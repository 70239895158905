export const Is = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Höfundarréttur &copy; 2023",
  black:"Svartur",
  green:"Grænn",
  gold:"Gull",
  blue:"Blár",
  brown:"Brúnn",
  purple:"Fjólublátt",
  pink:"Bleikur",
  red:"Rauður",
  Swatches:"Sýnishorn",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Litblær",
  Saturation:"Mettun",
  Lightness:"Léttleiki",
  Upgrade_To_Pro:"Uppfærðu í Pro",
  AllFeaturesInclude:"Allir eiginleikar innihalda:",
  PrintUnlimitedCheques:"Prentaðu ótakmarkaðar ávísanir",
  PremiumChequeDesigns:"Premium ávísanahönnun",
  ManageUnlimitedEmployees:"Stjórna ótakmörkuðum starfsmönnum",
  AddUnlimitedPayees:"Bættu við ótakmörkuðum greiðsluþegum",
  CreateUnlimitedPayrolls:"Búðu til ótakmarkaðar launaskrár",
  UnlimitedSchedulesandTimeSheets:"Ótakmarkaðar tímasetningar og tímaskrár",
  BulkPayPalPayouts:"Magn PayPal útborganir",
  UnlimitedBankAccounts:"Ótakmarkaður bankareikningur",
  ManageMultipleCompanies:"Stjórna mörgum fyrirtækjum",
  TrackInsurancePolicies:"Fylgstu með vátryggingaskírteinum",
  Bio_MetricProtection:"Lífmælingarvörn",
  Geo_FenceLock_OutProtection:"Geo-Fence Lock-Out vörn",
  Multiple_Companies_Word:"Að stjórna mörgum fyrirtækjum er ekki í boði án iðgjalds ávísana.",
  PayPal_Payouts_Word:"PayPal greiðslur eru óvirkar án ávísanaálags.",
  PINProtection:"PIN vörn",
  PasswordProtection:"Lykilorðsvörn",
  May_Require_Approval:"Getur þurft samþykki.",
  Subscribe:"Gerast áskrifandi",
  Billed:"Innheimt",
  Up:"Upp",
  Down:"Niður",
  Positioning:"Staðsetning",
  Marker:"Merki",
  Drag_Marker:"Dragðu merki",
  Tagline:"Prentaðu ávísanir og töfluðu launaskrá",
  Marker_Word:"Notaðu merkin til að stærð frumefnisins.",
  Pinch_Zoom:"Klípa aðdrátt",
  Pinch_Word:"Klíptu til að þysja þáttinn.",
  Drag:"Dragðu",
  Drag_Word:"Notaðu fingurinn til að draga þætti.",
  subscription_alias_word:"Sjálfvirk endurnýjun áskriftar",
  premium_alias_word:"Uppfærslupakki í eitt skipti",
  print_alias_word:"Útprentun einstakra ávísana",
  mode_alias_word:"Mode",
  Pro:"Pro",
  Pro_word:"Pro útgáfa krafist.",
  Cant_Delete_Default_Company_Word:"Því miður geturðu ekki eytt sjálfgefna fyrirtækinu þínu.",
  Reinsert_Default_Designs:"Settu aftur inn sjálfgefin hönnun",
  Reinsert_Default_Designs_word:"Viltu setja inn sjálfgefna hönnun aftur?",
  Subscription_Active_Disable_Word:"Þessi áskrift er virk. Viltu segja upp þessari áskrift að Cheques?",
  Company_Logo:"Fyrirtækismerki",
  ZERO_:"NÚLL",
  Disclaimer:"Fyrirvari",
  Privacy_Policy:"Friðhelgisstefna",
  EULA:"Athugar EULA",
  Terms_Of_Service:"Skilmálar þjónustu",
  Terms_Of_Use:"Notenda Skilmálar",
  Refunds:"Endurgreiðslustefna",
  Single_Print:"1 Athugaðu",
  Two_Prints:"2 Ávísanir",
  Five_Prints:"5 Ávísanir",
  Ten_Prints:"10 ávísanir",
  Fifteen_Prints:"15 Ávísanir",
  Twenty_Prints:"20 Ávísanir",
  Thirty_Prints:"30 Ávísanir",
  Image_Added:"Mynd bætt við",
  Image_Preview:"Forskoðun mynd",
  No_Image_Was_Selected:"Engin mynd var valin.",
  Cheques_Unlimited:"Ávísanir ótakmarkaðar",
  _Subscription:"Áskrift",
  Subscription:"Ávísanir - 1 mánuður",
  Two_Month_Subscription:"Ávísanir - 2 mánuðir",
  Three_Month_Subscription:"Ávísanir - 3 mánuðir",
  Six_Month_Subscription:"Ávísanir - 6 mánuðir",
  Twelve_Month_Subscription:"Ávísanir - 12 mánuðir",
  Cheques_Are_Available:"Hægt er að prenta ávísanir.",
  Upgrade_Required_Two:"Veldu pakka og tvísmelltu á verðhnappinn til að hefja kaupin. Prentaðu ávísanir í fullum lit í faglegri útliti á nokkrum sekúndum.",
  Month:"Mánuður",
  Due:"Á gjalddaga:",
  Expires:"Rennur út:",
  Subscription_Active:"Áskrift virk",
  Subscription_Inactive:"Áskrift óvirk",
  Purchase_Additional_Cheques:"Kaupa viðbótarávísanir?",
  Printable_Cheque:"Prentvæn ávísun",
  Printable_Cheques:"Prentvæn ávísun",
  Printable_Cheque_Word:"ávísun er tiltæk á reikningnum þínum.",
  Printable_Cheques_Word:"ávísanir eru tiltækar á reikningnum þínum.",
  Max_Amount_Message:"Upphæðin sem þú hefur tilgreint hefur farið yfir hámarksupphæðina sem sett er fyrir þetta kerfi:",
  Terms_Required_Word:"Þú verður að samþykkja þennan samning áður en þú heldur áfram að nota ávísanir.",
  Subscriptions:"Áskriftir",
  Product_Upgrades:"Uppfærslur",
  Mailed_Out_Cheques:"Útsendar ávísanir",
  Enter_A_Company_Name:"Vinsamlegast sláðu inn nafn fyrirtækis.",
  Single_Cheques:"Stakir ávísanir",
  Cheque_Golden:"Gullni ávísun",
  Cheque_Golden_Window:"Golden check hönnun.",
  Cheque_Green:"Græn ávísun",
  Cheque_Green_Window:"Græn ávísun hönnun.",
  Cheque_Red:"Rauður ávísun",
  Cheque_Red_Window:"Rauð tékkað hönnun.",
  Cheque_Yellow:"Gulur ávísun",
  Cheque_Yellow_Window:"Gul ávísun hönnun.",
  Cheque_Statue_of_Liberty:"Frelsisstyttan",
  Cheque_Statue_of_Liberty_Window:"Frelsisstyttan ávísun á hönnun.",
  Cheque_Green_Wave:"Græn bylgja",
  Cheque_Green_Wave_Window:"Græn ávísun hönnun.",
  Cheque_Golden_Weave:"Gullna vefnaður",
  Cheque_Golden_Weave_Window:"Glæsileg gyllt ávísun hönnun.",
  Cheque_Green_Sun:"Græn sól",
  Cheque_Green_Sun_Window:"Djúp og róandi tékkhönnun.",
  Cheque_Blue_Checkers:"Blá kömm",
  Cheque_Blue_Checkers_Window:"Blá ávísun hönnun.",
  Cashiers_Check:"Gjaldkeraávísun",
  Cashiers_Check_Window:"Stílsniðmát gjaldkeraávísana.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Aqua check hönnun.",
  Cheque_Golden_Checkers:"Gullna skák",
  Cheque_Golden_Checkers_Window:"Golden check hönnun.",
  Upgrade_Unavailable:"Uppfærslur ekki tiltækar",
  Bank_Code_Protection:"Bankanúmeravernd",
  Bank_Code_Protection_Word:"Verndaðu bankanúmerin þín gegn notkun í þessu forriti sem keyrir á öðru tæki eða fyrir annan notanda. Þessi aðgerð er óafturkræf. Halda áfram?",
  Bank_Code_Protection_Blocked_Word:"Bankanúmerin sem þú ert að reyna að nota eru frátekin fyrir annan notanda eða tæki.",
  Bank_Code_Protection_Error_Word:"Númerastaðfesting mistókst. Vinsamlegast tengdu við internetið og reyndu að bæta þessum bankareikningi við aftur.",
  Bank_Code_Protection_Set_Error_Word:"Bankanúmeravernd krefst þess að þú sért tengdur við internetið. Vinsamlegast tengdu og reyndu aftur.",
  Upgrade_Unavailable_Word:"Því miður, við eigum í vandræðum með að staðfesta þig. Ekki er hægt að kaupa áskriftir og uppfærslur á Checks á þínu svæði eins og er.",
  PayPal_Payment_Preview:"PayPal greiðsluforskoðun",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Veldu PayPal",
  PayPal_Applications:"PayPal umsóknir",
  Purchase_With_Apple_Pay:"Kauptu með Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Fyrirtæki",
  Insurance:"Tryggingar",
  New_PayPal:"Nýtt PayPal",
  Pay_By:"Borgaðu eftir",
  Insure:"Tryggja",
  Miles:"Mílur",
  Payment_Method:"Greiðslumáti",
  Select_Policies:"Veldu Reglur",
  Policies:"Stefna",
  Policy:"Stefna",
  No_PayPal_Account:"Enginn PayPal reikningur",
  No_Policies:"Engar tryggingarskírteini",
  New_Policy:"Ný stefna",
  PayPal_Payment:"PayPal greiðsla",
  PayPal_Payments:"PayPal greiðslur",
  No_Payment_Selected:"Engin greiðsla valin",
  Sending_Payment_Word:"Vinsamlegast bíddu... Verið er að senda þessa greiðslu.",
  Sending_Payments_Word:"Vinsamlegast bíddu... Verið er að senda greiðslur.",
  No_Payment_Selected_PayPal:"Engin <a routerLink='/folder/Payments'>PayPal Greiðsla</a> valin til að senda.",
  Payment_Sent:"Greiðsla send",
  PayPal_Payment_Sent:"Þessi greiðsla hefur verið send með PayPal.",
  Copay:"Afrit",
  Dead:"Dáinn",
  Alive:"Á lífi",
  Not_Dead:"Ekki dauður",
  Unclaimed:"Ósótt",
  Attempted:"Reyndi",
  Deceased:"Dáinn",
  Claimed:"Krafðist",
  Unpaid:"Ógreitt",
  Sending_Payment:"Sendir greiðslu",
  Sending_Payments:"Sendir greiðslur",
  Send_PayPal_Confirmation:"Viltu senda þessa færslu með PayPal?",
  Send_PayPal_Confirmation_Word:"Ýttu á græna hnappinn til að senda þessa færslu.",
  Save_Payment_As_Unpaid:"Vista þessa greiðslu sem ógreidda?",
  Payment_Pay_Confirmation_PayPal:"Vista þessa greiðslu sem greidda?",
  No_Policies_Word:"Bættu við fyrstu <a routerLink='/folder/Postage/New'>Tryggingaskírteini</a> núna.",
  Timesheet_Multiple_Delete_Confirmation:"Ertu viss um að þú viljir eyða mörgum tímablöðum?",
  Select_Multiple_Timesheets_Prompt:"Engin tímablöð valin. Veldu að minnsta kosti eitt tímablað.",
  Select_Multiple_Policies_Prompt:"Engar reglur valdar. Veldu að minnsta kosti eina tryggingarskírteini.",
  Policies_Multiple_Delete_Confirmation:"Ertu viss um að þú viljir eyða mörgum reglum?",
  Company:"Fyrirtæki",
  Add_Company:"Bæta við fyrirtæki",
  New_Company:"Bæta við fyrirtæki",
  No_Companies:"Engin fyrirtæki",
  Enable_Company:"Virkja fyrirtæki",
  Select_Company:"Veldu Fyrirtæki",
  The_Default_Company:"Sjálfgefið fyrirtækismerki.",
  Manage_Companies:"Stjórna fyrirtækjum",
  No_Companies_Word:"Athuganir munu nota sjálfgefið fyrirtæki.<br />Bættu við <a routerLink='/folder/Company/New'>Fyrsta Fyrirtækinu</a>.",
  Default_Company:"Sjálfgefið fyrirtæki",
  Cheques_Unlimited_Word:"Checks Unlimited gerir þér kleift að prenta eins margar ávísanir og þú vilt.",
  Cheques_Subscription_Word:"Áskrift ávísana gerir þér kleift að prenta eins margar ávísanir og þú vilt.",
  You_Own_This_Product:"Þú átt þessa vöru.",
  Your_Subscription_is_Active:"Áskriftin þín er virk.",
  Active_Products:"Virkjaðar vörur",
  Purchase_Confirmation:"Kaup",
  Purchase_Cheques:"Kaupávísanir",
  Restore_Purchase:"Endurheimta innkaup",
  Erase_Purchase:"Eyða innkaupum",
  Successfully_Purchased:"Tókst að kaupa",
  Enter_Your_Licence_Key:"Vinsamlegast sláðu inn leyfislykilinn þinn á þessari síðu til að virkja þessa vöru.",
  Licence_Key:"Leyfislykill",
  Enter_Licence_Key:"Sláðu inn leyfislykill",
  Purchased:"Keypt",
  Enable_Feature:"Virkja eiginleika",
  Cancel_Subscription:"Hætta áskrift",
  Subscription_Removed:"Áskrift fjarlægð",
  Select_Active_Subscription:"Veldu virka áskrift til að breyta henni.",
  Remove_Subscription_Word:"Ertu viss um að þú viljir segja upp þessari áskrift?",
  Delete_Company_Confirmation:"Ertu viss um að þú viljir eyða öllu þessu fyrirtæki? VIÐVÖRUN: Þú munt tapa öllum geymdum upplýsingum!",
  Delete_Default_Company_Word:"Þú getur ekki eytt sjálfgefna fyrirtækinu. Viltu endurstilla forritið og setja það aftur í sjálfgefið ástand? VIÐVÖRUN: Þú munt tapa öllum geymdum upplýsingum!",
  Console_Warning_2:"Ekki höndla neinn gjaldmiðil með þessu forriti sem er ekki þitt eins og er.",
  Terms_and_Conditions:"Skilmálar og skilyrði",
  and_the:"og",
  for:"fyrir",
  Please_Read_Word:"Vinsamlegast lestu og samþykktu",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Sum gjaldmiðlaviðskiptagengi fundust ekki. Vinsamlegast tengdu við internetið.",
  Free:"Ókeypis",
  DB_Erase_Prompt_2:"Eyða öllum þróunargagnagrunninum algjörlega? VIÐVÖRUN: Þú munt missa allar upplýsingar um KAUP og ÁSKRIFT!",
  Successfully_Imported:"Flutt inn",
  Select_Postage:"Veldu Porto",
  No_Postage:"Engin frímerki",
  No_Paid_Postage_Word:"Bættu við fyrsta <a routerLink='/folder/Postage/New'>Greitt Póstburðargjald</a> stimpil.",
  Trial_Complete:'Prufu lokið',
  Please_Upgrade:'Vinsamlegast uppfærðu ávísanir til að halda áfram prentun.',
  Aa:"Aa",
  Color:"Litur",
  Font:"Leturgerð",
  Guide:"Leiðsögumaður",
  Guides:"Leiðsögumenn",
  Image:"Mynd",
  Zoom:"Aðdráttur",
  Logo:"Merki",
  Bank:"Banki",
  MICR:"MICR",
  Total:"Samtals",
  Cancel:"Hætta við",
  Confirm:"Staðfesta",
  Method:"Aðferð",
  Biometric_Security:"Líffræðileg tölfræðiöryggi",
  Please_Login_To_Continue:"Vinsamlegast skráðu þig inn til að halda áfram.",
  Complete:"Heill",
  Sign_Up:"Skráðu þig",
  Error:"Villa",
  Biometrics:"Líffræðileg tölfræði",
  Percent:"Prósenta",
  Zero_Percent:"0%",
  Top_Margin:"Efsta framlegð",
  Bottom_Margin:"Neðsta spássía",
  Left_Margin:"Vinstri spássía",
  Right_Margin:"Hægri spássía",
  MICR_Margin:"MICR framlegð",
  Table_Margin:"Framlegð töflu",
  Address_Margin:"Heimilisfang Margin",
  Percentage_:"Hlutfall",
  Vacation_Title:"Orlofsheiti",
  Use_PIN:"Notaðu PIN",
  Loading__:"Hleður...",
  Design_Title:"Hönnunarheiti",
  Authorize:"Heimild",
  Key:"Lykill",
  Public_Key:"Opinber lykill",
  Private_Key:"Einkalykill",
  Authenticate:"Staðfesta",
  Last_Payroll:"Síðasta launaskrá",
  Last_Calculation:"Síðasti útreikningur",
  Authorized:"Heimilt",
  Geo_Authorized:"Geo-Location: Heimilt",
  Not_Authorized:"Ekki leyfilegt",
  Authorization_Complete:"Heimild lokið",
  Geolocation_Authorization:"Geo-location Authorization",
  Out_of_Bounds:"Út af mörkum",
  Cant_Delete_Default_Design:"Ekki er hægt að eyða sjálfgefna hönnun.",
  Unauthorized_Out_of_Bounds:"Óviðkomandi: Utan marka",
  Biometric_Authorization:"Líffræðileg tölfræðiheimild",
  Locating_Please_Wait:"Staðsetur, vinsamlegast bíddu...",
  Test_Biometrics:"Próf líffræðileg tölfræði",
  Cheque_Margins:"Athugaðu spássíur",
  Percentage_Full_Error:"Ekki er hægt að stilla prósentureitinn yfir 100% prósent.",
  No_Payroll_Text:"Bættu við <a routerLink='/folder/Employee/New'>Starfsmanni</a> eða <a routerLink='/folder/Payee/New'>Greiðsluþega</a> og <a routerLink='/folder/Schedule/New'>Tímaáætlun</a>.",
  Design_Saved:"Hönnun vistuð",
  Default_Design_Selected:"Sjálfgefin hönnun valin",
  Partial_Import:"Innflutningur að hluta",
  Partial_Export:"Útflutningur að hluta",
  Entire_Import:"Allur innflutningur",
  Entire_Export:"Allur útflutningur",
  Existing_Password:"Vinsamlegast sláðu inn núverandi lykilorð:",
  Existing_PIN:"Vinsamlegast sláðu inn núverandi PIN-númer:",
  Pin_Change_Header:"PIN Staðfesting",
  Pin_Change_SubHeader:"Sláðu inn gamla PIN-númerið þitt til að staðfesta breytinguna.",
  Pass_Change_Header:"Staðfesting lykilorðs",
  Pass_Change_SubHeader:"Sláðu inn gamla lykilorðið þitt til að staðfesta breytinguna.",
  PIN_Enter_Message:"Sláðu inn PIN-númerið þitt til að staðfesta.",
  Password_Enter_Message:"Sláðu inn lykilorðið þitt til að staðfesta.",
  Pin_Updated_Success:"PIN-númerið var uppfært!",
  Pin_Updated_Fail:"Ekki var hægt að uppfæra PIN-númerið.",
  Pass_Updated_Success:"Lykilorðið uppfært.",
  Pass_Updated_Fail:"Ekki tókst að uppfæra lykilorð.",
  Preview_Payment:"Forskoða greiðsla",
  Preview_Payroll:"Forskoða launaskrá",
  No_Payments_Created:"Engar greiðslur fundust til að búa til.",
  Payment_Preview:"Forskoðun greiðslu",
  Enable_Payee:"Virkja greiðsluviðtakanda",
  Rendered:"Afgreitt",
  No_Email:"Enginn tölvupóstur",
  Setup_Cheques:"Uppsetningarathuganir",
  name:"Nafn",
  address:"Heimilisfang",
  address_2:"Heimilisfang lína 2",
  city:"Borg",
  province:"Hérað",
  postal_code:"Póstnúmer",
  country:"Land",
  username:"Notendanafn",
  full_name:"Fullt nafn",
  birthday:"Afmælisdagur",
  email:"Tölvupóstur",
  phone:"Sími",
  employees:"Starfsmenn",
  addresses:"Heimilisföng",
  payment_amount_limit:"Greiðsluupphæðartakmörk",
  total_limit:"Heildarmörk",
  payees:"Greiðsluþegar",
  description:"Lýsing",
  address_line_one:"Heimilisfang lína eitt",
  address_line_two:"Heimilisfangslína tvö",
  image:"Mynd",
  account_holder:"Reikningshafi",
  cheque_starting_id:"Athugaðu Starting ID",
  transit_number:"Samgöngunúmer",
  institution_number:"Stofnunarnúmer",
  designation_number:"Tilnefningarnúmer",
  account_number:"Reikningsnúmer",
  currency:"Gjaldmiðill",
  signature:"Undirskrift",
  payment_payroll_limit:"Greiðslumörk",
  total_limi:"Heildarmörk",
  date:"Dagsetning",
  printed_memo:"Prentað minnisblað",
  banks:"Bankar",
  signature_image:"Undirskrift mynd",
  address_name:"Heimilisfang Nafn",
  notes:"Skýringar",
  design:"Hönnun",
  title:"Titill",
  frequency:"Tíðni",
  fax:"Fax",
  salaries:"Laun",
  salary_ids:"Launaauðkenni",
  start_time:"Byrjunartími",
  end_time:"Lokatími",
  paid:"Greitt",
  overtime_percentage:"Greitt hlutfall",
  overtime_amount:"Greidd föst upphæð",
  first_name:"Fyrsta nafn",
  last_name:"Eftirnafn",
  moderation:"Hófsemi",
  create:"Búa til",
  edit:"Breyta",
  destroy:"Eyðileggja",
  day_start_time:"Dagur_upphafstími",
  day_end_time:"Dagslokatími",
  fullname:"Nafn",
  time:"Tími",
  auto_send:"Senda sjálfkrafa",
  time_method:"Tímaaðferð",
  schedules:"Dagskrár",
  indefinite_payroll_enabled:"Virkja endalaust",
  amount:"Magn",
  repeat:"Endurtaktu",
  always_enabled:"Alltaf virkt",
  start_date:"Upphafsdagur",
  end_date:"Loka dagsetning",
  Cheque_Total:"Athugaðu Samtals",
  Total_Amount:"Heildarupphæð:",
  Amounts:"Upphæðir:",
  Images:"Myndir",
  Files:"Skrár",
  Previewing:"Forskoðun:",
  Insert:"Settu inn",
  Preview_Import:"Forskoða innflutning",
  Entry:"Inngangur",
  Entries:"Færslur",
  No_Entries:"Engar færslur",
  Import_Type:"Tegund innflutnings",
  Select_Details:"Veldu Upplýsingar",
  Select_Payee:"Veldu Viðtakandi greiðslu",
  Enable_Holidays:"Virkja frídaga",
  Disable_Holidays:"Slökkva á frídögum",
  Wire_Transfer:"Símaflutningur",
  New_Export:"Nýr útflutningur",
  Export_Data:"Flytja út gögn",
  Export_Data_Word:"Veldu tegund skráar sem á að flytja út og hlaða niður.",
  Export_File:"Flytja út skrá",
  Mode:"Mode",
  Times:"Tímar",
  Widgets:"Græjur",
  Slider:"Renna",
  Set_Details:"Stilltu upplýsingar",
  Select_Type:"Veldu Tegund",
  Display_Slider:"Skjár renna",
  Dashboard_Slider:"Renna fyrir mælaborð",
  Dashboard_Mode:"Mælaborðsstilling",
  Show_Intro:"Sýna Intro",
  Show_Payrolls:"Sýna launaskrár",
  Show_Holidays:"Sýna frí",
  Show_Invoices:"Sýna reikninga",
  Show_Cheques:"Sýna ávísanir",
  Enabled_Widgets:"Virkjaðar græjur",
  Disabled_Widgets:"Óvirkar búnaður",
  Colors:"Litir",
  Barcodes:"Strikamerki",
  View_Timers:"Skoða tímamæla",
  Gradients:"Halli",
  No_Info:"Engar upplýsingar",
  Disable:"Slökkva",
  Show_Layer:"Sýna lög",
  Hide_Layer:"Fela lög",
  Text_Layer:"Textalög",
  Secondly:"í öðru lagi",
  Minutely:"Mínútulega",
  nine_am:"9:00 UM MORGUN",
  five_pm:"17:00",
  Enable_Address:"Virkja heimilisfang",
  Invalid_File_Type:"Því miður var ógild skráargerð valin. Stuðningur skráartegundar:",
  Error_Updating_Entry:"Því miður kom upp villa við að uppfæra þessa færslu.",
  Schedule_Timing_Alert:"Villa: Upphafstími áætlunarinnar er stilltur á gildi eftir lokatímann.",
  Select_Multiple_Payments_Prompt:"Engar greiðslur valdar. Veldu að minnsta kosti eina greiðslu.",
  Select_Multiple_Cheques_Prompt:"Engar ávísanir valdar. Vinsamlegast veldu að minnsta kosti eina ávísun.",
  Select_Multiple_Items_Prompt:"Engir hlutir valdir. Vinsamlegast veldu að minnsta kosti eitt atriði.",
  Paymemt_Multiple_Delete_Confirmation:"Ertu viss um að þú viljir eyða mörgum greiðslum?",
  Cheque_Multiple_Delete_Confirmation:"Ertu viss um að þú viljir eyða mörgum ávísunum?",
  Payee_Multiple_Delete_Confirmation:"Ertu viss um að þú viljir eyða mörgum greiðsluviðtakendum?",
  Employee_Multiple_Delete_Confirmation:"Ertu viss um að þú viljir eyða mörgum starfsmönnum?",
  MICR_Warning:"Athugið: Sumir prentarar og tæki sýna MICR leturgerðina ekki rétt.",
  Automatically_Send:"Senda sjálfkrafa",
  Type:"Tegund",
  Payment_Type:"Greiðslutegund",
  Auto_Send:"Senda sjálfvirkt",
  Automatically_Process:"Vinnsla sjálfkrafa",
  Auto_Process:"Sjálfvirk ferli",
  Image_Based:"Byggt á myndum",
  Font_Based:"Letur byggt",
  Rerender:"Endurgera",
  Rendering:"Rending",
  Render:"Skrár",
  Top:"Efst",
  Middle:"Miðja",
  Bottom:"Neðst",
  Top_Left:"Efst til vinstri",
  Top_Center:"Efsta Miðja",
  Top_Right:"Efst til hægri",
  Middle_Left:"Mið til vinstri",
  Middle_Center:"Miðstöð",
  Middle_Right:"Miðhægri",
  Bottom_Left:"Neðst til vinstri",
  Bottom_Center:"Neðst Miðja",
  Bottom_Right:"Neðst til hægri",
  Numbers:"Tölur",
  Verified:"Staðfest",
  Paper_Size:"Pappírsstærð",
  New_Device:"Nýtt tæki",
  Add_Device:"Bæta við tæki",
  Remove_Device:"Fjarlægðu tæki",
  Delete_Device:"Eyða tæki",
  Block_Device:"Lokaðu á tæki",
  Block:"Block",
  Unblock:"Opna fyrir bann",
  Table:"Tafla",
  Scan_Login_Code:"Skannaðu innskráningarkóða",
  Login_Code:"Innskráningarkóði",
  Scan_Code:"Skanna kóða",
  Scan_QR_Code:"Skannaðu QR kóða",
  Select_All:"Velja allt",
  Deselect_All:"Afvelja allt",
  Increase:"Auka",
  Decrease:"Minnka",
  Bold:"Djarft",
  Text:"Texti",
  Style:"Stíll",
  Italic:"Skáletrað",
  QR_Code:"QR kóða",
  Barcode:"Strikamerki",
  Browse_Images:"Skoðaðu myndir",
  Browse_Files:"Skoðaðu skrár",
  Background_Image:"Bakgrunnsmynd",
  Logo_Image:"Logo mynd",
  Header_Image:"Hausmynd",
  Bank_Image:"Bankamynd",
  Cut_Lines:"Klipptu línur",
  Background:"Bakgrunnur",
  License:"Leyfi",
  One_License:"1 leyfi:",
  Licensed:"Með leyfi til:",
  Not_Licensed:"Ekki með leyfi",
  Enter_Serial:"Sláðu inn Serial",
  Serial_Key:"Raðlykill",
  Serial:"Rað",
  Product_Key:"Vörulykill",
  Check_Product_Key:"Athugaðu vörulykil",
  Add_Product_Key:"Bæta við vörulykli",
  Verifying_Purchase:"Staðfestir kaup...",
  Print_Envelope:"Prentaðu umslag",
  Envelope:"Umslag",
  Thank_You:"Þakka þér fyrir!",
  Scale:"Mælikvarði",
  Print_Scale:"Prentkvarði",
  Borders:"Landamæri",
  VOID:"Ógildur",
  Void_Cheque:"Ógild ávísun",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"GREIÐIST TIL:",
  NO_DOLLARS:"ENGIR DOLLAR ",
  ONE_DOLLAR:"EINN DOLLAR",
  DOLLARS:" DOLLAR",
  AND:" OG ",
  CENTS:" CENT.",
  NO_:"NEI ",
  ONE_:"EINN ",
  AND_NO_:"OG NEI ",
  _AND_ONE_:"OG EINN ",
  DOLLARS_AND_ONE_CENT:" OG EITT CENT.",
  AND_NO_CENTS:" OG NÚLL CENT.",
  CHEQUE_PRINT_DATE:"DAGSETNING:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"þingmaður",
  Initial_Tasks:"Upphafsverkefni",
  Inital_Setup:"Upphafleg uppsetning",
  Welcome_To:"Velkomin til ",
  Welcome:"Velkominn",
  Swipe:"Strjúktu",
  Intro_Setup:"Introuppsetning",
  Introduction:"Kynning",
  CHEQUE_PRINT_CREDIT:"Knúið af ávísunum",
  Title_Intro_Word:"Velkomin í ávísanir",
  Title_Intro:"Ávísanir kynning",
  Title_Setup:"Uppsetning ávísana",
  PayPal_Default_Email_Message:"Þú hefur fengið nýja PayPal millifærslu. [Krifið af ávísunum]",
  Cheques_App_Export:"Flutið út með ávísunum",
  Post_Intro_Thanks:"Þakka þér fyrir að velja ávísanir. Uppsetningarferlinu er nú lokið.",
  Post_Intro_Word:"Byrjaðu með því að prenta út fyrstu ávísunina þína, bæta við framtíðargreiðslu eða bæta starfsmanni á launaskrá.",
  Upgrade_Required:"Ávísanir krefjast þess að þú eigir hágæða útgáfu af hugbúnaðinum til að fela þessi skilaboð og opna fleiri eiginleika.",
  Upgrade_Title:"Ávísanir",
  Mailout_Prompt:"Þú getur auk þess valið að láta Checks senda út launaávísanir þínar fyrir þig.",
  Mailed_Cheque:"Send ávísun:",
  Mailed_Cheque_Color:"Send ávísun (litur):",
  Terms_Purchase:"Öll rafræn kaup með ávísunum eru að fullu endurgreidd í allt að 30 daga frá kaupdegi. Vinsamlegast lestu og samþykktu <a href='#'>skilmálana</a> áður en þú heldur áfram.",
  Dashboard_Setup:"Setja upp aðalprentarann",
  Dashboard_Add:"Bæta við aðalbankareikningi",
  Dashboard_Customize:"Veldu ávísunarsniðmát",
  Dashboard_Job_Salary:"Búðu til starf þitt og bættu við launum þínum",
  Dashboard_Employees:"Rekja starfsmenn og greiðsluþega",
  Dashboard_Print:"Prentaðu og sendu launaseðlana þína",
  Dashboard_Payroll:"Gerðu sjálfvirkan prentun launaskrá þinnar",
  Dashboard_PayPal:"Setja upp PayPal launaskrá / útborganir",
  Begin_Setup:"Byrjaðu uppsetningu",
  Get_Started:"Byrja",
  Purchase:"Kaup",
  Lockdown:"Útgöngubann",
  Unlock:"Opna",
  Detailed:"Ítarlega",
  Log_In:"Skrá inn",
  Login:"Skrá inn",
  Launch:"Sjósetja",
  Register:"Skráðu þig",
  Finish:"Klára",
  List:"listi",
  Weekends:"helgar",
  Weekly:"Vikulega",
  PayPal_Default_Subject:"Ný greiðsla",
  Payment_Message:"Greiðsluskilaboð",
  PayPal_Default_Payment_Note:"Þakka þér fyrir",
  Setup_Your_Cheqing_Account:"Ávísunarreikningur",
  Add_Your_Primary_Cheqing_Account:"Bættu við aðalávísunarreikningnum þínum.",
  Welcome_Word:"Einfalda og gera sjálfvirkan launa- og mannauðsstjórnun.",
  Get_Started_Quickly:"Svaraðu bara nokkrum einföldum spurningum sem hjálpa okkur að byrja...",
  Done_Intro_Word:"Uppsetningu lokið",
  PIN_Protected:"Lykilorð og PIN varið",
  Enter_New_PIN:"Sláðu inn nýjan PIN-kóða:",
  Enter_PIN:"Sláðu inn PIN-númer:",
  Invalid_PIN:"Ógilt PIN-númer slegið inn.",
  Account_Identifier:"Reikningsauðkenni",
  New_Account_Identifier:"Nýtt reikningsauðkenni",
  attempt:"tilraun",
  attempts:"tilraunir",
  remaining:"eftir",
  Language:"Tungumál",
  languages:"Tungumál",
  select_languages:"Veldu tungumál",
  Deposit:"Innborgun",
  Hire_One_Now:"Ráðu einn núna",
  App_Locked:"Forritið er læst.",
  Skip_:"Sleppa",
  Skip_to_Dashboard:"Sleppa yfir á stjórnborðið",
  Dashboard:"Mælaborð",
  Import:"Flytja inn",
  Admin:"Stjórnendur",
  New_Admin:"Nýr stjórnandi",
  Settings:"Stillingar",
  Color_Picker:"Litablokkari",
  Font_Picker:"leturval",
  Logout:"Að skrá þig út",
  Close:"Loka",
  Close_menu:"Loka",
  Excel:"Excel skrá",
  Csv:"CSV skrá",
  Sql:"SQL skrá",
  Json:"JSON skrá",
  Url:"Flytja inn eftir vefslóð",
  Back:"Aftur",
  Timers:"Tímamælir",
  Cheque:"Athugaðu",
  Print:"Prenta",
  Designs:"Hönnun",
  Pause_Printing:"Gera hlé á prentun",
  Resume_Printing:"Halda áfram prentun",
  Printing_Paused:"Prentun í bið",
  PrintingUnavailable:"Því miður! Prentun er ekki tiltæk á þessu kerfi.",
  Prints_Paused:"Prentanir í bið",
  Administration:"Stjórnun",
  Loading:"Hleðsla",
  Unnamed:"Ónefndur",
  error:"Því miður var ekki hægt að opna þessa ávísun til að skoða.",
  No_Cheques_To_Print:"Engar ávísanir til að prenta",
  No_Cheques_To_Print_Word:"Búðu til <a routerLink='/folder/Cheque/New'>Nýja Ávísun</a>.",
  New_Cheque:"Ný ávísun",
  Start_One_Now:"Byrjaðu einn núna",
  Edit_Cheque:"Breyta ávísun",
  Select_Cheques:"Veldu ávísanir",
  Select_Employee:"Veldu starfsmann",
  Default_Printer:"Sjálfgefinn prentari",
  Reassign:"Endurúthluta",
  Configure:"Stilla",
  Template:"Sniðmát",
  Designer:"Hönnuður",
  Edit_Designs:"Breyta hönnun",
  New_Design:"Ný hönnun",
  Next:"Næst",
  Save:"Vista",
  Name:"Nafn",
  Mail:"póstur",
  Amount:"Magn",
  Date:"Dagsetning",
  PayPal:"PayPal",
  Payouts:"Útgreiðslur",
  PayPal_Label:"PayPal merki",
  Email_Username:"Netfang / notendanafn",
  Client_ID:"Auðkenni viðskiptavinar",
  Sandbox_Email:"Sandbox tölvupóstur",
  PayPal_Email:"PayPal tölvupóstur",
  PayPal_Username:"API notendanafn",
  PayPal_Payouts:"PayPal útborganir",
  Select_PayPal_Key:"Veldu PayPal lykil",
  Secret:"Leyndarmál",
  API_Secret:"API Secret",
  PayPal_API_Keys:"PayPal lyklar",
  New_PayPal_Key:"Nýr PayPal lykill",
  Email_Subject:"Tölvupóstsefni",
  Email_Message:"Tölvupóstur",
  Payout_Options:"Útgreiðslumöguleikar",
  Payment_Note:"Greiðsluathugun",
  Enable_Employee:"Virkja starfsmann",
  Enable_Production_Mode:"Virkja framleiðsluham",
  Sandbox_Username:"Sandbox notendanafn",
  Sandbox_Signature:"Sandkassi undirskrift",
  Sandbox_Password:"Sandbox lykilorð",
  Production_Username:"Notandanafn framleiðslu",
  Production_Signature:"Framleiðsla undirskrift",
  Production_Password:"Lykilorð framleiðslu",
  Production_Email:"Tölvupóstur",
  API_Client_ID:"Auðkenni API viðskiptavinar",
  API_Signature:"API undirskrift",
  API_Password:"API lykilorð",
  API_Username:"API notendanafn",
  Secret_Key:"Leynilykill",
  Sandbox:"Sandkassi",
  Production:"Framleiðsla",
  Sandbox_Keys:"Sandkassalyklar",
  Production_Keys:"Framleiðslulyklar",
  API_Title:"API titill",
  Production_Mode:"Production Mode",
  Account_Label:"Reikningsmerki",
  No_PayPal_Setup:"Enginn PayPal lykill",
  Enable_PayPal_Account:"Virkja PayPal reikning",
  No_PayPal_Word:"Bættu við <a routerLink='/folder/Invoice/New'>PayPal API lyklinum þínum</a>.",
  Printed_Memo:"Prentað minnisblað",
  Employee:"Starfsmaður",
  View_Employee:"Skoða starfsmann",
  Mailing_Address:"Póstfang",
  Company_Address:"Fyrirtækis heimilisfang",
  Select_Company_Address:"Veldu heimilisfang fyrirtækis",
  Address:"Heimilisfang",
  Any_Day:"Hvaða dag sem er",
  Address_Name:"Heimilisfangsnafn",
  Unit:"Eining",
  Account:"Reikningur",
  Bank_Account:"Bankareikning",
  Account_Limits:"Virkja reikningstakmörk",
  Payroll:"Launaskrá",
  New_Payroll:"Ný launaskrá",
  No_Payroll:"Engin væntanleg launaskrá",
  Upcoming_Holiday:"Komandi frí:",
  Invoice_Due:"Reikningur á gjalddaga:",
  New_Invoice:"Nýr reikningur",
  No_Invoices:"Engir reikningar",
  No_Invoices_Word:"Búðu til fyrsta <a routerLink='/folder/Invoice/New'>Reikninginn</a>.",
  Cheque_Due:"Ávísun á gjalddaga:",
  Payrolls:"Launaskrár",
  Sandbox_Mode:"Sandbox Mode",
  Hire:"Leiga",
  Pay:"Borga",
  New:"Nýtt",
  Add:"Bæta við",
  Make:"gera",
  Time:"Tími",
  Write:"Skrifaðu",
  Holiday:"Frí",
  Holidays:"Frídagar",
  Next_Holiday:"Næsta frí:",
  All_Done:"Allt búið!",
  Employees:"Starfsmenn",
  Payees:"Greiðsluþegar",
  Job:"Starf",
  Jobs:"Starf",
  Invoice:"Reikningur",
  Invoices:"Reikningar",
  Vacations:"Frí",
  Cheques:"Ávísanir",
  Brand_Cheques:"Merki",
  Payment:"Greiðsla",
  Enable_Payment:"Virkja greiðslu",
  Payments:"Greiðslur",
  Schedule:"Tímaáætlun",
  Schedules:"Áætlanir",
  Timesheet:"Tímatafla",
  Timesheets:"Tímaskrár",
  Salary:"Laun",
  New_Address:"Nýtt heimilisfang",
  Address_2:"Heimilisfang lína 2)",
  _City:"Borg",
  _State:"Ríki/Prov",
  City:"Borg / Township",
  State:"Ríki/hérað",
  Postal:"Póstnúmer",
  ZIP:"Póst / ZIP",
  Country:"Land",
  Addresses:"heimilisföng",
  Required_Options:"Nauðsynlegir valkostir",
  Optional_Options:"Valfrjálsir valkostir",
  Additional_Options:"Viðbótarvalkostir",
  Required:"Nauðsynlegt",
  Optional:"Valfrjálst",
  Additional:"Viðbótar",
  No_Addresses:"Engin heimilisföng",
  New_Address_Word:"Bæta við fyrsta <a routerLink='/folder/Address/New'>Heimilisfang</a>.",
  Select_Address:"Veldu heimilisfang",
  No_Address:"Engin heimilisföng",
  Print_Cheque:"Prenta ávísun",
  Print_Cheque_Now:"Prenta Athugaðu núna",
  Description:"Lýsing",
  Pay_To_The_Order_Of:"Greiðist til:",
  Select_Date_Range:"Veldu tímabil",
  Select_Starting_Date:"Veldu upphafsdagsetningu",
  Select_Ending_Date:"Veldu lokadagsetningu",
  Select_Date:"Veldu dagsetningu",
  Cheque_Date:"Athugaðu dagsetningu",
  Cheque_Memo:"Athugaðu minnisblað",
  Blank_Cheque:"Autt ávísun",
  Blank:"Autt",
  No_Cheques:"Engar ávísanir",
  No_Cheques_Word:"Prentaðu fyrsta <a routerLink='/folder/Cheque/New'>Athugaðu</a>.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"Skoða mynd",
  View:"Útsýni",
  Modify:"Breyta",
  Delete:"Eyða",
  Cheque_Paid:"Borgað",
  New_Deduction:"Nýr frádráttur",
  Title:"Titill",
  Frequency:"Tíðni",
  Hourly:"Klukkutíma fresti",
  Daily:"Daglega",
  Weekdays:"Virka daga",
  BiWeekly:"2 vikur",
  TriWeekly:"3 vikur",
  Monthly:"Mánaðarlega",
  MiMonthly:"2 mánuðir",
  Quarterly:"Ársfjórðungslega",
  Yearly:"Árlega",
  Every_Week:"Í hverri viku",
  Every_Payroll:"Sérhver launaskrá",
  Every_Month:"Hvern mánuð",
  Annually:"Árlega",
  Always_Scheduled:"Alltaf tímasett",
  Start_Date:"Upphafsdagur",
  End_Date:"Loka dagsetning",
  Start_Time:"Byrjunartími",
  End_Time:"Endatími",
  Deduction_Label:"Frádráttarmerki",
  Notes:"Athugasemdir",
  Options:"Valkostir",
  Enable:"Virkja",
  Select_Deductions:"Veldu frádrátt",
  Deductions:"Frádráttur",
  No_Deductions:"Enginn frádráttur",
  No_Deductions_Word:"Búðu til þinn fyrsta <a routerLink='/folder/Deduction/New'>Frádrátt</a>.",
  New_Employee:"Nýr starfsmaður",
  No_Title:"Enginn titill",
  _Name:"Nafn",
  About_Yourself:"Um sjálfan þig",
  Full_Name:"Fullt nafn",
  Birthday:"Afmælisdagur",
  Email:"Tölvupóstur",
  SMS:"SMÁSKILABOÐ",
  Phone:"Sími",
  Test:"Próf",
  Call:"Hringja",
  Fax:"Fax",
  Printed_Note:"Prentað athugasemd",
  Position:"Staða",
  Job_Position:"Vinnu staða",
  Select_a_Job:"Veldu starf",
  Select_a_Salary:"Veldu laun",
  Add_a_Deduction:"Bæta við frádrætti",
  Taxes:"Skattar",
  Add_Taxes:"Bæta við sköttum",
  Date_of_Birth:"Fæðingardagur",
  Email_Address:"Netfang",
  Phone_Number:"Símanúmer",
  Phone_Call:"Símtal",
  Enable_on_Payroll:"Virkja á launaskrá",
  Select_Employees:"Veldu starfsmenn",
  No_Employees:"Engir starfsmenn",
  No_Employees_Word:"Bættu við fyrsta nýja <a routerLink='/folder/Employee/New'>Starfsmanninum Þínum</a>.",
  No_Name:"Ekkert nafn",
  Unemployeed:"Atvinnulaus",
  Employeed:"Vinnandi",
  Paid:"Borgað",
  Enabled:"Virkt",
  Disabled:"Fötluð",
  Fire:"Eldur",
  Not_Available:"Ekki í boði",
  Not_Available_Word:"Prentaðu fyrsta <a routerLink='/folder/Invoice/New'>Reikninginn Þinn</a> og fáðu borgað.",
  Select_Invoices:"Veldu_Reikningar",
  Print_Invoice_Word:"Prentaðu fyrsta <a routerLink='/folder/Invoice/New'>Reikninginn Þinn</a> og fáðu borgað.",
  Invoice_Title:"Reikningsheiti",
  Invoice_Date:"Dagsetning reiknings",
  Due_Date:"Gjaldadagur",
  New_Job:"Nýtt starf",
  Details:"Upplýsingar",
  Customize:"Sérsníða",
  Customize_Dashboard:"Sérsníða mælaborð",
  Components:"Íhlutir",
  No_Components:"Engir íhlutir",
  Main_Components:"Aðalhlutir",
  Smaller_Components:"Minni íhlutir",
  Error_Loading_Page:"Villa við að hlaða þessa síðu.",
  Bank_Details:"Bankaupplýsingar",
  About_Your_Job:"Um starf þitt",
  Your_Schedule:"Áætlun þín",
  Job_Title:"Starfsheiti",
  Job_Description:"Starfslýsing",
  Job_Details:"Starfsupplýsingar",
  Enable_Job:"Virkja starf",
  Pay_Period:"Launatímabil",
  Perpetually_Schedule:"Sífellt tímaáætlun",
  Select_Jobs:"Veldu störf",
  No_Jobs:"Engin störf",
  Add_Jobs:"Bæta við störfum",
  No_Jobs_Word:"Bættu fyrsta <a routerLink='/folder/Job/New'>Starf</a> við listann.",
  Count_Employees:"job.employee_count+' Starfsmenn'",
  Zero_Employees:"0 starfsmenn",
  New_Leave:"Nýtt leyfi",
  Leave_Name:"Leyfðu nafni",
  Paid_Leave:"Launað frí",
  Leave_Pay:"Leyfðu borga",
  Indefinite_Leave:"Ótímabundið leyfi",
  Indefinite_Payroll:"Óákveðinn launaskrá",
  Leave:"Farðu",
  Add_Schedules:"Bæta við áætlunum",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Virkja fjarveru",
  Select_Leaves:"Veldu blöð",
  No_Leaves:"Engin leyfi frá störfum",
  Leave_Of_Absence:"Fjarvistarleyfi",
  Leaves_Of_Absence:"Fjarvistarleyfi",
  New_Leave_of_Absense:"Nýtt leyfi frá störfum",
  No_Leaves_Word:"Bæta við fyrsta <a routerLink='/folder/Leave/New'>Fjarvistarleyfi</a>.",
  Not_Enabled:"Ekki virkt",
  Absences:"Fjarverur",
  Payee:"Greiðsluþegi",
  New_Payee:"Nýr greiðsluviðtakandi",
  Payee_Identifier:"Auðkenni greiðsluþega",
  Payee_Name:"Nafn viðtakanda",
  Payee_Title:"Titill viðtakanda",
  Payment_Memo:"Greiðsluminning",
  Select_Payees:"Veldu greiðsluviðtakendur",
  No_Payees:"Engir greiðsluþegar",
  Add_Payee_Note:"Bæta við fyrsta <a routerLink='/folder/Payee/New'>Greiðsluþega</a>.",
  New_Payees:"Nýir greiðsluþegar",
  About_The_Payment_Schedule:"Greiðsluáætlun",
  Your_Payroll_Schedule:"Sjálfvirk launaskrá",
  New_Payment:"Ný greiðsla",
  Identifier:"Auðkenni",
  Select:"Veldu",
  Memo:"Minnisatriði",
  Payment_Date:"Greiðsludagur",
  Mark_as_Paid:"Merkja sem greitt",
  Select_Payments:"Veldu greiðslur",
  No_Payments:"Engar greiðslur",
  No_Payments_Word:"Búðu til fyrstu <a routerLink='/folder/Payment/New'>Greiðsluna</a>.",
  Create_Payroll:"Búa til launaskrá",
  Properties:"Eignir",
  Payroll_Title:"Launaheiti",
  Payroll_Notes:"Launaskýrslur",
  Payroll_Setup:"Launauppsetning",
  Tabulate_Payments:"Taflaðu greiðslur",
  Tabulate:"Tafla",
  By:"Eftir:",
  Payments_By:"Greiðslur eftir",
  Timesheets_And_Schedules:"Tímablöð og tímasetningar",
  Both:"Bæði",
  Items:"Hlutir",
  Add_Payees:"Bæta við viðtakendum við",
  Add_Account:"Bæta við aðgangi",
  Enable_Account:"Virkja reikning",
  Enable_Payroll:"Virkja launaskrá",
  Print_Payroll:"Prenta launaskrá",
  No_Payrolls:"Engin launaskrá",
  No_Payroll_Word:"Búðu til fyrstu <a routerLink='/folder/Payroll/New'>Launaskrá</a>.",
  View_more:"SJÁ MEIRA",
  Less:"MINNA",
  Add_Payroll:"Bæta við launaskrá",
  Select_Payroll:"Veldu launaskrá",
  About_Your_Salary:"Um laun þín",
  Add_Salaries:"Bæta við launum",
  Add_Salary:"Bæta við launum",
  Salaries:"Laun",
  No_Salaries:"Engin laun",
  No_Salaries_Word:"Bættu við fyrstu <a routerLink='/folder/Salary/New'>Laununum</a>.",
  Select_Salaries:"Veldu laun",
  New_Salary:"Ný laun",
  Salary_Name:"Launakennari",
  Enable_Salary:"Virkja laun",
  Salary_Amount:"Launaupphæð",
  New_Schedule:"Ný stundaskrá",
  Schedule_Title:"Titill á dagskrá",
  Add_Address:"Bæta við heimilisfangi",
  Repeat:"Endurtaka",
  Design:"Hönnun",
  Edit_Design:"Breyta hönnun",
  Edit_this_Design:"Breyta þessari hönnun",
  Repeat_Payment:"Endurtaka greiðsla",
  Enable_Schedule:"Virkja áætlun",
  Never:"Aldrei",
  Select_Schedule:"Veldu tímasetningar",
  No_Schedules:"Engin tímaáætlun",
  No_Schedules_Word:"Búðu til fyrstu <a routerLink='/folder/Schedule/New'>Áætlun</a>.",
  New_Administrator:"Nýr stjórnandi",
  Username:"Notendanafn",
  First_Name:"Fyrsta nafn",
  Last_Name:"Eftirnafn",
  Add_an_Address:"Bæta við heimilisfangi",
  Payment_Limit:"Per-Payment Limit",
  Total_Limit:"Heildartakmörk",
  Select_Accounts:"Veldu reikninga",
  No_Administrators:"Engir stjórnendur",
  No_Administrators_Word:"Búðu til fyrsta <a routerLink='/folder/Administrator/New'>Stjórnandareikninginn</a>.",
  New_Administrators_Word:"Bæta við fyrsta <a routerLink='/folder/Administrator/New'>Stjórnanda</a>",
  Cloud:"ský",
  Backup:"Öryggisafrit",
  Enable_iCloud:"Virkja iCloud",
  Enable_Google_Drive:"Virkja Google Drive",
  Enable_Microsoft_OneDrive:"Virkja Microsoft OneDrive",
  Automatically_Backup:"Sjálfvirkt öryggisafrit",
  FTP_Settings:"FTP stillingar",
  URL_File_Prompt:"Vinsamlegast tilgreindu staðsetningu fyrir .xls / .xlsx / .json skrá til að flytja inn:",
  URL_SQL_Prompt:"Vinsamlegast tilgreindu staðsetningu SQLite skráarinnar sem á að flytja inn:",
  FTP_Backup:"FTP öryggisafrit",
  FTP_Import:"FTP innflutningur",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Gestgjafi",
  Port:"Höfn",
  Path:"Leið",
  Data_Path:"Gagnaslóð",
  Enable_FTP_Account:"Virkja FTP reikning",
  HostnameIP:"Hýsingarheiti",
  Password:"Lykilorð",
  Connection_Port:"Tengihöfn",
  Enable_MySQL_Database:"Virkja MySQL gagnagrunn",
  Log:"Log",
  Reset:"Endurstilla",
  Erase:"Eyða",
  Export:"Flytja út",
  Database:"gagnagrunnur",
  Upload_CSV:"Hlaða upp CSV",
  Download_CSV:"Hlaða niður CSV",
  SQL_Database:"SQL vefslóð",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL öryggisafrit",
  Internal_Notes:"Innri athugasemdir",
  Root_Path:"Root Path",
  Select_Backup:"Veldu öryggisafrit",
  Add_New_Backup:"Bæta við nýjum öryggisafriti",
  First_Backup:"Setja upp fyrsta öryggisafritið...",
  Backups:"Öryggisafrit",
  Add_Backup:"Bæta við öryggisafriti",
  No_Backups:"Það er engin afrit að finna.",
  Select_Backup_Type:"Veldu tegund öryggisafrits sem þú vilt setja upp...",
  Backup_Type:"Tegð öryggisafrits",
  FTP_Drive:"FTP drif",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Drive",
  Microsoft_OneDrive:"Drive",
  Import_Fields:"Innflutningsreitir",
  Import_Fields_Word:"Notaðu þennan hluta til að velja <a routerLink='/folder/Holiday/New'>Flytja Inn</a> gögn.",
  Upload:"Hlaða upp",
  Download:"Hlaða niður",
  Download_JSON:"Hlaða niður sem JSON gögnum",
  Download_SQL:"Hlaða niður sem SQL skrá",
  New_Bank:"Nýr banki",
  New_Account:"Nýr reikningur",
  New_Bank_Account:"Nýr bankareikningur",
  Upload_Image:"Hlaða inn mynd",
  Bank_Name:"Nafn banka",
  Bank_Address:"Banka heimilisfang",
  Branch_Address:"Heimilisfang útibús",
  Address_on_Cheque:"Heimilisfang",
  Cheque_Numbers:"Athugaðu tölur",
  Cheque_Details:"Athugaðu upplýsingar",
  Bank_Logo:"Bankamerki",
  Cheque_ID:"Athugaðu auðkenni",
  Starting_Cheque_ID:"Aðhafa ávísun auðkenni",
  Transit_Number:"Transit Number",
  Institution_Number:"Stofnunarnúmer",
  Designation_Number:"Tilnefningarnúmer",
  Account_Number:"Reikningsnúmer",
  Limits:"Takmörk",
  Default_Limits:"Sjálfgefin takmörk",
  Over_Limit:"Yfir takmörk",
  Under_Limit:"Undir takmörkunum",
  Payroll_Limit:"Launamörk",
  Enable_Bank_Account:"Virkja bankareikning",
  Select_Account:"Veldu reikning",
  No_Bank_Account:"Enginn bankareikningur",
  No_Bank_Account_Word:"Bæta við fyrsta <a routerLink='/folder/Accounts/New'>Bankareikningi</a>.",
  Bank_Accounts:"Bankareikningar",
  No_Accounts:"Engir reikningar",
  No_Accounts_Note:"Bæta við fyrsta <a routerLink='/folder/Accounts/New'>Bankareikningi</a>.",
  Cheque_Designer:"Athugaðu hönnuður",
  Cheque_Design:"Athugaðu hönnun",
  Select_Design:"Veldu hönnun",
  Cheque_Designs:"Athugaðu hönnun",
  No_Cheque_Designs:"Engin ávísunarhönnun",
  No_Cheque_Designs_Word:"Búðu til þína eigin <a routerLink='/folder/Settings/Cheque/Design/New'>Athugaðu Hönnun</a>.",
  Set_Default:"Setja sem sjálfgefið",
  Default:"Sjálfgefið",
  Remove:"Fjarlægja",
  Folder:"Mappa",
  Edit:"Breyta",
  LoadingDots:"Hleður...",
  Add_a_New_File:"Bæta <a href='#' (click)='add()'>Nýrri Skrá</a> við",
  this_folder:"þessi mappa",
  FTP_Backup_Settings:"FTP öryggisafritunarstillingar",
  Secure_File_Transfer:"Öryggur skráaflutningur",
  New_Holiday:"Nýtt frí",
  Add_Holiday:"Bæta við fríi",
  Holiday_Name:"Frídaginnafn",
  Additional_Pay:"Viðbótarlaun",
  Enable_Holiday:"Virkja frí",
  Select_Holidays:"Veldu frí",
  No_Holidays:"Engin frí",
  No_Holidays_Word:"Bæta við fyrsta <a routerLink='/folder/Holiday/New'>Almenna Frídaginn</a>.",
  New_Import:"Nýr innflutningur",
  Import_Data:"Flytja inn gögn",
  Import_Data_Word:"Veldu tegund skráar eða upphleðsluaðferð að eigin vali.<br /> Þú munt geta valið hvaða innfluttu reitir í skrá samsvara hvaða færibreytu sem er í forritinu eftir að þú hefur hlaðið upp studdri skrá.", 
  Import_File:"Flytja inn skrá",
  Link_To_File:"Tengill á skrá",
  Select_File:"Veldu skrá",
  New_Moderator:"Nýr stjórnandi",
  Allow_Moderation:"Leyfa hófsemi",
  Create_Paycheques:"Búa til launaseðla",
  Edit_Paycheques_and_Data:"Breyta launaseðlum og gögnum",
  Destroy_Data_and_Paycheques:"Eyðileggja gögn og launaávísanir",
  Bonus_Percentage:"Prósenta launaseðla",
  Fixed_Amount:"Föst upphæð",
  Select_Moderator:"Veldu stjórnanda",
  No_Moderators:"Engir stjórnendur",
  Moderators:"Stjórnendur",
  Moderator_Account:"Búa til fyrsta <a routerLink='/folder/Administrator/New'>Stjórnandareikninginn</a>.",
  No_Moderators_Word:"Bæta við fyrsta <a routerLink='/folder/Administrator/New'>Stjórnanda</a>.",
  Defaults:"Sjálfgefið",
  Provide_Us_Info:"Gefðu okkur upplýsingar",
  Setup_Your_Printer:"Settu upp prentarann ​​þinn",
  Your_Company:"Um fyrirtækið þitt",
  Company_Name:"Nafn fyrirtækis",
  Currency:"Gjaldmiðill",
  Default_Currency:"Sjálfgefinn gjaldmiðill",
  Base_Monthly_Income:"Mánaðarleg innkoma",
  Protection:"Vörn",
  App_Protection:"Appvernd",
  PIN_Code_Protection:"PIN kóða vernd",
  App_Protection_Word:"Virkjaðu öryggisaðferðir sem hjálpa til við að vernda reikninginn þinn.",
  PIN_Code:"PIN númer",
  Change_PIN:"Breyta PIN",
  New_Password:"Nýtt lykilorð",
  Geofence_Protection:"Geo-girðingarvernd",
  Geofence_Area:"Setja svæði",
  Distance:"Fjarlægð",
  Setup_Now:"Setja upp núna",
  Mile:"Míla",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Andlitsþekking",
  Face:"Andlit",
  Setup:"Uppsetning",
  Label:"Label",
  Password_Protection:"Lykilorðsvörn",
  Modify_Password:"Breyta lykilorði",
  New_Tax_Entry:"Ný skattfærsla",
  New_Tax:"Nýr skattur",
  Tax_Label:"Skattamerki",
  Perpetually_Enabled:"Enabled Enabled",
  Select_Taxes:"Veldu skatta",
  Tax_Deductions:"Skattafsláttur",
  No_Tax_Deductions:"Enginn skattaafsláttur",
  No_Tax_Deductions_Word:"Bættu við fyrsta <a routerLink='/folder/Tax/New'>Skatt</a> frádráttinum.",
  New_Timer:"Nýr tímamælir",
  Start:"Byrja",
  Stop:"Hættu",
  Start_Timer:"Start teljara",
  Stop_Timer:"Stöðva tímamælir",
  Timer_Active:"Tímamælir virkur",
  Timer:"Tímamælir:",
  Timer_Running:"Tímamælir: (Í gangi)",
  Save_Timer:"Vista tímamælir",
  New_Timesheet:"Nýr tímaskrá",
  Select_Timesheets:"Veldu tímaskrár",
  Work_Notes:"Vinnuskýringar",
  Entry_Title:"Titill færslu",
  No_Timesheets:"Engin tímaskýrsla",
  No_Timesheets_Word:"Bæta við fyrsta <a routerLink='/folder/Timesheet/New'>Tímatafla</a>.",
  Timesheet_Submitted:"Tímaskrá lögð fram",
  Timesheet_Congrats:"Til hamingju! Tímablaðið þitt hefur verið sent inn. Þakka þér!",
  Timesheet_Copy:"Til að fá afrit af skjölunum þínum vinsamlegast gefðu okkur upp netfangið þitt og/eða farsímanúmerið þitt.",
  Submit:"Senda",
  Allow_Notifications:"Leyfa tilkynningar",
  Untitled_Entry:"Ný færsla",
  Untitled_Bank:"Ónefndur banki",
  Timesheet_Entry:"Tímaskráning",
  Work_Description:"Vinnulýsing",
  Enable_Timesheet:"Virkja tímaskýrslu",
  Submit_Timesheet:"Senda inn tímaskýrslu",
  Vacation:"Frí",
  New_Vacation:"Nýtt frí",
  Vacation_Name:"Nafn orlofs",
  Paid_Vacation:"Goldið frí",
  Vacation_Pay:"Orlofslaun",
  Enable_Vacation:"Virkja frí",
  Select_Vacations:"Veldu frí",
  No_Vacations:"Ekkert frí",
  No_Vacations_Word:"Búðu til fyrstu <a routerLink='/folder/Vacation/New'>Frí</a> færsluna.",
  Payroll_Schedule:"Launaáætlun",
  Next_Payroll:"Næsta launaskrá:",
  Upcoming_Payroll:"Komandi launaskrá",
  No_Upcoming_Payroll:"Engin væntanleg launaskrá",
  Address_Book:"Heimilisfangabók",
  Archived_Documents:"Geymd skjöl",
  Dev_Mode:"Forrit í þróunarham",
  Administrators:"Stjórnendur",
  Privacy:"Persónuvernd",
  None:"Enginn",
  Select_Signature:"Veldu undirskrift",
  No_Signatures:"Engar undirskriftir",
  No_Signatures_Word:"Bæta við fyrstu <a routerLink='/folder/Signature/New'>Undirskrift</a>.",
  Repeat_Indefinitely:"Endurtaktu endalaust",
  Sign:"Skrifaðu undir",
  Sign_Here:"Skrifaðu undir hér",
  Date_Signed:"Dagsetning undirrituð",
  Signature_Pad:"Undirskriftarblokk",
  Account_Holder:"Reikningshafi",
  Account_Properties:"Reikningseignir",
  Name_On_Cheque:"Nafn á ávísun",
  Server_Hostname:"Hýsingarheiti þjóns / IP",
  Printers:"Prentarar",
  No_Printers:"Engir prentarar",
  Printer_Exists:'Prentari með þessu nafni er þegar til.',
  No_Printers_Word:"Bættu við fyrsta <a routerLink='/folder/Printer/New'>Prentaranum</a>.",
  No_Printer_Alert:"Enginn prentari er skilgreindur. Viltu setja upp prentara?",
  Add_Printer:"Bæta við prentara",
  New_Printer:"Nýr prentari",
  Printer_Hostname:"Hýsingarheiti prentara / IP",
  Printer_Label:"Prentari Label",
  Printer_Protocol:"Printer Protocol",
  Protocol:"Bókun",
  Email_Print:"Tölvupóstur",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Innstunga",
  Print_Job:"Prentverk",
  Printed:"Prentað",
  Not_Printed:"Ekki prentað",
  Print_Jobs:"Prentverk",
  Print_Queue:"Prent biðröð",
  No_Print_Jobs:"Engin prentverk",
  No_Prints:"Búðu til nýjan <a routerLink='/folder/Cheque/New'>Ávísun</a> til að prenta út.",
  Prints:"Prentar",
  Find_Printer:"Finna prentara",
  Find_AirPrint_Devices:"Finna AirPrint tæki",
  Select_Printer:"Veldu prentara",
  System_UI:"System UI",
  Printer:"Prentari",
  Status:"Staða",
  Preview:"Forskoðun",
  Enable_Print_Job:"Virkja prentverk",
  Queue_Weight:"Biðröð Þyngd",
  Unlimited:"Ótakmarkað",
  Show_Advanced_Printer_Options:"Sýna háþróaða prentaravalkosti",
  Advanced:"Ítarlegri",
  Location:"Staðsetning",
  Note:"Athugið",
  Queue_Name:"Biðröð nafn",
  Pages_Printed_Limit:"Síður prentaðar takmarkanir",
  MultiPage_Idle_Time:"Margsíðubiðtími (tímar)",
  Page_Count_Limit:"Takmörk síðufjölda",
  Page_Orientation:"Síðustefna",
  Portrait:"Portrett",
  Landscape:"Landslag",
  Duplex:"Duplex",
  Double_Sided_Printing:"Tvíhliða",
  Duplex_Mode:"Duplex Mode",
  Duplex_Short:"Stutt",
  Duplex_Long:"Langt",
  Duplex_None:"Enginn",
  Color_And_Quality:"Litur og gæði",
  Monochrome:"Einlitað",
  Photo_Quality_Prints:"Gæði ljósmyndaprentunar",
  Printer_Email:"Tölvupóstur prentara",
  Automatically_Downsize:"Færa sjálfkrafa niður",
  Paper:"pappír",
  Paper_Name:"Paper Name",
  Paper_Width:"Papir Breidd",
  Paper_Height:"Papir Hæð",
  Paper_Autocut_Length:"Sjálfvirkt klippt pappírslengd",
  Margins:"Margar",
  Page_Margins:"Síðu spássíur",
  Page_Margin_Top:"Spjall á efstu síðu",
  Page_Margin_Right:"Hægri síðu spássía",
  Page_Margin_Bottom:"Boðsíða spássía",
  Page_Margin_Left:"Vinstri síðu spássía",
  Add_Employees:"Bæta við starfsmönnum",
  Header:"haus",
  Print_A_Page_Header:"Prenta síðuhaus",
  Header_Label:"Höfuðmerki",
  Header_Page_Index:"Header Page Index",
  Header_Font_Name:"Höfuð leturgerð",
  Select_Font:"Veldu leturgerð",
  Font_Selector:"Leturval",
  Header_Font_Size:"Höfuð leturgerð",
  Header_Bold:"Höfuð feitletruð",
  Header_Italic:"Höfuð skáletraður",
  Header_Alignment:"Höfuðjöfnun",
  Left:"Vinstri",
  Center:"Miðja",
  Right:"Rétt",
  Justified:"Réttlætanlegt",
  Header_Font_Color:"Höfuðlitur",
  Select_Color:"Veldu lit",
  Footer:"Fótur",
  Print_A_Page_Footer:"Prenta síðufót",
  Footer_Label:"Fótmerki",
  Footer_Page_Index:"Footer Page Index",
  Footer_Font_Name:"Footer leturgerð",
  Fonts:"Skírnarfontur",
  Done:"Lokið",
  Select_Fonts:"Veldu leturgerðir",
  Footer_Font_Size:"Fótastærð",
  Footer_Bold:"Footer Feit",
  Footer_Italic:"Fótur skáletraður",
  Footer_Alignment:"Fótröðun",
  Footer_Font_Color:"Fótlitur",
  Page_Copies:"Síðuafrit",
  Enable_Printer:"Virkja prentara",
  Remote_Logging:"Fjarskráning",
  Log_Server:"Loggaþjónn",
  Encryption:"Dulkóðun",
  Random_Key:"Tilviljanakenndur lykill",
  Encryption_Key:"Dulkóðunarlykill",
  Cheques_Webserver:"Sérsniðinn gagnagrunnur",
  Learn_How:"Lærðu hvernig",
  Signature:"Undirskrift",
  Default_Printer_Unit:"í/cm/mm/(pt)",
  View_Signature:"Skoða undirskrift",
  Printed_Signature:"Printuð undirskrift",
  Digitally_Sign:"Stafrænt undirrita",
  Digital_Signature:"Stafræn undirskrift",
  Add_Signature:"Bæta við undirskrift",
  Add_Your_Signature:"Bættu við undirskrift þinni",
  Enable_Signature:"Virkja undirskrift",
  Digitally_Signed:"Stafrænt undirritað",
  Insert_Error:"Ekki er hægt að vista ávísun vegna vandamála í gagnagrunni.",
  Delete_Confirmation:"Ertu viss um að þú viljir eyða þessum upplýsingum?",
  Discard_Confirmation:"Ertu viss um að þú viljir henda þessum upplýsingum?",
  Discard_Bank_Confirmation:"Ertu viss um að þú viljir henda þessum reikningi?",
  Discard_Printer_Confirmation:"Ertu viss um að þú viljir fleygja þessum prentara?",
  Delete_Bonus_Confirmation:"Ertu viss um að þú viljir eyða þessum bónus?",
  Delete_Invoice_Confirmation:"Ertu viss um að þú viljir eyða þessum reikningi?",
  Generated_Cheque:"Gerður ávísun",
  Generated_Invoice:"Gerður reikningur",
  Schedule_Start_Time:"Skrá byrjun",
  Schedule_End_Time:"Tímasetningarlok",
  New_Call:"Nýtt símtal",
  No_Contacts:"Engir tengiliðir",
  No_Contacts_Word:"Stjórnendur, stjórnendur, starfsmenn og viðtakendur greiðslu birtast sem tengiliðir.",
  PDF_Subject:"fjármál",
  PDF_Keywords:"Launaávísun PDF ávísanir",
  Printer_Setup:"Prentari Uppsetning",
  Printer_Selection:"Val prentara",
  New_Fax:"Nýtt fax",
  New_Fax_Message:"Ný faxskilaboð",
  Fax_Machine:"Faxvél",
  Fax_Name:"Fax nafn",
  Fax_Email:"Fax tölvupóstur",
  Fax_Number:"Faxnúmer",
  Contents:"Efni",
  Fax_Body:"Síðu meginmál",
  Header_Word:"haus:",
  Header_Text:"Höfuðtexti",
  Include_Header:"Ta með haus",
  Include_Footer:"Láta fætur fylgja með",
  Footer_Word:"Fótur:",
  Footer_Text:"Fóttexti",
  Attach_a_Cheque:"Læta við ávísun",
  Add_Deduction:"Bæta við frádrætti",
  Enable_Fax:"Senda fax",
  Select_Fax:"Veldu fax",
  No_Faxes:"Engin fax",
  Faxes:"Fax",
  Save_and_Send:"Senda fax",
  Save_and_Pay:"Vista og borga",
  WARNING:"VIÐVÖRUN:",
  Remember:"Mundu",
  Printing:"Prenta",
  Printing_Complete:"Prentun lokið!\n\n",
  of:"af",
  There_Were:"Það voru ",
  Successful_Prints:"heppnaðar prentanir og",
  Unsuccessful_Prints:"misheppnaðar prentanir.",
  PrinterError:"Því miður! Það kom upp villa.",
  Printing_:"Prentar...",
  PrinterSuccess:"Skjal prentað með góðum árangri.",
  PrintersSuccess:"Skjöl prentuð með góðum árangri.",
  Print_Cheques:"Prenta ávísanir",
  New_Message:"Ný skilaboð",
  New_Messages:"Ný skilaboð",
  Read_Message:"Lesa skilaboð",
  Write_Message:"Skrifa skilaboð",
  Send_Message:"Senda skilaboð",
  Subject:"Viðfangsefni",
  Message:"Skilaboð",
  Writing:"Að skrifa...",
  Send:"Senda",
  Set_Date:"Stilla dagsetningu",
  Set_Time:"Stilla tíma",
  Recieve:"Fáðu",
  Set_as_Default:"Setja sem sjálfgefið",
  Default_Account:"Sjálfgefinn reikningur",
  Default_Design:"Sjálfgefin hönnun",
  Multiple_Cheques:"Ávísanir (þrífaldur)",
  Account_Mode:"Reikningshamur",
  Multiple_Cheques_Description:"Þrjár athuganir á síðu.",
  Check_and_Table:"Athugaðu og tafla",
  Check_including_Table:"Athugunar- og bókhaldstafla.",
  Check_Mailer:"Athugaðu póst",
  Check_Mailer_Window:"Athugaðu með heimilisfangsglugga.",
  DocuGard_Table_Top:"DocuGard Check & Table (Efst)",
  DocuGard_Table_Middle:"DocuGard Check & Table (miðja)",
  DocuGard_Table_Bottom:"DocuGard Check & Tafla (neðst)",
  DocuGard_Mailer_Top:"DocuGard Check Mailer (Efst)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (miðja)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mailer (neðst)",
  DocuGard_Three_Cheques:"DocuGard ávísanir (þrefaldur)",
  DocuGard_Cheque_Top:"DocuGard Check (Efst)",
  DocuGard_Cheque_Middle:"DocuGard Check (miðja)",
  DocuGard_Cheque_Bottom:"DocuGard Check (neðst)",
  DocuGard_Three_Cheques_Window:"Þrjár ávísanir á einni síðu.",
  DocuGard_Table_Top_Window:"Athugunar- og tekjutafla.",
  DocuGard_Table_Middle_Window:"Athugunar- og tekjutafla.",
  DocuGard_Table_Bottom_Window:"Athugunar- og tekjutafla.",
  DocuGard_Mailer_Top_Window:"Athugaðu, tafla og heimilisfang.",
  DocuGard_Mailer_Middle_Window:"Athugaðu, tafla og heimilisfang.",
  DocuGard_Mailer_Bottom_Window:"Athugaðu, tafla og heimilisfang.",
  DocuGard_Cheque_Top_Window:"Athugaðu hvort pappír sé öruggur.",
  DocuGard_Cheque_Middle_Window:"Athugaðu hvort pappír sé öruggur.",
  DocuGard_Cheque_Bottom_Window:"Athugaðu hvort pappír sé öruggur.",
  Basic_Cheque:"Athugaðu (Efst)",
  Basic_Cheque_Print:"Prentaðu út eina ávísun.",
  Error_Setting_As_Paid:"Villa við að stilla sem greitt",
  Add_Attachment:"Bæta við viðhengi",
  PrinterUnavailable:"Prentari ekki tiltækur",
  CreditCardComponent:"Spil",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Ný innborgun",
  Deposits:"Innlán",
  No_Deposits:"Engin innlán",
  Credit_Card_Deposit:"Kreditkort",
  New_Credit_Card_Deposit_Message:"Kreditkortainnborgun",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin innborgun",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Interac Transfer",
  Payments_Limit:"Greiðslumörk",
  No_Payment_Limit:"Engin greiðslumörk",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal innborgun",
  No_Deposits_Word:"Bæta við fyrsta fyrirtækinu <a routerLink='/folder/Deposit/New'>Innborgun</a>.",
  No_Documents_Specified:"Engin skjöl voru tilgreind til prentunar",
  No_Printers_Added:"Engir prentarar fundust. Farðu í Stillingar > Prentarar til að bæta við einum.",
  DB_Erase_Prompt:"Eyða allan gagnagrunninn að fullu? VIÐVÖRUN: Þú munt tapa öllum geymdum upplýsingum!",
  Console_Warning:"Ekki líma neinn texta inn í þessa leikjatölvu. Þú gætir sett sjálfan þig og/eða fyrirtæki þitt í alvarlega hættu.",
  No_Faxes_Word:"Búðu til fyrsta <a routerLink='/folder/Fax/New'>Faxið</a>.",
  Cheque_Delete_Confirmation:"Ertu viss um að þú viljir eyða þessari ávísun?",
  Design_Delete_Confirmation:"Ertu viss um að þú viljir eyða þessari hönnun?",
  Cheque_Pay_Confirmation:"Merkja þessa ávísun sem greidda? Hún mun EKKI birtast í prentröðinni.",
  Payment_Pay_Confirmation:"Merkið þessa greiðslu sem greidda? Hún mun EKKI birtast í ávísunarröðinni.",
  Delete_Deduction_Confirmation:"Ertu viss um að þú viljir eyða þessum frádrátt?",
  Delete_Job_Confirmation:"Ertu viss um að þú viljir eyða þessu starfi?",
  Delete_Timesheet_Confirmation:"Ertu viss um að þú viljir eyða þessu tímablaði?",
  Delete_Schedule_Confirmation:"Ertu viss um að þú viljir eyða þessari áætlun?",
  Delete_Setting_Confirmation:"Ertu viss um að þú viljir endurstilla þessa stillingu?",
  Delete_Fax_Confirmation:"Ertu viss um að þú viljir eyða þessu faxi?",
  Delete_File_Confirmation:"Ertu viss um að þú viljir eyða þessari skrá?",
  Delete_Vacation_Confirmation:"Ertu viss um að þú viljir eyða þessu fríi?",
  Delete_Printer_Confirmation:"Ertu viss um að þú viljir eyða þessum prentara?",
  Remove_Design_Confirmation:"Ertu viss um að þú viljir eyða þessari hönnun?",
  Delete_Payroll_Confirmation:"Ertu viss um að þú viljir eyða þessum launaskrá?",
  Send_Fax_Email_Confirmation:"Viltu faxa og senda þessi skjöl í tölvupósti?",
  Send_Email_Confirmation:"Viltu senda þetta skjal í tölvupósti?",
  Send_Fax_Confirmation:"Viltu faxa þetta skjal?",
  Error_Generating_PDF:"Því miður. Villa kom upp við að búa til þetta skjal.",
  PDF_Error_Saving_Image:"Því miður. Villa kom upp við að vista PDF mynd af þessu skjali.",
  Test_Printer_Confirmation:"Viltu prenta prufusíðu á þessum prentara?",
  Save_Timesheet_Prompt:"Vinsamlegast bættu við 'Titil' eða ýttu á 'Start Timer' til að vista.",
  Remove_Geofence_Prompt:"Ertu viss um að þú viljir fjarlægja staðsetningu þessarar landfræðilegu girðingar?",
  Delete_Employee_Confirmation:"Ertu viss um að þú viljir eyða",
  Fire_Employee_Confirmation:"Ertu viss um að þú viljir skjóta"
}