export const Ru = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Авторское право &copy; 2023",
  black:"Черный",
  green:"Зеленый",
  gold:"Золото",
  blue:"Синий",
  brown:"Коричневый",
  purple:"Фиолетовый",
  pink:"Розовый",
  red:"Красный",
  Swatches:"Образцы",
  HSL:"ВШЛ",
  RGB:"RGB",
  Hue:"оттенок",
  Saturation:"Насыщенность",
  Lightness:"Легкость",
  Upgrade_To_Pro:"Обновить до Про",
  AllFeaturesInclude:"Все функции включают в себя:",
  PrintUnlimitedCheques:"Печатайте неограниченное количество чеков",
  PremiumChequeDesigns:"Дизайн чеков премиум-класса",
  ManageUnlimitedEmployees:"Управление неограниченным количеством сотрудников",
  AddUnlimitedPayees:"Добавить неограниченное количество получателей",
  CreateUnlimitedPayrolls:"Создавайте неограниченные платежные ведомости",
  UnlimitedSchedulesandTimeSheets:"Неограниченное количество расписаний и табелей учета рабочего времени",
  BulkPayPalPayouts:"Массовые выплаты PayPal",
  UnlimitedBankAccounts:"Неограниченные банковские счета",
  ManageMultipleCompanies:"Управление несколькими компаниями",
  TrackInsurancePolicies:"Отслеживание страховых полисов",
  Bio_MetricProtection:"Биометрическая защита",
  Geo_FenceLock_OutProtection:"Защита от блокировки Geo-Fence",
  Multiple_Companies_Word:"Управление несколькими компаниями недоступно без Премиум Чеки.",
  PayPal_Payouts_Word:"Платежи PayPal отключены без надбавки к чекам.",
  PINProtection:"Защита PIN-кодом",
  PasswordProtection:"Защита паролем",
  May_Require_Approval:"Может потребоваться одобрение.",
  Subscribe:"Подписаться",
  Billed:"Выставлен счет",
  Up:"Вверх",
  Down:"Вниз",
  Positioning:"Позиционирование",
  Marker:"Маркер",
  Drag_Marker:"Перетащите маркер",
  Tagline:"Печатайте чеки и табулируйте заработную плату",
  Marker_Word:"Используйте маркеры, чтобы изменить размер элемента.",
  Pinch_Zoom:"Щепотка Масштаб",
  Pinch_Word:"Зажмите, чтобы увеличить элемент.",
  Drag:"Тащить",
  Drag_Word:"Используйте свой палец, чтобы перетаскивать элементы.",
  subscription_alias_word:"Автоматически продлеваемая подписка",
  premium_alias_word:"Пакет одноразового обновления",
  print_alias_word:"Распечатка отдельных чеков",
  mode_alias_word:"Режим",
  Pro:"Про",
  Pro_word:"Требуется про версия.",
  Cant_Delete_Default_Company_Word:"К сожалению, вы не можете удалить компанию по умолчанию.",
  Reinsert_Default_Designs:"Повторная вставка дизайнов по умолчанию",
  Reinsert_Default_Designs_word:"Вы хотите повторно вставить дизайны по умолчанию?",
  Subscription_Active_Disable_Word:"Эта подписка активна. Вы хотите отменить подписку на Cheques?",
  Company_Logo:"Логотип компании",
  ZERO_:"НУЛЬ",
  Disclaimer:"Отказ от ответственности",
  Privacy_Policy:"политика конфиденциальности",
  EULA:"Проверяет лицензионное соглашение",
  Terms_Of_Service:"Условия использования",
  Terms_Of_Use:"Условия эксплуатации",
  Refunds:"Политика возврата",
  Single_Print:"1 чек",
  Two_Prints:"2 проверки",
  Five_Prints:"5 проверок",
  Ten_Prints:"10 чеков",
  Fifteen_Prints:"15 проверок",
  Twenty_Prints:"20 чеков",
  Thirty_Prints:"30 чеков",
  Image_Added:"Изображение добавлено",
  Image_Preview:"Предварительный просмотр изображения",
  No_Image_Was_Selected:"Изображение не выбрано.",
  Cheques_Unlimited:"Чеки без ограничений",
  _Subscription:"Подписка",
  Subscription:"Чеки - 1 месяц",
  Two_Month_Subscription:"Чеки - 2 месяца",
  Three_Month_Subscription:"Чеки - 3 месяца",
  Six_Month_Subscription:"Чеки - 6 месяцев",
  Twelve_Month_Subscription:"Чеки - 12 месяцев",
  Cheques_Are_Available:"Чеки доступны для печати.",
  Upgrade_Required_Two:"Выберите пакет и дважды нажмите на кнопку цены, чтобы начать покупку. Печатайте профессионально выглядящие полноцветные чеки за считанные секунды.",
  Month:"Месяц",
  Due:"Должный:",
  Expires:"Истекает:",
  Subscription_Active:"Подписка активна",
  Subscription_Inactive:"Подписка неактивна",
  Purchase_Additional_Cheques:"Покупать дополнительные чеки?",
  Printable_Cheque:"Чек для печати",
  Printable_Cheques:"Печатные чеки",
  Printable_Cheque_Word:"чек доступен на вашем счету.",
  Printable_Cheques_Word:"чеки доступны на вашем счету.",
  Max_Amount_Message:"Указанная вами сумма превысила максимальную сумму, установленную для этой системы:",
  Terms_Required_Word:"Вы должны согласиться с этим соглашением, прежде чем продолжать использовать чеки.",
  Subscriptions:"Подписки",
  Product_Upgrades:"Обновления",
  Mailed_Out_Cheques:"Отправленные по почте чеки",
  Enter_A_Company_Name:"Пожалуйста, введите название компании.",
  Single_Cheques:"Одиночные проверки",
  Cheque_Golden:"Золотой чек",
  Cheque_Golden_Window:"Золотая клетка.",
  Cheque_Green:"Зеленый чек",
  Cheque_Green_Window:"Зеленая клетка.",
  Cheque_Red:"Красный чек",
  Cheque_Red_Window:"Красный чек дизайн.",
  Cheque_Yellow:"Желтая галочка",
  Cheque_Yellow_Window:"Желтая клетка.",
  Cheque_Statue_of_Liberty:"Статуя Свободы",
  Cheque_Statue_of_Liberty_Window:"Дизайн чека Статуи Свободы.",
  Cheque_Green_Wave:"Зеленая волна",
  Cheque_Green_Wave_Window:"Зеленая клетка.",
  Cheque_Golden_Weave:"Золотое плетение",
  Cheque_Golden_Weave_Window:"Элегантный дизайн в золотую клетку.",
  Cheque_Green_Sun:"Зеленое солнце",
  Cheque_Green_Sun_Window:"Глубокий и успокаивающий дизайн клетки.",
  Cheque_Blue_Checkers:"Синие шашки",
  Cheque_Blue_Checkers_Window:"Синий клетчатый дизайн.",
  Cashiers_Check:"Кассовый чек",
  Cashiers_Check_Window:"Образец оформления кассового чека.",
  Cheque_Aqua_Checkers:"Аква Шашки",
  Cheque_Aqua_Checkers_Window:"Аква проверить дизайн.",
  Cheque_Golden_Checkers:"Золотые шашки",
  Cheque_Golden_Checkers_Window:"Золотая клетка.",
  Upgrade_Unavailable:"Обновления недоступны",
  Bank_Code_Protection:"Защита номера банка",
  Bank_Code_Protection_Word:"Защитите свои банковские номера от использования в этом приложении, работающем на другом устройстве или для другого пользователя. Это действие НЕОБРАТИМО. Продолжать?",
  Bank_Code_Protection_Blocked_Word:"Банковские номера, которые вы пытаетесь использовать, зарезервированы для другого пользователя или устройства.",
  Bank_Code_Protection_Error_Word:"Проверка номера не удалась. Подключитесь к Интернету и попробуйте снова добавить этот банковский счет.",
  Bank_Code_Protection_Set_Error_Word:"Для защиты банковского номера необходимо подключение к Интернету. Пожалуйста, подключитесь и повторите попытку.",
  Upgrade_Unavailable_Word:"Извините, у нас возникли проблемы с вашей проверкой. Подписки и обновления до Checks в настоящее время недоступны для покупки в вашем регионе.",
  PayPal_Payment_Preview:"Предварительный просмотр платежа PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Выберите PayPal",
  PayPal_Applications:"Приложения PayPal",
  Purchase_With_Apple_Pay:"Покупка с помощью ApplePay",
  Google_Pay:"Google Pay",
  Companies:"Компании",
  Insurance:"Страхование",
  New_PayPal:"Новый PayPal",
  Pay_By:"Платить с помощью",
  Insure:"застраховать",
  Miles:"Мили",
  Payment_Method:"Способ оплаты",
  Select_Policies:"Выберите политики",
  Policies:"Политики",
  Policy:"Политика",
  No_PayPal_Account:"Нет учетной записи PayPal",
  No_Policies:"Нет страховых полисов",
  New_Policy:"Новая политика",
  PayPal_Payment:"Оплата PayPal",
  PayPal_Payments:"Платежи через PayPal",
  No_Payment_Selected:"Платеж не выбран",
  Sending_Payment_Word:"Пожалуйста, подождите... Этот платеж отправляется.",
  Sending_Payments_Word:"Пожалуйста, подождите... Платежи отправляются.",
  No_Payment_Selected_PayPal:"Не выбран <a routerLink='/folder/Payments'>платеж PayPal</a> для отправки.",
  Payment_Sent:"Платеж отправлен",
  PayPal_Payment_Sent:"Этот платеж был отправлен через PayPal.",
  Copay:"Доплата",
  Dead:"Мертвый",
  Alive:"Живой",
  Not_Dead:"Не мертв",
  Unclaimed:"Невостребованный",
  Attempted:"Попытка",
  Deceased:"Покойный",
  Claimed:"Заявлено",
  Unpaid:"Неоплачиваемый",
  Sending_Payment:"Отправка платежа",
  Sending_Payments:"Отправка платежей",
  Send_PayPal_Confirmation:"Вы хотите отправить эту транзакцию через PayPal?",
  Send_PayPal_Confirmation_Word:"Нажмите зеленую кнопку, чтобы отправить эту транзакцию.",
  Save_Payment_As_Unpaid:"Сохранить этот платеж как неоплаченный?",
  Payment_Pay_Confirmation_PayPal:"Сохранить этот платеж как оплаченный?",
  No_Policies_Word:"Добавьте первый <a routerLink='/folder/Postage/New'>страховой полис</a> прямо сейчас.",
  Timesheet_Multiple_Delete_Confirmation:"Вы уверены, что хотите удалить несколько табелей учета рабочего времени?",
  Select_Multiple_Timesheets_Prompt:"Табель учета рабочего времени не выбран. Выберите хотя бы один табель учета рабочего времени.",
  Select_Multiple_Policies_Prompt:"Политика не выбрана. Выберите хотя бы один страховой полис.",
  Policies_Multiple_Delete_Confirmation:"Вы уверены, что хотите удалить несколько политик?",
  Company:"Компания",
  Add_Company:"Добавить компанию",
  New_Company:"Добавить компанию",
  No_Companies:"Нет компаний",
  Enable_Company:"Включить компанию",
  Select_Company:"Выберите компанию",
  The_Default_Company:"Ярлык компании по умолчанию.",
  Manage_Companies:"Управление компаниями",
  No_Companies_Word:"При проверке будет использоваться компания по умолчанию.<br />Добавьте <a routerLink='/folder/Company/New'>первую компанию</a>.",
  Default_Company:"Компания по умолчанию",
  Cheques_Unlimited_Word:"Checks Unlimited позволяет вам распечатать столько чеков, сколько захотите.",
  Cheques_Subscription_Word:"Подписка на чеки позволяет вам печатать столько чеков, сколько захотите.",
  You_Own_This_Product:"Вы являетесь владельцем этого продукта.",
  Your_Subscription_is_Active:"Ваша подписка активна.",
  Active_Products:"Активированные продукты",
  Purchase_Confirmation:"Покупка",
  Purchase_Cheques:"Покупка Чеки",
  Restore_Purchase:"Восстановить покупки",
  Erase_Purchase:"Удалить покупки",
  Successfully_Purchased:"Успешно приобретено",
  Enter_Your_Licence_Key:"Введите лицензионный ключ на этой странице, чтобы активировать этот продукт.",
  Licence_Key:"Лицензионный ключ",
  Enter_Licence_Key:"Введите лицензионный ключ",
  Purchased:"Куплено",
  Enable_Feature:"Включить функцию",
  Cancel_Subscription:"Отменить подписку",
  Subscription_Removed:"Подписка удалена",
  Select_Active_Subscription:"Выберите активную подписку, чтобы изменить ее.",
  Remove_Subscription_Word:"Вы уверены, что хотите отменить эту подписку?",
  Delete_Company_Confirmation:"Вы уверены, что хотите удалить всю эту компанию? ВНИМАНИЕ: Вы потеряете всю сохраненную информацию!",
  Delete_Default_Company_Word:"Вы не можете удалить компанию по умолчанию. Вы хотите сбросить приложение и восстановить его состояние по умолчанию? ВНИМАНИЕ: Вы потеряете всю сохраненную информацию!",
  Console_Warning_2:"Не работайте с какой-либо валютой, используя это приложение, которое в настоящее время не принадлежит вам.",
  Terms_and_Conditions:"Условия и положения",
  and_the:"и",
  for:"для",
  Please_Read_Word:"Пожалуйста, прочтите и согласитесь с",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Не удалось найти курсы конвертации некоторых валют. Пожалуйста, подключитесь к Интернету.",
  Free:"Бесплатно",
  DB_Erase_Prompt_2:"Полностью стереть всю базу данных разработчиков? ВНИМАНИЕ: Вы потеряете всю информацию о ПОКУПКАХ и ПОДПИСКАХ!",
  Successfully_Imported:"Успешно импортировано",
  Select_Postage:"Выберите почтовые расходы",
  No_Postage:"Нет почтовых марок",
  No_Paid_Postage_Word:"Добавьте первую марку <a routerLink='/folder/Postage/New'>Платные почтовые расходы</a>.",
  Trial_Complete:'Пробная версия завершена',
  Please_Upgrade:'Обновите Checks, чтобы продолжить печать.',
  Aa:"Аа",
  Color:"Цвет",
  Font:"Шрифт",
  Guide:"Руководство",
  Guides:"Гиды",
  Image:"Изображение",
  Zoom:"Увеличить",
  Logo:"Логотип",
  Bank:"Банк",
  MICR:"МИКР",
  Total:"Общий",
  Cancel:"Отмена",
  Confirm:"Подтверждать",
  Method:"Метод",
  Biometric_Security:"Биометрическая безопасность",
  Please_Login_To_Continue:"Пожалуйста, залогиньтесь для продолжения.",
  Complete:"Полный",
  Sign_Up:"Зарегистрироваться",
  Error:"Ошибка",
  Biometrics:"Биометрия",
  Percent:"Процент",
  Zero_Percent:"0%",
  Top_Margin:"Верхнее поле",
  Bottom_Margin:"Нижнее поле",
  Left_Margin:"Левое поле",
  Right_Margin:"Правое поле",
  MICR_Margin:"MICR маржа",
  Table_Margin:"Маржа таблицы",
  Address_Margin:"Адресная маржа",
  Percentage_:"Процент",
  Vacation_Title:"Название отпуска",
  Use_PIN:"Использовать PIN-код",
  Loading__:"Загрузка...",
  Design_Title:"Название дизайна",
  Authorize:"Разрешить",
  Key:"Ключ",
  Public_Key:"Открытый ключ",
  Private_Key:"Закрытый ключ",
  Authenticate:"Аутентификация",
  Last_Payroll:"Последняя платежная ведомость",
  Last_Calculation:"Последний расчет",
  Authorized:"Авторизованный",
  Geo_Authorized:"Геолокация: Авторизовано",
  Not_Authorized:"Не авторизован",
  Authorization_Complete:"Авторизация завершена",
  Geolocation_Authorization:"Авторизация по геолокации",
  Out_of_Bounds:"За границами",
  Cant_Delete_Default_Design:"Невозможно удалить дизайн по умолчанию.",
  Unauthorized_Out_of_Bounds:"Несанкционировано: за пределами границ",
  Biometric_Authorization:"Биометрическая авторизация",
  Locating_Please_Wait:"Поиск, пожалуйста, подождите...",
  Test_Biometrics:"Тестовая биометрия",
  Cheque_Margins:"Проверить поля",
  Percentage_Full_Error:"Поле процента не может быть установлено выше 100%.",
  No_Payroll_Text:"Добавьте <a routerLink='/folder/Employee/New'>сотрудника</a> или <a routerLink='/folder/Payee/New'>получателя платежей</a> и <a routerLink='/folder/Schedule/New'>Расписание</a>.",
  Design_Saved:"Дизайн сохранен",
  Default_Design_Selected:"Выбран дизайн по умолчанию",
  Partial_Import:"Частичный импорт",
  Partial_Export:"Частичный экспорт",
  Entire_Import:"Весь импорт",
  Entire_Export:"Весь экспорт",
  Existing_Password:"Пожалуйста, введите ваш существующий пароль:",
  Existing_PIN:"Пожалуйста, введите ваш существующий PIN-код:",
  Pin_Change_Header:"Подтверждение PIN-кода",
  Pin_Change_SubHeader:"Введите свой старый PIN-код, чтобы подтвердить изменение.",
  Pass_Change_Header:"Подтверждение пароля",
  Pass_Change_SubHeader:"Введите старый пароль, чтобы подтвердить изменение.",
  PIN_Enter_Message:"Введите свой PIN-код для подтверждения.",
  Password_Enter_Message:"Введите свой пароль для подтверждения.",
  Pin_Updated_Success:"PIN-код успешно обновлен!",
  Pin_Updated_Fail:"PIN-код не может быть обновлен.",
  Pass_Updated_Success:"Пароль успешно обновлен.",
  Pass_Updated_Fail:"Не удалось обновить пароль.",
  Preview_Payment:"Предварительный платеж",
  Preview_Payroll:"Предварительный просмотр платежной ведомости",
  No_Payments_Created:"Платежи не были созданы.",
  Payment_Preview:"Предварительный просмотр платежа",
  Enable_Payee:"Включить получателя",
  Rendered:"Визуализировано",
  No_Email:"Нет электронной почты",
  Setup_Cheques:"Проверка установки",
  name:"Имя",
  address:"Адрес",
  address_2:"Адресная строка 2",
  city:"Город",
  province:"Провинция",
  postal_code:"Почтовый индекс",
  country:"Страна",
  username:"Имя пользователя",
  full_name:"ФИО",
  birthday:"День рождения",
  email:"Эл. адрес",
  phone:"Телефон",
  employees:"Сотрудники",
  addresses:"Адреса",
  payment_amount_limit:"Лимит суммы платежа",
  total_limit:"Общий лимит",
  payees:"Получатели",
  description:"Описание",
  address_line_one:"Адресная строка 1",
  address_line_two:"Адресная строка 2",
  image:"Изображение",
  account_holder:"Владелец счета",
  cheque_starting_id:"Проверить начальный идентификатор",
  transit_number:"Транзитный номер",
  institution_number:"Номер учреждения",
  designation_number:"Обозначение Номер",
  account_number:"Номер счета",
  currency:"Валюта",
  signature:"Подпись",
  payment_payroll_limit:"Платежный лимит",
  total_limi:"Общий лимит",
  date:"Свидание",
  printed_memo:"Печатная памятка",
  banks:"банки",
  signature_image:"Изображение подписи",
  address_name:"Адрес",
  notes:"Заметки",
  design:"Дизайн",
  title:"Заголовок",
  frequency:"Частота",
  fax:"Факс",
  salaries:"Заработная плата",
  salary_ids:"Идентификаторы заработной платы",
  start_time:"Время начала",
  end_time:"Время окончания",
  paid:"Оплаченный",
  overtime_percentage:"Выплаченный процент",
  overtime_amount:"Выплаченная фиксированная сумма",
  first_name:"Имя",
  last_name:"Фамилия",
  moderation:"Умеренность",
  create:"Создавать",
  edit:"Редактировать",
  destroy:"Разрушать",
  day_start_time:"Day_start_time",
  day_end_time:"Day_end_time",
  fullname:"Имя",
  time:"Время",
  auto_send:"Автоматически отправлять",
  time_method:"Метод времени",
  schedules:"Расписания",
  indefinite_payroll_enabled:"Включить на неопределенный срок",
  amount:"Количество",
  repeat:"Повторение",
  always_enabled:"Всегда включен",
  start_date:"Дата начала",
  end_date:"Дата окончания",
  Cheque_Total:"Проверить итог",
  Total_Amount:"Итого:",
  Amounts:"Суммы:",
  Images:"Картинки",
  Files:"Файлы",
  Previewing:"Предварительный просмотр:",
  Insert:"Вставлять",
  Preview_Import:"Предварительный просмотр импорта",
  Entry:"Вход",
  Entries:"Записи",
  No_Entries:"Нет записей",
  Import_Type:"Тип импорта",
  Select_Details:"Выберите детали",
  Select_Payee:"Выберите получателя",
  Enable_Holidays:"Включить праздники",
  Disable_Holidays:"Отключить праздники",
  Wire_Transfer:"Передача по проводам",
  New_Export:"Новый экспорт",
  Export_Data:"Экспорт данных",
  Export_Data_Word:"Выберите тип файла для экспорта и загрузки.",
  Export_File:"Экспорт файла",
  Mode:"Режим",
  Times:"раз",
  Widgets:"Виджеты",
  Slider:"Слайдер",
  Set_Details:"Установить детали",
  Select_Type:"Выберите тип",
  Display_Slider:"Показать ползунок",
  Dashboard_Slider:"Слайдер приборной панели",
  Dashboard_Mode:"Режим панели",
  Show_Intro:"Показать введение",
  Show_Payrolls:"Показать платежные ведомости",
  Show_Holidays:"Показать праздники",
  Show_Invoices:"Показать счета",
  Show_Cheques:"Показать чеки",
  Enabled_Widgets:"Включенные виджеты",
  Disabled_Widgets:"Отключенные виджеты",
  Colors:"Цвета",
  Barcodes:"Штрих-коды",
  View_Timers:"Просмотр таймеров",
  Gradients:"Градиенты",
  No_Info:"Нет информации",
  Disable:"Запрещать",
  Show_Layer:"Показать слои",
  Hide_Layer:"Скрыть слои",
  Text_Layer:"Текстовые слои",
  Secondly:"Во-вторых",
  Minutely:"Минутно",
  nine_am:"9:00 УТРА",
  five_pm:"5:00 ВЕЧЕРА",
  Enable_Address:"Включить адрес",
  Invalid_File_Type:"Извините, выбран недопустимый тип файла. Поддерживаемый тип файла:",
  Error_Updating_Entry:"Извините, при обновлении этой записи произошла ошибка.",
  Schedule_Timing_Alert:"Ошибка: время начала расписания установлено после времени окончания.",
  Select_Multiple_Payments_Prompt:"Платежи не выбраны. Выберите хотя бы один платеж.",
  Select_Multiple_Cheques_Prompt:"Проверки не выбраны. Пожалуйста, выберите хотя бы одну проверку.",
  Select_Multiple_Items_Prompt:"Элементы не выбраны. Выберите хотя бы один элемент.",
  Paymemt_Multiple_Delete_Confirmation:"Вы уверены, что хотите удалить несколько платежей?",
  Cheque_Multiple_Delete_Confirmation:"Вы уверены, что хотите удалить несколько чеков?",
  Payee_Multiple_Delete_Confirmation:"Вы уверены, что хотите удалить нескольких получателей платежей?",
  Employee_Multiple_Delete_Confirmation:"Вы уверены, что хотите удалить нескольких сотрудников?",
  MICR_Warning:"Примечание. Некоторые принтеры и устройства могут неправильно отображать шрифт MICR.",
  Automatically_Send:"Автоматически отправлять",
  Type:"Тип",
  Payment_Type:"Способ оплаты",
  Auto_Send:"Автоматическая отправка",
  Automatically_Process:"Автоматически обрабатывать",
  Auto_Process:"Автоматический процесс",
  Image_Based:"на основе изображений",
  Font_Based:"На основе шрифта",
  Rerender:"Перерендерить",
  Rendering:"Рендеринг",
  Render:"Файлы",
  Top:"верхний",
  Middle:"Середина",
  Bottom:"Нижний",
  Top_Left:"Верхний левый",
  Top_Center:"Верх Центр",
  Top_Right:"В правом верхнем углу",
  Middle_Left:"Средний левый",
  Middle_Center:"Средний центр",
  Middle_Right:"средний правый",
  Bottom_Left:"Нижняя левая",
  Bottom_Center:"Нижний центр",
  Bottom_Right:"Нижний правый",
  Numbers:"Числа",
  Verified:"проверено",
  Paper_Size:"Размер бумаги",
  New_Device:"Новое устройство",
  Add_Device:"Добавить устройство",
  Remove_Device:"Удалить устройство",
  Delete_Device:"Удалить устройство",
  Block_Device:"Блокировать устройство",
  Block:"Блокировать",
  Unblock:"Разблокировать",
  Table:"Стол",
  Scan_Login_Code:"Сканировать код входа",
  Login_Code:"Код входа",
  Scan_Code:"Сканировать код",
  Scan_QR_Code:"Сканировать QR-код",
  Select_All:"Выбрать все",
  Deselect_All:"Убрать выделение со всего",
  Increase:"Увеличивать",
  Decrease:"Снижаться",
  Bold:"Смелый",
  Text:"Текст",
  Style:"Стиль",
  Italic:"Курсив",
  QR_Code:"QR код",
  Barcode:"Штрих-код",
  Browse_Images:"Просмотр изображений",
  Browse_Files:"Просмотр файлов",
  Background_Image:"Фоновая картинка",
  Logo_Image:"Изображение логотипа",
  Header_Image:"Заглавное изображение",
  Bank_Image:"Изображение банка",
  Cut_Lines:"Линии разреза",
  Background:"Фон",
  License:"Лицензия",
  One_License:"1 лицензия:",
  Licensed:"Лицензия:",
  Not_Licensed:"Не лицензирован",
  Enter_Serial:"Введите серийный номер",
  Serial_Key:"Серийный номер",
  Serial:"Серийный",
  Product_Key:"Ключ продукта",
  Check_Product_Key:"Проверить ключ продукта",
  Add_Product_Key:"Добавить ключ продукта",
  Verifying_Purchase:"Проверка покупки...",
  Print_Envelope:"Распечатать конверт",
  Envelope:"Конверт",
  Thank_You:"Спасибо!",
  Scale:"Шкала",
  Print_Scale:"Печать шкалы",
  Borders:"Границы",
  VOID:"ПУСТОТА",
  Void_Cheque:"Недействительная проверка",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ОПЛАЧИВАТЬ ЗАКАЗ:",
  NO_DOLLARS:"НЕТ ДОЛЛАРОВ ",
  ONE_DOLLAR:"ОДИН ДОЛЛАР",
  DOLLARS:" ДОЛЛАРОВ",
  AND:" А ТАКЖЕ ",
  CENTS:" ЦЕНТОВ.",
  NO_:"НЕТ ",
  ONE_:"ОДИН ",
  AND_NO_:"И НЕТ ",
  _AND_ONE_:"И ОДИН ",
  DOLLARS_AND_ONE_CENT:" И ОДИН ЦЕНТ.",
  AND_NO_CENTS:" И НОЛЬ ЦЕНТОВ.",
  CHEQUE_PRINT_DATE:"СВИДАНИЕ:",
  CHEQUE_PRINT_MEMO:"ПАМЯТКА:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/100",
  CHEQUE_PRINT_SIGNATUREMP:"депутат",
  Initial_Tasks:"Начальные задачи",
  Inital_Setup:"Первоначальная установка",
  Welcome_To:"Добро пожаловать в ",
  Welcome:"Добро пожаловать",
  Swipe:"Свайп",
  Intro_Setup:"Введение",
  Introduction:"Введение",
  CHEQUE_PRINT_CREDIT:"Работает на чеках",
  Title_Intro_Word:"Добро пожаловать в чеки",
  Title_Intro:"Введение в чеки",
  Title_Setup:"Настройка чеков",
  PayPal_Default_Email_Message:"Вы получили новый перевод PayPal. [При поддержке чеков]",
  Cheques_App_Export:"Экспорт по чекам",
  Post_Intro_Thanks:"Спасибо, что выбрали чеки. Процесс настройки завершен.",
  Post_Intro_Word:"Начните с того, что распечатайте свой первый чек, добавьте будущий платеж или добавьте сотрудников в платежную ведомость.",
  Upgrade_Required:"Проверки требуют, чтобы у вас была более премиальная версия программного обеспечения, чтобы скрыть это сообщение и разблокировать дополнительные функции.",
  Upgrade_Title:"Чеки",
  Mailout_Prompt:"Вы также можете выбрать, чтобы Checks рассылал вам чеки по заработной плате по почте.",
  Mailed_Cheque:"Отправленный чек: ",
  Mailed_Cheque_Color:"Чек по почте (цветной): ",
  Terms_Purchase:"Все электронные покупки с помощью чеков подлежат полному возврату в течение 30 дней с даты покупки. Прежде чем продолжить, прочтите <a href='#'>Условия и положения</a> и примите их.",
  Dashboard_Setup:"Настройка основного принтера",
  Dashboard_Add:"Добавить основной банковский счет",
  Dashboard_Customize:"Выберите шаблон чека",
  Dashboard_Job_Salary:"Создай свою работу и добавь свою зарплату",
  Dashboard_Employees:"Отслеживание сотрудников и получателей платежей",
  Dashboard_Print:"Распечатайте и отправьте чеки по почте",
  Dashboard_Payroll:"Автоматизация печати платежных ведомостей",
  Dashboard_PayPal:"Настройка платежной ведомости/выплат PayPal",
  Begin_Setup:"Начать настройку",
  Get_Started:"Начать",
  Purchase:"Покупка",
  Lockdown:"Карантин",
  Unlock:"Разблокировать",
  Detailed:"Подробно",
  Log_In:"Авторизоваться",
  Login:"Авторизоваться",
  Launch:"Запуск",
  Register:"Регистр",
  Finish:"Заканчивать",
  List:"Список",
  Weekends:"Выходные",
  Weekly:"Еженедельно",
  PayPal_Default_Subject:"Новый платеж",
  Payment_Message:"Платежное сообщение",
  PayPal_Default_Payment_Note:"Спасибо",
  Setup_Your_Cheqing_Account:"Расчетный счет",
  Add_Your_Primary_Cheqing_Account:"Добавьте свой основной расчетный счет.",
  Welcome_Word:"Упростите и автоматизируйте расчет заработной платы и управление персоналом.",
  Get_Started_Quickly:"Просто ответьте на несколько простых вопросов, которые помогут нам начать...",
  Done_Intro_Word:"Настройка завершена",
  PIN_Protected:"Защищено паролем и PIN-кодом",
  Enter_New_PIN:"Введите новый PIN-код:",
  Enter_PIN:"Введите PIN-код:",
  Invalid_PIN:"Введен неверный PIN-код.",
  Account_Identifier:"Идентификатор счета",
  New_Account_Identifier:"Идентификатор новой учетной записи",
  attempt:"пытаться",
  attempts:"попытки",
  remaining:"осталось",
  Language:"Язык",
  languages:"Языки",
  select_languages:"Выберите язык",
  Deposit:"Депозит",
  Hire_One_Now:"Найми сейчас",
  App_Locked:"Приложение заблокировано.",
  Skip_:"Пропускать",
  Skip_to_Dashboard:"Перейти к панели управления",
  Dashboard:"Приборная доска",
  Import:"Импортировать",
  Admin:"Администраторы",
  New_Admin:"Новый админ",
  Settings:"Настройки",
  Color_Picker:"Выбор цвета",
  Font_Picker:"Выбор шрифта",
  Logout:"Выйти",
  Close:"Закрывать",
  Close_menu:"Закрывать",
  Excel:"Файл Эксель",
  Csv:"CSV-файл",
  Sql:"SQL-файл",
  Json:"JSON-файл",
  Url:"Импорт по URL",
  Back:"Назад",
  Timers:"Таймеры",
  Cheque:"Проверять",
  Print:"Распечатать",
  Designs:"Дизайн",
  Pause_Printing:"Приостановить печать",
  Resume_Printing:"Возобновить печать",
  Printing_Paused:"Печать приостановлена",
  PrintingUnavailable:"Извините! В этой системе печать недоступна.",
  Prints_Paused:"Печать приостановлена",
  Administration:"Администрация",
  Loading:"Загрузка",
  Unnamed:"Безымянный",
  error:"К сожалению, этот чек не может быть открыт для просмотра.",
  No_Cheques_To_Print:"Нет чеков для печати",
  No_Cheques_To_Print_Word:"Создайте <a routerLink='/folder/Check/New'>новый чек</a>.",
  New_Cheque:"Новый чек",
  Start_One_Now:"Начать сейчас",
  Edit_Cheque:"Редактировать чек",
  Select_Cheques:"Выбрать чеки",
  Select_Employee:"Выбрать сотрудника",
  Default_Printer:"Принтер по умолчанию",
  Reassign:"Переназначить",
  Configure:"Настроить",
  Template:"Шаблон",
  Designer:"Дизайнер",
  Edit_Designs:"Редактировать дизайны",
  New_Design:"Новый дизайн",
  Next:"Следующий",
  Save:"Сохранять",
  Name:"Имя",
  Mail:"Почта",
  Amount:"Количество",
  Date:"Дата",
  PayPal:"ПейПал",
  Payouts:"Выплаты",
  PayPal_Label:"ПейПал Лейбл",
  Email_Username:"Электронная почта/имя пользователя",
  Client_ID:"ID клиента",
  Sandbox_Email:"Песочница Электронная почта",
  PayPal_Email:"Электронная почта PayPal",
  PayPal_Username:"Имя пользователя API",
  PayPal_Payouts:"Выплаты PayPal",
  Select_PayPal_Key:"Выберите ключ PayPal",
  Secret:"Секрет",
  API_Secret:"Секрет API",
  PayPal_API_Keys:"Ключи PayPal",
  New_PayPal_Key:"Новый ключ PayPal",
  Email_Subject:"Тема письма",
  Email_Message:"Сообщение электронной почты",
  Payout_Options:"Варианты выплат",
  Payment_Note:"Платежная записка",
  Enable_Employee:"Включить сотрудника",
  Enable_Production_Mode:"Включить производственный режим",
  Sandbox_Username:"Имя пользователя песочницы",
  Sandbox_Signature:"Подпись песочницы",
  Sandbox_Password:"Пароль песочницы",
  Production_Username:"Производственное имя пользователя",
  Production_Signature:"Производственная подпись",
  Production_Password:"Производственный пароль",
  Production_Email:"Производственная электронная почта",
  API_Client_ID:"Идентификатор клиента API",
  API_Signature:"Подпись API",
  API_Password:"Пароль API",
  API_Username:"Имя пользователя API",
  Secret_Key:"Секретный ключ",
  Sandbox:"Песочница",
  Production:"Производство",
  Sandbox_Keys:"Ключи от песочницы",
  Production_Keys:"Производственные ключи",
  API_Title:"Название API",
  Production_Mode:"Производственный режим",
  Account_Label:"Ярлык аккаунта",
  No_PayPal_Setup:"Нет ключа PayPal",
  Enable_PayPal_Account:"Включить учетную запись PayPal",
  No_PayPal_Word:"Добавьте свой <a routerLink='/folder/Invoice/New'>Ключ API PayPal</a>.",
  Printed_Memo:"Печатная памятка",
  Employee:"Наемный рабочий",
  View_Employee:"Просмотреть сотрудника",
  Mailing_Address:"Электронная почта",
  Company_Address:"Адрес компании",
  Select_Company_Address:"Выберите адрес компании",
  Address:"Адрес",
  Any_Day:"Любой день",
  Address_Name:"Адрес",
  Unit:"Единица измерения",
  Account:"Счет",
  Bank_Account:"Банковский счет",
  Account_Limits:"Включить лимиты учетной записи",
  Payroll:"Начисление заработной платы",
  New_Payroll:"Новая зарплата",
  No_Payroll:"Нет предстоящей заработной платы",
  Upcoming_Holiday:"Предстоящий праздник:",
  Invoice_Due:"Счет к оплате:",
  New_Invoice:"Новый счет",
  No_Invoices:"Нет счетов",
  No_Invoices_Word:"Создайте первый <a routerLink='/folder/Invoice/New'>счет</a>.",
  Cheque_Due:"К оплате:",
  Payrolls:"Заработная плата",
  Sandbox_Mode:"Режим песочницы",
  Hire:"Нанять",
  Pay:"Платить",
  New:"Новый",
  Add:"Добавлять",
  Make:"Сделать",
  Time:"Время",
  Write:"Писать",
  Holiday:"Праздничный день",
  Holidays:"Каникулы",
  Next_Holiday:"Следующий выходной:",
  All_Done:"Все сделано!",
  Employees:"Сотрудники",
  Payees:"Плательщики",
  Job:"Работа",
  Jobs:"Работа",
  Invoice:"Счет",
  Invoices:"Счета",
  Vacations:"Каникулы",
  Cheques:"Чеки",
  Brand_Cheques:"Бренд",
  Payment:"Оплата",
  Enable_Payment:"Включить оплату",
  Payments:"Платежи",
  Schedule:"Расписание",
  Schedules:"Расписания",
  Timesheet:"Табель учета рабочего времени",
  Timesheets:"Табель учета рабочего времени",
  Salary:"Оплата труда",
  New_Address:"Новый адрес",
  Address_2:"Адресная строка 2)",
  _City:"Город",
  _State:"Государственный/Пров",
  City:"Город/поселок",
  State:"Штат/провинция",
  Postal:"Почтовый индекс",
  ZIP:"Почтовый / ИНдекс",
  Country:"Страна",
  Addresses:"Адреса",
  Required_Options:"Обязательные параметры",
  Optional_Options:"Дополнительные параметры",
  Additional_Options:"Дополнительные опции",
  Required:"Необходимые",
  Optional:"По желанию",
  Additional:"Дополнительный",
  No_Addresses:"Нет адресов",
  New_Address_Word:"Добавить первый <a routerLink='/folder/Address/New'>Адрес</a>.",
  Select_Address:"Выберите адрес",
  No_Address:"Нет адресов",
  Print_Cheque:"Распечатать чек",
  Print_Cheque_Now:"Распечатать чек сейчас",
  Description:"Описание",
  Pay_To_The_Order_Of:"Оплата к заказу:",
  Select_Date_Range:"Выберите диапазон дат",
  Select_Starting_Date:"Выберите дату начала",
  Select_Ending_Date:"Выберите дату окончания",
  Select_Date:"Выберите дату",
  Cheque_Date:"Проверить дату",
  Cheque_Memo:"Проверить памятку",
  Blank_Cheque:"Бланк",
  Blank:"Пустой",
  No_Cheques:"Без проверок",
  No_Cheques_Word:"Распечатайте свой первый <a routerLink='/folder/Check/New'>чек</a>.",
  Cheque_Amount_Placeholder:"0,00 долл. США",
  View_Image:"Просмотр изображения",
  View:"Вид",
  Modify:"Изменить",
  Delete:"Удалить",
  Cheque_Paid:"Оплаченный",
  New_Deduction:"Новая дедукция",
  Title:"Заголовок",
  Frequency:"Частота",
  Hourly:"Почасовая",
  Daily:"Ежедневно",
  Weekdays:"Будни",
  BiWeekly:"2 недели",
  TriWeekly:"3 недели",
  Monthly:"Ежемесячно",
  MiMonthly:"2 месяца",
  Quarterly:"Ежеквартальный",
  Yearly:"Ежегодно",
  Every_Week:"Каждую неделю",
  Every_Payroll:"Каждая зарплата",
  Every_Month:"Каждый месяц",
  Annually:"Ежегодно",
  Always_Scheduled:"Всегда по расписанию",
  Start_Date:"Дата начала",
  End_Date:"Дата окончания",
  Start_Time:"Начальное время",
  End_Time:"Время окончания",
  Deduction_Label:"Вычетная этикетка",
  Notes:"Примечания",
  Options:"Опции",
  Enable:"Давать возможность",
  Select_Deductions:"Выбрать отчисления",
  Deductions:"Вычеты",
  No_Deductions:"Без вычетов",
  No_Deductions_Word:"Создайте свой первый <a routerLink='/folder/Deduction/New'>Вычет</a>.",
  New_Employee:"Новый сотрудник",
  No_Title:"Без названия",
  _Name:"Имя",
  About_Yourself:"О себе",
  Full_Name:"Полное имя",
  Birthday:"День рождения",
  Email:"Электронное письмо",
  SMS:"СМС",
  Phone:"Телефон",
  Test:"Контрольная работа",
  Call:"Вызов",
  Fax:"Факс",
  Printed_Note:"Печатная записка",
  Position:"Должность",
  Job_Position:"Место работы",
  Select_a_Job:"Выбрать работу",
  Select_a_Salary:"Выберите заработную плату",
  Add_a_Deduction:"Добавить вычет",
  Taxes:"Налоги",
  Add_Taxes:"Добавить налоги",
  Date_of_Birth:"Дата рождения",
  Email_Address:"Адрес электронной почты",
  Phone_Number:"Телефонный номер",
  Phone_Call:"Телефонный звонок",
  Enable_on_Payroll:"Включить начисление заработной платы",
  Select_Employees:"Выбрать сотрудников",
  No_Employees:"Нет сотрудников",
  No_Employees_Word:"Добавьте своего первого нового <a routerLink='/folder/Employee/New'>сотрудника</a>.",
  No_Name:"Без имени",
  Unemployeed:"Безработные",
  Employeed:"Занятый",
  Paid:"Оплаченный",
  Enabled:"Включено",
  Disabled:"Неполноценный",
  Fire:"Огонь",
  Not_Available:"Недоступно",
  Not_Available_Word:"Распечатайте свой первый <a routerLink='/folder/Invoice/New'>счет</a> и получите оплату.",
  Select_Invoices:"Выбрать_Счета",
  Print_Invoice_Word:"Распечатайте свой первый <a routerLink='/folder/Invoice/New'>счет</a> и получите оплату.",
  Invoice_Title:"Название счета",
  Invoice_Date:"Дата счета",
  Due_Date:"Срок",
  New_Job:"Новая работа",
  Details:"Подробности",
  Customize:"Настроить",
  Customize_Dashboard:"Настроить панель управления",
  Components:"Компоненты",
  No_Components:"Без компонентов",
  Main_Components:"Основные компоненты",
  Smaller_Components:"Мелкие компоненты",
  Error_Loading_Page:"Ошибка загрузки этой страницы.",
  Bank_Details:"Банковские реквизиты",
  About_Your_Job:"О своей работе",
  Your_Schedule:"Твое расписание",
  Job_Title:"Название работы",
  Job_Description:"Описание работы",
  Job_Details:"Детали работы",
  Enable_Job:"Включить работу",
  Pay_Period:"Период оплаты",
  Perpetually_Schedule:"Вечный график",
  Select_Jobs:"Выбрать работу",
  No_Jobs:"Нет работы",
  Add_Jobs:"Добавить работу",
  No_Jobs_Word:"Добавить первое <a routerLink='/folder/Job/New'>Работа</a> в список.",
  Count_Employees:"job.employee_count+'Сотрудники'",
  Zero_Employees:"0 сотрудников",
  New_Leave:"Новый отпуск",
  Leave_Name:"Оставить имя",
  Paid_Leave:"Оплачиваемый отпуск",
  Leave_Pay:"Оплата отпуска",
  Indefinite_Leave:"Бессрочный отпуск",
  Indefinite_Payroll:"Бессрочная заработная плата",
  Leave:"Уехать",
  Add_Schedules:"Добавить расписания",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Включить отсутствие",
  Select_Leaves:"Выбрать листья",
  No_Leaves:"Нет листьев отсутствия",
  Leave_Of_Absence:"Отпуск",
  Leaves_Of_Absence:"Листья отсутствия",
  New_Leave_of_Absense:"Новый отпуск",
  No_Leaves_Word:"Добавить первый <a routerLink='/folder/Leave/New'>отпуск</a>.",
  Not_Enabled:"Не включено",
  Absences:"Отсутствия",
  Payee:"Плательщик",
  New_Payee:"Новый получатель",
  Payee_Identifier:"Идентификатор получателя",
  Payee_Name:"Имя получателя",
  Payee_Title:"Название получателя платежа",
  Payment_Memo:"Платежная записка",
  Select_Payees:"Выбрать получателя",
  No_Payees:"Нет получателей",
  Add_Payee_Note:"Добавить первого <a routerLink='/folder/Payee/New'>Получателя</a>.",
  New_Payees:"Новые получатели",
  About_The_Payment_Schedule:"График платежей",
  Your_Payroll_Schedule:"Автоматическая зарплата",
  New_Payment:"Новый платеж",
  Identifier:"Идентификатор",
  Select:"Выбирать",
  Memo:"Памятка",
  Payment_Date:"Дата платежа",
  Mark_as_Paid:"Отметить как оплаченный",
  Select_Payments:"Выбрать платежи",
  No_Payments:"Без платежей",
  No_Payments_Word:"Создайте первый <a routerLink='/folder/Payment/New'>платеж</a>.",
  Create_Payroll:"Создать заработную плату",
  Properties:"Характеристики",
  Payroll_Title:"Зарплатная ведомость",
  Payroll_Notes:"Заработная плата",
  Payroll_Setup:"Настройка заработной платы",
  Tabulate_Payments:"Таблица платежей",
  Tabulate:"Таблировать",
  By:"От:",
  Payments_By:"Платежи по",
  Timesheets_And_Schedules:"Табель учета рабочего времени и расписания",
  Both:"Оба",
  Items:"Предметы",
  Add_Payees:"Добавить получателей платежа",
  Add_Account:"Добавить аккаунт",
  Enable_Account:"Включить учетную запись",
  Enable_Payroll:"Включить начисление заработной платы",
  Print_Payroll:"Распечатать платежную ведомость",
  No_Payrolls:"Нет зарплаты",
  No_Payroll_Word:"Создайте свою первую <a routerLink='/folder/Payroll/New'>зарплату</a>.",
  View_more:"СМОТРЕТЬ_БОЛЬШЕ",
  Less:"МЕНЬШЕ",
  Add_Payroll:"Добавить зарплату",
  Select_Payroll:"Выбрать заработную плату",
  About_Your_Salary:"О вашей зарплате",
  Add_Salaries:"Добавить зарплату",
  Add_Salary:"Добавить зарплату",
  Salaries:"Зарплата",
  No_Salaries:"Нет зарплат",
  No_Salaries_Word:"Добавить первую <a routerLink='/folder/Salary/New'>зарплату</a>.",
  Select_Salaries:"Выбрать заработную плату",
  New_Salary:"Новая зарплата",
  Salary_Name:"Идентификатор зарплаты",
  Enable_Salary:"Включить зарплату",
  Salary_Amount:"Сумма заработной платы",
  New_Schedule:"Новое расписание",
  Schedule_Title:"Заголовок расписания",
  Add_Address:"Добавить адрес",
  Repeat:"Повторение",
  Design:"Дизайн",
  Edit_Design:"Редактировать дизайн",
  Edit_this_Design:"Редактировать этот дизайн",
  Repeat_Payment:"Повторить платеж",
  Enable_Schedule:"Включить расписание",
  Never:"Никогда",
  Select_Schedule:"Выбрать расписание",
  No_Schedules:"Без расписания",
  No_Schedules_Word:"Создайте первое <a routerLink='/folder/Schedule/New'>Расписание</a>.",
  New_Administrator:"Новый администратор",
  Username:"Имя пользователя",
  First_Name:"Имя",
  Last_Name:"Фамилия",
  Add_an_Address:"Добавить адрес",
  Payment_Limit:"Лимит на платеж",
  Total_Limit:"Общий лимит",
  Select_Accounts:"Выбрать аккаунты",
  No_Administrators:"Нет администраторов",
  No_Administrators_Word:"Создайте первую <a routerLink='/folder/Administrator/New'>учетную запись администратора</a>.",
  New_Administrators_Word:"Добавить первого <a routerLink='/folder/Administrator/New'>администратора</a>",
  Cloud:"Облако",
  Backup:"Резервный",
  Enable_iCloud:"Включить iCloud",
  Enable_Google_Drive:"Включить Google Диск",
  Enable_Microsoft_OneDrive:"Включить Microsoft OneDrive",
  Automatically_Backup:"Автоматическое резервное копирование",
  FTP_Settings:"Настройки FTP",
  URL_File_Prompt:"Укажите расположение файла .xls / .xlsx / .json для импорта:",
  URL_SQL_Prompt:"Укажите расположение файла SQLite для импорта:",
  FTP_Backup:"ФТП резервное копирование",
  FTP_Import:"Импорт FTP",
  FTP:"ФТП",
  SFTP:"SFTP",
  Host:"Хозяин",
  Port:"Порт",
  Path:"Дорожка",
  Data_Path:"Путь к данным",
  Enable_FTP_Account:"Включить учетную запись FTP",
  HostnameIP:"Имя хоста",
  Password:"Пароль",
  Connection_Port:"Порт подключения",
  Enable_MySQL_Database:"Включить базу данных MySQL",
  Log:"Бревно",
  Reset:"Перезагрузить",
  Erase:"Стереть",
  Export:"Экспорт",
  Database:"База данных",
  Upload_CSV:"Загрузить CSV",
  Download_CSV:"Скачать CSV",
  SQL_Database:"URL-адрес SQL",
  MySQL_Database:"Майкл",
  MySQL_Backup:"Резервное копирование MySQL",
  Internal_Notes:"Внутренние заметки",
  Root_Path:"Корневой путь",
  Select_Backup:"Выбрать резервную копию",
  Add_New_Backup:"Добавить новую резервную копию",
  First_Backup:"Настроить первую резервную копию...",
  Backups:"Резервные копии",
  Add_Backup:"Добавить резервную копию",
  No_Backups:"Резервные копии не найдены.",
  Select_Backup_Type:"Выберите тип резервного копирования, которое вы хотите настроить...",
  Backup_Type:"Тип резервной копии",
  FTP_Drive:"ФТП диск",
  URL_Location:"URL",
  Apple_iCloud:"айклауд",
  Google_Drive:"Водить машину",
  Microsoft_OneDrive:"Водить машину",
  Import_Fields:"Импорт полей",
  Import_Fields_Word:"Используйте этот раздел, чтобы выбрать <a routerLink='/folder/Holiday/New'>Импортировать</a> данные.",
  Upload:"Загрузить",
  Download:"Скачать",
  Download_JSON:"Скачать как данные JSON",
  Download_SQL:"Загрузить как файл SQL",
  New_Bank:"Новый банк",
  New_Account:"Новый аккаунт",
  New_Bank_Account:"Новый банковский счет",
  Upload_Image:"Загрузить изображение",
  Bank_Name:"Название банка",
  Bank_Address:"Адрес банка",
  Branch_Address:"Адрес отделения",
  Address_on_Cheque:"Адрес",
  Cheque_Numbers:"Проверить номера",
  Cheque_Details:"Проверить детали",
  Bank_Logo:"Лого банка",
  Cheque_ID:"Проверить ID",
  Starting_Cheque_ID:"Идентификатор запуска проверки",
  Transit_Number:"Транзитный номер",
  Institution_Number:"Номер учреждения",
  Designation_Number:"Номер обозначения",
  Account_Number:"Номер счета",
  Limits:"Лимиты",
  Default_Limits:"Ограничения по умолчанию",
  Over_Limit:"Свыше лимита",
  Under_Limit:"Под лимитом",
  Payroll_Limit:"Лимит заработной платы",
  Enable_Bank_Account:"Включить банковский счет",
  Select_Account:"Выбрать аккаунт",
  No_Bank_Account:"Нет счета в банке",
  No_Bank_Account_Word:"Добавить первый <a routerLink='/folder/Accounts/New'>банковский счет</a>.",
  Bank_Accounts:"Банковские счета",
  No_Accounts:"Нет счетов",
  No_Accounts_Note:"Добавить первый <a routerLink='/folder/Accounts/New'>банковский счет</a>.",
  Cheque_Designer:"Конструктор чеков",
  Cheque_Design:"Проверить дизайн",
  Select_Design:"Выберите дизайн",
  Cheque_Designs:"Проверить дизайн",
  No_Cheque_Designs:"Конструкции без чеков",
  No_Cheque_Designs_Word:"Создайте свой собственный <a routerLink='/folder/Settings/Check/Design/New'>Check Design</a>.",
  Set_Default:"Установить по умолчанию",
  Default:"Дефолт",
  Remove:"Удалять",
  Folder:"Папка",
  Edit:"Редактировать",
  LoadingDots:"Загружается...",
  Add_a_New_File:"Добавить <a href='#' (click)='add()'>новый файл</a> в",
  this_folder:"эта папка",
  FTP_Backup_Settings:"Настройки резервного копирования FTP",
  Secure_File_Transfer:"Безопасная передача файлов",
  New_Holiday:"Новый праздник",
  Add_Holiday:"Добавить праздник",
  Holiday_Name:"Имя праздника",
  Additional_Pay:"Дополнительная оплата",
  Enable_Holiday:"Включить праздник",
  Select_Holidays:"Выбрать праздники",
  No_Holidays:"Без праздников",
  No_Holidays_Word:"Добавить первый <a routerLink='/folder/Holiday/New'>праздничный день</a>.",
  New_Import:"Новый Импорт",
  Import_Data:"Импорт данных",
  Import_Data_Word:"Выберите тип файла или способ загрузки по вашему выбору.<br /> После загрузки поддерживаемого файла вы сможете выбрать любые импортированные поля в файле, соответствующие любому параметру в приложении.", 
  Import_File:"Импортировать файл",
  Link_To_File:"Ссылка на файл",
  Select_File:"Выбрать файл",
  New_Moderator:"Новый модератор",
  Allow_Moderation:"Разрешить модерацию",
  Create_Paycheques:"Создать зарплату",
  Edit_Paycheques_and_Data:"Редактировать зарплаты и данные",
  Destroy_Data_and_Paycheques:"Уничтожить данные и зарплаты",
  Bonus_Percentage:"Процент зарплаты",
  Fixed_Amount:"Фиксированное количество",
  Select_Moderator:"Выбрать модератора",
  No_Moderators:"Без модераторов",
  Moderators:"Модераторы",
  Moderator_Account:"Создайте первую <a routerLink='/folder/Administrator/New'>учетную запись модератора</a>.",
  No_Moderators_Word:"Добавить первого <a routerLink='/folder/Administrator/New'>Модератора</a>.",
  Defaults:"По умолчанию",
  Provide_Us_Info:"Предоставьте нам информацию",
  Setup_Your_Printer:"Настройте принтер",
  Your_Company:"О вашей компании",
  Company_Name:"Название компании",
  Currency:"Валюта",
  Default_Currency:"Валюта по умолчанию",
  Base_Monthly_Income:"Ежемесячный доход",
  Protection:"Защита",
  App_Protection:"Защита приложений",
  PIN_Code_Protection:"Защита PIN-кода",
  App_Protection_Word:"Включите методы безопасности, помогающие защитить вашу учетную запись.",
  PIN_Code:"Пин-код",
  Change_PIN:"Изменить PIN-код",
  New_Password:"Новый пароль",
  Geofence_Protection:"Защита от геозабора",
  Geofence_Area:"Установить площадь",
  Distance:"Расстояние",
  Setup_Now:"Настроить сейчас",
  Mile:"Миля",
  Km:"км",
  m:"м",
  Facial_Recognition:"Распознавание лиц",
  Face:"Лицо",
  Setup:"Настраивать",
  Label:"Этикетка",
  Password_Protection:"Защита паролем",
  Modify_Password:"Изменить пароль",
  New_Tax_Entry:"Новая налоговая декларация",
  New_Tax:"Новый налог",
  Tax_Label:"Налоговая этикетка",
  Perpetually_Enabled:"Постоянно включен",
  Select_Taxes:"Выберите налоги",
  Tax_Deductions:"Налоговые вычеты",
  No_Tax_Deductions:"Нет налоговых вычетов",
  No_Tax_Deductions_Word:"Добавить первый <a routerLink='/folder/Tax/New'>налоговый</a> вычет.",
  New_Timer:"Новый таймер",
  Start:"Начинать",
  Stop:"Останавливаться",
  Start_Timer:"Запуск таймера",
  Stop_Timer:"Остановить таймер",
  Timer_Active:"Таймер активен",
  Timer:"Таймер:",
  Timer_Running:"Таймер: (работает)",
  Save_Timer:"Таймер сохранения",
  New_Timesheet:"Новый табель учета рабочего времени",
  Select_Timesheets:"Выбрать табели учета рабочего времени",
  Work_Notes:"Рабочие заметки",
  Entry_Title:"Название записи",
  No_Timesheets:"Нет табель учета рабочего времени",
  No_Timesheets_Word:"Добавить первый <a routerLink='/folder/Timesheet/New'>Табель учета рабочего времени</a>.",
  Timesheet_Submitted:"Табель учета рабочего времени отправлен",
  Timesheet_Congrats:"Поздравляем! Табель учета рабочего времени успешно отправлен. Спасибо!",
  Timesheet_Copy:"Чтобы получить копию ваших документов, сообщите нам свой адрес электронной почты и/или номер мобильного телефона.",
  Submit:"Представлять на рассмотрение",
  Allow_Notifications:"Разрешить уведомления",
  Untitled_Entry:"Новая запись",
  Untitled_Bank:"Безымянный банк",
  Timesheet_Entry:"Ввод в табель учета рабочего времени",
  Work_Description:"Описание Работы",
  Enable_Timesheet:"Включить табель учета рабочего времени",
  Submit_Timesheet:"Отправить табель учета рабочего времени",
  Vacation:"Отпуск",
  New_Vacation:"Новые каникулы",
  Vacation_Name:"Имя отпуска",
  Paid_Vacation:"Оплачиваемый отпуск",
  Vacation_Pay:"Отпускные",
  Enable_Vacation:"Включить отпуск",
  Select_Vacations:"Выбрать отпуск",
  No_Vacations:"Без отпусков",
  No_Vacations_Word:"Создайте первую запись <a routerLink='/folder/Vacation/New'>Отпуск</a>.",
  Payroll_Schedule:"График заработной платы",
  Next_Payroll:"Следующая зарплата:",
  Upcoming_Payroll:"Предстоящий расчет заработной платы",
  No_Upcoming_Payroll:"Нет предстоящей заработной платы",
  Address_Book:"Адресная книга",
  Archived_Documents:"Архивные документы",
  Dev_Mode:"Приложение в режиме разработки",
  Administrators:"Администраторы",
  Privacy:"Конфиденциальность",
  None:"Никто",
  Select_Signature:"Выберите подпись",
  No_Signatures:"Без подписи",
  No_Signatures_Word:"Добавить первую <a routerLink='/folder/Signature/New'>Подпись</a>.",
  Repeat_Indefinitely:"Повторять бесконечно",
  Sign:"Подписать",
  Sign_Here:"Подпишите здесь",
  Date_Signed:"Дата подписания",
  Signature_Pad:"Подписной блокнот",
  Account_Holder:"Владелец счета",
  Account_Properties:"Свойства учетной записи",
  Name_On_Cheque:"Имя на чеке",
  Server_Hostname:"Имя хоста сервера/IP",
  Printers:"Принтеры",
  No_Printers:"Нет принтеров",
  Printer_Exists:'Принтер с таким именем уже существует.',
  No_Printers_Word:"Добавить самый первый <a routerLink='/folder/Printer/New'>принтер</a>.",
  No_Printer_Alert:"Принтер не определен. Хотите настроить принтер?",
  Add_Printer:"Добавить принтер",
  New_Printer:"Новый принтер",
  Printer_Hostname:"Имя хоста принтера/IP",
  Printer_Label:"Этикетка принтера",
  Printer_Protocol:"Протокол принтера",
  Protocol:"Протокол",
  Email_Print:"Электронное письмо",
  AirPrint:"ЭйрПринт",
  IPP:"ИПП",
  LPD:"ЛПД",
  HPJetDirect:"Разъем",
  Print_Job:"Распечатать задание",
  Printed:"Напечатанный",
  Not_Printed:"Не напечатано",
  Print_Jobs:"Задания на печать",
  Print_Queue:"Очередь печати",
  No_Print_Jobs:"Нет заданий на печать",
  No_Prints:"Создайте новый <a routerLink='/folder/Cheque/New'>Чек</a> для печати.",
  Prints:"Принты",
  Find_Printer:"Найти принтер",
  Find_AirPrint_Devices:"Найти устройства AirPrint",
  Select_Printer:"Выберите принтер",
  System_UI:"Системный интерфейс",
  Printer:"Принтер",
  Status:"Статус",
  Preview:"Предварительный просмотр",
  Enable_Print_Job:"Включить задание на печать",
  Queue_Weight:"Вес очереди",
  Unlimited:"Безлимитный",
  Show_Advanced_Printer_Options:"Показать дополнительные параметры принтера",
  Advanced:"Передовой",
  Location:"Место расположения",
  Note:"Примечание",
  Queue_Name:"Имя очереди",
  Pages_Printed_Limit:"Лимит распечатанных страниц",
  MultiPage_Idle_Time:"Время ожидания многостраничного (с)",
  Page_Count_Limit:"Ограничение количества страниц",
  Page_Orientation:"Ориентация страницы",
  Portrait:"Портрет",
  Landscape:"Пейзаж",
  Duplex:"Дуплекс",
  Double_Sided_Printing:"Двухсторонний",
  Duplex_Mode:"Дуплексный режим",
  Duplex_Short:"Короткий",
  Duplex_Long:"Длинная",
  Duplex_None:"Никто",
  Color_And_Quality:"Цвет и качество",
  Monochrome:"Монохромный",
  Photo_Quality_Prints:"Отпечатки фотокачества",
  Printer_Email:"Электронная почта принтера",
  Automatically_Downsize:"Автоматически уменьшать размер",
  Paper:"Бумага",
  Paper_Name:"Имя бумаги",
  Paper_Width:"Ширина бумаги",
  Paper_Height:"Высота бумаги",
  Paper_Autocut_Length:"Длина автоматической обрезки бумаги",
  Margins:"Поля",
  Page_Margins:"Поля страницы",
  Page_Margin_Top:"Верхнее поле страницы",
  Page_Margin_Right:"Правое поле страницы",
  Page_Margin_Bottom:"Нижнее поле страницы",
  Page_Margin_Left:"Левое поле страницы",
  Add_Employees:"Добавить сотрудников",
  Header:"Заголовок",
  Print_A_Page_Header:"Печать заголовка страницы",
  Header_Label:"Ярлык заголовка",
  Header_Page_Index:"Указатель страницы заголовка",
  Header_Font_Name:"Шрифт заголовка",
  Select_Font:"Выбрать шрифт",
  Font_Selector:"Выбор шрифта",
  Header_Font_Size:"Шрифт заголовка",
  Header_Bold:"Заголовок полужирный",
  Header_Italic:"Заголовок курсивом",
  Header_Alignment:"Выравнивание заголовка",
  Left:"Левый",
  Center:"Центр",
  Right:"Правильно",
  Justified:"Оправданный",
  Header_Font_Color:"Цвет заголовка",
  Select_Color:"Выбрать цвет",
  Footer:"Нижний колонтитул",
  Print_A_Page_Footer:"Печать нижнего колонтитула страницы",
  Footer_Label:"Ярлык нижнего колонтитула",
  Footer_Page_Index:"Индекс страницы нижнего колонтитула",
  Footer_Font_Name:"Шрифт нижнего колонтитула",
  Fonts:"Шрифты",
  Done:"Сделанный",
  Select_Fonts:"Выбрать шрифты",
  Footer_Font_Size:"Размер нижнего колонтитула",
  Footer_Bold:"Нижний колонтитул полужирный",
  Footer_Italic:"Нижний колонтитул курсивом",
  Footer_Alignment:"Выравнивание нижнего колонтитула",
  Footer_Font_Color:"Цвет нижнего колонтитула",
  Page_Copies:"Копии страниц",
  Enable_Printer:"Включить принтер",
  Remote_Logging:"Удаленное ведение журнала",
  Log_Server:"Сервер журналов",
  Encryption:"Шифрование",
  Random_Key:"Случайный ключ",
  Encryption_Key:"Ключ шифрования",
  Cheques_Webserver:"Пользовательская база данных",
  Learn_How:"Узнать, как",
  Signature:"Подпись",
  Default_Printer_Unit:"дюйм/см/мм/(т)",
  View_Signature:"Просмотреть подпись",
  Printed_Signature:"Печатная подпись",
  Digitally_Sign:"Цифровой знак",
  Digital_Signature:"Цифровой подписи",
  Add_Signature:"Добавить подпись",
  Add_Your_Signature:"Добавь свою подпись",
  Enable_Signature:"Включить подпись",
  Digitally_Signed:"Цифровая подпись",
  Insert_Error:"Не удалось сохранить чек из-за проблем с базой данных.",
  Delete_Confirmation:"Вы уверены, что хотите удалить эту информацию?",
  Discard_Confirmation:"Вы уверены, что хотите удалить эту информацию?",
  Discard_Bank_Confirmation:"Вы уверены, что хотите удалить эту учетную запись?",
  Discard_Printer_Confirmation:"Вы уверены, что хотите отказаться от этого принтера?",
  Delete_Bonus_Confirmation:"Вы уверены, что хотите удалить этот бонус?",
  Delete_Invoice_Confirmation:"Вы уверены, что хотите удалить этот счет?",
  Generated_Cheque:"Сгенерированный чек",
  Generated_Invoice:"Сформированный счет-фактура",
  Schedule_Start_Time:"Расписание начала",
  Schedule_End_Time:"Конец расписания",
  New_Call:"Новый вызов",
  No_Contacts:"Нет контактов",
  No_Contacts_Word:"Администраторы, модераторы, сотрудники и получатели платежей отображаются как контакты.",
  PDF_Subject:"финансы",
  PDF_Keywords:"чек о зарплате в формате PDF",
  Printer_Setup:"Настройка принтера",
  Printer_Selection:"Выбор принтера",
  New_Fax:"Новый факс",
  New_Fax_Message:"Новое факсимильное сообщение",
  Fax_Machine:"Факс",
  Fax_Name:"Имя факса",
  Fax_Email:"Факс электронной почты",
  Fax_Number:"Номер факса",
  Contents:"Содержание",
  Fax_Body:"Тело страницы",
  Header_Word:"Заголовок:",
  Header_Text:"Текст заголовка",
  Include_Header:"Включить заголовок",
  Include_Footer:"Включить нижний колонтитул",
  Footer_Word:"Нижний колонтитул:",
  Footer_Text:"Текст нижнего колонтитула",
  Attach_a_Cheque:"Приложить чек",
  Add_Deduction:"Добавить вычет",
  Enable_Fax:"Отправить факс",
  Select_Fax:"Выбрать факс",
  No_Faxes:"Нет факсов",
  Faxes:"Факсы",
  Save_and_Send:"Отправить факс",
  Save_and_Pay:"Экономь и плати",
  WARNING:"ПРЕДУПРЕЖДЕНИЕ:",
  Remember:"Помните",
  Printing:"Печать",
  Printing_Complete:"Печать завершена!\n\n",
  of:"из",
  There_Were:"Существовал ",
  Successful_Prints:"удачные отпечатки и ",
  Unsuccessful_Prints:"неудачные отпечатки.",
  PrinterError:"Извините произошла ошибка.",
  Printing_:"Печать...",
  PrinterSuccess:"Документ успешно распечатан.",
  PrintersSuccess:"Документы успешно распечатаны.",
  Print_Cheques:"Печать чеков",
  New_Message:"Новое сообщение",
  New_Messages:"Новые сообщения",
  Read_Message:"Прочитать сообщение",
  Write_Message:"Написать сообщение",
  Send_Message:"Отправить сообщение",
  Subject:"Предмет",
  Message:"Сообщение",
  Writing:"Письмо...",
  Send:"Отправлять",
  Set_Date:"Установить дату",
  Set_Time:"Установить время",
  Recieve:"Получить",
  Set_as_Default:"Установить по умолчанию",
  Default_Account:"Аккаунт по умолчанию",
  Default_Design:"Дизайн по умолчанию",
  Multiple_Cheques:"Чеки (тройные)",
  Account_Mode:"Режим счета",
  Multiple_Cheques_Description:"Три проверки на страницу.",
  Check_and_Table:"Чек и таблица",
  Check_including_Table:"Проверка и учетная таблица.",
  Check_Mailer:"Проверить почтовую программу",
  Check_Mailer_Window:"Проверить с адресным окном.",
  DocuGard_Table_Top:"Проверка и таблица DocuGard (вверху)",
  DocuGard_Table_Middle:"Проверка и таблица DocuGard (средний)",
  DocuGard_Table_Bottom:"Проверка и таблица DocuGard (внизу)",
  DocuGard_Mailer_Top:"Почтовик чеков DocuGard (вверху)",
  DocuGard_Mailer_Middle:"Почтовик чеков DocuGard (средний)",
  DocuGard_Mailer_Bottom:"Почтовик чеков DocuGard (внизу)",
  DocuGard_Three_Cheques:"Проверки DocuGard (тройные)",
  DocuGard_Cheque_Top:"Проверка DocuGard (Вверху)",
  DocuGard_Cheque_Middle:"Проверка DocuGard (средняя)",
  DocuGard_Cheque_Bottom:"Проверка DocuGard (внизу)",
  DocuGard_Three_Cheques_Window:"Три чека на одной странице.",
  DocuGard_Table_Top_Window:"Таблица чеков и доходов.",
  DocuGard_Table_Middle_Window:"Таблица чеков и доходов.",
  DocuGard_Table_Bottom_Window:"Таблица чеков и доходов.",
  DocuGard_Mailer_Top_Window:"Чек, таблица и адрес.",
  DocuGard_Mailer_Middle_Window:"Чек, таблица и адрес.",
  DocuGard_Mailer_Bottom_Window:"Чек, таблица и адрес.",
  DocuGard_Cheque_Top_Window:"Проверить защищенную бумагу.",
  DocuGard_Cheque_Middle_Window:"Проверить защищенную бумагу.",
  DocuGard_Cheque_Bottom_Window:"Проверить защищенную бумагу.",
  Basic_Cheque:"Проверить (Верх)",
  Basic_Cheque_Print:"Распечатать один чек.",
  Error_Setting_As_Paid:"Ошибка установки как оплаченного",
  Add_Attachment:"Добавить приложение",
  PrinterUnavailable:"Принтер недоступен",
  CreditCardComponent:"Открытки",
  PaypalComponent:"ПейПал",
  InteracComponent:"Интерак",
  BitcoinComponent:"Биткойн",
  New_Deposit:"Новый депозит",
  Deposits:"Депозиты",
  No_Deposits:"Без депозита",
  Credit_Card_Deposit:"Кредитная карта",
  New_Credit_Card_Deposit_Message:"Депозит кредитной карты",
  New_BitCoin_Deposit:"Биткойн",
  New_BitCoin_Deposit_Message:"Депозит Биткоин",
  New_Interac_Deposit:"Интерак",
  New_Interac_Deposit_Message:"Интерак Трансфер",
  Payments_Limit:"Лимит платежа",
  No_Payment_Limit:"Без лимита платежа",
  PayPal_Deposit:"ПейПал",
  PayPal_Deposit_Message:"Депозит PayPal",
  No_Deposits_Word:"Добавить первую компанию <a routerLink='/folder/Deposit/New'>Депозит</a>.",
  No_Documents_Specified:"Не указаны документы для печати",
  No_Printers_Added:"Принтеры не найдены. Выберите Настройки > Принтеры, чтобы добавить их.",
  DB_Erase_Prompt:"Полностью стереть всю базу данных? ВНИМАНИЕ: Вы потеряете всю сохраненную информацию!",
  Console_Warning:"Не вставляйте текст в эту консоль. Вы можете подвергнуть себя и/или свою компанию серьезному риску.",
  No_Faxes_Word:"Создайте первый <a routerLink='/folder/Fax/New'>факс</a>.",
  Cheque_Delete_Confirmation:"Вы уверены, что хотите удалить этот чек?",
  Design_Delete_Confirmation:"Вы уверены, что хотите удалить этот дизайн?",
  Cheque_Pay_Confirmation:"Отметить этот чек как оплаченный? Он НЕ появится в очереди на печать.",
  Payment_Pay_Confirmation:"Отметить этот платеж как оплаченный? Он НЕ появится в очереди проверки.",
  Delete_Deduction_Confirmation:"Вы уверены, что хотите удалить этот вычет?",
  Delete_Job_Confirmation:"Вы уверены, что хотите удалить эту вакансию?",
  Delete_Timesheet_Confirmation:"Вы уверены, что хотите удалить этот табель?",
  Delete_Schedule_Confirmation:"Вы уверены, что хотите удалить это расписание?",
  Delete_Setting_Confirmation:"Вы уверены, что хотите сбросить эту настройку?",
  Delete_Fax_Confirmation:"Вы уверены, что хотите удалить этот факс?",
  Delete_File_Confirmation:"Вы уверены, что хотите удалить этот файл?",
  Delete_Vacation_Confirmation:"Вы уверены, что хотите удалить этот отпуск?",
  Delete_Printer_Confirmation:"Вы уверены, что хотите удалить этот принтер?",
  Remove_Design_Confirmation:"Вы уверены, что хотите удалить этот дизайн?",
  Delete_Payroll_Confirmation:"Вы уверены, что хотите удалить эту платежную ведомость?",
  Send_Fax_Email_Confirmation:"Вы хотите отправить эти документы по факсу и электронной почте?",
  Send_Email_Confirmation:"Вы хотите отправить этот документ по электронной почте?",
  Send_Fax_Confirmation:"Вы хотите отправить этот документ по факсу?",
  Error_Generating_PDF:"Извините. При создании этого документа произошла ошибка.",
  PDF_Error_Saving_Image:"Извините. Произошла ошибка при сохранении PDF-изображения этого документа.",
  Test_Printer_Confirmation:"Хотите распечатать тестовую страницу на этом принтере?",
  Save_Timesheet_Prompt:"Пожалуйста, добавьте Заголовок или нажмите Запустить таймер, чтобы сохранить.",
  Remove_Geofence_Prompt:"Вы уверены, что хотите удалить местоположение этой геозоны?",
  Delete_Employee_Confirmation:"Вы уверены, что хотите удалить ",
  Fire_Employee_Confirmation:"Вы уверены, что хотите стрелять"
}