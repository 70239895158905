export const Da = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Copyright &copy; 2023",
  black:"Sort",
  green:"Grøn",
  gold:"Guld",
  blue:"Blå",
  brown:"Brun",
  purple:"Lilla",
  pink:"Lyserød",
  red:"Rød",
  Swatches:"Swatches",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Hue",
  Saturation:"Mætning",
  Lightness:"Lethed",
  Upgrade_To_Pro:"Opgrader til Pro",
  AllFeaturesInclude:"Alle funktioner inkluderer:",
  PrintUnlimitedCheques:"Udskriv ubegrænsede checks",
  PremiumChequeDesigns:"Premium Check Designs",
  ManageUnlimitedEmployees:"Administrer ubegrænset antal medarbejdere",
  AddUnlimitedPayees:"Tilføj ubegrænsede betalingsmodtagere",
  CreateUnlimitedPayrolls:"Opret ubegrænsede lønninger",
  UnlimitedSchedulesandTimeSheets:"Ubegrænset tidsplaner og timesedler",
  BulkPayPalPayouts:"Bulk PayPal-udbetalinger",
  UnlimitedBankAccounts:"Ubegrænset bankkonti",
  ManageMultipleCompanies:"Administrer flere virksomheder",
  TrackInsurancePolicies:"Spor forsikringspolicer",
  Bio_MetricProtection:"Biometrisk beskyttelse",
  Geo_FenceLock_OutProtection:"Geo-Fence Lock-Out beskyttelse",
  Multiple_Companies_Word:"Det er ikke muligt at administrere flere virksomheder uden Checks-præmien.",
  PayPal_Payouts_Word:"PayPal-betalinger er deaktiveret uden Checks-præmie.",
  PINProtection:"PIN-beskyttelse",
  PasswordProtection:"Adgangskodebeskyttelse",
  May_Require_Approval:"Kan kræve godkendelse.",
  Subscribe:"Abonner",
  Billed:"Faktureret",
  Up:"Op",
  Down:"ned",
  Positioning:"Positionering",
  Marker:"Markør",
  Drag_Marker:"Træk markør",
  Tagline:"Udskriv checks og tabuler løn",
  Marker_Word:"Brug markørerne til at dimensionere elementet.",
  Pinch_Zoom:"Knib zoom",
  Pinch_Word:"Knib for at zoome elementet.",
  Drag:"Træk",
  Drag_Word:"Brug din finger til at trække elementer.",
  subscription_alias_word:"Automatisk fornyelse af abonnement",
  premium_alias_word:"Engangsopgraderingspakke",
  print_alias_word:"Udskriv individuelle checks",
  mode_alias_word:"Mode",
  Pro:"Pro",
  Pro_word:"Pro version påkrævet.",
  Cant_Delete_Default_Company_Word:"Beklager, du kan ikke slette dit standardfirma.",
  Reinsert_Default_Designs:"Indsæt standarddesign igen",
  Reinsert_Default_Designs_word:"Vil du genindsætte standarddesigns?",
  Subscription_Active_Disable_Word:"Dette abonnement er aktivt. Vil du opsige dette abonnement på Cheques?",
  Company_Logo:"Firmalogo",
  ZERO_:"NUL",
  Disclaimer:"Ansvarsfraskrivelse",
  Privacy_Policy:"Fortrolighedspolitik",
  EULA:"Tjek EULA",
  Terms_Of_Service:"Servicevilkår",
  Terms_Of_Use:"Vilkår for brug",
  Refunds:"Tilbagebetalings politik",
  Single_Print:"1 Tjek",
  Two_Prints:"2 checks",
  Five_Prints:"5 checks",
  Ten_Prints:"10 checks",
  Fifteen_Prints:"15 checks",
  Twenty_Prints:"20 checks",
  Thirty_Prints:"30 checks",
  Image_Added:"Billede tilføjet",
  Image_Preview:"Billedforhåndsvisning",
  No_Image_Was_Selected:"Intet billede blev valgt.",
  Cheques_Unlimited:"Checks Ubegrænset",
  _Subscription:"Abonnement",
  Subscription:"Checks - 1 måned",
  Two_Month_Subscription:"Checks - 2 måneder",
  Three_Month_Subscription:"Checks - 3 måneder",
  Six_Month_Subscription:"Checks - 6 måneder",
  Twelve_Month_Subscription:"Checks - 12 måneder",
  Cheques_Are_Available:"Checks er tilgængelige til udskrivning.",
  Upgrade_Required_Two:"Vælg en pakke, og dobbelttryk på prisknappen for at starte dit køb. Print professionelt udseende fuldfarve-checks på få sekunder.",
  Month:"Måned",
  Due:"På grund:",
  Expires:"Udløber:",
  Subscription_Active:"Abonnement aktivt",
  Subscription_Inactive:"Abonnement inaktivt",
  Purchase_Additional_Cheques:"Købe yderligere checks?",
  Printable_Cheque:"Printbar check",
  Printable_Cheques:"Udskrivbare checks",
  Printable_Cheque_Word:"check er tilgængelig på din konto.",
  Printable_Cheques_Word:"checks er tilgængelige på din konto.",
  Max_Amount_Message:"Det beløb, du har angivet, er nået over det maksimale beløb, der er indstillet for dette system:",
  Terms_Required_Word:"Du skal acceptere denne aftale, før du fortsætter med at bruge checks.",
  Subscriptions:"Abonnementer",
  Product_Upgrades:"Opgraderinger",
  Mailed_Out_Cheques:"Udsendte checks",
  Enter_A_Company_Name:"Indtast venligst et firmanavn.",
  Single_Cheques:"Enkelte checks",
  Cheque_Golden:"Golden Check",
  Cheque_Golden_Window:"Golden tern design.",
  Cheque_Green:"Grøn check",
  Cheque_Green_Window:"Grøn ternet design.",
  Cheque_Red:"Rød check",
  Cheque_Red_Window:"Rød ternet design.",
  Cheque_Yellow:"Gul check",
  Cheque_Yellow_Window:"Gul tern design.",
  Cheque_Statue_of_Liberty:"Frihedsgudinden",
  Cheque_Statue_of_Liberty_Window:"Frihedsgudinden checkdesign.",
  Cheque_Green_Wave:"Grøn Bølge",
  Cheque_Green_Wave_Window:"Grøn ternet design.",
  Cheque_Golden_Weave:"Gyldent væv",
  Cheque_Golden_Weave_Window:"Elegant gyldent ternet design.",
  Cheque_Green_Sun:"Grøn Sol",
  Cheque_Green_Sun_Window:"Dybt og beroligende tjekdesign.",
  Cheque_Blue_Checkers:"Blå tern",
  Cheque_Blue_Checkers_Window:"Blå ternet design.",
  Cashiers_Check:"Kassecheck",
  Cashiers_Check_Window:"Cashier's Check stil skabelon.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Aqua check design.",
  Cheque_Golden_Checkers:"Gyldne Dam",
  Cheque_Golden_Checkers_Window:"Golden tern design.",
  Upgrade_Unavailable:"Opgraderinger er ikke tilgængelige",
  Bank_Code_Protection:"Banknummerbeskyttelse",
  Bank_Code_Protection_Word:"Beskyt dine banknumre mod at blive brugt på denne app, der kører på en anden enhed eller til en anden bruger. Denne handling er IRREVERSIBEL. Blive ved?",
  Bank_Code_Protection_Blocked_Word:"De banknumre, du forsøger at bruge, er reserveret til en anden bruger eller enhed.",
  Bank_Code_Protection_Error_Word:"Nummerbekræftelse mislykkedes. Opret forbindelse til internettet, og prøv at tilføje denne bankkonto igen.",
  Bank_Code_Protection_Set_Error_Word:"Banknummerbeskyttelse kræver, at du har forbindelse til internettet. Tilslut venligst og prøv igen.",
  Upgrade_Unavailable_Word:"Beklager, vi har problemer med at bekræfte dig. Abonnementer og opgraderinger til Checks er i øjeblikket ikke tilgængelige for køb i dit område.",
  PayPal_Payment_Preview:"Forhåndsvisning af PayPal-betaling",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Vælg PayPal",
  PayPal_Applications:"PayPal-applikationer",
  Purchase_With_Apple_Pay:"Køb med Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Virksomheder",
  Insurance:"Forsikring",
  New_PayPal:"Ny PayPal",
  Pay_By:"Betal pr",
  Insure:"Forsikre",
  Miles:"Miles",
  Payment_Method:"Betalingsmetode",
  Select_Policies:"Vælg Politikker",
  Policies:"Politikker",
  Policy:"Politik",
  No_PayPal_Account:"Ingen PayPal-konto",
  No_Policies:"Ingen forsikringspolicer",
  New_Policy:"Ny politik",
  PayPal_Payment:"PayPal-betaling",
  PayPal_Payments:"PayPal-betalinger",
  No_Payment_Selected:"Ingen betaling valgt",
  Sending_Payment_Word:"Vent venligst... Denne betaling sendes.",
  Sending_Payments_Word:"Vent venligst... Betalinger sendes.",
  No_Payment_Selected_PayPal:"Ingen <a routerLink='/folder/Payments'>PayPal-betaling</a> valgt at sende.",
  Payment_Sent:"Betaling sendt",
  PayPal_Payment_Sent:"Denne betaling er sendt med PayPal.",
  Copay:"Copay",
  Dead:"Død",
  Alive:"I live",
  Not_Dead:"Ikke død",
  Unclaimed:"Uanmeldt",
  Attempted:"Forsøgte",
  Deceased:"Afdød",
  Claimed:"Påstået",
  Unpaid:"Ubetalt",
  Sending_Payment:"Sender betaling",
  Sending_Payments:"Afsendelse af betalinger",
  Send_PayPal_Confirmation:"Vil du sende denne transaktion med PayPal?",
  Send_PayPal_Confirmation_Word:"Tryk på den grønne knap for at sende denne transaktion.",
  Save_Payment_As_Unpaid:"Vil du gemme denne betaling som ubetalt?",
  Payment_Pay_Confirmation_PayPal:"Vil du gemme denne betaling som betalt?",
  No_Policies_Word:"Tilføj den første <a routerLink='/folder/Postage/New'>forsikringspolice</a> nu.",
  Timesheet_Multiple_Delete_Confirmation:"Er du sikker på, at du vil slette flere timesedler?",
  Select_Multiple_Timesheets_Prompt:"Ingen timesedler valgt. Vælg mindst én timeseddel.",
  Select_Multiple_Policies_Prompt:"Der er ikke valgt nogen politikker. Vælg mindst én forsikring.",
  Policies_Multiple_Delete_Confirmation:"Er du sikker på, at du vil slette flere politikker?",
  Company:"Selskab",
  Add_Company:"Tilføj firma",
  New_Company:"Tilføj firma",
  No_Companies:"Ingen virksomheder",
  Enable_Company:"Aktiver virksomhed",
  Select_Company:"Vælg Firma",
  The_Default_Company:"Standardfirmaetiket.",
  Manage_Companies:"Administrer virksomheder",
  No_Companies_Word:"Checks vil bruge et standardfirma .<br />Tilføj <a routerLink='/folder/Company/New'>første firma</a>.",
  Default_Company:"Standardfirma",
  Cheques_Unlimited_Word:"Checks Unlimited giver dig mulighed for at udskrive så mange checks, du vil.",
  Cheques_Subscription_Word:"Et Checks-abonnement giver dig mulighed for at udskrive så mange checks, som du vil.",
  You_Own_This_Product:"Du ejer dette produkt.",
  Your_Subscription_is_Active:"Dit abonnement er aktivt.",
  Active_Products:"Aktiverede produkter",
  Purchase_Confirmation:"Køb",
  Purchase_Cheques:"Køb Checks",
  Restore_Purchase:"Genoprette indkøb",
  Erase_Purchase:"Slet køb",
  Successfully_Purchased:"Købt med succes",
  Enter_Your_Licence_Key:"Indtast venligst din licensnøgle på denne side for at aktivere dette produkt.",
  Licence_Key:"Licensnøgle",
  Enter_Licence_Key:"Indtast licensnøgle",
  Purchased:"Købt",
  Enable_Feature:"Aktiver funktion",
  Cancel_Subscription:"Annuller abonnement",
  Subscription_Removed:"Abonnement fjernet",
  Select_Active_Subscription:"Vælg et aktivt abonnement for at ændre det.",
  Remove_Subscription_Word:"Er du sikker på, at du vil annullere dette abonnement?",
  Delete_Company_Confirmation:"Er du sikker på, at du vil slette hele denne virksomhed? ADVARSEL: Du mister al gemt information!",
  Delete_Default_Company_Word:"Du kan ikke slette standardvirksomheden. Vil du nulstille applikationen og gendanne den til standardtilstanden? ADVARSEL: Du mister al gemt information!",
  Console_Warning_2:"Håndter ikke nogen valuta ved hjælp af denne applikation, som ikke er din i øjeblikket.",
  Terms_and_Conditions:"Vilkår og betingelser",
  and_the:"og",
  for:"til",
  Please_Read_Word:"Læs venligst og accepter",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Nogle valutaomregningskurser blev ikke fundet. Opret forbindelse til internettet.",
  Free:"Gratis",
  DB_Erase_Prompt_2:"Vil du slette hele udviklerdatabasen? ADVARSEL: Du mister alle oplysninger om KØB og ABONNEMENT!",
  Successfully_Imported:"Succesfuldt importeret",
  Select_Postage:"Vælg Porto",
  No_Postage:"Ingen frimærker",
  No_Paid_Postage_Word:"Tilføj det første <a routerLink='/folder/Porto/New'>Betalt porto</a>-stempel.",
  Trial_Complete:'Prøve færdig',
  Please_Upgrade:'Opgrader venligst Checks for at fortsætte udskrivningen.',
  Aa:"Aa",
  Color:"Farve",
  Font:"Skrifttype",
  Guide:"Guide",
  Guides:"Vejledninger",
  Image:"Billede",
  Zoom:"Zoom",
  Logo:"Logo",
  Bank:"Bank",
  MICR:"MICR",
  Total:"i alt",
  Cancel:"Afbestille",
  Confirm:"Bekræfte",
  Method:"Metode",
  Biometric_Security:"Biometrisk sikkerhed",
  Please_Login_To_Continue:"Log venligst ind for at fortsætte.",
  Complete:"Komplet",
  Sign_Up:"Tilmelde",
  Error:"Fejl",
  Biometrics:"Biometri",
  Percent:"Procent",
  Zero_Percent:"0 %",
  Top_Margin:"Topmargin",
  Bottom_Margin:"Bundmargen",
  Left_Margin:"Venstre margin",
  Right_Margin:"Højre margin",
  MICR_Margin:"MICR-margin",
  Table_Margin:"Tabel margin",
  Address_Margin:"Adressemargen",
  Percentage_:"Procent",
  Vacation_Title:"Ferie titel",
  Use_PIN:"Brug PIN",
  Loading__:"Indlæser...",
  Design_Title:"Design titel",
  Authorize:"Bemyndige",
  Key:"Nøgle",
  Public_Key:"Offentlig nøgle",
  Private_Key:"Privat nøgle",
  Authenticate:"Godkend",
  Last_Payroll:"Sidste lønudbetaling",
  Last_Calculation:"Sidste beregning",
  Authorized:"Autoriseret",
  Geo_Authorized:"Geo-Location: Autoriseret",
  Not_Authorized:"Ikke autoriseret",
  Authorization_Complete:"Autorisation fuldført",
  Geolocation_Authorization:"Geo-placeringsautorisation",
  Out_of_Bounds:"Over grænsen",
  Cant_Delete_Default_Design:"Kan ikke slette et standarddesign.",
  Unauthorized_Out_of_Bounds:"Uautoriseret: Out of Bounds",
  Biometric_Authorization:"Biometrisk autorisation",
  Locating_Please_Wait:"Finder, vent venligst...",
  Test_Biometrics:"Test biometri",
  Cheque_Margins:"Tjek marginer",
  Percentage_Full_Error:"Procentfeltet kan ikke sættes over 100 % procent.",
  No_Payroll_Text:"Tilføj en <a routerLink='/folder/Employee/New'>Medarbejder</a> eller <a routerLink='/folder/Payee/New'>Betalingsmodtager</a> og en <a routerLink='/folder/Schedule/New'>Tidsplan</a>.",
  Design_Saved:"Design gemt",
  Default_Design_Selected:"Standarddesign valgt",
  Partial_Import:"Delvis import",
  Partial_Export:"Delvis eksport",
  Entire_Import:"Hele importen",
  Entire_Export:"Hele eksporten",
  Existing_Password:"Indtast venligst din eksisterende adgangskode:",
  Existing_PIN:"Indtast venligst din eksisterende PIN-kode:",
  Pin_Change_Header:"PIN-bekræftelse",
  Pin_Change_SubHeader:"Indtast din gamle PIN-kode for at bekræfte ændringen.",
  Pass_Change_Header:"Kodeords bekræftelse",
  Pass_Change_SubHeader:"Indtast din gamle adgangskode for at bekræfte ændringen.",
  PIN_Enter_Message:"Indtast din PIN-kode for at bekræfte.",
  Password_Enter_Message:"Indtast din adgangskode for at bekræfte.",
  Pin_Updated_Success:"PIN-koden blev opdateret!",
  Pin_Updated_Fail:"PIN-koden kunne ikke opdateres.",
  Pass_Updated_Success:"Adgangskoden blev opdateret.",
  Pass_Updated_Fail:"Adgangskoden kunne ikke opdateres.",
  Preview_Payment:"Forhåndsvisning af betaling",
  Preview_Payroll:"Forhåndsvisning af løn",
  No_Payments_Created:"Der blev ikke fundet nogen betalinger oprettet.",
  Payment_Preview:"Forhåndsvisning af betaling",
  Enable_Payee:"Aktiver betalingsmodtager",
  Rendered:"Gengivet",
  No_Email:"Ingen e-mail",
  Setup_Cheques:"Opsætningstjek",
  name:"Navn",
  address:"Adresse",
  address_2:"Adresselinje 2",
  city:"By",
  province:"Provins",
  postal_code:"Postnummer",
  country:"Land",
  username:"Brugernavn",
  full_name:"Fulde navn",
  birthday:"Fødselsdag",
  email:"E-mail",
  phone:"telefon",
  employees:"Medarbejdere",
  addresses:"Adresser",
  payment_amount_limit:"Betalingsbeløbsgrænse",
  total_limit:"Total grænse",
  payees:"Betalingsmodtagere",
  description:"Beskrivelse",
  address_line_one:"Adresse linje 1",
  address_line_two:"Adresselinje to",
  image:"Billede",
  account_holder:"Kontoindehaver",
  cheque_starting_id:"Tjek start-id",
  transit_number:"Transitnummer",
  institution_number:"Institutionsnummer",
  designation_number:"Betegnelsesnummer",
  account_number:"Kontonummer",
  currency:"betalingsmiddel",
  signature:"Underskrift",
  payment_payroll_limit:"Betalingsgrænse",
  total_limi:"Samlet grænse",
  date:"Dato",
  printed_memo:"Trykt notat",
  banks:"Banker",
  signature_image:"Signaturbillede",
  address_name:"Adresse Navn",
  notes:"Noter",
  design:"Design",
  title:"Titel",
  frequency:"Frekvens",
  fax:"Fax",
  salaries:"Lønninger",
  salary_ids:"Løn-id'er",
  start_time:"Starttidspunkt",
  end_time:"Sluttid",
  paid:"Betalt",
  overtime_percentage:"Betalt procent",
  overtime_amount:"Betalt fast beløb",
  first_name:"Fornavn",
  last_name:"Efternavn",
  moderation:"Mådeholdenhed",
  create:"skab",
  edit:"Redigere",
  destroy:"Ødelægge",
  day_start_time:"Dag_starttid",
  day_end_time:"Dag_slut_tid",
  fullname:"Navn",
  time:"Tid",
  auto_send:"Send automatisk",
  time_method:"Tidsmetode",
  schedules:"Tidsplaner",
  indefinite_payroll_enabled:"Aktiver på ubestemt tid",
  amount:"Beløb",
  repeat:"Gentage",
  always_enabled:"Altid aktiveret",
  start_date:"Start dato",
  end_date:"Slutdato",
  Cheque_Total:"Tjek i alt",
  Total_Amount:"Total beløb:",
  Amounts:"Beløb:",
  Images:"Billeder",
  Files:"Filer",
  Previewing:"Forhåndsvisning:",
  Insert:"Indsæt",
  Preview_Import:"Forhåndsvisning af import",
  Entry:"Indgang",
  Entries:"Indgange",
  No_Entries:"Ingen indgange",
  Import_Type:"Importtype",
  Select_Details:"Vælg Detaljer",
  Select_Payee:"Vælg betalingsmodtager",
  Enable_Holidays:"Aktiver helligdage",
  Disable_Holidays:"Deaktiver helligdage",
  Wire_Transfer:"Bankoverførsel",
  New_Export:"Ny eksport",
  Export_Data:"Eksporter data",
  Export_Data_Word:"Vælg den filtype, der skal eksporteres og downloades.",
  Export_File:"Eksporter fil",
  Mode:"Mode",
  Times:"Tider",
  Widgets:"Widgets",
  Slider:"Skyder",
  Set_Details:"Indstil detaljer",
  Select_Type:"Vælg Type",
  Display_Slider:"Display Slider",
  Dashboard_Slider:"Dashboard Slider",
  Dashboard_Mode:"Dashboard-tilstand",
  Show_Intro:"Vis intro",
  Show_Payrolls:"Vis lønsedler",
  Show_Holidays:"Vis helligdage",
  Show_Invoices:"Vis fakturaer",
  Show_Cheques:"Vis checks",
  Enabled_Widgets:"Aktiverede widgets",
  Disabled_Widgets:"Deaktiverede widgets",
  Colors:"Farver",
  Barcodes:"Stregkoder",
  View_Timers:"Se timere",
  Gradients:"Gradienter",
  No_Info:"Ingen info",
  Disable:"Deaktiver",
  Show_Layer:"Vis lag",
  Hide_Layer:"Skjul lag",
  Text_Layer:"Tekstlag",
  Secondly:"For det andet",
  Minutely:"Minutter",
  nine_am:"09:00",
  five_pm:"17:00",
  Enable_Address:"Aktiver adresse",
  Invalid_File_Type:"Beklager, en ugyldig filtype blev valgt. Understøttet filtype:",
  Error_Updating_Entry:"Beklager, der opstod en fejl under opdatering af denne post.",
  Schedule_Timing_Alert:"Fejl: Tidsplanens starttidspunkt er indstillet til en værdi efter sluttidspunktet.",
  Select_Multiple_Payments_Prompt:"Der er ikke valgt nogen betalinger. Vælg mindst én betaling.",
  Select_Multiple_Cheques_Prompt:"Ingen checks valgt. Vælg venligst mindst én check.",
  Select_Multiple_Items_Prompt:"Ingen elementer er valgt. Vælg venligst mindst ét element.",
  Paymemt_Multiple_Delete_Confirmation:"Er du sikker på, at du vil slette flere betalinger?",
  Cheque_Multiple_Delete_Confirmation:"Er du sikker på, at du vil slette flere checks?",
  Payee_Multiple_Delete_Confirmation:"Er du sikker på, at du vil slette flere betalingsmodtagere?",
  Employee_Multiple_Delete_Confirmation:"Er du sikker på, at du vil slette flere medarbejdere?",
  MICR_Warning:"Bemærk: Nogle printere og enheder viser muligvis ikke MICR-skrifttypen korrekt.",
  Automatically_Send:"Send automatisk",
  Type:"Type",
  Payment_Type:"Betalings type",
  Auto_Send:"Send automatisk",
  Automatically_Process:"Behandle automatisk",
  Auto_Process:"Automatisk proces",
  Image_Based:"Billedbaseret",
  Font_Based:"Font-baseret",
  Rerender:"Gengive",
  Rendering:"Gengivelse",
  Render:"Filer",
  Top:"Top",
  Middle:"Midten",
  Bottom:"Bund",
  Top_Left:"Øverst til venstre",
  Top_Center:"Top Center",
  Top_Right:"Øverst til højre",
  Middle_Left:"Mellem venstre",
  Middle_Center:"Mellem center",
  Middle_Right:"Mellem højre",
  Bottom_Left:"Nederst til venstre",
  Bottom_Center:"Nederst i midten",
  Bottom_Right:"Nederst til højre",
  Numbers:"Tal",
  Verified:"Verificeret",
  Paper_Size:"Papirstørrelse",
  New_Device:"Ny enhed",
  Add_Device:"Tilføj enhed",
  Remove_Device:"Fjern enhed",
  Delete_Device:"Slet enhed",
  Block_Device:"Bloker enhed",
  Block:"Blok",
  Unblock:"Fjern blokering",
  Table:"Bord",
  Scan_Login_Code:"Scan loginkode",
  Login_Code:"Login kode",
  Scan_Code:"Scan kode",
  Scan_QR_Code:"Scan QR-kode",
  Select_All:"Vælg alle",
  Deselect_All:"Fravælg alle",
  Increase:"Øge",
  Decrease:"Formindske",
  Bold:"Fremhævet",
  Text:"Tekst",
  Style:"Stil",
  Italic:"Kursiv",
  QR_Code:"QR kode",
  Barcode:"Stregkode",
  Browse_Images:"Gennemse billeder",
  Browse_Files:"Gennemse filer",
  Background_Image:"Baggrundsbillede",
  Logo_Image:"Logo billede",
  Header_Image:"Overskriftsbillede",
  Bank_Image:"Bank billede",
  Cut_Lines:"Klip linjer",
  Background:"Baggrund",
  License:"Licens",
  One_License:"1 licens:",
  Licensed:"Licenseret til:",
  Not_Licensed:"Ikke Licenseret",
  Enter_Serial:"Indtast seriel",
  Serial_Key:"Seriel nøgle",
  Serial:"Seriel",
  Product_Key:"Produktnøgle",
  Check_Product_Key:"Tjek produktnøgle",
  Add_Product_Key:"Tilføj produktnøgle",
  Verifying_Purchase:"Bekræfter køb...",
  Print_Envelope:"Udskriv konvolut",
  Envelope:"Kuvert",
  Thank_You:"Tak skal du have!",
  Scale:"vægt",
  Print_Scale:"Udskriv skala",
  Borders:"Grænser",
  VOID:"UGYLDIG",
  Void_Cheque:"Ugyldig kontrol",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"BETAL I ORDEN AF:",
  NO_DOLLARS:"INGEN DOLLARS ",
  ONE_DOLLAR:"EN DOLLAR",
  DOLLARS:" DOLLARS",
  AND:" OG ",
  CENTS:" CENT.",
  NO_:"INGEN ",
  ONE_:"EN ",
  AND_NO_:"OG NEJ ",
  _AND_ONE_:"OG EN ",
  DOLLARS_AND_ONE_CENT:" OG ET CENT.",
  AND_NO_CENTS:" OG NUL CENT.",
  CHEQUE_PRINT_DATE:"DATO:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Indledende opgaver",
  Inital_Setup:"Initial Setup",
  Welcome_To:"Velkommen til ",
  Welcome:"Velkommen",
  Swipe:"Swipe",
  Intro_Setup:"Introopsætning",
  Introduction:"Introduktion",
  CHEQUE_PRINT_CREDIT:"Drevet af checks",
  Title_Intro_Word:"Velkommen til Cheques",
  Title_Intro:"Introduktion til checks",
  Title_Setup:"Konfiguration af checks",
  PayPal_Default_Email_Message:"Du har modtaget en ny PayPal-overførsel. [Powered by Cheques]",
  Cheques_App_Export:"Eksporteret med checks",
  Post_Intro_Thanks:"Tak, fordi du valgte Checks. Opsætningsprocessen er nu fuldført.",
  Post_Intro_Word:"Kom i gang ved at udskrive din første check, tilføje en fremtidig betaling eller tilføje en medarbejder til lønlisten.",
  Upgrade_Required:"Checks kræver, at du ejer en mere premium version af softwaren for at skjule denne besked og låse op for yderligere funktioner.",
  Upgrade_Title:"Checks",
  Mailout_Prompt:"Du kan desuden vælge at få Checks til at sende dine lønchecks til dig.",
  Mailed_Cheque:"E-mailet check:",
  Mailed_Cheque_Color:"E-mailet check (farve):",
  Terms_Purchase:"Alle elektroniske køb med checks kan refunderes fuldt ud i op til 30 dage fra købsdatoen. Læs og accepter <a href='#'>vilkår og betingelser</a>, før du fortsætter.",
  Dashboard_Setup:"Opsætning af den primære printer",
  Dashboard_Add:"Tilføj den primære bankkonto",
  Dashboard_Customize:"Vælg en kontrolskabelon",
  Dashboard_Job_Salary:"Opret dit job og tilføj din løn",
  Dashboard_Employees:"Spor medarbejdere og betalingsmodtagere",
  Dashboard_Print:"Udskriv og send dine lønsedler",
  Dashboard_Payroll:"Automatiser din lønudskrivning",
  Dashboard_PayPal:"Opsætning af PayPal Payroll / Udbetalinger",
  Begin_Setup:"Begynd opsætning",
  Get_Started:"Kom igang",
  Purchase:"Køb",
  Lockdown:"Nedlukning",
  Unlock:"Lås op",
  Detailed:"Detaljeret",
  Log_In:"Log på",
  Login:"Log på",
  Launch:"Start",
  Register:"Tilmeld",
  Finish:"Afslut",
  List:"Liste",
  Weekends:"Weekender",
  Weekly:"Ugentlig",
  PayPal_Default_Subject:"Ny betaling",
  Payment_Message:"Betalingsmeddelelse",
  PayPal_Default_Payment_Note:"Tak skal du have",
  Setup_Your_Cheqing_Account:"Checkkonto",
  Add_Your_Primary_Cheqing_Account:"Tilføj din primære checkkonto.",
  Welcome_Word:"Forenkle og automatisere løn- og personalestyring.",
  Get_Started_Quickly:"Bare svar på et par enkle spørgsmål, der vil hjælpe os i gang...",
  Done_Intro_Word:"Opsætning fuldført",
  PIN_Protected:"Adgangskode og PIN-kode beskyttet",
  Enter_New_PIN:"Indtast en ny PIN-kode:",
  Enter_PIN:"Indtast PIN-kode:",
  Invalid_PIN:"Ugyldig PIN-kode indtastet.",
  Account_Identifier:"Konto-id",
  New_Account_Identifier:"Ny konto-id",
  attempt:"forsøg",
  attempts:"forsøg",
  remaining:"resterende",
  Language:"Sprog",
  languages:"Sprog",
  select_languages:"Vælg sprog",
  Deposit:"Depositum",
  Hire_One_Now:"Lej en nu",
  App_Locked:"Applikationen er låst.",
  Skip_:"Springe",
  Skip_to_Dashboard:"Spring til betjeningspanelet",
  Dashboard:"Dashboard",
  Import:"Importere",
  Admin:"Administratorer",
  New_Admin:"Ny administrator",
  Settings:"Indstillinger",
  Color_Picker:"Farvevælger",
  Font_Picker:"Skrifttypevælger",
  Logout:"Log ud",
  Close:"Tæt",
  Close_menu:"Tæt",
  Excel:"Excel-fil",
  Csv:"CSV-fil",
  Sql:"SQL-fil",
  Json:"JSON-fil",
  Url:"Importér efter URL",
  Back:"Tilbage",
  Timers:"Timere",
  Cheque:"Kontrollere",
  Print:"Print",
  Designs:"Designs",
  Pause_Printing:"Sæt udskrivning på pause",
  Resume_Printing:"Genoptag udskrivning",
  Printing_Paused:"Udskrivning sat på pause",
  PrintingUnavailable:"Beklager! Udskrivning er ikke tilgængelig på dette system.",
  Prints_Paused:"Udskrifter sat på pause",
  Administration:"Administration",
  Loading:"Indlæser",
  Unnamed:"Unavngiven",
  error:"Beklager, denne check kunne ikke åbnes til visning.",
  No_Cheques_To_Print:"Ingen checks at udskrive",
  No_Cheques_To_Print_Word:"Opret en <a routerLink='/folder/Cheque/New'>Ny check</a>.",
  New_Cheque:"Ny check",
  Start_One_Now:"Start en nu",
  Edit_Cheque:"Rediger check",
  Select_Cheques:"Vælg checks",
  Select_Employee:"Vælg medarbejder",
  Default_Printer:"Standardprinter",
  Reassign:"Gentildele",
  Configure:"Konfigurer",
  Template:"Skabelon",
  Designer:"Designer",
  Edit_Designs:"Rediger designs",
  New_Design:"Nyt design",
  Next:"Næste",
  Save:"Gemme",
  Name:"Navn",
  Mail:"Post",
  Amount:"Beløb",
  Date:"Dato",
  PayPal:"PayPal",
  Payouts:"Udbetalinger",
  PayPal_Label:"PayPal Label",
  Email_Username:"E-mail/brugernavn",
  Client_ID:"Kunde-id",
  Sandbox_Email:"Sandbox Email",
  PayPal_Email:"PayPal e-mail",
  PayPal_Username:"API-brugernavn",
  PayPal_Payouts:"PayPal-udbetalinger",
  Select_PayPal_Key:"Vælg PayPal-nøgle",
  Secret:"Hemmelighed",
  API_Secret:"API-hemmelighed",
  PayPal_API_Keys:"PayPal nøgler",
  New_PayPal_Key:"Ny PayPal-nøgle",
  Email_Subject:"Email emne",
  Email_Message:"E-mail besked",
  Payout_Options:"Udbetalingsmuligheder",
  Payment_Note:"Betalingsnotat",
  Enable_Employee:"Aktiver medarbejder",
  Enable_Production_Mode:"Aktiver produktionstilstand",
  Sandbox_Username:"Sandbox brugernavn",
  Sandbox_Signature:"Sandkasse signatur",
  Sandbox_Password:"Sandbox Password",
  Production_Username:"Produktionsbrugernavn",
  Production_Signature:"Produktionssignatur",
  Production_Password:"Produktionsadgangskode",
  Production_Email:"Produktions-e-mail",
  API_Client_ID:"API Client ID",
  API_Signature:"API-signatur",
  API_Password:"API-adgangskode",
  API_Username:"API-brugernavn",
  Secret_Key:"Hemmelig nøgle",
  Sandbox:"Sandkasse",
  Production:"Produktion",
  Sandbox_Keys:"Sandkassenøgler",
  Production_Keys:"Produktionsnøgler",
  API_Title:"API-titel",
  Production_Mode:"Produktionstilstand",
  Account_Label:"Kontolabel",
  No_PayPal_Setup:"Ingen PayPal-nøgle",
  Enable_PayPal_Account:"Aktiver PayPal-konto",
  No_PayPal_Word:"Tilføj din <a routerLink='/folder/Invoice/New'>PayPal API-nøgle</a>.",
  Printed_Memo:"Trykt notat",
  Employee:"Medarbejder",
  View_Employee:"Se medarbejder",
  Mailing_Address:"Postadresse",
  Company_Address:"Virksomhedsadresse",
  Select_Company_Address:"Vælg firmaadresse",
  Address:"Adresse",
  Any_Day:"Enhver dag",
  Address_Name:"Adresse navn",
  Unit:"Enhed",
  Account:"Konto",
  Bank_Account:"Bankkonto",
  Account_Limits:"Aktiver kontogrænser",
  Payroll:"Lønningsliste",
  New_Payroll:"Ny lønningsliste",
  No_Payroll:"Ingen kommende lønudbetaling",
  Upcoming_Holiday:"Kommende ferie:",
  Invoice_Due:"Faktura forfalder:",
  New_Invoice:"Ny faktura",
  No_Invoices:"Ingen fakturaer",
  No_Invoices_Word:"Opret den første <a routerLink='/folder/Invoice/New'>faktura</a>.",
  Cheque_Due:"Check forfalder:",
  Payrolls:"Lønsedler",
  Sandbox_Mode:"Sandbox Mode",
  Hire:"Leje",
  Pay:"Betale",
  New:"Ny",
  Add:"Tilføje",
  Make:"Lave",
  Time:"Tid",
  Write:"Skrive",
  Holiday:"Ferie",
  Holidays:"Ferie",
  Next_Holiday:"Næste ferie:",
  All_Done:"Helt færdig!",
  Employees:"Medarbejdere",
  Payees:"Betalingsmodtagere",
  Job:"Job",
  Jobs:"Jobs",
  Invoice:"Faktura",
  Invoices:"Fakturaer",
  Vacations:"Ferie",
  Cheques:"Tjek",
  Brand_Cheques:"Mærke",
  Payment:"Betaling",
  Enable_Payment:"Aktiver betaling",
  Payments:"Betalinger",
  Schedule:"Tidsplan",
  Schedules:"Tidsplaner",
  Timesheet:"Timeseddel",
  Timesheets:"Timesedler",
  Salary:"Løn",
  New_Address:"Ny adresse",
  Address_2:"Adresselinje 2)",
  _City:"By",
  _State:"Stat/Prov",
  City:"By / Township",
  State:"Stat/provins",
  Postal:"Postnummer",
  ZIP:"Post / postnummer",
  Country:"Land",
  Addresses:"Adresser",
  Required_Options:"Påkrævede indstillinger",
  Optional_Options:"Valgfrie muligheder",
  Additional_Options:"Yderligere muligheder",
  Required:"Påkrævet",
  Optional:"Valgfri",
  Additional:"Ekstra",
  No_Addresses:"Ingen adresser",
  New_Address_Word:"Tilføj den første <a routerLink='/folder/Address/New'>adresse</a>.",
  Select_Address:"Vælg adresse",
  No_Address:"Ingen adresser",
  Print_Cheque:"Udskriv tjek",
  Print_Cheque_Now:"Udskriv tjek nu",
  Description:"Beskrivelse",
  Pay_To_The_Order_Of:"Betal til rækkefølgen af:",
  Select_Date_Range:"Vælg datointerval",
  Select_Starting_Date:"Vælg startdato",
  Select_Ending_Date:"Vælg slutdato",
  Select_Date:"Vælg dato",
  Cheque_Date:"Tjek dato",
  Cheque_Memo:"Tjek notat",
  Blank_Cheque:"Blank check",
  Blank:"Blank",
  No_Cheques:"Ingen kontrol",
  No_Cheques_Word:"Udskriv din første <a routerLink='/folder/Cheque/New'>Kontrollere</a>.",
  Cheque_Amount_Placeholder:"$0,00",
  View_Image:"Se billede",
  View:"Udsigt",
  Modify:"Modificere",
  Delete:"Slet",
  Cheque_Paid:"Betalt",
  New_Deduction:"Nyt fradrag",
  Title:"Titel",
  Frequency:"Frekvens",
  Hourly:"Hver time",
  Daily:"Daglige",
  Weekdays:"Hverdage",
  BiWeekly:"2 uger",
  TriWeekly:"3 uger",
  Monthly:"Månedlige",
  MiMonthly:"2 måneder",
  Quarterly:"Kvartalsvis",
  Yearly:"Årligt",
  Every_Week:"Hver uge",
  Every_Payroll:"Hver lønningsliste",
  Every_Month:"Hver måned",
  Annually:"Årligt",
  Always_Scheduled:"Altid planlagt",
  Start_Date:"Start dato",
  End_Date:"Slutdato",
  Start_Time:"Starttidspunkt",
  End_Time:"Sluttid",
  Deduction_Label:"Fradragsmærke",
  Notes:"Noter",
  Options:"Muligheder",
  Enable:"Aktiver",
  Select_Deductions:"Vælg fradrag",
  Deductions:"Fradrag",
  No_Deductions:"Ingen fradrag",
  No_Deductions_Word:"Opret dit første <a routerLink='/folder/Deduction/New'>Fradrag</a>.",
  New_Employee:"Ny medarbejder",
  No_Title:"Ingen titel",
  _Name:"Navn",
  About_Yourself:"Om dig selv",
  Full_Name:"Fulde navn",
  Birthday:"Fødselsdag",
  Email:"E-mail",
  SMS:"SMS",
  Phone:"Telefon",
  Test:"Prøve",
  Call:"Opkald",
  Fax:"Fax",
  Printed_Note:"Trykt note",
  Position:"Position",
  Job_Position:"Job position",
  Select_a_Job:"Vælg et job",
  Select_a_Salary:"Vælg en løn",
  Add_a_Deduction:"Tilføj et fradrag",
  Taxes:"Skatter",
  Add_Taxes:"Tilføj afgifter",
  Date_of_Birth:"Fødselsdato",
  Email_Address:"Email adresse",
  Phone_Number:"Telefonnummer",
  Phone_Call:"Telefon opkald",
  Enable_on_Payroll:"Aktiver på lønningslisten",
  Select_Employees:"Vælg medarbejdere",
  No_Employees:"Ingen ansatte",
  No_Employees_Word:"Tilføj din første nye <a routerLink='/folder/Employee/New'>Medarbejder</a>.",
  No_Name:"Intet navn",
  Unemployeed:"Arbejdsløs",
  Employeed:"Ansat",
  Paid:"Betalt",
  Enabled:"Aktiveret",
  Disabled:"Handicappet",
  Fire:"Brand",
  Not_Available:"Ikke tilgængelig",
  Not_Available_Word:"Udskriv din første <a routerLink='/folder/Invoice/New'>faktura</a> og få betalt.",
  Select_Invoices:"Vælg_fakturaer",
  Print_Invoice_Word:"Udskriv din første <a routerLink='/folder/Invoice/New'>faktura</a> og få betalt.",
  Invoice_Title:"Faktura titel",
  Invoice_Date:"Fakturadato",
  Due_Date:"Afleveringsdato",
  New_Job:"Nyt job",
  Details:"Detaljer",
  Customize:"Tilpas",
  Customize_Dashboard:"Tilpas Dashboard",
  Components:"Komponenter",
  No_Components:"Ingen komponenter",
  Main_Components:"Hovedkomponenter",
  Smaller_Components:"Mindre komponenter",
  Error_Loading_Page:"Fejl ved indlæsning af denne side.",
  Bank_Details:"Bank detaljer",
  About_Your_Job:"Om dit job",
  Your_Schedule:"Din tidsplan",
  Job_Title:"Jobtitel",
  Job_Description:"Job beskrivelse",
  Job_Details:"Jobdetaljer",
  Enable_Job:"Aktiver job",
  Pay_Period:"Lønsperiode",
  Perpetually_Schedule:"Evigt tidsplan",
  Select_Jobs:"Vælg job",
  No_Jobs:"Ingen job",
  Add_Jobs:"Tilføj job",
  No_Jobs_Word:"Tilføj det første <a routerLink='/folder/Job/New'>Job</a> til listen.",
  Count_Employees:"job.employee_count+' Medarbejdere'",
  Zero_Employees:"0 medarbejdere",
  New_Leave:"Ny orlov",
  Leave_Name:"Forlad navn",
  Paid_Leave:"Betalt orlov",
  Leave_Pay:"Forlad løn",
  Indefinite_Leave:"Ubestemt orlov",
  Indefinite_Payroll:"Ubestemt lønudbetaling",
  Leave:"Forlade",
  Add_Schedules:"Tilføj tidsplaner",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Aktiver fravær",
  Select_Leaves:"Vælg blade",
  No_Leaves:"Ingen fraværsblade",
  Leave_Of_Absence:"Orlov",
  Leaves_Of_Absence:"Fraværsblade",
  New_Leave_of_Absense:"Ny tjenestefrihed",
  No_Leaves_Word:"Tilføj den første <a routerLink='/folder/Leave/New'>fravær</a>.",
  Not_Enabled:"Ikke aktiveret",
  Absences:"Fravær",
  Payee:"Betalingsmodtager",
  New_Payee:"Ny betalingsmodtager",
  Payee_Identifier:"Betalingsmodtager-id",
  Payee_Name:"Betalingsmodtagernavn",
  Payee_Title:"Betalingsmodtagers titel",
  Payment_Memo:"Betalingsnota",
  Select_Payees:"Vælg betalingsmodtagere",
  No_Payees:"Ingen betalingsmodtagere",
  Add_Payee_Note:"Tilføj den første <a routerLink='/folder/Payee/New'>Betalingsmodtager</a>.",
  New_Payees:"Nye betalingsmodtagere",
  About_The_Payment_Schedule:"Betalingsplan",
  Your_Payroll_Schedule:"Automatisk lønudbetaling",
  New_Payment:"Ny betaling",
  Identifier:"Identifier",
  Select:"Vælg",
  Memo:"Memo",
  Payment_Date:"Betalingsdato",
  Mark_as_Paid:"Markér som betalt",
  Select_Payments:"Vælg betalinger",
  No_Payments:"Ingen betalinger",
  No_Payments_Word:"Opret den første <a routerLink='/folder/Payment/New'>Betaling</a>.",
  Create_Payroll:"Opret lønningsliste",
  Properties:"Ejendomme",
  Payroll_Title:"Løntitel",
  Payroll_Notes:"Lønnotater",
  Payroll_Setup:"Lønopsætning",
  Tabulate_Payments:"Tabulér betalinger",
  Tabulate:"Tabuler",
  By:"Ved:",
  Payments_By:"Betalinger af",
  Timesheets_And_Schedules:"Timesedler og tidsplaner",
  Both:"Begge",
  Items:"genstande",
  Add_Payees:"Tilføj betalingsmodtagere",
  Add_Account:"Tilføj konto",
  Enable_Account:"Aktiver konto",
  Enable_Payroll:"Aktiver lønning",
  Print_Payroll:"Udskriv lønliste",
  No_Payrolls:"Ingen løn",
  No_Payroll_Word:"Opret din første <a routerLink='/folder/Payroll/New'>Lønningsliste</a>.",
  View_more:"SE MERE",
  Less:"MINDRE",
  Add_Payroll:"Tilføj lønningsliste",
  Select_Payroll:"Vælg løn",
  About_Your_Salary:"Om din løn",
  Add_Salaries:"Tilføj lønninger",
  Add_Salary:"Tilføj løn",
  Salaries:"Lønninger",
  No_Salaries:"Ingen løn",
  No_Salaries_Word:"Tilføj den første <a routerLink='/folder/Salary/New'>Løn</a>.",
  Select_Salaries:"Vælg lønninger",
  New_Salary:"Ny løn",
  Salary_Name:"Lønidentifikator",
  Enable_Salary:"Aktiver løn",
  Salary_Amount:"Lønbeløb",
  New_Schedule:"Nyt skema",
  Schedule_Title:"Plantitel",
  Add_Address:"Tilføj adresse",
  Repeat:"Gentage",
  Design:"Design",
  Edit_Design:"Rediger design",
  Edit_this_Design:"Rediger dette design",
  Repeat_Payment:"Gentag betaling",
  Enable_Schedule:"Aktiver tidsplan",
  Never:"Aldrig",
  Select_Schedule:"Vælg tidsplaner",
  No_Schedules:"Ingen tidsplaner",
  No_Schedules_Word:"Opret den første <a routerLink='/folder/Schedule/New'>Tidsplan</a>.",
  New_Administrator:"Ny administrator",
  Username:"Brugernavn",
  First_Name:"Fornavn",
  Last_Name:"Efternavn",
  Add_an_Address:"Tilføj en adresse",
  Payment_Limit:"Pr-betalingsgrænse",
  Total_Limit:"Total grænse",
  Select_Accounts:"Vælg konti",
  No_Administrators:"Ingen administratorer",
  No_Administrators_Word:"Opret den første <a routerLink='/folder/Administrator/New'>Administratorkonto</a>.",
  New_Administrators_Word:"Tilføj den første <a routerLink='/folder/Administrator/New'>Administrator</a>",
  Cloud:"Sky",
  Backup:"Backup",
  Enable_iCloud:"Aktiver iCloud",
  Enable_Google_Drive:"Aktiver Google Drev",
  Enable_Microsoft_OneDrive:"Aktiver Microsoft OneDrive",
  Automatically_Backup:"Sikkerhedskopier automatisk",
  FTP_Settings:"FTP-indstillinger",
  URL_File_Prompt:"Angiv venligst placeringen for en .xls / .xlsx / .json fil til import:",
  URL_SQL_Prompt:"Angiv venligst placeringen af ​​SQLite-filen, der skal importeres:",
  FTP_Backup:"FTP Backup",
  FTP_Import:"FTP-import",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Vært",
  Port:"Havn",
  Path:"Sti",
  Data_Path:"Datasti",
  Enable_FTP_Account:"Aktiver FTP-konto",
  HostnameIP:"Værtsnavn",
  Password:"Adgangskode",
  Connection_Port:"Forbindelsesport",
  Enable_MySQL_Database:"Aktiver MySQL-database",
  Log:"Log",
  Reset:"Nulstil",
  Erase:"Slette",
  Export:"Eksport",
  Database:"Database",
  Upload_CSV:"Upload CSV",
  Download_CSV:"Download CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL Backup",
  Internal_Notes:"Interne noter",
  Root_Path:"Rodsti",
  Select_Backup:"Vælg sikkerhedskopiering",
  Add_New_Backup:"Tilføj en ny sikkerhedskopi",
  First_Backup:"Sæt den første sikkerhedskopi...",
  Backups:"Sikkerhedskopier",
  Add_Backup:"Tilføj sikkerhedskopi",
  No_Backups:"Der er ingen sikkerhedskopier at finde.",
  Select_Backup_Type:"Vælg den type sikkerhedskopi, du vil konfigurere...",
  Backup_Type:"Sikkerhedskopieringstype",
  FTP_Drive:"FTP-drev",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Køre",
  Microsoft_OneDrive:"Køre",
  Import_Fields:"Importer felter",
  Import_Fields_Word:"Brug denne sektion til at vælge <a routerLink='/folder/Holiday/New'>Importer</a> data.",
  Upload:"Upload",
  Download:"Hent",
  Download_JSON:"Download som JSON-data",
  Download_SQL:"Download som SQL-fil",
  New_Bank:"Ny bank",
  New_Account:"Ny konto",
  New_Bank_Account:"Ny bankkonto",
  Upload_Image:"Upload billede",
  Bank_Name:"Bank-navn",
  Bank_Address:"Bank adresse",
  Branch_Address:"Afdelingsadresse",
  Address_on_Cheque:"Adresse",
  Cheque_Numbers:"Tjek numre",
  Cheque_Details:"Tjek detaljer",
  Bank_Logo:"Banklogo",
  Cheque_ID:"Tjek ID",
  Starting_Cheque_ID:"Starter kontrol-id",
  Transit_Number:"Transitnummer",
  Institution_Number:"Institution nummer",
  Designation_Number:"Betegnelsesnummer",
  Account_Number:"Kontonummer",
  Limits:"Grænser",
  Default_Limits:"Standardgrænser",
  Over_Limit:"Over grænsen",
  Under_Limit:"Under grænse",
  Payroll_Limit:"Løngrænse",
  Enable_Bank_Account:"Aktiver bankkonto",
  Select_Account:"Vælg konto",
  No_Bank_Account:"Ingen bankkonto",
  No_Bank_Account_Word:"Tilføj den første <a routerLink='/folder/Accounts/New'>Bankkonto</a>.",
  Bank_Accounts:"Bankkonti",
  No_Accounts:"Ingen konti",
  No_Accounts_Note:"Tilføj den første <a routerLink='/folder/Accounts/New'>Bankkonto</a>.",
  Cheque_Designer:"Tjek designer",
  Cheque_Design:"Tjek design",
  Select_Design:"Vælg et design",
  Cheque_Designs:"Tjek designs",
  No_Cheque_Designs:"Ingen tjekdesign",
  No_Cheque_Designs_Word:"Opret dit eget <a routerLink='/folder/Settings/Cheque/Design/New'>Tjek Design</a>.",
  Set_Default:"Indstillet som standard",
  Default:"Standard",
  Remove:"Fjerne",
  Folder:"Folder",
  Edit:"Redigere",
  LoadingDots:"Indlæser...",
  Add_a_New_File:"Tilføj en <a href='#' (click)='add()'>ny fil</a> til",
  this_folder:"denne mappe",
  FTP_Backup_Settings:"FTP-sikkerhedskopiindstillinger",
  Secure_File_Transfer:"Sikker filoverførsel",
  New_Holiday:"Ny ferie",
  Add_Holiday:"Tilføj ferie",
  Holiday_Name:"Ferienavn",
  Additional_Pay:"Tillægsløn",
  Enable_Holiday:"Aktiver ferie",
  Select_Holidays:"Vælg helligdage",
  No_Holidays:"Ingen ferie",
  No_Holidays_Word:"Tilføj den første <a routerLink='/folder/Holiday/New'>helligdag</a>.",
  New_Import:"Ny import",
  Import_Data:"Importer data",
  Import_Data_Word:"Vælg den filtype eller uploadmetode, du vælger.<br /> Du vil være i stand til at vælge de importerede felter i en fil, der svarer til den parameter i appen, efter du har uploadet en understøttet fil.",
  Import_File:"Importer fil",
  Link_To_File:"Link til fil",
  Select_File:"Vælg fil",
  New_Moderator:"Ny moderator",
  Allow_Moderation:"Tillad moderation",
  Create_Paycheques:"Opret lønsedler",
  Edit_Paycheques_and_Data:"Rediger lønsedler og data",
  Destroy_Data_and_Paycheques:"Ødelæg data og lønsedler",
  Bonus_Percentage:"Lønseddel i procent",
  Fixed_Amount:"Fast beløb",
  Select_Moderator:"Vælg moderator",
  No_Moderators:"Ingen moderatorer",
  Moderators:"Moderatorer",
  Moderator_Account:"Opret den første <a routerLink='/folder/Administrator/New'>Moderatorkonto</a>.",
  No_Moderators_Word:"Tilføj den første <a routerLink='/folder/Administrator/New'>Moderator</a>.",
  Defaults:"Standard",
  Provide_Us_Info:"Giv os oplysninger",
  Setup_Your_Printer:"Konfigurer din printer",
  Your_Company:"Om din virksomhed",
  Company_Name:"Firmanavn",
  Currency:"Betalingsmiddel",
  Default_Currency:"Standardvaluta",
  Base_Monthly_Income:"Månedlig indkomst",
  Protection:"Beskyttelse",
  App_Protection:"App beskyttelse",
  PIN_Code_Protection:"PIN kode beskyttelse",
  App_Protection_Word:"Aktiver sikkerhedsmetoder, der hjælper med at beskytte din konto.",
  PIN_Code:"Pinkode",
  Change_PIN:"Skift pinkode",
  New_Password:"Nyt kodeord",
  Geofence_Protection:"Geo-hegn beskyttelse",
  Geofence_Area:"Indstil område",
  Distance:"Afstand",
  Setup_Now:"Opsætning nu",
  Mile:"Mil",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Ansigtsgenkendelse",
  Face:"Ansigt",
  Setup:"Opsætning",
  Label:"Etiket",
  Password_Protection:"Adgangskodebeskyttelse",
  Modify_Password:"Rediger adgangskode",
  New_Tax_Entry:"Ny skattepost",
  New_Tax:"Ny skat",
  Tax_Label:"Skattemærke",
  Perpetually_Enabled:"Perpetually Enabled",
  Select_Taxes:"Vælg afgifter",
  Tax_Deductions:"Skattefradrag",
  No_Tax_Deductions:"Ingen skattefradrag",
  No_Tax_Deductions_Word:"Tilføj det første <a routerLink='/folder/Tax/New'>Moms</a> fradrag.",
  New_Timer:"Ny timer",
  Start:"Start",
  Stop:"Hold op",
  Start_Timer:"Start timer",
  Stop_Timer:"Stop timer",
  Timer_Active:"Timer aktiv",
  Timer:"Timer:",
  Timer_Running:"Timer: (Kører)",
  Save_Timer:"Gem timer",
  New_Timesheet:"Ny timeseddel",
  Select_Timesheets:"Vælg timesedler",
  Work_Notes:"Arbejdsnoter",
  Entry_Title:"Indgangstitel",
  No_Timesheets:"Ingen timesedler",
  No_Timesheets_Word:"Tilføj den første <a routerLink='/folder/Timesheet/New'>Timeseddel</a>.",
  Timesheet_Submitted:"Timeseddel indsendt",
  Timesheet_Congrats:"Tillykke! Din timeseddel er blevet indsendt. Tak!",
  Timesheet_Copy:"For at modtage en kopi af dine dokumenter bedes du give os din e-mailadresse og/eller mobiltelefonnummer.",
  Submit:"Indsend",
  Allow_Notifications:"Tillad meddelelser",
  Untitled_Entry:"Ny indgang",
  Untitled_Bank:"Unavngivet bank",
  Timesheet_Entry:"Timeregistrering",
  Work_Description:"Arbejdsbeskrivelse",
  Enable_Timesheet:"Aktiver timeseddel",
  Submit_Timesheet:"Send timeseddel",
  Vacation:"Ferie",
  New_Vacation:"Ny ferie",
  Vacation_Name:"Ferie navn",
  Paid_Vacation:"Betalt ferie",
  Vacation_Pay:"Feriepenge",
  Enable_Vacation:"Aktiver ferie",
  Select_Vacations:"Vælg ferier",
  No_Vacations:"Ingen ferie",
  No_Vacations_Word:"Opret den første <a routerLink='/folder/Vacation/New'>Ferie</a>-indgang.",
  Payroll_Schedule:"Lønskema",
  Next_Payroll:"Næste lønudbetaling:",
  Upcoming_Payroll:"Kommende løn",
  No_Upcoming_Payroll:"Ingen kommende lønudbetaling",
  Address_Book:"Adressebog",
  Archived_Documents:"Arkiverede dokumenter",
  Dev_Mode:"Applikation i udviklingstilstand",
  Administrators:"Administratorer",
  Privacy:"Privatliv",
  None:"Ingen",
  Select_Signature:"Vælg signatur",
  No_Signatures:"Ingen underskrifter",
  No_Signatures_Word:"Tilføj den første <a routerLink='/folder/Signature/New'>Signatur</a>.",
  Repeat_Indefinitely:"Gentag på ubestemt tid",
  Sign:"Skilt",
  Sign_Here:"Underskriv her",
  Date_Signed:"Dato underskrevet",
  Signature_Pad:"Signaturblok",
  Account_Holder:"Kontoindehaver",
  Account_Properties:"Kontoegenskaber",
  Name_On_Cheque:"Navn på check",
  Server_Hostname:"Serverværtsnavn / IP",
  Printers:"printere",
  No_Printers:"Ingen printere",
  Printer_Exists:'Der findes allerede en printer med dette navn.',
  No_Printers_Word:"Tilføj den allerførste <a routerLink='/folder/Printer/New'>Printer</a>.",
  No_Printer_Alert:"Der er ikke defineret nogen printer. Vil du konfigurere en printer?",
  Add_Printer:"Tilføj printer",
  New_Printer:"Ny printer",
  Printer_Hostname:"Printerværtsnavn / IP",
  Printer_Label:"Printeretiket",
  Printer_Protocol:"Printerprotokol",
  Protocol:"Protokol",
  Email_Print:"E-mail",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Socket",
  Print_Job:"Udskriftsjob",
  Printed:"Trykt",
  Not_Printed:"Ikke udskrevet",
  Print_Jobs:"Udskriftsjob",
  Print_Queue:"Udskriftskø",
  No_Print_Jobs:"Ingen udskriftsjob",
  No_Prints:"Opret en ny <a routerLink='/folder/Cheque/New'>Kontrollere</a> for at udskrive.",
  Prints:"Udskrifter",
  Find_Printer:"Find printer",
  Find_AirPrint_Devices:"Find AirPrint-enheder",
  Select_Printer:"Vælg printer",
  System_UI:"System UI",
  Printer:"Printer",
  Status:"Status",
  Preview:"Preview",
  Enable_Print_Job:"Aktiver udskriftsjob",
  Queue_Weight:"Køvægt",
  Unlimited:"Ubegrænset",
  Show_Advanced_Printer_Options:"Vis avancerede printerindstillinger",
  Advanced:"Fremskreden",
  Location:"Beliggenhed",
  Note:"Bemærk",
  Queue_Name:"Kønavn",
  Pages_Printed_Limit:"Grænse for udskrevne sider",
  MultiPage_Idle_Time:"Flersidet ventetid(er)",
  Page_Count_Limit:"Grænse for sideantal",
  Page_Orientation:"Sideorientering",
  Portrait:"Portræt",
  Landscape:"Landskab",
  Duplex:"Duplex",
  Double_Sided_Printing:"Dobbeltsidet",
  Duplex_Mode:"Duplekstilstand",
  Duplex_Short:"Kort",
  Duplex_Long:"Lang",
  Duplex_None:"Ingen",
  Color_And_Quality:"Farve og kvalitet",
  Monochrome:"Monokrom",
  Photo_Quality_Prints:"Fotokvalitetsudskrifter",
  Printer_Email:"Printer email",
  Automatically_Downsize:"Reducer automatisk",
  Paper:"Papir",
  Paper_Name:"Papirnavn",
  Paper_Width:"Papirbredde",
  Paper_Height:"Papirhøjde",
  Paper_Autocut_Length:"Længde til automatisk skæring af papir",
  Margins:"Margener",
  Page_Margins:"Sidemargener",
  Page_Margin_Top:"Topsidemargen",
  Page_Margin_Right:"Højre sidemargen",
  Page_Margin_Bottom:"Bundsidemargen",
  Page_Margin_Left:"Venstre sidemargen",
  Add_Employees:"Tilføj medarbejdere",
  Header:"Header",
  Print_A_Page_Header:"Udskriv en sidehoved",
  Header_Label:"Sidehovedetiket",
  Header_Page_Index:"Header Page Index",
  Header_Font_Name:"Overskriftsskrifttype",
  Select_Font:"Vælg skrifttype",
  Font_Selector:"Skrifttypevælger",
  Header_Font_Size:"Overskriftsskrifttype",
  Header_Bold:"Overskrift fed",
  Header_Italic:"Overskrift kursiv",
  Header_Alignment:"Overskriftsjustering",
  Left:"Venstre",
  Center:"Centrum",
  Right:"Ret",
  Justified:"Berettiget",
  Header_Font_Color:"Overskriftsfarve",
  Select_Color:"Vælg farve",
  Footer:"Footer",
  Print_A_Page_Footer:"Udskriv en sidefod",
  Footer_Label:"Footer Label",
  Footer_Page_Index:"Sidesideindeks",
  Footer_Font_Name:"Footer Font",
  Fonts:"Skrifttyper",
  Done:"Færdig",
  Select_Fonts:"Vælg skrifttyper",
  Footer_Font_Size:"Footer Size",
  Footer_Bold:"Fed sidefod",
  Footer_Italic:"Sidefod kursiv",
  Footer_Alignment:"Footer Alignment",
  Footer_Font_Color:"Farve til sidefod",
  Page_Copies:"Sidekopier",
  Enable_Printer:"Aktiver printer",
  Remote_Logging:"Fjernlogning",
  Log_Server:"Logserver",
  Encryption:"Kryptering",
  Random_Key:"Tilfældig nøgle",
  Encryption_Key:"Krypteringsnøgle",
  Cheques_Webserver:"Tilpasset database",
  Learn_How:"Lær hvordan",
  Signature:"Underskrift",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Se signatur",
  Printed_Signature:"Trykt signatur",
  Digitally_Sign:"Digitalt underskrive",
  Digital_Signature:"Digital signatur",
  Add_Signature:"Tilføj signatur",
  Add_Your_Signature:"Tilføj din signatur",
  Enable_Signature:"Aktiver signatur",
  Digitally_Signed:"Digitalt signeret",
  Insert_Error:"Kan ikke gemme check på grund af databaseproblemer.",
  Delete_Confirmation:"Er du sikker på, at du vil slette disse oplysninger?",
  Discard_Confirmation:"Er du sikker på, at du vil kassere disse oplysninger?",
  Discard_Bank_Confirmation:"Er du sikker på, at du vil kassere denne konto?",
  Discard_Printer_Confirmation:"Er du sikker på, at du vil kassere denne printer?",
  Delete_Bonus_Confirmation:"Er du sikker på, at du vil slette denne bonus?",
  Delete_Invoice_Confirmation:"Er du sikker på, at du vil slette denne faktura?",
  Generated_Cheque:"Genereret check",
  Generated_Invoice:"Genereret faktura",
  Schedule_Start_Time:"Schedule Start",
  Schedule_End_Time:"Tidsplan slut",
  New_Call:"Nyt opkald",
  No_Contacts:"Ingen kontakter",
  No_Contacts_Word:"Administratorer, moderatorer, medarbejdere og betalingsmodtagere vises som kontakter.",
  PDF_Subject:"økonomi",
  PDF_Keywords:"Payroll paycheck PDF check checks",
  Printer_Setup:"Printeropsætning",
  Printer_Selection:"Printervalg",
  New_Fax:"Ny fax",
  New_Fax_Message:"Ny faxmeddelelse",
  Fax_Machine:"Faxmaskine",
  Fax_Name:"Fax navn",
  Fax_Email:"Fax e-mail",
  Fax_Number:"Fax nummer",
  Contents:"Indhold",
  Fax_Body:"Sidetekst",
  Header_Word:"Header:",
  Header_Text:"Overskriftstekst",
  Include_Header:"Inkluder overskrift",
  Include_Footer:"Inkluder sidefod",
  Footer_Word:"Footer:",
  Footer_Text:"Footer Text",
  Attach_a_Cheque:"Vedhæft en check",
  Add_Deduction:"Tilføj fradrag",
  Enable_Fax:"Send fax",
  Select_Fax:"Vælg fax",
  No_Faxes:"Ingen faxer",
  Faxes:"Faxer",
  Save_and_Send:"Send fax",
  Save_and_Pay:"Gem og betal",
  WARNING:"ADVARSEL:",
  Remember:"Husk",
  Printing:"Trykning",
  Printing_Complete:"Udskrivning fuldført!\n\n",
  of:"af",
  There_Were:"Der var ",
  Successful_Prints:"vellykkede udskrifter og",
  Unsuccessful_Prints:"mislykkede udskrifter.",
  PrinterError:"Beklager! Der opstod en fejl.",
  Printing_:"Trykning...",
  PrinterSuccess:"Dokumentet blev udskrevet.",
  PrintersSuccess:"Dokumenter blev udskrevet.",
  Print_Cheques:"Udskriv checks",
  New_Message:"Ny besked",
  New_Messages:"Nye beskeder",
  Read_Message:"Læs besked",
  Write_Message:"Skriv besked",
  Send_Message:"Send besked",
  Subject:"Emne",
  Message:"Besked",
  Writing:"Skrivning...",
  Send:"Sende",
  Set_Date:"Indstil dato",
  Set_Time:"Fastsat tidspunkt",
  Recieve:"Modtag",
  Set_as_Default:"Indstillet som standard",
  Default_Account:"Standardkonto",
  Default_Design:"Standard design",
  Multiple_Cheques:"Tjek (Trippel)",
  Account_Mode:"Kontotilstand",
  Multiple_Cheques_Description:"Tre checks pr. side.",
  Check_and_Table:"Tjek og tabel",
  Check_including_Table:"Tjek- og regnskabstabel.",
  Check_Mailer:"Check Mailer",
  Check_Mailer_Window:"Tjek med et adressevindue.",
  DocuGard_Table_Top:"DocuGard Check & Table (øverst)",
  DocuGard_Table_Middle:"DocuGard Check & Table (midten)",
  DocuGard_Table_Bottom:"DocuGard Check & Table (nederst)",
  DocuGard_Mailer_Top:"DocuGard Check Mailer (øverst)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (midt)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mailer (nederst)",
  DocuGard_Three_Cheques:"DocuGard Checks (Trippel)",
  DocuGard_Cheque_Top:"DocuGard Check (øverst)",
  DocuGard_Cheque_Middle:"DocuGard Check (midten)",
  DocuGard_Cheque_Bottom:"DocuGard Check (nederst)",
  DocuGard_Three_Cheques_Window:"Tre checks på én side.",
  DocuGard_Table_Top_Window:"Check & indtjeningstabel.",
  DocuGard_Table_Middle_Window:"Check & indtjeningstabel.",
  DocuGard_Table_Bottom_Window:"Check & indtjeningstabel.",
  DocuGard_Mailer_Top_Window:"Tjek, tabel og adresse.",
  DocuGard_Mailer_Middle_Window:"Tjek, tabel og adresse.",
  DocuGard_Mailer_Bottom_Window:"Tjek, tabel og adresse.",
  DocuGard_Cheque_Top_Window:"Kontroller for sikkert papir.",
  DocuGard_Cheque_Middle_Window:"Kontroller for sikkert papir.",
  DocuGard_Cheque_Bottom_Window:"Kontroller for sikkert papir.",
  Basic_Cheque:"Tjek (øverst)",
  Basic_Cheque_Print:"Udskriv en enkelt check.",
  Error_Setting_As_Paid:"Fejl ved indstilling som betalt",
  Add_Attachment:"Tilføj vedhæftet fil",
  PrinterUnavailable:"Printer utilgængelig",
  CreditCardComponent:"Kort",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Nyt depositum",
  Deposits:"Indlån",
  No_Deposits:"Ingen indskud",
  Credit_Card_Deposit:"Kreditkort",
  New_Credit_Card_Deposit_Message:"Kreditkortindbetaling",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin-indskud",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Interac Transfer",
  Payments_Limit:"Betalingsgrænse",
  No_Payment_Limit:"Ingen betalingsgrænse",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal depositum",
  No_Deposits_Word:"Tilføj det første firma <a routerLink='/folder/Deposit/New'>Indbetaling</a>.",
  No_Documents_Specified:"Ingen dokumenter blev specificeret til udskrivning",
  No_Printers_Added:"Der blev ikke fundet nogen printere. Gå til Indstillinger > Printere for at tilføje en.",
  DB_Erase_Prompt:"Slet hele databasen fuldstændigt? ADVARSEL: Du mister al gemt information!",
  Console_Warning:"Indsæt ikke nogen tekst i denne konsol. Du kan sætte dig selv og/eller din virksomhed i alvorlig fare.",
  No_Faxes_Word:"Opret den første <a routerLink='/folder/Fax/New'>Fax</a>.",
  Cheque_Delete_Confirmation:"Er du sikker på, at du vil slette denne check?",
  Design_Delete_Confirmation:"Er du sikker på, at du vil slette dette design?",
  Cheque_Pay_Confirmation:"Marker denne check som betalt? Den vises IKKE i printkøen.",
  Payment_Pay_Confirmation:"Marker denne betaling som betalt? Den vises IKKE i checkkøen.",
  Delete_Deduction_Confirmation:"Er du sikker på, at du vil slette dette fradrag?",
  Delete_Job_Confirmation:"Er du sikker på, at du vil slette dette job?",
  Delete_Timesheet_Confirmation:"Er du sikker på, at du vil slette denne timeseddel?",
  Delete_Schedule_Confirmation:"Er du sikker på, at du vil slette denne tidsplan?",
  Delete_Setting_Confirmation:"Er du sikker på, at du vil nulstille denne indstilling?",
  Delete_Fax_Confirmation:"Er du sikker på, at du vil slette denne fax?",
  Delete_File_Confirmation:"Er du sikker på, at du vil slette denne fil?",
  Delete_Vacation_Confirmation:"Er du sikker på, at du vil slette denne ferie?",
  Delete_Printer_Confirmation:"Er du sikker på, at du vil slette denne printer?",
  Remove_Design_Confirmation:"Er du sikker på, at du vil slette dette design?",
  Delete_Payroll_Confirmation:"Er du sikker på, at du vil slette denne lønliste?",
  Send_Fax_Email_Confirmation:"Vil du faxe og e-maile disse dokumenter?",
  Send_Email_Confirmation:"Vil du e-maile dette dokument?",
  Send_Fax_Confirmation:"Vil du faxe dette dokument?",
  Error_Generating_PDF:"Beklager. Der opstod en fejl under generering af dette dokument.",
  PDF_Error_Saving_Image:"Beklager. Der opstod en fejl under lagring af PDF-billede af dette dokument.",
  Test_Printer_Confirmation:"Vil du udskrive en testside på denne printer?",
  Save_Timesheet_Prompt:"Tilføj venligst en 'Titel' eller tryk på 'Start Timer' for at gemme.",
  Remove_Geofence_Prompt:"Er du sikker på, at du vil fjerne placeringen af ​​dette geo-hegn?",
  Delete_Employee_Confirmation:"Er du sikker på, at du vil slette ",
  Fire_Employee_Confirmation:"Er du sikker på, at du vil fyre"
}