export const Nl = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Auteursrecht &kopie; 2023",
  black:"Zwart",
  green:"Groente",
  gold:"Goud",
  blue:"Blauw",
  brown:"Bruin",
  purple:"Paars",
  pink:"Roze",
  red:"Rood",
  Swatches:"Stalen",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Tint",
  Saturation:"Verzadiging",
  Lightness:"Lichtheid",
  Upgrade_To_Pro:"Upgraden naar Pro",
  AllFeaturesInclude:"Alle functies omvatten:",
  PrintUnlimitedCheques:"Druk onbeperkt cheques af",
  PremiumChequeDesigns:"Premium cheque-ontwerpen",
  ManageUnlimitedEmployees:"Beheer onbeperkte werknemers",
  AddUnlimitedPayees:"Voeg onbeperkte begunstigden toe",
  CreateUnlimitedPayrolls:"Creëer onbeperkte loonlijsten",
  UnlimitedSchedulesandTimeSheets:"Onbeperkt schema's en urenstaten",
  BulkPayPalPayouts:"Bulk PayPal-uitbetalingen",
  UnlimitedBankAccounts:"Onbeperkte bankrekeningen",
  ManageMultipleCompanies:"Meerdere bedrijven beheren",
  TrackInsurancePolicies:"Volg verzekeringspolissen",
  Bio_MetricProtection:"Biometrische bescherming",
  Geo_FenceLock_OutProtection:"Geo-Fence vergrendelingsbeveiliging",
  Multiple_Companies_Word:"Het beheren van meerdere bedrijven is niet mogelijk zonder Cheques premie.",
  PayPal_Payouts_Word:"PayPal-betalingen zijn uitgeschakeld zonder Cheques-premie.",
  PINProtection:"PIN-beveiliging",
  PasswordProtection:"Wachtwoord beveiliging",
  May_Require_Approval:"Heeft mogelijk goedkeuring nodig.",
  Subscribe:"Abonneren",
  Billed:"Gefactureerd",
  Up:"Omhoog",
  Down:"Omlaag",
  Positioning:"positionering",
  Marker:"Markeerstift",
  Drag_Marker:"Sleep markering",
  Tagline:"Cheques afdrukken en loonlijst in tabelvorm weergeven",
  Marker_Word:"Gebruik de Markers om de grootte van het element te bepalen.",
  Pinch_Zoom:"Knijpen zoomen",
  Pinch_Word:"Knijp om in te zoomen op het element.",
  Drag:"Sleuren",
  Drag_Word:"Gebruik uw vinger om elementen te slepen.",
  subscription_alias_word:"Abonnement automatisch verlengen",
  premium_alias_word:"Eenmalig upgradepakket",
  print_alias_word:"Individuele cheques afdrukken",
  mode_alias_word:"modus",
  Pro:"Pro",
  Pro_word:"Pro-versie vereist.",
  Cant_Delete_Default_Company_Word:"Sorry, u kunt uw standaardbedrijf niet verwijderen.",
  Reinsert_Default_Designs:"Standaardontwerpen opnieuw invoegen",
  Reinsert_Default_Designs_word:"Wilt u standaardontwerpen opnieuw invoegen?",
  Subscription_Active_Disable_Word:"Dit abonnement is actief. Wilt u dit abonnement op Cheques opzeggen?",
  Company_Logo:"Bedrijfslogo",
  ZERO_:"NUL",
  Disclaimer:"Vrijwaring",
  Privacy_Policy:"Privacybeleid",
  EULA:"Controleert EULA",
  Terms_Of_Service:"Servicevoorwaarden",
  Terms_Of_Use:"Gebruiksvoorwaarden",
  Refunds:"Restitutiebeleid",
  Single_Print:"1 Controleer",
  Two_Prints:"2 cheques",
  Five_Prints:"5 cheques",
  Ten_Prints:"10 cheques",
  Fifteen_Prints:"15 cheques",
  Twenty_Prints:"20 cheques",
  Thirty_Prints:"30 cheques",
  Image_Added:"Afbeelding toegevoegd",
  Image_Preview:"Afbeeldingsvoorbeeld",
  No_Image_Was_Selected:"Er is geen afbeelding geselecteerd.",
  Cheques_Unlimited:"Cheques onbeperkt",
  _Subscription:"Abonnement",
  Subscription:"Cheques - 1 maand",
  Two_Month_Subscription:"Cheques - 2 maanden",
  Three_Month_Subscription:"Cheques - 3 maanden",
  Six_Month_Subscription:"Cheques - 6 maanden",
  Twelve_Month_Subscription:"Cheques - 12 maanden",
  Cheques_Are_Available:"Cheques zijn beschikbaar om af te drukken.",
  Upgrade_Required_Two:"Selecteer een pakket en dubbeltik op de prijsknop om uw aankoop te starten. Druk binnen enkele seconden professioneel ogende cheques in kleur af.",
  Month:"Maand",
  Due:"Vanwege:",
  Expires:"Verloopt:",
  Subscription_Active:"Abonnement Actief",
  Subscription_Inactive:"Abonnement inactief",
  Purchase_Additional_Cheques:"Extra cheques kopen?",
  Printable_Cheque:"Afdrukbare cheque",
  Printable_Cheques:"Afdrukbare cheques",
  Printable_Cheque_Word:"cheque is beschikbaar op uw account.",
  Printable_Cheques_Word:"cheques zijn beschikbaar op uw rekening.",
  Max_Amount_Message:"Het door u opgegeven bedrag is hoger dan het maximale bedrag dat voor dit systeem is ingesteld:",
  Terms_Required_Word:"U moet akkoord gaan met deze overeenkomst voordat u Cheques kunt blijven gebruiken.",
  Subscriptions:"Abonnementen",
  Product_Upgrades:"Upgrades",
  Mailed_Out_Cheques:"Uitgezonden cheques",
  Enter_A_Company_Name:"Voer een bedrijfsnaam in.",
  Single_Cheques:"Enkele cheques",
  Cheque_Golden:"Gouden cheque",
  Cheque_Golden_Window:"Ontwerp met gouden ruitjes.",
  Cheque_Green:"Groene ruit",
  Cheque_Green_Window:"Ontwerp met groen ruitje.",
  Cheque_Red:"Rode ruit",
  Cheque_Red_Window:"Rood geruit ontwerp.",
  Cheque_Yellow:"Gele ruit",
  Cheque_Yellow_Window:"Geel geruit ontwerp.",
  Cheque_Statue_of_Liberty:"Vrijheidsbeeld",
  Cheque_Statue_of_Liberty_Window:"Het ruitontwerp van het Vrijheidsbeeld.",
  Cheque_Green_Wave:"Groene Golf",
  Cheque_Green_Wave_Window:"Ontwerp met groen ruitje.",
  Cheque_Golden_Weave:"Gouden weefsel",
  Cheque_Golden_Weave_Window:"Elegant gouden ruitjesdesign.",
  Cheque_Green_Sun:"Groene zon",
  Cheque_Green_Sun_Window:"Diep en rustgevend ruitontwerp.",
  Cheque_Blue_Checkers:"Blauwe dammen",
  Cheque_Blue_Checkers_Window:"Blauw geruit ontwerp.",
  Cashiers_Check:"Cheque van de kassier",
  Cashiers_Check_Window:"Kascheque stijlsjabloon.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Aquacheck-ontwerp.",
  Cheque_Golden_Checkers:"Gouden dammen",
  Cheque_Golden_Checkers_Window:"Ontwerp met gouden ruitjes.",
  Upgrade_Unavailable:"Upgrades niet beschikbaar",
  Bank_Code_Protection:"Banknummerbeveiliging",
  Bank_Code_Protection_Word:"Bescherm uw banknummers tegen gebruik in deze app die op een ander apparaat of voor een andere gebruiker wordt uitgevoerd. Deze actie is ONOMKEERBAAR. Doorgaan?",
  Bank_Code_Protection_Blocked_Word:"De banknummers die u probeert te gebruiken, zijn gereserveerd voor een andere gebruiker of een ander apparaat.",
  Bank_Code_Protection_Error_Word:"Nummerverificatie is mislukt. Maak verbinding met internet en probeer deze bankrekening opnieuw toe te voegen.",
  Bank_Code_Protection_Set_Error_Word:"Banknummerbeveiliging vereist dat u verbonden bent met internet. Maak verbinding en probeer het opnieuw.",
  Upgrade_Unavailable_Word:"Sorry, we hebben problemen om u te verifiëren. Abonnementen en upgrades naar Cheques zijn momenteel niet verkrijgbaar in uw regio.",
  PayPal_Payment_Preview:"PayPal-betalingsvoorbeeld",
  Apple_Pay:"Apple Betalen",
  Select_PayPal:"Selecteer PayPal",
  PayPal_Applications:"PayPal-toepassingen",
  Purchase_With_Apple_Pay:"Koop met Apple Pay",
  Google_Pay:"Google Betalen",
  Companies:"Bedrijven",
  Insurance:"Verzekering",
  New_PayPal:"Nieuwe PayPal",
  Pay_By:"Betalen met",
  Insure:"Verzekeren",
  Miles:"mijlen",
  Payment_Method:"Betalingsmiddel",
  Select_Policies:"Selecteer Beleid",
  Policies:"Beleid",
  Policy:"Beleid",
  No_PayPal_Account:"Geen PayPal-rekening",
  No_Policies:"Geen verzekeringspolissen",
  New_Policy:"Nieuw beleid",
  PayPal_Payment:"PayPal betaling",
  PayPal_Payments:"PayPal-betalingen",
  No_Payment_Selected:"Geen betaling geselecteerd",
  Sending_Payment_Word:"Even geduld... Deze betaling wordt verzonden.",
  Sending_Payments_Word:"Even geduld... Betalingen worden verzonden.",
  No_Payment_Selected_PayPal:"Geen <a routerLink='/folder/Payments'>PayPal-betaling</a> geselecteerd om te verzenden.",
  Payment_Sent:"Betaling verzonden",
  PayPal_Payment_Sent:"Deze betaling is verzonden met PayPal.",
  Copay:"Copay",
  Dead:"Dood",
  Alive:"In leven",
  Not_Dead:"Niet dood",
  Unclaimed:"Niet opgeëist",
  Attempted:"geprobeerd",
  Deceased:"Overleden",
  Claimed:"Geclaimd",
  Unpaid:"Onbetaald",
  Sending_Payment:"Betaling verzenden",
  Sending_Payments:"Betalingen verzenden",
  Send_PayPal_Confirmation:"Wilt u deze transactie met PayPal versturen?",
  Send_PayPal_Confirmation_Word:"Druk op de groene knop om deze transactie te verzenden.",
  Save_Payment_As_Unpaid:"Deze betaling opslaan als onbetaald?",
  Payment_Pay_Confirmation_PayPal:"Deze betaling opslaan als betaald?",
  No_Policies_Word:"Voeg nu de eerste <a routerLink='/folder/Postage/New'>Verzekeringspolis</a> toe.",
  Timesheet_Multiple_Delete_Confirmation:"Weet je zeker dat je meerdere urenstaten wilt verwijderen?",
  Select_Multiple_Timesheets_Prompt:"Geen urenstaten geselecteerd. Selecteer ten minste één urenstaat.",
  Select_Multiple_Policies_Prompt:"Geen beleid geselecteerd. Selecteer minimaal één verzekering.",
  Policies_Multiple_Delete_Confirmation:"Weet u zeker dat u meerdere beleidsregels wilt verwijderen?",
  Company:"Bedrijf",
  Add_Company:"Bedrijf toevoegen",
  New_Company:"Bedrijf toevoegen",
  No_Companies:"Geen bedrijven",
  Enable_Company:"Bedrijf inschakelen",
  Select_Company:"Selecteer Bedrijf",
  The_Default_Company:"Het standaard bedrijfslabel.",
  Manage_Companies:"Bedrijven beheren",
  No_Companies_Word:"Cheques gebruiken een standaardbedrijf .<br />Voeg het <a routerLink='/folder/Company/New'>eerste bedrijf</a> toe.",
  Default_Company:"Standaard bedrijf",
  Cheques_Unlimited_Word:"Met Cheques Unlimited kunt u zoveel cheques afdrukken als u wilt.",
  Cheques_Subscription_Word:"Met een abonnement op Cheques kunt u zoveel cheques afdrukken als u wilt.",
  You_Own_This_Product:"U bent eigenaar van dit product.",
  Your_Subscription_is_Active:"Je abonnement is actief.",
  Active_Products:"Geactiveerde producten",
  Purchase_Confirmation:"Aankoop",
  Purchase_Cheques:"Aankoopcheques",
  Restore_Purchase:"Aankopen herstellen",
  Erase_Purchase:"Wis aankopen",
  Successfully_Purchased:"Succesvol gekocht",
  Enter_Your_Licence_Key:"Voer uw licentiesleutel op deze pagina in om dit product te activeren.",
  Licence_Key:"Licentiesleutel",
  Enter_Licence_Key:"Voer de licentiecode in",
  Purchased:"Gekocht",
  Enable_Feature:"Functie inschakelen",
  Cancel_Subscription:"Annuleer abonnement",
  Subscription_Removed:"Abonnement verwijderd",
  Select_Active_Subscription:"Selecteer een actief abonnement om het te wijzigen.",
  Remove_Subscription_Word:"Weet je zeker dat je dit abonnement wilt opzeggen?",
  Delete_Company_Confirmation:"Weet u zeker dat u dit hele bedrijf wilt verwijderen? WAARSCHUWING: U verliest alle opgeslagen informatie!",
  Delete_Default_Company_Word:"U kunt het standaardbedrijf niet verwijderen. Wilt u de applicatie resetten en terugzetten naar de standaardstatus? WAARSCHUWING: U verliest alle opgeslagen informatie!",
  Console_Warning_2:"Verwerk geen valuta met deze applicatie die momenteel niet van u is.",
  Terms_and_Conditions:"Voorwaarden",
  and_the:"en de",
  for:"voor",
  Please_Read_Word:"Gelieve te lezen en akkoord te gaan met de",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Sommige wisselkoersen konden niet worden gevonden. Maak verbinding met internet.",
  Free:"Vrij",
  DB_Erase_Prompt_2:"De volledige ontwikkelaarsdatabase volledig wissen? WAARSCHUWING: U verliest alle AANKOOP- en ABONNEMENT-informatie!",
  Successfully_Imported:"Succesvol geïmporteerd",
  Select_Postage:"Selecteer Verzendkosten",
  No_Postage:"Geen postzegels",
  No_Paid_Postage_Word:"Voeg de eerste <a routerLink='/folder/Postage/New'>Betaalde Postzegel</a> toe.",
  Trial_Complete:'Proef voltooid',
  Please_Upgrade:'Upgrade Cheques om door te gaan met afdrukken.',
  Aa:"Aa",
  Color:"Kleur",
  Font:"Lettertype",
  Guide:"Gids",
  Guides:"Gidsen",
  Image:"Afbeelding",
  Zoom:"Zoom",
  Logo:"Logo",
  Bank:"Bank",
  MICR:"MICR",
  Total:"Totaal",
  Cancel:"Annuleren",
  Confirm:"Bevestigen",
  Method:"Methode",
  Biometric_Security:"Biometrische beveiliging",
  Please_Login_To_Continue:"Gelieve in te loggen om verder te gaan.",
  Complete:"Compleet",
  Sign_Up:"Aanmelden",
  Error:"Fout",
  Biometrics:"Biometrie",
  Percent:"procent",
  Zero_Percent:"0%",
  Top_Margin:"Bovenmarge",
  Bottom_Margin:"De ondergrens",
  Left_Margin:"Linkermarge",
  Right_Margin:"Rechter marge",
  MICR_Margin:"MICR-marge",
  Table_Margin:"Tafelmarge",
  Address_Margin:"Adresmarge",
  Percentage_:"Percentage",
  Vacation_Title:"Vakantie Titel",
  Use_PIN:"Gebruik pincode",
  Loading__:"Bezig met laden...",
  Design_Title:"Ontwerptitel",
  Authorize:"Toestemming geven",
  Key:"Sleutel",
  Public_Key:"Publieke sleutel",
  Private_Key:"Prive sleutel",
  Authenticate:"authenticeren",
  Last_Payroll:"Laatste loonlijst",
  Last_Calculation:"Laatste berekening",
  Authorized:"Geautoriseerd",
  Geo_Authorized:"Geo-locatie: geautoriseerd",
  Not_Authorized:"Geen bevoegdheid",
  Authorization_Complete:"Autorisatie voltooid",
  Geolocation_Authorization:"Autorisatie voor geografische locatie",
  Out_of_Bounds:"Buiten de grenzen",
  Cant_Delete_Default_Design:"Kan een standaardontwerp niet verwijderen.",
  Unauthorized_Out_of_Bounds:"Ongeautoriseerd: Buiten de grenzen",
  Biometric_Authorization:"Biometrische autorisatie",
  Locating_Please_Wait:"Lokaliseren, een ogenblik geduld...",
  Test_Biometrics:"Biometrie testen",
  Cheque_Margins:"Marges controleren",
  Percentage_Full_Error:"Het percentageveld kan niet hoger dan 100% procent worden ingesteld.",
  No_Payroll_Text:"Voeg een <a routerLink='/folder/Employee/New'>Werknemer</a> of <a routerLink='/folder/Payee/New'>Begunstigde</a> en een <a routerLink='/folder/Schedule/New'>Plan</a>.",
  Design_Saved:"Ontwerp opgeslagen",
  Default_Design_Selected:"Standaardontwerp geselecteerd",
  Partial_Import:"Gedeeltelijke import",
  Partial_Export:"Gedeeltelijke export",
  Entire_Import:"Gehele import",
  Entire_Export:"Gehele export",
  Existing_Password:"Voer uw bestaande wachtwoord in:",
  Existing_PIN:"Voer uw bestaande pincode in:",
  Pin_Change_Header:"PIN-bevestiging",
  Pin_Change_SubHeader:"Voer uw oude pincode in om de wijziging te bevestigen.",
  Pass_Change_Header:"Wachtwoordbevestiging",
  Pass_Change_SubHeader:"Voer uw oude wachtwoord in om de wijziging te bevestigen.",
  PIN_Enter_Message:"Voer uw pincode in om te bevestigen.",
  Password_Enter_Message:"Voer uw wachtwoord in om te bevestigen.",
  Pin_Updated_Success:"Pincode succesvol geüpdatet!",
  Pin_Updated_Fail:"Pincode kan niet worden bijgewerkt.",
  Pass_Updated_Success:"wachtwoord succesvol bijgewerkt.",
  Pass_Updated_Fail:"Wachtwoord kon niet worden bijgewerkt.",
  Preview_Payment:"Voorbeeld betaling",
  Preview_Payroll:"Voorbeeld salarisadministratie",
  No_Payments_Created:"Er zijn geen betalingen gevonden.",
  Payment_Preview:"Betaalvoorbeeld",
  Enable_Payee:"Begunstigde inschakelen",
  Rendered:"weergegeven",
  No_Email:"Geen e-mail",
  Setup_Cheques:"Configuratiecontroles",
  name:"Naam",
  address:"Adres",
  address_2:"Adres lijn 2",
  city:"Stad",
  province:"Provincie",
  postal_code:"Postcode",
  country:"Land",
  username:"gebruikersnaam",
  full_name:"Voor-en achternaam",
  birthday:"Verjaardag",
  email:"E-mail",
  phone:"Telefoon",
  employees:"Medewerkers",
  addresses:"Adressen",
  payment_amount_limit:"Limiet betalingsbedrag",
  total_limit:"Totale limiet",
  payees:"begunstigden",
  description:"Beschrijving",
  address_line_one:"Adresregel één",
  address_line_two:"Adresregel twee",
  image:"Afbeelding",
  account_holder:"Rekeninghouder",
  cheque_starting_id:"Start-ID controleren",
  transit_number:"Vervoers nummer",
  institution_number:"Instellingsnummer",
  designation_number:"Benaming Nummer:",
  account_number:"Rekeningnummer",
  currency:"Munteenheid",
  signature:"Handtekening",
  payment_payroll_limit:"Betalingslimiet",
  total_limi:"Totale limiet",
  date:"Datum",
  printed_memo:"Gedrukte memo",
  banks:"Banken",
  signature_image:"Handtekening Afbeelding",
  address_name:"Adres naam",
  notes:"Opmerkingen:",
  design:"Ontwerp",
  title:"Titel",
  frequency:"Frequentie",
  fax:"Fax",
  salaries:"Salarissen",
  salary_ids:"Salaris-ID's",
  start_time:"Starttijd",
  end_time:"Eindtijd",
  paid:"Betaald",
  overtime_percentage:"Betaald Percentage",
  overtime_amount:"Betaald vast bedrag",
  first_name:"Voornaam",
  last_name:"Achternaam",
  moderation:"Met mate",
  create:"Creëren",
  edit:"Bewerk",
  destroy:"Kapot maken",
  day_start_time:"Day_start_time",
  day_end_time:"Day_end_time",
  fullname:"Naam",
  time:"Tijd",
  auto_send:"Automatisch verzenden",
  time_method:"Tijdmethode:",
  schedules:"schema's",
  indefinite_payroll_enabled:"Onbeperkt inschakelen",
  amount:"Hoeveelheid",
  repeat:"Herhalen",
  always_enabled:"Altijd ingeschakeld",
  start_date:"Startdatum",
  end_date:"Einddatum",
  Cheque_Total:"Controleer totaal",
  Total_Amount:"Totaalbedrag:",
  Amounts:"bedragen:",
  Images:"Afbeeldingen",
  Files:"Bestanden",
  Previewing:"Voorvertoning:",
  Insert:"Invoegen",
  Preview_Import:"Voorbeeld importeren",
  Entry:"binnenkomst",
  Entries:"Inzendingen",
  No_Entries:"Geen toegang",
  Import_Type:"Importtype",
  Select_Details:"Selecteer details",
  Select_Payee:"Selecteer begunstigde",
  Enable_Holidays:"Feestdagen inschakelen",
  Disable_Holidays:"Feestdagen uitschakelen",
  Wire_Transfer:"Overschrijving",
  New_Export:"Nieuwe export",
  Export_Data:"Exportgegevens",
  Export_Data_Word:"Selecteer het type bestand dat u wilt exporteren en downloaden.",
  Export_File:"Bestand exporteren",
  Mode:"Modus",
  Times:"Keer",
  Widgets:"Widgets",
  Slider:"Schuifregelaar",
  Set_Details:"Details instellen",
  Select_Type:"Selecteer type",
  Display_Slider:"Schuifregelaar weergeven",
  Dashboard_Slider:"Dashboardschuifregelaar",
  Dashboard_Mode:"Dashboard-modus",
  Show_Intro:"Toon Intro",
  Show_Payrolls:"Toon loonlijsten",
  Show_Holidays:"Feestdagen weergeven",
  Show_Invoices:"Facturen tonen",
  Show_Cheques:"Toon cheques",
  Enabled_Widgets:"Ingeschakelde widgets",
  Disabled_Widgets:"Uitgeschakelde widgets",
  Colors:"kleuren",
  Barcodes:"Barcodes",
  View_Timers:"Timers bekijken",
  Gradients:"verlopen",
  No_Info:"Geen informatie",
  Disable:"Uitzetten",
  Show_Layer:"Lagen tonen",
  Hide_Layer:"Lagen verbergen",
  Text_Layer:"Tekstlagen",
  Secondly:"ten tweede",
  Minutely:"Minutieus",
  nine_am:"09:00",
  five_pm:"17:00 uur",
  Enable_Address:"Adres inschakelen",
  Invalid_File_Type:"Sorry, er is een ongeldig bestandstype geselecteerd. Ondersteund bestandstype:",
  Error_Updating_Entry:"Sorry, er is een fout opgetreden bij het bijwerken van dit item.",
  Schedule_Timing_Alert:"Fout: De starttijd van het schema is ingesteld op een waarde na de eindtijd.",
  Select_Multiple_Payments_Prompt:"Geen betalingen geselecteerd. Selecteer minimaal één betaling.",
  Select_Multiple_Cheques_Prompt:"Geen cheques geselecteerd. Selecteer ten minste één vinkje.",
  Select_Multiple_Items_Prompt:"Geen items geselecteerd. Selecteer ten minste één item.",
  Paymemt_Multiple_Delete_Confirmation:"Weet u zeker dat u meerdere betalingen wilt verwijderen?",
  Cheque_Multiple_Delete_Confirmation:"Weet u zeker dat u meerdere cheques wilt verwijderen?",
  Payee_Multiple_Delete_Confirmation:"Weet u zeker dat u meerdere begunstigden wilt verwijderen?",
  Employee_Multiple_Delete_Confirmation:"Weet u zeker dat u meerdere medewerkers wilt verwijderen?",
  MICR_Warning:"Opmerking: Sommige printers en apparaten geven het MICR-lettertype mogelijk niet correct weer.",
  Automatically_Send:"Automatisch verzenden",
  Type:"Type",
  Payment_Type:"Betalingswijze",
  Auto_Send:"Automatisch verzenden",
  Automatically_Process:"Automatisch verwerken",
  Auto_Process:"Automatisch verwerken",
  Image_Based:"Op afbeeldingen gebaseerd",
  Font_Based:"Op lettertype gebaseerd",
  Rerender:"Opnieuw renderen",
  Rendering:"weergave",
  Render:"Bestanden",
  Top:"Bovenkant",
  Middle:"Midden",
  Bottom:"Onderkant",
  Top_Left:"Linksboven",
  Top_Center:"Midden boven",
  Top_Right:"Rechtsboven",
  Middle_Left:"Midden Links",
  Middle_Center:"Midden midden",
  Middle_Right:"Midden Rechts",
  Bottom_Left:"Linksonder",
  Bottom_Center:"Middenonder",
  Bottom_Right:"Rechts onder",
  Numbers:"Cijfers",
  Verified:"Geverifieerd",
  Paper_Size:"Papiergrootte",
  New_Device:"Nieuw apparaat",
  Add_Device:"Voeg toestel toe",
  Remove_Device:"Verwijder apparaat",
  Delete_Device:"Apparaat verwijderen",
  Block_Device:"Apparaat blokkeren",
  Block:"Blok",
  Unblock:"Deblokkeren",
  Table:"Tafel",
  Scan_Login_Code:"Inlogcode scannen",
  Login_Code:"Inlogcode",
  Scan_Code:"Scancode",
  Scan_QR_Code:"Scan QR-code",
  Select_All:"Selecteer alles",
  Deselect_All:"Deselecteer alles",
  Increase:"Toenemen",
  Decrease:"Kleiner worden",
  Bold:"Vetgedrukt",
  Text:"Tekst",
  Style:"Stijl",
  Italic:"Cursief",
  QR_Code:"QR code",
  Barcode:"streepjescode",
  Browse_Images:"Bladeren door afbeeldingen",
  Browse_Files:"Bestanden doorbladeren",
  Background_Image:"Achtergrond afbeelding",
  Logo_Image:"Logo afbeelding",
  Header_Image:"Koptekstafbeelding",
  Bank_Image:"Bankafbeelding",
  Cut_Lines:"Snijlijnen",
  Background:"Achtergrond",
  License:"Licentie",
  One_License:"1 Licentie:",
  Licensed:"Bevoegd om:",
  Not_Licensed:"Geen licentie",
  Enter_Serial:"Voer serienummer in",
  Serial_Key:"Serienummer",
  Serial:"serieel",
  Product_Key:"Product sleutel",
  Check_Product_Key:"Controleer de productsleutel",
  Add_Product_Key:"Productsleutel toevoegen",
  Verifying_Purchase:"Aankoop verifiëren...",
  Print_Envelope:"Envelop afdrukken",
  Envelope:"Envelop",
  Thank_You:"Bedankt!",
  Scale:"Schaal",
  Print_Scale:"Afdrukschaal",
  Borders:"grenzen",
  VOID:"LEEGTE",
  Void_Cheque:"ongeldige controle",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"BETALEN OP BESTELLING VAN:",
  NO_DOLLARS:"GEEN DOLLARS",
  ONE_DOLLAR:"EEN DOLLAR",
  DOLLARS:"DOLLARS",
  AND:"EN",
  CENTS:"CENTEN.",
  NO_:"NEE",
  ONE_:"EEN",
  AND_NO_:"EN NEE",
  _AND_ONE_:"EN EEN",
  DOLLARS_AND_ONE_CENT:"EN EEN CENT.",
  AND_NO_CENTS:"EN NUL CENTEN.",
  CHEQUE_PRINT_DATE:"DATUM:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/100",
  CHEQUE_PRINT_SIGNATUREMP:"Kamerlid",
  Initial_Tasks:"Initiële taken",
  Inital_Setup:"Eerste instelling",
  Welcome_To:"Welkom bij ",
  Welcome:"Welkom",
  Swipe:"Vegen",
  Intro_Setup:"Intro Setup",
  Introduction:"Invoering",
  CHEQUE_PRINT_CREDIT:"Aangedreven door cheques",
  Title_Intro_Word:"Welkom bij Cheques",
  Title_Intro:"Introductie cheques",
  Title_Setup:"Cheques instellen",
  PayPal_Default_Email_Message:"U heeft een nieuwe PayPal-overboeking ontvangen. [Powered by Cheques]",
  Cheques_App_Export:"Geëxporteerd door cheques",
  Post_Intro_Thanks:"Bedankt voor het kiezen van Cheques. Het installatieproces is nu voltooid.",
  Post_Intro_Word:"Ga aan de slag door uw eerste cheque af te drukken, een toekomstige betaling toe te voegen of een werknemer toe te voegen aan de loonlijst.",
  Upgrade_Required:"Voor cheques moet je een premiumversie van de software hebben om dit bericht te verbergen en extra functies te ontgrendelen.",
  Upgrade_Title:"Cheques",
  Mailout_Prompt:"U kunt er ook voor kiezen om Cheques uw looncheques voor u te laten versturen.",
  Mailed_Cheque:"Gemailde cheque: ",
  Mailed_Cheque_Color:"Gemailde cheque (kleur): ",
  Terms_Purchase:"Alle elektronische aankopen met Cheques kunnen tot 30 dagen vanaf de aankoopdatum volledig worden terugbetaald. Lees en ga akkoord met de <a href='#'>Algemene voorwaarden</a> voordat u verdergaat.",
  Dashboard_Setup:"De primaire printer instellen",
  Dashboard_Add:"Voeg de primaire bankrekening toe",
  Dashboard_Customize:"Selecteer een chequesjabloon",
  Dashboard_Job_Salary:"Maak uw baan en voeg uw salaris toe",
  Dashboard_Employees:"Werknemers en begunstigden volgen",
  Dashboard_Print:"Print & Mail uw loonstrookjes",
  Dashboard_Payroll:"Automatiseer uw salarisafdrukken",
  Dashboard_PayPal:"PayPal Payroll / Uitbetalingen instellen",
  Begin_Setup:"Begin met instellen",
  Get_Started:"Begin",
  Purchase:"Aankoop",
  Lockdown:"Lockdown",
  Unlock:"Ontgrendelen",
  Detailed:"Gedetailleerd",
  Log_In:"Log in",
  Login:"Log in",
  Launch:"Launch",
  Register:"Register",
  Finish:"Finish",
  List:"Lijst",
  Weekends:"Weekend",
  Weekly:"Wekelijks",
  PayPal_Default_Subject:"Nieuwe betaling",
  Payment_Message:"Betalingsbericht",
  PayPal_Default_Payment_Note:"Bedankt",
  Setup_Your_Cheqing_Account:"Betaalrekening",
  Add_Your_Primary_Cheqing_Account:"Voeg uw primaire betaalrekening toe.",
  Welcome_Word:"Vereenvoudig en automatiseer het salaris- en personeelsbeheer.",
  Get_Started_Quickly:"Beantwoord een paar simpele vragen die ons op weg helpen...",
  Done_Intro_Word:"Setup voltooid",
  PIN_Protected:"Wachtwoord en pincode beveiligd",
  Enter_New_PIN:"Voer een nieuwe pincode in:",
  Enter_PIN:"Vul pincode in:",
  Invalid_PIN:"Ongeldige pincode ingevoerd.",
  Account_Identifier:"Account-ID",
  New_Account_Identifier:"Nieuwe account-ID",
  attempt:"poging",
  attempts:"pogingen",
  remaining:"overig",
  Language:"Taal",
  languages:"Talen",
  select_languages:"Taal selecteren",
  Deposit:"Borg",
  Hire_One_Now:"Huur er nu een",
  App_Locked:"De applicatie is vergrendeld.",
  Skip_:"Overslaan",
  Skip_to_Dashboard:"Ga naar het dashboard",
  Dashboard:"Dashboard",
  Import:"Importeren",
  Admin:"Beheerders",
  New_Admin:"Nieuwe beheerder",
  Settings:"Instellingen",
  Color_Picker:"Kleur kiezer",
  Font_Picker:"Lettertypekiezer",
  Logout:"Uitloggen",
  Close:"Dichtbij",
  Close_menu:"Dichtbij",
  Excel:"Excel bestand",
  Csv:"CSV-bestand",
  Sql:"SQL-bestand",
  Json:"JSON-bestand",
  Url:"Importeren op URL",
  Back:"Rug",
  Timers:"Timers",
  Cheque:"Controleren",
  Print:"Afdrukken",
  Designs:"Ontwerpen",
  Pause_Printing:"Pauzeer afdrukken",
  Resume_Printing:"Hervat afdrukken",
  Printing_Paused:"Afdrukken onderbroken",
  PrintingUnavailable:"Sorry! Afdrukken is niet mogelijk op dit systeem.",
  Prints_Paused:"Afdrukken onderbroken",
  Administration:"Administratie",
  Loading:"Bezig met laden",
  Unnamed:"Naam",
  error:"Sorry, deze cheque kon niet worden geopend om te bekijken.",
  No_Cheques_To_Print:"Geen cheques om af te drukken",
  No_Cheques_To_Print_Word:"Maak een <a routerLink='/folder/Cheque/New'>Nieuwe Cheque</a>.",
  New_Cheque:"Nieuwe cheque",
  Start_One_Now:"Start er nu een",
  Edit_Cheque:"Bewerk controle",
  Select_Cheques:"Selecteer cheques",
  Select_Employee:"Kies werknemer",
  Default_Printer:"Standaard printer",
  Reassign:"Opnieuw toewijzen",
  Configure:"Configureren",
  Template:"Sjabloon",
  Designer:"Ontwerper",
  Edit_Designs:"Ontwerpen bewerken",
  New_Design:"Nieuw ontwerp",
  Next:"Volgende",
  Save:"Sparen",
  Name:"Naam",
  Mail:"Mail",
  Amount:"Hoeveelheid",
  Date:"Datum",
  PayPal:"PayPal",
  Payouts:"Uitbetalingen",
  PayPal_Label:"PayPal-label",
  Email_Username:"E-mail / gebruikersnaam",
  Client_ID:"Klant identificatie",
  Sandbox_Email:"Sandbox-e-mail",
  PayPal_Email:"Paypal E-mail",
  PayPal_Username:"API-gebruikersnaam",
  PayPal_Payouts:"PayPal-uitbetalingen",
  Select_PayPal_Key:"Selecteer PayPal-sleutel",
  Secret:"Geheim",
  API_Secret:"API-geheim",
  PayPal_API_Keys:"PayPal-sleutels",
  New_PayPal_Key:"Nieuwe PayPal-sleutel",
  Email_Subject:"E-mail onderwerp",
  Email_Message:"Email bericht",
  Payout_Options:"Uitbetalingsopties",
  Payment_Note:"Betalingsnota",
  Enable_Employee:"Werknemer inschakelen",
  Enable_Production_Mode:"Productiemodus inschakelen",
  Sandbox_Username:"Sandbox-gebruikersnaam",
  Sandbox_Signature:"Sandbox-handtekening",
  Sandbox_Password:"Sandbox-wachtwoord",
  Production_Username:"Productie gebruikersnaam",
  Production_Signature:"Productiehandtekening",
  Production_Password:"Productiewachtwoord",
  Production_Email:"Productie e-mail",
  API_Client_ID:"API-client-ID",
  API_Signature:"API-handtekening",
  API_Password:"API-wachtwoord",
  API_Username:"API-gebruikersnaam",
  Secret_Key:"Geheime sleutel",
  Sandbox:"Zandbak",
  Production:"Productie",
  Sandbox_Keys:"Sandbox-sleutels",
  Production_Keys:"Productiesleutels",
  API_Title:"API-titel",
  Production_Mode:"Productiemodus",
  Account_Label:"Accountlabel",
  No_PayPal_Setup:"Geen PayPal-sleutel",
  Enable_PayPal_Account:"Schakel PayPal-account in",
  No_PayPal_Word:"Voeg uw <a routerLink='/folder/Invoice/New'>PayPal API-sleutel</a> toe.",
  Printed_Memo:"Gedrukt memo",
  Employee:"Medewerker",
  View_Employee:"Bekijk Medewerker",
  Mailing_Address:"Postadres",
  Company_Address:"Bedrijfsadres",
  Select_Company_Address:"Selecteer bedrijfsadres",
  Address:"Adres",
  Any_Day:"Elke dag",
  Address_Name:"Adres naam",
  Unit:"Eenheid",
  Account:"Rekening",
  Bank_Account:"Bankrekening",
  Account_Limits:"Accountlimieten inschakelen",
  Payroll:"Loonlijst",
  New_Payroll:"Nieuwe loonlijst",
  No_Payroll:"Geen aanstaande loonlijst",
  Upcoming_Holiday:"Aanstaande vakantie:",
  Invoice_Due:"Factuur verschuldigd:",
  New_Invoice:"Nieuwe factuur",
  No_Invoices:"Geen facturen",
  No_Invoices_Word:"Maak de eerste <a routerLink='/folder/Invoice/New'>Factuur</a>.",
  Cheque_Due:"Te betalen cheque:",
  Payrolls:"Loonlijsten",
  Sandbox_Mode:"Sandbox-modus",
  Hire:"Arbeidskracht",
  Pay:"Betalen",
  New:"Nieuw",
  Add:"Toevoegen",
  Make:"Maken",
  Time:"Tijd",
  Write:"Schrijven",
  Holiday:"Vakantie",
  Holidays:"Vakantie",
  Next_Holiday:"Volgende vakantie:",
  All_Done:"Helemaal klaar!",
  Employees:"Medewerkers",
  Payees:"Betalers",
  Job:"Functie",
  Jobs:"banen",
  Invoice:"Factuur",
  Invoices:"Facturen",
  Vacations:"Vakanties",
  Cheques:"Controles",
  Brand_Cheques:"Merk",
  Payment:"Betaling",
  Enable_Payment:"Betaling inschakelen",
  Payments:"Betalingen",
  Schedule:"Schema",
  Schedules:"Schema's",
  Timesheet:"Timesheet",
  Timesheets:"Timesheets",
  Salary:"Salaris",
  New_Address:"Nieuw adres",
  Address_2:"Adres lijn 2)",
  _City:"Stad",
  _State:"Staat/Prov",
  City:"Stad / Gemeente",
  State:"Staat / Provincie",
  Postal:"Postcode",
  ZIP:"Post / ZIP",
  Country:"Land",
  Addresses:"Adressen",
  Required_Options:"Noodzakelijke opties",
  Optional_Options:"Optionele Opties",
  Additional_Options:"Toegevoegde opties",
  Required:"Vereist",
  Optional:"Optioneel",
  Additional:"Aanvullend",
  No_Addresses:"Nee Adressen",
  New_Address_Word:"De eerste <a routerLink='/folder/Address/New'> Adres </a> toevoegen.",
  Select_Address:"Selecteer adres",
  No_Address:"Nee Adressen",
  Print_Cheque:"Afdrukcontrole",
  Print_Cheque_Now:"Print nu controleren",
  Description:"Beschrijving",
  Pay_To_The_Order_Of:"Betalen aan de Orde van:",
  Select_Date_Range:"Selecteer datumbereik",
  Select_Starting_Date:"Select begindatum",
  Select_Ending_Date:"Select Einddatum",
  Select_Date:"Selecteer een datum",
  Cheque_Date:"Controleer de datum",
  Cheque_Memo:"Controle Memo",
  Blank_Cheque:"Blanco cheque",
  Blank:"Blanco",
  No_Cheques:"No Controles",
  No_Cheques_Word:"Druk uw eerste <a routerLink='/folder/Cheque/New'> Controleer </a>.",
  Cheque_Amount_Placeholder:"$ 0.00",
  View_Image:"Bekijk afbeelding",
  View:"Visie",
  Modify:"Aanpassen",
  Delete:"Verwijderen",
  Cheque_Paid:"Betaald",
  New_Deduction:"Nieuwe aftrek",
  Title:"Titel",
  Frequency:"Frequentie",
  Hourly:"Per uur",
  Daily:"Dagelijks",
  Weekdays:"Doordeweekse dagen",
  BiWeekly:"2 weken",
  TriWeekly:"3 weken",
  Monthly:"Maandelijks",
  MiMonthly:"2 maanden",
  Quarterly:"Per kwartaal",
  Yearly:"Jaarlijks",
  Every_Week:"Elke week",
  Every_Payroll:"Elke Payroll",
  Every_Month:"Elke maand",
  Annually:"Jaarlijks",
  Always_Scheduled:"Altijd gepland",
  Start_Date:"Begin datum",
  End_Date:"Einddatum",
  Start_Time:"Starttijd",
  End_Time:"Eindtijd",
  Deduction_Label:"Aftreklabel",
  Notes:"Opmerkingen",
  Options:"Opties",
  Enable:"Inschakelen",
  Select_Deductions:"Selecteer aftrekposten",
  Deductions:"Afhoudingen",
  No_Deductions:"Geen aftrekposten",
  No_Deductions_Word:"Uw eerste <a routerLink='/folder/Deduction/New'> Aftrek </a>.",
  New_Employee:"Nieuwe medewerker",
  No_Title:"Geen titel",
  _Name:"Naam",
  About_Yourself:"Over jezelf",
  Full_Name:"Voor-en achternaam",
  Birthday:"Verjaardag",
  Email:"E-mail",
  SMS:"SMS",
  Phone:"Telefoon",
  Test:"Test",
  Call:"Telefoongesprek",
  Fax:"Fax",
  Printed_Note:"Gedrukte notitie",
  Position:"Positie",
  Job_Position:"Baan positie",
  Select_a_Job:"Selecteer een baan",
  Select_a_Salary:"Selecteer een salaris",
  Add_a_Deduction:"Voeg een aftrek toe",
  Taxes:"Belastingen",
  Add_Taxes:"Belastingen Add",
  Date_of_Birth:"Geboortedatum",
  Email_Address:"E-mailadres",
  Phone_Number:"Telefoonnummer",
  Phone_Call:"Telefoongesprek",
  Enable_on_Payroll:"Schakel op de loonlijst",
  Select_Employees:"Selecteer medewerkers",
  No_Employees:"Nee Werknemers",
  No_Employees_Word:"Voeg uw eerste nieuwe <a routerLink='/folder/Employee/New'>Employee</a> toe.",
  No_Name:"Geen naam",
  Unemployeed:"Werkloos",
  Employeed:"In dienst",
  Paid:"Betaald",
  Enabled:"Ingeschakeld",
  Disabled:"Gehandicapt",
  Fire:"Vuur",
  Not_Available:"Niet beschikbaar",
  Not_Available_Word:"Druk uw eerste <a routerLink='/folder/Invoice/New'> Invoice </a> & betaald.",
  Select_Invoices:"Select_Facturen",
  Print_Invoice_Word:"Druk uw eerste <a routerLink='/folder/Invoice/New'> Invoice </a> & betaald.",
  Invoice_Title:"Factuurtitel",
  Invoice_Date:"Factuur datum",
  Due_Date:"Deadline",
  New_Job:"Nieuwe baan",
  Details:"Details",
  Customize:"Aanpassen",
  Customize_Dashboard:"Dashboard aanpassen",
  Components:"Componenten",
  No_Components:"Geen componenten",
  Main_Components:"Hoofd onderdelen",
  Smaller_Components:"Kleinere Componenten",
  Error_Loading_Page:"Fout bij het laden van deze pagina.",
  Bank_Details:"Bankgegevens",
  About_Your_Job:"Over je baan",
  Your_Schedule:"Jouw schema",
  Job_Title:"Functietitel",
  Job_Description:"Functieomschrijving",
  Job_Details:"Details van de baan",
  Enable_Job:"Taak inschakelen",
  Pay_Period:"Betaalperiode",
  Perpetually_Schedule:"Perpetually Schedule",
  Select_Jobs:"Selecteer banen",
  No_Jobs:"Geen banen",
  Add_Jobs:"Vacatures toevoegen",
  No_Jobs_Word:"Voeg de eerste <a routerLink='/folder/Job/New'>Job</a> toe aan de lijst.",
  Count_Employees:"job.employee_count+' Werknemers'",
  Zero_Employees:"0 Medewerkers",
  New_Leave:"Nieuw verlof",
  Leave_Name:"Laat naam achter",
  Paid_Leave:"Betaald verlof",
  Leave_Pay:"Verlofgeld",
  Indefinite_Leave:"Onbepaald verlof",
  Indefinite_Payroll:"Onbepaalde loonlijst",
  Leave:"Het verlof",
  Add_Schedules:"Schema's toevoegen",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Afwezigheid inschakelen",
  Select_Leaves:"Selecteer bladeren",
  No_Leaves:"Geen bladeren van afwezigheid",
  Leave_Of_Absence:"Verlof",
  Leaves_Of_Absence:"Bladeren van afwezigheid",
  New_Leave_of_Absense:"Nieuw verlof",
  No_Leaves_Word:"Voeg de eerste <a routerLink='/folder/Leave/New'>Afwezigheidsverlof</a> toe.",
  Not_Enabled:"Niet aangezet",
  Absences:"Afwezigheden",
  Payee:"Begunstigde",
  New_Payee:"Nieuwe begunstigde",
  Payee_Identifier:"Begunstigde-identificatie",
  Payee_Name:"Naam van de begunstigde",
  Payee_Title:"titel begunstigde",
  Payment_Memo:"Betalingsnota",
  Select_Payees:"Selecteer begunstigden",
  No_Payees:"Geen begunstigden",
  Add_Payee_Note:"Voeg de eerste <a routerLink='/folder/Payee/New'>Begunstigde</a> toe.",
  New_Payees:"Nieuwe begunstigden",
  About_The_Payment_Schedule:"Betalingsschema",
  Your_Payroll_Schedule:"Automatische Salaris",
  New_Payment:"Nieuwe betaling",
  Identifier:"Identificatie",
  Select:"Kies",
  Memo:"Memo",
  Payment_Date:"Betaaldatum",
  Mark_as_Paid:"Markeren als betaald",
  Select_Payments:"Selecteer Betalingen",
  No_Payments:"Geen betalingen",
  No_Payments_Word:"Maak de eerste <a routerLink='/folder/Payment/New'>Betaling</a>.",
  Create_Payroll:"Aanmaken Salaris",
  Properties:"Eigenschappen",
  Payroll_Title:"Payrolltitel",
  Payroll_Notes:"Loonnota's",
  Payroll_Setup:"Looninstelling",
  Tabulate_Payments:"Tabel betalingen",
  Tabulate:"Tabel",
  By:"Door:",
  Payments_By:"Betalingen Door",
  Timesheets_And_Schedules:"Timesheets & Roosters",
  Both:"Beide",
  Items:"Artikelen",
  Add_Payees:"Begunstigden toevoegen",
  Add_Account:"Account toevoegen",
  Enable_Account:"Account inschakelen",
  Enable_Payroll:"Schakel salarisadministratie in",
  Print_Payroll:"Afdrukken loonlijst",
  No_Payrolls:"Geen loon",
  No_Payroll_Word:"Maak uw eerste <a routerLink='/folder/Payroll/New'>Payroll</a>.",
  View_more:"BEKIJK MEER",
  Less:"MINDER",
  Add_Payroll:"Voeg loonlijst toe",
  Select_Payroll:"Selecteer Salaris",
  About_Your_Salary:"Over uw salaris",
  Add_Salaries:"Salarissen toevoegen",
  Add_Salary:"Salaris toevoegen",
  Salaries:"Salarissen",
  No_Salaries:"Geen Salarissen",
  No_Salaries_Word:"Voeg de eerste <a routerLink='/folder/Salary/New'>Salaris</a> toe.",
  Select_Salaries:"Selecteer Salarissen",
  New_Salary:"Nieuw salaris",
  Salary_Name:"Salarisidentificatie",
  Enable_Salary:"Schakel Salaris in",
  Salary_Amount:"Salarisbedrag",
  New_Schedule:"Nieuw schema",
  Schedule_Title:"Schema titel",
  Add_Address:"Voeg adres toe",
  Repeat:"Herhalen",
  Design:"Ontwerp",
  Edit_Design:"Ontwerp bewerken",
  Edit_this_Design:"Bewerk dit ontwerp",
  Repeat_Payment:"Herhaalde betaling",
  Enable_Schedule:"Schema inschakelen",
  Never:"Nooit",
  Select_Schedule:"Selecteer schema's",
  No_Schedules:"Geen schema's",
  No_Schedules_Word:"Maak de eerste <a routerLink='/folder/Schedule/New'>Schedule</a>.",
  New_Administrator:"Nieuwe beheerder",
  Username:"Gebruikersnaam",
  First_Name:"Voornaam",
  Last_Name:"Achternaam",
  Add_an_Address:"Voeg een adres toe",
  Payment_Limit:"Per-Betalingslimiet",
  Total_Limit:"Totale limiet",
  Select_Accounts:"Selecteer rekeningen",
  No_Administrators:"Geen beheerders",
  No_Administrators_Word:"Maak de eerste <a routerLink='/folder/Administrator/New'>Admin Account</a>.",
  New_Administrators_Word:"Voeg de eerste <a routerLink='/folder/Administrator/New'>Administrator</a> toe",
  Cloud:"Wolk",
  Backup:"Back-up",
  Enable_iCloud:"Schakel iCloud in",
  Enable_Google_Drive:"Google Drive inschakelen",
  Enable_Microsoft_OneDrive:"Schakel Microsoft OneDrive in",
  Automatically_Backup:"Automatisch een back-up maken",
  FTP_Settings:"FTP-instellingen",
  URL_File_Prompt:"Geef de locatie op voor een .xls / .xlsx / .json-bestand om te importeren:",
  URL_SQL_Prompt:"Geef de locatie op van het te importeren SQLite-bestand:",
  FTP_Backup:"FTP-back-up",
  FTP_Import:"FTP-import",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Gastheer",
  Port:"Haven",
  Path:"Pad",
  Data_Path:"Gegevenspad",
  Enable_FTP_Account:"Enable FTP Account",
  HostnameIP:"Hostnaam",
  Password:"Wachtwoord",
  Connection_Port:"Verbindingspoort",
  Enable_MySQL_Database:"Schakel MySQL-database in",
  Log:"Logboek",
  Reset:"Resetten",
  Erase:"Wissen",
  Export:"Exporteren",
  Database:"Database",
  Upload_CSV:"CSV-bestand uploaden",
  Download_CSV:"CSV downloaden",
  SQL_Database:"SQL-URL",
  MySQL_Database:"MijnSQL",
  MySQL_Backup:"MySQL-back-up",
  Internal_Notes:"Interne opmerkingen",
  Root_Path:"Wortelpad",
  Select_Backup:"Selecteer back-up",
  Add_New_Backup:"Voeg een nieuwe back-up toe",
  First_Backup:"Stel de eerste back-up in...",
  Backups:"Back-ups",
  Add_Backup:"Back-up toevoegen",
  No_Backups:"Er zijn geen back-ups te vinden.",
  Select_Backup_Type:"Selecteer het type back-up dat u wilt instellen...",
  Backup_Type:"Back-uptype",
  FTP_Drive:"FTP Drive",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Drijfveer",
  Microsoft_OneDrive:"Drijfveer",
  Import_Fields:"Import Velden",
  Import_Fields_Word:"Gebruik deze sectie om <a routerLink='/folder/Holiday/New'>Import</a> data te selecteren.",
  Upload:"Uploaden",
  Download:"Downloaden",
  Download_JSON:"Downloaden als JSON-gegevens",
  Download_SQL:"Download als SQL-bestand",
  New_Bank:"Nieuwe bank",
  New_Account:"Nieuw account",
  New_Bank_Account:"Nieuwe bankrekening",
  Upload_Image:"Afbeelding uploaden",
  Bank_Name:"Banknaam",
  Bank_Address:"Bank adres",
  Branch_Address:"vestigingsadres",
  Address_on_Cheque:"Adres",
  Cheque_Numbers:"Controleer nummers",
  Cheque_Details:"Controleer details",
  Bank_Logo:"Banklogo",
  Cheque_ID:"Controleer identiteitsbewijs",
  Starting_Cheque_ID:"Start ID controleren",
  Transit_Number:"Vervoers nummer",
  Institution_Number:"Instellingsnummer",
  Designation_Number:"Aanduiding Nummer",
  Account_Number:"Rekeningnummer",
  Limits:"Grenzen",
  Default_Limits:"Standaardlimieten",
  Over_Limit:"Over de grens",
  Under_Limit:"Onder de limiet",
  Payroll_Limit:"Loonlimiet",
  Enable_Bank_Account:"Bankrekening inschakelen",
  Select_Account:"Selecteer account",
  No_Bank_Account:"Geen bankrekening",
  No_Bank_Account_Word:"Voeg de eerste <a routerLink='/folder/Accounts/New'>Bankrekening</a> toe.",
  Bank_Accounts:"Bankrekeningen",
  No_Accounts:"Geen rekeningen",
  No_Accounts_Note:"Voeg de eerste <a routerLink='/folder/Accounts/New'>Bankrekening</a> toe.",
  Cheque_Designer:"Controleer Ontwerper",
  Cheque_Design:"Controleer ontwerp",
  Select_Design:"Selecteer een ontwerp",
  Cheque_Designs:"Controleer ontwerpen",
  No_Cheque_Designs:"Geen cheque-ontwerpen",
  No_Cheque_Designs_Word:"Maak uw eigen <a routerLink='/folder/Settings/Cheque/Design/New'>Check Design</a>.",
  Set_Default:"Stel in als standaard",
  Default:"Standaard",
  Remove:"Verwijderen",
  Folder:"Map",
  Edit:"Bewerk",
  LoadingDots:"Bezig met laden...",
  Add_a_New_File:"Voeg een <a href='#' (klik)='add()'>Nieuw bestand</a> toe aan",
  this_folder:"deze map",
  FTP_Backup_Settings:"FTP-back-upinstellingen",
  Secure_File_Transfer:"Beveiligde bestandsoverdracht",
  New_Holiday:"Nieuwe vakantie",
  Add_Holiday:"Voeg vakantie toe",
  Holiday_Name:"Vakantienaam",
  Additional_Pay:"Extra loon",
  Enable_Holiday:"Vakantie inschakelen",
  Select_Holidays:"Selecteer feestdagen",
  No_Holidays:"Geen feestdagen",
  No_Holidays_Word:"Voeg de eerste <a routerLink='/folder/Holiday/New'>Public Holiday</a> toe.",
  New_Import:"Nieuwe invoer",
  Import_Data:"Data importeren",
  Import_Data_Word:"Selecteer het type bestand of uploadmethode van uw keuze.<br /> U kunt de geïmporteerde velden in een bestand selecteren die overeenkomen met de parameter in de app na het uploaden van een ondersteund bestand.", 
  Import_File:"Importeer bestand",
  Link_To_File:"Link naar bestand",
  Select_File:"Selecteer bestand",
  New_Moderator:"Nieuwe moderator",
  Allow_Moderation:"Sta moderatie toe",
  Create_Paycheques:"Maak loonstrookjes",
  Edit_Paycheques_and_Data:"Bewerk loonstrookjes en gegevens",
  Destroy_Data_and_Paycheques:"Vernietig gegevens en loonstrookjes",
  Bonus_Percentage:"Percentage salaris",
  Fixed_Amount:"Vaste hoeveelheid",
  Select_Moderator:"Selecteer moderator",
  No_Moderators:"Geen moderatoren",
  Moderators:"Moderatoren",
  Moderator_Account:"Maak het eerste <a routerLink='/folder/Administrator/New'>Moderator-account</a>.",
  No_Moderators_Word:"Voeg de eerste <a routerLink='/folder/Administrator/New'>Moderator</a> toe.",
  Defaults:"Standaardinstellingen",
  Provide_Us_Info:"Geef ons informatie",
  Setup_Your_Printer:"Stel uw printer in",
  Your_Company:"About Your Company",
  Company_Name:"Bedrijfsnaam",
  Currency:"Munteenheid",
  Default_Currency:"Standaardvaluta",
  Base_Monthly_Income:"Maandinkomen",
  Protection:"Bescherming",
  App_Protection:"App-beveiliging",
  PIN_Code_Protection:"PIN-codebeveiliging",
  App_Protection_Word:"Enable beveiligingsmethoden die helpen beschermen uw account.",
  PIN_Code:"Pincode",
  Change_PIN:"Verander pincode",
  New_Password:"Nieuw paswoord",
  Geofence_Protection:"Geo-Fence Bescherming",
  Geofence_Area:"Set Area",
  Distance:"Afstand",
  Setup_Now:"Nu instellen",
  Mile:"Mijl",
  Km:"km",
  m:"m",
  Facial_Recognition:"Gezichtsherkenning",
  Face:"Gezicht",
  Setup:"Opstelling",
  Label:"Label",
  Password_Protection:"Wachtwoord beveiliging",
  Modify_Password:"Wachtwoord wijzigen",
  New_Tax_Entry:"Nieuwe belastingaangifte",
  New_Tax:"Nieuwe belasting",
  Tax_Label:"Belastinglabel",
  Perpetually_Enabled:"Perpetually ingeschakeld",
  Select_Taxes:"Selecteer belastingen",
  Tax_Deductions:"Belastingaftrek",
  No_Tax_Deductions:"Geen belastingaftrek",
  No_Tax_Deductions_Word:"Voeg de eerste <a routerLink='/folder/Tax/New'>Tax</a> aftrek toe.",
  New_Timer:"Nieuwe timer",
  Start:"Begin",
  Stop:"Stoppen",
  Start_Timer:"Starttimer",
  Stop_Timer:"Stoptimer",
  Timer_Active:"Timer actief",
  Timer:"Timer:",
  Timer_Running:"Timer: (loopt)",
  Save_Timer:"Timer opslaan",
  New_Timesheet:"Nieuwe urenstaat",
  Select_Timesheets:"Selecteer Urenstaten",
  Work_Notes:"Werknotities",
  Entry_Title:"Invoertitel",
  No_Timesheets:"Geen urenstaten",
  No_Timesheets_Word:"Voeg de eerste <a routerLink='/folder/Timesheet/New'>Timesheet</a> toe.",
  Timesheet_Submitted:"Timesheet verzonden",
  Timesheet_Congrats:"Gefeliciteerd! Uw urenstaat is succesvol ingediend. Bedankt!",
  Timesheet_Copy:"Om een ​​kopie van uw documenten te ontvangen, verzoeken wij u ons uw e-mailadres en/of mobiele telefoonnummer te verstrekken.",
  Submit:"Indienen",
  Allow_Notifications:"Sta meldingen toe",
  Untitled_Entry:"Nieuwe invoer",
  Untitled_Bank:"Naamloze Bank",
  Timesheet_Entry:"Tijdregistratie",
  Work_Description:"Werkomschrijving",
  Enable_Timesheet:"Tijdregistratie inschakelen",
  Submit_Timesheet:"Verzend Urenstaat",
  Vacation:"Vakantie",
  New_Vacation:"Nieuwe vakantie",
  Vacation_Name:"Vakantienaam",
  Paid_Vacation:"Betaalde vakantie",
  Vacation_Pay:"Vakantiegeld",
  Enable_Vacation:"Vakantie inschakelen",
  Select_Vacations:"Selecteer Vakanties",
  No_Vacations:"Geen vakanties",
  No_Vacations_Word:"Maak het eerste <a routerLink='/folder/Vacation/New'>Vakantie</a> item.",
  Payroll_Schedule:"Loonoverzicht",
  Next_Payroll:"Volgende Salaris:",
  Upcoming_Payroll:"Aanstaande Payroll",
  No_Upcoming_Payroll:"Geen aanstaande loonlijst",
  Address_Book:"Adresboek",
  Archived_Documents:"Gearchiveerde documenten",
  Dev_Mode:"Toepassing in ontwikkelingsmodus",
  Administrators:"Beheerders",
  Privacy:"Privacy",
  None:"Geen",
  Select_Signature:"Selecteer handtekening",
  No_Signatures:"Nee Signatures",
  No_Signatures_Word:"Voeg de eerste <a routerLink='/folder/Signature/New'>Signature</a> toe.",
  Repeat_Indefinitely:"Herhaal voor onbepaalde tijd",
  Sign:"Teken",
  Sign_Here:"Teken hier",
  Date_Signed:"Datum van ondertekening",
  Signature_Pad:"Handtekeningblok",
  Account_Holder:"Rekeninghouder",
  Account_Properties:"Accounteigenschappen",
  Name_On_Cheque:"Naam op cheque",
  Server_Hostname:"Serverhostnaam / IP",
  Printers:"Printers",
  No_Printers:"Geen printers",
  Printer_Exists:'Er bestaat al een printer met deze naam.',
  No_Printers_Word:"Voeg de allereerste <a routerLink='/folder/Printer/New'>Printer</a> toe.",
  No_Printer_Alert:"Er is geen printer gedefinieerd. Wilt u een printer instellen?",
  Add_Printer:"Printer toevoegen",
  New_Printer:"Nieuwe printer",
  Printer_Hostname:"Printerhostnaam / IP",
  Printer_Label:"Printerlabel",
  Printer_Protocol:"Printerprotocol",
  Protocol:"Protocol",
  Email_Print:"E-mail",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Stopcontact",
  Print_Job:"Afdrukopdracht",
  Printed:"Gedrukt",
  Not_Printed:"Niet bedrukt",
  Print_Jobs:"Afdruktaken",
  Print_Queue:"Afdrukwachtrij",
  No_Print_Jobs:"Geen afdruktaken",
  No_Prints:"Maak een nieuwe <a routerLink='/folder/Cheque/New'>Cheque</a> om af te drukken.",
  Prints:"Afdrukken",
  Find_Printer:"Printer zoeken",
  Find_AirPrint_Devices:"Zoek AirPrint-apparaten",
  Select_Printer:"Selecteer printer",
  System_UI:"Systeem gebruikersinterface",
  Printer:"Printer",
  Status:"Toestand",
  Preview:"Voorbeeld",
  Enable_Print_Job:"Schakel afdruktaak in",
  Queue_Weight:"Wachtrijgewicht",
  Unlimited:"Onbeperkt",
  Show_Advanced_Printer_Options:"Toon geavanceerde printeropties",
  Advanced:"Geavanceerd",
  Location:"Plaats",
  Note:"Opmerking",
  Queue_Name:"Wachtrijnaam",
  Pages_Printed_Limit:"Pagina's afgedrukt limiet",
  MultiPage_Idle_Time:"Meerdere pagina's wachttijd(en)",
  Page_Count_Limit:"Paginatellingslimiet",
  Page_Orientation:"Paginaoriëntatie",
  Portrait:"Portret",
  Landscape:"Landschap",
  Duplex:"Duplex",
  Double_Sided_Printing:"Tweezijdig",
  Duplex_Mode:"Duplexmodus",
  Duplex_Short:"Kort",
  Duplex_Long:"Lang",
  Duplex_None:"Geen",
  Color_And_Quality:"Kleur en kwaliteit",
  Monochrome:"monochroom",
  Photo_Quality_Prints:"Fotokwaliteit afdrukken",
  Printer_Email:"Printer e-mail",
  Automatically_Downsize:"Automatisch verkleinen",
  Paper:"Papier",
  Paper_Name:"Papiernaam",
  Paper_Width:"Papierbreedte",
  Paper_Height:"Papierhoogte",
  Paper_Autocut_Length:"Papier automatisch afsnijlengte",
  Margins:"Marges",
  Page_Margins:"Paginamarges",
  Page_Margin_Top:"Bovenste paginamarge",
  Page_Margin_Right:"Rechtse paginamarge",
  Page_Margin_Bottom:"Onderste paginamarge",
  Page_Margin_Left:"Linkerpaginamarge",
  Add_Employees:"Werknemers toevoegen",
  Header:"Koptekst",
  Print_A_Page_Header:"Print een paginakop",
  Header_Label:"Koptekstlabel",
  Header_Page_Index:"Koppagina-index",
  Header_Font_Name:"Koptekst lettertype",
  Select_Font:"Selecteer lettertype",
  Font_Selector:"Lettertypekiezer",
  Header_Font_Size:"Koptekst lettertype",
  Header_Bold:"Koptekst Vet",
  Header_Italic:"Koptekst cursief",
  Header_Alignment:"Koptekstuitlijning",
  Left:"Links",
  Center:"Centrum",
  Right:"Rechts",
  Justified:"Gerechtvaardigd",
  Header_Font_Color:"Koptekstkleur",
  Select_Color:"Selecteer kleur",
  Footer:"Voettekst",
  Print_A_Page_Footer:"Een paginavoettekst afdrukken",
  Footer_Label:"Voettekstlabel",
  Footer_Page_Index:"Footer Page Index",
  Footer_Font_Name:"Voettekst lettertype",
  Fonts:"Lettertypen",
  Done:"Gedaan",
  Select_Fonts:"Selecteer lettertypen",
  Footer_Font_Size:"Voettekstgrootte",
  Footer_Bold:"Voettekst vet",
  Footer_Italic:"Voettekst cursief",
  Footer_Alignment:"Voettekst uitlijnen",
  Footer_Font_Color:"Voettekstkleur",
  Page_Copies:"Pagina-kopieën",
  Enable_Printer:"Printer inschakelen",
  Remote_Logging:"Op afstand loggen",
  Log_Server:"Logserver",
  Encryption:"Encryptie",
  Random_Key:"Willekeurige sleutel",
  Encryption_Key:"Encryptiesleutel",
  Cheques_Webserver:"Aangepaste database",
  Learn_How:"Leren hoe",
  Signature:"Handtekening",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"View Signature",
  Printed_Signature:"Gedrukte handtekening",
  Digitally_Sign:"Digitaal ondertekenen",
  Digital_Signature:"Digitale handtekening",
  Add_Signature:"Handtekening toevoegen",
  Add_Your_Signature:"Voeg uw handtekening toe",
  Enable_Signature:"Handtekening inschakelen",
  Digitally_Signed:"Digitaal ondertekend",
  Insert_Error:"Kan cheque niet opslaan vanwege databaseproblemen.",
  Delete_Confirmation:"Weet u zeker dat u deze informatie wilt verwijderen?",
  Discard_Confirmation:"Weet u zeker dat u deze informatie wilt verwijderen?",
  Discard_Bank_Confirmation:"Weet u zeker dat u dit account wilt verwijderen?",
  Discard_Printer_Confirmation:"Weet u zeker dat u deze printer wilt weggooien?",
  Delete_Bonus_Confirmation:"Weet je zeker dat je deze bonus wilt verwijderen?",
  Delete_Invoice_Confirmation:"Weet u zeker dat u deze factuur wilt verwijderen?",
  Generated_Cheque:"Gegenereerde cheque",
  Generated_Invoice:"Gegenereerde factuur",
  Schedule_Start_Time:"Beginschema",
  Schedule_End_Time:"Einde planning",
  New_Call:"Nieuwe oproep",
  No_Contacts:"Geen contacten",
  No_Contacts_Word:"Beheerders, moderators, medewerkers en begunstigden verschijnen als contacten.",
  PDF_Subject:"financiën",
  PDF_Keywords:"loonlijst loonstrookje PDF cheque cheques",
  Printer_Setup:"Printer instellingen",
  Printer_Selection:"Printerselectie",
  New_Fax:"Nieuwe fax",
  New_Fax_Message:"Nieuw faxbericht",
  Fax_Machine:"Fax apparaat",
  Fax_Name:"Faxnaam",
  Fax_Email:"Fax e-mail",
  Fax_Number:"Faxnummer",
  Contents:"Inhoud",
  Fax_Body:"Paginalichaam",
  Header_Word:"Koptekst:",
  Header_Text:"Koptekst",
  Include_Header:"Inclusief koptekst",
  Include_Footer:"Voettekst opnemen",
  Footer_Word:"Voettekst:",
  Footer_Text:"Voettekst",
  Attach_a_Cheque:"Een cheque bijvoegen",
  Add_Deduction:"Voeg aftrek toe",
  Enable_Fax:"Fax verzenden",
  Select_Fax:"Selecteer fax",
  No_Faxes:"Geen faxen",
  Faxes:"Faxen",
  Save_and_Send:"Fax verzenden",
  Save_and_Pay:"Opslaan en betalen",
  WARNING:"WAARSCHUWING:",
  Remember:"Herinneren",
  Printing:"Afdrukken",
  Printing_Complete:"Afdrukken voltooid!\n\n",
  of:"van",
  There_Were:"Er waren ",
  Successful_Prints:" geslaagde prints en ",
  Unsuccessful_Prints:"mislukte afdrukken.",
  PrinterError:"Sorry er was een fout.",
  Printing_:"Afdrukken...",
  PrinterSuccess:"Document succesvol afgedrukt.",
  PrintersSuccess:"Documenten zijn met succes afgedrukt.",
  Print_Cheques:"Print cheques",
  New_Message:"Nieuw bericht",
  New_Messages:"Nieuwe berichten",
  Read_Message:"Lees bericht",
  Write_Message:"Schrijf een bericht",
  Send_Message:"Bericht versturen",
  Subject:"Onderwerp",
  Message:"Bericht",
  Writing:"Schrijven...",
  Send:"Versturen",
  Set_Date:"Datum instellen",
  Set_Time:"Tijd instellen",
  Recieve:"Ontvangen",
  Set_as_Default:"Stel in als standaard",
  Default_Account:"Standaardaccount",
  Default_Design:"Standaardontwerp",
  Multiple_Cheques:"Controles (Triple)",
  Account_Mode:"Accountmodus",
  Multiple_Cheques_Description:"Drie cheques per pagina.",
  Check_and_Table:"Controle & Tafel",
  Check_including_Table:"Check & boekhoudtabel.",
  Check_Mailer:"Controleer Mailer",
  Check_Mailer_Window:"Controleer met een adresvenster.",
  DocuGard_Table_Top:"DocuGard Check & Table (Top)",
  DocuGard_Table_Middle:"DocuGard Check & Table (Midden)",
  DocuGard_Table_Bottom:"DocuGard Check & Table (onder)",
  DocuGard_Mailer_Top:"DocuGard Cheque Mailer (Boven)",
  DocuGard_Mailer_Middle:"DocuGard Cheque Mailer (Midden)",
  DocuGard_Mailer_Bottom:"DocuGard Cheque Mailer (onder)",
  DocuGard_Three_Cheques:"DocuGard-cheques (tripple)",
  DocuGard_Cheque_Top:"DocuGard-controle (boven)",
  DocuGard_Cheque_Middle:"DocuGard Check (Midden)",
  DocuGard_Cheque_Bottom:"DocuGard-controle (onder)",
  DocuGard_Three_Cheques_Window:"Drie cheques op één pagina.",
  DocuGard_Table_Top_Window:"Check & winsttabel.",
  DocuGard_Table_Middle_Window:"Check & winsttabel.",
  DocuGard_Table_Bottom_Window:"Check & winsttabel.",
  DocuGard_Mailer_Top_Window:"Controleer, tafel en adres.",
  DocuGard_Mailer_Middle_Window:"Controleer, tafel en adres.",
  DocuGard_Mailer_Bottom_Window:"Controleer, tafel en adres.",
  DocuGard_Cheque_Top_Window:"Controleer op veilig papier.",
  DocuGard_Cheque_Middle_Window:"Controleer op veilig papier.",
  DocuGard_Cheque_Bottom_Window:"Controleer op veilig papier.",
  Basic_Cheque:"Controleer (Boven)",
  Basic_Cheque_Print:"Print een enkele cheque uit.",
  Error_Setting_As_Paid:"Fout instellen als betaald",
  Add_Attachment:"Voeg bijlage toe",
  PrinterUnavailable:"Printer niet beschikbaar",
  CreditCardComponent:"Kaarten",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Nieuwe storting",
  Deposits:"Deposito's",
  No_Deposits:"Geen deposito's",
  Credit_Card_Deposit:"Kredietkaart",
  New_Credit_Card_Deposit_Message:"Creditcard storting",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin-storting",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Interac-overdracht",
  Payments_Limit:"Betalingslimiet",
  No_Payment_Limit:"Geen betalingslimiet",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal-storting",
  No_Deposits_Word:"Voeg het eerste bedrijf <a routerLink='/folder/Deposit/New'>Borg</a> toe.",
  No_Documents_Specified:"Er zijn geen documenten opgegeven om af te drukken",
  No_Printers_Added:"Er zijn geen printers gevonden. Ga naar Instellingen > Printers om er een toe te voegen.",
  DB_Erase_Prompt:"De hele database volledig wissen? WAARSCHUWING: u verliest alle opgeslagen informatie!",
  Console_Warning:"Plak geen tekst in deze console. U kunt uzelf en/of uw bedrijf ernstig in gevaar brengen.",
  No_Faxes_Word:"Maak de eerste <a routerLink='/folder/Fax/New'>Fax</a>.",
  Cheque_Delete_Confirmation:"Weet u zeker dat u deze cheque wilt verwijderen?",
  Design_Delete_Confirmation:"Weet je zeker dat je dit ontwerp wilt verwijderen?",
  Cheque_Pay_Confirmation:"Markeer deze cheque als betaald? Hij verschijnt NIET in de afdrukwachtrij.",
  Payment_Pay_Confirmation:"Deze betaling markeren als betaald? Het verschijnt NIET in de wachtrij voor cheques.",
  Delete_Deduction_Confirmation:"Weet u zeker dat u deze aftrek wilt verwijderen?",
  Delete_Job_Confirmation:"Weet u zeker dat u deze taak wilt verwijderen?",
  Delete_Timesheet_Confirmation:"Weet u zeker dat u deze urenstaat wilt verwijderen?",
  Delete_Schedule_Confirmation:"Weet u zeker dat u dit schema wilt verwijderen?",
  Delete_Setting_Confirmation:"Weet u zeker dat u deze instelling wilt resetten?",
  Delete_Fax_Confirmation:"Weet u zeker dat u deze fax wilt verwijderen?",
  Delete_File_Confirmation:"Weet u zeker dat u dit bestand wilt verwijderen?",
  Delete_Vacation_Confirmation:"Weet je zeker dat je deze vakantie wilt verwijderen?",
  Delete_Printer_Confirmation:"Weet u zeker dat u deze printer wilt verwijderen?",
  Remove_Design_Confirmation:"Weet je zeker dat je dit ontwerp wilt verwijderen?",
  Delete_Payroll_Confirmation:"Weet u zeker dat u deze loonlijst wilt verwijderen?",
  Send_Fax_Email_Confirmation:"Wilt u deze documenten faxen en e-mailen?",
  Send_Email_Confirmation:"Wilt u dit document e-mailen?",
  Send_Fax_Confirmation:"Wilt u dit document faxen?",
  Error_Generating_PDF:"Sorry. Er is een fout opgetreden bij het genereren van dit document.",
  PDF_Error_Saving_Image:"Sorry. Er is een fout opgetreden bij het opslaan van de PDF-afbeelding van dit document.",
  Test_Printer_Confirmation:"Wilt u een testpagina afdrukken op deze printer?",
  Save_Timesheet_Prompt:"Voeg een 'Titel' toe of druk op 'Start Timer' om op te slaan.",
  Remove_Geofence_Prompt:"Weet u zeker dat u de locatie van deze geo-fence wilt verwijderen?",
  Delete_Employee_Confirmation:"Weet je zeker dat je wilt verwijderen ",
  Fire_Employee_Confirmation:"Weet je zeker dat je wilt vuren"
}