export const Gl = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Copyright &copy; 2023",
  black:"Negro",
  green:"Verde",
  gold:"Ouro",
  blue:"Azul",
  brown:"Marrón",
  purple:"Roxo",
  pink:"Rosa",
  red:"Vermello",
  Swatches:"Mostras",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Hue",
  Saturation:"Saturación",
  Lightness:"Lixeireza",
  Upgrade_To_Pro:"Actualiza a Pro",
  AllFeaturesInclude:"Todas as características inclúen:",
  PrintUnlimitedCheques:"Imprimir cheques ilimitados",
  PremiumChequeDesigns:"Deseños Premium Check",
  ManageUnlimitedEmployees:"Xestionar empregados ilimitados",
  AddUnlimitedPayees:"Engade beneficiarios ilimitados",
  CreateUnlimitedPayrolls:"Crear nóminas ilimitadas",
  UnlimitedSchedulesandTimeSheets:"Horarios e horarios ilimitados",
  BulkPayPalPayouts:"Pagos de PayPal masivos",
  UnlimitedBankAccounts:"Contas bancarias ilimitadas",
  ManageMultipleCompanies:"Xestionar varias empresas",
  TrackInsurancePolicies:"Seguimento das pólizas de seguro",
  Bio_MetricProtection:"Protección Biométrica",
  Geo_FenceLock_OutProtection:"Protección de bloqueo de Geo-Fence",
  Multiple_Companies_Word:"A xestión de varias empresas non está dispoñible sen Cheques premium.",
  PayPal_Payouts_Word:"Os pagos de PayPal están desactivados sen cheques premium.",
  PINProtection:"Protección PIN",
  PasswordProtection:"Protección por contrasinal",
  May_Require_Approval:"Pode requirir aprobación.",
  Subscribe:"Subscríbete",
  Billed:"Facturado",
  Up:"Arriba",
  Down:"Abaixo",
  Positioning:"Posicionamento",
  Marker:"Marcador",
  Drag_Marker:"Arrastrar marcador",
  Tagline:"Imprimir cheques e tabular a nómina",
  Marker_Word:"Use os marcadores para dimensionar o elemento.",
  Pinch_Zoom:"Pinche o zoom",
  Pinch_Word:"Preme para ampliar o elemento.",
  Drag:"Arrastra",
  Drag_Word:"Use o dedo para arrastrar elementos.",
  subscription_alias_word:"Subscrición de renovación automática",
  premium_alias_word:"Paquete de actualización única",
  print_alias_word:"Imprimir cheques individuais",
  mode_alias_word:"Modo",
  Pro:"Pro",
  Pro_word:"Requírese a versión Pro.",
  Cant_Delete_Default_Company_Word:"Sentímolo, non podes eliminar a túa empresa predeterminada.",
  Reinsert_Default_Designs:"Volver inserir deseños predeterminados",
  Reinsert_Default_Designs_word:"Queres volver inserir os deseños predeterminados?",
  Subscription_Active_Disable_Word:"Esta subscrición está activa. Queres cancelar esta subscrición a Cheques?",
  Company_Logo:"Logotipo da empresa",
  ZERO_:"CERO",
  Disclaimer:"Exención de responsabilidade",
  Privacy_Policy:"Política de Privacidade",
  EULA:"Comproba o CLUF",
  Terms_Of_Service:"Condicións de servicio",
  Terms_Of_Use:"Condicións de uso",
  Refunds:"Política de reembolso",
  Single_Print:"1 Comproba",
  Two_Prints:"2 Comprobacións",
  Five_Prints:"5 Comprobacións",
  Ten_Prints:"10 Cheques",
  Fifteen_Prints:"15 Cheques",
  Twenty_Prints:"20 Cheques",
  Thirty_Prints:"30 Cheques",
  Image_Added:"Imaxe engadida",
  Image_Preview:"Vista previa da imaxe",
  No_Image_Was_Selected:"Non se seleccionou ningunha imaxe.",
  Cheques_Unlimited:"Cheques ilimitados",
  _Subscription:"Subscrición",
  Subscription:"Cheques - 1 mes",
  Two_Month_Subscription:"Cheques - 2 meses",
  Three_Month_Subscription:"Cheques - 3 meses",
  Six_Month_Subscription:"Cheques - 6 meses",
  Twelve_Month_Subscription:"Cheques - 12 meses",
  Cheques_Are_Available:"Os cheques están dispoñibles para imprimir.",
  Upgrade_Required_Two:"Seleccione un paquete e toque dúas veces o botón de prezo para comezar a súa compra. Imprime cheques a toda cor de aspecto profesional en segundos.",
  Month:"Mes",
  Due:"Debido:",
  Expires:"Caduca:",
  Subscription_Active:"Subscrición activa",
  Subscription_Inactive:"Subscrición inactiva",
  Purchase_Additional_Cheques:"Queres mercar cheques adicionais?",
  Printable_Cheque:"Cheque imprimible",
  Printable_Cheques:"Cheques imprimibles",
  Printable_Cheque_Word:"o cheque está dispoñible na túa conta.",
  Printable_Cheques_Word:"os cheques están dispoñibles na súa conta.",
  Max_Amount_Message:"O importe que especificaches alcanzou o importe máximo establecido para este sistema:",
  Terms_Required_Word:"Debes aceptar este acordo antes de continuar usando os cheques.",
  Subscriptions:"Subscricións",
  Product_Upgrades:"Actualizacións",
  Mailed_Out_Cheques:"Cheques enviados por correo",
  Enter_A_Company_Name:"Introduza un nome de empresa.",
  Single_Cheques:"Cheques únicos",
  Cheque_Golden:"Cheque de ouro",
  Cheque_Golden_Window:"Deseño de cheques dourados.",
  Cheque_Green:"Cheque verde",
  Cheque_Green_Window:"Deseño de cheques verdes.",
  Cheque_Red:"Cheque vermello",
  Cheque_Red_Window:"Deseño de verificación vermella.",
  Cheque_Yellow:"Cheque amarelo",
  Cheque_Yellow_Window:"Deseño de cheques amarelos.",
  Cheque_Statue_of_Liberty:"Estatua da Liberdade",
  Cheque_Statue_of_Liberty_Window:"Deseño de cheques da Estatua da Liberdade.",
  Cheque_Green_Wave:"Onda Verde",
  Cheque_Green_Wave_Window:"Deseño de cheques verdes.",
  Cheque_Golden_Weave:"Tecido Dourado",
  Cheque_Golden_Weave_Window:"Elegante deseño dourado.",
  Cheque_Green_Sun:"Sol verde",
  Cheque_Green_Sun_Window:"Deseño de cheques profundo e calmante.",
  Cheque_Blue_Checkers:"Damas azuis",
  Cheque_Blue_Checkers_Window:"Deseño de verificación azul.",
  Cashiers_Check:"Cheque de caixa",
  Cashiers_Check_Window:"Modelo de estilo de cheque de caixa.",
  Cheque_Aqua_Checkers:"Damas Aqua",
  Cheque_Aqua_Checkers_Window:"Deseño Aqua Check.",
  Cheque_Golden_Checkers:"Damas de Ouro",
  Cheque_Golden_Checkers_Window:"Deseño de cheques dourados.",
  Upgrade_Unavailable:"As actualizacións non están dispoñibles",
  Bank_Code_Protection:"Protección do número bancario",
  Bank_Code_Protection_Word:"Protexe os teus números bancarios para que non se utilicen nesta aplicación que se executa noutro dispositivo ou para outro usuario. Esta acción é IRREVERSIBLE. Queres continuar?",
  Bank_Code_Protection_Blocked_Word:"Os números bancarios que estás a usar están reservados para outro usuario ou dispositivo.",
  Bank_Code_Protection_Error_Word:"Produciuse un erro na verificación do número. Conéctate a Internet e tenta engadir de novo esta conta bancaria.",
  Bank_Code_Protection_Set_Error_Word:"A protección do número bancario require que esteas conectado a Internet. Conéctate e téntao de novo.",
  Upgrade_Unavailable_Word:"Sentímolo, estamos tendo problemas para verificarte. As subscricións e as actualizacións de Cheques non están dispoñibles para a compra na túa zona.",
  PayPal_Payment_Preview:"Vista previa do pago de PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Seleccione PayPal",
  PayPal_Applications:"Aplicacións PayPal",
  Purchase_With_Apple_Pay:"Compra con Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Empresas",
  Insurance:"Seguros",
  New_PayPal:"Novo PayPal",
  Pay_By:"Pagar por",
  Insure:"Asegurar",
  Miles:"Millas",
  Payment_Method:"Método de pago",
  Select_Policies:"Seleccione Políticas",
  Policies:"Políticas",
  Policy:"Política",
  No_PayPal_Account:"Non hai conta de PayPal",
  No_Policies:"Sen pólizas de seguro",
  New_Policy:"Nova Política",
  PayPal_Payment:"Pago PayPal",
  PayPal_Payments:"Pagos PayPal",
  No_Payment_Selected:"Non se seleccionou ningún pago",
  Sending_Payment_Word:"Agarde... Este pago está sendo enviado.",
  Sending_Payments_Word:"Agarde... Os pagos envíanse.",
  No_Payment_Selected_PayPal:"Non seleccionou ningún <a routerLink='/folder/Payments'>pago de PayPal</a> para enviar.",
  Payment_Sent:"Pago enviado",
  PayPal_Payment_Sent:"Este pago foi enviado con PayPal.",
  Copay:"Copago",
  Dead:"Morto",
  Alive:"Vivo",
  Not_Dead:"Non morto",
  Unclaimed:"Non reclamado",
  Attempted:"Intento",
  Deceased:"Falecido",
  Claimed:"Reclamado",
  Unpaid:"Sen pago",
  Sending_Payment:"Enviando Pago",
  Sending_Payments:"Envío de Pagos",
  Send_PayPal_Confirmation:"Queres enviar esta transacción con PayPal?",
  Send_PayPal_Confirmation_Word:"Preme o botón verde para enviar esta transacción.",
  Save_Payment_As_Unpaid:"Queres gardar este pago como non pago?",
  Payment_Pay_Confirmation_PayPal:"Queres gardar este pago como pago?",
  No_Policies_Word:"Engade agora a primeira <a routerLink='/folder/Postage/New'>Póliza de seguro</a>.",
  Timesheet_Multiple_Delete_Confirmation:"Estás seguro de que queres eliminar varias follas de horas?",
  Select_Multiple_Timesheets_Prompt:"Non se seleccionaron follas de horas. Selecciona polo menos unha folla de horas.",
  Select_Multiple_Policies_Prompt:"Non se seleccionaron políticas. Seleccione polo menos unha póliza de seguro.",
  Policies_Multiple_Delete_Confirmation:"Estás seguro de que queres eliminar varias políticas?",
  Company:"Compañía",
  Add_Company:"Engadir empresa",
  New_Company:"Engadir empresa",
  No_Companies:"Sen Compañías",
  Enable_Company:"Habilitar empresa",
  Select_Company:"Seleccione Empresa",
  The_Default_Company:"A etiqueta da empresa predeterminada.",
  Manage_Companies:"Xestionar Empresas",
  No_Companies_Word:"Os cheques usarán unha empresa predeterminada.<br />Engade a <a routerLink='/folder/Company/New'>primeira empresa</a>.",
  Default_Company:"Empresa predeterminada",
  Cheques_Unlimited_Word:"Cheques Unlimited permíteche imprimir tantos cheques como queiras.",
  Cheques_Subscription_Word:"Unha subscrición a Cheques permítelle imprimir tantos cheques como queira.",
  You_Own_This_Product:"Vostede é o propietario deste produto.",
  Your_Subscription_is_Active:"A túa subscrición está activa.",
  Active_Products:"Produtos activados",
  Purchase_Confirmation:"Compra",
  Purchase_Cheques:"Cheques de compra",
  Restore_Purchase:"Restaurar compras",
  Erase_Purchase:"Borrar compras",
  Successfully_Purchased:"Comprado con éxito",
  Enter_Your_Licence_Key:"Introduza a súa clave de licenza nesta páxina para activar este produto.",
  Licence_Key:"Clave de licenza",
  Enter_Licence_Key:"Introduza a clave de licenza",
  Purchased:"Comprado",
  Enable_Feature:"Activar función",
  Cancel_Subscription:"Cancelar a subscrición",
  Subscription_Removed:"Quitouse a subscrición",
  Select_Active_Subscription:"Seleccione unha subscrición activa para modificala.",
  Remove_Subscription_Word:"Estás seguro de que queres cancelar esta subscrición?",
  Delete_Company_Confirmation:"Estás seguro de que queres eliminar toda esta empresa? AVISO: perderá toda a información almacenada!",
  Delete_Default_Company_Word:"Non pode eliminar a empresa predeterminada. Quere restablecer a aplicación e restaurala ao estado predeterminado? AVISO: perderá toda a información almacenada!",
  Console_Warning_2:"Non manexas ningunha moeda usando esta aplicación que non sexa a túa actualmente.",
  Terms_and_Conditions:"Termos e condicións",
  and_the:"e o",
  for:"para",
  Please_Read_Word:"Por favor, le e acepta o",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Non se atoparon algúns tipos de conversión de moeda. Conéctate a internet.",
  Free:"Libre",
  DB_Erase_Prompt_2:"Queres borrar por completo toda a base de datos do programador? AVISO: perderá toda a información de COMPRA e SUBSCRICIÓN!",
  Successfully_Imported:"Importouse correctamente",
  Select_Postage:"Seleccione Franqueo",
  No_Postage:"Sen selos de correos",
  No_Paid_Postage_Word:"Engade o primeiro selo <a routerLink='/folder/Postage/New'>Correos de pago</a>.",
  Trial_Complete:'Proba completada',
  Please_Upgrade:'Actualiza Cheques para continuar imprimindo.',
  Aa:"Aa",
  Color:"Cor",
  Font:"Fonte",
  Guide:"Guía",
  Guides:"Guías",
  Image:"Imaxe",
  Zoom:"Zoom",
  Logo:"Logotipo",
  Bank:"Banco",
  MICR:"MICR",
  Total:"Total",
  Cancel:"Cancelar",
  Confirm:"Confirmar",
  Method:"Método",
  Biometric_Security:"Seguridade biométrica",
  Please_Login_To_Continue:"Inicia sesión para continuar.",
  Complete:"Completa",
  Sign_Up:"Rexistrarse",
  Error:"Erro",
  Biometrics:"Biometría",
  Percent:"Por cento",
  Zero_Percent:"0 %",
  Top_Margin:"Marxe superior",
  Bottom_Margin:"Marxe inferior",
  Left_Margin:"Marxe Esquerda",
  Right_Margin:"Marxe dereita",
  MICR_Margin:"Marxe MICR",
  Table_Margin:"Marxe da táboa",
  Address_Margin:"Marxe do enderezo",
  Percentage_:"Porcentaxe",
  Vacation_Title:"Título de vacacións",
  Use_PIN:"Usa o PIN",
  Loading__:"Cargando...",
  Design_Title:"Título do deseño",
  Authorize:"Autorizar",
  Key:"Chave",
  Public_Key:"Chave pública",
  Private_Key:"Chave privada",
  Authenticate:"Autenticarse",
  Last_Payroll:"Última nómina",
  Last_Calculation:"Último cálculo",
  Authorized:"Autorizado",
  Geo_Authorized:"Xeolocalización: Autorizado",
  Not_Authorized:"Non autorizado",
  Authorization_Complete:"Autorización completa",
  Geolocation_Authorization:"Autorización de xeolocalización",
  Out_of_Bounds:"Fóra dos límites",
  Cant_Delete_Default_Design:"Non se pode eliminar un deseño predeterminado.",
  Unauthorized_Out_of_Bounds:"Non autorizado: fóra de límites",
  Biometric_Authorization:"Autorización biométrica",
  Locating_Please_Wait:"Localizando, agarde...",
  Test_Biometrics:"Proba de biometría",
  Cheque_Margins:"Comprobar Marxes",
  Percentage_Full_Error:"O campo de porcentaxe non se pode establecer máis do 100 % por cento.",
  No_Payroll_Text:"Engade un <a routerLink='/folder/Employee/New'>Empleado</a> ou <a routerLink='/folder/Payee/New'>Pagado</a> e un <a routerLink='/folder/Schedule/New'>Horario</a>.",
  Design_Saved:"Deseño gardado",
  Default_Design_Selected:"Deseño predeterminado seleccionado",
  Partial_Import:"Importación parcial",
  Partial_Export:"Exportación parcial",
  Entire_Import:"Importación enteira",
  Entire_Export:"Exportación completa",
  Existing_Password:"Introduce o teu contrasinal existente:",
  Existing_PIN:"Introduce o teu código PIN existente:",
  Pin_Change_Header:"Confirmación do PIN",
  Pin_Change_SubHeader:"Introduce o teu número PIN antigo para confirmar o cambio.",
  Pass_Change_Header:"Confirmación de contrasinal",
  Pass_Change_SubHeader:"Introduce o teu contrasinal antigo para confirmar o cambio.",
  PIN_Enter_Message:"Introduce o teu PIN para confirmar.",
  Password_Enter_Message:"Introduce o teu contrasinal para confirmar.",
  Pin_Updated_Success:"PIN actualizado correctamente!",
  Pin_Updated_Fail:"Non se puido actualizar o PIN.",
  Pass_Updated_Success:"O contrasinal actualizouse correctamente.",
  Pass_Updated_Fail:"Non se puido actualizar o contrasinal.",
  Preview_Payment:"Vista previa do pago",
  Preview_Payroll:"Vista previa da nómina",
  No_Payments_Created:"Non se atopou ningún pago que fose creado.",
  Payment_Preview:"Vista previa do pago",
  Enable_Payee:"Activar o beneficiario",
  Rendered:"Renderizado",
  No_Email:"Sen correo electrónico",
  Setup_Cheques:"Verificacións de configuración",
  name:"Nome",
  address:"Enderezo",
  address_2:"Enderezo Liña 2",
  city:"Cidade",
  province:"Provincia",
  postal_code:"Código postal",
  country:"País",
  username:"Nome de usuario",
  full_name:"Nome completo",
  birthday:"Aniversario",
  email:"Correo electrónico",
  phone:"Teléfono",
  employees:"Empregados",
  addresses:"Enderezos",
  payment_amount_limit:"Límite de cantidade de pago",
  total_limit:"Límite total",
  payees:"Beneficiarios",
  description:"Descrición",
  address_line_one:"Liña de enderezo un",
  address_line_two:"Liña de enderezo dúas",
  image:"Imaxe",
  account_holder:"Titular da conta",
  cheque_starting_id:"Comprobe o ID de inicio",
  transit_number:"Número de tránsito",
  institution_number:"Número de institución",
  designation_number:"Número de designación",
  account_number:"Número de conta",
  currency:"Moeda",
  signature:"Sinatura",
  payment_payroll_limit:"Límite de pago",
  total_limi:"Límite total",
  date:"Data",
  printed_memo:"Nota impresa",
  banks:"Bancos",
  signature_image:"Imaxe da sinatura",
  address_name:"Nome do enderezo",
  notes:"Notas",
  design:"Deseño",
  title:"Título",
  frequency:"Frecuencia",
  fax:"Fax",
  salaries:"Salarios",
  salary_ids:"ID de salario",
  start_time:"Hora de inicio",
  end_time:"Tempo de fin",
  paid:"Pagado",
  overtime_percentage:"Porcentaxe de pago",
  overtime_amount:"Importe fixo pagado",
  first_name:"Nome",
  last_name:"Apelido",
  moderation:"Moderación",
  create:"Crear",
  edit:"Editar",
  destroy:"Destruír",
  day_start_time:"Día_hora_inicio",
  day_end_time:"Hora_final_día",
  fullname:"Nome",
  time:"Tempo",
  auto_send:"Enviar automaticamente",
  time_method:"Método do tempo",
  schedules:"Horarios",
  indefinite_payroll_enabled:"Activar indefinidamente",
  amount:"Cantidade",
  repeat:"Repetir",
  always_enabled:"Sempre activado",
  start_date:"Data de inicio",
  end_date:"Data de finalización",
  Cheque_Total:"Comprobe Total",
  Total_Amount:"Cantidade total:",
  Amounts:"Cantidades:",
  Images:"Imaxes",
  Files:"Arquivos",
  Previewing:"Vista previa:",
  Insert:"Inserir",
  Preview_Import:"Vista previa da importación",
  Entry:"Entrada",
  Entries:"Entradas",
  No_Entries:"Sen entradas",
  Import_Type:"Tipo de importación",
  Select_Details:"Seleccione Detalles",
  Select_Payee:"Seleccione Beneficiario",
  Enable_Holidays:"Activa os festivos",
  Disable_Holidays:"Desactivar vacacións",
  Wire_Transfer:"Transferencia bancaria",
  New_Export:"Nova exportación",
  Export_Data:"Exportar datos",
  Export_Data_Word:"Seleccione o tipo de ficheiro para exportar e descargar.",
  Export_File:"Exportar ficheiro",
  Mode:"Modo",
  Times:"Veces",
  Widgets:"Widgets",
  Slider:"Control deslizante",
  Set_Details:"Definir detalles",
  Select_Type:"Seleccione Tipo",
  Display_Slider:"Mostrar o control deslizante",
  Dashboard_Slider:"Control deslizante do panel",
  Dashboard_Mode:"Modo de panel",
  Show_Intro:"Mostrar a introdución",
  Show_Payrolls:"Mostrar nóminas",
  Show_Holidays:"Mostrar festivos",
  Show_Invoices:"Mostrar facturas",
  Show_Cheques:"Mostrar cheques",
  Enabled_Widgets:"Widgets activados",
  Disabled_Widgets:"Widgets desactivados",
  Colors:"Cores",
  Barcodes:"Códigos de barras",
  View_Timers:"Ver temporizadores",
  Gradients:"Degradados",
  No_Info:"Sen información",
  Disable:"Desactivar",
  Show_Layer:"Mostrar capas",
  Hide_Layer:"Ocultar capas",
  Text_Layer:"Capas de texto",
  Secondly:"En segundo lugar",
  Minutely:"Minuto",
  nine_am:"9:00 AM",
  five_pm:"5:00 PM",
  Enable_Address:"Activar o enderezo",
  Invalid_File_Type:"Seleccionouse un tipo de ficheiro non válido. Tipo de ficheiro admitido:",
  Error_Updating_Entry:"Produciuse un erro ao actualizar esta entrada.",
  Schedule_Timing_Alert:"Erro: a hora de inicio da programación establécese nun valor posterior á hora de finalización.",
  Select_Multiple_Payments_Prompt:"Non se seleccionaron pagos. Selecciona polo menos un pago.",
  Select_Multiple_Cheques_Prompt:"Non se seleccionaron verificacións. Seleccione polo menos unha verificación.",
  Select_Multiple_Items_Prompt:"Non se seleccionaron elementos. Selecciona polo menos un elemento.",
  Paymemt_Multiple_Delete_Confirmation:"Estás seguro de que queres eliminar varios pagos?",
  Cheque_Multiple_Delete_Confirmation:"Estás seguro de que queres eliminar varias comprobacións?",
  Payee_Multiple_Delete_Confirmation:"Estás seguro de que queres eliminar varios beneficiarios?",
  Employee_Multiple_Delete_Confirmation:"Estás seguro de que queres eliminar varios empregados?",
  MICR_Warning:"Nota: Algunhas impresoras e dispositivos poden non mostrar correctamente o tipo de letra MICR.",
  Automatically_Send:"Enviar automaticamente",
  Type:"Tipo",
  Payment_Type:"Tipo de pago",
  Auto_Send:"Envío automático",
  Automatically_Process:"Procesar automaticamente",
  Auto_Process:"Proceso automático",
  Image_Based:"Baseado en imaxes",
  Font_Based:"Baseado en fontes",
  Rerender:"Volver a renderizar",
  Rendering:"Renderizado",
  Render:"Arquivos",
  Top:"Arriba",
  Middle:"Medio",
  Bottom:"Abaixo",
  Top_Left:"Arriba Esquerda",
  Top_Center:"Centro superior",
  Top_Right:"Arriba á dereita",
  Middle_Left:"Medio Esquerda",
  Middle_Center:"Centro medio",
  Middle_Right:"Medio Dereita",
  Bottom_Left:"Abaixo Esquerda",
  Bottom_Center:"Centro inferior",
  Bottom_Right:"Abaixo á dereita",
  Numbers:"Números",
  Verified:"Verificado",
  Paper_Size:"Tamaño do papel",
  New_Device:"Novo dispositivo",
  Add_Device:"Engadir dispositivo",
  Remove_Device:"Eliminar o dispositivo",
  Delete_Device:"Eliminar dispositivo",
  Block_Device:"Bloquear o dispositivo",
  Block:"Bloquear",
  Unblock:"Desbloquear",
  Table:"Táboa",
  Scan_Login_Code:"Escanear o código de inicio de sesión",
  Login_Code:"Código de inicio de sesión",
  Scan_Code:"Código de escaneo",
  Scan_QR_Code:"Escanear código QR",
  Select_All:"Seleccionar todo",
  Deselect_All:"Deseleccione Todo",
  Increase:"Aumentar",
  Decrease:"Diminuír",
  Bold:"Negriña",
  Text:"Texto",
  Style:"Estilo",
  Italic:"Cursiva",
  QR_Code:"Código QR",
  Barcode:"Código de barras",
  Browse_Images:"Explorar imaxes",
  Browse_Files:"Explorar ficheiros",
  Background_Image:"Imaxe de fondo",
  Logo_Image:"Imaxe do logotipo",
  Header_Image:"Imaxe de cabeceira",
  Bank_Image:"Imaxe do Banco",
  Cut_Lines:"Liñas de corte",
  Background:"Fondo",
  License:"Licenza",
  One_License:"1 Licenza:",
  Licensed:"Licenza para:",
  Not_Licensed:"Non licenciado",
  Enter_Serial:"Introduza Serial",
  Serial_Key:"Chave de serie",
  Serial:"Serial",
  Product_Key:"Clave do produto",
  Check_Product_Key:"Comproba a chave do produto",
  Add_Product_Key:"Engadir clave de produto",
  Verifying_Purchase:"Verificando a compra...",
  Print_Envelope:"Imprimir sobre",
  Envelope:"Sobre",
  Thank_You:"Grazas!",
  Scale:"Escala",
  Print_Scale:"Escala de impresión",
  Borders:"Fronteiras",
  VOID:"VALO",
  Void_Cheque:"Cheque nulo",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"PAGAR Á PEDIDO DE:",
  NO_DOLLARS:"SEN DÓLARES ",
  ONE_DOLLAR:"UN DÓLAR",
  DOLLARS:" DÓLARES",
  AND:" E ",
  CENTS:" CENTAMOS.",
  NO_:"NON ",
  ONE_:"UN ",
  AND_NO_:"E NON ",
  _AND_ONE_:"E UN ",
  DOLLARS_AND_ONE_CENT:" E UN CÉNIMO.",
  AND_NO_CENTS:" E CERO CÉNTIMOS.",
  CHEQUE_PRINT_DATE:"DATA:",
  CHEQUE_PRINT_MEMO:"MEMORIA:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Tarefas iniciais",
  Inital_Setup:"Configuración inicial",
  Welcome_To:"Benvido a ",
  Welcome:"Benvido",
  Swipe:"Pasar",
  Intro_Setup:"Configuración de introducción",
  Introduction:"Introdución",
  CHEQUE_PRINT_CREDIT:"Impulsado por cheques",
  Title_Intro_Word:"Benvido a Cheques",
  Title_Intro:"Introducción de cheques",
  Title_Setup:"Configuración de cheques",
  PayPal_Default_Email_Message:"Recibiches unha nova transferencia de PayPal. [Powered by Cheques]",
  Cheques_App_Export:"Exportado por cheques",
  Post_Intro_Thanks:"Grazas por escoller Cheques. O proceso de configuración xa está completo.",
  Post_Intro_Word:"Comece imprimindo o teu primeiro cheque, engade un pago futuro ou engade un empregado á nómina",
  Upgrade_Required:"Os cheques requiren que teñas unha versión máis premium do software para ocultar esta mensaxe e desbloquear funcións adicionais",
  Upgrade_Title:"Cheques",
  Mailout_Prompt:"Ademais, pode optar por que Cheques envíe os seus cheques de nómina por vostede.",
  Mailed_Cheque:"Cheque enviado por correo: ",
  Mailed_Cheque_Color:"Cheque enviado por correo (cor): ",
  Terms_Purchase:"Todas as compras electrónicas con cheques son totalmente reembolsables durante un máximo de 30 días desde a data de compra. Le e acepta os <a href='#'>Termos e condicións</a> antes de continuar.",
  Dashboard_Setup:"Configurar a impresora principal",
  Dashboard_Add:"Engadir a conta bancaria principal",
  Dashboard_Customize:"Seleccione un modelo de verificación",
  Dashboard_Job_Salary:"Crea o teu traballo e engade o teu salario",
  Dashboard_Employees:"Rastrexar empregados e empregados",
  Dashboard_Print:"Imprime e envía por correo os teus cheques",
  Dashboard_Payroll:"Automatiza a túa impresión de nóminas",
  Dashboard_PayPal:"Configurar nóminas/pagos de PayPal",
  Begin_Setup:"Comezar a configuración",
  Get_Started:"Comezar",
  Purchase:"Compra",
  Lockdown:"Confinamento",
  Unlock:"Desbloquear",
  Detailed:"Detallada",
  Log_In:"Acceder",
  Login:"Acceder",
  Launch:"Lanzamento",
  Register:"Rexistrarse",
  Finish:"Rematar",
  List:"Lista",
  Weekends:"Fins de semana",
  Weekly:"Semanalmente",
  PayPal_Default_Subject:"Pago novo",
  Payment_Message:"Mensaxe de pago",
  PayPal_Default_Payment_Note:"Grazas",
  Setup_Your_Cheqing_Account:"Conta de cheques",
  Add_Your_Primary_Cheqing_Account:"Engade a túa conta corrente principal.",
  Welcome_Word:"Simplificar e automatizar a xestión de nóminas e recursos humanos",
  Get_Started_Quickly:"Só responde a unhas preguntas sinxelas que nos axudarán a comezar...",
  Done_Intro_Word:"Configuración completada",
  PIN_Protected:"Contrasinal e PIN protexidos",
  Enter_New_PIN:"Introduza un novo código PIN:",
  Enter_PIN:"Introduza o código PIN:",
  Invalid_PIN:"Introduciuse un PIN non válido.",
  Account_Identifier:"Identificador da conta",
  New_Account_Identifier:"Identificador de nova conta",
  attempt:"intento",
  attempts:"intentos",
  remaining:"restante",
  Language:"Lingua",
  languages:"Idiomas",
  select_languages:"Seleccionar idioma",
  Deposit:"Depósito",
  Hire_One_Now:"Contrata un agora",
  App_Locked:"A aplicación está bloqueada.",
  Skip_:"Saltar",
  Skip_to_Dashboard:"Saltar ao panel de control",
  Dashboard:"Panel de control",
  Import:"Importar",
  Admin:"Administradores",
  New_Admin:"Novo administrador",
  Settings:"Configuración",
  Color_Picker:"Selector de cores",
  Font_Picker:"Selector de fontes",
  Logout:"Saír",
  Close:"Pechar",
  Close_menu:"Pechar",
  Excel:"Ficheiro Excel",
  Csv:"Ficheiro CSV",
  Sql:"Ficheiro SQL",
  Json:"Ficheiro JSON",
  Url:"Importar por URL",
  Back:"Atrás",
  Timers:"Temporizadores",
  Cheque:"Comprobar",
  Print:"Imprimir",
  Designs:"Deseños",
  Pause_Printing:"Pausa de impresión",
  Resume_Printing:"Continuar a impresión",
  Printing_Paused:"Impresión en pausa",
  PrintingUnavailable:"Sentímolo! A impresión non está dispoñible neste sistema.",
  Prints_Paused:"Impresións en pausa",
  Administration:"Administración",
  Loading:"Cargando",
  Unnamed:"Sen nome",
  error:"Sentímolo, esta comprobación non se puido abrir para visualizala.",
  No_Cheques_To_Print:"Non hai cheques para imprimir",
  No_Cheques_To_Print_Word:"Crear un <a routerLink='/folder/Cheque/New'>Novo cheque</a>.",
  New_Cheque:"Nova verificación",
  Start_One_Now:"Comezar un agora",
  Edit_Cheque:"Editar verificación",
  Select_Cheques:"Seleccionar cheques",
  Select_Employee:"Seleccionar empregado",
  Default_Printer:"Impresora predeterminada",
  Reassign:"Reasignar",
  Configure:"Configurar",
  Template:"Modelo",
  Designer:"Deseñador",
  Edit_Designs:"Editar deseños",
  New_Design:"Novo deseño",
  Next:"Seguinte",
  Save:"Gardar",
  Name:"Nome",
  Mail:"Correo",
  Amount:"Cantidade",
  Date:"Data",
  PayPal:"PayPal",
  Payouts:"Pagos",
  PayPal_Label:"Etiqueta de PayPal",
  Email_Username:"Correo electrónico / Nome de usuario",
  Client_ID:"ID de cliente",
  Sandbox_Email:"Correo electrónico Sandbox",
  PayPal_Email:"Correo electrónico de PayPal",
  PayPal_Username:"Nome de usuario API",
  PayPal_Payouts:"Pagos de PayPal",
  Select_PayPal_Key:"Seleccione a chave de PayPal",
  Secret:"Segredo",
  API_Secret:"Segredo da API",
  PayPal_API_Keys:"Chaves de PayPal",
  New_PayPal_Key:"Nova clave de PayPal",
  Email_Subject:"Asunto do correo electrónico",
  Email_Message:"Mensaxe de correo electrónico",
  Payout_Options:"Opcións de pago",
  Payment_Note:"Nota de pago",
  Enable_Employee:"Activar empregado",
  Enable_Production_Mode:"Activar o modo de produción",
  Sandbox_Username:"Nome de usuario de Sandbox",
  Sandbox_Signature:"Sinatura Sandbox",
  Sandbox_Password:"Contrasinal de Sandbox",
  Production_Username:"Nome de usuario de produción",
  Production_Signature:"Sinatura de produción",
  Production_Password:"Contrasinal de produción",
  Production_Email:"Correo electrónico de produción",
  API_Client_ID:"ID de cliente API",
  API_Signature:"Sinatura API",
  API_Password:"Contrasinal da API",
  API_Username:"Nome de usuario API",
  Secret_Key:"Chave secreta",
  Sandbox:"Sandbox",
  Production:"Produción",
  Sandbox_Keys:"Teclas de Sandbox",
  Production_Keys:"Chaves de produción",
  API_Title:"Título da API",
  Production_Mode:"Modo de produción",
  Account_Label:"Etiqueta da conta",
  No_PayPal_Setup:"Sen chave de PayPal",
  Enable_PayPal_Account:"Activar a conta de PayPal",
  No_PayPal_Word:"Engade a túa <a routerLink='/folder/Invoice/New'>Chave API de PayPal</a>.",
  Printed_Memo:"Memo impreso",
  Employee:"Empregado",
  View_Employee:"Ver empregado",
  Mailing_Address:"Enderezo de correo",
  Company_Address:"Enderezo da empresa",
  Select_Company_Address:"Seleccione o enderezo da empresa",
  Address:"Enderezo",
  Any_Day:"Calquera día",
  Address_Name:"Nome do enderezo",
  Unit:"Unidade",
  Account:"Conta",
  Bank_Account:"Conta bancaria",
  Account_Limits:"Activar límites de conta",
  Payroll:"Nómina",
  New_Payroll:"Nova nómina",
  No_Payroll:"Non hai nóminas próximas",
  Upcoming_Holiday:"Próximas vacacións:",
  Invoice_Due:"Factura vencida:",
  New_Invoice:"Nova factura",
  No_Invoices:"Sen facturas",
  No_Invoices_Word:"Cree a primeira <a routerLink='/folder/Invoice/New'>Factura</a>.",
  Cheque_Due:"Cheque vencido:",
  Payrolls:"Nóminas",
  Sandbox_Mode:"Modo Sandbox",
  Hire:"contratar",
  Pay:"Pagar",
  New:"Novo",
  Add:"Engadir",
  Make:"Facer",
  Time:"Tempo",
  Write:"Escribir",
  Holiday:"Vacacións",
  Holidays:"Vacacións",
  Next_Holiday:"Próximas vacacións:",
  All_Done:"Todo feito!",
  Employees:"Empleados",
  Payees:"Beneficiarios",
  Job:"Traballo",
  Jobs:"Traballos",
  Invoice:"Factura",
  Invoices:"Facturas",
  Vacations:"Vacacións",
  Cheques:"Cheques",
  Brand_Cheques:"Marca",
  Payment:"Pago",
  Enable_Payment:"Activar pago",
  Payments:"Pagos",
  Schedule:"Horario",
  Schedules:"Horarios",
  Timesheet:"Horario",
  Timesheets:"Follas de tempo",
  Salary:"Salario",
  New_Address:"Novo enderezo",
  Address_2:"Enderezo Liña 2)",
  _City:"Cidade",
  _State:"Estado/Prov",
  City:"Cidade/Municipio",
  State:"Provincia",
  Postal:"Código postal",
  ZIP:"Postal / ZIP",
  Country:"País",
  Addresses:"Enderezos",
  Required_Options:"Opcións requiridas",
  Optional_Options:"Opcións opcionais",
  Additional_Options:"Opcións adicionais",
  Required:"obrigatorio",
  Optional:"Opcional",
  Additional:"Adicional",
  No_Addresses:"Sen enderezos",
  New_Address_Word:"Engadir o primeiro <a routerLink='/folder/Address/New'>Enderezo</a>.",
  Select_Address:"Seleccionar enderezo",
  No_Address:"Sen enderezos",
  Print_Cheque:"Comprobación de impresión",
  Print_Cheque_Now:"Imprimir comproba agora",
  Description:"Descrición",
  Pay_To_The_Order_Of:"Pagar á orde de:",
  Select_Date_Range:"Seleccionar intervalo de datas",
  Select_Starting_Date:"Seleccionar data de inicio",
  Select_Ending_Date:"Seleccionar data de finalización",
  Select_Date:"Seleccionar data",
  Cheque_Date:"Comprobar data",
  Cheque_Memo:"Verificar a nota",
  Blank_Cheque:"Cheque en branco",
  Blank:"En branco",
  No_Cheques:"Sen cheques",
  No_Cheques_Word:"Imprime o teu primeiro <a routerLink='/folder/Cheque/New'>Check</a>.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"Ver imaxe",
  View:"Ver",
  Modify:"Modificar",
  Delete:"Eliminar",
  Cheque_Paid:"Pagado",
  New_Deduction:"Nova dedución",
  Title:"Título",
  Frequency:"Frecuencia",
  Hourly:"Por hora",
  Daily:"Diariamente",
  Weekdays:"Días laborables",
  BiWeekly:"2 semanas",
  TriWeekly:"3 semanas",
  Monthly:"Mensual",
  MiMonthly:"2 meses",
  Quarterly:"Trimestral",
  Yearly:"Anualmente",
  Every_Week:"Cada semana",
  Every_Payroll:"Cada nómina",
  Every_Month:"Todos os meses",
  Annually:"Anualmente",
  Always_Scheduled:"Sempre programado",
  Start_Date:"Data de inicio",
  End_Date:"Data de finalización",
  Start_Time:"Hora de inicio",
  End_Time:"Tempo de fin",
  Deduction_Label:"Etiqueta de dedución",
  Notes:"Notas",
  Options:"Opcións",
  Enable:"Activar",
  Select_Deductions:"Seleccionar Deducións",
  Deductions:"Deducións",
  No_Deductions:"Sen deducións",
  No_Deductions_Word:"Cree a súa primeira <a routerLink='/folder/Deduction/New'>Deduction</a>.",
  New_Employee:"Novo empregado",
  No_Title:"Sen título",
  _Name:"Nome",
  About_Yourself:"Sobre ti mesmo",
  Full_Name:"Nome completo",
  Birthday:"Cumpleaños",
  Email:"Correo electrónico",
  SMS:"SMS",
  Phone:"Teléfono",
  Test:"Proba",
  Call:"Chamar",
  Fax:"Fax",
  Printed_Note:"Nota impresa",
  Position:"Posición",
  Job_Position:"Posto de traballo",
  Select_a_Job:"Seleccione un traballo",
  Select_a_Salary:"Seleccione un salario",
  Add_a_Deduction:"Engadir unha dedución",
  Taxes:"Impostos",
  Add_Taxes:"Engadir impostos",
  Date_of_Birth:"Data de nacemento",
  Email_Address:"Enderezo electrónico",
  Phone_Number:"Número de teléfono",
  Phone_Call:"Chamada telefónica",
  Enable_on_Payroll:"Activar na nómina",
  Select_Employees:"Seleccionar empregados",
  No_Employees:"Sen empregados",
  No_Employees_Word:"Engade o teu primeiro <a routerLink='/folder/Employee/New'>Empleado</a> novo.",
  No_Name:"Sen nome",
  Unemployeed:"Desempregados",
  Employeed:"Empleado",
  Paid:"Pagado",
  Enabled:"Activado",
  Disabled:"Desactivado",
  Fire:"Lume",
  Not_Available:"Non dispoñible",
  Not_Available_Word:"Imprime a túa primeira <a routerLink='/folder/Invoice/New'>Factura</a> e recibe o pago.",
  Select_Invoices:"Seleccionar_Facturas",
  Print_Invoice_Word:"Imprime a túa primeira <a routerLink='/folder/Invoice/New'>Factura</a> e recibe o pago.",
  Invoice_Title:"Título da factura",
  Invoice_Date:"Data da factura",
  Due_Date:"Data de caducidade",
  New_Job:"Novo traballo",
  Details:"Detalles",
  Customize:"Personalizar",
  Customize_Dashboard:"Personalizar o panel",
  Components:"Compoñentes",
  No_Components:"Sen compoñentes",
  Main_Components:"Compoñentes principais",
  Smaller_Components:"Compoñentes máis pequenos",
  Error_Loading_Page:"Produciuse un erro ao cargar esta páxina.",
  Bank_Details:"Datos bancarios",
  About_Your_Job:"Sobre o teu traballo",
  Your_Schedule:"O teu horario",
  Job_Title:"Título do traballo",
  Job_Description:"Descripción do traballo",
  Job_Details:"Detalles do traballo",
  Enable_Job:"Activar traballo",
  Pay_Period:"Período de pago",
  Perpetually_Schedule:"Programar perpetuamente",
  Select_Jobs:"Seleccionar traballos",
  No_Jobs:"Sen emprego",
  Add_Jobs:"Engadir traballos",
  No_Jobs_Word:"Engade o primeiro <a routerLink='/folder/Job/New'>Job</a> á lista.",
  Count_Employees:"job.employee_count+' Empregados'",
  Zero_Employees:"0 empregados",
  New_Leave:"Nova baixa",
  Leave_Name:"Deixe o nome",
  Paid_Leave:"Permiso Pagado",
  Leave_Pay:"Deixa pagar",
  Indefinite_Leave:"Permiso indefinido",
  Indefinite_Payroll:"Nómina indefinida",
  Leave:"Saír",
  Add_Schedules:"Engadir horarios",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Activar Ausencia",
  Select_Leaves:"Seleccionar follas",
  No_Leaves:"Sen follas de ausencia",
  Leave_Of_Absence:"Excedencia",
  Leaves_Of_Absence:"Follas de ausencia",
  New_Leave_of_Absense:"Nova excedencia",
  No_Leaves_Word:"Engadir a primeira <a routerLink='/folder/Leave/New'>Ausencia</a>.",
  Not_Enabled:"Non activado",
  Absences:"Ausencias",
  Payee:"beneficiario",
  New_Payee:"Novo beneficiario",
  Payee_Identifier:"Identificador do beneficiario",
  Payee_Name:"Nome do beneficiario",
  Payee_Title:"Título do beneficiario",
  Payment_Memo:"Nota de pago",
  Select_Payees:"Seleccionar beneficiarios",
  No_Payees:"Sen beneficiarios",
  Add_Payee_Note:"Engadir o primeiro <a routerLink='/folder/Payee/New'>Payee</a>.",
  New_Payees:"Novos beneficiarios",
  About_The_Payment_Schedule:"Calendario de pagos",
  Your_Payroll_Schedule:"Nómina automática",
  New_Payment:"Pago novo",
  Identifier:"Identificador",
  Select:"Seleccionar",
  Memo:"Memo",
  Payment_Date:"Data de pago",
  Mark_as_Paid:"Marcar como pago",
  Select_Payments:"Seleccionar pagos",
  No_Payments:"Sen pagos",
  No_Payments_Word:"Crear o primeiro <a routerLink='/folder/Payment/New'>Pago</a>.",
  Create_Payroll:"Crear nómina",
  Properties:"Propiedades",
  Payroll_Title:"Título da nómina",
  Payroll_Notes:"Notas de nómina",
  Payroll_Setup:"Configuración da nómina",
  Tabulate_Payments:"Tabulación de pagos",
  Tabulate:"Tabular",
  By:"Por:",
  Payments_By:"Pagos por",
  Timesheets_And_Schedules:"Horarios e horarios",
  Both:"Ambos",
  Items:"Artigos",
  Add_Payees:"Engadir beneficiarios",
  Add_Account:"Engadir conta",
  Enable_Account:"Activar conta",
  Enable_Payroll:"Activar a nómina",
  Print_Payroll:"Imprimir nómina",
  No_Payrolls:"Sen nómina",
  No_Payroll_Word:"Cree a súa primeira <a routerLink='/folder/Payroll/New'>Nómina</a>.",
  View_more:"VIEW_MORE",
  Less:"MENOS",
  Add_Payroll:"Engadir nómina",
  Select_Payroll:"Seleccionar nómina",
  About_Your_Salary:"Sobre o teu salario",
  Add_Salaries:"Engadir salarios",
  Add_Salary:"Engadir salario",
  Salaries:"Salarios",
  No_Salaries:"Sen salarios",
  No_Salaries_Word:"Engadir o primeiro <a routerLink='/folder/Salary/New'>Salario</a>.",
  Select_Salaries:"Seleccionar salarios",
  New_Salary:"Novo Salario",
  Salary_Name:"Identificador de salario",
  Enable_Salary:"Activar salario",
  Salary_Amount:"Cantidade do salario",
  New_Schedule:"Nova programación",
  Schedule_Title:"Título da programación",
  Add_Address:"Engadir enderezo",
  Repeat:"Repetir",
  Design:"Deseño",
  Edit_Design:"Editar deseño",
  Edit_this_Design:"Editar este deseño",
  Repeat_Payment:"Repetir pago",
  Enable_Schedule:"Activar a programación",
  Never:"Nunca",
  Select_Schedule:"Seleccionar horarios",
  No_Schedules:"Sen horarios",
  No_Schedules_Word:"Cree a primeira <a routerLink='/folder/Schedule/New'>Schedule</a>.",
  New_Administrator:"Novo administrador",
  Username:"Nome de usuario",
  First_Name:"Nome",
  Last_Name:"Apelido",
  Add_an_Address:"Engadir un enderezo",
  Payment_Limit:"Límite por pago",
  Total_Limit:"Límite total",
  Select_Accounts:"Seleccionar contas",
  No_Administrators:"Sen administradores",
  No_Administrators_Word:"Cree a primeira <a routerLink='/folder/Administrator/New'>Conta de administrador</a>.",
  New_Administrators_Word:"Engadir o primeiro <a routerLink='/folder/Administrator/New'>Administrador</a>",
  Cloud:"Nube",
  Backup:"Copia de seguranza",
  Enable_iCloud:"Activar iCloud",
  Enable_Google_Drive:"Activar Google Drive",
  Enable_Microsoft_OneDrive:"Activar Microsoft OneDrive",
  Automatically_Backup:"Copia de seguranza automática",
  FTP_Settings:"Configuración FTP",
  URL_File_Prompt:"Especifique a localización para importar un ficheiro .xls / .xlsx / .json:",
  URL_SQL_Prompt:"Por favor, especifique a localización do ficheiro SQLite a importar:",
  FTP_Backup:"Copia de seguranza FTP",
  FTP_Import:"Importación FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Anfitrión",
  Port:"Porto",
  Path:"Camiño",
  Data_Path:"Ruta de datos",
  Enable_FTP_Account:"Activar conta FTP",
  HostnameIP:"Nome de host",
  Password:"Contrasinal",
  Connection_Port:"Porto de conexión",
  Enable_MySQL_Database:"Activar base de datos MySQL",
  Log:"Registro",
  Reset:"Restablecer",
  Erase:"Borrar",
  Export:"Exportar",
  Database:"Base de datos",
  Upload_CSV:"Cargar CSV",
  Download_CSV:"Descargar CSV",
  SQL_Database:"URL SQL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Copia de seguranza de MySQL",
  Internal_Notes:"Notas internas",
  Root_Path:"Ruta raíz",
  Select_Backup:"Seleccionar copia de seguranza",
  Add_New_Backup:"Engadir unha nova copia de seguranza",
  First_Backup:"Configurar a primeira copia de seguridade...",
  Backups:"Copias de seguranza",
  Add_Backup:"Engadir copia de seguranza",
  No_Backups:"Non hai copias de seguranza que se poidan atopar.",
  Select_Backup_Type:"Seleccione o tipo de copia de seguranza que desexa configurar...",
  Backup_Type:"Tipo de copia de seguranza",
  FTP_Drive:"Unidade FTP",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Conducir",
  Microsoft_OneDrive:"Conducir",
  Import_Fields:"Importar campos",
  Import_Fields_Word:"Use esta sección para seleccionar <a routerLink='/folder/Holiday/New'>Importar</a> datos.",
  Upload:"Cargar",
  Download:"Descargar",
  Download_JSON:"Descargar como datos JSON",
  Download_SQL:"Descargar como ficheiro SQL",
  New_Bank:"Novo Banco",
  New_Account:"Nova conta",
  New_Bank_Account:"Nova conta bancaria",
  Upload_Image:"Cargar imaxe",
  Bank_Name:"Nome do banco",
  Bank_Address:"Enderezo bancario",
  Branch_Address:"Enderezo da sucursal",
  Address_on_Cheque:"Enderezo",
  Cheque_Numbers:"Comprobar números",
  Cheque_Details:"Comprobar detalles",
  Bank_Logo:"Logotipo do banco",
  Cheque_ID:"Comprobar ID",
  Starting_Cheque_ID:"Iniciando a verificación de ID",
  Transit_Number:"Número de tránsito",
  Institution_Number:"Número de institución",
  Designation_Number:"Número de designación",
  Account_Number:"Número de conta",
  Limits:"Límites",
  Default_Limits:"Límites predeterminados",
  Over_Limit:"Sobre o límite",
  Under_Limit:"Baixo límite",
  Payroll_Limit:"Límite de nómina",
  Enable_Bank_Account:"Activar conta bancaria",
  Select_Account:"Seleccionar conta",
  No_Bank_Account:"Sen conta bancaria",
  No_Bank_Account_Word:"Engadir a primeira <a routerLink='/folder/Accounts/New'>Conta bancaria</a>.",
  Bank_Accounts:"Contas bancarias",
  No_Accounts:"Sen contas",
  No_Accounts_Note:"Engadir a primeira <a routerLink='/folder/Accounts/New'>Conta bancaria</a>.",
  Cheque_Designer:"Comprobar o deseñador",
  Cheque_Design:"Comprobar deseño",
  Select_Design:"Seleccione un deseño",
  Cheque_Designs:"Comprobar deseños",
  No_Cheque_Designs:"Sen deseños de verificación",
  No_Cheque_Designs_Word:"Cree o seu propio <a routerLink='/folder/Settings/Cheque/Design/New'>Comprobar deseño</a>.",
  Set_Default:"Establecer como predeterminado",
  Default:"Predeterminado",
  Remove:"Quitar",
  Folder:"Cartafol",
  Edit:"Editar",
  LoadingDots:"Cargando...",
  Add_a_New_File:"Engadir un <a href='#' (click)='add()'>Novo ficheiro</a> a",
  this_folder:"este cartafol",
  FTP_Backup_Settings:"Configuración de copia de seguranza FTP",
  Secure_File_Transfer:"Transferencia segura de ficheiros",
  New_Holiday:"Novas vacacións",
  Add_Holiday:"Engadir vacacións",
  Holiday_Name:"Nome de vacacións",
  Additional_Pay:"Pagamento adicional",
  Enable_Holiday:"Activar vacacións",
  Select_Holidays:"Seleccionar festivos",
  No_Holidays:"Non hai vacacións",
  No_Holidays_Word:"Engadir o primeiro <a routerLink='/folder/Holiday/New'>Festivo público</a>.",
  New_Import:"Nova importación",
  Import_Data:"Importar datos",
  Import_Data_Word:"Seleccione o tipo de ficheiro ou método de carga que elixa.<br /> Poderás seleccionar os campos importados dun ficheiro que correspondan ao parámetro da aplicación despois de cargar un ficheiro compatible.", 
  Import_File:"Importar ficheiro",
  Link_To_File:"Ligazón ao ficheiro",
  Select_File:"Seleccionar ficheiro",
  New_Moderator:"Novo moderador",
  Allow_Moderation:"Permitir moderación",
  Create_Paycheques:"Crear cheques",
  Edit_Paycheques_and_Data:"Editar cheques e datos",
  Destroy_Data_and_Paycheques:"Destruír datos e cheques de pagamento",
  Bonus_Percentage:"Porcentaxe do salario",
  Fixed_Amount:"Cantidade fixa",
  Select_Moderator:"Seleccionar moderador",
  No_Moderators:"Sen moderadores",
  Moderators:"Moderadores",
  Moderator_Account:"Cree a primeira <a routerLink='/folder/Administrator/New'>Conta de moderador</a>.",
  No_Moderators_Word:"Engadir o primeiro <a routerLink='/folder/Administrator/New'>Moderador</a>.",
  Defaults:"Predeterminados",
  Provide_Us_Info:"Proporcionenos información",
  Setup_Your_Printer:"Configura a túa impresora",
  Your_Company:"Sobre a túa empresa",
  Company_Name:"Nome da compañía",
  Currency:"Moeda",
  Default_Currency:"Moeda predeterminada",
  Base_Monthly_Income:"Ingresos mensuais",
  Protection:"Protección",
  App_Protection:"Protección da aplicación",
  PIN_Code_Protection:"Protección do código PIN",
  App_Protection_Word:"Activa os métodos de seguranza que axuden a protexer a túa conta",
  PIN_Code:"Código PIN",
  Change_PIN:"Cambiar PIN",
  New_Password:"Novo contrasinal",
  Geofence_Protection:"Protección de Geo-Fence",
  Geofence_Area:"Establecer área",
  Distance:"Distancia",
  Setup_Now:"Configurar agora",
  Mile:"Milla",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Recoñecemento facial",
  Face:"Cara",
  Setup:"Montar",
  Label:"Etiqueta",
  Password_Protection:"Protección con contrasinal",
  Modify_Password:"Modificar contrasinal",
  New_Tax_Entry:"Nova entrada fiscal",
  New_Tax:"Novo imposto",
  Tax_Label:"Etiqueta fiscal",
  Perpetually_Enabled:"Perpetuamente activado",
  Select_Taxes:"Seleccionar impostos",
  Tax_Deductions:"Deducións fiscais",
  No_Tax_Deductions:"Sen deducións fiscais",
  No_Tax_Deductions_Word:"Engadir a primeira dedución de <a routerLink='/folder/Tax/New'>Imposto</a>.",
  New_Timer:"Novo temporizador",
  Start:"Iniciar",
  Stop:"Para",
  Start_Timer:"Iniciar temporizador",
  Stop_Timer:"Parar o temporizador",
  Timer_Active:"Temporizador activo",
  Timer:"Timer:",
  Timer_Running:"Temporizador: (en execución)",
  Save_Timer:"Gardar temporizador",
  New_Timesheet:"Nova folla de tempo",
  Select_Timesheets:"Seleccionar follas de tempo",
  Work_Notes:"Notas de traballo",
  Entry_Title:"Título da entrada",
  No_Timesheets:"Sen follas de tempo",
  No_Timesheets_Word:"Engadir a primeira <a routerLink='/folder/Timesheet/New'>Timesheet</a>.",
  Timesheet_Submitted:"Folla de horas enviada",
  Timesheet_Congrats:"Parabéns! A túa folla de tempo foi enviada correctamente. Grazas!",
  Timesheet_Copy:"Para recibir unha copia dos seus documentos, proporcione-nos o seu enderezo de correo electrónico e/ou número de teléfono móbil.",
  Submit:"Enviar",
  Allow_Notifications:"Permitir notificacións",
  Untitled_Entry:"Nova entrada",
  Untitled_Bank:"Banco sen título",
  Timesheet_Entry:"Entrada da folla de horas",
  Work_Description:"Descrición do traballo",
  Enable_Timesheet:"Activar folla de tempo",
  Submit_Timesheet:"Enviar folla de tempo",
  Vacation:"Vacacións",
  New_Vacation:"Novas vacacións",
  Vacation_Name:"Nome das vacacións",
  Paid_Vacation:"Vacacións pagadas",
  Vacation_Pay:"Pagamento de vacacións",
  Enable_Vacation:"Activar vacacións",
  Select_Vacations:"Seleccionar vacacións",
  No_Vacations:"Non hai vacacións",
  No_Vacations_Word:"Cree a primeira entrada <a routerLink='/folder/Vacation/New'>Vacacións</a>.",
  Payroll_Schedule:"Axenda de nóminas",
  Next_Payroll:"Nómina seguinte:",
  Upcoming_Payroll:"Próxima nómina",
  No_Upcoming_Payroll:"Non hai nóminas próximas",
  Address_Book:"Axenda",
  Archived_Documents:"Documentos arquivados",
  Dev_Mode:"Aplicación en modo de desenvolvemento",
  Administrators:"Administradores",
  Privacy:"Privacidade",
  None:"Ningún",
  Select_Signature:"Seleccionar sinatura",
  No_Signatures:"Sen sinaturas",
  No_Signatures_Word:"Engadir a primeira <a routerLink='/folder/Signature/New'>Sinatura</a>.",
  Repeat_Indefinitely:"Repetir indefinidamente",
  Sign:"Asinar",
  Sign_Here:"Asinar aquí",
  Date_Signed:"Data de sinatura",
  Signature_Pad:"Panel de sinatura",
  Account_Holder:"titular da conta",
  Account_Properties:"Propiedades da conta",
  Name_On_Cheque:"Nome no cheque",
  Server_Hostname:"Nome de host/IP do servidor",
  Printers:"Impresoras",
  No_Printers:"Sen impresoras",
  Printer_Exists:'Xa existe unha impresora con este nome.',
  No_Printers_Word:"Engade a primeira <a routerLink='/folder/Printer/New'>Printer</a>.",
  No_Printer_Alert:"Non hai ningunha impresora definida. Queres configurar unha impresora?",
  Add_Printer:"Engadir impresora",
  New_Printer:"Nova impresora",
  Printer_Hostname:"Nome de host/IP da impresora",
  Printer_Label:"Etiqueta da impresora",
  Printer_Protocol:"Protocolo de impresora",
  Protocol:"Protocolo",
  Email_Print:"Correo electrónico",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Socket",
  Print_Job:"Traballo de impresión",
  Printed:"Impreso",
  Not_Printed:"Non impreso",
  Print_Jobs:"Traballos de impresión",
  Print_Queue:"Cola de impresión",
  No_Print_Jobs:"Sen traballos de impresión",
  No_Prints:"Cree un novo <a routerLink='/folder/Cheque/New'>Cheque</a> para imprimir.",
  Prints:"Impresións",
  Find_Printer:"Buscar impresora",
  Find_AirPrint_Devices:"Buscar dispositivos AirPrint",
  Select_Printer:"Seleccionar impresora",
  System_UI:"IU do sistema",
  Printer:"Impresora",
  Status:"Estado",
  Preview:"Vista previa",
  Enable_Print_Job:"Activar traballo de impresión",
  Queue_Weight:"Peso da cola",
  Unlimited:"Ilimitado",
  Show_Advanced_Printer_Options:"Mostrar opcións avanzadas de impresora",
  Advanced:"Avanzado",
  Location:"Localización",
  Note:"Nota",
  Queue_Name:"Nome da cola",
  Pages_Printed_Limit:"Límite de páxinas impresas",
  MultiPage_Idle_Time:"Tempo(s) de espera de varias páxinas",
  Page_Count_Limit:"Límite de conta de páxinas",
  Page_Orientation:"Orientación da páxina",
  Portrait:"Retrato",
  Landscape:"Paisaxe",
  Duplex:"Dúplex",
  Double_Sided_Printing:"Dobre cara",
  Duplex_Mode:"Modo dúplex",
  Duplex_Short:"curto",
  Duplex_Long:"longo",
  Duplex_None:"Ningún",
  Color_And_Quality:"Cor e calidade",
  Monochrome:"Monocromático",
  Photo_Quality_Prints:"Impresións de calidade fotográfica",
  Printer_Email:"Correo electrónico da impresora",
  Automatically_Downsize:"Reducir automaticamente",
  Paper:"Papel",
  Paper_Name:"Nome do papel",
  Paper_Width:"Ancho do papel",
  Paper_Height:"Altura do papel",
  Paper_Autocut_Length:"Lonxitude de corte automático do papel",
  Margins:"Marxes",
  Page_Margins:"Marxes de páxina",
  Page_Margin_Top:"Marxe da páxina superior",
  Page_Margin_Right:"Marxe da páxina dereita",
  Page_Margin_Bottom:"Marxe da páxina inferior",
  Page_Margin_Left:"Marxe esquerda da páxina",
  Add_Employees:"Engadir empregados",
  Header:"Encabezado",
  Print_A_Page_Header:"Imprimir un encabezado de páxina",
  Header_Label:"Etiqueta de cabeceira",
  Header_Page_Index:"Índice da páxina de cabeceira",
  Header_Font_Name:"Tipo de letra do encabezado",
  Select_Font:"Seleccionar fonte",
  Font_Selector:"Selector de fontes",
  Header_Font_Size:"Tipo de letra do encabezado",
  Header_Bold:"Encabezado en negrita",
  Header_Italic:"Encabezado cursiva",
  Header_Alignment:"Alineación da cabeceira",
  Left:"Esquerda",
  Center:"Centro",
  Right:"correcto",
  Justified:"Xustificada",
  Header_Font_Color:"Cor da cabeceira",
  Select_Color:"Seleccionar cor",
  Footer:"Pé de páxina",
  Print_A_Page_Footer:"Imprimir un pé de páxina",
  Footer_Label:"Etiqueta de pé de páxina",
  Footer_Page_Index:"Índice do pé de páxina",
  Footer_Font_Name:"Tipo de letra do pé de páxina",
  Fonts:"Tipos de letra",
  Done:"Feito",
  Select_Fonts:"Seleccionar fontes",
  Footer_Font_Size:"Tamaño do pé de páxina",
  Footer_Bold:"Footer Bold",
  Footer_Italic:"Peé de páxina cursiva",
  Footer_Alignment:"Alineación do pé de páxina",
  Footer_Font_Color:"Cor do pé de páxina",
  Page_Copies:"Copias de páxina",
  Enable_Printer:"Activar impresora",
  Remote_Logging:"Registro remoto",
  Log_Server:"Servidor de rexistro",
  Encryption:"Cifrado",
  Random_Key:"Chave aleatoria",
  Encryption_Key:"Chave de cifrado",
  Cheques_Webserver:"Base de datos personalizada",
  Learn_How:"Aprende como",
  Signature:"Sinatura",
  Default_Printer_Unit:"en/cm/mm/(pt)",
  View_Signature:"Ver a sinatura",
  Printed_Signature:"Sinatura impresa",
  Digitally_Sign:"Asinar dixitalmente",
  Digital_Signature:"Sinatura dixital",
  Add_Signature:"Engadir sinatura",
  Add_Your_Signature:"Engade a túa sinatura",
  Enable_Signature:"Activar a sinatura",
  Digitally_Signed:"Asinado dixitalmente",
  Insert_Error:"Non se puido gardar a comprobación debido a problemas na base de datos.",
  Delete_Confirmation:"Estás seguro de que queres eliminar esta información?",
  Discard_Confirmation:"Estás seguro de que queres descartar esta información?",
  Discard_Bank_Confirmation:"Estás seguro de que queres descartar esta conta?",
  Discard_Printer_Confirmation:"Estás seguro de que queres descartar esta impresora?",
  Delete_Bonus_Confirmation:"Estás seguro de que queres eliminar esta bonificación?",
  Delete_Invoice_Confirmation:"Estás seguro de que queres eliminar esta factura?",
  Generated_Cheque:"Cheque xerado",
  Generated_Invoice:"Factura xerada",
  Schedule_Start_Time:"Programa de inicio",
  Schedule_End_Time:"Fin do horario",
  New_Call:"Nova chamada",
  No_Contacts:"Sen contactos",
  No_Contacts_Word:"Os administradores, moderadores, empregados e beneficiarios aparecen como contactos",
  PDF_Subject:"finanzas",
  PDF_Keywords:"cheques de cheques PDF de nóminas",
  Printer_Setup:"Configuración da impresora",
  Printer_Selection:"Selección de impresora",
  New_Fax:"Fax novo",
  New_Fax_Message:"Nova mensaxe de fax",
  Fax_Machine:"Máquina de fax",
  Fax_Name:"Nome do fax",
  Fax_Email:"Correo electrónico de fax",
  Fax_Number:"Número de fax",
  Contents:"Contidos",
  Fax_Body:"Corpo da páxina",
  Header_Word:"Encabezado:",
  Header_Text:"Texto de cabeceira",
  Include_Header:"Incluír cabeceira",
  Include_Footer:"Incluír pé de páxina",
  Footer_Word:"Pé de páxina:",
  Footer_Text:"Texto ao pé de páxina",
  Attach_a_Cheque:"Achegue un cheque",
  Add_Deduction:"Engadir dedución",
  Enable_Fax:"Enviar fax",
  Select_Fax:"Seleccionar fax",
  No_Faxes:"Sen faxes",
  Faxes:"Faxes",
  Save_and_Send:"Enviar fax",
  Save_and_Pay:"Aforra e paga",
  WARNING:"AVISO:",
  Remember:"Lembra",
  Printing:"Impresión",
  Printing_Complete:"¡Impresión completada!\n\n",
  of:"de",
  There_Were:"Había ",
  Successful_Prints:"impresións exitosas e",
  Unsuccessful_Prints:"impresións sen éxito.",
  PrinterError:"Sentímolo! Houbo un erro.",
  Printing_:"Imprimindo...",
  PrinterSuccess:"O documento foi impreso correctamente.",
  PrintersSuccess:"Documentos impresos correctamente.",
  Print_Cheques:"Imprimir cheques",
  New_Message:"Nova mensaxe",
  New_Messages:"Novas mensaxes",
  Read_Message:"Ler a mensaxe",
  Write_Message:"Escribir mensaxe",
  Send_Message:"Enviar mensaxe",
  Subject:"Asunto",
  Message:"Mensaxe",
  Writing:"Escribindo...",
  Send:"Enviar",
  Set_Date:"Establecer data",
  Set_Time:"Establecer hora",
  Recieve:"Recibir",
  Set_as_Default:"Establecer como predeterminado",
  Default_Account:"Conta predeterminada",
  Default_Design:"Deseño por defecto",
  Multiple_Cheques:"Cheques (Triple)",
  Account_Mode:"Modo de conta",
  Multiple_Cheques_Description:"Tres comprobacións por páxina.",
  Check_and_Table:"Comprobar e táboa",
  Check_including_Table:"Táboa de verificación e contabilidade.",
  Check_Mailer:"Comprobar Mailer",
  Check_Mailer_Window:"Comprobar cunha xanela de enderezos.",
  DocuGard_Table_Top:"DocuGard Check & Table (arriba)",
  DocuGard_Table_Middle:"DocuGard Check & Table (Medio)",
  DocuGard_Table_Bottom:"DocuGard Check & Table (abaixo)",
  DocuGard_Mailer_Top:"DocuGard Check Mailer (arriba)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (medio)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mailer (abaixo)",
  DocuGard_Three_Cheques:"DocuGard Checks (Tripple)",
  DocuGard_Cheque_Top:"Comprobación de DocuGard (arriba)",
  DocuGard_Cheque_Middle:"Comprobación de DocuGard (medio)",
  DocuGard_Cheque_Bottom:"DocuGard Check (inferior)",
  DocuGard_Three_Cheques_Window:"Tres comprobacións nunha páxina.",
  DocuGard_Table_Top_Window:"Táboa de comprobación e ganancias.",
  DocuGard_Table_Middle_Window:"Táboa de comprobación e ganancias.",
  DocuGard_Table_Bottom_Window:"Táboa de comprobación e ganancias.",
  DocuGard_Mailer_Top_Window:"Comprobación, táboa e enderezo.",
  DocuGard_Mailer_Middle_Window:"Comprobación, táboa e enderezo.",
  DocuGard_Mailer_Bottom_Window:"Comprobación, táboa e enderezo.",
  DocuGard_Cheque_Top_Window:"Comproba se hai papel seguro.",
  DocuGard_Cheque_Middle_Window:"Comproba se hai papel seguro.",
  DocuGard_Cheque_Bottom_Window:"Comproba se hai papel seguro.",
  Basic_Cheque:"Comprobar (arriba)",
  Basic_Cheque_Print:"Imprime un único cheque.",
  Error_Setting_As_Paid:"Erro ao configurar como pago",
  Add_Attachment:"Engadir anexo",
  PrinterUnavailable:"Impresora non dispoñible",
  CreditCardComponent:"Cartas",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Novo depósito",
  Deposits:"Depósitos",
  No_Deposits:"Sen depósitos",
  Credit_Card_Deposit:"Tarxeta de crédito",
  New_Credit_Card_Deposit_Message:"Depósito con tarxeta de crédito",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Depósito Bitcoin",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Transferencia Interac",
  Payments_Limit:"Límite de pago",
  No_Payment_Limit:"Sen límite de pago",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Depósito PayPal",
  No_Deposits_Word:"Engadir a primeira empresa <a routerLink='/folder/Deposit/New'>Depósito</a>.",
  No_Documents_Specified:"Non se especificou ningún documento para imprimir",
  No_Printers_Added:"Non se atopou ningunha impresora. Vaia a Configuración > Impresoras para engadir unha",
  DB_Erase_Prompt:"¿Borrar completamente a base de datos? ADVERTENCIA: ¡Perderá toda a información almacenada!",
  Console_Warning:"Non pegues ningún texto nesta consola. Podes poñerte a ti mesmo e/ou á túa empresa en serio risco",
  No_Faxes_Word:"Cree o primeiro <a routerLink='/folder/Fax/New'>Fax</a>.",
  Cheque_Delete_Confirmation:"Estás seguro de que queres eliminar esta verificación?",
  Design_Delete_Confirmation:"Estás seguro de que queres eliminar este deseño?",
  Cheque_Pay_Confirmation:"Marcar este cheque como pagado? NON aparecerá na cola de impresión.",
  Payment_Pay_Confirmation:"Marcar este pago como pagado? NON aparecerá na cola de cheques.",
  Delete_Deduction_Confirmation:"Estás seguro de que queres eliminar esta dedución?",
  Delete_Job_Confirmation:"Estás seguro de que queres eliminar este traballo?",
  Delete_Timesheet_Confirmation:"Estás seguro de que queres eliminar esta folla de horas?",
  Delete_Schedule_Confirmation:"Estás seguro de que queres eliminar esta programación?",
  Delete_Setting_Confirmation:"Estás seguro de que queres restablecer esta configuración?",
  Delete_Fax_Confirmation:"Está seguro de que quere eliminar este fax?",
  Delete_File_Confirmation:"Estás seguro de que queres eliminar este ficheiro?",
  Delete_Vacation_Confirmation:"Estás seguro de que queres eliminar estas vacacións?",
  Delete_Printer_Confirmation:"Estás seguro de que queres eliminar esta impresora?",
  Remove_Design_Confirmation:"Estás seguro de que queres eliminar este deseño?",
  Delete_Payroll_Confirmation:"Estás seguro de que queres eliminar esta nómina?",
  Send_Fax_Email_Confirmation:"Quere enviar estes documentos por fax e correo electrónico?",
  Send_Email_Confirmation:"Queres enviar este documento por correo electrónico?",
  Send_Fax_Confirmation:"Desexa enviar este documento por fax?",
  Error_Generating_PDF:"Sentímolo. Produciuse un erro ao xerar este documento.",
  PDF_Error_Saving_Image:"Sentímolo. Produciuse un erro ao gardar a imaxe PDF deste documento.",
  Test_Printer_Confirmation:"Queres imprimir unha páxina de proba nesta impresora?",
  Save_Timesheet_Prompt:"Engade un 'Título' ou preme en 'Iniciar temporizador' para gardar.",
  Remove_Geofence_Prompt:"Estás seguro de que queres eliminar a localización deste xeo-valo?",
  Delete_Employee_Confirmation:"Estás seguro de que queres eliminar",
  Fire_Employee_Confirmation:"Estás seguro de que queres disparar"
}