export const Mi = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Manatārua &tārua; 2023",
  black:"Pango",
  green:"Kakariki",
  gold:"Koura",
  blue:"Kahurangi",
  brown:"Paraone",
  purple:"Waiporoporo",
  pink:"Māwhero",
  red:"Whero",
  Swatches:"Swatch",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Hue",
  Saturation:"Te hauhautanga",
  Lightness:"Maamaa",
  Upgrade_To_Pro:"Whakapai ake ki te Pro",
  AllFeaturesInclude:"Ko nga ahuatanga katoa kei roto:",
  PrintUnlimitedCheques:"Tāngia nga Tirohanga Mutunga Kore",
  PremiumChequeDesigns:"Nga Hoahoa Tirohanga Moni",
  ManageUnlimitedEmployees:"Whakahaerehia nga Kaimahi mutunga kore",
  AddUnlimitedPayees:"Taapirihia nga Kaiutu mutunga kore",
  CreateUnlimitedPayrolls:"Waihangahia nga utu utu mutunga kore",
  UnlimitedSchedulesandTimeSheets:"Nga Apiti mutunga kore me nga rau wa",
  BulkPayPalPayouts:"Utu PayPal Nui",
  UnlimitedBankAccounts:"Pūkete Peeke Unlimited",
  ManageMultipleCompanies:"Whakahaerehia nga Kamupene Maha",
  TrackInsurancePolicies:"Aroturuki Kaupapa Inihua",
  Bio_MetricProtection:"Tiaki Bio-Metric",
  Geo_FenceLock_OutProtection:"Geo-Taiapa Maukati-Oti Parenga",
  Multiple_Companies_Word:"Ko te whakahaere i nga kamupene maha karekau e waatea me te kore he moni Tirowhai.",
  PayPal_Payouts_Word:"Kua monoa nga utu a PayPal me te kore utu Tirowhai.",
  PINProtection:"Parenga PIN",
  PasswordProtection:"Parenga Kupuhipa",
  May_Require_Approval:"Ka hiahiatia he whakaaetanga.",
  Subscribe:"Ohauru",
  Billed:"Kua pire",
  Up:"Ki runga",
  Down:"Ki raro",
  Positioning:"Turanga",
  Marker:"Kaitohu",
  Drag_Marker:"Toia Tohu",
  Tagline:"Nga Tirohanga Taa me te Tabulate Utu Utu",
  Marker_Word:"Whakamahia nga Tohu ki te rahi i te huānga.",
  Pinch_Zoom:"Whakapiri Topa",
  Pinch_Word:"Whakawiri ki te topa i te huānga.",
  Drag:"Toia",
  Drag_Word:"Whakamahia to maihao ki te toia nga huānga.",
  subscription_alias_word:"Ohaurunga Whakahou Aunoa",
  premium_alias_word:"Mōkī Whakapai ake i te wā kotahi",
  print_alias_word:"Taki-Tahi Takitahi",
  mode_alias_word:"Aratau",
  Pro:"Pro",
  Pro_word:"Ko te putanga Pro e hiahiatia ana.",
  Cant_Delete_Default_Company_Word:"Aroha mai, kaore e taea e koe te muku i to kamupene taunoa.",
  Reinsert_Default_Designs:"Kōkuhu Anō i ngā Hoahoa Taunoa",
  Reinsert_Default_Designs_word:"Kei te pirangi koe ki te whakauru ano i nga hoahoa taunoa?",
  Subscription_Active_Disable_Word:"Kei te kaha tenei ohaurunga. Kei te pirangi koe ki te whakakore i tenei ohaurunga ki nga Tiki?",
  Company_Logo:"Moko Kamupene",
  ZERO_:"ZERO",
  Disclaimer:"Whakakahoretanga",
  Privacy_Policy:"Kaupapahere Tūmataiti",
  EULA:"Ka tirohia te EULA",
  Terms_Of_Service:"Nga Ture Ratonga",
  Terms_Of_Use:"Nga Ture Whakamahi",
  Refunds:"Kaupapa Whakahoki",
  Single_Print:"1 Tirohanga",
  Two_Prints:"2 Takitaki",
  Five_Prints:"5 Takitaki",
  Ten_Prints:"10 Takitaki",
  Fifteen_Prints:"15 Takitaki",
  Twenty_Prints:"20 Takitaki",
  Thirty_Prints:"30 Takitaki",
  Image_Added:"Whakaahua kua taapirihia",
  Image_Preview:"Arokite Atahanga",
  No_Image_Was_Selected:"Kaore he atahanga i tohua.",
  Cheques_Unlimited:"Tirohanga mutunga kore",
  _Subscription:"Ohaurunga",
  Subscription:"Takitaki - 1 Marama",
  Two_Month_Subscription:"Nga Tirohanga - 2 Marama",
  Three_Month_Subscription:"Nga Tirohanga - 3 Marama",
  Six_Month_Subscription:"Nga Tirohanga - 6 Marama",
  Twelve_Month_Subscription:"Nga Tirohanga - 12 Marama",
  Cheques_Are_Available:"E waatea ana nga haki hei ta.",
  Upgrade_Required_Two:"Tīpakohia he mōkihi ka pato-rua i te paatene utu hei timata i to hoko. Tāngia nga arowhai tae-katoa te ahua ngaio i roto i nga hēkona.",
  Month:"Marama",
  Due:"Taea:",
  Expires:"Ka pau:",
  Subscription_Active:"Hohe ohaurunga",
  Subscription_Inactive:"Ohaurunga Taukore",
  Purchase_Additional_Cheques:"Hoko haki taapiri?",
  Printable_Cheque:"Tirohanga Ka taea te ta",
  Printable_Cheques:"Tirohanga Ka taea te ta",
  Printable_Cheque_Word:"Kei te waatea te haki i runga i to putea.",
  Printable_Cheques_Word:"Kei te waatea nga haki i runga i to putea.",
  Max_Amount_Message:"Ko te moni kua tohua e koe kua eke ki runga ake i te rahinga morahi kua whakaritea mo tenei punaha:",
  Terms_Required_Word:"Me whakaae koe ki tenei whakaaetanga i mua i te whakamahi tonu i nga Tiki.",
  Subscriptions:"Ohaurunga",
  Product_Upgrades:"Whakapai ake",
  Mailed_Out_Cheques:"Nga Tirohanga Mēra",
  Enter_A_Company_Name:"Whakaurua koa he ingoa kamupene.",
  Single_Cheques:"Takitahi Takitahi",
  Cheque_Golden:"Tirohanga koura",
  Cheque_Golden_Window:"Hoahoa haki koura.",
  Cheque_Green:"Tirohanga Kakariki",
  Cheque_Green_Window:"Hoahoa taki kakariki.",
  Cheque_Red:"Taki Whero",
  Cheque_Red_Window:"Hoahoa haki whero.",
  Cheque_Yellow:"Taki Kōwhai",
  Cheque_Yellow_Window:"Hoahoa taki kowhai.",
  Cheque_Statue_of_Liberty:"Te whakapakoko o te Tiati",
  Cheque_Statue_of_Liberty_Window:"Ko te ahua o te Statue of Liberty tirohia te hoahoa.",
  Cheque_Green_Wave:"Ngaru Kakariki",
  Cheque_Green_Wave_Window:"Hoahoa taki kakariki.",
  Cheque_Golden_Weave:"Raranga koura",
  Cheque_Golden_Weave_Window:"Te hoahoa taki koura huatau.",
  Cheque_Green_Sun:"Ra Kakariki",
  Cheque_Green_Sun_Window:"Hohonu me te marie te hoahoa taki.",
  Cheque_Blue_Checkers:"Kaitaki Kahurangi",
  Cheque_Blue_Checkers_Window:"Hoahoa taki kikorangi.",
  Cashiers_Check:"Tiaki Kaitiaki",
  Cashiers_Check_Window:"Te tauira tauira a te Kaihoko Tirohanga.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Hoahoa taki Aqua.",
  Cheque_Golden_Checkers:"Nga Tiaki Koura",
  Cheque_Golden_Checkers_Window:"Hoahoa haki koura.",
  Upgrade_Unavailable:"Nga Whakamohoatanga Kaore i te waatea",
  Bank_Code_Protection:"Parenga Tau Peeke",
  Bank_Code_Protection_Word:"Tiakina o nama putea kei whakamahia i runga i tenei taupānga e rere ana i runga i tetahi atu taputapu, mo tetahi atu kaiwhakamahi ranei. Ko tenei mahi he IRREVERSIBLE. Haere tonu?",
  Bank_Code_Protection_Blocked_Word:"Ko nga nama putea e ngana ana koe ki te whakamahi kua rahuitia mo tetahi atu kaiwhakamahi, taputapu ranei.",
  Bank_Code_Protection_Error_Word:"Kua rahua te manatoko tau. Hono atu koa ki te ipurangi ka ngana ki te taapiri ano i tenei putea putea.",
  Bank_Code_Protection_Set_Error_Word:"Ko te tiaki nama putea me hono koe ki te ipurangi. Tūhono koa ka ngana anō.",
  Upgrade_Unavailable_Word:"Aroha mai, kei te raru matou ki te manatoko i a koe. Ko nga ohaurunga me nga whakamohoatanga ki nga Tirohanga kaore i te waatea mo te hoko i to rohe.",
  PayPal_Payment_Preview:"Arokite Utu PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Tīpakohia PayPal",
  PayPal_Applications:"Nga tono PayPal",
  Purchase_With_Apple_Pay:"Hokona me Apple Pay",
  Google_Pay:"Utu Google",
  Companies:"Kamupene",
  Insurance:"Inihua",
  New_PayPal:"PayPal hou",
  Pay_By:"Utu Ma",
  Insure:"Inihua",
  Miles:"Maero",
  Payment_Method:"Tikanga Utu",
  Select_Policies:"Tīpakohia nga kaupapa here",
  Policies:"Kaupapa here",
  Policy:"Kaupapa here",
  No_PayPal_Account:"Kaore he putea PayPal",
  No_Policies:"Kaore he kaupapa here inihua",
  New_Policy:"Kaupapa Here Hou",
  PayPal_Payment:"Utu PayPal",
  PayPal_Payments:"Utu PayPal",
  No_Payment_Selected:"Karekau he utu i tohua",
  Sending_Payment_Word:"Tatari koa... Kei te tukuna tenei utu.",
  Sending_Payments_Word:"Tatari koa... Kei te tukuna nga utu.",
  No_Payment_Selected_PayPal:"Karekau he <a routerLink='/folder/Payments'>utu PayPal</a> i tohua hei tuku.",
  Payment_Sent:"Utu i Tukua",
  PayPal_Payment_Sent:"Kua tukuna tenei utu ma te PayPal.",
  Copay:"Copay",
  Dead:"Mate",
  Alive:"E ora ana",
  Not_Dead:"Ehara i te Mate",
  Unclaimed:"Kore kereme",
  Attempted:"I ngana",
  Deceased:"Kua mate",
  Claimed:"I kii",
  Unpaid:"Kare i utua",
  Sending_Payment:"Te Tuku Utu",
  Sending_Payments:"Te Tuku Utu",
  Send_PayPal_Confirmation:"Kei te hiahia koe ki te tuku i tenei tauwhitinga me PayPal?",
  Send_PayPal_Confirmation_Word:"Pēhia te pātene kākāriki hei tuku i tēnei tauwhitinga.",
  Save_Payment_As_Unpaid:"Tiakina tenei utu hei utu kore?",
  Payment_Pay_Confirmation_PayPal:"Tiakina tenei utu hei utu?",
  No_Policies_Word:"Tāpirihia te tuatahi <a routerLink='/folder/Postage/New'>Kaupapa Inihua</a> ināianei.",
  Timesheet_Multiple_Delete_Confirmation:"E tino hiahia ana koe ki te muku i nga rau wa maha?",
  Select_Multiple_Timesheets_Prompt:"Karekau he pepa taima i tohua. Tīpakohia kia kotahi te pepa taima.",
  Select_Multiple_Policies_Prompt:"Kaore he kaupapa here i tohua. Tīpakohia kia kotahi te kaupapa here inihua.",
  Policies_Multiple_Delete_Confirmation:"E tino hiahia ana koe ki te muku i nga kaupapa here maha?",
  Company:"Kamupene",
  Add_Company:"Tāpiri Kamupene",
  New_Company:"Tāpiri Kamupene",
  No_Companies:"Kaore he Kamupene",
  Enable_Company:"Whakahohe Kamupene",
  Select_Company:"Tīpako Kamupene",
  The_Default_Company:"Ko te tapanga kamupene taunoa.",
  Manage_Companies:"Whakahaere Kamupene",
  No_Companies_Word:"Ka whakamahi nga arowhai i tetahi kamupene taunoa.<br /> Tāpirihia te <a routerLink='/folder/Company/New'>Kamupene tuatahi</a>.",
  Default_Company:"Kamupene Taunoa",
  Cheques_Unlimited_Word:"Ko te Checks Unlimited ka taea e koe te tuhi i nga haki e pai ana koe.",
  Cheques_Subscription_Word:"Ma te ohaurunga Tirohanga ka taea e koe te tuhi i te maha o nga haki e pai ana koe.",
  You_Own_This_Product:"Nou tenei hua.",
  Your_Subscription_is_Active:"Kei te kaha to ohaurunga.",
  Active_Products:"Hua Whakahohe",
  Purchase_Confirmation:"Hokonga",
  Purchase_Cheques:"Nga Tirohanga Hoko",
  Restore_Purchase:"Whakahokia nga Hokonga",
  Erase_Purchase:"Mukua Hokonga",
  Successfully_Purchased:"I Angitū te Hoko",
  Enter_Your_Licence_Key:"Whakauruhia koa to raihana raihana ki tenei wharangi hei whakahohe i tenei hua.",
  Licence_Key:"Kī Raihana",
  Enter_Licence_Key:"Whakauruhia te Kī Raihana",
  Purchased:"Kua hokona",
  Enable_Feature:"Whakahohehia te waahanga",
  Cancel_Subscription:"Whakakore ohaurunga",
  Subscription_Removed:"Kua Tangohia te Ohaurunga",
  Select_Active_Subscription:"Tīpakohia he ohaurunga kaha hei whakarereke.",
  Remove_Subscription_Word:"Kei te tino hiahia koe ki te whakakore i tenei ohaurunga?",
  Delete_Company_Confirmation:"Kei te tino hiahia koe ki te muku i tenei kamupene katoa? WHAKATŪPATO: Ka ngaro koe i nga korero rongoa katoa!",
  Delete_Default_Company_Word:"Kaore e taea e koe te whakakore i te kamupene taunoa. Kei te pirangi koe ki te tautuhi ano i te tono me te whakahoki ano ki te ahua taunoa? WHAKATŪPATO: Ka ngaro koe i nga korero rongoa katoa!",
  Console_Warning_2:"Kaua e hapai i tetahi moni ma te whakamahi i tenei tono ehara i a koe i tenei wa.",
  Terms_and_Conditions:"Nga Ture me nga Tikanga",
  and_the:"me te",
  for:"mo",
  Please_Read_Word:"Tena panui ka whakaae ki te",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Ko etahi reiti whakawhiti moni kaore i kitea. Tūhono koa ki te ipurangi.",
  Free:"Kore utu",
  DB_Erase_Prompt_2:"Me ūkui katoa te pātengi raraunga kaiwhakawhanake? WHAKATOKANGA: Ka ngaro katoa nga korero mo te hoko me te ohaurunga!",
  Successfully_Imported:"I Angitū te Kawemai",
  Select_Postage:"Tīpakohia te Poutāpeta",
  No_Postage:"Karekau he Panetere",
  No_Paid_Postage_Word:"Tāpirihia te tohu tuatahi <a routerLink='/folder/Postage/New'>Pākete Utu</a>.",
  Trial_Complete:'Kua Oti te Whakamatau',
  Please_Upgrade:'Whakapai ake i nga Tirowhai kia ta tonu.',
  Aa:"Aa",
  Color:"Tae",
  Font:"Momotuhi",
  Guide:"Arataki",
  Guides:"Kaitohutohu",
  Image:"Whakaahua",
  Zoom:"Topa",
  Logo:"Moko",
  Bank:"Peeke",
  MICR:"MICR",
  Total:"Tapeke",
  Cancel:"Whakakore",
  Confirm:"Whakaū",
  Method:"Tikanga",
  Biometric_Security:"Haumarutanga Bio-metric",
  Please_Login_To_Continue:"Tena koa takiuru kia haere tonu.",
  Complete:"Kua oti",
  Sign_Up:"Waitohu",
  Error:"Hapa",
  Biometrics:"Tikanga-koiora",
  Percent:"ōrau",
  Zero_Percent:"0%",
  Top_Margin:"Tawhē Runga",
  Bottom_Margin:"Tawhē Raro",
  Left_Margin:"Tawhē Mauī",
  Right_Margin:"Tawhē Matau",
  MICR_Margin:"Tawhē MICR",
  Table_Margin:"Tawhē Ripanga",
  Address_Margin:"Wāhitau Tawhē",
  Percentage_:"Te ōrau",
  Vacation_Title:"Taitara hararei",
  Use_PIN:"Whakamahia te PIN",
  Loading__:"Uta ana...",
  Design_Title:"Taitara Hoahoa",
  Authorize:"Whakamana",
  Key:"Kī",
  Public_Key:"Kī Tūmatanui",
  Private_Key:"Kī Tūmataiti",
  Authenticate:"Motuhēhē",
  Last_Payroll:"Utu Whakamutunga",
  Last_Calculation:"Tatau Whakamutunga",
  Authorized:"Whakamana",
  Geo_Authorized:"Wāhi-a-rangi: Kua whakamanahia",
  Not_Authorized:"Kaore i Whakamana",
  Authorization_Complete:"Kua Oti te Whakamana",
  Geolocation_Authorization:"Whakaaetanga Geo-tauwāhi",
  Out_of_Bounds:"I waho o te rohe",
  Cant_Delete_Default_Design:"Kaore e taea te muku i tetahi hoahoa taunoa.",
  Unauthorized_Out_of_Bounds:"Kaore i Whakamana: Kei waho i te rohe",
  Biometric_Authorization:"Whakaaetanga bio-metric",
  Locating_Please_Wait:"Kei te kimi, Tena Tatari...",
  Test_Biometrics:"Whakamātautau Bio-metrics",
  Cheque_Margins:"Tirohia Tawhē",
  Percentage_Full_Error:"Karekau e taea te whakarite te mara ōrau ki runga ake i te 100% ōrau.",
  No_Payroll_Text:"Tāpirihia he <a routerLink='/folder/Employee/New'>Kaimahi</a> ranei <a routerLink='/folder/Payee/New'>Payee</a> me tetahi <a routerLink='/folder/Schedule/New'>Hōtaka</a>.",
  Design_Saved:"Hoahoa kua Tiaki",
  Default_Design_Selected:"Hoahoa Taunoa kua tohua",
  Partial_Import:"Kawemai Wahi",
  Partial_Export:"Kaweake Wahi",
  Entire_Import:"Kawemai Katoa",
  Entire_Export:"Kaweake Katoa",
  Existing_Password:"Whakauruhia koa to kupuhipa o naianei:",
  Existing_PIN:"Whakauruhia koa to waehere PIN inaianei:",
  Pin_Change_Header:"Whakaputanga PIN",
  Pin_Change_SubHeader:"Whakauruhia to nama PIN tawhito hei whakaū i te huringa.",
  Pass_Change_Header:"Kupuhipa Kupuhipa",
  Pass_Change_SubHeader:"Whakauruhia to kupuhipa tawhito hei whakaū i te huringa.",
  PIN_Enter_Message:"Whakauruhia to PIN hei whakau.",
  Password_Enter_Message:"Whakauruhia to kupuhipa hei whakau.",
  Pin_Updated_Success:"I pai te whakahou PIN!",
  Pin_Updated_Fail:"Kāore i taea te whakahōu PIN.",
  Pass_Updated_Success:"I momoho te whakahou i te kupuhipa.",
  Pass_Updated_Fail:"Kaore i taea te whakahou i te kupuhipa.",
  Preview_Payment:"Arokite Utu",
  Preview_Payroll:"Arokite te utu utu",
  No_Payments_Created:"Karekau he utu i kitea i hangaia.",
  Payment_Preview:"Arokite Utu",
  Enable_Payee:"Whakahohe Kaiutu",
  Rendered:"Kua tukuna",
  No_Email:"Kore Īmēra",
  Setup_Cheques:"Nga Tirohanga Tatūnga",
  name:"Ingoa",
  address:"Wāhitau",
  address_2:"Raina Wāhitau 2",
  city:"Taone",
  province:"Porowini",
  postal_code:"Waehere Poutāpeta/ZIP",
  country:"Whenua",
  username:"Ingoa Kaiwhakamahi",
  full_name:"Ingoa Katoa",
  birthday:"Ra whanau",
  email:"Īmēra",
  phone:"Waea",
  employees:"Kaimahi",
  addresses:"Wāhitau",
  payment_amount_limit:"Tepe Moni Utu",
  total_limit:"Tepe katoa",
  payees:"Kaiutu",
  description:"Whakaahuatanga",
  address_line_one:"Wāhitau Raina Tuatahi",
  address_line_two:"Wāhitau Raina Tuarua",
  image:"Whakaahua",
  account_holder:"Kaipupuri Kaute",
  cheque_starting_id:"Tirohia ID Tīmatanga",
  transit_number:"Tau whakawhiti",
  institution_number:"Nama Whare",
  designation_number:"Tau Tohunga",
  account_number:"Tau Kaute",
  currency:"Moni",
  signature:"Waitohu",
  payment_payroll_limit:"Tepe utu",
  total_limi:"Tepe katoa",
  date:"Rā",
  printed_memo:"Panui Panui",
  banks:"Peeke",
  signature_image:"Waitohu Atahanga",
  address_name:"Ingoa Wāhitau",
  notes:"Notes",
  design:"Hoahoa",
  title:"Taitara",
  frequency:"Auautanga",
  fax:"Waeatuhi",
  salaries:"Utu",
  salary_ids:"Utu ID",
  start_time:"Wā Tīmata",
  end_time:"Te Wa Whakamutunga",
  paid:"Ka utua",
  overtime_percentage:"Te Ōrau Utu",
  overtime_amount:"Utu Moni Pumau",
  first_name:"Ingoa Tuatahi",
  last_name:"Ingoa Whakamutunga",
  moderation:"Whakaōrite",
  create:"Waihanga",
  edit:"Whakatikatika",
  destroy:"Whakangaromia",
  day_start_time:"Ra_timata_wa",
  day_end_time:"Ra_mutanga_wa",
  fullname:"Ingoa",
  time:"Te wa",
  auto_send:"Tuku Aunoa",
  time_method:"Tikanga Wa",
  schedules:"Nga Apiti",
  indefinite_payroll_enabled:"Whakahohe Ake",
  amount:"Te nui",
  repeat:"Whakahokia",
  always_enabled:"Whakahohea I nga wa katoa",
  start_date:"Rā Tīmata",
  end_date:"Te Ra Whakamutunga",
  Cheque_Total:"Taki Tapeke",
  Total_Amount:"Tapeke Tapeke:",
  Amounts:"Nga moni:",
  Images:"Whakaahua",
  Files:"Kōnae",
  Previewing:"Arokite ana:",
  Insert:"Kōkuhu",
  Preview_Import:"Arokite Kawemai",
  Entry:"Te urunga",
  Entries:"Nga urunga",
  No_Entries:"Karekau he urunga",
  Import_Type:"Momo Kawemai",
  Select_Details:"Tīpakohia nga Taipitopito",
  Select_Payee:"Tīpakohia te Kaiutu",
  Enable_Holidays:"Whakahohehia nga Hararei",
  Disable_Holidays:"Monokia nga Hararei",
  Wire_Transfer:"Whakawhiti Waea",
  New_Export:"Kaweake Hou",
  Export_Data:"Kaweake Raraunga",
  Export_Data_Word:"Tīpakohia te momo kōnae hei kaweake me te tikiake.",
  Export_File:"Kōnae Kaweake",
  Mode:"Aratau",
  Times:"Nga taima",
  Widgets:"Ngā widget",
  Slider:"Kiriata",
  Set_Details:"Tautuhi Taipitopito",
  Select_Type:"Tīpakohia te Momo",
  Display_Slider:"Whakaatu Rēreti",
  Dashboard_Slider:"Rēreti Papatohu",
  Dashboard_Mode:"Aratau Papatohu",
  Show_Intro:"Whakaatu Intro",
  Show_Payrolls:"Whakaatuhia nga utu utu",
  Show_Holidays:"Whakaatu Hararei",
  Show_Invoices:"Whakaatuhia nga nama",
  Show_Cheques:"Whakaatu Takitaki",
  Enabled_Widgets:"Nga Waahanga Whakahohe",
  Disabled_Widgets:"Nga Waahanga Mono",
  Colors:"Nga Tae",
  Barcodes:"Waehere Pae",
  View_Timers:"Tiro Taima",
  Gradients:"Rōnaki",
  No_Info:"Karekau he korero",
  Disable:"Monokia",
  Show_Layer:"Whakaatu Paparanga",
  Hide_Layer:"Huna Paparanga",
  Text_Layer:"Paparanga Kuputuhi",
  Secondly:"Tuarua",
  Minutely:"Te meneti",
  nine_am:"9:00 AM",
  five_pm:"5:00 PM",
  Enable_Address:"Whakahohe Wāhitau",
  Invalid_File_Type:"Aroha mai, he momo kōnae muhu i tohua. Momo kōnae tautoko:",
  Error_Updating_Entry:"Aroha mai, i hapa te whakahōu i tēnei tāurunga.",
  Schedule_Timing_Alert:"Hapa: Kua tautuhia te wa tiimata ki te uara i muri i te wa mutunga.",
  Select_Multiple_Payments_Prompt:"Karekau he utu i tohua. Tīpakohia kia kotahi te utu.",
  Select_Multiple_Cheques_Prompt:"Karekau he arowhai i tohua. Tīpakohia koa kia kotahi te haki.",
  Select_Multiple_Items_Prompt:"Karekau he tuemi i tohua. Tīpakohia koa kia kotahi te mea.",
  Paymemt_Multiple_Delete_Confirmation:"E tino hiahia ana koe ki te muku i nga utu maha?",
  Cheque_Multiple_Delete_Confirmation:"E tino hiahia ana koe ki te muku i nga haki maha?",
  Payee_Multiple_Delete_Confirmation:"E tino hiahia ana koe ki te muku i nga utu maha?",
  Employee_Multiple_Delete_Confirmation:"Kei te tino hiahia koe ki te muku i nga kaimahi maha?",
  MICR_Warning:"Tuhipoka: Ko etahi o nga kaituhi me nga taputapu kaore e whakaatu tika i te momotuhi MICR.",
  Automatically_Send:"Tuku Aunoa",
  Type:"Momo",
  Payment_Type:"Momo Utu",
  Auto_Send:"Tuku Aunoa",
  Automatically_Process:"Tukatuka Aunoa",
  Auto_Process:"Tukatuka Aunoa",
  Image_Based:"I runga i te whakaahua",
  Font_Based:"I runga i te momotuhi",
  Rerender:"Tukua ano",
  Rendering:"Tāhuahua",
  Render:"Kōnae",
  Top:"Runga",
  Middle:"Waenganui",
  Bottom:"Raro",
  Top_Left:"Runga Mauī",
  Top_Center:"Pokapū Runga",
  Top_Right:"Runga Matau",
  Middle_Left:"Waenga Mauī",
  Middle_Center:"Pokapū Waenganui",
  Middle_Right:"Waenga Matau",
  Bottom_Left:"Raro Mauī",
  Bottom_Center:"Pokapū Raro",
  Bottom_Right:"Raro Matau",
  Numbers:"Tau",
  Verified:"Manatoko",
  Paper_Size:"Rahi Pepa",
  New_Device:"Pūrere Hou",
  Add_Device:"Tāpiri Pūrere",
  Remove_Device:"Tango Pūrere",
  Delete_Device:"Mukua te Pūrere",
  Block_Device:"Pūrere Poraka",
  Block:"Poraka",
  Unblock:"Wewete",
  Table:"Ripanga",
  Scan_Login_Code:"Matawai Waehere Takiuru",
  Login_Code:"Waehere Takiuru",
  Scan_Code:"Waehere Matawai",
  Scan_QR_Code:"Matawai Waehere QR",
  Select_All:"Tīpakohia Katoa",
  Deselect_All:"Whakakorehia te Katoa",
  Increase:"Whakanuia",
  Decrease:"Whakaheke",
  Bold:"Te maia",
  Text:"Kuputuhi",
  Style:"Kāhua",
  Italic:"Tītaha",
  QR_Code:"Waehere QR",
  Barcode:"Waehere Pae",
  Browse_Images:"Tirotiro Whakaahua",
  Browse_Files:"Tirotiro Kōnae",
  Background_Image:"Whakaahua Papamuri",
  Logo_Image:"Whakaahua Moko",
  Header_Image:"Atahanga Pane",
  Bank_Image:"Whakaahua Peeke",
  Cut_Lines:"Tapahia Raina",
  Background:"Papamuri",
  License:"Raihana",
  One_License:"1 Raihana:",
  Licensed:"Kua Raihana ki:",
  Not_Licensed:"Kaore i Raihanatia",
  Enter_Serial:"Whakaurua Rangatū",
  Serial_Key:"Kī Rangatū",
  Serial:"Rangatū",
  Product_Key:"Kī Hua",
  Check_Product_Key:"Tirohia te Kī Hua",
  Add_Product_Key:"Tāpiri Kī Hua",
  Verifying_Purchase:"Manatoko Hokonga...",
  Print_Envelope:"Pūhera Tā",
  Envelope:"Kōpaki",
  Thank_You:"Tēnā Koe!",
  Scale:"Tauine",
  Print_Scale:"Tauine tā",
  Borders:"Taitapa",
  VOID:"KAUPAPA",
  Void_Cheque:"Tirohanga kore",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"Utua KI TE OTA O:",
  NO_DOLLARS:"KORE TAARA ",
  ONE_DOLLAR:"KOTAHI TAARA",
  DOLLARS:" TARA",
  AND:" ME ",
  CENTS:" HENETI.",
  NO_:"NO ",
  ONE_:"KOTAHI ",
  AND_NO_:"ME KAO ",
  _AND_ONE_:"ME KOTAHI ",
  DOLLARS_AND_ONE_CENT:" ME KOTAHI HENETE.",
  AND_NO_CENTS:" ME ZERO CENTS.",
  CHEQUE_PRINT_DATE:"RĀ:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Nga Mahi Tuatahi",
  Inital_Setup:"Tatūnga Tuatahi",
  Welcome_To:"Nau mai ki te",
  Welcome:"Nau mai",
  Swipe:"Swipe",
  Intro_Setup:"Tatūnga Whakataki",
  Introduction:"Whakataki",
  CHEQUE_PRINT_CREDIT:"Whakamanahia e nga Takitaki",
  Title_Intro_Word:"Nau mai ki nga Takitaki",
  Title_Intro:"Whakataki Takitaki",
  Title_Setup:"Tatūnga Takitaki",
  PayPal_Default_Email_Message:"Kua whiwhi koe i tetahi whakawhitinga PayPal hou. [I taia e nga Tirowhai]",
  Cheques_App_Export:"Kua kaweake e nga haki",
  Post_Intro_Thanks:"Me mihi ki a koe mo te whiriwhiri i nga Tirowhai. Kua oti te tukanga tatūnga.",
  Post_Intro_Word:"Timatahia ma te tuhi i to haki tuatahi, taapirihia he utu a muri ake nei, taapirihia he kaimahi ki te utu.",
  Upgrade_Required:"Ko nga haki me whiwhi koe i tetahi putanga utu nui ake o te rorohiko hei huna i tenei karere me te iriti i etahi atu waahanga.",
  Upgrade_Title:"Tirohanga",
  Mailout_Prompt:"Ka taea ano e koe te whiriwhiri kia tukuna atu e nga Tirotiro nga haki ki a koe.",
  Mailed_Cheque:"Tirohanga Mēra: ",
  Mailed_Cheque_Color:"Tirohanga Mēra (Tae): ",
  Terms_Purchase:"Ko nga hoko hiko katoa me nga Tirotiro ka utua katoa mo te 30-ra mai i te ra hoko. Tena koa panuihia me whakaae ki nga <a href='#'>Tuhinga me nga Tikanga</a> i mua i te haere tonu.",
  Dashboard_Setup:"Tatuhia te Kairereta Tuatahi",
  Dashboard_Add:"Taapirihia te Kaute Peeke Tuatahi",
  Dashboard_Customize:"Tīpakohia He Tātauira Taki",
  Dashboard_Job_Salary:"Waihangahia to mahi me te taapiri i to utu",
  Dashboard_Employees:"Aru i nga Kaimahi me nga Kaiutu",
  Dashboard_Print:"Taia me te Mēra i o Tiaki Utu",
  Dashboard_Payroll:"Whakaaunoa i to Taa Utu",
  Dashboard_PayPal:"Whakaritea PayPal Payroll / Utu",
  Begin_Setup:"Tīmata Tatūnga",
  Get_Started:"Timata",
  Purchase:"Hokona",
  Lockdown:"Kati",
  Unlock:"Wewete",
  Detailed:"Taipitopito",
  Log_In:"Takiuru",
  Login:"Takiuru",
  Launch:"Whakarewa",
  Register:"Rēhita",
  Finish:"Whakaoti",
  List:"Rārangi",
  Weekends:"Ngā wiki",
  Weekly:"Ia wiki",
  PayPal_Default_Subject:"Utu Hou",
  Payment_Message:"Karere Utu",
  PayPal_Default_Payment_Note:"Mauruuru",
  Setup_Your_Cheqing_Account:"Tirotiro Kaute",
  Add_Your_Primary_Cheqing_Account:"Taapirihia to putea kaute tuatahi.",
  Welcome_Word:"Whakamahia me te whakaaunoa i te utu utu me te whakahaere rauemi tangata.",
  Get_Started_Quickly:"Whakautua noa etahi patai ngawari hei awhina i a maatau ki te timata...",
  Done_Intro_Word:"Kua Oti te Tatūnga",
  PIN_Protected:"Kua Tiaki Kupuhipa me PIN",
  Enter_New_PIN:"Whakauruhia he Waehere PIN hou:",
  Enter_PIN:"Whakauruhia te Waehere PIN:",
  Invalid_PIN:"Kua whakaurua te PIN muhu.",
  Account_Identifier:"Tautuhi Pūkete",
  New_Account_Identifier:"Tautuhi Pūkete Hou",
  attempt:"ngana",
  attempts:"ngana",
  remaining:"e toe ana",
  Language:"Reo",
  languages:"Reo",
  select_languages:"Tīpakohia te Reo",
  Deposit:"Pupuu",
  Hire_One_Now:"Hire One Now",
  App_Locked:"Kua maukati te tono.",
  Skip_:"Hoki",
  Skip_to_Dashboard:"Hoko ki te Papatohu",
  Dashboard:"Papatohu",
  Import:"Kawemai",
  Admin:"Kaiwhakahaere",
  New_Admin:"Kaiwhakahaere Hou",
  Settings:"Tautuhinga",
  Color_Picker:"Kaiwhiri Tae",
  Font_Picker:"Kaiwhiri Momotuhi",
  Logout:"Takiputa",
  Close:"Kati",
  Close_menu:"Kati",
  Excel:"Kōnae Excel",
  Csv:"Kōnae CSV",
  Sql:"Kōnae SQL",
  Json:"Kōnae JSON",
  Url:"Kawemai ma te URL",
  Back:"Hoki",
  Timers:"Taima",
  Cheque:"Tirohia",
  Print:"Tā",
  Designs:"Hoahoa",
  Pause_Printing:"Okioki te Tā",
  Resume_Printing:"Whakahoutia te Tā",
  Printing_Paused:"Kua Tatari te Ta",
  PrintingUnavailable:"Aroha mai! Kore i te watea te tā ki tenei punaha.",
  Prints_Paused:"Tokia Taatari",
  Administration:"Whakahaere",
  Loading:"Uta ana",
  Unnamed:"Kaore i whakaingoatia",
  error:"Aroha mai, kaore i taea te whakatuwhera i tenei haki mo te tirohanga.",
  No_Cheques_To_Print:"Karekau he Takitaki hei Ta",
  No_Cheques_To_Print_Word:"Hangaia he <a routerLink='/folder/Cheque/New'>Hōu Tiaki</a>.",
  New_Cheque:"Tirohanga Hou",
  Start_One_Now:"Tīmata Kotahi Inaianei",
  Edit_Cheque:"Whakatika Taki",
  Select_Cheques:"Tīpakohia nga Takitaki",
  Select_Employee:"Tīpakohia te Kaimahi",
  Default_Printer:"Pitarereta Taunoa",
  Reassign:"Whakaritea ano",
  Configure:"Whakaritea",
  Template:"Tauira",
  Designer:"Kaihoahoa",
  Edit_Designs:"Whakatika Hoahoa",
  New_Design:"Hoahoa Hou",
  Next:"Whai muri",
  Save:"Tiaki",
  Name:"Ingoa",
  Mail:"Meera",
  Amount:"Nui",
  Date:"Rā",
  PayPal:"PayPal",
  Payouts:"Nga utu",
  PayPal_Label:"Tapanga PayPal",
  Email_Username:"Imeera / Ingoa Kaiwhakamahi",
  Client_ID:"ID Kiritaki",
  Sandbox_Email:"Imeera Pouaka kirikiri",
  PayPal_Email:"Imeera PayPal",
  PayPal_Username:"Ingoa Kaiwhakamahi API",
  PayPal_Payouts:"Nga utu PayPal",
  Select_PayPal_Key:"Tīpakohia te Kī PayPal",
  Secret:"Mea huna",
  API_Secret:"API ngaro",
  PayPal_API_Keys:"PayPal Kī",
  New_PayPal_Key:"Kī PayPal Hou",
  Email_Subject:"Marama Email",
  Email_Message:"Karere imeera",
  Payout_Options:"Ngā Kōwhiringa Utu",
  Payment_Note:"Tuhipoka Utu",
  Enable_Employee:"Whakahohe Kaimahi",
  Enable_Production_Mode:"Whakahohehia te Aratau Whakaputa",
  Sandbox_Username:"Ingoa Kaiwhakamahi Pouaka kirikiri",
  Sandbox_Signature:"Waitohu Pouaka kirikiri",
  Sandbox_Password:"Kupuhipa Sandbox",
  Production_Username:"Ingoa Kaiwhakamahi Whakaputa",
  Production_Signature:"Waitohu Whakaputa",
  Production_Password:"Whakaputa Kupuhipa",
  Production_Email:"Imeera Whakaputa",
  API_Client_ID:"ID Kiritaki API",
  API_Signature:"Waitohu API",
  API_Password:"API Kupuhipa",
  API_Username:"Ingoa Kaiwhakamahi API",
  Secret_Key:"Kī ngaro",
  Sandbox:"Poakakirikiri",
  Production:"Whakaputa",
  Sandbox_Keys:"Kī Pouaka kirikiri",
  Production_Keys:"Kī Whakaputa",
  API_Title:"Taitara API",
  Production_Mode:"Aratau Whakaputa",
  Account_Label:"Tapanga Pūkete",
  No_PayPal_Setup:"Karekau he Kī PayPal",
  Enable_PayPal_Account:"Whakahohehia te Kaute PayPal",
  No_PayPal_Word:"Taapirihia to <a routerLink='/folder/Invoice/New'>Kī API PayPal</a>.",
  Printed_Memo:"Memo kua taia",
  Employee:"Kaimahi",
  View_Employee:"Tirohia te Kaimahi",
  Mailing_Address:"Wāhitau Mēra",
  Company_Address:"Wāhitau Kamupene",
  Select_Company_Address:"Tīpakohia te Wāhitau Kamupene",
  Address:"Wāhitau",
  Any_Day:"Tetahi ra",
  Address_Name:"Ingoa Wāhitau",
  Unit:"Waeine",
  Account:"Pūkete",
  Bank_Account:"Putea Peeke",
  Account_Limits:"Whakahohe Nga Tepe Kaute",
  Payroll:"Tuhinga utu",
  New_Payroll:"Te utu utu hou",
  No_Payroll:"Kaore he utu e haere ake nei",
  Upcoming_Holiday:"Haereere e haere ake nei:",
  Invoice_Due:"Tuku Nama:",
  New_Invoice:"Ture Hou",
  No_Invoices:"Kaore he nama",
  No_Invoices_Word:"Waihangahia te <a routerLink='/folder/Invoice/New'>Nama</a> tuatahi.",
  Cheque_Due:"Tirohia:",
  Payrolls:"Nga utu utu",
  Sandbox_Mode:"Aratau Pouaka kirikiri",
  Hire:"Hire",
  Pay:"Utu",
  New:"Hou",
  Add:"Tāpiri",
  Make:"Hanga",
  Time:"Wā",
  Write:"Tuhia",
  Holiday:"Haereere",
  Holidays:"Haereere",
  Next_Holiday:"Haereere a muri ake nei:",
  All_Done:"Kua oti katoa!",
  Employees:"Kaimahi",
  Payees:"Ka utua",
  Job:"Hopa",
  Jobs:"Nga mahi",
  Invoice:"Puka nama",
  Invoices:"Nga nama",
  Vacations:"Haereere",
  Cheques:"Tirohanga",
  Brand_Cheques:"Waitohu",
  Payment:"Utu",
  Enable_Payment:"Whakahohe Utu",
  Payments:"Nga utu",
  Schedule:"Mahere",
  Schedules:"Nga Apiti",
  Timesheet:"Wā Pepa",
  Timesheets:"Waahi Rau",
  Salary:"Utu",
  New_Address:"Wāhitau Hōu",
  Address_2:"Wāhitau (Raina 2)",
  _City:"Taone",
  _State:"State/Prov",
  City:"Taone / Taone",
  State:"Whenua / Porowini",
  Postal:"Waehere Poutāpeta / ZIP",
  ZIP:"Poutauta / ZIP",
  Country:"Whenua",
  Addresses:"Nga wahitau",
  Required_Options:"Ngā Kōwhiringa e hiahiatia ana",
  Optional_Options:"Kōwhiringa Kōwhiringa",
  Additional_Options:"Ngā Kōwhiringa Tāpiri",
  Required:"E hiahiatia ana",
  Optional:"Kōwhiringa",
  Additional:"Apiti atu",
  No_Addresses:"Karekau he wahitau",
  New_Address_Word:"Tāpirihia te <a routerLink='/folder/Address/New'>Wāhitau</a> tuatahi.",
  Select_Address:"Tīpako Wāhitau",
  No_Address:"Karekau he wahitau",
  Print_Cheque:"Tirohanga Tā",
  Print_Cheque_Now:"Tirohia Inaianei",
  Description:"Whakaahuatanga",
  Pay_To_The_Order_Of:"Utua ki te Ota o:",
  Select_Date_Range:"Tīpakohia te Awhe Rā",
  Select_Starting_Date:"Tīpakohia te Rā Tīmata",
  Select_Ending_Date:"Tīpakohia te Rā Mutunga",
  Select_Date:"Tīpakohia te Rā",
  Cheque_Date:"Rā Tirohanga",
  Cheque_Memo:"Tirohia Memo",
  Blank_Cheque:"Tirohanga Patea",
  Blank:"Patea",
  No_Cheques:"Kaore he Takitaki",
  No_Cheques_Word:"Tangia to <a routerLink='/folder/Cheque/New'>Tirohanga</a> tuatahi.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"Tirohia te Whakaahua",
  View:"Tirohia",
  Modify:"Whakakē",
  Delete:"Muku",
  Cheque_Paid:"Ka utua",
  New_Deduction:"Tuhinga Hou",
  Title:"Taitara",
  Frequency:"Te auau",
  Hourly:"Ia haora",
  Daily:"Ia rā",
  Weekdays:"Ngā wiki",
  BiWeekly:"2 Wiki",
  TriWeekly:"3 Wiki",
  Monthly:"Marama",
  MiMonthly:"2 Marama",
  Quarterly:"Ia marama",
  Yearly:"Ia tau",
  Every_Week:"Ia Wiki",
  Every_Payroll:"Nga utu utu katoa",
  Every_Month:"Ia Marama",
  Annually:"Ia tau",
  Always_Scheduled:"Whakaritea i nga wa katoa",
  Start_Date:"Rā Tīmata",
  End_Date:"Rā Mutunga",
  Start_Time:"Wā Tīmata",
  End_Time:"Wā Mutunga",
  Deduction_Label:"Tapanga Tangohanga",
  Notes:"Tuhipoka",
  Options:"Kōwhiringa",
  Enable:"Whakahohe",
  Select_Deductions:"Tīpakohia te tangohanga",
  Deductions:"Nga tangohanga",
  No_Deductions:"Kaore he tangohanga",
  No_Deductions_Word:"Waihangahia to <a routerLink='/folder/Deduction/New'>Tangohanga</a> tuatahi.",
  New_Employee:"Kaimahi Hou",
  No_Title:"Kaore he taitara",
  _Name:"Ingoa",
  About_Yourself:"Mo koe ano",
  Full_Name:"Ingoa Katoa",
  Birthday:"Ra whanau",
  Email:"Imeera",
  SMS:"SMS",
  Phone:"Waea",
  Test:"Whakamatau",
  Call:"Karanga",
  Fax:"Waeatuhi",
  Printed_Note:"Tuhipoka kua taia",
  Position:"Turanga",
  Job_Position:"Tuhinga Mahi",
  Select_a_Job:"Tīpakohia he Mahi",
  Select_a_Salary:"Tīpakohia he utu",
  Add_a_Deduction:"Taapirihia he tangohanga",
  Taxes:"Taake",
  Add_Taxes:"Taapiri Taake",
  Date_of_Birth:"Te ra whanau",
  Email_Address:"Wāhitau īmēra",
  Phone_Number:"Tau Waea",
  Phone_Call:"Waea Waea",
  Enable_on_Payroll:"Whakahohe i runga i te utu utu",
  Select_Employees:"Tīpakohia nga Kaimahi",
  No_Employees:"Kaore he Kaimahi",
  No_Employees_Word:"Taapirihia to hou tuatahi <a routerLink='/folder/Employee/New'>Kaimahi</a>.",
  No_Name:"Kaore he Ingoa",
  Unemployeed:"Kaore he mahi",
  Employeed:"E mahi ana",
  Paid:"Ka utua",
  Enabled:"Whakahohe",
  Disabled:"Mua",
  Fire:"Ahi",
  Not_Available:"Kare i te waatea",
  Not_Available_Word:"Tangia to <a routerLink='/folder/Invoice/New'>Nama</a> tuatahi me te utu.",
  Select_Invoices:"Tīpakohia_Ngā nama",
  Print_Invoice_Word:"Tangia to <a routerLink='/folder/Invoice/New'>Nama</a> tuatahi me te utu.",
  Invoice_Title:"Taitara Nama",
  Invoice_Date:"Rā nama",
  Due_Date:"Rā Whakatau",
  New_Job:"Mahi Hou",
  Details:"Taipitopito",
  Customize:"Whakaritea",
  Customize_Dashboard:"Whakaritea te Papatohu",
  Components:"Wahanga",
  No_Components:"Kaore he Waehanga",
  Main_Components:"Wahanga Matua",
  Smaller_Components:"Wahanga Iti",
  Error_Loading_Page:"Hapa te uta i tenei wharangi.",
  Bank_Details:"Nga Taipitopito Peeke",
  About_Your_Job:"Mo to mahi",
  Your_Schedule:"To Apiti",
  Job_Title:"Ingoa Mahi",
  Job_Description:"Whakaahua Mahi",
  Job_Details:"Nga Taipitopito Mahi",
  Enable_Job:"Whakahohehia a Hopa",
  Pay_Period:"Waea Utu",
  Perpetually_Schedule:"Whakaritea tonu",
  Select_Jobs:"Tīpakohia nga Mahi",
  No_Jobs:"Kaore he mahi",
  Add_Jobs:"Taapiri Mahi",
  No_Jobs_Word:"Taapirihia te <a routerLink='/folder/Job/New'>Hopa</a> tuatahi ki te rarangi.",
  Count_Employees:"job.employee_count+' Kaimahi'",
  Zero_Employees:"0 nga Kaimahi",
  New_Leave:"Whakarerea Hou",
  Leave_Name:"Waiho Ingoa",
  Paid_Leave:"Whakarerea Utu",
  Leave_Pay:"Waiho Utu",
  Indefinite_Leave:"Mawehenga Moe",
  Indefinite_Payroll:"Te utu utu kore mutunga",
  Leave:"Waiho",
  Add_Schedules:"Taapirihia nga Apiti",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Whakahohehia te ngaro",
  Select_Leaves:"Tīpako Rau",
  No_Leaves:"Kaore he rau ngaro",
  Leave_Of_Absence:"Waihoe",
  Leaves_Of_Absence:"Nga rau ngaro",
  New_Leave_of_Absense:"Whakarerea Hou Hou",
  No_Leaves_Word:"Taapirihia te <a routerLink='/folder/Leave/New'>Waihotanga</a> tuatahi.",
  Not_Enabled:"Kare i Whakahohe",
  Absences:"Nga ngaro",
  Payee:"Ka utua",
  New_Payee:"Ka utua hou",
  Payee_Identifier:"Tautuutu Kaiutu",
  Payee_Name:"Ingoa Kaiutu",
  Payee_Title:"Taitara Kaiutu",
  Payment_Memo:"Memo Utu",
  Select_Payees:"Tīpakohia nga Kaiutu",
  No_Payees:"Kaore he utu",
  Add_Payee_Note:"Tāpirihia te <a routerLink='/folder/Payee/New'>Ka utua</a> tuatahi.",
  New_Payees:"Nga Kaiutu Hou",
  About_The_Payment_Schedule:"Apiti Utu",
  Your_Payroll_Schedule:"Te Utu Aunoa",
  New_Payment:"Utu Hou",
  Identifier:"Tautuutu",
  Select:"Tīpako",
  Memo:"Memo",
  Payment_Date:"Rā Utu",
  Mark_as_Paid:"Tohu kua utua",
  Select_Payments:"Tīpakohia Utu",
  No_Payments:"Kaore he utu",
  No_Payments_Word:"Waihangatia te <a routerLink='/folder/Payment/New'>Utu</a> tuatahi.",
  Create_Payroll:"Waihangahia te utu utu",
  Properties:"Ahuatanga",
  Payroll_Title:"Taitara Utu",
  Payroll_Notes:"Tuhinga Utu",
  Payroll_Setup:"Tautuhi Utu",
  Tabulate_Payments:"Tuhinga Utu",
  Tabulate:"Tabulate",
  By:"Na:",
  Payments_By:"Nga Utu Na",
  Timesheets_And_Schedules:"Rarangi Wā me ngā Hōtaka",
  Both:"E rua",
  Items:"Tuemi",
  Add_Payees:"Taapirihia nga Kaiutu",
  Add_Account:"Tāpiri Pūkete",
  Enable_Account:"Whakahohe Pūkete",
  Enable_Payroll:"Whakahohehia te utu utu",
  Print_Payroll:"Taa Utu",
  No_Payrolls:"Kaore he utu",
  No_Payroll_Word:"Waihangahia to <a routerLink='/folder/Payroll/New'>Te utu utu</a> tuatahi.",
  View_more:"VIEW_MORE",
  Less:"ITIITI",
  Add_Payroll:"Taapirihia te utu utu",
  Select_Payroll:"Tīpakohia te utu utu",
  About_Your_Salary:"Mo to utu",
  Add_Salaries:"Taapiri Utu",
  Add_Salary:"Taapiri Utu",
  Salaries:"Nga utu",
  No_Salaries:"Kaore he utu",
  No_Salaries_Word:"Taapirihia te <a routerLink='/folder/Salary/New'>Utu</a> tuatahi.",
  Select_Salaries:"Tīpakohia nga utu",
  New_Salary:"Utu Hou",
  Salary_Name:"Tautuutu Utu",
  Enable_Salary:"Whakahohe Utu",
  Salary_Amount:"Te moni utu",
  New_Schedule:"Apiti Hou",
  Schedule_Title:"Taitara Apiti",
  Add_Address:"Tāpiri Wāhitau",
  Repeat:"Tuarua",
  Design:"Hoahoa",
  Edit_Design:"Whakatika Hoahoa",
  Edit_this_Design:"Whakatikahia tenei Hoahoa",
  Repeat_Payment:"Tuarua Utu",
  Enable_Schedule:"Whakahohe Apiti",
  Never:"Kare rawa",
  Select_Schedule:"Tīpako Hōtaka",
  No_Schedules:"Kaore he Apiti",
  No_Schedules_Word:"Waihangahia te <a routerLink='/folder/Schedule/New'>Hōtaka</a> tuatahi.",
  New_Administrator:"Kaiwhakahaere Hou",
  Username:"Ingoa Kaiwhakamahi",
  First_Name:"Ingoa Tuatahi",
  Last_Name:"Ingoa Whakamutunga",
  Add_an_Address:"Tāpirihia he Wāhitau",
  Payment_Limit:"Tepe mo ia Utu",
  Total_Limit:"Tepe Tapeke",
  Select_Accounts:"Tīpakohia Ngā Pūkete",
  No_Administrators:"Kaore he Kaiwhakahaere",
  No_Administrators_Word:"Waihangatia te tuatahi <a routerLink='/folder/Administrator/New'>Pūkete Kaiwhakahaere</a>.",
  New_Administrators_Word:"Tāpirihia te <a routerLink='/folder/Administrator/New'>Kaiwhakahaere</a> tuatahi",
  Cloud:"Kapua",
  Backup:"Pūrua",
  Enable_iCloud:"Whakahohe iCloud",
  Enable_Google_Drive:"Whakahohehia a Google Drive",
  Enable_Microsoft_OneDrive:"Whakahohe Microsoft OneDrive",
  Automatically_Backup:"Pūrua Aunoa",
  FTP_Settings:"Tautuhinga FTP",
  URL_File_Prompt:"Tena koa tohua te waahi mo te konae .xls / .xlsx / .json hei kawemai:",
  URL_SQL_Prompt:"Tena koa tohua te waahi o te konae SQLite hei kawemai:",
  FTP_Backup:"Pūrua FTP",
  FTP_Import:"Kawemai FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Kaihautū",
  Port:"Tauranga",
  Path:"Ara",
  Data_Path:"Ara Raraunga",
  Enable_FTP_Account:"Whakahohe Pūkete FTP",
  HostnameIP:"Ingoa Kaihautū",
  Password:"Kupuhipa",
  Connection_Port:"Tauranga Hononga",
  Enable_MySQL_Database:"Whakahohehia te Raraunga Raraunga MySQL",
  Log:"Rakau",
  Reset:"Tautuhi",
  Erase:"Muku",
  Export:"Kaweake",
  Database:"Patengi Raraunga",
  Upload_CSV:"Tuake CSV",
  Download_CSV:"Tikiake CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL Pūrua",
  Internal_Notes:"Tuhipoka o roto",
  Root_Path:"Ara Root",
  Select_Backup:"Tīpakohia te Pūrua",
  Add_New_Backup:"Tāpirihia he Pūrua Hōu",
  First_Backup:"Tatūria te pūrua tuatahi...",
  Backups:"Pūrua",
  Add_Backup:"Tāpiri Pūrua",
  No_Backups:"Karekau he taapiri kia kitea.",
  Select_Backup_Type:"Tīpakohia te momo pūrua e hiahia ana koe ki te tatū...",
  Backup_Type:"Momo Pūrua",
  FTP_Drive:"Puku FTP",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Puku",
  Microsoft_OneDrive:"Puku",
  Import_Fields:"Apure Kawemai",
  Import_Fields_Word:"Whakamahia tenei wahanga ki te whiriwhiri i <a routerLink='/folder/Holiday/New'>Kawemai</a> raraunga.",
  Upload:"Tuake",
  Download:"Tikiake",
  Download_JSON:"Tikiake hei Raraunga JSON",
  Download_SQL:"Tikiake hei Kōnae SQL",
  New_Bank:"Peeke Hou",
  New_Account:"Kaute Hou",
  New_Bank_Account:"Kaute Peeke Hou",
  Upload_Image:"Tuake Atahanga",
  Bank_Name:"Ingoa Peke",
  Bank_Address:"Wāhitau Peke",
  Branch_Address:"Wāhitau Peka",
  Address_on_Cheque:"Wāhitau",
  Cheque_Numbers:"Tirohia nga Nama",
  Cheque_Details:"Tirohia nga Taipitopito",
  Bank_Logo:"Moko Peeke",
  Cheque_ID:"Tirohia ID",
  Starting_Cheque_ID:"Timata Takitaki ID",
  Transit_Number:"Tau Whakawhitiwhiti",
  Institution_Number:"Tau Whakahaere",
  Designation_Number:"Tau Tohunga",
  Account_Number:"Tau Pūkete",
  Limits:"Tepe",
  Default_Limits:"Tepe Taunoa",
  Over_Limit:"Kei te Tepe",
  Under_Limit:"Kei raro i te rohe",
  Payroll_Limit:"Tepe utu",
  Enable_Bank_Account:"Whakahohehia te putea putea",
  Select_Account:"Tīpakohia te Pūkete",
  No_Bank_Account:"Karekau he putea putea",
  No_Bank_Account_Word:"Tāpirihia te <a routerLink='/folder/Accounts/New'>Pūkete Peeke</a> tuatahi.",
  Bank_Accounts:"Putea Putea",
  No_Accounts:"Karekau he kaute",
  No_Accounts_Note:"Tāpirihia te <a routerLink='/folder/Accounts/New'>Pūkete Peeke</a> tuatahi.",
  Cheque_Designer:"Tirohia te Kaihoahoa",
  Cheque_Design:"Tirohia Hoahoa",
  Select_Design:"Tīpakohia he Hoahoa",
  Cheque_Designs:"Tirohia nga Hoahoa",
  No_Cheque_Designs:"Kaore he Hoahoa Takitaki",
  No_Cheque_Designs_Word:"Waihangahia to ake <a routerLink='/folder/Settings/Cheque/Design/New'>Tirohia Hoahoa</a>.",
  Set_Default:"Tautuhi hei Taunoa",
  Default:"Taunoa",
  Remove:"Tangohia",
  Folder:"Kōpaki",
  Edit:"Whakatika",
  LoadingDots:"Ka utaina...",
  Add_a_New_File:"Tāpirihia he <a href='#' (pāwhiri)='add()'>Kōnae Hou</a> ki",
  this_folder:"tenei kōpaki",
  FTP_Backup_Settings:"Ngā Tautuhinga Pūrua FTP",
  Secure_File_Transfer:"Whakawhiti Kōnae Haumaru",
  New_Holiday:"Haereere Hou",
  Add_Holiday:"Taapiri Hararei",
  Holiday_Name:"Ingoa Hararei",
  Additional_Pay:"Utu taapiri",
  Enable_Holiday:"Whakahohehia te Hararei",
  Select_Holidays:"Tīpakohia nga Hararei",
  No_Holidays:"Kaore he Hararei",
  No_Holidays_Word:"Taapirihia te <a routerLink='/folder/Holiday/New'>Hararei Tūmatanui</a>.",
  New_Import:"Kawemai Hou",
  Import_Data:"Kawemai Raraunga",
  Import_Data_Word:"Tīpakohia te momo kōnae, te tikanga tukuake ranei e hiahia ana koe.<br /> Ka taea e koe te kowhiri i nga mara kawemai i roto i te konae e rite ana ki nga tawhā o te taupānga i muri i te tukunga i tetahi konae tautoko.", 
  Import_File:"Kōnae Kawemai",
  Link_To_File:"Hono ki te Kōnae",
  Select_File:"Tīpakohia te Kōnae",
  New_Moderator:"Kaiwhakahaere Hou",
  Allow_Moderation:"Whakaaetia te Whakaōrite",
  Create_Paycheques:"Waihangahia nga utu utu",
  Edit_Paycheques_and_Data:"Whakatika Utu me Raraunga",
  Destroy_Data_and_Paycheques:"Whakangaromia nga Raraunga me nga Tiaki Utu",
  Bonus_Percentage:"Oheneti Utu",
  Fixed_Amount:"Motu Pumau",
  Select_Moderator:"Tīpakohia te Kaiwhakarite",
  No_Moderators:"Kaore he Kaiwhakahaere",
  Moderators:"Kaiwhakahaere",
  Moderator_Account:"Waihangatia te tuatahi <a routerLink='/folder/Administrator/New'>Pūkete Kaiwhakahaere</a>.",
  No_Moderators_Word:"Tāpirihia te <a routerLink='/folder/Administrator/New'>Kaiwhakahaere</a> tuatahi.",
  Defaults:"Taunoa",
  Provide_Us_Info:"Homai ki a matou nga korero",
  Setup_Your_Printer:"Tatūhia To Pūreretā",
  Your_Company:"Mo To Kamupene",
  Company_Name:"Ingoa Kamupene",
  Currency:"Moni Moni",
  Default_Currency:"Moni Moni Taunoa",
  Base_Monthly_Income:"Marama Marama",
  Protection:"Tiaki",
  App_Protection:"Paruru Taupānga",
  PIN_Code_Protection:"Tiaki Waehere PIN",
  App_Protection_Word:"Whakahohehia nga tikanga haumarutanga hei tiaki i to putea.",
  PIN_Code:"Waehere PIN",
  Change_PIN:"Huri PIN",
  New_Password:" Kupuhipa Hou",
  Geofence_Protection:"Tiaki Taiapa Geo",
  Geofence_Area:"Tautuhi Rohe",
  Distance:"Tawhiti",
  Setup_Now:"Whakaritea Inaianei",
  Mile:"Maero",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Whakaahua kanohi",
  Face:"Mata",
  Setup:"Tautuhi",
  Label:"Tapanga",
  Password_Protection:"Tiaki Kupuhipa",
  Modify_Password:"Whakarerekē Kupuhipa",
  New_Tax_Entry:"Whakauru Taake Hou",
  New_Tax:"Taake Hou",
  Tax_Label:"Tapanga Taake",
  Perpetually_Enabled:"Whakahohe tonu",
  Select_Taxes:"Tīpakohia nga Taake",
  Tax_Deductions:"Whakahekenga Taake",
  No_Tax_Deductions:"Kaore he tangohanga taake",
  No_Tax_Deductions_Word:"Taapirihia te tangohanga tuatahi <a routerLink='/folder/Tax/New'>Take</a>.",
  New_Timer:"Tamaoma Hou",
  Start:"Tīmata",
  Stop:"Kati",
  Start_Timer:"Tama Timata",
  Stop_Timer:"Kati Matama",
  Timer_Active:"Tama Hohe",
  Timer:"Tama:",
  Timer_Running:"Tama: (Rere)",
  Save_Timer:"Tiaki Matama",
  New_Timesheet:"Pepa Wā Hou",
  Select_Timesheets:"Tīpakohia nga Rau Wa",
  Work_Notes:"Tuhipoka Mahi",
  Entry_Title:"Taitara Whakauru",
  No_Timesheets:"Karekau he Pepa Wā",
  No_Timesheets_Word:"Tāpirihia te <a routerLink='/folder/Timesheet/New'>Wā</a> tuatahi.",
  Timesheet_Submitted:"Waima kua tukuna",
  Timesheet_Congrats:"Tena koe! Kua pai te tuku o to Waa Pepa. Nga mihi!",
  Timesheet_Copy:"Ki te whiwhi kape o o tuhinga me tuku mai to wahitau imeera me to nama waea pūkoro/ranei.",
  Submit:"Tukuna",
  Allow_Notifications:"Whakaaetia nga Whakamōhiotanga",
  Untitled_Entry:"Whakauru Hou",
  Untitled_Bank:"Peeke kore taitara",
  Timesheet_Entry:"Taurunga Rau Wa",
  Work_Description:"Whakaahua Mahi",
  Enable_Timesheet:"Whakahohe Pepa Wā",
  Submit_Timesheet:"Tukuhia te Pepa Wā",
  Vacation:"Haereere",
  New_Vacation:"Haereere Hou",
  Vacation_Name:"Ingoa Hararei",
  Paid_Vacation:"Haerenga Utu",
  Vacation_Pay:"Utu mo te hararei",
  Enable_Vacation:"Whakahohehia te hararei",
  Select_Vacations:"Tīpakohia nga hararei",
  No_Vacations:"Kaore he hararei",
  No_Vacations_Word:"Waihangahia te urunga <a routerLink='/folder/Vacation/New'>Hārei</a> tuatahi.",
  Payroll_Schedule:"Apiti utu",
  Next_Payroll:"Tuku Utu Panuku:",
  Upcoming_Payroll:"Tuku utu e haere ake nei",
  No_Upcoming_Payroll:"Kaore he utu e haere ake nei",
  Address_Book:"Puka Wāhitau",
  Archived_Documents:"Tuhinga Pūranga",
  Dev_Mode:"Te tono i roto i te Aratau Whakawhanake",
  Administrators:"Kaiwhakahaere",
  Privacy:"Tūmataiti",
  None:"Karekau",
  Select_Signature:"Tīpako Waitohu",
  No_Signatures:"Kaore he Waitohu",
  No_Signatures_Word:"Tāpirihia te <a routerLink='/folder/Signature/New'>Waitohu</a> tuatahi.",
  Repeat_Indefinitely:"Tuarua mo te kore mutunga",
  Sign:"Waitohu",
  Sign_Here:"Waitohu ki konei",
  Date_Signed:"Rā i hainatia",
  Signature_Pad:"Papa Waitohu",
  Account_Holder:"Kai mau Pūkete",
  Account_Properties:"Ahuatanga Pūkete",
  Name_On_Cheque:"Ingoa kei runga Tiaki",
  Server_Hostname:"Ingoa Kaihautū / IP",
  Printers:"Nga Kairereta",
  No_Printers:"Karekau he Kaituhi",
  Printer_Exists:'Kei te tīariari kē he pūreretā ko tēnei ingoa.',
  No_Printers_Word:"Tāpirihia te <a routerLink='/folder/Printer/New'>Pūreretā</a> tuatahi.",
  No_Printer_Alert:"Karekau he pūreretā i tautuhia. Kei te pirangi koe ki te tatū he pūreretā?",
  Add_Printer:"Tāpiri Pūreretā",
  New_Printer:"Kairereta Hou",
  Printer_Hostname:"Ingoa Kaihautū / IP",
  Printer_Label:"Tapanga Pūreretā",
  Printer_Protocol:"Kawa Pūreretā",
  Protocol:"Kawa",
  Email_Print:"Imeera",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Tupapa",
  Print_Job:"Tuhi Mahi",
  Printed:"I taia",
  Not_Printed:"Kare i taia",
  Print_Jobs:"Nga Mahi Ta",
  Print_Queue:"Tīria Tā",
  No_Print_Jobs:"Karekau he mahi taa",
  No_Prints:"Hangaia he <a routerLink='/folder/Cheque/New'>Titaki</a> hou hei tā.",
  Prints:"Nga Ta",
  Find_Printer:"Kimihia te Pūreretā",
  Find_AirPrint_Devices:"Kimihia nga taputapu AirPrint",
  Select_Printer:"Tīpakohia te Pūreretā",
  System_UI:"UI Pūnaha",
  Printer:"Kaireretā",
  Status:"Tunga",
  Preview:"Arokite",
  Enable_Print_Job:"Whakahohe Mahi Ta",
  Queue_Weight:"Taimaha Tūtira",
  Unlimited:"Kaore he mutunga",
  Show_Advanced_Printer_Options:"Whakaatu Kōwhiringa Pūreretā Arā Atu Anō",
  Advanced:"Arā Atu Anō",
  Location:"Wāhi",
  Note:"Tuhipoka",
  Queue_Name:"Ingoa Tūtira",
  Pages_Printed_Limit:"Wharangi tepe kua taia",
  MultiPage_Idle_Time:"Wā Tatari Whārangi Maha (ng)",
  Page_Count_Limit:"Tepe Tatau Whārangi",
  Page_Orientation:"Whakaahua Whārangi",
  Portrait:"Whakaahua",
  Landscape:"Whakaahua",
  Duplex:"Taruarua",
  Double_Sided_Printing:"Taarua Taarua",
  Duplex_Mode:"Aratau Matarua",
  Duplex_Short:"Poroa",
  Duplex_Long:"Roa",
  Duplex_None:"Karekau",
  Color_And_Quality:"Tae me te Kounga",
  Monochrome:"Monochrome",
  Photo_Quality_Prints:"Whakaahua Kounga Kounga",
  Printer_Email:"Imeera Pūreretā",
  Automatically_Downsize:"Whakaheke Aunoa",
  Paper:"Pepa",
  Paper_Name:"Ingoa Pepa",
  Paper_Width:"Whanui Pepa",
  Paper_Height:"Teitei Pepa",
  Paper_Autocut_Length:"Pepa Tapa-Aunoa Roa",
  Margins:"Tawhē",
  Page_Margins:"Tawhē Whārangi",
  Page_Margin_Top:"Tawhē Whārangi Runga",
  Page_Margin_Right:"Tawhē Whārangi Matau",
  Page_Margin_Bottom:"Tawhē Whārangi Raro",
  Page_Margin_Left:"Tawhē Whārangi Mauī",
  Add_Employees:"Taapirihia nga Kaimahi",
  Header:"Pūpoko",
  Print_A_Page_Header:"Tangia he Pane Wharangi",
  Header_Label:"Tapanga Pane",
  Header_Page_Index:"Taupū Whārangi Pane",
  Header_Font_Name:"Momotuhi Pane",
  Select_Font:"Tīpakohia Momotuhi",
  Font_Selector:"Kaiwhiri Momotuhi",
  Header_Font_Size:"Momotuhi Pane",
  Header_Bold:"Maaa Pane",
  Header_Italic:"Itari Pane",
  Header_Alignment:"Whakatikanga Pane",
  Left:"Maui",
  Center:"Pokapū",
  Right:"Tika",
  Justified:"Whakatika",
  Header_Font_Color:"Tae Pane",
  Select_Color:"Tīpakohia te Tae",
  Footer:"Hikui",
  Print_A_Page_Footer:"Taa He Hiku Whārangi",
  Footer_Label:"Tapanga Hiku",
  Footer_Page_Index:"Taupū Whārangi Hiku",
  Footer_Font_Name:"Momotuhi Hiku",
  Fonts:"Momotuhi",
  Done:"Kua oti",
  Select_Fonts:"Tīpakohia Momotuhi",
  Footer_Font_Size:"Rahi Hiku",
  Footer_Bold:"Maaa Hiku",
  Footer_Italic:"Hiku Itari",
  Footer_Alignment:"Tirohanga Hiku",
  Footer_Font_Color:"Tae Hiku",
  Page_Copies:"Nga kape wharangi",
  Enable_Printer:"Whakahohe Pūreretā",
  Remote_Logging:"Te Takitaki Mamao",
  Log_Server:"Tumau Takiuru",
  Encryption:"Whakamuna",
  Random_Key:"Kī tupurangi",
  Encryption_Key:"Kī Whakamuna",
  Cheques_Webserver:"Patengi Raraunga Ritenga",
  Learn_How:"Ako Me pehea",
  Signature:"Waitohu",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Tirohia te Waitohu",
  Printed_Signature:"Waitohu kua taia",
  Digitally_Sign:"Waitohu Mamati",
  Digital_Signature:"Waitohu Mamati",
  Add_Signature:"Taapiri Waitohu",
  Add_Your_Signature:"Taapirihia to Waitohu",
  Enable_Signature:"Whakahohe Waitohu",
  Digitally_Signed:"Waitohu Mamati",
  Insert_Error:"Kare e taea te tiaki i te haki na runga i nga take papaunga raraunga.",
  Delete_Confirmation:"Kei te tino hiahia koe ki te muku i enei korero?",
  Discard_Confirmation:"Kei te tino hiahia koe ki te whakakore i enei korero?",
  Discard_Bank_Confirmation:"E tino hiahia ana koe ki te whakakore i tenei kaute?",
  Discard_Printer_Confirmation:"Kei te tino hiahia koe ki te whakakore i tenei pūreretā?",
  Delete_Bonus_Confirmation:"Kei te tino hiahia koe ki te muku i tenei bonus?",
  Delete_Invoice_Confirmation:"Kei te tino hiahia koe ki te muku i tenei nama?",
  Generated_Cheque:"Tirohanga Hanga",
  Generated_Invoice:"Waihanga Nama",
  Schedule_Start_Time:"Whakamahere Timata",
  Schedule_End_Time:"Whakamutunga Mahere",
  New_Call:"Karanga Hou",
  No_Contacts:"Kaore he Hoapā",
  No_Contacts_Word:"Ko nga kaiwhakahaere, kaitakawaenga, nga kaimahi, me te hunga utu ka puta hei hoapaki.",
  PDF_Subject:"pūtea",
  PDF_Keywords:"taka utu utu utu PDF haki",
  Printer_Setup:"Tatūnga Pūreretā",
  Printer_Selection:"Kōwhiringa Pūreretā",
  New_Fax:"Waeatuhi Hou",
  New_Fax_Message:"Karere Waeatuhi Hou",
  Fax_Machine:"Miihini Waeatuhi",
  Fax_Name:"Ingoa Waeatuhi",
  Fax_Email:"Waeatuhi Īmēra",
  Fax_Number:"Tau Waeatuhi",
  Contents:"Ihirangi",
  Fax_Body:"Tana Whārangi",
  Header_Word:"Pūpoko:",
  Header_Text:" Kuputuhi Pane",
  Include_Header:"Whakauruhia te Pane",
  Include_Footer:"Whakauruhia te Hiku",
  Footer_Word:"Hiku:",
  Footer_Text:"Kuhinga Hiku",
  Attach_a_Cheque:"Apitihia he haki",
  Add_Deduction:"Taapiri Tangohanga",
  Enable_Fax:"Tukua Waeatuhi",
  Select_Fax:"Tīpako Waeatuhi",
  No_Faxes:"Kaore he Waeatuhi",
  Faxes:"Waeatuhi",
  Save_and_Send:"Tukua Waeatuhi",
  Save_and_Pay:"Tiaki me te utu",
  WARNING:"TUPATO:",
  Remember:"Mahara",
  Printing:"Tiki",
  Printing_Complete:"Kua Oti te Ta!\n\n",
  of:"o",
  There_Were:"I reira",
  Successful_Prints:" nga tuhinga angitu me ",
  Unsuccessful_Prints:"Ko nga tuhinga kaore i angitu.",
  PrinterError:"Aroha mai! He hapa.",
  Printing_:"E ta...",
  PrinterSuccess:"I momoho te tuhinga.",
  PrintersSuccess:"I pai te taia o nga tuhinga.",
  Print_Cheques:"Nga Tirohanga Ta",
  New_Message:"Karere Hou",
  New_Messages:"Karere Hou",
  Read_Message:"Panui Karere",
  Write_Message:"Tuhia Karere",
  Send_Message:"Tukua Karere",
  Subject:"Kaupapa",
  Message:"Karere",
  Writing:"Tuhituhi...",
  Send:"Tukua",
  Set_Date:"Tautuhi Ra",
  Set_Time:"Tautuhi Wa",
  Recieve:"Whiwhi",
  Set_as_Default:"Tautuhi hei Taunoa",
  Default_Account:"Kaute Taunoa",
  Default_Design:"Hoahoa Taunoa",
  Multiple_Cheques:"Tirohanga (Tripple)",
  Account_Mode:"Aratau Pūkete",
  Multiple_Cheques_Description:"E toru nga haki mo ia wharangi.",
  Check_and_Table:"Tirohia & Ripanga",
  Check_including_Table:"Tirohia & tepu kaute.",
  Check_Mailer:"Tirohia te Kaimēra",
  Check_Mailer_Window:"Tirohia me te matapihi wahitau.",
  DocuGard_Table_Top:"Tirohanga DocuGard & Ripanga (Runga)",
  DocuGard_Table_Middle:"Tirohanga DocuGard & Ripanga (Waenganui)",
  DocuGard_Table_Bottom:"Tirohanga DocuGard & Ripanga (Raro)",
  DocuGard_Mailer_Top:"DocuGard Check Mail (Runga)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (Waenganui)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mail (Raro)",
  DocuGard_Three_Cheques:"Nga Tirohanga DocuGard (Tripple)",
  DocuGard_Cheque_Top:"Tirohanga DocuGard (Runga)",
  DocuGard_Cheque_Middle:"Tirohanga DocuGard (Waenganui)",
  DocuGard_Cheque_Bottom:"Tirohia DocuGard (Raro)",
  DocuGard_Three_Cheques_Window:"E toru nga haki ki te wharangi kotahi.",
  DocuGard_Table_Top_Window:"Tirohia me te ripanga moni.",
  DocuGard_Table_Middle_Window:"Tirohia me te ripanga moni.",
  DocuGard_Table_Bottom_Window:"Tirohia me te ripanga moni.",
  DocuGard_Mailer_Top_Window:"Tirohia, tepu, me te wahitau.",
  DocuGard_Mailer_Middle_Window:"Tirohia, tepu, me te wahitau.",
  DocuGard_Mailer_Bottom_Window:"Tirohia, tepu, me te wahitau.",
  DocuGard_Cheque_Top_Window:"Tirohia mo te pepa haumaru.",
  DocuGard_Cheque_Middle_Window:"Tirohia mo te pepa haumaru.",
  DocuGard_Cheque_Bottom_Window:"Tirohia mo te pepa haumaru.",
  Basic_Cheque:"Tirohia (runga)",
  Basic_Cheque_Print:"Tangia he haki kotahi.",
  Error_Setting_As_Paid:"Hapa Tautuhinga hei Utu",
  Add_Attachment:"Taapirihia te taapiri",
  PrinterUnavailable:"Kare i Watea te Kairereta",
  CreditCardComponent:"Kaari",
  PaypalComponent:"PayPal",
  InteracComponent:"Whakawhitiwhiti",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Putea Hou",
  Deposits:"Pupuu",
  No_Deposits:"Kaore he putea",
  Credit_Card_Deposit:"Kaari nama",
  New_Credit_Card_Deposit_Message:"Tukunga Kaari Nama",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Putunga Bitcoin",
  New_Interac_Deposit:"Whakawhitiwhiti",
  New_Interac_Deposit_Message:"Whakawhiti Interac",
  Payments_Limit:"Tepe Utu",
  No_Payment_Limit:"Kaore he Tepe Utu",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Te putea PayPal",
  No_Deposits_Word:"Tāpirihia te kamupene tuatahi <a routerLink='/folder/Deposit/New'>Putunga</a>.",
  No_Documents_Specified:"Karekau he tuhinga i tohua mo te taa",
  No_Printers_Added:"Karekau he kaituhi i kitea. Haere ki Tautuhinga > Pūreretā hei tāpiri i tētahi.",
  DB_Erase_Prompt:"Whakamutua te ūkui i te pātengi raraunga katoa? WHAKATŪPATO: Ka ngaro katoa ngā pārongo penapena!",
  Console_Warning:"Kaua e whakapiri tetahi tuhinga ki roto i tenei papatohu. Ka raru pea koe me to kamupene.",
  No_Faxes_Word:"Waihangatia te <a routerLink='/folder/Waeatuhi/New'>Waeatuhi</a> tuatahi.",
  Cheque_Delete_Confirmation:"Kei te tino hiahia koe ki te muku i tenei haki?",
  Design_Delete_Confirmation:"Kei te tino hiahia koe ki te muku i tenei hoahoa?",
  Cheque_Pay_Confirmation:"Maria tenei haki kua utua? KAUA e puta ki te rarangi ta.",
  Payment_Pay_Confirmation:"Maria tenei utu kua utua? KAUA e puta ki te rarangi haki.",
  Delete_Deduction_Confirmation:"Kei te tino hiahia koe ki te muku i tenei tangohanga?",
  Delete_Job_Confirmation:"Kei te tino hiahia koe ki te muku i tenei mahi?",
  Delete_Timesheet_Confirmation:"Kei te tino hiahia koe ki te muku i tenei pepa waahi?",
  Delete_Schedule_Confirmation:"Kei te tino hiahia koe ki te muku i tenei raarangi?",
  Delete_Setting_Confirmation:"Kei te tino hiahia koe ki te tautuhi ano i tenei tautuhinga?",
  Delete_Fax_Confirmation:"Kei te tino hiahia koe ki te muku i tenei waeatuhi?",
  Delete_File_Confirmation:"Kei te tino hiahia koe ki te muku i tenei konae?",
  Delete_Vacation_Confirmation:"Kei te tino hiahia koe ki te muku i tenei hararei?",
  Delete_Printer_Confirmation:"Kei te tino hiahia koe ki te muku i tenei kaituhi?",
  Remove_Design_Confirmation:"Kei te tino hiahia koe ki te muku i tenei hoahoa?",
  Delete_Payroll_Confirmation:"Kei te tino hiahia koe ki te muku i tenei kaute utu?",
  Send_Fax_Email_Confirmation:"Kei te pirangi koe ki te waeatuhi me te imeera i enei tuhinga?",
  Send_Email_Confirmation:"Kei te pirangi koe ki te imeera i tenei tuhinga?",
  Send_Fax_Confirmation:"Kei te pirangi koe ki te waeatuhi i tenei tuhinga?",
  Error_Generating_PDF:"Aroha mai. I hapa te hanga i tenei tuhinga.",
  PDF_Error_Saving_Image:"Aroha mai. I hapa te tiaki whakaahua PDF o tenei tuhinga.",
  Test_Printer_Confirmation:"Kei te pirangi koe ki te ta i tetahi wharangi whakamatautau ki runga i tenei taputapu?",
  Save_Timesheet_Prompt:"Tēnā tāpirihia he 'Taitara' ka pēhi rānei 'Tīmata Matawā' ki te tiaki.",
  Remove_Geofence_Prompt:"Kei te tino hiahia koe ki te tango i te waahi o tenei taiapa geo?",
  Delete_Employee_Confirmation:"Kei te tino hiahia koe ki te muku ",
  Fire_Employee_Confirmation:"Kei te tino hiahia koe ki te ahi"
}