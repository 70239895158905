export const En = {
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  Author_Name: "Liam Hogan",
  Copyright: "Copyright &copy; 2023",
  Bonus: "Bonus",
  New_Bonus: "New Bonus",
  No_Bonus: "No Bonus",
  No_Bonus_Word:"Add the first <a routerLink='/folder/Bonus/New'>Bonus</a>.",
  New_Entry_Word: "Create the first <a routerLink='/folder/ChequeEntry/New'>Cheque Entry</a>.",
  Bonuses: "Bonuses",
  Select_Bonus: "Select Bonus",
  Cheque_Entries: "Cheque Entries",
  Archive: "Archive",
  black:"Black",
  green:"Green",
  gold:"Gold",
  blue:"Blue",
  brown:"Brown",
  purple:"Purple",
  pink:"Pink",
  red:"Red",
  Swatches: "Swatches",
  HSL: "HSL",
  RGB: "RGB",
  Hue: "Hue",
  Saturation: "Saturation",
  Lightness: "Lightness",
  Stars: "Stars",
  Bullets: "Bullets",
  Line: "Line",
  Lines: "Lines",
  Ampersand: "Ampersand",
  Ampersands: "Ampersands",
  Pipe: "Pipe",
  Pipes: "Pipes",
  Semicolon: "Semicolon",
  Semicolons: "Semicolons",
  Colons: "Colons",
  Colon: "Colon",
  no_more: "'No More'",
  Upgrade_To_Pro:"Upgrade to Pro",
  AllFeaturesInclude:"All Features Include:",
  PrintUnlimitedCheques:"Print Unlimited Cheques",
  PremiumChequeDesigns:"Premium Cheque Designs",
  ManageUnlimitedEmployees:"Manage Unlimited Employees",
  AddUnlimitedPayees:"Add Unlimited Payees",
  CreateUnlimitedPayrolls:"Create Unlimited Payrolls",
  UnlimitedSchedulesandTimeSheets:"Unlimited Schedules and Time Sheets",
  BulkPayPalPayouts:"Bulk PayPal Payouts",
  UnlimitedBankAccounts:"Unlimited Bank Accounts",
  ManageMultipleCompanies:"Manage Multiple Companies",
  TrackInsurancePolicies:"Track Insurance Policies",
  Bio_MetricProtection:"Bio-Metric Protection",
  Geo_FenceLock_OutProtection:"Geo-Fence Lock-Out Protection",
  Multiple_Companies_Word:"Managing multiple companies is not available without Cheques premium.",
  PayPal_Payouts_Word:"PayPal payments are disabled without Cheques premium.",
  PINProtection:"PIN Protection",
  PasswordProtection:"Password Protection",
  May_Require_Approval:"May require approval.",
  Subscribe: "Subscribe",
  Billed: "Billed",
  Up: "Up", 
  Down: "Down",
  Positioning: "Positioning",
  Marker: "Marker",
  Drag_Marker: "Drag Marker",
  Tagline: "Print Cheques and Tabulate Payroll",
  Marker_Word: "Use the Markers to size the element.",
  Pinch_Zoom: "Pinch Zoom",
  Pinch_Word: "Pinch to zoom the element.",
  Drag: "Drag",
  Drag_Word: "Use your finger to drag elements.",
  subscription_alias_word:"Auto-Renewing Subscription",
  premium_alias_word:"One-Time Upgrade Package",
  print_alias_word:"Print-Out Individual Cheques",
  mode_alias_word:"Mode",
  Pro: "Pro",
  Pro_word: "Pro version required.",
  Cant_Delete_Default_Company_Word:"Sorry, you can not delete your default company.",
  Reinsert_Default_Designs:"Re-Insert Default Designs",
  Reinsert_Default_Designs_word:"Do you want to re-insert default designs?",
  Subscription_Active_Disable_Word: "This subscription is active. Would you like to cancel this subscription to Cheques?",
  Company_Logo: "Company Logo",
  ZERO_: "ZERO ",
  Disclaimer: "Disclaimer",
  Privacy_Policy: "Privacy Policy",
  EULA: "Cheques EULA",
  Terms_Of_Service: "Terms of Service",
  Terms_Of_Use: "Terms of Use",
  Refunds: "Refund Policy",
  Single_Print: "1 Cheque",
  Two_Prints: "2 Cheques",
  Five_Prints: "5 Cheques",
  Ten_Prints: "10 Cheques",
  Fifteen_Prints: "15 Cheques",
  Twenty_Prints: "20 Cheques",
  Thirty_Prints: "30 Cheques",
  Image_Added: "Image Added",
  Image_Preview: "Image Preview",
  No_Image_Was_Selected: "No image was selected.",
  Cheques_Unlimited: "Cheques Unlimited",
  _Subscription:"Subscription",
  Subscription: "Cheques - 1 Month",
  Two_Month_Subscription: "Cheques - 2 Months",
  Three_Month_Subscription: "Cheques - 3 Months",
  Six_Month_Subscription: "Cheques - 6 Months",
  Twelve_Month_Subscription: "Cheques - 12 Months",
  Cheques_Are_Available: "Cheques are available to print.",
  Cheques_Thank_You: "Thank you so much for your purchase of Cheques! Your purchases are now active.",
  Upgrade_Required_Two: "Select a package and double-tap on the price button to start your purchase. Print professional looking full-color cheques in seconds.",
  Month: "Month",
  Due: "Due: ",
  Expires: "Expires: ",
  Subscription_Active: "Subscription Active",
  Subscription_Inactive: "Subscription Inactive",
  Purchase_Additional_Cheques:"Purchase additional cheques?",
  Printable_Cheque: "Printable Cheque",
  Printable_Cheques: "Printable Cheques",
  Printable_Cheque_Word: "cheque is available on your account.",
  Printable_Cheques_Word: "cheques are available on your account.",
  Max_Amount_Message: "The amount you have specified has reached over the maximum amount set for this system:",
  Terms_Required_Word: "You must agree to this agreement before continuing to use Cheques.",
  Subscriptions: "Subscriptions",
  Product_Upgrades: "Upgrades",
  Mailed_Out_Cheques: "Mailed-Out Cheques",
  Enter_A_Company_Name: "Please enter a company name.",
  Single_Cheques: "Single Cheques",
  Cheque_Golden: "Golden Cheque",
  Cheque_Golden_Window:"Golden cheque design.",
  Cheque_Green: "Green Cheque",
  Cheque_Green_Window:"Green cheque design.",
  Cheque_Red: "Red Cheque",
  Cheque_Red_Window:"Red cheque design.",
  Cheque_Yellow: "Yellow Cheque",
  Cheque_Yellow_Window:"Yellow cheque design.",
  Cheque_Statue_of_Liberty: "Statue of Liberty",
  Cheque_Statue_of_Liberty_Window: "The Statue of Liberty cheque design.",
  Cheque_Green_Wave: "Green Wave",
  Cheque_Green_Wave_Window: "Green cheque design.",
  Cheque_Golden_Weave: "Golden Weave",
  Cheque_Golden_Weave_Window: "Elegant golden cheque design.",
  Cheque_Green_Sun: "Green Sun",
  Cheque_Green_Sun_Window: "Deep and calming cheque design.",
  Cheque_Blue_Checkers: "Blue Checkers",
  Cheque_Blue_Checkers_Window: "Blue cheque design.",
  Cashiers_Check: "Cashiers Cheque",
  Cashiers_Check_Window: "Cashiers Cheque style template.",
  Cheque_Aqua_Checkers: "Aqua Checkers",
  Cheque_Aqua_Checkers_Window: "Aqua cheque design.",
  Cheque_Golden_Checkers: "Golden Checkers",
  Cheque_Golden_Checkers_Window: "Golden cheque design.",
  Upgrade_Unavailable: "Upgrades Unavailable",
  Bank_Code_Protection: "Bank Number Protection",
  Bank_Code_Protection_Word: "Protect your bank numbers from being used on this app running on another device or for another user. This action is IRREVERSIBLE. Continue?",
  Bank_Code_Protection_Blocked_Word: "The bank numbers you are trying to use are reserved for another user or device.",
  Bank_Code_Protection_Error_Word: "Number verification has failed. Please connect to the internet and try adding this bank account again.",
  Bank_Code_Protection_Set_Error_Word: "Bank number protection requires that you be connected to the internet. Please connect and try again.",
  Upgrade_Unavailable_Word: "Sorry, we are having trouble verifying you. Subscriptions and upgrades to Cheques are currently unavailable for purchase in your area.",
  PayPal_Payment_Preview: "PayPal Payment Preview",
  Apple_Pay: "Apple Pay",
  Select_PayPal: "Select PayPal",
  PayPal_Applications: "PayPal Applications",
  Purchase_With_Apple_Pay: "Purchase with Apple Pay",
  Google_Pay: "Google Pay",
  Companies: "Companies",
  Insurance: "Insurance",
  New_PayPal: "New PayPal",
  Pay_By: "Pay By",
  Insure: "Insure",
  Miles: "Miles",
  Payment_Method: "Payment Method",
  Select_Policies: "Select Policies",
  Policies: "Policies",
  Policy: "Policy",
  No_PayPal_Account: "No PayPal Account",
  No_Policies: "No Insurance Policies",
  New_Policy: "New Policy",
  PayPal_Payment: "PayPal Payment",
  PayPal_Payments: "PayPal Payments",
  No_Payment_Selected: "No Payment Selected",
  Sending_Payment_Word: "Please wait... This payment is being sent.",
  Sending_Payments_Word: "Please wait... Payments are being sent.",
  No_Payment_Selected_PayPal: "No <a routerLink='/folder/Payments'>PayPal payment</a> selected to send.",
  Payment_Sent: "Payment Sent",
  PayPal_Payment_Sent: "This payment has been sent with PayPal.",
  Copay: "Copay",
  Dead: "Dead",
  Alive: "Alive",
  Not_Dead: "Not Dead",
  Unclaimed: "Unclaimed",
  Attempted: "Attempted",
  Deceased: "Deceased",
  Claimed: "Claimed",
  Unpaid: "Unpaid",
  Sending_Payment: "Sending Payment",
  Sending_Payments: "Sending Payments",
  Send_PayPal_Confirmation: "Do you want to send this transaction with PayPal?",
  Send_PayPal_Confirmation_Word: "Press the green button to send this transaction.",
  Save_Payment_As_Unpaid: "Save this payment as unpaid?",
  Payment_Pay_Confirmation_PayPal:"Save this payment as paid?",
  No_Policies_Word: "Add the first <a routerLink='/folder/Postage/New'>Insurance Policy</a> now.",
  Timesheet_Multiple_Delete_Confirmation:"Are you sure you want to delete multiple timesheets?",
  Select_Multiple_Timesheets_Prompt:"No timesheets selected. Select at least one timesheet.",
  Select_Multiple_Policies_Prompt:"No policies selected. Select at least one insurance policy.",
  Policies_Multiple_Delete_Confirmation:"Are you sure you want to delete multiple policies?",
  Company: "Company",
  Add_Company: "Add Company",
  New_Company: "Add Company",
  No_Companies: "No Companies",
  Enable_Company: "Enable Company",
  Select_Company: "Select Company",
  The_Default_Company: "The default company label.",
  Manage_Companies: "Manage Companies",
  No_Companies_Word: "Cheques will use a default company.<br />Add the <a routerLink='/folder/Company/New'>first Company</a>.",
  Default_Company: "Default Company",
  Cheques_Unlimited_Word: "Cheques Unlimited allows you to print as many cheques as you like.",
  Cheques_Subscription_Word: "A Cheques subscription allows you to print as many cheques as you like.",
  You_Own_This_Product: "You own this product.",
  Your_Subscription_is_Active: "Your subscription is active.",
  Active_Products: "Activated Products",
  Purchase_Confirmation: "Purchase",
  Purchase_Cheques: "Purchase Cheques",
  Restore_Purchase: "Restore Purchases",
  Erase_Purchase: "Erase Purchases",
  Successfully_Purchased: "Successfully Purchased",
  Enter_Your_Licence_Key: "Please enter your license key on this page to activate this product.",
  Licence_Key: "License Key",
  Enter_Licence_Key: "Enter License Key",
  Purchased: "Purchased",
  Enable_Feature: "Enable Feature",
  Cancel_Subscription: "Cancel Subscription",
  Subscription_Removed: "Subscription Removed",
  Select_Active_Subscription: "Select an active subscription in order to modify it.",
  Remove_Subscription_Word: "Are you sure you want to cancel this subscription?",
  Delete_Company_Confirmation:"Are you sure you want to delete this entire company? WARNING: You will lose all stored information!",
  Delete_Default_Company_Word:"You cannot delete the default company. Would you like to reset the application and restore it to the default state? WARNING: You will lose all stored information!",
  Console_Warning_2: "Do not handle any currency using this application which is not currently yours.",
  Terms_and_Conditions: "Terms and Conditions",
  and_the: "and the",
  for: "for",
  Please_Read_Word: "Please read and agree to the",
  Some_Conversion_Rates_Could_Not_Be_Retrieved: "Some currency conversion rates could not be found. Please connect to the internet.",
  Free: "Free",
  DB_Erase_Prompt_2: "Completely erase the entire developer database? WARNING: You will lose all PURCHASE and SUBSCRIPTION information!",
  Successfully_Imported: "Successfully Imported",
  Select_Postage:"Select Postage",
  No_Postage:"No Postage Stamps",
  No_Paid_Postage_Word:"Add the first <a routerLink='/folder/Postage/New'>Paid Postage</a> stamp.",
  Trial_Complete:'Trial Complete',
  Please_Upgrade:'Please upgrade Cheques to continue printing.',
  Aa: "Aa",
  Color: "Color",
  Font: "Font",
  Guide: "Guide",
  Guides: "Guides",
  Image: "Image",
  Zoom: "Zoom",
  Logo: "Logo",
  Bank: "Bank",
  MICR:"MICR",
  Total:"Total",
  Cancel:"Cancel",
  Confirm:"Confirm",
  Method:"Method",
  Biometric_Security:"Bio-metric Security",
  Please_Login_To_Continue:"Please login to continue.",
  Complete:"Complete",
  Sign_Up:"Sign Up",
  Error:"Error",
  Biometrics:"Bio-metrics",
  Percent: "Percent",
  Zero_Percent: "0%",
  Top_Margin: 'Top Margin',
  Bottom_Margin: 'Bottom Margin',
  Left_Margin: "Left Margin",
  Right_Margin: "Right Margin",
  MICR_Margin: "MICR Margin",
  Table_Margin: "Table Margin",
  Address_Margin: "Address Margin",
  Percentage_: "Percentage",
  Vacation_Title: "Vacation Title",
  Use_PIN: "Use PIN",
  Loading__:"Loading...",
  Design_Title:"Design Title",
  Authorize: "Authorize",
  Key: "Key",
  Public_Key: "Public Key",
  Private_Key: "Private Key",
  Authenticate: "Authenticate",
  Last_Payroll: "Last Payroll",
  Last_Calculation: "Last Calculation",
  Authorized: "Authorized",
  Geo_Authorized: "Geo-Location: Authorized",
  Not_Authorized: "Not Authorized",
  Authorization_Complete:"Authorization Complete",
  Geolocation_Authorization: "Geo-location Authorization",
  Out_of_Bounds: "Out of Bounds",
  Cant_Delete_Default_Design: "Can not delete a default design.",
  Unauthorized_Out_of_Bounds: "Unauthorized: Out of Bounds",
  Biometric_Authorization: "Bio-metric Authorization",
  Locating_Please_Wait: "Locating, Please Wait...",
  Test_Biometrics: "Test Bio-metrics",
  Cheque_Margins: "Cheque Margins",
  Percentage_Full_Error: "The percentage field can not be set over 100% percent.",
  No_Payroll_Text:"Add an <a routerLink='/folder/Employee/New'>Employee</a> or <a routerLink='/folder/Payee/New'>Payee</a> and a <a routerLink='/folder/Schedule/New'>Schedule</a>.",
  Design_Saved: "Design Saved",
  Default_Design_Selected: "Default Design Selected",
  Partial_Import: "Partial Import",
  Partial_Export: "Partial Export",
  Entire_Import: "Entire Import",
  Entire_Export: "Entire Export",
  Existing_Password: "Please enter your existing password:",
  Existing_PIN: "Please enter your existing PIN code:",
  Pin_Change_Header:"PIN Confirmation",
  Pin_Change_SubHeader:"Enter your old PIN number to confirm the change.",
  Pass_Change_Header:"Password Confirmation",
  Pass_Change_SubHeader:"Enter your old password to confirm the change.",
  PIN_Enter_Message:"Enter your PIN to confirm.",
  Password_Enter_Message:"Enter your password to confirm.",
  Pin_Updated_Success:"PIN updated successfully!",
  Pin_Updated_Fail:"PIN could not be updated.",
  Pass_Updated_Success:"Password updated successfully.",
  Pass_Updated_Fail:"Password could not be updated.",
  Preview_Payment: "Preview Payment",
  Preview_Payroll: "Preview Payroll",
  No_Payments_Created: "No payments were found to be created.",
  Payment_Preview: "Payment Preview",
  Enable_Payee: "Enable Payee",
  Rendered: "Rendered",
  No_Email: "No Email",
  Setup_Cheques: "Setup Cheques",
  name: "Name",
  address: "Address",
  address_2: "Address Line 2",
  city: "City",
  province: "Province",
  postal_code: "Postal/ZIP Code",
  country: "Country",
  username: "Username",
  full_name: "Full Name",
  birthday: "Birthday",
  email: "Email",
  phone: "Phone",
  employees: "Employees",
  addresses: "Addresses",
  payment_amount_limit: "Payment Amount Limit",
  total_limit: "Total Limit",
  payees: "Payees",
  description: "Description",
  address_line_one: "Address Line One",
  address_line_two: "Address Line Two",
  image: "Image",
  account_holder: "Account Holder",
  cheque_starting_id: "Cheque Starting ID",
  transit_number: "Transit Number",
  institution_number: "Institution Number",
  designation_number: "Designation Number",
  account_number: "Account Number",
  currency: "Currency",
  signature: "Signature",
  payment_payroll_limit: "Payment Limit",
  total_limi: "Total limit",
  date: "Date",
  printed_memo: "Printed Memo",
  banks: "Banks",
  signature_image: "Signature Image",
  address_name: "Address Name",
  notes: "Notes",
  design: "Design",
  title: "Title",
  frequency: "Frequency",
  fax: "Fax",
  salaries: "Salaries",
  salary_ids: "Salary IDs",
  start_time: "Start Time",
  end_time: "End Time",
  paid: "Paid",
  overtime_percentage: "Paid Percentage",
  overtime_amount: "Paid Fixed Amount",
  first_name: "First Name",
  last_name: "Last Name",
  moderation: "Moderation",
  create: "Create",
  edit: "Edit",
  destroy: "Destroy",
  day_start_time: "Day_start_time",
  day_end_time: "Day_end_time",
  fullname: "Name",
  time: "Time",
  auto_send: "Automatically Send",
  time_method: "Time Method",
  schedules: "Schedules",
  indefinite_payroll_enabled: "Enable Indefinitely",
  amount: "Amount",
  repeat: "Repeat",
  always_enabled: "Always Enabled",
  start_date: "Start Date",
  end_date: "End Date",
  Cheque_Total:"Cheque Total",
  Total_Amount:"Total Amount:",
  Amounts:"Amounts:",
  Images:"Images",
  Files:"Files",
  Previewing:"Previewing: ",
  Insert:"Insert",
  Preview_Import:"Preview Import",
  Entry:"Entry",
  Entries:"Entries",
  No_Entries:"No Entries",
  Import_Type:"Import Type",
  Select_Details:"Select Details",
  Select_Payee: "Select Payee",
  Enable_Holidays: "Enable Holidays",
  Disable_Holidays: "Disable Holidays",
  Wire_Transfer:"Wire Transfer",
  New_Export: "New Export",
  Export_Data: "Export Data",
  Export_Data_Word: "Select the type of file to export and download.",
  Export_File:"Export File",
  Mode:"Mode",
  Times:"Times",
  Widgets:"Widgets",
  Slider:"Slider",
  Set_Details:"Set Details",
  Select_Type:"Select Type",
  Display_Slider:"Display Slider",
  Dashboard_Slider:"Dashboard Slider",
  Dashboard_Mode:"Dashboard Mode",
  Show_Intro:"Show Intro",
  Show_Payrolls:"Show Payrolls",
  Show_Holidays:"Show Holidays",
  Show_Invoices:"Show Invoices",
  Show_Cheques:"Show Cheques",
  Enabled_Widgets:"Enabled Widgets",
  Disabled_Widgets:"Disabled Widgets",
  Colors:"Colors",
  Barcodes:"Barcodes",
  View_Timers: "View Timers",
  Gradients:"Gradients",
  No_Info:"No Info",
  Disable: "Disable",
  Show_Layer:"Show Layers",
  Hide_Layer:"Hide Layers",
  Text_Layer:"Text Layers",
  Secondly:"Secondly",
  Minutely:"Minutely",
  nine_am:"9:00 AM",
  five_pm:"5:00 PM",
  Enable_Address:"Enable Address",
  Invalid_File_Type: "Sorry, an invalid file type was selected. Supported file type: ",
  Error_Updating_Entry: "Sorry, there was an error updating this entry.",
  Schedule_Timing_Alert:"Error: The schedule starting time is set to a value after the ending time.",
  Select_Multiple_Payments_Prompt:"No payments selected. Select at least one payment.",
  Select_Multiple_Cheques_Prompt:"No cheque selected. Please select at least one cheque.",
  Select_Multiple_Items_Prompt:"No items selected. Please select at least one item.",
  Paymemt_Multiple_Delete_Confirmation:"Are you sure you want to delete multiple payments?",
  Cheque_Multiple_Delete_Confirmation:"Are you sure you want to delete multiple cheques?",
  Payee_Multiple_Delete_Confirmation:"Are you sure you want to delete multiple payees?",
  Employee_Multiple_Delete_Confirmation:"Are you sure you want to delete multiple employees?",
  MICR_Warning:"Note: Some printers and devices may not display the MICR font correctly.",
  Automatically_Send:"Automatically Send",
  Type: "Type",
  Payment_Type: "Payment Type",
  Auto_Send:"Auto Send",
  Automatically_Process:"Automatically Process",
  Auto_Process:"Auto Process",
  Image_Based:"Image-based",
  Font_Based:"Font-based",
  Rerender:"Re-render",
  Rendering:"Rendering",
  Render:"Files",
  Top:"Top",
  Middle:"Middle",
  Bottom:"Bottom",
  Top_Left:"Top Left",
  Top_Center:"Top Center",
  Top_Right:"Top Right",
  Middle_Left:"Middle Left",
  Middle_Center:"Middle Center",
  Middle_Right:"Middle Right",
  Bottom_Left:"Bottom Left",
  Bottom_Center:"Bottom Center",
  Bottom_Right:"Bottom Right",
  Numbers:"Numbers",
  Verified:"Verified",
  Paper_Size:"Paper Size",
  New_Device:"New Device",
  Add_Device:"Add Device",
  Remove_Device:"Remove Device",
  Delete_Device:"Delete Device",
  Block_Device:"Block Device",
  Block:"Block",
  Unblock:"Unblock",
  Table: "Table",
  Scan_Login_Code:"Scan Login Code",
  Login_Code:"Login Code",
  Scan_Code:"Scan Code",
  Scan_QR_Code:"Scan QR Code",
  Select_All:"Select All",
  Deselect_All:"Deselect All",
  Increase: "Increase",
  Decrease: "Decrease",
  Bold: "Bold",
  Text: "Text",
  Style: "Style",
  Italic: "Italic",
  QR_Code: "QR Code",
  Barcode: "Barcode",
  Browse_Images:"Browse Images",
  Browse_Files:"Browse Files",
  Background_Image:"Background Image",
  Logo_Image:"Logo Image",
  Header_Image:"Header Image",
  Bank_Image:"Bank Image",
  Cut_Lines: "Cut Lines",
  Background: "Background",
  License: "License",
  One_License: "1 License: ",
  Licensed: "Licensed to: ",
  Not_Licensed: "Not Licensed",
  Enter_Serial: "Enter Serial",
  Serial_Key: "Serial Key",
  Serial: "Serial",
  Product_Key: "Product Key",
  Check_Product_Key: "Verify Product Key",
  Add_Product_Key: "Enter Product Key",
  Verifying_Purchase: "Verifying Purchase...",
  Print_Envelope: "Print Envelope",
  Envelope: "Envelope",
  Thank_You: "Thank You!",
  Scale:"Scale",
  Print_Scale:"Print Scale",
  Borders:"Borders",
  VOID:"VOID",
  Void_Cheque:"Void Cheque",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"PAY TO THE ORDER OF:",
  NO_DOLLARS: "NO DOLLARS ",
  ONE_DOLLAR: "ONE DOLLAR",
  DOLLARS: " DOLLARS",
  AND: " AND ",
  CENTS: " CENTS.",
  NO_: "NO ",
  ONE_: "ONE ",
  AND_NO_: "AND NO ",
  _AND_ONE_: "AND ONE ",
  DOLLARS_AND_ONE_CENT: " AND ONE CENT.",
  AND_NO_CENTS: " AND ZERO CENTS.",
  CHEQUE_PRINT_DATE:"DATE:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks: "Initial Tasks",
  Inital_Setup: "Initial Setup",
  Welcome_To: "Welcome to ",
  Welcome: "Welcome",
  Swipe: "Swipe",
  Intro_Setup: "Intro Setup",
  Introduction: "Introduction",
  CHEQUE_PRINT_CREDIT:"Powered by Cheques",
  Title_Intro_Word: "Welcome to Cheques",
  Title_Intro: "Cheques Introduction",
  Title_Setup: "Cheques Setup",
  PayPal_Default_Email_Message: "You've received a new PayPal transfer.",
  Cheques_App_Export: "Exported by Cheques",
  No_Prints_Purchased: "No Prints Purchased",
  No_Prints_Purchased_Word: "No additional prints are available. Please purchases additional cheques.",
  Post_Intro_Thanks:"Thank you for choosing Cheques. The setup process is now complete.",
  Post_Intro_Word:"Get started by printing out your first cheque, add a future payment, or add an employees to the payroll.",
  Upgrade_Required:"Cheques requires you to own a more premium version of the software in order to hide this message and unlock additional features.",
  Upgrade_Title:"Cheques",
  Mailout_Prompt:"You may additionally choose to have Cheques mail-out your payroll cheques for you.",
  Mailed_Cheque:"Mailed Cheque",
  Mailed_Cheque_Color:"Mailed Cheque (Color)",
  Terms_Purchase: "All electronic purchases with Cheques are fully refundable for up to 30-days from purchase date. Please read and agree to the ",
  Dashboard_Setup: "Setup The Primary Printer",
  Dashboard_Add: "Add The Primary Bank Account",
  Dashboard_Customize: "Select A Cheque Template",
  Dashboard_Job_Salary: "Create Your Job & Add Your Salary",
  Dashboard_Employees: "Track Employees & Payees",
  Dashboard_Print: "Print & Mail Your Paycheques",
  Dashboard_Payroll: "Automate Your Payroll Printing",
  Dashboard_PayPal: "Setup PayPal Payroll / Payouts",
  Begin_Setup: "Begin Setup",
  Get_Started: "Get Started",
  Purchase: "Purchase",
  Lockdown: "Lockdown",
  Unlock: "Unlock",
  Detailed:"Detailed",
  Log_In:"Log In",
  Login:"Login",
  Launch:"Launch",
  Register:"Register",
  Finish:"Finish",
  List:"List",
  Weekends:"Weekends",
  Weekly:"Weekly",
  PayPal_Default_Subject: "New Payment",
  Payment_Message: "Payment Message",
  PayPal_Default_Payment_Note: "Thank You",
  Setup_Your_Cheqing_Account: "Chequing Account",
  Add_Your_Primary_Cheqing_Account: "Add your primary chequing account.",
  Welcome_Word: "Simplify and automate payroll and human resource management.",
  Get_Started_Quickly: "Just answer a few simple questions that will help us get started...",
  Done_Intro_Word:"Setup Complete",
  PIN_Protected: "Password & PIN Protected",
  Enter_New_PIN: "Enter a new PIN Code:",
  Enter_PIN: "Enter PIN Code:",
  Invalid_PIN: "Invalid PIN entered.",
  Account_Identifier: "Account Identifier",
  New_Account_Identifier: "New Account Identifier",
  attempt: "attempt",
  attempts: "attempts",
  remaining: "remaining",
  Language: "Language",
  languages: "Languages",
  select_languages: "Select Language",
  Deposit: "Deposit",
  Hire_One_Now: "Hire One Now",
  App_Locked: "The application is locked.",
  Skip_: "Skip",
  Skip_to_Dashboard: "Skip to the Dashboard",
  Dashboard:"Dashboard",
  Import:"Import",
  Admin:"Administrators",
  New_Admin:"New Admin",
  Settings:"Settings",
  Color_Picker: "Color Picker",
  Font_Picker: "Font Picker",
  Logout:"Logout",
  Close:"Close",
  Close_menu:"Close",
  Excel:"Excel File",
  Csv:"CSV File",
  Sql:"SQL File",
  Json:"JSON File",
  Url:"Import by URL",
  Back:"Back",
  Timers:"Timers",
  Cheque:"Cheque",
  Print:"Print",
  Designs:"Designs",
  Pause_Printing:"Pause Printing",
  Resume_Printing:"Resume Printing",
  Printing_Paused:"Printing Paused",
  PrintingUnavailable:"Sorry! Printing is unavailable on this system.",
  Prints_Paused:"Prints Paused",
  Administration:"Administration",
  Loading:"Loading",
  Unnamed:"Unnamed",
  error:"Sorry, this cheque could not be opened for viewing.",
  No_Cheques_To_Print:"No Cheques To Print",
  No_Cheques_To_Print_Word:"Create a <a routerLink='/folder/Cheque/New'>New Cheque</a>.",
  New_Cheque:"New Cheque",
  Start_One_Now: "Start One Now",
  Edit_Cheque:"Edit Cheque",
  Select_Cheques:"Select Cheques",
  Select_Employee:"Select Employee",
  Default_Printer:"Default Printer",
  Reassign:"Reassign",
  Configure:"Configure",
  Template:"Template",
  Designer: "Designer",
  Edit_Designs: "Edit Designs",
  New_Design: "New Design",
  Next:"Next",
  Save:"Save",
  Name:"Name",
  Mail:"Mail",
  Amount:"Amount",
  Date:"Date",
  PayPal:"PayPal",
  Payouts:"Payouts",
  PayPal_Label:"PayPal Label",
  Email_Username:"Email / Username",
  Client_ID:"Client ID",
  Sandbox_Email:"Sandbox Email",
  PayPal_Email:"PayPal Email",
  PayPal_Username:"API Username",
  PayPal_Payouts:"PayPal Payouts",
  Select_PayPal_Key:"Select PayPal Key",
  Secret:"Secret",
  API_Secret:"API Secret",
  PayPal_API_Keys:"PayPal Keys",
  New_PayPal_Key:"New PayPal Key",
  Email_Subject: "Email Subject",
  Email_Message: "Email Message",
  Payout_Options: "Payout Options",
  Payment_Note: "Payment Note",
  Enable_Employee:"Enable Employee",
  Enable_Production_Mode:"Enable Production Mode",
  Sandbox_Username: "Sandbox Username",
  Sandbox_Signature: "Sandbox Signature",
  Sandbox_Password: "Sandbox Password",
  Production_Username: "Production Username",
  Production_Signature: "Production Signature",
  Production_Password: "Production Password",
  Production_Email:"Production Email",
  API_Client_ID:"API Client ID",
  API_Signature:"API Signature",
  API_Password:"API Password",
  API_Username:"API Username",
  Secret_Key: "Secret Key",
  Sandbox: "Sandbox",
  Production: "Production",
  Sandbox_Keys: "Sandbox Keys",
  Production_Keys: "Production Keys",
  API_Title:"API Title",
  Production_Mode:"Production Mode",
  Account_Label: "Account Label",
  No_PayPal_Setup:"No PayPal Key",
  Enable_PayPal_Account:"Enable PayPal Account",
  No_PayPal_Word:"Add your <a routerLink='/folder/Invoice/New'>PayPal API Key</a>.",
  Printed_Memo:"Printed Memo",
  Employee:"Employee",
  View_Employee:"View Employee",
  Mailing_Address:"Mailing Address",
  Company_Address:"Company Address",
  Select_Company_Address:"Select Company Address",
  Address:"Address",
  Any_Day:"Any Day",
  Address_Name:"Address Name",
  Unit:"Unit",
  Account:"Account",
  Bank_Account:"Bank Account",
  Account_Limits:"Enable Account Limits",
  Payroll: "Payroll",
  Run: "Run",
  Run_Payroll: "Run Payroll",
  New_Payroll: "New Payroll",
  No_Payroll: "No Upcoming Payroll",
  Upcoming_Holiday: "Upcoming Holiday:",
  Invoice_Due: "Invoice Due:",
  New_Invoice: "New Invoice",
  No_Invoices: "No Invoices",
  No_Invoices_Word: "Create the first <a routerLink='/folder/Invoice/New'>Invoice</a>.",
  Cheque_Due: "Cheque Due:",
  Payrolls: "Payrolls",
  Sandbox_Mode: "Sandbox Mode",
  Hire: "Hire",
  Pay: "Pay",
  New: "New",
  Add: "Add",
  Make: "Make",
  Time: "Time",
  Write: "Write",
  Holiday: "Holiday",
  Holidays: "Holidays",
  Next_Holiday: "Next Holiday:",
  All_Done: "All Done!",
  Employees:"Employees",
  Payees:"Payees",
  Job:"Job",
  Jobs:"Jobs",
  Invoice:"Invoice",
  Invoices:"Invoices",
  Vacations:"Vacations",
  Cheques:"Cheques",
  Brand_Cheques:"Your Brand",
  Payment:"Payment",
  Enable_Payment:"Enable Payment",
  Payments:"Payments",
  Schedule: "Schedule",
  Schedules: "Schedules",
  Timesheet:"Time Sheet",
  Timesheets:"Time Sheets",
  Salary:"Salary",
  New_Address:"New Address",
  Address_2: "Address (Line 2)",
  _City: "City",
  _State: "State/Prov",
  City: "City / Township",
  State: "State / Province",
  Postal: "Postal / ZIP Code",
  ZIP: "Postal / ZIP",
  Country: "Country",
  Addresses: "Addresses",
  Required_Options:"Required Options",
  Optional_Options:"Optional Options",
  Additional_Options:"Additional Options",
  Required:"Required",
  Optional:"Optional",
  Additional:"Additional",
  No_Addresses: "No Addresses",
  New_Address_Word: "Add the first <a routerLink='/folder/Address/New'>Address</a>.",
  Select_Address: "Select Address",
  No_Address: "No Addresses",
  Print_Cheque: "Print Cheque",
  Print_Cheques: "Print Cheques",
  Print_Cheque_Now: "Print Cheque Now",
  Description: "Description",
  Pay_To_The_Order_Of: "Pay to the Order of:",
  Select_Date_Range: "Select Date Range",
  Select_Starting_Date: "Select Starting Date",
  Select_Ending_Date: "Select Ending Date",
  Select_Date: "Select Date",
  Cheque_Date: "Cheque Date",
  Cheque_Memo: "Cheque Memo",
  Blank_Cheque:"Blank Cheque",
  Blank:"Blank",
  No_Cheques: "No Cheques",
  No_Cheques_Word: "Print your first <a routerLink='/folder/Cheque/New'>Cheque</a>.",
  Cheque_Amount_Placeholder: "$0.00",
  View_Image: "View Image",
  View: "View",
  Modify: "Modify",
  Delete: "Delete",
  Cheque_Paid: "Paid",
  New_Deduction: "New Deduction",
  Title: "Title",
  Frequency: "Frequency",
  Hourly: "Hourly",
  Daily: "Daily",
  Weekdays: "Weekdays",
  BiWeekly: "2 Weeks",
  TriWeekly: "3 Weeks",
  Monthly: "Monthly",
  MiMonthly: "2 Months",
  Quarterly: "Quarterly",
  Yearly: "Yearly",
  Every_Week: "Every Week",
  Every_Payroll: "Every Payroll",
  Every_Month: "Every Month",
  Annually: "Annually",
  Always_Scheduled: "Always Scheduled",
  Start_Date: "Start Date",
  End_Date: "End Date",
  Start_Time: "Start Time",
  End_Time: "End Time",
  Deduction_Label: "Deduction Label",
  Notes: "Notes",
  Options: "Options",
  Enable: "Enable",
  Select_Deductions:"Select Deductions",
  Deductions:"Deductions",
  No_Deductions:"No Deductions",
  No_Deductions_Word:"Create your first <a routerLink='/folder/Deduction/New'>Deduction</a>.",
  New_Employee:"New Employee",
  No_Title: "No Title",
  _Name: "Name",
  About_Yourself: "About Yourself",
  Full_Name: "Full Name",
  Birthday: "Birthday",
  Email: "Email",
  SMS: "SMS",
  Phone: "Phone",
  Test:"Test",
  Call:"Call",
  Fax:"Fax",
  Printed_Note: "Printed Note",
  Position: "Position",
  Job_Position: "Job Position",
  Select_a_Job: "Select a Job",
  Select_a_Salary: "Select a Salary",
  Add_a_Deduction: "Add a Deduction",
  Taxes: "Taxes",
  Add_Taxes: "Add Taxes",
  Date_of_Birth: "Date of Birth",
  Email_Address: "Email Address",
  Phone_Number: "Phone Number",
  Phone_Call: "Phone Call",
  Enable_on_Payroll: "Enable on Payroll",
  Select_Employees: "Select Employees",
  No_Employees: "No Employees",
  No_Employees_Word: "Add your first new <a routerLink='/folder/Employee/New'>Employee</a>.",
  No_Name:"No Name",
  Unemployeed:"Unemployed",
  Employeed:"Employed",
  Paid:"Paid",
  Enabled:"Enabled",
  Disabled:"Disabled",
  Fire:"Fire",
  Not_Available:"Not Available",
  Not_Available_Word:"Print your first <a routerLink='/folder/Invoice/New'>Invoice</a> & get paid.",
  Select_Invoices: "Select Invoices",
  Print_Invoice_Word:"Print your first <a routerLink='/folder/Invoice/New'>Invoice</a> & get paid.",
  Invoice_Title:"Invoice Title",
  Invoice_Date:"Invoice Date",
  Due_Date:"Due Date",
  New_Job: "New Job",
  Details: "Details",
  Customize: "Customize",
  Customize_Dashboard: "Customize Dashboard",
  Components: "Components",
  No_Components: "No Components",
  Main_Components: "Main Components",
  Smaller_Components: "Smaller Components",
  Error_Loading_Page: "Error loading this page.",
  Bank_Details: "Bank Details",
  About_Your_Job: "About Your Job",
  Your_Schedule: "Your Schedule",
  Job_Title: "Job Title",
  Job_Description: "Job Description",
  Job_Details: "Job Details",
  Enable_Job: "Enable Job",
  Pay_Period: "Pay Period",
  Perpetually_Schedule: "Perpetually Schedule",
  Select_Jobs: "Select Jobs",
  No_Jobs: "No Jobs",
  Add_Jobs: "Add Jobs",
  No_Jobs_Word: "Add the first <a routerLink='/folder/Job/New'>Job</a> to the list.",
  Count_Employees: "job.employee_count+' Employees'",
  Zero_Employees: "0 Employees",
  New_Leave: "New Leave",
  Leave_Name: "Leave Name",
  Paid_Leave: "Paid Leave",
  Leave_Pay: "Leave Pay",
  Indefinite_Leave: "Indefinite Leave",
  Indefinite_Payroll: "Indefinite Payroll",
  Leave: "Leave",
  Add_Schedules: "Add Schedules",
  Percentage: "100%",
  Enable_Leave_Of_Absence: "Enable Absence",
  Select_Leaves: "Select Leaves",
  No_Leaves: "No Leaves of Absence",
  Leave_Of_Absence: "Leave of Absence",
  Leaves_Of_Absence: "Leaves of Absence",
  New_Leave_of_Absense: "New Leave of Absence",
  No_Leaves_Word: "Add the first <a routerLink='/folder/Leave/New'>Leave of Absence</a>.",
  Not_Enabled: "Not Enabled",
  Absences: "Absences",
  Payee:"Payee",
  New_Payee: "New Payee",
  Payee_Identifier: "Payee Identifier",
  Payee_Name: "Payee Name",
  Payee_Title: "Payee Title",
  Payment_Memo: "Payment Memo",
  Select_Payees: "Select Payees",
  No_Payees: "No Payees",
  Add_Payee_Note: "Add the first <a routerLink='/folder/Payee/New'>Payee</a>.",
  New_Payees: "New Payees",
  About_The_Payment_Schedule: "Payment Schedule",
  Your_Payroll_Schedule: "Automatic Payroll",
  New_Payment: "New Payment",
  Identifier: "Identifier",
  Selected: "Selected",
  Select: "Select",
  Memo: "Memo",
  Payment_Date: "Payment Date",
  Mark_as_Paid: "Mark As Paid",
  Select_Payments: "Select Payments",
  No_Payments: "No Payments",
  No_Payments_Word: "Create the first <a routerLink='/folder/Payment/New'>Payment</a>.",
  Create_Payroll: "Create Payroll",
  Properties: "Properties",
  Payroll_Title: "Payroll Title",
  Payroll_Notes: "Payroll Notes",
  Payroll_Setup: "Payroll Setup",
  Tabulate_Payments: "Tabulate Payments",
  Tabulate: "Tabulate",
  By: "By:",
  Payments_By: "Payments By",
  Timesheets_And_Schedules: "Time Sheets & Schedules",
  Both: "Both",
  Items: "Items",
  Add_Payees: "Add Payees",
  Add_Account: "Add Account",
  Enable_Account: "Enable Account",
  Enable_Payroll: "Enable Payroll",
  Print_Payroll: "Print Payroll",
  No_Payrolls: "No Payroll",
  No_Payroll_Word: "Create your first <a routerLink='/folder/Payroll/New'>Payroll</a>.",
  View_more:"VIEW_MORE",
  Less:"LESS",
  Add_Payroll:"Add Payroll",
  Select_Payroll: "Select Payroll",
  About_Your_Salary: "About Your Salary",
  Add_Salaries: "Add Salaries",
  Add_Salary: "Add Salary",
  Salaries: "Salaries",
  No_Salaries: "No Salaries",
  No_Salaries_Word: "Add the first <a routerLink='/folder/Salary/New'>Salary</a>.",
  Select_Salaries: "Select Salaries",
  New_Salary: "New Salary",
  Salary_Name: "Salary Identifier",
  Enable_Salary: "Enable Salary",
  Salary_Amount: "Salary Amount",
  New_Schedule: "New Schedule",
  Schedule_Title: "Schedule Title",
  Add_Address: "Add Address",
  Repeat: "Repeat",
  Design: "Design",
  Edit_Design: "Edit Design",
  Edit_this_Design: "Edit this Design",
  Repeat_Payment: "Repeat Payment",
  Enable_Schedule: "Enable Schedule",
  Never: "Never",
  Select_Schedule: "Select Schedules",
  No_Schedules: "No Schedules",
  No_Schedules_Word: "Create the first <a routerLink='/folder/Schedule/New'>Schedule</a>.",
  New_Administrator: "New Administrator",
  Username: "Username",
  First_Name: "First Name",
  Last_Name: "Last Name",
  Add_an_Address: "Add an Address",
  Payment_Limit: "Per-Payment Limit",
  Total_Limit: "Total Limit",
  Select_Accounts: "Select Accounts",
  No_Administrators: "No Administrators",
  No_Administrators_Word: "Create the first <a routerLink='/folder/Administrator/New'>Admin Account</a>.",
  New_Administrators_Word: "Add the first <a routerLink='/folder/Administrator/New'>Administrator</a>",
  Cloud: "Cloud",
  Backup: "Backup",
  Enable_iCloud:"Enable iCloud",
  Enable_Google_Drive:"Enable Google Drive",
  Enable_Microsoft_OneDrive:"Enable Microsoft OneDrive",
  Automatically_Backup:"Automatically Backup",
  FTP_Settings:"FTP Settings",
  URL_File_Prompt:"Please specify the location for a .xls / .xlsx / .json file to import:",
  URL_SQL_Prompt:"Please specify the location of the SQLite file to import:",
  FTP_Backup:"FTP Backup",
  FTP_Import:"FTP Import",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Host",
  Port:"Port",
  Path:"Path",
  Data_Path:"Data Path",
  Enable_FTP_Account:"Enable FTP Account",
  HostnameIP: "Host-name",
  Password: "Password",
  Connection_Port: "Connection Port",
  Enable_MySQL_Database: "Enable MySQL Database",
  Log: "Log",
  Reset: "Reset",
  Erase: "Erase",
  Export: "Export",
  Database: "Database",
  Upload_CSV: "Upload CSV",
  Download_CSV: "Download CSV",
  SQL_Database: "SQL URL",
  MySQL_Database: "MySQL",
  MySQL_Backup: "MySQL Backup",
  Internal_Notes:"Internal Notes",
  Root_Path: "Root Path",
  Select_Backup:"Select Backup",
  Add_New_Backup:"Add a New Backup",
  First_Backup:"Setup the first backup...",
  Backups:"Backups",
  Add_Backup:"Add Backup",
  No_Backups:"There are no backups to be found.",
  Select_Backup_Type:"Select the type of backup you would like to setup...",
  Backup_Type:"Backup Type",
  FTP_Drive:"FTP Drive",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Drive",
  Microsoft_OneDrive:"Drive",
  Import_Fields:"Import Fields",
  Import_Fields_Word:"Use this section to select <a routerLink='/folder/Holiday/New'>Import</a> data.",
  Upload: "Upload",
  Download:"Download",
  Download_JSON:"Download as JSON Data",
  Download_SQL:"Download as SQL File",
  New_Bank: "New Bank",
  New_Account: "New Account",
  New_Bank_Account: "New Bank Account",
  Upload_Image: "Upload Image",
  Bank_Name: "Bank Name",
  Bank_Address: "Bank Address",
  Branch_Address: "Branch Address",
  Address_on_Cheque: "Address",
  Cheque_Numbers: "Cheque Numbers",
  Cheque_Details: "Cheque Details",
  Bank_Logo: "Bank Logo",
  Cheque_ID: "Cheque ID",
  Starting_Cheque_ID: "Starting Cheque ID",
  Transit_Number: "Transit Number",
  Institution_Number: "Institution Number",
  Designation_Number: "Designation Number",
  Account_Number: "Account Number",
  Limits: "Limits",
  Default_Limits: "Default Limits",
  Over_Limit: "Over Limit",
  Under_Limit: "Under Limit",
  Payroll_Limit: "Payroll Limit",
  Enable_Bank_Account: "Enable Bank Account",
  Select_Account: "Select Account",
  No_Bank_Account: "No Bank Account",
  No_Bank_Account_Word: "Add the first <a routerLink='/folder/Accounts/New'>Bank Account</a>.",
  Bank_Accounts: "Bank Accounts",
  No_Accounts: "No Accounts",
  No_Accounts_Note: "Add the first <a routerLink='/folder/Accounts/New'>Bank Account</a>.",
  Cheque_Designer: "Cheque Designer",
  Cheque_Design: "Cheque Design",
  Select_Design: "Select a Design",
  Cheque_Designs: "Cheque Designs",
  No_Cheque_Designs: "No Cheque Designs",
  No_Cheque_Designs_Word: "Create your own <a routerLink='/folder/Settings/Cheque/Design/New'>Cheque Design</a>.",
  Set_Default: "Set as Default",
  Default: "Default",
  Remove: "Remove",
  Folder: "Folder",
  Edit: "Edit",
  LoadingDots: "Loading...",
  Add_a_New_File: "Add a <a href='#' (click)='add()'>New File</a> to",
  this_folder: "this folder",
  FTP_Backup_Settings: "FTP Backup Settings",
  Secure_File_Transfer: "Secure File Transfer",
  New_Holiday: "New Holiday",
  Add_Holiday: "Add Holiday",
  Holiday_Name: "Holiday Name",
  Additional_Pay: "Additional Pay",
  Enable_Holiday: "Enable Holiday",
  Select_Holidays: "Select Holidays",
  No_Holidays: "No Holidays",
  No_Holidays_Word: "Add the first <a routerLink='/folder/Holiday/New'>Public Holiday</a>.",
  New_Import: "New Import",
  Import_Data: "Import Data",
  Import_Data_Word: "Select the type of file or upload method of your choosing.<br /> You will be able to select whichever imported fields in a file correspond to  whichever parameter in the app after uploading a supported file.",
  Import_File:"Import File",
  Link_To_File:"Link to File",
  Select_File:"Select File",
  New_Moderator:"New Moderator",
  Allow_Moderation: "Allow Moderation",
  Create_Paycheques: "Create Paycheques",
  Edit_Paycheques_and_Data: "Edit Paycheques and Data",
  Destroy_Data_and_Paycheques: "Destroy Data and Paycheques",
  Bonus_Percentage: "Paycheque Percentage",
  Fixed_Amount: "Fixed Amount",
  Select_Moderator: "Select Moderator",
  No_Moderators: "No Moderators",
  Moderators: "Moderators",
  Moderator_Account: "Create the first <a routerLink='/folder/Administrator/New'>Moderator Account</a>.",
  No_Moderators_Word:"Add the first <a routerLink='/folder/Administrator/New'>Moderator</a>.",
  Defaults: "Defaults",
  Provide_Us_Info: "Provide Info",
  Setup_Your_Printer: "Setup Your Printer",
  Your_Company: "About Your Company",
  Company_Name: "Company Name",
  Currency: "Currency",
  Default_Currency: "Default Currency",
  Base_Monthly_Income: "Monthly Income",
  Protection: "Protection",
  App_Protection: "App Protection",
  PIN_Code_Protection: "PIN Code Protection",
  App_Protection_Word: "Enable security methods that help protect your account.",
  PIN_Code: "PIN Code",
  Change_PIN: "Change PIN",
  New_Password: "New Password",
  Geofence_Protection: "Geo-Fence Protection",
  Geofence_Area: "Set Area",
  Distance: "Distance",
  Setup_Now: "Setup Now",
  Mile: "Mile",
  Km: "Km",
  m: "m",
  Facial_Recognition: "Facial Recognition",
  Face: "Face",
  Setup: "Setup",
  Label: "Label",
  Password_Protection: "Password Protection",
  Modify_Password: "Modify Password",
  New_Tax_Entry: "New Tax Entry",
  New_Tax: "New Tax",
  Tax_Label: "Tax Label",
  Perpetually_Enabled: "Perpetually Enabled",
  Select_Taxes: "Select Taxes",
  Tax_Deductions: "Tax Deductions",
  No_Tax_Deductions: "No Tax Deductions",
  No_Tax_Deductions_Word: "Add the first <a routerLink='/folder/Tax/New'>Tax</a> deduction.",
  New_Timer: "New Timer",
  Start: "Start",
  Stop: "Stop",
  Start_Timer: "Start Timer",
  Stop_Timer: "Stop Timer",
  Timer_Active: "Timer Active",
  Timer:"Timer:",
  Timer_Running: "Timer: (Running)",
  Save_Timer: "Save Timer",
  New_Timesheet: "New Time Sheet",
  Select_Timesheets: "Select Time Sheets",
  Work_Notes: "Work Notes",
  Entry_Title: "Entry Title",
  No_Timesheets: "No Time Sheets",
  No_Timesheets_Word: "Add the first <a routerLink='/folder/Timesheet/New'>Time Sheet</a>.",
  Timesheet_Submitted: "Time Sheet Submitted",
  Timesheet_Congrats: "Congratulations! Your time Sheet has been successfully submitted. Thank you!",
  Timesheet_Copy: "To receive a copy of your documents please provide us with your email address and/or mobile phone number.",
  Submit: "Submit",
  Allow_Notifications: "Allow Notifications",
  Untitled_Entry:"New Entry",
  Untitled_Bank:"Untitled Bank",
  Timesheet_Entry: "Time Sheet Entry",
  Work_Description: "Work Description",
  Enable_Timesheet: "Enable Time Sheet",
  Submit_Timesheet: "Submit Time Sheet",
  Vacation: "Vacation",
  New_Vacation: "New Vacation",
  Vacation_Name: "Vacation Name",
  Paid_Vacation: "Paid Vacation",
  Vacation_Pay: "Vacation Pay",
  Enable_Vacation: "Enable Vacation",
  Select_Vacations: "Select Vacations",
  No_Vacations: "No Vacations",
  No_Vacations_Word: "Create the first <a routerLink='/folder/Vacation/New'>Vacation</a> entry.",
  Payroll_Schedule: "Payroll Schedule",
  Next_Payroll: "Next Payroll:",
  Upcoming_Payroll: "Upcoming Payroll",
  No_Upcoming_Payroll: "No Upcoming Payroll",
  Address_Book:"Address Book",
  Archived_Documents:"Archived Documents",
  Dev_Mode:"Application In Development Mode",
  Administrators:"Administrators",
  Privacy:"Privacy",
  None:"None",
  Select_Signature:"Select Signature",
  No_Signatures:"No Signatures",
  No_Signatures_Word: "Add the first <a routerLink='/folder/Signature/New'>Signature</a>.",
  Repeat_Indefinitely:"Repeat Indefinitely",
  Sign: "Sign",
  Sign_Here:"Sign Here",
  Date_Signed:"Date Signed",
  Signature_Pad:"Signature Pad",
  Account_Holder:"Account Holder",
  Account_Properties:"Account Properties",
  Name_On_Cheque:"Name on Cheque",
  Server_Hostname: "Server Host-name / IP",
  Printers: "Printers",
  No_Printers: "No Printers",
  Printer_Exists:'A printer by this name already exists.',
  No_Printers_Word: "Add the very first <a routerLink='/folder/Printer/New'>Printer</a>.",
  No_Printer_Alert: "No printer is defined. Would you like to setup a printer?",
  Add_Printer: "Add Printer",
  New_Printer: "New Printer",
  Printer_Hostname: "Printer Host-name / IP",
  Printer_Label: "Printer Label",
  Printer_Protocol: "Printer Protocol",
  Protocol: "Protocol",
  Email_Print: "Email",
  AirPrint: "AirPrint",
  IPP: "IPP",
  LPD: "LPD",
  HPJetDirect: "Socket",
  Print_Job: "Print Job",
  Printed: "Printed",
  Not_Printed: "Not Printed",
  Print_Jobs: "Print Jobs",
  Print_Queue: "Print Queue",
  No_Print_Jobs: "No Print Jobs",
  No_Prints: "Create a new <a routerLink='/folder/Cheque/New'>Cheque</a> to print.",
  Prints: "Prints",
  Find_Printer: "Find Printer",
  Find_AirPrint_Devices: "Find AirPrint Devices",
  Select_Printer: "Select Printer",
  System_UI: "System UI",
  Printer: "Printer",
  Status: "Status",
  Preview: "Preview",
  Enable_Print_Job: "Enable Print Job",
  Queue_Weight:"Queue Weight",
  Unlimited:"Unlimited",
  Show_Advanced_Printer_Options: "Show Advanced Printer Options",
  Advanced: "Advanced",
  Location: "Location",
  Note: "Note",
  Queue_Name: "Queue Name",
  Pages_Printed_Limit:"Pages Printed Limit",
  MultiPage_Idle_Time:"Multi-page Wait Time (s)",
  Page_Count_Limit:"Page Count Limit",
  Page_Orientation:"Page Orientation",
  Portrait:"Portrait",
  Landscape:"Landscape",
  Duplex:"Duplex",
  Double_Sided_Printing:"Double Sided",
  Duplex_Mode:"Duplex Mode",
  Duplex_Short:"Short",
  Duplex_Long:"Long",
  Duplex_None:"None",
  Color_And_Quality:"Color And Quality",
  Monochrome:"Monochrome",
  Photo_Quality_Prints:"Photo Quality Prints",
  Printer_Email: "Printer Email",
  Automatically_Downsize:"Automatically Downsize",
  Paper:"Paper",
  Paper_Name:"Paper Name",
  Paper_Width:"Paper Width",
  Paper_Height:"Paper Height",
  Paper_Autocut_Length:"Paper Auto-cut Length",
  Margins:"Margins",
  Page_Margins:"Page Margins",
  Page_Margin_Top:"Top Page Margin",
  Page_Margin_Right:"Right Page Margin",
  Page_Margin_Bottom:"Bottom Page Margin",
  Page_Margin_Left:"Left Page Margin",
  Add_Employees:"Add Employees",
  Header:"Header",
  Print_A_Page_Header:"Print A Page Header",
  Header_Label:"Header Label",
  Header_Page_Index:"Header Page Index",
  Header_Font_Name:"Header Font",
  Select_Font:"Select Font",
  Font_Selector:"Font Selector",
  Header_Font_Size:"Header Font",
  Header_Bold:"Header Bold",
  Header_Italic:"Header Italic",
  Header_Alignment:"Header Alignment",
  Left:"Left",
  Center:"Center",
  Right:"Right",
  Justified:"Justified",
  Header_Font_Color:"Header Color",
  Select_Color:"Select Color",
  Footer:"Footer",
  Print_A_Page_Footer:"Print A Page Footer",
  Footer_Label:"Footer Label",
  Footer_Page_Index:"Footer Page Index",
  Footer_Font_Name:"Footer Font",
  Fonts:"Fonts",
  Done:"Done",
  Select_Fonts:"Select Fonts",
  Footer_Font_Size:"Footer Size",
  Footer_Bold:"Footer Bold",
  Footer_Italic:"Footer Italic",
  Footer_Alignment:"Footer Alignment",
  Footer_Font_Color:"Footer Color",
  Page_Copies:"Page Copies",
  Enable_Printer:"Enable Printer",
  Remote_Logging: "Remote Logging",
  Log_Server: "Log Server",
  Encryption: "Encryption",
  Random_Key: "Random Key",
  Encryption_Key: "Encryption Key",
  Cheques_Webserver: "Custom Database",
  Learn_How: "Learn How",
  Signature: "Signature",
  Default_Printer_Unit: "in/cm/mm/(pt)",
  View_Signature: "View Signature",
  Printed_Signature: "Printed Signature",
  Digitally_Sign: "Digitally Sign",
  Digital_Signature: "Digital Signature",
  Add_Signature: "Add Signature",
  Add_Your_Signature:"Add Your Signature",
  Enable_Signature: "Enable Signature",
  Digitally_Signed: "Digitally Signed",
  Insert_Error:"Unable to save cheque due to database issues.",
  Delete_Confirmation:"Are you sure you want to delete this information?",
  Discard_Confirmation:"Are you sure you want to discard this information?",
  Discard_Bank_Confirmation:"Are you sure you want to discard this account?",
  Discard_Printer_Confirmation:"Are you sure you want to discard this printer?",
  Delete_Bonus_Confirmation:"Are you sure you want to delete this bonus?",
  Delete_Invoice_Confirmation:"Are you sure you want to delete this invoice?",
  Generated_Cheque: "Generated Cheque",
  Generated_Invoice: "Generated Invoice",
  Schedule_Start_Time: "Schedule Start",
  Schedule_End_Time: "Schedule End",
  New_Call: "New Call",
  No_Contacts: "No Contacts",
  No_Contacts_Word: "Administrators, moderators, employees, and payees appear as contacts.",
  PDF_Subject: "finances",
  PDF_Keywords: "payroll paycheque PDF cheque cheques",
  Printer_Setup: "Printer Setup",
  Printer_Selection: "Printer Selection",
  New_Fax:"New Fax",
  New_Fax_Message: "New Fax Message",
  Fax_Machine: "Fax Machine",
  Fax_Name: "Fax Name",
  Fax_Email: "Fax Email",
  Fax_Number: "Fax Number",
  Contents: "Contents",
  Fax_Body: "Page Body",
  Header_Word: "Header:",
  Header_Text: "Header Text",
  Include_Header: "Include Header",
  Include_Footer: "Include Footer",
  Footer_Word: "Footer:",
  Footer_Text: "Footer Text",
  Attach_a_Cheque: "Attach a Cheque",
  Add_Deduction: "Add Deduction",
  Enable_Fax: "Send Fax",
  Select_Fax: "Select Fax",
  No_Faxes: "No Faxes",
  Faxes: "Faxes",
  Save_and_Send: "Send Fax",
  Save_and_Pay: "Save and Pay",
  WARNING: "WARNING:",
  Remember: "Remember",
  Printing: "Printing",
  Printing_Complete: "Printing Complete!\n\n",
  of: "of",
  There_Were: "There were ",
  Successful_Prints: " successful prints and ",
  Unsuccessful_Prints: " unsuccessful prints.",
  PrinterError: "Sorry! There was an error.",
  Printing_: "Printing...",
  PrinterSuccess: "Document printed successfully.",
  PrintersSuccess: "Documents printed successfully.",
  New_Message: "New Message",
  New_Messages: "New Messages",
  Read_Message: "Read Message",
  Write_Message: "Write Message",
  Send_Message: "Send Message",
  Subject: "Subject",
  Message: "Message",
  Writing: "Writing...",
  Send: "Send",
  Set_Date: "Set Date",
  Set_Time: "Set Time",
  Recieve: "Receive",
  Set_as_Default: "Set as Default",
  Default_Account: "Default Account",
  Default_Design: "Default Design",
  Multiple_Cheques: "Three Cheques",
  Account_Mode: "Account Mode",
  Multiple_Cheques_Description: "Three cheques per page.",
  Check_and_Table: "Cheque & Table",
  Check_including_Table: "Cheque with accounting table.",
  Check_Mailer: "Cheque Mailer",
  Check_Mailer_Window: "Cheque with an address window.",
  DocuGard_Table_Top:"DocuGard Large Cheque & Table",
  DocuGard_Table_Middle:"DocuGard Large Cheque & Table (Middle)",
  DocuGard_Table_Bottom:"DocuGard Large Cheque & Table (Bottom)",
  DocuGard_Mailer_Top:"DocuGard Large Cheque Mailer",
  DocuGard_Mailer_Middle:"DocuGard Large Cheque Mailer (Middle)",
  DocuGard_Mailer_Bottom:"DocuGard Large Cheque Mailer (Bottom)",
  DocuGard_Three_Cheques:"DocuGard Three Large Cheques",
  DocuGard_Cheque_Top:"DocuGard Single Large Cheque",
  DocuGard_Cheque_Middle:"DocuGard Large Cheque (Middle)",
  DocuGard_Cheque_Bottom:"DocuGard Large Cheque (Bottom)",
  DocuGard_Three_Cheques_Window:"DocuGard Three cheques on one page.",
  DocuGard_Table_Top_Window: "DocuGard Cheque & earnings table.",
  DocuGard_Table_Middle_Window: "DocuGard Cheque & earnings table.",
  DocuGard_Table_Bottom_Window: "DocuGard Cheque & earnings table.",
  DocuGard_Mailer_Top_Window: "DocuGard Cheque, table, and address.",
  DocuGard_Mailer_Middle_Window: "DocuGard Cheque, table, and address.",
  DocuGard_Mailer_Bottom_Window: "DocuGard Cheque, table, and address.",
  DocuGard_Cheque_Top_Window: "DocuGard Cheque for secure paper.",
  DocuGard_Cheque_Middle_Window: "DocuGard Cheque for secure paper.",
  DocuGard_Cheque_Bottom_Window: "DocuGard Cheque for secure paper.",
  Basic_Cheque: "Single Cheque",
  Basic_Cheque_Print: "Print out a single cheque.",
  Error_Setting_As_Paid: "Error Setting as Paid",
  Add_Attachment: "Add Attachment",
  PrinterUnavailable: "Printer Unavailable",
  CreditCardComponent:"Cards",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"New Deposit",
  Deposits:"Deposits",
  No_Deposits:"No Deposits",
  Credit_Card_Deposit:"Credit Card",
  New_Credit_Card_Deposit_Message:"Credit Card Deposit",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin Deposit",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Interac Transfer",
  Payments_Limit: "Payment Limit",
  No_Payment_Limit: "No Payment Limit",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal Deposit",
  No_Deposits_Word:"Add the first company <a routerLink='/folder/Deposit/New'>Deposit</a>.",
  No_Documents_Specified: "No documents were specified for printing",
  No_Printers_Added: "No printers were found. Go to Settings > Printers to add one.",
  DB_Erase_Prompt: "Completely erase the entire database? WARNING: You will lose all stored information!",
  Console_Warning: "Do not paste any text into this console. You may put yourself and/or your company at serious risk.",
  No_Faxes_Word: "Create the first <a routerLink='/folder/Fax/New'>Fax</a>.",
  Cheque_Delete_Confirmation:"Are you sure you want to delete this cheque?",
  Design_Delete_Confirmation:"Are you sure you want to delete this design?",
  Cheque_Pay_Confirmation:"Mark this cheque as paid? It will NOT appear in the print queue.",
  Payment_Pay_Confirmation:"Mark this payment as paid? It will NOT appear in the cheque queue.",
  Delete_Deduction_Confirmation:"Are you sure you want to delete this deduction?",
  Delete_Job_Confirmation:"Are you sure you want to delete this job?",
  Delete_Timesheet_Confirmation:"Are you sure you want to delete this timesheet?",
  Delete_Schedule_Confirmation:"Are you sure you want to delete this schedule?",
  Delete_Setting_Confirmation:"Are you sure you want to reset this setting?",
  Delete_Fax_Confirmation:"Are you sure you want to delete this fax?",
  Delete_File_Confirmation:"Are you sure you want to delete this file?",
  Delete_Vacation_Confirmation:"Are you sure you want to delete this vacation?",
  Delete_Printer_Confirmation:"Are you sure you want to delete this printer?",
  Remove_Design_Confirmation:"Are you sure you want to delete this design?",
  Delete_Payroll_Confirmation:"Are you sure you want to delete this payroll?",
  Send_Fax_Email_Confirmation:"Do you want to fax and email these documents?",
  Send_Email_Confirmation:"Do you want to email this document?",
  Send_Fax_Confirmation:"Do you want to fax this document?",
  Error_Generating_PDF: "Sorry. There was an error generating this document.",
  PDF_Error_Saving_Image: "Sorry. There was an error saving PDF image of this document.",
  Test_Printer_Confirmation:"Do you want to print a test page on this printer?",
  Save_Timesheet_Prompt:"Please add a 'Title' or press 'Start Timer' to save.",
  Remove_Geofence_Prompt: "Are you sure you want to remove the location of this geo-fence?",
  Delete_Employee_Confirmation:"Are you sure you want to delete ",
  Fire_Employee_Confirmation:"Are you sure you want to fire "
}