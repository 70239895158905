export const Kn = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"ಹಕ್ಕುಸ್ವಾಮ್ಯ &ನಕಲು; 2023",
  black:"ಕಪ್ಪು",
  green:"ಹಸಿರು",
  gold:"ಚಿನ್ನ",
  blue:"ನೀಲಿ",
  brown:"ಕಂದು",
  purple:"ನೇರಳೆ",
  pink:"ಗುಲಾಬಿ",
  red:"ಕೆಂಪು",
  Swatches:"ಸ್ವಾಚ್ಗಳು",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"ವರ್ಣ",
  Saturation:"ಶುದ್ಧತ್ವ",
  Lightness:"ಲಘುತೆ",
  Upgrade_To_Pro:"ಪ್ರೊಗೆ ಅಪ್ಗ್ರೇಡ್ ಮಾಡಿ",
  AllFeaturesInclude:"ಎಲ್ಲಾ ವೈಶಿಷ್ಟ್ಯಗಳು ಸೇರಿವೆ:",
  PrintUnlimitedCheques:"ಅನಿಯಮಿತ ಚೆಕ್‌ಗಳನ್ನು ಮುದ್ರಿಸಿ",
  PremiumChequeDesigns:"ಪ್ರೀಮಿಯಂ ಚೆಕ್ ವಿನ್ಯಾಸಗಳು",
  ManageUnlimitedEmployees:"ಅನಿಯಮಿತ ಉದ್ಯೋಗಿಗಳನ್ನು ನಿರ್ವಹಿಸಿ",
  AddUnlimitedPayees:"ಅನಿಯಮಿತ ಪಾವತಿದಾರರನ್ನು ಸೇರಿಸಿ",
  CreateUnlimitedPayrolls:"ಅನಿಯಮಿತ ವೇತನದಾರರ ಪಟ್ಟಿಗಳನ್ನು ರಚಿಸಿ",
  UnlimitedSchedulesandTimeSheets:"ಅನಿಯಮಿತ ವೇಳಾಪಟ್ಟಿಗಳು ಮತ್ತು ಸಮಯ ಹಾಳೆಗಳು",
  BulkPayPalPayouts:"ಬೃಹತ್ ಪೇಪಾಲ್ ಪಾವತಿಗಳು",
  UnlimitedBankAccounts:"ಅನಿಯಮಿತ ಬ್ಯಾಂಕ್ ಖಾತೆಗಳು",
  ManageMultipleCompanies:"ಬಹು ಕಂಪನಿಗಳನ್ನು ನಿರ್ವಹಿಸಿ",
  TrackInsurancePolicies:"ವಿಮಾ ಪಾಲಿಸಿಗಳನ್ನು ಟ್ರ್ಯಾಕ್ ಮಾಡಿ",
  Bio_MetricProtection:"ಬಯೋ-ಮೆಟ್ರಿಕ್ ರಕ್ಷಣೆ",
  Geo_FenceLock_OutProtection:"ಜಿಯೋ-ಬೇಲಿ ಲಾಕ್-ಔಟ್ ರಕ್ಷಣೆ",
  Multiple_Companies_Word:"ಚೆಕ್ ಪ್ರೀಮಿಯಂ ಇಲ್ಲದೆ ಬಹು ಕಂಪನಿಗಳನ್ನು ನಿರ್ವಹಿಸುವುದು ಲಭ್ಯವಿಲ್ಲ.",
  PayPal_Payouts_Word:"ಚೆಕ್ ಪ್ರೀಮಿಯಂ ಇಲ್ಲದೆ PayPal ಪಾವತಿಗಳನ್ನು ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಲಾಗಿದೆ.",
  PINProtection:"ಪಿನ್ ರಕ್ಷಣೆ",
  PasswordProtection:"ಪಾಸ್ವರ್ಡ್ ರಕ್ಷಣೆ",
  May_Require_Approval:"ಅನುಮೋದನೆ ಬೇಕಾಗಬಹುದು.",
  Subscribe:"ಚಂದಾದಾರರಾಗಿ",
  Billed:"ಬಿಲ್ ಮಾಡಲಾಗಿದೆ",
  Up:"ಮೇಲಕ್ಕೆ",
  Down:"ಕೆಳಗೆ",
  Positioning:"ಸ್ಥಾನೀಕರಣ",
  Marker:"ಮಾರ್ಕರ್",
  Drag_Marker:"ಮಾರ್ಕರ್ ಅನ್ನು ಎಳೆಯಿರಿ",
  Tagline:"ಚೆಕ್‌ಗಳನ್ನು ಮುದ್ರಿಸಿ ಮತ್ತು ವೇತನದಾರರ ಪಟ್ಟಿಯನ್ನು ನೀಡಿ",
  Marker_Word:"ಅಂಶವನ್ನು ಗಾತ್ರಗೊಳಿಸಲು ಮಾರ್ಕರ್‌ಗಳನ್ನು ಬಳಸಿ.",
  Pinch_Zoom:"ಪಿಂಚ್ ಜೂಮ್",
  Pinch_Word:"ಅಂಶವನ್ನು ಜೂಮ್ ಮಾಡಲು ಪಿಂಚ್ ಮಾಡಿ.",
  Drag:"ಎಳೆಯಿರಿ",
  Drag_Word:"ಅಂಶಗಳನ್ನು ಎಳೆಯಲು ನಿಮ್ಮ ಬೆರಳನ್ನು ಬಳಸಿ.",
  subscription_alias_word:"ಸ್ವಯಂ ನವೀಕರಣ ಚಂದಾದಾರಿಕೆ",
  premium_alias_word:"ಒನ್-ಟೈಮ್ ಅಪ್‌ಗ್ರೇಡ್ ಪ್ಯಾಕೇಜ್",
  print_alias_word:"ವೈಯಕ್ತಿಕ ತಪಾಸಣೆಗಳನ್ನು ಮುದ್ರಿಸು",
  mode_alias_word:"ಮೋಡ್",
  Pro:"ಪ್ರೊ",
  Pro_word:"ಪ್ರೊ ಆವೃತ್ತಿ ಅಗತ್ಯವಿದೆ.",
  Cant_Delete_Default_Company_Word:"ಕ್ಷಮಿಸಿ, ನಿಮ್ಮ ಡೀಫಾಲ್ಟ್ ಕಂಪನಿಯನ್ನು ನೀವು ಅಳಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ .",
  Reinsert_Default_Designs:"ಡೀಫಾಲ್ಟ್ ವಿನ್ಯಾಸಗಳನ್ನು ಮರು-ಸೇರಿಸಿ",
  Reinsert_Default_Designs_word:"ನೀವು ಡೀಫಾಲ್ಟ್ ವಿನ್ಯಾಸಗಳನ್ನು ಮರು-ಸೇರಿಸಲು ಬಯಸುವಿರಾ?",
  Subscription_Active_Disable_Word:"ಈ ಚಂದಾದಾರಿಕೆ ಸಕ್ರಿಯವಾಗಿದೆ. ಚೆಕ್‌ಗಳಿಗೆ ಈ ಚಂದಾದಾರಿಕೆಯನ್ನು ರದ್ದುಗೊಳಿಸಲು ನೀವು ಬಯಸುವಿರಾ?",
  Company_Logo:"ಕಂಪನಿ ಲೋಗೋ",
  ZERO_:"ಶೂನ್ಯ",
  Disclaimer:"ಹಕ್ಕು ನಿರಾಕರಣೆ",
  Privacy_Policy:"ಗೌಪ್ಯತಾ ನೀತಿ",
  EULA:"EULA ಪರಿಶೀಲಿಸುತ್ತದೆ",
  Terms_Of_Service:"ಸೇವಾ ನಿಯಮಗಳು",
  Terms_Of_Use:"ಬಳಕೆಯ ನಿಯಮಗಳು",
  Refunds:"ಮರುಪಾವತಿ ನೀತಿ",
  Single_Print:"1 ಪರಿಶೀಲಿಸಿ",
  Two_Prints:"2 ಪರಿಶೀಲನೆಗಳು",
  Five_Prints:"5 ಪರಿಶೀಲನೆಗಳು",
  Ten_Prints:"10 ಚೆಕ್",
  Fifteen_Prints:"15 ಚೆಕ್",
  Twenty_Prints:"20 ಚೆಕ್",
  Thirty_Prints:"30 ಚೆಕ್",
  Image_Added:"ಚಿತ್ರ ಸೇರಿಸಲಾಗಿದೆ",
  Image_Preview:"ಚಿತ್ರ ಪೂರ್ವವೀಕ್ಷಣೆ",
  No_Image_Was_Selected:"ಯಾವುದೇ ಚಿತ್ರವನ್ನು ಆಯ್ಕೆ ಮಾಡಲಾಗಿಲ್ಲ.",
  Cheques_Unlimited:"ಅನಿಯಮಿತ ತಪಾಸಣೆ",
  _Subscription:"ಚಂದಾದಾರಿಕೆ",
  Subscription:"ಚೆಕ್ - 1 ತಿಂಗಳು",
  Two_Month_Subscription:"ಪರಿಶೀಲನೆಗಳು - 2 ತಿಂಗಳುಗಳು",
  Three_Month_Subscription:"ಪರಿಶೀಲನೆಗಳು - 3 ತಿಂಗಳುಗಳು",
  Six_Month_Subscription:"ಪರಿಶೀಲನೆಗಳು - 6 ತಿಂಗಳುಗಳು",
  Twelve_Month_Subscription:"ಪರಿಶೀಲನೆಗಳು - 12 ತಿಂಗಳುಗಳು",
  Cheques_Are_Available:"ಮುದ್ರಿಸಲು ಚೆಕ್‌ಗಳು ಲಭ್ಯವಿವೆ.",
  Upgrade_Required_Two:"ಪ್ಯಾಕೇಜ್ ಅನ್ನು ಆಯ್ಕೆ ಮಾಡಿ ಮತ್ತು ನಿಮ್ಮ ಖರೀದಿಯನ್ನು ಪ್ರಾರಂಭಿಸಲು ಬೆಲೆ ಬಟನ್ ಮೇಲೆ ಡಬಲ್-ಟ್ಯಾಪ್ ಮಾಡಿ. ಸೆಕೆಂಡುಗಳಲ್ಲಿ ವೃತ್ತಿಪರವಾಗಿ ಕಾಣುವ ಪೂರ್ಣ-ಬಣ್ಣದ ಚೆಕ್‌ಗಳನ್ನು ಮುದ್ರಿಸಿ.",
  Month:"ತಿಂಗಳು",
  Due:"ಬಾಕಿ:",
  Expires:"ಅವಧಿ ಮುಗಿಯುತ್ತದೆ:",
  Subscription_Active:"ಚಂದಾದಾರಿಕೆ ಸಕ್ರಿಯವಾಗಿದೆ",
  Subscription_Inactive:"ಚಂದಾದಾರಿಕೆ ನಿಷ್ಕ್ರಿಯವಾಗಿದೆ",
  Purchase_Additional_Cheques:"ಹೆಚ್ಚುವರಿ ಚೆಕ್‌ಗಳನ್ನು ಖರೀದಿಸುವುದೇ?",
  Printable_Cheque:"ಮುದ್ರಿಸಬಹುದಾದ ಚೆಕ್",
  Printable_Cheques:"ಮುದ್ರಿಸಬಹುದಾದ ಚೆಕ್‌ಗಳು",
  Printable_Cheque_Word:"ಚೆಕ್ ನಿಮ್ಮ ಖಾತೆಯಲ್ಲಿ ಲಭ್ಯವಿದೆ.",
  Printable_Cheques_Word:"ನಿಮ್ಮ ಖಾತೆಯಲ್ಲಿ ಚೆಕ್‌ಗಳು ಲಭ್ಯವಿವೆ.",
  Max_Amount_Message:"ನೀವು ನಿರ್ದಿಷ್ಟಪಡಿಸಿದ ಮೊತ್ತವು ಈ ಸಿಸ್ಟಂಗಾಗಿ ಹೊಂದಿಸಲಾದ ಗರಿಷ್ಠ ಮೊತ್ತವನ್ನು ತಲುಪಿದೆ:",
  Terms_Required_Word:"ಚೆಕ್‌ಗಳನ್ನು ಬಳಸುವುದನ್ನು ಮುಂದುವರಿಸುವ ಮೊದಲು ನೀವು ಈ ಒಪ್ಪಂದಕ್ಕೆ ಸಮ್ಮತಿಸಬೇಕು.",
  Subscriptions:"ಚಂದಾದಾರಿಕೆಗಳು",
  Product_Upgrades:"ನವೀಕರಣಗಳು",
  Mailed_Out_Cheques:"ಮೇಲ್ ಮಾಡಿದ ಚೆಕ್‌ಗಳು",
  Enter_A_Company_Name:"ದಯವಿಟ್ಟು ಕಂಪನಿಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ.",
  Single_Cheques:"ಏಕ ತಪಾಸಣೆಗಳು",
  Cheque_Golden:"ಗೋಲ್ಡನ್ ಚೆಕ್",
  Cheque_Golden_Window:"ಗೋಲ್ಡನ್ ಚೆಕ್ ವಿನ್ಯಾಸ.",
  Cheque_Green:"ಹಸಿರು ಚೆಕ್",
  Cheque_Green_Window:"ಹಸಿರು ಚೆಕ್ ವಿನ್ಯಾಸ.",
  Cheque_Red:"ಕೆಂಪು ಚೆಕ್",
  Cheque_Red_Window:"ಕೆಂಪು ಚೆಕ್ ವಿನ್ಯಾಸ.",
  Cheque_Yellow:"ಹಳದಿ ಚೆಕ್",
  Cheque_Yellow_Window:"ಹಳದಿ ಚೆಕ್ ವಿನ್ಯಾಸ.",
  Cheque_Statue_of_Liberty:"ಸ್ಟ್ಯಾಚ್ಯೂ ಆಫ್ ಲಿಬರ್ಟಿ",
  Cheque_Statue_of_Liberty_Window:"ಸ್ಟ್ಯಾಚ್ಯೂ ಆಫ್ ಲಿಬರ್ಟಿ ಚೆಕ್ ವಿನ್ಯಾಸ.",
  Cheque_Green_Wave:"ಹಸಿರು ಅಲೆ",
  Cheque_Green_Wave_Window:"ಹಸಿರು ಚೆಕ್ ವಿನ್ಯಾಸ.",
  Cheque_Golden_Weave:"ಗೋಲ್ಡನ್ ನೇಯ್ಗೆ",
  Cheque_Golden_Weave_Window:"ಸೊಗಸಾದ ಗೋಲ್ಡನ್ ಚೆಕ್ ವಿನ್ಯಾಸ.",
  Cheque_Green_Sun:"ಹಸಿರು ಸೂರ್ಯ",
  Cheque_Green_Sun_Window:"ಆಳವಾದ ಮತ್ತು ಶಾಂತವಾದ ಚೆಕ್ ವಿನ್ಯಾಸ.",
  Cheque_Blue_Checkers:"ನೀಲಿ ಚೆಕರ್ಸ್",
  Cheque_Blue_Checkers_Window:"ನೀಲಿ ಚೆಕ್ ವಿನ್ಯಾಸ.",
  Cashiers_Check:"ಕ್ಯಾಷಿಯರ್ ಚೆಕ್",
  Cashiers_Check_Window:"ಕ್ಯಾಷಿಯರ್ ಚೆಕ್ ಶೈಲಿಯ ಟೆಂಪ್ಲೇಟ್.",
  Cheque_Aqua_Checkers:"ಆಕ್ವಾ ಚೆಕರ್ಸ್",
  Cheque_Aqua_Checkers_Window:"ಆಕ್ವಾ ಚೆಕ್ ವಿನ್ಯಾಸ.",
  Cheque_Golden_Checkers:"ಗೋಲ್ಡನ್ ಚೆಕರ್ಸ್",
  Cheque_Golden_Checkers_Window:"ಗೋಲ್ಡನ್ ಚೆಕ್ ವಿನ್ಯಾಸ.",
  Upgrade_Unavailable:"ನವೀಕರಣಗಳು ಲಭ್ಯವಿಲ್ಲ",
  Bank_Code_Protection:"ಬ್ಯಾಂಕ್ ಸಂಖ್ಯೆ ರಕ್ಷಣೆ",
  Bank_Code_Protection_Word:"ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಸಂಖ್ಯೆಗಳನ್ನು ಮತ್ತೊಂದು ಸಾಧನದಲ್ಲಿ ಅಥವಾ ಇನ್ನೊಬ್ಬ ಬಳಕೆದಾರರಿಗಾಗಿ ಚಾಲನೆಯಲ್ಲಿರುವ ಈ ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿ ಬಳಸದಂತೆ ರಕ್ಷಿಸಿ. ಈ ಕ್ರಿಯೆಯು ಹಿಂತಿರುಗಿಸಲಾಗದು. ಮುಂದುವರಿಸುವುದೇ?",
  Bank_Code_Protection_Blocked_Word:"ನೀವು ಬಳಸಲು ಪ್ರಯತ್ನಿಸುತ್ತಿರುವ ಬ್ಯಾಂಕ್ ಸಂಖ್ಯೆಗಳನ್ನು ಇನ್ನೊಬ್ಬ ಬಳಕೆದಾರ ಅಥವಾ ಸಾಧನಕ್ಕಾಗಿ ಕಾಯ್ದಿರಿಸಲಾಗಿದೆ.",
  Bank_Code_Protection_Error_Word:"ಸಂಖ್ಯೆ ಪರಿಶೀಲನೆ ವಿಫಲವಾಗಿದೆ. ದಯವಿಟ್ಟು ಇಂಟರ್ನೆಟ್‌ಗೆ ಸಂಪರ್ಕಪಡಿಸಿ ಮತ್ತು ಈ ಬ್ಯಾಂಕ್ ಖಾತೆಯನ್ನು ಮತ್ತೆ ಸೇರಿಸಲು ಪ್ರಯತ್ನಿಸಿ.",
  Bank_Code_Protection_Set_Error_Word:"ಬ್ಯಾಂಕ್ ಸಂಖ್ಯೆ ರಕ್ಷಣೆಗೆ ನೀವು ಇಂಟರ್ನೆಟ್‌ಗೆ ಸಂಪರ್ಕ ಹೊಂದಿರಬೇಕು. ದಯವಿಟ್ಟು ಸಂಪರ್ಕಿಸಿ ಮತ್ತು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
  Upgrade_Unavailable_Word:"ಕ್ಷಮಿಸಿ, ನಿಮ್ಮನ್ನು ಪರಿಶೀಲಿಸುವಲ್ಲಿ ನಮಗೆ ತೊಂದರೆಯಾಗುತ್ತಿದೆ. ಚಂದಾದಾರಿಕೆಗಳು ಮತ್ತು ಚೆಕ್‌ಗಳಿಗೆ ಅಪ್‌ಗ್ರೇಡ್‌ಗಳು ಪ್ರಸ್ತುತ ನಿಮ್ಮ ಪ್ರದೇಶದಲ್ಲಿ ಖರೀದಿಗೆ ಲಭ್ಯವಿಲ್ಲ.",
  PayPal_Payment_Preview:"ಪೇಪಾಲ್ ಪಾವತಿ ಪೂರ್ವವೀಕ್ಷಣೆ",
  Apple_Pay:"ಆಪಲ್ ಪೇ",
  Select_PayPal:"ಪೇಪಾಲ್ ಆಯ್ಕೆಮಾಡಿ",
  PayPal_Applications:"ಪೇಪಾಲ್ ಅಪ್ಲಿಕೇಶನ್‌ಗಳು",
  Purchase_With_Apple_Pay:"Apple Pay ಮೂಲಕ ಖರೀದಿಸಿ",
  Google_Pay:"Google Pay",
  Companies:"ಕಂಪನಿಗಳು",
  Insurance:"ವಿಮೆ",
  New_PayPal:"ಹೊಸ ಪೇಪಾಲ್",
  Pay_By:"ಮೂಲಕ ಪಾವತಿಸಿ",
  Insure:"ವಿಮೆ ಮಾಡಿ",
  Miles:"ಮೈಲಿಗಳು",
  Payment_Method:"ಪಾವತಿ ವಿಧಾನ",
  Select_Policies:"ನೀತಿಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  Policies:"ನೀತಿಗಳು",
  Policy:"ನೀತಿ",
  No_PayPal_Account:"ಪೇಪಾಲ್ ಖಾತೆ ಇಲ್ಲ",
  No_Policies:"ವಿಮಾ ಪಾಲಿಸಿಗಳಿಲ್ಲ",
  New_Policy:"ಹೊಸ ನೀತಿ",
  PayPal_Payment:"ಪೇಪಾಲ್ ಪಾವತಿ",
  PayPal_Payments:"ಪೇಪಾಲ್ ಪಾವತಿಗಳು",
  No_Payment_Selected:"ಯಾವುದೇ ಪಾವತಿಯನ್ನು ಆಯ್ಕೆ ಮಾಡಲಾಗಿಲ್ಲ",
  Sending_Payment_Word:"ದಯವಿಟ್ಟು ನಿರೀಕ್ಷಿಸಿ... ಈ ಪಾವತಿಯನ್ನು ಕಳುಹಿಸಲಾಗುತ್ತಿದೆ.",
  Sending_Payments_Word:"ದಯವಿಟ್ಟು ನಿರೀಕ್ಷಿಸಿ... ಪಾವತಿಗಳನ್ನು ಕಳುಹಿಸಲಾಗುತ್ತಿದೆ.",
  No_Payment_Selected_PayPal:"ಯಾವುದೇ <a routerLink='/folder/Payments'>PayPal ಪಾವತಿ</a> ಆಯ್ಕೆಮಾಡಲಾಗಿಲ್ಲ.",
  Payment_Sent:"ಪಾವತಿ ಕಳುಹಿಸಲಾಗಿದೆ",
  PayPal_Payment_Sent:"ಈ ಪಾವತಿಯನ್ನು PayPal ನೊಂದಿಗೆ ಕಳುಹಿಸಲಾಗಿದೆ.",
  Copay:"ನಕಲು ಮಾಡಿ",
  Dead:"ಸತ್ತ",
  Alive:"ಜೀವಂತವಾಗಿ",
  Not_Dead:"ಸತ್ತಿಲ್ಲ",
  Unclaimed:"ಹಕ್ಕು ಪಡೆಯದ",
  Attempted:"ಪ್ರಯತ್ನಿಸಿದೆ",
  Deceased:"ಮೃತನಾದ",
  Claimed:"ಹಕ್ಕು ಪಡೆದಿದ್ದಾರೆ",
  Unpaid:"ಪಾವತಿಸದ",
  Sending_Payment:"ಪಾವತಿ ಕಳುಹಿಸಲಾಗುತ್ತಿದೆ",
  Sending_Payments:"ಪಾವತಿಗಳನ್ನು ಕಳುಹಿಸಲಾಗುತ್ತಿದೆ",
  Send_PayPal_Confirmation:"ನೀವು ಈ ವಹಿವಾಟನ್ನು PayPal ನೊಂದಿಗೆ ಕಳುಹಿಸಲು ಬಯಸುವಿರಾ?",
  Send_PayPal_Confirmation_Word:"ಈ ವಹಿವಾಟನ್ನು ಕಳುಹಿಸಲು ಹಸಿರು ಬಟನ್ ಒತ್ತಿರಿ.",
  Save_Payment_As_Unpaid:"ಈ ಪಾವತಿಯನ್ನು ಪಾವತಿಸದಿರುವಂತೆ ಉಳಿಸುವುದೇ?",
  Payment_Pay_Confirmation_PayPal:"ಈ ಪಾವತಿಯನ್ನು ಪಾವತಿಸಿದಂತೆ ಉಳಿಸುವುದೇ?",
  No_Policies_Word:"ಮೊದಲ <a routerLink='/folder/Postage/New'>ವಿಮಾ ನೀತಿ</a> ಅನ್ನು ಈಗಲೇ ಸೇರಿಸಿ .",
  Timesheet_Multiple_Delete_Confirmation:"ಬಹು ಟೈಮ್‌ಶೀಟ್‌ಗಳನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Select_Multiple_Timesheets_Prompt:"ಯಾವುದೇ ಟೈಮ್‌ಶೀಟ್‌ಗಳನ್ನು ಆಯ್ಕೆಮಾಡಲಾಗಿಲ್ಲ. ಕನಿಷ್ಠ ಒಂದು ಟೈಮ್‌ಶೀಟ್ ಆಯ್ಕೆಮಾಡಿ.",
  Select_Multiple_Policies_Prompt:"ಯಾವುದೇ ನೀತಿಗಳನ್ನು ಆಯ್ಕೆಮಾಡಲಾಗಿಲ್ಲ. ಕನಿಷ್ಠ ಒಂದು ವಿಮಾ ಪಾಲಿಸಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ.",
  Policies_Multiple_Delete_Confirmation:"ಬಹು ನೀತಿಗಳನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Company:"ಕಂಪನಿ",
  Add_Company:"ಕಂಪನಿ ಸೇರಿಸಿ",
  New_Company:"ಕಂಪನಿ ಸೇರಿಸಿ",
  No_Companies:"ಯಾವುದೇ ಕಂಪನಿಗಳಿಲ್ಲ",
  Enable_Company:"ಕಂಪನಿಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Select_Company:"ಕಂಪನಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  The_Default_Company:"ಡೀಫಾಲ್ಟ್ ಕಂಪನಿ ಲೇಬಲ್.",
  Manage_Companies:"ಕಂಪನಿಗಳನ್ನು ನಿರ್ವಹಿಸಿ",
  No_Companies_Word:"ಪರಿಶೀಲನೆಗಳು ಡೀಫಾಲ್ಟ್ ಕಂಪನಿಯನ್ನು ಬಳಸುತ್ತವೆ .<br /> <a routerLink='/folder/Company/New'>ಮೊದಲ ಕಂಪನಿ</a> ಅನ್ನು ಸೇರಿಸಿ .",
  Default_Company:"ಡೀಫಾಲ್ಟ್ ಕಂಪನಿ",
  Cheques_Unlimited_Word:"ಚೆಕ್ಸ್ ಅನ್‌ಲಿಮಿಟೆಡ್ ನಿಮಗೆ ಇಷ್ಟವಾದಷ್ಟು ಚೆಕ್‌ಗಳನ್ನು ಮುದ್ರಿಸಲು ಅನುಮತಿಸುತ್ತದೆ.",
  Cheques_Subscription_Word:"ಚೆಕ್‌ಗಳ ಚಂದಾದಾರಿಕೆಯು ನೀವು ಇಷ್ಟಪಡುವಷ್ಟು ಚೆಕ್‌ಗಳನ್ನು ಮುದ್ರಿಸಲು ಅನುಮತಿಸುತ್ತದೆ.",
  You_Own_This_Product:"ನೀವು ಈ ಉತ್ಪನ್ನವನ್ನು ಹೊಂದಿದ್ದೀರಿ.",
  Your_Subscription_is_Active:"ನಿಮ್ಮ ಚಂದಾದಾರಿಕೆ ಸಕ್ರಿಯವಾಗಿದೆ.",
  Active_Products:"ಸಕ್ರಿಯ ಉತ್ಪನ್ನಗಳು",
  Purchase_Confirmation:"ಖರೀದಿ",
  Purchase_Cheques:"ಖರೀದಿ ಚೆಕ್‌ಗಳು",
  Restore_Purchase:"ಖರೀದಿಗಳನ್ನು ಮರುಸ್ಥಾಪಿಸಿ",
  Erase_Purchase:"ಖರೀದಿಗಳನ್ನು ಅಳಿಸಿ",
  Successfully_Purchased:"ಯಶಸ್ವಿಯಾಗಿ ಖರೀದಿಸಲಾಗಿದೆ",
  Enter_Your_Licence_Key:"ಈ ಉತ್ಪನ್ನವನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಲು ದಯವಿಟ್ಟು ಈ ಪುಟದಲ್ಲಿ ನಿಮ್ಮ ಪರವಾನಗಿ ಕೀಲಿಯನ್ನು ನಮೂದಿಸಿ.",
  Licence_Key:"ಪರವಾನಗಿ ಕೀಲಿ",
  Enter_Licence_Key:"ಪರವಾನಗಿ ಕೀಲಿಯನ್ನು ನಮೂದಿಸಿ",
  Purchased:"ಖರೀದಿಸಿದೆ",
  Enable_Feature:"ವೈಶಿಷ್ಟ್ಯವನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Cancel_Subscription:"ಚಂದಾದಾರಿಕೆಯನ್ನು ರದ್ದುಗೊಳಿಸಿ",
  Subscription_Removed:"ಚಂದಾದಾರಿಕೆಯನ್ನು ತೆಗೆದುಹಾಕಲಾಗಿದೆ",
  Select_Active_Subscription:"ಅದನ್ನು ಮಾರ್ಪಡಿಸಲು ಸಕ್ರಿಯ ಚಂದಾದಾರಿಕೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ.",
  Remove_Subscription_Word:"ಈ ಚಂದಾದಾರಿಕೆಯನ್ನು ರದ್ದುಗೊಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Delete_Company_Confirmation:"ಈ ಸಂಪೂರ್ಣ ಕಂಪನಿಯನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ? ಎಚ್ಚರಿಕೆ: ನೀವು ಸಂಗ್ರಹಿಸಿದ ಎಲ್ಲಾ ಮಾಹಿತಿಯನ್ನು ಕಳೆದುಕೊಳ್ಳುತ್ತೀರಿ!",
  Delete_Default_Company_Word:"ನೀವು ಡೀಫಾಲ್ಟ್ ಕಂಪನಿಯನ್ನು ಅಳಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ . ನೀವು ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಮರುಹೊಂದಿಸಲು ಮತ್ತು ಅದನ್ನು ಡೀಫಾಲ್ಟ್ ಸ್ಥಿತಿಗೆ ಮರುಸ್ಥಾಪಿಸಲು ಬಯಸುವಿರಾ? ಎಚ್ಚರಿಕೆ: ನೀವು ಸಂಗ್ರಹಿಸಿದ ಎಲ್ಲಾ ಮಾಹಿತಿಯನ್ನು ಕಳೆದುಕೊಳ್ಳುತ್ತೀರಿ!",
  Console_Warning_2:"ಪ್ರಸ್ತುತ ನಿಮ್ಮದಲ್ಲದ ಈ ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಬಳಸಿಕೊಂಡು ಯಾವುದೇ ಕರೆನ್ಸಿಯನ್ನು ನಿರ್ವಹಿಸಬೇಡಿ.",
  Terms_and_Conditions:"ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳು",
  and_the:"ಮತ್ತು",
  for:"ಫಾರ್",
  Please_Read_Word:"ದಯವಿಟ್ಟು ಓದಿ ಮತ್ತು ಒಪ್ಪಿಕೊಳ್ಳಿ",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"ಕೆಲವು ಕರೆನ್ಸಿ ಪರಿವರ್ತನೆ ದರಗಳು ಕಂಡುಬಂದಿಲ್ಲ. ದಯವಿಟ್ಟು ಇಂಟರ್ನೆಟ್‌ಗೆ ಸಂಪರ್ಕಪಡಿಸಿ.",
  Free:"ಉಚಿತ",
  DB_Erase_Prompt_2:"ಸಂಪೂರ್ಣ ಡೆವಲಪರ್ ಡೇಟಾಬೇಸ್ ಅನ್ನು ಸಂಪೂರ್ಣವಾಗಿ ಅಳಿಸುವುದೇ? ಎಚ್ಚರಿಕೆ: ನೀವು ಎಲ್ಲಾ ಖರೀದಿ ಮತ್ತು ಚಂದಾದಾರಿಕೆ ಮಾಹಿತಿಯನ್ನು ಕಳೆದುಕೊಳ್ಳುತ್ತೀರಿ!",
  Successfully_Imported:"ಯಶಸ್ವಿಯಾಗಿ ಆಮದು ಮಾಡಿಕೊಳ್ಳಲಾಗಿದೆ",
  Select_Postage:"ಅಂಚೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  No_Postage:"ಯಾವುದೇ ಅಂಚೆ ಚೀಟಿಗಳಿಲ್ಲ",
  No_Paid_Postage_Word:"ಮೊದಲ <a routerLink='/folder/Postage/New'>ಪಾವತಿಸಿದ ಅಂಚೆ</a> ಸ್ಟಾಂಪ್ ಸೇರಿಸಿ .",
  Trial_Complete:'ಪ್ರಯೋಗ ಪೂರ್ಣಗೊಂಡಿದೆ',
  Please_Upgrade:'ಮುದ್ರಣವನ್ನು ಮುಂದುವರಿಸಲು ಚೆಕ್‌ಗಳನ್ನು ಅಪ್‌ಗ್ರೇಡ್ ಮಾಡಿ.',
  Aa:"ಆ",
  Color:"ಬಣ್ಣ",
  Font:"ಫಾಂಟ್",
  Guide:"ಮಾರ್ಗದರ್ಶಿ",
  Guides:"ಮಾರ್ಗದರ್ಶಕರು",
  Image:"ಚಿತ್ರ",
  Zoom:"ಜೂಮ್ ಮಾಡಿ",
  Logo:"ಲೋಗೋ",
  Bank:"ಬ್ಯಾಂಕ್",
  MICR:"MICR",
  Total:"ಒಟ್ಟು",
  Cancel:"ರದ್ದುಮಾಡು",
  Confirm:"ದೃಢೀಕರಿಸಿ",
  Method:"ವಿಧಾನ",
  Biometric_Security:"ಬಯೋಮೆಟ್ರಿಕ್ ಭದ್ರತೆ",
  Please_Login_To_Continue:"ಮುಂದುವರಿಯಲು ದಯವಿಟ್ಟು ಲಾಗ್ ಇನ್ ಮಾಡಿ.",
  Complete:"ಸಂಪೂರ್ಣ",
  Sign_Up:"ಸೈನ್ ಅಪ್ ಮಾಡಿ",
  Error:"ದೋಷ",
  Biometrics:"ಬಯೋ-ಮೆಟ್ರಿಕ್ಸ್",
  Percent:"ಶೇಕಡಾ",
  Zero_Percent:"0%",
  Top_Margin:"ಟಾಪ್ ಮಾರ್ಜಿನ್",
  Bottom_Margin:"ಕೆಳಭಾಗದ ಅಂಚು",
  Left_Margin:"ಎಡ ಅಂಚು",
  Right_Margin:"ಬಲ ಅಂಚು",
  MICR_Margin:"MICR ಅಂಚು",
  Table_Margin:"ಟೇಬಲ್ ಅಂಚು",
  Address_Margin:"ವಿಳಾಸ ಅಂಚು",
  Percentage_:"ಶೇ",
  Vacation_Title:"ರಜೆಯ ಶೀರ್ಷಿಕೆ",
  Use_PIN:"ಪಿನ್ ಬಳಸಿ",
  Loading__:"ಲೋಡ್ ಆಗುತ್ತಿದೆ...",
  Design_Title:"ವಿನ್ಯಾಸ ಶೀರ್ಷಿಕೆ",
  Authorize:"ಅಧಿಕಾರ ನೀಡಿ",
  Key:"ಕೀ",
  Public_Key:"ಸಾರ್ವಜನಿಕ ಕೀ",
  Private_Key:"ಖಾಸಗಿ ಕೀ",
  Authenticate:"ಪ್ರಮಾಣೀಕರಿಸಿ",
  Last_Payroll:"ಕೊನೆಯ ವೇತನದಾರರ ಪಟ್ಟಿ",
  Last_Calculation:"ಕೊನೆಯ ಲೆಕ್ಕಾಚಾರ",
  Authorized:"ಅಧಿಕೃತಗೊಳಿಸಲಾಗಿದೆ",
  Geo_Authorized:"ಜಿಯೋ-ಸ್ಥಳ: ಅಧಿಕೃತ",
  Not_Authorized:"ಅಧಿಕೃತವಾಗಿಲ್ಲ",
  Authorization_Complete:"ದೃಢೀಕರಣ ಪೂರ್ಣಗೊಂಡಿದೆ",
  Geolocation_Authorization:"ಜಿಯೋ-ಸ್ಥಳದ ಅಧಿಕಾರ",
  Out_of_Bounds:"ಮಿತಿ ಮೀರಿದೆ",
  Cant_Delete_Default_Design:"ಡಿಫಾಲ್ಟ್ ವಿನ್ಯಾಸವನ್ನು ಅಳಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ.",
  Unauthorized_Out_of_Bounds:"ಅನಧಿಕೃತ: ಮಿತಿ ಮೀರಿದೆ",
  Biometric_Authorization:"ಬಯೋ-ಮೆಟ್ರಿಕ್ ಅಧಿಕಾರ",
  Locating_Please_Wait:"ಪತ್ತೆ ಮಾಡಲಾಗುತ್ತಿದೆ, ದಯವಿಟ್ಟು ನಿರೀಕ್ಷಿಸಿ...",
  Test_Biometrics:"ಬಯೋ-ಮೆಟ್ರಿಕ್ಸ್ ಪರೀಕ್ಷೆ",
  Cheque_Margins:"ಅಂಚುಗಳನ್ನು ಪರಿಶೀಲಿಸಿ",
  Percentage_Full_Error:"ಶೇಕಡಾವಾರು ಕ್ಷೇತ್ರವನ್ನು ಶೇಕಡಾ 100 ಕ್ಕಿಂತ ಹೆಚ್ಚು ಹೊಂದಿಸಲಾಗುವುದಿಲ್ಲ.",
  No_Payroll_Text:"<a routerLink='/folder/Employee/New'>ಉದ್ಯೋಗಿ</a> ಅಥವಾ <a routerLink='/folder/Payee/New'>ಪಾವತಿದಾರ</a> ಮತ್ತು <a routerLink='/folder/Schedule/New'>ವೇಳಾಪಟ್ಟಿ</a>.",
  Design_Saved:"ವಿನ್ಯಾಸವನ್ನು ಉಳಿಸಲಾಗಿದೆ",
  Default_Design_Selected:"ಡೀಫಾಲ್ಟ್ ವಿನ್ಯಾಸವನ್ನು ಆಯ್ಕೆಮಾಡಲಾಗಿದೆ",
  Partial_Import:"ಭಾಗಶಃ ಆಮದು",
  Partial_Export:"ಭಾಗಶಃ ರಫ್ತು",
  Entire_Import:"ಸಂಪೂರ್ಣ ಆಮದು",
  Entire_Export:"ಸಂಪೂರ್ಣ ರಫ್ತು",
  Existing_Password:"ದಯವಿಟ್ಟು ನಿಮ್ಮ ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ನಮೂದಿಸಿ:",
  Existing_PIN:"ದಯವಿಟ್ಟು ನಿಮ್ಮ ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಪಿನ್ ಕೋಡ್ ನಮೂದಿಸಿ:",
  Pin_Change_Header:"ಪಿನ್ ದೃಢೀಕರಣ",
  Pin_Change_SubHeader:"ಬದಲಾವಣೆಯನ್ನು ಖಚಿತಪಡಿಸಲು ನಿಮ್ಮ ಹಳೆಯ ಪಿನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ.",
  Pass_Change_Header:"ಪಾಸ್ವರ್ಡ್ ದೃಢೀಕರಣ",
  Pass_Change_SubHeader:"ಬದಲಾವಣೆಯನ್ನು ಖಚಿತಪಡಿಸಲು ನಿಮ್ಮ ಹಳೆಯ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ನಮೂದಿಸಿ.",
  PIN_Enter_Message:"ಖಚಿತಪಡಿಸಲು ನಿಮ್ಮ ಪಿನ್ ನಮೂದಿಸಿ.",
  Password_Enter_Message:"ಖಚಿತಪಡಿಸಲು ನಿಮ್ಮ ಗುಪ್ತಪದವನ್ನು ನಮೂದಿಸಿ.",
  Pin_Updated_Success:"ಪಿನ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ!",
  Pin_Updated_Fail:"ಪಿನ್ ಅನ್ನು ನವೀಕರಿಸಲಾಗಲಿಲ್ಲ.",
  Pass_Updated_Success:"ಪಾಸ್ವರ್ಡ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ.",
  Pass_Updated_Fail:"ಪಾಸ್ವರ್ಡ್ ಅನ್ನು ನವೀಕರಿಸಲಾಗಲಿಲ್ಲ.",
  Preview_Payment:"ಪೂರ್ವವೀಕ್ಷಣೆ ಪಾವತಿ",
  Preview_Payroll:"ವೇತನದಾರರ ಪೂರ್ವವೀಕ್ಷಣೆ",
  No_Payments_Created:"ಯಾವುದೇ ಪಾವತಿಗಳನ್ನು ರಚಿಸಿರುವುದು ಕಂಡುಬಂದಿಲ್ಲ.",
  Payment_Preview:"ಪಾವತಿ ಪೂರ್ವವೀಕ್ಷಣೆ",
  Enable_Payee:"ಪಾವತಿಸುವವರನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Rendered:"ನಿರೂಪಿಸಿದರು",
  No_Email:"ಇಮೇಲ್ ಇಲ್ಲ",
  Setup_Cheques:"ಸೆಟಪ್ ಪರಿಶೀಲನೆಗಳು",
  name:"ಹೆಸರು",
  address:"ವಿಳಾಸ",
  address_2:"ವಿಳಾಸ ಸಾಲು 2",
  city:"ನಗರ",
  province:"ಪ್ರಾಂತ್ಯ",
  postal_code:"ಅಂಚೆ/ಪಿನ್ ಕೋಡ್",
  country:"ದೇಶ",
  username:"ಬಳಕೆದಾರ ಹೆಸರು",
  full_name:"ಪೂರ್ಣ ಹೆಸರು",
  birthday:"ಜನ್ಮದಿನ",
  email:"ಇಮೇಲ್",
  phone:"ದೂರವಾಣಿ",
  employees:"ನೌಕರರು",
  addresses:"ವಿಳಾಸಗಳು",
  payment_amount_limit:"ಪಾವತಿ ಮೊತ್ತದ ಮಿತಿ",
  total_limit:"ಒಟ್ಟು ಮಿತಿ",
  payees:"ಪಾವತಿದಾರರು",
  description:"ವಿವರಣೆ",
  address_line_one:"ವಿಳಾಸ ಸಾಲು ಒಂದು",
  address_line_two:"ವಿಳಾಸ ಸಾಲು ಎರಡು",
  image:"ಚಿತ್ರ",
  account_holder:"ಖಾತೆದಾರ",
  cheque_starting_id:"ಪ್ರಾರಂಭ ID ಪರಿಶೀಲಿಸಿ",
  transit_number:"ಸಾರಿಗೆ ಸಂಖ್ಯೆ",
  institution_number:"ಸಂಸ್ಥೆಯ ಸಂಖ್ಯೆ",
  designation_number:"ಹುದ್ದೆ ಸಂಖ್ಯೆ",
  account_number:"ಖಾತೆ ಸಂಖ್ಯೆ",
  currency:"ಕರೆನ್ಸಿ",
  signature:"ಸಹಿ",
  payment_payroll_limit:"ಪಾವತಿ ಮಿತಿ",
  total_limi:"ಒಟ್ಟು ಮಿತಿ",
  date:"ದಿನಾಂಕ",
  printed_memo:"ಮುದ್ರಿತ ಮೆಮೊ",
  banks:"ಬ್ಯಾಂಕುಗಳು",
  signature_image:"ಸಹಿ ಚಿತ್ರ",
  address_name:"ವಿಳಾಸ ಹೆಸರು",
  notes:"ಟಿಪ್ಪಣಿಗಳು",
  design:"ವಿನ್ಯಾಸ",
  title:"ಶೀರ್ಷಿಕೆ",
  frequency:"ಆವರ್ತನ",
  fax:"ಫ್ಯಾಕ್ಸ್",
  salaries:"ಸಂಬಳಗಳು",
  salary_ids:"ಸಂಬಳ ಐಡಿಗಳು",
  start_time:"ಆರಂಭವಾಗುವ",
  end_time:"ಅಂತಿಮ ಸಮಯ",
  paid:"ಪಾವತಿಸಲಾಗಿದೆ",
  overtime_percentage:"ಪಾವತಿಸಿದ ಶೇ",
  overtime_amount:"ಪಾವತಿಸಿದ ಸ್ಥಿರ ಮೊತ್ತ",
  first_name:"ಮೊದಲ ಹೆಸರು",
  last_name:"ಕೊನೆಯ ಹೆಸರು",
  moderation:"ಮಿತಗೊಳಿಸುವಿಕೆ",
  create:"ರಚಿಸಿ",
  edit:"ತಿದ್ದು",
  destroy:"ನಾಶಮಾಡು",
  day_start_time:"ದಿನದ_ಪ್ರಾರಂಭದ_ಸಮಯ",
  day_end_time:"ದಿನದ_ಅಂತ್ಯ_ಸಮಯ",
  fullname:"ಹೆಸರು",
  time:"ಸಮಯ",
  auto_send:"ಸ್ವಯಂಚಾಲಿತವಾಗಿ ಕಳುಹಿಸಿ",
  time_method:"ಸಮಯದ ವಿಧಾನ",
  schedules:"ವೇಳಾಪಟ್ಟಿಗಳು",
  indefinite_payroll_enabled:"ಅನಿರ್ದಿಷ್ಟವಾಗಿ ಸಕ್ರಿಯಗೊಳಿಸಿ",
  amount:"ಮೊತ್ತ",
  repeat:"ಪುನರಾವರ್ತಿಸಿ",
  always_enabled:"ಯಾವಾಗಲೂ ಸಕ್ರಿಯಗೊಳಿಸಲಾಗಿದೆ",
  start_date:"ಪ್ರಾರಂಭ ದಿನಾಂಕ",
  end_date:"ಅಂತಿಮ ದಿನಾಂಕ",
  Cheque_Total:"ಒಟ್ಟು ಪರಿಶೀಲಿಸಿ",
  Total_Amount:"ಒಟ್ಟು ಮೊತ್ತ:",
  Amounts:"ಮೊತ್ತಗಳು:",
  Images:"ಚಿತ್ರಗಳು",
  Files:"ಕಡತಗಳನ್ನು",
  Previewing:"ಪೂರ್ವವೀಕ್ಷಣೆ:",
  Insert:"ಸೇರಿಸು",
  Preview_Import:"ಪೂರ್ವವೀಕ್ಷಣೆ ಆಮದು",
  Entry:"ಪ್ರವೇಶ",
  Entries:"ನಮೂದುಗಳು",
  No_Entries:"ಯಾವುದೇ ನಮೂದುಗಳಿಲ್ಲ",
  Import_Type:"ಆಮದು ಪ್ರಕಾರ",
  Select_Details:"ವಿವರಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  Select_Payee:"ಪಾವತಿಸುವವರನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  Enable_Holidays:"ರಜಾದಿನಗಳನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Disable_Holidays:"ರಜಾದಿನಗಳನ್ನು ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಿ",
  Wire_Transfer:"ತಂತಿ ವರ್ಗಾವಣೆ",
  New_Export:"ಹೊಸ ರಫ್ತು",
  Export_Data:"ಡೇಟಾವನ್ನು ರಫ್ತು ಮಾಡಿ",
  Export_Data_Word:"ರಫ್ತು ಮಾಡಲು ಮತ್ತು ಡೌನ್‌ಲೋಡ್ ಮಾಡಲು ಫೈಲ್ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ.",
  Export_File:"ಫೈಲ್ ಅನ್ನು ರಫ್ತು ಮಾಡಿ",
  Mode:"ಮೋಡ್",
  Times:"ಟೈಮ್ಸ್",
  Widgets:"ವಿಡ್ಗೆಟ್ಗಳು",
  Slider:"ಸ್ಲೈಡರ್",
  Set_Details:"ವಿವರಗಳನ್ನು ಹೊಂದಿಸಿ",
  Select_Type:"ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  Display_Slider:"ಸ್ಲೈಡರ್ ಅನ್ನು ಪ್ರದರ್ಶಿಸಿ",
  Dashboard_Slider:"ಡ್ಯಾಶ್‌ಬೋರ್ಡ್ ಸ್ಲೈಡರ್",
  Dashboard_Mode:"ಡ್ಯಾಶ್‌ಬೋರ್ಡ್ ಮೋಡ್",
  Show_Intro:"ಪರಿಚಯ ತೋರಿಸು",
  Show_Payrolls:"ವೇತನದಾರರ ಪಟ್ಟಿಗಳನ್ನು ತೋರಿಸಿ",
  Show_Holidays:"ರಜಾದಿನಗಳನ್ನು ತೋರಿಸಿ",
  Show_Invoices:"ಇನ್‌ವಾಯ್ಸ್‌ಗಳನ್ನು ತೋರಿಸಿ",
  Show_Cheques:"ಚೆಕ್‌ಗಳನ್ನು ತೋರಿಸಿ",
  Enabled_Widgets:"ಸಕ್ರಿಯಗೊಳಿಸಿದ ವಿಜೆಟ್‌ಗಳು",
  Disabled_Widgets:"ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಲಾದ ವಿಜೆಟ್‌ಗಳು",
  Colors:"ಬಣ್ಣಗಳು",
  Barcodes:"ಬಾರ್ಕೋಡ್ಗಳು",
  View_Timers:"ಟೈಮರ್‌ಗಳನ್ನು ವೀಕ್ಷಿಸಿ",
  Gradients:"ಗ್ರೇಡಿಯಂಟ್ಸ್",
  No_Info:"ಮಾಹಿತಿ ಇಲ್ಲ",
  Disable:"ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಿ",
  Show_Layer:"ಲೇಯರ್‌ಗಳನ್ನು ತೋರಿಸಿ",
  Hide_Layer:"ಪದರಗಳನ್ನು ಮರೆಮಾಡಿ",
  Text_Layer:"ಪಠ್ಯ ಪದರಗಳು",
  Secondly:"ಎರಡನೆಯದಾಗಿ",
  Minutely:"ನಿಮಿಷಕ್ಕೆ",
  nine_am:"ಬೆಳಗ್ಗೆ 9:00",
  five_pm:"5:00 PM",
  Enable_Address:"ವಿಳಾಸವನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Invalid_File_Type:"ಕ್ಷಮಿಸಿ, ಅಮಾನ್ಯವಾದ ಫೈಲ್ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಲಾಗಿದೆ. ಬೆಂಬಲಿತ ಫೈಲ್ ಪ್ರಕಾರ:",
  Error_Updating_Entry:"ಕ್ಷಮಿಸಿ, ಈ ನಮೂದನ್ನು ನವೀಕರಿಸುವಲ್ಲಿ ದೋಷ ಕಂಡುಬಂದಿದೆ.",
  Schedule_Timing_Alert:"ದೋಷ: ವೇಳಾಪಟ್ಟಿಯ ಪ್ರಾರಂಭದ ಸಮಯವನ್ನು ಅಂತ್ಯದ ಸಮಯದ ನಂತರ ಮೌಲ್ಯಕ್ಕೆ ಹೊಂದಿಸಲಾಗಿದೆ.",
  Select_Multiple_Payments_Prompt:"ಯಾವುದೇ ಪಾವತಿಗಳನ್ನು ಆಯ್ಕೆಮಾಡಲಾಗಿಲ್ಲ. ಕನಿಷ್ಠ ಒಂದು ಪಾವತಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ.",
  Select_Multiple_Cheques_Prompt:"ಯಾವುದೇ ಪರಿಶೀಲನೆಗಳನ್ನು ಆಯ್ಕೆಮಾಡಲಾಗಿಲ್ಲ. ದಯವಿಟ್ಟು ಕನಿಷ್ಠ ಒಂದು ಚೆಕ್ ಅನ್ನು ಆಯ್ಕೆಮಾಡಿ.",
  Select_Multiple_Items_Prompt:"ಯಾವುದೇ ಐಟಂಗಳನ್ನು ಆಯ್ಕೆಮಾಡಲಾಗಿಲ್ಲ. ದಯವಿಟ್ಟು ಕನಿಷ್ಠ ಒಂದು ಐಟಂ ಅನ್ನು ಆಯ್ಕೆಮಾಡಿ.",
  Paymemt_Multiple_Delete_Confirmation:"ಬಹು ಪಾವತಿಗಳನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Cheque_Multiple_Delete_Confirmation:"ಬಹು ಪರಿಶೀಲನೆಗಳನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Payee_Multiple_Delete_Confirmation:"ಬಹು ಪಾವತಿದಾರರನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Employee_Multiple_Delete_Confirmation:"ಬಹು ಉದ್ಯೋಗಿಗಳನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  MICR_Warning:"ಗಮನಿಸಿ: ಕೆಲವು ಮುದ್ರಕಗಳು ಮತ್ತು ಸಾಧನಗಳು MICR ಫಾಂಟ್ ಅನ್ನು ಸರಿಯಾಗಿ ಪ್ರದರ್ಶಿಸದೇ ಇರಬಹುದು.",
  Automatically_Send:"ಸ್ವಯಂಚಾಲಿತವಾಗಿ ಕಳುಹಿಸಿ",
  Type:"ಮಾದರಿ",
  Payment_Type:"ಪಾವತಿ ವಿಧಾನ",
  Auto_Send:"ಸ್ವಯಂ ಕಳುಹಿಸು",
  Automatically_Process:"ಸ್ವಯಂಚಾಲಿತವಾಗಿ ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಿ",
  Auto_Process:"ಸ್ವಯಂ ಪ್ರಕ್ರಿಯೆ",
  Image_Based:"ಚಿತ್ರ ಆಧಾರಿತ",
  Font_Based:"ಫಾಂಟ್ ಆಧಾರಿತ",
  Rerender:"ಮರು ನಿರೂಪಿಸಿ",
  Rendering:"ರೆಂಡರಿಂಗ್",
  Render:"ಕಡತಗಳನ್ನು",
  Top:"ಟಾಪ್",
  Middle:"ಮಧ್ಯಮ",
  Bottom:"ಕೆಳಗೆ",
  Top_Left:"ಮೇಲಿನ ಎಡ",
  Top_Center:"ಉನ್ನತ ಕೇಂದ್ರ",
  Top_Right:"ಮೇಲಿನಿಂದ ಬಲ",
  Middle_Left:"ಮಧ್ಯ ಎಡ",
  Middle_Center:"ಮಧ್ಯಮ ಕೇಂದ್ರ",
  Middle_Right:"ಮಧ್ಯಮ ಬಲ",
  Bottom_Left:"ಕೆಳಗೆ ಎಡ",
  Bottom_Center:"ಕೆಳಭಾಗದ ಕೇಂದ್ರ",
  Bottom_Right:"ಕೆಳಗಿನ ಬಲ",
  Numbers:"ಸಂಖ್ಯೆಗಳು",
  Verified:"ಪರಿಶೀಲಿಸಲಾಗಿದೆ",
  Paper_Size:"ಕಾಗದದ ಗಾತ್ರ",
  New_Device:"ಹೊಸ ಸಾಧನ",
  Add_Device:"ಸಾಧನವನ್ನು ಸೇರಿಸಿ",
  Remove_Device:"ಸಾಧನವನ್ನು ತೆಗೆದುಹಾಕಿ",
  Delete_Device:"ಸಾಧನವನ್ನು ಅಳಿಸಿ",
  Block_Device:"ಸಾಧನವನ್ನು ನಿರ್ಬಂಧಿಸಿ",
  Block:"ನಿರ್ಬಂಧಿಸಿ",
  Unblock:"ಅನಿರ್ಬಂಧಿಸಿ",
  Table:"ಟೇಬಲ್",
  Scan_Login_Code:"ಲಾಗಿನ್ ಕೋಡ್ ಅನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ",
  Login_Code:"ಲಾಗಿನ್ ಕೋಡ್",
  Scan_Code:"ಸ್ಕ್ಯಾನ್ ಕೋಡ್",
  Scan_QR_Code:"QR ಕೋಡ್ ಅನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ",
  Select_All:"ಎಲ್ಲವನ್ನು ಆರಿಸು",
  Deselect_All:"ಎಲ್ಲವನ್ನೂ ಆಯ್ಕೆ ರದ್ದುಮಾಡಿ",
  Increase:"ಹೆಚ್ಚಿಸಿ",
  Decrease:"ಕಡಿಮೆ ಮಾಡಿ",
  Bold:"ದಪ್ಪ",
  Text:"ಪಠ್ಯ",
  Style:"ಶೈಲಿ",
  Italic:"ಇಟಾಲಿಕ್",
  QR_Code:"QR ಕೋಡ್",
  Barcode:"ಬಾರ್ಕೋಡ್",
  Browse_Images:"ಚಿತ್ರಗಳನ್ನು ಬ್ರೌಸ್ ಮಾಡಿ",
  Browse_Files:"ಫೈಲ್‌ಗಳನ್ನು ಬ್ರೌಸ್ ಮಾಡಿ",
  Background_Image:"ಹಿನ್ನೆಲೆ ಚಿತ್ರ",
  Logo_Image:"ಲೋಗೋ ಚಿತ್ರ",
  Header_Image:"ಹೆಡರ್ ಚಿತ್ರ",
  Bank_Image:"ಬ್ಯಾಂಕ್ ಚಿತ್ರ",
  Cut_Lines:"ಕಟ್ ಲೈನ್ಸ್",
  Background:"ಹಿನ್ನೆಲೆ",
  License:"ಪರವಾನಗಿ",
  One_License:"1 ಪರವಾನಗಿ:",
  Licensed:"ಪರವಾನಗಿ ನೀಡಲಾಗಿದೆ:",
  Not_Licensed:"ಪರವಾನಗಿ ಪಡೆದಿಲ್ಲ",
  Enter_Serial:"ಸೀರಿಯಲ್ ನಮೂದಿಸಿ",
  Serial_Key:"ಸರಣಿ ಕೀ",
  Serial:"ಧಾರಾವಾಹಿ",
  Product_Key:"ಉತ್ಪನ್ನ ಕೀ",
  Check_Product_Key:"ಉತ್ಪನ್ನದ ಕೀಲಿಯನ್ನು ಪರಿಶೀಲಿಸಿ",
  Add_Product_Key:"ಉತ್ಪನ್ನ ಕೀ ಸೇರಿಸಿ",
  Verifying_Purchase:"ಖರೀದಿಯನ್ನು ಪರಿಶೀಲಿಸಲಾಗುತ್ತಿದೆ...",
  Print_Envelope:"ಪ್ರಿಂಟ್ ಎನ್ವಲಪ್",
  Envelope:"ಹೊದಿಕೆ",
  Thank_You:"ಧನ್ಯವಾದಗಳು!",
  Scale:"ಸ್ಕೇಲ್",
  Print_Scale:"ಪ್ರಿಂಟ್ ಸ್ಕೇಲ್",
  Borders:"ಗಡಿ",
  VOID:"ಅನೂರ್ಜಿತ",
  Void_Cheque:"ಅನೂರ್ಜಿತ ತಪಾಸಣೆ",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ಈ ಆದೇಶಕ್ಕೆ ಪಾವತಿಸಿ:",
  NO_DOLLARS:"ಡಾಲರ್ ಇಲ್ಲ ",
  ONE_DOLLAR:"ಒಂದು ಡಾಲರ್",
  DOLLARS:" ಡಾಲರ್",
  AND:" ಮತ್ತು ",
  CENTS:" ಸೆಂಟ್ಸ್.",
  NO_:"ಸಂ ",
  ONE_:"ಒಂದು ",
  AND_NO_:"ಮತ್ತು ಇಲ್ಲ ",
  _AND_ONE_:"ಮತ್ತು ಒಂದು ",
  DOLLARS_AND_ONE_CENT:" ಮತ್ತು ಒಂದು ಸೆಂ.",
  AND_NO_CENTS:" ಮತ್ತು ಶೂನ್ಯ ಸೆಂಟ್ಸ್.",
  CHEQUE_PRINT_DATE:"ದಿನಾಂಕ:",
  CHEQUE_PRINT_MEMO:"ಮೆಮೊ:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"ಆರಂಭಿಕ ಕಾರ್ಯಗಳು",
  Inital_Setup:"ಆರಂಭಿಕ ಸೆಟಪ್",
  Welcome_To:"ಸುಸ್ವಾಗತ ",
  Welcome:"ಸ್ವಾಗತ",
  Swipe:"ಸ್ವೈಪ್",
  Intro_Setup:"ಪರಿಚಯ ಸೆಟಪ್",
  Introduction:"ಪರಿಚಯ",
  CHEQUE_PRINT_CREDIT:"ಚೆಕ್‌ಗಳಿಂದ ನಡೆಸಲ್ಪಡುತ್ತಿದೆ",
  Title_Intro_Word:"ಚೆಕ್‌ಗಳಿಗೆ ಸುಸ್ವಾಗತ",
  Title_Intro:"ಚೆಕ್ ಪರಿಚಯ",
  Title_Setup:"ಚೆಕ್ ಸೆಟಪ್",
  PayPal_Default_Email_Message:"ನೀವು ಹೊಸ PayPal ವರ್ಗಾವಣೆಯನ್ನು ಸ್ವೀಕರಿಸಿರುವಿರಿ. [ಚೆಕ್‌ಗಳಿಂದ ನಡೆಸಲ್ಪಡುತ್ತಿದೆ]",
  Cheques_App_Export:"ಚೆಕ್‌ಗಳಿಂದ ರಫ್ತು ಮಾಡಲಾಗಿದೆ",
  Post_Intro_Thanks:"ಚೆಕ್‌ಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ಸೆಟಪ್ ಪ್ರಕ್ರಿಯೆಯು ಈಗ ಪೂರ್ಣಗೊಂಡಿದೆ.",
  Post_Intro_Word:"ನಿಮ್ಮ ಮೊದಲ ಚೆಕ್ ಅನ್ನು ಮುದ್ರಿಸುವ ಮೂಲಕ ಪ್ರಾರಂಭಿಸಿ, ಭವಿಷ್ಯದ ಪಾವತಿಯನ್ನು ಸೇರಿಸಿ ಅಥವಾ ವೇತನದಾರರಿಗೆ ಉದ್ಯೋಗಿಗಳನ್ನು ಸೇರಿಸಿ.",
  Upgrade_Required:"ಈ ಸಂದೇಶವನ್ನು ಮರೆಮಾಡಲು ಮತ್ತು ಹೆಚ್ಚುವರಿ ವೈಶಿಷ್ಟ್ಯಗಳನ್ನು ಅನ್‌ಲಾಕ್ ಮಾಡಲು ಚೆಕ್‌ಗಳಿಗೆ ನೀವು ಸಾಫ್ಟ್‌ವೇರ್‌ನ ಹೆಚ್ಚು ಪ್ರೀಮಿಯಂ ಆವೃತ್ತಿಯನ್ನು ಹೊಂದುವ ಅಗತ್ಯವಿದೆ.",
  Upgrade_Title:"ಚೆಕ್",
  Mailout_Prompt:"ನೀವು ಹೆಚ್ಚುವರಿಯಾಗಿ ನಿಮ್ಮ ವೇತನದಾರರ ಚೆಕ್‌ಗಳನ್ನು ಚೆಕ್‌ಗಳನ್ನು ಮೇಲ್-ಔಟ್ ಮಾಡಲು ಆಯ್ಕೆ ಮಾಡಬಹುದು.",
  Mailed_Cheque:"ಮೇಲ್ ಮಾಡಿದ ಚೆಕ್:",
  Mailed_Cheque_Color:"ಮೇಲ್ ಮಾಡಿದ ಚೆಕ್ (ಬಣ್ಣ): ",
  Terms_Purchase:"ಚೆಕ್‌ಗಳೊಂದಿಗೆ ಎಲ್ಲಾ ಎಲೆಕ್ಟ್ರಾನಿಕ್ ಖರೀದಿಗಳು ಖರೀದಿ ದಿನಾಂಕದಿಂದ 30-ದಿನಗಳವರೆಗೆ ಸಂಪೂರ್ಣವಾಗಿ ಮರುಪಾವತಿಸಲ್ಪಡುತ್ತವೆ. ಮುಂದುವರಿಸುವ ಮೊದಲು ದಯವಿಟ್ಟು <a href='#'>ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳನ್ನು</a> ಓದಿ ಮತ್ತು ಒಪ್ಪಿಕೊಳ್ಳಿ.",
  Dashboard_Setup:"ಪ್ರಾಥಮಿಕ ಮುದ್ರಕವನ್ನು ಹೊಂದಿಸಿ",
  Dashboard_Add:"ಪ್ರಾಥಮಿಕ ಬ್ಯಾಂಕ್ ಖಾತೆಯನ್ನು ಸೇರಿಸಿ",
  Dashboard_Customize:"ಚೆಕ್ ಟೆಂಪ್ಲೇಟ್ ಆಯ್ಕೆಮಾಡಿ",
  Dashboard_Job_Salary:"ನಿಮ್ಮ ಉದ್ಯೋಗವನ್ನು ರಚಿಸಿ ಮತ್ತು ನಿಮ್ಮ ಸಂಬಳವನ್ನು ಸೇರಿಸಿ",
  Dashboard_Employees:"ಉದ್ಯೋಗಿಗಳು ಮತ್ತು ಪಾವತಿದಾರರನ್ನು ಟ್ರ್ಯಾಕ್ ಮಾಡಿ",
  Dashboard_Print:"ನಿಮ್ಮ ಪಾವತಿಗಳನ್ನು ಮುದ್ರಿಸಿ ಮತ್ತು ಮೇಲ್ ಮಾಡಿ",
  Dashboard_Payroll:"ನಿಮ್ಮ ವೇತನದಾರರ ಮುದ್ರಣವನ್ನು ಸ್ವಯಂಚಾಲಿತಗೊಳಿಸಿ",
  Dashboard_PayPal:"PayPal ವೇತನದಾರರ / ಪಾವತಿಗಳನ್ನು ಹೊಂದಿಸಿ",
  Begin_Setup:"ಸೆಟಪ್ ಪ್ರಾರಂಭಿಸಿ",
  Get_Started:"ಪ್ರಾರಂಭಿಸಿ",
  Purchase:"ಖರೀದಿ",
  Lockdown:"ಮುಚ್ಚುವುದು",
  Unlock:"ಅನ್ಲಾಕ್",
  Detailed:"ವಿವರವಾದ",
  Log_In:"ಲಾಗ್ ಇನ್",
  Login:"ಲಾಗಿನ್",
  Launch:"ಲಾಂಚ್",
  Register:"ನೋಂದಣಿ",
  Finish:"ಮುಕ್ತಾಯ",
  List:"ಪಟ್ಟಿ",
  Weekends:"ವಾರಾಂತ್ಯ",
  Weekly:"ಸಾಪ್ತಾಹಿಕ",
  PayPal_Default_Subject:"ಹೊಸ ಪಾವತಿ",
  Payment_Message:"ಪಾವತಿ ಸಂದೇಶ",
  PayPal_Default_Payment_Note:"ಧನ್ಯವಾದ",
  Setup_Your_Cheqing_Account:"ಖಾತೆ ಪರಿಶೀಲನೆ",
  Add_Your_Primary_Cheqing_Account:"ನಿಮ್ಮ ಪ್ರಾಥಮಿಕ ಪರಿಶೀಲನಾ ಖಾತೆಯನ್ನು ಸೇರಿಸಿ.",
  Welcome_Word:"ವೇತನ ಪಟ್ಟಿ ಮತ್ತು ಮಾನವ ಸಂಪನ್ಮೂಲ ನಿರ್ವಹಣೆಯನ್ನು ಸರಳಗೊಳಿಸಿ ಮತ್ತು ಸ್ವಯಂಚಾಲಿತಗೊಳಿಸಿ.",
  Get_Started_Quickly:"ಪ್ರಾರಂಭಿಸಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುವ ಕೆಲವು ಸರಳ ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸಿ...",
  Done_Intro_Word:"ಸೆಟಪ್ ಕಂಪ್ಲೀಟ್",
  PIN_Protected:"ಪಾಸ್‌ವರ್ಡ್ ಮತ್ತು ಪಿನ್ ರಕ್ಷಿತ",
  Enter_New_PIN:"ಹೊಸ ಪಿನ್ ಕೋಡ್ ನಮೂದಿಸಿ:",
  Enter_PIN:"ಪಿನ್ ಕೋಡ್ ನಮೂದಿಸಿ:",
  Invalid_PIN:"ಅಮಾನ್ಯವಾದ ಪಿನ್ ನಮೂದಿಸಲಾಗಿದೆ.",
  Account_Identifier:"ಖಾತೆ ಗುರುತಿಸುವಿಕೆ",
  New_Account_Identifier:"ಹೊಸ ಖಾತೆ ಗುರುತಿಸುವಿಕೆ",
  attempt:"ಪ್ರಯತ್ನ",
  attempts:"ಪ್ರಯತ್ನಗಳು",
  remaining:"ಉಳಿದ",
  Language:"ಭಾಷೆ",
  languages:"ಭಾಷೆಗಳು",
  select_languages:"ಭಾಷೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  Deposit:"ಠೇವಣಿ",
  Hire_One_Now:"ಈಗ ಒಬ್ಬರನ್ನು ನೇಮಿಸಿ",
  App_Locked:"ಅಪ್ಲಿಕೇಶನ್ ಲಾಕ್ ಆಗಿದೆ.",
  Skip_:"ಸ್ಕಿಪ್",
  Skip_to_Dashboard:"ಡ್ಯಾಶ್‌ಬೋರ್ಡ್‌ಗೆ ತೆರಳಿ",
  Dashboard:"ಡ್ಯಾಶ್‌ಬೋರ್ಡ್",
  Import:"ಆಮದು",
  Admin:"ನಿರ್ವಾಹಕರು",
  New_Admin:"ಹೊಸ ನಿರ್ವಾಹಕ",
  Settings:"ಸಂಯೋಜನೆಗಳು",
  Color_Picker:"ಕಲರ್ ಪಿಕ್ಕರ್",
  Font_Picker:"ಫಾಂಟ್ ಪಿಕ್ಕರ್",
  Logout:"ಲಾಗ್ ಔಟ್",
  Close:"ಮುಚ್ಚು",
  Close_menu:"ಮುಚ್ಚು",
  Excel:"ಎಕ್ಸೆಲ್ ಫೈಲ್",
  Csv:"CSV ಫೈಲ್",
  Sql:"SQL ಫೈಲ್",
  Json:"JSON ಫೈಲ್",
  Url:"URL ಮೂಲಕ ಆಮದು",
  Back:"ಹಿಂದೆ",
  Timers:"ಟೈಮರ್‌ಗಳು",
  Cheque:"ಪರಿಶೀಲಿಸಿ",
  Print:"ಮುದ್ರಿಸಿ",
  Designs:"ವಿನ್ಯಾಸಗಳು",
  Pause_Printing:"ಮುದ್ರಣವನ್ನು ವಿರಾಮಗೊಳಿಸಿ",
  Resume_Printing:"ಪುನರಾರಂಭಿಸು ಮುದ್ರಣ",
  Printing_Paused:"ಮುದ್ರಣ ವಿರಾಮಗೊಳಿಸಲಾಗಿದೆ",
  PrintingUnavailable:"ಕ್ಷಮಿಸಿ! ಈ ಸಿಸ್ಟಂನಲ್ಲಿ ಮುದ್ರಣವು ಲಭ್ಯವಿಲ್ಲ.",
  Prints_Paused:"ಮುದ್ರಣಗಳನ್ನು ವಿರಾಮಗೊಳಿಸಲಾಗಿದೆ",
  Administration:"ಆಡಳಿತ",
  Loading:"ಲೋಡ್ ಆಗುತ್ತಿದೆ",
  Unnamed:"ಹೆಸರಿಲ್ಲದ",
  error:"ಕ್ಷಮಿಸಿ, ಈ ಚೆಕ್ ಅನ್ನು ವೀಕ್ಷಿಸಲು ತೆರೆಯಲಾಗಲಿಲ್ಲ.",
  No_Cheques_To_Print:"ಮುದ್ರಿಸಲು ಯಾವುದೇ ಚೆಕ್‌ಗಳಿಲ್ಲ",
  No_Cheques_To_Print_Word:"<a routerLink='/folder/Cheque/New'>ಹೊಸ ಚೆಕ್</a> ಅನ್ನು ರಚಿಸಿ.",
  New_Cheque:"ಹೊಸ ಚೆಕ್",
  Start_One_Now:"ಈಗ ಒಂದನ್ನು ಪ್ರಾರಂಭಿಸಿ",
  Edit_Cheque:"ಪರಿಶೀಲನೆಯನ್ನು ಸಂಪಾದಿಸು",
  Select_Cheques:"ಚೆಕ್‌ಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  Select_Employee:"ಉದ್ಯೋಗಿಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
  Default_Printer:"ಡೀಫಾಲ್ಟ್ ಪ್ರಿಂಟರ್",
  Reassign:"ಮರು ನಿಯೋಜಿಸಿ",
  Configure:"ಕಾನ್ಫಿಗರ್",
  Template:"ಟೆಂಪ್ಲೇಟ್",
  Designer:"ಡಿಸೈನರ್",
  Edit_Designs:"ವಿನ್ಯಾಸಗಳನ್ನು ಸಂಪಾದಿಸಿ",
  New_Design:"ಹೊಸ ವಿನ್ಯಾಸ",
  Next:"ಮುಂದೆ",
  Save:"ಉಳಿಸು",
  Name:"ಹೆಸರು",
  Mail:"ಮೇಲ್",
  Amount:"ಮೊತ್ತ",
  Date:"ದಿನಾಂಕ",
  PayPal:"ಪೇಪಾಲ್",
  Payouts:"ಪಾವತಿ",
  PayPal_Label:"ಪೇಪಾಲ್ ಲೇಬಲ್",
  Email_Username:"ಇಮೇಲ್ / ಬಳಕೆದಾರಹೆಸರು",
  Client_ID:"ಕ್ಲೈಂಟ್ ಐಡಿ",
  Sandbox_Email:"ಸ್ಯಾಂಡ್‌ಬಾಕ್ಸ್ ಇಮೇಲ್",
  PayPal_Email:"ಪೇಪಾಲ್ ಇಮೇಲ್",
  PayPal_Username:"API ಬಳಕೆದಾರಹೆಸರು",
  PayPal_Payouts:"ಪೇಪಾಲ್ ಪಾವತಿಗಳು",
  Select_PayPal_Key:"ಪೇಪಾಲ್ ಕೀ ಆಯ್ಕೆಮಾಡಿ",
  Secret:"ರಹಸ್ಯ",
  API_Secret:"API ರಹಸ್ಯ",
  PayPal_API_Keys:"ಪೇಪಾಲ್ ಕೀಗಳು",
  New_PayPal_Key:"ಹೊಸ ಪೇಪಾಲ್ ಕೀ",
  Email_Subject:"ವಿಷಯ ಇಮೇಲ್",
  Email_Message:"ಇಮೇಲ್ ಸಂದೇಶ",
  Payout_Options:"ಪಾವತಿ ಆಯ್ಕೆಗಳು",
  Payment_Note:"ಪಾವತಿ ಟಿಪ್ಪಣಿ",
  Enable_Employee:"ಉದ್ಯೋಗಿಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Enable_Production_Mode:"ಪ್ರೊಡಕ್ಷನ್ ಮೋಡ್ ಅನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Sandbox_Username:"ಸ್ಯಾಂಡ್‌ಬಾಕ್ಸ್ ಬಳಕೆದಾರಹೆಸರು",
  Sandbox_Signature:"ಸ್ಯಾಂಡ್‌ಬಾಕ್ಸ್ ಸಹಿ",
  Sandbox_Password:"ಸ್ಯಾಂಡ್‌ಬಾಕ್ಸ್ ಪಾಸ್‌ವರ್ಡ್",
  Production_Username:"ಉತ್ಪಾದನೆಯ ಬಳಕೆದಾರಹೆಸರು",
  Production_Signature:"ಉತ್ಪಾದನೆಯ ಸಹಿ",
  Production_Password:"ಪ್ರೊಡಕ್ಷನ್ ಪಾಸ್ವರ್ಡ್",
  Production_Email:"ಉತ್ಪಾದನೆಯ ಇಮೇಲ್",
  API_Client_ID:"API ಕ್ಲೈಂಟ್ ಐಡಿ",
  API_Signature:"API ಸಹಿ",
  API_Password:"API ಪಾಸ್ವರ್ಡ್",
  API_Username:"API ಬಳಕೆದಾರಹೆಸರು",
  Secret_Key:"ರಹಸ್ಯ ಕೀ",
  Sandbox:"ಸ್ಯಾಂಡ್ಬಾಕ್ಸ್",
  Production:"ಉತ್ಪಾದನೆ",
  Sandbox_Keys:"ಸ್ಯಾಂಡ್‌ಬಾಕ್ಸ್ ಕೀಗಳು",
  Production_Keys:"ಉತ್ಪಾದನಾ ಕೀಗಳು",
  API_Title:"API ಶೀರ್ಷಿಕೆ",
  Production_Mode:"ಪ್ರೊಡಕ್ಷನ್ ಮೋಡ್",
  Account_Label:"ಖಾತೆ ಲೇಬಲ್",
  No_PayPal_Setup:"ಪೇಪಾಲ್ ಕೀ ಇಲ್ಲ",
  Enable_PayPal_Account:"PayPal ಖಾತೆಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  No_PayPal_Word:"ನಿಮ್ಮ <a routerLink='/folder/Invoice/New'>PayPal API ಕೀ</a> ಸೇರಿಸಿ.",
  Printed_Memo:"ಮುದ್ರಿತ ಮೆಮೊ",
  Employee:"ನೌಕರ",
  View_Employee:"ನೌಕರನನ್ನು ವೀಕ್ಷಿಸಿ",
  Mailing_Address:"ಅಂಚೆ ವಿಳಾಸ",
  Company_Address:"ಕಂಪೆನಿ ವಿಳಾಸ",
  Select_Company_Address:"ಕಂಪನಿಯ ವಿಳಾಸವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  Address:"ವಿಳಾಸ",
  Any_Day:"ಯಾವುದೇ ದಿನ",
  Address_Name:"ವಿಳಾಸ ಹೆಸರು",
  Unit:"ಘಟಕ",
  Account:"ಖಾತೆ",
  Bank_Account:"ಬ್ಯಾಂಕ್ ಖಾತೆ",
  Account_Limits:"ಖಾತೆ ಮಿತಿಗಳನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Payroll:"ವೇತನ ಪಟ್ಟಿ",
  New_Payroll:"ಹೊಸ ವೇತನದಾರರ ಪಟ್ಟಿ",
  No_Payroll:"ಮುಂಬರುವ ವೇತನ ಪಟ್ಟಿ ಇಲ್ಲ",
  Upcoming_Holiday:"ಮುಂಬರುವ ರಜೆ:",
  Invoice_Due:"ಇನ್ವಾಯ್ಸ್ ಬಾಕಿ:",
  New_Invoice:"ಹೊಸ ಸರಕುಪಟ್ಟಿ",
  No_Invoices:"ಇನ್‌ವಾಯ್ಸ್‌ಗಳಿಲ್ಲ",
  No_Invoices_Word:"ಮೊದಲ <a routerLink='/folder/Invoice/New'>ಸರಕುಪಟ್ಟಿ</a> ಅನ್ನು ರಚಿಸಿ.",
  Cheque_Due:"ಚೆಕ್ ಬಾಕಿ:",
  Payrolls:"ವೇತನ ಪಟ್ಟಿಗಳು",
  Sandbox_Mode:"ಸ್ಯಾಂಡ್‌ಬಾಕ್ಸ್ ಮೋಡ್",
  Hire:"ಬಾಡಿಗೆ",
  Pay:"ಪಾವತಿ",
  New:"ಹೊಸ",
  Add:"ಸೇರಿಸು",
  Make:"ಮಾಡು",
  Time:"ಸಮಯ",
  Write:"ಬರೆಯಿರಿ",
  Holiday:"ರಜಾ",
  Holidays:"ರಜಾದಿನಗಳು",
  Next_Holiday:"ಮುಂದಿನ ರಜೆ:",
  All_Done:"ಪೂರ್ಣವಾಯಿತು!",
  Employees:"ನೌಕರರು",
  Payees:"ಪಾವತಿದಾರರು",
  Job:"ಉದ್ಯೋಗ",
  Jobs:"ಉದ್ಯೋಗಗಳು",
  Invoice:"ಸರಕುಪಟ್ಟಿ",
  Invoices:"ಇನ್‌ವಾಯ್ಸ್‌ಗಳು",
  Vacations:"ರಜೆಗಳು",
  Cheques:"ಚೆಕ್",
  Brand_Cheques:"ಬ್ರಾಂಡ್",
  Payment:"ಪಾವತಿ",
  Enable_Payment:"ಪಾವತಿಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Payments:"ಪಾವತಿಗಳು",
  Schedule:"ವೇಳಾಪಟ್ಟಿ",
  Schedules:"ವೇಳಾಪಟ್ಟಿಗಳು",
  Timesheet:"ವೇಳಾಚೀಟಿ",
  Timesheets:"ಟೈಮ್ ಶೀಟ್ಸ್",
  Salary:"ಸಂಬಳ",
  New_Address:"ಹೊಸ ವಿಳಾಸ",
  Address_2:"ವಿಳಾಸ ಸಾಲು 2)",
  _City:"ನಗರ",
  _State:"ರಾಜ್ಯ/ಪ್ರೊವ್",
  City:"ನಗರ / ಪಟ್ಟಣ",
  State:"ರಾಜ್ಯ / ಪ್ರಾಂತ್ಯ",
  Postal:"ಪೋಸ್ಟಲ್ / ಪಿನ್ ಕೋಡ್",
  ZIP:"ಪೋಸ್ಟಲ್ / ZIP",
  Country:"ದೇಶ",
  Addresses:"ವಿಳಾಸಗಳು",
  Required_Options:"ಅಗತ್ಯವಿರುವ ಆಯ್ಕೆಗಳು",
  Optional_Options:"ಐಚ್ಛಿಕ ಆಯ್ಕೆಗಳು",
  Additional_Options:"ಹೆಚ್ಚುವರಿ ಆಯ್ಕೆಗಳು",
  Required:"ಅಗತ್ಯವಿದೆ",
  Optional:"ಐಚ್ಛಿಕ",
  Additional:"ಹೆಚ್ಚುವರಿ",
  No_Addresses:"ವಿಳಾಸಗಳಿಲ್ಲ",
  New_Address_Word:"ಮೊದಲ <a routerLink='/folder/Address/New'>ವಿಳಾಸ</a> ಅನ್ನು ಸೇರಿಸಿ.",
  Select_Address:"ವಿಳಾಸವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  No_Address:"ವಿಳಾಸಗಳಿಲ್ಲ",
  Print_Cheque:"ಪ್ರಿಂಟ್ ಚೆಕ್",
  Print_Cheque_Now:"ಈಗ ಪರಿಶೀಲಿಸಿ ಮುದ್ರಿಸು",
  Description:"ವಿವರಣೆ",
  Pay_To_The_Order_Of:"ಆದೇಶಕ್ಕೆ ಪಾವತಿಸಿ:",
  Select_Date_Range:"ದಿನಾಂಕ ಶ್ರೇಣಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  Select_Starting_Date:"ಪ್ರಾರಂಭದ ದಿನಾಂಕವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  Select_Ending_Date:"ಮುಕ್ತಾಯ ದಿನಾಂಕವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  Select_Date:"ದಿನಾಂಕ ಆಯ್ಕೆಮಾಡಿ",
  Cheque_Date:"ಪರಿಶೀಲಿಸಿದ ದಿನಾಂಕ",
  Cheque_Memo:"ಚೆಕ್ ಮೆಮೊ",
  Blank_Cheque:"ಖಾಲಿ ಚೆಕ್",
  Blank:"ಖಾಲಿ",
  No_Cheques:"ಚೆಕ್‌ಗಳಿಲ್ಲ",
  No_Cheques_Word:"ನಿಮ್ಮ ಮೊದಲ <a routerLink='/folder/Cheque/New'>ಪರಿಶೀಲಿಸಿ</a> ಅನ್ನು ಮುದ್ರಿಸಿ.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"ಚಿತ್ರವನ್ನು ವೀಕ್ಷಿಸಿ",
  View:"ನೋಟ",
  Modify:"ಮಾರ್ಪಡಿಸು",
  Delete:"ಅಳಿಸು",
  Cheque_Paid:"ಪಾವತಿಸಿದ",
  New_Deduction:"ಹೊಸ ಕಡಿತ",
  Title:"ಶೀರ್ಷಿಕೆ",
  Frequency:"ಆವರ್ತನ",
  Hourly:"ಗಂಟೆಗೊಮ್ಮೆ",
  Daily:"ಪ್ರತಿದಿನ",
  Weekdays:"ವಾರದ ದಿನಗಳು",
  BiWeekly:"2 ವಾರಗಳು",
  TriWeekly:"3 ವಾರಗಳು",
  Monthly:"ಮಾಸಿಕ",
  MiMonthly:"2 ತಿಂಗಳ",
  Quarterly:"ತ್ರೈಮಾಸಿಕ",
  Yearly:"ವಾರ್ಷಿಕ",
  Every_Week:"ಪ್ರತಿ ವಾರ",
  Every_Payroll:"ಪ್ರತಿ ವೇತನದಾರರ ಪಟ್ಟಿ",
  Every_Month:"ಪ್ರತಿ ತಿಂಗಳು",
  Annually:"ವಾರ್ಷಿಕವಾಗಿ",
  Always_Scheduled:"ಯಾವಾಗಲೂ ನಿಗದಿಪಡಿಸಲಾಗಿದೆ",
  Start_Date:"ಪ್ರಾರಂಭ ದಿನಾಂಕ",
  End_Date:"ಅಂತ್ಯ ದಿನಾಂಕ",
  Start_Time:"ಆರಂಭವಾಗುವ",
  End_Time:"ಅಂತ್ಯ ಸಮಯ",
  Deduction_Label:"ಕಡಿತ ಲೇಬಲ್",
  Notes:"ಟಿಪ್ಪಣಿಗಳು",
  Options:"ಆಯ್ಕೆಗಳು",
  Enable:"ಸಕ್ರಿಯಗೊಳಿಸು",
  Select_Deductions:"ಕಡಿತಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  Deductions:"ಕಡಿತಗಳು",
  No_Deductions:"ಯಾವುದೇ ಕಡಿತಗಳಿಲ್ಲ",
  No_Deductions_Word:"ನಿಮ್ಮ ಮೊದಲ <a routerLink='/folder/Deduction/New'>ಕಡಿತಗೊಳಿಸುವಿಕೆ</a> ಅನ್ನು ರಚಿಸಿ.",
  New_Employee:"ಹೊಸ ಉದ್ಯೋಗಿ",
  No_Title:"ಶಿರೋನಾಮೆಯಿಲ್ಲ",
  _Name:"ಹೆಸರು",
  About_Yourself:"ನಿಮ್ಮ ಬಗ್ಗೆ",
  Full_Name:"ಪೂರ್ಣ ಹೆಸರು",
  Birthday:"ಹುಟ್ಟುಹಬ್ಬ",
  Email:"ಇಮೇಲ್",
  SMS:"SMS",
  Phone:"ದೂರವಾಣಿ",
  Test:"ಪರೀಕ್ಷೆ",
  Call:"ಕರೆ",
  Fax:"ಫ್ಯಾಕ್ಸ್",
  Printed_Note:"ಮುದ್ರಿತ ಟಿಪ್ಪಣಿ",
  Position:"ಸ್ಥಾನ",
  Job_Position:"ನೌಕರಿಯ ದರ್ಜೆ",
  Select_a_Job:"ಉದ್ಯೋಗವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  Select_a_Salary:"ಸಂಬಳವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  Add_a_Deduction:"ಕಡಿತವನ್ನು ಸೇರಿಸಿ",
  Taxes:"ತೆರಿಗೆಗಳು",
  Add_Taxes:"ತೆರಿಗೆಗಳನ್ನು ಸೇರಿಸಿ",
  Date_of_Birth:"ಹುಟ್ತಿದ ದಿನ",
  Email_Address:"ಇಮೇಲ್ ವಿಳಾಸ",
  Phone_Number:"ದೂರವಾಣಿ ಸಂಖ್ಯೆ",
  Phone_Call:"ದೂರವಾಣಿ ಕರೆ",
  Enable_on_Payroll:"ವೇತನಪಟ್ಟಿಯಲ್ಲಿ ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Select_Employees:"ಉದ್ಯೋಗಿಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
  No_Employees:"ನೌಕರರಿಲ್ಲ",
  No_Employees_Word:"ನಿಮ್ಮ ಮೊದಲ ಹೊಸ <a routerLink='/folder/Employee/New'>ಉದ್ಯೋಗಿಯನ್ನು</a> ಸೇರಿಸಿ.",
  No_Name:"ಹೆಸರು ಇಲ್ಲ",
  Unemployeed:"ನಿರುದ್ಯೋಗಿ",
  Employeed:"ಉದ್ಯೋಗದಲ್ಲಿರುವುದು",
  Paid:"ಪಾವತಿಸಿದ",
  Enabled:"ಸಕ್ರಿಯಗೊಳಿಸಲಾಗಿದೆ",
  Disabled:"ಅಂಗವಿಕಲ",
  Fire:"ಬೆಂಕಿ",
  Not_Available:"ಲಭ್ಯವಿಲ್ಲ",
  Not_Available_Word:"ನಿಮ್ಮ ಮೊದಲ <a routerLink='/folder/Invoice/New'>ಸರಕುಪಟ್ಟಿ</a> ಅನ್ನು ಮುದ್ರಿಸಿ ಮತ್ತು ಪಾವತಿಸಿ.",
  Select_Invoices:"Select_Invoices",
  Print_Invoice_Word:"ನಿಮ್ಮ ಮೊದಲ <a routerLink='/folder/Invoice/New'>ಸರಕುಪಟ್ಟಿ</a> ಅನ್ನು ಮುದ್ರಿಸಿ ಮತ್ತು ಪಾವತಿಸಿ.",
  Invoice_Title:"ಸರಕುಪಟ್ಟಿ ಶೀರ್ಷಿಕೆ",
  Invoice_Date:"ಸರಕುಪಟ್ಟಿ ದಿನಾಂಕ",
  Due_Date:"ನಿಧಿ ದಿನಾಂಕ",
  New_Job:"ಹೊಸ ಉದ್ಯೋಗ",
  Details:"ವಿವರಗಳು",
  Customize:"ಕಸ್ಟಮೈಸ್",
  Customize_Dashboard:"ಡ್ಯಾಶ್‌ಬೋರ್ಡ್ ಅನ್ನು ಕಸ್ಟಮೈಸ್ ಮಾಡಿ",
  Components:"ಘಟಕಗಳು",
  No_Components:"ಘಟಕಗಳಿಲ್ಲ",
  Main_Components:"ಮುಖ್ಯ ಘಟಕಗಳು",
  Smaller_Components:"ಸಣ್ಣ ಘಟಕಗಳು",
  Error_Loading_Page:"ಈ ಪುಟವನ್ನು ಲೋಡ್ ಮಾಡುವಲ್ಲಿ ದೋಷ.",
  Bank_Details:"ಬ್ಯಾಂಕ್ ವಿವರಗಳು",
  About_Your_Job:"ನಿಮ್ಮ ಕೆಲಸದ ಬಗ್ಗೆ",
  Your_Schedule:"ನಿಮ್ಮ ವೇಳಾಪಟ್ಟಿ",
  Job_Title:"ಕೆಲಸದ ಶೀರ್ಷಿಕೆ",
  Job_Description:"ಕೆಲಸದ ವಿವರ",
  Job_Details:"ಉದ್ಯೋಗದ ವಿವರಗಳು",
  Enable_Job:"ಉದ್ಯೋಗವನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Pay_Period:"ಪಾವತಿ ಅವಧಿ",
  Perpetually_Schedule:"ಶಾಶ್ವತವಾಗಿ ವೇಳಾಪಟ್ಟಿ",
  Select_Jobs:"ಉದ್ಯೋಗಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  No_Jobs:"ಉದ್ಯೋಗವಿಲ್ಲ",
  Add_Jobs:"ಉದ್ಯೋಗಗಳನ್ನು ಸೇರಿಸಿ",
  No_Jobs_Word:"ಮೊದಲ <a routerLink='/folder/Job/New'>ಉದ್ಯೋಗ</a> ಅನ್ನು ಪಟ್ಟಿಗೆ ಸೇರಿಸಿ.",
  Count_Employees:"job.employee_count+' ಉದ್ಯೋಗಿಗಳು'",
  Zero_Employees:"0 ಉದ್ಯೋಗಿಗಳು",
  New_Leave:"ಹೊಸ ರಜೆ",
  Leave_Name:"ಹೆಸರು ಬಿಡಿ",
  Paid_Leave:"ಪಾವತಿಸಿದ ರಜೆ",
  Leave_Pay:"ಪಾವತಿಯನ್ನು ಬಿಡಿ",
  Indefinite_Leave:"ಅನಿರ್ದಿಷ್ಟ ರಜೆ",
  Indefinite_Payroll:"ಅನಿರ್ದಿಷ್ಟ ವೇತನದಾರರ ಪಟ್ಟಿ",
  Leave:"ಬಿಡು",
  Add_Schedules:"ವೇಳಾಪಟ್ಟಿಗಳನ್ನು ಸೇರಿಸಿ",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"ಗೈರುಹಾಜರಿಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Select_Leaves:"ಎಲೆಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  No_Leaves:"ಗೈರುಹಾಜರಿಯಿಲ್ಲ",
  Leave_Of_Absence:"ಗೈರುಹಾಜರಿ ರಜೆ",
  Leaves_Of_Absence:"ಗೈರುಹಾಜರಿಯ ಎಲೆಗಳು",
  New_Leave_of_Absense:"ಗೈರುಹಾಜರಿಯ ಹೊಸ ರಜೆ",
  No_Leaves_Word:"ಮೊದಲ <a routerLink='/folder/Leave/New'>ಗೈರುಹಾಜರಿಯ ರಜೆ</a> ಸೇರಿಸಿ.",
  Not_Enabled:"ಸಕ್ರಿಯಗೊಳಿಸಲಾಗಿಲ್ಲ",
  Absences:"ಗೈರುಹಾಜರಿ",
  Payee:"ಪೇಯಿ",
  New_Payee:"ಹೊಸ ಪಾವತಿದಾರ",
  Payee_Identifier:"ಪೇಯಿ ಐಡೆಂಟಿಫೈಯರ್",
  Payee_Name:"ಪಾವತಿದಾರರ ಹೆಸರು",
  Payee_Title:"ಪೇಯಿ ಶೀರ್ಷಿಕೆ",
  Payment_Memo:"ಪಾವತಿ ಮೆಮೊ",
  Select_Payees:"ಪಾವತಿದಾರರನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  No_Payees:"ಪಾವತಿದಾರರಿಲ್ಲ",
  Add_Payee_Note:"ಮೊದಲ <a routerLink='/folder/Payee/New'>ಪಾವತಿದಾರ</a> ಅನ್ನು ಸೇರಿಸಿ.",
  New_Payees:"ಹೊಸ ಪಾವತಿದಾರರು",
  About_The_Payment_Schedule:"ಪಾವತಿ ವೇಳಾಪಟ್ಟಿ",
  Your_Payroll_Schedule:"ಸ್ವಯಂಚಾಲಿತ ವೇತನದಾರರ ಪಟ್ಟಿ",
  New_Payment:"ಹೊಸ ಪಾವತಿ",
  Identifier:"ಗುರುತಿಸುವಿಕೆ",
  Select:"ಆಯ್ಕೆ ಮಾಡಿ",
  Memo:"ಮೆಮೊ",
  Payment_Date:"ಪಾವತಿ ದಿನಾಂಕ",
  Mark_as_Paid:"ಪಾವತಿಸಿದಂತೆ ಗುರುತಿಸಿ",
  Select_Payments:"ಪಾವತಿಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  No_Payments:"ಪಾವತಿಗಳಿಲ್ಲ",
  No_Payments_Word:"ಮೊದಲ <a routerLink='/folder/Payment/New'>ಪಾವತಿ</a> ಅನ್ನು ರಚಿಸಿ.",
  Create_Payroll:"ವೇತನ ಪಟ್ಟಿಯನ್ನು ರಚಿಸಿ",
  Properties:"ಪ್ರಾಪರ್ಟೀಸ್",
  Payroll_Title:"ವೇತನ ಪಟ್ಟಿ ಶೀರ್ಷಿಕೆ",
  Payroll_Notes:"ವೇತನದಾರರ ಟಿಪ್ಪಣಿಗಳು",
  Payroll_Setup:"ಪೇರೋಲ್ ಸೆಟಪ್",
  Tabulate_Payments:"ಟ್ಯಾಬ್ಯುಲೇಟ್ ಪಾವತಿಗಳು",
  Tabulate:"ಟ್ಯಾಬ್ಲೇಟ್",
  By:"ಮೂಲಕ:",
  Payments_By:"ಪಾವತಿಗಳು",
  Timesheets_And_Schedules:"ಸಮಯ ಹಾಳೆಗಳು ಮತ್ತು ವೇಳಾಪಟ್ಟಿಗಳು",
  Both:"ಎರಡೂ",
  Items:"ವಸ್ತುಗಳು",
  Add_Payees:"ಪಾವತಿದಾರರನ್ನು ಸೇರಿಸಿ",
  Add_Account:"ಖಾತೆಯನ್ನು ಸೇರಿಸು",
  Enable_Account:"ಖಾತೆಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Enable_Payroll:"ವೇತನ ಪಟ್ಟಿಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Print_Payroll:"ಪ್ರಿಂಟ್ ಪೇರೋಲ್",
  No_Payrolls:"ವೇತನ ಪಟ್ಟಿ ಇಲ್ಲ",
  No_Payroll_Word:"ನಿಮ್ಮ ಮೊದಲ <a routerLink='/folder/Payroll/New'>ವೇತನದಾರರ ಪಟ್ಟಿ</a> ಅನ್ನು ರಚಿಸಿ.",
  View_more:"VIEW_MORE",
  Less:"ಕಡಿಮೆ",
  Add_Payroll:"ವೇತನ ಪಟ್ಟಿಯನ್ನು ಸೇರಿಸಿ",
  Select_Payroll:"ವೇತನ ಪಟ್ಟಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  About_Your_Salary:"ನಿಮ್ಮ ಸಂಬಳದ ಬಗ್ಗೆ",
  Add_Salaries:"ಸಂಬಳಗಳನ್ನು ಸೇರಿಸಿ",
  Add_Salary:"ಸಂಬಳವನ್ನು ಸೇರಿಸಿ",
  Salaries:"ಸಂಬಳಗಳು",
  No_Salaries:"ಸಂಬಳವಿಲ್ಲ",
  No_Salaries_Word:"ಮೊದಲ <a routerLink='/folder/Salary/New'>ಸಂಬಳ</a> ಅನ್ನು ಸೇರಿಸಿ.",
  Select_Salaries:"ಸಂಬಳವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  New_Salary:"ಹೊಸ ಸಂಬಳ",
  Salary_Name:"ಸಂಬಳ ಗುರುತಿಸುವಿಕೆ",
  Enable_Salary:"ಸಂಬಳವನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Salary_Amount:"ಸಂಬಳದ ಮೊತ್ತ",
  New_Schedule:"ಹೊಸ ವೇಳಾಪಟ್ಟಿ",
  Schedule_Title:"ಶೀರ್ಷಿಕೆ ವೇಳಾಪಟ್ಟಿ",
  Add_Address:"ವಿಳಾಸವನ್ನು ಸೇರಿಸಿ",
  Repeat:"ಪುನರಾವರ್ತನೆ",
  Design:"ವಿನ್ಯಾಸ",
  Edit_Design:"ವಿನ್ಯಾಸ ಸಂಪಾದಿಸಿ",
  Edit_this_Design:"ಈ ವಿನ್ಯಾಸವನ್ನು ಸಂಪಾದಿಸಿ",
  Repeat_Payment:"ಪುನರಾವರ್ತಿತ ಪಾವತಿ",
  Enable_Schedule:"ವೇಳಾಪಟ್ಟಿಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Never:"ಎಂದಿಗೂ",
  Select_Schedule:"ವೇಳಾಪಟ್ಟಿಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  No_Schedules:"ಯಾವುದೇ ವೇಳಾಪಟ್ಟಿಗಳಿಲ್ಲ",
  No_Schedules_Word:"ಮೊದಲ <a routerLink='/folder/Schedule/New'>ವೇಳಾಪಟ್ಟಿ</a> ಅನ್ನು ರಚಿಸಿ.",
  New_Administrator:"ಹೊಸ ನಿರ್ವಾಹಕ",
  Username:"ಬಳಕೆದಾರ ಹೆಸರು",
  First_Name:"ಮೊದಲ ಹೆಸರು",
  Last_Name:"ಕೊನೆಯ ಹೆಸರು",
  Add_an_Address:"ವಿಳಾಸವನ್ನು ಸೇರಿಸಿ",
  Payment_Limit:"ಪ್ರತಿ-ಪಾವತಿ ಮಿತಿ",
  Total_Limit:"ಒಟ್ಟು ಮಿತಿ",
  Select_Accounts:"ಖಾತೆಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  No_Administrators:"ನಿರ್ವಾಹಕರು ಇಲ್ಲ",
  No_Administrators_Word:"ಮೊದಲ <a routerLink='/folder/Administrator/New'>ನಿರ್ವಾಹಕ ಖಾತೆ</a> ಅನ್ನು ರಚಿಸಿ.",
  New_Administrators_Word:"ಮೊದಲ <a routerLink='/folder/Administrator/New'>ನಿರ್ವಾಹಕ</a> ಅನ್ನು ಸೇರಿಸಿ",
  Cloud:"ಮೇಘ",
  Backup:"ಬ್ಯಾಕಪ್",
  Enable_iCloud:"ಐಕ್ಲೌಡ್ ಅನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Enable_Google_Drive:"Google ಡ್ರೈವ್ ಅನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Enable_Microsoft_OneDrive:"Microsoft OneDrive ಅನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Automatically_Backup:"ಸ್ವಯಂಚಾಲಿತವಾಗಿ ಬ್ಯಾಕಪ್",
  FTP_Settings:"FTP ಸೆಟ್ಟಿಂಗ್‌ಗಳು",
  URL_File_Prompt:"ದಯವಿಟ್ಟು ಆಮದು ಮಾಡಲು .xls / .xlsx / .json ಫೈಲ್‌ಗಾಗಿ ಸ್ಥಳವನ್ನು ಸೂಚಿಸಿ:",
  URL_SQL_Prompt:"ದಯವಿಟ್ಟು ಆಮದು ಮಾಡಿಕೊಳ್ಳಲು SQLite ಫೈಲ್‌ನ ಸ್ಥಳವನ್ನು ಸೂಚಿಸಿ:",
  FTP_Backup:"FTP ಬ್ಯಾಕಪ್",
  FTP_Import:"FTP ಆಮದು",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"ಅತಿಥೆಯ",
  Port:"ಬಂದರು",
  Path:"ಮಾರ್ಗ",
  Data_Path:"ಡೇಟಾ ಪಾತ್",
  Enable_FTP_Account:"FTP ಖಾತೆಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  HostnameIP:"ಹೋಸ್ಟ್ ಹೆಸರು",
  Password:"ಗುಪ್ತಪದ",
  Connection_Port:"ಸಂಪರ್ಕ ಪೋರ್ಟ್",
  Enable_MySQL_Database:"MySQL ಡೇಟಾಬೇಸ್ ಅನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Log:"ಲಾಗ್",
  Reset:"ಮರುಹೊಂದಿಸಿ",
  Erase:"ಅಳಿಸು",
  Export:"ರಫ್ತು",
  Database:"ಡೇಟಾಬೇಸ್",
  Upload_CSV:"CSV ಅಪ್ಲೋಡ್",
  Download_CSV:"CSV ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL ಬ್ಯಾಕಪ್",
  Internal_Notes:"ಆಂತರಿಕ ಟಿಪ್ಪಣಿಗಳು",
  Root_Path:"ಮೂಲ ಮಾರ್ಗ",
  Select_Backup:"ಬ್ಯಾಕಪ್ ಆಯ್ಕೆಮಾಡಿ",
  Add_New_Backup:"ಹೊಸ ಬ್ಯಾಕಪ್ ಸೇರಿಸಿ",
  First_Backup:"ಮೊದಲ ಬ್ಯಾಕಪ್ ಅನ್ನು ಹೊಂದಿಸಿ...",
  Backups:"ಬ್ಯಾಕ್‌ಅಪ್‌ಗಳು",
  Add_Backup:"ಬ್ಯಾಕಪ್ ಸೇರಿಸಿ",
  No_Backups:"ಯಾವುದೇ ಬ್ಯಾಕಪ್‌ಗಳು ಕಂಡುಬಂದಿಲ್ಲ.",
  Select_Backup_Type:"ನೀವು ಹೊಂದಿಸಲು ಬಯಸುವ ಬ್ಯಾಕಪ್ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ...",
  Backup_Type:"ಬ್ಯಾಕಪ್ ಪ್ರಕಾರ",
  FTP_Drive:"FTP ಡ್ರೈವ್",
  URL_Location:"URL",
  Apple_iCloud:"ಐಕ್ಲೌಡ್",
  Google_Drive:"ಡ್ರೈವ್",
  Microsoft_OneDrive:"ಡ್ರೈವ್",
  Import_Fields:"ಆಮದು ಕ್ಷೇತ್ರಗಳು",
  Import_Fields_Word:"<a routerLink='/folder/Holiday/New'>ಆಮದು</a> ಡೇಟಾವನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ಈ ವಿಭಾಗವನ್ನು ಬಳಸಿ.",
  Upload:"ಅಪ್ಲೋಡ್",
  Download:"ಡೌನ್‌ಲೋಡ್",
  Download_JSON:"JSON ಡೇಟಾದಂತೆ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
  Download_SQL:"SQL ಫೈಲ್ ಆಗಿ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
  New_Bank:"ಹೊಸ ಬ್ಯಾಂಕ್",
  New_Account:"ಹೊಸ ಖಾತೆ",
  New_Bank_Account:"ಹೊಸ ಬ್ಯಾಂಕ್ ಖಾತೆ",
  Upload_Image:"ಚಿತ್ರವನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಿ",
  Bank_Name:"ಬ್ಯಾಂಕ್ ಹೆಸರು",
  Bank_Address:"ಬ್ಯಾಂಕ್ ವಿಳಾಸ",
  Branch_Address:"ಶಾಖೆ ವಿಳಾಸ",
  Address_on_Cheque:"ವಿಳಾಸ",
  Cheque_Numbers:"ಸಂಖ್ಯೆಗಳನ್ನು ಪರಿಶೀಲಿಸಿ",
  Cheque_Details:"ವಿವರಗಳನ್ನು ಪರಿಶೀಲಿಸಿ",
  Bank_Logo:"ಬ್ಯಾಂಕ್ ಲೋಗೋ",
  Cheque_ID:"ಐಡಿ ಪರಿಶೀಲಿಸಿ",
  Starting_Cheque_ID:"ಚೆಕ್ ಐಡಿಯನ್ನು ಪ್ರಾರಂಭಿಸಲಾಗುತ್ತಿದೆ",
  Transit_Number:"ಸಾರಿಗೆ ಸಂಖ್ಯೆ",
  Institution_Number:"ಸಂಸ್ಥೆಯ ಸಂಖ್ಯೆ",
  Designation_Number:"ನಾಮಕರಣ ಸಂಖ್ಯೆ",
  Account_Number:"ಖಾತೆ ಸಂಖ್ಯೆ",
  Limits:"ಮಿತಿಗಳು",
  Default_Limits:"ಡೀಫಾಲ್ಟ್ ಮಿತಿಗಳು",
  Over_Limit:"ಮಿತಿಮೀರಿದ",
  Under_Limit:"ಮಿತಿ ಅಡಿಯಲ್ಲಿ",
  Payroll_Limit:"ವೇತನ ಪಟ್ಟಿ",
  Enable_Bank_Account:"ಬ್ಯಾಂಕ್ ಖಾತೆಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Select_Account:"ಖಾತೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  No_Bank_Account:"ಬ್ಯಾಂಕ್ ಖಾತೆ ಇಲ್ಲ",
  No_Bank_Account_Word:"ಮೊದಲ <a routerLink='/folder/Accounts/New'>ಬ್ಯಾಂಕ್ ಖಾತೆಯನ್ನು</a> ಸೇರಿಸಿ.",
  Bank_Accounts:"ಬ್ಯಾಂಕ್ ಖಾತೆಗಳು",
  No_Accounts:"ಖಾತೆಗಳಿಲ್ಲ",
  No_Accounts_Note:"ಮೊದಲ <a routerLink='/folder/Accounts/New'>ಬ್ಯಾಂಕ್ ಖಾತೆಯನ್ನು</a> ಸೇರಿಸಿ.",
  Cheque_Designer:"ಚೆಕ್ ಡಿಸೈನರ್",
  Cheque_Design:"ವಿನ್ಯಾಸವನ್ನು ಪರಿಶೀಲಿಸಿ",
  Select_Design:"ವಿನ್ಯಾಸವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  Cheque_Designs:"ವಿನ್ಯಾಸಗಳನ್ನು ಪರಿಶೀಲಿಸಿ",
  No_Cheque_Designs:"ನೋ ಚೆಕ್ ವಿನ್ಯಾಸಗಳು",
  No_Cheque_Designs_Word:"ನಿಮ್ಮದೇ ಆದ <a routerLink='/folder/Settings/Cheque/Design/New'>Check Design</a> ಅನ್ನು ರಚಿಸಿ.",
  Set_Default:"ಪೂರ್ವನಿಯೋಜಿತವಾಗಿಡು",
  Default:"ಡೀಫಾಲ್ಟ್",
  Remove:"ತೆಗೆದುಹಾಕು",
  Folder:"ಫೋಲ್ಡರ್",
  Edit:"ತಿದ್ದು",
  LoadingDots:"ಲೋಡ್ ಆಗುತ್ತಿದೆ...",
  Add_a_New_File:"ಇದಕ್ಕೆ <a href='#' (click)='add()'>ಹೊಸ ಫೈಲ್</a> ಸೇರಿಸಿ",
  this_folder:"ಈ ಫೋಲ್ಡರ್",
  FTP_Backup_Settings:"FTP ಬ್ಯಾಕಪ್ ಸೆಟ್ಟಿಂಗ್‌ಗಳು",
  Secure_File_Transfer:"ಸುರಕ್ಷಿತ ಫೈಲ್ ವರ್ಗಾವಣೆ",
  New_Holiday:"ಹೊಸ ರಜಾದಿನ",
  Add_Holiday:"ಹಾಲಿಡೇ ಸೇರಿಸಿ",
  Holiday_Name:"ಹಾಲಿಡೇ ಹೆಸರು",
  Additional_Pay:"ಹೆಚ್ಚುವರಿ ವೇತನ",
  Enable_Holiday:"ರಜಾವನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Select_Holidays:"ರಜಾದಿನಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  No_Holidays:"ರಜಾದಿನಗಳಿಲ್ಲ",
  No_Holidays_Word:"ಮೊದಲ <a routerLink='/folder/Holiday/New'>ಸಾರ್ವಜನಿಕ ರಜಾದಿನವನ್ನು</a> ಸೇರಿಸಿ.",
  New_Import:"ಹೊಸ ಆಮದು",
  Import_Data:"ಡೇಟಾ ಆಮದು",
  Import_Data_Word:"ನೀವು ಆಯ್ಕೆಮಾಡುವ ಫೈಲ್ ಪ್ರಕಾರ ಅಥವಾ ಅಪ್‌ಲೋಡ್ ವಿಧಾನವನ್ನು ಆಯ್ಕೆಮಾಡಿ.<br /> ಬೆಂಬಲಿತ ಫೈಲ್ ಅನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿದ ನಂತರ ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿನ ಯಾವುದೇ ಪ್ಯಾರಾಮೀಟರ್‌ಗೆ ಸಂಬಂಧಿಸಿರುವ ಫೈಲ್‌ನಲ್ಲಿ ಆಮದು ಮಾಡಿದ ಕ್ಷೇತ್ರಗಳನ್ನು ನೀವು ಆಯ್ಕೆ ಮಾಡಲು ಸಾಧ್ಯವಾಗುತ್ತದೆ .", 
  Import_File:"ಫೈಲ್ ಆಮದು ಮಾಡಿ",
  Link_To_File:"ಫೈಲ್‌ಗೆ ಲಿಂಕ್",
  Select_File:"ಫೈಲ್ ಆಯ್ಕೆಮಾಡಿ",
  New_Moderator:"ಹೊಸ ಮಾಡರೇಟರ್",
  Allow_Moderation:"ಮಾಡರೇಶನ್ ಅನುಮತಿಸಿ",
  Create_Paycheques:"ಪಾವತಿಯನ್ನು ರಚಿಸಿ",
  Edit_Paycheques_and_Data:"ಪಾವತಿ ಚೆಕ್‌ಗಳು ಮತ್ತು ಡೇಟಾವನ್ನು ಸಂಪಾದಿಸಿ",
  Destroy_Data_and_Paycheques:"ಡೇಟಾ ಮತ್ತು ಪೇಚೆಕ್‌ಗಳನ್ನು ನಾಶಮಾಡಿ",
  Bonus_Percentage:"ಪಾವತಿಯ ಶೇಕಡಾವಾರು",
  Fixed_Amount:"ಸ್ಥಿರ ಮೊತ್ತ",
  Select_Moderator:"ಮಾಡರೇಟರ್ ಆಯ್ಕೆಮಾಡಿ",
  No_Moderators:"ಮಾಡರೇಟರ್‌ಗಳಿಲ್ಲ",
  Moderators:"ಮಾಡರೇಟರ್‌ಗಳು",
  Moderator_Account:"ಮೊದಲ <a routerLink='/folder/Administrator/New'>ಮಾಡರೇಟರ್ ಖಾತೆ</a> ಅನ್ನು ರಚಿಸಿ.",
  No_Moderators_Word:"ಮೊದಲ <a routerLink='/folder/Administrator/New'>ಮಾಡರೇಟರ್</a> ಅನ್ನು ಸೇರಿಸಿ.",
  Defaults:"ಡೀಫಾಲ್ಟ್",
  Provide_Us_Info:"ನಮಗೆ ಮಾಹಿತಿ ಒದಗಿಸಿ",
  Setup_Your_Printer:"ನಿಮ್ಮ ಮುದ್ರಕವನ್ನು ಹೊಂದಿಸಿ",
  Your_Company:"ನಿಮ್ಮ ಕಂಪನಿಯ ಬಗ್ಗೆ",
  Company_Name:"ಸಂಸ್ಥೆಯ ಹೆಸರು",
  Currency:"ಕರೆನ್ಸಿ",
  Default_Currency:"ಡೀಫಾಲ್ಟ್ ಕರೆನ್ಸಿ",
  Base_Monthly_Income:"ಮಾಸಿಕ ಆದಾಯ",
  Protection:"ರಕ್ಷಣೆ",
  App_Protection:"ಅಪ್ಲಿಕೇಶನ್ ರಕ್ಷಣೆ",
  PIN_Code_Protection:"ಪಿನ್ ಕೋಡ್ ರಕ್ಷಣೆ",
  App_Protection_Word:"ನಿಮ್ಮ ಖಾತೆಯನ್ನು ರಕ್ಷಿಸಲು ಸಹಾಯ ಮಾಡುವ ಭದ್ರತಾ ವಿಧಾನಗಳನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ.",
  PIN_Code:"ಪಿನ್ ಕೋಡ್",
  Change_PIN:"ಪಿನ್ ಬದಲಾಯಿಸಿ",
  New_Password:"ಹೊಸ ಪಾಸ್ವರ್ಡ್",
  Geofence_Protection:"ಜಿಯೋ-ಬೇಲಿ ರಕ್ಷಣೆ",
  Geofence_Area:"ಸೆಟ್ ಏರಿಯಾ",
  Distance:"ದೂರ",
  Setup_Now:"ಈಗ ಸೆಟಪ್ ಮಾಡಿ",
  Mile:"ಮೈಲಿ",
  Km:"ಕಿಮೀ",
  m:"m",
  Facial_Recognition:"ಮುಖ ಗುರುತಿಸುವಿಕೆ",
  Face:"ಮುಖ",
  Setup:"ಸೆಟಪ್",
  Label:"ಲೇಬಲ್",
  Password_Protection:"ಪಾಸ್ವರ್ಡ್ ರಕ್ಷಣೆ",
  Modify_Password:"ಪಾಸ್ವರ್ಡ್ ಮಾರ್ಪಡಿಸಿ",
  New_Tax_Entry:"ಹೊಸ ತೆರಿಗೆ ನಮೂದು",
  New_Tax:"ಹೊಸ ತೆರಿಗೆ",
  Tax_Label:"ತೆರಿಗೆ ಲೇಬಲ್",
  Perpetually_Enabled:"ಶಾಶ್ವತವಾಗಿ ಸಕ್ರಿಯಗೊಳಿಸಲಾಗಿದೆ",
  Select_Taxes:"ತೆರಿಗೆಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  Tax_Deductions:"ತೆರಿಗೆ ಕಡಿತಗಳು",
  No_Tax_Deductions:"ತೆರಿಗೆ ಕಡಿತಗಳಿಲ್ಲ",
  No_Tax_Deductions_Word:"ಮೊದಲ <a routerLink='/folder/Tax/New'>ತೆರಿಗೆ</a> ಕಡಿತವನ್ನು ಸೇರಿಸಿ.",
  New_Timer:"ಹೊಸ ಟೈಮರ್",
  Start:"ಪ್ರಾರಂಭ",
  Stop:"ನಿಲ್ಲಿಸು",
  Start_Timer:"ಟೈಮರ್ ಪ್ರಾರಂಭಿಸಿ",
  Stop_Timer:"ಸ್ಟಾಪ್ ಟೈಮರ್",
  Timer_Active:"ಟೈಮರ್ ಸಕ್ರಿಯ",
  Timer:"ಟೈಮರ್:",
  Timer_Running:"ಟೈಮರ್: (ರನ್ನಿಂಗ್)",
  Save_Timer:"ಟೈಮರ್ ಉಳಿಸಿ",
  New_Timesheet:"ಹೊಸ ಟೈಮ್ ಶೀಟ್",
  Select_Timesheets:"ಟೈಮ್ ಶೀಟ್‌ಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  Work_Notes:"ಕೆಲಸದ ಟಿಪ್ಪಣಿಗಳು",
  Entry_Title:"ಪ್ರವೇಶ ಶೀರ್ಷಿಕೆ",
  No_Timesheets:"ಸಮಯ ಹಾಳೆಗಳಿಲ್ಲ",
  No_Timesheets_Word:"ಮೊದಲ <a routerLink='/folder/Timesheet/New'>ವೇಳಾಚೀಟಿ</a> ಅನ್ನು ಸೇರಿಸಿ.",
  Timesheet_Submitted:"ಟೈಮ್ ಶೀಟ್ ಸಲ್ಲಿಸಲಾಗಿದೆ",
  Timesheet_Congrats:"ಅಭಿನಂದನೆಗಳು! ನಿಮ್ಮ ಸಮಯದ ಹಾಳೆಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆ. ಧನ್ಯವಾದಗಳು!",
  Timesheet_Copy:"ನಿಮ್ಮ ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳ ನಕಲನ್ನು ಸ್ವೀಕರಿಸಲು ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ವಿಳಾಸ ಮತ್ತು/ಅಥವಾ ಮೊಬೈಲ್ ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮಗೆ ಒದಗಿಸಿ.",
  Submit:"ಸಲ್ಲಿಸು",
  Allow_Notifications:"ಅಧಿಸೂಚನೆಗಳನ್ನು ಅನುಮತಿಸಿ",
  Untitled_Entry:"ಹೊಸ ಪ್ರವೇಶ",
  Untitled_Bank:"ಶೀರ್ಷಿಕೆಯಿಲ್ಲದ ಬ್ಯಾಂಕ್",
  Timesheet_Entry:"ಟೈಮ್ ಶೀಟ್ ಎಂಟ್ರಿ",
  Work_Description:"ಕೆಲಸದ ವಿವರಣೆ",
  Enable_Timesheet:"ಟೈಮ್ ಶೀಟ್ ಅನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Submit_Timesheet:"ಟೈಮ್ ಶೀಟ್ ಸಲ್ಲಿಸಿ",
  Vacation:"ರಜೆ",
  New_Vacation:"ಹೊಸ ರಜೆ",
  Vacation_Name:"ರಜೆಯ ಹೆಸರು",
  Paid_Vacation:"ಪಾವತಿಸಿದ ರಜೆ",
  Vacation_Pay:"ರಜೆಯ ವೇತನ",
  Enable_Vacation:"ರಜೆಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Select_Vacations:"ರಜೆಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  No_Vacations:"ರಜೆಗಳಿಲ್ಲ",
  No_Vacations_Word:"ಮೊದಲ <a routerLink='/folder/Vacation/New'>ರಜೆ</a> ನಮೂದನ್ನು ರಚಿಸಿ.",
  Payroll_Schedule:"ವೇತನ ಪಟ್ಟಿ",
  Next_Payroll:"ಮುಂದಿನ ವೇತನದಾರರ ಪಟ್ಟಿ:",
  Upcoming_Payroll:"ಮುಂಬರುವ ವೇತನದಾರರ ಪಟ್ಟಿ",
  No_Upcoming_Payroll:"ಮುಂಬರುವ ವೇತನ ಪಟ್ಟಿ ಇಲ್ಲ",
  Address_Book:"ವಿಳಾಸ ಪುಸ್ತಕ",
  Archived_Documents:"ಆರ್ಕೈವ್ ಮಾಡಿದ ದಾಖಲೆಗಳು",
  Dev_Mode:"ಅಭಿವೃದ್ಧಿ ಕ್ರಮದಲ್ಲಿ ಅಪ್ಲಿಕೇಶನ್",
  Administrators:"ನಿರ್ವಾಹಕರು",
  Privacy:"ಗೌಪ್ಯತೆ",
  None:"ಯಾವುದೂ",
  Select_Signature:"ಸಹಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  No_Signatures:"ಯಾವುದೇ ಸಹಿಗಳಿಲ್ಲ",
  No_Signatures_Word:"ಮೊದಲ <a routerLink='/folder/Signature/New'>ಸಹಿ</a> ಅನ್ನು ಸೇರಿಸಿ.",
  Repeat_Indefinitely:"ಅನಿರ್ದಿಷ್ಟವಾಗಿ ಪುನರಾವರ್ತಿಸಿ",
  Sign:"ಸಹಿ",
  Sign_Here:"ಇಲ್ಲಿ ರುಜು ಹಾಕಿ",
  Date_Signed:"ಸಹಿ ಮಾಡಿದ ದಿನಾಂಕ",
  Signature_Pad:"ಸಿಗ್ನೇಚರ್ ಪ್ಯಾಡ್",
  Account_Holder:"ಖಾತೆದಾರ",
  Account_Properties:"ಖಾತೆ ಗುಣಲಕ್ಷಣಗಳು",
  Name_On_Cheque:"ಚೆಕ್‌ನಲ್ಲಿ ಹೆಸರು",
  Server_Hostname:"ಸರ್ವರ್ ಹೋಸ್ಟ್ ನೇಮ್ / ಐಪಿ",
  Printers:"ಮುದ್ರಕಗಳು",
  No_Printers:"ಮುದ್ರಕಗಳಿಲ್ಲ",
  Printer_Exists:'ಈ ಹೆಸರಿನ ಪ್ರಿಂಟರ್ ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ.',
  No_Printers_Word:"ಮೊದಲ <a routerLink='/folder/Printer/New'>ಮುದ್ರಕ</a> ಅನ್ನು ಸೇರಿಸಿ.",
  No_Printer_Alert:"ಯಾವುದೇ ಪ್ರಿಂಟರ್ ಅನ್ನು ವಿವರಿಸಲಾಗಿಲ್ಲ. ನೀವು ಪ್ರಿಂಟರ್ ಅನ್ನು ಹೊಂದಿಸಲು ಬಯಸುವಿರಾ?",
  Add_Printer:"ಪ್ರಿಂಟರ್ ಸೇರಿಸಿ",
  New_Printer:"ಹೊಸ ಮುದ್ರಕ",
  Printer_Hostname:"ಪ್ರಿಂಟರ್ ಹೋಸ್ಟ್ ಹೆಸರು / ಐಪಿ",
  Printer_Label:"ಪ್ರಿಂಟರ್ ಲೇಬಲ್",
  Printer_Protocol:"ಪ್ರಿಂಟರ್ ಪ್ರೋಟೋಕಾಲ್",
  Protocol:"ಶಿಷ್ಟಾಚಾರ",
  Email_Print:"ಇಮೇಲ್",
  AirPrint:"ಏರ್ಪ್ರಿಂಟ್",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"ಸಾಕೆಟ್",
  Print_Job:"ಮುದ್ರಣ ಕೆಲಸ",
  Printed:"ಮುದ್ರಿತ",
  Not_Printed:"ಮುದ್ರಿತವಾಗಿಲ್ಲ",
  Print_Jobs:"ಮುದ್ರಣ ಉದ್ಯೋಗಗಳು",
  Print_Queue:"ಪ್ರಿಂಟ್ ಕ್ಯೂ",
  No_Print_Jobs:"ಮುದ್ರಣ ಕೆಲಸಗಳಿಲ್ಲ",
  No_Prints:"ಮುದ್ರಿಸಲು ಹೊಸ <a routerLink='/folder/Cheque/New'>ಪರಿಶೀಲಿಸಿ</a> ಅನ್ನು ರಚಿಸಿ.",
  Prints:"ಮುದ್ರಣಗಳು",
  Find_Printer:"ಪ್ರಿಂಟರ್ ಹುಡುಕಿ",
  Find_AirPrint_Devices:"ಏರ್ಪ್ರಿಂಟ್ ಸಾಧನಗಳನ್ನು ಹುಡುಕಿ",
  Select_Printer:"ಪ್ರಿಂಟರ್ ಆಯ್ಕೆಮಾಡಿ",
  System_UI:"ಸಿಸ್ಟಮ್ UI",
  Printer:"ಮುದ್ರಕ",
  Status:"ಸ್ಥಿತಿ",
  Preview:"ಮುನ್ನೋಟ",
  Enable_Print_Job:"ಮುದ್ರಣ ಕೆಲಸವನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Queue_Weight:"ಸರದಿ ತೂಕ",
  Unlimited:"ಅನಿಯಮಿತ",
  Show_Advanced_Printer_Options:"ಸುಧಾರಿತ ಪ್ರಿಂಟರ್ ಆಯ್ಕೆಗಳನ್ನು ತೋರಿಸು",
  Advanced:"ಸುಧಾರಿತ",
  Location:"ಸ್ಥಳ",
  Note:"ಸೂಚನೆ",
  Queue_Name:"ಸರದಿ ಹೆಸರು",
  Pages_Printed_Limit:"ಪುಟಗಳ ಮುದ್ರಿತ ಮಿತಿ",
  MultiPage_Idle_Time:"ಮಲ್ಟಿಪೇಜ್ ವೇಟ್ ಟೈಮ್ (ಗಳು)",
  Page_Count_Limit:"ಪುಟ ಎಣಿಕೆ ಮಿತಿ",
  Page_Orientation:"ಪುಟ ದೃಷ್ಟಿಕೋನ",
  Portrait:"ಭಾವಚಿತ್ರ",
  Landscape:"ಲ್ಯಾಂಡ್ಸ್ಕೇಪ್",
  Duplex:"ಡ್ಯೂಪ್ಲೆಕ್ಸ್",
  Double_Sided_Printing:"ಡಬಲ್ ಸೈಡೆಡ್",
  Duplex_Mode:"ಡ್ಯೂಪ್ಲೆಕ್ಸ್ ಮೋಡ್",
  Duplex_Short:"ಸಣ್ಣ",
  Duplex_Long:"ಉದ್ದ",
  Duplex_None:"ಯಾವುದೂ",
  Color_And_Quality:"ಬಣ್ಣ ಮತ್ತು ಗುಣಮಟ್ಟ",
  Monochrome:"ಮೊನೊಕ್ರೋಮ್",
  Photo_Quality_Prints:"ಫೋಟೋ ಗುಣಮಟ್ಟದ ಮುದ್ರಣಗಳು",
  Printer_Email:"ಪ್ರಿಂಟರ್ ಇಮೇಲ್",
  Automatically_Downsize:"ಸ್ವಯಂಚಾಲಿತವಾಗಿ ಕಡಿಮೆಗೊಳಿಸು",
  Paper:"ಪೇಪರ್",
  Paper_Name:"ಕಾಗದದ ಹೆಸರು",
  Paper_Width:"ಪೇಪರ್ ಅಗಲ",
  Paper_Height:"ಕಾಗದದ ಎತ್ತರ",
  Paper_Autocut_Length:"ಪೇಪರ್ ಸ್ವಯಂ-ಕಟ್ ಉದ್ದ",
  Margins:"ಅಂಚುಗಳು",
  Page_Margins:"ಪುಟ ಅಂಚುಗಳು",
  Page_Margin_Top:"ಟಾಪ್ ಪೇಜ್ ಮಾರ್ಜಿನ್",
  Page_Margin_Right:"ಬಲ ಪುಟದ ಅಂಚು",
  Page_Margin_Bottom:"ಕೆಳ ಪುಟದ ಅಂಚು",
  Page_Margin_Left:"ಎಡ ಪುಟದ ಅಂಚು",
  Add_Employees:"ನೌಕರರನ್ನು ಸೇರಿಸಿ",
  Header:"ಶೀರ್ಷಿಕೆ",
  Print_A_Page_Header:"ಪ್ರಿಂಟ್ ಎ ಪೇಜ್ ಹೆಡರ್",
  Header_Label:"ಹೆಡರ್ ಲೇಬಲ್",
  Header_Page_Index:"ಹೆಡರ್ ಪೇಜ್ ಇಂಡೆಕ್ಸ್",
  Header_Font_Name:"ಹೆಡರ್ ಫಾಂಟ್",
  Select_Font:"ಫಾಂಟ್ ಆಯ್ಕೆಮಾಡಿ",
  Font_Selector:"ಫಾಂಟ್ ಸೆಲೆಕ್ಟರ್",
  Header_Font_Size:"ಹೆಡರ್ ಫಾಂಟ್",
  Header_Bold:"ಹೆಡರ್ ಬೋಲ್ಡ್",
  Header_Italic:"ಹೆಡರ್ ಇಟಾಲಿಕ್",
  Header_Alignment:"ಹೆಡರ್ ಅಲೈನ್ಮೆಂಟ್",
  Left:"ಎಡ",
  Center:"ಕೇಂದ್ರ",
  Right:"ಬಲ",
  Justified:"ಸಮರ್ಥನೆ",
  Header_Font_Color:"ಹೆಡರ್ ಬಣ್ಣ",
  Select_Color:"ಬಣ್ಣವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  Footer:"ಅಡಿಟಿಪ್ಪಣಿ",
  Print_A_Page_Footer:"ಪ್ರಿಂಟ್ ಎ ಪೇಜ್ ಫೂಟರ್",
  Footer_Label:"ಅಡಿಟಿಪ್ಪಣಿ ಲೇಬಲ್",
  Footer_Page_Index:"ಅಡಿಟಿಪ್ಪಣಿ ಪುಟ ಸೂಚ್ಯಂಕ",
  Footer_Font_Name:"ಫೂಟರ್ ಫಾಂಟ್",
  Fonts:"ಫಾಂಟ್‌ಗಳು",
  Done:"ಮುಗಿದಿದೆ",
  Select_Fonts:"ಫಾಂಟ್‌ಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  Footer_Font_Size:"ಅಡಿಟಿಪ್ಪಣಿ ಗಾತ್ರ",
  Footer_Bold:"ಫೂಟರ್ ಬೋಲ್ಡ್",
  Footer_Italic:"ಫೂಟರ್ ಇಟಾಲಿಕ್",
  Footer_Alignment:"ಅಡಿ ಜೋಡಣೆ",
  Footer_Font_Color:"ಅಡಿ ಬಣ್ಣ",
  Page_Copies:"ಪುಟ ಪ್ರತಿಗಳು",
  Enable_Printer:"ಪ್ರಿಂಟರ್ ಅನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Remote_Logging:"ರಿಮೋಟ್ ಲಾಗಿಂಗ್",
  Log_Server:"ಲಾಗ್ ಸರ್ವರ್",
  Encryption:"ಎನ್‌ಕ್ರಿಪ್ಶನ್",
  Random_Key:"ಯಾದೃಚ್ಛಿಕ ಕೀ",
  Encryption_Key:"ಎನ್‌ಕ್ರಿಪ್ಶನ್ ಕೀ",
  Cheques_Webserver:"ಕಸ್ಟಮ್ ಡೇಟಾಬೇಸ್",
  Learn_How:"ಹೇಗೆಂದು ಕಲಿ",
  Signature:"ಸಹಿ",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"ಸಹಿಯನ್ನು ವೀಕ್ಷಿಸಿ",
  Printed_Signature:"ಮುದ್ರಿತ ಸಹಿ",
  Digitally_Sign:"ಡಿಜಿಟಲ್ ಸೈನ್",
  Digital_Signature:"ಡಿಜಿಟಲ್ ಸಹಿ",
  Add_Signature:"ಸಹಿ ಸೇರಿಸಿ",
  Add_Your_Signature:"ನಿಮ್ಮ ಸಹಿಯನ್ನು ಸೇರಿಸಿ",
  Enable_Signature:"ಸಹಿಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿ",
  Digitally_Signed:"ಡಿಜಿಟಲ್ ಸಹಿ",
  Insert_Error:"ಡೇಟಾಬೇಸ್ ಸಮಸ್ಯೆಗಳಿಂದಾಗಿ ಚೆಕ್ ಅನ್ನು ಉಳಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ.",
  Delete_Confirmation:"ಈ ಮಾಹಿತಿಯನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Discard_Confirmation:"ಈ ಮಾಹಿತಿಯನ್ನು ತ್ಯಜಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Discard_Bank_Confirmation:"ಈ ಖಾತೆಯನ್ನು ತ್ಯಜಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Discard_Printer_Confirmation:"ಈ ಮುದ್ರಕವನ್ನು ತ್ಯಜಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Delete_Bonus_Confirmation:"ಈ ಬೋನಸ್ ಅನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Delete_Invoice_Confirmation:"ಈ ಸರಕುಪಟ್ಟಿ ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Generated_Cheque:"ರಚಿಸಿದ ಚೆಕ್",
  Generated_Invoice:"ರಚಿಸಿದ ಸರಕುಪಟ್ಟಿ",
  Schedule_Start_Time:"ವೇಳಾಪಟ್ಟಿ ಪ್ರಾರಂಭ",
  Schedule_End_Time:"ವೇಳಾಪಟ್ಟಿ ಅಂತ್ಯ",
  New_Call:"ಹೊಸ ಕರೆ",
  No_Contacts:"ಸಂಪರ್ಕಗಳಿಲ್ಲ",
  No_Contacts_Word:"ನಿರ್ವಾಹಕರು, ಮಾಡರೇಟರ್‌ಗಳು, ಉದ್ಯೋಗಿಗಳು ಮತ್ತು ಪಾವತಿದಾರರು ಸಂಪರ್ಕಗಳಾಗಿ ಕಾಣಿಸಿಕೊಳ್ಳುತ್ತಾರೆ.",
  PDF_Subject:"ಹಣಕಾಸು",
  PDF_Keywords:"ವೇತನ ಪಾವತಿಯ PDF ಚೆಕ್ ಚೆಕ್",
  Printer_Setup:"ಪ್ರಿಂಟರ್ ಸೆಟಪ್",
  Printer_Selection:"ಪ್ರಿಂಟರ್ ಆಯ್ಕೆ",
  New_Fax:"ಹೊಸ ಫ್ಯಾಕ್ಸ್",
  New_Fax_Message:"ಹೊಸ ಫ್ಯಾಕ್ಸ್ ಸಂದೇಶ",
  Fax_Machine:"ಫ್ಯಾಕ್ಸ್ ಯಂತ್ರ",
  Fax_Name:"ಫ್ಯಾಕ್ಸ್ ಹೆಸರು",
  Fax_Email:"ಫ್ಯಾಕ್ಸ್ ಇಮೇಲ್",
  Fax_Number:"ಫ್ಯಾಕ್ಸ್ ಸಂಖ್ಯೆ",
  Contents:"ವಿಷಯ",
  Fax_Body:"ಪೇಜ್ ಬಾಡಿ",
  Header_Word:"ಶೀರ್ಷಿಕೆ:",
  Header_Text:"ಶೀರ್ಷಿಕೆ ಪಠ್ಯ",
  Include_Header:"ಹೆಡರ್ ಸೇರಿಸಿ",
  Include_Footer:"ಅಡಿಟಿಪ್ಪಣಿ ಸೇರಿಸಿ",
  Footer_Word:"ಅಡಿಟಿಪ್ಪಣಿ:",
  Footer_Text:"ಅಡಿಟಿಪ್ಪಣಿ ಪಠ್ಯ",
  Attach_a_Cheque:"ಚೆಕ್ ಅನ್ನು ಲಗತ್ತಿಸಿ",
  Add_Deduction:"ಕಡಿತವನ್ನು ಸೇರಿಸಿ",
  Enable_Fax:"ಫ್ಯಾಕ್ಸ್ ಕಳುಹಿಸಿ",
  Select_Fax:"ಫ್ಯಾಕ್ಸ್ ಆಯ್ಕೆಮಾಡಿ",
  No_Faxes:"ಫ್ಯಾಕ್ಸ್‌ಗಳಿಲ್ಲ",
  Faxes:"ಫ್ಯಾಕ್ಸ್",
  Save_and_Send:"ಫ್ಯಾಕ್ಸ್ ಕಳುಹಿಸಿ",
  Save_and_Pay:"ಉಳಿಸಿ ಮತ್ತು ಪಾವತಿಸಿ",
  WARNING:"ಎಚ್ಚರಿಕೆ:",
  Remember:"ನೆನಪಿಡಿ",
  Printing:"ಮುದ್ರಣ",
  Printing_Complete:"ಮುದ್ರಣ ಪೂರ್ಣಗೊಂಡಿದೆ!\n\n",
  of:"ನ",
  There_Were:"ಇದ್ದವು",
  Successful_Prints:"ಯಶಸ್ವಿ ಮುದ್ರಣಗಳು ಮತ್ತು",
  Unsuccessful_Prints:"ವಿಫಲವಾದ ಮುದ್ರಣಗಳು.",
  PrinterError:"ಕ್ಷಮಿಸಿ! ದೋಷ ಕಂಡುಬಂದಿದೆ.",
  Printing_:"ಮುದ್ರಣ...",
  PrinterSuccess:"ಡಾಕ್ಯುಮೆಂಟ್ ಯಶಸ್ವಿಯಾಗಿ ಮುದ್ರಿತವಾಗಿದೆ.",
  PrintersSuccess:"ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಮುದ್ರಿಸಲಾಗಿದೆ.",
  Print_Cheques:"ಪ್ರಿಂಟ್ ಚೆಕ್",
  New_Message:"ಹೊಸ ಸಂದೇಶ",
  New_Messages:"ಹೊಸ ಸಂದೇಶಗಳು",
  Read_Message:"ಸಂದೇಶವನ್ನು ಓದಿ",
  Write_Message:"ಸಂದೇಶ ಬರೆಯಿರಿ",
  Send_Message:"ಸಂದೇಶ ಕಳುಹಿಸಿ",
  Subject:"ವಿಷಯ",
  Message:"ಸಂದೇಶ",
  Writing:"ಬರಹ...",
  Send:"ಕಳುಹಿಸು",
  Set_Date:"ದಿನಾಂಕ ನಿಗದಿಪಡಿಸಿ",
  Set_Time:"ಸಮಯವನ್ನು ಹೊಂದಿಸಿ",
  Recieve:"ಸ್ವೀಕರಿಸಿ",
  Set_as_Default:"ಪೂರ್ವನಿಯೋಜಿತವಾಗಿಡು",
  Default_Account:"ಡೀಫಾಲ್ಟ್ ಖಾತೆ",
  Default_Design:"ಡೀಫಾಲ್ಟ್ ವಿನ್ಯಾಸ",
  Multiple_Cheques:"ಚೆಕ್‌ಗಳು (ಟ್ರಿಪಲ್)",
  Account_Mode:"ಖಾತೆ ಮೋಡ್",
  Multiple_Cheques_Description:"ಪ್ರತಿ ಪುಟಕ್ಕೆ ಮೂರು ಚೆಕ್‌ಗಳು.",
  Check_and_Table:"ಚೆಕ್ ಮತ್ತು ಟೇಬಲ್",
  Check_including_Table:"ಪರಿಶೀಲನೆ ಮತ್ತು ಲೆಕ್ಕಪತ್ರ ಕೋಷ್ಟಕ.",
  Check_Mailer:"ಮೇಲರ್ ಪರಿಶೀಲಿಸಿ",
  Check_Mailer_Window:"ವಿಳಾಸ ವಿಂಡೋದೊಂದಿಗೆ ಪರಿಶೀಲಿಸಿ.",
  DocuGard_Table_Top:"ಡಾಕ್ಯುಗಾರ್ಡ್ ಚೆಕ್ & ಟೇಬಲ್ (ಟಾಪ್)",
  DocuGard_Table_Middle:"ಡಾಕ್ಯುಗಾರ್ಡ್ ಚೆಕ್ & ಟೇಬಲ್ (ಮಧ್ಯ)",
  DocuGard_Table_Bottom:"ಡಾಕ್ಯುಗಾರ್ಡ್ ಚೆಕ್ & ಟೇಬಲ್ (ಕೆಳಭಾಗ)",
  DocuGard_Mailer_Top:"ಡಾಕ್ಯುಗಾರ್ಡ್ ಚೆಕ್ ಮೈಲರ್ (ಟಾಪ್)",
  DocuGard_Mailer_Middle:"ಡಾಕ್ಯುಗಾರ್ಡ್ ಚೆಕ್ ಮೈಲರ್ (ಮಧ್ಯ)",
  DocuGard_Mailer_Bottom:"ಡಾಕ್ಯುಗಾರ್ಡ್ ಚೆಕ್ ಮೈಲರ್ (ಕೆಳಭಾಗ)",
  DocuGard_Three_Cheques:"ಡಾಕ್ಯುಗಾರ್ಡ್ ತಪಾಸಣೆಗಳು (ಟ್ರಿಪಲ್)",
  DocuGard_Cheque_Top:"ಡಾಕ್ಯುಗಾರ್ಡ್ ಚೆಕ್ (ಟಾಪ್)",
  DocuGard_Cheque_Middle:"ಡಾಕ್ಯುಗಾರ್ಡ್ ಚೆಕ್ (ಮಧ್ಯ)",
  DocuGard_Cheque_Bottom:"ಡಾಕ್ಯುಗಾರ್ಡ್ ಚೆಕ್ (ಕೆಳಭಾಗ)",
  DocuGard_Three_Cheques_Window:"ಒಂದು ಪುಟದಲ್ಲಿ ಮೂರು ಚೆಕ್‌ಗಳು.",
  DocuGard_Table_Top_Window:"ಪರಿಶೀಲನೆ ಮತ್ತು ಗಳಿಕೆಯ ಕೋಷ್ಟಕ.",
  DocuGard_Table_Middle_Window:"ಪರಿಶೀಲನೆ ಮತ್ತು ಗಳಿಕೆಯ ಕೋಷ್ಟಕ.",
  DocuGard_Table_Bottom_Window:"ಪರಿಶೀಲನೆ ಮತ್ತು ಗಳಿಕೆಯ ಕೋಷ್ಟಕ.",
  DocuGard_Mailer_Top_Window:"ಚೆಕ್, ಟೇಬಲ್ ಮತ್ತು ವಿಳಾಸ.",
  DocuGard_Mailer_Middle_Window:"ಚೆಕ್, ಟೇಬಲ್ ಮತ್ತು ವಿಳಾಸ.",
  DocuGard_Mailer_Bottom_Window:"ಚೆಕ್, ಟೇಬಲ್ ಮತ್ತು ವಿಳಾಸ.",
  DocuGard_Cheque_Top_Window:"ಸುರಕ್ಷಿತ ಕಾಗದಕ್ಕಾಗಿ ಪರಿಶೀಲಿಸಿ.",
  DocuGard_Cheque_Middle_Window:"ಸುರಕ್ಷಿತ ಕಾಗದಕ್ಕಾಗಿ ಪರಿಶೀಲಿಸಿ.",
  DocuGard_Cheque_Bottom_Window:"ಸುರಕ್ಷಿತ ಕಾಗದಕ್ಕಾಗಿ ಪರಿಶೀಲಿಸಿ.",
  Basic_Cheque:"ಚೆಕ್ (ಮೇಲ್ಭಾಗ)",
  Basic_Cheque_Print:"ಒಂದೇ ಚೆಕ್ ಅನ್ನು ಮುದ್ರಿಸು.",
  Error_Setting_As_Paid:"ಪಾವತಿಸಿದಂತೆ ಹೊಂದಿಸುವಲ್ಲಿ ದೋಷ",
  Add_Attachment:"ಲಗತ್ತನ್ನು ಸೇರಿಸಿ",
  PrinterUnavailable:"ಪ್ರಿಂಟರ್ ಲಭ್ಯವಿಲ್ಲ",
  CreditCardComponent:"ಕಾರ್ಡ್‌ಗಳು",
  PaypalComponent:"ಪೇಪಾಲ್",
  InteracComponent:"ಇಂಟರಾಕ್",
  BitcoinComponent:"ಬಿಟ್‌ಕಾಯಿನ್",
  New_Deposit:"ಹೊಸ ಠೇವಣಿ",
  Deposits:"ಠೇವಣಿ",
  No_Deposits:"ಠೇವಣಿಗಳಿಲ್ಲ",
  Credit_Card_Deposit:"ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್",
  New_Credit_Card_Deposit_Message:"ಕ್ರೆಡಿಟ್ ಕಾರ್ಡ್ ಠೇವಣಿ",
  New_BitCoin_Deposit:"ಬಿಟ್‌ಕಾಯಿನ್",
  New_BitCoin_Deposit_Message:"ಬಿಟ್‌ಕಾಯಿನ್ ಠೇವಣಿ",
  New_Interac_Deposit:"ಇಂಟರಾಕ್",
  New_Interac_Deposit_Message:"ಇಂಟರಾಕ್ ವರ್ಗಾವಣೆ",
  Payments_Limit:"ಪಾವತಿ ಮಿತಿ",
  No_Payment_Limit:"ಪಾವತಿ ಮಿತಿ ಇಲ್ಲ",
  PayPal_Deposit:"ಪೇಪಾಲ್",
  PayPal_Deposit_Message:"ಪೇಪಾಲ್ ಠೇವಣಿ",
  No_Deposits_Word:"ಮೊದಲ ಕಂಪನಿ <a routerLink='/folder/Deposit/New'>ಠೇವಣಿ</a> ಸೇರಿಸಿ.",
  No_Documents_Specified:"ಮುದ್ರಣಕ್ಕಾಗಿ ಯಾವುದೇ ದಾಖಲೆಗಳನ್ನು ನಿರ್ದಿಷ್ಟಪಡಿಸಲಾಗಿಲ್ಲ",
  No_Printers_Added:"ಯಾವುದೇ ಮುದ್ರಕಗಳು ಕಂಡುಬಂದಿಲ್ಲ. ಒಂದನ್ನು ಸೇರಿಸಲು ಸೆಟ್ಟಿಂಗ್‌ಗಳು > ಪ್ರಿಂಟರ್‌ಗಳಿಗೆ ಹೋಗಿ.",
  DB_Erase_Prompt:"ಸಂಪೂರ್ಣ ಡೇಟಾಬೇಸ್ ಅನ್ನು ಸಂಪೂರ್ಣವಾಗಿ ಅಳಿಸುವುದೇ? ಎಚ್ಚರಿಕೆ: ನೀವು ಎಲ್ಲಾ ಸಂಗ್ರಹಿಸಿದ ಮಾಹಿತಿಯನ್ನು ಕಳೆದುಕೊಳ್ಳುತ್ತೀರಿ!",
  Console_Warning:"ಈ ಕನ್ಸೋಲ್‌ನಲ್ಲಿ ಯಾವುದೇ ಪಠ್ಯವನ್ನು ಅಂಟಿಸಬೇಡಿ. ನೀವು ನಿಮ್ಮನ್ನು ಮತ್ತು/ಅಥವಾ ನಿಮ್ಮ ಕಂಪನಿಯನ್ನು ಗಂಭೀರ ಅಪಾಯಕ್ಕೆ ಸಿಲುಕಿಸಬಹುದು.",
  No_Faxes_Word:"ಮೊದಲ <a routerLink='/folder/Fax/New'>Fax</a> ಅನ್ನು ರಚಿಸಿ.",
  Cheque_Delete_Confirmation:"ಈ ಚೆಕ್ ಅನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Design_Delete_Confirmation:"ಈ ವಿನ್ಯಾಸವನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Cheque_Pay_Confirmation:"ಈ ಚೆಕ್ ಅನ್ನು ಪಾವತಿಸಲಾಗಿದೆ ಎಂದು ಗುರುತಿಸುವುದೇ? ಇದು ಪ್ರಿಂಟ್ ಕ್ಯೂನಲ್ಲಿ ಕಾಣಿಸುವುದಿಲ್ಲ.",
  Payment_Pay_Confirmation:"ಈ ಪಾವತಿಯನ್ನು ಪಾವತಿಸಲಾಗಿದೆ ಎಂದು ಗುರುತಿಸುವುದೇ? ಇದು ಚೆಕ್ ಸರದಿಯಲ್ಲಿ ಕಾಣಿಸುವುದಿಲ್ಲ.",
  Delete_Deduction_Confirmation:"ಈ ಕಡಿತವನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Delete_Job_Confirmation:"ಈ ಕೆಲಸವನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Delete_Timesheet_Confirmation:"ಈ ಟೈಮ್‌ಶೀಟ್ ಅನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Delete_Schedule_Confirmation:"ಈ ವೇಳಾಪಟ್ಟಿಯನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Delete_Setting_Confirmation:"ಈ ಸೆಟ್ಟಿಂಗ್ ಅನ್ನು ಮರುಹೊಂದಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Delete_Fax_Confirmation:"ಈ ಫ್ಯಾಕ್ಸ್ ಅನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Delete_File_Confirmation:"ಈ ಫೈಲ್ ಅನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Delete_Vacation_Confirmation:"ಈ ರಜೆಯನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Delete_Printer_Confirmation:"ಈ ಮುದ್ರಕವನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Remove_Design_Confirmation:"ಈ ವಿನ್ಯಾಸವನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Delete_Payroll_Confirmation:"ಈ ವೇತನದಾರರನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Send_Fax_Email_Confirmation:"ನೀವು ಈ ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳನ್ನು ಫ್ಯಾಕ್ಸ್ ಮಾಡಲು ಮತ್ತು ಇಮೇಲ್ ಮಾಡಲು ಬಯಸುವಿರಾ?",
  Send_Email_Confirmation:"ನೀವು ಈ ಡಾಕ್ಯುಮೆಂಟ್ ಅನ್ನು ಇಮೇಲ್ ಮಾಡಲು ಬಯಸುವಿರಾ?",
  Send_Fax_Confirmation:"ನೀವು ಈ ಡಾಕ್ಯುಮೆಂಟ್ ಅನ್ನು ಫ್ಯಾಕ್ಸ್ ಮಾಡಲು ಬಯಸುವಿರಾ?",
  Error_Generating_PDF:"ಕ್ಷಮಿಸಿ. ಈ ಡಾಕ್ಯುಮೆಂಟ್ ಅನ್ನು ರಚಿಸುವಲ್ಲಿ ದೋಷ ಕಂಡುಬಂದಿದೆ.",
  PDF_Error_Saving_Image:"ಕ್ಷಮಿಸಿ. ಈ ಡಾಕ್ಯುಮೆಂಟ್‌ನ PDF ಚಿತ್ರವನ್ನು ಉಳಿಸುವಲ್ಲಿ ದೋಷ ಕಂಡುಬಂದಿದೆ.",
  Test_Printer_Confirmation:"ನೀವು ಈ ಪ್ರಿಂಟರ್‌ನಲ್ಲಿ ಪರೀಕ್ಷಾ ಪುಟವನ್ನು ಮುದ್ರಿಸಲು ಬಯಸುವಿರಾ?",
  Save_Timesheet_Prompt:"ದಯವಿಟ್ಟು 'ಶೀರ್ಷಿಕೆ' ಸೇರಿಸಿ ಅಥವಾ ಉಳಿಸಲು 'ಸ್ಟಾರ್ಟ್ ಟೈಮರ್' ಒತ್ತಿರಿ.",
  Remove_Geofence_Prompt:"ಈ ಜಿಯೋ-ಬೇಲಿಯ ಸ್ಥಳವನ್ನು ತೆಗೆದುಹಾಕಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  Delete_Employee_Confirmation:"ನೀವು ಅಳಿಸಲು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ",
  Fire_Employee_Confirmation:"ನೀವು ಗುಂಡು ಹಾರಿಸಲು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ"
}