export const Or = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"କପିରାଇଟ୍ & କପି; 2023",
  black:"କଳା",
  green:"ସବୁଜ",
  gold:"ସୁନା |",
  blue:"ନୀଳ",
  brown:"ବ୍ରାଉନ୍ |",
  purple:"ବାଇଗଣୀ |",
  pink:"ଗୋଲାପୀ |",
  red:"ଲାଲ୍ |",
  Swatches:"ସ୍ୱିଚ୍",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"ହୁ",
  Saturation:"ସାଚୁଚରେସନ୍",
  Lightness:"ହାଲୁକା",
  Upgrade_To_Pro:"ପ୍ରୋକୁ ଅପଗ୍ରେଡ୍ କରନ୍ତୁ |",
  AllFeaturesInclude:"ସମସ୍ତ ବ Features ଶିଷ୍ଟ୍ୟ ଅନ୍ତର୍ଭୂକ୍ତ କରେ:",
  PrintUnlimitedCheques:"ଅସୀମିତ ଚେକ୍ ପ୍ରିଣ୍ଟ୍ କରନ୍ତୁ |",
  PremiumChequeDesigns:"ପ୍ରିମିୟମ୍ ଚେକ୍ ଡିଜାଇନ୍ସ |",
  ManageUnlimitedEmployees:"ଅସୀମିତ କର୍ମଚାରୀ ପରିଚାଳନା କରନ୍ତୁ |",
  AddUnlimitedPayees:"ଅସୀମିତ ଦେୟ ଯୋଗକର |",
  CreateUnlimitedPayrolls:"ଅସୀମିତ ବେତନ ସୃଷ୍ଟି କରନ୍ତୁ |",
  UnlimitedSchedulesandTimeSheets:"ଅସୀମିତ କାର୍ଯ୍ୟସୂଚୀ ଏବଂ ସମୟ ସିଟ୍ |",
  BulkPayPalPayouts:"ବଲ୍କ ପେପାଲ୍ ପେଆଉଟ୍ |",
  UnlimitedBankAccounts:"ଅସୀମିତ ବ୍ୟାଙ୍କ ଖାତା |",
  ManageMultipleCompanies:"ଏକାଧିକ କମ୍ପାନୀ ପରିଚାଳନା କରନ୍ତୁ |",
  TrackInsurancePolicies:"ବୀମା ନୀତିଗୁଡିକ ଟ୍ରାକ୍ କରନ୍ତୁ |",
  Bio_MetricProtection:"ବାୟୋ-ମେଟ୍ରିକ୍ ସୁରକ୍ଷା |",
  Geo_FenceLock_OutProtection:"ଜିଓ-ଫେନ୍ସ ଲକ୍-ଆଉଟ୍ ସୁରକ୍ଷା |",
  Multiple_Companies_Word:"ଚେକ୍ ପ୍ରିମିୟମ୍ ବିନା ଏକାଧିକ କମ୍ପାନୀ ପରିଚାଳନା ଉପଲବ୍ଧ ନୁହେଁ |",
  PayPal_Payouts_Word:"ଚେକ୍ ପ୍ରିମିୟମ୍ ବିନା ପେପାଲ୍ ଦେୟ ଅକ୍ଷମ ହୋଇଛି |",
  PINProtection:"ପିନ୍ ସୁରକ୍ଷା",
  PasswordProtection:"ପାସୱାର୍ଡ ସୁରକ୍ଷା",
  May_Require_Approval:"ଅନୁମୋଦନ ଆବଶ୍ୟକ କରିପାରନ୍ତି |",
  Subscribe:"ସବସ୍କ୍ରାଇବ କରନ୍ତୁ |",
  Billed:"ବିଲ୍",
  Up:"ଅପ୍",
  Down:"ତଳକୁ |",
  Positioning:"ଅବସ୍ଥାନ",
  Marker:"ମାର୍କର",
  Drag_Marker:"ମାର୍କର୍ ଡ୍ରାଗ୍ କରନ୍ତୁ |",
  Tagline:"ଚେକ୍ ଏବଂ ଟ୍ୟାବୁଲେଟ୍ ବେତନ ପ୍ରିଣ୍ଟ୍ କରନ୍ତୁ |",
  Marker_Word:"ଉପାଦାନକୁ ଆକାର ଦେବା ପାଇଁ ମାର୍କର୍ସ ବ୍ୟବହାର କରନ୍ତୁ |",
  Pinch_Zoom:"ପିଚ୍ ଜୁମ୍ |",
  Pinch_Word:"ଉପାଦାନକୁ ଜୁମ୍ କରିବାକୁ ପିଚ୍ କରନ୍ତୁ |",
  Drag:"ଡ୍ରାଗ୍ କରନ୍ତୁ |",
  Drag_Word:"ଉପାଦାନଗୁଡ଼ିକୁ ଟାଣିବା ପାଇଁ ଆପଣଙ୍କର ଆଙ୍ଗୁଠି ବ୍ୟବହାର କରନ୍ତୁ |",
  subscription_alias_word:"ସଦସ୍ୟତା ସ୍ୱୟଂ-ନବୀକରଣ |",
  premium_alias_word:"ଏକ-ସମୟ ଅପଗ୍ରେଡ୍ ପ୍ୟାକେଜ୍ |",
  print_alias_word:"ବ୍ୟକ୍ତିଗତ ଯାଞ୍ଚ ପ୍ରିଣ୍ଟ-ଆଉଟ୍ |",
  mode_alias_word:"ମୋଡ୍",
  Pro:"ପ୍ରୋ",
  Pro_word:"ପ୍ରୋ ସଂସ୍କରଣ ଆବଶ୍ୟକ |",
  Cant_Delete_Default_Company_Word:"ଦୁ Sorry ଖିତ, ଆପଣ ଆପଣଙ୍କର ଡିଫଲ୍ଟ କମ୍ପାନୀ ବିଲୋପ କରିପାରିବେ ନାହିଁ |",
  Reinsert_Default_Designs:"ଡିଫଲ୍ଟ ଡିଜାଇନ୍ଗୁଡ଼ିକୁ ପୁନ - ସନ୍ନିବେଶ କରନ୍ତୁ |",
  Reinsert_Default_Designs_word:"ଆପଣ ଡିଫଲ୍ଟ ଡିଜାଇନ୍ଗୁଡ଼ିକୁ ପୁନ - ସନ୍ନିବେଶ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Subscription_Active_Disable_Word:"ଏହି ସଦସ୍ୟତା ସକ୍ରିୟ ଅଛି | ଚେକ୍ ପାଇଁ ଏହି ସବସ୍କ୍ରିପସନ୍ ବାତିଲ୍ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Company_Logo:"କମ୍ପାନୀ ଲୋଗୋ |",
  ZERO_:"ଜିରୋ |",
  Disclaimer:"ପ୍ରତ୍ୟାଖ୍ୟାନ |",
  Privacy_Policy:"ଗୋପନୀୟତା ନୀତି",
  EULA:"EULA ଯାଞ୍ଚ କରେ |",
  Terms_Of_Service:"ସେବା ସର୍ତ୍ତାବଳୀ",
  Terms_Of_Use:"ବ୍ୟବହାର ସର୍ତ୍ତାବଳୀ",
  Refunds:"ଫେରସ୍ତ ନୀତି",
  Single_Print:"1 ଯାଞ୍ଚ କରନ୍ତୁ |",
  Two_Prints:"2 ଯାଞ୍ଚ |",
  Five_Prints:"5 ଯାଞ୍ଚ |",
  Ten_Prints:"10 ଯାଞ୍ଚ",
  Fifteen_Prints:"15 ଯାଞ୍ଚ",
  Twenty_Prints:"20 ଯାଞ୍ଚ",
  Thirty_Prints:"30 ଯାଞ୍ଚ",
  Image_Added:"ପ୍ରତିଛବି ଯୋଡା ଯାଇଛି |",
  Image_Preview:"ପ୍ରତିଛବି ପୂର୍ବାବଲୋକନ |",
  No_Image_Was_Selected:"କ image ଣସି ପ୍ରତିଛବି ଚୟନ ହୋଇନାହିଁ |",
  Cheques_Unlimited:"ଅସୀମିତ ଯାଞ୍ଚ କରେ |",
  _Subscription:"ସଦସ୍ୟତା",
  Subscription:"ଯାଞ୍ଚ - 1 ମାସ |",
  Two_Month_Subscription:"ଯାଞ୍ଚ - 2 ମାସ |",
  Three_Month_Subscription:"ଯାଞ୍ଚ - 3 ମାସ |",
  Six_Month_Subscription:"ଯାଞ୍ଚ - 6 ମାସ |",
  Twelve_Month_Subscription:"ଯାଞ୍ଚ - 12 ମାସ |",
  Cheques_Are_Available:"ଚେକ୍ ପ୍ରିଣ୍ଟ୍ କରିବାକୁ ଉପଲବ୍ଧ |",
  Upgrade_Required_Two:"ଆପଣଙ୍କର କ୍ରୟ ଆରମ୍ଭ କରିବା ପାଇଁ ଏକ ପ୍ୟାକେଜ୍ ଚୟନ କରନ୍ତୁ ଏବଂ ମୂଲ୍ୟ ବଟନ୍ ଉପରେ ଦୁଇଥର ଟ୍ୟାପ୍ କରନ୍ତୁ | ପେସାଦାର ଦେଖାଯାଉଥିବା ପୂର୍ଣ୍ଣ ରଙ୍ଗର ଯାଞ୍ଚଗୁଡ଼ିକୁ ସେକେଣ୍ଡରେ ମୁଦ୍ରଣ କରନ୍ତୁ |",
  Month:"ମାସ",
  Due:"ଦେୟ:",
  Expires:"ମିଆଦ ପୂର୍ଣ୍ଣ:",
  Subscription_Active:"ସଦସ୍ୟତା ସକ୍ରିୟ |",
  Subscription_Inactive:"ସଦସ୍ୟତା ନିଷ୍କ୍ରିୟ |",
  Purchase_Additional_Cheques:"ଅତିରିକ୍ତ ଚେକ୍ କିଣନ୍ତୁ?",
  Printable_Cheque:"ମୁଦ୍ରଣଯୋଗ୍ୟ ଯାଞ୍ଚ |",
  Printable_Cheques:"ମୁଦ୍ରଣଯୋଗ୍ୟ ଯାଞ୍ଚଗୁଡ଼ିକ |",
  Printable_Cheque_Word:"ଚେକ୍ ଆପଣଙ୍କ ଆକାଉଣ୍ଟରେ ଉପଲବ୍ଧ |",
  Printable_Cheques_Word:"ଆପଣଙ୍କ ଆକାଉଣ୍ଟରେ ଚେକ୍ ଉପଲବ୍ଧ |",
  Max_Amount_Message:"ଆପଣ ନିର୍ଦ୍ଦିଷ୍ଟ କରିଥିବା ପରିମାଣ ଏହି ସିଷ୍ଟମ୍ ପାଇଁ ସେଟ୍ ହୋଇଥିବା ସର୍ବାଧିକ ରାଶି ଉପରେ ପହଞ୍ଚିଛି:",
  Terms_Required_Word:"ଚେକ୍ ବ୍ୟବହାର ଜାରି ରଖିବା ପୂର୍ବରୁ ଆପଣ ଏହି ଚୁକ୍ତିରେ ରାଜି ହେବା ଜରୁରୀ |",
  Subscriptions:"ସଦସ୍ୟତା",
  Product_Upgrades:"ଉନ୍ନୟନ",
  Mailed_Out_Cheques:"ମେଲ-ଆଉଟ୍ ଯାଞ୍ଚ |",
  Enter_A_Company_Name:"ଦୟାକରି ଏକ କମ୍ପାନୀ ନାମ ପ୍ରବେଶ କରନ୍ତୁ |",
  Single_Cheques:"ଏକକ ଯାଞ୍ଚ |",
  Cheque_Golden:"ଗୋଲଡେନ୍ ଚେକ୍ |",
  Cheque_Golden_Window:"ସୁବର୍ଣ୍ଣ ଯାଞ୍ଚ ଡିଜାଇନ୍ |",
  Cheque_Green:"ସବୁଜ ଯାଞ୍ଚ |",
  Cheque_Green_Window:"ସବୁଜ ଯାଞ୍ଚ ଡିଜାଇନ୍ |",
  Cheque_Red:"ରେଡ୍ ଚେକ୍ |",
  Cheque_Red_Window:"ରେଡ୍ ଚେକ୍ ଡିଜାଇନ୍ |",
  Cheque_Yellow:"ହଳଦିଆ ଯାଞ୍ଚ |",
  Cheque_Yellow_Window:"ହଳଦିଆ ଯାଞ୍ଚ ଡିଜାଇନ୍ |",
  Cheque_Statue_of_Liberty:"ଷ୍ଟାଚୁ ଅଫ ଲିବର୍ଟି",
  Cheque_Statue_of_Liberty_Window:"ଷ୍ଟାଚ୍ୟୁ ଅଫ୍ ଲିବର୍ଟି ଚେକ୍ ଡିଜାଇନ୍ |",
  Cheque_Green_Wave:"ସବୁଜ ତରଙ୍ଗ |",
  Cheque_Green_Wave_Window:"ସବୁଜ ଯାଞ୍ଚ ଡିଜାଇନ୍ |",
  Cheque_Golden_Weave:"ସୁବର୍ଣ୍ଣ ବୁଣା |",
  Cheque_Golden_Weave_Window:"ସୁସଜ୍ଜିତ ସୁବର୍ଣ୍ଣ ଯାଞ୍ଚ ଡିଜାଇନ୍ |",
  Cheque_Green_Sun:"ସବୁଜ ସୂର୍ଯ୍ୟ |",
  Cheque_Green_Sun_Window:"ଗଭୀର ଏବଂ ଶାନ୍ତକାରୀ ଯାଞ୍ଚ ଡିଜାଇନ୍ |",
  Cheque_Blue_Checkers:"ନୀଳ ଚେକର୍ସ |",
  Cheque_Blue_Checkers_Window:"ବ୍ଲୁ ଚେକ୍ ଡିଜାଇନ୍ |",
  Cashiers_Check:"କ୍ୟାସିଅର୍ ଚେକ୍ |",
  Cashiers_Check_Window:"କ୍ୟାସିଅର୍ ଚେକ୍ ଷ୍ଟାଇଲ୍ ଟେମ୍ପଲେଟ୍ |",
  Cheque_Aqua_Checkers:"କୁମ୍ଭ ଯାଞ୍ଚକାରୀ |",
  Cheque_Aqua_Checkers_Window:"କୁମ୍ଭ ଯାଞ୍ଚ ଡିଜାଇନ୍ |",
  Cheque_Golden_Checkers:"ସୁବର୍ଣ୍ଣ ଚେକର୍ସ |",
  Cheque_Golden_Checkers_Window:"ସୁବର୍ଣ୍ଣ ଯାଞ୍ଚ ଡିଜାଇନ୍ |",
  Upgrade_Unavailable:"ଅପଗ୍ରେଡ୍ ଉପଲବ୍ଧ ନାହିଁ",
  Bank_Code_Protection:"ବ୍ୟାଙ୍କ ନମ୍ବର ସୁରକ୍ଷା",
  Bank_Code_Protection_Word:"ଅନ୍ୟ ଡିଭାଇସରେ କିମ୍ବା ଅନ୍ୟ ଉପଭୋକ୍ତା ପାଇଁ ଚାଲୁଥିବା ଏହି ଆପରେ ଆପଣଙ୍କର ବ୍ୟାଙ୍କ ନମ୍ବରକୁ ବ୍ୟବହାର କରନ୍ତୁ | ଏହି କ୍ରିୟା IRREVERSIBLE ଅଟେ | ଜାରି ରଖନ୍ତୁ?",
  Bank_Code_Protection_Blocked_Word:"ଆପଣ ବ୍ୟବହାର କରିବାକୁ ଚେଷ୍ଟା କରୁଥିବା ବ୍ୟାଙ୍କ ନମ୍ବର ଅନ୍ୟ ଉପଭୋକ୍ତା କିମ୍ବା ଉପକରଣ ପାଇଁ ସଂରକ୍ଷିତ |",
  Bank_Code_Protection_Error_Word:"ସଂଖ୍ୟା ଯାଞ୍ଚ ବିଫଳ ହୋଇଛି | ଦୟାକରି ଇଣ୍ଟରନେଟ୍ ସହିତ ସଂଯୋଗ କରନ୍ତୁ ଏବଂ ଏହି ବ୍ୟାଙ୍କ ଆକାଉଣ୍ଟକୁ ପୁଣି ଯୋଡିବାକୁ ଚେଷ୍ଟା କରନ୍ତୁ |",
  Bank_Code_Protection_Set_Error_Word:"ବ୍ୟାଙ୍କ ନମ୍ବର ସୁରକ୍ଷା ଆବଶ୍ୟକ କରେ ଯେ ଆପଣ ଇଣ୍ଟରନେଟ୍ ସହିତ ସଂଯୁକ୍ତ ଅଟନ୍ତି | ଦୟାକରି ସଂଯୋଗ କରନ୍ତୁ ଏବଂ ପୁନର୍ବାର ଚେଷ୍ଟା କରନ୍ତୁ |",
  Upgrade_Unavailable_Word:"ଦୁ Sorry ଖିତ, ଆମେ ଆପଣଙ୍କୁ ଯାଞ୍ଚ କରିବାରେ ଅସୁବିଧା ଭୋଗୁଛୁ | ଚେକ୍ ପାଇଁ ସବସ୍କ୍ରିପସନ୍ ଏବଂ ଅପଗ୍ରେଡ୍ ବର୍ତ୍ତମାନ ଆପଣଙ୍କ ଅଞ୍ଚଳରେ କ୍ରୟ ପାଇଁ ଉପଲବ୍ଧ ନାହିଁ |",
  PayPal_Payment_Preview:"ପେପାଲ୍ ଦେୟ ପୂର୍ବାବଲୋକନ |",
  Apple_Pay:"ଆପଲ୍ ପେ |",
  Select_PayPal:"PayPal ଚୟନ କରନ୍ତୁ |",
  PayPal_Applications:"ପେପାଲ୍ ଆପ୍ଲିକେସନ୍",
  Purchase_With_Apple_Pay:"ଆପଲ୍ ପେ ସହିତ କ୍ରୟ କରନ୍ତୁ |",
  Google_Pay:"ଗୁଗୁଲ୍ ପେ |",
  Companies:"କମ୍ପାନୀଗୁଡିକ",
  Insurance:"ବୀମା",
  New_PayPal:"ନୂତନ ପେପାଲ୍ |",
  Pay_By:"ଦ୍ By ାରା ଦେୟ",
  Insure:"ବୀମା",
  Miles:"ମାଇଲ୍",
  Payment_Method:"ଅର୍ଥ ପ୍ରଦାନ ପଦ୍ଧତି",
  Select_Policies:"ନୀତି ଚୟନ କରନ୍ତୁ |",
  Policies:"ନୀତି",
  Policy:"ନୀତି",
  No_PayPal_Account:"କ Pay ଣସି ପେପାଲ ଆକାଉଣ୍ଟ ନାହିଁ |",
  No_Policies:"କ Insurance ଣସି ବୀମା ନୀତି ନାହିଁ |",
  New_Policy:"ନୂତନ ନୀତି",
  PayPal_Payment:"ପେପାଲ୍ ଦେୟ |",
  PayPal_Payments:"ପେପାଲ୍ ଦେୟ",
  No_Payment_Selected:"କ Pay ଣସି ଦେୟ ଚୟନ ହୋଇନାହିଁ |",
  Sending_Payment_Word:"ଦୟାକରି ଅପେକ୍ଷା କରନ୍ତୁ ... ଏହି ଦେୟ ପଠାଯାଉଛି |",
  Sending_Payments_Word:"ଦୟାକରି ଅପେକ୍ଷା କରନ୍ତୁ ... ଦେୟ ପଠାଯାଉଛି |",
  No_Payment_Selected_PayPal:"No ଣସି <a routerLink='/folder/Payments'>PayPal ଦେୟ </a> ଚୟନ କରାଯାଇ ନାହିଁ |",
  Payment_Sent:"ଦେୟ ପଠାଯାଇଛି |",
  PayPal_Payment_Sent:"ଏହି ଦେୟ ପେପାଲ ସହିତ ପଠାଯାଇଛି |",
  Copay:"କପି କରନ୍ତୁ |",
  Dead:"ମୃତ",
  Alive:"ଜୀବିତ |",
  Not_Dead:"ମୃତ ନୁହେଁ |",
  Unclaimed:"ଅନାବଶ୍ୟକ",
  Attempted:"ଚେଷ୍ଟା",
  Deceased:"ହତାଶ |",
  Claimed:"ଦାବି କରାଯାଇଛି",
  Unpaid:"ଦେୟମୁକ୍ତ |",
  Sending_Payment:"ଦେୟ ପଠାଇବା |",
  Sending_Payments:"ଦେୟ ପଠାଇବା |",
  Send_PayPal_Confirmation:"ଆପଣ ପେପାଲ ସହିତ ଏହି କାରବାର ପଠାଇବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Send_PayPal_Confirmation_Word:"ଏହି କାରବାର ପଠାଇବାକୁ ସବୁଜ ବଟନ୍ ଦବାନ୍ତୁ |",
  Save_Payment_As_Unpaid:"ଏହି ଦେୟକୁ ଅନାଦାୟ ଭାବରେ ସଞ୍ଚୟ କରନ୍ତୁ?",
  Payment_Pay_Confirmation_PayPal:"ଦେୟ ପରି ଏହି ଦେୟ ସଞ୍ଚୟ କରନ୍ତୁ?",
  No_Policies_Word:"ପ୍ରଥମ <a routerLink='/folder/Postage/New'>ବୀମା ପଲିସି </a> ଯୋଡନ୍ତୁ |",
  Timesheet_Multiple_Delete_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏକାଧିକ ଥର ଶୀଟ୍ ଡିଲିଟ୍ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Select_Multiple_Timesheets_Prompt:"କ times ଣସି ଟାଇମ୍ସିଟ୍ ଚୟନ ହୋଇନାହିଁ | ଅତିକମରେ ଗୋଟିଏ ଥରସିଟ୍ ଚୟନ କରନ୍ତୁ |",
  Select_Multiple_Policies_Prompt:"କ policies ଣସି ନୀତି ଚୟନ ହୋଇନାହିଁ | ଅତିକମରେ ଗୋଟିଏ ବୀମା ପଲିସି ବାଛନ୍ତୁ |",
  Policies_Multiple_Delete_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏକାଧିକ ନୀତି ବିଲୋପ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Company:"କମ୍ପାନୀ",
  Add_Company:"କମ୍ପାନୀ ଯୋଡନ୍ତୁ |",
  New_Company:"କମ୍ପାନୀ ଯୋଡନ୍ତୁ |",
  No_Companies:"କ No ଣସି କମ୍ପାନୀ ନାହିଁ |",
  Enable_Company:"କମ୍ପାନୀ ସକ୍ଷମ କରନ୍ତୁ |",
  Select_Company:"କମ୍ପାନୀ ଚୟନ କରନ୍ତୁ |",
  The_Default_Company:"ଡିଫଲ୍ଟ କମ୍ପାନୀ ଲେବଲ୍ |",
  Manage_Companies:"କମ୍ପାନୀଗୁଡିକ ପରିଚାଳନା କରନ୍ତୁ |",
  No_Companies_Word:"କମ୍ପାନୀ ବ୍ୟବହାର କରିବ | <br /> <a routerLink='/folder/Company/New'>ପ୍ରଥମ କମ୍ପାନୀ </a> ଯୋଡନ୍ତୁ |",
  Default_Company:"ଡିଫଲ୍ଟ କମ୍ପାନୀ",
  Cheques_Unlimited_Word:"ଚେକ୍ ଅନଲିମିଟେଡ୍ ଆପଣଙ୍କୁ ଯେତିକି ଚେକ୍ ପ୍ରିଣ୍ଟ୍ କରିବାକୁ ଅନୁମତି ଦିଏ |",
  Cheques_Subscription_Word:"ଏକ ଚେକ୍ ସବସ୍କ୍ରିପସନ୍ ଆପଣଙ୍କୁ ଯେତିକି ଚେକ୍ ପ୍ରିଣ୍ଟ୍ କରିବାକୁ ଅନୁମତି ଦିଏ |",
  You_Own_This_Product:"ଆପଣ ଏହି ଉତ୍ପାଦର ମାଲିକ ଅଟନ୍ତି |",
  Your_Subscription_is_Active:"ଆପଣଙ୍କର ସଦସ୍ୟତା ସକ୍ରିୟ ଅଛି |",
  Active_Products:"ସକ୍ରିୟ ଉତ୍ପାଦଗୁଡିକ |",
  Purchase_Confirmation:"କ୍ରୟ କରନ୍ତୁ |",
  Purchase_Cheques:"ଚେକ୍ କ୍ରୟ କରନ୍ତୁ |",
  Restore_Purchase:"କ୍ରୟଗୁଡିକ ପୁନରୁଦ୍ଧାର କରନ୍ତୁ |",
  Erase_Purchase:"କ୍ରୟଗୁଡିକ ଲିଭାନ୍ତୁ |",
  Successfully_Purchased:"ସଫଳତାର ସହିତ କ୍ରୟ କରାଯାଇଛି |",
  Enter_Your_Licence_Key:"ଏହି ଉତ୍ପାଦକୁ ସକ୍ରିୟ କରିବାକୁ ଦୟାକରି ଏହି ପୃଷ୍ଠାରେ ଆପଣଙ୍କର ଲାଇସେନ୍ସ କୀ ପ୍ରବେଶ କରନ୍ତୁ |",
  Licence_Key:"ଲାଇସେନ୍ସ କୀ",
  Enter_Licence_Key:"ଲାଇସେନ୍ସ କୀ ପ୍ରବେଶ କରନ୍ତୁ |",
  Purchased:"କିଣାଯାଇଛି",
  Enable_Feature:"ବ Feat ଶିଷ୍ଟ୍ୟ ସକ୍ଷମ କରନ୍ତୁ |",
  Cancel_Subscription:"ସଦସ୍ୟତା ବାତିଲ କରନ୍ତୁ |",
  Subscription_Removed:"ସଦସ୍ୟତା ଅପସାରିତ ହୋଇଛି |",
  Select_Active_Subscription:"ଏହାକୁ ସଂଶୋଧନ କରିବା ପାଇଁ ଏକ ସକ୍ରିୟ ସଦସ୍ୟତା ବାଛନ୍ତୁ |",
  Remove_Subscription_Word:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ସଦସ୍ୟତା ବାତିଲ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Delete_Company_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ପୁରା କମ୍ପାନୀ ବିଲୋପ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି? ସତର୍କବାଣୀ: ଆପଣ ସମସ୍ତ ସଂରକ୍ଷିତ ସୂଚନା ହରାଇବେ!",
  Delete_Default_Company_Word:"ଆପଣ ଡିଫଲ୍ଟ କମ୍ପାନୀ ବିଲୋପ କରିପାରିବେ ନାହିଁ | ଆପଣ ଅନୁପ୍ରୟୋଗକୁ ପୁନ res ସେଟ୍ କରିବାକୁ ଏବଂ ଏହାକୁ ଡିଫଲ୍ଟ ସ୍ଥିତିକୁ ଫେରାଇବାକୁ ଚାହୁଁଛନ୍ତି କି? ସତର୍କବାଣୀ: ଆପଣ ସମସ୍ତ ସଂରକ୍ଷିତ ସୂଚନା ହରାଇବେ!",
  Console_Warning_2:"ଏହି ଅନୁପ୍ରୟୋଗ ବ୍ୟବହାର କରି କ currency ଣସି ମୁଦ୍ରା ପରିଚାଳନା କରନ୍ତୁ ନାହିଁ ଯାହା ବର୍ତ୍ତମାନ ଆପଣଙ୍କର ନୁହେଁ |",
  Terms_and_Conditions:"ସର୍ତ୍ତାବଳୀ",
  and_the:"ଏବଂ",
  for:"ପାଇଁ",
  Please_Read_Word:"ଦୟାକରି ପ read ନ୍ତୁ ଏବଂ ଏଥିରେ ସହମତ ହୁଅନ୍ତୁ |",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"କିଛି ମୁଦ୍ରା ରୂପାନ୍ତର ହାର ମିଳିଲା ନାହିଁ | ଦୟାକରି ଇଣ୍ଟରନେଟ୍ ସହିତ ସଂଯୋଗ କରନ୍ତୁ |",
  Free:"ମାଗଣା |",
  DB_Erase_Prompt_2:"ସମଗ୍ର ଡେଭଲପର୍ ଡାଟାବେସ୍ ସଂପୂର୍ଣ୍ଣ ଭାବରେ ଲିଭାନ୍ତୁ? ସତର୍କବାଣୀ: ଆପଣ ସମସ୍ତ ପୁରସ୍କାର ଏବଂ ସବସ୍କ୍ରିପସନ୍ ସୂଚନା ହରାଇବେ!",
  Successfully_Imported:"ସଫଳତାର ସହିତ ଆମଦାନୀ ହୋଇଛି |",
  Select_Postage:"ଡାକଘର ଚୟନ କରନ୍ତୁ |",
  No_Postage:"କ Post ଣସି ଡାକ ଷ୍ଟାମ୍ପ ନାହିଁ |",
  No_Paid_Postage_Word:"ପ୍ରଥମ <a routerLink='/folder/Postage/New'>ପେଡ୍ ପୋଷ୍ଟେଜ୍ </a> ଷ୍ଟାମ୍ପ୍ ଯୋଡନ୍ତୁ |",
  Trial_Complete:'ପରୀକ୍ଷା ସମ୍ପୂର୍ଣ୍ଣ',
  Please_Upgrade:'ମୁଦ୍ରଣ ଜାରି ରଖିବାକୁ ଦୟାକରି ଚେକ୍ ଅପଗ୍ରେଡ୍ କରନ୍ତୁ |',
  Aa:"Aa",
  Color:"ରଙ୍ଗ",
  Font:"ଫଣ୍ଟ",
  Guide:"ଗାଇଡ୍",
  Guides:"ଗାଇଡ୍",
  Image:"ପ୍ରତିଛବି |",
  Zoom:"ଜୁମ୍ କରନ୍ତୁ |",
  Logo:"ଲୋଗୋ",
  Bank:"ବ୍ୟାଙ୍କ",
  MICR:"MICR",
  Total:"ସମୁଦାୟ",
  Cancel:"ବାତିଲ୍ କରନ୍ତୁ |",
  Confirm:"ନିଶ୍ଚିତ କରନ୍ତୁ |",
  Method:"ପଦ୍ଧତି",
  Biometric_Security:"ବାୟୋ-ମେଟ୍ରିକ୍ ସୁରକ୍ଷା |",
  Please_Login_To_Continue:"ଜାରି ରଖିବାକୁ ଦୟାକରି ଲଗଇନ୍ କରନ୍ତୁ |",
  Complete:"ସଂପୂର୍ଣ୍ଣ",
  Sign_Up:"ସାଇନ୍ ଅପ୍ କରନ୍ତୁ",
  Error:"ତ୍ରୁଟି |",
  Biometrics:"ବାୟୋ-ମେଟ୍ରିକ୍ସ |",
  Percent:"ଶତକଡା",
  Zero_Percent:"0%",
  Top_Margin:"ଟପ୍ ମାର୍ଜିନ୍ |",
  Bottom_Margin:"ନିମ୍ନ ମାର୍ଜିନ୍ |",
  Left_Margin:"ବାମ ମାର୍ଜିନ୍",
  Right_Margin:"ଡାହାଣ ମାର୍ଜିନ୍ |",
  MICR_Margin:"MICR ମାର୍ଜିନ୍ |",
  Table_Margin:"ଟେବୁଲ୍ ମାର୍ଜିନ୍ |",
  Address_Margin:"ଠିକଣା ମାର୍ଜିନ୍ |",
  Percentage_:"ଶତକଡା",
  Vacation_Title:"ଛୁଟି ଆଖ୍ୟା",
  Use_PIN:"PIN ବ୍ୟବହାର କରନ୍ତୁ |",
  Loading__:"ଲୋଡିଂ ...",
  Design_Title:"ଡିଜାଇନ୍ ଆଖ୍ୟା |",
  Authorize:"ପ୍ରାଧିକରଣ",
  Key:"ଚାବି",
  Public_Key:"ସାର୍ବଜନୀନ ଚାବି |",
  Private_Key:"ବ୍ୟକ୍ତିଗତ ଚାବି |",
  Authenticate:"ପ୍ରାମାଣିକିକରଣ |",
  Last_Payroll:"ଶେଷ ବେତନ",
  Last_Calculation:"ଶେଷ ଗଣନା",
  Authorized:"ପ୍ରାଧିକୃତ",
  Geo_Authorized:"ଜିଓ-ଅବସ୍ଥାନ: ପ୍ରାଧିକୃତ |",
  Not_Authorized:"ପ୍ରାଧିକୃତ ନୁହେଁ |",
  Authorization_Complete:"ପ୍ରାଧିକରଣ ସଂପୂର୍ଣ୍ଣ |",
  Geolocation_Authorization:"ଜିଓ-ଅବସ୍ଥାନ ପ୍ରାଧିକରଣ |",
  Out_of_Bounds:"ସୀମା ବାହାରେ",
  Cant_Delete_Default_Design:"ଏକ ଡିଫଲ୍ଟ ଡିଜାଇନ୍ ବିଲୋପ କରିପାରିବ ନାହିଁ |",
  Unauthorized_Out_of_Bounds:"ଅନଧିକୃତ: ସୀମା ବାହାରେ |",
  Biometric_Authorization:"ବାୟୋ-ମେଟ୍ରିକ୍ ପ୍ରାଧିକରଣ |",
  Locating_Please_Wait:"ଅବସ୍ଥାନ, ଦୟାକରି ଅପେକ୍ଷା କରନ୍ତୁ ...",
  Test_Biometrics:"ବାୟୋ-ମେଟ୍ରିକ୍ସ ପରୀକ୍ଷା କରନ୍ତୁ |",
  Cheque_Margins:"ମାର୍ଜିନ୍ ଯାଞ୍ଚ କରନ୍ତୁ |",
  Percentage_Full_Error:"ଶତକଡ଼ା କ୍ଷେତ୍ର 100% ରୁ ଅଧିକ ସେଟ୍ ହୋଇପାରିବ ନାହିଁ |",
  No_Payroll_Text:"ଏକ <a routerLink='/folder/Employee/New'> କର୍ମଚାରୀ </a> କିମ୍ବା <a routerLink='/folder/Payee/New'> ଦେୟ </a> ଏବଂ ଏକ <a routerLink='/folder/Schedule/New'> କାର୍ଯ୍ୟସୂଚୀ </a>।",
  Design_Saved:"ଡିଜାଇନ୍ ସଞ୍ଚୟ ହୋଇଛି |",
  Default_Design_Selected:"ଡିଫଲ୍ଟ ଡିଜାଇନ୍ ଚୟନ କରାଯାଇଛି |",
  Partial_Import:"ଆଂଶିକ ଆମଦାନୀ |",
  Partial_Export:"ଆଂଶିକ ରପ୍ତାନି",
  Entire_Import:"ସମଗ୍ର ଆମଦାନୀ |",
  Entire_Export:"ସମଗ୍ର ରପ୍ତାନି |",
  Existing_Password:"ଦୟାକରି ଆପଣଙ୍କର ବିଦ୍ୟମାନ ପାସୱାର୍ଡ ପ୍ରବେଶ କରନ୍ତୁ:",
  Existing_PIN:"ଦୟାକରି ଆପଣଙ୍କର ବିଦ୍ୟମାନ PIN କୋଡ୍ ପ୍ରବେଶ କରନ୍ତୁ:",
  Pin_Change_Header:"PIN ନିଶ୍ଚିତକରଣ |",
  Pin_Change_SubHeader:"ପରିବର୍ତ୍ତନକୁ ନିଶ୍ଚିତ କରିବାକୁ ଆପଣଙ୍କର ପୁରୁଣା PIN ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ |",
  Pass_Change_Header:"ପାସୱାର୍ଡ ନିଶ୍ଚିତକରଣ",
  Pass_Change_SubHeader:"ପରିବର୍ତ୍ତନକୁ ନିଶ୍ଚିତ କରିବାକୁ ଆପଣଙ୍କର ପୁରୁଣା ପାସୱାର୍ଡ ପ୍ରବେଶ କରନ୍ତୁ |",
  PIN_Enter_Message:"ନିଶ୍ଚିତ କରିବାକୁ ଆପଣଙ୍କର PIN ପ୍ରବେଶ କରନ୍ତୁ |",
  Password_Enter_Message:"ନିଶ୍ଚିତ କରିବାକୁ ଆପଣଙ୍କର ପାସୱାର୍ଡ ପ୍ରବେଶ କରନ୍ତୁ |",
  Pin_Updated_Success:"PIN ସଫଳତାର ସହିତ ଅଦ୍ୟତନ ହେଲା!",
  Pin_Updated_Fail:"PIN ଅଦ୍ୟତନ ହୋଇପାରିଲା ନାହିଁ |",
  Pass_Updated_Success:"ପାସୱାର୍ଡ ସଫଳତାର ସହିତ ଅଦ୍ୟତନ ହୋଇଛି |",
  Pass_Updated_Fail:"ପାସୱାର୍ଡ ଅପଡେଟ୍ ହୋଇପାରିଲା ନାହିଁ |",
  Preview_Payment:"ଦେୟ ପୂର୍ବାବଲୋକନ",
  Preview_Payroll:"ଦରମା ପୂର୍ବାବଲୋକନ କରନ୍ତୁ |",
  No_Payments_Created:"କ payments ଣସି ଦେୟ ସୃଷ୍ଟି ହେବାକୁ ମିଳିଲା ନାହିଁ |",
  Payment_Preview:"ଦେୟ ପୂର୍ବାବଲୋକନ |",
  Enable_Payee:"ଦେୟକୁ ସକ୍ଷମ କରନ୍ତୁ |",
  Rendered:"ଉପସ୍ଥାପିତ |",
  No_Email:"କ No ଣସି ଇମେଲ୍ ନାହିଁ |",
  Setup_Cheques:"ସେଟଅପ୍ ଯାଞ୍ଚ |",
  name:"ନାମ",
  address:"ଠିକଣା",
  address_2:"ଠିକଣା ରେଖା 2 |",
  city:"ସହର",
  province:"ପ୍ରଦେଶ",
  postal_code:"ଡାକ / ଜିପ୍ କୋଡ୍ |",
  country:"ଦେଶ",
  username:"ଉପଯୋଗକର୍ତ୍ତା ନାମ",
  full_name:"ପୁରା ନାମ",
  birthday:"ଜନ୍ମଦିନ",
  email:"ଇମେଲ୍ କରନ୍ତୁ |",
  phone:"ଫୋନ୍ |",
  employees:"କର୍ମଚାରୀ",
  addresses:"ଠିକଣା",
  payment_amount_limit:"ଦେୟ ରାଶି ସୀମା |",
  total_limit:"ସମୁଦାୟ ସୀମା |",
  payees:"ଦେୟ",
  description:"ବର୍ଣ୍ଣନା",
  address_line_one:"ଠିକଣା ଲାଇନ୍ ୱାନ୍ |",
  address_line_two:"ଠିକଣା ଲାଇନ୍ ଦୁଇ |",
  image:"ପ୍ରତିଛବି |",
  account_holder:"ଖାତା ଧାରକ |",
  cheque_starting_id:"ଆରମ୍ଭ ID ଯାଞ୍ଚ କରନ୍ତୁ |",
  transit_number:"ଗମନାଗମନ ସଂଖ୍ୟା",
  institution_number:"ଅନୁଷ୍ଠାନ ସଂଖ୍ୟା |",
  designation_number:"ନାମକରଣ ସଂଖ୍ୟା",
  account_number:"ଖାତା ସଂଖ୍ୟା",
  currency:"ମୁଦ୍ରା",
  signature:"ଦସ୍ତଖତ",
  payment_payroll_limit:"ଦେୟ ସୀମା",
  total_limi:"ସମୁଦାୟ ସୀମା |",
  date:"ତାରିଖ",
  printed_memo:"ମୁଦ୍ରିତ ମେମୋ |",
  banks:"ବ୍ୟାଙ୍କଗୁଡିକ",
  signature_image:"ଦସ୍ତଖତ ପ୍ରତିଛବି |",
  address_name:"ଠିକଣା ନାମ",
  notes:"ଟିପ୍ପଣୀ |",
  design:"ଡିଜାଇନ୍ |",
  title:"ଆଖ୍ୟା",
  frequency:"ଫ୍ରିକ୍ୱେନ୍ସି",
  fax:"ଫ୍ୟାକ୍ସ",
  salaries:"ଦରମା",
  salary_ids:"ଦରମା ID",
  start_time:"ଆରମ୍ଭ ସମୟ |",
  end_time:"ଶେଷ ସମୟ",
  paid:"ଦେୟ",
  overtime_percentage:"ଦେୟ ପ୍ରତିଶତ",
  overtime_amount:"ଦେୟ ସ୍ଥିର ପରିମାଣ |",
  first_name:"ପ୍ରଥମ ନାମ",
  last_name:"ଶେଷ ନାମ",
  moderation:"ମଧ୍ୟମତା",
  create:"ସୃଷ୍ଟି କରନ୍ତୁ |",
  edit:"ସଂପାଦନ କରନ୍ତୁ |",
  destroy:"ବିନାଶ କର |",
  day_start_time:"ଦିନ_ଷ୍ଟାର୍ଟ_ ସମୟ |",
  day_end_time:"Day_end_time",
  fullname:"ନାମ",
  time:"ସମୟ",
  auto_send:"ସ୍ୱୟଂଚାଳିତ ଭାବରେ ପଠାନ୍ତୁ |",
  time_method:"ସମୟ ପଦ୍ଧତି |",
  schedules:"କାର୍ଯ୍ୟସୂଚୀ",
  indefinite_payroll_enabled:"ଅନିର୍ଦ୍ଦିଷ୍ଟ ଭାବରେ ସକ୍ଷମ କରନ୍ତୁ |",
  amount:"ପରିମାଣ",
  repeat:"ପୁନରାବୃତ୍ତି କରନ୍ତୁ |",
  always_enabled:"ସର୍ବଦା ସକ୍ଷମ |",
  start_date:"ଆରମ୍ଭ ତାରିଖ",
  end_date:"ଶେଷ ତାରିଖ",
  Cheque_Total:"ସମୁଦାୟ ଯାଞ୍ଚ କରନ୍ତୁ |",
  Total_Amount:"ମୋଟ ରାଶି:",
  Amounts:"ପରିମାଣ:",
  Images:"ପ୍ରତିଛବିଗୁଡିକ",
  Files:"ଫାଇଲଗୁଡିକ",
  Previewing:"ପୂର୍ବାବଲୋକନ:",
  Insert:"ସନ୍ନିବେଶ କରନ୍ତୁ |",
  Preview_Import:"ଆମଦାନି ପୂର୍ବାବଲୋକନ କରନ୍ତୁ |",
  Entry:"ପ୍ରବେଶ",
  Entries:"ଏଣ୍ଟ୍ରିଗୁଡିକ",
  No_Entries:"କ No ଣସି ଏଣ୍ଟ୍ରି ନାହିଁ |",
  Import_Type:"ଆମଦାନୀ ପ୍ରକାର",
  Select_Details:"ବିବରଣୀ ଚୟନ କରନ୍ତୁ |",
  Select_Payee:"ଦେୟ ଚୟନ କରନ୍ତୁ |",
  Enable_Holidays:"ଛୁଟିଦିନ ସକ୍ଷମ କରନ୍ତୁ |",
  Disable_Holidays:"ଛୁଟିଦିନ ଅକ୍ଷମ କରନ୍ତୁ |",
  Wire_Transfer:"ତାରଦ୍ୱାରା ସ୍ଥାନାନ୍ତରଣ",
  New_Export:"ନୂତନ ରପ୍ତାନି |",
  Export_Data:"ଡାଟା ରପ୍ତାନି କରନ୍ତୁ |",
  Export_Data_Word:"ରପ୍ତାନି ଏବଂ ଡାଉନଲୋଡ୍ କରିବାକୁ ଫାଇଲ୍ ପ୍ରକାର ଚୟନ କରନ୍ତୁ |",
  Export_File:"ରପ୍ତାନି ଫାଇଲ୍ |",
  Mode:"ମୋଡ୍",
  Times:"ଟାଇମ୍ସ |",
  Widgets:"ୱିଜେଟ୍",
  Slider:"ସ୍ଲାଇଡର୍ |",
  Set_Details:"ବିବରଣୀ ସେଟ୍ କରନ୍ତୁ |",
  Select_Type:"ପ୍ରକାର ଚୟନ କରନ୍ତୁ |",
  Display_Slider:"ସ୍ଲାଇଡର୍ ପ୍ରଦର୍ଶନ କରନ୍ତୁ |",
  Dashboard_Slider:"ଡ୍ୟାସବୋର୍ଡ ସ୍ଲାଇଡର୍ |",
  Dashboard_Mode:"ଡ୍ୟାସବୋର୍ଡ ମୋଡ୍ |",
  Show_Intro:"ଇଣ୍ଟ୍ରୋ ଦେଖାନ୍ତୁ |",
  Show_Payrolls:"ବେତନ ଦେଖାନ୍ତୁ |",
  Show_Holidays:"ଛୁଟିଦିନ ଦେଖାନ୍ତୁ |",
  Show_Invoices:"ଇନଭଏସ୍ ଦେଖାନ୍ତୁ |",
  Show_Cheques:"ଚେକ୍ ଦେଖାନ୍ତୁ |",
  Enabled_Widgets:"ୱିଜେଟ୍ ସକ୍ଷମ |",
  Disabled_Widgets:"ଅକ୍ଷମ ୱିଜେଟ୍ |",
  Colors:"ରଙ୍ଗଗୁଡିକ |",
  Barcodes:"ବାରକୋଡ୍ |",
  View_Timers:"ଟାଇମର୍ସ ଦେଖନ୍ତୁ |",
  Gradients:"ଗ୍ରେଡିଏଣ୍ଟ୍",
  No_Info:"କ Info ଣସି ସୂଚନା ନାହିଁ |",
  Disable:"ଅକ୍ଷମ କରନ୍ତୁ |",
  Show_Layer:"ସ୍ତରଗୁଡିକ ଦେଖାନ୍ତୁ |",
  Hide_Layer:"ସ୍ତରଗୁଡିକ ଲୁଚାନ୍ତୁ |",
  Text_Layer:"ପାଠ୍ୟ ସ୍ତରଗୁଡ଼ିକ |",
  Secondly:"ଦ୍ୱିତୀୟତ। |",
  Minutely:"ମିନିଟ୍ରେ",
  nine_am:"9:00 AM",
  five_pm:"5:00 PM |",
  Enable_Address:"ଠିକଣା ସକ୍ଷମ କରନ୍ତୁ |",
  Invalid_File_Type:"ଦୁ Sorry ଖିତ, ଏକ ଅବ alid ଧ ଫାଇଲ୍ ପ୍ରକାର ଚୟନ କରାଯାଇଥିଲା | ସମର୍ଥିତ ଫାଇଲ୍ ପ୍ରକାର:",
  Error_Updating_Entry:"ଦୁ Sorry ଖିତ, ଏହି ଏଣ୍ଟ୍ରି ଅପଡେଟ୍ କରିବାରେ ଏକ ତ୍ରୁଟି ଥିଲା |",
  Schedule_Timing_Alert:"ତ୍ରୁଟି: କାର୍ଯ୍ୟସୂଚୀ ଆରମ୍ଭ ସମୟ ସମାପ୍ତ ସମୟ ପରେ ଏକ ମୂଲ୍ୟରେ ସେଟ୍ ହୋଇଛି |",
  Select_Multiple_Payments_Prompt:"କ payments ଣସି ଦେୟ ଚୟନ ହୋଇନାହିଁ | ଅତିକମରେ ଗୋଟିଏ ଦେୟ ଚୟନ କରନ୍ତୁ |",
  Select_Multiple_Cheques_Prompt:"କ No ଣସି ଯାଞ୍ଚ ଚୟନ କରାଯାଇ ନାହିଁ | ଦୟାକରି ଅତିକମରେ ଗୋଟିଏ ଚେକ୍ ଚୟନ କରନ୍ତୁ |",
  Select_Multiple_Items_Prompt:"କ items ଣସି ଆଇଟମ୍ ଚୟନ ହୋଇନାହିଁ | ଦୟାକରି ଅତିକମରେ ଗୋଟିଏ ଆଇଟମ୍ ଚୟନ କରନ୍ତୁ |",
  Paymemt_Multiple_Delete_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏକାଧିକ ଦେୟ ବିଲୋପ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Cheque_Multiple_Delete_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏକାଧିକ ଚେକ୍ ଡିଲିଟ୍ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Payee_Multiple_Delete_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏକାଧିକ ଦେୟ ବିଲୋପ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Employee_Multiple_Delete_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏକାଧିକ କର୍ମଚାରୀ ବିଲୋପ କରିବାକୁ ଚାହୁଁଛନ୍ତି?",
  MICR_Warning:"ଟିପନ୍ତୁ: କିଛି ପ୍ରିଣ୍ଟର୍ ଏବଂ ଡିଭାଇସ୍ MICR ଫଣ୍ଟକୁ ସଠିକ୍ ଭାବରେ ପ୍ରଦର୍ଶନ କରିପାରନ୍ତି ନାହିଁ |",
  Automatically_Send:"ସ୍ୱୟଂଚାଳିତ ଭାବରେ ପଠାନ୍ତୁ |",
  Type:"ଟାଇପ୍ କରନ୍ତୁ |",
  Payment_Type:"ଦେୟ ପ୍ରକାର",
  Auto_Send:"ଅଟୋ ପ୍ରେରଣ |",
  Automatically_Process:"ସ୍ୱୟଂଚାଳିତ ପ୍ରକ୍ରିୟା |",
  Auto_Process:"ଅଟୋ ପ୍ରକ୍ରିୟା |",
  Image_Based:"ପ୍ରତିଛବି ଆଧାରିତ |",
  Font_Based:"ଫଣ୍ଟ-ଆଧାରିତ |",
  Rerender:"ପୁନ render- ରେଣ୍ଡର୍ କରନ୍ତୁ |",
  Rendering:"ରେଣ୍ଡରିଂ",
  Render:"ଫାଇଲଗୁଡିକ",
  Top:"ଶୀର୍ଷ",
  Middle:"ମଧ୍ୟମ",
  Bottom:"ତଳ",
  Top_Left:"ଉପର ବାମ",
  Top_Center:"ଶୀର୍ଷ କେନ୍ଦ୍ର |",
  Top_Right:"ଉପର ଡାହାଣ",
  Middle_Left:"ମଧ୍ୟମ ବାମ",
  Middle_Center:"ମଧ୍ୟମ କେନ୍ଦ୍ର |",
  Middle_Right:"ମଧ୍ୟମ ଡାହାଣ |",
  Bottom_Left:"ତଳ ବାମ",
  Bottom_Center:"ନିମ୍ନ କେନ୍ଦ୍ର |",
  Bottom_Right:"ତଳ ଡାହାଣ",
  Numbers:"ସଂଖ୍ୟାଗୁଡିକ",
  Verified:"ଯାଞ୍ଚ ହୋଇଛି |",
  Paper_Size:"କାଗଜ ଆକାର |",
  New_Device:"ନୂତନ ଉପକରଣ |",
  Add_Device:"ଡିଭାଇସ୍ ଯୋଡନ୍ତୁ |",
  Remove_Device:"ଉପକରଣ ଅପସାରଣ କରନ୍ତୁ |",
  Delete_Device:"ଡିଭାଇସ୍ ଡିଲିଟ୍ କରନ୍ତୁ |",
  Block_Device:"ଉପକରଣକୁ ଅବରୋଧ କରନ୍ତୁ |",
  Block:"ଅବରୋଧ କରନ୍ତୁ |",
  Unblock:"ଅବରୋଧ କରନ୍ତୁ |",
  Table:"ସାରଣୀ",
  Scan_Login_Code:"ଲଗଇନ୍ କୋଡ୍ ସ୍କାନ୍ କରନ୍ତୁ |",
  Login_Code:"ଲଗଇନ୍ କୋଡ୍ |",
  Scan_Code:"କୋଡ୍ ସ୍କାନ୍ କରନ୍ତୁ |",
  Scan_QR_Code:"QR କୋଡ୍ ସ୍କାନ୍ କରନ୍ତୁ |",
  Select_All:"ସବୁ ବାଛ",
  Deselect_All:"ସମସ୍ତଙ୍କୁ ବାଛନ୍ତୁ |",
  Increase:"ବଢିବା",
  Decrease:"କମିବା",
  Bold:"ବୋଲ୍ଡ |",
  Text:"ପାଠ",
  Style:"ଶ Style ଳୀ",
  Italic:"ଇଟାଲିକ୍ |",
  QR_Code:"QR କୋଡ୍ |",
  Barcode:"ବାରକୋଡ୍ |",
  Browse_Images:"ପ୍ରତିଛବିଗୁଡିକ ବ୍ରାଉଜ୍ କରନ୍ତୁ |",
  Browse_Files:"ଫାଇଲଗୁଡିକ ବ୍ରାଉଜ୍ କରନ୍ତୁ |",
  Background_Image:"ପୃଷ୍ଠଭୂମି ପ୍ରତିଛବି |",
  Logo_Image:"ଲୋଗୋ ପ୍ରତିଛବି |",
  Header_Image:"ହେଡର୍ ପ୍ରତିଛବି |",
  Bank_Image:"ବ୍ୟାଙ୍କ ପ୍ରତିଛବି |",
  Cut_Lines:"ରେଖା କାଟନ୍ତୁ |",
  Background:"ପୃଷ୍ଠଭୂମି",
  License:"ଲାଇସେନ୍ସ |",
  One_License:"1 ଲାଇସେନ୍ସ:",
  Licensed:"ଲାଇସେନ୍ସପ୍ରାପ୍ତ:",
  Not_Licensed:"ଲାଇସେନ୍ସପ୍ରାପ୍ତ ନୁହେଁ |",
  Enter_Serial:"କ୍ରମିକ ପ୍ରବେଶ କରନ୍ତୁ |",
  Serial_Key:"କ୍ରମିକ ଚାବି |",
  Serial:"କ୍ରମିକ |",
  Product_Key:"ଉତ୍ପାଦ ଚାବି |",
  Check_Product_Key:"ଉତ୍ପାଦ ଚାବି ଯାଞ୍ଚ କରନ୍ତୁ |",
  Add_Product_Key:"ପ୍ରଡକ୍ଟ କୀ ଯୋଡନ୍ତୁ |",
  Verifying_Purchase:"କ୍ରୟ ଯାଞ୍ଚ କରୁଛି ...",
  Print_Envelope:"ଏନଭଲପ୍ ପ୍ରିଣ୍ଟ୍ କରନ୍ତୁ |",
  Envelope:"ଲିଭୋପା",
  Thank_You:"ଧନ୍ୟବାଦ!",
  Scale:"ମାପ",
  Print_Scale:"ସ୍କେଲ୍ ପ୍ରିଣ୍ଟ୍ କରନ୍ତୁ |",
  Borders:"ସୀମା",
  VOID:"VOID |",
  Void_Cheque:"ଶୂନ୍ୟ ଯାଞ୍ଚ |",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ଆଦେଶକୁ ଦେୟ:",
  NO_DOLLARS:"କ D ଣସି ଡୋଲାର୍ ନାହିଁ | ",
  ONE_DOLLAR:"ଗୋଟିଏ ଡୋଲାର୍ |",
  DOLLARS:" DOLLARS",
  AND:"ଏବଂ",
  CENTS:"କେନ୍ଦ୍ରଗୁଡିକ",
  NO_:"ନା ",
  ONE_:"ଗୋଟିଏ  ",
  AND_NO_:"ଏବଂ ନା",
  _AND_ONE_:"ଏବଂ ଗୋଟିଏ | ",
  DOLLARS_AND_ONE_CENT:" ଏବଂ ଗୋଟିଏ କେନ୍ଦ୍ର |",
  AND_NO_CENTS:" ଏବଂ ଜିରୋ କେନ୍ଦ୍ରଗୁଡିକ |",
  CHEQUE_PRINT_DATE:"ତାରିଖ:",
  CHEQUE_PRINT_MEMO:"ମେମୋ:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"ସାଂସଦ",
  Initial_Tasks:"ପ୍ରାରମ୍ଭିକ କାର୍ଯ୍ୟ",
  Inital_Setup:"ଇନିଟାଲ୍ ସେଟଅପ୍",
  Welcome_To:"କୁ ସ୍ଵାଗତ ",
  Welcome:"ସ୍ Welcome ାଗତ",
  Swipe:"ସ୍ୱାଇପ୍",
  Intro_Setup:"ଇଣ୍ଟ୍ରୋ ସେଟଅପ୍",
  Introduction:"ପରିଚୟ",
  CHEQUE_PRINT_CREDIT:"ଚେକ୍ ଦ୍ୱାରା ଚାଳିତ",
  Title_Intro_Word:"ଚେକ୍ କୁ ସ୍ୱାଗତ",
  Title_Intro:"ଚେକ୍ ପରିଚୟ",
  Title_Setup:"ଚେକ୍ ସେଟ୍ଅପ୍",
  PayPal_Default_Email_Message:"ଆପଣ ଏକ ନୂତନ ପେପାଲ୍ ସ୍ଥାନାନ୍ତର ଗ୍ରହଣ କରିଛନ୍ତି। [ଚେକ୍ ଦ୍ୱାରା ଚାଳିତ]",
  Cheques_App_Export:"ଚେକ୍ ଦ୍ୱାରା ରପ୍ତାନି",
  Post_Intro_Thanks:"ଚେକ୍ ବାଛିବା ପାଇଁ ଧନ୍ୟବାଦ। ସେଟଅପ୍ ପ୍ରକ୍ରିୟା ବର୍ତ୍ତମାନ ସମ୍ପୂର୍ଣ୍ଣ ହୋଇଛି।",
  Post_Intro_Word:"ତୁମର ପ୍ରଥମ ଚେକ୍ ପ୍ରିଣ୍ଟ୍ କରି ଆରମ୍ଭ କର, ଭବିଷ୍ୟତର ଦେୟ ଯୋଡ, କିମ୍ବା ବେତନରେ ଜଣେ କର୍ମଚାରୀ ଯୋଗ କର |",
  Upgrade_Required:"ଏହି ବାର୍ତ୍ତା ଲୁଚାଇବା ଏବଂ ଅତିରିକ୍ତ ବ features ଶିଷ୍ଟ୍ୟଗୁଡିକୁ ଅନଲକ୍ କରିବା ପାଇଁ ଚେକ୍ ଆପଣଙ୍କୁ ସଫ୍ଟୱେୟାରର ଅଧିକ ପ୍ରିମିୟମ୍ ସଂସ୍କରଣର ମାଲିକ ହେବା ଆବଶ୍ୟକ କରେ |",
  Upgrade_Title:"ଯାଞ୍ଚ",
  Mailout_Prompt:"ଆପଣ ଅତିରିକ୍ତ ଭାବରେ ଆପଣଙ୍କ ପାଇଁ ଆପଣଙ୍କ ବେତନ ଚେକ୍ ଚେକ୍ ମେଲ୍ ଆଉଟ୍ କରିବାକୁ ବାଛିପାରନ୍ତି |",
  Mailed_Cheque:"ମେଲ ହୋଇଥିବା ଯାଞ୍ଚ:",
  Mailed_Cheque_Color:"ମେଲ୍ ଯାଞ୍ଚ କରିବା (ରଙ୍ଗ):",
  Terms_Purchase:"ଚେକ୍ ସହିତ ସମସ୍ତ ବ electronic ଦ୍ୟୁତିକ କ୍ରୟ କ୍ରୟ ତାରିଖ ଠାରୁ 30 ଦିନ ପର୍ଯ୍ୟନ୍ତ ସମ୍ପୂର୍ଣ୍ଣ ଫେରସ୍ତଯୋଗ୍ୟ ଅଟେ। ଜାରି ରଖିବା ପୂର୍ବରୁ ଦୟାକରି <a href='#'> ସର୍ତ୍ତାବଳୀ ଏବଂ ଚୁକ୍ତିନାମା </a> ପ read ନ୍ତୁ ଏବଂ ରାଜି ହୁଅନ୍ତୁ।",
  Dashboard_Setup:"ପ୍ରାଥମିକ ପ୍ରିଣ୍ଟର୍ ସେଟ୍ଅପ୍",
  Dashboard_Add:"ପ୍ରାଥମିକ ବ୍ୟାଙ୍କ ଆକାଉଣ୍ଟ ଯୋଡନ୍ତୁ",
  Dashboard_Customize:"ଏକ ଚେକ୍ ଟେମ୍ପଲେଟ୍ ଚୟନ କରନ୍ତୁ",
  Dashboard_Job_Salary:"ତୁମର ଚାକିରି ସୃଷ୍ଟି କର ଏବଂ ତୁମର ଦରମା ଯୋଗ କର",
  Dashboard_Employees:"କର୍ମଚାରୀ ଏବଂ ଦରମା ଟ୍ରାକ୍ କରନ୍ତୁ",
  Dashboard_Print:"ତୁମର ପେଚେକ୍ ପ୍ରିଣ୍ଟ୍ ଏବଂ ମେଲ୍ କର",
  Dashboard_Payroll:"ତୁମର ବେତନ ମୁଦ୍ରଣକୁ ସ୍ୱୟଂଚାଳିତ କର",
  Dashboard_PayPal:"ସେଟଅପ୍ ପେପାଲ୍ ବେତନ / ପେଆଉଟ୍",
  Begin_Setup:"ସେଟଅପ୍ ଆରମ୍ଭ",
  Get_Started:"ଆରମ୍ଭ ହୋଇଗଲା",
  Purchase:"କ୍ରୟ",
  Lockdown:"ଲକ୍ଡାଉନ୍",
  Unlock:"ଅନଲକ୍",
  Detailed:"ବିସ୍ତୃତ",
  Log_In:"ଭିତରକୁ ଯାଉ",
  Login:"ଭିତରକୁ ଯାଉ",
  Launch:"ଲଞ୍ଚ",
  Register:"ପଞ୍ଜିକରଣ",
  Finish:"ସମାପ୍ତ",
  List:"ତାଲିକା",
  Weekends:"ୱିକେଣ୍ଡ୍",
  Weekly:"ସାପ୍ତାହିକ",
  PayPal_Default_Subject:"ନୂତନ ଦେୟ",
  Payment_Message:"Payment ବାର୍ତ୍ତା",
  PayPal_Default_Payment_Note:"ଧନ୍ୟବାଦ",
  Setup_Your_Cheqing_Account:"ଆକାଉଣ୍ଟ୍ ଚେକିଂ",
  Add_Your_Primary_Cheqing_Account:"ଆପଣଙ୍କର ପ୍ରାଥମିକ ଚେକିଂ ଆକାଉଣ୍ଟ୍ ଯୋଡନ୍ତୁ |",
  Welcome_Word:"ସରଳୀକୃତ ଏବଂ ସ୍ଵତଃସ୍ପୃତ େବତନଗ୍ରାହୀ ନାମାବଳୀ ଏବଂ ମାନବ ସମ୍ବଳ ପରିଚାଳନା।",
  Get_Started_Quickly:"କେବଳ କିଛି ସରଳ ପ୍ରଶ୍ନର ଉତ୍ତର ଦିଅ ଯାହା ଆମକୁ ଆରମ୍ଭ କରିବାରେ ସାହାଯ୍ୟ କରିବ ...",
  Done_Intro_Word:"ସେଟଅପ୍ ସଂପୂର୍ଣ୍ଣ",
  PIN_Protected:"ପାସୱାର୍ଡ ଏବଂ ପିନ୍ ସୁରକ୍ଷିତ",
  Enter_New_PIN:"ଏକ ନୂତନ PIN କୋଡ୍ ପ୍ରବେଶ କରନ୍ତୁ:",
  Enter_PIN:"PIN କୋଡ୍ ପ୍ରବେଶ କରନ୍ତୁ:",
  Invalid_PIN:"ଅବ val ଧ PIN ପ୍ରବେଶ କଲା |",
  Account_Identifier:"ଖାତା ପରିଚାୟକ",
  New_Account_Identifier:"ନୂତନ ଖାତା ପରିଚାୟକ",
  attempt:"ଚେଷ୍ଟା",
  attempts:"ଚେଷ୍ଟା",
  remaining:"ଅବଶିଷ୍ଟ",
  Language:"ଭାଷା",
  languages:"ଭାଷା",
  select_languages:"ଭାଷା ଚୟନ କରନ୍ତୁ",
  Deposit:"ଜମା",
  Hire_One_Now:"ବର୍ତ୍ତମାନ ଗୋଟିଏ ନିଯୁକ୍ତି",
  App_Locked:"ଆପ୍ଲିକେସନ୍ ଲକ୍ ହୋଇଛି।",
  Skip_:"ସ୍କିପ୍",
  Skip_to_Dashboard:"ଡ୍ୟାସବୋର୍ଡକୁ ଯାଆନ୍ତୁ",
  Dashboard:"ଡ୍ୟାସବୋର୍ଡ",
  Import:"ଆମଦାନି",
  Admin:"ପ୍ରଶାସକ",
  New_Admin:"ନୂତନ ଆଡମିନି",
  Settings:"ସେଟିଂସମୂହ",
  Color_Picker:"ରଙ୍ଗ ପିକର୍",
  Font_Picker:"ଫଣ୍ଟ ପିକର୍",
  Logout:"ପ୍ରସ୍ଥାନ କର",
  Close:"ବନ୍ଦ",
  Close_menu:"ବନ୍ଦ",
  Excel:"Excel ଫାଇଲ୍",
  Csv:"CSV ଫାଇଲ୍",
  Sql:"SQL ଫାଇଲ୍",
  Json:"JSON ଫାଇଲ୍",
  Url:"URL ଦ୍ୱାରା ଆମଦାନି",
  Back:"ପଛକୁ",
  Timers:"ଟାଇମର୍ସ",
  Cheque:"ଚେକ୍",
  Print:"ମୁଦ୍ରଣ",
  Designs:"ଡିଜାଇନ୍ସ",
  Pause_Printing:"ମୁଦ୍ରଣକୁ ବିରତି",
  Resume_Printing:"ପୁନ ମୁଦ୍ରଣ ପୁନ Res ଆରମ୍ଭ",
  Printing_Paused:"ମୁଦ୍ରଣ ବିରତି",
  PrintingUnavailable:"ଦୁ Sorry ଖିତ! ଏହି ସିଷ୍ଟମରେ ମୁଦ୍ରଣ ଉପଲବ୍ଧ ନାହିଁ।",
  Prints_Paused:"ମୁଦ୍ରଣ ବିରତି",
  Administration:"ପ୍ରଶାସନ",
  Loading:"ଲୋଡିଂ",
  Unnamed:"ନାମହୀନ",
  error:"ଦୁ Sorry ଖିତ, ଏହି ଚେକ୍ ଦେଖିବା ପାଇଁ ଖୋଲାଯାଇ ପାରିଲା ନାହିଁ।",
  No_Cheques_To_Print:"ମୁଦ୍ରଣ କରିବାକୁ କ Che ଣସି ଯାଞ୍ଚ ନାହିଁ",
  No_Cheques_To_Print_Word:"ଏକ <a routerLink='/folder/Cheque/New'> ନୂତନ ଚେକ୍ </a> ସୃଷ୍ଟି କରନ୍ତୁ |",
  New_Cheque:"ନୂତନ ଚେକ୍",
  Start_One_Now:"ବର୍ତ୍ତମାନ ଗୋଟିଏ ଆରମ୍ଭ କର",
  Edit_Cheque:"ଚେକ୍ ଏଡିଟ୍",
  Select_Cheques:"ଚୟନ ଯାଞ୍ଚ",
  Select_Employee:"କର୍ମଚାରୀ ଚୟନ କରନ୍ତୁ",
  Default_Printer:"ଡିଫଲ୍ଟ ପ୍ରିଣ୍ଟର୍",
  Reassign:"ନବଲିଖନ",
  Configure:"ବିନ୍ୟାସ କରନ୍ତୁ",
  Template:"ଟେମ୍ପଲେଟ୍",
  Designer:"ଡିଜାଇନର୍",
  Edit_Designs:"ଡିଜାଇନ୍ ସଂପାଦନ କରନ୍ତୁ",
  New_Design:"ନୂତନ ଡିଜାଇନ୍",
  Next:"ପରବର୍ତ୍ତୀ",
  Save:"ସଞ୍ଚୟ",
  Name:"ନାମ",
  Mail:"ମେଲ୍",
  Amount:"ପରିମାଣ",
  Date:"ତାରିଖ",
  PayPal:"ପେପାଲ୍",
  Payouts:"ଦେୟଗୁଡିକ",
  PayPal_Label:"ପେପାଲ୍ ଲେବଲ୍",
  Email_Username:"ଇମେଲ୍ / ଉପଯୋଗକର୍ତ୍ତା ନାମ",
  Client_ID:"କ୍ଲାଏଣ୍ଟ ID",
  Sandbox_Email:"ସ୍ୟାଣ୍ଡବକ୍ସ ଇମେଲ",
  PayPal_Email:"ପେପାଲ୍ ଇମେଲ୍",
  PayPal_Username:"API ଉପଯୋଗକର୍ତ୍ତା ନାମ",
  PayPal_Payouts:"ପେପାଲ୍ ପେଆଉଟ୍",
  Select_PayPal_Key:"ପେପାଲ୍ କି ଚୟନ କରନ୍ତୁ",
  Secret:"ଗୁପ୍ତ",
  API_Secret:"API ସିକ୍ରେଟ୍",
  PayPal_API_Keys:"ପେପାଲ୍ କି",
  New_PayPal_Key:"ନୂତନ ପେପାଲ୍ କି",
  Email_Subject:"ଇମେଲ୍ ବିଷୟ",
  Email_Message:"ଇମେଲ୍ ବାର୍ତ୍ତା",
  Payout_Options:"ଦେୟ ବିକଳ୍ପ",
  Payment_Note:"ଦେୟ ନୋଟ୍",
  Enable_Employee:"କର୍ମଚାରୀ ସକ୍ଷମ କରନ୍ତୁ",
  Enable_Production_Mode:"ଉତ୍ପାଦନ ମୋଡ୍ ସକ୍ଷମ କର",
  Sandbox_Username:"Sandbox ଉପଯୋଗକର୍ତ୍ତା ନାମ",
  Sandbox_Signature:"ସ୍ୟାଣ୍ଡବକ୍ସ ସ୍ ature ାକ୍ଷର",
  Sandbox_Password:"ସ୍ୟାଣ୍ଡବକ୍ସ ପାସୱାର୍ଡ",
  Production_Username:"ଉତ୍ପାଦନ ଉପଯୋଗକର୍ତ୍ତା ନାମ",
  Production_Signature:"ଉତ୍ପାଦନ ଦସ୍ତଖତ",
  Production_Password:"ଉତ୍ପାଦନ ପାସୱାର୍ଡ",
  Production_Email:"ଉତ୍ପାଦନ ଇମେଲ",
  API_Client_ID:"API କ୍ଲାଏଣ୍ଟ ID",
  API_Signature:"API ଦସ୍ତଖତ",
  API_Password:"API ପାସୱାର୍ଡ",
  API_Username:"API ଉପଯୋଗକର୍ତ୍ତା ନାମ",
  Secret_Key:"ଗୁପ୍ତ ଚାବି",
  Sandbox:"ସ୍ୟାଣ୍ଡବକ୍ସ",
  Production:"ଉତ୍ପାଦନ",
  Sandbox_Keys:"ସ୍ୟାଣ୍ଡବକ୍ସ କୀ",
  Production_Keys:"ଉତ୍ପାଦନ ଚାବି",
  API_Title:"API ଆଖ୍ୟା",
  Production_Mode:"ଉତ୍ପାଦନ ମୋଡ୍",
  Account_Label:"ଆକାଉଣ୍ଟ୍ ଲେବଲ୍",
  No_PayPal_Setup:"କ Pay ଣସି ପେପାଲ୍ କି ନାହିଁ",
  Enable_PayPal_Account:"ପେପାଲ ଆକାଉଣ୍ଟ ସକ୍ଷମ କରନ୍ତୁ",
  No_PayPal_Word:"ଆପଣଙ୍କର <a routerLink='/folder/Invoice/New'> PayPal API କି </a> କୁ ଯୋଡନ୍ତୁ |",
  Printed_Memo:"ମୁଦ୍ରିତ ମେମୋ",
  Employee:"କର୍ମଚାରୀ",
  View_Employee:"କର୍ମଚାରୀ ଦେଖନ୍ତୁ",
  Mailing_Address:"ମେଲିଂ ଠିକଣା",
  Company_Address:"କମ୍ପାନୀ ଠିକଣା",
  Select_Company_Address:"କମ୍ପାନୀ ଠିକଣା ଚୟନ କରନ୍ତୁ",
  Address:"ଠିକଣା",
  Any_Day:"ଯେକ Day ଣସି ଦିନ",
  Address_Name:"ଠିକଣା ନାମ",
  Unit:"ୟୁନିଟ୍",
  Account:"ଖାତା",
  Bank_Account:"ବ୍ୟାଙ୍କ ଖାତା",
  Account_Limits:"ଖାତା ସୀମା ସକ୍ଷମ କର",
  Payroll:"ବେତନ",
  New_Payroll:"ନୂତନ ବେତନ",
  No_Payroll:"ଆଗାମୀ ଦରମା ନାହିଁ",
  Upcoming_Holiday:"ଆଗାମୀ ଛୁଟିଦିନ:",
  Invoice_Due:"ଇନଭଏସ୍ ଦେୟ:",
  New_Invoice:"ନୂତନ ଇନଭଏସ୍",
  No_Invoices:"କ No ଣସି ଇନଭଏସ୍ ନାହିଁ",
  No_Invoices_Word:"ପ୍ରଥମ <a routerLink='/folder/Invoice/New'> ଇନଭଏସ୍ </a> ସୃଷ୍ଟି କରନ୍ତୁ |",
  Cheque_Due:"ଦେୟ ଯାଞ୍ଚ କରନ୍ତୁ:",
  Payrolls:"ବେତନ",
  Sandbox_Mode:"ସ୍ୟାଣ୍ଡବକ୍ସ ମୋଡ୍",
  Hire:"ନିଯୁକ୍ତି",
  Pay:"ଦେୟ",
  New:"ନୂତନ",
  Add:"ଯୋଡନ୍ତୁ",
  Make:"ତିଆରି",
  Time:"ସମୟ",
  Write:"ଲେଖ",
  Holiday:"ଛୁଟିଦିନ",
  Holidays:"ଛୁଟିଦିନ",
  Next_Holiday:"ପରବର୍ତ୍ତୀ ଛୁଟିଦିନ:",
  All_Done:"ସବୁ ସମାପ୍ତ!",
  Employees:"କର୍ମଚାରୀ",
  Payees:"ଦେୟ",
  Job:"ଚାକିରି",
  Jobs:"ଚାକିରି",
  Invoice:"ଚାଲାଣ",
  Invoices:"ଇନଭଏସ୍",
  Vacations:"ଛୁଟି",
  Cheques:"ଯାଞ୍ଚ",
  Brand_Cheques:"ବ୍ରାଣ୍ଡ",
  Payment:"ଦେୟ",
  Enable_Payment:"ଦେୟ ସକ୍ଷମ କରନ୍ତୁ",
  Payments:"ଦେୟ",
  Schedule:"କାର୍ଯ୍ୟସୂଚୀ",
  Schedules:"କାର୍ଯ୍ୟସୂଚୀ",
  Timesheet:"କାର୍ଯ୍ୟ ସୂଚୀ",
  Timesheets:"ସମୟ ସିଟ୍",
  Salary:"ଦରମା",
  New_Address:"ନୂତନ ଠିକଣା",
  Address_2:"ଠିକଣା (ରେଖା 2)",
  _City:"ସହର",
  _State:"ରାଜ୍ୟ / ପ୍ରୋଭ",
  City:"ସହର / ଟାଉନସିପ୍",
  State:"ରାଜ୍ୟ / ପ୍ରଦେଶ",
  Postal:"ଡାକ / ଜିପ୍ କୋଡ୍",
  ZIP:"ଡାକ / ଜିପ୍",
  Country:"ଦେଶ",
  Addresses:"ଠିକଣା",
  Required_Options:"ଆବଶ୍ୟକ ବିକଳ୍ପଗୁଡିକ",
  Optional_Options:"ବ tion କଳ୍ପିକ ବିକଳ୍ପ",
  Additional_Options:"ଅତିରିକ୍ତ ବିକଳ୍ପ",
  Required:"ଆବଶ୍ୟକ",
  Optional:"ବ tion କଳ୍ପିକ",
  Additional:"ଅତିରିକ୍ତ",
  No_Addresses:"କ Address ଣସି ଠିକଣା ନାହିଁ",
  New_Address_Word:"ପ୍ରଥମ <a routerLink='/folder/Address/New'> ଠିକଣା </a> ଯୋଡନ୍ତୁ |",
  Select_Address:"ଠିକଣା ଚୟନ କରନ୍ତୁ",
  No_Address:"କ Address ଣସି ଠିକଣା ନାହିଁ",
  Print_Cheque:"ମୁଦ୍ରଣ ଯାଞ୍ଚ",
  Print_Cheque_Now:"ବର୍ତ୍ତମାନ ଚେକ୍ ପ୍ରିଣ୍ଟ୍ କରନ୍ତୁ",
  Description:"ବର୍ଣ୍ଣନା",
  Pay_To_The_Order_Of:"ଅର୍ଡର ଅଫ୍ ପେ:",
  Select_Date_Range:"ତାରିଖ ପରିସର ଚୟନ କରନ୍ତୁ",
  Select_Starting_Date:"ଆରମ୍ଭ ତାରିଖ ଚୟନ କରନ୍ତୁ",
  Select_Ending_Date:"ଶେଷ ତାରିଖ ଚୟନ କରନ୍ତୁ",
  Select_Date:"ତାରିଖ ଚୟନ କରନ୍ତୁ",
  Cheque_Date:"ଯାଞ୍ଚ ତାରିଖ",
  Cheque_Memo:"ଚେକ୍ ମେମୋ",
  Blank_Cheque:"ଖାଲି ଚେକ୍",
  Blank:"ଖାଲି",
  No_Cheques:"କ Che ଣସି ଯାଞ୍ଚ ନାହିଁ",
  No_Cheques_Word:"ତୁମର ପ୍ରଥମ <a routerLink='/folder/Cheque/New'> ଚେକ୍ </a> ପ୍ରିଣ୍ଟ୍ କର |",
  Cheque_Amount_Placeholder:"$ 0.00",
  View_Image:"ପ୍ରତିଛବି ଦର୍ଶନ",
  View:"ଦର୍ଶନ",
  Modify:"ରୂପାନ୍ତର",
  Delete:"ବିଲୋପ",
  Cheque_Paid:"ଦେୟ",
  New_Deduction:"ନୂତନ ଡିଡକସନ",
  Title:"ଆଖ୍ୟା",
  Frequency:"ଫ୍ରିକ୍ୱେନ୍ସି",
  Hourly:"ଘଣ୍ଟା",
  Daily:"ଦ Daily ନିକ",
  Weekdays:"ସାପ୍ତାହିକ ଦିନ",
  BiWeekly:"2 ସପ୍ତାହ",
  TriWeekly:"3 ସପ୍ତାହ",
  Monthly:"ମାସିକ",
  MiMonthly:"2 ମାସ",
  Quarterly:"ତ୍ର art ମାସିକ",
  Yearly:"ବାର୍ଷିକ",
  Every_Week:"ପ୍ରତି ସପ୍ତାହ",
  Every_Payroll:"ପ୍ରତ୍ୟେକ ବେତନ",
  Every_Month:"ପ୍ରତ୍ୟେକ ମାସ",
  Annually:"ବାର୍ଷିକ",
  Always_Scheduled:"ସର୍ବଦା ଅନୁସୂଚିତ",
  Start_Date:"ପ୍ରାରମ୍ଭ ତାରିଖ",
  End_Date:"ଶେଷ ତାରିଖ",
  Start_Time:"ଆରମ୍ଭ ସମୟ",
  End_Time:"ଶେଷ ସମୟ",
  Deduction_Label:"ଡିଡକସନ ଲେବଲ୍",
  Notes:"ଟିପ୍ପଣୀ",
  Options:"ବିକଳ୍ପଗୁଡିକ",
  Enable:"ସକ୍ଷମ",
  Select_Deductions:"ଡିଡକସନ୍ ଚୟନ କରନ୍ତୁ",
  Deductions:"ରିହାତି",
  No_Deductions:"କ No ଣସି ରିହାତି ନାହିଁ",
  No_Deductions_Word:"ଆପଣଙ୍କର ପ୍ରଥମ <a routerLink='/folder/Deduction/New'> ରିହାତି </a> ସୃଷ୍ଟି କରନ୍ତୁ |",
  New_Employee:"ନୂତନ କର୍ମଚାରୀ",
  No_Title:"କ Title ଣସି ଆଖ୍ୟା ନାହିଁ",
  _Name:"ନାମ",
  About_Yourself:"ନିଜ ବିଷୟରେ",
  Full_Name:"ପୁରା ନାମ",
  Birthday:"ଜନ୍ମଦିନ",
  Email:"ଇମେଲ୍",
  SMS:"ଏସଏମଏସ",
  Phone:"ଫୋନ୍",
  Test:"ପରୀକ୍ଷା",
  Call:"କଲ୍",
  Fax:"ଫ୍ୟାକ୍ସ",
  Printed_Note:"ମୁଦ୍ରିତ ଟିପ୍ପଣୀ",
  Position:"ଅବସ୍ଥାନ",
  Job_Position:"ଚାକିରି ଅବସ୍ଥାନ",
  Select_a_Job:"ଏକ ଚାକିରି ବାଛ",
  Select_a_Salary:"ଏକ Salary ଚୟନ",
  Add_a_Deduction:"ଏକ ଡିଡକସନ୍ ଯୋଡନ୍ତୁ",
  Taxes:"କର",
  Add_Taxes:"ଟ୍ୟାକ୍ସ ଯୋଡନ୍ତୁ",
  Date_of_Birth:"ଜନ୍ମ ତାରିଖ",
  Email_Address:"ଈମୈଲ ଠିକଣା",
  Phone_Number:"ଫୋନ୍ ନମ୍ବର",
  Phone_Call:"ଫୋନ୍ କଲ",
  Enable_on_Payroll:"ବେତନ ଉପରେ ସକ୍ଷମ",
  Select_Employees:"କର୍ମଚାରୀ ଚୟନ କରନ୍ତୁ",
  No_Employees:"କ Emplo ଣସି କର୍ମଚାରୀ ନାହିଁ",
  No_Employees_Word:"ଆପଣଙ୍କର ପ୍ରଥମ ନୂତନ <a routerLink='/folder/Employee/New'> କର୍ମଚାରୀ </a> ଯୋଡନ୍ତୁ |",
  No_Name:"ନା ନାମ",
  Unemployeed:"ବେକାର",
  Employeed:"ନିଯୁକ୍ତ",
  Paid:"ଦେୟ",
  Enabled:"ସକ୍ଷମ",
  Disabled:"ଅକ୍ଷମ",
  Fire:"ଅଗ୍ନି",
  Not_Available:"ଉପଲବ୍ଧ ନୁହେଁ",
  Not_Available_Word:"ଆପଣଙ୍କର ପ୍ରଥମ <a routerLink='/folder/Invoice/New'> ଇନଭଏସ୍ </a> ପ୍ରିଣ୍ଟ୍ କରନ୍ତୁ ଏବଂ ଦରମା ପ୍ରାପ୍ତ କରନ୍ତୁ |",
  Select_Invoices:"Select_Invoices",
  Print_Invoice_Word:"ଆପଣଙ୍କର ପ୍ରଥମ <a routerLink='/folder/Invoice/New'> ଇନଭଏସ୍ </a> ପ୍ରିଣ୍ଟ୍ କରନ୍ତୁ ଏବଂ ଦରମା ପ୍ରାପ୍ତ କରନ୍ତୁ |",
  Invoice_Title:"ଇନଭଏସ୍ ଆଖ୍ୟା",
  Invoice_Date:"ଇନଭଏସ୍ ତାରିଖ",
  Due_Date:"ଧାର୍ଯ୍ୟ ତାରିଖ",
  New_Job:"ନୂତନ କାର୍ଯ୍ୟ",
  Details:"ବିବରଣୀ",
  Customize:"କଷ୍ଟମାଇଜ୍",
  Customize_Dashboard:"ଡ୍ୟାସବୋର୍ଡ କଷ୍ଟୋମାଇଜ୍ କରନ୍ତୁ",
  Components:"ଉପାଦାନଗୁଡିକ",
  No_Components:"କ Comp ଣସି ଉପାଦାନ ନାହିଁ",
  Main_Components:"ମୁଖ୍ୟ ଉପାଦାନଗୁଡିକ",
  Smaller_Components:"ଛୋଟ ଉପାଦାନଗୁଡିକ",
  Error_Loading_Page:"ଏହି ପୃଷ୍ଠା ଲୋଡ୍ କରିବାରେ ତ୍ରୁଟି।",
  Bank_Details:"ବ୍ୟାଙ୍କ ବିବରଣୀ",
  About_Your_Job:"ତୁମର ଚାକିରି ବିଷୟରେ",
  Your_Schedule:"ତୁମର କାର୍ଯ୍ୟସୂଚୀ",
  Job_Title:"ଚାକିରି ଆଖ୍ୟା",
  Job_Description:"କାର୍ଯ୍ୟ ବିସ୍ତୃତି",
  Job_Details:"କାର୍ଯ୍ୟ ବିବରଣୀ",
  Enable_Job:"କାର୍ଯ୍ୟ ସକ୍ଷମ କର",
  Pay_Period:"ସମୟ ଅବଧି",
  Perpetually_Schedule:"ଚିରାଚରିତ କାର୍ଯ୍ୟସୂଚୀ",
  Select_Jobs:"ଚାକିରି ଚୟନ କରନ୍ତୁ",
  No_Jobs:"କ Jobs ଣସି ଚାକିରି ନାହିଁ",
  Add_Jobs:"ଚାକିରି ଯୋଡନ୍ତୁ",
  No_Jobs_Word:"ତାଲିକାରେ ପ୍ରଥମ <a routerLink='/folder/Job/New'> ଚାକିରି </a> କୁ ଯୋଡନ୍ତୁ |",
  Count_Employees:"job.employee_count + 'କର୍ମଚାରୀ'",
  Zero_Employees:"0 କର୍ମଚାରୀ",
  New_Leave:"ନୂତନ ଛୁଟି",
  Leave_Name:"ନାମ ଛାଡ",
  Paid_Leave:"ଦେୟ ଛୁଟି",
  Leave_Pay:"ଦେୟ ଛାଡ",
  Indefinite_Leave:"ଅନିର୍ଦ୍ଦିଷ୍ଟ ଛୁଟି",
  Indefinite_Payroll:"ଅନିର୍ଦ୍ଦିଷ୍ଟ ଦରମା",
  Leave:"ଛାଡ",
  Add_Schedules:"କାର୍ଯ୍ୟସୂଚୀ ଯୋଡନ୍ତୁ",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"ଅନୁପସ୍ଥିତି ସକ୍ଷମ କରନ୍ତୁ",
  Select_Leaves:"ପତ୍ରଗୁଡିକ ଚୟନ କରନ୍ତୁ",
  No_Leaves:"ଅନୁପସ୍ଥିତିର ପତ୍ର ନାହିଁ",
  Leave_Of_Absence:"ଅନୁପସ୍ଥିତି ଛାଡ",
  Leaves_Of_Absence:"ଅନୁପସ୍ଥିତିର ପତ୍ର",
  New_Leave_of_Absense:"ଅନୁପସ୍ଥିତିର ନୂତନ ଛୁଟି",
  No_Leaves_Word:"ପ୍ରଥମ <a routerLink='/folder/Leave/New'> ଅନୁପସ୍ଥିତି </a>।",
  Not_Enabled:"ସକ୍ଷମ ନୁହେଁ",
  Absences:"ଅନୁପସ୍ଥିତି",
  Payee:"ଦେୟ",
  New_Payee:"ନୂତନ ଦେୟ",
  Payee_Identifier:"ଦେୟ ପରିଚୟକର୍ତ୍ତା",
  Payee_Name:"ଦେୟ ନାମ",
  Payee_Title:"ଦେୟ ଆଖ୍ୟା",
  Payment_Memo:"ଦେୟ ମେମୋ",
  Select_Payees:"ଦେୟ ଚୟନ କରନ୍ତୁ",
  No_Payees:"ନା ଦେୟ",
  Add_Payee_Note:"ପ୍ରଥମ <a routerLink='/folder/Payee/New'> ଦେୟ </a> ଯୋଡନ୍ତୁ |",
  New_Payees:"ନୂତନ ଦେୟ",
  About_The_Payment_Schedule:"ଦେୟ ସୂଚୀ",
  Your_Payroll_Schedule:"ସ୍ୱୟଂଚାଳିତ ଦରମା",
  New_Payment:"ନୂତନ ଦେୟ",
  Identifier:"ପରିଚାୟକ",
  Select:"ଚୟନ",
  Memo:"ମେମୋ",
  Payment_Date:"ଦେୟ ତାରିଖ",
  Mark_as_Paid:"ପେଡ୍ ଭାବରେ ମାର୍କ",
  Select_Payments:"ଦେୟ ଚୟନ କରନ୍ତୁ",
  No_Payments:"ଦେୟ ନାହିଁ",
  No_Payments_Word:"ପ୍ରଥମ <a routerLink='/folder/Payment/New'> ଦେୟ </a> ସୃଷ୍ଟି କରନ୍ତୁ |",
  Create_Payroll:"ବେତନ ସୃଷ୍ଟି କରନ୍ତୁ",
  Properties:"ଗୁଣଧର୍ମ",
  Payroll_Title:"ବେତନ ଆଖ୍ୟା",
  Payroll_Notes:"ବେତନ ଟିପ୍ପଣୀ",
  Payroll_Setup:"ବେତନ ସେଟଅପ୍",
  Tabulate_Payments:"ଟାବଲେଟ୍ ପେମେଣ୍ଟସ୍",
  Tabulate:"ଟାବୁଲେଟ୍",
  By:"ଦ୍: ାରା:",
  Payments_By:"ଦେୟ ଦ୍ By ାରା",
  Timesheets_And_Schedules:"ସମୟ ସିଟ୍ ଏବଂ କାର୍ଯ୍ୟସୂଚୀ",
  Both:"ଉଭୟ",
  Items:"ଆଇଟମ୍",
  Add_Payees:"ଦେୟ ଯୋଡନ୍ତୁ",
  Add_Account:"ଖାତା ଯୋଡନ୍ତୁ",
  Enable_Account:"ଖାତା ସକ୍ଷମ କର",
  Enable_Payroll:"ବେତନ ସକ୍ଷମ କରନ୍ତୁ",
  Print_Payroll:"ବେତନ ମୁଦ୍ରଣ",
  No_Payrolls:"ଦରମା ନାହିଁ",
  No_Payroll_Word:"ଆପଣଙ୍କର ପ୍ରଥମ <a routerLink='/folder/Payroll/New'> ବେତନ </a> ସୃଷ୍ଟି କରନ୍ତୁ।",
  View_more:"VIEW_MORE",
  Less:"କମ",
  Add_Payroll:"ବେତନ ଯୋଡନ୍ତୁ",
  Select_Payroll:"ବେତନ ଚୟନ କରନ୍ତୁ",
  About_Your_Salary:"ତୁମର ଦରମା ବିଷୟରେ",
  Add_Salaries:"ଦରମା ଯୋଡନ୍ତୁ",
  Add_Salary:"ଦରମା ଯୋଡନ୍ତୁ",
  Salaries:"ଦରମା",
  No_Salaries:"ଦରମା ନାହିଁ",
  No_Salaries_Word:"ପ୍ରଥମ <a routerLink='/folder/Salary/New'> ଦରମା </a> ଯୋଡନ୍ତୁ |",
  Select_Salaries:"ଦରମା ଚୟନ କରନ୍ତୁ",
  New_Salary:"ନୂତନ ଦରମା",
  Salary_Name:"ଦରମା ପରିଚାୟକ",
  Enable_Salary:"ଦରମା ସକ୍ଷମ କର",
  Salary_Amount:"ଦରମା ରାଶି",
  New_Schedule:"ନୂତନ କାର୍ଯ୍ୟସୂଚୀ",
  Schedule_Title:"କାର୍ଯ୍ୟସୂଚୀ ଆଖ୍ୟା",
  Add_Address:"ଠିକଣା ଠିକଣା",
  Repeat:"ପୁନରାବୃତ୍ତି",
  Design:"ଡିଜାଇନ୍",
  Edit_Design:"ଡିଜାଇନ୍ ସଂପାଦନା",
  Edit_this_Design:"ଏହି ଡିଜାଇନ୍ ସଂପାଦନ କରନ୍ତୁ",
  Repeat_Payment:"ପୁନରାବୃତ୍ତି ଦେୟ",
  Enable_Schedule:"କାର୍ଯ୍ୟସୂଚୀ ସକ୍ଷମ କରନ୍ତୁ",
  Never:"କେବେ ନୁହେଁ",
  Select_Schedule:"କାର୍ଯ୍ୟସୂଚୀ ଚୟନ କରନ୍ତୁ",
  No_Schedules:"କ No ଣସି କାର୍ଯ୍ୟସୂଚୀ ନାହିଁ",
  No_Schedules_Word:"ପ୍ରଥମ <a routerLink='/folder/Schedule/New'> ଅନୁସୂଚୀତ </a> ସୃଷ୍ଟି କରନ୍ତୁ |",
  New_Administrator:"ନୂତନ ପ୍ରଶାସକ",
  Username:"ଉପଯୋଗକର୍ତ୍ତା ନାମ",
  First_Name:"ପ୍ରଥମ ନାମ",
  Last_Name:"ଶେଷ ନାମ",
  Add_an_Address:"ଏକ ଠିକଣା ଯୋଡନ୍ତୁ",
  Payment_Limit:"ପ୍ରତି ଦେୟ ସୀମା",
  Total_Limit:"ମୋଟ ସୀମା",
  Select_Accounts:"ଖାତା ଚୟନ କରନ୍ତୁ",
  No_Administrators:"କ No ଣସି ପ୍ରଶାସକ ନାହିଁ",
  No_Administrators_Word:"ପ୍ରଥମ <a routerLink='/folder/Administrator/New'> ଆଡମିନ୍ ଆକାଉଣ୍ଟ୍ </a> ସୃଷ୍ଟି କରନ୍ତୁ |",
  New_Administrators_Word:"ପ୍ରଥମ <a routerLink='/folder/Administrator/New'> ଆଡମିନିଷ୍ଟ୍ରେଟର୍ </a> ଯୋଡନ୍ତୁ",
  Cloud:"କ୍ଲାଉଡ୍",
  Backup:"ବ୍ୟାକଅପ୍",
  Enable_iCloud:"ICloud ସକ୍ଷମ କରନ୍ତୁ",
  Enable_Google_Drive:"ଗୁଗୁଲ୍ ଡ୍ରାଇଭ୍ ସକ୍ଷମ କର",
  Enable_Microsoft_OneDrive:"ମାଇକ୍ରୋସଫ୍ଟ ୱାନଡ୍ରାଇଭ୍ ସକ୍ଷମ କର",
  Automatically_Backup:"ସ୍ୱୟଂଚାଳିତ ବ୍ୟାକଅପ୍",
  FTP_Settings:"FTP ସେଟିଂସମୂହ",
  URL_File_Prompt:"ଆମଦାନି କରିବାକୁ ଦୟାକରି ଏକ .xls / .xlsx / .json ଫାଇଲ୍ ପାଇଁ ସ୍ଥାନ ନିର୍ଦ୍ଦିଷ୍ଟ କରନ୍ତୁ:",
  URL_SQL_Prompt:"ଆମଦାନି କରିବାକୁ ଦୟାକରି SQLite ଫାଇଲର ଅବସ୍ଥାନ ନିର୍ଦ୍ଦିଷ୍ଟ କରନ୍ତୁ:",
  FTP_Backup:"FTP ବ୍ୟାକଅପ୍",
  FTP_Import:"FTP ଆମଦାନୀ",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"ହୋଷ୍ଟ",
  Port:"ବନ୍ଦର",
  Path:"ପଥ",
  Data_Path:"ଡାଟା ପଥ",
  Enable_FTP_Account:"FTP ଖାତା ସକ୍ଷମ କର",
  HostnameIP:"ହୋଷ୍ଟନାମ",
  Password:"ପାସୱାର୍ଡ",
  Connection_Port:"ସଂଯୋଗ ପୋର୍ଟ",
  Enable_MySQL_Database:"MySQL ଡାଟାବେସ୍ ସକ୍ଷମ କରନ୍ତୁ",
  Log:"ଲଗ୍",
  Reset:"ପୁନ et ସେଟ୍",
  Erase:"ଲିଭାନ୍ତୁ",
  Export:"ରପ୍ତାନି",
  Database:"ଡାଟାବେସ୍",
  Upload_CSV:"CSV ଅପଲୋଡ୍ କରନ୍ତୁ",
  Download_CSV:"CSV ଡାଉନଲୋଡ୍ କରନ୍ତୁ",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL ବ୍ୟାକଅପ୍",
  Internal_Notes:"ଆଭ୍ୟନ୍ତରୀଣ ଟିପ୍ପଣୀ",
  Root_Path:"ମୂଳ ପଥ",
  Select_Backup:"ବ୍ୟାକଅପ୍ ଚୟନ କରନ୍ତୁ",
  Add_New_Backup:"ଏକ ନୂତନ ବ୍ୟାକଅପ୍ ଯୋଡନ୍ତୁ",
  First_Backup:"ପ୍ରଥମ ବ୍ୟାକଅପ୍ ସେଟ୍ଅପ୍ କରନ୍ତୁ ...",
  Backups:"ବ୍ୟାକଅପ୍",
  Add_Backup:"ବ୍ୟାକଅପ୍ ଯୋଡନ୍ତୁ",
  No_Backups:"କ back ଣସି ବ୍ୟାକଅପ୍ ମିଳିବ ନାହିଁ।",
  Select_Backup_Type:"ଆପଣ ସେଟଅପ୍ କରିବାକୁ ଚାହୁଁଥିବା ବ୍ୟାକଅପ୍ ପ୍ରକାର ଚୟନ କରନ୍ତୁ ...",
  Backup_Type:"ବ୍ୟାକଅପ୍ ପ୍ରକାର",
  FTP_Drive:"FTP ଡ୍ରାଇଭ୍",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"ଡ୍ରାଇଭ୍",
  Microsoft_OneDrive:"ଡ୍ରାଇଭ୍",
  Import_Fields:"କ୍ଷେତ୍ରଗୁଡିକ ଆମଦାନୀ କରନ୍ତୁ",
  Import_Fields_Word:"<a routerLink='/folder/Holiday/New'> ଆମଦାନି </a> ତଥ୍ୟ ବାଛିବା ପାଇଁ ଏହି ବିଭାଗକୁ ବ୍ୟବହାର କରନ୍ତୁ |",
  Upload:"ଅପଲୋଡ୍",
  Download:"ଡାଉନଲୋଡ୍",
  Download_JSON:"JSON ଡାଟା ଭାବରେ ଡାଉନଲୋଡ୍ କରନ୍ତୁ",
  Download_SQL:"SQL ଫାଇଲ୍ ଭାବରେ ଡାଉନଲୋଡ୍ କରନ୍ତୁ",
  New_Bank:"ନୂତନ ବ୍ୟାଙ୍କ",
  New_Account:"ନୂତନ ଖାତା",
  New_Bank_Account:"ନ୍ୟୁ ବ୍ୟାଙ୍କ ଆକାଉଣ୍ଟ",
  Upload_Image:"ପ୍ରତିଛବି ଅପଲୋଡ୍ କରନ୍ତୁ",
  Bank_Name:"ବ୍ୟାଙ୍କ ନାମ",
  Bank_Address:"ବ୍ୟାଙ୍କ ଠିକଣା",
  Branch_Address:"ଶାଖା ଠିକଣା",
  Address_on_Cheque:"ଠିକଣା",
  Cheque_Numbers:"ନମ୍ବର ଯାଞ୍ଚ କରନ୍ତୁ",
  Cheque_Details:"ବିବରଣୀ ଯାଞ୍ଚ କରନ୍ତୁ",
  Bank_Logo:"ବ୍ୟାଙ୍କ ଲୋଗୋ",
  Cheque_ID:"ID ଯାଞ୍ଚ କରନ୍ତୁ",
  Starting_Cheque_ID:"ଚେକ୍ ID ଆରମ୍ଭ",
  Transit_Number:"ଗମନାଗମନ ସଂଖ୍ୟା",
  Institution_Number:"ଅନୁଷ୍ଠାନ ସଂଖ୍ୟା",
  Designation_Number:"ନାମକରଣ ସଂଖ୍ୟା",
  Account_Number:"ଖାତା ସଂଖ୍ୟା",
  Limits:"ସୀମା",
  Default_Limits:"ଡିଫଲ୍ଟ ସୀମା",
  Over_Limit:"ଓଭର ସୀମା",
  Under_Limit:"ସୀମା ତଳେ",
  Payroll_Limit:"ବେତନ ସୀମା",
  Enable_Bank_Account:"ବ୍ୟାଙ୍କ ଆକାଉଣ୍ଟ ସକ୍ଷମ କରନ୍ତୁ",
  Select_Account:"ଖାତା ଚୟନ କରନ୍ତୁ",
  No_Bank_Account:"ବ୍ୟାଙ୍କ ଆକାଉଣ୍ଟ ନାହିଁ",
  No_Bank_Account_Word:"ପ୍ରଥମ <a routerLink='/folder/Accounts/New'> ବ୍ୟାଙ୍କ ଆକାଉଣ୍ଟ </a> ଯୋଡନ୍ତୁ |",
  Bank_Accounts:"ବ୍ୟାଙ୍କ ଆକାଉଣ୍ଟସ୍",
  No_Accounts:"କ Account ଣସି ଖାତା ନାହିଁ",
  No_Accounts_Note:"ପ୍ରଥମ <a routerLink='/folder/Accounts/New'> ବ୍ୟାଙ୍କ ଆକାଉଣ୍ଟ </a> ଯୋଡନ୍ତୁ |",
  Cheque_Designer:"ଚେକ୍ ଡିଜାଇନର୍",
  Cheque_Design:"ଡିଜାଇନ୍ ଚେକ୍",
  Select_Design:"ଏକ ଡିଜାଇନ୍ ଚୟନ କରନ୍ତୁ",
  Cheque_Designs:"ଡିଜାଇନ୍ ଯାଞ୍ଚ କରନ୍ତୁ",
  No_Cheque_Designs:"କ Check ଣସି ଚେକ୍ ଡିଜାଇନ୍ ନାହିଁ",
  No_Cheque_Designs_Word:"ଆପଣଙ୍କର ନିଜର <a routerLink='/folder/Settings/Cheque/Design/New'> ଚେକ୍ ଡିଜାଇନ୍ </a> ସୃଷ୍ଟି କରନ୍ତୁ |",
  Set_Default:"ଡିଫଲ୍ଟ ଭାବରେ ସେଟ୍ କରନ୍ତୁ",
  Default:"ଡିଫଲ୍ଟ",
  Remove:"ଅପସାରଣ",
  Folder:"ଫୋଲ୍ଡର",
  Edit:"ସଂପାଦନା",
  LoadingDots:"ଲୋଡିଂ ...",
  Add_a_New_File:"ଏକ <a href='#' (click)='add()'> ନୂତନ ଫାଇଲ୍ </a> କୁ ଯୋଡନ୍ତୁ",
  this_folder:"ଏହି ଫୋଲ୍ଡର",
  FTP_Backup_Settings:"FTP ବ୍ୟାକଅପ୍ ସେଟିଂସମୂହ",
  Secure_File_Transfer:"ସୁରକ୍ଷିତ ଫାଇଲ୍ ସ୍ଥାନାନ୍ତର",
  New_Holiday:"ନୂତନ ଛୁଟିଦିନ",
  Add_Holiday:"ଛୁଟିଦିନ ଯୋଡନ୍ତୁ",
  Holiday_Name:"ଛୁଟିଦିନ ନାମ",
  Additional_Pay:"ଅତିରିକ୍ତ ଦେୟ",
  Enable_Holiday:"ଛୁଟିଦିନ ସକ୍ଷମ କର",
  Select_Holidays:"ଛୁଟିଦିନ ଚୟନ କରନ୍ତୁ",
  No_Holidays:"ଛୁଟିଦିନ ନାହିଁ",
  No_Holidays_Word:"ପ୍ରଥମ <a routerLink='/folder/Holiday/New'> ସର୍ବସାଧାରଣ ଛୁଟି </a> ଯୋଡନ୍ତୁ |",
  New_Import:"ନୂତନ ଆମଦାନୀ",
  Import_Data:"ଡାଟା ଆମଦାନୀ",
  Import_Data_Word:"ଆପଣ ବାଛିଥିବା ଫାଇଲର ପ୍ରକାର ବା ଅପଲୋଡ୍ ପଦ୍ଧତି ବାଛନ୍ତୁ। <br /> ଏକ ଫାଇଲରେ କେଉଁ ଆମଦାନୀ ହୋଇଥିବା କ୍ଷେତ୍ରଗୁଡିକ ଏକ ସମର୍ଥିତ ଫାଇଲ ଅପଲୋଡ୍ କରିବା ପରେ ଆପରେ ଥିବା କେଉଁ ପାରାମିଟର ସହିତ ମେଳ ଖାଇବାକୁ ଆପଣ ସକ୍ଷମ ହେବେ।", 
  Import_File:"ଫାଇଲ ଆମଦାନୀ",
  Link_To_File:"ଫାଇଲ୍ କୁ ଲିଙ୍କ୍",
  Select_File:"ଫାଇଲ୍ ଚୟନ କରନ୍ତୁ",
  New_Moderator:"ନୂତନ ମୋଡରେଟର୍",
  Allow_Moderation:"ମଧ୍ୟମତାକୁ ଅନୁମତି ଦିଅନ୍ତୁ",
  Create_Paycheques:"ପେଚେକ୍ ସୃଷ୍ଟି କରନ୍ତୁ",
  Edit_Paycheques_and_Data:"ପେଚେକ୍ ଏବଂ ଡାଟା ସଂପାଦନ କରନ୍ତୁ",
  Destroy_Data_and_Paycheques:"ଡାଟା ଏବଂ ପେଚେକ୍ ନଷ୍ଟ କରନ୍ତୁ",
  Bonus_Percentage:"ପେଚେକ୍ ଶତକଡା",
  Fixed_Amount:"ସ୍ଥିର ପରିମାଣ",
  Select_Moderator:"ମୋଡରେଟର୍ ଚୟନ କରନ୍ତୁ",
  No_Moderators:"କ Mod ଣସି ମୋଡରେଟର୍ ନାହିଁ",
  Moderators:"ମୋଡରେଟର୍",
  Moderator_Account:"ପ୍ରଥମ <a routerLink='/folder/Administrator/New'> ମୋଡରେଟର୍ ଆକାଉଣ୍ଟ୍ </a> ସୃଷ୍ଟି କରନ୍ତୁ |",
  No_Moderators_Word:"ପ୍ରଥମ <a routerLink='/folder/Administrator/New'> ମୋଡରେଟର୍ </a> ଯୋଡନ୍ତୁ |",
  Defaults:"ଡିଫଲ୍ଟସ୍",
  Provide_Us_Info:"ଆମକୁ ସୂଚନା ପ୍ରଦାନ କରନ୍ତୁ",
  Setup_Your_Printer:"ତୁମର ପ୍ରିଣ୍ଟର୍ ସେଟ୍ ଅପ୍",
  Your_Company:"ତୁମ କମ୍ପାନୀ ବିଷୟରେ",
  Company_Name:"କମ୍ପାନିର ନାମ",
  Currency:"ମୁଦ୍ରା",
  Default_Currency:"ଡିଫଲ୍ଟ ମୁଦ୍ରା",
  Base_Monthly_Income:"ମାସିକ ଆୟ",
  Protection:"ସୁରକ୍ଷା",
  App_Protection:"ଆପ୍ ସୁରକ୍ଷା",
  PIN_Code_Protection:"ପିନ୍ କୋଡ୍ ସୁରକ୍ଷା",
  App_Protection_Word:"ସୁରକ୍ଷା ପଦ୍ଧତିକୁ ସକ୍ଷମ କର ଯାହାକି ତୁମର ଖାତାକୁ ସୁରକ୍ଷା କରିବାରେ ସାହାଯ୍ୟ କରେ |",
  PIN_Code:"ପିନ୍ କୋଡ୍",
  Change_PIN:"PIN ପରିବର୍ତ୍ତନ କରନ୍ତୁ",
  New_Password:"ନୁତନ ପାସ୍ୱାର୍ଡ",
  Geofence_Protection:"ଜିଓ-ବାଡ଼ ସୁରକ୍ଷା",
  Geofence_Area:"କ୍ଷେତ୍ର ସ୍ଥିର କରନ୍ତୁ",
  Distance:"ଦୂରତା",
  Setup_Now:"ବର୍ତ୍ତମାନ ସେଟଅପ୍",
  Mile:"ମାଇଲ୍",
  Km:"କିମି",
  m:"ମି",
  Facial_Recognition:"ମୁଖ ଚିହ୍ନ",
  Face:"ଚେହେରା",
  Setup:"ସେଟଅପ୍",
  Label:"ଲେବଲ୍",
  Password_Protection:"ପାସୱାର୍ଡ ସୁରକ୍ଷା",
  Modify_Password:"ରୂପାନ୍ତର ପାସୱାର୍ଡ",
  New_Tax_Entry:"ନୂତନ କର ପ୍ରବେଶ",
  New_Tax:"ନୂତନ କର",
  Tax_Label:"ଟ୍ୟାକ୍ସ ଲେବଲ୍",
  Perpetually_Enabled:"Perpetually ସାମର୍ଥ୍ୟ",
  Select_Taxes:"ଟ୍ୟାକ୍ସ ଚୟନ କରନ୍ତୁ",
  Tax_Deductions:"ଟିକସ ରିହାତି",
  No_Tax_Deductions:"କ Tax ଣସି ଟିକସ ରିହାତି ନାହିଁ",
  No_Tax_Deductions_Word:"ପ୍ରଥମ <a routerLink='/folder/Tax/New'> ଟ୍ୟାକ୍ସ </a> ରିହାତି ଯୋଗକର |",
  New_Timer:"ନୂତନ ମାପକକୁ",
  Start:"ଆରମ୍ଭ",
  Stop:"ବନ୍ଦ",
  Start_Timer:"ଟାଇମର୍ ଆରମ୍ଭ",
  Stop_Timer:"ଟାଇମର୍ ବନ୍ଦ କରନ୍ତୁ",
  Timer_Active:"ଟାଇମର୍ ଆକ୍ଟିଭ୍",
  Timer:"ଟାଇମର୍:",
  Timer_Running:"କାଳମାପକ: (Running)",
  Save_Timer:"ଟାଇମର୍ ସେଭ୍",
  New_Timesheet:"ନୂତନ ସମୟ ସିଟ୍",
  Select_Timesheets:"ଟାଇମ୍ ସିଟ୍ ଚୟନ କରନ୍ତୁ",
  Work_Notes:"କାର୍ଯ୍ୟ ଟିପ୍ପଣୀ",
  Entry_Title:"ଏଣ୍ଟ୍ରି ଆଖ୍ୟା",
  No_Timesheets:"ଟାଇମ୍ ସିଟ୍ ନାହିଁ",
  No_Timesheets_Word:"ପ୍ରଥମ <a routerLink='/folder/Timesheet/New'> ଟାଇମ୍ ସିଟ୍ </a> ଯୋଡନ୍ତୁ |",
  Timesheet_Submitted:"ଟାଇମ୍ ସିଟ୍ ଦାଖଲ",
  Timesheet_Congrats:"ଅଭିନନ୍ଦନ! ତୁମର ସମୟ ସିଟ୍ ସଫଳତାର ସହିତ ଉପସ୍ଥାପିତ ହୋଇଛି। ଧନ୍ୟବାଦ!",
  Timesheet_Copy:"ଆପଣଙ୍କର ଡକ୍ୟୁମେଣ୍ଟଗୁଡିକର ଏକ କପି ପାଇବାକୁ ଦୟାକରି ଆମକୁ ଆପଣଙ୍କର ଇମେଲ୍ ଠିକଣା ଏବଂ / କିମ୍ବା ମୋବାଇଲ୍ ଫୋନ୍ ନମ୍ବର ପ୍ରଦାନ କରନ୍ତୁ |",
  Submit:"ଦାଖଲ",
  Allow_Notifications:"ବିଜ୍ଞପ୍ତି ଅନୁମତି",
  Untitled_Entry:"ନୂତନ ପ୍ରବେଶ",
  Untitled_Bank:"ଆଖ୍ୟାବିହୀନ ବ୍ୟାଙ୍କ",
  Timesheet_Entry:"ଟାଇମ୍ ସିଟ୍ ଏଣ୍ଟ୍ରି",
  Work_Description:"କାର୍ଯ୍ୟ ବର୍ଣ୍ଣନା",
  Enable_Timesheet:"ଟାଇମ୍ ସିଟ୍ ସକ୍ଷମ କର",
  Submit_Timesheet:"ଟାଇମ୍ ସିଟ୍ ଦାଖଲ କରନ୍ତୁ",
  Vacation:"ଛୁଟି",
  New_Vacation:"ନୂତନ ଛୁଟି",
  Vacation_Name:"ଛୁଟି ନାମ",
  Paid_Vacation:"ଦେୟ Vacation",
  Vacation_Pay:"ଛୁଟି ଦେୟ",
  Enable_Vacation:"ଛୁଟି ସକ୍ଷମ କର",
  Select_Vacations:"ଛୁଟି ଚୟନ କରନ୍ତୁ",
  No_Vacations:"କ No ଣସି ଛୁଟି ନାହିଁ",
  No_Vacations_Word:"ପ୍ରଥମ <a routerLink='/folder/Vacation/New'> ଛୁଟି </a> ଏଣ୍ଟ୍ରି ସୃଷ୍ଟି କରନ୍ତୁ |",
  Payroll_Schedule:"େବତନଗ୍ରାହୀ ନାମାବଳୀ Schedule",
  Next_Payroll:"ପରବର୍ତ୍ତୀ ବେତନ:",
  Upcoming_Payroll:"ଆଗନ୍ତୁକ େବତନଗ୍ରାହୀ ନାମାବଳୀ",
  No_Upcoming_Payroll:"ଆଗାମୀ ଦରମା ନାହିଁ",
  Address_Book:"ଠିକଣା ବହି",
  Archived_Documents:"ଆର୍କାଇଭ୍ ଡକ୍ୟୁମେଣ୍ଟ୍",
  Dev_Mode:"ବିକାଶ ମୋଡରେ ପ୍ରୟୋଗ",
  Administrators:"ପ୍ରଶାସକ",
  Privacy:"ଗୋପନୀୟତା",
  None:"କିଛି ନୁହେଁ",
  Select_Signature:"ଦସ୍ତଖତ ଚୟନ କରନ୍ତୁ",
  No_Signatures:"କ Sign ଣସି ଦସ୍ତଖତ ନାହିଁ",
  No_Signatures_Word:"ପ୍ରଥମ <a routerLink='/folder/Signature/New'> ଦସ୍ତଖତ </a> ଯୋଡନ୍ତୁ |",
  Repeat_Indefinitely:"ଅନିର୍ଦ୍ଦିଷ୍ଟ କାଳ ପାଇଁ ପୁନରାବୃତ୍ତି",
  Sign:"ସାଇନ୍",
  Sign_Here:"ଏଠାରେ ସାଇନ୍ କରନ୍ତୁ",
  Date_Signed:"ତାରିଖ ଦସ୍ତଖତ",
  Signature_Pad:"ଦସ୍ତଖତ ପ୍ୟାଡ୍",
  Account_Holder:"ଖାତା ଧାରକ",
  Account_Properties:"ଆକାଉଣ୍ଟ୍ ପ୍ରପର୍ଟିଜ୍",
  Name_On_Cheque:"ଚେକ୍ ଉପରେ ନାମ",
  Server_Hostname:"ସର୍ଭର ହୋଷ୍ଟନାମ / IP",
  Printers:"ପ୍ରିଣ୍ଟର୍ସ",
  No_Printers:"କ Pr ଣସି ପ୍ରିଣ୍ଟର୍ ନାହିଁ",
  Printer_Exists:'ଏହି ନାମରେ ଏକ ପ୍ରିଣ୍ଟର୍ ପୂର୍ବରୁ ବିଦ୍ୟମାନ ଅଛି |',
  No_Printers_Word:"ପ୍ରଥମ <a routerLink='/folder/Printer/New'> ପ୍ରିଣ୍ଟର୍ </a> ଯୋଡନ୍ତୁ |",
  No_Printer_Alert:"କ No ଣସି ପ୍ରିଣ୍ଟର୍ ବ୍ୟାଖ୍ୟା କରାଯାଇ ନାହିଁ। ଆପଣ ଏକ ପ୍ରିଣ୍ଟର୍ ସେଟ୍ ଅପ୍ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Add_Printer:"ପ୍ରିଣ୍ଟର୍ ଯୋଡନ୍ତୁ",
  New_Printer:"ନୂତନ ପ୍ରିଣ୍ଟର୍",
  Printer_Hostname:"ପ୍ରିଣ୍ଟର୍ ହୋଷ୍ଟନାମ / IP",
  Printer_Label:"ପ୍ରିଣ୍ଟର୍ ଲେବଲ୍",
  Printer_Protocol:"ପ୍ରିଣ୍ଟର୍ ପ୍ରୋଟୋକଲ୍",
  Protocol:"ପ୍ରୋଟୋକଲ୍",
  Email_Print:"ଇମେଲ୍",
  AirPrint:"ଏୟାରପ୍ରିଣ୍ଟ",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"ସକେଟ",
  Print_Job:"ମୁଦ୍ରଣ କାର୍ଯ୍ୟ",
  Printed:"ମୁଦ୍ରିତ",
  Not_Printed:"ମୁଦ୍ରିତ ନୁହେଁ",
  Print_Jobs:"ମୁଦ୍ରଣ କାର୍ଯ୍ୟ",
  Print_Queue:"Queue କୁ ବାଛି ମୁଦ୍ରଣ",
  No_Print_Jobs:"କ No ଣସି ମୁଦ୍ରଣ କାର୍ଯ୍ୟ ନାହିଁ",
  No_Prints:"ଏକ ନୂଆ <a routerLink='/folder/Cheque/New'> ପ୍ରିଣ୍ଟ୍ </a> ଯାଞ୍ଚ କରନ୍ତୁ।",
  Prints:"ମୁଦ୍ରଣ",
  Find_Printer:"ପ୍ରିଣ୍ଟର୍ ଖୋଜ",
  Find_AirPrint_Devices:"ଏୟାରପ୍ରିଣ୍ଟ ଉପକରଣ ଖୋଜ",
  Select_Printer:"ପ୍ରିଣ୍ଟର୍ ଚୟନ କରନ୍ତୁ",
  System_UI:"ସିଷ୍ଟମ୍ UI",
  Printer:"ପ୍ରିଣ୍ଟର୍",
  Status:"ସ୍ଥିତି",
  Preview:"ପୂର୍ବାବଲୋକନ",
  Enable_Print_Job:"ମୁଦ୍ରଣ ଆୟୁବ ସାମର୍ଥ୍ୟ",
  Queue_Weight:"Queue କୁ ବାଛି ଓଜନ",
  Unlimited:"ଅସୀମିତ",
  Show_Advanced_Printer_Options:"ଉନ୍ନତ ପ୍ରିଣ୍ଟର୍ ବିକଳ୍ପଗୁଡିକ ଦେଖାନ୍ତୁ",
  Advanced:"ଉନ୍ନତ",
  Location:"ଅବସ୍ଥାନ",
  Note:"ଧ୍ୟାନ ଦିଅନ୍ତୁ",
  Queue_Name:"Queue କୁ ବାଛି ନାମ",
  Pages_Printed_Limit:"ପୃଷ୍ଠାଗୁଡ଼ିକ ମୁଦ୍ରିତ ସୀମା",
  MultiPage_Idle_Time:"ମଲ୍ଟିପେଜ୍ ଅପେକ୍ଷା ସମୟ (ଗୁଡିକ)",
  Page_Count_Limit:"ପୃଷ୍ଠା ଗଣନା ସୀମା",
  Page_Orientation:"ପୃଷ୍ଠା ଓରିଏଣ୍ଟେସନ୍",
  Portrait:"ପୋର୍ଟ୍ରେଟ୍",
  Landscape:"ଲ୍ୟାଣ୍ଡସ୍କେପ୍",
  Duplex:"ଡୁପ୍ଲେକ୍ସ",
  Double_Sided_Printing:"ଡବଲ୍ ସାଇଡ୍",
  Duplex_Mode:"ଡୁପ୍ଲେକ୍ସ ମୋଡ୍",
  Duplex_Short:"ସଂକ୍ଷିପ୍ତ",
  Duplex_Long:"ଲମ୍ବା",
  Duplex_None:"କିଛି ନୁହେଁ",
  Color_And_Quality:"ରଙ୍ଗ ଏବଂ ଗୁଣ",
  Monochrome:"ମୋନୋକ୍ରୋମ୍",
  Photo_Quality_Prints:"ଫଟୋ ଗୁଣବତ୍ତା ପ୍ରିଣ୍ଟ୍",
  Printer_Email:"ପ୍ରିଣ୍ଟର୍ ଇମେଲ୍",
  Automatically_Downsize:"ସ୍ୱୟଂଚାଳିତ ଭାବରେ ଡାଉନସାଇଜ୍",
  Paper:"କାଗଜ",
  Paper_Name:"କାଗଜ ନାମ",
  Paper_Width:"କାଗଜ ଓସାର",
  Paper_Height:"କାଗଜ ଉଚ୍ଚତା",
  Paper_Autocut_Length:"କାଗଜ ଅଟୋ-କଟ୍ ଲମ୍ବ",
  Margins:"ମାର୍ଜିନ୍ସ",
  Page_Margins:"ପୃଷ୍ଠା ମାର୍ଜିନସ୍",
  Page_Margin_Top:"ଶୀର୍ଷ ପୃଷ୍ଠା ମାର୍ଜିନ୍",
  Page_Margin_Right:"ଡାହାଣ ପୃଷ୍ଠା ମାର୍ଜିନ୍",
  Page_Margin_Bottom:"ନିମ୍ନ ପୃଷ୍ଠା ମାର୍ଜିନ୍",
  Page_Margin_Left:"ବାମ ପୃଷ୍ଠା ମାର୍ଜିନ୍",
  Add_Employees:"କର୍ମଚାରୀ ଯୋଡନ୍ତୁ",
  Header:"ହେଡର୍",
  Print_A_Page_Header:"ଏକ ପୃଷ୍ଠା ହେଡର୍ ପ୍ରିଣ୍ଟ୍",
  Header_Label:"ହେଡର୍ ଲେବଲ୍",
  Header_Page_Index:"ହେଡର୍ ପେଜ୍ ଇଣ୍ଡେକ୍ସ",
  Header_Font_Name:"ହେଡର୍ ଫଣ୍ଟ୍",
  Select_Font:"ଫଣ୍ଟ ଚୟନ କରନ୍ତୁ",
  Font_Selector:"ଫଣ୍ଟ୍ ସିଲେକ୍ଟର୍",
  Header_Font_Size:"ହେଡର୍ ଫଣ୍ଟ୍",
  Header_Bold:"ହେଡର୍ ବୋଲ୍ଡ",
  Header_Italic:"ହେଡର୍ ଇଟାଲିକ୍",
  Header_Alignment:"ହେଡର୍ ଆଲାଇନ୍ମେଣ୍ଟ",
  Left:"ବାମ",
  Center:"କେନ୍ଦ୍ର",
  Right:"ଠିକ୍",
  Justified:"ଯଥାର୍ଥ",
  Header_Font_Color:"ହେଡର୍ ରଙ୍ଗ",
  Select_Color:"ରଙ୍ଗ ଚୟନ କରନ୍ତୁ",
  Footer:"ଫୁଟର୍",
  Print_A_Page_Footer:"ଏକ ପୃଷ୍ଠା ଫୁଟର୍ ପ୍ରିଣ୍ଟ୍",
  Footer_Label:"ଫୁଟର୍ ଲେବଲ୍",
  Footer_Page_Index:"ଫୁଟର୍ ପେଜ୍ ଇଣ୍ଡେକ୍ସ",
  Footer_Font_Name:"ଫୁଟର୍ ଫଣ୍ଟ୍",
  Fonts:"ଫଣ୍ଟ",
  Done:"ସମାପ୍ତ",
  Select_Fonts:"ଫଣ୍ଟଗୁଡିକ ଚୟନ କରନ୍ତୁ",
  Footer_Font_Size:"ଫୁଟର୍ ସାଇଜ୍",
  Footer_Bold:"ଫୁଟର୍ ବୋଲ୍ଡ",
  Footer_Italic:"ଫୁଟର୍ ଇଟାଲିକ୍",
  Footer_Alignment:"ଫୁଟର୍ ଆଲାଇନ୍ମେଣ୍ଟ",
  Footer_Font_Color:"ଫୁଟର୍ ରଙ୍ଗ",
  Page_Copies:"ପୃଷ୍ଠା କପି",
  Enable_Printer:"ପ୍ରିଣ୍ଟର୍ ସକ୍ଷମ କର",
  Remote_Logging:"ରିମୋଟ ଲଗିଂ",
  Log_Server:"ଲଗ୍ ସର୍ଭର",
  Encryption:"ଏନକ୍ରିପସନ୍",
  Random_Key:"ରାଣ୍ଡମ୍ କି",
  Encryption_Key:"ଏନକ୍ରିପସନ୍ କୀ",
  Cheques_Webserver:"କଷ୍ଟମ୍ ଡାଟାବେସ୍",
  Learn_How:"କିପରି ଶିଖନ୍ତୁ",
  Signature:"ଦସ୍ତଖତ",
  Default_Printer_Unit:"in / cm / mm / (pt)",
  View_Signature:"ଦସ୍ତଖତ ଦର୍ଶନ",
  Printed_Signature:"ମୁଦ୍ରିତ ଦସ୍ତଖତ",
  Digitally_Sign:"ଡିଜିଟାଲ୍ ସାଇନ୍",
  Digital_Signature:"ଡିଜିଟାଲ୍ ଦସ୍ତଖତ",
  Add_Signature:"ଦସ୍ତଖତ ଯୋଡନ୍ତୁ",
  Add_Your_Signature:"ତୁମର ଦସ୍ତଖତ ଯୋଡ",
  Enable_Signature:"ଦସ୍ତଖତ ସକ୍ଷମ କରନ୍ତୁ",
  Digitally_Signed:"ଡିଜିଟାଲ୍ ସାଇନ୍",
  Insert_Error:"ଡାଟାବେସ୍ ସମସ୍ୟା ହେତୁ ଚେକ୍ ସେଭ୍ କରିବାକୁ ଅସମର୍ଥ। ",
  Delete_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ସୂଚନା ବିଲୋପ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Discard_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ସୂଚନାକୁ ପରିତ୍ୟାଗ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Discard_Bank_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ଆକାଉଣ୍ଟକୁ ପରିତ୍ୟାଗ କରିବାକୁ ଚାହୁଁଛନ୍ତି?",
  Discard_Printer_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ପ୍ରିଣ୍ଟରକୁ ପରିତ୍ୟାଗ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Delete_Bonus_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ବୋନସ୍ ଡିଲିଟ୍ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Delete_Invoice_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ଇନଭଏସ୍ ଡିଲିଟ୍ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Generated_Cheque:"ଜେନେରେଟ୍ ଚେକ୍",
  Generated_Invoice:"ଉତ୍ପାଦିତ ଇନଭଏସ୍",
  Schedule_Start_Time:"କାର୍ଯ୍ୟସୂଚୀ ଆରମ୍ଭ",
  Schedule_End_Time:"କାର୍ଯ୍ୟସୂଚୀ ଶେଷ",
  New_Call:"ନୂତନ କଲ୍",
  No_Contacts:"କ No ଣସି ସମ୍ପର୍କ ନାହିଁ",
  No_Contacts_Word:"ଆଡମିନିଷ୍ଟ୍ରେଟର୍, ମୋଡରେଟର୍, କର୍ମଚାରୀ, ଏବଂ ଦରମା ଯୋଗାଯୋଗ ଭାବରେ ଦେଖାଯାଏ |",
  PDF_Subject:"ଆର୍ଥିକ",
  PDF_Keywords:"ବେତନ ବେତନ PDF ଯାଞ୍ଚ ଯାଞ୍ଚ",
  Printer_Setup:"ପ୍ରିଣ୍ଟର୍ ସେଟଅପ୍",
  Printer_Selection:"ପ୍ରିଣ୍ଟର୍ ଚୟନ",
  New_Fax:"ନୂତନ ଫ୍ୟାକ୍ସ",
  New_Fax_Message:"ନୂତନ ଫ୍ୟାକ୍ସ ବାର୍ତ୍ତା",
  Fax_Machine:"ଫ୍ୟାକ୍ସ ମେସିନ୍",
  Fax_Name:"ଫ୍ୟାକ୍ସ ନାମ",
  Fax_Email:"ଫ୍ୟାକ୍ସ ଇମେଲ",
  Fax_Number:"ଫ୍ୟାକ୍ସ ନମ୍ବର",
  Contents:"ବିଷୟବସ୍ତୁ",
  Fax_Body:"ପୃଷ୍ଠା ଶରୀର",
  Header_Word:"ହେଡର୍:",
  Header_Text:"ହେଡର୍ ଟେକ୍ସଟ୍",
  Include_Header:"ହେଡର୍ ଅନ୍ତର୍ଭୂକ୍ତ କରନ୍ତୁ",
  Include_Footer:"ଫୁଟର୍ ଅନ୍ତର୍ଭୂକ୍ତ କରନ୍ତୁ",
  Footer_Word:"ଫୁଟର୍:",
  Footer_Text:"ଫୁଟର୍ ଟେକ୍ସଟ୍",
  Attach_a_Cheque:"ଏକ ଚେକ୍ ସଂଲଗ୍ନ କରନ୍ତୁ",
  Add_Deduction:"ଡିଡକସନ୍ ଯୋଡନ୍ତୁ",
  Enable_Fax:"ଫ୍ୟାକ୍ସ ପଠାନ୍ତୁ",
  Select_Fax:"ଫ୍ୟାକ୍ସ ଚୟନ କରନ୍ତୁ",
  No_Faxes:"କ F ଣସି ଫ୍ୟାକ୍ସ ନାହିଁ",
  Faxes:"ଫ୍ୟାକ୍ସସ୍",
  Save_and_Send:"ଫ୍ୟାକ୍ସ ପଠାନ୍ତୁ",
  Save_and_Pay:"ସଞ୍ଚୟ ଏବଂ ଦେୟ",
  WARNING:"ସତର୍କବାଣୀ:",
  Remember:"ମନେରଖ",
  Printing:"ମୁଦ୍ରଣ",
  Printing_Complete:"ମୁଦ୍ରଣ ସଂପୂର୍ଣ୍ଣ!",
  of:"of",
  There_Were:"ସେଠାରେ",
  Successful_Prints:"ସଫଳ ମୁଦ୍ରଣ ଏବଂ",
  Unsuccessful_Prints:"ଅସଫଳ ମୁଦ୍ରଣ।",
  PrinterError:"ଦୁ Sorry ଖିତ! ଏକ ତ୍ରୁଟି ଥିଲା।",
  Printing_:"ମୁଦ୍ରଣ ...",
  PrinterSuccess:"ଡକ୍ୟୁମେଣ୍ଟ୍ ସଫଳତାର ସହିତ ମୁଦ୍ରିତ |",
  PrintersSuccess:"ଡକ୍ୟୁମେଣ୍ଟଗୁଡିକ ସଫଳତାର ସହିତ ମୁଦ୍ରିତ ହୋଇଛି |",
  Print_Cheques:"ମୁଦ୍ରଣ ଯାଞ୍ଚ",
  New_Message:"ନୂତନ ବାର୍ତ୍ତା",
  New_Messages:"ନୂତନ ବାର୍ତ୍ତାଗୁଡିକ",
  Read_Message:"ବାର୍ତ୍ତା ପ Read ନ୍ତୁ",
  Write_Message:"ବାର୍ତ୍ତା ଲେଖ",
  Send_Message:"ବାର୍ତ୍ତା ପଠାନ୍ତୁ",
  Subject:"ବିଷୟ",
  Message:"ବାର୍ତ୍ତା",
  Writing:"ଲେଖୁଛି ...",
  Send:"ପଠାନ୍ତୁ",
  Set_Date:"ତାରିଖ ସେଟ୍ କରନ୍ତୁ",
  Set_Time:"ସମୟ ସେଟ୍ କରନ୍ତୁ",
  Recieve:"ପ୍ରାପ୍ତ କରନ୍ତୁ",
  Set_as_Default:"ଡିଫଲ୍ଟ ଭାବରେ ସେଟ୍ କରନ୍ତୁ",
  Default_Account:"ଡିଫଲ୍ଟ ଆକାଉଣ୍ଟ୍",
  Default_Design:"ଡିଫଲ୍ଟ ଡିଜାଇନ୍",
  Multiple_Cheques:"ଚେକ୍ (ଟ୍ରିପଲ୍)",
  Account_Mode:"ଆକାଉଣ୍ଟ୍ ମୋଡ୍",
  Multiple_Cheques_Description:"ପ୍ରତି ପୃଷ୍ଠାରେ ତିନୋଟି ଯାଞ୍ଚ |",
  Check_and_Table:"ଯାଞ୍ଚ ଏବଂ ସାରଣୀ",
  Check_including_Table:"ଯାଞ୍ଚ ଏବଂ ଆକାଉଣ୍ଟିଂ ଟେବୁଲ୍।",
  Check_Mailer:"ମେଲର୍ ଚେକ୍ କରନ୍ତୁ",
  Check_Mailer_Window:"ଏକ ଠିକଣା ୱିଣ୍ଡୋ ସହିତ ଯାଞ୍ଚ କରନ୍ତୁ |",
  DocuGard_Table_Top:"ଡକ୍ୟୁଗାର୍ଡ ଚେକ୍ ଏବଂ ଟେବୁଲ୍ (ଶୀର୍ଷ)",
  DocuGard_Table_Middle:"ଡକ୍ୟୁଗାର୍ଡ ଚେକ୍ ଏବଂ ଟେବୁଲ୍ (ମଧ୍ୟମ)",
  DocuGard_Table_Bottom:"ଡକ୍ୟୁଗାର୍ଡ ଚେକ୍ ଏବଂ ଟେବୁଲ୍ (ତଳ)",
  DocuGard_Mailer_Top:"ଡକ୍ୟୁ ଗାର୍ଡ ଚେକ୍ ମେଲର୍ (ଶୀର୍ଷ)",
  DocuGard_Mailer_Middle:"ଡକ୍ୟୁ ଗାର୍ଡ ଚେକ୍ ମେଲର୍ (ମଧ୍ୟମ)",
  DocuGard_Mailer_Bottom:"ଡକ୍ୟୁ ଗାର୍ଡ ଚେକ୍ ମେଲର୍ (ତଳ)",
  DocuGard_Three_Cheques:"ଡକ୍ୟୁଗାର୍ଡ ଚେକ୍ (ଟ୍ରିପଲ୍)",
  DocuGard_Cheque_Top:"ଡକ୍ୟୁଗାର୍ଡ ଚେକ୍ (ଟପ୍)",
  DocuGard_Cheque_Middle:"ଡକ୍ୟୁଗାର୍ଡ ଚେକ୍ (ମଧ୍ୟମ)",
  DocuGard_Cheque_Bottom:"ଡକ୍ୟୁଗାର୍ଡ ଚେକ୍ (ତଳ)",
  DocuGard_Three_Cheques_Window:"ଗୋଟିଏ ପୃଷ୍ଠାରେ ତିନୋଟି ଯାଞ୍ଚ |",
  DocuGard_Table_Top_Window:"ଚେକ୍ ଏବଂ ରୋଜଗାର ଟେବୁଲ୍।",
  DocuGard_Table_Middle_Window:"ଚେକ୍ ଏବଂ ରୋଜଗାର ଟେବୁଲ୍।",
  DocuGard_Table_Bottom_Window:"ଚେକ୍ ଏବଂ ରୋଜଗାର ଟେବୁଲ୍।",
  DocuGard_Mailer_Top_Window:"ଯାଞ୍ଚ, ଟେବୁଲ୍, ଏବଂ ଠିକଣା।",
  DocuGard_Mailer_Middle_Window:"ଯାଞ୍ଚ, ଟେବୁଲ୍, ଏବଂ ଠିକଣା।",
  DocuGard_Mailer_Bottom_Window:"ଯାଞ୍ଚ, ଟେବୁଲ୍, ଏବଂ ଠିକଣା।",
  DocuGard_Cheque_Top_Window:"ସୁରକ୍ଷିତ କାଗଜ ଯାଞ୍ଚ କରନ୍ତୁ।",
  DocuGard_Cheque_Middle_Window:"ସୁରକ୍ଷିତ କାଗଜ ଯାଞ୍ଚ କରନ୍ତୁ।",
  DocuGard_Cheque_Bottom_Window:"ସୁରକ୍ଷିତ କାଗଜ ଯାଞ୍ଚ କରନ୍ତୁ।",
  Basic_Cheque:"ଯାଞ୍ଚ (ଶୀର୍ଷ)",
  Basic_Cheque_Print:"ଗୋଟିଏ ଚେକ୍ ପ୍ରିଣ୍ଟ୍ କରନ୍ତୁ |",
  Error_Setting_As_Paid:"ଦେୟ ପରି ତ୍ରୁଟି ସେଟିଂ",
  Add_Attachment:"ସଂଲଗ୍ନକ ଯୋଡନ୍ତୁ",
  PrinterUnavailable:"ପ୍ରିଣ୍ଟର୍ ଉପଲବ୍ଧ ନାହିଁ",
  CreditCardComponent:"କାର୍ଡ",
  PaypalComponent:"ପେପାଲ୍",
  InteracComponent:"ଇଣ୍ଟରାକ",
  BitcoinComponent:"ବିଟକଏନ୍",
  New_Deposit:"ନୂତନ ଜମା",
  Deposits:"ଜମା",
  No_Deposits:"କ Dep ଣସି ଜମା ନାହିଁ",
  Credit_Card_Deposit:"କ୍ରେଡିଟ୍ କାର୍ଡ଼",
  New_Credit_Card_Deposit_Message:"କ୍ରେଡିଟ୍ କାର୍ଡ ଜମା",
  New_BitCoin_Deposit:"ବିଟକଏନ୍",
  New_BitCoin_Deposit_Message:"ବିଟକଏନ୍ ଜମା",
  New_Interac_Deposit:"ଇଣ୍ଟରାକ",
  New_Interac_Deposit_Message:"ଇଣ୍ଟରାକ ଟ୍ରାନ୍ସଫର",
  Payments_Limit:"ଦେୟ ସୀମା",
  No_Payment_Limit:"କ Pay ଣସି ଦେୟ ସୀମା ନାହିଁ",
  PayPal_Deposit:"ପେପାଲ୍",
  PayPal_Deposit_Message:"ପେପାଲ୍ ଜମା",
  No_Deposits_Word:"ପ୍ରଥମ କମ୍ପାନୀ <a routerLink='/folder/Deposit/New'> ଜମା </a> ଯୋଡନ୍ତୁ |",
  No_Documents_Specified:"ମୁଦ୍ରଣ ପାଇଁ କ documents ଣସି ଦଲିଲ ନିର୍ଦ୍ଦିଷ୍ଟ କରାଯାଇ ନାହିଁ",
  No_Printers_Added:"କ No ଣସି ପ୍ରିଣ୍ଟର୍ ମିଳିଲା ନାହିଁ। ଗୋଟିଏ ଯୋଡିବା ପାଇଁ ସେଟିଂସମୂହ> ପ୍ରିଣ୍ଟର୍ କୁ ଯାଆନ୍ତୁ |",
  DB_Erase_Prompt:"ସମଗ୍ର ଡାଟାବେସ୍ କୁ ସଂପୂର୍ଣ୍ଣଭାବେ ଲିଭାନ୍ତୁ? ଚେତାବନୀ: ଆପଣ ସମସ୍ତ ଗଚ୍ଛିତ ସୂଚନା ହରାଇବେ!",
  Console_Warning:"ଏହି କନସୋଲରେ କ text ଣସି ପାଠ୍ୟ ଲେପନ କରନ୍ତୁ ନାହିଁ। ଆପଣ ନିଜକୁ ଏବଂ / କିମ୍ବା ଆପଣଙ୍କ କମ୍ପାନୀକୁ ଗୁରୁତର ବିପଦରେ ପକାଇ ପାରନ୍ତି।",
  No_Faxes_Word:"ପ୍ରଥମ <a routerLink='/folder/Fax/New'> ଫ୍ୟାକ୍ସ </a> ସୃଷ୍ଟି କରନ୍ତୁ |",
  Cheque_Delete_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ଚେକ୍ ଡିଲିଟ୍ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Design_Delete_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ଡିଜାଇନ୍ ବିଲୋପ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Cheque_Pay_Confirmation:"ଏହି ଚେକ୍ କୁ ପେଡ୍ ଭାବରେ ଚିହ୍ନିତ କର? ଏହା ପ୍ରିଣ୍ଟ୍ କ୍ୟୁରେ ଦେଖାଯିବ ନାହିଁ |",
  Payment_Pay_Confirmation:"ଏହି ଦେୟକୁ ଦେୟ ହିସାବରେ ଚିହ୍ନିତ କର? ଏହା ଚେକ୍ କ୍ୟୁରେ ଦେଖାଯିବ ନାହିଁ।",
  Delete_Deduction_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ରିହାତି ବିଲୋପ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Delete_Job_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ଚାକିରି ବିଲୋପ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Delete_Timesheet_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ଟାଇମ୍ସିଟ୍ ଡିଲିଟ୍ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Delete_Schedule_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି କାର୍ଯ୍ୟସୂଚୀ ବିଲୋପ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Delete_Setting_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ସେଟିଂ ପୁନ res ସେଟ୍ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Delete_Fax_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ଫ୍ୟାକ୍ସ ବିଲୋପ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Delete_File_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ଫାଇଲ୍ ଡିଲିଟ୍ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Delete_Vacation_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ଛୁଟି ବିଲୋପ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Delete_Printer_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ପ୍ରିଣ୍ଟର୍ ଡିଲିଟ୍ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Remove_Design_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ଡିଜାଇନ୍ ବିଲୋପ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Delete_Payroll_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ବେତନ ବିଲୋପ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Send_Fax_Email_Confirmation:"ଆପଣ ଏହି ଡକ୍ୟୁମେଣ୍ଟଗୁଡ଼ିକୁ ଫ୍ୟାକ୍ସ ଏବଂ ଇମେଲ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Send_Email_Confirmation:"ଆପଣ ଏହି ଡକ୍ୟୁମେଣ୍ଟକୁ ଇମେଲ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Send_Fax_Confirmation:"ଆପଣ ଏହି ଡକ୍ୟୁମେଣ୍ଟକୁ ଫ୍ୟାକ୍ସ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Error_Generating_PDF:"ଦୁ Sorry ଖିତ। ଏହି ଡକ୍ୟୁମେଣ୍ଟ ସୃଷ୍ଟି କରିବାରେ ଏକ ତ୍ରୁଟି ଥିଲା।",
  PDF_Error_Saving_Image:"ଦୁ Sorry ଖିତ। ଏହି ଡକ୍ୟୁମେଣ୍ଟର PDF ପ୍ରତିଛବି ସଂରକ୍ଷଣ କରିବାରେ ଏକ ତ୍ରୁଟି ଥିଲା।",
  Test_Printer_Confirmation:"ଆପଣ ଏହି ପ୍ରିଣ୍ଟରରେ ଏକ ପରୀକ୍ଷା ପୃଷ୍ଠା ପ୍ରିଣ୍ଟ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Save_Timesheet_Prompt:"ଦୟାକରି ଏକ 'ଆଖ୍ୟା' ଯୋଡନ୍ତୁ କିମ୍ବା ସଞ୍ଚୟ କରିବାକୁ 'ଷ୍ଟାର୍ଟ ଟାଇମର୍' ଦବାନ୍ତୁ।",
  Remove_Geofence_Prompt:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ଜିଓ-ବାଡ଼ର ଅବସ୍ଥାନ ହଟାଇବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  Delete_Employee_Confirmation:"ଆପଣ ବିଲୋପ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି ନାହିଁ",
  Fire_Employee_Confirmation:"ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ନିଆଁ ଲଗାଇବାକୁ ଚାହୁଁଛନ୍ତି?"
}