export const Pl = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Prawa autorskie &copy; 2023",
  black:"Czarny",
  green:"Zielony",
  gold:"Złoto",
  blue:"Niebieski",
  brown:"Brązowy",
  purple:"Fioletowy",
  pink:"Różowy",
  red:"Czerwony",
  Swatches:"Próbki",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Odcień",
  Saturation:"Nasycenie",
  Lightness:"Lekkość",
  Upgrade_To_Pro:"Przejść na wersje Pro",
  AllFeaturesInclude:"Wszystkie funkcje obejmują:",
  PrintUnlimitedCheques:"Drukuj nieograniczone czeki",
  PremiumChequeDesigns:"Wzory w kratkę premium",
  ManageUnlimitedEmployees:"Zarządzaj nieograniczoną liczbą pracowników",
  AddUnlimitedPayees:"Dodaj nieograniczoną liczbę odbiorców",
  CreateUnlimitedPayrolls:"Twórz nieograniczone listy płac",
  UnlimitedSchedulesandTimeSheets:"Nieograniczone harmonogramy i karty czasu pracy",
  BulkPayPalPayouts:"Zbiorcze wypłaty PayPal",
  UnlimitedBankAccounts:"Nieograniczone konta bankowe",
  ManageMultipleCompanies:"Zarządzaj wieloma firmami",
  TrackInsurancePolicies:"Śledź polisy ubezpieczeniowe",
  Bio_MetricProtection:"Ochrona biometryczna",
  Geo_FenceLock_OutProtection:"Ochrona przed blokadą Geo-Fence",
  Multiple_Companies_Word:"Zarządzanie wieloma firmami nie jest możliwe bez premii za czeki.",
  PayPal_Payouts_Word:"Płatności PayPal są wyłączone bez premii czekami.",
  PINProtection:"Ochrona kodem PIN",
  PasswordProtection:"Ochrona hasła",
  May_Require_Approval:"Może wymagać zatwierdzenia.",
  Subscribe:"Subskrybuj",
  Billed:"Rozliczone",
  Up:"W górę",
  Down:"W dół",
  Positioning:"Pozycjonowanie",
  Marker:"Znacznik",
  Drag_Marker:"Przeciągnij znacznik",
  Tagline:"Wydrukuj czeki i zestaw listę płac",
  Marker_Word:"Użyj znaczników, aby określić rozmiar elementu.",
  Pinch_Zoom:"Powiększenie przez szczypanie",
  Pinch_Word:"Ściśnij, aby powiększyć element.",
  Drag:"Ciągnąć",
  Drag_Word:"Palcem przeciągnij elementy.",
  subscription_alias_word:"Automatycznie odnawiająca się subskrypcja",
  premium_alias_word:"Jednorazowy pakiet aktualizacyjny",
  print_alias_word:"Wydrukuj czeki indywidualne",
  mode_alias_word:"Tryb",
  Pro:"Zawodowiec",
  Pro_word:"Wymagana wersja pro.",
  Cant_Delete_Default_Company_Word:"Przepraszamy, nie możesz usunąć swojej domyślnej firmy.",
  Reinsert_Default_Designs:"Wstaw ponownie domyślne projekty",
  Reinsert_Default_Designs_word:"Czy chcesz ponownie wstawić projekty domyślne?",
  Subscription_Active_Disable_Word:"Ta subskrypcja jest aktywna. Czy chcesz anulować tę subskrypcję Czeków?",
  Company_Logo:"Logo firmy",
  ZERO_:"ZERO",
  Disclaimer:"Zastrzeżenie",
  Privacy_Policy:"Polityka prywatności",
  EULA:"Sprawdza umowę EULA",
  Terms_Of_Service:"Warunki usługi",
  Terms_Of_Use:"Warunki korzystania",
  Refunds:"Polityka zwrotów",
  Single_Print:"1 Sprawdź",
  Two_Prints:"2 czeki",
  Five_Prints:"5 czeków",
  Ten_Prints:"10 czeków",
  Fifteen_Prints:"15 Czeki",
  Twenty_Prints:"20 Czeki",
  Thirty_Prints:"30 czeków",
  Image_Added:"Dodano obraz",
  Image_Preview:"Podgląd obrazu",
  No_Image_Was_Selected:"Nie wybrano obrazu.",
  Cheques_Unlimited:"Czeki Nieograniczone",
  _Subscription:"Subskrypcja",
  Subscription:"Czeki - 1 miesiąc",
  Two_Month_Subscription:"Czeki - 2 miesiące",
  Three_Month_Subscription:"Czeki - 3 miesiące",
  Six_Month_Subscription:"Czeki - 6 miesięcy",
  Twelve_Month_Subscription:"Czeki - 12 miesięcy",
  Cheques_Are_Available:"Czeki są dostępne do wydrukowania.",
  Upgrade_Required_Two:"Wybierz pakiet i kliknij dwukrotnie przycisk ceny, aby rozpocząć zakup. Drukuj profesjonalnie wyglądające pełnokolorowe czeki w ciągu kilku sekund.",
  Month:"Miesiąc",
  Due:"Należny:",
  Expires:"Wygasa:",
  Subscription_Active:"Subskrypcja aktywna",
  Subscription_Inactive:"Subskrypcja nieaktywna",
  Purchase_Additional_Cheques:"Kupić dodatkowe czeki?",
  Printable_Cheque:"Czek do druku",
  Printable_Cheques:"Czeki do druku",
  Printable_Cheque_Word:"czek jest dostępny na Twoim koncie.",
  Printable_Cheques_Word:"czeki są dostępne na Twoim koncie.",
  Max_Amount_Message:"Podana kwota przekroczyła maksymalną kwotę ustawioną dla tego systemu:",
  Terms_Required_Word:"Przed dalszym korzystaniem z Czeków należy wyrazić zgodę na niniejszą umowę.",
  Subscriptions:"Abonamenty",
  Product_Upgrades:"Ulepszenia",
  Mailed_Out_Cheques:"Czeki wysłane pocztą",
  Enter_A_Company_Name:"Wprowadź nazwę firmy.",
  Single_Cheques:"Pojedyncze czeki",
  Cheque_Golden:"Złoty czek",
  Cheque_Golden_Window:"Projekt złotej kratki.",
  Cheque_Green:"Zielony czek",
  Cheque_Green_Window:"Projekt zielonej kratki.",
  Cheque_Red:"Czerwony czek",
  Cheque_Red_Window:"Wzór w czerwoną kratkę.",
  Cheque_Yellow:"Żółty czek",
  Cheque_Yellow_Window:"Projekt żółtej kratki.",
  Cheque_Statue_of_Liberty:"Statua Wolności",
  Cheque_Statue_of_Liberty_Window:"Wzór w kratę Statua Wolności.",
  Cheque_Green_Wave:"zielona fala",
  Cheque_Green_Wave_Window:"Projekt zielonej kratki.",
  Cheque_Golden_Weave:"złoty splot",
  Cheque_Golden_Weave_Window:"Elegancki wzór w złotą kratkę.",
  Cheque_Green_Sun:"zielone słońce",
  Cheque_Green_Sun_Window:"Głęboki i uspokajający wzór w kratę.",
  Cheque_Blue_Checkers:"niebieskie warcaby",
  Cheque_Blue_Checkers_Window:"Niebieski wzór w kratkę.",
  Cashiers_Check:"Czek kasjerski",
  Cashiers_Check_Window:"Szablon stylu czeku kasjera.",
  Cheque_Aqua_Checkers:"Warcaby wodne",
  Cheque_Aqua_Checkers_Window:"Wzór w kratkę Aqua.",
  Cheque_Golden_Checkers:"Złote Warcaby",
  Cheque_Golden_Checkers_Window:"Projekt złotej kratki.",
  Upgrade_Unavailable:"Aktualizacje niedostępne",
  Bank_Code_Protection:"Ochrona numeru banku",
  Bank_Code_Protection_Word:"Chroń swoje numery bankowe przed użyciem w tej aplikacji działającej na innym urządzeniu lub dla innego użytkownika. Ta czynność jest NIEODWRACALNA. Kontynuować?",
  Bank_Code_Protection_Blocked_Word:"Numery banków, których próbujesz użyć, są zarezerwowane dla innego użytkownika lub urządzenia.",
  Bank_Code_Protection_Error_Word:"Weryfikacja numeru nie powiodła się. Połącz się z internetem i spróbuj ponownie dodać to konto bankowe.",
  Bank_Code_Protection_Set_Error_Word:"Ochrona numeru bankowego wymaga połączenia z Internetem. Połącz się i spróbuj ponownie.",
  Upgrade_Unavailable_Word:"Przepraszamy, mamy problem z weryfikacją. Subskrypcje i uaktualnienia czeków są obecnie niedostępne w Twojej okolicy.",
  PayPal_Payment_Preview:"Podgląd płatności PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Wybierz PayPala",
  PayPal_Applications:"Aplikacje PayPala",
  Purchase_With_Apple_Pay:"Kup za pomocą Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Firmy",
  Insurance:"Ubezpieczenie",
  New_PayPal:"Nowy PayPal",
  Pay_By:"Płacić przez",
  Insure:"Ubezpieczać",
  Miles:"mile",
  Payment_Method:"Metoda płatności",
  Select_Policies:"Wybierz Zasady",
  Policies:"Zasady",
  Policy:"Polityka",
  No_PayPal_Account:"Brak konta PayPal",
  No_Policies:"Brak polis ubezpieczeniowych",
  New_Policy:"Nowa polityka",
  PayPal_Payment:"Płatność PayPal",
  PayPal_Payments:"Płatności PayPal",
  No_Payment_Selected:"Nie wybrano płatności",
  Sending_Payment_Word:"Proszę czekać... Ta płatność jest wysyłana.",
  Sending_Payments_Word:"Proszę czekać... Płatności są wysyłane.",
  No_Payment_Selected_PayPal:"Nie wybrano <a routerLink='/folder/Payments'> Płatności PayPal</a> do wysłania.",
  Payment_Sent:"Płatność wysłana",
  PayPal_Payment_Sent:"Ta płatność została wysłana przez PayPal.",
  Copay:"współpłacić",
  Dead:"Martwy",
  Alive:"Żywy",
  Not_Dead:"Nieumarły",
  Unclaimed:"Nieodebrane",
  Attempted:"Próba",
  Deceased:"Zmarły",
  Claimed:"Przejęte",
  Unpaid:"Nie zapłacony",
  Sending_Payment:"Wysyłanie płatności",
  Sending_Payments:"Wysyłanie płatności",
  Send_PayPal_Confirmation:"Czy chcesz wysłać tę transakcję przez PayPal?",
  Send_PayPal_Confirmation_Word:"Naciśnij zielony przycisk, aby wysłać tę transakcję.",
  Save_Payment_As_Unpaid:"Zapisać tę płatność jako niezapłaconą?",
  Payment_Pay_Confirmation_PayPal:"Zapisać tę płatność jako zapłaconą?",
  No_Policies_Word:"Dodaj teraz pierwszą <a routerLink='/folder/Postage/New'> Polisę Ubezpieczeniową</a>.",
  Timesheet_Multiple_Delete_Confirmation:"Czy na pewno chcesz usunąć wiele grafików?",
  Select_Multiple_Timesheets_Prompt:"Nie wybrano grafików. Wybierz co najmniej jeden grafik.",
  Select_Multiple_Policies_Prompt:"Nie wybrano zasad. Wybierz co najmniej jedną polisę ubezpieczeniową.",
  Policies_Multiple_Delete_Confirmation:"Czy na pewno chcesz usunąć wiele zasad?",
  Company:"Firma",
  Add_Company:"Dodaj firmę",
  New_Company:"Dodaj firmę",
  No_Companies:"Brak firm",
  Enable_Company:"Włącz firmę",
  Select_Company:"Wybierz firmę",
  The_Default_Company:"Domyślna etykieta firmy.",
  Manage_Companies:"Zarządzaj firmami",
  No_Companies_Word:"Czeki będą używać domyślnej firmy.<br />Dodaj <a routerLink='/folder/Company/New'>Pierwszą Firmę</a>.",
  Default_Company:"Firma domyślna",
  Cheques_Unlimited_Word:"Czeki Unlimited umożliwiają wydrukowanie dowolnej liczby czeków.",
  Cheques_Subscription_Word:"Subskrypcja Czeki umożliwia wydrukowanie dowolnej liczby czeków.",
  You_Own_This_Product:"Jesteś właścicielem tego produktu.",
  Your_Subscription_is_Active:"Twoja subskrypcja jest aktywna.",
  Active_Products:"Produkty aktywowane",
  Purchase_Confirmation:"Zakup",
  Purchase_Cheques:"Kupuj czeki",
  Restore_Purchase:"Przywróć zakupy",
  Erase_Purchase:"Usuń zakupy",
  Successfully_Purchased:"Zakupiono pomyślnie",
  Enter_Your_Licence_Key:"Wprowadź swój klucz licencyjny na tej stronie, aby aktywować ten produkt.",
  Licence_Key:"Klucz licencyjny",
  Enter_Licence_Key:"Wprowadź klucz licencyjny",
  Purchased:"Zakupione",
  Enable_Feature:"Włącz funkcję",
  Cancel_Subscription:"Anuluj subskrypcje",
  Subscription_Removed:"Usunięto subskrypcję",
  Select_Active_Subscription:"Wybierz aktywną subskrypcję, aby ją zmodyfikować.",
  Remove_Subscription_Word:"Czy na pewno chcesz anulować tę subskrypcję?",
  Delete_Company_Confirmation:"Czy na pewno chcesz usunąć całą firmę? OSTRZEŻENIE: Utracisz wszystkie zapisane informacje!",
  Delete_Default_Company_Word:"Nie możesz usunąć domyślnej firmy. Czy chcesz zresetować aplikację i przywrócić ją do stanu domyślnego? OSTRZEŻENIE: Utracisz wszystkie zapisane informacje!",
  Console_Warning_2:"Nie obsługuj żadnej waluty za pomocą tej aplikacji, która nie jest obecnie Twoja.",
  Terms_and_Conditions:"Regulamin",
  and_the:"i",
  for:"Do",
  Please_Read_Word:"Proszę przeczytać i wyrazić zgodę na",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Nie można znaleźć niektórych kursów wymiany walut. Połącz się z internetem.",
  Free:"Bezpłatny",
  DB_Erase_Prompt_2:"Całkowicie wymazać całą bazę programistów? OSTRZEŻENIE: Utracisz wszystkie informacje dotyczące ZAKUPÓW i SUBSKRYPCJI!",
  Successfully_Imported:"Pomyślnie zaimportowano",
  Select_Postage:"Wybierz Opłata pocztowa",
  No_Postage:"Brak znaczków pocztowych",
  No_Paid_Postage_Word:"Dodaj pierwszy znaczek <a routerLink='/folder/Postage/New'>Opłata Pocztowa</a>.",
  Trial_Complete:'Wersja próbna zakończona',
  Please_Upgrade:'Zaktualizuj czeki, aby kontynuować drukowanie.',
  Aa:"Aa",
  Color:"Kolor",
  Font:"Czcionka",
  Guide:"Przewodnik",
  Guides:"Przewodniki",
  Image:"Obraz",
  Zoom:"Powiększenie",
  Logo:"Logo",
  Bank:"Bank",
  MICR:"MICR",
  Total:"Całkowity",
  Cancel:"Anulować",
  Confirm:"Potwierdzać",
  Method:"metoda",
  Biometric_Security:"Bezpieczeństwo biometryczne",
  Please_Login_To_Continue:"Proszę się zalogować, aby kontynuować.",
  Complete:"Kompletny",
  Sign_Up:"Zapisać się",
  Error:"Błąd",
  Biometrics:"Biometria",
  Percent:"Procent",
  Zero_Percent:"0%",
  Top_Margin:"Górny margines",
  Bottom_Margin:"Margines dolny",
  Left_Margin:"Lewy margines",
  Right_Margin:"Prawy margines",
  MICR_Margin:"Margines MICR",
  Table_Margin:"Margines tabeli",
  Address_Margin:"Margines adresu",
  Percentage_:"Odsetek",
  Vacation_Title:"Tytuł urlopu",
  Use_PIN:"Użyj kodu PIN",
  Loading__:"Ładowanie...",
  Design_Title:"Tytuł projektu",
  Authorize:"Autoryzować",
  Key:"Klucz",
  Public_Key:"Klucz publiczny",
  Private_Key:"Prywatny klucz",
  Authenticate:"Uwierzytelniać",
  Last_Payroll:"Ostatnia lista płac",
  Last_Calculation:"Ostatnie obliczenia",
  Authorized:"Upoważniony",
  Geo_Authorized:"Geolokalizacja: Autoryzowana",
  Not_Authorized:"Nieautoryzowany",
  Authorization_Complete:"Autoryzacja zakończona",
  Geolocation_Authorization:"Zezwolenie na geolokalizację",
  Out_of_Bounds:"Poza granicami",
  Cant_Delete_Default_Design:"Nie można usunąć domyślnego projektu.",
  Unauthorized_Out_of_Bounds:"Nieautoryzowane: poza granicami",
  Biometric_Authorization:"Autoryzacja biometryczna",
  Locating_Please_Wait:"Trwa lokalizowanie, proszę czekać...",
  Test_Biometrics:"Testuj biometrię",
  Cheque_Margins:"Sprawdź Marże",
  Percentage_Full_Error:"Pole procentu nie może być ustawione na więcej niż 100% procent.",
  No_Payroll_Text:"Dodaj <a routerLink='/folder/Employee/New'>Pracownika</a> lub <a routerLink='/folder/Payee/New'>Odbiorca Płatności</a> i <a routerLink='/folder/Schedule/New'>Harmonogram</a>.",
  Design_Saved:"Zapisano projekt",
  Default_Design_Selected:"Wybrano domyślny projekt",
  Partial_Import:"Import częściowy",
  Partial_Export:"Częściowy eksport",
  Entire_Import:"Cały import",
  Entire_Export:"Cały eksport",
  Existing_Password:"Wprowadź swoje dotychczasowe hasło:",
  Existing_PIN:"Wprowadź istniejący kod PIN:",
  Pin_Change_Header:"Potwierdzenie PIN",
  Pin_Change_SubHeader:"Wprowadź stary numer PIN, aby potwierdzić zmianę.",
  Pass_Change_Header:"Potwierdzenie hasła",
  Pass_Change_SubHeader:"Wprowadź swoje stare hasło, aby potwierdzić zmianę.",
  PIN_Enter_Message:"Wprowadź kod PIN, aby potwierdzić.",
  Password_Enter_Message:"Wprowadź swoje hasło, aby potwierdzić.",
  Pin_Updated_Success:"Pomyślnie zaktualizowano PIN!",
  Pin_Updated_Fail:"Nie udało się zaktualizować kodu PIN.",
  Pass_Updated_Success:"Hasło zostało zmienione.",
  Pass_Updated_Fail:"Nie udało się zaktualizować hasła.",
  Preview_Payment:"Podgląd płatności",
  Preview_Payroll:"Podgląd listy płac",
  No_Payments_Created:"Nie znaleziono żadnych płatności.",
  Payment_Preview:"Podgląd płatności",
  Enable_Payee:"Włącz odbiorcę",
  Rendered:"Renderowane",
  No_Email:"Brak e-maila",
  Setup_Cheques:"Kontrole konfiguracji",
  name:"Nazwa",
  address:"Adres zamieszkania",
  address_2:"Wiersz adresu 2",
  city:"Miasto",
  province:"Województwo",
  postal_code:"Kod pocztowy/Pocztowy",
  country:"Kraj",
  username:"Nazwa użytkownika",
  full_name:"Pełne imię i nazwisko",
  birthday:"Urodziny",
  email:"E-mail",
  phone:"Telefon",
  employees:"Pracownicy",
  addresses:"Adresy",
  payment_amount_limit:"Limit kwoty płatności",
  total_limit:"Całkowity limit",
  payees:"Odbiorcy",
  description:"Opis",
  address_line_one:"Pierwsza linia adresu",
  address_line_two:"Drugi wiersz adresu",
  image:"Obraz",
  account_holder:"Właściciel konta",
  cheque_starting_id:"Sprawdź identyfikator początkowy",
  transit_number:"Numer tranzytowy",
  institution_number:"Numer instytucji",
  designation_number:"Numer oznaczenia",
  account_number:"Numer konta",
  currency:"Waluta",
  signature:"Podpis",
  payment_payroll_limit:"Limit płatności",
  total_limi:"Całkowity limit",
  date:"Data",
  printed_memo:"Drukowane notatki",
  banks:"Banki",
  signature_image:"Obraz podpisu",
  address_name:"Adres",
  notes:"Uwagi",
  design:"Projekt",
  title:"Tytuł",
  frequency:"Częstotliwość",
  fax:"Faks",
  salaries:"Wynagrodzenia",
  salary_ids:"Identyfikatory wynagrodzenia",
  start_time:"Czas rozpoczęcia",
  end_time:"Koniec czasu",
  paid:"Płatny",
  overtime_percentage:"Zapłacone odsetki",
  overtime_amount:"Zapłacona stała kwota",
  first_name:"Imię",
  last_name:"Nazwisko",
  moderation:"Umiar",
  create:"Tworzyć",
  edit:"Edytować",
  destroy:"Zniszczyć",
  day_start_time:"Day_start_time",
  day_end_time:"Day_end_time",
  fullname:"Nazwa",
  time:"Czas",
  auto_send:"Wyślij automatycznie",
  time_method:"Metoda czasu",
  schedules:"Harmonogramy",
  indefinite_payroll_enabled:"Włącz na czas nieokreślony",
  amount:"Kwota",
  repeat:"Powtarzać",
  always_enabled:"Zawsze włączone",
  start_date:"Data rozpoczęcia",
  end_date:"Data zakonczenia",
  Cheque_Total:"Sprawdź sumę",
  Total_Amount:"Całkowita kwota:",
  Amounts:"Kwoty:",
  Images:"Obrazy",
  Files:"Akta",
  Previewing:"Podgląd:",
  Insert:"Wstawić",
  Preview_Import:"Podgląd importu",
  Entry:"Wejście",
  Entries:"Wpisy",
  No_Entries:"Brak wpisów",
  Import_Type:"Typ importu",
  Select_Details:"Wybierz szczegóły",
  Select_Payee:"Wybierz odbiorcę",
  Enable_Holidays:"Włącz święta",
  Disable_Holidays:"Wyłącz święta",
  Wire_Transfer:"Przelew",
  New_Export:"Nowy eksport",
  Export_Data:"Eksportuj dane",
  Export_Data_Word:"Wybierz typ pliku do wyeksportowania i pobrania.",
  Export_File:"Eksportuj plik",
  Mode:"Tryb",
  Times:"Czasy",
  Widgets:"Widżety",
  Slider:"Suwak",
  Set_Details:"Ustaw szczegóły",
  Select_Type:"Wybierz rodzaj",
  Display_Slider:"Suwak wyświetlacza",
  Dashboard_Slider:"Suwak deski rozdzielczej",
  Dashboard_Mode:"Tryb deski rozdzielczej",
  Show_Intro:"Pokaż wprowadzenie",
  Show_Payrolls:"Pokaż listy płac",
  Show_Holidays:"Pokaż święta",
  Show_Invoices:"Pokaż faktury",
  Show_Cheques:"Pokaż czeki",
  Enabled_Widgets:"Włączone widżety",
  Disabled_Widgets:"Wyłączone widżety",
  Colors:"Zabarwienie",
  Barcodes:"Kody kreskowe",
  View_Timers:"Zobacz liczniki",
  Gradients:"Gradienty",
  No_Info:"Brak informacji",
  Disable:"Wyłączyć",
  Show_Layer:"Pokaż warstwy",
  Hide_Layer:"Ukryj warstwy",
  Text_Layer:"Warstwy tekstowe",
  Secondly:"Po drugie",
  Minutely:"Drobiazgowo",
  nine_am:"09:00",
  five_pm:"17:00",
  Enable_Address:"Włącz adres",
  Invalid_File_Type:"Przepraszamy, wybrano nieprawidłowy typ pliku. Obsługiwany typ pliku:",
  Error_Updating_Entry:"Przepraszamy, wystąpił błąd podczas aktualizowania tego wpisu.",
  Schedule_Timing_Alert:"Błąd: godzina rozpoczęcia harmonogramu jest ustawiona na wartość po czasie zakończenia.",
  Select_Multiple_Payments_Prompt:"Nie wybrano płatności. Wybierz co najmniej jedną płatność.",
  Select_Multiple_Cheques_Prompt:"Nie wybrano kontroli. Wybierz co najmniej jeden czek.",
  Select_Multiple_Items_Prompt:"Nie wybrano żadnych elementów. Wybierz co najmniej jeden przedmiot.",
  Paymemt_Multiple_Delete_Confirmation:"Czy na pewno chcesz usunąć wiele płatności?",
  Cheque_Multiple_Delete_Confirmation:"Czy na pewno chcesz usunąć wiele sprawdzeń?",
  Payee_Multiple_Delete_Confirmation:"Czy na pewno chcesz usunąć wielu odbiorców?",
  Employee_Multiple_Delete_Confirmation:"Czy na pewno chcesz usunąć wielu pracowników?",
  MICR_Warning:"Uwaga: Niektóre drukarki i urządzenia mogą nie wyświetlać poprawnie czcionki MICR.",
  Automatically_Send:"Wyślij automatycznie",
  Type:"Rodzaj",
  Payment_Type:"Typ płatności",
  Auto_Send:"Automatyczne wysyłanie",
  Automatically_Process:"Przetwarzaj automatycznie",
  Auto_Process:"Proces automatyczny",
  Image_Based:"Oparte na obrazach",
  Font_Based:"Oparte na czcionce",
  Rerender:"Ponownie renderuj",
  Rendering:"Wykonanie",
  Render:"Akta",
  Top:"Top",
  Middle:"Środek",
  Bottom:"Na dole",
  Top_Left:"U góry z lewej",
  Top_Center:"Górny środek",
  Top_Right:"W prawym górnym rogu",
  Middle_Left:"Środkowy lewy",
  Middle_Center:"Środkowy środek",
  Middle_Right:"Środkowy prawy",
  Bottom_Left:"Na dole po lewej",
  Bottom_Center:"Dolny środek",
  Bottom_Right:"Prawy dolny",
  Numbers:"Liczby",
  Verified:"Zweryfikowany",
  Paper_Size:"Rozmiar papieru",
  New_Device:"Nowe urządzenie",
  Add_Device:"Dodaj urządzenie",
  Remove_Device:"Odłącz urządzenie",
  Delete_Device:"Usuń urządzenie",
  Block_Device:"Zablokuj urządzenie",
  Block:"Blok",
  Unblock:"Odblokować",
  Table:"Stół",
  Scan_Login_Code:"Zeskanuj kod logowania",
  Login_Code:"Kod logowania",
  Scan_Code:"Kod skanowania",
  Scan_QR_Code:"Skanowania QR code",
  Select_All:"Zaznacz wszystko",
  Deselect_All:"Odznacz wszystkie",
  Increase:"Zwiększać",
  Decrease:"Zmniejszać",
  Bold:"Pogrubienie",
  Text:"Tekst",
  Style:"Styl",
  Italic:"italski",
  QR_Code:"Kod QR",
  Barcode:"kod kreskowy",
  Browse_Images:"Przeglądaj obrazy",
  Browse_Files:"Przeglądaj pliki",
  Background_Image:"Zdjęcie w tle",
  Logo_Image:"Obraz logo",
  Header_Image:"Obraz nagłówka",
  Bank_Image:"Obraz banku",
  Cut_Lines:"Linie cięcia",
  Background:"Tło",
  License:"Licencja",
  One_License:"1 Licencja:",
  Licensed:"Byc licencjonowanym:",
  Not_Licensed:"Brak licencji",
  Enter_Serial:"Wprowadź numer seryjny",
  Serial_Key:"Numer seryjny",
  Serial:"Seryjny",
  Product_Key:"Klucz produktu",
  Check_Product_Key:"Sprawdź klucz produktu",
  Add_Product_Key:"Dodaj klucz produktu",
  Verifying_Purchase:"Weryfikowanie zakupu...",
  Print_Envelope:"Koperta do druku",
  Envelope:"Koperta",
  Thank_You:"Dziękuję!",
  Scale:"Skala",
  Print_Scale:"Skala wydruku",
  Borders:"Granice",
  VOID:"PRÓŻNIA",
  Void_Cheque:"Unieważnij Sprawdź",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ZAPŁAĆ NA ZAMÓWIENIE:",
  NO_DOLLARS:"BEZ DOLARÓW ",
  ONE_DOLLAR:"JEDEN DOLAR",
  DOLLARS:" DOLARÓW",
  AND:" ORAZ ",
  CENTS:" CENTÓW.",
  NO_:"NIE ",
  ONE_:"JEDEN ",
  AND_NO_:"I NIE ",
  _AND_ONE_:"I JEDEN ",
  DOLLARS_AND_ONE_CENT:" I JEDEN CENT.",
  AND_NO_CENTS:" I ZERO CENTÓW.",
  CHEQUE_PRINT_DATE:"DATA:",
  CHEQUE_PRINT_MEMO:"NOTATKA:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"POSEŁ",
  Initial_Tasks:"Zadania wstępne",
  Inital_Setup:"Konfiguracja początkowa",
  Welcome_To:"Witamy w ",
  Welcome:"Witamy",
  Swipe:"Trzepnąć",
  Intro_Setup:"Konfiguracja wstępna",
  Introduction:"Wstęp",
  CHEQUE_PRINT_CREDIT:"Zasilany Czekami",
  Title_Intro_Word:"Witamy w Czekach",
  Title_Intro:"Wprowadzenie do czeków",
  Title_Setup:"Konfiguracja czeków",
  PayPal_Default_Email_Message:"Otrzymałeś nowy przelew PayPal. [Powered by Cheques]",
  Cheques_App_Export:"Wywożone czekiem",
  Post_Intro_Thanks:"Dziękujemy za wybranie czeków. Proces konfiguracji został zakończony.",
  Post_Intro_Word:"Zacznij od wydrukowania pierwszego czeku, dodania przyszłej płatności lub dodania pracowników do listy płac.",
  Upgrade_Required:"Czeki wymagają posiadania bardziej premium wersji oprogramowania, aby ukryć tę wiadomość i odblokować dodatkowe funkcje.",
  Upgrade_Title:"Czeki",
  Mailout_Prompt:"Możesz dodatkowo zdecydować się na wysłanie czeków pocztą dla Ciebie.",
  Mailed_Cheque:"Czek wysyłany pocztą:",
  Mailed_Cheque_Color:"Wysłany czek (kolor): ",
  Terms_Purchase:"Wszystkie zakupy elektroniczne za pomocą czeków podlegają pełnemu zwrotowi przez okres do 30 dni od daty zakupu. Przed kontynuowaniem przeczytaj i zaakceptuj <a href='#'>Warunki</a>.",
  Dashboard_Setup:"Konfiguracja Drukarki Głównej",
  Dashboard_Add:"Dodaj główne konto bankowe",
  Dashboard_Customize:"Wybierz szablon czeku",
  Dashboard_Job_Salary:"Utwórz swoją pracę i dodaj pensję",
  Dashboard_Employees:"Śledź Pracowników i Płatników",
  Dashboard_Print:"Wydrukuj i wyślij czeki",
  Dashboard_Payroll:"Automatyzacja drukowania list płac",
  Dashboard_PayPal:"Konfiguruj PayPal Payroll / Wypłaty",
  Begin_Setup:"Rozpocznij konfigurację",
  Get_Started:"Zaczynaj",
  Purchase:"Zakup",
  Lockdown:"Izolacja",
  Unlock:"Odblokować",
  Detailed:"Szczegółowe",
  Log_In:"Zaloguj sie",
  Login:"Zaloguj sie",
  Launch:"Uruchomić",
  Register:"Zarejestrować",
  Finish:"Skończyć",
  List:"Lista",
  Weekends:"Weekendy",
  Weekly:"Tygodniowo",
  PayPal_Default_Subject:"Nowa płatność",
  Payment_Message:"Wiadomość Płatności",
  PayPal_Default_Payment_Note:"Dziękuję",
  Setup_Your_Cheqing_Account:"Konto czekowe",
  Add_Your_Primary_Cheqing_Account:"Dodaj swoje główne konto czekowe.",
  Welcome_Word:"Uprość i zautomatyzuj zarządzanie płacami i zasobami ludzkimi.",
  Get_Started_Quickly:"Wystarczy odpowiedzieć na kilka prostych pytań, które pomogą nam zacząć...",
  Done_Intro_Word:"Konfiguracja zakończona",
  PIN_Protected:"Chronione hasłem i kodem PIN",
  Enter_New_PIN:"Wprowadź nowy Kod PIN:",
  Enter_PIN:"Wprowadź Kod PIN:",
  Invalid_PIN:"Wprowadzono nieprawidłowy PIN.",
  Account_Identifier:"Identyfikator Konta",
  New_Account_Identifier:"Nowy Identyfikator Konta",
  attempt:"próba",
  attempts:"próbowanie",
  remaining:"pozostały",
  Language:"Język",
  languages:"Języki",
  select_languages:"Wybierz język",
  Deposit:"Depozyt",
  Hire_One_Now:"Zatrudnij teraz",
  App_Locked:"Aplikacja jest zablokowana.",
  Skip_:"Pomijać",
  Skip_to_Dashboard:"Przejdź do pulpitu nawigacyjnego",
  Dashboard:"Panel",
  Import:"Import",
  Admin:"Administratorzy",
  New_Admin:"Nowy Administrator",
  Settings:"Ustawienia",
  Color_Picker:"Narzędzie do wybierania kolorów",
  Font_Picker:"Selektor Czcionek",
  Logout:"Wyloguj",
  Close:"Blisko",
  Close_menu:"Blisko",
  Excel:"Plik Excel",
  Csv:"Plik CSV",
  Sql:"Plik SQL",
  Json:"Plik JSON",
  Url:"Importuj według adresu URL",
  Back:"Plecy",
  Timers:"Timery",
  Cheque:"Sprawdzać",
  Print:"Wydrukować",
  Designs:"Projekty",
  Pause_Printing:"Wstrzymaj drukowanie",
  Resume_Printing:"Wznów drukowanie",
  Printing_Paused:"Drukowanie wstrzymane",
  PrintingUnavailable:"Przepraszamy! Drukowanie jest niedostępne w tym systemie.",
  Prints_Paused:"Wydruki wstrzymane",
  Administration:"Administracja",
  Loading:"Ładowanie",
  Unnamed:"Anonimowy",
  error:"Przepraszamy, nie można otworzyć tego czeku do przeglądania.",
  No_Cheques_To_Print:"Brak czeków do wydrukowania",
  No_Cheques_To_Print_Word:"Utwórz <a routerLink='/folder/Cheque/New'>Nowy Czek</a>.",
  New_Cheque:"Nowy Czek",
  Start_One_Now:"Rozpocznij teraz",
  Edit_Cheque:"Edytuj czek",
  Select_Cheques:"Wybierz kontrole",
  Select_Employee:"Wybierz Pracownika",
  Default_Printer:"Domyślna drukarka",
  Reassign:"Ponowne przypisanie",
  Configure:"Konfiguruj",
  Template:"Szablon",
  Designer:"Projektant",
  Edit_Designs:"Edytuj Wzory",
  New_Design:"Nowy design",
  Next:"Następny",
  Save:"Zapisać",
  Name:"Nazwa",
  Mail:"Poczta",
  Amount:"Kwota",
  Date:"Data",
  PayPal:"PayPal",
  Payouts:"Wypłaty",
  PayPal_Label:"Etykieta PayPal",
  Email_Username:"E-mail/Nazwa Użytkownika",
  Client_ID:"Identyfikator klienta",
  Sandbox_Email:"E-mail piaskownicy",
  PayPal_Email:"Paypal email",
  PayPal_Username:"Nazwa Użytkownika API",
  PayPal_Payouts:"Wypłaty PayPal",
  Select_PayPal_Key:"Wybierz klucz PayPal",
  Secret:"Sekret",
  API_Secret:"Sekret API",
  PayPal_API_Keys:"Klucze PayPal",
  New_PayPal_Key:"Nowy klucz PayPal",
  Email_Subject:"Temat emaila",
  Email_Message:"Wiadomość e-mail",
  Payout_Options:"Opcje wypłaty",
  Payment_Note:"Dokument Płatniczy",
  Enable_Employee:"Włącz Pracownika",
  Enable_Production_Mode:"Włącz tryb produkcji",
  Sandbox_Username:"Nazwa użytkownika piaskownicy",
  Sandbox_Signature:"Podpis piaskownicy",
  Sandbox_Password:"Hasło piaskownicy",
  Production_Username:"Produkcyjna nazwa użytkownika",
  Production_Signature:"Podpis Produkcji",
  Production_Password:"Hasło produkcyjne",
  Production_Email:"E-mail produkcyjny",
  API_Client_ID:"Identyfikator Klienta API",
  API_Signature:"Podpis API",
  API_Password:"Hasło API",
  API_Username:"Nazwa Użytkownika API",
  Secret_Key:"Sekretny klucz",
  Sandbox:"Piaskownica",
  Production:"Produkcja",
  Sandbox_Keys:"Klucze do piaskownicy",
  Production_Keys:"Klucze produkcyjne",
  API_Title:"Tytuł API",
  Production_Mode:"Tryb produkcji",
  Account_Label:"Etykieta Konta",
  No_PayPal_Setup:"Brak klucza PayPal",
  Enable_PayPal_Account:"Włącz Konto PayPal",
  No_PayPal_Word:"Dodaj swój <a routerLink='/folder/Invoice/New'>Klucz API PayPal</a>.",
  Printed_Memo:"Notatka drukowana",
  Employee:"Pracownik",
  View_Employee:"Wyświetl pracownika",
  Mailing_Address:"Adres pocztowy",
  Company_Address:"Adres spółki",
  Select_Company_Address:"Wybierz Adres Firmy",
  Address:"Adres",
  Any_Day:"Każdego dnia",
  Address_Name:"Adres",
  Unit:"Jednostka",
  Account:"Rachunek",
  Bank_Account:"Konto bankowe",
  Account_Limits:"Włącz Limity Konta",
  Payroll:"Lista płac",
  New_Payroll:"Nowa Lista Płac",
  No_Payroll:"Brak nadchodzącej listy płac",
  Upcoming_Holiday:"Nadchodzące wakacje:",
  Invoice_Due:"Należna faktura:",
  New_Invoice:"Nowa Faktura",
  No_Invoices:"Brak faktur",
  No_Invoices_Word:"Utwórz pierwszą <a routerLink='/folder/Invoice/New'>Fakturę</a>.",
  Cheque_Due:"Czek:",
  Payrolls:"Listy płac",
  Sandbox_Mode:"Tryb piaskownicy",
  Hire:"Zatrudnić",
  Pay:"Płacić",
  New:"Nowy",
  Add:"Dodać",
  Make:"Robić",
  Time:"Czas",
  Write:"Pisać",
  Holiday:"Święto",
  Holidays:"Wakacje",
  Next_Holiday:"Następne wakacje:",
  All_Done:"Wszystko gotowe!",
  Employees:"Pracowników",
  Payees:"Odbiorcy",
  Job:"Stanowisko",
  Jobs:"Oferty pracy",
  Invoice:"Faktura",
  Invoices:"Faktury",
  Vacations:"Wakacje",
  Cheques:"Czeki",
  Brand_Cheques:"Marka",
  Payment:"Zapłata",
  Enable_Payment:"Włącz płatność",
  Payments:"Płatności",
  Schedule:"Harmonogram",
  Schedules:"Harmonogramy",
  Timesheet:"Arkusz czasu pracy",
  Timesheets:"Arkusze czasu pracy",
  Salary:"Wynagrodzenie",
  New_Address:"Nowy adres",
  Address_2:"Adres (wiersz 2)",
  _City:"Miasto",
  _State:"Stan/Prowincja",
  City:"Miasto / Gmina",
  State:"Stan/Prowincja",
  Postal:"Kod Pocztowy/Kod Pocztowy",
  ZIP:"Pocztowy/ZIP",
  Country:"Kraj",
  Addresses:"Adresy",
  Required_Options:"Wymagane opcje",
  Optional_Options:"Opcje Opcjonalne",
  Additional_Options:"Dodatkowe opcje",
  Required:"Wymagany",
  Optional:"Opcjonalny",
  Additional:"Dodatkowy",
  No_Addresses:"Brak adresów",
  New_Address_Word:"Dodaj pierwszy <a routerLink='/folder/Address/New'>Adres</a>.",
  Select_Address:"Wybierz Adres",
  No_Address:"Brak adresów",
  Print_Cheque:"Kontrola wydruku",
  Print_Cheque_Now:"Drukuj Sprawdź Teraz",
  Description:"Opis",
  Pay_To_The_Order_Of:"Płać na Zamówienie:",
  Select_Date_Range:"Wybierz zakres dat",
  Select_Starting_Date:"Wybierz datę rozpoczęcia",
  Select_Ending_Date:"Wybierz Datę Końcową",
  Select_Date:"Wybierz datę",
  Cheque_Date:"Sprawdź datę",
  Cheque_Memo:"Sprawdź Notatkę",
  Blank_Cheque:"Czek in blanco",
  Blank:"Pusty",
  No_Cheques:"Bez kontroli",
  No_Cheques_Word:"Wydrukuj swój pierwszy <a routerLink='/folder/Cheque/New'>Czek</a>.",
  Cheque_Amount_Placeholder:"0.00 zł",
  View_Image:"Zobacz obrazek",
  View:"Pogląd",
  Modify:"Modyfikować",
  Delete:"Kasować",
  Cheque_Paid:"Płatny",
  New_Deduction:"Nowe Odliczenie",
  Title:"Tytuł",
  Frequency:"Częstotliwość",
  Hourly:"Cogodzinny",
  Daily:"Codziennie",
  Weekdays:"Dni powszednie",
  BiWeekly:"2 tygodnie",
  TriWeekly:"3 tygodnie",
  Monthly:"Miesięczny",
  MiMonthly:"2 miesiące",
  Quarterly:"Kwartalny",
  Yearly:"Rocznie",
  Every_Week:"Co tydzień",
  Every_Payroll:"Każda lista płac",
  Every_Month:"Każdego miesiąca",
  Annually:"Rocznie",
  Always_Scheduled:"Zawsze Planowane",
  Start_Date:"Data rozpoczęcia",
  End_Date:"Data zakonczenia",
  Start_Time:"Czas rozpoczęcia",
  End_Time:"Koniec czasu",
  Deduction_Label:"Etykieta odliczenia",
  Notes:"Notatki",
  Options:"Opcje",
  Enable:"Włączyć",
  Select_Deductions:"Wybierz potrącenia",
  Deductions:"Potrącenia",
  No_Deductions:"Bez odliczeń",
  No_Deductions_Word:"Utwórz swoją pierwszą <a routerLink='/folder/Deduction/New'>Odliczenie</a>.",
  New_Employee:"Nowy pracownik",
  No_Title:"Bez tytułu",
  _Name:"Nazwa",
  About_Yourself:"O sobie",
  Full_Name:"Pełne imię i nazwisko",
  Birthday:"Urodziny",
  Email:"E-mail",
  SMS:"SMS",
  Phone:"Telefon",
  Test:"Test",
  Call:"Połączenie",
  Fax:"Faks",
  Printed_Note:"Notatka drukowana",
  Position:"Pozycja",
  Job_Position:"Stanowisko",
  Select_a_Job:"Wybierz pracę",
  Select_a_Salary:"Wybierz wynagrodzenie",
  Add_a_Deduction:"Dodaj odliczenie",
  Taxes:"Podatki",
  Add_Taxes:"Dodaj Podatki",
  Date_of_Birth:"Data urodzenia",
  Email_Address:"Adres E-mail",
  Phone_Number:"Numer telefonu",
  Phone_Call:"Połączenie telefoniczne",
  Enable_on_Payroll:"Włącz na liście płac",
  Select_Employees:"Wybierz Pracowników",
  No_Employees:"Brak Pracowników",
  No_Employees_Word:"Dodaj swój pierwszy nowy <a routerLink='/folder/Employee/New'>Pracownik</a>.",
  No_Name:"Bez nazwy",
  Unemployeed:"Bezrobotni",
  Employeed:"Zatrudniony",
  Paid:"Płatny",
  Enabled:"Włączony",
  Disabled:"Wyłączony",
  Fire:"Ogień",
  Not_Available:"Niedostępne",
  Not_Available_Word:"Wydrukuj swoją pierwszą <a routerLink='/folder/Invoice/New'>Fakturę</a> i otrzymaj zapłatę.",
  Select_Invoices:"Wybierz_Faktury",
  Print_Invoice_Word:"Wydrukuj swoją pierwszą <a routerLink='/folder/Invoice/New'>Fakturę</a> i otrzymaj zapłatę.",
  Invoice_Title:"Tytuł faktury",
  Invoice_Date:"Data wystawienia faktury",
  Due_Date:"Termin płatności",
  New_Job:"Nowa praca",
  Details:"Detale",
  Customize:"Dostosuj",
  Customize_Dashboard:"Dostosuj pulpit nawigacyjny",
  Components:"Składniki",
  No_Components:"Brak Komponentów",
  Main_Components:"Główne składniki",
  Smaller_Components:"Mniejsze Komponenty",
  Error_Loading_Page:"Błąd podczas ładowania tej strony.",
  Bank_Details:"Dane bankowe",
  About_Your_Job:"O Twojej pracy",
  Your_Schedule:"Twój Harmonogram",
  Job_Title:"Stanowisko",
  Job_Description:"Opis pracy",
  Job_Details:"Szczegóły pracy",
  Enable_Job:"Włącz zadanie",
  Pay_Period:"Okres płatności",
  Perpetually_Schedule:"Nieustanny Harmonogram",
  Select_Jobs:"Wybierz oferty pracy",
  No_Jobs:"Brak pracy",
  Add_Jobs:"Dodaj oferty pracy",
  No_Jobs_Word:"Dodaj pierwsze <a routerLink='/folder/Job/New'>Praca</a> do listy.",
  Count_Employees:"job.employee_count+' Pracownicy'",
  Zero_Employees:"0 Pracowników",
  New_Leave:"Nowy Urlop",
  Leave_Name:"Zostaw Imię",
  Paid_Leave:"Płatny urlop",
  Leave_Pay:"Odprawa",
  Indefinite_Leave:"Urlop bezterminowy",
  Indefinite_Payroll:"Nieokreślona lista płac",
  Leave:"Opuścić",
  Add_Schedules:"Dodaj Harmonogramy",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Włącz nieobecność",
  Select_Leaves:"Wybierz Liście",
  No_Leaves:"Bez urlopów",
  Leave_Of_Absence:"Urlop",
  Leaves_Of_Absence:"Liście nieobecności",
  New_Leave_of_Absense:"Nowy Urlop",
  No_Leaves_Word:"Dodaj pierwszy <a routerLink='/folder/Leave/New'>Urlop</a>.",
  Not_Enabled:"Nie włączony",
  Absences:"Nieobecności",
  Payee:"Odbiorca płatności",
  New_Payee:"Nowy Odbiorca",
  Payee_Identifier:"Identyfikator odbiorcy płatności",
  Payee_Name:"Imię odbiorcy płatności",
  Payee_Title:"Tytuł odbiorcy płatności",
  Payment_Memo:"Notatka płatnicza",
  Select_Payees:"Wybierz Odbiorców",
  No_Payees:"Brak odbiorców",
  Add_Payee_Note:"Dodaj pierwszy <a routerLink='/folder/Payee/New'>Odbiorca Płatności</a>.",
  New_Payees:"Nowi odbiorcy",
  About_The_Payment_Schedule:"Harmonogram płatności",
  Your_Payroll_Schedule:"Automatyczna Lista Płac",
  New_Payment:"Nowa płatność",
  Identifier:"Identyfikator",
  Select:"Wybierać",
  Memo:"Notatka",
  Payment_Date:"Termin płatności",
  Mark_as_Paid:"Oznacz jako zapłacony",
  Select_Payments:"Wybierz Płatności",
  No_Payments:"Brak płatności",
  No_Payments_Word:"Utwórz pierwszą <a routerLink='/folder/Payment/New'>Płatność</a>.",
  Create_Payroll:"Utwórz listę płac",
  Properties:"Nieruchomości",
  Payroll_Title:"Tytuł listy płac",
  Payroll_Notes:"Notatki płacowe",
  Payroll_Setup:"Konfiguracja listy płac",
  Tabulate_Payments:"Płatności w formie tabeli",
  Tabulate:"Tabularyzować",
  By:"Za pomocą:",
  Payments_By:"Płatności przez",
  Timesheets_And_Schedules:"Arkusze czasu pracy i harmonogramy",
  Both:"Obydwa",
  Items:"Przedmiotów",
  Add_Payees:"Dodaj odbiorców",
  Add_Account:"Dodaj konto",
  Enable_Account:"Włącz Konto",
  Enable_Payroll:"Włącz listę płac",
  Print_Payroll:"Drukuj listę płac",
  No_Payrolls:"Brak listy płac",
  No_Payroll_Word:"Utwórz swoją pierwszą <a routerLink='/folder/Payroll/New'>Płace</a>.",
  View_more:"ZOBACZ WIĘCEJ",
  Less:"MNIEJ",
  Add_Payroll:"Dodaj listę płac",
  Select_Payroll:"Wybierz listę płac",
  About_Your_Salary:"O Twojej Wynagrodzeniu",
  Add_Salaries:"Dodaj pensje",
  Add_Salary:"Dodaj wynagrodzenie",
  Salaries:"Wynagrodzenia",
  No_Salaries:"Brak wynagrodzeń",
  No_Salaries_Word:"Dodaj pierwszy <a routerLink='/folder/Salary/New'>Wynagrodzenie</a>.",
  Select_Salaries:"Wybierz Wynagrodzenia",
  New_Salary:"Nowa Wynagrodzenie",
  Salary_Name:"Identyfikator wynagrodzenia",
  Enable_Salary:"Włącz wynagrodzenie",
  Salary_Amount:"Kwota wynagrodzenia",
  New_Schedule:"Nowy harmonogram",
  Schedule_Title:"Tytuł Harmonogramu",
  Add_Address:"Dodaj adres",
  Repeat:"Powtarzać",
  Design:"Projekt",
  Edit_Design:"Edytuj projekt",
  Edit_this_Design:"Edytuj ten Projekt",
  Repeat_Payment:"Powtórz płatność",
  Enable_Schedule:"Włącz Harmonogram",
  Never:"Nigdy",
  Select_Schedule:"Wybierz Harmonogramy",
  No_Schedules:"Brak harmonogramów",
  No_Schedules_Word:"Utwórz pierwszy <a routerLink='/folder/Schedule/New'>Harmonogram</a>.",
  New_Administrator:"Nowy Administrator",
  Username:"Nazwa użytkownika",
  First_Name:"Imię",
  Last_Name:"Nazwisko",
  Add_an_Address:"Dodaj adres",
  Payment_Limit:"Limit Płatności",
  Total_Limit:"Limit Całkowity",
  Select_Accounts:"Wybierz Konta",
  No_Administrators:"Brak Administratorów",
  No_Administrators_Word:"Utwórz pierwsze <a routerLink='/folder/Administrator/New'>Konto Administratora</a>.",
  New_Administrators_Word:"Dodaj pierwszego <a routerLink='/folder/Administrator/New'>Administrator</a>",
  Cloud:"Chmura",
  Backup:"Utworzyć kopię zapasową",
  Enable_iCloud:"Włącz iCloud",
  Enable_Google_Drive:"Włącz Dysk Google",
  Enable_Microsoft_OneDrive:"Włącz Microsoft OneDrive",
  Automatically_Backup:"Automatyczna Kopia Zapasowa",
  FTP_Settings:"Ustawienia FTP",
  URL_File_Prompt:"Proszę określić lokalizację pliku .xls / .xlsx / .json do zaimportowania:",
  URL_SQL_Prompt:"Proszę określić lokalizację pliku SQLite do zaimportowania:",
  FTP_Backup:"Kopia FTP",
  FTP_Import:"Import FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Gospodarz",
  Port:"Port",
  Path:"Ścieżka",
  Data_Path:"Ścieżka Danych",
  Enable_FTP_Account:"Włącz Konto FTP",
  HostnameIP:"Nazwa Hosta",
  Password:"Hasło",
  Connection_Port:"Port Połączeniowy",
  Enable_MySQL_Database:"Włącz Bazę Danych MySQL",
  Log:"Dziennik",
  Reset:"Resetowanie",
  Erase:"Usuwać",
  Export:"Eksport",
  Database:"Baza danych",
  Upload_CSV:"Prześlij CSV",
  Download_CSV:"Pobierz CSV",
  SQL_Database:"URL SQL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Kopia zapasowa MySQL",
  Internal_Notes:"Notatki wewnętrzne",
  Root_Path:"Ścieżka Korzeń",
  Select_Backup:"Wybierz kopię zapasową",
  Add_New_Backup:"Dodaj nową kopię zapasową",
  First_Backup:"Ustaw pierwszą kopię zapasową...",
  Backups:"Kopie zapasowe",
  Add_Backup:"Dodaj kopię zapasową",
  No_Backups:"Nie ma kopii zapasowych do znalezienia.",
  Select_Backup_Type:"Wybierz typ kopii zapasowej, którą chcesz skonfigurować...",
  Backup_Type:"Typ Kopii Zapasowej",
  FTP_Drive:"Dysk FTP",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Odwieźć",
  Microsoft_OneDrive:"Odwieźć",
  Import_Fields:"Pola Importu",
  Import_Fields_Word:"Użyj tej sekcji, aby wybrać <a routerLink='/folder/Holiday/New'>Import</a> dane.",
  Upload:"Wgrywać",
  Download:"Ściągnij",
  Download_JSON:"Pobierz jako dane JSON",
  Download_SQL:"Pobierz jako plik SQL",
  New_Bank:"Nowy Bank",
  New_Account:"Nowe konto",
  New_Bank_Account:"Nowe Konto Bankowe",
  Upload_Image:"Załaduj obrazek",
  Bank_Name:"Nazwa banku",
  Bank_Address:"Numer konta",
  Branch_Address:"Adres oddziału",
  Address_on_Cheque:"Adres",
  Cheque_Numbers:"Sprawdź Liczby",
  Cheque_Details:"Sprawdź Szczegóły",
  Bank_Logo:"Logo Banku",
  Cheque_ID:"Sprawdź ID",
  Starting_Cheque_ID:"Rozpoczęcie czeku ID",
  Transit_Number:"Numer tranzytowy",
  Institution_Number:"Numer Instytucji",
  Designation_Number:"Numer oznaczenia",
  Account_Number:"Numer konta",
  Limits:"Limity",
  Default_Limits:"Domyślne limity",
  Over_Limit:"Ponad limit",
  Under_Limit:"Pod limitem",
  Payroll_Limit:"Limit wynagrodzeń",
  Enable_Bank_Account:"Włącz Konto Bankowe",
  Select_Account:"Wybierz Konto",
  No_Bank_Account:"Brak Konta Bankowego",
  No_Bank_Account_Word:"Dodaj pierwsze <a routerLink='/folder/Accounts/New'>Konto Bankowe</a>.",
  Bank_Accounts:"Konta bankowe",
  No_Accounts:"Brak Kont",
  No_Accounts_Note:"Dodaj pierwsze <a routerLink='/folder/Accounts/New'>Konto Bankowe</a>.",
  Cheque_Designer:"Sprawdź Projektanta",
  Cheque_Design:"Sprawdź Projekt",
  Select_Design:"Wybierz Projekt",
  Cheque_Designs:"Sprawdź Wzory",
  No_Cheque_Designs:"Wzory bez sprawdzenia",
  No_Cheque_Designs_Word:"Utwórz własny <a routerLink='/folder/Settings/Cheque/Design/New'>Sprawdź Projekt</a>.",
  Set_Default:"Ustaw jako domyślne",
  Default:"Domyślna",
  Remove:"Usunąć",
  Folder:"Teczka",
  Edit:"Edytować",
  LoadingDots:"Ładowanie...",
  Add_a_New_File:"Dodaj <a href='#' (click)='add()'>nowy plik</a> do",
  this_folder:"ten folder",
  FTP_Backup_Settings:"Ustawienia Kopii Zapasowej FTP",
  Secure_File_Transfer:"Bezpieczny Transfer Plików",
  New_Holiday:"Nowe Święto",
  Add_Holiday:"Dodaj święto",
  Holiday_Name:"Nazwa Święta",
  Additional_Pay:"Dodatkowe wynagrodzenie",
  Enable_Holiday:"Włącz Święto",
  Select_Holidays:"Wybierz Święta",
  No_Holidays:"Brak świąt",
  No_Holidays_Word:"Dodaj pierwsze <a routerLink='/folder/Holiday/New'>Święto Publiczne</a>.",
  New_Import:"Nowy Import",
  Import_Data:"Zaimportować dane",
  Import_Data_Word:"Wybierz typ pliku lub wybraną metodę przesyłania.<br /> Będziesz mógł wybrać dowolne zaimportowane pola w pliku, które odpowiadają dowolnemu parametrowi w aplikacji po przesłaniu obsługiwanego pliku.", 
  Import_File:"Importować plik",
  Link_To_File:"Link do pliku",
  Select_File:"Wybierz plik",
  New_Moderator:"Nowy Moderator",
  Allow_Moderation:"Zezwól na moderację",
  Create_Paycheques:"Utwórz czeki",
  Edit_Paycheques_and_Data:"Edytuj Wypłaty i Dane",
  Destroy_Data_and_Paycheques:"Zniszcz dane i czeki",
  Bonus_Percentage:"Procent czeku",
  Fixed_Amount:"Stała kwota",
  Select_Moderator:"Wybierz Moderatora",
  No_Moderators:"Brak Moderatorów",
  Moderators:"Moderatorzy",
  Moderator_Account:"Utwórz pierwsze <a routerLink='/folder/Administrator/New'>Konto Moderatora</a>.",
  No_Moderators_Word:"Dodaj pierwszego <a routerLink='/folder/Administrator/New'>Moderator</a>.",
  Defaults:"Domyślne",
  Provide_Us_Info:"Podaj nam informacje",
  Setup_Your_Printer:"Skonfiguruj swoją drukarkę",
  Your_Company:"O Twojej Firmie",
  Company_Name:"Nazwa firmy",
  Currency:"Waluta",
  Default_Currency:"Domyślna waluta",
  Base_Monthly_Income:"Miesięczny przychód",
  Protection:"Ochrona",
  App_Protection:"Ochrona Aplikacji",
  PIN_Code_Protection:"Ochrona Kodem PIN",
  App_Protection_Word:"Włącz metody bezpieczeństwa, które pomogą chronić Twoje konto.",
  PIN_Code:"Kod PIN",
  Change_PIN:"Zmień PIN",
  New_Password:"Nowe hasło",
  Geofence_Protection:"Ochrona Geo-Ogrodzenia",
  Geofence_Area:"Ustaw Obszar",
  Distance:"Dystans",
  Setup_Now:"Ustaw teraz",
  Mile:"Mila",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Rozpoznawanie twarzy",
  Face:"Twarz",
  Setup:"Ustawiać",
  Label:"Etykieta",
  Password_Protection:"Ochrona hasła",
  Modify_Password:"Zmień Hasło",
  New_Tax_Entry:"Nowy Wpis Podatkowy",
  New_Tax:"Nowy podatek",
  Tax_Label:"Etykieta Podatkowa",
  Perpetually_Enabled:"Włączone Wiecznie",
  Select_Taxes:"Wybierz Podatki",
  Tax_Deductions:"Odliczenia podatkowe",
  No_Tax_Deductions:"Bez Odliczeń Podatkowych",
  No_Tax_Deductions_Word:"Dodaj pierwsze odliczenie <a routerLink='/folder/Tax/New'>Podatek</a>.",
  New_Timer:"Nowy Zegar",
  Start:"Początek",
  Stop:"Zatrzymać",
  Start_Timer:"Uruchom timer",
  Stop_Timer:"Zatrzymaj Zegar",
  Timer_Active:"Timer Aktywny",
  Timer:"Regulator czasowy:",
  Timer_Running:"Zegar: (Uruchomiony)",
  Save_Timer:"Zapisz Timer",
  New_Timesheet:"Nowy Arkusz Czasowy",
  Select_Timesheets:"Wybierz karty czasu pracy",
  Work_Notes:"Notatki robocze",
  Entry_Title:"Tytuł wpisu",
  No_Timesheets:"Brak kart czasu pracy",
  No_Timesheets_Word:"Dodaj pierwszy <a routerLink='/folder/Timesheet/New'>Arkusz Czasu</a>.",
  Timesheet_Submitted:"Przesłano kartę czasu pracy",
  Timesheet_Congrats:"Gratulacje! Twój arkusz czasu pracy został pomyślnie przesłany. Dziękuję!",
  Timesheet_Copy:"Aby otrzymać kopię swoich dokumentów, podaj nam swój adres e-mail i/lub numer telefonu komórkowego.",
  Submit:"Składać",
  Allow_Notifications:"Zezwól na powiadomienia",
  Untitled_Entry:"Nowe wejście",
  Untitled_Bank:"Bank bez tytułu",
  Timesheet_Entry:"Wpis do karty czasu pracy",
  Work_Description:"Opis pracy",
  Enable_Timesheet:"Włącz kartę czasu pracy",
  Submit_Timesheet:"Prześlij arkusz czasu",
  Vacation:"Wakacje",
  New_Vacation:"Nowe wakacje",
  Vacation_Name:"Nazwa wakacji",
  Paid_Vacation:"Opłacone wakacje",
  Vacation_Pay:"Płatność urlopowa",
  Enable_Vacation:"Włącz wakacje",
  Select_Vacations:"Wybierz Wakacje",
  No_Vacations:"Brak wakacji",
  No_Vacations_Word:"Utwórz pierwszy wpis <a routerLink='/folder/Vacation/New'>Wakacje</a>.",
  Payroll_Schedule:"Harmonogram płac",
  Next_Payroll:"Następna lista płac:",
  Upcoming_Payroll:"Nadchodząca lista płac",
  No_Upcoming_Payroll:"Brak nadchodzącej listy płac",
  Address_Book:"Książka adresowa",
  Archived_Documents:"Dokumenty Archiwalne",
  Dev_Mode:"Aplikacja W Trybie Deweloperskim",
  Administrators:"Administratorzy",
  Privacy:"Prywatność",
  None:"Nic",
  Select_Signature:"Wybierz Podpis",
  No_Signatures:"Brak podpisów",
  No_Signatures_Word:"Dodaj pierwszy <a routerLink='/folder/Signature/New'>Podpis</a>.",
  Repeat_Indefinitely:"Powtarzaj w nieskończoność",
  Sign:"Podpisać",
  Sign_Here:"Podpisz tutaj",
  Date_Signed:"Data podpisu",
  Signature_Pad:"Podpis",
  Account_Holder:"Właściciel konta",
  Account_Properties:"Właściwości Konta",
  Name_On_Cheque:"Nazwisko na czeku",
  Server_Hostname:"Nazwa / IP Serwera",
  Printers:"Drukarki",
  No_Printers:"Brak drukarek",
  Printer_Exists:'Drukarka o tej nazwie już istnieje.',
  No_Printers_Word:"Dodaj pierwszą <a routerLink='/folder/Printer/New'>Drukarkę</a>.",
  No_Printer_Alert:"Nie zdefiniowano drukarki. Czy chcesz skonfigurować drukarkę?",
  Add_Printer:"Dodaj drukarkę",
  New_Printer:"Nowa Drukarka",
  Printer_Hostname:"Nazwa Hosta Drukarki / IP",
  Printer_Label:"Etykieta drukarki",
  Printer_Protocol:"Protokół Drukarki",
  Protocol:"Protokół",
  Email_Print:"E-mail",
  AirPrint:"Drukowanie lotnicze",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Gniazdo elektryczne",
  Print_Job:"Zadanie Drukowania",
  Printed:"Drukowane",
  Not_Printed:"Nie drukowane",
  Print_Jobs:"Prace Drukowania",
  Print_Queue:"Kolejka drukowania",
  No_Print_Jobs:"Brak zadań drukowania",
  No_Prints:"Utwórz nowy <a routerLink='/folder/Cheque/New'>Czek</a> do wydrukowania.",
  Prints:"Odbitki",
  Find_Printer:"Znajdż drukarkę",
  Find_AirPrint_Devices:"Znajdź Urządzenia AirPrint",
  Select_Printer:"Wybierz Drukarkę",
  System_UI:"UI systemu",
  Printer:"Drukarka",
  Status:"Status",
  Preview:"Zapowiedź",
  Enable_Print_Job:"Włącz zadanie drukowania",
  Queue_Weight:"Waga kolejki",
  Unlimited:"Nieograniczony",
  Show_Advanced_Printer_Options:"Pokaż Zaawansowane Opcje Drukarki",
  Advanced:"Zaawansowany",
  Location:"Lokalizacja",
  Note:"Notatka",
  Queue_Name:"Nazwa kolejki",
  Pages_Printed_Limit:"Limit wydrukowanych stron",
  MultiPage_Idle_Time:"Wielostronicowy Czas Oczekiwania (s)",
  Page_Count_Limit:"Limit liczby stron",
  Page_Orientation:"Orientacja strony",
  Portrait:"Portret",
  Landscape:"Krajobraz",
  Duplex:"Dupleks",
  Double_Sided_Printing:"Dwustronna",
  Duplex_Mode:"Tryb Duplex",
  Duplex_Short:"Niski",
  Duplex_Long:"Długo",
  Duplex_None:"Nic",
  Color_And_Quality:"Kolor i jakość",
  Monochrome:"Monochromia",
  Photo_Quality_Prints:"Odbitki w jakości fotograficznej",
  Printer_Email:"E-mail drukarki",
  Automatically_Downsize:"Automatycznie zmniejsz rozmiar",
  Paper:"Papier",
  Paper_Name:"Nazwa Papieru",
  Paper_Width:"Szerokość Papieru",
  Paper_Height:"Wysokość Papieru",
  Paper_Autocut_Length:"Długość automatycznego cięcia papieru",
  Margins:"Marże",
  Page_Margins:"Marginesy strony",
  Page_Margin_Top:"Margines górnej strony",
  Page_Margin_Right:"Margines Prawej Strony",
  Page_Margin_Bottom:"Margines Dolnej Strony",
  Page_Margin_Left:"Margines Lewej Strony",
  Add_Employees:"Dodaj Pracowników",
  Header:"Nagłówek",
  Print_A_Page_Header:"Drukuj nagłówek strony",
  Header_Label:"Etykieta nagłówka",
  Header_Page_Index:"Indeks strony nagłówkowej",
  Header_Font_Name:"Czcionka nagłówka",
  Select_Font:"Wybierz czcionkę",
  Font_Selector:"Wybór czcionek",
  Header_Font_Size:"Czcionka nagłówka",
  Header_Bold:"Pogrubiony nagłówek",
  Header_Italic:"Nagłówek kursywa",
  Header_Alignment:"Wyrównanie nagłówka",
  Left:"Lewo",
  Center:"Środek",
  Right:"Prawidłowy",
  Justified:"Usprawiedliwiony",
  Header_Font_Color:"Kolor Nagłówka",
  Select_Color:"Wybierz kolor",
  Footer:"Stopka",
  Print_A_Page_Footer:"Drukuj stopkę strony",
  Footer_Label:"Etykieta w stopce",
  Footer_Page_Index:"Indeks strony stopki",
  Footer_Font_Name:"Czcionka stopki",
  Fonts:"Czcionki",
  Done:"Gotowe",
  Select_Fonts:"Wybierz Czcionki",
  Footer_Font_Size:"Rozmiar stopki",
  Footer_Bold:"Pogrubienie w stopce",
  Footer_Italic:"Kursywa w stopce",
  Footer_Alignment:"Wyrównanie Stopki",
  Footer_Font_Color:"Kolor Stopki",
  Page_Copies:"Kopie stron",
  Enable_Printer:"Włącz Drukarkę",
  Remote_Logging:"Zdalne rejestrowanie",
  Log_Server:"Serwer Logów",
  Encryption:"Szyfrowanie",
  Random_Key:"Losowy klucz",
  Encryption_Key:"Klucz Szyfrowania",
  Cheques_Webserver:"Niestandardowa baza danych",
  Learn_How:"Naucz się jak",
  Signature:"Podpis",
  Default_Printer_Unit:"cal/cm/mm/(pt)",
  View_Signature:"Wyświetl podpis",
  Printed_Signature:"Podpis drukowany",
  Digitally_Sign:"Znak Cyfrowy",
  Digital_Signature:"Podpis cyfrowy",
  Add_Signature:"Dodaj Podpis",
  Add_Your_Signature:"Dodaj Swój Podpis",
  Enable_Signature:"Włącz Podpis",
  Digitally_Signed:"Podpisany cyfrowo",
  Insert_Error:"Nie można zapisać czeku z powodu problemów z bazą danych.",
  Delete_Confirmation:"Czy na pewno chcesz usunąć te informacje?",
  Discard_Confirmation:"Czy na pewno chcesz odrzucić te informacje?",
  Discard_Bank_Confirmation:"Czy na pewno chcesz odrzucić to konto?",
  Discard_Printer_Confirmation:"Czy na pewno chcesz wyrzucić tę drukarkę?",
  Delete_Bonus_Confirmation:"Czy na pewno chcesz usunąć ten bonus?",
  Delete_Invoice_Confirmation:"Czy na pewno chcesz usunąć tę fakturę?",
  Generated_Cheque:"Wygenerowany Czek",
  Generated_Invoice:"Wygenerowana Faktura",
  Schedule_Start_Time:"Rozpoczęcie Harmonogramu",
  Schedule_End_Time:"Koniec Harmonogramu",
  New_Call:"Nowe połączenie",
  No_Contacts:"Brak Kontaktów",
  No_Contacts_Word:"Administratorzy, moderatorzy, pracownicy i odbiorcy pojawiają się jako kontakty.",
  PDF_Subject:"finanse",
  PDF_Keywords:"czeki czekowe PDF na listę płac",
  Printer_Setup:"Konfiguracja Drukarki",
  Printer_Selection:"Wybór Drukarki",
  New_Fax:"Nowy Faks",
  New_Fax_Message:"Nowa Wiadomość Faksowa",
  Fax_Machine:"Faks",
  Fax_Name:"Nazwa Faksu",
  Fax_Email:"Faks E-mail",
  Fax_Number:"Numer faksu",
  Contents:"Zawartość",
  Fax_Body:"Treść strony",
  Header_Word:"Nagłówek:",
  Header_Text:"Tekst nagłówka",
  Include_Header:"Dołącz nagłówek",
  Include_Footer:"Dołącz stopkę",
  Footer_Word:"Stopka:",
  Footer_Text:"Tekst w stopce",
  Attach_a_Cheque:"Dołącz czek",
  Add_Deduction:"Dodaj odliczenie",
  Enable_Fax:"Wyślij Faks",
  Select_Fax:"Wybierz Faks",
  No_Faxes:"Brak Faksów",
  Faxes:"Faksy",
  Save_and_Send:"Wyślij Faks",
  Save_and_Pay:"Zapisz i zapłać",
  WARNING:"OSTRZEŻENIE:",
  Remember:"Pamiętać",
  Printing:"Druk",
  Printing_Complete:"Drukowanie zakończone!\n\n",
  of:"z",
  There_Were:"Byli ",
  Successful_Prints:" udane wydruki i ",
  Unsuccessful_Prints:" nieudane wydruki.",
  PrinterError:"Przepraszamy, wystąpił błąd.",
  Printing_:"Druk...",
  PrinterSuccess:"Dokument został pomyślnie wydrukowany.",
  PrintersSuccess:"Dokumenty wydrukowane pomyślnie.",
  Print_Cheques:"Wydruk czeków",
  New_Message:"Nowa wiadomość",
  New_Messages:"Nowe wiadomości",
  Read_Message:"Czytać wiadomość",
  Write_Message:"Pisać wiadomość",
  Send_Message:"Wyślij wiadomość",
  Subject:"Przedmiot",
  Message:"Wiadomość",
  Writing:"Pismo...",
  Send:"Wysłać",
  Set_Date:"Ustawić datę",
  Set_Time:"Ustaw Czas",
  Recieve:"Odbierz",
  Set_as_Default:"Ustaw jako domyślne",
  Default_Account:"Konto Domyślne",
  Default_Design:"Projekt Domyślny",
  Multiple_Cheques:"Kontrole (potrójne)",
  Account_Mode:"Tryb Konta",
  Multiple_Cheques_Description:"Trzy kontrole na stronę.",
  Check_and_Table:"Sprawdź i stół",
  Check_including_Table:"Tabela czeków i księgowości.",
  Check_Mailer:"Sprawdź Pocztę",
  Check_Mailer_Window:"Sprawdź w oknie adresu.",
  DocuGard_Table_Top:"DocuGard Sprawdź i Tabela (góra)",
  DocuGard_Table_Middle:"DocuGard Sprawdź i Tabela (środkowy)",
  DocuGard_Table_Bottom:"DocuGard Sprawdź i Tabela (na dole)",
  DocuGard_Mailer_Top:"DocuGard Sprawdź pocztę (góra)",
  DocuGard_Mailer_Middle:"DocuGard Sprawdź pocztę (środkowy)",
  DocuGard_Mailer_Bottom:"DocuGard Sprawdź pocztę (na dole)",
  DocuGard_Three_Cheques:"Kontrole DocuGard (potrójne)",
  DocuGard_Cheque_Top:"Czek DocuGard (Góra)",
  DocuGard_Cheque_Middle:"Czek DocuGard (środkowy)",
  DocuGard_Cheque_Bottom:"Czek DocuGard (na dole)",
  DocuGard_Three_Cheques_Window:"Trzy czeki na jednej stronie.",
  DocuGard_Table_Top_Window:"Tabela czeków i zarobków.",
  DocuGard_Table_Middle_Window:"Tabela czeków i zarobków.",
  DocuGard_Table_Bottom_Window:"Tabela czeków i zarobków.",
  DocuGard_Mailer_Top_Window:"Czek, tabela i adres.",
  DocuGard_Mailer_Middle_Window:"Czek, tabela i adres.",
  DocuGard_Mailer_Bottom_Window:"Czek, tabela i adres.",
  DocuGard_Cheque_Top_Window:"Sprawdź bezpieczny papier.",
  DocuGard_Cheque_Middle_Window:"Sprawdź bezpieczny papier.",
  DocuGard_Cheque_Bottom_Window:"Sprawdź bezpieczny papier.",
  Basic_Cheque:"Sprawdź (Góra)",
  Basic_Cheque_Print:"Wydrukuj pojedynczy czek.",
  Error_Setting_As_Paid:"Ustawienie błędu jako zapłaconego",
  Add_Attachment:"Dodać załącznik",
  PrinterUnavailable:"Drukarka niedostępna",
  CreditCardComponent:"Karty",
  PaypalComponent:"PayPal",
  InteracComponent:"Interak",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Nowa Lokata",
  Deposits:"Depozyty",
  No_Deposits:"Bez depozytów",
  Credit_Card_Deposit:"Karta kredytowa",
  New_Credit_Card_Deposit_Message:"Depozyt Karty Kredytowej",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Depozyt BitCoin",
  New_Interac_Deposit:"Interak",
  New_Interac_Deposit_Message:"Przeniesienie Interac",
  Payments_Limit:"Limit płatności",
  No_Payment_Limit:"Brak Limitu Płatności",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Depozyt PayPal",
  No_Deposits_Word:"Dodaj pierwszą firmę <a routerLink='/folder/Deposit/New'>Depozyt</a>.",
  No_Documents_Specified:"Nie określono dokumentów do druku",
  No_Printers_Added:"Nie znaleziono drukarek. Przejdź do Ustawienia > Drukarki, aby ją dodać.",
  DB_Erase_Prompt:"Całkowicie wykasuj całą bazę danych? UWAGA: Utracisz wszystkie zapisane informacje!",
  Console_Warning:"Nie wklejaj żadnego tekstu do tej konsoli. Możesz narazić siebie i/lub swoją firmę na poważne ryzyko.",
  No_Faxes_Word:"Utwórz pierwszy <a routerLink='/folder/Fax/New'>Faks</a>.",
  Cheque_Delete_Confirmation:"Czy na pewno chcesz usunąć ten czek?",
  Design_Delete_Confirmation:"Czy na pewno chcesz usunąć ten projekt?",
  Cheque_Pay_Confirmation:"Oznaczyć ten czek jako opłacony? NIE pojawi się w kolejce wydruku.",
  Payment_Pay_Confirmation:"Oznaczyć tę płatność jako zapłaconą? NIE pojawi się w kolejce czeków.",
  Delete_Deduction_Confirmation:"Czy na pewno chcesz usunąć to odliczenie?",
  Delete_Job_Confirmation:"Czy na pewno chcesz usunąć tę pracę?",
  Delete_Timesheet_Confirmation:"Czy na pewno chcesz usunąć ten grafik?",
  Delete_Schedule_Confirmation:"Czy na pewno chcesz usunąć ten harmonogram?",
  Delete_Setting_Confirmation:"Czy na pewno chcesz zresetować to ustawienie?",
  Delete_Fax_Confirmation:"Czy na pewno chcesz usunąć ten faks?",
  Delete_File_Confirmation:"Czy na pewno chcesz usunąć ten plik?",
  Delete_Vacation_Confirmation:"Czy na pewno chcesz usunąć te wakacje?",
  Delete_Printer_Confirmation:"Czy na pewno chcesz usunąć tę drukarkę?",
  Remove_Design_Confirmation:"Czy na pewno chcesz usunąć ten projekt?",
  Delete_Payroll_Confirmation:"Czy na pewno chcesz usunąć tę listę płac?",
  Send_Fax_Email_Confirmation:"Czy chcesz przesłać te dokumenty faksem i e-mailem?",
  Send_Email_Confirmation:"Czy chcesz wysłać ten dokument e-mailem?",
  Send_Fax_Confirmation:"Czy chcesz przefaksować ten dokument?",
  Error_Generating_PDF:"Przepraszamy. Wystąpił błąd podczas generowania tego dokumentu.",
  PDF_Error_Saving_Image:"Przepraszamy. Wystąpił błąd podczas zapisywania obrazu PDF tego dokumentu.",
  Test_Printer_Confirmation:"Czy chcesz wydrukować stronę testową na tej drukarce?",
  Save_Timesheet_Prompt:"Proszę dodać 'Tytuł' lub nacisnąć 'Uruchom Timer', aby zapisać.",
  Remove_Geofence_Prompt:"Czy na pewno chcesz usunąć lokalizację tego ogrodzenia geograficznego?",
  Delete_Employee_Confirmation:"Czy jesteś pewien, że chcesz usunąć ",
  Fire_Employee_Confirmation:"Czy na pewno chcesz odpalić"
}