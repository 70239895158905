export const Tg = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"БА СУПОРИШ:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  Copyright:"муаллифӣ & нусха; 2023",
  black:"Сиёҳ",
  green:"Сабз",
  gold:"Тилло",
  blue:"Кабуд",
  brown:"Қаҳваранг",
  purple:"Арғувон",
  pink:"Гулобӣ",
  red:"Сурх",
  Swatches:"Намунаҳо",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Ранг",
  Saturation:"Сатҳи",
  Lightness:"Сабукӣ",
  Upgrade_To_Pro:"Ба Pro навсозӣ кунед",
  AllFeaturesInclude:"Ҳама хусусиятҳо дар бар мегиранд:",
  PrintUnlimitedCheques:"Чекҳои номаҳдудро чоп кунед",
  PremiumChequeDesigns:"Тарҳҳои чеки Premium",
  ManageUnlimitedEmployees:"Кормандони номаҳдудро идора кунед",
  AddUnlimitedPayees:"Пардохткунандагони бемаҳдуд илова кунед",
  CreateUnlimitedPayrolls:"Эҷоди музди бемаҳдуд",
  UnlimitedSchedulesandTimeSheets:"Ҷадвалҳои номаҳдуд ва ҷадвалҳои вақт",
  BulkPayPalPayouts:"Пардохтҳои оммавии PayPal",
  UnlimitedBankAccounts:"Ҳисобҳои бонкии номаҳдуд",
  ManageMultipleCompanies:"Якчанд ширкатҳоро идора кунед",
  TrackInsurancePolicies:"Сиёсати суғуртаро пайгирӣ кунед",
  Bio_MetricProtection:"Муҳофизати биометрӣ",
  Geo_FenceLock_OutProtection:"Муҳофизати Geo-Fence Lock-Out",
  Multiple_Companies_Word:"Идоракунии ширкатҳои сершумор бидуни мукофоти Checks дастрас нест.",
  PayPal_Payouts_Word:"Пардохтҳои PayPal бидуни мукофоти чекҳо ғайрифаъол карда мешаванд.",
  PINProtection:"Муҳофизати PIN",
  PasswordProtection:"Муҳофизати парол",
  May_Require_Approval:"Метавонад тасдиқро талаб кунад.",
  Subscribe:"Обуна шавед",
  Billed:"Ҳисоб карда шуд",
  Up:"боло",
  Down:"Поён",
  Positioning:"Ҷойгиркунӣ",
  Marker:"Нишондиҳанда",
  Drag_Marker:"Нишондиҳандаро кашед",
  Tagline:"Чекҳоро чоп кунед ва ҷадвали музди меҳнат",
  Marker_Word:"Барои андозагирии элемент аломатҳоро истифода баред.",
  Pinch_Zoom:"Масштабро пахш кунед",
  Pinch_Word:"Барои васеъ кардани элемент чимч кунед.",
  Drag:"кашола кунед",
  Drag_Word:"Барои кашола кардани унсурҳо ангушти худро истифода баред.",
  subscription_alias_word:"Навсозии худкор обуна",
  premium_alias_word:"Бастаи такмилдиҳии яквақта",
  print_alias_word:"Чекхои индивидуалиро чоп кунед",
  mode_alias_word:"Режим",
  Pro:"Pro",
  Pro_word:"Версияи Pro талаб карда мешавад.",
  Cant_Delete_Default_Company_Word:"Бубахшед, шумо ширкати пешфарзии худро нест карда наметавонед.",
  Reinsert_Default_Designs:"Тарҳҳои пешфарзро дубора ворид кунед",
  Reinsert_Default_Designs_word:"Оё шумо мехоҳед тарҳҳои пешфарзро дубора ворид кунед?",
  Subscription_Active_Disable_Word:"Ин обуна фаъол аст. Оё шумо мехоҳед ин обуна ба чекҳоро бекор кунед?",
  Company_Logo:"Логотипи ширкат",
  ZERO_:"СУЛ",
  Disclaimer:"Радди масъулият",
  Privacy_Policy:"Сиёсати Корбурди Маълумоти Шахсӣ",
  EULA:"EULA-ро тафтиш мекунад",
  Terms_Of_Service:"Шартҳои хизматрасонӣ",
  Terms_Of_Use:"Шартҳои истифода",
  Refunds:"Сиёсати баргардонидан",
  Single_Print:"1 Санҷед",
  Two_Prints:"2 Санҷишҳо",
  Five_Prints:"5 Санҷишҳо",
  Ten_Prints:"10 Санҷиш",
  Fifteen_Prints:"15 Санҷишҳо",
  Twenty_Prints:"20 Санҷиш",
  Thirty_Prints:"30 Санҷиш",
  Image_Added:"Тасвир илова карда шуд",
  Image_Preview:"Пешнамоиши тасвир",
  No_Image_Was_Selected:"Ягон тасвир интихоб нашудааст.",
  Cheques_Unlimited:"Чекҳо бемаҳдуд",
  _Subscription:"Обуна",
  Subscription:"Чекхо — 1 мох",
  Two_Month_Subscription:"Санҷишҳо - 2 моҳ",
  Three_Month_Subscription:"Чекхо — 3 мох",
  Six_Month_Subscription:"Санҷишҳо - 6 моҳ",
  Twelve_Month_Subscription:"Чекхо — 12 мох",
  Cheques_Are_Available:"Чекҳо барои чоп дастрасанд.",
  Upgrade_Required_Two:"Бастаеро интихоб кунед ва тугмаи нархро ду маротиба клик кунед, то хариди худро оғоз кунед. Чекҳои пурраи рангаи касбиро дар сонияҳо чоп кунед.",
  Month:"Моҳ",
  Due:"Муддати:",
  Expires:"Ба охир мерасад:",
  Subscription_Active:"Обуна фаъол",
  Subscription_Inactive:"Обуна ғайрифаъол",
  Purchase_Additional_Cheques:"Чекҳои иловагӣ харед?",
  Printable_Cheque:"Санҷиши чопшаванда",
  Printable_Cheques:"Чекҳои чопшаванда",
  Printable_Cheque_Word:"чек дар ҳисоби шумо мавҷуд аст.",
  Printable_Cheques_Word:"чекҳо дар ҳисоби шумо мавҷуданд.",
  Max_Amount_Message:"Маблағи муайянкардаи шумо ба ҳадди ҳадди аксар барои ин система расидааст:",
  Terms_Required_Word:"Шумо бояд пеш аз идома додани истифодаи чекҳо ба ин созишнома розӣ шавед.",
  Subscriptions:"Обунаҳо",
  Product_Upgrades:"Навсозӣ",
  Mailed_Out_Cheques:"Чекҳои фиристодашуда",
  Enter_A_Company_Name:"Лутфан номи ширкатро ворид кунед.",
  Single_Cheques:"Санҷишҳои ягона",
  Cheque_Golden:"Чеки тиллоӣ",
  Cheque_Golden_Window:"Тарҳрезии чеки тиллоӣ.",
  Cheque_Green:"Санҷиши сабз",
  Cheque_Green_Window:"Тарҳрезии чеки сабз.",
  Cheque_Red:"Санҷиши сурх",
  Cheque_Red_Window:"Тарҳрезии чеки сурх.",
  Cheque_Yellow:"Санҷиши зард",
  Cheque_Yellow_Window:"Тарҳрезии чеки зард.",
  Cheque_Statue_of_Liberty:"Ҳайкали Озодӣ",
  Cheque_Statue_of_Liberty_Window:"Тарҳрезии ҳайкали Озодӣ.",
  Cheque_Green_Wave:"Мавҷи сабз",
  Cheque_Green_Wave_Window:"Тарҳрезии чеки сабз.",
  Cheque_Golden_Weave:"Бофтаи тиллоӣ",
  Cheque_Golden_Weave_Window:"Тарҳи зебои чеки тиллоӣ.",
  Cheque_Green_Sun:"Офтоби сабз",
  Cheque_Green_Sun_Window:"Тарҳрезии чеки амиқ ва оромкунанда.",
  Cheque_Blue_Checkers:"Шашкаҳои кабуд",
  Cheque_Blue_Checkers_Window:"Тарҳрезии чеки кабуд.",
  Cashiers_Check:"Чеки кассир",
  Cashiers_Check_Window:"Шаблон услуби чеки хазинадор.",
  Cheque_Aqua_Checkers:"Шашкаҳои обӣ",
  Cheque_Aqua_Checkers_Window:"Тарҳрезии санҷиши аква.",
  Cheque_Golden_Checkers:"Шашкаҳои тиллоӣ",
  Cheque_Golden_Checkers_Window:"Тарҳрезии чеки тиллоӣ.",
  Upgrade_Unavailable:"Навсозӣ дастнорас",
  Bank_Code_Protection:"Муҳофизати рақами бонкӣ",
  Bank_Code_Protection_Word:"Рақамҳои бонкии худро аз истифодаи ин барномае, ки дар дастгоҳи дигар ё барои корбари дигар кор мекунад, муҳофизат кунед. Ин амал бебозгашт аст. Давом додан?",
  Bank_Code_Protection_Blocked_Word:"Рақамҳои бонкие, ки шумо истифода карданӣ ҳастед, барои корбар ё дастгоҳи дигар маҳфуз аст.",
  Bank_Code_Protection_Error_Word:"Тасдиқи рақам ноком шуд. Лутфан ба интернет пайваст шавед ва бори дигар кӯшиш кунед, ки ин ҳисоби бонкиро илова кунед.",
  Bank_Code_Protection_Set_Error_Word:"Муҳофизати рақами бонкӣ талаб мекунад, ки шумо ба интернет пайваст бошед. Лутфан пайваст шавед ва бори дигар кӯшиш кунед.",
  Upgrade_Unavailable_Word:"Бубахшед, мо ҳангоми тасдиқи шумо мушкилӣ дорем. Обунаҳо ва навсозӣ ба Checks дар айни замон барои харид дар минтақаи шумо дастрас нестанд.",
  PayPal_Payment_Preview:"Пешнамоиши пардохти PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"PayPal-ро интихоб кунед",
  PayPal_Applications:"Барномаҳои PayPal",
  Purchase_With_Apple_Pay:"Бо Apple Pay харид кунед",
  Google_Pay:"Google Pay",
  Companies:"Ширкатҳо",
  Insurance:"Суғурта",
  New_PayPal:"PayPal нав",
  Pay_By:"Пардохт аз ҷониби",
  Insure:"Суғурта кунед",
  Miles:"Майлз",
  Payment_Method:"Тариқи пардохт",
  Select_Policies:"Сиёсатҳоро интихоб кунед",
  Policies:"Сиёсат",
  Policy:"Сиёсат",
  No_PayPal_Account:"Ҳисоби PayPal нест",
  No_Policies:"Сиёсати суғурта вуҷуд надорад",
  New_Policy:"Сиёсати нав",
  PayPal_Payment:"Пардохти PayPal",
  PayPal_Payments:"Пардохтҳои PayPal",
  No_Payment_Selected:"Пардохт интихоб нашудааст",
  Sending_Payment_Word:"Лутфан интизор шавед... Ин пардохт фиристода мешавад.",
  Sending_Payments_Word:"Лутфан интизор шавед... Пардохтҳо фиристода мешаванд.",
  No_Payment_Selected_PayPal:"Барои ирсол ягон <a routerLink='/folder/Payments'>пардохти PayPal</a> интихоб нашудааст.",
  Payment_Sent:"Пардохт фиристода шуд",
  PayPal_Payment_Sent:"Ин пардохт бо PayPal фиристода шудааст.",
  Copay:"Копай",
  Dead:"Мурда",
  Alive:"Зинда",
  Not_Dead:"Мурда нест",
  Unclaimed:"Талабнашаванда",
  Attempted:"Кӯшиш кард",
  Deceased:"фавтида",
  Claimed:"даъво кард",
  Unpaid:"Бемузд",
  Sending_Payment:"Интиқоли пардохт",
  Sending_Payments:"Интиқоли пардохтҳо",
  Send_PayPal_Confirmation:"Оё шумо мехоҳед ин транзаксияро бо PayPal фиристед?",
  Send_PayPal_Confirmation_Word:"Барои фиристодани ин транзаксия тугмаи сабзро пахш кунед.",
  Save_Payment_As_Unpaid:"Ин пардохтро ҳамчун пардохтнашуда захира кунед?",
  Payment_Pay_Confirmation_PayPal:"Ин пардохтро ҳамчун пардохта захира кунед?",
  No_Policies_Word:"Ҳоло аввалин <a routerLink='/folder/Postage/New'>Сиёсати суғурта</a>-ро илова кунед.",
  Timesheet_Multiple_Delete_Confirmation:"Шумо мутмаин ҳастед, ки мехоҳед якчанд ҷадвалҳои вақтро нест кунед?",
  Select_Multiple_Timesheets_Prompt:"Ягон ҷадвали вақт интихоб нашудааст. Ақаллан як ҷадвали вақтро интихоб кунед.",
  Select_Multiple_Policies_Prompt:"Ягон сиёсат интихоб нашудааст. Ақаллан як полиси суғуртаро интихоб кунед.",
  Policies_Multiple_Delete_Confirmation:"Шумо мутмаин ҳастед, ки мехоҳед якчанд сиёсатҳоро нест кунед?",
  Company:"Ширкат",
  Add_Company:"Илова кардани ширкат",
  New_Company:"Илова кардани ширкат",
  No_Companies:"Ширкатҳо нест",
  Enable_Company:"Ширкатро фаъол созед",
  Select_Company:"Ширкатро интихоб кунед",
  The_Default_Company:"Нишони пешфарзии ширкат.",
  Manage_Companies:"Ширкатҳоро идора кунед",
  No_Companies_Word:"Чекҳо ширкати пешфарзро истифода мебаранд.<br />Ширкати аввалини <a routerLink='/folder/Company/New'>ро илова кунед</a>.",
  Default_Company:"Ширкати пешфарз",
  Cheques_Unlimited_Word:"Чекҳои бемаҳдуд ба шумо имкон медиҳад, ки шумораи чекҳои дилхоҳатонро чоп кунед.",
  Cheques_Subscription_Word:"Обунаи чекҳо ба шумо имкон медиҳад, ки шумораи чекҳои дилхоҳатонро чоп кунед.",
  You_Own_This_Product:"Шумо соҳиби ин маҳсулот ҳастед.",
  Your_Subscription_is_Active:"Обунаи шумо фаъол аст.",
  Active_Products:"Маҳсулоти фаъолшуда",
  Purchase_Confirmation:"Харид",
  Purchase_Cheques:"Чекҳои харид",
  Restore_Purchase:"Барқарор кардани харидҳо",
  Erase_Purchase:"Харидҳоро нест кунед",
  Successfully_Purchased:"Бомуваффақият харида шуд",
  Enter_Your_Licence_Key:"Лутфан калиди иҷозатномаи худро дар ин саҳифа ворид кунед, то ин маҳсулотро фаъол созед.",
  Licence_Key:"Калиди иҷозатнома",
  Enter_Licence_Key:"Калиди иҷозатномаро ворид кунед",
  Purchased:"харида шудааст",
  Enable_Feature:"Хусусиятро фаъол созед",
  Cancel_Subscription:"Обунаро бекор кунед",
  Subscription_Removed:"Обуна хориҷ карда шуд",
  Select_Active_Subscription:"Барои тағир додани он обунаи фаъолро интихоб кунед.",
  Remove_Subscription_Word:"Шумо мутмаин ҳастед, ки мехоҳед ин обунаро бекор кунед?",
  Delete_Company_Confirmation:"Шумо мутмаин ҳастед, ки мехоҳед ин ширкатро пурра нест кунед? ОГОҲ: Шумо тамоми маълумоти захирашударо гум мекунед!",
  Delete_Default_Company_Word:"Шумо наметавонед ширкати пешфарзро нест кунед. Оё шумо мехоҳед барномаро аз нав танзим кунед ва онро ба ҳолати пешфарз барқарор кунед? ОГОҲ: Шумо тамоми маълумоти захирашударо гум мекунед!",
  Console_Warning_2:"Бо истифода аз ин барнома, ки айни замон аз они шумо нест, ягон асъорро коркард накунед.",
  Terms_and_Conditions:"Шарту Шароит",
  and_the:"ва",
  for:"барои",
  Please_Read_Word:"Лутфан хонед ва розӣ шавед",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Баъзе қурбҳои табдили асъорро ёфтан муяссар нашуд. Лутфан ба интернет пайваст шавед.",
  Free:"Озод",
  DB_Erase_Prompt_2:"Тамоми базаи таҳиягарро пурра нест кунед? Огоҳӣ: Шумо ҳама маълумоти харид ва ОБУНАро гум мекунед!",
  Successfully_Imported:"Бомуваффақият ворид карда шуд",
  Select_Postage:"Почтаро интихоб кунед",
  No_Postage:"Не маркаҳои почта",
  No_Paid_Postage_Word:"Аввалин мӯҳри <a routerLink='/folder/Postage/New'>Почтаи пулакӣ</a> -ро илова кунед.",
  Trial_Complete:"Озмоиш ба итмом расид",
  Please_Upgrade:"Лутфан чекҳоро навсозӣ кунед, то чопро идома диҳед.",
  Aa:"Аа",
  Color:"Ранги",
  Font:"Шрифт",
  Guide:"Роҳнамо",
  Guides:"Роҳнамо",
  Image:"Тасвир",
  Zoom:"Масштаб",
  Logo:"Лого",
  Bank:"Бонк",
  MICR:"MICR",
  Total:"Ҳамагӣ",
  Cancel:"Бекор кардан",
  Confirm:"Тасдиқ кунед",
  Method:"Усул",
  Biometric_Security:"Амнияти биометрӣ",
  Please_Login_To_Continue:"Лутфан ворид шавед, то идома диҳед.",
  Complete:"Анҷом",
  Sign_Up:"Қайд кардан",
  Error:"Хатогӣ",
  Biometrics:"Биометрия",
  Percent:"Фоиз",
  Zero_Percent:"0%",
  Top_Margin:"Маржаи боло",
  Bottom_Margin:"Маржаи поён",
  Left_Margin:"Маржаи чап",
  Right_Margin:"Маржаи рост",
  MICR_Margin:"Маржа MICR",
  Table_Margin:"Маржаи ҷадвал",
  Address_Margin:"Маржаи адрес",
  Percentage_:"Фоиз",
  Vacation_Title:"Унвони истироҳат",
  Use_PIN:"PIN-ро истифода баред",
  Loading__:"Loading...",
  Design_Title:"Унвони тарроҳӣ",
  Authorize:"Иҷозат диҳед",
  Key:"Калид",
  Public_Key:"Калиди ҷамъиятӣ",
  Private_Key:"Калиди хусусӣ",
  Authenticate:"Тасдиқ кардан",
  Last_Payroll:"Маоши охирин",
  Last_Calculation:"Ҳисобкунии охирин",
  Authorized:"Ваколатдор",
  Geo_Authorized:"Ҷойгиршавии ҷуғрофӣ: ваколатдор",
  Not_Authorized:"Иҷозат дода нашудааст",
  Authorization_Complete:"Авторизатсия ба итмом расид",
  Geolocation_Authorization:"Иҷозатномаи ҷойгиршавии ҷуғрофӣ",
  Out_of_Bounds:"Аз ҳудуди",
  Cant_Delete_Default_Design:"Тарҳи пешфарзро нест кардан мумкин нест.",
  Unauthorized_Out_of_Bounds:"Беиҷозат: берун аз ҳудуди",
  Biometric_Authorization:"Иҷозатномаи биометрӣ",
  Locating_Please_Wait:"Ҷойгиршавӣ, Лутфан интизор шавед...",
  Test_Biometrics:"Санҷиши биометрия",
  Cheque_Margins:"Маржаҳоро тафтиш кунед",
  Percentage_Full_Error:"Майдони фоизро аз 100% зиёд таъин кардан мумкин нест.",
  No_Payroll_Text:"Илова кардани <a routerLink='/folder/Employee/New'>Корманд</a> ё <a routerLink='/folder/Payee/New'>Пайгиранда</a> ва <a routerLink='/folder/Schedule/New'>Ҷадвал</a>.",
  Design_Saved:"Тарҳ захира карда шуд",
  Default_Design_Selected:"Тарҳрезии пешфарз интихобшуда",
  Partial_Import:"Воридоти қисман",
  Partial_Export:"Содироти қисман",
  Entire_Import:"Тамоми воридот",
  Entire_Export:"Тамоми содирот",
  Existing_Password:"Лутфан пароли мавҷудаи худро ворид кунед:",
  Existing_PIN:"Лутфан рамзи PIN мавҷудаи худро ворид кунед:",
  Pin_Change_Header:"Тасдиқи PIN",
  Pin_Change_SubHeader:"Рақами PIN-и кӯҳнаи худро ворид кунед, то тағиротро тасдиқ кунед.",
  Pass_Change_Header:"Тасдиқи парол",
  Pass_Change_SubHeader:"Барои тасдиқи тағирот пароли кӯҳнаи худро ворид кунед.",
  PIN_Enter_Message:"Барои тасдиқи PIN-и худро ворид кунед.",
  Password_Enter_Message:"Барои тасдиқ пароли худро ворид кунед.",
  Pin_Updated_Success:"PIN бо муваффақият навсозӣ шуд!",
  Pin_Updated_Fail:"PIN-ро навсозӣ кардан мумкин нест.",
  Pass_Updated_Success:"Рамз бомуваффақият нав карда шуд.",
  Pass_Updated_Fail:"Рамзро навсозӣ кардан мумкин нест.",
  Preview_Payment:"Пешниҳоди пардохт",
  Preview_Payroll:"Пешнамоиши музди меҳнат",
  No_Payments_Created:"Ягон пардохтҳо пайдо нашуд.",
  Payment_Preview:"Пешнамоиши пардохт",
  Enable_Payee:"Пардохтгирандаро фаъол созед",
  Rendered:"Намоиш дода шудааст",
  No_Email:"Почтаи электронӣ нест",
  Setup_Cheques:"Санҷишҳои танзимот",
  name:"Ном",
  address:"Суроға",
  address_2:"Сатри суроға 2",
  city:"Шаҳр",
  province:"вилоят",
  postal_code:"Индекси почта/почта",
  country:"кишвар",
  username:"Номи корбар",
  full_name:"Номи пурра",
  birthday:"Зодруз",
  email:"Почтаи электронӣ",
  phone:"Телефон",
  employees:"Кормандон",
  addresses:"Суроғаҳо",
  payment_amount_limit:"Маҳдудияти маблағи пардохт",
  total_limit:"Маҳдудияти умумӣ",
  payees:"Пардохткунандагон",
  description:"Тавсифи",
  address_line_one:"Суроғаи хатти як",
  address_line_two:"Суроғаи хати дуюм",
  image:"Тасвир",
  account_holder:"Дорандаи ҳисоб",
  cheque_starting_id:"Санҷед, ки ID оғоз",
  transit_number:"Рақами транзитӣ",
  institution_number:"Рақами муассиса",
  designation_number:"Рақами таъинот",
  account_number:"Рақами ҳисобӣ",
  currency:"Асъор",
  signature:"Имзо",
  payment_payroll_limit:"Лимити пардохт",
  total_limi:"Маҳдудияти умумӣ",
  date:"Сана",
  printed_memo:"Ёддошти чопшуда",
  banks:"Бонкхо",
  signature_image:"Тасвири имзо",
  address_name:"Номи суроға",
  notes:"Қайдҳо",
  design:"Тарҳрезӣ",
  title:"Унвон",
  frequency:"Басомад",
  fax:"Факс",
  salaries:"Музди мехнат",
  salary_ids:"Шаҳодатномаҳои музди меҳнат",
  start_time:"Вақти оғоз",
  end_time:"Вақти анҷом",
  paid:"Пардохтшуда",
  overtime_percentage:"Фоизи пардохтшуда",
  overtime_amount:"Маблағи муқарраршудаи пардохтшуда",
  first_name:"Ном",
  last_name:"Насаб",
  moderation:"Модератсия",
  create:"Эҷод кунед",
  edit:"Таҳрир",
  destroy:"Нобуд кардан",
  day_start_time:"Вақти_оғози_рӯз",
  day_end_time:"Вақти_охири_рӯз",
  fullname:"Ном",
  time:"Вақт",
  auto_send:"Ба таври худкор ирсол кунед",
  time_method:"Усули вақт",
  schedules:"Ҷадвалҳо",
  indefinite_payroll_enabled:"Ба таври номуайян фаъол кунед",
  amount:"Маблағ",
  repeat:"Такрор кунед",
  always_enabled:"Ҳамеша фаъол",
  start_date:"Саршавии Сана",
  end_date:"Санаи анҷом",
  Cheque_Total:"Ҳама чизро тафтиш кунед",
  Total_Amount:"Ҳамагӣ:",
  Amounts:"Маблағҳо:",
  Images:"Тасвирҳо",
  Files:"Файлҳо",
  Previewing:"Пешнамоиш:",
  Insert:"Ворид кунед",
  Preview_Import:"Пешнамоиши воридот",
  Entry:"Вуруд",
  Entries:"Вурудҳо",
  No_Entries:"Вуруд нест",
  Import_Type:"Навъи воридот",
  Select_Details:"Тафсилотро интихоб кунед",
  Select_Payee:"Пардохткунандаро интихоб кунед",
  Enable_Holidays:"Рӯзҳои истироҳатро фаъол созед",
  Disable_Holidays:"Рӯзҳои истироҳатро хомӯш кунед",
  Wire_Transfer:"Фиристодани хаттии пул",
  New_Export:"Содироти нав",
  Export_Data:"Содироти маълумот",
  Export_Data_Word:"Навъи файлро барои содирот ва зеркашӣ интихоб кунед.",
  Export_File:"Файли содиротӣ",
  Mode:"Режим",
  Times:"Вақтҳо",
  Widgets:"Виҷетҳо",
  Slider:"Слайдер",
  Set_Details:"Тафсилотро танзим кунед",
  Select_Type:"Навъи интихоб кунед",
  Display_Slider:"Намоиши слайдер",
  Dashboard_Slider:"Слайдер панели идоракунӣ",
  Dashboard_Mode:"Ҳолати панели идоракунӣ",
  Show_Intro:"Намоиши Intro",
  Show_Payrolls:"Нишон додани музди меҳнат",
  Show_Holidays:"Намоиши идҳо",
  Show_Invoices:"Нишон додани ҳисобнома-фактураҳо",
  Show_Cheques:"Нишон додани чекҳо",
  Enabled_Widgets:"Виҷетҳои фаъол",
  Disabled_Widgets:"Виҷетҳои ғайрифаъол",
  Colors:"Рангҳо",
  Barcodes:"Штрих-кодҳо",
  View_Timers:"Намоиши таймерҳо",
  Gradients:"Градиентҳо",
  No_Info:"Маълумот нест",
  Disable:"Хомӯш кардан",
  Show_Layer:"Намоиши қабатҳо",
  Hide_Layer:"Пинҳон кардани қабатҳо",
  Text_Layer:"Қабатҳои матн",
  Secondly:"Дуюм",
  Minutely:"Дақиқаҳо",
  nine_am:"9:00",
  five_pm:"17:00",
  Enable_Address:"Суроғаро фаъол кунед",
  Invalid_File_Type:"Бубахшед, навъи файли нодуруст интихоб карда шуд. Навъи файли дастгирӣ:",
  Error_Updating_Entry:"Бубахшед, ҳангоми навсозии ин сабт хатогӣ рух дод.",
  Schedule_Timing_Alert:"Хатогӣ: Вақти оғози ҷадвал ба арзиши пас аз вақти анҷомёбӣ муқаррар карда шудааст.",
  Select_Multiple_Payments_Prompt:"Ягон пардохт интихоб нашудааст. Ақаллан як пардохтро интихоб кунед.",
  Select_Multiple_Cheques_Prompt:"Ягон чек интихоб нашудааст. Лутфан ақаллан як чекро интихоб кунед.",
  Select_Multiple_Items_Prompt:"Ягон ашё интихоб нашудааст. Лутфан ақаллан як ашёро интихоб кунед.",
  Paymemt_Multiple_Delete_Confirmation:"Шумо мутмаин ҳастед, ки мехоҳед пардохтҳои сершуморро нест кунед?",
  Cheque_Multiple_Delete_Confirmation:"Шумо мутмаин ҳастед, ки мехоҳед чекҳои сершуморро нест кунед?",
  Payee_Multiple_Delete_Confirmation:"Шумо мутмаин ҳастед, ки мехоҳед пардохткунандагони сершуморро нест кунед?",
  Employee_Multiple_Delete_Confirmation:"Шумо мутмаин ҳастед, ки мехоҳед коргарони сершуморро нест кунед?",
  MICR_Warning:"Эзоҳ: Баъзе принтерҳо ва дастгоҳҳо метавонанд шрифти MICR-ро дуруст намоиш надиҳанд.",
  Automatically_Send:"Ба таври худкор ирсол кунед",
  Type:"Навъи",
  Payment_Type:"Навъи пардохт",
  Auto_Send:"Ирсоли худкор",
  Automatically_Process:"Ба таври худкор коркард",
  Auto_Process:"Раванди худкор",
  Image_Based:"Дар асоси тасвир",
  Font_Based:"Ба ҳарф асос ёфтааст",
  Rerender:"Аз нав намоиш диҳед",
  Rendering:"Намоиш",
  Render:"Файлҳо",
  Top:"боло",
  Middle:"Миёна",
  Bottom:"Поён",
  Top_Left:"Болои чап",
  Top_Center:"Маркази боло",
  Top_Right:"Аз болои рост",
  Middle_Left:"Чапи миёна",
  Middle_Center:"Маркази миёна",
  Middle_Right:"Миёнаи рост",
  Bottom_Left:"Поёни чап",
  Bottom_Center:"Маркази поён",
  Bottom_Right:"Поёни рост",
  Numbers:"Рақамҳо",
  Verified:"Тасдиқ карда шуд",
  Paper_Size:"Андозаи коғаз",
  New_Device:"Дастгоҳи нав",
  Add_Device:"Иловаи Дастгоҳ",
  Remove_Device:"Дастгоҳро хориҷ кунед",
  Delete_Device:"Дастгоҳро нест кунед",
  Block_Device:"Блоки дастгоҳ",
  Block:"Блок",
  Unblock:"Кушодан",
  Table:"Ҷадвал",
  Scan_Login_Code:"Рамзи воридшавиро скан кунед",
  Login_Code:"Рамзи воридшавӣ",
  Scan_Code:"Рамзи скан",
  Scan_QR_Code:"Коди QR-ро скан кунед",
  Select_All:"Ҳамаро интихоб кунед",
  Deselect_All:"Интихоби Ҳама",
  Increase:"Афзоиш",
  Decrease:"Кам кардан",
  Bold:"Далер",
  Text:"Матн",
  Style:"Услуб",
  Italic:"Курсив",
  QR_Code:"Кодекси QR",
  Barcode:"Штрих-код",
  Browse_Images:"Мурури тасвирҳо",
  Browse_Files:"Файлҳоро аз назар гузаронед",
  Background_Image:"Тасвири замина",
  Logo_Image:"Тасвири логотип",
  Header_Image:"Тасвири сарлавҳа",
  Bank_Image:"Тасвири бонк",
  Cut_Lines:"Хатҳои бурида",
  Background:"Замина",
  License:"Иҷозатнома",
  One_License:"1 Литсензия:",
  Licensed:"Иҷозатнома дода шудааст:",
  Not_Licensed:"Иҷозатнома дода нашудааст",
  Enter_Serial:"Силсиларо ворид кунед",
  Serial_Key:"Калиди силсилавӣ",
  Serial:"Силсила",
  Product_Key:"Калиди маҳсулот",
  Check_Product_Key:"Калиди маҳсулотро тасдиқ кунед",
  Add_Product_Key:"Калиди Маҳсулотро ворид кунед",
  Verifying_Purchase:"Тасдиқи харид...",
  Print_Envelope:"Лифофро чоп кунед",
  Envelope:"Конверт",
  Thank_You:"Сипос!",
  Scale:"Миқёс",
  Print_Scale:"Миқёси чоп",
  Borders:"Сарҳадҳо",
  VOID:"БЕТОР",
  Void_Cheque:"Санҷиши беэътибор",
  NO_DOLLARS:"ДОЛЛАР НЕСТ",
  ONE_DOLLAR:"ЯК ДОЛЛАР",
  DOLLARS:"ДОЛЛАР",
  AND:"ВА",
  CENTS:" СЕНТ.",
  NO_:"НЕ",
  ONE_:"ЯК",
  AND_NO_:"ВА НЕ",
  _AND_ONE_:"ВА ЯК",
  DOLLARS_AND_ONE_CENT:"ВА ЯК СЕНТ.",
  AND_NO_CENTS:"ВА СУЛ СЕНТ.",
  CHEQUE_PRINT_DATE:"САНА:",
  CHEQUE_PRINT_MEMO:"Ёддошт:",
  CHEQUE_PRINT_SIGNATUREMP:"депутат",
  Initial_Tasks:"Вазифаҳои ибтидоӣ",
  Inital_Setup:"Танзимоти ибтидоӣ",
  Welcome_To:"Хуш омадед ба",
  Welcome:"Хуш омадед",
  Swipe:"Лағжед",
  Intro_Setup:"Танзимоти муқаддима",
  Introduction:"Муқаддима",
  CHEQUE_PRINT_CREDIT:"Бо чекҳо таъмин карда мешавад",
  Title_Intro_Word:"Хуш омадед ба Checks",
  Title_Intro:"Санҷишҳо Муқаддима",
  Title_Setup:"Танзимотро тафтиш мекунад",
  PayPal_Default_Email_Message:"Шумо интиқоли нави PayPal гирифтаед.",
  Cheques_App_Export:"Бо чекҳо содир карда мешаванд",
  No_Prints_Purchased:"Ягон чоп харида нашудааст",
  No_Prints_Purchased_Word:"Ягон чопи иловагӣ дастрас нест. Лутфан чекҳои иловагӣ харед.",
  Post_Intro_Thanks:"Ташаккур ба шумо барои интихоби чекҳо. Раванди танзим ҳоло ба анҷом расид.",
  Post_Intro_Word:"Аз чопи чеки аввалини худ оғоз кунед, пардохти ояндаро илова кунед ё ба варақаи музди кор кормандон илова кунед.",
  Upgrade_Required:"Санҷишҳо аз шумо талаб мекунанд, ки версияи беҳтарини нармафзорро дошта бошед, то ин паёмро пинҳон кунед ва хусусиятҳои иловагиро кушоед.",
  Upgrade_Title:"чекхо",
  Mailout_Prompt:"Шумо инчунин метавонед интихоб кунед, ки чекҳои музди меҳнати худро барои шумо тавассути почтаи электронӣ интиқол диҳед.",
  Mailed_Cheque:"Санҷиши почта",
  Mailed_Cheque_Color:"Чеки почта (ранга)",
  Terms_Purchase:"Ҳама харидҳои электронӣ бо чекҳо дар давоми то 30 рӯз аз санаи харид пурра баргардонида мешаванд. Лутфан хонед ва розӣ шавед",
  Dashboard_Setup:"Принтери ибтидоиро насб кунед",
  Dashboard_Add:"Ҳисоби ибтидоии бонкиро илова кунед",
  Dashboard_Customize:"Шаблони санҷишро интихоб кунед",
  Dashboard_Job_Salary:"Кори худро эҷод кунед ва маоши худро илова кунед",
  Dashboard_Employees:"Кормандон ва пардохтгирандагонро пайгирӣ кунед",
  Dashboard_Print:"Маоши худро чоп кунед ва фиристед",
  Dashboard_Payroll:"Чопи музди меҳнати худро автоматӣ кунед",
  Dashboard_PayPal:"Пардохти PayPal / Пардохтҳоро насб кунед",
  Begin_Setup:"Танзимотро оғоз кунед",
  Get_Started:"Сар кардан",
  Purchase:"Харид",
  Lockdown:"Қулф кардан",
  Unlock:"Кушодан",
  Detailed:"Муфассал",
  Log_In:"Даромадан",
  Login:"Даромадан",
  Launch:"Оғози",
  Register:"Бақайдгирӣ",
  Finish:"Анҷом",
  List:"Рӯйхат",
  Weekends:"Рузхои истирохат",
  Weekly:"Ҳар ҳафта",
  PayPal_Default_Subject:"Пардохти нав",
  Payment_Message:"Паёми пардохт",
  PayPal_Default_Payment_Note:"Сипос",
  Setup_Your_Cheqing_Account:"Ҳисоби чек",
  Add_Your_Primary_Cheqing_Account:"Ҳисоби чекии ибтидоии худро илова кунед.",
  Welcome_Word:"Содда ва автоматикунонии музди меҳнат ва идоракунии захираҳои инсонӣ.",
  Get_Started_Quickly:"Танҳо ба чанд саволи оддӣ ҷавоб диҳед, ки ба мо дар оғоз кардан кӯмак мекунанд...",
  Done_Intro_Word:"Насбкунӣ анҷом ёфт",
  PIN_Protected:"Рамз ва PIN ҳифз карда шудааст",
  Enter_New_PIN:"Рамзи нави PIN-ро ворид кунед:",
  Enter_PIN:"Рамзи PIN-ро ворид кунед:",
  Invalid_PIN:"ПИН-коди нодуруст ворид карда шуд.",
  Account_Identifier:"Идентификатори ҳисоб",
  New_Account_Identifier:"Муайянкунандаи ҳисоби нав",
  attempt:"кушиш",
  attempts:"кушишхо",
  remaining:"боқимонда",
  Language:"Забон",
  languages:"Забонҳо",
  select_languages:"Забонро интихоб кунед",
  Deposit:"Амонат",
  Hire_One_Now:"Ҳоло як нафарро киро кунед",
  App_Locked:"Барнома қулф шудааст.",
  Skip_:"Гузаштан",
  Skip_to_Dashboard:"Ба панели идоракунӣ гузаред",
  Dashboard:"Панели асбобҳо",
  Import:"Воридот",
  Admin:"Маъмурон",
  New_Admin:"Админи нав",
  Settings:"Танзимотҳо",
  Color_Picker:"Интихобкунандаи ранг",
  Font_Picker:"Интихобкунандаи шрифт",
  Logout:"Баромадан",
  Close:"Пӯшед",
  Close_menu:"Пӯшед",
  Excel:"Файли Excel",
  Csv:"Файли CSV",
  Sql:"Файли SQL",
  Json:"Файли JSON",
  Url:"Воридот тавассути URL",
  Back:"Бозгашт",
  Timers:"Таймерҳо",
  Cheque:"Санҷед",
  Print:"Чоп кардан",
  Designs:"Тарҳҳо",
  Pause_Printing:"Таваққуфи чоп",
  Resume_Printing:"Давом додани чоп",
  Printing_Paused:"Чоп таваққуф карда шуд",
  PrintingUnavailable:"Бубахшед! Дар ин система чоп кардан ғайриимкон аст.",
  Prints_Paused:"Чопҳо таваққуф карда шуданд",
  Administration:"Маъмурият",
  Loading:"Бор карда мешавад",
  Unnamed:"Бе ном",
  error:"Мутаасифона, ин чекро барои дидан кушода натавонист.",
  No_Cheques_To_Print:"Не чекҳо барои чоп",
  No_Cheques_To_Print_Word:"<a routerLink='/folder/Cheque/New'>Чеги нав</a> эҷод кунед.",
  New_Cheque:"Санҷиши нав",
  Start_One_Now:"Яке аз ҳозир оғоз кунед",
  Edit_Cheque:"Санҷиши таҳрир",
  Select_Cheques:"Чекҳоро интихоб кунед",
  Select_Employee:"Кормандро интихоб кунед",
  Default_Printer:"Принтери пешфарз",
  Reassign:"Аз нав таъин кунед",
  Configure:"Танзим кунед",
  Template:"Шаблон",
  Designer:"Дизайнер",
  Edit_Designs:"Таҳрири тарҳҳо",
  New_Design:"Тарҳрезии нав",
  Next:"Баъдӣ",
  Save:"Захира кунед",
  Name:"Ном",
  Mail:"Почта",
  Amount:"Маблағ",
  Date:"Сана",
  PayPal:"PayPal",
  Payouts:"Пардохтҳо",
  PayPal_Label:"Нишони PayPal",
  Email_Username:"Почтаи электронӣ / Номи корбар",
  Client_ID:"ID мизоҷ",
  Sandbox_Email:"Email Sandbox",
  PayPal_Email:"Email PayPal",
  PayPal_Username:"Номи корбари API",
  PayPal_Payouts:"Пардохтҳои PayPal",
  Select_PayPal_Key:"Калиди PayPal -ро интихоб кунед",
  Secret:"Сирри",
  API_Secret:"Сирри API",
  PayPal_API_Keys:"Калидҳои PayPal",
  New_PayPal_Key:"Калиди нави PayPal",
  Email_Subject:"Мавзӯи почтаи электронӣ",
  Email_Message:"Паёми почтаи электронӣ",
  Payout_Options:"Имконоти пардохт",
  Payment_Note:"Эзоҳ пардохт",
  Enable_Employee:"Кормандро фаъол созед",
  Enable_Production_Mode:"Ҳолати истеҳсолиро фаъол созед",
  Sandbox_Username:"Номи корбари Sandbox",
  Sandbox_Signature:"Имзои Sandbox",
  Sandbox_Password:"Рамзи Sandbox",
  Production_Username:"Номи корбари истеҳсолот",
  Production_Signature:"Имзои истеҳсолот",
  Production_Password:"Рамзи истеҳсолот",
  Production_Email:"Почтаи электронии истеҳсолӣ",
  API_Client_ID:"ID муштарии API",
  API_Signature:"Имзои API",
  API_Password:"Рамзи API",
  API_Username:"Номи корбари API",
  Secret_Key:"Калиди махфӣ",
  Sandbox:"Қум қуттии",
  Production:"Истехсолот",
  Sandbox_Keys:"Калидҳои қуттӣ",
  Production_Keys:"Калидҳои истеҳсолӣ",
  API_Title:"Сарлавҳаи API",
  Production_Mode:"Реҷаи истеҳсолот",
  Account_Label:"Нишони ҳисоб",
  No_PayPal_Setup:"Калиди PayPal нест",
  Enable_PayPal_Account:"Ҳисоби PayPal-ро фаъол созед",
  No_PayPal_Word:"<a routerLink='/folder/Invoice/New'>Калиди API-и PayPal</a>-и худро илова кунед.",
  Printed_Memo:"Ёддошти чопшуда",
  Employee:"Корманд",
  View_Employee:"Намоиши корманд",
  Mailing_Address:"Суроғаи почта",
  Company_Address:"Суроғаи ширкат",
  Select_Company_Address:"Суроғаи ширкатро интихоб кунед",
  Address:"Суроға",
  Any_Day:"Ҳар рӯз",
  Address_Name:"Номи суроға",
  Unit:"Воҳиди",
  Account:"Ҳисоб",
  Bank_Account:"Ҳисоби бонкӣ",
  Account_Limits:"Маҳдудиятҳои ҳисобро фаъол созед",
  Payroll:"Маош",
  Run:"Давидан",
  Run_Payroll:"Иҷрои музди меҳнат",
  New_Payroll:"Маоши нав",
  No_Payroll:"Маоши дарпешистода нест",
  Upcoming_Holiday:"Иди дарпешистода:",
  Invoice_Due:"Мӯҳлати ҳисобнома-фактура:",
  New_Invoice:"Ҳисобнома-фактураи нав",
  No_Invoices:"Ҳисобнома-фактураҳо нест",
  No_Invoices_Word:"Аввалин <a routerLink='/folder/Invoice/New'>Ҳисобнома-фактура</a>-ро эҷод кунед.",
  Cheque_Due:"Мӯҳлати тафтиш:",
  Payrolls:"Музди меҳнат",
  Sandbox_Mode:"Ҳолати қуттии қуттӣ",
  Hire:"киро кардан",
  Pay:"Пардохт",
  New:"Нав",
  Add:"Илова",
  Make:"созед",
  Time:"Вақт",
  Write:"нависед",
  Holiday:"Ид",
  Holidays:"Идҳо",
  Next_Holiday:"Иди оянда:",
  All_Done:"Ҳама иҷро шуд!",
  Employees:"Кормандон",
  Payees:"Пардохткунандагон",
  Job:"Кор",
  Jobs:"Ҷойҳо",
  Invoice:"Ҳисобнома-фактура",
  Invoices:"Ҳисобнома-фактураҳо",
  Vacations:"Таътилҳо",
  Cheques:"чекхо",
  Brand_Cheques:"Бренди шумо",
  Payment:"Пардохт",
  Enable_Payment:"Пардохтро фаъол созед",
  Payments:"Пардохтҳо",
  Schedule:"Ҷадвал",
  Schedules:"Ҷадвалҳо",
  Timesheet:"Варақаи вақт",
  Timesheets:"Варақаҳои вақт",
  Salary:"Музди мехнат",
  New_Address:"Суроғаи нав",
  Address_2:"Суроға (сатри 2)",
  _City:"Шаҳр",
  _State:"Давлат/Мас",
  City:"Шаҳр / Шаҳр",
  State:"Давлат / Вилоят",
  Postal:"Индекси почта / почта",
  ZIP:"Почта / ZIP",
  Country:"кишвар",
  Addresses:"Суроғаҳо",
  Required_Options:"Имконоти зарурӣ",
  Optional_Options:"Имконоти ихтиёрӣ",
  Additional_Options:"Имконоти иловагӣ",
  Required:"Талаб карда мешавад",
  Optional:"Ихтиёрӣ",
  Additional:"Илова",
  No_Addresses:"Суроғаҳо нест",
  New_Address_Word:"Аввалин <a routerLink='/folder/Address/New'>Адрес</a>-ро илова кунед.",
  Select_Address:"Суроғаро интихоб кунед",
  No_Address:"Суроғаҳо нест",
  Print_Cheque:"Санҷиши чоп",
  Print_Cheques:"Чекҳои чоп",
  Print_Cheque_Now:"Санҷиши ҳозирро чоп кунед",
  Description:"Тавсифи",
  Pay_To_The_Order_Of:"Ба фармоиш пардохт кунед:",
  Select_Date_Range:"Диапазони санаро интихоб кунед",
  Select_Starting_Date:"Санаи оғозро интихоб кунед",
  Select_Ending_Date:"Санаи хотимаро интихоб кунед",
  Select_Date:"Сана-ро интихоб кунед",
  Cheque_Date:"Санаи тафтиш",
  Cheque_Memo:"Ёддоштро тафтиш кунед",
  Blank_Cheque:"Санҷиши холӣ",
  Blank:"Холӣ",
  No_Cheques:"Не чекҳо",
  No_Cheques_Word:"Аввалин <a routerLink='/folder/Cheque/New'>Чеги</a>-и худро чоп кунед.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"Намоиши тасвир",
  View:"Намоиш",
  Modify:"Тағйир додан",
  Delete:"Нобуд кунед",
  Cheque_Paid:"Пардохтшуда",
  New_Deduction:"Тарҳи нав",
  Title:"Унвон",
  Frequency:"Басомад",
  Hourly:"Соатӣ",
  Daily:"Ҳар рӯз",
  Weekdays:"Рӯзҳои корӣ",
  BiWeekly:"2 ҳафта",
  TriWeekly:"3 ҳафта",
  Monthly:"Ҳармоҳа",
  MiMonthly:"2 моҳ",
  Quarterly:"Ҳар семоҳа",
  Yearly:"солона",
  Every_Week:"Ҳар ҳафта",
  Every_Payroll:"Ҳар як музди меҳнат",
  Every_Month:"Ҳар моҳ",
  Annually:"Хар сол",
  Always_Scheduled:"Ҳамеша ба нақша гирифта шудааст",
  Start_Date:"Саршавии Сана",
  End_Date:"Санаи анҷом",
  Start_Time:"Вақти оғоз",
  End_Time:"Вақти анҷом",
  Deduction_Label:"Нишони тарҳ",
  Notes:"Қайдҳо",
  Options:"Имконот",
  Enable:"Фаъол кардан",
  Select_Deductions:"Тарҳҳоро интихоб кунед",
  Deductions:"Тарҳҳо",
  No_Deductions:"Тарҳҳо",
  No_Deductions_Word:"Аввалин <a routerLink='/folder/Deduction/New'>Тарҳ</a>-и худро эҷод кунед.",
  New_Employee:"Корманди нав",
  No_Title:"Унвон нест",
  _Name:"Ном",
  About_Yourself:"Дар бораи худ",
  Full_Name:"Номи пурра",
  Birthday:"Зодруз",
  Email:"Почтаи электронӣ",
  SMS:"СМС",
  Phone:"Телефон",
  Test:"Санҷиш",
  Call:"Занг занед",
  Fax:"Факс",
  Printed_Note:"Эзоҳ чопшуда",
  Position:"Мавқеъ",
  Job_Position:"Вазифаи кор",
  Select_a_Job:"Корро интихоб кунед",
  Select_a_Salary:"Музди меҳнатро интихоб кунед",
  Add_a_Deduction:"Тарҳ илова кунед",
  Taxes:"Андозҳо",
  Add_Taxes:"Илова кардани андозҳо",
  Date_of_Birth:"Санаи таваллуд",
  Email_Address:"Суроғаи имэйл",
  Phone_Number:"Рақами мобилӣ",
  Phone_Call:"Занги телефон",
  Enable_on_Payroll:"Дар бораи музди меҳнат фаъол кунед",
  Select_Employees:"Кормандонро интихоб кунед",
  No_Employees:"Кормандон нест",
  No_Employees_Word:"Аввалин нави <a routerLink='/folder/Employee/New'>Корманд</a>-и худро илова кунед.",
  No_Name:"Ном нест",
  Unemployeed:"бекор",
  Employeed:"Ба кор даромад",
  Paid:"Пардохтшуда",
  Enabled:"Фаъол",
  Disabled:"Маъюб",
  Fire:"Оташ",
  Not_Available:"Дастрас нест",
  Not_Available_Word:"Аввалин <a routerLink='/folder/Invoice/New'>Ҳисобнома-фактура</a>-ро чоп кунед ва пардохт кунед.",
  Select_Invoices:"Ҳисобномаҳоро интихоб кунед",
  Print_Invoice_Word:"Аввалин <a routerLink='/folder/Invoice/New'>Ҳисобнома-фактура</a>-ро чоп кунед ва пардохт кунед.",
  Invoice_Title:"Номи фактура",
  Invoice_Date:"Санаи ҳисобнома-фактура",
  Due_Date:"Мӯҳлати пардохт",
  New_Job:"Кори нав",
  Details:"Тафсилот",
  Customize:"Мутобиқсозӣ",
  Customize_Dashboard:"Панели дастгоҳро танзим кунед",
  Components:"Компонентхо",
  No_Components:"Не ҷузъҳо",
  Main_Components:"Қисмҳои асосӣ",
  Smaller_Components:"Компонентҳои хурдтар",
  Error_Loading_Page:"Хатогӣ ҳангоми боркунии ин саҳифа.",
  Bank_Details:"Тафсилоти бонк",
  About_Your_Job:"Дар бораи кори шумо",
  Your_Schedule:"Ҷадвали шумо",
  Job_Title:"Вазифа",
  Job_Description:"Тавсифи кор",
  Job_Details:"Тафсилоти кор",
  Enable_Job:"Корро фаъол созед",
  Pay_Period:"Мӯҳлати пардохт",
  Perpetually_Schedule:"Нақшаи доимӣ",
  Select_Jobs:"Корҳоро интихоб кунед",
  No_Jobs:"Ҷой нест",
  Add_Jobs:"Илова кардани ҷойҳои корӣ",
  No_Jobs_Word:"Аввалин <a routerLink='/folder/Job/New'>Кор</a>-ро ба рӯйхат илова кунед.",
  Count_Employees:"job.employee_count+' Кормандон'",
  Zero_Employees:"0 Кормандон",
  New_Leave:"Рухсатии нав",
  Leave_Name:"Номро тарк кунед",
  Paid_Leave:"Рухсатии пулакӣ",
  Leave_Pay:"Пардохтро тарк кунед",
  Indefinite_Leave:"Рухсатии номуайян",
  Indefinite_Payroll:"Музди меҳнати номуайян",
  Leave:"тарк кунед",
  Add_Schedules:"Илова кардани ҷадвалҳо",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Набуданиро фаъол созед",
  Select_Leaves:"Баргҳоро интихоб кунед",
  No_Leaves:"Бе рухсатии кор",
  Leave_Of_Absence:"Рухсатии аз кор рафтан",
  Leaves_Of_Absence:"Баргҳои ғоибона",
  New_Leave_of_Absense:"Рухсатии нави тахеил",
  No_Leaves_Word:"Аввалин <a routerLink='/folder/Leave/New'>Рӯххати ғоибона</a>-ро илова кунед.",
  Not_Enabled:"Фаъол нест",
  Absences:"Прогулхо",
  Payee:"Пардохткунанда",
  New_Payee:"Пардохти нав",
  Payee_Identifier:"Муайянкунандаи пардохткунанда",
  Payee_Name:"Номи пардохткунанда",
  Payee_Title:"Унвони пардохткунанда",
  Payment_Memo:"Ёддошти пардохт",
  Select_Payees:"Пардохткунандагонро интихоб кунед",
  No_Payees:"Пардохткунанда нест",
  Add_Payee_Note:"Аввалин <a routerLink='/folder/Payee/New'>Пайгиранда</a>-ро илова кунед.",
  New_Payees:"Пардохтгирандагони нав",
  About_The_Payment_Schedule:"Ҷадвали пардохт",
  Your_Payroll_Schedule:"Пардохти автоматӣ",
  New_Payment:"Пардохти нав",
  Identifier:"Идентификатор",
  Selected:"Интихобшуда",
  Select:"Интихоб кунед",
  Memo:"Ёддошт",
  Payment_Date:"Санаи пардохт",
  Mark_as_Paid:"Ҳамчун пардохт қайд кунед",
  Select_Payments:"Пардохтҳоро интихоб кунед",
  No_Payments:"Бе пардохтҳо",
  No_Payments_Word:"Аввалин <a routerLink='/folder/Payment/New'>Пардохт</a>-ро эҷод кунед.",
  Create_Payroll:"Эҷоди музди меҳнат",
  Properties:"Хосиятҳо",
  Payroll_Title:"Унвони музди меҳнат",
  Payroll_Notes:"Қайдҳои музди меҳнат",
  Payroll_Setup:"Танзими музди меҳнат",
  Tabulate_Payments:"Ҷадвали пардохтҳо",
  Tabulate:"Ҷадвал",
  By:"Аз ҷониби:",
  Payments_By:"Пардохтҳо аз ҷониби",
  Timesheets_And_Schedules:"Ҷадвалҳо ва ҷадвалҳои вақт",
  Both:"Ҳарду",
  Items:"Ададҳо",
  Add_Payees:"Пардохткунандагонро илова кунед",
  Add_Account:"Иловаи ҳисоб",
  Enable_Account:"Ҳисобро фаъол созед",
  Enable_Payroll:"Маошро фаъол созед",
  Print_Payroll:"Маошро чоп кунед",
  No_Payrolls:"Маош нест",
  No_Payroll_Word:"Аввалин <a routerLink='/folder/Payroll/New'>Пайроли</a>-и худро эҷод кунед.",
  View_more:"VIEW_MORE",
  Less:"КАМТАР",
  Add_Payroll:"Иловаи музди меҳнат",
  Select_Payroll:"Маошро интихоб кунед",
  About_Your_Salary:"Дар бораи музди худ",
  Add_Salaries:"Иловаи музди меҳнат",
  Add_Salary:"Иловаи музди меҳнат",
  Salaries:"Музди мехнат",
  No_Salaries:"Маош нест",
  No_Salaries_Word:"Аввалин <a routerLink='/folder/Salary/New'>Маош</a>-ро илова кунед.",
  Select_Salaries:"Маошҳоро интихоб кунед",
  New_Salary:"Музди нав",
  Salary_Name:"Муайянкунандаи музди меҳнат",
  Enable_Salary:"Музди меҳнатро фаъол созед",
  Salary_Amount:"Маблағи музди меҳнат",
  New_Schedule:"Ҷадвали нав",
  Schedule_Title:"Сарлавҳаи ҷадвал",
  Add_Address:"Иловаи суроға",
  Repeat:"Такрор кунед",
  Design:"Тарҳрезӣ",
  Edit_Design:"Таҳрири дизайн",
  Edit_this_Design:"Ин тарҳро таҳрир кунед",
  Repeat_Payment:"Пардохти такрорӣ",
  Enable_Schedule:"Ҷадвалро фаъол созед",
  Never:"Ҳеҷ гоҳ",
  Select_Schedule:"Ҷадвалҳоро интихоб кунед",
  No_Schedules:"Нақшаҳо",
  No_Schedules_Word:"Аввалин <a routerLink='/folder/Schedule/New'>Ҷадвал</a>-ро эҷод кунед.",
  New_Administrator:"Администратори нав",
  Username:"Номи корбар",
  First_Name:"Ном",
  Last_Name:"Насаб",
  Add_an_Address:"Иловаи суроға",
  Payment_Limit:"Маҳдудияти ҳар як пардохт",
  Total_Limit:"Маҳдудияти умумӣ",
  Select_Accounts:"Ҳисобҳо-ро интихоб кунед",
  No_Administrators:"Администраторҳо нест",
  No_Administrators_Word:"Аввалин <a routerLink='/folder/Administrator/New'>Ҳисоби администратор</a>-ро эҷод кунед.",
  New_Administrators_Word:"Аввалин <a routerLink='/folder/Administrator/New'>Администратор</a>-ро илова кунед",
  Cloud:"Абр",
  Backup:"Нусхаи эҳтиётӣ",
  Enable_iCloud:"iCloud-ро фаъол созед",
  Enable_Google_Drive:"Google Drive-ро фаъол созед",
  Enable_Microsoft_OneDrive:"Microsoft OneDrive-ро фаъол созед",
  Automatically_Backup:"Ба таври худкор нусхабардорӣ",
  FTP_Settings:"Танзимоти FTP",
  URL_File_Prompt:"Лутфан макони файли .xls / .xlsx / .json барои воридотро муайян кунед:",
  URL_SQL_Prompt:"Лутфан макони файли SQLite-ро барои воридот муайян кунед:",
  FTP_Backup:"Нусхаи эҳтиётии FTP",
  FTP_Import:"Воридоти FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Мизбон",
  Port:"Порт",
  Path:"Роҳ",
  Data_Path:"Роҳи маълумот",
  Enable_FTP_Account:"Ҳисоби FTP-ро фаъол созед",
  HostnameIP:"Номи мизбон",
  Password:"Рамз",
  Connection_Port:"Порти пайвастшавӣ",
  Enable_MySQL_Database:"Database MySQL-ро фаъол созед",
  Log:"Сабти ном",
  Reset:"Бозсозӣ",
  Erase:"Тоза кардан",
  Export:"Содирот",
  Database:"Пойгоҳи додаҳо",
  Upload_CSV:"CSV-ро бор кунед",
  Download_CSV:"CSV-ро зеркашӣ кунед",
  SQL_Database:"URL SQL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Захираи MySQL",
  Internal_Notes:"Қайдҳои дохилӣ",
  Root_Path:"Роҳи реша",
  Select_Backup:"Нусхабардорӣ-ро интихоб кунед",
  Add_New_Backup:"Нусхаи нав илова кунед",
  First_Backup:"Нусхаи аввалинро насб кунед ...",
  Backups:"Нусхаҳои эҳтиётӣ",
  Add_Backup:"Иловаи нусхабардорӣ",
  No_Backups:"Нусхаҳои захиравӣ пайдо намешаванд.",
  Select_Backup_Type:"Навъи нусхаеро, ки мехоҳед насб кунед...",
  Backup_Type:"Навъи нусхабардорӣ",
  FTP_Drive:"Drive FTP",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Ронанда",
  Microsoft_OneDrive:"Ронанда",
  Import_Fields:"Майдонҳои воридотӣ",
  Import_Fields_Word:"Барои интихоб кардани маълумоти <a routerLink='/folder/Holiday/New'>Воридоти</a> ин бахшро истифода баред.",
  Upload:"Бор кунед",
  Download:"Зеркашӣ кунед",
  Download_JSON:"Ҳамчун маълумоти JSON зеркашӣ кунед",
  Download_SQL:"Ҳамчун файли SQL зеркашӣ кунед",
  New_Bank:"Бонки нав",
  New_Account:"Ҳисоби нав",
  New_Bank_Account:"Ҳисоби нави бонкӣ",
  Upload_Image:"Тасвирро бор кунед",
  Bank_Name:"Номи бонк",
  Bank_Address:"Суроғаи бонк",
  Branch_Address:"Суроғаи филиал",
  Address_on_Cheque:"Суроға",
  Cheque_Numbers:"Рақамҳоро тафтиш кунед",
  Cheque_Details:"Тафсилотро тафтиш кунед",
  Bank_Logo:"Логотипи бонк",
  Cheque_ID:"Санҷед ID",
  Starting_Cheque_ID:"Оғози санҷиши ID",
  Transit_Number:"Рақами транзитӣ",
  Institution_Number:"Рақами муассиса",
  Designation_Number:"Рақами таъинот",
  Account_Number:"Рақами ҳисобӣ",
  Limits:"Маҳдудиятҳо",
  Default_Limits:"Маҳдудиятҳои пешфарз",
  Over_Limit:"Зиёда аз Лимит",
  Under_Limit:"Дар зери маҳдудият",
  Payroll_Limit:"Лимити музди меҳнат",
  Enable_Bank_Account:"Ҳисоби бонкиро фаъол созед",
  Select_Account:"Ҳисобро интихоб кунед",
  No_Bank_Account:"Ҳисоби бонкӣ нест",
  No_Bank_Account_Word:"Аввалин <a routerLink='/folder/Accounts/New'>Ҳисоби бонкӣ</a>-ро илова кунед.",
  Bank_Accounts:"Ҳисобҳои бонкӣ",
  No_Accounts:"Ҳисобҳо нест",
  No_Accounts_Note:"Аввалин <a routerLink='/folder/Accounts/New'>Ҳисоби бонкӣ</a>-ро илова кунед.",
  Cheque_Designer:"Дизайнерро тафтиш кунед",
  Cheque_Design:"Дизайнро тафтиш кунед",
  Select_Design:"Дизайнро интихоб кунед",
  Cheque_Designs:"Тарҳҳоро тафтиш кунед",
  No_Cheque_Designs:"Тарҳҳои чек нест",
  No_Cheque_Designs_Word:"<a routerLink='/folder/Settings/Cheque/Design/New'>Тарроҳии чеки</a>-и худро эҷод кунед.",
  Set_Default:"Ҳамчун пешфарз таъин кунед",
  Default:"Пешфарз",
  Remove:"Хориҷ кунед",
  Folder:"Папка",
  Edit:"Таҳрир",
  LoadingDots:"Loading...",
  Add_a_New_File:"Ба <a href='#' (click)='add()'>Файли нав</a> илова кунед",
  this_folder:"ин папка",
  FTP_Backup_Settings:"Танзимоти нусхабардории FTP",
  Secure_File_Transfer:"Интиқоли файлҳои бехатар",
  New_Holiday:"Иди нав",
  Add_Holiday:"Илова кардани истироҳат",
  Holiday_Name:"Номи ид",
  Additional_Pay:"Пардохти иловагӣ",
  Enable_Holiday:"Таътилро фаъол созед",
  Select_Holidays:"Рӯзҳои истироҳатро интихоб кунед",
  No_Holidays:"Идҳо нест",
  No_Holidays_Word:"Аввалин <a routerLink='/folder/Holiday/New'>Иди оммавӣ</a>-ро илова кунед.",
  New_Import:"Воридоти нав",
  Import_Data:"Воридоти маълумот",
  Import_Data_Word:"Навъи файл ё усули боркунии интихобкардаатонро интихоб кунед.<br /> Шумо метавонед пас аз боркунии файли дастгирӣшаванда кадом майдонҳои воридшударо дар файл ба кадом параметри барнома мувофиқат кунанд, интихоб кунед.",
  Import_File:"Файли воридотӣ",
  Link_To_File:"Истинод ба Файл",
  Select_File:"Файлро интихоб кунед",
  New_Moderator:"Модератори нав",
  Allow_Moderation:"Модератсияро иҷозат диҳед",
  Create_Paycheques:"Эҷоди маош",
  Edit_Paycheques_and_Data:"Таҳрири музди меҳнат ва маълумот",
  Destroy_Data_and_Paycheques:"Маълумот ва маошҳоро нест кунед",
  Bonus_Percentage:"Фоизи музди меҳнат",
  Fixed_Amount:"Маблағи муқарраршуда",
  Select_Moderator:"Модераторро интихоб кунед",
  No_Moderators:"Модератор нест",
  Moderators:"Модераторҳо",
  Moderator_Account:"Аввалин <a routerLink='/folder/Administrator/New'>Ҳисоби модератор</a>-ро эҷод кунед.",
  No_Moderators_Word:"Аввалин <a routerLink='/folder/Administrator/New'>Модератор</a>-ро илова кунед.",
  Defaults:"Пешфарзҳо",
  Provide_Us_Info:"Маълумот пешниҳод кунед",
  Setup_Your_Printer:"Принтери худро насб кунед",
  Your_Company:"Дар бораи ширкати шумо",
  Company_Name:"номи ширкат",
  Currency:"Асъор",
  Default_Currency:"Асъори пешфарз",
  Base_Monthly_Income:"Даромади моҳона",
  Protection:"Муҳофизат",
  App_Protection:"Муҳофизати барнома",
  PIN_Code_Protection:"Муҳофизати рамзи PIN",
  App_Protection_Word:"Усулҳои бехатариро фаъол созед, ки ба ҳифзи ҳисоби шумо кӯмак мерасонанд.",
  PIN_Code:"Рамзи PIN",
  Change_PIN:"ПИН-ро иваз кунед",
  New_Password:"Калидвожаи Нав",
  Geofence_Protection:"Муҳофизати геологӣ",
  Geofence_Area:"Минтақаи муқарраршуда",
  Distance:"Масофа",
  Setup_Now:"Ҳоло насб кунед",
  Mile:"мил",
  Km:"км",
  m:"м",
  Facial_Recognition:"Шинохти чеҳра",
  Face:"Чеҳра",
  Setup:"Ташкил кардан",
  Label:"Нишон",
  Password_Protection:"Муҳофизати парол",
  Modify_Password:"Паролро тағир диҳед",
  New_Tax_Entry:"Воридоти нави андоз",
  New_Tax:"Андози нав",
  Tax_Label:"Нишони андоз",
  Perpetually_Enabled:"Ба таври доимӣ фаъол",
  Select_Taxes:"Андозҳоро интихоб кунед",
  Tax_Deductions:"Тарҳҳои андоз",
  No_Tax_Deductions:"Тарҳи андоз нест",
  No_Tax_Deductions_Word:"Тарҳи аввалини <a routerLink='/folder/Tax/New'>Tax</a>-ро илова кунед.",
  New_Timer:"Таймери нав",
  Start:"Оғоз",
  Stop:"Ист",
  Start_Timer:"Таймерро оғоз кунед",
  Stop_Timer:"Таймерро қатъ кунед",
  Timer_Active:"Таймер фаъол",
  Timer:"Вақтсанҷ:",
  Timer_Running:"Вақтсанҷ: (давидан)",
  Save_Timer:"Таймерро захира кунед",
  New_Timesheet:"Ҷадвали нави вақт",
  Select_Timesheets:"Ҷадвали вақтро интихоб кунед",
  Work_Notes:"Қайдҳои корӣ",
  Entry_Title:"Сарлавҳаи вуруд",
  No_Timesheets:"Варақаҳои вақт нест",
  No_Timesheets_Word:"Аввалин <a routerLink='/folder/Timesheet/New'>Ҷадвали вақт</a>-ро илова кунед.",
  Timesheet_Submitted:"Ҷадвали вақт пешниҳод карда шуд",
  Timesheet_Congrats:"Табрик мекунем! Ҷадвали вақти шумо бомуваффақият пешниҳод карда шуд. Сипос!",
  Timesheet_Copy:"Барои гирифтани нусхаи ҳуҷҷатҳои худ лутфан ба мо суроғаи почтаи электронӣ ва/ё рақами телефони мобилии худро пешниҳод кунед.",
  Submit:"Пешниҳод кунед",
  Allow_Notifications:"Ба огоҳиҳо иҷозат диҳед",
  Untitled_Entry:"Вуруди нав",
  Untitled_Bank:"Бонки беном",
  Timesheet_Entry:"Вуруди ҷадвали вақт",
  Work_Description:"Тавсифи кор",
  Enable_Timesheet:"Ҷадвали вақтро фаъол созед",
  Submit_Timesheet:"Варақаи вақтро пешниҳод кунед",
  Vacation:"Таътил",
  New_Vacation:"Таътили нав",
  Vacation_Name:"Номи истироҳат",
  Paid_Vacation:"Рухсатии пулакӣ",
  Vacation_Pay:"Пардохти рухсатӣ",
  Enable_Vacation:"Таътилро фаъол созед",
  Select_Vacations:"Таътилҳоро интихоб кунед",
  No_Vacations:"Не таътил",
  No_Vacations_Word:"Аввалин сабти <a routerLink='/folder/Vacation/New'>Vacation</a>-ро эҷод кунед.",
  Payroll_Schedule:"Ҷадвали музди меҳнат",
  Next_Payroll:"Маблағи оянда:",
  Upcoming_Payroll:"Музди меҳнати дарпешистода",
  No_Upcoming_Payroll:"Маоши дарпешистода нест",
  Address_Book:"Китоби адрес",
  Archived_Documents:"Ҳуҷҷатҳои бойгонӣ",
  Dev_Mode:"Ариза дар ҳолати рушд",
  Administrators:"Маъмурон",
  Privacy:"Махфият",
  None:"Ҳеҷ",
  Select_Signature:"Имзоро интихоб кунед",
  No_Signatures:"Имзоҳо нест",
  No_Signatures_Word:"Аввалин <a routerLink='/folder/Signature/New'>Имзо</a>-ро илова кунед.",
  Repeat_Indefinitely:"Ба таври номуайян такрор кунед",
  Sign:"Имзо",
  Sign_Here:"Ин ҷо имзо кунед",
  Date_Signed:"Санаи имзо",
  Signature_Pad:"Варақаи имзо",
  Account_Holder:"Дорандаи ҳисоб",
  Account_Properties:"Хусусиятҳои ҳисоб",
  Name_On_Cheque:"Ном дар чек",
  Server_Hostname:"Номи сервер / IP",
  Printers:"Принтерҳо",
  No_Printers:"Принтер нест",
  Printer_Exists:"Принтер бо ин ном аллакай вуҷуд дорад.",
  No_Printers_Word:"Аввалин <a routerLink='/folder/Printer/New'>Принтер</a>-ро илова кунед.",
  No_Printer_Alert:"Ягон принтер муайян карда нашудааст. Оё шумо мехоҳед принтерро насб кунед?",
  Add_Printer:"Иловаи чопгар",
  New_Printer:"Принтери нав",
  Printer_Hostname:"Номи мизбони чопгар / IP",
  Printer_Label:"Нишони чопгар",
  Printer_Protocol:"Протоколи чопгар",
  Protocol:"Протокол",
  Email_Print:"Почтаи электронӣ",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Розетка",
  Print_Job:"Кори чоп",
  Printed:"Чоп карда шудааст",
  Not_Printed:"Чоп нашудааст",
  Print_Jobs:"Корҳои чоп",
  Print_Queue:"Навбати чоп",
  No_Print_Jobs:"Ҷойҳои чопӣ нест",
  No_Prints:"Барои чоп кардани <a routerLink='/folder/Cheque/New'>Чек</a>-и нав эҷод кунед.",
  Prints:"Чоп мекунад",
  Find_Printer:"Принтерро пайдо кунед",
  Find_AirPrint_Devices:"Дастгоҳҳои AirPrint-ро пайдо кунед",
  Select_Printer:"Принтерро интихоб кунед",
  System_UI:"UI система",
  Printer:"Принтер",
  Status:"Статус",
  Preview:"Пешнамоиш",
  Enable_Print_Job:"Вазифаи чопро фаъол созед",
  Queue_Weight:"Вазни навбат",
  Unlimited:"Бемаҳдуд",
  Show_Advanced_Printer_Options:"Намоиши имконоти пешрафтаи чопгар",
  Advanced:"Мукаммал",
  Location:"Ҷойгиршавӣ",
  Note:"Шарҳ",
  Queue_Name:"Номи навбат",
  Pages_Printed_Limit:"Маҳдудияти чопи саҳифаҳо",
  MultiPage_Idle_Time:"Вақти интизории чандсаҳифа (ҳо)",
  Page_Count_Limit:"Маҳдудияти шумораи саҳифа",
  Page_Orientation:"Самти саҳифа",
  Portrait:"Портрет",
  Landscape:"Манзара",
  Duplex:"Дуплекс",
  Double_Sided_Printing:"Дутарафа",
  Duplex_Mode:"Ҳолати дуплекс",
  Duplex_Short:"Кӯтоҳ",
  Duplex_Long:"дароз",
  Duplex_None:"Ҳеҷ",
  Color_And_Quality:"Ранг ва сифат",
  Monochrome:"Монохромӣ",
  Photo_Quality_Prints:"Чопҳои сифати акс",
  Printer_Email:"Почтаи электронии чопгар",
  Automatically_Downsize:"Ба таври худкор кам кардани андозаи",
  Paper:"Когаз",
  Paper_Name:"Номи коғаз",
  Paper_Width:"Паҳнои коғаз",
  Paper_Height:"Баландии коғаз",
  Paper_Autocut_Length:"Дарозии худкори коғаз",
  Margins:"Маржаҳо",
  Page_Margins:"Маржаҳои саҳифа",
  Page_Margin_Top:"Маржаи саҳифаи боло",
  Page_Margin_Right:"Маржаи саҳифаи рост",
  Page_Margin_Bottom:"Маржаи саҳифаи поён",
  Page_Margin_Left:"Маржаи саҳифаи чап",
  Add_Employees:"Илова кардани кормандон",
  Header:"Сарлавҳа",
  Print_A_Page_Header:"Сарлавҳаи саҳифаро чоп кунед",
  Header_Label:"Нишони сарлавҳа",
  Header_Page_Index:"Индекси саҳифаи сарлавҳа",
  Header_Font_Name:"Шрифти сарлавҳа",
  Select_Font:"Шрифт-ро интихоб кунед",
  Font_Selector:"Интихоби шрифт",
  Header_Font_Size:"Шрифти сарлавҳа",
  Header_Bold:"Сарлавҳаи ғафс",
  Header_Italic:"Сарлавҳаи курсив",
  Header_Alignment:"Ҳамоҳангсозии сарлавҳа",
  Left:"Чап",
  Center:"Марказ",
  Right:"Дуруст",
  Justified:"асоснок",
  Header_Font_Color:"Ранги сарлавҳа",
  Select_Color:"Рангро интихоб кунед",
  Footer:"Поёнтар",
  Print_A_Page_Footer:"Поёни саҳифаро чоп кунед",
  Footer_Label:"Нишони поён",
  Footer_Page_Index:"Индекси саҳифаи поёнӣ",
  Footer_Font_Name:"Шрифти поён",
  Fonts:"Шрифтҳо",
  Done:"Иҷро шуд",
  Select_Fonts:"Шрифтҳоро интихоб кунед",
  Footer_Font_Size:"Андозаи поён",
  Footer_Bold:"Пойтер Bold",
  Footer_Italic:"Поёнтар курсив",
  Footer_Alignment:"Ҳамоҳангсозии поён",
  Footer_Font_Color:"Ранги поён",
  Page_Copies:"Нусхаҳои саҳифа",
  Enable_Printer:"Принтерро фаъол созед",
  Remote_Logging:"Бақайдгирии дурдаст",
  Log_Server:"Сервери сабт",
  Encryption:"Рамзгузорӣ",
  Random_Key:"Калиди тасодуфӣ",
  Encryption_Key:"Калиди рамзгузорӣ",
  Cheques_Webserver:"Пойгоҳи додаҳои фармоишӣ",
  Learn_How:"Бифаҳмед, ки чӣ тавр",
  Signature:"Имзо",
  Default_Printer_Unit:"дар/см/мм/(пт)",
  View_Signature:"Дидани имзо",
  Printed_Signature:"Имзои чопшуда",
  Digitally_Sign:"Имзои рақамӣ",
  Digital_Signature:"Имзои рақамӣ",
  Add_Signature:"Иловаи имзо",
  Add_Your_Signature:"Имзои худро илова кунед",
  Enable_Signature:"Имзоро фаъол созед",
  Digitally_Signed:"Ба таври рақамӣ имзо карда шудааст",
  Insert_Error:"Чекро аз сабаби мушкилоти базаи маълумот захира кардан ғайриимкон аст.",
  Delete_Confirmation:"Оё мутмаин ҳастед, ки мехоҳед ин маълумотро нест кунед?",
  Discard_Confirmation:"Оё мутмаин ҳастед, ки мехоҳед ин маълумотро нест кунед?",
  Discard_Bank_Confirmation:"Шумо мутмаин ҳастед, ки мехоҳед ин ҳисобро нест кунед?",
  Discard_Printer_Confirmation:"Шумо мутмаин ҳастед, ки ин принтерро партофтан мехоҳед?",
  Delete_Bonus_Confirmation:"Шумо мутмаин ҳастед, ки мехоҳед ин бонусро нест кунед?",
  Delete_Invoice_Confirmation:"Шумо мутмаин ҳастед, ки мехоҳед ин ҳисобнома-фактураро нест кунед?",
  Generated_Cheque:"Санҷиши тавлидшуда",
  Generated_Invoice:"Ҳисобнома-фактураи тавлидшуда",
  Schedule_Start_Time:"Ҷадвали оғоз",
  Schedule_End_Time:"Анҷоми ҷадвал",
  New_Call:"Занги нав",
  No_Contacts:"Тамос нест",
  No_Contacts_Word:"Маъмурон, модераторҳо, кормандон ва пардохтгирандагон ҳамчун мухотибон пайдо мешаванд.",
  PDF_Subject:"молия",
  PDF_Keywords:"музди меҳнат чекҳои чеки PDF",
  Printer_Setup:"Танзимоти чопгар",
  Printer_Selection:"Интихоби чопгар",
  New_Fax:"Факси нав",
  New_Fax_Message:"Паёми нави факс",
  Fax_Machine:"Мошини факс",
  Fax_Name:"Номи факс",
  Fax_Email:"Почтаи электронии факс",
  Fax_Number:"Рақами факс",
  Contents:"Мундариҷа",
  Fax_Body:"Ҷисми саҳифа",
  Header_Word:"Сарлавҳа:",
  Header_Text:"Матни сарлавҳа",
  Include_Header:"Сарлавҳаро дохил кунед",
  Include_Footer:"Поёнтарро дохил кунед",
  Footer_Word:"Поёнтар:",
  Footer_Text:"Матни поён",
  Attach_a_Cheque:"Чекро замима кунед",
  Add_Deduction:"Илова кардани тарҳ",
  Enable_Fax:"Факс фиристед",
  Select_Fax:"Факс-ро интихоб кунед",
  No_Faxes:"Факс нест",
  Faxes:"Факсҳо",
  Save_and_Send:"Факс фиристед",
  Save_and_Pay:"Захира ва пардохт",
  WARNING:"Огоҳӣ:",
  Remember:"Дар хотир доред",
  Printing:"Чоп",
  Printing_Complete:"Чоп тамом шуд!\n\n",
  of:"аз",
  There_Were:"Онҷо буданд",
  Successful_Prints:"чопи муваффакиятнок ва",
  Unsuccessful_Prints:"чопҳои бемуваффақият.",
  PrinterError:"Бубахшед! Хатогие буд.",
  Printing_:"Чоп...",
  PrinterSuccess:"Ҳуҷҷат бомуваффақият чоп карда шуд.",
  PrintersSuccess:"Ҳуҷҷатҳо бомуваффақият чоп карда шуданд.",
  New_Message:"Паёми нав",
  New_Messages:"Паёмҳои нав",
  Read_Message:"Паёмро хонед",
  Write_Message:"Паём нависед",
  Send_Message:"Паём фиристед",
  Subject:"Мавзӯъ",
  Message:"Паём",
  Writing:"Навиштан...",
  Send:"Фиристодан",
  Set_Date:"Таъин кардани сана",
  Set_Time:"Вақтро таъин кунед",
  Recieve:"Гирифтан",
  Set_as_Default:"Ҳамчун пешфарз таъин кунед",
  Default_Account:"Ҳисоби пешфарз",
  Default_Design:"Тарҳрезии пешфарз",
  Multiple_Cheques:"Се чек",
  Account_Mode:"Ҳолати ҳисоб",
  Multiple_Cheques_Description:"Се чек дар як саҳифа.",
  Check_and_Table:"Санҷед ва Ҷадвал",
  Check_including_Table:"Бо ҷадвали ҳисобдорӣ тафтиш кунед.",
  Check_Mailer:"Почтаро тафтиш кунед",
  Check_Mailer_Window:"Бо равзанаи суроға санҷед.",
  DocuGard_Table_Top:"Чеки калон ва миз",
  DocuGard_Table_Middle:"Чеки калон ва миз (миёна)",
  DocuGard_Table_Bottom:"Чеки калон ва миз (поён)",
  DocuGard_Mailer_Top:"Почтаи калони чек",
  DocuGard_Mailer_Middle:"Почтаи чеки калон (миёна)",
  DocuGard_Mailer_Bottom:"Поёни чеки калон (поён)",
  DocuGard_Three_Cheques:"Се чеки калон",
  DocuGard_Cheque_Top:"Чеки ягонаи калон",
  DocuGard_Cheque_Middle:"Чеки калон (миёна)",
  DocuGard_Cheque_Bottom:"Чеки калон (поён)",
  DocuGard_Three_Cheques_Window:"Се чек дар як саҳифа.",
  DocuGard_Table_Top_Window:"Ҷадвали чек ва даромад.",
  DocuGard_Table_Middle_Window:"Ҷадвали чек ва даромад.",
  DocuGard_Table_Bottom_Window:"Ҷадвали чек ва даромад.",
  DocuGard_Mailer_Top_Window:"Чек, чадвал ва адрес.",
  DocuGard_Mailer_Middle_Window:"Чек, чадвал ва адрес.",
  DocuGard_Mailer_Bottom_Window:"Чек, чадвал ва адрес.",
  DocuGard_Cheque_Top_Window:"Коғази бехатарро тафтиш кунед.",
  DocuGard_Cheque_Middle_Window:"Коғази бехатарро тафтиш кунед.",
  DocuGard_Cheque_Bottom_Window:"Коғази бехатарро тафтиш кунед.",
  Basic_Cheque:"Санҷиши ягона",
  Basic_Cheque_Print:"Як чекро чоп кунед.",
  Error_Setting_As_Paid:"Хатогии танзим ҳамчун пардохташуда",
  Add_Attachment:"Иловаи замима",
  PrinterUnavailable:"Принтер дастнорас",
  CreditCardComponent:"Кортҳо",
  PaypalComponent:"PayPal",
  InteracComponent:"Интеракт",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Депозити нав",
  Deposits:"Амонатҳо",
  No_Deposits:"Бе пасандозҳо",
  Credit_Card_Deposit:"Корти кредитӣ",
  New_Credit_Card_Deposit_Message:"Депозити корти кредитӣ",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Депозити BitCoin",
  New_Interac_Deposit:"Интеракт",
  New_Interac_Deposit_Message:"Интиқоли интерактивӣ",
  Payments_Limit:"Лимити пардохт",
  No_Payment_Limit:"Лимити пардохт нест",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Депозити PayPal",
  No_Deposits_Word:"Аввалин ширкати <a routerLink='/folder/Deposit/New'>Депозит</a>-ро илова кунед.",
  No_Documents_Specified:"Барои чоп ягон ҳуҷҷат муайян карда нашудааст",
  No_Printers_Added:"Ягон принтер ёфт нашуд. Барои илова кардан ба Танзимотҳо > Чопгарҳо гузаред.",
  DB_Erase_Prompt:"Тамоми базаи маълумотро пурра нест кунед? ОГОҲ: Шумо тамоми маълумоти захирашударо гум мекунед!",
  Console_Warning:"Ба ин консол ягон матн нагузоред. Шумо метавонед худро ва/ё ширкати худро зери хатари ҷиддӣ гузоред.",
  No_Faxes_Word:"Аввалин <a routerLink='/folder/Fax/New'>Факс</a>-ро эҷод кунед.",
  Cheque_Delete_Confirmation:"Шумо мутмаин ҳастед, ки мехоҳед ин чекро нест кунед?",
  Design_Delete_Confirmation:"Оё мутмаин ҳастед, ки мехоҳед ин тарҳро нест кунед?",
  Cheque_Pay_Confirmation:"Ин чекро ҳамчун пардохт қайд кунед? Он дар навбати чоп НАМЕШАВАД.",
  Payment_Pay_Confirmation:"Ин пардохтро ҳамчун пардохт қайд кунед? Он дар навбати чек пайдо намешавад.",
  Delete_Deduction_Confirmation:"Шумо мутмаин ҳастед, ки мехоҳед ин тарҳро нест кунед?",
  Delete_Job_Confirmation:"Шумо мутмаин ҳастед, ки мехоҳед ин корро нест кунед?",
  Delete_Timesheet_Confirmation:"Шумо мутмаин ҳастед, ки мехоҳед ин ҷадвали вақтро нест кунед?",
  Delete_Schedule_Confirmation:"Оё мутмаин ҳастед, ки мехоҳед ин ҷадвалро нест кунед?",
  Delete_Setting_Confirmation:"Шумо мутмаин ҳастед, ки мехоҳед ин танзимотро аз нав танзим кунед?",
  Delete_Fax_Confirmation:"Шумо мутмаин ҳастед, ки мехоҳед ин факсро нест кунед?",
  Delete_File_Confirmation:"Шумо мутмаин ҳастед, ки мехоҳед ин файлро нест кунед?",
  Delete_Vacation_Confirmation:"Оё мутмаин ҳастед, ки мехоҳед ин таътилро нест кунед?",
  Delete_Printer_Confirmation:"Шумо мутмаин ҳастед, ки мехоҳед ин принтерро нест кунед?",
  Remove_Design_Confirmation:"Оё мутмаин ҳастед, ки мехоҳед ин тарҳро нест кунед?",
  Delete_Payroll_Confirmation:"Шумо мутмаин ҳастед, ки мехоҳед ин маошро нест кунед?",
  Send_Fax_Email_Confirmation:"Оё шумо мехоҳед ин ҳуҷҷатҳоро факс ва почтаи электронӣ фиристед?",
  Send_Email_Confirmation:"Мехоҳед ин ҳуҷҷатро ба почтаи электронӣ фиристед?",
  Send_Fax_Confirmation:"Оё шумо мехоҳед ин ҳуҷҷатро факс кунед?",
  Error_Generating_PDF:"Бубахшед. Ҳангоми тавлиди ин ҳуҷҷат хатогӣ рух дод.",
  PDF_Error_Saving_Image:"Бубахшед. Ҳангоми захира кардани тасвири PDF-и ин ҳуҷҷат хатогӣ рух дод.",
  Test_Printer_Confirmation:"Мехоҳед саҳифаи санҷиширо дар ин принтер чоп кунед?",
  Save_Timesheet_Prompt:"Лутфан 'Унвон' илова кунед ё 'Оғози Таймер' -ро пахш кунед, то захира кунед.",
  Remove_Geofence_Prompt:"Шумо мутмаин ҳастед, ки мехоҳед макони ин девори геодеворро нест кунед?",
  Delete_Employee_Confirmation:"Шумо мутмаин ҳастед, ки мехоҳед нест кунед",
  Fire_Employee_Confirmation:"Шумо мутмаин ҳастед, ки мехоҳед оташ гиред",
}