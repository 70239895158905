export const Ms = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Hak cipta &salinan; 2023",
  black:"Hitam",
  green:"Hijau",
  gold:"Emas",
  blue:"Biru",
  brown:"Coklat",
  purple:"Ungu",
  pink:"Merah jambu",
  red:"Merah",
  Swatches:"Swatch",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Hue",
  Saturation:"Ketepuan",
  Lightness:"Keringanan",
  Upgrade_To_Pro:"Naik taraf kepada Pro",
  AllFeaturesInclude:"Semua Ciri Termasuk:",
  PrintUnlimitedCheques:"Cetak Cek Tanpa Had",
  PremiumChequeDesigns:"Reka Bentuk Cek Premium",
  ManageUnlimitedEmployees:"Urus Pekerja Tanpa Had",
  AddUnlimitedPayees:"Tambah Penerima Bayaran Tanpa Had",
  CreateUnlimitedPayrolls:"Buat Gaji Tanpa Had",
  UnlimitedSchedulesandTimeSheets:"Jadual dan Helaian Masa tanpa had",
  BulkPayPalPayouts:"Pembayaran PayPal Pukal",
  UnlimitedBankAccounts:"Akaun Bank tanpa had",
  ManageMultipleCompanies:"Mengurus Berbilang Syarikat",
  TrackInsurancePolicies:"Jejaki Polisi Insurans",
  Bio_MetricProtection:"Perlindungan Bio-Metrik",
  Geo_FenceLock_OutProtection:"Perlindungan Geo-Fence Lock-Out",
  Multiple_Companies_Word:"Menguruskan berbilang syarikat tidak tersedia tanpa premium Cek.",
  PayPal_Payouts_Word:"Pembayaran PayPal dilumpuhkan tanpa premium Cek.",
  PINProtection:"Perlindungan PIN",
  PasswordProtection:"Perlindungan Kata Laluan",
  May_Require_Approval:"Mungkin memerlukan kelulusan.",
  Subscribe:"Langgan",
  Billed:"Dibilkan",
  Up:"Naik",
  Down:"Bawah",
  Positioning:"Kedudukan",
  Marker:"Penanda",
  Drag_Marker:"Penanda Seret",
  Tagline:"Cetak Cek dan Jadual Gaji",
  Marker_Word:"Gunakan Penanda untuk saiz elemen.",
  Pinch_Zoom:"Zum Cubit",
  Pinch_Word:"Cubit untuk mengezum elemen.",
  Drag:"Seret",
  Drag_Word:"Gunakan jari anda untuk menyeret elemen.",
  subscription_alias_word:"Auto-Membaharui Langganan",
  premium_alias_word:"Pakej Naik Taraf Sekali",
  print_alias_word:"Cetakan Cek Individu",
  mode_alias_word:"Mod",
  Pro:"Pro",
  Pro_word:"Versi pro diperlukan.",
  Cant_Delete_Default_Company_Word:"Maaf, anda tidak boleh memadamkan syarikat lalai anda.",
  Reinsert_Default_Designs:"Masukkan Semula Reka Bentuk Lalai",
  Reinsert_Default_Designs_word:"Adakah anda mahu memasukkan semula reka bentuk lalai?",
  Subscription_Active_Disable_Word:"Langganan ini aktif. Adakah anda ingin membatalkan langganan Cek ini?",
  Company_Logo:"Logo syarikat",
  ZERO_:"SIFAR",
  Disclaimer:"Penafian",
  Privacy_Policy:"Dasar Privasi",
  EULA:"Menyemak EULA",
  Terms_Of_Service:"Syarat Perkhidmatan",
  Terms_Of_Use:"Syarat Penggunaan",
  Refunds:"Polisi Bayaran Balik",
  Single_Print:"1 Semak",
  Two_Prints:"2 Cek",
  Five_Prints:"5 Cek",
  Ten_Prints:"10 Cek",
  Fifteen_Prints:"15 Cek",
  Twenty_Prints:"20 Cek",
  Thirty_Prints:"30 Cek",
  Image_Added:"Imej Ditambah",
  Image_Preview:"Pratonton Imej",
  No_Image_Was_Selected:"Tiada imej dipilih.",
  Cheques_Unlimited:"Cek Tanpa Had",
  _Subscription:"Langganan",
  Subscription:"Cek - 1 Bulan",
  Two_Month_Subscription:"Cek - 2 Bulan",
  Three_Month_Subscription:"Cek - 3 Bulan",
  Six_Month_Subscription:"Cek - 6 Bulan",
  Twelve_Month_Subscription:"Cek - 12 Bulan",
  Cheques_Are_Available:"Cek tersedia untuk dicetak.",
  Upgrade_Required_Two:"Pilih pakej dan ketik dua kali pada butang harga untuk memulakan pembelian anda. Cetak cek berwarna penuh profesional dalam beberapa saat.",
  Month:"bulan",
  Due:"Kerana:",
  Expires:"tamat tempoh:",
  Subscription_Active:"Langganan Aktif",
  Subscription_Inactive:"Langganan Tidak Aktif",
  Purchase_Additional_Cheques:"Beli cek tambahan?",
  Printable_Cheque:"Cek Boleh Cetak",
  Printable_Cheques:"Cek Boleh Cetak",
  Printable_Cheque_Word:"cek tersedia pada akaun anda.",
  Printable_Cheques_Word:"cek tersedia pada akaun anda.",
  Max_Amount_Message:"Amaun yang telah anda tentukan telah mencapai jumlah maksimum yang ditetapkan untuk sistem ini:",
  Terms_Required_Word:"Anda mesti bersetuju dengan perjanjian ini sebelum terus menggunakan Cek.",
  Subscriptions:"Langganan",
  Product_Upgrades:"Naik taraf",
  Mailed_Out_Cheques:"Cek Mel Keluar",
  Enter_A_Company_Name:"Sila masukkan nama syarikat.",
  Single_Cheques:"Cek Tunggal",
  Cheque_Golden:"Cek Emas",
  Cheque_Golden_Window:"Reka bentuk cek emas.",
  Cheque_Green:"Cek Hijau",
  Cheque_Green_Window:"Reka bentuk cek hijau.",
  Cheque_Red:"Cek Merah",
  Cheque_Red_Window:"Reka bentuk cek merah.",
  Cheque_Yellow:"Cek Kuning",
  Cheque_Yellow_Window:"Reka bentuk cek kuning.",
  Cheque_Statue_of_Liberty:"Patung Liberty",
  Cheque_Statue_of_Liberty_Window:"Reka bentuk cek Patung Liberty.",
  Cheque_Green_Wave:"Gelombang Hijau",
  Cheque_Green_Wave_Window:"Reka bentuk cek hijau.",
  Cheque_Golden_Weave:"Tenunan Emas",
  Cheque_Golden_Weave_Window:"Reka bentuk cek emas yang elegan.",
  Cheque_Green_Sun:"Matahari Hijau",
  Cheque_Green_Sun_Window:"Reka bentuk semak yang mendalam dan menenangkan.",
  Cheque_Blue_Checkers:"dam biru",
  Cheque_Blue_Checkers_Window:"Reka bentuk cek biru.",
  Cashiers_Check:"Cek Juruwang",
  Cashiers_Check_Window:"Templat gaya Cek Juruwang.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Reka bentuk cek Aqua.",
  Cheque_Golden_Checkers:"dam emas",
  Cheque_Golden_Checkers_Window:"Reka bentuk cek emas.",
  Upgrade_Unavailable:"Peningkatan Tidak Tersedia",
  Bank_Code_Protection:"Perlindungan Nombor Bank",
  Bank_Code_Protection_Word:"Lindungi nombor bank anda daripada digunakan pada apl ini yang dijalankan pada peranti lain atau untuk pengguna lain. Tindakan ini TIDAK DAPAT DIBALIKKAN. teruskan?",
  Bank_Code_Protection_Blocked_Word:"Nombor bank yang anda cuba gunakan dikhaskan untuk pengguna atau peranti lain.",
  Bank_Code_Protection_Error_Word:"Pengesahan nombor telah gagal. Sila sambung ke Internet dan cuba tambah akaun bank ini sekali lagi.",
  Bank_Code_Protection_Set_Error_Word:"Perlindungan nombor bank memerlukan anda disambungkan ke Internet. Sila sambung dan cuba lagi.",
  Upgrade_Unavailable_Word:"Maaf, kami menghadapi masalah mengesahkan anda. Langganan dan naik taraf kepada Cek pada masa ini tidak tersedia untuk pembelian di kawasan anda.",
  PayPal_Payment_Preview:"Pratonton Pembayaran PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Pilih PayPal",
  PayPal_Applications:"Aplikasi PayPal",
  Purchase_With_Apple_Pay:"Beli dengan Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Syarikat",
  Insurance:"Insurans",
  New_PayPal:"PayPal baharu",
  Pay_By:"Bayar Dengan",
  Insure:"menginsuranskan",
  Miles:"Batu",
  Payment_Method:"Kaedah Pembayaran",
  Select_Policies:"Pilih Dasar",
  Policies:"dasar",
  Policy:"Dasar",
  No_PayPal_Account:"Tiada Akaun PayPal",
  No_Policies:"Tiada Polisi Insurans",
  New_Policy:"Dasar Baru",
  PayPal_Payment:"Pembayaran PayPal",
  PayPal_Payments:"Pembayaran PayPal",
  No_Payment_Selected:"Tiada Pembayaran Dipilih",
  Sending_Payment_Word:"Sila tunggu... Pembayaran ini sedang dihantar.",
  Sending_Payments_Word:"Sila tunggu... Pembayaran sedang dihantar.",
  No_Payment_Selected_PayPal:"Tiada <a routerLink='/folder/Payments'>Pembayaran PayPal</a> dipilih untuk dihantar.",
  Payment_Sent:"Bayaran Dihantar",
  PayPal_Payment_Sent:"Pembayaran ini telah dihantar dengan PayPal.",
  Copay:"Copay",
  Dead:"Mati",
  Alive:"Hidup",
  Not_Dead:"Tidak mati",
  Unclaimed:"Tidak dituntut",
  Attempted:"Cuba",
  Deceased:"Meninggal dunia",
  Claimed:"Dituntut",
  Unpaid:"Tak bergaji",
  Sending_Payment:"Menghantar Bayaran",
  Sending_Payments:"Menghantar Bayaran",
  Send_PayPal_Confirmation:"Adakah anda mahu menghantar transaksi ini dengan PayPal?",
  Send_PayPal_Confirmation_Word:"Tekan butang hijau untuk menghantar transaksi ini.",
  Save_Payment_As_Unpaid:"Simpan pembayaran ini sebagai belum dibayar?",
  Payment_Pay_Confirmation_PayPal:"Simpan pembayaran ini sebagai dibayar?",
  No_Policies_Word:"Tambahkan <a routerLink='/folder/Postage/New'>Polisi Insurans</a> yang pertama sekarang.",
  Timesheet_Multiple_Delete_Confirmation:"Adakah anda pasti mahu memadamkan beberapa helaian masa?",
  Select_Multiple_Timesheets_Prompt:"Tiada helaian masa dipilih. Pilih sekurang-kurangnya satu helaian masa.",
  Select_Multiple_Policies_Prompt:"Tiada dasar dipilih. Pilih sekurang-kurangnya satu polisi insurans.",
  Policies_Multiple_Delete_Confirmation:"Adakah anda pasti mahu memadamkan berbilang dasar?",
  Company:"Syarikat",
  Add_Company:"Tambah Syarikat",
  New_Company:"Tambah Syarikat",
  No_Companies:"Tiada Syarikat",
  Enable_Company:"Dayakan Syarikat",
  Select_Company:"Pilih Syarikat",
  The_Default_Company:"Label syarikat lalai.",
  Manage_Companies:"Urus Syarikat",
  No_Companies_Word:"Semakan akan menggunakan syarikat lalai.<br />Tambahkan <a routerLink='/folder/Company/New'>Syarikat pertama</a>.",
  Default_Company:"Syarikat Lalai",
  Cheques_Unlimited_Word:"Cek Unlimited membolehkan anda mencetak seberapa banyak cek yang anda suka.",
  Cheques_Subscription_Word:"Langganan Cek membolehkan anda mencetak seberapa banyak cek yang anda suka.",
  You_Own_This_Product:"Anda memiliki produk ini.",
  Your_Subscription_is_Active:"Langganan anda aktif.",
  Active_Products:"Produk Diaktifkan",
  Purchase_Confirmation:"Belian",
  Purchase_Cheques:"Cek Belian",
  Restore_Purchase:"Pulihkan Pembelian",
  Erase_Purchase:"Padamkan Pembelian",
  Successfully_Purchased:"Berjaya Dibeli",
  Enter_Your_Licence_Key:"Sila masukkan kunci lesen anda pada halaman ini untuk mengaktifkan produk ini.",
  Licence_Key:"Kunci lesen",
  Enter_Licence_Key:"Masukkan Kunci Lesen",
  Purchased:"Dibeli",
  Enable_Feature:"Dayakan Ciri",
  Cancel_Subscription:"Batalkan Langganan",
  Subscription_Removed:"Langganan Dialih Keluar",
  Select_Active_Subscription:"Pilih langganan aktif untuk mengubah suainya.",
  Remove_Subscription_Word:"Adakah anda pasti mahu membatalkan langganan ini?",
  Delete_Company_Confirmation:"Adakah anda pasti mahu memadamkan keseluruhan syarikat ini? AMARAN: Anda akan kehilangan semua maklumat yang disimpan!",
  Delete_Default_Company_Word:"Anda tidak boleh memadamkan syarikat lalai. Adakah anda ingin menetapkan semula aplikasi dan memulihkannya kepada keadaan lalai? AMARAN: Anda akan kehilangan semua maklumat yang disimpan!",
  Console_Warning_2:"Jangan mengendalikan sebarang mata wang menggunakan aplikasi ini yang bukan milik anda pada masa ini.",
  Terms_and_Conditions:"Terma dan syarat",
  and_the:"dan juga",
  for:"untuk",
  Please_Read_Word:"Sila baca dan bersetuju dengan",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Beberapa kadar penukaran mata wang tidak dapat ditemui. Sila sambung ke internet.",
  Free:"Percuma",
  DB_Erase_Prompt_2:"Padam sepenuhnya seluruh pangkalan data pembangun? AMARAN: Anda akan kehilangan semua maklumat PEMBELIAN dan LANGGANAN!",
  Successfully_Imported:"Berjaya Diimport",
  Select_Postage:"Pilih Postage",
  No_Postage:"Tiada Setem Pos",
  No_Paid_Postage_Word:"Tambahkan setem <a routerLink='/folder/Postage/New'>Bayar Pos</a> yang pertama .",
  Trial_Complete:'Percubaan Selesai',
  Please_Upgrade:'Sila tingkatkan Cek untuk meneruskan pencetakan.',
  Aa:"Aa",
  Color:"Warna",
  Font:"fon",
  Guide:"Panduan",
  Guides:"Pemandu",
  Image:"Imej",
  Zoom:"Zum",
  Logo:"Logo",
  Bank:"Bank",
  MICR:"MICR",
  Total:"Jumlah",
  Cancel:"Batal",
  Confirm:"sahkan",
  Method:"Kaedah",
  Biometric_Security:"Keselamatan Bio-metrik",
  Please_Login_To_Continue:"Sila log masuk untuk meneruskan.",
  Complete:"lengkap",
  Sign_Up:"Daftar",
  Error:"ralat",
  Biometrics:"Bio-metrik",
  Percent:"Peratus",
  Zero_Percent:"0%",
  Top_Margin:"Margin Atas",
  Bottom_Margin:"Margin Bawah",
  Left_Margin:"Margin Kiri",
  Right_Margin:"Margin Kanan",
  MICR_Margin:"Margin MICR",
  Table_Margin:"Margin Jadual",
  Address_Margin:"Margin Alamat",
  Percentage_:"Peratusan",
  Vacation_Title:"Tajuk Percutian",
  Use_PIN:"Gunakan PIN",
  Loading__:"Memuatkan...",
  Design_Title:"Tajuk Reka Bentuk",
  Authorize:"memberi kebenaran",
  Key:"kunci",
  Public_Key:"Kunci Awam",
  Private_Key:"Kunci Peribadi",
  Authenticate:"Sahkan",
  Last_Payroll:"Gaji Terakhir",
  Last_Calculation:"Pengiraan Terakhir",
  Authorized:"diberi kuasa",
  Geo_Authorized:"Geo-Lokasi: Dibenarkan",
  Not_Authorized:"Tidak Dibenarkan",
  Authorization_Complete:"Kebenaran Selesai",
  Geolocation_Authorization:"Keizinan geolokasi",
  Out_of_Bounds:"Di luar batas",
  Cant_Delete_Default_Design:"Tidak boleh memadam reka bentuk lalai.",
  Unauthorized_Out_of_Bounds:"Tanpa Kebenaran: Di Luar Sempadan",
  Biometric_Authorization:"Keizinan Bio-metrik",
  Locating_Please_Wait:"Mencari, Sila Tunggu...",
  Test_Biometrics:"Ujian Bio-metrik",
  Cheque_Margins:"Semak Margin",
  Percentage_Full_Error:"Medan peratusan tidak boleh ditetapkan melebihi 100% peratus.",
  No_Payroll_Text:"Tambahkan <a routerLink='/folder/Employee/New'>Pekerja</a> atau <a routerLink='/folder/Payee/New'>Penerima Bayaran</a> dan <a routerLink='/folder/Schedule/New'>Jadual</a>.",
  Design_Saved:"Reka Bentuk Disimpan",
  Default_Design_Selected:"Reka Bentuk Lalai Dipilih",
  Partial_Import:"Import Separa",
  Partial_Export:"Eksport Separa",
  Entire_Import:"Seluruh Import",
  Entire_Export:"Eksport Keseluruhan",
  Existing_Password:"Sila masukkan kata laluan sedia ada anda:",
  Existing_PIN:"Sila masukkan kod PIN sedia ada anda:",
  Pin_Change_Header:"Pengesahan PIN",
  Pin_Change_SubHeader:"Masukkan nombor PIN lama anda untuk mengesahkan perubahan.",
  Pass_Change_Header:"Pengesahan kata laluan",
  Pass_Change_SubHeader:"Masukkan kata laluan lama anda untuk mengesahkan perubahan.",
  PIN_Enter_Message:"Masukkan PIN anda untuk mengesahkan.",
  Password_Enter_Message:"Masukkan kata laluan anda untuk mengesahkan.",
  Pin_Updated_Success:"PIN berjaya dikemas kini!",
  Pin_Updated_Fail:"PIN tidak dapat dikemas kini.",
  Pass_Updated_Success:"Kata laluan berjaya dikemas kini.",
  Pass_Updated_Fail:"Kata laluan tidak dapat dikemas kini.",
  Preview_Payment:"Pratonton Pembayaran",
  Preview_Payroll:"Pratonton Gaji",
  No_Payments_Created:"Tiada pembayaran ditemui untuk dibuat.",
  Payment_Preview:"Pratonton Pembayaran",
  Enable_Payee:"Dayakan Penerima",
  Rendered:"Diberikan",
  No_Email:"Tiada E-mel",
  Setup_Cheques:"Pemeriksaan Persediaan",
  name:"nama",
  address:"Alamat",
  address_2:"Alamat 2",
  city:"Bandar",
  province:"Wilayah",
  postal_code:"Pos / Poskod",
  country:"Negara",
  username:"Nama pengguna",
  full_name:"Nama penuh",
  birthday:"hari jadi",
  email:"E-mel",
  phone:"telefon",
  employees:"Pekerja",
  addresses:"Alamat",
  payment_amount_limit:"Had Jumlah Bayaran",
  total_limit:"Jumlah Had",
  payees:"Penerima bayaran",
  description:"Penerangan",
  address_line_one:"Alamat Baris Satu",
  address_line_two:"Alamat Baris Dua",
  image:"Imej",
  account_holder:"Pemegang akaun",
  cheque_starting_id:"Semak ID Permulaan",
  transit_number:"Nombor Transit",
  institution_number:"Nombor Institusi",
  designation_number:"Nombor Jawatan",
  account_number:"Nombor akaun",
  currency:"mata wang",
  signature:"Tandatangan",
  payment_payroll_limit:"Had Pembayaran",
  total_limi:"Jumlah had",
  date:"Tarikh",
  printed_memo:"Memo Bercetak",
  banks:"Bank",
  signature_image:"Imej Tandatangan",
  address_name:"Nama Alamat",
  notes:"Nota",
  design:"Reka bentuk",
  title:"Tajuk",
  frequency:"Kekerapan",
  fax:"Faks",
  salaries:"Gaji",
  salary_ids:"ID gaji",
  start_time:"Masa mula",
  end_time:"Masa tamat",
  paid:"Dibayar",
  overtime_percentage:"Peratusan Berbayar",
  overtime_amount:"Amaun Tetap Dibayar",
  first_name:"Nama pertama",
  last_name:"Nama terakhir",
  moderation:"Kesederhanaan",
  create:"Buat",
  edit:"Sunting",
  destroy:"musnah",
  day_start_time:"Hari_mula_masa",
  day_end_time:"Hari_akhir_masa",
  fullname:"nama",
  time:"Masa",
  auto_send:"Hantar secara automatik",
  time_method:"Kaedah Masa",
  schedules:"Jadual",
  indefinite_payroll_enabled:"Dayakan Selama-lamanya",
  amount:"Jumlah",
  repeat:"ulang",
  always_enabled:"Sentiasa Didayakan",
  start_date:"Tarikh mula",
  end_date:"Tarikh tamat",
  Cheque_Total:"Semak Jumlah",
  Total_Amount:"Jumlah keseluruhan:",
  Amounts:"Jumlah:",
  Images:"Imej",
  Files:"Fail",
  Previewing:"Pratonton:",
  Insert:"Sisipkan",
  Preview_Import:"Pratonton Import",
  Entry:"Kemasukan",
  Entries:"entri",
  No_Entries:"Tiada Penyertaan",
  Import_Type:"Jenis Import",
  Select_Details:"Pilih Butiran",
  Select_Payee:"Pilih Penerima",
  Enable_Holidays:"Dayakan Cuti",
  Disable_Holidays:"Lumpuhkan Cuti",
  Wire_Transfer:"Pemindahan Kawat",
  New_Export:"Eksport Baharu",
  Export_Data:"Eksport Data",
  Export_Data_Word:"Pilih jenis fail untuk dieksport dan dimuat turun.",
  Export_File:"Eksport Fail",
  Mode:"Mod",
  Times:"Masa",
  Widgets:"Widget",
  Slider:"peluncur",
  Set_Details:"Tetapkan Butiran",
  Select_Type:"Pilih Jenis",
  Display_Slider:"Peluncur Paparan",
  Dashboard_Slider:"Peluncur Papan Pemuka",
  Dashboard_Mode:"Mod Papan Pemuka",
  Show_Intro:"Tunjukkan Pengenalan",
  Show_Payrolls:"Tunjukkan Gaji",
  Show_Holidays:"Tunjukkan Cuti",
  Show_Invoices:"Tunjukkan Invois",
  Show_Cheques:"Tunjukkan Cek",
  Enabled_Widgets:"Widget Didayakan",
  Disabled_Widgets:"Widget Dilumpuhkan",
  Colors:"Warna",
  Barcodes:"Kod bar",
  View_Timers:"Lihat Pemasa",
  Gradients:"Kecerunan",
  No_Info:"Tiada Maklumat",
  Disable:"Lumpuhkan",
  Show_Layer:"Tunjukkan Lapisan",
  Hide_Layer:"Sembunyikan Lapisan",
  Text_Layer:"Lapisan Teks",
  Secondly:"Kedua",
  Minutely:"seminit",
  nine_am:"9.00 PAGI",
  five_pm:"5:00 PTG",
  Enable_Address:"Dayakan Alamat",
  Invalid_File_Type:"Maaf, jenis fail yang tidak sah telah dipilih. Jenis fail yang disokong:",
  Error_Updating_Entry:"Maaf, terdapat ralat semasa mengemas kini entri ini.",
  Schedule_Timing_Alert:"Ralat: Masa mula jadual ditetapkan kepada nilai selepas masa tamat.",
  Select_Multiple_Payments_Prompt:"Tiada pembayaran dipilih. Pilih sekurang-kurangnya satu pembayaran.",
  Select_Multiple_Cheques_Prompt:"Tiada semakan dipilih. Sila pilih sekurang-kurangnya satu cek.",
  Select_Multiple_Items_Prompt:"Tiada item dipilih. Sila pilih sekurang-kurangnya satu item.",
  Paymemt_Multiple_Delete_Confirmation:"Adakah anda pasti mahu memadamkan berbilang pembayaran?",
  Cheque_Multiple_Delete_Confirmation:"Adakah anda pasti mahu memadamkan berbilang semakan?",
  Payee_Multiple_Delete_Confirmation:"Adakah anda pasti mahu memadamkan berbilang penerima?",
  Employee_Multiple_Delete_Confirmation:"Adakah anda pasti mahu memadamkan berbilang pekerja?",
  MICR_Warning:"Nota: Sesetengah pencetak dan peranti mungkin tidak memaparkan fon MICR dengan betul.",
  Automatically_Send:"Hantar secara automatik",
  Type:"taip",
  Payment_Type:"Jenis pembayaran",
  Auto_Send:"Hantar Auto",
  Automatically_Process:"Proses Automatik",
  Auto_Process:"Proses Auto",
  Image_Based:"Berasaskan imej",
  Font_Based:"berasaskan fon",
  Rerender:"Paparan semula",
  Rendering:"Penyampaian",
  Render:"Fail",
  Top:"Atas",
  Middle:"Tengah",
  Bottom:"Bawah",
  Top_Left:"Atas Kiri",
  Top_Center:"Pusat Atas",
  Top_Right:"Atas Kanan",
  Middle_Left:"Tengah Kiri",
  Middle_Center:"Tengah Tengah",
  Middle_Right:"Tengah Kanan",
  Bottom_Left:"Dibahagian bawah kiri",
  Bottom_Center:"Pusat Bawah",
  Bottom_Right:"Bawah Kanan",
  Numbers:"Nombor",
  Verified:"Disahkan",
  Paper_Size:"Saiz Kertas",
  New_Device:"Peranti Baharu",
  Add_Device:"Tambah peranti",
  Remove_Device:"Alih Keluar Peranti",
  Delete_Device:"Padamkan Peranti",
  Block_Device:"Sekat Peranti",
  Block:"Sekat",
  Unblock:"Nyahsekat",
  Table:"Jadual",
  Scan_Login_Code:"Imbas Kod Log Masuk",
  Login_Code:"Kod Log Masuk",
  Scan_Code:"Kod Imbasan",
  Scan_QR_Code:"Imbas Kod QR",
  Select_All:"Pilih semua",
  Deselect_All:"Nyahpilih Semua",
  Increase:"Meningkat",
  Decrease:"Kurangkan",
  Bold:"berani",
  Text:"Teks",
  Style:"Gaya",
  Italic:"Italic",
  QR_Code:"Kod QR",
  Barcode:"Kod bar",
  Browse_Images:"Semak imbas Imej",
  Browse_Files:"Semak imbas Fail",
  Background_Image:"Imej Latar Belakang",
  Logo_Image:"Imej Logo",
  Header_Image:"Imej Tajuk",
  Bank_Image:"Imej Bank",
  Cut_Lines:"Potong Garisan",
  Background:"Latar belakang",
  License:"Lesen",
  One_License:"1 Lesen:",
  Licensed:"Berlesen kepada:",
  Not_Licensed:"Tidak Berlesen",
  Enter_Serial:"Masukkan Serial",
  Serial_Key:"Kunci Bersiri",
  Serial:"bersiri",
  Product_Key:"Kunci produk",
  Check_Product_Key:"Semak Kunci Produk",
  Add_Product_Key:"Tambah Kunci Produk",
  Verifying_Purchase:"Mengesahkan Pembelian...",
  Print_Envelope:"Cetak Sampul Surat",
  Envelope:"sampul surat",
  Thank_You:"Terima kasih!",
  Scale:"Skala",
  Print_Scale:"Skala Cetakan",
  Borders:"Sempadan",
  VOID:"KOSONG",
  Void_Cheque:"Cek Batal",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"BAYAR KEPADA PESANAN:",
  NO_DOLLARS:"TIADA DOLAR ",
  ONE_DOLLAR:"SATU DOLAR",
  DOLLARS:" DOLLAR",
  AND:" DAN ",
  CENTS:" SEN.",
  NO_:"TIDAK ",
  ONE_:"SATU ",
  AND_NO_:"DAN TIDAK ",
  _AND_ONE_:"DAN SATU ",
  DOLLARS_AND_ONE_CENT:"DAN SATU SEN.",
  AND_NO_CENTS:" DAN SIFAR SEN.",
  CHEQUE_PRINT_DATE:"TARIKH:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"Ahli Parlimen",
  Initial_Tasks:"Tugas Awal",
  Inital_Setup:"Persediaan Awal",
  Welcome_To:"Selamat datang ke ",
  Welcome:"Selamat datang",
  Swipe:"Leret",
  Intro_Setup:"Persediaan Pengenalan",
  Introduction:"Pengenalan",
  CHEQUE_PRINT_CREDIT:"Dikuasakan oleh Cek",
  Title_Intro_Word:"Selamat datang ke Cek",
  Title_Intro:"Pengenalan Cek",
  Title_Setup:"Sedia Cek",
  PayPal_Default_Email_Message:"Anda telah menerima pemindahan PayPal baharu. [Dikuasakan oleh Cek]",
  Cheques_App_Export:"Dieksport melalui Cek",
  Post_Intro_Thanks:"Terima kasih kerana memilih Cek. Proses persediaan kini selesai.",
  Post_Intro_Word:"Mulakan dengan mencetak cek pertama anda, tambah bayaran masa hadapan atau tambah pekerja pada senarai gaji.",
  Upgrade_Required:"Cek memerlukan anda memiliki versi perisian yang lebih premium untuk menyembunyikan mesej ini dan membuka kunci ciri tambahan.",
  Upgrade_Title:"Cek",
  Mailout_Prompt:"Anda juga boleh memilih untuk meminta Cek mengeposkan cek daftar gaji anda untuk anda.",
  Mailed_Cheque:"Cek Mel: ",
  Mailed_Cheque_Color:"Cek Mel (Warna): ",
  Terms_Purchase:"Semua pembelian elektronik dengan Cek boleh dibayar balik sepenuhnya sehingga 30 hari dari tarikh pembelian. Sila baca dan bersetuju menerima <a href='#'>Terma dan Syarat</a> sebelum meneruskan.",
  Dashboard_Setup:"Sediakan Pencetak Utama",
  Dashboard_Add:"Tambah Akaun Bank Utama",
  Dashboard_Customize:"Pilih Templat Semak",
  Dashboard_Job_Salary:"Buat Pekerjaan Anda & Tambah Gaji Anda",
  Dashboard_Employees:"Jejaki Pekerja & Penerima",
  Dashboard_Print:"Cetak & Hantar Cek Gaji Anda",
  Dashboard_Payroll:"Automasikan Pencetakan Gaji Anda",
  Dashboard_PayPal:"Sediakan Payroll / Pembayaran PayPal",
  Begin_Setup:"Mulakan Persediaan",
  Get_Started:"Mulakan",
  Purchase:"Beli",
  Lockdown:"Penutupan dan penyekatan",
  Unlock:"Buka kunci",
  Detailed:"Terperinci",
  Log_In:"Log masuk",
  Login:"Log masuk",
  Launch:"Pelancaran",
  Register:"Daftar",
  Finish:"Selesai",
  List:"Senarai",
  Weekends:"Hujung minggu",
  Weekly:"Setiap minggu",
  PayPal_Default_Subject:"Bayaran Baharu",
  Payment_Message:"Mesej Pembayaran",
  PayPal_Default_Payment_Note:"Terima kasih",
  Setup_Your_Cheqing_Account:"Semak Akaun",
  Add_Your_Primary_Cheqing_Account:"Tambahkan akaun cheqing utama anda.",
  Welcome_Word:"Memudahkan dan mengautomasikan senarai gaji dan pengurusan sumber manusia.",
  Get_Started_Quickly:"Hanya jawab beberapa soalan mudah yang akan membantu kami bermula...",
  Done_Intro_Word:"Persediaan Selesai",
  PIN_Protected:"Kata Laluan & PIN Dilindungi",
  Enter_New_PIN:"Masukkan Kod PIN baharu:",
  Enter_PIN:"Masukkan Kod PIN:",
  Invalid_PIN:"PIN tidak sah dimasukkan.",
  Account_Identifier:"Pengecam Akaun",
  New_Account_Identifier:"Pengecam Akaun Baharu",
  attempt:"percubaan",
  attempts:"percubaan",
  remaining:"baki",
  Language:"Bahasa",
  languages:"Bahasa",
  select_languages:"Pilih Bahasa",
  Deposit:"Deposit",
  Hire_One_Now:"Upah Satu Sekarang",
  App_Locked:"Aplikasi dikunci.",
  Skip_:"Langkau",
  Skip_to_Dashboard:"Langkau ke Papan Pemuka",
  Dashboard:"Papan pemuka",
  Import:"Import",
  Admin:"Pentadbir",
  New_Admin:"Pentadbir Baharu",
  Settings:"Tetapan",
  Color_Picker:"Pemilih Warna",
  Font_Picker:"Pemilih Fon",
  Logout:"Log keluar",
  Close:"Tutup",
  Close_menu:"Tutup",
  Excel:"Fail Excel",
  Csv:"Fail CSV",
  Sql:"Fail SQL",
  Json:"Fail JSON",
  Url:"Import melalui URL",
  Back:"Kembali",
  Timers:"Pemasa",
  Cheque:"Semak",
  Print:"Cetak",
  Designs:"Reka bentuk",
  Pause_Printing:"Jeda Pencetakan",
  Resume_Printing:"Sambung Pencetakan",
  Printing_Paused:"Pencetakan Dijeda",
  PrintingUnavailable:"Maaf! Pencetakan tidak tersedia pada sistem ini.",
  Prints_Paused:"Cetakan Dijeda",
  Administration:"Pentadbiran",
  Loading:"Memuatkan",
  Unnamed:"Tidak bernama",
  error:"Maaf, cek ini tidak dapat dibuka untuk tontonan.",
  No_Cheques_To_Print:"Tiada Cek Untuk Dicetak",
  No_Cheques_To_Print_Word:"Buat <a routerLink='/folder/Cheque/New'>Cek Baharu</a>.",
  New_Cheque:"Cek Baharu",
  Start_One_Now:"Mulakan Satu Sekarang",
  Edit_Cheque:"Edit Semak",
  Select_Cheques:"Pilih Semakan",
  Select_Employee:"Pilih Pekerja",
  Default_Printer:"Pencetak Lalai",
  Reassign:"Tugas semula",
  Configure:"Konfigurasikan",
  Template:"Templat",
  Designer:"Pereka",
  Edit_Designs:"Edit Reka Bentuk",
  New_Design:"Reka Bentuk Baharu",
  Next:"Seterusnya",
  Save:"Simpan",
  Name:"Nama",
  Mail:"mel",
  Amount:"Jumlah",
  Date:"Tarikh",
  PayPal:"PayPal",
  Payouts:"Pembayaran",
  PayPal_Label:"Label PayPal",
  Email_Username:"E-mel / Nama Pengguna",
  Client_ID:"ID Pelanggan",
  Sandbox_Email:"E-mel Kotak Pasir",
  PayPal_Email:"E-mel PayPal",
  PayPal_Username:"Nama Pengguna API",
  PayPal_Payouts:"Pembayaran PayPal",
  Select_PayPal_Key:"Pilih Kunci PayPal",
  Secret:"Rahsia",
  API_Secret:"Rahsia API",
  PayPal_API_Keys:"Kunci PayPal",
  New_PayPal_Key:"Kunci PayPal Baharu",
  Email_Subject:"Subjek email",
  Email_Message:"Mesej E-mel",
  Payout_Options:"Pilihan Pembayaran",
  Payment_Note:"Nota Pembayaran",
  Enable_Employee:"Dayakan Pekerja",
  Enable_Production_Mode:"Dayakan Mod Pengeluaran",
  Sandbox_Username:"Nama Pengguna Kotak Pasir",
  Sandbox_Signature:"Tandatangan Kotak Pasir",
  Sandbox_Password:"Kata Laluan Kotak Pasir",
  Production_Username:"Nama Pengguna Pengeluaran",
  Production_Signature:"Tandatangan Pengeluaran",
  Production_Password:"Kata Laluan Pengeluaran",
  Production_Email:"E-mel Pengeluaran",
  API_Client_ID:"ID Klien API",
  API_Signature:"Tandatangan API",
  API_Password:"Kata Laluan API",
  API_Username:"Nama Pengguna API",
  Secret_Key:"Kunci Rahsia",
  Sandbox:"Kotak pasir",
  Production:"Pengeluaran",
  Sandbox_Keys:"Kunci Kotak Pasir",
  Production_Keys:"Kunci Pengeluaran",
  API_Title:"Tajuk API",
  Production_Mode:"Mod Pengeluaran",
  Account_Label:"Label Akaun",
  No_PayPal_Setup:"Tiada Kunci PayPal",
  Enable_PayPal_Account:"Dayakan Akaun PayPal",
  No_PayPal_Word:"Tambahkan <a routerLink='/folder/Invoice/New'>Kunci API PayPal</a> anda.",
  Printed_Memo:"Memo Bercetak",
  Employee:"Pekerja",
  View_Employee:"Lihat Pekerja",
  Mailing_Address:"Alamat Mel",
  Company_Address:"Alamat syarikat",
  Select_Company_Address:"Pilih Alamat Syarikat",
  Address:"Alamat",
  Any_Day:"Mana-mana hari",
  Address_Name:"Nama Alamat",
  Unit:"Unit",
  Account:"Akaun",
  Bank_Account:"Akaun bank",
  Account_Limits:"Dayakan Had Akaun",
  Payroll:"Gaji",
  New_Payroll:"Gaji Baharu",
  No_Payroll:"Tiada Gaji Akan Datang",
  Upcoming_Holiday:"Cuti Akan Datang:",
  Invoice_Due:"Invois Perlu Dibayar:",
  New_Invoice:"Invois Baharu",
  No_Invoices:"Tiada Invois",
  No_Invoices_Word:"Buat <a routerLink='/folder/Invoice/New'>Invois</a> yang pertama.",
  Cheque_Due:"Cek Perlu Dibayar:",
  Payrolls:"Gaji",
  Sandbox_Mode:"Mod Kotak Pasir",
  Hire:"Sewa",
  Pay:"Bayar",
  New:"Baru",
  Add:"Tambah",
  Make:"Buat",
  Time:"Masa",
  Write:"Tulis",
  Holiday:"Cuti",
  Holidays:"Cuti",
  Next_Holiday:"Cuti Seterusnya:",
  All_Done:"Semua Selesai!",
  Employees:"Pekerja",
  Payees:"Pembayar",
  Job:"Pekerjaan",
  Jobs:"Pekerjaan",
  Invoice:"Invois",
  Invoices:"Invois",
  Vacations:"Percutian",
  Cheques:"Cek",
  Brand_Cheques:"Jenama",
  Payment:"Pembayaran",
  Enable_Payment:"Dayakan Pembayaran",
  Payments:"Pembayaran",
  Schedule:"Jadual",
  Schedules:"Jadual",
  Timesheet:"Lembaran Masa",
  Timesheets:"Lembaran Masa",
  Salary:"Gaji",
  New_Address:"Alamat Baru",
  Address_2:"Alamat 2)",
  _City:"Bandar",
  _State:"Negeri/Prov",
  City:"Bandar / Perbandaran",
  State:"Negeri / Wilayah",
  Postal:"Pos / Poskod",
  ZIP:"Pos / ZIP",
  Country:"Negara",
  Addresses:"Alamat",
  Required_Options:"Pilihan yang Diperlukan",
  Optional_Options:"Pilihan Pilihan",
  Additional_Options:"Pilihan Tambahan",
  Required:"Diperlukan",
  Optional:"Pilihan",
  Additional:"Tambahan",
  No_Addresses:"Tiada Alamat",
  New_Address_Word:"Tambahkan <a routerLink='/folder/Address/New'>Alamat</a> yang pertama.",
  Select_Address:"Pilih Alamat",
  No_Address:"Tiada Alamat",
  Print_Cheque:"Cetak Semakan",
  Print_Cheque_Now:"Cetak Semak Sekarang",
  Description:"Penerangan",
  Pay_To_The_Order_Of:"Bayar kepada Pesanan:",
  Select_Date_Range:"Pilih Julat Tarikh",
  Select_Starting_Date:"Pilih Tarikh Mula",
  Select_Ending_Date:"Pilih Tarikh Tamat",
  Select_Date:"Pilih Tarikh",
  Cheque_Date:"Semak Tarikh",
  Cheque_Memo:"Semak Memo",
  Blank_Cheque:"Cek Kosong",
  Blank:"Kosong",
  No_Cheques:"Tiada Cek",
  No_Cheques_Word:"Cetak <a routerLink='/folder/Cheque/New'>Semak</a> pertama anda.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"Lihat gambar",
  View:"Lihat",
  Modify:"Ubah suai",
  Delete:"Padam",
  Cheque_Paid:"Dibayar",
  New_Deduction:"Potongan Baharu",
  Title:"Tajuk",
  Frequency:"Kekerapan",
  Hourly:"Setiap jam",
  Daily:"Setiap hari",
  Weekdays:"Hari minggu",
  BiWeekly:"2 minggu",
  TriWeekly:"3 minggu",
  Monthly:"Bulanan",
  MiMonthly:"2 bulan",
  Quarterly:"Suku Tahun",
  Yearly:"Setahun",
  Every_Week:"Setiap minggu",
  Every_Payroll:"Setiap Gaji",
  Every_Month:"Setiap bulan",
  Annually:"Setiap tahun",
  Always_Scheduled:"Sentiasa Dijadualkan",
  Start_Date:"Tarikh mula",
  End_Date:"Tarikh tamat",
  Start_Time:"Masa mula",
  End_Time:"Masa tamat",
  Deduction_Label:"Label Potongan",
  Notes:"Nota",
  Options:"Pilihan",
  Enable:"Dayakan",
  Select_Deductions:"Pilih Potongan",
  Deductions:"Potongan",
  No_Deductions:"Tiada Potongan",
  No_Deductions_Word:"Buat <a routerLink='/folder/Deduction/New'>Potongan</a> pertama anda.",
  New_Employee:"Pekerja baru",
  No_Title:"Tiada tajuk",
  _Name:"Nama",
  About_Yourself:"Mengenai diri anda",
  Full_Name:"Nama penuh",
  Birthday:"Hari Lahir",
  Email:"E-mel",
  SMS:"SMS",
  Phone:"Telefon",
  Test:"Ujian",
  Call:"Panggil",
  Fax:"Faks",
  Printed_Note:"Nota Bercetak",
  Position:"Kedudukan",
  Job_Position:"Jawatan kerja",
  Select_a_Job:"Pilih Pekerjaan",
  Select_a_Salary:"Pilih Gaji",
  Add_a_Deduction:"Tambah Potongan",
  Taxes:"Cukai",
  Add_Taxes:"Tambah Cukai",
  Date_of_Birth:"Tarikh lahir",
  Email_Address:"Alamat emel",
  Phone_Number:"Nombor telefon",
  Phone_Call:"Panggilan telefon",
  Enable_on_Payroll:"Dayakan pada Penyata Gaji",
  Select_Employees:"Pilih Pekerja",
  No_Employees:"Tiada Pekerja",
  No_Employees_Word:"Tambahkan <a routerLink='/folder/Employee/New'>Pekerja</a> baharu pertama anda.",
  No_Name:"Tiada nama",
  Unemployeed:"Penganggur",
  Employeed:"Bekerja",
  Paid:"Dibayar",
  Enabled:"Didayakan",
  Disabled:"Dilumpuhkan",
  Fire:"Api",
  Not_Available:"Tidak Tersedia",
  Not_Available_Word:"Cetak <a routerLink='/folder/Invoice/New'>Invois</a> pertama anda & dapatkan bayaran.",
  Select_Invoices:"Pilih_Invois",
  Print_Invoice_Word:"Cetak <a routerLink='/folder/Invoice/New'>Invois</a> pertama anda & dapatkan bayaran.",
  Invoice_Title:"Tajuk Invois",
  Invoice_Date:"Tarikh invois",
  Due_Date:"Tarikh Tamat",
  New_Job:"Pekerjaan baru",
  Details:"Butiran",
  Customize:"Sesuaikan",
  Customize_Dashboard:"Sesuaikan Papan Pemuka",
  Components:"Komponen",
  No_Components:"Tiada Komponen",
  Main_Components:"Komponen Utama",
  Smaller_Components:"Komponen Lebih Kecil",
  Error_Loading_Page:"Ralat memuatkan halaman ini.",
  Bank_Details:"Butiran Bank",
  About_Your_Job:"Tentang Pekerjaan Anda",
  Your_Schedule:"Jadual Anda",
  Job_Title:"Jawatan Kerja",
  Job_Description:"Deskripsi kerja",
  Job_Details:"Butiran Kerja",
  Enable_Job:"Dayakan Kerja",
  Pay_Period:"Tempoh Bayar",
  Perpetually_Schedule:"Jadualkan Selalu",
  Select_Jobs:"Pilih Pekerjaan",
  No_Jobs:"Tiada Pekerjaan",
  Add_Jobs:"Tambah Pekerjaan",
  No_Jobs_Word:"Tambahkan <a routerLink='/folder/Job/New'>Kerja</a> pertama pada senarai.",
  Count_Employees:"job.employee_count+' Employees'",
  Zero_Employees:"0 Pekerja",
  New_Leave:"Cuti Baru",
  Leave_Name:"Tinggalkan Nama",
  Paid_Leave:"Cuti berbayar",
  Leave_Pay:"Cuti Gaji",
  Indefinite_Leave:"Cuti Tanpa Had",
  Indefinite_Payroll:"Gaji Tidak Tertentu",
  Leave:"Pergi",
  Add_Schedules:"Tambah Jadual",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Dayakan Ketiadaan",
  Select_Leaves:"Pilih Daun",
  No_Leaves:"Tiada Daun Ketiadaan",
  Leave_Of_Absence:"Cuti Tidak Hadir",
  Leaves_Of_Absence:"Daun Ketiadaan",
  New_Leave_of_Absense:"Cuti Baru",
  No_Leaves_Word:"Tambahkan <a routerLink='/folder/Leave/New'>Cuti of Absence</a> yang pertama.",
  Not_Enabled:"Tidak Didayakan",
  Absences:"Ketidakhadiran",
  Payee:"Pembayar",
  New_Payee:"Pembayar Baru",
  Payee_Identifier:"Pengecam Penerima",
  Payee_Name:"Nama pembayar",
  Payee_Title:"Gelaran Penerima",
  Payment_Memo:"Memo Pembayaran",
  Select_Payees:"Pilih Penerima",
  No_Payees:"Tiada Penerima",
  Add_Payee_Note:"Tambahkan <a routerLink='/folder/Payee/New'>Pembayar</a> pertama.",
  New_Payees:"Pembayar Baru",
  About_The_Payment_Schedule:"Jadual pembayaran",
  Your_Payroll_Schedule:"Gaji Automatik",
  New_Payment:"Bayaran Baharu",
  Identifier:"Pengecam",
  Select:"Pilih",
  Memo:"Memo",
  Payment_Date:"Tarikh pembayaran",
  Mark_as_Paid:"Tandai sebagai Dibayar",
  Select_Payments:"Pilih Pembayaran",
  No_Payments:"Tiada Pembayaran",
  No_Payments_Word:"Buat <a routerLink='/folder/Payment/New'>Pembayaran</a> yang pertama.",
  Create_Payroll:"Buat Penyata Gaji",
  Properties:"Hartanah",
  Payroll_Title:"Tajuk Gaji",
  Payroll_Notes:"Nota Gaji",
  Payroll_Setup:"Persediaan Gaji",
  Tabulate_Payments:"Jadualkan Pembayaran",
  Tabulate:"Jadual",
  By:"Oleh:",
  Payments_By:"Pembayaran Oleh",
  Timesheets_And_Schedules:"Helaian Masa & Jadual",
  Both:"Kedua-duanya",
  Items:"Item",
  Add_Payees:"Tambah Penerima",
  Add_Account:"Tambah akaun",
  Enable_Account:"Dayakan Akaun",
  Enable_Payroll:"Dayakan Penyata Gaji",
  Print_Payroll:"Cetak Gaji",
  No_Payrolls:"Tiada Gaji",
  No_Payroll_Word:"Buat <a routerLink='/folder/Payroll/New'>Gaji</a> pertama anda.",
  View_more:"VIEW_MORE",
  Less:"KURANG",
  Add_Payroll:"Tambah Gaji",
  Select_Payroll:"Pilih Gaji",
  About_Your_Salary:"Tentang Gaji Anda",
  Add_Salaries:"Tambah Gaji",
  Add_Salary:"Tambah Gaji",
  Salaries:"Gaji",
  No_Salaries:"Tiada Gaji",
  No_Salaries_Word:"Tambahkan <a routerLink='/folder/Gaji/Baru'>Gaji</a> yang pertama.",
  Select_Salaries:"Pilih Gaji",
  New_Salary:"Gaji Baru",
  Salary_Name:"Pengecam Gaji",
  Enable_Salary:"Dayakan Gaji",
  Salary_Amount:"Jumlah Gaji",
  New_Schedule:"Jadual Baharu",
  Schedule_Title:"Tajuk Jadual",
  Add_Address:"Tambah Alamat",
  Repeat:"Ulang",
  Design:"Reka bentuk",
  Edit_Design:"Edit Reka Bentuk",
  Edit_this_Design:"Edit Reka Bentuk ini",
  Repeat_Payment:"Bayaran Ulang",
  Enable_Schedule:"Dayakan Jadual",
  Never:"Tidak pernah",
  Select_Schedule:"Pilih Jadual",
  No_Schedules:"Tiada Jadual",
  No_Schedules_Word:"Buat <a routerLink='/folder/Schedule/New'>Jadual</a> yang pertama.",
  New_Administrator:"Pentadbir Baharu",
  Username:"Nama pengguna",
  First_Name:"Nama pertama",
  Last_Name:"Nama terakhir",
  Add_an_Address:"Tambah Alamat",
  Payment_Limit:"Had Setiap Pembayaran",
  Total_Limit:"Jumlah Had",
  Select_Accounts:"Pilih Akaun",
  No_Administrators:"Tiada Pentadbir",
  No_Administrators_Word:"Buat <a routerLink='/folder/Administrator/New'>Akaun Pentadbir</a> yang pertama.",
  New_Administrators_Word:"Tambahkan <a routerLink='/folder/Administrator/New'>Pentadbir</a> yang pertama",
  Cloud:"Awan",
  Backup:"Sandaran",
  Enable_iCloud:"Dayakan iCloud",
  Enable_Google_Drive:"Dayakan Google Drive",
  Enable_Microsoft_OneDrive:"Dayakan Microsoft OneDrive",
  Automatically_Backup:"Sandarkan Secara Automatik",
  FTP_Settings:"Tetapan FTP",
  URL_File_Prompt:"Sila nyatakan lokasi untuk fail .xls / .xlsx / .json untuk diimport:",
  URL_SQL_Prompt:"Sila nyatakan lokasi fail SQLite untuk diimport:",
  FTP_Backup:"Sandaran FTP",
  FTP_Import:"Import FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Hos",
  Port:"Pelabuhan",
  Path:"Laluan",
  Data_Path:"Laluan Data",
  Enable_FTP_Account:"Dayakan Akaun FTP",
  HostnameIP:"Nama hos",
  Password:"Kata Laluan",
  Connection_Port:"Port Sambungan",
  Enable_MySQL_Database:"Dayakan Pangkalan Data MySQL",
  Log:"Log",
  Reset:"Tetapkan semula",
  Erase:"Padam",
  Export:"Eksport",
  Database:"Pangkalan data",
  Upload_CSV:"Muat naik CSV",
  Download_CSV:"Muat turun CSV",
  SQL_Database:"URL SQL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Sandaran MySQL",
  Internal_Notes:"Nota Dalaman",
  Root_Path:"Laluan Akar",
  Select_Backup:"Pilih Sandaran",
  Add_New_Backup:"Tambah Sandaran Baharu",
  First_Backup:"Sediakan sandaran pertama...",
  Backups:"Sandaran",
  Add_Backup:"Tambah Sandaran",
  No_Backups:"Tiada sandaran untuk ditemui.",
  Select_Backup_Type:"Pilih jenis sandaran yang anda ingin sediakan...",
  Backup_Type:"Jenis Sandaran",
  FTP_Drive:"FTP Drive",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Memandu",
  Microsoft_OneDrive:"Memandu",
  Import_Fields:"Import Medan",
  Import_Fields_Word:"Gunakan bahagian ini untuk memilih <a routerLink='/folder/Holiday/New'>Import</a> data.",
  Upload:"Muat naik",
  Download:"Muat turun",
  Download_JSON:"Muat turun sebagai Data JSON",
  Download_SQL:"Muat turun sebagai Fail SQL",
  New_Bank:"Bank Baru",
  New_Account:"Akaun baru",
  New_Bank_Account:"Akaun Bank Baharu",
  Upload_Image:"Muat naik Imej",
  Bank_Name:"Nama bank",
  Bank_Address:"Alamat bank",
  Branch_Address:"Alamat cawangan",
  Address_on_Cheque:"Alamat",
  Cheque_Numbers:"Semak Nombor",
  Cheque_Details:"Semak Butiran",
  Bank_Logo:"Logo Bank",
  Cheque_ID:"Semak ID",
  Starting_Cheque_ID:"ID Semakan Mula",
  Transit_Number:"Nombor Transit",
  Institution_Number:"Nombor Institusi",
  Designation_Number:"Nombor Penetapan",
  Account_Number:"Nombor akaun",
  Limits:"Had",
  Default_Limits:"Had Lalai",
  Over_Limit:"Lebih had",
  Under_Limit:"Di Bawah Had",
  Payroll_Limit:"Had Gaji",
  Enable_Bank_Account:"Dayakan Akaun Bank",
  Select_Account:"Pilih Akaun",
  No_Bank_Account:"Tiada Akaun Bank",
  No_Bank_Account_Word:"Tambahkan <a routerLink='/folder/Accounts/New'>Akaun Bank</a> yang pertama.",
  Bank_Accounts:"Akaun bank",
  No_Accounts:"Tiada Akaun",
  No_Accounts_Note:"Tambahkan <a routerLink='/folder/Accounts/New'>Akaun Bank</a> yang pertama.",
  Cheque_Designer:"Semak Pereka",
  Cheque_Design:"Semak Reka Bentuk",
  Select_Design:"Pilih Reka Bentuk",
  Cheque_Designs:"Semak Reka Bentuk",
  No_Cheque_Designs:"Tiada Reka Bentuk Semak",
  No_Cheque_Designs_Word:"Buat <a routerLink='/folder/Settings/Cheque/Design/New'>Semak Reka Bentuk</a> anda sendiri.",
  Set_Default:"Tetapkan sebagai Lalai",
  Default:"Lalai",
  Remove:"Alih keluar",
  Folder:"Folder",
  Edit:"Edit",
  LoadingDots:"Memuatkan...",
  Add_a_New_File:"Tambah <a href='#' (click)='add()'>Fail Baharu</a> ke",
  this_folder:"folder ini",
  FTP_Backup_Settings:"Tetapan Sandaran FTP",
  Secure_File_Transfer:"Pemindahan Fail Selamat",
  New_Holiday:"Cuti Baru",
  Add_Holiday:"Tambah Cuti",
  Holiday_Name:"Nama Hari Raya",
  Additional_Pay:"Gaji Tambahan",
  Enable_Holiday:"Dayakan Cuti",
  Select_Holidays:"Pilih Cuti",
  No_Holidays:"Tiada Cuti",
  No_Holidays_Word:"Tambahkan <a routerLink='/folder/Holiday/New'>Cuti Umum</a> yang pertama.",
  New_Import:"Import Baharu",
  Import_Data:"Import Data",
  Import_Data_Word:"Pilih jenis fail atau kaedah muat naik yang anda pilih.<br /> Anda akan dapat memilih mana-mana medan yang diimport dalam fail yang sepadan dengan mana- mana parameter dalam apl selepas memuat naik fail yang disokong.",
  Import_File:"Import Fail",
  Link_To_File:"Pautan ke Fail",
  Select_File:"Pilih fail",
  New_Moderator:" Moderator Baharu",
  Allow_Moderation:"Benarkan Kesederhanaan",
  Create_Paycheques:"Buat Cek Gaji",
  Edit_Paycheques_and_Data:"Edit Gaji dan Data",
  Destroy_Data_and_Paycheques:"Hancurkan Data dan Cek Gaji",
  Bonus_Percentage:"Peratusan Gaji",
  Fixed_Amount:"Jumlah Tetap",
  Select_Moderator:"Pilih Moderator",
  No_Moderators:"Tiada Moderator",
  Moderators:"Moderator",
  Moderator_Account:"Buat <a routerLink='/folder/Administrator/New'>Akaun Moderator</a> yang pertama.",
  No_Moderators_Word:"Tambahkan <a routerLink='/folder/Administrator/New'>Moderator</a> yang pertama.",
  Defaults:"Lalai",
  Provide_Us_Info:"Berikan Kami Maklumat",
  Setup_Your_Printer:"Sediakan Pencetak Anda",
  Your_Company:"Tentang Syarikat Anda",
  Company_Name:"Nama syarikat",
  Currency:"Mata wang",
  Default_Currency:"Mata Wang Lalai",
  Base_Monthly_Income:"Pendapatan bulanan",
  Protection:"Perlindungan",
  App_Protection:"Perlindungan Apl",
  PIN_Code_Protection:"Perlindungan Kod PIN",
  App_Protection_Word:"Dayakan kaedah keselamatan yang membantu melindungi akaun anda.",
  PIN_Code:"Kod PIN",
  Change_PIN:"Tukar PIN",
  New_Password:"Kata laluan baharu",
  Geofence_Protection:"Perlindungan Geo-Pagar",
  Geofence_Area:"Tetapkan Kawasan",
  Distance:"Jarak",
  Setup_Now:"Sediakan Sekarang",
  Mile:"Batu",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Pengecaman Muka",
  Face:"Muka",
  Setup:"Persediaan",
  Label:"Label",
  Password_Protection:"Perlindungan Kata Laluan",
  Modify_Password:"Ubah Suai Kata Laluan",
  New_Tax_Entry:"Masukan Cukai Baharu",
  New_Tax:"Cukai Baharu",
  Tax_Label:"Label Cukai",
  Perpetually_Enabled:"Didayakan Selama-lamanya",
  Select_Taxes:"Pilih Cukai",
  Tax_Deductions:"Potongan Cukai",
  No_Tax_Deductions:"Tiada Potongan Cukai",
  No_Tax_Deductions_Word:"Tambahkan potongan <a routerLink='/folder/Tax/New'>Cukai</a> pertama.",
  New_Timer:"Pemasa Baharu",
  Start:"Mula",
  Stop:"Berhenti",
  Start_Timer:"Pemasa Mula",
  Stop_Timer:"Pemasa Berhenti",
  Timer_Active:"Pemasa Aktif",
  Timer:"Pemasa:",
  Timer_Running:"Pemasa: (Berlari)",
  Save_Timer:"Simpan Pemasa",
  New_Timesheet:"Lembaran Masa Baharu",
  Select_Timesheets:"Pilih Helaian Masa",
  Work_Notes:"Nota Kerja",
  Entry_Title:"Tajuk Kemasukan",
  No_Timesheets:"Tiada Lembaran Masa",
  No_Timesheets_Word:"Tambahkan <a routerLink='/folder/Timesheet/New'>Helaian Masa</a> yang pertama.",
  Timesheet_Submitted:"Helaian Masa Dihantar",
  Timesheet_Congrats:"Tahniah! Lembaran masa anda telah berjaya diserahkan. Terima kasih!",
  Timesheet_Copy:"Untuk menerima salinan dokumen anda, sila berikan kami alamat e-mel dan/atau nombor telefon mudah alih anda.",
  Submit:"Serahkan",
  Allow_Notifications:"Benarkan Pemberitahuan",
  Untitled_Entry:"Entri Baru",
  Untitled_Bank:"Bank Tanpa Judul",
  Timesheet_Entry:"Entri Lembaran Masa",
  Work_Description:"Huraian Kerja",
  Enable_Timesheet:"Dayakan Lembaran Masa",
  Submit_Timesheet:"Serahkan Lembaran Masa",
  Vacation:"Percutian",
  New_Vacation:"Percutian Baru",
  Vacation_Name:"Nama Percutian",
  Paid_Vacation:"Percutian Berbayar",
  Vacation_Pay:"Gaji Percutian",
  Enable_Vacation:"Dayakan Percutian",
  Select_Vacations:"Pilih Percutian",
  No_Vacations:"Tiada Percutian",
  No_Vacations_Word:"Buat entri <a routerLink='/folder/Vacation/New'>Percutian</a> pertama.",
  Payroll_Schedule:"Jadual Gaji",
  Next_Payroll:"Gaji Seterusnya:",
  Upcoming_Payroll:"Gaji Akan Datang",
  No_Upcoming_Payroll:"Tiada Gaji Akan Datang",
  Address_Book:"Buku alamat",
  Archived_Documents:"Dokumen Arkib",
  Dev_Mode:"Aplikasi Dalam Mod Pembangunan",
  Administrators:"Pentadbir",
  Privacy:"Privasi",
  Select_Signature:"Pilih Tandatangan",
  No_Signatures:"Tiada Tandatangan",
  No_Signatures_Word:"Tambahkan <a routerLink='/folder/Signature/New'>Tandatangan</a> yang pertama.",
  Repeat_Indefinitely:"Ulang Selama-lamanya",
  Sign:"Tanda",
  Sign_Here:"Tandatangan di sini",
  Date_Signed:"Tarikh Ditandatangani",
  Signature_Pad:"Pad Tandatangan",
  Account_Holder:"Pemegang akaun",
  Account_Properties:"Sifat Akaun",
  Name_On_Cheque:"Nama pada Cek",
  Server_Hostname:"Nama Hos Pelayan / IP",
  Printers:"Pencetak",
  No_Printers:"Tiada Pencetak",
  Printer_Exists:'Pencetak dengan nama ini sudah wujud.',
  No_Printers_Word:"Tambahkan <a routerLink='/folder/Printer/New'>Pencetak</a> yang pertama.",
  No_Printer_Alert:"Tiada pencetak ditentukan. Adakah anda ingin menyediakan pencetak?",
  Add_Printer:"Tambah Pencetak",
  New_Printer:"Pencetak Baharu",
  Printer_Hostname:"Nama Hos Pencetak / IP",
  Printer_Label:"Label Pencetak",
  Printer_Protocol:"Protokol Pencetak",
  Protocol:"Protokol",
  Email_Print:"E-mel",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Soket",
  Print_Job:"Kerja Cetak",
  Printed:"Dicetak",
  Not_Printed:"Tidak Dicetak",
  Print_Jobs:"Kerja Cetak",
  Print_Queue:"Cetak Baris Gilir",
  No_Print_Jobs:"Tiada Kerja Cetak",
  No_Prints:"Buat <a routerLink='/folder/Cheque/New'>Cek</a> baharu untuk dicetak.",
  Prints:"Cetakan",
  Find_Printer:"Cari Pencetak",
  Find_AirPrint_Devices:"Cari Peranti AirPrint",
  Select_Printer:"Pilih Pencetak",
  System_UI:"UI Sistem",
  Printer:"Mesin pencetak",
  Status:"Status",
  Preview:"Pratonton",
  Enable_Print_Job:"Dayakan Kerja Cetak",
  Queue_Weight:"Berat Beratur",
  Unlimited:"Tidak terhad",
  Show_Advanced_Printer_Options:"Tunjukkan Pilihan Pencetak Lanjutan",
  Advanced:"Maju",
  Location:"Lokasi",
  Note:"Nota",
  Queue_Name:"Nama giliran",
  Pages_Printed_Limit:"Had Cetakan Halaman",
  MultiPage_Idle_Time:"Masa Menunggu Berbilang Halaman",
  Page_Count_Limit:"Had Kiraan Halaman",
  Page_Orientation:"Orientasi Halaman",
  Portrait:"Potret",
  Landscape:"Lanskap",
  Duplex:"Dupleks",
  Double_Sided_Printing:"Dua belah",
  Duplex_Mode:"Mod Dupleks",
  Duplex_Short:"Pendek",
  Duplex_Long:"Panjang",
  Duplex_None:"Tiada",
  Color_And_Quality:"Warna Dan Kualiti",
  Monochrome:"Monokrom",
  Photo_Quality_Prints:"Cetakan Kualiti Foto",
  Printer_Email:"E-mel Pencetak",
  Automatically_Downsize:"Kurangkan Saiz Secara Automatik",
  Paper:"Kertas",
  Paper_Name:"Nama Kertas",
  Paper_Width:"Lebar Kertas",
  Paper_Height:"Ketinggian Kertas",
  Paper_Autocut_Length:"Panjang Potongan Auto Kertas",
  Margins:"Margin",
  Page_Margins:"Pinggir Halaman",
  Page_Margin_Top:"Margin Halaman Atas",
  Page_Margin_Right:"Margin Halaman Kanan",
  Page_Margin_Bottom:"Margin Halaman Bawah",
  Page_Margin_Left:"Margin Halaman Kiri",
  Add_Employees:"Tambah Pekerja",
  Header:"Kepala",
  Print_A_Page_Header:"Cetak Pengepala Halaman",
  Header_Label:"Label Pengepala",
  Header_Page_Index:"Indeks Halaman Pengepala",
  Header_Font_Name:"Fon Pengepala",
  Select_Font:"Pilih Fon",
  Font_Selector:"Pemilih Fon",
  Header_Font_Size:"Fon Pengepala",
  Header_Bold:"Tajuk Tebal",
  Header_Italic:"Tajuk Italic",
  Header_Alignment:"Penjajaran Pengepala",
  Left:"Dibiarkan",
  Center:"Pusat",
  Right:"Betul",
  Justified:"Wajar",
  Header_Font_Color:"Warna Pengepala",
  Select_Color:"Pilih Warna",
  Footer:"Pengaki",
  Print_A_Page_Footer:"Cetak Pengaki Halaman",
  Footer_Label:"Label Pengaki",
  Footer_Page_Index:"Indeks Halaman Pengaki",
  Footer_Font_Name:"Fon Pengaki",
  Fonts:"Fon",
  Done:"Selesai",
  Select_Fonts:"Pilih Fon",
  Footer_Font_Size:"Saiz Kaki",
  Footer_Bold:"Footer Bold",
  Footer_Italic:"Footer Italic",
  Footer_Alignment:"Penjajaran Kaki",
  Footer_Font_Color:"Warna Pengaki",
  Page_Copies:"Salinan Halaman",
  Enable_Printer:"Dayakan Pencetak",
  Remote_Logging:"Pengelogan Jauh",
  Log_Server:"Pelayan Log",
  Encryption:"Penyulitan",
  Random_Key:"Kunci Rawak",
  Encryption_Key:"Kunci Penyulitan",
  Cheques_Webserver:"Pangkalan Data Tersuai",
  Learn_How:"Belajar bagaimana",
  Signature:"Tandatangan",
  Default_Printer_Unit:"dalam/cm/mm/(pt)",
  View_Signature:"Lihat Tandatangan",
  Printed_Signature:"Tandatangan Bercetak",
  Digitally_Sign:"Tandatangan Secara Digital",
  Digital_Signature:"Tandatangan digital",
  Add_Signature:"Tambah Tandatangan",
  Add_Your_Signature:"Tambah Tandatangan Anda",
  Enable_Signature:"Dayakan Tandatangan",
  Digitally_Signed:"Ditandatangani Secara Digital",
  Insert_Error:"Tidak dapat menyimpan semakan kerana isu pangkalan data.",
  Delete_Confirmation:"Adakah anda pasti mahu memadamkan maklumat ini?",
  Discard_Confirmation:"Adakah anda pasti mahu membuang maklumat ini?",
  Discard_Bank_Confirmation:"Adakah anda pasti mahu membuang akaun ini?",
  Discard_Printer_Confirmation:"Adakah anda pasti mahu membuang pencetak ini?",
  Delete_Bonus_Confirmation:"Adakah anda pasti mahu memadamkan bonus ini?",
  Delete_Invoice_Confirmation:"Adakah anda pasti mahu memadamkan invois ini?",
  Generated_Cheque:"Semakan Dijana",
  Generated_Invoice:"Invois Dijana",
  Schedule_Start_Time:"Jadual Mula",
  Schedule_End_Time:"Jadual Tamat",
  New_Call:"Panggilan Baharu",
  No_Contacts:"Tiada Kenalan",
  No_Contacts_Word:"Pentadbir, moderator, pekerja dan penerima pembayaran muncul sebagai kenalan.",
  PDF_Subject:"kewangan",
  PDF_Keywords:"cek cek PDF gaji gaji",
  Printer_Setup:"Persediaan Pencetak",
  Printer_Selection:"Pemilihan Pencetak",
  New_Fax:"Faks Baharu",
  New_Fax_Message:"Mesej Faks Baharu",
  Fax_Machine:"Mesin faks",
  Fax_Name:"Nama Faks",
  Fax_Email:"E-mel Faks",
  Fax_Number:"Nombor faks",
  Contents:"Kandungan",
  Fax_Body:"Isi Halaman",
  Header_Word:"Kepala:",
  Header_Text:"Teks Pengepala",
  Include_Header:"Sertakan Pengepala",
  Include_Footer:"Sertakan Pengaki",
  Footer_Word:"Pengaki:",
  Footer_Text:"Teks Pengaki",
  Attach_a_Cheque:"Lampirkan Cek",
  Add_Deduction:"Tambah Potongan",
  Enable_Fax:"Hantar Faks",
  Select_Fax:"Pilih Faks",
  No_Faxes:"Tiada Faks",
  Faxes:"Faks",
  Save_and_Send:"Hantar Faks",
  Save_and_Pay:"Simpan dan Bayar",
  WARNING:"AMARAN:",
  Remember:"Ingat",
  Printing:"Percetakan",
  Printing_Complete:"Pencetakan Selesai!\n\n",
  of:"daripada",
  There_Were:"Ada",
  Successful_Prints:" cetakan yang berjaya dan ",
  Unsuccessful_Prints:"cetakan tidak berjaya.",
  PrinterError:"Maaf! Terdapat ralat.",
  Printing_:"Mencetak...",
  PrinterSuccess:"Dokumen berjaya dicetak.",
  PrintersSuccess:"Dokumen berjaya dicetak.",
  Print_Cheques:"Cetak Cek",
  New_Message:"Mesej baru",
  New_Messages:"Mesej Baharu",
  Read_Message:"Baca Mesej",
  Write_Message:"Tulis Mesej",
  Send_Message:"Menghantar mesej",
  Subject:"Subjek",
  Message:"Mesej",
  Writing:"Menulis...",
  Send:"Hantar",
  Set_Date:"Tetapkan Tarikh",
  Set_Time:"Tetapkan masa",
  Recieve:"Terima",
  Set_as_Default:"Tetapkan sebagai Lalai",
  Default_Account:"Akaun Lalai",
  Default_Design:"Reka Bentuk Lalai",
  Multiple_Cheques:"Cek (Tripple)",
  Account_Mode:"Mod Akaun",
  Multiple_Cheques_Description:"Tiga semakan setiap halaman.",
  Check_and_Table:"Semak & Jadual",
  Check_including_Table:"Semak & jadual perakaunan.",
  Check_Mailer:"Semak Pengirim",
  Check_Mailer_Window:"Semak dengan tetingkap alamat.",
  DocuGard_Table_Top:"Semak & Jadual DocuGard (Atas)",
  DocuGard_Table_Middle:"Semak & Jadual DocuGard (Tengah)",
  DocuGard_Table_Bottom:"Semak & Jadual DocuGard (Bawah)",
  DocuGard_Mailer_Top:"Pemeriksa Surat DocuGard (Atas)",
  DocuGard_Mailer_Middle:"DocuGard Semak Mel (Tengah)",
  DocuGard_Mailer_Bottom:"Pemeriksa Surat DocuGard (Bawah)",
  DocuGard_Three_Cheques:"Pemeriksaan DocuGard (Tripple)",
  DocuGard_Cheque_Top:"Semak DocuGard (Atas)",
  DocuGard_Cheque_Middle:"Semak DocuGard (Tengah)",
  DocuGard_Cheque_Bottom:"Semak DocuGard (Bawah)",
  DocuGard_Three_Cheques_Window:"Tiga semakan pada satu halaman.",
  DocuGard_Table_Top_Window:"Jadual cek & pendapatan.",
  DocuGard_Table_Middle_Window:"Jadual cek & pendapatan.",
  DocuGard_Table_Bottom_Window:"Jadual cek & pendapatan.",
  DocuGard_Mailer_Top_Window:"Semak, meja dan alamat.",
  DocuGard_Mailer_Middle_Window:"Semak, meja dan alamat.",
  DocuGard_Mailer_Bottom_Window:"Semak, meja dan alamat.",
  DocuGard_Cheque_Top_Window:"Periksa kertas selamat.",
  DocuGard_Cheque_Middle_Window:"Periksa kertas selamat.",
  DocuGard_Cheque_Bottom_Window:"Periksa kertas selamat.",
  Basic_Cheque:"Semak (Atas)",
  Basic_Cheque_Print:"Cetak satu cek.",
  Error_Setting_As_Paid:"Ralat Tetapan sebagai Berbayar",
  Add_Attachment:"Tambah lampiran",
  PrinterUnavailable:"Pencetak Tidak Tersedia",
  CreditCardComponent:"Kad",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Deposit Baru",
  Deposits:"Deposit",
  No_Deposits:"Tiada Deposit",
  Credit_Card_Deposit:"Kad kredit",
  New_Credit_Card_Deposit_Message:"Deposit Kad Kredit",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Deposit Bitcoin",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Pemindahan Interak",
  Payments_Limit:"Had Pembayaran",
  No_Payment_Limit:"Tiada Had Pembayaran",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Deposit PayPal",
  No_Deposits_Word:"Tambah syarikat pertama <a routerLink='/folder/Deposit/New'></a>.",
  No_Documents_Specified:"Tiada dokumen dinyatakan untuk dicetak",
  No_Printers_Added:"Tiada pencetak ditemui. Pergi ke Tetapan > Pencetak untuk menambah satu.",
  DB_Erase_Prompt:"Padam sepenuhnya seluruh pangkalan data? AMARAN: Anda akan kehilangan semua maklumat yang disimpan!",
  Console_Warning:"Jangan tampal sebarang teks ke dalam konsol ini. Anda boleh meletakkan diri anda dan/atau syarikat anda pada risiko yang serius.",
  No_Faxes_Word:"Buat <a routerLink='/folder/Fax/New'>Faks</a> yang pertama.",
  Cheque_Delete_Confirmation:"Adakah anda pasti mahu memadamkan cek ini?",
  Design_Delete_Confirmation:"Adakah anda pasti mahu memadamkan reka bentuk ini?",
  Cheque_Pay_Confirmation:"Tandai cek ini sebagai berbayar? Ia TIDAK akan muncul dalam baris gilir cetakan.",
  Payment_Pay_Confirmation:"Tandai pembayaran ini sebagai dibayar? Ia TIDAK akan muncul dalam baris gilir cek.",
  Delete_Deduction_Confirmation:"Adakah anda pasti mahu memadamkan potongan ini?",
  Delete_Job_Confirmation:"Adakah anda pasti mahu memadamkan kerja ini?",
  Delete_Timesheet_Confirmation:"Adakah anda pasti mahu memadamkan helaian masa ini?",
  Delete_Schedule_Confirmation:"Adakah anda pasti mahu memadamkan jadual ini?",
  Delete_Setting_Confirmation:"Adakah anda pasti mahu menetapkan semula tetapan ini?",
  Delete_Fax_Confirmation:"Adakah anda pasti mahu memadamkan faks ini?",
  Delete_File_Confirmation:"Adakah anda pasti mahu memadamkan fail ini?",
  Delete_Vacation_Confirmation:"Adakah anda pasti mahu memadamkan percutian ini?",
  Delete_Printer_Confirmation:"Adakah anda pasti mahu memadamkan pencetak ini?",
  Remove_Design_Confirmation:"Adakah anda pasti mahu memadamkan reka bentuk ini?",
  Delete_Payroll_Confirmation:"Adakah anda pasti mahu memadamkan senarai gaji ini?",
  Send_Fax_Email_Confirmation:"Adakah anda mahu faks dan e-mel dokumen ini?",
  Send_Email_Confirmation:"Adakah anda mahu menghantar e-mel dokumen ini?",
  Send_Fax_Confirmation:"Adakah anda mahu faks dokumen ini?",
  Error_Generating_PDF:"Maaf. Terdapat ralat semasa menjana dokumen ini.",
  PDF_Error_Saving_Image:"Maaf. Terdapat ralat semasa menyimpan imej PDF dokumen ini.",
  Test_Printer_Confirmation:"Adakah anda mahu mencetak halaman ujian pada pencetak ini?",
  Save_Timesheet_Prompt:"Sila tambah 'Tajuk' atau tekan 'Pemasa Mula' untuk menyimpan.",
  Remove_Geofence_Prompt:"Adakah anda pasti mahu mengalih keluar lokasi geo-pagar ini?",
  Delete_Employee_Confirmation:"Adakah anda pasti ingin memadam ",
  Fire_Employee_Confirmation:"Adakah anda pasti mahu menembak"
}