export const Sw = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  Copyright:"Hakimiliki &nakala; 2023",
  black:"Nyeusi",
  green:"Kijani",
  gold:"Dhahabu",
  blue:"Bluu",
  brown:"Brown",
  purple:"Zambarau",
  pink:"Pink",
  red:"Nyekundu",
  Swatches:"Swatches",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Hue",
  Saturation:"Kueneza",
  Lightness:"Wepesi",
  Upgrade_To_Pro:"Pata toleo jipya la Pro",
  AllFeaturesInclude:"Vipengele vyote ni pamoja na:",
  PrintUnlimitedCheques:"Chapisha Hundi Zisizo na Kikomo",
  PremiumChequeDesigns:"Miundo ya Ukaguzi wa Premium",
  ManageUnlimitedEmployees:"Dhibiti Wafanyakazi Wasio na Ukomo",
  AddUnlimitedPayees:"Ongeza Waliolipwa Bila Kikomo",
  CreateUnlimitedPayrolls:"Unda Malipo Yasiyo na Kikomo",
  UnlimitedSchedulesandTimeSheets:"Ratiba zisizo na kikomo na Laha za Wakati",
  BulkPayPalPayouts:"Malipo mengi ya PayPal",
  UnlimitedBankAccounts:"Akaunti za Benki zisizo na kikomo",
  ManageMultipleCompanies:"Dhibiti Kampuni Nyingi",
  TrackInsurancePolicies:"Fuatilia Sera za Bima",
  Bio_MetricProtection:"Ulinzi wa Bio-Metric",
  Geo_FenceLock_OutProtection:"Ulinzi wa Kufungia Nje ya Uzio wa Geo",
  Multiple_Companies_Word:"Kudhibiti kampuni nyingi hakupatikani bila malipo ya Hundi.",
  PayPal_Payouts_Word:"Malipo ya PayPal yamezimwa bila malipo ya Hundi.",
  PINProtection:"Ulinzi wa PIN",
  PasswordProtection:"Ulinzi wa Nenosiri",
  May_Require_Approval:"Huenda ikahitaji idhini.",
  Subscribe:"Jisajili",
  Billed:"Inatozwa",
  Up:"Juu",
  Down:"Chini",
  Positioning:"Kuweka",
  Marker:"Alama",
  Drag_Marker:"Buruta Alama",
  Tagline:"Chapisha Hundi na Uorodheshe Malipo ya Malipo",
  Marker_Word:"Tumia Alama kwa ukubwa wa kipengele.",
  Pinch_Zoom:"Bana Kuza",
  Pinch_Word:"Bana ili kukuza kipengele.",
  Drag:"Buruta",
  Drag_Word:"Tumia kidole chako kuburuta vipengele.",
  subscription_alias_word:"Kusasisha Usajili Kiotomatiki",
  premium_alias_word:"Kifurushi cha Uboreshaji wa Wakati Mmoja",
  print_alias_word:"Chapisha Hundi za Mtu Binafsi",
  mode_alias_word:"Hali",
  Pro:"Pro",
  Pro_word:"Toleo la Pro linahitajika.",
  Cant_Delete_Default_Company_Word:"Samahani, huwezi kufuta kampuni yako chaguomsingi.",
  Reinsert_Default_Designs:"Weka Tena Miundo Chaguomsingi",
  Reinsert_Default_Designs_word:"Je, ungependa kuingiza tena miundo chaguomsingi?",
  Subscription_Active_Disable_Word:"Usajili huu unatumika. Je, ungependa kughairi usajili huu wa Cheques?",
  Company_Logo:"Nembo ya Kampuni",
  ZERO_:"SUFURI",
  Disclaimer:"Kanusho",
  Privacy_Policy:"Sera ya Faragha",
  EULA:"Huangalia EULA",
  Terms_Of_Service:"Masharti ya Huduma",
  Terms_Of_Use:"Masharti ya matumizi",
  Refunds:"Sera ya Kurejesha Pesa",
  Single_Print:"1 Angalia",
  Two_Prints:"2 Hundi",
  Five_Prints:"5 Hundi",
  Ten_Prints:"10 hundi",
  Fifteen_Prints:"15 Hundi",
  Twenty_Prints:"20 Cheki",
  Thirty_Prints:"30 Cheki",
  Image_Added:"Picha Imeongezwa",
  Image_Preview:"Onyesho la Kuchungulia Picha",
  No_Image_Was_Selected:"Hakuna picha iliyochaguliwa.",
  Cheques_Unlimited:"Hundi Bila kikomo",
  _Subscription:"Usajili",
  Subscription:"Hundi - 1 Mwezi",
  Two_Month_Subscription:"Cheki - Miezi 2",
  Three_Month_Subscription:"Cheki - Miezi 3",
  Six_Month_Subscription:"Cheki - Miezi 6",
  Twelve_Month_Subscription:"Cheki - Miezi 12",
  Cheques_Are_Available:"Hundi zinapatikana ili kuchapishwa.",
  Upgrade_Required_Two:"Chagua kifurushi na uguse mara mbili kitufe cha bei ili kuanza ununuzi wako. Chapisha ukaguzi wa kitaalamu unaoonekana rangi kamili kwa sekunde.",
  Month:"Mwezi",
  Due:"Inastahili:",
  Expires:"Muda wake unaisha:",
  Subscription_Active:"Usajili Unatumika",
  Subscription_Inactive:"Usajili Hautumiki",
  Purchase_Additional_Cheques:"Ungependa kununua hundi za ziada?",
  Printable_Cheque:"Hundi Inayoweza Kuchapishwa",
  Printable_Cheques:"Hundi Zinazoweza Kuchapishwa",
  Printable_Cheque_Word:"hundi inapatikana kwenye akaunti yako.",
  Printable_Cheques_Word:"hundi zinapatikana kwenye akaunti yako.",
  Max_Amount_Message:"Kiasi ulichotaja kimefikia juu ya kiwango cha juu kilichowekwa kwa mfumo huu:",
  Terms_Required_Word:"Lazima ukubali makubaliano haya kabla ya kuendelea kutumia Hundi.",
  Subscriptions:"Usajili",
  Product_Upgrades:"Uboreshaji",
  Mailed_Out_Cheques:"Hundi Zilizotumwa kwa Barua",
  Enter_A_Company_Name:"Tafadhali weka jina la kampuni.",
  Single_Cheques:"Hundi Moja",
  Cheque_Golden:"Hundi ya Dhahabu",
  Cheque_Golden_Window:"Ubunifu wa hundi ya dhahabu.",
  Cheque_Green:"Cheki ya Kijani",
  Cheque_Green_Window:"Ubunifu wa kuangalia kijani.",
  Cheque_Red:"Cheki Nyekundu",
  Cheque_Red_Window:"Muundo wa kuangalia nyekundu.",
  Cheque_Yellow:"Cheki ya Njano",
  Cheque_Yellow_Window:"Muundo wa hundi ya njano.",
  Cheque_Statue_of_Liberty:"Sanamu ya Uhuru",
  Cheque_Statue_of_Liberty_Window:"Muundo wa hundi ya Sanamu ya Uhuru.",
  Cheque_Green_Wave:"Wimbi la Kijani",
  Cheque_Green_Wave_Window:"Ubunifu wa kuangalia kijani.",
  Cheque_Golden_Weave:"Dhahabu Weave",
  Cheque_Golden_Weave_Window:"Muundo wa hundi ya dhahabu ya kifahari.",
  Cheque_Green_Sun:"Jua la Kijani",
  Cheque_Green_Sun_Window:"Muundo wa ukaguzi wa kina na wa utulivu.",
  Cheque_Blue_Checkers:"Checkers Bluu",
  Cheque_Blue_Checkers_Window:"Muundo wa kuangalia bluu.",
  Cashiers_Check:"Cheki ya Cashier",
  Cashiers_Check_Window:"Kiolezo cha mtindo wa Cheki wa Cashier.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Muundo wa kuangalia Aqua.",
  Cheque_Golden_Checkers:"Golden Checkers",
  Cheque_Golden_Checkers_Window:"Ubunifu wa hundi ya dhahabu.",
  Upgrade_Unavailable:"Maboresho Hayapatikani",
  Bank_Code_Protection:"Ulinzi wa Nambari ya Benki",
  Bank_Code_Protection_Word:"Linda nambari zako za benki zisitumike kwenye programu hii inayotumika kwenye kifaa kingine au kwa mtumiaji mwingine. Kitendo hiki HALIWEZEKWI KUREVERSIBLE. Ungependa kuendelea?",
  Bank_Code_Protection_Blocked_Word:"Nambari za benki unazojaribu kutumia zimehifadhiwa kwa mtumiaji au kifaa kingine.",
  Bank_Code_Protection_Error_Word:"Uthibitishaji wa nambari umeshindwa. Tafadhali unganisha kwenye intaneti na ujaribu kuongeza akaunti hii ya benki tena.",
  Bank_Code_Protection_Set_Error_Word:"Ulinzi wa nambari ya benki unahitaji uunganishwe kwenye mtandao. Tafadhali unganisha na ujaribu tena.",
  Upgrade_Unavailable_Word:"Samahani, tunatatizika kukuthibitisha. Usajili na uboreshaji kwa Hundi kwa sasa hazipatikani kwa ununuzi katika eneo lako.",
  PayPal_Payment_Preview:"Hakiki ya Malipo ya PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Chagua PayPal",
  PayPal_Applications:"Maombi ya PayPal",
  Purchase_With_Apple_Pay:"Nunua ukitumia Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Makampuni",
  Insurance:"Bima",
  New_PayPal:"PayPal Mpya",
  Pay_By:"Lipa Kwa",
  Insure:"Bima",
  Miles:"Maili",
  Payment_Method:"Njia ya malipo",
  Select_Policies:"Chagua Sera",
  Policies:"Sera",
  Policy:"Sera",
  No_PayPal_Account:"Hakuna Akaunti ya PayPal",
  No_Policies:"Hakuna Sera za Bima",
  New_Policy:"Sera Mpya",
  PayPal_Payment:"Malipo ya PayPal",
  PayPal_Payments:"Malipo ya PayPal",
  No_Payment_Selected:"Hakuna Malipo Yaliyochaguliwa",
  Sending_Payment_Word:"Tafadhali subiri... Malipo haya yanatumwa.",
  Sending_Payments_Word:"Tafadhali subiri... Malipo yanatumwa.",
  No_Payment_Selected_PayPal:"Hakuna <a routerLink='/folder/Payments'>Malipo ya PayPal</a> iliyochaguliwa kutuma.",
  Payment_Sent:"Malipo Yametumwa",
  PayPal_Payment_Sent:"Malipo haya yametumwa kwa PayPal.",
  Copay:"Copay",
  Dead:"Wafu",
  Alive:"Hai",
  Not_Dead:"Sio Wafu",
  Unclaimed:"Haijadaiwa",
  Attempted:"Imejaribu",
  Deceased:"Marehemu",
  Claimed:"Alidai",
  Unpaid:"Haijalipwa",
  Sending_Payment:"Kutuma Malipo",
  Sending_Payments:"Kutuma Malipo",
  Send_PayPal_Confirmation:"Je, ungependa kutuma muamala huu kwa PayPal?",
  Send_PayPal_Confirmation_Word:"Bonyeza kitufe cha kijani kutuma muamala huu.",
  Save_Payment_As_Unpaid:"Ungependa kuhifadhi malipo haya kama ambayo hayajalipwa?",
  Payment_Pay_Confirmation_PayPal:"Ungependa kuhifadhi malipo haya kama yalivyolipwa?",
  No_Policies_Word:"Ongeza <a routerLink='/folder/Postage/New'>Sera ya Bima</a> ya kwanza sasa.",
  Timesheet_Multiple_Delete_Confirmation:"Je, una uhakika unataka kufuta laha nyingi za saa?",
  Select_Multiple_Timesheets_Prompt:"Hakuna laha za saa zilizochaguliwa. Chagua angalau laha moja ya saa.",
  Select_Multiple_Policies_Prompt:"Hakuna sera zilizochaguliwa. Chagua angalau sera moja ya bima.",
  Policies_Multiple_Delete_Confirmation:"Je, una uhakika unataka kufuta sera nyingi?",
  Company:"Kampuni",
  Add_Company:"Ongeza Kampuni",
  New_Company:"Ongeza Kampuni",
  No_Companies:"Hakuna Makampuni",
  Enable_Company:"Wezesha Kampuni",
  Select_Company:"Chagua Kampuni",
  The_Default_Company:"Lebo ya kampuni chaguomsingi.",
  Manage_Companies:"Dhibiti Makampuni",
  No_Companies_Word:"Hundi zitatumia kampuni chaguo-msingi.<br />Ongeza <a routerLink='/folder/Company/New'>Kampuni ya kwanza</a>.",
  Default_Company:"Kampuni Chaguomsingi",
  Cheques_Unlimited_Word:"Cheki Bila Kikomo hukuruhusu kuchapisha hundi nyingi upendavyo.",
  Cheques_Subscription_Word:"Usajili wa Cheki hukuruhusu kuchapisha hundi nyingi upendavyo.",
  You_Own_This_Product:"Unamiliki bidhaa hii.",
  Your_Subscription_is_Active:"Usajili wako unatumika.",
  Active_Products:"Bidhaa Zilizoamilishwa",
  Purchase_Confirmation:"Nunua",
  Purchase_Cheques:"Cheki za Ununuzi",
  Restore_Purchase:"Rejesha Ununuzi",
  Erase_Purchase:"Futa Ununuzi",
  Successfully_Purchased:"Imenunuliwa kwa Mafanikio",
  Enter_Your_Licence_Key:"Tafadhali weka ufunguo wako wa leseni kwenye ukurasa huu ili kuamilisha bidhaa hii.",
  Licence_Key:"Ufunguo wa Leseni",
  Enter_Licence_Key:"Ingiza Ufunguo wa Leseni",
  Purchased:"Imenunuliwa",
  Enable_Feature:"Washa Kipengele",
  Cancel_Subscription:"Ghairi Usajili",
  Subscription_Removed:"Usajili Umeondolewa",
  Select_Active_Subscription:"Chagua usajili unaoendelea ili kuurekebisha.",
  Remove_Subscription_Word:"Je, una uhakika unataka kughairi usajili huu?",
  Delete_Company_Confirmation:"Je, una uhakika unataka kufuta kampuni hii yote? ONYO: Utapoteza taarifa zote zilizohifadhiwa!",
  Delete_Default_Company_Word:"Huwezi kufuta kampuni chaguo-msingi. Je, ungependa kuweka upya programu na kuirejesha katika hali chaguomsingi? ONYO: Utapoteza taarifa zote zilizohifadhiwa!",
  Console_Warning_2:"Usishughulikie sarafu yoyote kwa kutumia programu hii ambayo si yako kwa sasa.",
  Terms_and_Conditions:"Sheria na Masharti",
  and_the:"na",
  for:"kwa",
  Please_Read_Word:"Tafadhali soma na ukubali",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Baadhi ya viwango vya ubadilishaji wa sarafu havikuweza kupatikana. Tafadhali unganisha kwenye mtandao.",
  Free:"Bure",
  DB_Erase_Prompt_2:"Je, ungependa kufuta kabisa hifadhidata nzima ya wasanidi programu? ONYO: Utapoteza taarifa zote za KUNUNUA na KUJIANDIKISHA!",
  Successfully_Imported:"Imefaulu Kuingizwa",
  Select_Postage:"Chagua Posta",
  No_Postage:"Hakuna Stempu za Posta",
  No_Paid_Postage_Word:"Ongeza stempu ya kwanza ya <a routerLink='/folder/Postage/New'>Malipo ya Posta</a>.",
  Trial_Complete:"Jaribio Limekamilika",
  Please_Upgrade:"Tafadhali pata toleo jipya la Hundi ili kuendelea kuchapa.",
  Aa:"Aa",
  Color:"Rangi",
  Font:"Fonti",
  Guide:"Mwongozo",
  Guides:"Waelekezi",
  Image:"Picha",
  Zoom:"Kuza",
  Logo:"Nembo",
  Bank:"Benki",
  MICR:"MICR",
  Total:"Jumla",
  Cancel:"Ghairi",
  Confirm:"Thibitisha",
  Method:"Njia",
  Biometric_Security:"Usalama wa Bio-metric",
  Please_Login_To_Continue:"Tafadhali ingia ili kuendelea.",
  Complete:"Kamilisha",
  Sign_Up:"Jisajili",
  Error:"Hitilafu",
  Biometrics:"Vipimo vya kibayolojia",
  Percent:"Asilimia",
  Zero_Percent:"0%",
  Top_Margin:"Pembezoni ya Juu",
  Bottom_Margin:"Pambizo ya Chini",
  Left_Margin:"Pambizo ya Kushoto",
  Right_Margin:"Pambizo la Kulia",
  MICR_Margin:"Pembeni ya MICR",
  Table_Margin:"Pembezoni za Jedwali",
  Address_Margin:"Pembezoni ya Anwani",
  Percentage_:"Asilimia",
  Vacation_Title:"Kichwa cha Likizo",
  Use_PIN:"Tumia PIN",
  Loading__:"Inapakia...",
  Design_Title:"Kichwa cha Kubuni",
  Authorize:"Kuidhinisha",
  Key:"Ufunguo",
  Public_Key:"Ufunguo wa Umma",
  Private_Key:"Ufunguo wa Kibinafsi",
  Authenticate:"Thibitisha",
  Last_Payroll:"Malipo ya Mwisho",
  Last_Calculation:"Hesabu ya Mwisho",
  Authorized:"Imeidhinishwa",
  Geo_Authorized:"Geo-Mahali: Imeidhinishwa",
  Not_Authorized:"Haijaidhinishwa",
  Authorization_Complete:"Uidhinishaji Umekamilika",
  Geolocation_Authorization:"Uidhinishaji wa eneo-jio",
  Out_of_Bounds:"Nje ya mipaka",
  Cant_Delete_Default_Design:"Haiwezi kufuta muundo chaguomsingi.",
  Unauthorized_Out_of_Bounds:"Isiyoidhinishwa: Nje ya Mipaka",
  Biometric_Authorization:"Uidhinishaji wa kipimo cha kibayolojia",
  Locating_Please_Wait:"Inatafuta, Tafadhali Subiri...",
  Test_Biometrics:"Jaribu Vipimo vya Ubinadamu",
  Cheque_Margins:"Angalia Pembezoni",
  Percentage_Full_Error:"Sehemu ya asilimia haiwezi kuwekwa zaidi ya asilimia 100.",
  No_Payroll_Text:"Ongeza <a routerLink='/folder/Employee/New'>Mfanyakazi</a> au <a routerLink='/folder/Payee/New'>Payee</a> na <a routerLink='/folder/Schedule /Mpya'>Ratiba</a>.",
  Design_Saved:"Muundo Umehifadhiwa",
  Default_Design_Selected:"Muundo Chaguomsingi Umechaguliwa",
  Partial_Import:"Uagizaji Sehemu",
  Partial_Export:"Usafirishaji wa Sehemu",
  Entire_Import:"Ingiza Mzima",
  Entire_Export:"Usafirishaji Mzima",
  Existing_Password:"Tafadhali weka nenosiri lako lililopo:",
  Existing_PIN:"Tafadhali weka PIN yako iliyopo:",
  Pin_Change_Header:"Uthibitishaji wa PIN",
  Pin_Change_SubHeader:"Weka PIN yako ya zamani ili kuthibitisha mabadiliko.",
  Pass_Change_Header:"Uthibitishaji wa Nenosiri",
  Pass_Change_SubHeader:"Weka nenosiri lako la zamani ili kuthibitisha mabadiliko.",
  PIN_Enter_Message:"Weka PIN yako ili kuthibitisha.",
  Password_Enter_Message:"Weka nenosiri lako ili kuthibitisha.",
  Pin_Updated_Success:"PIN imesasishwa!",
  Pin_Updated_Fail:"PIN haikuweza kusasishwa.",
  Pass_Updated_Success:"Nenosiri limesasishwa.",
  Pass_Updated_Fail:"Nenosiri halikuweza kusasishwa.",
  Preview_Payment:"Hakiki Malipo",
  Preview_Payroll:"Hakiki Malipo",
  No_Payments_Created:"Hakuna malipo yaliyopatikana kufanywa.",
  Payment_Preview:"Hakiki ya Malipo",
  Enable_Payee:"Washa Mlipwaji",
  Rendered:"Imetolewa",
  No_Email:"Hakuna Barua pepe",
  Setup_Cheques:"Mipangilio ya ukaguzi",
  name:"Jina",
  address:"Anwani",
  address_2:"Mstari wa 2 wa Anwani",
  city:"Jiji",
  province:"Mkoa",
  postal_code:"Msimbo wa Posta/ZIP",
  country:"Nchi",
  username:"Jina la mtumiaji",
  full_name:"Jina kamili",
  birthday:"Siku ya kuzaliwa",
  email:"Barua pepe",
  phone:"Simu",
  employees:"Wafanyakazi",
  addresses:"Anwani",
  payment_amount_limit:"Kikomo cha Kiasi cha Malipo",
  total_limit:"Jumla ya Kikomo",
  payees:"Waliolipwa",
  description:"Maelezo",
  address_line_one:"Mstari wa Kwanza wa Anwani",
  address_line_two:"Mstari wa Pili wa Anwani",
  image:"Picha",
  account_holder:"Mwenye akaunti",
  cheque_starting_id:"Angalia Kitambulisho cha Kuanzia",
  transit_number:"Nambari ya Usafiri",
  institution_number:"Nambari ya Taasisi",
  designation_number:"Nambari ya Uteuzi",
  account_number:"Nambari ya Akaunti",
  currency:"Sarafu",
  signature:"Sahihi",
  payment_payroll_limit:"Kikomo cha Malipo",
  total_limi:"Jumla ya kikomo",
  date:"Tarehe",
  printed_memo:"Memo iliyochapishwa",
  banks:"Benki",
  signature_image:"Picha ya Sahihi",
  address_name:"Jina la Anwani",
  notes:"Vidokezo",
  design:"Kubuni",
  title:"Kichwa",
  frequency:"Mzunguko",
  fax:"Faksi",
  salaries:"Mishahara",
  salary_ids:"Vitambulisho vya mishahara",
  start_time:"Wakati wa Kuanza",
  end_time:"Wakati wa Mwisho",
  paid:"Imelipwa",
  overtime_percentage:"Asilimia Inayolipwa",
  overtime_amount:"Imelipwa Kiasi Kilichowekwa",
  first_name:"Jina la kwanza",
  last_name:"Jina la familia",
  moderation:"Kiasi",
  create:"Unda",
  edit:"Hariri",
  destroy:"Kuharibu",
  day_start_time:"Siku_ya_kuanza",
  day_end_time:"Siku_mwisho_wakati",
  fullname:"Jina",
  time:"Muda",
  auto_send:"Tuma kiotomatiki",
  time_method:"Mbinu ya Wakati",
  schedules:"Ratiba",
  indefinite_payroll_enabled:"Wezesha Bila kikomo",
  amount:"Kiasi",
  repeat:"Rudia",
  always_enabled:"Imewashwa kila wakati",
  start_date:"Tarehe ya Kuanza",
  end_date:"Tarehe ya mwisho",
  Cheque_Total:"Angalia Jumla",
  Total_Amount:"Jumla:",
  Amounts:"Kiasi:",
  Images:"Picha",
  Files:"Mafaili",
  Previewing:"Kuhakiki:",
  Insert:"Ingiza",
  Preview_Import:"Hakiki Leta",
  Entry:"Kuingia",
  Entries:"Maingizo",
  No_Entries:"Hakuna Maingizo",
  Import_Type:"Aina ya Kuingiza",
  Select_Details:"Chagua Maelezo",
  Select_Payee:"Chagua Anayelipwa",
  Enable_Holidays:"Washa Likizo",
  Disable_Holidays:"Zima Likizo",
  Wire_Transfer:"Uhamisho wa Waya",
  New_Export:"Usafirishaji Mpya",
  Export_Data:"Hamisha Data",
  Export_Data_Word:"Chagua aina ya faili ya kuhamisha na kupakua.",
  Export_File:"Hamisha Faili",
  Mode:"Hali",
  Times:"Nyakati",
  Widgets:"Wijeti",
  Slider:"Kitelezi",
  Set_Details:"Weka Maelezo",
  Select_Type:"Chagua Aina",
  Display_Slider:"Kitelezi cha Kuonyesha",
  Dashboard_Slider:"Kitelezi cha Dashibodi",
  Dashboard_Mode:"Hali ya Dashibodi",
  Show_Intro:"Onyesha Utangulizi",
  Show_Payrolls:"Onyesha Mishahara",
  Show_Holidays:"Onyesha Likizo",
  Show_Invoices:"Onyesha ankara",
  Show_Cheques:"Onyesha Hundi",
  Enabled_Widgets:"Wijeti Zilizowezeshwa",
  Disabled_Widgets:"Wijeti Zilizozimwa",
  Colors:"Rangi",
  Barcodes:"Misimbo pau",
  View_Timers:"Tazama Vipima Muda",
  Gradients:"Gradients",
  No_Info:"Hakuna Taarifa",
  Disable:"Zima",
  Show_Layer:"Onyesha Tabaka",
  Hide_Layer:"Ficha Tabaka",
  Text_Layer:"Tabaka za Maandishi",
  Secondly:"Pili",
  Minutely:"Dakika",
  nine_am:"SAA 9:00 ASUBUHI",
  five_pm:"5:00 PM",
  Enable_Address:"Washa Anwani",
  Invalid_File_Type:"Samahani, aina ya faili isiyo sahihi ilichaguliwa. Aina ya faili inayotumika:",
  Error_Updating_Entry:"Samahani, kulikuwa na hitilafu katika kusasisha ingizo hili.",
  Schedule_Timing_Alert:"Hitilafu: Wakati wa kuanza kwa ratiba umewekwa kwa thamani baada ya muda wa kuisha.",
  Select_Multiple_Payments_Prompt:"Hakuna malipo yaliyochaguliwa. Chagua angalau malipo moja.",
  Select_Multiple_Cheques_Prompt:"Hakuna hundi iliyochaguliwa. Tafadhali chagua angalau hundi moja.",
  Select_Multiple_Items_Prompt:"Hakuna vipengee vilivyochaguliwa. Tafadhali chagua angalau kipengee kimoja.",
  Paymemt_Multiple_Delete_Confirmation:"Je, una uhakika unataka kufuta malipo mengi?",
  Cheque_Multiple_Delete_Confirmation:"Je, una uhakika unataka kufuta hundi nyingi?",
  Payee_Multiple_Delete_Confirmation:"Je, una uhakika unataka kufuta wanaolipwa wengi?",
  Employee_Multiple_Delete_Confirmation:"Je, una uhakika unataka kufuta wafanyakazi wengi?",
  MICR_Warning:"Kumbuka: Baadhi ya vichapishi na vifaa huenda visionyeshe fonti ya MICR ipasavyo.",
  Automatically_Send:"Tuma kiotomatiki",
  Type:"Aina",
  Payment_Type:"Aina ya malipo",
  Auto_Send:"Tuma Kiotomatiki",
  Automatically_Process:"Mchakato otomatiki",
  Auto_Process:"Mchakato wa Kiotomatiki",
  Image_Based:"Kulingana na picha",
  Font_Based:"Kulingana na fonti",
  Rerender:"Toa upya",
  Rendering:"Utoaji",
  Render:"Mafaili",
  Top:"Juu",
  Middle:"Kati",
  Bottom:"Chini",
  Top_Left:"Juu Kushoto",
  Top_Center:"Kituo cha Juu",
  Top_Right:"Juu Kulia",
  Middle_Left:"Katikati Kushoto",
  Middle_Center:"Kituo cha kati",
  Middle_Right:"Katikati ya Kulia",
  Bottom_Left:"Chini Kushoto",
  Bottom_Center:"Kituo cha chini",
  Bottom_Right:"Chini Kulia",
  Numbers:"Nambari",
  Verified:"Imethibitishwa",
  Paper_Size:"Ukubwa wa Karatasi",
  New_Device:"Kifaa Kipya",
  Add_Device:"Ongeza Kifaa",
  Remove_Device:"Ondoa Kifaa",
  Delete_Device:"Futa Kifaa",
  Block_Device:"Zuia Kifaa",
  Block:"Zuia",
  Unblock:"Ondoa kizuizi",
  Table:"Jedwali",
  Scan_Login_Code:"Changanua Msimbo wa Kuingia",
  Login_Code:"Msimbo wa Kuingia",
  Scan_Code:"Changanua Msimbo",
  Scan_QR_Code:"Changanua Msimbo wa QR",
  Select_All:"Chagua Zote",
  Deselect_All:"Usichague zote",
  Increase:"Ongeza",
  Decrease:"Punguza",
  Bold:"Ujasiri",
  Text:"Maandishi",
  Style:"Mtindo",
  Italic:"Italiki",
  QR_Code:"Msimbo wa QR",
  Barcode:"Msimbo pau",
  Browse_Images:"Vinjari Picha",
  Browse_Files:"Vinjari Faili",
  Background_Image:"Picha ya Mandharinyuma",
  Logo_Image:"Picha ya Nembo",
  Header_Image:"Picha ya Kichwa",
  Bank_Image:"Picha ya Benki",
  Cut_Lines:"Kata Mistari",
  Background:"Usuli",
  License:"Leseni",
  One_License:"Leseni 1:",
  Licensed:"Imepewa leseni ya:",
  Not_Licensed:"Haina Leseni",
  Enter_Serial:"Ingiza Serial",
  Serial_Key:"Ufunguo wa Serial",
  Serial:"Msururu",
  Product_Key:"Ufunguo wa Bidhaa",
  Check_Product_Key:"Thibitisha Ufunguo wa Bidhaa",
  Add_Product_Key:"Ingiza Ufunguo wa Bidhaa",
  Verifying_Purchase:"Inathibitisha Ununuzi...",
  Print_Envelope:"Chapisha Bahasha",
  Envelope:"Bahasha",
  Thank_You:"Asante!",
  Scale:"Mizani",
  Print_Scale:"Chapisha Scale",
  Borders:"Mipaka",
  VOID:"UTUPU",
  Void_Cheque:"Ukaguzi Utupu",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"LIPIA KWA AGIZO LA:",
  NO_DOLLARS:"HAKUNA DOLA",
  ONE_DOLLAR:"DOLA MOJA",
  DOLLARS:"DOLA",
  AND:"NA",
  CENTS:"SENDI.",
  NO_:"HAPANA",
  ONE_:"MOJA",
  AND_NO_:"NA HAPANA",
  _AND_ONE_:"NA MOJA",
  DOLLARS_AND_ONE_CENT:"NA SENTI MOJA.",
  AND_NO_CENTS:" NA SENTI SIFURI.",
  CHEQUE_PRINT_DATE:"TAREHE:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_SIGNATUREMP:"Mbunge",
  Initial_Tasks:"Kazi za Awali",
  Inital_Setup:"Mpangilio wa Awali",
  Welcome_To:"Karibu",
  Welcome:"Karibu",
  Swipe:"Telezesha kidole",
  Intro_Setup:"Usanidi wa Utangulizi",
  Introduction:"Utangulizi",
  CHEQUE_PRINT_CREDIT:"Inaendeshwa na Hundi",
  Title_Intro_Word:"Karibu kwenye Cheki",
  Title_Intro:"Hundi Utangulizi",
  Title_Setup:"Hukagua Mipangilio",
  PayPal_Default_Email_Message:"Umepokea uhamisho mpya wa PayPal.",
  Cheques_App_Export:"Inasafirishwa kwa Hundi",
  No_Prints_Purchased:"Hakuna Chapa Zilizonunuliwa",
  No_Prints_Purchased_Word:"Hakuna nakala za ziada zinazopatikana. Tafadhali nunua hundi za ziada.",
  Post_Intro_Thanks:"Asante kwa kuchagua Cheki. Mchakato wa kusanidi sasa umekamilika.",
  Post_Intro_Word:"Anza kwa kuchapisha hundi yako ya kwanza, kuongeza malipo ya baadaye au kuongeza mfanyakazi kwenye orodha ya malipo.",
  Upgrade_Required:"Hundi inakuhitaji umiliki toleo linalolipishwa zaidi la programu ili kuficha ujumbe huu na kufungua vipengele vya ziada.",
  Upgrade_Title:"Hundi",
  Mailout_Prompt:"Unaweza pia kuchagua kuwa na Hundi barua pepe ya ukaguzi wa malipo yako kwa ajili yako.",
  Mailed_Cheque:"Hundi iliyotumwa kwa barua",
  Mailed_Cheque_Color:"Hundi ya Barua (Rangi)",
  Terms_Purchase:"Ununuzi wote wa kielektroniki kwa Hundi hurejeshwa kikamilifu kwa hadi siku 30 kutoka tarehe ya ununuzi. Tafadhali soma na ukubali",
  Dashboard_Setup:"Sanidi Kichapishi Msingi",
  Dashboard_Add:"Ongeza Akaunti ya Msingi ya Benki",
  Dashboard_Customize:"Chagua Kiolezo cha Kuangalia",
  Dashboard_Job_Salary:"Unda Kazi Yako na Uongeze Mshahara Wako",
  Dashboard_Employees:"Fuatilia Wafanyakazi na Waliolipwa",
  Dashboard_Print:"Chapisha na Utume Malipo Yako",
  Dashboard_Payroll:"Otosha Uchapishaji Wako wa Malipo",
  Dashboard_PayPal:"Sanidi Malipo ya PayPal / Malipo",
  Begin_Setup:"Anza Kuweka",
  Get_Started:"Anza",
  Purchase:"Nunua",
  Lockdown:"Kusitishwa katikhuli za kawaida",
  Unlock:"Fungua",
  Detailed:"Kina",
  Log_In:"Ingia",
  Login:"Ingia",
  Launch:"Uzinduzi",
  Register:"Sajili",
  Finish:"Maliza",
  List:"Orodha",
  Weekends:"Mwishoni mwa wiki",
  Weekly:"Kila wiki",
  PayPal_Default_Subject:"Malipo Mapya",
  Payment_Message:"Ujumbe wa Malipo",
  PayPal_Default_Payment_Note:"Asante",
  Setup_Your_Cheqing_Account:"Akaunti ya ukaguzi",
  Add_Your_Primary_Cheqing_Account:"Ongeza akaunti yako ya msingi ya ukaguzi.",
  Welcome_Word:"Rahisisha na ubadilishe malipo na usimamizi wa rasilimali watu.",
  Get_Started_Quickly:"Jibu tu maswali machache rahisi ambayo yatatusaidia kuanza...",
  Done_Intro_Word:"Usanidi Umekamilika",
  PIN_Protected:"Nenosiri na PIN Imelindwa",
  Enter_New_PIN:"Weka Msimbo mpya wa PIN:",
  Enter_PIN:"Weka Msimbo wa PIN:",
  Invalid_PIN:"PIN batili imeingizwa.",
  Account_Identifier:"Kitambulisho cha Akaunti",
  New_Account_Identifier:"Kitambulisho Kipya cha Akaunti",
  attempt:"jaribio",
  attempts:"majaribio",
  remaining:"iliyobaki",
  Language:"Lugha",
  languages:"Lugha",
  select_languages:"Chagua Lugha",
  Deposit:"Amana",
  Hire_One_Now:"Ajiri Mmoja Sasa",
  App_Locked:"Programu imefungwa.",
  Skip_:"Ruka",
  Skip_to_Dashboard:"Ruka hadi kwenye Dashibodi",
  Dashboard:"Dashibodi",
  Import:"Ingiza",
  Admin:"Wasimamizi",
  New_Admin:"Msimamizi Mpya",
  Settings:"Mipangilio",
  Color_Picker:"Kiteua Rangi",
  Font_Picker:"Kiteua Fonti",
  Logout:"Ondoka",
  Close:"Funga",
  Close_menu:"Funga",
  Excel:"Faili ya Excel",
  Csv:"Faili ya CSV",
  Sql:"Faili ya SQL",
  Json:"Faili ya JSON",
  Url:"Ingiza kwa kutumia URL",
  Back:"Nyuma",
  Timers:"Vipima muda",
  Cheque:"Angalia",
  Print:"Chapisha",
  Designs:"Miundo",
  Pause_Printing:"Sitisha Uchapishaji",
  Resume_Printing:"Endelea Kuchapa",
  Printing_Paused:"Uchapishaji Umesitishwa",
  PrintingUnavailable:"Pole! Uchapishaji haupatikani kwenye mfumo huu.",
  Prints_Paused:"Uchapishaji Umesitishwa",
  Administration:"Utawala",
  Loading:"Inapakia",
  Unnamed:"Bila jina",
  error:"Samahani, ukaguzi huu haukuweza kufunguliwa kwa kutazamwa.",
  No_Cheques_To_Print:"Hakuna Hundi za Kuchapisha",
  No_Cheques_To_Print_Word:"Unda <a routerLink='/folder/Cheque/New'>Hundi Mpya</a>.",
  New_Cheque:"Hundi Mpya",
  Start_One_Now:"Anza Moja Sasa",
  Edit_Cheque:"Hariri Angalia",
  Select_Cheques:"Chagua Hundi",
  Select_Employee:"Chagua Mfanyakazi",
  Default_Printer:"Printa Chaguomsingi",
  Reassign:"Weka upya",
  Configure:"Sanidi",
  Template:"Kiolezo",
  Designer:"Mbunifu",
  Edit_Designs:"Badilisha Miundo",
  New_Design:"Muundo Mpya",
  Next:"Inayofuata",
  Save:"Hifadhi",
  Name:"Jina",
  Mail:"Barua",
  Amount:"Kiasi",
  Date:"Tarehe",
  PayPal:"PayPal",
  Payouts:"Malipo",
  PayPal_Label:"Lebo ya PayPal",
  Email_Username:"Barua pepe / Jina la mtumiaji",
  Client_ID:"Kitambulisho cha Mteja",
  Sandbox_Email:"Barua pepe ya Sandbox",
  PayPal_Email:"Barua pepe ya PayPal",
  PayPal_Username:"Jina la mtumiaji la API",
  PayPal_Payouts:"Malipo ya PayPal",
  Select_PayPal_Key:"Chagua Ufunguo wa PayPal",
  Secret:"Siri",
  API_Secret:"Siri ya API",
  PayPal_API_Keys:"Funguo za PayPal",
  New_PayPal_Key:"Ufunguo Mpya wa PayPal",
  Email_Subject:"Mada ya Barua Pepe",
  Email_Message:"Barua pepe ya Ujumbe",
  Payout_Options:"Chaguo za Malipo",
  Payment_Note:"Kumbuka Malipo",
  Enable_Employee:"Wezesha Mfanyakazi",
  Enable_Production_Mode:"Washa Hali ya Uzalishaji",
  Sandbox_Username:"Jina la mtumiaji la Sandbox",
  Sandbox_Signature:"Sahihi ya Sandbox",
  Sandbox_Password:"Nenosiri la Sandbox",
  Production_Username:"Jina la mtumiaji la Uzalishaji",
  Production_Signature:"Sahihi ya Uzalishaji",
  Production_Password:"Nenosiri la Uzalishaji",
  Production_Email:"Barua pepe ya Uzalishaji",
  API_Client_ID:"Kitambulisho cha Mteja wa API",
  API_Signature:"Sahihi ya API",
  API_Password:"Nenosiri la API",
  API_Username:"Jina la mtumiaji la API",
  Secret_Key:"Ufunguo wa Siri",
  Sandbox:"Sanduku la mchanga",
  Production:"Uzalishaji",
  Sandbox_Keys:"Funguo za Sandbox",
  Production_Keys:"Vifunguo vya Uzalishaji",
  API_Title:"Kichwa cha API",
  Production_Mode:"Hali ya Uzalishaji",
  Account_Label:"Lebo ya Akaunti",
  No_PayPal_Setup:"Hakuna Ufunguo wa PayPal",
  Enable_PayPal_Account:"Washa Akaunti ya PayPal",
  No_PayPal_Word:"Ongeza <a routerLink='/folder/Invoice/New'>Kifunguo chako cha API ya PayPal</a>.",
  Printed_Memo:"Memo iliyochapishwa",
  Employee:"Mfanyakazi",
  View_Employee:"Tazama Mfanyakazi",
  Mailing_Address:"Anwani ya posta",
  Company_Address:"Anwani ya Kampuni",
  Select_Company_Address:"Chagua Anwani ya Kampuni",
  Address:"Anwani",
  Any_Day:"Siku Yoyote",
  Address_Name:"Jina la Anwani",
  Unit:"Kitengo",
  Account:"Akaunti",
  Bank_Account:"Akaunti ya benki",
  Account_Limits:"Washa Vikomo vya Akaunti",
  Payroll:"Mishahara",
  Run:"Kimbia",
  Run_Payroll:"Endesha Malipo",
  New_Payroll:"Mshahara Mpya",
  No_Payroll:"Hakuna Malipo Yanayokuja",
  Upcoming_Holiday:"Likizo Ijayo:",
  Invoice_Due:"Malipo ya Ankara:",
  New_Invoice:"Ankara Mpya",
  No_Invoices:"Hakuna ankara",
  No_Invoices_Word:"Unda <a routerLink='/folder/Invoice/New'> Ankara</a> ya kwanza.",
  Cheque_Due:"Kagua Malipo:",
  Payrolls:"Mishahara",
  Sandbox_Mode:"Hali ya Sandbox",
  Hire:"Ajira",
  Pay:"Lipa",
  New:"Mpya",
  Add:"Ongeza",
  Make:"Fanya",
  Time:"Muda",
  Write:"Andika",
  Holiday:"Sikukuu",
  Holidays:"Likizo",
  Next_Holiday:"Likizo Inayofuata:",
  All_Done:"Yote Yamekamilika!",
  Employees:"Wafanyakazi",
  Payees:"Waliolipwa",
  Job:"Kazi",
  Jobs:"Ajira",
  Invoice:"Ankara",
  Invoices:"ankara",
  Vacations:"Likizo",
  Cheques:"Hundi",
  Brand_Cheques:"Chapa yako",
  Payment:"Malipo",
  Enable_Payment:"Washa Malipo",
  Payments:"Malipo",
  Schedule:"Ratiba",
  Schedules:"Ratiba",
  Timesheet:"Karatasi ya Wakati",
  Timesheets:"Laha za Muda",
  Salary:"Mshahara",
  New_Address:"Anwani Mpya",
  Address_2:"Anwani (Mstari wa 2)",
  _City:"Jiji",
  _State:"Jimbo/Mit",
  City:"Mji / Mji",
  State:"Jimbo / Mkoa",
  Postal:"Msimbo wa posta / wa posta",
  ZIP:"Posta / ZIP",
  Country:"Nchi",
  Addresses:"Anwani",
  Required_Options:"Chaguzi Zinazohitajika",
  Optional_Options:"Chaguo za Hiari",
  Additional_Options:"Chaguzi za Ziada",
  Required:"Inahitajika",
  Optional:"Hiari",
  Additional:"Ziada",
  No_Addresses:"Hakuna Anwani",
  New_Address_Word:"Ongeza <a routerLink='/folder/Address/New'>Anwani ya kwanza</a>.",
  Select_Address:"Chagua Anwani",
  No_Address:"Hakuna Anwani",
  Print_Cheque:"Chapisha Cheki",
  Print_Cheques:"Hundi za Chapisha",
  Print_Cheque_Now:"Chapisha Angalia Sasa",
  Description:"Maelezo",
  Pay_To_The_Order_Of:"Lipa kwa Agizo la:",
  Select_Date_Range:"Chagua Masafa ya Tarehe",
  Select_Starting_Date:"Chagua Tarehe ya Kuanza",
  Select_Ending_Date:"Chagua Tarehe ya Kuisha",
  Select_Date:"Chagua Tarehe",
  Cheque_Date:"Tarehe ya kuangalia",
  Cheque_Memo:"Angalia Memo",
  Blank_Cheque:"Cheki Tupu",
  Blank:"Tupu",
  No_Cheques:"Hakuna Hundi",
  No_Cheques_Word:"Chapisha <a routerLink='/folder/Cheque/New'>Check yako ya kwanza</a>.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"Tazama Picha",
  View:"Tazama",
  Modify:"Rekebisha",
  Delete:"Futa",
  Cheque_Paid:"Imelipwa",
  New_Deduction:"Punguzo Mpya",
  Title:"Kichwa",
  Frequency:"Mzunguko",
  Hourly:"Kila saa",
  Daily:"Kila siku",
  Weekdays:"Siku za wiki",
  BiWeekly:"Wiki 2",
  TriWeekly:"Wiki 3",
  Monthly:"Kila mwezi",
  MiMonthly:"Miezi 2",
  Quarterly:"Kila robo",
  Yearly:"Kila mwaka",
  Every_Week:"Kila wiki",
  Every_Payroll:"Kila Payroll",
  Every_Month:"Kila mwezi",
  Annually:"Kila mwaka",
  Always_Scheduled:"Imepangwa kila wakati",
  Start_Date:"Tarehe ya Kuanza",
  End_Date:"Tarehe ya mwisho",
  Start_Time:"Wakati wa Kuanza",
  End_Time:"Wakati wa Mwisho",
  Deduction_Label:"Lebo ya makato",
  Notes:"Vidokezo",
  Options:"Chaguo",
  Enable:"Wezesha",
  Select_Deductions:"Chagua Makato",
  Deductions:"Makato",
  No_Deductions:"Hakuna Makato",
  No_Deductions_Word:"Unda <a routerLink='/folder/Deduction/New'>Kato lako la kwanza</a>.",
  New_Employee:"Mfanyakazi Mpya",
  No_Title:"Hakuna Kichwa",
  _Name:"Jina",
  About_Yourself:"Kuhusu Wewe Mwenyewe",
  Full_Name:"Jina kamili",
  Birthday:"Siku ya kuzaliwa",
  Email:"Barua pepe",
  SMS:"SMS",
  Phone:"Simu",
  Test:"Mtihani",
  Call:"Wito",
  Fax:"Faksi",
  Printed_Note:"Ujumbe Uliochapishwa",
  Position:"Nafasi",
  Job_Position:"Nafasi ya kazi",
  Select_a_Job:"Chagua Kazi",
  Select_a_Salary:"Chagua Mshahara",
  Add_a_Deduction:"Ongeza Kipunguzo",
  Taxes:"Kodi",
  Add_Taxes:"Ongeza Kodi",
  Date_of_Birth:"Tarehe ya kuzaliwa",
  Email_Address:"Barua pepe",
  Phone_Number:"Nambari ya simu",
  Phone_Call:"Simu",
  Enable_on_Payroll:"Washa kwenye Payroll",
  Select_Employees:"Chagua Wafanyakazi",
  No_Employees:"Hakuna Wafanyakazi",
  No_Employees_Word:"Ongeza <a routerLink='/folder/Employee/New'>Mfanyakazi wako mpya wa kwanza</a>.",
  No_Name:"Hakuna jina",
  Unemployeed:"Wasio na kazi",
  Employeed:"Kuajiriwa",
  Paid:"Imelipwa",
  Enabled:"Imewashwa",
  Disabled:"Imezimwa",
  Fire:"Moto",
  Not_Available:"Haipatikani",
  Not_Available_Word:"Chapisha <a routerLink='/folder/Invoice/New'> Ankara yako ya kwanza</a> na ulipwe.",
  Select_Invoices:"Chagua ankara",
  Print_Invoice_Word:"Chapisha <a routerLink='/folder/Invoice/New'> Ankara yako ya kwanza</a> na ulipwe.",
  Invoice_Title:"Kichwa cha ankara",
  Invoice_Date:"Tarehe ya ankara",
  Due_Date:"Tarehe ya kukamilisha",
  New_Job:"Kazi mpya",
  Details:"Maelezo",
  Customize:"Geuza kukufaa",
  Customize_Dashboard:"Binafsisha Dashibodi",
  Components:"Vipengele",
  No_Components:"Hakuna Vipengele",
  Main_Components:"Vipengele Kuu",
  Smaller_Components:"Vipengele vidogo zaidi",
  Error_Loading_Page:"Hitilafu katika kupakia ukurasa huu.",
  Bank_Details:"Taarifa za benki",
  About_Your_Job:"Kuhusu Kazi Yako",
  Your_Schedule:"Ratiba Yako",
  Job_Title:"Jina la kazi",
  Job_Description:"Maelezo ya Kazi",
  Job_Details:"Maelezo ya Kazi",
  Enable_Job:"Wezesha Kazi",
  Pay_Period:"Kipindi cha Malipo",
  Perpetually_Schedule:"Ratiba ya kudumu",
  Select_Jobs:"Chagua Kazi",
  No_Jobs:"Hakuna Kazi",
  Add_Jobs:"Ongeza Ajira",
  No_Jobs_Word:"Ongeza <a routerLink='/folder/Job/New'>Kazi</a> ya kwanza kwenye orodha.",
  Count_Employees:"job.employee_count+' Employees'",
  Zero_Employees:"0 Wafanyakazi",
  New_Leave:"Likizo Mpya",
  Leave_Name:"Acha Jina",
  Paid_Leave:"Likizo ya Kulipwa",
  Leave_Pay:"Acha Malipo",
  Indefinite_Leave:"Likizo Isiyo na Kikomo",
  Indefinite_Payroll:"Mishahara isiyo na kikomo",
  Leave:"Ondoka",
  Add_Schedules:"Ongeza Ratiba",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Wezesha Kutokuwepo",
  Select_Leaves:"Chagua Majani",
  No_Leaves:"Hakuna Majani ya Kutokuwepo",
  Leave_Of_Absence:"Likizo ya Kutokuwepo",
  Leaves_Of_Absence:"Majani ya Kutokuwepo",
  New_Leave_of_Absense:"Likizo Mpya ya Kutokuwepo",
  No_Leaves_Word:"Ongeza <a routerLink='/folder/Leave/New'>Ondoka ya Kutokuwepo</a> ya kwanza.",
  Not_Enabled:"Haijawashwa",
  Absences:"Kutokuwepo",
  Payee:"Mlipwaji",
  New_Payee:"Mlipwaji Mpya",
  Payee_Identifier:"Kitambulisho cha Mlipaji",
  Payee_Name:"Jina la mlipaji",
  Payee_Title:"Kichwa cha Anayelipwa",
  Payment_Memo:"Memo ya Malipo",
  Select_Payees:"Chagua Waliolipwa",
  No_Payees:"Hakuna Waliolipwa",
  Add_Payee_Note:"Ongeza <a routerLink='/folder/Payee/New'>Payee wa kwanza</a>.",
  New_Payees:"Waliolipwa Wapya",
  About_The_Payment_Schedule:"Ratiba ya Malipo",
  Your_Payroll_Schedule:"Malipo ya Kiotomatiki",
  New_Payment:"Malipo Mapya",
  Identifier:"Kitambulisho",
  Selected:"Imechaguliwa",
  Select:"Chagua",
  Memo:"Memo",
  Payment_Date:"Siku ya malipo",
  Mark_as_Paid:"Weka Alama Kama Imelipwa",
  Select_Payments:"Chagua Malipo",
  No_Payments:"Hakuna Malipo",
  No_Payments_Word:"Unda <a routerLink='/folder/Payment/New'>Malipo</a> ya kwanza.",
  Create_Payroll:"Tengeneza Malipo",
  Properties:"Mali",
  Payroll_Title:"Jina la Mshahara",
  Payroll_Notes:"Vidokezo vya Mishahara",
  Payroll_Setup:"Mpangilio wa Mishahara",
  Tabulate_Payments:"Jedwali Malipo",
  Tabulate:"Jedwali",
  By:"Na:",
  Payments_By:"Malipo Kwa",
  Timesheets_And_Schedules:"Majedwali ya Muda na Ratiba",
  Both:"Zote mbili",
  Items:"Vipengee",
  Add_Payees:"Ongeza Waliolipwa",
  Add_Account:"Ongeza Akaunti",
  Enable_Account:"Washa Akaunti",
  Enable_Payroll:"Washa Malipo",
  Print_Payroll:"Chapisha Malipo",
  No_Payrolls:"Hakuna Malipo",
  No_Payroll_Word:"Unda <a routerLink='/folder/Payroll/New'>Malipo yako ya kwanza</a>.",
  View_more:"ONA ZAIDI",
  Less:"CHINI",
  Add_Payroll:"Ongeza Malipo",
  Select_Payroll:"Chagua Malipo",
  About_Your_Salary:"Kuhusu Mshahara wako",
  Add_Salaries:"Ongeza Mishahara",
  Add_Salary:"Ongeza Mshahara",
  Salaries:"Mishahara",
  No_Salaries:"Hakuna Mishahara",
  No_Salaries_Word:"Ongeza <a routerLink='/folder/Salary/New'>Mshahara</a> wa kwanza.",
  Select_Salaries:"Chagua Mishahara",
  New_Salary:"Mshahara Mpya",
  Salary_Name:"Kitambulisho cha Mshahara",
  Enable_Salary:"Wezesha Mshahara",
  Salary_Amount:"Kiasi cha Mshahara",
  New_Schedule:"Ratiba Mpya",
  Schedule_Title:"Kichwa cha Ratiba",
  Add_Address:"Ongeza Anwani",
  Repeat:"Rudia",
  Design:"Kubuni",
  Edit_Design:"Badilisha Usanifu",
  Edit_this_Design:"Hariri Muundo huu",
  Repeat_Payment:"Rudia Malipo",
  Enable_Schedule:"Washa Ratiba",
  Never:"Kamwe",
  Select_Schedule:"Chagua Ratiba",
  No_Schedules:"Hakuna Ratiba",
  No_Schedules_Word:"Unda <a routerLink='/folder/Schedule/New'>Ratiba</a> ya kwanza.",
  New_Administrator:"Msimamizi Mpya",
  Username:"Jina la mtumiaji",
  First_Name:"Jina la kwanza",
  Last_Name:"Jina la familia",
  Add_an_Address:"Ongeza Anwani",
  Payment_Limit:"Kikomo kwa Kila Malipo",
  Total_Limit:"Jumla ya Kikomo",
  Select_Accounts:"Chagua Akaunti",
  No_Administrators:"Hakuna Wasimamizi",
  No_Administrators_Word:"Fungua <a routerLink='/folder/Administrator/New'>Akaunti ya Msimamizi</a> ya kwanza.",
  New_Administrators_Word:"Ongeza <a routerLink='/folder/Administrator/New'>Msimamizi wa kwanza</a>",
  Cloud:"Wingu",
  Backup:"Hifadhi nakala",
  Enable_iCloud:"Washa iCloud",
  Enable_Google_Drive:"Washa Hifadhi ya Google",
  Enable_Microsoft_OneDrive:"Washa Microsoft OneDrive",
  Automatically_Backup:"Hifadhi nakala kiotomatiki",
  FTP_Settings:"Mipangilio ya FTP",
  URL_File_Prompt:"Tafadhali bainisha eneo la faili ya .xls / .xlsx / .json ili kuleta:",
  URL_SQL_Prompt:"Tafadhali bainisha eneo la faili ya SQLite ili kuleta:",
  FTP_Backup:"Hifadhi rudufu ya FTP",
  FTP_Import:"Ingiza FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Mwenyeji",
  Port:"Bandari",
  Path:"Njia",
  Data_Path:"Njia ya Data",
  Enable_FTP_Account:"Washa Akaunti ya FTP",
  HostnameIP:"Jina la mwenyeji",
  Password:"Nenosiri",
  Connection_Port:"Bandari ya Uunganisho",
  Enable_MySQL_Database:"Washa Hifadhidata ya MySQL",
  Log:"Kumbukumbu",
  Reset:"Weka upya",
  Erase:"Futa",
  Export:"Hamisha",
  Database:"Hifadhidata",
  Upload_CSV:"Pakia CSV",
  Download_CSV:"Pakua CSV",
  SQL_Database:"URL ya SQL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Hifadhi Nakala ya MySQL",
  Internal_Notes:"Vidokezo vya Ndani",
  Root_Path:"Njia ya mizizi",
  Select_Backup:"Chagua Hifadhi Nakala",
  Add_New_Backup:"Ongeza Hifadhi Nakala Mpya",
  First_Backup:"Sanidi hifadhi rudufu ya kwanza...",
  Backups:"Hifadhi rudufu",
  Add_Backup:"Ongeza Hifadhi Nakala",
  No_Backups:"Hakuna chelezo kupatikana.",
  Select_Backup_Type:"Chagua aina ya chelezo ungependa kusanidi...",
  Backup_Type:"Aina ya Hifadhi Nakala",
  FTP_Drive:"Hifadhi ya FTP",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Endesha",
  Microsoft_OneDrive:"Endesha",
  Import_Fields:"Sehemu za Kuingiza",
  Import_Fields_Word:"Tumia sehemu hii kuchagua <a routerLink='/folder/Holiday/New'>Leta</a> data.",
  Upload:"Pakia",
  Download:"Pakua",
  Download_JSON:"Pakua kama Data ya JSON",
  Download_SQL:"Pakua kama Faili ya SQL",
  New_Bank:"Benki Mpya",
  New_Account:"Akaunti mpya",
  New_Bank_Account:"Akaunti Mpya ya Benki",
  Upload_Image:"Pakia Picha",
  Bank_Name:"Jina la benki",
  Bank_Address:"Anwani ya Benki",
  Branch_Address:"Anwani ya Tawi",
  Address_on_Cheque:"Anwani",
  Cheque_Numbers:"Angalia Nambari",
  Cheque_Details:"Angalia Maelezo",
  Bank_Logo:"Nembo ya Benki",
  Cheque_ID:"Angalia kitambulisho",
  Starting_Cheque_ID:"Inaanzisha Kitambulisho cha Kuangalia",
  Transit_Number:"Nambari ya Usafiri",
  Institution_Number:"Nambari ya Taasisi",
  Designation_Number:"Nambari ya Uteuzi",
  Account_Number:"Nambari ya Akaunti",
  Limits:"Mipaka",
  Default_Limits:"Mipaka Chaguomsingi",
  Over_Limit:"Zaidi ya Kikomo",
  Under_Limit:"Chini ya Kikomo",
  Payroll_Limit:"Kikomo cha Mishahara",
  Enable_Bank_Account:"Washa Akaunti ya Benki",
  Select_Account:"Chagua Akaunti",
  No_Bank_Account:"Hakuna Akaunti ya Benki",
  No_Bank_Account_Word:"Ongeza <a routerLink='/folder/Accounts/New'>Akaunti ya Benki</a> ya kwanza.",
  Bank_Accounts:"Akaunti za Benki",
  No_Accounts:"Hakuna Akaunti",
  No_Accounts_Note:"Ongeza <a routerLink='/folder/Accounts/New'>Akaunti ya Benki</a> ya kwanza.",
  Cheque_Designer:"Angalia Mbuni",
  Cheque_Design:"Angalia Ubunifu",
  Select_Design:"Chagua Muundo",
  Cheque_Designs:"Angalia Miundo",
  No_Cheque_Designs:"Hakuna Miundo ya Kukagua",
  No_Cheque_Designs_Word:"Unda <a routerLink='/folder/Settings/Cheque/Design/New'>Angalia Muundo wako mwenyewe</a>.",
  Set_Default:"Weka kama Chaguomsingi",
  Default:"Chaguomsingi",
  Remove:"Ondoa",
  Folder:"Folda",
  Edit:"Hariri",
  LoadingDots:"Inapakia...",
  Add_a_New_File:"Ongeza <a href='#' (click)='add()'>Faili Mpya</a>",
  this_folder:"folda hii",
  FTP_Backup_Settings:"Mipangilio ya Hifadhi Nakala ya FTP",
  Secure_File_Transfer:"Salama Uhamisho wa Faili",
  New_Holiday:"Likizo Mpya",
  Add_Holiday:"Ongeza Likizo",
  Holiday_Name:"Jina la Likizo",
  Additional_Pay:"Malipo ya Ziada",
  Enable_Holiday:"Washa Likizo",
  Select_Holidays:"Chagua Likizo",
  No_Holidays:"Hakuna Likizo",
  No_Holidays_Word:"Ongeza <a routerLink='/folder/Holiday/New'>Likizo ya Umma</a> ya kwanza.",
  New_Import:"Ingiza Mpya",
  Import_Data:"Ingiza Data",
  Import_Data_Word:"Chagua aina ya faili au mbinu ya kupakia unayochagua.<br /> Utaweza kuchagua sehemu zozote zilizoletwa katika faili zinazolingana na kigezo chochote katika programu baada ya kupakia faili inayotumika.",
  Import_File:"Ingiza Faili",
  Link_To_File:"Unganisha kwa Faili",
  Select_File:"Chagua Faili",
  New_Moderator:"Msimamizi Mpya",
  Allow_Moderation:"Ruhusu Kiasi",
  Create_Paycheques:"Unda Malipo",
  Edit_Paycheques_and_Data:"Hariri Malipo na Data",
  Destroy_Data_and_Paycheques:"Kuharibu Data na Paycheques",
  Bonus_Percentage:"Asilimia ya Malipo",
  Fixed_Amount:"Kiasi kisichobadilika",
  Select_Moderator:"Chagua Msimamizi",
  No_Moderators:"Hakuna Wasimamizi",
  Moderators:"Wasimamizi",
  Moderator_Account:"Fungua <a routerLink='/folder/Administrator/New'>Akaunti ya kwanza ya Msimamizi</a>.",
  No_Moderators_Word:"Ongeza <a routerLink='/folder/Administrator/New'>Moderator wa kwanza</a>.",
  Defaults:"Chaguomsingi",
  Provide_Us_Info:"Toa Taarifa",
  Setup_Your_Printer:"Sanidi Kichapishi Chako",
  Your_Company:"Kuhusu Kampuni Yako",
  Company_Name:"jina la kampuni",
  Currency:"Sarafu",
  Default_Currency:"Sarafu Chaguomsingi",
  Base_Monthly_Income:"Kipato cha mwezi",
  Protection:"Ulinzi",
  App_Protection:"Ulinzi wa Programu",
  PIN_Code_Protection:"Ulinzi wa Msimbo wa PIN",
  App_Protection_Word:"Washa njia za usalama zinazosaidia kulinda akaunti yako.",
  PIN_Code:"Msimbo wa PIN",
  Change_PIN:"Badilisha PIN",
  New_Password:"Nenosiri Mpya",
  Geofence_Protection:"Ulinzi wa uzio wa Geo",
  Geofence_Area:"Weka Eneo",
  Distance:"Umbali",
  Setup_Now:"Sanidi Sasa",
  Mile:"Maili",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Utambuzi wa Usoni",
  Face:"Uso",
  Setup:"Sanidi",
  Label:"Lebo",
  Password_Protection:"Ulinzi wa Nenosiri",
  Modify_Password:"Rekebisha Nenosiri",
  New_Tax_Entry:"Ingizo Jipya la Kodi",
  New_Tax:"Ushuru Mpya",
  Tax_Label:"Lebo ya Ushuru",
  Perpetually_Enabled:"Imewezeshwa Daima",
  Select_Taxes:"Chagua Kodi",
  Tax_Deductions:"Makato ya Kodi",
  No_Tax_Deductions:"Hakuna Makato ya Kodi",
  No_Tax_Deductions_Word:"Ongeza makato ya kwanza ya <a routerLink='/folder/Tax/New'>Kodi</a>.",
  New_Timer:"Kipima Muda Mpya",
  Start:"Anza",
  Stop:"Acha",
  Start_Timer:"Anza Kipima Muda",
  Stop_Timer:"Simamisha Kipima Muda",
  Timer_Active:"Kipima Muda Kimetumika",
  Timer:"Kipima muda:",
  Timer_Running:"Kipima muda: (Inaendesha)",
  Save_Timer:"Hifadhi Kipima Muda",
  New_Timesheet:"Karatasi Mpya ya Wakati",
  Select_Timesheets:"Chagua Laha za Wakati",
  Work_Notes:"Vidokezo vya Kazi",
  Entry_Title:"Kichwa cha Kuingia",
  No_Timesheets:"Hakuna Laha za Wakati",
  No_Timesheets_Word:"Ongeza <a routerLink='/folder/Timesheet/New'>Jedwali la Saa la kwanza</a> la kwanza.",
  Timesheet_Submitted:"Laha ya Muda Imewasilishwa",
  Timesheet_Congrats:"Hongera! Laha yako ya saa imewasilishwa. Asante!",
  Timesheet_Copy:"Ili kupokea nakala ya hati zako tafadhali tupe barua pepe yako na/au nambari ya simu ya rununu.",
  Submit:"Wasilisha",
  Allow_Notifications:"Ruhusu Arifa",
  Untitled_Entry:"Ingizo Mpya",
  Untitled_Bank:"Benki isiyo na jina",
  Timesheet_Entry:"Ingizo la Laha ya Muda",
  Work_Description:"Maelezo ya Kazi",
  Enable_Timesheet:"Washa Laha ya Saa",
  Submit_Timesheet:"Wasilisha Karatasi ya Wakati",
  Vacation:"Likizo",
  New_Vacation:"Likizo Mpya",
  Vacation_Name:"Jina la Likizo",
  Paid_Vacation:"Likizo ya Kulipwa",
  Vacation_Pay:"Malipo ya Likizo",
  Enable_Vacation:"Washa Likizo",
  Select_Vacations:"Chagua Likizo",
  No_Vacations:"Hakuna Likizo",
  No_Vacations_Word:"Unda ingizo la kwanza la <a routerLink='/folder/Vacation/New'>Likizo</a>.",
  Payroll_Schedule:"Ratiba ya Mishahara",
  Next_Payroll:"Malipo Inayofuata:",
  Upcoming_Payroll:"Malipo yajayo",
  No_Upcoming_Payroll:"Hakuna Malipo Yanayokuja",
  Address_Book:"Kitabu cha anwani",
  Archived_Documents:"Nyaraka Zilizohifadhiwa",
  Dev_Mode:"Maombi Katika Njia ya Maendeleo",
  Administrators:"Wasimamizi",
  Privacy:"Faragha",
  None:"Hakuna",
  Select_Signature:"Chagua Sahihi",
  No_Signatures:"Hakuna Sahihi",
  No_Signatures_Word:"Ongeza <a routerLink='/folder/Signature/New'>Sahihi</a> ya kwanza.",
  Repeat_Indefinitely:"Rudia Bila kikomo",
  Sign:"Ishara",
  Sign_Here:"Saini Hapa",
  Date_Signed:"Tarehe Iliyosainiwa",
  Signature_Pad:"Pedi ya Sahihi",
  Account_Holder:"Mwenye akaunti",
  Account_Properties:"Sifa za Akaunti",
  Name_On_Cheque:"Jina kwenye Check",
  Server_Hostname:"Jina la Mpangishi wa Seva / IP",
  Printers:"Wachapishaji",
  No_Printers:"Hakuna Printer",
  Printer_Exists:"Kichapishaji kwa jina hili tayari kipo.",
  No_Printers_Word:"Ongeza <a routerLink='/folder/Printer/New'>Printa</a> ya kwanza kabisa.",
  No_Printer_Alert:"Hakuna printa iliyofafanuliwa. Je, ungependa kusanidi kichapishi?",
  Add_Printer:"Ongeza Printer",
  New_Printer:"Printer Mpya",
  Printer_Hostname:"Printer Host-jina / IP",
  Printer_Label:"Lebo ya Kichapishaji",
  Printer_Protocol:"Itifaki ya Kichapishaji",
  Protocol:"Itifaki",
  Email_Print:"Barua pepe",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Soketi",
  Print_Job:"Kazi ya Kuchapa",
  Printed:"Imechapishwa",
  Not_Printed:"Haijachapishwa",
  Print_Jobs:"Kazi za Kuchapa",
  Print_Queue:"Foleni ya Kuchapisha",
  No_Print_Jobs:"Hakuna Kazi za Kuchapisha",
  No_Prints:"Unda <a routerLink='/folder/Cheque/New'>Angalia</a> mpya ili kuchapisha.",
  Prints:"Vichapishaji",
  Find_Printer:"Tafuta Printer",
  Find_AirPrint_Devices:"Pata Vifaa vya AirPrint",
  Select_Printer:"Chagua Printer",
  System_UI:"Mfumo wa UI",
  Printer:"Printa",
  Status:"Hali",
  Preview:"Hakiki",
  Enable_Print_Job:"Washa Kazi ya Kuchapisha",
  Queue_Weight:"Uzito wa foleni",
  Unlimited:"Bila kikomo",
  Show_Advanced_Printer_Options:"Onyesha Chaguzi za Kina Printa",
  Advanced:"Advanced",
  Location:"Mahali",
  Note:"Kumbuka",
  Queue_Name:"Jina la Foleni",
  Pages_Printed_Limit:"Kikomo cha Kurasa Zilizochapishwa",
  MultiPage_Idle_Time:"Muda wa Kusubiri wa kurasa nyingi (s)",
  Page_Count_Limit:"Kikomo cha Hesabu ya Ukurasa",
  Page_Orientation:"Mwelekeo wa Ukurasa",
  Portrait:"Picha",
  Landscape:"Mandhari",
  Duplex:"Duplex",
  Double_Sided_Printing:"Upande Mbili",
  Duplex_Mode:"Njia ya Duplex",
  Duplex_Short:"Mfupi",
  Duplex_Long:"Muda mrefu",
  Duplex_None:"Hakuna",
  Color_And_Quality:"Rangi Na Ubora",
  Monochrome:"Monochrome",
  Photo_Quality_Prints:"Machapisho ya Ubora wa Picha",
  Printer_Email:"Barua pepe ya Kichapishi",
  Automatically_Downsize:"Punguza kiotomatiki",
  Paper:"Karatasi",
  Paper_Name:"Jina la karatasi",
  Paper_Width:"Upana wa Karatasi",
  Paper_Height:"Urefu wa Karatasi",
  Paper_Autocut_Length:"Urefu wa Kukatwa kwa Karatasi",
  Margins:"Pembezoni",
  Page_Margins:"Pembezoni za Ukurasa",
  Page_Margin_Top:"Pembezoni za Ukurasa wa Juu",
  Page_Margin_Right:"Pambizo ya Ukurasa wa kulia",
  Page_Margin_Bottom:"Pambizo ya Ukurasa wa Chini",
  Page_Margin_Left:"Pambizo ya Ukurasa wa Kushoto",
  Add_Employees:"Ongeza Wafanyakazi",
  Header:"Kijajuu",
  Print_A_Page_Header:"Chapisha Kijajuu cha Ukurasa",
  Header_Label:"Lebo ya Kichwa",
  Header_Page_Index:"Kielezo cha Ukurasa wa Kichwa",
  Header_Font_Name:"Fonti ya Kichwa",
  Select_Font:"Chagua Fonti",
  Font_Selector:"Kiteuzi cha herufi",
  Header_Font_Size:"Fonti ya Kichwa",
  Header_Bold:"Kichwa cha Ujasiri",
  Header_Italic:"Kichwa cha italiki",
  Header_Alignment:"Mpangilio wa Kichwa",
  Left:"Kushoto",
  Center:"Kituo",
  Right:"Haki",
  Justified:"Thibitisha",
  Header_Font_Color:"Rangi ya Kichwa",
  Select_Color:"Chagua Rangi",
  Footer:"Kijachini",
  Print_A_Page_Footer:"Chapisha Kijachini cha Ukurasa",
  Footer_Label:"Lebo ya chini",
  Footer_Page_Index:"Kielezo cha Ukurasa wa Chini",
  Footer_Font_Name:"Fonti ya Chini",
  Fonts:"Fonti",
  Done:"Imekamilika",
  Select_Fonts:"Chagua Fonti",
  Footer_Font_Size:"Ukubwa wa Chini",
  Footer_Bold:"Footer Bold",
  Footer_Italic:"Italiki ya chini ya ukurasa",
  Footer_Alignment:"Mpangilio wa Chini",
  Footer_Font_Color:"Rangi ya Kijachini",
  Page_Copies:"Nakala za Ukurasa",
  Enable_Printer:"Washa Kichapishi",
  Remote_Logging:"Uwekaji Magogo wa Mbali",
  Log_Server:"Seva ya logi",
  Encryption:"Usimbaji fiche",
  Random_Key:"Ufunguo wa Nasibu",
  Encryption_Key:"Ufunguo wa Usimbaji",
  Cheques_Webserver:"Hifadhidata Maalum",
  Learn_How:"Jifunze Jinsi",
  Signature:"Sahihi",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Tazama Sahihi",
  Printed_Signature:"Sahihi Iliyochapishwa",
  Digitally_Sign:"Ishara ya Dijiti",
  Digital_Signature:"Sahihi ya Dijiti",
  Add_Signature:"Ongeza Sahihi",
  Add_Your_Signature:"Ongeza Sahihi Yako",
  Enable_Signature:"Washa Sahihi",
  Digitally_Signed:"Imetiwa Saini Dijitali",
  Insert_Error:"Imeshindwa kuhifadhi hundi kwa sababu ya masuala ya hifadhidata.",
  Delete_Confirmation:"Je, una uhakika unataka kufuta maelezo haya?",
  Discard_Confirmation:"Je, una uhakika unataka kutupa maelezo haya?",
  Discard_Bank_Confirmation:"Je, una uhakika unataka kutupa akaunti hii?",
  Discard_Printer_Confirmation:"Je, una uhakika unataka kutupa kichapishi hiki?",
  Delete_Bonus_Confirmation:"Je, una uhakika unataka kufuta bonasi hii?",
  Delete_Invoice_Confirmation:"Je, una uhakika unataka kufuta ankara hii?",
  Generated_Cheque:"Hundi Inayozalishwa",
  Generated_Invoice:"Ankara Inayozalishwa",
  Schedule_Start_Time:"Ratiba Anza",
  Schedule_End_Time:"Ratiba Mwisho",
  New_Call:"Simu Mpya",
  No_Contacts:"Hakuna Anwani",
  No_Contacts_Word:"Wasimamizi, wasimamizi, wafanyakazi na wanaolipwa huonekana kama wasiliani.",
  PDF_Subject:"fedha",
  PDF_Keywords:"malipo ya malipo hundi za hundi za PDF",
  Printer_Setup:"Kuweka Printa",
  Printer_Selection:"Uteuzi wa Printa",
  New_Fax:"Faksi Mpya",
  New_Fax_Message:"Ujumbe Mpya wa Faksi",
  Fax_Machine:"Mashine ya faksi",
  Fax_Name:"Jina la Faksi",
  Fax_Email:"Barua pepe ya Faksi",
  Fax_Number:"Nambari ya Faksi",
  Contents:"Yaliyomo",
  Fax_Body:"Mwili wa Ukurasa",
  Header_Word:"Kijajuu:",
  Header_Text:"Maandishi ya Kichwa",
  Include_Header:"Jumuisha Kichwa",
  Include_Footer:"Jumuisha Kijachini",
  Footer_Word:"Kijachini:",
  Footer_Text:"Maandishi ya Kijachini",
  Attach_a_Cheque:"Ambatisha Cheki",
  Add_Deduction:"Ongeza Kupunguzwa",
  Enable_Fax:"Tuma Faksi",
  Select_Fax:"Chagua Faksi",
  No_Faxes:"Hakuna Faksi",
  Faxes:"Faksi",
  Save_and_Send:"Tuma Faksi",
  Save_and_Pay:"Okoa na Ulipe",
  WARNING:"ONYO:",
  Remember:"Kumbuka",
  Printing:"Uchapishaji",
  Printing_Complete:"Uchapishaji Umekamilika!\n\n",
  of:"ya",
  There_Were:"Kulikuwa na",
  Successful_Prints:"prints zilizofanikiwa na",
  Unsuccessful_Prints:"prints zisizofanikiwa.",
  PrinterError:"Pole! Kulikuwa na hitilafu.",
  Printing_:"Inachapisha...",
  PrinterSuccess:"Hati imechapishwa.",
  PrintersSuccess:"Hati zimechapishwa.",
  New_Message:"Ujumbe Mpya",
  New_Messages:"Ujumbe Mpya",
  Read_Message:"Soma Ujumbe",
  Write_Message:"Andika Ujumbe",
  Send_Message:"Tuma Ujumbe",
  Subject:"Somo",
  Message:"Ujumbe",
  Writing:"Inaandika...",
  Send:"Tuma",
  Set_Date:"Weka Tarehe",
  Set_Time:"Weka Muda",
  Recieve:"Pokea",
  Set_as_Default:"Weka kama Chaguomsingi",
  Default_Account:"Akaunti Chaguomsingi",
  Default_Design:"Muundo Chaguomsingi",
  Multiple_Cheques:"Cheki Tatu",
  Account_Mode:"Hali ya Akaunti",
  Multiple_Cheques_Description:"Hundi tatu kwa kila ukurasa.",
  Check_and_Table:"Angalia & Jedwali",
  Check_including_Table:"Angalia na jedwali la hesabu.",
  Check_Mailer:"Angalia Mailer",
  Check_Mailer_Window:"Angalia na dirisha la anwani.",
  DocuGard_Table_Top:"Cheki Kubwa & Jedwali",
  DocuGard_Table_Middle:"Cheki Kubwa na Jedwali (Katikati)",
  DocuGard_Table_Bottom:"Cheki Kubwa na Jedwali (Chini)",
  DocuGard_Mailer_Top:"Kubwa Check Mailer",
  DocuGard_Mailer_Middle:"Mtuma Barua Kubwa (Katikati)",
  DocuGard_Mailer_Bottom:"Mtumaji Barua Kubwa wa Hundi (Chini)",
  DocuGard_Three_Cheques:"Cheki Tatu Kubwa",
  DocuGard_Cheque_Top:"Cheki Kubwa Moja",
  DocuGard_Cheque_Middle:"Cheki Kubwa (Katikati)",
  DocuGard_Cheque_Bottom:"Cheki Kubwa (Chini)",
  DocuGard_Three_Cheques_Window:"Cheki tatu kwenye ukurasa mmoja.",
  DocuGard_Table_Top_Window:"Jedwali la hundi na mapato.",
  DocuGard_Table_Middle_Window:"Jedwali la hundi na mapato.",
  DocuGard_Table_Bottom_Window:"Jedwali la hundi na mapato.",
  DocuGard_Mailer_Top_Window:"Cheki, meza na anwani.",
  DocuGard_Mailer_Middle_Window:"Cheki, meza na anwani.",
  DocuGard_Mailer_Bottom_Window:"Cheki, meza na anwani.",
  DocuGard_Cheque_Top_Window:"Angalia karatasi salama.",
  DocuGard_Cheque_Middle_Window:"Angalia karatasi salama.",
  DocuGard_Cheque_Bottom_Window:"Angalia karatasi salama.",
  Basic_Cheque:"Cheki Moja",
  Basic_Cheque_Print:"Chapisha hundi moja.",
  Error_Setting_As_Paid:"Hitilafu Kuweka Kama Inayolipwa",
  Add_Attachment:"Ongeza Kiambatisho",
  PrinterUnavailable:"Printa Haipatikani",
  CreditCardComponent:"Kadi",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Amana Mpya",
  Deposits:"Amana",
  No_Deposits:"Hakuna Amana",
  Credit_Card_Deposit:"Kadi ya Mkopo",
  New_Credit_Card_Deposit_Message:"Amana ya Kadi ya Mkopo",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Amana ya BitCoin",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Uhamisho wa Interac",
  Payments_Limit:"Kikomo cha Malipo",
  No_Payment_Limit:"Hakuna Kikomo cha Malipo",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Amana ya PayPal",
  No_Deposits_Word:"Ongeza kampuni ya kwanza <a routerLink='/folder/Deposit/New'>Amana</a>.",
  No_Documents_Specified:"Hakuna hati zilizoainishwa kwa uchapishaji",
  No_Printers_Added:"Hakuna vichapishaji vilivyopatikana. Nenda kwa Mipangilio > Vichapishaji ili kuongeza moja.",
  DB_Erase_Prompt:"Je, ungependa kufuta hifadhidata yote kabisa? ONYO: Utapoteza taarifa zote zilizohifadhiwa!",
  Console_Warning:"Usibandike maandishi yoyote kwenye kiweko hiki. Unaweza kujiweka mwenyewe na/au kampuni yako katika hatari kubwa.",
  No_Faxes_Word:"Unda <a routerLink='/folder/Fax/New'>Faksi</a> ya kwanza.",
  Cheque_Delete_Confirmation:"Je, una uhakika unataka kufuta hundi hii?",
  Design_Delete_Confirmation:"Je, una uhakika unataka kufuta muundo huu?",
  Cheque_Pay_Confirmation:"Weka hundi hii kuwa imelipwa? HAITAONEKANA kwenye foleni ya uchapishaji.",
  Payment_Pay_Confirmation:"Je, utie alama kwenye malipo haya kuwa yamelipwa? HAITAONEKANA kwenye foleni ya kuangalia.",
  Delete_Deduction_Confirmation:"Je, una uhakika unataka kufuta makato haya?",
  Delete_Job_Confirmation:"Je, una uhakika unataka kufuta kazi hii?",
  Delete_Timesheet_Confirmation:"Je, una uhakika unataka kufuta laha hii ya saa?",
  Delete_Schedule_Confirmation:"Je, una uhakika unataka kufuta ratiba hii?",
  Delete_Setting_Confirmation:"Je, una uhakika unataka kuweka upya mpangilio huu?",
  Delete_Fax_Confirmation:"Je, una uhakika unataka kufuta faksi hii?",
  Delete_File_Confirmation:"Je, una uhakika unataka kufuta faili hii?",
  Delete_Vacation_Confirmation:"Je, una uhakika unataka kufuta likizo hii?",
  Delete_Printer_Confirmation:"Je, una uhakika unataka kufuta kichapishi hiki?",
  Remove_Design_Confirmation:"Je, una uhakika unataka kufuta muundo huu?",
  Delete_Payroll_Confirmation:"Je, una uhakika unataka kufuta orodha hii ya malipo?",
  Send_Fax_Email_Confirmation:"Je, ungependa kutuma hati hizi kwa faksi na barua pepe?",
  Send_Email_Confirmation:"Je, ungependa kutuma hati hii kwa barua pepe?",
  Send_Fax_Confirmation:"Je, ungependa kutuma hati hii kwa faksi?",
  Error_Generating_PDF:"Pole. Kulikuwa na hitilafu katika kuzalisha hati hii.",
  PDF_Error_Saving_Image:"Pole. Kulikuwa na hitilafu katika kuhifadhi picha ya PDF ya hati hii.",
  Test_Printer_Confirmation:"Je, ungependa kuchapisha ukurasa wa majaribio kwenye kichapishi hiki?",
  Save_Timesheet_Prompt:"Tafadhali ongeza 'Kichwa' au ubofye 'Anza Kipima Muda' ili kuhifadhi.",
  Remove_Geofence_Prompt:"Je, una uhakika unataka kuondoa eneo la uzio huu wa geo?",
  Delete_Employee_Confirmation:"Je, una uhakika unataka kufuta",
  Fire_Employee_Confirmation:"Je, una uhakika unataka kuwasha moto"
}