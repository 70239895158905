export const Ml = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"പകർപ്പവകാശം &പകർപ്പ്; 2023",
  black:"കറുപ്പ്",
  green:"പച്ച",
  gold:"സ്വർണ്ണം",
  blue:"നീല",
  brown:"തവിട്ട്",
  purple:"പർപ്പിൾ",
  pink:"പിങ്ക്",
  red:"ചുവപ്പ്",
  Swatches:"സ്വാച്ചുകൾ",
  HSL:"എച്ച്എസ്എൽ",
  RGB:"RGB",
  Hue:"നിറം",
  Saturation:"സാച്ചുറേഷൻ",
  Lightness:"ലഘുത്വം",
  Upgrade_To_Pro:"Pro-ലേക്ക് അപ്ഗ്രേഡുചെയ്യുക",
  AllFeaturesInclude:"എല്ലാ സവിശേഷതകളും ഉൾപ്പെടുന്നു:",
  PrintUnlimitedCheques:"അൺലിമിറ്റഡ് ചെക്കുകൾ പ്രിന്റ് ചെയ്യുക",
  PremiumChequeDesigns:"പ്രീമിയം ചെക്ക് ഡിസൈനുകൾ",
  ManageUnlimitedEmployees:"അൺലിമിറ്റഡ് ജീവനക്കാരെ നിയന്ത്രിക്കുക",
  AddUnlimitedPayees:"അൺലിമിറ്റഡ് പേയീസിനെ ചേർക്കുക",
  CreateUnlimitedPayrolls:"പരിധിയില്ലാത്ത ശമ്പളപ്പട്ടികകൾ സൃഷ്ടിക്കുക",
  UnlimitedSchedulesandTimeSheets:"പരിധിയില്ലാത്ത ഷെഡ്യൂളുകളും സമയ ഷീറ്റുകളും",
  BulkPayPalPayouts:"ബൾക്ക് പേപാൽ പേഔട്ടുകൾ",
  UnlimitedBankAccounts:"പരിധിയില്ലാത്ത ബാങ്ക് അക്കൗണ്ടുകൾ",
  ManageMultipleCompanies:"ഒന്നിലധികം കമ്പനികൾ കൈകാര്യം ചെയ്യുക",
  TrackInsurancePolicies:"ഇൻഷുറൻസ് പോളിസികൾ ട്രാക്ക് ചെയ്യുക",
  Bio_MetricProtection:"ബയോ-മെട്രിക് സംരക്ഷണം",
  Geo_FenceLock_OutProtection:"ജിയോ-വേലി ലോക്ക് ഔട്ട് പരിരക്ഷ",
  Multiple_Companies_Word:"ചെക്ക് പ്രീമിയം ഇല്ലാതെ ഒന്നിലധികം കമ്പനികൾ കൈകാര്യം ചെയ്യുന്നത് ലഭ്യമല്ല.",
  PayPal_Payouts_Word:"ചെക്ക് പ്രീമിയം ഇല്ലാതെ PayPal പേയ്‌മെന്റുകൾ പ്രവർത്തനരഹിതമാക്കിയിരിക്കുന്നു.",
  PINProtection:"പിൻ സംരക്ഷണം",
  PasswordProtection:"പാസ്‌വേഡ് പരിരക്ഷണം",
  May_Require_Approval:"അംഗീകാരം ആവശ്യമായി വന്നേക്കാം.",
  Subscribe:"സബ്സ്ക്രൈബ് ചെയ്യുക",
  Billed:"ബിൽ ചെയ്തു",
  Up:"മുകളിലേക്ക്",
  Down:"താഴേക്ക്",
  Positioning:"സ്ഥാനനിർണ്ണയം",
  Marker:"മാർക്കർ",
  Drag_Marker:"മാർക്കർ വലിച്ചിടുക",
  Tagline:"ചെക്കുകൾ അച്ചടിക്കുക, ശമ്പളപ്പട്ടിക തയ്യാറാക്കുക",
  Marker_Word:"മൂലകത്തിന്റെ വലുപ്പത്തിനായി മാർക്കറുകൾ ഉപയോഗിക്കുക.",
  Pinch_Zoom:"പിഞ്ച് സൂം",
  Pinch_Word:"ഘടകം സൂം ചെയ്യാൻ പിഞ്ച് ചെയ്യുക.",
  Drag:"വലിച്ചിടുക",
  Drag_Word:"ഘടകങ്ങൾ വലിച്ചിടാൻ നിങ്ങളുടെ വിരൽ ഉപയോഗിക്കുക.",
  subscription_alias_word:"സബ്‌സ്‌ക്രിപ്‌ഷൻ സ്വയമേവ പുതുക്കുന്നു",
  premium_alias_word:"ഒറ്റത്തവണ അപ്‌ഗ്രേഡ് പാക്കേജ്",
  print_alias_word:"വ്യക്തിഗത ചെക്കുകൾ പ്രിന്റ്-ഔട്ട് ചെയ്യുക",
  mode_alias_word:"മോഡ്",
  Pro:"പ്രൊഫ",
  Pro_word:"പ്രോ പതിപ്പ് ആവശ്യമാണ്.",
  Cant_Delete_Default_Company_Word:"ക്ഷമിക്കണം, നിങ്ങളുടെ ഡിഫോൾട്ട് കമ്പനി ഇല്ലാതാക്കാൻ നിങ്ങൾക്ക് കഴിയില്ല .",
  Reinsert_Default_Designs:"ഡിഫോൾട്ട് ഡിസൈനുകൾ വീണ്ടും ചേർക്കുക",
  Reinsert_Default_Designs_word:"നിങ്ങൾക്ക് ഡിഫോൾട്ട് ഡിസൈനുകൾ വീണ്ടും ചേർക്കണോ?",
  Subscription_Active_Disable_Word:"ഈ സബ്സ്ക്രിപ്ഷൻ സജീവമാണ്. ചെക്കുകളിലേക്കുള്ള ഈ സബ്‌സ്‌ക്രിപ്‌ഷൻ റദ്ദാക്കാൻ നിങ്ങൾക്ക് താൽപ്പര്യമുണ്ടോ?",
  Company_Logo:"കമ്പനി ലോഗോ",
  ZERO_:"ZERO",
  Disclaimer:"നിരാകരണം",
  Privacy_Policy:"സ്വകാര്യതാ നയം",
  EULA:"EULA പരിശോധിക്കുന്നു",
  Terms_Of_Service:"സേവന നിബന്ധനകൾ",
  Terms_Of_Use:"ഉപയോഗ നിബന്ധനകൾ",
  Refunds:"റീഫണ്ട് നയം",
  Single_Print:"1 പരിശോധിക്കുക",
  Two_Prints:"2 പരിശോധനകൾ",
  Five_Prints:"5 പരിശോധനകൾ",
  Ten_Prints:"10 പരിശോധനകൾ",
  Fifteen_Prints:"15 ചെക്കുകൾ",
  Twenty_Prints:"20 ചെക്കുകൾ",
  Thirty_Prints:"30 ചെക്കുകൾ",
  Image_Added:"ചിത്രം ചേർത്തു",
  Image_Preview:"ചിത്ര പ്രിവ്യൂ",
  No_Image_Was_Selected:"ചിത്രമൊന്നും തിരഞ്ഞെടുത്തിട്ടില്ല.",
  Cheques_Unlimited:"അൺലിമിറ്റഡ് പരിശോധനകൾ",
  _Subscription:"സബ്സ്ക്രിപ്ഷൻ",
  Subscription:"ചെക്കുകൾ - 1 മാസം",
  Two_Month_Subscription:"ചെക്കുകൾ - 2 മാസം",
  Three_Month_Subscription:"ചെക്കുകൾ - 3 മാസം",
  Six_Month_Subscription:"ചെക്കുകൾ - 6 മാസം",
  Twelve_Month_Subscription:"ചെക്കുകൾ - 12 മാസം",
  Cheques_Are_Available:"പ്രിന്റ് ചെയ്യാൻ ചെക്കുകൾ ലഭ്യമാണ്.",
  Upgrade_Required_Two:"നിങ്ങളുടെ വാങ്ങൽ ആരംഭിക്കാൻ ഒരു പാക്കേജ് തിരഞ്ഞെടുത്ത് വില ബട്ടണിൽ രണ്ടുതവണ ടാപ്പ് ചെയ്യുക. പൂർണ്ണ വർണ്ണ പരിശോധനകൾ സെക്കൻഡിൽ പ്രിന്റ് ചെയ്യുക.",
  Month:"മാസം",
  Due:"കാരണം:",
  Expires:"കാലഹരണപ്പെടുന്നു:",
  Subscription_Active:"സബ്സ്ക്രിപ്ഷൻ സജീവമാണ്",
  Subscription_Inactive:"സബ്‌സ്‌ക്രിപ്‌ഷൻ നിഷ്‌ക്രിയമാണ്",
  Purchase_Additional_Cheques:"അധിക ചെക്കുകൾ വാങ്ങണോ?",
  Printable_Cheque:"അച്ചടിക്കാവുന്ന ചെക്ക്",
  Printable_Cheques:"അച്ചടിക്കാവുന്ന ചെക്കുകൾ",
  Printable_Cheque_Word:"ചെക്ക് നിങ്ങളുടെ അക്കൗണ്ടിൽ ലഭ്യമാണ്.",
  Printable_Cheques_Word:"ചെക്കുകൾ നിങ്ങളുടെ അക്കൗണ്ടിൽ ലഭ്യമാണ്.",
  Max_Amount_Message:"നിങ്ങൾ വ്യക്തമാക്കിയ തുക ഈ സിസ്റ്റത്തിനായി സജ്ജീകരിച്ചിരിക്കുന്ന പരമാവധി തുകയിൽ എത്തിയിരിക്കുന്നു:",
  Terms_Required_Word:"ചെക്കുകൾ ഉപയോഗിക്കുന്നത് തുടരുന്നതിന് മുമ്പ് നിങ്ങൾ ഈ ഉടമ്പടി അംഗീകരിക്കണം.",
  Subscriptions:"സബ്സ്ക്രിപ്ഷനുകൾ",
  Product_Upgrades:"നവീകരിക്കുന്നു",
  Mailed_Out_Cheques:"മെയിൽ-ഔട്ട് ചെക്കുകൾ",
  Enter_A_Company_Name:"ദയവായി ഒരു കമ്പനിയുടെ പേര് നൽകുക.",
  Single_Cheques:"ഒറ്റ ചെക്കുകൾ",
  Cheque_Golden:"ഗോൾഡൻ ചെക്ക്",
  Cheque_Golden_Window:"ഗോൾഡൻ ചെക്ക് ഡിസൈൻ.",
  Cheque_Green:"ഗ്രീൻ ചെക്ക്",
  Cheque_Green_Window:"ഗ്രീൻ ചെക്ക് ഡിസൈൻ.",
  Cheque_Red:"റെഡ് ചെക്ക്",
  Cheque_Red_Window:"ചുവന്ന ചെക്ക് ഡിസൈൻ.",
  Cheque_Yellow:"മഞ്ഞ ചെക്ക്",
  Cheque_Yellow_Window:"മഞ്ഞ ചെക്ക് ഡിസൈൻ.",
  Cheque_Statue_of_Liberty:"സ്വാതന്ത്ര്യ പ്രതിമ",
  Cheque_Statue_of_Liberty_Window:"സ്റ്റാച്യു ഓഫ് ലിബർട്ടി ചെക്ക് ഡിസൈൻ.",
  Cheque_Green_Wave:"ഗ്രീൻ വേവ്",
  Cheque_Green_Wave_Window:"ഗ്രീൻ ചെക്ക് ഡിസൈൻ.",
  Cheque_Golden_Weave:"ഗോൾഡൻ നെയ്ത്ത്",
  Cheque_Golden_Weave_Window:"ഗംഭീരമായ സ്വർണ്ണ ചെക്ക് ഡിസൈൻ.",
  Cheque_Green_Sun:"പച്ച സൂര്യൻ",
  Cheque_Green_Sun_Window:"ആഴത്തിലുള്ളതും ശാന്തവുമായ ചെക്ക് ഡിസൈൻ.",
  Cheque_Blue_Checkers:"നീല ചെക്കറുകൾ",
  Cheque_Blue_Checkers_Window:"നീല ചെക്ക് ഡിസൈൻ.",
  Cashiers_Check:"കാഷ്യറുടെ ചെക്ക്",
  Cashiers_Check_Window:"കാഷ്യറുടെ ചെക്ക് സ്റ്റൈൽ ടെംപ്ലേറ്റ്.",
  Cheque_Aqua_Checkers:"അക്വാ ചെക്കറുകൾ",
  Cheque_Aqua_Checkers_Window:"അക്വാ ചെക്ക് ഡിസൈൻ.",
  Cheque_Golden_Checkers:"ഗോൾഡൻ ചെക്കറുകൾ",
  Cheque_Golden_Checkers_Window:"ഗോൾഡൻ ചെക്ക് ഡിസൈൻ.",
  Upgrade_Unavailable:"അപ്‌ഗ്രേഡുകൾ ലഭ്യമല്ല",
  Bank_Code_Protection:"ബാങ്ക് നമ്പർ സംരക്ഷണം",
  Bank_Code_Protection_Word:"മറ്റൊരു ഉപകരണത്തിലോ മറ്റൊരു ഉപയോക്താവിനോ പ്രവർത്തിക്കുന്ന ഈ ആപ്പിൽ ഉപയോഗിക്കുന്നതിൽ നിന്ന് നിങ്ങളുടെ ബാങ്ക് നമ്പറുകൾ പരിരക്ഷിക്കുക. ഈ പ്രവർത്തനം മാറ്റാനാവില്ല. തുടരുക?",
  Bank_Code_Protection_Blocked_Word:"നിങ്ങൾ ഉപയോഗിക്കാൻ ശ്രമിക്കുന്ന ബാങ്ക് നമ്പറുകൾ മറ്റൊരു ഉപയോക്താവിനോ ഉപകരണത്തിനോ വേണ്ടി റിസർവ് ചെയ്തിരിക്കുന്നു.",
  Bank_Code_Protection_Error_Word:"നമ്പർ പരിശോധിച്ചുറപ്പിക്കൽ പരാജയപ്പെട്ടു. ഇന്റർനെറ്റിലേക്ക് കണക്‌റ്റ് ചെയ്‌ത് ഈ ബാങ്ക് അക്കൗണ്ട് വീണ്ടും ചേർക്കാൻ ശ്രമിക്കുക.",
  Bank_Code_Protection_Set_Error_Word:"ബാങ്ക് നമ്പർ പരിരക്ഷയ്‌ക്ക് നിങ്ങൾ ഇന്റർനെറ്റുമായി കണക്‌റ്റ് ചെയ്‌തിരിക്കണം. ദയവായി ബന്ധിപ്പിച്ച് വീണ്ടും ശ്രമിക്കുക.",
  Upgrade_Unavailable_Word:"ക്ഷമിക്കണം, നിങ്ങളെ പരിശോധിക്കുന്നതിൽ ഞങ്ങൾക്ക് പ്രശ്‌നമുണ്ട്. ചെക്കുകളിലേക്കുള്ള സബ്‌സ്‌ക്രിപ്‌ഷനുകളും അപ്‌ഗ്രേഡുകളും നിങ്ങളുടെ പ്രദേശത്ത് വാങ്ങുന്നതിന് നിലവിൽ ലഭ്യമല്ല.",
  PayPal_Payment_Preview:"പേപാൽ പേയ്‌മെന്റ് പ്രിവ്യൂ",
  Apple_Pay:"ആപ്പിൾ പേ",
  Select_PayPal:"PayPal തിരഞ്ഞെടുക്കുക",
  PayPal_Applications:"പേപാൽ ആപ്ലിക്കേഷനുകൾ",
  Purchase_With_Apple_Pay:"Apple Pay ഉപയോഗിച്ച് വാങ്ങുക",
  Google_Pay:"Google Pay",
  Companies:"കമ്പനികൾ",
  Insurance:"ഇൻഷുറൻസ്",
  New_PayPal:"പുതിയ പേപാൽ",
  Pay_By:"പണം നൽകുക",
  Insure:"ഇൻഷ്വർ ചെയ്യുക",
  Miles:"മൈലുകൾ",
  Payment_Method:"പണമടയ്ക്കൽ രീതി",
  Select_Policies:"നയങ്ങൾ തിരഞ്ഞെടുക്കുക",
  Policies:"നയങ്ങൾ",
  Policy:"നയം",
  No_PayPal_Account:"പേപാൽ അക്കൗണ്ട് ഇല്ല",
  No_Policies:"ഇൻഷുറൻസ് പോളിസികളൊന്നുമില്ല",
  New_Policy:"പുതിയ നയം",
  PayPal_Payment:"പേപാൽ പേയ്മെന്റ്",
  PayPal_Payments:"പേപാൽ പേയ്‌മെന്റുകൾ",
  No_Payment_Selected:"പേയ്‌മെന്റൊന്നും തിരഞ്ഞെടുത്തിട്ടില്ല",
  Sending_Payment_Word:"ദയവായി കാത്തിരിക്കൂ... ഈ പേയ്‌മെന്റ് അയയ്‌ക്കുന്നു.",
  Sending_Payments_Word:"ദയവായി കാത്തിരിക്കൂ... പേയ്‌മെന്റുകൾ അയയ്‌ക്കുന്നു.",
  No_Payment_Selected_PayPal:"<a routerLink='/folder/Payments'>PayPal പേയ്‌മെന്റ്</a> ഒന്നും തിരഞ്ഞെടുത്തിട്ടില്ല.",
  Payment_Sent:"പേയ്മെന്റ് അയച്ചു",
  PayPal_Payment_Sent:"ഈ പേയ്‌മെന്റ് പേപാൽ ഉപയോഗിച്ച് അയച്ചു.",
  Copay:"പകർത്തുക",
  Dead:"മരിച്ചു",
  Alive:"ജീവനോടെ",
  Not_Dead:"മരിച്ചിട്ടില്ല",
  Unclaimed:"അവകാശപ്പെടാത്തത്",
  Attempted:"ശ്രമിച്ചു",
  Deceased:"അന്തരിച്ചു",
  Claimed:"അവകാശപ്പെട്ടു",
  Unpaid:"പണം നൽകാത്തത്",
  Sending_Payment:"പേയ്‌മെന്റ് അയയ്ക്കുന്നു",
  Sending_Payments:"പേയ്‌മെന്റുകൾ അയയ്ക്കുന്നു",
  Send_PayPal_Confirmation:"നിങ്ങൾക്ക് ഈ ഇടപാട് PayPal-ൽ അയയ്‌ക്കണോ?",
  Send_PayPal_Confirmation_Word:"ഈ ഇടപാട് അയയ്ക്കാൻ പച്ച ബട്ടൺ അമർത്തുക.",
  Save_Payment_As_Unpaid:"ഈ പേയ്‌മെന്റ് പണം നൽകാത്തതായി സംരക്ഷിക്കണോ?",
  Payment_Pay_Confirmation_PayPal:"ഈ പേയ്‌മെന്റ് പണമടച്ചതായി സംരക്ഷിക്കണോ?",
  No_Policies_Word:"ആദ്യത്തെ <a routerLink='/folder/Postage/New'>ഇൻഷുറൻസ് പോളിസി</a> ചേർക്കുക .",
  Timesheet_Multiple_Delete_Confirmation:"ഒന്നിലധികം ടൈംഷീറ്റുകൾ ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  Select_Multiple_Timesheets_Prompt:"ടൈംഷീറ്റുകളൊന്നും തിരഞ്ഞെടുത്തിട്ടില്ല. കുറഞ്ഞത് ഒരു ടൈംഷീറ്റെങ്കിലും തിരഞ്ഞെടുക്കുക.",
  Select_Multiple_Policies_Prompt:"നയങ്ങളൊന്നും തിരഞ്ഞെടുത്തിട്ടില്ല. കുറഞ്ഞത് ഒരു ഇൻഷുറൻസ് പോളിസിയെങ്കിലും തിരഞ്ഞെടുക്കുക.",
  Policies_Multiple_Delete_Confirmation:"ഒന്നിലധികം നയങ്ങൾ ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  Company:"കമ്പനി",
  Add_Company:"കമ്പനി ചേർക്കുക",
  New_Company:"കമ്പനി ചേർക്കുക",
  No_Companies:"കമ്പനികളില്ല",
  Enable_Company:"കമ്പനി പ്രവർത്തനക്ഷമമാക്കുക",
  Select_Company:"കമ്പനി തിരഞ്ഞെടുക്കുക",
  The_Default_Company:"സ്ഥിരസ്ഥിതി കമ്പനി ലേബൽ.",
  Manage_Companies:"കമ്പനികൾ നിയന്ത്രിക്കുക",
  No_Companies_Word:"പരിശോധനകൾ ഒരു സ്ഥിരസ്ഥിതി കമ്പനിയെ ഉപയോഗിക്കും .<br /> <a routerLink='/folder/Company/New'>ആദ്യ കമ്പനി</a> ചേർക്കുക .",
  Default_Company:"സ്ഥിരസ്ഥിതി കമ്പനി",
  Cheques_Unlimited_Word:"നിങ്ങൾക്ക് ഇഷ്ടമുള്ളത്ര ചെക്കുകൾ പ്രിന്റ് ചെയ്യാൻ ചെക്കുകൾ അൺലിമിറ്റഡ് നിങ്ങളെ അനുവദിക്കുന്നു.",
  Cheques_Subscription_Word:"ഒരു ചെക്ക് സബ്‌സ്‌ക്രിപ്‌ഷൻ നിങ്ങൾക്ക് ഇഷ്ടമുള്ളത്ര ചെക്കുകൾ പ്രിന്റ് ചെയ്യാൻ അനുവദിക്കുന്നു.",
  You_Own_This_Product:"ഈ ഉൽപ്പന്നം നിങ്ങളുടേതാണ്.",
  Your_Subscription_is_Active:"നിങ്ങളുടെ സബ്‌സ്‌ക്രിപ്‌ഷൻ സജീവമാണ്.",
  Active_Products:"സജീവമാക്കിയ ഉൽപ്പന്നങ്ങൾ",
  Purchase_Confirmation:"വാങ്ങൽ",
  Purchase_Cheques:"പർച്ചേസ് ചെക്കുകൾ",
  Restore_Purchase:"വാങ്ങലുകൾ പുനഃസ്ഥാപിക്കുക",
  Erase_Purchase:"വാങ്ങലുകൾ മായ്‌ക്കുക",
  Successfully_Purchased:"വിജയകരമായി വാങ്ങി",
  Enter_Your_Licence_Key:"ഈ ഉൽപ്പന്നം സജീവമാക്കുന്നതിന് ദയവായി ഈ പേജിൽ നിങ്ങളുടെ ലൈസൻസ് കീ നൽകുക.",
  Licence_Key:"അനുവാദ പത്രം",
  Enter_Licence_Key:"ലൈസൻസ് കീ നൽകുക",
  Purchased:"വാങ്ങിയത്",
  Enable_Feature:"ഫീച്ചർ പ്രവർത്തനക്ഷമമാക്കുക",
  Cancel_Subscription:"സബ്സ്ക്രിപ്ഷൻ റദ്ദാക്കുക",
  Subscription_Removed:"സബ്സ്ക്രിപ്ഷൻ നീക്കം ചെയ്തു",
  Select_Active_Subscription:"അത് പരിഷ്‌ക്കരിക്കുന്നതിന് ഒരു സജീവ സബ്‌സ്‌ക്രിപ്‌ഷൻ തിരഞ്ഞെടുക്കുക.",
  Remove_Subscription_Word:"ഈ സബ്‌സ്‌ക്രിപ്‌ഷൻ റദ്ദാക്കണമെന്ന് തീർച്ചയാണോ?",
  Delete_Company_Confirmation:"ഈ കമ്പനി മുഴുവൻ ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ? മുന്നറിയിപ്പ്: സംഭരിച്ചിരിക്കുന്ന എല്ലാ വിവരങ്ങളും നിങ്ങൾക്ക് നഷ്ടപ്പെടും!",
  Delete_Default_Company_Word:"നിങ്ങൾക്ക് സ്ഥിരസ്ഥിതി കമ്പനി ഇല്ലാതാക്കാൻ കഴിയില്ല . ആപ്ലിക്കേഷൻ പുനഃസജ്ജമാക്കാനും അത് സ്ഥിരസ്ഥിതിയിലേക്ക് പുനഃസ്ഥാപിക്കാനും നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ? മുന്നറിയിപ്പ്: സംഭരിച്ചിരിക്കുന്ന എല്ലാ വിവരങ്ങളും നിങ്ങൾക്ക് നഷ്ടപ്പെടും!",
  Console_Warning_2:"നിലവിൽ നിങ്ങളുടേതല്ലാത്ത ഈ ആപ്ലിക്കേഷൻ ഉപയോഗിച്ച് ഒരു കറൻസിയും കൈകാര്യം ചെയ്യരുത്.",
  Terms_and_Conditions:"ഉപാധികളും നിബന്ധനകളും",
  and_the:"കൂടാതെ",
  for:"വേണ്ടി",
  Please_Read_Word:"ദയവായി വായിക്കുകയും അംഗീകരിക്കുകയും ചെയ്യുക",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"ചില കറൻസി പരിവർത്തന നിരക്കുകൾ കണ്ടെത്താനായില്ല. ദയവായി ഇന്റർനെറ്റിലേക്ക് കണക്‌റ്റ് ചെയ്യുക.",
  Free:"സൗ ജന്യം",
  DB_Erase_Prompt_2:"മുഴുവൻ ഡെവലപ്പർ ഡാറ്റാബേസും പൂർണ്ണമായും മായ്‌ക്കണോ? മുന്നറിയിപ്പ്: നിങ്ങൾക്ക് എല്ലാ വാങ്ങലുകളും സബ്‌സ്‌ക്രിപ്‌ഷൻ വിവരങ്ങളും നഷ്‌ടമാകും!",
  Successfully_Imported:"വിജയകരമായി ഇറക്കുമതി ചെയ്തു",
  Select_Postage:"തപാൽ തുക തിരഞ്ഞെടുക്കുക",
  No_Postage:"തപാൽ സ്റ്റാമ്പുകൾ ഇല്ല",
  No_Paid_Postage_Word:"ആദ്യത്തെ <a routerLink='/folder/Postage/New'>പണമടച്ച തപാൽ</a> സ്റ്റാമ്പ് ചേർക്കുക .",
  Trial_Complete:'ട്രയൽ പൂർത്തിയായി',
  Please_Upgrade:'പ്രിന്റിംഗ് തുടരാൻ ചെക്കുകൾ അപ്‌ഗ്രേഡ് ചെയ്യുക.',
  Aa:"Aa",
  Color:"നിറം",
  Font:"ഫോണ്ട്",
  Guide:"വഴികാട്ടി",
  Guides:"വഴികാട്ടികൾ",
  Image:"ചിത്രം",
  Zoom:"സൂം ചെയ്യുക",
  Logo:"ലോഗോ",
  Bank:"ബാങ്ക്",
  MICR:"MICR",
  Total:"ആകെ",
  Cancel:"റദ്ദാക്കുക",
  Confirm:"സ്ഥിരീകരിക്കുക",
  Method:"രീതി",
  Biometric_Security:"ബയോമെട്രിക് സുരക്ഷ",
  Please_Login_To_Continue:"തുടരാൻ ദയവായി ലോഗിൻ ചെയ്യുക.",
  Complete:"പൂർത്തിയാക്കുക",
  Sign_Up:"സൈൻ അപ്പ് ചെയ്യുക",
  Error:"പിശക്",
  Biometrics:"ബയോമെട്രിക്സ്",
  Percent:"ശതമാനം",
  Zero_Percent:"0%",
  Top_Margin:"ടോപ്പ് മാർജിൻ",
  Bottom_Margin:"താഴെയുള്ള മാർജിൻ",
  Left_Margin:"ഇടത് മാർജിൻ",
  Right_Margin:"വലത് മാർജിൻ",
  MICR_Margin:"MICR മാർജിൻ",
  Table_Margin:"പട്ടിക മാർജിൻ",
  Address_Margin:"വിലാസ മാർജിൻ",
  Percentage_:"ശതമാനം",
  Vacation_Title:"അവധിക്കാല ശീർഷകം",
  Use_PIN:"പിൻ ഉപയോഗിക്കുക",
  Loading__:"ലോഡിംഗ്...",
  Design_Title:"ഡിസൈൻ ശീർഷകം",
  Authorize:"അധികാരപ്പെടുത്തുക",
  Key:"താക്കോൽ",
  Public_Key:"പൊതു കീ",
  Private_Key:"സ്വകാര്യ കീ",
  Authenticate:"ആധികാരികമാക്കുക",
  Last_Payroll:"അവസാന ശമ്പളപ്പട്ടിക",
  Last_Calculation:"അവസാന കണക്കുകൂട്ടൽ",
  Authorized:"അധികാരപ്പെടുത്തിയത്",
  Geo_Authorized:"ജിയോ-ലൊക്കേഷൻ: അംഗീകൃത",
  Not_Authorized:"അധികാരപ്പെടുത്തിയിട്ടില്ല",
  Authorization_Complete:"അംഗീകാരം പൂർത്തിയായി",
  Geolocation_Authorization:"ജിയോ-ലൊക്കേഷൻ ഓതറൈസേഷൻ",
  Out_of_Bounds:"അതിർത്തിക്കപ്പുറത്താണ്",
  Cant_Delete_Default_Design:"ഒരു ഡിഫോൾട്ട് ഡിസൈൻ ഇല്ലാതാക്കാൻ കഴിയില്ല.",
  Unauthorized_Out_of_Bounds:"അനധികൃതം: പരിധിക്ക് പുറത്ത്",
  Biometric_Authorization:"ബയോമെട്രിക് അംഗീകാരം",
  Locating_Please_Wait:"കണ്ടെത്തുന്നു, ദയവായി കാത്തിരിക്കൂ...",
  Test_Biometrics:"ബയോമെട്രിക്സ് പരീക്ഷിക്കുക",
  Cheque_Margins:"മാർജിനുകൾ പരിശോധിക്കുക",
  Percentage_Full_Error:"ശതമാനം ഫീൽഡ് 100% ശതമാനത്തിൽ കൂടുതൽ സജ്ജീകരിക്കാൻ കഴിയില്ല.",
  No_Payroll_Text:"ഒരു <a routerLink='/folder/Employee/New'>ജീവനക്കാരൻ</a> അല്ലെങ്കിൽ <a routerLink='/folder/Payee/New'>പണമടയ്ക്കുന്നയാൾ</a> കൂടാതെ ഒരു <a routerLink='/folder/Schedule/New'>ഷെഡ്യൂൾ</a>.",
  Design_Saved:"ഡിസൈൻ സംരക്ഷിച്ചു",
  Default_Design_Selected:"ഡിഫോൾട്ട് ഡിസൈൻ തിരഞ്ഞെടുത്തു",
  Partial_Import:"ഭാഗിക ഇറക്കുമതി",
  Partial_Export:"ഭാഗിക കയറ്റുമതി",
  Entire_Import:"മുഴുവൻ ഇറക്കുമതി",
  Entire_Export:"മുഴുവൻ കയറ്റുമതി",
  Existing_Password:"നിങ്ങളുടെ നിലവിലുള്ള പാസ്‌വേഡ് ദയവായി നൽകുക:",
  Existing_PIN:"നിങ്ങളുടെ നിലവിലുള്ള പിൻ കോഡ് നൽകുക:",
  Pin_Change_Header:"പിൻ സ്ഥിരീകരണം",
  Pin_Change_SubHeader:"മാറ്റം സ്ഥിരീകരിക്കാൻ നിങ്ങളുടെ പഴയ പിൻ നമ്പർ നൽകുക.",
  Pass_Change_Header:"പാസ്‌വേഡ് സ്ഥിരീകരണം",
  Pass_Change_SubHeader:"മാറ്റം സ്ഥിരീകരിക്കാൻ നിങ്ങളുടെ പഴയ പാസ്‌വേഡ് നൽകുക.",
  PIN_Enter_Message:"സ്ഥിരീകരിക്കാൻ നിങ്ങളുടെ പിൻ നൽകുക.",
  Password_Enter_Message:"സ്ഥിരീകരിക്കാൻ നിങ്ങളുടെ പാസ്‌വേഡ് നൽകുക.",
  Pin_Updated_Success:"പിൻ അപ്ഡേറ്റ് ചെയ്തു!",
  Pin_Updated_Fail:"പിൻ അപ്ഡേറ്റ് ചെയ്യാൻ കഴിഞ്ഞില്ല.",
  Pass_Updated_Success:"പാസ്‌വേഡ് വിജയകരമായി അപ്‌ഡേറ്റ് ചെയ്‌തു.",
  Pass_Updated_Fail:"പാസ്‌വേഡ് അപ്‌ഡേറ്റ് ചെയ്യാൻ കഴിഞ്ഞില്ല.",
  Preview_Payment:"പേയ്‌മെന്റ് പ്രിവ്യൂ ചെയ്യുക",
  Preview_Payroll:"പ്രിവ്യൂ പേറോൾ",
  No_Payments_Created:"പേയ്‌മെന്റുകളൊന്നും സൃഷ്‌ടിച്ചതായി കണ്ടെത്തിയില്ല.",
  Payment_Preview:"പേയ്‌മെന്റ് പ്രിവ്യൂ",
  Enable_Payee:"പണം സ്വീകരിക്കുന്നയാളെ പ്രവർത്തനക്ഷമമാക്കുക",
  Rendered:"റെൻഡർ ചെയ്തു",
  No_Email:"ഇമെയിൽ ഇല്ല",
  Setup_Cheques:"സജ്ജീകരണ പരിശോധനകൾ",
  name:"പേര്",
  address:"വിലാസം",
  address_2:"വിലാസം വരി 2",
  city:"നഗരം",
  province:"പ്രവിശ്യ",
  postal_code:"തപാൽ/പിൻ കോഡ്",
  country:"രാജ്യം",
  username:"ഉപയോക്തൃനാമം",
  full_name:"പൂർണ്ണമായ പേര്",
  birthday:"ജന്മദിനം",
  email:"ഇമെയിൽ",
  phone:"ഫോൺ",
  employees:"ജീവനക്കാർ",
  addresses:"വിലാസങ്ങൾ",
  payment_amount_limit:"പേയ്മെന്റ് തുക പരിധി",
  total_limit:"ആകെ പരിധി",
  payees:"പണം നൽകുന്നവർ",
  description:"വിവരണം",
  address_line_one:"വിലാസം ലൈൻ ഒന്ന്",
  address_line_two:"വിലാസം ലൈൻ രണ്ട്",
  image:"ചിത്രം",
  account_holder:"അക്കൗണ്ടിന്റെ ഉടമസ്ഥന്",
  cheque_starting_id:"ആരംഭിക്കുന്ന ഐഡി പരിശോധിക്കുക",
  transit_number:"ട്രാൻസിറ്റ് നമ്പർ",
  institution_number:"സ്ഥാപന നമ്പർ",
  designation_number:"പദവി നമ്പർ",
  account_number:"അക്കൗണ്ട് നമ്പർ",
  currency:"കറൻസി",
  signature:"കയ്യൊപ്പ്",
  payment_payroll_limit:"പേയ്മെന്റ് പരിധി",
  total_limi:"ആകെ പരിധി",
  date:"തീയതി",
  printed_memo:"അച്ചടിച്ച മെമ്മോ",
  banks:"ബാങ്കുകൾ",
  signature_image:"ഒപ്പ് ചിത്രം",
  address_name:"വിലാസത്തിന്റെ പേര്",
  notes:"കുറിപ്പുകൾ",
  design:"ഡിസൈൻ",
  title:"തലക്കെട്ട്",
  frequency:"ആവൃത്തി",
  fax:"ഫാക്സ്",
  salaries:"ശമ്പളം",
  salary_ids:"ശമ്പള ഐഡികൾ",
  start_time:"ആരംഭ സമയം",
  end_time:"അവസാന സമയം",
  paid:"പണം നൽകി",
  overtime_percentage:"പണമടച്ച ശതമാനം",
  overtime_amount:"നിശ്ചിത തുക അടച്ചു",
  first_name:"പേരിന്റെ ആദ്യഭാഗം",
  last_name:"പേരിന്റെ അവസാന ഭാഗം",
  moderation:"മോഡറേഷൻ",
  create:"സൃഷ്ടിക്കാൻ",
  edit:"എഡിറ്റ് ചെയ്യുക",
  destroy:"നശിപ്പിക്കുക",
  day_start_time:"ദിവസം_ആരംഭ സമയം",
  day_end_time:"ദിവസം_അവസാന_സമയം",
  fullname:"പേര്",
  time:"സമയം",
  auto_send:"സ്വയമേവ അയയ്‌ക്കുക",
  time_method:"സമയ രീതി",
  schedules:"ഷെഡ്യൂളുകൾ",
  indefinite_payroll_enabled:"അനിശ്ചിതമായി പ്രവർത്തനക്ഷമമാക്കുക",
  amount:"തുക",
  repeat:"ആവർത്തിച്ച്",
  always_enabled:"എപ്പോഴും പ്രവർത്തനക്ഷമമാക്കിയിരിക്കുന്നു",
  start_date:"തുടങ്ങുന്ന ദിവസം",
  end_date:"അവസാന ദിവസം",
  Cheque_Total:"ആകെ പരിശോധിക്കുക",
  Total_Amount:"മൊത്തം തുക:",
  Amounts:"തുകകൾ:",
  Images:"ചിത്രങ്ങൾ",
  Files:"ഫയലുകൾ",
  Previewing:"പ്രിവ്യൂ ചെയ്യുന്നു:",
  Insert:"തിരുകുക",
  Preview_Import:"പ്രിവ്യൂ ഇറക്കുമതി",
  Entry:"പ്രവേശനം",
  Entries:"എൻട്രികൾ",
  No_Entries:"എൻട്രികൾ ഇല്ല",
  Import_Type:"ഇറക്കുമതി തരം",
  Select_Details:"വിശദാംശങ്ങൾ തിരഞ്ഞെടുക്കുക",
  Select_Payee:"പണം സ്വീകരിക്കുന്നയാളെ തിരഞ്ഞെടുക്കുക",
  Enable_Holidays:"അവധിദിനങ്ങൾ പ്രവർത്തനക്ഷമമാക്കുക",
  Disable_Holidays:"അവധിദിനങ്ങൾ പ്രവർത്തനരഹിതമാക്കുക",
  Wire_Transfer:"വയർ ട്രാൻസ്ഫർ",
  New_Export:"പുതിയ കയറ്റുമതി",
  Export_Data:"ഡാറ്റ കയറ്റുമതി ചെയ്യുക",
  Export_Data_Word:"കയറ്റുമതി ചെയ്യാനും ഡൗൺലോഡ് ചെയ്യാനുമുള്ള ഫയലിന്റെ തരം തിരഞ്ഞെടുക്കുക.",
  Export_File:"കയറ്റുമതി ഫയൽ",
  Mode:"മോഡ്",
  Times:"സമയങ്ങൾ",
  Widgets:"വിജറ്റുകൾ",
  Slider:"സ്ലൈഡർ",
  Set_Details:"വിശദാംശങ്ങൾ സജ്ജമാക്കുക",
  Select_Type:"തരം തിരഞ്ഞെടുക്കുക",
  Display_Slider:"സ്ലൈഡർ പ്രദർശിപ്പിക്കുക",
  Dashboard_Slider:"ഡാഷ്ബോർഡ് സ്ലൈഡർ",
  Dashboard_Mode:"ഡാഷ്ബോർഡ് മോഡ്",
  Show_Intro:"ആമുഖം കാണിക്കുക",
  Show_Payrolls:"ശമ്പളപ്പട്ടിക കാണിക്കുക",
  Show_Holidays:"അവധിദിനങ്ങൾ കാണിക്കുക",
  Show_Invoices:"ഇൻവോയ്‌സുകൾ കാണിക്കുക",
  Show_Cheques:"ചെക്കുകൾ കാണിക്കുക",
  Enabled_Widgets:"പ്രവർത്തനക്ഷമമാക്കിയ വിജറ്റുകൾ",
  Disabled_Widgets:"പ്രവർത്തനരഹിതമാക്കിയ വിജറ്റുകൾ",
  Colors:"നിറങ്ങൾ",
  Barcodes:"ബാർകോഡുകൾ",
  View_Timers:"ടൈമറുകൾ കാണുക",
  Gradients:"ഗ്രേഡിയന്റ്സ്",
  No_Info:"വിവരമില്ല",
  Disable:"പ്രവർത്തനരഹിതമാക്കുക",
  Show_Layer:"ലെയറുകൾ കാണിക്കുക",
  Hide_Layer:"പാളികൾ മറയ്ക്കുക",
  Text_Layer:"ടെക്സ്റ്റ് ലെയറുകൾ",
  Secondly:"രണ്ടാമതായി",
  Minutely:"മിനിട്ടായി",
  nine_am:"9:00 AM",
  five_pm:"5:00 PM",
  Enable_Address:"വിലാസം പ്രവർത്തനക്ഷമമാക്കുക",
  Invalid_File_Type:"ക്ഷമിക്കണം, ഒരു അസാധുവായ ഫയൽ തരം തിരഞ്ഞെടുത്തു. പിന്തുണയ്ക്കുന്ന ഫയൽ തരം:",
  Error_Updating_Entry:"ക്ഷമിക്കണം, ഈ എൻട്രി അപ്ഡേറ്റ് ചെയ്യുന്നതിൽ ഒരു പിശകുണ്ടായി.",
  Schedule_Timing_Alert:"പിശക്: ഷെഡ്യൂൾ ആരംഭിക്കുന്ന സമയം അവസാനിക്കുന്ന സമയത്തിന് ശേഷം ഒരു മൂല്യത്തിലേക്ക് സജ്ജീകരിച്ചിരിക്കുന്നു.",
  Select_Multiple_Payments_Prompt:"പേയ്‌മെന്റുകളൊന്നും തിരഞ്ഞെടുത്തിട്ടില്ല. കുറഞ്ഞത് ഒരു പേയ്‌മെന്റെങ്കിലും തിരഞ്ഞെടുക്കുക.",
  Select_Multiple_Cheques_Prompt:"ചെക്കുകളൊന്നും തിരഞ്ഞെടുത്തിട്ടില്ല. ദയവായി ഒരു ചെക്കെങ്കിലും തിരഞ്ഞെടുക്കുക.",
  Select_Multiple_Items_Prompt:"ഇനങ്ങളൊന്നും തിരഞ്ഞെടുത്തിട്ടില്ല. ദയവായി ഒരു ഇനമെങ്കിലും തിരഞ്ഞെടുക്കുക.",
  Paymemt_Multiple_Delete_Confirmation:"ഒന്നിലധികം പേയ്‌മെന്റുകൾ ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  Cheque_Multiple_Delete_Confirmation:"ഒന്നിലധികം പരിശോധനകൾ ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  Payee_Multiple_Delete_Confirmation:"ഒന്നിലധികം പണം നൽകുന്നവരെ ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  Employee_Multiple_Delete_Confirmation:"ഒന്നിലധികം ജീവനക്കാരെ ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  MICR_Warning:"ശ്രദ്ധിക്കുക: ചില പ്രിന്ററുകളും ഉപകരണങ്ങളും MICR ഫോണ്ട് ശരിയായി പ്രദർശിപ്പിച്ചേക്കില്ല.",
  Automatically_Send:"സ്വയമേവ അയയ്‌ക്കുക",
  Type:"ടൈപ്പ് ചെയ്യുക",
  Payment_Type:"പേയ്മെന്റ് തരം",
  Auto_Send:"സ്വയമേവ അയയ്ക്കുക",
  Automatically_Process:"യാന്ത്രികമായി പ്രോസസ്സ് ചെയ്യുക",
  Auto_Process:"യാന്ത്രിക പ്രക്രിയ",
  Image_Based:"ചിത്രം അടിസ്ഥാനമാക്കിയുള്ളത്",
  Font_Based:"ഫോണ്ട് അടിസ്ഥാനമാക്കിയുള്ളത്",
  Rerender:"വീണ്ടും റെൻഡർ ചെയ്യുക",
  Rendering:"റെൻഡറിംഗ്",
  Render:"ഫയലുകൾ",
  Top:"മുകളിൽ",
  Middle:"മധ്യഭാഗം",
  Bottom:"താഴെ",
  Top_Left:"മുകളിൽ ഇടത്",
  Top_Center:"മുകളിലെ കേന്ദ്രം",
  Top_Right:"മുകളിൽ വലത്",
  Middle_Left:"ഇടത് ഇടത്",
  Middle_Center:"മധ്യ കേന്ദ്രം",
  Middle_Right:"മിഡിൽ റൈറ്റ്",
  Bottom_Left:"താഴെ ഇടതുഭാഗത്ത്",
  Bottom_Center:"താഴെയുള്ള കേന്ദ്രം",
  Bottom_Right:"താഴെ വലത്",
  Numbers:"നമ്പറുകൾ",
  Verified:"പരിശോധിച്ചുറപ്പിച്ചു",
  Paper_Size:"പേപ്പർ വലിപ്പം",
  New_Device:"പുതിയ ഉപകരണം",
  Add_Device:"ഉപകരണം ചേർക്കുക",
  Remove_Device:"ഉപകരണം നീക്കം ചെയ്യുക",
  Delete_Device:"ഉപകരണം ഇല്ലാതാക്കുക",
  Block_Device:"ഉപകരണം തടയുക",
  Block:"തടയുക",
  Unblock:"അൺബ്ലോക്ക് ചെയ്യുക",
  Table:"മേശ",
  Scan_Login_Code:"ലോഗിൻ കോഡ് സ്കാൻ ചെയ്യുക",
  Login_Code:"ലോഗിൻ കോഡ്",
  Scan_Code:"കോഡ് സ്കാൻ ചെയ്യുക",
  Scan_QR_Code:"QR കോഡ് സ്കാൻ ചെയ്യുക",
  Select_All:"എല്ലാം തിരഞ്ഞെടുക്കുക",
  Deselect_All:"തിരഞ്ഞെടുത്ത എല്ലാം മായ്ക്കുക",
  Increase:"വർധിപ്പിക്കുക",
  Decrease:"കുറയ്ക്കുക",
  Bold:"ധീരമായ",
  Text:"വാചകം",
  Style:"ശൈലി",
  Italic:"ഇറ്റാലിക്",
  QR_Code:"QR കോഡ്",
  Barcode:"ബാർകോഡ്",
  Browse_Images:"ചിത്രങ്ങൾ ബ്രൗസ് ചെയ്യുക",
  Browse_Files:"ഫയലുകൾ ബ്രൗസ് ചെയ്യുക",
  Background_Image:"പശ്ചാത്തല ചിത്രം",
  Logo_Image:"ലോഗോ ചിത്രം",
  Header_Image:"തലക്കെട്ട് ചിത്രം",
  Bank_Image:"ബാങ്ക് ചിത്രം",
  Cut_Lines:"വരികൾ മുറിക്കുക",
  Background:"പശ്ചാത്തലം",
  License:"ലൈസൻസ്",
  One_License:"1 ലൈസൻസ്:",
  Licensed:"ലൈസൻസ് നൽകിയത്:",
  Not_Licensed:"ലൈസൻസ് ഇല്ല",
  Enter_Serial:"സീരിയൽ നൽകുക",
  Serial_Key:"സീരിയൽ കീ",
  Serial:"സീരിയൽ",
  Product_Key:"ഉല്പന്നതാക്കോൽ",
  Check_Product_Key:"ഉൽപ്പന്ന കീ പരിശോധിക്കുക",
  Add_Product_Key:"ഉൽപ്പന്ന കീ ചേർക്കുക",
  Verifying_Purchase:"വാങ്ങൽ പരിശോധിക്കുന്നു...",
  Print_Envelope:"എൻവലപ്പ് അച്ചടിക്കുക",
  Envelope:"കവര്",
  Thank_You:"നന്ദി!",
  Scale:"സ്കെയിൽ",
  Print_Scale:"പ്രിന്റ് സ്കെയിൽ",
  Borders:"അതിർത്തികൾ",
  VOID:"അസാധുവാണ്",
  Void_Cheque:"അസാധുവായ പരിശോധന",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ഓർഡറിന് പണമടയ്ക്കുക:",
  NO_DOLLARS:"ഡോളറുകളില്ല ",
  ONE_DOLLAR:"ഒരു ഡോളര്",
  DOLLARS:" ഡോളർ",
  AND:" ഒപ്പം ",
  CENTS:" സെൻറ്.",
  NO_:"ഇല്ല ",
  ONE_:"ഒന്ന് ",
  AND_NO_:"കൂടാതെ ഇല്ല ",
  _AND_ONE_:"പിന്നെ ഒന്ന് ",
  DOLLARS_AND_ONE_CENT:" ഒരു സെന്റും.",
  AND_NO_CENTS:" കൂടാതെ സീറോ സെന്റും.",
  CHEQUE_PRINT_DATE:"തീയതി:",
  CHEQUE_PRINT_MEMO:"മെമ്മോ:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"എംപി",
  Initial_Tasks:"പ്രാരംഭ ജോലികൾ",
  Inital_Setup:"പ്രാരംഭ സജ്ജീകരണം",
  Welcome_To:"സ്വാഗതം",
  Welcome:"സ്വാഗതം",
  Swipe:"സ്വൈപ്പ്",
  Intro_Setup:"ആമുഖ സജ്ജീകരണം",
  Introduction:"ആമുഖം",
  CHEQUE_PRINT_CREDIT:"ചെക്കുകളാൽ പ്രവർത്തിക്കുന്നത്",
  Title_Intro_Word:"ചെക്കുകളിലേക്ക് സ്വാഗതം",
  Title_Intro:"ചെക്കുകളുടെ ആമുഖം",
  Title_Setup:"ചെക്കുകളുടെ സജ്ജീകരണം",
  PayPal_Default_Email_Message:"നിങ്ങൾക്ക് ഒരു പുതിയ പേപാൽ ട്രാൻസ്ഫർ ലഭിച്ചു. [ചെക്കുകൾ നൽകുന്നതാണ്]",
  Cheques_App_Export:"ചെക്കുകൾ വഴി കയറ്റുമതി ചെയ്തത്",
  Post_Intro_Thanks:"ചെക്കുകൾ തിരഞ്ഞെടുത്തതിന് നന്ദി. സജ്ജീകരണ പ്രക്രിയ ഇപ്പോൾ പൂർത്തിയായി.",
  Post_Intro_Word:"നിങ്ങളുടെ ആദ്യ ചെക്ക് പ്രിന്റ് ചെയ്തുകൊണ്ട് ആരംഭിക്കുക, ഭാവി പേയ്‌മെന്റ് ചേർക്കുക അല്ലെങ്കിൽ ശമ്പളപ്പട്ടികയിൽ ഒരു ജീവനക്കാരെ ചേർക്കുക.",
  Upgrade_Required:"ഈ സന്ദേശം മറയ്‌ക്കുന്നതിനും അധിക സവിശേഷതകൾ അൺലോക്കുചെയ്യുന്നതിനും സോഫ്‌റ്റ്‌വെയറിന്റെ കൂടുതൽ പ്രീമിയം പതിപ്പ് നിങ്ങൾ സ്വന്തമാക്കണമെന്ന് ചെക്കുകൾ ആവശ്യപ്പെടുന്നു.",
  Upgrade_Title:"ചെക്കുകൾ",
  Mailout_Prompt:"നിങ്ങൾക്കായി നിങ്ങളുടെ പേറോൾ ചെക്കുകൾ ചെക്ക് മെയിൽ-ഔട്ട് ചെയ്യാൻ നിങ്ങൾക്ക് അധികമായി തിരഞ്ഞെടുക്കാം.",
  Mailed_Cheque:"മെയിൽ ചെക്ക്:",
  Mailed_Cheque_Color:"മെയിൽ ചെക്ക് (നിറം): ",
  Terms_Purchase:"ചെക്കുകളുള്ള എല്ലാ ഇലക്ട്രോണിക് വാങ്ങലുകൾക്കും വാങ്ങൽ തീയതി മുതൽ 30-ദിവസം വരെ പൂർണ്ണമായും റീഫണ്ട് ലഭിക്കും. തുടരുന്നതിന് മുമ്പ് ദയവായി <a href='#'>നിബന്ധനകളും വ്യവസ്ഥകളും</a> വായിച്ച് അംഗീകരിക്കുക.",
  Dashboard_Setup:"പ്രൈമറി പ്രിന്റർ സജ്ജീകരിക്കുക",
  Dashboard_Add:"പ്രാഥമിക ബാങ്ക് അക്കൗണ്ട് ചേർക്കുക",
  Dashboard_Customize:"ഒരു ചെക്ക് ടെംപ്ലേറ്റ് തിരഞ്ഞെടുക്കുക",
  Dashboard_Job_Salary:"നിങ്ങളുടെ ജോലി സൃഷ്ടിക്കുക, നിങ്ങളുടെ ശമ്പളം ചേർക്കുക",
  Dashboard_Employees:"ജീവനക്കാരെയും പണം നൽകുന്നവരെയും ട്രാക്ക് ചെയ്യുക",
  Dashboard_Print:"നിങ്ങളുടെ പേ ചെക്കുകൾ പ്രിന്റ് ചെയ്ത് മെയിൽ ചെയ്യുക",
  Dashboard_Payroll:"നിങ്ങളുടെ പേറോൾ പ്രിന്റിംഗ് ഓട്ടോമേറ്റ് ചെയ്യുക",
  Dashboard_PayPal:"പേപാൽ പേറോൾ / പേഔട്ടുകൾ സജ്ജീകരിക്കുക",
  Begin_Setup:"സജ്ജീകരണം ആരംഭിക്കുക",
  Get_Started:"തുടങ്ങി",
  Purchase:"വാങ്ങൽ",
  Lockdown:"അടച്ചിടൽ",
  Unlock:"അൺലോക്ക്",
  Detailed:"വിശദമായ",
  Log_In:"ലോഗിൻ",
  Login:"ലോഗിൻ",
  Launch:"ലോഞ്ച്",
  Register:"രജിസ്റ്റർ",
  Finish:"പൂർത്തിയാക്കുക",
  List:"ലിസ്റ്റ്",
  Weekends:"വാരാന്ത്യങ്ങൾ",
  Weekly:"പ്രതിവാരം",
  PayPal_Default_Subject:"പുതിയ പേയ്‌മെന്റ്",
  Payment_Message:"പേയ്മെന്റ് സന്ദേശം",
  PayPal_Default_Payment_Note:"നന്ദി",
  Setup_Your_Cheqing_Account:"അക്കൗണ്ട് പരിശോധിക്കുന്നു",
  Add_Your_Primary_Cheqing_Account:"നിങ്ങളുടെ പ്രാഥമിക ചെക്കിംഗ് അക്കൗണ്ട് ചേർക്കുക.",
  Welcome_Word:"പേയ്റോളും ഹ്യൂമൻ റിസോഴ്സ് മാനേജ്മെന്റും ലളിതമാക്കുകയും ഓട്ടോമേറ്റ് ചെയ്യുകയും ചെയ്യുക.",
  Get_Started_Quickly:"ആരംഭിക്കാൻ ഞങ്ങളെ സഹായിക്കുന്ന കുറച്ച് ലളിതമായ ചോദ്യങ്ങൾക്ക് ഉത്തരം നൽകുക...",
  Done_Intro_Word:"സജ്ജീകരണം പൂർത്തിയായി",
  PIN_Protected:"പാസ്‌വേഡും പിൻ നമ്പറും പരിരക്ഷിതം",
  Enter_New_PIN:"ഒരു പുതിയ പിൻ കോഡ് നൽകുക:",
  Enter_PIN:"പിൻ കോഡ് നൽകുക:",
  Invalid_PIN:"അസാധുവായ പിൻ നൽകി.",
  Account_Identifier:"അക്കൗണ്ട് ഐഡന്റിഫയർ",
  New_Account_Identifier:"പുതിയ അക്കൗണ്ട് ഐഡന്റിഫയർ",
  attempt:"ശ്രമം",
  attempts:"ശ്രമങ്ങൾ",
  remaining:"ബാക്കി",
  Language:"ഭാഷ",
  languages:"ഭാഷകൾ",
  select_languages:"ഭാഷ തിരഞ്ഞെടുക്കുക",
  Deposit:"നിക്ഷേപം",
  Hire_One_Now:"ഇപ്പോൾ ഒരാളെ നിയമിക്കുക",
  App_Locked:"അപ്ലിക്കേഷൻ ലോക്ക് ചെയ്തു.",
  Skip_:"ഒഴിവാക്കുക",
  Skip_to_Dashboard:"ഡാഷ്ബോർഡിലേക്ക് പോകുക",
  Dashboard:"ഡാഷ്ബോർഡ്",
  Import:"ഇറക്കുമതി",
  Admin:"അഡ്മിനിസ്‌ട്രേറ്റർമാർ",
  New_Admin:"പുതിയ അഡ്മിൻ",
  Settings:"ക്രമീകരണങ്ങൾ",
  Color_Picker:"കളർ പിക്കർ",
  Font_Picker:"ഫോണ്ട് പിക്കർ",
  Logout:"ലോഗൗട്ട്",
  Close:"അടയ്ക്കുക",
  Close_menu:"അടയ്ക്കുക",
  Excel:"എക്‌സൽ ഫയൽ",
  Csv:"CSV ഫയൽ",
  Sql:"SQL ഫയൽ",
  Json:"JSON ഫയൽ",
  Url:"URL പ്രകാരം ഇറക്കുമതി ചെയ്യുക",
  Back:"പിന്നിലേക്ക്",
  Timers:"ടൈമറുകൾ",
  Cheque:"ചെക്ക്",
  Print:"പ്രിന്റ്",
  Designs:"ഡിസൈനുകൾ",
  Pause_Printing:"അച്ചടി നിർത്തുക",
  Resume_Printing:"റെസ്യൂം പ്രിന്റിംഗ്",
  Printing_Paused:"പ്രിന്റിംഗ് താൽക്കാലികമായി നിർത്തി",
  PrintingUnavailable:"ക്ഷമിക്കണം! ഈ സിസ്റ്റത്തിൽ പ്രിന്റിംഗ് ലഭ്യമല്ല.",
  Prints_Paused:"പ്രിന്റുകൾ താൽക്കാലികമായി നിർത്തി",
  Administration:"ഭരണകൂടം",
  Loading:"ലോഡിംഗ്",
  Unnamed:"പേരിടാത്തത്",
  error:"ക്ഷമിക്കണം, ഈ ചെക്ക് കാണാനായി തുറക്കാൻ കഴിഞ്ഞില്ല.",
  No_Cheques_To_Print:"പ്രിന്റ് ചെയ്യാൻ ചെക്കുകളൊന്നുമില്ല",
  No_Cheques_To_Print_Word:"ഒരു <a routerLink='/folder/Cheque/New'>പുതിയ ചെക്ക്</a> സൃഷ്‌ടിക്കുക.",
  New_Cheque:"പുതിയ ചെക്ക്",
  Start_One_Now:"ഇപ്പോൾ ഒന്ന് തുടങ്ങൂ",
  Edit_Cheque:"എഡിറ്റ് ചെക്ക്",
  Select_Cheques:"ചെക്കുകൾ തിരഞ്ഞെടുക്കുക",
  Select_Employee:"ജീവനക്കാരനെ തിരഞ്ഞെടുക്കുക",
  Default_Printer:"ഡിഫോൾട്ട് പ്രിന്റർ",
  Reassign:"വീണ്ടും നിയോഗിക്കുക",
  Configure:"കോൺഫിഗർ ചെയ്യുക",
  Template:"ടെംപ്ലേറ്റ്",
  Designer:"ഡിസൈനർ",
  Edit_Designs:"ഡിസൈറ്റുകൾ എഡിറ്റ് ചെയ്യുക",
  New_Design:"പുതിയ ഡിസൈൻ",
  Next:"അടുത്തത്",
  Save:"രക്ഷിക്കും",
  Name:"പേര്",
  Mail:"മെയിൽ",
  Amount:"തുക",
  Date:"തീയതി",
  PayPal:"പേപാൽ",
  Payouts:"പേഔട്ടുകൾ",
  PayPal_Label:"പേപാൽ ലേബൽ",
  Email_Username:"ഇമെയിൽ / ഉപയോക്തൃനാമം",
  Client_ID:"ക്ലയന്റ് ഐഡി",
  Sandbox_Email:"സാൻഡ്ബോക്സ് ഇമെയിൽ",
  PayPal_Email:"പേപാൽ ഇമെയിൽ",
  PayPal_Username:"API ഉപയോക്തൃനാമം",
  PayPal_Payouts:"പേപാൽ പേഔട്ടുകൾ",
  Select_PayPal_Key:"പേപാൽ കീ തിരഞ്ഞെടുക്കുക",
  Secret:"രഹസ്യം",
  API_Secret:"API രഹസ്യം",
  PayPal_API_Keys:"പേപാൽ കീകൾ",
  New_PayPal_Key:"പുതിയ പേപാൽ കീ",
  Email_Subject:"ഇമെയിൽ വിഷയം",
  Email_Message:"ഇമെയിൽ സന്ദേശം",
  Payout_Options:"പണമടയ്ക്കൽ ഓപ്ഷനുകൾ",
  Payment_Note:"പേയ്മെന്റ് നോട്ട്",
  Enable_Employee:"ജീവനക്കാരനെ പ്രാപ്തമാക്കുക",
  Enable_Production_Mode:"പ്രൊഡക്ഷൻ മോഡ് പ്രവർത്തനക്ഷമമാക്കുക",
  Sandbox_Username:"സാൻഡ്ബോക്സ് ഉപയോക്തൃനാമം",
  Sandbox_Signature:"സാൻഡ്ബോക്സ് ഒപ്പ്",
  Sandbox_Password:"സാൻഡ്‌ബോക്‌സ് പാസ്‌വേഡ്",
  Production_Username:"പ്രൊഡക്ഷൻ ഉപയോക്തൃനാമം",
  Production_Signature:"പ്രൊഡക്ഷൻ സിഗ്നേച്ചർ",
  Production_Password:"പ്രൊഡക്ഷൻ പാസ്‌വേഡ്",
  Production_Email:"പ്രൊഡക്ഷൻ ഇമെയിൽ",
  API_Client_ID:"API ക്ലയന്റ് ഐഡി",
  API_Signature:"API സിഗ്നേച്ചർ",
  API_Password:"API പാസ്‌വേഡ്",
  API_Username:"API ഉപയോക്തൃനാമം",
  Secret_Key:"രഹസ്യ താക്കോൽ",
  Sandbox:"സാൻഡ്ബോക്സ്",
  Production:"ഉൽപാദനം",
  Sandbox_Keys:"സാൻഡ്ബോക്സ് കീകൾ",
  Production_Keys:"പ്രൊഡക്ഷൻ കീകൾ",
  API_Title:"API ശീർഷകം",
  Production_Mode:"പ്രൊഡക്ഷൻ മോഡ്",
  Account_Label:"അക്കൗണ്ട് ലേബൽ",
  No_PayPal_Setup:"പേപാൽ കീ ഇല്ല",
  Enable_PayPal_Account:"പേപാൽ അക്കൗണ്ട് പ്രവർത്തനക്ഷമമാക്കുക",
  No_PayPal_Word:"നിങ്ങളുടെ <a routerLink='/folder/Invoice/New'>PayPal API കീ</a> ചേർക്കുക.",
  Printed_Memo:"പ്രിന്റ് ചെയ്ത മെമ്മോ",
  Employee:"ജീവനക്കാരൻ",
  View_Employee:"ജീവനക്കാരനെ കാണുക",
  Mailing_Address:"മെയിലിംഗ് വിലാസം",
  Company_Address:"കമ്പനി മേൽവിലാസം",
  Select_Company_Address:"കമ്പനി വിലാസം തിരഞ്ഞെടുക്കുക",
  Address:"വിലാസം",
  Any_Day:"ഏതെങ്കിലും ദിവസം",
  Address_Name:"വിലാസ നാമം",
  Unit:"യൂണിറ്റ്",
  Account:"അക്കൗണ്ട്",
  Bank_Account:"ബാങ്ക് അക്കൗണ്ട്",
  Account_Limits:"അക്കൗണ്ട് പരിധികൾ പ്രവർത്തനക്ഷമമാക്കുക",
  Payroll:"ശമ്പളപട്ടിക",
  New_Payroll:"പുതിയ ശമ്പളപ്പട്ടിക",
  No_Payroll:"വരാനിരിക്കുന്ന ശമ്പളപ്പട്ടിക ഇല്ല",
  Upcoming_Holiday:"വരാനിരിക്കുന്ന അവധി:",
  Invoice_Due:"ഇൻവോയ്സ് ഡ്യൂ:",
  New_Invoice:"പുതിയ ഇൻവോയ്സ്",
  No_Invoices:"ഇൻവോയ്സുകൾ ഇല്ല",
  No_Invoices_Word:"ആദ്യത്തെ <a routerLink='/folder/Invoice/New'>ഇൻവോയ്സ്</a> സൃഷ്ടിക്കുക.",
  Cheque_Due:"ചെക്ക് ഡ്യൂ:",
  Payrolls:"ശമ്പളം",
  Sandbox_Mode:"സാൻഡ്ബോക്സ് മോഡ്",
  Hire:"വാടക",
  Pay:"പണം",
  New:"പുതിയത്",
  Add:"ചേർക്കുക",
  Make:"ഉണ്ടാക്കുക",
  Time:"സമയം",
  Write:"എഴുതുക",
  Holiday:"അവധി",
  Holidays:"അവധി ദിവസങ്ങൾ",
  Next_Holiday:"അടുത്ത അവധി:",
  All_Done:"എല്ലാം കഴിഞ്ഞു!",
  Employees:"ജീവനക്കാർ",
  Payees:"പണക്കാർ",
  Job:"ജോലി",
  Jobs:"ജോലികൾ",
  Invoice:"ഇൻവോയ്സ്",
  Invoices:"ഇൻവോയ്സുകൾ",
  Vacations:"അവധിക്കാലം",
  Cheques:"ചെക്കുകൾ",
  Brand_Cheques:"ബ്രാൻഡ്",
  Payment:"പേയ്മെന്റ്",
  Enable_Payment:"പേയ്‌മെന്റ് പ്രവർത്തനക്ഷമമാക്കുക",
  Payments:"പേയ്മെന്റുകൾ",
  Schedule:"പട്ടിക",
  Schedules:"പട്ടികകൾ",
  Timesheet:"ടൈം ഷീറ്റ്",
  Timesheets:"ടൈം ഷീറ്റുകൾ",
  Salary:"ശമ്പളം",
  New_Address:"പുതിയ വിലാസം",
  Address_2:"വിലാസം വരി 2)",
  _City:"നഗരം",
  _State:"സംസ്ഥാനം/പ്രൊവ്",
  City:"നഗരം / ടൗൺഷിപ്പ്",
  State:"സംസ്ഥാനം / പ്രവിശ്യ",
  Postal:"തപാൽ / തപാൽ കോഡ്",
  ZIP:"തപാൽ / ZIP",
  Country:"രാജ്യം",
  Addresses:"വിലാസങ്ങൾ",
  Required_Options:"ആവശ്യമായ ഓപ്ഷനുകൾ",
  Optional_Options:"ഓപ്ഷണൽ ഓപ്ഷനുകൾ",
  Additional_Options:"അധിക ഓപ്‌ഷനുകൾ",
  Required:"ആവശ്യമാണ്",
  Optional:"ഓപ്ഷണൽ",
  Additional:"അധിക",
  No_Addresses:"വിലാസങ്ങളൊന്നുമില്ല",
  New_Address_Word:"ആദ്യത്തെ <a routerLink='/folder/Address/New'>വിലാസം</a> ചേർക്കുക.",
  Select_Address:"വിലാസം തിരഞ്ഞെടുക്കുക",
  No_Address:"വിലാസങ്ങളൊന്നുമില്ല",
  Print_Cheque:"പ്രിന്റ് ചെക്ക്",
  Print_Cheque_Now:"ഇപ്പോൾ അച്ചടിക്കുക",
  Description:"വിവരണം",
  Pay_To_The_Order_Of:"ഓർഡറിന് പണം നൽകുക:",
  Select_Date_Range:"തീയതി ശ്രേണി തിരഞ്ഞെടുക്കുക",
  Select_Starting_Date:"ആരംഭ തീയതി തിരഞ്ഞെടുക്കുക",
  Select_Ending_Date:"അവസാന തീയതി തിരഞ്ഞെടുക്കുക",
  Select_Date:"തീയതി തിരഞ്ഞെടുക്കുക",
  Cheque_Date:"തീയതി പരിശോധിക്കുക",
  Cheque_Memo:"മെമ്മോ പരിശോധിക്കുക",
  Blank_Cheque:"ബ്ലാങ്ക് ചെക്ക്",
  Blank:"ശൂന്യം",
  No_Cheques:"ചെക്കുകൾ ഇല്ല",
  No_Cheques_Word:"നിങ്ങളുടെ ആദ്യ <a routerLink='/folder/Cheque/New'>ചെക്ക്</a> അച്ചടിക്കുക.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"ചിത്രം കാണുക",
  View:"കാണുക",
  Modify:"പരിഷ്ക്കരിക്കുക",
  Delete:"ഇല്ലാതാക്കുക",
  Cheque_Paid:"പണമടച്ചു",
  New_Deduction:"പുതിയ കിഴിവ്",
  Title:"ശീർഷകം",
  Frequency:"ആവൃത്തി",
  Hourly:"മണിക്കൂർ",
  Daily:"ദിവസേന",
  Weekdays:"ആഴ്ച ദിനങ്ങൾ",
  BiWeekly:"2 ആഴ്ച",
  TriWeekly:"3 ആഴ്ച",
  Monthly:"പ്രതിമാസ",
  MiMonthly:"2 മാസം",
  Quarterly:"ത്രൈമാസ",
  Yearly:"വാർഷിക",
  Every_Week:"എല്ലാ ആഴ്ചയും",
  Every_Payroll:"ഓരോ ശമ്പളപ്പട്ടികയും",
  Every_Month:"എല്ലാ മാസവും",
  Annually:"വാർഷികം",
  Always_Scheduled:"എല്ലായ്‌പ്പോഴും ഷെഡ്യൂൾ ചെയ്‌തിരിക്കുന്നു",
  Start_Date:"തുടങ്ങുന്ന ദിവസം",
  End_Date:"അവസാന ദിവസം",
  Start_Time:"ആരംഭ സമയം",
  End_Time:"അവസാന സമയം",
  Deduction_Label:"ഡിഡക്ഷൻ ലേബൽ",
  Notes:"കുറിപ്പുകൾ",
  Options:"ഓപ്ഷനുകൾ",
  Enable:"പ്രാപ്തമാക്കുക",
  Select_Deductions:"ഡിഡക്ഷൻസ് തിരഞ്ഞെടുക്കുക",
  Deductions:"ഇളവുകൾ",
  No_Deductions:"ഡിഡക്ഷൻസ് ഇല്ല",
  No_Deductions_Word:"നിങ്ങളുടെ ആദ്യ <a routerLink='/folder/Deduction/New'>ഡിഡക്ഷൻ</a> സൃഷ്ടിക്കുക.",
  New_Employee:"പുതിയ ജീവനക്കാരൻ",
  No_Title:"തലക്കെട്ട് ഇല്ല",
  _Name:"പേര്",
  About_Yourself:"നിങ്ങളെ കുറിച്ച്",
  Full_Name:"പൂർണ്ണമായ പേര്",
  Birthday:"ജന്മദിനം",
  Email:"ഇമെയിൽ",
  SMS:"എസ്എംഎസ്",
  Phone:"ഫോൺ",
  Test:"ടെസ്റ്റ്",
  Call:"വിളി",
  Fax:"ഫാക്സ്",
  Printed_Note:"അച്ചടിച്ച കുറിപ്പ്",
  Position:"സ്ഥാനം",
  Job_Position:"ഉദ്യോഗ സ്ഥാനം",
  Select_a_Job:"ഒരു ജോലി തിരഞ്ഞെടുക്കുക",
  Select_a_Salary:"ഒരു ശമ്പളം തിരഞ്ഞെടുക്കുക",
  Add_a_Deduction:"ഒരു കിഴിവ് ചേർക്കുക",
  Taxes:"നികുതികൾ",
  Add_Taxes:"നികുതി ചേർക്കുക",
  Date_of_Birth:"ജനിച്ച ദിവസം",
  Email_Address:"ഇമെയിൽ വിലാസം",
  Phone_Number:"ഫോൺ നമ്പർ",
  Phone_Call:"ഫോണ് വിളി",
  Enable_on_Payroll:"പണപ്പട്ടികയിൽ പ്രാപ്തമാക്കുക",
  Select_Employees:"ജീവനക്കാരെ തിരഞ്ഞെടുക്കുക",
  No_Employees:"ജീവനക്കാർ ഇല്ല",
  No_Employees_Word:"നിങ്ങളുടെ ആദ്യത്തെ പുതിയ <a routerLink='/folder/Employee/New'>ജീവനക്കാരനെ</a> ചേർക്കുക.",
  No_Name:"പേരില്ല",
  Unemployeed:"തൊഴിൽ രഹിതൻ",
  Employeed:"തൊഴിൽ",
  Paid:"പണമടച്ചു",
  Enabled:"പ്രാപ്തമാക്കി",
  Disabled:"വികലാംഗൻ",
  Fire:"തീ",
  Not_Available:"ലഭ്യമല്ല",
  Not_Available_Word:"നിങ്ങളുടെ ആദ്യത്തെ <a routerLink='/folder/Invoice/New'>ഇൻവോയ്സ്</a> പ്രിന്റ് ചെയ്ത് പണം നേടുക.",
  Select_Invoices:"Select_Invoices",
  Print_Invoice_Word:"നിങ്ങളുടെ ആദ്യത്തെ <a routerLink='/folder/Invoice/New'>ഇൻവോയ്സ്</a> പ്രിന്റ് ചെയ്ത് പണം നേടുക.",
  Invoice_Title:"ഇൻവോയ്സ് ശീർഷകം",
  Invoice_Date:"രസീത് തീയതി",
  Due_Date:"അവസാന തീയതി",
  New_Job:"പുതിയ ജോലി",
  Details:"വിശദാംശങ്ങൾ",
  Customize:"ഇഷ്‌ടാനുസൃതമാക്കുക",
  Customize_Dashboard:"ഡാഷ്ബോർഡ് ഇഷ്ടാനുസൃതമാക്കുക",
  Components:"ഘടകങ്ങൾ",
  No_Components:"ഘടകങ്ങൾ ഇല്ല",
  Main_Components:"പ്രധാന ഘടകങ്ങൾ",
  Smaller_Components:"ചെറിയ ഘടകങ്ങൾ",
  Error_Loading_Page:"ഈ പേജ് ലോഡ് ചെയ്യുന്നതിൽ പിശക്.",
  Bank_Details:"ബാങ്ക് വിശദാംശങ്ങൾ",
  About_Your_Job:"നിങ്ങളുടെ ജോലിയെക്കുറിച്ച്",
  Your_Schedule:"നിങ്ങളുടെ ഷെഡ്യൂൾ",
  Job_Title:"തൊഴില് പേര്",
  Job_Description:"ജോലി വിവരണം",
  Job_Details:"ജോലി വിശദാംശങ്ങൾ",
  Enable_Job:"ജോലി പ്രവർത്തനക്ഷമമാക്കുക",
  Pay_Period:"പണ കാലയളവ്",
  Perpetually_Schedule:"ശാശ്വതമായി ഷെഡ്യൂൾ ചെയ്യുക",
  Select_Jobs:"ജോലികൾ തിരഞ്ഞെടുക്കുക",
  No_Jobs:"ജോലി ഇല്ല",
  Add_Jobs:"ജോലികൾ ചേർക്കുക",
  No_Jobs_Word:"ലിസ്റ്റിലേക്ക് ആദ്യത്തെ <a routerLink='/folder/Job/New'>ജോലി</a> ചേർക്കുക.",
  Count_Employees:"job.employee_count+' ജീവനക്കാർ'",
  Zero_Employees:"0 ജീവനക്കാർ",
  New_Leave:"പുതിയ ലീവ്",
  Leave_Name:"പേര് വിടുക",
  Paid_Leave:"ശമ്പളത്തോട് കൂടിയുള്ള അവധി",
  Leave_Pay:"ലീവ് പേ",
  Indefinite_Leave:"അനിശ്ചിതകാല അവധി",
  Indefinite_Payroll:"അനിശ്ചിതകാല ശമ്പളപ്പട്ടിക",
  Leave:"വിട്ടേക്കുക",
  Add_Schedules:"ഷെഡ്യൂളുകൾ ചേർക്കുക",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"അസാന്നിദ്ധ്യം പ്രാപ്തമാക്കുക",
  Select_Leaves:"ഇലകൾ തിരഞ്ഞെടുക്കുക",
  No_Leaves:"അസാന്നിധ്യത്തിന്റെ ഇലകൾ ഇല്ല",
  Leave_Of_Absence:"അസാന്നിദ്ധ്യം വിടുക",
  Leaves_Of_Absence:"അസാന്നിധ്യത്തിന്റെ ഇലകൾ",
  New_Leave_of_Absense:"അസാന്നിദ്ധ്യത്തിന്റെ പുതിയ ലീവ്",
  No_Leaves_Word:"ആദ്യത്തെ <a routerLink='/folder/Leave/New'>ലീവ് ഓഫ് അസാന്നിദ്ധ്യം</a> ചേർക്കുക.",
  Not_Enabled:"പ്രാപ്തമാക്കിയിട്ടില്ല",
  Absences:"അസാന്നിധ്യങ്ങൾ",
  Payee:"പേയ്",
  New_Payee:"പുതിയ പണക്കാരൻ",
  Payee_Identifier:"പേയി ഐഡന്റിഫയർ",
  Payee_Name:"പണം പറ്റുന്ന ആളിന്റെ പേര്",
  Payee_Title:"പേയ്‌ക്കുള്ള പേര്",
  Payment_Memo:"പേയ്മെന്റ് മെമ്മോ",
  Select_Payees:"പണമടിക്കുന്നവരെ തിരഞ്ഞെടുക്കുക",
  No_Payees:"പണക്കാർ ഇല്ല",
  Add_Payee_Note:"ആദ്യത്തെ <a routerLink='/folder/Payee/New'>പണം വാങ്ങുന്നയാൾ</a> ചേർക്കുക.",
  New_Payees:"പുതിയ പണം വാങ്ങുന്നവർ",
  About_The_Payment_Schedule:"തുക അടക്കേണ്ട തിയതികൾ",
  Your_Payroll_Schedule:"ഓട്ടോമാറ്റിക് പേറോൾ",
  New_Payment:"പുതിയ പേയ്‌മെന്റ്",
  Identifier:"ഐഡന്റിഫയർ",
  Select:"തിരഞ്ഞെടുക്കുക",
  Memo:"മെമ്മോ",
  Payment_Date:"പേയ്മെന്റ് തീയതി",
  Mark_as_Paid:"പണമടച്ചതായി അടയാളപ്പെടുത്തുക",
  Select_Payments:"പേയ്മെന്റുകൾ തിരഞ്ഞെടുക്കുക",
  No_Payments:"പേയ്മെന്റുകൾ ഇല്ല",
  No_Payments_Word:"ആദ്യത്തെ <a routerLink='/folder/Payment/New'>പേയ്‌മെന്റ്</a> സൃഷ്‌ടിക്കുക.",
  Create_Payroll:"പണപ്പട്ടിക സൃഷ്ടിക്കുക",
  Properties:"സ്വത്തുക്കൾ",
  Payroll_Title:"ശമ്പള ശീർഷകം",
  Payroll_Notes:"ശമ്പള കുറിപ്പുകൾ",
  Payroll_Setup:"ശമ്പള സജ്ജീകരണം",
  Tabulate_Payments:"പട്ടിക പേയ്‌മെന്റുകൾ",
  Tabulate:"പട്ടിക",
  By:"വഴി:",
  Payments_By:"പേയ്‌മെന്റുകൾ വഴി",
  Timesheets_And_Schedules:"ടൈം ഷീറ്റുകളും ഷെഡ്യൂളുകളും",
  Both:"രണ്ടും",
  Items:"ഇനങ്ങൾ",
  Add_Payees:"പണമടിക്കുന്നവരെ ചേർക്കുക",
  Add_Account:"അക്കൗണ്ട് ചേർക്കുക",
  Enable_Account:"അക്കൗണ്ട് പ്രവർത്തനക്ഷമമാക്കുക",
  Enable_Payroll:"പണയെടുപ്പ് പ്രവർത്തനക്ഷമമാക്കുക",
  Print_Payroll:"പ്രിന്റ് പേറോൾ",
  No_Payrolls:"ശമ്പളം ഇല്ല",
  No_Payroll_Word:"നിങ്ങളുടെ ആദ്യ <a routerLink='/folder/Payroll/New'>പേയ്റോൾ</a> സൃഷ്ടിക്കുക.",
  View_more:"കൂടുതൽ കാണു",
  Less:"കുറവ്",
  Add_Payroll:"പണപ്പട്ടിക ചേർക്കുക",
  Select_Payroll:"പണപ്പട്ടിക തിരഞ്ഞെടുക്കുക",
  About_Your_Salary:"നിങ്ങളുടെ ശമ്പളത്തെക്കുറിച്ച്",
  Add_Salaries:"ശമ്പളം ചേർക്കുക",
  Add_Salary:"ശമ്പളം ചേർക്കുക",
  Salaries:"ശമ്പളം",
  No_Salaries:"ശമ്പളം ഇല്ല",
  No_Salaries_Word:"ആദ്യത്തെ <a routerLink='/folder/Salary/New'>ശമ്പളം</a> ചേർക്കുക.",
  Select_Salaries:"ശമ്പളം തിരഞ്ഞെടുക്കുക",
  New_Salary:"പുതിയ ശമ്പളം",
  Salary_Name:"ശമ്പള ഐഡന്റിഫയർ",
  Enable_Salary:"ശമ്പളം പ്രാപ്തമാക്കുക",
  Salary_Amount:"ശമ്പള തുക",
  New_Schedule:"പുതിയ ഷെഡ്യൂൾ",
  Schedule_Title:"ഷെഡ്യൂൾ ശീർഷകം",
  Add_Address:"വിലാസം ചേർക്കുക",
  Repeat:"ആവർത്തിച്ച്",
  Design:"ഡിസൈൻ",
  Edit_Design:"ഡിസൈൻ എഡിറ്റ് ചെയ്യുക",
  Edit_this_Design:"ഈ ഡിസൈൻ എഡിറ്റ് ചെയ്യുക",
  Repeat_Payment:"ആവർത്തിച്ചുള്ള പേയ്‌മെന്റ്",
  Enable_Schedule:"ഷെഡ്യൂൾ പ്രവർത്തനക്ഷമമാക്കുക",
  Never:"ഒരിക്കലും",
  Select_Schedule:"ഷെഡ്യൂളുകൾ തിരഞ്ഞെടുക്കുക",
  No_Schedules:"ഷെഡ്യൂളുകൾ ഇല്ല",
  No_Schedules_Word:"ആദ്യത്തെ <a routerLink='/folder/Schedule/New'>Schedule</a> സൃഷ്‌ടിക്കുക.",
  New_Administrator:"പുതിയ അഡ്മിനിസ്ട്രേറ്റർ",
  Username:"ഉപയോക്തൃനാമം",
  First_Name:"പേരിന്റെ ആദ്യഭാഗം",
  Last_Name:"പേരിന്റെ അവസാന ഭാഗം",
  Add_an_Address:"ഒരു വിലാസം ചേർക്കുക",
  Payment_Limit:"ഓരോ പേയ്‌മെന്റ് പരിധി",
  Total_Limit:"മൊത്തം പരിധി",
  Select_Accounts:"അക്കൗണ്ടുകൾ തിരഞ്ഞെടുക്കുക",
  No_Administrators:"അഡ്മിനിസ്‌ട്രേറ്റർമാർ ഇല്ല",
  No_Administrators_Word:"ആദ്യത്തെ <a routerLink='/folder/Administrator/New'>അഡ്മിൻ അക്കൗണ്ട്</a> സൃഷ്‌ടിക്കുക.",
  New_Administrators_Word:"ആദ്യത്തെ <a routerLink='/folder/Administrator/New'>കാര്യനിർവാഹകൻ</a> ചേർക്കുക",
  Cloud:"മേഘം",
  Backup:"ബാക്കപ്പ്",
  Enable_iCloud:"iCloud പ്രവർത്തനക്ഷമമാക്കുക",
  Enable_Google_Drive:"Google ഡ്രൈവ് പ്രവർത്തനക്ഷമമാക്കുക",
  Enable_Microsoft_OneDrive:"Microsoft OneDrive പ്രവർത്തനക്ഷമമാക്കുക",
  Automatically_Backup:"യാന്ത്രികമായി ബാക്കപ്പ്",
  FTP_Settings:"FTP ക്രമീകരണങ്ങൾ",
  URL_File_Prompt:"ഇമ്പോർട്ടുചെയ്യാൻ ഒരു .xls / .xlsx / .json ഫയലിന്റെ സ്ഥാനം വ്യക്തമാക്കുക:",
  URL_SQL_Prompt:"ഇമ്പോർട്ടുചെയ്യാൻ SQLite ഫയലിന്റെ സ്ഥാനം വ്യക്തമാക്കുക:",
  FTP_Backup:"FTP ബാക്കപ്പ്",
  FTP_Import:"FTP ഇറക്കുമതി",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"ഹോസ്റ്റ്",
  Port:"തുറമുഖം",
  Path:"പാത",
  Data_Path:"ഡാറ്റ പാത്ത്",
  Enable_FTP_Account:"FTP അക്കൗണ്ട് പ്രവർത്തനക്ഷമമാക്കുക",
  HostnameIP:"ഹോസ്റ്റ് നാമം",
  Password:"Password",
  Connection_Port:"കണക്ഷൻ പോർട്ട്",
  Enable_MySQL_Database:"MySQL ഡാറ്റാബേസ് പ്രവർത്തനക്ഷമമാക്കുക",
  Log:"ലോഗ്",
  Reset:"പുനഃസജ്ജമാക്കുക",
  Erase:"മായ്ക്കുക",
  Export:"കയറ്റുമതി",
  Database:"ഡാറ്റാബേസ്",
  Upload_CSV:"CSV അപ്‌ലോഡ് ചെയ്യുക",
  Download_CSV:"CSV ഡൗൺലോഡ് ചെയ്യുക",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL ബാക്കപ്പ്",
  Internal_Notes:"ആന്തരിക കുറിപ്പുകൾ",
  Root_Path:"റൂട്ട് പാത്ത്",
  Select_Backup:"ബാക്കപ്പ് തിരഞ്ഞെടുക്കുക",
  Add_New_Backup:"ഒരു പുതിയ ബാക്കപ്പ് ചേർക്കുക",
  First_Backup:"ആദ്യത്തെ ബാക്കപ്പ് സജ്ജീകരിക്കുക...",
  Backups:"ബാക്കപ്പുകൾ",
  Add_Backup:"ബാക്കപ്പ് ചേർക്കുക",
  No_Backups:"ബാക്കപ്പുകളൊന്നും കണ്ടെത്താനില്ല.",
  Select_Backup_Type:"നിങ്ങൾ സജ്ജീകരിക്കാൻ ആഗ്രഹിക്കുന്ന ബാക്കപ്പ് തരം തിരഞ്ഞെടുക്കുക...",
  Backup_Type:"ബാക്കപ്പ് തരം",
  FTP_Drive:"FTP ഡ്രൈവ്",
  URL_Location:"URL",
  Apple_iCloud:"ഐക്ലൗഡ്",
  Google_Drive:"ഡ്രൈവ്",
  Microsoft_OneDrive:"ഡ്രൈവ്",
  Import_Fields:"ഇറക്കുമതി ഫീൽഡുകൾ",
  Import_Fields_Word:" <a routerLink='/folder/Holiday/New'>ഇറക്കുമതി</a> ഡാറ്റ തിരഞ്ഞെടുക്കാൻ ഈ വിഭാഗം ഉപയോഗിക്കുക.",
  Upload:"അപ്‌ലോഡ്",
  Download:"ഡൗൺലോഡ്",
  Download_JSON:"JSON ഡാറ്റയായി ഡൗൺലോഡ് ചെയ്യുക",
  Download_SQL:"SQL ഫയലായി ഡൗൺലോഡ് ചെയ്യുക",
  New_Bank:"പുതിയ ബാങ്ക്",
  New_Account:"പുതിയ അക്കൗണ്ട്",
  New_Bank_Account:"പുതിയ ബാങ്ക് അക്കൗണ്ട്",
  Upload_Image:"ചിത്രം അപ്ലോഡ് ചെയ്യുക",
  Bank_Name:"ബാങ്കിന്റെ പേര്",
  Bank_Address:"ബാങ്ക് വിലാസം",
  Branch_Address:"ശാഖ വിലാസം",
  Address_on_Cheque:"വിലാസം",
  Cheque_Numbers:"നമ്പറുകൾ പരിശോധിക്കുക",
  Cheque_Details:"വിശദാംശങ്ങൾ പരിശോധിക്കുക",
  Bank_Logo:"ബാങ്ക് ലോഗോ",
  Cheque_ID:"ഐഡി പരിശോധിക്കുക",
  Starting_Cheque_ID:"ചെക്ക് ഐഡി ആരംഭിക്കുന്നു",
  Transit_Number:"ട്രാൻസിറ്റ് നമ്പർ",
  Institution_Number:"സ്ഥാപന നമ്പർ",
  Designation_Number:"പദവി നമ്പർ",
  Account_Number:"അക്കൗണ്ട് നമ്പർ",
  Limits:"പരിധികൾ",
  Default_Limits:"സ്ഥിര പരിധികൾ",
  Over_Limit:"പരിധിക്കപ്പുറം",
  Under_Limit:"പരിധിക്ക് കീഴിൽ",
  Payroll_Limit:"ശമ്പള പരിധി",
  Enable_Bank_Account:"ബാങ്ക് അക്കൗണ്ട് പ്രവർത്തനക്ഷമമാക്കുക",
  Select_Account:"അക്കൗണ്ട് തിരഞ്ഞെടുക്കുക",
  No_Bank_Account:"ബാങ്ക് അക്കൗണ്ട് ഇല്ല",
  No_Bank_Account_Word:"ആദ്യത്തെ <a routerLink='/folder/Accounts/New'>ബാങ്ക് അക്കൗണ്ട്</a> ചേർക്കുക.",
  Bank_Accounts:"ബാങ്ക് അക്കൗണ്ടുകൾ",
  No_Accounts:"അക്കൗണ്ടുകൾ ഇല്ല",
  No_Accounts_Note:"ആദ്യത്തെ <a routerLink='/folder/Accounts/New'>ബാങ്ക് അക്കൗണ്ട്</a> ചേർക്കുക.",
  Cheque_Designer:"ഡിസൈനർ പരിശോധിക്കുക",
  Cheque_Design:"ചെക്ക് ഡിസൈൻ",
  Select_Design:"ഒരു ഡിസൈൻ തിരഞ്ഞെടുക്കുക",
  Cheque_Designs:"ഡിസൈനുകൾ പരിശോധിക്കുക",
  No_Cheque_Designs:"ചെക്ക് ഡിസൈനുകൾ ഇല്ല",
  No_Cheque_Designs_Word:"നിങ്ങളുടെ സ്വന്തം <a routerLink='/folder/Settings/Cheque/Design/New'>ചെക്ക് ഡിസൈൻ</a> സൃഷ്ടിക്കുക.",
  Set_Default:"സ്ഥിരസ്ഥിതിയായി സജ്ജമാക്കാൻ",
  Default:"സ്ഥിരസ്ഥിതി",
  Remove:"നീക്കം ചെയ്യുക",
  Folder:"ഫോൾഡർ",
  Edit:"എഡിറ്റ്",
  LoadingDots:"ലോഡിംഗ്...",
  Add_a_New_File:"ഒരു <a href='#' (click)='add()'>പുതിയ ഫയൽ</a> ചേർക്കുക",
  this_folder:"ഈ ഫോൾഡർ",
  FTP_Backup_Settings:"FTP ബാക്കപ്പ് ക്രമീകരണങ്ങൾ",
  Secure_File_Transfer:"സുരക്ഷിത ഫയൽ കൈമാറ്റം",
  New_Holiday:"പുതിയ അവധി",
  Add_Holiday:"അവധിക്കാലം ചേർക്കുക",
  Holiday_Name:"അവധിക്കാല നാമം",
  Additional_Pay:"അധിക കൂലി",
  Enable_Holiday:"അവധി പ്രവർത്തനക്ഷമമാക്കുക",
  Select_Holidays:"അവധിദിനങ്ങൾ തിരഞ്ഞെടുക്കുക",
  No_Holidays:"അവധിദിനങ്ങൾ ഇല്ല",
  No_Holidays_Word:"ആദ്യത്തെ <a routerLink='/folder/Holiday/New'>പൊതു അവധി</a> ചേർക്കുക.",
  New_Import:"പുതിയ ഇറക്കുമതി",
  Import_Data:"ഡാറ്റ ഇറക്കുമതി ചെയ്യുക",
  Import_Data_Word:"നിങ്ങൾ തിരഞ്ഞെടുക്കുന്ന ഫയലിന്റെ തരം അല്ലെങ്കിൽ അപ്‌ലോഡ് രീതി തിരഞ്ഞെടുക്കുക.<br /> പിന്തുണയ്‌ക്കുന്ന ഫയൽ അപ്‌ലോഡ് ചെയ്‌തതിന് ശേഷം ആപ്പിലെ ഏത് പാരാമീറ്ററുമായി പൊരുത്തപ്പെടുന്ന ഒരു ഫയലിലെ ഇറക്കുമതി ചെയ്‌ത ഫീൽഡുകൾ നിങ്ങൾക്ക് തിരഞ്ഞെടുക്കാനാകും.", 
  Import_File:"ഫയൽ ഇറക്കുമതി ചെയ്യുക",
  Link_To_File:"ഫയലിലേക്കുള്ള ലിങ്ക്",
  Select_File:"ഫയൽ തിരഞ്ഞെടുക്കുക",
  New_Moderator:"പുതിയ മോഡറേറ്റർ",
  Allow_Moderation:"മോഡറേഷൻ അനുവദിക്കുക",
  Create_Paycheques:"പണ ചെക്കുകൾ സൃഷ്ടിക്കുക",
  Edit_Paycheques_and_Data:"പണ ചെക്കുകളും ഡാറ്റയും എഡിറ്റ് ചെയ്യുക",
  Destroy_Data_and_Paycheques:"ഡാറ്റയും പേ ചെക്കുകളും നശിപ്പിക്കുക",
  Bonus_Percentage:"ശമ്പള ചെക്ക് ശതമാനം",
  Fixed_Amount:"നിശ്ചിത തുക",
  Select_Moderator:"മോഡറേറ്ററെ തിരഞ്ഞെടുക്കുക",
  No_Moderators:"മോഡറേറ്റർമാർ ഇല്ല",
  Moderators:"മോഡറേറ്റർമാർ",
  Moderator_Account:"ആദ്യത്തെ <a routerLink='/folder/Administrator/New'>മോഡറേറ്റർ അക്കൗണ്ട്</a> സൃഷ്‌ടിക്കുക.",
  No_Moderators_Word:"ആദ്യത്തെ <a routerLink='/folder/Administrator/New'>മോഡറേറ്റർ</a> ചേർക്കുക.",
  Defaults:"ഡിഫോൾട്ടുകൾ",
  Provide_Us_Info:"ഞങ്ങൾക്ക് വിവരം നൽകുക",
  Setup_Your_Printer:"നിങ്ങളുടെ പ്രിന്റർ സജ്ജീകരിക്കുക",
  Your_Company:"നിങ്ങളുടെ കമ്പനിയെക്കുറിച്ച്",
  Company_Name:"കമ്പനി പേര്",
  Currency:"കറൻസി",
  Default_Currency:"സ്ഥിര കറൻസി",
  Base_Monthly_Income:"പ്രതിമാസ വരുമാനം",
  Protection:"സംരക്ഷണം",
  App_Protection:"ആപ്പ് സംരക്ഷണം",
  PIN_Code_Protection:"പിൻ കോഡ് സംരക്ഷണം",
  App_Protection_Word:"നിങ്ങളുടെ അക്കൗണ്ട് പരിരക്ഷിക്കാൻ സഹായിക്കുന്ന സുരക്ഷാ രീതികൾ പ്രവർത്തനക്ഷമമാക്കുക.",
  PIN_Code:"പിൻ കോഡ്",
  Change_PIN:"പിൻ മാറ്റുക",
  New_Password:"പുതിയ പാസ്വേഡ്",
  Geofence_Protection:"ജിയോ-വേലി സംരക്ഷണം",
  Geofence_Area:"സെറ്റ് ഏരിയ",
  Distance:"ദൂരം",
  Setup_Now:"ഇപ്പോൾ സജ്ജീകരിക്കുക",
  Mile:"നാഴിക",
  Km:"കിമി",
  m:"m",
  Facial_Recognition:"മുഖം തിരിച്ചറിയൽ",
  Face:"മുഖം",
  Setup:"സജ്ജമാക്കുക",
  Label:"ലേബൽ",
  Password_Protection:"പാസ്‌വേഡ് സംരക്ഷണം",
  Modify_Password:"പാസ്‌വേഡ് പരിഷ്‌ക്കരിക്കുക",
  New_Tax_Entry:"പുതിയ നികുതി എൻട്രി",
  New_Tax:"പുതിയ നികുതി",
  Tax_Label:"നികുതി ലേബൽ",
  Perpetually_Enabled:"ശാശ്വതമായി പ്രവർത്തനക്ഷമമാക്കി",
  Select_Taxes:"നികുതികൾ തിരഞ്ഞെടുക്കുക",
  Tax_Deductions:"നികുതി ഇളവുകൾ",
  No_Tax_Deductions:"നികുതി ഇളവുകൾ ഇല്ല",
  No_Tax_Deductions_Word:"ആദ്യത്തെ <a routerLink='/folder/Tax/New'>നികുതി</a> കിഴിവ് ചേർക്കുക.",
  New_Timer:"പുതിയ ടൈമർ",
  Start:"ആരംഭിക്കുക",
  Stop:"നിർത്തുക",
  Start_Timer:"ടൈമർ ആരംഭിക്കുക",
  Stop_Timer:"സ്റ്റോപ്പ് ടൈമർ",
  Timer_Active:"ടൈമർ ആക്റ്റീവ്",
  Timer:"ടൈമർ:",
  Timer_Running:"ടൈമർ: (റണ്ണിംഗ്)",
  Save_Timer:"ടൈമർ സംരക്ഷിക്കുക",
  New_Timesheet:"പുതിയ ടൈം ഷീറ്റ്",
  Select_Timesheets:"ടൈം ഷീറ്റുകൾ തിരഞ്ഞെടുക്കുക",
  Work_Notes:"വർക്ക് നോട്ടുകൾ",
  Entry_Title:"എൻട്രി ടൈറ്റിൽ",
  No_Timesheets:"ടൈം ഷീറ്റുകൾ ഇല്ല",
  No_Timesheets_Word:"ആദ്യത്തെ <a routerLink='/folder/Timesheet/New'>ടൈം ഷീറ്റ്</a> ചേർക്കുക.",
  Timesheet_Submitted:"ടൈം ഷീറ്റ് സമർപ്പിച്ചു",
  Timesheet_Congrats:"അഭിനന്ദനങ്ങൾ! നിങ്ങളുടെ ടൈം ഷീറ്റ് വിജയകരമായി സമർപ്പിച്ചു. നന്ദി!",
  Timesheet_Copy:"നിങ്ങളുടെ പ്രമാണങ്ങളുടെ ഒരു പകർപ്പ് ലഭിക്കുന്നതിന് ദയവായി നിങ്ങളുടെ ഇമെയിൽ വിലാസവും കൂടാതെ/അല്ലെങ്കിൽ മൊബൈൽ ഫോൺ നമ്പറും ഞങ്ങൾക്ക് നൽകുക.",
  Submit:"സമർപ്പിക്കുക",
  Allow_Notifications:"അറിയിപ്പുകൾ അനുവദിക്കുക",
  Untitled_Entry:"പുതിയ എൻട്രി",
  Untitled_Bank:"പേരില്ലാത്ത ബാങ്ക്",
  Timesheet_Entry:"ടൈം ഷീറ്റ് എൻട്രി",
  Work_Description:"ജോലി വിവരണം",
  Enable_Timesheet:"ടൈം ഷീറ്റ് പ്രവർത്തനക്ഷമമാക്കുക",
  Submit_Timesheet:"ടൈം ഷീറ്റ് സമർപ്പിക്കുക",
  Vacation:"അവധിക്കാലം",
  New_Vacation:"പുതിയ അവധിക്കാലം",
  Vacation_Name:"അവധിക്കാല നാമം",
  Paid_Vacation:"പണമടച്ചുള്ള അവധി",
  Vacation_Pay:"അവധി വേതനം",
  Enable_Vacation:"അവധി പ്രവർത്തനക്ഷമമാക്കുക",
  Select_Vacations:"അവധിക്കാലങ്ങൾ തിരഞ്ഞെടുക്കുക",
  No_Vacations:"അവധിയില്ല",
  No_Vacations_Word:"ആദ്യത്തെ <a routerLink='/folder/Vacation/New'>അവധിക്കാലം</a> എൻട്രി സൃഷ്‌ടിക്കുക.",
  Payroll_Schedule:"പേയ്റോൾ ഷെഡ്യൂൾ",
  Next_Payroll:"അടുത്ത ശമ്പളപ്പട്ടിക:",
  Upcoming_Payroll:"വരാനിരിക്കുന്ന ശമ്പളപ്പട്ടിക",
  No_Upcoming_Payroll:"വരാനിരിക്കുന്ന ശമ്പളപ്പട്ടിക ഇല്ല",
  Address_Book:"മേൽവിലാസ പുസ്തകം",
  Archived_Documents:"ആർക്കൈവ് ചെയ്ത പ്രമാണങ്ങൾ",
  Dev_Mode:"ആപ്ലിക്കേഷൻ ഡെവലപ്‌മെന്റ് മോഡിൽ",
  Administrators:"അഡ്മിനിസ്‌ട്രേറ്റർമാർ",
  Privacy:"സ്വകാര്യത",
  None:"ഒന്നുമില്ല",
  Select_Signature:"ഒപ്പ് തിരഞ്ഞെടുക്കുക",
  No_Signatures:"ഒപ്പ് ഇല്ല",
  No_Signatures_Word:"ആദ്യത്തെ <a routerLink='/folder/Signature/New'>കയ്യൊപ്പ്</a> ചേർക്കുക.",
  Repeat_Indefinitely:"അനിശ്ചിതമായി ആവർത്തിക്കുക",
  Sign:"അടയാളം",
  Sign_Here:"ഇവിടെ ഒപ്പിടുക",
  Date_Signed:"ഒപ്പിട്ട തീയതി",
  Signature_Pad:"സിഗ്നേച്ചർ പാഡ്",
  Account_Holder:"അക്കൗണ്ടിന്റെ ഉടമസ്ഥന്",
  Account_Properties:"അക്കൗണ്ട് പ്രോപ്പർട്ടികൾ",
  Name_On_Cheque:"ചെക്കിലെ പേര്",
  Server_Hostname:"സെർവർ ഹോസ്റ്റ്നാമം / IP",
  Printers:"പ്രിൻററുകൾ",
  No_Printers:"പ്രിൻററുകൾ ഇല്ല",
  Printer_Exists:'ഈ പേരിൽ ഒരു പ്രിന്റർ ഇതിനകം നിലവിലുണ്ട്.',
  No_Printers_Word:"ആദ്യത്തെ <a routerLink='/folder/Printer/New'>പ്രിന്റർ</a> ചേർക്കുക.",
  No_Printer_Alert:"പ്രിൻററൊന്നും നിർവചിച്ചിട്ടില്ല. നിങ്ങൾക്ക് ഒരു പ്രിന്റർ സജ്ജീകരിക്കാൻ താൽപ്പര്യമുണ്ടോ?",
  Add_Printer:"പ്രിൻറർ ചേർക്കുക",
  New_Printer:"പുതിയ പ്രിന്റർ",
  Printer_Hostname:"പ്രിൻറർ ഹോസ്റ്റ്നാമം / IP",
  Printer_Label:"പ്രിൻറർ ലേബൽ",
  Printer_Protocol:"പ്രിൻറർ പ്രോട്ടോക്കോൾ",
  Protocol:"പ്രോട്ടോക്കോൾ",
  Email_Print:"ഇമെയിൽ",
  AirPrint:"എയർപ്രിന്റ്",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"സോക്കറ്റ്",
  Print_Job:"പ്രിന്റ് ജോബ്",
  Printed:"അച്ചടി",
  Not_Printed:"അച്ചടിക്കാത്തത്",
  Print_Jobs:"പ്രിന്റ് ജോലികൾ",
  Print_Queue:"പ്രിന്റ് ക്യൂ",
  No_Print_Jobs:"അച്ചടി ജോലികൾ ഇല്ല",
  No_Prints:"പ്രിന്റ് ചെയ്യാൻ ഒരു പുതിയ <a routerLink='/folder/Cheque/New'>ചെക്ക്</a> സൃഷ്‌ടിക്കുക.",
  Prints:"പ്രിന്റുകൾ",
  Find_Printer:"പ്രിൻറർ കണ്ടെത്തുക",
  Find_AirPrint_Devices:"എയർപ്രിന്റ് ഉപകരണങ്ങൾ കണ്ടെത്തുക",
  Select_Printer:"പ്രിൻറർ തിരഞ്ഞെടുക്കുക",
  System_UI:"സിസ്റ്റം യുഐ",
  Printer:"പ്രിന്റർ",
  Status:"പദവി",
  Preview:"പ്രിവ്യൂ",
  Enable_Print_Job:"പ്രിന്റ് ജോലി പ്രവർത്തനക്ഷമമാക്കുക",
  Queue_Weight:"ക്യൂ വെയ്റ്റ്",
  Unlimited:"അൺലിമിറ്റഡ്",
  Show_Advanced_Printer_Options:"വിപുലമായ പ്രിന്റർ ഓപ്ഷനുകൾ കാണിക്കുക",
  Advanced:"വിപുലമായ",
  Location:"സ്ഥാനം",
  Note:"കുറിപ്പ്",
  Queue_Name:"ക്യൂവിന്റെ പേര്",
  Pages_Printed_Limit:"പേജുകൾ അച്ചടിച്ച പരിധി",
  MultiPage_Idle_Time:"മൾട്ടിപേജ് കാത്തിരിപ്പ് സമയം (കൾ)",
  Page_Count_Limit:"പേജ് എണ്ണത്തിന്റെ പരിധി",
  Page_Orientation:"പേജ് ഓറിയന്റേഷൻ",
  Portrait:"ഛായാചിത്രം",
  Landscape:"ലാൻഡ്സ്കേപ്പ്",
  Duplex:"ഡ്യൂപ്ലെക്സ്",
  Double_Sided_Printing:"രണ്ടു വശമുള്ള",
  Duplex_Mode:"ഡ്യുപ്ലെക്സ് മോഡ്",
  Duplex_Short:"ഹ്രസ്വ",
  Duplex_Long:"നീളമുള്ള",
  Duplex_None:"ഒന്നുമില്ല",
  Color_And_Quality:"നിറവും ഗുണനിലവാരവും",
  Monochrome:"മോണോക്രോം",
  Photo_Quality_Prints:"ഫോട്ടോ ക്വാളിറ്റി പ്രിന്റുകൾ",
  Printer_Email:"പ്രിൻറർ ഇമെയിൽ",
  Automatically_Downsize:"യാന്ത്രികമായി കുറയ്ക്കുക",
  Paper:"പേപ്പർ",
  Paper_Name:"പേപ്പറിന്റെ പേര്",
  Paper_Width:"പേപ്പർ വീതി",
  Paper_Height:"പേപ്പർ ഉയരം",
  Paper_Autocut_Length:"പേപ്പർ ഓട്ടോ-കട്ട് ദൈർഘ്യം",
  Margins:"മാർജിനുകൾ",
  Page_Margins:"പേജ് മാർജിനുകൾ",
  Page_Margin_Top:"ടോപ്പ് പേജ് മാർജിൻ",
  Page_Margin_Right:"വലത് പേജ് മാർജിൻ",
  Page_Margin_Bottom:"താഴെ പേജ് മാർജിൻ",
  Page_Margin_Left:"ഇടത് പേജ് മാർജിൻ",
  Add_Employees:"ജീവനക്കാരെ ചേർക്കുക",
  Header:"തലക്കെട്ട്",
  Print_A_Page_Header:"ഒരു പേജ് തലക്കെട്ട് അച്ചടിക്കുക",
  Header_Label:"ഹെഡർ ലേബൽ",
  Header_Page_Index:"തലക്കെട്ട് പേജ് സൂചിക",
  Header_Font_Name:"ഹെഡർ ഫോണ്ട്",
  Select_Font:"ഫോണ്ട് തിരഞ്ഞെടുക്കുക",
  Font_Selector:"ഫോണ്ട് സെലക്ടർ",
  Header_Font_Size:"ഹെഡർ ഫോണ്ട്",
  Header_Bold:"തലക്കെട്ട് ബോൾഡ്",
  Header_Italic:"തല ഇറ്റാലിക്",
  Header_Alignment:"ഹെഡർ അലൈൻമെന്റ്",
  Left:"ഇടത്തെ",
  Center:"കേന്ദ്രം",
  Right:"ശരി",
  Justified:"ന്യായീകരിക്കപ്പെട്ടു",
  Header_Font_Color:"തലക്കെട്ട് നിറം",
  Select_Color:"നിറം തിരഞ്ഞെടുക്കുക",
  Footer:"അടിക്കുറിപ്പ്",
  Print_A_Page_Footer:"ഒരു പേജ് അടിക്കുറിപ്പ് അച്ചടിക്കുക",
  Footer_Label:"അടിക്കുറിപ്പ് ലേബൽ",
  Footer_Page_Index:"അടിക്കുറിപ്പ് പേജ് സൂചിക",
  Footer_Font_Name:"ഫൂട്ടർ ഫോണ്ട്",
  Fonts:"ഫോണ്ടുകൾ",
  Done:"പൂർത്തിയായി",
  Select_Fonts:"ഫോണ്ടുകൾ തിരഞ്ഞെടുക്കുക",
  Footer_Font_Size:"ഫൂട്ടർ സൈസ്",
  Footer_Bold:"ഫൂട്ടർ ബോൾഡ്",
  Footer_Italic:"ഇറ്റാലിക് അടിക്കുറിപ്പ്",
  Footer_Alignment:"അടിവിന്യാസം",
  Footer_Font_Color:"അടിവരണം",
  Page_Copies:"പേജ് പകർപ്പുകൾ",
  Enable_Printer:"പ്രിൻറർ പ്രവർത്തനക്ഷമമാക്കുക",
  Remote_Logging:"റിമോട്ട് ലോഗിംഗ്",
  Log_Server:"ലോഗ് സെർവർ",
  Encryption:"എൻക്രിപ്ഷൻ",
  Random_Key:"റാൻഡം കീ",
  Encryption_Key:"എൻക്രിപ്ഷൻ കീ",
  Cheques_Webserver:"ഇഷ്‌ടാനുസൃത ഡാറ്റാബേസ്",
  Learn_How:"എങ്ങനെയെന്ന് പഠിക്കുക",
  Signature:"കയ്യൊപ്പ്",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"ഒപ്പ് കാണുക",
  Printed_Signature:"പ്രിന്റ് ചെയ്ത ഒപ്പ്",
  Digitally_Sign:"ഡിജിറ്റലി സൈൻ",
  Digital_Signature:"ഡിജിറ്റൽ ഒപ്പ്",
  Add_Signature:"ഒപ്പ് ചേർക്കുക",
  Add_Your_Signature:"നിങ്ങളുടെ ഒപ്പ് ചേർക്കുക",
  Enable_Signature:"ഒപ്പ് പ്രവർത്തനക്ഷമമാക്കുക",
  Digitally_Signed:"ഡിജിറ്റലായി ഒപ്പിട്ടത്",
  Insert_Error:"ഡാറ്റാബേസ് പ്രശ്നങ്ങൾ കാരണം ചെക്ക് സംരക്ഷിക്കാനായില്ല.",
  Delete_Confirmation:"ഈ വിവരങ്ങൾ ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  Discard_Confirmation:"ഈ വിവരങ്ങൾ ഉപേക്ഷിക്കണമെന്ന് തീർച്ചയാണോ?",
  Discard_Bank_Confirmation:"ഈ അക്കൗണ്ട് ഉപേക്ഷിക്കണമെന്ന് തീർച്ചയാണോ?",
  Discard_Printer_Confirmation:"ഈ പ്രിന്റർ ഉപേക്ഷിക്കണമെന്ന് തീർച്ചയാണോ?",
  Delete_Bonus_Confirmation:"ഈ ബോണസ് ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  Delete_Invoice_Confirmation:"ഈ ഇൻവോയ്സ് ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  Generated_Cheque:"ജനറേറ്റഡ് ചെക്ക്",
  Generated_Invoice:"ജനറേറ്റ് ചെയ്ത ഇൻവോയ്സ്",
  Schedule_Start_Time:"ഷെഡ്യൂൾ ആരംഭം",
  Schedule_End_Time:"ഷെഡ്യൂൾ അവസാനം",
  New_Call:"പുതിയ കോൾ",
  No_Contacts:"ബന്ധങ്ങൾ ഇല്ല",
  No_Contacts_Word:"അഡ്‌മിനിസ്‌ട്രേറ്റർമാർ, മോഡറേറ്റർമാർ, ജീവനക്കാർ, പണം നൽകുന്നവർ എന്നിവർ കോൺടാക്‌റ്റുകളായി പ്രത്യക്ഷപ്പെടുന്നു.",
  PDF_Subject:"ധനകാര്യം",
  PDF_Keywords:"പേയ്റോൾ പേ ചെക്ക് PDF ചെക്ക് ചെക്കുകൾ",
  Printer_Setup:"പ്രിൻറർ സജ്ജീകരണം",
  Printer_Selection:"പ്രിൻറർ തിരഞ്ഞെടുക്കൽ",
  New_Fax:"പുതിയ ഫാക്സ്",
  New_Fax_Message:"പുതിയ ഫാക്സ് സന്ദേശം",
  Fax_Machine:"ഫാക്സ് മെഷീൻ",
  Fax_Name:"ഫാക്സ് നാമം",
  Fax_Email:"ഫാക്സ് ഇമെയിൽ",
  Fax_Number:"ഫാക്സ് നമ്പർ",
  Contents:"ഉള്ളടക്കം",
  Fax_Body:"പേജ് ബോഡി",
  Header_Word:"തലക്കെട്ട്:",
  Header_Text:"തലക്കെട്ട് വാചകം",
  Include_Header:"തലക്കെട്ട് ഉൾപ്പെടുത്തുക",
  Include_Footer:"അടിക്കുറിപ്പ് ഉൾപ്പെടുത്തുക",
  Footer_Word:"അടിക്കുറിപ്പ്:",
  Footer_Text:"അടിക്കുറിപ്പ് വാചകം",
  Attach_a_Cheque:"ഒരു ചെക്ക് അറ്റാച്ചുചെയ്യുക",
  Add_Deduction:"ഡിഡക്ഷൻ ചേർക്കുക",
  Enable_Fax:"ഫാക്സ് അയയ്ക്കുക",
  Select_Fax:"ഫാക്സ് തിരഞ്ഞെടുക്കുക",
  No_Faxes:"ഫാക്സുകൾ ഇല്ല",
  Faxes:"ഫാക്സുകൾ",
  Save_and_Send:"ഫാക്സ് അയയ്ക്കുക",
  Save_and_Pay:"സംരക്ഷിച്ച് പണമടയ്ക്കുക",
  WARNING:"മുന്നറിയിപ്പ്:",
  Remember:"ഓർക്കുക",
  Printing:"അച്ചടിക്കൽ",
  Printing_Complete:"പ്രിൻറിംഗ് പൂർത്തിയായി!\n\n",
  of:"ഓഫ്",
  There_Were:"അവിടെ ഉണ്ടായിരുന്നു ",
  Successful_Prints:"വിജയകരമായ പ്രിന്റുകൾ കൂടാതെ",
  Unsuccessful_Prints:"വിജയിക്കാത്ത പ്രിന്റുകൾ.",
  PrinterError:"ക്ഷമിക്കണം! ഒരു ​​പിശകുണ്ടായി.",
  Printing_:"അച്ചടിക്കൽ...",
  PrinterSuccess:"പ്രമാണം വിജയകരമായി അച്ചടിച്ചു.",
  PrintersSuccess:"പ്രമാണങ്ങൾ വിജയകരമായി അച്ചടിച്ചു.",
  Print_Cheques:"പ്രിന്റ് ചെക്കുകൾ",
  New_Message:"പുതിയ സന്ദേശം",
  New_Messages:"പുതിയ സന്ദേശങ്ങൾ",
  Read_Message:"സന്ദേശം വായിക്കുക",
  Write_Message:"സന്ദേശം എഴുതുക",
  Send_Message:"സന്ദേശം അയയ്ക്കുക",
  Subject:"വിഷയം",
  Message:"സന്ദേശം",
  Writing:"എഴുത്തു...",
  Send:"അയയ്ക്കുക",
  Set_Date:"തീയതി നിശ്ചയിക്കുക",
  Set_Time:"സമയം സജ്ജീകരിക്കുക",
  Recieve:"സ്വീകരിക്കുക",
  Set_as_Default:"സ്ഥിരസ്ഥിതിയായി സജ്ജമാക്കാൻ",
  Default_Account:"സ്ഥിര അക്കൗണ്ട്",
  Default_Design:"ഡിഫോൾട്ട് ഡിസൈൻ",
  Multiple_Cheques:"ചെക്കുകൾ (ട്രിപ്പിൾ)",
  Account_Mode:"അക്കൗണ്ട് മോഡ്",
  Multiple_Cheques_Description:"ഒരു പേജിന് മൂന്ന് ചെക്കുകൾ.",
  Check_and_Table:"ചെക്ക് & ടേബിൾ",
  Check_including_Table:"ചെക്ക് & അക്കൌണ്ടിംഗ് ടേബിൾ.",
  Check_Mailer:"മെയിലർ പരിശോധിക്കുക",
  Check_Mailer_Window:"ഒരു വിലാസ വിൻഡോ ഉപയോഗിച്ച് പരിശോധിക്കുക.",
  DocuGard_Table_Top:"DocuGard ചെക്ക് & ടേബിൾ (മുകളിൽ)",
  DocuGard_Table_Middle:"DocuGard ചെക്ക് & ടേബിൾ (മധ്യഭാഗം)",
  DocuGard_Table_Bottom:"DocuGard ചെക്ക് & ടേബിൾ (ചുവടെ)",
  DocuGard_Mailer_Top:"DocuGard ചെക്ക് മെയിലർ (മുകളിൽ)",
  DocuGard_Mailer_Middle:"DocuGard ചെക്ക് മെയിലർ (മിഡിൽ)",
  DocuGard_Mailer_Bottom:"DocuGard ചെക്ക് മെയിലർ (താഴെ)",
  DocuGard_Three_Cheques:"DocuGard ചെക്കുകൾ (ട്രിപ്പിൾ)",
  DocuGard_Cheque_Top:"DocuGard ചെക്ക് (മുകളിൽ)",
  DocuGard_Cheque_Middle:"DocuGard ചെക്ക് (മധ്യഭാഗം)",
  DocuGard_Cheque_Bottom:"DocuGard ചെക്ക് (താഴെ)",
  DocuGard_Three_Cheques_Window:"ഒരു പേജിൽ മൂന്ന് ചെക്കുകൾ.",
  DocuGard_Table_Top_Window:"ചെക്ക് & വരുമാന പട്ടിക.",
  DocuGard_Table_Middle_Window:"ചെക്ക് & വരുമാന പട്ടിക.",
  DocuGard_Table_Bottom_Window:"ചെക്ക് & വരുമാന പട്ടിക.",
  DocuGard_Mailer_Top_Window:"ചെക്ക്, ടേബിൾ, വിലാസം.",
  DocuGard_Mailer_Middle_Window:"ചെക്ക്, ടേബിൾ, വിലാസം.",
  DocuGard_Mailer_Bottom_Window:"ചെക്ക്, ടേബിൾ, വിലാസം.",
  DocuGard_Cheque_Top_Window:"സുരക്ഷിത പേപ്പർ പരിശോധിക്കുക.",
  DocuGard_Cheque_Middle_Window:"സുരക്ഷിത പേപ്പർ പരിശോധിക്കുക.",
  DocuGard_Cheque_Bottom_Window:"സുരക്ഷിത പേപ്പർ പരിശോധിക്കുക.",
  Basic_Cheque:"ചെക്ക് (മുകളിൽ)",
  Basic_Cheque_Print:"ഒരൊറ്റ ചെക്ക് അച്ചടിക്കുക.",
  Error_Setting_As_Paid:"പണമടച്ചതായി സജ്ജീകരിക്കുന്നതിൽ പിശക്",
  Add_Attachment:"അറ്റാച്ച്മെന്റ് ചേർക്കുക",
  PrinterUnavailable:"പ്രിൻറർ ലഭ്യമല്ല",
  CreditCardComponent:"കാർഡുകൾ",
  PaypalComponent:"പേപാൽ",
  InteracComponent:"ഇന്ററാക്",
  BitcoinComponent:"ബിറ്റ്കോയിൻ",
  New_Deposit:"പുതിയ നിക്ഷേപം",
  Deposits:"നിക്ഷേപങ്ങൾ",
  No_Deposits:"നിക്ഷേപങ്ങൾ ഇല്ല",
  Credit_Card_Deposit:"ക്രെഡിറ്റ് കാർഡ്",
  New_Credit_Card_Deposit_Message:"ക്രെഡിറ്റ് കാർഡ് ഡെപ്പോസിറ്റ്",
  New_BitCoin_Deposit:"ബിറ്റ്കോയിൻ",
  New_BitCoin_Deposit_Message:"ബിറ്റ്കോയിൻ നിക്ഷേപം",
  New_Interac_Deposit:"ഇന്ററാക്",
  New_Interac_Deposit_Message:"ഇന്ററാക് ട്രാൻസ്ഫർ",
  Payments_Limit:"പേയ്മെന്റ് പരിധി",
  No_Payment_Limit:"പേയ്‌മെന്റ് പരിധി ഇല്ല",
  PayPal_Deposit:"പേപാൽ",
  PayPal_Deposit_Message:"പേപാൽ നിക്ഷേപം",
  No_Deposits_Word:"ആദ്യ കമ്പനി <a routerLink='/folder/Deposit/New'>നിക്ഷേപം</a> ചേർക്കുക.",
  No_Documents_Specified:"അച്ചടിക്കുന്നതിനായി രേഖകളൊന്നും വ്യക്തമാക്കിയിട്ടില്ല",
  No_Printers_Added:"പ്രിൻററുകളൊന്നും കണ്ടെത്തിയില്ല. ഒരെണ്ണം ചേർക്കാൻ ക്രമീകരണങ്ങൾ > പ്രിന്ററുകൾ എന്നതിലേക്ക് പോകുക.",
  DB_Erase_Prompt:"മുഴുവൻ ഡാറ്റാബേസും പൂർണ്ണമായി മായ്‌ക്കണോ? മുന്നറിയിപ്പ്: സംഭരിച്ചിരിക്കുന്ന എല്ലാ വിവരങ്ങളും നിങ്ങൾക്ക് നഷ്‌ടമാകും!",
  Console_Warning:"ഈ കൺസോളിലേക്ക് ഒരു വാചകവും ഒട്ടിക്കരുത്. നിങ്ങൾക്ക് നിങ്ങളെയും/അല്ലെങ്കിൽ നിങ്ങളുടെ കമ്പനിയെയും ഗുരുതരമായ അപകടത്തിലാക്കാം.",
  No_Faxes_Word:"ആദ്യത്തെ <a routerLink='/folder/Fax/New'>ഫാക്സ്</a> സൃഷ്‌ടിക്കുക.",
  Cheque_Delete_Confirmation:"ഈ ചെക്ക് ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  Design_Delete_Confirmation:"ഈ ഡിസൈൻ ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  Cheque_Pay_Confirmation:"ഈ ചെക്ക് പണമടച്ചതായി അടയാളപ്പെടുത്തണോ? ഇത് പ്രിന്റ് ക്യൂവിൽ ദൃശ്യമാകില്ല.",
  Payment_Pay_Confirmation:"ഈ പേയ്‌മെന്റ് പണമടച്ചതായി അടയാളപ്പെടുത്തണോ? ഇത് ചെക്ക് ക്യൂവിൽ ദൃശ്യമാകില്ല.",
  Delete_Deduction_Confirmation:"ഈ കിഴിവ് ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  Delete_Job_Confirmation:"ഈ ജോലി ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  Delete_Timesheet_Confirmation:"ഈ ടൈംഷീറ്റ് ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  Delete_Schedule_Confirmation:"ഈ ഷെഡ്യൂൾ ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  Delete_Setting_Confirmation:"ഈ ക്രമീകരണം പുനഃസജ്ജമാക്കണമെന്ന് തീർച്ചയാണോ?",
  Delete_Fax_Confirmation:"ഈ ഫാക്സ് ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  Delete_File_Confirmation:"ഈ ഫയൽ ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  Delete_Vacation_Confirmation:"ഈ അവധിക്കാലം ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  Delete_Printer_Confirmation:"ഈ പ്രിന്റർ ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  Remove_Design_Confirmation:"ഈ ഡിസൈൻ ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  Delete_Payroll_Confirmation:"ഈ ശമ്പളപ്പട്ടിക ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  Send_Fax_Email_Confirmation:"നിങ്ങൾക്ക് ഈ ഡോക്യുമെന്റുകൾ ഫാക്സ് ചെയ്യാനും ഇമെയിൽ ചെയ്യാനും താൽപ്പര്യമുണ്ടോ?",
  Send_Email_Confirmation:"നിങ്ങൾക്ക് ഈ പ്രമാണം ഇമെയിൽ ചെയ്യണോ?",
  Send_Fax_Confirmation:"നിങ്ങൾക്ക് ഈ പ്രമാണം ഫാക്സ് ചെയ്യണോ?",
  Error_Generating_PDF:"ക്ഷമിക്കണം. ഈ പ്രമാണം സൃഷ്ടിക്കുന്നതിൽ ഒരു പിശകുണ്ടായി.",
  PDF_Error_Saving_Image:"ക്ഷമിക്കണം. ഈ പ്രമാണത്തിന്റെ PDF ഇമേജ് സംരക്ഷിക്കുന്നതിൽ ഒരു പിശകുണ്ടായി.",
  Test_Printer_Confirmation:"ഈ പ്രിന്ററിൽ നിങ്ങൾക്ക് ഒരു ടെസ്റ്റ് പേജ് പ്രിന്റ് ചെയ്യണോ?",
  Save_Timesheet_Prompt:"സംരക്ഷിക്കാൻ ദയവായി ഒരു 'ശീർഷകം' ചേർക്കുക അല്ലെങ്കിൽ 'ടൈമർ ആരംഭിക്കുക' അമർത്തുക.",
  Remove_Geofence_Prompt:"ഈ ജിയോ വേലിയുടെ സ്ഥാനം നീക്കം ചെയ്യണമെന്ന് തീർച്ചയാണോ?",
  Delete_Employee_Confirmation:"നിങ്ങൾ ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ",
  Fire_Employee_Confirmation:"നിങ്ങൾ വെടിവയ്ക്കണമെന്ന് തീർച്ചയാണോ"
}