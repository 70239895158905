export const Th = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  Copyright:"ลิขสิทธิ์ &copy; 2023",
  black:"สีดำ",
  green:"สีเขียว",
  gold:"ทอง",
  blue:"สีฟ้า",
  brown:"สีน้ำตาล",
  purple:"สีม่วง",
  pink:"สีชมพู",
  red:"สีแดง",
  Swatches:"สวอตช์",
  HSL:"เอชเอสแอล",
  RGB:"อาร์จีบี",
  Hue:"เว้",
  Saturation:"ความอิ่มตัว",
  Lightness:"ความสว่าง",
  Upgrade_To_Pro:"อัปเกรดเป็น Pro",
  AllFeaturesInclude:"คุณสมบัติทั้งหมดรวมถึง:",
  PrintUnlimitedCheques:"พิมพ์เช็คไม่จำกัด",
  PremiumChequeDesigns:"การออกแบบเช็คระดับพรีเมียม",
  ManageUnlimitedEmployees:"จัดการพนักงานไม่จำกัด",
  AddUnlimitedPayees:"เพิ่มผู้รับเงินไม่ จำกัด",
  CreateUnlimitedPayrolls:"สร้างเงินเดือนไม่ จำกัด",
  UnlimitedSchedulesandTimeSheets:"ตารางเวลาและแผ่นเวลาไม่ จำกัด",
  BulkPayPalPayouts:"การจ่ายเงินจำนวนมากของ PayPal",
  UnlimitedBankAccounts:"บัญชีธนาคารไม่ จำกัด",
  ManageMultipleCompanies:"จัดการหลายบริษัท",
  TrackInsurancePolicies:"ติดตามกรมธรรม์ประกันภัย",
  Bio_MetricProtection:"การป้องกันไบโอเมตริก",
  Geo_FenceLock_OutProtection:"การป้องกันการล็อกเอาต์ของ Geo-Fence",
  Multiple_Companies_Word:"การจัดการหลายบริษัทไม่สามารถทำได้หากไม่มี Checks premium",
  PayPal_Payouts_Word:"การชำระเงินด้วย PayPal ถูกปิดใช้งานโดยไม่มีเช็คพรีเมียม",
  PINProtection:"การป้องกันรหัส PIN",
  PasswordProtection:"การป้องกันรหัสผ่าน",
  May_Require_Approval:"อาจต้องได้รับการอนุมัติ",
  Subscribe:"ติดตาม",
  Billed:"เรียกเก็บเงินแล้ว",
  Up:"ขึ้น",
  Down:"ลง",
  Positioning:"การวางตำแหน่ง",
  Marker:"เครื่องหมาย",
  Drag_Marker:"ลากเครื่องหมาย",
  Tagline:"พิมพ์เช็คและจัดทำตารางบัญชีเงินเดือน",
  Marker_Word:"ใช้เครื่องหมายเพื่อกำหนดขนาดองค์ประกอบ",
  Pinch_Zoom:"หยิกซูม",
  Pinch_Word:"บีบนิ้วเพื่อซูมองค์ประกอบ",
  Drag:"ลาก",
  Drag_Word:"ใช้นิ้วของคุณเพื่อลากองค์ประกอบ",
  subscription_alias_word:"การสมัครสมาชิกต่ออายุอัตโนมัติ",
  premium_alias_word:"แพ็คเกจอัปเกรดครั้งเดียว",
  print_alias_word:"พิมพ์เช็ครายบุคคล",
  mode_alias_word:"โหมด",
  Pro:"มือโปร",
  Pro_word:"ต้องใช้รุ่น Pro",
  Cant_Delete_Default_Company_Word:"ขออภัย คุณไม่สามารถลบบริษัทเริ่มต้นของคุณได้",
  Reinsert_Default_Designs:"ใส่การออกแบบเริ่มต้นอีกครั้ง",
  Reinsert_Default_Designs_word:"คุณต้องการแทรกการออกแบบเริ่มต้นใหม่หรือไม่?",
  Subscription_Active_Disable_Word:"การสมัครรับข้อมูลนี้เปิดใช้งานอยู่ คุณต้องการยกเลิกการสมัคร Cheques นี้หรือไม่",
  Company_Logo:"โลโก้บริษัท",
  ZERO_:"ศูนย์",
  Disclaimer:"ข้อจำกัดความรับผิดชอบ",
  Privacy_Policy:"นโยบายความเป็นส่วนตัว",
  EULA:"ตรวจสอบ EULA",
  Terms_Of_Service:"เงื่อนไขการให้บริการ",
  Terms_Of_Use:"ข้อกำหนดการใช้งาน",
  Refunds:"นโยบายการคืนเงิน",
  Single_Print:"1 ตรวจสอบ",
  Two_Prints:"2 ตรวจสอบ",
  Five_Prints:"5 ตรวจสอบ",
  Ten_Prints:"10 ตรวจสอบ",
  Fifteen_Prints:"15 ตรวจสอบ",
  Twenty_Prints:"20 เช็ค",
  Thirty_Prints:"30 เช็ค",
  Image_Added:"เพิ่มรูปภาพแล้ว",
  Image_Preview:"ภาพตัวอย่าง",
  No_Image_Was_Selected:"ไม่ได้เลือกรูปภาพ",
  Cheques_Unlimited:"ตรวจสอบไม่ จำกัด",
  _Subscription:"การสมัครสมาชิก",
  Subscription:"เช็ค - 1 เดือน",
  Two_Month_Subscription:"เช็ค - 2 เดือน",
  Three_Month_Subscription:"เช็ค - 3 เดือน",
  Six_Month_Subscription:"เช็ค - 6 เดือน",
  Twelve_Month_Subscription:"เช็ค - 12 เดือน",
  Cheques_Are_Available:"สามารถพิมพ์เช็คได้",
  Upgrade_Required_Two:"เลือกแพ็คเกจและแตะสองครั้งที่ปุ่มราคาเพื่อเริ่มการซื้อของคุณ พิมพ์เช็คสีเต็มรูปแบบที่ดูเป็นมืออาชีพในไม่กี่วินาที",
  Month:"เดือน",
  Due:"เนื่องจาก:",
  Expires:"หมดอายุ:",
  Subscription_Active:"สมัครสมาชิกที่ใช้งานอยู่",
  Subscription_Inactive:"การสมัครสมาชิกไม่ได้ใช้งาน",
  Purchase_Additional_Cheques:"ซื้อเช็คเพิ่ม?",
  Printable_Cheque:"เช็คที่พิมพ์ได้",
  Printable_Cheques:"เช็คที่พิมพ์ได้",
  Printable_Cheque_Word:"ตรวจสอบได้ในบัญชีของคุณ",
  Printable_Cheques_Word:"เช็คที่มีอยู่ในบัญชีของคุณ",
  Max_Amount_Message:"จำนวนเงินที่คุณระบุเกินจำนวนสูงสุดที่ตั้งไว้สำหรับระบบนี้:",
  Terms_Required_Word:"คุณต้องยอมรับข้อตกลงนี้ก่อนที่จะใช้ Cheques ต่อไป",
  Subscriptions:"การสมัครรับข้อมูล",
  Product_Upgrades:"การอัพเกรด",
  Mailed_Out_Cheques:"เช็คที่ส่งทางไปรษณีย์",
  Enter_A_Company_Name:"กรุณากรอกชื่อบริษัท",
  Single_Cheques:"เช็คเดี่ยว",
  Cheque_Golden:"ตรวจสอบทอง",
  Cheque_Golden_Window:"การออกแบบเช็คสีทอง",
  Cheque_Green:"ตรวจสอบสีเขียว",
  Cheque_Green_Window:"การออกแบบเช็คสีเขียว",
  Cheque_Red:"ตรวจสอบสีแดง",
  Cheque_Red_Window:"การออกแบบเช็คสีแดง",
  Cheque_Yellow:"ตรวจสอบสีเหลือง",
  Cheque_Yellow_Window:"การออกแบบเช็คสีเหลือง",
  Cheque_Statue_of_Liberty:"เทพีเสรีภาพ",
  Cheque_Statue_of_Liberty_Window:"การออกแบบตรวจสอบเทพีเสรีภาพ",
  Cheque_Green_Wave:"กรีนเวฟ",
  Cheque_Green_Wave_Window:"การออกแบบเช็คสีเขียว",
  Cheque_Golden_Weave:"สานทอง",
  Cheque_Golden_Weave_Window:"การออกแบบลายตารางสีทองหรูหรา",
  Cheque_Green_Sun:"กรีนซัน",
  Cheque_Green_Sun_Window:"การออกแบบการตรวจสอบที่ลึกและสงบเงียบ",
  Cheque_Blue_Checkers:"หมากฮอสสีน้ำเงิน",
  Cheque_Blue_Checkers_Window:"การออกแบบเช็คสีน้ำเงิน",
  Cashiers_Check:"แคชเชียร์เช็ค",
  Cashiers_Check_Window:"เทมเพลตสไตล์แคชเชียร์เช็ค",
  Cheque_Aqua_Checkers:"อควา เช็คเกอร์",
  Cheque_Aqua_Checkers_Window:"การออกแบบตรวจสอบน้ำ",
  Cheque_Golden_Checkers:"หมากฮอสทองคำ",
  Cheque_Golden_Checkers_Window:"การออกแบบเช็คสีทอง",
  Upgrade_Unavailable:"การอัปเกรดไม่พร้อมใช้งาน",
  Bank_Code_Protection:"การป้องกันหมายเลขธนาคาร",
  Bank_Code_Protection_Word:"ป้องกันหมายเลขธนาคารของคุณไม่ให้ถูกใช้กับแอปนี้ที่ทำงานบนอุปกรณ์อื่นหรือสำหรับผู้ใช้รายอื่น การกระทำนี้กลับไม่ได้ ดำเนินการต่อ?",
  Bank_Code_Protection_Blocked_Word:"หมายเลขธนาคารที่คุณพยายามใช้ถูกสงวนไว้สำหรับผู้ใช้หรืออุปกรณ์อื่น",
  Bank_Code_Protection_Error_Word:"การยืนยันหมายเลขล้มเหลว โปรดเชื่อมต่ออินเทอร์เน็ตและลองเพิ่มบัญชีธนาคารนี้อีกครั้ง",
  Bank_Code_Protection_Set_Error_Word:"การป้องกันหมายเลขธนาคารกำหนดให้คุณต้องเชื่อมต่อกับอินเทอร์เน็ต โปรดเชื่อมต่อและลองอีกครั้ง",
  Upgrade_Unavailable_Word:"ขออภัย เรากำลังประสบปัญหาในการยืนยันตัวคุณ ขณะนี้การสมัครรับข้อมูลและการอัปเกรดเป็น Checks ไม่สามารถซื้อได้ในพื้นที่ของคุณ",
  PayPal_Payment_Preview:"ดูตัวอย่างการชำระเงินของ PayPal",
  Apple_Pay:"แอปเปิ้ลจ่าย",
  Select_PayPal:"เลือกเพย์พาล",
  PayPal_Applications:"แอปพลิเคชันเพย์พาล",
  Purchase_With_Apple_Pay:"ซื้อด้วย Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"บริษัท",
  Insurance:"ประกันภัย",
  New_PayPal:"ใหม่เพย์พาล",
  Pay_By:"จ่ายโดย",
  Insure:"ประกัน",
  Miles:"ไมล์",
  Payment_Method:"วิธีการชำระเงิน",
  Select_Policies:"เลือกนโยบาย",
  Policies:"นโยบาย",
  Policy:"นโยบาย",
  No_PayPal_Account:"ไม่มีบัญชี PayPal",
  No_Policies:"ไม่มีนโยบายการประกันภัย",
  New_Policy:"นโยบายใหม่",
  PayPal_Payment:"การชำระเงินด้วยเพย์พาล",
  PayPal_Payments:"การชำระเงินด้วย PayPal",
  No_Payment_Selected:"ไม่ได้เลือกการชำระเงิน",
  Sending_Payment_Word:"โปรดรอสักครู่... กำลังส่งการชำระเงินนี้",
  Sending_Payments_Word:"กรุณารอสักครู่... กำลังดำเนินการชำระเงิน",
  No_Payment_Selected_PayPal:"ไม่ได้เลือก <a routerLink='/folder/Payments'>การชำระเงิน PayPal</a> เพื่อส่ง",
  Payment_Sent:"ส่งการชำระเงินแล้ว",
  PayPal_Payment_Sent:"การชำระเงินนี้ถูกส่งด้วย PayPal",
  Copay:"โคเพย์",
  Dead:"ตาย",
  Alive:"มีชีวิตอยู่",
  Not_Dead:"ไม่ตาย",
  Unclaimed:"ไม่มีการอ้างสิทธิ์",
  Attempted:"พยายาม",
  Deceased:"ตาย",
  Claimed:"อ้างสิทธิ์",
  Unpaid:"ค้างชำระ",
  Sending_Payment:"ส่งการชำระเงิน",
  Sending_Payments:"การส่งการชำระเงิน",
  Send_PayPal_Confirmation:"คุณต้องการส่งธุรกรรมนี้ด้วย PayPal หรือไม่",
  Send_PayPal_Confirmation_Word:"กดปุ่มสีเขียวเพื่อส่งธุรกรรมนี้",
  Save_Payment_As_Unpaid:"บันทึกการชำระเงินนี้เป็นยังไม่ได้ชำระไหม",
  Payment_Pay_Confirmation_PayPal:"บันทึกการชำระเงินนี้เป็นการชำระเงินแล้วหรือไม่",
  No_Policies_Word:"เพิ่ม <a routerLink='/folder/Postage/New'>กรมธรรม์ประกันภัย</a> รายการแรกทันที",
  Timesheet_Multiple_Delete_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบแผ่นบันทึกเวลาหลายแผ่น",
  Select_Multiple_Timesheets_Prompt:"ไม่ได้เลือกไทม์ชีท เลือกอย่างน้อยหนึ่งแผ่นเวลา",
  Select_Multiple_Policies_Prompt:"ไม่ได้เลือกนโยบาย เลือกอย่างน้อยหนึ่งกรมธรรม์ประกันภัย",
  Policies_Multiple_Delete_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบนโยบายหลายรายการ",
  Company:"บริษัท",
  Add_Company:"เพิ่ม บริษัท",
  New_Company:"เพิ่ม บริษัท",
  No_Companies:"ไม่มีบริษัท",
  Enable_Company:"เปิดใช้งานบริษัท",
  Select_Company:"เลือก บริษัท",
  The_Default_Company:"ป้ายชื่อบริษัทเริ่มต้น",
  Manage_Companies:"จัดการ บริษัท",
  No_Companies_Word:"การตรวจสอบจะใช้บริษัทเริ่มต้น<br />เพิ่ม <a routerLink='/folder/Company/New'>บริษัทแรก</a>",
  Default_Company:"บริษัทเริ่มต้น",
  Cheques_Unlimited_Word:"Checks Unlimited ให้คุณพิมพ์เช็คได้มากเท่าที่คุณต้องการ",
  Cheques_Subscription_Word:"การสมัครสมาชิกเช็คช่วยให้คุณสามารถพิมพ์เช็คได้มากเท่าที่คุณต้องการ",
  You_Own_This_Product:"คุณเป็นเจ้าของผลิตภัณฑ์นี้",
  Your_Subscription_is_Active:"การสมัครของคุณใช้งานได้",
  Active_Products:"ผลิตภัณฑ์ที่เปิดใช้งาน",
  Purchase_Confirmation:"ซื้อ",
  Purchase_Cheques:"ตรวจสอบการซื้อ",
  Restore_Purchase:"เรียกคืนการซื้อสินค้า",
  Erase_Purchase:"ลบการซื้อ",
  Successfully_Purchased:"ซื้อเรียบร้อยแล้ว",
  Enter_Your_Licence_Key:"โปรดป้อนรหัสใบอนุญาตของคุณในหน้านี้เพื่อเปิดใช้งานผลิตภัณฑ์นี้",
  Licence_Key:"รหัสใบอนุญาต",
  Enter_Licence_Key:"ป้อนรหัสใบอนุญาต",
  Purchased:"ซื้อแล้ว",
  Enable_Feature:"เปิดใช้งานคุณสมบัติ",
  Cancel_Subscription:"ยกเลิกการสมัครสมาชิก",
  Subscription_Removed:"ลบการสมัครสมาชิกแล้ว",
  Select_Active_Subscription:"เลือกการสมัครสมาชิกที่ใช้งานอยู่เพื่อแก้ไข",
  Remove_Subscription_Word:"คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการสมัครรับข้อมูลนี้",
  Delete_Company_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบทั้งบริษัทนี้ คำเตือน: คุณจะสูญเสียข้อมูลที่เก็บไว้ทั้งหมด!",
  Delete_Default_Company_Word:"คุณไม่สามารถลบบริษัทเริ่มต้นได้ คุณต้องการรีเซ็ตแอปพลิเคชันและกู้คืนเป็นสถานะเริ่มต้นหรือไม่ คำเตือน: คุณจะสูญเสียข้อมูลที่เก็บไว้ทั้งหมด!",
  Console_Warning_2:"อย่าจัดการสกุลเงินใด ๆ โดยใช้แอปพลิเคชันนี้ซึ่งปัจจุบันไม่ใช่ของคุณ",
  Terms_and_Conditions:"ข้อกำหนดและเงื่อนไข",
  and_the:"และ",
  for:"สำหรับ",
  Please_Read_Word:"โปรดอ่านและยอมรับ",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"ไม่พบอัตราการแปลงสกุลเงินบางสกุล กรุณาเชื่อมต่ออินเทอร์เน็ต",
  Free:"ฟรี",
  DB_Erase_Prompt_2:"ลบฐานข้อมูลนักพัฒนาทั้งหมดหรือไม่ คำเตือน: คุณจะสูญเสียข้อมูลการซื้อและสมัครสมาชิกทั้งหมด!",
  Successfully_Imported:"นำเข้าสำเร็จ",
  Select_Postage:"เลือกไปรษณีย์",
  No_Postage:"ไม่มีแสตมป์",
  No_Paid_Postage_Word:"เพิ่มแสตมป์ <a routerLink='/folder/Postage/New'>ไปรษณีย์แบบชำระเงิน</a>ชุดแรก",
  Trial_Complete:"การทดลองเสร็จสมบูรณ์",
  Please_Upgrade:"โปรดอัปเกรด Checks เพื่อพิมพ์ต่อ",
  Aa:"อา",
  Color:"สี",
  Font:"แบบอักษร",
  Guide:"แนะนำ",
  Guides:"คู่มือ",
  Image:"ภาพ",
  Zoom:"ซูม",
  Logo:"โลโก้",
  Bank:"ธนาคาร",
  MICR:"ไมครอน",
  Total:"ทั้งหมด",
  Cancel:"ยกเลิก",
  Confirm:"ยืนยัน",
  Method:"วิธี",
  Biometric_Security:"ความปลอดภัยไบโอเมตริก",
  Please_Login_To_Continue:"โปรดเข้าสู่ระบบเพื่อจะดำเนินการต่อ.",
  Complete:"สมบูรณ์",
  Sign_Up:"ลงชื่อ",
  Error:"ข้อผิดพลาด",
  Biometrics:"ไบโอเมตริก",
  Percent:"เปอร์เซ็นต์",
  Zero_Percent:"0%",
  Top_Margin:"ขอบบน",
  Bottom_Margin:"ขอบล่าง",
  Left_Margin:"ระยะขอบซ้าย",
  Right_Margin:"ระยะขอบขวา",
  MICR_Margin:"MICR มาร์จิ้น",
  Table_Margin:"ระยะขอบของตาราง",
  Address_Margin:"ระยะขอบที่อยู่",
  Percentage_:"เปอร์เซ็นต์",
  Vacation_Title:"ชื่อเรื่อง วันหยุด",
  Use_PIN:"ใช้รหัส PIN",
  Loading__:"กำลังโหลด...",
  Design_Title:"ชื่อเรื่องการออกแบบ",
  Authorize:"อนุญาต",
  Key:"สำคัญ",
  Public_Key:"รหัสสาธารณะ",
  Private_Key:"คีย์ส่วนตัว",
  Authenticate:"ตรวจสอบสิทธิ์",
  Last_Payroll:"เงินเดือนล่าสุด",
  Last_Calculation:"การคำนวณครั้งล่าสุด",
  Authorized:"ได้รับอนุญาต",
  Geo_Authorized:"ตำแหน่งทางภูมิศาสตร์: ได้รับอนุญาต",
  Not_Authorized:"ไม่มีอำนาจ",
  Authorization_Complete:"การอนุญาตเสร็จสมบูรณ์",
  Geolocation_Authorization:"การอนุญาตตำแหน่งทางภูมิศาสตร์",
  Out_of_Bounds:"นอกขอบเขต",
  Cant_Delete_Default_Design:"ไม่สามารถลบการออกแบบเริ่มต้นได้",
  Unauthorized_Out_of_Bounds:"ไม่ได้รับอนุญาต: นอกขอบเขต",
  Biometric_Authorization:"การอนุญาตไบโอเมตริก",
  Locating_Please_Wait:"กำลังค้นหา โปรดรอสักครู่...",
  Test_Biometrics:"ทดสอบไบโอเมตริก",
  Cheque_Margins:"ตรวจสอบระยะขอบ",
  Percentage_Full_Error:"ไม่สามารถตั้งค่าฟิลด์เปอร์เซ็นต์เกิน 100% เปอร์เซ็นต์",
  No_Payroll_Text:"เพิ่ม <a routerLink='/folder/Employee/New'>พนักงาน</a> หรือ <a routerLink='/folder/Payee/New'>ผู้รับเงิน</a> และ <a routerLink='/folder/Schedule /New'>กำหนดการ</a>",
  Design_Saved:"บันทึกการออกแบบแล้ว",
  Default_Design_Selected:"การออกแบบเริ่มต้นที่เลือกไว้",
  Partial_Import:"นำเข้าบางส่วน",
  Partial_Export:"การส่งออกบางส่วน",
  Entire_Import:"นำเข้าทั้งหมด",
  Entire_Export:"การส่งออกทั้งหมด",
  Existing_Password:"กรุณาใส่รหัสผ่านที่มีอยู่ของคุณ:",
  Existing_PIN:"โปรดป้อนรหัส PIN ที่มีอยู่ของคุณ:",
  Pin_Change_Header:"การยืนยัน PIN",
  Pin_Change_SubHeader:"ป้อนหมายเลข PIN เก่าของคุณเพื่อยืนยันการเปลี่ยนแปลง",
  Pass_Change_Header:"การยืนยันรหัสผ่าน",
  Pass_Change_SubHeader:"ป้อนรหัสผ่านเดิมของคุณเพื่อยืนยันการเปลี่ยนแปลง",
  PIN_Enter_Message:"ป้อน PIN ของคุณเพื่อยืนยัน",
  Password_Enter_Message:"ป้อนรหัสผ่านของคุณเพื่อยืนยัน",
  Pin_Updated_Success:"อัปเดต PIN เรียบร้อยแล้ว!",
  Pin_Updated_Fail:"ไม่สามารถอัปเดต PIN ได้",
  Pass_Updated_Success:"อัปเดตรหัสผ่านสำเร็จแล้ว",
  Pass_Updated_Fail:"ไม่สามารถอัปเดตรหัสผ่านได้",
  Preview_Payment:"ดูตัวอย่างการชำระเงิน",
  Preview_Payroll:"ดูตัวอย่างบัญชีเงินเดือน",
  No_Payments_Created:"ไม่พบการชำระเงินที่จะสร้าง",
  Payment_Preview:"ดูตัวอย่างการชำระเงิน",
  Enable_Payee:"เปิดใช้งานผู้รับเงิน",
  Rendered:"แสดงผล",
  No_Email:"ไม่มีอีเมล",
  Setup_Cheques:"ตรวจสอบการตั้งค่า",
  name:"ชื่อ",
  address:"ที่อยู่",
  address_2:"ที่อยู่บรรทัดที่ 2",
  city:"เมือง",
  province:"จังหวัด",
  postal_code:"รหัสไปรษณีย์/ไปรษณีย์",
  country:"ประเทศ",
  username:"ชื่อผู้ใช้",
  full_name:"ชื่อเต็ม",
  birthday:"วันเกิด",
  email:"อีเมล",
  phone:"โทรศัพท์",
  employees:"พนักงาน",
  addresses:"ที่อยู่",
  payment_amount_limit:"วงเงินการชำระเงิน",
  total_limit:"วงเงินรวม",
  payees:"ผู้รับเงิน",
  description:"คำอธิบาย",
  address_line_one:"ที่อยู่บรรทัดที่หนึ่ง",
  address_line_two:"ที่อยู่บรรทัดที่สอง",
  image:"ภาพ",
  account_holder:"ผู้ถือบัญชี",
  cheque_starting_id:"ตรวจสอบรหัสเริ่มต้น",
  transit_number:"หมายเลขการขนส่ง",
  institution_number:"หมายเลขสถาบัน",
  designation_number:"หมายเลขประจำตัว",
  account_number:"หมายเลขบัญชี",
  currency:"สกุลเงิน",
  signature:"ลายเซ็น",
  payment_payroll_limit:"วงเงินการชำระเงิน",
  total_limi:"วงเงินรวม",
  date:"วันที่",
  printed_memo:"พิมพ์บันทึก",
  banks:"ธนาคาร",
  signature_image:"ภาพลายเซ็น",
  address_name:"ชื่อที่อยู่",
  notes:"หมายเหตุ",
  design:"ออกแบบ",
  title:"ชื่อ",
  frequency:"ความถี่",
  fax:"แฟกซ์",
  salaries:"เงินเดือน",
  salary_ids:"รหัสเงินเดือน",
  start_time:"เวลาเริ่มต้น",
  end_time:"เวลาสิ้นสุด",
  paid:"จ่าย",
  overtime_percentage:"เปอร์เซ็นต์ที่จ่าย",
  overtime_amount:"จ่ายเป็นจำนวนคงที่",
  first_name:"ชื่อจริง",
  last_name:"นามสกุล",
  moderation:"การดูแล",
  create:"สร้าง",
  edit:"แก้ไข",
  destroy:"ทำลาย",
  day_start_time:"วัน_start_time",
  day_end_time:"วัน_end_เวลา",
  fullname:"ชื่อ",
  time:"เวลา",
  auto_send:"ส่งโดยอัตโนมัติ",
  time_method:"วิธีเวลา",
  schedules:"ตารางเวลา",
  indefinite_payroll_enabled:"เปิดใช้งานอย่างไม่มีกำหนด",
  amount:"จำนวน",
  repeat:"ทำซ้ำ",
  always_enabled:"เปิดใช้งานเสมอ",
  start_date:"วันที่เริ่มต้น",
  end_date:"วันที่สิ้นสุด",
  Cheque_Total:"ตรวจสอบผลรวม",
  Total_Amount:"จำนวนเงินทั้งหมด:",
  Amounts:"จำนวน:",
  Images:"รูปภาพ",
  Files:"ไฟล์",
  Previewing:"กำลังดูตัวอย่าง:",
  Insert:"แทรก",
  Preview_Import:"ดูตัวอย่างการนำเข้า",
  Entry:"รายการ",
  Entries:"รายการ",
  No_Entries:"ไม่มีรายการ",
  Import_Type:"ประเภทนำเข้า",
  Select_Details:"เลือกรายละเอียด",
  Select_Payee:"เลือกผู้รับเงิน",
  Enable_Holidays:"เปิดใช้งานวันหยุด",
  Disable_Holidays:"ปิดใช้งานวันหยุด",
  Wire_Transfer:"การโอนเงินผ่านธนาคาร",
  New_Export:"ใหม่ส่งออก",
  Export_Data:"ส่งออกข้อมูล",
  Export_Data_Word:"เลือกประเภทของไฟล์ที่จะส่งออกและดาวน์โหลด",
  Export_File:"ส่งออกไฟล์",
  Mode:"โหมด",
  Times:"ครั้ง",
  Widgets:"วิดเจ็ต",
  Slider:"สไลเดอร์",
  Set_Details:"รายละเอียดชุด",
  Select_Type:"เลือกประเภท",
  Display_Slider:"แถบเลื่อนการแสดงผล",
  Dashboard_Slider:"ตัวเลื่อนแดชบอร์ด",
  Dashboard_Mode:"โหมดแดชบอร์ด",
  Show_Intro:"แสดงบทนำ",
  Show_Payrolls:"แสดงบัญชีเงินเดือน",
  Show_Holidays:"แสดงวันหยุด",
  Show_Invoices:"แสดงใบแจ้งหนี้",
  Show_Cheques:"แสดงการตรวจสอบ",
  Enabled_Widgets:"วิดเจ็ตที่เปิดใช้งาน",
  Disabled_Widgets:"วิดเจ็ตที่ปิดใช้งาน",
  Colors:"สี",
  Barcodes:"บาร์โค้ด",
  View_Timers:"ดูตัวจับเวลา",
  Gradients:"การไล่ระดับสี",
  No_Info:"ไม่มีข้อมูล",
  Disable:"ปิดการใช้งาน",
  Show_Layer:"แสดงเลเยอร์",
  Hide_Layer:"ซ่อนเลเยอร์",
  Text_Layer:"เลเยอร์ข้อความ",
  Secondly:"ประการที่สอง",
  Minutely:"นาที",
  nine_am:"09.00 น",
  five_pm:"17.00 น",
  Enable_Address:"เปิดใช้งานที่อยู่",
  Invalid_File_Type:"ขออภัย เลือกประเภทไฟล์ไม่ถูกต้อง ประเภทไฟล์ที่รองรับ:",
  Error_Updating_Entry:"ขออภัย มีข้อผิดพลาดในการอัปเดตรายการนี้",
  Schedule_Timing_Alert:"ข้อผิดพลาด: เวลาเริ่มต้นของกำหนดการถูกกำหนดเป็นค่าหลังจากเวลาสิ้นสุด",
  Select_Multiple_Payments_Prompt:"ไม่ได้เลือกการชำระเงิน เลือกการชำระเงินอย่างน้อยหนึ่งรายการ",
  Select_Multiple_Cheques_Prompt:"ไม่ได้เลือกกา โปรดเลือกอย่างน้อยหนึ่งเช็ค",
  Select_Multiple_Items_Prompt:"ไม่มีรายการที่เลือก โปรดเลือกอย่างน้อยหนึ่งรายการ",
  Paymemt_Multiple_Delete_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบการชำระเงินหลายรายการ",
  Cheque_Multiple_Delete_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบเช็คหลายรายการ",
  Payee_Multiple_Delete_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบผู้รับเงินหลายราย",
  Employee_Multiple_Delete_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบพนักงานหลายคน",
  MICR_Warning:"หมายเหตุ: เครื่องพิมพ์และอุปกรณ์บางอย่างอาจแสดงแบบอักษร MICR ไม่ถูกต้อง",
  Automatically_Send:"ส่งโดยอัตโนมัติ",
  Type:"พิมพ์",
  Payment_Type:"ประเภทการชำระเงิน",
  Auto_Send:"ส่งอัตโนมัติ",
  Automatically_Process:"ดำเนินการโดยอัตโนมัติ",
  Auto_Process:"กระบวนการอัตโนมัติ",
  Image_Based:"ตามรูปภาพ",
  Font_Based:"ตามตัวอักษร",
  Rerender:"แสดงผลอีกครั้ง",
  Rendering:"กำลังแสดงผล",
  Render:"ไฟล์",
  Top:"สูงสุด",
  Middle:"กลาง",
  Bottom:"ด้านล่าง",
  Top_Left:"บนซ้าย",
  Top_Center:"ท็อปเซ็นเตอร์",
  Top_Right:"ด้านบนขวา",
  Middle_Left:"ซ้ายกลาง",
  Middle_Center:"ศูนย์กลาง",
  Middle_Right:"กลางขวา",
  Bottom_Left:"ล่างซ้าย",
  Bottom_Center:"ศูนย์ล่าง",
  Bottom_Right:"ด้านล่างขวา",
  Numbers:"ตัวเลข",
  Verified:"ตรวจสอบแล้ว",
  Paper_Size:"ขนาดกระดาษ",
  New_Device:"อุปกรณ์ใหม่",
  Add_Device:"เพิ่มอุปกรณ์",
  Remove_Device:"ลบอุปกรณ์",
  Delete_Device:"ลบอุปกรณ์",
  Block_Device:"บล็อกอุปกรณ์",
  Block:"ปิดกั้น",
  Unblock:"เลิกบล็อก",
  Table:"โต๊ะ",
  Scan_Login_Code:"สแกนรหัสเข้าสู่ระบบ",
  Login_Code:"รหัสเข้าสู่ระบบ",
  Scan_Code:"สแกนรหัส",
  Scan_QR_Code:"สแกนคิวอาร์โค้ด",
  Select_All:"เลือกทั้งหมด",
  Deselect_All:"ยกเลิกการเลือกทั้งหมด",
  Increase:"เพิ่มขึ้น",
  Decrease:"ลด",
  Bold:"ตัวหนา",
  Text:"ข้อความ",
  Style:"สไตล์",
  Italic:"ตัวเอียง",
  QR_Code:"คิวอาร์โค้ด",
  Barcode:"บาร์โค้ด",
  Browse_Images:"เรียกดูรูปภาพ",
  Browse_Files:"เรียกดูไฟล์",
  Background_Image:"ภาพพื้นหลัง",
  Logo_Image:"รูปภาพโลโก้",
  Header_Image:"รูปภาพส่วนหัว",
  Bank_Image:"ภาพธนาคาร",
  Cut_Lines:"ตัดเส้น",
  Background:"พื้นหลัง",
  License:"ใบอนุญาต",
  One_License:"1 ใบอนุญาต:",
  Licensed:"ได้รับอนุญาตให้:",
  Not_Licensed:"ไม่ได้รับใบอนุญาต",
  Enter_Serial:"ใส่ซีเรียล",
  Serial_Key:"ซีเรียลคีย์",
  Serial:"อนุกรม",
  Product_Key:"คีย์ผลิตภัณฑ์",
  Check_Product_Key:"ตรวจสอบรหัสผลิตภัณฑ์",
  Add_Product_Key:"ป้อนรหัสผลิตภัณฑ์",
  Verifying_Purchase:"กำลังยืนยันการซื้อ...",
  Print_Envelope:"พิมพ์ซองจดหมาย",
  Envelope:"ซองจดหมาย",
  Thank_You:"ขอบคุณ!",
  Scale:"มาตราส่วน",
  Print_Scale:"ขนาดการพิมพ์",
  Borders:"เส้นขอบ",
  VOID:"เป็นโมฆะ",
  Void_Cheque:"การตรวจสอบเป็นโมฆะ",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ชำระเงินตามคำสั่งของ:",
  NO_DOLLARS:"ไม่มีดอลลาร์",
  ONE_DOLLAR:"หนึ่งดอลลาร์",
  DOLLARS:"ดอลลาร์",
  AND:"และ",
  CENTS:" เซ็นต์",
  NO_:"เลขที่",
  ONE_:"หนึ่ง",
  AND_NO_:"และไม่",
  _AND_ONE_:"และหนึ่ง",
  DOLLARS_AND_ONE_CENT:"และหนึ่งเซ็นต์",
  AND_NO_CENTS:" และศูนย์เซ็นต์",
  CHEQUE_PRINT_DATE:"วันที่:",
  CHEQUE_PRINT_MEMO:"บันทึก:",
  CHEQUE_PRINT_SIGNATUREMP:"ส.ส",
  Initial_Tasks:"งานเริ่มต้น",
  Inital_Setup:"ตั้งค่าเริ่มต้น",
  Welcome_To:"ยินดีต้อนรับสู่",
  Welcome:"ยินดีต้อนรับ",
  Swipe:"ปัด",
  Intro_Setup:"การตั้งค่าบทนำ",
  Introduction:"การแนะนำ",
  CHEQUE_PRINT_CREDIT:"ขับเคลื่อนโดยเช็ค",
  Title_Intro_Word:"ยินดีต้อนรับสู่เช็ค",
  Title_Intro:"บทนำการตรวจสอบ",
  Title_Setup:"ตรวจสอบการตั้งค่า",
  PayPal_Default_Email_Message:"คุณได้รับการโอนเงิน PayPal ใหม่แล้ว",
  Cheques_App_Export:"ส่งออกโดยเช็ค",
  No_Prints_Purchased:"ไม่มีการซื้อภาพพิมพ์",
  No_Prints_Purchased_Word:"ไม่มีการพิมพ์เพิ่มเติม กรุณาซื้อเช็คเพิ่มเติม",
  Post_Intro_Thanks:"ขอบคุณที่เลือกเช็ค ขั้นตอนการตั้งค่าเสร็จสมบูรณ์แล้ว",
  Post_Intro_Word:"เริ่มต้นด้วยการพิมพ์เช็คฉบับแรกของคุณ เพิ่มการชำระเงินในอนาคต หรือเพิ่มพนักงานในบัญชีเงินเดือน",
  Upgrade_Required:"การตรวจสอบกำหนดให้คุณต้องเป็นเจ้าของซอฟต์แวร์เวอร์ชันพรีเมียมเพื่อซ่อนข้อความนี้และปลดล็อกคุณสมบัติเพิ่มเติม",
  Upgrade_Title:"ตรวจสอบ",
  Mailout_Prompt:"นอกจากนี้ คุณยังสามารถเลือกที่จะให้ Checks ส่งเช็คจ่ายเงินเดือนให้คุณทางไปรษณีย์",
  Mailed_Cheque:"เช็คทางไปรษณีย์",
  Mailed_Cheque_Color:"เช็คส่งทางไปรษณีย์ (สี)",
  Terms_Purchase:"การซื้อทางอิเล็กทรอนิกส์ทั้งหมดด้วยเช็คจะได้รับเงินคืนเต็มจำนวนภายใน 30 วันนับจากวันที่ซื้อ โปรดอ่านและยอมรับ",
  Dashboard_Setup:"ตั้งค่าเครื่องพิมพ์หลัก",
  Dashboard_Add:"เพิ่มบัญชีธนาคารหลัก",
  Dashboard_Customize:"เลือกเทมเพลตการตรวจสอบ",
  Dashboard_Job_Salary:"สร้างงานและเพิ่มเงินเดือนของคุณ",
  Dashboard_Employees:"ติดตามพนักงานและผู้รับเงิน",
  Dashboard_Print:"พิมพ์และส่ง Paycheques ของคุณ",
  Dashboard_Payroll:"การพิมพ์บัญชีเงินเดือนของคุณโดยอัตโนมัติ",
  Dashboard_PayPal:"ตั้งค่าบัญชีเงินเดือน / การจ่ายเงินของ PayPal",
  Begin_Setup:"เริ่มการตั้งค่า",
  Get_Started:"เริ่ม",
  Purchase:"ซื้อ",
  Lockdown:"การปิดพื้นที่",
  Unlock:"ปลดล็อค",
  Detailed:"รายละเอียด",
  Log_In:"เข้าสู่ระบบ",
  Login:"เข้าสู่ระบบ",
  Launch:"ปล่อย",
  Register:"ลงทะเบียน",
  Finish:"เสร็จ",
  List:"รายการ",
  Weekends:"วันหยุดสุดสัปดาห์",
  Weekly:"รายสัปดาห์",
  PayPal_Default_Subject:"การชำระเงินใหม่",
  Payment_Message:"ข้อความการชำระเงิน",
  PayPal_Default_Payment_Note:"ขอบคุณ",
  Setup_Your_Cheqing_Account:"บัญชีเช็ค",
  Add_Your_Primary_Cheqing_Account:"เพิ่มบัญชีเช็คหลักของคุณ",
  Welcome_Word:"ลดความซับซ้อนและทำให้การจ่ายเงินเดือนและการจัดการทรัพยากรบุคคลเป็นไปโดยอัตโนมัติ",
  Get_Started_Quickly:"เพียงตอบคำถามง่ายๆ ไม่กี่ข้อ ซึ่งจะช่วยให้เราเริ่มต้น...",
  Done_Intro_Word:"ตั้งค่าเสร็จสมบูรณ์",
  PIN_Protected:"ป้องกันรหัสผ่านและ PIN",
  Enter_New_PIN:"ป้อนรหัส PIN ใหม่:",
  Enter_PIN:"ป้อนรหัส PIN:",
  Invalid_PIN:"ป้อน PIN ไม่ถูกต้อง",
  Account_Identifier:"ตัวระบุบัญชี",
  New_Account_Identifier:"ตัวระบุบัญชีใหม่",
  attempt:"พยายาม",
  attempts:"ความพยายาม",
  remaining:"ที่เหลืออยู่",
  Language:"ภาษา",
  languages:"ภาษา",
  select_languages:"เลือกภาษา",
  Deposit:"เงินฝาก",
  Hire_One_Now:"จ้างหนึ่งตอนนี้",
  App_Locked:"แอปพลิเคชันถูกล็อค",
  Skip_:"ข้าม",
  Skip_to_Dashboard:"ข้ามไปที่แดชบอร์ด",
  Dashboard:"แผงควบคุม",
  Import:"นำเข้า",
  Admin:"ผู้ดูแลระบบ",
  New_Admin:"ผู้ดูแลระบบใหม่",
  Settings:"การตั้งค่า",
  Color_Picker:"ตัวเลือกสี",
  Font_Picker:"ตัวเลือกแบบอักษร",
  Logout:"ออกจากระบบ",
  Close:"ปิด",
  Close_menu:"ปิด",
  Excel:"ไฟล์เอ็กเซล",
  Csv:"ไฟล์ CSV",
  Sql:"ไฟล์ SQL",
  Json:"ไฟล์ JSON",
  Url:"นำเข้าโดย URL",
  Back:"กลับ",
  Timers:"ตัวจับเวลา",
  Cheque:"ตรวจสอบ",
  Print:"พิมพ์",
  Designs:"การออกแบบ",
  Pause_Printing:"หยุดพิมพ์ชั่วคราว",
  Resume_Printing:"ดำเนินการพิมพ์ต่อ",
  Printing_Paused:"หยุดการพิมพ์ชั่วคราว",
  PrintingUnavailable:"ขอโทษ! ไม่สามารถพิมพ์ได้ในระบบนี้",
  Prints_Paused:"พิมพ์หยุดชั่วคราว",
  Administration:"การบริหาร",
  Loading:"กำลังโหลด",
  Unnamed:"ไม่มีชื่อ",
  error:"ขออภัย ไม่สามารถเปิดการตรวจสอบนี้เพื่อดูได้",
  No_Cheques_To_Print:"ไม่มีการตรวจสอบเพื่อพิมพ์",
  No_Cheques_To_Print_Word:"สร้าง <a routerLink='/folder/Cheque/New'>เช็คใหม่</a>",
  New_Cheque:"เช็คใหม่",
  Start_One_Now:"เริ่มเลย",
  Edit_Cheque:"แก้ไข ตรวจสอบ",
  Select_Cheques:"เลือก เช็ค",
  Select_Employee:"เลือกพนักงาน",
  Default_Printer:"เครื่องพิมพ์เริ่มต้น",
  Reassign:"กำหนดใหม่",
  Configure:"กำหนดค่า",
  Template:"แม่แบบ",
  Designer:"ดีไซเนอร์",
  Edit_Designs:"แก้ไขการออกแบบ",
  New_Design:"การออกแบบใหม่",
  Next:"ต่อไป",
  Save:"บันทึก",
  Name:"ชื่อ",
  Mail:"จดหมาย",
  Amount:"จำนวน",
  Date:"วันที่",
  PayPal:"เพย์พาล",
  Payouts:"การจ่ายเงิน",
  PayPal_Label:"ป้ายกำกับ PayPal",
  Email_Username:"อีเมล / ชื่อผู้ใช้",
  Client_ID:"รหัสลูกค้า",
  Sandbox_Email:"อีเมลแซนด์บ็อกซ์",
  PayPal_Email:"อีเมลเพย์พาล",
  PayPal_Username:"ชื่อผู้ใช้ API",
  PayPal_Payouts:"การจ่ายเงินของ PayPal",
  Select_PayPal_Key:"เลือกรหัส PayPal",
  Secret:"ความลับ",
  API_Secret:"ความลับของ API",
  PayPal_API_Keys:"คีย์ PayPal",
  New_PayPal_Key:"รหัส PayPal ใหม่",
  Email_Subject:"หัวข้อจดหมาย",
  Email_Message:"ข้อความอีเมล",
  Payout_Options:"ตัวเลือกการจ่ายเงิน",
  Payment_Note:"หมายเหตุการชำระเงิน",
  Enable_Employee:"เปิดใช้งานพนักงาน",
  Enable_Production_Mode:"เปิดใช้งานโหมดการผลิต",
  Sandbox_Username:"ชื่อผู้ใช้แซนด์บ็อกซ์",
  Sandbox_Signature:"ลายเซ็นแซนด์บ็อกซ์",
  Sandbox_Password:"รหัสผ่านแซนด์บ็อกซ์",
  Production_Username:"ชื่อผู้ใช้การผลิต",
  Production_Signature:"ลายเซ็นการผลิต",
  Production_Password:"รหัสผ่านการผลิต",
  Production_Email:"อีเมลการผลิต",
  API_Client_ID:"รหัสลูกค้า API",
  API_Signature:"ลายเซ็น API",
  API_Password:"รหัสผ่าน API",
  API_Username:"ชื่อผู้ใช้ API",
  Secret_Key:"คีย์ลับ",
  Sandbox:"กระบะทราย",
  Production:"การผลิต",
  Sandbox_Keys:"คีย์แซนด์บ็อกซ์",
  Production_Keys:"คีย์การผลิต",
  API_Title:"ชื่อ API",
  Production_Mode:"โหมดการผลิต",
  Account_Label:"ป้ายชื่อบัญชี",
  No_PayPal_Setup:"ไม่มีรหัส PayPal",
  Enable_PayPal_Account:"เปิดใช้งานบัญชี PayPal",
  No_PayPal_Word:"เพิ่ม <a routerLink='/folder/Invoice/New'>คีย์ API ของ PayPal</a>",
  Printed_Memo:"พิมพ์บันทึก",
  Employee:"พนักงาน",
  View_Employee:"ดูพนักงาน",
  Mailing_Address:"ที่อยู่ทางไปรษณีย์",
  Company_Address:"ที่อยู่ บริษัท",
  Select_Company_Address:"เลือกที่อยู่บริษัท",
  Address:"ที่อยู่",
  Any_Day:"วันใดวันหนึ่ง",
  Address_Name:"ชื่อที่อยู่",
  Unit:"หน่วย",
  Account:"บัญชี",
  Bank_Account:"บัญชีธนาคาร",
  Account_Limits:"เปิดใช้งานการจำกัดบัญชี",
  Payroll:"บัญชีเงินเดือน",
  Run:"วิ่ง",
  Run_Payroll:"เรียกใช้บัญชีเงินเดือน",
  New_Payroll:"บัญชีเงินเดือนใหม่",
  No_Payroll:"ไม่มีการจ่ายเงินเดือนที่จะเกิดขึ้น",
  Upcoming_Holiday:"วันหยุดที่จะมาถึง:",
  Invoice_Due:"ใบแจ้งหนี้ที่ครบกำหนด:",
  New_Invoice:"ใบแจ้งหนี้ใหม่",
  No_Invoices:"ไม่มีใบแจ้งหนี้",
  No_Invoices_Word:"สร้าง <a routerLink='/folder/Invoice/New'>ใบแจ้งหนี้</a> แรก",
  Cheque_Due:"ตรวจสอบเนื่องจาก:",
  Payrolls:"บัญชีเงินเดือน",
  Sandbox_Mode:"โหมดแซนด์บ็อกซ์",
  Hire:"จ้าง",
  Pay:"จ่าย",
  New:"ใหม่",
  Add:"เพิ่ม",
  Make:"ทำ",
  Time:"เวลา",
  Write:"เขียน",
  Holiday:"วันหยุด",
  Holidays:"วันหยุด",
  Next_Holiday:"วันหยุดหน้า:",
  All_Done:"ทุกอย่างเสร็จเรียบร้อย!",
  Employees:"พนักงาน",
  Payees:"ผู้รับเงิน",
  Job:"งาน",
  Jobs:"งาน",
  Invoice:"ใบแจ้งหนี้",
  Invoices:"ใบแจ้งหนี้",
  Vacations:"วันหยุด",
  Cheques:"ตรวจสอบ",
  Brand_Cheques:"แบรนด์ของคุณ",
  Payment:"การชำระเงิน",
  Enable_Payment:"เปิดใช้งานการชำระเงิน",
  Payments:"การชำระเงิน",
  Schedule:"กำหนดการ",
  Schedules:"ตารางเวลา",
  Timesheet:"ใบบันทึกเวลา",
  Timesheets:"แผ่นเวลา",
  Salary:"เงินเดือน",
  New_Address:"ที่อยู่ใหม่",
  Address_2:"ที่อยู่ (บรรทัดที่ 2)",
  _City:"เมือง",
  _State:"รัฐ / จังหวัด",
  City:"เมือง / ตำบล",
  State:"รัฐ / จังหวัด",
  Postal:"รหัสไปรษณีย์ / รหัสไปรษณีย์",
  ZIP:"ไปรษณีย์/ไปรษณีย์",
  Country:"ประเทศ",
  Addresses:"ที่อยู่",
  Required_Options:"ตัวเลือกที่จำเป็น",
  Optional_Options:"ตัวเลือกเสริม",
  Additional_Options:"ตัวเลือกเพิ่มเติม",
  Required:"ที่จำเป็น",
  Optional:"ไม่จำเป็น",
  Additional:"เพิ่มเติม",
  No_Addresses:"ไม่มีที่อยู่",
  New_Address_Word:"เพิ่ม <a routerLink='/folder/Address/New'>ที่อยู่</a> แรก",
  Select_Address:"เลือกที่อยู่",
  No_Address:"ไม่มีที่อยู่",
  Print_Cheque:"พิมพ์เช็ค",
  Print_Cheques:"พิมพ์เช็ค",
  Print_Cheque_Now:"พิมพ์ตรวจสอบทันที",
  Description:"คำอธิบาย",
  Pay_To_The_Order_Of:"ชำระเงินตามคำสั่งของ:",
  Select_Date_Range:"เลือกช่วงวันที่",
  Select_Starting_Date:"เลือกวันที่เริ่มต้น",
  Select_Ending_Date:"เลือกวันที่สิ้นสุด",
  Select_Date:"เลือกวันที่",
  Cheque_Date:"ตรวจสอบวันที่",
  Cheque_Memo:"ตรวจสอบบันทึก",
  Blank_Cheque:"เช็คเปล่า",
  Blank:"ว่างเปล่า",
  No_Cheques:"ไม่มีการตรวจสอบ",
  No_Cheques_Word:"พิมพ์ <a routerLink='/folder/Cheque/New'>เช็ค</a> ฉบับแรกของคุณ",
  Cheque_Amount_Placeholder:"0.00 ดอลลาร์สหรัฐฯ",
  View_Image:"ดูภาพ",
  View:"ดู",
  Modify:"แก้ไข",
  Delete:"ลบ",
  Cheque_Paid:"จ่าย",
  New_Deduction:"การหักเงินใหม่",
  Title:"ชื่อ",
  Frequency:"ความถี่",
  Hourly:"รายชั่วโมง",
  Daily:"รายวัน",
  Weekdays:"วันธรรมดา",
  BiWeekly:"2 สัปดาห์",
  TriWeekly:"3 สัปดาห์",
  Monthly:"รายเดือน",
  MiMonthly:"2 เดือน",
  Quarterly:"รายไตรมาส",
  Yearly:"รายปี",
  Every_Week:"ทุกสัปดาห์",
  Every_Payroll:"ทุกการจ่ายเงินเดือน",
  Every_Month:"ทุกๆเดือน",
  Annually:"เป็นประจำทุกปี",
  Always_Scheduled:"กำหนดเวลาเสมอ",
  Start_Date:"วันที่เริ่มต้น",
  End_Date:"วันที่สิ้นสุด",
  Start_Time:"เวลาเริ่มต้น",
  End_Time:"เวลาสิ้นสุด",
  Deduction_Label:"ป้ายหัก",
  Notes:"หมายเหตุ",
  Options:"ตัวเลือก",
  Enable:"เปิดใช้งาน",
  Select_Deductions:"เลือก การหักเงิน",
  Deductions:"การหักเงิน",
  No_Deductions:"ไม่มีการหักเงิน",
  No_Deductions_Word:"สร้าง <a routerLink='/folder/Deduction/New'>การหักเงิน</a> แรกของคุณ",
  New_Employee:"พนักงานใหม่",
  No_Title:"ไม่มีชื่อเรื่อง",
  _Name:"ชื่อ",
  About_Yourself:"เกี่ยวกับตัวเอง",
  Full_Name:"ชื่อเต็ม",
  Birthday:"วันเกิด",
  Email:"อีเมล",
  SMS:"ข้อความ",
  Phone:"โทรศัพท์",
  Test:"ทดสอบ",
  Call:"เรียก",
  Fax:"แฟกซ์",
  Printed_Note:"พิมพ์หมายเหตุ",
  Position:"ตำแหน่ง",
  Job_Position:"ตำแหน่งงาน",
  Select_a_Job:"เลือกงาน",
  Select_a_Salary:"เลือกเงินเดือน",
  Add_a_Deduction:"เพิ่มการหักเงิน",
  Taxes:"ภาษี",
  Add_Taxes:"เพิ่มภาษี",
  Date_of_Birth:"วันเกิด",
  Email_Address:"ที่อยู่อีเมล",
  Phone_Number:"หมายเลขโทรศัพท์",
  Phone_Call:"สายเข้า",
  Enable_on_Payroll:"เปิดใช้งานในบัญชีเงินเดือน",
  Select_Employees:"เลือกพนักงาน",
  No_Employees:"ไม่มีพนักงาน",
  No_Employees_Word:"เพิ่ม <a routerLink='/folder/Employee/New'>พนักงาน</a> ใหม่เป็นครั้งแรกของคุณ",
  No_Name:"ไม่มีชื่อ",
  Unemployeed:"ว่างงาน",
  Employeed:"ลูกจ้าง",
  Paid:"จ่าย",
  Enabled:"เปิดใช้งาน",
  Disabled:"พิการ",
  Fire:"ไฟ",
  Not_Available:"ไม่สามารถใช้ได้",
  Not_Available_Word:"พิมพ์ <a routerLink='/folder/Invoice/New'>ใบแจ้งหนี้</a>แรกของคุณและรับเงิน",
  Select_Invoices:"เลือกใบแจ้งหนี้",
  Print_Invoice_Word:"พิมพ์ <a routerLink='/folder/Invoice/New'>ใบแจ้งหนี้</a>แรกของคุณและรับเงิน",
  Invoice_Title:"ชื่อเรื่องใบแจ้งหนี้",
  Invoice_Date:"วันที่ในใบแจ้งหนี้",
  Due_Date:"วันครบกำหนด",
  New_Job:"งานใหม่",
  Details:"รายละเอียด",
  Customize:"ปรับแต่ง",
  Customize_Dashboard:"ปรับแต่งแดชบอร์ด",
  Components:"ส่วนประกอบ",
  No_Components:"ไม่มีส่วนประกอบ",
  Main_Components:"องค์ประกอบหลัก",
  Smaller_Components:"ส่วนประกอบที่เล็กลง",
  Error_Loading_Page:"เกิดข้อผิดพลาดในการโหลดหน้านี้",
  Bank_Details:"รายละเอียดธนาคาร",
  About_Your_Job:"เกี่ยวกับงานของคุณ",
  Your_Schedule:"กำหนดการของคุณ",
  Job_Title:"ชื่องาน",
  Job_Description:"รายละเอียดงาน",
  Job_Details:"รายละเอียดงาน",
  Enable_Job:"เปิดใช้งานงาน",
  Pay_Period:"งวดจ่าย",
  Perpetually_Schedule:"กำหนดการถาวร",
  Select_Jobs:"เลือกงาน",
  No_Jobs:"ไม่มีงาน",
  Add_Jobs:"เพิ่มงาน",
  No_Jobs_Word:"เพิ่ม <a routerLink='/folder/Job/New'>งาน</a>แรกในรายการ",
  Count_Employees:"job.employee_count+' พนักงาน '",
  Zero_Employees:"0 พนักงาน",
  New_Leave:"ลาใหม่",
  Leave_Name:"ฝากชื่อ",
  Paid_Leave:"การลาที่ได้รับค่าจ้าง",
  Leave_Pay:"ออกจากการชำระเงิน",
  Indefinite_Leave:"ลาไม่มีกำหนด",
  Indefinite_Payroll:"เงินเดือนไม่แน่นอน",
  Leave:"ออกจาก",
  Add_Schedules:"เพิ่มตารางเวลา",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"เปิดใช้งานการขาดงาน",
  Select_Leaves:"เลือกใบ",
  No_Leaves:"ไม่มีใบขาด",
  Leave_Of_Absence:"ออกจากการขาด",
  Leaves_Of_Absence:"ใบไม้ขาด",
  New_Leave_of_Absense:"การลาหยุดใหม่",
  No_Leaves_Word:"เพิ่ม <a routerLink='/folder/Leave/New'>ลางานแรก</a>",
  Not_Enabled:"ไม่ได้เปิดใช้งาน",
  Absences:"ขาด",
  Payee:"ผู้รับเงิน",
  New_Payee:"ผู้รับเงินรายใหม่",
  Payee_Identifier:"ตัวระบุผู้รับเงิน",
  Payee_Name:"ชื่อผู้รับเงิน",
  Payee_Title:"ชื่อผู้รับเงิน",
  Payment_Memo:"บันทึกการชำระเงิน",
  Select_Payees:"เลือกผู้รับเงิน",
  No_Payees:"ไม่มีผู้รับเงิน",
  Add_Payee_Note:"เพิ่ม <a routerLink='/folder/Payee/New'>ผู้รับเงิน</a> แรก",
  New_Payees:"ผู้รับเงินรายใหม่",
  About_The_Payment_Schedule:"กำหนดการชำระเงิน",
  Your_Payroll_Schedule:"บัญชีเงินเดือนอัตโนมัติ",
  New_Payment:"การชำระเงินใหม่",
  Identifier:"ตัวระบุ",
  Selected:"เลือกแล้ว",
  Select:"เลือก",
  Memo:"บันทึก",
  Payment_Date:"วันจ่าย",
  Mark_as_Paid:"ทำเครื่องหมายว่าชำระแล้ว",
  Select_Payments:"เลือก การชำระเงิน",
  No_Payments:"ไม่มีการชำระเงิน",
  No_Payments_Word:"สร้าง <a routerLink='/folder/Payment/New'>การชำระเงิน</a> รายการแรก",
  Create_Payroll:"สร้างบัญชีเงินเดือน",
  Properties:"คุณสมบัติ",
  Payroll_Title:"ชื่อเรื่องเงินเดือน",
  Payroll_Notes:"บันทึกเงินเดือน",
  Payroll_Setup:"การตั้งค่าบัญชีเงินเดือน",
  Tabulate_Payments:"ตารางการชำระเงิน",
  Tabulate:"ตาราง",
  By:"โดย:",
  Payments_By:"ชำระเงินโดย",
  Timesheets_And_Schedules:"แผ่นเวลาและตารางเวลา",
  Both:"ทั้งคู่",
  Items:"รายการ",
  Add_Payees:"เพิ่มผู้รับเงิน",
  Add_Account:"เพิ่มบัญชี",
  Enable_Account:"เปิดใช้งานบัญชี",
  Enable_Payroll:"เปิดใช้งานบัญชีเงินเดือน",
  Print_Payroll:"พิมพ์บัญชีเงินเดือน",
  No_Payrolls:"ไม่มีบัญชีเงินเดือน",
  No_Payroll_Word:"สร้าง <a routerLink='/folder/Payroll/New'>บัญชีเงินเดือน</a>แรกของคุณ",
  View_more:"ดูเพิ่มเติม",
  Less:"น้อย",
  Add_Payroll:"เพิ่มบัญชีเงินเดือน",
  Select_Payroll:"เลือกบัญชีเงินเดือน",
  About_Your_Salary:"เกี่ยวกับเงินเดือนของคุณ",
  Add_Salaries:"เพิ่มเงินเดือน",
  Add_Salary:"เพิ่มเงินเดือน",
  Salaries:"เงินเดือน",
  No_Salaries:"ไม่มีเงินเดือน",
  No_Salaries_Word:"เพิ่ม <a routerLink='/folder/Salary/New'>เงินเดือนแรก</a>แรก",
  Select_Salaries:"เลือก เงินเดือน",
  New_Salary:"เงินเดือนใหม่",
  Salary_Name:"ตัวระบุเงินเดือน",
  Enable_Salary:"เปิดใช้งานเงินเดือน",
  Salary_Amount:"จำนวนเงินเดือน",
  New_Schedule:"กำหนดการใหม่",
  Schedule_Title:"ชื่อกำหนดการ",
  Add_Address:"เพิ่มที่อยู่",
  Repeat:"ทำซ้ำ",
  Design:"ออกแบบ",
  Edit_Design:"แก้ไขการออกแบบ",
  Edit_this_Design:"แก้ไขการออกแบบนี้",
  Repeat_Payment:"ชำระเงินซ้ำ",
  Enable_Schedule:"เปิดใช้งานกำหนดการ",
  Never:"ไม่เคย",
  Select_Schedule:"เลือกกำหนดการ",
  No_Schedules:"ไม่มีกำหนดการ",
  No_Schedules_Word:"สร้าง<a routerLink='/folder/Schedule/New'>กำหนดการ</a>แรก",
  New_Administrator:"ผู้ดูแลระบบใหม่",
  Username:"ชื่อผู้ใช้",
  First_Name:"ชื่อจริง",
  Last_Name:"นามสกุล",
  Add_an_Address:"เพิ่มที่อยู่",
  Payment_Limit:"วงเงินต่อการชำระเงิน",
  Total_Limit:"วงเงินรวม",
  Select_Accounts:"เลือกบัญชี",
  No_Administrators:"ไม่มีผู้ดูแลระบบ",
  No_Administrators_Word:"สร้าง <a routerLink='/folder/Administrator/New'>บัญชีผู้ดูแลระบบ</a> บัญชีแรก",
  New_Administrators_Word:"เพิ่ม <a routerLink='/folder/Administrator/New'>ผู้ดูแลระบบ</a> คนแรก",
  Cloud:"คลาวด์",
  Backup:"การสำรองข้อมูล",
  Enable_iCloud:"เปิดใช้งาน iCloud",
  Enable_Google_Drive:"เปิดใช้งาน Google ไดรฟ์",
  Enable_Microsoft_OneDrive:"เปิดใช้งาน Microsoft OneDrive",
  Automatically_Backup:"สำรองข้อมูลโดยอัตโนมัติ",
  FTP_Settings:"การตั้งค่า FTP",
  URL_File_Prompt:"โปรดระบุตำแหน่งสำหรับไฟล์ .xls / .xlsx / .json ที่จะนำเข้า:",
  URL_SQL_Prompt:"โปรดระบุตำแหน่งของไฟล์ SQLite ที่จะนำเข้า:",
  FTP_Backup:"การสำรองข้อมูล FTP",
  FTP_Import:"FTP นำเข้า",
  FTP:"เอฟทีพี",
  SFTP:"เอสเอฟทีพี",
  Host:"เจ้าภาพ",
  Port:"ท่าเรือ",
  Path:"เส้นทาง",
  Data_Path:"เส้นทางข้อมูล",
  Enable_FTP_Account:"เปิดใช้งานบัญชี FTP",
  HostnameIP:"ชื่อโฮสต์",
  Password:"รหัสผ่าน",
  Connection_Port:"พอร์ทเชื่อมต่อ",
  Enable_MySQL_Database:"เปิดใช้งานฐานข้อมูล MySQL",
  Log:"บันทึก",
  Reset:"รีเซ็ต",
  Erase:"ลบ",
  Export:"ส่งออก",
  Database:"ฐานข้อมูล",
  Upload_CSV:"อัปโหลด CSV",
  Download_CSV:"ดาวน์โหลด CSV",
  SQL_Database:"URL ของ SQL",
  MySQL_Database:"มายเอสคิวแอล",
  MySQL_Backup:"การสำรองข้อมูล MySQL",
  Internal_Notes:"หมายเหตุภายใน",
  Root_Path:"เส้นทางรูท",
  Select_Backup:"เลือกสำรองข้อมูล",
  Add_New_Backup:"เพิ่มข้อมูลสำรองใหม่",
  First_Backup:"ตั้งค่าการสำรองข้อมูลครั้งแรก...",
  Backups:"การสำรองข้อมูล",
  Add_Backup:"เพิ่มการสำรองข้อมูล",
  No_Backups:"ไม่พบข้อมูลสำรอง",
  Select_Backup_Type:"เลือกประเภทการสำรองข้อมูลที่คุณต้องการตั้งค่า...",
  Backup_Type:"ประเภทการสำรองข้อมูล",
  FTP_Drive:"ไดรฟ์ FTP",
  URL_Location:"URL",
  Apple_iCloud:"ไอคลาว",
  Google_Drive:"ขับ",
  Microsoft_OneDrive:"ขับ",
  Import_Fields:"ฟิลด์นำเข้า",
  Import_Fields_Word:"ใช้ส่วนนี้เพื่อเลือกข้อมูล <a routerLink='/folder/Holiday/New'>นำเข้า</a>",
  Upload:"ที่อัพโหลด",
  Download:"ดาวน์โหลด",
  Download_JSON:"ดาวน์โหลดเป็นข้อมูล JSON",
  Download_SQL:"ดาวน์โหลดเป็นไฟล์ SQL",
  New_Bank:"ธนาคารใหม่",
  New_Account:"บัญชีใหม่",
  New_Bank_Account:"บัญชีธนาคารใหม่",
  Upload_Image:"อัปโหลดรูปภาพ",
  Bank_Name:"ชื่อธนาคาร",
  Bank_Address:"ที่อยู่ธนาคาร",
  Branch_Address:"ที่อยู่สาขา",
  Address_on_Cheque:"ที่อยู่",
  Cheque_Numbers:"ตรวจสอบหมายเลข",
  Cheque_Details:"ตรวจสอบรายละเอียด",
  Bank_Logo:"โลโก้ธนาคาร",
  Cheque_ID:"เช็คไอดี",
  Starting_Cheque_ID:"รหัสตรวจสอบเริ่มต้น",
  Transit_Number:"หมายเลขการขนส่ง",
  Institution_Number:"หมายเลขสถาบัน",
  Designation_Number:"หมายเลขประจำตัว",
  Account_Number:"หมายเลขบัญชี",
  Limits:"ขีด จำกัด",
  Default_Limits:"ขีดจำกัดเริ่มต้น",
  Over_Limit:"เกินกำหนด",
  Under_Limit:"ภายใต้ขีดจำกัด",
  Payroll_Limit:"วงเงินเงินเดือน",
  Enable_Bank_Account:"เปิดใช้งานบัญชีธนาคาร",
  Select_Account:"เลือกบัญชี",
  No_Bank_Account:"ไม่มีบัญชีธนาคาร",
  No_Bank_Account_Word:"เพิ่ม <a routerLink='/folder/Accounts/New'>บัญชีธนาคาร</a>แรก",
  Bank_Accounts:"บัญชีธนาคาร",
  No_Accounts:"ไม่มีบัญชี",
  No_Accounts_Note:"เพิ่ม <a routerLink='/folder/Accounts/New'>บัญชีธนาคาร</a>แรก",
  Cheque_Designer:"ตรวจสอบตัวออกแบบ",
  Cheque_Design:"ตรวจสอบการออกแบบ",
  Select_Design:"เลือกการออกแบบ",
  Cheque_Designs:"ตรวจสอบการออกแบบ",
  No_Cheque_Designs:"ไม่มีการออกแบบตรวจสอบ",
  No_Cheque_Designs_Word:"สร้าง<a routerLink='/folder/Settings/Cheque/Design/New'>การออกแบบเช็ค</a>ของคุณเอง",
  Set_Default:"ตั้งเป็นค่าเริ่มต้น",
  Default:"ค่าเริ่มต้น",
  Remove:"ลบ",
  Folder:"โฟลเดอร์",
  Edit:"แก้ไข",
  LoadingDots:"กำลังโหลด...",
  Add_a_New_File:"เพิ่ม <a href='#' (click)='add()'>ไฟล์ใหม่</a> ลงใน",
  this_folder:"โฟลเดอร์นี้",
  FTP_Backup_Settings:"การตั้งค่าการสำรองข้อมูล FTP",
  Secure_File_Transfer:"การถ่ายโอนไฟล์ที่ปลอดภัย",
  New_Holiday:"วันหยุดใหม่",
  Add_Holiday:"เพิ่มวันหยุด",
  Holiday_Name:"ชื่อวันหยุด",
  Additional_Pay:"จ่ายเพิ่ม",
  Enable_Holiday:"เปิดใช้งานวันหยุด",
  Select_Holidays:"เลือกวันหยุด",
  No_Holidays:"ไม่มีวันหยุด",
  No_Holidays_Word:"เพิ่ม <a routerLink='/folder/Holiday/New'>วันหยุดนักขัตฤกษ์</a> รายการแรก",
  New_Import:"นำเข้าใหม่",
  Import_Data:"นำเข้าข้อมูล",
  Import_Data_Word:"เลือกประเภทของไฟล์หรือวิธีการอัปโหลดที่คุณเลือก<br /> คุณจะสามารถเลือกฟิลด์ที่นำเข้าในไฟล์ที่สอดคล้องกับพารามิเตอร์ใดๆ ในแอปหลังจากอัปโหลดไฟล์ที่รองรับ",
  Import_File:"นำเข้าไฟล์",
  Link_To_File:"เชื่อมโยงไปยังไฟล์",
  Select_File:"เลือกไฟล์",
  New_Moderator:"ผู้ดูแลคนใหม่",
  Allow_Moderation:"อนุญาตให้มีการกลั่นกรอง",
  Create_Paycheques:"สร้าง Paycheques",
  Edit_Paycheques_and_Data:"แก้ไข Paycheques และข้อมูล",
  Destroy_Data_and_Paycheques:"ทำลายข้อมูลและ Paycheques",
  Bonus_Percentage:"Paycheque เปอร์เซ็นต์",
  Fixed_Amount:"จำนวนเงินคงที่",
  Select_Moderator:"เลือกผู้ดูแล",
  No_Moderators:"ไม่มีผู้ดูแล",
  Moderators:"ผู้ดูแล",
  Moderator_Account:"สร้าง<a routerLink='/folder/Administrator/New'>บัญชีผู้ดูแล</a>บัญชีแรก",
  No_Moderators_Word:"เพิ่ม <a routerLink='/folder/Administrator/New'>ผู้ดูแล</a> คนแรก",
  Defaults:"ค่าเริ่มต้น",
  Provide_Us_Info:"ให้ข้อมูล",
  Setup_Your_Printer:"ตั้งค่าเครื่องพิมพ์ของคุณ",
  Your_Company:"เกี่ยวกับบริษัทของคุณ",
  Company_Name:"ชื่อ บริษัท",
  Currency:"สกุลเงิน",
  Default_Currency:"สกุลเงินเริ่มต้น",
  Base_Monthly_Income:"รายได้ต่อเดือน",
  Protection:"การป้องกัน",
  App_Protection:"การป้องกันแอป",
  PIN_Code_Protection:"การป้องกันรหัส PIN",
  App_Protection_Word:"เปิดใช้งานวิธีการรักษาความปลอดภัยที่ช่วยปกป้องบัญชีของคุณ",
  PIN_Code:"รหัสพิน",
  Change_PIN:"เปลี่ยนรหัส PIN",
  New_Password:"รหัสผ่านใหม่",
  Geofence_Protection:"การป้องกันรั้วทางภูมิศาสตร์",
  Geofence_Area:"กำหนดพื้นที่",
  Distance:"ระยะทาง",
  Setup_Now:"ตั้งค่าทันที",
  Mile:"ไมล์",
  Km:"กม",
  m:"ม",
  Facial_Recognition:"การจดจำใบหน้า",
  Face:"ใบหน้า",
  Setup:"ติดตั้ง",
  Label:"ฉลาก",
  Password_Protection:"การป้องกันรหัสผ่าน",
  Modify_Password:"แก้ไขรหัสผ่าน",
  New_Tax_Entry:"รายการภาษีใหม่",
  New_Tax:"ภาษีใหม่",
  Tax_Label:"ป้ายภาษี",
  Perpetually_Enabled:"เปิดใช้งานตลอดเวลา",
  Select_Taxes:"เลือกภาษี",
  Tax_Deductions:"ลดหย่อนภาษี",
  No_Tax_Deductions:"ไม่มีการลดหย่อนภาษี",
  No_Tax_Deductions_Word:"เพิ่มการหัก <a routerLink='/folder/Tax/New'>ภาษี</a> ครั้งแรก",
  New_Timer:"ตัวจับเวลาใหม่",
  Start:"เริ่ม",
  Stop:"หยุด",
  Start_Timer:"เริ่มจับเวลา",
  Stop_Timer:"หยุดจับเวลา",
  Timer_Active:"ตัวจับเวลาทำงานอยู่",
  Timer:"ตัวจับเวลา:",
  Timer_Running:"ตัวจับเวลา: (ทำงาน)",
  Save_Timer:"บันทึกตัวจับเวลา",
  New_Timesheet:"ใบบันทึกเวลาใหม่",
  Select_Timesheets:"เลือกใบบันทึกเวลา",
  Work_Notes:"บันทึกการทำงาน",
  Entry_Title:"ชื่อรายการ",
  No_Timesheets:"ไม่มีไทม์ชีท",
  No_Timesheets_Word:"เพิ่ม <a routerLink='/folder/Timesheet/New'>ใบบันทึกเวลา</a>แรก",
  Timesheet_Submitted:"ส่งใบบันทึกเวลาแล้ว",
  Timesheet_Congrats:"ยินดีด้วย! ส่งใบบันทึกเวลาของคุณเรียบร้อยแล้ว ขอบคุณ!",
  Timesheet_Copy:"หากต้องการรับสำเนาเอกสารของคุณ โปรดแจ้งที่อยู่อีเมลและ/หรือหมายเลขโทรศัพท์มือถือของคุณให้เราทราบ",
  Submit:"ส่ง",
  Allow_Notifications:"อนุญาตการแจ้งเตือน",
  Untitled_Entry:"รายการใหม่",
  Untitled_Bank:"ธนาคารที่ไม่มีชื่อ",
  Timesheet_Entry:"รายการบันทึกเวลา",
  Work_Description:"ลักษณะการทำงาน",
  Enable_Timesheet:"เปิดใช้งานใบบันทึกเวลา",
  Submit_Timesheet:"ส่งใบบันทึกเวลา",
  Vacation:"วันหยุด",
  New_Vacation:"วันหยุดใหม่",
  Vacation_Name:"ชื่อวันหยุด",
  Paid_Vacation:"วันหยุดจ่าย",
  Vacation_Pay:"จ่ายวันหยุด",
  Enable_Vacation:"เปิดใช้งานวันหยุด",
  Select_Vacations:"เลือกวันหยุด",
  No_Vacations:"ไม่มีวันหยุด",
  No_Vacations_Word:"สร้างรายการ <a routerLink='/folder/Vacation/New'>วันหยุด</a> รายการแรก",
  Payroll_Schedule:"ตารางการจ่ายเงินเดือน",
  Next_Payroll:"เงินเดือนถัดไป:",
  Upcoming_Payroll:"เงินเดือนที่จะเกิดขึ้น",
  No_Upcoming_Payroll:"ไม่มีการจ่ายเงินเดือนที่จะเกิดขึ้น",
  Address_Book:"สมุดที่อยู่",
  Archived_Documents:"เอกสารที่เก็บถาวร",
  Dev_Mode:"แอปพลิเคชันในโหมดการพัฒนา",
  Administrators:"ผู้ดูแลระบบ",
  Privacy:"ความเป็นส่วนตัว",
  None:"ไม่มี",
  Select_Signature:"เลือกลายเซ็น",
  No_Signatures:"ไม่มีลายเซ็น",
  No_Signatures_Word:"เพิ่ม <a routerLink='/folder/Signature/New'>ลายเซ็น</a> รายการแรก",
  Repeat_Indefinitely:"ทำซ้ำไปเรื่อย ๆ",
  Sign:"เข้าสู่ระบบ",
  Sign_Here:"ลงชื่อที่นี่",
  Date_Signed:"วันที่ลงนาม",
  Signature_Pad:"ซิกเนเจอร์แพด",
  Account_Holder:"ผู้ถือบัญชี",
  Account_Properties:"คุณสมบัติบัญชี",
  Name_On_Cheque:"ชื่อบนเช็ค",
  Server_Hostname:"ชื่อโฮสต์เซิร์ฟเวอร์ / IP",
  Printers:"เครื่องพิมพ์",
  No_Printers:"ไม่มีเครื่องพิมพ์",
  Printer_Exists:"มีเครื่องพิมพ์ชื่อนี้อยู่แล้ว",
  No_Printers_Word:"เพิ่ม<a routerLink='/folder/Printer/New'>เครื่องพิมพ์</a>เครื่องแรก",
  No_Printer_Alert:"ไม่มีการกำหนดเครื่องพิมพ์ คุณต้องการตั้งค่าเครื่องพิมพ์หรือไม่",
  Add_Printer:"เพิ่มเครื่องพิมพ์",
  New_Printer:"เครื่องพิมพ์ใหม่",
  Printer_Hostname:"ชื่อโฮสต์เครื่องพิมพ์ / IP",
  Printer_Label:"ฉลากเครื่องพิมพ์",
  Printer_Protocol:"โปรโตคอลเครื่องพิมพ์",
  Protocol:"มาตรการ",
  Email_Print:"อีเมล",
  AirPrint:"แอร์พริ้นท์",
  IPP:"ไอพีพี",
  LPD:"หจก",
  HPJetDirect:"เบ้า",
  Print_Job:"งานพิมพ์",
  Printed:"พิมพ์",
  Not_Printed:"ไม่พิมพ์",
  Print_Jobs:"งานพิมพ์",
  Print_Queue:"คิวการพิมพ์",
  No_Print_Jobs:"ไม่มีงานพิมพ์",
  No_Prints:"สร้าง <a routerLink='/folder/Cheque/New'>ตรวจสอบ</a> ใหม่เพื่อพิมพ์",
  Prints:"พิมพ์",
  Find_Printer:"ค้นหาเครื่องพิมพ์",
  Find_AirPrint_Devices:"ค้นหาอุปกรณ์ AirPrint",
  Select_Printer:"เลือกเครื่องพิมพ์",
  System_UI:"UI ของระบบ",
  Printer:"เครื่องพิมพ์",
  Status:"สถานะ",
  Preview:"ดูตัวอย่าง",
  Enable_Print_Job:"เปิดใช้งานงานพิมพ์",
  Queue_Weight:"น้ำหนักคิว",
  Unlimited:"ไม่ จำกัด",
  Show_Advanced_Printer_Options:"แสดงตัวเลือกเครื่องพิมพ์ขั้นสูง",
  Advanced:"ขั้นสูง",
  Location:"ที่ตั้ง",
  Note:"บันทึก",
  Queue_Name:"ชื่อคิว",
  Pages_Printed_Limit:"ขีดจำกัดการพิมพ์หน้า",
  MultiPage_Idle_Time:"เวลารอหลายหน้า (s)",
  Page_Count_Limit:"ขีดจำกัดจำนวนหน้า",
  Page_Orientation:"การวางแนวหน้า",
  Portrait:"ภาพเหมือน",
  Landscape:"ภูมิประเทศ",
  Duplex:"ดูเพล็กซ์",
  Double_Sided_Printing:"สองด้าน",
  Duplex_Mode:"โหมดดูเพล็กซ์",
  Duplex_Short:"สั้น",
  Duplex_Long:"ยาว",
  Duplex_None:"ไม่มี",
  Color_And_Quality:"สีและคุณภาพ",
  Monochrome:"ขาวดำ",
  Photo_Quality_Prints:"คุณภาพของภาพพิมพ์",
  Printer_Email:"อีเมลเครื่องพิมพ์",
  Automatically_Downsize:"ลดขนาดโดยอัตโนมัติ",
  Paper:"กระดาษ",
  Paper_Name:"ชื่อกระดาษ",
  Paper_Width:"ความกว้างกระดาษ",
  Paper_Height:"ความสูงของกระดาษ",
  Paper_Autocut_Length:"ความยาวตัดกระดาษอัตโนมัติ",
  Margins:"ระยะขอบ",
  Page_Margins:"ระยะขอบของหน้า",
  Page_Margin_Top:"ระยะขอบของหน้าบนสุด",
  Page_Margin_Right:"ระยะขอบของหน้าขวา",
  Page_Margin_Bottom:"ขอบล่างของหน้า",
  Page_Margin_Left:"ระยะขอบหน้าซ้าย",
  Add_Employees:"เพิ่มพนักงาน",
  Header:"หัวข้อ",
  Print_A_Page_Header:"พิมพ์ส่วนหัวของหน้า",
  Header_Label:"ป้ายกำกับส่วนหัว",
  Header_Page_Index:"ดัชนีหน้าส่วนหัว",
  Header_Font_Name:"แบบอักษรส่วนหัว",
  Select_Font:"เลือกแบบอักษร",
  Font_Selector:"ตัวเลือกแบบอักษร",
  Header_Font_Size:"แบบอักษรส่วนหัว",
  Header_Bold:"ส่วนหัวตัวหนา",
  Header_Italic:"ตัวเอียงส่วนหัว",
  Header_Alignment:"การจัดตำแหน่งส่วนหัว",
  Left:"ซ้าย",
  Center:"ศูนย์",
  Right:"ขวา",
  Justified:"เป็นธรรม",
  Header_Font_Color:"สีส่วนหัว",
  Select_Color:"เลือกสี",
  Footer:"ส่วนท้าย",
  Print_A_Page_Footer:"พิมพ์ส่วนท้ายของหน้า",
  Footer_Label:"ป้ายกำกับส่วนท้าย",
  Footer_Page_Index:"ดัชนีหน้าท้ายกระดาษ",
  Footer_Font_Name:"แบบอักษรท้ายกระดาษ",
  Fonts:"แบบอักษร",
  Done:"เสร็จแล้ว",
  Select_Fonts:"เลือกแบบอักษร",
  Footer_Font_Size:"ขนาดส่วนท้าย",
  Footer_Bold:"ส่วนท้ายเป็นตัวหนา",
  Footer_Italic:"ตัวเอียงส่วนท้าย",
  Footer_Alignment:"การจัดตำแหน่งส่วนท้าย",
  Footer_Font_Color:"สีส่วนท้าย",
  Page_Copies:"สำเนาหน้า",
  Enable_Printer:"เปิดใช้งานเครื่องพิมพ์",
  Remote_Logging:"การบันทึกระยะไกล",
  Log_Server:"ล็อกเซิร์ฟเวอร์",
  Encryption:"การเข้ารหัส",
  Random_Key:"คีย์สุ่ม",
  Encryption_Key:"คีย์เข้ารหัส",
  Cheques_Webserver:"ฐานข้อมูลที่กำหนดเอง",
  Learn_How:"เรียนรู้วิธี",
  Signature:"ลายเซ็น",
  Default_Printer_Unit:"นิ้ว/ซม./มม./(pt)",
  View_Signature:"ดูลายเซ็น",
  Printed_Signature:"ลายเซ็นที่พิมพ์",
  Digitally_Sign:"เซ็นชื่อแบบดิจิทัล",
  Digital_Signature:"ลายเซ็นดิจิทัล",
  Add_Signature:"เพิ่มลายเซ็น",
  Add_Your_Signature:"เพิ่มลายเซ็นของคุณ",
  Enable_Signature:"เปิดใช้งานลายเซ็น",
  Digitally_Signed:"เซ็นชื่อแบบดิจิทัล",
  Insert_Error:"ไม่สามารถบันทึกเช็คได้เนื่องจากปัญหาเกี่ยวกับฐานข้อมูล",
  Delete_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูลนี้",
  Discard_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการยกเลิกข้อมูลนี้",
  Discard_Bank_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการยกเลิกบัญชีนี้",
  Discard_Printer_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการยกเลิกเครื่องพิมพ์นี้",
  Delete_Bonus_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบโบนัสนี้",
  Delete_Invoice_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบใบแจ้งหนี้นี้",
  Generated_Cheque:"สร้างเช็ค",
  Generated_Invoice:"ใบแจ้งหนี้ที่สร้างขึ้น",
  Schedule_Start_Time:"กำหนดการเริ่มต้น",
  Schedule_End_Time:"กำหนดเวลาสิ้นสุด",
  New_Call:"โทรใหม่",
  No_Contacts:"ไม่มีรายชื่อ",
  No_Contacts_Word:"ผู้ดูแลระบบ ผู้ดูแล พนักงาน และผู้รับเงินจะปรากฏเป็นผู้ติดต่อ",
  PDF_Subject:"การเงิน",
  PDF_Keywords:"เช็คเงินเดือน payroll PDF เช็ค",
  Printer_Setup:"การตั้งค่าเครื่องพิมพ์",
  Printer_Selection:"การเลือกเครื่องพิมพ์",
  New_Fax:"โทรสารใหม่",
  New_Fax_Message:"ข้อความแฟกซ์ใหม่",
  Fax_Machine:"เครื่องโทรสาร",
  Fax_Name:"ชื่อแฟกซ์",
  Fax_Email:"แฟกซ์อีเมล์",
  Fax_Number:"หมายเลขโทรสาร",
  Contents:"เนื้อหา",
  Fax_Body:"เนื้อความของหน้า",
  Header_Word:"หัวข้อ:",
  Header_Text:"ข้อความส่วนหัว",
  Include_Header:"รวมส่วนหัว",
  Include_Footer:"รวมส่วนท้าย",
  Footer_Word:"ส่วนท้าย:",
  Footer_Text:"ข้อความส่วนท้าย",
  Attach_a_Cheque:"แนบเช็ค",
  Add_Deduction:"เพิ่มการหักเงิน",
  Enable_Fax:"ส่งแฟกซ์",
  Select_Fax:"เลือก แฟกซ์",
  No_Faxes:"ไม่มีแฟกซ์",
  Faxes:"โทรสาร",
  Save_and_Send:"ส่งแฟกซ์",
  Save_and_Pay:"บันทึกและชำระเงิน",
  WARNING:"คำเตือน:",
  Remember:"จดจำ",
  Printing:"การพิมพ์",
  Printing_Complete:"การพิมพ์เสร็จสมบูรณ์!\n\n",
  of:"ของ",
  There_Were:"มี",
  Successful_Prints:"พิมพ์สำเร็จและ",
  Unsuccessful_Prints:"พิมพ์ไม่สำเร็จ.",
  PrinterError:"ขอโทษ! มีข้อผิดพลาด",
  Printing_:"กำลังพิมพ์...",
  PrinterSuccess:"พิมพ์เอกสารเรียบร้อยแล้ว",
  PrintersSuccess:"เอกสารพิมพ์สำเร็จ",
  New_Message:"ข้อความใหม่",
  New_Messages:"ข้อความใหม่",
  Read_Message:"อ่านข้อความ",
  Write_Message:"เขียนข้อความ",
  Send_Message:"ส่งข้อความ",
  Subject:"เรื่อง",
  Message:"ข้อความ",
  Writing:"การเขียน...",
  Send:"ส่ง",
  Set_Date:"ตั้งวันที่",
  Set_Time:"ตั้งเวลา",
  Recieve:"รับ",
  Set_as_Default:"ตั้งเป็นค่าเริ่มต้น",
  Default_Account:"บัญชีเริ่มต้น",
  Default_Design:"การออกแบบเริ่มต้น",
  Multiple_Cheques:"สามเช็ค",
  Account_Mode:"โหมดบัญชี",
  Multiple_Cheques_Description:"ตรวจสอบสามครั้งต่อหน้า",
  Check_and_Table:"ตรวจสอบ & ตาราง",
  Check_including_Table:"ตรวจสอบกับตารางบัญชี",
  Check_Mailer:"ตรวจสอบจดหมาย",
  Check_Mailer_Window:"ตรวจสอบกับหน้าต่างที่อยู่",
  DocuGard_Table_Top:"เช็ค & ตารางขนาดใหญ่",
  DocuGard_Table_Middle:"เช็ค & โต๊ะขนาดใหญ่ (กลาง)",
  DocuGard_Table_Bottom:"เช็ค & ตารางขนาดใหญ่ (ด้านล่าง)",
  DocuGard_Mailer_Top:"เช็คเมล์ขนาดใหญ่",
  DocuGard_Mailer_Middle:"ไปรษณีย์เช็คขนาดใหญ่ (กลาง)",
  DocuGard_Mailer_Bottom:"จดหมายเช็คขนาดใหญ่ (ด้านล่าง)",
  DocuGard_Three_Cheques:"เช็คใหญ่สามใบ",
  DocuGard_Cheque_Top:"ตรวจสอบขนาดใหญ่เดียว",
  DocuGard_Cheque_Middle:"เช็คใหญ่ (กลาง)",
  DocuGard_Cheque_Bottom:"ตรวจสอบขนาดใหญ่ (ด้านล่าง)",
  DocuGard_Three_Cheques_Window:"ตรวจสอบสามรายการในหน้าเดียว",
  DocuGard_Table_Top_Window:"ตารางตรวจสอบและรายได้",
  DocuGard_Table_Middle_Window:"ตารางตรวจสอบและรายได้",
  DocuGard_Table_Bottom_Window:"ตารางตรวจสอบและรายได้",
  DocuGard_Mailer_Top_Window:"เช็ค ตาราง และที่อยู่",
  DocuGard_Mailer_Middle_Window:"เช็ค ตาราง และที่อยู่",
  DocuGard_Mailer_Bottom_Window:"เช็ค ตาราง และที่อยู่",
  DocuGard_Cheque_Top_Window:"ตรวจสอบกระดาษที่ปลอดภัย",
  DocuGard_Cheque_Middle_Window:"ตรวจสอบกระดาษที่ปลอดภัย",
  DocuGard_Cheque_Bottom_Window:"ตรวจสอบกระดาษที่ปลอดภัย",
  Basic_Cheque:"ตรวจสอบเดี่ยว",
  Basic_Cheque_Print:"พิมพ์เช็คเดียว",
  Error_Setting_As_Paid:"เกิดข้อผิดพลาดในการตั้งค่าเป็นชำระแล้ว",
  Add_Attachment:"เพิ่มไฟล์แนบ",
  PrinterUnavailable:"เครื่องพิมพ์ไม่พร้อมใช้งาน",
  CreditCardComponent:"การ์ด",
  PaypalComponent:"เพย์พาล",
  InteracComponent:"อินเตอร์แอค",
  BitcoinComponent:"บิตคอยน์",
  New_Deposit:"ฝากใหม่",
  Deposits:"เงินฝาก",
  No_Deposits:"ไม่มีเงินฝาก",
  Credit_Card_Deposit:"บัตรเครดิต",
  New_Credit_Card_Deposit_Message:"เงินฝากบัตรเครดิต",
  New_BitCoin_Deposit:"บิตคอยน์",
  New_BitCoin_Deposit_Message:"เงินฝาก BitCoin",
  New_Interac_Deposit:"อินเตอร์แอค",
  New_Interac_Deposit_Message:"Interac โอน",
  Payments_Limit:"วงเงินการชำระเงิน",
  No_Payment_Limit:"ไม่จำกัดการชำระเงิน",
  PayPal_Deposit:"เพย์พาล",
  PayPal_Deposit_Message:"เงินฝากเพย์พาล",
  No_Deposits_Word:"เพิ่มบริษัทแรก <a routerLink='/folder/Deposit/New'>เงินฝาก</a>",
  No_Documents_Specified:"ไม่มีการระบุเอกสารสำหรับการพิมพ์",
  No_Printers_Added:"ไม่พบเครื่องพิมพ์ ไปที่ การตั้งค่า > เครื่องพิมพ์ เพื่อเพิ่ม",
  DB_Erase_Prompt:"ลบฐานข้อมูลทั้งหมดโดยสมบูรณ์หรือไม่ คำเตือน: คุณจะสูญเสียข้อมูลที่เก็บไว้ทั้งหมด!",
  Console_Warning:"อย่าวางข้อความใดๆ ลงในคอนโซลนี้ คุณอาจทำให้ตัวคุณเองและ/หรือบริษัทของคุณตกอยู่ในความเสี่ยงร้ายแรง",
  No_Faxes_Word:"สร้าง <a routerLink='/folder/Fax/New'>แฟกซ์</a> แรก",
  Cheque_Delete_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบเช็คนี้",
  Design_Delete_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบการออกแบบนี้",
  Cheque_Pay_Confirmation:"ทำเครื่องหมายเช็คนี้ว่าจ่ายแล้ว? จะไม่ปรากฏในคิวการพิมพ์",
  Payment_Pay_Confirmation:"ทำเครื่องหมายการชำระเงินนี้ว่าชำระเงินแล้วหรือไม่ จะไม่ปรากฏในคิวตรวจสอบ",
  Delete_Deduction_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบการหักเงินนี้",
  Delete_Job_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบงานนี้",
  Delete_Timesheet_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบไทม์ชีทนี้",
  Delete_Schedule_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบกำหนดการนี้",
  Delete_Setting_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการรีเซ็ตการตั้งค่านี้",
  Delete_Fax_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบแฟกซ์นี้",
  Delete_File_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบไฟล์นี้",
  Delete_Vacation_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบวันหยุดนี้",
  Delete_Printer_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบเครื่องพิมพ์นี้",
  Remove_Design_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบการออกแบบนี้",
  Delete_Payroll_Confirmation:"คุณแน่ใจหรือไม่ว่าต้องการลบบัญชีเงินเดือนนี้",
  Send_Fax_Email_Confirmation:"คุณต้องการแฟกซ์และส่งอีเมลเอกสารเหล่านี้หรือไม่?",
  Send_Email_Confirmation:"คุณต้องการส่งอีเมลเอกสารนี้หรือไม่",
  Send_Fax_Confirmation:"คุณต้องการแฟกซ์เอกสารนี้หรือไม่?",
  Error_Generating_PDF:"ขอโทษ. มีข้อผิดพลาดในการสร้างเอกสารนี้",
  PDF_Error_Saving_Image:"ขอโทษ. เกิดข้อผิดพลาดในการบันทึกภาพ PDF ของเอกสารนี้",
  Test_Printer_Confirmation:"คุณต้องการพิมพ์หน้าทดสอบบนเครื่องพิมพ์นี้หรือไม่?",
  Save_Timesheet_Prompt:"โปรดเพิ่ม 'ชื่อเรื่อง' หรือกด 'เริ่มจับเวลา' เพื่อบันทึก",
  Remove_Geofence_Prompt:"คุณแน่ใจหรือไม่ว่าต้องการลบตำแหน่งของ geo-fence นี้",
  Delete_Employee_Confirmation:"คุณแน่ใจหรือว่าต้องการลบ",
  Fire_Employee_Confirmation:"คุณแน่ใจหรือว่าต้องการยิง"
}