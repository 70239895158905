export const My = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"မူပိုင်ခွင့် & မိတ္တူ; ၂၀၂၃",
  black:"အနက်ရောင်",
  green:"အစိမ်းရောင်",
  gold:"ရွှေ",
  blue:"အပြာ",
  brown:"အညို",
  purple:"ခရမ်းရောင်",
  pink:"ပန်းရောင်",
  red:"အနီေရာင်",
  Swatches:"အကွက်များ",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"အသွေး",
  Saturation:"ရွှဲ",
  Lightness:"ပေါ့ပါးမှု",
  Upgrade_To_Pro:"Pro သို့ အဆင့်မြှင့်ပါ။",
  AllFeaturesInclude:"အင်္ဂါရပ်များ အားလုံးပါဝင်သည်-",
  PrintUnlimitedCheques:"အကန့်အသတ်မရှိ စစ်ဆေးမှုများကို ပရင့်ထုတ်ပါ။",
  PremiumChequeDesigns:"ပရီမီယံစစ်ဆေးမှုဒီဇိုင်းများ",
  ManageUnlimitedEmployees:"အကန့်အသတ်မရှိဝန်ထမ်းများကို စီမံခန့်ခွဲပါ။",
  AddUnlimitedPayees:"အကန့်အသတ်မရှိ ငွေလက်ခံသူများ ထည့်ပါ။",
  CreateUnlimitedPayrolls:"အကန့်အသတ်မရှိ လစာငွေများ ဖန်တီးပါ။",
  UnlimitedSchedulesandTimeSheets:"အကန့်အသတ်မရှိ အချိန်ဇယားများနှင့် အချိန်ဇယားများ",
  BulkPayPalPayouts:"အစုလိုက် PayPal ပေးချေမှုများ",
  UnlimitedBankAccounts:"အကန့်အသတ်မရှိ ဘဏ်အကောင့်များ",
  ManageMultipleCompanies:"ကုမ္ပဏီများစွာကို စီမံခန့်ခွဲပါ။",
  TrackInsurancePolicies:"အာမခံမူဝါဒများကို ခြေရာခံပါ။",
  Bio_MetricProtection:"Bio-Metric ကာကွယ်မှု",
  Geo_FenceLock_OutProtection:"Geo-Fence Lock-Out ကာကွယ်မှု",
  Multiple_Companies_Word:"စစ်ဆေးမှုပရီမီယံမပါဘဲ ကုမ္ပဏီအများအပြားကို စီမံခန့်ခွဲခြင်းသည် မရနိုင်ပါ။",
  PayPal_Payouts_Word:"ချက်လက်မှတ်ပရီမီယံမပါဘဲ PayPal ငွေပေးချေမှုများကိုပိတ်ထားသည်။",
  PINProtection:"ပင်နံပါတ်ကာကွယ်ရေး",
  PasswordProtection:"စကားဝှက်ကာကွယ်ရေး",
  May_Require_Approval:"အတည်ပြုချက် လိုအပ်နိုင်သည်။",
  Subscribe:"စာရင်းသွင်းပါ။",
  Billed:"ကောက်ခံခဲ့သည်။",
  Up:"တက်",
  Down:"ဆင်းတယ်။",
  Positioning:"နေရာချထားခြင်း။",
  Marker:"အမှတ်အသား",
  Drag_Marker:"အမှတ်အသားကို ဆွဲယူပါ။",
  Tagline:"ချက်လက်မှတ်များကို ထုတ်ယူပြီး လစာငွေကို ဇယားကွက်လုပ်ပါ။",
  Marker_Word:"ဒြပ်စင်ကိုအရွယ်အစားအတွက်အမှတ်အသားများကိုသုံးပါ။",
  Pinch_Zoom:"ချဲ့ကြည့်ပါ။",
  Pinch_Word:"ဒြပ်စင်ကို ဇူးမ်ချဲ့ရန် လိမ်ပါ။",
  Drag:"ဆွဲယူပါ။",
  Drag_Word:"အစိတ်အပိုင်းများကို ဆွဲယူရန် သင့်လက်ချောင်းကို အသုံးပြုပါ။",
  subscription_alias_word:"အော်တို-သက်တမ်းတိုး စာရင်းသွင်းမှု",
  premium_alias_word:"တစ်ကြိမ် အဆင့်မြှင့်တင်မှု ပက်ကေ့ချ်",
  print_alias_word:"Print-Out တစ်ဦးချင်းစစ်ဆေးမှုများ",
  mode_alias_word:"မုဒ်",
  Pro:"လိုလားသူ",
  Pro_word:"Pro ဗားရှင်း လိုအပ်သည်။",
  Cant_Delete_Default_Company_Word:"ဝမ်းနည်းပါသည်၊ သင်သည် သင်၏ မူရင်းကုမ္ပဏီကို ဖျက်၍ မရပါ ။",
  Reinsert_Default_Designs:"မူရင်းဒီဇိုင်းများကို ပြန်လည်ထည့်သွင်းပါ။",
  Reinsert_Default_Designs_word:"မူရင်းဒီဇိုင်းများကို ပြန်လည်ထည့်သွင်းလိုပါသလား။",
  Subscription_Active_Disable_Word:"ဤစာရင်းသွင်းမှုသည် အသက်ဝင်ပါသည်။ Cheques တွင် ဤစာရင်းသွင်းမှုကို ပယ်ဖျက်လိုပါသလား။",
  Company_Logo:"ကုမ္ပဏီလိုဂို",
  ZERO_:"ZERO",
  Disclaimer:"ရှင်းလင်းချက်",
  Privacy_Policy:"ကိုယ်ရေးအချက်အလက်မူဝါဒ",
  EULA:"EULA စစ်ဆေးသည်။",
  Terms_Of_Service:"ဝန်ဆောင်မှုစည်းမျဉ်းများ",
  Terms_Of_Use:"သတ်မှတ်ချက်များ",
  Refunds:"ပြန်အမ်းငွေမူဝါဒ",
  Single_Print:"1 စစ်ဆေးပါ။",
  Two_Prints:"2 စစ်ဆေးမှုများ",
  Five_Prints:"5 စစ်ဆေးမှုများ",
  Ten_Prints:"10 စစ်ဆေးမှုများ",
  Fifteen_Prints:"15 စစ်ဆေးမှုများ",
  Twenty_Prints:"20 စစ်ဆေးမှုများ",
  Thirty_Prints:"30 စစ်ဆေးမှုများ",
  Image_Added:"ပုံထည့်ထားသည်။",
  Image_Preview:"ပုံ အစမ်းကြည့်",
  No_Image_Was_Selected:"မည်သည့်ပုံကိုမျှ ရွေးမထားပါ။",
  Cheques_Unlimited:"အကန့်အသတ်မရှိ စစ်ဆေးသည်။",
  _Subscription:"စာရင်းသွင်းပါ။",
  Subscription:"စစ်ဆေးမှုများ - ၁ လ",
  Two_Month_Subscription:"စစ်ဆေးမှုများ - 2 လ",
  Three_Month_Subscription:"စစ်ဆေးမှုများ - 3 လ",
  Six_Month_Subscription:"စစ်ဆေးမှုများ - 6 လ",
  Twelve_Month_Subscription:"စစ်ဆေးမှုများ - 12 လ",
  Cheques_Are_Available:"ထုတ်ယူရန် စစ်ဆေးမှုများ ရရှိနိုင်သည်။",
  Upgrade_Required_Two:"သင့်ဝယ်ယူမှုစတင်ရန် ပက်ကေ့ဂျ်တစ်ခုကို ရွေးချယ်ပြီး စျေးနှုန်းခလုတ်ကို နှစ်ချက်နှိပ်ပါ။ စက္ကန့်ပိုင်းအတွင်း ရောင်စုံစစ်ဆေးမှုများကို ကျွမ်းကျင်သော ပရင့်ထုတ်ပါ။",
  Month:"လ",
  Due:"ပေးရမည့်ရက်-",
  Expires:"သက်တမ်းကုန်သည်-",
  Subscription_Active:"စာရင်းသွင်းမှု အသက်ဝင်သည်။",
  Subscription_Inactive:"စာရင်းသွင်းမှု မလုပ်ဆောင်ပါ။",
  Purchase_Additional_Cheques:"နောက်ထပ် ချက်လက်မှတ်များ ဝယ်မလား။",
  Printable_Cheque:"Printable Check",
  Printable_Cheques:"ပရင့်ထုတ်နိုင်သော စစ်ဆေးမှုများ",
  Printable_Cheque_Word:"စစ်ဆေးမှုကို သင့်အကောင့်တွင် ရနိုင်သည်။",
  Printable_Cheques_Word:"စစ်ဆေးမှုများကို သင့်အကောင့်တွင် ရနိုင်ပါသည်။",
  Max_Amount_Message:"သင်သတ်မှတ်ထားသော ပမာဏသည် ဤစနစ်အတွက် သတ်မှတ်ထားသည့် အများဆုံးပမာဏထက် ကျော်လွန်သွားသည်-",
  Terms_Required_Word:"Cheques ကို ဆက်လက် အသုံးမပြုမီ သင်သည် ဤသဘောတူညီချက်ကို သဘောတူရပါမည်။",
  Subscriptions:"စာရင်းသွင်းမှုများ",
  Product_Upgrades:"အဆင့်မြှင့်တင်မှုများ",
  Mailed_Out_Cheques:"စာပို့-အထွက် စစ်ဆေးမှုများ",
  Enter_A_Company_Name:"ကုမ္ပဏီအမည်ကို ထည့်သွင်းပါ။",
  Single_Cheques:"တစ်ခုတည်းစစ်ဆေးမှုများ",
  Cheque_Golden:"ရွှေစစ်ဆေးမှု",
  Cheque_Golden_Window:"ရွှေရောင်စစ်ဆေးဒီဇိုင်း။",
  Cheque_Green:"အစိမ်းရောင်စစ်ဆေးပါ။",
  Cheque_Green_Window:"အစိမ်းရောင်စစ်ဆေးဒီဇိုင်း။",
  Cheque_Red:"အနီရောင်စစ်ဆေးပါ။",
  Cheque_Red_Window:"အနီရောင်စစ်ဆေးထားတာ။",
  Cheque_Yellow:"အဝါရောင်စစ်ဆေးပါ။",
  Cheque_Yellow_Window:"အဝါရောင်ဒီဇိုင်း။",
  Cheque_Statue_of_Liberty:"လွတ်လပ်ရေးရုပ်တု",
  Cheque_Statue_of_Liberty_Window:"လွတ်လပ်ရေးရုပ်တုကို စစ်ဆေးထားတာ။",
  Cheque_Green_Wave:"အစိမ်းရောင်လှိုင်း",
  Cheque_Green_Wave_Window:"အစိမ်းရောင်စစ်ဆေးဒီဇိုင်း။",
  Cheque_Golden_Weave:"ရွှေရက်ကန်း",
  Cheque_Golden_Weave_Window:"အံဝင်ခွင်ကျ ရွှေရောင်ဒီဇိုင်း။",
  Cheque_Green_Sun:"အစိမ်းရောင် နေမင်း",
  Cheque_Green_Sun_Window:"နက်ရှိုင်းပြီး အေးဆေးတည်ငြိမ်တဲ့ ဒီဇိုင်း။",
  Cheque_Blue_Checkers:"အပြာရောင် Checkers",
  Cheque_Blue_Checkers_Window:"အပြာရောင်ဒီဇိုင်း။",
  Cashiers_Check:"ငွေကိုင်စစ်ချက်",
  Cashiers_Check_Window:"Cashier's Check စတိုင် နမူနာပုံစံ။",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Aqua ဒီဇိုင်းကိုစစ်ဆေးပါ။",
  Cheque_Golden_Checkers:"ရွှေ checkers",
  Cheque_Golden_Checkers_Window:"ရွှေရောင်စစ်ဆေးဒီဇိုင်း။",
  Upgrade_Unavailable:"အဆင့်မြှင့်တင်မှုများ မရရှိနိုင်ပါ။",
  Bank_Code_Protection:"ဘဏ်နံပါတ်ကာကွယ်ရေး",
  Bank_Code_Protection_Word:"အခြားစက်ပစ္စည်းပေါ်တွင် သို့မဟုတ် အခြားအသုံးပြုသူအတွက် လုပ်ဆောင်နေသော ဤအက်ပ်တွင် သင့်ဘဏ်နံပါတ်များကို အသုံးမပြုရန် ကာကွယ်ပါ။ ဤလုပ်ဆောင်ချက်သည် နောက်ပြန်ဆုတ်၍မရပါ။ ဆက်လက်?",
  Bank_Code_Protection_Blocked_Word:"သင်အသုံးပြုရန်ကြိုးစားနေသော ဘဏ်နံပါတ်များကို အခြားအသုံးပြုသူ သို့မဟုတ် စက်အတွက် သီးသန့်ထားရှိပါသည်။",
  Bank_Code_Protection_Error_Word:"နံပါတ်စစ်ဆေးခြင်း မအောင်မြင်ပါ။ ကျေးဇူးပြု၍ အင်တာနက်ချိတ်ဆက်ပြီး ဤဘဏ်အကောင့်ကို ထပ်ထည့်ကြည့်ပါ။",
  Bank_Code_Protection_Set_Error_Word:"ဘဏ်နံပါတ် အကာအကွယ်သည် သင့်အား အင်တာနက်သို့ ချိတ်ဆက်ထားရန် လိုအပ်သည်။ ကျေးဇူးပြု၍ ချိတ်ဆက်ပြီး ထပ်စမ်းကြည့်ပါ။",
  Upgrade_Unavailable_Word:"ဝမ်းနည်းပါသည်၊ သင့်အား အတည်ပြုရာတွင် အခက်အခဲရှိနေပါသည်။ Checks သို့ စာရင်းသွင်းမှုများနှင့် အဆင့်မြှင့်ခြင်းများ လောလောဆယ်တွင် သင့်ဧရိယာတွင် ဝယ်ယူ၍မရပါ။",
  PayPal_Payment_Preview:"PayPal ငွေပေးချေမှု အစမ်းကြည့်ရှုခြင်း။",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"PayPal ကို ရွေးပါ။",
  PayPal_Applications:"PayPal အပလီကေးရှင်းများ",
  Purchase_With_Apple_Pay:"Apple Pay ဖြင့် ဝယ်ယူပါ။",
  Google_Pay:"Google Pay",
  Companies:"ကုမ္ပဏီတွေ",
  Insurance:"အာမခံ",
  New_PayPal:"PayPal အသစ်",
  Pay_By:"ပေးချေပါ။",
  Insure:"အာမခံတယ်။",
  Miles:"မိုင်",
  Payment_Method:"ငွေပေးချေစနစ်",
  Select_Policies:"မူဝါဒများကို ရွေးချယ်ပါ။",
  Policies:"မူဝါဒများ",
  Policy:"မူဝါဒ",
  No_PayPal_Account:"PayPal အကောင့်မရှိပါ။",
  No_Policies:"အာမခံမူဝါဒမရှိပါ။",
  New_Policy:"မူဝါဒအသစ်",
  PayPal_Payment:"PayPal ငွေပေးချေမှု",
  PayPal_Payments:"PayPal ငွေပေးချေမှုများ",
  No_Payment_Selected:"ငွေပေးချေမှုကို ရွေးချယ်ထားခြင်းမရှိပါ။",
  Sending_Payment_Word:"ခေတ္တစောင့်ပါ... ဤငွေပေးချေမှုကို ပို့နေပါသည်။",
  Sending_Payments_Word:"ခေတ္တစောင့်ပါ... ငွေပေးချေမှုများကို ပေးပို့နေပါသည်။",
  No_Payment_Selected_PayPal:"<a routerLink='/folder/Payments'>PayPal ငွေပေးချေမှု</a> ကို ရွေးချယ်ထားခြင်းမရှိပါ ။",
  Payment_Sent:"ငွေပေးချေပြီးပါပြီ။",
  PayPal_Payment_Sent:"ဤငွေပေးချေမှုကို PayPal ဖြင့် ပို့ပြီးဖြစ်သည်။",
  Copay:"ကော်ပီ",
  Dead:"သေပြီ။",
  Alive:"အသက်ရှင်လျက်",
  Not_Dead:"မသေ",
  Unclaimed:"အရေးဆိုမထား",
  Attempted:"ကြိုးပမ်းခဲ့သည်။",
  Deceased:"ကွယ်လွန်သည်။",
  Claimed:"တိုင်ကြားထားသည်။",
  Unpaid:"အခကြေးငွေမယူရသေး",
  Sending_Payment:"ငွေပေးချေမှု ပေးပို့ခြင်း။",
  Sending_Payments:"ငွေပေးချေမှုများ ပေးပို့ခြင်း။",
  Send_PayPal_Confirmation:"ဤငွေပေးငွေယူကို PayPal ဖြင့် ပေးပို့လိုပါသလား။",
  Send_PayPal_Confirmation_Word:"ဤငွေလွှဲပို့ရန် အစိမ်းရောင်ခလုတ်ကို နှိပ်ပါ။",
  Save_Payment_As_Unpaid:"ဤငွေပေးချေမှုကို အခပေးမရသေးသည့်အဖြစ် သိမ်းမလား။",
  Payment_Pay_Confirmation_PayPal:"ဤငွေပေးချေမှုကို အခကြေးငွေအဖြစ် သိမ်းဆည်းမလား။",
  No_Policies_Word:"ပထမဆုံး <a routerLink='/folder/Postage/New'>အာမခံမူဝါဒ</a> ကို ယခုထည့်ပါ ။",
  Timesheet_Multiple_Delete_Confirmation:"အချိန်ဇယားများစွာကို ဖျက်လိုသည်မှာ သေချာပါသလား။",
  Select_Multiple_Timesheets_Prompt:"အချိန်စာရင်းများကို ရွေးချယ်ထားခြင်းမရှိပါ။ အနည်းဆုံး အချိန်ဇယားတစ်ခုကို ရွေးပါ။",
  Select_Multiple_Policies_Prompt:"မူဝါဒများကို ရွေးချယ်ထားခြင်းမရှိပါ။ အနည်းဆုံး အာမခံမူဝါဒကို ရွေးချယ်ပါ။",
  Policies_Multiple_Delete_Confirmation:"မူဝါဒများစွာကို ဖျက်လိုသည်မှာ သေချာပါသလား။",
  Company:"ကုမ္ပဏီ",
  Add_Company:"ကုမ္ပဏီထည့်ပါ။",
  New_Company:"ကုမ္ပဏီထည့်ပါ။",
  No_Companies:"ကုမ္ပဏီ မရှိပါ။",
  Enable_Company:"ကုမ္ပဏီကိုဖွင့်ပါ။",
  Select_Company:"ကုမ္ပဏီကိုရွေးချယ်ပါ။",
  The_Default_Company:"မူရင်းကုမ္ပဏီတံဆိပ်။",
  Manage_Companies:"ကုမ္ပဏီများကို စီမံခန့်ခွဲပါ။",
  No_Companies_Word:"စစ်ဆေးမှုများသည် မူရင်းကုမ္ပဏီကို အသုံးပြုပါမည် ။<br /> <a routerLink='/folder/Company/New'>ပထမကုမ္ပဏီ</a> ကို ထည့်ပါ ။",
  Default_Company:"မူရင်းကုမ္ပဏီ",
  Cheques_Unlimited_Word:"Checks Unlimited သည် သင်နှစ်သက်သလောက် ချက်လက်မှတ်များစွာကို print ထုတ်နိုင်စေပါသည်။",
  Cheques_Subscription_Word:"Checks စာရင်းသွင်းမှုတစ်ခုသည် သင်နှစ်သက်သလောက် ချက်လက်မှတ်များစွာကို ပရင့်ထုတ်နိုင်စေပါသည်။",
  You_Own_This_Product:"သင်သည် ဤထုတ်ကုန်ကို ပိုင်ဆိုင်သည်။",
  Your_Subscription_is_Active:"သင်၏စာရင်းသွင်းမှုသည် အသက်ဝင်ပါသည်။",
  Active_Products:"အသက်သွင်းထားသောထုတ်ကုန်များ",
  Purchase_Confirmation:"ဝယ်ယူပါ။",
  Purchase_Cheques:"ဝယ်ယူမှုစစ်ဆေးမှုများ",
  Restore_Purchase:"ဝယ်ယူမှုများကို ပြန်လည်ရယူပါ။",
  Erase_Purchase:"ဝယ်ယူမှုများကို ဖျက်ပါ။",
  Successfully_Purchased:"အောင်မြင်စွာ ဝယ်ယူနိုင်ပါပြီ။",
  Enter_Your_Licence_Key:"ဤထုတ်ကုန်ကို အသက်သွင်းရန် ဤစာမျက်နှာတွင် သင်၏လိုင်စင်ကီးကို ထည့်သွင်းပါ။",
  Licence_Key:"လိုင်စင်ကီး",
  Enter_Licence_Key:"လိုင်စင်ကီးထည့်ပါ။",
  Purchased:"ဝယ်ယူခဲ့သည်။",
  Enable_Feature:"အင်္ဂါရပ်ကို ဖွင့်ပါ။",
  Cancel_Subscription:"စာရင်းသွင်းမှုကို ပယ်ဖျက်ပါ။",
  Subscription_Removed:"စာရင်းသွင်းမှုကို ဖယ်ရှားခဲ့သည်။",
  Select_Active_Subscription:"၎င်းကိုမွမ်းမံရန်အတွက် လက်ရှိစာရင်းသွင်းမှုကို ရွေးချယ်ပါ။",
  Remove_Subscription_Word:"ဤစာရင်းသွင်းမှုကို ပယ်ဖျက်လိုသည်မှာ သေချာပါသလား။",
  Delete_Company_Confirmation:"ဤကုမ္ပဏီတစ်ခုလုံးကို ဖျက်လိုသည်မှာ သေချာပါသလား။ သတိပေးချက်- သိမ်းဆည်းထားသော အချက်အလက်အားလုံး ဆုံးရှုံးသွားပါမည်။",
  Delete_Default_Company_Word:"မူရင်းကုမ္ပဏီကို သင် ဖျက်၍ မရပါ ။ အပလီကေးရှင်းကို ပြန်လည်သတ်မှတ်ပြီး မူလအခြေအနေသို့ ပြန်လည်ရယူလိုပါသလား။ သတိပေးချက်- သိမ်းဆည်းထားသော အချက်အလက်အားလုံး ဆုံးရှုံးသွားပါမည်။",
  Console_Warning_2:"သင့်လက်ရှိမဟုတ်သော ဤအပလီကေးရှင်းကို အသုံးပြု၍ မည်သည့်ငွေကြေးကိုမျှ မကိုင်တွယ်ပါနှင့်။",
  Terms_and_Conditions:"စည်းကမ်းနှင့်သတ်မှတ်ချက်များ",
  and_the:"ပြီးနောက်",
  for:"ဘို့",
  Please_Read_Word:"ကျေးဇူးပြု၍ ဖတ်ပြီး သဘောတူပါ။",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"အချို့သော ငွေကြေးလဲလှယ်နှုန်းများကို ရှာမတွေ့ပါ။ ကျေးဇူးပြု၍ အင်တာနက်ချိတ်ဆက်ပါ။",
  Free:"အခမဲ့",
  DB_Erase_Prompt_2:"ဆော့ဖ်ဝဲရေးသားသူဒေတာဘေ့စ်တစ်ခုလုံးကို အပြီးအပြတ်ဖျက်မလား။ သတိပေးချက်- ဝယ်ယူမှုနှင့် စာရင်းသွင်းမှု အချက်အလက်အားလုံးကို သင်ဆုံးရှုံးပါမည်။",
  Successfully_Imported:"အောင်မြင်စွာ တင်သွင်းခဲ့သည်။",
  Select_Postage:"Postage ကို ရွေးပါ။",
  No_Postage:"စာပို့တံဆိပ်ခေါင်း မပါပါ။",
  No_Paid_Postage_Word:"ပထမဆုံး <a routerLink='/folder/Postage/New'>အခပေးစာပို့</a> တံဆိပ်တုံးကို ထည့်ပါ ။",
  Trial_Complete:'အစမ်းပြီးပါပြီ။',
  Please_Upgrade:'ဆက်လက်ပုံနှိပ်ရန် စစ်ဆေးမှုများကို အဆင့်မြှင့်ပါ။',
  Aa:"Aa",
  Color:"အရောင်",
  Font:"ဖောင့်",
  Guide:"လမ်းညွှန်",
  Guides:"လမ်းညွှန်များ",
  Image:"ပုံ",
  Zoom:"ချဲ့ပါ။",
  Logo:"လိုဂို",
  Bank:"ဘဏ်",
  MICR:"MICR",
  Total:"စုစုပေါင်း",
  Cancel:"မလုပ်တော့",
  Confirm:"အတည်ပြုပါ။",
  Method:"နည်းလမ်း",
  Biometric_Security:"ဇီဝမက်ထရစ်လုံခြုံရေး",
  Please_Login_To_Continue:"ရှေ့ဆက်ရန် ကျေးဇူးပြု၍ အကောင့်ဝင်ပါ။",
  Complete:"ပြီးအောင်",
  Sign_Up:"ဆိုင်းအပ်",
  Error:"အမှား",
  Biometrics:"ဇီဝတိုင်းတာမှုများ",
  Percent:"ရာခိုင်နှုန်း",
  Zero_Percent:"0%",
  Top_Margin:"ထိပ်တန်းအနားသတ်",
  Bottom_Margin:"အောက်ခြေအနားသတ်",
  Left_Margin:"ဘယ်ဘက်အနားသတ်",
  Right_Margin:"ညာဘက်အနားသတ်",
  MICR_Margin:"MICR Margin",
  Table_Margin:"ဇယားအနားသတ်",
  Address_Margin:"လိပ်စာ Margin",
  Percentage_:"ရာခိုင်နှုန်း",
  Vacation_Title:"အားလပ်ရက်ခေါင်းစဉ်",
  Use_PIN:"PIN ကိုသုံးပါ။",
  Loading__:"ဖွင့်နေသည်...",
  Design_Title:"ဒီဇိုင်းခေါင်းစဉ်",
  Authorize:"လုပ်ပိုင်ခွင့်",
  Key:"သော့",
  Public_Key:"အများသူငှာသော့",
  Private_Key:"သီးသန့်သော့",
  Authenticate:"အထောက်အထားပြပါ။",
  Last_Payroll:"နောက်ဆုံးလစာ",
  Last_Calculation:"နောက်ဆုံးတွက်ချက်မှု",
  Authorized:"လုပ်ပိုင်ခွင့်",
  Geo_Authorized:"ပထဝီတည်နေရာ- ခွင့်ပြုချက်",
  Not_Authorized:"လုပ်ပိုင်ခွင့်မရှိပါ။",
  Authorization_Complete:"ခွင့်ပြုချက် ပြီးပါပြီ။",
  Geolocation_Authorization:"ပထဝီတည်နေရာခွင့်ပြုချက်",
  Out_of_Bounds:"နယ်နိမိတ်ထဲက",
  Cant_Delete_Default_Design:"ပုံသေဒီဇိုင်းကို ဖျက်၍မရပါ။",
  Unauthorized_Out_of_Bounds:"လုပ်ပိုင်ခွင့်မရှိသော- နယ်နိမိတ်ပြင်ပ",
  Biometric_Authorization:"ဇီဝမက်ထရစ်ခွင့်ပြုချက်",
  Locating_Please_Wait:"ရှာဖွေနေသည်၊ ခဏစောင့်ပါ...",
  Test_Biometrics:"Bio-metrics စမ်းသပ်ပါ။",
  Cheque_Margins:"အနားသတ်များကို စစ်ဆေးပါ။",
  Percentage_Full_Error:"ရာခိုင်နှုန်းအကွက်ကို 100% ရာခိုင်နှုန်းအထက် သတ်မှတ်၍မရပါ။",
  No_Payroll_Text:"<a routerLink='/folder/Employee/New'>ဝန်ထမ်း</a> သို့မဟုတ် <a routerLink='/folder/Payee/New'>ငွေလက်ခံသူ</a> နှင့် <a routerLink='/folder/Schedule/New'>အချိန်ဇယား</a>။",
  Design_Saved:"ဒီဇိုင်းကို သိမ်းဆည်းထားသည်။",
  Default_Design_Selected:"ပုံသေဒီဇိုင်းကို ရွေးထားသည်။",
  Partial_Import:"တစ်စိတ်တစ်ပိုင်း တင်သွင်းခြင်း။",
  Partial_Export:"တစ်စိတ်တစ်ပိုင်း တင်ပို့ခြင်း။",
  Entire_Import:"တင်သွင်းမှုတစ်ခုလုံး",
  Entire_Export:"Export တစ်ခုလုံး",
  Existing_Password:"သင့်လက်ရှိ စကားဝှက်ကို ထည့်ပါ-",
  Existing_PIN:"သင့်လက်ရှိ PIN ကုဒ်ကို ထည့်ပါ-",
  Pin_Change_Header:"PIN အတည်ပြုချက်",
  Pin_Change_SubHeader:"အပြောင်းအလဲကို အတည်ပြုရန် သင်၏ PIN နံပါတ်ဟောင်းကို ထည့်ပါ။",
  Pass_Change_Header:"စကားဝှက်အတည်ပြုခြင်း။",
  Pass_Change_SubHeader:"အပြောင်းအလဲကို အတည်ပြုရန် သင့်စကားဝှက်ဟောင်းကို ထည့်သွင်းပါ။",
  PIN_Enter_Message:"အတည်ပြုရန် သင့်ပင်နံပါတ်ကို ထည့်သွင်းပါ။",
  Password_Enter_Message:"အတည်ပြုရန် သင့်စကားဝှက်ကို ထည့်သွင်းပါ။",
  Pin_Updated_Success:"ပင်နံပါတ်ကို အောင်မြင်စွာ မွမ်းမံပြီးပါပြီ။",
  Pin_Updated_Fail:"ပင်နံပါတ်ကို အပ်ဒိတ်လုပ်၍မရပါ။",
  Pass_Updated_Success:"စကားဝှက်ကို အောင်မြင်စွာ မွမ်းမံပြီးပါပြီ။",
  Pass_Updated_Fail:"စကားဝှက်ကို အပ်ဒိတ်လုပ်၍မရပါ။",
  Preview_Payment:"ငွေပေးချေမှုကို အစမ်းကြည့်ရှုပါ။",
  Preview_Payroll:"လစာငွေကို အစမ်းကြည့်ပါ။",
  No_Payments_Created:"ငွေပေးချေမှုများကို ဖန်တီးရန် မတွေ့ပါ။",
  Payment_Preview:"ငွေပေးချေမှု အကြိုကြည့်ရှုခြင်း။",
  Enable_Payee:"ငွေလက်ခံသူကိုဖွင့်ပါ။",
  Rendered:"ပြန်ဆိုသည်။",
  No_Email:"အီးမေးလ်မရှိပါ။",
  Setup_Cheques:"သတ်မှတ်စစ်ဆေးမှုများ",
  name:"နာမည်",
  address:"လိပ်စာ",
  address_2:"လိပ်စာလိုင်း ၂",
  city:"မြို",
  province:"ပြည်နယ်",
  postal_code:"စာတိုက်/စာတိုက်ကုဒ်",
  country:"တိုင်းပြည်",
  username:"အသုံးပြုသူအမည်",
  full_name:"နာမည်အပြည့်အစုံ",
  birthday:"မွေးနေ့",
  email:"အီးမေးလ်",
  phone:"ဖုန်း",
  employees:"ဝန်ထမ်းများ",
  addresses:"လိပ်စာများ",
  payment_amount_limit:"ငွေပေးချေမှုပမာဏ ကန့်သတ်ချက်",
  total_limit:"စုစုပေါင်းကန့်သတ်ချက်",
  payees:"ငွေလက်ခံသူ",
  description:"ဖော်ပြချက်",
  address_line_one:"လိပ်စာလိုင်းတစ်ကြောင်း",
  address_line_two:"လိပ်စာ လိုင်းနှစ်",
  image:"ပုံ",
  account_holder:"အကောင့်ပိုင်ရှင်",
  cheque_starting_id:"စတင်ခြင်း ID ကိုစစ်ဆေးပါ။",
  transit_number:"ယာဉ်လိုင်းနံပါတ်",
  institution_number:"အဖွဲ့အစည်း နံပါတ်",
  designation_number:"သတ်မှတ်အမှတ်",
  account_number:"အကောင့်နံပါတ်",
  currency:"ငွေကြေး",
  signature:"လက်မှတ်",
  payment_payroll_limit:"ငွေပေးချေမှုကန့်သတ်ချက်",
  total_limi:"စုစုပေါင်းကန့်သတ်ချက်",
  date:"ရက်စွဲ",
  printed_memo:"ပုံနှိပ်စာတို",
  banks:"ဘဏ်များ",
  signature_image:"လက်မှတ်ပုံ",
  address_name:"လိပ်စာအမည်",
  notes:"မှတ်စုများ",
  design:"ဒီဇိုင်း",
  title:"ခေါင်းစဥ်",
  frequency:"အကြိမ်ရေ",
  fax:"ဖက်စ်",
  salaries:"လစာ",
  salary_ids:"လစာ ID များ",
  start_time:"စတင်ချိန်",
  end_time:"ကုန်ဆုံးချိန်",
  paid:"အခကြေးငွေ",
  overtime_percentage:"ပေးဆောင်သော ရာခိုင်နှုန်း",
  overtime_amount:"ပေးဆောင်ထားသည့် ပုံသေပမာဏ",
  first_name:"နာမည်",
  last_name:"မျိုးနွယ်အမည်",
  moderation:"ထိန်းညှိပေးသည်။",
  create:"ဖန်တီးပါ။",
  edit:"တည်းဖြတ်ပါ။",
  destroy:"ဖျက်ဆီးပါ။",
  day_start_time:"နေ့_စသည့်_အချိန်",
  day_end_time:"နေ့_ကုန်_အချိန်",
  fullname:"နာမည်",
  time:"အချိန်",
  auto_send:"အလိုအလျောက် ပေးပို့ပါ။",
  time_method:"အချိန်နည်း",
  schedules:"အချိန်ဇယားများ",
  indefinite_payroll_enabled:"အကန့်အသတ်မရှိ ဖွင့်ပါ။",
  amount:"ပမာဏ",
  repeat:"ပြန်လုပ်ပါ။",
  always_enabled:"အမြဲတမ်း ဖွင့်ထားသည်။",
  start_date:"စတင်သည့်ရက်စွဲ",
  end_date:"ကုန်ဆုံးရက်စွဲ",
  Cheque_Total:"စုစုပေါင်းစစ်ဆေးပါ။",
  Total_Amount:"စုစုပေါင်းပမာဏ:",
  Amounts:"ပမာဏများ-",
  Images:"ပုံများ",
  Files:"ဖို",
  Previewing:"အစမ်းကြည့်ရှုခြင်း-",
  Insert:"ထည့်ပါ။",
  Preview_Import:"တင်သွင်းမှုကို အစမ်းကြည့်ရှုပါ။",
  Entry:"ဝင်ခွင့်",
  Entries:"ထည့်သွင်းမှုများ",
  No_Entries:"ထည့်သွင်းမှုများမရှိပါ။",
  Import_Type:"သွင်းကုန်အမျိုးအစား",
  Select_Details:"အသေးစိတ်ကို ရွေးပါ။",
  Select_Payee:"ငွေလက်ခံသူကို ရွေးပါ။",
  Enable_Holidays:"အားလပ်ရက်များကိုဖွင့်ပါ။",
  Disable_Holidays:"အားလပ်ရက်များကိုပိတ်ပါ။",
  Wire_Transfer:"ငွေလွှဲခြင်း",
  New_Export:"ပို့ကုန်အသစ်",
  Export_Data:"ဒေတာထုတ်ရန်",
  Export_Data_Word:"ထုတ်ယူရန်နှင့် ဒေါင်းလုဒ်လုပ်ရန် ဖိုင်အမျိုးအစားကို ရွေးချယ်ပါ။",
  Export_File:"ဖိုင်ကို ထုတ်ယူပါ။",
  Mode:"မုဒ်",
  Times:"ကြိမ်",
  Widgets:"ဝစ်ဂျက်များ",
  Slider:"ဆလိုက်ဒါ",
  Set_Details:"အသေးစိတ်ကို သတ်မှတ်ပါ။",
  Select_Type:"အမျိုးအစားကို ရွေးပါ။",
  Display_Slider:"Slider ကိုပြသပါ။",
  Dashboard_Slider:"ဒက်ရှ်ဘုတ် ဆလိုက်ဒါ",
  Dashboard_Mode:"ဒက်ရှ်ဘုတ်မုဒ်",
  Show_Intro:"Intro ပြပါ။",
  Show_Payrolls:"လစာငွေများကို ပြပါ။",
  Show_Holidays:"အားလပ်ရက်များကိုပြသပါ။",
  Show_Invoices:"ငွေတောင်းခံလွှာများကို ပြပါ။",
  Show_Cheques:"စစ်ဆေးမှုများကို ပြသပါ။",
  Enabled_Widgets:"ဝစ်ဂျက်များကို ဖွင့်ထားသည်။",
  Disabled_Widgets:"ပိတ်ထားသောဝစ်ဂျက်များ",
  Colors:"အရောင်များ",
  Barcodes:"ဘားကုဒ်များ",
  View_Timers:"တိုင်မာများကို ကြည့်ပါ။",
  Gradients:"အဆင့်အတန်းများ",
  No_Info:"အချက်အလက်မရှိပါ။",
  Disable:"ပိတ်ပါ။",
  Show_Layer:"အလွှာများကိုပြသပါ။",
  Hide_Layer:"အလွှာများကို ဝှက်ပါ။",
  Text_Layer:"စာသားအလွှာများ",
  Secondly:"ညိုညို",
  Minutely:"မိနစ်ပိုင်း",
  nine_am:"9:00 AM",
  five_pm:"ညနေ ၅း၀၀",
  Enable_Address:"လိပ်စာကိုဖွင့်ပါ။",
  Invalid_File_Type:"ဝမ်းနည်းပါသည်၊ မမှန်ကန်သော ဖိုင်အမျိုးအစားကို ရွေးထားသည်။ ပံ့ပိုးထားသော ဖိုင်အမျိုးအစား-",
  Error_Updating_Entry:"ဝမ်းနည်းပါတယ်၊ ဤထည့်သွင်းမှုကို အပ်ဒိတ်လုပ်ရာတွင် အမှားအယွင်းရှိခဲ့သည်။",
  Schedule_Timing_Alert:"အမှားအယွင်း- စတင်ချိန်ဇယားကို ပြီးဆုံးချိန်ပြီးနောက် တန်ဖိုးတစ်ခုအဖြစ် သတ်မှတ်သည်။",
  Select_Multiple_Payments_Prompt:"ငွေပေးချေမှုများကို ရွေးချယ်ထားခြင်းမရှိပါ။ အနည်းဆုံး ငွေပေးချေမှုတစ်ခုကို ရွေးပါ။",
  Select_Multiple_Cheques_Prompt:"စစ်ဆေးမှုများကို ရွေးချယ်ထားခြင်းမရှိပါ။ ကျေးဇူးပြု၍ အနည်းဆုံး စစ်ဆေးချက်တစ်ခု ရွေးပါ။",
  Select_Multiple_Items_Prompt:"မည်သည့်အရာမှ ရွေးထားခြင်းမရှိပါ။ ကျေးဇူးပြု၍ အနည်းဆုံး အကြောင်းအရာတစ်ခုကို ရွေးပါ။",
  Paymemt_Multiple_Delete_Confirmation:"ငွေပေးချေမှုများစွာကို ဖျက်လိုသည်မှာ သေချာပါသလား။",
  Cheque_Multiple_Delete_Confirmation:"စစ်ဆေးမှုများစွာကို ဖျက်လိုသည်မှာ သေချာပါသလား။",
  Payee_Multiple_Delete_Confirmation:"ငွေပေးသူအများအပြားကို ဖျက်လိုသည်မှာ သေချာပါသလား။",
  Employee_Multiple_Delete_Confirmation:"ဝန်ထမ်းအများအပြားကို ဖျက်လိုသည်မှာ သေချာပါသလား။",
  MICR_Warning:"မှတ်ချက်- အချို့သော ပရင်တာများနှင့် စက်များသည် MICR ဖောင့်ကို မှန်ကန်စွာ မပြနိုင်ပါ။",
  Automatically_Send:"အလိုအလျောက် ပေးပို့ပါ။",
  Type:"ရိုက်ပါ။",
  Payment_Type:"ငွေပေးချေမှုအမျိုးအစား",
  Auto_Send:"အလိုအလျောက် ပို့ပါ။",
  Automatically_Process:"အလိုအလျောက်လုပ်ဆောင်ခြင်း။",
  Auto_Process:"အလိုအလျောက်လုပ်ဆောင်မှု",
  Image_Based:"ရုပ်ပုံအခြေခံ",
  Font_Based:"ဖောင့်အခြေခံ",
  Rerender:"ပြန်လည်တင်ဆက်ပါသည်။",
  Rendering:"ပုံဖေါ်ခြင်း။",
  Render:"ဖို",
  Top:"ထိပ်တန်း",
  Middle:"အလယ်တန်း",
  Bottom:"အောက်ခြေ",
  Top_Left:"ဘယ်ဘက်ထိပ်",
  Top_Center:"ထိပ်တန်းစင်တာ",
  Top_Right:"ညာဘက်",
  Middle_Left:"အလယ်လက်ဝဲ",
  Middle_Center:"အလယ်ဗဟို",
  Middle_Right:"အလယ်တန်း",
  Bottom_Left:"ဘယ်ဘက်အောက်ခြေ",
  Bottom_Center:"အောက်ခြေစင်တာ",
  Bottom_Right:"အောက်ခြေ ညာဘက်",
  Numbers:"နံပါတ်များ",
  Verified:"စိစစ်ပြီး",
  Paper_Size:"စက္ကူအရွယ်အစား",
  New_Device:"စက်အသစ်",
  Add_Device:"စက်ကိုထည့်ပါ။",
  Remove_Device:"စက်ပစ္စည်းကို ဖယ်ရှားပါ။",
  Delete_Device:"စက်ပစ္စည်းကို ဖျက်ပါ။",
  Block_Device:"စက်ပစ္စည်းကို ပိတ်ပါ။",
  Block:"ပိတ်ဆို့သည်။",
  Unblock:"ပြန်ဖွင့်ပါ။",
  Table:"စားပွဲ",
  Scan_Login_Code:"အကောင့်ဝင်ကုဒ်ကို စကင်န်ဖတ်ပါ။",
  Login_Code:"အကောင့်ဝင်ကုဒ်",
  Scan_Code:"ကုဒ်ကို စကင်န်ဖတ်ပါ။",
  Scan_QR_Code:"QR ကုဒ်ကို စကင်န်ဖတ်ပါ။",
  Select_All:"အားလုံးကို ရွေးပါ။",
  Deselect_All:"အားလုံးရွေးချယ်မှုမပြု",
  Increase:"တိုးမြှင့်လာသည်",
  Decrease:"ရုပ်ရည်",
  Bold:"ရဲရင့်သည်။",
  Text:"စာသား",
  Style:"စတိုင်",
  Italic:"စာလုံးစောင်း",
  QR_Code:"QR ကုဒ်",
  Barcode:"ဘားကုဒ်",
  Browse_Images:"ပုံများကို ကြည့်ပါ။",
  Browse_Files:"ဖိုင်များကို ရှာဖွေပါ။",
  Background_Image:"နောက်ခံပုံ",
  Logo_Image:"လိုဂိုပုံ",
  Header_Image:"ခေါင်းစီးပုံ",
  Bank_Image:"ဘဏ်ပုံ",
  Cut_Lines:"လိုင်းများဖြတ်ပါ။",
  Background:"နောက်ခံ",
  License:"လိုင်စင်",
  One_License:"1 လိုင်စင်-",
  Licensed:"လိုင်စင်ရ-",
  Not_Licensed:"လိုင်စင်မရပါ။",
  Enter_Serial:"Serial ရိုက်ထည့်ပါ။",
  Serial_Key:"Serial Key",
  Serial:"အမှတ်စဉ်",
  Product_Key:"ထုတ်ကုန်ကီး",
  Check_Product_Key:"ထုတ်ကုန်ကီးကို စစ်ဆေးပါ။",
  Add_Product_Key:"ထုတ်ကုန်ကီးထည့်ပါ။",
  Verifying_Purchase:"ဝယ်ယူမှုကို အတည်ပြုခြင်း...",
  Print_Envelope:"ပုံနှိပ်စာအိတ်",
  Envelope:"စာအိတ်",
  Thank_You:"ကျေးဇူးတင်ပါသည်!",
  Scale:"အတိုင်းအတာ",
  Print_Scale:"ပုံနှိပ်စကေး",
  Borders:"နယ်နိမိတ်များ",
  VOID:"ပျက်ပြယ်သည်။",
  Void_Cheque:"ပျက်ပြယ်ကြောင်း စစ်ဆေးပါ။",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"မှာယူမှုသို့ ပေးချေပါ-",
  NO_DOLLARS:"ဒေါ်လာ မရှိပါ။ ",
  ONE_DOLLAR:"တစ်ဒေါ်လာ",
  DOLLARS:" ဒေါ်လာ",
  AND:" AND ",
  CENTS:" CENTS",
  NO_:"မဟုတ်ဘူး ",
  ONE_:"ONE ",
  AND_NO_:"AND NO ",
  _AND_ONE_:"AND ONE ",
  DOLLARS_AND_ONE_CENT:" နှင့် ONE CENT ။",
  AND_NO_CENTS:" နှင့် ZERO CENTS",
  CHEQUE_PRINT_DATE:"DATE-",
  CHEQUE_PRINT_MEMO:"မှတ်စုတို:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/၁၀၀",
  CHEQUE_PRINT_SIGNATUREMP:"အမတ်",
  Initial_Tasks:"ကနဦးတာဝန်များ",
  Inital_Setup:"ကနဦး စနစ်ထည့်သွင်းမှု",
  Welcome_To:"ကြိုဆိုပါတယ်",
  Welcome:"ကြိုဆိုပါတယ်",
  Swipe:"ပွတ်ဆွဲ",
  Intro_Setup:"Intro Setup",
  Introduction:"နိဒါန်း",
  CHEQUE_PRINT_CREDIT:"Powered by Cheques",
  Title_Intro_Word:"စစ်ချက်မှကြိုဆိုပါတယ်",
  Title_Intro:"စစ်ချက်နိဒါန်း",
  Title_Setup:"ချက်လက်မှတ်များ စနစ်ထည့်သွင်းခြင်း",
  PayPal_Default_Email_Message:"PayPal လွှဲပြောင်းမှုအသစ်တစ်ခုကို သင်လက်ခံရရှိပြီးပါပြီ။ [Cheques ဖြင့် လုပ်ဆောင်သည်]",
  Cheques_App_Export:"ချက်လက်မှတ်ဖြင့် တင်ပို့သည်",
  Post_Intro_Thanks:"Cheques ကိုရွေးချယ်သည့်အတွက် ကျေးဇူးတင်ပါသည်။ စနစ်ထည့်သွင်းခြင်းလုပ်ငန်းစဉ်သည် ယခု ပြီးသွားပါပြီ။",
  Post_Intro_Word:"မင်းရဲ့ ပထမဆုံးချက်လက်မှတ်ကို ပုံနှိပ်ထုတ်ပါ၊ အနာဂတ်မှာ ငွေပေးချေမှုတစ်ခု ထပ်ထည့်ပါ၊ ဒါမှမဟုတ် လစာစာရင်းမှာ ဝန်ထမ်းတစ်ဦးကို ထည့်ပေးခြင်းဖြင့် စတင်လိုက်ပါ။",
  Upgrade_Required:"Cheques သည် ဤမက်ဆေ့ချ်ကို ဝှက်ထားရန်နှင့် အပိုဝန်ဆောင်မှုများကို လော့ခ်ဖွင့်ရန်အတွက် သင့်အား ပိုမိုပရီမီယံဗားရှင်းတစ်ခု ပိုင်ဆိုင်ရန် လိုအပ်ပါသည်။",
  Upgrade_Title:"ချက်လက်မှတ်များ",
  Mailout_Prompt:"သင့်အတွက် လစာချက်လက်မှတ်များကို Checks mail-out လုပ်ရန် ထပ်မံရွေးချယ်နိုင်ပါသည်။",
  Mailed_Cheque:"စာပို့စစ်ဆေးမှု",
  Mailed_Cheque_Color:"စာပို့စစ်ဆေးမှု (အရောင်):",
  Terms_Purchase:"ချက်လက်မှတ်များဖြင့် အီလက်ထရွန်းနစ်ဝယ်ယူမှုအားလုံးကို ဝယ်ယူသည့်နေ့မှ ရက်ပေါင်း 30 အထိ အပြည့်အဝ ပြန်အမ်းနိုင်သည်။ ဆက်လက်မလုပ်ဆောင်မီ <a href='#'>စည်းမျဥ်းစည်းကမ်းများ</a> ကို ဖတ်ပြီး သဘောတူပါ။",
  Dashboard_Setup:"ပင်မပရင်တာအား စနစ်ထည့်သွင်းခြင်း",
  Dashboard_Add:"ပင်မဘဏ်အကောင့်ထည့်ရန်",
  Dashboard_Customize:"စစ်ဆေးသည့်ပုံစံကို ရွေးပါ",
  Dashboard_Job_Salary:"သင်၏အလုပ်ဖန်တီးပြီး လစာထည့်ပါ",
  Dashboard_Employees:"ဝန်ထမ်းများနှင့် ငွေပေးချေသူများ",
  Dashboard_Print:"သင်၏လစာချက်လက်မှတ်များကို ပရင့်ထုတ်ပြီး စာပို့ပါ",
  Dashboard_Payroll:"သင်၏လစာငွေပုံနှိပ်ခြင်းအလိုအလျောက်လုပ်ပါ",
  Dashboard_PayPal:"PayPay Payroll / Payouts ကို စနစ်ထည့်သွင်းခြင်း",
  Begin_Setup:"စတင်သတ်မှတ်ခြင်း",
  Get_Started:"စတင်လိုက်ပါ",
  Purchase:"အဝယ်",
  Lockdown:"အပြင်မထွက်ခိုင်းခြင်း",
  Unlock:"သော့ဖွင့်",
  Detailed:"အသေးစိတ်",
  Log_In:"လော့ဂ်အင်",
  Login:"လော့ဂ်အင်",
  Launch:"ပစ်လွှတ်ခြင်း",
  Register:"မှတ်ပုံတင်",
  Finish:"ပြီးအောင်",
  List:"စာရင်း",
  Weekends:"စနေ၊",
  Weekly:"အပတ်စဉ်",
  PayPal_Default_Subject:"ငွေပေးချေမှုအသစ်",
  Payment_Message:"ငွေပေးချေမှုမက်ဆေ့ခ်ျ",
  PayPal_Default_Payment_Note:"ကျေးဇူးတင်ပါသည်",
  Setup_Your_Cheqing_Account:"အကောင့်စစ်ဆေးခြင်း",
  Add_Your_Primary_Cheqing_Account:"သင်၏အဓိကစစ်ချက်အကောင့်ကိုထည့်ပါ။",
  Welcome_Word:"လုပ်ခလစာနှင့် လူ့စွမ်းအားအရင်းအမြစ်စီမံခန့်ခွဲမှုကို ရိုးရှင်းစေပြီး အလိုအလျောက်လုပ်ပါ။",
  Get_Started_Quickly:"ကျွန်ုပ်တို့ကို စတင်ရန် အထောက်အကူဖြစ်စေမည့် ရိုးရှင်းသောမေးခွန်းအချို့ကို ဖြေပါ...",
  Done_Intro_Word:"သတ်မှတ်မှု အပြီးအစီး",
  PIN_Protected:"စကားဝှက်နှင့် ပင်နံပါတ်ကို ကာကွယ်ထားသည်",
  Enter_New_PIN:"ပင်နံပါတ်ကုဒ်အသစ်ကို ထည့်ပါ-",
  Enter_PIN:"ပင်နံပါတ်ကုဒ်ထည့်ပါ-",
  Invalid_PIN:"မမှန်ကန်သော ပင်နံပါတ် ထည့်သွင်းထားသည်။",
  Account_Identifier:"အကောင့်အမှတ်အသား",
  New_Account_Identifier:"အကောင့်အသစ်သတ်မှတ်သူ",
  attempt:"ကြိုးပမ်းမှု",
  attempts:"ကြိုးစားမှု",
  remaining:"ကျန်",
  Language:"ဘာသာစကား",
  languages:"ဘာသာစကားများ",
  select_languages:"ဘာသာစကားကိုရွေးချယ်ပါ",
  Deposit:"အပ်ငွေ",
  Hire_One_Now:"အခုပဲ ငှားပါ",
  App_Locked:"လျှောက်လွှာကို လော့ခ်ချထားသည်။",
  Skip_:"ကျော်သွား",
  Skip_to_Dashboard:"ဒက်ရှ်ဘုတ်သို့ ကျော်သွားပါ",
  Dashboard:"ဒိုင်ခွက်",
  Import:"သွင်းကုန်",
  Admin:"အုပ်ချုပ်သူများ",
  New_Admin:"အုပ်ချုပ်ရေးမှူးအသစ်",
  Settings:"ဆက်တင်များ",
  Color_Picker:"အရောင်ရွေးချယ်သူ",
  Font_Picker:"ဖောင့်ရွေးချယ်မှု",
  Logout:"ထွက်ရန်",
  Close:"ပိတ်",
  Close_menu:"ပိတ်",
  Excel:"Excel ဖိုင်",
  Csv:"CSV ဖိုင်",
  Sql:"SQL File",
  Json:"JSON ဖိုင်",
  Url:"URL ဖြင့် တင်သွင်းမည်",
  Back:"ပြန်",
  Timers:"တိုင်မာများ",
  Cheque:"စစ်ဆေးခြင်း",
  Print:"ညောင်ပင်",
  Designs:"ဒီဇိုင်းများ",
  Pause_Printing:"ပုံနှိပ်ခြင်းကို ခေတ္တရပ်ရန်",
  Resume_Printing:"ပြန်လည်စတင်ပုံနှိပ်ခြင်း",
  Printing_Paused:"ပုံနှိပ်ခြင်းကို ရပ်ထားသည်",
  PrintingUnavailable:"ဆောရီး! ပုံနှိပ်ခြင်းကို ဤစနစ်တွင် မရရှိနိုင်ပါ။",
  Prints_Paused:"ပုံနှိပ်မှုများ ရပ်ထားသည်",
  Administration:"အုပ်ချုပ်ရေး",
  Loading:"ဒိန်း",
  Unnamed:"အမည်မဖော်လိုသူ",
  error:"တောင်းပန်ပါတယ်၊ ဒီစစ်ဆေးမှုကို ကြည့်ရှုဖို့ မဖွင့်နိုင်ခဲ့ပါ။",
  No_Cheques_To_Print:"ပုံနှိပ်ရန် စစ်ဆေးမှုမရှိပါ",
  No_Cheques_To_Print_Word:"<a routerLink='/folder/Cheque/New'>ချက်ချက်အသစ်</a> ဖန်တီးပါ။",
  New_Cheque:"စစ်ဆေးချက်အသစ်",
  Start_One_Now:"ယခုတစ်ခုစတင်ပါ",
  Edit_Cheque:"တည်းဖြတ်စစ်ဆေးခြင်း",
  Select_Cheques:"စစ်ဆေးမှုများကိုရွေးချယ်ပါ",
  Select_Employee:"ဝန်ထမ်းရွေးပါ",
  Default_Printer:"ပုံသေ ပုံနှိပ်စက်",
  Reassign:"ပြန်လည်တာဝန်ပေးခြင်း",
  Configure:"Configure",
  Template:"ပုံစံ",
  Designer:"ဒီဇိုင်နာ",
  Edit_Designs:"တည်းဖြတ်ဒီဇိုင်းများ",
  New_Design:"ဒီဇိုင်းအသစ်",
  Next:"နောက်တစ်ခု",
  Save:"သိမ်းဆည်း",
  Name:"အမည်",
  Mail:"မေးလ်",
  Amount:"ပမာဏ",
  Date:"ရက်စွဲ",
  PayPal:"PayPal",
  Payouts:"ဆုကြေးငွေများ",
  PayPal_Label:"PayPal Label",
  Email_Username:"အီးမေးလ်/အသုံးပြုသူအမည်",
  Client_ID:"Client ID",
  Sandbox_Email:"Sandbox အီးမေးလ်",
  PayPal_Email:"PayPal အီးမေးလ်",
  PayPal_Username:"API အသုံးပြုသူအမည်",
  PayPal_Payouts:"PayPal ပေးချေမှုများ",
  Select_PayPal_Key:"Paypal Key ကို ရွေးပါ",
  Secret:"လျှို့ဝှက်ချက်",
  API_Secret:"API လျှို့ဝှက်ချက်",
  PayPal_API_Keys:"PayPal သော့များ",
  New_PayPal_Key:" PayPal သော့အသစ်",
  Email_Subject:"အီးမေးလ်အကြောင်းအရာ",
  Email_Message:"အီးမေးလ်စာတို",
  Payout_Options:"ငွေပေးချေမှုရွေးချယ်စရာများ",
  Payment_Note:"ငွေပေးချေမှုမှတ်စု",
  Enable_Employee:"ဝန်ထမ်းကိုဖွင့်ပါ",
  Enable_Production_Mode:"ထုတ်လုပ်မှုမုဒ်ကိုဖွင့်ပါ",
  Sandbox_Username:"Sandbox အသုံးပြုသူအမည်",
  Sandbox_Signature:"Sandbox လက်မှတ်",
  Sandbox_Password:"Sandbox စကားဝှက်",
  Production_Username:"ထုတ်လုပ်မှုအသုံးပြုသူအမည်",
  Production_Signature:"ထုတ်လုပ်မှု လက်မှတ်",
  Production_Password:"ထုတ်လုပ်မှုစကားဝှက်",
  Production_Email:"ထုတ်လုပ်မှုအီးမေးလ်",
  API_Client_ID:"API Client ID",
  API_Signature:"API လက်မှတ်",
  API_Password:"API စကားဝှက်",
  API_Username:"API အသုံးပြုသူအမည်",
  Secret_Key:"လျှို့ဝှက်သော့",
  Sandbox:"သဲပုံး",
  Production:"ထုတ်လုပ်မှု",
  Sandbox_Keys:"Sandbox သော့များ",
  Production_Keys:"ထုတ်လုပ်မှုသော့များ",
  API_Title:"API ခေါင်းစဉ်",
  Production_Mode:"ထုတ်လုပ်မှုမုဒ်",
  Account_Label:"အကောင့်တံဆိပ်",
  No_PayPal_Setup:"PayPay သော့မရှိပါ",
  Enable_PayPal_Account:" PayPal အကောင့်ကိုဖွင့်ပါ",
  No_PayPal_Word:"သင်၏ <a routerLink='/folder/Invoice/New'>PayPal API သော့</a> ကိုထည့်ပါ။",
  Printed_Memo:"ပုံနှိပ်စာတို",
  Employee:"ဝန်ထမ်း",
  View_Employee:"ဝန်ထမ်းကိုကြည့်ပါ",
  Mailing_Address:"စာပို့လိပ်စာ",
  Company_Address:"ကုမ္ပဏီလိပ်စာ",
  Select_Company_Address:"ကုမ္ပဏီလိပ်စာကို ရွေးပါ",
  Address:"လိပ်စာ",
  Any_Day:"ဘယ်နေ့မဆို",
  Address_Name:"လိပ်စာအမည်",
  Unit:"ယူနစ်",
  Account:"အကောင့်",
  Bank_Account:"ဘဏ်အကောင့်",
  Account_Limits:"အကောင့်ကန့်သတ်ချက်များကိုဖွင့်ပါ",
  Payroll:"လစာ",
  New_Payroll:"လစာအသစ်",
  No_Payroll:"လာမည့်လစာငွေ မရှိပါ",
  Upcoming_Holiday:"လာမည့် အားလပ်ရက်",
  Invoice_Due:"ပြေစာပေးရမည့်ရက်-",
  New_Invoice:"ပြေစာအသစ်",
  No_Invoices:"ငွေတောင်းခံလွှာမရှိပါ",
  No_Invoices_Word:"ပထမဆုံး <a routerLink='/folder/Invoice/New'>ပြေ</a>ကို ဖန်တီးပါ။",
  Cheque_Due:"စစ်ချက်ပေးရမည့်ရက်",
  Payrolls:"လစာငွေများ",
  Sandbox_Mode:"Sandbox မုဒ်",
  Hire:"အငှား",
  Pay:"လစာ",
  New:"အသစ်",
  Add:"ထည့်",
  Make:"လုပ်",
  Time:"အချိန်",
  Write:"ရေးပါ",
  Holiday:"အားလပ်ရက်",
  Holidays:"အားလပ်ရက်များ",
  Next_Holiday:"နောက်ထပ် အားလပ်ရက်၊",
  All_Done:"အားလုံးပြီးပြီ!",
  Employees:"ဝန်ထမ်း",
  Payees:"ငွေလက်ခံသူ",
  Job:"အလုပ်",
  Jobs:"အလုပ်များ",
  Invoice:"ပြေစာ",
  Invoices:"ငွေတောင်းခံလွှာများ",
  Vacations:"အားလပ်ရက်များ",
  Cheques:"စစ်ဆေးမှုများ",
  Brand_Cheques:"အမှတ်တံဆိပ်",
  Payment:"ငွေပေးချေမှု",
  Enable_Payment:"ငွေပေးချေမှုကိုဖွင့်ပါ",
  Payments:"ငွေပေးချေမှုများ",
  Schedule:"အချိန်ဇယား",
  Schedules:"အချိန်ဇယားများ",
  Timesheet:"အချိန်ဇယား",
  Timesheets:"အချိန်စာရွက်များ",
  Salary:"လစာ",
  New_Address:"လိပ်စာအသစ်",
  Address_2:"လိပ်စာ (လိုင်း ၂)",
  _City:"မြို့",
  _State:"ပြည်နယ်/သု",
  City:"မြို့/မြို့နယ်",
  State:"ပြည်နယ်/ခရိုင်",
  Postal:"စာတိုက်/စာပို့ကုဒ်",
  ZIP:"စာတိုက်/စာတိုက်",
  Country:"နိုင်ငံ",
  Addresses:"လိပ်စာများ",
  Required_Options:"လိုအပ်သောရွေးချယ်စရာများ",
  Optional_Options:"ရွေးချယ်ခွင့် ရွေးချယ်စရာများ",
  Additional_Options:"နောက်ထပ်ရွေးချယ်စရာများ",
  Required:"လိုအပ်သည်",
  Optional:"ရွေးချယ်ခွင့်",
  Additional:"အပိုဆောင်း",
  No_Addresses:"လိပ်စာမရှိ၊",
  New_Address_Word:"ပထမဆုံး <a routerLink='/folder/Address/New'>လိပ်စာ</a> ကိုထည့်ပါ။",
  Select_Address:"လိပ်စာကို ရွေးပါ",
  No_Address:"လိပ်စာမရှိ၊",
  Print_Cheque:"ပုံနှိပ်စစ်ဆေးခြင်း",
  Print_Cheque_Now:"Print Check Now",
  Description:"ဖော်ပြချက်",
  Pay_To_The_Order_Of:"အမှာစာအတွက် ပေးချေပါ",
  Select_Date_Range:"ရက်စွဲအပိုင်းကို ရွေးပါ",
  Select_Starting_Date:"စတင်မည့်ရက်ကို ရွေးပါ",
  Select_Ending_Date:"ပြီးဆုံးသည့်ရက်စွဲကို ရွေးပါ",
  Select_Date:"ရက်စွဲကို ရွေးပါ",
  Cheque_Date:"စစ်ဆေးသည့်ရက်စွဲ",
  Cheque_Memo:"မှတ်စုတို စစ်ဆေးရန်",
  Blank_Cheque:"အလွတ်စစ်ဆေးခြင်း",
  Blank:"ဗလာ",
  No_Cheques:"စစ်ဆေးမှုမရှိ",
  No_Cheques_Word:"သင်၏ပထမဆုံး <a routerLink='/folder/Cheque/New'>စစ်ဆေးပါ။</a> ကို ပရင့်ထုတ်ပါ။",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"ပုံကိုကြည့်ပါ",
  View:"အမြင်",
  Modify:"ပြုပြင်ရန်",
  Delete:"ဖျက်",
  Cheque_Paid:"အခကြေးငွေ",
  New_Deduction:"နုတ်ယူမှုအသစ်",
  Title:"ခေါင်းစဥ်",
  Frequency:"အကြိမ်ရေ",
  Hourly:"နာရီတိုင်း",
  Daily:"နေ့စဉ်",
  Weekdays:"အပတ်စဉ်",
  BiWeekly:"2 ပတ်",
  TriWeekly:"3 ပတ်",
  Monthly:"လစဉ်",
  MiMonthly:"၂လ",
  Quarterly:"သုံးလပတ်",
  Yearly:"နှစ်စဉ်",
  Every_Week:"အပတ်တိုင်း",
  Every_Payroll:"လစာငွေတိုင်း",
  Every_Month:"လစဉ်လတိုင်း",
  Annually:"နှစ်စဉ်",
  Always_Scheduled:"အမြဲစီစဉ်ထားသည်",
  Start_Date:"စတင်သည့်ရက်စွဲ",
  End_Date:"ပြီးဆုံးရက်စွဲ",
  Start_Time:"စတင်ချိန်",
  End_Time:"ပြီးဆုံးချိန်",
  Deduction_Label:"နုတ်ထွက်တံဆိပ်",
  Notes:"မှတ်စုများ",
  Options:"ရွေးချယ်စရာများ",
  Enable:"ဖွင့်ပါ",
  Select_Deductions:"နုတ်ယူမှုများကို ရွေးပါ",
  Deductions:"နုတ်ယူခြင်း",
  No_Deductions:"အလျှော့မပေးဘူး",
  No_Deductions_Word:"သင်၏ ပထမဆုံး <a routerLink='/folder/Deduction/New'>အဖြတ်အတောက်</a>ကို ဖန်တီးပါ။",
  New_Employee:"ဝန်ထမ်းအသစ်",
  No_Title:"ခေါင်းစဉ်မရှိ",
  _Name:"အမည်",
  About_Yourself:"သင့်အကြောင်း",
  Full_Name:"နာမည်အပြည့်အစုံ",
  Birthday:"မွေးနေ့",
  Email:"အီးမေးလ်",
  SMS:"စာတို",
  Phone:"ဖုန်း",
  Test:"စမ်းသပ်",
  Call:"ခေါ်",
  Fax:"ဖက်စ်",
  Printed_Note:"ပုံနှိပ်မှတ်စု",
  Position:"ရာထူး",
  Job_Position:"အလုပ်ရာထူး",
  Select_a_Job:"အလုပ်တစ်ခုရွေး",
  Select_a_Salary:"လစာကိုရွေးချယ်ပါ",
  Add_a_Deduction:"နုတ်ယူငွေထည့်ပါ",
  Taxes:"အခွန်",
  Add_Taxes:"အခွန်များထည့်ရန်",
  Date_of_Birth:"မွေးရက်",
  Email_Address:"အီးမေးလ်လိပ်စာ",
  Phone_Number:"ဖုန်းနံပါတ်",
  Phone_Call:"ဖုန်းဆက်",
  Enable_on_Payroll:"လစာငွေပေါ်တွင်ဖွင့်ရန်",
  Select_Employees:"ဝန်ထမ်းရွေးပါ",
  No_Employees:"ဝန်ထမ်းမရှိ၊",
  No_Employees_Word:"သင်၏ ပထမဆုံး <a routerLink='/folder/Employee/New'>ဝန်ထမ်း</a> အသစ်ကို ပေါင်းထည့်ပါ။",
  No_Name:"အမည်မရှိ",
  Unemployeed:"အလုပ်လက်မဲ့",
  Employeed:"အလုပ်ရှင်",
  Paid:"အခကြေးငွေ",
  Enabled:"ဖွင့်ထားသည်",
  Disabled:"မသန်စွမ်းသူ",
  Fire:"မီး",
  Not_Available:"မရရှိနိုင်ပါ",
  Not_Available_Word:"သင်၏ပထမဆုံး <a routerLink='/folder/Invoice/New'>ပြေ</a> ကို ပရင့်ထုတ်ပြီး ငွေပေးချေလိုက်ပါ။",
  Select_Invoices:"Select_Invoices",
  Print_Invoice_Word:"သင်၏ပထမဆုံး <a routerLink='/folder/Invoice/New'>ပြေ</a> ကို ပရင့်ထုတ်ပြီး ငွေပေးချေလိုက်ပါ။",
  Invoice_Title:"ပြေစာခေါင်းစဉ်",
  Invoice_Date:"ပြေစာရက်စွဲ",
  Due_Date:"နောက်ဆုံးရက်",
  New_Job:"အလုပ်သစ်",
  Details:"အသေးစိတ်",
  Customize:"စိတ်ကြိုက်",
  Customize_Dashboard:"ဒိုင်ခွက်ကို စိတ်ကြိုက်လုပ်ပါ",
  Components:"အစိတ်အပိုင်းများ",
  No_Components:"အစိတ်အပိုင်းများမပါ",
  Main_Components:"အဓိကအစိတ်အပိုင်းများ",
  Smaller_Components:"သေးငယ်သောအစိတ်အပိုင်းများ",
  Error_Loading_Page:"ဤစာမျက်နှာကို တင်ရာတွင် အမှားအယွင်းရှိသည်။",
  Bank_Details:"ဘဏ်အသေးစိတ်",
  About_Your_Job:"မင်းအလုပ်အကြောင်း",
  Your_Schedule:"မင်းရဲ့ အချိန်ဇယား",
  Job_Title:"အလုပ်အကိုင်အမည်",
  Job_Description:"လုပ်ငန်းတာဝန်သတ်မှတ်ချက်",
  Job_Details:"အလုပ်အသေးစိတ်",
  Enable_Job:"အလုပ်ဖွင့်ပါ",
  Pay_Period:"လစာကာလ",
  Perpetually_Schedule:"ထာဝရအချိန်ဇယား",
  Select_Jobs:"အလုပ်များရွေးချယ်ပါ",
  No_Jobs:"အလုပ်မရှိ၊",
  Add_Jobs:"အလုပ်များထည့်ပါ",
  No_Jobs_Word:"ပထမ <a routerLink='/folder/Job/New'>Job</a> ကို စာရင်းထဲသို့ ထည့်ပါ။",
  Count_Employees:"job.employee_count+' ဝန်ထမ်းများ'",
  Zero_Employees:"၀န်ထမ်း",
  New_Leave:"ခွင့်သစ်",
  Leave_Name:"အမည်ချန်",
  Paid_Leave:"လစာထုတ်ခွင့်",
  Leave_Pay:"ချန်ထားပေး",
  Indefinite_Leave:"အကန့်အသတ်မရှိ ထွက်ခွာခွင့်",
  Indefinite_Payroll:"အကန့်အသတ်မရှိ လုပ်ခလစာ",
  Leave:"ထားခဲ့",
  Add_Schedules:"ဇယားများထည့်ရန်",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"မရှိခြင်းကိုဖွင့်ပါ",
  Select_Leaves:"အရွက်များကိုရွေးချယ်ပါ",
  No_Leaves:"အရွက်မရှိ",
  Leave_Of_Absence:"ပျက်ကွက်ခွင့်",
  Leaves_Of_Absence:"မရှိခြင်းအရွက်",
  New_Leave_of_Absense:"ပျက်ကွက်ခွင့်အသစ်",
  No_Leaves_Word:"ပထမဆုံး <a routerLink='/folder/Leave/New'>ထွက်ခွာခြင်း</a>ကို ထည့်ပါ။",
  Not_Enabled:"မဖွင့်ထားဘူး",
  Absences:"ပျက်ကွက်များ",
  Payee:"ငွေပေးသူ",
  New_Payee:"ငွေလက်ခံသူအသစ်",
  Payee_Identifier:"ငွေပေးသူသတ်မှတ်သူ",
  Payee_Name:"ငွေပေးသူအမည်",
  Payee_Title:"ငွေပေးသူအမည်",
  Payment_Memo:"ငွေပေးချေမှုမှတ်စုတို",
  Select_Payees:"ငွေလက်ခံသူ ရွေးပါ",
  No_Payees:"ငွေလက်ခံသူမရှိ",
  Add_Payee_Note:"ပထမဆုံး <a routerLink='/folder/Payee/New'>ငွေလက်ခံသူ</a> ကိုထည့်ပါ။",
  New_Payees:"ငွေလက်ခံသူအသစ်",
  About_The_Payment_Schedule:"ငွေပေးချေမှုအချိန်ဇယား",
  Your_Payroll_Schedule:"အလိုအလျောက်လစာ",
  New_Payment:"ငွေပေးချေမှုအသစ်",
  Identifier:"သတ်မှတ်သူ",
  Select:"ရွေးချယ်ပါ",
  Memo:"မှတ်စုတို",
  Payment_Date:"ငွေပေးချေသည့်နေ့ရက်",
  Mark_as_Paid:"အခကြေးငွေအဖြစ် မှတ်ပါ",
  Select_Payments:"ငွေပေးချေမှုများကို ရွေးချယ်ပါ",
  No_Payments:"ငွေပေးချေမှု မရှိပါ",
  No_Payments_Word:"ပထမဆုံး <a routerLink='/folder/Payment/New'>ငွေပေးချေမှု</a>ကို ဖန်တီးပါ။",
  Create_Payroll:"လစာငွေဖန်တီးရန်",
  Properties:"ဂုဏ်သတ္တိ",
  Payroll_Title:"လစာခေါင်းစဉ်",
  Payroll_Notes:"လစာမှတ်စုများ",
  Payroll_Setup:"လစာငွေ သတ်မှတ်မှု",
  Tabulate_Payments:"ငွေပေးချေမှုစာရင်းဇယား",
  Tabulate:"Tabulate",
  By:"By:",
  Payments_By:"ငွေပေးချေမှုများ",
  Timesheets_And_Schedules:"အချိန်စာရင်းများနှင့် အချိန်ဇယားများ",
  Both:"နှစ်ခုလုံး",
  Items:"ပစ္စည်းများ",
  Add_Payees:"ငွေထည့်ရန်",
  Add_Account:"အကောင့်ထည့်ပါ",
  Enable_Account:"အကောင့်ဖွင့်ရန်",
  Enable_Payroll:"လစာငွေကိုဖွင့်ပါ",
  Print_Payroll:"လစာထုတ်ယူခြင်း",
  No_Payrolls:"လစာမတိုးဘူး",
  No_Payroll_Word:"သင်၏ ပထမဆုံး <a routerLink='/folder/Payroll/New'>လစာငွေ</a>ကို ဖန်တီးပါ။",
  View_more:"VIEW_MORE",
  Less:"နည်းသော",
  Add_Payroll:"လစာထည့်ရန်",
  Select_Payroll:"လစာငွေကို ရွေးပါ",
  About_Your_Salary:"မင်းရဲ့လစာအကြောင်း",
  Add_Salaries:"လစာထည့်ပါ",
  Add_Salary:"လစာထည့်ပါ",
  Salaries:"လစာ",
  No_Salaries:"လစာမရှိဘူး",
  No_Salaries_Word:"ပထမဆုံး <a routerLink='/folder/Salary/New'>လစာ</a> ကိုထည့်ပါ။",
  Select_Salaries:"လစာကိုရွေးချယ်ပါ",
  New_Salary:"လစာအသစ်",
  Salary_Name:"လစာသတ်မှတ်ချက်",
  Enable_Salary:"လစာဖွင့်ပါ",
  Salary_Amount:"လစာငွေပမာဏ",
  New_Schedule:"အချိန်ဇယားအသစ်",
  Schedule_Title:"အချိန်ဇယားခေါင်းစဉ်",
  Add_Address:"လိပ်စာထည့်ပါ",
  Repeat:"အဖန်ဖန်",
  Design:"ဒီဇိုင်း",
  Edit_Design:"ဒီဇိုင်းပြင်ဆင်ရန်",
  Edit_this_Design:"ဒီဒီဇိုင်းကို တည်းဖြတ်ပါ",
  Repeat_Payment:"ငွေပေးချေမှု ထပ်လုပ်ပါ",
  Enable_Schedule:"အချိန်ဇယားကိုဖွင့်ပါ",
  Never:"ဘယ်တော့မှ",
  Select_Schedule:"အချိန်ဇယားများကိုရွေးချယ်ပါ",
  No_Schedules:"အချိန်ဇယားမရှိပါ",
  No_Schedules_Word:"ပထမဆုံး <a routerLink='/folder/Schedule/New'>အချိန်ဇယား</a> ကို ဖန်တီးပါ။",
  New_Administrator:"အုပ်ချုပ်ရေးမှူးအသစ်",
  Username:"အသုံးပြုသူအမည်",
  First_Name:"နာမည်",
  Last_Name:"မျိုးနွယ်အမည်",
  Add_an_Address:"လိပ်စာထည့်ပါ",
  Payment_Limit:"ငွေပေးချေမှုကန့်သတ်ချက်",
  Total_Limit:"စုစုပေါင်းကန့်သတ်ချက်",
  Select_Accounts:"အကောင့်များကိုရွေးချယ်ပါ",
  No_Administrators:"အုပ်ချုပ်သူမရှိ၊",
  No_Administrators_Word:"ပထမဆုံး <a routerLink='/folder/Administrator/New'>စီမံခန့်ခွဲသူအကောင့်</a> ကို ဖန်တီးပါ။",
  New_Administrators_Word:"ပထမဆုံး <a routerLink='/folder/Administrator/New'>အုပ်ချုပ်ရေးမှူး</a> ကိုထည့်ပါ၊",
  Cloud:"တိမ်တိုက်",
  Backup:"အရန်သိမ်းခြင်း",
  Enable_iCloud:"iCloud ကိုဖွင့်ပါ",
  Enable_Google_Drive:"Google Drive ကိုဖွင့်ပါ",
  Enable_Microsoft_OneDrive:"Microsoft OneDrive ကိုဖွင့်ပါ",
  Automatically_Backup:"အလိုအလျောက် Backup",
  FTP_Settings:"FTP ဆက်တင်များ",
  URL_File_Prompt:"ကျေးဇူးပြု၍ တင်သွင်းရန် .xls / .xlsx / .json ဖိုင်အတွက် တည်နေရာကို သတ်မှတ်ပါ-",
  URL_SQL_Prompt:"ကျေးဇူးပြု၍ တင်သွင်းရန် SQLite ဖိုင်၏တည်နေရာကို သတ်မှတ်ပါ-",
  FTP_Backup:"FTP Backup",
  FTP_Import:"FTP တင်သွင်းမှု",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"အိမ်ရှင်",
  Port:"ဆိပ်ကမ်း",
  Path:"မဂ်",
  Data_Path:"ဒေတာလမ်းကြောင်း",
  Enable_FTP_Account:"FTP အကောင့်ကိုဖွင့်ပါ",
  HostnameIP:"အိမ်ရှင်အမည်",
  Password:"စကားဝှက်",
  Connection_Port:"Connection Port",
  Enable_MySQL_Database:"MySQL Database ကိုဖွင့်ပါ",
  Log:"လော့ဂ်",
  Reset:"ပြန်လည်သတ်မှတ်ခြင်း",
  Erase:"ဖျက်ရန်",
  Export:"ပို့ကုန်",
  Database:"ဒေတာဘေ့စ်",
  Upload_CSV:"CSV အပ်လုဒ်လုပ်ခြင်း",
  Download_CSV:"CSV ဒေါင်းလုဒ်လုပ်ရန်",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL Backup",
  Internal_Notes:"အတွင်းရေးမှတ်စုများ",
  Root_Path:"အမြစ်လမ်း",
  Select_Backup:"အရန်သိမ်းခြင်းကို ရွေးပါ",
  Add_New_Backup:"အရန်အသစ်တစ်ခုထည့်ပါ",
  First_Backup:"ပထမအရန်ကူးယူမှုကို သတ်မှတ်ပါ...",
  Backups:"အရန်သိမ်းဆည်းမှုများ",
  Add_Backup:"အရန်ကူးထည့်ပါ",
  No_Backups:"အရန်ကူးယူမှုများကို ရှာမတွေ့ပါ။",
  Select_Backup_Type:"သင်ထည့်သွင်းလိုသော အရန်အရန်အမျိုးအစားကို ရွေးပါ...",
  Backup_Type:"အရန်သိမ်းခြင်းအမျိုးအစား",
  FTP_Drive:"FTP Drive",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"မောင်း",
  Microsoft_OneDrive:"မောင်း",
  Import_Fields:"သွင်းကုန်နယ်ပယ်များ",
  Import_Fields_Word:"<a routerLink='/folder/Holiday/New'>ဒေတာတင်သွင်း</a> ကို ရွေးရန် ဤကဏ္ဍကို အသုံးပြုပါ။",
  Upload:"အပ်လုဒ်",
  Download:"ဒေါင်းလုဒ်",
  Download_JSON:"JSON Data အဖြစ် ဒေါင်းလုဒ်လုပ်ပါ",
  Download_SQL:"SQL ဖိုင်အဖြစ်ဒေါင်းလုဒ်လုပ်ပါ",
  New_Bank:"ဘဏ်သစ်",
  New_Account:"အကောင့်အသစ်",
  New_Bank_Account:"ဘဏ်အကောင့်အသစ်",
  Upload_Image:"ပုံတင်ခြင်း",
  Bank_Name:"ဘဏ်အမည်",
  Bank_Address:"ဘဏ်လိပ်စာ",
  Branch_Address:"ဘဏ်ခွဲလိပ်စာ",
  Address_on_Cheque:"လိပ်စာ",
  Cheque_Numbers:"နံပါတ်များစစ်ဆေးခြင်း",
  Cheque_Details:"အသေးစိတ်စစ်ဆေးခြင်း",
  Bank_Logo:"ဘဏ်လိုဂို",
  Cheque_ID:"စစ် ID",
  Starting_Cheque_ID:"စတင်စစ်ဆေး ID",
  Transit_Number:"ယာဉ်လိုင်းနံပါတ်",
  Institution_Number:"အင်စတီကျူးရှင်းနံပါတ်",
  Designation_Number:"သတ်မှတ်နံပါတ်",
  Account_Number:"အကောင့်နံပါတ်",
  Limits:"ကန့်သတ်ချက်များ",
  Default_Limits:"မူလကန့်သတ်ချက်များ",
  Over_Limit:"ကန့်သတ်ချက်ကျော်",
  Under_Limit:"ကန့်သတ်ချက်အောက်",
  Payroll_Limit:"လစာကန့်သတ်ချက်",
  Enable_Bank_Account:"ဘဏ်အကောင့်ဖွင့်ရန်",
  Select_Account:"အကောင့်ကိုရွေးချယ်ပါ",
  No_Bank_Account:"ဘဏ်အကောင့်မရှိဘူး",
  No_Bank_Account_Word:"ပထမဆုံး <a routerLink='/folder/Accounts/New'>ဘဏ်အကောင့်</a> ကိုထည့်ပါ။",
  Bank_Accounts:"ဘဏ်စာရင်းများ",
  No_Accounts:"အကောင့်မရှိပါ",
  No_Accounts_Note:"ပထမဆုံး <a routerLink='/folder/Accounts/New'>ဘဏ်အကောင့်</a> ကိုထည့်ပါ။",
  Cheque_Designer:"ဒီဇိုင်နာ စစ်ဆေးခြင်း",
  Cheque_Design:"ဒီဇိုင်းစစ်ဆေးခြင်း",
  Select_Design:"ဒီဇိုင်းရွေးပါ",
  Cheque_Designs:"ဒီဇိုင်းများကိုစစ်ဆေးပါ",
  No_Cheque_Designs:"မစစ်ဆေးရသေးသော ဒီဇိုင်းများ",
  No_Cheque_Designs_Word:"သင့်ကိုယ်ပိုင် <a routerLink='/folder/Settings/Cheque/Design/New'>ဒီဇိုင်းစစ်ဆေးပါ။</a> ကို ဖန်တီးပါ။",
  Set_Default:"မူရင်းအဖြစ်သတ်မှတ်မည်",
  Default:"ပုံသေ",
  Remove:"ဖယ်",
  Folder:"ဖိုလ်",
  Edit:"တည်းဖြတ်",
  LoadingDots:"ဖွင့်နေသည်...",
  Add_a_New_File:"Add a <a href='#' (click)='add()'>ဖိုင်အသစ်</a> သို့",
  this_folder:"ဤဖိုင်တွဲ",
  FTP_Backup_Settings:"FTP Backup ဆက်တင်များ",
  Secure_File_Transfer:"Secure File Transfer",
  New_Holiday:"အားလပ်ရက်အသစ်",
  Add_Holiday:"အားလပ်ရက်ထည့်ပါ",
  Holiday_Name:"အားလပ်ရက်အမည်",
  Additional_Pay:"အပိုဆောင်းကြေး",
  Enable_Holiday:"အားလပ်ရက်ကိုဖွင့်ပါ",
  Select_Holidays:"အားလပ်ရက်များကိုရွေးချယ်ပါ",
  No_Holidays:"ပိတ်ရက်မရှိ",
  No_Holidays_Word:"ပထမဆုံး <a routerLink='/folder/Holiday/New'>အများပြည်သူပိတ်ရက်</a> ကိုထည့်ပါ။",
  New_Import:"သွင်းကုန်အသစ်",
  Import_Data:"ဒေတာတင်သွင်းခြင်း",
  Import_Data_Word:"သင်ရွေးချယ်သည့် ဖိုင်အမျိုးအစား သို့မဟုတ် အပ်လုဒ်လုပ်နည်းကို ရွေးပါ။<br /> ပံ့ပိုးထားသောဖိုင်ကို အပ်လုဒ်တင်ပြီးနောက် အက်ပ်အတွင်းရှိ မည်သည့်ကန့်သတ်ဘောင်နှင့် ကိုက်ညီသည့်ဖိုင်တစ်ဖိုင်မှ တင်သွင်းသည့်အကွက်များကို သင်ရွေးချယ်နိုင်မည်ဖြစ်သည်။",
  Import_File:"ဖိုင်တင်သွင်းခြင်း",
  Link_To_File:"ဖိုင်သို့လင့်ခ်",
  Select_File:"ဖိုင်ကိုရွေးပါ",
  New_Moderator:"ထိန်းညှိသူအသစ်",
  Allow_Moderation:"စိစစ်ခွင့်ပြုပါ",
  Create_Paycheques:"လစာချက်လက်မှတ်များဖန်တီးပါ",
  Edit_Paycheques_and_Data:"လစာနှင့် ဒေတာကို တည်းဖြတ်ရန်",
  Destroy_Data_and_Paycheques:"ဒေတာနှင့် လစာချက်များကို ဖျက်ပါ",
  Bonus_Percentage:"လစာပေးချေမှုရာခိုင်နှုန်း",
  Fixed_Amount:"ပုံသေငွေပမာဏ",
  Select_Moderator:"စိစစ်ရွေးချယ်ပါ",
  No_Moderators:"ကြီးကြပ်သူများမရှိပါ",
  Moderators:"ထိန်းကျောင်းသူများ",
  Moderator_Account:"ပထမဆုံး <a routerLink='/folder/Administrator/New'>ထိန်းညှိပေးသည်။ အကောင့်</a>ကို ဖန်တီးပါ။",
  No_Moderators_Word:"ပထမဆုံး <a routerLink='/folder/Administrator/New'>ထိန်းညှိပေးသည်။</a> ကိုထည့်ပါ။",
  Defaults:"မူရင်း",
  Provide_Us_Info:"ကျွန်ုပ်တို့၏ အချက်အလက်ကို ပေးပါ",
  Setup_Your_Printer:"သင်၏ပရင်တာတပ်ဆင်ခြင်း",
  Your_Company:"မင်းရဲ့ကုမ္ပဏီအကြောင်း",
  Company_Name:"ကုမ္ပဏီအမည်",
  Currency:"ငွေကြေး",
  Default_Currency:"မူရင်းငွေကြေး",
  Base_Monthly_Income:"လစဉ်ဝင်ငွေ",
  Protection:"ကာကွယ်ရေး",
  App_Protection:"အက်ပ်ကာကွယ်ရေး",
  PIN_Code_Protection:"ပင်နံပါတ် အကာအကွယ်",
  App_Protection_Word:"သင့်အကောင့်ကို ကာကွယ်ပေးသော လုံခြုံရေးနည်းလမ်းများကို ဖွင့်ပါ။",
  PIN_Code:"ပင်နံပါတ်",
  Change_PIN:"ပင်နံပါတ်ပြောင်းရန်",
  New_Password:"စကားဝှကိအသစ်",
  Geofence_Protection:"ဘူမိခြံစည်းရိုးကာကွယ်ရေး",
  Geofence_Area:"သတ်မှတ်ဧရိယာ",
  Distance:"အကွာအဝေး",
  Setup_Now:"ယခု Setup",
  Mile:"မိုင်",
  Km:"ကီလိုမီတာ",
  m:"ဍ",
  Facial_Recognition:"မျက်နှာ အသိအမှတ်ပြုခြင်း",
  Face:"မျက်နှာ",
  Setup:"တည်ဆောက်သည်",
  Label:"တံဆိပ်",
  Password_Protection:"စကားဝှက်ကာကွယ်ရေး",
  Modify_Password:"စကားဝှက်ကို ပြုပြင်ပါ",
  New_Tax_Entry:"အခွန်အသစ်ဝင်ခြင်း",
  New_Tax:"အခွန်အသစ်",
  Tax_Label:"အခွန်တံဆိပ်",
  Perpetually_Enabled:"အမြဲတမ်း Enabled",
  Select_Taxes:"အခွန်များရွေးချယ်ပါ",
  Tax_Deductions:"အခွန် နုတ်ယူခြင်း",
  No_Tax_Deductions:"အခွန် နုတ်ယူခြင်း မရှိပါ",
  No_Tax_Deductions_Word:"ပထမ <a routerLink='/folder/Tax/New'>အခွန်</a> နုတ်ယူခြင်းကို ထည့်ပါ။",
  New_Timer:"အချိန်တိုင်းမ်အသစ်",
  Start:"စတင်",
  Stop:"ရပ်",
  Start_Timer:"စတင်ချိန်တိုင်းမ်",
  Stop_Timer:"မှတ်တိုင်မှတ်တိုင်",
  Timer_Active:"တိုင်မာ လှုပ်လှုပ်ရှားရှား",
  Timer:"တိုင်မာ-",
  Timer_Running:"တိုင်မာ- (ပြေးနေ)",
  Save_Timer:"အချိန်တိုင်းကို သိမ်းဆည်းပါ",
  New_Timesheet:"အချိန်သစ်စာရွက်",
  Select_Timesheets:"အချိန်စာရွက်များကိုရွေးချယ်ပါ",
  Work_Notes:"အလုပ်မှတ်စုများ",
  Entry_Title:"ဝင်ခွင့်ခေါင်းစဉ်",
  No_Timesheets:"အချိန်မရှိသောစာရွက်များ",
  No_Timesheets_Word:"ပထမဆုံး <a routerLink='/folder/Timesheet/New'>အချိန်ဇယား</a> ကိုထည့်ပါ။",
  Timesheet_Submitted:"အချိန်စာရင်း တင်ပြခြင်း",
  Timesheet_Congrats:"ဂုဏ်ယူပါတယ်။ မင်းရဲ့အချိန်စာရွက်ကို အောင်မြင်စွာ တင်သွင်းပြီးပါပြီ။ ကျေးဇူးတင်ပါတယ်။",
  Timesheet_Copy:"သင်၏စာရွက်စာတမ်းများ၏မိတ္တူကိုလက်ခံရရှိရန် ကျေးဇူးပြု၍ သင့်အီးမေးလ်လိပ်စာနှင့်/သို့မဟုတ် မိုဘိုင်းဖုန်းနံပါတ်ကို ပေးဆောင်ပါ။",
  Submit:"တင်ပြမည်",
  Allow_Notifications:"အသိပေးချက်များကို ခွင့်ပြုရန်",
  Untitled_Entry:"ဝင်ရောက်မှုအသစ်",
  Untitled_Bank:"အမည်မဲ့ဘဏ်",
  Timesheet_Entry:"အချိန်စာရင်းဝင်ခြင်း",
  Work_Description:"အလုပ်ဖော်ပြချက်",
  Enable_Timesheet:"အချိန်စာရင်းကိုဖွင့်ပါ",
  Submit_Timesheet:"အချိန်စာရင်းတင်ပြရန်",
  Vacation:"အားလပ်ရက်",
  New_Vacation:"အားလပ်ရက်အသစ်",
  Vacation_Name:"အားလပ်ရက်အမည်",
  Paid_Vacation:"အခပေး အပန်းဖြေခရီး",
  Vacation_Pay:"အားလပ်ရက်လစာ",
  Enable_Vacation:"အားလပ်ရက်ကိုဖွင့်ပါ",
  Select_Vacations:"အားလပ်ရက်များကိုရွေးချယ်ပါ",
  No_Vacations:"အားလပ်ရက်မရှိ",
  No_Vacations_Word:"ပထမဆုံး <a routerLink='/folder/Vacation/New'>အားလပ်ရက်</a> entry ကို ဖန်တီးပါ။",
  Payroll_Schedule:"လစာစာရင်းဇယား",
  Next_Payroll:"နောက်ထပ် လစာ-",
  Upcoming_Payroll:"လာမယ့်လစာငွေ",
  No_Upcoming_Payroll:"လာမည့်လစာငွေ မရှိပါ",
  Address_Book:"လိပ်စာစာအုပ်",
  Archived_Documents:"သိမ်းဆည်းထားသောစာရွက်စာတမ်းများ",
  Dev_Mode:"ဖွံ့ဖြိုးရေးမုဒ်တွင် အက်ပ်လီကေးရှင်း",
  Administrators:"အုပ်ချုပ်သူများ",
  Privacy:"ကိုယ်ရေးကိုယ်တာ",
  None:"မရှိဘူး",
  Select_Signature:"လက်မှတ်ကိုရွေးချယ်ပါ",
  No_Signatures:"လက်မှတ်မရှိ၊",
  No_Signatures_Word:"ပထမဆုံး <a routerLink='/folder/Signature/New'>လက်မှတ်</a> ကိုထည့်ပါ။",
  Repeat_Indefinitely:"အကန့်အသတ်မရှိ ပြန်လုပ်ပါ",
  Sign:"ဆိုင်းဘုတ်",
  Sign_Here:"ဒီမှာ လက်မှတ်ထိုး",
  Date_Signed:"လက်မှတ်ထိုးသည့်နေ့",
  Signature_Pad:"Signature Pad",
  Account_Holder:"အကောင့်ပိုင်ရှင်",
  Account_Properties:"အကောင့်ပိုင်ဆိုင်မှု",
  Name_On_Cheque:"စစ်ချက်အမည်",
  Server_Hostname:"ဆာဗာ Hostname / IP",
  Printers:"ပုံနှိပ်စက်များ",
  No_Printers:"ပုံနှိပ်စက်မရှိ၊",
  Printer_Exists:'ဤအမည်ဖြင့် ပရင်တာတစ်ခု ရှိနှင့်ပြီးဖြစ်သည်။',
  No_Printers_Word:"ပထမဆုံး <a routerLink='/folder/Printer/New'>ပုံနှိပ်စက်</a> ကိုထည့်ပါ။",
  No_Printer_Alert:"ပရင်တာကို သတ်မှတ်ထားခြင်း မရှိပါ။ သင်သည် ပရင်တာတစ်ခုကို စနစ်ထည့်သွင်းလိုပါသလား။",
  Add_Printer:"ပရင်တာထည့်ပါ",
  New_Printer:"ပုံနှိပ်စက်အသစ်",
  Printer_Hostname:"ပရင်တာ Hostname / IP",
  Printer_Label:"ပရင်တာတံဆိပ်",
  Printer_Protocol:"ပရင်တာ ပရိုတိုကော",
  Protocol:"ပရိုတိုကော",
  Email_Print:"အီးမေးလ်",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"socket",
  Print_Job:"ပုံနှိပ်အလုပ်",
  Printed:"ပုံနှိပ်",
  Not_Printed:"မပုံနှိပ်ဘူး",
  Print_Jobs:"ပုံနှိပ်အလုပ်များ",
  Print_Queue:"ပုံနှိပ်တန်းစီ",
  No_Print_Jobs:"ပုံနှိပ်အလုပ်မရှိ၊",
  No_Prints:"ပရင့်ထုတ်ရန် <a routerLink='/folder/Cheque/New'>စစ်ဆေးပါ။</a> အသစ်ကို ဖန်တီးပါ။",
  Prints:"ပုံနှိပ်",
  Find_Printer:"ပရင်တာရှာပါ",
  Find_AirPrint_Devices:"AirPrint Devices ကိုရှာပါ",
  Select_Printer:"ပရင်တာကို ရွေးပါ",
  System_UI:"စနစ် UI",
  Printer:"ပရင်တာ",
  Status:"အဆင့်အတန်း",
  Preview:"အကြိုသုံးသပ်ချက်",
  Enable_Print_Job:"ပုံနှိပ်လုပ်ငန်းကိုဖွင့်ပါ",
  Queue_Weight:"Queue Weight",
  Unlimited:"အကန့်အသတ်မရှိ",
  Show_Advanced_Printer_Options:"အဆင့်မြင့် ပရင်တာ ရွေးချယ်မှုများကို ပြရန်",
  Advanced:"အဆင့်မြင့်",
  Location:"တည်နေရာ",
  Note:"မှတ်ချက်",
  Queue_Name:"တန်းစီအမည်",
  Pages_Printed_Limit:"ပုံနှိပ်ထားသော စာမျက်နှာများ ကန့်သတ်ချက်",
  MultiPage_Idle_Time:"Multipage Wait Time (s)",
  Page_Count_Limit:"စာမျက်နှာအရေအတွက် ကန့်သတ်ချက်",
  Page_Orientation:"စာမျက်နှာ ဦးတည်ချက်",
  Portrait:"ပုံတူ",
  Landscape:"ရှုခင်း",
  Duplex:"နှစ်ထပ်",
  Double_Sided_Printing:"နှစ်ထပ်",
  Duplex_Mode:"Duplex Mode",
  Duplex_Short:"အတို",
  Duplex_Long:"ရှည်",
  Duplex_None:"မရှိဘူး",
  Color_And_Quality:"အရောင်နှင့် အရည်အသွေး",
  Monochrome:"မိုနိုခရုမ်း",
  Photo_Quality_Prints:"ဓာတ်ပုံအရည်အသွေး ပုံနှိပ်ခြင်း",
  Printer_Email:"ပရင်တာအီးမေးလ်",
  Automatically_Downsize:"အလိုအလျောက် အရွယ်အစားကို လျှော့ချခြင်း",
  Paper:"စက္ကူ",
  Paper_Name:"စက္ကူအမည်",
  Paper_Width:"စက္ကူအကျယ်",
  Paper_Height:"စက္ကူအမြင့်",
  Paper_Autocut_Length:"စက္ကူ အလိုအလျောက်ဖြတ်သည့် အရှည်",
  Margins:"အနားသတ်",
  Page_Margins:"စာမျက်နှာအနားသတ်များ",
  Page_Margin_Top:"ထိပ်တန်းစာမျက်နှာအနားသတ်",
  Page_Margin_Right:"ညာဘက်စာမျက်နှာအနားသတ်",
  Page_Margin_Bottom:"အောက်ခြေစာမျက်နှာအနားသတ်",
  Page_Margin_Left:"ဘယ်စာမျက်နှာအနားသတ်",
  Add_Employees:"ဝန်ထမ်းထည့်ရန်",
  Header:"ခေါင်းစီး",
  Print_A_Page_Header:"Print A Page Header",
  Header_Label:"ခေါင်းစီးတံဆိပ်",
  Header_Page_Index:"ခေါင်းစီးစာမျက်နှာအညွှန်း",
  Header_Font_Name:"ခေါင်းစီးဖောင့်",
  Select_Font:"ဖောင့်ကို ရွေးပါ",
  Font_Selector:"ဖောင့်ရွေးချယ်မှု",
  Header_Font_Size:"ခေါင်းစီးဖောင့်",
  Header_Bold:"ခေါင်းစီး Bold",
  Header_Italic:"ခေါင်းစီးစာလုံးစောင်း",
  Header_Alignment:"ခေါင်းစဥ်ချိန်ညှိခြင်း",
  Left:"ဘယ်",
  Center:"ဗဟို",
  Right:"မှန်တယ်",
  Justified:"ကုသိုလ်",
  Header_Font_Color:"ခေါင်းစီးအရောင်",
  Select_Color:"အရောင်ရွေးပါ",
  Footer:"အောက်ခြေ",
  Print_A_Page_Footer:"စာမျက်နှာအောက်ခြေမှတ်ရာကို ပရင့်ထုတ်ရန်",
  Footer_Label:"အောက်ခြေအညွှန်း",
  Footer_Page_Index:"အောက်ခြေစာမျက်နှာ အညွှန်း",
  Footer_Font_Name:"အောက်ခြေဖောင့်",
  Fonts:"ဖောင့်များ",
  Done:"ပြီးပြီ",
  Select_Fonts:"ဖောင့်များကို ရွေးပါ",
  Footer_Font_Size:"အောက်ခြေအရွယ်အစား",
  Footer_Bold:"Footer Bold",
  Footer_Italic:"အောက်ခြေစာလုံးစာလုံးစောင်း",
  Footer_Alignment:"Footer Alignment",
  Footer_Font_Color:"အောက်ခြေအရောင်",
  Page_Copies:"စာမျက်နှာမိတ္တူများ",
  Enable_Printer:"ပရင်တာကိုဖွင့်ပါ",
  Remote_Logging:"အဝေးမှ မှတ်တမ်းသွင်းခြင်း",
  Log_Server:"လော့ဂ်ဆာဗာ",
  Encryption:"ကုဒ်ဝှက်ခြင်း",
  Random_Key:"ကျပန်းသော့",
  Encryption_Key:"ကုဒ်ဝှက်ခြင်းကီး",
  Cheques_Webserver:"စိတ်ကြိုက်ဒေတာဘေ့စ်",
  Learn_How:"ဘယ်လိုလေ့လာပါ",
  Signature:"လက်မှတ်",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"လက်မှတ်ကိုကြည့်ပါ",
  Printed_Signature:"ပုံနှိပ်လက်မှတ်",
  Digitally_Sign:"ဒစ်ဂျစ်တယ်သင်္ကေတ",
  Digital_Signature:"ဒစ်ဂျစ်တယ်လက်မှတ်",
  Add_Signature:"လက်မှတ်ထည့်ပါ",
  Add_Your_Signature:"မင်းရဲ့ လက်မှတ်ထည့်ပါ",
  Enable_Signature:"လက်မှတ်ကိုဖွင့်ပါ",
  Digitally_Signed:"ဒစ်ဂျစ်တယ်လက်မှတ်ထိုးခြင်း",
  Insert_Error:"ဒေတာဘေ့စ်ပြဿနာကြောင့် စစ်ဆေးသိမ်းဆည်း၍မရပါ။",
  Delete_Confirmation:"ဤအချက်အလက်ကို ဖျက်လိုသည်မှာ သေချာပါသလား။",
  Discard_Confirmation:"ဒီအချက်အလက်တွေကို စွန့်ပစ်ချင်တာ သေချာလား",
  Discard_Bank_Confirmation:"ဒီအကောင့်ကို စွန့်ပစ်ချင်တာ သေချာလား",
  Discard_Printer_Confirmation:"ဒီပုံနှိပ်စက်ကို စွန့်ပစ်ချင်တာ သေချာလား",
  Delete_Bonus_Confirmation:"ဒီဘောနပ်စ်ကို ဖျက်ချင်တာ သေချာလား",
  Delete_Invoice_Confirmation:"ဤငွေတောင်းခံလွှာကို ဖျက်လိုသည်မှာ သေချာပါသလား။",
  Generated_Cheque:"ထုတ်ပေးသော စစ်ဆေးခြင်း",
  Generated_Invoice:"ထုတ်ပေးသောပြေစာ",
  Schedule_Start_Time:"အချိန်ဇယား စတင်ခြင်း",
  Schedule_End_Time:"ဇယားအဆုံး",
  New_Call:"ခေါ်ဆိုမှုအသစ်",
  No_Contacts:"အဆက်အသွယ်မရှိပါ",
  No_Contacts_Word:"စီမံခန့်ခွဲသူ၊ ကြီးကြပ်သူ၊ ဝန်ထမ်းများ၊ နှင့် ငွေပေးချေသူများသည် အဆက်အသွယ်များအဖြစ် ပေါ်လာသည်။",
  PDF_Subject:"ဘဏ္ဍာရေး",
  PDF_Keywords:"လစာငွေပေးချေမှု PDF ချက်လက်မှတ်များ",
  Printer_Setup:"ပရင်တာတပ်ဆင်မှု",
  Printer_Selection:"ပရင်တာရွေးချယ်မှု",
  New_Fax:"ဖက်စ်အသစ်",
  New_Fax_Message:"ဖက်စ်စာအသစ်",
  Fax_Machine:"ဖက်စ်စက်",
  Fax_Name:"ဖက်စ်အမည်",
  Fax_Email:"ဖက်စ်အီးမေးလ်",
  Fax_Number:"ဖက်စ်နံပါတ်",
  Contents:"အကြောင်းအရာ",
  Fax_Body:"စာမျက်နှာကိုယ်ထည်",
  Header_Word:"ခေါင်းစီး-",
  Header_Text:"ခေါင်းစီးစာသား",
  Include_Header:"ခေါင်းစီးပါဝင်သည်",
  Include_Footer:"အောက်ခြေမှတ်စု ပါဝင်မည်",
  Footer_Word:"အောက်ခြေမှတ်:",
  Footer_Text:"အောက်ခြေစာသား",
  Attach_a_Cheque:"ချက်လက်မှတ် ပူးတွဲပါ",
  Add_Deduction:"နုတ်ယူငွေထည့်ပါ",
  Enable_Fax:"ဖက်စ်ပို့ရန်",
  Select_Fax:"ဖက်စ်ကို ရွေးပါ",
  No_Faxes:"ဖက်စ်မရှိ၊",
  Faxes:"ဖက်စ်များ",
  Save_and_Send:"ဖက်စ်ပို့ရန်",
  Save_and_Pay:"Save and Pay",
  WARNING:"သတိပေးချက်-",
  Remember:"မှတ်ထား",
  Printing:"ပုံနှိပ်ခြင်း",
  Printing_Complete:"ပုံနှိပ်ခြင်းအပြီးသတ်!\n\n",
  of:"၏",
  There_Were:"ရှိခဲ့ပါတယ် ",
  Successful_Prints:"အောင်မြင်သောပုံနှိပ်များနှင့်",
  Unsuccessful_Prints:"မအောင်မြင်သော ပုံများ",
  PrinterError:"ဆောရီး! အမှားအယွင်းရှိခဲ့တယ်။",
  Printing_:"ပုံနှိပ်ခြင်း...",
  PrinterSuccess:"စာရွက်စာတမ်းကို အောင်မြင်စွာ ပုံနှိပ်ပြီးပါပြီ။",
  PrintersSuccess:"စာရွက်စာတမ်းများ ပုံနှိပ်ပြီးပါပြီ။",
  Print_Cheques:"ပုံနှိပ်စစ်ဆေးမှုများ",
  New_Message:"သတင်းသစ်",
  New_Messages:"မက်ဆေ့ခ်ျအသစ်များ",
  Read_Message:"စာဖတ်ရန်",
  Write_Message:"စာရေးပါ",
  Send_Message:"စာပို့ပါ",
  Subject:"ဘာသာရပ်",
  Message:"သတင်းစကား",
  Writing:"စာရေး...",
  Send:"ပို့",
  Set_Date:"သတ်မှတ်ရက်စွဲ",
  Set_Time:"အချိန်သတ်မှတ်",
  Recieve:"လက်ခံ",
  Set_as_Default:"မူရင်းအဖြစ်သတ်မှတ်မည်",
  Default_Account:"မူလအကောင့်",
  Default_Design:"ပုံသေဒီဇိုင်း",
  Multiple_Cheques:"စစ်ဆေးမှုများ (သုံးဆ)",
  Account_Mode:"အကောင့်မုဒ်",
  Multiple_Cheques_Description:"တစ်မျက်နှာလျှင် ချက်လက်မှတ်သုံးခု။",
  Check_and_Table:"စစ်ချက်ဇယား",
  Check_including_Table:"စာရင်းစစ်နှင့် စာရင်းဇယား",
  Check_Mailer:"စာပို့စစ်ဆေးခြင်း",
  Check_Mailer_Window:"လိပ်စာပြတင်းပေါက်ဖြင့် စစ်ဆေးပါ။",
  DocuGard_Table_Top:"DocuGard စစ်ဆေးပြီးဇယား (ထိပ်တန်း)",
  DocuGard_Table_Middle:"DocuGard စစ်ဆေးပြီးဇယား (အလယ်)",
  DocuGard_Table_Bottom:"DocuGard စစ်ဆေးပြီးဇယား (အောက်ခြေ)",
  DocuGard_Mailer_Top:"DocuGard မေးလ်ကို စစ်ဆေးပါ။ (ထိပ်တန်း)",
  DocuGard_Mailer_Middle:"DocuGard မေးလ်ကို စစ်ဆေးပါ။ (အလယ်)",
  DocuGard_Mailer_Bottom:"DocuGard မေးလ်ကို စစ်ဆေးပါ။ (အောက်ခြေ)",
  DocuGard_Three_Cheques:"DocuGard စစ်ဆေးမှုများ (သုံးဆ)",
  DocuGard_Cheque_Top:"DocuGard စစ်ဆေးခြင်း (ထိပ်တန်း)",
  DocuGard_Cheque_Middle:"DocuGard  (အလယ်)",
  DocuGard_Cheque_Bottom:"DocuGard စစ်ဆေးပါ။ (အောက်ခြေ)",
  DocuGard_Three_Cheques_Window:"စာမျက်နှာတစ်ခုတွင် စစ်ဆေးမှုသုံးခု",
  DocuGard_Table_Top_Window:"စစ်ဆေးခြင်းနှင့် ၀င်ငွေဇယား",
  DocuGard_Table_Middle_Window:"စစ်ဆေးခြင်းနှင့် ၀င်ငွေဇယား",
  DocuGard_Table_Bottom_Window:"စစ်ဆေးခြင်းနှင့် ၀င်ငွေဇယား",
  DocuGard_Mailer_Top_Window:"စစ်ဆေး၊ ဇယား၊ လိပ်စာ",
  DocuGard_Mailer_Middle_Window:"စစ်ဆေး၊ ဇယား၊ လိပ်စာ",
  DocuGard_Mailer_Bottom_Window:"စစ်ဆေး၊ ဇယား၊ လိပ်စာ",
  DocuGard_Cheque_Top_Window:"လုံခြုံသောစာရွက်ကိုစစ်ဆေးပါ။",
  DocuGard_Cheque_Middle_Window:"လုံခြုံသောစာရွက်ကိုစစ်ဆေးပါ။",
  DocuGard_Cheque_Bottom_Window:"လုံခြုံသောစာရွက်ကိုစစ်ဆေးပါ။",
  Basic_Cheque:"စစ်ဆေး (ထိပ်တန်း)",
  Basic_Cheque_Print:"စစ်ဆေးချက်တစ်ခုထုတ်ပါ။",
  Error_Setting_As_Paid:"အခကြေးငွေအဖြစ် သတ်မှတ်ခြင်းအမှား",
  Add_Attachment:"တွယ်တာထည့်ပါ",
  PrinterUnavailable:"ပရင်တာ မရရှိနိုင်ပါ",
  CreditCardComponent:"ကတ်များ",
  PaypalComponent:"PayPal",
  InteracComponent:"အင်တာနက်",
  BitcoinComponent:"BitCoin",
  New_Deposit:"အပ်ငွေအသစ်",
  Deposits:"အပ်ငွေ",
  No_Deposits:"အပ်ငွေမရှိ၊",
  Credit_Card_Deposit:"အကြွေးဝယ်ကဒ်",
  New_Credit_Card_Deposit_Message:"အကြွေးဝယ်ကတ်အပ်ငွေ",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin အပ်ငွေ",
  New_Interac_Deposit:"အင်တာနက်",
  New_Interac_Deposit_Message:"Interac Transfer",
  Payments_Limit:"ငွေပေးချေမှုကန့်သတ်ချက်",
  No_Payment_Limit:"ငွေပေးချေမှု အကန့်အသတ်မရှိ",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal အပ်ငွေ",
  No_Deposits_Word:"ပထမဆုံးကုမ္ပဏီ <a routerLink='/folder/Deposit/New'>အပ်ငွေ</a> ကိုထည့်ပါ။",
  No_Documents_Specified:"ပုံနှိပ်ခြင်းအတွက် မည်သည့်စာရွက်စာတမ်းမျှ မသတ်မှတ်ထား",
  No_Printers_Added:"ပရင်တာ မတွေ့ပါ။ တစ်ခုထည့်ရန် ဆက်တင်များ > ပရင်တာများသို့ သွားပါ။",
  DB_Erase_Prompt:"ဒေတာဘေ့စ်တစ်ခုလုံးကို အပြီးအပြတ်ဖျက်မလား။ သတိပေးချက်- သင်သိမ်းဆည်းထားသော အချက်အလက်အားလုံးကို ဆုံးရှုံးလိမ့်မည်!",
  Console_Warning:"ဤကွန်ဆိုးလ်တွင် မည်သည့်စာသားကိုမျှ မထည့်ပါနှင့်။ သင်သည် သင်ကိုယ်တိုင်နှင့်/သို့မဟုတ် သင့်ကုမ္ပဏီကို အန္တရာယ်ဖြစ်စေနိုင်သည်။",
  No_Faxes_Word:"ပထမဆုံး <a routerLink='/folder/Fax/New'>ဖက်စ်</a> ကို ဖန်တီးပါ။",
  Cheque_Delete_Confirmation:"ဒီစစ်ဆေးမှုကို ဖျက်ချင်တာ သေချာလား",
  Design_Delete_Confirmation:"ဒီဒီဇိုင်းကို ဖျက်ချင်တာ သေချာလား",
  Cheque_Pay_Confirmation:"ဤချက်လက်မှတ်ကို အခကြေးငွေအဖြစ် အမှတ်အသားပြုပါ။ ၎င်းသည် ပုံနှိပ်တန်းစီတွင် ပေါ်လာမည်မဟုတ်ပါ။",
  Payment_Pay_Confirmation:"ဤငွေပေးချေမှုကို ပေးဆောင်သည်ဟု အမှတ်အသားပြုပါ။ ၎င်းသည် ချက်လက်မှတ်တန်းစီခြင်းတွင် ပေါ်လာမည်မဟုတ်ပါ။",
  Delete_Deduction_Confirmation:"ဤနုတ်ယူခြင်းကို ဖျက်လိုသည်မှာ သေချာပါသလား။",
  Delete_Job_Confirmation:"ဒီအလုပ်ကို ဖျက်ချင်တာ သေချာလား",
  Delete_Timesheet_Confirmation:"ဤအချိန်ဇယားကို ဖျက်လိုသည်မှာ သေချာပါသလား။",
  Delete_Schedule_Confirmation:"ဤအချိန်ဇယားကို ဖျက်လိုသည်မှာ သေချာပါသလား။",
  Delete_Setting_Confirmation:"ဤဆက်တင်ကို ပြန်လည်သတ်မှတ်လိုသည်မှာ သေချာပါသလား။",
  Delete_Fax_Confirmation:"ဒီဖက်စ်ကို ဖျက်ချင်တာ သေချာလား",
  Delete_File_Confirmation:"ဒီဖိုင်ကို ဖျက်ချင်တာ သေချာလား",
  Delete_Vacation_Confirmation:"ဤအားလပ်ရက်ကို ဖျက်လိုသည်မှာ သေချာပါသလား။",
  Delete_Printer_Confirmation:"ဒီပရင်တာကို ဖျက်ချင်တာ သေချာလား",
  Remove_Design_Confirmation:"ဒီဒီဇိုင်းကို ဖျက်ချင်တာ သေချာလား",
  Delete_Payroll_Confirmation:"ဤလစာစာရင်းကို ဖျက်လိုသည်မှာ သေချာပါသလား။",
  Send_Fax_Email_Confirmation:"ဒီစာရွက်စာတမ်းတွေကို ဖက်စ်နဲ့ အီးမေးလ်ပို့ချင်တာလား။",
  Send_Email_Confirmation:"ဤစာတမ်းကို အီးမေးလ်ပို့လိုပါသလား။",
  Send_Fax_Confirmation:"ဒီစာရွက်စာတမ်းကို ဖက်စ်ပို့လိုပါသလား။",
  Error_Generating_PDF:"ဆောရီး။ ဤစာတမ်းကို ဖန်တီးရာတွင် အမှားအယွင်းရှိနေသည်။",
  PDF_Error_Saving_Image:"ဆောရီး။ ဤစာရွက်စာတမ်း၏ PDF ပုံကို သိမ်းဆည်းရာတွင် အမှားအယွင်းရှိနေသည်။",
  Test_Printer_Confirmation:"ဤပရင်တာပေါ်တွင် စမ်းသပ်မှုစာမျက်နှာကို ပရင့်ထုတ်လိုပါသလား။",
  Save_Timesheet_Prompt:"ကျေးဇူးပြု၍ 'ခေါင်းစဉ်' တစ်ခုထည့်ပါ သို့မဟုတ် သိမ်းရန် 'စတင်အချိန်တိုင်း' ကိုနှိပ်ပါ။",
  Remove_Geofence_Prompt:"ဤပထဝီဝင်ခြံစည်းရိုးတည်နေရာကို ဖယ်ရှားလိုသည်မှာ သေချာပါသလား။",
  Delete_Employee_Confirmation:"ဖျက်ချင်တာသေချာလား",
  Fire_Employee_Confirmation:"မင်းပစ်ချင်တာသေချာလား",
}