export const Zu = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"I-copyright &kopisha; 2023",
  black:"Mnyama",
  green:"Okuhlaza",
  gold:"Igolide",
  blue:"Okuluhlaza okwesibhakabhaka",
  brown:"Brown",
  purple:"Okunsomi",
  pink:"Pink",
  red:"Okubomvu",
  Swatches:"Ama-Swatches",
  HSL:"I-HSL",
  RGB:"I-RGB",
  Hue:"Hue",
  Saturation:"Ukugcwaliswa kwesikhala",
  Lightness:"Ukukhanya",
  Upgrade_To_Pro:"Thuthukela ku-Pro",
  AllFeaturesInclude:"Zonke izici zihlanganisa:",
  PrintUnlimitedCheques:"Phrinta Amasheke Angenamkhawulo",
  PremiumChequeDesigns:"Imiklamo ye-Premium Check",
  ManageUnlimitedEmployees:"Phatha Abasebenzi Abangenamkhawulo",
  AddUnlimitedPayees:"Engeza Abakhokhelwayo Abangenamkhawulo",
  CreateUnlimitedPayrolls:"Dala Ama-Payrolls Angenamkhawulo",
  UnlimitedSchedulesandTimeSheets:"Amashejuli Angenamkhawulo Namashidi Esikhathi",
  BulkPayPalPayouts:"Izinkokhelo eziningi ze-PayPal",
  UnlimitedBankAccounts:"Ama-Akhawunti Ebhange Angenamkhawulo",
  ManageMultipleCompanies:"Phatha Izinkampani Eziningi",
  TrackInsurancePolicies:"Landelela Izinqubomgomo Zomshwalense",
  Bio_MetricProtection:"Ukuvikelwa kwe-Bio-Metric",
  Geo_FenceLock_OutProtection:"I-Geo-Fence Lock-Out Protection",
  Multiple_Companies_Word:"Ukuphatha izinkampani eziningi akutholakali ngaphandle kwe-premium yamasheke.",
  PayPal_Payouts_Word:"Izinkokhelo ze-PayPal zivaliwe ngaphandle kwe-premium yamasheke.",
  PINProtection:"Ukuvikelwa kwe-PIN",
  PasswordProtection:"Ukuvikelwa Kwephasiwedi",
  May_Require_Approval:"Ingase idinge imvume.",
  Subscribe:"Bhalisa",
  Billed:"Ukhokhisiwe",
  Up:"Phezulu",
  Down:"Phansi",
  Positioning:"Ukuma",
  Marker:"Umaka",
  Drag_Marker:"Hudula Umaka",
  Tagline:"Phrinta Amasheke futhi Ubhale I-Payroll kuthebula",
  Marker_Word:"Sebenzisa Omaka ukuze usayizi we-elementi.",
  Pinch_Zoom:"Ncinza Zoom",
  Pinch_Word:"Ncinza ukuze usondeze isici.",
  Drag:"Hudula",
  Drag_Word:"Sebenzisa umunwe wakho ukuhudula izakhi.",
  subscription_alias_word:"Ukuvuselela Okuzenzakalelayo Okubhaliselwe",
  premium_alias_word:"Iphakheji Yokuthuthukisa Yesikhathi esisodwa",
  print_alias_word:"Phrinta Ukuhlola Ngamunye",
  mode_alias_word:"Imodi",
  Pro:"UPro",
  Pro_word:"Inguqulo ye-Pro iyadingeka.",
  Cant_Delete_Default_Company_Word:"Uxolo, awukwazi ukususa inkampani yakho ezenzakalelayo.",
  Reinsert_Default_Designs:"Faka kabusha Imiklamo Ezenzakalelayo",
  Reinsert_Default_Designs_word:"Ingabe ufuna ukufaka kabusha imiklamo ezenzakalelayo?",
  Subscription_Active_Disable_Word:"Lokhu kubhalisa kuyasebenza. Ungathanda ukukhansela lokhu okubhalisele kuma-Cheques?",
  Company_Logo:"Ilogo Yenkampani",
  ZERO_:"ZERO",
  Disclaimer:"Umshwana wokuzihlangula",
  Privacy_Policy:"Inqubomgomo yobumfihlo",
  EULA:"Ihlola i-EULA",
  Terms_Of_Service:"Imigomo Yesevisi",
  Terms_Of_Use:"Imigomo Yokusebenzisa",
  Refunds:"Inqubomgomo Yokubuyisela Imali",
  Single_Print:"1 Hlola",
  Two_Prints:"2 Amasheke",
  Five_Prints:"5 Amasheke",
  Ten_Prints:"10 Amasheke",
  Fifteen_Prints:"15 Amasheke",
  Twenty_Prints:"20 Amasheke",
  Thirty_Prints:"30 Amasheke",
  Image_Added:"Isithombe Sengeziwe",
  Image_Preview:"Ukubuka Kuqala Kwesithombe",
  No_Image_Was_Selected:"Asikho isithombe esikhethiwe.",
  Cheques_Unlimited:"Amasheke Unlimited",
  _Subscription:"Ukubhalisa",
  Subscription:"Amasheke - Inyanga engu-1",
  Two_Month_Subscription:"Amasheke - 2 Izinyanga",
  Three_Month_Subscription:"Amasheke - Izinyanga ezi-3",
  Six_Month_Subscription:"Amasheke - Izinyanga eziyisi-6",
  Twelve_Month_Subscription:"Amasheke - Izinyanga eziyi-12",
  Cheques_Are_Available:"Amasheke ayatholakala ukuze aphrintwe.",
  Cheques_Thank_You:"Siyabonga kakhulu ngokuthenga kwakho Amasheke! Ukuthenga kwakho manje kuyasebenza.",
  Upgrade_Required_Two:"Khetha iphakheji bese uthepha kabili inkinobho yentengo ukuze uqale ukuthenga kwakho. Phrinta amasheke ochwepheshe abukeka enemibala egcwele ngemizuzwana.",
  Month:"Inyanga",
  Due:"Ifuneka:",
  Expires:"Iphelelwa yisikhathi:",
  Subscription_Active:"Ukubhalisa Kuyasebenza",
  Subscription_Inactive:"Ukubhalisa akusebenzi",
  Purchase_Additional_Cheques:"Thenga amasheke engeziwe?",
  Printable_Cheque:"Isheke Eliphrintekayo",
  Printable_Cheques:"Amasheke Aphrintekayo",
  Printable_Cheque_Word:"isheke liyatholakala ku-akhawunti yakho.",
  Printable_Cheques_Word:"amasheke ayatholakala ku-akhawunti yakho.",
  Max_Amount_Message:"Inani olicacisile selifinyelele phezu kwenani eliphezulu elibekelwe lolu hlelo:",
  Terms_Required_Word:"Kufanele uvumelane nalesi sivumelwano ngaphambi kokuqhubeka nokusebenzisa Amasheke.",
  Subscriptions:"Okubhaliselwe",
  Product_Upgrades:"Ukuthuthukiswa",
  Mailed_Out_Cheques:"Amasheke Athunyelwe",
  Enter_A_Company_Name:"Sicela ufake igama lenkampani.",
  Single_Cheques:"Amasheke Awodwa",
  Cheque_Golden:"Isheke Legolide",
  Cheque_Golden_Window:"Idizayini yesheke eligolide.",
  Cheque_Green:"Isheke Eliluhlaza",
  Cheque_Green_Window:"Umklamo wesheke oluhlaza.",
  Cheque_Red:"Isheke Elibomvu",
  Cheque_Red_Window:"Idizayini yesheke elibomvu.",
  Cheque_Yellow:"Isheke Eliphuzi",
  Cheque_Yellow_Window:"Idizayini yesheke eliphuzi.",
  Cheque_Statue_of_Liberty:"Isithombe senkululeko",
  Cheque_Statue_of_Liberty_Window:"Idizayini yokuhlola i-Statue of Liberty.",
  Cheque_Green_Wave:"Igagasi Eliluhlaza",
  Cheque_Green_Wave_Window:"Umklamo wesheke oluhlaza.",
  Cheque_Golden_Weave:"I-Golden Weave",
  Cheque_Golden_Weave_Window:"Idizayini yesheke legolide elihle.",
  Cheque_Green_Sun:"Ilanga Eliluhlaza",
  Cheque_Green_Sun_Window:"Idizayini yesheke elijulile nelizolile.",
  Cheque_Blue_Checkers:"Ama-Blue Checkers",
  Cheque_Blue_Checkers_Window:"Idizayini yesheke eluhlaza okwesibhakabhaka.",
  Cashiers_Check:"Isheke likaCashier",
  Cashiers_Check_Window:"Isifanekiso sesitayela se-Cashier's Check.",
  Cheque_Aqua_Checkers:"I-Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Umklamo wokuhlola i-Aqua.",
  Cheque_Golden_Checkers:"Ama-Golden Checkers",
  Cheque_Golden_Checkers_Window:"Idizayini yesheke eligolide.",
  Upgrade_Unavailable:"Ukuthuthukiswa Akutholakali",
  Bank_Code_Protection:"Ukuvikelwa Kwenombolo Yasebhange",
  Bank_Code_Protection_Word:"Vikela izinombolo zakho zasebhange ekusetshenzisweni kulolu hlelo lokusebenza olusebenza kwenye idivayisi noma komunye umsebenzisi. Lesi senzo AYIREVERSIBLE. Qhubeka?",
  Bank_Code_Protection_Blocked_Word:"Izinombolo zasebhange ozama ukuzisebenzisa zibekelwe omunye umsebenzisi noma idivayisi.",
  Bank_Code_Protection_Error_Word:"Ukuqinisekisa inombolo kuhlulekile. Sicela uxhume ku-inthanethi bese uzama ukungeza le akhawunti yasebhange futhi.",
  Bank_Code_Protection_Set_Error_Word:"Ukuvikelwa kwenombolo yebhange kudinga ukuthi uxhumeke ku-inthanethi. Sicela uxhume bese uzama futhi.",
  Upgrade_Unavailable_Word:"Uxolo, sinenkinga yokukuqinisekisa. Okubhaliselwe nokuthuthukiswa kokuthi Amasheke akutholakali okwamanje ukuze kuthengwe endaweni yangakini.",
  PayPal_Payment_Preview:"Ukuhlola kuqala Inkokhelo ye-PayPal",
  Apple_Pay:"I-Apple Pay",
  Select_PayPal:"Khetha i-PayPal",
  PayPal_Applications:"Izicelo ze-PayPal",
  Purchase_With_Apple_Pay:"Thenga nge-Apple Pay",
  Google_Pay:"I-Google Pay",
  Companies:"Izinkampani",
  Insurance:"Umshwalense",
  New_PayPal:"I-PayPal entsha",
  Pay_By:"Khokha Nge",
  Insure:"Umshwalense",
  Miles:"Amamayela",
  Payment_Method:"Indlela yokukhokha",
  Select_Policies:"Khetha Izinqubomgomo",
  Policies:"Izinqubomgomo",
  Policy:"Inqubomgomo",
  No_PayPal_Account:"Ayikho i-akhawunti ye-PayPal",
  No_Policies:"Azikho Izinqubomgomo Zomshwalense",
  New_Policy:"Inqubomgomo Entsha",
  PayPal_Payment:"Inkokhelo ye-PayPal",
  PayPal_Payments:"Izinkokhelo ze-PayPal",
  No_Payment_Selected:"Ayikho Inkokhelo Ekhethiwe",
  Sending_Payment_Word:"Sicela ulinde... Le nkokhelo iyathunyelwa.",
  Sending_Payments_Word:"Sicela ulinde... Izinkokhelo ziyathunyelwa.",
  No_Payment_Selected_PayPal:"Akukho <a routerLink='/folder/Payments'>Inkokhelo ye-PayPal</a> ekhethiwe ukuthumela.",
  Payment_Sent:"Inkokhelo Ithunyelwe",
  PayPal_Payment_Sent:"Le nkokhelo ithunyelwe nge-PayPal.",
  Copay:"I-Copay",
  Dead:"Ufile",
  Alive:"Uyaphila",
  Not_Dead:"Hhayi Ufile",
  Unclaimed:"Akufunwanga",
  Attempted:"Uzamile",
  Deceased:"Ushonile",
  Claimed:"Kufunwe",
  Unpaid:"Okungakhokhelwa",
  Sending_Payment:"Ithumela Inkokhelo",
  Sending_Payments:"Ukuthumela Izinkokhelo",
  Send_PayPal_Confirmation:"Uyafuna ukuthumela lo msebenzi nge-PayPal?",
  Send_PayPal_Confirmation_Word:"Cindezela inkinobho eluhlaza ukuze uthumele lokhu kuthenga.",
  Save_Payment_As_Unpaid:"Londoloza le nkokhelo njengengakhokhiwe?",
  Payment_Pay_Confirmation_PayPal:"Londoloza le nkokhelo njengekhokhiwe?",
  No_Policies_Word:"Engeza <a routerLink='/folder/Postage/New'>Inqubomgomo Yomshwalense</a> yokuqala manje.",
  Timesheet_Multiple_Delete_Confirmation:"Uqinisekile ukuthi ufuna ukususa ama-timesheets amaningi?",
  Select_Multiple_Timesheets_Prompt:"Awekho amaphepha esikhathi akhethiwe. Khetha okungenani ishidi lesikhathi elilodwa.",
  Select_Multiple_Policies_Prompt:"Azikho izinqubomgomo ezikhethiwe. Khetha okungenani umshwalense owodwa.",
  Policies_Multiple_Delete_Confirmation:"Uqinisekile ukuthi ufuna ukususa izinqubomgomo eziningi?",
  Company:"Inkampani",
  Add_Company:"Engeza Inkampani",
  New_Company:"Engeza Inkampani",
  No_Companies:"Azikho Izinkampani",
  Enable_Company:"Nika amandla Inkampani",
  Select_Company:"Khetha Inkampani",
  The_Default_Company:"Ilebula yenkampani ezenzakalelayo.",
  Manage_Companies:"Phatha Izinkampani",
  No_Companies_Word:"Ukuhlola kuzosebenzisa inkampani ezenzakalelayo.<br />Engeza <a routerLink='/folder/Company/New'>Inkampani yokuqala</a>.",
  Default_Company:"Inkampani ezenzakalelayo",
  Cheques_Unlimited_Word:"I-Checks Unlimited ikuvumela ukuthi uphrinte amasheke amaningi ngendlela othanda ngayo.",
  Cheques_Subscription_Word:"Ukubhaliswa kwamasheke kukuvumela ukuthi uphrinte amasheke amaningi ngendlela othanda ngayo.",
  You_Own_This_Product:"Ungumnikazi walo mkhiqizo.",
  Your_Subscription_is_Active:"Ukubhalisa kwakho kuyasebenza.",
  Active_Products:"Imikhiqizo Ecushiwe",
  Purchase_Confirmation:"Thenga",
  Purchase_Cheques:"Thenga Amasheke",
  Restore_Purchase:"Buyisela Okuthengiwe",
  Erase_Purchase:"Sula Ukuthenga",
  Successfully_Purchased:"Kuthengwe Ngempumelelo",
  Enter_Your_Licence_Key:"Sicela ufake ukhiye wakho welayisense kuleli khasi ukuze wenze lo mkhiqizo usebenze.",
  Licence_Key:"Ukhiye Welayisensi",
  Enter_Licence_Key:"Faka Ukhiye Welayisensi",
  Purchased:"Kuthengiwe",
  Enable_Feature:"Nika amandla Isici",
  Cancel_Subscription:"Khansela Ukubhalisa",
  Subscription_Removed:"Okubhalisile kususiwe",
  Select_Active_Subscription:"Khetha ukubhalisa okusebenzayo ukuze ukuguqule.",
  Remove_Subscription_Word:"Uqinisekile ukuthi ufuna ukukhansela lokhu kubhalisa?",
  Delete_Company_Confirmation:"Uqinisekile ukuthi ufuna ukususa yonke le nkampani? ISEXWAYISO: Uzolahlekelwa yilo lonke ulwazi olugciniwe!",
  Delete_Default_Company_Word:"Awukwazi ukususa inkampani ezenzakalelayo. Ungathanda ukusetha kabusha uhlelo lokusebenza futhi ulubuyisele esimweni esimisiwe? ISEXWAYISO: Uzolahlekelwa yilo lonke ulwazi olugciniwe!",
  Console_Warning_2:"Ungaphathi noma yiluphi uhlobo lwemali usebenzisa lolu hlelo lokusebenza okungelona olwakho okwamanje.",
  Terms_and_Conditions:"Imibandela",
  and_the:"kanye ne",
  for:"okwe",
  Please_Read_Word:"Sicela ufunde futhi uvumelane ne",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Ezinye izilinganiso zokuguqulwa kwemali azitholakalanga. Sicela uxhume ku-inthanethi.",
  Free:"Mahhala",
  DB_Erase_Prompt_2:"Sula ngokuphelele yonke imininingo egciniwe yonjiniyela? ISEXWAYISO: Uzolahlekelwa yilo lonke ulwazi lwe-PurCHASE kanye ne-SUBSCRIPTION!",
  Successfully_Imported:"Ingeniswe Ngempumelelo",
  Select_Postage:"Khetha Ukuposa",
  No_Postage:"Azikho Izitembu Zokuposa",
  No_Paid_Postage_Word:"Engeza isitembu sokuqala sokuthi <a routerLink='/folder/Postage/New'>Imali Ekhokhelwayo</a>.",
  Trial_Complete:"Isivivinyo Siqediwe",
  Please_Upgrade:"Sicela uthuthukise Amasheke ukuze uqhubeke nokuphrinta.",
  Aa:"Aa",
  Color:"Umbala",
  Font:"Ifonti",
  Guide:"Umhlahlandlela",
  Guides:"Abaqondisi",
  Image:"Isithombe",
  Zoom:"Sondeza",
  Logo:"Ilogo",
  Bank:"Ibhange",
  MICR:"I-MICR",
  Total:"Ingqikithi",
  Cancel:"Khansela",
  Confirm:"Qinisekisa",
  Method:"Indlela",
  Biometric_Security:"Ukuphepha kwe-Bio-metric",
  Please_Login_To_Continue:"Sicela ungene ngemvume ukuze uqhubeke.",
  Complete:"Qedela",
  Sign_Up:"Bhalisela",
  Error:"Iphutha",
  Biometrics:"I-Bio-metrics",
  Percent:"Amaphesenti",
  Zero_Percent:"0%",
  Top_Margin:"Imajini ephezulu",
  Bottom_Margin:"Imajini engezansi",
  Left_Margin:"Imajini Yesokunxele",
  Right_Margin:"Imajini yesokudla",
  MICR_Margin:"I-MICR Margin",
  Table_Margin:"Imajini yethebula",
  Address_Margin:"Imajini Yekheli",
  Percentage_:"Iphesenti",
  Vacation_Title:"Isihloko Seholide",
  Use_PIN:"Sebenzisa i-PIN",
  Loading__:"Iyalayisha...",
  Design_Title:"Isihloko Sokuklama",
  Authorize:"Gunyaza",
  Key:"Ukhiye",
  Public_Key:"Ukhiye Womphakathi",
  Private_Key:"Ukhiye Oyimfihlo",
  Authenticate:"Qinisekisa",
  Last_Payroll:"Iholo Lokugcina",
  Last_Calculation:"Ukubalwa Kokugcina",
  Authorized:"Igunyaziwe",
  Geo_Authorized:"I-Geo-Indawo: Igunyaziwe",
  Not_Authorized:"Akugunyaziwe",
  Authorization_Complete:"Ukugunyazwa Kuqediwe",
  Geolocation_Authorization:"I-Geo-location Ukugunyazwa",
  Out_of_Bounds:"Ngaphandle Kwemingcele",
  Cant_Delete_Default_Design:"Ayikwazi ukususa idizayini ezenzakalelayo.",
  Unauthorized_Out_of_Bounds:"Okungagunyaziwe: Ngaphandle Kwemingcele",
  Biometric_Authorization:"Ukugunyazwa kwe-Bio-metric",
  Locating_Please_Wait:"Ithola, Sicela Ulinde...",
  Test_Biometrics:"Hlola i-Bio-metrics",
  Cheque_Margins:"Hlola Amamajini",
  Percentage_Full_Error:"Inkambu yephesenti ayikwazi ukusethwa ngaphezu kwamaphesenti angu-100%.",
  No_Payroll_Text:"Engeza <a routerLink='/folder/Employee/New'>Isisebenzi</a> noma <a routerLink='/folder/Payee/New'>Okhokhelwayo</a> kanye <a routerLink='/folder/Schedule/New'>Ishejuli</a>.",
  Design_Saved:"Umklamo Ulondoloziwe",
  Default_Design_Selected:"Idizayini Ezenzakalelayo Ikhethiwe",
  Partial_Import:"Ngenisa Ngokwengxenye",
  Partial_Export:"Ukuthekelisa Ngokwengxenye",
  Entire_Import:"Ngenisa Konke",
  Entire_Export:"Ukuthekelisa Konke",
  Existing_Password:"Sicela ufake iphasiwedi yakho ekhona:",
  Existing_PIN:"Sicela ufake i-PIN khodi yakho ekhona:",
  Pin_Change_Header:"Iphinikhodi yokuqinisekisa",
  Pin_Change_SubHeader:"Faka inombolo yakho ye-PIN endala ukuze uqinisekise ushintsho.",
  Pass_Change_Header:"Ukuqinisekisa Iphasiwedi",
  Pass_Change_SubHeader:"Faka iphasiwedi yakho endala ukuze uqinisekise ushintsho.",
  PIN_Enter_Message:"Faka i-PIN yakho ukuze uqinisekise.",
  Password_Enter_Message:"Faka iphasiwedi yakho ukuze uqinisekise.",
  Pin_Updated_Success:"Iphinikhodi ibuyekezwe ngempumelelo!",
  Pin_Updated_Fail:"Iphinikhodi ayikwazanga ukubuyekezwa.",
  Pass_Updated_Success:"Iphasiwedi ibuyekezwe ngempumelelo.",
  Pass_Updated_Fail:"Iphasiwedi ayikwazanga ukubuyekezwa.",
  Preview_Payment:"Buka kuqala Inkokhelo",
  Preview_Payroll:"Buka kuqala Inkokhelo",
  No_Payments_Created:"Azikho izinkokhelo ezitholakele ukuthi zidaliwe.",
  Payment_Preview:"Ukuhlola kuqala Inkokhelo",
  Enable_Payee:"Nika amandla Okhokhelwayo",
  Rendered:"Kunikezelwe",
  No_Email:"Ayikho I-imeyili",
  Setup_Cheques:"Setha Amasheke",
  name:"Igama",
  address:"Ikheli",
  address_2:"Umugqa Wekheli 2",
  city:"Idolobha",
  province:"Isifundazwe",
  postal_code:"Ikhodi Yeposi/I-ZIP",
  country:"Izwe",
  username:"Igama lomsebenzisi",
  full_name:"Igama eligcwele",
  birthday:"Usuku lokuzalwa",
  email:"I-imeyili",
  phone:"Ucingo",
  employees:"Abasebenzi",
  addresses:"Amakheli",
  payment_amount_limit:"Umkhawulo Wenani Lokukhokha",
  total_limit:"Umkhawulo Ophelele",
  payees:"Abakhokhelwayo",
  description:"Incazelo",
  address_line_one:"Umugqa Wokuqala Wekheli",
  address_line_two:"Umugqa Wekheli Wesibili",
  image:"Isithombe",
  account_holder:"Umphathi we-akhawunti",
  cheque_starting_id:"Hlola I-ID Yokuqala",
  transit_number:"Inombolo Yezokuthutha",
  institution_number:"Inombolo Yesikhungo",
  designation_number:"Inombolo Yokuqokwa",
  account_number:"Inombolo Ye-akhawunti",
  currency:"Imali",
  signature:"Isiginesha",
  payment_payroll_limit:"Umkhawulo Wokukhokha",
  total_limi:"Umkhawulo ophelele",
  date:"Usuku",
  printed_memo:"Imemo Ephrintiwe",
  banks:"Amabhange",
  signature_image:"Isithombe Sesiginesha",
  address_name:"Igama Lekheli",
  notes:"Amanothi",
  design:"Idizayini",
  title:"Isihloko",
  frequency:"Imvamisa",
  fax:"Ifeksi",
  salaries:"Amaholo",
  salary_ids:"Omazisi bemiholo",
  start_time:"Isikhathi sokuqala",
  end_time:"Isikhathi Sokuphela",
  paid:"Ikhokhiwe",
  overtime_percentage:"Amaphesenti Akhokhiwe",
  overtime_amount:"Inani Elimisiwe Elikhokhelwe",
  first_name:"Igama",
  last_name:"Isibongo",
  moderation:"Ukulinganisela",
  create:"Dala",
  edit:"Hlela",
  destroy:"Bhubhisa",
  day_start_time:"Day_start_time",
  day_end_time:"Day_end_time",
  fullname:"Igama",
  time:"Isikhathi",
  auto_send:"Thumela ngokuzenzakalelayo",
  time_method:"Indlela Yesikhathi",
  schedules:"Amashejuli",
  indefinite_payroll_enabled:"Nika amandla Ngokungapheli",
  amount:"Inani",
  repeat:"Phinda",
  always_enabled:"Ihlala Inikwe amandla",
  start_date:"Usuku okuqalwa ngalo",
  end_date:"Idethi Yokuphela",
  Cheque_Total:"Hlola Inani",
  Total_Amount:"Ingqikithi yemali:",
  Amounts:"Amanani:",
  Images:"Izithombe",
  Files:"Amafayela",
  Previewing:"Ukuhlola kuqala:",
  Insert:"Faka",
  Preview_Import:"Buka kuqala Ukungenisa",
  Entry:"Ukungena",
  Entries:"Okufakiwe",
  No_Entries:"Akukho Okufakiwe",
  Import_Type:"Ngenisa Uhlobo",
  Select_Details:"Khetha Imininingwane",
  Select_Payee:"Khetha Okhokhelwayo",
  Enable_Holidays:"Vumela Amaholide",
  Disable_Holidays:"Khubaza Amaholide",
  Wire_Transfer:"Ukudlisa imali ngebhange",
  New_Export:"Ukuthekelisa Okusha",
  Export_Data:"Khipha Idatha",
  Export_Data_Word:"Khetha uhlobo lwefayela ozolithekelisa futhi ulilande.",
  Export_File:"Khipha Ifayela",
  Mode:"Imodi",
  Times:"Izikhathi",
  Widgets:"Amawijethi",
  Slider:"Isilayidi",
  Set_Details:"Setha Imininingwane",
  Select_Type:"Khetha Uhlobo",
  Display_Slider:"Bonisa Isilayidi",
  Dashboard_Slider:"Isilayidi Sedeshibhodi",
  Dashboard_Mode:"Imodi yedeshibhodi",
  Show_Intro:"Bonisa Isingeniso",
  Show_Payrolls:"Bonisa Izinkokhelo",
  Show_Holidays:"Bonisa Amaholide",
  Show_Invoices:"Bonisa ama-invoyisi",
  Show_Cheques:"Bonisa Amasheke",
  Enabled_Widgets:"Amawijethi anikwe amandla",
  Disabled_Widgets:"Amawijethi Akhutshaziwe",
  Colors:"Imibala",
  Barcodes:"Amabhakhodi",
  View_Timers:"Buka Izibali-sikhathi",
  Gradients:"Ama-Gradients",
  No_Info:"Alukho Ulwazi",
  Disable:"Khubaza",
  Show_Layer:"Bonisa Izendlalelo",
  Hide_Layer:"Fihla Izendlalelo",
  Text_Layer:"Izendlalelo Zombhalo",
  Secondly:"Okwesibili",
  Minutely:"Ngomzuzu",
  nine_am:"9:00 AM",
  five_pm:"5:00 PM",
  Enable_Address:"Nika amandla Ikheli",
  Invalid_File_Type:"Uxolo, uhlobo lwefayela olungavumelekile lukhethiwe. Uhlobo lwefayela elisekelwayo:",
  Error_Updating_Entry:"Uxolo, kube nephutha ekubuyekezeni lokhu okufakile.",
  Schedule_Timing_Alert:"Iphutha: Isikhathi sokuqala seshejuli sisethelwe kunani ngemva kwesikhathi sokugcina.",
  Select_Multiple_Payments_Prompt:"Azikho izinkokhelo ezikhethiwe. Khetha okungenani inkokhelo eyodwa.",
  Select_Multiple_Cheques_Prompt:"Alikho isheke elikhethiwe. Sicela ukhethe okungenani isheke elilodwa.",
  Select_Multiple_Items_Prompt:"Azikho izinto ezikhethiwe. Sicela ukhethe okungenani into eyodwa.",
  Paymemt_Multiple_Delete_Confirmation:"Uqinisekile ukuthi ufuna ukususa izinkokhelo eziningi?",
  Cheque_Multiple_Delete_Confirmation:"Uqinisekile ukuthi ufuna ukususa amasheke amaningi?",
  Payee_Multiple_Delete_Confirmation:"Uqinisekile ukuthi ufuna ukususa abakhokhelwayo abaningi?",
  Employee_Multiple_Delete_Confirmation:"Uqinisekile ukuthi ufuna ukususa abasebenzi abaningi?",
  MICR_Warning:"Qaphela: Amanye amaphrinta namadivayisi angase angabonisi ifonti ye-MICR ngendlela efanele.",
  Automatically_Send:"Thumela ngokuzenzakalelayo",
  Type:"Uhlobo",
  Payment_Type:"Uhlobo Lokukhokha",
  Auto_Send:"Thumela ngokuzenzakalelayo",
  Automatically_Process:"Ukucubungula ngokuzenzakalelayo",
  Auto_Process:"Inqubo ye-Auto",
  Image_Based:"Isekelwe esithombeni",
  Font_Based:"Kususelwa kufonti",
  Rerender:"Phinda unikeze",
  Rendering:"Iyanikezela",
  Render:"Amafayela",
  Top:"Phezulu",
  Middle:"Maphakathi",
  Bottom:"Phansi",
  Top_Left:"Phezulu Kwesokunxele",
  Top_Center:"Isikhungo esiphezulu",
  Top_Right:"Phezulu Kwesokudla",
  Middle_Left:"Phakathi Kwesobunxele",
  Middle_Center:"Isikhungo Esiphakathi",
  Middle_Right:"Phakathi Kwesokudla",
  Bottom_Left:"Phansi Kwesobunxele",
  Bottom_Center:"Isikhungo Esingezansi",
  Bottom_Right:"Phansi Kwesokudla",
  Numbers:"Izinombolo",
  Verified:"Kuqinisekisiwe",
  Paper_Size:"Usayizi Wephepha",
  New_Device:"Idivayisi Entsha",
  Add_Device:"Engeza Idivayisi",
  Remove_Device:"Susa Idivayisi",
  Delete_Device:"Susa Idivayisi",
  Block_Device:"Vimba Idivayisi",
  Block:"Vimba",
  Unblock:"Vulela",
  Table:"Ithebula",
  Scan_Login_Code:"Skena Ikhodi Yokungena",
  Login_Code:"Ikhodi yokungena",
  Scan_Code:"Skena Ikhodi",
  Scan_QR_Code:"Skena ikhodi ye-QR",
  Select_All:"Khetha konke",
  Deselect_All:"Yekisa Ukukhetha Konke",
  Increase:"Khulisa",
  Decrease:"Nciphisa",
  Bold:"Okugqamile",
  Text:"Umbhalo",
  Style:"Isitayela",
  Italic:"I-ithalikhi",
  QR_Code:"Ikhodi ye-QR",
  Barcode:"Ibhakhodi",
  Browse_Images:"Phequlula Izithombe",
  Browse_Files:"Phequlula Amafayela",
  Background_Image:"Isithombe sangemuva",
  Logo_Image:"Isithombe selogo",
  Header_Image:"Isithombe sikanhlokweni",
  Bank_Image:"Isithombe Sebhange",
  Cut_Lines:"Sika Imigqa",
  Background:"Ingemuva",
  License:"Ilayisensi",
  One_License:"1 Ilayisensi:",
  Licensed:"Inikezwe ilayisense yokuthi:",
  Not_Licensed:"Awunayo Ilayisensi",
  Enter_Serial:"Faka i-serial",
  Serial_Key:"Ukhiye We-serial",
  Serial:"I-serial",
  Product_Key:"Ukhiye Womkhiqizo",
  Check_Product_Key:"Qinisekisa Ukhiye Womkhiqizo",
  Add_Product_Key:"Faka Ukhiye Womkhiqizo",
  Verifying_Purchase:"Iqinisekisa Ukuthenga...",
  Print_Envelope:"Phrinta Imvilophu",
  Envelope:"Imvilophu",
  Thank_You:"Ngiyabonga!",
  Scale:"Isikali",
  Print_Scale:"Isikali Sokuphrinta",
  Borders:"Imingcele",
  VOID:"I-VOID",
  Void_Cheque:"I-Void Check",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"KHOKHA KUMA-ODA WE:",
  NO_DOLLARS:"AKUKHO AMADOLE",
  ONE_DOLLAR:"IDALA ELILODWA",
  DOLLARS:"AMADALA",
  AND:"KANYE",
  CENTS:"CENTS.",
  NO_:"CHA",
  ONE_:"ENYE",
  AND_NO_:"FUTHI CHA",
  _AND_ONE_:"KANYE OKUNYE",
  DOLLARS_AND_ONE_CENT:"KANYE NESENTI ELILODWA.",
  AND_NO_CENTS:"KANYE NOZIRO AMASENTI.",
  CHEQUE_PRINT_DATE:"DATE:",
  CHEQUE_PRINT_MEMO:"I-MEMO:",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Imisebenzi Yokuqala",
  Inital_Setup:"Ukusethwa Kwasekuqaleni",
  Welcome_To:"Siyakwamukela ku",
  Welcome:"Siyakwamukela",
  Swipe:"Swayipha",
  Intro_Setup:"Ukusethwa Kwesethulo",
  Introduction:"Isingeniso",
  CHEQUE_PRINT_CREDIT:"Inikwa amandla amasheke",
  Title_Intro_Word:"Siyakwamukela kumasheke",
  Title_Intro:"Ihlola Isingeniso",
  Title_Setup:"Ihlola Ukusethwa",
  PayPal_Default_Email_Message:"Uthole ukudluliselwa kwe-PayPal okusha.",
  Cheques_App_Export:"Ithekeliswa ngamasheke",
  No_Prints_Purchased:"Awekho Amaphrinta Athengiwe",
  No_Prints_Purchased_Word:"Awekho amaphrinti engeziwe atholakalayo. Sicela uthenge amasheke engeziwe.",
  Post_Intro_Thanks:"Siyabonga ngokukhetha Amasheke. Inqubo yokusetha isiphelile.",
  Post_Intro_Word:"Qalisa ngokuphrinta isheke lakho lokuqala, ungeze inkokhelo yesikhathi esizayo, noma wengeze abasebenzi ohlwini lwabakhokhayo.",
  Upgrade_Required:"Ukuhlola kudinga ukuthi ube ngumnikazi wenguqulo ye-software ebiza kakhulu ukuze ufihle lo mlayezo futhi uvule izici ezengeziwe.",
  Upgrade_Title:"Amasheke",
  Mailout_Prompt:"Ungase futhi ukhethe ukuthi i-Checks ikuthumelele amasheke okukhokha kwakho.",
  Mailed_Cheque:"Hlola Ngemeyili",
  Mailed_Cheque_Color:"Isheke Lemeyili (Umbala)",
  Terms_Purchase:"Konke okuthengwe nge-elekthronikhi ngamasheke kubuyiselwa imali ngokugcwele kufika ezinsukwini ezingu-30 kusukela ngedethi yokuthenga. Sicela ufunde futhi uvumelane ne",
  Dashboard_Setup:"Setha Iphrinta Eyisisekelo",
  Dashboard_Add:"Engeza I-Akhawunti Yebhange Eyisisekelo",
  Dashboard_Customize:"Khetha Isifanekiso Sokuhlola",
  Dashboard_Job_Salary:"Dala Umsebenzi Wakho Futhi Wengeze Iholo Lakho",
  Dashboard_Employees:"Landelela Abasebenzi & Abakhokhelwayo",
  Dashboard_Print:"Phrinta futhi uthumele ama-Paycheques akho",
  Dashboard_Payroll:"Lungisa Ukuphrinta Kwakho Kwenkokhelo",
  Dashboard_PayPal:"Setha i-PayPal Payroll / Izinkokhelo",
  Begin_Setup:"Qala Ukumisa",
  Get_Started:"Qalisa",
  Purchase:"Thenga",
  Lockdown:"Ukuvalwa thaqa kwezwe",
  Unlock:"Vula",
  Detailed:"Okuningiliziwe",
  Log_In:"Ngena ngemvume",
  Login:"Ngena ngemvume",
  Launch:"Yethula",
  Register:"Bhalisa",
  Finish:"Qeda",
  List:"Uhlu",
  Weekends:"Izimpelaviki",
  Weekly:"Maviki onke",
  PayPal_Default_Subject:"Inkokhelo Entsha",
  Payment_Message:"Umlayezo Wokukhokha",
  PayPal_Default_Payment_Note:"Ngiyabonga",
  Setup_Your_Cheqing_Account:"Ihlola I-akhawunti",
  Add_Your_Primary_Cheqing_Account:"Engeza i-akhawunti yakho yokuhlola eyinhloko.",
  Welcome_Word:"Yenza kube lula futhi wenze ngokuzenzakalelayo ukukhokhelwa kanye nokuphathwa kwabasebenzi.",
  Get_Started_Quickly:"Vele uphendule imibuzo embalwa elula ezosisiza ukuthi siqale...",
  Done_Intro_Word:"Ukusetha kuqedile",
  PIN_Protected:"Iphasiwedi & Iphinikhodi Kuvikelwe",
  Enter_New_PIN:"Faka Ikhodi Yephinikhodi entsha:",
  Enter_PIN:"Faka Ikhodi Yephinikhodi:",
  Invalid_PIN:"Kufakwe i-PIN engavumelekile.",
  Account_Identifier:"Isihlonzi se-akhawunti",
  New_Account_Identifier:"Isihlonzi Se-akhawunti Entsha",
  attempt:"umzamo",
  attempts:"imizamo",
  remaining:"esele",
  Language:"Ulimi",
  languages:"Izilimi",
  select_languages:"Khetha Ulimi",
  Deposit:"Idiphozithi",
  Hire_One_Now:"Qasha Omunye Manje",
  App_Locked:"Uhlelo lokusebenza lukhiyiwe.",
  Skip_:"Yeqa",
  Skip_to_Dashboard:"Yeqela Kudeshibhodi",
  Dashboard:"Ideshibhodi",
  Import:"Ngenisa",
  Admin:"Abaphathi",
  New_Admin:"Umphathi Omusha",
  Settings:"Izilungiselelo",
  Color_Picker:"Isikhethi sombala",
  Font_Picker:"Isikhethi Sefonti",
  Logout:"Phuma",
  Close:"Vala",
  Close_menu:"Vala",
  Excel:"Ifayela le-Excel",
  Csv:"Ifayela le-CSV",
  Sql:"Ifayela le-SQL",
  Json:"Ifayela le-JSON",
  Url:"Ngenisa nge-URL",
  Back:"Emuva",
  Timers:"Izibali sikhathi",
  Cheque:"Hlola",
  Print:"Phrinta",
  Designs:"Imiklamo",
  Pause_Printing:"Misa Isikhashana Ukuphrinta",
  Resume_Printing:"Qalisa kabusha Ukuphrinta",
  Printing_Paused:"Ukuphrinta Kumisiwe",
  PrintingUnavailable:"Uxolo! Ukuphrinta akutholakali kule sistimu.",
  Prints_Paused:"Ukuphrinta Kumisiwe",
  Administration:"Ukuphatha",
  Loading:"Iyalayisha",
  Unnamed:"Akunagama",
  error:"Uxolo, lokhu kuhlola akukwazanga ukuvulwa ukuze kubukwe.",
  No_Cheques_To_Print:"Awekho Amasheke Okuzophrintwa",
  No_Cheques_To_Print_Word:"Dala <a routerLink='/folder/Cheque/New'>Isheke Elisha</a>.",
  New_Cheque:"Isheke Elisha",
  Start_One_Now:"Qala Okukodwa Manje",
  Edit_Cheque:"Hlela isheke",
  Select_Cheques:"Khetha okuthi Hlola",
  Select_Employee:"Khetha Umsebenzisi",
  Default_Printer:"Iphrinta ezenzakalelayo",
  Reassign:"Yabela kabusha",
  Configure:"Lungiselela",
  Template:"Isifanekiso",
  Designer:"Umklami",
  Edit_Designs:"Hlela Imiklamo",
  New_Design:"Idizayini Entsha",
  Next:"Olandelayo",
  Save:"Londoloza",
  Name:"Igama",
  Mail:"Imeyili",
  Amount:"Inani",
  Date:"Usuku",
  PayPal:"I-PayPal",
  Payouts:"Izinkokhelo",
  PayPal_Label:"Ilebula ye-PayPal",
  Email_Username:"I-imeyili / Igama lomsebenzisi",
  Client_ID:"I-ID yeklayenti",
  Sandbox_Email:"I-imeyili yesihlabathi",
  PayPal_Email:"I-imeyili ye-PayPal",
  PayPal_Username:"Igama lomsebenzisi le-API",
  PayPal_Payouts:"Izinkokhelo ze-PayPal",
  Select_PayPal_Key:"Khetha ukhiye we-PayPal",
  Secret:"Imfihlo",
  API_Secret:"Imfihlo ye-API",
  PayPal_API_Keys:"Okhiye be-PayPal",
  New_PayPal_Key:"Ukhiye omusha we-PayPal",
  Email_Subject:"Isihloko se-imeyili",
  Email_Message:"Umlayezo we-imeyili",
  Payout_Options:"Izinketho zokukhokha",
  Payment_Note:"Inothi Lokukhokha",
  Enable_Employee:"Nika amandla Isisebenzi",
  Enable_Production_Mode:"Nika amandla Imodi Yokukhiqiza",
  Sandbox_Username:"Igama lomsebenzisi le-Sandbox",
  Sandbox_Signature:"Isignesha yebhokisi lesihlabathi",
  Sandbox_Password:"Iphasiwedi yebhokisi lesihlabathi",
  Production_Username:"Igama lomsebenzisi Lokukhiqiza",
  Production_Signature:"Isiginesha Yokukhiqiza",
  Production_Password:"Iphasiwedi yokukhiqiza",
  Production_Email:"I-imeyili Yokukhiqiza",
  API_Client_ID:"I-API Client ID",
  API_Signature:"Isignesha ye-API",
  API_Password:"Iphasiwedi ye-API",
  API_Username:"Igama lomsebenzisi le-API",
  Secret_Key:"Ukhiye Oyimfihlo",
  Sandbox:"Ibhokisi lesihlabathi",
  Production:"Ukukhiqiza",
  Sandbox_Keys:"Okhiye bebhokisi lesihlabathi",
  Production_Keys:"Okhiye Bokukhiqiza",
  API_Title:"Isihloko se-API",
  Production_Mode:"Imodi Yokukhiqiza",
  Account_Label:"Ilebula ye-akhawunti",
  No_PayPal_Setup:"Awukho ukhiye we-PayPal",
  Enable_PayPal_Account:"Nika amandla i-akhawunti ye-PayPal",
  No_PayPal_Word:"Engeza <a routerLink='/folder/Invoice/New'>Ukhiye we-PayPal API</a>.",
  Printed_Memo:"Imemo Ephrintiwe",
  Employee:"Isisebenzi",
  View_Employee:"Buka Isisebenzi",
  Mailing_Address:"Ikheli leposi",
  Company_Address:"Ikheli Lenkampani",
  Select_Company_Address:"Khetha Ikheli Lenkampani",
  Address:"Ikheli",
  Any_Day:"Noma yiluphi Usuku",
  Address_Name:"Igama Lekheli",
  Unit:"Iyunithi",
  Account:"I-akhawunti",
  Bank_Account:"I-Akhawunti Yasebhange",
  Account_Limits:"Nika amandla Imikhawulo Ye-akhawunti",
  Payroll:"Iholo",
  Run:"Gijima",
  Run_Payroll:"Qalisa I-Payroll",
  New_Payroll:"Iholo elisha",
  No_Payroll:"Ayikho Inkokhelo Ezayo",
  Upcoming_Holiday:"Iholide Elizayo:",
  Invoice_Due:"I-invoyisi ifuneka:",
  New_Invoice:"I-invoyisi Entsha",
  No_Invoices:"Awekho ama-invoyisi",
  No_Invoices_Word:"Dala <a routerLink='/folder/Invoice/New'>Invoyisi yokuqala</a>.",
  Cheque_Due:"Hlola Ifuneka:",
  Payrolls:"Amaholo",
  Sandbox_Mode:"Imodi yebhokisi lesihlabathi",
  Hire:"Qasha",
  Pay:"Khokha",
  New:"Okusha",
  Add:"Engeza",
  Make:"Yenza",
  Time:"Isikhathi",
  Write:"Bhala",
  Holiday:"Iholide",
  Holidays:"Amaholide",
  Next_Holiday:"Iholide Elilandelayo:",
  All_Done:"Konke Kwenziwe!",
  Employees:"Abasebenzi",
  Payees:"Abakhokhelwayo",
  Job:"Umsebenzi",
  Jobs:"Imisebenzi",
  Invoice:"I-invoyisi",
  Invoices:"Ama-invoyisi",
  Vacations:"Amaholide",
  Cheques:"Amasheke",
  Brand_Cheques:"Ibhrendi Yakho",
  Payment:"Inkokhelo",
  Enable_Payment:"Nika amandla Inkokhelo",
  Payments:"Izinkokhelo",
  Schedule:"Isheduli",
  Schedules:"Amashejuli",
  Timesheet:"Ishidi Lesikhathi",
  Timesheets:"AmaSpredishithi Esikhathi",
  Salary:"Umholo",
  New_Address:"Ikheli Elisha",
  Address_2:"Ikheli (Umugqa 2)",
  _City:"Idolobha",
  _State:"Izwe/IzAga",
  City:"Idolobha/Ilokishi",
  State:"Isifunda / Isifunda",
  Postal:"Ikhodi Yeposi / Yezip",
  ZIP:"Iposi / ZIP",
  Country:"Izwe",
  Addresses:"Amakheli",
  Required_Options:"Izinketho ezidingekayo",
  Optional_Options:"Izinketho Ongazikhetha",
  Additional_Options:"Izinketho Ezengeziwe",
  Required:"Kudingeka",
  Optional:"Ongakukhetha",
  Additional:"Okwengeziwe",
  No_Addresses:"Awekho Amakheli",
  New_Address_Word:"Engeza <a routerLink='/folder/Address/New'>Ikheli</a> lokuqala.",
  Select_Address:"Khetha Ikheli",
  No_Address:"Awekho Amakheli",
  Print_Cheque:"Isheke Lokuphrinta",
  Print_Cheques:"Amasheke Wokuphrinta",
  Print_Cheque_Now:"Phrinta Hlola Manje",
  Description:"Incazelo",
  Pay_To_The_Order_Of:"Khokha ku-oda lika:",
  Select_Date_Range:"Khetha Ibanga Ledethi",
  Select_Starting_Date:"Khetha Idethi Yokuqala",
  Select_Ending_Date:"Khetha Idethi Yokuphela",
  Select_Date:"Khetha Idethi",
  Cheque_Date:"Hlola Usuku",
  Cheque_Memo:"Bheka iMemo",
  Blank_Cheque:"Isheke Elingenalutho",
  Blank:"Akunalutho",
  No_Cheques:"Awekho Amasheke",
  No_Cheques_Word:"Phrinta <a routerLink='/folder/Cheque/New'>Hlola</a> yakho yokuqala.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"Buka Isithombe",
  View:"Buka",
  Modify:"Lungisa",
  Delete:"Susa",
  Cheque_Paid:"Ikhokhiwe",
  New_Deduction:"Ukudonswa Okusha",
  Title:"Isihloko",
  Frequency:"Imvamisa",
  Hourly:"Ngehora",
  Daily:"Nsuku zonke",
  Weekdays:"Izinsuku zeviki",
  BiWeekly:"2 Amaviki",
  TriWeekly:"3 Amasonto",
  Monthly:"Nyanga zonke",
  MiMonthly:"Izinyanga ezi-2",
  Quarterly:"Ngekota",
  Yearly:"Minyaka yonke",
  Every_Week:"Masonto onke",
  Every_Payroll:"Wonke ama-Payroll",
  Every_Month:"Nyanga zonke",
  Annually:"Minyaka yonke",
  Always_Scheduled:"Ihlezi Ihleliwe",
  Start_Date:"Usuku okuqalwa ngalo",
  End_Date:"Idethi Yokuphela",
  Start_Time:"Isikhathi sokuqala",
  End_Time:"Isikhathi Sokuphela",
  Deduction_Label:"Ilebula Lokukhipha",
  Notes:"Amanothi",
  Options:"Izinketho",
  Enable:"Nika amandla",
  Select_Deductions:"Khetha Okudonswayo",
  Deductions:"Izimali ezibanjwayo",
  No_Deductions:"Akukho Imali Ebanjwayo",
  No_Deductions_Word:"Dala <a routerLink='/folder/Deduction/New'>Ukubanjwa kwemali yakho yokuqala</a>.",
  New_Employee:"Isisebenzi Esisha",
  No_Title:"Asikho Isihloko",
  _Name:"Igama",
  About_Yourself:"Ngawe",
  Full_Name:"Igama eligcwele",
  Birthday:"Usuku lokuzalwa",
  Email:"I-imeyili",
  SMS:"I-SMS",
  Phone:"Ucingo",
  Test:"Hlola",
  Call:"Shaya ucingo",
  Fax:"Ifeksi",
  Printed_Note:"Inothi Eliphrintiwe",
  Position:"Isikhundla",
  Job_Position:"Isikhundla Somsebenzi",
  Select_a_Job:"Khetha Umsebenzi",
  Select_a_Salary:"Khetha Iholo",
  Add_a_Deduction:"Engeza Ukudonswa",
  Taxes:"Izintela",
  Add_Taxes:"Engeza Izintela",
  Date_of_Birth:"Usuku lokuzalwa",
  Email_Address:"Ikheli le-imeyili",
  Phone_Number:"Inombolo yocingo",
  Phone_Call:"Ucingo lwefoni",
  Enable_on_Payroll:"Nika amandla ku-Payroll",
  Select_Employees:"Khetha Abasebenzi",
  No_Employees:"Abekho Abasebenzi",
  No_Employees_Word:"Engeza entsha <a routerLink='/folder/Employee/New'>Isisebenzi</a> sakho sokuqala.",
  No_Name:"Alikho igama",
  Unemployeed:"Abangasebenzi",
  Employeed:"Kuqashiwe",
  Paid:"Ikhokhiwe",
  Enabled:"Inikwe amandla",
  Disabled:"Ikhutshaziwe",
  Fire:"Umlilo",
  Not_Available:"Akutholakali",
  Not_Available_Word:"Phrinta <a routerLink='/folder/Invoice/New'>Invoyisi yakho yokuqala</a> futhi ukhokhelwe.",
  Select_Invoices:"Khetha Ama-invoyisi",
  Print_Invoice_Word:"Phrinta <a routerLink='/folder/Invoice/New'>Invoyisi yakho yokuqala</a> futhi ukhokhelwe.",
  Invoice_Title:"Isihloko Serisidi",
  Invoice_Date:"Idethi ye-invoyisi",
  Due_Date:"Usuku olumisiwe",
  New_Job:"Umsebenzi Omusha",
  Details:"Imininingwane",
  Customize:"Enza ngendlela oyifisayo",
  Customize_Dashboard:"Yenza ngokwezifiso Ideshibhodi",
  Components:"Izingxenye",
  No_Components:"Azikho Izingxenye",
  Main_Components:"Izingxenye Eziyinhloko",
  Smaller_Components:"Izingxenye Ezincane",
  Error_Loading_Page:"Iphutha ekulayisheni leli khasi.",
  Bank_Details:"Imininingwane Yasebhange",
  About_Your_Job:"Mayelana Nomsebenzi Wakho",
  Your_Schedule:"Isheduli Yakho",
  Job_Title:"Isihloko somsebenzi",
  Job_Description:"Incazelo yomsebenzi",
  Job_Details:"Imininingwane Yomsebenzi",
  Enable_Job:"Nika amandla Umsebenzi",
  Pay_Period:"Isikhathi Sokukhokha",
  Perpetually_Schedule:"Hlela njalo",
  Select_Jobs:"Khetha Imisebenzi",
  No_Jobs:"Ayikho Imisebenzi",
  Add_Jobs:"Engeza Imisebenzi",
  No_Jobs_Word:"Engeza <a routerLink='/folder/Job/New'>Umsebenzi</a> wokuqala ohlwini.",
  Count_Employees:"job.employee_count+' Employees'",
  Zero_Employees:"0 Abasebenzi",
  New_Leave:"Ikhefu Elisha",
  Leave_Name:"Shiya Igama",
  Paid_Leave:"Ikhefu Elikhokhelwayo",
  Leave_Pay:"Shiya Inkokhelo",
  Indefinite_Leave:"Ikhefu Elingapheli",
  Indefinite_Payroll:"I-Infinite Payroll",
  Leave:"Hamba",
  Add_Schedules:"Engeza Amashejuli",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Nika amandla Ukungabibikho",
  Select_Leaves:"Khetha Amaqabunga",
  No_Leaves:"Awekho Amahlamvu Okungabikho",
  Leave_Of_Absence:"Ikhefu Lokungabibikho",
  Leaves_Of_Absence:"Amahlamvu Okungabikho",
  New_Leave_of_Absense:"Ikhefu Elisha Lokungabibikho",
  No_Leaves_Word:"Engeza eyokuqala <a routerLink='/folder/Leave/New'>Ikhefu Lokungabibikho</a>.",
  Not_Enabled:"Akuvunyelwe",
  Absences:"Ukungabi khona",
  Payee:"Okhokhelwayo",
  New_Payee:"Okhokhelwayo Omusha",
  Payee_Identifier:"Isihlonzi sokhokhelwayo",
  Payee_Name:"Igama Lokhokhelwayo",
  Payee_Title:"Isihloko sokhokhelwayo",
  Payment_Memo:"Imemo Yokukhokha",
  Select_Payees:"Khetha Abakhokhelwayo",
  No_Payees:"Abekho Abakhokhelwayo",
  Add_Payee_Note:"Engeza i-<a routerLink='/folder/Payee/New'>Okhokhelwayo</a> yokuqala.",
  New_Payees:"Abakhokhelwayo Abasha",
  About_The_Payment_Schedule:"Isheduli Yokukhokha",
  Your_Payroll_Schedule:"I-Automatic Payroll",
  New_Payment:"Inkokhelo Entsha",
  Identifier:"Isihlonzi",
  Selected:"Ikhethiwe",
  Select:"Khetha",
  Memo:"Imemo",
  Payment_Date:"Usuku Lokukhokha",
  Mark_as_Paid:"Maka Njengekhokhiwe",
  Select_Payments:"Khetha Izinkokhelo",
  No_Payments:"Azikho Izinkokhelo",
  No_Payments_Word:"Dala <a routerLink='/folder/Payment/New'>Inkokhelo</a> yokuqala.",
  Create_Payroll:"Dala i-Payroll",
  Properties:"Izakhiwo",
  Payroll_Title:"Isihloko Senkokhelo",
  Payroll_Notes:"Amanothi Okukhokha",
  Payroll_Setup:"Ukusethwa Kwenkokhelo",
  Tabulate_Payments:"Faka kuthebula Izinkokhelo",
  Tabulate:"Faka ithebula",
  By:"Ngu:",
  Payments_By:"Izinkokhelo Ngu",
  Timesheets_And_Schedules:"AmaSpredishithi Esikhathi namashejuli",
  Both:"Kokubili",
  Items:"Izinto",
  Add_Payees:"Engeza Abakhokhelwayo",
  Add_Account:"Engeza i-akhawunti",
  Enable_Account:"Nika amandla i-Akhawunti",
  Enable_Payroll:"Nika amandla i-Payroll",
  Print_Payroll:"Phrinta Inkokhelo",
  No_Payrolls:"Ayikho Inkokhelo",
  No_Payroll_Word:"Dala i-<a routerLink='/folder/Payroll/New'>Iholo</a> yakho yokuqala.",
  View_more:"VIEW_MORE",
  Less:"NGAPHANSI",
  Add_Payroll:"Engeza i-Payroll",
  Select_Payroll:"Khetha Inkokhelo",
  About_Your_Salary:"Mayelana Nomholo Wakho",
  Add_Salaries:"Engeza Amaholo",
  Add_Salary:"Engeza Iholo",
  Salaries:"Amaholo",
  No_Salaries:"Awekho Amaholo",
  No_Salaries_Word:"Engeza <a routerLink='/folder/Salary/New'>Iholo</a> lokuqala.",
  Select_Salaries:"Khetha Amaholo",
  New_Salary:"Umholo Omusha",
  Salary_Name:"Isihlonzi Somholo",
  Enable_Salary:"Nika amandla Iholo",
  Salary_Amount:"Imali Yomholo",
  New_Schedule:"Isheduli Entsha",
  Schedule_Title:"Isihloko Seshejuli",
  Add_Address:"Engeza Ikheli",
  Repeat:"Phinda",
  Design:"Idizayini",
  Edit_Design:"Hlela i-Design",
  Edit_this_Design:"Hlela lo Mklamo",
  Repeat_Payment:"Phinda Ukukhokha",
  Enable_Schedule:"Nika amandla Ishejuli",
  Never:"Ungalokothi",
  Select_Schedule:"Khetha Amashejuli",
  No_Schedules:"Awekho Amashejuli",
  No_Schedules_Word:"Dala <a routerLink='/folder/Schedule/New'>Shejuli</a> yokuqala.",
  New_Administrator:"Umlawuli Omusha",
  Username:"Igama lomsebenzisi",
  First_Name:"Igama",
  Last_Name:"Isibongo",
  Add_an_Address:"Engeza Ikheli",
  Payment_Limit:"Umkhawulo wenkokhelo ngayinye",
  Total_Limit:"Umkhawulo Ophelele",
  Select_Accounts:"Khetha ama-Akhawunti",
  No_Administrators:"Abekho Abalawuli",
  No_Administrators_Word:"Dala <a routerLink='/folder/Administrator/New'>Akhawunti Yomphathi</a> yokuqala.",
  New_Administrators_Word:"Engeza <a routerLink='/folder/Administrator/New'>Umlawuli</a> wokuqala wokuqala",
  Cloud:"Ifu",
  Backup:"Yenza ikhophi yasenqolobaneni",
  Enable_iCloud:"Nika amandla iCloud",
  Enable_Google_Drive:"Nika amandla i-Google Drayivu",
  Enable_Microsoft_OneDrive:"Nika amandla i-Microsoft OneDrive",
  Automatically_Backup:"Isipele Ngokuzenzakalelayo",
  FTP_Settings:"Izilungiselelo ze-FTP",
  URL_File_Prompt:"Sicela ucacise indawo yefayela le-.xls / .xlsx / .json ongalingenisa:",
  URL_SQL_Prompt:"Sicela ucacise indawo yefayela le-SQLite ongalingenisa:",
  FTP_Backup:"Isipele se-FTP",
  FTP_Import:"Ngenisa i-FTP",
  FTP:"I-FTP",
  SFTP:"I-SFTP",
  Host:"Umsingathi",
  Port:"Itheku",
  Path:"Indlela",
  Data_Path:"Umzila Wedatha",
  Enable_FTP_Account:"Nika amandla i-Akhawunti ye-FTP",
  HostnameIP:"Igama lomsingathi",
  Password:"Iphasiwedi",
  Connection_Port:"Imbobo yokuxhuma",
  Enable_MySQL_Database:"Nika amandla Isizindalwazi se-MySQL",
  Log:"Ilogi",
  Reset:"Setha kabusha",
  Erase:"Sula",
  Export:"Thekelisa",
  Database:"Isizindalwazi",
  Upload_CSV:"Layisha i-CSV",
  Download_CSV:"Landa i-CSV",
  SQL_Database:"I-URL ye-SQL",
  MySQL_Database:"I-MySQL",
  MySQL_Backup:"I-MySQL Backup",
  Internal_Notes:"Amanothi Angaphakathi",
  Root_Path:"Umzila Wezimpande",
  Select_Backup:"Khetha okuthi Isipele",
  Add_New_Backup:"Engeza Isipele Esisha",
  First_Backup:"Setha isipele sokuqala...",
  Backups:"Izipele",
  Add_Backup:"Engeza Isipele",
  No_Backups:"Azikho izipele ezizotholwa.",
  Select_Backup_Type:"Khetha uhlobo lwesipele ongathanda ukusetha...",
  Backup_Type:"Uhlobo Lwekhophi yasenqolobaneni",
  FTP_Drive:"I-FTP Drive",
  URL_Location:"I-URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Shayela",
  Microsoft_OneDrive:"Shayela",
  Import_Fields:"Ngenisa Izinkambu",
  Import_Fields_Word:"Sebenzisa lesi sigaba ukuze ukhethe <a routerLink='/folder/Holiday/New'>Ngenisa</a> idatha.",
  Upload:"Layisha",
  Download:"Landa",
  Download_JSON:"Landa njengedatha ye-JSON",
  Download_SQL:"Landa njengefayela le-SQL",
  New_Bank:"Ibhange Elisha",
  New_Account:"I-akhawunti Entsha",
  New_Bank_Account:"I-akhawunti Yebhange Entsha",
  Upload_Image:"Layisha Isithombe",
  Bank_Name:"Igama Lebhange",
  Bank_Address:"Ikheli Lebhange",
  Branch_Address:"Ikheli Legatsha",
  Address_on_Cheque:"Ikheli",
  Cheque_Numbers:"Hlola Izinombolo",
  Cheque_Details:"Hlola Imininingwane",
  Bank_Logo:"Ilogo yasebhange",
  Cheque_ID:"Hlola i-ID",
  Starting_Cheque_ID:"Iqala i-ID yokuhlola",
  Transit_Number:"Inombolo Yezokuthutha",
  Institution_Number:"Inombolo Yesikhungo",
  Designation_Number:"Inombolo Yokuqokwa",
  Account_Number:"Inombolo Ye-akhawunti",
  Limits:"Imikhawulo",
  Default_Limits:"Imikhawulo ezenzakalelayo",
  Over_Limit:"Over Limit",
  Under_Limit:"Ngaphansi Komkhawulo",
  Payroll_Limit:"Umkhawulo Wokukhokhelwa",
  Enable_Bank_Account:"Nika amandla I-akhawunti Yebhange",
  Select_Account:"Khetha i-Akhawunti",
  No_Bank_Account:"Ayikho I-akhawunti Yasebhange",
  No_Bank_Account_Word:"Engeza i-<a routerLink='/folder/Accounts/New'>Akhawunti Yasebhange</a> yokuqala.",
  Bank_Accounts:"Ama-Akhawunti Ebhange",
  No_Accounts:"Awekho ama-Akhawunti",
  No_Accounts_Note:"Engeza i-<a routerLink='/folder/Accounts/New'>Akhawunti Yasebhange</a> yokuqala.",
  Cheque_Designer:"Hlola Umklami",
  Cheque_Design:"Hlola i-Design",
  Select_Design:"Khetha Idizayini",
  Cheque_Designs:"Hlola Imiklamo",
  No_Cheque_Designs:"Ayikho Imiklamo Yokuhlola",
  No_Cheque_Designs_Word:"Dala eyakho <a routerLink='/folder/Settings/Cheque/Design/New'>Hlola Idizayini yakho</a>.",
  Set_Default:"Setha Njengokuzenzakalelayo",
  Default:"Okuzenzakalelayo",
  Remove:"Susa",
  Folder:"Ifolda",
  Edit:"Hlela",
  LoadingDots:"Iyalayisha...",
  Add_a_New_File:"Engeza <a href='#' (chofoza)='add()'>Ifayela Elisha</a> kulo",
  this_folder:"le folda",
  FTP_Backup_Settings:"Izilungiselelo zesipele se-FTP",
  Secure_File_Transfer:"Vikela Ukudluliswa Kwefayela",
  New_Holiday:"Iholidi Elisha",
  Add_Holiday:"Engeza Amaholide",
  Holiday_Name:"Igama Leholide",
  Additional_Pay:"Inkokhelo eyengeziwe",
  Enable_Holiday:"Nika Amaholide Amandla",
  Select_Holidays:"Khetha Amaholide",
  No_Holidays:"Awekho Amaholide",
  No_Holidays_Word:"Engeza <a routerLink='/folder/Holiday/New'>Iholide Lomphakathi</a> lokuqala.",
  New_Import:"Ukungenisa Okusha",
  Import_Data:"Ngenisa Idatha",
  Import_Data_Word:"Khetha uhlobo lwefayela noma indlela yokulayisha oyikhethayo.<br /> Uzokwazi ukukhetha noma yiziphi izinkambu ezingenisiwe efayelini ezihambisana nanoma iyiphi ipharamitha kuhlelo lokusebenza ngemva kokulayisha ifayela elisekelwe.",
  Import_File:"Ngenisa Ifayela",
  Link_To_File:"Xhuma kokuthi Ifayela",
  Select_File:"Khetha Ifayela",
  New_Moderator:"Umongameli Omusha",
  Allow_Moderation:"Vumela Ukulinganisela",
  Create_Paycheques:"Dala ama-Paycheques",
  Edit_Paycheques_and_Data:"Hlela ama-Paycheques kanye nedatha",
  Destroy_Data_and_Paycheques:"Bhubhisa Idatha nama-Paycheques",
  Bonus_Percentage:"Iphesenti le-Paycheque",
  Fixed_Amount:"Inani Eligxilile",
  Select_Moderator:"Khetha umongameli",
  No_Moderators:"Abekho Omengameli",
  Moderators:"Omengameli",
  Moderator_Account:"Dala <a routerLink='/folder/Administrator/New'>Akhawunti Yomengameli</a> yokuqala.",
  No_Moderators_Word:"Engeza <a routerLink='/folder/Administrator/New'>Umongameli</a> wokuqala.",
  Defaults:"Okuzenzakalelayo",
  Provide_Us_Info:"Nikeza Ulwazi",
  Setup_Your_Printer:"Setha Iphrinta Yakho",
  Your_Company:"Mayelana Nenkampani Yakho",
  Company_Name:"Igama Lenkampani",
  Currency:"Imali",
  Default_Currency:"Imali Ezenzakalelayo",
  Base_Monthly_Income:"Iholo Lenyanga",
  Protection:"Ukuvikelwa",
  App_Protection:"Ukuvikelwa kohlelo lokusebenza",
  PIN_Code_Protection:"I-PIN Ikhodi Ukuvikelwa",
  App_Protection_Word:"Nika amandla izindlela zokuphepha ezisiza ukuvikela i-akhawunti yakho.",
  PIN_Code:"I-PIN Ikhodi",
  Change_PIN:"Shintsha iphinikhodi",
  New_Password:"Iphasiwedi Entsha",
  Geofence_Protection:"I-Geo-Fence Protection",
  Geofence_Area:"Setha Indawo",
  Distance:"Ibanga",
  Setup_Now:"Misa Manje",
  Mile:"Imayela",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Ukuqashelwa Ubuso",
  Face:"Ubuso",
  Setup:"Setha",
  Label:"Ilebula",
  Password_Protection:"Ukuvikelwa Kwephasiwedi",
  Modify_Password:"Lungisa Iphasiwedi",
  New_Tax_Entry:"Ukungena Okusha Kwentela",
  New_Tax:"Intela Entsha",
  Tax_Label:"Ilebula yentela",
  Perpetually_Enabled:"Inikwe amandla njalo",
  Select_Taxes:"Khetha Izintela",
  Tax_Deductions:"Ukudonswa Kwentela",
  No_Tax_Deductions:"Akukho Ukudonswa Kwentela",
  No_Tax_Deductions_Word:"Engeza ukukhishwa kokuqala <a routerLink='/folder/Tax/New'>Intela</a>.",
  New_Timer:"Isikhathi Esisha",
  Start:"Qala",
  Stop:"Ima",
  Start_Timer:"Qala Isibali-sikhathi",
  Stop_Timer:"Misa Isikhathi",
  Timer_Active:"Isikhathi siyasebenza",
  Timer:"Isikhathi:",
  Timer_Running:"Isikhathi: (Igijima)",
  Save_Timer:"Londoloza Isikhathi",
  New_Timesheet:"Ishidi Lesikhathi Esisha",
  Select_Timesheets:"Khetha AmaSpredishithi Esikhathi",
  Work_Notes:"Amanothi Omsebenzi",
  Entry_Title:"Isihloko Sokungena",
  No_Timesheets:"Awekho AmaSpredishithi Esikhathi",
  No_Timesheets_Word:"Engeza <a routerLink='/folder/Timesheet/New'>Ishidi Lesikhathi</a> lokuqala.",
  Timesheet_Submitted:"Ishidi Lesikhathi Lithunyelwe",
  Timesheet_Congrats:"Siyakuhalalisela! Ishidi lakho lesikhathi lithunyelwe ngempumelelo. Ngiyabonga!",
  Timesheet_Copy:"Ukuze uthole ikhophi yamadokhumenti akho sicela usinikeze ikheli lakho le-imeyili kanye/noma inombolo yocingo lweselula.",
  Submit:"Thumela",
  Allow_Notifications:"Vumela Izaziso",
  Untitled_Entry:"Ukungena Okusha",
  Untitled_Bank:"Ibhange Elingenasihloko",
  Timesheet_Entry:"Ukufakwa Kweshidi Lesikhathi",
  Work_Description:"Incazelo Yomsebenzi",
  Enable_Timesheet:"Nika amandla Ishidi Lesikhathi",
  Submit_Timesheet:"Thumela Ishidi Lesikhathi",
  Vacation:"Iholide",
  New_Vacation:"Iholide Elisha",
  Vacation_Name:"Igama Leholide",
  Paid_Vacation:"Iholide Elikhokhelwayo",
  Vacation_Pay:"Inkokhelo Yeholide",
  Enable_Vacation:"Nika amandla Iholide",
  Select_Vacations:"Khetha Amaholide",
  No_Vacations:"Awekho Amaholide",
  No_Vacations_Word:"Dala okokuqala <a routerLink='/folder/Vacation/New'>Iholide</a>.",
  Payroll_Schedule:"I-Payroll schedule",
  Next_Payroll:"I-Payroll elandelayo:",
  Upcoming_Payroll:"I-Payroll ezayo",
  No_Upcoming_Payroll:"Ayikho Inkokhelo Ezayo",
  Address_Book:"Incwadi Yekheli",
  Archived_Documents:"Amadokhumenti Agciniwe",
  Dev_Mode:"Isicelo Kumodi Yokuthuthukisa",
  Administrators:"Abaphathi",
  Privacy:"Ubumfihlo",
  None:"Lutho",
  Select_Signature:"Khetha Isiginesha",
  No_Signatures:"Awekho Amasiginesha",
  No_Signatures_Word:"Engeza <a routerLink='/folder/Signature/New'>Isiginesha</a> yokuqala.",
  Repeat_Indefinitely:"Phinda unomphela",
  Sign:"Sayina",
  Sign_Here:"Sayina Lapha",
  Date_Signed:"Usuku okusayinwe ngalo",
  Signature_Pad:"Iphedi Yesiginesha",
  Account_Holder:"Umphathi we-akhawunti",
  Account_Properties:"Izakhiwo Ze-akhawunti",
  Name_On_Cheque:"Igama kusheke",
  Server_Hostname:"Igama Lomethuleli Weseva / IP",
  Printers:"Amaphrinta",
  No_Printers:"Awekho Amaphrinta",
  Printer_Exists:"Iphrinta enaleli gama isivele ikhona.",
  No_Printers_Word:"Engeza eyokuqala <a routerLink='/folder/Printer/New'>Iphrinta</a>.",
  No_Printer_Alert:"Ayikho iphrinta echaziwe. Ungathanda ukumisa iphrinta?",
  Add_Printer:"Engeza Iphrinta",
  New_Printer:"Iphrinta Entsha",
  Printer_Hostname:"Iphrinta Host-igama / IP",
  Printer_Label:"Ilebula Yephrinta",
  Printer_Protocol:"Iphrothokholi Yephrinta",
  Protocol:"Iphrothokholi",
  Email_Print:"I-imeyili",
  AirPrint:"I-AirPrint",
  IPP:"IPP",
  LPD:"I-LPD",
  HPJetDirect:"Isokhethi",
  Print_Job:"Umsebenzi Wokuphrinta",
  Printed:"Iphrintiwe",
  Not_Printed:"Ayiphrintiwe",
  Print_Jobs:"Imisebenzi Yokuphrinta",
  Print_Queue:"Umugqa Wokuphrinta",
  No_Print_Jobs:"Ayikho Imisebenzi Yokuphrinta",
  No_Prints:"Dala <a routerLink='/folder/Cheque/New'>Hlola</a> entsha ukuze uyiphrinte.",
  Prints:"Amaphrinti",
  Find_Printer:"Thola Iphrinta",
  Find_AirPrint_Devices:"Thola Amadivayisi e-AirPrint",
  Select_Printer:"Khetha Iphrinta",
  System_UI:"Isistimu ye-UI",
  Printer:"Iphrinta",
  Status:"Isimo",
  Preview:"Buka kuqala",
  Enable_Print_Job:"Nika amandla Umsebenzi Wokuphrinta",
  Queue_Weight:"Isisindo somugqa",
  Unlimited:"Okungenamkhawulo",
  Show_Advanced_Printer_Options:"Bonisa Izinketho Zephrinta Ezithuthukile",
  Advanced:"Okuthuthukile",
  Location:"Indawo",
  Note:"Qaphela",
  Queue_Name:"Igama lomugqa",
  Pages_Printed_Limit:"Umkhawulo Ophrintiwe Wamakhasi",
  MultiPage_Idle_Time:"Isikhathi sokulinda samakhasi amaningi (s)",
  Page_Count_Limit:"Umkhawulo Wesibalo Sekhasi",
  Page_Orientation:"Ukuma kwekhasi",
  Portrait:"Ukuma ngobude",
  Landscape:"Ukuma kwezwe",
  Duplex:"I-Duplex",
  Double_Sided_Printing:"Izinhlangothi Ezimbili",
  Duplex_Mode:"Imodi ye-Duplex",
  Duplex_Short:"Kufushane",
  Duplex_Long:"Yinde",
  Duplex_None:"Lutho",
  Color_And_Quality:"Umbala Nekhwalithi",
  Monochrome:"I-Monochrome",
  Photo_Quality_Prints:"Ukuphrinta Kwekhwalithi Yesithombe",
  Printer_Email:"I-imeyili Yephrinta",
  Automatically_Downsize:"Yehlisa Ngokuzenzakalelayo",
  Paper:"Iphepha",
  Paper_Name:"Igama Lephepha",
  Paper_Width:"Ububanzi Bephepha",
  Paper_Height:"Ukuphakama Kwephepha",
  Paper_Autocut_Length:"Ubude bokusika okuzenzakalelayo kwephepha",
  Margins:"Amamajini",
  Page_Margins:"Amamajini Ekhasi",
  Page_Margin_Top:"Imajini Yekhasi Eliphezulu",
  Page_Margin_Right:"Imajini Yekhasi Elingakwesokudla",
  Page_Margin_Bottom:"Imajini Yekhasi Elingezansi",
  Page_Margin_Left:"Imajini Yekhasi Lesobunxele",
  Add_Employees:"Engeza Abasebenzi",
  Header:"Unhlokweni",
  Print_A_Page_Header:"Phrinta Iheda Yekhasi",
  Header_Label:"Ilebula likanhlokweni",
  Header_Page_Index:"Inkomba Yekhasi Lesihloko",
  Header_Font_Name:"Ifonti Yekhanda",
  Select_Font:"Khetha Ifonti",
  Font_Selector:"Isikhethi Sefonti",
  Header_Font_Size:"Ifonti Yekhanda",
  Header_Bold:"Isihloko Esigqamile",
  Header_Italic:"I-ithalikhi yesihloko",
  Header_Alignment:"Ukuqondanisa Isihloko",
  Left:"Kwesokunxele",
  Center:"Isikhungo",
  Right:"Kulungile",
  Justified:"Kulungisiwe",
  Header_Font_Color:"Umbala Weheda",
  Select_Color:"Khetha Umbala",
  Footer:"Unyaweni",
  Print_A_Page_Footer:"Phrinta Unyaweni Wekhasi",
  Footer_Label:"Ilebula yaphansi",
  Footer_Page_Index:"Inkomba Yekhasi Eliphansi",
  Footer_Font_Name:"Ifonti yaphansi",
  Fonts:"Amafonti",
  Done:"Kwenziwe",
  Select_Fonts:"Khetha Amafonti",
  Footer_Font_Size:"Usayizi Waphansi",
  Footer_Bold:"Unyaweni Ogqamile",
  Footer_Italic:"I-Ithalikhi kanyaweni",
  Footer_Alignment:"Ukuqondanisa Kwaphansi",
  Footer_Font_Color:"Umbala Waphansi",
  Page_Copies:"Amakhophi Ekhasi",
  Enable_Printer:"Nika amandla Iphrinta",
  Remote_Logging:"Ukungena Okukude",
  Log_Server:"Iseva yelogi",
  Encryption:"Ukubethela",
  Random_Key:"Ukhiye Ongahleliwe",
  Encryption_Key:"Ukhiye Wokubethela",
  Cheques_Webserver:"Isizindalwazi Esingokwezifiso",
  Learn_How:"Funda Kanjani",
  Signature:"Isiginesha",
  Default_Printer_Unit:"ku/cm/mm/(pt)",
  View_Signature:"Buka Isiginesha",
  Printed_Signature:"Isiginesha Ephrintiwe",
  Digitally_Sign:"Sayina Ngedijithali",
  Digital_Signature:"Isignesha Yedijithali",
  Add_Signature:"Engeza Isiginesha",
  Add_Your_Signature:"Engeza Isiginesha Yakho",
  Enable_Signature:"Nika amandla Isiginesha",
  Digitally_Signed:"Kusayinwe Ngedijithali",
  Insert_Error:"Ayikwazi ukulondoloza isheke ngenxa yezinkinga zesizindalwazi.",
  Delete_Confirmation:"Uqinisekile ukuthi ufuna ukususa lolu lwazi?",
  Discard_Confirmation:"Uqinisekile ukuthi ufuna ukulahla lolu lwazi?",
  Discard_Bank_Confirmation:"Uqinisekile ukuthi ufuna ukulahla le akhawunti?",
  Discard_Printer_Confirmation:"Uqinisekile ukuthi ufuna ukulahla le phrinta?",
  Delete_Bonus_Confirmation:"Uqinisekile ukuthi ufuna ukususa le bhonasi?",
  Delete_Invoice_Confirmation:"Uqinisekile ukuthi ufuna ukususa le invoyisi?",
  Generated_Cheque:"Isheke Elikhiqiziwe",
  Generated_Invoice:"I-invoyisi eyakhiwe",
  Schedule_Start_Time:"Hlela Qala",
  Schedule_End_Time:"Ukuphela Kweshejuli",
  New_Call:"Ucingo Olusha",
  No_Contacts:"Abekho Oxhumana Nabo",
  No_Contacts_Word:"Abalawuli, omengameli, abasebenzi, nabakhokhelwayo bavela njengabathintwayo.",
  PDF_Subject:"ezezimali",
  PDF_Keywords:"i-payroll isheke lokuhlola isheke le-PDF",
  Printer_Setup:"Ukusethwa Kwephrinta",
  Printer_Selection:"Ukukhetha Iphrinta",
  New_Fax:"Ifeksi Entsha",
  New_Fax_Message:"Umlayezo Wefeksi Omusha",
  Fax_Machine:"Umshini Wefeksi",
  Fax_Name:"Igama lefeksi",
  Fax_Email:"I-imeyili Yefeksi",
  Fax_Number:"Inombolo Yefeksi",
  Contents:"Okuqukethwe",
  Fax_Body:"Umzimba Wekhasi",
  Header_Word:"Unhlokweni:",
  Header_Text:"Umbhalo Wesihloko",
  Include_Header:"Faka unhlokweni",
  Include_Footer:"Faka unyaweni",
  Footer_Word:"Unyaweni:",
  Footer_Text:"Umbhalo Osezansi",
  Attach_a_Cheque:"Namathisela isheke",
  Add_Deduction:"Engeza Ukudonswa",
  Enable_Fax:"Thumela ifeksi",
  Select_Fax:"Khetha Ifeksi",
  No_Faxes:"Awekho Amafeksi",
  Faxes:"Amafeksi",
  Save_and_Send:"Thumela ifeksi",
  Save_and_Pay:"Yonga bese Ukhokha",
  WARNING:"ISEXWAYISO:",
  Remember:"Khumbula",
  Printing:"Ukuphrinta",
  Printing_Complete:"Ukuphrinta Kuqediwe!\n\n",
  of:"kwe",
  There_Were:"Kade bengu",
  Successful_Prints:"amaphrinti aphumelele futhi",
  Unsuccessful_Prints:"amaphrinti angaphumelelanga.",
  PrinterError:"Uxolo! Kube nephutha.",
  Printing_:"Iyaphrinta...",
  PrinterSuccess:"Idokhumenti iphrintwe ngempumelelo.",
  PrintersSuccess:"Amadokhumenti aphrintwe ngempumelelo.",
  New_Message:"Umlayezo Omusha",
  New_Messages:"Imilayezo Emisha",
  Read_Message:"Funda Umlayezo",
  Write_Message:"Bhala Umlayezo",
  Send_Message:"Thumela umlayezo",
  Subject:"Isihloko",
  Message:"Umlayezo",
  Writing:"Iyabhala...",
  Send:"Thumela",
  Set_Date:"Setha Usuku",
  Set_Time:"Setha Isikhathi",
  Recieve:"Yamukela",
  Set_as_Default:"Setha Njengokuzenzakalelayo",
  Default_Account:"I-akhawunti Ezenzakalelayo",
  Default_Design:"Idizayini Ezenzakalelayo",
  Multiple_Cheques:"Amasheke Amathathu",
  Account_Mode:"Imodi ye-Akhawunti",
  Multiple_Cheques_Description:"Amasheke amathathu ekhasini ngalinye.",
  Check_and_Table:"Hlola & Ithebula",
  Check_including_Table:"Hlola ngethebula le-accounting.",
  Check_Mailer:"Bheka u-Mailer",
  Check_Mailer_Window:"Hlola ngewindi lekheli.",
  DocuGard_Table_Top:"Isheke elikhulu & Ithebula",
  DocuGard_Table_Middle:"Isheke elikhulu netafula (Phakathi)",
  DocuGard_Table_Bottom:"Isheke elikhulu netafula (Phansi)",
  DocuGard_Mailer_Top:"Imeyili Yesheke Enkulu",
  DocuGard_Mailer_Middle:"Umthumeli Wesheke Omkhulu (Phakathi)",
  DocuGard_Mailer_Bottom:"Imeyili Yesheke Enkulu (Phansi)",
  DocuGard_Three_Cheques:"Amasheke Amakhulu Amathathu",
  DocuGard_Cheque_Top:"Isheke Elikhulu Elilodwa",
  DocuGard_Cheque_Middle:"Isheke elikhulu (Phakathi)",
  DocuGard_Cheque_Bottom:"Isheke elikhulu (Phansi)",
  DocuGard_Three_Cheques_Window:"Amasheke amathathu ekhasini elilodwa.",
  DocuGard_Table_Top_Window:"Isheke & netafula lezinzuzo.",
  DocuGard_Table_Middle_Window:"Isheke & netafula lezinzuzo.",
  DocuGard_Table_Bottom_Window:"Isheke & netafula lezinzuzo.",
  DocuGard_Mailer_Top_Window:"Isheke, ithebula, nekheli.",
  DocuGard_Mailer_Middle_Window:"Isheke, ithebula, nekheli.",
  DocuGard_Mailer_Bottom_Window:"Isheke, ithebula, nekheli.",
  DocuGard_Cheque_Top_Window:"Hlola iphepha elivikelekile.",
  DocuGard_Cheque_Middle_Window:"Hlola iphepha elivikelekile.",
  DocuGard_Cheque_Bottom_Window:"Hlola iphepha elivikelekile.",
  Basic_Cheque:"Isheke Elilodwa",
  Basic_Cheque_Print:"Phrinta isheke elilodwa.",
  Error_Setting_As_Paid:"Iphutha Ukusetha Njengoba Kukhokhelwe",
  Add_Attachment:"Engeza Okunamathiselwe",
  PrinterUnavailable:"Iphrinta Ayitholakali",
  CreditCardComponent:"Amakhadi",
  PaypalComponent:"I-PayPal",
  InteracComponent:"I-Interac",
  BitcoinComponent:"I-BitCoin",
  New_Deposit:"Idiphozithi Entsha",
  Deposits:"Amadiphozithi",
  No_Deposits:"Awekho Amadiphozithi",
  Credit_Card_Deposit:"Ikhadi lesikweletu",
  New_Credit_Card_Deposit_Message:"Idiphozithi Yekhadi Lesikweletu",
  New_BitCoin_Deposit:"I-BitCoin",
  New_BitCoin_Deposit_Message:"Idiphozithi ye-BitCoin",
  New_Interac_Deposit:"I-Interac",
  New_Interac_Deposit_Message:"I-Interac Transfer",
  Payments_Limit:"Umkhawulo Wokukhokha",
  No_Payment_Limit:"Awukho Umkhawulo Wokukhokha",
  PayPal_Deposit:"I-PayPal",
  PayPal_Deposit_Message:"Idiphozithi ye-PayPal",
  No_Deposits_Word:"Engeza inkampani yokuqala <a routerLink='/folder/Deposit/New'>Idiphozithi</a>.",
  No_Documents_Specified:"Awekho amadokhumenti ashiwo ukuthi aphrintwe",
  No_Printers_Added:"Awekho amaphrinta atholakele. Iya kokuthi Izilungiselelo > Amaphrinta ukuze ungeze eyodwa.",
  DB_Erase_Prompt:"Sula ngokuphelele yonke imininingwane egciniwe? ISEXWAYISO: Uzolahlekelwa yilo lonke ulwazi olugciniwe!",
  Console_Warning:"Unganamathiseli noma yimuphi umbhalo kule khonsoli. Ungazifaka wena kanye/noma inkampani yakho engcupheni enkulu.",
  No_Faxes_Word:"Dala <a routerLink='/folder/Fax/New'>Ifeksi</a> yokuqala.",
  Cheque_Delete_Confirmation:"Uqinisekile ukuthi ufuna ukususa leli sheke?",
  Design_Delete_Confirmation:"Uqinisekile ukuthi ufuna ukususa lo mklamo?",
  Cheque_Pay_Confirmation:"Maka leli sheke njengelikhokhelwe? NGEKE kuvele kulayini wokuphrinta.",
  Payment_Pay_Confirmation:"Maka le nkokhelo njengekhokhiwe? NGEKE kuvele kulayini wokuhlola.",
  Delete_Deduction_Confirmation:"Uqinisekile ukuthi ufuna ukususa lokhu kubanjwa?",
  Delete_Job_Confirmation:"Uqinisekile ukuthi ufuna ukususa lo msebenzi?",
  Delete_Timesheet_Confirmation:"Uqinisekile ukuthi ufuna ukususa leli shidi lesikhathi?",
  Delete_Schedule_Confirmation:"Uqinisekile ukuthi ufuna ukususa le shejuli?",
  Delete_Setting_Confirmation:"Uqinisekile ukuthi ufuna ukusetha kabusha lesi silungiselelo?",
  Delete_Fax_Confirmation:"Uqinisekile ukuthi ufuna ukususa le fax?",
  Delete_File_Confirmation:"Uqinisekile ukuthi ufuna ukususa leli fayela?",
  Delete_Vacation_Confirmation:"Uqinisekile ukuthi ufuna ukususa leli holide?",
  Delete_Printer_Confirmation:"Uqinisekile ukuthi ufuna ukususa le phrinta?",
  Remove_Design_Confirmation:"Uqinisekile ukuthi ufuna ukususa lo mklamo?",
  Delete_Payroll_Confirmation:"Uqinisekile ukuthi ufuna ukususa le payroll?",
  Send_Fax_Email_Confirmation:"Ingabe uyafuna ukuyifeksa futhi uyithumele nge-imeyili lawa madokhumenti?",
  Send_Email_Confirmation:"Uyafuna ukuthumela le dokhumenti nge-imeyili?",
  Send_Fax_Confirmation:"Uyafuna ukufeksa le dokhumenti?",
  Error_Generating_PDF:"Uxolo. Kube nephutha ekukhiqizeni le dokhumenti.",
  PDF_Error_Saving_Image:"Uxolo. Kube nephutha ekulondolozeni isithombe se-PDF sale dokhumenti.",
  Test_Printer_Confirmation:"Ingabe ufuna ukuphrinta ikhasi lokuhlola kule phrinta?",
  Save_Timesheet_Prompt:"Sicela ungeze 'Isihloko' noma cindezela u-'Qala Isikhathi' ukuze ulondoloze.",
  Remove_Geofence_Prompt:"Uqinisekile ukuthi ufuna ukususa indawo yale geo-fence?",
  Delete_Employee_Confirmation:"Uqinisekile ukuthi ufuna ukususa",
  Fire_Employee_Confirmation:"Uqinisekile ukuthi ufuna ukuxosha"
}