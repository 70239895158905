export const Ur = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"کے آرڈر پر ادائیگی کریں:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/100",
  Copyright:"کاپی رائٹ &copy; 2023",
  black:"سیاہ",
  green:"سبز",
  gold:"سونا",
  blue:"نیلا",
  brown:"براؤن",
  purple:"جامنی",
  pink:"گلابی",
  red:"سرخ",
  Swatches:"جھولے۔",
  HSL:"ایچ ایس ایل",
  RGB:"آر جی بی",
  Hue:"رنگت",
  Saturation:"سنترپتی",
  Lightness:"ہلکا پن",
  Upgrade_To_Pro:"پرو میں اپ گریڈ کریں۔",
  AllFeaturesInclude:"تمام خصوصیات میں شامل ہیں:",
  PrintUnlimitedCheques:"لامحدود چیک پرنٹ کریں۔",
  PremiumChequeDesigns:"پریمیم چیک ڈیزائنز",
  ManageUnlimitedEmployees:"لامحدود ملازمین کا نظم کریں۔",
  AddUnlimitedPayees:"لامحدود ادائیگی کرنے والوں کو شامل کریں۔",
  CreateUnlimitedPayrolls:"لامحدود پے رولز بنائیں",
  UnlimitedSchedulesandTimeSheets:"لامحدود نظام الاوقات اور ٹائم شیٹس",
  BulkPayPalPayouts:"بلک پے پال ادائیگیاں",
  UnlimitedBankAccounts:"لامحدود بینک اکاؤنٹس",
  ManageMultipleCompanies:"متعدد کمپنیوں کا نظم کریں۔",
  TrackInsurancePolicies:"انشورنس پالیسیوں کو ٹریک کریں۔",
  Bio_MetricProtection:"بائیو میٹرک پروٹیکشن",
  Geo_FenceLock_OutProtection:"جیو فینس لاک آؤٹ پروٹیکشن",
  Multiple_Companies_Word:"چیک پریمیم کے بغیر متعدد کمپنیوں کا انتظام دستیاب نہیں ہے۔",
  PayPal_Payouts_Word:"پے پال کی ادائیگیاں چیک پریمیم کے بغیر غیر فعال ہیں۔",
  PINProtection:"پن کا تحفظ",
  PasswordProtection:"پاس ورڈ کا تحفظ",
  May_Require_Approval:"منظوری کی ضرورت ہو سکتی ہے۔",
  Subscribe:"سبسکرائب",
  Billed:"بل کیا گیا۔",
  Up:"اوپر",
  Down:"نیچے",
  Positioning:"پوزیشننگ",
  Marker:"مارکر",
  Drag_Marker:"مارکر کو گھسیٹیں۔",
  Tagline:"پرنٹ چیک اور ٹیبلیٹ پے رول",
  Marker_Word:"عنصر کو سائز دینے کے لیے مارکر استعمال کریں۔",
  Pinch_Zoom:"چوٹکی زوم",
  Pinch_Word:"عنصر کو زوم کرنے کے لیے چوٹکی لگائیں۔",
  Drag:"گھسیٹیں۔",
  Drag_Word:"عناصر کو گھسیٹنے کے لیے اپنی انگلی کا استعمال کریں۔",
  subscription_alias_word:"خودکار تجدید سبسکرپشن",
  premium_alias_word:"ایک وقتی اپ گریڈ پیکیج",
  print_alias_word:"پرنٹ آؤٹ انفرادی چیک",
  mode_alias_word:"موڈ",
  Pro:"پرو",
  Pro_word:"پرو ورژن درکار ہے۔",
  Cant_Delete_Default_Company_Word:"معذرت، آپ اپنی ڈیفالٹ کمپنی کو حذف نہیں کر سکتے۔",
  Reinsert_Default_Designs:"ڈیفالٹ ڈیزائن دوبارہ داخل کریں۔",
  Reinsert_Default_Designs_word:"کیا آپ ڈیفالٹ ڈیزائن دوبارہ داخل کرنا چاہتے ہیں؟",
  Subscription_Active_Disable_Word:"یہ رکنیت فعال ہے۔ کیا آپ چیک کی اس رکنیت کو منسوخ کرنا چاہیں گے؟",
  Company_Logo:"کمپنی کا لوگو",
  ZERO_:"صفر",
  Disclaimer:"ڈس کلیمر",
  Privacy_Policy:"رازداری کی پالیسی",
  EULA:"EULA چیک کرتا ہے۔",
  Terms_Of_Service:"سروس کی شرائط",
  Terms_Of_Use:"استعمال کرنے کی شرائط",
  Refunds:"رقم کی واپسی کی پالیسی",
  Single_Print:"1 چیک کریں۔",
  Two_Prints:"2 چیک",
  Five_Prints:"5 چیک",
  Ten_Prints:"10 چیک",
  Fifteen_Prints:"15 چیک",
  Twenty_Prints:"20 چیک",
  Thirty_Prints:"30 چیک",
  Image_Added:"تصویر شامل کر دی گئی۔",
  Image_Preview:"تصویری پیش نظارہ",
  No_Image_Was_Selected:"کوئی تصویر منتخب نہیں کی گئی۔",
  Cheques_Unlimited:"لامحدود چیک کرتا ہے۔",
  _Subscription:"رکنیت",
  Subscription:"چیکس - 1 مہینہ",
  Two_Month_Subscription:"چیکس - 2 ماہ",
  Three_Month_Subscription:"چیکس - 3 ماہ",
  Six_Month_Subscription:"چیکس - 6 ماہ",
  Twelve_Month_Subscription:"چیکس - 12 ماہ",
  Cheques_Are_Available:"چیک پرنٹ کرنے کے لیے دستیاب ہیں۔",
  Upgrade_Required_Two:"ایک پیکیج منتخب کریں اور اپنی خریداری شروع کرنے کے لیے قیمت کے بٹن پر دو بار تھپتھپائیں۔ پروفیشنل نظر آنے والے فل کلر چیک سیکنڈوں میں پرنٹ کریں۔",
  Month:"مہینہ",
  Due:"واجب الادا:",
  Expires:"میعاد ختم:",
  Subscription_Active:"سبسکرپشن فعال",
  Subscription_Inactive:"سبسکرپشن غیر فعال",
  Purchase_Additional_Cheques:"اضافی چیک خریدیں؟",
  Printable_Cheque:"پرنٹ ایبل چیک",
  Printable_Cheques:"پرنٹ ایبل چیکس",
  Printable_Cheque_Word:"چیک آپ کے اکاؤنٹ پر دستیاب ہے۔",
  Printable_Cheques_Word:"چیک آپ کے اکاؤنٹ پر دستیاب ہیں۔",
  Max_Amount_Message:"آپ کی بتائی گئی رقم اس سسٹم کے لیے مقرر کردہ زیادہ سے زیادہ رقم پر پہنچ گئی ہے:",
  Terms_Required_Word:"چیک کا استعمال جاری رکھنے سے پہلے آپ کو اس معاہدے سے اتفاق کرنا چاہیے۔",
  Subscriptions:"سبسکرپشنز",
  Product_Upgrades:"اپ گریڈ",
  Mailed_Out_Cheques:"میل آؤٹ چیکس",
  Enter_A_Company_Name:"براہ کرم کمپنی کا نام درج کریں۔",
  Single_Cheques:"سنگل چیکس",
  Cheque_Golden:"گولڈن چیک",
  Cheque_Golden_Window:"گولڈن چیک ڈیزائن۔",
  Cheque_Green:"گرین چیک",
  Cheque_Green_Window:"گرین چیک ڈیزائن۔",
  Cheque_Red:"ریڈ چیک",
  Cheque_Red_Window:"ریڈ چیک ڈیزائن۔",
  Cheque_Yellow:"پیلا چیک",
  Cheque_Yellow_Window:"پیلا چیک ڈیزائن۔",
  Cheque_Statue_of_Liberty:"مجسمہ آزادی",
  Cheque_Statue_of_Liberty_Window:"مجسمہ آزادی چیک ڈیزائن۔",
  Cheque_Green_Wave:"سبز لہر",
  Cheque_Green_Wave_Window:"گرین چیک ڈیزائن۔",
  Cheque_Golden_Weave:"گولڈن ویو",
  Cheque_Golden_Weave_Window:"خوبصورت گولڈن چیک ڈیزائن۔",
  Cheque_Green_Sun:"سبز سورج",
  Cheque_Green_Sun_Window:"گہرا اور پرسکون چیک ڈیزائن۔",
  Cheque_Blue_Checkers:"بلیو چیکرز",
  Cheque_Blue_Checkers_Window:"بلیو چیک ڈیزائن۔",
  Cashiers_Check:"کیشئر کا چیک",
  Cashiers_Check_Window:"کیشئر چیک اسٹائل ٹیمپلیٹ۔",
  Cheque_Aqua_Checkers:"ایکوا چیکرز",
  Cheque_Aqua_Checkers_Window:"ایکوا چیک ڈیزائن۔",
  Cheque_Golden_Checkers:"گولڈن چیکرز",
  Cheque_Golden_Checkers_Window:"گولڈن چیک ڈیزائن۔",
  Upgrade_Unavailable:"اپ گریڈز دستیاب نہیں ہیں۔",
  Bank_Code_Protection:"بینک نمبر پروٹیکشن",
  Bank_Code_Protection_Word:"اپنے بینک نمبرز کو اس ایپ پر استعمال ہونے سے بچائیں جو کسی دوسرے ڈیوائس پر چل رہے ہیں یا کسی دوسرے صارف کے لیے۔ یہ عمل ناقابل واپسی ہے۔ جاری رہے؟",
  Bank_Code_Protection_Blocked_Word:"آپ جو بینک نمبر استعمال کرنے کی کوشش کر رہے ہیں وہ کسی دوسرے صارف یا ڈیوائس کے لیے محفوظ ہیں۔",
  Bank_Code_Protection_Error_Word:"نمبر کی تصدیق ناکام ہو گئی ہے۔ براہ کرم انٹرنیٹ سے جڑیں اور اس بینک اکاؤنٹ کو دوبارہ شامل کرنے کی کوشش کریں۔",
  Bank_Code_Protection_Set_Error_Word:"بینک نمبر کے تحفظ کے لیے ضروری ہے کہ آپ انٹرنیٹ سے منسلک ہوں۔ براہ کرم جڑیں اور دوبارہ کوشش کریں۔",
  Upgrade_Unavailable_Word:"معذرت، ہمیں آپ کی تصدیق کرنے میں پریشانی ہو رہی ہے۔ چیکس کی سبسکرپشنز اور اپ گریڈ فی الحال آپ کے علاقے میں خریداری کے لیے دستیاب نہیں ہیں۔",
  PayPal_Payment_Preview:"پے پال ادائیگی کا پیش نظارہ",
  Apple_Pay:"ایپل پے",
  Select_PayPal:"پے پال کو منتخب کریں۔",
  PayPal_Applications:"پے پال ایپلی کیشنز",
  Purchase_With_Apple_Pay:"ایپل پے کے ساتھ خریداری کریں۔",
  Google_Pay:"گوگل پے",
  Companies:"کمپنیاں",
  Insurance:"انشورنس",
  New_PayPal:"نیا پے پال",
  Pay_By:"کی طرف سے ادا کریں",
  Insure:"بیمہ کرو",
  Miles:"میل",
  Payment_Method:"ادائیگی کا طریقہ",
  Select_Policies:"پالیسیاں منتخب کریں۔",
  Policies:"پالیسیاں",
  Policy:"پالیسی",
  No_PayPal_Account:"کوئی پے پال اکاؤنٹ نہیں۔",
  No_Policies:"کوئی انشورنس پالیسیاں نہیں۔",
  New_Policy:"نئی پالیسی",
  PayPal_Payment:"پے پال ادائیگی",
  PayPal_Payments:"پے پال ادائیگیاں",
  No_Payment_Selected:"کوئی ادائیگی منتخب نہیں کی گئی۔",
  Sending_Payment_Word:"براہ کرم انتظار کریں... یہ ادائیگی بھیجی جا رہی ہے۔",
  Sending_Payments_Word:"براہ کرم انتظار کریں... ادائیگیاں بھیجی جا رہی ہیں۔",
  No_Payment_Selected_PayPal:"بھیجنے کے لیے کوئی <a routerLink='/folder/Payments'>PayPal ادائیگی</a> منتخب نہیں کی گئی۔",
  Payment_Sent:"ادائیگی بھیج دی گئی۔",
  PayPal_Payment_Sent:"یہ ادائیگی پے پال کے ساتھ بھیجی گئی ہے۔",
  Copay:"کاپی کریں۔",
  Dead:"مردہ",
  Alive:"زندہ",
  Not_Dead:"نہیں مرا",
  Unclaimed:"غیر دعویدار",
  Attempted:"کوشش کی۔",
  Deceased:"متوفی",
  Claimed:"دعویٰ کیا۔",
  Unpaid:"بلا معاوضہ",
  Sending_Payment:"ادائیگی بھیج رہا ہے۔",
  Sending_Payments:"ادائیگیاں بھیجنا",
  Send_PayPal_Confirmation:"کیا آپ یہ لین دین پے پال کے ساتھ بھیجنا چاہتے ہیں؟",
  Send_PayPal_Confirmation_Word:"اس لین دین کو بھیجنے کے لیے سبز بٹن کو دبائیں۔",
  Save_Payment_As_Unpaid:"اس ادائیگی کو غیر ادا شدہ کے طور پر محفوظ کریں؟",
  Payment_Pay_Confirmation_PayPal:"اس ادائیگی کو بطور ادائیگی محفوظ کریں؟",
  No_Policies_Word:"پہلی <a routerLink='/folder/Postage/New'>انشورنس پالیسی</a> ابھی شامل کریں۔",
  Timesheet_Multiple_Delete_Confirmation:"کیا آپ واقعی متعدد ٹائم شیٹس کو حذف کرنا چاہتے ہیں؟",
  Select_Multiple_Timesheets_Prompt:"کوئی ٹائم شیٹ منتخب نہیں کی گئی۔ کم از کم ایک ٹائم شیٹ منتخب کریں۔",
  Select_Multiple_Policies_Prompt:"کوئی پالیسی منتخب نہیں کی گئی۔ کم از کم ایک انشورنس پالیسی منتخب کریں۔",
  Policies_Multiple_Delete_Confirmation:"کیا آپ واقعی متعدد پالیسیوں کو حذف کرنا چاہتے ہیں؟",
  Company:"کمپنی",
  Add_Company:"کمپنی شامل کریں۔",
  New_Company:"کمپنی شامل کریں۔",
  No_Companies:"کوئی کمپنیاں نہیں۔",
  Enable_Company:"کمپنی کو فعال کریں۔",
  Select_Company:"کمپنی منتخب کریں۔",
  The_Default_Company:"کمپنی کا ڈیفالٹ لیبل۔",
  Manage_Companies:"کمپنیوں کا انتظام کریں۔",
  No_Companies_Word:"چیک ڈیفالٹ کمپنی استعمال کریں گے۔<br /><a routerLink='/folder/Company/New'>پہلی کمپنی</a> شامل کریں۔",
  Default_Company:"ڈیفالٹ کمپنی",
  Cheques_Unlimited_Word:"چیکس لا محدود آپ کو جتنے چاہیں چیک پرنٹ کرنے کی اجازت دیتا ہے۔",
  Cheques_Subscription_Word:"چیک سبسکرپشن آپ کو اپنی مرضی کے مطابق زیادہ سے زیادہ چیک پرنٹ کرنے کی اجازت دیتا ہے۔",
  You_Own_This_Product:"آپ اس پروڈکٹ کے مالک ہیں۔",
  Your_Subscription_is_Active:"آپ کی رکنیت فعال ہے۔",
  Active_Products:"چالو مصنوعات",
  Purchase_Confirmation:"خریداری",
  Purchase_Cheques:"خریداری کے چیک",
  Restore_Purchase:"خریداریوں کو بحال کریں۔",
  Erase_Purchase:"خریداریوں کو مٹا دیں۔",
  Successfully_Purchased:"کامیابی کے ساتھ خریدا گیا۔",
  Enter_Your_Licence_Key:"اس پروڈکٹ کو چالو کرنے کے لیے براہ کرم اس صفحہ پر اپنی لائسنس کلید درج کریں۔",
  Licence_Key:"لائسنس کی چابی",
  Enter_Licence_Key:"لائسنس کلید درج کریں۔",
  Purchased:"خریدا گیا۔",
  Enable_Feature:"فیچر کو فعال کریں۔",
  Cancel_Subscription:"رکنیت منسوخ کریں۔",
  Subscription_Removed:"رکنیت ہٹا دی گئی۔",
  Select_Active_Subscription:"اس میں ترمیم کرنے کے لیے ایک فعال رکنیت منتخب کریں۔",
  Remove_Subscription_Word:"کیا آپ واقعی اس سبسکرپشن کو منسوخ کرنا چاہتے ہیں؟",
  Delete_Company_Confirmation:"کیا آپ واقعی اس پوری کمپنی کو حذف کرنا چاہتے ہیں؟ انتباہ: آپ تمام ذخیرہ شدہ معلومات کھو دیں گے!",
  Delete_Default_Company_Word:"آپ پہلے سے طے شدہ کمپنی کو حذف نہیں کرسکتے ہیں۔ کیا آپ ایپلیکیشن کو دوبارہ ترتیب دینا اور اسے ڈیفالٹ حالت میں بحال کرنا چاہیں گے؟ انتباہ: آپ تمام ذخیرہ شدہ معلومات کھو دیں گے!",
  Console_Warning_2:"اس ایپلیکیشن کا استعمال کرتے ہوئے کوئی بھی کرنسی نہ سنبھالیں جو فی الحال آپ کی نہیں ہے۔",
  Terms_and_Conditions:"شرائط و ضوابط",
  and_the:"اور",
  for:"کے لیے",
  Please_Read_Word:"براہ کرم پڑھیں اور اس سے اتفاق کریں۔",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"کچھ کرنسی کی تبدیلی کی شرحیں نہیں مل سکیں۔ براہ کرم انٹرنیٹ سے جڑیں۔",
  Free:"مفت",
  DB_Erase_Prompt_2:"پورے ڈویلپر ڈیٹا بیس کو مکمل طور پر مٹا دیں؟ انتباہ: آپ خریداری اور سبسکرپشن کی تمام معلومات کھو دیں گے!",
  Successfully_Imported:"کامیابی کے ساتھ درآمد کیا گیا۔",
  Select_Postage:"ڈاک منتخب کریں۔",
  No_Postage:"کوئی ڈاک ٹکٹ نہیں۔",
  No_Paid_Postage_Word:"پہلا <a routerLink='/folder/Postage/New'>بمعاوضہ ڈاک</a> سٹیمپ شامل کریں۔",
  Trial_Complete:"ٹرائل مکمل",
  Please_Upgrade:"پرنٹنگ جاری رکھنے کے لیے براہ کرم چیک اپ گریڈ کریں۔",
  Aa:"اے",
  Color:"رنگ",
  Font:"فونٹ",
  Guide:"رہنما",
  Guides:"گائیڈز",
  Image:"تصویر",
  Zoom:"زوم",
  Logo:"لوگو",
  Bank:"بینک",
  MICR:"MICR",
  Total:"کل",
  Cancel:"منسوخ کریں۔",
  Confirm:"تصدیق کریں۔",
  Method:"طریقہ",
  Biometric_Security:"بائیو میٹرک سیکیورٹی",
  Please_Login_To_Continue:"جاری رکھنے کے لئے لاگ ان کریں.",
  Complete:"مکمل",
  Sign_Up:"سائن اپ",
  Error:"خرابی",
  Biometrics:"بائیو میٹرکس",
  Percent:"فیصد",
  Zero_Percent:"0%",
  Top_Margin:"ٹاپ مارجن",
  Bottom_Margin:"نیچے مارجن",
  Left_Margin:"بائیں مارجن",
  Right_Margin:"دائیں مارجن",
  MICR_Margin:"MICR مارجن",
  Table_Margin:"ٹیبل مارجن",
  Address_Margin:"ایڈریس مارجن",
  Percentage_:"فیصد",
  Vacation_Title:"تعطیل کا عنوان",
  Use_PIN:"PIN استعمال کریں۔",
  Loading__:"لوڈ ہو رہا ہے...",
  Design_Title:"ڈیزائن کا عنوان",
  Authorize:"اختیار دینا",
  Key:"چابی",
  Public_Key:"عوامی کلید",
  Private_Key:"نجی کلید",
  Authenticate:"تصدیق کریں۔",
  Last_Payroll:"آخری پے رول",
  Last_Calculation:"آخری حساب",
  Authorized:"مجاز",
  Geo_Authorized:"جیو لوکیشن: مجاز",
  Not_Authorized:"مجاز نہیں۔",
  Authorization_Complete:"اجازت مکمل",
  Geolocation_Authorization:"جیو لوکیشن کی اجازت",
  Out_of_Bounds:"حد سے باہر",
  Cant_Delete_Default_Design:"پہلے سے طے شدہ ڈیزائن کو حذف نہیں کیا جا سکتا۔",
  Unauthorized_Out_of_Bounds:"غیر مجاز: حد سے باہر",
  Biometric_Authorization:"بائیو میٹرک کی اجازت",
  Locating_Please_Wait:"تلاش کر رہا ہے، براہ کرم انتظار کریں...",
  Test_Biometrics:"بائیو میٹرکس کی جانچ کریں۔",
  Cheque_Margins:"مارجن چیک کریں۔",
  Percentage_Full_Error:"فیصد کی فیلڈ کو 100% فیصد سے زیادہ سیٹ نہیں کیا جا سکتا۔",
  No_Payroll_Text:"ایک <a routerLink='/folder/Employee/New'>ملازم</a> یا <a routerLink='/folder/Payee/New'>ادا کنندہ</a> اور ایک <a routerLink='/folder/Schedule شامل کریں /نیا'>شیڈول</a>۔",
  Design_Saved:"ڈیزائن محفوظ کیا گیا۔",
  Default_Design_Selected:"پہلے سے طے شدہ ڈیزائن منتخب",
  Partial_Import:"جزوی درآمد",
  Partial_Export:"جزوی برآمد",
  Entire_Import:"پوری درآمد",
  Entire_Export:"پوری ایکسپورٹ",
  Existing_Password:"براہ کرم اپنا موجودہ پاس ورڈ درج کریں:",
  Existing_PIN:"براہ کرم اپنا موجودہ پن کوڈ درج کریں:",
  Pin_Change_Header:"پن کی تصدیق",
  Pin_Change_SubHeader:"تبدیلی کی تصدیق کے لیے اپنا پرانا پن نمبر درج کریں۔",
  Pass_Change_Header:"پاسورڈ کی تو ثیق",
  Pass_Change_SubHeader:"تبدیلی کی تصدیق کے لیے اپنا پرانا پاس ورڈ درج کریں۔",
  PIN_Enter_Message:"تصدیق کے لیے اپنا PIN درج کریں۔",
  Password_Enter_Message:"تصدیق کے لیے اپنا پاس ورڈ درج کریں۔",
  Pin_Updated_Success:"PIN کامیابی کے ساتھ اپ ڈیٹ ہو گیا!",
  Pin_Updated_Fail:"PIN کو اپ ڈیٹ نہیں کیا جا سکا۔",
  Pass_Updated_Success:"پاس ورڈ کامیابی کے ساتھ اپ ڈیٹ ہو گیا۔",
  Pass_Updated_Fail:"پاس ورڈ اپ ڈیٹ نہیں کیا جا سکا۔",
  Preview_Payment:"پیش نظارہ ادائیگی",
  Preview_Payroll:"پے رول کا پیش نظارہ کریں۔",
  No_Payments_Created:"کوئی ادائیگیاں نہیں پائی گئیں۔",
  Payment_Preview:"ادائیگی کا پیش نظارہ",
  Enable_Payee:"وصول کنندہ کو فعال کریں۔",
  Rendered:"پیش کیا گیا۔",
  No_Email:"کوئی ای میل نہیں۔",
  Setup_Cheques:"سیٹ اپ چیکس",
  name:"نام",
  address:"پتہ",
  address_2:"پتہ لائن 2",
  city:"شہر",
  province:"صوبہ",
  postal_code:"ڈاک / زپ کوڈ",
  country:"ملک",
  username:"صارف نام",
  full_name:"پورا نام",
  birthday:"سالگرہ",
  email:"ای میل",
  phone:"فون",
  employees:"ملازمین",
  addresses:"پتے",
  payment_amount_limit:"ادائیگی کی رقم کی حد",
  total_limit:"کل حد",
  payees:"ادا کرنے والے",
  description:"تفصیل",
  address_line_one:"ایڈریس لائن ون",
  address_line_two:"ایڈریس لائن ٹو",
  image:"تصویر",
  account_holder:"حساب کتاب کا مالک",
  cheque_starting_id:"شروع کرنے والی ID چیک کریں۔",
  transit_number:"ٹرانزٹ نمبر",
  institution_number:"ادارہ نمبر",
  designation_number:"عہدہ نمبر",
  account_number:"اکاؤنٹ نمبر",
  currency:"کرنسی",
  signature:"دستخط",
  payment_payroll_limit:"ادائیگی کی حد",
  total_limi:"کل حد",
  date:"تاریخ",
  printed_memo:"پرنٹ شدہ میمو",
  banks:"بینکوں",
  signature_image:"دستخطی تصویر",
  address_name:"پتہ کا نام",
  notes:"نوٹس",
  design:"ڈیزائن",
  title:"عنوان",
  frequency:"تعدد",
  fax:"فیکس",
  salaries:"تنخواہیں",
  salary_ids:"تنخواہ کی شناخت",
  start_time:"وقت آغاز",
  end_time:"آخر وقت",
  paid:"ادا کیا",
  overtime_percentage:"ادا شدہ فیصد",
  overtime_amount:"ادا شدہ مقررہ رقم",
  first_name:"پہلا نام",
  last_name:"آخری نام",
  moderation:"اعتدال",
  create:"بنانا",
  edit:"ترمیم",
  destroy:"تباہ کرنا",
  day_start_time:"دن کا_آغاز_وقت",
  day_end_time:"دن_اختتام_وقت",
  fullname:"نام",
  time:"وقت",
  auto_send:"خود بخود بھیجیں۔",
  time_method:"وقت کا طریقہ",
  schedules:"شیڈولز",
  indefinite_payroll_enabled:"غیر معینہ مدت تک فعال کریں۔",
  amount:"رقم",
  repeat:"دہرائیں۔",
  always_enabled:"ہمیشہ فعال",
  start_date:"شروع کرنے کی تاریخ",
  end_date:"آخری تاریخ",
  Cheque_Total:"ٹوٹل چیک کریں۔",
  Total_Amount:"کل رقم:",
  Amounts:"رقم:",
  Images:"امیجز",
  Files:"فائلوں",
  Previewing:"پیش نظارہ:",
  Insert:"داخل کریں",
  Preview_Import:"پیش نظارہ درآمد",
  Entry:"داخلہ",
  Entries:"اندراجات",
  No_Entries:"کوئی اندراجات نہیں۔",
  Import_Type:"درآمد کی قسم",
  Select_Details:"تفصیلات منتخب کریں۔",
  Select_Payee:"وصول کنندہ کو منتخب کریں۔",
  Enable_Holidays:"تعطیلات کو فعال کریں۔",
  Disable_Holidays:"تعطیلات کو غیر فعال کریں۔",
  Wire_Transfer:"وائر ٹرانسفر",
  New_Export:"نئی ایکسپورٹ",
  Export_Data:"ڈیٹا ایکسپورٹ کریں۔",
  Export_Data_Word:"برآمد اور ڈاؤن لوڈ کرنے کے لیے فائل کی قسم منتخب کریں۔",
  Export_File:"فائل برآمد کریں۔",
  Mode:"موڈ",
  Times:"اوقات",
  Widgets:"وجیٹس",
  Slider:"سلائیڈر",
  Set_Details:"تفصیلات مرتب کریں۔",
  Select_Type:"قسم منتخب کریں۔",
  Display_Slider:"ڈسپلے سلائیڈر",
  Dashboard_Slider:"ڈیش بورڈ سلائیڈر",
  Dashboard_Mode:"ڈیش بورڈ موڈ",
  Show_Intro:"تعارف دکھائیں۔",
  Show_Payrolls:"پے رولز دکھائیں۔",
  Show_Holidays:"تعطیلات دکھائیں۔",
  Show_Invoices:"رسیدیں دکھائیں۔",
  Show_Cheques:"چیک دکھائیں۔",
  Enabled_Widgets:"فعال وجیٹس",
  Disabled_Widgets:"غیر فعال وجیٹس",
  Colors:"رنگ",
  Barcodes:"بارکوڈز",
  View_Timers:"ٹائمر دیکھیں",
  Gradients:"میلان",
  No_Info:"کوئی معلومات نہیں۔",
  Disable:"غیر فعال کریں۔",
  Show_Layer:"پرتیں دکھائیں۔",
  Hide_Layer:"پرتیں چھپائیں۔",
  Text_Layer:"متن کی تہیں",
  Secondly:"دوسری بات",
  Minutely:"لمحہ بہ لمحہ",
  nine_am:"صبح کے 9:00 بجے",
  five_pm:"5:00 PM",
  Enable_Address:"ایڈریس کو فعال کریں۔",
  Invalid_File_Type:"معذرت، ایک غلط فائل کی قسم منتخب کی گئی تھی۔ تائید شدہ فائل کی قسم:",
  Error_Updating_Entry:"معذرت، اس اندراج کو اپ ڈیٹ کرنے میں ایک خامی تھی۔",
  Schedule_Timing_Alert:"خرابی: شیڈول شروع ہونے کا وقت اختتامی وقت کے بعد ایک قدر پر سیٹ کیا جاتا ہے۔",
  Select_Multiple_Payments_Prompt:"کوئی ادائیگیاں منتخب نہیں کی گئیں۔ کم از کم ایک ادائیگی منتخب کریں۔",
  Select_Multiple_Cheques_Prompt:"کوئی چیک منتخب نہیں کیا گیا۔ براہ کرم کم از کم ایک چیک منتخب کریں۔",
  Select_Multiple_Items_Prompt:"کوئی آئٹمز منتخب نہیں کیے گئے۔ براہ کرم کم از کم ایک آئٹم منتخب کریں۔",
  Paymemt_Multiple_Delete_Confirmation:"کیا آپ واقعی متعدد ادائیگیوں کو حذف کرنا چاہتے ہیں؟",
  Cheque_Multiple_Delete_Confirmation:"کیا آپ واقعی متعدد چیک حذف کرنا چاہتے ہیں؟",
  Payee_Multiple_Delete_Confirmation:"کیا آپ واقعی متعدد وصول کنندگان کو حذف کرنا چاہتے ہیں؟",
  Employee_Multiple_Delete_Confirmation:"کیا آپ واقعی متعدد ملازمین کو حذف کرنا چاہتے ہیں؟",
  MICR_Warning:"نوٹ: ہو سکتا ہے کچھ پرنٹرز اور آلات MICR فونٹ کو صحیح طریقے سے ظاہر نہ کریں۔",
  Automatically_Send:"خود بخود بھیجیں۔",
  Type:"قسم",
  Payment_Type:"ادائیگی کی قسم",
  Auto_Send:"خودکار بھیجیں۔",
  Automatically_Process:"خود بخود عمل کریں۔",
  Auto_Process:"آٹو پروسیس",
  Image_Based:"تصویر پر مبنی",
  Font_Based:"فونٹ پر مبنی",
  Rerender:"دوبارہ پیش کریں۔",
  Rendering:"رینڈرنگ",
  Render:"فائلوں",
  Top:"اوپر",
  Middle:"درمیانی",
  Bottom:"نیچے",
  Top_Left:"سب سے اوپر بائیں",
  Top_Center:"ٹاپ سینٹر",
  Top_Right:"اوپر سے دایاں",
  Middle_Left:"درمیانی بائیں",
  Middle_Center:"درمیانی مرکز",
  Middle_Right:"درمیانی دائیں",
  Bottom_Left:"نیچے بائیں",
  Bottom_Center:"نیچے کا مرکز",
  Bottom_Right:"نیچے دائیں طرف",
  Numbers:"نمبرز",
  Verified:"تصدیق شدہ",
  Paper_Size:"کاغذ کا سائز",
  New_Device:"نیا آلہ",
  Add_Device:"ڈیوائس شامل کریں۔",
  Remove_Device:"آلے کو ہٹا دیں",
  Delete_Device:"ڈیوائس کو حذف کریں۔",
  Block_Device:"ڈیوائس کو بلاک کریں۔",
  Block:"بلاک",
  Unblock:"غیر مسدود کریں۔",
  Table:"ٹیبل",
  Scan_Login_Code:"لاگ ان کوڈ اسکین کریں۔",
  Login_Code:"لاگ ان کوڈ",
  Scan_Code:"اسکین کوڈ",
  Scan_QR_Code:"کیو آر کوڈ اسکین کریں۔",
  Select_All:"تمام منتخب کریں",
  Deselect_All:"سبھی کو غیر منتخب کریں۔",
  Increase:"اضافہ",
  Decrease:"کمی",
  Bold:"بولڈ",
  Text:"متن",
  Style:"انداز",
  Italic:"ترچھا",
  QR_Code:"QR کوڈ",
  Barcode:"بارکوڈ",
  Browse_Images:"تصاویر کو براؤز کریں۔",
  Browse_Files:"فائلیں براؤز کریں۔",
  Background_Image:"پس منظر کی تصویر",
  Logo_Image:"لوگو کی تصویر",
  Header_Image:"ہیڈر کی تصویر",
  Bank_Image:"بینک کی تصویر",
  Cut_Lines:"لائنیں کاٹ دیں۔",
  Background:"پس منظر",
  License:"لائسنس",
  One_License:"1 لائسنس:",
  Licensed:"لائسنس یافتہ:",
  Not_Licensed:"لائسنس یافتہ نہیں۔",
  Enter_Serial:"سیریل درج کریں۔",
  Serial_Key:"سیریل کلید",
  Serial:"سیریل",
  Product_Key:"مصنوعہ کلید",
  Check_Product_Key:"پروڈکٹ کلید کی تصدیق کریں۔",
  Add_Product_Key:"پروڈکٹ کی کلید درج کریں۔",
  Verifying_Purchase:"خریداری کی توثیق ہو رہی ہے...",
  Print_Envelope:"لفافہ پرنٹ کریں۔",
  Envelope:"لفافے",
  Thank_You:"شکریہ!",
  Scale:"پیمانہ",
  Print_Scale:"پرنٹ اسکیل",
  Borders:"سرحدوں",
  VOID:"باطل",
  Void_Cheque:"باطل چیک",
  NO_DOLLARS:"کوئی ڈالر نہیں",
  ONE_DOLLAR:"ایک ڈالر",
  DOLLARS:"ڈالرز",
  AND:"اور",
  CENTS:" سینٹس۔",
  NO_:"نہیں",
  ONE_:"ایک",
  AND_NO_:"اور نہیں",
  _AND_ONE_:"اور ایک",
  DOLLARS_AND_ONE_CENT:"اور ایک سینٹ۔",
  AND_NO_CENTS:"اور صفر سینٹ۔",
  CHEQUE_PRINT_DATE:"تاریخ:",
  CHEQUE_PRINT_MEMO:"میمو:",
  CHEQUE_PRINT_SIGNATUREMP:"ایم پی",
  Initial_Tasks:"ابتدائی کام",
  Inital_Setup:"ابتدائی ڈھانچہ",
  Welcome_To:"میں خوش آمدید",
  Welcome:"خوش آمدید",
  Swipe:"سوائپ کریں۔",
  Intro_Setup:"انٹرو سیٹ اپ",
  Introduction:"تعارف",
  CHEQUE_PRINT_CREDIT:"چیکس کے ذریعہ تقویت یافتہ",
  Title_Intro_Word:"چیک میں خوش آمدید",
  Title_Intro:"تعارف چیک کرتا ہے۔",
  Title_Setup:"سیٹ اپ چیک کرتا ہے۔",
  PayPal_Default_Email_Message:"آپ کو ایک نیا PayPal ٹرانسفر موصول ہوا ہے۔",
  Cheques_App_Export:"چیک کے ذریعے برآمد کیا گیا۔",
  No_Prints_Purchased:"کوئی پرنٹس نہیں خریدے گئے۔",
  No_Prints_Purchased_Word:"کوئی اضافی پرنٹس دستیاب نہیں ہیں۔ براہ کرم اضافی چیک خریدیں۔",
  Post_Intro_Thanks:"چیک منتخب کرنے کے لیے آپ کا شکریہ۔ سیٹ اپ کا عمل اب مکمل ہو گیا ہے۔",
  Post_Intro_Word:"اپنے پہلے چیک کو پرنٹ کرکے شروع کریں، مستقبل کی ادائیگی شامل کریں، یا پے رول میں ملازمین کو شامل کریں۔",
  Upgrade_Required:"اس پیغام کو چھپانے اور اضافی خصوصیات کو غیر مقفل کرنے کے لیے چیکس کے لیے آپ کو سافٹ ویئر کے مزید پریمیم ورژن کا مالک ہونا چاہیے۔",
  Upgrade_Title:"چیک کرتا ہے۔",
  Mailout_Prompt:"آپ اضافی طور پر اپنے پے رول کے چیکوں کو اپنے لیے چیک بھیجنے کا انتخاب کر سکتے ہیں۔",
  Mailed_Cheque:"ڈاک چیک",
  Mailed_Cheque_Color:"میل چیک (رنگ)",
  Terms_Purchase:"چیک کے ساتھ تمام الیکٹرانک خریداریاں خریداری کی تاریخ سے 30 دنوں تک مکمل طور پر قابل واپسی ہیں۔ براہ کرم پڑھیں اور اس سے اتفاق کریں۔",
  Dashboard_Setup:"پرائمری پرنٹر سیٹ اپ کریں۔",
  Dashboard_Add:"پرائمری بینک اکاؤنٹ شامل کریں۔",
  Dashboard_Customize:"ایک چیک ٹیمپلیٹ کو منتخب کریں۔",
  Dashboard_Job_Salary:"اپنی ملازمت بنائیں اور اپنی تنخواہ شامل کریں۔",
  Dashboard_Employees:"ملازمین اور وصول کنندگان کو ٹریک کریں۔",
  Dashboard_Print:"اپنے پے چیک پرنٹ اور میل کریں۔",
  Dashboard_Payroll:"اپنے پے رول پرنٹنگ کو خودکار بنائیں",
  Dashboard_PayPal:"پے پال پے رول / ادائیگیاں ترتیب دیں۔",
  Begin_Setup:"سیٹ اپ شروع کریں۔",
  Get_Started:"شروع کرنے کے",
  Purchase:"خریداری",
  Lockdown:"لاک ڈاؤن",
  Unlock:"غیر مقفل کریں۔",
  Detailed:"تفصیلی",
  Log_In:"لاگ ان کریں",
  Login:"لاگ ان کریں",
  Launch:"لانچ کریں۔",
  Register:"رجسٹر کریں۔",
  Finish:"ختم کرنا",
  List:"فہرست",
  Weekends:"ویک اینڈ",
  Weekly:"ہفتہ وار",
  PayPal_Default_Subject:"نئی ادائیگی",
  Payment_Message:"ادائیگی کا پیغام",
  PayPal_Default_Payment_Note:"شکریہ",
  Setup_Your_Cheqing_Account:"چیکنگ اکاؤنٹ",
  Add_Your_Primary_Cheqing_Account:"اپنا بنیادی چیکنگ اکاؤنٹ شامل کریں۔",
  Welcome_Word:"پے رول اور انسانی وسائل کے انتظام کو آسان اور خودکار بنائیں۔",
  Get_Started_Quickly:"بس چند آسان سوالات کے جوابات دیں جو ہمیں شروع کرنے میں مدد کریں گے...",
  Done_Intro_Word:"سیٹ اپ مکمل",
  PIN_Protected:"پاس ورڈ اور پن محفوظ",
  Enter_New_PIN:"ایک نیا پن کوڈ درج کریں:",
  Enter_PIN:"پن کوڈ درج کریں:",
  Invalid_PIN:"غلط PIN درج کیا گیا۔",
  Account_Identifier:"اکاؤنٹ شناخت کنندہ",
  New_Account_Identifier:"نیا اکاؤنٹ شناخت کنندہ",
  attempt:"کوشش",
  attempts:"کوششیں",
  remaining:"باقی",
  Language:"زبان",
  languages:"زبانیں",
  select_languages:"زبان منتخب کریں۔",
  Deposit:"جمع",
  Hire_One_Now:"ابھی ایک کی خدمات حاصل کریں۔",
  App_Locked:"درخواست مقفل ہے۔",
  Skip_:"چھوڑ دو",
  Skip_to_Dashboard:"ڈیش بورڈ پر جائیں۔",
  Dashboard:"ڈیش بورڈ",
  Import:"درآمد کریں۔",
  Admin:"منتظمین",
  New_Admin:"نیا ایڈمن",
  Settings:"ترتیبات",
  Color_Picker:"رنگ چننا",
  Font_Picker:"فونٹ چننے والا",
  Logout:"لاگ آوٹ",
  Close:"بند کریں",
  Close_menu:"بند کریں",
  Excel:"ایکسل فائل",
  Csv:"CSV فائل",
  Sql:"ایس کیو ایل فائل",
  Json:"JSON فائل",
  Url:"یو آر ایل کے ذریعے درآمد کریں۔",
  Back:"پیچھے",
  Timers:"ٹائمرز",
  Cheque:"چیک کریں۔",
  Print:"پرنٹ کریں",
  Designs:"ڈیزائنز",
  Pause_Printing:"پرنٹنگ کو روکیں۔",
  Resume_Printing:"پرنٹنگ دوبارہ شروع کریں۔",
  Printing_Paused:"پرنٹنگ روک دی گئی۔",
  PrintingUnavailable:"معذرت! اس سسٹم پر پرنٹنگ دستیاب نہیں ہے۔",
  Prints_Paused:"پرنٹس روک دیے گئے۔",
  Administration:"انتظامیہ",
  Loading:"لوڈ ہو رہا ہے۔",
  Unnamed:"نام",
  error:"معذرت، یہ چیک دیکھنے کے لیے نہیں کھولا جا سکا۔",
  No_Cheques_To_Print:"پرنٹ کرنے کے لیے کوئی چیک نہیں۔",
  No_Cheques_To_Print_Word:"ایک <a routerLink='/folder/Cheque/New'>نیا چیک</a> بنائیں۔",
  New_Cheque:"نیا چیک",
  Start_One_Now:"ابھی ایک شروع کریں۔",
  Edit_Cheque:"چیک میں ترمیم کریں۔",
  Select_Cheques:"چیک منتخب کریں۔",
  Select_Employee:"ملازم کو منتخب کریں۔",
  Default_Printer:"ڈیفالٹ پرنٹر",
  Reassign:"دوبارہ تفویض کریں۔",
  Configure:"ترتیب دیں۔",
  Template:"سانچے",
  Designer:"ڈیزائنر",
  Edit_Designs:"ڈیزائن میں ترمیم کریں۔",
  New_Design:"نئی ڈیزائن",
  Next:"اگلے",
  Save:"محفوظ کریں۔",
  Name:"نام",
  Mail:"میل",
  Amount:"رقم",
  Date:"تاریخ",
  PayPal:"پے پال",
  Payouts:"ادائیگیاں",
  PayPal_Label:"پے پال لیبل",
  Email_Username:"ای میل / صارف نام",
  Client_ID:"کلائنٹ ID",
  Sandbox_Email:"سینڈ باکس ای میل",
  PayPal_Email:"پے پال ای میل",
  PayPal_Username:"API صارف نام",
  PayPal_Payouts:"پے پال ادائیگیاں",
  Select_PayPal_Key:"پے پال کی کو منتخب کریں۔",
  Secret:"خفیہ",
  API_Secret:"API خفیہ",
  PayPal_API_Keys:"پے پال کیز",
  New_PayPal_Key:"نئی پے پال کلید",
  Email_Subject:"ای میل کے موضوع",
  Email_Message:"ای میل پیغام",
  Payout_Options:"ادائیگی کے اختیارات",
  Payment_Note:"ادائیگی کا نوٹ",
  Enable_Employee:"ملازم کو فعال کریں۔",
  Enable_Production_Mode:"پروڈکشن موڈ کو فعال کریں۔",
  Sandbox_Username:"سینڈ باکس صارف نام",
  Sandbox_Signature:"سینڈ باکس دستخط",
  Sandbox_Password:"سینڈ باکس پاس ورڈ",
  Production_Username:"پیداوار کا صارف نام",
  Production_Signature:"پروڈکشن دستخط",
  Production_Password:"پروڈکشن پاس ورڈ",
  Production_Email:"پروڈکشن ای میل",
  API_Client_ID:"API کلائنٹ ID",
  API_Signature:"API دستخط",
  API_Password:"API پاس ورڈ",
  API_Username:"API صارف نام",
  Secret_Key:"خفیہ کلید",
  Sandbox:"سینڈ باکس",
  Production:"پیداوار",
  Sandbox_Keys:"سینڈ باکس کیز",
  Production_Keys:"پیداوار کی چابیاں",
  API_Title:"API عنوان",
  Production_Mode:"پروڈکشن موڈ",
  Account_Label:"اکاؤنٹ کا لیبل",
  No_PayPal_Setup:"کوئی پے پال کلید نہیں۔",
  Enable_PayPal_Account:"پے پال اکاؤنٹ کو فعال کریں۔",
  No_PayPal_Word:"اپنا <a routerLink='/folder/Invoice/New'>PayPal API کلید</a> شامل کریں۔",
  Printed_Memo:"پرنٹ شدہ میمو",
  Employee:"ملازم",
  View_Employee:"ملازم کو دیکھیں",
  Mailing_Address:"ڈاک کا پتہ",
  Company_Address:"کمپنی ایڈریس",
  Select_Company_Address:"کمپنی کا پتہ منتخب کریں۔",
  Address:"پتہ",
  Any_Day:"کسی بھی دن",
  Address_Name:"پتہ کا نام",
  Unit:"یونٹ",
  Account:"کھاتہ",
  Bank_Account:"بینک اکاؤنٹ",
  Account_Limits:"اکاؤنٹ کی حدود کو فعال کریں۔",
  Payroll:"پے رول",
  Run:"رن",
  Run_Payroll:"پے رول چلائیں۔",
  New_Payroll:"نیا پے رول",
  No_Payroll:"کوئی آئندہ پے رول نہیں۔",
  Upcoming_Holiday:"آنے والی چھٹی:",
  Invoice_Due:"انوائس واجب الادا:",
  New_Invoice:"نئی رسید",
  No_Invoices:"کوئی رسیدیں نہیں۔",
  No_Invoices_Word:"پہلا <a routerLink='/folder/Invoice/New'>انوائس</a> بنائیں۔",
  Cheque_Due:"واجب الادا چیک کریں:",
  Payrolls:"پے رولز",
  Sandbox_Mode:"سینڈ باکس موڈ",
  Hire:"کرایہ پر لینا",
  Pay:"ادا کریں۔",
  New:"نئی",
  Add:"شامل کریں۔",
  Make:"بنائیں",
  Time:"وقت",
  Write:"لکھیں۔",
  Holiday:"چھٹی",
  Holidays:"چھٹیاں",
  Next_Holiday:"اگلی چھٹی:",
  All_Done:"سب ہو گیا!",
  Employees:"ملازمین",
  Payees:"ادا کرنے والے",
  Job:"جاب",
  Jobs:"نوکریاں",
  Invoice:"رسید",
  Invoices:"رسیدیں",
  Vacations:"تعطیلات",
  Cheques:"چیک کرتا ہے۔",
  Brand_Cheques:"آپ کا برانڈ",
  Payment:"ادائیگی",
  Enable_Payment:"ادائیگی کو فعال کریں۔",
  Payments:"ادائیگیاں",
  Schedule:"شیڈول",
  Schedules:"شیڈولز",
  Timesheet:"وقت شیٹ",
  Timesheets:"ٹائم شیٹس",
  Salary:"تنخواہ",
  New_Address:"نیا پتہ",
  Address_2:"پتہ لائن 2)",
  _City:"شہر",
  _State:"ریاست/پروو",
  City:"شہر/ ٹاؤن شپ",
  State:"ریاست / صوبہ",
  Postal:"ڈاک / زپ کوڈ",
  ZIP:"پوسٹل/زپ",
  Country:"ملک",
  Addresses:"پتے",
  Required_Options:"مطلوبہ اختیارات",
  Optional_Options:"اختیاری اختیارات",
  Additional_Options:"اضافی اختیارات",
  Required:"درکار ہے۔",
  Optional:"اختیاری",
  Additional:"اضافی",
  No_Addresses:"کوئی پتہ نہیں۔",
  New_Address_Word:"پہلا <a routerLink='/folder/Address/New'>پتہ</a> شامل کریں۔",
  Select_Address:"پتہ منتخب کریں۔",
  No_Address:"کوئی پتہ نہیں۔",
  Print_Cheque:"پرنٹ چیک",
  Print_Cheques:"پرنٹ چیک",
  Print_Cheque_Now:"ابھی پرنٹ چیک کریں۔",
  Description:"تفصیل",
  Pay_To_The_Order_Of:"کے آرڈر پر ادائیگی کریں:",
  Select_Date_Range:"تاریخ کی حد منتخب کریں۔",
  Select_Starting_Date:"شروع کرنے کی تاریخ منتخب کریں۔",
  Select_Ending_Date:"اختتامی تاریخ منتخب کریں۔",
  Select_Date:"تاریخ منتخب کریں۔",
  Cheque_Date:"تاریخ چیک کریں۔",
  Cheque_Memo:"میمو چیک کریں۔",
  Blank_Cheque:"خالی چیک",
  Blank:"خالی",
  No_Cheques:"کوئی چیک نہیں۔",
  No_Cheques_Word:"اپنا پہلا <a routerLink='/folder/Cheque/New'>چیک کریں۔</a> پرنٹ کریں۔",
  Cheque_Amount_Placeholder:" $0.00",
  View_Image:"تصویر دیکھنا",
  View:"دیکھیں",
  Modify:"ترمیم کریں۔",
  Delete:"حذف کریں۔",
  Cheque_Paid:"ادا کیا",
  New_Deduction:"نئی کٹوتی",
  Title:"عنوان",
  Frequency:"تعدد",
  Hourly:"فی گھنٹہ",
  Daily:"روزانہ",
  Weekdays:"ہفتے کے دن",
  BiWeekly:"2 ہفتے",
  TriWeekly:"3 ہفتے",
  Monthly:"ماہانہ",
  MiMonthly:"2 مہینے",
  Quarterly:"سہ ماہی",
  Yearly:"سالانہ",
  Every_Week:"ہر ہفتے",
  Every_Payroll:"ہر پے رول",
  Every_Month:"ہر مہینے",
  Annually:"سالانہ",
  Always_Scheduled:"ہمیشہ شیڈول",
  Start_Date:"شروع کرنے کی تاریخ",
  End_Date:"آخری تاریخ",
  Start_Time:"وقت آغاز",
  End_Time:"آخر وقت",
  Deduction_Label:"کٹوتی کا لیبل",
  Notes:"نوٹس",
  Options:"اختیارات",
  Enable:"فعال",
  Select_Deductions:"کٹوتیوں کو منتخب کریں۔",
  Deductions:"کٹوتیاں",
  No_Deductions:"کوئی کٹوتیاں نہیں۔",
  No_Deductions_Word:"اپنا پہلا <a routerLink='/folder/Deduction/New'>کٹوتی</a> بنائیں۔",
  New_Employee:"نیا کارکن",
  No_Title:"بلا عنوان",
  _Name:"نام",
  About_Yourself:"اپنے بارے میں",
  Full_Name:"پورا نام",
  Birthday:"سالگرہ",
  Email:"ای میل",
  SMS:"پیغام",
  Phone:"فون",
  Test:"پرکھ",
  Call:"کال کریں۔",
  Fax:"فیکس",
  Printed_Note:"پرنٹ شدہ نوٹ",
  Position:"پوزیشن",
  Job_Position:"عہدہ",
  Select_a_Job:"ایک نوکری منتخب کریں۔",
  Select_a_Salary:"تنخواہ منتخب کریں۔",
  Add_a_Deduction:"ایک کٹوتی شامل کریں۔",
  Taxes:"ٹیکس",
  Add_Taxes:"ٹیکس شامل کریں۔",
  Date_of_Birth:"پیدائش کی تاریخ",
  Email_Address:"ای میل اڈریس",
  Phone_Number:"فون نمبر",
  Phone_Call:"فون کال",
  Enable_on_Payroll:"پے رول پر فعال کریں۔",
  Select_Employees:"ملازمین کو منتخب کریں۔",
  No_Employees:"کوئی ملازم نہیں۔",
  No_Employees_Word:"اپنا پہلا نیا <a routerLink='/folder/Employee/New'>ملازم</a> شامل کریں۔",
  No_Name:"گمنام",
  Unemployeed:"بے روزگار",
  Employeed:"ملازم",
  Paid:"ادا کیا",
  Enabled:"فعال",
  Disabled:"معذور",
  Fire:"آگ",
  Not_Available:"دستیاب نہیں ہے",
  Not_Available_Word:"اپنا پہلا <a routerLink='/folder/Invoice/New'>انوائس</a> پرنٹ کریں اور ادائیگی کریں۔",
  Select_Invoices:"رسیدیں منتخب کریں۔",
  Print_Invoice_Word:"اپنا پہلا <a routerLink='/folder/Invoice/New'>انوائس</a> پرنٹ کریں اور ادائیگی کریں۔",
  Invoice_Title:"انوائس کا عنوان",
  Invoice_Date:"رسید کی تاریخ",
  Due_Date:"اخری تاریخ",
  New_Job:"نیا کام",
  Details:"تفصیلات",
  Customize:"حسب ضرورت بنائیں",
  Customize_Dashboard:"ڈیش بورڈ کو حسب ضرورت بنائیں",
  Components:"اجزاء",
  No_Components:"کوئی اجزاء نہیں۔",
  Main_Components:"اہم اجزاء",
  Smaller_Components:"چھوٹے اجزاء",
  Error_Loading_Page:"اس صفحہ کو لوڈ کرنے میں خرابی۔",
  Bank_Details:"بینک کی تفصیلات",
  About_Your_Job:"آپ کی ملازمت کے بارے میں",
  Your_Schedule:"آپ کا شیڈول",
  Job_Title:"ملازمت کا عنوان",
  Job_Description:"کام کی تفصیل",
  Job_Details:"ملازمت کی تفصیلات",
  Enable_Job:"جاب کو فعال کریں۔",
  Pay_Period:"ادائیگی کی مدت",
  Perpetually_Schedule:"مستقل طور پر شیڈول",
  Select_Jobs:"ملازمتیں منتخب کریں۔",
  No_Jobs:"کوئی نوکریاں نہیں۔",
  Add_Jobs:"نوکریاں شامل کریں۔",
  No_Jobs_Word:"فہرست میں پہلا <a routerLink='/folder/Job/New'>جاب</a> شامل کریں۔",
  Count_Employees:"job.employee_count+' ملازمین'",
  Zero_Employees:"0 ملازمین",
  New_Leave:"نئی چھٹی",
  Leave_Name:"نام چھوڑیں۔",
  Paid_Leave:"ادا شدہ چھٹی",
  Leave_Pay:"تنخواہ چھوڑ دیں۔",
  Indefinite_Leave:"غیر معینہ مدت کی چھٹی",
  Indefinite_Payroll:"غیر معینہ تنخواہ",
  Leave:"چھوڑو",
  Add_Schedules:"شیڈولز شامل کریں۔",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"غیر حاضری کو فعال کریں۔",
  Select_Leaves:"پتے منتخب کریں۔",
  No_Leaves:"غیر حاضری کی کوئی پتی نہیں۔",
  Leave_Of_Absence:"غیر حاضری کی چھٹی",
  Leaves_Of_Absence:"غیر موجودگی کے پتے",
  New_Leave_of_Absense:"غیر حاضری کی نئی چھٹی",
  No_Leaves_Word:"پہلا <a routerLink='/folder/Leave/New'>غیر موجودگی کی چھٹی</a> شامل کریں۔",
  Not_Enabled:"فعال نہیں ہے۔",
  Absences:"غیر حاضریاں",
  Payee:"ادا کرنے والا",
  New_Payee:"نیا وصول کنندہ",
  Payee_Identifier:"وصول کنندہ شناخت کنندہ",
  Payee_Name:"وصول کرنے والے کا نام",
  Payee_Title:"وصول کنندہ کا عنوان",
  Payment_Memo:"ادائیگی کا میمو",
  Select_Payees:"وصول کنندگان کو منتخب کریں۔",
  No_Payees:"کوئی ادا کرنے والا نہیں۔",
  Add_Payee_Note:"پہلا <a routerLink='/folder/Payee/New'>ادا کرنے والا</a> شامل کریں۔",
  New_Payees:"نئے وصول کنندگان",
  About_The_Payment_Schedule:"ادائیگی کے شیڈول",
  Your_Payroll_Schedule:"خودکار پے رول",
  New_Payment:"نئی ادائیگی",
  Identifier:"شناخت کنندہ",
  Selected:"منتخب شدہ",
  Select:"منتخب کریں۔",
  Memo:"میمو",
  Payment_Date:"ادائیگی کی تاریخ",
  Mark_as_Paid:"بطور ادا شدہ نشان زد کریں۔",
  Select_Payments:"ادائیگیاں منتخب کریں۔",
  No_Payments:"کوئی ادائیگی نہیں",
  No_Payments_Word:"پہلا <a routerLink='/folder/Payment/New'>ادائیگی</a> بنائیں۔",
  Create_Payroll:"پے رول بنائیں",
  Properties:"پراپرٹیز",
  Payroll_Title:"پے رول کا عنوان",
  Payroll_Notes:"پے رول نوٹس",
  Payroll_Setup:"پے رول سیٹ اپ",
  Tabulate_Payments:"ادائیگیوں کو ٹیبلیٹ کریں۔",
  Tabulate:"ٹیبلیٹ",
  By:"بذریعہ:",
  Payments_By:"کی طرف سے ادائیگی",
  Timesheets_And_Schedules:"ٹائم شیٹس اور شیڈولز",
  Both:"دونوں",
  Items:"اشیاء",
  Add_Payees:"وصول کنندگان کو شامل کریں۔",
  Add_Account:"اکاؤنٹ کا اضافہ",
  Enable_Account:"اکاؤنٹ کو فعال کریں۔",
  Enable_Payroll:"پے رول کو فعال کریں۔",
  Print_Payroll:"پے رول پرنٹ کریں۔",
  No_Payrolls:"کوئی پے رول نہیں۔",
  No_Payroll_Word:"اپنا پہلا <a routerLink='/folder/Payroll/New'>پے رول</a> بنائیں۔",
  View_more:"مزید دیکھیں",
  Less:"کم",
  Add_Payroll:"پے رول شامل کریں۔",
  Select_Payroll:"پے رول کو منتخب کریں۔",
  About_Your_Salary:"آپ کی تنخواہ کے بارے میں",
  Add_Salaries:"تنخواہیں شامل کریں۔",
  Add_Salary:"تنخواہ شامل کریں۔",
  Salaries:"تنخواہیں",
  No_Salaries:"کوئی تنخواہ نہیں۔",
  No_Salaries_Word:"پہلا <a routerLink='/folder/Salary/New'>تنخواہ</a> شامل کریں۔",
  Select_Salaries:"تنخواہ منتخب کریں۔",
  New_Salary:"نئی تنخواہ",
  Salary_Name:"تنخواہ کا شناخت کنندہ",
  Enable_Salary:"تنخواہ کو فعال کریں۔",
  Salary_Amount:"تنخواہ کی رقم",
  New_Schedule:"نیا شیڈول",
  Schedule_Title:"شیڈول کا عنوان",
  Add_Address:"ایڈریس شامل کریں۔",
  Repeat:"دہرائیں۔",
  Design:"ڈیزائن",
  Edit_Design:"ڈیزائن میں ترمیم کریں۔",
  Edit_this_Design:"اس ڈیزائن میں ترمیم کریں۔",
  Repeat_Payment:"ادائیگی کو دہرائیں۔",
  Enable_Schedule:"شیڈول کو فعال کریں۔",
  Never:"کبھی نہیں",
  Select_Schedule:"شیڈولز کو منتخب کریں۔",
  No_Schedules:"کوئی شیڈول نہیں۔",
  No_Schedules_Word:"پہلا <a routerLink='/folder/Schedule/New'>شیڈیول</a> بنائیں۔",
  New_Administrator:"نیا ایڈمنسٹریٹر",
  Username:"صارف نام",
  First_Name:"پہلا نام",
  Last_Name:"آخری نام",
  Add_an_Address:"ایک پتہ شامل کریں۔",
  Payment_Limit:"فی ادائیگی کی حد",
  Total_Limit:"کل حد",
  Select_Accounts:"اکاؤنٹس کو منتخب کریں۔",
  No_Administrators:"کوئی ایڈمنسٹریٹر نہیں۔",
  No_Administrators_Word:"پہلا <a routerLink='/folder/Administrator/New'>ایڈمن اکاؤنٹ</a> بنائیں۔",
  New_Administrators_Word:"پہلا <a routerLink='/folder/Administrator/New'>ایڈمنسٹریٹر</a> شامل کریں",
  Cloud:"بادل",
  Backup:"بیک اپ",
  Enable_iCloud:"iCloud کو فعال کریں۔",
  Enable_Google_Drive:"گوگل ڈرائیو کو فعال کریں۔",
  Enable_Microsoft_OneDrive:"Microsoft OneDrive کو فعال کریں۔",
  Automatically_Backup:"خودکار طور پر بیک اپ",
  FTP_Settings:"ایف ٹی پی کی ترتیبات",
  URL_File_Prompt:"براہ کرم درآمد کرنے کے لیے .xls / .xlsx / .json فائل کے لیے مقام کی وضاحت کریں:",
  URL_SQL_Prompt:"براہ کرم درآمد کرنے کے لیے SQLite فائل کے مقام کی وضاحت کریں:",
  FTP_Backup:"ایف ٹی پی بیک اپ",
  FTP_Import:"ایف ٹی پی درآمد",
  FTP:"ایف ٹی پی",
  SFTP:"SFTP",
  Host:"میزبان",
  Port:"بندرگاہ",
  Path:"راستہ",
  Data_Path:"ڈیٹا پاتھ",
  Enable_FTP_Account:"ایف ٹی پی اکاؤنٹ کو فعال کریں۔",
  HostnameIP:"میزبان کا نام",
  Password:"پاس ورڈ",
  Connection_Port:"کنکشن پورٹ",
  Enable_MySQL_Database:"MySQL ڈیٹا بیس کو فعال کریں۔",
  Log:"لاگ",
  Reset:"دوبارہ ترتیب دیں۔",
  Erase:"مٹانا",
  Export:"برآمد کریں۔",
  Database:"ڈیٹا بیس",
  Upload_CSV:"CSV اپ لوڈ کریں۔",
  Download_CSV:"CSV ڈاؤن لوڈ کریں۔",
  SQL_Database:"ایس کیو ایل یو آر ایل",
  MySQL_Database:"مائی ایس کیو ایل",
  MySQL_Backup:"MySQL بیک اپ",
  Internal_Notes:"اندرونی نوٹس",
  Root_Path:"جڑ کا راستہ",
  Select_Backup:"بیک اپ کو منتخب کریں۔",
  Add_New_Backup:"ایک نیا بیک اپ شامل کریں۔",
  First_Backup:"پہلا بیک اپ ترتیب دیں...",
  Backups:"بیک اپس",
  Add_Backup:"بیک اپ شامل کریں۔",
  No_Backups:"تلاش کرنے کے لیے کوئی بیک اپ نہیں ہیں۔",
  Select_Backup_Type:"بیک اپ کی قسم منتخب کریں جسے آپ سیٹ اپ کرنا چاہتے ہیں...",
  Backup_Type:"بیک اپ کی قسم",
  FTP_Drive:"ایف ٹی پی ڈرائیو",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"ڈرائیو",
  Microsoft_OneDrive:"ڈرائیو",
  Import_Fields:"فیلڈز درآمد کریں۔",
  Import_Fields_Word:"<a routerLink='/folder/Holiday/New'>درآمد</a> ڈیٹا کو منتخب کرنے کے لیے اس سیکشن کا استعمال کریں۔",
  Upload:"اپ لوڈ کریں۔",
  Download:"ڈاؤن لوڈ کریں",
  Download_JSON:"JSON ڈیٹا کے بطور ڈاؤن لوڈ کریں۔",
  Download_SQL:"ایس کیو ایل فائل کے بطور ڈاؤن لوڈ کریں۔",
  New_Bank:"نیا بینک",
  New_Account:"نیا کھاتہ",
  New_Bank_Account:"نیا بینک اکاؤنٹ",
  Upload_Image:"تصویر انٹرنیٹ پر ڈالنا",
  Bank_Name:"بینک کا نام",
  Bank_Address:"بینک کا پتہ",
  Branch_Address:"برانچ کا پتہ",
  Address_on_Cheque:"پتہ",
  Cheque_Numbers:"نمبر چیک کریں۔",
  Cheque_Details:"تفصیلات چیک کریں۔",
  Bank_Logo:"بینک کا لوگو",
  Cheque_ID:"آئی ڈی چیک کریں۔",
  Starting_Cheque_ID:"ID چیک کرنا شروع کر رہا ہے۔",
  Transit_Number:"ٹرانزٹ نمبر",
  Institution_Number:"ادارہ نمبر",
  Designation_Number:"عہدہ نمبر",
  Account_Number:"اکاؤنٹ نمبر",
  Limits:"حدود",
  Default_Limits:"پہلے سے طے شدہ حدود",
  Over_Limit:"حد سے زیادہ",
  Under_Limit:"حد کے تحت",
  Payroll_Limit:"پے رول کی حد",
  Enable_Bank_Account:"بینک اکاؤنٹ کو فعال کریں۔",
  Select_Account:"اکاؤنٹ منتخب کریں۔",
  No_Bank_Account:"کوئی بینک اکاؤنٹ نہیں۔",
  No_Bank_Account_Word:"پہلا <a routerLink='/folder/Accounts/New'>بینک اکاؤنٹ</a> شامل کریں۔",
  Bank_Accounts:"بینک اکاؤنٹ",
  No_Accounts:"کوئی اکاؤنٹس نہیں۔",
  No_Accounts_Note:"پہلا <a routerLink='/folder/Accounts/New'>بینک اکاؤنٹ</a> شامل کریں۔",
  Cheque_Designer:"ڈیزائنر کو چیک کریں۔",
  Cheque_Design:"ڈیزائن چیک کریں۔",
  Select_Design:"ایک ڈیزائن منتخب کریں۔",
  Cheque_Designs:"ڈیزائن چیک کریں۔",
  No_Cheque_Designs:"کوئی چیک ڈیزائن نہیں۔",
  No_Cheque_Designs_Word:"اپنا <a routerLink='/folder/Settings/Cheque/Design/New'>ڈیزائن چیک کریں۔</a> بنائیں۔",
  Set_Default:"ڈیفالٹ کے طور پر مقرر",
  Default:"طے شدہ",
  Remove:"دور",
  Folder:"فولڈر",
  Edit:"ترمیم",
  LoadingDots:"لوڈ ہو رہا ہے...",
  Add_a_New_File:"اس میں ایک <a href='#' (click)='add()'>نئی فائل</a> شامل کریں",
  this_folder:"یہ فولڈر",
  FTP_Backup_Settings:"FTP بیک اپ کی ترتیبات",
  Secure_File_Transfer:"محفوظ فائل ٹرانسفر",
  New_Holiday:"نئی چھٹی",
  Add_Holiday:"تعطیلات شامل کریں۔",
  Holiday_Name:"چھٹیوں کا نام",
  Additional_Pay:"اضافی تنخواہ",
  Enable_Holiday:"چھٹیوں کو فعال کریں۔",
  Select_Holidays:"چھٹیاں منتخب کریں۔",
  No_Holidays:"کوئی چھٹیاں نہیں۔",
  No_Holidays_Word:"پہلا <a routerLink='/folder/Holiday/New'>عوامی چھٹی</a> شامل کریں۔",
  New_Import:"نئی درآمد",
  Import_Data:"ڈیٹا درآمد کریں۔",
  Import_Data_Word:"اپنی پسند کے مطابق فائل کی قسم یا اپ لوڈ کا طریقہ منتخب کریں۔<br /> معاون فائل اپ لوڈ کرنے کے بعد آپ فائل میں جو بھی درآمد شدہ فیلڈز ایپ کے کسی بھی پیرامیٹر سے مطابقت رکھتے ہیں اسے منتخب کر سکیں گے۔",
  Import_File:"فائل درآمد کریں۔",
  Link_To_File:"فائل سے لنک کریں۔",
  Select_File:"فائل کو منتخب کریں۔",
  New_Moderator:"نیا ناظم",
  Allow_Moderation:"اعتدال کی اجازت دیں۔",
  Create_Paycheques:"پے چیکس بنائیں",
  Edit_Paycheques_and_Data:"پے چیک اور ڈیٹا میں ترمیم کریں۔",
  Destroy_Data_and_Paycheques:"ڈیٹا اور پے چیک کو تباہ کریں۔",
  Bonus_Percentage:"پے چیک فیصد",
  Fixed_Amount:"مقررہ رقم",
  Select_Moderator:"ماڈریٹر کو منتخب کریں۔",
  No_Moderators:"کوئی ماڈریٹرز نہیں۔",
  Moderators:"ناظمین",
  Moderator_Account:"پہلا <a routerLink='/folder/Administrator/New'>ماڈریٹر اکاؤنٹ</a> بنائیں۔",
  No_Moderators_Word:"پہلا <a routerLink='/folder/Administrator/New'>ماڈریٹر</a> شامل کریں۔",
  Defaults:"ڈیفالٹس",
  Provide_Us_Info:"معلومات فراہم کریں۔",
  Setup_Your_Printer:"اپنا پرنٹر سیٹ اپ کریں۔",
  Your_Company:"آپ کی کمپنی کے بارے میں",
  Company_Name:"کمپنی کا نام",
  Currency:"کرنسی",
  Default_Currency:"ڈیفالٹ کرنسی",
  Base_Monthly_Income:"ماہانہ آمدنی",
  Protection:"تحفظ",
  App_Protection:"ایپ پروٹیکشن",
  PIN_Code_Protection:"پن کوڈ کا تحفظ",
  App_Protection_Word:"حفاظتی طریقوں کو فعال کریں جو آپ کے اکاؤنٹ کی حفاظت میں مدد کرتے ہیں۔",
  PIN_Code:"خفیہ نمبر",
  Change_PIN:"پن تبدیل کریں۔",
  New_Password:"نیا پاس ورڈ",
  Geofence_Protection:"جیو فینس پروٹیکشن",
  Geofence_Area:"ایریا سیٹ کریں۔",
  Distance:"فاصلے",
  Setup_Now:"ابھی سیٹ اپ کریں۔",
  Mile:"میل",
  Km:"کلومیٹر",
  m:"m",
  Facial_Recognition:"چہرے کی پہچان",
  Face:"چہرہ",
  Setup:"سیٹ اپ",
  Label:"لیبل",
  Password_Protection:"پاس ورڈ کا تحفظ",
  Modify_Password:"پاس ورڈ میں ترمیم کریں۔",
  New_Tax_Entry:"نئی ٹیکس انٹری",
  New_Tax:"نیا ٹیکس",
  Tax_Label:"ٹیکس لیبل",
  Perpetually_Enabled:"مستقل طور پر فعال",
  Select_Taxes:"ٹیکس منتخب کریں۔",
  Tax_Deductions:"ٹیکس کٹوتیاں",
  No_Tax_Deductions:"کوئی ٹیکس کٹوتیاں نہیں۔",
  No_Tax_Deductions_Word:"پہلی <a routerLink='/folder/Tax/New'>ٹیکس</a> کٹوتی شامل کریں۔",
  New_Timer:"نیا ٹائمر",
  Start:"شروع کریں۔",
  Stop:"رک جاؤ",
  Start_Timer:"ٹائمر شروع کریں۔",
  Stop_Timer:"ٹائمر بند کرو",
  Timer_Active:"ٹائمر ایکٹو",
  Timer:"ٹائمر:",
  Timer_Running:"ٹائمر: (چل رہا ہے)",
  Save_Timer:"ٹائمر کو محفوظ کریں۔",
  New_Timesheet:"نئی ٹائم شیٹ",
  Select_Timesheets:"ٹائم شیٹس کو منتخب کریں۔",
  Work_Notes:"کام کے نوٹس",
  Entry_Title:"اندراج کا عنوان",
  No_Timesheets:"کوئی ٹائم شیٹس نہیں۔",
  No_Timesheets_Word:"پہلا <a routerLink='/folder/Timesheet/New'>ٹائم شیٹ</a> شامل کریں۔",
  Timesheet_Submitted:"ٹائم شیٹ جمع کرائی گئی۔",
  Timesheet_Congrats:"مبارک ہو! آپ کی ٹائم شیٹ کامیابی کے ساتھ جمع کر دی گئی ہے۔ شکریہ!",
  Timesheet_Copy:"اپنے دستاویزات کی کاپی حاصل کرنے کے لیے براہ کرم ہمیں اپنا ای میل پتہ اور/یا موبائل فون نمبر فراہم کریں۔",
  Submit:"جمع کرائیں",
  Allow_Notifications:"اطلاعات کی اجازت دیں۔",
  Untitled_Entry:"نیا اندراج",
  Untitled_Bank:"بلا عنوان بینک",
  Timesheet_Entry:"ٹائم شیٹ انٹری",
  Work_Description:"کام کی تفصیل",
  Enable_Timesheet:"ٹائم شیٹ کو فعال کریں۔",
  Submit_Timesheet:"ٹائم شیٹ جمع کروائیں۔",
  Vacation:"چھٹی",
  New_Vacation:"نئی چھٹی",
  Vacation_Name:"چھٹی کا نام",
  Paid_Vacation:"ادا شدہ چھٹی",
  Vacation_Pay:"تعطیل کی تنخواہ",
  Enable_Vacation:"تعطیل کو فعال کریں۔",
  Select_Vacations:"چھٹیاں منتخب کریں۔",
  No_Vacations:"کوئی تعطیلات نہیں۔",
  No_Vacations_Word:"پہلی <a routerLink='/folder/Vacation/New'>تعطیل</a> اندراج بنائیں۔",
  Payroll_Schedule:"پے رول کا شیڈول",
  Next_Payroll:"اگلا پے رول:",
  Upcoming_Payroll:"آنے والا پے رول",
  No_Upcoming_Payroll:"کوئی آنے والا پے رول نہیں۔",
  Address_Book:"ایڈریس بک",
  Archived_Documents:"محفوظ شدہ دستاویزات",
  Dev_Mode:"ڈیولپمنٹ موڈ میں درخواست",
  Administrators:"منتظمین",
  Privacy:"رازداری",
  None:"کوئی نہیں۔",
  Select_Signature:"دستخط منتخب کریں۔",
  No_Signatures:"کوئی دستخط نہیں۔",
  No_Signatures_Word:"پہلا <a routerLink='/folder/Signature/New'>دستخط</a> شامل کریں۔",
  Repeat_Indefinitely:"غیر معینہ مدت تک دہرائیں۔",
  Sign:"دستخط",
  Sign_Here:"یہاں دستخط کریں۔",
  Date_Signed:"دستخط کی تاریخ",
  Signature_Pad:"دستخطی پیڈ",
  Account_Holder:"حساب کتاب کا مالک",
  Account_Properties:"اکاؤنٹ کی خصوصیات",
  Name_On_Cheque:"چیک پر نام",
  Server_Hostname:"سرور میزبان نام / آئی پی",
  Printers:"پرنٹرز",
  No_Printers:"کوئی پرنٹرز نہیں۔",
  Printer_Exists:"اس نام کا پرنٹر پہلے سے موجود ہے۔",
  No_Printers_Word:"سب سے پہلے <a routerLink='/folder/Printer/New'>پرنٹر</a> شامل کریں۔",
  No_Printer_Alert:"کوئی پرنٹر متعین نہیں ہے۔ کیا آپ پرنٹر سیٹ اپ کرنا چاہیں گے؟",
  Add_Printer:"پرنٹر شامل کریں۔",
  New_Printer:"نیا پرنٹر",
  Printer_Hostname:"پرنٹر میزبان کا نام / آئی پی",
  Printer_Label:"پرنٹر لیبل",
  Printer_Protocol:"پرنٹر پروٹوکول",
  Protocol:"پروٹوکول",
  Email_Print:"ای میل",
  AirPrint:"ایئر پرنٹ",
  IPP:"آئی پی پی",
  LPD:"ایل پی ڈی",
  HPJetDirect:"ساکٹ",
  Print_Job:"پرنٹ جاب",
  Printed:"مطبوعہ",
  Not_Printed:"مطبوعہ نہیں۔",
  Print_Jobs:"پرنٹ جابز",
  Print_Queue:"پرنٹ قطار",
  No_Print_Jobs:"کوئی پرنٹ جاب نہیں۔",
  No_Prints:"پرنٹ کرنے کے لیے ایک نیا <a routerLink='/folder/Cheque/New'>چیک کریں۔</a> بنائیں۔",
  Prints:"پرنٹس",
  Find_Printer:"پرنٹر تلاش کریں۔",
  Find_AirPrint_Devices:"ایئر پرنٹ ڈیوائسز تلاش کریں۔",
  Select_Printer:"پرنٹر کو منتخب کریں۔",
  System_UI:"سسٹم UI",
  Printer:"پرنٹر",
  Status:"حالت",
  Preview:"پیش نظارہ",
  Enable_Print_Job:"پرنٹ جاب کو فعال کریں۔",
  Queue_Weight:"قطار کا وزن",
  Unlimited:"لا محدود",
  Show_Advanced_Printer_Options:"اعلی درجے کے پرنٹر کے اختیارات دکھائیں۔",
  Advanced:"اعلی درجے کی",
  Location:"مقام",
  Note:"نوٹ",
  Queue_Name:"قطار کا نام",
  Pages_Printed_Limit:"صفحات کی طباعت کی حد",
  MultiPage_Idle_Time:"متعدد صفحات پر مشتمل انتظار کا وقت",
  Page_Count_Limit:"صفحہ شمار کی حد",
  Page_Orientation:"صفحہ کی سمت بندی",
  Portrait:"پورٹریٹ",
  Landscape:"زمین کی تزئین",
  Duplex:"ڈوپلیکس",
  Double_Sided_Printing:"دو طرفہ",
  Duplex_Mode:"ڈوپلیکس موڈ",
  Duplex_Short:"مختصر",
  Duplex_Long:"لمبی",
  Duplex_None:"کوئی نہیں۔",
  Color_And_Quality:"رنگ اور معیار",
  Monochrome:"مونوکروم",
  Photo_Quality_Prints:"فوٹو کوالٹی پرنٹس",
  Printer_Email:"پرنٹر ای میل",
  Automatically_Downsize:"خودکار طور پر سائز کم کریں۔",
  Paper:"کاغذ",
  Paper_Name:"کاغذ کا نام",
  Paper_Width:"کاغذ کی چوڑائی",
  Paper_Height:"کاغذ کی اونچائی",
  Paper_Autocut_Length:"کاغذ آٹو کٹ لمبائی",
  Margins:"حاشیہ",
  Page_Margins:"صفحہ حاشیہ",
  Page_Margin_Top:"ٹاپ پیج مارجن",
  Page_Margin_Right:"دائیں صفحہ کا مارجن",
  Page_Margin_Bottom:"نیچے صفحہ مارجن",
  Page_Margin_Left:"بائیں صفحہ کا مارجن",
  Add_Employees:"ملازمین کو شامل کریں۔",
  Header:"ہیڈر",
  Print_A_Page_Header:"صفحہ کا ہیڈر پرنٹ کریں۔",
  Header_Label:"ہیڈر لیبل",
  Header_Page_Index:"ہیڈر پیج انڈیکس",
  Header_Font_Name:"ہیڈر فونٹ",
  Select_Font:"فونٹ منتخب کریں۔",
  Font_Selector:"فونٹ سلیکٹر",
  Header_Font_Size:"ہیڈر فونٹ",
  Header_Bold:"ہیڈر بولڈ",
  Header_Italic:"ہیڈر اٹالک",
  Header_Alignment:"ہیڈر کی سیدھ",
  Left:"بائیں",
  Center:"مرکز",
  Right:"ٹھیک ہے۔",
  Justified:"جائز",
  Header_Font_Color:"ہیڈر کا رنگ",
  Select_Color:"رنگ منتخب کریں۔",
  Footer:"فوٹر",
  Print_A_Page_Footer:"صفحہ کا فوٹر پرنٹ کریں۔",
  Footer_Label:"فوٹر لیبل",
  Footer_Page_Index:"فوٹر پیج انڈیکس",
  Footer_Font_Name:"فوٹر فونٹ",
  Fonts:"فونٹس",
  Done:"ہو گیا",
  Select_Fonts:"فونٹس کو منتخب کریں۔",
  Footer_Font_Size:"فوٹر کا سائز",
  Footer_Bold:"فوٹر بولڈ",
  Footer_Italic:"فوٹر اٹالک",
  Footer_Alignment:"فوٹر کی سیدھ",
  Footer_Font_Color:"فوٹر کا رنگ",
  Page_Copies:"صفحہ کاپیاں",
  Enable_Printer:"پرنٹر کو فعال کریں۔",
  Remote_Logging:"ریموٹ لاگنگ",
  Log_Server:"لاگ سرور",
  Encryption:"خفیہ کاری",
  Random_Key:"بے ترتیب کلید",
  Encryption_Key:"خفیہ کاری کی کلید",
  Cheques_Webserver:"حسب ضرورت ڈیٹا بیس",
  Learn_How:"جانیں کیسے",
  Signature:"دستخط",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"دستخط دیکھیں",
  Printed_Signature:"مطبوعہ دستخط",
  Digitally_Sign:"ڈیجیٹل سائن کریں۔",
  Digital_Signature:"ڈیجیٹل دستخط",
  Add_Signature:"دستخط شامل کریں۔",
  Add_Your_Signature:"اپنے دستخط شامل کریں۔",
  Enable_Signature:"دستخط کو فعال کریں۔",
  Digitally_Signed:"ڈیجیٹل طور پر دستخط شدہ",
  Insert_Error:"ڈیٹا بیس کے مسائل کی وجہ سے چیک محفوظ کرنے سے قاصر ہے۔",
  Delete_Confirmation:"کیا آپ واقعی اس معلومات کو حذف کرنا چاہتے ہیں؟",
  Discard_Confirmation:"کیا آپ واقعی اس معلومات کو ضائع کرنا چاہتے ہیں؟",
  Discard_Bank_Confirmation:"کیا آپ واقعی اس اکاؤنٹ کو مسترد کرنا چاہتے ہیں؟",
  Discard_Printer_Confirmation:"کیا آپ واقعی اس پرنٹر کو ضائع کرنا چاہتے ہیں؟",
  Delete_Bonus_Confirmation:"کیا آپ واقعی اس بونس کو حذف کرنا چاہتے ہیں؟",
  Delete_Invoice_Confirmation:"کیا آپ واقعی اس رسید کو حذف کرنا چاہتے ہیں؟",
  Generated_Cheque:"تیار شدہ چیک",
  Generated_Invoice:"تیار کردہ رسید",
  Schedule_Start_Time:"شیڈول شروع کریں۔",
  Schedule_End_Time:"شیڈول کا اختتام",
  New_Call:"نئی کال",
  No_Contacts:"کوئی رابطے نہیں۔",
  No_Contacts_Word:"منتظمین، ناظمین، ملازمین، اور وصول کنندگان رابطے کے طور پر ظاہر ہوتے ہیں۔",
  PDF_Subject:"مالیات",
  PDF_Keywords:"پے رول پے چیک پی ڈی ایف چیک چیک",
  Printer_Setup:"پرنٹر سیٹ اپ",
  Printer_Selection:"پرنٹر کا انتخاب",
  New_Fax:"نیا فیکس",
  New_Fax_Message:"نیا فیکس پیغام",
  Fax_Machine:"فیکس مشین",
  Fax_Name:"فیکس کا نام",
  Fax_Email:"فیکس ای میل",
  Fax_Number:"فیکس نمبر",
  Contents:"مشمولات",
  Fax_Body:"صفحہ باڈی",
  Header_Word:"ہیڈر:",
  Header_Text:"ہیڈر کا متن",
  Include_Header:"ہیڈر شامل کریں۔",
  Include_Footer:"فوٹر شامل کریں۔",
  Footer_Word:"فوٹر:",
  Footer_Text:"فوٹر ٹیکسٹ",
  Attach_a_Cheque:"ایک چیک منسلک کریں۔",
  Add_Deduction:"کٹوتی شامل کریں۔",
  Enable_Fax:"فیکس بھیجیں۔",
  Select_Fax:"فیکس منتخب کریں۔",
  No_Faxes:"کوئی فیکس نہیں۔",
  Faxes:"فیکس",
  Save_and_Send:"فیکس بھیجیں۔",
  Save_and_Pay:"محفوظ کریں اور ادائیگی کریں۔",
  WARNING:"انتباہ:",
  Remember:"یاد رکھیں",
  Printing:"پرنٹنگ",
  Printing_Complete:"پرنٹنگ مکمل!\n\n",
  of:"کی",
  There_Were:"وہاں تھے",
  Successful_Prints:"کامیاب پرنٹس اور",
  Unsuccessful_Prints:" ناکام پرنٹس۔",
  PrinterError:"معذرت! ایک خرابی تھی۔",
  Printing_:"پرنٹنگ...",
  PrinterSuccess:"دستاویز کامیابی کے ساتھ پرنٹ ہو گئی۔",
  PrintersSuccess:"دستاویزات کامیابی سے پرنٹ ہو گئیں۔",
  New_Message:"نیا پیغام",
  New_Messages:"نئے پیغامات",
  Read_Message:"پیغام پڑھیں",
  Write_Message:"پیغام لکھیں۔",
  Send_Message:"پیغام بھیجیں",
  Subject:"مضمون",
  Message:"پیغام",
  Writing:"تحریر...",
  Send:"بھیجیں",
  Set_Date:"تاریخ مقرر کریں۔",
  Set_Time:"وقت ٹھیک کرنا",
  Recieve:"وصول کریں۔",
  Set_as_Default:"ڈیفالٹ کے طور پر مقرر",
  Default_Account:"ڈیفالٹ اکاؤنٹ",
  Default_Design:"پہلے سے طے شدہ ڈیزائن",
  Multiple_Cheques:"تین چیک",
  Account_Mode:"اکاؤنٹ موڈ",
  Multiple_Cheques_Description:"فی صفحہ تین چیک۔",
  Check_and_Table:"چیک اینڈ ٹیبل",
  Check_including_Table:"اکاؤنٹنگ ٹیبل کے ساتھ چیک کریں۔",
  Check_Mailer:"میلر کو چیک کریں۔",
  Check_Mailer_Window:"ایڈریس ونڈو سے چیک کریں۔",
  DocuGard_Table_Top:"بڑی چیک اینڈ ٹیبل",
  DocuGard_Table_Middle:"بڑا چیک اینڈ ٹیبل (درمیانی)",
  DocuGard_Table_Bottom:"بڑا چیک اینڈ ٹیبل (نیچے)",
  DocuGard_Mailer_Top:"بڑا چیک میلر",
  DocuGard_Mailer_Middle:"بڑا چیک میلر (درمیانی)",
  DocuGard_Mailer_Bottom:"بڑا چیک میلر (نیچے)",
  DocuGard_Three_Cheques:"تین بڑے چیک",
  DocuGard_Cheque_Top:"سنگل بڑا چیک",
  DocuGard_Cheque_Middle:"بڑا چیک (درمیانی)",
  DocuGard_Cheque_Bottom:"بڑا چیک (نیچے)",
  DocuGard_Three_Cheques_Window:"ایک صفحے پر تین چیک۔",
  DocuGard_Table_Top_Window:"چیک اور آمدنی کی میز۔",
  DocuGard_Table_Middle_Window:"چیک اور آمدنی کی میز۔",
  DocuGard_Table_Bottom_Window:"چیک اور آمدنی کی میز۔",
  DocuGard_Mailer_Top_Window:"چیک، ٹیبل، اور پتہ۔",
  DocuGard_Mailer_Middle_Window:"چیک، ٹیبل، اور پتہ۔",
  DocuGard_Mailer_Bottom_Window:"چیک، ٹیبل، اور پتہ۔",
  DocuGard_Cheque_Top_Window:"محفوظ کاغذ کی جانچ کریں۔",
  DocuGard_Cheque_Middle_Window:"محفوظ کاغذ کی جانچ کریں۔",
  DocuGard_Cheque_Bottom_Window:"محفوظ کاغذ کی جانچ کریں۔",
  Basic_Cheque:"سنگل چیک",
  Basic_Cheque_Print:"ایک ہی چیک پرنٹ کریں۔",
  Error_Setting_As_Paid:"ادائیگی کے طور پر ترتیب دینے میں خرابی۔",
  Add_Attachment:"اٹیچمنٹ شامل کریں۔",
  PrinterUnavailable:"پرنٹر دستیاب نہیں ہے۔",
  CreditCardComponent:"کارڈز",
  PaypalComponent:"پے پال",
  InteracComponent:"انٹراک",
  BitcoinComponent:"بٹ کوائن",
  New_Deposit:"نیا ڈپازٹ",
  Deposits:"جمع",
  No_Deposits:"کوئی ڈپازٹ نہیں۔",
  Credit_Card_Deposit:"کریڈٹ کارڈ",
  New_Credit_Card_Deposit_Message:"کریڈٹ کارڈ ڈپازٹ",
  New_BitCoin_Deposit:"بٹ کوائن",
  New_BitCoin_Deposit_Message:"بٹ کوائن ڈپازٹ",
  New_Interac_Deposit:"انٹراک",
  New_Interac_Deposit_Message:"انٹراک ٹرانسفر",
  Payments_Limit:"ادائیگی کی حد",
  No_Payment_Limit:"ادائیگی کی کوئی حد نہیں۔",
  PayPal_Deposit:"پے پال",
  PayPal_Deposit_Message:"پے پال ڈپازٹ",
  No_Deposits_Word:"پہلی کمپنی <a routerLink='/folder/Deposit/New'>ڈپازٹ</a> شامل کریں۔",
  No_Documents_Specified:"پرنٹنگ کے لیے کوئی دستاویز نہیں بتائی گئی۔",
  No_Printers_Added:"کوئی پرنٹرز نہیں ملے۔ ایک شامل کرنے کے لیے ترتیبات > پرنٹرز پر جائیں۔",
  DB_Erase_Prompt:"پورے ڈیٹا بیس کو مکمل طور پر مٹا دیں؟ انتباہ: آپ تمام ذخیرہ شدہ معلومات کھو دیں گے!",
  Console_Warning:"اس کنسول میں کوئی متن نہ چسپاں کریں۔ آپ اپنے آپ کو اور/یا اپنی کمپنی کو سنگین خطرے میں ڈال سکتے ہیں۔",
  No_Faxes_Word:"پہلا <a routerLink='/folder/Fax/New'>فیکس</a> بنائیں۔",
  Cheque_Delete_Confirmation:"کیا آپ واقعی اس چیک کو حذف کرنا چاہتے ہیں؟",
  Design_Delete_Confirmation:"کیا آپ واقعی اس ڈیزائن کو حذف کرنا چاہتے ہیں؟",
  Cheque_Pay_Confirmation:"اس چیک کو بطور ادا شدہ نشان زد کریں؟ یہ پرنٹ کی قطار میں ظاہر نہیں ہوگا۔",
  Payment_Pay_Confirmation:"اس ادائیگی کو بطور ادا شدہ نشان زد کریں؟ یہ چیک کی قطار میں ظاہر نہیں ہوگا۔",
  Delete_Deduction_Confirmation:"کیا آپ واقعی اس کٹوتی کو حذف کرنا چاہتے ہیں؟",
  Delete_Job_Confirmation:"کیا آپ واقعی اس جاب کو حذف کرنا چاہتے ہیں؟",
  Delete_Timesheet_Confirmation:"کیا آپ واقعی اس ٹائم شیٹ کو حذف کرنا چاہتے ہیں؟",
  Delete_Schedule_Confirmation:"کیا آپ واقعی اس شیڈول کو حذف کرنا چاہتے ہیں؟",
  Delete_Setting_Confirmation:"کیا آپ واقعی اس ترتیب کو دوبارہ ترتیب دینا چاہتے ہیں؟",
  Delete_Fax_Confirmation:"کیا آپ واقعی اس فیکس کو حذف کرنا چاہتے ہیں؟",
  Delete_File_Confirmation:"کیا آپ واقعی اس فائل کو حذف کرنا چاہتے ہیں؟",
  Delete_Vacation_Confirmation:"کیا آپ واقعی اس چھٹی کو حذف کرنا چاہتے ہیں؟",
  Delete_Printer_Confirmation:"کیا آپ واقعی اس پرنٹر کو حذف کرنا چاہتے ہیں؟",
  Remove_Design_Confirmation:"کیا آپ واقعی اس ڈیزائن کو حذف کرنا چاہتے ہیں؟",
  Delete_Payroll_Confirmation:"کیا آپ واقعی اس پے رول کو حذف کرنا چاہتے ہیں؟",
  Send_Fax_Email_Confirmation:"کیا آپ ان دستاویزات کو فیکس اور ای میل کرنا چاہتے ہیں؟",
  Send_Email_Confirmation:"کیا آپ اس دستاویز کو ای میل کرنا چاہتے ہیں؟",
  Send_Fax_Confirmation:"کیا آپ اس دستاویز کو فیکس کرنا چاہتے ہیں؟",
  Error_Generating_PDF:"معذرت اس دستاویز کو بنانے میں ایک خامی تھی۔",
  PDF_Error_Saving_Image:"معذرت اس دستاویز کی پی ڈی ایف تصویر محفوظ کرنے میں ایک خرابی تھی۔",
  Test_Printer_Confirmation:"کیا آپ اس پرنٹر پر ٹیسٹ صفحہ پرنٹ کرنا چاہتے ہیں؟",
  Save_Timesheet_Prompt:"براہ کرم ایک 'عنوان' شامل کریں یا محفوظ کرنے کے لیے 'اسٹارٹ ٹائمر' دبائیں۔",
  Remove_Geofence_Prompt:"کیا آپ واقعی اس جیو فینس کے مقام کو ہٹانا چاہتے ہیں؟",
  Delete_Employee_Confirmation:"کیا آپ واقعی حذف کرنا چاہتے ہیں۔",
  Fire_Employee_Confirmation:"کیا آپ واقعی فائر کرنا چاہتے ہیں؟"
}