import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebService } from './services/web.service'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Cheques-Webapp';
  showFiller = false;
  show_login = false;
  constructor(private router: Router, private web: WebService) {
  }
  go(l?) {
    this.router.navigate([l]);
  }
  timesheets() {
    window.location.href ='http://cheques.janglehost.com/timesheets/';
  }
  login() {
    // this.show_login = true;
    window.location.href ='http://cheques.janglehost.com/api/login';
  }
  ngOnInit() {
    this.web.localize();
  }
}
