export const Hr = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Autorska prava &copy; 2023",
  black:"Crno",
  green:"zelena",
  gold:"Zlato",
  blue:"Plava",
  brown:"Smeđa",
  purple:"Ljubičasta",
  pink:"Ružičasta",
  red:"Crvena",
  Swatches:"Swatchevi",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Nijansa",
  Saturation:"Zasićenost",
  Lightness:"Lakoća",
  Upgrade_To_Pro:"Nadogradi na pro",
  AllFeaturesInclude:"Sve značajke uključuju:",
  PrintUnlimitedCheques:"Ispisujte neograničene čekove",
  PremiumChequeDesigns:"Vrhunski čekovi",
  ManageUnlimitedEmployees:"Upravljajte neograničenim brojem zaposlenika",
  AddUnlimitedPayees:"Dodajte neograničeni broj primatelja",
  CreateUnlimitedPayrolls:"Kreirajte neograničene plaće",
  UnlimitedSchedulesandTimeSheets:"Neograničeni rasporedi i tablice radnog vremena",
  BulkPayPalPayouts:"Skupne PayPal isplate",
  UnlimitedBankAccounts:"Neograničeni bankovni računi",
  ManageMultipleCompanies:"Upravljajte s više tvrtki",
  TrackInsurancePolicies:"Pratite police osiguranja",
  Bio_MetricProtection:"Biometrijska zaštita",
  Geo_FenceLock_OutProtection:"Geo-Fence Lock-Out Zaštita",
  Multiple_Companies_Word:"Upravljanje više tvrtki nije dostupno bez premije za Čekove.",
  PayPal_Payouts_Word:"PayPal plaćanja su onemogućena bez premije za čekove.",
  PINProtection:"PIN zaštita",
  PasswordProtection:"Zaštita lozinkom",
  May_Require_Approval:"Može zahtijevati odobrenje.",
  Subscribe:"Pretplatite se",
  Billed:"Naplaćeno",
  Up:"Gore",
  Down:"dolje",
  Positioning:"Pozicioniranje",
  Marker:"Marker",
  Drag_Marker:"Povucite marker",
  Tagline:"Ispis čekova i tabeliranje plaća",
  Marker_Word:"Koristite markere za veličinu elementa.",
  Pinch_Zoom:"Zumiranje prstima",
  Pinch_Word:"Stisnite prste da biste zumirali element.",
  Drag:"Opterećenje",
  Drag_Word:"Koristite prst za povlačenje elemenata.",
  subscription_alias_word:"Pretplata s automatskim obnavljanjem",
  premium_alias_word:"Paket jednokratne nadogradnje",
  print_alias_word:"Ispis pojedinačnih čekova",
  mode_alias_word:"Način rada",
  Pro:"profesionalac",
  Pro_word:"Potrebna je Pro verzija.",
  Cant_Delete_Default_Company_Word:"Nažalost, ne možete izbrisati svoju zadanu tvrtku.",
  Reinsert_Default_Designs:"Ponovno umetnite zadane dizajne",
  Reinsert_Default_Designs_word:"Želite li ponovno umetnuti zadane dizajne?",
  Subscription_Active_Disable_Word:"Ova pretplata je aktivna. Želite li otkazati ovu pretplatu na Čekove?",
  Company_Logo:"Logo tvrtke",
  ZERO_:"NULA",
  Disclaimer:"Odricanje",
  Privacy_Policy:"Politika privatnosti",
  EULA:"Provjerava EULA",
  Terms_Of_Service:"Uvjeti pružanja usluge",
  Terms_Of_Use:"Uvjeti korištenja",
  Refunds:"Politika povrata novca",
  Single_Print:"1 Provjerite",
  Two_Prints:"2 Provjere",
  Five_Prints:"5 Provjere",
  Ten_Prints:"10 Čekovi",
  Fifteen_Prints:"15 Čekovi",
  Twenty_Prints:"20 Čekovi",
  Thirty_Prints:"30 Čekovi",
  Image_Added:"Slika dodana",
  Image_Preview:"Pregled slike",
  No_Image_Was_Selected:"Nijedna slika nije odabrana.",
  Cheques_Unlimited:"Čekovi neograničeni",
  _Subscription:"Pretplata",
  Subscription:"Čekovi - 1 mjesec",
  Two_Month_Subscription:"Čekovi - 2 mjeseca",
  Three_Month_Subscription:"Čekovi - 3 mjeseca",
  Six_Month_Subscription:"Čekovi - 6 mjeseci",
  Twelve_Month_Subscription:"Čekovi - 12 mjeseci",
  Cheques_Are_Available:"Čekovi su dostupni za ispis.",
  Upgrade_Required_Two:"Odaberite paket i dvaput dodirnite gumb cijene kako biste započeli kupnju. Ispis čekova u punoj boji profesionalnog izgleda za nekoliko sekundi.",
  Month:"Mjesec",
  Due:"Rok:",
  Expires:"Ističe:",
  Subscription_Active:"Pretplata aktivna",
  Subscription_Inactive:"Pretplata nije aktivna",
  Purchase_Additional_Cheques:"Kupiti dodatne čekove?",
  Printable_Cheque:"Ček za ispis",
  Printable_Cheques:"Čekovi za ispis",
  Printable_Cheque_Word:"ček je dostupan na vašem računu.",
  Printable_Cheques_Word:"čekovi su dostupni na vašem računu.",
  Max_Amount_Message:"Iznos koji ste naveli premašio je maksimalni iznos postavljen za ovaj sustav:",
  Terms_Required_Word:"Prije nastavka korištenja čekova morate pristati na ovaj ugovor.",
  Subscriptions:"Pretplate",
  Product_Upgrades:"Nadogradnje",
  Mailed_Out_Cheques:"Čekovi poslani poštom",
  Enter_A_Company_Name:"Unesite naziv tvrtke.",
  Single_Cheques:"Pojedinačne provjere",
  Cheque_Golden:"Zlatni ček",
  Cheque_Golden_Window:"Zlatni karirani dizajn.",
  Cheque_Green:"Zeleni ček",
  Cheque_Green_Window:"Zeleni karirani dizajn.",
  Cheque_Red:"Crveni ček",
  Cheque_Red_Window:"Crveni karirani dizajn.",
  Cheque_Yellow:"Žuti ček",
  Cheque_Yellow_Window:"Žuti karirani dizajn.",
  Cheque_Statue_of_Liberty:"Kip slobode",
  Cheque_Statue_of_Liberty_Window:"Dizajn karata Kipa slobode.",
  Cheque_Green_Wave:"Zeleni val",
  Cheque_Green_Wave_Window:"Zeleni karirani dizajn.",
  Cheque_Golden_Weave:"Zlatno tkanje",
  Cheque_Golden_Weave_Window:"Elegantan zlatni karirani dizajn.",
  Cheque_Green_Sun:"Zeleno Sunce",
  Cheque_Green_Sun_Window:"Duboki i umirujući karo dizajn.",
  Cheque_Blue_Checkers:"Plave kockice",
  Cheque_Blue_Checkers_Window:"Plavi karirani dizajn.",
  Cashiers_Check:"Blagajnički ček",
  Cashiers_Check_Window:"Predložak stila blagajničkog čeka.",
  Cheque_Aqua_Checkers:"Aqua dame",
  Cheque_Aqua_Checkers_Window:"Aqua check dizajn.",
  Cheque_Golden_Checkers:"Zlatne dame",
  Cheque_Golden_Checkers_Window:"Zlatni karirani dizajn.",
  Upgrade_Unavailable:"Nadogradnje nisu dostupne",
  Bank_Code_Protection:"Zaštita bankovnog broja",
  Bank_Code_Protection_Word:"Zaštitite svoje bankovne brojeve od korištenja u ovoj aplikaciji koja radi na drugom uređaju ili za drugog korisnika. Ova radnja je NEOPOZIVA. Nastaviti?",
  Bank_Code_Protection_Blocked_Word:"Brojevi banke koje pokušavate koristiti rezervirani su za drugog korisnika ili uređaj.",
  Bank_Code_Protection_Error_Word:"Provjera broja nije uspjela. Povežite se s internetom i pokušajte ponovno dodati ovaj bankovni račun.",
  Bank_Code_Protection_Set_Error_Word:"Zaštita bankovnog broja zahtijeva da budete povezani na internet. Povežite se i pokušajte ponovno.",
  Upgrade_Unavailable_Word:"Nažalost, imamo problema s vašom provjerom. Pretplate i nadogradnje na Čekove trenutno nisu dostupne za kupnju u vašem području.",
  PayPal_Payment_Preview:"PayPal pregled plaćanja",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Odaberite PayPal",
  PayPal_Applications:"PayPal aplikacije",
  Purchase_With_Apple_Pay:"Kupujte s Apple Payom",
  Google_Pay:"Google Pay",
  Companies:"Tvrtke",
  Insurance:"Osiguranje",
  New_PayPal:"Novi PayPal",
  Pay_By:"Plaćanje",
  Insure:"Osigurati",
  Miles:"Miles",
  Payment_Method:"Način plaćanja",
  Select_Policies:"Odaberite Pravila",
  Policies:"Politike",
  Policy:"Politika",
  No_PayPal_Account:"Nema PayPal računa",
  No_Policies:"Nema polica osiguranja",
  New_Policy:"Nova politika",
  PayPal_Payment:"PayPal plaćanje",
  PayPal_Payments:"PayPal plaćanja",
  No_Payment_Selected:"Nije odabrano plaćanje",
  Sending_Payment_Word:"Molimo pričekajte... Ovo plaćanje se šalje.",
  Sending_Payments_Word:"Molimo pričekajte... Uplate se šalju.",
  No_Payment_Selected_PayPal:"Nije odabrano <a routerLink='/folder/Payments'>PayPal plaćanje</a> za slanje.",
  Payment_Sent:"Plaćanje poslano",
  PayPal_Payment_Sent:"Ova uplata je poslana putem PayPala.",
  Copay:"Copay",
  Dead:"Mrtav",
  Alive:"Živ",
  Not_Dead:"Nije mrtav",
  Unclaimed:"Nezahtijevan",
  Attempted:"Pokušao",
  Deceased:"Pokojnik",
  Claimed:"Traženo",
  Unpaid:"Neplaćeno",
  Sending_Payment:"Slanje uplate",
  Sending_Payments:"Slanje uplata",
  Send_PayPal_Confirmation:"Želite li ovu transakciju poslati putem PayPala?",
  Send_PayPal_Confirmation_Word:"Pritisnite zeleni gumb za slanje ove transakcije.",
  Save_Payment_As_Unpaid:"Spremiti ovu uplatu kao neplaćenu?",
  Payment_Pay_Confirmation_PayPal:"Spremiti ovu uplatu kao plaćenu?",
  No_Policies_Word:"dodajte prvu <a routerLink='/folder/Postage/New'>policu osiguranja</a>.",
  Timesheet_Multiple_Delete_Confirmation:"Jeste li sigurni da želite izbrisati više vremenskih tablica?",
  Select_Multiple_Timesheets_Prompt:"Nema odabranih vremenskih tablica. Odaberite barem jedan vremenski list.",
  Select_Multiple_Policies_Prompt:"Nema odabranih pravila. Odaberite barem jednu policu osiguranja.",
  Policies_Multiple_Delete_Confirmation:"Jeste li sigurni da želite izbrisati više pravila?",
  Company:"Društvo",
  Add_Company:"Dodaj tvrtku",
  New_Company:"Dodaj tvrtku",
  No_Companies:"Nema tvrtki",
  Enable_Company:"Omogući tvrtku",
  Select_Company:"Odaberite Tvrtka",
  The_Default_Company:"Zadana oznaka tvrtke.",
  Manage_Companies:"Upravljanje tvrtkama",
  No_Companies_Word:"Čekovi će koristiti zadanu tvrtku.<br />Dodajte <a routerLink='/folder/Company/New'>prvu tvrtku</a>.",
  Default_Company:"Zadana tvrtka",
  Cheques_Unlimited_Word:"Checks Unlimited vam omogućuje ispis onoliko čekova koliko želite.",
  Cheques_Subscription_Word:"Pretplata na čekove omogućuje vam ispis koliko god želite čekova.",
  You_Own_This_Product:"Vi posjedujete ovaj proizvod.",
  Your_Subscription_is_Active:"Vaša pretplata je aktivna.",
  Active_Products:"Aktivirani proizvodi",
  Purchase_Confirmation:"Kupiti",
  Purchase_Cheques:"Čekovi za kupnju",
  Restore_Purchase:"Obnovi kupnje",
  Erase_Purchase:"Izbriši kupnje",
  Successfully_Purchased:"Uspješno kupljeno",
  Enter_Your_Licence_Key:"Unesite svoj licencni ključ na ovu stranicu kako biste aktivirali ovaj proizvod.",
  Licence_Key:"Licencni ključ",
  Enter_Licence_Key:"Unesite ključ licence",
  Purchased:"Kupljeno",
  Enable_Feature:"Omogući značajku",
  Cancel_Subscription:"Otkaži pretplatu",
  Subscription_Removed:"Pretplata uklonjena",
  Select_Active_Subscription:"Odaberite aktivnu pretplatu kako biste je izmijenili.",
  Remove_Subscription_Word:"Jeste li sigurni da želite otkazati ovu pretplatu?",
  Delete_Company_Confirmation:"Jeste li sigurni da želite izbrisati cijelu tvrtku? UPOZORENJE: Izgubit ćete sve pohranjene podatke!",
  Delete_Default_Company_Word:"Ne možete izbrisati zadanu tvrtku. Želite li resetirati aplikaciju i vratiti je na zadano stanje? UPOZORENJE: Izgubit ćete sve pohranjene podatke!",
  Console_Warning_2:"Nemojte rukovati valutom pomoću ove aplikacije koja trenutno nije vaša.",
  Terms_and_Conditions:"Odredbe i uvjeti",
  and_the:"i",
  for:"za",
  Please_Read_Word:"Molimo pročitajte i složite se s",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Neki tečajevi pretvorbe valute nisu pronađeni. Molimo spojite se na internet.",
  Free:"Besplatno",
  DB_Erase_Prompt_2:"Potpuno izbrisati cijelu bazu podataka programera? UPOZORENJE: Izgubit ćete sve podatke o KUPNJI i PRETPLATI!",
  Successfully_Imported:"Uspješno uvezeno",
  Select_Postage:"Odaberite Poštarina",
  No_Postage:"Nema poštanskih markica",
  No_Paid_Postage_Word:"Dodajte prvu marku <a routerLink='/folder/Postage/New'>Plaćena poštarina</a>.",
  Trial_Complete:'Proba dovršena',
  Please_Upgrade:'Nadogradite čekove za nastavak ispisa.',
  Aa:"Aa",
  Color:"Boja",
  Font:"Font",
  Guide:"Vodič",
  Guides:"Vodiči",
  Image:"Slika",
  Zoom:"Zum",
  Logo:"Logo",
  Bank:"Banka",
  MICR:"MICR",
  Total:"Ukupno",
  Cancel:"Otkazati",
  Confirm:"Potvrdite",
  Method:"Metoda",
  Biometric_Security:"Biometrička sigurnost",
  Please_Login_To_Continue:"Molimo prijavite se za nastavak.",
  Complete:"Dovršeno",
  Sign_Up:"Prijavite se",
  Error:"Greška",
  Biometrics:"Biometrika",
  Percent:"postotak",
  Zero_Percent:"0%",
  Top_Margin:"Gornja marža",
  Bottom_Margin:"Donja margina",
  Left_Margin:"Lijeva margina",
  Right_Margin:"Desna margina",
  MICR_Margin:"MICR marža",
  Table_Margin:"Margina tablice",
  Address_Margin:"Margina adrese",
  Percentage_:"Postotak",
  Vacation_Title:"Naslov odmora",
  Use_PIN:"Koristite PIN",
  Loading__:"Učitavam...",
  Design_Title:"Naslov dizajna",
  Authorize:"Odobriti",
  Key:"Ključ",
  Public_Key:"Javni ključ",
  Private_Key:"Privatni ključ",
  Authenticate:"Provjeri autentičnost",
  Last_Payroll:"Posljednji obračun plaća",
  Last_Calculation:"Posljednji izračun",
  Authorized:"Ovlašteni",
  Geo_Authorized:"Geografska lokacija: Ovlašteno",
  Not_Authorized:"Nije ovlašteno",
  Authorization_Complete:"Autorizacija dovršena",
  Geolocation_Authorization:"Autorizacija geolokacije",
  Out_of_Bounds:"Izvan granica",
  Cant_Delete_Default_Design:"Ne može se izbrisati zadani dizajn.",
  Unauthorized_Out_of_Bounds:"Neovlašteno: izvan granica",
  Biometric_Authorization:"Biometrička autorizacija",
  Locating_Please_Wait:"Lociranje, molimo pričekajte...",
  Test_Biometrics:"Testirajte biometriju",
  Cheque_Margins:"Provjerite margine",
  Percentage_Full_Error:"Polje postotka ne može se postaviti na više od 100% posto.",
  No_Payroll_Text:"Dodajte <a routerLink='/folder/Employee/New'>Zaposlenika</a> ili <a routerLink='/folder/Payee/New'>Primatelja</a> i <a routerLink='/folder/Schedule/New'>Raspored</a>.",
  Design_Saved:"Dizajn spremljen",
  Default_Design_Selected:"Odabran zadani dizajn",
  Partial_Import:"Djelomični uvoz",
  Partial_Export:"Djelomični izvoz",
  Entire_Import:"Cijeli uvoz",
  Entire_Export:"Cijeli izvoz",
  Existing_Password:"Unesite svoju postojeću lozinku:",
  Existing_PIN:"Unesite svoj postojeći PIN kod:",
  Pin_Change_Header:"PIN potvrda",
  Pin_Change_SubHeader:"Unesite svoj stari PIN broj da potvrdite promjenu.",
  Pass_Change_Header:"Potvrda lozinke",
  Pass_Change_SubHeader:"Unesite svoju staru lozinku da potvrdite promjenu.",
  PIN_Enter_Message:"Unesite svoj PIN za potvrdu.",
  Password_Enter_Message:"Unesite svoju lozinku za potvrdu.",
  Pin_Updated_Success:"PIN je uspješno ažuriran!",
  Pin_Updated_Fail:"PIN se nije mogao ažurirati.",
  Pass_Updated_Success:"Lozinka je uspješno ažurirana.",
  Pass_Updated_Fail:"Lozinka se nije mogla ažurirati.",
  Preview_Payment:"Pregled plaćanja",
  Preview_Payroll:"Pregled plaće",
  No_Payments_Created:"Nisu pronađena plaćanja.",
  Payment_Preview:"Pregled plaćanja",
  Enable_Payee:"Omogući primatelja uplate",
  Rendered:"Rendered",
  No_Email:"Nema e-pošte",
  Setup_Cheques:"Provjere postavljanja",
  name:"Ime",
  address:"Adresa",
  address_2:"Adresni redak 2",
  city:"Grad",
  province:"Pokrajina",
  postal_code:"Poštanski broj",
  country:"Zemlja",
  username:"Korisničko ime",
  full_name:"Puno ime",
  birthday:"Rođendan",
  email:"E-mail",
  phone:"Telefon",
  employees:"Zaposlenici",
  addresses:"Adrese",
  payment_amount_limit:"Ograničenje iznosa plaćanja",
  total_limit:"Ukupno ograničenje",
  payees:"Primatelji plaćanja",
  description:"Opis",
  address_line_one:"Adresni red jedan",
  address_line_two:"Adresni red dva",
  image:"Slika",
  account_holder:"Nositelj računa",
  cheque_starting_id:"Provjerite početni ID",
  transit_number:"Tranzitni broj",
  institution_number:"Broj ustanove",
  designation_number:"Broj oznake",
  account_number:"Broj računa",
  currency:"Valuta",
  signature:"Potpis",
  payment_payroll_limit:"Ograničenje plaćanja",
  total_limi:"Ukupno ograničenje",
  date:"Datum",
  printed_memo:"Tiskani dopis",
  banks:"Banke",
  signature_image:"Slika potpisa",
  address_name:"Adresa Ime",
  notes:"Bilješke",
  design:"Oblikovati",
  title:"Titula",
  frequency:"Frekvencija",
  fax:"Faks",
  salaries:"Plaće",
  salary_ids:"ID plaće",
  start_time:"Vrijeme početka",
  end_time:"Vrijeme završetka",
  paid:"Plaćeno",
  overtime_percentage:"Plaćeni postotak",
  overtime_amount:"Plaćeni fiksni iznos",
  first_name:"Ime",
  last_name:"Prezime",
  moderation:"Umjerenost",
  create:"Stvoriti",
  edit:"Uredi",
  destroy:"Uništiti",
  day_start_time:"Dan_početka_vrijeme",
  day_end_time:"Dan_kraj_vrijeme",
  fullname:"Ime",
  time:"Vrijeme",
  auto_send:"Automatski poslati",
  time_method:"Vremenska metoda",
  schedules:"Rasporedi",
  indefinite_payroll_enabled:"Omogući neograničeno",
  amount:"Iznos",
  repeat:"Ponoviti",
  always_enabled:"Uvijek omogućeno",
  start_date:"Početni datum",
  end_date:"Datum završetka",
  Cheque_Total:"Provjerite Ukupno",
  Total_Amount:"Ukupan iznos:",
  Amounts:"Iznosi:",
  Images:"Slike",
  Files:"Datoteke",
  Previewing:"Pregled:",
  Insert:"Umetnuti",
  Preview_Import:"Pregled uvoza",
  Entry:"Ulazak",
  Entries:"Unosi",
  No_Entries:"Nema unosa",
  Import_Type:"Vrsta uvoza",
  Select_Details:"Odaberite Detalji",
  Select_Payee:"Odaberite primatelja plaćanja",
  Enable_Holidays:"Omogućite praznike",
  Disable_Holidays:"Onemogući praznike",
  Wire_Transfer:"Žični prijenos",
  New_Export:"Novi izvoz",
  Export_Data:"Izvoz podataka",
  Export_Data_Word:"Odaberite vrstu datoteke za izvoz i preuzimanje.",
  Export_File:"Izvezi datoteku",
  Mode:"Način rada",
  Times:"Vremena",
  Widgets:"Widgeti",
  Slider:"Klizač",
  Set_Details:"Postavite pojedinosti",
  Select_Type:"Odaberite Vrsta",
  Display_Slider:"Klizač prikaza",
  Dashboard_Slider:"Klizač za nadzornu ploču",
  Dashboard_Mode:"Način rada nadzorne ploče",
  Show_Intro:"Prikaži uvod",
  Show_Payrolls:"Prikaži platne liste",
  Show_Holidays:"Prikaži praznike",
  Show_Invoices:"Prikaži fakture",
  Show_Cheques:"Prikaži čekove",
  Enabled_Widgets:"Omogućeni widgeti",
  Disabled_Widgets:"Onemogućeni widgeti",
  Colors:"Boje",
  Barcodes:"Bar kodovi",
  View_Timers:"Prikaz mjerača vremena",
  Gradients:"Gradijenta",
  No_Info:"Nema informacija",
  Disable:"Onemogući",
  Show_Layer:"Prikaži slojeve",
  Hide_Layer:"Sakrij slojeve",
  Text_Layer:"Slojevi teksta",
  Secondly:"Drugo",
  Minutely:"U minuti",
  nine_am:"9:00 ujutro",
  five_pm:"17:00",
  Enable_Address:"Omogući Adresa",
  Invalid_File_Type:"Nažalost, odabrana je nevažeća vrsta datoteke. Podržana vrsta datoteke:",
  Error_Updating_Entry:"Nažalost, došlo je do pogreške prilikom ažuriranja ovog unosa.",
  Schedule_Timing_Alert:"Pogreška: vrijeme početka rasporeda postavljeno je na vrijednost nakon vremena završetka.",
  Select_Multiple_Payments_Prompt:"Nema odabranih plaćanja. Odaberite barem jedno plaćanje.",
  Select_Multiple_Cheques_Prompt:"Nema odabranih provjera. Odaberite barem jednu provjeru.",
  Select_Multiple_Items_Prompt:"Nema odabranih stavki. Molimo odaberite barem jednu stavku.",
  Paymemt_Multiple_Delete_Confirmation:"Jeste li sigurni da želite izbrisati više uplata?",
  Cheque_Multiple_Delete_Confirmation:"Jeste li sigurni da želite izbrisati više provjera?",
  Payee_Multiple_Delete_Confirmation:"Jeste li sigurni da želite izbrisati više primatelja plaćanja?",
  Employee_Multiple_Delete_Confirmation:"Jeste li sigurni da želite izbrisati više zaposlenika?",
  MICR_Warning:"Napomena: Neki pisači i uređaji možda neće ispravno prikazati MICR font.",
  Automatically_Send:"Automatski poslati",
  Type:"Tip",
  Payment_Type:"Način plaćanja",
  Auto_Send:"Automatsko slanje",
  Automatically_Process:"Automatski obraditi",
  Auto_Process:"Automatski proces",
  Image_Based:"Na temelju slike",
  Font_Based:"Na temelju fontova",
  Rerender:"Ponovno renderiranje",
  Rendering:"Renderiranje",
  Render:"Datoteke",
  Top:"Vrh",
  Middle:"Srednji",
  Bottom:"Dno",
  Top_Left:"Gore lijevo",
  Top_Center:"Vrh centar",
  Top_Right:"Gore desno",
  Middle_Left:"Srednja lijeva",
  Middle_Center:"Srednji centar",
  Middle_Right:"Srednje desno",
  Bottom_Left:"Dolje lijevo",
  Bottom_Center:"Donji centar",
  Bottom_Right:"Dolje desno",
  Numbers:"Brojevi",
  Verified:"Provjereno",
  Paper_Size:"Veličina papira",
  New_Device:"Novi uređaj",
  Add_Device:"Dodaj uređaj",
  Remove_Device:"Uklonite uređaj",
  Delete_Device:"Izbriši uređaj",
  Block_Device:"Blokiraj uređaj",
  Block:"Blok",
  Unblock:"Deblokiraj",
  Table:"Stol",
  Scan_Login_Code:"Skenirajte kod za prijavu",
  Login_Code:"Kod za prijavu",
  Scan_Code:"Skeniraj kod",
  Scan_QR_Code:"Skenirajte QR kod",
  Select_All:"Odaberi sve",
  Deselect_All:"Odznači sve",
  Increase:"Povećati",
  Decrease:"Smanjenje",
  Bold:"Podebljano",
  Text:"Tekst",
  Style:"Stil",
  Italic:"Kurziv",
  QR_Code:"QR kod",
  Barcode:"Crtični kod",
  Browse_Images:"Pregledajte slike",
  Browse_Files:"Pregledajte datoteke",
  Background_Image:"Pozadinska slika",
  Logo_Image:"Slika logotipa",
  Header_Image:"Slika zaglavlja",
  Bank_Image:"Slika banke",
  Cut_Lines:"Rezane linije",
  Background:"Pozadina",
  License:"Licenca",
  One_License:"1 licenca:",
  Licensed:"Licencirano za:",
  Not_Licensed:"Nije licencirano",
  Enter_Serial:"Unesite serijski",
  Serial_Key:"Serijski ključ",
  Serial:"Serijski",
  Product_Key:"Šifra proizvoda",
  Check_Product_Key:"Provjerite ključ proizvoda",
  Add_Product_Key:"Dodajte ključ proizvoda",
  Verifying_Purchase:"Provjera kupnje...",
  Print_Envelope:"Ispis omotnice",
  Envelope:"Omotnica",
  Thank_You:"Hvala vam!",
  Scale:"Mjerilo",
  Print_Scale:"Mjerilo ispisa",
  Borders:"Granice",
  VOID:"PONIŠTITI",
  Void_Cheque:"Provjera nevaženja",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"PLAĆANJE PO NARUDŽBU:",
  NO_DOLLARS:"BEZ DOLARA ",
  ONE_DOLLAR:"JEDAN DOLAR",
  DOLLARS:" DOLARA",
  AND:" I ",
  CENTS:" CENTI.",
  NO_:"NE ",
  ONE_:"JEDAN ",
  AND_NO_:"I NE ",
  _AND_ONE_:"I JEDAN ",
  DOLLARS_AND_ONE_CENT:" I JEDAN CENT.",
  AND_NO_CENTS:" I NULA CENTI.",
  CHEQUE_PRINT_DATE:"DATUM:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"narodni poslanik",
  Initial_Tasks:"Početni zadaci",
  Inital_Setup:"Početno postavljanje",
  Welcome_To:"Dobrodošli u ",
  Welcome:"Dobrodošli",
  Swipe:"Ukrasti",
  Intro_Setup:"Uvodna postavka",
  Introduction:"Uvod",
  CHEQUE_PRINT_CREDIT:"Pokreće se čekovima",
  Title_Intro_Word:"Dobro došli u čekove",
  Title_Intro:"Uvod u čekove",
  Title_Setup:"Postavljanje čekova",
  PayPal_Default_Email_Message:"Primili ste novi PayPal prijenos. [Pokreće čekovi]",
  Cheques_App_Export:"Izvezeno čekovima",
  Post_Intro_Thanks:"Hvala što ste odabrali Čekove. Proces postavljanja je sada završen.",
  Post_Intro_Word:"Započnite tako što ćete ispisati svoj prvi ček, dodati buduću uplatu ili dodati zaposlenika na platni spisak.",
  Upgrade_Required:"Čekovi zahtijevaju da posjedujete premium verziju softvera kako biste sakrili ovu poruku i otključali dodatne značajke.",
  Upgrade_Title:"Čekovi",
  Mailout_Prompt:"Možete dodatno odabrati da vam čekovi šalju čekove na platni spisak.",
  Mailed_Cheque:"Ček poslat poštom: ",
  Mailed_Cheque_Color:"Poslan ček (boja): ",
  Terms_Purchase:"Sve elektroničke kupnje s čekovima mogu se u potpunosti vratiti u roku od 30 dana od datuma kupnje. Prije nastavka pročitajte i prihvatite <a href='#'>Uvjete i odredbe</a>.",
  Dashboard_Setup:"Postavljanje primarnog pisača",
  Dashboard_Add:"Dodaj primarni bankovni račun",
  Dashboard_Customize:"Odaberi predložak za provjeru",
  Dashboard_Job_Salary:"Stvorite svoj posao i dodajte svoju plaću",
  Dashboard_Employees:"Prati zaposlenike i primatelje plaćanja",
  Dashboard_Print:"Ispiši i pošalji svoje čekove",
  Dashboard_Payroll:"Automatizirajte ispis platnog spiska",
  Dashboard_PayPal:"Postavljanje PayPal Payroll / Isplate",
  Begin_Setup:"Započni postavljanje",
  Get_Started:"Započni",
  Purchase:"Kupiti",
  Lockdown:"Izolacija",
  Unlock:"Otključati",
  Detailed:"Detaljan",
  Log_In:"Prijaviti se",
  Login:"Prijaviti se",
  Launch:"Pokreni",
  Register:"Registar",
  Finish:"Završi",
  List:"Popis",
  Weekends:"vikendima",
  Weekly:"Tjedni",
  PayPal_Default_Subject:"Novo plaćanje",
  Payment_Message:"Poruka o plaćanju",
  PayPal_Default_Payment_Note:"Hvala vam",
  Setup_Your_Cheqing_Account:"Čekovni račun",
  Add_Your_Primary_Cheqing_Account:"Dodajte svoj primarni čekovni račun.",
  Welcome_Word:"Pojednostavite i automatizirajte obračun plaća i upravljanje ljudskim resursima.",
  Get_Started_Quickly:"Samo odgovorite na nekoliko jednostavnih pitanja koja će nam pomoći da počnemo...",
  Done_Intro_Word:"Postavljanje dovršeno",
  PIN_Protected:"Zaštićeno lozinkom i PIN-om",
  Enter_New_PIN:"Unesite novi PIN kod:",
  Enter_PIN:"Unesite PIN kod:",
  Invalid_PIN:"Unesen je nevažeći PIN.",
  Account_Identifier:"Identifikator računa",
  New_Account_Identifier:"Novi identifikator računa",
  attempt:"pokušaj",
  attempts:"pokušaji",
  remaining:"preostalo",
  Language:"Jezik",
  languages:"Jezici",
  select_languages:"Izaberi jezik",
  Deposit:"Depozit",
  Hire_One_Now:"Unajmite jednog sada",
  App_Locked:"Aplikacija je zaključana.",
  Skip_:"Preskočiti",
  Skip_to_Dashboard:"Preskoči na nadzornu ploču",
  Dashboard:"Upravljačka ploča",
  Import:"Uvoz",
  Admin:"Administratori",
  New_Admin:"Novi administrator",
  Settings:"Postavke",
  Color_Picker:"Birač boja",
  Font_Picker:"Birač fonta",
  Logout:"Odjavite se",
  Close:"Zatvoriti",
  Close_menu:"Zatvoriti",
  Excel:"Excel datoteka",
  Csv:"CSV datoteka",
  Sql:"SQL datoteka",
  Json:"JSON datoteka",
  Url:"Uvoz po URL-u",
  Back:"Leđa",
  Timers:"Timeri",
  Cheque:"Ček",
  Print:"Ispis",
  Designs:"Dizajni",
  Pause_Printing:"Pauziraj ispis",
  Resume_Printing:"Nastavi ispis",
  Printing_Paused:"Ispis pauziran",
  PrintingUnavailable:"Oprostite! Ispis nije dostupan na ovom sustavu.",
  Prints_Paused:"Ispisi zaustavljeni",
  Administration:"Uprava",
  Loading:"Učitavam",
  Unnamed:"Neimenovani",
  error:"Nažalost, ovaj se ček nije mogao otvoriti za pregled.",
  No_Cheques_To_Print:"Nema čekova za ispis",
  No_Cheques_To_Print_Word:"Stvorite <a routerLink='/folder/Cheque/New'>Novi ček</a>.",
  New_Cheque:"Novi ček",
  Start_One_Now:"Započni jedan sada",
  Edit_Cheque:"Uredi provjeru",
  Select_Cheques:"Odaberi čekove",
  Select_Employee:"Odaberite zaposlenika",
  Default_Printer:"Zadani pisač",
  Reassign:"Predodijeliti",
  Configure:"Konfigurirati",
  Template:"Predložak",
  Designer:"Dizajner",
  Edit_Designs:"Uređivanje dizajna",
  New_Design:"Novi dizajn",
  Next:"Sljedeći",
  Save:"Uštedjeti",
  Name:"Ime",
  Mail:"pošta",
  Amount:"Iznos",
  Date:"Datum",
  PayPal:"PayPal",
  Payouts:"isplate",
  PayPal_Label:"PayPal Label",
  Email_Username:"E-mail / korisničko ime",
  Client_ID:"ID klijenta",
  Sandbox_Email:"Sandbox Email",
  PayPal_Email:"PayPal e-pošta",
  PayPal_Username:"Korisničko ime za API",
  PayPal_Payouts:"PayPal isplate",
  Select_PayPal_Key:"Odaberite PayPal ključ",
  Secret:"Tajna",
  API_Secret:"Tajna API-ja",
  PayPal_API_Keys:"PayPal ključevi",
  New_PayPal_Key:"Novi PayPal ključ",
  Email_Subject:"Predmet e-pošte",
  Email_Message:"Poruka e-pošte",
  Payout_Options:"Opcije isplate",
  Payment_Note:"Napomena o uplati",
  Enable_Employee:"Omogući zaposleniku",
  Enable_Production_Mode:"Omogući način proizvodnje",
  Sandbox_Username:"Korisničko ime u Sandboxu",
  Sandbox_Signature:"Sandbox Signature",
  Sandbox_Password:"Lozinka u zaštićenom okruženju",
  Production_Username:"Produkcijsko korisničko ime",
  Production_Signature:"Proizvodni potpis",
  Production_Password:"Proizvodna lozinka",
  Production_Email:"E-pošta za proizvodnju",
  API_Client_ID:"ID klijenta za API",
  API_Signature:"API potpis",
  API_Password:"API lozinka",
  API_Username:"Korisničko ime za API",
  Secret_Key:"Tajni ključ",
  Sandbox:"pješčanik",
  Production:"Proizvodnja",
  Sandbox_Keys:"Ključevi u pješčanom okruženju",
  Production_Keys:"Proizvodni ključevi",
  API_Title:"Naslov API-ja",
  Production_Mode:"Proizvodni način",
  Account_Label:"Oznaka računa",
  No_PayPal_Setup:"Nema PayPal ključa",
  Enable_PayPal_Account:"Omogući PayPal račun",
  No_PayPal_Word:"Dodajte svoj <a routerLink='/folder/Invoice/New'>PayPal API ključ</a>.",
  Printed_Memo:"Ispisani dopis",
  Employee:"Zaposlenik",
  View_Employee:"Prikaz zaposlenika",
  Mailing_Address:"Poštanska adresa",
  Company_Address:"Adresa tvrtke",
  Select_Company_Address:"Odaberi adresu tvrtke",
  Address:"Adresa",
  Any_Day:"Bilo koji dan",
  Address_Name:"Naziv adrese",
  Unit:"Jedinica",
  Account:"Račun",
  Bank_Account:"Bankovni račun",
  Account_Limits:"Omogući ograničenja računa",
  Payroll:"Platni spisak",
  New_Payroll:"Novi obračun plaća",
  No_Payroll:"Nema nadolazeće plaće",
  Upcoming_Holiday:"Nadolazeći praznik:",
  Invoice_Due:"Dospjeli račun:",
  New_Invoice:"Nova faktura",
  No_Invoices:"Bez faktura",
  No_Invoices_Word:"Stvorite prvu <a routerLink='/folder/Invoice/New'>Fakturu</a>.",
  Cheque_Due:"Dospjeli ček:",
  Payrolls:"platne liste",
  Sandbox_Mode:"Sandbox način rada",
  Hire:"Najam",
  Pay:"Platiti",
  New:"Novi",
  Add:"Dodati",
  Make:"Napraviti",
  Time:"Vrijeme",
  Write:"Pisati",
  Holiday:"Odmor",
  Holidays:"Praznici",
  Next_Holiday:"Sljedeći praznik:",
  All_Done:"Sve Gotovo!",
  Employees:"Zaposlenici",
  Payees:"Primatelji uplate",
  Job:"Posao",
  Jobs:"poslovi",
  Invoice:"Dostavnica",
  Invoices:"Fakture",
  Vacations:"Odmor",
  Cheques:"provjere",
  Brand_Cheques:"Marka",
  Payment:"Plaćanje",
  Enable_Payment:"Omogući plaćanje",
  Payments:"Plaćanja",
  Schedule:"Raspored",
  Schedules:"Rasporedi",
  Timesheet:"Time Sheet",
  Timesheets:"Time Sheets",
  Salary:"Plaća",
  New_Address:"Nova adresa",
  Address_2:"Adresa (redak 2)",
  _City:"Grad",
  _State:"Država/Prov",
  City:"Grad / naselje",
  State:"Država/pokrajina",
  Postal:"Poštanski broj",
  ZIP:"Poštanski / ZIP",
  Country:"Zemlja",
  Addresses:"Adrese",
  Required_Options:"Obavezne opcije",
  Optional_Options:"Neobavezne opcije",
  Additional_Options:"Dodatne opcije",
  Required:"Potreban",
  Optional:"Neobavezno",
  Additional:"Dodatno",
  No_Addresses:"Nema adresa",
  New_Address_Word:"Dodaj prvu <a routerLink='/folder/Address/New'>Adresu</a>.",
  Select_Address:"Odaberi adresu",
  No_Address:"Nema adresa",
  Print_Cheque:"Print Check",
  Print_Cheque_Now:"Ispiši provjeri sada",
  Description:"Opis",
  Pay_To_The_Order_Of:"Plati po nalogu:",
  Select_Date_Range:"Odaberite raspon datuma",
  Select_Starting_Date:"Odaberi datum početka",
  Select_Ending_Date:"Odaberite datum završetka",
  Select_Date:"Odaberi datum",
  Cheque_Date:"Datum provjere",
  Cheque_Memo:"Provjeri bilješku",
  Blank_Cheque:"Bjanko ček",
  Blank:"Prazan",
  No_Cheques:"Bez čekova",
  No_Cheques_Word:"Ispišite svoju prvu <a routerLink='/folder/Cheque/New'>Ček</a>.",
  Cheque_Amount_Placeholder:"0,00 USD",
  View_Image:"Pogledati sliku",
  View:"Pogled",
  Modify:"Izmijeniti",
  Delete:"Izbrisati",
  Cheque_Paid:"Plaćeno",
  New_Deduction:"Novi odbitak",
  Title:"Titula",
  Frequency:"Frekvencija",
  Hourly:"Po satu",
  Daily:"Dnevno",
  Weekdays:"radnim danima",
  BiWeekly:"2 tjedna",
  TriWeekly:"3 tjedna",
  Monthly:"Mjesečno",
  MiMonthly:"2 mjeseca",
  Quarterly:"Kvartalni",
  Yearly:"Godišnje",
  Every_Week:"Svaki tjedan",
  Every_Payroll:"Svaka platna lista",
  Every_Month:"Svaki mjesec",
  Annually:"Godišnje",
  Always_Scheduled:"Uvijek na rasporedu",
  Start_Date:"Početni datum",
  End_Date:"Datum završetka",
  Start_Time:"Vrijeme početka",
  End_Time:"Vrijeme kraja",
  Deduction_Label:"Oznaka odbitka",
  Notes:"Bilješke",
  Options:"Opcije",
  Enable:"Omogućiti",
  Select_Deductions:"Odaberite odbitke",
  Deductions:"Odbici",
  No_Deductions:"Bez odbitaka",
  No_Deductions_Word:"Stvorite svoj prvi <a routerLink='/folder/Deduction/New'>Odbitak</a>.",
  New_Employee:"Novi zaposlenik",
  No_Title:"Bez naslova",
  _Name:"Ime",
  About_Yourself:"O sebi",
  Full_Name:"Puno ime",
  Birthday:"Rođendan",
  Email:"E-pošta",
  SMS:"SMS",
  Phone:"Telefon",
  Test:"Test",
  Call:"Poziv",
  Fax:"Faks",
  Printed_Note:"Tiskana bilješka",
  Position:"Položaj",
  Job_Position:"Poslovno mjesto",
  Select_a_Job:"Odaberi posao",
  Select_a_Salary:"Odaberite plaću",
  Add_a_Deduction:"Dodaj odbitak",
  Taxes:"Porezi",
  Add_Taxes:"Dodaj porez",
  Date_of_Birth:"Datum rođenja",
  Email_Address:"Email adresa",
  Phone_Number:"Broj telefona",
  Phone_Call:"Telefonski poziv",
  Enable_on_Payroll:"Omogući na obračunu plaća",
  Select_Employees:"Odaberite zaposlenike",
  No_Employees:"Nema zaposlenih",
  No_Employees_Word:"Dodajte svoj prvi novi <a routerLink='/folder/Employee/New'>Zaposlenik</a>.",
  No_Name:"Bez imena",
  Unemployeed:"Nezaposleni",
  Employeed:"Zaposlen",
  Paid:"Plaćeno",
  Enabled:"Omogućeno",
  Disabled:"Onemogućeno",
  Fire:"Vatra",
  Not_Available:"Nije dostupno",
  Not_Available_Word:"Ispišite svoj prvi <a routerLink='/folder/Invoice/New'>Fakturu</a> i primite plaćanje.",
  Select_Invoices:"Odaberi_fakture",
  Print_Invoice_Word:"Ispišite svoj prvi <a routerLink='/folder/Invoice/New'>Fakturu</a> i primite plaćanje.",
  Invoice_Title:"Naslov fakture",
  Invoice_Date:"Datum dostavnice",
  Due_Date:"Datum dospijeća",
  New_Job:"Novi posao",
  Details:"Detalji",
  Customize:"Prilagodi",
  Customize_Dashboard:"Prilagodi nadzornu ploču",
  Components:"Komponente",
  No_Components:"Bez komponenti",
  Main_Components:"Glavne komponente",
  Smaller_Components:"Manje komponente",
  Error_Loading_Page:"Pogreška pri učitavanju ove stranice.",
  Bank_Details:"Bankovni detalji",
  About_Your_Job:"O tvom poslu",
  Your_Schedule:"Vaš raspored",
  Job_Title:"Naziv posla",
  Job_Description:"Opis posla",
  Job_Details:"Detalji posla",
  Enable_Job:"Omogući posao",
  Pay_Period:"Razdoblje plaćanja",
  Perpetually_Schedule:"Trajni raspored",
  Select_Jobs:"Odaberi poslove",
  No_Jobs:"Nema poslova",
  Add_Jobs:"Dodaj poslove",
  No_Jobs_Word:"Dodajte prvi <a routerLink='/folder/Job/New'>Posao</a> na popis.",
  Count_Employees:"job.employee_count+' Employees'",
  Zero_Employees:"0 zaposlenika",
  New_Leave:"Novi odlazak",
  Leave_Name:"Napusti ime",
  Paid_Leave:"Plaćeni dopust",
  Leave_Pay:"Ostavite platu",
  Indefinite_Leave:"Neograničeno odsustvo",
  Indefinite_Payroll:"Neograničeno plaća",
  Leave:"Napustiti",
  Add_Schedules:"Dodaj rasporede",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Omogući odsutnost",
  Select_Leaves:"Odaberi lišće",
  No_Leaves:"Bez odsustva",
  Leave_Of_Absence:"Bolovanje",
  Leaves_Of_Absence:"Ostavi odsutnosti",
  New_Leave_of_Absense:"Novi dopust",
  No_Leaves_Word:"Dodaj prvi <a routerLink='/folder/Leave/New'>Odsustvo</a>.",
  Not_Enabled:"Nije omogućeno",
  Absences:"Izostanci",
  Payee:"Primatelj plaćanja",
  New_Payee:"Novi primatelj plaćanja",
  Payee_Identifier:"Identifikator primatelja uplate",
  Payee_Name:"ime primatelja uplate",
  Payee_Title:"Naslov primatelja uplate",
  Payment_Memo:"Zapis o plaćanju",
  Select_Payees:"Odaberi primatelje plaćanja",
  No_Payees:"Bez primatelja",
  Add_Payee_Note:"Dodaj prvog <a routerLink='/folder/Payee/New'>Primatelja</a>.",
  New_Payees:"Novi primatelji plaćanja",
  About_The_Payment_Schedule:"Raspored plaćanja",
  Your_Payroll_Schedule:"Automatski obračun plaća",
  New_Payment:"Novo plaćanje",
  Identifier:"Identifikator",
  Select:"Izaberi",
  Memo:"Memo",
  Payment_Date:"Datum plačanja",
  Mark_as_Paid:"Označi kao plaćeno",
  Select_Payments:"Odaberi plaćanja",
  No_Payments:"Bez plaćanja",
  No_Payments_Word:"Kreiraj prvo <a routerLink='/folder/Payment/New'>Plaćanje</a>.",
  Create_Payroll:"Kreiraj platni spisak",
  Properties:"Svojstva",
  Payroll_Title:"Naziv plaće",
  Payroll_Notes:"Bilješke o plaćama",
  Payroll_Setup:"Postavljanje platnog spiska",
  Tabulate_Payments:"Tabularna plaćanja",
  Tabulate:"Tabela",
  By:"Po:",
  Payments_By:"Plaćanja do",
  Timesheets_And_Schedules:"Raspored i raspored",
  Both:"Oba",
  Items:"Predmeti",
  Add_Payees:"Dodaj primatelje plaćanja",
  Add_Account:"Dodaj račun",
  Enable_Account:"Omogući račun",
  Enable_Payroll:"Omogući obračun plaća",
  Print_Payroll:"Ispis plaće",
  No_Payrolls:"Bez obračuna plaća",
  No_Payroll_Word:"Stvorite svoj prvi <a routerLink='/folder/Payroll/New'>Platni Spisak</a>.",
  View_more:"VIDI VIŠE",
  Less:"MANJE",
  Add_Payroll:"Dodaj obračun plaća",
  Select_Payroll:"Odaberi obračun plaća",
  About_Your_Salary:"O tvojoj plaći",
  Add_Salaries:"Dodaj plaće",
  Add_Salary:"Dodaj plaću",
  Salaries:"Plaće",
  No_Salaries:"Bez plaća",
  No_Salaries_Word:"Dodaj prvu <a routerLink='/folder/Salary/New'>Plaću</a>.",
  Select_Salaries:"Odaberi plaće",
  New_Salary:"Nova plaća",
  Salary_Name:"Identifikator plaće",
  Enable_Salary:"Omogući plaću",
  Salary_Amount:"Iznos plaće",
  New_Schedule:"Novi raspored",
  Schedule_Title:"Naslov rasporeda",
  Add_Address:"Dodaj adresu",
  Repeat:"Ponoviti",
  Design:"Oblikovati",
  Edit_Design:"Uredi dizajn",
  Edit_this_Design:"Uredi ovaj dizajn",
  Repeat_Payment:"Ponovno plaćanje",
  Enable_Schedule:"Omogući raspored",
  Never:"Nikada",
  Select_Schedule:"Odaberi rasporede",
  No_Schedules:"Bez rasporeda",
  No_Schedules_Word:"Kreiraj prvi <a routerLink='/folder/Schedule/New'>Raspored</a>.",
  New_Administrator:"Novi administrator",
  Username:"Korisničko ime",
  First_Name:"Ime",
  Last_Name:"Prezime",
  Add_an_Address:"Dodaj adresu",
  Payment_Limit:"Ograničenje po plaćanju",
  Total_Limit:"Ukupno ograničenje",
  Select_Accounts:"Odaberi račune",
  No_Administrators:"Bez administratora",
  No_Administrators_Word:"Stvorite prvi <a routerLink='/folder/Administrator/New'>administratorski račun</a>.",
  New_Administrators_Word:"Dodaj prvi <a routerLink='/folder/Administrator/New'>Administrator</a>",
  Cloud:"Oblak",
  Backup:"Pričuvna kopija",
  Enable_iCloud:"Omogući iCloud",
  Enable_Google_Drive:"Omogući Google disk",
  Enable_Microsoft_OneDrive:"Omogući Microsoft OneDrive",
  Automatically_Backup:"Automatski sigurnosno kopiranje",
  FTP_Settings:"FTP postavke",
  URL_File_Prompt:"Molimo navedite mjesto za .xls / .xlsx / .json datoteku za uvoz:",
  URL_SQL_Prompt:"Molimo navedite lokaciju datoteke SQLite za uvoz:",
  FTP_Backup:"FTP sigurnosna kopija",
  FTP_Import:"FTP uvoz",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Domaćin",
  Port:"Luka",
  Path:"Staza",
  Data_Path:"Put podataka",
  Enable_FTP_Account:"Omogući FTP račun",
  HostnameIP:"ime domaćina",
  Password:"Zaporka",
  Connection_Port:"Priključak za povezivanje",
  Enable_MySQL_Database:"Omogući MySQL bazu podataka",
  Log:"Dnevnik",
  Reset:"Reset",
  Erase:"Izbriši",
  Export:"Izvoz",
  Database:"Baza podataka",
  Upload_CSV:"Prenesi CSV",
  Download_CSV:"Preuzmi CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL sigurnosna kopija",
  Internal_Notes:"Unutarnje bilješke",
  Root_Path:"Korijenski put",
  Select_Backup:"Odaberi sigurnosnu kopiju",
  Add_New_Backup:"Dodaj novu sigurnosnu kopiju",
  First_Backup:"Postavite prvu sigurnosnu kopiju...",
  Backups:"Sigurnosne kopije",
  Add_Backup:"Dodaj sigurnosnu kopiju",
  No_Backups:"Nema sigurnosnih kopija koje treba pronaći.",
  Select_Backup_Type:"Odaberite vrstu sigurnosne kopije koju želite postaviti...",
  Backup_Type:"Vrsta sigurnosne kopije",
  FTP_Drive:"FTP pogon",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Voziti",
  Microsoft_OneDrive:"Voziti",
  Import_Fields:"Polja za uvoz",
  Import_Fields_Word:"Upotrijebite ovaj odjeljak za odabir <a routerLink='/folder/Holiday/New'>Uvoz</a> podataka.",
  Upload:"Učitaj",
  Download:"Preuzimanje datoteka",
  Download_JSON:"Preuzmi kao JSON podatke",
  Download_SQL:"Preuzmi kao SQL datoteku",
  New_Bank:"Nova banka",
  New_Account:"Novi profil",
  New_Bank_Account:"Novi bankovni račun",
  Upload_Image:"Učitaj sliku",
  Bank_Name:"Ime banke",
  Bank_Address:"Adresa banke",
  Branch_Address:"Adresa podružnice",
  Address_on_Cheque:"Adresa",
  Cheque_Numbers:"Provjerite brojeve",
  Cheque_Details:"Provjeri detalje",
  Bank_Logo:"Logotip banke",
  Cheque_ID:"Provjeri ID",
  Starting_Cheque_ID:"ID početne provjere",
  Transit_Number:"Tranzitni broj",
  Institution_Number:"broj ustanove",
  Designation_Number:"označni broj",
  Account_Number:"Broj računa",
  Limits:"Granice",
  Default_Limits:"Zadana ograničenja",
  Over_Limit:"Preko granice",
  Under_Limit:"ispod ograničenja",
  Payroll_Limit:"Ograničenje platnog spiska",
  Enable_Bank_Account:"Omogući bankovni račun",
  Select_Account:"Odaberi račun",
  No_Bank_Account:"Nema bankovnog računa",
  No_Bank_Account_Word:"Dodaj prvi <a routerLink='/folder/Accounts/New'>bankovni račun</a>.",
  Bank_Accounts:"Bankovni računi",
  No_Accounts:"Nema računa",
  No_Accounts_Note:"Dodaj prvi <a routerLink='/folder/Accounts/New'>bankovni račun</a>.",
  Cheque_Designer:"Provjeri dizajnera",
  Cheque_Design:"Provjeri dizajn",
  Select_Design:"Odaberi dizajn",
  Cheque_Designs:"Provjeri dizajne",
  No_Cheque_Designs:"Dizajni bez provjere",
  No_Cheque_Designs_Word:"Stvorite svoj vlastiti <a routerLink='/folder/Settings/Cheque/Design/New'>Check Design</a>.",
  Set_Default:"Postavi kao zadano",
  Default:"Zadano",
  Remove:"Ukloniti",
  Folder:"Mapa",
  Edit:"Uredi",
  LoadingDots:"Učitavam...",
  Add_a_New_File:"Dodaj <a href='#' (click)='add()'>Novu datoteku</a> u",
  this_folder:"ova mapa",
  FTP_Backup_Settings:"Postavke sigurnosne kopije FTP-a",
  Secure_File_Transfer:"Sigurni prijenos datoteka",
  New_Holiday:"Novi praznik",
  Add_Holiday:"Dodaj praznik",
  Holiday_Name:"Ime praznika",
  Additional_Pay:"Dodatna plaća",
  Enable_Holiday:"Omogući odmor",
  Select_Holidays:"Odaberi praznike",
  No_Holidays:"Bez praznika",
  No_Holidays_Word:"Dodaj prvi <a routerLink='/folder/Holiday/New'>Praznici</a>.",
  New_Import:"Novi uvoz",
  Import_Data:"Uvoz podataka",
  Import_Data_Word:"Odaberite vrstu datoteke ili način prijenosa po vašem izboru.<br /> Moći ćete odabrati koja god uvezena polja u datoteci odgovaraju bilo kojem parametru u aplikaciji nakon prijenosa podržane datoteke.", 
  Import_File:"Uvezi datoteku",
  Link_To_File:"Veza na datoteku",
  Select_File:"Odaberi datoteku",
  New_Moderator:"Novi moderator",
  Allow_Moderation:"Dopusti moderiranje",
  Create_Paycheques:"Kreiraj plaće",
  Edit_Paycheques_and_Data:"Uređivanje plaća i podataka",
  Destroy_Data_and_Paycheques:"Uništi podatke i plaće",
  Bonus_Percentage:"postotak plaće",
  Fixed_Amount:"fiksni iznos",
  Select_Moderator:"Odaberi moderatora",
  No_Moderators:"Bez moderatora",
  Moderators:"Moderatori",
  Moderator_Account:"Stvorite prvi <a routerLink='/folder/Administrator/New'>račun moderatora</a>.",
  No_Moderators_Word:"Dodaj prvi <a routerLink='/folder/Administrator/New'>Moderator</a>.",
  Defaults:"Zadano",
  Provide_Us_Info:"Pruži nam informacije",
  Setup_Your_Printer:"Postavite svoj pisač",
  Your_Company:"O vašoj tvrtki",
  Company_Name:"Naziv tvrtke",
  Currency:"Valuta",
  Default_Currency:"Zadana valuta",
  Base_Monthly_Income:"Mjesečni prihod",
  Protection:"Zaštita",
  App_Protection:"Zaštita aplikacija",
  PIN_Code_Protection:"Zaštita PIN koda",
  App_Protection_Word:"Omogući sigurnosne metode koje pomažu u zaštiti vašeg računa.",
  PIN_Code:"PIN kod",
  Change_PIN:"Promijeni PIN",
  New_Password:"Nova lozinka",
  Geofence_Protection:"Zaštita geo-ograde",
  Geofence_Area:"Postavi područje",
  Distance:"Udaljenost",
  Setup_Now:"Postavi sada",
  Mile:"Milja",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Prepoznavanje lica",
  Face:"Lice",
  Setup:"Postaviti",
  Label:"Označiti",
  Password_Protection:"Zaštita lozinkom",
  Modify_Password:"Izmijeni lozinku",
  New_Tax_Entry:"Novi porezni unos",
  New_Tax:"Novi porez",
  Tax_Label:"Porezna oznaka",
  Perpetually_Enabled:"Trajno omogućeno",
  Select_Taxes:"Odaberi poreze",
  Tax_Deductions:"Porezne olakšice",
  No_Tax_Deductions:"Bez poreznih olakšica",
  No_Tax_Deductions_Word:"Dodaj prvi <a routerLink='/folder/Tax/New'>porezni</a> odbitak.",
  New_Timer:"Novi mjerač vremena",
  Start:"Početak",
  Stop:"Stop",
  Start_Timer:"Start Timer",
  Stop_Timer:"Zaustavi mjerač vremena",
  Timer_Active:"Timer Active",
  Timer:"Timer:",
  Timer_Running:"Timer: (Radi)",
  Save_Timer:"Save timer",
  New_Timesheet:"Novi satnica",
  Select_Timesheets:"Odaberite satnice",
  Work_Notes:"Radne bilješke",
  Entry_Title:"Naslov unosa",
  No_Timesheets:"Bez tablica radnog vremena",
  No_Timesheets_Word:"Dodaj prvi <a routerLink='/folder/Timesheet/New'>Vremenski List</a>.",
  Timesheet_Submitted:"Radni list dostavljen",
  Timesheet_Congrats:"Čestitamo! Vaša tablica vremena je uspješno poslana. Hvala!",
  Timesheet_Copy:"Da biste primili kopiju svojih dokumenata, navedite nam svoju adresu e-pošte i/ili broj mobilnog telefona.",
  Submit:"Podnijeti",
  Allow_Notifications:"Dopusti obavijesti",
  Untitled_Entry:"Novi unos",
  Untitled_Bank:"Banka bez naziva",
  Timesheet_Entry:"Unos radnog vremena",
  Work_Description:"Opis Posla",
  Enable_Timesheet:"Enable Time Sheet",
  Submit_Timesheet:"Pošalji satnicu",
  Vacation:"Odmor",
  New_Vacation:"Novi odmor",
  Vacation_Name:"Naziv odmora",
  Paid_Vacation:"Plaćeni odmor",
  Vacation_Pay:"Regres",
  Enable_Vacation:"Omogući odmor",
  Select_Vacations:"Odaberi godišnji odmor",
  No_Vacations:"Bez odmora",
  No_Vacations_Word:"Kreiraj prvi unos <a routerLink='/folder/Vacation/New'>Odmor</a>.",
  Payroll_Schedule:"Raspored plaća",
  Next_Payroll:"Sljedeća plata:",
  Upcoming_Payroll:"Nadolazeći obračun plaća",
  No_Upcoming_Payroll:"Nema nadolazeće plaće",
  Address_Book:"Adresar",
  Archived_Documents:"Arhivirani dokumenti",
  Dev_Mode:"Aplikacija u razvojnom načinu",
  Administrators:"Administratori",
  Privacy:"Privatnost",
  None:"Nijedan",
  Select_Signature:"Odaberi potpis",
  No_Signatures:"Bez potpisa",
  No_Signatures_Word:"Dodaj prvi <a routerLink='/folder/Signature/New'>Potpis</a>.",
  Repeat_Indefinitely:"Ponavljati neograničeno",
  Sign:"Znak",
  Sign_Here:"Potpiši ovdje",
  Date_Signed:"Datum potpisa",
  Signature_Pad:"Jastučić za potpis",
  Account_Holder:"Nositelj računa",
  Account_Properties:"Svojstva računa",
  Name_On_Cheque:"Ime na čeku",
  Server_Hostname:"Naziv hosta poslužitelja / IP",
  Printers:"Pisači",
  No_Printers:"Bez pisača",
  Printer_Exists:'Pisač s ovim imenom već postoji.',
  No_Printers_Word:"Dodaj prvi <a routerLink='/folder/Printer/New'>Printer</a>.",
  No_Printer_Alert:"Nije definiran nijedan pisač. Želite li postaviti pisač?",
  Add_Printer:"Dodaj pisač",
  New_Printer:"Novi pisač",
  Printer_Hostname:"Naziv hosta pisača / IP",
  Printer_Label:"Etiketa pisača",
  Printer_Protocol:"Protokol pisača",
  Protocol:"Protokol",
  Email_Print:"E-pošta",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"utičnica",
  Print_Job:"Posao ispisa",
  Printed:"Ispisano",
  Not_Printed:"Nije tiskano",
  Print_Jobs:"Poslovi ispisa",
  Print_Queue:"Red za ispis",
  No_Print_Jobs:"Bez poslova ispisa",
  No_Prints:"Stvorite novi <a routerLink='/folder/Cheque/New'>ček</a> za ispis.",
  Prints:"Otisci",
  Find_Printer:"Pronađi pisač",
  Find_AirPrint_Devices:"Pronađi AirPrint uređaje",
  Select_Printer:"Odaberi pisač",
  System_UI:"System UI",
  Printer:"pisač",
  Status:"Status",
  Preview:"Pregled",
  Enable_Print_Job:"Omogući zadatak ispisa",
  Queue_Weight:"Težina reda",
  Unlimited:"Neograničen",
  Show_Advanced_Printer_Options:"Prikaži napredne mogućnosti pisača",
  Advanced:"Napredna",
  Location:"Mjesto",
  Note:"Bilješka",
  Queue_Name:"Naziv reda",
  Pages_Printed_Limit:"Ograničenje ispisanih stranica",
  MultiPage_Idle_Time:"Vrijeme čekanja na više stranica (s)",
  Page_Count_Limit:"Ograničenje broja stranica",
  Page_Orientation:"Orijentacija stranice",
  Portrait:"Portret",
  Landscape:"Pejzaž",
  Duplex:"dupleks",
  Double_Sided_Printing:"Dvostran",
  Duplex_Mode:"Duplex Mode",
  Duplex_Short:"Kratak",
  Duplex_Long:"dugo",
  Duplex_None:"Nijedan",
  Color_And_Quality:"Boja i kvaliteta",
  Monochrome:"Monokromatski",
  Photo_Quality_Prints:"Kvalitetni ispisi fotografija",
  Printer_Email:"E-pošta pisača",
  Automatically_Downsize:"Automatski smanjivanje",
  Paper:"Papir",
  Paper_Name:"Naziv papira",
  Paper_Width:"Širina papira",
  Paper_Height:"Visina papira",
  Paper_Autocut_Length:"Duljina automatskog rezanja papira",
  Margins:"Marže",
  Page_Margins:"Margine stranice",
  Page_Margin_Top:"Margina na vrhu stranice",
  Page_Margin_Right:"Desna margina stranice",
  Page_Margin_Bottom:"Donja margina stranice",
  Page_Margin_Left:"Lijeva margina stranice",
  Add_Employees:"Dodaj zaposlenike",
  Header:"Zaglavlje",
  Print_A_Page_Header:"Ispis zaglavlja stranice",
  Header_Label:"Oznaka zaglavlja",
  Header_Page_Index:"Indeks stranice zaglavlja",
  Header_Font_Name:"Font zaglavlja",
  Select_Font:"Odaberi font",
  Font_Selector:"Odabir fonta",
  Header_Font_Size:"Font zaglavlja",
  Header_Bold:"Podebljano zaglavlje",
  Header_Italic:"Zaglavlje Kurziv",
  Header_Alignment:"Poravnavanje zaglavlja",
  Left:"Lijevo",
  Center:"Centar",
  Right:"Pravo",
  Justified:"Opravdano",
  Header_Font_Color:"Boja zaglavlja",
  Select_Color:"Odaberi boju",
  Footer:"Podnožje",
  Print_A_Page_Footer:"Ispis podnožja stranice",
  Footer_Label:"Oznaka podnožja",
  Footer_Page_Index:"Indeks stranice u podnožju",
  Footer_Font_Name:"Font podnožja",
  Fonts:"Fontovi",
  Done:"Gotovo",
  Select_Fonts:"Odaberi fontove",
  Footer_Font_Size:"Veličina podnožja",
  Footer_Bold:"Podnožje podebljano",
  Footer_Italic:"Footer Italic",
  Footer_Alignment:"Poravnanje podnožja",
  Footer_Font_Color:"Boja podnožja",
  Page_Copies:"Kopije stranica",
  Enable_Printer:"Omogući pisač",
  Remote_Logging:"Daljinsko evidentiranje",
  Log_Server:"Poslužitelj dnevnika",
  Encryption:"Enkripcija",
  Random_Key:"Slučajni ključ",
  Encryption_Key:"Ključ za šifriranje",
  Cheques_Webserver:"Prilagođena baza podataka",
  Learn_How:"Nauči kako",
  Signature:"Potpis",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Pogledaj potpis",
  Printed_Signature:"Ispisani potpis",
  Digitally_Sign:"Digitalni potpis",
  Digital_Signature:"Digitalni potpis",
  Add_Signature:"Dodaj potpis",
  Add_Your_Signature:"Dodajte svoj potpis",
  Enable_Signature:"Omogući potpis",
  Digitally_Signed:"Digitalno potpisan",
  Insert_Error:"Nije moguće spremiti ček zbog problema s bazom podataka.",
  Delete_Confirmation:"Jeste li sigurni da želite izbrisati ove podatke?",
  Discard_Confirmation:"Jeste li sigurni da želite odbaciti ove informacije?",
  Discard_Bank_Confirmation:"Jeste li sigurni da želite odbaciti ovaj račun?",
  Discard_Printer_Confirmation:"Jeste li sigurni da želite odbaciti ovaj pisač?",
  Delete_Bonus_Confirmation:"Jeste li sigurni da želite izbrisati ovaj bonus?",
  Delete_Invoice_Confirmation:"Jeste li sigurni da želite izbrisati ovu fakturu?",
  Generated_Cheque:"Generirana provjera",
  Generated_Invoice:"generirana faktura",
  Schedule_Start_Time:"Početak rasporeda",
  Schedule_End_Time:"Završetak rasporeda",
  New_Call:"Novi poziv",
  No_Contacts:"Nema kontakata",
  No_Contacts_Word:"Administratori, moderatori, zaposlenici i primatelji plaćanja pojavljuju se kao kontakti.",
  PDF_Subject:"finansije",
  PDF_Keywords:"Pyroll Paycheck PDF check čekovi",
  Printer_Setup:"Podešavanje pisača",
  Printer_Selection:"Odabir pisača",
  New_Fax:"Novi faks",
  New_Fax_Message:"Nova faks poruka",
  Fax_Machine:"Faks uređaj",
  Fax_Name:"Naziv faksa",
  Fax_Email:"Faks e-pošta",
  Fax_Number:"Broj faksa",
  Contents:"Sadržaj",
  Fax_Body:"Tijelo stranice",
  Header_Word:"Zaglavlje:",
  Header_Text:"Tekst zaglavlja",
  Include_Header:"Uključi zaglavlje",
  Include_Footer:"Uključi podnožje",
  Footer_Word:"Podnožje:",
  Footer_Text:"Tekst u podnožju",
  Attach_a_Cheque:"Priloži ček",
  Add_Deduction:"Dodaj odbitak",
  Enable_Fax:"Pošalji faks",
  Select_Fax:"Odaberi faks",
  No_Faxes:"Bez faksova",
  Faxes:"faksovi",
  Save_and_Send:"Pošalji faks",
  Save_and_Pay:"Uštedi i plati",
  WARNING:"UPOZORENJE:",
  Remember:"Zapamtiti",
  Printing:"Ispis",
  Printing_Complete:"Ispis dovršen!\n\n",
  of:"od",
  There_Were:"Bilo je",
  Successful_Prints:" uspješni otisci i ",
  Unsuccessful_Prints:"neuspjeli ispisi.",
  PrinterError:"Oprostite! Došlo je do pogreške.",
  Printing_:"Ispis...",
  PrinterSuccess:"Dokument je uspješno ispisan.",
  PrintersSuccess:"Dokumenti su uspješno ispisani.",
  Print_Cheques:"Ispis čekova",
  New_Message:"Nova poruka",
  New_Messages:"Nove poruke",
  Read_Message:"Pročitaj poruku",
  Write_Message:"Napiši poruku",
  Send_Message:"Pošalji poruku",
  Subject:"Predmet",
  Message:"Poruka",
  Writing:"Pisanje...",
  Send:"Poslati",
  Set_Date:"Postavi datum",
  Set_Time:"Postavi vrijeme",
  Recieve:"Primi",
  Set_as_Default:"Postavi kao zadano",
  Default_Account:"Zadani račun",
  Default_Design:"Zadani dizajn",
  Multiple_Cheques:"Provjere (trostruki)",
  Account_Mode:"Način računa",
  Multiple_Cheques_Description:"Tri čeka po stranici.",
  Check_and_Table:"Check & Table",
  Check_including_Table:"Ček i računovodstveni stol.",
  Check_Mailer:"Provjeri mailer",
  Check_Mailer_Window:"Provjeri s adresnim prozorom.",
  DocuGard_Table_Top:"DocuGard provjera i stol (vrh)",
  DocuGard_Table_Middle:"DocuGard provjera i tablica (sredina)",
  DocuGard_Table_Bottom:"DocuGard provjera i tablica (dolje)",
  DocuGard_Mailer_Top:"DocuGard Check Mailer (vrh)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (srednji)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mailer (dolje)",
  DocuGard_Three_Cheques:"DocuGard provjere (trostruki)",
  DocuGard_Cheque_Top:"DocuGard Check (vrh)",
  DocuGard_Cheque_Middle:"DocuGard Check (sredina)",
  DocuGard_Cheque_Bottom:"DocuGard Check (dolje)",
  DocuGard_Three_Cheques_Window:"Tri čeka na jednoj stranici.",
  DocuGard_Table_Top_Window:"Tablica čekova i zarade.",
  DocuGard_Table_Middle_Window:"Tablica čekova i zarade.",
  DocuGard_Table_Bottom_Window:"Tablica čekova i zarade.",
  DocuGard_Mailer_Top_Window:"Ček, stol i adresa.",
  DocuGard_Mailer_Middle_Window:"Ček, stol i adresa.",
  DocuGard_Mailer_Bottom_Window:"Ček, stol i adresa.",
  DocuGard_Cheque_Top_Window:"Provjeri siguran papir.",
  DocuGard_Cheque_Middle_Window:"Provjeri siguran papir.",
  DocuGard_Cheque_Bottom_Window:"Provjeri siguran papir.",
  Basic_Cheque:"Provjera (vrh)",
  Basic_Cheque_Print:"Ispiši jedan ček.",
  Error_Setting_As_Paid:"Pogreška pri postavljanju kao plaćeno",
  Add_Attachment:"Dodaj privitak",
  PrinterUnavailable:"Printer nedostupan",
  CreditCardComponent:"Kartice",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Novi depozit",
  Deposits:"Depoziti",
  No_Deposits:"Bez depozita",
  Credit_Card_Deposit:"Kreditna kartica",
  New_Credit_Card_Deposit_Message:"Depozit kreditne kartice",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin depozit",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Interac Transfer",
  Payments_Limit:"Ograničenje plaćanja",
  No_Payment_Limit:"Bez ograničenja plaćanja",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal depozit",
  No_Deposits_Word:"Dodaj prvu tvrtku <a routerLink='/folder/Deposit/New'>Polog</a>.",
  No_Documents_Specified:"Nisu navedeni dokumenti za ispis",
  No_Printers_Added:"Nije pronađen nijedan pisač. Idite na Postavke > Pisači da biste ga dodali.",
  DB_Erase_Prompt:"Potpuno izbrisati cijelu bazu podataka? UPOZORENJE: Izgubit ćete sve pohranjene podatke!",
  Console_Warning:"Ne lijepite nikakav tekst u ovu konzolu. Možete izložiti sebe i/ili svoju tvrtku ozbiljnom riziku.",
  No_Faxes_Word:"Stvorite prvi <a routerLink='/folder/Fax/New'>Faks</a>.",
  Cheque_Delete_Confirmation:"Jeste li sigurni da želite izbrisati ovaj ček?",
  Design_Delete_Confirmation:"Jeste li sigurni da želite izbrisati ovaj dizajn?",
  Cheque_Pay_Confirmation:"Označiti ovaj ček kao plaćen? Neće se pojaviti u redu čekanja za ispis.",
  Payment_Pay_Confirmation:"Označiti ovo plaćanje kao plaćeno? Neće se pojaviti u redu čekanja.",
  Delete_Deduction_Confirmation:"Jeste li sigurni da želite izbrisati ovaj odbitak?",
  Delete_Job_Confirmation:"Jeste li sigurni da želite izbrisati ovaj posao?",
  Delete_Timesheet_Confirmation:"Jeste li sigurni da želite izbrisati ovaj raspored?",
  Delete_Schedule_Confirmation:"Jeste li sigurni da želite izbrisati ovaj raspored?",
  Delete_Setting_Confirmation:"Jeste li sigurni da želite resetirati ovu postavku?",
  Delete_Fax_Confirmation:"Jeste li sigurni da želite izbrisati ovaj faks?",
  Delete_File_Confirmation:"Jeste li sigurni da želite izbrisati ovu datoteku?",
  Delete_Vacation_Confirmation:"Jeste li sigurni da želite izbrisati ovaj odmor?",
  Delete_Printer_Confirmation:"Jeste li sigurni da želite izbrisati ovaj pisač?",
  Remove_Design_Confirmation:"Jeste li sigurni da želite izbrisati ovaj dizajn?",
  Delete_Payroll_Confirmation:"Jeste li sigurni da želite izbrisati ovaj platni spisak?",
  Send_Fax_Email_Confirmation:"Želite li poslati ove dokumente faksom i e-poštom?",
  Send_Email_Confirmation:"Želite li poslati ovaj dokument e-poštom?",
  Send_Fax_Confirmation:"Želite li faksirati ovaj dokument?",
  Error_Generating_PDF:"Oprostite. Došlo je do pogreške prilikom generiranja ovog dokumenta.",
  PDF_Error_Saving_Image:"Oprostite. Došlo je do pogreške prilikom spremanja PDF slike ovog dokumenta.",
  Test_Printer_Confirmation:"Želite li ispisati probnu stranicu na ovom pisaču?",
  Save_Timesheet_Prompt:"Molimo dodajte 'Naslov' ili pritisnite 'Pokreni mjerač vremena' za spremanje.",
  Remove_Geofence_Prompt:"Jeste li sigurni da želite ukloniti lokaciju ove geo-ograde?",
  Delete_Employee_Confirmation:"Jeste li sigurni da želite izbrisati",
  Fire_Employee_Confirmation:"Jesi li siguran da želiš pucati"
}