export const Ca = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Copyright &copy; 2023",
  black:"Negre",
  green:"Verd",
  gold:"Or",
  blue:"Blau",
  brown:"Marró",
  purple:"Porpra",
  pink:"Rosa",
  red:"Vermell",
  Swatches:"Mostres",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Hue",
  Saturation:"Saturació",
  Lightness:"Lleugeresa",
  Upgrade_To_Pro:"Actualitza a pro",
  AllFeaturesInclude:"Totes les característiques inclouen:",
  PrintUnlimitedCheques:"Imprimeix xecs il·limitats",
  PremiumChequeDesigns:"Dissenys Premium Check",
  ManageUnlimitedEmployees:"Gestioneu un nombre il·limitat d\'empleats",
  AddUnlimitedPayees:"Afegeix beneficiaris il·limitats",
  CreateUnlimitedPayrolls:"Crea nòmines il·limitades",
  UnlimitedSchedulesandTimeSheets:"Horaris i fulls de temps il·limitats",
  BulkPayPalPayouts:"Pagaments a granel de PayPal",
  UnlimitedBankAccounts:"Comptes bancaris il·limitats",
  ManageMultipleCompanies:"Gestionar diverses empreses",
  TrackInsurancePolicies:"Seguiment de pòlisses d\'assegurança",
  Bio_MetricProtection:"Protecció Biomètrica",
  Geo_FenceLock_OutProtection:"Protecció de bloqueig de Geo-Fence",
  Multiple_Companies_Word:"La gestió de diverses empreses no està disponible sense xecs premium.",
  PayPal_Payouts_Word:"Els pagaments de PayPal estan desactivats sense la prima de xecs.",
  PINProtection:"Protecció PIN",
  PasswordProtection:"Protecció amb contrasenya",
  May_Require_Approval:"Pot requerir aprovació.",
  Subscribe:"Subscriu-te",
  Billed:"Facturat",
  Up:"Amunt",
  Down:"Avall",
  Positioning:"Posicionament",
  Marker:"Marcador",
  Drag_Marker:"Arrossega el marcador",
  Tagline:"Imprimeix xecs i tabula la nòmina",
  Marker_Word:"Utilitzeu els marcadors per dimensionar l'element.",
  Pinch_Zoom:"Zoom de pessic",
  Pinch_Word:"Pessigueu per ampliar l'element.",
  Drag:"Arrossegueu",
  Drag_Word:"Utilitzeu el dit per arrossegar elements.",
  subscription_alias_word:"Subscripció de renovació automàtica",
  premium_alias_word:"Paquet d'actualització única",
  print_alias_word:"Impressió de xecs individuals",
  mode_alias_word:"Mode",
  Pro:"Pro",
  Pro_word:"Es requereix la versió Pro.",
  Cant_Delete_Default_Company_Word:"Ho sentim, no pots suprimir la teva empresa predeterminada.",
  Reinsert_Default_Designs:"Torneu a inserir els dissenys predeterminats",
  Reinsert_Default_Designs_word:"Voleu tornar a inserir els dissenys predeterminats?",
  Subscription_Active_Disable_Word:"Aquesta subscripció està activa. Vols cancel·lar aquesta subscripció als xecs?",
  Company_Logo:"Logotip de l'empresa",
  ZERO_:"ZERO",
  Disclaimer:"Exempció de responsabilitat",
  Privacy_Policy:"Política de privacitat",
  EULA:"Comprova el CLUF",
  Terms_Of_Service:"Termes del servei",
  Terms_Of_Use:"Condicions d'ús",
  Refunds:"Política de reemborsament",
  Single_Print:"1 Comprova",
  Two_Prints:"2 xecs",
  Five_Prints:"5 xecs",
  Ten_Prints:"10 xecs",
  Fifteen_Prints:"15 xecs",
  Twenty_Prints:"20 xecs",
  Thirty_Prints:"30 xecs",
  Image_Added:"Imatge afegida",
  Image_Preview:"Vista prèvia de la imatge",
  No_Image_Was_Selected:"No s'ha seleccionat cap imatge.",
  Cheques_Unlimited:"xecs il·limitats",
  _Subscription:"Subscripció",
  Subscription:"xecs - 1 mes",
  Two_Month_Subscription:"xecs - 2 mesos",
  Three_Month_Subscription:"xecs - 3 mesos",
  Six_Month_Subscription:"xecs - 6 mesos",
  Twelve_Month_Subscription:"xecs - 12 mesos",
  Cheques_Are_Available:"Els xecs estan disponibles per imprimir.",
  Upgrade_Required_Two:"Seleccioneu un paquet i feu doble toc al botó de preu per començar la vostra compra. Imprimiu xecs a tot color d'aspecte professional en qüestió de segons.",
  Month:"Mes",
  Due:"Degut:",
  Expires:"Caduca:",
  Subscription_Active:"Subscripció activa",
  Subscription_Inactive:"Subscripció inactiva",
  Purchase_Additional_Cheques:"Comprar xecs addicionals?",
  Printable_Cheque:"Cheque imprimible",
  Printable_Cheques:"Xecs imprimibles",
  Printable_Cheque_Word:"el xec està disponible al vostre compte.",
  Printable_Cheques_Word:"els xecs estan disponibles al vostre compte.",
  Max_Amount_Message:"L'import que heu especificat ha arribat a l'import màxim establert per a aquest sistema:",
  Terms_Required_Word:"Heu d'acceptar aquest acord abans de continuar utilitzant els xecs.",
  Subscriptions:"Subscripcions",
  Product_Upgrades:"Actualitzacions",
  Mailed_Out_Cheques:"xecs enviats per correu",
  Enter_A_Company_Name:"Introduïu el nom d'una empresa.",
  Single_Cheques:"Controls únics",
  Cheque_Golden:"Check Daurat",
  Cheque_Golden_Window:"Disseny de quadres daurats.",
  Cheque_Green:"Check verd",
  Cheque_Green_Window:"Disseny de xec verd.",
  Cheque_Red:"Cheque vermell",
  Cheque_Red_Window:"Disseny de xecs vermells.",
  Cheque_Yellow:"Check groc",
  Cheque_Yellow_Window:"Disseny de quadre groc.",
  Cheque_Statue_of_Liberty:"Estàtua de la Llibertat",
  Cheque_Statue_of_Liberty_Window:"Disseny de xecs de l'Estàtua de la Llibertat.",
  Cheque_Green_Wave:"Onada Verda",
  Cheque_Green_Wave_Window:"Disseny de xec verd.",
  Cheque_Golden_Weave:"Teixit daurat",
  Cheque_Golden_Weave_Window:"Disseny elegant de quadres daurats.",
  Cheque_Green_Sun:"Sol Verd",
  Cheque_Green_Sun_Window:"Disseny de control profund i calmant.",
  Cheque_Blue_Checkers:"Dames blaves",
  Cheque_Blue_Checkers_Window:"Disseny de quadres blaus.",
  Cashiers_Check:"Xec de caixa",
  Cashiers_Check_Window:"Plantilla d'estil de xec de caixa.",
  Cheque_Aqua_Checkers:"Damas Aqua",
  Cheque_Aqua_Checkers_Window:"Disseny Aqua Check.",
  Cheque_Golden_Checkers:"Dames daurades",
  Cheque_Golden_Checkers_Window:"Disseny de quadres daurats.",
  Upgrade_Unavailable:"Actualitzacions no disponibles",
  Bank_Code_Protection:"Protecció del número bancari",
  Bank_Code_Protection_Word:"Protegiu els vostres números bancaris perquè no s'utilitzin en aquesta aplicació que s'executa en un altre dispositiu o per a un altre usuari. Aquesta acció és IRREVERSIBLE. Continuar?",
  Bank_Code_Protection_Blocked_Word:"Els números bancaris que intenteu utilitzar es reserven per a un altre usuari o dispositiu.",
  Bank_Code_Protection_Error_Word:"La verificació del número ha fallat. Connecteu-vos a Internet i torneu a provar d'afegir aquest compte bancari.",
  Bank_Code_Protection_Set_Error_Word:"La protecció del número bancari requereix que estigueu connectat a Internet. Connecta't i torna-ho a provar.",
  Upgrade_Unavailable_Word:"Ho sentim, estem tenint problemes per verificar-te. Actualment, les subscripcions i les actualitzacions de xecs no estan disponibles per a la compra a la vostra zona.",
  PayPal_Payment_Preview:"Vista prèvia del pagament de PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Seleccioneu PayPal",
  PayPal_Applications:"Aplicacions de PayPal",
  Purchase_With_Apple_Pay:"Compra amb Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Empreses",
  Insurance:"Assegurança",
  New_PayPal:"PayPal nou",
  Pay_By:"Pagar per",
  Insure:"Assegurar",
  Miles:"Milles",
  Payment_Method:"Mètode de pagament",
  Select_Policies:"Seleccioneu Polítiques",
  Policies:"Polítiques",
  Policy:"Política",
  No_PayPal_Account:"Sense compte de PayPal",
  No_Policies:"Sense pòlisses d'assegurança",
  New_Policy:"Nova política",
  PayPal_Payment:"Pagament PayPal",
  PayPal_Payments:"Pagaments de PayPal",
  No_Payment_Selected:"No s'ha seleccionat cap pagament",
  Sending_Payment_Word:"Espereu... S'està enviant aquest pagament.",
  Sending_Payments_Word:"Espereu... S'estan enviant pagaments.",
  No_Payment_Selected_PayPal:"No s'ha seleccionat cap <a routerLink='/folder/Payments'>pagament de PayPal</a> per enviar.",
  Payment_Sent:"Pagament enviat",
  PayPal_Payment_Sent:"Aquest pagament s'ha enviat amb PayPal.",
  Copay:"Copago",
  Dead:"Mort",
  Alive:"Viu",
  Not_Dead:"No Mort",
  Unclaimed:"No reclamat",
  Attempted:"Intentat",
  Deceased:"Difunt",
  Claimed:"Reclamat",
  Unpaid:"Sense pagar",
  Sending_Payment:"Enviament de pagament",
  Sending_Payments:"Enviament de pagaments",
  Send_PayPal_Confirmation:"Vols enviar aquesta transacció amb PayPal?",
  Send_PayPal_Confirmation_Word:"Premeu el botó verd per enviar aquesta transacció.",
  Save_Payment_As_Unpaid:"Deseu aquest pagament com a no pagat?",
  Payment_Pay_Confirmation_PayPal:"Deseu aquest pagament com a pagat?",
  No_Policies_Word:"Afegiu ara la primera <a routerLink='/folder/Postage/New'> Política d'assegurança</a>.",
  Timesheet_Multiple_Delete_Confirmation:"Esteu segur que voleu suprimir diversos fulls de temps?",
  Select_Multiple_Timesheets_Prompt:"No s'han seleccionat fulls de temps. Seleccioneu almenys un full de temps.",
  Select_Multiple_Policies_Prompt:"No s'ha seleccionat cap política. Seleccioneu almenys una pòlissa d'assegurança.",
  Policies_Multiple_Delete_Confirmation:"Esteu segur que voleu suprimir diverses polítiques?",
  Company:"Companyia",
  Add_Company:"Afegeix empresa",
  New_Company:"Afegeix empresa",
  No_Companies:"No hi ha empreses",
  Enable_Company:"Habilita l'empresa",
  Select_Company:"Seleccioneu Empresa",
  The_Default_Company:"L'etiqueta d'empresa predeterminada.",
  Manage_Companies:"Gestionar Empreses",
  No_Companies_Word:"Les comprovacions utilitzaran una empresa predeterminada.<br />Afegiu la <a routerLink='/folder/Company/New'>primera empresa</a>.",
  Default_Company:"Empresa per defecte",
  Cheques_Unlimited_Word:"Checks Unlimited us permet imprimir tants xecs com vulgueu.",
  Cheques_Subscription_Word:"Una subscripció de xecs us permet imprimir tants xecs com vulgueu.",
  You_Own_This_Product:"Vostè és el propietari d'aquest producte.",
  Your_Subscription_is_Active:"La teva subscripció està activa.",
  Active_Products:"Productes activats",
  Purchase_Confirmation:"Compra",
  Purchase_Cheques:"Cheques de compra",
  Restore_Purchase:"Restaurar les compres",
  Erase_Purchase:"Esborra compres",
  Successfully_Purchased:"Comprat amb èxit",
  Enter_Your_Licence_Key:"Introduïu la vostra clau de llicència en aquesta pàgina per activar aquest producte.",
  Licence_Key:"Clau de llicència",
  Enter_Licence_Key:"Introduïu la clau de llicència",
  Purchased:"Comprat",
  Enable_Feature:"Activa la funció",
  Cancel_Subscription:"Cancel·la la subscripció",
  Subscription_Removed:"S'ha suprimit la subscripció",
  Select_Active_Subscription:"Seleccioneu una subscripció activa per modificar-la.",
  Remove_Subscription_Word:"Confirmes que vols cancel·lar aquesta subscripció?",
  Delete_Company_Confirmation:"Esteu segur que voleu suprimir tota l'empresa? ADVERTÈNCIA: perdràs tota la informació emmagatzemada!",
  Delete_Default_Company_Word:"No podeu eliminar l'empresa predeterminada. Voleu restablir l'aplicació i restaurar-la a l'estat predeterminat? ADVERTÈNCIA: perdràs tota la informació emmagatzemada!",
  Console_Warning_2:"No manipuleu cap moneda utilitzant aquesta aplicació que actualment no és la vostra.",
  Terms_and_Conditions:"Termes i condicions",
  and_the:"i la",
  for:"per",
  Please_Read_Word:"Si us plau, llegiu i accepteu",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"No s'han pogut trobar alguns tipus de conversió de moneda. Si us plau, connecteu-vos a Internet.",
  Free:"Gratuït",
  DB_Erase_Prompt_2:"Esborrar completament tota la base de dades de desenvolupadors? ADVERTIMENT: perdràs tota la informació de COMPRA i SUBSCRIPCIÓ!",
  Successfully_Imported:"Importat correctament",
  Select_Postage:"Seleccioneu Franqueig",
  No_Postage:"Sense segells postals",
  No_Paid_Postage_Word:"Afegiu el primer segell <a routerLink='/folder/Postage/New'>Enviament de pagament</a>.",
  Trial_Complete:'Prova completada',
  Please_Upgrade:'Si us plau, actualitzeu els xecs per continuar imprimint.',
  Aa:"Aa",
  Color:"Color",
  Font:"Font",
  Guide:"Guia",
  Guides:"Guies",
  Image:"Imatge",
  Zoom:"Zoom",
  Logo:"Logotip",
  Bank:"Banc",
  MICR:"MICR",
  Total:"Total",
  Cancel:"Cancel · lar",
  Confirm:"Confirmeu",
  Method:"Mètode",
  Biometric_Security:"Seguretat biomètrica",
  Please_Login_To_Continue:"Si us plau, inicieu sessió per continuar.",
  Complete:"Completa",
  Sign_Up:"Registra't",
  Error:"Error",
  Biometrics:"Biomètrica",
  Percent:"Per cent",
  Zero_Percent:"0%",
  Top_Margin:"Marge superior",
  Bottom_Margin:"Marge inferior",
  Left_Margin:"Marge esquerre",
  Right_Margin:"Marge dret",
  MICR_Margin:"Marge MICR",
  Table_Margin:"Marge de la taula",
  Address_Margin:"Marge d'adreça",
  Percentage_:"Percentatge",
  Vacation_Title:"Títol de vacances",
  Use_PIN:"Utilitza el PIN",
  Loading__:"Carregant...",
  Design_Title:"Títol del disseny",
  Authorize:"Autoritzar",
  Key:"clau",
  Public_Key:"Clau pública",
  Private_Key:"Clau privada",
  Authenticate:"Autenticar-se",
  Last_Payroll:"Última nòmina",
  Last_Calculation:"Últim càlcul",
  Authorized:"Autoritzat",
  Geo_Authorized:"Geo-Ubicació: Autoritzat",
  Not_Authorized:"No autoritzat",
  Authorization_Complete:"Autorització completa",
  Geolocation_Authorization:"Autorització de geolocalització",
  Out_of_Bounds:"Fora dels límits",
  Cant_Delete_Default_Design:"No es pot suprimir un disseny predeterminat.",
  Unauthorized_Out_of_Bounds:"No autoritzat: fora de límits",
  Biometric_Authorization:"Autorització biomètrica",
  Locating_Please_Wait:"Localitzant, espereu...",
  Test_Biometrics:"Prova de biomètrica",
  Cheque_Margins:"Comproveu els marges",
  Percentage_Full_Error:"El camp de percentatge no es pot configurar per sobre del 100% per cent.",
  No_Payroll_Text:"Afegiu un <a routerLink='/folder/Employee/New'>Empleat</a> o <a routerLink='/folder/Payee/New'>Beneficiari</a> i un <a routerLink='/folder/Schedule/New'>Programació</a>.",
  Design_Saved:"Disseny guardat",
  Default_Design_Selected:"Disseny per defecte seleccionat",
  Partial_Import:"Importació parcial",
  Partial_Export:"Exportació parcial",
  Entire_Import:"Importació sencera",
  Entire_Export:"Exportació sencera",
  Existing_Password:"Introduïu la vostra contrasenya existent:",
  Existing_PIN:"Introduïu el vostre codi PIN existent:",
  Pin_Change_Header:"Confirmació del PIN",
  Pin_Change_SubHeader:"Introduïu el vostre número PIN antic per confirmar el canvi.",
  Pass_Change_Header:"Confirmació de la contrasenya",
  Pass_Change_SubHeader:"Introduïu la vostra contrasenya antiga per confirmar el canvi.",
  PIN_Enter_Message:"Introduïu el vostre PIN per confirmar.",
  Password_Enter_Message:"Introduïu la vostra contrasenya per confirmar.",
  Pin_Updated_Success:"El PIN s'ha actualitzat correctament!",
  Pin_Updated_Fail:"No s'ha pogut actualitzar el PIN.",
  Pass_Updated_Success:"La contrasenya s'ha actualitzat correctament.",
  Pass_Updated_Fail:"No s'ha pogut actualitzar la contrasenya.",
  Preview_Payment:"Vista prèvia del pagament",
  Preview_Payroll:"Vista prèvia de la nòmina",
  No_Payments_Created:"No s'ha trobat cap pagament per crear.",
  Payment_Preview:"Vista prèvia del pagament",
  Enable_Payee:"Habilita el beneficiari",
  Rendered:"Renderitzat",
  No_Email:"Sense correu electrònic",
  Setup_Cheques:"Comprovacions de configuració",
  name:"Nom",
  address:"adreça",
  address_2:"Direcció Línia 2",
  city:"ciutat",
  province:"Província",
  postal_code:"Codi postal",
  country:"País",
  username:"Nom d'usuari",
  full_name:"Nom complet",
  birthday:"Aniversari",
  email:"Correu electrònic",
  phone:"Telèfon",
  employees:"Empleats",
  addresses:"Adreces",
  payment_amount_limit:"Límit d'import del pagament",
  total_limit:"Límit total",
  payees:"Beneficiaris",
  description:"Descripció",
  address_line_one:"Línia d'adreça 1",
  address_line_two:"Línia d'adreça segona",
  image:"Imatge",
  account_holder:"Titular del compte",
  cheque_starting_id:"Comproveu l'identificador inicial",
  transit_number:"Número de trànsit",
  institution_number:"Número d'entitat",
  designation_number:"Número de designació",
  account_number:"Número de compte",
  currency:"Moneda",
  signature:"Signatura",
  payment_payroll_limit:"Límit de pagament",
  total_limi:"Límit total",
  date:"Data",
  printed_memo:"Memo imprès",
  banks:"Bancs",
  signature_image:"Imatge de la signatura",
  address_name:"Nom de l'adreça",
  notes:"Notes",
  design:"Disseny",
  title:"Títol",
  frequency:"Freqüència",
  fax:"Fax",
  salaries:"Salaris",
  salary_ids:"Identificadors salarials",
  start_time:"L'hora d'inici",
  end_time:"Fi del temps",
  paid:"Pagat",
  overtime_percentage:"Percentatge pagat",
  overtime_amount:"Import fix pagat",
  first_name:"Nom",
  last_name:"Cognom",
  moderation:"Moderació",
  create:"Crear",
  edit:"Edita",
  destroy:"Destruir",
  day_start_time:"Dia_hora_inici",
  day_end_time:"Dia_hora_final",
  fullname:"Nom",
  time:"Temps",
  auto_send:"Envia automàticament",
  time_method:"Mètode del temps",
  schedules:"Horaris",
  indefinite_payroll_enabled:"Activa indefinidament",
  amount:"Import",
  repeat:"Repetiu",
  always_enabled:"Sempre activat",
  start_date:"Data d'inici",
  end_date:"Data de finalització",
  Cheque_Total:"Comprova el total",
  Total_Amount:"Quantitat total:",
  Amounts:"Imports:",
  Images:"Imatges",
  Files:"Fitxers",
  Previewing:"Previsualització:",
  Insert:"Insereix",
  Preview_Import:"Vista prèvia d'importació",
  Entry:"Entrada",
  Entries:"Entrades",
  No_Entries:"No hi ha entrades",
  Import_Type:"Tipus d'importació",
  Select_Details:"Seleccioneu Detalls",
  Select_Payee:"Seleccioneu Beneficiari",
  Enable_Holidays:"Activa les vacances",
  Disable_Holidays:"Desactiva les vacances",
  Wire_Transfer:"Transferència bancària",
  New_Export:"Nova exportació",
  Export_Data:"Exporta dades",
  Export_Data_Word:"Seleccioneu el tipus de fitxer que voleu exportar i descarregar.",
  Export_File:"Exportar fitxer",
  Mode:"Mode",
  Times:"Temps",
  Widgets:"Ginys",
  Slider:"Control lliscant",
  Set_Details:"Establir detalls",
  Select_Type:"Seleccioneu Tipus",
  Display_Slider:"Mostra el control lliscant",
  Dashboard_Slider:"Control lliscant del tauler de control",
  Dashboard_Mode:"Mode de tauler",
  Show_Intro:"Mostra la introducció",
  Show_Payrolls:"Mostra les nòmines",
  Show_Holidays:"Mostra Vacances",
  Show_Invoices:"Mostra les factures",
  Show_Cheques:"Mostra els xecs",
  Enabled_Widgets:"Ginys activats",
  Disabled_Widgets:"Ginys desactivats",
  Colors:"Colors",
  Barcodes:"Codis de barres",
  View_Timers:"Veure temporitzadors",
  Gradients:"Gradients",
  No_Info:"Sense informació",
  Disable:"Desactivar",
  Show_Layer:"Mostra les capes",
  Hide_Layer:"Amaga les capes",
  Text_Layer:"Capes de text",
  Secondly:"En segon lloc",
  Minutely:"Minutament",
  nine_am:"09 A.M",
  five_pm:"17:00",
  Enable_Address:"Activa l'adreça",
  Invalid_File_Type:"Ho sentim, s'ha seleccionat un tipus de fitxer no vàlid. Tipus de fitxer compatible:",
  Error_Updating_Entry:"Ho sentim, s'ha produït un error en actualitzar aquesta entrada.",
  Schedule_Timing_Alert:"Error: l'hora d'inici de la programació s'estableix en un valor posterior a l'hora de finalització.",
  Select_Multiple_Payments_Prompt:"No s'ha seleccionat cap pagament. Seleccioneu almenys un pagament.",
  Select_Multiple_Cheques_Prompt:"No s'ha seleccionat cap xec. Seleccioneu almenys un xec.",
  Select_Multiple_Items_Prompt:"No s'ha seleccionat cap element. Seleccioneu almenys un element.",
  Paymemt_Multiple_Delete_Confirmation:"Esteu segur que voleu suprimir diversos pagaments?",
  Cheque_Multiple_Delete_Confirmation:"Esteu segur que voleu suprimir diversos xecs?",
  Payee_Multiple_Delete_Confirmation:"Esteu segur que voleu suprimir diversos beneficiaris?",
  Employee_Multiple_Delete_Confirmation:"Esteu segur que voleu suprimir diversos empleats?",
  MICR_Warning:"Nota: És possible que algunes impressores i dispositius no mostrin correctament el tipus de lletra MICR.",
  Automatically_Send:"Envia automàticament",
  Type:"Tipus",
  Payment_Type:"Tipus de pagament",
  Auto_Send:"Enviament automàtic",
  Automatically_Process:"Processa automàticament",
  Auto_Process:"Procés automàtic",
  Image_Based:"Basat en imatges",
  Font_Based:"Basat en fonts",
  Rerender:"Torna a renderitzar",
  Rendering:"Renderització",
  Render:"Fitxers",
  Top:"Superior",
  Middle:"Mig",
  Bottom:"A baix",
  Top_Left:"A dalt a l'esquerra",
  Top_Center:"Centre superior",
  Top_Right:"Dalt a la dreta",
  Middle_Left:"Mitjà Esquerra",
  Middle_Center:"Centre mitjà",
  Middle_Right:"Mitjà Dreta",
  Bottom_Left:"Baix a l 'esquerra",
  Bottom_Center:"Centre inferior",
  Bottom_Right:"Abaix a la dreta",
  Numbers:"Nombres",
  Verified:"Verificat",
  Paper_Size:"Mida del paper",
  New_Device:"Nou dispositiu",
  Add_Device:"Afegeix un dispositiu",
  Remove_Device:"Elimina el dispositiu",
  Delete_Device:"Suprimeix el dispositiu",
  Block_Device:"Bloqueja el dispositiu",
  Block:"Bloc",
  Unblock:"Desbloqueja",
  Table:"Taula",
  Scan_Login_Code:"Escaneja el codi d'inici de sessió",
  Login_Code:"Codi d'inici de sessió",
  Scan_Code:"Escaneja el codi",
  Scan_QR_Code:"Escaneja el codi QR",
  Select_All:"Seleccionar tot",
  Deselect_All:"Deseleccionar tot",
  Increase:"Augmentar",
  Decrease:"Disminuir",
  Bold:"Atrevit",
  Text:"Text",
  Style:"Estil",
  Italic:"Cursiva",
  QR_Code:"Codi QR",
  Barcode:"Codi de barres",
  Browse_Images:"Navega per imatges",
  Browse_Files:"Examinar fitxers",
  Background_Image:"Imatge de fons",
  Logo_Image:"Imatge del logotip",
  Header_Image:"Imatge de capçalera",
  Bank_Image:"Imatge del banc",
  Cut_Lines:"Línies de tall",
  Background:"Fons",
  License:"llicència",
  One_License:"1 llicència:",
  Licensed:"Llicència per a:",
  Not_Licensed:"No amb llicència",
  Enter_Serial:"Introduïu la sèrie",
  Serial_Key:"Clau de sèrie",
  Serial:"Serial",
  Product_Key:"Clau del producte",
  Check_Product_Key:"Comproveu la clau del producte",
  Add_Product_Key:"Afegeix una clau de producte",
  Verifying_Purchase:"S'està verificant la compra...",
  Print_Envelope:"Imprimeix sobre",
  Envelope:"Sobre",
  Thank_You:"Gràcies!",
  Scale:"Escala",
  Print_Scale:"Escala d'impressió",
  Borders:"Fronteres",
  VOID:"BUIT",
  Void_Cheque:"Comprovació nul·la",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"PAGAR A LA COMANDA DE:",
  NO_DOLLARS:"SENSE DÒLARS ",
  ONE_DOLLAR:"UN DÒLAR",
  DOLLARS:" DÒLARS",
  AND:" I ",
  CENTS:" CÈNTIMAS.",
  NO_:"NO ",
  ONE_:"UNA ",
  AND_NO_:"I NO ",
  _AND_ONE_:"I UNA ",
  DOLLARS_AND_ONE_CENT:" I UN CENT.",
  AND_NO_CENTS:" I ZERO CÈNTIMAS.",
  CHEQUE_PRINT_DATE:"DATA:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Tasques inicials",
  Inital_Setup:"Configuració inicial",
  Welcome_To:"Benvingut a ",
  Welcome:"Benvingut",
  Swipe:"Llisca",
  Intro_Setup:"Configuració d'introducció",
  Introduction:"Introducció",
  CHEQUE_PRINT_CREDIT:"Funcionat amb xecs",
  Title_Intro_Word:"Benvingut als xecs",
  Title_Intro:"Introducció dels xecs",
  Title_Setup:"Configuració de xecs",
  PayPal_Default_Email_Message:"Heu rebut una nova transferència de PayPal. [Funcionat amb xecs]",
  Cheques_App_Export:"Exportat per xecs",
  Post_Intro_Thanks:"Gràcies per triar els xecs. El procés de configuració ja s'ha completat.",
  Post_Intro_Word:"Comenceu imprimint el vostre primer xec, afegir un pagament futur o afegir un empleat a la nòmina",
  Upgrade_Required:"Els xecs requereixen que tingueu una versió més premium del programari per amagar aquest missatge i desbloquejar funcions addicionals",
  Upgrade_Title:"Xecs",
  Mailout_Prompt:"A més, podeu optar perquè Checks enviï els vostres xecs de nòmina per correu electrònic",
  Mailed_Cheque:"Comprovació enviada per correu: ",
  Mailed_Cheque_Color:"Chec enviat per correu (color): ",
  Terms_Purchase:"Totes les compres electròniques amb xecs són totalment reemborsables durant un màxim de 30 dies a partir de la data de compra. Llegiu i accepteu els <a href='#'>Termes i condicions</a> abans de continuar",
  Dashboard_Setup:"Configuració de la impressora primària",
  Dashboard_Add:"Afegeix el compte bancari principal",
  Dashboard_Customize:"Seleccioneu una plantilla de comprovació",
  Dashboard_Job_Salary:"Crea la teva feina i afegeix el teu sou",
  Dashboard_Employees:"Seguiment dels empleats i dels beneficiaris",
  Dashboard_Print:"Imprimeix i envia els teus xecs de pagament",
  Dashboard_Payroll:"Automatitzeu la impressió de la nòmina",
  Dashboard_PayPal:"Configuració de nòmines / pagaments de PayPal",
  Begin_Setup:"Comença la configuració",
  Get_Started:"Començar",
  Purchase:"Compra",
  Lockdown:"Confinament",
  Unlock:"Descobrir",
  Detailed:"Detall",
  Log_In:"Iniciar Sessió",
  Login:"Iniciar Sessió",
  Launch:"Llançament",
  Register:"Registrar",
  Finish:"Acabar",
  List:"Llista",
  Weekends:"Caps de setmana",
  Weekly:"Semanal",
  PayPal_Default_Subject:"Nou pagament",
  Payment_Message:"Missatge de pagament",
  PayPal_Default_Payment_Note:"Gràcies",
  Setup_Your_Cheqing_Account:"Compte xec",
  Add_Your_Primary_Cheqing_Account:"Afegiu el vostre compte de xecs principal.",
  Welcome_Word:"Simplificar i automatitzar la gestió de nòmines i recursos humans",
  Get_Started_Quickly:"Només respon a unes quantes preguntes senzilles que ens ajudaran a començar...",
  Done_Intro_Word:"Configuració completa",
  PIN_Protected:"Contrasenya i PIN protegits",
  Enter_New_PIN:"Introduïu un codi PIN nou:",
  Enter_PIN:"Introdueix el codi PIN:",
  Invalid_PIN:"S'ha introduït un PIN no vàlid.",
  Account_Identifier:"Identificador del compte",
  New_Account_Identifier:"Identificador de compte nou",
  attempt:"intent",
  attempts:"intents",
  remaining:"resta",
  Language:"Llenguatge",
  languages:"Idiomes",
  select_languages:"Escolliu l'idioma",
  Deposit:"Dipòsit",
  Hire_One_Now:"Lloga un ara",
  App_Locked:"L'aplicació està bloquejada.",
  Skip_:"Omet",
  Skip_to_Dashboard:"Vés al tauler de control",
  Dashboard:"Panell",
  Import:"Importa",
  Admin:"Administradors",
  New_Admin:"Nou administrador",
  Settings:"Configuració",
  Color_Picker:"Selector de colors",
  Font_Picker:"Selector de fonts",
  Logout:"Tancar sessió",
  Close:"Tanca",
  Close_menu:"Tanca",
  Excel:"Fitxer Excel",
  Csv:"Fitxer CSV",
  Sql:"Fitxer SQL",
  Json:"Fitxer JSON",
  Url:"Importa per URL",
  Back:"Esquena",
  Timers:"Temporitzadors",
  Cheque:"Comprova",
  Print:"Imprimir",
  Designs:"Dissenys",
  Pause_Printing:"Pausa la impressió",
  Resume_Printing:"Reprèn la impressió",
  Printing_Paused:"Impressió en pausa",
  PrintingUnavailable:"Ho sentim! La impressió no està disponible en aquest sistema.",
  Prints_Paused:"Impressió en pausa",
  Administration:"Administració",
  Loading:"Càrrega",
  Unnamed:"Sense nom",
  error:"Ho sentim, aquest xec no s'ha pogut obrir per veure'l.",
  No_Cheques_To_Print:"No hi ha xecs per imprimir",
  No_Cheques_To_Print_Word:"Creeu un <a routerLink='/folder/Cheque/New'>Nou xec</a>.",
  New_Cheque:"Nou xec",
  Start_One_Now:"Comença un ara",
  Edit_Cheque:"Edita comprovació",
  Select_Cheques:"Seleccioneu xecs",
  Select_Employee:"Selecciona un empleat",
  Default_Printer:"Impressora per defecte",
  Reassign:"Reassignar",
  Configure:"Configurar",
  Template:"Plantilla",
  Designer:"Dissenyador",
  Edit_Designs:"Edita dissenys",
  New_Design:"Nou disseny",
  Next:"Pròxim",
  Save:"Desa",
  Name:"Nom",
  Mail:"Correu",
  Amount:"Quantitat",
  Date:"Data",
  PayPal:"PayPal",
  Payouts:"Pagaments",
  PayPal_Label:"Etiqueta de PayPal",
  Email_Username:"Correu electrònic / Nom d'usuari",
  Client_ID:"ID de client",
  Sandbox_Email:"Correu electrònic Sandbox",
  PayPal_Email:"Correu electrònic de PayPal",
  PayPal_Username:"Nom d'usuari de l'API",
  PayPal_Payouts:"Pagaments de PayPal",
  Select_PayPal_Key:"Selecciona la clau de PayPal",
  Secret:"Secret",
  API_Secret:"Secret de l'API",
  PayPal_API_Keys:"Claus de PayPal",
  New_PayPal_Key:"Nova clau de PayPal",
  Email_Subject:"Assumpte del correu electrònic",
  Email_Message:"Missatge de correu electrònic",
  Payout_Options:"Opcions de pagament",
  Payment_Note:"Nota de pagament",
  Enable_Employee:"Habilita l'empleat",
  Enable_Production_Mode:"Activa el mode de producció",
  Sandbox_Username:"Nom d'usuari de Sandbox",
  Sandbox_Signature:"Sandbox Signature",
  Sandbox_Password:"Contrasenya Sandbox",
  Production_Username:"Nom d'usuari de producció",
  Production_Signature:"Firma de producció",
  Production_Password:"Contrasenya de producció",
  Production_Email:"Correu electrònic de producció",
  API_Client_ID:"ID de client de l'API",
  API_Signature:"Signatura de l'API",
  API_Password:"Contrasenya de l'API",
  API_Username:"Nom d'usuari de l'API",
  Secret_Key:"Clau secreta",
  Sandbox:"Sandbox",
  Production:"Producció",
  Sandbox_Keys:"Tecles Sandbox",
  Production_Keys:"Claus de producció",
  API_Title:"Títol de l'API",
  Production_Mode:"Mode de producció",
  Account_Label:"Etiqueta del compte",
  No_PayPal_Setup:"Sense clau de PayPal",
  Enable_PayPal_Account:"Activa el compte de PayPal",
  No_PayPal_Word:"Afegiu la vostra <a routerLink='/folder/Invoice/New'>Clau de l'API de PayPal</a>.",
  Printed_Memo:"Memo imprès",
  Employee:"Empleat",
  View_Employee:"Veure l'empleat",
  Mailing_Address:"Adreça de correu",
  Company_Address:"Adreça de l'empresa",
  Select_Company_Address:"Seleccioneu l'adreça de l'empresa",
  Address:"Adreça",
  Any_Day:"Qualsevol dia",
  Address_Name:"Nom de l'adreça",
  Unit:"Unitat",
  Account:"Compte",
  Bank_Account:"Compte bancari",
  Account_Limits:"Activa els límits del compte",
  Payroll:"Nòmina",
  New_Payroll:"Nova nòmina",
  No_Payroll:"No hi ha nòmina propera",
  Upcoming_Holiday:"Properes vacances:",
  Invoice_Due:"Factura pendent:",
  New_Invoice:"Nova factura",
  No_Invoices:"Sense factures",
  No_Invoices_Word:"Creeu la primera <a routerLink='/folder/Invoice/New'>Factura</a>.",
  Cheque_Due:"Xec pendent:",
  Payrolls:"Nòmines",
  Sandbox_Mode:"Mode sandbox",
  Hire:"Llogar",
  Pay:"Pagar",
  New:"Nou",
  Add:"Afegeix",
  Make:"Fer",
  Time:"Temps",
  Write:"Escriure",
  Holiday:"Festa",
  Holidays:"Vacances",
  Next_Holiday:"Properes vacances:",
  All_Done:"Tot fet!",
  Employees:"Empleats",
  Payees:"beneficiaris",
  Job:"Treball",
  Jobs:"Ocupació",
  Invoice:"Factura",
  Invoices:"Factures",
  Vacations:"Vacances",
  Cheques:"Cheques",
  Brand_Cheques:"marca",
  Payment:"Pagament",
  Enable_Payment:"Activa el pagament",
  Payments:"Pagaments",
  Schedule:"Agenda",
  Schedules:"Horaris",
  Timesheet:"Horari",
  Timesheets:"Fulls de temps",
  Salary:"Sou",
  New_Address:"Nova adreça",
  Address_2:"Direcció Línia 2)",
  _City:"Ciutat",
  _State:"Estat/Prov",
  City:"Ciutat/Municipi",
  State:"Estat / Província",
  Postal:"Codi postal",
  ZIP:"Postal / ZIP",
  Country:"País",
  Addresses:"Adreces",
  Required_Options:"Opcions necessàries",
  Optional_Options:"Opcions opcionals",
  Additional_Options:"Opcions addicionals",
  Required:"Obligatori",
  Optional:"Opcional",
  Additional:"Adicional",
  No_Addresses:"Sense adreces",
  New_Address_Word:"Afegiu la primera <a routerLink='/folder/Address/New'>adreça</a>.",
  Select_Address:"Selecciona l'adreça",
  No_Address:"Sense adreces",
  Print_Cheque:"Imprimeix comprovació",
  Print_Cheque_Now:"Imprimeix la comprovació ara",
  Description:"Descripció",
  Pay_To_The_Order_Of:"Paga a l'ordre de:",
  Select_Date_Range:"Selecciona l'interval de dates",
  Select_Starting_Date:"Seleccioneu la data d'inici",
  Select_Ending_Date:"Selecciona la data de finalització",
  Select_Date:"Selecciona la data",
  Cheque_Date:"Comprova la data",
  Cheque_Memo:"Comprova la nota",
  Blank_Cheque:"Xec en blanc",
  Blank:"En blanc",
  No_Cheques:"Sense xecs",
  No_Cheques_Word:"Imprimeix el teu primer <a routerLink='/folder/Cheque/New'>Comproveu</a>.",
  Cheque_Amount_Placeholder:"0,00 $",
  View_Image:"Mostra la imatge",
  View:"Vista",
  Modify:"Modificar",
  Delete:"Suprimeix",
  Cheque_Paid:"Pagat",
  New_Deduction:"Nova deducció",
  Title:"Títol",
  Frequency:"Freqüència",
  Hourly:"Hora",
  Daily:"Diari",
  Weekdays:"Dies feiners",
  BiWeekly:"2 setmanes",
  TriWeekly:"3 setmanes",
  Monthly:"Mensual",
  MiMonthly:"2 mesos",
  Quarterly:"Trimestral",
  Yearly:"Anualment",
  Every_Week:"Cada setmana",
  Every_Payroll:"Totes les nòmines",
  Every_Month:"Cada mes",
  Annually:"Anualment",
  Always_Scheduled:"Sempre programat",
  Start_Date:"Data d'inici",
  End_Date:"Data de finalització",
  Start_Time:"L'hora d'inici",
  End_Time:"Fi del temps",
  Deduction_Label:"Etiqueta de deducció",
  Notes:"Notes",
  Options:"Opcions",
  Enable:"Activa",
  Select_Deductions:"Seleccioneu les deduccions",
  Deductions:"Deduccions",
  No_Deductions:"Sense deduccions",
  No_Deductions_Word:"Creeu la vostra primera <a routerLink='/folder/Deduction/New'>Deducció</a>.",
  New_Employee:"Nou empleat",
  No_Title:"Sense títol",
  _Name:"Nom",
  About_Yourself:"Sobre tu",
  Full_Name:"Nom complet",
  Birthday:"Aniversari",
  Email:"Correu electrònic",
  SMS:"SMS",
  Phone:"Telèfon",
  Test:"Prova",
  Call:"Anomenada",
  Fax:"Fax",
  Printed_Note:"Nota impresa",
  Position:"Posició",
  Job_Position:"Posició de treball",
  Select_a_Job:"Selecciona una feina",
  Select_a_Salary:"Seleccioneu un sou",
  Add_a_Deduction:"Afegir una deducció",
  Taxes:"Impostos",
  Add_Taxes:"Afegir impostos",
  Date_of_Birth:"Data de naixement",
  Email_Address:"Correu electrònic",
  Phone_Number:"Número de telèfon",
  Phone_Call:"Trucada telefònica",
  Enable_on_Payroll:"Activa a la nòmina",
  Select_Employees:"Seleccioneu empleats",
  No_Employees:"No hi ha empleats",
  No_Employees_Word:"Afegiu el vostre primer <a routerLink='/folder/Employee/New'>Empleat</a> nou nou.",
  No_Name:"Sense nom",
  Unemployeed:"aturats",
  Employeed:"Empleat",
  Paid:"Pagat",
  Enabled:"Activat",
  Disabled:"Inhabilitat",
  Fire:"Foc",
  Not_Available:"No disponible",
  Not_Available_Word:"Imprimeix la teva primera <a routerLink='/folder/Invoice/New'>Factura</a> i rep el pagament.",
  Select_Invoices:"Selecciona_factures",
  Print_Invoice_Word:"Imprimeix la teva primera <a routerLink='/folder/Invoice/New'>Factura</a> i rep el pagament.",
  Invoice_Title:"Títol de la factura",
  Invoice_Date:"Data de la factura",
  Due_Date:"Data de venciment",
  New_Job:"Nou treball",
  Details:"Detalls",
  Customize:"Personalitzar",
  Customize_Dashboard:"Personalitza el tauler de control",
  Components:"Components",
  No_Components:"Sense components",
  Main_Components:"Components principals",
  Smaller_Components:"Components més petits",
  Error_Loading_Page:"S'ha produït un error en carregar aquesta pàgina.",
  Bank_Details:"Detalls del banc",
  About_Your_Job:"Sobre la teva feina",
  Your_Schedule:"El teu horari",
  Job_Title:"Lloc de treball",
  Job_Description:"Descripció de la feina",
  Job_Details:"Detalls del treball",
  Enable_Job:"Activa la feina",
  Pay_Period:"Període de pagament",
  Perpetually_Schedule:"Programa perpètuament",
  Select_Jobs:"Selecciona feines",
  No_Jobs:"No feines",
  Add_Jobs:"Afegeix feines",
  No_Jobs_Word:"Afegiu el primer <a routerLink='/folder/Job/New'>Treball</a> a la llista.",
  Count_Employees:"job.employee_count+'Empleats'",
  Zero_Employees:"0 empleats",
  New_Leave:"Nova baixa",
  Leave_Name:"Deixa el nom",
  Paid_Leave:"Permís pagat",
  Leave_Pay:"Deixeu pagar",
  Indefinite_Leave:"Llicència indefinida",
  Indefinite_Payroll:"Nòmina indefinida",
  Leave:"Marxa",
  Add_Schedules:"Afegeix horaris",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Activa l'absència",
  Select_Leaves:"Selecciona les fulles",
  No_Leaves:"Sense fulls d'absència",
  Leave_Of_Absence:"Permís d'absència",
  Leaves_Of_Absence:"Permisos d'absència",
  New_Leave_of_Absense:"Nova excedència",
  No_Leaves_Word:"Afegiu el primer <a routerLink='/folder/Leave/New'>Permisa d'absència</a>.",
  Not_Enabled:"No activat",
  Absences:"absències",
  Payee:"Destinatari",
  New_Payee:"Nou beneficiari",
  Payee_Identifier:"Identificador del beneficiari",
  Payee_Name:"Nom del beneficiari",
  Payee_Title:"Títol del beneficiari",
  Payment_Memo:"Nota de pagament",
  Select_Payees:"Seleccioneu beneficiaris",
  No_Payees:"Sense beneficiaris",
  Add_Payee_Note:"Afegiu el primer <a routerLink='/folder/Payee/New'>Beneficiari</a>.",
  New_Payees:"Nous beneficiaris",
  About_The_Payment_Schedule:"Pla de pagament",
  Your_Payroll_Schedule:"Nòmina automàtica",
  New_Payment:"Nou pagament",
  Identifier:"Identificador",
  Select:"Selecciona",
  Memo:"Memo",
  Payment_Date:"Data de pagament",
  Mark_as_Paid:"Marca com a pagat",
  Select_Payments:"Seleccioneu pagaments",
  No_Payments:"Sense pagaments",
  No_Payments_Word:"Creeu el primer <a routerLink='/folder/Payment/New'>Pagament</a>.",
  Create_Payroll:"Crear nòmina",
  Properties:"Propietats",
  Payroll_Title:"Títol de la nòmina",
  Payroll_Notes:"Notes de nòmina",
  Payroll_Setup:"Configuració de nòmines",
  Tabulate_Payments:"Tabulació de pagaments",
  Tabulate:"Tabulació",
  By:"Per:",
  Payments_By:"Pagaments per",
  Timesheets_And_Schedules:"Fulls de temps i horaris",
  Both:"Tots dos",
  Items:"Articles",
  Add_Payees:"Afegeix beneficiaris",
  Add_Account:"Afegir compte",
  Enable_Account:"Activa el compte",
  Enable_Payroll:"Activa la nòmina",
  Print_Payroll:"Imprimeix la nòmina",
  No_Payrolls:"Sense nòmina",
  No_Payroll_Word:"Creeu la vostra primera <a routerLink='/folder/Payroll/New'>Nòmina</a>.",
  View_more:"VEURE MÉS",
  Less:"MENS",
  Add_Payroll:"Afegeix nòmina",
  Select_Payroll:"Selecciona la nòmina",
  About_Your_Salary:"Sobre el teu sou",
  Add_Salaries:"Afegeix salaris",
  Add_Salary:"Afegir sou",
  Salaries:"Salaris",
  No_Salaries:"Sense sou",
  No_Salaries_Word:"Afegiu el primer <a routerLink='/folder/Salary/New'>Salari</a>.",
  Select_Salaries:"Selecciona salaris",
  New_Salary:"Nou sou",
  Salary_Name:"Identificador de salari",
  Enable_Salary:"Activa el sou",
  Salary_Amount:"Import del salari",
  New_Schedule:"Nou horari",
  Schedule_Title:"Títol de la programació",
  Add_Address:"Afegeix adreça",
  Repeat:"Repetir",
  Design:"Disseny",
  Edit_Design:"Edita el disseny",
  Edit_this_Design:"Edita aquest disseny",
  Repeat_Payment:"Repetir el pagament",
  Enable_Schedule:"Activa la programació",
  Never:"Mai",
  Select_Schedule:"Seleccioneu horaris",
  No_Schedules:"Sense horaris",
  No_Schedules_Word:"Creeu la primera <a routerLink='/folder/Schedule/New'>Schedule</a>.",
  New_Administrator:"Nou administrador",
  Username:"Nom d'usuari",
  First_Name:"Nom",
  Last_Name:"Cognom",
  Add_an_Address:"Afegeix una adreça",
  Payment_Limit:"Límit per pagament",
  Total_Limit:"Límit total",
  Select_Accounts:"Seleccioneu comptes",
  No_Administrators:"Sense administradors",
  No_Administrators_Word:"Creeu el primer <a routerLink='/folder/Administrator/New'>Compte d'administrador</a>.",
  New_Administrators_Word:"Afegiu el primer <a routerLink='/folder/Administrator/New'>Administrador</a>",
  Cloud:"núvol",
  Backup:"Còpia de seguretat",
  Enable_iCloud:"Activa iCloud",
  Enable_Google_Drive:"Activa Google Drive",
  Enable_Microsoft_OneDrive:"Activa Microsoft OneDrive",
  Automatically_Backup:"Còpia de seguretat automàtica",
  FTP_Settings:"Configuració FTP",
  URL_File_Prompt:"Especifiqueu la ubicació per importar un fitxer .xls / .xlsx / .json:",
  URL_SQL_Prompt:"Especifiqueu la ubicació del fitxer SQLite a importar:",
  FTP_Backup:"Còpia de seguretat FTP",
  FTP_Import:"Importació FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Amfitrió",
  Port:"Port",
  Path:"Camí",
  Data_Path:"Camí de dades",
  Enable_FTP_Account:"Activa el compte FTP",
  HostnameIP:"Nom d'amfitrió",
  Password:"Contrasenya",
  Connection_Port:"Port de connexió",
  Enable_MySQL_Database:"Activa la base de dades MySQL",
  Log:"Registre",
  Reset:"Restablir",
  Erase:"Esborra",
  Export:"Exportar",
  Database:"Base de dades",
  Upload_CSV:"Penja CSV",
  Download_CSV:"Baixa CSV",
  SQL_Database:"URL SQL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Còpia de seguretat de MySQL",
  Internal_Notes:"Notes internes",
  Root_Path:"Camí arrel",
  Select_Backup:"Selecciona Còpia de seguretat",
  Add_New_Backup:"Afegeix una nova còpia de seguretat",
  First_Backup:"Configura la primera còpia de seguretat...",
  Backups:"Còpia de seguretat",
  Add_Backup:"Afegeix una còpia de seguretat",
  No_Backups:"No es poden trobar còpies de seguretat.",
  Select_Backup_Type:"Seleccioneu el tipus de còpia de seguretat que voleu configurar...",
  Backup_Type:"Tipus de còpia de seguretat",
  FTP_Drive:"Unitat FTP",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Conduir",
  Microsoft_OneDrive:"Conduir",
  Import_Fields:"Importa camps",
  Import_Fields_Word:"Utilitzeu aquesta secció per seleccionar dades <a routerLink='/folder/Holiday/New'>Importa</a>.",
  Upload:"Pujar",
  Download:"Descarregar",
  Download_JSON:"Baixa com a dades JSON",
  Download_SQL:"Baixa com a fitxer SQL",
  New_Bank:"Nou banc",
  New_Account:"Compte nou",
  New_Bank_Account:"Nou compte bancari",
  Upload_Image:"Pujar imatge",
  Bank_Name:"Nom del banc",
  Bank_Address:"Adreça bancària",
  Branch_Address:"Adreça de la sucursal",
  Address_on_Cheque:"Adreça",
  Cheque_Numbers:"Comprova els números",
  Cheque_Details:"Comprova els detalls",
  Bank_Logo:"Logotip del banc",
  Cheque_ID:"Comprova l'identificador",
  Starting_Cheque_ID:"Inici de comprovació d'identificació",
  Transit_Number:"Número de trànsit",
  Institution_Number:"Número d'entitat",
  Designation_Number:"Número de designació",
  Account_Number:"Número de compte",
  Limits:"Límits",
  Default_Limits:"Límits per defecte",
  Over_Limit:"Sobre el límit",
  Under_Limit:"Sota el límit",
  Payroll_Limit:"Límit de nòmina",
  Enable_Bank_Account:"Activa el compte bancari",
  Select_Account:"Selecciona el compte",
  No_Bank_Account:"Sense compte bancari",
  No_Bank_Account_Word:"Afegiu el primer <a routerLink='/folder/Accounts/New'>Compte bancari</a>.",
  Bank_Accounts:"Comptes bancàries",
  No_Accounts:"Sense comptes",
  No_Accounts_Note:"Afegiu el primer <a routerLink='/folder/Accounts/New'>Compte bancari</a>.",
  Cheque_Designer:"Comprova el dissenyador",
  Cheque_Design:"Comprova el disseny",
  Select_Design:"Selecciona un disseny",
  Cheque_Designs:"Comprova els dissenys",
  No_Cheque_Designs:"Sense dissenys de comprovació",
  No_Cheque_Designs_Word:"Crea el teu propi <a routerLink='/folder/Settings/Cheque/Design/New'>Comprova el disseny</a>.",
  Set_Default:"Establir com a defecte",
  Default:"Per defecte",
  Remove:"Elimina",
  Folder:"Carpeta",
  Edit:"Edita",
  LoadingDots:"S'està carregant...",
  Add_a_New_File:"Afegiu un <a href='#' (click)='add()'>Fitxer nou</a> a",
  this_folder:"aquesta carpeta",
  FTP_Backup_Settings:"Configuració de còpia de seguretat FTP",
  Secure_File_Transfer:"Transferència segura de fitxers",
  New_Holiday:"Noves vacances",
  Add_Holiday:"Afegeix vacances",
  Holiday_Name:"Nom de vacances",
  Additional_Pay:"Pagament addicional",
  Enable_Holiday:"Activa les vacances",
  Select_Holidays:"Seleccioneu vacances",
  No_Holidays:"No hi ha vacances",
  No_Holidays_Word:"Afegiu el primer <a routerLink='/folder/Holiday/New'>Festa pública</a>.",
  New_Import:"Nova importació",
  Import_Data:"Importa dades",
  Import_Data_Word:"Seleccioneu el tipus de fitxer o el mètode de càrrega que trieu.<br /> Podreu seleccionar els camps importats d'un fitxer que corresponguin a qualsevol paràmetre de l'aplicació després de penjar un fitxer compatible.", 
  Import_File:"Importa el fitxer",
  Link_To_File:"Enllaç al fitxer",
  Select_File:"Selecciona un fitxer",
  New_Moderator:"Nou moderador",
  Allow_Moderation:"Permetre la moderació",
  Create_Paycheques:"Crear xecs de pagament",
  Edit_Paycheques_and_Data:"Editar xecs de pagament i dades",
  Destroy_Data_and_Paycheques:"Destrueix dades i xecs de pagament",
  Bonus_Percentage:"Percentatge de sou",
  Fixed_Amount:"Import fix",
  Select_Moderator:"Selecciona un moderador",
  No_Moderators:"Sense moderadors",
  Moderators:"Moderadors",
  Moderator_Account:"Creeu el primer <a routerLink='/folder/Administrator/New'>Compte de moderador</a>.",
  No_Moderators_Word:"Afegiu el primer <a routerLink='/folder/Administrator/New'>Moderador</a>.",
  Defaults:"Per defecte",
  Provide_Us_Info:"Oferiu-nos informació",
  Setup_Your_Printer:"Configura la teva impressora",
  Your_Company:"Sobre la teva empresa",
  Company_Name:"Nom de la companyia",
  Currency:"Moneda",
  Default_Currency:"Moneda predeterminada",
  Base_Monthly_Income:"Ingressos mensuals",
  Protection:"Protecció",
  App_Protection:"Protecció d'aplicacions",
  PIN_Code_Protection:"Protecció del codi PIN",
  App_Protection_Word:"Activa mètodes de seguretat que ajudin a protegir el teu compte",
  PIN_Code:"Codi PIN",
  Change_PIN:"Canvia el PIN",
  New_Password:"Nova contrasenya",
  Geofence_Protection:"Protecció de la tanca geogràfica",
  Geofence_Area:"Estableix l'àrea",
  Distance:"Distància",
  Setup_Now:"Configura ara",
  Mile:"Milla",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Reconeixement facial",
  Face:"Cara",
  Setup:"Configuració",
  Label:"Etiqueta",
  Password_Protection:"Protecció amb contrasenya",
  Modify_Password:"Modifica la contrasenya",
  New_Tax_Entry:"Nova entrada fiscal",
  New_Tax:"Nou impost",
  Tax_Label:"Etiqueta fiscal",
  Perpetually_Enabled:"Perpètuament activat",
  Select_Taxes:"Seleccioneu impostos",
  Tax_Deductions:"Deduccions fiscals",
  No_Tax_Deductions:"Sense deduccions fiscals",
  No_Tax_Deductions_Word:"Afegiu la primera deducció <a routerLink='/folder/Tax/New'>Impost</a>.",
  New_Timer:"Nou temporitzador",
  Start:"Començar",
  Stop:"Atura",
  Start_Timer:"Inicia el temporitzador",
  Stop_Timer:"Atura el temporitzador",
  Timer_Active:"Temporitzador actiu",
  Timer:"Temporitzador:",
  Timer_Running:"Temporitzador: (en execució)",
  Save_Timer:"Desa el temporitzador",
  New_Timesheet:"Nou full de temps",
  Select_Timesheets:"Seleccioneu fulls de temps",
  Work_Notes:"Notes de treball",
  Entry_Title:"Títol de l'entrada",
  No_Timesheets:"Sense fulls de temps",
  No_Timesheets_Word:"Afegiu el primer <a routerLink='/folder/Timesheet/New'>Horari</a>.",
  Timesheet_Submitted:"Full de temps enviat",
  Timesheet_Congrats:"Enhorabona! El vostre full de temps s'ha enviat correctament. Gràcies!",
  Timesheet_Copy:"Per rebre una còpia dels vostres documents, si us plau, proporcioneu-nos la vostra adreça de correu electrònic i/o número de telèfon mòbil.",
  Submit:"Presentar",
  Allow_Notifications:"Permet notificacions",
  Untitled_Entry:"Nova entrada",
  Untitled_Bank:"Banc sense títol",
  Timesheet_Entry:"Entrada del full de temps",
  Work_Description:"Descripció de treball",
  Enable_Timesheet:"Activa el full de temps",
  Submit_Timesheet:"Envia el full de temps",
  Vacation:"Vacances",
  New_Vacation:"Noves vacances",
  Vacation_Name:"Nom de vacances",
  Paid_Vacation:"Vacances pagades",
  Vacation_Pay:"Pagament de vacances",
  Enable_Vacation:"Activa les vacances",
  Select_Vacations:"Seleccioneu vacances",
  No_Vacations:"No hi ha vacances",
  No_Vacations_Word:"Creeu la primera entrada <a routerLink='/folder/Vacation/New'>Vacants</a>.",
  Payroll_Schedule:"Planificació de nòmines",
  Next_Payroll:"Nòmina següent:",
  Upcoming_Payroll:"Pròxima nòmina",
  No_Upcoming_Payroll:"No hi ha nòmina propera",
  Address_Book:"Llibreta d'adreces",
  Archived_Documents:"Documents arxivats",
  Dev_Mode:"Aplicació en mode de desenvolupament",
  Administrators:"Administradors",
  Privacy:"Privadesa",
  None:"Cap",
  Select_Signature:"Selecciona la signatura",
  No_Signatures:"Sense signatures",
  No_Signatures_Word:"Afegiu la primera <a routerLink='/folder/Signature/New'>Signatura</a>.",
  Repeat_Indefinitely:"Repetiu indefinidament",
  Sign:"Signe",
  Sign_Here:"Signa aquí",
  Date_Signed:"Data de signatura",
  Signature_Pad:"Box de signatura",
  Account_Holder:"Titular del compte",
  Account_Properties:"Propietats del compte",
  Name_On_Cheque:"Nom al xec",
  Server_Hostname:"Nom d'amfitrió/IP del servidor",
  Printers:"Impressores",
  No_Printers:"Sense impressores",
  Printer_Exists:'Ja existeix una impressora amb aquest nom.',
  No_Printers_Word:"Afegiu la primera <a routerLink='/folder/Printer/New'>Impressora</a>.",
  No_Printer_Alert:"No hi ha cap impressora definida. Vols configurar una impressora?",
  Add_Printer:"Afegeix una impressora",
  New_Printer:"Nova impressora",
  Printer_Hostname:"Nom d'amfitrió / IP de la impressora",
  Printer_Label:"Etiqueta de la impressora",
  Printer_Protocol:"Protocol de la impressora",
  Protocol:"Protocol",
  Email_Print:"Correu electrònic",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Endoll",
  Print_Job:"Treball d'impressió",
  Printed:"Imprès",
  Not_Printed:"No imprès",
  Print_Jobs:"Treines d'impressió",
  Print_Queue:"Cua d'impressió",
  No_Print_Jobs:"No hi ha treballs d'impressió",
  No_Prints:"Creeu un nou <a routerLink='/folder/Cheque/New'>Comproveu</a> per imprimir.",
  Prints:"Imprimeix",
  Find_Printer:"Troba la impressora",
  Find_AirPrint_Devices:"Troba dispositius AirPrint",
  Select_Printer:"Seleccioneu la impressora",
  System_UI:"Interfície d'usuari del sistema",
  Printer:"impressora",
  Status:"Estat",
  Preview:"Vista prèvia",
  Enable_Print_Job:"Activa el treball d'impressió",
  Queue_Weight:"Pes de la cua",
  Unlimited:"Il·limitat",
  Show_Advanced_Printer_Options:"Mostra les opcions avançades de la impressora",
  Advanced:"Avançat",
  Location:"Ubicació",
  Note:"Nota",
  Queue_Name:"Nom de la cua",
  Pages_Printed_Limit:"Límit de pàgines impreses",
  MultiPage_Idle_Time:"Temps d'espera de diverses pàgines",
  Page_Count_Limit:"Límit de recompte de pàgines",
  Page_Orientation:"Orientació de la pàgina",
  Portrait:"Retrat",
  Landscape:"Paisatge",
  Duplex:"Dúplex",
  Double_Sided_Printing:"Doble cara",
  Duplex_Mode:"Mode dúplex",
  Duplex_Short:"Curt",
  Duplex_Long:"Llarg",
  Duplex_None:"Cap",
  Color_And_Quality:"Color i qualitat",
  Monochrome:"Monocrom",
  Photo_Quality_Prints:"Impressió de qualitat fotogràfica",
  Printer_Email:"Correu electrònic de la impressora",
  Automatically_Downsize:"Redueix la mida automàticament",
  Paper:"Paper",
  Paper_Name:"Nom del paper",
  Paper_Width:"Amplada del paper",
  Paper_Height:"Alçada del paper",
  Paper_Autocut_Length:"Longitud de tall automàtic del paper",
  Margins:"Marges",
  Page_Margins:"Marges de pàgina",
  Page_Margin_Top:"Marge de la pàgina superior",
  Page_Margin_Right:"Marge dret de la pàgina",
  Page_Margin_Bottom:"Marge inferior de la pàgina",
  Page_Margin_Left:"Marge esquerre de la pàgina",
  Add_Employees:"Afegeix empleats",
  Header:"Encapçalament",
  Print_A_Page_Header:"Imprimeix una capçalera de pàgina",
  Header_Label:"Etiqueta de capçalera",
  Header_Page_Index:"Índex de la capçalera",
  Header_Font_Name:"Tipus de lletra de la capçalera",
  Select_Font:"Selecciona font",
  Font_Selector:"Selector de font",
  Header_Font_Size:"Tipus de lletra de la capçalera",
  Header_Bold:"Capçalera en negreta",
  Header_Italic:"Capçalera cursiva",
  Header_Alignment:"Alineació de la capçalera",
  Left:"Esquerra",
  Center:"Centre",
  Right:"Dret",
  Justified:"Justificat",
  Header_Font_Color:"Color de la capçalera",
  Select_Color:"Selecciona el color",
  Footer:"Peu de pàgina",
  Print_A_Page_Footer:"Imprimeix el peu de pàgina",
  Footer_Label:"Etiqueta de peu de pàgina",
  Footer_Page_Index:"Índex de pàgina de peu de pàgina",
  Footer_Font_Name:"Footer Font",
  Fonts:"Tipus de lletra",
  Done:"Fet",
  Select_Fonts:"Seleccioneu tipus de lletra",
  Footer_Font_Size:"Mida del peu de pàgina",
  Footer_Bold:"Peu de pàgina en negreta",
  Footer_Italic:"Peu de pàgina cursiva",
  Footer_Alignment:"Alineació de peu de pàgina",
  Footer_Font_Color:"Color del peu de pàgina",
  Page_Copies:"Còpies de pàgina",
  Enable_Printer:"Activa la impressora",
  Remote_Logging:"Registre remot",
  Log_Server:"Servidor de registre",
  Encryption:"Xifrat",
  Random_Key:"Clau aleatòria",
  Encryption_Key:"Clau de xifratge",
  Cheques_Webserver:"Base de dades personalitzada",
  Learn_How:"Aprèn com",
  Signature:"Firma",
  Default_Printer_Unit:"en/cm/mm/(pt)",
  View_Signature:"Mostra la signatura",
  Printed_Signature:"Firma impresa",
  Digitally_Sign:"Significar digitalment",
  Digital_Signature:"Signatura digital",
  Add_Signature:"Afegeix una signatura",
  Add_Your_Signature:"Afegiu la vostra signatura",
  Enable_Signature:"Activa la signatura",
  Digitally_Signed:"Signat digitalment",
  Insert_Error:"No es pot desar la comprovació a causa de problemes de base de dades.",
  Delete_Confirmation:"Estàs segur que vols suprimir aquesta informació?",
  Discard_Confirmation:"Estàs segur que vols descartar aquesta informació?",
  Discard_Bank_Confirmation:"Estàs segur que vols descartar aquest compte?",
  Discard_Printer_Confirmation:"Estàs segur que vols descartar aquesta impressora?",
  Delete_Bonus_Confirmation:"Estàs segur que vols suprimir aquesta bonificació?",
  Delete_Invoice_Confirmation:"Esteu segur que voleu suprimir aquesta factura?",
  Generated_Cheque:"Comprovació generada",
  Generated_Invoice:"Factura generada",
  Schedule_Start_Time:"Programa d'inici",
  Schedule_End_Time:"Fi de programació",
  New_Call:"Nova trucada",
  No_Contacts:"Sense contactes",
  No_Contacts_Word:"Els administradors, moderadors, empleats i beneficiaris apareixen com a contactes",
  PDF_Subject:"finances",
  PDF_Keywords:"xecs de xec de nòmina PDF",
  Printer_Setup:"Configuració de la impressora",
  Printer_Selection:"Selecció d'impressora",
  New_Fax:"Fax nou",
  New_Fax_Message:"Nou missatge de fax",
  Fax_Machine:"Màquina de fax",
  Fax_Name:"Nom del fax",
  Fax_Email:"Correu electrònic de fax",
  Fax_Number:"Número de fax",
  Contents:"Continguts",
  Fax_Body:"Cos de la pàgina",
  Header_Word:"Encapçalament:",
  Header_Text:"Text de la capçalera",
  Include_Header:"Inclou la capçalera",
  Include_Footer:"Inclou el peu de pàgina",
  Footer_Word:"Peu de pàgina:",
  Footer_Text:"Text al peu de pàgina",
  Attach_a_Cheque:"Adjunteu un xec",
  Add_Deduction:"Afegir deducció",
  Enable_Fax:"Envia un fax",
  Select_Fax:"Seleccioneu el fax",
  No_Faxes:"Sense fax",
  Faxes:"Faxes",
  Save_and_Send:"Envia un fax",
  Save_and_Pay:"Estalvia i paga",
  WARNING:"ADVERTIMENT:",
  Remember:"Recorda",
  Printing:"Impressió",
  Printing_Complete:"Impressió completada!\n\n",
  of:"de",
  There_Were:"Hi havia ",
  Successful_Prints:"impressions reeixides i",
  Unsuccessful_Prints:"impressions sense èxit.",
  PrinterError:"Ho sentim! Hi ha hagut un error.",
  Printing_:"Impressió...",
  PrinterSuccess:"El document s'ha imprès correctament.",
  PrintersSuccess:"Els documents s'han imprès correctament.",
  Print_Cheques:"Imprimeix xecs",
  New_Message:"Nou missatge",
  New_Messages:"Nous missatges",
  Read_Message:"Llegir el missatge",
  Write_Message:"Escriu missatge",
  Send_Message:"Enviar missatge",
  Subject:"Assignatura",
  Message:"Missatge",
  Writing:"Escriure...",
  Send:"Envia",
  Set_Date:"Defineix la data",
  Set_Time:"Estableix l'hora",
  Recieve:"Rebre",
  Set_as_Default:"Establir com a defecte",
  Default_Account:"Compte predeterminat",
  Default_Design:"Disseny per defecte",
  Multiple_Cheques:"Checks (Triple)",
  Account_Mode:"Mode de compte",
  Multiple_Cheques_Description:"Tres xecs per pàgina.",
  Check_and_Table:"Comprova i taula",
  Check_including_Table:"Taula de control i comptabilitat.",
  Check_Mailer:"Comprova el correu",
  Check_Mailer_Window:"Comprova amb una finestra d'adreça.",
  DocuGard_Table_Top:"DocuGard Check & Table (A dalt)",
  DocuGard_Table_Middle:"DocuGard Check & Table (mitjana)",
  DocuGard_Table_Bottom:"DocuGard Check & Table (a baix)",
  DocuGard_Mailer_Top:"DocuGard Check Mailer (a dalt)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (mitjà)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mailer (a baix)",
  DocuGard_Three_Cheques:"Comprovacions DocuGard (Triple)",
  DocuGard_Cheque_Top:"Comprovació de DocuGard (a dalt)",
  DocuGard_Cheque_Middle:"Comprovació de DocuGard (mitjana)",
  DocuGard_Cheque_Bottom:"Comprovació de DocuGard (a baix)",
  DocuGard_Three_Cheques_Window:"Tres comprovacions en una pàgina.",
  DocuGard_Table_Top_Window:"Taula de xecs i guanys.",
  DocuGard_Table_Middle_Window:"Taula de xecs i guanys.",
  DocuGard_Table_Bottom_Window:"Taula de xecs i guanys.",
  DocuGard_Mailer_Top_Window:"Comprova, taula i adreça.",
  DocuGard_Mailer_Middle_Window:"Comprova, taula i adreça.",
  DocuGard_Mailer_Bottom_Window:"Comprova, taula i adreça.",
  DocuGard_Cheque_Top_Window:"Comproveu si hi ha paper segur.",
  DocuGard_Cheque_Middle_Window:"Comproveu si hi ha paper segur.",
  DocuGard_Cheque_Bottom_Window:"Comproveu si hi ha paper segur.",
  Basic_Cheque:"Comprova (a dalt)",
  Basic_Cheque_Print:"Imprimeix un sol xec.",
  Error_Setting_As_Paid:"S'ha produït un error en definir com a pagament",
  Add_Attachment:"Afegeix un fitxer adjunt",
  PrinterUnavailable:"Impressora no disponible",
  CreditCardComponent:"Targetes",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Nou dipòsit",
  Deposits:"Dipòsits",
  No_Deposits:"Sense dipòsits",
  Credit_Card_Deposit:"Targeta de crèdit",
  New_Credit_Card_Deposit_Message:"Dipòsit amb targeta de crèdit",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Dipòsit de Bitcoin",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Transferència Interac",
  Payments_Limit:"Límit de pagament",
  No_Payment_Limit:"Sense límit de pagament",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Dipòsit de PayPal",
  No_Deposits_Word:"Afegiu la primera empresa <a routerLink='/folder/Deposit/New'>Dipòsit</a>.",
  No_Documents_Specified:"No s'ha especificat cap document per imprimir",
  No_Printers_Added:"No s'ha trobat cap impressora. Aneu a Configuració > Impressores per afegir-ne una",
  DB_Erase_Prompt:"Esborrar completament tota la base de dades? ADVERTIMENT: perdràs tota la informació emmagatzemada!",
  Console_Warning:"No enganxeu cap text a aquesta consola. Podeu posar-vos en perill a vosaltres mateixos i/o a la vostra empresa",
  No_Faxes_Word:"Creeu el primer <a routerLink='/folder/Fax/New'>Fax</a>.",
  Cheque_Delete_Confirmation:"Estàs segur que vols suprimir aquest xec?",
  Design_Delete_Confirmation:"Estàs segur que vols suprimir aquest disseny?",
  Cheque_Pay_Confirmation:"Marqueu aquest xec com a pagat? NO apareixerà a la cua d'impressió.",
  Payment_Pay_Confirmation:"Marqueu aquest pagament com a pagat? NO apareixerà a la cua de xecs.",
  Delete_Deduction_Confirmation:"Estàs segur que vols suprimir aquesta deducció?",
  Delete_Job_Confirmation:"Estàs segur que vols suprimir aquesta feina?",
  Delete_Timesheet_Confirmation:"Estàs segur que vols suprimir aquest full de temps?",
  Delete_Schedule_Confirmation:"Estàs segur que vols suprimir aquesta programació?",
  Delete_Setting_Confirmation:"Estàs segur que vols restablir aquesta configuració?",
  Delete_Fax_Confirmation:"Esteu segur que voleu suprimir aquest fax?",
  Delete_File_Confirmation:"Estàs segur que vols suprimir aquest fitxer?",
  Delete_Vacation_Confirmation:"Estàs segur que vols suprimir aquestes vacances?",
  Delete_Printer_Confirmation:"Esteu segur que voleu suprimir aquesta impressora?",
  Remove_Design_Confirmation:"Estàs segur que vols suprimir aquest disseny?",
  Delete_Payroll_Confirmation:"Estàs segur que vols suprimir aquesta nòmina?",
  Send_Fax_Email_Confirmation:"Voleu enviar per fax i correu electrònic aquests documents?",
  Send_Email_Confirmation:"Voleu enviar aquest document per correu electrònic?",
  Send_Fax_Confirmation:"Voleu enviar aquest document per fax?",
  Error_Generating_PDF:"Ho sentim. S'ha produït un error en generar aquest document.",
  PDF_Error_Saving_Image:"Ho sentim. S'ha produït un error en desar la imatge PDF d'aquest document.",
  Test_Printer_Confirmation:"Voleu imprimir una pàgina de prova en aquesta impressora?",
  Save_Timesheet_Prompt:"Afegiu un 'Títol' o premeu 'Iniciar temporitzador' per desar-lo.",
  Remove_Geofence_Prompt:"Esteu segur que voleu eliminar la ubicació d'aquesta tanca geogràfica?",
  Delete_Employee_Confirmation:"Estàs segur que vols suprimir",
  Fire_Employee_Confirmation:"Estàs segur que vols disparar"
}