export const Lv = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Autortiesības &copy; 2023. gads",
  black:"Melns",
  green:"Zaļš",
  gold:"Zelts",
  blue:"Zils",
  brown:"Brūns",
  purple:"Violets",
  pink:"Rozā",
  red:"Sarkans",
  Swatches:"Paraugi",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Nokrāsa",
  Saturation:"Piesātinājums",
  Lightness:"Vieglums",
  Upgrade_To_Pro:"Jauniniet uz Pro",
  AllFeaturesInclude:"Visas funkcijas ietver:",
  PrintUnlimitedCheques:"Drukāt neierobežotu čeku skaitu",
  PremiumChequeDesigns:"Premium čeku modeļi",
  ManageUnlimitedEmployees:"Pārvaldiet neierobežotu darbinieku skaitu",
  AddUnlimitedPayees:"Pievienojiet neierobežotus maksājumu saņēmējus",
  CreateUnlimitedPayrolls:"Izveidojiet neierobežotu algu sarakstu",
  UnlimitedSchedulesandTimeSheets:"Neierobežoti grafiki un laika uzskaites tabulas",
  BulkPayPalPayouts:"Lielapjoma PayPal izmaksas",
  UnlimitedBankAccounts:"Neierobežots bankas kontu skaits",
  ManageMultipleCompanies:"Pārvaldiet vairākus uzņēmumus",
  TrackInsurancePolicies:"Track apdrošināšanas polises",
  Bio_MetricProtection:"Biometriskā aizsardzība",
  Geo_FenceLock_OutProtection:"Aizsardzība pret ģeogrāfisko žogu",
  Multiple_Companies_Word:"Vairāku uzņēmumu pārvaldība nav pieejama bez čeku piemaksas.",
  PayPal_Payouts_Word:"PayPal maksājumi ir atspējoti bez čeku prēmijas.",
  PINProtection:"PIN aizsardzība",
  PasswordProtection:"Paroles aizsardzība",
  May_Require_Approval:"Var būt nepieciešams apstiprinājums.",
  Subscribe:"Abonēt",
  Billed:"Rēķins",
  Up:"Uz augšu",
  Down:"Uz leju",
  Positioning:"Pozicionēšana",
  Marker:"Marķieris",
  Drag_Marker:"Velciet marķieri",
  Tagline:"Drukāt čekus un tabulēt algas",
  Marker_Word:"Izmantojiet marķierus, lai noteiktu elementa izmēru.",
  Pinch_Zoom:"Saspiediet tālummaiņu",
  Pinch_Word:"Savelciet, lai tuvinātu elementu.",
  Drag:"Velciet",
  Drag_Word:"Izmantojiet pirkstu, lai vilktu elementus.",
  subscription_alias_word:"Abonementa automātiska atjaunošana",
  premium_alias_word:"Vienreizēja jaunināšanas pakotne",
  print_alias_word:"Izdrukājiet individuālos čekus",
  mode_alias_word:"Režīms",
  Pro:"Pro",
  Pro_word:"Nepieciešama Pro versija.",
  Cant_Delete_Default_Company_Word:"Atvainojiet, jūs nevarat izdzēst savu noklusējuma uzņēmumu.",
  Reinsert_Default_Designs:"Atkārtoti ievietojiet noklusējuma dizainus",
  Reinsert_Default_Designs_word:"Vai vēlaties atkārtoti ievietot noklusējuma dizainu?",
  Subscription_Active_Disable_Word:"Šis abonements ir aktīvs. Vai vēlaties atcelt šo čeku abonementu?",
  Company_Logo:"Uzņēmuma logotips",
  ZERO_:"NULLE",
  Disclaimer:"Atruna",
  Privacy_Policy:"Privātuma politika",
  EULA:"Pārbauda EULA",
  Terms_Of_Service:"Pakalpojuma noteikumi",
  Terms_Of_Use:"Lietošanas noteikumi",
  Refunds:"Atmaksas politika",
  Single_Print:"1 Pārbaudiet",
  Two_Prints:"2 Pārbaudes",
  Five_Prints:"5 Pārbaudes",
  Ten_Prints:"10 pārbaudes",
  Fifteen_Prints:"15 Pārbaudes",
  Twenty_Prints:"20 pārbaudes",
  Thirty_Prints:"30 pārbaudes",
  Image_Added:"Attēls pievienots",
  Image_Preview:"Attēla priekšskatījums",
  No_Image_Was_Selected:"Netika atlasīts neviens attēls.",
  Cheques_Unlimited:"Neierobežoti čeki",
  _Subscription:"Abonēšana",
  Subscription:"Pārbaudes - 1 mēnesis",
  Two_Month_Subscription:"Pārbaudes - 2 mēneši",
  Three_Month_Subscription:"Pārbaudes - 3 mēneši",
  Six_Month_Subscription:"Pārbaudes - 6 mēneši",
  Twelve_Month_Subscription:"Pārbaudes - 12 mēneši",
  Cheques_Are_Available:"Čekus var izdrukāt.",
  Upgrade_Required_Two:"Izvēlieties paketi un veiciet dubultskārienu uz cenas pogas, lai sāktu pirkumu. Drukājiet profesionāla izskata pilnkrāsu čekus dažu sekunžu laikā.",
  Month:"Mēnesis",
  Due:"Termiņš:",
  Expires:"Beidzas:",
  Subscription_Active:"Aktīvs abonements",
  Subscription_Inactive:"Abonements neaktīvs",
  Purchase_Additional_Cheques:"Vai iegādāties papildu čekus?",
  Printable_Cheque:"Drukājams čeks",
  Printable_Cheques:"Drukājami čeki",
  Printable_Cheque_Word:"čeks ir pieejams jūsu kontā.",
  Printable_Cheques_Word:"čeki ir pieejami jūsu kontā.",
  Max_Amount_Message:"Jūsu norādītā summa ir pārsniegusi šai sistēmai iestatīto maksimālo summu:",
  Terms_Required_Word:"Pirms turpināt izmantot čekus, jums ir jāpiekrīt šim līgumam.",
  Subscriptions:"Abonementi",
  Product_Upgrades:"Uzlabojumi",
  Mailed_Out_Cheques:"Pa pastu nosūtītas čekas",
  Enter_A_Company_Name:"Lūdzu, ievadiet uzņēmuma nosaukumu.",
  Single_Cheques:"Vienotās pārbaudes",
  Cheque_Golden:"Zelta čeks",
  Cheque_Golden_Window:"Zelta čeka dizains.",
  Cheque_Green:"Zaļais čeks",
  Cheque_Green_Window:"Zaļā čeka dizains.",
  Cheque_Red:"Sarkanais čeks",
  Cheque_Red_Window:"Sarkano čeku dizains.",
  Cheque_Yellow:"Dzeltenais čeks",
  Cheque_Yellow_Window:"Dzeltenā čeka dizains.",
  Cheque_Statue_of_Liberty:"Brīvības statuja",
  Cheque_Statue_of_Liberty_Window:"Brīvības statujas čeka dizains.",
  Cheque_Green_Wave:"Zaļais vilnis",
  Cheque_Green_Wave_Window:"Zaļā čeka dizains.",
  Cheque_Golden_Weave:"Zelta pinums",
  Cheque_Golden_Weave_Window:"Elegants zelta čekas dizains.",
  Cheque_Green_Sun:"Zaļā saule",
  Cheque_Green_Sun_Window:"Dziļš un nomierinošs čeka dizains.",
  Cheque_Blue_Checkers:"Zilā dambrete",
  Cheque_Blue_Checkers_Window:"Zilā čeka dizains.",
  Cashiers_Check:"Kases čeks",
  Cashiers_Check_Window:"Kases čeka stila veidne.",
  Cheque_Aqua_Checkers:"Ūdens dambrete",
  Cheque_Aqua_Checkers_Window:"Aqua check dizains.",
  Cheque_Golden_Checkers:"Zelta dambrete",
  Cheque_Golden_Checkers_Window:"Zelta čeka dizains.",
  Upgrade_Unavailable:"Jauninājumi nav pieejami",
  Bank_Code_Protection:"Bankas numura aizsardzība",
  Bank_Code_Protection_Word:"Aizsargājiet savus bankas numurus, lai tie netiktu izmantoti šajā lietotnē, kas darbojas citā ierīcē vai citam lietotājam. Šī darbība ir NEATgriezeniska. Turpināt?",
  Bank_Code_Protection_Blocked_Word:"Bankas numuri, kurus mēģināt izmantot, ir rezervēti citam lietotājam vai ierīcei.",
  Bank_Code_Protection_Error_Word:"Numura pārbaude neizdevās. Lūdzu, izveidojiet savienojumu ar internetu un mēģiniet vēlreiz pievienot šo bankas kontu.",
  Bank_Code_Protection_Set_Error_Word:"Bankas numura aizsardzībai ir nepieciešams savienojums ar internetu. Lūdzu, izveidojiet savienojumu un mēģiniet vēlreiz.",
  Upgrade_Unavailable_Word:"Atvainojiet, mums ir problēmas ar jūsu pārbaudi. Čeku abonementi un jauninājumi pašlaik nav pieejami iegādei jūsu reģionā.",
  PayPal_Payment_Preview:"PayPal maksājumu priekšskatījums",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Izvēlieties PayPal",
  PayPal_Applications:"PayPal lietojumprogrammas",
  Purchase_With_Apple_Pay:"Iegādājieties, izmantojot Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Kompānijas",
  Insurance:"Apdrošināšana",
  New_PayPal:"Jauns PayPal",
  Pay_By:"Maksāt līdz",
  Insure:"Apdrošināt",
  Miles:"Jūdzes",
  Payment_Method:"Apmaksas veids",
  Select_Policies:"Atlasiet Politikas",
  Policies:"Politikas",
  Policy:"Politika",
  No_PayPal_Account:"Nav PayPal konta",
  No_Policies:"Nav apdrošināšanas polišu",
  New_Policy:"Jauna politika",
  PayPal_Payment:"PayPal maksājums",
  PayPal_Payments:"PayPal maksājumi",
  No_Payment_Selected:"Nav atlasīts neviens maksājums",
  Sending_Payment_Word:"Lūdzu, uzgaidiet... Šis maksājums tiek nosūtīts.",
  Sending_Payments_Word:"Lūdzu, uzgaidiet... Maksājumi tiek sūtīti.",
  No_Payment_Selected_PayPal:"nav atlasīts neviens <a routerLink='/folder/Payments'>PayPal Maksājums</a>.",
  Payment_Sent:"Maksājums nosūtīts",
  PayPal_Payment_Sent:"Šis maksājums ir nosūtīts, izmantojot PayPal.",
  Copay:"Copay",
  Dead:"Miris",
  Alive:"Dzīvs",
  Not_Dead:"Nav miris",
  Unclaimed:"Nepieprasīts",
  Attempted:"Mēģināja",
  Deceased:"Miris",
  Claimed:"Pretenzija",
  Unpaid:"Neapmaksāts",
  Sending_Payment:"Maksājuma nosūtīšana",
  Sending_Payments:"Maksājumu nosūtīšana",
  Send_PayPal_Confirmation:"Vai vēlaties nosūtīt šo darījumu, izmantojot PayPal?",
  Send_PayPal_Confirmation_Word:"Nospiediet zaļo pogu, lai nosūtītu šo darījumu.",
  Save_Payment_As_Unpaid:"Vai saglabāt šo maksājumu kā neapmaksātu?",
  Payment_Pay_Confirmation_PayPal:"Vai saglabāt šo maksājumu kā apmaksātu?",
  No_Policies_Word:"Pievienojiet pirmo <a routerLink='/folder/Postage/New'>Apdrošināšanas Polisi</a> tūlīt.",
  Timesheet_Multiple_Delete_Confirmation:"Vai tiešām vēlaties dzēst vairākas laika uzskaites tabulas?",
  Select_Multiple_Timesheets_Prompt:"Nav atlasīta neviena laika uzskaite. Atlasiet vismaz vienu laika uzskaiti.",
  Select_Multiple_Policies_Prompt:"Nav atlasīta neviena politika. Izvēlieties vismaz vienu apdrošināšanas polisi.",
  Policies_Multiple_Delete_Confirmation:"Vai tiešām vēlaties dzēst vairākas politikas?",
  Company:"Uzņēmums",
  Add_Company:"Pievienot uzņēmumu",
  New_Company:"Pievienot uzņēmumu",
  No_Companies:"Nav uzņēmumu",
  Enable_Company:"Iespējot uzņēmumu",
  Select_Company:"Izvēlieties Uzņēmums",
  The_Default_Company:"Uzņēmuma noklusējuma etiķete.",
  Manage_Companies:"Pārvaldīt uzņēmumus",
  No_Companies_Word:"Pārbaudēs tiks izmantots noklusējuma uzņēmums.<br />Pievienojiet <a routerLink='/folder/Company/New'>Pirmo Uzņēmumu</a>.",
  Default_Company:"Noklusējuma uzņēmums",
  Cheques_Unlimited_Word:"Checks Unlimited ļauj izdrukāt tik daudz čeku, cik vēlaties.",
  Cheques_Subscription_Word:"Čeku abonements ļauj izdrukāt tik daudz čeku, cik vēlaties.",
  You_Own_This_Product:"Jums pieder šis produkts.",
  Your_Subscription_is_Active:"Jūsu abonements ir aktīvs.",
  Active_Products:"Aktivizētie produkti",
  Purchase_Confirmation:"Pirkums",
  Purchase_Cheques:"Pirkuma čeki",
  Restore_Purchase:"Atjaunot pirkumus",
  Erase_Purchase:"Dzēst pirkumus",
  Successfully_Purchased:"Veiksmīgi iegādāts",
  Enter_Your_Licence_Key:"Lūdzu, ievadiet savu licences atslēgu šajā lapā, lai aktivizētu šo produktu.",
  Licence_Key:"Licences atslēga",
  Enter_Licence_Key:"Ievadiet licences atslēgu",
  Purchased:"Iegādāts",
  Enable_Feature:"Iespējot funkciju",
  Cancel_Subscription:"Anulēt abonementu",
  Subscription_Removed:"Abonements noņemts",
  Select_Active_Subscription:"Atlasiet aktīvo abonementu, lai to mainītu.",
  Remove_Subscription_Word:"Vai tiešām vēlaties atcelt šo abonementu?",
  Delete_Company_Confirmation:"Vai tiešām vēlaties dzēst visu šo uzņēmumu? BRĪDINĀJUMS: Jūs zaudēsiet visu saglabāto informāciju!",
  Delete_Default_Company_Word:"Jūs nevarat izdzēst noklusējuma uzņēmumu. Vai vēlaties atiestatīt lietojumprogrammu un atjaunot to noklusējuma stāvoklī? BRĪDINĀJUMS: Jūs zaudēsiet visu saglabāto informāciju!",
  Console_Warning_2:"Neapstrādājiet ar valūtu, izmantojot šo lietojumprogrammu, kas pašlaik nav jūsu.",
  Terms_and_Conditions:"Noteikumi un nosacījumi",
  and_the:"un",
  for:"priekš",
  Please_Read_Word:"Lūdzu, izlasiet un piekrītiet",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Dažus valūtas maiņas kursus nevarēja atrast. Lūdzu, izveidojiet savienojumu ar internetu.",
  Free:"Bezmaksas",
  DB_Erase_Prompt_2:"Vai pilnībā dzēst visu izstrādātāju datu bāzi? BRĪDINĀJUMS: Jūs zaudēsiet visu informāciju par PIRKUMU un ABONEMENTU!",
  Successfully_Imported:"Veiksmīgi importēts",
  Select_Postage:"Izvēlieties Pasta izdevumi",
  No_Postage:"Nav pastmarku",
  No_Paid_Postage_Word:"Pievienojiet pirmo <a routerLink='/folder/Postage/New'>Apmaksātās Pasta Zīmogu</a>.",
  Trial_Complete:'Izmēģinājums pabeigts',
  Please_Upgrade:'Lūdzu, jauniniet čekus, lai turpinātu drukāšanu.',
  Aa:"Aa",
  Color:"Krāsa",
  Font:"Fonts",
  Guide:"Vadīt",
  Guides:"Ceļveži",
  Image:"Attēls",
  Zoom:"Tālummaiņa",
  Logo:"Logotips",
  Bank:"Banka",
  MICR:"MICR",
  Total:"Kopā",
  Cancel:"Atcelt",
  Confirm:"Apstiprināt",
  Method:"Metode",
  Biometric_Security:"Biometriskā drošība",
  Please_Login_To_Continue:"Lūdzu, piesakieties, lai turpinātu.",
  Complete:"Pabeigts",
  Sign_Up:"Pierakstīties",
  Error:"Kļūda",
  Biometrics:"Biometrija",
  Percent:"Procenti",
  Zero_Percent:"0%",
  Top_Margin:"Augšējā piemale",
  Bottom_Margin:"Apakšējā robeža",
  Left_Margin:"Kreisā piemale",
  Right_Margin:"Labā piemale",
  MICR_Margin:"MICR rezerve",
  Table_Margin:"Tabulas piemale",
  Address_Margin:"Adreses robeža",
  Percentage_:"Procenti",
  Vacation_Title:"Atvaļinājuma nosaukums",
  Use_PIN:"Izmantojiet PIN",
  Loading__:"Notiek ielāde...",
  Design_Title:"Dizaina nosaukums",
  Authorize:"Autorizēties",
  Key:"Atslēga",
  Public_Key:"Publiskā atslēga",
  Private_Key:"Privātā atslēga",
  Authenticate:"Autentificēt",
  Last_Payroll:"Pēdējā alga",
  Last_Calculation:"Pēdējais aprēķins",
  Authorized:"Pilnvarots",
  Geo_Authorized:"Ģeogrāfiskā atrašanās vieta: pilnvarota",
  Not_Authorized:"Nav pilnvarots",
  Authorization_Complete:"Autorizācija pabeigta",
  Geolocation_Authorization:"Ģeogrāfiskās atrašanās vietas autorizācija",
  Out_of_Bounds:"Ārpus robežām",
  Cant_Delete_Default_Design:"Nevar izdzēst noklusējuma dizainu.",
  Unauthorized_Out_of_Bounds:"Neatļauts: ārpus robežām",
  Biometric_Authorization:"Biometriskā atļauja",
  Locating_Please_Wait:"Notiek atrašanās vietas noteikšana, lūdzu, uzgaidiet...",
  Test_Biometrics:"Pārbaudi biometriju",
  Cheque_Margins:"Pārbaudiet piemales",
  Percentage_Full_Error:"Procentuālā lauka vērtība nedrīkst būt lielāka par 100%.",
  No_Payroll_Text:"Pievienojiet <a routerLink='/folder/Employee/New'>Darbinieku</a> vai <a routerLink='/folder/Payee/New'>Maksājumu Saņēmēju</a> un <a routerLink='/folder/Schedule/New'>Grafiks</a>.",
  Design_Saved:"Dizains saglabāts",
  Default_Design_Selected:"Atlasīts noklusējuma dizains",
  Partial_Import:"Daļējs imports",
  Partial_Export:"Daļējs eksports",
  Entire_Import:"Viss imports",
  Entire_Export:"Viss eksports",
  Existing_Password:"Lūdzu, ievadiet savu esošo paroli:",
  Existing_PIN:"Lūdzu, ievadiet savu esošo PIN kodu:",
  Pin_Change_Header:"PIN apstiprinājums",
  Pin_Change_SubHeader:"Ievadiet savu veco PIN numuru, lai apstiprinātu izmaiņas.",
  Pass_Change_Header:"Paroles apstiprināšana",
  Pass_Change_SubHeader:"Ievadiet savu veco paroli, lai apstiprinātu izmaiņas.",
  PIN_Enter_Message:"Ievadiet savu PIN, lai apstiprinātu.",
  Password_Enter_Message:"Ievadiet savu paroli, lai apstiprinātu.",
  Pin_Updated_Success:"PIN ir veiksmīgi atjaunināts!",
  Pin_Updated_Fail:"PIN nevarēja atjaunināt.",
  Pass_Updated_Success:"Parole veiksmīgi atjaunināta.",
  Pass_Updated_Fail:"Paroli nevarēja atjaunināt.",
  Preview_Payment:"Priekšskatīt maksājumu",
  Preview_Payroll:"Priekšskatīt algu sarakstu",
  No_Payments_Created:"Netika atrasts neviens maksājums, kas būtu izveidots.",
  Payment_Preview:"Maksājuma priekšskatījums",
  Enable_Payee:"Iespējot maksājuma saņēmēju",
  Rendered:"Renderēts",
  No_Email:"Nav e-pasta",
  Setup_Cheques:"Iestatīšanas pārbaudes",
  name:"Vārds",
  address:"Adrese",
  address_2:"Adreses 2. rindiņa",
  city:"Pilsēta",
  province:"Provincē",
  postal_code:"Pasta/pasta indekss",
  country:"Valsts",
  username:"Lietotājvārds",
  full_name:"Pilnais vārds",
  birthday:"Dzimšanas diena",
  email:"E-pasts",
  phone:"Tālrunis",
  employees:"Darbinieki",
  addresses:"Adreses",
  payment_amount_limit:"Maksājuma summas limits",
  total_limit:"Kopējais limits",
  payees:"Maksājumu saņēmēji",
  description:"Apraksts",
  address_line_one:"Adreses pirmā rinda",
  address_line_two:"Adreses otrā rinda",
  image:"Attēls",
  account_holder:"Konta īpašnieks",
  cheque_starting_id:"Pārbaudiet sākuma ID",
  transit_number:"Tranzīta numurs",
  institution_number:"Iestādes numurs",
  designation_number:"Apzīmējuma numurs",
  account_number:"Konta numurs",
  currency:"Valūta",
  signature:"Paraksts",
  payment_payroll_limit:"Maksājuma limits",
  total_limi:"Kopējais ierobežojums",
  date:"Datums",
  printed_memo:"Iespiesta piezīme",
  banks:"Bankas",
  signature_image:"Paraksta attēls",
  address_name:"Adrese Vārds",
  notes:"Piezīmes",
  design:"Dizains",
  title:"Nosaukums",
  frequency:"Biežums",
  fax:"Fakss",
  salaries:"Algas",
  salary_ids:"Algu ID",
  start_time:"Sākuma laiks",
  end_time:"Beigu laiks",
  paid:"Apmaksāts",
  overtime_percentage:"Apmaksātais procents",
  overtime_amount:"Apmaksāta fiksēta summa",
  first_name:"Vārds",
  last_name:"Uzvārds",
  moderation:"Mērenība",
  create:"Izveidot",
  edit:"Rediģēt",
  destroy:"Iznīcināt",
  day_start_time:"Dienas_sākuma_laiks",
  day_end_time:"Dienas_beigu_laiks",
  fullname:"Vārds",
  time:"Laiks",
  auto_send:"Automātiski sūtīt",
  time_method:"Laika metode",
  schedules:"Grafiki",
  indefinite_payroll_enabled:"Iespējot bezgalīgi",
  amount:"Summa",
  repeat:"Atkārtojiet",
  always_enabled:"Vienmēr iespējots",
  start_date:"Sākuma datums",
  end_date:"Beigu datums",
  Cheque_Total:"Pārbaudiet Kopā",
  Total_Amount:"Kopējā summa:",
  Amounts:"Summas:",
  Images:"Attēli",
  Files:"Faili",
  Previewing:"Priekšskatījums:",
  Insert:"Ievietot",
  Preview_Import:"Priekšskatījums Importēt",
  Entry:"Ieeja",
  Entries:"Ieraksti",
  No_Entries:"Nav ierakstu",
  Import_Type:"Importēšanas veids",
  Select_Details:"Atlasiet Detaļas",
  Select_Payee:"Atlasiet Maksājuma saņēmējs",
  Enable_Holidays:"Iespējot brīvdienas",
  Disable_Holidays:"Atspējot brīvdienas",
  Wire_Transfer:"Pārskaitījums",
  New_Export:"Jauns eksports",
  Export_Data:"Eksportēt datus",
  Export_Data_Word:"Atlasiet eksportējamā un lejupielādējamā faila veidu.",
  Export_File:"Eksportēt failu",
  Mode:"režīms",
  Times:"reizes",
  Widgets:"Logrīki",
  Slider:"Slīdnis",
  Set_Details:"Iestatīt detaļas",
  Select_Type:"Izvēlieties tipu",
  Display_Slider:"Displeja slīdnis",
  Dashboard_Slider:"Informācijas paneļa slīdnis",
  Dashboard_Mode:"Informācijas paneļa režīms",
  Show_Intro:"Rādīt ievadu",
  Show_Payrolls:"Rādīt algu sarakstus",
  Show_Holidays:"Parādīt brīvdienas",
  Show_Invoices:"Rādīt rēķinus",
  Show_Cheques:"Rādīt čekus",
  Enabled_Widgets:"Iespējoti logrīki",
  Disabled_Widgets:"Atspējoti logrīki",
  Colors:"Krāsas",
  Barcodes:"Svītrkodi",
  View_Timers:"Skatīt taimerus",
  Gradients:"Gradienti",
  No_Info:"Nav informācijas",
  Disable:"Atspējot",
  Show_Layer:"Rādīt slāņus",
  Hide_Layer:"Slēpt slāņus",
  Text_Layer:"Teksta slāņi",
  Secondly:"Otrkārt",
  Minutely:"Minūti",
  nine_am:"9:00",
  five_pm:"17:00",
  Enable_Address:"Iespējot adresi",
  Invalid_File_Type:"Atvainojiet, tika atlasīts nederīgs faila veids. Atbalstītais faila tips:",
  Error_Updating_Entry:"Atvainojiet, atjauninot šo ierakstu, radās kļūda.",
  Schedule_Timing_Alert:"Kļūda: grafika sākuma laiks ir iestatīts uz vērtību pēc beigu laika.",
  Select_Multiple_Payments_Prompt:"Nav atlasīts neviens maksājums. Izvēlieties vismaz vienu maksājumu.",
  Select_Multiple_Cheques_Prompt:"Nav atlasīts neviens čeks. Lūdzu, atlasiet vismaz vienu čeku.",
  Select_Multiple_Items_Prompt:"Nav atlasīts neviens vienums. Lūdzu, atlasiet vismaz vienu vienumu.",
  Paymemt_Multiple_Delete_Confirmation:"Vai tiešām vēlaties dzēst vairākus maksājumus?",
  Cheque_Multiple_Delete_Confirmation:"Vai tiešām vēlaties dzēst vairākas pārbaudes?",
  Payee_Multiple_Delete_Confirmation:"Vai tiešām vēlaties dzēst vairākus maksājumu saņēmējus?",
  Employee_Multiple_Delete_Confirmation:"Vai tiešām vēlaties dzēst vairākus darbiniekus?",
  MICR_Warning:"Piezīme. Daži printeri un ierīces var nepareizi parādīt MICR fontu.",
  Automatically_Send:"Automātiski sūtīt",
  Type:"Tips",
  Payment_Type:"Maksājuma veids",
  Auto_Send:"Automātiska sūtīšana",
  Automatically_Process:"Automātiski apstrādāt",
  Auto_Process:"Automātiskais process",
  Image_Based:"Pamatojoties uz attēlu",
  Font_Based:"Uz fonta bāzes",
  Rerender:"Atkārtoti renderēt",
  Rendering:"Renderēšana",
  Render:"Faili",
  Top:"Tops",
  Middle:"Vidus",
  Bottom:"Apakšā",
  Top_Left:"Augšējais kreisais",
  Top_Center:"Augšējais centrs",
  Top_Right:"Augšējais pa labi",
  Middle_Left:"pa kreisi pa vidu",
  Middle_Center:"Vidējais centrs",
  Middle_Right:"Vidēji pa labi",
  Bottom_Left:"Apakšā pa kreisi",
  Bottom_Center:"Apakšējais centrs",
  Bottom_Right:"Apakšējā labajā stūrī",
  Numbers:"Skaitļi",
  Verified:"Pārbaudīts",
  Paper_Size:"Papīra izmērs",
  New_Device:"Jauna ierīce",
  Add_Device:"Pievienot ierīci",
  Remove_Device:"Noņemt ierīci",
  Delete_Device:"Dzēst ierīci",
  Block_Device:"Bloķēt ierīci",
  Block:"Bloķēt",
  Unblock:"Atbloķēt",
  Table:"Tabula",
  Scan_Login_Code:"Skenējiet pieteikšanās kodu",
  Login_Code:"Pieteikšanās kods",
  Scan_Code:"Skenēt kodu",
  Scan_QR_Code:"Skenējiet QR kodu",
  Select_All:"Izvēlēties visus",
  Deselect_All:"Atsijāt visu",
  Increase:"Palielināt",
  Decrease:"Samazināt",
  Bold:"Treknrakstā",
  Text:"Teksts",
  Style:"Stils",
  Italic:"Kursīvs",
  QR_Code:"QR kods",
  Barcode:"Svītrkods",
  Browse_Images:"Pārlūkot attēlus",
  Browse_Files:"Pārlūkojiet failus",
  Background_Image:"Fona attēls",
  Logo_Image:"Logotipa attēls",
  Header_Image:"Galvenes attēls",
  Bank_Image:"Bankas attēls",
  Cut_Lines:"Griezuma līnijas",
  Background:"Fons",
  License:"Licence",
  One_License:"1 Licence:",
  Licensed:"Licencēts:",
  Not_Licensed:"Nav Licencēts",
  Enter_Serial:"Ievadiet Serial",
  Serial_Key:"Sērijas atslēga",
  Serial:"Seriāls",
  Product_Key:"Produkta atslēga",
  Check_Product_Key:"Pārbaudiet produkta atslēgu",
  Add_Product_Key:"Pievienojiet produkta atslēgu",
  Verifying_Purchase:"Notiek pirkuma pārbaude...",
  Print_Envelope:"Drukāt aploksni",
  Envelope:"Aploksne",
  Thank_You:"Paldies!",
  Scale:"Mērogs",
  Print_Scale:"Drukas mērogs",
  Borders:"Robežas",
  VOID:"NEDERĪGS",
  Void_Cheque:"Nederīga pārbaude",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"MAKSĀT PĒC PASŪTĪJUMA:",
  NO_DOLLARS:"NAV DOLĀRU ",
  ONE_DOLLAR:"VIENS DOLĀRS",
  DOLLARS:" DOLĀRI",
  AND:" UN ",
  CENTS:" CENTI.",
  NO_:"NĒ ",
  ONE_:"VIENS ",
  AND_NO_:"UN NĒ ",
  _AND_ONE_:"UN VIENS ",
  DOLLARS_AND_ONE_CENT:" UN VIENS CENTS.",
  AND_NO_CENTS:" UN NULLE CENTU.",
  CHEQUE_PRINT_DATE:"DATUMS:",
  CHEQUE_PRINT_MEMO:"ATMIŅA:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Sākotnējie uzdevumi",
  Inital_Setup:"Sākotnējā iestatīšana",
  Welcome_To:"Laipni lūdzam ",
  Welcome:"Laipni lūdzam",
  Swipe:"Swipe",
  Intro_Setup:"Ievada iestatīšana",
  Introduction:"Ievads",
  CHEQUE_PRINT_CREDIT:"Pārbauda čeki",
  Title_Intro_Word:"Laipni lūdzam Čekos",
  Title_Intro:"Ievads ar čekiem",
  Title_Setup:"Čeku iestatīšana",
  PayPal_Default_Email_Message:"Jūs esat saņēmis jaunu PayPal pārskaitījumu. [Pārvada čeki]",
  Cheques_App_Export:"Eksportēts ar čekiem",
  Post_Intro_Thanks:"Paldies, ka izvēlējāties čekus. Iestatīšanas process tagad ir pabeigts.",
  Post_Intro_Word:"Sāciet, izdrukājot savu pirmo čeku, pievienojiet nākamo maksājumu vai pievienojiet darbinieku algu sarakstam.",
  Upgrade_Required:"Lai paslēptu šo ziņojumu un atbloķētu papildu funkcijas, čekiem ir nepieciešama augstākās kvalitātes programmatūras versija.",
  Upgrade_Title:"Čeki",
  Mailout_Prompt:"Jūs varat papildus izvēlēties, lai čeki izsūta jūsu algas čekus jūsu vietā.",
  Mailed_Cheque:"Pasta čeks: ",
  Mailed_Cheque_Color:"Nosūtīts čeks (krāsa): ",
  Terms_Purchase:"Visi elektroniskie pirkumi ar čekiem tiek pilnībā atmaksāti 30 dienu laikā no iegādes datuma. Pirms turpināt, lūdzu, izlasiet <a href='#'>pakalpojumu sniegšanas noteikumus</a> un piekrītiet tiem.",
  Dashboard_Setup:"Primārā printera iestatīšana",
  Dashboard_Add:"Pievienot primāro bankas kontu",
  Dashboard_Customize:"Atlasīt pārbaudes veidni",
  Dashboard_Job_Salary:"Izveidojiet savu darbu un pievienojiet savu algu",
  Dashboard_Employees:"Izsekot darbiniekiem un maksājumu saņēmējiem",
  Dashboard_Print:"Izdrukājiet un nosūtiet pa pastu jūsu algas čekus",
  Dashboard_Payroll:"Automatizējiet algas drukāšanu",
  Dashboard_PayPal:"Iestatīt PayPal algu/izmaksas",
  Begin_Setup:"Sākt iestatīšanu",
  Get_Started:"Sāc",
  Purchase:"Pirkt",
  Lockdown:"Blokāde",
  Unlock:"Atbloķēt",
  Detailed:"Detalizēti",
  Log_In:"Pieslēgties",
  Login:"Pieslēgties",
  Launch:"Palaist",
  Register:"Reģistrēties",
  Finish:"Pabeigt",
  List:"Saraksts",
  Weekends:"Nedēļas nogales",
  Weekly:"Iknedēļas",
  PayPal_Default_Subject:"Jauns maksājums",
  Payment_Message:"Maksājuma ziņojums",
  PayPal_Default_Payment_Note:"Paldies",
  Setup_Your_Cheqing_Account:"Norēķinu konts",
  Add_Your_Primary_Cheqing_Account:"Pievienojiet savu primāro pārbaudes kontu.",
  Welcome_Word:"Vienkāršojiet un automatizējiet algu un cilvēkresursu pārvaldību.",
  Get_Started_Quickly:"Vienkārši atbildiet uz dažiem vienkāršiem jautājumiem, kas mums palīdzēs sākt...",
  Done_Intro_Word:"Iestatīšana pabeigta",
  PIN_Protected:"Aizsargāts ar paroli un PIN kodu",
  Enter_New_PIN:"Ievadiet jaunu PIN kodu:",
  Enter_PIN:"Ievadiet PIN kodu:",
  Invalid_PIN:"Ievadīts nederīgs PIN.",
  Account_Identifier:"Konta identifikators",
  New_Account_Identifier:"Jauns konta identifikators",
  attempt:"mēģinājums",
  attempts:"mēģinājumi",
  remaining:"palicis",
  Language:"Valoda",
  languages:"Valodas",
  select_languages:"Izvēlēties valodu",
  Deposit:"Depozīts",
  Hire_One_Now:"Nomājiet vienu tūlīt",
  App_Locked:"Lietojumprogramma ir bloķēta.",
  Skip_:"Izlaist",
  Skip_to_Dashboard:"Pāriet uz informācijas paneli",
  Dashboard:"Mērinstrumentu panelis",
  Import:"Importēt",
  Admin:"Administratori",
  New_Admin:"Jauns administrators",
  Settings:"Iestatījumi",
  Color_Picker:"Krāsu atlasītājs",
  Font_Picker:"Fontu atlasītājs",
  Logout:"Izlogoties",
  Close:"Aizvērt",
  Close_menu:"Aizvērt",
  Excel:"Excel fails",
  Csv:"CSV fails",
  Sql:"SQL fails",
  Json:"JSON fails",
  Url:"Importēt pēc URL",
  Back:"Atpakaļ",
  Timers:"Taimeri",
  Cheque:"Pārbaudīt",
  Print:"Drukāt",
  Designs:"Dizains",
  Pause_Printing:"Pārtraukt drukāšanu",
  Resume_Printing:"Atsākt drukāšanu",
  Printing_Paused:"Drukāšana apturēta",
  PrintingUnavailable:"Atvainojiet! Drukāšana šajā sistēmā nav pieejama.",
  Prints_Paused:"Izdrukas apturētas",
  Administration:"Administrācija",
  Loading:"Ielādē",
  Unnamed:"Nenosaukts",
  error:"Atvainojiet, šo čeku nevarēja atvērt apskatei.",
  No_Cheques_To_Print:"Nav drukātu čeku",
  No_Cheques_To_Print_Word:"Izveidot <a routerLink='/folder/Cheque/New'>Jaunu Čeku</a>.",
  New_Cheque:"Jauns čeks",
  Start_One_Now:"Sāciet vienu tūlīt",
  Edit_Cheque:"Rediģēt pārbaudi",
  Select_Cheques:"Atlasīt čekus",
  Select_Employee:"Izvēlēties darbinieku",
  Default_Printer:"Noklusējuma printeris",
  Reassign:"Atkārtoti piešķirt",
  Configure:"Konfigurēt",
  Template:"Veidne",
  Designer:"Dizaineris",
  Edit_Designs:"Rediģēt noformējumus",
  New_Design:"Jauns dizains",
  Next:"Nākamais",
  Save:"Saglabāt",
  Name:"Vārds",
  Mail:"Pasts",
  Amount:"Summa",
  Date:"Datums",
  PayPal:"PayPal",
  Payouts:"Izmaksas",
  PayPal_Label:"PayPal etiķete",
  Email_Username:"E-pasts / lietotājvārds",
  Client_ID:"Klienta ID",
  Sandbox_Email:"Smilškastes e-pasts",
  PayPal_Email:"PayPal e-pasts",
  PayPal_Username:"API lietotājvārds",
  PayPal_Payouts:"PayPal izmaksas",
  Select_PayPal_Key:"Izvēlieties PayPal atslēgu",
  Secret:"Noslēpums",
  API_Secret:"API noslēpums",
  PayPal_API_Keys:"PayPal atslēgas",
  New_PayPal_Key:"Jauna PayPal atslēga",
  Email_Subject:"E-pasta tēma",
  Email_Message:"E-pasta ziņojums",
  Payout_Options:"Izmaksas iespējas",
  Payment_Note:"Maksājuma piezīme",
  Enable_Employee:"Iespējot darbinieku",
  Enable_Production_Mode:"Iespējot ražošanas režīmu",
  Sandbox_Username:"Smilškastes lietotājvārds",
  Sandbox_Signature:"Smilškastes paraksts",
  Sandbox_Password:"Smilškastes parole",
  Production_Username:"Ražošanas lietotājvārds",
  Production_Signature:"Ražošanas paraksts",
  Production_Password:"Ražošanas parole",
  Production_Email:"Ražošanas e-pasts",
  API_Client_ID:"API klienta ID",
  API_Signature:"API paraksts",
  API_Password:"API parole",
  API_Username:"API lietotājvārds",
  Secret_Key:"Slepenā atslēga",
  Sandbox:"Smilšu kaste",
  Production:"Ražošana",
  Sandbox_Keys:"Smilškastes atslēgas",
  Production_Keys:"Ražošanas atslēgas",
  API_Title:"API nosaukums",
  Production_Mode:"Ražošanas režīms",
  Account_Label:"Konta etiķete",
  No_PayPal_Setup:"Nav PayPal atslēgas",
  Enable_PayPal_Account:"Iespējot PayPal kontu",
  No_PayPal_Word:"Pievienojiet savu <a routerLink='/folder/Invoice/New'>PayPal API Atslēgu</a>.",
  Printed_Memo:"Drukāta piezīme",
  Employee:"Darbinieks",
  View_Employee:"Skatīt darbinieku",
  Mailing_Address:"Pasta adrese",
  Company_Address:"Kompānijas adrese",
  Select_Company_Address:"Izvēlieties uzņēmuma adresi",
  Address:"Adrese",
  Any_Day:"Jebkurā dienā",
  Address_Name:"Adreses nosaukums",
  Unit:"Vienība",
  Account:"Konts",
  Bank_Account:"Bankas konts",
  Account_Limits:"Iespējot konta ierobežojumus",
  Payroll:"Algu saraksts",
  New_Payroll:"Jaunais algu saraksts",
  No_Payroll:"Nav gaidāma algu saraksta",
  Upcoming_Holiday:"Gaidāmie svētki:",
  Invoice_Due:"Rēķina apmaksas termiņš:",
  New_Invoice:"Jauns rēķins",
  No_Invoices:"Nav rēķinu",
  No_Invoices_Word:"Izveidot pirmo <a routerLink='/folder/Invoice/New'>Rēķinu</a>.",
  Cheque_Due:"čeka maksājums:",
  Payrolls:"Algu saraksts",
  Sandbox_Mode:"Smilškastes režīms",
  Hire:"Noma",
  Pay:"Maksāt",
  New:"Jauns",
  Add:"Pievienot",
  Make:"Veidot",
  Time:"Laiks",
  Write:"Rakstīt",
  Holiday:"Brīvdiena",
  Holidays:"Brīvdienas",
  Next_Holiday:"Nākamā brīvdiena:",
  All_Done:"Viss pabeigts!",
  Employees:"Darbinieki",
  Payees:"Maksājumu saņēmēji",
  Job:"Darbs",
  Jobs:"Darbs",
  Invoice:"Rēķins",
  Invoices:"Rēķini",
  Vacations:"Brīvdienas",
  Cheques:"Pārbaudes",
  Brand_Cheques:"Zīmols",
  Payment:"Maksājums",
  Enable_Payment:"Iespējot maksājumu",
  Payments:"Maksājumi",
  Schedule:"Grafiks",
  Schedules:"Grafiki",
  Timesheet:"Laika uzskaite",
  Timesheets:"Laika uzskaites tabulas",
  Salary:"Alga",
  New_Address:"Jauna adrese",
  Address_2:"Adrese (2. rinda)",
  _City:"Pilsēta",
  _State:"State/Prov",
  City:"Pilsēta/pilsēta",
  State:"Valsts/province",
  Postal:"Pasta/pasta indekss",
  ZIP:"Pasts / ZIP",
  Country:"Valsts",
  Addresses:"Adreses",
  Required_Options:"Nepieciešamās opcijas",
  Optional_Options:"Izvēles opcijas",
  Additional_Options:"Papildu opcijas",
  Required:"Obligāts",
  Optional:"Neobligāti",
  Additional:"Papildus",
  No_Addresses:"Nav adreses",
  New_Address_Word:"Pievienot pirmo <a routerLink='/folder/Address/New'>Adresi</a>.",
  Select_Address:"Izvēlēties adresi",
  No_Address:"Nav adreses",
  Print_Cheque:"Drukāšanas čeks",
  Print_Cheque_Now:"Drukāt pārbaudi tūlīt",
  Description:"Apraksts",
  Pay_To_The_Order_Of:"Maksāt pēc pasūtījuma:",
  Select_Date_Range:"Atlasīt datumu diapazonu",
  Select_Starting_Date:"Izvēlieties sākuma datumu",
  Select_Ending_Date:"Izvēlieties beigu datumu",
  Select_Date:"Izvēlēties datumu",
  Cheque_Date:"Pārbaudīt datumu",
  Cheque_Memo:"Pārbaudīt piezīmi",
  Blank_Cheque:"Tukšs čeks",
  Blank:"Tukšs",
  No_Cheques:"Nav čeku",
  No_Cheques_Word:"Izdrukājiet savu pirmo <a routerLink='/folder/Cheque/New'>Čeku</a>.",
  Cheque_Amount_Placeholder:"0,00 USD",
  View_Image:"Skatīt attēlu",
  View:"Skatīt",
  Modify:"Modificēt",
  Delete:"Dzēst",
  Cheque_Paid:"Apmaksāts",
  New_Deduction:"Jauns atskaitījums",
  Title:"Nosaukums",
  Frequency:"Biežums",
  Hourly:"Stundu",
  Daily:"Ikdienas",
  Weekdays:"Nedēļas dienas",
  BiWeekly:"2 nedēļas",
  TriWeekly:"3 nedēļas",
  Monthly:"Mēnesī",
  MiMonthly:"2 mēneši",
  Quarterly:"Ceturkšņa",
  Yearly:"Katru gadu",
  Every_Week:"Katru nedēļu",
  Every_Payroll:"Katrs algas saraksts",
  Every_Month:"Katru mēnesi",
  Annually:"Katru gadu",
  Always_Scheduled:"Vienmēr ieplānots",
  Start_Date:"Sākuma datums",
  End_Date:"Beigu datums",
  Start_Time:"Sākuma laiks",
  End_Time:"Beigu laiks",
  Deduction_Label:"Atskaitīšanas etiķete",
  Notes:"Piezīmes",
  Options:"Opcijas",
  Enable:"Iespējot",
  Select_Deductions:"Atlasīt atskaitījumus",
  Deductions:"Atskaitījumi",
  No_Deductions:"Bez atskaitījumiem",
  No_Deductions_Word:"Izveidojiet savu pirmo <a routerLink='/folder/Deduction/New'>Atskaitījumu</a>.",
  New_Employee:"Jauns darbinieks",
  No_Title:"Bez nosaukuma",
  _Name:"Vārds",
  About_Yourself:"Par sevi",
  Full_Name:"Pilnais vārds",
  Birthday:"Dzimšanas diena",
  Email:"E-pasts",
  SMS:"ĪSZIŅA",
  Phone:"Tālrunis",
  Test:"Pārbaude",
  Call:"Zvanīt",
  Fax:"Fakss",
  Printed_Note:"Drukāta piezīme",
  Position:"Amats",
  Job_Position:"Darba pozīcija",
  Select_a_Job:"Izvēlieties darbu",
  Select_a_Salary:"Izvēlieties algu",
  Add_a_Deduction:"Pievienot atskaitījumu",
  Taxes:"Nodokļi",
  Add_Taxes:"Pievienot nodokļus",
  Date_of_Birth:"Dzimšanas datums",
  Email_Address:"Epasta adrese",
  Phone_Number:"Telefona numurs",
  Phone_Call:"Telefona zvans",
  Enable_on_Payroll:"Iespējot algu sarakstā",
  Select_Employees:"Atlasīt darbiniekus",
  No_Employees:"Nav darbinieku",
  No_Employees_Word:"Pievienojiet savu pirmo jauno <a routerLink='/folder/Employee/New'>Darbinieku</a>.",
  No_Name:"Nav vārda",
  Unemployeed:"Bezdarbnieks",
  Employeed:"Nodarbinātais",
  Paid:"Apmaksāts",
  Enabled:"Iespējots",
  Disabled:"Invalīds",
  Fire:"Uguns",
  Not_Available:"Nav pieejams",
  Not_Available_Word:"Izdrukājiet savu pirmo <a routerLink='/folder/Invoice/New'>Rēķinu</a> un saņemiet samaksu.",
  Select_Invoices:"Select_Invoices",
  Print_Invoice_Word:"Izdrukājiet savu pirmo <a routerLink='/folder/Invoice/New'>Rēķinu</a> un saņemiet samaksu.",
  Invoice_Title:"Rēķina nosaukums",
  Invoice_Date:"Rēķina datums",
  Due_Date:"Gala termiņš",
  New_Job:"Jauns darbs",
  Details:"Detaļas",
  Customize:"Pielāgot",
  Customize_Dashboard:"Pielāgot informācijas paneli",
  Components:"Sastāvdaļas",
  No_Components:"Nav komponentu",
  Main_Components:"Galvenās sastāvdaļas",
  Smaller_Components:"Mazākas sastāvdaļas",
  Error_Loading_Page:"Kļūda, ielādējot šo lapu.",
  Bank_Details:"Bankas informācija",
  About_Your_Job:"Par tavu darbu",
  Your_Schedule:"Tavs grafiks",
  Job_Title:"Amata nosaukums",
  Job_Description:"Darba apraksts",
  Job_Details:"Sīkāka informācija par darbu",
  Enable_Job:"Iespējot darbu",
  Pay_Period:"Apmaksas periods",
  Perpetually_Schedule:"Pastāvīgs grafiks",
  Select_Jobs:"Atlasīt darbu",
  No_Jobs:"Nav darba",
  Add_Jobs:"Pievienot darbus",
  No_Jobs_Word:"Pievienot sarakstam pirmo <a routerLink='/folder/Job/New'>Darbu</a>.",
  Count_Employees:"job.employee_count+' Darbinieki'",
  Zero_Employees:"0 darbinieku",
  New_Leave:"Jaunais atvaļinājums",
  Leave_Name:"Atstāt vārdu",
  Paid_Leave:"Apmaksāts atvaļinājums",
  Leave_Pay:"Atstāt samaksu",
  Indefinite_Leave:"Nenoteikts atvaļinājums",
  Indefinite_Payroll:"Nenoteikts algu saraksts",
  Leave:"Aiziet",
  Add_Schedules:"Pievienot grafikus",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Iespējot prombūtni",
  Select_Leaves:"Izvēlieties lapas",
  No_Leaves:"Bez prombūtnes lapām",
  Leave_Of_Absence:"Prombūtnes atvaļinājums",
  Leaves_Of_Absence:"Prombūtnes lapas",
  New_Leave_of_Absense:"Jauns prombūtnes atvaļinājums",
  No_Leaves_Word:"Pievienojiet pirmo <a routerLink='/folder/Leave/New'>Prombūtnes Atvaļinājums</a>.",
  Not_Enabled:"Nav iespējots",
  Absences:"Prombūtnes",
  Payee:"Maksājuma saņēmējs",
  New_Payee:"Jauns maksājuma saņēmējs",
  Payee_Identifier:"Maksājuma saņēmēja identifikators",
  Payee_Name:"Maksājuma saņēmēja vārds",
  Payee_Title:"Maksājuma saņēmēja nosaukums",
  Payment_Memo:"Maksājuma piezīme",
  Select_Payees:"Atlasīt maksājumu saņēmējus",
  No_Payees:"Nav maksājumu saņēmēju",
  Add_Payee_Note:"Pievienojiet pirmo <a routerLink='/folder/Payee/New'>Maksājuma Saņēmējs</a>.",
  New_Payees:"Jaunie maksājumu saņēmēji",
  About_The_Payment_Schedule:"Maksājumu grafiks",
  Your_Payroll_Schedule:"Automātiskā algu aprēķināšana",
  New_Payment:"Jauns maksājums",
  Identifier:"identifikators",
  Select:"Izvēlēties",
  Memo:"Piezīme",
  Payment_Date:"Maksājuma datums",
  Mark_as_Paid:"Atzīmēt kā apmaksātu",
  Select_Payments:"Atlasīt maksājumus",
  No_Payments:"Nav maksājumu",
  No_Payments_Word:"Izveidot pirmo <a routerLink='/folder/Payment/New'>Maksājums</a>.",
  Create_Payroll:"Izveidot algas sarakstu",
  Properties:"Īpašības",
  Payroll_Title:"Algas nosaukums",
  Payroll_Notes:"Piezīmes par algām",
  Payroll_Setup:"Algas iestatīšana",
  Tabulate_Payments:"Maksājumi tabulā",
  Tabulate:"Tabula",
  By:"Autors:",
  Payments_By:"Maksājumi līdz",
  Timesheets_And_Schedules:"Laika uzskaites tabulas un grafiki",
  Both:"Abi",
  Items:"Preces",
  Add_Payees:"Pievienot maksājumu saņēmējus",
  Add_Account:"Pievienot kontu",
  Enable_Account:"Iespējot kontu",
  Enable_Payroll:"Iespējot algu sarakstu",
  Print_Payroll:"Drukāt algas sarakstu",
  No_Payrolls:"Nav algas",
  No_Payroll_Word:"Create your first <a routerLink='/folder/Payroll/New'>Algu saraksts</a>.",
  View_more:"VIEW_MORE",
  Less:"MAZĀK",
  Add_Payroll:"Pievienot algas sarakstu",
  Select_Payroll:"Izvēlēties algas sarakstu",
  About_Your_Salary:"Par jūsu algu",
  Add_Salaries:"Pievienot algas",
  Add_Salary:"Pievienot algu",
  Salaries:"Algas",
  No_Salaries:"Nav algu",
  No_Salaries_Word:"Pievienot pirmo <a routerLink='/folder/Salary/New'>Algu</a>.",
  Select_Salaries:"Izvēlēties algas",
  New_Salary:"Jaunā alga",
  Salary_Name:"Algas identifikators",
  Enable_Salary:"Iespējot algu",
  Salary_Amount:"Algas summa",
  New_Schedule:"Jauns grafiks",
  Schedule_Title:"Grafika nosaukums",
  Add_Address:"Pievienot adresi",
  Repeat:"Atkārtot",
  Design:"Dizains",
  Edit_Design:"Rediģēt dizainu",
  Edit_this_Design:"Rediģēt šo dizainu",
  Repeat_Payment:"Atkārtots maksājums",
  Enable_Schedule:"Iespējot grafiku",
  Never:"Nekad",
  Select_Schedule:"Atlasīt grafikus",
  No_Schedules:"Nav grafiku",
  No_Schedules_Word:"Izveidot pirmo <a routerLink='/folder/Schedule/New'>Grafiku</a>.",
  New_Administrator:"Jaunais administrators",
  Username:"Lietotājvārds",
  First_Name:"Vārds",
  Last_Name:"Uzvārds",
  Add_an_Address:"Pievienot adresi",
  Payment_Limit:"Maksājuma limits",
  Total_Limit:"Kopējais limits",
  Select_Accounts:"Atlasīt kontus",
  No_Administrators:"Nav administratoru",
  No_Administrators_Word:"Izveidojiet pirmo <a routerLink='/folder/Administrator/New'>Administratora kontu</a>.",
  New_Administrators_Word:"Pievienot pirmo <a routerLink='/folder/Administrator/New'>Administratoru</a>",
  Cloud:"Mākonis",
  Backup:"Rezerves kopija",
  Enable_iCloud:"Iespējot iCloud",
  Enable_Google_Drive:"Iespējot Google disku",
  Enable_Microsoft_OneDrive:"Iespējot Microsoft OneDrive",
  Automatically_Backup:"Automātiski dublēt",
  FTP_Settings:"FTP iestatījumi",
  URL_File_Prompt:"Lūdzu, norādiet importējamā .xls/.xlsx/.json faila atrašanās vietu:",
  URL_SQL_Prompt:"Lūdzu, norādiet importējamā SQLite faila atrašanās vietu:",
  FTP_Backup:"FTP dublēšana",
  FTP_Import:"FTP importēšana",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Saimnieks",
  Port:"Osta",
  Path:"Ceļš",
  Data_Path:"Datu ceļš",
  Enable_FTP_Account:"Iespējot FTP kontu",
  HostnameIP:"Saimniekdatora nosaukums",
  Password:"Parole",
  Connection_Port:"Savienojuma ports",
  Enable_MySQL_Database:"Iespējot MySQL datu bāzi",
  Log:"Žurnāls",
  Reset:"Atiestatīt",
  Erase:"Dzēst",
  Export:"Eksportēt",
  Database:"Datu bāze",
  Upload_CSV:"Augšupielādēt CSV",
  Download_CSV:"Lejupielādēt CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL dublējums",
  Internal_Notes:"Iekšējās piezīmes",
  Root_Path:"Saknes ceļš",
  Select_Backup:"Atlasīt dublējumu",
  Add_New_Backup:"Pievienot jaunu dublējumu",
  First_Backup:"Iestatiet pirmo dublējumu...",
  Backups:"Dublējumi",
  Add_Backup:"Pievienot dublējumu",
  No_Backups:"Nevar atrast dublējumus.",
  Select_Backup_Type:"Atlasiet dublējuma veidu, kuru vēlaties iestatīt...",
  Backup_Type:"Dublējuma veids",
  FTP_Drive:"FTP disks",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Braukt",
  Microsoft_OneDrive:"Braukt",
  Import_Fields:"Importa lauki",
  Import_Fields_Word:"Izmantojiet šo sadaļu, lai atlasītu <a routerLink='/folder/Holiday/New'>Importēt</a> datus.",
  Upload:"Augšupielādēt",
  Download:"Lejupielādēt",
  Download_JSON:"Lejupielādēt kā JSON datus",
  Download_SQL:"Lejupielādēt kā SQL failu",
  New_Bank:"Jaunā banka",
  New_Account:"Jauns konts",
  New_Bank_Account:"Jauns bankas konts",
  Upload_Image:"Augšupielādēt attēlu",
  Bank_Name:"Bankas nosaukums",
  Bank_Address:"Bankas adrese",
  Branch_Address:"Filiāles adrese",
  Address_on_Cheque:"Adrese",
  Cheque_Numbers:"Pārbaudīt numurus",
  Cheque_Details:"Pārbaudīt detaļas",
  Bank_Logo:"Bankas logotips",
  Cheque_ID:"Pārbaudīt ID",
  Starting_Cheque_ID:"Sākuma pārbaudes ID",
  Transit_Number:"Tranzīta numurs",
  Institution_Number:"Iestādes numurs",
  Designation_Number:"Apzīmējuma numurs",
  Account_Number:"Konta numurs",
  Limits:"Robežas",
  Default_Limits:"Noklusējuma ierobežojumi",
  Over_Limit:"Pārsniedz limitu",
  Under_Limit:"Saskaņā ar limitu",
  Payroll_Limit:"Algas limits",
  Enable_Bank_Account:"Iespējot bankas kontu",
  Select_Account:"Izvēlēties kontu",
  No_Bank_Account:"Nav bankas konta",
  No_Bank_Account_Word:"Pievienot pirmo <a routerLink='/folder/Accounts/New'>Bankas Kontu</a>.",
  Bank_Accounts:"Bankas konti",
  No_Accounts:"Nav kontu",
  No_Accounts_Note:"Pievienot pirmo <a routerLink='/folder/Accounts/New'>Bankas Kontu</a>.",
  Cheque_Designer:"Cheku dizainers",
  Cheque_Design:"Pārbaudīt dizainu",
  Select_Design:"Izvēlieties dizainu",
  Cheque_Designs:"Pārbaudīt dizainus",
  No_Cheque_Designs:"Nav čeku dizainparaugu",
  No_Cheque_Designs_Word:"Izveidojiet savu <a routerLink='/folder/Settings/Cheque/Design/New'>Pārbaudiet Dizainu</a>.",
  Set_Default:"Iestatīta pēc noklusējuma",
  Default:"Noklusējums",
  Remove:"Noņemt",
  Folder:"Mape",
  Edit:"Rediģēt",
  LoadingDots:"Notiek ielāde...",
  Add_a_New_File:"Pievienot <a href='#' (click)='add()'>Jaunu failu</a>",
  this_folder:"šī mape",
  FTP_Backup_Settings:"FTP dublēšanas iestatījumi",
  Secure_File_Transfer:"Droša failu pārsūtīšana",
  New_Holiday:"Jaunie svētki",
  Add_Holiday:"Pievienot brīvdienu",
  Holiday_Name:"Svētku vārds",
  Additional_Pay:"Papildu samaksa",
  Enable_Holiday:"Iespējot brīvdienu",
  Select_Holidays:"Izvēlēties brīvdienas",
  No_Holidays:"Bez brīvdienām",
  No_Holidays_Word:"Pievienot pirmo <a routerLink='/folder/Holiday/New'>Valsts Svētku Dienu</a>.",
  New_Import:"Jauns imports",
  Import_Data:"Importēt datus",
  Import_Data_Word:"Atlasiet faila veidu vai augšupielādes metodi pēc savas izvēles.<br /> Pēc atbalstītā faila augšupielādes varēsiet atlasīt visus importētos faila laukus, kas atbilst jebkuram parametram lietotnē.",
  Import_File:"Importēt failu",
  Link_To_File:"Saite uz failu",
  Select_File:"Atlasīt failu",
  New_Moderator:"Jaunais moderators",
  Allow_Moderation:"Atļaut regulēšanu",
  Create_Paycheques:"Izveidot algas čekus",
  Edit_Paycheques_and_Data:"Rediģēt algas un datus",
  Destroy_Data_and_Paycheques:"Iznīcināt datus un algas čekus",
  Bonus_Percentage:"Algas čeka procenti",
  Fixed_Amount:"Fiksētā summa",
  Select_Moderator:"Izvēlēties moderatoru",
  No_Moderators:"Nav moderatoru",
  Moderators:"Moderatori",
  Moderator_Account:"Izveidojiet pirmo <a routerLink='/folder/Administrator/New'>Moderatora kontu</a>.",
  No_Moderators_Word:"Pievienot pirmo <a routerLink='/folder/Administrator/New'>Moderatoru</a>.",
  Defaults:"Noklusējumi",
  Provide_Us_Info:"Sniedziet mums informāciju",
  Setup_Your_Printer:"Iestatīt savu printeri",
  Your_Company:"Par jūsu uzņēmumu",
  Company_Name:"Kompānijas nosaukums",
  Currency:"Valūta",
  Default_Currency:"Noklusējuma valūta",
  Base_Monthly_Income:"Mēneša ienākumi",
  Protection:"Aizsardzība",
  App_Protection:"Lietotņu aizsardzība",
  PIN_Code_Protection:"PIN koda aizsardzība",
  App_Protection_Word:"Iespējot drošības metodes, kas palīdz aizsargāt jūsu kontu.",
  PIN_Code:"PIN kods",
  Change_PIN:"Mainīt PIN",
  New_Password:"Jauna parole",
  Geofence_Protection:"Ģeo-žoga aizsardzība",
  Geofence_Area:"Iestatīt apgabalu",
  Distance:"Attālums",
  Setup_Now:"Iestatīt tūlīt",
  Mile:"Jūdze",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Sejas atpazīšana",
  Face:"Seja",
  Setup:"Uzstādīt",
  Label:"Etiķete",
  Password_Protection:"Paroles aizsardzība",
  Modify_Password:"Mainīt paroli",
  New_Tax_Entry:"Jauns nodokļu ieraksts",
  New_Tax:"Jaunais nodoklis",
  Tax_Label:"Nodokļu etiķete",
  Perpetually_Enabled:"Mūžīgi iespējots",
  Select_Taxes:"Atlasīt nodokļus",
  Tax_Deductions:"Nodokļu atskaitījumi",
  No_Tax_Deductions:"Nav nodokļu atskaitījumu",
  No_Tax_Deductions_Word:"Pievienojiet pirmo <a routerLink='/folder/Tax/New'>Nodokļu</a> atskaitījumu.",
  New_Timer:"Jauns taimeris",
  Start:"Sākt",
  Stop:"Apstāties",
  Start_Timer:"Start Timer",
  Stop_Timer:"Apturēt taimeris",
  Timer_Active:"Taimeris aktīvs",
  Timer:"Taimeris:",
  Timer_Running:"Taimeris: (Running)",
  Save_Timer:"Saglabāt taimeri",
  New_Timesheet:"Jaunā laika uzskaite",
  Select_Timesheets:"Izvēlēties laika uzskaites tabulas",
  Work_Notes:"Darba piezīmes",
  Entry_Title:"Ieraksta nosaukums",
  No_Timesheets:"Nav laika uzskaites",
  No_Timesheets_Word:"Pievienot pirmo <a routerLink='/folder/Timesheet/New'>Laika Lapu</a>.",
  Timesheet_Submitted:"Laika uzskaite ir iesniegta",
  Timesheet_Congrats:"Apsveicam! Jūsu laika uzskaite ir veiksmīgi iesniegta. Paldies!",
  Timesheet_Copy:"Lai saņemtu savu dokumentu kopijas, lūdzu, norādiet mums savu e-pasta adresi un/vai mobilā tālruņa numuru.",
  Submit:"Iesniegt",
  Allow_Notifications:"Atļaut paziņojumus",
  Untitled_Entry:"Jauns ieraksts",
  Untitled_Bank:"Banka bez nosaukuma",
  Timesheet_Entry:"Ieraksts laika tabulā",
  Work_Description:"Darba Apraksts",
  Enable_Timesheet:"Iespējot laika tabulu",
  Submit_Timesheet:"Iesniegt laika tabulu",
  Vacation:"Atvaļinājums",
  New_Vacation:"Jaunas brīvdienas",
  Vacation_Name:"Brīvdienas nosaukums",
  Paid_Vacation:"Apmaksāts atvaļinājums",
  Vacation_Pay:"Atvaļinājuma nauda",
  Enable_Vacation:"Iespējot atvaļinājumu",
  Select_Vacations:"Atlasīt brīvdienas",
  No_Vacations:"Bez brīvdienām",
  No_Vacations_Word:"Izveidojiet pirmo <a routerLink='/folder/Vacation/New'>Atvaļinājums</a> ierakstu.",
  Payroll_Schedule:"Algu grafiks",
  Next_Payroll:"Nākamais algas saraksts:",
  Upcoming_Payroll:"Gaidāmais algu saraksts",
  No_Upcoming_Payroll:"Nav gaidāma algu saraksta",
  Address_Book:"Adrešu grāmata",
  Archived_Documents:"Arhivētie dokumenti",
  Dev_Mode:"Lietojumprogramma izstrādes režīmā",
  Administrators:"Administratori",
  Privacy:"Privātums",
  None:"Nav",
  Select_Signature:"Atlasīt parakstu",
  No_Signatures:"Nav parakstu",
  No_Signatures_Word:"Pievienot pirmo <a routerLink='/folder/Signature/New'>Parakstu</a>.",
  Repeat_Indefinitely:"Atkārtot bezgalīgi",
  Sign:"Parakstīt",
  Sign_Here:"Parakstieties šeit",
  Date_Signed:"Parakstīšanas datums",
  Signature_Pad:"Paraksta bloks",
  Account_Holder:"Konta īpašnieks",
  Account_Properties:"Konta rekvizīti",
  Name_On_Cheque:"Vārds uz čeka",
  Server_Hostname:"Servera resursdatora nosaukums / IP",
  Printers:"Printeri",
  No_Printers:"Nav printeru",
  Printer_Exists:"Printeris ar šādu nosaukumu jau pastāv.",
  No_Printers_Word:"Pievienojiet pašu pirmo <a routerLink='/folder/Printer/New'>Printeri</a>.",
  No_Printer_Alert:"Neviens printeris nav definēts. Vai vēlaties iestatīt printeri?",
  Add_Printer:"Pievienot printeri",
  New_Printer:"Jauns printeris",
  Printer_Hostname:"Printera resursdatora nosaukums/IP",
  Printer_Label:"Printera etiķete",
  Printer_Protocol:"Printera protokols",
  Protocol:"Protokols",
  Email_Print:"E-pasts",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Socket",
  Print_Job:"Drukas darbs",
  Printed:"Iedrukāts",
  Not_Printed:"Nav drukāts",
  Print_Jobs:"Drukas darbi",
  Print_Queue:"Drukas rinda",
  No_Print_Jobs:"Nav drukas darbu",
  No_Prints:"Izveidojiet jaunu <a routerLink='/folder/Cheque/New'>Čeku</a>, lai drukātu.",
  Prints:"Izdrukas",
  Find_Printer:"Atrast printeri",
  Find_AirPrint_Devices:"Atrast AirPrint ierīces",
  Select_Printer:"Izvēlēties printeri",
  System_UI:"Sistēmas lietotāja saskarne",
  Printer:"Printeris",
  Status:"Statuss",
  Preview:"Priekšskatījums",
  Enable_Print_Job:"Iespējot drukas darbu",
  Queue_Weight:"Rindas svars",
  Unlimited:"Neierobežots",
  Show_Advanced_Printer_Options:"Rādīt papildu printera opcijas",
  Advanced:"Papildu",
  Location:"Atrašanās vieta",
  Note:"Piezīme",
  Queue_Name:"Rindas nosaukums",
  Pages_Printed_Limit:"Lapu drukāšanas limits",
  MultiPage_Idle_Time:"Vairāku lappušu gaidīšanas laiks (s)",
  Page_Count_Limit:"Lapu skaita ierobežojums",
  Page_Orientation:"Lapas orientācija",
  Portrait:"Portrets",
  Landscape:"Ainava",
  Duplex:"Duplekss",
  Double_Sided_Printing:"Abpusējs",
  Duplex_Mode:"Dupleksais režīms",
  Duplex_Short:"Īss",
  Duplex_Long:"Ilgu",
  Duplex_None:"Nav",
  Color_And_Quality:"Krāsa un kvalitāte",
  Monochrome:"Vienkrāsains",
  Photo_Quality_Prints:"Fotoattēlu kvalitātes izdrukas",
  Printer_Email:"Printera e-pasts",
  Automatically_Downsize:"Automātiski samazināt",
  Paper:"Papīrs",
  Paper_Name:"Papīra nosaukums",
  Paper_Width:"Papīra platums",
  Paper_Height:"Papīra augstums",
  Paper_Autocut_Length:"Papīra automātiskā griešanas garums",
  Margins:"Maržas",
  Page_Margins:"Lapu malas",
  Page_Margin_Top:"Augšējās lapas piemale",
  Page_Margin_Right:"Labās lapas mala",
  Page_Margin_Bottom:"Apakšējās lapas piemale",
  Page_Margin_Left:"Kreisā lapas mala",
  Add_Employees:"Pievienot darbiniekus",
  Header:"Galvene",
  Print_A_Page_Header:"Drukāt lapas galveni",
  Header_Label:"Galvenes etiķete",
  Header_Page_Index:"Galvenes lapas indekss",
  Header_Font_Name:"Galvenes fonts",
  Select_Font:"Izvēlēties fontu",
  Font_Selector:"Fontu atlasītājs",
  Header_Font_Size:"Galvenes fonts",
  Header_Bold:"Galvene treknrakstā",
  Header_Italic:"Galvene slīpraksts",
  Header_Alignment:"Galvenes izlīdzināšana",
  Left:"Pa kreisi",
  Center:"Centrs",
  Right:"Taisnība",
  Justified:"Pamatots",
  Header_Font_Color:"Galvenes krāsa",
  Select_Color:"Izvēlēties krāsu",
  Footer:"Kājene",
  Print_A_Page_Footer:"Drukāt lapas kājeni",
  Footer_Label:"Kājenes etiķete",
  Footer_Page_Index:"Kājenes lapas indekss",
  Footer_Font_Name:"Kājenes fonts",
  Fonts:"Fonti",
  Done:"Gatavs",
  Select_Fonts:"Atlasīt fontus",
  Footer_Font_Size:"Kājenes izmērs",
  Footer_Bold:"Footer Bold",
  Footer_Italic:"Footer Italic",
  Footer_Alignment:"Kājenes izlīdzināšana",
  Footer_Font_Color:"Kājenes krāsa",
  Page_Copies:"Lapu kopijas",
  Enable_Printer:"Iespējot printeri",
  Remote_Logging:"Attālā reģistrēšana",
  Log_Server:"Žurnāla serveris",
  Encryption:"Šifrēšana",
  Random_Key:"Izlases atslēga",
  Encryption_Key:"Šifrēšanas atslēga",
  Cheques_Webserver:"Pielāgota datu bāze",
  Learn_How:"Iemācies kā",
  Signature:"Paraksts",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Skatīt parakstu",
  Printed_Signature:"Drukātais paraksts",
  Digitally_Sign:"Digitāli parakstīties",
  Digital_Signature:"Digitālais paraksts",
  Add_Signature:"Pievienot parakstu",
  Add_Your_Signature:"Pievienot savu parakstu",
  Enable_Signature:"Iespējot parakstu",
  Digitally_Signed:"Parakstīts digitāli",
  Insert_Error:"Nevar saglabāt čeku datu bāzes problēmu dēļ.",
  Delete_Confirmation:"Vai tiešām vēlaties dzēst šo informāciju?",
  Discard_Confirmation:"Vai tiešām vēlaties atmest šo informāciju?",
  Discard_Bank_Confirmation:"Vai tiešām vēlaties atmest šo kontu?",
  Discard_Printer_Confirmation:"Vai tiešām vēlaties izmest šo printeri?",
  Delete_Bonus_Confirmation:"Vai tiešām vēlaties dzēst šo bonusu?",
  Delete_Invoice_Confirmation:"Vai tiešām vēlaties dzēst šo rēķinu?",
  Generated_Cheque:"Ģenerēts čeks",
  Generated_Invoice:"Ģenerēts rēķins",
  Schedule_Start_Time:"Ieplānot sākumu",
  Schedule_End_Time:"Grafika beigas",
  New_Call:"Jauns zvans",
  No_Contacts:"Nav kontaktu",
  No_Contacts_Word:"Administratori, moderatori, darbinieki un maksājumu saņēmēji tiek rādīti kā kontaktpersonas.",
  PDF_Subject:"finanses",
  PDF_Keywords:"algas algas čeku PDF čeku čeki",
  Printer_Setup:"Printera iestatīšana",
  Printer_Selection:"Printera izvēle",
  New_Fax:"Jauns fakss",
  New_Fax_Message:"Jauns faksa ziņojums",
  Fax_Machine:"Faksa aparāts",
  Fax_Name:"Faksa nosaukums",
  Fax_Email:"Faksa e-pasts",
  Fax_Number:"Faksa numurs",
  Contents:"Saturs",
  Fax_Body:"Lapas pamatteksts",
  Header_Word:"Galvene:",
  Header_Text:"Galvenes teksts",
  Include_Header:"Iekļaut galveni",
  Include_Footer:"Iekļaut kājeni",
  Footer_Word:"Kājene:",
  Footer_Text:"Kājenes teksts",
  Attach_a_Cheque:"Pievienot čeku",
  Add_Deduction:"Pievienot atskaitījumu",
  Enable_Fax:"Sūtīt faksu",
  Select_Fax:"Izvēlēties faksu",
  No_Faxes:"Nav faksu",
  Faxes:"Faksi",
  Save_and_Send:"Sūtīt faksu",
  Save_and_Pay:"Saglabāt un maksāt",
  WARNING:"BRĪDINĀJUMS:",
  Remember:"Atceries",
  Printing:"Drukāšana",
  Printing_Complete:"Drukāšana pabeigta!\n\n",
  of:"no",
  There_Were:"Tur bija ",
  Successful_Prints:"veiksmīgas izdrukas un",
  Unsuccessful_Prints:"neveiksmīgas izdrukas.",
  PrinterError:"Atvainojiet! Radās kļūda.",
  Printing_:"Drukāšana...",
  PrinterSuccess:"Dokuments veiksmīgi izdrukāts.",
  PrintersSuccess:"Dokumenti veiksmīgi izdrukāti.",
  Print_Cheques:"Drukāt čekus",
  New_Message:"Jauna ziņa",
  New_Messages:"Jaunas ziņas",
  Read_Message:"Izlasīt ziņojumu",
  Write_Message:"Rakstīt ziņu",
  Send_Message:"Sūtīt ziņu",
  Subject:"Tēma",
  Message:"Ziņojums",
  Writing:"Rakstu...",
  Send:"Sūtīt",
  Set_Date:"Iestatīt datumu",
  Set_Time:"Uzstādīt laiku",
  Recieve:"Saņemt",
  Set_as_Default:"Iestatīta pēc noklusējuma",
  Default_Account:"Noklusējuma konts",
  Default_Design:"Noklusējuma dizains",
  Multiple_Cheques:"Pārbaudes (trīskārši)",
  Account_Mode:"Konta režīms",
  Multiple_Cheques_Description:"Trīs pārbaudes katrā lapā.",
  Check_and_Table:"Pārbaude un tabula",
  Check_including_Table:"Pārbaudes un uzskaites tabula.",
  Check_Mailer:"Pa pastu nosūtīti čeki",
  Check_Mailer_Window:"Pārbaudīt ar adreses logu.",
  DocuGard_Table_Top:"DocuGard pārbaude un tabula (augšā)",
  DocuGard_Table_Middle:"DocuGard pārbaude un tabula (vidējais)",
  DocuGard_Table_Bottom:"DocuGard pārbaude un tabula (apakšā)",
  DocuGard_Mailer_Top:"DocuGard Pa pastu nosūtīti čeki (augšā)",
  DocuGard_Mailer_Middle:"DocuGard Pa pastu nosūtīti čeki (vidējais)",
  DocuGard_Mailer_Bottom:"DocuGard Pa pastu nosūtīti čeki (apakšā)",
  DocuGard_Three_Cheques:"DocuGard pārbaudes (trīskārši)",
  DocuGard_Cheque_Top:"DocuGard pārbaude (augšā)",
  DocuGard_Cheque_Middle:"DocuGard pārbaude (vidējā)",
  DocuGard_Cheque_Bottom:"DocuGard pārbaude (apakšā)",
  DocuGard_Three_Cheques_Window:"Trīs čeki vienā lapā.",
  DocuGard_Table_Top_Window:"Check & pelnu tabula.",
  DocuGard_Table_Middle_Window:"Check & pelnu tabula.",
  DocuGard_Table_Bottom_Window:"Check & pelnu tabula.",
  DocuGard_Mailer_Top_Window:"Pārbaude, tabula un adrese.",
  DocuGard_Mailer_Middle_Window:"Pārbaude, tabula un adrese.",
  DocuGard_Mailer_Bottom_Window:"Pārbaude, tabula un adrese.",
  DocuGard_Cheque_Top_Window:"Pārbaudiet drošu papīru.",
  DocuGard_Cheque_Middle_Window:"Pārbaudiet drošu papīru.",
  DocuGard_Cheque_Bottom_Window:"Pārbaudiet drošu papīru.",
  Basic_Cheque:"Pārbaudīt (augšā)",
  Basic_Cheque_Print:"Izdrukājiet vienu čeku.",
  Error_Setting_As_Paid:"Kļūda, iestatot kā apmaksātu",
  Add_Attachment:"Pievienot pielikumu",
  PrinterUnavailable:"Printeris nav pieejams",
  CreditCardComponent:"Kartes",
  PaypalComponent:"PayPal",
  InteracComponent:"Interaka",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Jauns depozīts",
  Deposits:"Noguldījumi",
  No_Deposits:"Nav noguldījumu",
  Credit_Card_Deposit:"Kredītkarte",
  New_Credit_Card_Deposit_Message:"Depozīts ar kredītkarti",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin depozīts",
  New_Interac_Deposit:"Interaka",
  New_Interac_Deposit_Message:"Interac Transfer",
  Payments_Limit:"Maksājuma limits",
  No_Payment_Limit:"Nav maksājumu ierobežojuma",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal depozīts",
  No_Deposits_Word:"Pievienot pirmo uzņēmuma <a routerLink='/folder/Deposit/New'>Depozītu</a>.",
  No_Documents_Specified:"Drukāšanai netika norādīts neviens dokuments",
  No_Printers_Added:"Netika atrasts neviens printeris. Lai to pievienotu, atveriet sadaļu Iestatījumi > Printeri.",
  DB_Erase_Prompt:"Vai pilnībā izdzēst visu datu bāzi? BRĪDINĀJUMS: jūs zaudēsit visu saglabāto informāciju!",
  Console_Warning:"Neielīmējiet nekādu tekstu šajā konsolē. Jūs varat pakļaut sevi un/vai savu uzņēmumu nopietnam riskam.",
  No_Faxes_Word:"Izveidot pirmo <a routerLink='/folder/Fax/New'>Faksu</a>.",
  Cheque_Delete_Confirmation:"Vai tiešām vēlaties dzēst šo čeku?",
  Design_Delete_Confirmation:"Vai tiešām vēlaties dzēst šo dizainu?",
  Cheque_Pay_Confirmation:"Atzīmēt šo čeku kā apmaksātu? Tas NEBŪS redzams drukas rindā.",
  Payment_Pay_Confirmation:"Atzīmēt šo maksājumu kā apmaksātu? Tas NEBŪS redzams čeku rindā.",
  Delete_Deduction_Confirmation:"Vai tiešām vēlaties dzēst šo atskaitījumu?",
  Delete_Job_Confirmation:"Vai tiešām vēlaties dzēst šo darbu?",
  Delete_Timesheet_Confirmation:"Vai tiešām vēlaties dzēst šo laika uzskaiti?",
  Delete_Schedule_Confirmation:"Vai tiešām vēlaties dzēst šo grafiku?",
  Delete_Setting_Confirmation:"Vai tiešām vēlaties atiestatīt šo iestatījumu?",
  Delete_Fax_Confirmation:"Vai tiešām vēlaties dzēst šo faksu?",
  Delete_File_Confirmation:"Vai tiešām vēlaties dzēst šo failu?",
  Delete_Vacation_Confirmation:"Vai tiešām vēlaties dzēst šo atvaļinājumu?",
  Delete_Printer_Confirmation:"Vai tiešām vēlaties dzēst šo printeri?",
  Remove_Design_Confirmation:"Vai tiešām vēlaties dzēst šo dizainu?",
  Delete_Payroll_Confirmation:"Vai tiešām vēlaties dzēst šo algu sarakstu?",
  Send_Fax_Email_Confirmation:"Vai vēlaties sūtīt šos dokumentus pa faksu un e-pastu?",
  Send_Email_Confirmation:"Vai vēlaties nosūtīt šo dokumentu pa e-pastu?",
  Send_Fax_Confirmation:"Vai vēlaties nosūtīt šo dokumentu pa faksu?",
  Error_Generating_PDF:"Atvainojiet. Veidojot šo dokumentu, radās kļūda.",
  PDF_Error_Saving_Image:"Atvainojiet. Saglabājot šī dokumenta PDF attēlu, radās kļūda.",
  Test_Printer_Confirmation:"Vai vēlaties šajā printerī izdrukāt testa lapu?",
  Save_Timesheet_Prompt:"Lai saglabātu, lūdzu, pievienojiet nosaukumu vai nospiediet pogu Sākt taimeri.",
  Remove_Geofence_Prompt:"Vai tiešām vēlaties noņemt šī ģeogrāfiskā žoga atrašanās vietu?",
  Delete_Employee_Confirmation:"Vai tiešām vēlaties dzēst",
  Fire_Employee_Confirmation:"Vai esat pārliecināts, ka vēlaties atlaist?"
}