export const Vi = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Bản quyền &bản sao; 2023",
  black:"Đen",
  green:"Màu Xanh Lá",
  gold:"Vàng",
  blue:"Màu Xanh Da Trời",
  brown:"Màu Nâu",
  purple:"Màu Tím",
  pink:"Hồng",
  red:"Màu Đỏ",
  Swatches:"Mẫu Màu",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Huế",
  Saturation:"Bão Hòa",
  Lightness:"Nhẹ Nhàng",
  Upgrade_To_Pro:"Nâng cấp lên Pro",
  AllFeaturesInclude:"Tất cả các tính năng bao gồm:",
  PrintUnlimitedCheques:"In séc không giới hạn",
  PremiumChequeDesigns:"Thiết kế kiểm tra cao cấp",
  ManageUnlimitedEmployees:"Quản lý nhân viên không giới hạn",
  AddUnlimitedPayees:"Thêm người được trả tiền không giới hạn",
  CreateUnlimitedPayrolls:"Tạo bảng lương không giới hạn",
  UnlimitedSchedulesandTimeSheets:"Lịch trình và bảng thời gian không giới hạn",
  BulkPayPalPayouts:"Thanh toán PayPal hàng loạt",
  UnlimitedBankAccounts:"Tài khoản ngân hàng không giới hạn",
  ManageMultipleCompanies:"Quản lý nhiều công ty",
  TrackInsurancePolicies:"Theo dõi chính sách bảo hiểm",
  Bio_MetricProtection:"Bảo vệ chỉ số sinh học",
  Geo_FenceLock_OutProtection:"Bảo vệ khóa hàng rào địa lý",
  Multiple_Companies_Word:"Quản lý nhiều công ty không khả dụng nếu không có Checks cao cấp.",
  PayPal_Payouts_Word:"Thanh toán PayPal bị vô hiệu hóa nếu không có Séc cao cấp.",
  PINProtection:"Bảo vệ mã PIN",
  PasswordProtection:"Mật khẩu bảo vệ",
  May_Require_Approval:"Có thể yêu cầu phê duyệt.",
  Subscribe:"Đặt mua",
  Billed:"đã lập hóa đơn",
  Up:"Hướng lên",
  Down:"Xuống",
  Positioning:"định vị",
  Marker:"Đánh dấu",
  Drag_Marker:"Kéo điểm đánh dấu",
  Tagline:"In séc và lập bảng lương",
  Marker_Word:"Sử dụng Markers để định kích thước phần tử.",
  Pinch_Zoom:"Chụm thu phóng",
  Pinch_Word:"Chụm để thu phóng phần tử.",
  Drag:"Lôi kéo",
  Drag_Word:"Sử dụng ngón tay của bạn để kéo các phần tử.",
  subscription_alias_word:"Đăng ký tự động gia hạn",
  premium_alias_word:"Gói nâng cấp một lần",
  print_alias_word:"In ra séc cá nhân",
  mode_alias_word:"Cách thức",
  Pro:"chuyên nghiệp",
  Pro_word:"Yêu cầu phiên bản chuyên nghiệp.",
  Cant_Delete_Default_Company_Word:"Rất tiếc, bạn không thể xóa công ty mặc định của mình.",
  Reinsert_Default_Designs:"Chèn lại các thiết kế mặc định",
  Reinsert_Default_Designs_word:"Bạn có muốn chèn lại các thiết kế mặc định không?",
  Subscription_Active_Disable_Word:"Đăng ký này đang hoạt động. Bạn có muốn hủy đăng ký Séc này không?",
  Company_Logo:"Logo công ty",
  ZERO_:"SỐ KHÔNG",
  Disclaimer:"từ chối trách nhiệm",
  Privacy_Policy:"Chính sách bảo mật",
  EULA:"Séc EULA",
  Terms_Of_Service:"Điều khoản dịch vụ",
  Terms_Of_Use:"Điều khoản sử dụng",
  Refunds:"Chính sách hoàn tiền",
  Single_Print:"1 kiểm tra",
  Two_Prints:"2 lần kiểm tra",
  Five_Prints:"5 kiểm tra",
  Ten_Prints:"10 lần kiểm tra",
  Fifteen_Prints:"15 lần kiểm tra",
  Twenty_Prints:"20 lần kiểm tra",
  Thirty_Prints:"30 lần kiểm tra",
  Image_Added:"Đã thêm hình ảnh",
  Image_Preview:"Xem trước hình ảnh",
  No_Image_Was_Selected:"Không có hình ảnh nào được chọn.",
  Cheques_Unlimited:"séc không giới hạn",
  _Subscription:"Đăng ký",
  Subscription:"Séc - 1 tháng",
  Two_Month_Subscription:"Séc - 2 tháng",
  Three_Month_Subscription:"Séc - 3 tháng",
  Six_Month_Subscription:"Séc - 6 tháng",
  Twelve_Month_Subscription:"Kiểm tra - 12 tháng",
  Cheques_Are_Available:"Séc có sẵn để in.",
  Cheques_Thank_You:"Cảm ơn bạn rất nhiều vì đã mua Séc! Giao dịch mua của bạn hiện đang hoạt động.",
  Upgrade_Required_Two:"Chọn một gói và nhấn đúp vào nút giá để bắt đầu giao dịch mua của bạn. In séc đủ màu chuyên nghiệp trong vài giây.",
  Month:"Tháng",
  Due:"Quá hạn:",
  Expires:"hết hạn:",
  Subscription_Active:"Đăng ký đang hoạt động",
  Subscription_Inactive:"Đăng ký không hoạt động",
  Purchase_Additional_Cheques:"Mua séc bổ sung?",
  Printable_Cheque:"Kiểm tra có thể in",
  Printable_Cheques:"Séc có thể in",
  Printable_Cheque_Word:"kiểm tra có sẵn trên tài khoản của bạn.",
  Printable_Cheques_Word:"kiểm tra có sẵn trên tài khoản của bạn.",
  Max_Amount_Message:"Số tiền bạn chỉ định đã vượt quá số tiền tối đa được đặt cho hệ thống này:",
  Terms_Required_Word:"Bạn phải đồng ý với thỏa thuận này trước khi tiếp tục sử dụng Séc.",
  Subscriptions:"đăng ký",
  Product_Upgrades:"nâng cấp",
  Mailed_Out_Cheques:"Séc gửi qua đường bưu điện",
  Enter_A_Company_Name:"Vui lòng nhập tên công ty.",
  Single_Cheques:"Séc đơn",
  Cheque_Golden:"Kiểm tra vàng",
  Cheque_Golden_Window:"Thiết kế séc vàng.",
  Cheque_Green:"Kiểm tra màu xanh lá cây",
  Cheque_Green_Window:"Thiết kế kiểm tra màu xanh lá cây.",
  Cheque_Red:"Séc đỏ",
  Cheque_Red_Window:"Thiết kế kiểm tra màu đỏ.",
  Cheque_Yellow:"Kiểm tra màu vàng",
  Cheque_Yellow_Window:"Thiết kế kiểm tra màu vàng.",
  Cheque_Statue_of_Liberty:"tượng nữ thần tự do",
  Cheque_Statue_of_Liberty_Window:"Thiết kế séc của Tượng Nữ thần Tự do.",
  Cheque_Green_Wave:"Làn sóng Xanh",
  Cheque_Green_Wave_Window:"Thiết kế kiểm tra màu xanh lá cây.",
  Cheque_Golden_Weave:"dệt vàng",
  Cheque_Golden_Weave_Window:"Thiết kế sọc vàng sang trọng.",
  Cheque_Green_Sun:"mặt trời xanh",
  Cheque_Green_Sun_Window:"Thiết kế kiểm tra sâu và êm dịu.",
  Cheque_Blue_Checkers:"cờ xanh",
  Cheque_Blue_Checkers_Window:"thiết kế kiểm tra màu xanh.",
  Cashiers_Check:"Kiểm tra thu ngân",
  Cashiers_Check_Window:"Mẫu kiểm tra phong cách thu ngân.",
  Cheque_Aqua_Checkers:"Cờ đam",
  Cheque_Aqua_Checkers_Window:"Thiết kế kiểm tra nước.",
  Cheque_Golden_Checkers:"Rô vàng",
  Cheque_Golden_Checkers_Window:"Thiết kế séc vàng.",
  Upgrade_Unavailable:"Nâng cấp không khả dụng",
  Bank_Code_Protection:"Bảo vệ số ngân hàng",
  Bank_Code_Protection_Word:"Bảo vệ số ngân hàng của bạn khỏi bị sử dụng trên ứng dụng này chạy trên thiết bị khác hoặc cho người dùng khác. Hành động này là KHÔNG THỂ ĐỔI ĐƯỢC. Tiếp tục?",
  Bank_Code_Protection_Blocked_Word:"Số ngân hàng bạn đang cố sử dụng được dành riêng cho người dùng hoặc thiết bị khác.",
  Bank_Code_Protection_Error_Word:"Xác minh số không thành công. Vui lòng kết nối với internet và thử thêm lại tài khoản ngân hàng này.",
  Bank_Code_Protection_Set_Error_Word:"Bảo vệ số ngân hàng yêu cầu bạn phải kết nối với internet. Vui lòng kết nối và thử lại.",
  Upgrade_Unavailable_Word:"Rất tiếc, chúng tôi đang gặp sự cố khi xác minh bạn. Đăng ký và nâng cấp cho Séc hiện không có sẵn để mua trong khu vực của bạn.",
  PayPal_Payment_Preview:"Xem trước thanh toán PayPal",
  Apple_Pay:"Trả phí cho apple",
  Select_PayPal:"Chọn PayPal",
  PayPal_Applications:"Ứng dụng PayPal",
  Purchase_With_Apple_Pay:"Mua hàng bằng Apple Pay",
  Google_Pay:"Google trả tiền",
  Companies:"Các công ty",
  Insurance:"Bảo hiểm",
  New_PayPal:"PayPal mới",
  Pay_By:"Thanh toán bằng",
  Insure:"Bảo hiểm",
  Miles:"Dặm",
  Payment_Method:"Phương thức thanh toán",
  Select_Policies:"Chọn chính sách",
  Policies:"chính sách",
  Policy:"Chính sách",
  No_PayPal_Account:"Không có tài khoản PayPal",
  No_Policies:"Không có hợp đồng bảo hiểm",
  New_Policy:"Chính sách mới",
  PayPal_Payment:"Thanh toán Paypal",
  PayPal_Payments:"Thanh toán PayPal",
  No_Payment_Selected:"Không có khoản thanh toán nào được chọn",
  Sending_Payment_Word:"Vui lòng đợi... Khoản thanh toán này đang được gửi.",
  Sending_Payments_Word:"Vui lòng đợi... Thanh toán đang được gửi.",
  No_Payment_Selected_PayPal:"Không có <a routerLink='/folder/Payments'>thanh toán PayPal</a> nào được chọn để gửi.",
  Payment_Sent:"Thanh toán đã gửi",
  PayPal_Payment_Sent:"Khoản thanh toán này đã được gửi bằng PayPal.",
  Copay:"đồng thanh toán",
  Dead:"Chết",
  Alive:"Còn sống",
  Not_Dead:"Không chết",
  Unclaimed:"không có người nhận",
  Attempted:"đã cố gắng",
  Deceased:"Người chết",
  Claimed:"Yêu cầu bồi thường",
  Unpaid:"Chưa thanh toán",
  Sending_Payment:"gửi thanh toán",
  Sending_Payments:"gửi thanh toán",
  Send_PayPal_Confirmation:"Bạn có muốn gửi giao dịch này bằng PayPal không?",
  Send_PayPal_Confirmation_Word:"Nhấn nút màu xanh lá cây để gửi giao dịch này.",
  Save_Payment_As_Unpaid:"Lưu khoản thanh toán này dưới dạng chưa thanh toán?",
  Payment_Pay_Confirmation_PayPal:"Lưu khoản thanh toán này dưới dạng đã thanh toán?",
  No_Policies_Word:"Thêm <a routerLink='/folder/Postage/New'>Chính sách bảo hiểm</a> đầu tiên ngay bây giờ.",
  Timesheet_Multiple_Delete_Confirmation:"Bạn có chắc chắn muốn xóa nhiều bảng chấm công không?",
  Select_Multiple_Timesheets_Prompt:"Không có bảng chấm công nào được chọn. Chọn ít nhất một bảng chấm công.",
  Select_Multiple_Policies_Prompt:"Không có chính sách nào được chọn. Chọn ít nhất một hợp đồng bảo hiểm.",
  Policies_Multiple_Delete_Confirmation:"Bạn có chắc chắn muốn xóa nhiều chính sách không?",
  Company:"Công ty",
  Add_Company:"Thêm công ty",
  New_Company:"Thêm công ty",
  No_Companies:"Không có công ty",
  Enable_Company:"Kích hoạt công ty",
  Select_Company:"Chọn công ty",
  The_Default_Company:"Nhãn mặc định của công ty.",
  Manage_Companies:"Quản lý công ty",
  No_Companies_Word:"Séc sẽ sử dụng công ty mặc định.<br />Thêm <a routerLink='/folder/Company/New'>Công ty đầu tiên</a>.",
  Default_Company:"Công ty mặc định",
  Cheques_Unlimited_Word:"Checks Unlimited cho phép bạn in bao nhiêu séc tùy thích.",
  Cheques_Subscription_Word:"Đăng ký Séc cho phép bạn in bao nhiêu séc tùy thích.",
  You_Own_This_Product:"Bạn sở hữu sản phẩm này.",
  Your_Subscription_is_Active:"Đăng ký của bạn đang hoạt động.",
  Active_Products:"sản phẩm kích hoạt",
  Purchase_Confirmation:"Mua",
  Purchase_Cheques:"Séc mua hàng",
  Restore_Purchase:"khôi phục mua hàng",
  Erase_Purchase:"Xóa mua hàng",
  Successfully_Purchased:"Đã mua thành công",
  Enter_Your_Licence_Key:"Vui lòng nhập mã cấp phép của bạn trên trang này để kích hoạt sản phẩm này.",
  Licence_Key:"Mã bản quyền",
  Enter_Licence_Key:"Nhập mã cấp phép",
  Purchased:"đã mua",
  Enable_Feature:"Bật tính năng",
  Cancel_Subscription:"Hủy đăng ký",
  Subscription_Removed:"Đã xóa đăng ký",
  Select_Active_Subscription:"Chọn một thuê bao đang hoạt động để sửa đổi nó.",
  Remove_Subscription_Word:"Bạn có chắc chắn muốn hủy đăng ký này không?",
  Delete_Company_Confirmation:"Bạn có chắc chắn muốn xóa toàn bộ công ty này không? CẢNH BÁO: Bạn sẽ mất tất cả thông tin được lưu trữ!",
  Delete_Default_Company_Word:"Bạn không thể xóa công ty mặc định. Bạn có muốn đặt lại ứng dụng và khôi phục nó về trạng thái mặc định không? CẢNH BÁO: Bạn sẽ mất tất cả thông tin được lưu trữ!",
  Console_Warning_2:"Không xử lý bất kỳ loại tiền nào bằng ứng dụng này hiện không phải của bạn.",
  Terms_and_Conditions:"Các điều khoản và điều kiện",
  and_the:"và",
  for:"vì",
  Please_Read_Word:"Vui lòng đọc và đồng ý với",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Không thể tìm thấy một số tỷ giá chuyển đổi tiền tệ. Vui lòng kết nối Internet.",
  Free:"Miễn phí",
  DB_Erase_Prompt_2:"Xóa hoàn toàn toàn bộ cơ sở dữ liệu của nhà phát triển? CẢNH BÁO: Bạn sẽ mất tất cả thông tin MUA và ĐĂNG KÝ!",
  Successfully_Imported:"Đã nhập thành công",
  Select_Postage:"Chọn bưu chính",
  No_Postage:"Không có tem bưu chính",
  No_Paid_Postage_Word:"Thêm tem <a routerLink='/folder/Postage/New'>Bưu phí trả trước</a> đầu tiên.",
  Trial_Complete:"Hoàn thành bản dùng thử",
  Please_Upgrade:"Vui lòng nâng cấp Séc để tiếp tục in.",
  Aa:"aa",
  Color:"Màu sắc",
  Font:"Nét chữ",
  Guide:"Hướng dẫn",
  Guides:"hướng dẫn",
  Image:"Hình ảnh",
  Zoom:"Phóng",
  Logo:"Logo",
  Bank:"Ngân hàng",
  MICR:"MICR",
  Total:"Tổng cộng",
  Cancel:"Hủy bỏ",
  Confirm:"Xác nhận",
  Method:"Phương pháp",
  Biometric_Security:"Bảo mật sinh trắc học",
  Please_Login_To_Continue:"Làm ơn đăng nhập để tiếp tục.",
  Complete:"Hoàn thành",
  Sign_Up:"Đăng ký",
  Error:"Lỗi",
  Biometrics:"chỉ số sinh học",
  Percent:"Phần trăm",
  Zero_Percent:"0%",
  Top_Margin:"Lề trên",
  Bottom_Margin:"Lề dưới",
  Left_Margin:"Lề trái",
  Right_Margin:"lề phải",
  MICR_Margin:"Ký quỹ MICR",
  Table_Margin:"Lề bảng",
  Address_Margin:"Ký quỹ địa chỉ",
  Percentage_:"tỷ lệ phần trăm",
  Vacation_Title:"tiêu đề kỳ nghỉ",
  Use_PIN:"Sử dụng mã PIN",
  Loading__:"Đang tải...",
  Design_Title:"tiêu đề thiết kế",
  Authorize:"ủy quyền",
  Key:"Chìa khóa",
  Public_Key:"Khóa công khai",
  Private_Key:"Khóa riêng",
  Authenticate:"xác thực",
  Last_Payroll:"Bảng lương cuối cùng",
  Last_Calculation:"Tính toán cuối cùng",
  Authorized:"ủy quyền",
  Geo_Authorized:"Vị trí địa lý: Được ủy quyền",
  Not_Authorized:"không được ủy quyền",
  Authorization_Complete:"Hoàn thành ủy quyền",
  Geolocation_Authorization:"Ủy quyền vị trí địa lý",
  Out_of_Bounds:"Ngoài giới hạn",
  Cant_Delete_Default_Design:"Không thể xóa một thiết kế mặc định.",
  Unauthorized_Out_of_Bounds:"Trái phép: Ngoài giới hạn",
  Biometric_Authorization:"Cấp phép chỉ số sinh học",
  Locating_Please_Wait:"Đang định vị, Vui lòng đợi...",
  Test_Biometrics:"Kiểm tra chỉ số sinh học",
  Cheque_Margins:"kiểm tra ký quỹ",
  Percentage_Full_Error:"Trường tỷ lệ phần trăm không thể được đặt trên 100% phần trăm.",
  No_Payroll_Text:"Thêm <a routerLink='/folder/Employee/New'>Nhân viên</a> hoặc <a routerLink='/folder/Payee/New'>Người nhận thanh toán</a> và <a routerLink='/folder/Schedule/New'>Lịch biểu</a>.",
  Design_Saved:"Đã lưu thiết kế",
  Default_Design_Selected:"Thiết kế mặc định được chọn",
  Partial_Import:"Nhập một phần",
  Partial_Export:"Xuất một phần",
  Entire_Import:"Toàn bộ nhập khẩu",
  Entire_Export:"xuất toàn bộ",
  Existing_Password:"Vui lòng nhập mật khẩu hiện tại của bạn:",
  Existing_PIN:"Vui lòng nhập mã PIN hiện tại của bạn:",
  Pin_Change_Header:"Xác nhận mã PIN",
  Pin_Change_SubHeader:"Nhập số PIN cũ của bạn để xác nhận thay đổi.",
  Pass_Change_Header:"Xác nhận mật khẩu",
  Pass_Change_SubHeader:"Nhập mật khẩu cũ của bạn để xác nhận thay đổi.",
  PIN_Enter_Message:"Nhập mã PIN của bạn để xác nhận.",
  Password_Enter_Message:"Nhập mật khẩu của bạn để xác nhận.",
  Pin_Updated_Success:"Đã cập nhật mã PIN thành công!",
  Pin_Updated_Fail:"Không thể cập nhật mã PIN.",
  Pass_Updated_Success:"Đã cập nhật mật khẩu thành công.",
  Pass_Updated_Fail:"Không thể cập nhật mật khẩu.",
  Preview_Payment:"Xem trước thanh toán",
  Preview_Payroll:"Xem trước bảng lương",
  No_Payments_Created:"Không tìm thấy khoản thanh toán nào được tạo.",
  Payment_Preview:"Xem trước thanh toán",
  Enable_Payee:"Kích hoạt người nhận thanh toán",
  Rendered:"kết xuất",
  No_Email:"không có email",
  Setup_Cheques:"Kiểm tra thiết lập",
  name:"Tên",
  address:"Địa chỉ",
  address_2:"Địa chỉ 2",
  city:"Thành phố",
  province:"Tỉnh",
  postal_code:"Mã Bưu điện/Mã ZIP",
  country:"Quốc gia",
  username:"tên tài khoản",
  full_name:"Họ và tên",
  birthday:"Sinh nhật",
  email:"E-mail",
  phone:"Điện thoại",
  employees:"Người lao động",
  addresses:"địa chỉ",
  payment_amount_limit:"Giới hạn số tiền thanh toán",
  total_limit:"Tổng giới hạn",
  payees:"người được trả tiền",
  description:"Sự miêu tả",
  address_line_one:"Địa chỉ dòng đầu tiên",
  address_line_two:"Dòng địa chỉ Hai",
  image:"Hình ảnh",
  account_holder:"Chủ tài khoản",
  cheque_starting_id:"Kiểm tra ID bắt đầu",
  transit_number:"Mã số chuyển tiếp",
  institution_number:"Số tổ chức",
  designation_number:"Số chỉ định",
  account_number:"Số tài khoản",
  currency:"Tiền tệ",
  signature:"Chữ ký",
  payment_payroll_limit:"Hạn mức thanh toán",
  total_limi:"Tổng giới hạn",
  date:"Ngày",
  printed_memo:"Bản ghi nhớ đã in",
  banks:"ngân hàng",
  signature_image:"Hình ảnh chữ ký",
  address_name:"Tên địa chỉ",
  notes:"ghi chú",
  design:"Thiết kế",
  title:"Tiêu đề",
  frequency:"Tính thường xuyên",
  fax:"Số fax",
  salaries:"Lương",
  salary_ids:"ID lương",
  start_time:"Thời gian bắt đầu",
  end_time:"Thời gian kết thúc",
  paid:"Trả",
  overtime_percentage:"Tỷ lệ phần trăm được trả tiền",
  overtime_amount:"Số tiền cố định đã thanh toán",
  first_name:"Họ",
  last_name:"Họ",
  moderation:"điều độ",
  create:"Tạo nên",
  edit:"Biên tập",
  destroy:"Hủy hoại",
  day_start_time:"Ngày_bắt_đầu_giờ",
  day_end_time:"Ngày_cuối_giờ",
  fullname:"Tên",
  time:"Thời gian",
  auto_send:"Tự động gửi",
  time_method:"Phương pháp thời gian",
  schedules:"lịch trình",
  indefinite_payroll_enabled:"Kích hoạt vô thời hạn",
  amount:"Số lượng",
  repeat:"Lặp lại",
  always_enabled:"Luôn được kích hoạt",
  start_date:"Ngày bắt đầu",
  end_date:"Ngày cuối",
  Cheque_Total:"Kiểm tra Tổng số",
  Total_Amount:"Tổng cộng:",
  Amounts:"Lượng:",
  Images:"Hình ảnh",
  Files:"Các tập tin",
  Previewing:"Xem trước:",
  Insert:"Chèn",
  Preview_Import:"Xem trước nhập",
  Entry:"Lối vào",
  Entries:"Mục",
  No_Entries:"Ko lối vào",
  Import_Type:"Loại nhập khẩu",
  Select_Details:"Chọn Chi tiết",
  Select_Payee:"Chọn Người nhận thanh toán",
  Enable_Holidays:"Kích hoạt ngày lễ",
  Disable_Holidays:"Vô hiệu hóa ngày lễ",
  Wire_Transfer:"Chuyển khoản",
  New_Export:"Xuất khẩu mới",
  Export_Data:"Xuất dữ liệu",
  Export_Data_Word:"Chọn loại tệp để xuất và tải xuống.",
  Export_File:"Xuất tệp",
  Mode:"Cách thức",
  Times:"lần",
  Widgets:"tiện ích",
  Slider:"Thanh trượt",
  Set_Details:"Đặt chi tiết",
  Select_Type:"Lựa chọn đối tượng",
  Display_Slider:"Thanh trượt hiển thị",
  Dashboard_Slider:"Thanh trượt bảng điều khiển",
  Dashboard_Mode:"Chế độ bảng điều khiển",
  Show_Intro:"Hiển thị phần giới thiệu",
  Show_Payrolls:"Hiển thị bảng lương",
  Show_Holidays:"Hiển thị ngày lễ",
  Show_Invoices:"Hiển thị hóa đơn",
  Show_Cheques:"Hiển thị séc",
  Enabled_Widgets:"Tiện ích đã bật",
  Disabled_Widgets:"Tiện ích bị vô hiệu hóa",
  Colors:"Màu sắc",
  Barcodes:"Mã vạch",
  View_Timers:"Xem bộ hẹn giờ",
  Gradients:"Độ dốc",
  No_Info:"Không có thông tin",
  Disable:"Vô hiệu hóa",
  Show_Layer:"Hiển thị lớp",
  Hide_Layer:"Ẩn các lớp",
  Text_Layer:"Lớp văn bản",
  Secondly:"thứ hai",
  Minutely:"từng phút",
  nine_am:"09:00",
  five_pm:"05:00",
  Enable_Address:"Kích hoạt địa chỉ",
  Invalid_File_Type:"Rất tiếc, loại tệp không hợp lệ đã được chọn. Loại tệp được hỗ trợ:",
  Error_Updating_Entry:"Xin lỗi, đã xảy ra lỗi khi cập nhật mục này.",
  Schedule_Timing_Alert:"Lỗi: Thời gian bắt đầu lịch trình được đặt thành giá trị sau thời gian kết thúc.",
  Select_Multiple_Payments_Prompt:"Không có khoản thanh toán nào được chọn. Chọn ít nhất một khoản thanh toán.",
  Select_Multiple_Cheques_Prompt:"Không có kiểm tra nào được chọn. Vui lòng chọn ít nhất một séc.",
  Select_Multiple_Items_Prompt:"Không có mục nào được chọn. Vui lòng chọn ít nhất một mục.",
  Paymemt_Multiple_Delete_Confirmation:"Bạn có chắc chắn muốn xóa nhiều khoản thanh toán không?",
  Cheque_Multiple_Delete_Confirmation:"Bạn có chắc chắn muốn xóa nhiều séc không?",
  Payee_Multiple_Delete_Confirmation:"Bạn có chắc chắn muốn xóa nhiều người nhận thanh toán không?",
  Employee_Multiple_Delete_Confirmation:"Bạn có chắc chắn muốn xóa nhiều nhân viên không?",
  MICR_Warning:"Lưu ý: Một số máy in và thiết bị có thể không hiển thị đúng phông chữ MICR.",
  Automatically_Send:"Tự động gửi",
  Type:"Kiểu",
  Payment_Type:"Hình thức thanh toán",
  Auto_Send:"Tự động gửi",
  Automatically_Process:"Tự động xử lý",
  Auto_Process:"Quy trình tự động",
  Image_Based:"dựa trên hình ảnh",
  Font_Based:"dựa trên phông chữ",
  Rerender:"kết xuất lại",
  Rendering:"kết xuất",
  Render:"Các tập tin",
  Top:"Đứng đầu",
  Middle:"Ở giữa",
  Bottom:"Đáy",
  Top_Left:"Trên cùng bên trái",
  Top_Center:"Trung tâm hàng đầu",
  Top_Right:"Trên cùng bên phải",
  Middle_Left:"Trung tâm bên trái",
  Middle_Center:"trung tâm",
  Middle_Right:"Giữa bên phải",
  Bottom_Left:"Dưới cùng bên trái",
  Bottom_Center:"Trung tâm trên",
  Bottom_Right:"Góc phải ở phía dưới",
  Numbers:"số",
  Verified:"Đã xác minh",
  Paper_Size:"Khổ giấy",
  New_Device:"Thiết bị mới",
  Add_Device:"Thêm thiết bị",
  Remove_Device:"Gỡ bỏ thiết bị",
  Delete_Device:"Xóa thiết bị",
  Block_Device:"Chặn thiết bị",
  Block:"Khối",
  Unblock:"Mở khóa",
  Table:"Bàn",
  Scan_Login_Code:"Quét mã đăng nhập",
  Login_Code:"Mã đăng nhập",
  Scan_Code:"quét mã",
  Scan_QR_Code:"Quét mã QR",
  Select_All:"Chọn tất cả",
  Deselect_All:"Bỏ chọn tất cả",
  Increase:"Tăng",
  Decrease:"Giảm bớt",
  Bold:"In đậm",
  Text:"Chữ",
  Style:"Phong cách",
  Italic:"chữ nghiêng",
  QR_Code:"Mã QR",
  Barcode:"mã vạch",
  Browse_Images:"Duyệt hình ảnh",
  Browse_Files:"Duyệt tệp",
  Background_Image:"Hình nền",
  Logo_Image:"Hình ảnh logo",
  Header_Image:"Hình ảnh tiêu đề",
  Bank_Image:"Hình ảnh ngân hàng",
  Cut_Lines:"đường cắt",
  Background:"Lý lịch",
  License:"Giấy phép",
  One_License:"1 Giấy phép:",
  Licensed:"Cấp phép:",
  Not_Licensed:"Không được cấp phép",
  Enter_Serial:"Nhập nối tiếp",
  Serial_Key:"Khóa nối tiếp",
  Serial:"nối tiếp",
  Product_Key:"khóa sản phẩm",
  Check_Product_Key:"Xác minh khóa sản phẩm",
  Add_Product_Key:"Nhập mã sản phẩm",
  Verifying_Purchase:"Đang xác minh mua hàng...",
  Print_Envelope:"In Phong Bì",
  Envelope:"Phong bì",
  Thank_You:"Cảm ơn!",
  Scale:"Tỉ lệ",
  Print_Scale:"Quy mô in",
  Borders:"biên giới",
  VOID:"VÔ HIỆU",
  Void_Cheque:"Kiểm tra vô hiệu",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"TRẢ CHO ĐƠN ĐẶT HÀNG CỦA:",
  NO_DOLLARS:"KHÔNG CÓ ĐÔ LA",
  ONE_DOLLAR:"MỘT ĐÔ LA",
  DOLLARS:"USD",
  AND:"VÀ",
  CENTS:"TRUNG TÂM.",
  NO_:"KHÔNG",
  ONE_:"MỘT",
  AND_NO_:"VÀ KHÔNG",
  _AND_ONE_:"VÀ MỘT",
  DOLLARS_AND_ONE_CENT:"VÀ MỘT XU.",
  AND_NO_CENTS:"VÀ KHÔNG XU.",
  CHEQUE_PRINT_DATE:"NGÀY:",
  CHEQUE_PRINT_MEMO:"BẢN GHI NHỚ:",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Nhiệm vụ ban đầu",
  Inital_Setup:"Thiết lập ban đầu",
  Welcome_To:"Chào mừng bạn đến",
  Welcome:"Chào mừng",
  Swipe:"vuốt",
  Intro_Setup:"Cài đặt giới thiệu",
  Introduction:"Giới thiệu",
  CHEQUE_PRINT_CREDIT:"Powered by Séc",
  Title_Intro_Word:"Chào mừng bạn đến với Kiểm tra",
  Title_Intro:"Giới thiệu séc",
  Title_Setup:"Kiểm tra thiết lập",
  PayPal_Default_Email_Message:"Bạn đã nhận được một chuyển khoản PayPal mới.",
  Cheques_App_Export:"Xuất bằng séc",
  No_Prints_Purchased:"Không có bản in nào được mua",
  No_Prints_Purchased_Word:"Không có bản in bổ sung nào. Vui lòng mua thêm séc.",
  Post_Intro_Thanks:"Cảm ơn bạn đã chọn Séc. Quá trình thiết lập hiện đã hoàn tất.",
  Post_Intro_Word:"Bắt đầu bằng cách in séc đầu tiên của bạn, thêm khoản thanh toán trong tương lai hoặc thêm nhân viên vào bảng lương.",
  Upgrade_Required:"Kiểm tra yêu cầu bạn sở hữu phiên bản cao cấp hơn của phần mềm để ẩn thông báo này và mở khóa các tính năng bổ sung.",
  Upgrade_Title:"Séc",
  Mailout_Prompt:"Ngoài ra, bạn có thể chọn để Séc gửi séc lương qua đường bưu điện cho bạn.",
  Mailed_Cheque:"Séc gửi qua đường bưu điện",
  Mailed_Cheque_Color:"Séc gửi qua đường bưu điện (Màu)",
  Terms_Purchase:"Tất cả các giao dịch mua điện tử bằng Séc đều được hoàn trả đầy đủ trong tối đa 30 ngày kể từ ngày mua. Vui lòng đọc và đồng ý với",
  Dashboard_Setup:"Cài đặt máy in chính",
  Dashboard_Add:"Thêm tài khoản ngân hàng chính",
  Dashboard_Customize:"Chọn một mẫu kiểm tra",
  Dashboard_Job_Salary:"Tạo công việc của bạn và thêm tiền lương của bạn",
  Dashboard_Employees:"Theo dõi nhân viên & người được trả tiền",
  Dashboard_Print:"In & Gửi séc lương của bạn",
  Dashboard_Payroll:"Tự động hóa việc in bảng lương của bạn",
  Dashboard_PayPal:"Thiết lập bảng lương / xuất chi PayPal",
  Begin_Setup:"Bắt đầu thiết lập",
  Get_Started:"Bắt đầu",
  Purchase:"Mua",
  Lockdown:"Lệnh đóng cửa",
  Unlock:"mở khóa",
  Detailed:"chi tiết",
  Log_In:"Đăng nhập",
  Login:"Đăng nhập",
  Launch:"Phóng",
  Register:"Đăng ký",
  Finish:"Hoàn thành",
  List:"Danh sách",
  Weekends:"Nhưng ngay cuối tuần",
  Weekly:"hàng tuần",
  PayPal_Default_Subject:"Thanh toán mới",
  Payment_Message:"Tin nhắn thanh toán",
  PayPal_Default_Payment_Note:"Cảm ơn",
  Setup_Your_Cheqing_Account:"kiểm tra tài khoản",
  Add_Your_Primary_Cheqing_Account:"Thêm tài khoản séc chính của bạn.",
  Welcome_Word:"Đơn giản hóa và tự động hóa quản lý nhân sự và tiền lương.",
  Get_Started_Quickly:"Chỉ cần trả lời một số câu hỏi đơn giản sẽ giúp chúng tôi bắt đầu...",
  Done_Intro_Word:"Cài đặt hoàn thành",
  PIN_Protected:"Mật khẩu & mã PIN được bảo vệ",
  Enter_New_PIN:"Nhập Mã PIN mới:",
  Enter_PIN:"Nhập mã PIN:",
  Invalid_PIN:"Đã nhập mã PIN không hợp lệ.",
  Account_Identifier:"Định danh tài khoản",
  New_Account_Identifier:"Định danh tài khoản mới",
  attempt:"nỗ lực",
  attempts:"nỗ lực",
  remaining:"còn lại",
  Language:"Ngôn ngữ",
  languages:"ngôn ngữ",
  select_languages:"Chọn ngôn ngữ",
  Deposit:"Tiền gửi",
  Hire_One_Now:"Thuê ngay",
  App_Locked:"Ứng dụng đã bị khóa.",
  Skip_:"Nhảy",
  Skip_to_Dashboard:"Chuyển đến Bảng điều khiển",
  Dashboard:"bảng điều khiển",
  Import:"Nhập khẩu",
  Admin:"quản trị viên",
  New_Admin:"Quản trị viên mới",
  Settings:"Cài đặt",
  Color_Picker:"Bộ chọn màu",
  Font_Picker:"Bộ chọn phông chữ",
  Logout:"Đăng xuất",
  Close:"Đóng",
  Close_menu:"Đóng",
  Excel:"Tệp Excel",
  Csv:"Tệp CSV",
  Sql:"Tệp SQL",
  Json:"Tệp JSON",
  Url:"Nhập theo URL",
  Back:"Mặt sau",
  Timers:"hẹn giờ",
  Cheque:"Kiểm tra",
  Print:"In",
  Designs:"kiểu dáng",
  Pause_Printing:"Tạm dừng in",
  Resume_Printing:"Tiếp tục in",
  Printing_Paused:"Tạm dừng in",
  PrintingUnavailable:"Lấy làm tiếc! Tính năng in không khả dụng trên hệ thống này.",
  Prints_Paused:"Tạm dừng in",
  Administration:"Sự quản lý",
  Loading:"Đang tải",
  Unnamed:"Vô danh",
  error:"Rất tiếc, không thể mở séc này để xem.",
  No_Cheques_To_Print:"Không Có Séc Để In",
  No_Cheques_To_Print_Word:"Tạo một <a routerLink='/folder/Cheque/New'>Séc mới</a>.",
  New_Cheque:"Kiểm tra mới",
  Start_One_Now:"Bắt đầu ngay bây giờ",
  Edit_Cheque:"Chỉnh sửa kiểm tra",
  Select_Cheques:"Chọn Kiểm tra",
  Select_Employee:"Chọn nhân viên",
  Default_Printer:"In mặc định",
  Reassign:"chỉ định lại",
  Configure:"định cấu hình",
  Template:"Bản mẫu",
  Designer:"Nhà thiết kế",
  Edit_Designs:"Chỉnh sửa thiết kế",
  New_Design:"Thiết kế mới",
  Next:"Kế tiếp",
  Save:"Cứu",
  Name:"Tên",
  Mail:"Thư",
  Amount:"Số lượng",
  Date:"Ngày",
  PayPal:"PayPal",
  Payouts:"xuất chi",
  PayPal_Label:"Nhãn PayPal",
  Email_Username:"Email / Tên người dùng",
  Client_ID:"ID khách hàng",
  Sandbox_Email:"Email hộp cát",
  PayPal_Email:"Paypal Email",
  PayPal_Username:"Tên người dùng API",
  PayPal_Payouts:"Xuất chi PayPal",
  Select_PayPal_Key:"Chọn Khóa PayPal",
  Secret:"Bí mật",
  API_Secret:"Bí mật API",
  PayPal_API_Keys:"Khóa PayPal",
  New_PayPal_Key:"Khóa PayPal mới",
  Email_Subject:"Chủ đề email",
  Email_Message:"Thư điện tử",
  Payout_Options:"Tùy chọn thanh toán",
  Payment_Note:"Ghi chú thanh toán",
  Enable_Employee:"Kích hoạt nhân viên",
  Enable_Production_Mode:"Kích hoạt chế độ sản xuất",
  Sandbox_Username:"Tên người dùng hộp cát",
  Sandbox_Signature:"Chữ ký hộp cát",
  Sandbox_Password:"Mật khẩu hộp cát",
  Production_Username:"Tên người dùng sản xuất",
  Production_Signature:"Chữ ký sản xuất",
  Production_Password:"mật khẩu sản xuất",
  Production_Email:"Email sản xuất",
  API_Client_ID:"ID ứng dụng khách API",
  API_Signature:"Chữ ký API",
  API_Password:"Mật khẩu API",
  API_Username:"Tên người dùng API",
  Secret_Key:"Chìa khoá bí mật",
  Sandbox:"hộp cát",
  Production:"Sản xuất",
  Sandbox_Keys:"Phím hộp cát",
  Production_Keys:"Chìa khóa sản xuất",
  API_Title:"Tiêu đề API",
  Production_Mode:"Chế độ sản xuất",
  Account_Label:"Nhãn tài khoản",
  No_PayPal_Setup:"Không có khóa PayPal",
  Enable_PayPal_Account:"Kích hoạt tài khoản PayPal",
  No_PayPal_Word:"Thêm <a routerLink='/folder/Invoice/New'>Khóa API PayPal</a> của bạn.",
  Printed_Memo:"Bản ghi nhớ đã in",
  Employee:"Người lao động",
  View_Employee:"Xem nhân viên",
  Mailing_Address:"Địa chỉ gửi thư",
  Company_Address:"địa chỉ công ty",
  Select_Company_Address:"Chọn địa chỉ công ty",
  Address:"Địa chỉ",
  Any_Day:"Bất kỳ ngày nào",
  Address_Name:"Tên địa chỉ",
  Unit:"Đơn vị",
  Account:"Tài khoản",
  Bank_Account:"Tài khoản ngân hàng",
  Account_Limits:"Kích hoạt giới hạn tài khoản",
  Payroll:"Lương bổng",
  Run:"Chạy",
  Run_Payroll:"Chạy bảng lương",
  New_Payroll:"bảng lương mới",
  No_Payroll:"Không có bảng lương sắp tới",
  Upcoming_Holiday:"Kỳ nghỉ sắp tới:",
  Invoice_Due:"Hóa đơn đến hạn:",
  New_Invoice:"Hóa đơn mới",
  No_Invoices:"không có hóa đơn",
  No_Invoices_Word:"Tạo <a routerLink='/folder/Invoice/New'>Hóa đơn</a> đầu tiên.",
  Cheque_Due:"Kiểm tra đến hạn:",
  Payrolls:"bảng lương",
  Sandbox_Mode:"chế độ sandbox",
  Hire:"Thuê",
  Pay:"Chi trả",
  New:"Mới",
  Add:"Thêm vào",
  Make:"Làm",
  Time:"Thời gian",
  Write:"Viết",
  Holiday:"Ngày lễ",
  Holidays:"Ngày lễ",
  Next_Holiday:"Kỳ nghỉ tiếp theo:",
  All_Done:"Tất cả đã được làm xong!",
  Employees:"Người lao động",
  Payees:"người được trả tiền",
  Job:"Công việc",
  Jobs:"công việc",
  Invoice:"Hóa đơn",
  Invoices:"hóa đơn",
  Vacations:"kỳ nghỉ",
  Cheques:"Séc",
  Brand_Cheques:"Thương hiệu của bạn",
  Payment:"Sự chi trả",
  Enable_Payment:"Kích hoạt thanh toán",
  Payments:"thanh toán",
  Schedule:"Lịch trình",
  Schedules:"lịch trình",
  Timesheet:"Thời gian biểu",
  Timesheets:"bảng chấm công",
  Salary:"Lương",
  New_Address:"Địa chỉ mới",
  Address_2:"Địa chỉ 2)",
  _City:"Thành phố",
  _State:"Bang/Prov",
  City:"Thành phố / Thị trấn",
  State:"Bang / Tỉnh",
  Postal:"Mã bưu chính / ZIP",
  ZIP:"Bưu chính / ZIP",
  Country:"Quốc gia",
  Addresses:"địa chỉ",
  Required_Options:"Tùy chọn bắt buộc",
  Optional_Options:"Tùy chọn tùy chọn",
  Additional_Options:"Tùy chọn bổ sung",
  Required:"Yêu cầu",
  Optional:"Không bắt buộc",
  Additional:"Thêm vào",
  No_Addresses:"Không có địa chỉ",
  New_Address_Word:"Thêm <a routerLink='/folder/Address/New'>Địa chỉ</a> đầu tiên.",
  Select_Address:"Chọn địa chỉ",
  No_Address:"Không có địa chỉ",
  Print_Cheque:"In séc",
  Print_Cheques:"In séc",
  Print_Cheque_Now:"In Kiểm tra ngay",
  Description:"Sự miêu tả",
  Pay_To_The_Order_Of:"Trả cho đơn đặt hàng của:",
  Select_Date_Range:"Chọn Phạm vi Ngày",
  Select_Starting_Date:"Chọn ngày bắt đầu",
  Select_Ending_Date:"Chọn ngày kết thúc",
  Select_Date:"Chọn ngày",
  Cheque_Date:"Kiểm tra ngày",
  Cheque_Memo:"Kiểm tra bản ghi nhớ",
  Blank_Cheque:"Kiểm tra trắng",
  Blank:"Trống",
  No_Cheques:"Không kiểm tra",
  No_Cheques_Word:"In <a routerLink='/folder/Cheque/New'>Séc</a> đầu tiên của bạn.",
  Cheque_Amount_Placeholder:"$0,00",
  View_Image:"Xem hình ảnh",
  View:"Xem",
  Modify:"Biến đổi",
  Delete:"Xóa bỏ",
  Cheque_Paid:"Trả",
  New_Deduction:"Khoản Khấu Trừ Mới",
  Title:"Tiêu đề",
  Frequency:"Tính thường xuyên",
  Hourly:"hàng giờ",
  Daily:"Hằng ngày",
  Weekdays:"các ngày trong tuần",
  BiWeekly:"2 tuần",
  TriWeekly:"3 tuần",
  Monthly:"hàng tháng",
  MiMonthly:"2 tháng",
  Quarterly:"hàng quý",
  Yearly:"hàng năm",
  Every_Week:"Mỗi tuần",
  Every_Payroll:"Mỗi bảng lương",
  Every_Month:"Mỗi tháng",
  Annually:"hàng năm",
  Always_Scheduled:"Luôn được lên lịch",
  Start_Date:"Ngày bắt đầu",
  End_Date:"Ngày cuối",
  Start_Time:"Thời gian bắt đầu",
  End_Time:"Thời gian kết thúc",
  Deduction_Label:"Nhãn khấu trừ",
  Notes:"ghi chú",
  Options:"Tùy chọn",
  Enable:"Cho phép",
  Select_Deductions:"Chọn các khoản khấu trừ",
  Deductions:"khấu trừ",
  No_Deductions:"Không Khấu Trừ",
  No_Deductions_Word:"Tạo <a routerLink='/folder/Deduction/New'>Khấu trừ</a> đầu tiên của bạn.",
  New_Employee:"Nhân viên mới",
  No_Title:"Không tiêu đề",
  _Name:"Tên",
  About_Yourself:"Về bản thân bạn",
  Full_Name:"Họ và tên",
  Birthday:"Sinh nhật",
  Email:"E-mail",
  SMS:"tin nhắn",
  Phone:"Điện thoại",
  Test:"Bài kiểm tra",
  Call:"Gọi",
  Fax:"Số fax",
  Printed_Note:"Ghi chú đã in",
  Position:"Chức vụ",
  Job_Position:"Vị trí công việc",
  Select_a_Job:"Chọn một công việc",
  Select_a_Salary:"Chọn một mức lương",
  Add_a_Deduction:"Thêm một khoản khấu trừ",
  Taxes:"thuế",
  Add_Taxes:"thêm thuế",
  Date_of_Birth:"Ngày sinh",
  Email_Address:"Địa chỉ email",
  Phone_Number:"Số điện thoại",
  Phone_Call:"Gọi điện",
  Enable_on_Payroll:"Bật trên Bảng lương",
  Select_Employees:"Chọn nhân viên",
  No_Employees:"Không có nhân viên",
  No_Employees_Word:"Thêm <a routerLink='/folder/Employee/New'>Người lao động</a> mới đầu tiên của bạn.",
  No_Name:"Không Tên",
  Unemployeed:"thất nghiệp",
  Employeed:"có việc làm",
  Paid:"Trả",
  Enabled:"Đã bật",
  Disabled:"Tàn tật",
  Fire:"Ngọn lửa",
  Not_Available:"Không có sẵn",
  Not_Available_Word:"In <a routerLink='/folder/Invoice/New'>Hóa đơn</a> đầu tiên của bạn và được thanh toán.",
  Select_Invoices:"Chọn hóa đơn",
  Print_Invoice_Word:"In <a routerLink='/folder/Invoice/New'>Hóa đơn</a> đầu tiên của bạn và được thanh toán.",
  Invoice_Title:"Tiêu đề hóa đơn",
  Invoice_Date:"Ngày hóa đơn",
  Due_Date:"Ngày đáo hạn",
  New_Job:"Công việc mới",
  Details:"Chi tiết",
  Customize:"tùy chỉnh",
  Customize_Dashboard:"Tùy chỉnh bảng điều khiển",
  Components:"Các thành phần",
  No_Components:"Không có thành phần",
  Main_Components:"Các thành phần chính",
  Smaller_Components:"Linh kiện nhỏ hơn",
  Error_Loading_Page:"Lỗi khi tải trang này.",
  Bank_Details:"Thông tin chi tiết ngân hàng",
  About_Your_Job:"Về công việc của bạn",
  Your_Schedule:"Lịch trình của bạn",
  Job_Title:"Chức vụ",
  Job_Description:"Mô tả công việc",
  Job_Details:"Chi tiết công việc",
  Enable_Job:"Kích hoạt công việc",
  Pay_Period:"Thời gian thu phí",
  Perpetually_Schedule:"Lịch trình vĩnh viễn",
  Select_Jobs:"Chọn công việc",
  No_Jobs:"không có việc làm",
  Add_Jobs:"Thêm công việc",
  No_Jobs_Word:"Thêm <a routerLink='/folder/Job/New'>Công việc</a> đầu tiên vào danh sách.",
  Count_Employees:"job.employee_count+'Nhân viên'",
  Zero_Employees:"0 nhân viên",
  New_Leave:"Nghỉ Phép Mới",
  Leave_Name:"để lại tên",
  Paid_Leave:"Nghỉ có lương",
  Leave_Pay:"Lương Nghỉ Phép",
  Indefinite_Leave:"Nghỉ không thời hạn",
  Indefinite_Payroll:"Biên chế vô thời hạn",
  Leave:"Rời khỏi",
  Add_Schedules:"Thêm lịch biểu",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Kích hoạt Vắng mặt",
  Select_Leaves:"Chọn lá",
  No_Leaves:"Không Lá Vắng Mặt",
  Leave_Of_Absence:"Nghỉ phép",
  Leaves_Of_Absence:"lá vắng mặt",
  New_Leave_of_Absense:"Nghỉ Phép Mới",
  No_Leaves_Word:"Thêm <a routerLink='/folder/Leave/New'>Nghỉ phép</a> đầu tiên.",
  Not_Enabled:"Không được kích hoạt",
  Absences:"Vắng mặt",
  Payee:"người nhận tiền",
  New_Payee:"Người nhận thanh toán mới",
  Payee_Identifier:"Định danh người nhận thanh toán",
  Payee_Name:"Tên người nhận",
  Payee_Title:"Tiêu đề người nhận thanh toán",
  Payment_Memo:"Biên bản thanh toán",
  Select_Payees:"Chọn người được trả tiền",
  No_Payees:"Không có người được trả tiền",
  Add_Payee_Note:"Thêm <a routerLink='/folder/Payee/New'>người nhận tiền</a> đầu tiên.",
  New_Payees:"Người được trả tiền mới",
  About_The_Payment_Schedule:"Lịch trình thanh toán",
  Your_Payroll_Schedule:"Bảng lương tự động",
  New_Payment:"Thanh toán mới",
  Identifier:"định danh",
  Selected:"Đã chọn",
  Select:"Lựa chọn",
  Memo:"Bản ghi nhớ",
  Payment_Date:"Ngày thanh toán",
  Mark_as_Paid:"Đánh dấu là đã thanh toán",
  Select_Payments:"Chọn Thanh toán",
  No_Payments:"Không thanh toán",
  No_Payments_Word:"Tạo <a routerLink='/folder/Payment/New'>Thanh toán</a> đầu tiên.",
  Create_Payroll:"Tạo bảng lương",
  Properties:"Của cải",
  Payroll_Title:"Chức danh bảng lương",
  Payroll_Notes:"Ghi chú bảng lương",
  Payroll_Setup:"Thiết lập bảng lương",
  Tabulate_Payments:"lập bảng thanh toán",
  Tabulate:"Lập bảng",
  By:"Qua:",
  Payments_By:"Thanh toán theo",
  Timesheets_And_Schedules:"Bảng chấm công & Lịch trình",
  Both:"Cả hai",
  Items:"Mặt hàng",
  Add_Payees:"Thêm người được trả tiền",
  Add_Account:"Thêm tài khoản",
  Enable_Account:"Kích hoạt tài khoản",
  Enable_Payroll:"Kích hoạt bảng lương",
  Print_Payroll:"In bảng lương",
  No_Payrolls:"Không Có Bảng Lương",
  No_Payroll_Word:"Tạo <a routerLink='/folder/Payroll/New'>Bảng lương</a> đầu tiên của bạn.",
  View_more:"XEM THÊM",
  Less:"ÍT HƠN",
  Add_Payroll:"Thêm bảng lương",
  Select_Payroll:"Chọn bảng lương",
  About_Your_Salary:"Về mức lương của bạn",
  Add_Salaries:"Thêm lương",
  Add_Salary:"Thêm lương",
  Salaries:"Lương",
  No_Salaries:"Không lương",
  No_Salaries_Word:"Thêm <a routerLink='/folder/Salary/New'>Mức lương</a> đầu tiên.",
  Select_Salaries:"Chọn lương",
  New_Salary:"Mức lương mới",
  Salary_Name:"Định danh lương",
  Enable_Salary:"Bật lương",
  Salary_Amount:"Số tiền lương",
  New_Schedule:"Kế hoạch mới",
  Schedule_Title:"tiêu đề lịch trình",
  Add_Address:"Thêm địa chỉ",
  Repeat:"Lặp lại",
  Design:"Thiết kế",
  Edit_Design:"Chỉnh sửa thiết kế",
  Edit_this_Design:"Chỉnh sửa thiết kế này",
  Repeat_Payment:"lặp lại thanh toán",
  Enable_Schedule:"Bật lịch biểu",
  Never:"Không bao giờ",
  Select_Schedule:"Chọn lịch trình",
  No_Schedules:"Không có lịch trình",
  No_Schedules_Word:"Tạo <a routerLink='/folder/Schedule/New'>Lịch trình</a> đầu tiên.",
  New_Administrator:"Quản trị viên mới",
  Username:"tên tài khoản",
  First_Name:"Họ",
  Last_Name:"Họ",
  Add_an_Address:"Thêm một địa chỉ",
  Payment_Limit:"Giới hạn mỗi lần thanh toán",
  Total_Limit:"Tổng giới hạn",
  Select_Accounts:"Chọn Tài khoản",
  No_Administrators:"Không có quản trị viên",
  No_Administrators_Word:"Tạo <a routerLink='/folder/Administrator/New'>Tài khoản quản trị</a> đầu tiên.",
  New_Administrators_Word:"Thêm <a routerLink='/folder/Administrator/New'>Quản trị viên</a> đầu tiên",
  Cloud:"Đám mây",
  Backup:"Hỗ trợ",
  Enable_iCloud:"Kích hoạt iCloud",
  Enable_Google_Drive:"Bật Google Drive",
  Enable_Microsoft_OneDrive:"Kích hoạt Microsoft OneDrive",
  Automatically_Backup:"Tự động sao lưu",
  FTP_Settings:"Cài đặt FTP",
  URL_File_Prompt:"Vui lòng chỉ định vị trí cho tệp .xls/.xlsx/.json để nhập:",
  URL_SQL_Prompt:"Vui lòng chỉ định vị trí của tệp SQLite để nhập:",
  FTP_Backup:"Sao lưu FTP",
  FTP_Import:"Nhập FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Chủ nhà",
  Port:"Hải cảng",
  Path:"Con đường",
  Data_Path:"Đường dẫn dữ liệu",
  Enable_FTP_Account:"Kích hoạt tài khoản FTP",
  HostnameIP:"tên máy chủ",
  Password:"Mật khẩu",
  Connection_Port:"Cổng kết nối",
  Enable_MySQL_Database:"Kích hoạt cơ sở dữ liệu MySQL",
  Log:"Nhật ký",
  Reset:"Cài lại",
  Erase:"Tẩy xóa",
  Export:"Xuất khẩu",
  Database:"cơ sở dữ liệu",
  Upload_CSV:"Tải lên CSV",
  Download_CSV:"Tải xuống CSV",
  SQL_Database:"URL SQL",
  MySQL_Database:"mysql",
  MySQL_Backup:"Sao lưu MySQL",
  Internal_Notes:"Ghi chú nội bộ",
  Root_Path:"Đường dẫn gốc",
  Select_Backup:"Chọn Sao lưu",
  Add_New_Backup:"Thêm một bản sao lưu mới",
  First_Backup:"Thiết lập bản sao lưu đầu tiên...",
  Backups:"sao lưu",
  Add_Backup:"Thêm sao lưu",
  No_Backups:"Không có bản sao lưu nào được tìm thấy.",
  Select_Backup_Type:"Chọn loại sao lưu bạn muốn thiết lập...",
  Backup_Type:"Loại sao lưu",
  FTP_Drive:"Ổ đĩa FTP",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Lái xe",
  Microsoft_OneDrive:"Lái xe",
  Import_Fields:"Nhập trường",
  Import_Fields_Word:"Sử dụng phần này để chọn dữ liệu <a routerLink='/folder/Holiday/New'>Nhập</a>.",
  Upload:"Tải lên",
  Download:"Tải xuống",
  Download_JSON:"Tải xuống dưới dạng dữ liệu JSON",
  Download_SQL:"Tải xuống dưới dạng tệp SQL",
  New_Bank:"Ngân hàng mới",
  New_Account:"Tài khoản mới",
  New_Bank_Account:"Tài khoản ngân hàng mới",
  Upload_Image:"Tải lên hình ảnh",
  Bank_Name:"Tên ngân hàng",
  Bank_Address:"Địa chỉ ngân hàng",
  Branch_Address:"Địa chỉ chi nhánh",
  Address_on_Cheque:"Địa chỉ",
  Cheque_Numbers:"kiểm tra số",
  Cheque_Details:"Kiểm tra chi tiết",
  Bank_Logo:"Logo ngân hàng",
  Cheque_ID:"kiểm tra ID",
  Starting_Cheque_ID:"Bắt đầu kiểm tra ID",
  Transit_Number:"Mã số chuyển tiếp",
  Institution_Number:"Số tổ chức",
  Designation_Number:"Số chỉ định",
  Account_Number:"Số tài khoản",
  Limits:"Hạn mức",
  Default_Limits:"Giới hạn mặc định",
  Over_Limit:"Qua giới hạn",
  Under_Limit:"dưới giới hạn",
  Payroll_Limit:"Giới hạn biên chế",
  Enable_Bank_Account:"Kích hoạt tài khoản ngân hàng",
  Select_Account:"Chọn tài khoản",
  No_Bank_Account:"Không có tài khoản ngân hàng",
  No_Bank_Account_Word:"Thêm <a routerLink='/folder/Accounts/New'>Tài khoản ngân hàng</a> đầu tiên.",
  Bank_Accounts:"Tài khoản ngân hàng",
  No_Accounts:"Không có tài khoản",
  No_Accounts_Note:"Thêm <a routerLink='/folder/Accounts/New'>Tài khoản ngân hàng</a> đầu tiên.",
  Cheque_Designer:"kiểm tra thiết kế",
  Cheque_Design:"kiểm tra thiết kế",
  Select_Design:"Chọn một thiết kế",
  Cheque_Designs:"kiểm tra thiết kế",
  No_Cheque_Designs:"Không kiểm tra thiết kế",
  No_Cheque_Designs_Word:"Tạo <a routerLink='/folder/Settings/Cheque/Design/New'>Thiết kế séc</a> của riêng bạn.",
  Set_Default:"Đặt làm mặc định",
  Default:"Mặc định",
  Remove:"Di dời",
  Folder:"Thư mục",
  Edit:"Biên tập",
  LoadingDots:"Đang tải...",
  Add_a_New_File:"Thêm <a href='#' (click)='add()'>Tệp mới</a> vào",
  this_folder:"thư mục này",
  FTP_Backup_Settings:"Cài đặt sao lưu FTP",
  Secure_File_Transfer:"Truyền tệp an toàn",
  New_Holiday:"Kỳ nghỉ mới",
  Add_Holiday:"Thêm kỳ nghỉ",
  Holiday_Name:"tên ngày lễ",
  Additional_Pay:"Lương bổ sung",
  Enable_Holiday:"Kích hoạt ngày lễ",
  Select_Holidays:"Chọn ngày lễ",
  No_Holidays:"không có ngày lễ",
  No_Holidays_Word:"Thêm <a routerLink='/folder/Holiday/New'>Ngày nghỉ lễ chung</a> đầu tiên.",
  New_Import:"Nhập khẩu mới",
  Import_Data:"Nhập dữ liệu",
  Import_Data_Word:"Chọn loại tệp hoặc phương pháp tải lên mà bạn chọn.<br /> Bạn sẽ có thể chọn bất kỳ trường nào đã nhập trong tệp tương ứng với bất kỳ tham số nào trong ứng dụng sau khi tải tệp được hỗ trợ lên.",
  Import_File:"Nhập tệp",
  Link_To_File:"Liên kết đến tệp",
  Select_File:"Chọn tập tin",
  New_Moderator:"Người điều hành mới",
  Allow_Moderation:"Cho phép kiểm duyệt",
  Create_Paycheques:"Tạo séc thanh toán",
  Edit_Paycheques_and_Data:"Chỉnh sửa phiếu lương và dữ liệu",
  Destroy_Data_and_Paycheques:"Phá hủy dữ liệu và séc thanh toán",
  Bonus_Percentage:"Tỷ lệ phần trăm tiền lương",
  Fixed_Amount:"Số tiền cố định",
  Select_Moderator:"Chọn người điều hành",
  No_Moderators:"Không có người điều hành",
  Moderators:"người điều hành",
  Moderator_Account:"Tạo <a routerLink='/folder/Administrator/New'>Tài khoản người điều hành</a> đầu tiên.",
  No_Moderators_Word:"Thêm <a routerLink='/folder/Administrator/New'>người điều hành</a> đầu tiên.",
  Defaults:"Mặc định",
  Provide_Us_Info:"cung cấp thông tin",
  Setup_Your_Printer:"Cài đặt máy in của bạn",
  Your_Company:"Về công ty của bạn",
  Company_Name:"Tên công ty",
  Currency:"Tiền tệ",
  Default_Currency:"mặc định ngoại tệ",
  Base_Monthly_Income:"Thu nhập hàng tháng",
  Protection:"Sự bảo vệ",
  App_Protection:"Bảo vệ ứng dụng",
  PIN_Code_Protection:"Bảo vệ mã PIN",
  App_Protection_Word:"Bật các phương pháp bảo mật giúp bảo vệ tài khoản của bạn.",
  PIN_Code:"Mã PIN",
  Change_PIN:"Thay đổi pin",
  New_Password:"mật khẩu mới",
  Geofence_Protection:"Bảo vệ hàng rào địa lý",
  Geofence_Area:"Đặt khu vực",
  Distance:"Khoảng cách",
  Setup_Now:"Thiết lập ngay",
  Mile:"dặm",
  Km:"km",
  m:"tôi",
  Facial_Recognition:"Nhận dạng khuôn mặt",
  Face:"Khuôn mặt",
  Setup:"Cài đặt",
  Label:"Nhãn",
  Password_Protection:"Mật khẩu bảo vệ",
  Modify_Password:"thay đổi mật khẩu",
  New_Tax_Entry:"Mục nhập thuế mới",
  New_Tax:"Thuế mới",
  Tax_Label:"Nhãn thuế",
  Perpetually_Enabled:"Kích hoạt vĩnh viễn",
  Select_Taxes:"Chọn Thuế",
  Tax_Deductions:"Khấu trừ thuế",
  No_Tax_Deductions:"Không khấu trừ thuế",
  No_Tax_Deductions_Word:"Thêm khoản khấu trừ <a routerLink='/folder/Tax/New'>Thuế</a> đầu tiên.",
  New_Timer:"Hẹn giờ mới",
  Start:"Bắt đầu",
  Stop:"Dừng lại",
  Start_Timer:"Bắt đầu hẹn giờ",
  Stop_Timer:"Hẹn giờ dừng",
  Timer_Active:"hẹn giờ hoạt động",
  Timer:"hẹn giờ:",
  Timer_Running:"Hẹn giờ: (Đang chạy)",
  Save_Timer:"Lưu hẹn giờ",
  New_Timesheet:"Bảng chấm công mới",
  Select_Timesheets:"Chọn Bảng chấm công",
  Work_Notes:"Ghi chú công việc",
  Entry_Title:"Tiêu đề mục nhập",
  No_Timesheets:"Không có bảng thời gian",
  No_Timesheets_Word:"Thêm <a routerLink='/folder/Timesheet/New'>Thời gian biểu</a> đầu tiên.",
  Timesheet_Submitted:"Bảng thời gian đã gửi",
  Timesheet_Congrats:"Chúc mừng! Bảng thời gian của bạn đã được gửi thành công. Cảm ơn!",
  Timesheet_Copy:"Để nhận bản sao tài liệu của bạn, vui lòng cung cấp cho chúng tôi địa chỉ email và/hoặc số điện thoại di động của bạn.",
  Submit:"Nộp",
  Allow_Notifications:"Cho phép thông báo",
  Untitled_Entry:"Lối đi mới",
  Untitled_Bank:"Ngân hàng không tên",
  Timesheet_Entry:"Mục nhập bảng chấm công",
  Work_Description:"Mô tả công việc",
  Enable_Timesheet:"Bật Bảng chấm công",
  Submit_Timesheet:"Gửi bảng chấm công",
  Vacation:"Kì nghỉ",
  New_Vacation:"kỳ nghỉ mới",
  Vacation_Name:"tên kỳ nghỉ",
  Paid_Vacation:"Kỳ nghỉ trả",
  Vacation_Pay:"Tiền lương cho kỳ nghỉ",
  Enable_Vacation:"Kích hoạt kỳ nghỉ",
  Select_Vacations:"Chọn kỳ nghỉ",
  No_Vacations:"Không có kỳ nghỉ",
  No_Vacations_Word:"Tạo mục nhập <a routerLink='/folder/Vacation/New'>Kỳ nghỉ</a> đầu tiên.",
  Payroll_Schedule:"bảng lương",
  Next_Payroll:"Bảng lương tiếp theo:",
  Upcoming_Payroll:"Bảng lương sắp tới",
  No_Upcoming_Payroll:"Không có bảng lương sắp tới",
  Address_Book:"Sổ địa chỉ",
  Archived_Documents:"Tài liệu lưu trữ",
  Dev_Mode:"Ứng dụng trong chế độ phát triển",
  Administrators:"quản trị viên",
  Privacy:"Sự riêng tư",
  None:"Không có",
  Select_Signature:"Chọn chữ ký",
  No_Signatures:"Không có chữ ký",
  No_Signatures_Word:"Thêm <a routerLink='/folder/Signature/New'>Chữ ký</a> đầu tiên.",
  Repeat_Indefinitely:"Lặp lại vô thời hạn",
  Sign:"Dấu hiệu",
  Sign_Here:"Ký vào đây",
  Date_Signed:"Ngày ký",
  Signature_Pad:"Bảng chữ ký",
  Account_Holder:"Chủ tài khoản",
  Account_Properties:"Thuộc tính tài khoản",
  Name_On_Cheque:"Tên trên séc",
  Server_Hostname:"Máy chủ Tên máy chủ / IP",
  Printers:"máy in",
  No_Printers:"Không có máy in",
  Printer_Exists:"Một máy in có tên này đã tồn tại.",
  No_Printers_Word:"Thêm <a routerLink='/folder/Printer/New'>máy in</a> đầu tiên.",
  No_Printer_Alert:"Không có máy in nào được xác định. Bạn có muốn thiết lập một máy in?",
  Add_Printer:"Thêm máy in",
  New_Printer:"Máy in mới",
  Printer_Hostname:"Máy in Tên máy chủ / IP",
  Printer_Label:"Nhãn máy in",
  Printer_Protocol:"Giao thức máy in",
  Protocol:"giao thức",
  Email_Print:"E-mail",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Ổ cắm",
  Print_Job:"Công việc in",
  Printed:"đã in",
  Not_Printed:"không được in",
  Print_Jobs:"Công việc in",
  Print_Queue:"Chuôi in",
  No_Print_Jobs:"Không có lệnh in",
  No_Prints:"Tạo một <a routerLink='/folder/Cheque/New'>Séc</a> mới để in.",
  Prints:"bản in",
  Find_Printer:"Tìm máy in",
  Find_AirPrint_Devices:"Tìm thiết bị AirPrint",
  Select_Printer:"Chọn máy in",
  System_UI:"Giao diện người dùng hệ thống",
  Printer:"máy in",
  Status:"Trạng thái",
  Preview:"Xem trước",
  Enable_Print_Job:"Bật lệnh in",
  Queue_Weight:"trọng lượng hàng đợi",
  Unlimited:"Vô hạn",
  Show_Advanced_Printer_Options:"Hiển thị Tùy chọn Máy in Nâng cao",
  Advanced:"Trình độ cao",
  Location:"Vị trí",
  Note:"Ghi chú",
  Queue_Name:"Tên hàng đợi",
  Pages_Printed_Limit:"Giới hạn số trang được in",
  MultiPage_Idle_Time:"(Các) Thời gian chờ nhiều trang",
  Page_Count_Limit:"Giới hạn số lượng trang",
  Page_Orientation:"Định hướng trang",
  Portrait:"Chân dung",
  Landscape:"Phong cảnh",
  Duplex:"song công",
  Double_Sided_Printing:"Hai mặt",
  Duplex_Mode:"Chế độ song công",
  Duplex_Short:"Ngắn",
  Duplex_Long:"Dài",
  Duplex_None:"Không có",
  Color_And_Quality:"màu sắc và chất lượng",
  Monochrome:"đơn sắc",
  Photo_Quality_Prints:"Bản in chất lượng ảnh",
  Printer_Email:"Email máy in",
  Automatically_Downsize:"Tự động thu nhỏ",
  Paper:"Giấy",
  Paper_Name:"Tên giấy",
  Paper_Width:"Chiều rộng giấy",
  Paper_Height:"Chiều cao giấy",
  Paper_Autocut_Length:"Chiều dài tự động cắt giấy",
  Margins:"lợi nhuận",
  Page_Margins:"Lề trang",
  Page_Margin_Top:"Lợi nhuận đầu trang",
  Page_Margin_Right:"Lề trang bên phải",
  Page_Margin_Bottom:"Lề trang dưới cùng",
  Page_Margin_Left:"Lề trang trái",
  Add_Employees:"Thêm nhân viên",
  Header:"tiêu đề",
  Print_A_Page_Header:"In tiêu đề trang",
  Header_Label:"Nhãn tiêu đề",
  Header_Page_Index:"Chỉ mục trang tiêu đề",
  Header_Font_Name:"Phông chữ tiêu đề",
  Select_Font:"Chọn phông chữ",
  Font_Selector:"Bộ chọn phông chữ",
  Header_Font_Size:"Phông chữ tiêu đề",
  Header_Bold:"Tiêu đề in đậm",
  Header_Italic:"Tiêu đề nghiêng",
  Header_Alignment:"Căn chỉnh tiêu đề",
  Left:"Bên trái",
  Center:"Trung tâm",
  Right:"Phải",
  Justified:"chính đáng",
  Header_Font_Color:"Màu tiêu đề",
  Select_Color:"Chọn màu",
  Footer:"chân trang",
  Print_A_Page_Footer:"In chân trang",
  Footer_Label:"Nhãn chân trang",
  Footer_Page_Index:"Chỉ mục trang chân trang",
  Footer_Font_Name:"Phông chữ chân trang",
  Fonts:"Phông chữ",
  Done:"Xong",
  Select_Fonts:"Chọn phông chữ",
  Footer_Font_Size:"Kích thước chân trang",
  Footer_Bold:"In đậm chân trang",
  Footer_Italic:"Chân trang nghiêng",
  Footer_Alignment:"Căn chỉnh chân trang",
  Footer_Font_Color:"Màu chân trang",
  Page_Copies:"Bản sao trang",
  Enable_Printer:"Kích hoạt máy in",
  Remote_Logging:"Ghi nhật ký từ xa",
  Log_Server:"Máy chủ nhật ký",
  Encryption:"mã hóa",
  Random_Key:"Khóa ngẫu nhiên",
  Encryption_Key:"Khóa mã hóa",
  Cheques_Webserver:"Cơ sở dữ liệu tùy chỉnh",
  Learn_How:"học như thế nào",
  Signature:"Chữ ký",
  Default_Printer_Unit:"tính bằng/cm/mm/(pt)",
  View_Signature:"Xem chữ ký",
  Printed_Signature:"Chữ ký in",
  Digitally_Sign:"Ký điện tử",
  Digital_Signature:"Chữ ký số",
  Add_Signature:"Thêm ngôn ngữ",
  Add_Your_Signature:"Thêm chữ ký của bạn",
  Enable_Signature:"Bật chữ ký",
  Digitally_Signed:"Chữ ký số",
  Insert_Error:"Không thể lưu séc do sự cố cơ sở dữ liệu.",
  Delete_Confirmation:"Bạn có chắc chắn muốn xóa thông tin này?",
  Discard_Confirmation:"Bạn có chắc chắn muốn loại bỏ thông tin này?",
  Discard_Bank_Confirmation:"Bạn có chắc chắn muốn hủy tài khoản này không?",
  Discard_Printer_Confirmation:"Bạn có chắc chắn muốn hủy máy in này không?",
  Delete_Bonus_Confirmation:"Bạn có chắc chắn muốn xóa phần thưởng này không?",
  Delete_Invoice_Confirmation:"Bạn có chắc chắn muốn xóa hóa đơn này không?",
  Generated_Cheque:"Kiểm tra đã tạo",
  Generated_Invoice:"Hóa đơn đã tạo",
  Schedule_Start_Time:"Lên lịch bắt đầu",
  Schedule_End_Time:"Kết thúc lịch trình",
  New_Call:"cuộc gọi mới",
  No_Contacts:"Không có Danh bạ",
  No_Contacts_Word:"Quản trị viên, người kiểm duyệt, nhân viên và người được trả tiền xuất hiện dưới dạng địa chỉ liên hệ.",
  PDF_Subject:"tài chính",
  PDF_Keywords:"séc trả lương séc kiểm tra PDF",
  Printer_Setup:"Thiết lập máy in",
  Printer_Selection:"Lựa chọn máy in",
  New_Fax:"Fax mới",
  New_Fax_Message:"Tin nhắn Fax mới",
  Fax_Machine:"Máy fax",
  Fax_Name:"Tên Fax",
  Fax_Email:"Email Fax",
  Fax_Number:"Số fax",
  Contents:"nội dung",
  Fax_Body:"Nội dung trang",
  Header_Word:"tiêu đề:",
  Header_Text:"Văn bản tiêu đề",
  Include_Header:"Bao gồm tiêu đề",
  Include_Footer:"Bao gồm chân trang",
  Footer_Word:"Chân trang:",
  Footer_Text:"Văn bản chân trang",
  Attach_a_Cheque:"Đính kèm séc",
  Add_Deduction:"Thêm khoản khấu trừ",
  Enable_Fax:"Gửi Fax",
  Select_Fax:"Chọn Fax",
  No_Faxes:"Không Fax",
  Faxes:"Fax",
  Save_and_Send:"Gửi Fax",
  Save_and_Pay:"Lưu và Thanh toán",
  WARNING:"CẢNH BÁO:",
  Remember:"Nhớ",
  Printing:"in ấn",
  Printing_Complete:"Hoàn thành in!\n\n",
  of:"của",
  There_Were:"đã có",
  Successful_Prints:"bản in thành công và",
  Unsuccessful_Prints:"bản in không thành công.",
  PrinterError:"Lấy làm tiếc! Có một lỗi.",
  Printing_:"Đang in...",
  PrinterSuccess:"Đã in tài liệu thành công.",
  PrintersSuccess:"Tài liệu được in thành công.",
  New_Message:"Tin nhắn mới",
  New_Messages:"Các tin nhắn mới",
  Read_Message:"đọc tin nhắn",
  Write_Message:"Viết tin nhắn",
  Send_Message:"Gửi tin nhắn",
  Subject:"Chủ thể",
  Message:"Tin nhắn",
  Writing:"Viết...",
  Send:"Gửi",
  Set_Date:"Đặt ngày",
  Set_Time:"Cài đặt thời gian",
  Recieve:"Nhận được",
  Set_as_Default:"Đặt làm mặc định",
  Default_Account:"Tài khoản mặc định",
  Default_Design:"Thiết kế mặc định",
  Multiple_Cheques:"ba lần kiểm tra",
  Account_Mode:"Chế độ tài khoản",
  Multiple_Cheques_Description:"Ba lần kiểm tra trên mỗi trang.",
  Check_and_Table:"Kiểm tra & Bảng",
  Check_including_Table:"Kiểm tra với bảng kế toán.",
  Check_Mailer:"kiểm tra bưu phẩm",
  Check_Mailer_Window:"Kiểm tra với một cửa sổ địa chỉ.",
  DocuGard_Table_Top:"Séc lớn & Bảng",
  DocuGard_Table_Middle:"Séc & Bàn Lớn (Giữa)",
  DocuGard_Table_Bottom:"Séc & Bảng lớn (Dưới cùng)",
  DocuGard_Mailer_Top:"Bưu phẩm kiểm tra lớn",
  DocuGard_Mailer_Middle:"Bưu phẩm séc lớn (giữa)",
  DocuGard_Mailer_Bottom:"Bưu phẩm séc lớn (Dưới cùng)",
  DocuGard_Three_Cheques:"Ba tấm séc lớn",
  DocuGard_Cheque_Top:"Séc lớn đơn",
  DocuGard_Cheque_Middle:"Séc Lớn (Giữa)",
  DocuGard_Cheque_Bottom:"Séc lớn (Dưới cùng)",
  DocuGard_Three_Cheques_Window:"Ba lần kiểm tra trên một trang.",
  DocuGard_Table_Top_Window:"Kiểm tra & bảng thu nhập.",
  DocuGard_Table_Middle_Window:"Kiểm tra & bảng thu nhập.",
  DocuGard_Table_Bottom_Window:"Kiểm tra & bảng thu nhập.",
  DocuGard_Mailer_Top_Window:"Séc, bảng và địa chỉ.",
  DocuGard_Mailer_Middle_Window:"Séc, bảng và địa chỉ.",
  DocuGard_Mailer_Bottom_Window:"Séc, bảng và địa chỉ.",
  DocuGard_Cheque_Top_Window:"Kiểm tra giấy an toàn.",
  DocuGard_Cheque_Middle_Window:"Kiểm tra giấy an toàn.",
  DocuGard_Cheque_Bottom_Window:"Kiểm tra giấy an toàn.",
  Basic_Cheque:"Kiểm tra duy nhất",
  Basic_Cheque_Print:"In ra một tấm séc duy nhất.",
  Error_Setting_As_Paid:"Lỗi Cài đặt là Trả phí",
  Add_Attachment:"Thêm bản đính kèm",
  PrinterUnavailable:"Máy in không khả dụng",
  CreditCardComponent:"thẻ",
  PaypalComponent:"PayPal",
  InteracComponent:"tương tác",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Tiền gửi mới",
  Deposits:"tiền gửi",
  No_Deposits:"Không có tiền gửi",
  Credit_Card_Deposit:"Thẻ tín dụng",
  New_Credit_Card_Deposit_Message:"Gửi tiền bằng thẻ tín dụng",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Tiền gửi BitCoin",
  New_Interac_Deposit:"tương tác",
  New_Interac_Deposit_Message:"chuyển giao liên lạc",
  Payments_Limit:"Hạn mức thanh toán",
  No_Payment_Limit:"Không giới hạn thanh toán",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Gửi tiền qua PayPal",
  No_Deposits_Word:"Thêm công ty đầu tiên <a routerLink='/folder/Deposit/New'>Deposit</a>.",
  No_Documents_Specified:"Không có tài liệu nào được chỉ định để in",
  No_Printers_Added:"Không tìm thấy máy in nào. Truy cập Cài đặt > Máy in để thêm một.",
  DB_Erase_Prompt:"Xóa hoàn toàn toàn bộ cơ sở dữ liệu? CẢNH BÁO: Bạn sẽ mất tất cả thông tin được lưu trữ!",
  Console_Warning:"Không dán bất kỳ văn bản nào vào bảng điều khiển này. Bạn có thể khiến bản thân và/hoặc công ty của bạn gặp rủi ro nghiêm trọng.",
  No_Faxes_Word:"Tạo <a routerLink='/folder/Fax/New'>Fax</a> đầu tiên.",
  Cheque_Delete_Confirmation:"Bạn có chắc chắn muốn xóa séc này không?",
  Design_Delete_Confirmation:"Bạn có chắc chắn muốn xóa thiết kế này?",
  Cheque_Pay_Confirmation:"Đánh dấu séc này là đã thanh toán? Nó sẽ KHÔNG xuất hiện trong hàng đợi in.",
  Payment_Pay_Confirmation:"Đánh dấu khoản thanh toán này là đã thanh toán? Nó sẽ KHÔNG xuất hiện trong hàng đợi kiểm tra.",
  Delete_Deduction_Confirmation:"Bạn có chắc chắn muốn xóa khoản khấu trừ này không?",
  Delete_Job_Confirmation:"Bạn có chắc chắn muốn xóa công việc này?",
  Delete_Timesheet_Confirmation:"Bạn có chắc chắn muốn xóa bảng chấm công này không?",
  Delete_Schedule_Confirmation:"Bạn có chắc chắn muốn xóa lịch trình này?",
  Delete_Setting_Confirmation:"Bạn có chắc chắn muốn đặt lại cài đặt này không?",
  Delete_Fax_Confirmation:"Bạn có chắc chắn muốn xóa bản fax này không?",
  Delete_File_Confirmation:"Bạn có chắc chắn muốn xóa tệp này không?",
  Delete_Vacation_Confirmation:"Bạn có chắc chắn muốn xóa kỳ nghỉ này?",
  Delete_Printer_Confirmation:"Bạn có chắc chắn muốn xóa máy in này không?",
  Remove_Design_Confirmation:"Bạn có chắc chắn muốn xóa thiết kế này?",
  Delete_Payroll_Confirmation:"Bạn có chắc chắn muốn xóa bảng lương này không?",
  Send_Fax_Email_Confirmation:"Bạn có muốn gửi fax và gửi email những tài liệu này không?",
  Send_Email_Confirmation:"Bạn có muốn gửi email tài liệu này?",
  Send_Fax_Confirmation:"Bạn có muốn fax tài liệu này không?",
  Error_Generating_PDF:"Lấy làm tiếc. Đã xảy ra lỗi khi tạo tài liệu này.",
  PDF_Error_Saving_Image:"Lấy làm tiếc. Đã xảy ra lỗi khi lưu hình ảnh PDF của tài liệu này.",
  Test_Printer_Confirmation:"Bạn có muốn in một trang kiểm tra trên máy in này không?",
  Save_Timesheet_Prompt:"Vui lòng thêm 'Tiêu đề' hoặc nhấn 'Bắt đầu hẹn giờ' để lưu.",
  Remove_Geofence_Prompt:"Bạn có chắc chắn muốn xóa vị trí của hàng rào địa lý này không?",
  Delete_Employee_Confirmation:"Bạn có chắc chắn muốn xóa",
  Fire_Employee_Confirmation:"Bạn có chắc chắn muốn sa thải",
}