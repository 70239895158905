export const Fa = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"حق چاپ & کپی; 2023",
  black:"مشکی",
  green:"سبز",
  gold:"طلا",
  blue:"آبی",
  brown:"رنگ قهوه ای",
  purple:"رنگ بنفش",
  pink:"رنگ صورتی",
  red:"قرمز",
  Swatches:"نمونه ها",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"رنگ",
  Saturation:"اشباع",
  Lightness:"سبکی",
  Upgrade_To_Pro:"پیشرفت",
  AllFeaturesInclude:"همه ویژگی ها عبارتند از:",
  PrintUnlimitedCheques:"چاپ چک های نامحدود",
  PremiumChequeDesigns:"طرح های چک حق بیمه",
  ManageUnlimitedEmployees:"کارمندان نامحدود را مدیریت کنید",
  AddUnlimitedPayees:"پرداخت‌کنندگان نامحدود را اضافه کنید",
  CreateUnlimitedPayrolls:"حقوق و دستمزد نامحدود ایجاد کنید",
  UnlimitedSchedulesandTimeSheets:"برنامه ها و برگه های زمانی نامحدود",
  BulkPayPalPayouts:"پرداخت های انبوه پی پال",
  UnlimitedBankAccounts:"حساب های بانکی نامحدود",
  ManageMultipleCompanies:"چندین شرکت را مدیریت کنید",
  TrackInsurancePolicies:"پیگیری بیمه نامه ها",
  Bio_MetricProtection:"حفاظت بیومتریک",
  Geo_FenceLock_OutProtection:"حفاظت از قفل ژئو حصار",
  Multiple_Companies_Word:"مدیریت چندین شرکت بدون حق بیمه چک در دسترس نیست.",
  PayPal_Payouts_Word:"پرداخت های پی پال بدون حق بیمه چک غیرفعال است.",
  PINProtection:"حفاظت از پین",
  PasswordProtection:"حفاظت از رمز عبور",
  May_Require_Approval:"ممکن است نیاز به تایید داشته باشد.",
  Subscribe:"اشتراک در",
  Billed:"صورتحساب شد",
  Up:"بالا",
  Down:"پایین",
  Positioning:"تثبیت موقعیت",
  Marker:"نشانگر",
  Drag_Marker:"نشانگر را بکشید",
  Tagline:"چاپ چک و جدول حقوق و دستمزد",
  Marker_Word:"از نشانگرها برای اندازه عنصر استفاده کنید.",
  Pinch_Zoom:"زوم را کوچک کنید",
  Pinch_Word:"برای بزرگنمایی عنصر، انگشت خود را فشار دهید.",
  Drag:"بکشید",
  Drag_Word:"از انگشت خود برای کشیدن عناصر استفاده کنید.",
  subscription_alias_word:"تمدید خودکار اشتراک",
  premium_alias_word:"بسته ارتقاء یکباره",
  print_alias_word:"چک های انفرادی چاپی",
  mode_alias_word:"حالت",
  Pro:"حرفه ای",
  Pro_word:"نسخه حرفه ای مورد نیاز است.",
  Cant_Delete_Default_Company_Word:"متأسفیم، شما نمی توانید شرکت پیش فرض خود را حذف کنید.",
  Reinsert_Default_Designs:"طرح های پیش فرض را دوباره درج کنید",
  Reinsert_Default_Designs_word:"آیا می خواهید طرح های پیش فرض را دوباره درج کنید؟",
  Subscription_Active_Disable_Word:"این اشتراک فعال است. آیا می خواهید این اشتراک در چک را لغو کنید؟",
  Company_Logo:"آرم شرکت",
  ZERO_:"صفر",
  Disclaimer:"سلب مسئولیت",
  Privacy_Policy:"سیاست حفظ حریم خصوصی",
  EULA:"EULA را چک می کند",
  Terms_Of_Service:"شرایط استفاده از خدمات",
  Terms_Of_Use:"شرایط استفاده",
  Refunds:"سیاست بازپرداخت",
  Single_Print:"1 بررسی کنید",
  Two_Prints:"2 چک",
  Five_Prints:"5 چک",
  Ten_Prints:"10 چک",
  Fifteen_Prints:"15 چک",
  Twenty_Prints:"20 چک",
  Thirty_Prints:"30 چک",
  Image_Added:"تصویر اضافه شد",
  Image_Preview:"پیش نمایش تصویر",
  No_Image_Was_Selected:"هیچ تصویری انتخاب نشد.",
  Cheques_Unlimited:"نامحدود چک می کند",
  _Subscription:"اشتراک، ابونمان",
  Subscription:"چک - 1 ماه",
  Two_Month_Subscription:"چک - 2 ماه",
  Three_Month_Subscription:"چک - 3 ماه",
  Six_Month_Subscription:"چک - 6 ماه",
  Twelve_Month_Subscription:"چک - 12 ماه",
  Cheques_Are_Available:"چک برای چاپ موجود است.",
  Upgrade_Required_Two:"بسته ای را انتخاب کنید و روی دکمه قیمت دوبار ضربه بزنید تا خرید خود شروع شود. چک های تمام رنگی حرفه ای را در چند ثانیه چاپ کنید.",
  Month:"ماه",
  Due:"ناشی از:",
  Expires:"منقضی می شود:",
  Subscription_Active:"اشتراک فعال",
  Subscription_Inactive:"اشتراک غیر فعال",
  Purchase_Additional_Cheques:"چک اضافی بخرید؟",
  Printable_Cheque:"چک قابل چاپ",
  Printable_Cheques:"چک های قابل چاپ",
  Printable_Cheque_Word:"چک در حساب شما موجود است.",
  Printable_Cheques_Word:"چک در حساب شما موجود است.",
  Max_Amount_Message:"مقداری که شما مشخص کرده اید به بیش از حداکثر مقدار تعیین شده برای این سیستم رسیده است:",
  Terms_Required_Word:"قبل از ادامه استفاده از چک باید با این توافقنامه موافقت کنید.",
  Subscriptions:"اشتراک ها",
  Product_Upgrades:"ارتقاء",
  Mailed_Out_Cheques:"چک های ارسال شده از طریق پست",
  Enter_A_Company_Name:"لطفا نام شرکت را وارد کنید",
  Single_Cheques:"چک های تک",
  Cheque_Golden:"چک طلایی",
  Cheque_Golden_Window:"طرح چک طلایی.",
  Cheque_Green:"چک سبز",
  Cheque_Green_Window:"طرح چک سبز.",
  Cheque_Red:"چک قرمز",
  Cheque_Red_Window:"طرح چک قرمز.",
  Cheque_Yellow:"چک زرد",
  Cheque_Yellow_Window:"طرح چک زرد.",
  Cheque_Statue_of_Liberty:"مجسمه آزادی",
  Cheque_Statue_of_Liberty_Window:"طرح چک مجسمه آزادی.",
  Cheque_Green_Wave:"موج سبز",
  Cheque_Green_Wave_Window:"طرح چک سبز.",
  Cheque_Golden_Weave:"بافت طلایی",
  Cheque_Golden_Weave_Window:"طرح شیک چک طلایی.",
  Cheque_Green_Sun:"خورشید سبز",
  Cheque_Green_Sun_Window:"طراحی چک عمیق و آرامش بخش.",
  Cheque_Blue_Checkers:"چکرز آبی",
  Cheque_Blue_Checkers_Window:"طرح چک آبی.",
  Cashiers_Check:"چک صندوقدار",
  Cashiers_Check_Window:"قالب سبک صندوقدار چک.",
  Cheque_Aqua_Checkers:"چکرز آبی",
  Cheque_Aqua_Checkers_Window:"طرح آکوا چک.",
  Cheque_Golden_Checkers:"چکرز طلایی",
  Cheque_Golden_Checkers_Window:"طرح چک طلایی.",
  Upgrade_Unavailable:"ارتقاها در دسترس نیستند",
  Bank_Code_Protection:"حفاظت از شماره بانک",
  Bank_Code_Protection_Word:"از استفاده از شماره‌های بانک خود در این برنامه که روی دستگاه دیگری یا برای کاربر دیگری اجرا می‌شود، محافظت کنید. این عمل غیر قابل برگشت است. ادامه هید؟",
  Bank_Code_Protection_Blocked_Word:"شماره‌های بانکی که می‌خواهید استفاده کنید برای کاربر یا دستگاه دیگری رزرو شده است.",
  Bank_Code_Protection_Error_Word:"تأیید شماره انجام نشد. لطفاً به اینترنت متصل شوید و دوباره این حساب بانکی را اضافه کنید.",
  Bank_Code_Protection_Set_Error_Word:"حفاظت از شماره بانک مستلزم اتصال به اینترنت است. لطفاً وصل شوید و دوباره امتحان کنید.",
  Upgrade_Unavailable_Word:"متأسفیم، در تأیید شما با مشکل روبرو هستیم. اشتراک‌ها و ارتقاء چک‌ها در حال حاضر برای خرید در منطقه شما در دسترس نیست.",
  PayPal_Payment_Preview:"پیش نمایش پرداخت PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"PayPal را انتخاب کنید",
  PayPal_Applications:"برنامه های پی پال",
  Purchase_With_Apple_Pay:"با Apple Pay خرید کنید",
  Google_Pay:"Google Pay",
  Companies:"شرکت ها",
  Insurance:"بیمه",
  New_PayPal:"پی پال جدید",
  Pay_By:"پرداخت توسط",
  Insure:"بیمه کنید",
  Miles:"مایل",
  Payment_Method:"روش پرداخت",
  Select_Policies:"سیاست ها را انتخاب کنید",
  Policies:"سیاست های",
  Policy:"خط مشی",
  No_PayPal_Account:"بدون حساب پی پال",
  No_Policies:"بدون بیمه نامه",
  New_Policy:"سیاست جدید",
  PayPal_Payment:"پرداخت پی پال",
  PayPal_Payments:"پرداخت های پی پال",
  No_Payment_Selected:"هیچ پرداختی انتخاب نشده است",
  Sending_Payment_Word:"لطفا صبر کنید... این پرداخت در حال ارسال است.",
  Sending_Payments_Word:"لطفا صبر کنید... پرداخت ها در حال ارسال هستند.",
  No_Payment_Selected_PayPal:"هیچ <a routerLink='/folder/Payments'> پرداخت PayPal</a> برای ارسال انتخاب نشده است.",
  Payment_Sent:"پرداخت ارسال شد",
  PayPal_Payment_Sent:"این پرداخت با پی پال ارسال شده است.",
  Copay:"کپی کنید",
  Dead:"مرده",
  Alive:"زنده",
  Not_Dead:"نمرده",
  Unclaimed:"بی ادعا",
  Attempted:"تلاش",
  Deceased:"فوت شده",
  Claimed:"ادعا کرد",
  Unpaid:"پرداخت نشده",
  Sending_Payment:"ارسال پرداخت",
  Sending_Payments:"ارسال پرداخت ها",
  Send_PayPal_Confirmation:"آیا می خواهید این تراکنش را با پی پال ارسال کنید؟",
  Send_PayPal_Confirmation_Word:"برای ارسال این تراکنش دکمه سبز رنگ را فشار دهید.",
  Save_Payment_As_Unpaid:"این پرداخت به عنوان پرداخت نشده ذخیره شود؟",
  Payment_Pay_Confirmation_PayPal:"این پرداخت به صورت پرداختی ذخیره شود؟",
  No_Policies_Word:"اولین <a routerLink='/folder/Postage/New'> بیمه نامه</a> را اضافه کنید .",
  Timesheet_Multiple_Delete_Confirmation:"آیا مطمئن هستید که می خواهید چندین جدول زمانی را حذف کنید؟",
  Select_Multiple_Timesheets_Prompt:"هیچ جدول زمانی انتخاب نشده است. حداقل یک جدول زمانی انتخاب کنید.",
  Select_Multiple_Policies_Prompt:"هیچ خط‌مشی انتخاب نشده است. حداقل یک بیمه نامه را انتخاب کنید.",
  Policies_Multiple_Delete_Confirmation:"آیا مطمئن هستید که می خواهید چندین خط مشی را حذف کنید؟",
  Company:"شرکت",
  Add_Company:"اضافه کردن شرکت",
  New_Company:"اضافه کردن شرکت",
  No_Companies:"بدون شرکت",
  Enable_Company:"شرکت را فعال کنید",
  Select_Company:"شرکت را انتخاب کنید",
  The_Default_Company:"برچسب شرکت پیش فرض",
  Manage_Companies:"مدیریت شرکت ها",
  No_Companies_Word:"چک ها از یک شرکت پیش فرض استفاده می کنند.<br /> <a routerLink='/folder/Company/New'> اولین شرکت</a> را اضافه کنید .",
  Default_Company:"شرکت پیش فرض",
  Cheques_Unlimited_Word:"Checks Unlimited به شما امکان می دهد هر تعداد چک را که دوست دارید چاپ کنید.",
  Cheques_Subscription_Word:"اشتراک چک به شما امکان می دهد هر تعداد چک را که دوست دارید چاپ کنید.",
  You_Own_This_Product:"شما صاحب این محصول هستید.",
  Your_Subscription_is_Active:"اشتراک شما فعال است",
  Active_Products:"محصولات فعال شده",
  Purchase_Confirmation:"خرید",
  Purchase_Cheques:"خرید چک",
  Restore_Purchase:"بازگرداندن خرید",
  Erase_Purchase:"پاک کردن خریدها",
  Successfully_Purchased:"با موفقیت خریداری شد",
  Enter_Your_Licence_Key:"لطفا کلید مجوز خود را در این صفحه وارد کنید تا این محصول فعال شود.",
  Licence_Key:"کلید مجوز",
  Enter_Licence_Key:"کلید مجوز را وارد کنید",
  Purchased:"خریداری شده است",
  Enable_Feature:"فعال کردن ویژگی",
  Cancel_Subscription:"لغو عضویت",
  Subscription_Removed:"اشتراک حذف شد",
  Select_Active_Subscription:"یک اشتراک فعال را برای تغییر آن انتخاب کنید.",
  Remove_Subscription_Word:"آیا مطمئن هستید که می خواهید این اشتراک را لغو کنید؟",
  Delete_Company_Confirmation:"آیا مطمئن هستید که می خواهید کل این شرکت را حذف کنید؟ هشدار: تمام اطلاعات ذخیره شده را از دست خواهید داد!",
  Delete_Default_Company_Word:"شما نمی توانید شرکت پیش فرض را حذف کنید. آیا می خواهید برنامه را بازنشانی کنید و آن را به حالت پیش فرض برگردانید؟ هشدار: تمام اطلاعات ذخیره شده را از دست خواهید داد!",
  Console_Warning_2:"با استفاده از این برنامه که در حال حاضر مال شما نیست، از هیچ ارزی استفاده نکنید.",
  Terms_and_Conditions:"شرایط و ضوابط",
  and_the:"و",
  for:"برای",
  Please_Read_Word:"لطفا بخوانید و با آن موافقت کنید",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"برخی از نرخ‌های تبدیل ارز پیدا نشد. لطفا به اینترنت وصل شوید.",
  Free:"رایگان",
  DB_Erase_Prompt_2:"آیا کل پایگاه داده توسعه دهنده پاک شود؟ اخطار: تمام اطلاعات خرید و اشتراک را از دست خواهید داد!",
  Successfully_Imported:"با موفقیت وارد شد",
  Select_Postage:"هزینه پست را انتخاب کنید",
  No_Postage:"بدون تمبر پستی",
  No_Paid_Postage_Word:"اولین تمبر <a routerLink='/folder/Postage/New'> هزینه پست پرداخت شده</a> را اضافه کنید .",
  Trial_Complete:'آزمایشی کامل شد',
  Please_Upgrade:'لطفاً چک‌ها را برای ادامه چاپ ارتقا دهید.',
  Aa:"Aa",
  Color:"رنگ",
  Font:"فونت",
  Guide:"راهنما",
  Guides:"راهنماها",
  Image:"تصویر",
  Zoom:"بزرگنمایی",
  Logo:"لوگو",
  Bank:"بانک",
  MICR:"MICR",
  Total:"جمع",
  Cancel:"لغو کنید",
  Confirm:"تایید",
  Method:"روش",
  Biometric_Security:"امنیت بیومتریک",
  Please_Login_To_Continue:"لطفا برای ادامه وارد شوید.",
  Complete:"کامل",
  Sign_Up:"ثبت نام",
  Error:"خطا",
  Biometrics:"بیومتریک",
  Percent:"درصد",
  Zero_Percent:"0%",
  Top_Margin:"حاشیه بالا",
  Bottom_Margin:"حاشیه پایینی",
  Left_Margin:"حاشیه چپ",
  Right_Margin:"حاشیه سمت راست",
  MICR_Margin:"حاشیه MICR",
  Table_Margin:"حاشیه جدول",
  Address_Margin:"حاشیه آدرس",
  Percentage_:"درصد",
  Vacation_Title:"عنوان تعطیلات",
  Use_PIN:"از پین استفاده کنید",
  Loading__:"بارگذاری...",
  Design_Title:"عنوان طراحی",
  Authorize:"مجوز دهید",
  Key:"کلید",
  Public_Key:"کلید عمومی",
  Private_Key:"کلید خصوصی",
  Authenticate:"تصدیق کردن",
  Last_Payroll:"آخرین حقوق و دستمزد",
  Last_Calculation:"آخرین محاسبه",
  Authorized:"مجاز",
  Geo_Authorized:"موقعیت جغرافیایی: مجاز",
  Not_Authorized:"مجاز نیست",
  Authorization_Complete:"مجوز کامل شد",
  Geolocation_Authorization:"مجوز موقعیت جغرافیایی",
  Out_of_Bounds:"خارج از محدوده",
  Cant_Delete_Default_Design:"نمی توان طرح پیش فرض را حذف کرد.",
  Unauthorized_Out_of_Bounds:"غیر مجاز: خارج از محدوده",
  Biometric_Authorization:"مجوز بیومتریک",
  Locating_Please_Wait:"در حال مکان یابی، لطفا صبر کنید...",
  Test_Biometrics:"تست بیومتریک",
  Cheque_Margins:"حاشیه ها را بررسی کنید",
  Percentage_Full_Error:"فیلد درصد را نمی توان بیش از 100٪ درصد تنظیم کرد.",
  No_Payroll_Text:"یک <a routerLink='/folder/Employee/New'>کارمند</a> یا <a routerLink='/folder/Payee/New'> گیرنده پرداخت</a> و یک <a routerLink='/folder/Schedule/New'>برنامه</a>.",
  Design_Saved:"طراحی ذخیره شد",
  Default_Design_Selected:"طرح پیش‌فرض انتخاب شد",
  Partial_Import:"واردات جزئی",
  Partial_Export:"صادرات جزئی",
  Entire_Import:"کل واردات",
  Entire_Export:"صادرات کامل",
  Existing_Password:"لطفا رمز عبور موجود خود را وارد کنید:",
  Existing_PIN:"لطفا پین کد موجود خود را وارد کنید:",
  Pin_Change_Header:"تایید پین",
  Pin_Change_SubHeader:"برای تأیید تغییر، شماره پین قدیمی خود را وارد کنید.",
  Pass_Change_Header:"تایید رمز عبور",
  Pass_Change_SubHeader:"رمز عبور قدیمی خود را برای تایید تغییر وارد کنید.",
  PIN_Enter_Message:"برای تایید پین خود را وارد کنید.",
  Password_Enter_Message:"رمز عبور خود را برای تایید وارد کنید.",
  Pin_Updated_Success:"پین با موفقیت به روز شد!",
  Pin_Updated_Fail:"پین به‌روزرسانی نشد.",
  Pass_Updated_Success:"رمز عبور با موفقیت به روز شد.",
  Pass_Updated_Fail:"رمز عبور نمی تواند به روز شود.",
  Preview_Payment:"پیش نمایش پرداخت",
  Preview_Payroll:"پیش نمایش حقوق و دستمزد",
  No_Payments_Created:"هیچ پرداختی برای ایجاد یافت نشد.",
  Payment_Preview:"پیش نمایش پرداخت",
  Enable_Payee:"گیرنده پرداخت را فعال کنید",
  Rendered:"ارائه شده است",
  No_Email:"بدون ایمیل",
  Setup_Cheques:"چک های راه اندازی",
  name:"نام",
  address:"نشانی",
  address_2:"آدرس خط 2",
  city:"شهر",
  province:"استان",
  postal_code:"کد پستی/پستی",
  country:"کشور",
  username:"نام کاربری",
  full_name:"نام و نام خانوادگی",
  birthday:"روز تولد",
  email:"پست الکترونیک",
  phone:"تلفن",
  employees:"کارمندان",
  addresses:"آدرس ها",
  payment_amount_limit:"محدودیت مبلغ پرداخت",
  total_limit:"حد کل",
  payees:"پرداخت کنندگان",
  description:"شرح",
  address_line_one:"آدرس خط یک",
  address_line_two:"آدرس خط دو",
  image:"تصویر",
  account_holder:"صاحب حساب",
  cheque_starting_id:"شناسه شروع را بررسی کنید",
  transit_number:"شماره ترانزیت",
  institution_number:"شماره موسسه",
  designation_number:"شماره تعیین",
  account_number:"شماره حساب",
  currency:"واحد پول",
  signature:"امضا",
  payment_payroll_limit:"محدودیت پرداخت",
  total_limi:"حد کل",
  date:"تاریخ",
  printed_memo:"یادداشت چاپی",
  banks:"بانک ها",
  signature_image:"تصویر امضا",
  address_name:"نام آدرس",
  notes:"یادداشت",
  design:"طرح",
  title:"عنوان",
  frequency:"فرکانس",
  fax:"فکس",
  salaries:"حقوق و دستمزد",
  salary_ids:"شناسه حقوق و دستمزد",
  start_time:"زمان شروع",
  end_time:"زمان پایان",
  paid:"پرداخت شده",
  overtime_percentage:"درصد پرداخت شده",
  overtime_amount:"مبلغ ثابت پرداخت شده",
  first_name:"نام کوچک",
  last_name:"نام خانوادگی",
  moderation:"اعتدال",
  create:"ایجاد کردن",
  edit:"ویرایش کنید",
  destroy:"از بین رفتن",
  day_start_time:"روز_شروع_زمان",
  day_end_time:"روز_پایان_زمان",
  fullname:"نام",
  time:"زمان",
  auto_send:"ارسال خودکار",
  time_method:"روش زمان",
  schedules:"برنامه",
  indefinite_payroll_enabled:"به صورت نامحدود فعال شود",
  amount:"میزان",
  repeat:"تکرار",
  always_enabled:"همیشه فعال است",
  start_date:"تاریخ شروع",
  end_date:"تاریخ پایان",
  Cheque_Total:"Total را بررسی کنید",
  Total_Amount:"کل مبلغ:",
  Amounts:"مبالغ:",
  Images:"تصاویر",
  Files:"فایل ها",
  Previewing:"پیش نمایش:",
  Insert:"درج کنید",
  Preview_Import:"پیش نمایش واردات",
  Entry:"ورود",
  Entries:"ورودی های",
  No_Entries:"بدون ورودی",
  Import_Type:"نوع واردات",
  Select_Details:"جزئیات را انتخاب کنید",
  Select_Payee:"گیرنده پرداخت را انتخاب کنید",
  Enable_Holidays:"تعطیلات را فعال کنید",
  Disable_Holidays:"تعطیلات را غیرفعال کنید",
  Wire_Transfer:"انتقال سیم",
  New_Export:"صادرات جدید",
  Export_Data:"صادرات داده",
  Export_Data_Word:"نوع فایل را برای صادرات و دانلود انتخاب کنید.",
  Export_File:"صادرات فایل",
  Mode:"حالت",
  Times:"بار",
  Widgets:"ابزارک ها",
  Slider:"لغزنده",
  Set_Details:"تنظیم جزئیات",
  Select_Type:"نوع را انتخاب کنید",
  Display_Slider:"نمایشگر لغزنده",
  Dashboard_Slider:"نوار لغزنده داشبورد",
  Dashboard_Mode:"حالت داشبورد",
  Show_Intro:"نمایش مقدمه",
  Show_Payrolls:"نمایش حقوق و دستمزد",
  Show_Holidays:"نمایش تعطیلات",
  Show_Invoices:"نمایش فاکتورها",
  Show_Cheques:"نمایش چک ها",
  Enabled_Widgets:"ابزارک های فعال",
  Disabled_Widgets:"ابزارک های غیرفعال",
  Colors:"رنگ ها",
  Barcodes:"بارکدها",
  View_Timers:"مشاهده تایمرها",
  Gradients:"گرادیان ها",
  No_Info:"بدون اطلاعات",
  Disable:"غیر فعال کردن",
  Show_Layer:"نمایش لایه ها",
  Hide_Layer:"پنهان کردن لایه ها",
  Text_Layer:"لایه های متن",
  Secondly:"دوما",
  Minutely:"دقیقه",
  nine_am:"09:00",
  five_pm:"5:00 بعد از ظهر",
  Enable_Address:"آدرس را فعال کنید",
  Invalid_File_Type:"با عرض پوزش، یک نوع فایل نامعتبر انتخاب شد. نوع فایل پشتیبانی شده:",
  Error_Updating_Entry:"با عرض پوزش، هنگام به روز رسانی این ورودی خطایی روی داد.",
  Schedule_Timing_Alert:"خطا: زمان شروع زمانبندی روی مقداری بعد از زمان پایان تنظیم می شود.",
  Select_Multiple_Payments_Prompt:"هیچ پرداختی انتخاب نشده است. حداقل یک پرداخت را انتخاب کنید.",
  Select_Multiple_Cheques_Prompt:"هیچ چکی انتخاب نشده است. لطفاً حداقل یک چک را انتخاب کنید.",
  Select_Multiple_Items_Prompt:"هیچ موردی انتخاب نشده است. لطفا حداقل یک مورد را انتخاب کنید.",
  Paymemt_Multiple_Delete_Confirmation:"آیا مطمئن هستید که می خواهید چندین پرداخت را حذف کنید؟",
  Cheque_Multiple_Delete_Confirmation:"آیا مطمئن هستید که می خواهید چندین چک را حذف کنید؟",
  Payee_Multiple_Delete_Confirmation:"آیا مطمئنید که می‌خواهید چند گیرنده پرداخت را حذف کنید؟",
  Employee_Multiple_Delete_Confirmation:"آیا مطمئن هستید که می خواهید چندین کارمند را حذف کنید؟",
  MICR_Warning:"توجه: برخی از چاپگرها و دستگاه ها ممکن است فونت MICR را به درستی نمایش ندهند.",
  Automatically_Send:"ارسال خودکار",
  Type:"تایپ کنید",
  Payment_Type:"نوع پرداخت",
  Auto_Send:"ارسال خودکار",
  Automatically_Process:"پردازش خودکار",
  Auto_Process:"پردازش خودکار",
  Image_Based:"مبتنی بر تصویر",
  Font_Based:"مبتنی بر فونت",
  Rerender:"رندر مجدد",
  Rendering:"تفسیر",
  Render:"فایل ها",
  Top:"بالا",
  Middle:"وسط",
  Bottom:"پایین",
  Top_Left:"بالا سمت چپ",
  Top_Center:"مرکز برتر",
  Top_Right:"بالا سمت راست",
  Middle_Left:"چپ میانه",
  Middle_Center:"مرکز میانه",
  Middle_Right:"راست میانه",
  Bottom_Left:"پایین سمت چپ",
  Bottom_Center:"مرکز پایین",
  Bottom_Right:"سمت راست پایین",
  Numbers:"شماره",
  Verified:"تایید شده است",
  Paper_Size:"اندازه کاغذ",
  New_Device:"دستگاه جدید",
  Add_Device:"افزودن دستگاه",
  Remove_Device:"دستگاه را خارج کنید",
  Delete_Device:"دستگاه را حذف کنید",
  Block_Device:"مسدود کردن دستگاه",
  Block:"مسدود کردن",
  Unblock:"رفع انسداد",
  Table:"جدول",
  Scan_Login_Code:"اسکن کد ورود",
  Login_Code:"کد ورود",
  Scan_Code:"اسکن کد",
  Scan_QR_Code:"کد QR را اسکن کنید",
  Select_All:"انتخاب همه",
  Deselect_All:"همه را لغو انتخاب کنید",
  Increase:"افزایش دادن",
  Decrease:"نزول کردن",
  Bold:"پررنگ",
  Text:"متن",
  Style:"سبک",
  Italic:"کج",
  QR_Code:"کد QR",
  Barcode:"بارکد",
  Browse_Images:"تصاویر را مرور کنید",
  Browse_Files:"مرور فایل ها",
  Background_Image:"تصویر پس زمینه",
  Logo_Image:"تصویر لوگو",
  Header_Image:"تصویر هدر",
  Bank_Image:"تصویر بانک",
  Cut_Lines:"خطوط برش",
  Background:"زمینه",
  License:"مجوز",
  One_License:"1 مجوز:",
  Licensed:"دارای مجوز به:",
  Not_Licensed:"مجوز ندارد",
  Enter_Serial:"سریال را وارد کنید",
  Serial_Key:"شماره سریال",
  Serial:"سریال",
  Product_Key:"کلید محصول",
  Check_Product_Key:"کلید محصول را بررسی کنید",
  Add_Product_Key:"افزودن کلید محصول",
  Verifying_Purchase:"در حال تایید خرید...",
  Print_Envelope:"چاپ پاکت",
  Envelope:"پاكت نامه",
  Thank_You:"متشکرم!",
  Scale:"مقیاس",
  Print_Scale:"مقیاس چاپ",
  Borders:"مرز ها",
  VOID:"خالی",
  Void_Cheque:"چک بی اعتبار",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"پرداخت به سفارش:",
  NO_DOLLARS:"بدون دلار ",
  ONE_DOLLAR:"یک دلار",
  DOLLARS:" دلار",
  AND:" و ",
  CENTS:" سنت.",
  NO_:"نه ",
  ONE_:"یکی ",
  AND_NO_:"و نه ",
  _AND_ONE_:"و یکی ",
  DOLLARS_AND_ONE_CENT:" و یک سنت.",
  AND_NO_CENTS:" و صفر سنت.",
  CHEQUE_PRINT_DATE:"تاریخ:",
  CHEQUE_PRINT_MEMO:"یادداشت:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"نماینده مجلس",
  Initial_Tasks: "وظایف اولیه",
  Inital_Setup: "تنظیم اولیه",
  Welcome_To: "خوش آمدید به ",
  Welcome: "خوش آمدی",
  Swipe: "تند کشیدن",
  Intro_Setup: "راه اندازی مقدماتی",
  Introduction: "معرفی",
  CHEQUE_PRINT_CREDIT: "تأمین شده توسط چک",
  Title_Intro_Word: "به چک خوش آمدید",
  Title_Intro: "معرفی چک",
  Title_Setup: "تنظیم چک",
  PayPal_Default_Email_Message: "شما یک انتقال پی پال جدید دریافت کرده اید. [تأمین شده توسط چک]",
  Cheques_App_Export: "صادرات توسط چک",
  Post_Intro_Thanks: "از اینکه چک ها را انتخاب کردید متشکریم. مراحل راه اندازی اکنون کامل شده است.",
  Post_Intro_Word:"با چاپ اولین چک خود شروع کنید، یک پرداخت آینده اضافه کنید، یا یک کارمند را به لیست حقوق و دستمزد اضافه کنید.",
  Upgrade_Required:"چک‌ها از شما می‌خواهند که نسخه پریمیوم‌تری از نرم‌افزار را داشته باشید تا این پیام را مخفی کنید و ویژگی‌های اضافی را باز کنید.",
  Upgrade_Title:"چک",
  Mailout_Prompt:"به‌علاوه می‌توانید انتخاب کنید که چک‌ها چک‌های حقوق و دستمزدتان را برای شما پست کنند.",
  Mailed_Cheque:"چک پستی: ",
  Mailed_Cheque_Color:"چک پستی (رنگی): ",
  Terms_Purchase: "همه خریدهای الکترونیکی با چک تا 30 روز از تاریخ خرید به طور کامل قابل استرداد هستند. لطفاً قبل از ادامه <a href='#'>شرایط و ضوابط</a> را بخوانید و با آن موافقت کنید.",
  Dashboard_Setup: "راه اندازی چاپگر اصلی",
  Dashboard_Add: "افزودن حساب بانکی اصلی",
  Dashboard_Customize: "انتخاب یک الگوی چک",
  Dashboard_Job_Salary: "شغل خود را ایجاد کنید و حقوق خود را اضافه کنید",
  Dashboard_Employees: "ردیابی کارکنان و دریافت کنندگان پرداخت",
  Dashboard_Print: "چک دستمزد خود را چاپ و ارسال کنید",
  Dashboard_Payroll: "چاپ حقوق و دستمزد خود را خودکار کنید",
  Dashboard_PayPal: "تنظیم PayPal / پرداخت ها",
  Begin_Setup: "شروع راه اندازی",
  Get_Started: "شروع کن",
  Purchase: "خرید",
  Lockdown: "تعطیلی",
  Unlock: "باز کردن قفل",
  Detailed: "جزئیات",
  Log_In:"وارد شدن",
  Login:"وارد شدن",
  Launch:"راه اندازی",
  Register:"ثبت نام",
  Finish:"پایان",
  List:"فهرست",
  Weekends:"آخر هفته",
  Weekly:"هفتگی",
  PayPal_Default_Subject: "پرداخت جدید",
  Payment_Message: "پیام پرداخت",
  PayPal_Default_Payment_Note: "متشکرم",
  Setup_Your_Cheqing_Account: "حساب چک",
  Add_Your_Primary_Cheqing_Account: "حساب چک اصلی خود را اضافه کنید.",
  Welcome_Word: "ساده سازی و خودکارسازی مدیریت حقوق و دستمزد و منابع انسانی.",
  Get_Started_Quickly: "فقط به چند سوال ساده پاسخ دهید که به ما کمک می کند شروع کنیم...",
  Done_Intro_Word: "تنظیم کامل شد",
  PIN_Protected: "رمز عبور و پین محافظت شده",
  Enter_New_PIN: "یک کد پین جدید را وارد کنید:",
  Enter_PIN: "کد پین را وارد کنید:",
  Invalid_PIN: "PIN نامعتبر وارد شد.",
  Account_Identifier: "شناسه حساب",
  New_Account_Identifier: "شناسه حساب جدید",
  attempt: "تلاش",
  attempts: "تلاش",
  remaining: "باقی مانده",
  Language: "زبان",
  languages: "زبان ها",
  select_languages: "زبان را انتخاب کنید",
  Deposit: "سپرده",
  Hire_One_Now: "اکنون یکی را استخدام کن",
  App_Locked: "برنامه قفل شده است.",
  Skip_: "پرش",
  Skip_to_Dashboard: "پرش به داشبورد",
  Dashboard: "داشبورد",
  Import:"وارد كردن",
  Admin:"مدیران",
  New_Admin:"ادمین جدید",
  Settings:"تنظیمات",
  Color_Picker: "انتخاب کننده رنگ",
  Font_Picker: "انتخاب قلم",
  Logout: "خروج",
  Close:"نزدیک",
  Close_menu:"نزدیک",
  Excel:"فایل اکسل",
  Csv:"فایل CSV",
  Sql:"فایل SQL",
  Json:"فایل JSON",
  Url:"وارد کردن با URL",
  Back:"بازگشت",
  Timers:"تایمر",
  Cheque:"بررسی",
  Print:"چاپ",
  Designs:"طرح ها",
  Pause_Printing:"مکث چاپ",
  Resume_Printing:"چاپ رزومه",
  Printing_Paused:"چاپ متوقف شد",
  PrintingUnavailable:"با عرض پوزش! چاپ در این سیستم در دسترس نیست.",
  Prints_Paused:"چاپ متوقف شد",
  Administration:"مدیریت",
  Loading:"بارگذاری",
  Unnamed:"بی نام",
  error:"با عرض پوزش، این چک برای مشاهده باز نشد.",
  No_Cheques_To_Print:"بدون چک برای چاپ",
  No_Cheques_To_Print_Word:"یک <a routerLink='/folder/Cheque/New'>چک جدید</a> ایجاد کنید.",
  New_Cheque:"چک جدید",
  Start_One_Now: "اکنون یکی را شروع کن",
  Edit_Cheque: "ویرایش چک",
  Select_Cheques:"انتخاب چک",
  Select_Employee:"انتخاب کارمند",
  Default_Printer:"چاپگر پیش فرض",
  Reassign:"تخصیص مجدد",
  Configure:"پیکربندی",
  Template:"قالب",
  Designer: "طراح",
  Edit_Designs: "ویرایش طرح ها",
  New_Design: "طراحی جدید",
  Next: "بعد",
  Save:"صرفه جویی",
  Name:"نام",
  Mail:"پست",
  Amount:"میزان",
  Date:"تاریخ",
  PayPal:"پی پال",
  Payouts:"پرداخت ها",
  PayPal_Label:"لیبل پی پال",
  Email_Username:"ایمیل / نام کاربری",
  Client_ID:"شناسه مشتری",
  Sandbox_Email:"ایمیل جعبه ایمنی",
  PayPal_Email:"پی پال ایمیل",
  PayPal_Username:"نام کاربری API",
  PayPal_Payouts:"پرداخت های پی پال",
  Select_PayPal_Key:"کلید پی پال را انتخاب کنید",
  Secret:"راز",
  API_Secret:"راز API",
  PayPal_API_Keys:"کلیدهای پی پال",
  New_PayPal_Key:"کلید جدید پی پال",
  Email_Subject: "موضوع نامه الکترونیکی",
  Email_Message: "پیام ایمیل",
  Payout_Options: "گزینه های پرداخت",
  Payment_Note: "یادداشت پرداخت",
  Enable_Employee: "فعال کردن کارمند",
  Enable_Production_Mode:"فعال کردن حالت تولید",
  Sandbox_Username: "نام کاربری جعبه ایمنی",
  Sandbox_Signature: "امضای جعبه شن",
  Sandbox_Password: "رمز عبور جعبه شن",
  Production_Username: "نام کاربری تولید",
  Production_Signature: "امضای تولید",
  Production_Password: "رمز عبور تولید",
  Production_Email: "ایمیل تولید",
  API_Client_ID:"API Client ID",
  API_Signature:"امضای API",
  API_Password:"گذرواژه API",
  API_Username:"نام کاربری API",
  Secret_Key: "کلید مخفی",
  Sandbox: "جعبه شنی",
  Production: "تولید",
  Sandbox_Keys: "کلیدهای جعبه شن",
  Production_Keys: "کلیدهای تولید",
  API_Title: "عنوان API",
  Production_Mode:"حالت تولید",
  Account_Label: "برچسب حساب",
  No_PayPal_Setup: "بدون کلید پی پال",
  Enable_PayPal_Account:"فعال کردن حساب پی پال",
  No_PayPal_Word:"<a routerLink='/folder/Invoice/New'>کلید API PayPal</a> خود را اضافه کنید.",
  Printed_Memo:"یادداشت چاپی",
  Employee:"کارمند",
  View_Employee:"مشاهده کارمند",
  Mailing_Address:"آدرس پستی",
  Company_Address:"آدرس شرکت",
  Select_Company_Address:"انتخاب آدرس شرکت",
  Address:"نشانی",
  Any_Day:"هر روزی",
  Address_Name:"نام آدرس",
  Unit:"واحد",
  Account:"حساب",
  Bank_Account:"حساب بانکی",
  Account_Limits:"فعال کردن محدودیت های حساب",
  Payroll: "دستمزد",
  New_Payroll: "دستمزد جدید",
  No_Payroll: "بدون حقوق و دستمزد آینده",
  Upcoming_Holiday: "تعطیلات پیش رو:",
  Invoice_Due: "سررسید فاکتور:",
  New_Invoice: "فاکتور جدید",
  No_Invoices: "بدون فاکتور",
  No_Invoices_Word: "اولین <a routerLink='/folder/Invoice/New'>فاکتور</a> را ایجاد کنید.",
  Cheque_Due: "سررسید چک:",
  Payrolls: "لیست حقوق و دستمزد",
  Sandbox_Mode: "حالت جعبه ایمنی",
  Hire: "استخدام",
  Pay: "پرداخت",
  New: "جدید",
  Add: "اضافه کردن",
  Make: "ساختن",
  Time: "زمان",
  Write: "نوشتن",
  Holiday: "تعطیلات",
  Holidays: "تعطیلات",
  Next_Holiday: "تعطیلات بعدی:",
  All_Done: "همه چیز تمام شد!",
  Employees: "کارمندان",
  Payees:"پرداخت کنندگان",
  Job:"کار",
  Jobs:"شغل ها",
  Invoice:"صورتحساب",
  Invoices:"فاکتورها",
  Vacations:"تعطیلات",
  Cheques:"چک",
  Brand_Cheques:"نام تجاری",
  Payment:"پرداخت",
  Enable_Payment:"فعال کردن پرداخت",
  Payments:"مبلغ پرداختی",
  Schedule: "برنامه",
  Schedules: "برنامه",
  Timesheet: "برگه زمان",
  Timesheets:"برگه های زمانی",
  Salary:"حقوق",
  New_Address:"آدرس جدید",
  Address_2: "آدرس (خط 2)",
  _City: "شهر",
  _State: "دولت / اثبات",
  City: "شهر / شهرستان",
  State: "ایالت / استان",
  Postal: "پستی / کد پستی",
  ZIP: "پستی / ZIP",
  Country: "کشور",
  Addresses: "آدرس ها",
  Required_Options: "گزینه های مورد نیاز",
  Optional_Options:"گزینه های اختیاری",
  Additional_Options:"گزینه های اضافی",
  Required:"ضروری",
  Optional:"اختیاری",
  Additional:"اضافی",
  No_Addresses: "بدون آدرس",
  New_Address_Word: "اولین <a routerLink='/folder/Address/New'>آدرس</a> را اضافه کنید.",
  Select_Address: "انتخاب آدرس",
  No_Address: "بدون آدرس",
  Print_Cheque: "چک چاپ",
  Print_Cheque_Now: "چاپ بررسی کن",
  Description: "شرح",
  Pay_To_The_Order_Of: "پرداخت به سفارش:",
  Select_Date_Range: "انتخاب محدوده تاریخ",
  Select_Starting_Date: "انتخاب تاریخ شروع",
  Select_Ending_Date: "انتخاب تاریخ پایان",
  Select_Date: "انتخاب تاریخ",
  Cheque_Date: "تاریخ بررسی",
  Cheque_Memo: "بررسی یادداشت",
  Blank_Cheque: "چک سفید",
  Blank:"جای خالی",
  No_Cheques: "بدون چک",
  No_Cheques_Word: "اولین <a routerLink='/folder/Cheque/New'>بررسی</a> خود را چاپ کنید.",
  Cheque_Amount_Placeholder: "0.00 دلار",
  View_Image: "مشاهده تصویر",
  View: "چشم انداز",
  Modify: "تغییر",
  Delete: "حذف",
  Cheque_Paid: "پرداخت شده",
  New_Deduction: "کسر جدید",
  Title: "عنوان",
  Frequency: "فرکانس",
  Hourly: "ساعتی",
  Daily: "روزانه",
  Weekdays: "روزهای هفته",
  BiWeekly: "2 هفته",
  TriWeekly: "3 هفته",
  Monthly: "ماهانه",
  MiMonthly: "2 ماه",
  Quarterly: "سه ماه یکبار",
  Yearly: "سالانه",
  Every_Week: "هر هفته",
  Every_Payroll: "هر حقوق و دستمزد",
  Every_Month: "هر ماه",
  Annually: "سالانه",
  Always_Scheduled: "همیشه برنامه ریزی شده",
  Start_Date: "تاریخ شروع",
  End_Date: "تاریخ پایان",
  Start_Time: "زمان شروع",
  End_Time: "زمان پایان",
  Deduction_Label: "برچسب کسر",
  Notes: "یادداشت",
  Options: "گزینه ها",
  Enable: "فعال کردن",
  Select_Deductions: "انتخاب کسرها",
  Deductions:"کسر",
  No_Deductions:"بدون کسر",
  No_Deductions_Word:"اولین <a routerLink='/folder/Deduction/New'>کسر</a> خود را ایجاد کنید.",
  New_Employee:"کارمند جدید",
  No_Title: "بدون عنوان",
  _Name: "نام",
  About_Yourself: "درباره خودتان",
  Full_Name: "نام و نام خانوادگی",
  Birthday: "روز تولد",
  Email: "پست الکترونیک",
  SMS: "پیامک",
  Phone: "تلفن",
  Test: "تست",
  Call:"زنگ زدن",
  Fax:"فکس",
  Printed_Note: "یادداشت چاپی",
  Position: "موقعیت",
  Job_Position: "موقعیت شغلی",
  Select_a_Job: "انتخاب شغل",
  Select_a_Salary: "انتخاب حقوق",
  Add_a_Deduction: "افزودن کسر",
  Taxes: "مالیات",
  Add_Taxes: "افزودن مالیات",
  Date_of_Birth: "تاریخ تولد",
  Email_Address: "آدرس ایمیل",
  Phone_Number: "شماره تلفن",
  Phone_Call: "تماس تلفنی",
  Enable_on_Payroll: "فعال کردن در حقوق و دستمزد",
  Select_Employees: "انتخاب کارکنان",
  No_Employees: "بدون کارمند",
  No_Employees_Word: "اولین <a routerLink='/folder/Employee/New'>کارمند</a> جدید خود را اضافه کنید.",
  No_Name: "بدون نام",
  Unemployeed:"بیکار",
  Employeed:"به کار گرفته شده",
  Paid:"پرداخت شده",
  Enabled:"فعال",
  Disabled:"معلول",
  Fire:"آتش",
  Not_Available:"در دسترس نیست",
  Not_Available_Word:"اولین <a routerLink='/folder/Invoice/New'>فاکتور</a> خود را چاپ کنید و پول دریافت کنید.",
  Select_Invoices: "انتخاب_فاکتورها",
  Print_Invoice_Word: "اولین <a routerLink='/folder/Invoice/New'>فاکتور</a> خود را چاپ کنید و پول دریافت کنید.",
  Invoice_Title:"عنوان فاکتور",
  Invoice_Date:"تاریخ فاکتور",
  Due_Date:"تاریخ سررسید",
  New_Job: "شغل جدید",
  Details: "جزئیات",
  Customize: "شخصی سازی",
  Customize_Dashboard: "سفارشی کردن داشبورد",
  Components: "اجزاء",
  No_Components: "بدون مولفه",
  Main_Components: "اجزای اصلی",
  Smaller_Components: "اجزای کوچکتر",
  Error_Loading_Page: "خطا در بارگیری این صفحه.",
  Bank_Details: "جزئیات بانک",
  About_Your_Job: "درباره شغل شما",
  Your_Schedule: "زمان بندی شما",
  Job_Title: "عنوان شغلی",
  Job_Description: "شرح شغل",
  Job_Details: "جزئیات شغل",
  Enable_Job: "فعال کردن کار",
  Pay_Period: "دوره پرداخت",
  Perpetually_Schedule: "همیشه برنامه ریزی",
  Select_Jobs: "انتخاب مشاغل",
  No_Jobs: "بدون شغل",
  Add_Jobs: "افزودن مشاغل",
  No_Jobs_Word: "اولین <a routerLink='/folder/Job/New'>شغل</a> را به لیست اضافه کنید.",
  Count_Employees: "job.employee_count+' کارمندان",
  Zero_Employees: "0 کارمند",
  New_Leave: "مرخصی جدید",
  Leave_Name: "اسم را بگذارید",
  Paid_Leave: "مرخصی با حقوق",
  Leave_Pay: "پرداخت مرخصی",
  Indefinite_Leave: "مرخصی نامحدود",
  Indefinite_Payroll: "حقوق دستمزد نامحدود",
  Leave: "ترک کردن",
  Add_Schedules: "افزودن برنامه ها",
  Percentage: "100٪",
  Enable_Leave_Of_Absence: "فعال کردن غیبت",
  Select_Leaves: "انتخاب برگ",
  No_Leaves: "بدون برگ غیبت",
  Leave_Of_Absence: "مرخصی غیبت",
  Leaves_Of_Absence: "برگ غیبت",
  New_Leave_of_Absense: "مرخصی جدید",
  No_Leaves_Word: "اولین <a routerLink='/folder/Leave/New'>مرخصی</a> را اضافه کنید.",
  Not_Enabled: "غیر فعال",
  Absences: "غیبت",
  Payee: "دریافت کننده وجه",
  New_Payee: "پرداخت کننده جدید",
  Payee_Identifier: "شناسه گیرنده پرداخت",
  Payee_Name: "نام گیرنده پرداخت",
  Payee_Title: "عنوان گیرنده پرداخت",
  Payment_Memo: "یادداشت پرداخت",
  Select_Payees: "انتخاب گیرندگان پرداخت",
  No_Payees: "بدون پرداخت کننده",
  Add_Payee_Note: "اولین <a routerLink='/folder/Payee/New'>پرداخت کننده</a> را اضافه کنید.",
  New_Payees: "پرداخت کنندگان جدید",
  About_The_Payment_Schedule: "زمان بندی پرداخت",
  Your_Payroll_Schedule: "دستمزد خودکار",
  New_Payment: "پرداخت جدید",
  Identifier: "مشخص کننده",
  Select: "انتخاب کنید",
  Memo: "یادداشت",
  Payment_Date: "تاریخ پرداخت",
  Mark_as_Paid: "علامت گذاری به عنوان پرداخت شده",
  Select_Payments: "انتخاب پرداخت ها",
  No_Payments: "بدون پرداخت",
  No_Payments_Word: "اولین <a routerLink='/folder/Payment/New'>پرداخت</a> را ایجاد کنید.",
  Create_Payroll: "ایجاد لیست حقوق و دستمزد",
  Properties: "خواص",
  Payroll_Title: "عنوان حقوق و دستمزد",
  Payroll_Notes: "یادداشت حقوق و دستمزد",
  Payroll_Setup: "تنظیم حقوق و دستمزد",
  Tabulate_Payments: "جدول پرداخت ها",
  Tabulate: "جدول",
  By: "توسط:",
  Payments_By: "پرداخت توسط",
  Timesheets_And_Schedules: "برگه های زمانی و برنامه ها",
  Both: "هر دو",
  Items: "موارد",
  Add_Payees: "افزودن گیرندگان پرداخت",
  Add_Account: "اضافه کردن حساب",
  Enable_Account: "فعال کردن حساب",
  Enable_Payroll: "فعال کردن حقوق و دستمزد",
  Print_Payroll: "چاپ حقوق و دستمزد",
  No_Payrolls: "بدون حقوق و دستمزد",
  No_Payroll_Word: "اولین <a routerLink='/folder/Payroll/New'>دستمزد</a> خود را ایجاد کنید.",
  View_more: "بیشتر ببینید",
  Less:"کمتر",
  Add_Payroll:"افزودن حقوق و دستمزد",
  Select_Payroll: "انتخاب حقوق و دستمزد",
  About_Your_Salary: "درباره حقوق شما",
  Add_Salaries: "افزودن حقوق",
  Add_Salary: "افزودن حقوق",
  Salaries: "حقوق",
  No_Salaries: "بدون حقوق",
  No_Salaries_Word: "اولین <a routerLink='/folder/Salary/New'>حقوق</a> را اضافه کنید.",
  Select_Salaries: "انتخاب حقوق",
  New_Salary: "حقوق جدید",
  Salary_Name: "شناسه حقوق و دستمزد",
  Enable_Salary: "فعال کردن حقوق",
  Salary_Amount: "میزان حقوق و دستمزد",
  New_Schedule: "برنامه جدید",
  Schedule_Title: "عنوان برنامه",
  Add_Address: "اضافه کردن آدرس",
  Repeat: "تکرار",
  Design: "طرح",
  Edit_Design: "ویرایش طراحی",
  Edit_this_Design: "ویرایش این طرح",
  Repeat_Payment: "تکرار پرداخت",
  Enable_Schedule: "فعال کردن زمانبندی",
  Never: "هرگز",
  Select_Schedule: "انتخاب برنامه ها",
  No_Schedules: "بدون برنامه",
  No_Schedules_Word: "اولین <a routerLink='/folder/Schedule/New'>برنامه</a> را ایجاد کنید.",
  New_Administrator: "مدیر جدید",
  Username: "نام کاربری",
  First_Name: "نام کوچک",
  Last_Name: "نام خانوادگی",
  Add_an_Address: "افزودن آدرس",
  Payment_Limit: "محدودیت در هر پرداخت",
  Total_Limit: "محدودیت کل",
  Select_Accounts: "انتخاب حساب ها",
  No_Administrators: "بدون مدیر",
  No_Administrators_Word: "اولین <a routerLink='/folder/Administrator/New'>حساب مدیریت</a> را ایجاد کنید.",
  New_Administrators_Word: "اولین <a routerLink='/folder/Administrator/New'>مدیر</a> را اضافه کنید",
  Cloud: "ابر",
  Backup: "پشتیبان گیری",
  Enable_iCloud: "فعال کردن iCloud",
  Enable_Google_Drive:"فعال کردن Google Drive",
  Enable_Microsoft_OneDrive:"فعال کردن Microsoft OneDrive",
  Automatically_Backup:"پشتیبان گیری خودکار",
  FTP_Settings:"تنظیمات FTP",
  URL_File_Prompt:"لطفا مکان یک فایل .xls / .xlsx / .json را برای وارد کردن مشخص کنید:",
  URL_SQL_Prompt:"لطفا مکان فایل SQLite را برای وارد کردن مشخص کنید:",
  FTP_Backup:"پشتیبان گیری FTP",
  FTP_Import:"واردات FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"میزبان",
  Port:"بندر",
  Path:"مسیر",
  Data_Path:"مسیر داده",
  Enable_FTP_Account:"فعال کردن حساب FTP",
  HostnameIP: "نام میزبان",
  Password: "کلمه عبور",
  Connection_Port: "پورت اتصال",
  Enable_MySQL_Database: "پایگاه داده MySQL را فعال کنید",
  Log: "ورود",
  Reset: "بازنشانی",
  Erase: "پاک کردن",
  Export: "صادرات",
  Database: "پایگاه داده",
  Upload_CSV: "آپلود CSV",
  Download_CSV: "دانلود CSV",
  SQL_Database: "URL SQL",
  MySQL_Database: "MySQL",
  MySQL_Backup: "پشتیبان گیری MySQL",
  Internal_Notes: "یادداشت های داخلی",
  Root_Path: "مسیر ریشه",
  Select_Backup: "انتخاب پشتیبان گیری",
  Add_New_Backup:"افزودن یک نسخه پشتیبان جدید",
  First_Backup:"تنظیم اولین نسخه پشتیبان...",
  Backups:"پشتیبان گیری",
  Add_Backup:"افزودن پشتیبان",
  No_Backups:"هیچ نسخه پشتیبان برای یافتن وجود ندارد.",
  Select_Backup_Type:"نوع پشتیبان مورد نظر برای تنظیم را انتخاب کنید...",
  Backup_Type:"نوع پشتیبان",
  FTP_Drive:"FTP Drive",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"راندن",
  Microsoft_OneDrive:"راندن",
  Import_Fields:"زمینه های واردات",
  Import_Fields_Word:"از این بخش برای انتخاب داده <a routerLink='/folder/Holiday/New'>وارد کردن</a> استفاده کنید.",
  Upload: "بارگذاری",
  Download: "دانلود",
  Download_JSON:"دانلود به عنوان داده JSON",
  Download_SQL:"دانلود به عنوان فایل SQL",
  New_Bank: "بانک جدید",
  New_Account: "حساب جدید",
  New_Bank_Account: "حساب بانکی جدید",
  Upload_Image: "آپلود تصویر",
  Bank_Name: "نام بانک",
  Bank_Address: "آدرس بانک",
  Branch_Address: "آدرس شعبه",
  Address_on_Cheque: "نشانی",
  Cheque_Numbers: "بررسی اعداد",
  Cheque_Details: "بررسی جزئیات",
  Bank_Logo: "لوگوی بانک",
  Cheque_ID: "بررسی شناسه",
  Starting_Cheque_ID: "شروع بررسی شناسه",
  Transit_Number: "شماره ترانزیت",
  Institution_Number: "شماره موسسه",
  Designation_Number: "شماره تعیین",
  Account_Number: "شماره حساب",
  Limits: "محدودیت ها",
  Default_Limits: "محدودیت های پیش فرض",
  Over_Limit: "بیش از حد",
  Under_Limit: "تحت محدودیت",
  Payroll_Limit: "محدودیت حقوق و دستمزد",
  Enable_Bank_Account: "فعال کردن حساب بانکی",
  Select_Account: "انتخاب حساب",
  No_Bank_Account: "بدون حساب بانکی",
  No_Bank_Account_Word: "اولین <a routerLink='/folder/Accounts/New'>حساب بانکی</a> را اضافه کنید.",
  Bank_Accounts: "حساب های بانکی",
  No_Accounts: "بدون حساب",
  No_Accounts_Note: "اولین <a routerLink='/folder/Accounts/New'>حساب بانکی</a> را اضافه کنید.",
  Cheque_Designer: "طراح چک",
  Cheque_Design: "بررسی طراحی",
  Select_Design: "انتخاب یک طرح",
  Cheque_Designs: "بررسی طرح ها",
  No_Cheque_Designs: "بدون طرح چک",
  No_Cheque_Designs_Word: "<a routerLink='/folder/Settings/Cheque/Design/New'>بررسی طرح</a> خود را ایجاد کنید.",
  Set_Default: "تنظیم به عنوان پیشفرض",
  Default: "پیش فرض",
  Remove: "برداشتن",
  Folder: "پوشه",
  Edit: "ویرایش",
  LoadingDots: "بارگذاری...",
  Add_a_New_File: "افزودن یک <a href='#' (click)='add()'>پرونده جدید</a> به",
  this_folder: "این پوشه",
  FTP_Backup_Settings: "تنظیمات پشتیبان گیری FTP",
  Secure_File_Transfer: "انتقال امن فایل",
  New_Holiday: "تعطیلات جدید",
  Add_Holiday: "افزودن تعطیلات",
  Holiday_Name: "نام تعطیلات",
  Additional_Pay: "پرداخت اضافی",
  Enable_Holiday: "فعال کردن تعطیلات",
  Select_Holidays: "انتخاب تعطیلات",
  No_Holidays: "بدون تعطیلات",
  No_Holidays_Word: "اولین <a routerLink='/folder/Holiday/New'>تعطیلات عمومی</a> را اضافه کنید.",
  New_Import: "واردات جدید",
  Import_Data: "واردات داده",
  Import_Data_Word: "نوع فایل یا روش آپلود مورد نظر خود را انتخاب کنید.<br /> شما می توانید پس از آپلود فایل پشتیبانی شده، فیلدهای وارد شده در یک فایل با پارامترهای موجود در برنامه را انتخاب کنید.",
  Import_File: "وارد کردن فایل",
  Link_To_File:"پیوند به فایل",
  Select_File:"فایل را انتخاب کنید",
  New_Moderator:"مدیریت جدید",
  Allow_Moderation: "اجازه اعتدال",
  Create_Paycheques: "ایجاد چک حقوق",
  Edit_Paycheques_and_Data: "ویرایش چک حقوق و داده ها",
  Destroy_Data_and_Paycheques: "از بین بردن داده ها و چک های حقوق",
  Bonus_Percentage: "درصد چک حقوق",
  Fixed_Amount: "مقدار ثابت",
  Select_Moderator: "انتخاب مدیر",
  No_Moderators: "بدون ناظر",
  Moderators: "مدیران",
  Moderator_Account: "اولین <a routerLink='/folder/Administrator/New'>حساب مدیر</a> را ایجاد کنید.",
  No_Moderators_Word: "اولین <a routerLink='/folder/Administrator/New'>مجری</a> را اضافه کنید.",
  Defaults: "پیش فرض",
  Provide_Us_Info: "اطلاعات را به ما ارائه دهید",
  Setup_Your_Printer: "چاپگر خود را راه اندازی کنید",
  Your_Company: "درباره شرکت شما",
  Company_Name: "نام شرکت",
  Currency: "واحد پول",
  Default_Currency: "ارز پیش فرض",
  Base_Monthly_Income: "درآمد ماهانه",
  Protection: "حفاظت",
  App_Protection: "محافظت از برنامه",
  PIN_Code_Protection: "محافظت کد پین",
  App_Protection_Word: "روش های امنیتی را فعال کنید که به محافظت از حساب شما کمک می کند.",
  PIN_Code: "پین کد",
  Change_PIN: "تغییر پین",
  New_Password: "رمز عبور جدید",
  Geofence_Protection: "حفاظت از حصار جغرافیایی",
  Geofence_Area: "محدوده تنظیم",
  Distance: "فاصله",
  Setup_Now: "اکنون راه اندازی",
  Mile: "مایل",
  Km: "کیلومتر",
  m: "م",
  Facial_Recognition: "تشخیص چهره",
  Face: "صورت",
  Setup: "برپایی",
  Label: "برچسب",
  Password_Protection: "محافظت با رمز عبور",
  Modify_Password: "تغییر رمز عبور",
  New_Tax_Entry: "ورودی مالیاتی جدید",
  New_Tax: "مالیات جدید",
  Tax_Label: "برچسب مالیاتی",
  Perpetually_Enabled: "همیشگی فعال",
  Select_Taxes: "انتخاب مالیات",
  Tax_Deductions: "کسر مالیات",
  No_Tax_Deductions: "بدون کسر مالیات",
  No_Tax_Deductions_Word: "اولین کسر <a routerLink='/folder/Tax/New'>مالیات</a> را اضافه کنید.",
  New_Timer: "تایمر جدید",
  Start: "شروع",
  Stop: "متوقف کردن",
  Start_Timer: "شروع تایمر",
  Stop_Timer: "توقف تایمر",
  Timer_Active: "تایمر فعال",
  Timer: "تایمر:",
  Timer_Running: "تایمر: (در حال اجرا)",
  Save_Timer: "ذخیره تایمر",
  New_Timesheet: "برگ زمان جدید",
  Select_Timesheets: "انتخاب برگه های زمانی",
  Work_Notes: "یادداشت های کاری",
  Entry_Title: "عنوان ورودی",
  No_Timesheets: "بدون برگه زمانی",
  No_Timesheets_Word: "اولین <a routerLink='/folder/Timesheet/New'>برگه زمان</a> را اضافه کنید.",
  Timesheet_Submitted: "برگه زمانی ارسال شد",
  Timesheet_Congrats: "تبریک! برگه زمان شما با موفقیت ارسال شد. متشکرم!",
  Timesheet_Copy: "برای دریافت یک کپی از مدارک خود لطفا آدرس ایمیل و/یا شماره تلفن همراه خود را به ما ارائه دهید.",
  Submit: "ارسال",
  Allow_Notifications: "اجازه اعلان ها",
  Untitled_Entry: "ورودی جدید",
  Untitled_Bank:"بانک بدون عنوان",
  Timesheet_Entry: "ورودی برگه زمانی",
  Work_Description: "شرح کار",
  Enable_Timesheet: "فعال کردن برگه زمان",
  Submit_Timesheet: "ارسال برگه زمانی",
  Vacation: "تعطیلات",
  New_Vacation: "تعطیلات جدید",
  Vacation_Name: "نام تعطیلات",
  Paid_Vacation: "مرخصی با حقوق",
  Vacation_Pay: "پرداخت تعطیلات",
  Enable_Vacation: "فعال کردن تعطیلات",
  Select_Vacations: "انتخاب تعطیلات",
  No_Vacations: "بدون تعطیلات",
  No_Vacations_Word: "اولین ورودی <a routerLink='/folder/Vacation/New'>تعطیلات</a> را ایجاد کنید.",
  Payroll_Schedule: "برنامه حقوق و دستمزد",
  Next_Payroll: "حقوق بعدی:",
  Upcoming_Payroll: "دستمزد آتی",
  No_Upcoming_Payroll: "بدون حقوق و دستمزد آینده",
  Address_Book: "دفترچه آدرس",
  Archived_Documents:"اسناد آرشیو شده",
  Dev_Mode:"برنامه در حالت توسعه",
  Administrators:"مدیران",
  Privacy:"حریم خصوصی",
  None:"هیچ یک",
  Select_Signature:"انتخاب امضا",
  No_Signatures:"بدون امضا",
  No_Signatures_Word: "اولین <a routerLink='/folder/Signature/New'>امضا</a> را اضافه کنید.",
  Repeat_Indefinitely: "تکرار بی نهایت",
  Sign: "امضا کردن",
  Sign_Here: "اینجا را امضا کنید",
  Date_Signed:"تاریخ امضاء",
  Signature_Pad:"صفحه امضا",
  Account_Holder:"صاحب حساب",
  Account_Properties:"ویژگی های حساب",
  Name_On_Cheque:"نام در چک",
  Server_Hostname: "نام میزبان / IP",
  Printers: "چاپگرها",
  No_Printers: "بدون چاپگر",
  Printer_Exists: "یک چاپگر با این نام از قبل وجود دارد.",
  No_Printers_Word: "اولین <a routerLink='/folder/Printer/New'>چاپگر</a> را اضافه کنید.",
  No_Printer_Alert: "هیچ چاپگری تعریف نشده است. آیا می خواهید یک چاپگر راه اندازی کنید؟",
  Add_Printer: "افزودن چاپگر",
  New_Printer: "چاپگر جدید",
  Printer_Hostname: "نام میزبان چاپگر / IP",
  Printer_Label: "لیبل چاپگر",
  Printer_Protocol: "پروتکل چاپگر",
  Protocol: "پروتکل",
  Email_Print: "پست الکترونیک",
  AirPrint: "AirPrint",
  IPP: "IPP",
  LPD: "LPD",
  HPJetDirect: "سوکت",
  Print_Job: "کار چاپ",
  Printed: "چاپ شده",
  Not_Printed: "چاپ نشده",
  Print_Jobs: "مشاغل چاپ",
  Print_Queue: "صف چاپ",
  No_Print_Jobs: "بدون کار چاپ",
  No_Prints: "یک <a routerLink='/folder/Cheque/New'>چک</a> جدید برای چاپ ایجاد کنید.",
  Prints: "چاپ",
  Find_Printer: "یافتن چاپگر",
  Find_AirPrint_Devices: "یافتن دستگاه های AirPrint",
  Select_Printer: "انتخاب چاپگر",
  System_UI: "واسطه کاربری سیستم",
  Printer: "چاپگر",
  Status: "وضعیت",
  Preview: "پیش نمایش",
  Enable_Print_Job: "فعال کردن کار چاپ",
  Queue_Weight: "وزن صف",
  Unlimited:"نامحدود",
  Show_Advanced_Printer_Options: "نمایش گزینه های پیشرفته چاپگر",
  Advanced: "پیشرفته",
  Location: "محل",
  Note: "توجه داشته باشید",
  Queue_Name: "نام صف",
  Pages_Printed_Limit: "محدودیت چاپ صفحات",
  MultiPage_Idle_Time:"زمان (های) انتظار چند صفحه",
  Page_Count_Limit:"محدودیت تعداد صفحات",
  Page_Orientation:"جهت صفحه",
  Portrait:"پرتره",
  Landscape:"چشم انداز",
  Duplex:"دوبلکس",
  Double_Sided_Printing:"دو طرفه",
  Duplex_Mode:"حالت دوبلکس",
  Duplex_Short:"کوتاه",
  Duplex_Long:"طولانی",
  Duplex_None:"هیچ یک",
  Color_And_Quality:"رنگ و کیفیت",
  Monochrome:"تک رنگ",
  Photo_Quality_Prints:"چاپ با کیفیت عکس",
  Printer_Email: "ایمیل چاپگر",
  Automatically_Downsize: "کوچک کردن خودکار",
  Paper:"کاغذ",
  Paper_Name:"نام کاغذ",
  Paper_Width:"عرض کاغذ",
  Paper_Height:"ارتفاع کاغذ",
  Paper_Autocut_Length:"طول برش خودکار کاغذ",
  Margins:"حاشیه",
  Page_Margins:"حاشیه صفحه",
  Page_Margin_Top:"حاشیه صفحه بالا",
  Page_Margin_Right:"حاشیه صفحه سمت راست",
  Page_Margin_Bottom:"حاشیه صفحه پایین",
  Page_Margin_Left:"حاشیه صفحه سمت چپ",
  Add_Employees:"افزودن کارمندان",
  Header:"سرتیتر",
  Print_A_Page_Header:"چاپ سرصفحه صفحه",
  Header_Label:"برچسب هدر",
  Header_Page_Index:"شاخص صفحه سرصفحه",
  Header_Font_Name:"فونت سرصفحه",
  Select_Font:"انتخاب قلم",
  Font_Selector:"انتخاب قلم",
  Header_Font_Size:"فونت سرصفحه",
  Header_Bold:"هدر پررنگ",
  Header_Italic:"هدر کج",
  Header_Alignment:"تراز هدر",
  Left:"ترک کرد",
  Center:"مرکز",
  Right:"درست",
  Justified:"تعدیل شده",
  Header_Font_Color:"رنگ هدر",
  Select_Color:"انتخاب رنگ",
  Footer:"پانویس",
  Print_A_Page_Footer:"چاپ پاورقی صفحه",
  Footer_Label:"برچسب پاورقی",
  Footer_Page_Index:"فهرست پاورقی",
  Footer_Font_Name:"فونت پاورقی",
  Fonts:"فونت",
  Done:"انجام شده",
  Select_Fonts:"انتخاب فونت",
  Footer_Font_Size:"اندازه پاورقی",
  Footer_Bold:"پانویس پررنگ",
  Footer_Italic:"پایینی کج",
  Footer_Alignment:"تراز پاورقی",
  Footer_Font_Color:"رنگ پاورقی",
  Page_Copies:"کپی های صفحه",
  Enable_Printer:"فعال کردن چاپگر",
  Remote_Logging: " ثبت از راه دور ",
  Log_Server: "Log Server",
  Encryption: "رمزگذاری",
  Random_Key: "کلید تصادفی",
  Encryption_Key: "کلید رمزگذاری",
  Cheques_Webserver: "پایگاه داده سفارشی",
  Learn_How: "یاد بگیرند که چگونه",
  Signature: "امضا",
  Default_Printer_Unit: "in/cm/mm/(pt)",
  View_Signature: "مشاهده امضا",
  Printed_Signature: "امضای چاپی",
  Digitally_Sign: "نشانه دیجیتال",
  Digital_Signature: "امضای دیجیتالی",
  Add_Signature: "افزودن امضا",
  Add_Your_Signature: "امضای خود را اضافه کنید",
  Enable_Signature: "فعال کردن امضا",
  Digitally_Signed: "امضای دیجیتال",
  Insert_Error: "به دلیل مشکلات پایگاه داده، چک ذخیره نمی شود.",
  Delete_Confirmation:"آیا مطمئن هستید که می خواهید این اطلاعات را حذف کنید؟",
  Discard_Confirmation:"آیا مطمئن هستید که می خواهید این اطلاعات را نادیده بگیرید؟",
  Discard_Bank_Confirmation:"آیا مطمئن هستید که می خواهید این حساب را رد کنید؟",
  Discard_Printer_Confirmation:"آیا مطمئن هستید که می خواهید این چاپگر را دور بیندازید؟",
  Delete_Bonus_Confirmation:"آیا مطمئن هستید که می خواهید این جایزه را حذف کنید؟",
  Delete_Invoice_Confirmation:"آیا مطمئن هستید که می خواهید این فاکتور را حذف کنید؟",
  Generated_Cheque: "چک تولید شده",
  Generated_Invoice: "فاکتور تولید شده",
  Schedule_Start_Time: "شروع برنامه",
  Schedule_End_Time: "پایان برنامه",
  New_Call: "تماس جدید",
  No_Contacts: "بدون مخاطب",
  No_Contacts_Word: "مدیران، ناظران، کارمندان و دریافت کنندگان پرداخت به عنوان مخاطب ظاهر می شوند.",
  PDF_Subject: "امور مالی",
  PDF_Keywords: "چک های پی دی اف چک حقوق و دستمزد",
  Printer_Setup: "راهاندازی چاپگر",
  Printer_Selection: "انتخاب چاپگر",
  New_Fax: "فکس جدید",
  New_Fax_Message: "پیام فکس جدید",
  Fax_Machine: "دستگاه فکس",
  Fax_Name: "نام فکس",
  Fax_Email: "ایمیل فکس",
  Fax_Number: "شماره فکس",
  Contents: "فهرست",
  Fax_Body: "بدنه صفحه",
  Header_Word: "سرتیتر:",
  Header_Text: "متن سرصفحه",
  Include_Header: "شامل سربرگ",
  Include_Footer: "شامل پاورقی",
  Footer_Word: "پانویس:",
  Footer_Text: "متن پاورقی",
  Attach_a_Cheque: "ضمیمه چک",
  Add_Deduction: "افزودن کسر",
  Enable_Fax: "ارسال فکس",
  Select_Fax: "انتخاب فکس",
  No_Faxes: "بدون فکس",
  Faxes: "فاکس",
  Save_and_Send: "ارسال فکس",
  Save_and_Pay: "پس انداز و پرداخت",
  WARNING: "هشدار:",
  Remember: "یاد آوردن",
  Printing: "چاپ",
  Printing_Complete: "چاپ کامل شد!",
  of: "از",
  There_Were: "وجود داشت ",
  Successful_Prints: "چاپ های موفق و ",
  Unsuccessful_Prints: "چاپ ناموفق.",
  PrinterError: "با عرض پوزش، خطا وجود دارد.",
  Printing_: "چاپ...",
  PrinterSuccess: "سند با موفقیت چاپ شد.",
  PrintersSuccess: "اسناد با موفقیت چاپ شد.",
  Print_Cheques: "چاپ چک",
  New_Message: "پیام جدید",
  New_Messages: "پیام جدید",
  Read_Message: "خواندن پیام",
  Write_Message: "پیام بنویس",
  Send_Message: "پیام فرستادن",
  Subject: "موضوع",
  Message: "پیام",
  Writing: "نوشتن...",
  Send: "ارسال",
  Set_Date: "تعیین تاریخ",
  Set_Time: "مدت زمان معینی",
  Recieve: "دریافت",
  Set_as_Default: "تنظیم به عنوان پیشفرض",
  Default_Account: "حساب پیش فرض",
  Default_Design: "طراحی پیش فرض",
  Multiple_Cheques: "چک ها (سه گانه)",
  Account_Mode: "حالت حساب",
  Multiple_Cheques_Description: "سه چک در هر صفحه.",
  Check_and_Table: "بررسی و جدول",
  Check_including_Table: "جدول چک و حسابداری.",
  Check_Mailer: "بررسی ایمیل",
  Check_Mailer_Window: "با یک پنجره آدرس بررسی کنید.",
  DocuGard_Table_Top: "بررسی و جدول DocuGard (بالا)",
  DocuGard_Table_Middle:"بررسی و جدول DocuGard (وسط)",
  DocuGard_Table_Bottom:"بررسی و جدول DocuGard (پایین)",
  DocuGard_Mailer_Top:"DocuGard بررسی ایمیل (بالا)",
  DocuGard_Mailer_Middle:"DocuGard بررسی ایمیل (وسط)",
  DocuGard_Mailer_Bottom:"DocuGard بررسی ایمیل (پایین)",
  DocuGard_Three_Cheques:"چک های DocuGard (سه گانه)",
  DocuGard_Cheque_Top:"بررسی DocuGard (بالا)",
  DocuGard_Cheque_Middle:"بررسی DocuGard (وسط)",
  DocuGard_Cheque_Bottom:"بررسی DocuGard (پایین)",
  DocuGard_Three_Cheques_Window:"سه چک در یک صفحه.",
  DocuGard_Table_Top_Window: "جدول چک و درآمد.",
  DocuGard_Table_Middle_Window: "جدول چک و درآمد.",
  DocuGard_Table_Bottom_Window: "جدول چک و درآمد.",
  DocuGard_Mailer_Top_Window: "بررسی، جدول، و آدرس.",
  DocuGard_Mailer_Middle_Window: "بررسی، جدول، و آدرس.",
  DocuGard_Mailer_Bottom_Window: "بررسی، جدول، و آدرس.",
  DocuGard_Cheque_Top_Window: "کاغذ ایمن را بررسی کنید.",
  DocuGard_Cheque_Middle_Window: "کاغذ ایمن را بررسی کنید.",
  DocuGard_Cheque_Bottom_Window: "کاغذ ایمن را بررسی کنید.",
  Basic_Cheque: "بررسی (بالا)",
  Basic_Cheque_Print: "چاپ یک چک",
  
  Error_Setting_As_Paid: "خطا در تنظیم به عنوان پولی",
  Add_Attachment: "پیوست را اضافه کنید",
  PrinterUnavailable: "چاپگر در دسترس نیست",
  CreditCardComponent: "کارت",
  PaypalComponent:"پی پال",
  InteracComponent:"اینتراک",
  BitcoinComponent:"بیت کوین",
  New_Deposit:"سپرده جدید",
  Deposits:"سپرده",
  No_Deposits:"بدون سپرده",
  Credit_Card_Deposit:"کارت اعتباری",
  New_Credit_Card_Deposit_Message:"سپرده کارت اعتباری",
  New_BitCoin_Deposit:"بیت کوین",
  New_BitCoin_Deposit_Message:"سپرده بیت کوین",
  New_Interac_Deposit:"اینتراک",
  New_Interac_Deposit_Message:"انتقال اینتراک",
  Payments_Limit: "محدودیت پرداخت",
  No_Payment_Limit: "بدون محدودیت پرداخت",
  PayPal_Deposit: "پی پال",
  PayPal_Deposit_Message:"سپرده پی پال",
  No_Deposits_Word:"اولین شرکت <a routerLink='/folder/Deposit/New'>سپرده</a> را اضافه کنید.",
  No_Documents_Specified: "هیچ سندی برای چاپ مشخص نشده است",
  No_Printers_Added: "هیچ چاپگری یافت نشد. برای افزودن یک چاپگر به تنظیمات > چاپگرها بروید.",
  DB_Erase_Prompt: "کل پایگاه داده را به طور کامل پاک کنید؟ هشدار: تمام اطلاعات ذخیره شده را از دست خواهید داد!",
  Console_Warning: "هیچ متنی را در این کنسول قرار ندهید. ممکن است خود و/یا شرکتتان را در معرض خطر جدی قرار دهید.",
  No_Faxes_Word: "اولین <a routerLink='/folder/Fax/New'>فکس</a> را ایجاد کنید.",
  Cheque_Delete_Confirmation: "آیا مطمئن هستید که می خواهید این چک را حذف کنید؟",
  Design_Delete_Confirmation:"آیا مطمئن هستید که می خواهید این طرح را حذف کنید؟",
  Cheque_Pay_Confirmation:"این چک را به عنوان پرداخت شده علامت گذاری کنید؟ در صف چاپ ظاهر نمی شود.",
  Payment_Pay_Confirmation:"این پرداخت را به عنوان پرداخت شده علامت بزنید؟ در صف چک ظاهر نمی شود.",
  Delete_Deduction_Confirmation:"آیا مطمئن هستید که می خواهید این کسر را حذف کنید؟",
  Delete_Job_Confirmation:"آیا مطمئن هستید که می خواهید این کار را حذف کنید؟",
  Delete_Timesheet_Confirmation:"آیا مطمئن هستید که می خواهید این جدول زمانی را حذف کنید؟",
  Delete_Schedule_Confirmation:"آیا مطمئن هستید که می خواهید این برنامه زمان بندی را حذف کنید؟",
  Delete_Setting_Confirmation:"آیا مطمئن هستید که می خواهید این تنظیم را بازنشانی کنید؟",
  Delete_Fax_Confirmation:"آیا مطمئن هستید که می خواهید این فکس را حذف کنید؟",
  Delete_File_Confirmation:"آیا مطمئن هستید که می خواهید این فایل را حذف کنید؟",
  Delete_Vacation_Confirmation:"آیا مطمئن هستید که می خواهید این تعطیلات را حذف کنید؟",
  Delete_Printer_Confirmation:"آیا مطمئن هستید که می خواهید این چاپگر را حذف کنید؟",
  Remove_Design_Confirmation:"آیا مطمئن هستید که می خواهید این طرح را حذف کنید؟",
  Delete_Payroll_Confirmation:"آیا مطمئن هستید که می خواهید این لیست حقوق و دستمزد را حذف کنید؟",
  Send_Fax_Email_Confirmation:"آیا می خواهید این اسناد را فکس و ایمیل کنید؟",
  Send_Email_Confirmation:"آیا می خواهید این سند را ایمیل کنید؟",
  Send_Fax_Confirmation:"آیا می خواهید این سند را فکس کنید؟",
  Error_Generating_PDF: "با عرض پوزش، خطایی در ایجاد این سند رخ داد.",
  PDF_Error_Saving_Image: "با عرض پوزش. هنگام ذخیره تصویر PDF این سند خطایی روی داد.",
  Test_Printer_Confirmation: "آیا می خواهید یک صفحه آزمایشی روی این چاپگر چاپ کنید؟",
  Save_Timesheet_Prompt:"لطفا یک \"عنوان\" اضافه کنید یا \"شروع تایمر\" را فشار دهید تا ذخیره شود.",
  Remove_Geofence_Prompt: "آیا مطمئن هستید که می خواهید مکان این حصار جغرافیایی را بردارید؟",
  Delete_Employee_Confirmation: "آیا مطمئنید که میخواهید حذف کنید ",
  Fire_Employee_Confirmation:"آیا مطمئن هستید که می خواهید شلیک کنید",
}