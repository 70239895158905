export const Mt = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Copyright & kopja; 2023",
  black:"Iswed",
  green:"Aħdar",
  gold:"Deheb",
  blue:"Blu",
  brown:"Kannella",
  purple:"Vjola",
  pink:"Roża",
  red:"aħmar",
  Swatches:"Kampjuni",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Lewn",
  Saturation:"Saturazzjoni",
  Lightness:"Ħeffa",
  Upgrade_To_Pro:"Aġġorna għal Pro",
  AllFeaturesInclude:"Il-karatteristiċi kollha jinkludu:",
  PrintUnlimitedCheques:"Stampa Ċekkijiet Illimitati",
  PremiumChequeDesigns:"Disinni tal-Iċċekkjar Premium",
  ManageUnlimitedEmployees:"Immaniġġja Impjegati Unlimited",
  AddUnlimitedPayees:"Żid Pagaturi Illimitati",
  CreateUnlimitedPayrolls:"Oħloq Pagi Illimitati",
  UnlimitedSchedulesandTimeSheets:"Skedi u Time Sheets bla limitu",
  BulkPayPalPayouts:"Ħlasijiet PayPal bl-ingrossa",
  UnlimitedBankAccounts:"Kontijiet Bankarji Illimitati",
  ManageMultipleCompanies:"Immaniġġja Kumpaniji Multipli",
  TrackInsurancePolicies:"Politiki tal-Assigurazzjoni tat-Track",
  Bio_MetricProtection:"Protezzjoni Bijometrika",
  Geo_FenceLock_OutProtection:"Protezzjoni tal-Lock-Out tal-Ġeo-Fence",
  Multiple_Companies_Word:"Il-ġestjoni ta' kumpaniji multipli mhix disponibbli mingħajr premium ta' Ċekkijiet.",
  PayPal_Payouts_Word:"Pagamenti PayPal huma diżattivati mingħajr premium Ċekkijiet.",
  PINProtection:"Protezzjoni PIN",
  PasswordProtection:"Protezzjoni tal-Password",
  May_Require_Approval:"Jistgħu jeħtieġu approvazzjoni.",
  Subscribe:"Abbona",
  Billed:"Billed",
  Up:"Up",
  Down:"Down",
  Positioning:"Pożizzjonament",
  Marker:"Marker",
  Drag_Marker:"Drag Marker",
  Tagline:"Stampa Ċekkijiet u Tabula l-Pagi",
  Marker_Word:"Uża l-Markers għad-daqs tal-element.",
  Pinch_Zoom:"Niskata Zoom",
  Pinch_Word:"Oqros biex tkabbar l-element.",
  Drag:"Iddreggja",
  Drag_Word:"Uża subgħajk biex tkaxkar l-elementi.",
  subscription_alias_word:"Abbonament li Tiġġedded Awtomatikament",
  premium_alias_word:"Pakkett ta 'Aġġornament ta' One-Time",
  print_alias_word:"Print-Out Kontrolli Individwali",
  mode_alias_word:"Modalità",
  Pro:"Pro",
  Pro_word:"Verżjoni Pro meħtieġa.",
  Cant_Delete_Default_Company_Word:"Jiddispjacini, ma tistax tħassar il-kumpanija default tiegħek.",
  Reinsert_Default_Designs:"Erġa' Daħħal Disinni Default",
  Reinsert_Default_Designs_word:"Trid terġa' ddaħħal id-disinji default?",
  Subscription_Active_Disable_Word:"Dan l-abbonament huwa attiv. Tixtieq tikkanċella dan l-abbonament għal Ċekkijiet?",
  Company_Logo:"Logo tal-Kumpanija",
  ZERO_:"ŻERO",
  Disclaimer:"Ċaħda",
  Privacy_Policy:"Regoli tal-privatezza",
  EULA:"Iċċekkja l-EULA",
  Terms_Of_Service:"Termini tas-Servizz",
  Terms_Of_Use:"Termini ta 'Użu",
  Refunds:"Politika ta' Rifużjoni",
  Single_Print:"1 Iċċekkja",
  Two_Prints:"2 Kontrolli",
  Five_Prints:"5 Kontrolli",
  Ten_Prints:"10 Kontrolli",
  Fifteen_Prints:"15 Kontrolli",
  Twenty_Prints:"20 Kontrolli",
  Thirty_Prints:"30 Kontrolli",
  Image_Added:"Immaġni Miżjuda",
  Image_Preview:"Preview tal-Immaġini",
  No_Image_Was_Selected:"Ma ntgħażlet l-ebda immaġini.",
  Cheques_Unlimited:"Ċekkijiet Illimitati",
  _Subscription:"Abbonament",
  Subscription:"Kontrolli - Xahar 1",
  Two_Month_Subscription:"Kontrolli - Xahrejn",
  Three_Month_Subscription:"Kontrolli - 3 Xhur",
  Six_Month_Subscription:"Kontrolli - 6 Xhur",
  Twelve_Month_Subscription:"Kontrolli - 12-il Xahar",
  Cheques_Are_Available:"Iċ-ċekkijiet huma disponibbli għall-istampar.",
  Upgrade_Required_Two:"Agħżel pakkett u tektek darbtejn fuq il-buttuna tal-prezz biex tibda x-xiri tiegħek. Stampa ċekkijiet b'kulur sħiħ li jħarsu professjonali f'sekondi.",
  Month:"Xahar",
  Due:"Dovuta:",
  Expires:"Tiskadi:",
  Subscription_Active:"Abbonament Attiv",
  Subscription_Inactive:"Abbonament Inattiv",
  Purchase_Additional_Cheques:"Tixtri ċekkijiet addizzjonali?",
  Printable_Cheque:"Iċċekkja Printable",
  Printable_Cheques:"Ċekkijiet Printable",
  Printable_Cheque_Word:"verifika hija disponibbli fuq il-kont tiegħek.",
  Printable_Cheques_Word:"ċekkijiet huma disponibbli fuq il-kont tiegħek.",
  Max_Amount_Message:"L-ammont li speċifikajt laħaq fuq l-ammont massimu stabbilit għal din is-sistema:",
  Terms_Required_Word:"Int trid taqbel ma' dan il-ftehim qabel ma tkompli tuża Ċekkijiet.",
  Subscriptions:"Abbonamenti",
  Product_Upgrades:"Titjib",
  Mailed_Out_Cheques:"Ċekkijiet Mibgħuta 'l barra",
  Enter_A_Company_Name:"Jekk jogħġbok daħħal isem tal-kumpanija.",
  Single_Cheques:"Kontrolli Uniċi",
  Cheque_Golden:"Iċċekkja tad-Deheb",
  Cheque_Golden_Window:"Disinn tal-kontroll tad-deheb.",
  Cheque_Green:"Green Check",
  Cheque_Green_Window:"Disinn tal-kontroll aħdar.",
  Cheque_Red:"Iċċekkja Aħmar",
  Cheque_Red_Window:"Disinn tal-kontroll aħmar.",
  Cheque_Yellow:"Iċċekkja Isfar",
  Cheque_Yellow_Window:"Disinn tal-kontroll isfar.",
  Cheque_Statue_of_Liberty:"Statwa tal-libertà",
  Cheque_Statue_of_Liberty_Window:"Id-disinn tal-kontroll tal-Istatwa tal-Libertà.",
  Cheque_Green_Wave:"Mewġa ħadra",
  Cheque_Green_Wave_Window:"Disinn tal-kontroll aħdar.",
  Cheque_Golden_Weave:"Nisġa tad-Deheb",
  Cheque_Golden_Weave_Window:"Disinn eleganti tal-kontroll tad-deheb.",
  Cheque_Green_Sun:"Ħad Ħadra",
  Cheque_Green_Sun_Window:"Disinn tal-kontroll fil-fond u paċifikazzjoni.",
  Cheque_Blue_Checkers:"Blue Checkers",
  Cheque_Blue_Checkers_Window:"Disinn tal-kontroll blu.",
  Cashiers_Check:"Ċekk tal-Kaxxier",
  Cashiers_Check_Window:"Mudell tal-istil tal-Check tal-Kaxxier.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Aqua check disinn.",
  Cheque_Golden_Checkers:"Checkers tad-Deheb",
  Cheque_Golden_Checkers_Window:"Disinn tal-kontroll tad-deheb.",
  Upgrade_Unavailable:"Titjib Mhux Disponibbli",
  Bank_Code_Protection:"Protezzjoni tan-Numru tal-Bank",
  Bank_Code_Protection_Word:"Ipproteġi n-numri tal-bank tiegħek milli jintużaw fuq din l-app taħdem fuq apparat ieħor jew għal utent ieħor. Din l-azzjoni hija IRREVERSIBBLI. Kompli?",
  Bank_Code_Protection_Blocked_Word:"In-numri tal-bank li qed tipprova tuża huma riżervati għal utent jew apparat ieħor.",
  Bank_Code_Protection_Error_Word:"Il-verifika tan-numri falliet. Jekk jogħġbok qabbad mal-internet u erġa' pprova żid dan il-kont bankarju.",
  Bank_Code_Protection_Set_Error_Word:"Il-protezzjoni tan-numru tal-bank teħtieġ li tkun konness mal-internet. Jekk jogħġbok qabbad u erġa' pprova.",
  Upgrade_Unavailable_Word:"Jiddispjacini, qed ikollna problemi biex nivverifikawk. L-abbonamenti u l-aġġornamenti għal Ċekkijiet bħalissa mhumiex disponibbli għax-xiri fiż-żona tiegħek.",
  PayPal_Payment_Preview:"Previżjoni tal-Ħlas PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Agħżel PayPal",
  PayPal_Applications:"Applikazzjonijiet PayPal",
  Purchase_With_Apple_Pay:"Xiri bl-Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Kumpaniji",
  Insurance:"Assigurazzjoni",
  New_PayPal:"PayPal ġdid",
  Pay_By:"Pay By",
  Insure:"Assigura",
  Miles:"Miles",
  Payment_Method:"Metodu ta 'pagament",
  Select_Policies:"Agħżel Politiki",
  Policies:"Politiki",
  Policy:"Politika",
  No_PayPal_Account:"Ebda Kont PayPal",
  No_Policies:"Ebda Politiki tal-Assigurazzjoni",
  New_Policy:"Politika Ġdida",
  PayPal_Payment:"Ħlas PayPal",
  PayPal_Payments:"Pagamenti PayPal",
  No_Payment_Selected:"Ebda Ħlas Magħżul",
  Sending_Payment_Word:"Jekk jogħġbok stenna... Dan il-ħlas qed jintbagħat.",
  Sending_Payments_Word:"Jekk jogħġbok stenna... Qed jintbagħtu ħlasijiet.",
  No_Payment_Selected_PayPal:"L-ebda <a routerLink='/folder/Payments'>pagament PayPal</a> magħżul biex jintbagħat.",
  Payment_Sent:"Ħlas Mibgħut",
  PayPal_Payment_Sent:"Dan il-ħlas intbagħat ma' PayPal.",
  Copay:"Copay",
  Dead:"Mejjet",
  Alive:"Ħaj",
  Not_Dead:"Mhux Mejjet",
  Unclaimed:"Mhux mitluba",
  Attempted:"Attentat",
  Deceased:"Mejjet",
  Claimed:"Prelevat",
  Unpaid:"Mhux imħallas",
  Sending_Payment:"Jibgħat il-Ħlas",
  Sending_Payments:"Tibgħat Ħlasijiet",
  Send_PayPal_Confirmation:"Trid tibgħat din it-tranżazzjoni ma' PayPal?",
  Send_PayPal_Confirmation_Word:"Agħfas il-buttuna ħadra biex tibgħat din it-tranżazzjoni.",
  Save_Payment_As_Unpaid:"Issejvja dan il-ħlas bħala mhux imħallas?",
  Payment_Pay_Confirmation_PayPal:"Issejvja dan il-ħlas bħala mħallas?",
  No_Policies_Word:"Żid l-ewwel <a routerLink='/folder/Postage/New'>Polza ta' Assigurazzjoni</a> issa.",
  Timesheet_Multiple_Delete_Confirmation:"Int żgur li trid tħassar diversi timesheets?",
  Select_Multiple_Timesheets_Prompt:"Ebda timesheets magħżula. Agħżel mill-inqas timesheet waħda.",
  Select_Multiple_Policies_Prompt:"Ebda politika magħżula. Agħżel mill-inqas polza ta' assigurazzjoni waħda.",
  Policies_Multiple_Delete_Confirmation:"Int żgur li trid tħassar politiki multipli?",
  Company:"Kumpanija",
  Add_Company:"Żid Kumpanija",
  New_Company:"Żid Kumpanija",
  No_Companies:"L-ebda Kumpaniji",
  Enable_Company:"Ippermetti Kumpanija",
  Select_Company:"Agħżel Kumpanija",
  The_Default_Company:"It-tikketta default tal-kumpanija.",
  Manage_Companies:"Immaniġġja Kumpaniji",
  No_Companies_Word:"Iċċekkijiet se jużaw kumpanija default.<br />Żid l- <a routerLink='/folder/Company/New'>l-ewwel Kumpanija</a>.",
  Default_Company:"Kumpanija Default",
  Cheques_Unlimited_Word:"Checks Unlimited jippermettilek li tipprintja kemm ċekkijiet trid.",
  Cheques_Subscription_Word:"Abbonament Ċekk jippermettilek tipprintja ċekkijiet kemm tixtieq.",
  You_Own_This_Product:"Inti stess dan il-prodott.",
  Your_Subscription_is_Active:"L-abbonament tiegħek huwa attiv.",
  Active_Products:"Prodotti Attivati",
  Purchase_Confirmation:"Xiri",
  Purchase_Cheques:"Ċekkijiet tax-Xiri",
  Restore_Purchase:"Irrestawra Xiri",
  Erase_Purchase:"Ħassar Xiri",
  Successfully_Purchased:"Inxtrat b'suċċess",
  Enter_Your_Licence_Key:"Jekk jogħġbok daħħal iċ-ċavetta tal-liċenzja tiegħek fuq din il-paġna biex tattiva dan il-prodott.",
  Licence_Key:"Ċavetta tal-Liċenzja",
  Enter_Licence_Key:"Daħħal License Key",
  Purchased:"Mixtrija",
  Enable_Feature:"Ippermetti Karatteristika",
  Cancel_Subscription:"Ikkanċella l-Abbonament",
  Subscription_Removed:"Abbonament Imneħħi",
  Select_Active_Subscription:"Agħżel abbonament attiv sabiex timmodifikah.",
  Remove_Subscription_Word:"Int żgur li trid tikkanċella dan l-abbonament?",
  Delete_Company_Confirmation:"Int żgur li trid tħassar din il-kumpanija kollha? TWISSIJA: Inti titlef l-informazzjoni kollha maħżuna!",
  Delete_Default_Company_Word:"Ma tistax tħassar il-kumpanija default. Tixtieq tirrisettja l-applikazzjoni u tirrestawraha għall-istat default? TWISSIJA: Inti titlef l-informazzjoni kollha maħżuna!",
  Console_Warning_2:"M'għandekx timmaniġġja l-ebda munita billi tuża din l-applikazzjoni li bħalissa mhix tiegħek.",
  Terms_and_Conditions:"Termini u Kundizzjonijiet",
  and_the:"u il",
  for:"għal",
  Please_Read_Word:"Jekk jogħġbok aqra u taqbel mal-",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Xi rati ta' konverżjoni tal-munita ma setgħux jinstabu. Jekk jogħġbok qabbad mal-internet.",
  Free:"B'xejn",
  DB_Erase_Prompt_2:"Tħassar kompletament id-database tal-iżviluppatur kollu? TWISSIJA: Inti titlef l-informazzjoni kollha tax-XIRI u l-ABBONAMENT!",
  Successfully_Imported:"Importati b'suċċess",
  Select_Postage:"Agħżel Pustaġġ",
  No_Postage:"Ebda Bolol",
  No_Paid_Postage_Word:"Żid l-ewwel timbru <a routerLink='/folder/Postage/New'>Posta Mħallsa</a>.",
  Trial_Complete:'Prova Tlesta',
  Please_Upgrade:'Jekk jogħġbok aġġorna l-Checks biex tkompli tipprintja.',
  Aa:"Aa",
  Color:"Kulur",
  Font:"Font",
  Guide:"Gwida",
  Guides:"Gwidi",
  Image:"Immaġni",
  Zoom:"Zoom",
  Logo:"Logo",
  Bank:"Bank",
  MICR:"MICR",
  Total:"Total",
  Cancel:"Ikkanċella",
  Confirm:"Ikkonferma",
  Method:"Metodu",
  Biometric_Security:"Sigurtà bijometrika",
  Please_Login_To_Continue:"Jekk jogħġbok illoginja biex tkompli.",
  Complete:"Imla",
  Sign_Up:"Irregistra",
  Error:"Żball",
  Biometrics:"Bijometrika",
  Percent:"Perċentwali",
  Zero_Percent:"0%",
  Top_Margin:"Marġni ta 'fuq",
  Bottom_Margin:"Marġni tal-qiegħ",
  Left_Margin:"Marġni tax-Xellug",
  Right_Margin:"Marġni Dritt",
  MICR_Margin:"Marġni MICR",
  Table_Margin:"Marġni tal-Tabella",
  Address_Margin:"Marġni ta' l-Indirizz",
  Percentage_:"Persentaġġ",
  Vacation_Title:"Titolu vaganza",
  Use_PIN:"Uża PIN",
  Loading__:"Tagħbija...",
  Design_Title:"Titolu tad-Disinn",
  Authorize:"Awtorizza",
  Key:"Ewlenin",
  Public_Key:"Ċavetta Pubblika",
  Private_Key:"Ċavetta Privata",
  Authenticate:"Awtentika",
  Last_Payroll:"L-aħħar Payroll",
  Last_Calculation:"L-aħħar Kalkolu",
  Authorized:"Awtorizzat",
  Geo_Authorized:"Ġeo-Lokazzjoni: Awtorizzat",
  Not_Authorized:"Mhux Awtorizzat",
  Authorization_Complete:"Awtorizzazzjoni Tlesta",
  Geolocation_Authorization:"Awtorizzazzjoni ta' ġeo-lokazzjoni",
  Out_of_Bounds:"Out of Bounds",
  Cant_Delete_Default_Design:"Ma tistax tħassar disinn default.",
  Unauthorized_Out_of_Bounds:"Mhux awtorizzat: Barra mil-limiti",
  Biometric_Authorization:"Awtorizzazzjoni bijometrika",
  Locating_Please_Wait:"Issib, jekk jogħġbok Stenna...",
  Test_Biometrics:"Bijometrika tat-test",
  Cheque_Margins:"Iċċekkja l-Marġini",
  Percentage_Full_Error:"Il-qasam tal-perċentwali ma jistax jiġi ssettjat fuq 100% fil-mija.",
  No_Payroll_Text:"Żid <a routerLink='/folder/Employee/New'>Impjegat</a> jew <an routerLink='/folder/Payee/New'>Payee</a> u <a routerLink='/folder/Schedule/New'>Skeda</a>.",
  Design_Saved:"Disinn Issejvjat",
  Default_Design_Selected:"Disinn Default Magħżul",
  Partial_Import:"Importazzjoni Parzjali",
  Partial_Export:"Esportazzjoni Parzjali",
  Entire_Import:"Importazzjoni Sħiħa",
  Entire_Export:"Esportazzjoni Sħiħa",
  Existing_Password:"Jekk jogħġbok daħħal il-password eżistenti tiegħek:",
  Existing_PIN:"Jekk jogħġbok daħħal il-kodiċi PIN eżistenti tiegħek:",
  Pin_Change_Header:"Konferma tal-PIN",
  Pin_Change_SubHeader:"Daħħal in-numru PIN antik tiegħek biex tikkonferma l-bidla.",
  Pass_Change_Header:"Konferma tal-Password",
  Pass_Change_SubHeader:"Daħħal il-password l-antika tiegħek biex tikkonferma l-bidla.",
  PIN_Enter_Message:"Daħħal il-PIN tiegħek biex tikkonferma.",
  Password_Enter_Message:"Daħħal il-password tiegħek biex tikkonferma.",
  Pin_Updated_Success:"PIN aġġornat b'suċċess!",
  Pin_Updated_Fail:"PIN ma setax jiġi aġġornat.",
  Pass_Updated_Success:"Password aġġornata b'suċċess.",
  Pass_Updated_Fail:"Il-password ma setgħetx tiġi aġġornata.",
  Preview_Payment:"Ħlas minn qabel",
  Preview_Payroll:"Preview Payroll",
  No_Payments_Created:"L-ebda pagament ma nstab li nħolqot.",
  Payment_Preview:"Preview tal-Ħlas",
  Enable_Payee:"Jippermetti lil Min jitħallas",
  Rendered:"Mogħtija",
  No_Email:"Nru Email",
  Setup_Cheques:"Setup Kontrolli",
  name:"Isem",
  address:"Indirizz",
  address_2:"Linja ta' l-Indirizz 2",
  city:"Belt",
  province:"Provinċja",
  postal_code:"Kodiċi Postali/Zip",
  country:"Pajjiż",
  username:"Isem tal-utent",
  full_name:"Isem sħiħ",
  birthday:"Birthday",
  email:"Email",
  phone:"Telefon",
  employees:"Impjegati",
  addresses:"Indirizzi",
  payment_amount_limit:"Limitu ta' Ammont ta' Ħlas",
  total_limit:"Limitu Totali",
  payees:"Min jitħallas",
  description:"Deskrizzjoni",
  address_line_one:"Linja ta' l-Indirizz Wieħed",
  address_line_two:"Linja ta' l-Indirizz Tnejn",
  image:"Immaġni",
  account_holder:"Detentur tal-Kont",
  cheque_starting_id:"Iċċekkja l-ID tal-Bidu",
  transit_number:"Numru tat-Tranżitu",
  institution_number:"Numru tal-Istituzzjoni",
  designation_number:"Numru ta' Denominazzjoni",
  account_number:"Numru tal-kont",
  currency:"Munita",
  signature:"Firma",
  payment_payroll_limit:"Limitu ta' Ħlas",
  total_limi:"Limitu totali",
  date:"Data",
  printed_memo:"Memo stampat",
  banks:"Banek",
  signature_image:"Immaġni tal-Firma",
  address_name:"Isem Indirizz",
  notes:"Noti",
  design:"Disinn",
  title:"Titolu",
  frequency:"Frekwenza",
  fax:"Fax",
  salaries:"Salarji",
  salary_ids:"IDs tas-salarju",
  start_time:"Ħin tal-Bidu",
  end_time:"Ħin tat-Tmiem",
  paid:"Imħallas",
  overtime_percentage:"Perċentwal imħallas",
  overtime_amount:"Ammont Fiss imħallas",
  first_name:"Isem",
  last_name:"Kunjom",
  moderation:"Moderazzjoni",
  create:"Oħloq",
  edit:"Editja",
  destroy:"Eqred",
  day_start_time:"Day_start_time",
  day_end_time:"Day_end_time",
  fullname:"Isem",
  time:"Ħin",
  auto_send:"Ibgħat awtomatikament",
  time_method:"Metodu tal-Ħin",
  schedules:"Skedi",
  indefinite_payroll_enabled:"Attiva Indefinittivament",
  amount:"Ammont",
  repeat:"Irrepeti",
  always_enabled:"Dejjem Ippermettiet",
  start_date:"Data tal-Bidu",
  end_date:"Data tat-Tmiem",
  Cheque_Total:"Iċċekkja Total",
  Total_Amount:"Ammont totali:",
  Amounts:"Ammonti:",
  Images:"Stampi",
  Files:"Fajls",
  Previewing:"Previewing:",
  Insert:"Daħħal",
  Preview_Import:"Preview Import",
  Entry:"Dħul",
  Entries:"Daħliet",
  No_Entries:"Ebda Daħliet",
  Import_Type:"Tip ta 'importazzjoni",
  Select_Details:"Agħżel Dettalji",
  Select_Payee:"Agħżel lil min jitħallas",
  Enable_Holidays:"Ippermetti Festi",
  Disable_Holidays:"Iddiżattiva Festi",
  Wire_Transfer:"Trasferiment tal-Wajer",
  New_Export:"Esportazzjoni Ġdida",
  Export_Data:"Esportazzjoni tad-Dejta",
  Export_Data_Word:"Agħżel it-tip ta 'fajl biex tesporta u tniżżel.",
  Export_File:"Fajl ta' Esportazzjoni",
  Mode:"Modalità",
  Times:"Times",
  Widgets:"Widgets",
  Slider:"Slider",
  Set_Details:"Issettja Dettalji",
  Select_Type:"Agħżel Tip",
  Display_Slider:"Display Slider",
  Dashboard_Slider:"Dashboard Slider",
  Dashboard_Mode:"Modalità Dashboard",
  Show_Intro:"Uri l-Intro",
  Show_Payrolls:"Uri l-Pagi",
  Show_Holidays:"Uri Festi",
  Show_Invoices:"Uri Fatturi",
  Show_Cheques:"Uri Kontrolli",
  Enabled_Widgets:"Widgets attivati",
  Disabled_Widgets:"Widgets b'Diżabilità",
  Colors:"Kuluri",
  Barcodes:"Barcodes",
  View_Timers:"Ara Timers",
  Gradients:"Gradjenti",
  No_Info:"Ebda Informazzjoni",
  Disable:"Itfi",
  Show_Layer:"Uri Saffi",
  Hide_Layer:"Aħbi Saffi",
  Text_Layer:"Saffi tat-Test",
  Secondly:"It-tieni",
  Minutely:"Minuta",
  nine_am:"9:00 AM",
  five_pm:"5:00 PM",
  Enable_Address:"Ippermetti l-Indirizz",
  Invalid_File_Type:"Jiddispjacini, intgħażel tip ta' fajl invalidu. Tip ta' fajl appoġġjat:",
  Error_Updating_Entry:"Jiddispjacini, kien hemm żball fl-aġġornament ta' din l-entrata.",
  Schedule_Timing_Alert:"Żball: Il-ħin tal-bidu tal-iskeda huwa ssettjat għal valur wara l-ħin tat-tmiem.",
  Select_Multiple_Payments_Prompt:"Ebda pagamenti magħżula. Agħżel mill-inqas ħlas wieħed.",
  Select_Multiple_Cheques_Prompt:"Ebda kontrolli magħżula. Jekk jogħġbok agħżel mill-inqas ċekk wieħed.",
  Select_Multiple_Items_Prompt:"Ebda oġġetti magħżula. Jekk jogħġbok agħżel mill-inqas oġġett wieħed.",
  Paymemt_Multiple_Delete_Confirmation:"Int żgur li trid tħassar pagamenti multipli?",
  Cheque_Multiple_Delete_Confirmation:"Int żgur li trid tħassar kontrolli multipli?",
  Payee_Multiple_Delete_Confirmation:"Int żgur li trid tħassar lil benefiċjarji multipli?",
  Employee_Multiple_Delete_Confirmation:"Int żgur li trid tħassar impjegati multipli?",
  MICR_Warning:"Nota: Xi printers u apparati jistgħu ma jurux it-tipa MICR b'mod korrett.",
  Automatically_Send:"Ibgħat awtomatikament",
  Type:"Tip",
  Payment_Type:"Tip ta' Ħlas",
  Auto_Send:"Ibgħat Awtomatiku",
  Automatically_Process:"Awtomatikament Ipproċessa",
  Auto_Process:"Proċess Awtomatiku",
  Image_Based:"Ibbażat fuq l-immaġni",
  Font_Based:"Ibbażat fuq font",
  Rerender:"Irrendi mill-ġdid",
  Rendering:"Rendering",
  Render:"Fajls",
  Top:"Fuq",
  Middle:"Nofsani",
  Bottom:"Qiegħ",
  Top_Left:"Fuq Xellug",
  Top_Center:"Fuq Ċentru",
  Top_Right:"Fuq Lemin",
  Middle_Left:"Xellug Nofsani",
  Middle_Center:"Ċentru Nofsani",
  Middle_Right:"Nofsani Dritt",
  Bottom_Left:"T'isfel Xellug",
  Bottom_Center:"Ċentru tal-qiegħ",
  Bottom_Right:"Tal-qiegħ Dritt",
  Numbers:"Numri",
  Verified:"Verifikat",
  Paper_Size:"Daqs tal-Karta",
  New_Device:"Apparat Ġdid",
  Add_Device:"Żid Apparat",
  Remove_Device:"Neħħi l-Apparat",
  Delete_Device:"Ħassar Apparat",
  Block_Device:"Imblokka Apparat",
  Block:"Blokk",
  Unblock:"Sblokka",
  Table:"Tabella",
  Scan_Login_Code:"Skennja Kodiċi Login",
  Login_Code:"Kodiċi Login",
  Scan_Code:"Scan Code",
  Scan_QR_Code:"Skennja l-Kodiċi QR",
  Select_All:"Agħżel kollox",
  Deselect_All:"Agħżel Kollha",
  Increase:"Żid",
  Decrease:"Tnaqqas",
  Bold:"Bold",
  Text:"Test",
  Style:"Stil",
  Italic:"Korsiv",
  QR_Code:"Kodiċi QR",
  Barcode:"Barcode",
  Browse_Images:"Fittex Stampi",
  Browse_Files:"Fittex Fajls",
  Background_Image:"Image Sfond",
  Logo_Image:"Immaġni tal-logo",
  Header_Image:"Header Image",
  Bank_Image:"Bank Image",
  Cut_Lines:"Linji maqtugħin",
  Background:"Sfond",
  License:"Liċenzja",
  One_License:"1 Liċenzja:",
  Licensed:"Liċenzjat għal:",
  Not_Licensed:"Mhux Liċenzjat",
  Enter_Serial:"Daħħal Serial",
  Serial_Key:"Ċavetta Serjali",
  Serial:"Serjali",
  Product_Key:"Ċavetta tal-Prodott",
  Check_Product_Key:"Iċċekkja Ċavetta tal-Prodott",
  Add_Product_Key:"Żid Ċavetta tal-Prodott",
  Verifying_Purchase:"Nivverifikaw ix-Xiri...",
  Print_Envelope:"Print Envelope",
  Envelope:"Pakkett",
  Thank_You:"Grazzi!",
  Scale:"Skala",
  Print_Scale:"Skala tal-Istampar",
  Borders:"Fruntieri",
  VOID:"VOID",
  Void_Cheque:"Void Check",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ĦLAS LILL-ORDNI TA’:",
  NO_DOLLARS:"L-EBDA DOLLARI ",
  ONE_DOLLAR:"DOLLAR WIEĦED",
  DOLLARS:" DOLLARI",
  AND:" U ",
  CENTS:" ĊENTEŻI.",
  NO_:"LE ",
  ONE_:"WAĦDA ",
  AND_NO_:"U LE ",
  _AND_ONE_:"U WIEĦED ",
  DOLLARS_AND_ONE_CENT:" U ĊENTSUM.",
  AND_NO_CENTS:" U ŻERO ĊENTEŻI.",
  CHEQUE_PRINT_DATE:"DATA:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Kompiti Inizjali",
  Inital_Setup:"Configurazzjoni inizjali",
  Welcome_To:"Merhba ",
  Welcome:"Merħba",
  Swipe:"Swipe",
  Intro_Setup:"Intro Setup",
  Introduction:"Introduzzjoni",
  CHEQUE_PRINT_CREDIT:"Mħaddma b'ċekkijiet",
  Title_Intro_Word:"Merħba għal Ċekki",
  Title_Intro:"Introduzzjoni taċ-ċekkijiet",
  Title_Setup:"Configurazzjoni taċ-ċekkijiet",
  PayPal_Default_Email_Message:"Int irċevejt trasferiment ġdid ta' PayPal. [Powered by Cheques]",
  Cheques_App_Export:"Esportat b'ċekkijiet",
  Post_Intro_Thanks:"Grazzi talli għażilt Ċekkijiet. Il-proċess tas-setup issa huwa lest.",
  Post_Intro_Word:"Ibda billi tipprintja l-ewwel ċekk tiegħek, żid ħlas fil-futur, jew żid impjegat mal-pagi.",
  Upgrade_Required:"Iċ-ċekkijiet jirrikjedu li jkollok verżjoni aktar premium tas-softwer sabiex taħbi dan il-messaġġ u tiftaħ karatteristiċi addizzjonali.",
  Upgrade_Title:"Ċekki",
  Mailout_Prompt:"Int tista' wkoll tagħżel li ċ-Ċekkijiet tibgħatlek iċ-ċekkijiet tal-pagi tiegħek.",
  Mailed_Cheque:"Iċċekkja Mibgħuta: ",
  Mailed_Cheque_Color:"Iċċekkja Mibgħuta (Kulur): ",
  Terms_Purchase:"Ix-xiri elettroniku kollu b'Ċekkijiet huwa rimborsabbli għal kollox sa 30 jum mid-data tax-xiri. Jekk jogħġbok aqra u taqbel mat-<a href='#'>Termini u Kundizzjonijiet</a> qabel tkompli.",
  Dashboard_Setup:"Issettja L-Istampatur Primarju",
  Dashboard_Add:"Żid Il-Kont Bankarju Primarju",
  Dashboard_Customize:"Agħżel Mudell ta' Ċekkjar",
  Dashboard_Job_Salary:"Oħloq ix-xogħol tiegħek u żid is-salarju tiegħek",
  Dashboard_Employees:"Traċċa Impjegati u Pagati",
  Dashboard_Print:"Ipprintja u Ibgħat il-Pagi tiegħek",
  Dashboard_Payroll:"Awtomatizza l-Istampar tal-Pagi Tiegħek",
  Dashboard_PayPal:"Imwaqqaf PayPal Payroll / Ħlasijiet",
  Begin_Setup:"Ibda Setup",
  Get_Started:"Ibda",
  Purchase:"Xiri",
  Lockdown:"Lockdown",
  Unlock:"Nisfruttaw",
  Detailed:"Dettaljat",
  Log_In:"Idħol",
  Login:"Idħol",
  Launch:"Tnedija",
  Register:"Irreġistra",
  Finish:"Temm",
  List:"Lista",
  Weekends:"Weekends",
  Weekly:"Kul ġimgħa",
  PayPal_Default_Subject:"Ħlas Ġdid",
  Payment_Message:"Messaġġ tal-Ħlas",
  PayPal_Default_Payment_Note:"Grazzi",
  Setup_Your_Cheqing_Account:"Kont Ċekkjar",
  Add_Your_Primary_Cheqing_Account:"Żid il-kont primarju taċ-cheqing tiegħek.",
  Welcome_Word:"Issimplifika u awtomat il-ġestjoni tal-pagi u tar-riżorsi umani.",
  Get_Started_Quickly:"Irrispondi ftit mistoqsijiet sempliċi li jgħinuna nibdew...",
  Done_Intro_Word:"Setup Imlesta",
  PIN_Protected:"Password & PIN Protetti",
  Enter_New_PIN:"Daħħal Kodiċi PIN ġdid:",
  Enter_PIN:"Daħħal Kodiċi PIN:",
  Invalid_PIN:"PIN invalidu ddaħħal.",
  Account_Identifier:"Identifikatur tal-Kont",
  New_Account_Identifier:"Identifikatur tal-Kont Ġdid",
  attempt:"tentattiv",
  attempts:"attentati",
  remaining:"fadal",
  Language:"Lingwa",
  languages:"Lingwi",
  select_languages:"Agħżel Lingwa",
  Deposit:"Depożitu",
  Hire_One_Now:"Kri Wieħed Issa",
  App_Locked:"L-applikazzjoni hija msakkra.",
  Skip_:"Aqbeż",
  Skip_to_Dashboard:"Aqbeż għad-Dashboard",
  Dashboard:"Dashboard",
  Import:"Importa",
  Admin:"Amministraturi",
  New_Admin:"Amministratur Ġdid",
  Settings:"Settings",
  Color_Picker:"Picker tal-Kulur",
  Font_Picker:"Font Picker",
  Logout:"Oħroġ",
  Close:"Agħlaq",
  Close_menu:"Agħlaq",
  Excel:"Fajl Excel",
  Csv:"CSV File",
  Sql:"SQL File",
  Json:"Fajl JSON",
  Url:"Importa bil-URL",
  Back:"Lura",
  Timers:"Tajmers",
  Cheque:"Iċċekkja",
  Print:"Istampa",
  Designs:"Disinni",
  Pause_Printing:"Pawża Stampar",
  Resume_Printing:"Ibda l-Istampar",
  Printing_Paused:"Istampar waqfa",
  PrintingUnavailable:"Skużani! L-istampar mhux disponibbli fuq din is-sistema.",
  Prints_Paused:"Prints waqfa",
  Administration:"Amministrazzjoni",
  Loading:"Tagħbija",
  Unnamed:"Mhux isem",
  error:"Jiddispjaċina, dan il-kontroll ma setax jinfetaħ għall-wiri.",
  No_Cheques_To_Print:"Ebda Kontrolli Biex Stampa",
  No_Cheques_To_Print_Word:"Oħloq <a routerLink='/folder/Cheque/New'>Ċekk Ġdid</a>.",
  New_Cheque:"Iċċekkja ġdida",
  Start_One_Now:"Ibda Wieħed Issa",
  Edit_Cheque:"Editja Iċċekkja",
  Select_Cheques:"Agħżel Ċekkijiet",
  Select_Employee:"Agħżel Impjegat",
  Default_Printer:"Printer Default",
  Reassign:"Assenja mill-ġdid",
  Configure:"Kkonfigura",
  Template:"Mudell",
  Designer:"Disinjatur",
  Edit_Designs:"Editja Disinni",
  New_Design:"Disinn Ġdid",
  Next:"Li jmiss",
  Save:"Issejvja",
  Name:"Isem",
  Mail:"Posta",
  Amount:"Ammont",
  Date:"Data",
  PayPal:"PayPal",
  Payouts:"Ħlasijiet",
  PayPal_Label:"Tikketta PayPal",
  Email_Username:"Email / Username",
  Client_ID:"ID tal-Klijent",
  Sandbox_Email:"Email Sandbox",
  PayPal_Email:"PayPal Email",
  PayPal_Username:"Isem tal-Utent API",
  PayPal_Payouts:"Ħlasijiet ta' PayPal",
  Select_PayPal_Key:"Agħżel PayPal Key",
  Secret:"Sigriet",
  API_Secret:"API Sigriet",
  PayPal_API_Keys:"PayPal Keys",
  New_PayPal_Key:"Ċavetta PayPal ġdida",
  Email_Subject:"Suġġett tal-Email",
  Email_Message:"Messaġġ bl-Email",
  Payout_Options:"Għażliet ta' Ħlas",
  Payment_Note:"Nota ta' Ħlas",
  Enable_Employee:"Ippermetti Impjegat",
  Enable_Production_Mode:"Ippermetti l-Modalità ta' Produzzjoni",
  Sandbox_Username:"Isem tal-Utent tal-kaxxa tar-ramel",
  Sandbox_Signature:"Firma Sandbox",
  Sandbox_Password:"Password Sandbox",
  Production_Username:"Isem tal-Utent tal-Produzzjoni",
  Production_Signature:"Firma tal-Produzzjoni",
  Production_Password:"Password tal-Produzzjoni",
  Production_Email:"Email tal-Produzzjoni",
  API_Client_ID:"ID tal-Klijent API",
  API_Signature:"Firma API",
  API_Password:"Password API",
  API_Username:"Isem tal-Utent API",
  Secret_Key:"Ċavetta Sigrieta",
  Sandbox:"Sandbox",
  Production:"Produzzjoni",
  Sandbox_Keys:"Ċwievet Sandbox",
  Production_Keys:"Ċwievet tal-Produzzjoni",
  API_Title:"Titolu API",
  Production_Mode:"Modalità ta' Produzzjoni",
  Account_Label:"Tikketta tal-Kont",
  No_PayPal_Setup:"L-ebda ċavetta PayPal",
  Enable_PayPal_Account:"Ippermetti Kont PayPal",
  No_PayPal_Word:"Żid tiegħek <a routerLink='/folder/Invoice/New'>PayPal API Key</a>.",
  Printed_Memo:"Memo stampat",
  Employee:"Impjegat",
  View_Employee:"Ara l-Impjegat",
  Mailing_Address:"Indirizz postali",
  Company_Address:"Indirizz tal-Kumpanija",
  Select_Company_Address:"Agħżel l-Indirizz tal-Kumpanija",
  Address:"Indirizz",
  Any_Day:"Kull Jum",
  Address_Name:"Isem tal-Indirizz",
  Unit:"Unità",
  Account:"Kont",
  Bank_Account:"Kont Bankarju",
  Account_Limits:"Attiva Limiti tal-Kont",
  Payroll:"Pagi",
  New_Payroll:"Pagi Ġdid",
  No_Payroll:"L-ebda Payroll li jmiss",
  Upcoming_Holiday:"Vacanza li ġejja:",
  Invoice_Due:"Fattura dovuta:",
  New_Invoice:"Fattura ġdida",
  No_Invoices:"L-ebda Fattura",
  No_Invoices_Word:"Oħloq l-ewwel <a routerLink='/folder/Invoice/New'>Fattura</a>.",
  Cheque_Due:"Ċekk dovut:",
  Payrolls:"Pagi",
  Sandbox_Mode:"Modalità Sandbox",
  Hire:"Kiri",
  Pay:"Ħlas",
  New:"Ġdid",
  Add:"Żid",
  Make:"Agħmel",
  Time:"Ħin",
  Write:"Ikteb",
  Holiday:"Vaganza",
  Holidays:"Festi",
  Next_Holiday:"Vaganza li jmiss:",
  All_Done:"Kollox lest!",
  Employees:"Impjegati",
  Payees:"Min jitħallas",
  Job:"Xogħol",
  Jobs:"Impjiegi",
  Invoice:"Fattura",
  Invoices:"Fatturi",
  Vacations:"Vaganzi",
  Cheques:"Verifiki",
  Brand_Cheques:"Ditta",
  Payment:"Ħlas",
  Enable_Payment:"Ippermetti l-Ħlas",
  Payments:"Pagamenti",
  Schedule:"Skeda",
  Schedules:"Skedi",
  Timesheet:"Time Sheet",
  Timesheets:"Time Sheets",
  Salary:"Salarju",
  New_Address:"Indirizz Ġdid",
  Address_2:"Indirizz (Linja 2)",
  _City:"Belt",
  _State:"Stat/Prov",
  City:"Belt / Township",
  State:"Stat/Provinċja",
  Postal:"Kodiċi Postali / ZIP",
  ZIP:"Posta / ZIP",
  Country:"Pajjiż",
  Addresses:"Indirizzi",
  Required_Options:"Għażliet Meħtieġa",
  Optional_Options:"Għażliet Fakultattivi",
  Additional_Options:"Għażliet addizzjonali",
  Required:"Meħtieġ",
  Optional:"Mhux obbligatorju",
  Additional:"Addizzjonali",
  No_Addresses:"L-ebda Indirizzi",
  New_Address_Word:"Żid l-ewwel <a routerLink='/folder/Address/New'>Indirizz</a>.",
  Select_Address:"Agħżel l-Indirizz",
  No_Address:"L-ebda Indirizzi",
  Print_Cheque:"Istampa Iċċekkja",
  Print_Cheque_Now:"Ipprintja Iċċekkja Issa",
  Description:"Deskrizzjoni",
  Pay_To_The_Order_Of:"Ħallas lill-Ordni ta':",
  Select_Date_Range:"Agħżel Firxa tad-Data",
  Select_Starting_Date:"Agħżel Data tal-Bidu",
  Select_Ending_Date:"Agħżel Data ta' Tmiem",
  Select_Date:"Agħżel id-Data",
  Cheque_Date:"Iċċekkja d-Data",
  Cheque_Memo:"Iċċekkja Memo",
  Blank_Cheque:"Blank Check",
  Blank:"Bjank",
  No_Cheques:"L-ebda Kontrolli",
  No_Cheques_Word:"Ipprintja l-ewwel <a routerLink='/folder/Cheque/New'>Iċċekkja</a> tiegħek.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"Ara l-Immaġni",
  View:"Ara",
  Modify:"Immodifika",
  Delete:"Ħassar",
  Cheque_Paid:"Mħallsa",
  New_Deduction:"Tnaqqis Ġdid",
  Title:"Titolu",
  Frequency:"Frekwenza",
  Hourly:"Kull siegħa",
  Daily:"Kuljum",
  Weekdays:"Ġranet tal-ġimgħa",
  BiWeekly:"Ġigħtejn",
  TriWeekly:"3 ġimgħat",
  Monthly:"Kul xahar",
  MiMonthly:"Xahur",
  Quarterly:"Kul tliet xhur",
  Yearly:"Kull sena",
  Every_Week:"Kull ġimgħa",
  Every_Payroll:"Kull Payroll",
  Every_Month:"Kull xahar",
  Annually:"Annwalment",
  Always_Scheduled:"Dejjem Skedat",
  Start_Date:"Data tal-Bidu",
  End_Date:"Data tat-Tmiem",
  Start_Time:"Ħin tal-Bidu",
  End_Time:"Ħin tat-Tmiem",
  Deduction_Label:"Tikketta ta' Tnaqqis",
  Notes:"Noti",
  Options:"Għażliet",
  Enable:"Ippermetti",
  Select_Deductions:"Agħżel Tnaqqis",
  Deductions:"Tnaqqis",
  No_Deductions:"L-ebda Tnaqqis",
  No_Deductions_Word:"Oħloq l-ewwel <a routerLink='/folder/Deduction/New'>Tnaqqis</a> tiegħek.",
  New_Employee:"Impjegat Ġdid",
  No_Title:"L-ebda Titolu",
  _Name:"Isem",
  About_Yourself:"Dwar lilek innifsek",
  Full_Name:"Isem sħiħ",
  Birthday:"L-għeluq",
  Email:"Email",
  SMS:"SMS",
  Phone:"Telefown",
  Test:"Test",
  Call:"Sejħa",
  Fax:"Faks",
  Printed_Note:"Nota Stampata",
  Position:"Pożizzjoni",
  Job_Position:"Pożizzjoni tax-Xogħol",
  Select_a_Job:"Agħżel Impjieg",
  Select_a_Salary:"Agħżel Salarju",
  Add_a_Deduction:"Żid Tnaqqis",
  Taxes:"Taxxi",
  Add_Taxes:"Żid it-Taxxi",
  Date_of_Birth:"Data tat-twelid",
  Email_Address:"Indirizz tal-Email",
  Phone_Number:"Numru tat-telefon",
  Phone_Call:"Telefonata",
  Enable_on_Payroll:"Ippermetti fuq Payroll",
  Select_Employees:"Agħżel l-Impjegati",
  No_Employees:"L-ebda Impjegati",
  No_Employees_Word:"Żid l-ewwel <a routerLink='/folder/Employee/New'>Impjegat ġdid tiegħek</a>.",
  No_Name:"Bla isem",
  Unemployeed:"Qgħad",
  Employeed:"Impjegat",
  Paid:"Mħallsa",
  Enabled:"Ippermettiet",
  Disabled:"Diżabilità",
  Fire:"Nar",
  Not_Available:"Mhux disponibbli",
  Not_Available_Word:"Ipprintja l-ewwel <a routerLink='/folder/Invoice/New'>Fattura</a> tiegħek u titħallas.",
  Select_Invoices:"Agħżel_Fatturi",
  Print_Invoice_Word:"Ipprintja l-ewwel <a routerLink='/folder/Invoice/New'>Fattura</a> tiegħek u titħallas.",
  Invoice_Title:"Titolu tal-fattura",
  Invoice_Date:"Data tal-Fattura",
  Due_Date:"Data ta' skadenza",
  New_Job:"Impjieg Ġdid",
  Details:"Dettalji",
  Customize:"Ippersonalizza",
  Customize_Dashboard:"Ippersonalizza Dashboard",
  Components:"Komponenti",
  No_Components:"L-ebda Komponenti",
  Main_Components:"Komponenti ewlenin",
  Smaller_Components:"Komponenti iżgħar",
  Error_Loading_Page:"Żball waqt it-tagħbija ta' din il-paġna.",
  Bank_Details:"Dettalji tal-Bank",
  About_Your_Job:"Dwar ix-xogħol tiegħek",
  Your_Schedule:"L-Iskeda Tiegħek",
  Job_Title:"Titlu tax-xogħol",
  Job_Description:"Deskrizzjoni tax-xogħol",
  Job_Details:"Dettalji tax-xogħol",
  Enable_Job:"Ippermetti l-Impjieg",
  Pay_Period:"Perjodu ta' Paga",
  Perpetually_Schedule:"Perpetwu Skeda",
  Select_Jobs:"Agħżel Impjiegi",
  No_Jobs:"L-ebda Impjiegi",
  Add_Jobs:"Żid Impjiegi",
  No_Jobs_Word:"Żid l-ewwel <a routerLink='/folder/Job/New'>Job</a> mal-lista.",
  Count_Employees:"job.employee_count+' Impjegati'",
  Zero_Employees:"0 Impjegati",
  New_Leave:"Leave Ġdid",
  Leave_Name:"Ħalli l-Isem",
  Paid_Leave:"Leave imħallas",
  Leave_Pay:"Ħalli l-paga",
  Indefinite_Leave:"Leave Indefinit",
  Indefinite_Payroll:"Pagi Indefinit",
  Leave:"Tlaq",
  Add_Schedules:"Żid Skedi",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Ippermetti l-Assenza",
  Select_Leaves:"Agħżel Weraq",
  No_Leaves:"Ebda Weave ta' Assenza",
  Leave_Of_Absence:"Leave ta' Assenza",
  Leaves_Of_Absence:"Leave of Assence",
  New_Leave_of_Absense:"Leave ta' Assenza Ġdid",
  No_Leaves_Word:"Żid l-ewwel <a routerLink='/folder/Leave/New'>Leave ta' Assenza</a>.",
  Not_Enabled:"Mhux Ippermettiet",
  Absences:"Assenzi",
  Payee:"Min jitħallas",
  New_Payee:"Beeee Ġdid",
  Payee_Identifier:"Identifikatur ta' min jitħallas",
  Payee_Name:"Isem min jitħallas",
  Payee_Title:"Titolu ta' Min jitħallas",
  Payment_Memo:"Memo tal-Ħlas",
  Select_Payees:"Agħżel il-benefiċjarji",
  No_Payees:"L-ebda benefiċjarju",
  Add_Payee_Note:"Żid l-ewwel <a routerLink='/folder/Payee/New'>Jitħallas</a>.",
  New_Payees:"Pagaturi ġodda",
  About_The_Payment_Schedule:"Skeda tal-Ħlas",
  Your_Payroll_Schedule:"Pagi Awtomatiku",
  New_Payment:"Ħlas Ġdid",
  Identifier:"Identifikatur",
  Select:"Agħżel",
  Memo:"Memo",
  Payment_Date:"Data tal-Ħlas",
  Mark_as_Paid:"Immarka bħala Mħallsa",
  Select_Payments:"Agħżel Pagamenti",
  No_Payments:"L-ebda Ħlas",
  No_Payments_Word:"Oħloq l-ewwel <a routerLink='/folder/Payment/New'>l-Ħlas</a>.",
  Create_Payroll:"Oħloq Payroll",
  Properties:"Proprjetajiet",
  Payroll_Title:"Titolu tal-Pagi",
  Payroll_Notes:"Noti tal-pagi",
  Payroll_Setup:"Configurazzjoni tal-pagi",
  Tabulate_Payments:"Ħlasijiet f'tabella",
  Tabulate:"Tabula",
  By:"Bil:",
  Payments_By:"Pagamenti Sa",
  Timesheets_And_Schedules:"Time Sheets & Skedi",
  Both:"It-tnejn",
  Items:"Oġġetti",
  Add_Payees:"Żid il-benefiċjarji",
  Add_Account:"Żid Kont",
  Enable_Account:"Attiva l-Kont",
  Enable_Payroll:"Attiva Payroll",
  Print_Payroll:"Istampa l-Pagi",
  No_Payrolls:"L-ebda Payroll",
  No_Payroll_Word:"Oħloq l-ewwel <a routerLink='/folder/Payroll/New'>Payroll</a> tiegħek.",
  View_more:"VIEW_MORE",
  Less:"ANQAS",
  Add_Payroll:"Żid il-Pagi",
  Select_Payroll:"Agħżel Payroll",
  About_Your_Salary:"Dwar is-salarju tiegħek",
  Add_Salaries:"Żid Salarji",
  Add_Salary:"Żid Salarju",
  Salaries:"Salarji",
  No_Salaries:"L-ebda Salarju",
  No_Salaries_Word:"Żid l-ewwel <a routerLink='/folder/Salary/New'>Salarju</a>.",
  Select_Salaries:"Agħżel Salarji",
  New_Salary:"Salarju Ġdid",
  Salary_Name:"Identifikatur tas-salarju",
  Enable_Salary:"Attiva Salarju",
  Salary_Amount:"Ammont tas-salarju",
  New_Schedule:"Skeda Ġdida",
  Schedule_Title:"Titolu tal-Iskeda",
  Add_Address:"Żid Indirizz",
  Repeat:"Irrepeti",
  Design:"Disinn",
  Edit_Design:"Editja d-Disinn",
  Edit_this_Design:"Editja dan id-Disinn",
  Repeat_Payment:"Irrepeti l-Ħlas",
  Enable_Schedule:"Ippermetti l-Iskeda",
  Never:"Qatt",
  Select_Schedule:"Agħżel Skedi",
  No_Schedules:"L-ebda Skedi",
  No_Schedules_Word:"Oħloq l-ewwel <a routerLink='/folder/Schedule/New'>Skeda</a>.",
  New_Administrator:"Amministratur Ġdid",
  Username:"Isem tal-Utent",
  First_Name:"Isem",
  Last_Name:"Kunjom",
  Add_an_Address:"Żid Indirizz",
  Payment_Limit:"Limitu għal kull Ħlas",
  Total_Limit:"Limitu totali",
  Select_Accounts:"Agħżel Kontijiet",
  No_Administrators:"L-ebda Amministraturi",
  No_Administrators_Word:"Oħloq l-ewwel <a routerLink='/folder/Administrator/New'>Kont Amministratur</a>.",
  New_Administrators_Word:"Żid l-ewwel <a routerLink='/folder/Administrator/New'>Amministratur</a>",
  Cloud:"Sħaba",
  Backup:"Backup",
  Enable_iCloud:"Ippermetti iCloud",
  Enable_Google_Drive:"Ippermetti Google Drive",
  Enable_Microsoft_OneDrive:"Ippermetti Microsoft OneDrive",
  Automatically_Backup:"Awtomatikament Backup",
  FTP_Settings:"Settings FTP",
  URL_File_Prompt:"Jekk jogħġbok speċifika l-post għal fajl .xls / .xlsx / .json għall-importazzjoni:",
  URL_SQL_Prompt:"Jekk jogħġbok speċifika l-post tal-fajl SQLite għall-importazzjoni:",
  FTP_Backup:"Backup FTP",
  FTP_Import:"Importazzjoni FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Ospitanti",
  Port:"Port",
  Path:"Passaġġ",
  Data_Path:"Mogħdija tad-Data",
  Enable_FTP_Account:"Ippermetti Kont FTP",
  HostnameIP:"Isem tal-ospitant",
  Password:"Password",
  Connection_Port:"Port tal-Konnessjoni",
  Enable_MySQL_Database:"Ippermetti Database MySQL",
  Log:"Zokk maqtugħ",
  Reset:"Irrisettja",
  Erase:"Ħassar",
  Export:"Esportazzjoni",
  Database:"Database",
  Upload_CSV:"Tella' CSV",
  Download_CSV:"Niżżel CSV",
  SQL_Database:"URL SQL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL Backup",
  Internal_Notes:"Noti Interni",
  Root_Path:"Root Path",
  Select_Backup:"Agħżel Backup",
  Add_New_Backup:"Żid Backup Ġdid",
  First_Backup:"Imwaqqaf l-ewwel backup...",
  Backups:"Backups",
  Add_Backup:"Żid Backup",
  No_Backups:"M'hemm l-ebda backups li jinstabu.",
  Select_Backup_Type:"Agħżel it-tip ta' backup li tixtieq tissettja...",
  Backup_Type:"Tip ta' backup",
  FTP_Drive:"FTP Drive",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Issuq",
  Microsoft_OneDrive:"Issuq",
  Import_Fields:"Importa Oqsma",
  Import_Fields_Word:"Uża din it-taqsima biex tagħżel <a routerLink='/folder/Holiday/New'>Importa</a> data.",
  Upload:"Itella",
  Download:"Niżżel",
  Download_JSON:"Niżżel bħala Data JSON",
  Download_SQL:"Niżżel bħala Fajl SQL",
  New_Bank:"Bank Ġdid",
  New_Account:"Kont Ġdid",
  New_Bank_Account:"Kont Bankarju Ġdid",
  Upload_Image:"Itella' immaġni",
  Bank_Name:"Isem tal-Bank",
  Bank_Address:"Indirizz tal-Bank",
  Branch_Address:"Indirizz tal-Fergħa",
  Address_on_Cheque:"Indirizz",
  Cheque_Numbers:"Iċċekkja Numri",
  Cheque_Details:"Iċċekkja d-dettalji",
  Bank_Logo:"Logo tal-Bank",
  Cheque_ID:"Iċċekkja l-ID",
  Starting_Cheque_ID:"Bidu Iċċekkja ID",
  Transit_Number:"Numru tat-Transitu",
  Institution_Number:"Numru tal-Istituzzjoni",
  Designation_Number:"Numru ta' Deżinjazzjoni",
  Account_Number:"Numru tal-kont",
  Limits:"Limiti",
  Default_Limits:"Limiti default",
  Over_Limit:"Laqqa' l-limitu",
  Under_Limit:"Taħt Limitu",
  Payroll_Limit:"Limitu tal-pagi",
  Enable_Bank_Account:"Attiva Kont Bankarju",
  Select_Account:"Agħżel Kont",
  No_Bank_Account:"L-ebda Kont Bankarju",
  No_Bank_Account_Word:"Żid l-ewwel <a routerLink='/folder/Accounts/New'>Kont Bankarju</a>.",
  Bank_Accounts:"Kontijiet Bankarji",
  No_Accounts:"L-ebda Kontijiet",
  No_Accounts_Note:"Żid l-ewwel <a routerLink='/folder/Accounts/New'>Kont Bankarju</a>.",
  Cheque_Designer:"Iċċekkja Disinjatur",
  Cheque_Design:"Iċċekkja d-Disinn",
  Select_Design:"Agħżel Disinn",
  Cheque_Designs:"Iċċekkja Disinni",
  No_Cheque_Designs:"Ebda Disinni ta' Iċċekkja",
  No_Cheque_Designs_Word:"Oħloq tiegħek stess <a routerLink='/folder/Settings/Cheque/Design/New'>Iċċekkja Disinn</a>.",
  Set_Default:"Issettja bħala Default",
  Default:"Default",
  Remove:"Neħħi",
  Folder:"Folder",
  Edit:"Editja",
  LoadingDots:"Tagħbija...",
  Add_a_New_File:"Żid <a href='#' (click)='add()'>Fajl Ġdid</a> ma'",
  this_folder:"dan il-folder",
  FTP_Backup_Settings:"Settings tal-Backup FTP",
  Secure_File_Transfer:"Trasferiment ta' Fajl Sikur",
  New_Holiday:"Vacanza Ġdida",
  Add_Holiday:"Żid Vaganza",
  Holiday_Name:"Isem il-vaganzi",
  Additional_Pay:"Paga Addizzjonali",
  Enable_Holiday:"Ippermetti l-Vaganzi",
  Select_Holidays:"Agħżel il-Festi",
  No_Holidays:"L-ebda vaganzi",
  No_Holidays_Word:"Żid l-ewwel <a routerLink='/folder/Holiday/New'>Festa Pubblika</a>.",
  New_Import:"Importazzjoni Ġdida",
  Import_Data:"Importa Data",
  Import_Data_Word:"Agħżel it-tip ta' fajl jew il-metodu ta' upload tal-għażla tiegħek.<br /> Inti tkun tista' tagħżel liema oqsma importati f'fajl jikkorrispondu għal kwalunkwe parametru fl-app wara li ttella' fajl appoġġjat.", 
  Import_File:"Importa Fajl",
  Link_To_File:"Link għall-Fajl",
  Select_File:"Agħżel File",
  New_Moderator:"Moderatur Ġdid",
  Allow_Moderation:"Ħalli l-Moderazzjoni",
  Create_Paycheques:"Oħloq Paychecks",
  Edit_Paycheques_and_Data:"Editja Paychecks u Data",
  Destroy_Data_and_Paycheques:"Eqred id-Data u l-Pagi",
  Bonus_Percentage:"Perċentwal tal-Paycheck",
  Fixed_Amount:"Ammont Fiss",
  Select_Moderator:"Agħżel Moderatur",
  No_Moderators:"L-ebda Moderaturi",
  Moderators:"Moderaturi",
  Moderator_Account:"Oħloq l-ewwel <a routerLink='/folder/Administrator/New'>Kont tal-Moderatur</a>.",
  No_Moderators_Word:"Żid l-ewwel <a routerLink='/folder/Administrator/New'>Moderatur</a>.",
  Defaults:"Defaults",
  Provide_Us_Info:"Ipprovdilna Informazzjoni",
  Setup_Your_Printer:"Issettja l-istampatur tiegħek",
  Your_Company:"Dwar il-Kumpanija Tiegħek",
  Company_Name:"Isem tal-Kumpanija",
  Currency:"Munita",
  Default_Currency:"Munita Default",
  Base_Monthly_Income:"Dħul ta' kull xahar",
  Protection:"Protezzjoni",
  App_Protection:"Protezzjoni tal-App",
  PIN_Code_Protection:"Protezzjoni tal-Kodiċi PIN",
  App_Protection_Word:"Ippermetti metodi ta' sigurtà li jgħinu biex jipproteġu l-kont tiegħek.",
  PIN_Code:"Kodiċi PIN",
  Change_PIN:"Ibdel il-PIN",
  New_Password:"Password gdida",
  Geofence_Protection:"Protezzjoni Ġeo-Fence",
  Geofence_Area:"Issettja Żona",
  Distance:"Distanza",
  Setup_Now:"Issettja Issa",
  Mile:"Mile",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Rikonoxximent tal-wiċċ",
  Face:"Wiċċ",
  Setup:"Setup",
  Label:"Tikketta",
  Password_Protection:"Protezzjoni bil-Password",
  Modify_Password:"Immodifika l-Password",
  New_Tax_Entry:"Dħul Ġdid tat-Taxxa",
  New_Tax:"Taxxa Ġdida",
  Tax_Label:"Tikketta tat-Taxxa",
  Perpetually_Enabled:"Perpetually Enabled",
  Select_Taxes:"Agħżel it-Taxxi",
  Tax_Deductions:"Tnaqqis tat-Taxxa",
  No_Tax_Deductions:"L-ebda Tnaqqis tat-Taxxa",
  No_Tax_Deductions_Word:"Żid l-ewwel <a routerLink='/folder/Tax/New'>Taxxa</a> tnaqqis.",
  New_Timer:"Timer Ġdid",
  Start:"Ibda",
  Stop:"Waqfa",
  Start_Timer:"Ibda Timer",
  Stop_Timer:"Stop Timer",
  Timer_Active:"Timer Attiv",
  Timer:"Timer:",
  Timer_Running:"Timer: (Tħaddim)",
  Save_Timer:"Save Timer",
  New_Timesheet:"Ħin Ġdid",
  Select_Timesheets:"Agħżel Time Sheets",
  Work_Notes:"Noti tax-Xogħol",
  Entry_Title:"Titolu tad-dħul",
  No_Timesheets:"L-ebda Time Sheets",
  No_Timesheets_Word:"Żid l-ewwel <a routerLink='/folder/Timesheet/New'>Time Sheet</a>.",
  Timesheet_Submitted:"Time Sheet Mibgħuta",
  Timesheet_Congrats:"Prosit! Ħin tiegħek Sheet ġiet sottomessa b'suċċess. Grazzi!",
  Timesheet_Copy:"Biex tirċievi kopja tad-dokumenti tiegħek jekk jogħġbok agħtina l-indirizz elettroniku tiegħek u/jew in-numru tal-mowbajl.",
  Submit:"Ibgħat",
  Allow_Notifications:"Ħalli Notifiki",
  Untitled_Entry:"Dħul Ġdid",
  Untitled_Bank:"Bank bla titolu",
  Timesheet_Entry:"Dħul tal-Time Sheet",
  Work_Description:"Deskrizzjoni tax-Xogħol",
  Enable_Timesheet:"Ippermetti Time Sheet",
  Submit_Timesheet:"Ibgħat Folja tal-Ħin",
  Vacation:"Vaganzi",
  New_Vacation:"Vaganza Ġdida",
  Vacation_Name:"Isem tal-Vaganzi",
  Paid_Vacation:"Vaganza mħallsa",
  Vacation_Pay:"Ħlas vaganza",
  Enable_Vacation:"Ippermetti Vacation",
  Select_Vacations:"Agħżel Vaganzi",
  No_Vacations:"L-ebda Vaganza",
  No_Vacations_Word:"Oħloq l-ewwel <a routerLink='/folder/Vacation/New'>Vaganzi</a> entrata.",
  Payroll_Schedule:"Skeda tal-pagi",
  Next_Payroll:"Pagi li jmiss:",
  Upcoming_Payroll:"Pagi li jmiss",
  No_Upcoming_Payroll:"L-ebda Payroll li jmiss",
  Address_Book:"Ktieb tal-indirizzi",
  Archived_Documents:"Dokumenti Arkivjati",
  Dev_Mode:"Applikazzjoni fil-Modalità ta' Żvilupp",
  Administrators:"Amministraturi",
  Privacy:"Privatezza",
  None:"Xejn",
  Select_Signature:"Agħżel Firma",
  No_Signatures:"L-ebda firem",
  No_Signatures_Word:"Żid l-ewwel <a routerLink='/folder/Signature/New'>Firma</a>.",
  Repeat_Indefinitely:"Irrepeti b'mod indefinit",
  Sign:"Ffirma",
  Sign_Here:"Iffirma hawn",
  Date_Signed:"Data Iffirmata",
  Signature_Pad:"Pad tal-Firem",
  Account_Holder:"Detentur tal-Kont",
  Account_Properties:"Proprjetajiet tal-Kont",
  Name_On_Cheque:"Isem fuq Ċekk",
  Server_Hostname:"Isem tas-Server / IP",
  Printers:"printers",
  No_Printers:"L-ebda Printers",
  Printer_Exists:'Printer b\'dan l-isem diġà jeżisti.',
  No_Printers_Word:"Żid l-ewwel <a routerLink='/folder/Printer/New'>Printer</a>.",
  No_Printer_Alert:"L-ebda printer mhu definit. Tixtieq issettja printer?",
  Add_Printer:"Żid Printer",
  New_Printer:"Printer Ġdid",
  Printer_Hostname:"Isem tal-Ost / IP tal-istampatur",
  Printer_Label:"Tikketta tal-istampatur",
  Printer_Protocol:"Protokoll tal-istampatur",
  Protocol:"Protokoll",
  Email_Print:"Email",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Socket",
  Print_Job:"Istampa Xogħol",
  Printed:"Stampat",
  Not_Printed:"Mhux Stampat",
  Print_Jobs:"Impjiegi tal-Istampar",
  Print_Queue:"Kju tal-Istampar",
  No_Print_Jobs:"L-ebda Impjiegi tal-Istampar",
  No_Prints:"Oħloq <a routerLink='/folder/Cheque/New'>Ċekk</a> ġdid biex tipprintja.",
  Prints:"Stampi",
  Find_Printer:"Sib Printer",
  Find_AirPrint_Devices:"Sib Apparat AirPrint",
  Select_Printer:"Agħżel Printer",
  System_UI:"UI tas-sistema",
  Printer:"Printer",
  Status:"Stats",
  Preview:"Preview",
  Enable_Print_Job:"Ippermetti x-xogħol tal-istampar",
  Queue_Weight:"Piż tal-kju",
  Unlimited:"Illimitat",
  Show_Advanced_Printer_Options:"Uri Għażliet Avvanzati tal-Istampatur",
  Advanced:"Avvanzat",
  Location:"Lokazzjoni",
  Note:"Nota",
  Queue_Name:"Isem tal-kju",
  Pages_Printed_Limit:"Limitu Stampat tal-Paġni",
  MultiPage_Idle_Time:"Ħin ta' Stennija Multipaġni(i)",
  Page_Count_Limit:"Limitu tal-Għadd tal-Paġni",
  Page_Orientation:"Orjentazzjoni tal-Paġna",
  Portrait:"Ritratt",
  Landscape:"Pajsaġġ",
  Duplex:"Duplex",
  Double_Sided_Printing:"Doppju naħat",
  Duplex_Mode:"Modalità Duplex",
  Duplex_Short:"Qasir",
  Duplex_Long:"Twal",
  Duplex_None:"Xejn",
  Color_And_Quality:"Kulur U Kwalità",
  Monochrome:"Monokromu",
  Photo_Quality_Prints:"Stampi ta' Kwalità tar-Ritratti",
  Printer_Email:"Email tal-istampatur",
  Automatically_Downsize:"Naqqas id-daqs awtomatikament",
  Paper:"Karta",
  Paper_Name:"Isem tal-Karta",
  Paper_Width:"Wisa' tal-Karta",
  Paper_Height:"Għoli tal-Karta",
  Paper_Autocut_Length:"Tul ta' Qtugħ Awtomatiku tal-Karta",
  Margins:"Marġini",
  Page_Margins:"Marġini tal-Paġni",
  Page_Margin_Top:"Marġni ta' Fuq tal-Paġna",
  Page_Margin_Right:"Marġni tal-Paġna Leminija",
  Page_Margin_Bottom:"Marġni tal-paġna ta' isfel",
  Page_Margin_Left:"Marġni tax-Xellug tal-Paġna",
  Add_Employees:"Żid Impjegati",
  Header:"Header",
  Print_A_Page_Header:"Ipprintja Header tal-Paġna",
  Header_Label:"Tikketta tal-Header",
  Header_Page_Index:"Indiċi tal-Paġna tal-Intestatura",
  Header_Font_Name:"Tip tal-header",
  Select_Font:"Agħżel Font",
  Font_Selector:"Selettur tal-Font",
  Header_Font_Size:"Tip tal-header",
  Header_Bold:"Header Bold",
  Header_Italic:"Header Korsiv",
  Header_Alignment:"Allinjament tal-Header",
  Left:"Xellug",
  Center:"Ċentru",
  Right:"Is-sewwa",
  Justified:"Ġustifikata",
  Header_Font_Color:"Kulur tal-Header",
  Select_Color:"Agħżel il-Kulur",
  Footer:"Footer",
  Print_A_Page_Footer:"Ipprintja paġna ta' qiegħ il-paġna",
  Footer_Label:"Tikketta ta' qiegħ il-paġna",
  Footer_Page_Index:"Indiċi tal-paġna ta' qiegħ il-paġna",
  Footer_Font_Name:"Footer Font",
  Fonts:"Fonts",
  Done:"Magħmul",
  Select_Fonts:"Agħżel Fonts",
  Footer_Font_Size:"Daqs tal-footer",
  Footer_Bold:"Footer Bold",
  Footer_Italic:"Footer Korsiv",
  Footer_Alignment:"Allinjament ta' qiegħ il-paġna",
  Footer_Font_Color:"Kulur ta' qiegħ il-paġna",
  Page_Copies:"Kopji tal-Paġni",
  Enable_Printer:"Ippermetti Printer",
  Remote_Logging:"Logging mill-bogħod",
  Log_Server:"Log Server",
  Encryption:"Encryption",
  Random_Key:"Keft każwali",
  Encryption_Key:"Ċavetta ta' Encryption",
  Cheques_Webserver:"Database Custom",
  Learn_How:"Tgħallem Kif",
  Signature:"Firma",
  Default_Printer_Unit:"fi/ċm/mm/(pt)",
  View_Signature:"Ara l-Firma",
  Printed_Signature:"Firma Stampata",
  Digitally_Sign:"Finjal Diġitali",
  Digital_Signature:"Firma Diġitali",
  Add_Signature:"Żid Firma",
  Add_Your_Signature:"Żid il-Firma Tiegħek",
  Enable_Signature:"Attiva Firma",
  Digitally_Signed:"Ffirmat Diġitalment",
  Insert_Error:"Ma tistax tissejvja l-kontroll minħabba kwistjonijiet ta' database.",
  Delete_Confirmation:"Int żgur li trid tħassar din l-informazzjoni?",
  Discard_Confirmation:"Int ċert li trid tarmi din l-informazzjoni?",
  Discard_Bank_Confirmation:"Int ċert li trid tarmi dan il-kont?",
  Discard_Printer_Confirmation:"Int ċert li trid tarmi dan l-istampatur?",
  Delete_Bonus_Confirmation:"Int ċert li trid tħassar dan il-bonus?",
  Delete_Invoice_Confirmation:"Int ċert li trid tħassar din il-fattura?",
  Generated_Cheque:"Iċċekkja Ġġenerata",
  Generated_Invoice:"Fattura Ġenerata",
  Schedule_Start_Time:"Bidu tal-Iskeda",
  Schedule_End_Time:"Tmiem l-Iskeda",
  New_Call:"Sejħa Ġdida",
  No_Contacts:"L-ebda Kuntatti",
  No_Contacts_Word:"L-amministraturi, il-moderaturi, l-impjegati u l-benefiċjarji jidhru bħala kuntatti.",
  PDF_Subject:"finanzi",
  PDF_Keywords:"check tal-pagi tal-pagi PDF check cheques",
  Printer_Setup:"Setup tal-istampatur",
  Printer_Selection:"Għażla tal-istampatur",
  New_Fax:"Faks Ġdid",
  New_Fax_Message:"Messaġġ Ġdid tal-Faks",
  Fax_Machine:"Magni tal-Faks",
  Fax_Name:"Isem tal-Faks",
  Fax_Email:"Email tal-Faks",
  Fax_Number:"Numru tal-Faks",
  Contents:"Kontenut",
  Fax_Body:"Korp tal-paġna",
  Header_Word:"Header:",
  Header_Text:"Test tal-Intestatura",
  Include_Header:"Inkludi Header",
  Include_Footer:"Inkludi Footer",
  Footer_Word:"Footer:",
  Footer_Text:"Test ta' qiegħ il-paġna",
  Attach_a_Cheque:"Ehmeż Ċekk",
  Add_Deduction:"Żid Tnaqqis",
  Enable_Fax:"Ibgħat Fax",
  Select_Fax:"Agħżel Fax",
  No_Faxes:"L-ebda Fax",
  Faxes:"Faks",
  Save_and_Send:"Ibgħat Fax",
  Save_and_Pay:"Issejvja u ħallas",
  WARNING:"TWISSIJA:",
  Remember:"Ftakar",
  Printing:"Istampar",
  Printing_Complete:"L-Istampar Tlesti!\n\n",
  of:"ta'",
  There_Were:"Kien hemm ",
  Successful_Prints:"stampi ta' suċċess u",
  Unsuccessful_Prints:"prints mingħajr suċċess.",
  PrinterError:"Skużani! Kien hemm żball.",
  Printing_:"Stampar...",
  PrinterSuccess:"Dokument stampat b'suċċess.",
  PrintersSuccess:"Dokumenti stampati b'suċċess.",
  Print_Cheques:"Istampa Ċekkijiet",
  New_Message:"Messaġġ Ġdid",
  New_Messages:"Messaġġi ġodda",
  Read_Message:"Aqra l-Messaġġ",
  Write_Message:"Ikteb Messaġġ",
  Send_Message:"Ibgħat Messaġġ",
  Subject:"Suġġett",
  Message:"Messaġġ",
  Writing:"Kitba...",
  Send:"Ibgħat",
  Set_Date:"Issettja d-Data",
  Set_Time:"Issettja l-Ħin",
  Recieve:"Irċievi",
  Set_as_Default:"Issettja bħala Default",
  Default_Account:"Kont default",
  Default_Design:"Disinn Default",
  Multiple_Cheques:"Verifiki (Tripple)",
  Account_Mode:"Modalità tal-Kont",
  Multiple_Cheques_Description:"Tliet kontrolli għal kull paġna.",
  Check_and_Table:"Iċċekkja u Tabella",
  Check_including_Table:"Iċċekkja u tabella tal-kontabilità.",
  Check_Mailer:"Iċċekkja Mailer",
  Check_Mailer_Window:"Iċċekkja b'tieqa ta' indirizz.",
  DocuGard_Table_Top:"DocuGard Check & Table (Fuq)",
  DocuGard_Table_Middle:"DocuGard Check & Table (Nofsani)",
  DocuGard_Table_Bottom:"DocuGard Iċċekkja u Tabella (T'isfel)",
  DocuGard_Mailer_Top:"DocuGard Check Mailer (Fuq)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (Nofsani)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mailer (T'isfel)",
  DocuGard_Three_Cheques:"DocuGard Checks (Tripple)",
  DocuGard_Cheque_Top:"DocuGard Check (Fuq)",
  DocuGard_Cheque_Middle:"DocuGard Check (Nofsani)",
  DocuGard_Cheque_Bottom:"DocuGard Check (qiegħ)",
  DocuGard_Three_Cheques_Window:"Tliet kontrolli fuq paġna waħda.",
  DocuGard_Table_Top_Window:"Iċċekkja u tabella tal-qligħ.",
  DocuGard_Table_Middle_Window:"Iċċekkja u tabella tal-qligħ.",
  DocuGard_Table_Bottom_Window:"Iċċekkja u tabella tal-qligħ.",
  DocuGard_Mailer_Top_Window:"Iċċekkja, tabella, u indirizz.",
  DocuGard_Mailer_Middle_Window:"Iċċekkja, tabella, u indirizz.",
  DocuGard_Mailer_Bottom_Window:"Iċċekkja, tabella, u indirizz.",
  DocuGard_Cheque_Top_Window:"Iċċekkja għal karta sigura.",
  DocuGard_Cheque_Middle_Window:"Iċċekkja għal karta sigura.",
  DocuGard_Cheque_Bottom_Window:"Iċċekkja għal karta sigura.",
  Basic_Cheque:"Iċċekkja (Fuq)",
  Basic_Cheque_Print:"Ipprintja ċekk wieħed.",
  Error_Setting_As_Paid:"Żball fl-issettjar bħala Imħallas",
  Add_Attachment:"Żid l-Attachment",
  PrinterUnavailable:"Printer Mhux Disponibbli",
  CreditCardComponent:"Kards",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Depożitu Ġdid",
  Deposits:"Depożiti",
  No_Deposits:"L-ebda Depożiti",
  Credit_Card_Deposit:"Karta tal-kreditu",
  New_Credit_Card_Deposit_Message:"Depożitu ta' Karta ta' Kreditu",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Depożitu Bitcoin",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Trasferiment Interac",
  Payments_Limit:"Limitu ta' Ħlas",
  No_Payment_Limit:"L-ebda Limitu ta' Ħlas",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Depożitu PayPal",
  No_Deposits_Word:"Żid l-ewwel kumpanija <a routerLink='/folder/Deposit/New'>Depożitu</a>.",
  No_Documents_Specified:"L-ebda dokument ma kien speċifikat għall-istampar",
  No_Printers_Added:"L-ebda printers ma nstab. Mur Settings > Printers biex iżżid wieħed.",
  DB_Erase_Prompt:"Ħassar kompletament id-database kollha? TWISSIJA: Se titlef l-informazzjoni kollha maħżuna!",
  Console_Warning:"Twaħħalx xi test f'din il-console. Tista' tpoġġi lilek innifsek u/jew lill-kumpanija tiegħek f'riskju serju.",
  No_Faxes_Word:"Oħloq l-ewwel <a routerLink='/folder/Fax/New'>Fax</a>.",
  Cheque_Delete_Confirmation:"Int żgur li trid tħassar dan il-kontroll?",
  Design_Delete_Confirmation:"Int żgur li trid tħassar dan id-disinn?",
  Cheque_Pay_Confirmation:"Immarka dan iċ-ċekk bħala mħallas? MHUX se jidher fil-kju tal-istampar.",
  Payment_Pay_Confirmation:"Immarka dan il-ħlas bħala mħallas? MHUX se jidher fil-kju taċ-ċekkijiet.",
  Delete_Deduction_Confirmation:"Int ċert li trid tħassar dan it-tnaqqis?",
  Delete_Job_Confirmation:"Int żgur li trid tħassar dan ix-xogħol?",
  Delete_Timesheet_Confirmation:"Int ċert li trid tħassar din il-timesheet?",
  Delete_Schedule_Confirmation:"Int ċert li trid tħassar din l-iskeda?",
  Delete_Setting_Confirmation:"Int żgur li trid tirrisettja dan is-setting?",
  Delete_Fax_Confirmation:"Int żgur li trid tħassar dan il-faks?",
  Delete_File_Confirmation:"Int żgur li trid tħassar dan il-fajl?",
  Delete_Vacation_Confirmation:"Int żgur li trid tħassar din il-vaganza?",
  Delete_Printer_Confirmation:"Int ċert li trid tħassar dan l-istampatur?",
  Remove_Design_Confirmation:"Int żgur li trid tħassar dan id-disinn?",
  Delete_Payroll_Confirmation:"Int żgur li trid tħassar din il-pagi?",
  Send_Fax_Email_Confirmation:"Tridu tibgħat faks u email dawn id-dokumenti?",
  Send_Email_Confirmation:"Tixtieq tibgħat email dan id-dokument?",
  Send_Fax_Confirmation:"Trid tfax dan id-dokument?",
  Error_Generating_PDF:"Skużani. Kien hemm żball fil-ġenerazzjoni ta' dan id-dokument.",
  PDF_Error_Saving_Image:"Jiddispjaċina. Kien hemm żball fl-iffrankar tal-immaġni PDF ta' dan id-dokument.",
  Test_Printer_Confirmation:"Trid tipprintja paġna tat-test fuq dan l-istampatur?",
  Save_Timesheet_Prompt:"Jekk jogħġbok żid 'Titolu' jew agħfas 'Ibda Timer' biex issalva.",
  Remove_Geofence_Prompt:"Int żgur li trid tneħħi l-post ta' dan il-ġeo-ċint?",
  Delete_Employee_Confirmation:"Int żgur li trid tħassar",
  Fire_Employee_Confirmation:"Int ċert li trid tispara"
}