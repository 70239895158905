import { Component, OnInit } from '@angular/core';
declare var jQuery:any; declare var $ :any; declare var MasterSlider :any;
@Component({
  selector: 'app-screenshots',
  templateUrl: './screenshots.component.html',
  styleUrls: ['./screenshots.component.css']
})
export class ScreenshotsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
      jQuery(function () {
        var slider = new MasterSlider();
        slider.setup('masterslider' , {autoplay:true,width:800,height:700,space:5});
        slider.control('arrows');
      });
  }

}
