export const Az = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"müəlliflik hüququ & surəti; 2023",
  black:"Qara",
  green:"Yaşıl",
  gold:"Qızıl",
  blue:"Mavi",
  brown:"Qəhvəyi",
  purple:"Bənövşəyi",
  pink:"Çəhrayı",
  red:"Qırmızı",
  Swatches:"Nümunə nümunələri",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Hue",
  Saturation:"Doyma",
  Lightness:"Yüngüllük",
  Upgrade_To_Pro:"Professional səviyyəyə yüksəlmək",
  AllFeaturesInclude:"Bütün Xüsusiyyətlərə Daxildir:",
  PrintUnlimitedCheques:"Limitsiz Çekləri çap edin",
  PremiumChequeDesigns:"Premium Çek Dizaynları",
  ManageUnlimitedEmployees:"Limitsiz İşçiləri idarə edin",
  AddUnlimitedPayees:"Limitsiz Payees əlavə edin",
  CreateUnlimitedPayrolls:"Limitsiz Əmək Haqqı Yaradın",
  UnlimitedSchedulesandTimeSheets:"Limitsiz cədvəllər və vaxt cədvəlləri",
  BulkPayPalPayouts:"Toplu PayPal Ödənişləri",
  UnlimitedBankAccounts:"Limitsiz Bank Hesabları",
  ManageMultipleCompanies:"Çox şirkətləri idarə edin",
  TrackInsurancePolicies:"Sığorta Siyasətlərini izləyin",
  Bio_MetricProtection:"Bio-Metrik Mühafizə",
  Geo_FenceLock_OutProtection:"Geo-Fence Lock-Out Mühafizəsi",
  Multiple_Companies_Word:"Çoxlu şirkətləri idarə etmək Checks mükafatı olmadan mümkün deyil.",
  PayPal_Payouts_Word:"PayPal ödənişləri Checks mükafatı olmadan deaktiv edilir.",
  PINProtection:"PİN Qoruma",
  PasswordProtection:"Parolun qorunması",
  May_Require_Approval:"Təsdiq tələb oluna bilər.",
  Subscribe:"Abunə ol",
  Billed:"Hesablanmışdır",
  Up:"Yuxarı",
  Down:"Aşağı",
  Positioning:"Mövqeləşdirmə",
  Marker:"Marker",
  Drag_Marker:"Markeri sürükləyin",
  Tagline:"Çekləri çap edin və əmək haqqı cədvəlini tərtib edin",
  Marker_Word:"Elementi ölçmək üçün Markerlərdən istifadə edin.",
  Pinch_Zoom:"Yaxınlaşdırın",
  Pinch_Word:"Elementi böyütmək üçün çimdikləyin.",
  Drag:"Çək",
  Drag_Word:"Elementləri dartmaq üçün barmağınızdan istifadə edin.",
  subscription_alias_word:"Abunəliyin avtomatik yenilənməsi",
  premium_alias_word:"Birdəfəlik Təkmilləşdirmə Paketi",
  print_alias_word:"Fərdi çekləri çap edin",
  mode_alias_word:"Rejim",
  Pro:"Pro",
  Pro_word:"Pro versiyası tələb olunur.",
  Cant_Delete_Default_Company_Word:"Üzr istəyirik, siz defolt şirkətinizi silə bilməzsiniz .",
  Reinsert_Default_Designs:"Defolt dizaynları yenidən daxil edin",
  Reinsert_Default_Designs_word:"Defolt dizaynları yenidən daxil etmək istəyirsiniz?",
  Subscription_Active_Disable_Word:"Bu abunə aktivdir. Bu Çeklərə abunəliyi ləğv etmək istərdinizmi?",
  Company_Logo:"Şirkət loqosu",
  ZERO_:"SIFIR",
  Disclaimer:"İmtina",
  Privacy_Policy:"Gizlilik Siyasəti",
  EULA:"EULA-nı yoxlayır",
  Terms_Of_Service:"Xidmət Şərtləri",
  Terms_Of_Use:"İstifadə qaydaları",
  Refunds:"Geri qaytarma siyasəti",
  Single_Print:"1 Yoxlayın",
  Two_Prints:"2 Yoxlama",
  Five_Prints:"5 Çek",
  Ten_Prints:"10 Çek",
  Fifteen_Prints:"15 Çek",
  Twenty_Prints:"20 Çek",
  Thirty_Prints:"30 Çek",
  Image_Added:"Şəkil əlavə edildi",
  Image_Preview:"Şəkilə baxış",
  No_Image_Was_Selected:"Heç bir şəkil seçilmədi.",
  Cheques_Unlimited:"Çeklər Limitsizdir",
  _Subscription:"Abunəlik",
  Subscription:"Çeklər - 1 Ay",
  Two_Month_Subscription:"Çeklər - 2 Ay",
  Three_Month_Subscription:"Çeklər - 3 Ay",
  Six_Month_Subscription:"Çeklər - 6 Ay",
  Twelve_Month_Subscription:"Çeklər - 12 Ay",
  Cheques_Are_Available:"Çekləri çap etmək mümkündür.",
  Upgrade_Required_Two:"Paket seçin və alışınıza başlamaq üçün qiymət düyməsinə iki dəfə toxunun. Saniyədə peşəkar görünən tam rəngli çekləri çap edin.",
  Month:"ay",
  Due:"Müddəti:",
  Expires:"Müddəti bitir:",
  Subscription_Active:"Abunəlik Aktivdir",
  Subscription_Inactive:"Abunəlik Qeyri-aktiv",
  Purchase_Additional_Cheques:"Əlavə çeklər alırsınız?",
  Printable_Cheque:"Çap edilə bilən çek",
  Printable_Cheques:"Çap edilə bilən çeklər",
  Printable_Cheque_Word:"çek hesabınızda mövcuddur.",
  Printable_Cheques_Word:"çeklər hesabınızda mövcuddur.",
  Max_Amount_Message:"Göstərdiyiniz məbləğ bu sistem üçün təyin edilmiş maksimum məbləği keçib:",
  Terms_Required_Word:"Çeklərdən istifadə etməyə davam etməzdən əvvəl bu müqavilə ilə razılaşmalısınız.",
  Subscriptions:"Abunəliklər",
  Product_Upgrades:"Təkmilləşdirmələr",
  Mailed_Out_Cheques:"Poçtla göndərilən çeklər",
  Enter_A_Company_Name:"Şirkət adını daxil edin.",
  Single_Cheques:"Tək Çeklər",
  Cheque_Golden:"Qızıl Çek",
  Cheque_Golden_Window:"Qızıl çek dizaynı.",
  Cheque_Green:"Yaşıl çek",
  Cheque_Green_Window:"Yaşıl çek dizaynı.",
  Cheque_Red:"Qırmızı Çek",
  Cheque_Red_Window:"Qırmızı çek dizaynı.",
  Cheque_Yellow:"Sarı Çek",
  Cheque_Yellow_Window:"Sarı çek dizaynı.",
  Cheque_Statue_of_Liberty:"Azadlıq heykəli",
  Cheque_Statue_of_Liberty_Window:"Azadlıq Heykəlinin dizaynını yoxlayın.",
  Cheque_Green_Wave:"Yaşıl Dalğa",
  Cheque_Green_Wave_Window:"Yaşıl çek dizaynı.",
  Cheque_Golden_Weave:"Qızıl Toxuculuq",
  Cheque_Golden_Weave_Window:"Zərif qızıl çek dizaynı.",
  Cheque_Green_Sun:"Yaşıl Günəş",
  Cheque_Green_Sun_Window:"Dərin və sakitləşdirici çek dizaynı.",
  Cheque_Blue_Checkers:"Mavi Dama",
  Cheque_Blue_Checkers_Window:"Mavi çek dizaynı.",
  Cashiers_Check:"Kassir çeki",
  Cashiers_Check_Window:"Kassir çeki üslub şablonu.",
  Cheque_Aqua_Checkers:"Aqua Dama",
  Cheque_Aqua_Checkers_Window:"Aqua çek dizaynı.",
  Cheque_Golden_Checkers:"Qızıl Dama",
  Cheque_Golden_Checkers_Window:"Qızıl çek dizaynı.",
  Upgrade_Unavailable:"Təkmilləşdirmələr Əlçatan deyil",
  Bank_Code_Protection:"Bank nömrəsinin qorunması",
  Bank_Code_Protection_Word:"Bank nömrələrinizin başqa cihazda və ya başqa istifadəçi üçün işləyən bu proqramda istifadə olunmasından qoruyun. Bu hərəkət geri qaytarılmazdır. Davam edilsin?",
  Bank_Code_Protection_Blocked_Word:"İstifadə etməyə çalışdığınız bank nömrələri başqa istifadəçi və ya cihaz üçün qorunur.",
  Bank_Code_Protection_Error_Word:"Nömrənin doğrulanması uğursuz oldu. İnternetə qoşulun və bu bank hesabını yenidən əlavə etməyə cəhd edin.",
  Bank_Code_Protection_Set_Error_Word:"Bank nömrəsinin qorunması internetə qoşulmağınızı tələb edir. Qoşun və yenidən cəhd edin.",
  Upgrade_Unavailable_Word:"Üzr istəyirik, sizi doğrulamaqda çətinlik çəkirik. Abunəliklər və Çeklərə təkmilləşdirmələr hazırda ərazinizdə satın alına bilməz.",
  PayPal_Payment_Preview:"PayPal Ödənişinə Baxış",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"PayPal seçin",
  PayPal_Applications:"PayPal Tətbiqləri",
  Purchase_With_Apple_Pay:"Apple Pay ilə satın alın",
  Google_Pay:"Google Pay",
  Companies:"Şirkətlər",
  Insurance:"Sığorta",
  New_PayPal:"Yeni PayPal",
  Pay_By:"Ödənişlə",
  Insure:"Sığorta etmək",
  Miles:"Miles",
  Payment_Method:"Ödəniş üsulu",
  Select_Policies:"Siyasətləri seçin",
  Policies:"Siyasətlər",
  Policy:"Siyasət",
  No_PayPal_Account:"PayPal Hesabı yoxdur",
  No_Policies:"Sığorta Siyasəti yoxdur",
  New_Policy:"Yeni Siyasət",
  PayPal_Payment:"PayPal Ödənişi",
  PayPal_Payments:"PayPal Ödənişləri",
  No_Payment_Selected:"Ödəniş Seçilməyib",
  Sending_Payment_Word:"Zəhmət olmasa gözləyin... Bu ödəniş göndərilir.",
  Sending_Payments_Word:"Zəhmət olmasa gözləyin... Ödənişlər göndərilir.",
  No_Payment_Selected_PayPal:"<a routerLink='/folder/Payments'>PayPal ödənişi</a> seçilməyib.",
  Payment_Sent:"Ödəniş Göndərilib",
  PayPal_Payment_Sent:"Bu ödəniş PayPal ilə göndərilib.",
  Copay:"Copay",
  Dead:"Ölü",
  Alive:"Canlı",
  Not_Dead:"Ölü deyil",
  Unclaimed:"İddiasız",
  Attempted:"cəhd etdi",
  Deceased:"Ölən",
  Claimed:"İddia edildi",
  Unpaid:"Ödənilməmiş",
  Sending_Payment:"Ödənişin Göndərilməsi",
  Sending_Payments:"Ödənişlərin Göndərilməsi",
  Send_PayPal_Confirmation:"Bu əməliyyatı PayPal ilə göndərmək istəyirsiniz?",
  Send_PayPal_Confirmation_Word:"Bu əməliyyatı göndərmək üçün yaşıl düyməni basın.",
  Save_Payment_As_Unpaid:"Bu ödəniş ödənilməmiş kimi yadda saxlanılsın?",
  Payment_Pay_Confirmation_PayPal:"Bu ödəniş ödənilmiş kimi yadda saxlanılsın?",
  No_Policies_Word:"ilk <a routerLink='/folder/Postage/New'>Sığorta Siyasətini</a> əlavə edin .",
  Timesheet_Multiple_Delete_Confirmation:"Çox vaxt cədvəlini silmək istədiyinizə əminsiniz?",
  Select_Multiple_Timesheets_Prompt:"Heç bir vaxt cədvəli seçilməyib. Ən azı bir vaxt cədvəli seçin.",
  Select_Multiple_Policies_Prompt:"Heç bir siyasət seçilməyib. Ən azı bir sığorta siyasəti seçin.",
  Policies_Multiple_Delete_Confirmation:"Çoxsaylı siyasəti silmək istədiyinizə əminsiniz?",
  Company:"Şirkət",
  Add_Company:"Şirkət əlavə edin",
  New_Company:"Şirkət əlavə edin",
  No_Companies:"Şirkətlər yoxdur",
  Enable_Company:"Şirkəti aktivləşdirin",
  Select_Company:"Şirkəti seçin",
  The_Default_Company:"Standart şirkət etiketi.",
  Manage_Companies:"Şirkətləri idarə edin",
  No_Companies_Word:"Çeklər defolt şirkətdən istifadə edəcək .<br /> <a routerLink='/folder/Company/New'>birinci Şirkəti</a> əlavə edin .",
  Default_Company:"Defolt Şirkət",
  Cheques_Unlimited_Word:"Checks Unlimited sizə istədiyiniz qədər çek çap etməyə imkan verir.",
  Cheques_Subscription_Word:"Çeklər abunəliyi sizə istədiyiniz qədər çek çap etməyə imkan verir.",
  You_Own_This_Product:"Bu məhsulun sahibisiniz.",
  Your_Subscription_is_Active:"Abunəliyiniz aktivdir.",
  Active_Products:"Aktivləşdirilmiş Məhsullar",
  Purchase_Confirmation:"Alış",
  Purchase_Cheques:"Satınalma Çekləri",
  Restore_Purchase:"Satınalmaları bərpa edin",
  Erase_Purchase:"Satınalmaları silin",
  Successfully_Purchased:"Uğurla alınıb",
  Enter_Your_Licence_Key:"Bu məhsulu aktivləşdirmək üçün bu səhifəyə lisenziya açarınızı daxil edin.",
  Licence_Key:"Lisenziya açarı",
  Enter_Licence_Key:"Lisenziya Açarını daxil edin",
  Purchased:"alınıb",
  Enable_Feature:"Xüsusiyyəti aktivləşdirin",
  Cancel_Subscription:"Abunəliyi ləğv edin",
  Subscription_Removed:"Abunəlik Silindi",
  Select_Active_Subscription:"Dəyişdirmək üçün aktiv abunə seçin.",
  Remove_Subscription_Word:"Bu abunəliyi ləğv etmək istədiyinizə əminsiniz?",
  Delete_Company_Confirmation:"Bu şirkəti bütünlüklə silmək istədiyinizə əminsiniz? XƏBƏRDARLIQ: Siz bütün saxlanan məlumatları itirəcəksiniz!",
  Delete_Default_Company_Word:"Siz standart şirkəti silə bilməzsiniz . Tətbiqi sıfırlamaq və onu defolt vəziyyətinə qaytarmaq istərdinizmi? XƏBƏRDARLIQ: Siz bütün saxlanan məlumatları itirəcəksiniz!",
  Console_Warning_2:"Hazırda sizin olmayan bu proqramdan istifadə edərək heç bir valyuta ilə işləməyin.",
  Terms_and_Conditions:"Şərtlər və Qaydalar",
  and_the:"və",
  for:"üçün",
  Please_Read_Word:"Zəhmət olmasa oxuyun və razılaşın",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Bəzi valyuta konvertasiya məzənnələrini tapmaq mümkün olmadı. Zəhmət olmasa internetə qoşulun.",
  Free:"Pulsuz",
  DB_Erase_Prompt_2:"Bütün tərtibatçı verilənlər bazası tamamilə silinsin? XƏBƏRDARLIQ: Siz bütün ALMA və ABUNƏ məlumatlarınızı itirəcəksiniz!",
  Successfully_Imported:"Uğurla idxal edildi",
  Select_Postage:"Poçt seçin",
  No_Postage:"Poçt markaları yoxdur",
  No_Paid_Postage_Word:"İlk <a routerLink='/folder/Postage/New'>Ödənişli Poçt</a> möhürünü əlavə edin .",
  Trial_Complete:'Sınaq Tamamlandı',
  Please_Upgrade:'Çap etməyə davam etmək üçün Çekləri təkmilləşdirin.',
  Aa:"Aa",
  Color:"Rəng",
  Font:"Şrift",
  Guide:"Bələdçi",
  Guides:"Bələdçilər",
  Image:"Şəkil",
  Zoom:"Zoom",
  Logo:"Loqo",
  Bank:"Bank",
  MICR:"MICR",
  Total:"Ümumi",
  Cancel:"Ləğv et",
  Confirm:"Təsdiq edin",
  Method:"Metod",
  Biometric_Security:"Biometrik Təhlükəsizlik",
  Please_Login_To_Continue:"Davam etmək üçün daxil olun.",
  Complete:"Tamamlayın",
  Sign_Up:"Qeydiyyatdan keçmək",
  Error:"Xəta",
  Biometrics:"Biometrika",
  Percent:"Faiz",
  Zero_Percent:"0%",
  Top_Margin:"Üst marja",
  Bottom_Margin:"Aşağı kənar",
  Left_Margin:"Sol kənar",
  Right_Margin:"Sağ kənar",
  MICR_Margin:"MICR Margin",
  Table_Margin:"Cədvəl marjası",
  Address_Margin:"Ünvan marjası",
  Percentage_:"Faiz",
  Vacation_Title:"Tətil adı",
  Use_PIN:"PIN kodu istifadə edin",
  Loading__:"Yüklənir...",
  Design_Title:"Dizayn Başlığı",
  Authorize:"Səlahiyyət verin",
  Key:"Açar",
  Public_Key:"Açıq Açar",
  Private_Key:"Şəxsi Açar",
  Authenticate:"Doğrulayın",
  Last_Payroll:"Son əmək haqqı",
  Last_Calculation:"Son hesablama",
  Authorized:"Səlahiyyətli",
  Geo_Authorized:"Geo-Məkan: Səlahiyyətli",
  Not_Authorized:"Səlahiyyətli deyil",
  Authorization_Complete:"Avtorizasiya Tamamlandı",
  Geolocation_Authorization:"Coğrafi yerin icazəsi",
  Out_of_Bounds:"Həddindən artıq",
  Cant_Delete_Default_Design:"Defolt dizaynı silmək mümkün deyil.",
  Unauthorized_Out_of_Bounds:"İcazəsiz: Həddindən artıq",
  Biometric_Authorization:"Biometrik Avtorizasiya",
  Locating_Please_Wait:"Yerləşir, Zəhmət olmasa gözləyin...",
  Test_Biometrics:"Biometrik testlər",
  Cheque_Margins:"Marjaları yoxlayın",
  Percentage_Full_Error:"Faiz sahəsi 100% faizdən çox təyin edilə bilməz.",
  No_Payroll_Text:"<a routerLink='/folder/Employee/New'>İşçi</a> və ya <a routerLink='/folder/Payee/New'>Alıcı</a> və <a routerLink='/folder/Schedule/New'>Cədvəl</a>.",
  Design_Saved:"Dizayn Saxlanıldı",
  Default_Design_Selected:"Defolt Dizayn Seçildi",
  Partial_Import:"Qismən idxal",
  Partial_Export:"Qismən İxrac",
  Entire_Import:"Bütün İdxal",
  Entire_Export:"Bütün İxrac",
  Existing_Password:"Zəhmət olmasa mövcud parolunuzu daxil edin:",
  Existing_PIN:"Lütfən, mövcud PİN kodunuzu daxil edin:",
  Pin_Change_Header:"PIN Təsdiqi",
  Pin_Change_SubHeader:"Dəyişikliyi təsdiqləmək üçün köhnə PİN nömrənizi daxil edin.",
  Pass_Change_Header:"Parolun Təsdiqi",
  Pass_Change_SubHeader:"Dəyişikliyi təsdiqləmək üçün köhnə parolunuzu daxil edin.",
  PIN_Enter_Message:"Təsdiq etmək üçün PİN kodu daxil edin.",
  Password_Enter_Message:"Təsdiq etmək üçün parolunuzu daxil edin.",
  Pin_Updated_Success:"PIN uğurla yeniləndi!",
  Pin_Updated_Fail:"PIN-i yeniləmək mümkün olmadı.",
  Pass_Updated_Success:"Parol uğurla yeniləndi.",
  Pass_Updated_Fail:"Parol yenilənə bilmədi.",
  Preview_Payment:"Ödənişə ön baxış",
  Preview_Payroll:"Əmək haqqını əvvəlcədən nəzərdən keçirin",
  No_Payments_Created:"Heç bir ödənişin yaradılması tapılmadı.",
  Payment_Preview:"Ödənişin Baxışı",
  Enable_Payee:"Alıcını aktivləşdirin",
  Rendered:"Göstərilən",
  No_Email:"E-poçt yoxdur",
  Setup_Cheques:"Quraşdırma Yoxlamaları",
  name:"ad",
  address:"Ünvan",
  address_2:"Ünvan sətri 2",
  city:"Şəhər",
  province:"Vilayət",
  postal_code:"Poçt/Poçt Kodu",
  country:"ölkə",
  username:"İstifadəçi adı",
  full_name:"Tam adı",
  birthday:"Ad günü",
  email:"E-poçt",
  phone:"Telefon",
  employees:"İşçilər",
  addresses:"Ünvanlar",
  payment_amount_limit:"Ödəniş Məbləği Limiti",
  total_limit:"Ümumi Limit",
  payees:"Ödəniş alanlar",
  description:"Təsvir",
  address_line_one:"Ünvan sətri 1",
  address_line_two:"Ünvan sətri ikinci",
  image:"Şəkil",
  account_holder:"Hesab sahibi",
  cheque_starting_id:"Başlanğıc ID-sini yoxlayın",
  transit_number:"Tranzit Nömrə",
  institution_number:"Təşkilat nömrəsi",
  designation_number:"Təyinat nömrəsi",
  account_number:"Hesab nömrəsi",
  currency:"Valyuta",
  signature:"İmza",
  payment_payroll_limit:"Ödəniş Limiti",
  total_limi:"Ümumi limit",
  date:"Tarix",
  printed_memo:"Çap edilmiş Memo",
  banks:"Banklar",
  signature_image:"İmza Şəkil",
  address_name:"Ünvan Adı",
  notes:"Qeydlər",
  design:"Dizayn",
  title:"Başlıq",
  frequency:"Tezlik",
  fax:"Faks",
  salaries:"Maaşlar",
  salary_ids:"Əmək haqqı vəsiqələri",
  start_time:"Başlama vaxtı",
  end_time:"Bitmə vaxtı",
  paid:"Ödənişli",
  overtime_percentage:"Ödənilmiş Faiz",
  overtime_amount:"Ödənilmiş Sabit Məbləğ",
  first_name:"Ad",
  last_name:"Soyad",
  moderation:"Moderasiya",
  create:"Yaradın",
  edit:"Redaktə et",
  destroy:"Məhv etmək",
  day_start_time:"gün_başlama_vaxtı",
  day_end_time:"günün_axır_vaxtı",
  fullname:"ad",
  time:"Vaxt",
  auto_send:"Avtomatik Göndər",
  time_method:"Zaman Metodu",
  schedules:"Cədvəllər",
  indefinite_payroll_enabled:"Qeyri-müəyyən müddətə aktivləşdirin",
  amount:"Məbləğ",
  repeat:"Təkrarlamaq",
  always_enabled:"Həmişə Aktivdir",
  start_date:"Başlama tarixi",
  end_date:"Bitmə vaxtı",
  Cheque_Total:"Cəmi yoxlayın",
  Total_Amount:"Ümumi miqdar:",
  Amounts:"Məbləğlər:",
  Images:"Şəkillər",
  Files:"Fayllar",
  Previewing:"Önizləmə:",
  Insert:"Daxil et",
  Preview_Import:"İdxaldan əvvəl baxın",
  Entry:"Giriş",
  Entries:"Girişlər",
  No_Entries:"Giriş yoxdur",
  Import_Type:"İdxal növü",
  Select_Details:"Detallar seçin",
  Select_Payee:"Alıcı seçin",
  Enable_Holidays:"Tətilləri aktivləşdirin",
  Disable_Holidays:"Tətilləri söndürün",
  Wire_Transfer:"Tel köçürmə",
  New_Export:"Yeni İxrac",
  Export_Data:"Məlumatları ixrac edin",
  Export_Data_Word:"İxrac etmək və yükləmək üçün fayl növünü seçin.",
  Export_File:"Faylı ixrac edin",
  Mode:"Rejim",
  Times:"Vaxt",
  Widgets:"Vidjetlər",
  Slider:"Sürgü",
  Set_Details:"Təfərrüatları təyin edin",
  Select_Type:"Növü seçin",
  Display_Slider:"Slayderi göstərin",
  Dashboard_Slider:"İdarə paneli kaydırıcısı",
  Dashboard_Mode:"İdarə paneli rejimi",
  Show_Intro:"Girişi göstərin",
  Show_Payrolls:"Əmək haqqı hesabatlarını göstərin",
  Show_Holidays:"Bayramları göstərin",
  Show_Invoices:"Fakturaları göstərin",
  Show_Cheques:"Çekləri göstərin",
  Enabled_Widgets:"Aktivləşdirilmiş Vidjetlər",
  Disabled_Widgets:"Əlil Vidjetlər",
  Colors:"Rənglər",
  Barcodes:"Barkodlar",
  View_Timers:"Taymerlərə baxın",
  Gradients:"Qradientlər",
  No_Info:"Məlumat yoxdur",
  Disable:"Deaktiv edin",
  Show_Layer:"Qatları göstərin",
  Hide_Layer:"Qatları gizlədin",
  Text_Layer:"Mətn qatları",
  Secondly:"İkincisi",
  Minutely:"Dəqiqə",
  nine_am:"9:00",
  five_pm:"17:00",
  Enable_Address:"Ünvanı aktivləşdirin",
  Invalid_File_Type:"Üzr istəyirik, yanlış fayl növü seçilib. Dəstəklənən fayl növü:",
  Error_Updating_Entry:"Üzr istəyirik, bu girişi yeniləyərkən xəta baş verdi.",
  Schedule_Timing_Alert:"Xəta: Cədvəlin başlama vaxtı bitmə vaxtından sonrakı dəyərə təyin edilib.",
  Select_Multiple_Payments_Prompt:"Heç bir ödəniş seçilməyib. Ən azı bir ödəniş seçin.",
  Select_Multiple_Cheques_Prompt:"Heç bir çek seçilməyib. Ən azı bir çek seçin.",
  Select_Multiple_Items_Prompt:"Element seçilməyib. Ən azı bir element seçin.",
  Paymemt_Multiple_Delete_Confirmation:"Çoxsaylı ödənişləri silmək istədiyinizə əminsiniz?",
  Cheque_Multiple_Delete_Confirmation:"Çoxsaylı çekləri silmək istədiyinizə əminsiniz?",
  Payee_Multiple_Delete_Confirmation:"Çoxlu ödəyiciləri silmək istədiyinizə əminsiniz?",
  Employee_Multiple_Delete_Confirmation:"Birdən çox işçini silmək istədiyinizə əminsiniz?",
  MICR_Warning:"Qeyd: Bəzi printerlər və cihazlar MICR şriftini düzgün göstərməyə bilər.",
  Automatically_Send:"Avtomatik Göndər",
  Type:"Növ",
  Payment_Type:"Ödəniş növü",
  Auto_Send:"Avtomatik Göndər",
  Automatically_Process:"Avtomatik Proses",
  Auto_Process:"Avtomatik Proses",
  Image_Based:"Şəkil əsaslı",
  Font_Based:"Şrift əsaslı",
  Rerender:"Yenidən göstərin",
  Rendering:"Rendering",
  Render:"Fayllar",
  Top:"Üst",
  Middle:"Orta",
  Bottom:"Aşağı",
  Top_Left:"Yuxarı Sol",
  Top_Center:"Üst Mərkəz",
  Top_Right:"Yuxarı Sağ",
  Middle_Left:"Orta Sol",
  Middle_Center:"Orta Mərkəz",
  Middle_Right:"Orta sağ",
  Bottom_Left:"Aşağı Sol",
  Bottom_Center:"Aşağı Mərkəz",
  Bottom_Right:"Aşağı Sağ",
  Numbers:"Nömrələri",
  Verified:"Doğrulanıb",
  Paper_Size:"Kağız Ölçüsü",
  New_Device:"Yeni Cihaz",
  Add_Device:"Cihaz əlavə edin",
  Remove_Device:"Cihazı Sil",
  Delete_Device:"Cihazı silin",
  Block_Device:"Blok Cihazı",
  Block:"Blok",
  Unblock:"Blokdan çıxarın",
  Table:"Cədvəl",
  Scan_Login_Code:"Giriş kodunu skan edin",
  Login_Code:"Giriş kodu",
  Scan_Code:"Skan kodu",
  Scan_QR_Code:"QR kodunu skan edin",
  Select_All:"Hamısını seç",
  Deselect_All:"Bütün seçimləri ləğv et",
  Increase:"Artırmaq",
  Decrease:"Azaltmaq",
  Bold:"Qalın",
  Text:"Mətn",
  Style:"Stil",
  Italic:"kursiv",
  QR_Code:"QR kodu",
  Barcode:"Barkod",
  Browse_Images:"Şəkillərə Baxın",
  Browse_Files:"Faylları nəzərdən keçirin",
  Background_Image:"Fon Şəkli",
  Logo_Image:"Logo Şəkli",
  Header_Image:"Başlıq şəkli",
  Bank_Image:"Bank şəkli",
  Cut_Lines:"Xətləri kəsin",
  Background:"Fon",
  License:"Lisenziya",
  One_License:"1 Lisenziya:",
  Licensed:"Lisenziyalı:",
  Not_Licensed:"Lisenziyalı deyil",
  Enter_Serial:"Seriala daxil olun",
  Serial_Key:"Serial açarı",
  Serial:"Serial",
  Product_Key:"Məhsul Açarı",
  Check_Product_Key:"Məhsul Açarını yoxlayın",
  Add_Product_Key:"Məhsul Açarı əlavə edin",
  Verifying_Purchase:"Satınalma təsdiqlənir...",
  Print_Envelope:"Zərfi çap edin",
  Envelope:"Zərf",
  Thank_You:"Çox sağ ol!",
  Scale:"Ölçək",
  Print_Scale:"Çap Ölçüsü",
  Borders:"Sərhədlər",
  VOID:"KEÇMİŞ",
  Void_Cheque:"Yoxlamanı ləğv edin",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"SİFARİŞƏ ÖDƏNİN:",
  NO_DOLLARS:"DOLLAR YOXDUR ",
  ONE_DOLLAR:"BİR DOLLAR",
  DOLLARS:" DOLLAR",
  AND:" VƏ ",
  CENTS:"QƏPIK.",
  NO_:"YOX ",
  ONE_:"BİR ",
  AND_NO_:"VƏ YOX ",
  _AND_ONE_:"VƏ BİR ",
  DOLLARS_AND_ONE_CENT:" VƏ BİR SENT.",
  AND_NO_CENTS:" VƏ SIFIR SENT.",
  CHEQUE_PRINT_DATE:"TARİX:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"Millət vəkili",
  Initial_Tasks:"İlkin tapşırıqlar",
  Inital_Setup:"İlkin Quraşdırma",
  Welcome_To:"Xoş gəlmisiniz",
  Welcome:"Xoş gəlmisiniz",
  Swipe:"Çaldırmaq",
  Intro_Setup:"Giriş Quraşdırma",
  Introduction:"Giriş",
  CHEQUE_PRINT_CREDIT:"Çeklərlə təchiz edilmişdir",
  Title_Intro_Word:"Çeklərə xoş gəlmisiniz",
  Title_Intro:"Çeklərin təqdimatı",
  Title_Setup:"Çeklərin qurulması",
  PayPal_Default_Email_Message:"Siz yeni PayPal köçürməsi aldınız. [Çeklər tərəfindən dəstəklənir]",
  Cheques_App_Export:"Çeklərlə ixrac olunur",
  Post_Intro_Thanks:"Çekləri seçdiyiniz üçün təşəkkür edirik. Quraşdırma prosesi artıq tamamlandı.",
  Post_Intro_Word:"İlk çekinizi çap etməklə başlayın, gələcək ödəniş əlavə edin və ya əmək haqqı siyahısına işçi əlavə edin.",
  Upgrade_Required:"Çeklər bu mesajı gizlətmək və əlavə funksiyaların kilidini açmaq üçün proqram təminatının daha premium versiyasına sahib olmanızı tələb edir.",
  Upgrade_Title:"Çeklər",
  Mailout_Prompt:"Əlavə olaraq, sizin üçün əmək haqqı çeklərinizi poçtla göndərməyi seçə bilərsiniz.",
  Mailed_Cheque:"Poçtla göndərilmiş çek:",
  Mailed_Cheque_Color:"Poçtla göndərilmiş çek (rəng):",
  Terms_Purchase:"Çek ilə bütün elektron alışlar alış tarixindən etibarən 30 günə qədər tam şəkildə geri qaytarılır. Davam etməzdən əvvəl <a href='#'>Qaydalar və Şərtləri</a> oxuyun və razılaşın.",
  Dashboard_Setup:"İlkin printeri quraşdırın",
  Dashboard_Add:"Əsas Bank Hesabını əlavə et",
  Dashboard_Customize:"Yoxlama Şablonunu Seçin",
  Dashboard_Job_Salary:"İşinizi yaradın və maaşınızı əlavə edin",
  Dashboard_Employees:"İşçiləri və Ödənişçiləri İzləyin",
  Dashboard_Print:"Maaş çeklərinizi çap edin və göndərin",
  Dashboard_Payroll:"Əmək haqqı çapını avtomatlaşdırın",
  Dashboard_PayPal:"PayPal Əmək haqqı / Ödənişləri quraşdırın",
  Begin_Setup:"Quraşdırmağa başlayın",
  Get_Started:"Başlamaq",
  Purchase:"Alış",
  Lockdown:"Kilidləmə",
  Unlock:"Kilidi aç",
  Detailed:"Ətraflı",
  Log_In:"Daxil ol",
  Login:"Daxil ol",
  Launch:"Başlat",
  Register:"Qeydiyyatdan keçin",
  Finish:"Bitir",
  List:"Siyahı",
  Weekends:"Həftə sonları",
  Weekly:"Həftəlik",
  PayPal_Default_Subject:"Yeni Ödəniş",
  Payment_Message:"Ödəniş mesajı",
  PayPal_Default_Payment_Note:"Çox sağ ol",
  Setup_Your_Cheqing_Account:"Hesabın yoxlanılması",
  Add_Your_Primary_Cheqing_Account:"Əsas yoxlama hesabınızı əlavə edin.",
  Welcome_Word:"Əmək haqqı və insan resurslarının idarə edilməsini sadələşdirin və avtomatlaşdırın.",
  Get_Started_Quickly:"Başlamağa kömək edəcək bir neçə sadə suala cavab verin...",
  Done_Intro_Word:"Quraşdırma tamamlandı",
  PIN_Protected:"Parol və PİN qorunur",
  Enter_New_PIN:"Yeni PİN kodu daxil edin:",
  Enter_PIN:"PİN kodu daxil edin:",
  Invalid_PIN:"Yanlış PIN daxil edilib.",
  Account_Identifier:"Hesab identifikatoru",
  New_Account_Identifier:"Yeni hesab identifikatoru",
  attempt:"cəhd",
  attempts:"cəhdlər",
  remaining:"qalan",
  Language:"Dil",
  languages:"Dillər",
  select_languages:"Dil seçin",
  Deposit:"Depozit",
  Hire_One_Now:"İndi birini işə götür",
  App_Locked:"Tətbiq kilidlənib.",
  Skip_:"keç",
  Skip_to_Dashboard:"İdarə panelinə keçin",
  Dashboard:"İdarə paneli",
  Import:"İdxal",
  Admin:"İdarəçilər",
  New_Admin:"Yeni Admin",
  Settings:"Parametrlər",
  Color_Picker:"Rəng seçici",
  Font_Picker:"Şrift Seçici",
  Logout:"Çıxış",
  Close:"Yaxın",
  Close_menu:"Yaxın",
  Excel:"Excel faylı",
  Csv:"CSV faylı",
  Sql:"SQL faylı",
  Json:"JSON faylı",
  Url:"URL ilə idxal",
  Back:"Geri",
  Timers:"Taymerlər",
  Cheque:"Yoxlama",
  Print:"Çap",
  Designs:"Dizaynlar",
  Pause_Printing:"Çapı dayandırın",
  Resume_Printing:"Çapı davam etdirin",
  Printing_Paused:"Çap dayandırıldı",
  PrintingUnavailable:"Bağışlayın! Bu sistemdə çap etmək mümkün deyil.",
  Prints_Paused:"Çaplar dayandırıldı",
  Administration:"İdarəetmə",
  Loading:"Yüklənir",
  Unnamed:"Adsız",
  error:"Bağışlayın, bu çeki baxmaq üçün açmaq mümkün olmadı.",
  No_Cheques_To_Print:"Çap etmək üçün çek yoxdur",
  No_Cheques_To_Print_Word:"<a routerLink='/folder/Cheque/New'>Yeni Çek</a> yaradın.",
  New_Cheque:"Yeni Çek",
  Start_One_Now:"İndi başla",
  Edit_Cheque:"Çoxu redaktə et",
  Select_Cheques:"Çekləri seçin",
  Select_Employee:"İşçini seçin",
  Default_Printer:"Defolt printer",
  Reassign:"Yenidən təyin",
  Configure:"Konfiqurasiya",
  Template:"Şablon",
  Designer:"Dizayner",
  Edit_Designs:"Dizaynları redaktə et",
  New_Design:"Yeni dizayn",
  Next:"Növbəti",
  Save:"Yadda saxla",
  Name:"Ad",
  Mail:"Poçt",
  Amount:"Məbləğ",
  Date:"Tarix",
  PayPal:"PayPal",
  Payouts:"Ödənişlər",
  PayPal_Label:"PayPal Etiketi",
  Email_Username:"E-poçt / İstifadəçi adı",
  Client_ID:"Müştəri ID",
  Sandbox_Email:"Sandbox E-poçt",
  PayPal_Email:"PayPal E-poçtu",
  PayPal_Username:"API İstifadəçi adı",
  PayPal_Payouts:"PayPal ödənişləri",
  Select_PayPal_Key:"PayPal Açarını seçin",
  Secret:"Gizli",
  API_Secret:"API sirri",
  PayPal_API_Keys:"PayPal Açarları",
  New_PayPal_Key:"Yeni PayPal Açarı",
  Email_Subject:"E-poçt mövzusu",
  Email_Message:"E-poçt mesajı",
  Payout_Options:"Ödəniş Seçimləri",
  Payment_Note:"Ödəniş qeydi",
  Enable_Employee:"İşçini işə salın",
  Enable_Production_Mode:"İstehsal rejimini aktivləşdir",
  Sandbox_Username:"Sandbox istifadəçi adı",
  Sandbox_Signature:"Sandbox İmzası",
  Sandbox_Password:"Sandbox Parol",
  Production_Username:"İstehsal istifadəçi adı",
  Production_Signature:"İstehsal imzası",
  Production_Password:"İstehsal parolu",
  Production_Email:"İstehsal e-poçtu",
  API_Client_ID:"API Client ID",
  API_Signature:"API İmzası",
  API_Password:"API Parol",
  API_Username:"API İstifadəçi adı",
  Secret_Key:"Gizli açar",
  Sandbox:"Qum qutusu",
  Production:"İstehsal",
  Sandbox_Keys:"Sandbox Açarları",
  Production_Keys:"İstehsal açarları",
  API_Title:"API Başlığı",
  Production_Mode:"İstehsal rejimi",
  Account_Label:"Hesab etiketi",
  No_PayPal_Setup:"PayPal Açarı yoxdur",
  Enable_PayPal_Account:"PayPal Hesabını aktivləşdirin",
  No_PayPal_Word:"<a routerLink='/folder/Invoice/New'>PayPal API Açarını</a> əlavə edin.",
  Printed_Memo:"Çap edilmiş Memo",
  Employee:"İşçi",
  View_Employee:"İşçiyə baxın",
  Mailing_Address:"Poçt ünvanı",
  Company_Address:"Şirkət ünvanı",
  Select_Company_Address:"Şirkət ünvanını seçin",
  Address:"Ünvan",
  Any_Day:"Hər gün",
  Address_Name:"Ünvan adı",
  Unit:"vahid",
  Account:"Hesab",
  Bank_Account:"Bank hesabı",
  Account_Limits:"Hesab Limitlərini Aktivləşdir",
  Payroll:"Əmək haqqı",
  New_Payroll:"Yeni əmək haqqı",
  No_Payroll:"Gələcək əmək haqqı yoxdur",
  Upcoming_Holiday:"Qarşıdan gələn bayram:",
  Invoice_Due:"Gələcək faktura:",
  New_Invoice:"Yeni faktura",
  No_Invoices:"Heç bir faktura yoxdur",
  No_Invoices_Word:"İlk <a routerLink='/folder/Invoice/New'>Faktura</a> yaradın.",
  Cheque_Due:"Ödəmə vaxtı olan çek:",
  Payrolls:"Əmək haqqı",
  Sandbox_Mode:"Sandbox rejimi",
  Hire:"İcarə",
  Pay:"Ödəniş",
  New:"Yeni",
  Add:"Əlavə et",
  Make:"etmək",
  Time:"Zaman",
  Write:"Yaz",
  Holiday:"Bayram",
  Holidays:"Bayramlar",
  Next_Holiday:"Növbəti Tətil:",
  All_Done:"Hər şey hazırdır!",
  Employees:"İşçilər",
  Payees:"Ödənişçilər",
  Job:"İş",
  Jobs:"İşlər",
  Invoice:"Faktura",
  Invoices:"Qaimə-fakturalar",
  Vacations:"Tətillər",
  Cheques:"Yoxlamalar",
  Brand_Cheques:"Brend",
  Payment:"Ödəniş",
  Enable_Payment:"Ödənişi aktivləşdir",
  Payments:"Ödənişlər",
  Schedule:"Cədvəl",
  Schedules:"Cədvəllər",
  Timesheet:"Vaxt cədvəli",
  Timesheets:"Vaxt cədvəli",
  Salary:"Maaş",
  New_Address:"Yeni ünvan",
  Address_2:"Ünvan (sətir 2)",
  _City:"Şəhər",
  _State:"Dövlət / Prov",
  City:"Şəhər / qəsəbə",
  State:"Ştat / Vilayət",
  Postal:"Poçt / Poçt Kodu",
  ZIP:"Poçt / ZIP",
  Country:"Ölkə",
  Addresses:"Ünvanlar",
  Required_Options:"Tələb olunan Seçimlər",
  Optional_Options:"Könüllü Seçimlər",
  Additional_Options:"Əlavə Seçimlər",
  Required:"Tələb olunur",
  Optional:"Könüllü",
  Additional:"Əlavə",
  No_Addresses:"Ünvan yoxdur",
  New_Address_Word:"İlk <a routerLink='/folder/Address/New'>Ünvanı</a> əlavə edin.",
  Select_Address:"Ünvan seçin",
  No_Address:"Ünvan yoxdur",
  Print_Cheque:"Çap yoxlayın",
  Print_Cheque_Now:"İndi Çap Yoxlayın",
  Description:"Təsvir",
  Pay_To_The_Order_Of:"Sifarişlə ödəyin:",
  Select_Date_Range:"Tarix Aralığını Seçin",
  Select_Starting_Date:"Başlama tarixini seçin",
  Select_Ending_Date:"Bitmə tarixini seçin",
  Select_Date:"Tarixi seçin",
  Cheque_Date:"Yoxlama tarixi",
  Cheque_Memo:"Yaddaşı yoxlayın",
  Blank_Cheque:"Boş çek",
  Blank:"Boş",
  No_Cheques:"Çek yoxdur",
  No_Cheques_Word:"İlk <a routerLink='/folder/Cheque/New'>Yoxlamanızı</a> çap edin.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"Şəkilə bax",
  View:"Görünüş",
  Modify:"Dəyişdirmək",
  Delete:"Sil",
  Cheque_Paid:"Ödənişli",
  New_Deduction:"Yeni çıxılma",
  Title:"Başlıq",
  Frequency:"Tezlik",
  Hourly:"Saatlıq",
  Daily:"Gündəlik",
  Weekdays:"Həftə içi",
  BiWeekly:"2 həftə",
  TriWeekly:"3 həftə",
  Monthly:"Aylıq",
  MiMonthly:"2 ay",
  Quarterly:"Rüblük",
  Yearly:"İllik",
  Every_Week:"Hər həftə",
  Every_Payroll:"Hər əmək haqqı",
  Every_Month:"Hər ay",
  Annually:"Hər il",
  Always_Scheduled:"Həmişə planlaşdırılıb",
  Start_Date:"Başlama tarixi",
  End_Date:"Bitmə vaxtı",
  Start_Time:"Başlama vaxtı",
  End_Time:"Son vaxt",
  Deduction_Label:"Çıxarma etiketi",
  Notes:"Qeydlər",
  Options:"Seçimlər",
  Enable:"Enable",
  Select_Deductions:"Açıqlamaları seçin",
  Deductions:"Çıxarmalar",
  No_Deductions:"Çıxılma yoxdur",
  No_Deductions_Word:"İlk <a routerLink='/folder/Deduction/New'>Deduksiyanızı</a> yaradın.",
  New_Employee:"Yeni İşçi",
  No_Title:"Başlıq yoxdur",
  _Name:"Ad",
  About_Yourself:"Özün haqqında",
  Full_Name:"Tam adı",
  Birthday:"Ad günü",
  Email:"E-poçt",
  SMS:"SMS",
  Phone:"Telefon",
  Test:"Sınaq",
  Call:"Zəng",
  Fax:"Faks",
  Printed_Note:"Çap edilmiş qeyd",
  Position:"Mövqe",
  Job_Position:"İş yeri",
  Select_a_Job:"İş seçin",
  Select_a_Salary:"Maaş seçin",
  Add_a_Deduction:"Çıxarma əlavə et",
  Taxes:"Vergilər",
  Add_Taxes:"Vergilər əlavə et",
  Date_of_Birth:"Doğum tarixi",
  Email_Address:"E-poçt ünvanı",
  Phone_Number:"Telefon nömrəsi",
  Phone_Call:"Telefon zəngi",
  Enable_on_Payroll:"Əmək haqqını aktivləşdir",
  Select_Employees:"İşçiləri seçin",
  No_Employees:"İşçi yoxdur",
  No_Employees_Word:"İlk yeni <a routerLink='/folder/Employee/New'>İşçinizi</a> əlavə edin.",
  No_Name:"Ad yoxdur",
  Unemployeed:"İşsiz",
  Employeed:"Məşğul",
  Paid:"Ödənişli",
  Enabled:"Aktivdir",
  Disabled:"Əlil",
  Fire:"Yanğın",
  Not_Available:"Mövcud deyil",
  Not_Available_Word:"İlk <a routerLink='/folder/Invoice/New'>Faktura</a>-ni çap edin və ödəniş alın.",
  Select_Invoices:"Faturaları_Seç",
  Print_Invoice_Word:"İlk <a routerLink='/folder/Invoice/New'>Faktura</a>-ni çap edin və ödəniş alın.",
  Invoice_Title:"Qaimə-fakturanın adı",
  Invoice_Date:"Qaimə-faktura tarixi",
  Due_Date:"Bitiş tarixi, son tarix",
  New_Job:"Yeni iş",
  Details:"Təfərrüatlar",
  Customize:"Özəlləşdirmək",
  Customize_Dashboard:"İdarə panelini fərdiləşdirin",
  Components:"Komponentlər",
  No_Components:"Heç bir komponent yoxdur",
  Main_Components:"Əsas komponentlər",
  Smaller_Components:"Kiçik komponentlər",
  Error_Loading_Page:"Bu səhifəni yükləmə xətası.",
  Bank_Details:"Bank məlumatları",
  About_Your_Job:"İşiniz haqqında",
  Your_Schedule:"Cədvəliniz",
  Job_Title:"Vəzifə",
  Job_Description:"İşin təsviri",
  Job_Details:"İş təfərrüatları",
  Enable_Job:"İşi aktivləşdir",
  Pay_Period:"Ödəniş müddəti",
  Perpetually_Schedule:"Daimi cədvəl",
  Select_Jobs:"İşləri seçin",
  No_Jobs:"İş yoxdur",
  Add_Jobs:"İşlər əlavə et",
  No_Jobs_Word:"Siyahıya ilk <a routerLink='/folder/Job/New'>İşi</a> əlavə edin.",
  Count_Employees:"job.employee_count+' İşçilər'",
  Zero_Employees:"0 İşçi",
  New_Leave:"Yeni məzuniyyət",
  Leave_Name:"Adını buraxın",
  Paid_Leave:"Ödənişli məzuniyyət",
  Leave_Pay:"Ödənişdən çıxın",
  Indefinite_Leave:"Qeyri-müəyyən məzuniyyət",
  Indefinite_Payroll:"Qeyri-müəyyən əmək haqqı",
  Leave:"Tərk et",
  Add_Schedules:"Cədvəllər əlavə et",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Yoxluğu aktivləşdir",
  Select_Leaves:"Yarpaqları seçin",
  No_Leaves:"Qiymət vərəqələri yoxdur",
  Leave_Of_Absence:"Məzuniyyət",
  Leaves_Of_Absence:"Yoxluq vərəqələri",
  New_Leave_of_Absense:"Yeni məzuniyyət",
  No_Leaves_Word:"İlk <a routerLink='/folder/Leave/New'>Məzuniyyəti</a> əlavə edin.",
  Not_Enabled:"Aktiv deyil",
  Absences:"Qeyd etmələr",
  Payee:"Alıcı",
  New_Payee:"Yeni Alıcı",
  Payee_Identifier:"Alıcı İdentifikatoru",
  Payee_Name:"Alıcı adı",
  Payee_Title:"Alıcı adı",
  Payment_Memo:"Ödəniş Memo",
  Select_Payees:"Ödəyiciləri seçin",
  No_Payees:"Ödəniş yoxdur",
  Add_Payee_Note:"İlk <a routerLink='/folder/Payee/New'>Alıcı</a> əlavə edin.",
  New_Payees:"Yeni Ödənişçilər",
  About_The_Payment_Schedule:"Ödəniş cədvəli",
  Your_Payroll_Schedule:"Avtomatik əmək haqqı",
  New_Payment:"Yeni Ödəniş",
  Identifier:"İdentifikator",
  Select:"Seç",
  Memo:"Yaddaş",
  Payment_Date:"Ödəniş tarixi",
  Mark_as_Paid:"Ödənilmiş kimi qeyd et",
  Select_Payments:"Ödənişləri seçin",
  No_Payments:"Ödəniş yoxdur",
  No_Payments_Word:"İlk <a routerLink='/folder/Payment/New'>Ödəniş</a> yaradın.",
  Create_Payroll:"Əmək haqqı yaradın",
  Properties:"Xüsusiyyətlər",
  Payroll_Title:"Əmək haqqı adı",
  Payroll_Notes:"Əmək haqqı qeydləri",
  Payroll_Setup:"Əmək haqqının qurulması",
  Tabulate_Payments:"Ödənişlər cədvəli",
  Tabulate:"Cədvəl",
  By:"Tərəfindən:",
  Payments_By:"Ödənişlər",
  Timesheets_And_Schedules:"Vaxt cədvəlləri və cədvəllər",
  Both:"Hər ikisi",
  Items:"Əşyalar",
  Add_Payees:"Ödənişçiləri əlavə et",
  Add_Account:"Hesab əlavə et",
  Enable_Account:"Hesabı aktivləşdir",
  Enable_Payroll:"Əmək haqqını aktivləşdir",
  Print_Payroll:"Əmək haqqını çap edin",
  No_Payrolls:"Əmək haqqı yoxdur",
  No_Payroll_Word:"İlk <a routerLink='/folder/Payroll/New'>Əmək haqqı</a>-nı yaradın.",
  View_more:"MORE_MORE",
  Less:"AZAL",
  Add_Payroll:"Əmək haqqı əlavə et",
  Select_Payroll:"Əmək haqqını seçin",
  About_Your_Salary:"Maaşınız haqqında",
  Add_Salaries:"Maaşları əlavə et",
  Add_Salary:"Maaş əlavə et",
  Salaries:"Maaşlar",
  No_Salaries:"Maaş yoxdur",
  No_Salaries_Word:"İlk <a routerLink='/folder/Salary/New'>Əmək haqqı</a> əlavə edin.",
  Select_Salaries:"Maaşları seçin",
  New_Salary:"Yeni maaş",
  Salary_Name:"Əmək haqqı identifikatoru",
  Enable_Salary:"Əmək haqqını aktivləşdir",
  Salary_Amount:"Əmək haqqının məbləği",
  New_Schedule:"Yeni cədvəl",
  Schedule_Title:"Cədvəlin adı",
  Add_Address:"Ünvan əlavə et",
  Repeat:"Təkrarlamaq",
  Design:"Dizayn",
  Edit_Design:"Dizaynı redaktə et",
  Edit_this_Design:"Bu dizaynı redaktə et",
  Repeat_Payment:"Təkrar ödəniş",
  Enable_Schedule:"Cədvəli aktivləşdir",
  Never:"Heç vaxt",
  Select_Schedule:"Cədvəlləri seçin",
  No_Schedules:"Cədvəl yoxdur",
  No_Schedules_Word:"İlk <a routerLink='/folder/Schedule/New'>Cədvəli</a> yaradın.",
  New_Administrator:"Yeni İdarəçi",
  Username:"İstifadəçi adı",
  First_Name:"Ad",
  Last_Name:"Soyad",
  Add_an_Address:"Ünvan əlavə et",
  Payment_Limit:"Ödəniş üzrə Limit",
  Total_Limit:"Ümumi limit",
  Select_Accounts:"Hesabları seçin",
  No_Administrators:"İdarəçilər yoxdur",
  No_Administrators_Word:"İlk <a routerLink='/folder/Administrator/New'>Admin Hesabını</a> yaradın.",
  New_Administrators_Word:"İlk <a routerLink='/folder/Administrator/New'>Administrator</a> əlavə edin",
  Cloud:"Bulud",
  Backup:"Yedək",
  Enable_iCloud:"iCloud-u aktivləşdir",
  Enable_Google_Drive:"Google Diski aktivləşdir",
  Enable_Microsoft_OneDrive:"Microsoft OneDrive-ı aktivləşdirin",
  Automatically_Backup:"Avtomatik Yedəkləmə",
  FTP_Settings:"FTP Parametrləri",
  URL_File_Prompt:"İdxal etmək üçün .xls / .xlsx / .json faylı üçün yeri göstərin:",
  URL_SQL_Prompt:"İdxal etmək üçün SQLite faylının yerini göstərin:",
  FTP_Backup:"FTP Yedəkləmə",
  FTP_Import:"FTP İdxal",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Ev sahibi",
  Port:"Liman",
  Path:"Yol",
  Data_Path:"Məlumat yolu",
  Enable_FTP_Account:"FTP Hesabını aktivləşdir",
  HostnameIP:"Host adı",
  Password:"Parol",
  Connection_Port:"Bağlantı Portu",
  Enable_MySQL_Database:"MySQL verilənlər bazasını aktivləşdirin",
  Log:"Gündəlik",
  Reset:"Sıfırla",
  Erase:"Sil",
  Export:"İxrac",
  Database:"Məlumat bazası",
  Upload_CSV:"CSV yükləyin",
  Download_CSV:"CSV yükləyin",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL Yedəkləmə",
  Internal_Notes:"Daxili qeydlər",
  Root_Path:"Kök yolu",
  Select_Backup:"Yedəkləmə seçin",
  Add_New_Backup:"Yeni Yedək əlavə et",
  First_Backup:"İlk ehtiyat nüsxəsini qurun...",
  Backups:"Yedəkləmələr",
  Add_Backup:"Yedək əlavə et",
  No_Backups:"Tapılacaq ehtiyat nüsxələri yoxdur.",
  Select_Backup_Type:"Quraşdırmaq istədiyiniz ehtiyat nüsxə növünü seçin...",
  Backup_Type:"Yedək Növü",
  FTP_Drive:"FTP Diski",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Sürüş",
  Microsoft_OneDrive:"Sürüş",
  Import_Fields:"İdxal Sahələri",
  Import_Fields_Word:"<a routerLink='/folder/Holiday/New'>İdxal</a> datasını seçmək üçün bu bölmədən istifadə edin.",
  Upload:"Yükləmək",
  Download:"Yükləmə",
  Download_JSON:"JSON Data kimi endirin",
  Download_SQL:"SQL faylı kimi endirin",
  New_Bank:"Yeni Bank",
  New_Account:"Yeni hesab",
  New_Bank_Account:"Yeni Bank Hesabı",
  Upload_Image:"Şəkil yüklə",
  Bank_Name:"Bank adı",
  Bank_Address:"Bank ünvanı",
  Branch_Address:"Filial ünvanı",
  Address_on_Cheque:"Ünvan",
  Cheque_Numbers:"Nömrələri yoxlayın",
  Cheque_Details:"Detalları yoxlayın",
  Bank_Logo:"Bank loqosu",
  Cheque_ID:"İdentifikatoru yoxlayın",
  Starting_Cheque_ID:"Yoxlama ID-si başlayır",
  Transit_Number:"Tranzit nömrəsi",
  Institution_Number:"Təşkilat nömrəsi",
  Designation_Number:"Təyin nömrəsi",
  Account_Number:"Hesab nömrəsi",
  Limits:"Limitlər",
  Default_Limits:"Defolt Limitlər",
  Over_Limit:"Həddindən artıq",
  Under_Limit:"Limit altında",
  Payroll_Limit:"Əmək haqqı limiti",
  Enable_Bank_Account:"Bank Hesabını aktivləşdir",
  Select_Account:"Hesab seçin",
  No_Bank_Account:"Bank hesabı yoxdur",
  No_Bank_Account_Word:"İlk <a routerLink='/folder/Accounts/New'>Bank Hesabını</a> əlavə edin.",
  Bank_Accounts:"Bank hesabları",
  No_Accounts:"Hesab yoxdur",
  No_Accounts_Note:"İlk <a routerLink='/folder/Accounts/New'>Bank Hesabını</a> əlavə edin.",
  Cheque_Designer:"Dizayneri yoxlayın",
  Cheque_Design:"Dizayn yoxlayın",
  Select_Design:"Dizayn seçin",
  Cheque_Designs:"Dizaynları yoxlayın",
  No_Cheque_Designs:"Çek dizaynları yoxdur",
  No_Cheque_Designs_Word:"Öz <a routerLink='/folder/Settings/Cheque/Design/New'>Dizaynı yoxlayın</a>-ı yaradın.",
  Set_Default:"Defolt olaraq təyin et",
  Default:"Defolt",
  Remove:"Sil",
  Folder:"Qovluq",
  Edit:"redaktə etmək",
  LoadingDots:"Yüklənir...",
  Add_a_New_File:"<a href='#' (click)='add()'>Yeni Fayl</a> əlavə edin",
  this_folder:"bu qovluq",
  FTP_Backup_Settings:"FTP Yedəkləmə Parametrləri",
  Secure_File_Transfer:"Təhlükəsiz Fayl Transferi",
  New_Holiday:"Yeni bayram",
  Add_Holiday:"Bayram əlavə et",
  Holiday_Name:"Bayram adı",
  Additional_Pay:"Əlavə ödəniş",
  Enable_Holiday:"Bayramı aktivləşdir",
  Select_Holidays:"Bayramları seçin",
  No_Holidays:"Bayram yoxdur",
  No_Holidays_Word:"İlk <a routerLink='/folder/Holiday/New'>İctimai Tətil</a> əlavə edin.",
  New_Import:"Yeni İdxal",
  Import_Data:"Məlumatları idxal et",
  Import_Data_Word:"Seçdiyiniz fayl növünü və ya yükləmə metodunu seçin.<br /> Siz dəstəklənən faylı yüklədikdən sonra faylda hansı idxal edilmiş sahələrin tətbiqdə hansı parametrə uyğun olduğunu seçə biləcəksiniz .", 
  Import_File:"Faylı idxal et",
  Link_To_File:"Fayl üçün keçid",
  Select_File:"Fayl seçin",
  New_Moderator:"Yeni Moderator",
  Allow_Moderation:"Moderasiyaya icazə verin",
  Create_Paycheques:"Maaş çekləri yaradın",
  Edit_Paycheques_and_Data:"Maaş çeklərini və məlumatları redaktə et",
  Destroy_Data_and_Paycheques:"Məlumatları və maaşları məhv edin",
  Bonus_Percentage:"Maaş faizi",
  Fixed_Amount:"Sabit məbləğ",
  Select_Moderator:"Moderatoru seçin",
  No_Moderators:"Moderator yoxdur",
  Moderators:"Moderatorlar",
  Moderator_Account:"İlk <a routerLink='/folder/Administrator/New'>Moderator Hesabını</a> yaradın.",
  No_Moderators_Word:"İlk <a routerLink='/folder/Administrator/New'>Moderator</a> əlavə edin.",
  Defaults:"Defolt",
  Provide_Us_Info:"Bizə məlumat verin",
  Setup_Your_Printer:"Printerinizi Quraşdırın",
  Your_Company:"Şirkətiniz haqqında",
  Company_Name:"Şirkətin adı",
  Currency:"Valyuta",
  Default_Currency:"Defolt valyuta",
  Base_Monthly_Income:"Aylıq gəlir",
  Protection:"Mühafizə",
  App_Protection:"Tətbiq Mühafizəsi",
  PIN_Code_Protection:"PIN Kodun Mühafizəsi",
  App_Protection_Word:"Hesabınızı qorumağa kömək edən təhlükəsizlik üsullarını aktivləşdirin.",
  PIN_Code:"Pin kodu",
  Change_PIN:"PIN kodu dəyişdirin",
  New_Password:"Yeni şifrə",
  Geofence_Protection:"Geo-Hasar Mühafizəsi",
  Geofence_Area:"Sahəni təyin et",
  Distance:"Məsafə",
  Setup_Now:"İndi Quraşdır",
  Mile:"Mil",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Üzün tanınması",
  Face:"Üz",
  Setup:"Qurmaq",
  Label:"Etiket",
  Password_Protection:"Parolun qorunması",
  Modify_Password:"Parolun dəyişdirilməsi",
  New_Tax_Entry:"Yeni Vergi Girişi",
  New_Tax:"Yeni Vergi",
  Tax_Label:"Vergi etiketi",
  Perpetually_Enabled:"Daimi aktiv",
  Select_Taxes:"Vergiləri seçin",
  Tax_Deductions:"Vergi ayırmaları",
  No_Tax_Deductions:"Vergi endirimləri yoxdur",
  No_Tax_Deductions_Word:"İlk <a routerLink='/folder/Tax/New'>Vergi</a> çıxılmasını əlavə edin.",
  New_Timer:"Yeni Taymer",
  Start:"Başlamaq",
  Stop:"Dayan",
  Start_Timer:"Start timer",
  Stop_Timer:"Taymeri dayandır",
  Timer_Active:"Taymer Aktiv",
  Timer:"Taymer:",
  Timer_Running:"Taymer: (İşləyir)",
  Save_Timer:"Taymeri saxla",
  New_Timesheet:"Yeni vaxt cədvəli",
  Select_Timesheets:"Vaxt cədvəllərini seçin",
  Work_Notes:"İş qeydləri",
  Entry_Title:"Giriş Başlığı",
  No_Timesheets:"Vaxt cədvəli yoxdur",
  No_Timesheets_Word:"İlk <a routerLink='/folder/Timesheet/New'>Vaxt cədvəlini</a> əlavə edin.",
  Timesheet_Submitted:"Təqdim olunan vaxt cədvəli",
  Timesheet_Congrats:"Təbrik edirik! Vaxt Cədvəliniz uğurla təqdim edildi. Təşəkkür edirəm!",
  Timesheet_Copy:"Sənədlərinizin surətini almaq üçün bizə e-poçt ünvanınızı və/və ya mobil telefon nömrənizi təqdim edin.",
  Submit:"Təqdim",
  Allow_Notifications:"Bildirişlərə icazə verin",
  Untitled_Entry:"Yeni Giriş",
  Untitled_Bank:"Adsız Bank",
  Timesheet_Entry:"Vaxt cədvəlinə giriş",
  Work_Description:"İşin təsviri",
  Enable_Timesheet:"Vaxt cədvəlini aktivləşdirin",
  Submit_Timesheet:"Vaxt cədvəlini təqdim et",
  Vacation:"Tətil",
  New_Vacation:"Yeni tətil",
  Vacation_Name:"Tətil adı",
  Paid_Vacation:"Ödənişli məzuniyyət",
  Vacation_Pay:"Tətil ödənişi",
  Enable_Vacation:"Tətilləri aktivləşdir",
  Select_Vacations:"Tətilləri seçin",
  No_Vacations:"Tətil yoxdur",
  No_Vacations_Word:"İlk <a routerLink='/folder/Vacation/New'>Tətil</a> girişini yaradın.",
  Payroll_Schedule:"Əmək haqqı cədvəli",
  Next_Payroll:"Növbəti Əmək haqqı:",
  Upcoming_Payroll:"Gələcək əmək haqqı",
  No_Upcoming_Payroll:"Gələcək əmək haqqı yoxdur",
  Address_Book:"Ünvan kitabı",
  Archived_Documents:"Arxiv sənədləri",
  Dev_Mode:"İnkişaf rejimində tətbiq",
  Administrators:"İdarəçilər",
  Privacy:"Məxfilik",
  None:"Heç biri",
  Select_Signature:"İmza seçin",
  No_Signatures:"İmza yoxdur",
  No_Signatures_Word:"İlk <a routerLink='/folder/Signature/New'>İmza</a> əlavə edin.",
  Repeat_Indefinitely:"Qeyri-müəyyən təkrarlayın",
  Sign:"İmza",
  Sign_Here:"Buraya imza at",
  Date_Signed:"İmzalanma tarixi",
  Signature_Pad:"İmza lövhəsi",
  Account_Holder:"Hesab sahibi",
  Account_Properties:"Hesab Xüsusiyyətləri",
  Name_On_Cheque:"Çekdəki ad",
  Server_Hostname:"Server Host adı / IP",
  Printers:"Printerlər",
  No_Printers:"Printer yoxdur",
  Printer_Exists:'Bu adda printer artıq mövcuddur.',
  No_Printers_Word:"İlk <a routerLink='/folder/Printer/New'>Printer</a>i əlavə edin.",
  No_Printer_Alert:"Heç bir printer müəyyən edilməyib. Printer quraşdırmaq istərdinizmi?",
  Add_Printer:"Printer əlavə et",
  New_Printer:"Yeni printer",
  Printer_Hostname:"Printer Hostname / IP",
  Printer_Label:"Printer etiketi",
  Printer_Protocol:"Printer Protokolu",
  Protocol:"Protokol",
  Email_Print:"E-poçt",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Rozetka",
  Print_Job:"Çap işi",
  Printed:"Çap",
  Not_Printed:"Çap edilməyib",
  Print_Jobs:"Çap işləri",
  Print_Queue:"Çap növbəsi",
  No_Print_Jobs:"Çap işləri yoxdur",
  No_Prints:"Çap etmək üçün yeni <a routerLink='/folder/Cheque/New'>Çek</a> yaradın.",
  Prints:"Çaplar",
  Find_Printer:"Printer tap",
  Find_AirPrint_Devices:"AirPrint Cihazlarını tapın",
  Select_Printer:"Printer seçin",
  System_UI:"Sistem UI",
  Printer:"Printer",
  Status:"Status",
  Preview:"Ön baxış",
  Enable_Print_Job:"Çap işini aktivləşdir",
  Queue_Weight:"Növbə çəkisi",
  Unlimited:"Limitsiz",
  Show_Advanced_Printer_Options:"Qabaqcıl Printer Seçimlərini göstər",
  Advanced:"Qabaqcıl",
  Location:"Yer",
  Note:"Qeyd",
  Queue_Name:"Növbə adı",
  Pages_Printed_Limit:"Çap edilmiş səhifələrin həddi",
  MultiPage_Idle_Time:"Çoxsaylı Gözləmə Müddəti (lər)",
  Page_Count_Limit:"Səhifə sayı limiti",
  Page_Orientation:"Səhifə istiqaməti",
  Portrait:"Portret",
  Landscape:"Mənzərə",
  Duplex:"Dubleks",
  Double_Sided_Printing:"İkitərəfli",
  Duplex_Mode:"Dupleks rejimi",
  Duplex_Short:"Qısa",
  Duplex_Long:"Uzun",
  Duplex_None:"Heç biri",
  Color_And_Quality:"Rəng və Keyfiyyət",
  Monochrome:"Monoxrom",
  Photo_Quality_Prints:"Foto Keyfiyyətli Çaplar",
  Printer_Email:"Printer E-poçtu",
  Automatically_Downsize:"Avtomatik olaraq azaldın",
  Paper:"Kağız",
  Paper_Name:"Kağız adı",
  Paper_Width:"Kağız eni",
  Paper_Height:"Kağız Hündürlüyü",
  Paper_Autocut_Length:"Kağızın avtomatik kəsilməsi uzunluğu",
  Margins:"Kənarlar",
  Page_Margins:"Səhifə kənarları",
  Page_Margin_Top:"Üst Səhifə Haşiyəsi",
  Page_Margin_Right:"Sağ Səhifə Haşiyəsi",
  Page_Margin_Bottom:"Aşağı Səhifə Haşiyəsi",
  Page_Margin_Left:"Sol Səhifə Haşiyəsi",
  Add_Employees:"İşçilər əlavə et",
  Header:"Başlıq",
  Print_A_Page_Header:"Səhifə başlığını çap edin",
  Header_Label:"Başlıq etiketi",
  Header_Page_Index:"Başlıq səhifəsi indeksi",
  Header_Font_Name:"Başlıq Şrifti",
  Select_Font:"Şrift seçin",
  Font_Selector:"Şrift Seçici",
  Header_Font_Size:"Başlıq Şrifti",
  Header_Bold:"Başlıq Qalın",
  Header_Italic:"Başlıq kursiv",
  Header_Alignment:"Başlıq Hizalanması",
  Left:"Sol",
  Center:"Mərkəz",
  Right:"Sağ",
  Justified:"əsaslandırılmış",
  Header_Font_Color:"Başlıq Rəngi",
  Select_Color:"Rəng seçin",
  Footer:"Alt-bilgi",
  Print_A_Page_Footer:"Səhifənin altbilgisini çap edin",
  Footer_Label:"Alt-bilgi etiketi",
  Footer_Page_Index:"Alt-bilgi səhifəsi indeksi",
  Footer_Font_Name:"Alt-bilgi şrifti",
  Fonts:"Şriftlər",
  Done:"Bitti",
  Select_Fonts:"Şriftləri seçin",
  Footer_Font_Size:"Alt-bilgi ölçüsü",
  Footer_Bold:"Qalın altbilgi",
  Footer_Italic:"Alt-bilgi kursiv",
  Footer_Alignment:"Alt-bilgilərin uyğunlaşdırılması",
  Footer_Font_Color:"Altyazı Rəngi",
  Page_Copies:"Səhifə nüsxələri",
  Enable_Printer:"Printeri aktivləşdirin",
  Remote_Logging:"Uzaqdan Qeydiyyat",
  Log_Server:"Giriş Server",
  Encryption:"Şifrələmə",
  Random_Key:"Təsadüfi açar",
  Encryption_Key:"Şifrələmə açarı",
  Cheques_Webserver:"Fərdi verilənlər bazası",
  Learn_How:"Necə öyrənin",
  Signature:"İmza",
  Default_Printer_Unit:"in/sm/mm/(pt)",
  View_Signature:"İmzaya baxın",
  Printed_Signature:"Çap imzası",
  Digitally_Sign:"Rəqəmsal İmza",
  Digital_Signature:"Rəqəmsal İmza",
  Add_Signature:"İmza əlavə et",
  Add_Your_Signature:"İmzanızı əlavə edin",
  Enable_Signature:"İmzanı aktivləşdir",
  Digitally_Signed:"Rəqəmsal İmzalı",
  Insert_Error:"Verilənlər bazası problemlərinə görə çeki saxlamaq mümkün deyil.",
  Delete_Confirmation:"Bu məlumatı silmək istədiyinizə əminsiniz?",
  Discard_Confirmation:"Bu məlumatı silmək istədiyinizə əminsiniz?",
  Discard_Bank_Confirmation:"Bu hesabı silmək istədiyinizə əminsiniz?",
  Discard_Printer_Confirmation:"Bu printeri ləğv etmək istədiyinizə əminsiniz?",
  Delete_Bonus_Confirmation:"Bu bonusu silmək istədiyinizə əminsiniz?",
  Delete_Invoice_Confirmation:"Bu fakturanı silmək istədiyinizə əminsiniz?",
  Generated_Cheque:"Yaradılmış Çek",
  Generated_Invoice:"Yaradılmış faktura",
  Schedule_Start_Time:"Cədvəlin başlanğıcı",
  Schedule_End_Time:"Cədvəlin sonu",
  New_Call:"Yeni Zəng",
  No_Contacts:"Əlaqə yoxdur",
  No_Contacts_Word:"İnzibatçılar, moderatorlar, işçilər və ödəniş alanlar əlaqə kimi görünür.",
  PDF_Subject:"maliyyə",
  PDF_Keywords:"əmək haqqı çeki PDF çek çekləri",
  Printer_Setup:"Printer Quraşdırma",
  Printer_Selection:"Printer seçimi",
  New_Fax:"Yeni Faks",
  New_Fax_Message:"Yeni Faks Mesajı",
  Fax_Machine:"Faks maşın",
  Fax_Name:"Faksın adı",
  Fax_Email:"Faks e-poçtu",
  Fax_Number:"Faks nömrəsi",
  Contents:"Məzmun",
  Fax_Body:"Səhifə Gövdəsi",
  Header_Word:"Başlıq:",
  Header_Text:"Başlıq mətni",
  Include_Header:"Başlıq daxil et",
  Include_Footer:"Alt-bilgi daxil et",
  Footer_Word:"Alt-bilgi:",
  Footer_Text:"Alt-bilgi mətni",
  Attach_a_Cheque:"Çek əlavə edin",
  Add_Deduction:"Çıxılma əlavə et",
  Enable_Fax:"Faks göndər",
  Select_Fax:"Faksı seçin",
  No_Faxes:"Faks yoxdur",
  Faxes:"Fakslar",
  Save_and_Send:"Faks göndər",
  Save_and_Pay:"Saxla və ödə",
  WARNING:"XƏBƏRDARLIQ:",
  Remember:"Unutma",
  Printing:"Çap",
  Printing_Complete:"Çap tamamlandı!",
  of:"in",
  There_Were:"Var idi",
  Successful_Prints:"uğurlu çaplar və",
  Unsuccessful_Prints:"uğursuz çaplar.",
  PrinterError:"Bağışlayın! Xəta baş verdi.",
  Printing_:"Çap olunur...",
  PrinterSuccess:"Sənəd uğurla çap olundu.",
  PrintersSuccess:"Sənədlər uğurla çap olundu.",
  Print_Cheques:"Çap çekləri",
  New_Message:"Yeni Mesaj",
  New_Messages:"Yeni Mesajlar",
  Read_Message:"Mesajı oxuyun",
  Write_Message:"Mesaj yaz",
  Send_Message:"Mesaj göndər",
  Subject:"Mövzu",
  Message:"Mesaj",
  Writing:"Yazılar...",
  Send:"Göndər",
  Set_Date:"Tarixi təyin et",
  Set_Time:"Vaxtı təyin et",
  Recieve:"Qəbul",
  Set_as_Default:"Defolt olaraq təyin et",
  Default_Account:"Defolt hesab",
  Default_Design:"Defolt dizayn",
  Multiple_Cheques:"Yoxlar (üç dəfə)",
  Account_Mode:"Hesab rejimi",
  Multiple_Cheques_Description:"Hər səhifədə üç yoxlama.",
  Check_and_Table:"Yoxlama və Cədvəl",
  Check_including_Table:"Yoxlama və uçot cədvəli.",
  Check_Mailer:"Mailləri yoxlayın",
  Check_Mailer_Window:"Ünvan pəncərəsi ilə yoxlayın.",
  DocuGard_Table_Top:"DocuGard Yoxlama və Cədvəl (Üst)",
  DocuGard_Table_Middle:"DocuGard Yoxlama və Cədvəl (Orta)",
  DocuGard_Table_Bottom:"DocuGard Yoxlama və Cədvəl (Alt)",
  DocuGard_Mailer_Top:"DocuGard Check Mailer (Üst)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (Orta)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mailer (Alt)",
  DocuGard_Three_Cheques:"DocuGard Çekləri (Üçlü)",
  DocuGard_Cheque_Top:"DocuGard Check (Üst)",
  DocuGard_Cheque_Middle:"DocuGard Check (Orta)",
  DocuGard_Cheque_Bottom:"DocuGard Check (Alt)",
  DocuGard_Three_Cheques_Window:"Bir səhifədə üç yoxlama.",
  DocuGard_Table_Top_Window:"Çox və qazanc cədvəli.",
  DocuGard_Table_Middle_Window:"Çox və qazanc cədvəli.",
  DocuGard_Table_Bottom_Window:"Çox və qazanc cədvəli.",
  DocuGard_Mailer_Top_Window:"Yoxlama, cədvəl və ünvan.",
  DocuGard_Mailer_Middle_Window:"Yoxlama, cədvəl və ünvan.",
  DocuGard_Mailer_Bottom_Window:"Yoxlama, cədvəl və ünvan.",
  DocuGard_Cheque_Top_Window:"Təhlükəsiz kağızı yoxlayın.",
  DocuGard_Cheque_Middle_Window:"Təhlükəsiz kağızı yoxlayın.",
  DocuGard_Cheque_Bottom_Window:"Təhlükəsiz kağızı yoxlayın.",
  Basic_Cheque:"Yoxlama (Üst)",
  Basic_Cheque_Print:"Bir çeki çap edin.",
  Error_Setting_As_Paid:"Ödənişli Ayarlama xətası",
  Add_Attachment:"Qoşma əlavə et",
  PrinterUnavailable:"Printer əlçatan deyil",
  CreditCardComponent:"Kartlar",
  PaypalComponent:"PayPal",
  InteracComponent:"İnterak",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Yeni əmanət",
  Deposits:"Depozitlər",
  No_Deposits:"Depozit yoxdur",
  Credit_Card_Deposit:"Kredit kartı",
  New_Credit_Card_Deposit_Message:"Kredit Kartı Depoziti",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin Depoziti",
  New_Interac_Deposit:"İnterak",
  New_Interac_Deposit_Message:"İnterak Transfer",
  Payments_Limit:"Ödəniş Limiti",
  No_Payment_Limit:"Ödəniş limiti yoxdur",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal depoziti",
  No_Deposits_Word:"İlk şirkəti <a routerLink='/folder/Deposit/New'>Depozit</a> əlavə edin.",
  No_Documents_Specified:"Çap üçün heç bir sənəd göstərilməyib",
  No_Printers_Added:"Heç bir printer tapılmadı. Əlavə etmək üçün Parametrlər > Printerlər bölməsinə keçin.",
  DB_Erase_Prompt:"Bütün verilənlər bazası tamamilə silinsin? XƏBƏRDARLIQ: Saxlanan bütün məlumatları itirəcəksiniz!",
  Console_Warning:"Bu konsola heç bir mətn yapışdırmayın. Özünüzü və/yaxud şirkətinizi ciddi riskə ata bilərsiniz.",
  No_Faxes_Word:"İlk <a routerLink='/folder/Fax/New'>Faksı</a> yaradın.",
  Cheque_Delete_Confirmation:"Bu çeki silmək istədiyinizə əminsiniz?",
  Design_Delete_Confirmation:"Bu dizaynı silmək istədiyinizə əminsiniz?",
  Cheque_Pay_Confirmation:"Bu çeki ödənişli kimi qeyd edin? O, çap növbəsində GÖRÜNMƏYƏCƏK.",
  Payment_Pay_Confirmation:"Bu ödənişi ödənilmiş kimi qeyd edin? O, çek növbəsində GÖRÜNMƏYƏCƏK.",
  Delete_Deduction_Confirmation:"Bu endirimi silmək istədiyinizə əminsiniz?",
  Delete_Job_Confirmation:"Bu işi silmək istədiyinizə əminsiniz?",
  Delete_Timesheet_Confirmation:"Bu vaxt cədvəlini silmək istədiyinizə əminsinizmi?",
  Delete_Schedule_Confirmation:"Bu cədvəli silmək istədiyinizə əminsiniz?",
  Delete_Setting_Confirmation:"Bu ayarı sıfırlamaq istədiyinizə əminsiniz?",
  Delete_Fax_Confirmation:"Bu faksı silmək istədiyinizə əminsiniz?",
  Delete_File_Confirmation:"Bu faylı silmək istədiyinizə əminsiniz?",
  Delete_Vacation_Confirmation:"Bu tətili silmək istədiyinizə əminsiniz?",
  Delete_Printer_Confirmation:"Bu printeri silmək istədiyinizə əminsiniz?",
  Remove_Design_Confirmation:"Bu dizaynı silmək istədiyinizə əminsiniz?",
  Delete_Payroll_Confirmation:"Bu əmək haqqı cədvəlini silmək istədiyinizə əminsiniz?",
  Send_Fax_Email_Confirmation:"Bu sənədləri faks və e-poçtla göndərmək istəyirsiniz?",
  Send_Email_Confirmation:"Bu sənədi e-poçtla göndərmək istəyirsiniz?",
  Send_Fax_Confirmation:"Bu sənədi faksla göndərmək istəyirsiniz?",
  Error_Generating_PDF:"Bağışlayın. Bu sənədi yaratarkən xəta baş verdi.",
  PDF_Error_Saving_Image:"Bağışlayın. Bu sənədin PDF şəklini saxlayarkən xəta baş verdi.",
  Test_Printer_Confirmation:"Bu printerdə sınaq səhifəsini çap etmək istəyirsiniz?",
  Save_Timesheet_Prompt:"Yaddaşa saxlamaq üçün 'Başlıq' əlavə edin və ya 'Start Timer' düyməsini basın.",
  Remove_Geofence_Prompt:"Bu geo-hasarın yerini silmək istədiyinizə əminsiniz?",
  Delete_Employee_Confirmation:"Silmək istədiyinizə əminsiniz",
  Fire_Employee_Confirmation:"Atəş etmək istədiyinizə əminsiniz"
}