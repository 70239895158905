export const Tl = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"BAYAD SA ORDER NG:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  Copyright:"Copyright &copy; 2023",
  black:"Itim",
  green:"Berde",
  gold:"Ginto",
  blue:"Asul",
  brown:"Kayumanggi",
  purple:"Lila",
  pink:"Rosas",
  red:"Pula",
  Swatches:"Mga swatch",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Hue",
  Saturation:"Saturation",
  Lightness:"Kagaanan",
  Upgrade_To_Pro:"Mag-upgrade sa Pro",
  AllFeaturesInclude:"Lahat ng Mga Tampok ay kinabibilangan ng:",
  PrintUnlimitedCheques:"Mag-print ng Walang limitasyong mga tseke",
  PremiumChequeDesigns:"Mga Premium Check Designs",
  ManageUnlimitedEmployees:"Pamahalaan ang Walang limitasyong mga Empleyado",
  AddUnlimitedPayees:"Magdagdag ng Walang Limitasyong Nagbabayad",
  CreateUnlimitedPayrolls:"Lumikha ng Walang Limitasyong Payroll",
  UnlimitedSchedulesandTimeSheets:"Walang limitasyong mga Iskedyul at Time Sheet",
  BulkPayPalPayouts:"Maramihang Payout sa PayPal",
  UnlimitedBankAccounts:"Walang limitasyong mga Bank Account",
  ManageMultipleCompanies:"Pamahalaan ang Maramihang Kumpanya",
  TrackInsurancePolicies:"Subaybayan ang Mga Patakaran sa Seguro",
  Bio_MetricProtection:"Bio-Metric na Proteksyon",
  Geo_FenceLock_OutProtection:"Proteksyon ng Geo-Fence Lock-Out",
  Multiple_Companies_Word:"Ang pamamahala ng maraming kumpanya ay hindi magagamit nang walang Checks premium.",
  PayPal_Payouts_Word:"Ang mga pagbabayad sa PayPal ay hindi pinagana nang walang mga Check premium.",
  PINProtection:"Proteksyon ng PIN",
  PasswordProtection:"Proteksyon ng Password",
  May_Require_Approval:"Maaaring mangailangan ng pag-apruba.",
  Subscribe:"Mag-subscribe",
  Billed:"Sinisingil",
  Up:"pataas",
  Down:"Pababa",
  Positioning:"Pagpoposisyon",
  Marker:"Pananda",
  Drag_Marker:"I-drag ang Marker",
  Tagline:"Mag-print ng Mga Check at Mag-tabulate ng Payroll",
  Marker_Word:"Gamitin ang mga Marker upang sukatin ang elemento.",
  Pinch_Zoom:"Pinch Zoom",
  Pinch_Word:"I-pinch para i-zoom ang elemento.",
  Drag:"I-drag",
  Drag_Word:"Gamitin ang iyong daliri upang i-drag ang mga elemento.",
  subscription_alias_word:"Awtomatikong Pag-renew ng Subscription",
  premium_alias_word:"Isang-Beses na Upgrade Package",
  print_alias_word:"Print-Out na Mga Indibidwal na Check",
  mode_alias_word:"Mode",
  Pro:"Pro",
  Pro_word:"Pro bersyon kinakailangan.",
  Cant_Delete_Default_Company_Word:"Paumanhin, hindi mo matatanggal ang iyong default na kumpanya.",
  Reinsert_Default_Designs:"Ipasok muli ang mga Default na Disenyo",
  Reinsert_Default_Designs_word:"Gusto mo bang muling ipasok ang mga default na disenyo?",
  Subscription_Active_Disable_Word:"Aktibo ang subscription na ito. Gusto mo bang kanselahin ang subscription na ito sa Mga tseke?",
  Company_Logo:"Logo ng kompanya",
  ZERO_:"ZERO",
  Disclaimer:"Disclaimer",
  Privacy_Policy:"Patakaran sa Privacy",
  EULA:"Sinusuri ang EULA",
  Terms_Of_Service:"Mga Tuntunin ng Serbisyo",
  Terms_Of_Use:"Mga Tuntunin ng Paggamit",
  Refunds:"Patakaran sa Pag-refund",
  Single_Print:"1 Suriin",
  Two_Prints:"2 Mga tseke",
  Five_Prints:"5 Mga tseke",
  Ten_Prints:"10 Mga tseke",
  Fifteen_Prints:"15 Mga tseke",
  Twenty_Prints:"20 Mga tseke",
  Thirty_Prints:"30 Mga tseke",
  Image_Added:"Idinagdag ang Larawan",
  Image_Preview:"Preview ng Larawan",
  No_Image_Was_Selected:"Walang napiling larawan.",
  Cheques_Unlimited:"Walang limitasyong mga tseke",
  _Subscription:"Subscription",
  Subscription:"Mga tseke - 1 Buwan",
  Two_Month_Subscription:"Mga tseke - 2 Buwan",
  Three_Month_Subscription:"Mga tseke - 3 Buwan",
  Six_Month_Subscription:"Mga tseke - 6 na Buwan",
  Twelve_Month_Subscription:"Mga tseke - 12 Buwan",
  Cheques_Are_Available:"Ang mga tseke ay magagamit upang i-print.",
  Upgrade_Required_Two:"Pumili ng package at i-double tap ang button ng presyo para simulan ang iyong pagbili. Mag-print ng propesyonal na mukhang full-color na mga tseke sa ilang segundo.",
  Month:"buwan",
  Due:"Dahil:",
  Expires:"Mag-e-expire:",
  Subscription_Active:"Aktibo ang Subscription",
  Subscription_Inactive:"Hindi Aktibo ang Subscription",
  Purchase_Additional_Cheques:"Bumili ng mga karagdagang tseke?",
  Printable_Cheque:"Napi-print na Check",
  Printable_Cheques:"Mga Napi-print na Check",
  Printable_Cheque_Word:"available ang tseke sa iyong account.",
  Printable_Cheques_Word:"available ang mga tseke sa iyong account.",
  Max_Amount_Message:"Ang halagang iyong tinukoy ay umabot sa maximum na halagang itinakda para sa system na ito:",
  Terms_Required_Word:"Dapat kang sumang-ayon sa kasunduang ito bago magpatuloy sa paggamit ng Mga tseke.",
  Subscriptions:"Mga subscription",
  Product_Upgrades:"Mga upgrade",
  Mailed_Out_Cheques:"Mga Na-mail na Mga Check",
  Enter_A_Company_Name:"Mangyaring maglagay ng pangalan ng kumpanya.",
  Single_Cheques:"Mga Single Check",
  Cheque_Golden:"Golden Check",
  Cheque_Golden_Window:"Golden check na disenyo.",
  Cheque_Green:"Green Check",
  Cheque_Green_Window:"Disenyo ng green check.",
  Cheque_Red:"Red Check",
  Cheque_Red_Window:"Red check na disenyo.",
  Cheque_Yellow:"Yellow Check",
  Cheque_Yellow_Window:"Dilaw na disenyo ng check.",
  Cheque_Statue_of_Liberty:"Statue of Liberty",
  Cheque_Statue_of_Liberty_Window:"Ang disenyo ng tseke ng Statue of Liberty.",
  Cheque_Green_Wave:"Green Wave",
  Cheque_Green_Wave_Window:"Disenyo ng green check.",
  Cheque_Golden_Weave:"Gintong Habi",
  Cheque_Golden_Weave_Window:"Eleganteng golden check na disenyo.",
  Cheque_Green_Sun:"Berdeng Araw",
  Cheque_Green_Sun_Window:"Malalim at nagpapatahimik na disenyo ng check.",
  Cheque_Blue_Checkers:"Blue Checkers",
  Cheque_Blue_Checkers_Window:"Disenyo ng asul na check.",
  Cashiers_Check:"Cashier'\s Check",
  Cashiers_Check_Window:"Template ng istilo ng Cashier\'s Check.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Aqua check disenyo.",
  Cheque_Golden_Checkers:"Golden Checkers",
  Cheque_Golden_Checkers_Window:"Golden check na disenyo.",
  Upgrade_Unavailable:"Hindi available ang mga upgrade",
  Bank_Code_Protection:"Proteksyon sa Numero ng Bangko",
  Bank_Code_Protection_Word:"Protektahan ang mga numero ng iyong bangko mula sa paggamit sa app na ito na tumatakbo sa ibang device o para sa ibang user. Ang pagkilos na ito ay IRREVERSIBLE. Magpatuloy?",
  Bank_Code_Protection_Blocked_Word:"Ang mga numero ng bangko na sinusubukan mong gamitin ay nakalaan para sa isa pang user o device.",
  Bank_Code_Protection_Error_Word:"Nabigo ang pag-verify ng numero. Mangyaring kumonekta sa internet at subukang idagdag muli ang bank account na ito.",
  Bank_Code_Protection_Set_Error_Word:"Ang proteksyon sa numero ng bangko ay nangangailangan na ikaw ay konektado sa internet. Mangyaring kumonekta at subukang muli.",
  Upgrade_Unavailable_Word:"Paumanhin, nagkakaproblema kami sa pag-verify sa iyo. Kasalukuyang hindi magagamit ang mga subscription at pag-upgrade sa Mga Check para mabili sa iyong lugar.",
  PayPal_Payment_Preview:"Preview ng Pagbabayad sa PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Piliin ang PayPal",
  PayPal_Applications:"Mga Aplikasyon ng PayPal",
  Purchase_With_Apple_Pay:"Bumili gamit ang Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Mga kumpanya",
  Insurance:"Insurance",
  New_PayPal:"Bagong PayPal",
  Pay_By:"Magbayad Sa pamamagitan ng",
  Insure:"I-insure",
  Miles:"Miles",
  Payment_Method:"Paraan ng Pagbayad",
  Select_Policies:"Piliin ang Mga Patakaran",
  Policies:"Mga patakaran",
  Policy:"Patakaran",
  No_PayPal_Account:"Walang PayPal Account",
  No_Policies:"Walang Mga Patakaran sa Seguro",
  New_Policy:"Bagong Patakaran",
  PayPal_Payment:"Pagbabayad sa PayPal",
  PayPal_Payments:"Mga Pagbabayad sa PayPal",
  No_Payment_Selected:"Walang Napiling Pagbabayad",
  Sending_Payment_Word:"Mangyaring maghintay... Ang bayad na ito ay ipinapadala.",
  Sending_Payments_Word:"Mangyaring maghintay... Ipinapadala ang mga pagbabayad.",
  No_Payment_Selected_PayPal:"Walang napiling ipadala na <a routerLink='/folder/Payments'>PayPal payment</a>.",
  Payment_Sent:"Ang bayad ay naipadala na",
  PayPal_Payment_Sent:"Ang pagbabayad na ito ay naipadala gamit ang PayPal.",
  Copay:"Copay",
  Dead:"Patay",
  Alive:"Buhay",
  Not_Dead:"Hindi patay",
  Unclaimed:"Hindi inaangkin",
  Attempted:"Tinangka",
  Deceased:"Namatay na",
  Claimed:"Inangkin",
  Unpaid:"Walang bayad",
  Sending_Payment:"Nagpapadala ng Pagbabayad",
  Sending_Payments:"Nagpapadala ng mga Pagbabayad",
  Send_PayPal_Confirmation:"Gusto mo bang ipadala ang transaksyong ito gamit ang PayPal?",
  Send_PayPal_Confirmation_Word:"Pindutin ang berdeng button para ipadala ang transaksyong ito.",
  Save_Payment_As_Unpaid:"I-save ang bayad na ito bilang hindi nabayaran?",
  Payment_Pay_Confirmation_PayPal:"I-save ang bayad na ito bilang bayad?",
  No_Policies_Word:"Idagdag ang unang <a routerLink='/folder/Postage/New'>Patakaran sa Seguro</a> ngayon.",
  Timesheet_Multiple_Delete_Confirmation:"Sigurado ka bang gusto mong magtanggal ng maraming timesheet?",
  Select_Multiple_Timesheets_Prompt:"Walang napiling timesheets. Pumili ng hindi bababa sa isang timesheet.",
  Select_Multiple_Policies_Prompt:"Walang napiling mga patakaran. Pumili ng kahit isang patakaran sa seguro.",
  Policies_Multiple_Delete_Confirmation:"Sigurado ka bang gusto mong magtanggal ng maraming patakaran?",
  Company:"kumpanya",
  Add_Company:"Magdagdag ng Kumpanya",
  New_Company:"Magdagdag ng Kumpanya",
  No_Companies:"Walang Kumpanya",
  Enable_Company:"Paganahin ang Kumpanya",
  Select_Company:"Piliin ang Kumpanya",
  The_Default_Company:"Ang default na label ng kumpanya.",
  Manage_Companies:"Pamahalaan ang mga Kumpanya",
  No_Companies_Word:"Gagamit ang mga tseke ng default na kumpanya.<br />Idagdag ang <a routerLink='/folder/Company/New'>unang Kumpanya</a>.",
  Default_Company:"Default na Kumpanya",
  Cheques_Unlimited_Word:"Pinapayagan ka ng Checks Unlimited na mag-print ng maraming tseke hangga't gusto mo.",
  Cheques_Subscription_Word:"Nagbibigay-daan sa iyo ang isang subscription sa Checks na mag-print ng maraming tseke hangga't gusto mo.",
  You_Own_This_Product:"Pagmamay-ari mo ang produktong ito.",
  Your_Subscription_is_Active:"Aktibo ang iyong subscription.",
  Active_Products:"Mga Aktibong Produkto",
  Purchase_Confirmation:"Bumili",
  Purchase_Cheques:"Mga tseke sa Pagbili",
  Restore_Purchase:"Ibalik ang Mga Pagbili",
  Erase_Purchase:"Burahin ang Mga Pagbili",
  Successfully_Purchased:"Matagumpay na Nabili",
  Enter_Your_Licence_Key:"Pakilagay ang iyong license key sa page na ito para i-activate ang produktong ito.",
  Licence_Key:"Lisensya",
  Enter_Licence_Key:"Ipasok ang License Key",
  Purchased:"Binili",
  Enable_Feature:"Paganahin ang Tampok",
  Cancel_Subscription:"Ikansela ang subskripsyon",
  Subscription_Removed:"Inalis ang Subscription",
  Select_Active_Subscription:"Pumili ng aktibong subscription para mabago ito.",
  Remove_Subscription_Word:"Sigurado ka bang gusto mong kanselahin ang subscription na ito?",
  Delete_Company_Confirmation:"Sigurado ka bang gusto mong tanggalin ang buong kumpanyang ito? BABALA: Mawawala ang lahat ng nakaimbak na impormasyon!",
  Delete_Default_Company_Word:"Hindi mo matatanggal ang default na kumpanya. Gusto mo bang i-reset ang application at ibalik ito sa default na estado? BABALA: Mawawala ang lahat ng nakaimbak na impormasyon!",
  Console_Warning_2:"Huwag hawakan ang anumang pera gamit ang application na ito na kasalukuyang hindi sa iyo.",
  Terms_and_Conditions:"Mga Tuntunin at Kundisyon",
  and_the:"at ang",
  for:"para sa",
  Please_Read_Word:"Mangyaring basahin at sumang-ayon sa",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Ang ilang mga rate ng conversion ng pera ay hindi mahanap. Mangyaring kumonekta sa internet.",
  Free:"Libre",
  DB_Erase_Prompt_2:"Ganap na burahin ang buong database ng developer? BABALA: Mawawala sa iyo ang lahat ng impormasyon sa PAGBILI at PAG-SUBSCRIPTION!",
  Successfully_Imported:"Matagumpay na Na-import",
  Select_Postage:"Piliin ang Postage",
  No_Postage:"Walang Selyo ng Selyo",
  No_Paid_Postage_Word:"Idagdag ang unang <a routerLink='/folder/Postage/New'>Paid Postage</a> stamp.",
  Trial_Complete:"Kumpleto na ang Pagsubok",
  Please_Upgrade:"Mangyaring i-upgrade ang Mga Pagsusuri upang magpatuloy sa pag-print.",
  Aa:"Aa",
  Color:"Kulay",
  Font:"Font",
  Guide:"Gabay",
  Guides:"Mga gabay",
  Image:"Imahe",
  Zoom:"Mag-zoom",
  Logo:"Logo",
  Bank:"bangko",
  MICR:"MICR",
  Total:"Kabuuan",
  Cancel:"Kanselahin",
  Confirm:"Kumpirmahin",
  Method:"Pamamaraan",
  Biometric_Security:"Bio-metric na Seguridad",
  Please_Login_To_Continue:"Mangyaring mag-login upang magpatuloy.",
  Complete:"Kumpleto",
  Sign_Up:"Mag-sign Up",
  Error:"Error",
  Biometrics:"Bio-metrics",
  Percent:"Porsiyento",
  Zero_Percent:"0%",
  Top_Margin:"Nangungunang Margin",
  Bottom_Margin:"Ibabang Margin",
  Left_Margin:"Kaliwang margin",
  Right_Margin:"Right Margin",
  MICR_Margin:"Margin ng MICR",
  Table_Margin:"Table Margin",
  Address_Margin:"Margin ng Address",
  Percentage_:"Porsiyento",
  Vacation_Title:"Pamagat ng Bakasyon",
  Use_PIN:"Gumamit ng PIN",
  Loading__:"Naglo-load...",
  Design_Title:"Pamagat ng Disenyo",
  Authorize:"Pahintulutan",
  Key:"Susi",
  Public_Key:"Pampublikong Susi",
  Private_Key:"Pribadong Susi",
  Authenticate:"Patotohanan",
  Last_Payroll:"Huling Payroll",
  Last_Calculation:"Huling Pagkalkula",
  Authorized:"Awtorisado",
  Geo_Authorized:"Geo-Lokasyon: Awtorisadong",
  Not_Authorized:"Hindi Awtorisado",
  Authorization_Complete:"Kumpleto ang Awtorisasyon",
  Geolocation_Authorization:"Awtorisasyon ng geo-lokasyon",
  Out_of_Bounds:"Out of Bounds",
  Cant_Delete_Default_Design:"Hindi matanggal ang isang default na disenyo.",
  Unauthorized_Out_of_Bounds:"Hindi Pinahintulutan: Out of Bounds",
  Biometric_Authorization:"Bio-metric na Awtorisasyon",
  Locating_Please_Wait:"Hinahanap, Mangyaring Maghintay...",
  Test_Biometrics:"Subukan ang Bio-metrics",
  Cheque_Margins:"Suriin ang Mga Margin",
  Percentage_Full_Error:"Ang field ng porsyento ay hindi maaaring itakda nang higit sa 100% porsyento.",
  No_Payroll_Text:"Magdagdag ng <a routerLink='/folder/Employee/New'>Empleyado</a> o <a routerLink='/folder/Payee/New'>Payee</a> at isang <a routerLink='/folder/Schedule /New'>Iskedyul</a>.",
  Design_Saved:"Nai-save ang Disenyo",
  Default_Design_Selected:"Napili ang Default na Disenyo",
  Partial_Import:"Bahagyang Pag-import",
  Partial_Export:"Bahagyang Pag-export",
  Entire_Import:"Buong Pag-import",
  Entire_Export:"Buong Pag-export",
  Existing_Password:"Mangyaring ipasok ang iyong umiiral na password:",
  Existing_PIN:"Pakilagay ang iyong kasalukuyang PIN code:",
  Pin_Change_Header:"Pagkumpirma ng PIN",
  Pin_Change_SubHeader:"Ilagay ang iyong lumang PIN number para kumpirmahin ang pagbabago.",
  Pass_Change_Header:"Pagkumpirma ng Password",
  Pass_Change_SubHeader:"Ilagay ang iyong lumang password upang kumpirmahin ang pagbabago.",
  PIN_Enter_Message:"Ilagay ang iyong PIN para kumpirmahin.",
  Password_Enter_Message:"Ilagay ang iyong password para kumpirmahin.",
  Pin_Updated_Success:"Matagumpay na na-update ang PIN!",
  Pin_Updated_Fail:"Hindi ma-update ang PIN.",
  Pass_Updated_Success:"Matagumpay na na-update ang password.",
  Pass_Updated_Fail:"Hindi ma-update ang password.",
  Preview_Payment:"I-preview ang Pagbabayad",
  Preview_Payroll:"Silipin ang Payroll",
  No_Payments_Created:"Walang nakitang mga pagbabayad na nagawa.",
  Payment_Preview:"Preview ng Pagbabayad",
  Enable_Payee:"Paganahin ang Payee",
  Rendered:"Na-render",
  No_Email:"Walang Email",
  Setup_Cheques:"Mga Pagsusuri sa Pag-setup",
  name:"Pangalan",
  address:"Address",
  address_2:"Linya ng Address 2",
  city:"lungsod",
  province:"Lalawigan",
  postal_code:"Postal / ZIP Code",
  country:"Bansa",
  username:"Username",
  full_name:"Buong pangalan",
  birthday:"Birthday",
  email:"Email",
  phone:"Telepono",
  employees:"Mga empleyado",
  addresses:"Mga address",
  payment_amount_limit:"Limitasyon sa Halaga ng Pagbabayad",
  total_limit:"Kabuuang Limitasyon",
  payees:"Mga nagbabayad",
  description:"Paglalarawan",
  address_line_one:"Address Unang Linya",
  address_line_two:"Ikalawang Linya ng Address",
  image:"Imahe",
  account_holder:"May hawak ng Account",
  cheque_starting_id:"Suriin ang Panimulang ID",
  transit_number:"Numero ng Transit",
  institution_number:"Numero ng Institusyon",
  designation_number:"Numero ng Pagtatalaga",
  account_number:"Account Number",
  currency:"Pera",
  signature:"Lagda",
  payment_payroll_limit:"Limitasyon sa Pagbabayad",
  total_limi:"Kabuuang limitasyon",
  date:"Petsa",
  printed_memo:"Naka-print na Memo",
  banks:"Mga bangko",
  signature_image:"Larawan ng Lagda",
  address_name:"Pangalan ng Address",
  notes:"Mga Tala",
  design:"Disenyo",
  title:"Pamagat",
  frequency:"Dalas",
  fax:"Fax",
  salaries:"Mga suweldo",
  salary_ids:"Mga Salary ID",
  start_time:"Oras ng simula",
  end_time:"Oras ng Pagtatapos",
  paid:"Binayaran",
  overtime_percentage:"Bayad na Porsiyento",
  overtime_amount:"Bayad na Nakapirming Halaga",
  first_name:"Pangalan",
  last_name:"Huling pangalan",
  moderation:"Moderation",
  create:"Lumikha",
  edit:"I-edit",
  destroy:"sirain",
  day_start_time:"Araw_pagsisimula_oras",
  day_end_time:"Araw_pagtatapos_oras",
  fullname:"Pangalan",
  time:"Oras",
  auto_send:"Awtomatikong Ipadala",
  time_method:"Paraan ng Oras",
  schedules:"Mga iskedyul",
  indefinite_payroll_enabled:"Paganahin ang Walang Katiyakan",
  amount:"Halaga",
  repeat:"Ulitin",
  always_enabled:"Palaging Naka-enable",
  start_date:"Petsa ng Pagsisimula",
  end_date:"Petsa ng Pagtatapos",
  Cheque_Total:"Suriin ang Kabuuan",
  Total_Amount:"Kabuuang Halaga:",
  Amounts:"Mga halaga:",
  Images:"Mga imahe",
  Files:"Mga file",
  Previewing:"Pag-preview:",
  Insert:"Ipasok",
  Preview_Import:"I-preview ang Pag-import",
  Entry:"Pagpasok",
  Entries:"Mga entry",
  No_Entries:"Walang Entries",
  Import_Type:"Uri ng Pag-import",
  Select_Details:"Piliin ang Mga Detalye",
  Select_Payee:"Piliin ang Nagbabayad",
  Enable_Holidays:"Paganahin ang Mga Piyesta Opisyal",
  Disable_Holidays:"Huwag paganahin ang mga Piyesta Opisyal",
  Wire_Transfer:"Wire Transfer",
  New_Export:"Bagong Export",
  Export_Data:"I-export ang Data",
  Export_Data_Word:"Piliin ang uri ng file na ie-export at ida-download.",
  Export_File:"I-export ang File",
  Mode:"Mode",
  Times:"Mga oras",
  Widgets:"Mga Widget",
  Slider:"Slider",
  Set_Details:"Itakda ang Mga Detalye",
  Select_Type:"Piliin ang Uri",
  Display_Slider:"Display Slider",
  Dashboard_Slider:"Dashboard Slider",
  Dashboard_Mode:"Dashboard Mode",
  Show_Intro:"Ipakita ang Intro",
  Show_Payrolls:"Ipakita ang mga Payroll",
  Show_Holidays:"Ipakita ang mga Piyesta Opisyal",
  Show_Invoices:"Ipakita ang Mga Invoice",
  Show_Cheques:"Ipakita ang Mga Pagsusuri",
  Enabled_Widgets:"Pinagana ang Mga Widget",
  Disabled_Widgets:"Mga Naka-disable na Widget",
  Colors:"Mga kulay",
  Barcodes:"Mga barcode",
  View_Timers:"Tingnan ang mga Timer",
  Gradients:"Mga gradient",
  No_Info:"Walang Impormasyon",
  Disable:"Huwag paganahin",
  Show_Layer:"Ipakita ang Mga Layer",
  Hide_Layer:"Itago ang Mga Layer",
  Text_Layer:"Mga Layer ng Teksto",
  Secondly:"Pangalawa",
  Minutely:"Minuly",
  nine_am:"9:00",
  five_pm:"5:00 PM",
  Enable_Address:"Paganahin ang Address",
  Invalid_File_Type:"Paumanhin, isang di-wastong uri ng file ang napili. Mga sinusuportahang uri ng file:",
  Error_Updating_Entry:"Paumanhin, nagkaroon ng error sa pag-update ng entry na ito.",
  Schedule_Timing_Alert:"Error: Ang oras ng pagsisimula ng iskedyul ay nakatakda sa isang halaga pagkatapos ng oras ng pagtatapos.",
  Select_Multiple_Payments_Prompt:"Walang napiling mga pagbabayad. Pumili ng hindi bababa sa isang pagbabayad.",
  Select_Multiple_Cheques_Prompt:"Walang napiling tseke. Mangyaring pumili ng hindi bababa sa isang tseke.",
  Select_Multiple_Items_Prompt:"Walang napiling mga item. Mangyaring pumili ng kahit isang item.",
  Paymemt_Multiple_Delete_Confirmation:"Sigurado ka bang gusto mong tanggalin ang maramihang mga pagbabayad?",
  Cheque_Multiple_Delete_Confirmation:"Sigurado ka bang gusto mong magtanggal ng maraming tseke?",
  Payee_Multiple_Delete_Confirmation:"Sigurado ka bang gusto mong tanggalin ang maramihang nagbabayad?",
  Employee_Multiple_Delete_Confirmation:"Sigurado ka bang gusto mong magtanggal ng maraming empleyado?",
  MICR_Warning:"Tandaan: Maaaring hindi maipakita nang tama ng ilang mga printer at device ang MICR font.",
  Automatically_Send:"Awtomatikong Ipadala",
  Type:"Uri",
  Payment_Type:"Uri ng Pagbabayad",
  Auto_Send:"Auto Send",
  Automatically_Process:"Awtomatikong Iproseso",
  Auto_Process:"Auto Proseso",
  Image_Based:"Nakabatay sa imahe",
  Font_Based:"Nakabatay sa font",
  Rerender:"Muling i-render",
  Rendering:"Nagre-render",
  Render:"Mga file",
  Top:"Nangunguna",
  Middle:"Gitna",
  Bottom:"Ibaba",
  Top_Left:"Kaliwa sa itaas",
  Top_Center:"Nangungunang Center",
  Top_Right:"Kanan sa itaas",
  Middle_Left:"Gitnang Kaliwa",
  Middle_Center:"Gitnang Gitna",
  Middle_Right:"Gitnang Kanan",
  Bottom_Left:"Babang kaliwa",
  Bottom_Center:"Ibaba Gitna",
  Bottom_Right:"Kanan sa ibaba",
  Numbers:"Numero",
  Verified:"Na-verify",
  Paper_Size:"Laki ng papel",
  New_Device:"Bagong Device",
  Add_Device:"Magdagdag ng Device",
  Remove_Device:"Alisin ang Device",
  Delete_Device:"Tanggalin ang Device",
  Block_Device:"I-block ang Device",
  Block:"I-block",
  Unblock:"I-unblock",
  Table:"mesa",
  Scan_Login_Code:"I-scan ang Login Code",
  Login_Code:"Login Code",
  Scan_Code:"Scan Code",
  Scan_QR_Code:"I-scan ang QR Code",
  Select_All:"Piliin lahat",
  Deselect_All:"Alisin sa pagkakapili ang Lahat",
  Increase:"Taasan",
  Decrease:"Bumaba",
  Bold:"Matapang",
  Text:"Text",
  Style:"Estilo",
  Italic:"Italic",
  QR_Code:"QR Code",
  Barcode:"Barcode",
  Browse_Images:"Mag-browse ng Mga Larawan",
  Browse_Files:"Mag-browse ng Mga file",
  Background_Image:"Larawan sa Background",
  Logo_Image:"Imahe ng Logo",
  Header_Image:"Larawan ng Header",
  Bank_Image:"Larawan ng Bangko",
  Cut_Lines:"Gupitin ang mga Linya",
  Background:"Background",
  License:"Lisensya",
  One_License:"1 Lisensya:",
  Licensed:"Lisensyado sa:",
  Not_Licensed:"Hindi Lisensyado",
  Enter_Serial:"Ipasok ang Serial",
  Serial_Key:"Serial Key",
  Serial:"Serial",
  Product_Key:"Susi ng Produkto",
  Check_Product_Key:"I-verify ang Product Key",
  Add_Product_Key:"Ilagay ang Product Key",
  Verifying_Purchase:"Bine-verify ang Pagbili...",
  Print_Envelope:"I-print ang Sobre",
  Envelope:"Sobre",
  Thank_You:"Salamat!",
  Scale:"Scale",
  Print_Scale:"Print Scale",
  Borders:"Mga hangganan",
  VOID:"VOID",
  Void_Cheque:"Void Check",
  NO_DOLLARS:"WALANG DOLYAR",
  ONE_DOLLAR:"ISANG DOLYAR",
  DOLLARS:"DOLLAR",
  AND:"AT",
  CENTS:" CENTS.",
  NO_:"HINDI",
  ONE_:"ISA",
  AND_NO_:"AT HINDI",
  _AND_ONE_:"AT ISA",
  DOLLARS_AND_ONE_CENT:"AT ISANG SENTRO.",
  AND_NO_CENTS:" AT ZERO CENTS.",
  CHEQUE_PRINT_DATE:"DATE:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Mga Panimulang Gawain",
  Inital_Setup:"Paunang Setup",
  Welcome_To:"Maligayang pagdating sa",
  Welcome:"Maligayang pagdating",
  Swipe:"Mag-swipe",
  Intro_Setup:"Intro Setup",
  Introduction:"Panimula",
  CHEQUE_PRINT_CREDIT:"Pinapatakbo ng Mga tseke",
  Title_Intro_Word:"Maligayang pagdating sa Checks",
  Title_Intro:"Mga Pagsusuri sa Panimula",
  Title_Setup:"Sinusuri ang Setup",
  PayPal_Default_Email_Message:"Nakatanggap ka ng bagong PayPal transfer.",
  Cheques_App_Export:"Na-export ng mga Check",
  No_Prints_Purchased:"Walang Binili na Mga Print",
  No_Prints_Purchased_Word:"Walang magagamit na karagdagang mga pag-print. Mangyaring bumili ng mga karagdagang tseke.",
  Post_Intro_Thanks:"Salamat sa pagpili ng mga tseke. Kumpleto na ang proseso ng pag-setup.",
  Post_Intro_Word:"Magsimula sa pamamagitan ng pag-print ng iyong unang tseke, magdagdag ng isang pagbabayad sa hinaharap, o magdagdag ng isang empleyado sa payroll.",
  Upgrade_Required:"Hinihiling sa iyo ng mga tseke na magkaroon ng mas premium na bersyon ng software upang maitago ang mensaheng ito at ma-unlock ang mga karagdagang feature.",
  Upgrade_Title:"Mga tseke",
  Mailout_Prompt:"Maaari mo ring piliing ipa-mail sa Mga Check ang iyong mga tseke sa payroll para sa iyo.",
  Mailed_Cheque:"Naka-mail na Check",
  Mailed_Cheque_Color:"Naka-mail na Check (Kulay)",
  Terms_Purchase:"Ang lahat ng mga elektronikong pagbili gamit ang Mga Check ay ganap na maibabalik nang hanggang 30 araw mula sa petsa ng pagbili. Mangyaring basahin at sumang-ayon sa",
  Dashboard_Setup:"I-setup ang Pangunahing Printer",
  Dashboard_Add:"Idagdag ang Pangunahing Bank Account",
  Dashboard_Customize:"Pumili ng Template ng Check",
  Dashboard_Job_Salary:"Lumikha ng Iyong Trabaho at Idagdag ang Iyong Sahod",
  Dashboard_Employees:"Subaybayan ang mga Empleyado at Nagbabayad",
  Dashboard_Print:"I-print at I-mail ang Iyong Mga Paycheck",
  Dashboard_Payroll:"I-automate ang Iyong Payroll Printing",
  Dashboard_PayPal:"I-setup ang PayPal Payroll / Payouts",
  Begin_Setup:"Simulan ang Pag-setup",
  Get_Started:"Magsimula",
  Purchase:"Bumili",
  Lockdown:"Lockdown",
  Unlock:"I-unlock",
  Detailed:"Detalyadong",
  Log_In:"Mag log in",
  Login:"Mag log in",
  Launch:"Ilunsad",
  Register:"Magrehistro",
  Finish:"Tapusin",
  List:"Listahan",
  Weekends:"Weekends",
  Weekly:"Linggu-linggo",
  PayPal_Default_Subject:"Bagong Pagbabayad",
  Payment_Message:"Mensahe sa Pagbabayad",
  PayPal_Default_Payment_Note:"Salamat",
  Setup_Your_Cheqing_Account:"Chequing Account",
  Add_Your_Primary_Cheqing_Account:"Idagdag ang iyong pangunahing checking account.",
  Welcome_Word:"Pasimplehin at i-automate ang payroll at pamamahala ng human resource.",
  Get_Started_Quickly:"Sagutin lang ang ilang simpleng tanong na makakatulong sa amin na makapagsimula...",
  Done_Intro_Word:"Kumpleto na ang Setup",
  PIN_Protected:"Pinoprotektahan ng Password at PIN",
  Enter_New_PIN:"Maglagay ng bagong PIN Code:",
  Enter_PIN:"Ilagay ang PIN Code:",
  Invalid_PIN:"Di-wastong PIN ang ipinasok.",
  Account_Identifier:"Identifier ng Account",
  New_Account_Identifier:"Bagong Account Identifier",
  attempt:"tangka",
  attempts:"mga pagtatangka",
  remaining:"natitira",
  Language:"Wika",
  languages:"Mga wika",
  select_languages:"Piliin ang Wika",
  Deposit:"Deposito",
  Hire_One_Now:"Mag-hire ng Isa Ngayon",
  App_Locked:"Naka-lock ang application.",
  Skip_:"Laktawan",
  Skip_to_Dashboard:"Lumaktaw sa Dashboard",
  Dashboard:"Dashboard",
  Import:"Angkat",
  Admin:"Mga tagapangasiwa",
  New_Admin:"Bagong Admin",
  Settings:"Mga setting",
  Color_Picker:"Tagapili ng Kulay",
  Font_Picker:"Tagapili ng Font",
  Logout:"Mag-logout",
  Close:"Isara",
  Close_menu:"Isara",
  Excel:"Excel file",
  Csv:"CSV File",
  Sql:"SQL File",
  Json:"JSON File",
  Url:"Mag-import sa pamamagitan ng URL",
  Back:"Bumalik",
  Timers:"Mga timer",
  Cheque:"Suriin",
  Print:"Print",
  Designs:"Mga disenyo",
  Pause_Printing:"I-pause ang Pag-print",
  Resume_Printing:"Ipagpatuloy ang Pagpi-print",
  Printing_Paused:"Naka-pause ang Printing",
  PrintingUnavailable:"Paumanhin! Hindi available ang pag-print sa system na ito.",
  Prints_Paused:"Naka-pause ang mga Print",
  Administration:"Pangangasiwa",
  Loading:"Naglo-load",
  Unnamed:"Walang pangalan",
  error:"Paumanhin, hindi mabuksan ang tsekeng ito para sa pagtingin.",
  No_Cheques_To_Print:"Walang mga tseke na ipi-print",
  No_Cheques_To_Print_Word:"Gumawa ng <a routerLink='/folder/Cheque/New'>Bagong Check</a>.",
  New_Cheque:"Bagong Check",
  Start_One_Now:"Magsimula ng Isa Ngayon",
  Edit_Cheque:"I-edit ang Check",
  Select_Cheques:"Piliin ang Mga Pagsusuri",
  Select_Employee:"Piliin ang Empleyado",
  Default_Printer:"Default na Printer",
  Reassign:"Muling italaga",
  Configure:"I-configure",
  Template:"Template",
  Designer:"Designer",
  Edit_Designs:"I-edit ang Mga Disenyo",
  New_Design:"Bagong disenyo",
  Next:"Susunod",
  Save:"I-save",
  Name:"Pangalan",
  Mail:"Mail",
  Amount:"Halaga",
  Date:"Petsa",
  PayPal:"PayPal",
  Payouts:"Mga pagbabayad",
  PayPal_Label:"Label ng PayPal",
  Email_Username:"Email / Username",
  Client_ID:"ID ng kliyente",
  Sandbox_Email:"Sandbox Email",
  PayPal_Email:"Paypal Email",
  PayPal_Username:"Username ng API",
  PayPal_Payouts:"Mga Payout sa PayPal",
  Select_PayPal_Key:"Piliin ang PayPal Key",
  Secret:"Lihim",
  API_Secret:"Lihim ng API",
  PayPal_API_Keys:"Mga Susi ng PayPal",
  New_PayPal_Key:"Bagong PayPal Key",
  Email_Subject:"Paksa ng Email",
  Email_Message:"Mensahe sa Email",
  Payout_Options:"Mga Pagpipilian sa Pagbabayad",
  Payment_Note:"Tala sa Pagbabayad",
  Enable_Employee:"Paganahin ang Empleyado",
  Enable_Production_Mode:"Paganahin ang Production Mode",
  Sandbox_Username:"Sandbox Username",
  Sandbox_Signature:"Lagda ng Sandbox",
  Sandbox_Password:"Sandbox Password",
  Production_Username:"Username ng Produksyon",
  Production_Signature:"Lagda ng Produksyon",
  Production_Password:"Password ng Produksyon",
  Production_Email:"Email ng Produksyon",
  API_Client_ID:"API Client ID",
  API_Signature:"Lagda ng API",
  API_Password:"Password ng API",
  API_Username:"Username ng API",
  Secret_Key:"Lihim na Susi",
  Sandbox:"Sandbox",
  Production:"Produksyon",
  Sandbox_Keys:"Mga Sandbox Key",
  Production_Keys:"Mga Susi ng Produksyon",
  API_Title:"Pamagat ng API",
  Production_Mode:"Mode ng Produksyon",
  Account_Label:"Label ng Account",
  No_PayPal_Setup:"Walang PayPal Key",
  Enable_PayPal_Account:"Paganahin ang PayPal Account",
  No_PayPal_Word:"Idagdag ang iyong <a routerLink='/folder/Invoice/New'>PayPal API Key</a>.",
  Printed_Memo:"Naka-print na Memo",
  Employee:"Empleado",
  View_Employee:"Tingnan ang Empleyado",
  Mailing_Address:"Mailing Address",
  Company_Address:"Address ng Kumpanya",
  Select_Company_Address:"Piliin ang Address ng Kumpanya",
  Address:"Address",
  Any_Day:"Anumang araw",
  Address_Name:"Pangalan ng Address",
  Unit:"Yunit",
  Account:"Account",
  Bank_Account:"Bank account",
  Account_Limits:"Paganahin ang Mga Limitasyon sa Account",
  Payroll:"Payroll",
  Run:"Takbo",
  Run_Payroll:"Patakbuhin ang Payroll",
  New_Payroll:"Bagong Payroll",
  No_Payroll:"Walang Paparating na Payroll",
  Upcoming_Holiday:"Paparating na Holiday:",
  Invoice_Due:"Nakatakdang Invoice:",
  New_Invoice:"Bagong Invoice",
  No_Invoices:"Walang Invoice",
  No_Invoices_Word:"Lumikha ng unang <a routerLink='/folder/Invoice/New'>Invoice</a>.",
  Cheque_Due:"Check Due:",
  Payrolls:"Mga Payroll",
  Sandbox_Mode:"Sandbox Mode",
  Hire:"Hire",
  Pay:"Magbayad",
  New:"Bago",
  Add:"Idagdag",
  Make:"Gawin",
  Time:"Oras",
  Write:"Sumulat",
  Holiday:"Holiday",
  Holidays:"Mga Piyesta Opisyal",
  Next_Holiday:"Susunod na Holiday:",
  All_Done:"Tapos na!",
  Employees:"Mga empleyado",
  Payees:"Mga nagbabayad",
  Job:"Trabaho",
  Jobs:"Mga trabaho",
  Invoice:"Invoice",
  Invoices:"Mga invoice",
  Vacations:"Bakasyon",
  Cheques:"Mga tseke",
  Brand_Cheques:"Ang iyong Brand",
  Payment:"Pagbabayad",
  Enable_Payment:"Paganahin ang Pagbabayad",
  Payments:"Mga pagbabayad",
  Schedule:"Iskedyul",
  Schedules:"Mga iskedyul",
  Timesheet:"Time Sheet",
  Timesheets:"Mga Time Sheet",
  Salary:"suweldo",
  New_Address:"Bagong tirahan",
  Address_2:"Address (Line 2)",
  _City:"lungsod",
  _State:"Estado/Prov",
  City:"Lungsod / Bayan",
  State:"Estado / Lalawigan",
  Postal:"Postal / ZIP Code",
  ZIP:"Postal / ZIP",
  Country:"Bansa",
  Addresses:"Mga address",
  Required_Options:"Mga Kinakailangang Opsyon",
  Optional_Options:"Opsyonal na Opsyon",
  Additional_Options:"Karagdagang Pagpipilian",
  Required:"Kailangan",
  Optional:"Opsyonal",
  Additional:"Dagdag",
  No_Addresses:"Walang mga Address",
  New_Address_Word:"Idagdag ang unang <a routerLink='/folder/Address/New'>Address</a>.",
  Select_Address:"Piliin ang Address",
  No_Address:"Walang mga Address",
  Print_Cheque:"Print Check",
  Print_Cheques:"Mag-print ng mga Check",
  Print_Cheque_Now:"Print Check Ngayon",
  Description:"Paglalarawan",
  Pay_To_The_Order_Of:"Magbayad sa Order ng:",
  Select_Date_Range:"Piliin ang Hanay ng Petsa",
  Select_Starting_Date:"Piliin ang Petsa ng Pagsisimula",
  Select_Ending_Date:"Piliin ang Petsa ng Pagtatapos",
  Select_Date:"Piliin ang Petsa",
  Cheque_Date:"Suriin ang Petsa",
  Cheque_Memo:"Suriin ang Memo",
  Blank_Cheque:"Blangkong Check",
  Blank:"Blanko",
  No_Cheques:"Walang mga tseke",
  No_Cheques_Word:"I-print ang iyong unang <a routerLink='/folder/Cheque/New'>Cheque</a>.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"Tingnan ang Larawan",
  View:"Tingnan",
  Modify:"Baguhin",
  Delete:"Tanggalin",
  Cheque_Paid:"Binayaran",
  New_Deduction:"Bagong Deduction",
  Title:"Pamagat",
  Frequency:"Dalas",
  Hourly:"Oras-oras",
  Daily:"Araw-araw",
  Weekdays:"Weekdays",
  BiWeekly:"2 Linggo",
  TriWeekly:"3 Linggo",
  Monthly:"Buwan-buwan",
  MiMonthly:"2 Buwan",
  Quarterly:"quarterly",
  Yearly:"Taon-taon",
  Every_Week:"Bawat Linggo",
  Every_Payroll:"Bawat Payroll",
  Every_Month:"Bawat buwan",
  Annually:"Taun-taon",
  Always_Scheduled:"Palaging Naka-iskedyul",
  Start_Date:"Petsa ng Pagsisimula",
  End_Date:"Petsa ng Pagtatapos",
  Start_Time:"Oras ng simula",
  End_Time:"Oras ng Pagtatapos",
  Deduction_Label:"Deduction Label",
  Notes:"Mga Tala",
  Options:"Mga pagpipilian",
  Enable:"Paganahin",
  Select_Deductions:"Piliin ang Mga Deduction",
  Deductions:"Mga pagbabawas",
  No_Deductions:"Walang Bawas",
  No_Deductions_Word:"Gawin ang iyong unang <a routerLink='/folder/Deduction/New'>Deduction</a>.",
  New_Employee:"Bagong Empleyado",
  No_Title:"Walang titulo",
  _Name:"Pangalan",
  About_Yourself:"Tungkol sa iyong sarili",
  Full_Name:"Buong pangalan",
  Birthday:"Birthday",
  Email:"Email",
  SMS:"SMS",
  Phone:"Telepono",
  Test:"Pagsusulit",
  Call:"Tumawag",
  Fax:"Fax",
  Printed_Note:"Naka-print na Tala",
  Position:"Posisyon",
  Job_Position:"Posisyon sa Trabaho",
  Select_a_Job:"Pumili ng Trabaho",
  Select_a_Salary:"Pumili ng Salary",
  Add_a_Deduction:"Magdagdag ng Deduction",
  Taxes:"Mga buwis",
  Add_Taxes:"Magdagdag ng mga Buwis",
  Date_of_Birth:"Araw ng kapanganakan",
  Email_Address:"Email Address",
  Phone_Number:"Numero ng telepono",
  Phone_Call:"Tawag sa telepono",
  Enable_on_Payroll:"Paganahin sa Payroll",
  Select_Employees:"Piliin ang Mga Empleyado",
  No_Employees:"Walang Empleyado",
  No_Employees_Word:"Idagdag ang iyong unang bagong <a routerLink='/folder/Employee/New'>Empleyado</a>.",
  No_Name:"Walang pangalan",
  Unemployeed:"Walang trabaho",
  Employeed:"Empleyado",
  Paid:"Binayaran",
  Enabled:"Pinagana",
  Disabled:"Hindi pinagana",
  Fire:"Apoy",
  Not_Available:"Hindi magagamit",
  Not_Available_Word:"I-print ang iyong unang <a routerLink='/folder/Invoice/New'>Invoice</a> at mabayaran.",
  Select_Invoices:"Piliin ang Mga Invoice",
  Print_Invoice_Word:"I-print ang iyong unang <a routerLink='/folder/Invoice/New'>Invoice</a> at mabayaran.",
  Invoice_Title:"Pamagat ng Invoice",
  Invoice_Date:"Petsa ng Invoice",
  Due_Date:"Takdang petsa",
  New_Job:"Bagong trabaho",
  Details:"Mga Detalye",
  Customize:"I-customize",
  Customize_Dashboard:"I-customize ang Dashboard",
  Components:"Mga bahagi",
  No_Components:"Walang Mga Bahagi",
  Main_Components:"Pangunahing Bahagi",
  Smaller_Components:"Mas Maliit na Mga Bahagi",
  Error_Loading_Page:"Error sa paglo-load ng page na ito.",
  Bank_Details:"Mga Detalye ng Bangko",
  About_Your_Job:"Tungkol sa Iyong Trabaho",
  Your_Schedule:"Iyong Iskedyul",
  Job_Title:"Titulo sa trabaho",
  Job_Description:"Deskripsyon ng trabaho",
  Job_Details:"Mga Detalye ng Trabaho",
  Enable_Job:"Paganahin ang Trabaho",
  Pay_Period:"Panahon ng pagbayad",
  Perpetually_Schedule:"Laging Mag-iskedyul",
  Select_Jobs:"Piliin ang Mga Trabaho",
  No_Jobs:"Walang Trabaho",
  Add_Jobs:"Magdagdag ng mga Trabaho",
  No_Jobs_Word:"Idagdag ang unang <a routerLink='/folder/Job/New'>Trabaho</a> sa listahan.",
  Count_Employees:"job.employee_count+' Employees'",
  Zero_Employees:"0 Mga empleyado",
  New_Leave:"Bagong Iwanan",
  Leave_Name:"Iwan ang Pangalan",
  Paid_Leave:"Bayad na bakasyon",
  Leave_Pay:"Mag-iwan ng Bayad",
  Indefinite_Leave:"Walang Katiyakang Iwanan",
  Indefinite_Payroll:"Walang Katiyakan na Payroll",
  Leave:"umalis",
  Add_Schedules:"Magdagdag ng mga Iskedyul",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Paganahin ang Absence",
  Select_Leaves:"Piliin ang Dahon",
  No_Leaves:"Walang Dahon ng Kawalan",
  Leave_Of_Absence:"leave of absence",
  Leaves_Of_Absence:"Dahon ng Kawalan",
  New_Leave_of_Absense:"Bagong leave of absence",
  No_Leaves_Word:"Idagdag ang unang <a routerLink='/folder/Leave/New'>Leave of Absence</a>.",
  Not_Enabled:"Hindi pinagana",
  Absences:"Mga pagliban",
  Payee:"Nagbabayad",
  New_Payee:"Bagong Nagbabayad",
  Payee_Identifier:"Tagatukoy ng Nagbabayad",
  Payee_Name:"Pangalan ng Nagbabayad",
  Payee_Title:"Pamagat ng Binabayaran",
  Payment_Memo:"Memo ng Pagbabayad",
  Select_Payees:"Piliin ang Mga Nagbabayad",
  No_Payees:"Walang mga nagbabayad",
  Add_Payee_Note:"Idagdag ang unang <a routerLink='/folder/Payee/New'>Payee</a>.",
  New_Payees:"Mga Bagong Nagbabayad",
  About_The_Payment_Schedule:"Iskedyul ng Pagbabayad",
  Your_Payroll_Schedule:"Awtomatikong Payroll",
  New_Payment:"Bagong Pagbabayad",
  Identifier:"Identifier",
  Selected:"Napili",
  Select:"Pumili",
  Memo:"Memo",
  Payment_Date:"Petsa ng Pagbabayad",
  Mark_as_Paid:"Markahan Bilang Bayad",
  Select_Payments:"Piliin ang Mga Pagbabayad",
  No_Payments:"Walang Pagbabayad",
  No_Payments_Word:"Lumikha ng unang <a routerLink='/folder/Payment/New'>Payment</a>.",
  Create_Payroll:"Lumikha ng Payroll",
  Properties:"Ari-arian",
  Payroll_Title:"Pamagat ng Payroll",
  Payroll_Notes:"Mga Tala sa Payroll",
  Payroll_Setup:"Setup ng Payroll",
  Tabulate_Payments:"I-tabulate ang mga Pagbabayad",
  Tabulate:"I-tabulate",
  By:"ni:",
  Payments_By:"Mga Pagbabayad Ni",
  Timesheets_And_Schedules:"Mga Time Sheet at Iskedyul",
  Both:"pareho",
  Items:"Mga bagay",
  Add_Payees:"Magdagdag ng mga nagbabayad",
  Add_Account:"Magdagdag ng account",
  Enable_Account:"Paganahin ang Account",
  Enable_Payroll:"Paganahin ang Payroll",
  Print_Payroll:"I-print ang Payroll",
  No_Payrolls:"Walang Payroll",
  No_Payroll_Word:"Gawin ang iyong unang <a routerLink='/folder/Payroll/New'>Payroll</a>.",
  View_more:"VIEW_MORE",
  Less:"mas mababa",
  Add_Payroll:"Magdagdag ng Payroll",
  Select_Payroll:"Piliin ang Payroll",
  About_Your_Salary:"Tungkol sa Iyong Sahod",
  Add_Salaries:"Magdagdag ng mga suweldo",
  Add_Salary:"Magdagdag ng suweldo",
  Salaries:"Mga suweldo",
  No_Salaries:"Walang suweldo",
  No_Salaries_Word:"Idagdag ang unang <a routerLink='/folder/Salary/New'>Suweldo</a>.",
  Select_Salaries:"Piliin ang Salaries",
  New_Salary:"Bagong Sahod",
  Salary_Name:"Tagatukoy ng suweldo",
  Enable_Salary:"Paganahin ang Salary",
  Salary_Amount:"Halaga ng suweldo",
  New_Schedule:"Bagong Iskedyul",
  Schedule_Title:"Pamagat ng Iskedyul",
  Add_Address:"Magdagdag ng Address",
  Repeat:"Ulitin",
  Design:"Disenyo",
  Edit_Design:"I-edit ang Disenyo",
  Edit_this_Design:"I-edit ang Disenyong ito",
  Repeat_Payment:"Ulitin ang Pagbabayad",
  Enable_Schedule:"Paganahin ang Iskedyul",
  Never:"Hindi kailanman",
  Select_Schedule:"Piliin ang Mga Iskedyul",
  No_Schedules:"Walang mga Iskedyul",
  No_Schedules_Word:"Lumikha ng unang <a routerLink='/folder/Schedule/New'>Schedule</a>.",
  New_Administrator:"Bagong Administrator",
  Username:"Username",
  First_Name:"Pangalan",
  Last_Name:"Huling pangalan",
  Add_an_Address:"Magdagdag ng Address",
  Payment_Limit:"Limit sa Bawat Pagbabayad",
  Total_Limit:"Kabuuang Limitasyon",
  Select_Accounts:"Piliin ang Mga Account",
  No_Administrators:"Walang Administrator",
  No_Administrators_Word:"Lumikha ng unang <a routerLink='/folder/Administrator/New'>Admin Account</a>.",
  New_Administrators_Word:"Idagdag ang unang <a routerLink='/folder/Administrator/New'>Administrator</a>",
  Cloud:"Ulap",
  Backup:"Backup",
  Enable_iCloud:"Paganahin ang iCloud",
  Enable_Google_Drive:"Paganahin ang Google Drive",
  Enable_Microsoft_OneDrive:"Paganahin ang Microsoft OneDrive",
  Automatically_Backup:"Awtomatikong I-backup",
  FTP_Settings:"Mga Setting ng FTP",
  URL_File_Prompt:"Mangyaring tukuyin ang lokasyon para sa isang .xls / .xlsx / .json file na ii-import:",
  URL_SQL_Prompt:"Pakitukoy ang lokasyon ng SQLite file na ii-import:",
  FTP_Backup:"FTP Backup",
  FTP_Import:"Pag-import ng FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Host",
  Port:"Port",
  Path:"Daan",
  Data_Path:"Daanan ng mga datos",
  Enable_FTP_Account:"Paganahin ang FTP Account",
  HostnameIP:"Host-name",
  Password:"Password",
  Connection_Port:"Port ng Koneksyon",
  Enable_MySQL_Database:"Paganahin ang MySQL Database",
  Log:"Log",
  Reset:"I-reset",
  Erase:"Burahin",
  Export:"I-export",
  Database:"Database",
  Upload_CSV:"Mag-upload ng CSV",
  Download_CSV:"I-download ang CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL Backup",
  Internal_Notes:"Mga Panloob na Tala",
  Root_Path:"Landas ng Root",
  Select_Backup:"Piliin ang Backup",
  Add_New_Backup:"Magdagdag ng Bagong Backup",
  First_Backup:"I-setup ang unang backup...",
  Backups:"Mga backup",
  Add_Backup:"Magdagdag ng Backup",
  No_Backups:"Walang mga backup na mahahanap.",
  Select_Backup_Type:"Piliin ang uri ng backup na gusto mong i-setup...",
  Backup_Type:"Uri ng Backup",
  FTP_Drive:"FTP Drive",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Magmaneho",
  Microsoft_OneDrive:"Magmaneho",
  Import_Fields:"Mag-import ng mga Field",
  Import_Fields_Word:"Gamitin ang seksyong ito upang piliin ang <a routerLink='/folder/Holiday/New'>Import</a> data.",
  Upload:"Mag-upload",
  Download:"I-download",
  Download_JSON:"I-download bilang JSON Data",
  Download_SQL:"I-download bilang SQL File",
  New_Bank:"Bagong Bangko",
  New_Account:"Bagong account",
  New_Bank_Account:"Bagong Bank Account",
  Upload_Image:"Mag-upload ng Larawan",
  Bank_Name:"Pangalan ng bangko",
  Bank_Address:"Address ng Bangko",
  Branch_Address:"Address ng Sangay",
  Address_on_Cheque:"Address",
  Cheque_Numbers:"Suriin ang mga Numero",
  Cheque_Details:"Suriin ang Mga Detalye",
  Bank_Logo:"Logo ng Bangko",
  Cheque_ID:"Suriin ang ID",
  Starting_Cheque_ID:"Simula sa Check ID",
  Transit_Number:"Numero ng Transit",
  Institution_Number:"Numero ng Institusyon",
  Designation_Number:"Numero ng Pagtatalaga",
  Account_Number:"Account Number",
  Limits:"Mga limitasyon",
  Default_Limits:"Mga Default na Limitasyon",
  Over_Limit:"Over Limit",
  Under_Limit:"Sa ilalim ng Limitasyon",
  Payroll_Limit:"Limitasyon sa Payroll",
  Enable_Bank_Account:"Paganahin ang Bank Account",
  Select_Account:"Piliin ang Account",
  No_Bank_Account:"Walang Bank Account",
  No_Bank_Account_Word:"Idagdag ang unang <a routerLink='/folder/Accounts/New'>Bank Account</a>.",
  Bank_Accounts:"Mga Bank Account",
  No_Accounts:"Walang Accounts",
  No_Accounts_Note:"Idagdag ang unang <a routerLink='/folder/Accounts/New'></a>.",
  Cheque_Designer:"Suriin ang Designer",
  Cheque_Design:"Suriin ang Disenyo",
  Select_Design:"Pumili ng isang Disenyo",
  Cheque_Designs:"Suriin ang Mga Disenyo",
  No_Cheque_Designs:"Walang Check Designs",
  No_Cheque_Designs_Word:"Lumikha ng iyong sariling <a routerLink='/folder/Settings/Cheque/Design/New'>Cheque Design</a>.",
  Set_Default:"Itakda bilang Default",
  Default:"Default",
  Remove:"Alisin",
  Folder:"Folder",
  Edit:"I-edit",
  LoadingDots:"Naglo-load...",
  Add_a_New_File:"Magdagdag ng <a href='#' (click)='add()'>Bagong File</a> sa",
  this_folder:"folder na ito",
  FTP_Backup_Settings:"Mga Setting ng Pag-backup ng FTP",
  Secure_File_Transfer:"Secure na Paglipat ng File",
  New_Holiday:"Bagong Holiday",
  Add_Holiday:"Idagdag ang Holiday",
  Holiday_Name:"Pangalan ng Holiday",
  Additional_Pay:"Karagdagang Bayad",
  Enable_Holiday:"Paganahin ang Holiday",
  Select_Holidays:"Piliin ang Mga Piyesta Opisyal",
  No_Holidays:"Walang Piyesta Opisyal",
  No_Holidays_Word:"Idagdag ang unang <a routerLink='/folder/Holiday/New'>Public Holiday</a>.",
  New_Import:"Bagong Import",
  Import_Data:"Mag-import ng Data",
  Import_Data_Word:"Piliin ang uri ng file o paraan ng pag-upload na iyong pinili.<br /> Magagawa mong piliin ang alinmang na-import na mga field sa isang file na tumutugma sa alinmang parameter sa app pagkatapos mag-upload ng sinusuportahang file.",
  Import_File:"Import ng file",
  Link_To_File:"Link sa File",
  Select_File:"Piliin ang File",
  New_Moderator:"Bagong Moderator",
  Allow_Moderation:"Payagan ang Pag-moderate",
  Create_Paycheques:"Lumikha ng mga Paycheque",
  Edit_Paycheques_and_Data:"I-edit ang Mga Paycheque at Data",
  Destroy_Data_and_Paycheques:"Wasakin ang Data at mga Paycheque",
  Bonus_Percentage:"Porsiyento ng Paycheque",
  Fixed_Amount:"Nakapirming Halaga",
  Select_Moderator:"Piliin ang Moderator",
  No_Moderators:"Walang mga Moderator",
  Moderators:"Mga moderator",
  Moderator_Account:"Lumikha ng unang <a routerLink='/folder/Administrator/New'>Moderator Account</a>.",
  No_Moderators_Word:"Idagdag ang unang <a routerLink='/folder/Administrator/New'>Moderator</a>.",
  Defaults:"Mga Default",
  Provide_Us_Info:"Magbigay ng Impormasyon",
  Setup_Your_Printer:"I-setup ang Iyong Printer",
  Your_Company:"Tungkol sa Iyong Kumpanya",
  Company_Name:"pangalan ng Kumpanya",
  Currency:"Pera",
  Default_Currency:"Default na Pera",
  Base_Monthly_Income:"Buwanang Kita",
  Protection:"Proteksyon",
  App_Protection:"Proteksyon ng App",
  PIN_Code_Protection:"Proteksyon ng PIN Code",
  App_Protection_Word:"Paganahin ang mga paraan ng seguridad na makakatulong sa pagprotekta sa iyong account.",
  PIN_Code:"Pin code",
  Change_PIN:"Baguhin ang PIN",
  New_Password:"Bagong Password",
  Geofence_Protection:"Proteksyon ng Geo-Fence",
  Geofence_Area:"Itakda ang Lugar",
  Distance:"Distansya",
  Setup_Now:"I-setup Ngayon",
  Mile:"Mile",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Facial recognition",
  Face:"Mukha",
  Setup:"Setup",
  Label:"Label",
  Password_Protection:"Proteksyon ng Password",
  Modify_Password:"Baguhin ang Password",
  New_Tax_Entry:"Bagong Tax Entry",
  New_Tax:"Bagong Buwis",
  Tax_Label:"Tax Label",
  Perpetually_Enabled:"Laging Pinagana",
  Select_Taxes:"Piliin ang Mga Buwis",
  Tax_Deductions:"Mga Bawas sa Buwis",
  No_Tax_Deductions:"Walang Bawas sa Buwis",
  No_Tax_Deductions_Word:"Idagdag ang unang <a routerLink='/folder/Tax/New'>Buwis</a> na bawas.",
  New_Timer:"Bagong Timer",
  Start:"Magsimula",
  Stop:"Tumigil ka",
  Start_Timer:"Simulan ang Timer",
  Stop_Timer:"Itigil ang Timer",
  Timer_Active:"Aktibo ang timer",
  Timer:"Timer:",
  Timer_Running:"Timer: (Tumakbo)",
  Save_Timer:"I-save ang Timer",
  New_Timesheet:"Bagong Time Sheet",
  Select_Timesheets:"Piliin ang Mga Time Sheet",
  Work_Notes:"Mga Tala sa Trabaho",
  Entry_Title:"Pamagat ng Entry",
  No_Timesheets:"Walang Time Sheet",
  No_Timesheets_Word:"Idagdag ang unang <a routerLink='/folder/Timesheet/New'>Time Sheet</a>.",
  Timesheet_Submitted:"Naisumite ang Time Sheet",
  Timesheet_Congrats:"Binabati kita! Ang iyong time Sheet ay matagumpay na naisumite. Salamat!",
  Timesheet_Copy:"Upang makatanggap ng kopya ng iyong mga dokumento, mangyaring ibigay sa amin ang iyong email address at/o numero ng mobile phone.",
  Submit:"Ipasa",
  Allow_Notifications:"Payagan ang Mga Notification",
  Untitled_Entry:"Bagong Entry",
  Untitled_Bank:"Walang Pamagat na Bangko",
  Timesheet_Entry:"Time Sheet Entry",
  Work_Description:"Paglalarawan ng Trabaho",
  Enable_Timesheet:"Paganahin ang Time Sheet",
  Submit_Timesheet:"Isumite ang Time Sheet",
  Vacation:"Bakasyon",
  New_Vacation:"Bagong Bakasyon",
  Vacation_Name:"Pangalan ng Bakasyon",
  Paid_Vacation:"Bayad na Bakasyon",
  Vacation_Pay:"Bayad sa Bakasyon",
  Enable_Vacation:"Paganahin ang Bakasyon",
  Select_Vacations:"Piliin ang Bakasyon",
  No_Vacations:"Walang Bakasyon",
  No_Vacations_Word:"Lumikha ng unang <a routerLink='/folder/Vacation/New'>Bakasyon</a> entry.",
  Payroll_Schedule:"Iskedyul ng Payroll",
  Next_Payroll:"Susunod na Payroll:",
  Upcoming_Payroll:"Paparating na Payroll",
  No_Upcoming_Payroll:"Walang Paparating na Payroll",
  Address_Book:"Address Book",
  Archived_Documents:"Mga Naka-archive na Dokumento",
  Dev_Mode:"Application Sa Development Mode",
  Administrators:"Mga tagapangasiwa",
  Privacy:"Pagkapribado",
  None:"wala",
  Select_Signature:"Piliin ang Lagda",
  No_Signatures:"Walang Pirma",
  No_Signatures_Word:"Idagdag ang unang <a routerLink='/folder/Signature/New'>Lagda</a>.",
  Repeat_Indefinitely:"Ulitin nang Walang Katiyakan",
  Sign:"Tanda",
  Sign_Here:"Pumirma dito",
  Date_Signed:"Petsa ng paglagda",
  Signature_Pad:"Signature Pad",
  Account_Holder:"May hawak ng Account",
  Account_Properties:"Mga Katangian ng Account",
  Name_On_Cheque:"Pangalan sa Check",
  Server_Hostname:"Server Host-name / IP",
  Printers:"Mga Printer",
  No_Printers:"Walang mga Printer",
  Printer_Exists:"Mayroon nang printer na may ganitong pangalan.",
  No_Printers_Word:"Idagdag ang pinakaunang <a routerLink='/folder/Printer/New'>Printer</a>.",
  No_Printer_Alert:"Walang tinukoy na printer. Gusto mo bang mag-set up ng printer?",
  Add_Printer:"Magdagdag ng Printer",
  New_Printer:"Bagong Printer",
  Printer_Hostname:"Printer Host-name / IP",
  Printer_Label:"Label ng Printer",
  Printer_Protocol:"Protokol ng Printer",
  Protocol:"Protocol",
  Email_Print:"Email",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Socket",
  Print_Job:"Print Job",
  Printed:"Naka-print",
  Not_Printed:"Hindi nakalimbag",
  Print_Jobs:"Mga Trabaho sa Pag-print",
  Print_Queue:"Print Queue",
  No_Print_Jobs:"Walang Mga Trabaho sa Pag-print",
  No_Prints:"Gumawa ng bagong <a routerLink='/folder/Cheque/New'>Cheque</a> para i-print.",
  Prints:"Mga print",
  Find_Printer:"Maghanap ng Printer",
  Find_AirPrint_Devices:"Maghanap ng Mga AirPrint Device",
  Select_Printer:"Piliin ang Printer",
  System_UI:"System UI",
  Printer:"Printer",
  Status:"Katayuan",
  Preview:"Silipin",
  Enable_Print_Job:"Paganahin ang Print Job",
  Queue_Weight:"Timbang ng Pila",
  Unlimited:"Walang limitasyon",
  Show_Advanced_Printer_Options:"Ipakita ang Advanced na Mga Opsyon sa Printer",
  Advanced:"Advanced",
  Location:"Lokasyon",
  Note:"Tandaan",
  Queue_Name:"Pangalan ng Queue",
  Pages_Printed_Limit:"Mga Pahina na Naka-print na Limitasyon",
  MultiPage_Idle_Time:"Maramihang-pahinang Oras ng Paghihintay",
  Page_Count_Limit:"Limitasyon sa Bilang ng Pahina",
  Page_Orientation:"Oryentasyon ng Pahina",
  Portrait:"Larawan",
  Landscape:"Landscape",
  Duplex:"Duplex",
  Double_Sided_Printing:"Dalawang Panig",
  Duplex_Mode:"Duplex Mode",
  Duplex_Short:"Maikli",
  Duplex_Long:"Mahaba",
  Duplex_None:"wala",
  Color_And_Quality:"Kulay At Kalidad",
  Monochrome:"Monochrome",
  Photo_Quality_Prints:"Mga Print na Kalidad ng Larawan",
  Printer_Email:"Email ng Printer",
  Automatically_Downsize:"Awtomatikong Bawasan ang Laki",
  Paper:"Papel",
  Paper_Name:"Pangalan ng Papel",
  Paper_Width:"Lapad ng Papel",
  Paper_Height:"Taas ng Papel",
  Paper_Autocut_Length:"Papel Auto-cut Haba",
  Margins:"Mga margin",
  Page_Margins:"Mga Margin ng Pahina",
  Page_Margin_Top:"Margin sa Nangungunang Pahina",
  Page_Margin_Right:"Right Page Margin",
  Page_Margin_Bottom:"Margin sa Ibabang Pahina",
  Page_Margin_Left:"Margin sa Kaliwang Pahina",
  Add_Employees:"Magdagdag ng mga Empleyado",
  Header:"Header",
  Print_A_Page_Header:"Mag-print ng Header ng Pahina",
  Header_Label:"Label ng Header",
  Header_Page_Index:"Index ng Pahina ng Header",
  Header_Font_Name:"Font ng Header",
  Select_Font:"Piliin ang Font",
  Font_Selector:"Tagapili ng Font",
  Header_Font_Size:"Font ng Header",
  Header_Bold:"Naka-bold ang Header",
  Header_Italic:"Header Italic",
  Header_Alignment:"Pag-align ng Header",
  Left:"Kaliwa",
  Center:"Gitna",
  Right:"Tama",
  Justified:"Nabigyang-katwiran",
  Header_Font_Color:"Kulay ng Header",
  Select_Color:"Piliin ang Kulay",
  Footer:"Footer",
  Print_A_Page_Footer:"Mag-print ng Footer ng Pahina",
  Footer_Label:"Label ng Footer",
  Footer_Page_Index:"Index ng Pahina ng Footer",
  Footer_Font_Name:"Font ng Footer",
  Fonts:"Mga font",
  Done:"Tapos na",
  Select_Fonts:"Piliin ang Mga Font",
  Footer_Font_Size:"Laki ng Footer",
  Footer_Bold:"Footer Bold",
  Footer_Italic:"Footer Italic",
  Footer_Alignment:"Pag-align ng Footer",
  Footer_Font_Color:"Kulay ng Footer",
  Page_Copies:"Mga Kopya ng Pahina",
  Enable_Printer:"Paganahin ang Printer",
  Remote_Logging:"Malayong Pag-log",
  Log_Server:"Log Server",
  Encryption:"Pag-encrypt",
  Random_Key:"Random Key",
  Encryption_Key:"Encryption Key",
  Cheques_Webserver:"Custom na Database",
  Learn_How:"Matuto kung paano",
  Signature:"Lagda",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Tingnan ang Lagda",
  Printed_Signature:"Naka-print na Lagda",
  Digitally_Sign:"Digitally Sign",
  Digital_Signature:"Digital na Lagda",
  Add_Signature:"Magdagdag ng Lagda",
  Add_Your_Signature:"Idagdag ang Iyong Lagda",
  Enable_Signature:"Paganahin ang Lagda",
  Digitally_Signed:"Digitally Signed",
  Insert_Error:"Hindi ma-save ang tseke dahil sa mga isyu sa database.",
  Delete_Confirmation:"Sigurado ka bang gusto mong tanggalin ang impormasyong ito?",
  Discard_Confirmation:"Sigurado ka bang gusto mong itapon ang impormasyong ito?",
  Discard_Bank_Confirmation:"Sigurado ka bang gusto mong itapon ang account na ito?",
  Discard_Printer_Confirmation:"Sigurado ka bang gusto mong itapon ang printer na ito?",
  Delete_Bonus_Confirmation:"Sigurado ka bang gusto mong tanggalin ang bonus na ito?",
  Delete_Invoice_Confirmation:"Sigurado ka bang gusto mong tanggalin ang invoice na ito?",
  Generated_Cheque:"Binuo ng Check",
  Generated_Invoice:"Binuo na Invoice",
  Schedule_Start_Time:"Iskedyul ng Pagsisimula",
  Schedule_End_Time:"Pagtatapos ng Iskedyul",
  New_Call:"Bagong Tawag",
  No_Contacts:"Walang Mga Contact",
  No_Contacts_Word:"Lumilitaw ang mga administrator, moderator, empleyado, at nagbabayad bilang mga contact.",
  PDF_Subject:"pananalapi",
  PDF_Keywords:"payroll paycheque PDF check checks",
  Printer_Setup:"Printer setup",
  Printer_Selection:"Pagpili ng Printer",
  New_Fax:"Bagong Fax",
  New_Fax_Message:"Bagong Fax Message",
  Fax_Machine:"Fax Machine",
  Fax_Name:"Pangalan ng Fax",
  Fax_Email:"Fax Email",
  Fax_Number:"Numero ng Fax",
  Contents:"Mga nilalaman",
  Fax_Body:"Katawan ng Pahina",
  Header_Word:"Header:",
  Header_Text:"Header text",
  Include_Header:"Isama ang Header",
  Include_Footer:"Isama ang Footer",
  Footer_Word:"Footer:",
  Footer_Text:"Teksto ng Footer",
  Attach_a_Cheque:"Maglakip ng Tsek",
  Add_Deduction:"Magdagdag ng Deduction",
  Enable_Fax:"Magpadala ng Fax",
  Select_Fax:"Piliin ang Fax",
  No_Faxes:"Walang mga Fax",
  Faxes:"Mga Fax",
  Save_and_Send:"Magpadala ng Fax",
  Save_and_Pay:"Mag-ipon at Magbayad",
  WARNING:"BABALA:",
  Remember:"Tandaan",
  Printing:"Pagpi-print",
  Printing_Complete:"Kumpleto na ang Pagpi-print!\n\n",
  of:"ng",
  There_Were:"Mayroong",
  Successful_Prints:"matagumpay na mga pag-print at",
  Unsuccessful_Prints:" hindi matagumpay na mga pag-print.",
  PrinterError:"Paumanhin! Nagkaroon ng error.",
  Printing_:"Nagpi-print...",
  PrinterSuccess:"Matagumpay na na-print ang dokumento.",
  PrintersSuccess:"Matagumpay na na-print ang mga dokumento.",
  New_Message:"Bagong mensahe",
  New_Messages:"Mga Bagong Mensahe",
  Read_Message:"Basahin ang Mensahe",
  Write_Message:"Magsulat ng mensahe",
  Send_Message:"Magpadala ng Mensahe",
  Subject:"Paksa",
  Message:"Mensahe",
  Writing:"Nagsusulat...",
  Send:"Ipadala",
  Set_Date:"Nakatakdang petsa",
  Set_Time:"Itakda ang Oras",
  Recieve:"Tumanggap",
  Set_as_Default:"Itakda bilang Default",
  Default_Account:"Default account",
  Default_Design:"Default na Disenyo",
  Multiple_Cheques:"Tatlong tseke",
  Account_Mode:"Mode ng Account",
  Multiple_Cheques_Description:"Tatlong tseke bawat pahina.",
  Check_and_Table:"Check & Table",
  Check_including_Table:"Tingnan sa talahanayan ng accounting.",
  Check_Mailer:"Suriin ang Mailer",
  Check_Mailer_Window:"Tingnan gamit ang isang window ng address.",
  DocuGard_Table_Top:"Malaking Check & Table",
  DocuGard_Table_Middle:"Malaking Check at Table (Gitna)",
  DocuGard_Table_Bottom:"Malaking Check at Table (Ibaba)",
  DocuGard_Mailer_Top:"Malaking Check Mailer",
  DocuGard_Mailer_Middle:"Malaking Check Mailer (Gitna)",
  DocuGard_Mailer_Bottom:"Malaking Check Mailer (Ibaba)",
  DocuGard_Three_Cheques:"Tatlong Malaking Tsek",
  DocuGard_Cheque_Top:"Isang Malaking Check",
  DocuGard_Cheque_Middle:"Malaking Check (Gitna)",
  DocuGard_Cheque_Bottom:"Malaking Check (Ibaba)",
  DocuGard_Three_Cheques_Window:"Tatlong tseke sa isang pahina.",
  DocuGard_Table_Top_Window:"Talaan ng tseke at kita.",
  DocuGard_Table_Middle_Window:"Talaan ng tseke at kita.",
  DocuGard_Table_Bottom_Window:"Talaan ng tseke at kita.",
  DocuGard_Mailer_Top_Window:"Tsek, mesa, at address.",
  DocuGard_Mailer_Middle_Window:"Tsek, mesa, at address.",
  DocuGard_Mailer_Bottom_Window:"Tsek, mesa, at address.",
  DocuGard_Cheque_Top_Window:"Tingnan kung may ligtas na papel.",
  DocuGard_Cheque_Middle_Window:"Tingnan kung may ligtas na papel.",
  DocuGard_Cheque_Bottom_Window:"Tingnan kung may ligtas na papel.",
  Basic_Cheque:"Isang Check",
  Basic_Cheque_Print:"Mag-print ng isang tseke.",
  Error_Setting_As_Paid:"Error sa Setting bilang Bayad",
  Add_Attachment:"Magdagdag ng Attachment",
  PrinterUnavailable:"Hindi Available ang Printer",
  CreditCardComponent:"Mga kard",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Bagong Deposito",
  Deposits:"Mga deposito",
  No_Deposits:"Walang Deposito",
  Credit_Card_Deposit:"Credit Card",
  New_Credit_Card_Deposit_Message:"Deposito sa Credit Card",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Deposito ng BitCoin",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Interac Transfer",
  Payments_Limit:"Limitasyon sa Pagbabayad",
  No_Payment_Limit:"Walang Limitasyon sa Pagbabayad",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Deposito sa PayPal",
  No_Deposits_Word:"Idagdag ang unang kumpanya na <a routerLink='/folder/Deposit/New'>Deposito</a>.",
  No_Documents_Specified:"Walang mga dokumentong tinukoy para sa pag-print",
  No_Printers_Added:"Walang nakitang mga printer. Pumunta sa Mga Setting > Mga Printer para magdagdag ng isa.",
  DB_Erase_Prompt:"Ganap na burahin ang buong database? BABALA: Mawawala ang lahat ng nakaimbak na impormasyon!",
  Console_Warning:"Huwag mag-paste ng anumang text sa console na ito. Maaari mong ilagay ang iyong sarili at/o ang iyong kumpanya sa seryosong panganib.",
  No_Faxes_Word:"Lumikha ng unang <a routerLink='/folder/Fax/New'>Fax</a>.",
  Cheque_Delete_Confirmation:"Sigurado ka bang gusto mong tanggalin ang tseke na ito?",
  Design_Delete_Confirmation:"Sigurado ka bang gusto mong tanggalin ang disenyong ito?",
  Cheque_Pay_Confirmation:"Markahan ang tseke na ito bilang bayad? HINDI ito lalabas sa print queue.",
  Payment_Pay_Confirmation:"Markahan ang bayad na ito bilang bayad? HINDI ito lalabas sa check queue.",
  Delete_Deduction_Confirmation:"Sigurado ka bang gusto mong tanggalin ang bawas na ito?",
  Delete_Job_Confirmation:"Sigurado ka bang gusto mong tanggalin ang trabahong ito?",
  Delete_Timesheet_Confirmation:"Sigurado ka bang gusto mong tanggalin ang timesheet na ito?",
  Delete_Schedule_Confirmation:"Sigurado ka bang gusto mong tanggalin ang iskedyul na ito?",
  Delete_Setting_Confirmation:"Sigurado ka bang gusto mong i-reset ang setting na ito?",
  Delete_Fax_Confirmation:"Sigurado ka bang gusto mong tanggalin ang fax na ito?",
  Delete_File_Confirmation:"Sigurado ka bang gusto mong tanggalin ang file na ito?",
  Delete_Vacation_Confirmation:"Sigurado ka bang gusto mong tanggalin ang bakasyong ito?",
  Delete_Printer_Confirmation:"Sigurado ka bang gusto mong tanggalin ang printer na ito?",
  Remove_Design_Confirmation:"Sigurado ka bang gusto mong tanggalin ang disenyong ito?",
  Delete_Payroll_Confirmation:"Sigurado ka bang gusto mong tanggalin ang payroll na ito?",
  Send_Fax_Email_Confirmation:"Gusto mo bang i-fax at i-email ang mga dokumentong ito?",
  Send_Email_Confirmation:"Gusto mo bang i-email ang dokumentong ito?",
  Send_Fax_Confirmation:"Gusto mo bang i-fax ang dokumentong ito?",
  Error_Generating_PDF:"Paumanhin. Nagkaroon ng error sa pagbuo ng dokumentong ito.",
  PDF_Error_Saving_Image:"Paumanhin. Nagkaroon ng error sa pag-save ng PDF na larawan ng dokumentong ito.",
  Test_Printer_Confirmation:"Gusto mo bang mag-print ng test page sa printer na ito?",
  Save_Timesheet_Prompt:"Mangyaring magdagdag ng 'Pamagat' o pindutin ang 'Start Timer' upang i-save.",
  Remove_Geofence_Prompt:"Sigurado ka bang gusto mong alisin ang lokasyon ng geo-fence na ito?",
  Delete_Employee_Confirmation:"Sigurado ka bang gusto mong tanggalin",
  Fire_Employee_Confirmation:"Sigurado ka bang gusto mong magpaputok",
}