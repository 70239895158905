export const Gd = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Dlighe-sgrìobhaidh &copy; 2023",
  black:"Dubh",
  green:"Uaine",
  gold:"Òr",
  blue:"Gorm",
  brown:"Donn",
  purple:"Purpaidh",
  pink:"Pinc",
  red:"Dearg",
  Swatches:"Swatches",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Lù",
  Saturation:"Sàthadh",
  Lightness:"Aotromachd",
  Upgrade_To_Pro:"Àrdachadh gu Pro",
  AllFeaturesInclude:"Tha na feartan uile a’ toirt a-steach:",
  PrintUnlimitedCheques:"Clò-bhuail seicichean gun chrìoch",
  PremiumChequeDesigns:"Dealbhadh Premium Check",
  ManageUnlimitedEmployees:"Stiùirich luchd-obrach gun chrìoch",
  AddUnlimitedPayees:"Cuir ris Pàighidhean gun chrìoch",
  CreateUnlimitedPayrolls:"Cruthaich pàighidhean pàighidh gun chrìoch",
  UnlimitedSchedulesandTimeSheets:"Clàran-ama agus clàran-ama gun chrìoch",
  BulkPayPalPayouts:"Pàigheadh mòr PayPal",
  UnlimitedBankAccounts:"Cunntasan Banca gun chrìoch",
  ManageMultipleCompanies:"Stiùirich ioma-chompanaidhean",
  TrackInsurancePolicies:"Cum sùil air Poileasaidhean Àrachais",
  Bio_MetricProtection:"Dìon bith-mheatrach",
  Geo_FenceLock_OutProtection:"Dìon glasaidh geo-fheansa",
  Multiple_Companies_Word:"Chan eil riaghladh ioma-chompanaidh ri fhaighinn às aonais Premium Checks.",
  PayPal_Payouts_Word:"Tha pàighidhean PayPal à comas às aonais Premium Checks.",
  PINProtection:"Dìon PIN",
  PasswordProtection:"Dìon Facal-faire",
  May_Require_Approval:"Is dòcha gu feum cead.",
  Subscribe:"Subscribe",
  Billed:"Bile",
  Up:"Suas",
  Down:"Sìos",
  Positioning:"Suidheachadh",
  Marker:"Comharraiche",
  Drag_Marker:"Comharra Slaod",
  Tagline:"Clò-bhuail seicichean agus Clàr-pàighidh Tabulate",
  Marker_Word:"Cleachd na Comharran gus an eileamaid a mheudachadh.",
  Pinch_Zoom:"Zoom pinch",
  Pinch_Word:"Pinch gus an eileamaid a leudachadh.",
  Drag:"Slaod",
  Drag_Word:"Cleachd do mheur gus eileamaidean a shlaodadh.",
  subscription_alias_word:"Fo-sgrìobhadh ùrachadh fèin-ghluasadach",
  premium_alias_word:"Pasgan ùrachadh aon-ùine",
  print_alias_word:"Clò-bhuail seicichean fa leth",
  mode_alias_word:"Modh",
  Pro:"Pro",
  Pro_word:"Tha feum air dreach pro.",
  Cant_Delete_Default_Company_Word:"Duilich, chan urrainn dhut do chompanaidh bunaiteach a sguabadh às.",
  Reinsert_Default_Designs:"Cuir a-steach dealbhaidhean bunaiteach a-rithist",
  Reinsert_Default_Designs_word:"A bheil thu airson na dealbhaidhean bunaiteach a chuir a-steach a-rithist?",
  Subscription_Active_Disable_Word:"Tha an fho-sgrìobhadh seo gnìomhach. Am bu toil leat an fho-sgrìobhadh seo gu Checks a chur dheth?",
  Company_Logo:"Suaicheantas na companaidh",
  ZERO_:"NEONI",
  Disclaimer:"Àicheadh",
  Privacy_Policy:"Poileasaidh Dìomhaireachd",
  EULA:"Thoir sùil air EULA",
  Terms_Of_Service:"Teirmean seirbheis",
  Terms_Of_Use:"Cumhachan Cleachdaidh",
  Refunds:"Poileasaidh Ath-dhìolaidh",
  Single_Print:"1 Feuch",
  Two_Prints:"2 Seic",
  Five_Prints:"5 Seic",
  Ten_Prints:"10 Seic",
  Fifteen_Prints:"15 Seic",
  Twenty_Prints:"20 Seic",
  Thirty_Prints:"30 Seic",
  Image_Added:"Ìomhaigh air a chur ris",
  Image_Preview:"Ro-shealladh Ìomhaigh",
  No_Image_Was_Selected:"Cha deach dealbh a thaghadh.",
  Cheques_Unlimited:"Checks Unlimited",
  _Subscription:"Fo-sgrìobhadh",
  Subscription:"Sgrùdaidhean - 1 mìos",
  Two_Month_Subscription:"Sgrùdaidhean - 2 mìosan",
  Three_Month_Subscription:"Sgrùdaidhean - 3 mìosan",
  Six_Month_Subscription:"Sgrùdaidhean - 6 mìosan",
  Twelve_Month_Subscription:"Sgrùdaidhean - 12 mìosan",
  Cheques_Are_Available:"Tha seicichean rim faighinn airson an clò-bhualadh.",
  Upgrade_Required_Two:"Tagh pasgan agus tap dùbailte air a’ phutan prìs gus do cheannach a thòiseachadh. Clò-bhuail sgrùdaidhean proifeasanta làn-dath ann an diogan.",
  Month:"Mìos",
  Due:"Air sgàth:",
  Expires:"A’ tighinn gu crìch:",
  Subscription_Active:"Fo-sgrìobhadh Gnìomhach",
  Subscription_Inactive:"Fo-sgrìobhadh neo-ghnìomhach",
  Purchase_Additional_Cheques:"Ceannaich seicichean a bharrachd?",
  Printable_Cheque:"Seic clò-bhuailte",
  Printable_Cheques:"Sgrùdaidhean clò-bhuailte",
  Printable_Cheque_Word:"tha seic ri fhaighinn air do chunntas.",
  Printable_Cheques_Word:"tha seicichean rim faighinn air do chunntas.",
  Max_Amount_Message:"Tha an t-suim a shònraich thu air ruighinn thairis air an t-suim as àirde a chaidh a shuidheachadh airson an t-siostam seo:",
  Terms_Required_Word:"Feumaidh tu aontachadh ris an aonta seo mus lean thu a’ cleachdadh Cheques.",
  Subscriptions:"Fo-sgrìobhaidhean",
  Product_Upgrades:"Àrdachaidhean",
  Mailed_Out_Cheques:"Sgrùdaidhean post-d",
  Enter_A_Company_Name:"Feuch an cuir thu a-steach ainm companaidh.",
  Single_Cheques:"Seicichean Singilte",
  Cheque_Golden:"Seic Òir",
  Cheque_Golden_Window:"Dealbhadh seic òir.",
  Cheque_Green:"Seic uaine",
  Cheque_Green_Window:"Dealbhadh seic glas.",
  Cheque_Red:"Seic dearg",
  Cheque_Red_Window:"Dealbhadh seic dearg.",
  Cheque_Yellow:"Seic Buidhe",
  Cheque_Yellow_Window:"Dealbhadh seic buidhe.",
  Cheque_Statue_of_Liberty:"Ìomhaigh na Saorsa",
  Cheque_Statue_of_Liberty_Window:"Dealbhadh seic Ìomhaigh na Saorsa.",
  Cheque_Green_Wave:"Tonn Uaine",
  Cheque_Green_Wave_Window:"Dealbhadh seic glas.",
  Cheque_Golden_Weave:"Fighe Òir",
  Cheque_Golden_Weave_Window:"Dealbhadh seic òir eireachdail.",
  Cheque_Green_Sun:"Grian Uaine",
  Cheque_Green_Sun_Window:"Dealbhadh seic domhainn agus socair.",
  Cheque_Blue_Checkers:"Blue Checkers",
  Cheque_Blue_Checkers_Window:"Dealbhadh seic gorm.",
  Cashiers_Check:"Seic an Cashier",
  Cashiers_Check_Window:"Teamplaid stoidhle Cashier's Check.",
  Cheque_Aqua_Checkers:"Aqua checkers",
  Cheque_Aqua_Checkers_Window:"Dealbhadh seic Aqua.",
  Cheque_Golden_Checkers:"Golden Checkers",
  Cheque_Golden_Checkers_Window:"Dealbhadh seic òir.",
  Upgrade_Unavailable:"Ùrachaidhean nach eil rim faighinn",
  Bank_Code_Protection:"Dìon Àireamh Banca",
  Bank_Code_Protection_Word:"Dìon na h-àireamhan banca agad bho bhith gan cleachdadh air an aplacaid seo a tha a’ ruith air inneal eile no airson cleachdaiche eile. Tha an gnìomh seo IRREVERSIBLE. Lean air adhart?",
  Bank_Code_Protection_Blocked_Word:"Tha na h-àireamhan banca a tha thu a’ feuchainn ri chleachdadh glèidhte airson neach-cleachdaidh no inneal eile.",
  Bank_Code_Protection_Error_Word:"Dh'fhàillig dearbhadh àireamh. Feuch an ceangail thu ris an eadar-lìon agus feuch ris a' chunntas banca seo a chur ris a-rithist.",
  Bank_Code_Protection_Set_Error_Word:"Feumaidh dìon àireamh banca gum bi thu ceangailte ris an eadar-lìn. Dèan ceangal is feuch ris a-rithist.",
  Upgrade_Unavailable_Word:"Duilich, tha duilgheadas againn do dhearbhadh. Chan eil fo-sgrìobhaidhean agus ùrachadh gu Checks rim faighinn an-dràsta airson an ceannach san sgìre agad.",
  PayPal_Payment_Preview:"Ro-shealladh pàighidh PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Tagh PayPal",
  PayPal_Applications:"Iarrtasan PayPal",
  Purchase_With_Apple_Pay:"Ceannaich le Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Companaidhean",
  Insurance:"Àrachas",
  New_PayPal:"PayPal ùr",
  Pay_By:"Pàigh le",
  Insure:"Àrachas",
  Miles:"Mìle",
  Payment_Method:"Dòigh pàighidh",
  Select_Policies:"Tagh Poileasaidhean",
  Policies:"Poileasaidhean",
  Policy:"Poileasaidh",
  No_PayPal_Account:"Gun chunntas PayPal",
  No_Policies:"Chan eil Poileasaidh Àrachais ann",
  New_Policy:"Poileasaidh Ùr",
  PayPal_Payment:"Pàigheadh PayPal",
  PayPal_Payments:"Pàighidhean PayPal",
  No_Payment_Selected:"Chan eil Pàigheadh air a thaghadh",
  Sending_Payment_Word:"Fuirich ort... Tha am pàigheadh seo ga chur.",
  Sending_Payments_Word:"Fuirich ort... Tha pàighidhean gan cur.",
  No_Payment_Selected_PayPal:"Cha deach <a routerLink='/folder/Payments'>Pàigheadh ​​PayPal</a> a thaghadh airson a chur.",
  Payment_Sent:"Pàigheadh air a chuir",
  PayPal_Payment_Sent:"Chaidh am pàigheadh seo a chuir le PayPal.",
  Copay:"Copaidh",
  Dead:"Marbh",
  Alive:"Beò",
  Not_Dead:"Nach Marbh",
  Unclaimed:"Gun thagradh",
  Attempted:"Dh' fheuch",
  Deceased:"Chaochail",
  Claimed:"Air a thagradh",
  Unpaid:"Gun phàigheadh",
  Sending_Payment:"A 'cur Pàigheadh",
  Sending_Payments:"A 'cur Pàighidhean",
  Send_PayPal_Confirmation:"A bheil thu airson an gnothach seo a chuir le PayPal?",
  Send_PayPal_Confirmation_Word:"Brùth am putan uaine gus an gnothach seo a chuir.",
  Save_Payment_As_Unpaid:"Sàbhail am pàigheadh seo mar gun phàigheadh?",
  Payment_Pay_Confirmation_PayPal:"Sàbhail am pàigheadh seo mar a chaidh a phàigheadh?",
  No_Policies_Word:"Cuir ris a' chiad <a routerLink='/folder/Postage/New'>Poileasaidh Àrachais</a> an-dràsta.",
  Timesheet_Multiple_Delete_Confirmation:"A bheil thu cinnteach gu bheil thu airson iomadh duilleag-ama a sguabadh às?",
  Select_Multiple_Timesheets_Prompt:"Chan eil clàran-ama air an taghadh. Tagh co-dhiù aon chlàr-ama.",
  Select_Multiple_Policies_Prompt:"Cha deach poileasaidhean a thaghadh. Tagh co-dhiù aon phoileasaidh àrachais.",
  Policies_Multiple_Delete_Confirmation:"A bheil thu cinnteach gu bheil thu airson iomadh poileasaidh a sguabadh às?",
  Company:"Companaidh",
  Add_Company:"Cuir Companaidh ris",
  New_Company:"Cuir Companaidh ris",
  No_Companies:"Gun Chompanaidhean",
  Enable_Company:"Dèan comas air Companaidh",
  Select_Company:"Tagh Companaidh",
  The_Default_Company:"An leubail companaidh bunaiteach.",
  Manage_Companies:"Stiùirich companaidhean",
  No_Companies_Word:"Cleachdaidh seicichean companaidh bunaiteach.<br /> Cuir a' <a routerLink='/folder/Company/New'> a' chiad chompanaidh</a> ris.",
  Default_Company:"Companaidh bunaiteach",
  Cheques_Unlimited_Word:"Leigidh Checks Unlimited leat nas urrainn dhut de sheicean a chlò-bhualadh.",
  Cheques_Subscription_Word:"Leigidh fo-sgrìobhadh Checks leat na h-uimhir de sgrùdaidhean a chlò-bhualadh agus a thogras tu.",
  You_Own_This_Product:"Is ann leatsa a tha an toradh seo.",
  Your_Subscription_is_Active:"Tha an fho-sgrìobhadh agad gnìomhach.",
  Active_Products:"Bathar Gnìomhaichte",
  Purchase_Confirmation:"Ceannach",
  Purchase_Cheques:"Sgrùdaidhean Ceannach",
  Restore_Purchase:"Ath-nuadhachadh Ceannach",
  Erase_Purchase:"Sguab às Ceannach",
  Successfully_Purchased:"Air a cheannach gu soirbheachail",
  Enter_Your_Licence_Key:"Feuch an cuir thu a-steach an iuchair ceadachais agad air an duilleag seo gus an toradh seo a chuir an gnìomh.",
  Licence_Key:"Iuchair ceadachais",
  Enter_Licence_Key:"Cuir a-steach iuchair ceadachais",
  Purchased:"Ceannach",
  Enable_Feature:"Dèan comas air Feart",
  Cancel_Subscription:"Sguir dhen fho-sgrìobhadh",
  Subscription_Removed:"Fo-sgrìobhadh air a thoirt air falbh",
  Select_Active_Subscription:"Tagh fo-sgrìobhadh gnìomhach gus a atharrachadh.",
  Remove_Subscription_Word:"A bheil thu cinnteach gu bheil thu airson an fho-sgrìobhadh seo a chur dheth?",
  Delete_Company_Confirmation:"A bheil thu cinnteach gu bheil thu airson a' chompanaidh seo gu lèir a sguabadh às? RABHADH: Caillidh tu a h-uile fiosrachadh a tha air a stòradh!",
  Delete_Default_Company_Word:"Chan urrainn dhut an companaidh bunaiteach a sguabadh às. Am bu toil leat an aplacaid ath-shuidheachadh agus a thoirt air ais chun staid bunaiteach? RABHADH: Caillidh tu a h-uile fiosrachadh a tha air a stòradh!",
  Console_Warning_2:"Na làimhsich airgead sam bith a’ cleachdadh an aplacaid seo nach eil leatsa an-dràsta.",
  Terms_and_Conditions:"Cumhachan is Cùmhnantan",
  and_the:"agus an",
  for:"airson",
  Please_Read_Word:"Feuch an leugh thu agus aontaich ris an",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Cha deach cuid de ìrean tionndaidh airgead a lorg. Feuch an ceangail thu ris an eadar-lìn.",
  Free:"Saor",
  DB_Erase_Prompt_2:"Sgrios gu tur an stòr-dàta leasaiche gu lèir? RABHADH: Caillidh tu a h-uile fiosrachadh CEANNACH is SÒNRACHADH!",
  Successfully_Imported:"Air a thoirt a-steach gu soirbheachail",
  Select_Postage:"Tagh Postachd",
  No_Postage:"Gun stampaichean postachd",
  No_Paid_Postage_Word:"Cuir ris a' chiad stampa <a routerLink='/folder/Postage/New'>Postachd pàighte</a>.",
  Trial_Complete:'Triail air a chrìochnachadh',
  Please_Upgrade:'Feuch an ùraich thu seicichean gus leantainn air adhart leis a’ chlò-bhualadh.',
  Aa:"Aa",
  Color:"Dath",
  Font:"Cruth-clò",
  Guide:"Stiùiridh",
  Guides:"Stiùiridhean",
  Image:"Dealbh",
  Zoom:"Sùm",
  Logo:"Suaicheantas",
  Bank:"Banca",
  MICR:"MICR",
  Total:"Iomlan",
  Cancel:"Sguir dheth",
  Confirm:"Dearbhaich",
  Method:"Dòigh-obrach",
  Biometric_Security:"Tèarainteachd bith-mheatrach",
  Please_Login_To_Continue:"Feuch an log thu a-steach gus leantainn air adhart.",
  Complete:"Crìochnaich",
  Sign_Up:"Cuir d’ainm ris",
  Error:"Mearachd",
  Biometrics:"Bith-mheatrach",
  Percent:"sa cheud",
  Zero_Percent:"0%",
  Top_Margin:"Iomall àrd",
  Bottom_Margin:"Iomall ìosal",
  Left_Margin:"Margin clì",
  Right_Margin:"Iomall cheart",
  MICR_Margin:"Iomall MICR",
  Table_Margin:"Iomall Clàr",
  Address_Margin:"Seòladh Margin",
  Percentage_:"Ceudad",
  Vacation_Title:"Tiotal saor-làithean",
  Use_PIN:"Cleachd PIN",
  Loading__:"A' luchdachadh...",
  Design_Title:"Ainm dealbhaidh",
  Authorize:"Ùghdarrachadh",
  Key:"iuchair",
  Public_Key:"Iuchair Phoblach",
  Private_Key:"Iuchair phrìobhaideach",
  Authenticate:"Dearbhaich",
  Last_Payroll:"Pàighidh mu dheireadh",
  Last_Calculation:"An àireamhachadh mu dheireadh",
  Authorized:"Ùghdarraichte",
  Geo_Authorized:"Geo-Suidheachadh: Ùghdarraichte",
  Not_Authorized:"Gun Ùghdarrachadh",
  Authorization_Complete:"Ceadachadh air a chrìochnachadh",
  Geolocation_Authorization:"Ùghdarrachadh geo-shuidheachadh",
  Out_of_Bounds:"A-mach à Crìochan",
  Cant_Delete_Default_Design:"Chan urrainn dhuinn dealbhadh bunaiteach a sguabadh às.",
  Unauthorized_Out_of_Bounds:"Gun chead: A-mach à crìochan",
  Biometric_Authorization:"Ùghdarrachadh bith-mheatrach",
  Locating_Please_Wait:"A’ suidheachadh, feuch an fuirich thu...",
  Test_Biometrics:"Dèan deuchainn air Bio-metrics",
  Cheque_Margins:"Thoir sùil air Margins",
  Percentage_Full_Error:"Chan urrainnear an raon sa cheud a shuidheachadh os cionn 100% sa cheud.",
  No_Payroll_Text:"Cuir <a routerLink='/folder/Employee/New'>Neach-obrach</a> ris no <a routerLink='/folder/Payee/New'>Payee/New'>Payee</a> agus <a routerLink='/folder/Schedule/New'>Clàr</a>.",
  Design_Saved:"Dealbhadh air a shàbhaladh",
  Default_Design_Selected:"Dealbhadh bunaiteach air a thaghadh",
  Partial_Import:"Pàirt a-steach",
  Partial_Export:"Às-mhalairt pàirt",
  Entire_Import:"Import iomlan",
  Entire_Export:"Às-mhalairt iomlan",
  Existing_Password:"Feuch an cuir thu a-steach am facal-faire a th’ agad mu thràth:",
  Existing_PIN:"Cuir a-steach an còd PIN a th’ agad mu thràth:",
  Pin_Change_Header:"Dearbhadh PIN",
  Pin_Change_SubHeader:"Cuir a-steach an t-seann àireamh PIN agad gus an atharrachadh a dhearbhadh.",
  Pass_Change_Header:"Dearbhadh Facal-faire",
  Pass_Change_SubHeader:"Cuir a-steach an t-seann fhacal-faire agad gus an atharrachadh a dhearbhadh.",
  PIN_Enter_Message:"Cuir a-steach am PIN agad gus a dhearbhadh.",
  Password_Enter_Message:"Cuir a-steach am facal-faire agad gus a dhearbhadh.",
  Pin_Updated_Success:"Chaidh am PIN ùrachadh gu soirbheachail!",
  Pin_Updated_Fail:"Cha b' urrainn dhuinn am PIN ùrachadh.",
  Pass_Updated_Success:"Chaidh am facal-faire ùrachadh gu soirbheachail.",
  Pass_Updated_Fail:"Cha b' urrainn dhuinn am facal-faire ùrachadh.",
  Preview_Payment:"Pàigheadh ro-shealladh",
  Preview_Payroll:"Ro-shealladh pàighidh",
  No_Payments_Created:"Cha deach pàighidhean sam bith a lorg.",
  Payment_Preview:"Ro-shealladh pàighidh",
  Enable_Payee:"Dèan comas air Payee",
  Rendered:"Air a thoirt seachad",
  No_Email:"Gun phost-d",
  Setup_Cheques:"Sgrùdaidhean rèiteachaidh",
  name:"Ainm",
  address:"Seòladh",
  address_2:"Seòladh loidhne 2",
  city:"Cathair-bhaile",
  province:"Mòr-roinn",
  postal_code:"Còd-puist/ZIP",
  country:"Dùthaich",
  username:"Ainm-cleachdaidh",
  full_name:"Làn ainm",
  birthday:"Co-là-breith",
  email:"Post-d",
  phone:"Fòn",
  employees:"Luchd-obrach",
  addresses:"Seòlaidhean",
  payment_amount_limit:"Crìochan meud pàighidh",
  total_limit:"Crìochan iomlan",
  payees:"Pàighidh",
  description:"Tuairisgeul",
  address_line_one:"Seòladh Loidhne a h-Aon",
  address_line_two:"Seòladh Loidhne a Dhà",
  image:"Dealbh",
  account_holder:"Neach-cunntais",
  cheque_starting_id:"Thoir sùil air ID tòiseachaidh",
  transit_number:"Àireamh Gluasaid",
  institution_number:"Àireamh Institiud",
  designation_number:"Àireamh Ainmeachaidh",
  account_number:"Àireamh cunntais",
  currency:"Airgeadra",
  signature:"Ainm-sgrìobhte",
  payment_payroll_limit:"Crìochan pàighidh",
  total_limi:"Crìochan iomlan",
  date:"Ceann-latha",
  printed_memo:"Meòrachan Clò-bhuailte",
  banks:"Bancaichean",
  signature_image:"Ìomhaigh ainm-sgrìobhte",
  address_name:"Ainm Seòladh",
  notes:"Notaichean",
  design:"Dealbhadh",
  title:"Tiotal",
  frequency:"Tricead",
  fax:"facs",
  salaries:"Tuarastalan",
  salary_ids:"IDan tuarastail",
  start_time:"Ùine tòiseachaidh",
  end_time:"Ùine Crìochnachaidh",
  paid:"Phàigh",
  overtime_percentage:"Ìre sa cheud pàighte",
  overtime_amount:"Suim Stèidhichte pàighte",
  first_name:"A’ chiad ainm",
  last_name:"An t-ainm mu dheireadh",
  moderation:"Measarrachd",
  create:"Cruthaich",
  edit:"Deasaich",
  destroy:"Sgrios",
  day_start_time:"latha_tòiseachd_àm",
  day_end_time:"Latha_deireadh_àm",
  fullname:"Ainm",
  time:"Uair",
  auto_send:"Cuir gu fèin-ghluasadach",
  time_method:"Dòigh Uair",
  schedules:"Clàran",
  indefinite_payroll_enabled:"Dèan comas gun chrìoch",
  amount:"Suim",
  repeat:"Dèan a-rithist",
  always_enabled:"An-còmhnaidh comasach",
  start_date:"Ceann-latha tòiseachaidh",
  end_date:"Ceann-latha crìochnachaidh",
  Cheque_Total:"Thoir sùil air Iomlan",
  Total_Amount:"Suim iomlan:",
  Amounts:"Meudan:",
  Images:"Dealbhan",
  Files:"Faidhlichean",
  Previewing:"Ro-shealladh:",
  Insert:"cuir a-steach",
  Preview_Import:"Ro-shealladh Import",
  Entry:"Inntrigeadh",
  Entries:"Inntrigidhean",
  No_Entries:"Gun inntrigeadh",
  Import_Type:"Seòrsa Import",
  Select_Details:"Tagh Mion-fhiosrachadh",
  Select_Payee:"Tagh Payee",
  Enable_Holidays:"Dèan comas air saor-làithean",
  Disable_Holidays:"Cuir dheth saor-làithean",
  Wire_Transfer:"Gluasad uèir",
  New_Export:"Às-mhalairt Ùr",
  Export_Data:"Dàta às-mhalairt",
  Export_Data_Word:"Tagh an seòrsa faidhle airson às-mhalairt agus luchdachadh sìos.",
  Export_File:"Às-mhalairt File",
  Mode:"Modh",
  Times:"Amannan",
  Widgets:"Widgets",
  Slider:"Sleamhnag",
  Set_Details:"Suidhich mion-fhiosrachadh",
  Select_Type:"Tagh Seòrsa",
  Display_Slider:"Slider taisbeanaidh",
  Dashboard_Slider:"Sleamhnag deas-bhòrd",
  Dashboard_Mode:"Modh deas-bhòrd",
  Show_Intro:"Seall Intro",
  Show_Payrolls:"Seall Pàighidhean",
  Show_Holidays:"Taisbeanadh saor-làithean",
  Show_Invoices:"Seall fàirdealan",
  Show_Cheques:"Seall seicichean",
  Enabled_Widgets:"Widgets air an comasachadh",
  Disabled_Widgets:"Widgets ciorramach",
  Colors:"Dathan",
  Barcodes:"Barcodes",
  View_Timers:"Seall Timers",
  Gradients:"Caiseadan",
  No_Info:"Gun fhiosrachadh",
  Disable:"Cuir à comas",
  Show_Layer:"Seall sreathan",
  Hide_Layer:"Falaich sreathan",
  Text_Layer:"Sreathan teacsa",
  Secondly:"San dara h-àite",
  Minutely:"A mhionaid",
  nine_am:"9:00m",
  five_pm:"5:00f",
  Enable_Address:"Cuir an comas Seòladh",
  Invalid_File_Type:"Duilich, chaidh seòrsa faidhle mì-dhligheach a thaghadh. Seòrsa faidhle le taic:",
  Error_Updating_Entry:"Duilich, bha mearachd ann ag ùrachadh an inntrig seo.",
  Schedule_Timing_Alert:"Mearachd: Tha an ùine tòiseachaidh clàr air a shuidheachadh gu luach às deidh an ùine crìochnachaidh.",
  Select_Multiple_Payments_Prompt:"Cha deach pàighidhean a thaghadh. Tagh co-dhiù aon phàigheadh.",
  Select_Multiple_Cheques_Prompt:"Cha deach seicichean a thaghadh. Feuch an tagh thu co-dhiù aon seic.",
  Select_Multiple_Items_Prompt:"Chan eil stuthan air an taghadh. Feuch an tagh thu co-dhiù aon nì.",
  Paymemt_Multiple_Delete_Confirmation:"A bheil thu cinnteach gu bheil thu airson iomadh pàigheadh a sguabadh às?",
  Cheque_Multiple_Delete_Confirmation:"A bheil thu cinnteach gu bheil thu airson iomadh seic a sguabadh às?",
  Payee_Multiple_Delete_Confirmation:"A bheil thu cinnteach gu bheil thu airson grunn luchd-pàighidh a sguabadh às?",
  Employee_Multiple_Delete_Confirmation:"A bheil thu cinnteach gu bheil thu airson grunn luchd-obrach a sguabadh às?",
  MICR_Warning:"Nota: Is dòcha nach seall cuid de chlò-bhualadairean is innealan an cruth-clò MICR mar bu chòir.",
  Automatically_Send:"Cuir gu fèin-ghluasadach",
  Type:"Seòrsa",
  Payment_Type:"Seòrsa pàighidh",
  Auto_Send:"Cuir fèin-ghluasadach",
  Automatically_Process:"Pròiseas gu fèin-ghluasadach",
  Auto_Process:"Pròiseas fèin-ghluasadach",
  Image_Based:"Stèidhichte air ìomhaigh",
  Font_Based:"Stèidhichte air cruth-clò",
  Rerender:"Ath-thaisbeanadh",
  Rendering:"A' toirt seachad",
  Render:"Faidhlichean",
  Top:"Barr",
  Middle:"Meadhanach",
  Bottom:"Bun",
  Top_Left:"Clì gu h-àrd",
  Top_Center:"Ionad as àirde",
  Top_Right:"Deas gu h-àrd",
  Middle_Left:"Clì Meadhanach",
  Middle_Center:"Ionad meadhanach",
  Middle_Right:"Deas mheadhanach",
  Bottom_Left:"Fon taobh chlì",
  Bottom_Center:"Ionad gu h-ìosal",
  Bottom_Right:"Gu h-ìosal air an làimh dheis",
  Numbers:"Àireamhan",
  Verified:"Dearbhaichte",
  Paper_Size:"Meud Pàipear",
  New_Device:"Inneal Ùr",
  Add_Device:"Cuir inneal ris",
  Remove_Device:"Thoir air falbh inneal",
  Delete_Device:"Sguab às an inneal",
  Block_Device:"Inneal bacadh",
  Block:"Bloc",
  Unblock:"Neo-bhacadh",
  Table:"Clàr",
  Scan_Login_Code:"Sgan Còd Log a-steach",
  Login_Code:"Còd a-steach",
  Scan_Code:"Còd scan",
  Scan_QR_Code:"Sgan QR Code",
  Select_All:"Tagh Uile",
  Deselect_All:"Dì-thaghadh na h-uile",
  Increase:"Meudachadh",
  Decrease:"Lùghdachadh",
  Bold:"dàna",
  Text:"Teacs",
  Style:"Stoidhle",
  Italic:"eadailteach",
  QR_Code:"Còd QR",
  Barcode:"Barcode",
  Browse_Images:"Dèan brobhsadh air Dealbhan",
  Browse_Files:"Rannsaich Faidhlichean",
  Background_Image:"Ìomhaigh Cùl-fhiosrachaidh",
  Logo_Image:"Ìomhaigh Logo",
  Header_Image:"Dealbh Ceann-cinnidh",
  Bank_Image:"Ìomhaigh Banca",
  Cut_Lines:"Gearr loidhnichean",
  Background:"Cùl-fhiosrachadh",
  License:"Ceadachd",
  One_License:"1 Cead:",
  Licensed:"Le cead:",
  Not_Licensed:"Gun chead",
  Enter_Serial:"Cuir a-steach an t-sreath",
  Serial_Key:"Iuchair sreathach",
  Serial:"Sreathach",
  Product_Key:"Iuchair toraidh",
  Check_Product_Key:"Thoir sùil air iuchair toraidh",
  Add_Product_Key:"Cuir iuchair toraidh ris",
  Verifying_Purchase:"A’ dearbhadh ceannach...",
  Print_Envelope:"Cèis Clò-bhualaidh",
  Envelope:"Cèis",
  Thank_You:"Tapadh leat!",
  Scale:"Sgèile",
  Print_Scale:"Sgèile Clò-bhualaidh",
  Borders:"Crìochan",
  VOID:"FÀNAS",
  Void_Cheque:"Sgrùdadh falamh",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"PÀIGHEADH GU ORDUGH:",
  NO_DOLLARS:"GUN DOL ",
  ONE_DOLLAR:"AON DOLAR",
  DOLLARS:" DOLARAN",
  AND:" AGUS ",
  CENTS:" CENTS.",
  NO_:"CHAN EIL ",
  ONE_:"AON ",
  AND_NO_:"AGUS CHAN EIL ",
  _AND_ONE_:"AGUS ORAIN ",
  DOLLARS_AND_ONE_CENT:" AGUS AON CENT.",
  AND_NO_CENTS:" AGUS ZERO CENTS.",
  CHEQUE_PRINT_DATE:"Ceann-latha:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"BP",
  Initial_Tasks:"Tòisich gnìomhan",
  Inital_Setup:"Suidheachadh tùsail",
  Welcome_To:"Fàilte gu",
  Welcome:"Fàilte",
  Swipe:"Sguab",
  Intro_Setup:"Suidheachadh Intro",
  Introduction:"Ro-ràdh",
  CHEQUE_PRINT_CREDIT:"Le cumhachd seicichean",
  Title_Intro_Word:"Fàilte gu seicichean",
  Title_Intro:"Ro-ràdh seicichean",
  Title_Setup:"Suidhich seicichean",
  PayPal_Default_Email_Message:"Tha thu air gluasad PayPal ùr fhaighinn. [Le cumhachd seicichean]",
  Cheques_App_Export:"Air a chuir a-mach le seicichean",
  Post_Intro_Thanks:"Tapadh leat airson seicichean a thaghadh. Tha am pròiseas rèiteachaidh deiseil a-nis.",
  Post_Intro_Word:"Tòisich le bhith a’ clò-bhualadh a’ chiad seic agad, cuir pàigheadh ​​san àm ri teachd no cuir neach-obrach ris a’ chlàr-pàighidh.",
  Upgrade_Required:"Tha seicichean ag iarraidh gum bi sealbh agad air dreach nas fheàrr den bhathar-bog gus an teachdaireachd seo fhalach agus gus feartan a bharrachd fhuasgladh.",
  Upgrade_Title:"Seicean",
  Mailout_Prompt:"Faodaidh tu cuideachd roghnachadh seicichean a chur a-mach air post-dealain dhut.",
  Mailed_Cheque:"Seic air a phostadh:",
  Mailed_Cheque_Color:"Seic air a phostadh (dath): ",
  Terms_Purchase:"Tha gach ceannach eileagtronaigeach le seic ri fhaighinn air ais gu h-iomlan airson suas ri 30 latha bhon cheann-latha ceannach. Feuch an leugh thu agus aontaich ris na <a href='#'>Teirmichean is Cùmhnantan</a> mus lean thu air adhart.",
  Dashboard_Setup:"Suidhich am prìomh chlò-bhualadair",
  Dashboard_Add:"Cuir a 'phrìomh chunntas banca ris",
  Dashboard_Customize:"Tagh teamplaid sgrùdaidh",
  Dashboard_Job_Salary:"Cruthaich d' obair agus cuir do thuarastal",
  Dashboard_Employees:"Trac Luchd-obrach & Luchd-pàighidh",
  Dashboard_Print:"Clò-bhuail is post-d na seicichean pàighidh agad",
  Dashboard_Payroll:"Fèin-ghluasadach do chlò-bhualadh pàighidh",
  Dashboard_PayPal:"Suidhich PayPal Payroll / Payouts",
  Begin_Setup:"Tòisich air an stàladh",
  Get_Started:"Thòisich",
  Purchase:"Ceannach",
  Lockdown:"Glasadh-sluaigh",
  Unlock:"Fuasgail",
  Detailed:"Mionaideach",
  Log_In:"Log a-steach",
  Login:"Log a-steach",
  Launch:"Cuir air bhog",
  Register:"Clàr",
  Finish:"Crìochnaich",
  List:"Liosta",
  Weekends:"Deireadh-sheachdainean",
  Weekly:"A h-uile seachdain",
  PayPal_Default_Subject:"Pàigheadh ​​Ùr",
  Payment_Message:"Teachdaireachd Pàighidh",
  PayPal_Default_Payment_Note:"Tapadh leat",
  Setup_Your_Cheqing_Account:"Cunntas a' sireadh",
  Add_Your_Primary_Cheqing_Account:"Cuir do bhun-cheqing cunntas.",
  Welcome_Word:"Sìmplich agus bog roile-pàighidh agus rianachd goireasan daonna.",
  Get_Started_Quickly:"Dìreach freagair beagan cheistean sìmplidh a chuidicheas sinn gus tòiseachadh...",
  Done_Intro_Word:"Suidhich coileanta",
  PIN_Protected:"Facal-faire & Dìon PIN",
  Enter_New_PIN:"Cuir a-steach ùr PIN Code:",
  Enter_PIN:"Cuir a-steach an còd PIN:",
  Invalid_PIN:"Chaidh PIN mì-dhligheach a-steach.",
  Account_Identifier:"Aithneachadh cunntais",
  New_Account_Identifier:"Aithneadair Cunntas Ùr",
  attempt:"oidhirp",
  attempts:"oidhirpean",
  remaining:"air fhàgail",
  Language:"Cànan",
  languages:"Cànanan",
  select_languages:"Tagh Language",
  Deposit:"Tasgadh",
  Hire_One_Now:"Falaich fear a-nis",
  App_Locked:"Tha an aplacaid glaiste.",
  Skip_:"Sgip",
  Skip_to_Dashboard:"Leum gu Dashboard",
  Dashboard:"Deas-bhòrd",
  Import:"Thoir a-steach",
  Admin:"Riadairean",
  New_Admin:"Rianachd ùr",
  Settings:"Roghainnean",
  Color_Picker:"Roghadair dath",
  Font_Picker:"Font Picker",
  Logout:"Log a-mach",
  Close:"Dùin",
  Close_menu:"Dùin",
  Excel:"Faidhle Excel",
  Csv:"Faidhle CSV",
  Sql:"Faidhle SQL",
  Json:"Faidhle JSON",
  Url:"Iom-phortaich le URL",
  Back:"Air ais",
  Timers:"Luchd-ama",
  Cheque:"Thoir sùil",
  Print:"Priont",
  Designs:"Dealbhan",
  Pause_Printing:"Na stad an clò-bhualadh",
  Resume_Printing:"Air ais Clò-bhualadh",
  Printing_Paused:"Clò-bhualadh air stad",
  PrintingUnavailable:"Duilich! Chan eil clò-bhualadh ri fhaighinn air an t-siostam seo.",
  Prints_Paused:"Clò-bhualaidhean air an stad",
  Administration:"Rianachd",
  Loading:"A' luchdachadh",
  Unnamed:"Gun ainm",
  error:"Duilich, cha b’ urrainn dhuinn an t-seic seo fhosgladh airson a choimhead.",
  No_Cheques_To_Print:"Chan eil seicichean ri chlò-bhualadh",
  No_Cheques_To_Print_Word:"Cruthaich <a routerLink='/folder/Cheque/New'>Seic Ùr</a>.",
  New_Cheque:"Seic ùr",
  Start_One_Now:"Tòisich fear a-nis",
  Edit_Cheque:"Deasaich seic",
  Select_Cheques:"Tagh seicichean",
  Select_Employee:"Tagh Neach-obrach",
  Default_Printer:"Clò-bhualadair bunaiteach",
  Reassign:"Ath-shònrachadh",
  Configure:"Rèitich",
  Template:"Teamplaid",
  Designer:"Dealbhaiche",
  Edit_Designs:"Deasaich dealbhaidhean",
  New_Design:"Dealbhadh Ùr",
  Next:"Air adhart",
  Save:"Sàbhail",
  Name:"Ainm",
  Mail:"Post",
  Amount:"Suim",
  Date:"Ceann-latha",
  PayPal:"PayPal",
  Payouts:"Pàighidhean",
  PayPal_Label:"PayPal Label",
  Email_Username:"Cuir post-dealain / Ainm-cleachdaidh",
  Client_ID:"ID neach-dèiligidh",
  Sandbox_Email:"Sandbox Cuir post-dealain",
  PayPal_Email:"Post-d PayPal",
  PayPal_Username:"Ainm-cleachdaidh API",
  PayPal_Payouts:"Pàighidhean PayPal",
  Select_PayPal_Key:"Tagh PayPal Key",
  Secret:"Dìomhair",
  API_Secret:"API Secret",
  PayPal_API_Keys:"PayPal Keys",
  New_PayPal_Key:"Iuchrach PayPal ùr",
  Email_Subject:"Cuir post-dealain Subject",
  Email_Message:"Teachdaireachd post-d",
  Payout_Options:"Roghainnean pàighidh",
  Payment_Note:"Nota pàighidh",
  Enable_Employee:"Cuir an comas neach-obrach",
  Enable_Production_Mode:"Cuir an comas modh riochdachaidh",
  Sandbox_Username:"Ainm-cleachdaidh bogsa gainmhich",
  Sandbox_Signature:"Soidhne bogsa gainmhich",
  Sandbox_Password:"Facal-faire bogsa-gainmhich",
  Production_Username:"Ainm-cleachdaidh toraidh",
  Production_Signature:"Soidhne Riochdachaidh",
  Production_Password:"Facal-faire toraidh",
  Production_Email:"Post-d riochdachaidh",
  API_Client_ID:"ID Client API",
  API_Signature:"Api Signature",
  API_Password:"Facal-faire API",
  API_Username:"Ainm-cleachdaidh API",
  Secret_Key:"Iuchair Dhìomhair",
  Sandbox:"Bogsa-gainmhich",
  Production:"Riochdachadh",
  Sandbox_Keys:"Iuchraichean bogsa-gainmhich",
  Production_Keys:"Iuchraichean Riochdachaidh",
  API_Title:"Tiotal API",
  Production_Mode:"Modh toraidh",
  Account_Label:"Leubail cunntais",
  No_PayPal_Setup:"Gun iuchair PayPal",
  Enable_PayPal_Account:"Cuir an comas cunntas PayPal",
  No_PayPal_Word:"Cuir do <a routerLink='/folder/Invoice/New'>Iuchair API PayPal</a> ris.",
  Printed_Memo:"Meòrachan Clò-bhuailte",
  Employee:"Neach-obrach",
  View_Employee:"Seall Neach-obrach",
  Mailing_Address:"Seòladh puist",
  Company_Address:"Seòladh na Companaidh",
  Select_Company_Address:"Tagh seòladh companaidh",
  Address:"Seòladh",
  Any_Day:"Latha sam bith",
  Address_Name:"Ainm an t-seòlaidh",
  Unit:"Aonad",
  Account:"Cunntas",
  Bank_Account:"Cunntas banca",
  Account_Limits:"Cuir an comas crìochan cunntais",
  Payroll:"Pàigheadh",
  New_Payroll:"Pàigheadh ​​Ùr",
  No_Payroll:"Chan eil tuarastal ri thighinn",
  Upcoming_Holiday:"Saor-làithean ri thighinn:",
  Invoice_Due:"Cunntas ri phàigheadh:",
  New_Invoice:"Cunntas Ùr",
  No_Invoices:"Gun fàirdealan",
  No_Invoices_Word:"Cruthaich a' chiad <a routerLink='/folder/Invoice/New'> Fàirdeal</a>.",
  Cheque_Due:"Seic ri phàigheadh:",
  Payrolls:"Pàighidhean",
  Sandbox_Mode:"Modh bogsa gainmhich",
  Hire:"Fastadh",
  Pay:"Pàigh",
  New:"Ùr",
  Add:"Cuir ris",
  Make:"Dèan",
  Time:"Am",
  Write:"Sgrìobh",
  Holiday:"saor-làithean",
  Holidays:"saor-làithean",
  Next_Holiday:"An ath shaor-làithean:",
  All_Done:"Rinn thu uile!",
  Employees:"Luchd-obrach",
  Payees:"Pàighidh",
  Job:"Obair",
  Jobs:"Obraichean",
  Invoice:"cunntas",
  Invoices:"Cunntas",
  Vacations:"saor-làithean",
  Cheques:"Seicean",
  Brand_Cheques:"Brand",
  Payment:"Pàigheadh",
  Enable_Payment:"Cuir an comas pàigheadh",
  Payments:"Pàighidhean",
  Schedule:"Clàr",
  Schedules:"Clàran",
  Timesheet:"Clàr-ama",
  Timesheets:"Clàr-ama",
  Salary:"Tuarastal",
  New_Address:"Seòladh Ùr",
  Address_2:"Seòladh (Loidhne 2)",
  _City:"Cathair",
  _State:"Stàit/Prov",
  City:"Cathair/Bhaile",
  State:"Stàite / Roinn",
  Postal:"Còd puist / ZIP",
  ZIP:"Post / ZIP",
  Country:"Dùthaich",
  Addresses:"Seòlaidhean",
  Required_Options:"Roghainnean riatanach",
  Optional_Options:"Roghainnean roghainneil",
  Additional_Options:"Roghainnean a bharrachd",
  Required:"Riatanach",
  Optional:"Roghainneil",
  Additional:"A bharrachd",
  No_Addresses:"Gun seòlaidhean",
  New_Address_Word:"Cuir a' chiad <a routerLink='/folder/Address/New'>Seòladh</a> ris.",
  Select_Address:"Tagh Seòladh",
  No_Address:"Gun seòlaidhean",
  Print_Cheque:"Clò-bhuail seic",
  Print_Cheque_Now:"Print Check Now",
  Description:"Tuairisgeul",
  Pay_To_The_Order_Of:"Pàigh gu òrdugh:",
  Select_Date_Range:"Tagh raon ceann-latha",
  Select_Starting_Date:"Tagh ceann-latha tòiseachaidh",
  Select_Ending_Date:"Tagh ceann-latha crìochnachaidh",
  Select_Date:"Tagh ceann-latha",
  Cheque_Date:"Dearbh-latha",
  Cheque_Memo:"Thoir sùil air Memo",
  Blank_Cheque:"Seic Bhàn",
  Blank:"Bal",
  No_Cheques:"Gun seicichean",
  No_Cheques_Word:"Clò-bhuail a' chiad <a routerLink='/folder/Cheque/New'>Seic</a> agad.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"Seall an dealbh",
  View:"Seall",
  Modify:"Atharraich",
  Delete:"Sguab às",
  Cheque_Paid:"Pàighte",
  New_Deduction:"Toradh Ùr",
  Title:"tiotal",
  Frequency:"Tricead",
  Hourly:"Anns an uair",
  Daily:"Latha",
  Weekdays:"Làithean na seachdain",
  BiWeekly:"2 sheachdain",
  TriWeekly:"3 seachdainean",
  Monthly:"mìosail",
  MiMonthly:"2 mhìos",
  Quarterly:"Ceathramh",
  Yearly:"Bliadhnail",
  Every_Week:"A h-uile seachdain",
  Every_Payroll:"Gach tuarastal",
  Every_Month:"Gach mìos",
  Annually:"A h-uile bliadhna",
  Always_Scheduled:"An-còmhnaidh clàraichte",
  Start_Date:"Ceann-latha tòiseachaidh",
  End_Date:"Ceann-latha crìochnachaidh",
  Start_Time:"Àm tòiseachaidh",
  End_Time:"Am crìoch",
  Deduction_Label:"Deduction Label",
  Notes:"Notaichean",
  Options:"Roghainnean",
  Enable:"Cuir an comas",
  Select_Deductions:"Tagh lùghdachaidhean",
  Deductions:"Deductions",
  No_Deductions:"Gun lùghdachaidhean",
  No_Deductions_Word:"Cruthaich a' chiad <a routerLink='/folder/Deduction/New'>Deduction</a> agad.",
  New_Employee:"Neach-obrach Ùr",
  No_Title:"Gun tiotal",
  _Name:"Ainm",
  About_Yourself:"Mu dheidhinn fhèin",
  Full_Name:"Làn ainm",
  Birthday:"Co-là-breith",
  Email:"Post-d",
  SMS:"SMS",
  Phone:"Fòn",
  Test:"Deuchainn",
  Call:"Call",
  Fax:"Facs",
  Printed_Note:"Nota Clò-bhuailte",
  Position:"Suidheachadh",
  Job_Position:"Suidheachadh Obrach",
  Select_a_Job:"Tagh obair",
  Select_a_Salary:"Tagh tuarastal",
  Add_a_Deduction:"Cuir Deduction ris",
  Taxes:"Cìsean",
  Add_Taxes:"Cuir cìsean ris",
  Date_of_Birth:"Latha breith",
  Email_Address:"Seòladh puist-d",
  Phone_Number:"Àireamh fòn",
  Phone_Call:"Call fòn",
  Enable_on_Payroll:"Cuir an comas air tuarastal",
  Select_Employees:"Tagh Luchd-obrach",
  No_Employees:"Gun luchd-obrach",
  No_Employees_Word:"Cuir a' chiad <a routerLink='/folder/Employee/New'>Neach-obrach</a> agad ris.",
  No_Name:"Gun ainm",
  Unemployeed:"Gun obair",
  Employeed:"Fastaichte",
  Paid:"Pàighte",
  Enabled:"Cuir an comas",
  Disabled:"Ciorramach",
  Fire:"Teine",
  Not_Available:"Chan eil ri fhaighinn",
  Not_Available_Word:"Clò-bhuail a' chiad <a routerLink='/folder/Invoice/New'> Fàirdeal</a> agad & faigh pàigheadh.",
  Select_Invoices:"Tagh_Cunntas",
  Print_Invoice_Word:"Clò-bhuail a' chiad <a routerLink='/folder/Invoice/New'> Fàirdeal</a> agad & faigh pàigheadh.",
  Invoice_Title:"Tiotal an fhàirdeal",
  Invoice_Date:"Ceann-latha an fhàirdeal",
  Due_Date:"An latha ri thighinn",
  New_Job:"Obair Ùr",
  Details:"Mion-fhiosrachadh",
  Customize:"Gnàthaich",
  Customize_Dashboard:"Gnàthaich deas-bhòrd",
  Components:"Co-phàirtean",
  No_Components:"Gun cho-phàirtean",
  Main_Components:"Prìomh cho-phàirtean",
  Smaller_Components:"Co-phàirtean nas lugha",
  Error_Loading_Page:"Thachair mearachd le luchdadh na duilleige seo.",
  Bank_Details:"Fiosrachadh Banca",
  About_Your_Job:"Mu d' Obair",
  Your_Schedule:"An clàr-ama agad",
  Job_Title:"Tiotal dreuchd",
  Job_Description:"Tuairisgeul Obrach",
  Job_Details:"Fiosrachadh Obrach",
  Enable_Job:"Cuir an comas Iob",
  Pay_Period:"Ùine pàighidh",
  Perpetually_Schedule:"Clàr gu bràth",
  Select_Jobs:"Tagh obraichean",
  No_Jobs:"Gun obraichean",
  Add_Jobs:"Cuir obraichean ris",
  No_Jobs_Word:"Cuir a' chiad <a routerLink='/folder/Job/New'>Iob</a> ris an liosta.",
  Count_Employees:"job.employee_count + 'Luchd-obrach'",
  Zero_Employees:"0 neach-obrach",
  New_Leave:"Fòrladh Ùr",
  Leave_Name:"Fàg ainm",
  Paid_Leave:"Fàgan pàighte",
  Leave_Pay:"Fàg pàigheadh",
  Indefinite_Leave:"Fàg gun chrìoch",
  Indefinite_Payroll:"Pàigheadh ​​​​neo-chinnteach",
  Leave:"Fàg",
  Add_Schedules:"Cuir clàran-amais ris",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Cuir an comas neo-làthaireachd",
  Select_Leaves:"Tagh duilleagan",
  No_Leaves:"Gun duilleagan neo-làthaireachd",
  Leave_Of_Absence:"Fàg neo-làthaireachd",
  Leaves_Of_Absence:"Duilleagan neo-làthaireachd",
  New_Leave_of_Absense:"Fàg ùr neo-làthaireachd",
  No_Leaves_Word:"Cuir a' chiad <a routerLink='/folder/Leave/New'>Fàg neo-làthaireachd</a> ris.",
  Not_Enabled:"Gun chomas",
  Absences:"Neo-làthaireachd",
  Payee:"Pàighidh",
  New_Payee:"Pàighidh Ùr",
  Payee_Identifier:"Aithneaiche Pàigheadh",
  Payee_Name:"Ainm neach-pàighidh",
  Payee_Title:"Tiotal neach-pàighidh",
  Payment_Memo:"Meòrachan pàighidh",
  Select_Payees:"Tagh Luchd-pàighidh",
  No_Payees:"Gun luchd-pàighidh",
  Add_Payee_Note:"Cuir a' chiad <a routerLink='/folder/Payee/New'>Pàigheadh</a> ris.",
  New_Payees:"Pàighidhean ùra",
  About_The_Payment_Schedule:"Clàr Pàighidh",
  Your_Payroll_Schedule:"Pàigheadh ​​fèin-ghluasadach",
  New_Payment:"Pàigheadh ​​Ùr",
  Identifier:"Aithneadair",
  Select:"Tagh",
  Memo:"Meòrachan",
  Payment_Date:"Ceann-latha pàighidh",
  Mark_as_Paid:"Mark mar Pàighte",
  Select_Payments:"Tagh Pàighidhean",
  No_Payments:"Gun phàigheadh",
  No_Payments_Word:"Cruthaich a' chiad <a routerLink='/folder/Payment/New'>Pàigheadh</a>.",
  Create_Payroll:"Cruthaich tuarastal",
  Properties:"Properties",
  Payroll_Title:"Tiotal pàighidh",
  Payroll_Notes:"Notaichean pàighidh",
  Payroll_Setup:"Suidheachadh pàighidh",
  Tabulate_Payments:"Pàighidhean Clàr",
  Tabulate:"Clàr",
  By:"Le:",
  Payments_By:"Pàighidhean le",
  Timesheets_And_Schedules:"Clàran-ama & clàran-ama",
  Both:"An dà chuid",
  Items:"Nithean",
  Add_Payees:"Cuir Pàighidhean ris",
  Add_Account:"Cuir cunntas ris",
  Enable_Account:"Cuir an comas cunntas",
  Enable_Payroll:"Cuir an comas pàighidh",
  Print_Payroll:"Clò-bhuail pàighidh",
  No_Payrolls:"Gun tuarastal",
  No_Payroll_Word:"Cruthaich a' chiad <a routerLink='/folder/Payroll/New'>Pàighidh</a> agad.",
  View_more:"VIEW_MORE",
  Less:"LEAS",
  Add_Payroll:"Cuir Pàighidh ris",
  Select_Payroll:"Tagh tuarastal",
  About_Your_Salary:"Mu dheidhinn do thuarastal",
  Add_Salaries:"Cuir tuarastal ris",
  Add_Salary:"Cuir tuarastal ris",
  Salaries:"Tuarastal",
  No_Salaries:"Gun tuarastal",
  No_Salaries_Word:"Cuir a' chiad <a routerLink='/folder/Salary/New'>Tuarastal</a> ris.",
  Select_Salaries:"Tagh tuarastal",
  New_Salary:"Tuarastal Ùr",
  Salary_Name:"Aithriche tuarastail",
  Enable_Salary:"Cuir an comas tuarastal",
  Salary_Amount:"Suim tuarastail",
  New_Schedule:"Clàr Ùr",
  Schedule_Title:"Tiotal Clàr",
  Add_Address:"Cuir Seòladh ris",
  Repeat:"Dèan ath-aithris",
  Design:"Dealbhadh",
  Edit_Design:"Deasaich dealbhadh",
  Edit_this_Design:"Deasaich an dealbhadh seo",
  Repeat_Payment:"Pàigheadh ​​a-rithist",
  Enable_Schedule:"Cuir an comas clàr",
  Never:"Cha bhi",
  Select_Schedule:"Tagh clàran",
  No_Schedules:"Gun chlàran",
  No_Schedules_Word:"Cruthaich a' chiad <a routerLink='/folder/Schedule/New'>Clàr</a>.",
  New_Administrator:"Riaghladair ùr",
  Username:"Ainm-cleachdaidh",
  First_Name:"A 'chiad ainm",
  Last_Name:"Ainm deireannach",
  Add_an_Address:"Cuir Seòladh ris",
  Payment_Limit:"Crìochan gach pàighidh",
  Total_Limit:"Crìochan iomlan",
  Select_Accounts:"Tagh cunntasan",
  No_Administrators:"Gun rianadairean",
  No_Administrators_Word:"Cruthaich a' chiad <a routerLink='/folder/Administrator/New'>Cunntas Rianachd</a>.",
  New_Administrators_Word:"Cuir a' chiad <a routerLink='/folder/Administrator/New'>Rianaire</a> ris",
  Cloud:"Cloud",
  Backup:"Cùl-taice",
  Enable_iCloud:"Cuir an comas iCloud",
  Enable_Google_Drive:"Cuir an comas Google Drive",
  Enable_Microsoft_OneDrive:"Cuir an comas Microsoft OneDrive",
  Automatically_Backup:"Cùl-taic gu fèin-obrachail",
  FTP_Settings:"Roghainnean FTP",
  URL_File_Prompt:"Sònraich an t-àite airson faidhle .xls / .xlsx / .json a thoirt a-steach:",
  URL_SQL_Prompt:"Sònraich far a bheil am faidhle SQLite airson ion-phortadh:",
  FTP_Backup:"lethbhreac glèidhidh FTP",
  FTP_Import:"In-mhalairt FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Aoigh",
  Port:"Port",
  Path:"Slighe",
  Data_Path:"Slighe dàta",
  Enable_FTP_Account:"Cuir an comas cunntas FTP",
  HostnameIP:"Ainm an òstair",
  Password:"Facal-faire",
  Connection_Port:"Port ceangail",
  Enable_MySQL_Database:"Cuir an comas stòr-dàta MySQL",
  Log:"Log",
  Reset:"Ath-shuidhich",
  Erase:"Sguab às",
  Export:"Export",
  Database:"Stòr-dàta",
  Upload_CSV:"Luchdaich suas CSV",
  Download_CSV:"Luchdaich sìos CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Cùl-taic MySQL",
  Internal_Notes:"Notaichean a-staigh",
  Root_Path:"Root Path",
  Select_Backup:"Tagh lethbhreac-glèidhidh",
  Add_New_Backup:"Cuir lethbhreac-glèidhidh ùr ris",
  First_Backup:"Suidhich a' chiad lethbhreac-glèidhidh...",
  Backups:"Cùl-taice",
  Add_Backup:"Cuir lethbhreac-glèidhidh ris",
  No_Backups:"Chan eil lethbhreacan-glèidhidh ri lorg.",
  Select_Backup_Type:"Tagh an seòrsa lethbhreac-glèidhidh a bu toigh leat a rèiteachadh...",
  Backup_Type:"Seòrsa cùl-taice",
  FTP_Drive:"Drive FTP",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Drive",
  Microsoft_OneDrive:"Drive",
  Import_Fields:"Cuir a-steach raointean",
  Import_Fields_Word:"Cleachd an roinn seo gus <a routerLink='/folder/Holiday/New'>Import</a> dàta a thaghadh.",
  Upload:"Luchdaich suas",
  Download:"Luchdaich sìos",
  Download_JSON:"Luchdaich sìos mar JSON Data",
  Download_SQL:"Luchdaich sìos mar fhaidhle SQL",
  New_Bank:"Banca Ùr",
  New_Account:"Cunntas Ùr",
  New_Bank_Account:"Cunntas Banca Ùr",
  Upload_Image:"Luchdaich suas dealbh",
  Bank_Name:"Ainm Banca",
  Bank_Address:"Seòladh Banca",
  Branch_Address:"Seòladh Meur",
  Address_on_Cheque:"Seòladh",
  Cheque_Numbers:"Thoir sùil air àireamhan",
  Cheque_Details:"Thoir sùil air mion-fhiosrachadh",
  Bank_Logo:"Suaicheantas Banca",
  Cheque_ID:"Thoir sùil air ID",
  Starting_Cheque_ID:"A 'tòiseachadh air an ID",
  Transit_Number:"Àireamh siubhail",
  Institution_Number:"Àireamh Institiud",
  Designation_Number:"Àireamh Sònrachaidh",
  Account_Number:"Àireamh cunntais",
  Limits:"Crìochan",
  Default_Limits:"Crìochan bunaiteach",
  Over_Limit:"Thar chrìoch",
  Under_Limit:"Fo chrìoch",
  Payroll_Limit:"Crìochan pàighidh",
  Enable_Bank_Account:"Cuir an comas cunntas banca",
  Select_Account:"Tagh cunntas",
  No_Bank_Account:"Gun chunntas banca",
  No_Bank_Account_Word:"Cuir a' chiad <a routerLink='/folder/Accounts/New'>Cunntas Banca</a> ris.",
  Bank_Accounts:"Cunntasan Banca",
  No_Accounts:"Gun chunntasan",
  No_Accounts_Note:"Cuir a' chiad <a routerLink='/folder/Accounts/New'>Cunntas Banca</a> ris.",
  Cheque_Designer:"Thoir sùil air dealbhaiche",
  Cheque_Design:"Thoir sùil air dealbhadh",
  Select_Design:"Tagh dealbhadh",
  Cheque_Designs:"Thoir sùil air dealbhadh",
  No_Cheque_Designs:"Gun dealbhadh seic",
  No_Cheque_Designs_Word:"Cruthaich an <a routerLink='/folder/Settings/Cheque/Design/New'>Thoir sùil air an dealbhadh</a> agad fhèin.",
  Set_Default:"Suidhich mar an àbhaist",
  Default:"Default",
  Remove:"Thoir air falbh",
  Folder:"Folder",
  Edit:"Deasaich",
  LoadingDots:"A' luchdachadh...",
  Add_a_New_File:"Cuir <a href='#' (cliog) = 'cuir()'> faidhle ùr</a> ri",
  this_folder:"am pasgan seo",
  FTP_Backup_Settings:"Roghainnean cùl-taic FTP",
  Secure_File_Transfer:"Cuir thairis faidhle tèarainte",
  New_Holiday:"Saor-làithean Ùr",
  Add_Holiday:"Cuir saor-làithean ris",
  Holiday_Name:"Ainm saor-làithean",
  Additional_Pay:"Pàigheadh ​​​​a bharrachd",
  Enable_Holiday:"Cuir an comas saor-làithean",
  Select_Holidays:"Tagh saor-làithean",
  No_Holidays:"Gun saor-làithean",
  No_Holidays_Word:"Cuir a' chiad <a routerLink='/folder/Holiday/New'>Saor-làithean Poblach</a> ris.",
  New_Import:"Iompaireachd Ùr",
  Import_Data:"In-mhalairt dàta",
  Import_Data_Word:"Tagh an seòrsa faidhle no modh luchdaidh suas a thagh thu.<br /> Faodaidh tu taghadh ge bith dè na raointean a chaidh a thoirt a-steach ann am faidhle a fhreagras ri paramadair sam bith san aplacaid às deidh dhut faidhle le taic a luchdachadh suas.", 
  Import_File:"Iom-phortaich faidhle",
  Link_To_File:"Ceangail gu File",
  Select_File:"Tagh File",
  New_Moderator:"Moderatair Ùr",
  Allow_Moderation:"Ceadaich le tomhas",
  Create_Paycheques:"Cruthaich seicichean pàighidh",
  Edit_Paycheques_and_Data:"Deasaich seicichean pàighidh is dàta",
  Destroy_Data_and_Paycheques:"Sguab às dàta agus seicichean pàighidh",
  Bonus_Percentage:"Ceudad seic pàighidh",
  Fixed_Amount:"Suim stèidhichte",
  Select_Moderator:"Tagh Modaireatair",
  No_Moderators:"Gun modaireatairean",
  Moderators:"Moderators",
  Moderator_Account:"Cruthaich a' chiad <a routerLink='/folder/Administrator/New'>Cunntas Modaireatair</a>.",
  No_Moderators_Word:"Cuir a' chiad <a routerLink='/folder/Administrator/New'>Moderator</a> ris.",
  Defaults:"Defaultan",
  Provide_Us_Info:"Thoir dhuinn fiosrachadh",
  Setup_Your_Printer:"Suidhich do chlò-bhualadair",
  Your_Company:"Mu do chompanaidh",
  Company_Name:"Ainm a 'Chompanaidh",
  Currency:"Airgeadais",
  Default_Currency:"Airgead bunaiteach",
  Base_Monthly_Income:"Teachd a-steach mìosail",
  Protection:"Dìon",
  App_Protection:"Dìon App",
  PIN_Code_Protection:"Dìon còd PIN",
  App_Protection_Word:"Cuir an comas dòighean tèarainteachd a chuidicheas le bhith dìon do chunntas.",
  PIN_Code:"Còd PIN",
  Change_PIN:"Atharraich PIN",
  New_Password:"Facal-faire ùr",
  Geofence_Protection:"Dìon Geo-Feansa",
  Geofence_Area:"Suidhich raon",
  Distance:"Astar",
  Setup_Now:"Suidhich a-nis",
  Mile:"Mile",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Aithneachadh Aghaidh",
  Face:"Aghaidh",
  Setup:"Suidhich",
  Label:"Label",
  Password_Protection:"Dìon Facal-faire",
  Modify_Password:"Atharraich am facal-faire",
  New_Tax_Entry:"Inntrigeadh Cìse Ùr",
  New_Tax:"Cìs Ùr",
  Tax_Label:"Label Cìse",
  Perpetually_Enabled:"Air a chomasachadh gu sìorraidh",
  Select_Taxes:"Tagh cìsean",
  Tax_Deductions:"Toraidhean cìse",
  No_Tax_Deductions:"Gun lùghdachaidhean cìse",
  No_Tax_Deductions_Word:"Cuir ris a' chiad <a routerLink='/folder/Tax/New'>Cìs</a> deduction.",
  New_Timer:"Ùineadair Ùr",
  Start:"Tòisich",
  Stop:"Stad",
  Start_Timer:"Tòisich an timer",
  Stop_Timer:"Stop timer",
  Timer_Active:"Timer Gnìomhach",
  Timer:"Timer:",
  Timer_Running:"Timer: (A 'ruith)",
  Save_Timer:"Sàbhail an timer",
  New_Timesheet:"Clàr-ama Ùr",
  Select_Timesheets:"Tagh clàran-ama",
  Work_Notes:"Notaichean obrach",
  Entry_Title:"Tiotal inntrigidh",
  No_Timesheets:"Gun clàran-ama",
  No_Timesheets_Word:"Cuir a' chiad <a routerLink='/folder/Timesheet/New'>Duilleag-ama</a> ris.",
  Timesheet_Submitted:"Clàr-ama air a chuir a-steach",
  Timesheet_Congrats:"Mealaibh ur naidheachd! Chaidh an duilleag-ama agad a chur a-steach gu soirbheachail. Tapadh leibh!",
  Timesheet_Copy:"Gus lethbhreac fhaighinn de na sgrìobhainnean agad thoir dhuinn do sheòladh puist-d agus/no àireamh fòn-làimhe.",
  Submit:"Cuir a-steach",
  Allow_Notifications:"Ceadaich fiosan",
  Untitled_Entry:"Inntrigeadh Ùr",
  Untitled_Bank:"Banca gun tiotal",
  Timesheet_Entry:"Cuir a-steach Clàr-ùine",
  Work_Description:"Tuairisgeul obrach",
  Enable_Timesheet:"Cuir an comas clàr-ama",
  Submit_Timesheet:"Cuir a-steach Clàr-ama",
  Vacation:"saor-làithean",
  New_Vacation:"Saor-làithean Ùr",
  Vacation_Name:"Ainm saor-làithean",
  Paid_Vacation:"Saor-làithean pàighte",
  Vacation_Pay:"Pàigheadh ​​saor-làithean",
  Enable_Vacation:"Cuir an comas saor-làithean",
  Select_Vacations:"Tagh saor-làithean",
  No_Vacations:"Gun saor-làithean",
  No_Vacations_Word:"Cruthaich a' chiad inntrigeadh <a routerLink='/folder/Vacation/New'>saor-làithean</a>.",
  Payroll_Schedule:"Clàr-pàighidh",
  Next_Payroll:"An ath phàigheadh:",
  Upcoming_Payroll:"Pàigheadh ​​​​Ri thighinn",
  No_Upcoming_Payroll:"Chan eil tuarastal ri thighinn",
  Address_Book:"Leabhar Seòlaidhean",
  Archived_Documents:"Sgrìobhainnean tasglann",
  Dev_Mode:"Iarrtas ann am modh leasachaidh",
  Administrators:"Riadairean",
  Privacy:"Dìomhaireachd",
  None:"Chan eil",
  Select_Signature:"Tagh ainm-sgrìobhte",
  No_Signatures:"Gun ainmean-sgrìobhte",
  No_Signatures_Word:"Cuir a' chiad <a routerLink='/folder/Signature/New'>Ainm-sgrìobhte</a> ris.",
  Repeat_Indefinitely:"Dèan ath-aithris gu bràth",
  Sign:"Soidhne",
  Sign_Here:"Clàraich an seo",
  Date_Signed:"Ceann-latha air a shoidhnigeadh",
  Signature_Pad:"Pad Soidhnidh",
  Account_Holder:"neach-cunntais",
  Account_Properties:"Buadhan a' chunntais",
  Name_On_Cheque:"Ainm air seic",
  Server_Hostname:"Ainm òstair / IP an fhrithealaiche",
  Printers:"Clò-bhualadairean",
  No_Printers:"Gun chlò-bhualadair",
  Printer_Exists:'Tha clò-bhualadair air a bheil an t-ainm seo ann mu thràth.',
  No_Printers_Word:"Cuir a' chiad <a routerLink='/folder/Printer/New'>Clò-bhualadair</a> ris.",
  No_Printer_Alert:"Chan eil clò-bhualadair air a mhìneachadh. Am bu toil leat clò-bhualadair a shuidheachadh?",
  Add_Printer:"Cuir Clò-bhualadair ris",
  New_Printer:"Clò-bhualadair ùr",
  Printer_Hostname:"Ainm òstair / IP clò-bhualadair",
  Printer_Label:"Label Clò-bhualadair",
  Printer_Protocol:"Pròtacal Clò-bhualadair",
  Protocol:"Pròtacal",
  Email_Print:"Post-d",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Socaid",
  Print_Job:"Clò-bhuail Job",
  Printed:"Clò-bhuailte",
  Not_Printed:"Gun chlò-bhualadh",
  Print_Jobs:"Obraichean Clò-bhualaidh",
  Print_Queue:"Cuimhne Clò",
  No_Print_Jobs:"Gun obraichean clò-bhualaidh",
  No_Prints:"Cruthaich <a routerLink='/folder/Cheque/New'>Seic</a> ùr airson a chlò-bhualadh.",
  Prints:"Clò-bhualaidhean",
  Find_Printer:"Lorg Clò-bhualadair",
  Find_AirPrint_Devices:"Lorg innealan AirPrint",
  Select_Printer:"Tagh clò-bhualadair",
  System_UI:"UI an t-siostaim",
  Printer:"Clò-bhualadair",
  Status:"Inbhe",
  Preview:"Ro-shealladh",
  Enable_Print_Job:"Cuir an comas obair clò-bhualaidh",
  Queue_Weight:"Cuideam ciudha",
  Unlimited:"Gun chrìoch",
  Show_Advanced_Printer_Options:"Seall roghainnean clò-bhualadair adhartach",
  Advanced:"Adhartach",
  Location:"Àite",
  Note:"Thoir an aire",
  Queue_Name:"Ainm na Ciudha",
  Pages_Printed_Limit:"Crìoch air duilleagan clò-bhuailte",
  MultiPage_Idle_Time:"Am(an) feitheimh ioma-dhuilleag",
  Page_Count_Limit:"Crìochan àireamh na duilleige",
  Page_Orientation:"Stuireadh na duilleige",
  Portrait:"Dealbh",
  Landscape:"Tìr-tìre",
  Duplex:"Duplex",
  Double_Sided_Printing:"Dà-thaobh",
  Duplex_Mode:"Modh Duplex",
  Duplex_Short:"Geàrr",
  Duplex_Long:"Fada",
  Duplex_None:"Chan eil",
  Color_And_Quality:"Dath agus Càileachd",
  Monochrome:"Monochrome",
  Photo_Quality_Prints:"Clò-bhualaidhean càileachd dhealbhan",
  Printer_Email:"Post-d clò-bhualadair",
  Automatically_Downsize:"Lùghdachadh gu fèin-ghluasadach",
  Paper:"Pàipear",
  Paper_Name:"Ainm pàipear",
  Paper_Width:"Leud pàipear",
  Paper_Height:"Àrd pàipear",
  Paper_Autocut_Length:"Fad pàipear air a ghearradh gu fèin-ghluasadach",
  Margins:"iomaill",
  Page_Margins:"Iall na duilleige",
  Page_Margin_Top:"Iomall na duilleige as àirde",
  Page_Margin_Right:"Ceart na duilleige",
  Page_Margin_Bottom:"Iomall na duilleige Bun",
  Page_Margin_Left:"Iomall na duilleige Clì",
  Add_Employees:"Cuir luchd-obrach ris",
  Header:"Ceannard",
  Print_A_Page_Header:"Clò-bhuail Ceann-cinn na duilleige",
  Header_Label:"Leubail cinn",
  Header_Page_Index:"Clàr-innse na duilleige cinn",
  Header_Font_Name:"Cruth-cinn",
  Select_Font:"Tagh cruth-clò",
  Font_Selector:"Roghnaiche cruth-clò",
  Header_Font_Size:"Cruth-cinn",
  Header_Bold:"Ceannard trom",
  Header_Italic:"Header Eadailteach",
  Header_Alignment:"Co-thaobhadh cinn",
  Left:"Clì",
  Center:"Ionad",
  Right:"Deas",
  Justified:"Fìreanachadh",
  Header_Font_Color:"Dath a' chinn",
  Select_Color:"Tagh dath",
  Footer:"Footer",
  Print_A_Page_Footer:"Clò-bhuail bonn-coise na duilleige",
  Footer_Label:"Footer Label",
  Footer_Page_Index:"Clàr-innse Duilleag Footer",
  Footer_Font_Name:"Footer Font",
  Fonts:"Cruth-clò",
  Done:"Dèan",
  Select_Fonts:"Tagh cruthan-clò",
  Footer_Font_Size:"Meud Footer",
  Footer_Bold:"Footer trom",
  Footer_Italic:"Footer Eadailteach",
  Footer_Alignment:"Co-thaobhadh Footer",
  Footer_Font_Color:"Dath na Footer",
  Page_Copies:"Leth-bhreacan duilleag",
  Enable_Printer:"Cuir an comas clò-bhualadair",
  Remote_Logging:"Logadh Iomallach",
  Log_Server:"Frithealaiche Log",
  Encryption:"Crioptachadh",
  Random_Key:"Random Key",
  Encryption_Key:"Iuchrach crioptachaidh",
  Cheques_Webserver:"Stòr-dàta Custom",
  Learn_How:"Ionnsaich mar",
  Signature:"ainm-sgrìobhte",
  Default_Printer_Unit:"ann an/cm/mm/(pt)",
  View_Signature:"Seall ainm-sgrìobhte",
  Printed_Signature:"Clò-sgrìobhte Signature",
  Digitally_Sign:"Soidhne digiteach",
  Digital_Signature:"Soidhne Didseatach",
  Add_Signature:"Cuir ainm-sgrìobhte ris",
  Add_Your_Signature:"Cuir d' ainm-sgrìobhte ris",
  Enable_Signature:"Cuir an comas ainm-sgrìobhte",
  Digitally_Signed:"Air a shoidhnigeadh gu didseatach",
  Insert_Error:"Cha b' urrainn dhuinn an t-seic a shàbhaladh ri linn duilgheadasan an stòr-dàta.",
  Delete_Confirmation:"A bheil thu cinnteach gu bheil thu airson am fiosrachadh seo a sguabadh às?",
  Discard_Confirmation:"A bheil thu cinnteach gu bheil thu airson am fiosrachadh seo a chur air falbh?",
  Discard_Bank_Confirmation:"A bheil thu cinnteach gu bheil thu airson an cunntas seo a chur air falbh?",
  Discard_Printer_Confirmation:"A bheil thu cinnteach gu bheil thu airson an clò-bhualadair seo a chur air falbh?",
  Delete_Bonus_Confirmation:"A bheil thu cinnteach gu bheil thu airson am bònas seo a sguabadh às?",
  Delete_Invoice_Confirmation:"A bheil thu cinnteach gu bheil thu airson an fhàirdeal seo a sguabadh às?",
  Generated_Cheque:"Seic air a ghineadh",
  Generated_Invoice:"Cunntas air a ghineadh",
  Schedule_Start_Time:"Tòiseachadh Clàr",
  Schedule_End_Time:"Crìoch air a' Chlàr",
  New_Call:"Call Ùr",
  No_Contacts:"Gun neach-aithne",
  No_Contacts_Word:"Bidh rianadairean, modaireatairean, luchd-obrach is luchd-pàighidh a’ nochdadh mar luchd-ceangail.",
  PDF_Subject:"ionmhas",
  PDF_Keywords:"seicean pàighidh pàighidh pàighidh PDF",
  Printer_Setup:"Suidhich a' chlò-bhualadair",
  Printer_Selection:"Tagh clò-bhualadair",
  New_Fax:"Facs Ùr",
  New_Fax_Message:"Teachdaireachd Facs Ùr",
  Fax_Machine:"Inneal facs",
  Fax_Name:"Ainm facs",
  Fax_Email:"Post-d facs",
  Fax_Number:"Àireamh facs",
  Contents:"Susbaint",
  Fax_Body:"Corp na duilleige",
  Header_Word:"Ceann-cinnidh:",
  Header_Text:"Teacsa cinn",
  Include_Header:"Cuir a-steach Ceann-cinn",
  Include_Footer:"Thoir a-steach footer",
  Footer_Word:"Footer:",
  Footer_Text:"Footer Text",
  Attach_a_Cheque:"Ceangail seic",
  Add_Deduction:"Cuir Deduction ris",
  Enable_Fax:"Cuir Facs",
  Select_Fax:"Tagh Facs",
  No_Faxes:"Gun facs",
  Faxes:"Facs",
  Save_and_Send:"Cuir Facs",
  Save_and_Pay:"Sàbhail is Pàigh",
  WARNING:"RABHADH:",
  Remember:"Cuimhnich",
  Printing:"Clò-bhualadh",
  Printing_Complete:"Clò-bhualadh coileanta!\n\n",
  of:"de",
  There_Were:"Bha ",
  Successful_Prints:"clò-bhualaidhean soirbheachail agus",
  Unsuccessful_Prints:"clò-bhualaidhean neo-shoirbheachail.",
  PrinterError:"Duilich! Bha mearachd ann.",
  Printing_:"Clò-bhualadh...",
  PrinterSuccess:"Chlò-bhuail an sgrìobhainn gu soirbheachail.",
  PrintersSuccess:"Sgrìobhainnean air an clò-bhualadh gu soirbheachail.",
  Print_Cheques:"Clò-bhuail seicichean",
  New_Message:"Teachdaireachd Ùr",
  New_Messages:"Teachdaireachdan Ùra",
  Read_Message:"Leugh Teachdaireachd",
  Write_Message:"Sgrìobh brath",
  Send_Message:"Cuir Teachdaireachd",
  Subject:"Cuspair",
  Message:"Teachdaireachd",
  Writing:"A' sgrìobhadh...",
  Send:"Cuir",
  Set_Date:"Ceann-latha suidhichte",
  Set_Time:"Suidhich ùine",
  Recieve:"Faigh",
  Set_as_Default:"Suidhich mar an àbhaist",
  Default_Account:"Cunntas bunaiteach",
  Default_Design:"Dealbhadh bunaiteach",
  Multiple_Cheques:"Seicean (Tripple)",
  Account_Mode:"Modh cunntais",
  Multiple_Cheques_Description:"Trì seicichean air gach duilleag.",
  Check_and_Table:"Seic & Clàr",
  Check_including_Table:"Clàr sgrùdaidh is cunntais.",
  Check_Mailer:"Thoir sùil air post-d",
  Check_Mailer_Window:"Thoir sùil le uinneag an t-seòlaidh.",
  DocuGard_Table_Top:"Seic & Clàr DocuGard (Mullach)",
  DocuGard_Table_Middle:"Seic & Clàr DocuGard (Meadhan)",
  DocuGard_Table_Bottom:"Seic & Clàr DocuGard (Bonn)",
  DocuGard_Mailer_Top:"DocuGard Check Mailer (Mullach)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (Meadhan)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mailer (Bun)",
  DocuGard_Three_Cheques:"Seicean DocuGard (Tripple)",
  DocuGard_Cheque_Top:"Seic DocuGard (Mullach)",
  DocuGard_Cheque_Middle:"Seic DocuGard (Meadhan)",
  DocuGard_Cheque_Bottom:"Seic DocuGard (Bun)",
  DocuGard_Three_Cheques_Window:"Trì seicichean air aon duilleag.",
  DocuGard_Table_Top_Window:"Clàr seic & teachd a-steach.",
  DocuGard_Table_Middle_Window:"Clàr seic & teachd a-steach.",
  DocuGard_Table_Bottom_Window:"Clàr seic & teachd a-steach.",
  DocuGard_Mailer_Top_Window:"Seic, clàr, agus seòladh.",
  DocuGard_Mailer_Middle_Window:"Seic, clàr, agus seòladh.",
  DocuGard_Mailer_Bottom_Window:"Seic, clàr, agus seòladh.",
  DocuGard_Cheque_Top_Window:"Thoir sùil airson pàipear tèarainte.",
  DocuGard_Cheque_Middle_Window:"Thoir sùil airson pàipear tèarainte.",
  DocuGard_Cheque_Bottom_Window:"Thoir sùil airson pàipear tèarainte.",
  Basic_Cheque:"Thoir sùil (mullach)",
  Basic_Cheque_Print:"Clò-bhuail aon seic.",
  Error_Setting_As_Paid:"Mearachd le suidheachadh mar phàigheadh",
  Add_Attachment:"Cuir ceanglachan ris",
  PrinterUnavailable:"Clò-bhualadair gun a bhith ri fhaighinn",
  CreditCardComponent:"Cairtean",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Tasgadh Ùr",
  Deposits:"Tasgaidhean",
  No_Deposits:"Gun tasgadh",
  Credit_Card_Deposit:"Cairt creideis",
  New_Credit_Card_Deposit_Message:"Tasgadh cairt-creideis",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin tasgadh",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Gluasad eadar-aghaidh",
  Payments_Limit:"Crìoch pàighidh",
  No_Payment_Limit:"Gun chrìoch pàighidh",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Tasgadh PayPal",
  No_Deposits_Word:"Cuir ris a' chiad chompanaidh <a routerLink='/folder/Deposit/New'>Tasgaidh</a>.",
  No_Documents_Specified:"Cha deach sgrìobhainn sam bith a shònrachadh airson clò-bhualadh",
  No_Printers_Added:"Cha deach clò-bhualadairean a lorg. Rach gu Settings > Clò-bhualadairean gus fear a chur ris.",
  DB_Erase_Prompt:"Sguab às an stòr-dàta gu lèir? RABHADH: Caillidh tu am fiosrachadh a tha air a stòradh!",
  Console_Warning:"Na cuir teacsa sam bith a-steach don chonsail seo. Faodaidh tu fhèin agus/no do chompanaidh a bhith ann an cunnart mòr.",
  No_Faxes_Word:"Cruthaich a' chiad <a routerLink='/folder/Fax/New'>Facs</a>.",
  Cheque_Delete_Confirmation:"A bheil thu cinnteach gu bheil thu airson an t-seic seo a sguabadh às?",
  Design_Delete_Confirmation:"A bheil thu cinnteach gu bheil thu airson an dealbhadh seo a sguabadh às?",
  Cheque_Pay_Confirmation:"Comharraich an t-seic seo mar phàigheadh? CHAN EIL e a’ nochdadh sa chiudha clò-bhualaidh.",
  Payment_Pay_Confirmation:"Comharraich am pàigheadh ​​seo mar phàigheadh? CHAN EIL e a’ nochdadh sa chiudha seic.",
  Delete_Deduction_Confirmation:"A bheil thu cinnteach gu bheil thu airson an tarraing seo a sguabadh às?",
  Delete_Job_Confirmation:"A bheil thu cinnteach gu bheil thu airson an obair seo a sguabadh às?",
  Delete_Timesheet_Confirmation:"A bheil thu cinnteach gu bheil thu airson an duilleag-ama seo a sguabadh às?",
  Delete_Schedule_Confirmation:"A bheil thu cinnteach gu bheil thu airson an clàr seo a sguabadh às?",
  Delete_Setting_Confirmation:"A bheil thu cinnteach gu bheil thu airson an suidheachadh seo ath-shuidheachadh?",
  Delete_Fax_Confirmation:"A bheil thu cinnteach gu bheil thu airson am facs seo a sguabadh às?",
  Delete_File_Confirmation:"A bheil thu cinnteach gu bheil thu airson am faidhle seo a sguabadh às?",
  Delete_Vacation_Confirmation:"A bheil thu cinnteach gu bheil thu airson an saor-làithean seo a sguabadh às?",
  Delete_Printer_Confirmation:"A bheil thu cinnteach gu bheil thu airson an clò-bhualadair seo a sguabadh às?",
  Remove_Design_Confirmation:"A bheil thu cinnteach gu bheil thu airson an dealbhadh seo a sguabadh às?",
  Delete_Payroll_Confirmation:"A bheil thu cinnteach gu bheil thu airson am pàigheadh ​​seo a sguabadh às?",
  Send_Fax_Email_Confirmation:"A bheil thu airson na sgrìobhainnean seo a chuir air facs is post-d?",
  Send_Email_Confirmation:"A bheil thu airson post-d dhan sgrìobhainn seo?",
  Send_Fax_Confirmation:"A bheil thu airson facs a chur dhan sgrìobhainn seo?",
  Error_Generating_PDF:"Duilich. Bha mearachd ann nuair a ghineadh an sgrìobhainn seo.",
  PDF_Error_Saving_Image:"Duilich. Bha mearachd ann le sàbhaladh dealbh PDF na sgrìobhainn seo.",
  Test_Printer_Confirmation:"A bheil thu airson duilleag deuchainn a chlò-bhualadh air a’ chlò-bhualadair seo?",
  Save_Timesheet_Prompt:"Feuch an cuir thu 'Title' no brùth 'Start Timer' gus a shàbhaladh.",
  Remove_Geofence_Prompt:"A bheil thu cinnteach gu bheil thu airson ionad na geo-fheansa seo a thoirt air falbh?",
  Delete_Employee_Confirmation:"A bheil thu cinnteach gu bheil thu airson sguabadh às",
  Fire_Employee_Confirmation:"A bheil thu cinnteach gu bheil thu airson losgadh"
}