export const ZhCn = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"版權所有2023年",
  black:"黑色的",
  green:"綠色的",
  gold:"金子",
  blue:"藍色的",
  brown:"棕色的",
  purple:"紫色的",
  pink:"粉色的",
  red:"紅色的",
  Swatches:"色板",
  HSL:"華碩",
  RGB:"紅綠藍",
  Hue:"色調",
  Saturation:"飽和",
  Lightness:"亮度",
  Upgrade_To_Pro:"升級到專業版",
  AllFeaturesInclude:"所有功能包括：",
  PrintUnlimitedCheques:"打印無限支票",
  PremiumChequeDesigns:"高級支票設計",
  ManageUnlimitedEmployees:"管理無限員工",
  AddUnlimitedPayees:"添加無限收款人",
  CreateUnlimitedPayrolls:"創建無限的工資單",
  UnlimitedSchedulesandTimeSheets:"無限的時間表和時間表",
  BulkPayPalPayouts:"批量支付貝寶",
  UnlimitedBankAccounts:"無限銀行賬戶",
  ManageMultipleCompanies:"管理多家公司",
  TrackInsurancePolicies:"跟踪保險政策",
  Bio_MetricProtection:"生物特徵保護",
  Geo_FenceLock_OutProtection:"地理圍欄鎖定保護",
  Multiple_Companies_Word:"如果沒有 支票保費，則無法管理多家公司。",
  PayPal_Payouts_Word:"如果沒有 支票保費，貝寶 付款將被禁用。",
  PINProtection:"密碼保護",
  PasswordProtection:"密碼保護",
  May_Require_Approval:"可能需要批准。",
  Subscribe:"訂閱",
  Billed:"計費",
  Up:"向上",
  Down:"向下",
  Positioning:"定位",
  Marker:"標記",
  Drag_Marker:"拖動標記",
  Tagline:"打印支票和製表工資",
  Marker_Word:"使用標記來調整元素的大小。",
  Pinch_Zoom:"捏縮放",
  Pinch_Word:"雙指縮放元素。",
  Drag:"拖",
  Drag_Word:"用手指拖動元素。",
  subscription_alias_word:"自動續訂訂閱",
  premium_alias_word:"一次性升級包",
  print_alias_word:"打印個人支票",
  mode_alias_word:"模式",
  Pro:"臨",
  Pro_word:"需要專業版。",
  Cant_Delete_Default_Company_Word:"抱歉，您不能刪除您的默認公司。",
  Reinsert_Default_Designs:"重新插入默認設計",
  Reinsert_Default_Designs_word:"您要重新插入默認設計嗎？",
  Subscription_Active_Disable_Word:"此訂閱有效。您想取消對 Cheques 的訂閱嗎？",
  Company_Logo:"公司標誌",
  ZERO_:"零",
  Disclaimer:"免責聲明",
  Privacy_Policy:"隱私政策",
  EULA:"檢查 EULA",
  Terms_Of_Service:"服務條款",
  Terms_Of_Use:"使用條款",
  Refunds:"退款政策",
  Single_Print:"1 檢查",
  Two_Prints:"2 支票",
  Five_Prints:"5 檢查",
  Ten_Prints:"10張支票",
  Fifteen_Prints:"15 支票",
  Twenty_Prints:"20張支票",
  Thirty_Prints:"30 張支票",
  Image_Added:"圖片已添加",
  Image_Preview:"圖片預覽",
  No_Image_Was_Selected:"沒有選擇圖像。",
  Cheques_Unlimited:"支票無限",
  _Subscription:"訂閱",
  Subscription:"支票 - 1 個月",
  Two_Month_Subscription:"支票 - 2 個月",
  Three_Month_Subscription:"支票 - 3 個月",
  Six_Month_Subscription:"支票 - 6 個月",
  Twelve_Month_Subscription:"支票 - 12 個月",
  Cheques_Are_Available:"支票可供打印。",
  Cheques_Thank_You:"非常感謝您購買支票！您的購買現已生效。",
  Upgrade_Required_Two:"選擇一個套餐並雙擊價格按鈕開始購買。在幾秒鐘內打印出具有專業外觀的全彩支票。",
  Month:"月",
  Due:"到期的：",
  Expires:"過期：",
  Subscription_Active:"訂閱有效",
  Subscription_Inactive:"訂閱無效",
  Purchase_Additional_Cheques:"購買額外的支票？",
  Printable_Cheque:"可打印支票",
  Printable_Cheques:"可打印支票",
  Printable_Cheque_Word:"支票可用於您的帳戶。",
  Printable_Cheques_Word:"您的帳戶上有支票。",
  Max_Amount_Message:"您指定的金額已超過為本系統設置的最大金額：",
  Terms_Required_Word:"在繼續使用支票之前，您必須同意本協議。",
  Subscriptions:"訂閱",
  Product_Upgrades:"升級",
  Mailed_Out_Cheques:"郵寄支票",
  Enter_A_Company_Name:"請輸入公司名稱。",
  Single_Cheques:"單一支票",
  Cheque_Golden:"黃金支票",
  Cheque_Golden_Window:"金色支票設計。",
  Cheque_Green:"綠色檢查",
  Cheque_Green_Window:"綠色檢查設計。",
  Cheque_Red:"紅色格子",
  Cheque_Red_Window:"紅色檢查設計。",
  Cheque_Yellow:"黃色格子",
  Cheque_Yellow_Window:"黃色檢查設計。",
  Cheque_Statue_of_Liberty:"自由女神像",
  Cheque_Statue_of_Liberty_Window:"自由女神像檢查設計。",
  Cheque_Green_Wave:"綠波",
  Cheque_Green_Wave_Window:"綠色檢查設計。",
  Cheque_Golden_Weave:"金織",
  Cheque_Golden_Weave_Window:"優雅的金色格子設計。",
  Cheque_Green_Sun:"綠太陽",
  Cheque_Green_Sun_Window:"深沉而平靜的格紋設計。",
  Cheque_Blue_Checkers:"藍色跳棋",
  Cheque_Blue_Checkers_Window:"藍色檢查設計。",
  Cashiers_Check:"本票",
  Cashiers_Check_Window:"銀行本票樣式模板。",
  Cheque_Aqua_Checkers:"水上跳棋",
  Cheque_Aqua_Checkers_Window:"水色檢查設計。",
  Cheque_Golden_Checkers:"金跳棋",
  Cheque_Golden_Checkers_Window:"金色支票設計。",
  Upgrade_Unavailable:"升級不可用",
  Bank_Code_Protection:"銀行號碼保護",
  Bank_Code_Protection_Word:"保護您的銀行號碼不被用於在另一台設備上運行的此應用程序或用於其他用戶。此操作不可逆轉。繼續？",
  Bank_Code_Protection_Blocked_Word:"您嘗試使用的銀行號碼是為其他用戶或設備保留的。",
  Bank_Code_Protection_Error_Word:"號碼驗證失敗。請連接到互聯網並嘗試重新添加此銀行帳戶。",
  Bank_Code_Protection_Set_Error_Word:"銀行號碼保護要求您連接到互聯網。請連接並重試。",
  Upgrade_Unavailable_Word:"抱歉，我們無法驗證您。當前無法在您所在的地區購買 支票 的訂閱和升級。",
  PayPal_Payment_Preview:"貝寶付款預覽",
  Apple_Pay:"蘋果支付",
  Select_PayPal:"選擇貝寶",
  PayPal_Applications:"貝寶應用程序",
  Purchase_With_Apple_Pay:"使用 蘋果支付 購買",
  Google_Pay:"谷歌支付",
  Companies:"公司",
  Insurance:"保險",
  New_PayPal:"新貝寶",
  Pay_By:"支付方式",
  Insure:"保證",
  Miles:"英里",
  Payment_Method:"付款方式",
  Select_Policies:"選擇政策",
  Policies:"政策",
  Policy:"政策",
  No_PayPal_Account:"沒有貝寶賬戶",
  No_Policies:"沒有保險政策",
  New_Policy:"新政策",
  PayPal_Payment:"貝寶支付",
  PayPal_Payments:"貝寶付款",
  No_Payment_Selected:"未選擇付款",
  Sending_Payment_Word:"請稍候... 正在發送這筆付款。",
  Sending_Payments_Word:"請稍候... 正在發送付款。",
  No_Payment_Selected_PayPal:"沒有選擇要發送的 <a routerLink='/folder/Payments'>貝寶 付款</a>。",
  Payment_Sent:"付款已發送",
  PayPal_Payment_Sent:"此付款已通過 貝寶 發送。",
  Copay:"共付額",
  Dead:"死的",
  Alive:"活",
  Not_Dead:"沒死",
  Unclaimed:"無人認領",
  Attempted:"嘗試過",
  Deceased:"死者",
  Claimed:"聲稱",
  Unpaid:"未付",
  Sending_Payment:"發送付款",
  Sending_Payments:"發送付款",
  Send_PayPal_Confirmation:"您要使用 PayPal 發送此交易嗎？",
  Send_PayPal_Confirmation_Word:"按綠色按鈕發送此交易。",
  Save_Payment_As_Unpaid:"將這筆付款保存為未付款？",
  Payment_Pay_Confirmation_PayPal:"將此付款保存為已付款？",
  No_Policies_Word:"現在添加第一張<a routerLink='/folder/Postage/New'>保險政策</a>。",
  Timesheet_Multiple_Delete_Confirmation:"您確定要刪除多個時間表嗎？",
  Select_Multiple_Timesheets_Prompt:"未選擇時間表。選擇至少一個時間表。",
  Select_Multiple_Policies_Prompt:"未選擇政策。至少選擇一項保險單。",
  Policies_Multiple_Delete_Confirmation:"您確定要刪除多個策略嗎？",
  Company:"公司",
  Add_Company:"添加公司",
  New_Company:"添加公司",
  No_Companies:"沒有公司",
  Enable_Company:"啟用公司",
  Select_Company:"選擇公司",
  The_Default_Company:"默認公司標籤。",
  Manage_Companies:"管理公司",
  No_Companies_Word:"支票將使用默認公司。<br />添加<a routerLink='/folder/Company/New'>第一個公司</a>。",
  Default_Company:"默認公司",
  Cheques_Unlimited_Word:"Checks Unlimited 允許您打印任意數量的支票。",
  Cheques_Subscription_Word:"支票訂閱允許您打印任意數量的支票。",
  You_Own_This_Product:"您擁有此產品。",
  Your_Subscription_is_Active:"您的訂閱有效。",
  Active_Products:"活化產品",
  Purchase_Confirmation:"購買",
  Purchase_Cheques:"採購支票",
  Restore_Purchase:"恢復購買",
  Erase_Purchase:"擦除購買",
  Successfully_Purchased:"購買成功",
  Enter_Your_Licence_Key:"請在此頁面上輸入您的許可證密鑰以激活此產品。",
  Licence_Key:"註冊碼",
  Enter_Licence_Key:"輸入許可證密鑰",
  Purchased:"已購買",
  Enable_Feature:"啟用功能",
  Cancel_Subscription:"取消訂閱",
  Subscription_Removed:"訂閱已刪除",
  Select_Active_Subscription:"選擇一個活動訂閱以對其進行修改。",
  Remove_Subscription_Word:"您確定要取消此訂閱嗎？",
  Delete_Company_Confirmation:"您確定要刪除整個公司嗎？警告：您將丟失所有存儲的信息！",
  Delete_Default_Company_Word:"您不能刪除默認公司。您想重置應用程序並將其恢復到默認狀態嗎？警告：您將丟失所有存儲的信息！",
  Console_Warning_2:"不要使用此應用程序處理當前不屬於您的任何貨幣。",
  Terms_and_Conditions:"條款和條件",
  and_the:"和",
  for:"為了",
  Please_Read_Word:"請閱讀並同意",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"找不到某些貨幣兌換率。請連接到互聯網。",
  Free:"自由的",
  DB_Erase_Prompt_2:"徹底擦除整個開發者數據庫？警告：您將丟失所有購買和訂閱信息！",
  Successfully_Imported:"導入成功",
  Select_Postage:"選擇郵費",
  No_Postage:"沒有郵票",
  No_Paid_Postage_Word:"添加第一個 <a routerLink='/folder/Postage/New'>已付郵費</a> 郵票。",
  Trial_Complete:"試用完成",
  Please_Upgrade:"請升級支票以繼續打印。",
  Aa:"啊",
  Color:"顏色",
  Font:"字體",
  Guide:"指導",
  Guides:"導遊",
  Image:"圖像",
  Zoom:"飛漲",
  Logo:"標識",
  Bank:"銀行",
  MICR:"微創",
  Total:"全部的",
  Cancel:"取消",
  Confirm:"確認",
  Method:"方法",
  Biometric_Security:"生物識別安全",
  Please_Login_To_Continue:"請登錄訪問更多內容。",
  Complete:"完全的",
  Sign_Up:"報名",
  Error:"錯誤",
  Biometrics:"生物識別",
  Percent:"百分",
  Zero_Percent:"0%",
  Top_Margin:"頂邊距",
  Bottom_Margin:"底邊距",
  Left_Margin:"左邊距",
  Right_Margin:"右邊距",
  MICR_Margin:"MICR 保證金",
  Table_Margin:"表格邊距",
  Address_Margin:"地址保證金",
  Percentage_:"百分比",
  Vacation_Title:"假期標題",
  Use_PIN:"使用密碼",
  Loading__:"加載中...",
  Design_Title:"設計標題",
  Authorize:"授權",
  Key:"鑰匙",
  Public_Key:"公鑰",
  Private_Key:"私鑰",
  Authenticate:"認證",
  Last_Payroll:"最後的工資單",
  Last_Calculation:"最後計算",
  Authorized:"授權",
  Geo_Authorized:"地理位置：授權",
  Not_Authorized:"未經授權",
  Authorization_Complete:"授權完成",
  Geolocation_Authorization:"地理位置授權",
  Out_of_Bounds:"越界",
  Cant_Delete_Default_Design:"無法刪除默認設計。",
  Unauthorized_Out_of_Bounds:"未經授權：越界",
  Biometric_Authorization:"生物識別授權",
  Locating_Please_Wait:"定位中，請稍候...",
  Test_Biometrics:"測試生物識別",
  Cheque_Margins:"檢查保證金",
  Percentage_Full_Error:"百分比字段不能設置超過 100% 百分比。",
  No_Payroll_Text:"添加一個 <a routerLink='/folder/Employee/New'>員工</a> 或 <a routerLink='/folder/Payee/New'>收款人</a> 和一個 <a routerLink='/folder/Schedule/New'>時間表</a>。",
  Design_Saved:"設計已保存",
  Default_Design_Selected:"選定的默認設計",
  Partial_Import:"部分進口",
  Partial_Export:"部分出口",
  Entire_Import:"整個導入",
  Entire_Export:"整體出口",
  Existing_Password:"請輸入您現有的密碼：",
  Existing_PIN:"請輸入您現有的 PIN 碼：",
  Pin_Change_Header:"密碼確認",
  Pin_Change_SubHeader:"輸入您的舊 PIN 碼以確認更改。",
  Pass_Change_Header:"確認密碼",
  Pass_Change_SubHeader:"輸入您的舊密碼以確認更改。",
  PIN_Enter_Message:"輸入您的 PIN 碼進行確認。",
  Password_Enter_Message:"輸入您的密碼進行確認。",
  Pin_Updated_Success:"PIN 更新成功！",
  Pin_Updated_Fail:"無法更新 PIN。",
  Pass_Updated_Success:"密碼更新成功。",
  Pass_Updated_Fail:"無法更新密碼。",
  Preview_Payment:"預覽付款",
  Preview_Payroll:"預覽工資單",
  No_Payments_Created:"未發現已創建任何付款。",
  Payment_Preview:"付款預覽",
  Enable_Payee:"啟用收款人",
  Rendered:"呈現",
  No_Email:"沒有電子郵件",
  Setup_Cheques:"設置檢查",
  name:"姓名",
  address:"地址",
  address_2:"地址行 2",
  city:"城市",
  province:"省",
  postal_code:"郵政編碼",
  country:"國家",
  username:"用戶名",
  full_name:"全名",
  birthday:"生日",
  email:"電子郵件",
  phone:"電話",
  employees:"僱員",
  addresses:"地址",
  payment_amount_limit:"付款限額",
  total_limit:"總限額",
  payees:"收款人",
  description:"描述",
  address_line_one:"地址第一行",
  address_line_two:"地址第二行",
  image:"圖像",
  account_holder:"帳戶持有人",
  cheque_starting_id:"檢查起始 ID",
  transit_number:"中轉號碼",
  institution_number:"機構編號",
  designation_number:"編號",
  account_number:"帳號",
  currency:"貨幣",
  signature:"簽名",
  payment_payroll_limit:"支付限額",
  total_limi:"總限額",
  date:"日期",
  printed_memo:"打印的備忘錄",
  banks:"銀行",
  signature_image:"簽名圖片",
  address_name:"地址名稱",
  notes:"筆記",
  design:"設計",
  title:"標題",
  frequency:"頻率",
  fax:"傳真",
  salaries:"工資",
  salary_ids:"工資 ID",
  start_time:"開始時間",
  end_time:"時間結束",
  paid:"有薪酬的",
  overtime_percentage:"付費百分比",
  overtime_amount:"支付固定金額",
  first_name:"名",
  last_name:"姓",
  moderation:"適度",
  create:"創造",
  edit:"編輯",
  destroy:"破壞",
  day_start_time:"一天開始時間",
  day_end_time:"日終時間",
  fullname:"姓名",
  time:"時間",
  auto_send:"自動發送",
  time_method:"時間法",
  schedules:"時間表",
  indefinite_payroll_enabled:"無限期啟用",
  amount:"數量",
  repeat:"重複",
  always_enabled:"始終啟用",
  start_date:"開始日期",
  end_date:"結束日期",
  Cheque_Total:"檢查總計",
  Total_Amount:"總金額：",
  Amounts:"金額：",
  Images:"圖片",
  Files:"文件",
  Previewing:"預覽：",
  Insert:"插入",
  Preview_Import:"預覽導入",
  Entry:"入口",
  Entries:"條目",
  No_Entries:"沒有條目",
  Import_Type:"導入類型",
  Select_Details:"選擇詳細信息",
  Select_Payee:"選擇收款人",
  Enable_Holidays:"啟用假期",
  Disable_Holidays:"禁用假期",
  Wire_Transfer:"電匯",
  New_Export:"新出口",
  Export_Data:"導出數據",
  Export_Data_Word:"選擇要導出和下載的文件類型。",
  Export_File:"導出文件",
  Mode:"模式",
  Times:"次",
  Widgets:"小部件",
  Slider:"滑塊",
  Set_Details:"設置細節",
  Select_Type:"選擇類型",
  Display_Slider:"顯示滑塊",
  Dashboard_Slider:"儀表板滑塊",
  Dashboard_Mode:"儀表板模式",
  Show_Intro:"節目介紹",
  Show_Payrolls:"顯示工資單",
  Show_Holidays:"展會假期",
  Show_Invoices:"顯示發票",
  Show_Cheques:"顯示支票",
  Enabled_Widgets:"啟用的小部件",
  Disabled_Widgets:"禁用的小部件",
  Colors:"顏色",
  Barcodes:"條形碼",
  View_Timers:"查看計時器",
  Gradients:"漸變",
  No_Info:"沒有信息",
  Disable:"停用",
  Show_Layer:"顯示圖層",
  Hide_Layer:"隱藏圖層",
  Text_Layer:"文字圖層",
  Secondly:"第二",
  Minutely:"每分鐘",
  nine_am:"9:00 AM",
  five_pm:"5:00 PM",
  Enable_Address:"啟用地址",
  Invalid_File_Type:"抱歉，選擇了無效的文件類型。支持的文件類型：",
  Error_Updating_Entry:"抱歉，更新此條目時出錯。",
  Schedule_Timing_Alert:"錯誤：計劃開始時間設置為結束時間之後的值。",
  Select_Multiple_Payments_Prompt:"未選擇付款。選擇至少一項付款。",
  Select_Multiple_Cheques_Prompt:"未選擇檢查。請至少選擇一張支票。",
  Select_Multiple_Items_Prompt:"沒有選擇項目。請至少選擇一項。",
  Paymemt_Multiple_Delete_Confirmation:"您確定要刪除多筆付款嗎？",
  Cheque_Multiple_Delete_Confirmation:"您確定要刪除多張支票嗎？",
  Payee_Multiple_Delete_Confirmation:"您確定要刪除多個收款人嗎？",
  Employee_Multiple_Delete_Confirmation:"您確定要刪除多個員工嗎？",
  MICR_Warning:"注意：某些打印機和設備可能無法正確顯示 MICR 字體。",
  Automatically_Send:"自動發送",
  Type:"類型",
  Payment_Type:"付款方式",
  Auto_Send:"自動發送",
  Automatically_Process:"自動處理",
  Auto_Process:"自動處理",
  Image_Based:"基於圖像",
  Font_Based:"基於字體",
  Rerender:"重新渲染",
  Rendering:"渲染",
  Render:"文件",
  Top:"頂部",
  Middle:"中間",
  Bottom:"底部",
  Top_Left:"左上方",
  Top_Center:"頂部中心",
  Top_Right:"右上",
  Middle_Left:"中左",
  Middle_Center:"中間中心",
  Middle_Right:"中右",
  Bottom_Left:"左下方",
  Bottom_Center:"底部中心",
  Bottom_Right:"右下方",
  Numbers:"數字",
  Verified:"已驗證",
  Paper_Size:"紙張尺寸",
  New_Device:"新設備",
  Add_Device:"添加設備",
  Remove_Device:"刪除設備",
  Delete_Device:"刪除設備",
  Block_Device:"塊設備",
  Block:"堵塞",
  Unblock:"解鎖",
  Table:"桌子",
  Scan_Login_Code:"掃描登錄碼",
  Login_Code:"登入密碼",
  Scan_Code:"掃碼",
  Scan_QR_Code:"掃描二維碼",
  Select_All:"全選",
  Deselect_All:"取消全選",
  Increase:"增加",
  Decrease:"減少",
  Bold:"大膽的",
  Text:"文本",
  Style:"風格",
  Italic:"斜體",
  QR_Code:"二維碼",
  Barcode:"條碼",
  Browse_Images:"瀏覽圖像",
  Browse_Files:"瀏覽文件",
  Background_Image:"背景圖",
  Logo_Image:"形象標識",
  Header_Image:"標題圖片",
  Bank_Image:"銀行形象",
  Cut_Lines:"切割線",
  Background:"背景",
  License:"執照",
  One_License:"1 執照：",
  Licensed:"授權給：",
  Not_Licensed:"未獲許可",
  Enter_Serial:"輸入序列號",
  Serial_Key:"序列號",
  Serial:"連續劇",
  Product_Key:"產品密鑰",
  Check_Product_Key:"驗證產品密鑰",
  Add_Product_Key:"輸入產品密鑰",
  Verifying_Purchase:"正在驗證購買...",
  Print_Envelope:"打印信封",
  Envelope:"信封",
  Thank_You:"謝謝你！",
  Scale:"規模",
  Print_Scale:"打印比例",
  Borders:"邊框",
  VOID:"空白",
  Void_Cheque:"作廢支票",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"支付訂單：",
  NO_DOLLARS:"沒有美元",
  ONE_DOLLAR:"一美元",
  DOLLARS:"美元",
  AND:"和",
  CENTS:"美分。",
  NO_:"不",
  ONE_:"一",
  AND_NO_:"和不",
  _AND_ONE_:"和一個",
  DOLLARS_AND_ONE_CENT:"和一分錢。",
  AND_NO_CENTS:"和零美分。",
  CHEQUE_PRINT_DATE:"日期：",
  CHEQUE_PRINT_MEMO:"備忘錄：",
  CHEQUE_PRINT_SIGNATUREMP:"國會議員",
  Initial_Tasks:"初始任務",
  Inital_Setup:"初始設置",
  Welcome_To:"歡迎來到",
  Welcome:"歡迎",
  Swipe:"滑動",
  Intro_Setup:"簡介設置",
  Introduction:"介紹",
  CHEQUE_PRINT_CREDIT:"由支票提供支持",
  Title_Intro_Word:"歡迎使用支票",
  Title_Intro:"支票介紹",
  Title_Setup:"檢查設置",
  PayPal_Default_Email_Message:"您收到了一筆新的 貝寶 轉賬。",
  Cheques_App_Export:"支票出口",
  No_Prints_Purchased:"沒有購買印刷品",
  No_Prints_Purchased_Word:"沒有額外的印刷品可用。請購買額外的支票。",
  Post_Intro_Thanks:"感謝您選擇支票。設置過程現已完成。",
  Post_Intro_Word:"從打印您的第一張支票開始，添加未來付款或將員工添加到工資單中。",
  Upgrade_Required:"Checks 要求您擁有更高版本的軟件才能隱藏此消息並解鎖其他功能。",
  Upgrade_Title:"支票",
  Mailout_Prompt:"您還可以選擇讓 Checks 為您郵寄工資支票。",
  Mailed_Cheque:"郵寄支票",
  Mailed_Cheque_Color:"郵寄支票（彩色）",
  Terms_Purchase:"自購買之日起 30 天內，所有使用支票進行的電子購買均可全額退款。請閱讀並同意",
  Dashboard_Setup:"設置主打印機",
  Dashboard_Add:"添加主要銀行賬戶",
  Dashboard_Customize:"選擇檢查模板",
  Dashboard_Job_Salary:"創造你的工作並增加你的薪水",
  Dashboard_Employees:"跟踪員工和收款人",
  Dashboard_Print:"打印並郵寄您的薪水支票",
  Dashboard_Payroll:"自動化您的工資單打印",
  Dashboard_PayPal:"設置 PayPal 薪資/付款",
  Begin_Setup:"開始設置",
  Get_Started:"開始",
  Purchase:"購買",
  Lockdown:"封鎖",
  Unlock:"開鎖",
  Detailed:"詳細的",
  Log_In:"登錄",
  Login:"登錄",
  Launch:"發射",
  Register:"登記",
  Finish:"結束",
  List:"列表",
  Weekends:"週末",
  Weekly:"每週",
  PayPal_Default_Subject:"新付款",
  Payment_Message:"付款訊息",
  PayPal_Default_Payment_Note:"謝謝",
  Setup_Your_Cheqing_Account:"支票賬戶",
  Add_Your_Primary_Cheqing_Account:"添加您的主要支票賬戶。",
  Welcome_Word:"簡化和自動化薪資和人力資源管理。",
  Get_Started_Quickly:"只需回答幾個簡單的問題，這將幫助我們開始......",
  Done_Intro_Word:"安裝完成",
  PIN_Protected:"密碼和 PIN 保護",
  Enter_New_PIN:"輸入新的 PIN 碼：",
  Enter_PIN:"輸入密碼：",
  Invalid_PIN:"輸入的 PIN 無效。",
  Account_Identifier:"帳戶標識符",
  New_Account_Identifier:"新帳戶標識符",
  attempt:"試圖",
  attempts:"嘗試",
  remaining:"其餘的",
  Language:"語言",
  languages:"語言",
  select_languages:"選擇語言",
  Deposit:"訂金",
  Hire_One_Now:"現在僱一個",
  App_Locked:"該應用程序已鎖定。",
  Skip_:"跳過",
  Skip_to_Dashboard:"跳到儀表板",
  Dashboard:"儀表板",
  Import:"進口",
  Admin:"管理員",
  New_Admin:"新管理員",
  Settings:"設置",
  Color_Picker:"選色器",
  Font_Picker:"字體選擇器",
  Logout:"登出",
  Close:"關閉",
  Close_menu:"關閉",
  Excel:"文件",
  Csv:"CSV文件",
  Sql:"文件",
  Json:"JSON文件",
  Url:"通過 URL 導入",
  Back:"後退",
  Timers:"計時器",
  Cheque:"查看",
  Print:"打印",
  Designs:"設計",
  Pause_Printing:"暫停打印",
  Resume_Printing:"恢復打印",
  Printing_Paused:"打印暫停",
  PrintingUnavailable:"對不起！在此系統上無法打印。",
  Prints_Paused:"打印暫停",
  Administration:"行政",
  Loading:"加載中",
  Unnamed:"無名",
  error:"抱歉，無法打開此支票進行查看。",
  No_Cheques_To_Print:"沒有要打印的支票",
  No_Cheques_To_Print_Word:"創建一張<a routerLink='/folder/Cheque/New'>新支票</a>。",
  New_Cheque:"新支票",
  Start_One_Now:"現在開始",
  Edit_Cheque:"編輯檢查",
  Select_Cheques:"選擇支票",
  Select_Employee:"選擇員工",
  Default_Printer:"默認打印機",
  Reassign:"重新分配",
  Configure:"配置",
  Template:"模板",
  Designer:"設計師",
  Edit_Designs:"編輯設計",
  New_Design:"新設計",
  Next:"下一個",
  Save:"節省",
  Name:"姓名",
  Mail:"郵件",
  Amount:"數量",
  Date:"日期",
  PayPal:"貝寶",
  Payouts:"支出",
  PayPal_Label:"貝寶標籤",
  Email_Username:"電子郵件/用戶名",
  Client_ID:"客戶端編號",
  Sandbox_Email:"沙箱電子郵件",
  PayPal_Email:"貝寶郵箱",
  PayPal_Username:"API用戶名",
  PayPal_Payouts:"貝寶付款",
  Select_PayPal_Key:"選擇貝寶密鑰",
  Secret:"秘密",
  API_Secret:"API 秘密",
  PayPal_API_Keys:"貝寶鑰匙",
  New_PayPal_Key:"新的貝寶密鑰",
  Email_Subject:"電子郵件主題",
  Email_Message:"電郵訊息",
  Payout_Options:"支付選項",
  Payment_Note:"付款單",
  Enable_Employee:"啟用員工",
  Enable_Production_Mode:"啟用生產模式",
  Sandbox_Username:"沙盒用戶名",
  Sandbox_Signature:"沙盒簽名",
  Sandbox_Password:"沙盒密碼",
  Production_Username:"生產用戶名",
  Production_Signature:"生產簽名",
  Production_Password:"製作密碼",
  Production_Email:"生產電子郵件",
  API_Client_ID:"API 客戶端 ID",
  API_Signature:"API 簽名",
  API_Password:"接口密碼",
  API_Username:"API用戶名",
  Secret_Key:"密鑰",
  Sandbox:"沙盒",
  Production:"生產",
  Sandbox_Keys:"沙箱密鑰",
  Production_Keys:"生產密鑰",
  API_Title:"API標題",
  Production_Mode:"生產方式",
  Account_Label:"賬戶標籤",
  No_PayPal_Setup:"沒有貝寶密鑰",
  Enable_PayPal_Account:"啟用貝寶賬戶",
  No_PayPal_Word:"添加您的 <a routerLink='/folder/Invoice/New'>貝寶 API 密鑰</a>。",
  Printed_Memo:"打印的備忘錄",
  Employee:"員工",
  View_Employee:"查看員工",
  Mailing_Address:"郵寄地址",
  Company_Address:"公司地址",
  Select_Company_Address:"選擇公司地址",
  Address:"地址",
  Any_Day:"任何一天",
  Address_Name:"地址名稱",
  Unit:"單元",
  Account:"帳戶",
  Bank_Account:"銀行賬戶",
  Account_Limits:"啟用賬戶限制",
  Payroll:"工資單",
  Run:"跑步",
  Run_Payroll:"運行薪資",
  New_Payroll:"新工資單",
  No_Payroll:"沒有即將到來的工資單",
  Upcoming_Holiday:"即將到來的假期：",
  Invoice_Due:"發票到期：",
  New_Invoice:"新發票",
  No_Invoices:"沒有發票",
  No_Invoices_Word:"創建第一個 <a routerLink='/folder/Invoice/New'>發票</a>。",
  Cheque_Due:"檢查到期：",
  Payrolls:"工資單",
  Sandbox_Mode:"沙盒模式",
  Hire:"聘請",
  Pay:"支付",
  New:"新的",
  Add:"添加",
  Make:"製作",
  Time:"時間",
  Write:"寫",
  Holiday:"假期",
  Holidays:"假期",
  Next_Holiday:"下一個假期：",
  All_Done:"全做完了！",
  Employees:"僱員",
  Payees:"收款人",
  Job:"工作",
  Jobs:"工作",
  Invoice:"發票",
  Invoices:"發票",
  Vacations:"假期",
  Cheques:"支票",
  Brand_Cheques:"你的品牌",
  Payment:"支付",
  Enable_Payment:"啟用付款",
  Payments:"付款",
  Schedule:"日程",
  Schedules:"時間表",
  Timesheet:"時間表",
  Timesheets:"時間表",
  Salary:"薪水",
  New_Address:"新地址",
  Address_2:"地址（第 2 行）",
  _City:"城市",
  _State:"州/省",
  City:"城市/鄉鎮",
  State:"州/省",
  Postal:"郵政編碼",
  ZIP:"郵政/郵編",
  Country:"國家",
  Addresses:"地址",
  Required_Options:"必需的選項",
  Optional_Options:"可選選項",
  Additional_Options:"其他選項",
  Required:"必需的",
  Optional:"選修的",
  Additional:"額外的",
  No_Addresses:"沒有地址",
  New_Address_Word:"添加第一個 <a routerLink='/folder/Address/New'>地址</a>。",
  Select_Address:"選擇地址",
  No_Address:"沒有地址",
  Print_Cheque:"打印支票",
  Print_Cheques:"打印支票",
  Print_Cheque_Now:"立即打印支票",
  Description:"描述",
  Pay_To_The_Order_Of:"支付給以下訂單：",
  Select_Date_Range:"選擇日期範圍",
  Select_Starting_Date:"選擇開始日期",
  Select_Ending_Date:"選擇結束日期",
  Select_Date:"選擇日期",
  Cheque_Date:"檢查日期",
  Cheque_Memo:"檢查備忘錄",
  Blank_Cheque:"空白支票",
  Blank:"空白的",
  No_Cheques:"沒有支票",
  No_Cheques_Word:"打印您的第一張<a routerLink='/folder/Cheque/New'>支票</a>。",
  Cheque_Amount_Placeholder:"0.00 美元",
  View_Image:"看圖",
  View:"看法",
  Modify:"調整",
  Delete:"刪除",
  Cheque_Paid:"有薪酬的",
  New_Deduction:"新扣除",
  Title:"標題",
  Frequency:"頻率",
  Hourly:"每小時",
  Daily:"日常的",
  Weekdays:"平日",
  BiWeekly:"2週",
  TriWeekly:"3週",
  Monthly:"每月",
  MiMonthly:"2個月",
  Quarterly:"季刊",
  Yearly:"每年",
  Every_Week:"每週",
  Every_Payroll:"每個工資單",
  Every_Month:"每個月",
  Annually:"每年",
  Always_Scheduled:"總是預定",
  Start_Date:"開始日期",
  End_Date:"結束日期",
  Start_Time:"開始時間",
  End_Time:"時間結束",
  Deduction_Label:"扣除標籤",
  Notes:"筆記",
  Options:"選項",
  Enable:"使能夠",
  Select_Deductions:"選擇扣除",
  Deductions:"扣除額",
  No_Deductions:"沒有扣除",
  No_Deductions_Word:"創建您的第一個 <a routerLink='/folder/Deduction/New'>扣除</a>。",
  New_Employee:"新員工",
  No_Title:"無題",
  _Name:"姓名",
  About_Yourself:"關於你自己",
  Full_Name:"全名",
  Birthday:"生日",
  Email:"電子郵件",
  SMS:"短信",
  Phone:"電話",
  Test:"測試",
  Call:"稱呼",
  Fax:"傳真",
  Printed_Note:"打印的筆記",
  Position:"位置",
  Job_Position:"工作職位",
  Select_a_Job:"選擇工作",
  Select_a_Salary:"選擇薪水",
  Add_a_Deduction:"添加扣除",
  Taxes:"稅收",
  Add_Taxes:"加稅",
  Date_of_Birth:"出生日期",
  Email_Address:"電子郵件地址",
  Phone_Number:"電話號碼",
  Phone_Call:"電話",
  Enable_on_Payroll:"在工資單上啟用",
  Select_Employees:"選擇員工",
  No_Employees:"沒有員工",
  No_Employees_Word:"添加您的第一個新<a routerLink='/folder/Employee/New'>員工</a>。",
  No_Name:"無名",
  Unemployeed:"失業",
  Employeed:"在職",
  Paid:"有薪酬的",
  Enabled:"啟用",
  Disabled:"殘疾人",
  Fire:"火",
  Not_Available:"無法使用",
  Not_Available_Word:"打印您的第一個<a routerLink='/folder/Invoice/New'>發票</a>並獲得報酬。",
  Select_Invoices:"選擇發票",
  Print_Invoice_Word:"打印您的第一個<a routerLink='/folder/Invoice/New'>發票</a>並獲得報酬。",
  Invoice_Title:"發票標題",
  Invoice_Date:"發票日期",
  Due_Date:"到期日",
  New_Job:"新工作",
  Details:"細節",
  Customize:"定制",
  Customize_Dashboard:"自定義儀表板",
  Components:"成分",
  No_Components:"沒有組件",
  Main_Components:"主要成分",
  Smaller_Components:"更小的組件",
  Error_Loading_Page:"加載此頁面時出錯。",
  Bank_Details:"銀行明細",
  About_Your_Job:"關於你的工作",
  Your_Schedule:"你的日程安排",
  Job_Title:"職稱",
  Job_Description:"職位描述",
  Job_Details:"職位詳情",
  Enable_Job:"啟用作業",
  Pay_Period:"薪水期",
  Perpetually_Schedule:"永久安排",
  Select_Jobs:"選擇職位",
  No_Jobs:"沒有工作",
  Add_Jobs:"添加職位",
  No_Jobs_Word:"將第一個 <a routerLink='/folder/Job/New'>Job</a> 添加到列表中。",
  Count_Employees:"job.employee_count+'僱員'",
  Zero_Employees:"0 名員工",
  New_Leave:"新假",
  Leave_Name:"留下姓名",
  Paid_Leave:"帶薪休假",
  Leave_Pay:"帶薪休假",
  Indefinite_Leave:"無限期休假",
  Indefinite_Payroll:"無限期薪資",
  Leave:"離開",
  Add_Schedules:"添加時間表",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"啟用缺席",
  Select_Leaves:"選擇樹葉",
  No_Leaves:"沒有請假",
  Leave_Of_Absence:"請假",
  Leaves_Of_Absence:"請假",
  New_Leave_of_Absense:"新的請假",
  No_Leaves_Word:"添加第一個 <a routerLink='/folder/Leave/New'>請假</a>。",
  Not_Enabled:"未啟用",
  Absences:"缺席",
  Payee:"收款人",
  New_Payee:"新收款人",
  Payee_Identifier:"收款人識別碼",
  Payee_Name:"收款人姓名",
  Payee_Title:"收款人名稱",
  Payment_Memo:"付款備忘錄",
  Select_Payees:"選擇收款人",
  No_Payees:"沒有收款人",
  Add_Payee_Note:"添加第一個<a routerLink='/folder/Payee/New'>收款人</a>。",
  New_Payees:"新收款人",
  About_The_Payment_Schedule:"付款時間表",
  Your_Payroll_Schedule:"自動發薪",
  New_Payment:"新付款",
  Identifier:"標識符",
  Selected:"已選",
  Select:"選擇",
  Memo:"備忘錄",
  Payment_Date:"付款日期",
  Mark_as_Paid:"標記為已付款",
  Select_Payments:"選擇付款方式",
  No_Payments:"沒有付款",
  No_Payments_Word:"創建第一個 <a routerLink='/folder/Payment/New'>支付</a>。",
  Create_Payroll:"創建工資單",
  Properties:"特性",
  Payroll_Title:"工資標題",
  Payroll_Notes:"工資單",
  Payroll_Setup:"薪資設置",
  Tabulate_Payments:"製表付款",
  Tabulate:"製表",
  By:"經過：",
  Payments_By:"付款人",
  Timesheets_And_Schedules:"時間表和時間表",
  Both:"兩個都",
  Items:"項目",
  Add_Payees:"添加收款人",
  Add_Account:"新增帳戶",
  Enable_Account:"啟用帳戶",
  Enable_Payroll:"啟用薪資",
  Print_Payroll:"打印工資單",
  No_Payrolls:"沒有工資單",
  No_Payroll_Word:"創建您的第一個 <a routerLink='/folder/Payroll/New'>工資單</a>。",
  View_more:"查看更多",
  Less:"較少的",
  Add_Payroll:"添加工資單",
  Select_Payroll:"選擇薪資",
  About_Your_Salary:"關於你的薪水",
  Add_Salaries:"添加工資",
  Add_Salary:"加工資",
  Salaries:"工資",
  No_Salaries:"沒有薪水",
  No_Salaries_Word:"添加第一個 <a routerLink='/folder/Salary/New'>薪水</a>。",
  Select_Salaries:"選擇工資",
  New_Salary:"新工資",
  Salary_Name:"工資標識符",
  Enable_Salary:"啟用工資",
  Salary_Amount:"工資金額",
  New_Schedule:"新時間表",
  Schedule_Title:"時間表標題",
  Add_Address:"添加地址",
  Repeat:"重複",
  Design:"設計",
  Edit_Design:"編輯設計",
  Edit_this_Design:"編輯此設計",
  Repeat_Payment:"重複付款",
  Enable_Schedule:"啟用時間表",
  Never:"絕不",
  Select_Schedule:"選擇時間表",
  No_Schedules:"沒有時間表",
  No_Schedules_Word:"創建第一個 <a routerLink='/folder/Schedule/New'>日程</a>。",
  New_Administrator:"新管理員",
  Username:"用戶名",
  First_Name:"名",
  Last_Name:"姓",
  Add_an_Address:"添加地址",
  Payment_Limit:"每次付款限額",
  Total_Limit:"總限額",
  Select_Accounts:"選擇賬戶",
  No_Administrators:"沒有管理員",
  No_Administrators_Word:"創建第一個 <a routerLink='/folder/Administrator/New'>管理員帳戶</a>。",
  New_Administrators_Word:"添加第一個<a routerLink='/folder/Administrator/New'>行政人員</a>",
  Cloud:"雲",
  Backup:"備份",
  Enable_iCloud:"啟用 iCloud",
  Enable_Google_Drive:"啟用谷歌云端硬盤",
  Enable_Microsoft_OneDrive:"啟用 Microsoft OneDrive",
  Automatically_Backup:"自動備份",
  FTP_Settings:"FTP設置",
  URL_File_Prompt:"請指定要導入的 .xls / .xlsx / .json 文件的位置：",
  URL_SQL_Prompt:"請指定要導入的 SQLite 文件的位置：",
  FTP_Backup:"FTP 備份",
  FTP_Import:"FTP導入",
  FTP:"文件傳輸協議",
  SFTP:"SFTP協議",
  Host:"主持人",
  Port:"港口",
  Path:"小路",
  Data_Path:"數據路徑",
  Enable_FTP_Account:"啟用 FTP 帳戶",
  HostnameIP:"主機名",
  Password:"密碼",
  Connection_Port:"連接端口",
  Enable_MySQL_Database:"啟用 MySQL 數據庫",
  Log:"日誌",
  Reset:"重置",
  Erase:"擦除",
  Export:"出口",
  Database:"數據庫",
  Upload_CSV:"上傳 CSV",
  Download_CSV:"下載 CSV",
  SQL_Database:"網址",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL 備份",
  Internal_Notes:"內部筆記",
  Root_Path:"根路徑",
  Select_Backup:"選擇備份",
  Add_New_Backup:"添加新備份",
  First_Backup:"設置第一個備份...",
  Backups:"備份",
  Add_Backup:"添加備份",
  No_Backups:"找不到備份。",
  Select_Backup_Type:"選擇您要設置的備份類型...",
  Backup_Type:"備份類型",
  FTP_Drive:"FTP驅動器",
  URL_Location:"網址",
  Apple_iCloud:"雲端",
  Google_Drive:"駕駛",
  Microsoft_OneDrive:"駕駛",
  Import_Fields:"導入字段",
  Import_Fields_Word:"使用此部分選擇<a routerLink='/folder/Holiday/New'>導入</a>數據。",
  Upload:"上傳",
  Download:"下載",
  Download_JSON:"下載為 JSON 數據",
  Download_SQL:"下載為 SQL 文件",
  New_Bank:"新銀行",
  New_Account:"新賬戶",
  New_Bank_Account:"新銀行賬戶",
  Upload_Image:"上傳圖片",
  Bank_Name:"銀行名",
  Bank_Address:"銀行地址",
  Branch_Address:"分行地址",
  Address_on_Cheque:"地址",
  Cheque_Numbers:"檢查號碼",
  Cheque_Details:"檢查詳情",
  Bank_Logo:"銀行標誌",
  Cheque_ID:"檢查身份證",
  Starting_Cheque_ID:"開始檢查 ID",
  Transit_Number:"中轉號碼",
  Institution_Number:"機構編號",
  Designation_Number:"編號",
  Account_Number:"帳號",
  Limits:"限制",
  Default_Limits:"默認限制",
  Over_Limit:"超限",
  Under_Limit:"下限",
  Payroll_Limit:"工資限額",
  Enable_Bank_Account:"啟用銀行賬戶",
  Select_Account:"選擇賬戶",
  No_Bank_Account:"沒有銀行賬戶",
  No_Bank_Account_Word:"添加第一個 <a routerLink='/folder/Accounts/New'>銀行賬戶</a>。",
  Bank_Accounts:"銀行賬戶",
  No_Accounts:"沒有帳戶",
  No_Accounts_Note:"添加第一個 <a routerLink='/folder/Accounts/New'>銀行賬戶</a>。",
  Cheque_Designer:"檢查設計師",
  Cheque_Design:"檢查設計",
  Select_Design:"選擇設計",
  Cheque_Designs:"檢查設計",
  No_Cheque_Designs:"無檢查設計",
  No_Cheque_Designs_Word:"創建您自己的<a routerLink='/folder/Settings/Cheque/Design/New'>支票設計</a>。",
  Set_Default:"設為默認",
  Default:"默認",
  Remove:"消除",
  Folder:"文件夾",
  Edit:"編輯",
  LoadingDots:"加載中...",
  Add_a_New_File:"添加一個 <a href='#' (click)='add()'>新文件</a> 到",
  this_folder:"這個文件夾",
  FTP_Backup_Settings:"FTP 備份設置",
  Secure_File_Transfer:"安全文件傳輸",
  New_Holiday:"新假期",
  Add_Holiday:"添加假期",
  Holiday_Name:"節日名稱",
  Additional_Pay:"附加費",
  Enable_Holiday:"啟用假期",
  Select_Holidays:"選擇假期",
  No_Holidays:"沒有假期",
  No_Holidays_Word:"添加第一個<a routerLink='/folder/Holiday/New'>公共假期</a>。",
  New_Import:"新導入",
  Import_Data:"導入數據",
  Import_Data_Word:"選擇您選擇的文件類型或上傳方法。<br />上傳支持的文件後，您將能夠選擇文件中導入的字段對應於應用程序中的任何參數。",
  Import_File:"導入文件",
  Link_To_File:"鏈接到文件",
  Select_File:"選擇文件",
  New_Moderator:"新版主",
  Allow_Moderation:"允許審核",
  Create_Paycheques:"創建薪水支票",
  Edit_Paycheques_and_Data:"編輯薪水支票和數據",
  Destroy_Data_and_Paycheques:"銷毀數據和薪水支票",
  Bonus_Percentage:"工資支票百分比",
  Fixed_Amount:"固定值",
  Select_Moderator:"選擇版主",
  No_Moderators:"沒有版主",
  Moderators:"主持人",
  Moderator_Account:"創建第一個 <a routerLink='/folder/Administrator/New'>版主帳戶</a>。",
  No_Moderators_Word:"添加第一個 <a routerLink='/folder/Administrator/New'>版主</a>。",
  Defaults:"默認值",
  Provide_Us_Info:"提供資料",
  Setup_Your_Printer:"設置您的打印機",
  Your_Company:"關於貴公司",
  Company_Name:"公司名稱",
  Currency:"貨幣",
  Default_Currency:"默認貨幣",
  Base_Monthly_Income:"月收入",
  Protection:"保護",
  App_Protection:"應用保護",
  PIN_Code_Protection:"PIN碼保護",
  App_Protection_Word:"啟用有助於保護您帳戶的安全方法。",
  PIN_Code:"PIN碼",
  Change_PIN:"更改密碼",
  New_Password:"新密碼",
  Geofence_Protection:"地理圍欄保護",
  Geofence_Area:"設置區域",
  Distance:"距離",
  Setup_Now:"立即設置",
  Mile:"英里",
  Km:"公里",
  m:"米",
  Facial_Recognition:"面部識別",
  Face:"臉",
  Setup:"設置",
  Label:"標籤",
  Password_Protection:"密碼保護",
  Modify_Password:"修改密碼",
  New_Tax_Entry:"新的稅收條目",
  New_Tax:"新稅",
  Tax_Label:"稅標",
  Perpetually_Enabled:"永久啟用",
  Select_Taxes:"選擇稅種",
  Tax_Deductions:"減稅",
  No_Tax_Deductions:"沒有稅收減免",
  No_Tax_Deductions_Word:"添加第一個 <a routerLink='/folder/Tax/New'>稅</a> 扣除。",
  New_Timer:"新定時器",
  Start:"開始",
  Stop:"停止",
  Start_Timer:"開始定時器",
  Stop_Timer:"停止定時器",
  Timer_Active:"定時器激活",
  Timer:"定時器：",
  Timer_Running:"定時器：（運行）",
  Save_Timer:"保存定時器",
  New_Timesheet:"新時間表",
  Select_Timesheets:"選擇時間表",
  Work_Notes:"工作筆記",
  Entry_Title:"作品名稱",
  No_Timesheets:"沒有時間表",
  No_Timesheets_Word:"添加第一個 <a routerLink='/folder/Timesheet/New'>時間表</a>。",
  Timesheet_Submitted:"時間表已提交",
  Timesheet_Congrats:"恭喜！您的時間表已成功提交。謝謝你！",
  Timesheet_Copy:"要接收您的文件副本，請向我們提供您的電子郵件地址和/或手機號碼。",
  Submit:"提交",
  Allow_Notifications:"允許通知",
  Untitled_Entry:"新條目",
  Untitled_Bank:"無題銀行",
  Timesheet_Entry:"時間表條目",
  Work_Description:"工作描述",
  Enable_Timesheet:"啟用時間表",
  Submit_Timesheet:"提交時間表",
  Vacation:"假期",
  New_Vacation:"新假期",
  Vacation_Name:"假期名稱",
  Paid_Vacation:"帶薪休假",
  Vacation_Pay:"假期工資",
  Enable_Vacation:"啟用休假",
  Select_Vacations:"選擇假期",
  No_Vacations:"沒有假期",
  No_Vacations_Word:"創建第一個 <a routerLink='/folder/Vacation/New'>假期</a> 條目。",
  Payroll_Schedule:"薪資表",
  Next_Payroll:"下一個工資單：",
  Upcoming_Payroll:"即將到來的工資單",
  No_Upcoming_Payroll:"沒有即將到來的工資單",
  Address_Book:"地址簿",
  Archived_Documents:"歸檔文件",
  Dev_Mode:"開發模式下的應用",
  Administrators:"管理員",
  Privacy:"隱私",
  None:"沒有任何",
  Select_Signature:"選擇簽名",
  No_Signatures:"沒有簽名",
  No_Signatures_Word:"添加第一個 <a routerLink='/folder/Signature/New'>簽名</a>。",
  Repeat_Indefinitely:"無限重複",
  Sign:"符號",
  Sign_Here:"在這裡簽名",
  Date_Signed:"簽署日期",
  Signature_Pad:"簽名板",
  Account_Holder:"帳戶持有人",
  Account_Properties:"帳戶屬性",
  Name_On_Cheque:"支票上的名字",
  Server_Hostname:"服務器主機名/IP",
  Printers:"打印機",
  No_Printers:"沒有打印機",
  Printer_Exists:"已存在同名打印機。",
  No_Printers_Word:"添加第一個 <a routerLink='/folder/Printer/New'>打印機</a>。",
  No_Printer_Alert:"未定義打印機。您想安裝打印機嗎？",
  Add_Printer:"添加打印機",
  New_Printer:"新打印機",
  Printer_Hostname:"打印機主機名/IP",
  Printer_Label:"打印機標籤",
  Printer_Protocol:"打印機協議",
  Protocol:"協議",
  Email_Print:"電子郵件",
  AirPrint:"空氣打印",
  IPP:"獨立生產計劃",
  LPD:"液化石油氣",
  HPJetDirect:"插座",
  Print_Job:"打印作業",
  Printed:"打印",
  Not_Printed:"未打印",
  Print_Jobs:"打印作業",
  Print_Queue:"打印隊列",
  No_Print_Jobs:"無打印作業",
  No_Prints:"創建一個新的 <a routerLink='/folder/Cheque/New'>查看</a> 進行打印。",
  Prints:"印刷",
  Find_Printer:"查找打印機",
  Find_AirPrint_Devices:"查找 AirPrint 設備",
  Select_Printer:"選擇打印機",
  System_UI:"系統界面",
  Printer:"打印機",
  Status:"地位",
  Preview:"預覽",
  Enable_Print_Job:"啟用打印作業",
  Queue_Weight:"隊列權重",
  Unlimited:"無限",
  Show_Advanced_Printer_Options:"顯示高級打印機選項",
  Advanced:"先進的",
  Location:"地點",
  Note:"筆記",
  Queue_Name:"隊列名稱",
  Pages_Printed_Limit:"打印頁數限制",
  MultiPage_Idle_Time:"多頁等待時間（秒）",
  Page_Count_Limit:"頁數限制",
  Page_Orientation:"頁面方向",
  Portrait:"肖像",
  Landscape:"景觀",
  Duplex:"雙工",
  Double_Sided_Printing:"兩面性",
  Duplex_Mode:"雙工模式",
  Duplex_Short:"短的",
  Duplex_Long:"長的",
  Duplex_None:"沒有任何",
  Color_And_Quality:"顏色和質量",
  Monochrome:"單色",
  Photo_Quality_Prints:"照片質量打印",
  Printer_Email:"打印機電子郵件",
  Automatically_Downsize:"自動縮小",
  Paper:"紙",
  Paper_Name:"論文名稱",
  Paper_Width:"紙張寬度",
  Paper_Height:"紙張高度",
  Paper_Autocut_Length:"自動切紙長度",
  Margins:"邊距",
  Page_Margins:"頁邊距",
  Page_Margin_Top:"首頁邊距",
  Page_Margin_Right:"右頁邊距",
  Page_Margin_Bottom:"底部頁邊距",
  Page_Margin_Left:"左頁邊距",
  Add_Employees:"添加員工",
  Header:"標頭",
  Print_A_Page_Header:"打印頁眉",
  Header_Label:"標頭標籤",
  Header_Page_Index:"標題頁索引",
  Header_Font_Name:"標題字體",
  Select_Font:"選擇字體",
  Font_Selector:"字體選擇器",
  Header_Font_Size:"標題字體",
  Header_Bold:"標題粗體",
  Header_Italic:"標題斜體",
  Header_Alignment:"標題對齊",
  Left:"左邊",
  Center:"中心",
  Right:"正確的",
  Justified:"合理的",
  Header_Font_Color:"標題顏色",
  Select_Color:"選擇顏色",
  Footer:"頁腳",
  Print_A_Page_Footer:"打印頁腳",
  Footer_Label:"頁腳標籤",
  Footer_Page_Index:"頁腳索引",
  Footer_Font_Name:"頁腳字體",
  Fonts:"字體",
  Done:"完畢",
  Select_Fonts:"選擇字體",
  Footer_Font_Size:"頁腳大小",
  Footer_Bold:"頁腳粗體",
  Footer_Italic:"頁腳斜體",
  Footer_Alignment:"頁腳對齊",
  Footer_Font_Color:"頁腳顏色",
  Page_Copies:"頁數",
  Enable_Printer:"啟用打印機",
  Remote_Logging:"遠程記錄",
  Log_Server:"日誌服務器",
  Encryption:"加密",
  Random_Key:"隨機密鑰",
  Encryption_Key:"加密密鑰",
  Cheques_Webserver:"自定義數據庫",
  Learn_How:"學習如何",
  Signature:"簽名",
  Default_Printer_Unit:"英寸/厘米/毫米/（磅）",
  View_Signature:"查看簽名",
  Printed_Signature:"印刷簽名",
  Digitally_Sign:"數字簽名",
  Digital_Signature:"電子簽名",
  Add_Signature:"添加簽名",
  Add_Your_Signature:"添加您的簽名",
  Enable_Signature:"啟用簽名",
  Digitally_Signed:"數字簽名",
  Insert_Error:"由於數據庫問題無法保存支票。",
  Delete_Confirmation:"您確定要刪除此信息嗎？",
  Discard_Confirmation:"您確定要放棄此信息嗎？",
  Discard_Bank_Confirmation:"您確定要放棄此帳戶嗎？",
  Discard_Printer_Confirmation:"您確定要丟棄這台打印機嗎？",
  Delete_Bonus_Confirmation:"您確定要刪除此獎勵嗎？",
  Delete_Invoice_Confirmation:"您確定要刪除此發票嗎？",
  Generated_Cheque:"生成支票",
  Generated_Invoice:"生成的發票",
  Schedule_Start_Time:"計劃開始",
  Schedule_End_Time:"計劃結束",
  New_Call:"新來電",
  No_Contacts:"沒有聯繫人",
  No_Contacts_Word:"管理員、版主、員工和收款人顯示為聯繫人。",
  PDF_Subject:"財政",
  PDF_Keywords:"工資支票 PDF 支票支票",
  Printer_Setup:"打印機設置",
  Printer_Selection:"打印機選擇",
  New_Fax:"新傳真",
  New_Fax_Message:"新傳真信息",
  Fax_Machine:"傳真機",
  Fax_Name:"傳真名稱",
  Fax_Email:"傳真電郵",
  Fax_Number:"傳真號",
  Contents:"內容",
  Fax_Body:"頁面正文",
  Header_Word:"標頭：",
  Header_Text:"標題文本",
  Include_Header:"包括標題",
  Include_Footer:"包括頁腳",
  Footer_Word:"頁腳：",
  Footer_Text:"頁腳文本",
  Attach_a_Cheque:"附上支票",
  Add_Deduction:"加減",
  Enable_Fax:"發送傳真",
  Select_Fax:"選擇傳真",
  No_Faxes:"沒有傳真",
  Faxes:"傳真",
  Save_and_Send:"發送傳真",
  Save_and_Pay:"保存並支付",
  WARNING:"警告：",
  Remember:"記住",
  Printing:"印刷",
  Printing_Complete:"打印完成！\n\n",
  of:"的",
  There_Were:"有",
  Successful_Prints:"成功的打印和",
  Unsuccessful_Prints:"不成功的打印。",
  PrinterError:"對不起！有一個錯誤。",
  Printing_:"印刷...",
  PrinterSuccess:"文檔打印成功。",
  PrintersSuccess:"文檔打印成功。",
  New_Message:"新消息",
  New_Messages:"新消息",
  Read_Message:"閱讀消息",
  Write_Message:"寫信息",
  Send_Message:"發信息",
  Subject:"主題",
  Message:"信息",
  Writing:"寫作...",
  Send:"發送",
  Set_Date:"設置日期",
  Set_Time:"設置時間",
  Recieve:"收到",
  Set_as_Default:"設為默認",
  Default_Account:"默認帳戶",
  Default_Design:"默認設計",
  Multiple_Cheques:"三檢",
  Account_Mode:"賬戶模式",
  Multiple_Cheques_Description:"每頁三張支票。",
  Check_and_Table:"檢查表",
  Check_including_Table:"核對會計表。",
  Check_Mailer:"檢查郵件",
  Check_Mailer_Window:"檢查地址窗口。",
  DocuGard_Table_Top:"大支票和桌子",
  DocuGard_Table_Middle:"大支票和桌子（中）",
  DocuGard_Table_Bottom:"大支票和桌子（底部）",
  DocuGard_Mailer_Top:"大支票郵寄",
  DocuGard_Mailer_Middle:"大型支票郵寄袋（中）",
  DocuGard_Mailer_Bottom:"大型支票郵寄箱（底部）",
  DocuGard_Three_Cheques:"三張大支票",
  DocuGard_Cheque_Top:"單張大額支票",
  DocuGard_Cheque_Middle:"大格子（中）",
  DocuGard_Cheque_Bottom:"大格子（底部）",
  DocuGard_Three_Cheques_Window:"一頁上三張支票。",
  DocuGard_Table_Top_Window:"檢查和收入表。",
  DocuGard_Table_Middle_Window:"檢查和收入表。",
  DocuGard_Table_Bottom_Window:"檢查和收入表。",
  DocuGard_Mailer_Top_Window:"支票、表格和地址。",
  DocuGard_Mailer_Middle_Window:"支票、表格和地址。",
  DocuGard_Mailer_Bottom_Window:"支票、表格和地址。",
  DocuGard_Cheque_Top_Window:"檢查安全紙。",
  DocuGard_Cheque_Middle_Window:"檢查安全紙。",
  DocuGard_Cheque_Bottom_Window:"檢查安全紙。",
  Basic_Cheque:"單支票",
  Basic_Cheque_Print:"打印一張支票。",
  Error_Setting_As_Paid:"錯誤設置為付費",
  Add_Attachment:"添加附件",
  PrinterUnavailable:"打印機不可用",
  CreditCardComponent:"牌",
  PaypalComponent:"貝寶",
  InteracComponent:"互動",
  BitcoinComponent:"比特幣",
  New_Deposit:"新存款",
  Deposits:"存款",
  No_Deposits:"沒有存款",
  Credit_Card_Deposit:"信用卡",
  New_Credit_Card_Deposit_Message:"信用卡存款",
  New_BitCoin_Deposit:"比特幣",
  New_BitCoin_Deposit_Message:"比特幣存款",
  New_Interac_Deposit:"互動",
  New_Interac_Deposit_Message:"交互傳輸",
  Payments_Limit:"支付限額",
  No_Payment_Limit:"無支付限額",
  PayPal_Deposit:"貝寶",
  PayPal_Deposit_Message:"貝寶存款",
  No_Deposits_Word:"添加第一家公司<a routerLink='/folder/Deposit/New'>訂金</a>。",
  No_Documents_Specified:"沒有指定要打印的文檔",
  No_Printers_Added:"未找到打印機。轉到“設置”>“打印機”以添加一台。",
  DB_Erase_Prompt:"徹底擦除整個數據庫？警告：您將丟失所有存儲的信息！",
  Console_Warning:"不要將任何文本粘貼到此控制台中。您可能會使自己和/或您的公司面臨嚴重風險。",
  No_Faxes_Word:"創建第一個 <a routerLink='/folder/Fax/New'>傳真</a>。",
  Cheque_Delete_Confirmation:"您確定要刪除這張支票嗎？",
  Design_Delete_Confirmation:"您確定要刪除此設計嗎？",
  Cheque_Pay_Confirmation:"將這張支票標記為已付？它不會出現在打印隊列中。",
  Payment_Pay_Confirmation:"將此付款標記為已付款？它不會出現在檢查隊列中。",
  Delete_Deduction_Confirmation:"您確定要刪除此扣除嗎？",
  Delete_Job_Confirmation:"您確定要刪除此作業嗎？",
  Delete_Timesheet_Confirmation:"您確定要刪除此時間表嗎？",
  Delete_Schedule_Confirmation:"您確定要刪除此計劃嗎？",
  Delete_Setting_Confirmation:"您確定要重置此設置嗎？",
  Delete_Fax_Confirmation:"您確定要刪除此傳真嗎？",
  Delete_File_Confirmation:"您確定要刪除此文件嗎？",
  Delete_Vacation_Confirmation:"您確定要刪除這個假期嗎？",
  Delete_Printer_Confirmation:"您確定要刪除這台打印機嗎？",
  Remove_Design_Confirmation:"您確定要刪除此設計嗎？",
  Delete_Payroll_Confirmation:"您確定要刪除此工資單嗎？",
  Send_Fax_Email_Confirmation:"您要傳真和通過電子郵件發送這些文件嗎？",
  Send_Email_Confirmation:"您要通過電子郵件發送此文檔嗎？",
  Send_Fax_Confirmation:"您要傳真這份文件嗎？",
  Error_Generating_PDF:"對不起。生成此文檔時出錯。",
  PDF_Error_Saving_Image:"對不起。保存此文檔的 PDF 圖像時出錯。",
  Test_Printer_Confirmation:"您想在此打印機上打印測試頁嗎？",
  Save_Timesheet_Prompt:"請添加“標題”或按“開始計時器”進行保存。",
  Remove_Geofence_Prompt:"您確定要刪除此地理圍欄的位置嗎？",
  Delete_Employee_Confirmation:"你確定你要刪除",
  Fire_Employee_Confirmation:"你確定要開火嗎"
}