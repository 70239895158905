export const Hi = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"कॉपीराइट और कॉपी; 2023",
  black:"काला",
  green:"हरा",
  gold:"सोना",
  blue:"नीला",
  brown:"भूरा",
  purple:"बैंगनी",
  pink:"गुलाबी",
  red:"लाल",
  Swatches:"नमूनों",
  HSL:"एचएसएल",
  RGB:"आरजीबी",
  Hue:"रंग",
  Saturation:"परिपूर्णता",
  Lightness:"लपट",
  Upgrade_To_Pro:"प्रो में अपग्रेड",
  AllFeaturesInclude:"सभी सुविधाओं में शामिल हैं:",
  PrintUnlimitedCheques:"असीमित चेक प्रिंट करें",
  PremiumChequeDesigns:"प्रीमियम चेक डिजाइन",
  ManageUnlimitedEmployees:"असीमित कर्मचारियों का प्रबंधन करें",
  AddUnlimitedPayees:"असीमित भुगतानकर्ता जोड़ें",
  CreateUnlimitedPayrolls:"असीमित पेरोल बनाएँ",
  UnlimitedSchedulesandTimeSheets:"असीमित अनुसूचियां और समय पत्रक",
  BulkPayPalPayouts:"थोक पेपैल भुगतान",
  UnlimitedBankAccounts:"असीमित बैंक खाते",
  ManageMultipleCompanies:"कई कंपनियों का प्रबंधन करें",
  TrackInsurancePolicies:"ट्रैक बीमा नीतियां",
  Bio_MetricProtection:"बायो-मीट्रिक सुरक्षा",
  Geo_FenceLock_OutProtection:"जियो-फेंस लॉक-आउट प्रोटेक्शन",
  Multiple_Companies_Word:"चेक प्रीमियम के बिना कई कंपनियों का प्रबंधन उपलब्ध नहीं है।",
  PayPal_Payouts_Word:"चेक प्रीमियम के बिना पेपाल भुगतान अक्षम हैं।",
  PINProtection:"पिन सुरक्षा",
  PasswordProtection:"पारणशब्द सुरक्षा",
  May_Require_Approval:"अनुमोदन की आवश्यकता हो सकती है।",
  Subscribe:"सदस्यता लें",
  Billed:"बिल",
  Up:"ऊपर",
  Down:"नीचे",
  Positioning:"पोजिशनिंग",
  Marker:"निशान",
  Drag_Marker:"ड्रैग मार्कर",
  Tagline:"प्रिंट चेक और पेरोल सारणीबद्ध करें",
  Marker_Word:"तत्व को आकार देने के लिए मार्कर का उपयोग करें।",
  Pinch_Zoom:"पिंच ज़ूम",
  Pinch_Word:"तत्व को ज़ूम करने के लिए पिंच करें।",
  Drag:"खींचना",
  Drag_Word:"तत्वों को खींचने के लिए अपनी उंगली का प्रयोग करें।",
  subscription_alias_word:"ऑटो-नवीनीकरण सदस्यता",
  premium_alias_word:"वन-टाइम अपग्रेड पैकेज",
  print_alias_word:"प्रिंट-आउट व्यक्तिगत चेक",
  mode_alias_word:"तरीका",
  Pro:"समर्थक",
  Pro_word:"प्रो संस्करण की आवश्यकता है।",
  Cant_Delete_Default_Company_Word:"क्षमा करें, आप अपनी डिफ़ॉल्ट कंपनी को हटा नहीं सकते .",
  Reinsert_Default_Designs:"डिफ़ॉल्ट डिजाइनों को फिर से डालें",
  Reinsert_Default_Designs_word:"क्या आप डिफ़ॉल्ट डिजाइनों को फिर से सम्मिलित करना चाहते हैं?",
  Subscription_Active_Disable_Word:"यह सदस्यता सक्रिय है। क्या आप चेक की इस सदस्यता को रद्द करना चाहेंगे?",
  Company_Logo:"कंपनी का लोगो",
  ZERO_:"शून्य",
  Disclaimer:"अस्वीकरण",
  Privacy_Policy:"गोपनीयता नीति",
  EULA:"ईयूएलए की जांच करता है",
  Terms_Of_Service:"सेवा की शर्तें",
  Terms_Of_Use:"उपयोग की शर्तें",
  Refunds:"भुगतान वापसी की नीति",
  Single_Print:"1 चेक करें",
  Two_Prints:"2 चेक",
  Five_Prints:"5 चेक",
  Ten_Prints:"10 चेक",
  Fifteen_Prints:"15 चेक",
  Twenty_Prints:"20 चेक",
  Thirty_Prints:"30 चेक",
  Image_Added:"छवि जोड़ी गई",
  Image_Preview:"छवि पूर्वावलोकन",
  No_Image_Was_Selected:"कोई छवि नहीं चुनी गई थी।",
  Cheques_Unlimited:"असीमित चेक करता है",
  _Subscription:"अंशदान",
  Subscription:"चेक - 1 महीना",
  Two_Month_Subscription:"चेक - 2 महीने",
  Three_Month_Subscription:"चेक - 3 महीने",
  Six_Month_Subscription:"चेक - 6 महीने",
  Twelve_Month_Subscription:"चेक - 12 महीने",
  Cheques_Are_Available:"चेक प्रिंट करने के लिए उपलब्ध हैं।",
  Upgrade_Required_Two:"अपनी खरीदारी शुरू करने के लिए एक पैकेज चुनें और मूल्य बटन पर डबल-टैप करें। सेकंड में पेशेवर दिखने वाले पूर्ण-रंगीन चेक प्रिंट करें।",
  Month:"महीना",
  Due:"देय:",
  Expires:"समाप्त:",
  Subscription_Active:"सदस्यता सक्रिय",
  Subscription_Inactive:"सदस्यता निष्क्रिय",
  Purchase_Additional_Cheques:"अतिरिक्त चेक खरीदें?",
  Printable_Cheque:"प्रिंट करने योग्य चेक",
  Printable_Cheques:"प्रिंट करने योग्य चेक",
  Printable_Cheque_Word:"चेक आपके खाते में उपलब्ध है।",
  Printable_Cheques_Word:"चेक आपके खाते में उपलब्ध हैं।",
  Max_Amount_Message:"आपके द्वारा निर्दिष्ट राशि इस सिस्टम के लिए निर्धारित अधिकतम राशि से अधिक हो गई है:",
  Terms_Required_Word:"चेकों का उपयोग जारी रखने से पहले आपको इस समझौते से सहमत होना होगा।",
  Subscriptions:"सदस्यता",
  Product_Upgrades:"उन्नयन",
  Mailed_Out_Cheques:"मेल-आउट चेक",
  Enter_A_Company_Name:"कृपया एक कंपनी का नाम दर्ज करें।",
  Single_Cheques:"एकल चेक",
  Cheque_Golden:"गोल्डन चेक",
  Cheque_Golden_Window:"गोल्डन चेक डिजाइन।",
  Cheque_Green:"हरा चेक",
  Cheque_Green_Window:"हरा चेक डिजाइन।",
  Cheque_Red:"लाल चेक",
  Cheque_Red_Window:"लाल चेक डिजाइन।",
  Cheque_Yellow:"पीला चेक",
  Cheque_Yellow_Window:"पीला चेक डिजाइन।",
  Cheque_Statue_of_Liberty:"स्टेचू ऑफ़ लिबर्टी",
  Cheque_Statue_of_Liberty_Window:"स्टैच्यू ऑफ़ लिबर्टी चेक डिज़ाइन।",
  Cheque_Green_Wave:"हरी लहर",
  Cheque_Green_Wave_Window:"हरा चेक डिजाइन।",
  Cheque_Golden_Weave:"सुनहरी बुनाई",
  Cheque_Golden_Weave_Window:"सुरुचिपूर्ण गोल्डन चेक डिजाइन।",
  Cheque_Green_Sun:"हरा सूरज",
  Cheque_Green_Sun_Window:"गहरी और शांत चेक डिजाइन।",
  Cheque_Blue_Checkers:"ब्लू चेकर्स",
  Cheque_Blue_Checkers_Window:"ब्लू चेक डिजाइन।",
  Cashiers_Check:"खजांची का चेक",
  Cashiers_Check_Window:"कैशियर चेक स्टाइल टेम्पलेट।",
  Cheque_Aqua_Checkers:"एक्वा चेकर्स",
  Cheque_Aqua_Checkers_Window:"एक्वा चेक डिजाइन।",
  Cheque_Golden_Checkers:"गोल्डन चेकर्स",
  Cheque_Golden_Checkers_Window:"गोल्डन चेक डिजाइन।",
  Upgrade_Unavailable:"उन्नयन अनुपलब्ध",
  Bank_Code_Protection:"बैंक नंबर सुरक्षा",
  Bank_Code_Protection_Word:"अपने बैंक नंबरों को किसी अन्य डिवाइस पर चल रहे इस ऐप पर या किसी अन्य उपयोगकर्ता के लिए इस्तेमाल होने से बचाएं। यह क्रिया अपरिवर्तनीय है। जारी रखना?",
  Bank_Code_Protection_Blocked_Word:"आप जिन बैंक नंबरों का उपयोग करने का प्रयास कर रहे हैं, वे किसी अन्य उपयोगकर्ता या डिवाइस के लिए आरक्षित हैं।",
  Bank_Code_Protection_Error_Word:"संख्या सत्यापन विफल हो गया है। कृपया इंटरनेट से कनेक्ट करें और इस बैंक खाते को दोबारा जोड़ने का प्रयास करें।",
  Bank_Code_Protection_Set_Error_Word:"बैंक नंबर सुरक्षा के लिए जरूरी है कि आप इंटरनेट से जुड़े हों। कृपया कनेक्ट करें और पुनः प्रयास करें।",
  Upgrade_Unavailable_Word:"क्षमा करें, हमें आपका सत्यापन करने में समस्या हो रही है। चेक के लिए सदस्यता और उन्नयन वर्तमान में आपके क्षेत्र में खरीद के लिए उपलब्ध नहीं हैं।",
  PayPal_Payment_Preview:"पेपैल भुगतान पूर्वावलोकन",
  Apple_Pay:"मोटी वेतन",
  Select_PayPal:"पेपैल का चयन करें",
  PayPal_Applications:"पेपैल अनुप्रयोग",
  Purchase_With_Apple_Pay:"ऐप्पल पे से खरीदारी करें",
  Google_Pay:"गूगल पे",
  Companies:"कंपनियों",
  Insurance:"बीमा",
  New_PayPal:"नया पेपैल",
  Pay_By:"द्वारा भुगतान",
  Insure:"ठीक कर लेना",
  Miles:"मील",
  Payment_Method:"भुगतान विधि",
  Select_Policies:"नीतियों का चयन करें",
  Policies:"नीतियों",
  Policy:"नीति",
  No_PayPal_Account:"कोई पेपैल खाता नहीं",
  No_Policies:"कोई बीमा पॉलिसी नहीं",
  New_Policy:"नई नीति",
  PayPal_Payment:"पेपैल भुगतान",
  PayPal_Payments:"पेपैल भुगतान",
  No_Payment_Selected:"कोई भुगतान नहीं चुना गया",
  Sending_Payment_Word:"कृपया प्रतीक्षा करें... यह भुगतान भेजा जा रहा है।",
  Sending_Payments_Word:"कृपया प्रतीक्षा करें... भुगतान भेजे जा रहे हैं।",
  No_Payment_Selected_PayPal:"कोई <a RouterLink='/folder/Payments'>PayPal भुगतान</a> नहीं चुना गया है।",
  Payment_Sent:"भुगतान भेजा",
  PayPal_Payment_Sent:"यह भुगतान पेपैल के साथ भेजा गया है।",
  Copay:"कोपे",
  Dead:"मृत",
  Alive:"जीवित",
  Not_Dead:"मरा नहीं",
  Unclaimed:"लावारिस",
  Attempted:"का प्रयास किया गया",
  Deceased:"मृत",
  Claimed:"दावा किया",
  Unpaid:"अवैतनिक",
  Sending_Payment:"भुगतान भेजा जा रहा है",
  Sending_Payments:"भुगतान भेजा जा रहा है",
  Send_PayPal_Confirmation:"क्या आप इस लेन-देन को PayPal से भेजना चाहते हैं?",
  Send_PayPal_Confirmation_Word:"इस लेन-देन को भेजने के लिए हरा बटन दबाएं।",
  Save_Payment_As_Unpaid:"इस भुगतान को भुगतान न किए गए के रूप में सहेजें?",
  Payment_Pay_Confirmation_PayPal:"इस भुगतान को भुगतान के रूप में सहेजें?",
  No_Policies_Word:"पहली <a RouterLink='/folder/Postage/New'>बीमा पॉलिसी</a> अभी जोड़ें ।",
  Timesheet_Multiple_Delete_Confirmation:"क्या आप वाकई एकाधिक टाइमशीट हटाना चाहते हैं?",
  Select_Multiple_Timesheets_Prompt:"कोई टाइमशीट चयनित नहीं। कम से कम एक टाइमशीट चुनें।",
  Select_Multiple_Policies_Prompt:"कोई नीति नहीं चुनी गई. कम से कम एक बीमा पॉलिसी चुनें।",
  Policies_Multiple_Delete_Confirmation:"क्या आप सुनिश्चित हैं कि आप अनेक नीतियों को हटाना चाहते हैं?",
  Company:"कंपनी",
  Add_Company:"कंपनी जोड़ें",
  New_Company:"कंपनी जोड़ें",
  No_Companies:"कोई कंपनी नहीं",
  Enable_Company:"कंपनी को सक्षम करें",
  Select_Company:"कंपनी का चयन करें",
  The_Default_Company:"डिफ़ॉल्ट कंपनी लेबल।",
  Manage_Companies:"कंपनियों का प्रबंधन करें",
  No_Companies_Word:"कंपनी का उपयोग करेंगे ।<br /> <a RouterLink='/folder/Company/New'>पहली कंपनी</a> जोड़ें ।",
  Default_Company:"डिफ़ॉल्ट कंपनी",
  Cheques_Unlimited_Word:"चेक अनलिमिटेड आपको जितने चाहें उतने चेक प्रिंट करने की अनुमति देता है।",
  Cheques_Subscription_Word:"चेक सदस्यता आपको जितने चाहें उतने चेक प्रिंट करने की अनुमति देती है।",
  You_Own_This_Product:"आप इस उत्पाद के स्वामी हैं।",
  Your_Subscription_is_Active:"आपकी सदस्यता सक्रिय है।",
  Active_Products:"सक्रिय उत्पाद",
  Purchase_Confirmation:"खरीदना",
  Purchase_Cheques:"खरीद चेक",
  Restore_Purchase:"खरीदारी वापस लौटाएं",
  Erase_Purchase:"खरीदारी मिटाएं",
  Successfully_Purchased:"सफलतापूर्वक खरीदा गया",
  Enter_Your_Licence_Key:"कृपया इस उत्पाद को सक्रिय करने के लिए इस पृष्ठ पर अपनी लाइसेंस कुंजी दर्ज करें।",
  Licence_Key:"लाइसेंस कुंजी",
  Enter_Licence_Key:"लाइसेंस नंबर डाले",
  Purchased:"खरीदी",
  Enable_Feature:"सुविधा सक्षम करें",
  Cancel_Subscription:"सदस्यता रद्द",
  Subscription_Removed:"सदस्यता हटा दी गई",
  Select_Active_Subscription:"इसे संशोधित करने के लिए एक सक्रिय सदस्यता का चयन करें।",
  Remove_Subscription_Word:"क्या आप वाकई इस सदस्यता को रद्द करना चाहते हैं?",
  Delete_Company_Confirmation:"क्या आप वाकई इस पूरी कंपनी को हटाना चाहते हैं? चेतावनी: आप सभी संग्रहीत जानकारी खो देंगे!",
  Delete_Default_Company_Word:"आप डिफ़ॉल्ट कंपनी को हटा नहीं सकते । क्या आप एप्लिकेशन को रीसेट करना चाहते हैं और इसे डिफ़ॉल्ट स्थिति में पुनर्स्थापित करना चाहते हैं? चेतावनी: आप सभी संग्रहीत जानकारी खो देंगे!",
  Console_Warning_2:"इस एप्लिकेशन का उपयोग करके किसी भी मुद्रा को न संभालें जो वर्तमान में आपकी नहीं है।",
  Terms_and_Conditions:"नियम और शर्तें",
  and_the:"और यह",
  for:"के लिए",
  Please_Read_Word:"कृपया पढ़ें और इससे सहमत हों",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"कुछ मुद्रा रूपांतरण दरें नहीं मिल सकीं। कृपया इंटरनेट से जुड़ें।",
  Free:"मुक्त",
  DB_Erase_Prompt_2:"संपूर्ण डेवलपर डेटाबेस को पूरी तरह से मिटा दें? चेतावनी: आप सभी खरीद और सदस्यता जानकारी खो देंगे!",
  Successfully_Imported:"सफलतापूर्वक आयात किया गया",
  Select_Postage:"डाक का चयन करें",
  No_Postage:"कोई डाक टिकट नहीं",
  No_Paid_Postage_Word:"पहला <a RouterLink='/folder/Postage/New'>सशुल्क डाक टिकट</a> जोड़ें ।",
  Trial_Complete:'ट्रायल पूरा',
  Please_Upgrade:'प्रिंटिंग जारी रखने के लिए कृपया चेक अपग्रेड करें।',
  Aa:"आ",
  Color:"रंग",
  Font:"फ़ॉन्ट",
  Guide:"मार्गदर्शक",
  Guides:"गाइड",
  Image:"छवि",
  Zoom:"ज़ूम",
  Logo:"प्रतीक चिन्ह",
  Bank:"बैंक",
  MICR:"माइक्रो",
  Total:"कुल",
  Cancel:"रद्द करना",
  Confirm:"पुष्टि करें",
  Method:"तरीका",
  Biometric_Security:"बायो-मीट्रिक सुरक्षा",
  Please_Login_To_Continue:"जारी रखने के लिए लॉग इन करें।",
  Complete:"पूरा",
  Sign_Up:"साइन अप करें",
  Error:"गलती",
  Biometrics:"बायो-मीट्रिक",
  Percent:"प्रतिशत",
  Zero_Percent:"0%",
  Top_Margin:"ऊपरी किनारा",
  Bottom_Margin:"नीचे में मार्जिन",
  Left_Margin:"बायां मार्जिन",
  Right_Margin:"दायां मार्जिन",
  MICR_Margin:"एमआईसीआर मार्जिन",
  Table_Margin:"टेबल मार्जिन",
  Address_Margin:"पता मार्जिन",
  Percentage_:"प्रतिशत",
  Vacation_Title:"अवकाश शीर्षक",
  Use_PIN:"पिन का प्रयोग करें",
  Loading__:"लोड हो रहा है...",
  Design_Title:"डिजाइन शीर्षक",
  Authorize:"अधिकृत",
  Key:"चाभी",
  Public_Key:"सार्वजनिक कुंजी",
  Private_Key:"निजी चाबी",
  Authenticate:"प्रमाणित",
  Last_Payroll:"अंतिम पेरोल",
  Last_Calculation:"अंतिम गणना",
  Authorized:"अधिकार दिया गया",
  Geo_Authorized:"भू-स्थान: अधिकृत",
  Not_Authorized:"अधिकृत नहीं हैं",
  Authorization_Complete:"प्राधिकरण पूर्ण",
  Geolocation_Authorization:"भू-स्थान प्राधिकरण",
  Out_of_Bounds:"सीमा के बाहर",
  Cant_Delete_Default_Design:"एक डिफ़ॉल्ट डिज़ाइन को हटा नहीं सकता।",
  Unauthorized_Out_of_Bounds:"अनधिकृत: सीमा से बाहर",
  Biometric_Authorization:"बायो-मीट्रिक प्राधिकरण",
  Locating_Please_Wait:"पता लगाया जा रहा है, कृपया प्रतीक्षा करें...",
  Test_Biometrics:"टेस्ट बायोमेट्रिक्स",
  Cheque_Margins:"मार्जिन की जाँच करें",
  Percentage_Full_Error:"प्रतिशत फ़ील्ड को 100% प्रतिशत से अधिक सेट नहीं किया जा सकता है।",
  No_Payroll_Text:"एक <a RouterLink='/folder/कर्मचारी/नया'>कर्मचारी</a> या <a RouterLink='/folder/Payee/New'>प्राप्तकर्ता</a> और एक <a RouterLink='/folder/Schedule/New'>अनुसूची</a>।",
  Design_Saved:"डिज़ाइन सहेजा गया",
  Default_Design_Selected:"डिफ़ॉल्ट डिज़ाइन चयनित",
  Partial_Import:"आंशिक आयात",
  Partial_Export:"आंशिक निर्यात",
  Entire_Import:"संपूर्ण आयात",
  Entire_Export:"संपूर्ण निर्यात",
  Existing_Password:"कृपया अपना मौजूदा पासवर्ड दर्ज करें:",
  Existing_PIN:"कृपया अपना मौजूदा पिन कोड दर्ज करें:",
  Pin_Change_Header:"पिन पुष्टि",
  Pin_Change_SubHeader:"परिवर्तन की पुष्टि करने के लिए अपना पुराना पिन नंबर दर्ज करें।",
  Pass_Change_Header:"पासवर्ड पुष्टि",
  Pass_Change_SubHeader:"परिवर्तन की पुष्टि करने के लिए अपना पुराना पासवर्ड दर्ज करें।",
  PIN_Enter_Message:"पुष्टि करने के लिए अपना पिन दर्ज करें।",
  Password_Enter_Message:"पुष्टि करने के लिए अपना पासवर्ड दर्ज करें।",
  Pin_Updated_Success:"पिन सफलतापूर्वक अपडेट किया गया!",
  Pin_Updated_Fail:"पिन अपडेट नहीं किया जा सका.",
  Pass_Updated_Success:"पासवर्ड सफलतापूर्वक अपडेट किया गया।",
  Pass_Updated_Fail:"पासवर्ड अपडेट नहीं किया जा सका।",
  Preview_Payment:"पूर्वावलोकन भुगतान",
  Preview_Payroll:"पूर्वावलोकन पेरोल",
  No_Payments_Created:"कोई भुगतान सृजित नहीं पाया गया।",
  Payment_Preview:"भुगतान पूर्वावलोकन",
  Enable_Payee:"प्राप्तकर्ता सक्षम करें",
  Rendered:"प्रतिपादन किया",
  No_Email:"कोई ईमेल नहीं",
  Setup_Cheques:"सेटअप जांच",
  name:"नाम",
  address:"पता",
  address_2:"पता पंक्ति नं। 2",
  city:"",
  province:"प्रांत",
  postal_code:"डाक का / ज़िप कोड",
  country:"देश",
  username:"उपयोगकर्ता नाम",
  full_name:"पूरा नाम",
  birthday:"जन्मदिन",
  email:"ईमेल",
  phone:"फ़ोन",
  employees:"कर्मचारी",
  addresses:"पतों",
  payment_amount_limit:"भुगतान राशि सीमा",
  total_limit:"कुल सीमा",
  payees:"आदाता",
  description:"विवरण",
  address_line_one:"पता पंक्ति एक",
  address_line_two:"पता पंक्ति दो",
  image:"छवि",
  account_holder:"खाता धारक",
  cheque_starting_id:"चेक स्टार्टिंग आईडी",
  transit_number:"ट्रांजिट नंबर",
  institution_number:"संस्था संख्या",
  designation_number:"पदनाम संख्या",
  account_number:"खाता संख्या",
  currency:"मुद्रा",
  signature:"हस्ताक्षर",
  payment_payroll_limit:"भुगतान सीमा",
  total_limi:"कुल सीमा",
  date:"दिनांक",
  printed_memo:"मुद्रित मेमो",
  banks:"बैंकों",
  signature_image:"हस्ताक्षर छवि",
  address_name:"नाम पता",
  notes:"टिप्पणियाँ",
  design:"डिज़ाइन",
  title:"शीर्षक",
  frequency:"आवृत्ति",
  fax:"फैक्स",
  salaries:"वेतन",
  salary_ids:"वेतन आईडी",
  start_time:"समय शुरू",
  end_time:"अंत समय",
  paid:"भुगतान किया गया",
  overtime_percentage:"भुगतान प्रतिशत",
  overtime_amount:"भुगतान की गई निश्चित राशि",
  first_name:"पहला नाम",
  last_name:"उपनाम",
  moderation:"संयम",
  create:"सृजन करना",
  edit:"संपादन करना",
  destroy:"नष्ट करना",
  day_start_time:"दिन_शुरू_समय",
  day_end_time:"दिन_अंत_समय",
  fullname:"नाम",
  time:"समय",
  auto_send:"स्वत: भेजना",
  time_method:"समय विधि",
  schedules:"अनुसूचियों",
  indefinite_payroll_enabled:"अनिश्चित काल के लिए सक्षम करें",
  amount:"राशि",
  repeat:"दोहराना",
  always_enabled:"हमेशा सक्षम",
  start_date:"आरंभ करने की तिथि",
  end_date:"अंतिम तिथि",
  Cheque_Total:"कुल चेक करें",
  Total_Amount:"कुल राशि:",
  Amounts:"राशियाँ:",
  Images:"इमेजिस",
  Files:"फ़ाइलें",
  Previewing:"पूर्वावलोकन:",
  Insert:"डालना",
  Preview_Import:"पूर्वावलोकन आयात",
  Entry:"प्रवेश",
  Entries:"प्रविष्टियां",
  No_Entries:"कोई प्रविष्टि नहीं",
  Import_Type:"आयात प्रकार",
  Select_Details:"विवरण चुनें",
  Select_Payee:"प्राप्तकर्ता चुनें",
  Enable_Holidays:"छुट्टियाँ सक्षम करें",
  Disable_Holidays:"छुट्टियों को अक्षम करें",
  Wire_Transfer:"तार स्थानांतरण",
  New_Export:"नया निर्यात",
  Export_Data:"निर्यात जानकारी",
  Export_Data_Word:"निर्यात और डाउनलोड करने के लिए फ़ाइल का प्रकार चुनें।",
  Export_File:"निर्यात फ़ाइल",
  Mode:"तरीका",
  Times:"बार",
  Widgets:"विजेट",
  Slider:"स्लाइडर",
  Set_Details:"विवरण सेट करें",
  Select_Type:"प्रकार चुनें",
  Display_Slider:"स्लाइडर प्रदर्शित करें",
  Dashboard_Slider:"डैशबोर्ड स्लाइडर",
  Dashboard_Mode:"डैशबोर्ड मोड",
  Show_Intro:"परिचय दिखाएं",
  Show_Payrolls:"पेरोल दिखाएं",
  Show_Holidays:"छुट्टियाँ दिखाएँ",
  Show_Invoices:"चालान दिखाएं",
  Show_Cheques:"चेक दिखाएं",
  Enabled_Widgets:"सक्षम विजेट",
  Disabled_Widgets:"अक्षम विजेट",
  Colors:"रंग की",
  Barcodes:"बारकोड",
  View_Timers:"टाइमर देखें",
  Gradients:"ढ़ाल",
  No_Info:"कोई सूचना नहीं",
  Disable:"बंद करना",
  Show_Layer:"परतें दिखाएं",
  Hide_Layer:"परतें छुपाएं",
  Text_Layer:"पाठ परतें",
  Secondly:"दूसरे",
  Minutely:"प्रतिमिनट",
  nine_am:"सुबह के 9 बजे",
  five_pm:"5:00 सायं",
  Enable_Address:"पता सक्षम करें",
  Invalid_File_Type:"क्षमा करें, एक अमान्य फ़ाइल प्रकार चुना गया था। समर्थित फ़ाइल प्रकार:",
  Error_Updating_Entry:"क्षमा करें, इस प्रविष्टि को अपडेट करने में त्रुटि हुई थी।",
  Schedule_Timing_Alert:"त्रुटि: शेड्यूल प्रारंभ समय समाप्ति समय के बाद मान पर सेट है।",
  Select_Multiple_Payments_Prompt:"कोई भुगतान नहीं चुना गया. कम से कम एक भुगतान चुनें।",
  Select_Multiple_Cheques_Prompt:"कोई चेक चयनित नहीं है। कृपया कम से कम एक चेक चुनें।",
  Select_Multiple_Items_Prompt:"कोई आइटम नहीं चुना गया. कृपया कम से कम एक आइटम चुनें.",
  Paymemt_Multiple_Delete_Confirmation:"क्या आप वाकई एक से अधिक भुगतान हटाना चाहते हैं?",
  Cheque_Multiple_Delete_Confirmation:"क्या आप वाकई एक से अधिक चेक हटाना चाहते हैं?",
  Payee_Multiple_Delete_Confirmation:"क्या आप वाकई एक से अधिक प्राप्तकर्ताओं को हटाना चाहते हैं?",
  Employee_Multiple_Delete_Confirmation:"क्या आप वाकई एक से अधिक कर्मचारियों को हटाना चाहते हैं?",
  MICR_Warning:"नोट: हो सकता है कुछ प्रिंटर और डिवाइस MICR फ़ॉन्ट को सही ढंग से प्रदर्शित न करें।",
  Automatically_Send:"स्वत: भेजना",
  Type:"टाइप",
  Payment_Type:"भुगतान के प्रकार",
  Auto_Send:"ऑटो भेजें",
  Automatically_Process:"स्वचालित रूप से प्रक्रिया",
  Auto_Process:"ऑटो प्रक्रिया",
  Image_Based:"छवि आधारित",
  Font_Based:"फ़ॉन्ट आधारित",
  Rerender:"फिर से प्रस्तुत करना",
  Rendering:"प्रतिपादन",
  Render:"फ़ाइलें",
  Top:"ऊपर",
  Middle:"मध्यम",
  Bottom:"नीचे",
  Top_Left:"बाएं से बाएं",
  Top_Center:"शीर्ष केंद्र",
  Top_Right:"दायां शीर्ष",
  Middle_Left:"मध्य वाम",
  Middle_Center:"मध्य केंद्र",
  Middle_Right:"मध्य अधिकार",
  Bottom_Left:"तली छोड़ें",
  Bottom_Center:"निचला बीच का",
  Bottom_Right:"नीचे दाएं",
  Numbers:"नंबर",
  Verified:"सत्यापित",
  Paper_Size:"काग़ज़ का आकार",
  New_Device:"नया यंत्र",
  Add_Device:"डिवाइस जोडे",
  Remove_Device:"यन्त्र को निकालो",
  Delete_Device:"डिवाइस हटाएं",
  Block_Device:"ब्लॉक डिवाइस",
  Block:"अवरोध पैदा करना",
  Unblock:"अनब्लॉक",
  Table:"मेज",
  Scan_Login_Code:"स्कैन लॉगिन कोड",
  Login_Code:"लॉगिन कोड",
  Scan_Code:"स्कैन कोड",
  Scan_QR_Code:"क्यू आर कोड स्कैन करें",
  Select_All:"सभी का चयन करे",
  Deselect_All:"सभी को अचिन्हिंत करें",
  Increase:"बढ़ोतरी",
  Decrease:"कमी",
  Bold:"साहसिक",
  Text:"मूलपाठ",
  Style:"शैली",
  Italic:"तिरछा",
  QR_Code:"क्यू आर संहिता",
  Barcode:"बारकोड",
  Browse_Images:"छवियां ब्राउज़ करें",
  Browse_Files:"फ़ाइलों को ब्राउज़ करें",
  Background_Image:"पृष्ठभूमि छवि",
  Logo_Image:"लोगो छवि",
  Header_Image:"प्रवेशिका प्रतिमा",
  Bank_Image:"बैंक छवि",
  Cut_Lines:"कट लाइन्स",
  Background:"पार्श्वभूमि",
  License:"लाइसेंस",
  One_License:"1 लाइसेंस:",
  Licensed:"के लिए लाइसेंस:",
  Not_Licensed:"लाइसेंस नहीं",
  Enter_Serial:"सीरियल दर्ज करें",
  Serial_Key:"क्रमिक कुंजी",
  Serial:"धारावाहिक",
  Product_Key:"उत्पाद कुंजी",
  Check_Product_Key:"उत्पाद कुंजी की जाँच करें",
  Add_Product_Key:"उत्पाद कुंजी जोड़ें",
  Verifying_Purchase:"खरीदारी सत्यापित की जा रही है...",
  Print_Envelope:"प्रिंट लिफाफा",
  Envelope:"लिफ़ाफ़ा",
  Thank_You:"धन्यवाद!",
  Scale:"पैमाना",
  Print_Scale:"प्रिंट स्केल",
  Borders:"सीमाओं",
  VOID:"शून्य",
  Void_Cheque:"निष्प्रभाव चेक",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"कुल भुगतान:",
  NO_DOLLARS:"कोई डॉलर नहीं ",
  ONE_DOLLAR:"एक डॉलर",
  DOLLARS:" डॉलर",
  AND:" तथा ",
  CENTS:" सेंट।",
  NO_:"ना ",
  ONE_:"एक ",
  AND_NO_:"और नहीं ",
  _AND_ONE_:"और एक ",
  DOLLARS_AND_ONE_CENT:" और एक सेंट।",
  AND_NO_CENTS:" और शून्य सेंट।",
  CHEQUE_PRINT_DATE:"दिनांक:",
  CHEQUE_PRINT_MEMO:"ज्ञापन:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"प्रारंभिक कार्य",
  Inital_Setup:"प्रारंभिक सेटअप",
  Welcome_To:"में स्वागत ",
  Welcome:"स्वागत",
  Swipe:"स्वाइप",
  Intro_Setup:"परिचय सेटअप",
  Introduction:"परिचय",
  CHEQUE_PRINT_CREDIT:"चेक द्वारा संचालित",
  Title_Intro_Word:"चेक में आपका स्वागत है",
  Title_Intro:"चेक परिचय",
  Title_Setup:"चेक सेटअप",
  PayPal_Default_Email_Message:"आपको एक नया पेपैल हस्तांतरण प्राप्त हुआ है। [चेक द्वारा संचालित]",
  Cheques_App_Export:"चेक द्वारा निर्यात किया गया",
  Post_Intro_Thanks:"चेक चुनने के लिए धन्यवाद। सेटअप प्रक्रिया अब पूरी हो गई है।",
  Post_Intro_Word:"अपना पहला चेक प्रिंट करके शुरू करें, भविष्य का भुगतान जोड़ें, या पेरोल में कर्मचारियों को जोड़ें।",
  Upgrade_Required:"इस संदेश को छिपाने और अतिरिक्त सुविधाओं को अनलॉक करने के लिए चेक के लिए आपको सॉफ़्टवेयर के अधिक प्रीमियम संस्करण की आवश्यकता होती है।",
  Upgrade_Title:"चेक",
  Mailout_Prompt:"आप अपने लिए अपने पेरोल चेक चेक मेल-आउट करने का विकल्प भी चुन सकते हैं।",
  Mailed_Cheque:"मेल की गई जांच:",
  Mailed_Cheque_Color:"मेल की गई जांच (रंग):",
  Terms_Purchase:"चेक के साथ सभी इलेक्ट्रॉनिक खरीदारी खरीद तिथि से 30 दिनों तक पूरी तरह से वापसी योग्य हैं। जारी रखने से पहले कृपया <a href='#'>नियम और शर्तें</a> पढ़ें और उनसे सहमत हों।",
  Dashboard_Setup:"प्राथमिक प्रिंटर सेटअप करें",
  Dashboard_Add:"प्राथमिक बैंक खाता जोड़ें",
  Dashboard_Customize:"एक चेक टेम्प्लेट चुनें",
  Dashboard_Job_Salary:"अपना काम बनाएं और अपना वेतन जोड़ें",
  Dashboard_Employees:"कर्मचारियों और भुगतानकर्ताओं को ट्रैक करें",
  Dashboard_Print:"अपनी तनख्वाह प्रिंट और मेल करें",
  Dashboard_Payroll:"अपना पेरोल प्रिंटिंग स्वचालित करें",
  Dashboard_PayPal:"पेपाल पेरोल / पेआउट सेटअप करें",
  Begin_Setup:"सेटअप शुरू करें",
  Get_Started:"शुरू हो जाओ",
  Purchase:"खरीदना",
  Lockdown:"लॉकडाउन",
  Unlock:"अनलॉक",
  Detailed:"विस्तृत",
  Log_In:"लॉग इन करें",
  Login:"लॉग इन करें",
  Launch:"प्रक्षेपण",
  Register:"रजिस्टर करें",
  Finish:"खत्म हो",
  List:"सूची",
  Weekends:"सप्ताहांत",
  Weekly:"साप्ताहिक",
  PayPal_Default_Subject:"नया भुगतान",
  Payment_Message:"भुगतान संदेश",
  PayPal_Default_Payment_Note:"धन्यवाद",
  Setup_Your_Cheqing_Account:"चेकिंग अकाउंट",
  Add_Your_Primary_Cheqing_Account:"अपना प्राथमिक चेकिंग खाता जोड़ें।",
  Welcome_Word:"पेरोल और मानव संसाधन प्रबंधन को सरल और स्वचालित करें।",
  Get_Started_Quickly:"बस कुछ सरल प्रश्नों के उत्तर दें जो हमें आरंभ करने में मदद करेंगे...",
  Done_Intro_Word:"सेटअप पूर्ण",
  PIN_Protected:"पासवर्ड और पिन संरक्षित",
  Enter_New_PIN:"नया पिन कोड दर्ज करें:",
  Enter_PIN:"पिन कोड दर्ज करें:",
  Invalid_PIN:"अमान्य पिन दर्ज किया गया।",
  Account_Identifier:"खाता पहचानकर्ता",
  New_Account_Identifier:"नया खाता पहचानकर्ता",
  attempt:"प्रयास",
  attempts:"प्रयास",
  remaining:"बचा हुआ",
  Language:"भाषा",
  languages:"भाषाएं",
  select_languages:"भाषा चुने",
  Deposit:"जमा",
  Hire_One_Now:"अभी एक किराए पर लें",
  App_Locked:"एप्लिकेशन लॉक है।",
  Skip_:"छोड़ें",
  Skip_to_Dashboard:"डैशबोर्ड पर जाएं",
  Dashboard:"डैशबोर्ड",
  Import:"आयात",
  Admin:"प्रशासक",
  New_Admin:"नया व्यवस्थापक",
  Settings:"समायोजन",
  Color_Picker:"रंग चयनकर्ता",
  Font_Picker:"फ़ॉन्ट पिकर",
  Logout:"लॉग आउट",
  Close:"बंद करे",
  Close_menu:"बंद करे",
  Excel:"एक्सेल फाइल",
  Csv:"सीएसवी फ़ाइल",
  Sql:"एसक्यूएल फ़ाइल",
  Json:"JSON फ़ाइल",
  Url:"यूआरएल द्वारा आयात करें",
  Back:"पीछे",
  Timers:"टाइमर",
  Cheque:"जाँच",
  Print:"प्रिंट",
  Designs:"डिजाइन",
  Pause_Printing:"मुद्रण रोकें",
  Resume_Printing:"मुद्रण फिर से शुरू करें",
  Printing_Paused:"प्रिंटिंग रुकी हुई",
  PrintingUnavailable:"क्षमा करें! इस सिस्टम पर प्रिंटिंग उपलब्ध नहीं है।",
  Prints_Paused:"प्रिंट रोका गया",
  Administration:"प्रशासन",
  Loading:"लोड हो रहा है",
  Unnamed:"अनाम",
  error:"क्षमा करें, यह चेक देखने के लिए नहीं खोला जा सका।",
  No_Cheques_To_Print:"प्रिंट करने के लिए कोई चेक नहीं",
  No_Cheques_To_Print_Word:"एक <a राउटरलिंक='/फ़ोल्डर/चेक/नया'>नया चेक</a> बनाएं।",
  New_Cheque:"नया चेक",
  Start_One_Now:"अभी एक शुरू करें",
  Edit_Cheque:"चेक संपादित करें",
  Select_Cheques:"चेक चुनें",
  Select_Employee:"कर्मचारी चुनें",
  Default_Printer:"डिफ़ॉल्ट प्रिंटर",
  Reassign:"पुन: असाइन करें",
  Configure:"कॉन्फ़िगर करें",
  Template:"टेम्पलेट",
  Designer:"डिजाइनर",
  Edit_Designs:"डिजाइन संपादित करें",
  New_Design:"नई डिजाइन",
  Next:"अगला",
  Save:"सहेजें",
  Name:"नाम",
  Mail:"मेल",
  Amount:"राशि",
  Date:"दिनांक",
  PayPal:"पेपैल",
  Payouts:"पेआउट",
  PayPal_Label:"पेपैल लेबल",
  Email_Username:"ईमेल / उपयोगकर्ता नाम",
  Client_ID:"ग्राहक ID",
  Sandbox_Email:"सैंडबॉक्स ईमेल",
  PayPal_Email:"पे पल ईमेल",
  PayPal_Username:"एपीआई उपयोगकर्ता नाम",
  PayPal_Payouts:"पेपैल भुगतान",
  Select_PayPal_Key:"पेपैल कुंजी चुनें",
  Secret:"गुप्त",
  API_Secret:"एपीआई सीक्रेट",
  PayPal_API_Keys:"पेपैल कीज़",
  New_PayPal_Key:"नई पेपैल कुंजी",
  Email_Subject:"ईमेल विषय",
  Email_Message:"सन्देश भेज देना",
  Payout_Options:"पेआउट विकल्प",
  Payment_Note:"भुगतान नोट",
  Enable_Employee:"कर्मचारी सक्षम करें",
  Enable_Production_Mode:"उत्पादन मोड सक्षम करें",
  Sandbox_Username:"सैंडबॉक्स उपयोगकर्ता नाम",
  Sandbox_Signature:"सैंडबॉक्स हस्ताक्षर",
  Sandbox_Password:"सैंडबॉक्स पासवर्ड",
  Production_Username:"उत्पादन उपयोगकर्ता नाम",
  Production_Signature:"उत्पादन हस्ताक्षर",
  Production_Password:"उत्पादन पासवर्ड",
  Production_Email:"उत्पादन ईमेल",
  API_Client_ID:"एपीआई क्लाइंट आईडी",
  API_Signature:"एपीआई हस्ताक्षर",
  API_Password:"एपीआई पासवर्ड",
  API_Username:"एपीआई उपयोगकर्ता नाम",
  Secret_Key:"गुप्त कुंजी",
  Sandbox:"सैंडबॉक्स",
  Production:"उत्पादन",
  Sandbox_Keys:"सैंडबॉक्स कुंजियाँ",
  Production_Keys:"उत्पादन कुंजी",
  API_Title:"एपीआई शीर्षक",
  Production_Mode:"उत्पादन मोड",
  Account_Label:"खाता लेबल",
  No_PayPal_Setup:"कोई पेपैल कुंजी नहीं",
  Enable_PayPal_Account:"पेपैल खाता सक्षम करें",
  No_PayPal_Word:"अपना <a RouterLink='/folder/Invoice/New'>पेपैल एपीआई कुंजी</a> जोड़ें।",
  Printed_Memo:"मुद्रित मेमो",
  Employee:"कर्मचारी",
  View_Employee:"कर्मचारी देखें",
  Mailing_Address:"डाक पता",
  Company_Address:"कम्पनी का पता",
  Select_Company_Address:"कंपनी का पता चुनें",
  Address:"पता",
  Any_Day:"किसी भी दिन",
  Address_Name:"नाम पता",
  Unit:"इकाई",
  Account:"कारण",
  Bank_Account:"बैंक खाता",
  Account_Limits:"खाता सीमा सक्षम करें",
  Payroll:"पेरोल",
  New_Payroll:"नया वेतन",
  No_Payroll:"कोई आगामी वेतन नहीं",
  Upcoming_Holiday:"आने वाली छुट्टी:",
  Invoice_Due:"चालान देय:",
  New_Invoice:"नया चालान",
  No_Invoices:"कोई चालान नहीं",
  No_Invoices_Word:"पहला <a राउटरलिंक='/फ़ोल्डर/चालान/नया'>चालान</a> बनाएं।",
  Cheque_Due:"चेक देय:",
  Payrolls:"पेरोल",
  Sandbox_Mode:"सैंडबॉक्स मोड",
  Hire:"किराया",
  Pay:"वेतन",
  New:"नया",
  Add:"जोड़ें",
  Make:"निर्माण",
  Time:"समय",
  Write:"लिखना",
  Holiday:"छुट्टी",
  Holidays:"छुट्टियाँ",
  Next_Holiday:"अगले छुट्टी:",
  All_Done:"सब कुछ कर दिया!",
  Employees:"कर्मचारियों",
  Payees:"भुगतानकर्ता",
  Job:"काम",
  Jobs:"नौकरियां",
  Invoice:"चालान",
  Invoices:"चालान",
  Vacations:"छुट्टियाँ",
  Cheques:"चेक",
  Brand_Cheques:"ब्रांड",
  Payment:"भुगतान",
  Enable_Payment:"भुगतान सक्षम करें",
  Payments:"भुगतान",
  Schedule:"अनुसूची",
  Schedules:"अनुसूची",
  Timesheet:"समय पत्रक",
  Timesheets:"टाइम शीट्स",
  Salary:"वेतन",
  New_Address:"नया पता",
  Address_2:"पता पंक्ति नं। 2)",
  _City:"शहर",
  _State:"राज्य/प्रोव",
  City:"शहर / टाउनशिप",
  State:"राज्य / प्रांत",
  Postal:"डाक का / ज़िप कोड",
  ZIP:"पोस्टल / ज़िप",
  Country:"देश",
  Addresses:"पते",
  Required_Options:"आवश्यक विकल्प",
  Optional_Options:"वैकल्पिक विकल्प",
  Additional_Options:"अतिरिक्त विकल्प",
  Required:"आवश्यक",
  Optional:"वैकल्पिक",
  Additional:"अतिरिक्त",
  No_Addresses:"कोई पता नहीं",
  New_Address_Word:"पहला <a राउटरलिंक='/फ़ोल्डर/पता/नया'>पता</a> जोड़ें।",
  Select_Address:"पता चुनें",
  No_Address:"कोई पता नहीं",
  Print_Cheque:"प्रिंट चेक",
  Print_Cheque_Now:"अभी प्रिंट करें",
  Description:"विवरण",
  Pay_To_The_Order_Of:"कुल भुगतान:",
  Select_Date_Range:"तिथि सीमा चुनें",
  Select_Starting_Date:"प्रारंभ तिथि चुनें",
  Select_Ending_Date:"समापन तिथि चुनें",
  Select_Date:"तारीख़ चुनें",
  Cheque_Date:"तारीख देखो",
  Cheque_Memo:"चेक मेमो",
  Blank_Cheque:"कोरा चेक",
  Blank:"रिक्त",
  No_Cheques:"कोई चेक नहीं",
  No_Cheques_Word:"अपना पहला <a राउटरलिंक='/फ़ोल्डर/चेक/नया'>चेक</a> प्रिंट करें।",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"छवि देखें",
  View:"राय",
  Modify:"संशोधित करें",
  Delete:"हटाएं",
  Cheque_Paid:"भुगतान किया गया",
  New_Deduction:"नई कटौती",
  Title:"शीर्षक",
  Frequency:"आवृत्ति",
  Hourly:"प्रति घंटा",
  Daily:"दैनिक",
  Weekdays:"सप्ताह के दिन",
  BiWeekly:"2 सप्ताह",
  TriWeekly:"3 सप्ताह",
  Monthly:"महीने के",
  MiMonthly:"2 महीने",
  Quarterly:"त्रैमासिक",
  Yearly:"वार्षिक",
  Every_Week:"हर हफ्ते",
  Every_Payroll:"हर पेरोल",
  Every_Month:"हर महीने",
  Annually:"सालाना",
  Always_Scheduled:"हमेशा अनुसूचित",
  Start_Date:"आरंभ करने की तिथि",
  End_Date:"समाप्ति तिथि",
  Start_Time:"समय शुरू",
  End_Time:"अंतिम समय",
  Deduction_Label:"कटौती लेबल",
  Notes:"टिप्पणियाँ",
  Options:"विकल्प",
  Enable:"सक्षम",
  Select_Deductions:"कटौती का चयन करें",
  Deductions:"कटौती",
  No_Deductions:"कोई कटौती नहीं",
  No_Deductions_Word:"अपना पहला <a RouterLink='/folder/Deduction/New'>कटौती</a> बनाएं।",
  New_Employee:"नए कर्मचारी",
  No_Title:"कोई शीर्षक नहीं",
  _Name:"नाम",
  About_Yourself:"अपने बारे में",
  Full_Name:"पूरा नाम",
  Birthday:"जन्मदिन",
  Email:"ईमेल",
  SMS:"एसएमएस",
  Phone:"फ़ोन",
  Test:"परीक्षण",
  Call:"बुलाना",
  Fax:"फैक्स",
  Printed_Note:"मुद्रित नोट",
  Position:"पद",
  Job_Position:"काम की स्थिति",
  Select_a_Job:"नौकरी चुनें",
  Select_a_Salary:"एक वेतन का चयन करें",
  Add_a_Deduction:"एक कटौती जोड़ें",
  Taxes:"कर",
  Add_Taxes:"कर जोड़ें",
  Date_of_Birth:"जन्म की तारीख",
  Email_Address:"मेल पता",
  Phone_Number:"फ़ोन नंबर",
  Phone_Call:"फोन कॉल",
  Enable_on_Payroll:"पेरोल पर सक्षम करें",
  Select_Employees:"कर्मचारियों का चयन करें",
  No_Employees:"कोई कर्मचारी नहीं",
  No_Employees_Word:"अपना पहला नया <a राउटरलिंक='/फ़ोल्डर/कर्मचारी/नया'>कर्मचारी</a> जोड़ें।",
  No_Name:"कोई नाम नहीं",
  Unemployeed:"बेरोजगार",
  Employeed:"कार्यरत",
  Paid:"भुगतान किया गया",
  Enabled:"सक्षम",
  Disabled:"अक्षम",
  Fire:"आग",
  Not_Available:"उपलब्ध नहीं है",
  Not_Available_Word:"अपना पहला <a राउटरलिंक='/फ़ोल्डर/चालान/नया'>चालान</a> प्रिंट करें और भुगतान पाएं।",
  Select_Invoices:"चुनें_चालान",
  Print_Invoice_Word:"अपना पहला <a राउटरलिंक='/फ़ोल्डर/चालान/नया'>चालान</a> प्रिंट करें और भुगतान पाएं।",
  Invoice_Title:"चालान शीर्षक",
  Invoice_Date:"चालान की तारीख",
  Due_Date:"नियत तारीख",
  New_Job:"नयी नौकरी",
  Details:"विवरण",
  Customize:"अनुकूलित करें",
  Customize_Dashboard:"डैशबोर्ड अनुकूलित करें",
  Components:"अवयव",
  No_Components:"कोई घटक नहीं",
  Main_Components:"प्रमुख तत्व",
  Smaller_Components:"छोटे घटक",
  Error_Loading_Page:"इस पृष्ठ को लोड करने में त्रुटि।",
  Bank_Details:"बैंक विवरण",
  About_Your_Job:"आपकी नौकरी के बारे में",
  Your_Schedule:"आपका समय - सारणी",
  Job_Title:"नौकरी का नाम",
  Job_Description:"नौकरी का विवरण",
  Job_Details:"नौकरी विवरण",
  Enable_Job:"नौकरी सक्षम करें",
  Pay_Period:"भुगतान की अवधि",
  Perpetually_Schedule:"निरंतर अनुसूची",
  Select_Jobs:"नौकरियों का चयन करें",
  No_Jobs:"नौकरी नहीं",
  Add_Jobs:"नौकरियां जोड़ें",
  No_Jobs_Word:"सूची में पहला <a RouterLink='/folder/Job/New'>काम</a> जोड़ें।",
  Count_Employees:"job.employee_count+'कर्मचारी'",
  Zero_Employees:"0 कर्मचारी",
  New_Leave:"नई छुट्टी",
  Leave_Name:"नाम छोड़ो",
  Paid_Leave:"वैतनिक अवकाश",
  Leave_Pay:"छुट्टी वेतन",
  Indefinite_Leave:"अनिश्चित छुट्टी",
  Indefinite_Payroll:"अनिश्चित वेतन",
  Leave:"छोड़ना",
  Add_Schedules:"अनुसूची जोड़ें",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"अनुपस्थिति सक्षम करें",
  Select_Leaves:"पत्तियों का चयन करें",
  No_Leaves:"अनुपस्थिति की कोई पत्तियां नहीं",
  Leave_Of_Absence:"छुट्टी",
  Leaves_Of_Absence:"अनुपस्थिति के पत्ते",
  New_Leave_of_Absense:"अनुपस्थिति की नई छुट्टी",
  No_Leaves_Word:"पहला <a राउटरलिंक='/फ़ोल्डर/छोड़ें/नया'>अनुपस्थिति छोड़ें</a> जोड़ें।",
  Not_Enabled:"सक्षम नहीं",
  Absences:"अनुपस्थिति",
  Payee:"भुगतानकर्ता",
  New_Payee:"नया प्राप्तकर्ता",
  Payee_Identifier:"प्राप्तकर्ता पहचानकर्ता",
  Payee_Name:"प्राप्तकर्ता का नाम",
  Payee_Title:"प्राप्तकर्ता शीर्षक",
  Payment_Memo:"भुगतान ज्ञापन",
  Select_Payees:"भुगतानकर्ताओं का चयन करें",
  No_Payees:"कोई भुगतान नहीं",
  Add_Payee_Note:"पहले <a RouterLink='/folder/Payee/New'>आदाता</a> जोड़ें।",
  New_Payees:"नए भुगतानकर्ता",
  About_The_Payment_Schedule:"भुगतान अनुसूची",
  Your_Payroll_Schedule:"स्वचालित पेरोल",
  New_Payment:"नया भुगतान",
  Identifier:"पहचानकर्ता",
  Select:"चुनते हैं",
  Memo:"मेमो",
  Payment_Date:"भुगतान तिथि",
  Mark_as_Paid:"भुगतान के रूप में चिह्नित करें",
  Select_Payments:"भुगतान चुनें",
  No_Payments:"कोई भुगतान नहीं",
  No_Payments_Word:"पहला <a RouterLink='/folder/Payment/New'>भुगतान</a> बनाएं।",
  Create_Payroll:"पेरोल बनाएं",
  Properties:"गुण",
  Payroll_Title:"पेरोल शीर्षक",
  Payroll_Notes:"पेरोल नोट्स",
  Payroll_Setup:"पेरोल सेटअप",
  Tabulate_Payments:"सारणी भुगतान",
  Tabulate:"सारणीबद्ध",
  By:"द्वारा:",
  Payments_By:"द्वारा भुगतान",
  Timesheets_And_Schedules:"समय पत्रक और अनुसूचियां",
  Both:"दोनों",
  Items:"आइटम",
  Add_Payees:"भुगतानकर्ता जोड़ें",
  Add_Account:"खाता जोड़ो",
  Enable_Account:"खाता सक्षम करें",
  Enable_Payroll:"पेरोल सक्षम करें",
  Print_Payroll:"प्रिंट पेरोल",
  No_Payrolls:"कोई वेतन नहीं",
  No_Payroll_Word:"अपना पहला <a राउटरलिंक='/फ़ोल्डर/पेरोल/नया'>पेरोल</a> बनाएं।",
  View_more:"और देखो",
  Less:"कम",
  Add_Payroll:"पेरोल जोड़ें",
  Select_Payroll:"पेरोल का चयन करें",
  About_Your_Salary:"आपके वेतन के बारे में",
  Add_Salaries:"वेतन जोड़ें",
  Add_Salary:"वेतन जोड़ें",
  Salaries:"वेतन",
  No_Salaries:"कोई वेतन नहीं",
  No_Salaries_Word:"पहला <a राउटरलिंक='/फ़ोल्डर/वेतन/नया'>वेतन</a> जोड़ें।",
  Select_Salaries:"वेतन चुनें",
  New_Salary:"नया वेतन",
  Salary_Name:"वेतन पहचानकर्ता",
  Enable_Salary:"वेतन सक्षम करें",
  Salary_Amount:"वेतन राशि",
  New_Schedule:"नई सारणी",
  Schedule_Title:"अनुसूची शीर्षक",
  Add_Address:"पता जोड़ें",
  Repeat:"दोहराना",
  Design:"डिज़ाइन",
  Edit_Design:"डिजाइन संपादित करें",
  Edit_this_Design:"इस डिज़ाइन को संपादित करें",
  Repeat_Payment:"दोहराएँ भुगतान",
  Enable_Schedule:"अनुसूची सक्षम करें",
  Never:"कभी नहीँ",
  Select_Schedule:"अनुसूची का चयन करें",
  No_Schedules:"कोई शेड्यूल नहीं",
  No_Schedules_Word:"पहला <a राउटरलिंक='/फ़ोल्डर/शेड्यूल/नया'>शेड्यूल</a> बनाएं।",
  New_Administrator:"नया प्रशासक",
  Username:"उपयोगकर्ता नाम",
  First_Name:"पहला नाम",
  Last_Name:"अंतिम नाम",
  Add_an_Address:"एक पता जोड़ें",
  Payment_Limit:"प्रति-भुगतान सीमा",
  Total_Limit:"कुल सीमा",
  Select_Accounts:"खातों का चयन करें",
  No_Administrators:"कोई प्रशासक नहीं",
  No_Administrators_Word:"पहला <a राउटरलिंक='/फ़ोल्डर/व्यवस्थापक/नया'>व्यवस्थापक खाता</a> बनाएं।",
  New_Administrators_Word:"पहला <a राउटरलिंक='/फ़ोल्डर/व्यवस्थापक/नया'>व्यवस्थापक</a> जोड़ें",
  Cloud:"बादल",
  Backup:"बैकअप",
  Enable_iCloud:"आईक्लाउड सक्षम करें",
  Enable_Google_Drive:"Google डिस्क सक्षम करें",
  Enable_Microsoft_OneDrive:"माइक्रोसॉफ्ट वनड्राइव सक्षम करें",
  Automatically_Backup:"स्वचालित रूप से बैकअप",
  FTP_Settings:"एफ़टीपी सेटिंग्स",
  URL_File_Prompt:"कृपया आयात करने के लिए .xls / .xlsx / .json फ़ाइल के लिए स्थान निर्दिष्ट करें:",
  URL_SQL_Prompt:"कृपया आयात करने के लिए SQLite फ़ाइल का स्थान निर्दिष्ट करें:",
  FTP_Backup:"एफ़टीपी बैकअप",
  FTP_Import:"एफ़टीपी आयात",
  FTP:"एफ़टीपी",
  SFTP:"एसएफटीपी",
  Host:"मेज़बान",
  Port:"बंदरगाह",
  Path:"पथ",
  Data_Path:"डेटा पथ",
  Enable_FTP_Account:"एफ़टीपी खाता सक्षम करें",
  HostnameIP:"होस्टनाम",
  Password:"कुंजिका",
  Connection_Port:"कनेक्शन पोर्ट",
  Enable_MySQL_Database:"MySQL डेटाबेस सक्षम करें",
  Log:"लॉग",
  Reset:"रीसेट",
  Erase:"मिटाओ",
  Export:"निर्यात",
  Database:"डेटाबेस",
  Upload_CSV:"सीएसवी अपलोड करें",
  Download_CSV:"सीएसवी डाउनलोड करें",
  SQL_Database:"एसक्यूएल यूआरएल",
  MySQL_Database:"माई एसक्यूएल",
  MySQL_Backup:"MySQL बैकअप",
  Internal_Notes:"आंतरिक नोट्स",
  Root_Path:"रूट पथ",
  Select_Backup:"बैकअप चुनें",
  Add_New_Backup:"एक नया बैकअप जोड़ें",
  First_Backup:"पहला बैकअप सेटअप करें...",
  Backups:"बैकअप",
  Add_Backup:"बैकअप जोड़ें",
  No_Backups:"कोई बैकअप नहीं मिला है।",
  Select_Backup_Type:"आप जिस प्रकार का बैकअप सेटअप करना चाहते हैं उसका चयन करें...",
  Backup_Type:"बैकअप प्रकार",
  FTP_Drive:"एफ़टीपी ड्राइव",
  URL_Location:"यूआरएल",
  Apple_iCloud:"आईक्लाउड",
  Google_Drive:"गाड़ी चलाना",
  Microsoft_OneDrive:"गाड़ी चलाना",
  Import_Fields:"आयात फ़ील्ड",
  Import_Fields_Word:"इस अनुभाग का उपयोग <a RouterLink='/folder/Holiday/New'>आयात</a> डेटा चुनने के लिए करें।",
  Upload:"डालना",
  Download:"डाउनलोड",
  Download_JSON:"JSON डेटा के रूप में डाउनलोड करें",
  Download_SQL:"एसक्यूएल फ़ाइल के रूप में डाउनलोड करें",
  New_Bank:"नया बैंक",
  New_Account:"नया खाता",
  New_Bank_Account:"नया बैंक खाता",
  Upload_Image:"तस्वीर डालिये",
  Bank_Name:"बैंक का नाम",
  Bank_Address:"बैंक पता",
  Branch_Address:"शाखा पता",
  Address_on_Cheque:"पता",
  Cheque_Numbers:"नंबर जांचें",
  Cheque_Details:"विवरण जांचें",
  Bank_Logo:"बैंक लोगो",
  Cheque_ID:"चेक आईडी",
  Starting_Cheque_ID:"चेक आईडी शुरू करना",
  Transit_Number:"ट्रांजिट नंबर",
  Institution_Number:"संस्था संख्या",
  Designation_Number:"पदनाम संख्या",
  Account_Number:"खाता संख्या",
  Limits:"सीमा",
  Default_Limits:"डिफ़ॉल्ट सीमाएं",
  Over_Limit:"सीमा से अधिक",
  Under_Limit:"सीमा के तहत",
  Payroll_Limit:"पेरोल लिमिट",
  Enable_Bank_Account:"बैंक खाता सक्षम करें",
  Select_Account:"खाता चुनें",
  No_Bank_Account:"कोई बैंक खाता नहीं",
  No_Bank_Account_Word:"पहला <a राउटरलिंक='/फ़ोल्डर/खाता/नया'>बैंक खाता</a> जोड़ें।",
  Bank_Accounts:"बैंक खाते",
  No_Accounts:"कोई खाता नहीं",
  No_Accounts_Note:"पहला <a राउटरलिंक='/फ़ोल्डर/खाता/नया'>बैंक खाता</a> जोड़ें।",
  Cheque_Designer:"डिजाइनर की जाँच करें",
  Cheque_Design:"डिजाइन की जाँच करें",
  Select_Design:"एक डिज़ाइन चुनें",
  Cheque_Designs:"डिजाइनों की जांच करें",
  No_Cheque_Designs:"नो चेक डिज़ाइन",
  No_Cheque_Designs_Word:"अपना खुद का <a राउटरलिंक='/फ़ोल्डर/सेटिंग्स/चेक/डिज़ाइन/नया'>डिज़ाइन जांचें</a> बनाएं।",
  Set_Default:"डिफाल्ट के रूप में सेट",
  Default:"चूक",
  Remove:"हटाना",
  Folder:"फ़ोल्डर",
  Edit:"संपादित करें",
  LoadingDots:"लोड हो रहा है...",
  Add_a_New_File:"इसमें एक <a href='#' (क्लिक करें)='add()'>नई फ़ाइल</a> जोड़ें",
  this_folder:"यह फ़ोल्डर",
  FTP_Backup_Settings:"एफ़टीपी बैकअप सेटिंग्स",
  Secure_File_Transfer:"सुरक्षित फ़ाइल स्थानांतरण",
  New_Holiday:"नई छुट्टी",
  Add_Holiday:"छुट्टी जोड़ें",
  Holiday_Name:"अवकाश का नाम",
  Additional_Pay:"अतिरिक्त वेतन",
  Enable_Holiday:"छुट्टी सक्षम करें",
  Select_Holidays:"छुट्टियों का चयन करें",
  No_Holidays:"कोई छुट्टी नहीं",
  No_Holidays_Word:"पहला <a RouterLink='/folder/Holiday/New'>सार्वजनिक अवकाश</a> जोड़ें।",
  New_Import:"नया आयात",
  Import_Data:"आयात आंकड़ा",
  Import_Data_Word:"अपनी पसंद की फ़ाइल का प्रकार या अपलोड विधि चुनें।<br /> आप किसी समर्थित फ़ाइल को अपलोड करने के बाद ऐप में किसी भी पैरामीटर के अनुरूप फ़ाइल में आयात किए गए फ़ील्ड का चयन करने में सक्षम होंगे ।", 
  Import_File:"फ़ाइल आयात करें",
  Link_To_File:"फ़ाइल से लिंक करें",
  Select_File:"फ़ाइल का चयन करें",
  New_Moderator:"नया मॉडरेटर",
  Allow_Moderation:"मॉडरेशन की अनुमति दें",
  Create_Paycheques:"पेचेक बनाएं",
  Edit_Paycheques_and_Data:"पेचेक और डेटा संपादित करें",
  Destroy_Data_and_Paycheques:"डेटा और तनख्वाह को नष्ट करें",
  Bonus_Percentage:"पेचेक प्रतिशत",
  Fixed_Amount:"निर्धारित रकम",
  Select_Moderator:"मॉडरेटर चुनें",
  No_Moderators:"कोई मॉडरेटर नहीं",
  Moderators:"मॉडरेटर्स",
  Moderator_Account:"पहला <a राउटरलिंक='/फ़ोल्डर/व्यवस्थापक/नया'>मॉडरेटर खाता</a> बनाएं।",
  No_Moderators_Word:"पहला <a राउटरलिंक='/फ़ोल्डर/व्यवस्थापक/नया'>मॉडरेटर</a> जोड़ें।",
  Defaults:"डिफ़ॉल्ट",
  Provide_Us_Info:"हमें जानकारी प्रदान करें",
  Setup_Your_Printer:"अपना प्रिंटर सेटअप करें",
  Your_Company:"आपकी कंपनी के बारे में",
  Company_Name:"कंपनी का नाम",
  Currency:"मुद्रा",
  Default_Currency:"डिफ़ॉल्ट मुद्रा",
  Base_Monthly_Income:"मासिक आय",
  Protection:"सुरक्षा",
  App_Protection:"ऐप सुरक्षा",
  PIN_Code_Protection:"पिन कोड सुरक्षा",
  App_Protection_Word:"सुरक्षा विधियां सक्षम करें जो आपके खाते को सुरक्षित रखने में सहायता करती हैं।",
  PIN_Code:"पिन कोड",
  Change_PIN:"पिन बदलिए",
  New_Password:"नया पासवर्ड",
  Geofence_Protection:"जियो-फेंस प्रोटेक्शन",
  Geofence_Area:"सेट एरिया",
  Distance:"दूरी",
  Setup_Now:"अभी सेटअप करें",
  Mile:"मील",
  Km:"किमी",
  m:"एम",
  Facial_Recognition:"चेहरे की पहचान",
  Face:"चेहरा",
  Setup:"सेट अप",
  Label:"लेबल",
  Password_Protection:"पारणशब्द सुरक्षा",
  Modify_Password:"पासवर्ड संशोधित करें",
  New_Tax_Entry:"नई टैक्स एंट्री",
  New_Tax:"नया कर",
  Tax_Label:"टैक्स लेबल",
  Perpetually_Enabled:"हमेशा सक्षम",
  Select_Taxes:"कर चुनें",
  Tax_Deductions:"कर कटौती",
  No_Tax_Deductions:"कोई कर कटौती नहीं",
  No_Tax_Deductions_Word:"पहला <a राउटरलिंक='/फ़ोल्डर/कर/नया'>कर</a> कटौती जोड़ें।",
  New_Timer:"नया टाइमर",
  Start:"शुरू",
  Stop:"विराम",
  Start_Timer:"टाइमर प्रारंभ करें",
  Stop_Timer:"स्टॉप टाइमर",
  Timer_Active:"टाइमर सक्रिय",
  Timer:"टाइमर:",
  Timer_Running:"टाइमर: (चल रहा है)",
  Save_Timer:"टाइमर सहेजें",
  New_Timesheet:"नई टाइम शीट",
  Select_Timesheets:"समय पत्रक चुनें",
  Work_Notes:"कार्य नोट्स",
  Entry_Title:"प्रवेश शीर्षक",
  No_Timesheets:"नो टाइम शीट्स",
  No_Timesheets_Word:"पहला <a राउटरलिंक='/फ़ोल्डर/टाइमशीट/नया'>टाइम शीट</a> जोड़ें।",
  Timesheet_Submitted:"टाइम शीट सबमिट की गई",
  Timesheet_Congrats:"बधाई हो! आपका टाइम शीट सफलतापूर्वक सबमिट कर दिया गया है। धन्यवाद!",
  Timesheet_Copy:"अपने दस्तावेज़ों की एक प्रति प्राप्त करने के लिए कृपया हमें अपना ईमेल पता और/या मोबाइल फ़ोन नंबर प्रदान करें।",
  Submit:"प्रस्तुत करना",
  Allow_Notifications:"सूचनाओं की अनुमति दें",
  Untitled_Entry:"नविन प्रवेश",
  Untitled_Bank:"अनटाइटल्ड बैंक",
  Timesheet_Entry:"टाइम शीट एंट्री",
  Work_Description:"कार्य विवरण",
  Enable_Timesheet:"टाइम शीट सक्षम करें",
  Submit_Timesheet:"समय पत्रक जमा करें",
  Vacation:"छुट्टी",
  New_Vacation:"नई छुट्टी",
  Vacation_Name:"अवकाश का नाम",
  Paid_Vacation:"सवेतन अवकाश",
  Vacation_Pay:"छुट्टी का वेतन",
  Enable_Vacation:"अवकाश सक्षम करें",
  Select_Vacations:"छुट्टियों का चयन करें",
  No_Vacations:"कोई छुट्टी नहीं",
  No_Vacations_Word:"पहली <a RouterLink='/folder/Vacation/New'>छुट्टी</a> एंट्री बनाएं।",
  Payroll_Schedule:"पेरोल शेड्यूल",
  Next_Payroll:"अगला वेतन:",
  Upcoming_Payroll:"आगामी पेरोल",
  No_Upcoming_Payroll:"कोई आगामी वेतन नहीं",
  Address_Book:"पता पुस्तिका",
  Archived_Documents:"संग्रहीत दस्तावेज़",
  Dev_Mode:"विकास मोड में आवेदन",
  Administrators:"प्रशासक",
  Privacy:"गोपनीयता",
  None:"कोई नहीं",
  Select_Signature:"हस्ताक्षर का चयन करें",
  No_Signatures:"कोई हस्ताक्षर नहीं",
  No_Signatures_Word:"पहला <a राउटरलिंक='/फ़ोल्डर/हस्ताक्षर/नया'>हस्ताक्षर</a> जोड़ें।",
  Repeat_Indefinitely:"अनिश्चित काल के लिए दोहराएं",
  Sign:"संकेत",
  Sign_Here:"यहां हस्ताक्षर करें",
  Date_Signed:"तारीख पर हस्ताक्षर किए",
  Signature_Pad:"हस्ताक्षर पैड",
  Account_Holder:"खाता धारक",
  Account_Properties:"खाता गुण",
  Name_On_Cheque:"चेक पर नाम",
  Server_Hostname:"सर्वर होस्टनाम / आईपी",
  Printers:"प्रिंटर",
  No_Printers:"कोई प्रिंटर नहीं",
  Printer_Exists:'इस नाम का एक प्रिंटर पहले से मौजूद है।',
  No_Printers_Word:"सबसे पहले <a राउटरलिंक='/फ़ोल्डर/प्रिंटर/नया'>प्रिंटर</a> जोड़ें।",
  No_Printer_Alert:"कोई प्रिंटर परिभाषित नहीं है। क्या आप प्रिंटर सेट करना चाहेंगे?",
  Add_Printer:"प्रिंटर जोड़ें",
  New_Printer:"नया प्रिंटर",
  Printer_Hostname:"प्रिंटर होस्टनाम / आईपी",
  Printer_Label:"प्रिंटर लेबल",
  Printer_Protocol:"प्रिंटर प्रोटोकॉल",
  Protocol:"शिष्टाचार",
  Email_Print:"ईमेल",
  AirPrint:"एयरप्रिंट",
  IPP:"आईपीपी",
  LPD:"एलपीडी",
  HPJetDirect:"सॉकेट",
  Print_Job:"प्रिंट जॉब",
  Printed:"मुद्रित",
  Not_Printed:"मुद्रित नहीं",
  Print_Jobs:"प्रिंट जॉब्स",
  Print_Queue:"प्रिंट कतार",
  No_Print_Jobs:"नो प्रिंट जॉब्स",
  No_Prints:"प्रिंट करने के लिए एक नया <a राउटरलिंक='/फ़ोल्डर/चेक/नया'>चेक</a> बनाएं।",
  Prints:"प्रिंट",
  Find_Printer:"प्रिंटर ढूंढें",
  Find_AirPrint_Devices:"एयरप्रिंट डिवाइस ढूंढें",
  Select_Printer:"प्रिंटर चुनें",
  System_UI:"सिस्टम यूआई",
  Printer:"मुद्रक",
  Status:"स्थिति",
  Preview:"पूर्वावलोकन",
  Enable_Print_Job:"प्रिंट जॉब सक्षम करें",
  Queue_Weight:"कतार वजन",
  Unlimited:"असीमित",
  Show_Advanced_Printer_Options:"उन्नत प्रिंटर विकल्प दिखाएं",
  Advanced:"उन्नत",
  Location:"स्थान",
  Note:"ध्यान दें",
  Queue_Name:"कतार का नाम",
  Pages_Printed_Limit:"पृष्ठ मुद्रित सीमा",
  MultiPage_Idle_Time:"एकाधिक प्रतीक्षा समय (ओं)",
  Page_Count_Limit:"पृष्ठ गणना सीमा",
  Page_Orientation:"पेज ओरिएंटेशन",
  Portrait:"चित्र",
  Landscape:"परिदृश्य",
  Duplex:"डुप्लेक्स",
  Double_Sided_Printing:"दोहरा",
  Duplex_Mode:"डुप्लेक्स मोड",
  Duplex_Short:"छोटा",
  Duplex_Long:"लंबा",
  Duplex_None:"कोई नहीं",
  Color_And_Quality:"रंग और गुणवत्ता",
  Monochrome:"मोनोक्रोम",
  Photo_Quality_Prints:"फोटो गुणवत्ता प्रिंट",
  Printer_Email:"प्रिंटर ईमेल",
  Automatically_Downsize:"स्वचालित रूप से डाउनसाइज़ करें",
  Paper:"कागज़",
  Paper_Name:"कागज का नाम",
  Paper_Width:"कागज की चौड़ाई",
  Paper_Height:"कागज की ऊंचाई",
  Paper_Autocut_Length:"पेपर ऑटो-कट लंबाई",
  Margins:"मार्जिन",
  Page_Margins:"पेज मार्जिन",
  Page_Margin_Top:"शीर्ष पृष्ठ मार्जिन",
  Page_Margin_Right:"राइट पेज मार्जिन",
  Page_Margin_Bottom:"निचला पृष्ठ मार्जिन",
  Page_Margin_Left:"वाम पृष्ठ मार्जिन",
  Add_Employees:"कर्मचारियों को जोड़ें",
  Header:"हेडर",
  Print_A_Page_Header:"एक पेज हैडर प्रिंट करें",
  Header_Label:"हैडर लेबल",
  Header_Page_Index:"हैडर पेज इंडेक्स",
  Header_Font_Name:"हैडर फ़ॉन्ट",
  Select_Font:"फ़ॉन्ट चुनें",
  Font_Selector:"फ़ॉन्ट चयनकर्ता",
  Header_Font_Size:"हैडर फ़ॉन्ट",
  Header_Bold:"हेडर बोल्ड",
  Header_Italic:"हैडर इटैलिक",
  Header_Alignment:"हैडर संरेखण",
  Left:"बाएं",
  Center:"केंद्र",
  Right:"सही",
  Justified:"न्यायसंगत",
  Header_Font_Color:"हैडर रंग",
  Select_Color:"रंग चुनो",
  Footer:"पाद",
  Print_A_Page_Footer:"एक पृष्ठ पाद लेख प्रिंट करें",
  Footer_Label:"पाद लेबल",
  Footer_Page_Index:"पाद लेख पृष्ठ अनुक्रमणिका",
  Footer_Font_Name:"पाद फ़ॉन्ट",
  Fonts:"फोंट्स",
  Done:"किया हुआ",
  Select_Fonts:"फ़ॉन्ट चुनें",
  Footer_Font_Size:"पाद आकार",
  Footer_Bold:"फुटर बोल्ड",
  Footer_Italic:"पाद इटैलिक",
  Footer_Alignment:"पाद संरेखण",
  Footer_Font_Color:"पाद रंग",
  Page_Copies:"पृष्ठ प्रतियां",
  Enable_Printer:"प्रिंटर सक्षम करें",
  Remote_Logging:"रिमोट लॉगिंग",
  Log_Server:"लॉग सर्वर",
  Encryption:"कूटलेखन",
  Random_Key:"यादृच्छिक कुंजी",
  Encryption_Key:"कूटलेखन कुंजी",
  Cheques_Webserver:"कस्टम डेटाबेस",
  Learn_How:"कैसे सीखें",
  Signature:"हस्ताक्षर",
  Default_Printer_Unit:"में/सेमी/मिमी/(पीटी)",
  View_Signature:"हस्ताक्षर देखें",
  Printed_Signature:"मुद्रित हस्ताक्षर",
  Digitally_Sign:"डिजिटल साइन",
  Digital_Signature:"अंगुली का हस्ताक्षर",
  Add_Signature:"हस्ताक्षर जोड़ें",
  Add_Your_Signature:"अपना हस्ताक्षर जोड़ें",
  Enable_Signature:"हस्ताक्षर सक्षम करें",
  Digitally_Signed:"डिजिटल रूप से हस्ताक्षरित",
  Insert_Error:"डेटाबेस समस्याओं के कारण चेक सहेजने में असमर्थ।",
  Delete_Confirmation:"क्या आप वाकई इस जानकारी को हटाना चाहते हैं?",
  Discard_Confirmation:"क्या आप वाकई इस जानकारी को छोड़ना चाहते हैं?",
  Discard_Bank_Confirmation:"क्या आप वाकई इस खाते को छोड़ना चाहते हैं?",
  Discard_Printer_Confirmation:"क्या आप वाकई इस प्रिंटर को त्यागना चाहते हैं?",
  Delete_Bonus_Confirmation:"क्या आप वाकई इस बोनस को हटाना चाहते हैं?",
  Delete_Invoice_Confirmation:"क्या आप वाकई इस चालान को हटाना चाहते हैं?",
  Generated_Cheque:"जनरेटेड चेक",
  Generated_Invoice:"जनरेटेड चालान",
  Schedule_Start_Time:"शेड्यूल प्रारंभ",
  Schedule_End_Time:"शेड्यूल अंत",
  New_Call:"नई कॉल",
  No_Contacts:"कोई संपर्क नहीं",
  No_Contacts_Word:"व्यवस्थापक, मॉडरेटर, कर्मचारी, और प्राप्तकर्ता संपर्क के रूप में दिखाई देते हैं।",
  PDF_Subject:"वित्त",
  PDF_Keywords:"पेरोल पेचेक पीडीएफ चेक चेक",
  Printer_Setup:"प्रिंटर रुपरचना",
  Printer_Selection:"प्रिंटर चयन",
  New_Fax:"नया फैक्स",
  New_Fax_Message:"नया फैक्स संदेश",
  Fax_Machine:"फैक्स मशीन",
  Fax_Name:"फैक्स नाम",
  Fax_Email:"फैक्स ईमेल",
  Fax_Number:"फैक्स नंबर",
  Contents:"सामग्री",
  Fax_Body:"पेज बॉडी",
  Header_Word:"हैडर:",
  Header_Text:"शीर्ष लेख पाठ",
  Include_Header:"हेडर शामिल करें",
  Include_Footer:"पाद लेख शामिल करें",
  Footer_Word:"पाद लेख:",
  Footer_Text:"फूटर टेक्स्ट",
  Attach_a_Cheque:"एक चेक संलग्न करें",
  Add_Deduction:"कटौती जोड़ें",
  Enable_Fax:"फैक्स भेजें",
  Select_Fax:"फ़ैक्स का चयन करें",
  No_Faxes:"कोई फ़ैक्स नहीं",
  Faxes:"फैक्स",
  Save_and_Send:"फैक्स भेजें",
  Save_and_Pay:"सहेजें और भुगतान करें",
  WARNING:"चेतावनी:",
  Remember:"याद रखना",
  Printing:"मुद्रण",
  Printing_Complete:"मुद्रण पूर्ण!\n\n",
  of:"का",
  There_Were:"वहां थे ",
  Successful_Prints:"सफल प्रिंट और",
  Unsuccessful_Prints:"असफल प्रिंट।",
  PrinterError:"क्षमा करें, कोई त्रुटि हुई।",
  Printing_:"मुद्रण...",
  PrinterSuccess:"दस्तावेज़ सफलतापूर्वक मुद्रित।",
  PrintersSuccess:"दस्तावेज़ सफलतापूर्वक मुद्रित।",
  Print_Cheques:"प्रिंट चेक",
  New_Message:"नया संदेश",
  New_Messages:"नए संदेश",
  Read_Message:"संदेश पढ़ना",
  Write_Message:"संदेश लिखना",
  Send_Message:"मेसेज भेजें",
  Subject:"विषय",
  Message:"संदेश",
  Writing:"लिखना...",
  Send:"भेजना",
  Set_Date:"तारीख सेट करें",
  Set_Time:"निर्धारित समय",
  Recieve:"प्राप्त करें",
  Set_as_Default:"डिफाल्ट के रूप में सेट",
  Default_Account:"मूल खाता",
  Default_Design:"डिफ़ॉल्ट डिजाइन",
  Multiple_Cheques:"चेक (ट्रिपल)",
  Account_Mode:"खाता मोड",
  Multiple_Cheques_Description:"प्रति पृष्ठ तीन चेक।",
  Check_and_Table:"चेक एंड टेबल",
  Check_including_Table:"चेक एंड अकाउंटिंग टेबल।",
  Check_Mailer:"चेक मेलर",
  Check_Mailer_Window:"एक पता विंडो से जांचें।",
  DocuGard_Table_Top:"डॉक्यूगार्ड चेक एंड टेबल (टॉप)",
  DocuGard_Table_Middle:"डॉक्यूगार्ड चेक एंड टेबल (मध्य)",
  DocuGard_Table_Bottom:"डॉक्यूगार्ड चेक एंड टेबल (नीचे)",
  DocuGard_Mailer_Top:"डॉक्यूगार्ड चेक मेलर (शीर्ष)",
  DocuGard_Mailer_Middle:"डॉक्यूगार्ड चेक मेलर (मध्य)",
  DocuGard_Mailer_Bottom:"डॉक्यूगार्ड चेक मेलर (नीचे)",
  DocuGard_Three_Cheques:"डॉक्यूगार्ड चेक (ट्रिपल)",
  DocuGard_Cheque_Top:"डॉक्यूगार्ड चेक (शीर्ष)",
  DocuGard_Cheque_Middle:"डॉक्यूगार्ड चेक (मध्य)",
  DocuGard_Cheque_Bottom:"डॉक्यूगार्ड चेक (नीचे)",
  DocuGard_Three_Cheques_Window:"एक पेज पर तीन चेक।",
  DocuGard_Table_Top_Window:"चेक और कमाई तालिका।",
  DocuGard_Table_Middle_Window:"चेक और कमाई तालिका।",
  DocuGard_Table_Bottom_Window:"चेक और कमाई तालिका।",
  DocuGard_Mailer_Top_Window:"चेक, टेबल और पता।",
  DocuGard_Mailer_Middle_Window:"चेक, टेबल और पता।",
  DocuGard_Mailer_Bottom_Window:"चेक, टेबल और पता।",
  DocuGard_Cheque_Top_Window:"सुरक्षित कागज की जाँच करें।",
  DocuGard_Cheque_Middle_Window:"सुरक्षित कागज की जाँच करें।",
  DocuGard_Cheque_Bottom_Window:"सुरक्षित कागज की जाँच करें।",
  Basic_Cheque:"चेक (शीर्ष)",
  Basic_Cheque_Print:"एक ही चेक का प्रिंट आउट लें।",
  Error_Setting_As_Paid:"भुगतान के रूप में सेट करने में त्रुटि",
  Add_Attachment:"अय्टाचमेंट जोडे",
  PrinterUnavailable:"प्रिंटर अनुपलब्ध",
  CreditCardComponent:"पत्ते",
  PaypalComponent:"पेपैल",
  InteracComponent:"इंटरैक",
  BitcoinComponent:"बिटकॉइन",
  New_Deposit:"नई जमा",
  Deposits:"जमा",
  No_Deposits:"कोई जमा नहीं",
  Credit_Card_Deposit:"क्रेडिट कार्ड",
  New_Credit_Card_Deposit_Message:"क्रेडिट कार्ड जमा",
  New_BitCoin_Deposit:"बिटकॉइन",
  New_BitCoin_Deposit_Message:"बिटकॉइन जमा",
  New_Interac_Deposit:"इंटरैक",
  New_Interac_Deposit_Message:"इंटरैक ट्रांसफर",
  Payments_Limit:"भुगतान सीमा",
  No_Payment_Limit:"कोई भुगतान सीमा नहीं",
  PayPal_Deposit:"पेपैल",
  PayPal_Deposit_Message:"पेपैल जमा",
  No_Deposits_Word:"पहली कंपनी जोड़ें <a RouterLink='/folder/Deposit/New'>जमा</a>।",
  No_Documents_Specified:"मुद्रण के लिए कोई दस्तावेज निर्दिष्ट नहीं किया गया",
  No_Printers_Added:"कोई प्रिंटर नहीं मिला। एक जोड़ने के लिए सेटिंग > प्रिंटर पर जाएं।",
  DB_Erase_Prompt:"पूरे डेटाबेस को पूरी तरह से मिटा दें? चेतावनी: आप सभी संग्रहीत जानकारी खो देंगे!",
  Console_Warning:"इस कंसोल में कोई भी टेक्स्ट पेस्ट न करें। आप खुद को और/या अपनी कंपनी को गंभीर जोखिम में डाल सकते हैं।",
  No_Faxes_Word:"पहला <a राउटरलिंक='/फ़ोल्डर/फ़ैक्स/नया'>फ़ैक्स</a> बनाएं।",
  Cheque_Delete_Confirmation:"क्या आप वाकई इस चेक को हटाना चाहते हैं?",
  Design_Delete_Confirmation:"क्या आप वाकई इस डिज़ाइन को हटाना चाहते हैं?",
  Cheque_Pay_Confirmation:"इस चेक को सशुल्क के रूप में चिह्नित करें? यह प्रिंट कतार में नहीं दिखाई देगा।",
  Payment_Pay_Confirmation:"इस भुगतान को भुगतान के रूप में चिह्नित करें? यह चेक कतार में नहीं दिखाई देगा।",
  Delete_Deduction_Confirmation:"क्या आप वाकई इस कटौती को हटाना चाहते हैं?",
  Delete_Job_Confirmation:"क्या आप वाकई इस नौकरी को हटाना चाहते हैं?",
  Delete_Timesheet_Confirmation:"क्या आप वाकई इस टाइमशीट को हटाना चाहते हैं?",
  Delete_Schedule_Confirmation:"क्या आप वाकई इस शेड्यूल को हटाना चाहते हैं?",
  Delete_Setting_Confirmation:"क्या आप वाकई इस सेटिंग को रीसेट करना चाहते हैं?",
  Delete_Fax_Confirmation:"क्या आप वाकई इस फ़ैक्स को हटाना चाहते हैं?",
  Delete_File_Confirmation:"क्या आप द्वारा इस फाइल को हटाया जाना सुनिश्चित है?",
  Delete_Vacation_Confirmation:"क्या आप वाकई इस अवकाश को हटाना चाहते हैं?",
  Delete_Printer_Confirmation:"क्या आप वाकई इस प्रिंटर को हटाना चाहते हैं?",
  Remove_Design_Confirmation:"क्या आप वाकई इस डिज़ाइन को हटाना चाहते हैं?",
  Delete_Payroll_Confirmation:"क्या आप वाकई इस पेरोल को हटाना चाहते हैं?",
  Send_Fax_Email_Confirmation:"क्या आप इन दस्तावेज़ों को फ़ैक्स और ईमेल करना चाहते हैं?",
  Send_Email_Confirmation:"क्या आप इस दस्तावेज़ को ईमेल करना चाहते हैं?",
  Send_Fax_Confirmation:"क्या आप इस दस्तावेज़ को फ़ैक्स करना चाहते हैं?",
  Error_Generating_PDF:"क्षमा करें। इस दस्तावेज़ को बनाने में एक त्रुटि हुई।",
  PDF_Error_Saving_Image:"क्षमा करें। इस दस्तावेज़ की PDF छवि सहेजने में त्रुटि हुई।",
  Test_Printer_Confirmation:"क्या आप इस प्रिंटर पर एक परीक्षण पृष्ठ मुद्रित करना चाहते हैं?",
  Save_Timesheet_Prompt:"कृपया एक 'शीर्षक' जोड़ें या सहेजने के लिए 'टाइमर प्रारंभ करें' दबाएं।",
  Remove_Geofence_Prompt:"क्या आप वाकई इस भू-बाड़ के स्थान को हटाना चाहते हैं?",
  Delete_Employee_Confirmation:"बहुत ही पास से ",
  Fire_Employee_Confirmation:"क्या आप वाकई फायर करना चाहते हैं"
}