/**
 * @cheques
 * @sql
 * Starting point to export all lang vars.
 * Languages Translated January 2022
 * TODO: 
Cebuano
Corsican
Esperanto
Estonian
Frisian
Gujarati
Haitian Creole
Hausa
Hmong
Igbo
Irish
Javanese
Kinyarwanda
Kurdish (Kurmanji)
Kyrgyz
Luxembourgish
Malagasy
Pashto
Samoan
Sesotho
Shona
Sinhala
Sundanese
Turkmen
Uyghur
Yoruba
 * 
 */
export const Languages = [
    {name:"Afrikaans", code:"Af"},
    {name:"Amharic", code:"Am"},
    {name:"Arabic", code:"Ar"},
    {name:"Azerbaijani", code:"Az"},
    {name:"Belarusian", code:"Be"},
    {name:"Bengali", code:"Bn"},
    {name:"Bulgarian", code:"Bg"},
    {name:"Bosnian", code:"Bs"},
    {name:"Catalan", code:"Ca"},
    {name:"Czech", code:"Cs"},
    {name:"Welsh", code:"Cy"},
    {name:"Danish", code:"Da"},
    {name:"German", code:"De"},
    {name:"Greek", code:"El"},
    {name:"English", code:"En"},
    {name:"Spanish", code:"Es"},
    {name:"Basque", code:"Eu"},
    {name:"Persian", code:"Fa"},
    {name:"Finnish", code:"Fi"},
    {name:"Filipino", code:"Tl"},
    {name:"French", code:"Fr"},
    {name:"Scots Gaelic", code:"Gd"},
    {name:"Galician", code:"Gl"},
    {name:"Gujarati", code:"Gu"},
    {name:"Hawaiian", code:"Haw"},
    {name:"Hebrew", code:"He"},
    {name:"Hindi", code:"Hi"},
    {name:"Croatian", code:"Hr"},
    {name:"Hungarian", code:"Hu"},
    {name:"Armenian", code:"Hy"},
    {name:"Indonesian", code:"Id"},
    {name:"Icelandic", code:"Is"},
    {name:"Italian", code:"It"},
    {name:"Japanese", code:"Ja"},
    {name:"Georgian", code:"Ka"},
    {name:"Kazakh", code:"Kk"},
    {name:"Khmer", code:"Km"},
    {name:"Kannada", code:"Kn"},
    {name:"Korean", code:"Ko"},
    {name:"Latin", code:"La"},
    {name:"Lithuanian", code:"Li"},
    {name:"Lao", code:"Lo"},
    {name:"Latvian", code:"Lv"},
    {name:"Maori", code:"Mi"},
    {name:"Macedonian", code:"Mk"},
    {name:"Malayalam", code:"Ml"},
    {name:"Mongolian", code:"Mn"},
    {name:"Marathi", code:"Mr"},
    {name:"Malay", code:"Ms"},
    {name:"Maltese", code:"Mt"},
    {name:"Myanmar (Burmese)", code:"My"},
    {name:"Norwegian (Bokml)", code:"Nb"},
    {name:"Nepali", code:"Ne"},
    {name:"Dutch", code:"Nl"},
    {name:"Norwegian", code:"Nn"},
    {name:"Odia (Oriya)", code:"Or"},
    {name:"Punjabi", code:"Pa"},
    {name:"Polish", code:"Pl"},
    {name:"Portuguese", code:"Pt"},
    {name:"Romanian", code:"Ro"},
    {name:"Russian", code:"Ru"},
    {name:"Sindhi", code:"Sd"},
    {name:"Slovak", code:"Sk"},
    {name:"Slovenian", code:"Sl"},
    {name:"Somali", code:"So"},
    {name:"Albanian", code:"Sq"},
    {name:"Serbian", code:"Sr"},
    {name:"Swedish", code:"Sv"},
    {name:"Swahili", code:"Sw"},
    {name:"Tamil", code:"Ta"},
    {name:"Telugu", code:"Te"},
    {name:"Tajik", code:"Tg"},
    {name:"Thai", code:"Th"},
    {name:"Turkish", code:"Tr"},
    {name:"Tatar", code:"Tt"},
    {name:"Ukrainian", code:"Uk"},
    {name:"Urdu", code:"Ur"},
    {name:"Uzbek", code:"Uz"},
    {name:"Vietnamese", code:"Vi"},
    {name:"Xhosa", code:"Xh"},
    {name:"Yiddish", code:"Yi"},
    {name:"Chinese (Simplified)", code:"ZhCn"},
    {name:"Chinese (Traditional)", code:"Zh"},
    {name:"Zulu", code:"Zu"}
];
export {Af} from './af';
export {Am} from './am';
export {Ar} from './ar';
export {Az} from './az';
export {Be} from './be';
export {Bg} from './bg';
export {Bn} from './bn';
export {Bs} from './bs';
export {Ca} from './ca';
export {Cs} from './cs';
export {Cy} from './cy';
export {Da} from './da';
export {De} from './de';
export {El} from './el';
export {En} from './en';
export {Es} from './es';
export {Eu} from './eu';
export {Fa} from './fa';
export {Fi} from './fi';
export {Fr} from './fr';
export {Gd} from './gd';
export {Gl} from './gl';
export {Gu} from './gu';
export {Haw} from './haw';
export {He} from './he';
export {Hi} from './hi';
export {Hr} from './hr';
export {Hu} from './hu';
export {Hy} from './hy';
export {Id} from './id';
export {Is} from './is';
export {It} from './it';
export {Ja} from './ja';
export {Ka} from './ka';
export {Kk} from './kk';
export {Km} from './km';
export {Kn} from './kn';
export {Ko} from './ko';
export {La} from './la';
export {Li} from './li';
export {Lo} from './lo';
export {Lv} from './lv';
export {Mi} from './mi';
export {Mk} from './mk';
export {Ml} from './ml';
export {Mn} from './mn';
export {Mr} from './mr';
export {Ms} from './ms';
export {Mt} from './mt';
export {My} from './my';
export {Nb} from './nb';
export {Ne} from './ne';
export {Nl} from './nl';
export {Nn} from './nn';
export {Or} from './or';
export {Pa} from './pa';
export {Pl} from './pl';
export {Pt} from './pt';
export {Ro} from './ro';
export {Ru} from './ru';
export {Sd} from './sd';
export {Sk} from './sk';
export {Sl} from './sl';
export {So} from './so';
export {Sq} from './sq';
export {Sr} from './sr';
export {Sv} from './sv';
export {Sw} from './sw';
export {Ta} from './ta';
export {Te} from './te';
export {Tg} from './tg';
export {Th} from './th';
export {Tr} from './tr';
export {Tt} from './tt';
export {Tl} from './tl';
export {Uk} from './uk';
export {Ur} from './ur';
export {Uz} from './uz';
export {Vi} from './vi';
export {Xh} from './xh';
export {Yi} from './yi';
export {ZhCn} from './zh-cn';
export {Zh} from './zh';
export {Zu} from './zu';