export const El = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Πνευματικά δικαιώματα &copy; 2023",
  black:"Μαύρος",
  green:"Πράσινος",
  gold:"Χρυσός",
  blue:"Μπλε",
  brown:"Καφέ",
  purple:"Μωβ",
  pink:"Ροζ",
  red:"Το κόκκινο",
  Swatches:"Δείγματα",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Απόχρωση",
  Saturation:"Κορεσμός",
  Lightness:"Ελαφρότητα",
  Upgrade_To_Pro:"Αναβάθμιση σε επαγγελματικό",
  AllFeaturesInclude:"Όλα τα χαρακτηριστικά περιλαμβάνουν:",
  PrintUnlimitedCheques:"Εκτύπωση απεριόριστων επιταγών",
  PremiumChequeDesigns:"Σχέδια επιταγών Premium",
  ManageUnlimitedEmployees:"Διαχείριση απεριόριστων εργαζομένων",
  AddUnlimitedPayees:"Προσθήκη απεριόριστων δικαιούχων πληρωμής",
  CreateUnlimitedPayrolls:"Δημιουργήστε απεριόριστες μισθοδοσίες",
  UnlimitedSchedulesandTimeSheets:"Απεριόριστα χρονοδιαγράμματα και φύλλα χρόνου",
  BulkPayPalPayouts:"Μαζικές πληρωμές PayPal",
  UnlimitedBankAccounts:"Απεριόριστοι τραπεζικοί λογαριασμοί",
  ManageMultipleCompanies:"Διαχείριση πολλών εταιρειών",
  TrackInsurancePolicies:"Παρακολουθήστε τα ασφαλιστήρια συμβόλαια",
  Bio_MetricProtection:"Βιομετρική προστασία",
  Geo_FenceLock_OutProtection:"Προστασία κλειδώματος Geo-Fence",
  Multiple_Companies_Word:"Η διαχείριση πολλών εταιρειών δεν είναι διαθέσιμη χωρίς επιταγές premium.",
  PayPal_Payouts_Word:"Οι πληρωμές με PayPal είναι απενεργοποιημένες χωρίς επιταγές premium.",
  PINProtection:"Προστασία PIN",
  PasswordProtection:"Προστασία με κωδικό πρόσβασης",
  May_Require_Approval:"Ενδέχεται να απαιτείται έγκριση.",
  Subscribe:"Εγγραφείτε",
  Billed:"Τιμολογήθηκε",
  Up:"Πάνω",
  Down:"Κάτω",
  Positioning:"Τοποθέτηση",
  Marker:"Σημάδι",
  Drag_Marker:"Σύρετε το δείκτη",
  Tagline:"Εκτύπωση επιταγών και πίνακα μισθοδοσίας",
  Marker_Word:"Χρησιμοποιήστε τους δείκτες για το μέγεθος του στοιχείου.",
  Pinch_Zoom:"Τσιμπήστε το ζουμ",
  Pinch_Word:"Τσιμπήστε για να μεγεθύνετε το στοιχείο.",
  Drag:"Σέρνω",
  Drag_Word:"Χρησιμοποιήστε το δάχτυλό σας για να σύρετε στοιχεία.",
  subscription_alias_word:"Αυτόματη ανανέωση συνδρομής",
  premium_alias_word:"Πακέτο αναβάθμισης μίας χρήσης",
  print_alias_word:"Εκτύπωση Ατομικών Επιταγών",
  mode_alias_word:"Τρόπος",
  Pro:"Pro",
  Pro_word:"Απαιτείται έκδοση Pro.",
  Cant_Delete_Default_Company_Word:"Λυπούμαστε, δεν μπορείτε να διαγράψετε την προεπιλεγμένη εταιρεία σας.",
  Reinsert_Default_Designs:"Εισαγωγή εκ νέου προεπιλεγμένων σχεδίων",
  Reinsert_Default_Designs_word:"Θέλετε να εισαγάγετε ξανά προεπιλεγμένα σχέδια;",
  Subscription_Active_Disable_Word:"Αυτή η συνδρομή είναι ενεργή. Θέλετε να ακυρώσετε αυτήν τη συνδρομή στις επιταγές;",
  Company_Logo:"Λογότυπο Εταιρείας",
  ZERO_:"ΜΗΔΕΝ",
  Disclaimer:"Αποποίηση ευθυνών",
  Privacy_Policy:"Πολιτική Απορρήτου",
  EULA:"Έλεγχοι EULA",
  Terms_Of_Service:"Όροι χρήσης",
  Terms_Of_Use:"Οροι χρήσης",
  Refunds:"Πολιτική επιστροφής",
  Single_Print:"1 Έλεγχος",
  Two_Prints:"2 Έλεγχοι",
  Five_Prints:"5 Έλεγχοι",
  Ten_Prints:"10 Έλεγχοι",
  Fifteen_Prints:"15 Έλεγχοι",
  Twenty_Prints:"20 Έλεγχοι",
  Thirty_Prints:"30 Έλεγχοι",
  Image_Added:"Προστέθηκε εικόνα",
  Image_Preview:"Προεπισκόπηση εικόνας",
  No_Image_Was_Selected:"Δεν επιλέχθηκε καμία εικόνα.",
  Cheques_Unlimited:"Επιταγές Απεριόριστες",
  _Subscription:"Συνδρομή",
  Subscription:"Έλεγχοι - 1 μήνας",
  Two_Month_Subscription:"Έλεγχοι - 2 μήνες",
  Three_Month_Subscription:"Έλεγχοι - 3 μήνες",
  Six_Month_Subscription:"Έλεγχοι - 6 μήνες",
  Twelve_Month_Subscription:"Έλεγχοι - 12 μήνες",
  Cheques_Are_Available:"Επιταγές είναι διαθέσιμες για εκτύπωση.",
  Upgrade_Required_Two:"Επιλέξτε ένα πακέτο και πατήστε δύο φορές το κουμπί τιμής για να ξεκινήσετε την αγορά σας. Εκτυπώστε επαγγελματικούς έγχρωμους ελέγχους σε δευτερόλεπτα.",
  Month:"Μήνας",
  Due:"Λόγω:",
  Expires:"Λήγει:",
  Subscription_Active:"Συνδρομή Ενεργή",
  Subscription_Inactive:"Συνδρομή Ανενεργή",
  Purchase_Additional_Cheques:"Αγορά επιπλέον επιταγών;",
  Printable_Cheque:"Εκτυπώσιμος έλεγχος",
  Printable_Cheques:"Εκτυπώσιμες επιταγές",
  Printable_Cheque_Word:"η επιταγή είναι διαθέσιμη στον λογαριασμό σας.",
  Printable_Cheques_Word:"υπάρχουν διαθέσιμες επιταγές στον λογαριασμό σας.",
  Max_Amount_Message:"Το ποσό που έχετε καθορίσει έχει υπερβεί το μέγιστο ποσό που έχει οριστεί για αυτό το σύστημα:",
  Terms_Required_Word:"Πρέπει να συμφωνήσετε με αυτήν τη συμφωνία πριν συνεχίσετε να χρησιμοποιείτε τις επιταγές.",
  Subscriptions:"Συνδρομές",
  Product_Upgrades:"Αναβαθμίσεις",
  Mailed_Out_Cheques:"Έλεγχοι που έχουν αποσταλεί μέσω ταχυδρομείου",
  Enter_A_Company_Name:"Εισαγάγετε ένα όνομα εταιρείας.",
  Single_Cheques:"Ενιαίες επιταγές",
  Cheque_Golden:"Χρυσή Επιταγή",
  Cheque_Golden_Window:"Σχέδιο χρυσής επιταγής.",
  Cheque_Green:"Πράσινος έλεγχος",
  Cheque_Green_Window:"Πράσινη επιταγή σχέδιο.",
  Cheque_Red:"Κόκκινη επιταγή",
  Cheque_Red_Window:"Σχέδιο κόκκινης επιταγής.",
  Cheque_Yellow:"Κίτρινη επιταγή",
  Cheque_Yellow_Window:"Σχέδιο κίτρινης επιταγής.",
  Cheque_Statue_of_Liberty:"Αγαλμα της ελευθερίας",
  Cheque_Statue_of_Liberty_Window:"Σχέδιο επιταγών του αγάλματος της ελευθερίας.",
  Cheque_Green_Wave:"Πράσινο Κύμα",
  Cheque_Green_Wave_Window:"Πράσινη επιταγή σχέδιο.",
  Cheque_Golden_Weave:"Χρυσή Ύφανση",
  Cheque_Golden_Weave_Window:"Κομψό σχέδιο χρυσής επιταγής.",
  Cheque_Green_Sun:"Πράσινος Ήλιος",
  Cheque_Green_Sun_Window:"Βαθύ και ηρεμιστικό σχέδιο ελέγχου.",
  Cheque_Blue_Checkers:"Μπλε πούλια",
  Cheque_Blue_Checkers_Window:"Σχέδιο μπλε επιταγών.",
  Cashiers_Check:"Επιταγή ταμείου",
  Cashiers_Check_Window:"Πρότυπο στυλ επιταγής ταμείου.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Σχεδιασμός Aqua check.",
  Cheque_Golden_Checkers:"Χρυσά πούλια",
  Cheque_Golden_Checkers_Window:"Σχέδιο χρυσής επιταγής.",
  Upgrade_Unavailable:"Μη διαθέσιμες αναβαθμίσεις",
  Bank_Code_Protection:"Προστασία αριθμών τράπεζας",
  Bank_Code_Protection_Word:"Προστατέψτε τους αριθμούς της τράπεζάς σας από τη χρήση σε αυτήν την εφαρμογή που εκτελείται σε άλλη συσκευή ή για άλλο χρήστη. Αυτή η ενέργεια είναι ΜΗ ΑΝΤΙΣΤΡΕΦΗ. Να συνεχίσει?",
  Bank_Code_Protection_Blocked_Word:"Οι αριθμοί τραπεζών που προσπαθείτε να χρησιμοποιήσετε είναι δεσμευμένοι για άλλον χρήστη ή συσκευή.",
  Bank_Code_Protection_Error_Word:"Η επαλήθευση αριθμού απέτυχε. Συνδεθείτε στο διαδίκτυο και δοκιμάστε να προσθέσετε ξανά αυτόν τον τραπεζικό λογαριασμό.",
  Bank_Code_Protection_Set_Error_Word:"Η προστασία αριθμών τράπεζας απαιτεί να είστε συνδεδεμένοι στο διαδίκτυο. Συνδεθείτε και δοκιμάστε ξανά.",
  Upgrade_Unavailable_Word:"Λυπούμαστε, αντιμετωπίζουμε πρόβλημα με την επαλήθευση σας. Οι συνδρομές και οι αναβαθμίσεις σε επιταγές δεν είναι προς το παρόν διαθέσιμες για αγορά στην περιοχή σας.",
  PayPal_Payment_Preview:"Προεπισκόπηση πληρωμής PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Επιλέξτε PayPal",
  PayPal_Applications:"Εφαρμογές PayPal",
  Purchase_With_Apple_Pay:"Αγορά με Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Εταιρείες",
  Insurance:"ΑΣΦΑΛΙΣΗ",
  New_PayPal:"Νέο PayPal",
  Pay_By:"Πληρωμή μέσω",
  Insure:"Ασφαλίζω",
  Miles:"Μίλια",
  Payment_Method:"Μέθοδος πληρωμής",
  Select_Policies:"Επιλέξτε Πολιτικές",
  Policies:"πολιτικών",
  Policy:"Πολιτική",
  No_PayPal_Account:"Χωρίς λογαριασμό PayPal",
  No_Policies:"Χωρίς ασφαλιστήρια συμβόλαια",
  New_Policy:"Νέα Πολιτική",
  PayPal_Payment:"Πληρωμή PayPal",
  PayPal_Payments:"Πληρωμές PayPal",
  No_Payment_Selected:"Δεν επιλέχθηκε πληρωμή",
  Sending_Payment_Word:"Παρακαλώ περιμένετε... Αυτή η πληρωμή αποστέλλεται.",
  Sending_Payments_Word:"Παρακαλώ περιμένετε... Οι πληρωμές αποστέλλονται.",
  No_Payment_Selected_PayPal:"Δεν έχει επιλεγεί <a routerLink='/folder/Payments'>πληρωμή PayPal</a> για αποστολή.",
  Payment_Sent:"Η πληρωμή έχει σταλεί",
  PayPal_Payment_Sent:"Αυτή η πληρωμή έχει σταλεί με PayPal.",
  Copay:"Αντιπληρωμή",
  Dead:"Νεκρός",
  Alive:"Ζωντανός",
  Not_Dead:"Όχι νεκρός",
  Unclaimed:"Αγύρευτος",
  Attempted:"Προσπάθησε",
  Deceased:"Αποθανών",
  Claimed:"Διεκδικήθηκε",
  Unpaid:"Απλήρωτος",
  Sending_Payment:"Αποστολή πληρωμής",
  Sending_Payments:"Αποστολή πληρωμών",
  Send_PayPal_Confirmation:"Θέλετε να στείλετε αυτήν τη συναλλαγή με το PayPal;",
  Send_PayPal_Confirmation_Word:"Πατήστε το πράσινο κουμπί για να στείλετε αυτήν τη συναλλαγή.",
  Save_Payment_As_Unpaid:"Αποθήκευση αυτής της πληρωμής ως απλήρωτη;",
  Payment_Pay_Confirmation_PayPal:"Αποθήκευση αυτής της πληρωμής ως πληρωμένη;",
  No_Policies_Word:"Προσθέστε το πρώτο <a routerLink='/folder/Postage/New'>Ασφαλιστήριο συμβόλαιο</a> τώρα.",
  Timesheet_Multiple_Delete_Confirmation:"Είστε βέβαιοι ότι θέλετε να διαγράψετε πολλά φύλλα χρόνου;",
  Select_Multiple_Timesheets_Prompt:"Δεν έχουν επιλεγεί φύλλα χρόνου. Επιλέξτε τουλάχιστον ένα φύλλο χρόνου.",
  Select_Multiple_Policies_Prompt:"Δεν επιλέχθηκαν πολιτικές. Επιλέξτε τουλάχιστον ένα ασφαλιστήριο συμβόλαιο.",
  Policies_Multiple_Delete_Confirmation:"Είστε βέβαιοι ότι θέλετε να διαγράψετε πολλές πολιτικές;",
  Company:"Εταιρία",
  Add_Company:"Προσθήκη εταιρείας",
  New_Company:"Προσθήκη εταιρείας",
  No_Companies:"Χωρίς Εταιρείες",
  Enable_Company:"Ενεργοποίηση εταιρείας",
  Select_Company:"Επιλέξτε Εταιρεία",
  The_Default_Company:"Η προεπιλεγμένη ετικέτα εταιρείας.",
  Manage_Companies:"Διαχείριση Εταιρειών",
  No_Companies_Word:"Οι έλεγχοι θα χρησιμοποιούν μια προεπιλεγμένη εταιρεία.<br />Προσθέστε την <a routerLink='/folder/Company/New'>πρώτη εταιρεία</a>.",
  Default_Company:"Προεπιλεγμένη εταιρεία",
  Cheques_Unlimited_Word:"Το Checks Unlimited σάς επιτρέπει να εκτυπώνετε όσες επιταγές θέλετε.",
  Cheques_Subscription_Word:"Μια συνδρομή επιταγών σάς επιτρέπει να εκτυπώνετε όσες επιταγές θέλετε.",
  You_Own_This_Product:"Είστε κάτοχος αυτού του προϊόντος.",
  Your_Subscription_is_Active:"Η συνδρομή σας είναι ενεργή.",
  Active_Products:"Ενεργοποιημένα Προϊόντα",
  Purchase_Confirmation:"Αγορά",
  Purchase_Cheques:"Επιταγές αγοράς",
  Restore_Purchase:"Επαναφορά αγορών",
  Erase_Purchase:"Διαγραφή αγορών",
  Successfully_Purchased:"Επιτυχής Αγορά",
  Enter_Your_Licence_Key:"Εισαγάγετε το κλειδί άδειας χρήσης σε αυτήν τη σελίδα για να ενεργοποιήσετε αυτό το προϊόν.",
  Licence_Key:"Κλειδί άδειας χρήσης",
  Enter_Licence_Key:"Εισαγάγετε το κλειδί άδειας χρήσης",
  Purchased:"Αγορασμένο",
  Enable_Feature:"Ενεργοποίηση λειτουργίας",
  Cancel_Subscription:"Ακύρωση συνδρομής",
  Subscription_Removed:"Η συνδρομή καταργήθηκε",
  Select_Active_Subscription:"Επιλέξτε μια ενεργή συνδρομή για να την τροποποιήσετε.",
  Remove_Subscription_Word:"Είστε βέβαιοι ότι θέλετε να ακυρώσετε αυτήν τη συνδρομή;",
  Delete_Company_Confirmation:"Είστε βέβαιοι ότι θέλετε να διαγράψετε ολόκληρη αυτήν την εταιρεία; ΠΡΟΕΙΔΟΠΟΙΗΣΗ: Θα χάσετε όλες τις αποθηκευμένες πληροφορίες!",
  Delete_Default_Company_Word:"Δεν μπορείτε να διαγράψετε την προεπιλεγμένη εταιρεία. Θέλετε να επαναφέρετε την εφαρμογή και να την επαναφέρετε στην προεπιλεγμένη κατάσταση; ΠΡΟΕΙΔΟΠΟΙΗΣΗ: Θα χάσετε όλες τις αποθηκευμένες πληροφορίες!",
  Console_Warning_2:"Μην χειρίζεστε κανένα νόμισμα χρησιμοποιώντας αυτήν την εφαρμογή που δεν είναι προς το παρόν δικό σας.",
  Terms_and_Conditions:"Οροι και Προϋποθέσεις",
  and_the:"και το",
  for:"Για",
  Please_Read_Word:"Παρακαλώ διαβάστε και συμφωνήστε με το",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Δεν ήταν δυνατή η εύρεση ορισμένων ισοτιμιών μετατροπής νομισμάτων. Συνδεθείτε στο διαδίκτυο.",
  Free:"Ελεύθερος",
  DB_Erase_Prompt_2:"Να διαγραφεί πλήρως ολόκληρη η βάση δεδομένων προγραμματιστών; ΠΡΟΕΙΔΟΠΟΙΗΣΗ: Θα χάσετε όλες τις πληροφορίες ΑΓΟΡΑ και ΕΓΓΡΑΦΗΣ!",
  Successfully_Imported:"Έγινε επιτυχής εισαγωγή",
  Select_Postage:"Επιλέξτε Ταχυδρομικά τέλη",
  No_Postage:"Χωρίς γραμματόσημα",
  No_Paid_Postage_Word:"Προσθέστε την πρώτη σφραγίδα <a routerLink='/folder/Postage/New'>Πληρωμένα ταχυδρομικά τέλη</a>.",
  Trial_Complete:'Ολοκληρώθηκε η δοκιμή',
  Please_Upgrade:'Αναβαθμίστε τις επιταγές για να συνεχίσετε την εκτύπωση.',
  Aa:"Αα",
  Color:"Χρώμα",
  Font:"Γραμματοσειρά",
  Guide:"Οδηγός",
  Guides:"Οδηγοί",
  Image:"Εικόνα",
  Zoom:"Ανίπταμαι διαγωνίως",
  Logo:"Λογότυπο",
  Bank:"Τράπεζα",
  MICR:"MICR",
  Total:"Σύνολο",
  Cancel:"Ματαίωση",
  Confirm:"Επιβεβαιώνω",
  Method:"Μέθοδος",
  Biometric_Security:"Βιομετρική ασφάλεια",
  Please_Login_To_Continue:"Παρακαλώ συνδεθείτε για να συνεχίσετε.",
  Complete:"Πλήρης",
  Sign_Up:"Εγγραφείτε",
  Error:"Λάθος",
  Biometrics:"Βιομετρική",
  Percent:"Τοις εκατό",
  Zero_Percent:"0%",
  Top_Margin:"Κορυφαίο Περιθώριο",
  Bottom_Margin:"Κάτω Περιθώριο",
  Left_Margin:"Αριστερό περιθώριο",
  Right_Margin:"Δεξί Περιθώριο",
  MICR_Margin:"Περιθώριο MICR",
  Table_Margin:"Περιθώριο πίνακα",
  Address_Margin:"Περιθώριο Διεύθυνσης",
  Percentage_:"Ποσοστό",
  Vacation_Title:"Τίτλος διακοπών",
  Use_PIN:"Χρησιμοποιήστε το PIN",
  Loading__:"Φόρτωση...",
  Design_Title:"Τίτλος σχεδίου",
  Authorize:"Εξουσιοδοτώ",
  Key:"Κλειδί",
  Public_Key:"Δημόσιο κλειδί",
  Private_Key:"Ιδιωτικό κλειδί",
  Authenticate:"Πιστοποιώ την αυθεντικότητα",
  Last_Payroll:"Τελευταία Μισθοδοσία",
  Last_Calculation:"Τελευταίος Υπολογισμός",
  Authorized:"Εξουσιοδοτημένο",
  Geo_Authorized:"Γεωγραφική Τοποθεσία: Εξουσιοδοτημένο",
  Not_Authorized:"Μη εξουσιοδοτημενο",
  Authorization_Complete:"Ολοκληρώθηκε η εξουσιοδότηση",
  Geolocation_Authorization:"Εξουσιοδότηση γεωγραφικής τοποθεσίας",
  Out_of_Bounds:"Εκτός ορίων",
  Cant_Delete_Default_Design:"Δεν είναι δυνατή η διαγραφή ενός προεπιλεγμένου σχεδίου.",
  Unauthorized_Out_of_Bounds:"Μη εξουσιοδοτημένο: Εκτός ορίων",
  Biometric_Authorization:"Βιομετρική Εξουσιοδότηση",
  Locating_Please_Wait:"Εντοπισμός, Περιμένετε...",
  Test_Biometrics:"Δοκιμή βιομετρικών",
  Cheque_Margins:"Ελέγξτε τα περιθώρια",
  Percentage_Full_Error:"Το πεδίο ποσοστού δεν μπορεί να οριστεί πάνω από το 100% τοις εκατό.",
  No_Payroll_Text:"Προσθέστε έναν <a routerLink='/folder/Employee/New'>Υπάλληλο</a> ή <a routerLink='/folder/Payee/New'>Δικαιούχο πληρωμής</a> και έναν <a routerLink='/folder/Schedule/New'>Πρόγραμμα</a>.",
  Design_Saved:"Το σχέδιο αποθηκεύτηκε",
  Default_Design_Selected:"Επιλέχθηκε η προεπιλεγμένη σχεδίαση",
  Partial_Import:"Μερική εισαγωγή",
  Partial_Export:"Μερική εξαγωγή",
  Entire_Import:"Ολόκληρη η εισαγωγή",
  Entire_Export:"Ολόκληρη η εξαγωγή",
  Existing_Password:"Εισαγάγετε τον υπάρχοντα κωδικό πρόσβασής σας:",
  Existing_PIN:"Εισαγάγετε τον υπάρχοντα κωδικό PIN:",
  Pin_Change_Header:"Επιβεβαίωση PIN",
  Pin_Change_SubHeader:"Εισαγάγετε τον παλιό σας αριθμό PIN για να επιβεβαιώσετε την αλλαγή.",
  Pass_Change_Header:"Επιβεβαίωση κωδικού πρόσβασης",
  Pass_Change_SubHeader:"Εισαγάγετε τον παλιό σας κωδικό πρόσβασης για να επιβεβαιώσετε την αλλαγή.",
  PIN_Enter_Message:"Εισαγάγετε το PIN σας για επιβεβαίωση.",
  Password_Enter_Message:"Εισαγάγετε τον κωδικό πρόσβασής σας για επιβεβαίωση.",
  Pin_Updated_Success:"Το PIN ενημερώθηκε με επιτυχία!",
  Pin_Updated_Fail:"Δεν ήταν δυνατή η ενημέρωση του PIN.",
  Pass_Updated_Success:"Ο κωδικός πρόσβασης ενημερώθηκε με επιτυχία.",
  Pass_Updated_Fail:"Δεν ήταν δυνατή η ενημέρωση του κωδικού πρόσβασης.",
  Preview_Payment:"Προεπισκόπηση πληρωμής",
  Preview_Payroll:"Προεπισκόπηση μισθοδοσίας",
  No_Payments_Created:"Δεν βρέθηκαν πληρωμές να δημιουργηθούν.",
  Payment_Preview:"Προεπισκόπηση πληρωμής",
  Enable_Payee:"Ενεργοποίηση δικαιούχου πληρωμής",
  Rendered:"Αποδόθηκε",
  No_Email:"Χωρίς email",
  Setup_Cheques:"Έλεγχοι εγκατάστασης",
  name:"Ονομα",
  address:"Διεύθυνση",
  address_2:"Διεύθυνση Γραμμή 2",
  city:"Πόλη",
  province:"Επαρχία",
  postal_code:"Ταχυδρομικός/Τ.Κ",
  country:"Χώρα",
  username:"Όνομα χρήστη",
  full_name:"Πλήρες όνομα",
  birthday:"Γενέθλια",
  email:"ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
  phone:"Τηλέφωνο",
  employees:"Υπαλλήλους",
  addresses:"Διευθύνσεις",
  payment_amount_limit:"Όριο ποσού πληρωμής",
  total_limit:"Συνολικό Όριο",
  payees:"Δικαιούχοι πληρωμής",
  description:"Περιγραφή",
  address_line_one:"Διεύθυνση Γραμμή 1",
  address_line_two:"Διεύθυνση Γραμμή δύο",
  image:"Εικόνα",
  account_holder:"Κάτοχος λογαριασμού",
  cheque_starting_id:"Ελέγξτε το αναγνωριστικό εκκίνησης",
  transit_number:"Αριθμός συγκοινωνίας",
  institution_number:"Αριθμός ιδρύματος",
  designation_number:"Αριθμός Ονομασίας",
  account_number:"Αριθμός λογαριασμού",
  currency:"Νόμισμα",
  signature:"Υπογραφή",
  payment_payroll_limit:"Όριο πληρωμής",
  total_limi:"Συνολικό όριο",
  date:"Ημερομηνία",
  printed_memo:"Έντυπο Σημείωμα",
  banks:"Τράπεζες",
  signature_image:"Εικόνα υπογραφής",
  address_name:"Διεύθυνση",
  notes:"Σημειώσεις",
  design:"Σχέδιο",
  title:"Τίτλος",
  frequency:"Συχνότητα",
  fax:"Φαξ",
  salaries:"Μισθοί",
  salary_ids:"Ταυτότητες μισθοδοσίας",
  start_time:"Ωρα έναρξης",
  end_time:"Τέλος χρόνου",
  paid:"επί πληρωμή",
  overtime_percentage:"Ποσοστό επί πληρωμή",
  overtime_amount:"Καταβληθέν Πάγιο Ποσό",
  first_name:"Ονομα",
  last_name:"Επίθετο",
  moderation:"Μετριοπάθεια",
  create:"Δημιουργώ",
  edit:"Επεξεργασία",
  destroy:"Καταστρέφω",
  day_start_time:"Ώρα_έναρξης_ημέρας",
  day_end_time:"Ημέρα_λήξης_ώρας",
  fullname:"Ονομα",
  time:"χρόνος",
  auto_send:"Αυτόματη αποστολή",
  time_method:"Μέθοδος χρόνου",
  schedules:"Δρομολόγια",
  indefinite_payroll_enabled:"Ενεργοποίηση επ' αόριστον",
  amount:"Ποσό",
  repeat:"Επαναλαμβάνω",
  always_enabled:"Πάντα ενεργοποιημένο",
  start_date:"Ημερομηνία έναρξης",
  end_date:"Ημερομηνία λήξης",
  Cheque_Total:"Ελέγξτε το Σύνολο",
  Total_Amount:"Συνολικό ποσό:",
  Amounts:"Ποσά:",
  Images:"εικόνες",
  Files:"Αρχεία",
  Previewing:"Προεπισκόπηση:",
  Insert:"Εισάγετε",
  Preview_Import:"Εισαγωγή προεπισκόπησης",
  Entry:"Είσοδος",
  Entries:"Συμμετοχές",
  No_Entries:"Δεν υπάρχουν καταχωρήσεις",
  Import_Type:"Τύπος εισαγωγής",
  Select_Details:"Επιλέξτε Λεπτομέρειες",
  Select_Payee:"Επιλέξτε Δικαιούχος πληρωμής",
  Enable_Holidays:"Ενεργοποίηση Διακοπών",
  Disable_Holidays:"Απενεργοποιήστε τις διακοπές",
  Wire_Transfer:"Τραπεζικό έμβασμα",
  New_Export:"Νέα Εξαγωγή",
  Export_Data:"Εξαγωγή δεδομένων",
  Export_Data_Word:"Επιλέξτε τον τύπο αρχείου για εξαγωγή και λήψη.",
  Export_File:"Εξαγωγή αρχείου",
  Mode:"Τρόπος",
  Times:"Φορές",
  Widgets:"Widgets",
  Slider:"Ολισθητής",
  Set_Details:"Ρύθμιση λεπτομερειών",
  Select_Type:"Επιλέξτε Τύπος",
  Display_Slider:"Ρυθμιστικό οθόνης",
  Dashboard_Slider:"Ρυθμιστικό πίνακα οργάνων",
  Dashboard_Mode:"Λειτουργία ταμπλό",
  Show_Intro:"Εμφάνιση εισαγωγής",
  Show_Payrolls:"Εμφάνιση μισθοδοσίας",
  Show_Holidays:"Εμφάνιση Διακοπών",
  Show_Invoices:"Εμφάνιση τιμολογίων",
  Show_Cheques:"Εμφάνιση επιταγών",
  Enabled_Widgets:"Ενεργοποιημένα γραφικά στοιχεία",
  Disabled_Widgets:"Απενεργοποιημένα γραφικά στοιχεία",
  Colors:"Χρωματιστά",
  Barcodes:"Barcodes",
  View_Timers:"Προβολή χρονοδιακόπτων",
  Gradients:"Διαβαθμίσεις",
  No_Info:"Δεν υπάρχουν πληροφορίες",
  Disable:"Καθιστώ ανίκανο",
  Show_Layer:"Εμφάνιση επιπέδων",
  Hide_Layer:"Απόκρυψη επιπέδων",
  Text_Layer:"Επίπεδα κειμένου",
  Secondly:"κατα δευτερον",
  Minutely:"Λεπτομερώς",
  nine_am:"9:00 Π.Μ",
  five_pm:"5:00 ΜΜ",
  Enable_Address:"Ενεργοποίηση Διεύθυνσης",
  Invalid_File_Type:"Λυπούμαστε, επιλέχθηκε μη έγκυρος τύπος αρχείου. Υποστηριζόμενος τύπος αρχείου:",
  Error_Updating_Entry:"Λυπούμαστε, παρουσιάστηκε σφάλμα κατά την ενημέρωση αυτής της καταχώρισης.",
  Schedule_Timing_Alert:"Σφάλμα: Η ώρα έναρξης του χρονοδιαγράμματος ορίζεται σε μια τιμή μετά την ώρα λήξης.",
  Select_Multiple_Payments_Prompt:"Δεν επιλέχθηκαν πληρωμές. Επιλέξτε τουλάχιστον μία πληρωμή.",
  Select_Multiple_Cheques_Prompt:"Δεν επιλέχθηκαν έλεγχοι. Επιλέξτε τουλάχιστον μία επιταγή.",
  Select_Multiple_Items_Prompt:"Δεν επιλέχθηκαν στοιχεία. Επιλέξτε τουλάχιστον ένα στοιχείο.",
  Paymemt_Multiple_Delete_Confirmation:"Είστε βέβαιοι ότι θέλετε να διαγράψετε πολλές πληρωμές;",
  Cheque_Multiple_Delete_Confirmation:"Είστε βέβαιοι ότι θέλετε να διαγράψετε πολλές επιταγές;",
  Payee_Multiple_Delete_Confirmation:"Είστε βέβαιοι ότι θέλετε να διαγράψετε πολλούς δικαιούχους πληρωμής;",
  Employee_Multiple_Delete_Confirmation:"Είστε βέβαιοι ότι θέλετε να διαγράψετε πολλούς υπαλλήλους;",
  MICR_Warning:"Σημείωση: Ορισμένοι εκτυπωτές και συσκευές ενδέχεται να μην εμφανίζουν σωστά τη γραμματοσειρά MICR.",
  Automatically_Send:"Αυτόματη αποστολή",
  Type:"Τύπος",
  Payment_Type:"Τρόπος πληρωμής",
  Auto_Send:"Αυτόματη αποστολή",
  Automatically_Process:"Αυτόματη επεξεργασία",
  Auto_Process:"Αυτόματη επεξεργασία",
  Image_Based:"Βασισμένο σε εικόνα",
  Font_Based:"Με βάση τη γραμματοσειρά",
  Rerender:"Εκ νέου απόδοση",
  Rendering:"Απόδοση",
  Render:"Αρχεία",
  Top:"Μπλουζα",
  Middle:"Μέσης",
  Bottom:"Κάτω μέρος",
  Top_Left:"Πάνω αριστερά",
  Top_Center:"Κορυφαίο Κέντρο",
  Top_Right:"Πάνω δεξιά",
  Middle_Left:"Μέση Αριστερά",
  Middle_Center:"Μέσο Κέντρο",
  Middle_Right:"Μέση Δεξιά",
  Bottom_Left:"Κάτω αριστερά",
  Bottom_Center:"Κάτω κέντρο",
  Bottom_Right:"Κάτω δεξιά",
  Numbers:"Αριθμοί",
  Verified:"Επαληθεύτηκε",
  Paper_Size:"Μέγεθος χαρτιού",
  New_Device:"Νέα Συσκευή",
  Add_Device:"Προσθέστε ΣΥΣΚΕΥΗ",
  Remove_Device:"Αφαίρεση της συσκευής",
  Delete_Device:"Διαγραφή συσκευής",
  Block_Device:"Αποκλεισμός συσκευής",
  Block:"ΟΙΚΟΔΟΜΙΚΟ ΤΕΤΡΑΓΩΝΟ",
  Unblock:"Ξεβουλώνω",
  Table:"Τραπέζι",
  Scan_Login_Code:"Σάρωση κωδικού σύνδεσης",
  Login_Code:"Κωδικός σύνδεσης",
  Scan_Code:"Σάρωση κώδικα",
  Scan_QR_Code:"Σάρωση κωδικού QR",
  Select_All:"Επιλογή όλων",
  Deselect_All:"Αποεπιλογή όλων",
  Increase:"Αυξάνουν",
  Decrease:"Μείωση",
  Bold:"Τολμηρός",
  Text:"Κείμενο",
  Style:"Στυλ",
  Italic:"Πλάγια γραφή",
  QR_Code:"Κωδικός QR",
  Barcode:"Barcode",
  Browse_Images:"Αναζήτηση εικόνων",
  Browse_Files:"ΕΞΕΡΕΥΝΗΣΗ ΑΡΧΕΙΩΝ",
  Background_Image:"Εικόνα φόντου",
  Logo_Image:"Εικόνα λογοτύπου",
  Header_Image:"Εικόνα επικεφαλίδας",
  Bank_Image:"Εικόνα Τράπεζας",
  Cut_Lines:"Γραμμές κοπής",
  Background:"Ιστορικό",
  License:"Αδεια",
  One_License:"1 Άδεια:",
  Licensed:"Άδεια χρήσης για:",
  Not_Licensed:"Χωρίς άδεια",
  Enter_Serial:"Εισαγάγετε το Serial",
  Serial_Key:"Σειριακό κλειδί",
  Serial:"Κατα συρροη",
  Product_Key:"Κλειδί προϊόντος",
  Check_Product_Key:"Ελέγξτε το κλειδί προϊόντος",
  Add_Product_Key:"Προσθήκη κλειδιού προϊόντος",
  Verifying_Purchase:"Επαλήθευση αγοράς...",
  Print_Envelope:"Εκτύπωση φακέλου",
  Envelope:"Φάκελος",
  Thank_You:"Ευχαριστώ!",
  Scale:"Κλίμακα",
  Print_Scale:"Κλίμακα εκτύπωσης",
  Borders:"σύνορα",
  VOID:"ΚΕΝΟΣ",
  Void_Cheque:"Άκυρος έλεγχος",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ΠΛΗΡΩΣΤΕ ΜΕ ΠΑΡΑΓΓΕΛΙΑ:",
  NO_DOLLARS:"ΟΧΙ ΔΟΛΑΡΙΑ ",
  ONE_DOLLAR:"ΕΝΑ ΔΟΛΑΡΙΟ",
  DOLLARS:" ΔΟΛΑΡΙΑ",
  AND:" ΚΑΙ ",
  CENTS:" CENTS.",
  NO_:"ΟΧΙ ",
  ONE_:"ΕΝΑΣ ",
  AND_NO_:"ΚΑΙ ΟΧΙ ",
  _AND_ONE_:"ΚΑΙ ΕΝΑ ",
  DOLLARS_AND_ONE_CENT:" ΚΑΙ ΕΝΑ ΣΕΝΤΟ.",
  AND_NO_CENTS:" ΚΑΙ ΜΗΔΕΝ ΣΕΝΤ.",
  CHEQUE_PRINT_DATE:"ΗΜΕΡΟΜΗΝΙΑ:",
  CHEQUE_PRINT_MEMO:"ΣΗΜΕΙΩΜΑ:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Tareas Iniciales",
  Inital_Setup:"Configuración Inicial",
  Welcome_To:"Bienvenido a ",
  Welcome:"Bienvenidos",
  Swipe:"Golpe fuerte",
  Intro_Setup:"Configuración de introducción",
  Introduction:"Introducción",
  CHEQUE_PRINT_CREDIT:"Impulsado por cheques",
  Title_Intro_Word:"Bienvenidos a Cheques",
  Title_Intro:"Introducción de cheques",
  Title_Setup:"Configuración de cheques",
  PayPal_Default_Email_Message:"Ha recibido una nueva transferencia de PayPal. [Powered by Cheques]",
  Cheques_App_Export:"Exportado por Cheques",
  Post_Intro_Thanks:"Gracias por elegir Cheques. El proceso de configuración ahora está completo.",
  Post_Intro_Word:"Comience imprimiendo su primer cheque, agregue un pago futuro o agregue un empleado a la nómina.",
  Upgrade_Required:"Cheques requiere que tengas una versión más premium del software para ocultar este mensaje y desbloquear funciones adicionales.",
  Upgrade_Title:"Cheques",
  Mailout_Prompt:"Además, puede elegir que Checks envíe sus cheques de nómina por correo.",
  Mailed_Cheque:"Cheque enviado por correo: ",
  Mailed_Cheque_Color:"Cheque enviado por correo (color): ",
  Terms_Purchase:"Todas las compras electrónicas con cheques son totalmente reembolsables por hasta 30 días a partir de la fecha de compra. Lea y acepte los <a href='#'>Términos y condiciones</a> antes de continuar.",
  Dashboard_Setup:"Configurar la impresora principal",
  Dashboard_Add:"Agregar la cuenta bancaria principal",
  Dashboard_Customize:"Seleccione una plantilla de cheque",
  Dashboard_Job_Salary:"Cree su trabajo y agregue su salario",
  Dashboard_Employees:"Rastrear empleados y beneficiarios",
  Dashboard_Print:"Imprima y envíe por correo sus cheques de pago",
  Dashboard_Payroll:"Automatiza la impresión de tu nómina",
  Dashboard_PayPal:"Configurar nómina/pagos de PayPal",
  Begin_Setup:"Comenzar configuración",
  Get_Started:"Empezar",
  Purchase:"Compra",
  Lockdown:"Aislamiento",
  Unlock:"Desbloquear",
  Detailed:"Detallado",
  Log_In:"Iniciar sesión",
  Login:"Acceso",
  Launch:"Lanzamiento",
  Register:"Registrarse",
  Finish:"Terminar",
  List:"Lista",
  Weekends:"fines de semana",
  Weekly:"Semanalmente",
  PayPal_Default_Subject:"Nuevo Pago",
  Payment_Message:"Mensaje de pago",
  PayPal_Default_Payment_Note:"Gracias",
  Setup_Your_Cheqing_Account:"Cuenta corriente",
  Add_Your_Primary_Cheqing_Account:"Agregue su cuenta de cheques principal.",
  Welcome_Word:"Simplifique y automatice la gestión de nóminas y recursos humanos.",
  Get_Started_Quickly:"Solo responda algunas preguntas simples que nos ayudarán a comenzar...",
  Done_Intro_Word:"Instalación completa",
  PIN_Protected:"Protegido con contraseña y PIN",
  Enter_New_PIN:"Ingrese un nuevo código PIN:",
  Enter_PIN:"Ingrese el código PIN:",
  Invalid_PIN:"PIN inválido ingresado.",
  Account_Identifier:"Identificador de cuenta",
  New_Account_Identifier:"Identificador de cuenta nueva",
  attempt:"intento",
  attempts:"intentos",
  remaining:"restante",
  Language:"Idioma",
  languages:"Idiomas",
  select_languages:"Seleccione el idioma",
  Deposit:"Depositar",
  Hire_One_Now:"Contrata uno ahora",
  App_Locked:"La aplicación está bloqueada.",
  Skip_:"Saltar",
  Skip_to_Dashboard:"Saltar al panel de control",
  Dashboard:"Tablero de mandos",
  Import:"Importar",
  Admin:"Administradores",
  New_Admin:"Nuevo administrador",
  Settings:"Ajustes",
  Color_Picker:"Selector de color",
  Font_Picker:"Selector de fuente",
  Logout:"Cerrar sesión",
  Close:"Cerca",
  Close_menu:"Cerca",
  Excel:"Archivo Excel",
  Csv:"Archivo CSV",
  Sql:"Archivo SQL",
  Json:"Archivo JSON",
  Url:"Importar por URL",
  Back:"Atrás",
  Timers:"Temporizadores",
  Cheque:"Cheque",
  Print:"Impresión",
  Designs:"Diseños",
  Pause_Printing:"Pausar impresión",
  Resume_Printing:"Reanudar impresión",
  Printing_Paused:"Impresión en pausa",
  PrintingUnavailable:"¡Lo sentimos! La impresión no está disponible en este sistema.",
  Prints_Paused:"Impresiones en pausa",
  Administration:"Administración",
  Loading:"Cargando",
  Unnamed:"Sin nombre",
  error:"Lo sentimos, este cheque no se pudo abrir para verlo.",
  No_Cheques_To_Print:"No hay cheques para imprimir",
  No_Cheques_To_Print_Word:"Crear un <a routerLink='/carpeta/Cheque/New'>Nuevo Cheque</a>.",
  New_Cheque:"Nuevo cheque",
  Start_One_Now:"Comience uno ahora",
  Edit_Cheque:"Editar cheque",
  Select_Cheques:"Seleccionar cheques",
  Select_Employee:"Seleccionar empleado",
  Default_Printer:"Impresora predeterminada",
  Reassign:"Reasignar",
  Configure:"Configurar",
  Template:"Plantilla",
  Designer:"Diseñador",
  Edit_Designs:"Editar diseños",
  New_Design:"Nuevo diseño",
  Next:"Próximo",
  Save:"Ahorrar",
  Name:"Nombre",
  Mail:"Correo",
  Amount:"Monto",
  Date:"Fecha",
  PayPal:"PayPal",
  Payouts:"Pagos",
  PayPal_Label:"Etiqueta de PayPal",
  Email_Username:"Correo electrónico / Nombre de usuario",
  Client_ID:"Identificación del cliente",
  Sandbox_Email:"Correo electrónico de zona de pruebas",
  PayPal_Email:"E-mail de Paypal",
  PayPal_Username:"Nombre de usuario de la API",
  PayPal_Payouts:"Pagos de PayPal",
  Select_PayPal_Key:"Seleccione la clave de PayPal",
  Secret:"Secreto",
  API_Secret:"Secreto API",
  PayPal_API_Keys:"Claves de PayPal",
  New_PayPal_Key:"Nueva clave de PayPal",
  Email_Subject:"Asunto del email",
  Email_Message:"Mensaje de correo electrónico",
  Payout_Options:"Opciones de pago",
  Payment_Note:"Nota de pago",
  Enable_Employee:"Habilitar empleado",
  Enable_Production_Mode:"Habilitar modo de producción",
  Sandbox_Username:"Nombre de usuario de la zona de pruebas",
  Sandbox_Signature:"Firma de la zona de pruebas",
  Sandbox_Password:"Contraseña de la zona de pruebas",
  Production_Username:"Nombre de usuario de producción",
  Production_Signature:"Firma de producción",
  Production_Password:"Contraseña de producción",
  Production_Email:"Correo electrónico de producción",
  API_Client_ID:"ID de cliente de API",
  API_Signature:"Firma API",
  API_Password:"Contraseña API",
  API_Username:"Nombre de usuario de la API",
  Secret_Key:"Llave secreta",
  Sandbox:"Salvadera",
  Production:"Producción",
  Sandbox_Keys:"Claves de la caja de arena",
  Production_Keys:"Claves de producción",
  API_Title:"Título de la API",
  Production_Mode:"Modo de producción",
  Account_Label:"Etiqueta de cuenta",
  No_PayPal_Setup:"Sin clave de PayPal",
  Enable_PayPal_Account:"Habilitar cuenta PayPal",
  No_PayPal_Word:"Agregue su <a routerLink='/carpeta/Factura/Nueva'>Clave API de PayPal</a>.",
  Printed_Memo:"Memorándum impreso",
  Employee:"Empleado",
  View_Employee:"Ver empleado",
  Mailing_Address:"Dirección de envio",
  Company_Address:"Dirección de la empresa",
  Select_Company_Address:"Seleccione la dirección de la empresa",
  Address:"Habla a",
  Any_Day:"Cualquier día",
  Address_Name:"Nombre de la dirección",
  Unit:"Unidad",
  Account:"Cuenta",
  Bank_Account:"Cuenta bancaria",
  Account_Limits:"Habilitar límites de cuenta",
  Payroll:"Nómina de sueldos",
  New_Payroll:"Nueva Nómina",
  No_Payroll:"Sin Nómina Próxima",
  Upcoming_Holiday:"Próximas vacaciones:",
  Invoice_Due:"Factura vencida:",
  New_Invoice:"Nueva Factura",
  No_Invoices:"Sin facturas",
  No_Invoices_Word:"Cree la primera <a routerLink='/carpeta/Factura/Nueva'>Factura</a>.",
  Cheque_Due:"Cheque vencido:",
  Payrolls:"nóminas",
  Sandbox_Mode:"Modo sandbox",
  Hire:"Contratar",
  Pay:"Pagar",
  New:"Nuevo",
  Add:"Agregar",
  Make:"Fabricar",
  Time:"Hora",
  Write:"Escribir",
  Holiday:"Vacaciones",
  Holidays:"Días festivos",
  Next_Holiday:"Siguientes vacaciones:",
  All_Done:"¡Todo listo!",
  Employees:"Empleados",
  Payees:"Beneficiarios",
  Job:"Trabajo",
  Jobs:"Trabajos",
  Invoice:"Factura",
  Invoices:"Facturas",
  Vacations:"Vacaciones",
  Cheques:"Cheques",
  Brand_Cheques:"Marca",
  Payment:"Pago",
  Enable_Payment:"Habilitar pago",
  Payments:"Pagos",
  Schedule:"Calendario",
  Schedules:"Horarios",
  Timesheet:"Hoja de tiempo",
  Timesheets:"Hojas de horas",
  Salary:"Salario",
  New_Address:"Nueva direccion",
  Address_2:"Dirección (Línea 2)",
  _City:"Ciudad",
  _State:"Estado/Prov",
  City:"Ciudad / Municipio",
  State:"Provincia del estado",
  Postal:"Código Postal / ZIP",
  ZIP:"Postal / Código Postal",
  Country:"País",
  Addresses:"Direcciones",
  Required_Options:"Opciones requeridas",
  Optional_Options:"Opciones Opcionales",
  Additional_Options:"Opciones adicionales",
  Required:"Requerido",
  Optional:"Opcional",
  Additional:"Adicional",
  No_Addresses:"Sin direcciones",
  New_Address_Word:"Agregue la primera <a routerLink='/carpeta/Dirección/Nueva'>Dirección</a>.",
  Select_Address:"Seleccionar dirección",
  No_Address:"Sin direcciones",
  Print_Cheque:"Imprimir cheque",
  Print_Cheque_Now:"Imprimir cheque ahora",
  Description:"Descripción",
  Pay_To_The_Order_Of:"Pagar a la orden de:",
  Select_Date_Range:"Seleccionar rango de fechas",
  Select_Starting_Date:"Seleccionar fecha de inicio",
  Select_Ending_Date:"Seleccionar fecha de finalización",
  Select_Date:"Seleccione fecha",
  Cheque_Date:"Comprobar Fecha",
  Cheque_Memo:"Comprobar nota",
  Blank_Cheque:"Cheque en blanco",
  Blank:"Blanco",
  No_Cheques:"Sin cheques",
  No_Cheques_Word:"Imprime tu primer <a routerLink='/folder/Cheque/New'>Ελεγχος</a>.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"Ver imagen",
  View:"Vista",
  Modify:"Modificar",
  Delete:"Borrar",
  Cheque_Paid:"Pagado",
  New_Deduction:"Nueva Deducción",
  Title:"Título",
  Frequency:"Frecuencia",
  Hourly:"Cada hora",
  Daily:"A diario",
  Weekdays:"días laborables",
  BiWeekly:"2 semanas",
  TriWeekly:"3 semanas",
  Monthly:"Mensual",
  MiMonthly:"2 meses",
  Quarterly:"Trimestral",
  Yearly:"Anual",
  Every_Week:"Cada semana",
  Every_Payroll:"Cada Nómina",
  Every_Month:"Cada mes",
  Annually:"Anualmente",
  Always_Scheduled:"Siempre Programado",
  Start_Date:"Fecha de inicio",
  End_Date:"Fecha final",
  Start_Time:"Hora de inicio",
  End_Time:"Hora de finalización",
  Deduction_Label:"Etiqueta de deducción",
  Notes:"Notas",
  Options:"Opciones",
  Enable:"Permitir",
  Select_Deductions:"Seleccionar Deducciones",
  Deductions:"Deducciones",
  No_Deductions:"Sin deducciones",
  No_Deductions_Word:"Cree su primera <a routerLink='/carpeta/Deducción/Nueva'>Deducción</a>.",
  New_Employee:"Nuevo empleado",
  No_Title:"Sin título",
  _Name:"Nombre",
  About_Yourself:"Acerca de ti mismo",
  Full_Name:"Nombre completo",
  Birthday:"Cumpleaños",
  Email:"Correo electrónico",
  SMS:"SMS",
  Phone:"Teléfono",
  Test:"Prueba",
  Call:"Llamar",
  Fax:"Fax",
  Printed_Note:"Nota impresa",
  Position:"Posición",
  Job_Position:"Puesto de trabajo",
  Select_a_Job:"Seleccione un trabajo",
  Select_a_Salary:"Seleccione un salario",
  Add_a_Deduction:"Agregar una deducción",
  Taxes:"Impuestos",
  Add_Taxes:"Añadir Impuestos",
  Date_of_Birth:"Fecha de nacimiento",
  Email_Address:"Dirección de correo electrónico",
  Phone_Number:"Número de teléfono",
  Phone_Call:"Llamada telefónica",
  Enable_on_Payroll:"Habilitar en Nómina",
  Select_Employees:"Seleccionar Empleados",
  No_Employees:"Sin empleados",
  No_Employees_Word:"Agregue su primer nuevo <a routerLink='/carpeta/Empleado/Nuevo'>Empleado</a>.",
  No_Name:"Sin nombre",
  Unemployeed:"Desempleados",
  Employeed:"empleado",
  Paid:"Pagado",
  Enabled:"Activado",
  Disabled:"Discapacitado",
  Fire:"Fuego",
  Not_Available:"No disponible",
  Not_Available_Word:"Imprime tu primera <a routerLink='/folder/Invoice/New'>Factura</a> y recibe el pago.",
  Select_Invoices:"Seleccionar_Facturas",
  Print_Invoice_Word:"Imprime tu primera <a routerLink='/folder/Invoice/New'>Factura</a> y recibe el pago.",
  Invoice_Title:"Título de la factura",
  Invoice_Date:"Fecha de la factura",
  Due_Date:"Fecha de vencimiento",
  New_Job:"Nuevo trabajo",
  Details:"Detalles",
  Customize:"Personalizar",
  Customize_Dashboard:"Personalizar panel",
  Components:"Componentes",
  No_Components:"Sin componentes",
  Main_Components:"Componentes principales",
  Smaller_Components:"Componentes más pequeños",
  Error_Loading_Page:"Error al cargar esta página.",
  Bank_Details:"Detalles del banco",
  About_Your_Job:"Sobre tu trabajo",
  Your_Schedule:"Tu agenda",
  Job_Title:"Título profesional",
  Job_Description:"Descripción del trabajo",
  Job_Details:"Detalles del trabajo",
  Enable_Job:"Habilitar trabajo",
  Pay_Period:"Período de pago",
  Perpetually_Schedule:"Programación perpetua",
  Select_Jobs:"Seleccionar trabajos",
  No_Jobs:"No trabajos",
  Add_Jobs:"Agregar trabajos",
  No_Jobs_Word:"Agregue el primer <a routerLink='/carpeta/Trabajo/Nuevo'>Trabajo</a> a la lista.",
  Count_Employees:"job.employee_count+'Empleados'",
  Zero_Employees:"0 empleados",
  New_Leave:"Nueva licencia",
  Leave_Name:"Dejar Nombre",
  Paid_Leave:"Vacaciones pagadas",
  Leave_Pay:"Dejar Pagar",
  Indefinite_Leave:"licencia indefinida",
  Indefinite_Payroll:"Nómina Indefinida",
  Leave:"Salir",
  Add_Schedules:"Añadir Horarios",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Habilitar Ausencia",
  Select_Leaves:"Seleccionar hojas",
  No_Leaves:"Sin permisos de ausencia",
  Leave_Of_Absence:"Excedencia",
  Leaves_Of_Absence:"Permisos de ausencia",
  New_Leave_of_Absense:"Nueva excedencia",
  No_Leaves_Word:"Agregue el primer <a routerLink='/folder/Leave/New'>Permiso de ausencia</a>.",
  Not_Enabled:"No disponible",
  Absences:"Ausencias",
  Payee:"Tenedor",
  New_Payee:"Nuevo beneficiario",
  Payee_Identifier:"Identificador del Beneficiario",
  Payee_Name:"Nombre del beneficiario",
  Payee_Title:"Título del beneficiario",
  Payment_Memo:"Memorándum de pago",
  Select_Payees:"Seleccionar beneficiarios",
  No_Payees:"Sin beneficiarios",
  Add_Payee_Note:"Agregue el primer <a routerLink='/carpeta/Beneficiario/Nuevo'>Beneficiario</a>.",
  New_Payees:"Nuevos beneficiarios",
  About_The_Payment_Schedule:"Calendario de pago",
  Your_Payroll_Schedule:"Nómina Automática",
  New_Payment:"Nuevo Pago",
  Identifier:"Identificador",
  Select:"Seleccione",
  Memo:"Memorándum",
  Payment_Date:"Fecha de pago",
  Mark_as_Paid:"Marcar como pagado",
  Select_Payments:"Seleccionar Pagos",
  No_Payments:"Sin pagos",
  No_Payments_Word:"Cree el primer <a routerLink='/carpeta/Pago/Nuevo'>Pago</a>.",
  Create_Payroll:"Crear Nómina",
  Properties:"Propiedades",
  Payroll_Title:"Título de Nómina",
  Payroll_Notes:"Notas de nómina",
  Payroll_Setup:"Configuración de nómina",
  Tabulate_Payments:"Tabular Pagos",
  Tabulate:"Tabular",
  By:"Por:",
  Payments_By:"Pagos por",
  Timesheets_And_Schedules:"Hojas de tiempo y horarios",
  Both:"Ambos",
  Items:"Elementos",
  Add_Payees:"Agregar Beneficiarios",
  Add_Account:"Añadir cuenta",
  Enable_Account:"Habilitar cuenta",
  Enable_Payroll:"Habilitar Nómina",
  Print_Payroll:"Imprimir Nómina",
  No_Payrolls:"Sin Nómina",
  No_Payroll_Word:"Cree su primera <a routerLink='/folder/Payroll/New'>Κατάσταση μισθοδοσίας</a>.",
  View_more:"VER MÁS",
  Less:"MENOS",
  Add_Payroll:"Añadir Nómina",
  Select_Payroll:"Seleccionar Nómina",
  About_Your_Salary:"Acerca de tu salario",
  Add_Salaries:"Añadir Salarios",
  Add_Salary:"Agregar Salario",
  Salaries:"Salarios",
  No_Salaries:"Sin salarios",
  No_Salaries_Word:"Agregue el primer <a routerLink='/carpeta/Salario/Nuevo'>Salario</a>.",
  Select_Salaries:"Seleccionar Salarios",
  New_Salary:"Nuevo Salario",
  Salary_Name:"Identificador de salario",
  Enable_Salary:"Habilitar salario",
  Salary_Amount:"Cantidad del salario",
  New_Schedule:"Nuevo horario",
  Schedule_Title:"Título del horario",
  Add_Address:"Añadir dirección",
  Repeat:"Repetir",
  Design:"Diseño",
  Edit_Design:"Editar diseño",
  Edit_this_Design:"Editar este diseño",
  Repeat_Payment:"Repetir Pago",
  Enable_Schedule:"Habilitar horario",
  Never:"Nunca",
  Select_Schedule:"Seleccionar Horarios",
  No_Schedules:"Sin Horarios",
  No_Schedules_Word:"Cree el primer <a routerLink='/folder/Schedule/New'>Horario</a>.",
  New_Administrator:"Nuevo Administrador",
  Username:"Nombre de usuario",
  First_Name:"Primer nombre",
  Last_Name:"Apellido",
  Add_an_Address:"Añadir una dirección",
  Payment_Limit:"Límite por pago",
  Total_Limit:"Límite total",
  Select_Accounts:"Seleccionar Cuentas",
  No_Administrators:"Sin administradores",
  No_Administrators_Word:"Cree la primera <a routerLink='/carpeta/Administrador/Nueva'>Cuenta de administrador</a>.",
  New_Administrators_Word:"Agregue el primer <a routerLink='/carpeta/Administrador/Nuevo'>Administrador</a>",
  Cloud:"Nube",
  Backup:"Respaldo",
  Enable_iCloud:"Habilitar iCloud",
  Enable_Google_Drive:"Habilitar Google Drive",
  Enable_Microsoft_OneDrive:"Habilitar Microsoft OneDrive",
  Automatically_Backup:"Copia de seguridad automática",
  FTP_Settings:"Configuración FTP",
  URL_File_Prompt:"Por favor, especifique la ubicación para importar un archivo .xls/.xlsx/.json:",
  URL_SQL_Prompt:"Por favor, especifique la ubicación del archivo SQLite para importar:",
  FTP_Backup:"Copia de seguridad FTP",
  FTP_Import:"Importación FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Anfitrión",
  Port:"Puerto",
  Path:"Sendero",
  Data_Path:"Ruta de datos",
  Enable_FTP_Account:"Habilitar cuenta FTP",
  HostnameIP:"Nombre de host",
  Password:"Contraseña",
  Connection_Port:"Puerto de conexión",
  Enable_MySQL_Database:"Habilitar base de datos MySQL",
  Log:"Tronco",
  Reset:"Reiniciar",
  Erase:"Borrar",
  Export:"Exportar",
  Database:"Base de datos",
  Upload_CSV:"Subir CSV",
  Download_CSV:"Descargar CSV",
  SQL_Database:"URL SQL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Copia de seguridad MySQL",
  Internal_Notes:"Notas internas",
  Root_Path:"Ruta raíz",
  Select_Backup:"Seleccionar copia de seguridad",
  Add_New_Backup:"Agregar una nueva copia de seguridad",
  First_Backup:"Configurar la primera copia de seguridad...",
  Backups:"Copias de seguridad",
  Add_Backup:"Añadir copia de seguridad",
  No_Backups:"No se pueden encontrar copias de seguridad.",
  Select_Backup_Type:"Seleccione el tipo de copia de seguridad que desea configurar...",
  Backup_Type:"Tipo de copia de seguridad",
  FTP_Drive:"Unidad FTP",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Conducir",
  Microsoft_OneDrive:"Conducir",
  Import_Fields:"Importar Campos",
  Import_Fields_Word:"Use esta sección para seleccionar <a routerLink='/carpeta/Vacaciones/Nuevo'>Importar</a> datos.",
  Upload:"Subir",
  Download:"Descargar",
  Download_JSON:"Descargar como datos JSON",
  Download_SQL:"Descargar como archivo SQL",
  New_Bank:"Nuevo Banco",
  New_Account:"Nueva cuenta",
  New_Bank_Account:"Nueva Cuenta Bancaria",
  Upload_Image:"Cargar imagen",
  Bank_Name:"Nombre del banco",
  Bank_Address:"Dirección del banco",
  Branch_Address:"Dirección de sucursal",
  Address_on_Cheque:"Habla a",
  Cheque_Numbers:"Números de verificación",
  Cheque_Details:"Ver detalles",
  Bank_Logo:"Logotipo del banco",
  Cheque_ID:"Comprobar identificación",
  Starting_Cheque_ID:"Iniciando verificación de identificación",
  Transit_Number:"Número de Tránsito",
  Institution_Number:"Número de institución",
  Designation_Number:"Número de designación",
  Account_Number:"Número de cuenta",
  Limits:"Límites",
  Default_Limits:"Límites predeterminados",
  Over_Limit:"Sobre el limite",
  Under_Limit:"Por debajo del límite",
  Payroll_Limit:"Límite de Nómina",
  Enable_Bank_Account:"Habilitar cuenta bancaria",
  Select_Account:"Seleccionar cuenta",
  No_Bank_Account:"Sin cuenta bancaria",
  No_Bank_Account_Word:"Agregue la primera <a routerLink='/carpeta/Cuentas/Nueva'>Cuenta bancaria</a>.",
  Bank_Accounts:"Cuentas bancarias",
  No_Accounts:"Sin cuentas",
  No_Accounts_Note:"Agregue la primera <a routerLink='/carpeta/Cuentas/Nueva'>Cuenta bancaria</a>.",
  Cheque_Designer:"Diseñador de cheques",
  Cheque_Design:"Diseño de cheques",
  Select_Design:"Seleccione un diseño",
  Cheque_Designs:"Diseños de cheques",
  No_Cheque_Designs:"Sin diseños de cheques",
  No_Cheque_Designs_Word:"Cree su propio <a routerLink='/folder/Settings/Cheque/Design/New'>Έλεγχος σχεδίασης</a>.",
  Set_Default:"Establecer por defecto",
  Default:"Defecto",
  Remove:"Eliminar",
  Folder:"Carpeta",
  Edit:"Editar",
  LoadingDots:"Cargando...",
  Add_a_New_File:"Agregar un <a href='#' (clic)='add()'>Nuevo archivo</a> a",
  this_folder:"esta carpeta",
  FTP_Backup_Settings:"Configuración de copia de seguridad de FTP",
  Secure_File_Transfer:"Transferencia segura de archivos",
  New_Holiday:"Nuevas vacaciones",
  Add_Holiday:"Añadir vacaciones",
  Holiday_Name:"Nombre de vacaciones",
  Additional_Pay:"Pago Adicional",
  Enable_Holiday:"Habilitar vacaciones",
  Select_Holidays:"Seleccionar días festivos",
  No_Holidays:"Sin vacaciones",
  No_Holidays_Word:"Agregue el primer <a routerLink='/folder/Holiday/New'>Día festivo</a>.",
  New_Import:"Nueva importación",
  Import_Data:"Datos de importacion",
  Import_Data_Word:"Seleccione el tipo de archivo o el método de carga de su elección.<br /> Podrá seleccionar los campos importados en un archivo que correspondan a cualquier parámetro en la aplicación después de cargar un archivo compatible",
   
  Import_File:"Importar archivo",
  Link_To_File:"Enlace a archivo",
  Select_File:"Seleccione Archivo",
  New_Moderator:"Nuevo moderador",
  Allow_Moderation:"Permitir moderación",
  Create_Paycheques:"Crear cheques de pago",
  Edit_Paycheques_and_Data:"Editar cheques de pago y datos",
  Destroy_Data_and_Paycheques:"Destruir datos y cheques de pago",
  Bonus_Percentage:"Porcentaje de cheque de pago",
  Fixed_Amount:"Cantidad fija",
  Select_Moderator:"Seleccionar moderador",
  No_Moderators:"Sin moderadores",
  Moderators:"Moderadores",
  Moderator_Account:"Cree la primera <a routerLink='/carpeta/Administrador/Nueva'>Cuenta de moderador</a>.",
  No_Moderators_Word:"Agregue el primer <a routerLink='/carpeta/Administrador/Nuevo'>Moderador</a>.",
  Defaults:"Predeterminados",
  Provide_Us_Info:"Proporcione información",
  Setup_Your_Printer:"Configura tu impresora",
  Your_Company:"Acerca de su empresa",
  Company_Name:"Nombre de empresa",
  Currency:"Divisa",
  Default_Currency:"Moneda predeterminada",
  Base_Monthly_Income:"Ingreso mensual",
  Protection:"Proteccion",
  App_Protection:"Protección de aplicaciones",
  PIN_Code_Protection:"Protección de código PIN",
  App_Protection_Word:"Habilite métodos de seguridad que ayuden a proteger su cuenta.",
  PIN_Code:"Código PIN",
  Change_PIN:"Cambiar PIN",
  New_Password:"Nueva contraseña",
  Geofence_Protection:"Protección de Geo-Cerca",
  Geofence_Area:"Establecer área",
  Distance:"Distancia",
  Setup_Now:"Configurar ahora",
  Mile:"Milla",
  Km:"Km",
  m:"metro",
  Facial_Recognition:"Reconocimiento facial",
  Face:"Rostro",
  Setup:"Configuración",
  Label:"Etiqueta",
  Password_Protection:"Protección de contraseña",
  Modify_Password:"Modificar la contraseña",
  New_Tax_Entry:"Nueva Entrada Tributaria",
  New_Tax:"Nuevo Impuesto",
  Tax_Label:"Etiqueta de impuestos",
  Perpetually_Enabled:"Habilitado perpetuamente",
  Select_Taxes:"Seleccionar Impuestos",
  Tax_Deductions:"Deducciones Fiscales",
  No_Tax_Deductions:"Sin deducciones fiscales",
  No_Tax_Deductions_Word:"Agregue la primera deducción de <a routerLink='/folder/Tax/New'>Φόρος</a>.",
  New_Timer:"Nuevo temporizador",
  Start:"Comienzo",
  Stop:"Detener",
  Start_Timer:"Iniciar temporizador",
  Stop_Timer:"Detener temporizador",
  Timer_Active:"Temporizador activo",
  Timer:"Temporizador:",
  Timer_Running:"Temporizador: (en ejecución)",
  Save_Timer:"Guardar temporizador",
  New_Timesheet:"Nueva hoja de tiempo",
  Select_Timesheets:"Seleccionar hojas de tiempo",
  Work_Notes:"Notas de trabajo",
  Entry_Title:"Título de entrada",
  No_Timesheets:"Sin hojas de tiempo",
  No_Timesheets_Word:"Agregue la primera <a routerLink='/folder/Timesheet/New'>Hoja de horas</a>.",
  Timesheet_Submitted:"Hoja de tiempo enviada",
  Timesheet_Congrats:"¡Felicitaciones! Su hoja de tiempo se ha enviado con éxito. ¡Gracias!",
  Timesheet_Copy:"Para recibir una copia de sus documentos, proporciónenos su dirección de correo electrónico y/o número de teléfono móvil.",
  Submit:"Entregar",
  Allow_Notifications:"Permitir notificaciones",
  Untitled_Entry:"Nueva entrada",
  Untitled_Bank:"Banco sin título",
  Timesheet_Entry:"Entrada de hoja de tiempo",
  Work_Description:"Descripción del trabajo",
  Enable_Timesheet:"Habilitar hoja de tiempo",
  Submit_Timesheet:"Enviar hoja de tiempo",
  Vacation:"Vacaciones",
  New_Vacation:"Nuevas vacaciones",
  Vacation_Name:"Nombre de vacaciones",
  Paid_Vacation:"Vacaciones pagas",
  Vacation_Pay:"Pago de vacaciones",
  Enable_Vacation:"Habilitar vacaciones",
  Select_Vacations:"Seleccionar Vacaciones",
  No_Vacations:"Sin vacaciones",
  No_Vacations_Word:"Cree la primera entrada <a routerLink='/folder/Vacation/New'>Διακοπές</a>.",
  Payroll_Schedule:"Calendario de nómina",
  Next_Payroll:"Siguiente Nómina:",
  Upcoming_Payroll:"Nómina Próxima",
  No_Upcoming_Payroll:"Sin Nómina Próxima",
  Address_Book:"Directorio",
  Archived_Documents:"Documentos Archivados",
  Dev_Mode:"Aplicación en modo de desarrollo",
  Administrators:"Administradores",
  Privacy:"Intimidad",
  None:"Ninguna",
  Select_Signature:"Seleccionar Firma",
  No_Signatures:"Sin firmas",
  No_Signatures_Word:"Agregue la primera <a routerLink='/carpeta/Firma/Nueva'>Firma</a>.",
  Repeat_Indefinitely:"Repetir indefinidamente",
  Sign:"Firmar",
  Sign_Here:"Firma aqui",
  Date_Signed:"Fecha de firma",
  Signature_Pad:"Bloc de firmas",
  Account_Holder:"Titular de la cuenta",
  Account_Properties:"Propiedades de la cuenta",
  Name_On_Cheque:"Nombre en el cheque",
  Server_Hostname:"Nombre de host del servidor/IP",
  Printers:"Impresoras",
  No_Printers:"Sin impresoras",
  Printer_Exists:'Ya existe una impresora con este nombre.',
  No_Printers_Word:"Agregue la primera <a routerLink='/carpeta/Impresora/Nueva'>Impresora</a>.",
  No_Printer_Alert:"No se ha definido ninguna impresora. ¿Desea configurar una impresora?",
  Add_Printer:"Añadir Impresora",
  New_Printer:"Impresora nueva",
  Printer_Hostname:"Nombre de host/IP de la impresora",
  Printer_Label:"Etiqueta de impresora",
  Printer_Protocol:"Protocolo de impresora",
  Protocol:"Protocolo",
  Email_Print:"Correo electrónico",
  AirPrint:"Impresión aérea",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Enchufe",
  Print_Job:"Trabajo de impresión",
  Printed:"Impreso",
  Not_Printed:"No impreso",
  Print_Jobs:"Trabajos de impresión",
  Print_Queue:"Cola de impresión",
  No_Print_Jobs:"Sin trabajos de impresión",
  No_Prints:"Cree un nuevo <a routerLink='/carpeta/Cheque/New'>Ελεγχος</a> para imprimir.",
  Prints:"Huellas dactilares",
  Find_Printer:"Buscar impresora",
  Find_AirPrint_Devices:"Buscar dispositivos AirPrint",
  Select_Printer:"Seleccionar Impresora",
  System_UI:"IU del sistema",
  Printer:"Impresora",
  Status:"Estado",
  Preview:"Avance",
  Enable_Print_Job:"Habilitar trabajo de impresión",
  Queue_Weight:"Peso de cola",
  Unlimited:"Ilimitado",
  Show_Advanced_Printer_Options:"Mostrar opciones avanzadas de impresora",
  Advanced:"Avanzado",
  Location:"Ubicación",
  Note:"Nota",
  Queue_Name:"Nombre de la cola",
  Pages_Printed_Limit:"Límite de páginas impresas",
  MultiPage_Idle_Time:"Tiempo de espera multipágina (s)",
  Page_Count_Limit:"Límite de recuento de páginas",
  Page_Orientation:"Orientación de la página",
  Portrait:"Retrato",
  Landscape:"Paisaje",
  Duplex:"Dúplex",
  Double_Sided_Printing:"Doble cara",
  Duplex_Mode:"Modo dúplex",
  Duplex_Short:"Corto",
  Duplex_Long:"Largo",
  Duplex_None:"Ninguna",
  Color_And_Quality:"Color Y Calidad",
  Monochrome:"Monocromo",
  Photo_Quality_Prints:"Impresiones de calidad fotográfica",
  Printer_Email:"Correo electrónico de la impresora",
  Automatically_Downsize:"Reducir tamaño automáticamente",
  Paper:"Papel",
  Paper_Name:"Nombre de papel",
  Paper_Width:"Ancho de papel",
  Paper_Height:"Altura del papel",
  Paper_Autocut_Length:"Longitud de corte automático de papel",
  Margins:"Márgenes",
  Page_Margins:"Márgenes de página",
  Page_Margin_Top:"Margen de página superior",
  Page_Margin_Right:"Margen de página derecho",
  Page_Margin_Bottom:"Margen inferior de la página",
  Page_Margin_Left:"Margen de página izquierdo",
  Add_Employees:"Añadir Empleados",
  Header:"Encabezamiento",
  Print_A_Page_Header:"Imprimir un encabezado de página",
  Header_Label:"Etiqueta de encabezado",
  Header_Page_Index:"Índice de página de encabezado",
  Header_Font_Name:"Fuente de encabezado",
  Select_Font:"Seleccionar fuente",
  Font_Selector:"Selector de fuente",
  Header_Font_Size:"Fuente de encabezado",
  Header_Bold:"Encabezado en negrita",
  Header_Italic:"Encabezado cursiva",
  Header_Alignment:"Alineación de encabezados",
  Left:"Izquierda",
  Center:"Centrar",
  Right:"Correcto",
  Justified:"Justificado",
  Header_Font_Color:"Color del encabezado",
  Select_Color:"Seleccionar el color",
  Footer:"Pie de página",
  Print_A_Page_Footer:"Imprimir un pie de página",
  Footer_Label:"Etiqueta de pie de página",
  Footer_Page_Index:"Índice de página de pie de página",
  Footer_Font_Name:"Fuente de pie de página",
  Fonts:"Fuentes",
  Done:"Hecho",
  Select_Fonts:"Seleccionar fuentes",
  Footer_Font_Size:"Tamaño de pie de página",
  Footer_Bold:"Pie de página en negrita",
  Footer_Italic:"Pie de página en cursiva",
  Footer_Alignment:"Alineación de pie de página",
  Footer_Font_Color:"Color de pie de página",
  Page_Copies:"Copias de página",
  Enable_Printer:"Habilitar impresora",
  Remote_Logging:"Registro remoto",
  Log_Server:"Servidor de registro",
  Encryption:"Cifrado",
  Random_Key:"Clave aleatoria",
  Encryption_Key:"Clave de encriptación",
  Cheques_Webserver:"Base de datos personalizada",
  Learn_How:"Aprender cómo",
  Signature:"Firma",
  Default_Printer_Unit:"pulgadas/cm/mm/(pt)",
  View_Signature:"Ver firma",
  Printed_Signature:"Firma impresa",
  Digitally_Sign:"Firmar digitalmente",
  Digital_Signature:"Firma digital",
  Add_Signature:"Agregar firma",
  Add_Your_Signature:"Agregue su firma",
  Enable_Signature:"Habilitar firma",
  Digitally_Signed:"Firmado digitalmente",
  Insert_Error:"No se pudo guardar el cheque debido a problemas con la base de datos.",
  Delete_Confirmation:"¿Está seguro de que desea eliminar esta información?",
  Discard_Confirmation:"¿Estás seguro de que quieres descartar esta información?",
  Discard_Bank_Confirmation:"¿Estás seguro de que quieres descartar esta cuenta?",
  Discard_Printer_Confirmation:"¿Está seguro de que desea descartar esta impresora?",
  Delete_Bonus_Confirmation:"¿Está seguro de que desea eliminar este bono?",
  Delete_Invoice_Confirmation:"¿Está seguro de que desea eliminar esta factura?",
  Generated_Cheque:"Cheque generado",
  Generated_Invoice:"Factura Generada",
  Schedule_Start_Time:"Programar inicio",
  Schedule_End_Time:"Fin del horario",
  New_Call:"Nueva llamada",
  No_Contacts:"Sin contactos",
  No_Contacts_Word:"Los administradores, moderadores, empleados y beneficiarios aparecen como contactos.",
  PDF_Subject:"finanzas",
  PDF_Keywords:"nómina cheque pago PDF cheque cheques",
  Printer_Setup:"Configuración de la impresora",
  Printer_Selection:"Selección de impresora",
  New_Fax:"Nuevo Fax",
  New_Fax_Message:"Nuevo mensaje de fax",
  Fax_Machine:"Máquina de fax",
  Fax_Name:"Nombre de fax",
  Fax_Email:"Correo electrónico de fax",
  Fax_Number:"Número de fax",
  Contents:"Contenido",
  Fax_Body:"Cuerpo de página",
  Header_Word:"Encabezamiento:",
  Header_Text:"Texto de cabecera",
  Include_Header:"Incluir encabezado",
  Include_Footer:"Incluir pie de página",
  Footer_Word:"Pie de página:",
  Footer_Text:"Texto de pie de página",
  Attach_a_Cheque:"Adjunte un cheque",
  Add_Deduction:"Añadir Deducción",
  Enable_Fax:"Enviar fax",
  Select_Fax:"Seleccionar Fax",
  No_Faxes:"Sin faxes",
  Faxes:"Faxes",
  Save_and_Send:"Enviar fax",
  Save_and_Pay:"Ahorra y paga",
  WARNING:"ADVERTENCIA:",
  Remember:"Recordar",
  Printing:"Impresión",
  Printing_Complete:"¡Impresión completa!\n\n",
  of:"de",
  There_Were:"Había ",
  Successful_Prints:"impresiones exitosas y",
  Unsuccessful_Prints:" impresiones fallidas.",
  PrinterError:"Lo sentimos, ha habido un error.",
  Printing_:"Impresión...",
  PrinterSuccess:"Documento impreso correctamente.",
  PrintersSuccess:"Documentos impresos correctamente.",
  Print_Cheques:"Imprimir cheques",
  New_Message:"Nuevo mensaje",
  New_Messages:"Nuevos mensajes",
  Read_Message:"Leer el mensaje",
  Write_Message:"Escribe un mensaje",
  Send_Message:"Enviar mensaje",
  Subject:"Sujeto",
  Message:"Mensaje",
  Writing:"Escribiendo...",
  Send:"Enviar",
  Set_Date:"Establece la fecha",
  Set_Time:"Fijar tiempo",
  Recieve:"Recibir",
  Set_as_Default:"Establecer por defecto",
  Default_Account:"Cuenta predeterminada",
  Default_Design:"Diseño predeterminado",
  Multiple_Cheques:"Cheques (Triple)",
  Account_Mode:"Modo de cuenta",
  Multiple_Cheques_Description:"Tres cheques por página.",
  Check_and_Table:"Cheque y tabla",
  Check_including_Table:"Cuadro de cheques y contabilidad.",
  Check_Mailer:"Comprobar correo",
  Check_Mailer_Window:"Comprobar con una ventana de dirección.",
  DocuGard_Table_Top:"DocuGard Έλεγχος και πίνακας (Arriba)",
  DocuGard_Table_Middle:"DocuGard Έλεγχος και πίνακας (Medio)",
  DocuGard_Table_Bottom:"Comprobación y tabla de DocuGard (abajo)",
  DocuGard_Mailer_Top:"DocuGard Ταχυδρομημένη επιταγή (arriba)",
  DocuGard_Mailer_Middle:"DocuGard Ταχυδρομημένη επιταγή (Medio)",
  DocuGard_Mailer_Bottom:"DocuGard Ταχυδρομημένη επιταγή (abajo)",
  DocuGard_Three_Cheques:"Controles DocuGard (Triple)",
  DocuGard_Cheque_Top:"Comprobación de DocuGard (arriba)",
  DocuGard_Cheque_Middle:"Comprobación de DocuGard (centro)",
  DocuGard_Cheque_Bottom:"Comprobación de DocuGard (abajo)",
  DocuGard_Three_Cheques_Window:"Tres cheques en una página.",
  DocuGard_Table_Top_Window:"Tabla de cheques y ganancias.",
  DocuGard_Table_Middle_Window:"Tabla de cheques y ganancias.",
  DocuGard_Table_Bottom_Window:"Tabla de cheques y ganancias.",
  DocuGard_Mailer_Top_Window:"Cheque, mesa y dirección.",
  DocuGard_Mailer_Middle_Window:"Cheque, mesa y dirección.",
  DocuGard_Mailer_Bottom_Window:"Cheque, mesa y dirección.",
  DocuGard_Cheque_Top_Window:"Buscar papel seguro.",
  DocuGard_Cheque_Middle_Window:"Buscar papel seguro.",
  DocuGard_Cheque_Bottom_Window:"Buscar papel seguro.",
  Basic_Cheque:"Verificar (Arriba)",
  Basic_Cheque_Print:"Imprimir un solo cheque.",
  Error_Setting_As_Paid:"Error al configurar como pagado",
  Add_Attachment:"Añadir un adjunto",
  PrinterUnavailable:"Impresora no disponible",
  CreditCardComponent:"Tarjetas",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Nuevo Depósito",
  Deposits:"Depósitos",
  No_Deposits:"Sin depósitos",
  Credit_Card_Deposit:"Tarjeta de crédito",
  New_Credit_Card_Deposit_Message:"Depósito con tarjeta de crédito",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Depósito de Bitcoin",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Transferencia Interac",
  Payments_Limit:"Límite de pago",
  No_Payment_Limit:"Sin límite de pago",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Depósito de PayPal",
  No_Deposits_Word:"Agregue la primera empresa <a routerLink='/folder/Deposit/New'>Κατάθεση</a>.",
  No_Documents_Specified:"No se especificaron documentos para imprimir",
  No_Printers_Added:"No se encontraron impresoras. Vaya a Configuración > Impresoras para agregar una.",
  DB_Erase_Prompt:"¿Borrar completamente toda la base de datos? ADVERTENCIA: ¡Perderás toda la información almacenada!",
  Console_Warning:"No pegues ningún texto en esta consola. Puedes ponerte a ti y/o a tu empresa en grave riesgo.",
  No_Faxes_Word:"Cree el primer <a routerLink='/carpeta/Fax/Nuevo'>Φαξ</a>.",
  Cheque_Delete_Confirmation:"¿Está seguro de que desea eliminar este cheque?",
  Design_Delete_Confirmation:"¿Está seguro de que desea eliminar este diseño?",
  Cheque_Pay_Confirmation:"¿Marcar este cheque como pagado? NO aparecerá en la cola de impresión.",
  Payment_Pay_Confirmation:"¿Marcar este pago como pagado? NO aparecerá en la cola de cheques.",
  Delete_Deduction_Confirmation:"¿Está seguro de que desea eliminar esta deducción?",
  Delete_Job_Confirmation:"¿Está seguro de que desea eliminar este trabajo?",
  Delete_Timesheet_Confirmation:"¿Está seguro de que desea eliminar esta hoja de tiempo?",
  Delete_Schedule_Confirmation:"¿Estás seguro de que quieres eliminar este horario?",
  Delete_Setting_Confirmation:"¿Está seguro de que desea restablecer esta configuración?",
  Delete_Fax_Confirmation:"¿Está seguro de que desea eliminar este fax?",
  Delete_File_Confirmation:"¿Está seguro de que desea eliminar este archivo?",
  Delete_Vacation_Confirmation:"¿Seguro que quieres borrar estas vacaciones?",
  Delete_Printer_Confirmation:"¿Está seguro de que desea eliminar esta impresora?",
  Remove_Design_Confirmation:"¿Está seguro de que desea eliminar este diseño?",
  Delete_Payroll_Confirmation:"¿Está seguro de que desea eliminar esta nómina?",
  Send_Fax_Email_Confirmation:"¿Desea enviar por fax y correo electrónico estos documentos?",
  Send_Email_Confirmation:"¿Quieres enviar este documento por correo electrónico?",
  Send_Fax_Confirmation:"¿Quiere enviar por fax este documento?",
  Error_Generating_PDF:"Lo sentimos. Hubo un error al generar este documento.",
  PDF_Error_Saving_Image:"Lo sentimos. Hubo un error al guardar la imagen PDF de este documento.",
  Test_Printer_Confirmation:"¿Desea imprimir una página de prueba en esta impresora?",
  Save_Timesheet_Prompt:"Agregue un 'Título' o presione 'Iniciar temporizador' para guardar.",
  Remove_Geofence_Prompt:"¿Está seguro de que desea eliminar la ubicación de esta geo-cerca?",
  Delete_Employee_Confirmation:"Estas seguro que quieres borrarlo ",
  Fire_Employee_Confirmation:"¿Estás seguro de que quieres disparar?"
}