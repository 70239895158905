export const Mn = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Зохиогчийн эрх & хуулбар; 2023 он",
  black:"Хар",
  green:"Ногоон",
  gold:"Алт",
  blue:"Цэнхэр",
  brown:"Бор",
  purple:"Нил ягаан",
  pink:"Ягаан",
  red:"Улаан",
  Swatches:"Свэтчүүд",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Өнгө",
  Saturation:"Ханалт",
  Lightness:"Хөнгөн байдал",
  Upgrade_To_Pro:"Pro руу шинэчлэх",
  AllFeaturesInclude:"Бүх онцлогууд нь:",
  PrintUnlimitedCheques:"Хязгааргүй чек хэвлэх",
  PremiumChequeDesigns:"Дээд зэрэглэлийн чекийн загвар",
  ManageUnlimitedEmployees:"Хязгааргүй ажилчдыг удирдах",
  AddUnlimitedPayees:"Хязгааргүй төлбөр авагч нэмэх",
  CreateUnlimitedPayrolls:"Хязгааргүй цалингийн жагсаалт үүсгэх",
  UnlimitedSchedulesandTimeSheets:"Хязгааргүй хуваарь, цагийн хуудас",
  BulkPayPalPayouts:"Бөөнөөр PayPal төлбөр",
  UnlimitedBankAccounts:"Хязгааргүй банкны данс",
  ManageMultipleCompanies:"Олон компанийг удирдах",
  TrackInsurancePolicies:"Даатгалын бодлогыг хянах",
  Bio_MetricProtection:"Био-метрийн хамгаалалт",
  Geo_FenceLock_OutProtection:"Гео-хашаа түгжих хамгаалалт",
  Multiple_Companies_Word:"Олон компанийг удирдах нь Checks урамшуулалгүйгээр боломжгүй.",
  PayPal_Payouts_Word:"PayPal төлбөрийг Чекийн хураамжгүйгээр идэвхгүй болгосон.",
  PINProtection:"ПИН хамгаалалт",
  PasswordProtection:"Нууц үгийн хамгаалалт",
  May_Require_Approval:"Зөвшөөрөл шаардаж магадгүй.",
  Subscribe:"Бүртгүүлэх",
  Billed:"Тооцоо хийсэн",
  Up:"Дээшээ",
  Down:"Доош",
  Positioning:"Байршил",
  Marker:"Тэмдэглэгч",
  Drag_Marker:"Тэмдэглэгчийг чирэх",
  Tagline:"Чек хэвлэх, Цалингийн хүснэгтийг гаргах",
  Marker_Word:"Элементийн хэмжээг тогтоохын тулд тэмдэглэгээг ашиглана уу.",
  Pinch_Zoom:"Томруулахыг хавчих",
  Pinch_Word:"Элементийг томруулахын тулд хавчих.",
  Drag:"Чирэх",
  Drag_Word:"Элементүүдийг чирхийн тулд хуруугаа ашиглана уу.",
  subscription_alias_word:"Бүртгэлийг автоматаар шинэчлэх",
  premium_alias_word:"Нэг удаагийн шинэчлэлтийн багц",
  print_alias_word:"Хувь хүний чекийг хэвлэх",
  mode_alias_word:"Горим",
  Pro:"Pro",
  Pro_word:"Pro хувилбар шаардлагатай.",
  Cant_Delete_Default_Company_Word:"Уучлаарай, та үндсэн компаниа устгах боломжгүй .",
  Reinsert_Default_Designs:"Өгөгдмөл загваруудыг дахин оруулна уу",
  Reinsert_Default_Designs_word:"Та өгөгдмөл загваруудыг дахин оруулахыг хүсэж байна уу?",
  Subscription_Active_Disable_Word:"Энэ захиалга идэвхтэй байна. Та Чекийн энэ захиалгыг цуцлахыг хүсэж байна уу?",
  Company_Logo:"Компанийн лого",
  ZERO_:"ТЭГ",
  Disclaimer:"Хариуцлага",
  Privacy_Policy:"Нууцлалын бодлого",
  EULA:"EULA-г шалгана",
  Terms_Of_Service:"Үйлчилгээний нөхцөл",
  Terms_Of_Use:"Хэрэглэх нөхцөл",
  Refunds:"Буцаан олголтын бодлого",
  Single_Print:"1 шалгах",
  Two_Prints:"2 Шалгалт",
  Five_Prints:"5 Шалгалт",
  Ten_Prints:"10 шалгалт",
  Fifteen_Prints:"15 Шалгалт",
  Twenty_Prints:"20 шалгалт",
  Thirty_Prints:"30 шалгалт",
  Image_Added:"Зураг нэмсэн",
  Image_Preview:"Зургийг урьдчилан харах",
  No_Image_Was_Selected:"Ямар ч зураг сонгогдоогүй.",
  Cheques_Unlimited:"Шалгалт Хязгааргүй",
  _Subscription:"Захиалга",
  Subscription:"Шалгалт - 1 сар",
  Two_Month_Subscription:"Шалгалт - 2 сар",
  Three_Month_Subscription:"Шалгалт - 3 сар",
  Six_Month_Subscription:"Шалгалт - 6 сар",
  Twelve_Month_Subscription:"Шалгалт - 12 сар",
  Cheques_Are_Available:"Чек хэвлэх боломжтой.",
  Upgrade_Required_Two:"Худалдан авалтаа эхлүүлэхийн тулд багцаа сонгоод үнийн товчлуур дээр хоёр товшино уу. Мэргэжлийн харагдахуйц өнгөт чекүүдийг хэдхэн секундын дотор хэвлээрэй.",
  Month:"Сар",
  Due:"Хугацаа:",
  Expires:"Дуусах хугацаа:",
  Subscription_Active:"Захиалга идэвхтэй",
  Subscription_Inactive:"Захиалга идэвхгүй байна",
  Purchase_Additional_Cheques:"Нэмэлт чек худалдаж авах уу?",
  Printable_Cheque:"Хэвлэх боломжтой чек",
  Printable_Cheques:"Хэвлэх боломжтой чекүүд",
  Printable_Cheque_Word:"чек таны дансанд боломжтой.",
  Printable_Cheques_Word:"Таны дансанд чек авах боломжтой.",
  Max_Amount_Message:"Таны заасан хэмжээ энэ системд тогтоосон дээд хэмжээнээс хэтэрсэн байна:",
  Terms_Required_Word:"Та чекийг үргэлжлүүлэн ашиглахын өмнө энэхүү гэрээг зөвшөөрөх ёстой.",
  Subscriptions:"Захиалга",
  Product_Upgrades:"Шинэчлэлтүүд",
  Mailed_Out_Cheques:"Шуудангаар илгээсэн чекүүд",
  Enter_A_Company_Name:"Компанийн нэрийг оруулна уу.",
  Single_Cheques:"Нэг удаагийн шалгалт",
  Cheque_Golden:"Алтан чек",
  Cheque_Golden_Window:"Алтан чек дизайн.",
  Cheque_Green:"Ногоон чек",
  Cheque_Green_Window:"Ногоон чекийн загвар.",
  Cheque_Red:"Улаан чек",
  Cheque_Red_Window:"Улаан чекийн загвар.",
  Cheque_Yellow:"Шар чек",
  Cheque_Yellow_Window:"Шар чек дизайн.",
  Cheque_Statue_of_Liberty:"Эрх чөлөөний хөшөө",
  Cheque_Statue_of_Liberty_Window:"Эрх чөлөөний хөшөөний дизайныг шалгана.",
  Cheque_Green_Wave:"Ногоон давалгаа",
  Cheque_Green_Wave_Window:"Ногоон чекийн загвар.",
  Cheque_Golden_Weave:"Алтан сүлжмэл",
  Cheque_Golden_Weave_Window:"Гоёмсог алтан чек загвар.",
  Cheque_Green_Sun:"Ногоон нар",
  Cheque_Green_Sun_Window:"Гүнзгий, тайвшруулах чек дизайн.",
  Cheque_Blue_Checkers:"Цэнхэр даам",
  Cheque_Blue_Checkers_Window:"Цэнхэр чек дизайн.",
  Cashiers_Check:"Кассын чек",
  Cashiers_Check_Window:"Кассын чек маягийн загвар.",
  Cheque_Aqua_Checkers:"Усан даам",
  Cheque_Aqua_Checkers_Window:"Aqua чек дизайн.",
  Cheque_Golden_Checkers:"Алтан даам",
  Cheque_Golden_Checkers_Window:"Алтан чек дизайн.",
  Upgrade_Unavailable:"Шинэчлэлт хийх боломжгүй",
  Bank_Code_Protection:"Банкны дугаарын хамгаалалт",
  Bank_Code_Protection_Word:"Өөр төхөөрөмж эсвэл өөр хэрэглэгч дээр ажиллаж байгаа энэ аппликешн дээр өөрийн банкны дугаарыг ашиглахаас хамгаалаарай. Энэ үйлдэл нь эргэлт буцалтгүй юм. Үргэлжлүүлэх үү?",
  Bank_Code_Protection_Blocked_Word:"Таны ашиглах гэж буй банкны дугаарууд өөр хэрэглэгч эсвэл төхөөрөмжид зориулагдсан болно.",
  Bank_Code_Protection_Error_Word:"Дугаар баталгаажуулалт амжилтгүй боллоо. Интернетэд холбогдоод энэ банкны дансыг дахин нэмж оролдоно уу.",
  Bank_Code_Protection_Set_Error_Word:"Банкны дугаарын хамгаалалт нь таныг интернетэд холбогдсон байхыг шаарддаг. Холбоод дахин оролдоно уу.",
  Upgrade_Unavailable_Word:"Уучлаарай, бид таныг баталгаажуулахад асуудал гарлаа. Танай бүс нутагт Чекийн захиалга болон сайжруулалтыг одоогоор худалдан авах боломжгүй байна.",
  PayPal_Payment_Preview:"PayPal төлбөрийн урьдчилж харах",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"PayPal сонгоно уу",
  PayPal_Applications:"PayPal програмууд",
  Purchase_With_Apple_Pay:"Apple Pay ашиглан худалдаж аваарай",
  Google_Pay:"Google Pay",
  Companies:"Компаниуд",
  Insurance:"Даатгал",
  New_PayPal:"Шинэ PayPal",
  Pay_By:"Төлбөр төлнө",
  Insure:"Даатгах",
  Miles:"Миль",
  Payment_Method:"Төлбөрийн нөхцөл",
  Select_Policies:"Бодлого сонгоно уу",
  Policies:"Бодлого",
  Policy:"Бодлого",
  No_PayPal_Account:"PayPal данс байхгүй",
  No_Policies:"Даатгалын бодлого байхгүй",
  New_Policy:"Шинэ бодлого",
  PayPal_Payment:"PayPal төлбөр",
  PayPal_Payments:"PayPal төлбөр",
  No_Payment_Selected:"Төлбөрийг сонгоогүй",
  Sending_Payment_Word:"Түр хүлээнэ үү... Энэ төлбөрийг илгээж байна.",
  Sending_Payments_Word:"Түр хүлээнэ үү... Төлбөрийг илгээж байна.",
  No_Payment_Selected_PayPal:"<a routerLink='/folder/Payments'> PayPal төлбөр</a> сонгогдоогүй байна.",
  Payment_Sent:"Төлбөр илгээсэн",
  PayPal_Payment_Sent:"Энэ төлбөрийг PayPal-р илгээсэн.",
  Copay:"Копай",
  Dead:"Үхсэн",
  Alive:"Амьд",
  Not_Dead:"Үхээгүй",
  Unclaimed:"Нэхэмжлэлгүй",
  Attempted:"оролдсон",
  Deceased:"Нас барсан",
  Claimed:"Нэхэмжлэгдсэн",
  Unpaid:"Төлбөргүй",
  Sending_Payment:"Төлбөр илгээж байна",
  Sending_Payments:"Төлбөр илгээх",
  Send_PayPal_Confirmation:"Та энэ гүйлгээг PayPal-р илгээхийг хүсэж байна уу?",
  Send_PayPal_Confirmation_Word:"Энэ гүйлгээг илгээхийн тулд ногоон товчийг дарна уу.",
  Save_Payment_As_Unpaid:"Энэ төлбөрийг төлөөгүй гэж хадгалах уу?",
  Payment_Pay_Confirmation_PayPal:"Энэ төлбөрийг төлсөн гэж хадгалах уу?",
  No_Policies_Word:"Эхний <a routerLink='/folder/Postage/New'> Даатгалын бодлогыг</a> одоо нэмнэ үү .",
  Timesheet_Multiple_Delete_Confirmation:"Та олон цагийн хуудсыг устгахдаа итгэлтэй байна уу?",
  Select_Multiple_Timesheets_Prompt:"Цагийн хуудас сонгоогүй байна. Дор хаяж нэг цагийн хуудсыг сонгоно уу.",
  Select_Multiple_Policies_Prompt:"Ямар ч бодлого сонгоогүй. Дор хаяж нэг даатгалын бодлогыг сонго.",
  Policies_Multiple_Delete_Confirmation:"Та олон бодлогыг устгахдаа итгэлтэй байна уу?",
  Company:"Компани",
  Add_Company:"Компани нэмэх",
  New_Company:"Компани нэмэх",
  No_Companies:"Компани байхгүй",
  Enable_Company:"Компанийг идэвхжүүлэх",
  Select_Company:"Компани сонгох",
  The_Default_Company:"Анхдагч компанийн шошго.",
  Manage_Companies:"Компаниудыг удирдах",
  No_Companies_Word:"Чекүүд нь анхдагч компанийг ашиглана .<br /> <a routerLink='/folder/Company/New'> эхний компани</a>-г нэмнэ үү .",
  Default_Company:"Үндсэн компани",
  Cheques_Unlimited_Word:"Checks Unlimited нь танд хэдэн ч чек хэвлэх боломжийг олгоно.",
  Cheques_Subscription_Word:"Чекийн захиалга нь танд хүссэн хэмжээгээр чек хэвлэх боломжийг олгоно.",
  You_Own_This_Product:"Та энэ бүтээгдэхүүнийг эзэмшдэг.",
  Your_Subscription_is_Active:"Таны захиалга идэвхтэй байна.",
  Active_Products:"Идэвхжүүлсэн бүтээгдэхүүн",
  Purchase_Confirmation:"Худалдан авалт",
  Purchase_Cheques:"Худалдан авалтын чекүүд",
  Restore_Purchase:"Худалдан авалт нөхөн сэргээх",
  Erase_Purchase:"Худалдан авалтыг устгах",
  Successfully_Purchased:"Амжилттай худалдаж авлаа",
  Enter_Your_Licence_Key:"Энэ бүтээгдэхүүнийг идэвхжүүлэхийн тулд энэ хуудсанд лицензийн түлхүүрээ оруулна уу.",
  Licence_Key:"Лицензийн түлхүүр",
  Enter_Licence_Key:"Лицензийн түлхүүрийг оруулна уу",
  Purchased:"Худалдан авсан",
  Enable_Feature:"Функцийг идэвхжүүлэх",
  Cancel_Subscription:"Захиалга цуцлах",
  Subscription_Removed:"Бүртгэлийг устгасан",
  Select_Active_Subscription:"Өөрчлөхийн тулд идэвхтэй захиалгыг сонгоно уу.",
  Remove_Subscription_Word:"Та энэ захиалгыг цуцлахдаа итгэлтэй байна уу?",
  Delete_Company_Confirmation:"Та энэ компанийг бүхэлд нь устгахдаа итгэлтэй байна уу? АНХААРУУЛГА: Та бүх хадгалагдсан мэдээллээ алдах болно!",
  Delete_Default_Company_Word:"Та үндсэн компанийг устгах боломжгүй . Та аппликешныг дахин тохируулж, үндсэн төлөвт нь оруулахыг хүсэж байна уу? АНХААРУУЛГА: Та бүх хадгалагдсан мэдээллээ алдах болно!",
  Console_Warning_2:"Одоогоор таных биш энэ программыг ашиглан ямар ч валюттай харьцаж болохгүй.",
  Terms_and_Conditions:"Үйлчилгээний нөхцөл",
  and_the:"болон",
  for:"төлөө",
  Please_Read_Word:"-ийг уншаад зөвшөөрнө үү",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Зарим валют хөрвүүлэх ханшийг олж чадсангүй. Интернетэд холбогдоно уу.",
  Free:"Үнэгүй",
  DB_Erase_Prompt_2:"Хөгжүүлэгчийн мэдээллийн санг бүхэлд нь устгах уу? АНХААРУУЛГА: Та ХУДАЛДАН АВАХ, ЗАХИАЛАХ бүх мэдээллээ алдах болно!",
  Successfully_Imported:"Амжилттай импортолсон",
  Select_Postage:"Шуудангийн төлбөрийг сонгоно уу",
  No_Postage:"Шуудангийн марк байхгүй",
  No_Paid_Postage_Word:"Эхний <a routerLink='/folder/Postage/New'> Төлбөртэй шуудангийн</a> маркийг нэмнэ үү .",
  Trial_Complete:'Туршилт дууссан',
  Please_Upgrade:'Үргэлжлүүлэн хэвлэхийн тулд Checks-г шинэчилнэ үү.',
  Aa:"Аа",
  Color:"Өнгө",
  Font:"Фонт",
  Guide:"Хөтөч",
  Guides:"Хөтөч",
  Image:"Зураг",
  Zoom:"Томруулах",
  Logo:"Лого",
  Bank:"Банк",
  MICR:"MICR",
  Total:"Нийт",
  Cancel:"Цуцлах",
  Confirm:"Баталгаажуулах",
  Method:"Арга",
  Biometric_Security:"Биометрийн аюулгүй байдал",
  Please_Login_To_Continue:"Үргэлжлүүлэхийн тулд нэвтэрнэ үү.",
  Complete:"Бүрэн",
  Sign_Up:"Бүртгүүлэх",
  Error:"Алдаа",
  Biometrics:"Био хэмжигдэхүүн",
  Percent:"Хувь",
  Zero_Percent:"0%",
  Top_Margin:"Дээд зах",
  Bottom_Margin:"Доод зах",
  Left_Margin:"Зүүн зах",
  Right_Margin:"Баруун зах",
  MICR_Margin:"MICR Margin",
  Table_Margin:"Хүснэгтийн захын зай",
  Address_Margin:"Хаягийн зах",
  Percentage_:"Хувь",
  Vacation_Title:"Амралтын гарчиг",
  Use_PIN:"ПИН код ашиглана уу",
  Loading__:"Ачааж байна...",
  Design_Title:"Дизайн гарчиг",
  Authorize:"Зөвшөөрөх",
  Key:"Түлхүүр",
  Public_Key:"Нийтийн түлхүүр",
  Private_Key:"Хувийн түлхүүр",
  Authenticate:"Баталгаажуулах",
  Last_Payroll:"Сүүлийн цалин",
  Last_Calculation:"Сүүлийн тооцоо",
  Authorized:"Зөвшөөрөгдсөн",
  Geo_Authorized:"Газарзүйн байршил: Зөвшөөрөгдсөн",
  Not_Authorized:"Зөвшөөрөлгүй",
  Authorization_Complete:"Зөвшөөрөл дууссан",
  Geolocation_Authorization:"Газарзүйн байршлын зөвшөөрөл",
  Out_of_Bounds:"Хязгааргүй",
  Cant_Delete_Default_Design:"Өгөгдмөл загварыг устгах боломжгүй.",
  Unauthorized_Out_of_Bounds:"Зөвшөөрөгдөөгүй: Хязгааргүй",
  Biometric_Authorization:"Биометрийн зөвшөөрөл",
  Locating_Please_Wait:"Байршуулж байна, түр хүлээнэ үү...",
  Test_Biometrics:"Био хэмжигдэхүүнийг турших",
  Cheque_Margins:"Margins шалгах",
  Percentage_Full_Error:"Хувийн талбарыг 100% хувиас дээш тогтоох боломжгүй.",
  No_Payroll_Text:"<a routerLink='/folder/Employee/New'>Ажилтан</a> эсвэл <an routerLink='/folder/Payee/New'>Төлбөр хүлээн авагч</a> болон <a routerLink='/folder/Schedule/New'>Хуваарь</a>.",
  Design_Saved:"Дизайныг хадгалсан",
  Default_Design_Selected:"Өгөгдмөл загварыг сонгосон",
  Partial_Import:"Хэсэгчилсэн импорт",
  Partial_Export:"Хэсэгчилсэн экспорт",
  Entire_Import:"Бүтэн импорт",
  Entire_Export:"Бүтэн экспорт",
  Existing_Password:"Одоо байгаа нууц үгээ оруулна уу:",
  Existing_PIN:"Одоо байгаа ПИН кодоо оруулна уу:",
  Pin_Change_Header:"ПИН баталгаажуулалт",
  Pin_Change_SubHeader:"Өөрчлөлтийг баталгаажуулахын тулд хуучин ПИН дугаараа оруулна уу.",
  Pass_Change_Header:"Нууц үгийн баталгаажуулалт",
  Pass_Change_SubHeader:"Өөрчлөлтийг баталгаажуулахын тулд хуучин нууц үгээ оруулна уу.",
  PIN_Enter_Message:"Баталгаажуулахын тулд PIN кодоо оруулна уу.",
  Password_Enter_Message:"Баталгаажуулахын тулд нууц үгээ оруулна уу.",
  Pin_Updated_Success:"ПИН кодыг амжилттай шинэчилсэн!",
  Pin_Updated_Fail:"ПИН кодыг шинэчилж чадсангүй.",
  Pass_Updated_Success:"Нууц үг амжилттай шинэчлэгдсэн.",
  Pass_Updated_Fail:"Нууц үгийг шинэчлэх боломжгүй байна.",
  Preview_Payment:"Төлбөрийг урьдчилан харах",
  Preview_Payroll:"Цалингийн хуудсыг урьдчилан харах",
  No_Payments_Created:"Төлбөр үүсгэгдээгүй байна.",
  Payment_Preview:"Төлбөрийг урьдчилан харах",
  Enable_Payee:"Төлбөр хүлээн авагчийг идэвхжүүл",
  Rendered:"Үзүүлсэн",
  No_Email:"Имэйл байхгүй",
  Setup_Cheques:"Setup Checks",
  name:"Нэр",
  address:"Хаяг",
  address_2:"Хаягийн мөр 2",
  city:"Хот",
  province:"муж",
  postal_code:"Шуудангийн / ZIP код",
  country:"Улс",
  username:"Хэрэглэгчийн нэр",
  full_name:"Бүтэн нэр",
  birthday:"Төрсөн өдөр",
  email:"Имэйл",
  phone:"Утас",
  employees:"Ажилчид",
  addresses:"Хаяг",
  payment_amount_limit:"Төлбөрийн үнийн хязгаар",
  total_limit:"Нийт хязгаар",
  payees:"Төлбөр төлөгчид",
  description:"Тодорхойлолт",
  address_line_one:"Хаягийн нэгдүгээр мөр",
  address_line_two:"Хаягийн хоёр дахь мөр",
  image:"Зураг",
  account_holder:"Данс эзэмшигч",
  cheque_starting_id:"Эхлэх ID-г шалгана уу",
  transit_number:"Транзит дугаар",
  institution_number:"Байгууллагын дугаар",
  designation_number:"Зориулалтын дугаар",
  account_number:"Дансны дугаар",
  currency:"Валют",
  signature:"Гарын үсэг",
  payment_payroll_limit:"Төлбөрийн хязгаар",
  total_limi:"Нийт хязгаар",
  date:"Огноо",
  printed_memo:"Хэвлэсэн санамж",
  banks:"Банкууд",
  signature_image:"Гарын үсэг зурсан зураг",
  address_name:"Хаягийн нэр",
  notes:"Тэмдэглэл",
  design:"Дизайн",
  title:"Гарчиг",
  frequency:"Давтамж",
  fax:"Факс",
  salaries:"Цалин",
  salary_ids:"Цалингийн үнэмлэх",
  start_time:"Эхлэх цаг",
  end_time:"Дуусах цаг",
  paid:"Төлбөртэй",
  overtime_percentage:"Төлбөртэй хувь",
  overtime_amount:"Тогтмол төлбөр төлсөн",
  first_name:"Нэр",
  last_name:"Овог",
  moderation:"Зохицуулах",
  create:"Үүсгэх",
  edit:"Засварлах",
  destroy:"Устгах",
  day_start_time:"Өдрийн_эхлэх_цаг",
  day_end_time:"Өдрийн_төгсгөлийн_цаг",
  fullname:"Нэр",
  time:"Цаг хугацаа",
  auto_send:"Автоматаар илгээх",
  time_method:"Цагийн арга",
  schedules:"Хуваарь",
  indefinite_payroll_enabled:"Тодорхойгүй хугацаагаар идэвхжүүлэх",
  amount:"Дүн",
  repeat:"Давт",
  always_enabled:"Үргэлж идэвхжүүлсэн",
  start_date:"Эхлэх өдөр",
  end_date:"Дуусах огноо",
  Cheque_Total:"Нийт шалгах",
  Total_Amount:"Нийт дүн:",
  Amounts:"Дүн:",
  Images:"Зураг",
  Files:"Файлууд",
  Previewing:"Урьдчилан үзэж байна:",
  Insert:"Оруулах",
  Preview_Import:"Импортыг урьдчилан харах",
  Entry:"Нэвтрэх",
  Entries:"Бичлэгүүд",
  No_Entries:"Оруулга байхгүй",
  Import_Type:"Импортын төрөл",
  Select_Details:"Дэлгэрэнгүйг сонгоно уу",
  Select_Payee:"Төлбөр авагчийг сонгоно уу",
  Enable_Holidays:"Амралтын өдрүүдийг идэвхжүүлнэ үү",
  Disable_Holidays:"Амралтын өдрүүдийг идэвхгүй болгох",
  Wire_Transfer:"Утас шилжүүлэг",
  New_Export:"Шинэ экспорт",
  Export_Data:"Өгөгдлийг экспортлох",
  Export_Data_Word:"Экспорт болон татаж авах файлын төрлийг сонгоно уу.",
  Export_File:"Файл экспортлох",
  Mode:"Горим",
  Times:"Цаг хугацаа",
  Widgets:"Виджетүүд",
  Slider:"Слайдер",
  Set_Details:"Дэлгэрэнгүйг тохируулах",
  Select_Type:"Төрөл сонгоно уу",
  Display_Slider:"Гулсагчийг харуулах",
  Dashboard_Slider:"Хяналтын самбарын гулсагч",
  Dashboard_Mode:"Хяналтын самбарын горим",
  Show_Intro:"Танилцуулга харуулах",
  Show_Payrolls:"Цалингийн жагсаалтыг харуулах",
  Show_Holidays:"Баярын өдрүүдийг харуулах",
  Show_Invoices:"Нэхэмжлэхийг харуулах",
  Show_Cheques:"Чекүүдийг харуулах",
  Enabled_Widgets:"Идэвхжүүлсэн виджетүүд",
  Disabled_Widgets:"Идэвхгүй виджетүүд",
  Colors:"Өнгө",
  Barcodes:"Бар код",
  View_Timers:"Цаг хэмжигчийг харах",
  Gradients:"Градиент",
  No_Info:"Мэдээлэл байхгүй",
  Disable:"Идэвхгүй болгох",
  Show_Layer:"Давхаргыг харуулах",
  Hide_Layer:"Давхаргыг нуух",
  Text_Layer:"Текстийн давхаргууд",
  Secondly:"Хоёрдугаарт",
  Minutely:"Хоромхон зуур",
  nine_am:"Өглөөний 9:00",
  five_pm:"17:00",
  Enable_Address:"Хаяг идэвхжүүлэх",
  Invalid_File_Type:"Уучлаарай, буруу файлын төрлийг сонгосон байна. Дэмжигдсэн файлын төрөл:",
  Error_Updating_Entry:"Уучлаарай, энэ оруулгыг шинэчлэхэд алдаа гарлаа.",
  Schedule_Timing_Alert:"Алдаа: Хуваарийн эхлэх цагийг дуусах хугацааны дараах утгаар тохируулсан.",
  Select_Multiple_Payments_Prompt:"Сонгосон төлбөр байхгүй. Дор хаяж нэг төлбөр сонгоно уу.",
  Select_Multiple_Cheques_Prompt:"Сонгосон чек байхгүй. Дор хаяж нэг чек сонгоно уу.",
  Select_Multiple_Items_Prompt:"Сонгосон зүйл алга. Дор хаяж нэг зүйл сонгоно уу.",
  Paymemt_Multiple_Delete_Confirmation:"Та олон төлбөрийг устгахдаа итгэлтэй байна уу?",
  Cheque_Multiple_Delete_Confirmation:"Та олон чекийг устгахдаа итгэлтэй байна уу?",
  Payee_Multiple_Delete_Confirmation:"Та олон төлбөр авагчийг устгахдаа итгэлтэй байна уу?",
  Employee_Multiple_Delete_Confirmation:"Та олон ажилтныг устгахдаа итгэлтэй байна уу?",
  MICR_Warning:"Анхаар: Зарим принтер болон төхөөрөмжүүд MICR фонтыг зөв харуулахгүй байж магадгүй.",
  Automatically_Send:"Автоматаар илгээх",
  Type:"Төрөл",
  Payment_Type:"Төлбөрийн хэлбэр",
  Auto_Send:"Автомат илгээх",
  Automatically_Process:"Автоматаар боловсруулах",
  Auto_Process:"Автомат процесс",
  Image_Based:"Зураг дээр суурилсан",
  Font_Based:"Фонт дээр суурилсан",
  Rerender:"Дахин дүрслэх",
  Rendering:"Тайлбарлах",
  Render:"Файлууд",
  Top:"Топ",
  Middle:"Дунд",
  Bottom:"Доод талд",
  Top_Left:"Зүүн дээд",
  Top_Center:"Топ төв",
  Top_Right:"Баруун дээд",
  Middle_Left:"Зүүн дунд",
  Middle_Center:"Дунд төв",
  Middle_Right:"Баруун дунд",
  Bottom_Left:"Зүүн доод",
  Bottom_Center:"Доод төв",
  Bottom_Right:"Баруун доод",
  Numbers:"Тоонууд",
  Verified:"Баталгаажсан",
  Paper_Size:"Цаасны хэмжээ",
  New_Device:"Шинэ төхөөрөмж",
  Add_Device:"Төхөөрөмж нэмэх",
  Remove_Device:"Төхөөрөмжийг устгах",
  Delete_Device:"Төхөөрөмжийг устгах",
  Block_Device:"Төхөөрөмжийг блоклох",
  Block:"Блоклох",
  Unblock:"Блокыг тайлах",
  Table:"Хүснэгт",
  Scan_Login_Code:"Нэвтрэх кодыг сканнердах",
  Login_Code:"Нэвтрэх код",
  Scan_Code:"Код сканнердах",
  Scan_QR_Code:"QR кодыг сканнердах",
  Select_All:"Бүгдийг сонгох",
  Deselect_All:"Бүгдийг сонгоно уу",
  Increase:"Өсөх",
  Decrease:"Буурах",
  Bold:"Зоригтой",
  Text:"Текст",
  Style:"Загвар",
  Italic:"Налуу",
  QR_Code:"QR код",
  Barcode:"Бар код",
  Browse_Images:"Зураг үзэх",
  Browse_Files:"Файлуудыг үзэх",
  Background_Image:"Арын зураг",
  Logo_Image:"Логоны зураг",
  Header_Image:"Толгойн зураг",
  Bank_Image:"Банкны зураг",
  Cut_Lines:"Зүссэн шугамууд",
  Background:"Суурь",
  License:"Тусгай зөвшөөрөл",
  One_License:"1 Лиценз:",
  Licensed:"Лицензтэй:",
  Not_Licensed:"Лицензгүй",
  Enter_Serial:"Цувралыг оруулна уу",
  Serial_Key:"Цуваа түлхүүр",
  Serial:"Цуврал",
  Product_Key:"Бүтээгдэхүүний түлхүүр",
  Check_Product_Key:"Бүтээгдэхүүний түлхүүрийг шалгана уу",
  Add_Product_Key:"Бүтээгдэхүүний түлхүүр нэмэх",
  Verifying_Purchase:"Худалдан авалтыг баталгаажуулж байна...",
  Print_Envelope:"Дугтуй хэвлэх",
  Envelope:"Дугтуй",
  Thank_You:"Баярлалаа!",
  Scale:"Масштаб",
  Print_Scale:"Хэмжээ хэвлэх",
  Borders:"Хил",
  VOID:"ХҮЧИНГҮЙ",
  Void_Cheque:"Шалгалтыг хүчингүй болгох",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ТӨЛБӨРИЙГ ЗАХИАЛГАА:",
  NO_DOLLARS:"ДОЛЛАР БАЙХГҮЙ ",
  ONE_DOLLAR:"НЭГ ДОЛЛАР",
  DOLLARS:" ДОЛЛАР",
  AND:" БА ",
  CENTS:" цент.",
  NO_:"ҮГҮЙ ",
  ONE_:"НЭГ ",
  AND_NO_:"БА ҮГҮЙ ",
  _AND_ONE_:"БА НЭГ ",
  DOLLARS_AND_ONE_CENT:" БА НЭГ ЦЕНТ.",
  AND_NO_CENTS:" БА ТЭГ ЦЕНТ.",
  CHEQUE_PRINT_DATE:"огноо:",
  CHEQUE_PRINT_MEMO:"САНАМЖ:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_RINT_MEMO:"САНАМЖ:",
  CHEQUE_PRINT_SIGNATUREMP:"УИХ-ын гишүүн",
  Initial_Tasks:"Анхны даалгавар",
  Inital_Setup:"Анхны тохиргоо",
  Welcome_To:"Тавтай морил ",
  Welcome:"Тавтай морил",
  Swipe:"Шудрах",
  Intro_Setup:"Танилцуулгын тохиргоо",
  Introduction:"Танилцуулга",
  CHEQUE_PRINT_CREDIT:"Чекээр ажилладаг",
  Title_Intro_Word:"Чекүүдэд тавтай морилно уу",
  Title_Intro:"Чекийн танилцуулга",
  Title_Setup:"Чекийн тохиргоо",
  PayPal_Default_Email_Message:"Та шинэ PayPal шилжүүлэг хүлээн авлаа. [Чекээр дэмжигдсэн]",
  Cheques_App_Export:"Чекээр экспортолсон",
  Post_Intro_Thanks:"Чекийг сонгосонд баярлалаа. Тохиргоог хийж дууслаа.",
  Post_Intro_Word:"Эхний чекээ хэвлэх, ирээдүйн төлбөрийг нэмэх эсвэл цалингийн жагсаалтад ажилчдаа нэмэх замаар эхлээрэй.",
  Upgrade_Required:"Чек нь энэ мессежийг нууж, нэмэлт функцуудыг нээхийн тулд програм хангамжийн илүү дээд зэргийн хувилбарыг эзэмшихийг шаарддаг.",
  Upgrade_Title:"Чек",
  Mailout_Prompt:"Та цалингийн чекээ Checks-ээр нэмэлтээр сонгож болно.",
  Mailed_Cheque:"Шуудангийн чек:",
  Mailed_Cheque_Color:"Шуудангийн чек (өнгөт):",
  Terms_Purchase:"Чек бүхий бүх цахим худалдан авалтыг худалдан авсан өдрөөс хойш 30 хүртэлх хоногийн хугацаанд бүрэн буцаан олгох боломжтой. Үргэлжлүүлэхээсээ өмнө <a href='#'>Үйлчилгээний нөхцөлийг</a> уншиж, зөвшөөрнө үү.",
  Dashboard_Setup:"Үндсэн хэвлэгчийг тохируулах",
  Dashboard_Add:"Анхдагч банкны данс нэмэх",
  Dashboard_Customize:"Шалгах загвар сонгох",
  Dashboard_Job_Salary:"Таны Иов үүсгэх & цалин нэмэх",
  Dashboard_Employees:"Ажилчид ба төлбөр авагчдыг хянах",
  Dashboard_Print:"Цалингаа хэвлэж, шуудангаар илгээнэ үү",
  Dashboard_Payroll:"Цалингаа хэвлэх ажлыг автоматжуулах",
  Dashboard_PayPal:"PayPal Цалин / Төлбөрийг тохируулах",
  Begin_Setup:"Тохиргоог эхлүүлэх",
  Get_Started:"Эхэлцгээе",
  Purchase:"Худалдан авах",
  Lockdown:"Тусгаарлалт",
  Unlock:"Түгжээ тайлах",
  Detailed:"Дэлгэрэнгүй",
  Log_In:"Нэвтрэх",
  Login:"Нэвтрэх",
  Launch:"Эхлэх",
  Register:"Бүртгүүлэх",
  Finish:"Дуусгах",
  List:"Жагсаалт",
  Weekends:"Амралтын өдрүүд",
  Weekly:"Долоо хоног бүр",
  PayPal_Default_Subject:"Шинэ төлбөр",
  Payment_Message:"Төлбөрийн мессеж",
  PayPal_Default_Payment_Note:"Баярлалаа",
  Setup_Your_Cheqing_Account:"Данс шалгах",
  Add_Your_Primary_Cheqing_Account:"Үндсэн чекийн дансаа нэмнэ үү.",
  Welcome_Word:"Цалин, хүний ​​нөөцийн удирдлагыг хялбарчлах, автоматжуулах.",
  Get_Started_Quickly:"Биднийг эхлүүлэхэд туслах хэдэн энгийн асуултанд хариулна уу...",
  Done_Intro_Word:"Тохиргоо дууссан",
  PIN_Protected:"Нууц үг & ПИН хамгаалагдсан",
  Enter_New_PIN:"Шинэ ПИН код оруулна уу:",
  Enter_PIN:"ПИН код оруулна уу:",
  Invalid_PIN:"Буруу ПИН оруулсан байна.",
  Account_Identifier:"Бүртгэл танигч",
  New_Account_Identifier:"Шинэ данс танигч",
  attempt:"оролдолт",
  attempts:"оролдлого",
  remaining:"үлдсэн",
  Language:"Хэл",
  languages:"Хэлнүүд",
  select_languages:"Хэл сонгох",
  Deposit:"Хадгаламж",
  Hire_One_Now:"Одоо нэгийг нь ажилд ав",
  App_Locked:"Програм түгжигдсэн байна.",
  Skip_:"Алгасах",
  Skip_to_Dashboard:"Хяналтын самбар руу алгасах",
  Dashboard:"Хяналтын самбар",
  Import:"Импорт",
  Admin:"Администраторууд",
  New_Admin:"Шинэ админ",
  Settings:"Тохиргоо",
  Color_Picker:"Өнгө сонгогч",
  Font_Picker:"фонт сонгогч",
  Logout:"Гарах",
  Close:"Хаах",
  Close_menu:"Хаах",
  Excel:"Excel файл",
  Csv:"CSV файл",
  Sql:"SQL файл",
  Json:"JSON файл",
  Url:"URL-ээр импортлох",
  Back:"Буцах",
  Timers:"Таймер",
  Cheque:"Шалгах",
  Print:"Хэвлэх",
  Designs:"Дизайн",
  Pause_Printing:"Хэвлэхийг түр зогсоох",
  Resume_Printing:"Хэвлэхийг үргэлжлүүлэх",
  Printing_Paused:"Хэвлэхийг түр зогсоосон",
  PrintingUnavailable:"Уучлаарай! Энэ систем дээр хэвлэх боломжгүй.",
  Prints_Paused:"Хэвлэхийг түр зогсоосон",
  Administration:"Захиргаа",
  Loading:"Ачааж байна",
  Unnamed:"Нэргүй",
  error:"Уучлаарай, энэ чекийг нээх боломжгүй байна.",
  No_Cheques_To_Print:"Үгүй ээ шалгана хэвлэх",
  No_Cheques_To_Print_Word:"Нь <a routerLink='/folder/Cheque/New'> Шинэ шалгах </a> үүсгэнэ.",
  New_Cheque:"Шинэ чек",
  Start_One_Now:"Одоо нэгийг эхлүүл",
  Edit_Cheque:"Шалгах засвар",
  Select_Cheques:"Шалгалтуудыг сонгох",
  Select_Employee:"Ажилтан сонгох",
  Default_Printer:"Өгөгдмөл хэвлэгч",
  Reassign:"Дахин хуваарилах",
  Configure:"Тохиргоо",
  Template:"Загвар",
  Designer:"Дизайнер",
  Edit_Designs:"Дизайн засварлах",
  New_Design:"Шинэ дизайн",
  Next:"Дараачийн",
  Save:"Хадгалах",
  Name:"Нэр",
  Mail:"мэйл",
  Amount:"Хэмжээ",
  Date:"Огноо",
  PayPal:"PayPal",
  Payouts:"Төлбөр",
  PayPal_Label:"PayPal шошго",
  Email_Username:"И-мэйл / хэрэглэгчийн нэр",
  Client_ID:"Үйлчлүүлэгч ID",
  Sandbox_Email:"Sandbox И-мэйл",
  PayPal_Email:"PayPal имэйл",
  PayPal_Username:"API хэрэглэгчийн нэр",
  PayPal_Payouts:"PayPal төлбөр",
  Select_PayPal_Key:"Сонгоно PayPal Key",
  Secret:"Нууц",
  API_Secret:"API нууц",
  PayPal_API_Keys:"PayPal түлхүүрүүд",
  New_PayPal_Key:"Шинэ PayPal түлхүүр",
  Email_Subject:"И-мэйл сэдэв",
  Email_Message:"И-мэйл мессеж",
  Payout_Options:"Төлбөрийн сонголтууд",
  Payment_Note:"Төлбөрийн тэмдэглэл",
  Enable_Employee:"Ажилтныг идэвхжүүлэх",
  Enable_Production_Mode:"Үйлдвэрлэлийн горимыг идэвхжүүлэх",
  Sandbox_Username:"Sandbox хэрэглэгчийн нэр",
  Sandbox_Signature:"Sandbox гарын үсэг",
  Sandbox_Password:"Sandbox нууц үг",
  Production_Username:"Үйлдвэрлэлийн хэрэглэгчийн нэр",
  Production_Signature:"Үйлдвэрлэлийн гарын үсэг",
  Production_Password:"Үйлдвэрлэл Нууц үг",
  Production_Email:"Үйлдвэрлэлийн имэйл",
  API_Client_ID:"API Client ID",
  API_Signature:"API гарын үсэг",
  API_Password:"API нууц үг",
  API_Username:"API хэрэглэгчийн нэр",
  Secret_Key:"Нууц түлхүүр",
  Sandbox:"Элсэн хайрцаг",
  Production:"Үйлдвэрлэл",
  Sandbox_Keys:"Sandbox түлхүүрүүд",
  Production_Keys:"Үйлдвэрлэлийн түлхүүрүүд",
  API_Title:"API гарчиг",
  Production_Mode:"Үйлдвэрлэлийн горим",
  Account_Label:"Бүртгэлийн шошго",
  No_PayPal_Setup:"PayPal түлхүүр байхгүй",
  Enable_PayPal_Account:"PayPal дансыг идэвхжүүлэх",
  No_PayPal_Word:"<a routerLink='/folder/Invoice/New'>PayPal API түлхүүрийг</a> нэмнэ үү.",
  Printed_Memo:"Хэвлэсэн санамж",
  Employee:"Ажилчин",
  View_Employee:"Ажилтныг харах",
  Mailing_Address:"Шуудангийн хаяг",
  Company_Address:"Компанийн хаяг",
  Select_Company_Address:"Компанийн хаяг сонгох",
  Address:"Хаяг",
  Any_Day:"Ямар ч өдөр",
  Address_Name:"Хаяг нэр",
  Unit:"нэгж",
  Account:"Бүртгэл",
  Bank_Account:"Банкны данс",
  Account_Limits:"Бүртгэлийн хязгаарлалтыг идэвхжүүлэх",
  Payroll:"Цалин",
  New_Payroll:"Шинэ цалин",
  No_Payroll:"Удахгүй болох цалин байхгүй",
  Upcoming_Holiday:"Удахгүй болох баяр:",
  Invoice_Due:"Нэхэмжлэхийн хугацаа:",
  New_Invoice:"Шинэ нэхэмжлэх",
  No_Invoices:"Нэхэмжлэхгүй",
  No_Invoices_Word:"Эхний <a routerLink='/folder/Invoice/New'>Нэхэмжлэх</a>-г үүсгэ.",
  Cheque_Due:"Чекийн хугацаа:",
  Payrolls:"Цалингийн жагсаалт",
  Sandbox_Mode:"Sandbox горим",
  Hire:"Хөлс",
  Pay:"Төлбөр",
  New:"Шинэ",
  Add:"Нэмэх",
  Make:"хийх",
  Time:"Цаг хугацаа",
  Write:"Бичих",
  Holiday:"Баяр",
  Holidays:"Баярын өдрүүд",
  Next_Holiday:"Дараагийн амралт:",
  All_Done:"Бүгд бэлэн!",
  Employees:"Ажилчид",
  Payees:"Төлбөр төлөгчид",
  Job:"Ажил",
  Jobs:"Ажил",
  Invoice:"Нэхэмжлэх",
  Invoices:"Нэхэмжлэх",
  Vacations:"Амралт",
  Cheques:"Шалгах",
  Brand_Cheques:"Брэнд",
  Payment:"Төлбөр",
  Enable_Payment:"Төлбөрийг идэвхжүүлэх",
  Payments:"Төлбөр",
  Schedule:"Хуваарь",
  Schedules:"Хуваарь",
  Timesheet:"Цагийн хуудас",
  Timesheets:"Цагийн хуудас",
  Salary:"Цалин",
  New_Address:"Шинэ хаяг",
  Address_2:"Хаягийн мөр 2)",
  _City:"Хот",
  _State:"Төр/Үнэлгээ",
  City:"Хот / суурин газар",
  State:"Муж / муж",
  Postal:"Шуудангийн / ZIP код",
  ZIP:"Шуудангийн / ZIP",
  Country:"Улс",
  Addresses:"Хаяг",
  Required_Options:"Шаардлагатай сонголтууд",
  Optional_Options:"Нэмэлт сонголтууд",
  Additional_Options:"Нэмэлт сонголтууд",
  Required:"Шаардлагатай",
  Optional:"Заавал биш",
  Additional:"Нэмэлт",
  No_Addresses:"Хаяг байхгүй",
  New_Address_Word:"Эхний <a routerLink='/folder/Address/New'>Хаяг</a>-г нэмнэ үү.",
  Select_Address:"Хаяг сонгох",
  No_Address:"Хаяг байхгүй",
  Print_Cheque:"Хэвлэх шалгах",
  Print_Cheque_Now:"Одоо шалгах хэвлэх",
  Description:"Тодорхойлолт",
  Pay_To_The_Order_Of:"Захиалгад төлнө:",
  Select_Date_Range:"Огнооны мужийг сонгох",
  Select_Starting_Date:"Эхлэх огноог сонгох",
  Select_Ending_Date:"Төгсгөлийн огноог сонгох",
  Select_Date:"Огноо сонгох",
  Cheque_Date:"Шалгах огноо",
  Cheque_Memo:"Тэмдэглэлийг шалгах",
  Blank_Cheque:"Хоосон чек",
  Blank:"Хоосон",
  No_Cheques:"Шалгалт байхгүй",
  No_Cheques_Word:"Анхны <a routerLink='/folder/Cheque/New'>Шалгах</a>-аа хэвлэнэ үү.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"Зургийг харах",
  View:"Харах",
  Modify:"Өөрчлөх",
  Delete:"Устгах",
  Cheque_Paid:"Төлбөртэй",
  New_Deduction:"Шинэ хасалт",
  Title:"Гарчиг",
  Frequency:"Давтамж",
  Hourly:"цаг тутамд",
  Daily:"Өдөр бүр",
  Weekdays:"Ажлын өдрүүд",
  BiWeekly:"2 долоо хоног",
  TriWeekly:"3 долоо хоног",
  Monthly:"Сар бүр",
  MiMonthly:"2 сар",
  Quarterly:"Улирал тутам",
  Yearly:"Жил бүр",
  Every_Week:"Долоо хоног бүр",
  Every_Payroll:"Цалин бүр",
  Every_Month:"Сар бүр",
  Annually:"Жил бүр",
  Always_Scheduled:"Үргэлж хуваарьтай",
  Start_Date:"Эхлэх өдөр",
  End_Date:"Төгсгөлийн огноо",
  Start_Time:"Эхлэх цаг",
  End_Time:"Төгсгөлийн цаг",
  Deduction_Label:"Хасах шошго",
  Notes:"Тэмдэглэл",
  Options:"Сонголтууд",
  Enable:"Идэвхжүүлэх",
  Select_Deductions:"Суутгал сонгох",
  Deductions:"Суутгал",
  No_Deductions:"Суутгал байхгүй",
  No_Deductions_Word:"Анхны <a routerLink='/folder/Deduction/New'>Хасах</a>-г үүсгэ.",
  New_Employee:"Шинэ ажилтан",
  No_Title:"Гарчиг байхгүй",
  _Name:"Нэр",
  About_Yourself:"Өөрийнхөө тухай",
  Full_Name:"Бүтэн нэр",
  Birthday:"Төрсөн өдөр",
  Email:"И-мэйл",
  SMS:"SMS",
  Phone:"Утас",
  Test:"Туршилт",
  Call:"Дуудлага",
  Fax:"Факс",
  Printed_Note:"Хэвлэсэн тэмдэглэл",
  Position:"Байрлал",
  Job_Position:"Ажлын байр",
  Select_a_Job:"Ажил сонгох",
  Select_a_Salary:"Цалингаа сонгох",
  Add_a_Deduction:"Суутгал нэмэх",
  Taxes:"Татвар",
  Add_Taxes:"Татвар нэмэх",
  Date_of_Birth:"Төрсөн өдөр",
  Email_Address:"Имэйл хаяг",
  Phone_Number:"Утасны дугаар",
  Phone_Call:"Утасны дуудлага",
  Enable_on_Payroll:"Цалин дээр идэвхжүүлэх",
  Select_Employees:"Ажилчдыг сонгох",
  No_Employees:"Ажилчидгүй",
  No_Employees_Word:"Анхны шинэ <a routerLink='/folder/Employee/New'>Ажилтан</a>-аа нэмнэ үү.",
  No_Name:"Нэргүй",
  Unemployeed:"Ажилгүй",
  Employeed:"Ажилтай",
  Paid:"Төлбөртэй",
  Enabled:"Идэвхжүүлсэн",
  Disabled:"Хөгжлийн бэрхшээлтэй",
  Fire:"Гал",
  Not_Available:"Боломжгүй",
  Not_Available_Word:"Анхны <a routerLink='/folder/Invoice/New'>Нэхэмжлэх</a> хэвлээд төлбөрөө аваарай.",
  Select_Invoices:"Нэхэмжлэх_сонгох",
  Print_Invoice_Word:"Анхны <a routerLink='/folder/Invoice/New'>Нэхэмжлэх</a> хэвлээд төлбөрөө аваарай.",
  Invoice_Title:"Нэхэмжлэхийн гарчиг",
  Invoice_Date:"Нэхэмжлэлийн өдөр",
  Due_Date:"Хугацаа дуусах хугацаа",
  New_Job:"Шинэ ажил",
  Details:"Дэлгэрэнгүй мэдээлэл",
  Customize:"Тохируулах",
  Customize_Dashboard:"Хяналтын самбарыг тохируулах",
  Components:"Бүрэлдэхүүн хэсгүүд",
  No_Components:"Бүрэлдэхүүн хэсгүүд байхгүй",
  Main_Components:"Үндсэн бүрэлдэхүүн хэсэг",
  Smaller_Components:"Жижиг бүрэлдэхүүн хэсгүүд",
  Error_Loading_Page:"Энэ хуудсыг ачаалахад алдаа гарлаа.",
  Bank_Details:"Банкны мэдээлэл",
  About_Your_Job:"Таны ажлын тухай",
  Your_Schedule:"Таны хуваарь",
  Job_Title:"Албан тушаал",
  Job_Description:"Ажлын байрны тодорхойлолт",
  Job_Details:"Ажлын дэлгэрэнгүй мэдээлэл",
  Enable_Job:"Ажлыг идэвхжүүлэх",
  Pay_Period:"Төлбөрийн хугацаа",
  Perpetually_Schedule:"Байнгын хуваарь",
  Select_Jobs:"Ажлыг сонгох",
  No_Jobs:"Ажилгүй",
  Add_Jobs:"Ажил нэмэх",
  No_Jobs_Word:"Эхний <a routerLink='/folder/Job/New'>Ажлыг</a> жагсаалтад нэмнэ үү.",
  Count_Employees:"job.employee_count+' Ажилтнууд'",
  Zero_Employees:"0 ажилтан",
  New_Leave:"Шинэ чөлөө",
  Leave_Name:"Нэр үлдээ",
  Paid_Leave:"Төлбөртэй чөлөө",
  Leave_Pay:"Төлбөрийг үлдээх",
  Indefinite_Leave:"Хугацаагүй чөлөө",
  Indefinite_Payroll:"Тодорхойгүй цалин",
  Leave:"Орхих",
  Add_Schedules:"Хуваарь нэмэх",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Эзгүй байдлыг идэвхжүүлэх",
  Select_Leaves:"Навч сонгох",
  No_Leaves:"Ажлын чөлөө байхгүй",
  Leave_Of_Absence:"Чөлөө",
  Leaves_Of_Absence:"Эцсийн хуудас",
  New_Leave_of_Absense:"Шинэ чөлөө",
  No_Leaves_Word:"Эхний <a routerLink='/folder/Leave/New'>Эзлээс чөлөөлөгдсөн</a>-г нэмнэ үү.",
  Not_Enabled:"Идэвхжүүлээгүй",
  Absences:"Эцэс таслал",
  Payee:"Төлбөр авагч",
  New_Payee:"Шинэ төлбөр авагч",
  Payee_Identifier:"Төлбөр хүлээн авагчийн танигч",
  Payee_Name:"Төлбөр хүлээн авагчийн нэр",
  Payee_Title:"Төлбөр хүлээн авагчийн гарчиг",
  Payment_Memo:"Төлбөрийн санамж",
  Select_Payees:"Төлбөр төлөгчийг сонгох",
  No_Payees:"Төлбөр төлөгчгүй",
  Add_Payee_Note:"Эхний <a routerLink='/folder/Payee/New'>Төлбөр авагч</a>-г нэмнэ үү.",
  New_Payees:"Шинэ төлбөр авагч",
  About_The_Payment_Schedule:"Төлбөрийн хуваарь",
  Your_Payroll_Schedule:"Автомат цалин",
  New_Payment:"Шинэ төлбөр",
  Identifier:"Таниулагч",
  Select:"Сонгох",
  Memo:"Санамж",
  Payment_Date:"Төлбөрийн хугацаа",
  Mark_as_Paid:"Төлсөн гэж тэмдэглэх",
  Select_Payments:"Төлбөрийг сонгох",
  No_Payments:"Төлбөр байхгүй",
  No_Payments_Word:"Эхний <a routerLink='/folder/Payment/New'>Төлбөрийг</a> үүсгэнэ үү.",
  Create_Payroll:"Цалингийн хуудсыг үүсгэх",
  Properties:"Properties",
  Payroll_Title:"Цалингийн гарчиг",
  Payroll_Notes:"Цалингийн тэмдэглэл",
  Payroll_Setup:"Цалингийн тохиргоо",
  Tabulate_Payments:"Төлбөрийн хүснэгт",
  Tabulate:"Хүснэгт",
  By:"Би:",
  Payments_By:"Төлбөр тооцоо",
  Timesheets_And_Schedules:"Цагийн хуудас ба хуваарь",
  Both:"Хоёулаа",
  Items:"Зүйлс",
  Add_Payees:"Төлбөр төлөгчийг нэмэх",
  Add_Account:"Данс нэмэх",
  Enable_Account:"Бүртгэлийг идэвхжүүлэх",
  Enable_Payroll:"Цалингийн хуудсыг идэвхжүүлэх",
  Print_Payroll:"Цалингийн хуудсыг хэвлэх",
  No_Payrolls:"Цалингүй",
  No_Payroll_Word:"Анхны <a routerLink='/folder/Payroll/New'>Цалин</a>-г үүсгэ.",
  View_more:"ИЛҮҮ ИХИЙГ ҮЗЭХ",
  Less:"БАГА",
  Add_Payroll:"Цалин нэмэх",
  Select_Payroll:"Цалингийн хуудсыг сонгох",
  About_Your_Salary:"Таны цалингийн тухай",
  Add_Salaries:"Цалин нэмэх",
  Add_Salary:"Цалин нэмэх",
  Salaries:"Цалин",
  No_Salaries:"Цалингүй",
  No_Salaries_Word:"Эхний <a routerLink='/folder/Salary/New'>Цалин</a>-г нэмнэ үү.",
  Select_Salaries:"Цалингаа сонгох",
  New_Salary:"Шинэ цалин",
  Salary_Name:"Цалингийн тодорхойлогч",
  Enable_Salary:"Цалиныг идэвхжүүлэх",
  Salary_Amount:"Цалингийн хэмжээ",
  New_Schedule:"Шинэ хуваарь",
  Schedule_Title:"Хуваарийн гарчиг",
  Add_Address:"Хаяг нэмэх",
  Repeat:"Давтах",
  Design:"Дизайн",
  Edit_Design:"Дизайн засварлах",
  Edit_this_Design:"Энэ дизайныг засах",
  Repeat_Payment:"Төлбөрийг давтах",
  Enable_Schedule:"Хуваарийг идэвхжүүлэх",
  Never:"Хэзээ ч",
  Select_Schedule:"Хуваарь сонгох",
  No_Schedules:"Хуваарь байхгүй",
  No_Schedules_Word:"Эхний <a routerLink='/folder/Schedule/New'>Хуваарь</a>-г үүсгэ.",
  New_Administrator:"Шинэ админ",
  Username:"Хэрэглэгчийн нэр",
  First_Name:"Нэр",
  Last_Name:"Овог",
  Add_an_Address:"Хаяг нэмэх",
  Payment_Limit:"Төлбөрийн хязгаар",
  Total_Limit:"Нийт хязгаар",
  Select_Accounts:"Бүртгэл сонгох",
  No_Administrators:"Администратор байхгүй",
  No_Administrators_Word:"Эхний <a routerLink='/folder/Administrator/New'>Админ бүртгэлийг</a> үүсгэнэ үү.",
  New_Administrators_Word:"Эхний <a routerLink='/folder/Administrator/New'>Администратор</a>-г нэмнэ үү",
  Cloud:"Үүл",
  Backup:"Нөөцлөх",
  Enable_iCloud:"iCloud-г идэвхжүүлэх",
  Enable_Google_Drive:"Google Драйвыг идэвхжүүлэх",
  Enable_Microsoft_OneDrive:"Microsoft OneDrive-г идэвхжүүлэх",
  Automatically_Backup:"Автоматаар нөөцлөх",
  FTP_Settings:"FTP тохиргоо",
  URL_File_Prompt:"Импортлох .xls / .xlsx / .json файлын байршлыг зааж өгнө үү:",
  URL_SQL_Prompt:"Импортлох SQLite файлын байршлыг зааж өгнө үү:",
  FTP_Backup:"FTP нөөц",
  FTP_Import:"FTP импорт",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Хост",
  Port:"Порт",
  Path:"Зам",
  Data_Path:"Өгөгдлийн зам",
  Enable_FTP_Account:"FTP бүртгэлийг идэвхжүүлэх",
  HostnameIP:"Хост нэр",
  Password:"Нууц үг",
  Connection_Port:"Холболтын порт",
  Enable_MySQL_Database:"MySQL мэдээллийн санг идэвхжүүлэх",
  Log:"Бүртгэл",
  Reset:"Дахин тохируулах",
  Erase:"Устгах",
  Export:"Экспорт",
  Database:"Мэдээллийн сан",
  Upload_CSV:"CSV байршуулах",
  Download_CSV:"CSV татаж авах",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL нөөцлөлт",
  Internal_Notes:"Дотоод тэмдэглэл",
  Root_Path:"Үндэс зам",
  Select_Backup:"Нөөцлөлтийг сонгох",
  Add_New_Backup:"Шинэ нөөцлөлт нэмэх",
  First_Backup:"Эхний нөөцлөлтийг тохируулна уу...",
  Backups:"Нөөцлөлт",
  Add_Backup:"Нөөцлөлт нэмэх",
  No_Backups:"Нөөц олдохгүй байна.",
  Select_Backup_Type:"Та тохируулахыг хүсэж буй нөөцлөлтийн төрлөө сонгоно уу...",
  Backup_Type:"Нөөцлөх төрөл",
  FTP_Drive:"FTP Drive",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Жолооч",
  Microsoft_OneDrive:"Жолооч",
  Import_Fields:"Импортын талбарууд",
  Import_Fields_Word:"Энэ хэсгийг ашиглан <a routerLink='/folder/Holiday/New'>Импорт</a> өгөгдлийг сонгоно уу.",
  Upload:"Байршуулах",
  Download:"Татаж авах",
  Download_JSON:"JSON өгөгдөл болгон татаж авах",
  Download_SQL:"SQL файл болгон татаж авах",
  New_Bank:"Шинэ банк",
  New_Account:"Шинэ хаяг",
  New_Bank_Account:"Банкны шинэ данс",
  Upload_Image:"Зураг байршуулах",
  Bank_Name:"Банкны нэр",
  Bank_Address:"Банкны хаяг",
  Branch_Address:"Салбарын хаяг",
  Address_on_Cheque:"Хаяг",
  Cheque_Numbers:"Дугаар шалгах",
  Cheque_Details:"Дэлгэрэнгүйг шалгах",
  Bank_Logo:"Банкны лого",
  Cheque_ID:"ID шалгах",
  Starting_Cheque_ID:"Шалгаж эхлэх ID",
  Transit_Number:"Транзит дугаар",
  Institution_Number:"Байгууллагын дугаар",
  Designation_Number:"Тэмдэглэлийн дугаар",
  Account_Number:"Дансны дугаар",
  Limits:"Хязгаарлалт",
  Default_Limits:"Өгөгдмөл хязгаарлалт",
  Over_Limit:"Хязгаарлалтаас хэтэрсэн",
  Under_Limit:"Хязгаар дор",
  Payroll_Limit:"Цалингийн хязгаар",
  Enable_Bank_Account:"Банкны дансыг идэвхжүүлэх",
  Select_Account:"Бүртгэл сонгох",
  No_Bank_Account:"Банкны данс байхгүй",
  No_Bank_Account_Word:"Эхний <a routerLink='/folder/Accounts/New'>Банкны дансыг</a> нэмнэ үү.",
  Bank_Accounts:"Банкны данс",
  No_Accounts:"Бүртгэл байхгүй",
  No_Accounts_Note:"Эхний <a routerLink='/folder/Accounts/New'>Банкны дансыг</a> нэмнэ үү.",
  Cheque_Designer:"Дизайнерыг шалгах",
  Cheque_Design:"Дизайн шалгах",
  Select_Design:"Дизайн сонгох",
  Cheque_Designs:"Дизайн шалгах",
  No_Cheque_Designs:"Шалгах загвар байхгүй",
  No_Cheque_Designs_Word:"Өөрийндөө <a routerLink='/folder/Settings/Cheque/Design/New'>Дизайныг шалгана уу</a> үүсгээрэй.",
  Set_Default:"Өгөгдмөл болгож тохируулах",
  Default:"Өгөгдмөл",
  Remove:"Устгах",
  Folder:"Хавтас",
  Edit:"Засварлах",
  LoadingDots:"Ачааж байна...",
  Add_a_New_File:"<a href='#' (click)='add()'>Шинэ файл</a>-г нэмэх",
  this_folder:"энэ хавтас",
  FTP_Backup_Settings:"FTP нөөцлөх тохиргоо",
  Secure_File_Transfer:"Аюулгүй файл дамжуулах",
  New_Holiday:"Шинэ амралт",
  Add_Holiday:"Баяр нэмэх",
  Holiday_Name:"Баярын нэр",
  Additional_Pay:"Нэмэлт төлбөр",
  Enable_Holiday:"Баярын амралтыг идэвхжүүлэх",
  Select_Holidays:"Баярын өдрүүдийг сонгох",
  No_Holidays:"Баярын өдрүүд байхгүй",
  No_Holidays_Word:"Эхний <a routerLink='/folder/Holiday/New'>Нийтийн амралтын өдрийг</a> нэмнэ үү.",
  New_Import:"Шинэ импорт",
  Import_Data:"Өгөгдөл импортлох",
  Import_Data_Word:"Өөрийн сонгосон файлын төрөл эсвэл байршуулах аргыг сонгоно уу.<br /> Та дэмжигдсэн файлыг байршуулсны дараа файлын аль ч импортын талбарыг програмын аль параметрт тохирохыг сонгох боломжтой .", 
  Import_File:"Файл импортлох",
  Link_To_File:"Файл руу холбох",
  Select_File:"Файл сонгох",
  New_Moderator:"Шинэ зохицуулагч",
  Allow_Moderation:"Зохицуулахыг зөвшөөрөх",
  Create_Paycheques:"Цалингийн чек үүсгэх",
  Edit_Paycheques_and_Data:"Цалин болон өгөгдлийг засах",
  Destroy_Data_and_Paycheques:"Өгөгдөл болон цалингийн чекийг устгах",
  Bonus_Percentage:"Цалингийн хувь",
  Fixed_Amount:"Тогтмол хэмжээ",
  Select_Moderator:"Модератор сонгох",
  No_Moderators:"Модератор байхгүй",
  Moderators:"Модераторууд",
  Moderator_Account:"Эхний <a routerLink='/folder/Administrator/New'>Модераторын данс</a> үүсгэнэ үү.",
  No_Moderators_Word:"Эхний <a routerLink='/folder/Administrator/New'>Зохицуулагч</a>-г нэмнэ үү.",
  Defaults:"Өгөгдмөл",
  Provide_Us_Info:"Бидэнд мэдээлэл өгнө үү",
  Setup_Your_Printer:"Хэвлэгчээ тохируулах",
  Your_Company:"Танай компанийн тухай",
  Company_Name:"Компанийн нэр",
  Currency:"валют",
  Default_Currency:"Үндсэн валют",
  Base_Monthly_Income:"Сарын орлого",
  Protection:"Хамгаалалт",
  App_Protection:"Програмын хамгаалалт",
  PIN_Code_Protection:"ПИН кодын хамгаалалт",
  App_Protection_Word:"Таны бүртгэлийг хамгаалахад туслах хамгаалалтын аргуудыг идэвхжүүлнэ үү.",
  PIN_Code:"PIN код",
  Change_PIN:"ПИН солих",
  New_Password:"Шинэ нууц үг",
  Geofence_Protection:"Гео-хашааны хамгаалалт",
  Geofence_Area:"Тусгай талбай",
  Distance:"Зай",
  Setup_Now:"Одоо тохируулах",
  Mile:"Мил",
  Km:"км",
  m:"м",
  Facial_Recognition:"Нүүр таних",
  Face:"Нүүр царай",
  Setup:"Тохируулах",
  Label:"Шошго",
  Password_Protection:"Нууц үгийн хамгаалалт",
  Modify_Password:"Нууц үг өөрчлөх",
  New_Tax_Entry:"Татварын шинэ бүртгэл",
  New_Tax:"Шинэ татвар",
  Tax_Label:"Татварын шошго",
  Perpetually_Enabled:"Үргэлж идэвхжүүлсэн",
  Select_Taxes:"Татвар сонгох",
  Tax_Deductions:"Татварын хөнгөлөлтүүд",
  No_Tax_Deductions:"Татварын хөнгөлөлт үзүүлэхгүй",
  No_Tax_Deductions_Word:"Эхний <a routerLink='/folder/Tax/New'>Татварын</a> хасалтыг нэмнэ үү.",
  New_Timer:"Шинэ цаг хэмжигч",
  Start:"Эхлэх",
  Stop:"Зогс",
  Start_Timer:"Эхлэх цаг хэмжигч",
  Stop_Timer:"Таймерыг зогсоох",
  Timer_Active:"Таймер идэвхтэй",
  Timer:"Таймер:",
  Timer_Running:"Таймер: (Гүйж байна)",
  Save_Timer:"Таймерыг хадгалах",
  New_Timesheet:"Шинэ цагийн хуудас",
  Select_Timesheets:"Цагийн хуудас сонгох",
  Work_Notes:"Ажлын тэмдэглэл",
  Entry_Title:"Бүртгэлийн гарчиг",
  No_Timesheets:"Цагийн хуудас байхгүй",
  No_Timesheets_Word:"Эхний <a routerLink='/folder/Timesheet/New'>Цагийн хуудсыг</a> нэмнэ үү.",
  Timesheet_Submitted:"Цагийн хуудсыг илгээсэн",
  Timesheet_Congrats:"Баяр хүргэе! Таны цагийн хуудсыг амжилттай илгээлээ. Баярлалаа!",
  Timesheet_Copy:"Баримт бичгийнхээ хуулбарыг авахын тулд бидэнд имэйл хаяг болон гар утасны дугаараа оруулна уу.",
  Submit:"Илгээх",
  Allow_Notifications:"Мэдэгдэлийг зөвшөөрөх",
  Untitled_Entry:"Шинэ оруулга",
  Untitled_Bank:"Нэргүй банк",
  Timesheet_Entry:"Цагийн хуудасны бичилт",
  Work_Description:"Ажлын тодорхойлолт",
  Enable_Timesheet:"Цагийн хуудсыг идэвхжүүлэх",
  Submit_Timesheet:"Цагийн хуудсыг илгээх",
  Vacation:"Амралт",
  New_Vacation:"Шинэ амралт",
  Vacation_Name:"Амралтын нэр",
  Paid_Vacation:"Төлбөртэй амралт",
  Vacation_Pay:"Амралтын төлбөр",
  Enable_Vacation:"Амралтыг идэвхжүүлэх",
  Select_Vacations:"Амралт сонгох",
  No_Vacations:"Амралт байхгүй",
  No_Vacations_Word:"Эхний <a routerLink='/folder/Vacation/New'>Амралт</a> оруулгыг үүсгэ.",
  Payroll_Schedule:"Цалингийн хуваарь",
  Next_Payroll:"Дараагийн цалин:",
  Upcoming_Payroll:"Удахгүй болох цалин",
  No_Upcoming_Payroll:"Удахгүй болох цалин байхгүй",
  Address_Book:"Хаяг хадгалах ном",
  Archived_Documents:"Архивлагдсан баримтууд",
  Dev_Mode:"Хөгжлийн горим дахь програм",
  Administrators:"Администраторууд",
  Privacy:"Нууцлал",
  None:"Байхгүй",
  Select_Signature:"Гарын үсэг сонгох",
  No_Signatures:"Гарын үсэг байхгүй",
  No_Signatures_Word:"Эхний <a routerLink='/folder/Signature/New'>Гарын үсэг</a>-г нэмнэ үү.",
  Repeat_Indefinitely:"Тодорхойгүй давтана",
  Sign:"Гарын үсэг",
  Sign_Here:"Энд бүртгүүл",
  Date_Signed:"Гарын үсэг зурсан огноо",
  Signature_Pad:"Гарын үсэг",
  Account_Holder:"Данс эзэмшигч",
  Account_Properties:"Бүртгэлийн шинж чанарууд",
  Name_On_Cheque:"Чек дээрх нэр",
  Server_Hostname:"Серверийн хостын нэр / IP",
  Printers:"Хэвлэгч",
  No_Printers:"Хэвлэгч байхгүй",
  Printer_Exists:'Ийм нэртэй принтер аль хэдийн байна.',
  No_Printers_Word:"Эхний <a routerLink='/folder/Printer/New'>Хэвлэгч</a>-г нэмнэ үү.",
  No_Printer_Alert:"Ямар ч хэвлэгч тодорхойлогдоогүй байна. Та принтер тохируулахыг хүсэж байна уу?",
  Add_Printer:"Хэвлэгч нэмэх",
  New_Printer:"Шинэ принтер",
  Printer_Hostname:"Хэвлэгчийн хостын нэр / IP",
  Printer_Label:"Хэвлэгчийн шошго",
  Printer_Protocol:"Хэвлэгчийн протокол",
  Protocol:"Протокол",
  Email_Print:"И-мэйл",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Сокет",
  Print_Job:"Хэвлэх ажил",
  Printed:"Хэвлэсэн",
  Not_Printed:"Хэвлэээгүй",
  Print_Jobs:"Хэвлэх ажил",
  Print_Queue:"Хэвлэх дараалал",
  No_Print_Jobs:"Хэвлэх ажил байхгүй",
  No_Prints:"Хэвлэхийн тулд шинэ <a routerLink='/folder/Cheque/New'>Чек</a> үүсгэнэ үү.",
  Prints:"Хэвлэх",
  Find_Printer:"Хэвлэгч олох",
  Find_AirPrint_Devices:"AirPrint төхөөрөмжүүдийг олох",
  Select_Printer:"Хэвлэгч сонгох",
  System_UI:"Системийн UI",
  Printer:"Хэвлэгч",
  Status:"Статус",
  Preview:"Урьдчилан үзэх",
  Enable_Print_Job:"Хэвлэх ажлыг идэвхжүүлэх",
  Queue_Weight:"Дарааллын жин",
  Unlimited:"Хязгааргүй",
  Show_Advanced_Printer_Options:"Дэвшилтэт принтерийн сонголтыг харуулах",
  Advanced:"Дэвшилтэт",
  Location:"Байршил",
  Note:"Тэмдэглэл",
  Queue_Name:"Дарааллын нэр",
  Pages_Printed_Limit:"Хэвлэсэн хуудасны хязгаарлалт",
  MultiPage_Idle_Time:"Олон хуудас хүлээх хугацаа (ууд)",
  Page_Count_Limit:"Хуудасны тооны хязгаар",
  Page_Orientation:"Хуудасны чиглэл",
  Portrait:"Хөрөг",
  Landscape:"Ландшафт",
  Duplex:"Дуплекс",
  Double_Sided_Printing:"Давхар тал",
  Duplex_Mode:"Дуплекс горим",
  Duplex_Short:"Богино",
  Duplex_Long:"Урт",
  Duplex_None:"Байхгүй",
  Color_And_Quality:"Өнгө ба чанар",
  Monochrome:"Монохром",
  Photo_Quality_Prints:"Зургийн чанартай хэвлэх",
  Printer_Email:"Хэвлэгчийн имэйл",
  Automatically_Downsize:"Автоматаар багасгах",
  Paper:"Цаас",
  Paper_Name:"Цаасны нэр",
  Paper_Width:"Цаасны өргөн",
  Paper_Height:"Цаасны өндөр",
  Paper_Autocut_Length:"Цаасыг автоматаар зүсэх урт",
  Margins:"Маржин",
  Page_Margins:"Хуудасны захын зай",
  Page_Margin_Top:"Хуудасны дээд талын зай",
  Page_Margin_Right:"Баруун хуудасны ирмэг",
  Page_Margin_Bottom:"Хуудасны доод зай",
  Page_Margin_Left:"Зүүн хуудасны ирмэг",
  Add_Employees:"Ажилчдыг нэмэх",
  Header:"Толгой",
  Print_A_Page_Header:"Хуудасны толгой хэсгийг хэвлэх",
  Header_Label:"Толгой шошго",
  Header_Page_Index:"Толгой хуудасны индекс",
  Header_Font_Name:"Толгой фонт",
  Select_Font:"фонт сонгох",
  Font_Selector:"фонт сонгогч",
  Header_Font_Size:"Толгой фонт",
  Header_Bold:"Толгой Болд",
  Header_Italic:"Толгой налуу",
  Header_Alignment:"Толгойг зэрэгцүүлэх",
  Left:"Зүүн",
  Center:"Төв",
  Right:"Зөв",
  Justified:"Үндэслэсэн",
  Header_Font_Color:"Толгойн өнгө",
  Select_Color:"Өнгө сонгох",
  Footer:"Хөл хэсэг",
  Print_A_Page_Footer:"Хуудасны хөлийг хэвлэх",
  Footer_Label:"Хөл хэсгийн шошго",
  Footer_Page_Index:"Хөл хуудасны индекс",
  Footer_Font_Name:"Footer Font",
  Fonts:"фонтууд",
  Done:"Дууссан",
  Select_Fonts:"фонт сонгох",
  Footer_Font_Size:"Хөл хэсгийн хэмжээ",
  Footer_Bold:"Болд хөл",
  Footer_Italic:"Хөл хэсэг налуу",
  Footer_Alignment:"Footer Alignment",
  Footer_Font_Color:"Хөл хэсгийн өнгө",
  Page_Copies:"Хуудасны хуулбар",
  Enable_Printer:"Хэвлэгчийг идэвхжүүлэх",
  Remote_Logging:"Алсын бүртгэл",
  Log_Server:"Бүртгэлийн сервер",
  Encryption:"Шифрлэлт",
  Random_Key:"Санамсаргүй түлхүүр",
  Encryption_Key:"Шифрлэлтийн түлхүүр",
  Cheques_Webserver:"Захиалгат мэдээллийн сан",
  Learn_How:"Яаж сур",
  Signature:"Гарын үсэг",
  Default_Printer_Unit:"ин/см/мм/(pt)",
  View_Signature:"Гарын үсэг харах",
  Printed_Signature:"Хэвлэсэн гарын үсэг",
  Digitally_Sign:"Тоон гарын үсэг",
  Digital_Signature:"Цахим гарын үсэг",
  Add_Signature:"Гарын үсэг нэмэх",
  Add_Your_Signature:"Гарын үсгээ нэмнэ үү",
  Enable_Signature:"Гарын үсгийг идэвхжүүлэх",
  Digitally_Signed:"Тоон гарын үсэгтэй",
  Insert_Error:"Өгөгдлийн сангийн асуудлаас болж чекийг хадгалах боломжгүй.",
  Delete_Confirmation:"Та энэ мэдээллийг устгахдаа итгэлтэй байна уу?",
  Discard_Confirmation:"Та энэ мэдээллийг устгахдаа итгэлтэй байна уу?",
  Discard_Bank_Confirmation:"Та энэ бүртгэлийг устгахдаа итгэлтэй байна уу?",
  Discard_Printer_Confirmation:"Та энэ принтерийг устгахдаа итгэлтэй байна уу?",
  Delete_Bonus_Confirmation:"Та энэ урамшууллыг устгахдаа итгэлтэй байна уу?",
  Delete_Invoice_Confirmation:"Та энэ нэхэмжлэхийг устгахдаа итгэлтэй байна уу?",
  Generated_Cheque:"Үйлдвэрлэсэн чек",
  Generated_Invoice:"Үйлдвэрлэсэн нэхэмжлэх",
  Schedule_Start_Time:"Эхлэх хуваарь",
  Schedule_End_Time:"Хуваарийн төгсгөл",
  New_Call:"Шинэ дуудлага",
  No_Contacts:"Харилцагч байхгүй",
  No_Contacts_Word:"Администраторууд, зохицуулагчид, ажилчид болон төлбөр авагчид харилцагчид болж харагдана.",
  PDF_Subject:"санхүү",
  PDF_Keywords:"цалингийн цалингийн чек PDF чек",
  Printer_Setup:"Хэвлэгчийн тохиргоо",
  Printer_Selection:"Хэвлэгчийн сонголт",
  New_Fax:"Шинэ факс",
  New_Fax_Message:"Шинэ факсын мессеж",
  Fax_Machine:"Факсын машин",
  Fax_Name:"Факсын нэр",
  Fax_Email:"Факсын имэйл",
  Fax_Number:"Факсын дугаар",
  Contents:"Агуулга",
  Fax_Body:"Хуудасны үндсэн хэсэг",
  Header_Word:"Толгой:",
  Header_Text:"Толгой текст",
  Include_Header:"Толгойг оруулах",
  Include_Footer:"Хөл хэсгийг оруулах",
  Footer_Word:"Хөл хэсэг:",
  Footer_Text:"Хөл текст",
  Attach_a_Cheque:"Чек хавсаргах",
  Add_Deduction:"Суутгал нэмэх",
  Enable_Fax:"Факс илгээх",
  Select_Fax:"Факс сонгох",
  No_Faxes:"Факс байхгүй",
  Faxes:"Факс",
  Save_and_Send:"Факс илгээх",
  Save_and_Pay:"Хадгалах ба төлөх",
  WARNING:"АНХААРУУЛГА:",
  Remember:"Санах",
  Printing:"Хэвлэх",
  Printing_Complete:"Хэвлэж дууслаа!\n\n",
  of:"нь",
  There_Were:"Байсан",
  Successful_Prints:"амжилттай хэвлэх ба",
  Unsuccessful_Prints:"амжилтгүй хэвлэмэлүүд.",
  PrinterError:"Уучлаарай! Алдаа гарлаа.",
  Printing_:"Хэвлэж байна...",
  PrinterSuccess:"Баримт бичгийг амжилттай хэвлэв.",
  PrintersSuccess:"Баримт бичгүүдийг амжилттай хэвлэсэн.",
  Print_Cheques:"Шалгах хэвлэх",
  New_Message:"Шинэ мессеж",
  New_Messages:"Шинэ мессежүүд",
  Read_Message:"Зурвас унших",
  Write_Message:"Зурвас бичих",
  Send_Message:"Зурвас илгээх",
  Subject:"Сэдэв",
  Message:"Захиа",
  Writing:"Бичих...",
  Send:"Илгээх",
  Set_Date:"Огноог тохируулах",
  Set_Time:"Цаг тааруулах",
  Recieve:"Хүлээн авах",
  Set_as_Default:"Өгөгдмөл болгож тохируулах",
  Default_Account:"Үндсэн данс",
  Default_Design:"Өгөгдмөл дизайн",
  Multiple_Cheques:"Шалгах (Гурвалсан)",
  Account_Mode:"Бүртгэлийн горим",
  Multiple_Cheques_Description:"Нэг хуудсанд гурван чек.",
  Check_and_Table:"Шалгах ба Хүснэгт",
  Check_including_Table:"Шалгах ба нягтлан бодох бүртгэлийн хүснэгт.",
  Check_Mailer:"Шуудангийн илгээмжийг шалгах",
  Check_Mailer_Window:"Хаягийн цонхоор шалгана уу.",
  DocuGard_Table_Top:"DocuGard шалгах ба хүснэгт (дээд)",
  DocuGard_Table_Middle:"DocuGard Шалгах & Хүснэгт (Дунд)",
  DocuGard_Table_Bottom:"DocuGard шалгах ба хүснэгт (доод)",
  DocuGard_Mailer_Top:"DocuGard шалгах шуудан (дээд)",
  DocuGard_Mailer_Middle:"DocuGard шалгах шуудан (дунд)",
  DocuGard_Mailer_Bottom:"DocuGard шалгах шуудан (доод)",
  DocuGard_Three_Cheques:"DocuGard Checks (Гурвалсан)",
  DocuGard_Cheque_Top:"DocuGard шалгах (дээд)",
  DocuGard_Cheque_Middle:"DocuGard шалгах (дунд)",
  DocuGard_Cheque_Bottom:"DocuGard шалгах (доод)",
  DocuGard_Three_Cheques_Window:"Нэг хуудсан дээр гурван шалгалт.",
  DocuGard_Table_Top_Window:"Шалгах ба орлогын хүснэгт.",
  DocuGard_Table_Middle_Window:"Шалгах ба орлогын хүснэгт.",
  DocuGard_Table_Bottom_Window:"Шалгах ба орлогын хүснэгт.",
  DocuGard_Mailer_Top_Window:"Шалгах, хүснэгт, хаяг.",
  DocuGard_Mailer_Middle_Window:"Шалгах, хүснэгт, хаяг.",
  DocuGard_Mailer_Bottom_Window:"Шалгах, хүснэгт, хаяг.",
  DocuGard_Cheque_Top_Window:"Аюулгүй цаас байгаа эсэхийг шалгана уу.",
  DocuGard_Cheque_Middle_Window:"Аюулгүй цаас байгаа эсэхийг шалгана уу.",
  DocuGard_Cheque_Bottom_Window:"Аюулгүй цаас байгаа эсэхийг шалгана уу.",
  Basic_Cheque:"Шалгах (дээд)",
  Basic_Cheque_Print:"Ганц чек хэвлэх.",
  Error_Setting_As_Paid:"Төлбөртэй гэж тохируулах алдаа",
  Add_Attachment:"Хавсралт нэмэх",
  PrinterUnavailable:"Хэвлэгч боломжгүй",
  CreditCardComponent:"Картууд",
  PaypalComponent:"PayPal",
  InteracComponent:"Интерак",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Шинэ хадгаламж",
  Deposits:"Хадгаламж",
  No_Deposits:"Хадгаламжгүй",
  Credit_Card_Deposit:"Зээлийн карт",
  New_Credit_Card_Deposit_Message:"Зээлийн картын хадгаламж",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Биткойн хадгаламж",
  New_Interac_Deposit:"Интерак",
  New_Interac_Deposit_Message:"Интеракт шилжүүлэг",
  Payments_Limit:"Төлбөрийн хязгаар",
  No_Payment_Limit:"Төлбөрийн хязгаарлалт байхгүй",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal хадгаламж",
  No_Deposits_Word:"Эхний компанийг <a routerLink='/folder/Deposit/New'>Хадгаламж</a> нэмнэ үү.",
  No_Documents_Specified:"Хэвлэх баримт бичгийг заагаагүй",
  No_Printers_Added:"Ямар ч принтер олдсонгүй. Нэгийг нэмэхийн тулд Тохиргоо > Принтерүүд рүү очно уу.",
  DB_Erase_Prompt:"Өгөгдлийн санг бүхэлд нь устгах уу? АНХААРУУЛГА: Та бүх хадгалагдсан мэдээллээ алдах болно!",
  Console_Warning:"Энэ консолд ямар ч текст буулгаж болохгүй. Та өөрийгөө болон/эсвэл компаниа ноцтой эрсдэлд оруулж болзошгүй.",
  No_Faxes_Word:"Эхний <a routerLink='/folder/Fax/New'>Факс</a> үүсгэнэ үү.",
  Cheque_Delete_Confirmation:"Та энэ чекийг устгахдаа итгэлтэй байна уу?",
  Design_Delete_Confirmation:"Та энэ загварыг устгахдаа итгэлтэй байна уу?",
  Cheque_Pay_Confirmation:"Энэ чекийг төлбөртэй гэж тэмдэглэх үү? Энэ нь хэвлэх дараалалд харагдахгүй.",
  Payment_Pay_Confirmation:"Энэ төлбөрийг төлсөн гэж тэмдэглэх үү? Энэ нь чекийн дараалалд харагдахгүй.",
  Delete_Deduction_Confirmation:"Та энэ хасалтыг устгахдаа итгэлтэй байна уу?",
  Delete_Job_Confirmation:"Та энэ ажлыг устгахдаа итгэлтэй байна уу?",
  Delete_Timesheet_Confirmation:"Та энэ цагийн хуудсыг устгахдаа итгэлтэй байна уу?",
  Delete_Schedule_Confirmation:"Та энэ хуваарийг устгахдаа итгэлтэй байна уу?",
  Delete_Setting_Confirmation:"Та энэ тохиргоог дахин тохируулахдаа итгэлтэй байна уу?",
  Delete_Fax_Confirmation:"Та энэ факсыг устгахдаа итгэлтэй байна уу?",
  Delete_File_Confirmation:"Та энэ файлыг устгахдаа итгэлтэй байна уу?",
  Delete_Vacation_Confirmation:"Та энэ амралтаа устгахдаа итгэлтэй байна уу?",
  Delete_Printer_Confirmation:"Та энэ принтерийг устгахдаа итгэлтэй байна уу?",
  Remove_Design_Confirmation:"Та энэ загварыг устгахдаа итгэлтэй байна уу?",
  Delete_Payroll_Confirmation:"Та энэ цалингийн хуудсыг устгахдаа итгэлтэй байна уу?",
  Send_Fax_Email_Confirmation:"Та эдгээр баримт бичгүүдийг факсаар илгээхийг хүсэж байна уу?",
  Send_Email_Confirmation:"Та энэ баримт бичгийг имэйлээр илгээхийг хүсэж байна уу?",
  Send_Fax_Confirmation:"Та энэ баримтыг факсаар илгээхийг хүсэж байна уу?",
  Error_Generating_PDF:"Уучлаарай. Энэ баримт бичгийг үүсгэхэд алдаа гарлаа.",
  PDF_Error_Saving_Image:"Уучлаарай. Энэ баримт бичгийн PDF зургийг хадгалахад алдаа гарлаа.",
  Test_Printer_Confirmation:"Та энэ принтер дээр туршилтын хуудас хэвлэхийг хүсэж байна уу?",
  Save_Timesheet_Prompt:"Хадгалахын тулд 'Гарчиг' нэмнэ үү эсвэл 'Start Timer' дээр дарна уу.",
  Remove_Geofence_Prompt:"Та энэ гео хашааны байршлыг устгахдаа итгэлтэй байна уу?",
  Delete_Employee_Confirmation:"Та устгахдаа итгэлтэй байна уу",
  Fire_Employee_Confirmation:"Чи галлахдаа итгэлтэй байна уу"
}