export const Ko = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"저작권 &copy; 2023년",
  black:"검은색",
  green:"녹색",
  gold:"금",
  blue:"파란색",
  brown:"갈색",
  purple:"보라",
  pink:"분홍색",
  red:"빨간색",
  Swatches:"견본",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"색조",
  Saturation:"포화",
  Lightness:"가벼움",
  Upgrade_To_Pro:"프로로 업그레이드",
  AllFeaturesInclude:"모든 기능에는 다음이 포함됩니다.",
  PrintUnlimitedCheques:"무제한 수표 인쇄",
  PremiumChequeDesigns:"프리미엄 체크 디자인",
  ManageUnlimitedEmployees:"무제한 직원 관리",
  AddUnlimitedPayees:"무제한 수취인 추가",
  CreateUnlimitedPayrolls:"무제한 급여 생성",
  UnlimitedSchedulesandTimeSheets:"무제한 일정 및 시간 시트",
  BulkPayPalPayouts:"대량 PayPal 지급",
  UnlimitedBankAccounts:"무제한 은행 계좌",
  ManageMultipleCompanies:"여러 회사 관리",
  TrackInsurancePolicies:"보험 정책 추적",
  Bio_MetricProtection:"생체 보호",
  Geo_FenceLock_OutProtection:"지오펜스 잠금 보호",
  Multiple_Companies_Word:"Checks 프리미엄이 없으면 여러 회사를 관리할 수 없습니다.",
  PayPal_Payouts_Word:"수표 프리미엄이 없으면 PayPal 결제가 비활성화됩니다.",
  PINProtection:"PIN 보호",
  PasswordProtection:"비밀번호 보안",
  May_Require_Approval:"승인이 필요할 수 있습니다.",
  Subscribe:"구독하다",
  Billed:"청구됨",
  Up:"위로",
  Down:"아래에",
  Positioning:"포지셔닝",
  Marker:"채점자",
  Drag_Marker:"드래그 마커",
  Tagline:"수표 인쇄 및 급여 표 작성",
  Marker_Word:"마커를 사용하여 요소의 크기를 조정합니다.",
  Pinch_Zoom:"핀치 줌",
  Pinch_Word:"핀치하여 요소를 확대/축소합니다.",
  Drag:"견인",
  Drag_Word:"손가락을 사용하여 요소를 드래그합니다.",
  subscription_alias_word:"자동 갱신 구독",
  premium_alias_word:"일회성 업그레이드 패키지",
  print_alias_word:"개별 수표 출력",
  mode_alias_word:"방법",
  Pro:"찬성",
  Pro_word:"프로 버전이 필요합니다.",
  Cant_Delete_Default_Company_Word:"죄송합니다. 기본 회사는 삭제할 수 없습니다 .",
  Reinsert_Default_Designs:"기본 디자인 다시 삽입",
  Reinsert_Default_Designs_word:"기본 디자인을 다시 삽입하시겠습니까?",
  Subscription_Active_Disable_Word:"이 구독은 활성화되어 있습니다. 이 수표 구독을 취소하시겠습니까?",
  Company_Logo:"회사 로고",
  ZERO_:"영",
  Disclaimer:"부인 성명",
  Privacy_Policy:"개인 정보 정책",
  EULA:"EULA 확인",
  Terms_Of_Service:"서비스 약관",
  Terms_Of_Use:"이용약관",
  Refunds:"환불 정책",
  Single_Print:"1 수표",
  Two_Prints:"2 수표",
  Five_Prints:"5 수표",
  Ten_Prints:"10 수표",
  Fifteen_Prints:"15 수표",
  Twenty_Prints:"20 수표",
  Thirty_Prints:"30 수표",
  Image_Added:"이미지가 추가됨",
  Image_Preview:"이미지 미리보기",
  No_Image_Was_Selected:"선택된 이미지가 없습니다.",
  Cheques_Unlimited:"무제한 수표",
  _Subscription:"신청",
  Subscription:"수표 - 1개월",
  Two_Month_Subscription:"수표 - 2개월",
  Three_Month_Subscription:"수표 - 3개월",
  Six_Month_Subscription:"수표 - 6개월",
  Twelve_Month_Subscription:"수표 - 12개월",
  Cheques_Are_Available:"수표를 인쇄할 수 있습니다.",
  Upgrade_Required_Two:"패키지를 선택하고 가격 버튼을 두 번 탭하여 구매를 시작하세요. 전문가 수준의 풀 컬러 수표를 몇 초 만에 인쇄하십시오.",
  Month:"월",
  Due:"로 인한:",
  Expires:"만료:",
  Subscription_Active:"구독 활성",
  Subscription_Inactive:"구독 비활성",
  Purchase_Additional_Cheques:"추가 수표를 구매하시겠습니까?",
  Printable_Cheque:"인쇄 가능한 수표",
  Printable_Cheques:"인쇄 가능한 수표",
  Printable_Cheque_Word:"귀하의 계정에서 수표를 사용할 수 있습니다.",
  Printable_Cheques_Word:"귀하의 계정에서 수표를 사용할 수 있습니다.",
  Max_Amount_Message:"지정한 금액이 이 시스템에 설정된 최대 금액을 초과했습니다.",
  Terms_Required_Word:"수표를 계속 사용하려면 이 계약에 동의해야 합니다.",
  Subscriptions:"구독",
  Product_Upgrades:"업그레이드",
  Mailed_Out_Cheques:"우편으로 발송된 수표",
  Enter_A_Company_Name:"회사명을 입력해주세요.",
  Single_Cheques:"단일 확인",
  Cheque_Golden:"골든 체크",
  Cheque_Golden_Window:"골든 체크 디자인.",
  Cheque_Green:"녹색 체크",
  Cheque_Green_Window:"그린 체크 디자인.",
  Cheque_Red:"레드 체크",
  Cheque_Red_Window:"레드 체크 디자인.",
  Cheque_Yellow:"옐로우 체크",
  Cheque_Yellow_Window:"노란색 체크 디자인.",
  Cheque_Statue_of_Liberty:"자유의 여신상",
  Cheque_Statue_of_Liberty_Window:"자유의 여신상 체크 디자인.",
  Cheque_Green_Wave:"그린 웨이브",
  Cheque_Green_Wave_Window:"그린 체크 디자인.",
  Cheque_Golden_Weave:"골든 위브",
  Cheque_Golden_Weave_Window:"우아한 골든 체크 디자인.",
  Cheque_Green_Sun:"그린 썬",
  Cheque_Green_Sun_Window:"깊고 차분한 체크 디자인.",
  Cheque_Blue_Checkers:"블루 체커",
  Cheque_Blue_Checkers_Window:"블루 체크 디자인.",
  Cashiers_Check:"자기앞 수표",
  Cashiers_Check_Window:"출납원 수표 스타일 템플릿입니다.",
  Cheque_Aqua_Checkers:"아쿠아체커",
  Cheque_Aqua_Checkers_Window:"아쿠아 체크 디자인.",
  Cheque_Golden_Checkers:"골든 체커",
  Cheque_Golden_Checkers_Window:"골든 체크 디자인.",
  Upgrade_Unavailable:"업그레이드 불가",
  Bank_Code_Protection:"은행 번호 보호",
  Bank_Code_Protection_Word:"다른 기기에서 실행되는 이 앱에서 또는 다른 사용자를 위해 은행 번호가 사용되지 않도록 보호하세요. 이 조치는 되돌릴 수 없습니다. 계속하다?",
  Bank_Code_Protection_Blocked_Word:"사용하려는 은행 번호는 다른 사용자 또는 장치용으로 예약되어 있습니다.",
  Bank_Code_Protection_Error_Word:"번호 확인에 실패했습니다. 인터넷에 연결하고 이 은행 계좌를 다시 추가해 보세요.",
  Bank_Code_Protection_Set_Error_Word:"은행 번호 보호를 위해서는 인터넷에 연결되어 있어야 합니다. 연결하고 다시 시도하십시오.",
  Upgrade_Unavailable_Word:"죄송합니다. 귀하를 확인하는 데 문제가 있습니다. 귀하의 지역에서는 현재 수표 구독 및 업그레이드를 구매할 수 없습니다.",
  PayPal_Payment_Preview:"PayPal 결제 미리보기",
  Apple_Pay:"애플 페이",
  Select_PayPal:"페이팔 선택",
  PayPal_Applications:"페이팔 애플리케이션",
  Purchase_With_Apple_Pay:"Apple Pay로 구매",
  Google_Pay:"구글페이",
  Companies:"회사",
  Insurance:"보험",
  New_PayPal:"새로운 페이팔",
  Pay_By:"에 의해 지불",
  Insure:"안전하게 하다",
  Miles:"마일",
  Payment_Method:"지불 방법",
  Select_Policies:"정책 선택",
  Policies:"정책",
  Policy:"정책",
  No_PayPal_Account:"페이팔 계정 없음",
  No_Policies:"보험 정책 없음",
  New_Policy:"새로운 정책",
  PayPal_Payment:"페이팔 결제",
  PayPal_Payments:"페이팔 결제",
  No_Payment_Selected:"선택한 결제가 없습니다.",
  Sending_Payment_Word:"잠시만 기다려 주세요... 결제가 전송 중입니다.",
  Sending_Payments_Word:"잠시만 기다려 주세요... 결제가 전송 중입니다.",
  No_Payment_Selected_PayPal:"<a routerLink='/folder/Payments'>PayPal 결제</a> 가 선택되지 않았습니다 .",
  Payment_Sent:"지불 보냄",
  PayPal_Payment_Sent:"이 결제는 PayPal로 전송되었습니다.",
  Copay:"자기부담금",
  Dead:"죽은",
  Alive:"살아 있는",
  Not_Dead:"죽지 않았다",
  Unclaimed:"청구되지 않은",
  Attempted:"시도됨",
  Deceased:"고인",
  Claimed:"청구됨",
  Unpaid:"미지급",
  Sending_Payment:"송금하기",
  Sending_Payments:"지불 보내기",
  Send_PayPal_Confirmation:"이 거래를 PayPal로 보내시겠습니까?",
  Send_PayPal_Confirmation_Word:"이 거래를 보내려면 녹색 버튼을 누르십시오.",
  Save_Payment_As_Unpaid:"이 결제를 미결제로 저장하시겠습니까?",
  Payment_Pay_Confirmation_PayPal:"이 지불을 지불된 것으로 저장하시겠습니까?",
  No_Policies_Word:"첫 번째 <a routerLink='/folder/Postage/New'>보험 정책</a> 을 추가하세요 .",
  Timesheet_Multiple_Delete_Confirmation:"여러 작업표를 삭제하시겠습니까?",
  Select_Multiple_Timesheets_Prompt:"선택한 작업표가 없습니다. 작업표를 하나 이상 선택하세요.",
  Select_Multiple_Policies_Prompt:"선택한 정책이 없습니다. 하나 이상의 보험 증권을 선택하십시오.",
  Policies_Multiple_Delete_Confirmation:"여러 정책을 삭제하시겠습니까?",
  Company:"회사",
  Add_Company:"회사 추가",
  New_Company:"회사 추가",
  No_Companies:"회사 없음",
  Enable_Company:"회사 활성화",
  Select_Company:"회사 선택",
  The_Default_Company:"기본 회사 레이블입니다.",
  Manage_Companies:"회사 관리",
  No_Companies_Word:"수표는 기본 회사를 사용합니다 .<br /> <a routerLink='/folder/Company/New'>첫 번째 회사</a> 를 추가합니다 .",
  Default_Company:"기본 회사",
  Cheques_Unlimited_Word:"Checks Unlimited를 사용하면 원하는 만큼 수표를 인쇄할 수 있습니다.",
  Cheques_Subscription_Word:"수표 구독을 통해 원하는 만큼 수표를 인쇄할 수 있습니다.",
  You_Own_This_Product:"이 제품을 소유하고 있습니다.",
  Your_Subscription_is_Active:"구독이 활성화되었습니다.",
  Active_Products:"활성화된 제품",
  Purchase_Confirmation:"구입",
  Purchase_Cheques:"구매 수표",
  Restore_Purchase:"구매를 복원",
  Erase_Purchase:"구매 지우기",
  Successfully_Purchased:"성공적으로 구매됨",
  Enter_Your_Licence_Key:"이 제품을 활성화하려면 이 페이지에 라이센스 키를 입력하십시오.",
  Licence_Key:"라이센스 키",
  Enter_Licence_Key:"라이선스 키 입력",
  Purchased:"구매 한",
  Enable_Feature:"기능 활성화",
  Cancel_Subscription:"구독 취소",
  Subscription_Removed:"구독이 제거됨",
  Select_Active_Subscription:"수정하려면 활성 구독을 선택하십시오.",
  Remove_Subscription_Word:"이 구독을 취소하시겠습니까?",
  Delete_Company_Confirmation:"이 회사 전체를 삭제하시겠습니까? 경고: 저장된 모든 정보를 잃게 됩니다!",
  Delete_Default_Company_Word:"기본 회사는 삭제할 수 없습니다 . 응용 프로그램을 재설정하고 기본 상태로 복원하시겠습니까? 경고: 저장된 모든 정보를 잃게 됩니다!",
  Console_Warning_2:"현재 귀하의 소유가 아닌 이 애플리케이션을 사용하여 통화를 처리하지 마십시오.",
  Terms_and_Conditions:"이용약관",
  and_the:"그리고",
  for:"~을 위한",
  Please_Read_Word:"내용을 읽고 동의해 주세요",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"일부 환율을 찾을 수 없습니다. 인터넷에 접속해 주십시오.",
  Free:"무료",
  DB_Erase_Prompt_2:"전체 개발자 데이터베이스를 완전히 지우시겠습니까? 경고: 모든 구매 및 구독 정보를 잃게 됩니다!",
  Successfully_Imported:"성공적으로 가져옴",
  Select_Postage:"우송료 선택",
  No_Postage:"우표 없음",
  No_Paid_Postage_Word:"첫 번째 <a routerLink='/folder/Postage/New'>유료 우표</a> 우표를 추가합니다 .",
  Trial_Complete:'평가판 완료',
  Please_Upgrade:'인쇄를 계속하려면 수표를 업그레이드하십시오.',
  Aa:"아아",
  Color:"색깔",
  Font:"폰트",
  Guide:"가이드",
  Guides:"가이드",
  Image:"영상",
  Zoom:"줌",
  Logo:"심벌 마크",
  Bank:"은행",
  MICR:"MICR",
  Total:"총",
  Cancel:"취소",
  Confirm:"확인하다",
  Method:"방법",
  Biometric_Security:"생체 인식 보안",
  Please_Login_To_Continue:"계속하려면 로그인하세요.",
  Complete:"완벽한",
  Sign_Up:"가입하기",
  Error:"오류",
  Biometrics:"생체 인식",
  Percent:"퍼센트",
  Zero_Percent:"0%",
  Top_Margin:"상단 여백",
  Bottom_Margin:"하단 여백",
  Left_Margin:"왼쪽 여백",
  Right_Margin:"오른쪽 여백",
  MICR_Margin:"MICR 마진",
  Table_Margin:"테이블 여백",
  Address_Margin:"주소 여백",
  Percentage_:"백분율",
  Vacation_Title:"휴가 제목",
  Use_PIN:"PIN 사용",
  Loading__:"로드 중...",
  Design_Title:"디자인 제목",
  Authorize:"승인",
  Key:"열쇠",
  Public_Key:"공개 키",
  Private_Key:"개인 키",
  Authenticate:"인증",
  Last_Payroll:"마지막 급여",
  Last_Calculation:"마지막 계산",
  Authorized:"인정 받은",
  Geo_Authorized:"지리적 위치: 승인됨",
  Not_Authorized:"승인되지 않음",
  Authorization_Complete:"승인 완료",
  Geolocation_Authorization:"지리적 위치 인증",
  Out_of_Bounds:"출입 금지 구역의",
  Cant_Delete_Default_Design:"기본 디자인은 삭제할 수 없습니다.",
  Unauthorized_Out_of_Bounds:"무단: 범위를 벗어남",
  Biometric_Authorization:"생체 인증",
  Locating_Please_Wait:"찾는 중입니다. 잠시만 기다려 주십시오...",
  Test_Biometrics:"생체 인식 테스트",
  Cheque_Margins:"여백 확인",
  Percentage_Full_Error:"백분율 필드는 100%를 초과하여 설정할 수 없습니다.",
  No_Payroll_Text:"<a routerLink='/folder/Employee/New'>직원</a> 또는 <a routerLink='/folder/Payee/New'>수취인</a> 및 <a routerLink='/folder/Schedule/New'>일정</a>.",
  Design_Saved:"디자인 저장됨",
  Default_Design_Selected:"기본 디자인 선택됨",
  Partial_Import:"부분 가져오기",
  Partial_Export:"부분 내보내기",
  Entire_Import:"전체 가져오기",
  Entire_Export:"전체 내보내기",
  Existing_Password:"기존 비밀번호를 입력하세요:",
  Existing_PIN:"기존 PIN 코드를 입력하세요.",
  Pin_Change_Header:"PIN 확인",
  Pin_Change_SubHeader:"변경 사항을 확인하려면 이전 PIN 번호를 입력하세요.",
  Pass_Change_Header:"비밀번호 확인",
  Pass_Change_SubHeader:"변경 사항을 확인하려면 이전 비밀번호를 입력하세요.",
  PIN_Enter_Message:"PIN을 입력하여 확인하세요.",
  Password_Enter_Message:"확인을 위해 비밀번호를 입력하세요.",
  Pin_Updated_Success:"PIN이 성공적으로 업데이트되었습니다!",
  Pin_Updated_Fail:"PIN을 업데이트할 수 없습니다.",
  Pass_Updated_Success:"비밀번호가 성공적으로 업데이트되었습니다.",
  Pass_Updated_Fail:"비밀번호를 업데이트할 수 없습니다.",
  Preview_Payment:"결제 미리보기",
  Preview_Payroll:"급여 미리보기",
  No_Payments_Created:"생성된 결제가 없습니다.",
  Payment_Preview:"결제 미리보기",
  Enable_Payee:"수취인 활성화",
  Rendered:"렌더링",
  No_Email:"이메일 없음",
  Setup_Cheques:"설정 확인",
  name:"이름",
  address:"주소",
  address_2:"주소 2",
  city:"도시",
  province:"주",
  postal_code:"우편번호",
  country:"국가",
  username:"사용자 이름",
  full_name:"성명",
  birthday:"생신",
  email:"이메일",
  phone:"핸드폰",
  employees:"직원",
  addresses:"구애",
  payment_amount_limit:"지불 금액 한도",
  total_limit:"총 한도",
  payees:"수취인",
  description:"설명",
  address_line_one:"주소 입력란 1",
  address_line_two:"주소 입력란 2",
  image:"영상",
  account_holder:"계정 소유자",
  cheque_starting_id:"시작 ID 확인",
  transit_number:"대중교통 번호",
  institution_number:"기관 번호",
  designation_number:"지정번호",
  account_number:"계좌 번호",
  currency:"통화",
  signature:"서명",
  payment_payroll_limit:"지불 한도",
  total_limi:"총 한도",
  date:"날짜",
  printed_memo:"인쇄된 메모",
  banks:"은행",
  signature_image:"서명 이미지",
  address_name:"주소 명",
  notes:"메모",
  design:"설계",
  title:"제목",
  frequency:"빈도",
  fax:"팩스",
  salaries:"급여",
  salary_ids:"급여 ID",
  start_time:"시작 시간",
  end_time:"종료 시간",
  paid:"유급의",
  overtime_percentage:"유료 비율",
  overtime_amount:"지급 고정 금액",
  first_name:"이름",
  last_name:"성",
  moderation:"절도",
  create:"만들다",
  edit:"편집하다",
  destroy:"파괴하다",
  day_start_time:"Day_start_time",
  day_end_time:"Day_end_time",
  fullname:"이름",
  time:"시간",
  auto_send:"자동으로 보내기",
  time_method:"시간 방법",
  schedules:"일정",
  indefinite_payroll_enabled:"무기한 활성화",
  amount:"양",
  repeat:"반복하다",
  always_enabled:"항상 사용",
  start_date:"시작일",
  end_date:"종료일",
  Cheque_Total:"합계 확인",
  Total_Amount:"총액:",
  Amounts:"금액:",
  Images:"이미지",
  Files:"파일",
  Previewing:"미리보기:",
  Insert:"끼워 넣다",
  Preview_Import:"미리보기 가져오기",
  Entry:"기입",
  Entries:"엔트리",
  No_Entries:"항목 없음",
  Import_Type:"가져오기 유형",
  Select_Details:"세부 정보 선택",
  Select_Payee:"수취인 선택",
  Enable_Holidays:"휴일 활성화",
  Disable_Holidays:"휴일 비활성화",
  Wire_Transfer:"전신환 송금",
  New_Export:"새 내보내기",
  Export_Data:"데이터 내보내기",
  Export_Data_Word:"내보내고 다운로드할 파일 형식을 선택합니다.",
  Export_File:"파일 내보내기",
  Mode:"방법",
  Times:"타임스",
  Widgets:"위젯",
  Slider:"슬라이더",
  Set_Details:"세부 사항 설정",
  Select_Type:"유형 선택",
  Display_Slider:"디스플레이 슬라이더",
  Dashboard_Slider:"대시보드 슬라이더",
  Dashboard_Mode:"대시보드 모드",
  Show_Intro:"소개 보기",
  Show_Payrolls:"급여 표시",
  Show_Holidays:"휴일 표시",
  Show_Invoices:"송장 표시",
  Show_Cheques:"수표 표시",
  Enabled_Widgets:"활성화된 위젯",
  Disabled_Widgets:"비활성화된 위젯",
  Colors:"그림 물감",
  Barcodes:"바코드",
  View_Timers:"타이머 보기",
  Gradients:"그라디언트",
  No_Info:"정보 없음",
  Disable:"장애를 입히다",
  Show_Layer:"레이어 표시",
  Hide_Layer:"레이어 숨기기",
  Text_Layer:"텍스트 레이어",
  Secondly:"두 번째로",
  Minutely:"상세히",
  nine_am:"오전 9시",
  five_pm:"오후 5시",
  Enable_Address:"주소 활성화",
  Invalid_File_Type:"죄송합니다. 잘못된 파일 형식이 선택되었습니다. 지원되는 파일 형식:",
  Error_Updating_Entry:"죄송합니다. 이 항목을 업데이트하는 동안 오류가 발생했습니다.",
  Schedule_Timing_Alert:"오류: 일정 시작 시간이 종료 시간 이후의 값으로 설정되었습니다.",
  Select_Multiple_Payments_Prompt:"선택한 결제가 없습니다. 결제를 하나 이상 선택하세요.",
  Select_Multiple_Cheques_Prompt:"선택된 체크가 없습니다. 체크를 하나 이상 선택하십시오.",
  Select_Multiple_Items_Prompt:"선택된 항목이 없습니다. 항목을 하나 이상 선택하십시오.",
  Paymemt_Multiple_Delete_Confirmation:"여러 결제를 삭제하시겠습니까?",
  Cheque_Multiple_Delete_Confirmation:"여러 수표를 삭제하시겠습니까?",
  Payee_Multiple_Delete_Confirmation:"여러 수취인을 삭제하시겠습니까?",
  Employee_Multiple_Delete_Confirmation:"여러 직원을 삭제하시겠습니까?",
  MICR_Warning:"참고: 일부 프린터 및 장치는 MICR 글꼴을 올바르게 표시하지 않을 수 있습니다.",
  Automatically_Send:"자동으로 보내기",
  Type:"유형",
  Payment_Type:"지불 유형",
  Auto_Send:"자동 전송",
  Automatically_Process:"자동 처리",
  Auto_Process:"자동 프로세스",
  Image_Based:"이미지 기반",
  Font_Based:"글꼴 기반",
  Rerender:"다시 렌더링",
  Rendering:"표현",
  Render:"파일",
  Top:"맨 위",
  Middle:"가운데",
  Bottom:"맨 아래",
  Top_Left:"왼쪽 상단",
  Top_Center:"상단 중앙",
  Top_Right:"오른쪽 상단",
  Middle_Left:"가운데 왼쪽",
  Middle_Center:"중간 센터",
  Middle_Right:"가운데 오른쪽",
  Bottom_Left:"왼쪽 하단",
  Bottom_Center:"하단 중앙",
  Bottom_Right:"오른쪽 하단",
  Numbers:"번호",
  Verified:"확인됨",
  Paper_Size:"용지 크기",
  New_Device:"새 장치",
  Add_Device:"기기 추가",
  Remove_Device:"장치를 제거",
  Delete_Device:"장치 삭제",
  Block_Device:"차단 장치",
  Block:"차단하다",
  Unblock:"차단 해제",
  Table:"테이블",
  Scan_Login_Code:"로그인 코드 스캔",
  Login_Code:"로그인 코드",
  Scan_Code:"스캔 코드",
  Scan_QR_Code:"QR 코드 스캔",
  Select_All:"모두 선택",
  Deselect_All:"모두 선택 해제",
  Increase:"증가하다",
  Decrease:"감소하다",
  Bold:"용감한",
  Text:"텍스트",
  Style:"스타일",
  Italic:"이탤릭체",
  QR_Code:"QR 코드",
  Barcode:"바코드",
  Browse_Images:"이미지 찾아보기",
  Browse_Files:"파일 찾아보기",
  Background_Image:"배경 이미지",
  Logo_Image:"로고 이미지",
  Header_Image:"헤더 이미지",
  Bank_Image:"은행 이미지",
  Cut_Lines:"절단선",
  Background:"배경",
  License:"특허",
  One_License:"1 라이센스:",
  Licensed:"라이선스:",
  Not_Licensed:"라이센스 없음",
  Enter_Serial:"시리얼 입력",
  Serial_Key:"시리얼 키",
  Serial:"연속물",
  Product_Key:"제품 키",
  Check_Product_Key:"제품 키 확인",
  Add_Product_Key:"제품 키 추가",
  Verifying_Purchase:"구매 확인 중...",
  Print_Envelope:"봉투 인쇄",
  Envelope:"봉투",
  Thank_You:"감사합니다!",
  Scale:"규모",
  Print_Scale:"인쇄 스케일",
  Borders:"테두리",
  VOID:"무효의",
  Void_Cheque:"보이드 체크",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"다음의 순서로 지불:",
  NO_DOLLARS:"달러 없음 ",
  ONE_DOLLAR:"1 달러",
  DOLLARS:" 불화",
  AND:" 그리고 ",
  CENTS:" 센트.",
  NO_:"아니 ",
  ONE_:"하나 ",
  AND_NO_:"그리고 아니오 ",
  _AND_ONE_:"그리고 하나 ",
  DOLLARS_AND_ONE_CENT:" 그리고 1센트.",
  AND_NO_CENTS:" 그리고 제로 센트.",
  CHEQUE_PRINT_DATE:"데이트:",
  CHEQUE_PRINT_MEMO:"메모:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"의원",
  Initial_Tasks:"초기 작업",
  Inital_Setup:"초기 설정",
  Welcome_To:"에 오신 것을 환영합니다 ",
  Welcome:"어서 오십시오",
  Swipe:"강타",
  Intro_Setup:"소개 설정",
  Introduction:"소개",
  CHEQUE_PRINT_CREDIT:"수표로 전원 공급",
  Title_Intro_Word:"수표에 오신 것을 환영합니다",
  Title_Intro:"수표 소개",
  Title_Setup:"수표 설정",
  PayPal_Default_Email_Message:"새 PayPal 송금을 받았습니다. [Powered by Cheques]",
  Cheques_App_Export:"수표로 수출",
  Post_Intro_Thanks:"수표를 선택해 주셔서 감사합니다. 이제 설정 프로세스가 완료되었습니다.",
  Post_Intro_Word:"첫 번째 수표를 인쇄하여 시작하거나, 향후 지불을 추가하거나, 급여에 직원을 추가하십시오.",
  Upgrade_Required:"수표를 사용하려면 이 메시지를 숨기고 추가 기능을 잠금 해제하려면 더 고급 버전의 소프트웨어를 소유해야 합니다.",
  Upgrade_Title:"체크 무늬",
  Mailout_Prompt:"수표가 급여 수표를 우편으로 발송하도록 추가로 선택할 수 있습니다.",
  Mailed_Cheque:"우편 수표: ",
  Mailed_Cheque_Color:"우편 수표(색상): ",
  Terms_Purchase:"수표를 사용한 모든 전자적 구매는 구매일로부터 최대 30일 동안 전액 환불됩니다. 계속하기 전에 <a href='#'>이용약관</a>을 읽고 동의하십시오.",
  Dashboard_Setup:"기본 프린터 설정",
  Dashboard_Add:"기본 은행 계좌 추가",
  Dashboard_Customize:"수표 템플릿 선택",
  Dashboard_Job_Salary:"직장을 만들고 급여를 추가하십시오",
  Dashboard_Employees:"직원 및 수취인 추적",
  Dashboard_Print:"당신의 급여를 인쇄하고 우편으로 보내십시오",
  Dashboard_Payroll:"급여 인쇄 자동화",
  Dashboard_PayPal:"PayPal 급여/지급금 설정",
  Begin_Setup:"설정 시작",
  Get_Started:"시작하다",
  Purchase:"구입",
  Lockdown:"폐쇄",
  Unlock:"터놓다",
  Detailed:"상세한",
  Log_In:"로그인",
  Login:"로그인",
  Launch:"시작하다",
  Register:"등록하다",
  Finish:"마치다",
  List:"목록",
  Weekends:"주말",
  Weekly:"주간",
  PayPal_Default_Subject:"새 지불",
  Payment_Message:"결제 메시지",
  PayPal_Default_Payment_Note:"감사 해요",
  Setup_Your_Cheqing_Account:"수표 계좌",
  Add_Your_Primary_Cheqing_Account:"기본 당좌 예금 계좌를 추가하십시오.",
  Welcome_Word:"급여 및 인적 자원 관리를 단순화하고 자동화하십시오.",
  Get_Started_Quickly:"시작하는 데 도움이 되는 몇 가지 간단한 질문에 답하세요...",
  Done_Intro_Word:"설정 완료",
  PIN_Protected:"비밀번호 및 PIN으로 보호됨",
  Enter_New_PIN:"새 PIN 코드 입력:",
  Enter_PIN:"PIN 코드 입력:",
  Invalid_PIN:"잘못된 PIN을 입력했습니다.",
  Account_Identifier:"계정 식별자",
  New_Account_Identifier:"새 계정 식별자",
  attempt:"시도",
  attempts:"시도",
  remaining:"남은",
  Language:"언어",
  languages:"언어",
  select_languages:"언어 선택",
  Deposit:"보증금",
  Hire_One_Now:"지금 고용하세요",
  App_Locked:"응용 프로그램이 잠겨 있습니다.",
  Skip_:"건너 뛰다",
  Skip_to_Dashboard:"대시보드로 건너뛰기",
  Dashboard:"계기반",
  Import:"수입",
  Admin:"관리자",
  New_Admin:"새 관리자",
  Settings:"설정",
  Color_Picker:"색상 선택기",
  Font_Picker:"글꼴 선택기",
  Logout:"로그 아웃",
  Close:"닫다",
  Close_menu:"닫다",
  Excel:"엑셀 파일",
  Csv:"CSV 파일",
  Sql:"SQL 파일",
  Json:"JSON 파일",
  Url:"URL로 가져오기",
  Back:"뒤",
  Timers:"타이머",
  Cheque:"확인하다",
  Print:"인쇄",
  Designs:"디자인",
  Pause_Printing:"인쇄 일시 중지",
  Resume_Printing:"인쇄 재개",
  Printing_Paused:"인쇄 일시 중지",
  PrintingUnavailable:"죄송합니다! 이 시스템에서는 인쇄할 수 없습니다.",
  Prints_Paused:"인쇄 일시 중지",
  Administration:"관리",
  Loading:"로드 중",
  Unnamed:"무명",
  error:"죄송합니다. 이 수표를 보기 위해 열 수 없습니다.",
  No_Cheques_To_Print:"인쇄할 수표 없음",
  No_Cheques_To_Print_Word:"<a routerLink='/folder/Cheque/New'>새 수표</a>를 만드십시오.",
  New_Cheque:"새로운 체크",
  Start_One_Now:"지금 시작하세요",
  Edit_Cheque:"편집 확인",
  Select_Cheques:"체크 선택",
  Select_Employee:"직원 선택",
  Default_Printer:"기본 프린터",
  Reassign:"재할당",
  Configure:"구성",
  Template:"주형",
  Designer:"디자이너",
  Edit_Designs:"디자인 편집",
  New_Design:"새로운 디자인",
  Next:"다음",
  Save:"구하다",
  Name:"이름",
  Mail:"우편",
  Amount:"양",
  Date:"날짜",
  PayPal:"페이팔",
  Payouts:"지급금",
  PayPal_Label:"페이팔 레이블",
  Email_Username:"이메일 / 사용자 이름",
  Client_ID:"클라이언트 ID",
  Sandbox_Email:"샌드박스 이메일",
  PayPal_Email:"페이팔 이메일",
  PayPal_Username:"API 사용자 이름",
  PayPal_Payouts:"페이팔 지불",
  Select_PayPal_Key:"PayPal 키 선택",
  Secret:"비밀",
  API_Secret:"API 비밀",
  PayPal_API_Keys:"페이팔 키",
  New_PayPal_Key:"새 페이팔 키",
  Email_Subject:"이메일 제목",
  Email_Message:"이메일 메시지",
  Payout_Options:"지급 옵션",
  Payment_Note:"결제 메모",
  Enable_Employee:"직원 활성화",
  Enable_Production_Mode:"생산 모드 활성화",
  Sandbox_Username:"샌드박스 사용자 이름",
  Sandbox_Signature:"샌드박스 서명",
  Sandbox_Password:"샌드박스 비밀번호",
  Production_Username:"프로덕션 사용자 이름",
  Production_Signature:"제작 서명",
  Production_Password:"생산 비밀번호",
  Production_Email:"제작 이메일",
  API_Client_ID:"API 클라이언트 ID",
  API_Signature:"API 서명",
  API_Password:"API 비밀번호",
  API_Username:"API 사용자 이름",
  Secret_Key:"비밀열쇠",
  Sandbox:"모래 상자",
  Production:"생산",
  Sandbox_Keys:"샌드박스 키",
  Production_Keys:"생산 키",
  API_Title:"API 제목",
  Production_Mode:"생산 모드",
  Account_Label:"계정 레이블",
  No_PayPal_Setup:"페이팔 키 없음",
  Enable_PayPal_Account:"페이팔 계정 활성화",
  No_PayPal_Word:"<a routerLink='/folder/Invoice/New'>PayPal API 키</a>를 추가하세요.",
  Printed_Memo:"인쇄된 메모",
  Employee:"직원",
  View_Employee:"직원보기",
  Mailing_Address:"우편 주소",
  Company_Address:"회사 주소",
  Select_Company_Address:"회사 주소 선택",
  Address:"주소",
  Any_Day:"모든 일",
  Address_Name:"주소 명",
  Unit:"단위",
  Account:"계정",
  Bank_Account:"은행 계좌",
  Account_Limits:"계정 한도 활성화",
  Payroll:"급여",
  New_Payroll:"새 급여",
  No_Payroll:"예정 급여 없음",
  Upcoming_Holiday:" 다가오는 휴일 : ",
  Invoice_Due:"인보이스 만기:",
  New_Invoice:"새 송장",
  No_Invoices:"인보이스 없음",
  No_Invoices_Word:"첫 번째 <a routerLink='/folder/Invoice/New'>인보이스</a>를 생성합니다.",
  Cheque_Due:"수표 만기:",
  Payrolls:"급여",
  Sandbox_Mode:"샌드 박스 모드",
  Hire:"고용하다",
  Pay:"지불",
  New:"새로운",
  Add:"추가하다",
  Make:"만들다",
  Time:"시간",
  Write:"쓰다",
  Holiday:"휴일",
  Holidays:"휴가",
  Next_Holiday:"다음 휴일:",
  All_Done:"다 끝났어!",
  Employees:"직원",
  Payees:"수취인",
  Job:"직업",
  Jobs:"취업",
  Invoice:"송장",
  Invoices:"인보이스",
  Vacations:"휴가",
  Cheques:"체크 무늬",
  Brand_Cheques:"상표",
  Payment:"지불",
  Enable_Payment:"결제 활성화",
  Payments:"결제",
  Schedule:"일정",
  Schedules:"일정",
  Timesheet:"시간표",
  Timesheets:"타임 시트",
  Salary:"샐러리",
  New_Address:"새 주소",
  Address_2:"주소 2)",
  _City:"도시",
  _State:"주/증명",
  City:"시 / 타운십",
  State:"주/도",
  Postal:"우편/우편번호",
  ZIP:"우편 / 우편 번호",
  Country:"국가",
  Addresses:"구애",
  Required_Options:"필수 옵션",
  Optional_Options:"선택적 옵션",
  Additional_Options:"추가 옵션",
  Required:"필수의",
  Optional:"선택 과목",
  Additional:"추가의",
  No_Addresses:"주소 없음",
  New_Address_Word:"첫 번째 <a routerLink='/folder/Address/New'>주소</a>를 추가하십시오.",
  Select_Address:"주소 선택",
  No_Address:"주소 없음",
  Print_Cheque:"인쇄 확인",
  Print_Cheque_Now:"지금 인쇄 확인",
  Description:"설명",
  Pay_To_The_Order_Of:"다음의 순서로 지불:",
  Select_Date_Range:"날짜 범위 선택",
  Select_Starting_Date:"시작 날짜 선택",
  Select_Ending_Date:"종료일 선택",
  Select_Date:"날짜 선택",
  Cheque_Date:"날짜 확인",
  Cheque_Memo:"메모 확인",
  Blank_Cheque:"백지 수표",
  Blank:"공백",
  No_Cheques:"수표 없음",
  No_Cheques_Word:"첫 번째 <a routerLink='/folder/Cheque/New'>수표</a>를 인쇄하십시오.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"이미지 보기",
  View:"보다",
  Modify:"수정하다",
  Delete:"삭제",
  Cheque_Paid:"유급의",
  New_Deduction:"새로운 공제",
  Title:"제목",
  Frequency:"빈도",
  Hourly:"시간별",
  Daily:"일일",
  Weekdays:"평일",
  BiWeekly:"이주",
  TriWeekly:"3 주",
  Monthly:"월간 간행물",
  MiMonthly:"2 개월",
  Quarterly:"계간지",
  Yearly:"매년",
  Every_Week:"매주",
  Every_Payroll:"모든 급여",
  Every_Month:"매 달",
  Annually:"매년",
  Always_Scheduled:"항상 예정",
  Start_Date:"시작일",
  End_Date:"종료일",
  Start_Time:"시작 시간",
  End_Time:"종료 시간",
  Deduction_Label:"공제 라벨",
  Notes:"노트",
  Options:"옵션",
  Enable:"할 수있게하다",
  Select_Deductions:"공제 선택",
  Deductions:"공제",
  No_Deductions:"공제 없음",
  No_Deductions_Word:"첫 번째 <a routerLink='/folder/Deduction/New'>공제</a>을 만드세요.",
  New_Employee:"신입 사원",
  No_Title:"제목 없음",
  _Name:"이름",
  About_Yourself:"자신에 대해",
  Full_Name:"이름",
  Birthday:"생일",
  Email:"이메일",
  SMS:"SMS",
  Phone:"핸드폰",
  Test:"테스트",
  Call:"부르다",
  Fax:"팩스",
  Printed_Note:"인쇄된 메모",
  Position:"위치",
  Job_Position:"직위",
  Select_a_Job:"직업 선택",
  Select_a_Salary:"급여를 선택하십시오",
  Add_a_Deduction:"공제 추가",
  Taxes:"구실",
  Add_Taxes:"세금 추가",
  Date_of_Birth:"생일",
  Email_Address:"이메일 주소",
  Phone_Number:"전화 번호",
  Phone_Call:"전화",
  Enable_on_Payroll:"급여에서 활성화",
  Select_Employees:"직원 선택",
  No_Employees:"직원 없음",
  No_Employees_Word:"첫 번째 새 <a routerLink='/folder/Employee/New'>직원</a>을 추가하세요.",
  No_Name:"이름 없음",
  Unemployeed:"한가한",
  Employeed:"취업",
  Paid:"유급의",
  Enabled:"활성화",
  Disabled:"장애가있는",
  Fire:"불",
  Not_Available:"사용할 수 없습니다",
  Not_Available_Word:"첫 번째 <a routerLink='/folder/Invoice/New'>인보이스</a>를 인쇄하고 급여를 받으십시오.",
  Select_Invoices:"Select_Invoices",
  Print_Invoice_Word:"첫 번째 <a routerLink='/folder/Invoice/New'>인보이스</a>를 인쇄하고 급여를 받으십시오.",
  Invoice_Title:"인보이스 제목",
  Invoice_Date:"송장 날짜",
  Due_Date:"마감일",
  New_Job:"새 직업",
  Details:"세부",
  Customize:"사용자 정의",
  Customize_Dashboard:"대시보드 사용자 정의",
  Components:"구성 요소",
  No_Components:"구성 요소 없음",
  Main_Components:"주요 구성 요소",
  Smaller_Components:"작은 구성 요소",
  Error_Loading_Page:"이 페이지를 로드하는 동안 오류가 발생했습니다.",
  Bank_Details:"은행 계좌 정보",
  About_Your_Job:"당신의 직업에 대해",
  Your_Schedule:"당신의 스케쥴",
  Job_Title:"직책",
  Job_Description:"업무 설명서",
  Job_Details:"작업 세부 정보",
  Enable_Job:"작업 활성화",
  Pay_Period:"지급 기간",
  Perpetually_Schedule:"영구 일정",
  Select_Jobs:"작업 선택",
  No_Jobs:"작업 없음",
  Add_Jobs:"작업 추가",
  No_Jobs_Word:"목록에 첫 번째 <a routerLink='/folder/Job/New'>작업</a>을 추가합니다.",
  Count_Employees:"job.employee_count+' 직원'",
  Zero_Employees:"직원 0명",
  New_Leave:"새로운 휴가",
  Leave_Name:"이름 남기기",
  Paid_Leave:"유급 휴가",
  Leave_Pay:"퇴직금",
  Indefinite_Leave:"무기한 휴가",
  Indefinite_Payroll:"무기한 급여",
  Leave:"떠나다",
  Add_Schedules:"일정 추가",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"부재 활성화",
  Select_Leaves:"잎 선택",
  No_Leaves:"휴식 없음",
  Leave_Of_Absence:"휴직",
  Leaves_Of_Absence:"부재의 잎",
  New_Leave_of_Absense:"새로운 휴가",
  No_Leaves_Word:"첫 번째 <a routerLink='/folder/Leave/New'>휴가</a>를 추가하세요.",
  Not_Enabled:"활성화되지 않음",
  Absences:"결석",
  Payee:"수취인",
  New_Payee:"새 수취인",
  Payee_Identifier:"수취인 식별자",
  Payee_Name:"수취인 이름",
  Payee_Title:"수취인 제목",
  Payment_Memo:"결제 메모",
  Select_Payees:"수취인 선택",
  No_Payees:"수취인 없음",
  Add_Payee_Note:"첫 번째 <a routerLink='/folder/Payee/New'>수취인</a>을 추가합니다.",
  New_Payees:"새 수취인",
  About_The_Payment_Schedule:"지불 일정",
  Your_Payroll_Schedule:"자동 급여",
  New_Payment:"새 지불",
  Identifier:"식별자",
  Select:"선택하다",
  Memo:"메모",
  Payment_Date:"결제일",
  Mark_as_Paid:"유료로 표시",
  Select_Payments:"결제 선택",
  No_Payments:"결제 없음",
  No_Payments_Word:"첫 번째 <a routerLink='/folder/Payment/New'>지급</a>을 만듭니다.",
  Create_Payroll:"급여 생성",
  Properties:"속성",
  Payroll_Title:"급여 제목",
  Payroll_Notes:"급여 메모",
  Payroll_Setup:"급여 설정",
  Tabulate_Payments:"결제표",
  Tabulate:"표로 만들다",
  By:"에 의해:",
  Payments_By:"결제 기준",
  Timesheets_And_Schedules:"시간표 및 일정",
  Both:"둘 다",
  Items:"항목",
  Add_Payees:"수취인 추가",
  Add_Account:"계정을 추가",
  Enable_Account:"계정 활성화",
  Enable_Payroll:"급여 활성화",
  Print_Payroll:"급여 인쇄",
  No_Payrolls:"급여 없음",
  No_Payroll_Word:"첫 번째 <a routerLink='/folder/Payroll/New'>급여</a>를 만드십시오.",
  View_more:"더보기",
  Less:"더 적은",
  Add_Payroll:"급여 추가",
  Select_Payroll:"급여 선택",
  About_Your_Salary:"당신의 급여에 대해",
  Add_Salaries:"급여 추가",
  Add_Salary:"급여 추가",
  Salaries:"급여",
  No_Salaries:"급여가 없다",
  No_Salaries_Word:"첫 번째 <a routerLink='/folder/Salary/New'>급여</a>를 추가하세요.",
  Select_Salaries:"급여 선택",
  New_Salary:"새 급여",
  Salary_Name:"급여 식별자",
  Enable_Salary:"급여 활성화",
  Salary_Amount:"급여 금액",
  New_Schedule:"새 일정",
  Schedule_Title:"스케줄 제목",
  Add_Address:"주소 추가",
  Repeat:"반복하다",
  Design:"설계",
  Edit_Design:"디자인 편집",
  Edit_this_Design:"이 디자인 편집",
  Repeat_Payment:"반복 결제",
  Enable_Schedule:"일정 활성화",
  Never:"절대",
  Select_Schedule:"일정 선택",
  No_Schedules:"일정 없음",
  No_Schedules_Word:"첫 번째 <a routerLink='/folder/Schedule/New'>일정</a>을 만듭니다.",
  New_Administrator:"새 관리자",
  Username:"사용자 이름",
  First_Name:"이름",
  Last_Name:"성",
  Add_an_Address:"주소 추가",
  Payment_Limit:"결제당 한도",
  Total_Limit:"총 한도",
  Select_Accounts:"계정 선택",
  No_Administrators:"관리자 없음",
  No_Administrators_Word:"첫 번째 <a routerLink='/folder/Administrator/New'>관리자 계정</a>을 만드십시오.",
  New_Administrators_Word:"첫 번째 <a routerLink='/folder/Administrator/New'>관리자</a> 추가",
  Cloud:"구름",
  Backup:"지원",
  Enable_iCloud:"iCloud 활성화",
  Enable_Google_Drive:"구글 드라이브 활성화",
  Enable_Microsoft_OneDrive:"Microsoft OneDrive 활성화",
  Automatically_Backup:"자동 백업",
  FTP_Settings:"FTP 설정",
  URL_File_Prompt:"가져올 .xls / .xlsx / .json 파일의 위치를 ​​지정하십시오:",
  URL_SQL_Prompt:"가져올 SQLite 파일의 위치를 ​​지정하십시오:",
  FTP_Backup:"FTP 백업",
  FTP_Import:"FTP 가져오기",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"주최자",
  Port:"포트",
  Path:"길",
  Data_Path:"데이터 경로",
  Enable_FTP_Account:"FTP 계정 활성화",
  HostnameIP:"호스트 이름",
  Password:"비밀번호",
  Connection_Port:"연결 포트",
  Enable_MySQL_Database:"MySQL 데이터베이스 활성화",
  Log:"통나무",
  Reset:"초기화",
  Erase:"삭제",
  Export:"내 보내다",
  Database:"데이터 베이스",
  Upload_CSV:"CSV 업로드",
  Download_CSV:"CSV 다운로드",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL 백업",
  Internal_Notes:"내부 메모",
  Root_Path:"루트 경로",
  Select_Backup:"백업 선택",
  Add_New_Backup:"새 백업 추가",
  First_Backup:"첫 번째 백업 설정...",
  Backups:"백업",
  Add_Backup:"백업 추가",
  No_Backups:"백업을 찾을 수 없습니다.",
  Select_Backup_Type:"설정하려는 백업 유형을 선택하십시오...",
  Backup_Type:"백업 유형",
  FTP_Drive:"FTP 드라이브",
  URL_Location:"URL",
  Apple_iCloud:"아이클라우드",
  Google_Drive:"운전하다",
  Microsoft_OneDrive:"운전하다",
  Import_Fields:"가져오기 필드",
  Import_Fields_Word:"이 섹션을 사용하여 <a routerLink='/folder/Holiday/New'>가져오기</a> 데이터를 선택하십시오.",
  Upload:"업로드",
  Download:"다운로드",
  Download_JSON:"JSON 데이터로 다운로드",
  Download_SQL:"SQL 파일로 다운로드",
  New_Bank:"새 은행",
  New_Account:"새 계정",
  New_Bank_Account:"새 은행 계좌",
  Upload_Image:"이미지 업로드",
  Bank_Name:"은행 이름",
  Bank_Address:"은행 주소",
  Branch_Address:"지점 주소",
  Address_on_Cheque:"주소",
  Cheque_Numbers:"번호 확인",
  Cheque_Details:"세부사항 확인",
  Bank_Logo:"은행 로고",
  Cheque_ID:"아이디 확인",
  Starting_Cheque_ID:"ID 확인 시작",
  Transit_Number:"교통 번호",
  Institution_Number:"기관 번호",
  Designation_Number:"지정번호",
  Account_Number:"계좌 번호",
  Limits:"제한",
  Default_Limits:"기본 제한",
  Over_Limit:"한도 초과",
  Under_Limit:"한도 미만",
  Payroll_Limit:"급여 한도",
  Enable_Bank_Account:"은행 계좌 활성화",
  Select_Account:"계정 선택",
  No_Bank_Account:"은행 계좌 없음",
  No_Bank_Account_Word:"첫 번째 <a routerLink='/folder/Accounts/New'>은행 계좌</a>를 추가하십시오.",
  Bank_Accounts:"은행 계좌",
  No_Accounts:"계정 없음",
  No_Accounts_Note:"첫 번째 <a routerLink='/folder/Accounts/New'>은행 계좌</a>를 추가하십시오.",
  Cheque_Designer:"디자이너 확인",
  Cheque_Design:"디자인 확인",
  Select_Design:"디자인 선택",
  Cheque_Designs:"디자인 확인",
  No_Cheque_Designs:"노 체크 디자인",
  No_Cheque_Designs_Word:"나만의 <a routerLink='/folder/Settings/Cheque/Design/New'>체크 디자인</a>을 만드십시오.",
  Set_Default:"기본값으로 설정",
  Default:"기본",
  Remove:"제거하다",
  Folder:"폴더",
  Edit:"편집하다",
  LoadingDots:"로드 중...",
  Add_a_New_File:"다음에 <a href='#' (click)='add()'>새 파일</a> 추가",
  this_folder:"이 폴더",
  FTP_Backup_Settings:"FTP 백업 설정",
  Secure_File_Transfer:"보안 파일 전송",
  New_Holiday:"새로운 휴일",
  Add_Holiday:"공휴일 추가",
  Holiday_Name:"공휴일 이름",
  Additional_Pay:"추가 급여",
  Enable_Holiday:"휴일 활성화",
  Select_Holidays:"휴일 선택",
  No_Holidays:"휴일 없음",
  No_Holidays_Word:"첫 번째 <a routerLink='/folder/Holiday/New'>공휴일</a>을 추가합니다.",
  New_Import:"새 가져오기",
  Import_Data:"데이터 가져오기",
  Import_Data_Word:"선택한 파일 유형 또는 업로드 방법을 선택하십시오.<br /> 지원되는 파일을 업로드한 후 앱의 매개변수에 해당하는 파일의 가져온 필드를 선택할 수 있습니다.", 
  Import_File:"파일 가져오기",
  Link_To_File:"파일에 링크",
  Select_File:"파일 선택",
  New_Moderator:"새로운 중재자",
  Allow_Moderation:"조정 허용",
  Create_Paycheques:"급여 만들기",
  Edit_Paycheques_and_Data:"급여 및 데이터 편집",
  Destroy_Data_and_Paycheques:"데이터 및 급여 파괴",
  Bonus_Percentage:"연봉 비율",
  Fixed_Amount:"정액",
  Select_Moderator:"진행자 선택",
  No_Moderators:"진행자 없음",
  Moderators:"진행자",
  Moderator_Account:"첫 번째 <a routerLink='/folder/Administrator/New'>조정자 계정</a>을 만드십시오.",
  No_Moderators_Word:"첫 번째 <a routerLink='/folder/Administrator/New'> 중재자</a>를 추가하십시오.",
  Defaults:"기본값",
  Provide_Us_Info:"정보 제공",
  Setup_Your_Printer:"프린터 설정",
  Your_Company:"회사 소개",
  Company_Name:"회사 이름",
  Currency:"통화",
  Default_Currency:"기본 통화",
  Base_Monthly_Income:"월 소득",
  Protection:"보호",
  App_Protection:"앱 보호",
  PIN_Code_Protection:"PIN 코드 보호",
  App_Protection_Word:"계정을 보호하는 데 도움이 되는 보안 방법을 활성화하십시오.",
  PIN_Code:"핀 코드",
  Change_PIN:"비밀번호 변경",
  New_Password:"새 비밀번호",
  Geofence_Protection:"지오펜스 보호",
  Geofence_Area:"영역 설정",
  Distance:"거리",
  Setup_Now:"지금 설정",
  Mile:"마일",
  Km:"킴",
  m:"중",
  Facial_Recognition:"얼굴 인식",
  Face:"얼굴",
  Setup:"설정",
  Label:"상표",
  Password_Protection:"비밀번호 보안",
  Modify_Password:"비밀번호 수정",
  New_Tax_Entry:"새로운 세금 항목",
  New_Tax:"새로운 세금",
  Tax_Label:"세금 라벨",
  Perpetually_Enabled:"영구적으로 사용 가능",
  Select_Taxes:"세금 선택",
  Tax_Deductions:"세금 공제",
  No_Tax_Deductions:"세금 공제 없음",
  No_Tax_Deductions_Word:"첫 번째 <a routerLink='/folder/Tax/New'>세금</a> 공제를 추가하십시오.",
  New_Timer:"새 타이머",
  Start:"시작",
  Stop:"중지",
  Start_Timer:"타이머 시작",
  Stop_Timer:"타이머 중지",
  Timer_Active:"타이머 활성",
  Timer:"시간제 노동자:",
  Timer_Running:"타이머: (실행 중)",
  Save_Timer:"타이머 저장",
  New_Timesheet:"새 시간표",
  Select_Timesheets:"시간표 선택",
  Work_Notes:"작업 노트",
  Entry_Title:"항목 제목",
  No_Timesheets:"시간표 없음",
  No_Timesheets_Word:"첫 번째 <a routerLink='/folder/Timesheet/New'>작업표</a>를 추가합니다.",
  Timesheet_Submitted:"타임 시트 제출",
  Timesheet_Congrats:"축하합니다! 시간표가 성공적으로 제출되었습니다. 감사합니다!",
  Timesheet_Copy:"문서 사본을 받으려면 이메일 주소 및/또는 휴대폰 번호를 알려주십시오.",
  Submit:"제출하다",
  Allow_Notifications:"알림 허용",
  Untitled_Entry:"새 항목",
  Untitled_Bank:"무제 은행",
  Timesheet_Entry:"타임 시트 입력",
  Work_Description:"작업 설명",
  Enable_Timesheet:"시간표 활성화",
  Submit_Timesheet:"시간표 제출",
  Vacation:"휴가",
  New_Vacation:"새로운 휴가",
  Vacation_Name:"휴가 이름",
  Paid_Vacation:"유급 휴가",
  Vacation_Pay:"휴가 수당",
  Enable_Vacation:"휴가 활성화",
  Select_Vacations:"휴가 선택",
  No_Vacations:"휴가 없음",
  No_Vacations_Word:"첫 번째 <a routerLink='/folder/Vacation/New'>휴가</a> 항목을 만드십시오.",
  Payroll_Schedule:"급여 일정",
  Next_Payroll:"다음 급여:",
  Upcoming_Payroll:"예정 급여",
  No_Upcoming_Payroll:"예정 급여 없음",
  Address_Book:"주소록",
  Archived_Documents:"보관된 문서",
  Dev_Mode:"개발 모드의 응용 프로그램",
  Administrators:"관리자",
  Privacy:"은둔",
  None:"없음",
  Select_Signature:"서명 선택",
  No_Signatures:"서명 없음",
  No_Signatures_Word:"첫 번째 <a routerLink='/folder/Signature/New'>서명</a>을 추가합니다.",
  Repeat_Indefinitely:"무한 반복",
  Sign:"징후",
  Sign_Here:"여기에 서명",
  Date_Signed:"서명 날짜",
  Signature_Pad:"서명 패드",
  Account_Holder:"계정 소유자",
  Account_Properties:"계정 속성",
  Name_On_Cheque:"수표에 이름",
  Server_Hostname:"서버 호스트 이름/IP",
  Printers:"프린터",
  No_Printers:"프린터 없음",
  Printer_Exists:'이 이름의 프린터가 이미 있습니다.',
  No_Printers_Word:"첫 번째 <a routerLink='/folder/Printer/New'>프린터</a>를 추가하세요.",
  No_Printer_Alert:"정의된 프린터가 없습니다. 프린터를 설정하시겠습니까?",
  Add_Printer:"프린터 추가",
  New_Printer:"새 프린터",
  Printer_Hostname:"프린터 호스트 이름/IP",
  Printer_Label:"프린터 라벨",
  Printer_Protocol:"프린터 프로토콜",
  Protocol:"규약",
  Email_Print:"이메일",
  AirPrint:"에어프린트",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"소켓",
  Print_Job:"인쇄 작업",
  Printed:"인쇄",
  Not_Printed:"인쇄되지 않음",
  Print_Jobs:"인쇄 작업",
  Print_Queue:"인쇄 대기열",
  No_Print_Jobs:"인쇄 작업 없음",
  No_Prints:"인쇄할 새 <a routerLink='/folder/Cheque/New'>수표</a>를 만드십시오.",
  Prints:"인쇄물",
  Find_Printer:"프린터 찾기",
  Find_AirPrint_Devices:"AirPrint 장치 찾기",
  Select_Printer:"프린터 선택",
  System_UI:"시스템 UI",
  Printer:"인쇄기",
  Status:"상태",
  Preview:"시사",
  Enable_Print_Job:"인쇄 작업 활성화",
  Queue_Weight:"대기열 가중치",
  Unlimited:"제한 없는",
  Show_Advanced_Printer_Options:"고급 프린터 옵션 표시",
  Advanced:"고급의",
  Location:"위치",
  Note:"메모",
  Queue_Name:"대기열 이름",
  Pages_Printed_Limit:"인쇄된 페이지 수 제한",
  MultiPage_Idle_Time:"여러 페이지 대기 시간",
  Page_Count_Limit:"페이지 수 제한",
  Page_Orientation:"페이지 방향",
  Portrait:"초상화",
  Landscape:"풍경",
  Duplex:"듀플렉스",
  Double_Sided_Printing:"양면의",
  Duplex_Mode:"이중 모드",
  Duplex_Short:"짧은",
  Duplex_Long:"긴",
  Duplex_None:"없음",
  Color_And_Quality:"색상과 품질",
  Monochrome:"단색화",
  Photo_Quality_Prints:"사진 품질 인쇄",
  Printer_Email:"프린터 이메일",
  Automatically_Downsize:"자동으로 축소",
  Paper:"종이",
  Paper_Name:"종이 이름",
  Paper_Width:"용지 너비",
  Paper_Height:"종이 높이",
  Paper_Autocut_Length:"종이 자동 절단 길이",
  Margins:"여백",
  Page_Margins:"페이지 여백",
  Page_Margin_Top:"상단 페이지 여백",
  Page_Margin_Right:"오른쪽 페이지 여백",
  Page_Margin_Bottom:"하단 페이지 여백",
  Page_Margin_Left:"왼쪽 페이지 여백",
  Add_Employees:"직원 추가",
  Header:"헤더",
  Print_A_Page_Header:"페이지 머리글 인쇄",
  Header_Label:"헤더 레이블",
  Header_Page_Index:"헤더 페이지 인덱스",
  Header_Font_Name:"헤더 글꼴",
  Select_Font:"글꼴 선택",
  Font_Selector:"글꼴 선택기",
  Header_Font_Size:"헤더 글꼴",
  Header_Bold:"굵은 머리글",
  Header_Italic:"헤더 기울임꼴",
  Header_Alignment:"헤더 정렬",
  Left:"왼쪽",
  Center:"센터",
  Right:"오른쪽",
  Justified:"정당하다",
  Header_Font_Color:"헤더 색상",
  Select_Color:"색상 선택",
  Footer:"보행인",
  Print_A_Page_Footer:"페이지 바닥글 인쇄",
  Footer_Label:"바닥글 레이블",
  Footer_Page_Index:"바닥글 페이지 색인",
  Footer_Font_Name:"바닥글 글꼴",
  Fonts:"글꼴",
  Done:"완료",
  Select_Fonts:"글꼴 선택",
  Footer_Font_Size:"바닥글 크기",
  Footer_Bold:"굵은 바닥글",
  Footer_Italic:"바닥글 기울임꼴",
  Footer_Alignment:"바닥글 정렬",
  Footer_Font_Color:"바닥글 색상",
  Page_Copies:"페이지 사본",
  Enable_Printer:"프린터 활성화",
  Remote_Logging:"원격 로깅",
  Log_Server:"로그 서버",
  Encryption:"암호화",
  Random_Key:"랜덤 키",
  Encryption_Key:"암호화 키",
  Cheques_Webserver:"사용자 정의 데이터베이스",
  Learn_How:"어떻게하는지 배우다",
  Signature:"서명",
  Default_Printer_Unit:"인치/cm/mm/(pt)",
  View_Signature:"서명 보기",
  Printed_Signature:"인쇄된 서명",
  Digitally_Sign:"디지털 서명",
  Digital_Signature:"전자 서명",
  Add_Signature:"서명 추가",
  Add_Your_Signature:"서명 추가",
  Enable_Signature:"서명 활성화",
  Digitally_Signed:"디지털 서명",
  Insert_Error:"데이터베이스 문제로 인해 검사를 저장할 수 없습니다.",
  Delete_Confirmation:"이 정보를 삭제하시겠습니까?",
  Discard_Confirmation:"이 정보를 삭제하시겠습니까?",
  Discard_Bank_Confirmation:"이 계정을 삭제하시겠습니까?",
  Discard_Printer_Confirmation:"이 프린터를 폐기하시겠습니까?",
  Delete_Bonus_Confirmation:"이 보너스를 삭제하시겠습니까?",
  Delete_Invoice_Confirmation:"이 인보이스를 삭제하시겠습니까?",
  Generated_Cheque:"생성된 수표",
  Generated_Invoice:"생성된 송장",
  Schedule_Start_Time:"스케줄 시작",
  Schedule_End_Time:"스케줄 종료",
  New_Call:"뉴 콜",
  No_Contacts:"연락처 없음",
  No_Contacts_Word:"관리자, 중재자, 직원 및 수취인이 연락처로 표시됩니다.",
  PDF_Subject:"재정",
  PDF_Keywords:"급여 급여 수표 PDF 수표",
  Printer_Setup:"프린터 설정",
  Printer_Selection:"프린터 선택",
  New_Fax:"새 팩스",
  New_Fax_Message:"새 팩스 메시지",
  Fax_Machine:"팩스",
  Fax_Name:"팩스 이름",
  Fax_Email:"팩스 이메일",
  Fax_Number:"팩스 번호",
  Contents:"내용물",
  Fax_Body:"페이지 본문",
  Header_Word:"헤더:",
  Header_Text:"헤더 텍스트",
  Include_Header:"헤더 포함",
  Include_Footer:"바닥글 포함",
  Footer_Word:"보행인:",
  Footer_Text:"바닥글 텍스트",
  Attach_a_Cheque:"수표 첨부",
  Add_Deduction:"공제 추가",
  Enable_Fax:"팩스 보내기",
  Select_Fax:"팩스 선택",
  No_Faxes:"팩스 없음",
  Faxes:"팩스",
  Save_and_Send:"팩스 보내기",
  Save_and_Pay:"저장하고 지불",
  WARNING:"경고:",
  Remember:"기억하다",
  Printing:"인쇄",
  Printing_Complete:"인쇄 완료!\n\n",
  of:"의",
  There_Were:"있었다",
  Successful_Prints:" 성공적인 인쇄 및 ",
  Unsuccessful_Prints:"실패한 인쇄.",
  PrinterError:"죄송합니다! 오류가 발생했습니다.",
  Printing_:"인쇄...",
  PrinterSuccess:"문서가 성공적으로 인쇄되었습니다.",
  PrintersSuccess:"문서가 성공적으로 인쇄되었습니다.",
  Print_Cheques:"수표 인쇄",
  New_Message:"새로운 메시지",
  New_Messages:"새 메시지",
  Read_Message:"메시지 읽기",
  Write_Message:"메시지 쓰기",
  Send_Message:"문자 보내",
  Subject:"주제",
  Message:"메세지",
  Writing:"글쓰기...",
  Send:"보내다",
  Set_Date:"날짜 설정",
  Set_Time:"시간 설정",
  Recieve:"받다",
  Set_as_Default:"기본값으로 설정",
  Default_Account:"기본 계정",
  Default_Design:"기본 디자인",
  Multiple_Cheques:"체크(트리플)",
  Account_Mode:"계정 모드",
  Multiple_Cheques_Description:"페이지당 세 번 확인합니다.",
  Check_and_Table:"체크 & 테이블",
  Check_including_Table:"수표 및 회계 테이블.",
  Check_Mailer:"메일러 확인",
  Check_Mailer_Window:"주소창으로 확인하세요.",
  DocuGard_Table_Top:"DocuGard 체크 & 테이블(상단)",
  DocuGard_Table_Middle:"DocuGard 체크 & 테이블(가운데)",
  DocuGard_Table_Bottom:"DocuGard 체크 & 테이블(하단)",
  DocuGard_Mailer_Top:"DocuGard 체크 메일러(상단)",
  DocuGard_Mailer_Middle:"DocuGard 체크 메일러(가운데)",
  DocuGard_Mailer_Bottom:"DocuGard 체크 메일러(하단)",
  DocuGard_Three_Cheques:"DocuGard 체크(트리플)",
  DocuGard_Cheque_Top:"DocuGard 체크(상단)",
  DocuGard_Cheque_Middle:"다큐가드 체크(중)",
  DocuGard_Cheque_Bottom:"DocuGard 체크(하단)",
  DocuGard_Three_Cheques_Window:"한 페이지에 세 가지 검사가 있습니다.",
  DocuGard_Table_Top_Window:"수표 및 수입 테이블.",
  DocuGard_Table_Middle_Window:"수표 및 수입 테이블.",
  DocuGard_Table_Bottom_Window:"수표 및 수입 테이블.",
  DocuGard_Mailer_Top_Window:"확인, 테이블 및 주소.",
  DocuGard_Mailer_Middle_Window:"확인, 테이블 및 주소.",
  DocuGard_Mailer_Bottom_Window:"확인, 테이블 및 주소.",
  DocuGard_Cheque_Top_Window:"안전한 용지를 확인하십시오.",
  DocuGard_Cheque_Middle_Window:"안전한 용지를 확인하십시오.",
  DocuGard_Cheque_Bottom_Window:"안전한 용지를 확인하십시오.",
  Basic_Cheque:"확인(상단)",
  Basic_Cheque_Print:"수표 한 장을 인쇄하십시오.",
  Error_Setting_As_Paid:"유료로 설정 오류",
  Add_Attachment:"첨부 파일 추가",
  PrinterUnavailable:"프린터를 사용할 수 없음",
  CreditCardComponent:"카드",
  PaypalComponent:"페이팔",
  InteracComponent:"인터랙",
  BitcoinComponent:"비트코인",
  New_Deposit:"신입금",
  Deposits:"매장",
  No_Deposits:"예금 없음",
  Credit_Card_Deposit:"신용 카드",
  New_Credit_Card_Deposit_Message:"신용카드 예치금",
  New_BitCoin_Deposit:"비트코인",
  New_BitCoin_Deposit_Message:"비트코인 입금",
  New_Interac_Deposit:"인터랙",
  New_Interac_Deposit_Message:"인터랙 전송",
  Payments_Limit:"지불 한도",
  No_Payment_Limit:"결제 한도 없음",
  PayPal_Deposit:"페이팔",
  PayPal_Deposit_Message:"페이팔 입금",
  No_Deposits_Word:"첫 번째 회사 <a routerLink='/folder/Deposit/New'>입금</a>을 추가하세요.",
  No_Documents_Specified:"인쇄할 문서가 지정되지 않았습니다",
  No_Printers_Added:"프린터를 찾을 수 없습니다. 추가하려면 설정 > 프린터로 이동하십시오.",
  DB_Erase_Prompt:"전체 데이터베이스를 완전히 지우시겠습니까? 경고: 저장된 모든 정보를 잃게 됩니다!",
  Console_Warning:"이 콘솔에 텍스트를 붙여넣지 마십시오. 자신 및/또는 회사를 심각한 위험에 빠뜨릴 수 있습니다.",
  No_Faxes_Word:"첫 번째 <a routerLink='/folder/Fax/New'>Fax</a>를 만듭니다.",
  Cheque_Delete_Confirmation:"이 수표를 삭제하시겠습니까?",
  Design_Delete_Confirmation:"정말 이 디자인을 삭제하시겠습니까?",
  Cheque_Pay_Confirmation:"이 수표를 지불된 것으로 표시하시겠습니까? 인쇄 대기열에 나타나지 않습니다.",
  Payment_Pay_Confirmation:"이 지불을 지불된 것으로 표시하시겠습니까? 수표 대기열에 나타나지 않습니다.",
  Delete_Deduction_Confirmation:"이 공제를 삭제하시겠습니까?",
  Delete_Job_Confirmation:"이 작업을 삭제하시겠습니까?",
  Delete_Timesheet_Confirmation:"이 작업표를 삭제하시겠습니까?",
  Delete_Schedule_Confirmation:"이 일정을 삭제하시겠습니까?",
  Delete_Setting_Confirmation:"이 설정을 재설정하시겠습니까?",
  Delete_Fax_Confirmation:"이 팩스를 삭제하시겠습니까?",
  Delete_File_Confirmation:"이 파일을 삭제하시겠습니까?",
  Delete_Vacation_Confirmation:"이 휴가를 삭제하시겠습니까?",
  Delete_Printer_Confirmation:"이 프린터를 삭제하시겠습니까?",
  Remove_Design_Confirmation:"정말 이 디자인을 삭제하시겠습니까?",
  Delete_Payroll_Confirmation:"이 급여를 삭제하시겠습니까?",
  Send_Fax_Email_Confirmation:"이 문서를 팩스 및 이메일로 보내시겠습니까?",
  Send_Email_Confirmation:"이 문서를 이메일로 보내시겠습니까?",
  Send_Fax_Confirmation:"이 문서를 팩스로 보내시겠습니까?",
  Error_Generating_PDF:"죄송합니다. 이 문서를 생성하는 동안 오류가 발생했습니다.",
  PDF_Error_Saving_Image:"죄송합니다. 이 문서의 PDF 이미지를 저장하는 동안 오류가 발생했습니다.",
  Test_Printer_Confirmation:"이 프린터에서 테스트 페이지를 인쇄하시겠습니까?",
  Save_Timesheet_Prompt:"'제목'을 추가하거나 '타이머 시작'을 눌러 저장하십시오.",
  Remove_Geofence_Prompt:"이 지오펜스의 위치를 ​​제거하시겠습니까?",
  Delete_Employee_Confirmation:"삭제 하시겠습니까 ",
  Fire_Employee_Confirmation:"정말 발사하시겠습니까?"
}