export const Km = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"រក្សាសិទ្ធិ និងចម្លង; ២០២៣",
  black:"ខ្មៅ",
  green:"បៃតង",
  gold:"មាស",
  blue:"ខៀវ",
  brown:"ត្នោត",
  purple:"ពណ៌ស្វាយ",
  pink:"ផ្កាឈូក",
  red:"ក្រហម",
  Swatches:"Swatches",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"ពណ៌លាំៗ",
  Saturation:"តិត្ថិភាព",
  Lightness:"ពន្លឺ",
  Upgrade_To_Pro:"ដំឡើងកំណែទៅ ប្រូ",
  AllFeaturesInclude:"លក្ខណៈពិសេសទាំងអស់រួមមាន:",
  PrintUnlimitedCheques:"បោះពុម្ពការត្រួតពិនិត្យគ្មានដែនកំណត់",
  PremiumChequeDesigns:"ការរចនាពិនិត្យពិសេស",
  ManageUnlimitedEmployees:"គ្រប់គ្រងបុគ្គលិកគ្មានដែនកំណត់",
  AddUnlimitedPayees:"បន្ថែមអ្នកទទួលប្រាក់គ្មានដែនកំណត់",
  CreateUnlimitedPayrolls:"បង្កើតប្រាក់បៀវត្សរ៍គ្មានដែនកំណត់",
  UnlimitedSchedulesandTimeSheets:"តារាងពេលវេលា និងតារាងពេលវេលាគ្មានដែនកំណត់",
  BulkPayPalPayouts:"ការទូទាត់ PayPal ភាគច្រើន",
  UnlimitedBankAccounts:"គណនីធនាគារគ្មានដែនកំណត់",
  ManageMultipleCompanies:"គ្រប់គ្រងក្រុមហ៊ុនជាច្រើន។",
  TrackInsurancePolicies:"តាមដានគោលនយោបាយធានារ៉ាប់រង",
  Bio_MetricProtection:"ការការពារជីវមាត្រ",
  Geo_FenceLock_OutProtection:"របងភូមិសាស្ត្រ ចាក់សោរចេញ ការការពារ",
  Multiple_Companies_Word:"ការគ្រប់គ្រងក្រុមហ៊ុនជាច្រើនមិនអាចប្រើបានដោយគ្មាន Checks premium ទេ។",
  PayPal_Payouts_Word:"ការទូទាត់តាម PayPal ត្រូវបានបិទដោយគ្មាន Checks premium។",
  PINProtection:"ការការពារលេខសម្ងាត់",
  PasswordProtection:"ការការពារពាក្យសម្ងាត់",
  May_Require_Approval:"អាចទាមទារការយល់ព្រម។",
  Subscribe:"ជាវ",
  Billed:"ចេញវិក្កយបត្រ",
  Up:"ឡើង",
  Down:"ចុះ",
  Positioning:"ការកំណត់ទីតាំង",
  Marker:"សញ្ញាសម្គាល់",
  Drag_Marker:"អូសសញ្ញាសម្គាល់",
  Tagline:"បោះពុម្ពមូលប្បទានប័ត្រ និងកំណត់បញ្ជីប្រាក់បៀវត្សរ៍",
  Marker_Word:"ប្រើសញ្ញាសម្គាល់ដើម្បីទំហំធាតុ។",
  Pinch_Zoom:"Pinch Zoom",
  Pinch_Word:"ខ្ទាស់ដើម្បីពង្រីកធាតុ។",
  Drag:"អូស",
  Drag_Word:"ប្រើម្រាមដៃរបស់អ្នកដើម្បីអូសធាតុ។",
  subscription_alias_word:"ការបន្តការជាវដោយស្វ័យប្រវត្តិ",
  premium_alias_word:"កញ្ចប់ដំឡើងកំណែតែម្តង",
  print_alias_word:"បោះពុម្ព-ចេញការត្រួតពិនិត្យបុគ្គល",
  mode_alias_word:"របៀប",
  Pro:"គាំទ្រ",
  Pro_word:"កំណែ Pro ទាមទារ។",
  Cant_Delete_Default_Company_Word:"សូមអភ័យទោស អ្នក មិនអាច លុបក្រុមហ៊ុនលំនាំដើមរបស់អ្នកបានទេ។",
  Reinsert_Default_Designs:"បញ្ចូលការរចនាលំនាំដើមឡើងវិញ",
  Reinsert_Default_Designs_word:"តើអ្នកចង់បញ្ចូលការរចនាលំនាំដើមឡើងវិញទេ?",
  Subscription_Active_Disable_Word:"ការជាវនេះសកម្ម។ តើអ្នកចង់បោះបង់ការជាវ Cheques នេះទេ?",
  Company_Logo:"និមិត្តសញ្ញាក្រុមហ៊ុន",
  ZERO_:"សូន្យ",
  Disclaimer:"ការបដិសេធ",
  Privacy_Policy:"គោលការណ៍ភាពឯកជន",
  EULA:"ពិនិត្យ EULA",
  Terms_Of_Service:"លក្ខខណ្ឌនៃសេវាកម្ម",
  Terms_Of_Use:"ល័ក្ខខ័ណ្ឌនៃការប្រើប្រាស់",
  Refunds:"គោលនយោបាយសងប្រាក់វិញ។",
  Single_Print:"1 ពិនិត្យ",
  Two_Prints:"2 ពិនិត្យ",
  Five_Prints:"5 ពិនិត្យ",
  Ten_Prints:"10 ការត្រួតពិនិត្យ",
  Fifteen_Prints:"15 ការត្រួតពិនិត្យ",
  Twenty_Prints:"20 ការត្រួតពិនិត្យ",
  Thirty_Prints:"30 ពិនិត្យ",
  Image_Added:"បានបន្ថែមរូបភាព",
  Image_Preview:"ការមើលរូបភាពជាមុន",
  No_Image_Was_Selected:"គ្មានរូបភាពត្រូវបានជ្រើសរើសទេ។",
  Cheques_Unlimited:"ពិនិត្យគ្មានដែនកំណត់",
  _Subscription:"ការជាវ",
  Subscription:"ពិនិត្យ - 1 ខែ",
  Two_Month_Subscription:"ពិនិត្យ - 2 ខែ",
  Three_Month_Subscription:"ពិនិត្យ - 3 ខែ",
  Six_Month_Subscription:"ការត្រួតពិនិត្យ - 6 ខែ",
  Twelve_Month_Subscription:"ការត្រួតពិនិត្យ - 12 ខែ",
  Cheques_Are_Available:"មូលប្បទានប័ត្រមានសម្រាប់បោះពុម្ព។",
  Upgrade_Required_Two:"ជ្រើសរើសកញ្ចប់មួយ ហើយចុចពីរដងលើប៊ូតុងតម្លៃ ដើម្បីចាប់ផ្តើមការទិញរបស់អ្នក។ បោះពុម្ពប្រកបដោយវិជ្ជាជីវៈ រកមើលការត្រួតពិនិត្យពណ៌ពេញក្នុងរយៈពេលប៉ុន្មានវិនាទី។",
  Month:"ខែ",
  Due:"ដល់កំណត់៖",
  Expires:"ផុតកំណត់៖",
  Subscription_Active:"ការជាវសកម្ម",
  Subscription_Inactive:"ការជាវអសកម្ម",
  Purchase_Additional_Cheques:"ទិញការត្រួតពិនិត្យបន្ថែម?",
  Printable_Cheque:"ការត្រួតពិនិត្យដែលអាចបោះពុម្ពបាន។",
  Printable_Cheques:"ការត្រួតពិនិត្យដែលអាចបោះពុម្ពបាន។",
  Printable_Cheque_Word:"មូលប្បទានប័ត្រមាននៅលើគណនីរបស់អ្នក។",
  Printable_Cheques_Word:"មូលប្បទានប័ត្រមាននៅលើគណនីរបស់អ្នក។",
  Max_Amount_Message:"ចំនួនទឹកប្រាក់ដែលអ្នកបានបញ្ជាក់បានឈានដល់លើសពីចំនួនអតិបរមាដែលបានកំណត់សម្រាប់ប្រព័ន្ធនេះ៖",
  Terms_Required_Word:"អ្នកត្រូវតែយល់ព្រមនឹងកិច្ចព្រមព្រៀងនេះ មុនពេលបន្តប្រើប្រាស់ Cheques ។",
  Subscriptions:"ការជាវ",
  Product_Upgrades:"ធ្វើឱ្យប្រសើរឡើង",
  Mailed_Out_Cheques:"ការត្រួតពិនិត្យតាមសំបុត្រ",
  Enter_A_Company_Name:"សូមបញ្ចូលឈ្មោះក្រុមហ៊ុន។",
  Single_Cheques:"ការត្រួតពិនិត្យតែមួយ",
  Cheque_Golden:"ការពិនិត្យមាស",
  Cheque_Golden_Window:"ការរចនាពិនិត្យមាស។",
  Cheque_Green:"ពិនិត្យពណ៌បៃតង",
  Cheque_Green_Window:"ការរចនាពិនិត្យពណ៌បៃតង។",
  Cheque_Red:"ពិនិត្យក្រហម",
  Cheque_Red_Window:"ការរចនាពិនិត្យក្រហម។",
  Cheque_Yellow:"ពិនិត្យពណ៌លឿង",
  Cheque_Yellow_Window:"ការរចនាពណ៌លឿង។",
  Cheque_Statue_of_Liberty:"រូបសំណាកសេរីភាព",
  Cheque_Statue_of_Liberty_Window:"ការរចនារូបសំណាកសេរីភាព។",
  Cheque_Green_Wave:"រលកបៃតង",
  Cheque_Green_Wave_Window:"ការរចនាពិនិត្យពណ៌បៃតង។",
  Cheque_Golden_Weave:"ត្បាញមាស",
  Cheque_Golden_Weave_Window:"ការរចនាពណ៌មាសឆើតឆាយ។",
  Cheque_Green_Sun:"ព្រះអាទិត្យបៃតង",
  Cheque_Green_Sun_Window:"ការរចនាពិនិត្យយ៉ាងស៊ីជម្រៅ និងស្ងប់ស្ងាត់។",
  Cheque_Blue_Checkers:"អ្នកត្រួតពិនិត្យពណ៌ខៀវ",
  Cheque_Blue_Checkers_Window:"ការរចនាពណ៌ខៀវ។",
  Cashiers_Check:"មូលប្បទានប័ត្ររបស់អ្នកគិតលុយ",
  Cashiers_Check_Window:"គំរូរចនាប័ទ្ម Cashier's Check ។",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"ការរចនា Aqua ពិនិត្យ។",
  Cheque_Golden_Checkers:"អ្នកត្រួតពិនិត្យមាស",
  Cheque_Golden_Checkers_Window:"ការរចនាពិនិត្យមាស។",
  Upgrade_Unavailable:"ការអាប់ដេតមិនអាចប្រើបានទេ។",
  Bank_Code_Protection:"ការការពារលេខធនាគារ",
  Bank_Code_Protection_Word:"ការពារលេខធនាគាររបស់អ្នកពីការប្រើប្រាស់នៅលើកម្មវិធីនេះដែលកំពុងដំណើរការនៅលើឧបករណ៍ផ្សេងទៀត ឬសម្រាប់អ្នកប្រើប្រាស់ផ្សេងទៀត។ សកម្មភាពនេះគឺមិនអាចត្រឡប់វិញបានទេ។ បន្ត?",
  Bank_Code_Protection_Blocked_Word:"លេខធនាគារដែលអ្នកកំពុងព្យាយាមប្រើត្រូវបានបម្រុងទុកសម្រាប់អ្នកប្រើប្រាស់ ឬឧបករណ៍ផ្សេងទៀត។",
  Bank_Code_Protection_Error_Word:"ការផ្ទៀងផ្ទាត់លេខបានបរាជ័យ។ សូមភ្ជាប់អ៊ីនធឺណិត ហើយព្យាយាមបញ្ចូលគណនីធនាគារនេះម្តងទៀត។",
  Bank_Code_Protection_Set_Error_Word:"ការការពារលេខធនាគារតម្រូវឱ្យអ្នកភ្ជាប់អ៊ីនធឺណិត។ សូមភ្ជាប់ ហើយព្យាយាមម្តងទៀត។",
  Upgrade_Unavailable_Word:"សូមអភ័យទោស យើងកំពុងមានបញ្ហាក្នុងការផ្ទៀងផ្ទាត់អ្នក។ ការជាវ និងការអាប់ដេតទៅ Checks បច្ចុប្បន្នមិនមានសម្រាប់ការទិញនៅក្នុងតំបន់របស់អ្នក។",
  PayPal_Payment_Preview:"ការមើលជាមុននៃការទូទាត់ PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"ជ្រើសរើស PayPal",
  PayPal_Applications:"កម្មវិធី PayPal",
  Purchase_With_Apple_Pay:"ទិញជាមួយ Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"ក្រុមហ៊ុន",
  Insurance:"ធានារ៉ាប់រង",
  New_PayPal:"PayPal ថ្មី។",
  Pay_By:"បង់ដោយ",
  Insure:"ធានា",
  Miles:"ម៉ាយល៍",
  Payment_Method:"វិធីសាស្រ្តទូទាត់",
  Select_Policies:"ជ្រើសរើសគោលការណ៍",
  Policies:"គោលនយោបាយ",
  Policy:"គោលនយោបាយ",
  No_PayPal_Account:"គ្មានគណនី PayPal",
  No_Policies:"គ្មានគោលការណ៍ធានារ៉ាប់រង",
  New_Policy:"គោលនយោបាយថ្មី។",
  PayPal_Payment:"ការទូទាត់ PayPal",
  PayPal_Payments:"ការទូទាត់ PayPal",
  No_Payment_Selected:"មិនបានជ្រើសរើសការទូទាត់ទេ។",
  Sending_Payment_Word:"សូមរង់ចាំ... ការបង់ប្រាក់នេះកំពុងត្រូវបានផ្ញើ។",
  Sending_Payments_Word:"សូមរង់ចាំ... ការបង់ប្រាក់កំពុងត្រូវបានផ្ញើ។",
  No_Payment_Selected_PayPal:"មិនមាន <a routerLink='/folder/Payments'>ការទូទាត់តាម PayPal</a> ដែលបានជ្រើសរើសដើម្បីផ្ញើ។",
  Payment_Sent:"ការទូទាត់ត្រូវបានផ្ញើ",
  PayPal_Payment_Sent:"ការទូទាត់នេះត្រូវបានផ្ញើជាមួយ PayPal ។",
  Copay:"បង់ថ្លៃ",
  Dead:"ស្លាប់",
  Alive:"នៅរស់",
  Not_Dead:"មិនស្លាប់",
  Unclaimed:"មិនបានទាមទារ",
  Attempted:"បានព្យាយាម",
  Deceased:"ស្លាប់",
  Claimed:"ទាមទារ",
  Unpaid:"មិនទាន់បង់ប្រាក់",
  Sending_Payment:"ការផ្ញើការទូទាត់",
  Sending_Payments:"ការផ្ញើការទូទាត់",
  Send_PayPal_Confirmation:"តើអ្នកចង់ផ្ញើប្រតិបត្តិការនេះជាមួយ PayPal ទេ?",
  Send_PayPal_Confirmation_Word:"ចុចប៊ូតុងពណ៌បៃតងដើម្បីផ្ញើប្រតិបត្តិការនេះ។",
  Save_Payment_As_Unpaid:"រក្សាទុកការទូទាត់នេះថាមិនទាន់បានបង់ប្រាក់ទេ?",
  Payment_Pay_Confirmation_PayPal:"រក្សាទុកការទូទាត់នេះដូចដែលបានបង់?",
  No_Policies_Word:"បន្ថែម <a routerLink='/folder/Postage/New'>គោលការណ៍ធានារ៉ាប់រង</a> ដំបូង ឥឡូវនេះ។",
  Timesheet_Multiple_Delete_Confirmation:"តើអ្នកប្រាកដថាចង់លុបតារាងពេលវេលាច្រើនទេ?",
  Select_Multiple_Timesheets_Prompt:"មិនបានជ្រើសរើសតារាងពេលវេលាទេ។ ជ្រើសរើសតារាងពេលវេលាយ៉ាងហោចណាស់មួយ។",
  Select_Multiple_Policies_Prompt:"មិនបានជ្រើសរើសគោលការណ៍ទេ។ ជ្រើសរើសយ៉ាងហោចណាស់គោលនយោបាយធានារ៉ាប់រងមួយ។",
  Policies_Multiple_Delete_Confirmation:"តើអ្នកប្រាកដថាចង់លុបគោលការណ៍ច្រើនទេ?",
  Company:"ក្រុមហ៊ុន",
  Add_Company:"បន្ថែមក្រុមហ៊ុន",
  New_Company:"បន្ថែមក្រុមហ៊ុន",
  No_Companies:"គ្មានក្រុមហ៊ុនទេ។",
  Enable_Company:"បើកក្រុមហ៊ុន",
  Select_Company:"ជ្រើសរើសក្រុមហ៊ុន",
  The_Default_Company:"ស្លាកក្រុមហ៊ុនលំនាំដើម។",
  Manage_Companies:"គ្រប់គ្រងក្រុមហ៊ុន",
  No_Companies_Word:"ការត្រួតពិនិត្យនឹងប្រើ ក្រុមហ៊ុនលំនាំដើម។<br />បន្ថែម <a routerLink='/folder/Company/New'>ក្រុមហ៊ុនដំបូង</a>។",
  Default_Company:"ក្រុមហ៊ុនលំនាំដើម",
  Cheques_Unlimited_Word:"Checks Unlimited អនុញ្ញាតឱ្យអ្នកបោះពុម្ពមូលប្បទានប័ត្រជាច្រើនតាមដែលអ្នកចូលចិត្ត។",
  Cheques_Subscription_Word:"ការជាវ Checks អនុញ្ញាតឱ្យអ្នកបោះពុម្ពមូលប្បទានប័ត្រជាច្រើនតាមដែលអ្នកចូលចិត្ត។",
  You_Own_This_Product:"អ្នកជាម្ចាស់ផលិតផលនេះ។",
  Your_Subscription_is_Active:"ការជាវរបស់អ្នកគឺសកម្ម។",
  Active_Products:"ផលិតផលដែលបានធ្វើឱ្យសកម្ម",
  Purchase_Confirmation:"ទិញ",
  Purchase_Cheques:"ការត្រួតពិនិត្យការទិញ",
  Restore_Purchase:"ទិញឡើងវិញ",
  Erase_Purchase:"លុបការទិញ",
  Successfully_Purchased:"ទិញដោយជោគជ័យ",
  Enter_Your_Licence_Key:"សូមបញ្ចូលលេខកូដអាជ្ញាប័ណ្ណរបស់អ្នកនៅលើទំព័រនេះ ដើម្បីដំណើរការផលិតផលនេះ។",
  Licence_Key:"សោអាជ្ញាប័ណ្ណ",
  Enter_Licence_Key:"បញ្ចូលលេខកូដអាជ្ញាប័ណ្ណ",
  Purchased:"បានទិញ",
  Enable_Feature:"បើកមុខងារ",
  Cancel_Subscription:"បោះបង់ការជាវ",
  Subscription_Removed:"ការជាវត្រូវបានដកចេញ",
  Select_Active_Subscription:"ជ្រើសរើសការជាវសកម្មដើម្បីកែប្រែវា។",
  Remove_Subscription_Word:"តើអ្នកប្រាកដថាចង់បោះបង់ការជាវនេះទេ?",
  Delete_Company_Confirmation:"តើអ្នកប្រាកដថាចង់លុបក្រុមហ៊ុននេះទាំងស្រុងទេ? ការព្រមាន៖ អ្នកនឹងបាត់បង់ព័ត៌មានដែលបានរក្សាទុកទាំងអស់!",
  Delete_Default_Company_Word:"អ្នក មិនអាច លុបក្រុមហ៊ុនលំនាំដើមបានទេ។ តើអ្នកចង់កំណត់កម្មវិធីឡើងវិញ ហើយស្ដារវាទៅស្ថានភាពលំនាំដើមឬ? ការព្រមាន៖ អ្នកនឹងបាត់បង់ព័ត៌មានដែលបានរក្សាទុកទាំងអស់!",
  Console_Warning_2:"កុំគ្រប់គ្រងរូបិយប័ណ្ណណាមួយដោយប្រើកម្មវិធីនេះដែលបច្ចុប្បន្នមិនមែនជារបស់អ្នកទេ។",
  Terms_and_Conditions:"លក្ខខណ្ឌ",
  and_the:"និង",
  for:"សម្រាប់",
  Please_Read_Word:"សូមអាន និងយល់ព្រមតាម",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"រកមិនឃើញអត្រាប្តូររូបិយប័ណ្ណមួយចំនួនទេ។ សូមភ្ជាប់ទៅអ៊ីនធឺណិត។",
  Free:"ឥតគិតថ្លៃ",
  DB_Erase_Prompt_2:"លុបមូលដ្ឋានទិន្នន័យរបស់អ្នកអភិវឌ្ឍន៍ទាំងស្រុង? ការព្រមាន៖ អ្នកនឹងបាត់បង់ព័ត៌មានការទិញ និងការជាវទាំងអស់!",
  Successfully_Imported:"បាននាំចូលដោយជោគជ័យ",
  Select_Postage:"ជ្រើសរើសប្រៃសណីយ៍",
  No_Postage:"គ្មានត្រាប្រៃសណីយ៍",
  No_Paid_Postage_Word:"បន្ថែមត្រា <a routerLink='/folder/Postage/New'>ថ្លៃប្រៃសណីយ៍</a> ដំបូង ។",
  Trial_Complete:'ការសាកល្បងបានបញ្ចប់',
  Please_Upgrade:'សូមដំឡើងកំណែ Checks ដើម្បីបន្តការបោះពុម្ព។',
  Aa:"អេ",
  Color:"ពណ៌",
  Font:"ពុម្ពអក្សរ",
  Guide:"មគ្គុទ្ទេសក៍",
  Guides:"មគ្គុទ្ទេសក៍",
  Image:"រូបភាព",
  Zoom:"ពង្រីក",
  Logo:"និមិត្តសញ្ញា",
  Bank:"ធនាគារ",
  MICR:"MICR",
  Total:"សរុប",
  Cancel:"បោះបង់",
  Confirm:"បញ្ជាក់",
  Method:"វិធីសាស្រ្ត",
  Biometric_Security:"សុវត្ថិភាពជីវមាត្រ",
  Please_Login_To_Continue:"សូមចូលដើម្បីបន្ត។",
  Complete:"បញ្ចប់",
  Sign_Up:"ចុះឈ្មោះ",
  Error:"កំហុស",
  Biometrics:"ជីវមាត្រ",
  Percent:"ភាគរយ",
  Zero_Percent:"0%",
  Top_Margin:"រឹមកំពូល",
  Bottom_Margin:"រឹមខាងក្រោម",
  Left_Margin:"រឹមខាងឆ្វេង",
  Right_Margin:"រឹមស្តាំ",
  MICR_Margin:"រឹម MICR",
  Table_Margin:"រឹមតារាង",
  Address_Margin:"រឹមអាសយដ្ឋាន",
  Percentage_:"ភាគរយ",
  Vacation_Title:"ចំណងជើងវិស្សមកាល",
  Use_PIN:"ប្រើលេខសម្ងាត់",
  Loading__:"កំពុងផ្ទុក...",
  Design_Title:"ចំណងជើងរចនា",
  Authorize:"អនុញ្ញាត",
  Key:"សោ",
  Public_Key:"សោសាធារណៈ",
  Private_Key:"សោឯកជន",
  Authenticate:"ផ្ទៀងផ្ទាត់ភាពត្រឹមត្រូវ",
  Last_Payroll:"ប្រាក់ខែចុងក្រោយ",
  Last_Calculation:"ការគណនាចុងក្រោយ",
  Authorized:"អនុញ្ញាត",
  Geo_Authorized:"ទីតាំងភូមិសាស្ត្រ៖ ត្រូវបានអនុញ្ញាត",
  Not_Authorized:"មិនត្រូវបានអនុញ្ញាត",
  Authorization_Complete:"ការអនុញ្ញាតបានបញ្ចប់",
  Geolocation_Authorization:"ការអនុញ្ញាតទីតាំងភូមិសាស្ត្រ",
  Out_of_Bounds:"ចេញពីព្រំដែន",
  Cant_Delete_Default_Design:"មិនអាចលុបការរចនាលំនាំដើមបានទេ។",
  Unauthorized_Out_of_Bounds:"គ្មានការអនុញ្ញាត៖ ហួសព្រំដែន",
  Biometric_Authorization:"ការអនុញ្ញាតជីវមាត្រ",
  Locating_Please_Wait:"ស្វែងរកទីតាំង សូមរង់ចាំ...",
  Test_Biometrics:"តេស្តជីវមាត្រ",
  Cheque_Margins:"ពិនិត្យរឹម",
  Percentage_Full_Error:"វាលភាគរយមិនអាចកំណត់លើសពី 100% ភាគរយបានទេ។",
  No_Payroll_Text:"បន្ថែម <a routerLink='/folder/Employee/New'>Employee</a> ឬ <a routerLink='/folder/Payee/New'>Payee</a> និង <a routerLink='/folder/Schedule/New'>កាលវិភាគ</a>។",
  Design_Saved:"បានរក្សាទុកការរចនា",
  Default_Design_Selected:"បានជ្រើសរើសការរចនាលំនាំដើម",
  Partial_Import:"ការនាំចូលដោយផ្នែក",
  Partial_Export:"ការនាំចេញដោយផ្នែក",
  Entire_Import:"នាំចូលទាំងស្រុង",
  Entire_Export:"ការនាំចេញទាំងមូល",
  Existing_Password:"សូមបញ្ចូលពាក្យសម្ងាត់ដែលមានស្រាប់របស់អ្នក៖",
  Existing_PIN:"សូមបញ្ចូលលេខកូដ PIN ដែលមានស្រាប់របស់អ្នក៖",
  Pin_Change_Header:"ការបញ្ជាក់លេខសម្ងាត់",
  Pin_Change_SubHeader:"បញ្ចូលលេខ PIN ចាស់របស់អ្នក ដើម្បីបញ្ជាក់ការផ្លាស់ប្តូរ។",
  Pass_Change_Header:"ការបញ្ជាក់ពាក្យសម្ងាត់",
  Pass_Change_SubHeader:"បញ្ចូលពាក្យសម្ងាត់ចាស់របស់អ្នក ដើម្បីបញ្ជាក់ការផ្លាស់ប្តូរ។",
  PIN_Enter_Message:"បញ្ចូលកូដ PIN របស់អ្នកដើម្បីបញ្ជាក់។",
  Password_Enter_Message:"បញ្ចូលពាក្យសម្ងាត់របស់អ្នកដើម្បីបញ្ជាក់។",
  Pin_Updated_Success:"ធ្វើបច្ចុប្បន្នភាពកូដ PIN ដោយជោគជ័យ!",
  Pin_Updated_Fail:"កូដ PIN មិនអាចធ្វើបច្ចុប្បន្នភាពបានទេ។",
  Pass_Updated_Success:"បានធ្វើបច្ចុប្បន្នភាពពាក្យសម្ងាត់ដោយជោគជ័យ។",
  Pass_Updated_Fail:"ពាក្យសម្ងាត់មិនអាចត្រូវបានធ្វើបច្ចុប្បន្នភាពបានទេ។",
  Preview_Payment:"មើលការទូទាត់ជាមុន",
  Preview_Payroll:"មើលបញ្ជីប្រាក់ខែជាមុន",
  No_Payments_Created:"រកមិនឃើញការទូទាត់ត្រូវបានបង្កើតឡើងទេ។",
  Payment_Preview:"ការមើលការបង់ប្រាក់ជាមុន",
  Enable_Payee:"បើកអ្នកទទួលប្រាក់",
  Rendered:"បង្ហាញ",
  No_Email:"គ្មានអ៊ីមែល",
  Setup_Cheques:"កំណត់ការត្រួតពិនិត្យ",
  name:"ឈ្មោះ",
  address:"អាស័យដ្ឋាន",
  address_2:"អសយដ្ឋានខ្សែរទី 2",
  city:"ទីក្រុង",
  province:"ខេត្ត",
  postal_code:"ប្រៃសណីយ៍/លេខកូដប្រៃសណីយ៍",
  country:"ប្រទេស",
  username:"ឈ្មោះអ្នកប្រើប្រាស់",
  full_name:"ឈ្មោះពេញ",
  birthday:"ថ្ងៃកំណើត",
  email:"អ៊ីមែល",
  phone:"ទូរស័ព្ទ",
  employees:"និយោជិត",
  addresses:"អាស័យដ្ឋាន",
  payment_amount_limit:"ដែនកំណត់នៃចំនួនទឹកប្រាក់ទូទាត់",
  total_limit:"ដែនកំណត់សរុប",
  payees:"អ្នកទទួលប្រាក់",
  description:"ការពិពណ៌នា",
  address_line_one:"អាស័យដ្ឋាន ខ្សែទីមួយ",
  address_line_two:"អាស័យដ្ឋាន ជួរទីពីរ",
  image:"រូបភាព",
  account_holder:"ម្ចាស់គណនី",
  cheque_starting_id:"ពិនិត្យលេខសម្គាល់ការចាប់ផ្តើម",
  transit_number:"លេខឆ្លងកាត់",
  institution_number:"លេខស្ថាប័ន",
  designation_number:"លេខសម្គាល់",
  account_number:"លេខគណនី",
  currency:"រូបិយប័ណ្ណ",
  signature:"ហត្ថលេខា",
  payment_payroll_limit:"ដែនកំណត់ការទូទាត់",
  total_limi:"ដែនកំណត់សរុប",
  date:"កាលបរិច្ឆេទ",
  printed_memo:"អនុស្សរណៈដែលបានបោះពុម្ព",
  banks:"ធនាគារ",
  signature_image:"រូបភាពហត្ថលេខា",
  address_name:"ឈ្មោះអាសយដ្ឋាន",
  notes:"កំណត់ចំណាំ",
  design:"រចនា",
  title:"ចំណងជើង",
  frequency:"ប្រេកង់",
  fax:"ទូរសារ",
  salaries:"ប្រាក់ខែ",
  salary_ids:"លេខសម្គាល់ប្រាក់ខែ",
  start_time:"ពេលវេលាចាប់ផ្តើម",
  end_time:"ពេលវេលាបញ្ចប់",
  paid:"បង់",
  overtime_percentage:"ភាគរយដែលបានបង់",
  overtime_amount:"ចំនួនទឹកប្រាក់ថេរដែលបានបង់",
  first_name:"ឈ្មោះដំបូង",
  last_name:"នាមត្រកូល",
  moderation:"ការសម្របសម្រួល",
  create:"បង្កើត",
  edit:"កែសម្រួល",
  destroy:"បំផ្លាញ",
  day_start_time:"ថ្ងៃ_ចាប់ផ្តើម_ម៉ោង",
  day_end_time:"ថ្ងៃ_ចុង_ម៉ោង",
  fullname:"ឈ្មោះ",
  time:"ពេលវេលា",
  auto_send:"ផ្ញើដោយស្វ័យប្រវត្តិ",
  time_method:"វិធីសាស្រ្តពេលវេលា",
  schedules:"កាលវិភាគ",
  indefinite_payroll_enabled:"បើកដំណើរការដោយគ្មានកំណត់",
  amount:"ចំនួនទឹកប្រាក់",
  repeat:"ធ្វើម្តងទៀត",
  always_enabled:"បើកដំណើរការជានិច្ច",
  start_date:"ថ្ងៃចាប់ផ្តើម",
  end_date:"កាលបរិច្ឆេទបញ្ចប់",
  Cheque_Total:"ពិនិត្យសរុប",
  Total_Amount:"ចំនួនសរុប:",
  Amounts:"បរិមាណ:",
  Images:"រូបភាព",
  Files:"ឯកសារ",
  Previewing:"ការមើលជាមុន៖",
  Insert:"បញ្ចូល",
  Preview_Import:"មើលការនាំចូលជាមុន",
  Entry:"ការចូល",
  Entries:"ធាតុ",
  No_Entries:"គ្មានធាតុ",
  Import_Type:"ប្រភេទនាំចូល",
  Select_Details:"ជ្រើសរើសព័ត៌មានលម្អិត",
  Select_Payee:"ជ្រើសរើសអ្នកទទួលប្រាក់",
  Enable_Holidays:"បើកដំណើរការថ្ងៃឈប់សម្រាក",
  Disable_Holidays:"បិទថ្ងៃឈប់សម្រាក",
  Wire_Transfer:"ខ្សែបញ្ជូន",
  New_Export:"ការនាំចេញថ្មី។",
  Export_Data:"នាំចេញទិន្នន័យ",
  Export_Data_Word:"ជ្រើសរើសប្រភេទឯកសារដែលត្រូវនាំចេញ និងទាញយក។",
  Export_File:"នាំចេញឯកសារ",
  Mode:"របៀប",
  Times:"ដង",
  Widgets:"ធាតុក្រាហ្វិក",
  Slider:"គ្រាប់រំកិល",
  Set_Details:"កំណត់ព័ត៌មានលម្អិត",
  Select_Type:"ជ្រើសរើសប្រភេទ",
  Display_Slider:"បង្ហាញគ្រាប់រំកិល",
  Dashboard_Slider:"គ្រាប់រំកិលផ្ទាំងគ្រប់គ្រង",
  Dashboard_Mode:"របៀបផ្ទាំងគ្រប់គ្រង",
  Show_Intro:"បង្ហាញការណែនាំ",
  Show_Payrolls:"បង្ហាញបញ្ជីប្រាក់ខែ",
  Show_Holidays:"បង្ហាញថ្ងៃបុណ្យ",
  Show_Invoices:"បង្ហាញវិក្កយបត្រ",
  Show_Cheques:"បង្ហាញការត្រួតពិនិត្យ",
  Enabled_Widgets:"ធាតុក្រាហ្វិកដែលបានបើក",
  Disabled_Widgets:"ធាតុក្រាហ្វិកដែលបានបិទ",
  Colors:"ពណ៌",
  Barcodes:"បាកូដ",
  View_Timers:"មើលកម្មវិធីកំណត់ពេល",
  Gradients:"ជម្រាល",
  No_Info:"គ្មានព័ត៌មាន",
  Disable:"បិទ",
  Show_Layer:"បង្ហាញស្រទាប់",
  Hide_Layer:"លាក់ស្រទាប់",
  Text_Layer:"ស្រទាប់អត្ថបទ",
  Secondly:"ទីពីរ",
  Minutely:"នាទី",
  nine_am:"ម៉ោង 9:00 ព្រឹក",
  five_pm:"ម៉ោង 5:00 ល្ងាច",
  Enable_Address:"បើកអាសយដ្ឋាន",
  Invalid_File_Type:"សូមអភ័យទោស ប្រភេទឯកសារមិនត្រឹមត្រូវត្រូវបានជ្រើសរើស។ ប្រភេទឯកសារដែលគាំទ្រ៖",
  Error_Updating_Entry:"សូមអភ័យទោស មានកំហុសក្នុងការធ្វើបច្ចុប្បន្នភាពធាតុនេះ។",
  Schedule_Timing_Alert:"កំហុស៖ ពេលវេលាចាប់ផ្តើមកាលវិភាគត្រូវបានកំណត់ទៅជាតម្លៃបន្ទាប់ពីពេលវេលាបញ្ចប់។",
  Select_Multiple_Payments_Prompt:"មិនបានជ្រើសរើសការទូទាត់ទេ។ ជ្រើសរើសការទូទាត់យ៉ាងហោចណាស់មួយ។",
  Select_Multiple_Cheques_Prompt:"មិនបានជ្រើសរើសការត្រួតពិនិត្យទេ។ សូមជ្រើសរើសពិនិត្យយ៉ាងហោចណាស់មួយ។",
  Select_Multiple_Items_Prompt:"មិនបានជ្រើសរើសធាតុទេ។ សូមជ្រើសរើសយ៉ាងហោចណាស់ធាតុមួយ។",
  Paymemt_Multiple_Delete_Confirmation:"តើអ្នកប្រាកដថាចង់លុបការបង់ប្រាក់ច្រើនមែនទេ?",
  Cheque_Multiple_Delete_Confirmation:"តើអ្នកប្រាកដថាចង់លុបការត្រួតពិនិត្យច្រើនទេ?",
  Payee_Multiple_Delete_Confirmation:"តើអ្នកប្រាកដថាចង់លុបអ្នកបង់ប្រាក់ច្រើនទេ?",
  Employee_Multiple_Delete_Confirmation:"តើអ្នកប្រាកដថាចង់លុបបុគ្គលិកច្រើននាក់មែនទេ?",
  MICR_Warning:"ចំណាំ៖ ម៉ាស៊ីនបោះពុម្ព និងឧបករណ៍មួយចំនួនអាចមិនបង្ហាញពុម្ពអក្សរ MICR ត្រឹមត្រូវ។",
  Automatically_Send:"ផ្ញើដោយស្វ័យប្រវត្តិ",
  Type:"ប្រភេទ",
  Payment_Type:"ប្រភេទការទូទាត់",
  Auto_Send:"ផ្ញើដោយស្វ័យប្រវត្តិ",
  Automatically_Process:"ដំណើរការដោយស្វ័យប្រវត្តិ",
  Auto_Process:"ដំណើរការស្វ័យប្រវត្តិ",
  Image_Based:"ផ្អែកលើរូបភាព",
  Font_Based:"ផ្អែកលើពុម្ពអក្សរ",
  Rerender:"បង្ហាញឡើងវិញ",
  Rendering:"ការបង្ហាញ",
  Render:"ឯកសារ",
  Top:"កំពូល",
  Middle:"កណ្តាល",
  Bottom:"បាត",
  Top_Left:"កំពូលឆ្វេង",
  Top_Center:"មជ្ឈមណ្ឌលកំពូល",
  Top_Right:"ខាងលើស្តាំ",
  Middle_Left:"កណ្តាលឆ្វេង",
  Middle_Center:"មជ្ឈមណ្ឌលកណ្តាល",
  Middle_Right:"ស្តាំកណ្តាល",
  Bottom_Left:"បាតខាងឆ្វេង",
  Bottom_Center:"មជ្ឈមណ្ឌលខាងក្រោម",
  Bottom_Right:"នៅខាងស្ដាំផ្នែកខាងក្រោម",
  Numbers:"លេខ",
  Verified:"ផ្ទៀងផ្ទាត់",
  Paper_Size:"ទំហំក្រដាស",
  New_Device:"ឧបករណ៍ថ្មី។",
  Add_Device:"បន្ថែមឧបករណ៍",
  Remove_Device:"ដកឧបករណ៍ចេញ",
  Delete_Device:"លុបឧបករណ៍",
  Block_Device:"រារាំងឧបករណ៍",
  Block:"ទប់ស្កាត់",
  Unblock:"ឈប់ទប់ស្កាត់",
  Table:"តុ",
  Scan_Login_Code:"ស្កេនលេខកូដចូល",
  Login_Code:"លេខកូដចូល",
  Scan_Code:"ស្កេនលេខកូដ",
  Scan_QR_Code:"ស្កេន QR កូដ",
  Select_All:"ជ្រើសរើសទាំងអស់",
  Deselect_All:"ដកការជ្រើសរើសទាំងអស់។",
  Increase:"កើនឡើង",
  Decrease:"ថយចុះ",
  Bold:"ដិត",
  Text:"អត្ថបទ",
  Style:"រចនាប័ទ្ម",
  Italic:"ទ្រេត",
  QR_Code:"កូដ QR",
  Barcode:"បាកូដ",
  Browse_Images:"រកមើលរូបភាព",
  Browse_Files:"រកមើលឯកសារ",
  Background_Image:"រូបភាពផ្ទៃខាងក្រោយ",
  Logo_Image:"រូបភាពនិមិត្តសញ្ញា",
  Header_Image:"រូបភាពបឋមកថា",
  Bank_Image:"រូបភាពធនាគារ",
  Cut_Lines:"កាត់បន្ទាត់",
  Background:"ផ្ទៃខាងក្រោយ",
  License:"អាជ្ញាប័ណ្ណ",
  One_License:"1 អាជ្ញាប័ណ្ណ៖",
  Licensed:"មានអាជ្ញាប័ណ្ណដល់៖",
  Not_Licensed:"មិនមានអាជ្ញាប័ណ្ណ",
  Enter_Serial:"បញ្ចូលស៊េរី",
  Serial_Key:"គ្រាប់ចុចសៀរៀល",
  Serial:"សៀរៀល",
  Product_Key:"លេខកូខទំនិញ",
  Check_Product_Key:"ពិនិត្យសោផលិតផល",
  Add_Product_Key:"បញ្ចូលលេខកូដផលិតផល",
  Verifying_Purchase:"ផ្ទៀងផ្ទាត់ការទិញ...",
  Print_Envelope:"បោះពុម្ពស្រោមសំបុត្រ",
  Envelope:"ស្រោមសំបុត្រ",
  Thank_You:"សូមអរគុណ!",
  Scale:"មាត្រដ្ឋាន",
  Print_Scale:"មាត្រដ្ឋានបោះពុម្ព",
  Borders:"ព្រំដែន",
  VOID:"វីអូឌី",
  Void_Cheque:"ពិនិត្យទុកជាមោឃៈ",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"បង់ទៅកាន់ការបញ្ជាទិញរបស់:",
  NO_DOLLARS:"គ្មានដុល្លារ ",
  ONE_DOLLAR:"មួយដុល្លារ",
  DOLLARS:" ដុល្លារ",
  AND:" និង ",
  CENTS:" សេន",
  NO_:"ទេ ",
  ONE_:"មួយ។ ",
  AND_NO_:"និងទេ។ ",
  _AND_ONE_:"និងមួយ។ ",
  DOLLARS_AND_ONE_CENT:" និងមួយសេន។",
  AND_NO_CENTS:" និង សូន្យសេន ។",
  CHEQUE_PRINT_DATE:"កាលបរិច្ឆេទ៖",
  CHEQUE_PRINT_MEMO:"MEMO៖",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"កិច្ចការដំបូង",
  Inital_Setup:"ការដំឡើងដំបូង",
  Welcome_To:"ស្វាគមន៍​មកកាន់ ",
  Welcome:"សូមស្វាគមន៍",
  Swipe:"អូស",
  Intro_Setup:"ការដំឡើងការណែនាំ",
  Introduction:"សេចក្តីផ្តើម",
  CHEQUE_PRINT_CREDIT:"ឧបត្ថម្ភដោយ Cheques",
  Title_Intro_Word:"សូមស្វាគមន៍មកកាន់ Cheques",
  Title_Intro:"សេចក្តីណែនាំអំពីការពិនិត្យ",
  Title_Setup:"ការ​កំណត់​ការ​ពិនិត្យ",
  PayPal_Default_Email_Message:"អ្នក​បាន​ទទួល​ការ​ផ្ទេរ​ប្រាក់ PayPal ថ្មី។ [ឧបត្ថម្ភ​ដោយ Cheques]",
  Cheques_App_Export:"នាំចេញដោយ Cheques",
  Post_Intro_Thanks:"សូមអរគុណសម្រាប់ការជ្រើសរើស Cheques ។ ដំណើរការរៀបចំឥឡូវនេះបានបញ្ចប់ហើយ។",
  Post_Intro_Word:"ចាប់ផ្តើមដោយការបោះពុម្ពមូលប្បទានប័ត្រដំបូងរបស់អ្នក បន្ថែមការបង់ប្រាក់នាពេលអនាគត ឬបន្ថែមបុគ្គលិកទៅក្នុងបញ្ជីប្រាក់បៀវត្សរ៍។",
  Upgrade_Required:"Cheques តម្រូវ​ឱ្យ​អ្នក​ធ្វើ​ជា​ម្ចាស់​នៃ​កំណែ​ពិសេស​ជាង​នេះ​នៃ​កម្មវិធី​ដើម្បី​លាក់​សារ​នេះ និង​ដោះ​សោ​លក្ខណៈ​ពិសេស​បន្ថែម​ទៀត​។",
  Upgrade_Title:"ពិនិត្យ",
  Mailout_Prompt:"បន្ថែមពីលើនេះទៀត អ្នកអាចជ្រើសរើសឱ្យមាន Checks mail-out ការត្រួតពិនិត្យបញ្ជីប្រាក់បៀវត្សរ៍របស់អ្នកសម្រាប់អ្នក។",
  Mailed_Cheque:"ការត្រួតពិនិត្យតាមសំបុត្រ៖",
  Mailed_Cheque_Color:"ពិនិត្យតាមសំបុត្រ (ពណ៌):",
  Terms_Purchase:"រាល់ការទិញតាមប្រព័ន្ធអេឡិចត្រូនិចជាមួយមូលប្បទានប័ត្រគឺអាចសងវិញបានពេញលេញក្នុងរយៈពេល 30 ថ្ងៃចាប់ពីថ្ងៃទិញ។ សូមអាន និងយល់ព្រមជាមួយ <a href='#'>លក្ខខណ្ឌ</a> មុនពេលបន្ត។ ",
  Dashboard_Setup:"ដំឡើងម៉ាស៊ីនបោះពុម្ពបឋម",
  Dashboard_Add:"បន្ថែមគណនីធនាគារបឋម",
  Dashboard_Customize:"ជ្រើសរើសគំរូពិនិត្យ",
  Dashboard_Job_Salary:"បង្កើតការងាររបស់អ្នក និងបន្ថែមប្រាក់ខែរបស់អ្នក",
  Dashboard_Employees:"តាមដានបុគ្គលិក និងអ្នកទទួលប្រាក់",
  Dashboard_Print:"បោះពុម្ព និងផ្ញើសំបុត្រប្រាក់បៀវត្សរ៍របស់អ្នក",
  Dashboard_Payroll:"ការបោះពុម្ពប្រាក់បៀវត្សរ៍របស់អ្នកដោយស្វ័យប្រវត្តិ",
  Dashboard_PayPal:"ដំឡើង PayPal Payroll / Payouts",
  Begin_Setup:"ចាប់ផ្តើមការដំឡើង",
  Get_Started:"ចាប់ផ្តើម",
  Purchase:"ទិញ",
  Lockdown:"ការ​បង្ខាំង​មិន​ឱ្យ​ចេញ​ចូល",
  Unlock:"ដោះសោ",
  Detailed:"លម្អិត",
  Log_In:"ចូល",
  Login:"ចូល",
  Launch:"បើកដំណើរការ",
  Register:"ចុះឈ្មោះ",
  Finish:"បញ្ចប់",
  List:"បញ្ជី",
  Weekends:"ចុងសប្តាហ៍",
  Weekly:"ប្រចាំសប្តាហ៍",
  PayPal_Default_Subject:"ការទូទាត់ថ្មី",
  Payment_Message:"សារបង់ប្រាក់",
  PayPal_Default_Payment_Note:"សូមអរគុណ",
  Setup_Your_Cheqing_Account:"ពិនិត្យគណនី",
  Add_Your_Primary_Cheqing_Account:"បន្ថែមគណនីត្រួតពិនិត្យចម្បងរបស់អ្នក។",
  Welcome_Word:"ធ្វើឱ្យសាមញ្ញ និងស្វ័យប្រវត្តិកម្មបញ្ជីប្រាក់ខែ និងការគ្រប់គ្រងធនធានមនុស្ស។",
  Get_Started_Quickly:"គ្រាន់តែឆ្លើយសំណួរសាមញ្ញមួយចំនួនដែលនឹងជួយយើងចាប់ផ្តើម...",
  Done_Intro_Word:"បញ្ចប់ការដំឡើង",
  PIN_Protected:"ពាក្យសម្ងាត់ & PIN ការពារ",
  Enter_New_PIN:"បញ្ចូលលេខកូដ PIN ថ្មី៖",
  Enter_PIN:"បញ្ចូលកូដ PIN៖",
  Invalid_PIN:"បញ្ចូលកូដ PIN មិនត្រឹមត្រូវ",
  Account_Identifier:"លេខសម្គាល់គណនី",
  New_Account_Identifier:"ការកំណត់អត្តសញ្ញាណគណនីថ្មី",
  attempt:"ការប៉ុនប៉ង",
  attempts:"ការប៉ុនប៉ង",
  remaining:"នៅសល់",
  Language:"ភាសា",
  languages:"ភាសា",
  select_languages:"ជ្រើសរើស​ភាសា",
  Deposit:"ដាក់ប្រាក់",
  Hire_One_Now:"ជួលម្នាក់ឥឡូវនេះ",
  App_Locked:"កម្មវិធីត្រូវបានចាក់សោ។",
  Skip_:"រំលង",
  Skip_to_Dashboard:"រំលងទៅផ្ទាំងគ្រប់គ្រង",
  Dashboard:"ផ្ទាំងគ្រប់គ្រង",
  Import:"នាំចូល",
  Admin:"អ្នកគ្រប់គ្រង",
  New_Admin:"អ្នកគ្រប់គ្រងថ្មី",
  Settings:"ការកំណត់",
  Color_Picker:"អ្នកជ្រើសរើសពណ៌",
  Font_Picker:"កម្មវិធីជ្រើសរើសពុម្ពអក្សរ",
  Logout:"ចាកចេញ",
  Close:"បិទ",
  Close_menu:"បិទ",
  Excel:"ឯកសារ Excel",
  Csv:"ឯកសារ CSV",
  Sql:"ឯកសារ SQL",
  Json:"ឯកសារ JSON",
  Url:"នាំចូលតាម URL",
  Back:"ត្រឡប់មកវិញ",
  Timers:"កម្មវិធីកំណត់ម៉ោង",
  Cheque:"ពិនិត្យ",
  Print:"បោះពុម្ព",
  Designs:"ការរចនា",
  Pause_Printing:"ផ្អាកការបោះពុម្ព",
  Resume_Printing:"បន្តការបោះពុម្ព",
  Printing_Paused:"ផ្អាកការបោះពុម្ព",
  PrintingUnavailable:"សូមអភ័យទោស! ការបោះពុម្ពគឺមិនមាននៅលើប្រព័ន្ធនេះ។",
  Prints_Paused:"ផ្អាកការបោះពុម្ព",
  Administration:"រដ្ឋបាល",
  Loading:"កំពុងផ្ទុក",
  Unnamed:"គ្មានឈ្មោះ",
  error:"សូមអភ័យទោស ការត្រួតពិនិត្យនេះមិនអាចបើកសម្រាប់មើលបានទេ។",
  No_Cheques_To_Print:"គ្មានការត្រួតពិនិត្យដើម្បីបោះពុម្ព",
  No_Cheques_To_Print_Word:"បង្កើត <a routerLink='/folder/Cheque/New'>ពិនិត្យថ្មី។</a>",
  New_Cheque:"ពិនិត្យថ្មី",
  Start_One_Now:"ចាប់ផ្តើមមួយឥឡូវនេះ",
  Edit_Cheque:"ពិនិត្យកែសម្រួល",
  Select_Cheques:"ជ្រើសរើសការត្រួតពិនិត្យ",
  Select_Employee:"ជ្រើសរើសបុគ្គលិក",
  Default_Printer:"ម៉ាស៊ីនបោះពុម្ពលំនាំដើម",
  Reassign:"ចាត់តាំងឡើងវិញ",
  Configure:"កំណត់រចនាសម្ព័ន្ធ",
  Template:"គំរូ",
  Designer:"អ្នករចនា",
  Edit_Designs:"កែសម្រួលការរចនា",
  New_Design:"ការរចនាថ្មី",
  Next:"បន្ទាប់",
  Save:"រក្សាទុក",
  Name:"ឈ្មោះ",
  Mail:"សំបុត្រ",
  Amount:"ចំនួន",
  Date:"កាលបរិច្ឆេទ",
  PayPal:"PayPal",
  Payouts:"ការទូទាត់",
  PayPal_Label:"ស្លាក PayPal",
  Email_Username:"អ៊ីមែល / ឈ្មោះអ្នកប្រើ",
  Client_ID:"លេខសម្គាល់អតិថិជន",
  Sandbox_Email:"Sandbox Email",
  PayPal_Email:"អ៊ីមែល PayPal",
  PayPal_Username:"ឈ្មោះអ្នកប្រើប្រាស់ API",
  PayPal_Payouts:"ការទូទាត់ PayPal",
  Select_PayPal_Key:"ជ្រើសរើស PayPal Key",
  Secret:"សម្ងាត់",
  API_Secret:"API Secret",
  PayPal_API_Keys:"សោ PayPal",
  New_PayPal_Key:"សោ PayPal ថ្មី",
  Email_Subject:"ប្រធានបទអ៊ីមែល",
  Email_Message:"សារអ៊ីមែល",
  Payout_Options:"ជម្រើសបង់ប្រាក់",
  Payment_Note:"កំណត់ចំណាំការទូទាត់",
  Enable_Employee:"បើកដំណើរការបុគ្គលិក",
  Enable_Production_Mode:"បើករបៀបផលិតកម្ម",
  Sandbox_Username:"ឈ្មោះអ្នកប្រើ Sandbox",
  Sandbox_Signature:"ហត្ថលេខា Sandbox",
  Sandbox_Password:"ពាក្យសម្ងាត់ Sandbox",
  Production_Username:"ឈ្មោះអ្នកប្រើប្រាស់ផលិតកម្ម",
  Production_Signature:"ហត្ថលេខាផលិតកម្ម",
  Production_Password:"ពាក្យសម្ងាត់ផលិតកម្ម",
  Production_Email:"អ៊ីមែលផលិតកម្ម",
  API_Client_ID:"លេខសម្គាល់អតិថិជន API",
  API_Signature:"ហត្ថលេខា API",
  API_Password:"ពាក្យសម្ងាត់ API",
  API_Username:"ឈ្មោះអ្នកប្រើប្រាស់ API",
  Secret_Key:"គន្លឹះសម្ងាត់",
  Sandbox:"ប្រអប់ខ្សាច់",
  Production:"ផលិតផល",
  Sandbox_Keys:"គ្រាប់ចុច Sandbox",
  Production_Keys:"សោផលិតកម្ម",
  API_Title:"ចំណងជើង API",
  Production_Mode:"របៀបផលិតកម្ម",
  Account_Label:"ស្លាកគណនី",
  No_PayPal_Setup:"គ្មានសោ PayPal",
  Enable_PayPal_Account:"បើកគណនី PayPal",
  No_PayPal_Word:"បន្ថែម <a routerLink='/folder/Invoice/New'>សោ API របស់ PayPal</a> របស់អ្នក។",
  Printed_Memo:"អនុស្សរណៈដែលបានបោះពុម្ព",
  Employee:"និយោជិត",
  View_Employee:"មើលបុគ្គលិក",
  Mailing_Address:"អាសយដ្ឋានប្រៃសណីយ៍",
  Company_Address:"អាសយដ្ឋានក្រុមហ៊ុន",
  Select_Company_Address:"ជ្រើសរើសអាសយដ្ឋានក្រុមហ៊ុន",
  Address:"អាសយដ្ឋាន",
  Any_Day:"ថ្ងៃណាមួយ",
  Address_Name:"ឈ្មោះអាសយដ្ឋាន",
  Unit:"ឯកតា",
  Account:"គណនី",
  Bank_Account:"គណនីធនាគារ",
  Account_Limits:"បើកដែនកំណត់គណនី",
  Payroll:"ប្រាក់ខែ",
  New_Payroll:"ប្រាក់ខែថ្មី",
  No_Payroll:"គ្មានប្រាក់បៀវត្សរ៍នាពេលខាងមុខ",
  Upcoming_Holiday:"វិស្សមកាលខាងមុខនេះ៖",
  Invoice_Due:"វិក័យប័ត្រដល់កំណត់៖",
  New_Invoice:"វិក្កយបត្រថ្មី",
  No_Invoices:"គ្មានវិក្កយបត្រ",
  No_Invoices_Word:"បង្កើត <a routerLink='/folder/Invoice/New'>វិក្កយបត្រ</a> ដំបូង។",
  Cheque_Due:"ពិនិត្យ​ផុត​កំណត់៖",
  Payrolls:"ប្រាក់បៀវត្សរ៍",
  Sandbox_Mode:"របៀប Sandbox",
  Hire:"ជួល",
  Pay:"បង់",
  New:"ថ្មី",
  Add:"បន្ថែម",
  Make:"ធ្វើ",
  Time:"ពេលវេលា",
  Write:"សរសេរ",
  Holiday:"ថ្ងៃឈប់សម្រាក",
  Holidays:"ថ្ងៃឈប់សម្រាក",
  Next_Holiday:"ថ្ងៃឈប់សម្រាកបន្ទាប់៖",
  All_Done:"រួចរាល់ទាំងអស់!",
  Employees:"បុគ្គលិក",
  Payees:"អ្នកទទួលប្រាក់",
  Job:"ការងារ",
  Jobs:"ការងារ",
  Invoice:"វិក័យប័ត្រ",
  Invoices:"វិក័យប័ត្រ",
  Vacations:"វិស្សមកាល",
  Cheques:"ពិនិត្យ",
  Brand_Cheques:"ម៉ាក",
  Payment:"ការទូទាត់",
  Enable_Payment:"បើកការទូទាត់",
  Payments:"ការទូទាត់",
  Schedule:"កាលវិភាគ",
  Schedules:"កាលវិភាគ",
  Timesheet:"តារាង​ពេលវេលា",
  Timesheets:"សន្លឹកពេលវេលា",
  Salary:"ប្រាក់ខែ",
  New_Address:"អាសយដ្ឋានថ្មី",
  Address_2:"អ​ស​យ​ដ្ឋាន​ខ្សែ​រ​ទី 2)",
  _City:"ទីក្រុង",
  _State:"រដ្ឋ/វិញ្ញាបនបត្រ",
  City:"ក្រុង/ក្រុង",
  State:"រដ្ឋ/ខេត្ត",
  Postal:"ប្រៃសណីយ៍ / លេខកូដប្រៃសណីយ៍",
  ZIP:"ប្រៃសណីយ៍ / ហ្ស៊ីប",
  Country:"ប្រទេស",
  Addresses:"អាសយដ្ឋាន",
  Required_Options:"ជម្រើសដែលត្រូវការ",
  Optional_Options:"ជម្រើសស្រេចចិត្ត",
  Additional_Options:"ជម្រើសបន្ថែម",
  Required:"ទាមទារ",
  Optional:"ស្រេចចិត្ត",
  Additional:"បន្ថែម",
  No_Addresses:"គ្មានអាសយដ្ឋាន",
  New_Address_Word:"បន្ថែម <a routerLink='/folder/Address/New'>អាសយដ្ឋាន</a> ដំបូង។",
  Select_Address:"ជ្រើសរើសអាសយដ្ឋាន",
  No_Address:"គ្មានអាសយដ្ឋាន",
  Print_Cheque:"ពិនិត្យការបោះពុម្ព",
  Print_Cheque_Now:"បោះពុម្ពពិនិត្យឥឡូវនេះ",
  Description:"ការពិពណ៌នា",
  Pay_To_The_Order_Of:"បង់តាមលំដាប់នៃ:",
  Select_Date_Range:"ជ្រើសរើសជួរកាលបរិច្ឆេទ",
  Select_Starting_Date:"ជ្រើសរើសកាលបរិច្ឆេទចាប់ផ្តើម",
  Select_Ending_Date:"ជ្រើសរើសកាលបរិច្ឆេទបញ្ចប់",
  Select_Date:"ជ្រើសរើសកាលបរិច្ឆេទ",
  Cheque_Date:"ពិនិត្យកាលបរិច្ឆេទ",
  Cheque_Memo:"ពិនិត្យអនុស្សរណៈ",
  Blank_Cheque:"ពិនិត្យទទេ",
  Blank:"ទទេ",
  No_Cheques:"គ្មានការត្រួតពិនិត្យ",
  No_Cheques_Word:"បោះពុម្ព <a routerLink='/folder/Cheque/New'>ពិនិត្យ</a> ដំបូងរបស់អ្នក",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"មើលរូបភាព",
  View:"មើល",
  Modify:"កែប្រែ",
  Delete:"លុប",
  Cheque_Paid:"បង់",
  New_Deduction:"ការកាត់ថ្មី",
  Title:"ចំណងជើង",
  Frequency:"ប្រេកង់",
  Hourly:"រៀងរាល់ម៉ោង",
  Daily:"ប្រចាំថ្ងៃ",
  Weekdays:"ថ្ងៃធ្វើការ",
  BiWeekly:"2 ស​ប្ដា​ហ៏",
  TriWeekly:"3 សប្តាហ៍",
  Monthly:"ប្រចាំខែ",
  MiMonthly:"2 ខែ",
  Quarterly:"ប្រចាំត្រីមាស",
  Yearly:"ប្រចាំឆ្នាំ",
  Every_Week:"រាល់​ស​ប្តា​ហ៍",
  Every_Payroll:"រាល់ប្រាក់បៀវត្សរ៍",
  Every_Month:"រៀងរាល់​ខែ",
  Annually:"ប្រចាំឆ្នាំ",
  Always_Scheduled:"កំណត់ពេលជានិច្ច",
  Start_Date:"ថ្ងៃ​ចាប់ផ្តើម",
  End_Date:"កាលបរិច្ឆេទបញ្ចប់",
  Start_Time:"ពេលវេលាចាប់ផ្តើម",
  End_Time:"ពេលវេលាបញ្ចប់",
  Deduction_Label:"ស្លាកកាត់",
  Notes:"កំណត់ចំណាំ",
  Options:"ជម្រើស",
  Enable:"បើក",
  Select_Deductions:"ជ្រើសរើសការកាត់ប្រាក់",
  Deductions:"ការកាត់បន្ថយ",
  No_Deductions:"គ្មានការកាត់ប្រាក់",
  No_Deductions_Word:"បង្កើត <a routerLink='/folder/Deduction/New'>ការកាត់ប្រាក់</a> ដំបូងរបស់អ្នក",
  New_Employee:"បុគ្គលិកថ្មី",
  No_Title:"គ្មានចំណងជើង",
  _Name:"ឈ្មោះ",
  About_Yourself:"អំពីខ្លួនអ្នក",
  Full_Name:"ឈ្មោះ​ពេញ",
  Birthday:"ខួបកំណើត",
  Email:"អ៊ីមែល",
  SMS:"សារ",
  Phone:"ទូរស័ព្ទ",
  Test:"សាកល្បង",
  Call:"ហៅ",
  Fax:"ទូរសារ",
  Printed_Note:"ចំណាំដែលបានបោះពុម្ព",
  Position:"ទីតាំង",
  Job_Position:"មុខតំណែងការងារ",
  Select_a_Job:"ជ្រើសរើសការងារ",
  Select_a_Salary:"ជ្រើសរើសប្រាក់ខែ",
  Add_a_Deduction:"បន្ថែមការកាត់ប្រាក់",
  Taxes:"ពន្ធ",
  Add_Taxes:"បន្ថែមពន្ធ",
  Date_of_Birth:"ថ្ងៃខែ​ឆ្នាំ​កំណើត",
  Email_Address:"អាស័យ​ដ្ឋាន​អ៊ី​ម៉េ​ល",
  Phone_Number:"លេខទូរសព្ទ",
  Phone_Call:"ការហៅទូរស័ព្ទ",
  Enable_on_Payroll:"បើកដំណើរការប្រាក់បៀវត្សរ៍",
  Select_Employees:"ជ្រើសរើសបុគ្គលិក",
  No_Employees:"គ្មានបុគ្គលិក",
  No_Employees_Word:"បន្ថែម <a routerLink='/folder/Employee/New'>និយោជិត</a> ថ្មីដំបូងរបស់អ្នក",
  No_Name:"គ្មាន​ឈ្មោះ",
  Unemployeed:"គ្មាន​ការងារ​ធ្វើ",
  Employeed:"មានការងារធ្វើ",
  Paid:"បង់",
  Enabled:"បានបើក",
  Disabled:"ពិការ",
  Fire:"ភ្លើង",
  Not_Available:"មិនអាច",
  Not_Available_Word:"បោះពុម្ព <a routerLink='/folder/Invoice/New'>វិក្កយបត្រ</a> ដំបូងរបស់អ្នក ហើយទទួលប្រាក់។",
  Select_Invoices:"Select_Invoices",
  Print_Invoice_Word:"បោះពុម្ព <a routerLink='/folder/Invoice/New'>វិក្កយបត្រ</a> ដំបូងរបស់អ្នក ហើយទទួលប្រាក់។",
  Invoice_Title:"ចំណងជើងវិក័យប័ត្រ",
  Invoice_Date:"កាលបរិច្ឆេទវិក័យប័ត្រ",
  Due_Date:"កាលបរិច្ឆេទ​កំណត់",
  New_Job:"ការងារ​ថ្មី",
  Details:"ព័ត៌មានលម្អិត",
  Customize:"ប្ដូរតាមបំណង",
  Customize_Dashboard:"ប្ដូរតាមបំណងផ្ទាំងគ្រប់គ្រង",
  Components:"សមាសធាតុ",
  No_Components:"គ្មានសមាសធាតុ",
  Main_Components:"សមាសធាតុសំខាន់ៗ",
  Smaller_Components:"សមាសធាតុតូចជាង",
  Error_Loading_Page:"កំហុសក្នុងការផ្ទុកទំព័រនេះ។",
  Bank_Details:"ព​ត៌​មាន​លម្អិត​នៃ​ធនាគារ",
  About_Your_Job:"អំពីការងាររបស់អ្នក",
  Your_Schedule:"កាលវិភាគរបស់អ្នក",
  Job_Title:"ចំណងជើងការងារ",
  Job_Description:"ការពិពណ៌នាការងារ",
  Job_Details:"ព័ត៌មានលម្អិតការងារ",
  Enable_Job:"បើកការងារ",
  Pay_Period:"រយៈពេលបង់ប្រាក់",
  Perpetually_Schedule:"កាលវិភាគជារៀងរហូត",
  Select_Jobs:"ជ្រើសរើសការងារ",
  No_Jobs:"គ្មានការងារធ្វើ",
  Add_Jobs:"បន្ថែមការងារ",
  No_Jobs_Word:"បន្ថែម <a routerLink='/folder/Job/New'>ការងារ</a> ដំបូងទៅក្នុងបញ្ជី។",
  Count_Employees:"job.employee_count+' បុគ្គលិក'",
  Zero_Employees:"0 បុគ្គលិក",
  New_Leave:"ការចាកចេញថ្មី",
  Leave_Name:"ទុកឈ្មោះ",
  Paid_Leave:"ការឈប់សម្រាកដែលបានបង់",
  Leave_Pay:"ចាកចេញពីការបង់ប្រាក់",
  Indefinite_Leave:"ការចាកចេញដោយគ្មានកំណត់",
  Indefinite_Payroll:"ប្រាក់ខែមិនកំណត់",
  Leave:"ចាកចេញ",
  Add_Schedules:"បន្ថែមកាលវិភាគ",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"បើកដំណើរការអវត្តមាន",
  Select_Leaves:"ជ្រើសរើសស្លឹក",
  No_Leaves:"គ្មានស្លឹកនៃអវត្តមាន",
  Leave_Of_Absence:"ការចាកចេញពីអវត្តមាន",
  Leaves_Of_Absence:"ស្លឹកនៃអវត្តមាន",
  New_Leave_of_Absense:"ការឈប់សម្រាកថ្មី",
  No_Leaves_Word:"បន្ថែម <a routerLink='/folder/Leave/New'>ការចាកចេញពីអវត្តមានដំបូង</a>",
  Not_Enabled:"មិនបានបើក",
  Absences:"អវត្តមាន",
  Payee:"អ្នកទទួលប្រាក់",
  New_Payee:"អ្នកទទួលប្រាក់ថ្មី",
  Payee_Identifier:"អត្តសញ្ញាណអ្នកទទួលប្រាក់",
  Payee_Name:"ឈ្មោះអ្នកទទួលប្រាក់",
  Payee_Title:"ចំណងជើងអ្នកទទួលប្រាក់",
  Payment_Memo:"អនុស្សរណៈនៃការបង់ប្រាក់",
  Select_Payees:"ជ្រើសរើសអ្នកទទួលប្រាក់",
  No_Payees:"គ្មានអ្នកទទួលប្រាក់",
  Add_Payee_Note:"បន្ថែម <a routerLink='/folder/Payee/New'>អ្នកទទួលប្រាក់</a> ដំបូង។",
  New_Payees:"អ្នកទទួលប្រាក់ថ្មី",
  About_The_Payment_Schedule:"កាលវិភាគទូទាត់",
  Your_Payroll_Schedule:"បើកប្រាក់បៀវត្សរ៍ដោយស្វ័យប្រវត្តិ",
  New_Payment:"ការទូទាត់ថ្មី",
  Identifier:"អ្នកកំណត់អត្តសញ្ញាណ",
  Select:"ជ្រើសរើស",
  Memo:"អនុស្សរណៈ",
  Payment_Date:"កាលបរិច្ឆេទទូទាត់ប្រាក់",
  Mark_as_Paid:"សម្គាល់ថាបានបង់",
  Select_Payments:"ជ្រើសរើសការទូទាត់",
  No_Payments:"គ្មានការបង់ប្រាក់",
  No_Payments_Word:"បង្កើត <a routerLink='/folder/Payment/New'>ការទូទាត់</a> ដំបូង។",
  Create_Payroll:"បង្កើតប្រាក់ខែ",
  Properties:"ទ្រព្យសម្បត្តិ",
  Payroll_Title:"ចំណងជើងប្រាក់ខែ",
  Payroll_Notes:"កំណត់ចំណាំប្រាក់ខែ",
  Payroll_Setup:"ការដំឡើងប្រាក់ខែ",
  Tabulate_Payments:"តារាងការទូទាត់",
  Tabulate:"តារាង",
  By:"ដោយ៖",
  Payments_By:"ការទូទាត់ដោយ",
  Timesheets_And_Schedules:"តារាងពេលវេលា និងកាលវិភាគ",
  Both:"ទាំងពីរ",
  Items:"ធាតុ",
  Add_Payees:"បន្ថែមអ្នកទទួលប្រាក់",
  Add_Account:"បន្ថែមគណនី",
  Enable_Account:"បើកគណនី",
  Enable_Payroll:"បើកប្រាក់បៀវត្សរ៍",
  Print_Payroll:"បោះពុម្ពប្រាក់បៀវត្សរ៍",
  No_Payrolls:"គ្មានប្រាក់ខែ",
  No_Payroll_Word:"បង្កើត <a routerLink='/folder/Payroll/New'>ប្រាក់បៀវត្សរ៍</a> ដំបូងរបស់អ្នក",
  View_more:"មើល​ច្រើន​ទៀត",
  Less:"តិច",
  Add_Payroll:"បន្ថែមប្រាក់ខែ",
  Select_Payroll:"ជ្រើសរើសប្រាក់ខែ",
  About_Your_Salary:"អំពីប្រាក់ខែរបស់អ្នក",
  Add_Salaries:"បន្ថែមប្រាក់ខែ",
  Add_Salary:"បន្ថែមប្រាក់ខែ",
  Salaries:"ប្រាក់ខែ",
  No_Salaries:"គ្មានប្រាក់ខែ",
  No_Salaries_Word:"បន្ថែម <a routerLink='/folder/Salary/New'>ប្រាក់ខែ</a> ដំបូង។",
  Select_Salaries:"ជ្រើសរើសប្រាក់ខែ",
  New_Salary:"ប្រាក់ខែថ្មី",
  Salary_Name:"ការកំណត់អត្តសញ្ញាណប្រាក់ខែ",
  Enable_Salary:"បើកប្រាក់ខែ",
  Salary_Amount:"ចំនួនប្រាក់ខែ",
  New_Schedule:"កាលវិភាគថ្មី",
  Schedule_Title:"ចំណងជើងកាលវិភាគ",
  Add_Address:"បន្ថែមអាសយដ្ឋាន",
  Repeat:"ធ្វើម្តងទៀត",
  Design:"រចនា",
  Edit_Design:"កែសម្រួលការរចនា",
  Edit_this_Design:"កែសម្រួលការរចនានេះ",
  Repeat_Payment:"ការទូទាត់ម្តងទៀត",
  Enable_Schedule:"បើកកាលវិភាគ",
  Never:"មិនដែល",
  Select_Schedule:"ជ្រើសរើសកាលវិភាគ",
  No_Schedules:"គ្មានកាលវិភាគ",
  No_Schedules_Word:"បង្កើត <a routerLink='/folder/Schedule/New'>កាលវិភាគ</a> ដំបូង។",
  New_Administrator:"អ្នកគ្រប់គ្រងថ្មី",
  Username:"ឈ្មោះ​អ្នកប្រើប្រាស់",
  First_Name:"នាមខ្លួន",
  Last_Name:"នាមត្រកូល",
  Add_an_Address:"បន្ថែមអាសយដ្ឋាន",
  Payment_Limit:"ដែនកំណត់ក្នុងមួយការទូទាត់",
  Total_Limit:"ដែនកំណត់សរុប",
  Select_Accounts:"ជ្រើសរើសគណនី",
  No_Administrators:"គ្មានអ្នកគ្រប់គ្រង",
  No_Administrators_Word:"បង្កើត <a routerLink='/folder/Administrator/New'>គណនីអ្នកគ្រប់គ្រង</a> ដំបូងបង្អស់។",
  New_Administrators_Word:"បន្ថែម <a routerLink='/folder/Administrator/New'>អ្នកគ្រប់គ្រង</a> ដំបូងបង្អស់",
  Cloud:"ពពក",
  Backup:"បម្រុងទុក",
  Enable_iCloud:"បើកដំណើរការ iCloud",
  Enable_Google_Drive:"បើកដំណើរការ Google Drive",
  Enable_Microsoft_OneDrive:"បើកដំណើរការ Microsoft OneDrive",
  Automatically_Backup:"បម្រុងទុកដោយស្វ័យប្រវត្តិ",
  FTP_Settings:"ការកំណត់ FTP",
  URL_File_Prompt:"សូមបញ្ជាក់ទីតាំងសម្រាប់ឯកសារ .xls / .xlsx / .json ដើម្បីនាំចូល៖",
  URL_SQL_Prompt:"សូមបញ្ជាក់ទីតាំងរបស់ឯកសារ SQLite ដើម្បីនាំចូល៖",
  FTP_Backup:"ការបម្រុងទុក FTP",
  FTP_Import:"ការនាំចូល FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"ម្ចាស់ផ្ទះ",
  Port:"ច្រក",
  Path:"ផ្លូវ",
  Data_Path:"ផ្លូវទិន្នន័យ",
  Enable_FTP_Account:"បើកគណនី FTP",
  HostnameIP:"ឈ្មោះម៉ាស៊ីន",
  Password:"ពាក្យសម្ងាត់",
  Connection_Port:"ច្រកតភ្ជាប់",
  Enable_MySQL_Database:"បើកដំណើរការមូលដ្ឋានទិន្នន័យ MySQL",
  Log:"កំណត់ហេតុ",
  Reset:"កំណត់ឡើងវិញ",
  Erase:"លុប",
  Export:"នាំចេញ",
  Database:"មូលដ្ឋានទិន្នន័យ",
  Upload_CSV:"ផ្ទុកឡើង CSV",
  Download_CSV:"ទាញយក CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"ការបម្រុងទុក MySQL",
  Internal_Notes:"កំណត់ចំណាំផ្ទៃក្នុង",
  Root_Path:"ផ្លូវឫស",
  Select_Backup:"ជ្រើសរើសការបម្រុងទុក",
  Add_New_Backup:"បន្ថែមការបម្រុងទុកថ្មី",
  First_Backup:"រៀបចំការបម្រុងទុកដំបូង ... ",
  Backups:"ការបម្រុងទុក",
  Add_Backup:"បន្ថែមការបម្រុងទុក",
  No_Backups: "មិន​មាន​ការ​បម្រុង​ទុក​ត្រូវ​បាន​រក​ឃើញ​ទេ​។",
  Select_Backup_Type:"ជ្រើសរើសប្រភេទនៃការបម្រុងទុកដែលអ្នកចង់ដំឡើង...",
  Backup_Type:"ប្រភេទបម្រុងទុក",
  FTP_Drive:"ថាស FTP",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"បើកបរ",
  Microsoft_OneDrive:"បើកបរ",
  Import_Fields:"នាំចូលវាល",
  Import_Fields_Word:"ប្រើផ្នែកនេះដើម្បីជ្រើសរើស <a routerLink='/folder/Holiday/New'>នាំចូល</a> ទិន្នន័យ",
  Upload:"ផ្ទុកឡើង",
  Download:"ទាញយក",
  Download_JSON:"ទាញយកជាទិន្នន័យ JSON",
  Download_SQL:"ទាញយកជាឯកសារ SQL",
  New_Bank:"ធនាគារថ្មី",
  New_Account:"គណនី​ថ្មី",
  New_Bank_Account:"គណនីធនាគារថ្មី",
  Upload_Image:"បង្ហោះរូបភាព",
  Bank_Name:"ឈ្មោះ​របស់​ធនាគារ",
  Bank_Address:"អាស័យ​ដ្ឋាន​ធនាគារ",
  Branch_Address:"អាសយដ្ឋានសាខា",
  Address_on_Cheque:"អាសយដ្ឋាន",
  Cheque_Numbers:"ពិនិត្យលេខ",
  Cheque_Details:"ពិនិត្យព័ត៌មានលម្អិត",
  Bank_Logo:"និមិត្តសញ្ញាធនាគារ",
  Cheque_ID:"ពិនិត្យលេខសម្គាល់",
  Starting_Cheque_ID:"ចាប់ផ្តើមពិនិត្យលេខសម្គាល់",
  Transit_Number:"លេខដឹកជញ្ជូន",
  Institution_Number:"លេខស្ថាប័ន",
  Designation_Number:"លេខកំណត់",
  Account_Number:"លេខ​គណនី",
  Limits:"ដែនកំណត់",
  Default_Limits:"ដែនកំណត់លំនាំដើម",
  Over_Limit:"លើសកំណត់",
  Under_Limit:"ក្រោមដែនកំណត់",
  Payroll_Limit:"ដែនកំណត់ប្រាក់ខែ",
  Enable_Bank_Account:"បើកគណនីធនាគារ",
  Select_Account:"ជ្រើសរើសគណនី",
  No_Bank_Account:"គ្មានគណនីធនាគារ",
  No_Bank_Account_Word:"បន្ថែម <a routerLink='/folder/Accounts/New'>គណនីធនាគារ</a> ដំបូងបង្អស់។",
  Bank_Accounts:"គណនីធនាគារ",
  No_Accounts:"គ្មានគណនី",
  No_Accounts_Note:"បន្ថែម <a routerLink='/folder/Accounts/New'>គណនីធនាគារ</a> ដំបូងបង្អស់។",
  Cheque_Designer:"ពិនិត្យអ្នករចនា",
  Cheque_Design:"ពិនិត្យការរចនា",
  Select_Design:"ជ្រើសរើសការរចនា",
  Cheque_Designs:"ពិនិត្យការរចនា",
  No_Cheque_Designs:"គ្មានការរចនាម៉ូដពិនិត្យ",
  No_Cheque_Designs_Word:"បង្កើត <a routerLink='/folder/Settings/Cheque/Design/New'>ពិនិត្យការរចនា</a> ផ្ទាល់ខ្លួនរបស់អ្នក។",
  Set_Default:"កំណត់ជាលំនាំដើម",
  Default:"លំនាំដើម",
  Remove:"យកចេញ",
  Folder:"ថតឯកសារ",
  Edit:"កែសម្រួល",
  LoadingDots:"កំពុងផ្ទុក...",
  Add_a_New_File:"បន្ថែម <a href='#' (click)='add()'>ឯកសារថ្មី</a> ទៅ",
  this_folder:"ថតឯកសារនេះ",
  FTP_Backup_Settings:"ការកំណត់ការបម្រុងទុក FTP",
  Secure_File_Transfer:"ការផ្ទេរឯកសារសុវត្ថិភាព",
  New_Holiday:"ថ្ងៃឈប់សម្រាកថ្មី",
  Add_Holiday:"បន្ថែមថ្ងៃឈប់សម្រាក",
  Holiday_Name:"ឈ្មោះថ្ងៃឈប់សម្រាក",
  Additional_Pay:"ប្រាក់បន្ថែម",
  Enable_Holiday:"បើកដំណើរការថ្ងៃឈប់សម្រាក",
  Select_Holidays:"ជ្រើសរើសថ្ងៃឈប់សម្រាក",
  No_Holidays:"គ្មានថ្ងៃឈប់សម្រាក",
  No_Holidays_Word:"បន្ថែម <a routerLink='/folder/Holiday/New'>ថ្ងៃឈប់សម្រាក</a> ដំបូង។",
  New_Import:"នាំចូលថ្មី",
  Import_Data:"នាំចូលទិន្នន័យ",
  Import_Data_Word:"ជ្រើសរើសប្រភេទឯកសារ ឬវិធីសាស្ត្រផ្ទុកឡើងដែលអ្នកជ្រើសរើស។<br /> អ្នកនឹងអាចជ្រើសរើសវាលដែលនាំចូលក្នុងឯកសារណាមួយដែលត្រូវនឹង ប៉ារ៉ាម៉ែត្រណាមួយនៅក្នុងកម្មវិធី បន្ទាប់ពីផ្ទុកឡើងឯកសារដែលគាំទ្រ។", 
  Import_File:"នាំចូលឯកសារ",
  Link_To_File:"តំណភ្ជាប់ទៅឯកសារ",
  Select_File:"ជ្រើសរើសឯកសារ",
  New_Moderator:"អ្នកសម្របសម្រួលថ្មី",
  Allow_Moderation:"អនុញ្ញាតការសម្របសម្រួល",
  Create_Paycheques:"បង្កើតការបង់ប្រាក់",
  Edit_Paycheques_and_Data:"កែសម្រួលការទូទាត់និងទិន្នន័យ",
  Destroy_Data_and_Paycheques:"បំផ្លាញទិន្នន័យនិងការទូទាត់",
  Bonus_Percentage:"ភាគរយនៃការទូទាត់",
  Fixed_Amount:"ចំនួនទឹកប្រាក់ថេរ",
  Select_Moderator:"ជ្រើសរើសអ្នកសម្របសម្រួល",
  No_Moderators:"គ្មានអ្នកសម្របសម្រួល",
  Moderators:"អ្នកសម្របសម្រួល",
  Moderator_Account:"បង្កើត <a routerLink='/folder/Administrator/New'>គណនី Moderator</a> ដំបូង។",
  No_Moderators_Word:"បន្ថែម <a routerLink='/folder/Administrator/New'>Moderator</a> ដំបូងបង្អស់។",
  Defaults:"លំនាំដើម",
  Provide_Us_Info:"ផ្តល់ឱ្យយើងនូវព័ត៌មាន",
  Setup_Your_Printer:"ដំឡើងម៉ាស៊ីនបោះពុម្ពរបស់អ្នក",
  Your_Company:"អំពីក្រុមហ៊ុនរបស់អ្នក",
  Company_Name:"ឈ្មោះ​ក្រុម​ហ៊ុន",
  Currency:"រូបិយប័ណ្ណ",
  Default_Currency:"រូបិយប័ណ្ណលំនាំដើម",
  Base_Monthly_Income:"ចំណូល​ប្រចាំខែ",
  Protection:"ការការពារ",
  App_Protection:"ការការពារកម្មវិធី",
  PIN_Code_Protection:"ការការពារលេខកូដ PIN",
  App_Protection_Word:"បើកវិធីសាស្ត្រសុវត្ថិភាពដែលជួយការពារគណនីរបស់អ្នក។",
  PIN_Code:"កូដ PIN",
  Change_PIN:"ប្តូរលេខសម្ងាត់",
  New_Password:"ពាក្យសម្ងាត់​ថ្មី",
  Geofence_Protection:"ការការពាររបងភូមិសាស្ត្រ",
  Geofence_Area:"តំបន់កំណត់",
  Distance:"ចម្ងាយ",
  Setup_Now:"ដំឡើងឥឡូវនេះ",
  Mile:"ម៉ាយ",
  Km:"គីឡូម៉ែត្រ",
  m:"m",
  Facial_Recognition:"ការទទួលស្គាល់មុខ",
  Face:"មុខ",
  Setup:"រៀបចំ",
  Label:"ស្លាក​សញ្ញា",
  Password_Protection:"ការការពារពាក្យសម្ងាត់",
  Modify_Password:"កែប្រែពាក្យសម្ងាត់",
  New_Tax_Entry:"ការបញ្ចូលពន្ធថ្មី",
  New_Tax:"ពន្ធថ្មី",
  Tax_Label:"ស្លាកពន្ធ",
  Perpetually_Enabled:"បើកដំណើរការជារៀងរហូត",
  Select_Taxes:"ជ្រើសរើសពន្ធ",
  Tax_Deductions:"ការកាត់ពន្ធ",
  No_Tax_Deductions:"គ្មានការកាត់ពន្ធ",
  No_Tax_Deductions_Word:"បន្ថែមការកាត់ <a routerLink='/folder/Tax/New'>ពន្ធ</a> ដំបូង។",
  New_Timer:"កម្មវិធីកំណត់ពេលវេលាថ្មី",
  Start:"ចាប់ផ្តើម",
  Stop:"ឈប់",
  Start_Timer:"ចាប់ផ្តើមកម្មវិធីកំណត់ម៉ោង",
  Stop_Timer:"បញ្ឈប់កម្មវិធីកំណត់ម៉ោង",
  Timer_Active:"កម្មវិធីកំណត់ម៉ោងសកម្ម",
  Timer:"កម្មវិធីកំណត់ម៉ោង៖",
  Timer_Running:"កម្មវិធីកំណត់ម៉ោង៖ (កំពុងដំណើរការ)",
  Save_Timer:"រក្សាទុកកម្មវិធីកំណត់ពេលវេលា",
  New_Timesheet:"តារាងពេលវេលាថ្មី",
  Select_Timesheets:"ជ្រើសរើសសន្លឹកពេលវេលា",
  Work_Notes:"កំណត់ចំណាំការងារ",
  Entry_Title:"ចំណងជើងចូល",
  No_Timesheets:"គ្មានសន្លឹកពេលវេលា",
  No_Timesheets_Word:"បន្ថែម <a routerLink='/folder/Timesheet/New'>សន្លឹកពេលវេលា</a> ដំបូង។",
  Timesheet_Submitted:"សន្លឹកពេលវេលាបានដាក់ស្នើ",
  Timesheet_Congrats:"សូមអបអរសាទរ! សន្លឹកពេលវេលារបស់អ្នកត្រូវបានដាក់ជូនដោយជោគជ័យ។ សូមអរគុណ!",
  Timesheet_Copy:"ដើម្បីទទួលបានច្បាប់ចម្លងនៃឯកសាររបស់អ្នក សូមផ្តល់ឱ្យយើងនូវអាសយដ្ឋានអ៊ីមែល និង/ឬលេខទូរស័ព្ទចល័តរបស់អ្នក។",
  Submit:"ដាក់ស្នើ",
  Allow_Notifications:"អនុញ្ញាតការជូនដំណឹង",
  Untitled_Entry:"ធាតុថ្មី",
  Untitled_Bank:"ធនាគារគ្មានចំណងជើង",
  Timesheet_Entry:"ការបញ្ចូលសន្លឹកពេលវេលា",
  Work_Description:"ពិ​ព៌​ណ​នា​ពី​ការងារ",
  Enable_Timesheet:"បើកដំណើរការតារាងពេលវេលា",
  Submit_Timesheet:"ដាក់ស្នើតារាងពេលវេលា",
  Vacation:"វិស្សមកាល",
  New_Vacation:"វិស្សមកាលថ្មី",
  Vacation_Name:"ឈ្មោះវិស្សមកាល",
  Paid_Vacation:"វិស្សមកាលបង់ប្រាក់",
  Vacation_Pay:"ប្រាក់ឈ្នួលវិស្សមកាល",
  Enable_Vacation:"បើកវិស្សមកាល",
  Select_Vacations:"ជ្រើសរើសវិស្សមកាល",
  No_Vacations:"គ្មានវិស្សមកាល",
  No_Vacations_Word:"បង្កើតធាតុ <a routerLink='/folder/Vacation/New'>វិស្សមកាល</a> ដំបូង។",
  Payroll_Schedule:"តារាងប្រាក់បៀវត្សរ៍",
  Next_Payroll:"ប្រាក់បៀវត្សរ៍បន្ទាប់៖",
  Upcoming_Payroll:"ប្រាក់បៀវត្សរ៍នាពេលខាងមុខ",
  No_Upcoming_Payroll:"គ្មានប្រាក់បៀវត្សរ៍នាពេលខាងមុខ",
  Address_Book:"សៀវភៅ​អាស័យ​ដ្ឋាន",
  Archived_Documents:"ឯកសារដែលបានទុកក្នុងប័ណ្ណសារ",
  Dev_Mode:"កម្មវិធីនៅក្នុងរបៀបអភិវឌ្ឍន៍",
  Administrators:"អ្នកគ្រប់គ្រង",
  Privacy:"ភាពឯកជន",
  None:"គ្មាន",
  Select_Signature:"ជ្រើសរើសហត្ថលេខា",
  No_Signatures:"គ្មានហត្ថលេខា",
  No_Signatures_Word:"បន្ថែម <a routerLink='/folder/Signature/New'>ហត្ថលេខា</a> ដំបូងបង្អស់។",
  Repeat_Indefinitely:"ធ្វើម្តងទៀតដោយគ្មានកំណត់",
  Sign:"ចុះហត្ថលេខា",
  Sign_Here:"ចុះហត្ថលេខា​ទីនេះ",
  Date_Signed:"កាលបរិច្ឆេទចុះហត្ថលេខា",
  Signature_Pad:"បន្ទះហត្ថលេខា",
  Account_Holder:"ម្ចាស់​គណនី",
  Account_Properties:"ទ្រព្យសម្បត្តិគណនី",
  Name_On_Cheque:"ឈ្មោះនៅលើឆេក",
  Server_Hostname:"ឈ្មោះម៉ាស៊ីនមេ / IP",
  Printers:"ម៉ាស៊ីនបោះពុម្ព",
  No_Printers:"គ្មានម៉ាស៊ីនបោះពុម្ព",
  Printer_Exists:'ម៉ាស៊ីនបោះពុម្ពតាមឈ្មោះនេះមានរួចហើយ។',
  No_Printers_Word:"បន្ថែម <a routerLink='/folder/Printer/New'>ម៉ាស៊ីនបោះពុម្ព</a> ដំបូងបង្អស់។",
  No_Printer_Alert:"គ្មានម៉ាស៊ីនបោះពុម្ពត្រូវបានកំណត់ទេ។ តើអ្នកចង់ដំឡើងម៉ាស៊ីនបោះពុម្ពទេ?",
  Add_Printer:"បន្ថែមម៉ាស៊ីនបោះពុម្ព",
  New_Printer:"ម៉ាស៊ីនបោះពុម្ពថ្មី",
  Printer_Hostname:"ឈ្មោះម៉ាស៊ីនបោះពុម្ព / IP",
  Printer_Label:"ស្លាកម៉ាស៊ីនបោះពុម្ព",
  Printer_Protocol:"ពិធីការម៉ាស៊ីនបោះពុម្ព",
  Protocol:"ពិធីការ",
  Email_Print:"អ៊ីមែល",
  AirPrint:"ម៉ាស៊ីនបោះពុម្ព",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"រន្ធ",
  Print_Job:"ការងារបោះពុម្ព",
  Printed:"បោះពុម្ព",
  Not_Printed:"មិនបានបោះពុម្ព",
  Print_Jobs:"ការងារបោះពុម្ព",
  Print_Queue:"ជួរបោះពុម្ព",
  No_Print_Jobs:"គ្មានការងារបោះពុម្ព",
  No_Prints:"បង្កើត <a routerLink='/folder/Cheque/New'>Cheque</a> ថ្មីមួយដើម្បីបោះពុម្ព។",
  Prints:"បោះពុម្ព",
  Find_Printer:"ស្វែងរកម៉ាស៊ីនបោះពុម្ព",
  Find_AirPrint_Devices:"ស្វែងរកឧបករណ៍ AirPrint",
  Select_Printer:"ជ្រើសរើសម៉ាស៊ីនបោះពុម្ព",
  System_UI:"UI ប្រព័ន្ធ",
  Printer:"ម៉ាស៊ីនបោះពុម្ព",
  Status:"ស្ថានភាព",
  Preview:"មើលជាមុន",
  Enable_Print_Job:"បើកដំណើរការបោះពុម្ព",
  Queue_Weight:"ទម្ងន់ជួរ",
  Unlimited:"គ្មានដែនកំណត់",
  Show_Advanced_Printer_Options:"បង្ហាញជម្រើសម៉ាស៊ីនបោះពុម្ពកម្រិតខ្ពស់",
  Advanced:"កម្រិតខ្ពស់",
  Location:"ទីតាំង",
  Note:"ចំណាំ",
  Queue_Name:"ឈ្មោះជួរ",
  Pages_Printed_Limit:"ទំព័រដែលបានបោះពុម្ពដែនកំណត់",
  MultiPage_Idle_Time:"ពេលវេលារង់ចាំច្រើន",
  Page_Count_Limit:"ចំនួនទំព័រកំណត់",
  Page_Orientation:"ការតំរង់ទិសទំព័រ",
  Portrait:"រូបបញ្ឈរ",
  Landscape:"ទេសភាព",
  Duplex:"ពីរជាន់",
  Double_Sided_Printing:"ទ្វេភាគី",
  Duplex_Mode:"របៀបទ្វេ",
  Duplex_Short:"ខ្លី",
  Duplex_Long:"វែង",
  Duplex_None:"គ្មាន",
  Color_And_Quality:"ពណ៌និងគុណភាព",
  Monochrome:"ម៉ូណូក្រូម",
  Photo_Quality_Prints:"ការបោះពុម្ពគុណភាពរូបថត",
  Printer_Email:"អ៊ីមែលម៉ាស៊ីនបោះពុម្ព",
  Automatically_Downsize:"បន្ថយទំហំដោយស្វ័យប្រវត្តិ",
  Paper:"ក្រដាស",
  Paper_Name:"ឈ្មោះក្រដាស",
  Paper_Width:"ទទឹងក្រដាស",
  Paper_Height:"កម្ពស់ក្រដាស",
  Paper_Autocut_Length:"ប្រវែងកាត់ក្រដាសដោយស្វ័យប្រវត្តិ",
  Margins:"រឹម",
  Page_Margins:"រឹមទំព័រ",
  Page_Margin_Top:"រឹមទំព័រកំពូល",
  Page_Margin_Right:"រឹមទំព័រស្តាំ",
  Page_Margin_Bottom:"រឹមទំព័រខាងក្រោម",
  Page_Margin_Left:"រឹមទំព័រខាងឆ្វេង",
  Add_Employees:"បន្ថែមបុគ្គលិក",
  Header:"ក្បាល",
  Print_A_Page_Header:"បោះពុម្ពបឋមកថាទំព័រ",
  Header_Label:"ស្លាកក្បាល",
  Header_Page_Index:"សន្ទស្សន៍ទំព័រក្បាល",
  Header_Font_Name:"ពុម្ពអក្សរបឋមកថា",
  Select_Font:"ជ្រើសរើសពុម្ពអក្សរ",
  Font_Selector:"ជម្រើសពុម្ពអក្សរ",
  Header_Font_Size:"ពុម្ពអក្សរបឋមកថា",
  Header_Bold:"ក្បាលដិត",
  Header_Italic:"ក្បាលទ្រេត",
  Header_Alignment:"តម្រឹមក្បាល",
  Left:"ឆ្វេង",
  Center:"មជ្ឈមណ្ឌល",
  Right:"ត្រូវ",
  Justified:"សមហេតុផល",
  Header_Font_Color:"ពណ៌ក្បាល",
  Select_Color:"ជ្រើសរើសពណ៌",
  Footer:"បាតកថា",
  Print_A_Page_Footer:"បោះពុម្ពបាតកថាទំព័រ",
  Footer_Label:"ស្លាកបាតកថា",
  Footer_Page_Index:"សន្ទស្សន៍ទំព័របាតកថា",
  Footer_Font_Name:"ពុម្ពអក្សរបាតកថា",
  Fonts:"ពុម្ពអក្សរ",
  Done:"រួចរាល់",
  Select_Fonts:"ជ្រើសរើសពុម្ពអក្សរ",
  Footer_Font_Size:"ទំហំបាតកថា",
  Footer_Bold:"បាតកថាដិត",
  Footer_Italic:"បាតកថាទ្រេត",
  Footer_Alignment:"តម្រឹមបាតកថា",
  Footer_Font_Color:"ពណ៌បាតកថា",
  Page_Copies:"ច្បាប់ចម្លងទំព័រ",
  Enable_Printer:"បើកម៉ាស៊ីនបោះពុម្ព",
  Remote_Logging:"ការកត់ត្រាពីចម្ងាយ",
  Log_Server:"ម៉ាស៊ីនមេកំណត់ហេតុ",
  Encryption:"ការអ៊ិនគ្រីប",
  Random_Key:"គ្រាប់ចុចចៃដន្យ",
  Encryption_Key:"កូនសោអ៊ិនគ្រីប",
  Cheques_Webserver:"មូលដ្ឋានទិន្នន័យផ្ទាល់ខ្លួន",
  Learn_How:"រៀនពីរបៀប",
  Signature:"ហត្ថលេខា",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"មើលហត្ថលេខា",
  Printed_Signature:"ហត្ថលេខាបោះពុម្ព",
  Digitally_Sign:"សញ្ញាឌីជីថល",
  Digital_Signature:"ហត្ថលេខាឌីជីថល",
  Add_Signature:"បន្ថែមហត្ថលេខា",
  Add_Your_Signature:"បន្ថែមហត្ថលេខារបស់អ្នក",
  Enable_Signature:"បើកហត្ថលេខា",
  Digitally_Signed:"ចុះហត្ថលេខាដោយឌីជីថល",
  Insert_Error:"មិនអាចរក្សាទុកការត្រួតពិនិត្យបានទេ ដោយសារបញ្ហាមូលដ្ឋានទិន្នន័យ។",
  Delete_Confirmation:"តើអ្នកប្រាកដថាចង់លុបព័ត៌មាននេះមែនទេ?",
  Discard_Confirmation:"តើអ្នកប្រាកដថាចង់បោះបង់ព័ត៌មាននេះមែនទេ?",
  Discard_Bank_Confirmation:"តើអ្នកប្រាកដថាចង់បោះបង់គណនីនេះមែនទេ?",
  Discard_Printer_Confirmation:"តើអ្នកពិតជាចង់បោះបង់ម៉ាស៊ីនបោះពុម្ពនេះមែនទេ?",
  Delete_Bonus_Confirmation:"តើអ្នកប្រាកដថាចង់លុបប្រាក់រង្វាន់នេះមែនទេ?",
  Delete_Invoice_Confirmation:"តើអ្នកប្រាកដថាចង់លុបវិក្កយបត្រនេះមែនទេ?",
  Generated_Cheque:"ការត្រួតពិនិត្យដែលបានបង្កើត",
  Generated_Invoice:"វិក្កយបត្រដែលបានបង្កើត",
  Schedule_Start_Time:"ចាប់ផ្តើមកាលវិភាគ",
  Schedule_End_Time:"កាលវិភាគបញ្ចប់",
  New_Call:"ការហៅថ្មី",
  No_Contacts:"គ្មានទំនាក់ទំនង",
  No_Contacts_Word:"អ្នកគ្រប់គ្រង អ្នកសម្របសម្រួល និយោជិត និងអ្នកទទួលប្រាក់លេចឡើងជាទំនាក់ទំនង។",
  PDF_Subject:"ហិរញ្ញវត្ថុ",
  PDF_Keywords:"បញ្ជីបើកប្រាក់បៀវត្សរ៍ PDF checkcheck",
  Printer_Setup:"ការដំឡើងម៉ាស៊ីនបោះពុម្ព",
  Printer_Selection:"ការជ្រើសរើសម៉ាស៊ីនបោះពុម្ព",
  New_Fax:"ទូរសារថ្មី",
  New_Fax_Message:"សារទូរសារថ្មី",
  Fax_Machine:"ម៉ា​សី​ុ​ន​ទូរលេខ",
  Fax_Name:"ឈ្មោះទូរសារ",
  Fax_Email:"ទូរសារអ៊ីមែល",
  Fax_Number:"លេខទូរសារ",
  Contents:"មាតិកា",
  Fax_Body:"តួទំព័រ",
  Header_Word:"ក្បាល៖",
  Header_Text:"អត្ថបទបឋមកថា",
  Include_Header:"រួមបញ្ចូលបឋមកថា",
  Include_Footer:"រួមបញ្ចូលបាតកថា",
  Footer_Word:"បាតកថា:",
  Footer_Text:"អត្ថបទបាតកថា",
  Attach_a_Cheque:"ភ្ជាប់​មូលប្បទានប័ត្រ",
  Add_Deduction:"បន្ថែមការកាត់កង",
  Enable_Fax:"ផ្ញើទូរសារ",
  Select_Fax:"ជ្រើសរើសទូរសារ",
  No_Faxes:"គ្មានទូរសារ",
  Faxes:"ទូរសារ",
  Save_and_Send:"ផ្ញើទូរសារ",
  Save_and_Pay:"រក្សាទុកនិងបង់",
  WARNING:"ព្រមាន៖",
  Remember:"ចងចាំ",
  Printing:"បោះពុម្ព",
  Printing_Complete:"ការបោះពុម្ពបានបញ្ចប់!\n\n",
  of:"នៃ",
  There_Were:"មាន ",
  Successful_Prints:"ការបោះពុម្ពជោគជ័យ និង",
  Unsuccessful_Prints:"ការបោះពុម្ពមិនជោគជ័យ។",
  PrinterError:"សូមអភ័យទោស! មានកំហុស។",
  Printing_:"បោះពុម្ព ... ",
  PrinterSuccess:"ឯកសារត្រូវបានបោះពុម្ពដោយជោគជ័យ។",
  PrintersSuccess:"ឯកសារត្រូវបានបោះពុម្ពដោយជោគជ័យ។",
  Print_Cheques:"ពិនិត្យការបោះពុម្ព",
  New_Message:"សារថ្មី",
  New_Messages:"សារថ្មី",
  Read_Message:"អានសារ",
  Write_Message:"សរសេរសារ",
  Send_Message:"ផ្ញើ​សារ",
  Subject:"ប្រធានបទ",
  Message:"សារ",
  Writing:"សរសេរ ... ",
  Send:"ផ្ញើ",
  Set_Date:"កំណត់កាលបរិច្ឆេទ",
  Set_Time:"កំណត់ពេលវេលា",
  Recieve:"ទទួល",
  Set_as_Default:"កំណត់ជាលំនាំដើម",
  Default_Account:"គណនីលំនាំដើម",
  Default_Design:"ការរចនាលំនាំដើម",
  Multiple_Cheques:"ពិនិត្យ (បីដង)",
  Account_Mode:"របៀបគណនី",
  Multiple_Cheques_Description:"ពិនិត្យបីក្នុងមួយទំព័រ។",
  Check_and_Table:"ពិនិត្យ និងតារាង",
  Check_including_Table:"តារាងពិនិត្យ និងគណនេយ្យ",
  Check_Mailer:"ពិនិត្យសំបុត្រ",
  Check_Mailer_Window:"ពិនិត្យជាមួយបង្អួចអាសយដ្ឋាន។",
  DocuGard_Table_Top:"DocuGard Check & Table (កំពូល)",
  DocuGard_Table_Middle:"DocuGard Check & Table (កណ្តាល)",
  DocuGard_Table_Bottom:"DocuGard Check & Table (បាត)",
  DocuGard_Mailer_Top:"DocuGard Check Mailer (កំពូល)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (កណ្តាល)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mailer (ខាងក្រោម)",
  DocuGard_Three_Cheques:"ការត្រួតពិនិត្យ DocuGard (បីដង)",
  DocuGard_Cheque_Top:"DocuGard Check (កំពូល)",
  DocuGard_Cheque_Middle:"DocuGard Check (កណ្តាល)",
  DocuGard_Cheque_Bottom:"ពិនិត្យ DocuGard (បាត)",
  DocuGard_Three_Cheques_Window:"ពិនិត្យបីនៅលើទំព័រមួយ។",
  DocuGard_Table_Top_Window:"តារាងពិនិត្យ និងប្រាក់ចំណូល",
  DocuGard_Table_Middle_Window:"តារាងពិនិត្យ និងប្រាក់ចំណូល",
  DocuGard_Table_Bottom_Window:"តារាងពិនិត្យ និងប្រាក់ចំណូល",
  DocuGard_Mailer_Top_Window:"ពិនិត្យ តារាង និងអាសយដ្ឋាន។",
  DocuGard_Mailer_Middle_Window:"ពិនិត្យ តារាង និងអាសយដ្ឋាន។",
  DocuGard_Mailer_Bottom_Window:"ពិនិត្យ តារាង និងអាសយដ្ឋាន។",
  DocuGard_Cheque_Top_Window:"ពិនិត្យមើលក្រដាសសុវត្ថិភាព។",
  DocuGard_Cheque_Middle_Window:"ពិនិត្យមើលក្រដាសសុវត្ថិភាព។",
  DocuGard_Cheque_Bottom_Window:"ពិនិត្យមើលក្រដាសសុវត្ថិភាព។",
  Basic_Cheque:"ពិនិត្យ (កំពូល)",
  Basic_Cheque_Print:"បោះពុម្ពចេញមូលប្បទានប័ត្រតែមួយ",
  Error_Setting_As_Paid:"កំហុសក្នុងការកំណត់ថាបានបង់",
  Add_Attachment:"បន្ថែមឯកសារភ្ជាប់",
  PrinterUnavailable:"ម៉ាស៊ីនបោះពុម្ពមិនអាចប្រើបាន",
  CreditCardComponent:"កាត",
  PaypalComponent:"PayPal",
  InteracComponent:"អន្តរកម្ម",
  BitcoinComponent:"BitCoin",
  New_Deposit:"ប្រាក់បញ្ញើថ្មី",
  Deposits:"ប្រាក់បញ្ញើ",
  No_Deposits:"គ្មានប្រាក់កក់",
  Credit_Card_Deposit:"កាត​ឥណទាន",
  New_Credit_Card_Deposit_Message:"ប្រាក់បញ្ញើប័ណ្ណឥណទាន",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"ការដាក់ប្រាក់ BitCoin",
  New_Interac_Deposit:"អន្តរកម្ម",
  New_Interac_Deposit_Message:"ការផ្ទេរអន្តរកម្ម",
  Payments_Limit:"ដែនកំណត់ការទូទាត់",
  No_Payment_Limit:"គ្មានដែនកំណត់ការទូទាត់",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"ប្រាក់បញ្ញើ PayPal",
  No_Deposits_Word:"បន្ថែមក្រុមហ៊ុនដំបូង <a routerLink='/folder/Deposit/New'>ដាក់ប្រាក់</a>",
  No_Documents_Specified:"គ្មានឯកសារណាមួយត្រូវបានបញ្ជាក់សម្រាប់ការបោះពុម្ព",
  No_Printers_Added:"រកមិនឃើញម៉ាស៊ីនបោះពុម្ពទេ។ ចូលទៅកាន់ការកំណត់ > ម៉ាស៊ីនបោះពុម្ព ដើម្បីបន្ថែមម៉ាស៊ីនបោះពុម្ពមួយ។",
  DB_Erase_Prompt:"លុបមូលដ្ឋានទិន្នន័យទាំងស្រុងឬ? ការព្រមាន៖ អ្នកនឹងបាត់បង់ព័ត៌មានដែលបានរក្សាទុកទាំងអស់!",
  Console_Warning:"កុំបិទភ្ជាប់អត្ថបទណាមួយទៅក្នុងកុងសូលនេះ។ អ្នកអាចធ្វើឱ្យខ្លួនអ្នក និង/ឬក្រុមហ៊ុនរបស់អ្នកមានហានិភ័យធ្ងន់ធ្ងរ។",
  No_Faxes_Word:"បង្កើត <a routerLink='/folder/Fax/New'>Fax</a> ដំបូង។",
  Cheque_Delete_Confirmation:"តើអ្នកប្រាកដថាចង់លុបការត្រួតពិនិត្យនេះមែនទេ?",
  Design_Delete_Confirmation:"តើអ្នកប្រាកដថាចង់លុបការរចនានេះមែនទេ?",
  Cheque_Pay_Confirmation:"សម្គាល់មូលប្បទានប័ត្រនេះថាបានបង់? វានឹងមិនបង្ហាញក្នុងជួរបោះពុម្ពទេ។",
  Payment_Pay_Confirmation:"សម្គាល់ការបង់ប្រាក់នេះថាបានបង់មែនទេ? វានឹងមិនបង្ហាញនៅក្នុងជួរពិនិត្យនោះទេ។",
  Delete_Deduction_Confirmation:"តើអ្នកប្រាកដថាចង់លុបការកាត់នេះមែនទេ?",
  Delete_Job_Confirmation:"តើអ្នកប្រាកដថាចង់លុបការងារនេះមែនទេ?",
  Delete_Timesheet_Confirmation:"តើអ្នកប្រាកដថាចង់លុបតារាងពេលវេលានេះមែនទេ?",
  Delete_Schedule_Confirmation:"តើអ្នកប្រាកដថាចង់លុបកាលវិភាគនេះមែនទេ?",
  Delete_Setting_Confirmation:"តើអ្នកប្រាកដថាចង់កំណត់ការកំណត់នេះឡើងវិញទេ?",
  Delete_Fax_Confirmation:"តើអ្នកប្រាកដថាចង់លុបទូរសារនេះមែនទេ?",
  Delete_File_Confirmation:"តើអ្នកប្រាកដថាចង់លុបឯកសារនេះមែនទេ?",
  Delete_Vacation_Confirmation:"តើអ្នកប្រាកដថាចង់លុបវិស្សមកាលនេះមែនទេ?",
  Delete_Printer_Confirmation:"តើអ្នកប្រាកដថាចង់លុបម៉ាស៊ីនបោះពុម្ពនេះមែនទេ?",
  Remove_Design_Confirmation:"តើអ្នកប្រាកដថាចង់លុបការរចនានេះមែនទេ?",
  Delete_Payroll_Confirmation:"តើអ្នកប្រាកដថាចង់លុបបញ្ជីប្រាក់ខែនេះមែនទេ?",
  Send_Fax_Email_Confirmation:"តើអ្នកចង់ទូរសារ និងផ្ញើអ៊ីមែលឯកសារទាំងនេះទេ?",
  Send_Email_Confirmation:"តើអ្នកចង់ផ្ញើអ៊ីមែលឯកសារនេះទេ?",
  Send_Fax_Confirmation:"តើអ្នកចង់ទូរសារឯកសារនេះទេ?",
  Error_Generating_PDF:"សូមអភ័យទោស។ មានកំហុសក្នុងការបង្កើតឯកសារនេះ។",
  PDF_Error_Saving_Image:"សូមអភ័យទោស។ មានកំហុសក្នុងការរក្សាទុករូបភាព PDF នៃឯកសារនេះ។",
  Test_Printer_Confirmation:"តើអ្នកចង់បោះពុម្ពទំព័រសាកល្បងនៅលើម៉ាស៊ីនបោះពុម្ពនេះទេ?",
  Save_Timesheet_Prompt:"សូមបន្ថែម 'ចំណងជើង' ឬចុច 'ចាប់ផ្តើមកម្មវិធីកំណត់ពេល' ដើម្បីរក្សាទុក។",
  Remove_Geofence_Prompt:"តើ​អ្នក​ប្រាកដ​ជា​ចង់​ដក​ទីតាំង​នៃ​របង​ភូមិសាស្ត្រ​នេះ​ចេញ?",
  Delete_Employee_Confirmation:"តើអ្នកប្រាកដថាចង់លុប",
  Fire_Employee_Confirmation:"តើអ្នកប្រាកដជាចង់បាញ់"
}