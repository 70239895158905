export const Nb = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Copyright &copy; 2023",
  black:"Svart",
  green:"Grønn",
  gold:"Gull",
  blue:"Blå",
  brown:"Brun",
  purple:"Lilla",
  pink:"Rosa",
  red:"Rød",
  Swatches:"အကွက်များ",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"အသွေး",
  Saturation:"ရွှဲ",
  Lightness:"ပေါ့ပါးမှု",
  Upgrade_To_Pro:"Oppgrader til Pro",
  AllFeaturesInclude:"Alle funksjoner inkluderer:",
  PrintUnlimitedCheques:"Skriv ut ubegrensede sjekker",
  PremiumChequeDesigns:"Premium sjekkdesign",
  ManageUnlimitedEmployees:"Administrer ubegrenset antall ansatte",
  AddUnlimitedPayees:"Legg til ubegrensede mottakere",
  CreateUnlimitedPayrolls:"Opprett ubegrensede lønnslister",
  UnlimitedSchedulesandTimeSheets:"Ubegrenset tidsplaner og timelister",
  BulkPayPalPayouts:"Bulk PayPal-utbetalinger",
  UnlimitedBankAccounts:"Ubegrenset bankkontoer",
  ManageMultipleCompanies:"Administrer flere selskaper",
  TrackInsurancePolicies:"Spor forsikringspoliser",
  Bio_MetricProtection:"Biometrisk beskyttelse",
  Geo_FenceLock_OutProtection:"Geo-gjerde lock-out beskyttelse",
  Multiple_Companies_Word:"Å administrere flere selskaper er ikke tilgjengelig uten Checks-premie.",
  PayPal_Payouts_Word:"PayPal-betalinger er deaktivert uten Checks-premie.",
  PINProtection:"PIN-beskyttelse",
  PasswordProtection:"Passordbeskyttelse",
  May_Require_Approval:"Kan kreve godkjenning.",
  Subscribe:"Abonnere",
  Billed:"Fakturert",
  Up:"Opp",
  Down:"Ned",
  Positioning:"Posisjonering",
  Marker:"Markør",
  Drag_Marker:"Dra markør",
  Tagline:"Skriv ut sjekker og tabuler lønn",
  Marker_Word:"Bruk markørene til å dimensjonere elementet.",
  Pinch_Zoom:"Knip zoom",
  Pinch_Word:"Knip for å zoome elementet.",
  Drag:"Dra",
  Drag_Word:"Bruk fingeren til å dra elementer.",
  subscription_alias_word:"Automatisk fornyelse av abonnement",
  premium_alias_word:"Engangsoppgraderingspakke",
  print_alias_word:"Skriv ut individuelle sjekker",
  mode_alias_word:"Modus",
  Pro:"Pro",
  Pro_word:"Pro-versjon kreves.",
  Cant_Delete_Default_Company_Word:"Beklager, du kan ikke slette standardselskapet ditt.",
  Reinsert_Default_Designs:"Sett inn standarddesign på nytt",
  Reinsert_Default_Designs_word:"Vil du sette inn standarddesign på nytt?",
  Subscription_Active_Disable_Word:"Dette abonnementet er aktivt. Vil du kansellere dette abonnementet på Cheques?",
  Company_Logo:"Firmalogo",
  ZERO_:"NULL",
  Disclaimer:"Ansvarsfraskrivelse",
  Privacy_Policy:"Personvernerklæring",
  EULA:"Sjekker EULA",
  Terms_Of_Service:"Vilkår for bruk",
  Terms_Of_Use:"Vilkår for bruk",
  Refunds:"Angrerett",
  Single_Print:"1 Sjekk",
  Two_Prints:"2 sjekker",
  Five_Prints:"5 sjekker",
  Ten_Prints:"10 sjekker",
  Fifteen_Prints:"15 sjekker",
  Twenty_Prints:"20 sjekker",
  Thirty_Prints:"30 sjekker",
  Image_Added:"Bilde lagt til",
  Image_Preview:"Forhåndsvisning av bilde",
  No_Image_Was_Selected:"Ingen bilder ble valgt.",
  Cheques_Unlimited:"Sjekker ubegrenset",
  _Subscription:"Abonnement",
  Subscription:"Sjekker - 1 måned",
  Two_Month_Subscription:"Sjekker - 2 måneder",
  Three_Month_Subscription:"Sjekker - 3 måneder",
  Six_Month_Subscription:"Sjekker - 6 måneder",
  Twelve_Month_Subscription:"Sjekker - 12 måneder",
  Cheques_Are_Available:"Sjekker er tilgjengelige for utskrift.",
  Cheques_Thank_You:"Tusen takk for kjøpet av sjekker! Kjøpene dine er nå aktive.",
  Upgrade_Required_Two:"Velg en pakke og dobbelttrykk på prisknappen for å starte kjøpet. Skriv ut profesjonelt utseende fullfarge sjekker på sekunder.",
  Month:"Måned",
  Due:"Forfaller:",
  Expires:"Utløper:",
  Subscription_Active:"Abonnement aktivt",
  Subscription_Inactive:"Abonnement inaktivt",
  Purchase_Additional_Cheques:"Kjøpe ekstra sjekker?",
  Printable_Cheque:"Utskrivbar sjekk",
  Printable_Cheques:"Utskrivbare sjekker",
  Printable_Cheque_Word:"sjekk er tilgjengelig på kontoen din.",
  Printable_Cheques_Word:"sjekker er tilgjengelige på kontoen din.",
  Max_Amount_Message:"Beløpet du har spesifisert har nådd over maksimumsbeløpet som er angitt for dette systemet:",
  Terms_Required_Word:"Du må godta denne avtalen før du fortsetter å bruke sjekker.",
  Subscriptions:"Abonnementer",
  Product_Upgrades:"Oppgraderinger",
  Mailed_Out_Cheques:"Utsendte sjekker",
  Enter_A_Company_Name:"Vennligst skriv inn et firmanavn.",
  Single_Cheques:"Enkeltsjekker",
  Cheque_Golden:"Gylden sjekk",
  Cheque_Golden_Window:"Gylden sjekk design.",
  Cheque_Green:"Grønn sjekk",
  Cheque_Green_Window:"Grønn rutete design.",
  Cheque_Red:"Rød sjekk",
  Cheque_Red_Window:"Rød rutete design.",
  Cheque_Yellow:"Gul sjekk",
  Cheque_Yellow_Window:"Gul sjekkedesign.",
  Cheque_Statue_of_Liberty:"Frihetsgudinnen",
  Cheque_Statue_of_Liberty_Window:"Frihetsgudinnen sjekk design.",
  Cheque_Green_Wave:"Grønn bølge",
  Cheque_Green_Wave_Window:"Grønn rutete design.",
  Cheque_Golden_Weave:"Gylden vev",
  Cheque_Golden_Weave_Window:"Elegant gullrutedesign.",
  Cheque_Green_Sun:"Grønn sol",
  Cheque_Green_Sun_Window:"Dyp og beroligende sjekkdesign.",
  Cheque_Blue_Checkers:"Blå dam",
  Cheque_Blue_Checkers_Window:"Blå rutete design.",
  Cashiers_Check:"Kassesjekk",
  Cashiers_Check_Window:"Kvittering stilmal.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Aqua sjekk design.",
  Cheque_Golden_Checkers:"Golden Checkers",
  Cheque_Golden_Checkers_Window:"Gylden sjekk design.",
  Upgrade_Unavailable:"Oppgraderinger er ikke tilgjengelig",
  Bank_Code_Protection:"Banknummerbeskyttelse",
  Bank_Code_Protection_Word:"Beskytt banknumrene dine fra å bli brukt på denne appen som kjører på en annen enhet eller for en annen bruker. Denne handlingen er IRREVERSIBIL. Fortsette?",
  Bank_Code_Protection_Blocked_Word:"Banknumrene du prøver å bruke er reservert for en annen bruker eller enhet.",
  Bank_Code_Protection_Error_Word:"Nummerbekreftelse mislyktes. Koble til internett og prøv å legge til denne bankkontoen på nytt.",
  Bank_Code_Protection_Set_Error_Word:"Banknummerbeskyttelse krever at du er koblet til internett. Koble til og prøv igjen.",
  Upgrade_Unavailable_Word:"Beklager, vi har problemer med å bekrefte deg. Abonnementer og oppgraderinger til Checks er for øyeblikket utilgjengelige for kjøp i ditt område.",
  PayPal_Payment_Preview:"PayPal-betalingsforhåndsvisning",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Velg PayPal",
  PayPal_Applications:"PayPal-applikasjoner",
  Purchase_With_Apple_Pay:"Kjøp med Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Bedrifter",
  Insurance:"Forsikring",
  New_PayPal:"Ny PayPal",
  Pay_By:"Betal med",
  Insure:"Forsikre",
  Miles:"Miles",
  Payment_Method:"Betalingsmetode",
  Select_Policies:"Velg Retningslinjer",
  Policies:"Retningslinjer",
  Policy:"Politikk",
  No_PayPal_Account:"Ingen PayPal-konto",
  No_Policies:"Ingen forsikringspoliser",
  New_Policy:"Ny politikk",
  PayPal_Payment:"PayPal-betaling",
  PayPal_Payments:"PayPal-betalinger",
  No_Payment_Selected:"Ingen betaling valgt",
  Sending_Payment_Word:"Vennligst vent... Denne betalingen sendes.",
  Sending_Payments_Word:"Vennligst vent... Betalinger blir sendt.",
  No_Payment_Selected_PayPal:"Ingen <a routerLink='/folder/Payments'>PayPal-betaling</a> valgt å sende.",
  Payment_Sent:"Betaling sendt",
  PayPal_Payment_Sent:"Denne betalingen er sendt med PayPal.",
  Copay:"Copay",
  Dead:"Død",
  Alive:"I live",
  Not_Dead:"Ikke død",
  Unclaimed:"Ikke gjort krav på",
  Attempted:"Forsøkte",
  Deceased:"Avdød",
  Claimed:"Hevdet",
  Unpaid:"Ubetalt",
  Sending_Payment:"Sender betaling",
  Sending_Payments:"Sender betalinger",
  Send_PayPal_Confirmation:"Vil du sende denne transaksjonen med PayPal?",
  Send_PayPal_Confirmation_Word:"Trykk på den grønne knappen for å sende denne transaksjonen.",
  Save_Payment_As_Unpaid:"Vil du lagre denne betalingen som ubetalt?",
  Payment_Pay_Confirmation_PayPal:"Lagre denne betalingen som betalt?",
  No_Policies_Word:"Legg til den første <a routerLink='/folder/Postage/New'>forsikringspolisen</a> nå.",
  Timesheet_Multiple_Delete_Confirmation:"Er du sikker på at du vil slette flere timelister?",
  Select_Multiple_Timesheets_Prompt:"Ingen timelister er valgt. Velg minst én timeliste.",
  Select_Multiple_Policies_Prompt:"Ingen retningslinjer er valgt. Velg minst én forsikring.",
  Policies_Multiple_Delete_Confirmation:"Er du sikker på at du vil slette flere retningslinjer?",
  Company:"Selskap",
  Add_Company:"Legg til selskap",
  New_Company:"Legg til selskap",
  No_Companies:"Ingen selskaper",
  Enable_Company:"Aktiver selskapet",
  Select_Company:"Velg Firma",
  The_Default_Company:"Standard firmaetikett.",
  Manage_Companies:"Administrer selskaper",
  No_Companies_Word:"Sjekker vil bruke et standardselskap.<br />Legg til <a routerLink='/folder/Company/New'>første firma</a>.",
  Default_Company:"Standard selskap",
  Cheques_Unlimited_Word:"Med Checks Unlimited kan du skrive ut så mange sjekker du vil.",
  Cheques_Subscription_Word:"Et Checks-abonnement lar deg skrive ut så mange sjekker du vil.",
  You_Own_This_Product:"Du eier dette produktet.",
  Your_Subscription_is_Active:"Abonnementet ditt er aktivt.",
  Active_Products:"Aktiverte produkter",
  Purchase_Confirmation:"Kjøp",
  Purchase_Cheques:"Kjøpssjekker",
  Restore_Purchase:"Gjenopprette kjøp",
  Erase_Purchase:"Slett kjøp",
  Successfully_Purchased:"Vellykket kjøpt",
  Enter_Your_Licence_Key:"Vennligst skriv inn din lisensnøkkel på denne siden for å aktivere dette produktet.",
  Licence_Key:"Lisensnøkkel",
  Enter_Licence_Key:"Skriv inn lisensnøkkel",
  Purchased:"Kjøpt",
  Enable_Feature:"Aktiver funksjon",
  Cancel_Subscription:"Avbestille abonnementet",
  Subscription_Removed:"Abonnement fjernet",
  Select_Active_Subscription:"Velg et aktivt abonnement for å endre det.",
  Remove_Subscription_Word:"Er du sikker på at du vil kansellere dette abonnementet?",
  Delete_Company_Confirmation:"Er du sikker på at du vil slette hele dette selskapet? ADVARSEL: Du vil miste all lagret informasjon!",
  Delete_Default_Company_Word:"Du kan ikke slette standardselskapet. Vil du tilbakestille applikasjonen og gjenopprette den til standardtilstanden? ADVARSEL: Du vil miste all lagret informasjon!",
  Console_Warning_2:"Ikke håndter noen valuta ved å bruke denne applikasjonen som for øyeblikket ikke er din.",
  Terms_and_Conditions:"Vilkår og betingelser",
  and_the:"og",
  for:"til",
  Please_Read_Word:"Vennligst les og godta",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Noen valutaomregningskurser ble ikke funnet. Koble til Internett.",
  Free:"Gratis",
  DB_Erase_Prompt_2:"Vil du slette hele utviklerdatabasen? ADVARSEL: Du vil miste all informasjon om KJØP og ABONNEMENT!",
  Successfully_Imported:"Vellykket importert",
  Select_Postage:"Velg Porto",
  No_Postage:"Ingen frimerker",
  No_Paid_Postage_Word:"Legg til det første <a routerLink='/folder/Postage/New'>Betalt porto</a>-stempel.",
  Trial_Complete:"Prøve fullført",
  Please_Upgrade:"Vennligst oppgrader Checks for å fortsette utskriften.",
  Aa:"Aa",
  Color:"Farge",
  Font:"Font",
  Guide:"Guide",
  Guides:"Guider",
  Image:"Bilde",
  Zoom:"Zoom",
  Logo:"Logo",
  Bank:"Bank",
  MICR:"MICR",
  Total:"Total",
  Cancel:"Avbryt",
  Confirm:"Bekrefte",
  Method:"Metode",
  Biometric_Security:"Biometrisk sikkerhet",
  Please_Login_To_Continue:"Vennligst Logg inn for å fortsette.",
  Complete:"Fullstendig",
  Sign_Up:"Melde deg på",
  Error:"Feil",
  Biometrics:"Biometri",
  Percent:"Prosent",
  Zero_Percent:"0 %",
  Top_Margin:"Toppmargin",
  Bottom_Margin:"Bunnmargin",
  Left_Margin:"Venstre marg",
  Right_Margin:"Høyre marg",
  MICR_Margin:"MICR Margin",
  Table_Margin:"Tabellmargin",
  Address_Margin:"Adressemargin",
  Percentage_:"Prosentdel",
  Vacation_Title:"Ferietittel",
  Use_PIN:"Bruk PIN",
  Loading__:"Laster inn...",
  Design_Title:"Designtittel",
  Authorize:"Autorisere",
  Key:"Nøkkel",
  Public_Key:"Offentlig nøkkel",
  Private_Key:"Privat nøkkel",
  Authenticate:"Autentiser",
  Last_Payroll:"Siste Lønn",
  Last_Calculation:"Siste beregning",
  Authorized:"Autorisert",
  Geo_Authorized:"Geografisk plassering: Autorisert",
  Not_Authorized:"Ikke autorisert",
  Authorization_Complete:"Autorisasjon fullført",
  Geolocation_Authorization:"Autorisasjon for geolokalisering",
  Out_of_Bounds:"Utenfor grensene",
  Cant_Delete_Default_Design:"Kan ikke slette et standarddesign.",
  Unauthorized_Out_of_Bounds:"Uautorisert: Utenfor grensene",
  Biometric_Authorization:"Biometrisk autorisasjon",
  Locating_Please_Wait:"Finner, vennligst vent...",
  Test_Biometrics:"Test biometri",
  Cheque_Margins:"Sjekk marginer",
  Percentage_Full_Error:"Prosentfeltet kan ikke settes over 100 % prosent.",
  No_Payroll_Text:"Legg til en <a routerLink='/folder/Employee/New'>Ansatt</a> eller <a routerLink='/folder/Payee/New'>Betalingsmottaker</a> og en <a routerLink='/folder/Schedule/New'>Tidsplan</a>.",
  Design_Saved:"Design lagret",
  Default_Design_Selected:"Standard design valgt",
  Partial_Import:"Delvis import",
  Partial_Export:"Delvis eksport",
  Entire_Import:"Hele importen",
  Entire_Export:"Hele eksporten",
  Existing_Password:"Vennligst skriv inn ditt eksisterende passord:",
  Existing_PIN:"Vennligst skriv inn din eksisterende PIN-kode:",
  Pin_Change_Header:"PIN-bekreftelse",
  Pin_Change_SubHeader:"Skriv inn din gamle PIN-kode for å bekrefte endringen.",
  Pass_Change_Header:"Passord bekreftelse",
  Pass_Change_SubHeader:"Skriv inn ditt gamle passord for å bekrefte endringen.",
  PIN_Enter_Message:"Skriv inn PIN-koden for å bekrefte.",
  Password_Enter_Message:"Skriv inn passordet ditt for å bekrefte.",
  Pin_Updated_Success:"PIN-koden ble oppdatert!",
  Pin_Updated_Fail:"PIN-koden kunne ikke oppdateres.",
  Pass_Updated_Success:"Passordet ble oppdatert.",
  Pass_Updated_Fail:"Passordet kunne ikke oppdateres.",
  Preview_Payment:"Forhåndsvis betaling",
  Preview_Payroll:"Forhåndsvisning av lønn",
  No_Payments_Created:"Det ble ikke funnet noen betalinger som ble opprettet.",
  Payment_Preview:"Forhåndsvisning av betaling",
  Enable_Payee:"Aktiver betalingsmottaker",
  Rendered:"Gjengitt",
  No_Email:"Ingen e-post",
  Setup_Cheques:"Oppsettsjekker",
  name:"Navn",
  address:"Adresse",
  address_2:"Adresselinje 2",
  city:"By",
  province:"Provins",
  postal_code:"Postnummer",
  country:"Land",
  username:"Brukernavn",
  full_name:"Fullt navn",
  birthday:"Fødselsdag",
  email:"E-post",
  phone:"Telefon",
  employees:"Ansatte",
  addresses:"Adresser",
  payment_amount_limit:"Betalingsbeløpsgrense",
  total_limit:"Totalgrense",
  payees:"Betalingsmottakere",
  description:"Beskrivelse",
  address_line_one:"Adresselinje én",
  address_line_two:"Adresselinje to",
  image:"Bilde",
  account_holder:"Kontoinnehaver",
  cheque_starting_id:"Sjekk start-ID",
  transit_number:"Transittnummer",
  institution_number:"Institusjonsnummer",
  designation_number:"Betegnelsesnummer",
  account_number:"Kontonummer",
  currency:"Valuta",
  signature:"Signatur",
  payment_payroll_limit:"Betalingsgrense",
  total_limi:"Totalgrense",
  date:"Dato",
  printed_memo:"Trykt notat",
  banks:"Banker",
  signature_image:"Signaturbilde",
  address_name:"Adressenavn",
  notes:"Notater",
  design:"Design",
  title:"Tittel",
  frequency:"Frekvens",
  fax:"Faks",
  salaries:"Lønn",
  salary_ids:"Lønns-IDer",
  start_time:"Starttid",
  end_time:"Sluttid",
  paid:"Betalt",
  overtime_percentage:"Betalt prosentandel",
  overtime_amount:"Betalt fast beløp",
  first_name:"Fornavn",
  last_name:"Etternavn",
  moderation:"Moderasjon",
  create:"Skape",
  edit:"Redigere",
  destroy:"Ødelegge",
  day_start_time:"Day_start_time",
  day_end_time:"Dag_sluttid",
  fullname:"Navn",
  time:"Tid",
  auto_send:"Send automatisk",
  time_method:"Tidsmetode",
  schedules:"Tidsplaner",
  indefinite_payroll_enabled:"Aktiver på ubestemt tid",
  amount:"Beløp",
  repeat:"Gjenta",
  always_enabled:"Alltid aktivert",
  start_date:"Startdato",
  end_date:"Sluttdato",
  Cheque_Total:"Sjekk Totalt",
  Total_Amount:"Totale mengden:",
  Amounts:"Beløp:",
  Images:"Bilder",
  Files:"Filer",
  Previewing:"Forhåndsvisning:",
  Insert:"Sett inn",
  Preview_Import:"Forhåndsvis import",
  Entry:"Inngang",
  Entries:"Innganger",
  No_Entries:"Ingen oppføringer",
  Import_Type:"Importtype",
  Select_Details:"Velg Detaljer",
  Select_Payee:"Velg betalingsmottaker",
  Enable_Holidays:"Aktiver helligdager",
  Disable_Holidays:"Deaktiver helligdager",
  Wire_Transfer:"Bankoverføring",
  New_Export:"Ny eksport",
  Export_Data:"Eksporter data",
  Export_Data_Word:"Velg filtypen som skal eksporteres og lastes ned.",
  Export_File:"Eksporter fil",
  Mode:"Modus",
  Times:"Tider",
  Widgets:"Widgets",
  Slider:"Skyveknapp",
  Set_Details:"Angi detaljer",
  Select_Type:"Velg Type",
  Display_Slider:"Skjermglidebryter",
  Dashboard_Slider:"Dashboard-glidebryter",
  Dashboard_Mode:"Dashboard-modus",
  Show_Intro:"Vis intro",
  Show_Payrolls:"Vis lønnslister",
  Show_Holidays:"Vis helligdager",
  Show_Invoices:"Vis fakturaer",
  Show_Cheques:"Vis sjekker",
  Enabled_Widgets:"Aktiverte widgeter",
  Disabled_Widgets:"Deaktiverte widgeter",
  Colors:"Farger",
  Barcodes:"Strekkoder",
  View_Timers:"Se tidtakere",
  Gradients:"Gradienter",
  No_Info:"Ingen info",
  Disable:"Deaktiver",
  Show_Layer:"Vis lag",
  Hide_Layer:"Skjul lag",
  Text_Layer:"Tekstlag",
  Secondly:"for det andre",
  Minutely:"Minutt",
  nine_am:"9:00 OM MORGENEN",
  five_pm:"17:00",
  Enable_Address:"Aktiver adresse",
  Invalid_File_Type:"Beklager, en ugyldig filtype ble valgt. Støttet filtype:",
  Error_Updating_Entry:"Beklager, det oppstod en feil under oppdatering av denne oppføringen.",
  Schedule_Timing_Alert:"Feil: Tidsplanens starttidspunkt er satt til en verdi etter sluttid.",
  Select_Multiple_Payments_Prompt:"Ingen betalinger er valgt. Velg minst én betaling.",
  Select_Multiple_Cheques_Prompt:"Ingen hake valgt. Velg minst én sjekk.",
  Select_Multiple_Items_Prompt:"Ingen elementer er valgt. Velg minst ett element.",
  Paymemt_Multiple_Delete_Confirmation:"Er du sikker på at du vil slette flere betalinger?",
  Cheque_Multiple_Delete_Confirmation:"Er du sikker på at du vil slette flere sjekker?",
  Payee_Multiple_Delete_Confirmation:"Er du sikker på at du vil slette flere betalingsmottakere?",
  Employee_Multiple_Delete_Confirmation:"Er du sikker på at du vil slette flere ansatte?",
  MICR_Warning:"Merk: Noen skrivere og enheter viser kanskje ikke MICR-fonten riktig.",
  Automatically_Send:"Send automatisk",
  Type:"Type",
  Payment_Type:"Betalings type",
  Auto_Send:"Send automatisk",
  Automatically_Process:"Behandle automatisk",
  Auto_Process:"Automatisk prosess",
  Image_Based:"Bildebasert",
  Font_Based:"Font-basert",
  Rerender:"Gjengi",
  Rendering:"Gjengivelse",
  Render:"Filer",
  Top:"Topp",
  Middle:"Midten",
  Bottom:"Bunn",
  Top_Left:"Øverst til venstre",
  Top_Center:"Topp senter",
  Top_Right:"Øverst til høyre",
  Middle_Left:"Midt til venstre",
  Middle_Center:"Midtsenter",
  Middle_Right:"Midt til høyre",
  Bottom_Left:"Nede til venstre",
  Bottom_Center:"Nederst i midten",
  Bottom_Right:"Nede til høyre",
  Numbers:"Tall",
  Verified:"Verifisert",
  Paper_Size:"Papir størrelse",
  New_Device:"Ny enhet",
  Add_Device:"Legg til enhet",
  Remove_Device:"Fjern enhet",
  Delete_Device:"Slett enhet",
  Block_Device:"Blokker enhet",
  Block:"Blokkere",
  Unblock:"Fjern blokkering",
  Table:"Bord",
  Scan_Login_Code:"Skann påloggingskode",
  Login_Code:"Påloggingskode",
  Scan_Code:"Skann kode",
  Scan_QR_Code:"Skann QR kode",
  Select_All:"Velg alle",
  Deselect_All:"Opphev alle",
  Increase:"Øke",
  Decrease:"Avta",
  Bold:"Dristig",
  Text:"Tekst",
  Style:"Stil",
  Italic:"Kursiv",
  QR_Code:"QR kode",
  Barcode:"Strekkode",
  Browse_Images:"Bla gjennom bilder",
  Browse_Files:"Bla gjennom filer",
  Background_Image:"Bakgrunnsbilde",
  Logo_Image:"Logo bilde",
  Header_Image:"Overskriftsbilde",
  Bank_Image:"Bankbilde",
  Cut_Lines:"Kutt linjer",
  Background:"Bakgrunn",
  License:"Tillatelse",
  One_License:"1 lisens:",
  Licensed:"Lisensiert til:",
  Not_Licensed:"Ikke lisensiert",
  Enter_Serial:"Angi seriell",
  Serial_Key:"System nøkkel",
  Serial:"Seriell",
  Product_Key:"Produktnøkkel",
  Check_Product_Key:"Bekreft produktnøkkel",
  Add_Product_Key:"Skriv inn produktnøkkel",
  Verifying_Purchase:"Bekrefter kjøp...",
  Print_Envelope:"Skriv ut konvolutt",
  Envelope:"Konvolutt",
  Thank_You:"Takk skal du ha!",
  Scale:"Skala",
  Print_Scale:"Print Scale",
  Borders:"Grenser",
  VOID:"TOMROM",
  Void_Cheque:"Ugyldig sjekk",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"BETAL I ORDEN AV:",
  NO_DOLLARS:"INGEN DOLLAR",
  ONE_DOLLAR:"ÉN DOLLAR",
  DOLLARS:"DOLLAR",
  AND:"OG",
  CENTS:"CENT.",
  NO_:"NEI",
  ONE_:"EN",
  AND_NO_:"OG NEI",
  _AND_ONE_:"OG EN",
  DOLLARS_AND_ONE_CENT:"OG ETT CENT.",
  AND_NO_CENTS:"OG NULL CENT.",
  CHEQUE_PRINT_DATE:"DATO:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Innledende oppgaver",
  Inital_Setup:"Førstegangs oppsett",
  Welcome_To:"Velkommen til",
  Welcome:"Velkommen",
  Swipe:"Sveip",
  Intro_Setup:"Introoppsett",
  Introduction:"Introduksjon",
  CHEQUE_PRINT_CREDIT:"Drevet av sjekker",
  Title_Intro_Word:"Velkommen til Checks",
  Title_Intro:"Sjekker Introduksjon",
  Title_Setup:"Sjekker oppsett",
  PayPal_Default_Email_Message:"Du har mottatt en ny PayPal-overføring.",
  Cheques_App_Export:"Eksportert av sjekker",
  No_Prints_Purchased:"Ingen utskrifter kjøpt",
  No_Prints_Purchased_Word:"Ingen ekstra utskrifter er tilgjengelige. Vennligst kjøp ekstra sjekker.",
  Post_Intro_Thanks:"Takk for at du valgte Cheques. Konfigurasjonsprosessen er nå fullført.",
  Post_Intro_Word:"Kom i gang ved å skrive ut din første sjekk, legg til en fremtidig betaling eller legg til en medarbeider på lønnslisten.",
  Upgrade_Required:"Sjekker krever at du eier en mer premiumversjon av programvaren for å skjule denne meldingen og låse opp tilleggsfunksjoner.",
  Upgrade_Title:"Sjekker",
  Mailout_Prompt:"Du kan i tillegg velge å la Checks sende ut lønnssjekkene dine for deg.",
  Mailed_Cheque:"Sendt sjekk",
  Mailed_Cheque_Color:"Sendt sjekk (farge)",
  Terms_Purchase:"Alle elektroniske kjøp med sjekker er fullt refunderbare i opptil 30 dager fra kjøpsdatoen. Vennligst les og godta",
  Dashboard_Setup:"Konfigurer den primære skriveren",
  Dashboard_Add:"Legg til den primære bankkontoen",
  Dashboard_Customize:"Velg en sjekkmal",
  Dashboard_Job_Salary:"Lag din jobb og legg til lønn",
  Dashboard_Employees:"Spor ansatte og betalingsmottakere",
  Dashboard_Print:"Skriv ut og send lønnsslippene dine",
  Dashboard_Payroll:"Automatiser lønnsutskriften din",
  Dashboard_PayPal:"Konfigurer PayPal Lønn / Utbetalinger",
  Begin_Setup:"Start oppsett",
  Get_Started:"Kom i gang",
  Purchase:"Kjøp",
  Lockdown:"Nedstengning",
  Unlock:"Låse opp",
  Detailed:"Detaljert",
  Log_In:"Logg Inn",
  Login:"Logg Inn",
  Launch:"Lansering",
  Register:"Registrere",
  Finish:"Bli ferdig",
  List:"Liste",
  Weekends:"Helger",
  Weekly:"Ukentlig",
  PayPal_Default_Subject:"Ny betaling",
  Payment_Message:"Betalingsmelding",
  PayPal_Default_Payment_Note:"Takk skal du ha",
  Setup_Your_Cheqing_Account:"Sjekkkonto",
  Add_Your_Primary_Cheqing_Account:"Legg til din primære sjekkkonto.",
  Welcome_Word:"Forenkle og automatisere lønns- og personaladministrasjon.",
  Get_Started_Quickly:"Bare svar på noen enkle spørsmål som vil hjelpe oss å komme i gang...",
  Done_Intro_Word:"Oppsett fullført",
  PIN_Protected:"Passord og PIN-beskyttet",
  Enter_New_PIN:"Skriv inn en ny PIN-kode:",
  Enter_PIN:"Skriv inn PIN-kode:",
  Invalid_PIN:"Ugyldig PIN-kode angitt.",
  Account_Identifier:"Kontoidentifikator",
  New_Account_Identifier:"Ny kontoidentifikator",
  attempt:"forsøk",
  attempts:"forsøk",
  remaining:"gjenstående",
  Language:"Språk",
  languages:"Språk",
  select_languages:"Velg språk",
  Deposit:"Innskudd",
  Hire_One_Now:"Lei en nå",
  App_Locked:"Applikasjonen er låst.",
  Skip_:"Hopp over",
  Skip_to_Dashboard:"Gå til dashbordet",
  Dashboard:"Dashbord",
  Import:"Import",
  Admin:"Administratorer",
  New_Admin:"Ny admin",
  Settings:"Innstillinger",
  Color_Picker:"Fargevelger",
  Font_Picker:"Fontvelger",
  Logout:"Logg ut",
  Close:"Lukk",
  Close_menu:"Lukk",
  Excel:"Excel-fil",
  Csv:"CSV-fil",
  Sql:"SQL-fil",
  Json:"JSON-fil",
  Url:"Importer etter URL",
  Back:"Tilbake",
  Timers:"Tidtakere",
  Cheque:"Kryss av",
  Print:"Skrive ut",
  Designs:"Design",
  Pause_Printing:"Pause utskrift",
  Resume_Printing:"Fortsett utskrift",
  Printing_Paused:"Utskrift satt på pause",
  PrintingUnavailable:"Beklager! Utskrift er utilgjengelig på dette systemet.",
  Prints_Paused:"Utskrifter satt på pause",
  Administration:"Administrasjon",
  Loading:"Laster",
  Unnamed:"uten navn",
  error:"Beklager, denne sjekken kunne ikke åpnes for visning.",
  No_Cheques_To_Print:"Ingen sjekker å skrive ut",
  No_Cheques_To_Print_Word:"Opprett en <a routerLink='/folder/Cheque/New'>Ny sjekk</a>.",
  New_Cheque:"Ny sjekk",
  Start_One_Now:"Start en nå",
  Edit_Cheque:"Rediger sjekk",
  Select_Cheques:"Velg Sjekker",
  Select_Employee:"Velg Ansatt",
  Default_Printer:"Standard skriver",
  Reassign:"Tilordne på nytt",
  Configure:"Konfigurer",
  Template:"Mal",
  Designer:"Designer",
  Edit_Designs:"Rediger design",
  New_Design:"Nytt design",
  Next:"Neste",
  Save:"Lagre",
  Name:"Navn",
  Mail:"Post",
  Amount:"Beløp",
  Date:"Dato",
  PayPal:"PayPal",
  Payouts:"Utbetalinger",
  PayPal_Label:"PayPal-etikett",
  Email_Username:"E-post/brukernavn",
  Client_ID:"klient-ID",
  Sandbox_Email:"Sandbox E-post",
  PayPal_Email:"PayPal e-post",
  PayPal_Username:"API-brukernavn",
  PayPal_Payouts:"PayPal-utbetalinger",
  Select_PayPal_Key:"Velg PayPal-nøkkel",
  Secret:"Hemmelig",
  API_Secret:"API-hemmelighet",
  PayPal_API_Keys:"PayPal-nøkler",
  New_PayPal_Key:"Ny PayPal-nøkkel",
  Email_Subject:"E-post Emne",
  Email_Message:"E-postmelding",
  Payout_Options:"Utbetalingsalternativer",
  Payment_Note:"Betalingsanmerkning",
  Enable_Employee:"Aktiver ansatt",
  Enable_Production_Mode:"Aktiver produksjonsmodus",
  Sandbox_Username:"Sandbox brukernavn",
  Sandbox_Signature:"Sandkassesignatur",
  Sandbox_Password:"Sandbox-passord",
  Production_Username:"Brukernavn for produksjon",
  Production_Signature:"Produksjonssignatur",
  Production_Password:"Produksjonspassord",
  Production_Email:"E-post for produksjon",
  API_Client_ID:"API-klient-ID",
  API_Signature:"API-signatur",
  API_Password:"API-passord",
  API_Username:"API-brukernavn",
  Secret_Key:"Hemmelig nøkkel",
  Sandbox:"Sandkasse",
  Production:"Produksjon",
  Sandbox_Keys:"Sandkassenøkler",
  Production_Keys:"Produksjonsnøkler",
  API_Title:"API-tittel",
  Production_Mode:"Produksjonsmodus",
  Account_Label:"Kontoetikett",
  No_PayPal_Setup:"Ingen PayPal-nøkkel",
  Enable_PayPal_Account:"Aktiver PayPal-konto",
  No_PayPal_Word:"Legg til <a routerLink='/folder/Invoice/New'>PayPal API-nøkkel</a>.",
  Printed_Memo:"Trykt notat",
  Employee:"Ansatt",
  View_Employee:"Se medarbeider",
  Mailing_Address:"Postadresse",
  Company_Address:"Firma adresse",
  Select_Company_Address:"Velg Firmaadresse",
  Address:"Adresse",
  Any_Day:"Hvilken som helst dag",
  Address_Name:"Adressenavn",
  Unit:"Enhet",
  Account:"Regnskap",
  Bank_Account:"Bankkonto",
  Account_Limits:"Aktiver kontogrenser",
  Payroll:"Lønn",
  Run:"Løpe",
  Run_Payroll:"Kjør lønn",
  New_Payroll:"Ny lønnsliste",
  No_Payroll:"Ingen kommende lønn",
  Upcoming_Holiday:"Kommende ferie:",
  Invoice_Due:"Forfallsfaktura:",
  New_Invoice:"Ny faktura",
  No_Invoices:"Ingen fakturaer",
  No_Invoices_Word:"Opprett den første <a routerLink='/folder/Invoice/New'>fakturaen</a>.",
  Cheque_Due:"Sjekk forfall:",
  Payrolls:"Lønnslister",
  Sandbox_Mode:"sandkasse modus",
  Hire:"Ansette",
  Pay:"Betale",
  New:"Ny",
  Add:"Legg til",
  Make:"Gjøre",
  Time:"Tid",
  Write:"Skrive",
  Holiday:"ferie",
  Holidays:"Helligdager",
  Next_Holiday:"Neste ferie:",
  All_Done:"Ferdig!",
  Employees:"Ansatte",
  Payees:"Betalingsmottakere",
  Job:"Jobb",
  Jobs:"Arbeidsplasser",
  Invoice:"Faktura",
  Invoices:"Fakturaer",
  Vacations:"Ferier",
  Cheques:"Sjekker",
  Brand_Cheques:"Ditt merke",
  Payment:"innbetaling",
  Enable_Payment:"Aktiver betaling",
  Payments:"Betalinger",
  Schedule:"Rute",
  Schedules:"Tidsplaner",
  Timesheet:"Tids skjema",
  Timesheets:"Timelister",
  Salary:"Lønn",
  New_Address:"Ny adresse",
  Address_2:"Adresselinje 2)",
  _City:"By",
  _State:"Stat/Prov",
  City:"By / Township",
  State:"Stat/provins",
  Postal:"Postnummer",
  ZIP:"Poststed / postnummer",
  Country:"Land",
  Addresses:"Adresser",
  Required_Options:"Nødvendige alternativer",
  Optional_Options:"Valgfrie alternativer",
  Additional_Options:"Ytterligere alternativer",
  Required:"Obligatorisk",
  Optional:"Valgfri",
  Additional:"Ytterligere",
  No_Addresses:"Ingen adresser",
  New_Address_Word:"Legg til den første <a routerLink='/folder/Address/New'>adressen</a>.",
  Select_Address:"Velg Adresse",
  No_Address:"Ingen adresser",
  Print_Cheque:"Skriv ut sjekk",
  Print_Cheques:"Skriv ut sjekker",
  Print_Cheque_Now:"Skriv ut Sjekk nå",
  Description:"Beskrivelse",
  Pay_To_The_Order_Of:"Betal til bestillingen av:",
  Select_Date_Range:"Velg Datoperiode",
  Select_Starting_Date:"Velg Startdato",
  Select_Ending_Date:"Velg Sluttdato",
  Select_Date:"Velg Dato",
  Cheque_Date:"Sjekk dato",
  Cheque_Memo:"Sjekk Memo",
  Blank_Cheque:"Blank sjekk",
  Blank:"Blank",
  No_Cheques:"Ingen sjekker",
  No_Cheques_Word:"Skriv ut din første <a routerLink='/folder/Cheque/New'>sjekk</a>.",
  Cheque_Amount_Placeholder:"$0,00",
  View_Image:"Vis bilde",
  View:"Utsikt",
  Modify:"Endre",
  Delete:"Slett",
  Cheque_Paid:"Betalt",
  New_Deduction:"Nytt fradrag",
  Title:"Tittel",
  Frequency:"Frekvens",
  Hourly:"Hver time",
  Daily:"Daglig",
  Weekdays:"Hverdager",
  BiWeekly:"2 uker",
  TriWeekly:"3 uker",
  Monthly:"Månedlig",
  MiMonthly:"2 måneder",
  Quarterly:"Kvartalsvis",
  Yearly:"Årlig",
  Every_Week:"Hver uke",
  Every_Payroll:"Hver lønnsliste",
  Every_Month:"Hver måned",
  Annually:"Årlig",
  Always_Scheduled:"Alltid planlagt",
  Start_Date:"Startdato",
  End_Date:"Sluttdato",
  Start_Time:"Starttid",
  End_Time:"Sluttid",
  Deduction_Label:"Fradragsetikett",
  Notes:"Notater",
  Options:"Alternativer",
  Enable:"Muliggjøre",
  Select_Deductions:"Velg Fradrag",
  Deductions:"Fradrag",
  No_Deductions:"Ingen fradrag",
  No_Deductions_Word:"Opprett ditt første <a routerLink='/folder/Deduction/New'>Fradrag</a>.",
  New_Employee:"Ny ansatt",
  No_Title:"Uten tittel",
  _Name:"Navn",
  About_Yourself:"Om deg selv",
  Full_Name:"Fullt navn",
  Birthday:"Fødselsdag",
  Email:"E-post",
  SMS:"tekstmelding",
  Phone:"Telefon",
  Test:"Test",
  Call:"Anrop",
  Fax:"Faks",
  Printed_Note:"Utskrevet notat",
  Position:"Posisjon",
  Job_Position:"Jobb stilling",
  Select_a_Job:"Velg en jobb",
  Select_a_Salary:"Velg en lønn",
  Add_a_Deduction:"Legg til et fradrag",
  Taxes:"Skatter",
  Add_Taxes:"Legg til skatter",
  Date_of_Birth:"Fødselsdato",
  Email_Address:"Epostadresse",
  Phone_Number:"Telefonnummer",
  Phone_Call:"Telefonsamtale",
  Enable_on_Payroll:"Aktiver på lønn",
  Select_Employees:"Velg Ansatte",
  No_Employees:"Ingen ansatte",
  No_Employees_Word:"Legg til din første nye <a routerLink='/folder/Employee/New'>Ansatt</a>.",
  No_Name:"Ingen navn",
  Unemployeed:"Arbeidsledig",
  Employeed:"Ansatt",
  Paid:"Betalt",
  Enabled:"Aktivert",
  Disabled:"Funksjonshemmet",
  Fire:"Brann",
  Not_Available:"Ikke tilgjengelig",
  Not_Available_Word:"Skriv ut din første <a routerLink='/folder/Invoice/New'>faktura</a> og få betalt.",
  Select_Invoices:"Velg Fakturaer",
  Print_Invoice_Word:"Skriv ut din første <a routerLink='/folder/Invoice/New'>faktura</a> og få betalt.",
  Invoice_Title:"Fakturatittel",
  Invoice_Date:"Fakturadato",
  Due_Date:"Tidsfrist",
  New_Job:"Ny jobb",
  Details:"Detaljer",
  Customize:"Tilpass",
  Customize_Dashboard:"Tilpass Dashboard",
  Components:"Komponenter",
  No_Components:"Ingen komponenter",
  Main_Components:"Hovedkomponenter",
  Smaller_Components:"Mindre komponenter",
  Error_Loading_Page:"Feil ved innlasting av denne siden.",
  Bank_Details:"Bankinformasjon",
  About_Your_Job:"Om jobben din",
  Your_Schedule:"Din timeplan",
  Job_Title:"Jobbtittel",
  Job_Description:"Stillingsbeskrivelse",
  Job_Details:"Jobbdetaljer",
  Enable_Job:"Aktiver jobb",
  Pay_Period:"Betalingsperiode",
  Perpetually_Schedule:"Evig tidsplan",
  Select_Jobs:"Velg Jobber",
  No_Jobs:"Ingen jobber",
  Add_Jobs:"Legg til jobber",
  No_Jobs_Word:"Legg til den første <a routerLink='/folder/Job/New'>jobben</a> til listen.",
  Count_Employees:"job.employee_count+' Ansatte'",
  Zero_Employees:"0 ansatte",
  New_Leave:"Ny permisjon",
  Leave_Name:"Legg igjen navn",
  Paid_Leave:"Betalt permisjon",
  Leave_Pay:"La Pay",
  Indefinite_Leave:"Permisjon på ubestemt tid",
  Indefinite_Payroll:"Ubestemt lønn",
  Leave:"Permisjon",
  Add_Schedules:"Legg til tidsplaner",
  Percentage:"100 %",
  Enable_Leave_Of_Absence:"Aktiver fravær",
  Select_Leaves:"Velg Blader",
  No_Leaves:"Ingen fravær",
  Leave_Of_Absence:"Permisjon",
  Leaves_Of_Absence:"Fraværsblader",
  New_Leave_of_Absense:"Ny permisjon",
  No_Leaves_Word:"Legg til den første <a routerLink='/folder/Leave/New'>fraværet</a>.",
  Not_Enabled:"Ikke aktivert",
  Absences:"Fravær",
  Payee:"Betalingsmottaker",
  New_Payee:"Ny betalingsmottaker",
  Payee_Identifier:"Identifikasjon av betalingsmottaker",
  Payee_Name:"Mottakers navn",
  Payee_Title:"Utbetalingsmottakers tittel",
  Payment_Memo:"Betalingsnotat",
  Select_Payees:"Velg Betalingsmottakere",
  No_Payees:"Ingen betalingsmottakere",
  Add_Payee_Note:"Legg til den første <a routerLink='/folder/Payee/New'>utbetalingsmottaker</a>.",
  New_Payees:"Nye betalingsmottakere",
  About_The_Payment_Schedule:"Nedbetalingsplan",
  Your_Payroll_Schedule:"Automatisk lønn",
  New_Payment:"Ny betaling",
  Identifier:"Identifikator",
  Selected:"Valgt",
  Select:"Plukke ut",
  Memo:"Memo",
  Payment_Date:"Betalingsdato",
  Mark_as_Paid:"Merk som betalt",
  Select_Payments:"Velg Betalinger",
  No_Payments:"Ingen betalinger",
  No_Payments_Word:"Opprett den første <a routerLink='/folder/Payment/New'>betalingen</a>.",
  Create_Payroll:"Lag lønn",
  Properties:"Egenskaper",
  Payroll_Title:"Lønnstittel",
  Payroll_Notes:"Lønnsnotater",
  Payroll_Setup:"Lønnsoppsett",
  Tabulate_Payments:"Tabell betalinger",
  Tabulate:"Tabuler",
  By:"Av:",
  Payments_By:"Betalinger etter",
  Timesheets_And_Schedules:"Timelister og tidsplaner",
  Both:"Både",
  Items:"Varer",
  Add_Payees:"Legg til betalingsmottakere",
  Add_Account:"Legg til konto",
  Enable_Account:"Aktiver konto",
  Enable_Payroll:"Aktiver lønn",
  Print_Payroll:"Skriv ut lønn",
  No_Payrolls:"Ingen lønn",
  No_Payroll_Word:"Opprett din første <a routerLink='/folder/Payroll/New'>Lønningsliste</a>.",
  View_more:"SE MER",
  Less:"MINDRE",
  Add_Payroll:"Legg til lønn",
  Select_Payroll:"Velg Lønn",
  About_Your_Salary:"Om lønnen din",
  Add_Salaries:"Legg til lønn",
  Add_Salary:"Legg til lønn",
  Salaries:"Lønn",
  No_Salaries:"Ingen lønn",
  No_Salaries_Word:"Legg til den første <a routerLink='/folder/Salary/New'>Lønn</a>.",
  Select_Salaries:"Velg Lønn",
  New_Salary:"Ny lønn",
  Salary_Name:"Lønnidentifikator",
  Enable_Salary:"Aktiver lønn",
  Salary_Amount:"Lønnsbeløp",
  New_Schedule:"Ny timeplan",
  Schedule_Title:"Tidsplantittel",
  Add_Address:"Legg til adresse",
  Repeat:"Gjenta",
  Design:"Design",
  Edit_Design:"Rediger design",
  Edit_this_Design:"Rediger dette designet",
  Repeat_Payment:"Gjenta betaling",
  Enable_Schedule:"Aktiver tidsplan",
  Never:"Aldri",
  Select_Schedule:"Velg Tidsplaner",
  No_Schedules:"Ingen tidsplaner",
  No_Schedules_Word:"Opprett den første <a routerLink='/folder/Schedule/New'>Rute</a>.",
  New_Administrator:"Ny administrator",
  Username:"Brukernavn",
  First_Name:"Fornavn",
  Last_Name:"Etternavn",
  Add_an_Address:"Legg til en adresse",
  Payment_Limit:"Per-betalingsgrense",
  Total_Limit:"Totalgrense",
  Select_Accounts:"Velg Kontoer",
  No_Administrators:"Ingen administratorer",
  No_Administrators_Word:"Opprett den første <a routerLink='/folder/Administrator/New'>Administratorkontoen</a>.",
  New_Administrators_Word:"Legg til den første <a routerLink='/folder/Administrator/New'>administratoren</a>",
  Cloud:"Sky",
  Backup:"Sikkerhetskopiering",
  Enable_iCloud:"Aktiver iCloud",
  Enable_Google_Drive:"Aktiver Google Disk",
  Enable_Microsoft_OneDrive:"Aktiver Microsoft OneDrive",
  Automatically_Backup:"Automatisk sikkerhetskopiering",
  FTP_Settings:"FTP-innstillinger",
  URL_File_Prompt:"Vennligst spesifiser plasseringen for en .xls / .xlsx / .json-fil som skal importeres:",
  URL_SQL_Prompt:"Vennligst spesifiser plasseringen til SQLite-filen som skal importeres:",
  FTP_Backup:"FTP-sikkerhetskopi",
  FTP_Import:"FTP-import",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Vert",
  Port:"Havn",
  Path:"Sti",
  Data_Path:"Databane",
  Enable_FTP_Account:"Aktiver FTP-konto",
  HostnameIP:"Vertsnavn",
  Password:"Passord",
  Connection_Port:"Tilkoblingsport",
  Enable_MySQL_Database:"Aktiver MySQL-database",
  Log:"Logg",
  Reset:"Nullstille",
  Erase:"Viske ut",
  Export:"Eksport",
  Database:"Database",
  Upload_CSV:"Last opp CSV",
  Download_CSV:"Last ned CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL sikkerhetskopi",
  Internal_Notes:"Interne merknader",
  Root_Path:"Rotsti",
  Select_Backup:"Velg Sikkerhetskopiering",
  Add_New_Backup:"Legg til en ny sikkerhetskopi",
  First_Backup:"Sett opp den første sikkerhetskopien...",
  Backups:"Sikkerhetskopier",
  Add_Backup:"Legg til sikkerhetskopi",
  No_Backups:"Det er ingen sikkerhetskopier å finne.",
  Select_Backup_Type:"Velg typen sikkerhetskopi du vil konfigurere...",
  Backup_Type:"Backup Type",
  FTP_Drive:"FTP-stasjon",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Kjøre",
  Microsoft_OneDrive:"Kjøre",
  Import_Fields:"Importer felt",
  Import_Fields_Word:"Bruk denne delen til å velge <a routerLink='/folder/Holiday/New'>Importer</a> data.",
  Upload:"Laste opp",
  Download:"nedlasting",
  Download_JSON:"Last ned som JSON-data",
  Download_SQL:"Last ned som SQL-fil",
  New_Bank:"Ny bank",
  New_Account:"Ny konto",
  New_Bank_Account:"Ny bankkonto",
  Upload_Image:"Last opp bilde",
  Bank_Name:"Bank navn",
  Bank_Address:"Bankadresse",
  Branch_Address:"Gren adresse",
  Address_on_Cheque:"Adresse",
  Cheque_Numbers:"Sjekk tall",
  Cheque_Details:"Sjekk detaljer",
  Bank_Logo:"Banklogo",
  Cheque_ID:"Sjekk ID",
  Starting_Cheque_ID:"Starter Sjekk ID",
  Transit_Number:"Transittnummer",
  Institution_Number:"Institusjonsnummer",
  Designation_Number:"Betegnelsesnummer",
  Account_Number:"Kontonummer",
  Limits:"Grenser",
  Default_Limits:"Standardgrenser",
  Over_Limit:"Over grensen",
  Under_Limit:"Under grense",
  Payroll_Limit:"Lønnsgrense",
  Enable_Bank_Account:"Aktiver bankkonto",
  Select_Account:"Velg Konto",
  No_Bank_Account:"Ingen bankkonto",
  No_Bank_Account_Word:"Legg til den første <a routerLink='/folder/Accounts/New'>bankkontoen</a>.",
  Bank_Accounts:"Bankkontoer",
  No_Accounts:"Ingen kontoer",
  No_Accounts_Note:"Legg til den første <a routerLink='/folder/Accounts/New'>bankkontoen</a>.",
  Cheque_Designer:"Sjekk Designer",
  Cheque_Design:"Sjekk design",
  Select_Design:"Velg et design",
  Cheque_Designs:"Sjekk design",
  No_Cheque_Designs:"Ingen sjekkdesign",
  No_Cheque_Designs_Word:"Lag ditt eget <a routerLink='/folder/Settings/Cheque/Design/New'>Sjekkdesign</a>.",
  Set_Default:"Satt som standard",
  Default:"Misligholde",
  Remove:"Fjerne",
  Folder:"Mappe",
  Edit:"Redigere",
  LoadingDots:"Laster inn...",
  Add_a_New_File:"Legg til en <a href='#' (click)='add()'>Ny fil</a> til",
  this_folder:"denne mappen",
  FTP_Backup_Settings:"Innstillinger for FTP-sikkerhetskopiering",
  Secure_File_Transfer:"Sikker filoverføring",
  New_Holiday:"Ny ferie",
  Add_Holiday:"Legg til ferie",
  Holiday_Name:"Ferienavn",
  Additional_Pay:"Ekstra lønn",
  Enable_Holiday:"Aktiver ferie",
  Select_Holidays:"Velg Helligdager",
  No_Holidays:"Ingen helligdager",
  No_Holidays_Word:"Legg til den første <a routerLink='/folder/Holiday/New'>offentlige helligdager</a>.",
  New_Import:"Ny import",
  Import_Data:"Importer data",
  Import_Data_Word:"Velg filtypen eller opplastingsmetoden du velger.<br /> Du vil kunne velge hvilke importerte felt i en fil som tilsvarer parameteren i appen etter å ha lastet opp en støttet fil.",
  Import_File:"Importer fil",
  Link_To_File:"Link til fil",
  Select_File:"Velg Fil",
  New_Moderator:"Ny moderator",
  Allow_Moderation:"Tillat moderering",
  Create_Paycheques:"Opprett lønnsslipper",
  Edit_Paycheques_and_Data:"Rediger lønnsslipper og data",
  Destroy_Data_and_Paycheques:"Ødelegg data og lønnssjekker",
  Bonus_Percentage:"Prosent av lønnsslipp",
  Fixed_Amount:"Fast beløp",
  Select_Moderator:"Velg Moderator",
  No_Moderators:"Ingen moderatorer",
  Moderators:"Moderatorer",
  Moderator_Account:"Opprett den første <a routerLink='/folder/Administrator/New'>Moderatorkontoen</a>.",
  No_Moderators_Word:"Legg til den første <a routerLink='/folder/Administrator/New'>moderatoren</a>.",
  Defaults:"Standarder",
  Provide_Us_Info:"Gi informasjon",
  Setup_Your_Printer:"Sett opp skriveren din",
  Your_Company:"Om ditt firma",
  Company_Name:"selskapsnavn",
  Currency:"Valuta",
  Default_Currency:"Standard valuta",
  Base_Monthly_Income:"Månedlig inntekt",
  Protection:"Beskyttelse",
  App_Protection:"Appbeskyttelse",
  PIN_Code_Protection:"PIN-kodebeskyttelse",
  App_Protection_Word:"Aktiver sikkerhetsmetoder som bidrar til å beskytte kontoen din.",
  PIN_Code:"PIN-kode",
  Change_PIN:"Endre PIN",
  New_Password:"Nytt passord",
  Geofence_Protection:"Geo-gjerde beskyttelse",
  Geofence_Area:"Angi område",
  Distance:"Avstand",
  Setup_Now:"Konfigurer nå",
  Mile:"Mile",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Ansiktsgjenkjenning",
  Face:"Ansikt",
  Setup:"Oppsett",
  Label:"Merkelapp",
  Password_Protection:"Passordbeskyttelse",
  Modify_Password:"Endre passord",
  New_Tax_Entry:"Ny skatteoppføring",
  New_Tax:"Ny skatt",
  Tax_Label:"Skattemerke",
  Perpetually_Enabled:"Evig aktivert",
  Select_Taxes:"Velg Skatter",
  Tax_Deductions:"Skattefradrag",
  No_Tax_Deductions:"Ingen skattefradrag",
  No_Tax_Deductions_Word:"Legg til det første <a routerLink='/folder/Tax/New'>Skatte</a>-fradraget.",
  New_Timer:"Ny timer",
  Start:"Start",
  Stop:"Stoppe",
  Start_Timer:"Start timer",
  Stop_Timer:"Stopp timer",
  Timer_Active:"Timer aktiv",
  Timer:"Timer:",
  Timer_Running:"Timer: (løper)",
  Save_Timer:"Lagre timer",
  New_Timesheet:"Ny timeliste",
  Select_Timesheets:"Velg Timelister",
  Work_Notes:"Arbeidsnotater",
  Entry_Title:"Oppføringstittel",
  No_Timesheets:"Ingen timelister",
  No_Timesheets_Word:"Legg til den første <a routerLink='/folder/Timesheet/New'>Timelisten</a>.",
  Timesheet_Submitted:"Timeliste innsendt",
  Timesheet_Congrats:"Gratulerer! Timelisten din er sendt inn. Takk skal du ha!",
  Timesheet_Copy:"For å motta en kopi av dokumentene dine, vennligst gi oss din e-postadresse og/eller mobiltelefonnummer.",
  Submit:"Sende inn",
  Allow_Notifications:"Tillat varsler",
  Untitled_Entry:"Ny inngang",
  Untitled_Bank:"Uten tittel Bank",
  Timesheet_Entry:"Timelisteregistrering",
  Work_Description:"arbeidsbeskrivelse",
  Enable_Timesheet:"Aktiver timeliste",
  Submit_Timesheet:"Send inn timeliste",
  Vacation:"Ferie",
  New_Vacation:"Ny ferie",
  Vacation_Name:"Ferienavn",
  Paid_Vacation:"Betalt ferie",
  Vacation_Pay:"Feriepenger",
  Enable_Vacation:"Aktiver ferie",
  Select_Vacations:"Velg Ferier",
  No_Vacations:"Ingen ferier",
  No_Vacations_Word:"Opprett den første <a routerLink='/folder/Vacation/New'>Ferie</a>-oppføringen.",
  Payroll_Schedule:"Lønnsplan",
  Next_Payroll:"Neste lønn:",
  Upcoming_Payroll:"Kommende Lønn",
  No_Upcoming_Payroll:"Ingen kommende lønn",
  Address_Book:"Adressebok",
  Archived_Documents:"Arkiverte dokumenter",
  Dev_Mode:"Applikasjon i utviklingsmodus",
  Administrators:"Administratorer",
  Privacy:"Personvern",
  None:"Ingen",
  Select_Signature:"Velg Signatur",
  No_Signatures:"Ingen signaturer",
  No_Signatures_Word:"Legg til den første <a routerLink='/folder/Signature/New'>signaturen</a>.",
  Repeat_Indefinitely:"Gjenta på ubestemt tid",
  Sign:"Skilt",
  Sign_Here:"Signer her",
  Date_Signed:"Dato signert",
  Signature_Pad:"Signaturblokk",
  Account_Holder:"Kontoinnehaver",
  Account_Properties:"Kontoegenskaper",
  Name_On_Cheque:"Navn på sjekk",
  Server_Hostname:"Server vertsnavn / IP",
  Printers:"Skrivere",
  No_Printers:"Ingen skrivere",
  Printer_Exists:"Det finnes allerede en skriver med dette navnet.",
  No_Printers_Word:"Legg til den aller første <a routerLink='/folder/Printer/New'>skriveren</a>.",
  No_Printer_Alert:"Ingen skriver er definert. Vil du sette opp en skriver?",
  Add_Printer:"Legg til skriver",
  New_Printer:"Ny skriver",
  Printer_Hostname:"Skriverens vertsnavn / IP",
  Printer_Label:"Skriveretikett",
  Printer_Protocol:"Skriverprotokoll",
  Protocol:"Protokoll",
  Email_Print:"E-post",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Stikkontakt",
  Print_Job:"Utskriftsjobb",
  Printed:"Skrevet ut",
  Not_Printed:"Ikke trykt",
  Print_Jobs:"Utskriftsjobber",
  Print_Queue:"Utskriftskø",
  No_Print_Jobs:"Ingen utskriftsjobber",
  No_Prints:"Opprett en ny <a routerLink='/folder/Cheque/New'>sjekk</a> for å skrive ut.",
  Prints:"Utskrifter",
  Find_Printer:"Finn skriver",
  Find_AirPrint_Devices:"Finn AirPrint-enheter",
  Select_Printer:"Velg Skriver",
  System_UI:"System UI",
  Printer:"Skriver",
  Status:"Status",
  Preview:"Forhåndsvisning",
  Enable_Print_Job:"Aktiver utskriftsjobb",
  Queue_Weight:"Køvekt",
  Unlimited:"Ubegrenset",
  Show_Advanced_Printer_Options:"Vis avanserte skriveralternativer",
  Advanced:"Avansert",
  Location:"plassering",
  Note:"Merk",
  Queue_Name:"Kønavn",
  Pages_Printed_Limit:"Utskriftsgrense for sider",
  MultiPage_Idle_Time:"Ventetid på flere sider",
  Page_Count_Limit:"Sideantallsgrense",
  Page_Orientation:"Sideorientering",
  Portrait:"Portrett",
  Landscape:"Landskap",
  Duplex:"Tosidig",
  Double_Sided_Printing:"Dobbelsidet",
  Duplex_Mode:"Dupleksmodus",
  Duplex_Short:"Kort",
  Duplex_Long:"Lang",
  Duplex_None:"Ingen",
  Color_And_Quality:"Farge og kvalitet",
  Monochrome:"Monokrom",
  Photo_Quality_Prints:"Fotokvalitetsutskrifter",
  Printer_Email:"Skriverens e-post",
  Automatically_Downsize:"Automatisk nedbemanning",
  Paper:"Papir",
  Paper_Name:"Papirnavn",
  Paper_Width:"Papirbredde",
  Paper_Height:"Papirhøyde",
  Paper_Autocut_Length:"Papir autokuttelengde",
  Margins:"Marginer",
  Page_Margins:"Sidemarger",
  Page_Margin_Top:"Toppsidemargin",
  Page_Margin_Right:"Høyre sidemarg",
  Page_Margin_Bottom:"Margen på nederste side",
  Page_Margin_Left:"Venstre sidemarg",
  Add_Employees:"Legg til ansatte",
  Header:"Overskrift",
  Print_A_Page_Header:"Skriv ut en sidehode",
  Header_Label:"Overskriftsetikett",
  Header_Page_Index:"Toppsideindeks",
  Header_Font_Name:"Overskriftsskrift",
  Select_Font:"Velg Font",
  Font_Selector:"Fontvelger",
  Header_Font_Size:"Overskriftsskrift",
  Header_Bold:"Topptekst fet",
  Header_Italic:"Overskrift kursiv",
  Header_Alignment:"Overskriftsjustering",
  Left:"Venstre",
  Center:"Senter",
  Right:"Ikke sant",
  Justified:"Begrunnet",
  Header_Font_Color:"Overskriftsfarge",
  Select_Color:"Velg Farge",
  Footer:"Bunntekst",
  Print_A_Page_Footer:"Skriv ut en sidebunntekst",
  Footer_Label:"Bunntekstetikett",
  Footer_Page_Index:"Bunntekst sideindeks",
  Footer_Font_Name:"Bunntekst skrift",
  Fonts:"Skrifter",
  Done:"Ferdig",
  Select_Fonts:"Velg Skrifter",
  Footer_Font_Size:"Bunntekststørrelse",
  Footer_Bold:"Bunntekst fet",
  Footer_Italic:"Bunntekst kursiv",
  Footer_Alignment:"Bunntekstjustering",
  Footer_Font_Color:"Bunntekstfarge",
  Page_Copies:"Sidekopier",
  Enable_Printer:"Aktiver skriver",
  Remote_Logging:"Ekstern logging",
  Log_Server:"Loggserver",
  Encryption:"Kryptering",
  Random_Key:"Tilfeldig nøkkel",
  Encryption_Key:"Krypteringsnøkkel",
  Cheques_Webserver:"Egendefinert database",
  Learn_How:"Lær hvordan",
  Signature:"Signatur",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Se signatur",
  Printed_Signature:"Trykt signatur",
  Digitally_Sign:"Signer digitalt",
  Digital_Signature:"Digital signatur",
  Add_Signature:"Legg til signatur",
  Add_Your_Signature:"Legg til signaturen din",
  Enable_Signature:"Aktiver signatur",
  Digitally_Signed:"Digitalt signert",
  Insert_Error:"Kan ikke lagre sjekken på grunn av databaseproblemer.",
  Delete_Confirmation:"Er du sikker på at du vil slette denne informasjonen?",
  Discard_Confirmation:"Er du sikker på at du vil forkaste denne informasjonen?",
  Discard_Bank_Confirmation:"Er du sikker på at du vil forkaste denne kontoen?",
  Discard_Printer_Confirmation:"Er du sikker på at du vil forkaste denne skriveren?",
  Delete_Bonus_Confirmation:"Er du sikker på at du vil slette denne bonusen?",
  Delete_Invoice_Confirmation:"Er du sikker på at du vil slette denne fakturaen?",
  Generated_Cheque:"Generert sjekk",
  Generated_Invoice:"Generert faktura",
  Schedule_Start_Time:"Planlegg start",
  Schedule_End_Time:"Tidsplan slutt",
  New_Call:"Ny samtale",
  No_Contacts:"Ingen kontakter",
  No_Contacts_Word:"Administratorer, moderatorer, ansatte og betalingsmottakere vises som kontakter.",
  PDF_Subject:"økonomi",
  PDF_Keywords:"lønnsslipp PDF-sjekksjekker",
  Printer_Setup:"Skriveroppsett",
  Printer_Selection:"Skrivervalg",
  New_Fax:"Ny faks",
  New_Fax_Message:"Ny faksmelding",
  Fax_Machine:"Faksmaskin",
  Fax_Name:"Faksnavn",
  Fax_Email:"Faks e-post",
  Fax_Number:"Faksnummer",
  Contents:"Innhold",
  Fax_Body:"Sidetekst",
  Header_Word:"Overskrift:",
  Header_Text:"Overskriftstekst",
  Include_Header:"Inkluder overskrift",
  Include_Footer:"Inkluder bunntekst",
  Footer_Word:"Bunntekst:",
  Footer_Text:"Bunntekst",
  Attach_a_Cheque:"Legg ved en sjekk",
  Add_Deduction:"Legg til fradrag",
  Enable_Fax:"Send faks",
  Select_Fax:"Velg Faks",
  No_Faxes:"Ingen fakser",
  Faxes:"Fakser",
  Save_and_Send:"Send faks",
  Save_and_Pay:"Spar og betal",
  WARNING:"ADVARSEL:",
  Remember:"Huske",
  Printing:"Printing",
  Printing_Complete:"Utskriften er fullført!\n\n",
  of:"av",
  There_Were:"Det var",
  Successful_Prints:"vellykkede utskrifter og",
  Unsuccessful_Prints:"mislykkede utskrifter.",
  PrinterError:"Beklager! Det var en feil.",
  Printing_:"Printing...",
  PrinterSuccess:"Dokumentet ble skrevet ut.",
  PrintersSuccess:"Dokumenter ble skrevet ut.",
  New_Message:"Ny melding",
  New_Messages:"Nye meldinger",
  Read_Message:"Les melding",
  Write_Message:"Skriv melding",
  Send_Message:"Sende melding",
  Subject:"Emne",
  Message:"Beskjed",
  Writing:"Skriver...",
  Send:"Sende",
  Set_Date:"Sett dato",
  Set_Time:"Still inn tid",
  Recieve:"Motta",
  Set_as_Default:"Satt som standard",
  Default_Account:"Standard konto",
  Default_Design:"Standard design",
  Multiple_Cheques:"Tre sjekker",
  Account_Mode:"Kontomodus",
  Multiple_Cheques_Description:"Tre sjekker per side.",
  Check_and_Table:"Sjekk & Tabell",
  Check_including_Table:"Sjekk med regnskapstabell.",
  Check_Mailer:"Sjekk Mailer",
  Check_Mailer_Window:"Sjekk med et adressevindu.",
  DocuGard_Table_Top:"Stor sjekk og bord",
  DocuGard_Table_Middle:"Stor sjekk og bord (midt)",
  DocuGard_Table_Bottom:"Stor sjekk og bord (nederst)",
  DocuGard_Mailer_Top:"Stor sjekkpost",
  DocuGard_Mailer_Middle:"Stor sjekkpost (midt)",
  DocuGard_Mailer_Bottom:"Stor sjekkpost (nederst)",
  DocuGard_Three_Cheques:"Tre store sjekker",
  DocuGard_Cheque_Top:"Enkel stor sjekk",
  DocuGard_Cheque_Middle:"Stor sjekk (midt)",
  DocuGard_Cheque_Bottom:"Stor sjekk (bunn)",
  DocuGard_Three_Cheques_Window:"Tre sjekker på én side.",
  DocuGard_Table_Top_Window:"Sjekk- og inntektstabell.",
  DocuGard_Table_Middle_Window:"Sjekk- og inntektstabell.",
  DocuGard_Table_Bottom_Window:"Sjekk- og inntektstabell.",
  DocuGard_Mailer_Top_Window:"Sjekk, bord og adresse.",
  DocuGard_Mailer_Middle_Window:"Sjekk, bord og adresse.",
  DocuGard_Mailer_Bottom_Window:"Sjekk, bord og adresse.",
  DocuGard_Cheque_Top_Window:"Se etter sikkert papir.",
  DocuGard_Cheque_Middle_Window:"Se etter sikkert papir.",
  DocuGard_Cheque_Bottom_Window:"Se etter sikkert papir.",
  Basic_Cheque:"Enkeltsjekk",
  Basic_Cheque_Print:"Skriv ut en enkelt sjekk.",
  Error_Setting_As_Paid:"Feil ved innstilling som betalt",
  Add_Attachment:"Legg til vedlegg",
  PrinterUnavailable:"Skriver utilgjengelig",
  CreditCardComponent:"Kort",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Nytt innskudd",
  Deposits:"Innskudd",
  No_Deposits:"Ingen innskudd",
  Credit_Card_Deposit:"Kredittkort",
  New_Credit_Card_Deposit_Message:"Kredittkortinnskudd",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin-innskudd",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Interac-overføring",
  Payments_Limit:"Betalingsgrense",
  No_Payment_Limit:"Ingen betalingsgrense",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal-innskudd",
  No_Deposits_Word:"Legg til det første selskapet <a routerLink='/folder/Deposit/New'>Innskudd</a>.",
  No_Documents_Specified:"Ingen dokumenter ble spesifisert for utskrift",
  No_Printers_Added:"Ingen skrivere ble funnet. Gå til Innstillinger > Skrivere for å legge til en.",
  DB_Erase_Prompt:"Vil du slette hele databasen? ADVARSEL: Du vil miste all lagret informasjon!",
  Console_Warning:"Ikke lim inn noen tekst i denne konsollen. Du kan sette deg selv og/eller din bedrift i alvorlig fare.",
  No_Faxes_Word:"Opprett den første <a routerLink='/folder/Fax/New'>faksen</a>.",
  Cheque_Delete_Confirmation:"Er du sikker på at du vil slette denne sjekken?",
  Design_Delete_Confirmation:"Er du sikker på at du vil slette dette designet?",
  Cheque_Pay_Confirmation:"Vil du merke denne sjekken som betalt? Det vil IKKE vises i utskriftskøen.",
  Payment_Pay_Confirmation:"Vil du merke denne betalingen som betalt? Det vil IKKE vises i sjekkkøen.",
  Delete_Deduction_Confirmation:"Er du sikker på at du vil slette dette fradraget?",
  Delete_Job_Confirmation:"Er du sikker på at du vil slette denne jobben?",
  Delete_Timesheet_Confirmation:"Er du sikker på at du vil slette denne timelisten?",
  Delete_Schedule_Confirmation:"Er du sikker på at du vil slette denne tidsplanen?",
  Delete_Setting_Confirmation:"Er du sikker på at du vil tilbakestille denne innstillingen?",
  Delete_Fax_Confirmation:"Er du sikker på at du vil slette denne faksen?",
  Delete_File_Confirmation:"Er du sikker på at du vil slette denne filen?",
  Delete_Vacation_Confirmation:"Er du sikker på at du vil slette denne ferien?",
  Delete_Printer_Confirmation:"Er du sikker på at du vil slette denne skriveren?",
  Remove_Design_Confirmation:"Er du sikker på at du vil slette dette designet?",
  Delete_Payroll_Confirmation:"Er du sikker på at du vil slette denne lønnslisten?",
  Send_Fax_Email_Confirmation:"Vil du fakse og sende disse dokumentene via e-post?",
  Send_Email_Confirmation:"Vil du sende dette dokumentet på e-post?",
  Send_Fax_Confirmation:"Vil du fakse dette dokumentet?",
  Error_Generating_PDF:"Beklager. Det oppsto en feil under generering av dette dokumentet.",
  PDF_Error_Saving_Image:"Beklager. Det oppsto en feil under lagring av PDF-bilde av dette dokumentet.",
  Test_Printer_Confirmation:"Vil du skrive ut en testside på denne skriveren?",
  Save_Timesheet_Prompt:"Legg til en 'Tittel' eller trykk 'Start Timer' for å lagre.",
  Remove_Geofence_Prompt:"Er du sikker på at du vil fjerne plasseringen til dette geo-gjerdet?",
  Delete_Employee_Confirmation:"Er du sikker på at du vil slette",
  Fire_Employee_Confirmation:"Er du sikker på at du vil skyte",
}