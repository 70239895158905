export const Hy = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Հեղինակային իրավունք & պատճեն; 2023 թ",
  black:"Սեվ",
  green:"Կանաչ",
  gold:"Ոսկի",
  blue:"Կապույտ",
  brown:"Շագանակագույն",
  purple:"Մանուշակագույն",
  pink:"Վարդագույն",
  red:"Կարմիր",
  Swatches:"Սալաթներ",
  HSL:"Խոտամածշ",
  RGB:"RGB",
  Hue:"Երանգ",
  Saturation:"Հագեցում",
  Lightness:"Թեթեվություն",
  Upgrade_To_Pro:"Անցնել ՊՐՈ տարբերակին",
  AllFeaturesInclude:"Բոլոր հատկանիշները ներառում են.",
  PrintUnlimitedCheques:"Տպել անսահմանափակ չեկեր",
  PremiumChequeDesigns:"Պրեմիում չեկերի նմուշներ",
  ManageUnlimitedEmployees:"Կառավարեք անսահմանափակ աշխատակիցներ",
  AddUnlimitedPayees:"Ավելացնել անսահմանափակ վճարողներ",
  CreateUnlimitedPayrolls:"Ստեղծեք անսահմանափակ աշխատավարձ",
  UnlimitedSchedulesandTimeSheets:"Անսահմանափակ ժամանակացույցեր և ժամանակացույցեր",
  BulkPayPalPayouts:"Զանգվածային PayPal վճարումներ",
  UnlimitedBankAccounts:"Անսահմանափակ բանկային հաշիվներ",
  ManageMultipleCompanies:"Կառավարեք բազմաթիվ ընկերություններ",
  TrackInsurancePolicies:"Հետևեք ապահովագրական քաղաքականությանը",
  Bio_MetricProtection:"Կենսաչափական պաշտպանություն",
  Geo_FenceLock_OutProtection:"Geo-Fence Lock-Out պաշտպանություն",
  Multiple_Companies_Word:"Բազմաթիվ ընկերությունների կառավարումը հասանելի չէ առանց չեկերի պրեմիումի:",
  PayPal_Payouts_Word:"PayPal վճարումները անջատված են առանց չեկերի պրեմիումի:",
  PINProtection:"PIN-ի պաշտպանություն",
  PasswordProtection:"Գաղտնաբառի պաշտպանություն",
  May_Require_Approval:"Կարող է պահանջվել հաստատում:",
  Subscribe:"Բաժանորդագրվել",
  Billed:"Հաշվարկված",
  Up:"Վերև",
  Down:"Ներքև",
  Positioning:"Դիրքորոշում",
  Marker:"Մարկեր",
  Drag_Marker:"Քաշել Մարկերը",
  Tagline:"Տպել չեկերը և աղյուսակավորել աշխատավարձերի ցուցակը",
  Marker_Word:"Օգտագործեք Մարկերները տարրը չափելու համար:",
  Pinch_Zoom:"Պտտեցնել Zoom",
  Pinch_Word:"Կտտացրեք տարրը մեծացնելու համար:",
  Drag:"Քաշել",
  Drag_Word:"Օգտագործեք ձեր մատը տարրերը քաշելու համար:",
  subscription_alias_word:"Ավտոմատ թարմացվող բաժանորդագրություն",
  premium_alias_word:"Միանգամյա արդիականացման փաթեթ",
  print_alias_word:"Տպագիր անհատական ստուգումներ",
  mode_alias_word:"Ռեժիմ",
  Pro:"Pro",
  Pro_word:"Պահանջվում է Pro տարբերակը:",
  Cant_Delete_Default_Company_Word:"Ներողություն, դուք չեք կարող ջնջել ձեր լռելյայն ընկերությունը:",
  Reinsert_Default_Designs:"Կրկին տեղադրեք լռելյայն նմուշները",
  Reinsert_Default_Designs_word:"Ցանկանու՞մ եք նորից զետեղել լռելյայն նմուշները:",
  Subscription_Active_Disable_Word:"Այս բաժանորդագրությունն ակտիվ է: Ցանկանու՞մ եք չեղարկել չեկերի այս բաժանորդագրությունը:",
  Company_Logo:"Ընկերության լոգոն",
  ZERO_:"ԶՐՈ",
  Disclaimer:"Հրաժարում պատասխանատվությունից",
  Privacy_Policy:"Գաղտնիության քաղաքականություն",
  EULA:"Ստուգում է EULA-ն",
  Terms_Of_Service:"Ծառայությունների մատուցման պայմաններ",
  Terms_Of_Use:"Օգտվելու կանոններ",
  Refunds:"Փոխհատուցման քաղաքականություն",
  Single_Print:"1 Ստուգեք",
  Two_Prints:"2 Չեկեր",
  Five_Prints:"5 Ստուգումներ",
  Ten_Prints:"10 Չեկեր",
  Fifteen_Prints:"15 Չեկեր",
  Twenty_Prints:"20 Չեկեր",
  Thirty_Prints:"30 Չեկեր",
  Image_Added:"Պատկերն ավելացված է",
  Image_Preview:"Պատկերի նախադիտում",
  No_Image_Was_Selected:"Պատկեր չի ընտրվել:",
  Cheques_Unlimited:"Չեկեր անսահմանափակ",
  _Subscription:"Բաժանորդագրություն",
  Subscription:"Չեկեր - 1 ամիս",
  Two_Month_Subscription:"Չեկեր - 2 ամիս",
  Three_Month_Subscription:"Չեկեր - 3 ամիս",
  Six_Month_Subscription:"Չեկեր - 6 ամիս",
  Twelve_Month_Subscription:"Չեկեր - 12 ամիս",
  Cheques_Are_Available:"Չեկերը հասանելի են տպելու համար:",
  Upgrade_Required_Two:"Ընտրեք փաթեթ և կրկնակի հպեք գնի կոճակին՝ ձեր գնումը սկսելու համար: Տպեք պրոֆեսիոնալ տեսք ունեցող ամբողջական գունավոր ստուգումներ վայրկյանների ընթացքում:",
  Month:"Ամիս",
  Due:"Պայմանավորված:",
  Expires:"Ժամկետը լրանում է.",
  Subscription_Active:"Բաժանորդագրությունն ակտիվ է",
  Subscription_Inactive:"Բաժանորդագրությունն անգործուն է",
  Purchase_Additional_Cheques:"Գնե՞լ լրացուցիչ չեկեր:",
  Printable_Cheque:"Տպագրվող ստուգում",
  Printable_Cheques:"Տպագրվող չեկեր",
  Printable_Cheque_Word:"ստուգումը հասանելի է ձեր հաշվի վրա:",
  Printable_Cheques_Word:"չեկերը հասանելի են ձեր հաշվի վրա:",
  Max_Amount_Message:"Ձեր նշած գումարը գերազանցել է այս համակարգի համար սահմանված առավելագույն գումարը՝",
  Terms_Required_Word:"Դուք պետք է համաձայնեք այս համաձայնագրին նախքան չեկերի օգտագործումը շարունակելը:",
  Subscriptions:"Բաժանորդագրություններ",
  Product_Upgrades:"Թարմացումներ",
  Mailed_Out_Cheques:"Փոստով ուղարկված չեկեր",
  Enter_A_Company_Name:"Խնդրում ենք մուտքագրել ընկերության անվանումը:",
  Single_Cheques:"Միայնակ չեկեր",
  Cheque_Golden:"Ոսկե չեկ",
  Cheque_Golden_Window:"Ոսկե չեկի ձևավորում.",
  Cheque_Green:"Կանաչ ստուգում",
  Cheque_Green_Window:"Կանաչ չեկի ձևավորում:",
  Cheque_Red:"Կարմիր ստուգում",
  Cheque_Red_Window:"Կարմիր չեկի ձևավորում.",
  Cheque_Yellow:"Դեղին ստուգում",
  Cheque_Yellow_Window:"Դեղին չեկի ձևավորում:",
  Cheque_Statue_of_Liberty:"Ազատության արձան",
  Cheque_Statue_of_Liberty_Window:"Ազատության արձանի չեկի դիզայն.",
  Cheque_Green_Wave:"Կանաչ ալիք",
  Cheque_Green_Wave_Window:"Կանաչ չեկի ձևավորում:",
  Cheque_Golden_Weave:"Ոսկե հյուսվածք",
  Cheque_Golden_Weave_Window:"Ոսկե չեկի էլեգանտ դիզայն։",
  Cheque_Green_Sun:"Կանաչ արև",
  Cheque_Green_Sun_Window:"Խորը և հանգստացնող ստուգման ձևավորում:",
  Cheque_Blue_Checkers:"Կապույտ շաշկի",
  Cheque_Blue_Checkers_Window:"Կապույտ չեկի ձևավորում:",
  Cashiers_Check:"ՀԴՄ կտրոն",
  Cashiers_Check_Window:"Cashier's Check ոճի ձևանմուշ:",
  Cheque_Aqua_Checkers:"Aqua շաշկի",
  Cheque_Aqua_Checkers_Window:"Aqua check դիզայն.",
  Cheque_Golden_Checkers:"Ոսկե շաշկի",
  Cheque_Golden_Checkers_Window:"Ոսկե չեկի ձևավորում.",
  Upgrade_Unavailable:"Թարմացումներն անհասանելի են",
  Bank_Code_Protection:"Բանկի համարի պաշտպանություն",
  Bank_Code_Protection_Word:"Պաշտպանեք ձեր բանկի համարները այլ սարքում կամ մեկ այլ օգտատիրոջ համար աշխատող այս հավելվածում օգտագործելուց: Այս գործողությունն ԱՆՎԵՐԱԴԱՐՁԻ Է: Շարունակել?",
  Bank_Code_Protection_Blocked_Word:"Բանկային համարները, որոնք դուք փորձում եք օգտագործել, վերապահված են մեկ այլ օգտատիրոջ կամ սարքի համար:",
  Bank_Code_Protection_Error_Word:"Համարի ստուգումը ձախողվեց: Խնդրում ենք միանալ ինտերնետին և կրկին փորձել ավելացնել այս բանկային հաշիվը:",
  Bank_Code_Protection_Set_Error_Word:"Բանկի համարների պաշտպանությունը պահանջում է, որ դուք միացված լինեք ինտերնետին: Խնդրում ենք միանալ և նորից փորձել:",
  Upgrade_Unavailable_Word:"Ներեցեք, մենք ձեզ հաստատելու խնդիր ունենք: Չեկերի բաժանորդագրություններն ու բարելավումները ներկայումս հասանելի չեն ձեր տարածքում գնելու համար:",
  PayPal_Payment_Preview:"PayPal վճարման նախադիտում",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Ընտրեք PayPal",
  PayPal_Applications:"PayPal հավելվածներ",
  Purchase_With_Apple_Pay:"Գնեք Apple Pay-ով",
  Google_Pay:"Google Pay",
  Companies:"Ընկերություններ",
  Insurance:"Ապահովագրություն",
  New_PayPal:"Նոր PayPal",
  Pay_By:"Վճարել ըստ",
  Insure:"Ապահովագրել",
  Miles:"մղոններ",
  Payment_Method:"Վճարման եղանակ",
  Select_Policies:"Ընտրեք Քաղաքականություն",
  Policies:"Քաղաքականություն",
  Policy:"Քաղաքականություն",
  No_PayPal_Account:"PayPal հաշիվ չկա",
  No_Policies:"Ոչ ապահովագրական քաղաքականություն",
  New_Policy:"Նոր քաղաքականություն",
  PayPal_Payment:"PayPal վճարում",
  PayPal_Payments:"PayPal վճարումներ",
  No_Payment_Selected:"Վճարում չի ընտրվել",
  Sending_Payment_Word:"Խնդրում ենք սպասել... Այս վճարումն ուղարկվում է:",
  Sending_Payments_Word:"Խնդրում ենք սպասել... Վճարումները ուղարկվում են:",
  No_Payment_Selected_PayPal:"<a routerLink='/folder/Payments'>PayPal վճարում</a> ընտրված չէ :",
  Payment_Sent:"Վճարումն ուղարկված է",
  PayPal_Payment_Sent:"Այս վճարումն ուղարկվել է PayPal-ով:",
  Copay:"Համավճար",
  Dead:"Մահացած",
  Alive:"Կենդանի",
  Not_Dead:"Մեռած չէ",
  Unclaimed:"Չպահանջված",
  Attempted:"Փորձ է արվել",
  Deceased:"Մահացած",
  Claimed:"Պնդեց",
  Unpaid:"Չվճարված",
  Sending_Payment:"Վճարման ուղարկում",
  Sending_Payments:"Վճարումների ուղարկում",
  Send_PayPal_Confirmation:"Ցանկանու՞մ եք այս գործարքն ուղարկել PayPal-ով:",
  Send_PayPal_Confirmation_Word:"Այս գործարքն ուղարկելու համար սեղմեք կանաչ կոճակը:",
  Save_Payment_As_Unpaid:"Պահե՞լ այս վճարումը որպես չվճարված:",
  Payment_Pay_Confirmation_PayPal:"Պահե՞լ այս վճարումը որպես վճարովի:",
  No_Policies_Word:"Ավելացրեք առաջին <a routerLink='/folder/Postage/New'>Ապահովագրական քաղաքականությունը</a> հիմա:",
  Timesheet_Multiple_Delete_Confirmation:"Իսկապե՞ս ուզում եք ջնջել մի քանի ժամանակաթերթեր:",
  Select_Multiple_Timesheets_Prompt:"Ժամացույցներ ընտրված չեն: Ընտրեք առնվազն մեկ ժամանակացույց:",
  Select_Multiple_Policies_Prompt:"Ոչ մի քաղաքականություն ընտրված չէ: Ընտրեք առնվազն մեկ ապահովագրական քաղաքականություն:",
  Policies_Multiple_Delete_Confirmation:"Իսկապե՞ս ուզում եք ջնջել մի քանի կանոններ:",
  Company:"Ընկերություն",
  Add_Company:"Ավելացնել ընկերություն",
  New_Company:"Ավելացնել ընկերություն",
  No_Companies:"Ընկերություններ չկան",
  Enable_Company:"Միացնել ընկերությանը",
  Select_Company:"Ընտրեք ընկերություն",
  The_Default_Company:"Ընկերության լռելյայն պիտակը:",
  Manage_Companies:"Կառավարեք ընկերությունները",
  No_Companies_Word:"Չեկերը կօգտագործեն լռելյայն ընկերություն:<br />Ավելացրեք <a routerLink='/folder/Company/New'>առաջին ընկերությունը</a>:",
  Default_Company:"Լռելյայն ընկերություն",
  Cheques_Unlimited_Word:"Checks Unlimited-ը թույլ է տալիս տպել այնքան չեկ, որքան ցանկանում եք:",
  Cheques_Subscription_Word:"Չեկերի բաժանորդագրությունը թույլ է տալիս տպել այնքան չեկ, որքան ցանկանում եք:",
  You_Own_This_Product:"Դուք այս ապրանքի սեփականատերն եք:",
  Your_Subscription_is_Active:"Ձեր բաժանորդագրությունն ակտիվ է:",
  Active_Products:"Ակտիվացված ապրանքներ",
  Purchase_Confirmation:"Գնել",
  Purchase_Cheques:"Գնման չեկեր",
  Restore_Purchase:"Վերականգնել գնումներ",
  Erase_Purchase:"Ջնջել գնումները",
  Successfully_Purchased:"Հաջողությամբ գնված",
  Enter_Your_Licence_Key:"Խնդրում ենք մուտքագրել ձեր լիցենզիայի բանալին այս էջում՝ այս ապրանքն ակտիվացնելու համար:",
  Licence_Key:"Լիցենզիայի բանալի",
  Enter_Licence_Key:"Մուտքագրեք լիցենզիայի բանալին",
  Purchased:"Գնված",
  Enable_Feature:"Միացնել գործառույթը",
  Cancel_Subscription:"Չեղարկել բաժանորդագրությունը",
  Subscription_Removed:"Բաժանորդագրությունը հեռացված է",
  Select_Active_Subscription:"Ընտրեք ակտիվ բաժանորդագրություն՝ այն փոփոխելու համար:",
  Remove_Subscription_Word:"Իսկապե՞ս ուզում եք չեղարկել այս բաժանորդագրությունը:",
  Delete_Company_Confirmation:"Իսկապե՞ս ուզում եք ջնջել այս ամբողջ ընկերությունը: ԶԳՈՒՇԱՑՈՒՄ. Դուք կկորցնեք բոլոր պահված տեղեկությունները:",
  Delete_Default_Company_Word:"Դուք չեք կարող ջնջել լռելյայն ընկերությունը: Ցանկանու՞մ եք վերականգնել հավելվածը և վերականգնել այն լռելյայն վիճակի: ԶԳՈՒՇԱՑՈՒՄ. Դուք կկորցնեք բոլոր պահված տեղեկությունները:",
  Console_Warning_2:"Այս հավելվածի միջոցով մի գործարկեք որևէ արժույթ, որը ներկայումս ձերը չէ:",
  Terms_and_Conditions:"Ընդհանուր դրույթներր եւ պայմանները",
  and_the:"եւ",
  for:"համար",
  Please_Read_Word:"Խնդրում ենք կարդալ և համաձայնել",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Որոշ արժույթի փոխարկման փոխարժեքներ չհաջողվեց գտնել: Խնդրում ենք միանալ ինտերնետին:",
  Free:"Անվճար",
  DB_Erase_Prompt_2:"Ամբողջությամբ ջնջե՞լ մշակողի տվյալների բազան: ԶԳՈՒՇԱՑՈՒՄ. Դուք կկորցնեք ԳՆԵԼՈՒ և ԲԱԺԱՆՈՐԴԱԳՐՈՒԹՅԱՆ մասին բոլոր տեղեկությունները:",
  Successfully_Imported:"Հաջողությամբ ներմուծվել է",
  Select_Postage:"Ընտրեք փոստային առաքում",
  No_Postage:"Փոստային նամականիշեր չկան",
  No_Paid_Postage_Word:"Ավելացրեք առաջին <a routerLink='/folder/Postage/New'>Վճարովի փոստային առաքում</a> նամականիշը:",
  Trial_Complete:'Փորձարկումն ավարտված է',
  Please_Upgrade:'Խնդրում ենք թարմացնել չեկերը՝ տպագրությունը շարունակելու համար:',
  Aa:"Աա",
  Color:"Գույն",
  Font:"Տառատեսակ",
  Guide:"Ուղեցույց",
  Guides:"Էքսկուրսավարներ",
  Image:"Պատկեր",
  Zoom:"Մեծացնել",
  Logo:"Լոգոն",
  Bank:"Բանկ",
  MICR:"MICR",
  Total:"Ընդամենը",
  Cancel:"Չեղարկել",
  Confirm:"Հաստատել",
  Method:"Մեթոդ",
  Biometric_Security:"Կենսաչափական անվտանգություն",
  Please_Login_To_Continue:"Շարունակելու համար խնդրում ենք մուտք գործել:",
  Complete:"Ամբողջական",
  Sign_Up:"Գրանցվել",
  Error:"Սխալ",
  Biometrics:"Կենսաչափություն",
  Percent:"տոկոս",
  Zero_Percent:"0%",
  Top_Margin:"Վերևի լուսանցք",
  Bottom_Margin:"Ներքևի լուսանցք",
  Left_Margin:"Ձախ լուսանցք",
  Right_Margin:"Աջ լուսանցք",
  MICR_Margin:"MICR մարժան",
  Table_Margin:"Սեղանի լուսանցք",
  Address_Margin:"Հասցեի լուսանցք",
  Percentage_:"Տոկոսը",
  Vacation_Title:"Արձակուրդի կոչում",
  Use_PIN:"Օգտագործեք PIN կոդը",
  Loading__:"Բեռնվում է...",
  Design_Title:"Դիզայնի անվանումը",
  Authorize:"Լիազորել",
  Key:"Բանալի",
  Public_Key:"Հանրային բանալի",
  Private_Key:"Անձնական բանալի",
  Authenticate:"Նույնականացնել",
  Last_Payroll:"Վերջին աշխատավարձը",
  Last_Calculation:"Վերջին հաշվարկ",
  Authorized:"Լիազորված",
  Geo_Authorized:"Աշխարհագրական դիրք՝ լիազորված",
  Not_Authorized:"Լիազորված չէ",
  Authorization_Complete:"Լիազորումն ավարտված է",
  Geolocation_Authorization:"Աշխարհագրական դիրքի թույլտվություն",
  Out_of_Bounds:"Սահմաններից դուրս",
  Cant_Delete_Default_Design:"Հնարավոր չէ ջնջել լռելյայն դիզայնը:",
  Unauthorized_Out_of_Bounds:"Չլիազորված. սահմաններից դուրս",
  Biometric_Authorization:"Կենսաչափական թույլտվություն",
  Locating_Please_Wait:"Գտնվում է, խնդրում ենք սպասել...",
  Test_Biometrics:"Կենսաչափական փորձարկում",
  Cheque_Margins:"Ստուգեք մարժան",
  Percentage_Full_Error:"Տոկոսային դաշտը չի կարող սահմանվել 100% տոկոսից ավելի:",
  No_Payroll_Text:"Ավելացրեք <a routerLink='/folder/Employee/New'>Աշխատող</a> կամ <a routerLink='/folder/Payee/New'>վճարառու</a> և <a routerLink='/folder/Schedule/New'>Ժամանակացույց</a>:",
  Design_Saved:"Դիզայնը պահպանված է",
  Default_Design_Selected:"Ընտրված է լռելյայն դիզայն",
  Partial_Import:"Մասնակի ներմուծում",
  Partial_Export:"Մասնակի արտահանում",
  Entire_Import:"Ամբողջական ներմուծում",
  Entire_Export:"Ամբողջական արտահանում",
  Existing_Password:"Խնդրում ենք մուտքագրել ձեր գոյություն ունեցող գաղտնաբառը.",
  Existing_PIN:"Խնդրում ենք մուտքագրել ձեր առկա PIN կոդը.",
  Pin_Change_Header:"PIN-ի հաստատում",
  Pin_Change_SubHeader:"Փոփոխությունը հաստատելու համար մուտքագրեք ձեր հին PIN համարը:",
  Pass_Change_Header:"Գաղտնաբառի հաստատում",
  Pass_Change_SubHeader:"Փոփոխությունը հաստատելու համար մուտքագրեք ձեր հին գաղտնաբառը:",
  PIN_Enter_Message:"Մուտքագրեք ձեր PIN կոդը հաստատելու համար:",
  Password_Enter_Message:"Մուտքագրեք ձեր գաղտնաբառը՝ հաստատելու համար:",
  Pin_Updated_Success:"PIN-ը հաջողությամբ թարմացվեց:",
  Pin_Updated_Fail:"PIN-ը չհաջողվեց թարմացնել:",
  Pass_Updated_Success:"Գաղտնաբառը հաջողությամբ թարմացվել է:",
  Pass_Updated_Fail:"Գաղտնաբառը չհաջողվեց թարմացնել:",
  Preview_Payment:"Նախադիտում վճարում",
  Preview_Payroll:"Աշխատավարձի նախադիտում",
  No_Payments_Created:"Ոչ մի վճարում չի հայտնաբերվել:",
  Payment_Preview:"Վճարման նախադիտում",
  Enable_Payee:"Միացնել վճարման ստացողը",
  Rendered:"Կատարված է",
  No_Email:"Էլփոստ չկա",
  Setup_Cheques:"Կարգավորման ստուգումներ",
  name:"Անուն",
  address:"Հասցե",
  address_2:"Հասցեի տող 2",
  city:"Քաղաք",
  province:"Նահանգ",
  postal_code:"Փոստային/Փոստային ինդեքս",
  country:"Երկիր",
  username:"Օգտագործողի անունը",
  full_name:"Ամբողջական անուն",
  birthday:"Ծննդյան օր",
  email:"Էլ",
  phone:"Հեռախոս",
  employees:"Աշխատակիցներ",
  addresses:"Հասցեներ",
  payment_amount_limit:"Վճարման չափի սահմանաչափ",
  total_limit:"Ընդհանուր սահմանաչափ",
  payees:"Վճարվողներ",
  description:"Նկարագրություն",
  address_line_one:"Հասցեի տող առաջին",
  address_line_two:"Հասցեի տող երկրորդ",
  image:"Պատկեր",
  account_holder:"Հաշվի սեփականատեր",
  cheque_starting_id:"Ստուգեք մեկնարկային ID-ն",
  transit_number:"Տարանցիկ համարը",
  institution_number:"Հաստատության համարը",
  designation_number:"Նշանակման համարը",
  account_number:"Հաշվեհամար",
  currency:"Արժույթ",
  signature:"Ստորագրություն",
  payment_payroll_limit:"Վճարման սահմանաչափ",
  total_limi:"Ընդհանուր սահմանաչափ",
  date:"Ամսաթիվ",
  printed_memo:"Տպագիր Հուշագիր",
  banks:"Բանկեր",
  signature_image:"Ստորագրության պատկեր",
  address_name:"Հասցեի անվանումը",
  notes:"Նշումներ",
  design:"Դիզայն",
  title:"Կոչում",
  frequency:"Հաճախականություն",
  fax:"Ֆաքս",
  salaries:"Աշխատավարձեր",
  salary_ids:"Աշխատավարձի վկայականներ",
  start_time:"Մեկնարկի ժամանակը",
  end_time:"Ավարտի ժամանակը",
  paid:"Վճարված",
  overtime_percentage:"Վճարված տոկոս",
  overtime_amount:"Վճարված հաստատագրված գումար",
  first_name:"Անուն",
  last_name:"Ազգանուն",
  moderation:"Չափավորություն",
  create:"Ստեղծել",
  edit:"Խմբագրել",
  destroy:"Ոչնչացնել",
  day_start_time:"Day_start_time",
  day_end_time:"Օր_վերջ_ժամ",
  fullname:"Անուն",
  time:"Ժամանակը",
  auto_send:"Ավտոմատ ուղարկել",
  time_method:"Ժամանակի մեթոդ",
  schedules:"Ժամանակացույցեր",
  indefinite_payroll_enabled:"Միացնել անորոշ ժամանակով",
  amount:"Գումարը",
  repeat:"Կրկնել",
  always_enabled:"Միշտ միացված է",
  start_date:"Մեկնարկի ամսաթիվ",
  end_date:"Ավարտի ամսաթիվ",
  Cheque_Total:"Ստուգեք Total",
  Total_Amount:"Ընդհանուր գումարը:",
  Amounts:"Գումարները:",
  Images:"Պատկերներ",
  Files:"Ֆայլեր",
  Previewing:"Նախադիտում.",
  Insert:"Տեղադրեք",
  Preview_Import:"Նախադիտման ներմուծում",
  Entry:"Մուտք",
  Entries:"Գրառումներ",
  No_Entries:"Գրառումներ չկան",
  Import_Type:"Ներմուծման տեսակը",
  Select_Details:"Ընտրեք Մանրամասն",
  Select_Payee:"Ընտրեք վճարողին",
  Enable_Holidays:"Միացնել արձակուրդները",
  Disable_Holidays:"Անջատել արձակուրդները",
  Wire_Transfer:"Մետաղական փոխանցում",
  New_Export:"Նոր արտահանում",
  Export_Data:"Արտահանման տվյալները",
  Export_Data_Word:"Ընտրեք արտահանման և ներբեռնման համար ֆայլի տեսակը:",
  Export_File:"Արտահանել ֆայլ",
  Mode:"Ռեժիմ",
  Times:"Ժամանակներ",
  Widgets:"Վիջեթներ",
  Slider:"Slider",
  Set_Details:"Սահմանել մանրամասները",
  Select_Type:"Ընտրեք տեսակը",
  Display_Slider:"Ցուցադրման սահիչ",
  Dashboard_Slider:"Վահանակի սահիչ",
  Dashboard_Mode:"Վահանակի ռեժիմ",
  Show_Intro:"Ցույց տալ ներածությունը",
  Show_Payrolls:"Ցույց տալ Աշխատավարձերը",
  Show_Holidays:"Ցույց տալ արձակուրդներ",
  Show_Invoices:"Ցույց տալ հաշիվ-ապրանքագրերը",
  Show_Cheques:"Ցույց տալ չեկերը",
  Enabled_Widgets:"Միացված վիդջեթներ",
  Disabled_Widgets:"Հաշմանդամ վիդջեթներ",
  Colors:"Գույներ",
  Barcodes:"Շտրիխ կոդեր",
  View_Timers:"Դիտեք ժամանակաչափերը",
  Gradients:"Գրադիենտներ",
  No_Info:"Տեղեկություն չկա",
  Disable:"Անջատել",
  Show_Layer:"Ցույց տալ շերտերը",
  Hide_Layer:"Թաքցնել շերտերը",
  Text_Layer:"Տեքստի շերտեր",
  Secondly:"Երկրորդ",
  Minutely:"Րոպե",
  nine_am:"9:00",
  five_pm:"17:00",
  Enable_Address:"Միացնել հասցեն",
  Invalid_File_Type:"Ներողություն, ընտրվել է անվավեր ֆայլի տեսակ: Աջակցվող ֆայլի տեսակը.",
  Error_Updating_Entry:"Ներողություն, այս գրառումը թարմացնելիս սխալ տեղի ունեցավ:",
  Schedule_Timing_Alert:"Սխալ․ ժամանակացույցի մեկնարկի ժամը սահմանվում է ավարտի ժամանակից հետո արժեքի։",
  Select_Multiple_Payments_Prompt:"Վճարումներ չեն ընտրվել: Ընտրեք առնվազն մեկ վճարում:",
  Select_Multiple_Cheques_Prompt:"Չեկեր չեն ընտրվել: Խնդրում ենք ընտրել առնվազն մեկ ստուգում:",
  Select_Multiple_Items_Prompt:"Ընտրված տարրեր չկան: Խնդրում ենք ընտրել առնվազն մեկ տարր:",
  Paymemt_Multiple_Delete_Confirmation:"Իսկապե՞ս ուզում եք ջնջել մի քանի վճարումներ:",
  Cheque_Multiple_Delete_Confirmation:"Իսկապե՞ս ուզում եք ջնջել մի քանի չեկեր:",
  Payee_Multiple_Delete_Confirmation:"Վստա՞հ եք, որ ցանկանում եք ջնջել մի քանի վճարողներ:",
  Employee_Multiple_Delete_Confirmation:"Իսկապե՞ս ուզում եք ջնջել մի քանի աշխատակիցների:",
  MICR_Warning:"Նշում. որոշ տպիչներ և սարքեր կարող են ճիշտ չցուցադրել MICR տառատեսակը:",
  Automatically_Send:"Ավտոմատ ուղարկել",
  Type:"Տիպ",
  Payment_Type:"Վճարման տեսակ",
  Auto_Send:"Ավտոմատ ուղարկել",
  Automatically_Process:"Ավտոմատ մշակում",
  Auto_Process:"Ավտոմատ գործընթաց",
  Image_Based:"Պատկերի վրա հիմնված",
  Font_Based:"Տառատեսակի վրա հիմնված",
  Rerender:"Վերարտադրել",
  Rendering:"Ներկայացում",
  Render:"Ֆայլեր",
  Top:"Գագաթ",
  Middle:"Միջին",
  Bottom:"Ներքևում",
  Top_Left:"Վերևի ձախ",
  Top_Center:"Top Center",
  Top_Right:"Վերևի աջ",
  Middle_Left:"Միջին Ձախ",
  Middle_Center:"Միջին Կենտրոն",
  Middle_Right:"Միջին Աջ",
  Bottom_Left:"Ներքևի ձախ",
  Bottom_Center:"Ներքևի կենտրոն",
  Bottom_Right:"Ներքևի աջ",
  Numbers:"Թվեր",
  Verified:"Ստուգված է",
  Paper_Size:"Թղթի չափը",
  New_Device:"Նոր Սարք",
  Add_Device:"Սարքի ավելացում",
  Remove_Device:"Հեռացնել սարքը",
  Delete_Device:"Ջնջել Սարքը",
  Block_Device:"Արգելափակել սարքը",
  Block:"Արգելափակել",
  Unblock:"Արգելափակել",
  Table:"Աղյուսակ",
  Scan_Login_Code:"Սկանավորեք մուտքի կոդը",
  Login_Code:"Մուտքի կոդը",
  Scan_Code:"Սկան կոդը",
  Scan_QR_Code:"Սկանավորեք QR կոդը",
  Select_All:"Ընտրել բոլորը",
  Deselect_All:"Ապաընտրել բոլորը",
  Increase:"Աճ",
  Decrease:"Նվազեցնել",
  Bold:"Համարձակ",
  Text:"Տեքստ",
  Style:"Ոճ",
  Italic:"Շեղագիր",
  QR_Code:"QR կոդ",
  Barcode:"Շտրիխ կոդ",
  Browse_Images:"Թերթիր պատկերները",
  Browse_Files:"Թերթիր Ֆայլեր",
  Background_Image:"Ֆոնային պատկեր",
  Logo_Image:"Լոգոյի պատկեր",
  Header_Image:"Վերնագրի պատկեր",
  Bank_Image:"Բանկի պատկեր",
  Cut_Lines:"Կտրված գծեր",
  Background:"Նախապատմություն",
  License:"Լիցենզիա",
  One_License:"1 Լիցենզիա:",
  Licensed:"Լիցենզավորված՝",
  Not_Licensed:"Լիցենզավորված չէ",
  Enter_Serial:"Մուտքագրեք Serial",
  Serial_Key:"Սերիական բանալի",
  Serial:"Սերիալ",
  Product_Key:"Ապրանքի բանալի",
  Check_Product_Key:"Ստուգեք ապրանքի բանալին",
  Add_Product_Key:"Ավելացնել ապրանքի բանալի",
  Verifying_Purchase:"Գնման ստուգում...",
  Print_Envelope:"Տպել ծրար",
  Envelope:"Ծրար",
  Thank_You:"Շնորհակալություն!",
  Scale:"Սանդղակ",
  Print_Scale:"Տպման սանդղակ",
  Borders:"Սահմաններ",
  VOID:"ԱՆՎԱՐ",
  Void_Cheque:"Անվավեր ստուգում",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ՎՃԱՐԵԼ ՊԱՏՎԵՐԻՆ.",
  NO_DOLLARS:"ՈՉ ԴՈԼԱՐ ",
  ONE_DOLLAR:"ՄԵԿ ԴՈԼԱՐ",
  DOLLARS:" ԴՈԼԱՐՆԵՐ",
  AND:" ԵՎ ",
  CENTS:" CENTS.",
  NO_:"ՈՉ ",
  ONE_:"ՄԵԿ ",
  AND_NO_:"ԵՎ ՈՉ ",
  _AND_ONE_:"ԵՎ ՄԵԿ ",
  DOLLARS_AND_ONE_CENT:" ԵՎ ՄԵԿ ՍԵՆՏ.",
  AND_NO_CENTS:" ԵՎ ԶՐՈ ՑԵՆՏ։",
  CHEQUE_PRINT_DATE:"DATE:",
  CHEQUE_PRINT_MEMO:"ՀԻՇԱՏԱԿԱՐԱՆ:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"Պատգամավոր",
  Initial_Tasks:"Նախնական առաջադրանքներ",
  Inital_Setup:"Նախնական կարգավորում",
  Welcome_To:"Բարի գալուստ ",
  Welcome:"Բարի գալուստ",
  Swipe:"Սահեցրեք",
  Intro_Setup:"Ներածական կարգավորում",
  Introduction:"Ներածություն",
  CHEQUE_PRINT_CREDIT:"Սահմանված է չեկերով",
  Title_Intro_Word:"Բարի գալուստ չեկեր",
  Title_Intro:"Չեկերի ներածություն",
  Title_Setup:"Չեկերի կարգավորում",
  PayPal_Default_Email_Message:"Դուք ստացել եք նոր PayPal փոխանցում",
  Cheques_App_Export:"Արտահանվում է չեկերով",
  Post_Intro_Thanks:"Շնորհակալություն չեկեր ընտրելու համար: Կարգավորման գործընթացն այժմ ավարտված է",
  Post_Intro_Word:"Սկսեք տպելով ձեր առաջին չեկը, ավելացրեք ապագա վճարում կամ աշխատակցին ավելացրեք աշխատավարձի ցուցակում",
  Upgrade_Required:"Չեկերը պահանջում են, որ դուք ունենաք ծրագրաշարի ավելի պրեմիում տարբերակ՝ այս հաղորդագրությունը թաքցնելու և լրացուցիչ գործառույթները բացելու համար",
  Upgrade_Title:"Չեկեր",
  Mailout_Prompt:"Դուք կարող եք նաև ընտրել, որ չեկերը փոստով ուղարկեն ձեր աշխատավարձի չեկերը ձեզ համար",
  Mailed_Cheque:"Փոստով չեկ.",
  Mailed_Cheque_Color:"Փոստով չեկ (գունավոր).",
  Terms_Purchase:"Չեկերով բոլոր էլեկտրոնային գնումները լիովին վերադարձվում են գնման օրվանից մինչև 30 օրվա ընթացքում: Խնդրում ենք կարդալ և համաձայնել <a href='#'>Պայմաններն ու դրույթները</a>, նախքան շարունակելը",
  Dashboard_Setup:"Կարգավորեք հիմնական տպիչը",
  Dashboard_Add:"Ավելացնել հիմնական բանկային հաշիվը",
  Dashboard_Customize:"Ընտրեք ստուգման ձևանմուշ",
  Dashboard_Job_Salary:"Ստեղծիր քո աշխատանքը և ավելացրու քո աշխատավարձը",
  Dashboard_Employees:"Հետևել աշխատողներին և վճարողներին",
  Dashboard_Print:"Տպեք և ուղարկեք ձեր աշխատավարձերը",
  Dashboard_Payroll:"Ավտոմատացրեք ձեր աշխատավարձի տպագրությունը",
  Dashboard_PayPal:"Setup PayPal Payroll / Payouts",
  Begin_Setup:"Սկսել կարգավորումը",
  Get_Started:"Սկսել",
  Purchase:"Գնել",
  Lockdown:"Արգելափակում",
  Unlock:"Բացել",
  Detailed:"Մանրամասն",
  Log_In:"Մուտք գործել",
  Login:"Մուտք",
  Launch:"Գործարկել",
  Register:"Գրանցվել",
  Finish:"Ավարտել",
  List:"Ցուցակ",
  Weekends:"Հանգստյան օրեր",
  Weekly:"Շաբաթական",
  PayPal_Default_Subject:"Նոր վճարում",
  Payment_Message:"Վճարային հաղորդագրություն",
  PayPal_Default_Payment_Note:"Շնորհակալություն",
  Setup_Your_Cheqing_Account:"Չեկային հաշիվ",
  Add_Your_Primary_Cheqing_Account:"Ավելացրեք ձեր հիմնական չեկային հաշիվը",
  Welcome_Word:"Պարզեցնել և ավտոմատացնել աշխատավարձերի և մարդկային ռեսուրսների կառավարումը",
  Get_Started_Quickly:"Պարզապես պատասխանեք մի քանի պարզ հարցերի, որոնք կօգնեն մեզ սկսել…",
  Done_Intro_Word:"Կարգավորումն ավարտված է",
  PIN_Protected:"Գաղտնաբառը և PIN-ը պաշտպանված են",
  Enter_New_PIN:"Մուտքագրեք նոր PIN կոդը:",
  Enter_PIN:"Մուտքագրեք PIN կոդը:",
  Invalid_PIN:"Մուտքագրվել է անվավեր PIN",
  Account_Identifier:"Հաշվի նույնացուցիչ",
  New_Account_Identifier:"Նոր հաշվի նույնացուցիչ",
  attempt:"փորձ",
  attempts:"փորձեր",
  remaining:"մնացյալ",
  Language:"Լեզու",
  languages:"Լեզուներ",
  select_languages:"Ընտրել լեզուն",
  Deposit:"Ավանդ",
  Hire_One_Now:"Վարձակալեք մեկին հիմա",
  App_Locked:"Հավելվածը կողպված է",
  Skip_:"Բաց թողնել",
  Skip_to_Dashboard:"Բաց թողնել կառավարման վահանակ",
  Dashboard:"Վահանակ",
  Import:"Ներմուծում",
  Admin:"Ադմինիստրատորներ",
  New_Admin:"Նոր ադմին",
  Settings:"Կարգավորումներ",
  Color_Picker:"Գույնի ընտրիչ",
  Font_Picker:"Տառատեսակի ընտրիչ",
  Logout:"Դուրս գալ",
  Close:"Փակել",
  Close_menu:"Փակել",
  Excel:"Excel ֆայլ",
  Csv:"CSV ֆայլ",
  Sql:"SQL ֆայլ",
  Json:"JSON ֆայլ",
  Url:"Ներմուծում URL-ով",
  Back:"Հետ",
  Timers:"ժմչփեր",
  Cheque:"Ստուգել",
  Print:"Տպել",
  Designs:"Դիզայններ",
  Pause_Printing:"Դադար տպագրություն",
  Resume_Printing:"Ռեզյումեի տպագրություն",
  Printing_Paused:"Տպագրությունն ընդհատված է",
  PrintingUnavailable:"Ներողություն, տպումն անհասանելի է այս համակարգում",
  Prints_Paused:"Տպումները դադարեցված են",
  Administration:"Վարչություն",
  Loading:"Բեռնում",
  Unnamed:"Անանուն",
  error:"Ներողություն, այս ստուգումը չհաջողվեց բացել դիտելու համար",
  No_Cheques_To_Print:"Չեկեր տպելու համար",
  No_Cheques_To_Print_Word:"Ստեղծեք <a routerLink='/folder/Cheque/New'>Նոր չեկ</a>։",
  New_Cheque:"Նոր ստուգում",
  Start_One_Now:"Սկսիր մեկ հիմա",
  Edit_Cheque:"Խմբագրել ստուգումը",
  Select_Cheques:"Ընտրեք չեկեր",
  Select_Employee:"Ընտրեք աշխատող",
  Default_Printer:"Լռելյայն տպիչ",
  Reassign:"Վերահանձնարարել",
  Configure:"Կարգավորել",
  Template:"Կաղապար",
  Designer:"Դիզայներ",
  Edit_Designs:"Խմբագրել դիզայնը",
  New_Design:"Նոր դիզայն",
  Next:"Հաջորդ",
  Save:"Պահպանել",
  Name:"Անուն",
  Mail:"Փոստ",
  Amount:"Գումար",
  Date:"Ամսաթիվ",
  PayPal:"PayPal",
  Payouts:"Վճարումներ",
  PayPal_Label:"PayPal Label",
  Email_Username:"Էլ. փոստ / օգտանուն",
  Client_ID:"Հաճախորդի ID",
  Sandbox_Email:"Sandbox էլ.փոստ",
  PayPal_Email:"PayPal էլ.փոստ",
  PayPal_Username:"API օգտվողի անուն",
  PayPal_Payouts:"PayPal վճարումներ",
  Select_PayPal_Key:"Ընտրեք PayPal բանալի",
  Secret:"Գաղտնիք",
  API_Secret:"API Secret",
  PayPal_API_Keys:"PayPal բանալիներ",
  New_PayPal_Key:"Նոր PayPal բանալի",
  Email_Subject:"Էլփոստի թեմա",
  Email_Message:"Էլփոստի հաղորդագրություն",
  Payout_Options:"Վճարման տարբերակներ",
  Payment_Note:"Վճարման նշում",
  Enable_Employee:"Միացնել աշխատակցին",
  Enable_Production_Mode:"Միացնել արտադրության ռեժիմը",
  Sandbox_Username:"Sandbox օգտվողի անուն",
  Sandbox_Signature:"Sandbox Signature",
  Sandbox_Password:"Sandbox Գաղտնաբառ",
  Production_Username:"Արտադրական օգտանուն",
  Production_Signature:"Արտադրական ստորագրություն",
  Production_Password:"Արտադրական գաղտնաբառ",
  Production_Email:"Արտադրական էլ.փոստ",
  API_Client_ID:"API Client ID",
  API_Signature:"API ստորագրություն",
  API_Password:"API գաղտնաբառ",
  API_Username:"API օգտվողի անուն",
  Secret_Key:"Գաղտնի բանալի",
  Sandbox:"Ավազատուփ",
  Production:"Արտադրություն",
  Sandbox_Keys:"Sandbox Keys",
  Production_Keys:"Արտադրական բանալիներ",
  API_Title:"API վերնագիր",
  Production_Mode:"Արտադրական ռեժիմ",
  Account_Label:"Հաշվի պիտակ",
  No_PayPal_Setup:"PayPal բանալի չկա",
  Enable_PayPal_Account:"Միացնել PayPal հաշիվը",
  No_PayPal_Word:"Ավելացրեք ձեր <a routerLink='/folder/Invoice/New'>PayPal API բանալի</a>",
  Printed_Memo:"Տպագիր հուշագիր",
  Employee:"Աշխատակից",
  View_Employee:"Դիտել աշխատակցին",
  Mailing_Address:"Փոստային ՀԱՍՑԵ",
  Company_Address:"Ընկերության հասցեն",
  Select_Company_Address:"Ընտրեք ընկերության հասցեն",
  Address:"Հասցե",
  Any_Day:"Ցանկացած օր",
  Address_Name:"Հասցեի անվանումը",
  Unit:"Միավոր",
  Account:"Հաշիվ",
  Bank_Account:"Բանկային հաշիվ",
  Account_Limits:"Միացնել հաշվի սահմանաչափերը",
  Payroll:"Աշխատավարձ",
  New_Payroll:"Նոր աշխատավարձ",
  No_Payroll:"Առաջիկա աշխատավարձ չկա",
  Upcoming_Holiday:"Առաջիկա տոն.",
  Invoice_Due:"Վճարված ապրանքագիր.",
  New_Invoice:"Նոր հաշիվ-ապրանքագիր",
  No_Invoices:"Առանց հաշիվ-ապրանքագրերի",
  No_Invoices_Word:"Ստեղծեք առաջին <a routerLink='/folder/Invoice/New'>Հաշիվ-ապրանքագիր</a>",
  Cheque_Due:"Չեկի ժամկետանց:",
  Payrolls:"Աշխատավարձեր",
  Sandbox_Mode:"Sandbox ռեժիմ",
  Hire:"Վարձել",
  Pay:"Վճարել",
  New:"Նոր",
  Add:"Ավելացնել",
  Make:"պատրաստել",
  Time:"Ժամանակ",
  Write:"Գրել",
  Holiday:"Տոն",
  Holidays:"Տոներ",
  Next_Holiday:"Հաջորդ արձակուրդը:",
  All_Done:"Ամեն ինչ արված է!",
  Employees:"Աշխատակիցներ",
  Payees:"Վճարողներ",
  Job:"Աշխատանք",
  Jobs:"Աշխատանք",
  Invoice:"հաշիվ",
  Invoices:"Հաշիվ-ապրանքագրեր",
  Vacations:"Արձակուրդներ",
  Cheques:"Չեկեր",
  Brand_Cheques:"Ապրանքանիշը",
  Payment:"Վճարում",
  Enable_Payment:"Միացնել վճարումը",
  Payments:"Վճարումներ",
  Schedule:"Ժամանակացույց",
  Schedules:"Ժամանակացույցեր",
  Timesheet:"Ժամանակացույց",
  Timesheets:"Ժամանակի թերթիկներ",
  Salary:"Աշխատավարձ",
  New_Address:"Նոր հասցե",
  Address_2:"Հասցե (տող 2)",
  _City:"Քաղաք",
  _State:"Պետություն/Պրով",
  City:"Քաղաք / Քաղաք",
  State:"Պետական ​​նահանգը",
  Postal:"Փոստային / փոստային ինդեքս",
  ZIP:"Փոստային / ZIP",
  Country:"Երկիր",
  Addresses:"Հասցեներ",
  Required_Options:"Պահանջվող ընտրանքներ",
  Optional_Options:"Ընտրովի ընտրանքներ",
  Additional_Options:"Լրացուցիչ ընտրանքներ",
  Required:"Պարտադիր",
  Optional:"Ըստ ցանկության",
  Additional:"Լրացուցիչ",
  No_Addresses:"Հասցեներ չկան",
  New_Address_Word:"Ավելացրեք առաջին <a routerLink='/folder/Address/New'>Հասցե</a>",
  Select_Address:"Ընտրել հասցեն",
  No_Address:"Հասցեներ չկան",
  Print_Cheque:"Տպել ստուգում",
  Print_Cheque_Now:"Տպել ստուգել հիմա",
  Description:"Նկարագրություն",
  Pay_To_The_Order_Of:"Վճարել պատվերին.",
  Select_Date_Range:"Ընտրեք ամսաթվերի միջակայք",
  Select_Starting_Date:"Ընտրեք մեկնարկի ամսաթիվը",
  Select_Ending_Date:"Ընտրել ավարտի ամսաթիվը",
  Select_Date:"Ընտրել ամսաթիվը",
  Cheque_Date:"Ստուգման ամսաթիվ",
  Cheque_Memo:"Ստուգեք հուշագիրը",
  Blank_Cheque:"Դատարկ կտրոն",
  Blank:"Դատարկ",
  No_Cheques:"Չեկեր",
  No_Cheques_Word:"Տպեք ձեր առաջին <a routerLink='/folder/Cheque/New'>Ստուգեք</a>",
  Cheque_Amount_Placeholder:"0,00 դոլար",
  View_Image:"Դիտել պատկերը",
  View:"Դիտել",
  Modify:"Փոփոխել",
  Delete:"Ջնջել",
  Cheque_Paid:"Վճարված",
  New_Deduction:"Նոր նվազեցում",
  Title:"Կոչում",
  Frequency:"Հաճախականություն",
  Hourly:"Ժամային",
  Daily:"Ամենօրյա",
  Weekdays:"Շաբաթվա օրերը",
  BiWeekly:"2 շաբաթ",
  TriWeekly:"3 շաբաթ",
  Monthly:"Ամսական",
  MiMonthly:"2 ամիս",
  Quarterly:"Եռամսյակային",
  Yearly:"Տարեկան",
  Every_Week:"Ամեն շաբաթ",
  Every_Payroll:"Յուրաքանչյուր աշխատավարձ",
  Every_Month:"Ամեն ամիս",
  Annually:"Տարեկան",
  Always_Scheduled:"Միշտ պլանավորված",
  Start_Date:"Մեկնարկի ամսաթիվ",
  End_Date:"Ավարտի ամսաթիվ",
  Start_Time:"Մեկնարկի ժամանակը",
  End_Time:"Վերջի ժամանակը",
  Deduction_Label:"Նվազեցման պիտակ",
  Notes:"Նշումներ",
  Options:"Ընտրանքներ",
  Enable:"Միացնել",
  Select_Deductions:"Ընտրեք նվազեցումները",
  Deductions:"Նվազեցումներ",
  No_Deductions:"Ոչ նվազեցումներ",
  No_Deductions_Word:"Ստեղծեք ձեր առաջին <a routerLink='/folder/Deduction/New'>Նվազեցում</a>",
  New_Employee:"Նոր աշխատակից",
  No_Title:"Առանց վերնագրի",
  _Name:"Անուն",
  About_Yourself:"Քո մասին",
  Full_Name:"Լրիվ անուն",
  Birthday:"Ծննդյան օր",
  Email:"Էլ. փոստ",
  SMS:"SMS",
  Phone:"Հեռախոս",
  Test:"Փորձարկում",
  Call:"Զանգիր",
  Fax:"Ֆաքս",
  Printed_Note:"Տպագիր նշում",
  Position:"Դիրք",
  Job_Position:"Աշխատանքային պաշտոն",
  Select_a_Job:"Ընտրիր աշխատանք",
  Select_a_Salary:"Ընտրեք աշխատավարձ",
  Add_a_Deduction:"Ավելացնել նվազեցում",
  Taxes:"Հարկեր",
  Add_Taxes:"Ավելացնել հարկեր",
  Date_of_Birth:"Ծննդյան ամսաթիվ",
  Email_Address:"Էլեկտրոնային հասցե",
  Phone_Number:"Հեռախոսահամար",
  Phone_Call:"Հեռախոսազանգ",
  Enable_on_Payroll:"Միացնել աշխատավարձի վրա",
  Select_Employees:"Ընտրեք աշխատողներ",
  No_Employees:"Աշխատակիցներ չկան",
  No_Employees_Word:"Ավելացրեք ձեր առաջին նոր <a routerLink='/folder/Employee/New'>Աշխատակիցը</a>",
  No_Name:"Անանուն",
  Unemployeed:"Գործազուրկ",
  Employeed:"Աշխատում է",
  Paid:"Վճարված",
  Enabled:"Միացված է",
  Disabled:"Անաշխատունակ",
  Fire:"Կրակ",
  Not_Available:"Անհասանելի",
  Not_Available_Word:"Տպեք ձեր առաջին <a routerLink='/folder/Invoice/New'>հաշիվը</a> և ստացեք վճարում",
  Select_Invoices:"Select_Invoices",
  Print_Invoice_Word:"Տպեք ձեր առաջին <a routerLink='/folder/Invoice/New'>հաշիվը</a> և ստացեք վճարում",
  Invoice_Title:"Հաշիվ-ապրանքագրի անվանումը",
  Invoice_Date:"Հաշիվ-ապրանքագրի ամսաթիվ",
  Due_Date:"Վերջնաժամկետ",
  New_Job:"Նոր աշխատանք",
  Details:"Մանրամասներ",
  Customize:"Անհատականացնել",
  Customize_Dashboard:"Անհատականացնել վահանակը",
  Components:"Բաղադրիչներ",
  No_Components:"Բաղադրիչներ չկան",
  Main_Components:"Հիմնական բաղադրիչներ",
  Smaller_Components:"Փոքր բաղադրիչներ",
  Error_Loading_Page:"Սխալ՝ այս էջը բեռնելիս",
  Bank_Details:"Բանկի տվյալները",
  About_Your_Job:"Ձեր աշխատանքի մասին",
  Your_Schedule:"Ձեր ժամանակացույցը",
  Job_Title:"Աշխատանքի անվանումը",
  Job_Description:"Աշխատանքի նկարագրություն",
  Job_Details:"Աշխատանքի մանրամասներ",
  Enable_Job:"Միացնել Աշխատանքը",
  Pay_Period:"Վճարման ժամկետ",
  Perpetually_Schedule:"Մշտապես ժամանակացույց",
  Select_Jobs:"Ընտրել աշխատատեղեր",
  No_Jobs:"Աշխատանք չկա",
  Add_Jobs:"Ավելացնել աշխատատեղեր",
  No_Jobs_Word:"Ավելացրեք առաջին <a routerLink='/folder/Job/New'>Աշխատանքը</a> ցուցակին",
  Count_Employees:"job.employee_count+' Աշխատակիցներ'",
  Zero_Employees:"0 աշխատող",
  New_Leave:"Նոր արձակուրդ",
  Leave_Name:"Անուն թողնել",
  Paid_Leave:"Վճարովի արձակուրդ",
  Leave_Pay:"Վճարում թողնել",
  Indefinite_Leave:"Անժամկետ արձակուրդ",
  Indefinite_Payroll:"Անորոշ աշխատավարձ",
  Leave:"Հեռանալ",
  Add_Schedules:"Ավելացնել ժամանակացույցեր",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Միացնել բացակայությունը",
  Select_Leaves:"Ընտրեք տերևները",
  No_Leaves:"Բացակայության տերևներ չկան",
  Leave_Of_Absence:"Բացակայության արձակուրդ",
  Leaves_Of_Absence:"Բացակայության տերևներ",
  New_Leave_of_Absense:"Բացակայության նոր արձակուրդ",
  No_Leaves_Word:"Ավելացրեք առաջին <a routerLink='/folder/Leave/New'>Բացակայության արձակուրդը</a>",
  Not_Enabled:"Միացված չէ",
  Absences:"Բացակայություններ",
  Payee:"Վճարող",
  New_Payee:"Նոր վճարող",
  Payee_Identifier:"Վճարողի նույնացուցիչ",
  Payee_Name:"Վճարը ստացողի անունը",
  Payee_Title:"Վճարողի կոչում",
  Payment_Memo:"Վճարման հուշագիր",
  Select_Payees:"Ընտրել վճարողներին",
  No_Payees:"Վճարողներ չկան",
  Add_Payee_Note:"Ավելացրե՛ք առաջին <a routerLink='/folder/Payee/New'>վճարատիրոջը</a>",
  New_Payees:"Նոր վճարողներ",
  About_The_Payment_Schedule:"Վճարումների ժամանակացույց",
  Your_Payroll_Schedule:"Ավտոմատ աշխատավարձ",
  New_Payment:"Նոր վճարում",
  Identifier:"Նույնականացուցիչ",
  Select:"Ընտրել",
  Memo:"Հուշագրություն",
  Payment_Date:"Վճարման օր",
  Mark_as_Paid:"Նշել որպես վճարված",
  Select_Payments:"Ընտրեք վճարումներ",
  No_Payments:"Վճարումներ չկան",
  No_Payments_Word:"Ստեղծեք առաջին <a routerLink='/folder/Payment/New'>Վճարումը</a>",
  Create_Payroll:"Ստեղծել աշխատավարձը",
  Properties:"Հատկություններ",
  Payroll_Title:"Աշխատավարձի անվանումը",
  Payroll_Notes:"Աշխատավարձի նշումներ",
  Payroll_Setup:"Աշխատավարձի կարգավորում",
  Tabulate_Payments:"Աղյուսակային վճարումներ",
  Tabulate:"Աղյուսակ",
  By:"Ըստ:",
  Payments_By:"Վճարումներ ըստ",
  Timesheets_And_Schedules:"Ժամանակի թերթիկներ և ժամանակացույցեր",
  Both:"Երկուսն էլ",
  Items:"Նյութեր",
  Add_Payees:"Ավելացնել վճարողներ",
  Add_Account:"Ավելացնել հաշիվ",
  Enable_Account:"Միացնել հաշիվը",
  Enable_Payroll:"Միացնել աշխատավարձը",
  Print_Payroll:"Տպել աշխատավարձը",
  No_Payrolls:"Ոչ աշխատավարձ",
  No_Payroll_Word:"Ստեղծեք ձեր առաջին <a routerLink='/folder/Payroll/New'>Աշխատավարձը</a>",
  View_more:"ԴԻՏԵԼ ԱՎԵԼԻՆ",
  Less:"ՔԻՉ",
  Add_Payroll:"Ավելացնել աշխատավարձը",
  Select_Payroll:"Ընտրեք Աշխատավարձը",
  About_Your_Salary:"Ձեր աշխատավարձի մասին",
  Add_Salaries:"Ավելացնել աշխատավարձեր",
  Add_Salary:"Ավելացնել աշխատավարձ",
  Salaries:"Աշխատավարձեր",
  No_Salaries:"Աշխատավարձեր չկան",
  No_Salaries_Word:"Ավելացրեք առաջին <a routerLink='/folder/Salary/New'>Աշխատավարձը</a>",
  Select_Salaries:"Ընտրեք աշխատավարձեր",
  New_Salary:"Նոր աշխատավարձ",
  Salary_Name:"Աշխատավարձի նույնացուցիչ",
  Enable_Salary:"Միացնել աշխատավարձը",
  Salary_Amount:"Աշխատավարձի չափը",
  New_Schedule:"Նոր ժամանակացույց",
  Schedule_Title:"Ժամանակացույցի անվանումը",
  Add_Address:"Ավելացնել հասցե",
  Repeat:"Կրկնել",
  Design:"Դիզայն",
  Edit_Design:"Խմբագրել դիզայնը",
  Edit_this_Design:"Խմբագրել այս դիզայնը",
  Repeat_Payment:"Կրկնվող վճարում",
  Enable_Schedule:"Միացնել ժամանակացույցը",
  Never:"Երբեք",
  Select_Schedule:"Ընտրել ժամանակացույցերը",
  No_Schedules:"Ոչ մի ժամանակացույց",
  No_Schedules_Word:"Ստեղծեք առաջին <a routerLink='/folder/Schedule/New'>Ժամանակացույցը</a>",
  New_Administrator:"Նոր ադմինիստրատոր",
  Username:"Օգտագործողի անունը",
  First_Name:"Անուն",
  Last_Name:"Ազգանուն",
  Add_an_Address:"Ավելացնել հասցե",
  Payment_Limit:"Մեկ վճարման սահմանաչափ",
  Total_Limit:"Ընդհանուր սահմանաչափ",
  Select_Accounts:"Ընտրեք հաշիվներ",
  No_Administrators:"Ադմինիստրատորներ չկան",
  No_Administrators_Word:"Ստեղծեք առաջին <a routerLink='/folder/Administrator/New'>Ադմինիստրատորի հաշիվը</a>։",
  New_Administrators_Word:"Ավելացրեք առաջին <a routerLink='/folder/Administrator/New'>Ադմինիստրատորը</a>",
  Cloud:"Ամպ",
  Backup:"Պահուստային",
  Enable_iCloud:"Միացնել iCloud-ը",
  Enable_Google_Drive:"Միացնել Google Drive-ը",
  Enable_Microsoft_OneDrive:"Միացնել Microsoft OneDrive-ը",
  Automatically_Backup:"Ավտոմատ կրկնօրինակում",
  FTP_Settings:"FTP կարգավորումներ",
  URL_File_Prompt:"Խնդրում ենք նշել .xls / .xlsx / .json ֆայլի գտնվելու վայրը ներմուծման համար:",
  URL_SQL_Prompt:"Խնդրում ենք նշել ներմուծման համար SQLite ֆայլի գտնվելու վայրը",
  FTP_Backup:"FTP կրկնօրինակում",
  FTP_Import:"FTP ներմուծում",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"հյուրընկալող",
  Port:"Պորտ",
  Path:"Ճանապարհ",
  Data_Path:"Տվյալների ուղի",
  Enable_FTP_Account:"Միացնել FTP հաշիվը",
  HostnameIP:"Հյուրընկալող անուն",
  Password:"Գաղտնաբառ",
  Connection_Port:"Միացման նավահանգիստ",
  Enable_MySQL_Database:"Միացնել MySQL տվյալների բազան",
  Log:"Մատյան",
  Reset:"Վերականգնել",
  Erase:"Ջնջել",
  Export:"Արտահանում",
  Database:"Տվյալների բազա",
  Upload_CSV:"Վերբեռնել CSV",
  Download_CSV:"Ներբեռնել CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL կրկնօրինակում",
  Internal_Notes:"Ներքին նշումներ",
  Root_Path:"Արմատային ուղի",
  Select_Backup:"Ընտրեք կրկնօրինակում",
  Add_New_Backup:"Ավելացնել նոր կրկնօրինակում",
  First_Backup:"Կարգավորեք առաջին կրկնօրինակը...",
  Backups:"Կրկնօրինակներ",
  Add_Backup:"Ավելացնել կրկնօրինակում",
  No_Backups:"Կրկնօրինակներ չեն գտնվել",
  Select_Backup_Type:"Ընտրեք պահուստավորման տեսակը, որը ցանկանում եք կարգավորել...",
  Backup_Type:"Պահուստավորման տեսակը",
  FTP_Drive:"FTP Drive",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Քշել",
  Microsoft_OneDrive:"Քշել",
  Import_Fields:"Ներմուծման ոլորտներ",
  Import_Fields_Word:"Օգտագործեք այս բաժինը՝ <a routerLink='/folder/Holiday/New'>Ներմուծել</a> տվյալներ ընտրելու համար",
  Upload:"Վերբեռնում",
  Download:"Ներբեռնել",
  Download_JSON:"Ներբեռնել որպես JSON տվյալներ",
  Download_SQL:"Ներբեռնել որպես SQL ֆայլ",
  New_Bank:"Նոր բանկ",
  New_Account:"Նոր հաշիվ",
  New_Bank_Account:"Նոր բանկային հաշիվ",
  Upload_Image:"Վերբեռնել պատկերը",
  Bank_Name:"Բանկի անվանումը",
  Bank_Address:"Բանկի հասցեն",
  Branch_Address:"Մասնաճյուղի հասցեն",
  Address_on_Cheque:"Հասցե",
  Cheque_Numbers:"Ստուգեք համարները",
  Cheque_Details:"Ստուգեք մանրամասները",
  Bank_Logo:"Բանկի լոգոն",
  Cheque_ID:"Ստուգեք ID-ն",
  Starting_Cheque_ID:"Սկսվում է ստուգման ID",
  Transit_Number:"Տարանցիկ համար",
  Institution_Number:"Հաստատության համարը",
  Designation_Number:"Նշանակման համարը",
  Account_Number:"Հաշվեհամար",
  Limits:"Սահմաններ",
  Default_Limits:"Լռելյայն սահմաններ",
  Over_Limit:"Ավելի սահմանը",
  Under_Limit:"Սահմանի տակ",
  Payroll_Limit:"Աշխատավարձի սահմանաչափ",
  Enable_Bank_Account:"Միացնել բանկային հաշիվը",
  Select_Account:"Ընտրել հաշիվ",
  No_Bank_Account:"Բանկային հաշիվ չկա",
  No_Bank_Account_Word:"Ավելացրեք առաջին <a routerLink='/folder/Accounts/New'>բանկային հաշիվը</a>",
  Bank_Accounts:"Բանկային հաշիվներ",
  No_Accounts:"Հաշիվներ չկան",
  No_Accounts_Note:"Ավելացրեք առաջին <a routerLink='/folder/Accounts/New'>բանկային հաշիվը</a>",
  Cheque_Designer:"Չեկի դիզայներ",
  Cheque_Design:"Ստուգեք դիզայնը",
  Select_Design:"Ընտրեք դիզայն",
  Cheque_Designs:"Ստուգեք դիզայնը",
  No_Cheque_Designs:"Չեկի նմուշներ",
  No_Cheque_Designs_Word:"Ստեղծեք ձեր սեփական <a routerLink='/folder/Settings/Cheque/Design/New'>Check Design</a>:",
  Set_Default:"Սահմանել որպես լռելյայն",
  Default:"Լռելյայն",
  Remove:"Հեռացնել",
  Folder:"Թղթապանակ",
  Edit:"Խմբագրել",
  LoadingDots:"Բեռնվում է...",
  Add_a_New_File:"Ավելացնել <a href='#' (click)='add()'>Նոր ֆայլ</a>",
  this_folder:"Այս թղթապանակը",
  FTP_Backup_Settings:"FTP կրկնօրինակման կարգավորումներ",
  Secure_File_Transfer:"Անվտանգ ֆայլերի փոխանցում",
  New_Holiday:"Նոր տոն",
  Add_Holiday:"Ավելացնել արձակուրդ",
  Holiday_Name:"Տոնի անունը",
  Additional_Pay:"Լրացուցիչ վճար",
  Enable_Holiday:"Միացնել արձակուրդը",
  Select_Holidays:"Ընտրեք արձակուրդներ",
  No_Holidays:"Տոներ չկան",
  No_Holidays_Word:"Ավելացրեք առաջին <a routerLink='/folder/Holiday/New'>Հանրային արձակուրդը</a>։",
  New_Import:"Նոր ներմուծում",
  Import_Data:"Տվյալների ներմուծում",
  Import_Data_Word:"Ընտրեք ձեր ընտրած ֆայլի տեսակը կամ վերբեռնման եղանակը:<br /> Դուք կկարողանաք ընտրել ֆայլի ներմուծված որ դաշտերը, որոնք կհամապատասխանեն հավելվածի ցանկացած պարամետրին՝ աջակցվող ֆայլը վերբեռնելուց հետո", 
  Import_File:"Ներմուծել ֆայլ",
  Link_To_File:"Հղում դեպի ֆայլ",
  Select_File:"Ընտրել ֆայլը",
  New_Moderator:"Նոր մոդերատոր",
  Allow_Moderation:"Թույլատրել չափավորությունը",
  Create_Paycheques:"Ստեղծել աշխատավարձեր",
  Edit_Paycheques_and_Data:"Խմբագրել աշխատավարձերը և տվյալները",
  Destroy_Data_and_Paycheques:"Ոչնչացնել տվյալները և աշխատավարձերը",
  Bonus_Percentage:"Աշխատավարձի տոկոսը",
  Fixed_Amount:"Ֆիքսված գումար",
  Select_Moderator:"Ընտրել մոդերատոր",
  No_Moderators:"Ոչ մոդերատորներ",
  Moderators:"Մոդերատորներ",
  Moderator_Account:"Ստեղծեք առաջին <a routerLink='/folder/Administrator/New'>մոդերատորի հաշիվը</a>:",
  No_Moderators_Word:"Ավելացրեք առաջին <a routerLink='/folder/Administrator/New'>Moderator</a>",
  Defaults:"Լռելյայն",
  Provide_Us_Info:"Տրամադրել մեզ տեղեկատվություն",
  Setup_Your_Printer:"Կարգավորեք ձեր տպիչը",
  Your_Company:"Ձեր ընկերության մասին",
  Company_Name:"Ընկերության Անվանումը",
  Currency:"Արժույթ",
  Default_Currency:"Լռելյայն արժույթ",
  Base_Monthly_Income:"Ամսական եկամուտը",
  Protection:"Պաշտպանություն",
  App_Protection:"Հավելվածի պաշտպանություն",
  PIN_Code_Protection:"PIN կոդի պաշտպանություն",
  App_Protection_Word:"Միացնել անվտանգության մեթոդները, որոնք օգնում են պաշտպանել ձեր հաշիվը",
  PIN_Code:"Փին կոդը",
  Change_PIN:"Փոխել PIN-ը",
  New_Password:"Նոր ծածկագիր",
  Geofence_Protection:"Geo-Fence Protection",
  Geofence_Area:"Սահմանված տարածք",
  Distance:"Հեռավորություն",
  Setup_Now:"Կարգավորել հիմա",
  Mile:"Միլ",
  Km:"կմ",
  m:"մ",
  Facial_Recognition:"Դեմքի ճանաչում",
  Face:"Դեմք",
  Setup:"Կարգավորում",
  Label:"Պիտակ",
  Password_Protection:"Գաղտնաբառի պաշտպանություն",
  Modify_Password:"Փոփոխել գաղտնաբառը",
  New_Tax_Entry:"Նոր հարկային մուտք",
  New_Tax:"Նոր հարկ",
  Tax_Label:"Հարկային պիտակ",
  Perpetually_Enabled:"Մշտապես միացված է",
  Select_Taxes:"Ընտրեք հարկերը",
  Tax_Deductions:"Հարկային նվազեցումներ",
  No_Tax_Deductions:"Հարկային նվազեցումներ չկան",
  No_Tax_Deductions_Word:"Ավելացրեք առաջին <a routerLink='/folder/Tax/New'>Հարկը</a> նվազեցումը",
  New_Timer:"Նոր ժմչփ",
  Start:"Սկսել",
  Stop:"Կանգնիր",
  Start_Timer:"Սկսել ժմչփ",
  Stop_Timer:"Stop Timer",
  Timer_Active:"Ակտիվ ժամանակաչափ",
  Timer:"Ժամաչափ:",
  Timer_Running:"Ժամաչափ. (վազում)",
  Save_Timer:"Save Timer",
  New_Timesheet:"Նոր ժամանակի թերթիկ",
  Select_Timesheets:"Ընտրեք ժամանակի թերթիկներ",
  Work_Notes:"Աշխատանքային նշումներ",
  Entry_Title:"Մուտքի անվանումը",
  No_Timesheets:"Ժամանակի թերթիկներ չկան",
  No_Timesheets_Word:"Ավելացրեք առաջին <a routerLink='/folder/Timesheet/New'>Ժամանակի թերթիկը</a>։",
  Timesheet_Submitted:"Ներկայացված ժամանակի թերթիկը",
  Timesheet_Congrats:"Շնորհավորում եմ: Ձեր ժամանակի թերթիկը հաջողությամբ ուղարկվել է: Շնորհակալություն",
  Timesheet_Copy:"Ձեր փաստաթղթերի պատճենը ստանալու համար խնդրում ենք տրամադրել մեզ ձեր էլ.փոստի հասցեն և/կամ բջջային հեռախոսահամարը",
  Submit:"Ներկայացնել",
  Allow_Notifications:"Թույլատրել ծանուցումները",
  Untitled_Entry:"Նոր մուտք",
  Untitled_Bank:"Անվերնագիր բանկ",
  Timesheet_Entry:"Ժամանակի թերթիկի մուտքագրում",
  Work_Description:"Աշխատանքի նկարագրություն",
  Enable_Timesheet:"Միացնել ժամանակի թերթիկը",
  Submit_Timesheet:"Ներկայացնել ժամանակի թերթիկը",
  Vacation:"Արձակուրդ",
  New_Vacation:"Նոր արձակուրդ",
  Vacation_Name:"Արձակուրդի անունը",
  Paid_Vacation:"Վճարովի արձակուրդ",
  Vacation_Pay:"Արձակուրդի վճարում",
  Enable_Vacation:"Միացնել արձակուրդը",
  Select_Vacations:"Ընտրեք արձակուրդներ",
  No_Vacations:"Արձակուրդներ չկան",
  No_Vacations_Word:"Ստեղծեք առաջին <a routerLink='/folder/Vacation/New'>Արձակուրդ</a> գրառումը",
  Payroll_Schedule:"Աշխատավարձի ժամանակացույց",
  Next_Payroll:"Հաջորդ աշխատավարձ.",
  Upcoming_Payroll:"Առաջիկա աշխատավարձը",
  No_Upcoming_Payroll:"Առաջիկա աշխատավարձ չկա",
  Address_Book:"Հասցեների գիրք",
  Archived_Documents:"Արխիվացված փաստաթղթեր",
  Dev_Mode:"Հավելվածը զարգացման ռեժիմում",
  Administrators:"Ադմինիստրատորներ",
  Privacy:"Գաղտնիություն",
  None:"Ոչ ոք",
  Select_Signature:"Ընտրել ստորագրությունը",
  No_Signatures:"Ստորագրություններ չկան",
  No_Signatures_Word:"Ավելացրեք առաջին <a routerLink='/folder/Signature/New'>Ստորագրությունը</a>",
  Repeat_Indefinitely:"Կրկնել անորոշ ժամանակով",
  Sign:"Նշան",
  Sign_Here:"Ստորագրեք այստեղ",
  Date_Signed:"Ստորագրման ամսաթիվը",
  Signature_Pad:"Ստորագրության պահոց",
  Account_Holder:"Հաշվի սեփականատեր",
  Account_Properties:"Հաշվի հատկություններ",
  Name_On_Cheque:"Անունը չեկի վրա",
  Server_Hostname:"Սերվերի հոսթի անուն / IP",
  Printers:"Տպիչներ",
  No_Printers:"Տպիչներ չկան",
  Printer_Exists:"Այս անունով տպիչ արդեն գոյություն ունի։",
  No_Printers_Word:"Ավելացրեք առաջին <a routerLink='/folder/Printer/New'>Տպիչը</a>",
  No_Printer_Alert:"Ոչ մի տպիչ սահմանված չէ: Ցանկանու՞մ եք կարգավորել տպիչ",
  Add_Printer:"Ավելացնել տպիչ",
  New_Printer:"Նոր տպիչ",
  Printer_Hostname:"Տպիչի հոսթի անուն / IP",
  Printer_Label:"Տպիչի պիտակ",
  Printer_Protocol:"Տպիչի արձանագրություն",
  Protocol:"Արձանագրություն",
  Email_Print:"Էլ. փոստ",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Վարդակ",
  Print_Job:"Տպագրական աշխատանք",
  Printed:"Տպագիր",
  Not_Printed:"Տպագրված չէ",
  Print_Jobs:"Տպագրական աշխատանք",
  Print_Queue:"Տպման հերթ",
  No_Print_Jobs:"Տպման աշխատանքներ չկան",
  No_Prints:"Ստեղծեք նոր <a routerLink='/folder/Cheque/New'>Cheque</a> տպելու համար",
  Prints:"Տպագրություններ",
  Find_Printer:"Գտեք տպիչը",
  Find_AirPrint_Devices:"Գտեք AirPrint սարքեր",
  Select_Printer:"Ընտրեք տպիչ",
  System_UI:"Համակարգի միջերես",
  Printer:"Տպիչ",
  Status:"Կարգավիճակ",
  Preview:"Նախադիտում",
  Enable_Print_Job:"Միացնել տպման աշխատանքը",
  Queue_Weight:"Հերթի քաշը",
  Unlimited:"Անսահմանափակ",
  Show_Advanced_Printer_Options:"Ցույց տալ տպիչի ընդլայնված ընտրանքները",
  Advanced:"Ընդլայնված",
  Location:"Գտնվելու վայրը",
  Note:"Նշում",
  Queue_Name:"Հերթի անուն",
  Pages_Printed_Limit:"Էջերի տպագրության սահմանաչափ",
  MultiPage_Idle_Time:"Բազմէջանոց սպասման ժամանակ (ներ)",
  Page_Count_Limit:"Էջերի քանակի սահմանաչափ",
  Page_Orientation:"Էջի կողմնորոշում",
  Portrait:"Դիմանկար",
  Landscape:"Լանդշաֆտ",
  Duplex:"Դուպլեքս",
  Double_Sided_Printing:"Երկկողմանի",
  Duplex_Mode:"Դուպլեքս ռեժիմ",
  Duplex_Short:"Կարճ",
  Duplex_Long:"Երկար",
  Duplex_None:"Ոչ ոք",
  Color_And_Quality:"Գույն և որակ",
  Monochrome:"Մոնոխրոմ",
  Photo_Quality_Prints:"Լուսանկարների որակի տպագրություն",
  Printer_Email:"Տպիչի էլ.փոստ",
  Automatically_Downsize:"Ավտոմատ փոքրացնել",
  Paper:"Թուղթ",
  Paper_Name:"Թղթի անվանումը",
  Paper_Width:"Թղթի լայնությունը",
  Paper_Height:"Թղթի բարձրություն",
  Paper_Autocut_Length:"Թղթի ավտոմատ կտրման երկարություն",
  Margins:"Լուսանցքներ",
  Page_Margins:"Էջի լուսանցքներ",
  Page_Margin_Top:"Վերին էջի լուսանցք",
  Page_Margin_Right:"Աջ էջի լուսանցք",
  Page_Margin_Bottom:"Ներքևի էջի լուսանցք",
  Page_Margin_Left:"Ձախ էջի լուսանցք",
  Add_Employees:"Ավելացնել աշխատակիցներ",
  Header:"Վերնագիր",
  Print_A_Page_Header:"Տպել էջի վերնագիր",
  Header_Label:"Վերնագրի պիտակ",
  Header_Page_Index:"Վերագլուխ էջի ինդեքս",
  Header_Font_Name:"Վերնագրի տառատեսակ",
  Select_Font:"Ընտրել տառատեսակը",
  Font_Selector:"Տառատեսակի ընտրիչ",
  Header_Font_Size:"Վերնագրի տառատեսակ",
  Header_Bold:"Հաստատ վերնագիր",
  Header_Italic:"Վերնագիր շեղ",
  Header_Alignment:"Վերնագրի հավասարեցում",
  Left:"Ձախ",
  Center:"Կենտրոն",
  Right:"Ճիշտ",
  Justified:"Արդարացված",
  Header_Font_Color:"Վերնագրի գույնը",
  Select_Color:"Ընտրել գույնը",
  Footer:"Ստորագիր",
  Print_A_Page_Footer:"Տպել էջի ստորագիր",
  Footer_Label:"Վերջատակի պիտակ",
  Footer_Page_Index:"Վերջին էջի ինդեքս",
  Footer_Font_Name:"Foter Font",
  Fonts:"Տառատեսակներ",
  Done:"Կատարած",
  Select_Fonts:"Ընտրել տառատեսակներ",
  Footer_Font_Size:"Ստորի չափ",
  Footer_Bold:"Foter Bold",
  Footer_Italic:"Վերջատակի շեղագիր",
  Footer_Alignment:"Եզրագծի հավասարեցում",
  Footer_Font_Color:"Եզրագծի գույն",
  Page_Copies:"Էջի պատճեններ",
  Enable_Printer:"Միացնել տպիչը",
  Remote_Logging:"Հեռավոր գրանցում",
  Log_Server:"Մատյան սերվեր",
  Encryption:"Կոդավորումը",
  Random_Key:"Պատահական բանալի",
  Encryption_Key:"Կոդավորման բանալի",
  Cheques_Webserver:"Մաքսային տվյալների բազա",
  Learn_How:"Սովորել ինչպես",
  Signature:"Ստորագրություն",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Դիտել ստորագրությունը",
  Printed_Signature:"Տպագիր ստորագրություն",
  Digitally_Sign:"Թվային նշան",
  Digital_Signature:"Թվային ստորագրություն",
  Add_Signature:"Ավելացնել ստորագրություն",
  Add_Your_Signature:"Ավելացրեք ձեր ստորագրությունը",
  Enable_Signature:"Միացնել ստորագրությունը",
  Digitally_Signed:"Թվային ստորագրված",
  Insert_Error:"Չհաջողվեց պահել ստուգումը տվյալների բազայի խնդիրների պատճառով",
  Delete_Confirmation:"Վստա՞հ եք, որ ցանկանում եք ջնջել այս տեղեկատվությունը",
  Discard_Confirmation:"Դուք վստա՞հ եք, որ ցանկանում եք հրաժարվել այս տեղեկատվությունը",
  Discard_Bank_Confirmation:"Վստա՞հ եք, որ ցանկանում եք չեղարկել այս հաշիվը",
  Discard_Printer_Confirmation:"Վստա՞հ եք, որ ցանկանում եք հրաժարվել այս տպիչից",
  Delete_Bonus_Confirmation:"Վստա՞հ եք, որ ցանկանում եք ջնջել այս բոնուսը",
  Delete_Invoice_Confirmation:"Վստա՞հ եք, որ ցանկանում եք ջնջել այս ապրանքագիրը",
  Generated_Cheque:"Ստեղծված ստուգում",
  Generated_Invoice:"Ստեղծված հաշիվ ապրանքագիր",
  Schedule_Start_Time:"Ժամանակացույցի սկիզբ",
  Schedule_End_Time:"Ժամանակացույցի ավարտ",
  New_Call:"Նոր զանգ",
  No_Contacts:"Կոնտակտներ չկան",
  No_Contacts_Word:"Ադմինիստրատորները, մոդերատորները, աշխատակիցները և վճարողները հայտնվում են որպես կոնտակտներ",
  PDF_Subject:"ֆինանսներ",
  PDF_Keywords:"Աշխատավարձի աշխատավարձի PDF ստուգման չեկեր",
  Printer_Setup:"Տպիչի կարգավորում",
  Printer_Selection:"Տպիչի ընտրություն",
  New_Fax:"Նոր Ֆաքս",
  New_Fax_Message:"Նոր ֆաքսի հաղորդագրություն",
  Fax_Machine:"Ֆաքսի մեքենա",
  Fax_Name:"Ֆաքսի անվանումը",
  Fax_Email:"Ֆաքսի էլ.փոստ",
  Fax_Number:"Ֆաքսի համարը",
  Contents:"Բովանդակություն",
  Fax_Body:"Էջի մարմին",
  Header_Word:"Վերնագիր:",
  Header_Text:"Վերնագրի տեքստ",
  Include_Header:"Ներառել վերնագիր",
  Include_Footer:"Ներառել ստորագիր",
  Footer_Word:"Ստորագիր:",
  Footer_Text:"Վերջատակի տեքստ",
  Attach_a_Cheque:"Կցել չեկ",
  Add_Deduction:"Ավելացնել նվազեցում",
  Enable_Fax:"Ուղարկել Ֆաքս",
  Select_Fax:"Ընտրել Ֆաքս",
  No_Faxes:"Ֆաքսեր չկան",
  Faxes:"Ֆաքսեր",
  Save_and_Send:"Ուղարկել Ֆաքս",
  Save_and_Pay:"Խնայիր և վճարիր",
  WARNING:"ԶԳՈՒՇԱՑՈՒՄ.",
  Remember:"Հիշիր",
  Printing:"Տպագրություն",
  Printing_Complete:"Տպագրումն ավարտված է:\n\n",
  of:"ից",
  There_Were:"Կային ",
  Successful_Prints:"հաջող տպագրություններ և",
  Unsuccessful_Prints:"անհաջող տպագրություններ",
  PrinterError:"Ներողություն, սխալ է տեղի ունեցել",
  Printing_:"Տպագրություն...",
  PrinterSuccess:"Փաստաթուղթը հաջողությամբ տպագրվել է",
  PrintersSuccess:"Փաստաթղթերը հաջողությամբ տպագրվել են",
  Print_Cheques:"Տպել ստուգումներ",
  New_Message:"Նոր հաղորդագրություն",
  New_Messages:"Նոր հաղորդագրություններ",
  Read_Message:"Կարդալ հաղորդագրություն",
  Write_Message:"Գրել հաղորդագրություն",
  Send_Message:"Ուղարկել հաղորդագրություն",
  Subject:"Առարկա",
  Message:"Ուղերձ",
  Writing:"Գրել…",
  Send:"Ուղարկել",
  Set_Date:"Սահմանել ամսաթիվը",
  Set_Time:"Սահմանել ժամանակը",
  Recieve:"Ստանալ",
  Set_as_Default:"Սահմանել որպես լռելյայն",
  Default_Account:"Լռելյայն հաշիվ",
  Default_Design:"Լռելյայն դիզայն",
  Multiple_Cheques:"Չեկեր (եռակի)",
  Account_Mode:"Հաշվի ռեժիմ",
  Multiple_Cheques_Description:"Երեք ստուգում մեկ էջի համար",
  Check_and_Table:"Ստուգում և աղյուսակ",
  Check_including_Table:"Չեկ և հաշվապահական աղյուսակ",
  Check_Mailer:"Ստուգեք փոստարկղը",
  Check_Mailer_Window:"Ստուգեք հասցեի պատուհանով",
  DocuGard_Table_Top:"DocuGard Check & Table (վերև)",
  DocuGard_Table_Middle:"DocuGard ստուգում և աղյուսակ (միջին)",
  DocuGard_Table_Bottom:"DocuGard ստուգում և աղյուսակ (ներքևում)",
  DocuGard_Mailer_Top:"DocuGard Check Mailer (վերևում)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (Middle)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mailer (ներքևում)",
  DocuGard_Three_Cheques:"DocuGard Checks (Tripple)",
  DocuGard_Cheque_Top:"DocuGard ստուգում (վերևում)",
  DocuGard_Cheque_Middle:"DocuGard ստուգում (միջին)",
  DocuGard_Cheque_Bottom:"DocuGard ստուգում (ներքևում)",
  DocuGard_Three_Cheques_Window:"Երեք ստուգում մեկ էջի վրա",
  DocuGard_Table_Top_Window:"Ստուգեք և եկամուտների աղյուսակ",
  DocuGard_Table_Middle_Window:"Ստուգեք և եկամուտների աղյուսակ",
  DocuGard_Table_Bottom_Window:"Ստուգեք և եկամուտների աղյուսակ",
  DocuGard_Mailer_Top_Window:"Ստուգ, աղյուսակ և հասցե",
  DocuGard_Mailer_Middle_Window:"Ստուգ, աղյուսակ և հասցե",
  DocuGard_Mailer_Bottom_Window:"Ստուգ, աղյուսակ և հասցե",
  DocuGard_Cheque_Top_Window:"Ստուգեք ապահով թղթի առկայությունը",
  DocuGard_Cheque_Middle_Window:"Ստուգեք ապահով թղթի առկայությունը",
  DocuGard_Cheque_Bottom_Window:"Ստուգեք ապահով թղթի առկայությունը",
  Basic_Cheque:"Ստուգել (վերև)",
  Basic_Cheque_Print:"Տպեք մեկ կտրոն",
  Error_Setting_As_Paid:"Սխալ՝ որպես վճարովի սահմանելը",
  Add_Attachment:"Ավելացնել հավելված",
  PrinterUnavailable:"Տպիչն անհասանելի է",
  CreditCardComponent:"Քարտեր",
  PaypalComponent:"PayPal",
  InteracComponent:"Ինտերակ",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Նոր ավանդ",
  Deposits:"Ավանդներ",
  No_Deposits:"Ավանդներ չկան",
  Credit_Card_Deposit:"ԿՐԵԴԻՏ քարտ",
  New_Credit_Card_Deposit_Message:"Վարկային քարտի ավանդ",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin ավանդ",
  New_Interac_Deposit:"Ինտերակ",
  New_Interac_Deposit_Message:"Interac Transfer",
  Payments_Limit:"Վճարման սահմանաչափ",
  No_Payment_Limit:"Վճարման սահմանափակում չկա",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal Ավանդ",
  No_Deposits_Word:"Ավելացրեք առաջին ընկերության <a routerLink='/folder/Deposit/New'>Ավանդը</a>",
  No_Documents_Specified:"Տպագրման համար փաստաթղթեր չեն նշվել",
  No_Printers_Added:"Տպիչներ չեն գտնվել: Գնացեք Կարգավորումներ > Տպիչներ՝ մեկը ավելացնելու համար",
  DB_Erase_Prompt:"Ամբողջությամբ ջնջե՞լ տվյալների բազան: ԶԳՈՒՇԱՑՈՒՄ. Դուք կկորցնեք բոլոր պահված տեղեկությունները:",
  Console_Warning:"Մի տեղադրեք որևէ տեքստ այս վահանակի մեջ: Դուք կարող եք ձեզ և/կամ ձեր ընկերությանը լուրջ վտանգի ենթարկել",
  No_Faxes_Word:"Ստեղծեք առաջին <a routerLink='/folder/Fax/New'>Ֆաքսը</a>",
  Cheque_Delete_Confirmation:"Վստա՞հ եք, որ ցանկանում եք ջնջել այս ստուգումը",
  Design_Delete_Confirmation:"Վստա՞հ եք, որ ցանկանում եք ջնջել այս դիզայնը",
  Cheque_Pay_Confirmation:"Նշե՞լ այս չեկը որպես վճարովի: Այն ՉԻ հայտնվի տպագրության հերթում",
  Payment_Pay_Confirmation:"Նշե՞լ այս վճարումը որպես վճարված: Այն ՉԻ հայտնվի չեկի հերթում",
  Delete_Deduction_Confirmation:"Վստա՞հ եք, որ ցանկանում եք ջնջել այս նվազեցումը",
  Delete_Job_Confirmation:"Վստա՞հ եք, որ ցանկանում եք ջնջել այս աշխատանքը",
  Delete_Timesheet_Confirmation:"Վստա՞հ եք, որ ցանկանում եք ջնջել այս ժամանակացույցը",
  Delete_Schedule_Confirmation:"Վստա՞հ եք, որ ցանկանում եք ջնջել այս ժամանակացույցը",
  Delete_Setting_Confirmation:"Վստա՞հ եք, որ ցանկանում եք վերակայել այս կարգավորումը",
  Delete_Fax_Confirmation:"Վստա՞հ եք, որ ցանկանում եք ջնջել այս ֆաքսը",
  Delete_File_Confirmation:"Վստա՞հ եք, որ ցանկանում եք ջնջել այս ֆայլը",
  Delete_Vacation_Confirmation:"Վստա՞հ եք, որ ցանկանում եք ջնջել այս արձակուրդը",
  Delete_Printer_Confirmation:"Վստա՞հ եք, որ ցանկանում եք ջնջել այս տպիչը",
  Remove_Design_Confirmation:"Վստա՞հ եք, որ ցանկանում եք ջնջել այս դիզայնը",
  Delete_Payroll_Confirmation:"Վստա՞հ եք, որ ցանկանում եք ջնջել այս աշխատավարձը",
  Send_Fax_Email_Confirmation:"Ցանկանու՞մ եք ֆաքսով և էլեկտրոնային փոստով ուղարկել այս փաստաթղթերը",
  Send_Email_Confirmation:"Ցանկանու՞մ եք էլեկտրոնային փոստով ուղարկել այս փաստաթուղթը",
  Send_Fax_Confirmation:"Ցանկանու՞մ եք ֆաքս ուղարկել այս փաստաթուղթը",
  Error_Generating_PDF:"Ներողություն: Այս փաստաթուղթը ստեղծելիս սխալ տեղի ունեցավ",
  PDF_Error_Saving_Image:"Ներողություն: Այս փաստաթղթի PDF պատկերը պահելիս սխալ տեղի ունեցավ",
  Test_Printer_Confirmation:"Ցանկանու՞մ եք այս տպիչի վրա փորձնական էջ տպել",
  Save_Timesheet_Prompt:"Խնդրում ենք ավելացնել \"Վերնագիր\" կամ սեղմել \"Սկսել ժմչփը\"՝ պահպանելու համար",
  Remove_Geofence_Prompt:"Վստա՞հ եք, որ ցանկանում եք հեռացնել այս աշխարհագրական ցանկապատի գտնվելու վայրը",
  Delete_Employee_Confirmation:"Վստա՞հ եք, որ ցանկանում եք ջնջել",
  Fire_Employee_Confirmation:"Վստա՞հ ես, որ ուզում ես կրակել"
}