export const He = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"זכויות יוצרים &copy; 2023",
  black:"שָׁחוֹר",
  green:"ירוק",
  gold:"זהב",
  blue:"כְּחוֹל",
  brown:"חום",
  purple:"סָגוֹל",
  pink:"וָרוֹד",
  red:"אָדוֹם",
  Swatches:"דוגמיות",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"צֶבַע",
  Saturation:"רִוּוּי",
  Lightness:"קְלִילוּת",
  Upgrade_To_Pro:"שדרוג לפרו",
  AllFeaturesInclude:"כל התכונות כוללות:",
  PrintUnlimitedCheques:"הדפס צ'קים ללא הגבלה",
  PremiumChequeDesigns:"עיצובי צ'קים פרימיום",
  ManageUnlimitedEmployees:"ניהול עובדים ללא הגבלה",
  AddUnlimitedPayees:"הוסף מקבלי תשלום ללא הגבלה",
  CreateUnlimitedPayrolls:"צור מספרי שכר ללא הגבלה",
  UnlimitedSchedulesandTimeSheets:"לוחות זמנים ודפי זמן ללא הגבלה",
  BulkPayPalPayouts:"תשלומי PayPal בכמות גדולה",
  UnlimitedBankAccounts:"חשבונות בנק ללא הגבלה",
  ManageMultipleCompanies:"ניהול מספר חברות",
  TrackInsurancePolicies:"עקוב אחר פוליסות ביטוח",
  Bio_MetricProtection:"הגנה ביו-מטרית",
  Geo_FenceLock_OutProtection:"הגנת נעילה גיאוגרפית",
  Multiple_Companies_Word:"ניהול מספר חברות אינו זמין ללא צ'קים פרמיה.",
  PayPal_Payouts_Word:"תשלומי PayPal מושבתים ללא פרמיית צ'קים.",
  PINProtection:"הגנת PIN",
  PasswordProtection:"הגנת סיסמה",
  May_Require_Approval:"עשוי לדרוש אישור.",
  Subscribe:"הירשם",
  Billed:"מחויב",
  Up:"לְמַעלָה",
  Down:"מטה",
  Positioning:"מיקום",
  Marker:"סַמָן",
  Drag_Marker:"סמן גרור",
  Tagline:"הדפס צ'קים וטבלט שכר",
  Marker_Word:"השתמש בסמנים לגודל האלמנט.",
  Pinch_Zoom:"צביטה זום",
  Pinch_Word:"צביטה כדי להגדיל את האלמנט.",
  Drag:"לִגרוֹר",
  Drag_Word:"השתמש באצבע שלך כדי לגרור אלמנטים.",
  subscription_alias_word:"חידוש אוטומטי של מנוי",
  premium_alias_word:"חבילת שדרוג חד פעמית",
  print_alias_word:"הדפסת צ'קים בודדים",
  mode_alias_word:"מצב",
  Pro:"מִקצוֹעָן",
  Pro_word:"דרושה גרסת פרו.",
  Cant_Delete_Default_Company_Word:"מצטערים, אינך יכול למחוק את חברת ברירת המחדל שלך.",
  Reinsert_Default_Designs:"הכנס מחדש עיצובי ברירת מחדל",
  Reinsert_Default_Designs_word:"האם ברצונך להכניס מחדש עיצובי ברירת מחדל?",
  Subscription_Active_Disable_Word:"מנוי זה פעיל. האם תרצה לבטל מנוי זה לצ'קים?",
  Company_Logo:"לוגו חברה",
  ZERO_:"אֶפֶס",
  Disclaimer:"כתב ויתור",
  Privacy_Policy:"מדיניות הפרטיות",
  EULA:"בודק EULA",
  Terms_Of_Service:"תנאי השירות",
  Terms_Of_Use:"תנאי שימוש",
  Refunds:"מדיניות החזרים",
  Single_Print:"1 בדוק",
  Two_Prints:"2 צ'קים",
  Five_Prints:"5 צ'קים",
  Ten_Prints:"10 צ'קים",
  Fifteen_Prints:"15 צ'קים",
  Twenty_Prints:"20 צ'קים",
  Thirty_Prints:"30 צ'קים",
  Image_Added:"תמונה נוספה",
  Image_Preview:"תצוגה מקדימה של תמונה",
  No_Image_Was_Selected:"לא נבחרה תמונה.",
  Cheques_Unlimited:"צ'קים ללא הגבלה",
  _Subscription:"מִנוּי",
  Subscription:"צ'קים - חודש",
  Two_Month_Subscription:"צ'קים - חודשיים",
  Three_Month_Subscription:"צ'קים - 3 חודשים",
  Six_Month_Subscription:"צ'קים - 6 חודשים",
  Twelve_Month_Subscription:"צ'קים - 12 חודשים",
  Cheques_Are_Available:"שיקים זמינים להדפסה.",
  Upgrade_Required_Two:"בחר חבילה והקש פעמיים על כפתור המחיר כדי להתחיל ברכישה. הדפס צ'קים בצבע מלא במראה מקצועי תוך שניות.",
  Month:"חוֹדֶשׁ",
  Due:"בשל:",
  Expires:"פג תוקף:",
  Subscription_Active:"מנוי פעיל",
  Subscription_Inactive:"מנוי לא פעיל",
  Purchase_Additional_Cheques:"לרכוש צ'קים נוספים?",
  Printable_Cheque:"צ'ק להדפסה",
  Printable_Cheques:"צ'קים להדפסה",
  Printable_Cheque_Word:"המחאה זמינה בחשבון שלך.",
  Printable_Cheques_Word:"המחאות זמינות בחשבונך.",
  Max_Amount_Message:"הסכום שציינת עבר את הסכום המקסימלי שנקבע עבור מערכת זו:",
  Terms_Required_Word:"עליך להסכים להסכם זה לפני שתמשיך להשתמש בצ'קים.",
  Subscriptions:"מנויים",
  Product_Upgrades:"שדרוגים",
  Mailed_Out_Cheques:"צ'קים שנשלחו בדואר",
  Enter_A_Company_Name:"נא להזין שם חברה.",
  Single_Cheques:"צ'קים בודדים",
  Cheque_Golden:"צ'ק זהב",
  Cheque_Golden_Window:"עיצוב צ'ק זהב.",
  Cheque_Green:"צ'ק ירוק",
  Cheque_Green_Window:"עיצוב צ'ק ירוק.",
  Cheque_Red:"צ'ק אדום",
  Cheque_Red_Window:"עיצוב צ'ק אדום.",
  Cheque_Yellow:"צ'ק צהוב",
  Cheque_Yellow_Window:"עיצוב צ'ק צהוב.",
  Cheque_Statue_of_Liberty:"פסל החירות",
  Cheque_Statue_of_Liberty_Window:"עיצוב המחאה של פסל החירות.",
  Cheque_Green_Wave:"גל ירוק",
  Cheque_Green_Wave_Window:"עיצוב צ'ק ירוק.",
  Cheque_Golden_Weave:"מארג הזהב",
  Cheque_Golden_Weave_Window:"עיצוב שיק מוזהב אלגנטי.",
  Cheque_Green_Sun:"שמש ירוקה",
  Cheque_Green_Sun_Window:"עיצוב צ'קים עמוק ומרגיע.",
  Cheque_Blue_Checkers:"דמקה כחולה",
  Cheque_Blue_Checkers_Window:"עיצוב צ'ק כחול.",
  Cashiers_Check:"צ\'ק קופאי",
  Cashiers_Check_Window:"תבנית סגנון צ'ק קופאי.",
  Cheque_Aqua_Checkers:"אקווה דמקה",
  Cheque_Aqua_Checkers_Window:"עיצוב אקווה צ'ק.",
  Cheque_Golden_Checkers:"דמקה זהב",
  Cheque_Golden_Checkers_Window:"עיצוב צ'ק זהב.",
  Upgrade_Unavailable:"שדרוגים לא זמינים",
  Bank_Code_Protection:"הגנה על מספר בנק",
  Bank_Code_Protection_Word:"הגן על מספרי הבנק שלך מפני שימוש באפליקציה זו הפועלת במכשיר אחר או עבור משתמש אחר. פעולה זו היא בלתי הפיכה. לְהַמשִׁיך?",
  Bank_Code_Protection_Blocked_Word:"מספרי הבנק שבהם אתה מנסה להשתמש שמורים למשתמש אחר או למכשיר אחר.",
  Bank_Code_Protection_Error_Word:"אימות המספר נכשל. אנא התחבר לאינטרנט ונסה להוסיף שוב את חשבון הבנק הזה.",
  Bank_Code_Protection_Set_Error_Word:"הגנה על מספר בנק מחייבת שתהיה מחובר לאינטרנט. אנא התחבר ונסה שוב.",
  Upgrade_Unavailable_Word:"מצטערים, אנו מתקשים לאמת אותך. מנויים ושדרוגים לצ'קים אינם זמינים כעת לרכישה באזור שלך.",
  PayPal_Payment_Preview:"תצוגה מקדימה של PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"בחר PayPal",
  PayPal_Applications:"יישומי PayPal",
  Purchase_With_Apple_Pay:"רכישה באמצעות Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"חברות",
  Insurance:"ביטוח",
  New_PayPal:"PayPal חדשה",
  Pay_By:"שלם לפי",
  Insure:"לְהַבטִיחַ",
  Miles:"מיילס",
  Payment_Method:"אמצעי תשלום",
  Select_Policies:"בחר מדיניות",
  Policies:"מדיניות",
  Policy:"מְדִינִיוּת",
  No_PayPal_Account:"אין חשבון PayPal",
  No_Policies:"אין פוליסות ביטוח",
  New_Policy:"מדיניות חדשה",
  PayPal_Payment:"תשלום PayPal",
  PayPal_Payments:"PayPal תשלומים",
  No_Payment_Selected:"לא נבחר תשלום",
  Sending_Payment_Word:"אנא המתן... תשלום זה נשלח.",
  Sending_Payments_Word:"אנא המתן... תשלומים נשלחים.",
  No_Payment_Selected_PayPal:"לא נבחר <a routerLink='/folder/Payments'>תשלום ב-PayPal</a> לשליחה.",
  Payment_Sent:"תשלום נשלח",
  PayPal_Payment_Sent:"תשלום זה נשלח באמצעות PayPal.",
  Copay:"Copay",
  Dead:"מֵת",
  Alive:"בחיים",
  Not_Dead:"לא מת",
  Unclaimed:"לא נתבע",
  Attempted:"ניסיתי",
  Deceased:"נפטר",
  Claimed:"נִתבָּע",
  Unpaid:"ללא תשלום",
  Sending_Payment:"שולח תשלום",
  Sending_Payments:"שליחת תשלומים",
  Send_PayPal_Confirmation:"האם ברצונך לשלוח את העסקה הזו עם PayPal?",
  Send_PayPal_Confirmation_Word:"לחץ על הכפתור הירוק כדי לשלוח עסקה זו.",
  Save_Payment_As_Unpaid:"האם לשמור את התשלום הזה כלא שולם?",
  Payment_Pay_Confirmation_PayPal:"האם לשמור את התשלום הזה כתשלום?",
  No_Policies_Word:"הוסף את <a routerLink='/folder/Postage/New'> פוליסת הביטוח</a> הראשונה עכשיו.",
  Timesheet_Multiple_Delete_Confirmation:"האם אתה בטוח שברצונך למחוק דפי זמן מרובים?",
  Select_Multiple_Timesheets_Prompt:"לא נבחרו דפי זמן. בחר לפחות גיליון זמנים אחד.",
  Select_Multiple_Policies_Prompt:"לא נבחרו מדיניות. בחר לפחות פוליסת ביטוח אחת.",
  Policies_Multiple_Delete_Confirmation:"האם אתה בטוח שברצונך למחוק מספר מדיניות?",
  Company:"חֶברָה",
  Add_Company:"הוסף חברה",
  New_Company:"הוסף חברה",
  No_Companies:"אין חברות",
  Enable_Company:"אפשר חברה",
  Select_Company:"בחר חברה",
  The_Default_Company:"תווית ברירת המחדל של החברה.",
  Manage_Companies:"ניהול חברות",
  No_Companies_Word:"המחאות ישתמשו בחברת ברירת מחדל .<br />הוסף את <a routerLink='/folder/Company/New'>החברה הראשונה</a>.",
  Default_Company:"חברת ברירת מחדל",
  Cheques_Unlimited_Word:"צ'קים ללא הגבלה מאפשרת לך להדפיס כמה צ'קים שתרצה.",
  Cheques_Subscription_Word:"מנוי צ'קים מאפשר לך להדפיס כמה צ'קים שתרצה.",
  You_Own_This_Product:"אתה הבעלים של המוצר הזה.",
  Your_Subscription_is_Active:"המנוי שלך פעיל.",
  Active_Products:"מוצרים מופעלים",
  Purchase_Confirmation:"לִרְכּוֹשׁ",
  Purchase_Cheques:"צ'קים לרכישה",
  Restore_Purchase:"לשחזר רכישות",
  Erase_Purchase:"מחק רכישות",
  Successfully_Purchased:"נרכש בהצלחה",
  Enter_Your_Licence_Key:"אנא הזן את מפתח הרישיון שלך בדף זה כדי להפעיל מוצר זה.",
  Licence_Key:"מפתח רישיון",
  Enter_Licence_Key:"הזן מפתח רישיון",
  Purchased:"נרכש",
  Enable_Feature:"הפעל תכונה",
  Cancel_Subscription:"בטל רישום",
  Subscription_Removed:"המנוי הוסר",
  Select_Active_Subscription:"בחר מנוי פעיל כדי לשנות אותו.",
  Remove_Subscription_Word:"האם אתה בטוח שברצונך לבטל את המנוי הזה?",
  Delete_Company_Confirmation:"האם אתה בטוח שברצונך למחוק את כל החברה הזו? אזהרה: תאבד את כל המידע המאוחסן!",
  Delete_Default_Company_Word:"אינך יכול למחוק את חברת ברירת המחדל. האם תרצה לאפס את היישום ולשחזר אותו למצב ברירת המחדל? אזהרה: תאבד את כל המידע המאוחסן!",
  Console_Warning_2:"אל תטפל בשום מטבע באמצעות יישום זה שאינו שלך כרגע.",
  Terms_and_Conditions:"תנאים",
  and_the:"וה",
  for:"ל",
  Please_Read_Word:"נא לקרוא ולהסכים ל",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"חלק משערי המרת מטבע לא נמצאו. אנא התחבר לאינטרנט.",
  Free:"חינם",
  DB_Erase_Prompt_2:"למחוק לחלוטין את כל מסד הנתונים של המפתחים? אזהרה: תאבד את כל פרטי הרכישה וההרשמה!",
  Successfully_Imported:"יובא בהצלחה",
  Select_Postage:"בחר דואר",
  No_Postage:"ללא בולי דואר",
  No_Paid_Postage_Word:"הוסף את חותמת <a routerLink='/folder/Postage/New'>דואר בתשלום</a> הראשון .",
  Trial_Complete:'ניסיון הושלם',
  Please_Upgrade:'שדרג את צ\'קים כדי להמשיך בהדפסה.',
  Aa:"אא",
  Color:"צֶבַע",
  Font:"גוֹפָן",
  Guide:"להנחות",
  Guides:"מדריכים",
  Image:"תמונה",
  Zoom:"תקריב",
  Logo:"סֵמֶל",
  Bank:"בַּנק",
  MICR:"MICR",
  Total:"סך הכל",
  Cancel:"לְבַטֵל",
  Confirm:"לְאַשֵׁר",
  Method:"שיטה",
  Biometric_Security:"אבטחה ביומטרית",
  Please_Login_To_Continue:"אנא התחבר על מנת להמשיך.",
  Complete:"לְהַשְׁלִים",
  Sign_Up:"הירשם",
  Error:"שְׁגִיאָה",
  Biometrics:"ביומטרים",
  Percent:"אָחוּז",
  Zero_Percent:"0%",
  Top_Margin:"שוליים עליונים",
  Bottom_Margin:"שוליים תחתונים",
  Left_Margin:"השוליים השמאליים",
  Right_Margin:"שוליים ימין",
  MICR_Margin:"שולי MICR",
  Table_Margin:"שולי טבלה",
  Address_Margin:"שולי כתובת",
  Percentage_:"אֲחוּזִים",
  Vacation_Title:"כותרת חופשה",
  Use_PIN:"השתמש ב-PIN",
  Loading__:"טוען...",
  Design_Title:"כותרת עיצוב",
  Authorize:"לְאַשֵׁר",
  Key:"מַפְתֵחַ",
  Public_Key:"מפתח ציבורי",
  Private_Key:"מפתח פרטי",
  Authenticate:"לְאַמֵת",
  Last_Payroll:"שכר אחרון",
  Last_Calculation:"חישוב אחרון",
  Authorized:"מורשה",
  Geo_Authorized:"מיקום גיאוגרפי: מורשה",
  Not_Authorized:"לא מורשה",
  Authorization_Complete:"ההרשאה הושלמה",
  Geolocation_Authorization:"אישור מיקום גיאוגרפי",
  Out_of_Bounds:"מחוץ לתחום",
  Cant_Delete_Default_Design:"לא ניתן למחוק עיצוב ברירת מחדל.",
  Unauthorized_Out_of_Bounds:"לא מורשה: מחוץ לתחום",
  Biometric_Authorization:"הרשאה ביומטרית",
  Locating_Please_Wait:"מאתר, נא המתן...",
  Test_Biometrics:"בדיקת ביו-מטרים",
  Cheque_Margins:"בדוק שוליים",
  Percentage_Full_Error:"לא ניתן להגדיר את שדה האחוזים מעל 100% אחוז.",
  No_Payroll_Text:"הוסף <a routerLink='/folder/Employee/New'>עובד</a> או <a routerLink='/folder/Payee/New'>מקבל תשלום</a> ו<a routerLink='/folder/Schedule/New'>לוח זמנים</a>.",
  Design_Saved:"העיצוב נשמר",
  Default_Design_Selected:"עיצוב ברירת מחדל נבחר",
  Partial_Import:"יבוא חלקי",
  Partial_Export:"ייצוא חלקי",
  Entire_Import:"יבוא שלם",
  Entire_Export:"ייצוא שלם",
  Existing_Password:"נא להזין את הסיסמה הקיימת שלך:",
  Existing_PIN:"אנא הזן את קוד ה-PIN הקיים שלך:",
  Pin_Change_Header:"אישור PIN",
  Pin_Change_SubHeader:"הזן את מספר ה-PIN הישן שלך כדי לאשר את השינוי.",
  Pass_Change_Header:"אימות סיסמה",
  Pass_Change_SubHeader:"הזן את הסיסמה הישנה שלך כדי לאשר את השינוי.",
  PIN_Enter_Message:"הזן את ה-PIN שלך כדי לאשר.",
  Password_Enter_Message:"הזן את הסיסמה שלך כדי לאשר.",
  Pin_Updated_Success:"PIN עודכן בהצלחה!",
  Pin_Updated_Fail:"לא ניתן היה לעדכן את ה-PIN.",
  Pass_Updated_Success:"הסיסמא עודכנה בהצלחה.",
  Pass_Updated_Fail:"לא ניתן היה לעדכן את הסיסמה.",
  Preview_Payment:"תצוגה מקדימה של תשלום",
  Preview_Payroll:"תצוגה מקדימה של שכר",
  No_Payments_Created:"לא נמצאו תשלומים שנוצרו.",
  Payment_Preview:"תצוגה מקדימה של תשלום",
  Enable_Payee:"הפעל את מקבל התשלום",
  Rendered:"עיבוד",
  No_Email:"אין אימייל",
  Setup_Cheques:"בדיקות התקנה",
  name:"שֵׁם",
  address:"כתובת",
  address_2:"שורת כתובת 2",
  city:"עִיר",
  province:"מָחוֹז",
  postal_code:"מיקוד/מיקוד",
  country:"מדינה",
  username:"שם משתמש",
  full_name:"שם מלא",
  birthday:"יום הולדת",
  email:"אימייל",
  phone:"טלפון",
  employees:"עובדים",
  addresses:"כתובות",
  payment_amount_limit:"מגבלת סכום תשלום",
  total_limit:"גבול מוחלט",
  payees:"מקבלי תשלום",
  description:"תיאור",
  address_line_one:"כתובת שורה אחת",
  address_line_two:"כתובת שורת שתיים",
  image:"תמונה",
  account_holder:"בעל החשבון",
  cheque_starting_id:"בדוק את מזהה ההתחלה",
  transit_number:"מספר מעבר",
  institution_number:"מספר מוסד",
  designation_number:"מספר ייעוד",
  account_number:"מספר חשבון",
  currency:"מַטְבֵּעַ",
  signature:"חֲתִימָה",
  payment_payroll_limit:"מגבלת תשלום",
  total_limi:"מגבלה כוללת",
  date:"תַאֲרִיך",
  printed_memo:"תזכיר מודפס",
  banks:"בנקים",
  signature_image:"תמונת חתימה",
  address_name:"כתובת",
  notes:"הערות",
  design:"לְעַצֵב",
  title:"כותרת",
  frequency:"תדירות",
  fax:"פַקס",
  salaries:"משכורות",
  salary_ids:"מזהי שכר",
  start_time:"שעת התחלה",
  end_time:"שעת סיום",
  paid:"שולם",
  overtime_percentage:"אחוז בתשלום",
  overtime_amount:"סכום קבוע ששולם",
  first_name:"שם פרטי",
  last_name:"שם משפחה",
  moderation:"מְתִינוּת",
  create:"לִיצוֹר",
  edit:"לַעֲרוֹך",
  destroy:"להרוס",
  day_start_time:"שעת_התחלה_יום",
  day_end_time:"שעת_סיום_יום",
  fullname:"שֵׁם",
  time:"זְמַן",
  auto_send:"נשלח אוטומטית",
  time_method:"שיטת זמן",
  schedules:"לוחות זמנים",
  indefinite_payroll_enabled:"הפעל ללא הגבלת זמן",
  amount:"כמות",
  repeat:"חזור",
  always_enabled:"מופעל תמיד",
  start_date:"תאריך התחלה",
  end_date:"תאריך סיום",
  Cheque_Total:"סמן סך הכל",
  Total_Amount:"סכום סופי:",
  Amounts:"סכומים:",
  Images:"תמונות",
  Files:"קבצים",
  Previewing:"תצוגה מקדימה:",
  Insert:"לְהַכנִיס",
  Preview_Import:"תצוגה מקדימה של ייבוא",
  Entry:"כְּנִיסָה",
  Entries:"ערכים",
  No_Entries:"אין כניסות",
  Import_Type:"סוג ייבוא",
  Select_Details:"בחר פרטים",
  Select_Payee:"בחר מקבל תשלום",
  Enable_Holidays:"אפשר חגים",
  Disable_Holidays:"השבת חגים",
  Wire_Transfer:"העברה בנקאית",
  New_Export:"ייצוא חדש",
  Export_Data:"ייצוא נתונים",
  Export_Data_Word:"בחר את סוג הקובץ לייצוא ולהורדה.",
  Export_File:"ייצוא קובץ",
  Mode:"מצב",
  Times:"פִּי",
  Widgets:"ווידג'טים",
  Slider:"סליידר",
  Set_Details:"הגדר פרטים",
  Select_Type:"בחר סוג",
  Display_Slider:"מחוון תצוגה",
  Dashboard_Slider:"מחוון לוח המחוונים",
  Dashboard_Mode:"מצב לוח מחוונים",
  Show_Intro:"הצג מבוא",
  Show_Payrolls:"הצג תלושי שכר",
  Show_Holidays:"הצג חגים",
  Show_Invoices:"הצג חשבוניות",
  Show_Cheques:"הצג צ'קים",
  Enabled_Widgets:"ווידג'טים מופעלים",
  Disabled_Widgets:"ווידג'טים מושבתים",
  Colors:"צבעים",
  Barcodes:"ברקודים",
  View_Timers:"הצג טיימרים",
  Gradients:"שיפועים",
  No_Info:"אין מידע",
  Disable:"השבת",
  Show_Layer:"הצג שכבות",
  Hide_Layer:"הסתר שכבות",
  Text_Layer:"שכבות טקסט",
  Secondly:"שנית",
  Minutely:"בדקה",
  nine_am:"09:00",
  five_pm:"5:00 אחר הצהריים",
  Enable_Address:"הפעל כתובת",
  Invalid_File_Type:"מצטערים, נבחר סוג קובץ לא חוקי. סוג קובץ נתמך:",
  Error_Updating_Entry:"מצטערים, אירעה שגיאה בעדכון ערך זה.",
  Schedule_Timing_Alert:"שגיאה: זמן ההתחלה של לוח הזמנים מוגדר לערך לאחר שעת הסיום.",
  Select_Multiple_Payments_Prompt:"לא נבחרו תשלומים. בחר תשלום אחד לפחות.",
  Select_Multiple_Cheques_Prompt:"לא נבחרו המחאות. אנא בחר צ'ק אחד לפחות.",
  Select_Multiple_Items_Prompt:"לא נבחרו פריטים. אנא בחר פריט אחד לפחות.",
  Paymemt_Multiple_Delete_Confirmation:"האם אתה בטוח שברצונך למחוק תשלומים מרובים?",
  Cheque_Multiple_Delete_Confirmation:"האם אתה בטוח שברצונך למחוק מספר המחאות?",
  Payee_Multiple_Delete_Confirmation:"האם אתה בטוח שברצונך למחוק מספר מקבלי תשלום?",
  Employee_Multiple_Delete_Confirmation:"האם אתה בטוח שברצונך למחוק מספר עובדים?",
  MICR_Warning:"הערה: ייתכן שחלק מהמדפסות והתקנים לא יציגו את גופן MICR כהלכה.",
  Automatically_Send:"נשלח אוטומטית",
  Type:"סוּג",
  Payment_Type:"סוג תשלום",
  Auto_Send:"שלח אוטומטי",
  Automatically_Process:"עיבוד אוטומטי",
  Auto_Process:"תהליך אוטומטי",
  Image_Based:"מבוסס תמונה",
  Font_Based:"מבוסס על גופנים",
  Rerender:"עיבוד מחדש",
  Rendering:"טִיוּחַ",
  Render:"קבצים",
  Top:"חלק עליון",
  Middle:"אֶמצַע",
  Bottom:"תַחתִית",
  Top_Left:"שמאלי עליון",
  Top_Center:"מרכז העליון",
  Top_Right:"למעלה מימין",
  Middle_Left:"שמאל אמצעי",
  Middle_Center:"מרכז התיכון",
  Middle_Right:"ימין אמצעי",
  Bottom_Left:"שמאל תחתון",
  Bottom_Center:"מרכז תחתון",
  Bottom_Right:"למטה מימין",
  Numbers:"מספרים",
  Verified:"מְאוּמָת",
  Paper_Size:"גודל נייר",
  New_Device:"מכשיר חדש",
  Add_Device:"הוסף מכשיר",
  Remove_Device:"הסר מכשיר",
  Delete_Device:"מחק מכשיר",
  Block_Device:"חסום מכשיר",
  Block:"לַחסוֹם",
  Unblock:"בטל חסימה",
  Table:"שולחן",
  Scan_Login_Code:"סרוק את קוד התחברות",
  Login_Code:"קוד כניסה",
  Scan_Code:"סרוק קוד",
  Scan_QR_Code:"סרוק קוד QR",
  Select_All:"בחר הכל",
  Deselect_All:"הסר סימון מהכל",
  Increase:"להגביר",
  Decrease:"לְהַקְטִין",
  Bold:"נוֹעָז",
  Text:"טֶקסט",
  Style:"סִגְנוֹן",
  Italic:"נטוי",
  QR_Code:"קוד QR",
  Barcode:"ברקוד",
  Browse_Images:"עיין בתמונות",
  Browse_Files:"עיין בקבצים",
  Background_Image:"תמונת רקע",
  Logo_Image:"תמונת לוגו",
  Header_Image:"תמונת כותרת",
  Bank_Image:"תמונת בנק",
  Cut_Lines:"קווים חתוכים",
  Background:"רקע כללי",
  License:"רישיון",
  One_License:"רישיון 1:",
  Licensed:"מורשה ל:",
  Not_Licensed:"לא מורשה",
  Enter_Serial:"היכנס לסידורי",
  Serial_Key:"מפתח טורי",
  Serial:"סידורי",
  Product_Key:"מפתח מוצר",
  Check_Product_Key:"בדוק את מפתח המוצר",
  Add_Product_Key:"הוסף מפתח מוצר",
  Verifying_Purchase:"מאמת רכישה...",
  Print_Envelope:"הדפס מעטפה",
  Envelope:"מַעֲטָפָה",
  Thank_You:"תודה!",
  Scale:"סוּלָם",
  Print_Scale:"סולם הדפסה",
  Borders:"גבולות",
  VOID:"בָּטֵל",
  Void_Cheque:"בדיקת חלל",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"שלם לפקודת:",
  NO_DOLLARS:"ללא דולרים ",
  ONE_DOLLAR:"דולר אחד",
  DOLLARS:" דולרים",
  AND:" ו ",
  CENTS:" סנטים.",
  NO_:"לא ",
  ONE_:"אחד ",
  AND_NO_:"ולא ",
  _AND_ONE_:"ואחד ",
  DOLLARS_AND_ONE_CENT:" וסנט אחד.",
  AND_NO_CENTS:" ואפס סנטים.",
  CHEQUE_PRINT_DATE:"תַאֲרִיך:",
  CHEQUE_PRINT_MEMO:"תַזכִּיר:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"משימות ראשוניות",
  Inital_Setup:"הגדרה ראשונית",
  Welcome_To:"ברוך הבא ל ",
  Welcome:"ברוך הבא",
  Swipe:"לִגנוֹב",
  Intro_Setup:"הגדרת מבוא",
  Introduction:"מבוא",
  CHEQUE_PRINT_CREDIT:"מופעל על ידי צ'קים",
  Title_Intro_Word:"ברוכים הבאים לצ'קים",
  Title_Intro:"הקדמה להמחאות",
  Title_Setup:"הגדרת המחאות",
  PayPal_Default_Email_Message:"קיבלת העברה חדשה של PayPal. [מופעל על ידי צ'קים]",
  Cheques_App_Export:"יוצא בשיקים",
  Post_Intro_Thanks:"תודה שבחרת בצ'קים. תהליך ההגדרה הושלם כעת.",
  Post_Intro_Word:"התחל על ידי הדפסת ההמחאה הראשונה שלך, הוסף תשלום עתידי או הוסף עובד ללוח השכר.",
  Upgrade_Required:"המחאות מחייבות אותך להיות בעלים של גרסה פרימיום יותר של התוכנה כדי להסתיר הודעה זו ולפתוח תכונות נוספות.",
  Upgrade_Title:"צ'קים",
  Mailout_Prompt:"תוכל בנוסף לבחור שצ'קים ישלחו עבורך את המחאות השכר שלך בדואר.",
  Mailed_Cheque:"מחאה שנשלחה בדואר: ",
  Mailed_Cheque_Color:"מחאה שנשלחה בדואר (צבע): ",
  Terms_Purchase:"כל הרכישות האלקטרוניות עם צ'קים ניתנות להחזר מלא עד 30 יום מתאריך הרכישה. אנא קרא והסכים ל<a href='#'>תנאים והגבלות</a> לפני שתמשיך.",
  Dashboard_Setup:"הגדר את המדפסת הראשית",
  Dashboard_Add:"הוסף את חשבון הבנק הראשי",
  Dashboard_Customize:"בחר תבנית בדיקה",
  Dashboard_Job_Salary:"צור את העבודה שלך והוסף את המשכורת שלך",
  Dashboard_Employees:"עקוב אחר עובדים ומקבלי תשלום",
  Dashboard_Print:"הדפס ודואר את תלושי המשכורת שלך",
  Dashboard_Payroll:"הפוך את הדפסת השכר שלך לאוטומטית",
  Dashboard_PayPal:"הגדר שכר / תשלומים של PayPal",
  Begin_Setup:"התחל בהתקנה",
  Get_Started:"להתחיל",
  Purchase:"לִרְכּוֹשׁ",
  Lockdown:"סגר",
  Unlock:"לבטל נעילה",
  Detailed:"מְפוֹרָט",
  Log_In:"התחברות",
  Login:"התחברות",
  Launch:"לְהַשִׁיק",
  Register:"הירשם",
  Finish:"סיים",
  List:"רשימה",
  Weekends:"סופי שבוע",
  Weekly:"שְׁבוּעִי",
  PayPal_Default_Subject:"תשלום חדש",
  Payment_Message:"הודעת תשלום",
  PayPal_Default_Payment_Note:"תודה",
  Setup_Your_Cheqing_Account:"חשבון המחאה",
  Add_Your_Primary_Cheqing_Account:"הוסף את חשבון הצ'ק הראשי שלך.",
  Welcome_Word:"פשט והפוך את ניהול שכר ומשאב אנוש לאוטומטי",
  Get_Started_Quickly:"פשוט תענה על כמה שאלות פשוטות שיעזרו לנו להתחיל...",
  Done_Intro_Word:"ההתקנה הושלמה",
  PIN_Protected:"סיסמה ו-PIN מוגנים",
  Enter_New_PIN:"הזן קוד PIN חדש:",
  Enter_PIN:"הזן קוד PIN:",
  Invalid_PIN:"הזנת PIN לא חוקי.",
  Account_Identifier:"מזהה חשבון",
  New_Account_Identifier:"מזהה חשבון חדש",
  attempt:"לְנַסוֹת",
  attempts:"ניסיונות",
  remaining:"נוֹתָר",
  Language:"שפה",
  languages:"שפות",
  select_languages:"בחר שפה",
  Deposit:"לְהַפְקִיד",
  Hire_One_Now:"שכר אחד עכשיו",
  App_Locked:"האפליקציה נעולה.",
  Skip_:"לדלג",
  Skip_to_Dashboard:"דלג ללוח המחוונים",
  Dashboard:"לוּחַ מַחווָנִים",
  Import:"יְבוּא",
  Admin:"מנהלים",
  New_Admin:"מנהל חדש",
  Settings:"הגדרות",
  Color_Picker:"בוחר הצבעים",
  Font_Picker:"בוחר גופנים",
  Logout:"להתנתק",
  Close:"סגור",
  Close_menu:"סגור",
  Excel:"קובץ אקסל",
  Csv:"קובץ CSV",
  Sql:"קובץ SQL",
  Json:"קובץ JSON",
  Url:"ייבוא ​​לפי כתובת אתר",
  Back:"חזור",
  Timers:"טיימרים",
  Cheque:"חשבון",
  Print:"הדפס",
  Designs:"עיצובים",
  Pause_Printing:"השהה הדפסה",
  Resume_Printing:"המשך הדפסה",
  Printing_Paused:"ההדפסה מושהית",
  PrintingUnavailable:"מצטערים! הדפסה אינה זמינה במערכת זו.",
  Prints_Paused:"ההדפסות מושהות",
  Administration:"מִנהָל",
  Loading:"טעינה",
  Unnamed:"ללא שם",
  error:"מצטערים, לא ניתן היה לפתוח את הצ'ק הזה לצפייה.",
  No_Cheques_To_Print:"אין צ'קים להדפסה",
  No_Cheques_To_Print_Word:"צור <a routerLink='/folder/Cheque/New'>מחאה חדשה</a>.",
  New_Cheque:"צ'ק חדש",
  Start_One_Now:"התחל אחד עכשיו",
  Edit_Cheque:"ערוך בדיקה",
  Select_Cheques:"בחר צ'קים",
  Select_Employee:"בחר עובד",
  Default_Printer:"מדפסת ברירת מחדל",
  Reassign:"הקצה מחדש",
  Configure:"הגדר",
  Template:"תבנית",
  Designer:"מְעַצֵב",
  Edit_Designs:"עריכת עיצובים",
  New_Design:"עיצוב חדש",
  Next:"הַבָּא",
  Save:"להציל",
  Name:"שֵׁם",
  Mail:"דוֹאַר",
  Amount:"כמות",
  Date:"תַאֲרִיך",
  PayPal:"פייפאל",
  Payouts:"תשלומים",
  PayPal_Label:"תווית PayPal",
  Email_Username:"אימייל / שם משתמש",
  Client_ID:"זיהוי לקוח",
  Sandbox_Email:"אימייל ארגז חול",
  PayPal_Email:"אימייל של PayPal",
  PayPal_Username:"שם משתמש API",
  PayPal_Payouts:"תשלומי PayPal",
  Select_PayPal_Key:"בחר מפתח PayPal",
  Secret:"סוֹד",
  API_Secret:"סוד API",
  PayPal_API_Keys:"מפתחות פייפאל",
  New_PayPal_Key:"מפתח PayPal חדש",
  Email_Subject:"נושא האימייל",
  Email_Message:"הודעת אימייל",
  Payout_Options:"אפשרויות תשלום",
  Payment_Note:"הערת תשלום",
  Enable_Employee:"אפשר לעובד",
  Enable_Production_Mode:"הפעל מצב ייצור",
  Sandbox_Username:"שם משתמש ארגז חול",
  Sandbox_Signature:"חתימת ארגז חול",
  Sandbox_Password:"סיסמת ארגז חול",
  Production_Username:"שם משתמש בהפקה",
  Production_Signature:"חתימת הפקה",
  Production_Password:"סיסמת ייצור",
  Production_Email:"אימייל הפקה",
  API_Client_ID:"מזהה לקוח API",
  API_Signature:"חתימת API",
  API_Password:"סיסמת API",
  API_Username:"שם משתמש API",
  Secret_Key:"מפתח סודי",
  Sandbox:"ארגז חול",
  Production:"הפקה",
  Sandbox_Keys:"מפתחות ארגז חול",
  Production_Keys:"מפתחות ייצור",
  API_Title:"כותרת API",
  Production_Mode:"מצב ייצור",
  Account_Label:"תווית חשבון",
  No_PayPal_Setup:"אין מפתח PayPal",
  Enable_PayPal_Account:"הפעל חשבון PayPal",
  No_PayPal_Word:"הוסף את <a routerLink='/folder/Invoice/New'>מפתח API של PayPal</a>.",
  Printed_Memo:"תזכיר מודפס",
  Employee:"עוֹבֵד",
  View_Employee:"הצג עובד",
  Mailing_Address:"כתובת דואר",
  Company_Address:"כתובת החברה",
  Select_Company_Address:"בחר כתובת חברה",
  Address:"כתובת",
  Any_Day:"כל יום",
  Address_Name:"כתובת",
  Unit:"יחידה",
  Account:"חֶשְׁבּוֹן",
  Bank_Account:"חשבון בנק",
  Account_Limits:"הפעל מגבלות חשבון",
  Payroll:"גִלְיוֹן שָׂכָר",
  New_Payroll:"לוח שכר חדש",
  No_Payroll:"אין חשבון שכר קרוב",
  Upcoming_Holiday:"חג קרוב:",
  Invoice_Due:"חשבונית לפירעון:",
  New_Invoice:"חשבונית חדשה",
  No_Invoices:"ללא חשבוניות",
  No_Invoices_Word:"צור את ה<a routerLink='/folder/Invoice/New'>חשבונית</a> הראשונה.",
  Cheque_Due:"צ'ק לפירעון:",
  Payrolls:"תשלומי שכר",
  Sandbox_Mode:"מצב ארגז חול",
  Hire:"לִשְׂכּוֹר",
  Pay:"לְשַׁלֵם",
  New:"חָדָשׁ",
  Add:"לְהוֹסִיף",
  Make:"עשה",
  Time:"זְמַן",
  Write:"לִכתוֹב",
  Holiday:"חַג",
  Holidays:"חגים",
  Next_Holiday:"חג הבא:",
  All_Done:"הכל בוצע!",
  Employees:"עובדים",
  Payees:"משלמים",
  Job:"עבודה",
  Jobs:"מקומות תעסוקה",
  Invoice:"חשבונית מס",
  Invoices:"חשבוניות",
  Vacations:"חופשות",
  Cheques:"צ'קים",
  Brand_Cheques:"מותג",
  Payment:"תַשְׁלוּם",
  Enable_Payment:"אפשר תשלום",
  Payments:"תשלומים",
  Schedule:"לוח זמנים",
  Schedules:"לוחות זמנים",
  Timesheet:"לוח זמנים",
  Timesheets:"דפי זמן",
  Salary:"שכר",
  New_Address:"כתובת חדשה",
  Address_2:"שורת כתובת 2)",
  _City:"עִיר",
  _State:"מדינה/פרוב",
  City:"עיר / עיירה",
  State:"מדינה/מחוז",
  Postal:"מיקוד/מיקוד",
  ZIP:"דואר / מיקוד",
  Country:"מדינה",
  Addresses:"כתובות",
  Required_Options:"אפשרויות נדרשות",
  Optional_Options:"אפשרויות אופציונליות",
  Additional_Options:"אפשרויות נוספות",
  Required:"נדרש",
  Optional:"אופציונאלי",
  Additional:"נוֹסָף",
  No_Addresses:"אין כתובות",
  New_Address_Word:"הוסף את ה<a routerLink='/folder/Address/New'>כתובת</a> הראשונה.",
  Select_Address:"בחר כתובת",
  No_Address:"אין כתובות",
  Print_Cheque:"הדפס בדיקה",
  Print_Cheque_Now:"הדפס בדוק עכשיו",
  Description:"תיאור",
  Pay_To_The_Order_Of:"שלם לפקודת:",
  Select_Date_Range:"בחר טווח תאריכים",
  Select_Starting_Date:"בחר תאריך התחלה",
  Select_Ending_Date:"בחר תאריך סיום",
  Select_Date:"בחר תאריך",
  Cheque_Date:"בדוק תאריך",
  Cheque_Memo:"בדוק תזכיר",
  Blank_Cheque:"צ'ק ריק",
  Blank:"רֵיק",
  No_Cheques:"אין צ'קים",
  No_Cheques_Word:"הדפס את ה<a routerLink='/folder/Cheque/New'>Check</a> הראשון שלך.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"הצג תמונה",
  View:"נוף",
  Modify:"לְשַׁנוֹת",
  Delete:"לִמְחוֹק",
  Cheque_Paid:"שולם",
  New_Deduction:"ניכוי חדש",
  Title:"כותרת",
  Frequency:"תדירות",
  Hourly:"כל שעה",
  Daily:"יומי",
  Weekdays:"ימי חול",
  BiWeekly:"2 שבועות",
  TriWeekly:"3 שבועות",
  Monthly:"יַרחוֹן",
  MiMonthly:"2 חודשים",
  Quarterly:"רִבעוֹן",
  Yearly:"שְׁנָתִי",
  Every_Week:"כל שבוע",
  Every_Payroll:"כל שכר",
  Every_Month:"כל חודש",
  Annually:"כל שנה",
  Always_Scheduled:"תמיד מתוכנן",
  Start_Date:"תאריך התחלה",
  End_Date:"תאריך סיום",
  Start_Time:"שעת התחלה",
  End_Time:"זמן סיום",
  Deduction_Label:"תווית ניכוי",
  Notes:"הערות",
  Options:"אפשרויות",
  Enable:"לְאַפשֵׁר",
  Select_Deductions:"בחר ניכויים",
  Deductions:"ניכויים",
  No_Deductions:"ללא ניכויים",
  No_Deductions_Word:"צור את ה<a routerLink='/folder/Deduction/New'>ניכוי</a> הראשון שלך.",
  New_Employee:"עובד חדש",
  No_Title:"ללא כותרת",
  _Name:"שֵׁם",
  About_Yourself:"על עצמך",
  Full_Name:"שם מלא",
  Birthday:"יום הולדת",
  Email:"אימייל",
  SMS:"סמס",
  Phone:"טלפון",
  Test:"מִבְחָן",
  Call:"שִׂיחָה",
  Fax:"פַקס",
  Printed_Note:"הערה מודפסת",
  Position:"עמדה",
  Job_Position:"עמדת עבודה",
  Select_a_Job:"בחר עבודה",
  Select_a_Salary:"בחר משכורת",
  Add_a_Deduction:"הוסף ניכוי",
  Taxes:"מיסים",
  Add_Taxes:"הוסף מיסים",
  Date_of_Birth:"תאריך לידה",
  Email_Address:"כתובת דוא\"ל",
  Phone_Number:"מספר טלפון",
  Phone_Call:"שיחת טלפון",
  Enable_on_Payroll:"הפעל בשכר",
  Select_Employees:"בחר עובדים",
  No_Employees:"ללא עובדים",
  No_Employees_Word:"הוסף את ה<a routerLink='/folder/Employee/New'>עובד</a> החדש הראשון שלך.",
  No_Name:"ללא שם",
  Unemployeed:"מובטלים",
  Employeed:"מוּעֳסָק",
  Paid:"שולם",
  Enabled:"מופעל",
  Disabled:"נָכֶה",
  Fire:"אֵשׁ",
  Not_Available:"לא זמין",
  Not_Available_Word:"הדפיס את <a routerLink='/folder/Invoice/New'>חשבונית</a> הראשונה שלך וקבל תשלום.",
  Select_Invoices:"בחר_חשבוניות",
  Print_Invoice_Word:"הדפיס את <a routerLink='/folder/Invoice/New'>חשבונית</a> הראשונה שלך וקבל תשלום.",
  Invoice_Title:"כותרת חשבונית",
  Invoice_Date:"תאריך חשבונית",
  Due_Date:"תאריך להגשה",
  New_Job:"עבודה חדשה",
  Details:"פרטים",
  Customize:"התאמה אישית",
  Customize_Dashboard:"התאמה אישית של לוח המחוונים",
  Components:"רכיבים",
  No_Components:"ללא רכיבים",
  Main_Components:"רכיבים עיקריים",
  Smaller_Components:"רכיבים קטנים יותר",
  Error_Loading_Page:"שגיאה בטעינת דף זה.",
  Bank_Details:"פרטי בנק",
  About_Your_Job:"על העבודה שלך",
  Your_Schedule:"הלו\"ז שלך",
  Job_Title:"הגדרת תפקיד",
  Job_Description:"תיאור משרה",
  Job_Details:"פרטי עבודה",
  Enable_Job:"אפשר עבודה",
  Pay_Period:"תקופת תשלום",
  Perpetually_Schedule:"לוח זמנים תמידי",
  Select_Jobs:"בחר משרות",
  No_Jobs:"אין עבודה",
  Add_Jobs:"הוסף משרות",
  No_Jobs_Word:"הוסף את <a routerLink='/folder/Job/New'>העבודה</a> הראשונה לרשימה.",
  Count_Employees:"job.employee_count+' Employees'",
  Zero_Employees:"0 עובדים",
  New_Leave:"חופשה חדשה",
  Leave_Name:"עזוב שם",
  Paid_Leave:"חופשה בתשלום",
  Leave_Pay:"עזוב תשלום",
  Indefinite_Leave:"חופשה ללא הגבלת זמן",
  Indefinite_Payroll:"תשלום שכר בלתי מוגבל",
  Leave:"לעזוב",
  Add_Schedules:"הוסף לוחות זמנים",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"אפשר היעדרות",
  Select_Leaves:"בחר עלים",
  No_Leaves:"ללא עלי היעדרות",
  Leave_Of_Absence:"חופשה ללא תשלום",
  Leaves_Of_Absence:"עלי היעדרות",
  New_Leave_of_Absense:"חופשה חדשה",
  No_Leaves_Word:"הוסף את <a routerLink='/folder/Leave/New'>חופשת ההיעדרות</a> הראשונה.",
  Not_Enabled:"לא מופעל",
  Absences:"היעדרויות",
  Payee:"מוטב",
  New_Payee:"מוטב חדש",
  Payee_Identifier:"מזהה מקבל התשלום",
  Payee_Name:"שם מקבל התשלום",
  Payee_Title:"כותרת מקבל התשלום",
  Payment_Memo:"תזכיר תשלום",
  Select_Payees:"בחר מקבלי תשלום",
  No_Payees:"אין מקבלי תשלום",
  Add_Payee_Note:"הוסף את <a routerLink='/folder/Payee/New'>המקבל</a> הראשון.",
  New_Payees:"מקבלים חדשים",
  About_The_Payment_Schedule:"לוח זמנים לתשלום",
  Your_Payroll_Schedule:"חשבונית שכר אוטומטית",
  New_Payment:"תשלום חדש",
  Identifier:"מזהה",
  Select:"בחר",
  Memo:"תַזכִּיר",
  Payment_Date:"תאריך תשלום",
  Mark_as_Paid:"סמן כבתשלום",
  Select_Payments:"בחר תשלומים",
  No_Payments:"ללא תשלומים",
  No_Payments_Word:"צור את <a routerLink='/folder/Payment/New'>תשלום</a> הראשון.",
  Create_Payroll:"צור שכר",
  Properties:"נכסים",
  Payroll_Title:"כותרת שכר",
  Payroll_Notes:"הערות שכר",
  Payroll_Setup:"הגדרת שכר",
  Tabulate_Payments:"טבלת תשלומים",
  Tabulate:"לְלַווֵחַ",
  By:"על ידי:",
  Payments_By:"תשלומים לפי",
  Timesheets_And_Schedules:"דפי זמן ולוחות זמנים",
  Both:"שניהם",
  Items:"פריטים",
  Add_Payees:"הוסף מקבלי תשלום",
  Add_Account:"הוסף חשבון",
  Enable_Account:"הפעל חשבון",
  Enable_Payroll:"הפעל שכר",
  Print_Payroll:"הדפסת שכר",
  No_Payrolls:"ללא שכר",
  No_Payroll_Word:"צור את <a routerLink='/folder/Payroll/New'>השכר</a> הראשון שלך.",
  View_more:"ראה עוד",
  Less:"פָּחוּת",
  Add_Payroll:"הוסף שכר",
  Select_Payroll:"בחר שכר",
  About_Your_Salary:"על המשכורת שלך",
  Add_Salaries:"הוסף משכורות",
  Add_Salary:"הוסף משכורת",
  Salaries:"משכורות",
  No_Salaries:"אין משכורות",
  No_Salaries_Word:"הוסף את ה<a routerLink='/folder/Salary/New'>משכורת</a> הראשונה.",
  Select_Salaries:"בחר משכורות",
  New_Salary:"משכורת חדשה",
  Salary_Name:"מזהה שכר",
  Enable_Salary:"אפשר שכר",
  Salary_Amount:"סכום שכר",
  New_Schedule:"לוח זמנים חדש",
  Schedule_Title:"כותרת לוח הזמנים",
  Add_Address:"הוסף כתובת",
  Repeat:"חזור",
  Design:"לְעַצֵב",
  Edit_Design:"עריכת עיצוב",
  Edit_this_Design:"ערוך את העיצוב הזה",
  Repeat_Payment:"תשלום חוזר",
  Enable_Schedule:"הפעל לוח זמנים",
  Never:"לעולם לא",
  Select_Schedule:"בחר לוחות זמנים",
  No_Schedules:"אין לוחות זמנים",
  No_Schedules_Word:"צור את <a routerLink='/folder/Schedule/New'>לוח הזמנים</a> הראשון.",
  New_Administrator:"מנהל חדש",
  Username:"שם משתמש",
  First_Name:"שם פרטי",
  Last_Name:"שם משפחה",
  Add_an_Address:"הוסף כתובת",
  Payment_Limit:"מגבלת לכל תשלום",
  Total_Limit:"גבול כולל",
  Select_Accounts:"בחר חשבונות",
  No_Administrators:"אין מנהלים",
  No_Administrators_Word:"צור את <a routerLink='/folder/Administrator/New'>חשבון הניהול</a> הראשון.",
  New_Administrators_Word:"הוסף את <a routerLink='/folder/Administrator/New'>מנהל המערכת</a> הראשון",
  Cloud:"ענן",
  Backup:"גיבוי",
  Enable_iCloud:"הפעל iCloud",
  Enable_Google_Drive:"הפעל את Google Drive",
  Enable_Microsoft_OneDrive:"הפעל את Microsoft OneDrive",
  Automatically_Backup:"גיבוי אוטומטי",
  FTP_Settings:"הגדרות FTP",
  URL_File_Prompt:"אנא ציין את המיקום של קובץ ‎.xls / .xlsx / .json לייבוא:",
  URL_SQL_Prompt:"אנא ציין את המיקום של קובץ SQLite לייבוא:",
  FTP_Backup:"גיבוי FTP",
  FTP_Import:"יבוא FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"מנחה",
  Port:"נמל",
  Path:"נָתִיב",
  Data_Path:"נתיב נתונים",
  Enable_FTP_Account:"הפעל חשבון FTP",
  HostnameIP:"שם מארח",
  Password:"סיסמה",
  Connection_Port:"יציאת חיבור",
  Enable_MySQL_Database:"הפעל את מסד הנתונים של MySQL",
  Log:"עֵץ",
  Reset:"אִתחוּל",
  Erase:"לִמְחוֹק",
  Export:"יְצוּא",
  Database:"מאגר מידע",
  Upload_CSV:"העלה CSV",
  Download_CSV:"הורד CSV",
  SQL_Database:"כתובת URL של SQL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"גיבוי MySQL",
  Internal_Notes:"הערות פנימיות",
  Root_Path:"נתיב שורש",
  Select_Backup:"בחר גיבוי",
  Add_New_Backup:"הוסף גיבוי חדש",
  First_Backup:"הגדר את הגיבוי הראשון...",
  Backups:"גיבויים",
  Add_Backup:"הוסף גיבוי",
  No_Backups:"אין גיבויים שניתן למצוא.",
  Select_Backup_Type:"בחר את סוג הגיבוי שברצונך להגדיר...",
  Backup_Type:"סוג גיבוי",
  FTP_Drive:"כונן FTP",
  URL_Location:"כתובת אתר",
  Apple_iCloud:"iCloud",
  Google_Drive:"נהיגה",
  Microsoft_OneDrive:"נהיגה",
  Import_Fields:"שדות ייבוא",
  Import_Fields_Word:"השתמש בקטע זה כדי לבחור <a routerLink='/folder/Holiday/New'>ייבוא</a> נתונים.",
  Upload:"העלה",
  Download:"הורד",
  Download_JSON:"הורד כנתוני JSON",
  Download_SQL:"הורד כקובץ SQL",
  New_Bank:"בנק חדש",
  New_Account:"חשבון חדש",
  New_Bank_Account:"חשבון בנק חדש",
  Upload_Image:"העלאת תמונה",
  Bank_Name:"שם הבנק",
  Bank_Address:"כתובת בנק",
  Branch_Address:"כתובת הסניף",
  Address_on_Cheque:"כתובת",
  Cheque_Numbers:"בדוק מספרים",
  Cheque_Details:"בדוק פרטים",
  Bank_Logo:"לוגו הבנק",
  Cheque_ID:"בדוק תעודת זהות",
  Starting_Cheque_ID:"התחלת בדיקה מזהה",
  Transit_Number:"מספר מעבר",
  Institution_Number:"מספר מוסד",
  Designation_Number:"מספר ייעוד",
  Account_Number:"מספר חשבון",
  Limits:"גבולות",
  Default_Limits:"גבולות ברירת מחדל",
  Over_Limit:"מעל הגבול",
  Under_Limit:"מתחת לגבול",
  Payroll_Limit:"מגבלת שכר",
  Enable_Bank_Account:"הפעל חשבון בנק",
  Select_Account:"בחר חשבון",
  No_Bank_Account:"אין חשבון בנק",
  No_Bank_Account_Word:"הוסף את <a routerLink='/folder/Accounts/New'>חשבון הבנק</a> הראשון.",
  Bank_Accounts:"חשבונות בנק",
  No_Accounts:"אין חשבונות",
  No_Accounts_Note:"הוסף את <a routerLink='/folder/Accounts/New'>חשבון הבנק</a> הראשון.",
  Cheque_Designer:"בדוק מעצב",
  Cheque_Design:"בדוק עיצוב",
  Select_Design:"בחר עיצוב",
  Cheque_Designs:"בדוק עיצובים",
  No_Cheque_Designs:"ללא עיצובי צ'קים",
  No_Cheque_Designs_Word:"צור <a routerLink='/folder/Settings/Cheque/Design/New'>בדיקת עיצוב</a> משלך.",
  Set_Default:"נקבע כברירת מחדל",
  Default:"בְּרִירַת מֶחדָל",
  Remove:"לְהַסִיר",
  Folder:"תיקיה",
  Edit:"לַעֲרוֹך",
  LoadingDots:"טעינה...",
  Add_a_New_File:"הוסף <a href='#' (click)='add()'>קובץ חדש</a> אל",
  this_folder:"התיקייה הזו",
  FTP_Backup_Settings:"הגדרות גיבוי FTP",
  Secure_File_Transfer:"העברת קבצים מאובטחת",
  New_Holiday:"חג חדש",
  Add_Holiday:"הוסף חג",
  Holiday_Name:"שם החג",
  Additional_Pay:"שכר נוסף",
  Enable_Holiday:"אפשר חג",
  Select_Holidays:"בחר חגים",
  No_Holidays:"אין חגים",
  No_Holidays_Word:"הוסף את <a routerLink='/folder/Holiday/New'>חג הציבורי</a> הראשון.",
  New_Import:"יבוא חדש",
  Import_Data:"ייבוא ​​נתונים",
  Import_Data_Word:"בחר את סוג הקובץ או שיטת ההעלאה לבחירתך.<br /> תוכל לבחור את השדות המיובאים בקובץ שמתאימים לכל פרמטר באפליקציה לאחר העלאת קובץ נתמך.",
  Import_File:"ייבוא ​​קובץ",
  Link_To_File:"קישור לקובץ",
  Select_File:"בחר קובץ",
  New_Moderator:"מנחה חדש",
  Allow_Moderation:"אפשר מתינות",
  Create_Paycheques:"צור תלושי שכר",
  Edit_Paycheques_and_Data:"ערוך תלושי שכר ונתונים",
  Destroy_Data_and_Paycheques:"להרוס נתונים ותלושי שכר",
  Bonus_Percentage:"אחוז משכורת",
  Fixed_Amount:"סכום קבוע",
  Select_Moderator:"בחר מנחה",
  No_Moderators:"אין מנחים",
  Moderators:"מנחים",
  Moderator_Account:"צור את <a routerLink='/folder/Administrator/New'>חשבון המנחה</a> הראשון.",
  No_Moderators_Word:"הוסף את <a routerLink='/folder/Administrator/New'>מנחה</a> הראשון.",
  Defaults:"ברירות מחדל",
  Provide_Us_Info:"ספק לנו מידע",
  Setup_Your_Printer:"הגדר את המדפסת שלך",
  Your_Company:"על החברה שלך",
  Company_Name:"שם החברה",
  Currency:"מַטְבֵּעַ",
  Default_Currency:"מטבע ברירת מחדל",
  Base_Monthly_Income:"הכנסה חודשית",
  Protection:"הֲגָנָה",
  App_Protection:"הגנה על אפליקציה",
  PIN_Code_Protection:"הגנה על קוד PIN",
  App_Protection_Word:"הפעל שיטות אבטחה שעוזרות להגן על החשבון שלך.",
  PIN_Code:"קוד סודי",
  Change_PIN:"שנה PIN",
  New_Password:"סיסמה חדשה",
  Geofence_Protection:"הגנה גיאוגרפית",
  Geofence_Area:"הגדר אזור",
  Distance:"מֶרְחָק",
  Setup_Now:"הגדר עכשיו",
  Mile:"מִיל",
  Km:"ק\"מ",
  m:"M",
  Facial_Recognition:"זיהוי פנים",
  Face:"פָּנִים",
  Setup:"להכין",
  Label:"תווית",
  Password_Protection:"הגנה על סיסמה",
  Modify_Password:"שנה סיסמה",
  New_Tax_Entry:"רישום מס חדש",
  New_Tax:"מס חדש",
  Tax_Label:"תווית מס",
  Perpetually_Enabled:"מופעל באופן תמידי",
  Select_Taxes:"בחר מיסים",
  Tax_Deductions:"הנחות מס",
  No_Tax_Deductions:"ללא הנחות מס",
  No_Tax_Deductions_Word:"הוסף את הניכוי הראשון של <a routerLink='/folder/Tax/New'>מס</a>.",
  New_Timer:"טיימר חדש",
  Start:"הַתחָלָה",
  Stop:"תפסיק",
  Start_Timer:"התחל טיימר",
  Stop_Timer:"עצור טיימר",
  Timer_Active:"טיימר פעיל",
  Timer:"שָׁעוֹן עֶצֶר:",
  Timer_Running:"טיימר: (פועל)",
  Save_Timer:"שמור טיימר",
  New_Timesheet:"דף זמן חדש",
  Select_Timesheets:"בחר דפי זמן",
  Work_Notes:"הערות עבודה",
  Entry_Title:"כותרת ערך",
  No_Timesheets:"ללא דפי זמן",
  No_Timesheets_Word:"הוסף את <a routerLink='/folder/Timesheet/New'>גיליון הזמן</a> הראשון.",
  Timesheet_Submitted:"דף זמן הוגש",
  Timesheet_Congrats:"מזל טוב! דף השעות שלך הוגש בהצלחה. תודה!",
  Timesheet_Copy:"כדי לקבל עותק של המסמכים שלך אנא ספק לנו את כתובת הדוא\"ל שלך ו/או מספר הטלפון הנייד שלך.",
  Submit:"שלח",
  Allow_Notifications:"אפשר הודעות",
  Untitled_Entry:"רשומה חדשה",
  Untitled_Bank:"בנק ללא שם",
  Timesheet_Entry:"רישום גיליון זמן",
  Work_Description:"תיאור העבודה",
  Enable_Timesheet:"הפעל גיליון זמן",
  Submit_Timesheet:"שלח גיליון זמן",
  Vacation:"חוּפשָׁה",
  New_Vacation:"חופשה חדשה",
  Vacation_Name:"שם חופשה",
  Paid_Vacation:"חופשה בתשלום",
  Vacation_Pay:"דמי חופשה",
  Enable_Vacation:"אפשר חופשה",
  Select_Vacations:"בחר חופשות",
  No_Vacations:"בלי חופשות",
  No_Vacations_Word:"צור את הערך הראשון של <a routerLink='/folder/Vacation/New'>חופשה</a>.",
  Payroll_Schedule:"לוח שכר",
  Next_Payroll:"השכר הבא:",
  Upcoming_Payroll:"תשלום שכר קרוב",
  No_Upcoming_Payroll:"אין חשבון שכר קרוב",
  Address_Book:"פנקס הכתובות",
  Archived_Documents:"מסמכים בארכיון",
  Dev_Mode:"יישום במצב פיתוח",
  Administrators:"מנהלים",
  Privacy:"פְּרָטִיוּת",
  None:"אף אחד",
  Select_Signature:"בחר חתימה",
  No_Signatures:"אין חתימות",
  No_Signatures_Word:"הוסף את ה<a routerLink='/folder/Signature/New'>חתימה</a> הראשונה.",
  Repeat_Indefinitely:"חזור ללא הגבלה",
  Sign:"סִימָן",
  Sign_Here:"תחתום כאן",
  Date_Signed:"תאריך חתום",
  Signature_Pad:"פנקס חתימה",
  Account_Holder:"בעל החשבון",
  Account_Properties:"נכסי חשבון",
  Name_On_Cheque:"שם בהמחאה",
  Server_Hostname:"שם מארח / IP של שרת",
  Printers:"מדפסות",
  No_Printers:"אין מדפסות",
  Printer_Exists:'מדפסת בשם זה כבר קיימת.',
  No_Printers_Word:"הוסף את ה<a routerLink='/folder/Printer/New'>מדפסת</a> הראשונה.",
  No_Printer_Alert:"לא מוגדרת מדפסת. האם תרצה להגדיר מדפסת?",
  Add_Printer:"הוסף מדפסת",
  New_Printer:"מדפסת חדשה",
  Printer_Hostname:"שם מארח / IP של מדפסת",
  Printer_Label:"תווית מדפסת",
  Printer_Protocol:"פרוטוקול מדפסת",
  Protocol:"נוהל",
  Email_Print:"אימייל",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"שֶׁקַע",
  Print_Job:"עבודת הדפסה",
  Printed:"מודפס",
  Not_Printed:"לא מודפס",
  Print_Jobs:"עבודות הדפסה",
  Print_Queue:"תור הדפסה",
  No_Print_Jobs:"אין עבודות הדפסה",
  No_Prints:"צור <a routerLink='/folder/Cheque/New'>צ'ק</a> חדש להדפסה.",
  Prints:"הדפסים",
  Find_Printer:"מצא מדפסת",
  Find_AirPrint_Devices:"מצא התקני AirPrint",
  Select_Printer:"בחר מדפסת",
  System_UI:"ממשק המשתמש של המערכת",
  Printer:"מדפסת",
  Status:"סטָטוּס",
  Preview:"תצוגה מקדימה",
  Enable_Print_Job:"אפשר עבודת הדפסה",
  Queue_Weight:"משקל תור",
  Unlimited:"ללא הגבלה",
  Show_Advanced_Printer_Options:"הצג אפשרויות מדפסת מתקדמות",
  Advanced:"מִתקַדֵם",
  Location:"מקום",
  Note:"הערה",
  Queue_Name:"שם התור",
  Pages_Printed_Limit:"מגבלת דפים מודפסים",
  MultiPage_Idle_Time:"זמני המתנה מרובי עמודים",
  Page_Count_Limit:"מגבלת ספירת דפים",
  Page_Orientation:"כיוון עמוד",
  Portrait:"דְיוֹקָן",
  Landscape:"נוֹף",
  Duplex:"דופלקס",
  Double_Sided_Printing:"דו צדדי",
  Duplex_Mode:"מצב דופלקס",
  Duplex_Short:"קצר",
  Duplex_Long:"ארוך",
  Duplex_None:"אף אחד",
  Color_And_Quality:"צבע ואיכות",
  Monochrome:"מונוכרום",
  Photo_Quality_Prints:"הדפסות באיכות תמונה",
  Printer_Email:"אימייל למדפסת",
  Automatically_Downsize:"הקטנה אוטומטית",
  Paper:"עיתון",
  Paper_Name:"שם נייר",
  Paper_Width:"רוחב נייר",
  Paper_Height:"גובה נייר",
  Paper_Autocut_Length:"אורך חיתוך אוטומטי של נייר",
  Margins:"שוליים",
  Page_Margins:"שולי עמוד",
  Page_Margin_Top:"שוליים בעמוד העליון",
  Page_Margin_Right:"שולי עמוד ימין",
  Page_Margin_Bottom:"שולי עמוד תחתון",
  Page_Margin_Left:"שולי עמוד שמאל",
  Add_Employees:"הוסף עובדים",
  Header:"כּוֹתֶרֶת",
  Print_A_Page_Header:"הדפס כותרת דף",
  Header_Label:"תווית כותרת",
  Header_Page_Index:"אינדקס עמוד הכותרת",
  Header_Font_Name:"גופן כותרת",
  Select_Font:"בחר גופן",
  Font_Selector:"בורר גופנים",
  Header_Font_Size:"גופן כותרת",
  Header_Bold:"כותרת מודגשת",
  Header_Italic:"כותרת נטוי",
  Header_Alignment:"יישור כותרת",
  Left:"שמאלה",
  Center:"מֶרְכָּז",
  Right:"ימין",
  Justified:"מוּצדָק",
  Header_Font_Color:"צבע כותרת",
  Select_Color:"בחר צבע",
  Footer:"כותרת תחתונה",
  Print_A_Page_Footer:"הדפס כותרת תחתונה של עמוד",
  Footer_Label:"תווית תחתונה",
  Footer_Page_Index:"אינדקס עמוד תחתון",
  Footer_Font_Name:"גופן תחתית",
  Fonts:"גופנים",
  Done:"בוצע",
  Select_Fonts:"בחר גופנים",
  Footer_Font_Size:"גודל כותרת תחתונה",
  Footer_Bold:"כותרת תחתונה מודגשת",
  Footer_Italic:"כותרת תחתונה נטוי",
  Footer_Alignment:"יישור כותרת תחתונה",
  Footer_Font_Color:"צבע תחתית",
  Page_Copies:"עותקי עמודים",
  Enable_Printer:"הפעל מדפסת",
  Remote_Logging:"רישום מרחוק",
  Log_Server:"שרת יומן",
  Encryption:"הצפנה",
  Random_Key:"מפתח אקראי",
  Encryption_Key:"מפתח הצפנה",
  Cheques_Webserver:"מסד נתונים מותאם אישית",
  Learn_How:"ללמוד איך",
  Signature:"חֲתִימָה",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"הצג חתימה",
  Printed_Signature:"חתימה מודפסת",
  Digitally_Sign:"חתימה דיגיטלית",
  Digital_Signature:"חתימה דיגיטלית",
  Add_Signature:"הוסף חתימה",
  Add_Your_Signature:"הוסף את החתימה שלך",
  Enable_Signature:"אפשר חתימה",
  Digitally_Signed:"חתום דיגיטלית",
  Insert_Error:"לא ניתן לשמור את ההמחאה עקב בעיות במסד הנתונים.",
  Delete_Confirmation:"האם אתה בטוח שאתה רוצה למחוק את המידע הזה?",
  Discard_Confirmation:"האם אתה בטוח שאתה רוצה למחוק את המידע הזה?",
  Discard_Bank_Confirmation:"האם אתה בטוח שאתה רוצה למחוק את החשבון הזה?",
  Discard_Printer_Confirmation:"האם אתה בטוח שברצונך להשליך את המדפסת הזו?",
  Delete_Bonus_Confirmation:"האם אתה בטוח שאתה רוצה למחוק את הבונוס הזה?",
  Delete_Invoice_Confirmation:"האם אתה בטוח שברצונך למחוק חשבונית זו?",
  Generated_Cheque:"צ'ק שנוצר",
  Generated_Invoice:"חשבונית שנוצרה",
  Schedule_Start_Time:"תזמון התחלה",
  Schedule_End_Time:"סוף לוח זמנים",
  New_Call:"שיחה חדשה",
  No_Contacts:"אין אנשי קשר",
  No_Contacts_Word:"מנהלי מערכת, מנהלים, עובדים ומקבלי תשלום מופיעים כאנשי קשר.",
  PDF_Subject:"כספים",
  PDF_Keywords:"המחאות PDF של תלוש שכר",
  Printer_Setup:"הגדרת מדפסת",
  Printer_Selection:"בחירת מדפסת",
  New_Fax:"פקס חדש",
  New_Fax_Message:"הודעת פקס חדשה",
  Fax_Machine:"מכשיר פקס",
  Fax_Name:"שם הפקס",
  Fax_Email:"אימייל פקס",
  Fax_Number:"מספר פקס",
  Contents:"תוכן",
  Fax_Body:"גוף העמוד",
  Header_Word:"כּוֹתֶרֶת:",
  Header_Text:"טקסט כותרת",
  Include_Header:"כלול כותרת",
  Include_Footer:"כלול כותרת תחתונה",
  Footer_Word:"כותרת תחתונה:",
  Footer_Text:"טקסט תחתון",
  Attach_a_Cheque:"צרף צ'ק",
  Add_Deduction:"הוסף ניכוי",
  Enable_Fax:"שלח פקס",
  Select_Fax:"בחר פקס",
  No_Faxes:"ללא פקסים",
  Faxes:"פקסים",
  Save_and_Send:"שלח פקס",
  Save_and_Pay:"שמור ושלם",
  WARNING:"אַזהָרָה:",
  Remember:"זכור",
  Printing:"הַדפָּסָה",
  Printing_Complete:"ההדפסה הושלמה!\n\n",
  of:"שֶׁל",
  There_Were:"היו ",
  Successful_Prints:"הדפסות מוצלחות ו",
  Unsuccessful_Prints:"הדפסות לא מוצלחות.",
  PrinterError:"סליחה! הייתה שגיאה.",
  Printing_:"הַדפָּסָה...",
  PrinterSuccess:"המסמך הודפס בהצלחה.",
  PrintersSuccess:"המסמכים הודפסו בהצלחה.",
  Print_Cheques:"הדפס צ'קים",
  New_Message:"הודעה חדשה",
  New_Messages:"הודעות חדשות",
  Read_Message:"קרא הודעה",
  Write_Message:"כתוב הודעה",
  Send_Message:"לשלוח הודעה",
  Subject:"נושא",
  Message:"הוֹדָעָה",
  Writing:"כְּתִיבָה...",
  Send:"לִשְׁלוֹחַ",
  Set_Date:"קבע תאריך",
  Set_Time:"זמן מוגדר",
  Recieve:"קבל",
  Set_as_Default:"נקבע כברירת מחדל",
  Default_Account:"חשבון ברירת מחדל",
  Default_Design:"עיצוב ברירת מחדל",
  Multiple_Cheques:"צ'קים (טריפל)",
  Account_Mode:"מצב חשבון",
  Multiple_Cheques_Description:"שלושה המחאות בעמוד.",
  Check_and_Table:"בדוק וטבלה",
  Check_including_Table:"טבלת חשבונות וחשבונות.",
  Check_Mailer:"בדוק מיילר",
  Check_Mailer_Window:"בדוק עם חלון כתובת.",
  DocuGard_Table_Top:"דוקוגארד צ'קים וחשבונות (למעלה)",
  DocuGard_Table_Middle:"דוקוגארד צ'קים וחשבונות (אמצע)",
  DocuGard_Table_Bottom:"דוקוגארד צ'קים וחשבונות (למטה)",
  DocuGard_Mailer_Top:"דוקוגארד צ'ק נשלח בדואר (למעלה)",
  DocuGard_Mailer_Middle:"דוקוגארד צ'ק נשלח בדואר (אמצע)",
  DocuGard_Mailer_Bottom:"דוקוגארד צ'ק נשלח בדואר (למטה)",
  DocuGard_Three_Cheques:"דוקוגרד צ'קים (טריפל)",
  DocuGard_Cheque_Top:"בדוק דוקוגארד (למעלה)",
  DocuGard_Cheque_Middle:"בדוק דוקוגארד (אמצע)",
  DocuGard_Cheque_Bottom:"בדוק דוקוגארד (למטה)",
  DocuGard_Three_Cheques_Window:"שלושה המחאות בעמוד אחד.",
  DocuGard_Table_Top_Window:"טבלת צ'קים ורווחים.",
  DocuGard_Table_Middle_Window:"טבלת צ'קים ורווחים.",
  DocuGard_Table_Bottom_Window:"טבלת צ'קים ורווחים.",
  DocuGard_Mailer_Top_Window:"בדיקה, טבלה וכתובת.",
  DocuGard_Mailer_Middle_Window:"בדיקה, טבלה וכתובת.",
  DocuGard_Mailer_Bottom_Window:"בדיקה, טבלה וכתובת.",
  DocuGard_Cheque_Top_Window:"בדוק אם יש נייר מאובטח.",
  DocuGard_Cheque_Middle_Window:"בדוק אם יש נייר מאובטח.",
  DocuGard_Cheque_Bottom_Window:"בדוק אם יש נייר מאובטח.",
  Basic_Cheque:"בדוק (למעלה)",
  Basic_Cheque_Print:"הדפיס צ'ק בודד.",
  Error_Setting_As_Paid:"שגיאה בהגדרת בתשלום",
  Add_Attachment:"הוסף קובץ מצורף",
  PrinterUnavailable:"מדפסת לא זמינה",
  CreditCardComponent:"קלפים",
  PaypalComponent:"פייפאל",
  InteracComponent:"אינטראק",
  BitcoinComponent:"ביטקוין",
  New_Deposit:"פיקדון חדש",
  Deposits:"הפקדות",
  No_Deposits:"ללא הפקדות",
  Credit_Card_Deposit:"כרטיס אשראי",
  New_Credit_Card_Deposit_Message:"הפקדה בכרטיס אשראי",
  New_BitCoin_Deposit:"ביטקוין",
  New_BitCoin_Deposit_Message:"הפקדת ביטקוין",
  New_Interac_Deposit:"אינטראק",
  New_Interac_Deposit_Message:"אינטראק העברה",
  Payments_Limit:"מגבלת תשלום",
  No_Payment_Limit:"ללא הגבלת תשלום",
  PayPal_Deposit:"פייפאל",
  PayPal_Deposit_Message:"פיקדון PayPal",
  No_Deposits_Word:"הוסף את החברה הראשונה <a routerLink='/folder/Deposit/New'>הפקדה</a>.",
  No_Documents_Specified:"לא צוינו מסמכים להדפסה",
  No_Printers_Added:"לא נמצאו מדפסות. עבור אל הגדרות > מדפסות כדי להוסיף אחת.",
  DB_Erase_Prompt:"מחק לחלוטין את כל מסד הנתונים? אזהרה: תאבד את כל המידע המאוחסן!",
  Console_Warning:"אל תדביק שום טקסט בקונסולה הזו. אתה עלול לסכן את עצמך ו/או את החברה שלך.",
  No_Faxes_Word:"צור את ה<a routerLink='/folder/Fax/New'>פקס</a> הראשון.",
  Cheque_Delete_Confirmation:"האם אתה בטוח שאתה רוצה למחוק את הסימון הזה?",
  Design_Delete_Confirmation:"האם אתה בטוח שאתה רוצה למחוק את העיצוב הזה?",
  Cheque_Pay_Confirmation:"סמן את ההמחאה הזו כשולם? הוא לא יופיע בתור להדפסה.",
  Payment_Pay_Confirmation:"סמן תשלום זה כשולם? הוא לא יופיע בתור הצ'קים.",
  Delete_Deduction_Confirmation:"האם אתה בטוח שאתה רוצה למחוק את הניכוי הזה?",
  Delete_Job_Confirmation:"האם אתה בטוח שאתה רוצה למחוק את העבודה הזו?",
  Delete_Timesheet_Confirmation:"האם אתה בטוח שאתה רוצה למחוק את גיליון הזמנים הזה?",
  Delete_Schedule_Confirmation:"האם אתה בטוח שאתה רוצה למחוק את לוח הזמנים הזה?",
  Delete_Setting_Confirmation:"האם אתה בטוח שברצונך לאפס את ההגדרה הזו?",
  Delete_Fax_Confirmation:"האם אתה בטוח שאתה רוצה למחוק את הפקס הזה?",
  Delete_File_Confirmation:"האם אתה בטוח שאתה רוצה למחוק את הקובץ הזה?",
  Delete_Vacation_Confirmation:"האם אתה בטוח שאתה רוצה למחוק את החופשה הזו?",
  Delete_Printer_Confirmation:"האם אתה בטוח שברצונך למחוק מדפסת זו?",
  Remove_Design_Confirmation:"האם אתה בטוח שאתה רוצה למחוק את העיצוב הזה?",
  Delete_Payroll_Confirmation:"האם אתה בטוח שאתה רוצה למחוק את השכר הזה?",
  Send_Fax_Email_Confirmation:"האם ברצונך לשלוח את המסמכים האלה בפקס ובמייל?",
  Send_Email_Confirmation:"האם אתה רוצה לשלוח את המסמך הזה באימייל?",
  Send_Fax_Confirmation:"האם אתה רוצה לשלוח את המסמך הזה בפקס?",
  Error_Generating_PDF:"מצטערים. הייתה שגיאה ביצירת מסמך זה.",
  PDF_Error_Saving_Image:"מצטערים. אירעה שגיאה בשמירת תמונת PDF של מסמך זה.",
  Test_Printer_Confirmation:"האם ברצונך להדפיס דף ניסיון במדפסת זו?",
  Save_Timesheet_Prompt:"אנא הוסף 'כותרת' או לחץ על 'התחל טיימר' כדי לשמור.",
  Remove_Geofence_Prompt:"האם אתה בטוח שאתה רוצה להסיר את המיקום של הגדר הגיאוגרפית הזו?",
  Delete_Employee_Confirmation:"אתה בטוח שאתה רוצה למחוק ",
  Fire_Employee_Confirmation:"האם אתה בטוח שאתה רוצה לפטר"
}