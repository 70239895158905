export const Mk = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Авторски права & копија; 2023 година",
  black:"Црна",
  green:"Зелена",
  gold:"Злато",
  blue:"Сина",
  brown:"Кафеава",
  purple:"Виолетова",
  pink:"Пинк",
  red:"Црвено",
  Swatches:"Сватови",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Нијанса",
  Saturation:"Заситеност",
  Lightness:"Леснотија",
  Upgrade_To_Pro:"Надградба на про",
  AllFeaturesInclude:"Сите карактеристики вклучуваат:",
  PrintUnlimitedCheques:"Печатете неограничени чекови",
  PremiumChequeDesigns:"Дизајни за премиум проверка",
  ManageUnlimitedEmployees:"Управувајте со неограничени вработени",
  AddUnlimitedPayees:"Додадете неограничени примачи",
  CreateUnlimitedPayrolls:"Создадете неограничени плати",
  UnlimitedSchedulesandTimeSheets:"Неограничени распореди и временски листови",
  BulkPayPalPayouts:"Масовни исплати на PayPal",
  UnlimitedBankAccounts:"Неограничени банкарски сметки",
  ManageMultipleCompanies:"Управувајте со повеќе компании",
  TrackInsurancePolicies:"Следете ги полисите за осигурување",
  Bio_MetricProtection:"Биометриска заштита",
  Geo_FenceLock_OutProtection:"Заштита од заклучување на гео-оградата",
  Multiple_Companies_Word:"Управувањето со повеќе компании не е достапно без премија за чекови.",
  PayPal_Payouts_Word:"Плаќањата со PayPal се оневозможени без премија за чекови.",
  PINProtection:"PIN-заштита",
  PasswordProtection:"Заштита со лозинка",
  May_Require_Approval:"Може да бара одобрение.",
  Subscribe:"Претплатете се",
  Billed:"Фактурирана",
  Up:"Горе",
  Down:"Долу",
  Positioning:"Позиционирање",
  Marker:"Маркер",
  Drag_Marker:"Повлечете го маркерот",
  Tagline:"Печатете чекови и табеларизирајте ги платите",
  Marker_Word:"Користете ги маркерите за големината на елементот.",
  Pinch_Zoom:"Стиснете зум",
  Pinch_Word:"Стиснете за да го зумирате елементот.",
  Drag:"Повлечете",
  Drag_Word:"Користете го прстот за влечење елементи.",
  subscription_alias_word:"Автоматско обновување на претплата",
  premium_alias_word:"Пакет за еднократна надградба",
  print_alias_word:"Печатење Индивидуални проверки",
  mode_alias_word:"Мод",
  Pro:"Про",
  Pro_word:"Потребна е про верзија.",
  Cant_Delete_Default_Company_Word:"За жал, не можете да ја избришете вашата стандардна компанија.",
  Reinsert_Default_Designs:"Повторно вметнете стандардни дизајни",
  Reinsert_Default_Designs_word:"Дали сакате повторно да ги вметнете стандардните дизајни?",
  Subscription_Active_Disable_Word:"Оваа претплата е активна. Дали сакате да ја откажете оваа претплата на Чекови?",
  Company_Logo:"Лого на компанијата",
  ZERO_:"НУЛА",
  Disclaimer:"Одрекување",
  Privacy_Policy:"Политика за приватност",
  EULA:"Проверува EULA",
  Terms_Of_Service:"Услови за користење",
  Terms_Of_Use:"Услови за користење",
  Refunds:"Политика за рефундирање",
  Single_Print:"1 Проверете",
  Two_Prints:"2 Проверки",
  Five_Prints:"5 Проверки",
  Ten_Prints:"10 Чекови",
  Fifteen_Prints:"15 Проверки",
  Twenty_Prints:"20 Чекови",
  Thirty_Prints:"30 Чекови",
  Image_Added:"Сликата е додадена",
  Image_Preview:"Преглед на слика",
  No_Image_Was_Selected:"Не беше избрана слика.",
  Cheques_Unlimited:"Неограничени проверки",
  _Subscription:"Претплата",
  Subscription:"Проверки - 1 месец",
  Two_Month_Subscription:"Проверки - 2 месеци",
  Three_Month_Subscription:"Проверки - 3 месеци",
  Six_Month_Subscription:"Проверки - 6 месеци",
  Twelve_Month_Subscription:"Проверки - 12 месеци",
  Cheques_Are_Available:"Проверките се достапни за печатење.",
  Upgrade_Required_Two:"Изберете пакет и допрете двапати на копчето за цена за да започнете со купувањето. Печатете проверки во боја со професионален изглед за неколку секунди.",
  Month:"Месец",
  Due:"Долг:",
  Expires:"Истекува:",
  Subscription_Active:"Претплатата е активна",
  Subscription_Inactive:"Претплатата е неактивна",
  Purchase_Additional_Cheques:"Да купите дополнителни чекови?",
  Printable_Cheque:"Проверка за печатење",
  Printable_Cheques:"Чекови за печатење",
  Printable_Cheque_Word:"проверката е достапна на вашата сметка.",
  Printable_Cheques_Word:"чекови се достапни на вашата сметка.",
  Max_Amount_Message:"Износот што го наведовте го достигна максималниот износ поставен за овој систем:",
  Terms_Required_Word:"Мора да се согласите со овој договор пред да продолжите да ги користите чековите.",
  Subscriptions:"Претплати",
  Product_Upgrades:"Надградби",
  Mailed_Out_Cheques:"Испратени проверки по пошта",
  Enter_A_Company_Name:"Ве молиме внесете име на компанија.",
  Single_Cheques:"Единечни проверки",
  Cheque_Golden:"Златен чек",
  Cheque_Golden_Window:"Дизајн на златен чек.",
  Cheque_Green:"Зелена проверка",
  Cheque_Green_Window:"Дизајн на зелена проверка.",
  Cheque_Red:"Црвена проверка",
  Cheque_Red_Window:"Дизајн на црвена проверка.",
  Cheque_Yellow:"Жолта проверка",
  Cheque_Yellow_Window:"Дизајн на жолта проверка.",
  Cheque_Statue_of_Liberty:"Статуа на слободата",
  Cheque_Statue_of_Liberty_Window:"Проверете го дизајнот на Статуата на слободата.",
  Cheque_Green_Wave:"Зелен бран",
  Cheque_Green_Wave_Window:"Дизајн на зелена проверка.",
  Cheque_Golden_Weave:"Златен ткаат",
  Cheque_Golden_Weave_Window:"Елегантен дизајн на златна чек.",
  Cheque_Green_Sun:"Зелено сонце",
  Cheque_Green_Sun_Window:"Длабок и смирувачки дизајн на проверка.",
  Cheque_Blue_Checkers:"Сини дама",
  Cheque_Blue_Checkers_Window:"Дизајн на сина проверка.",
  Cashiers_Check:"Касиерска проверка",
  Cashiers_Check_Window:"Шаблон за стил на касиерска чек.",
  Cheque_Aqua_Checkers:"Аква дама",
  Cheque_Aqua_Checkers_Window:"Дизајн на аква проверка.",
  Cheque_Golden_Checkers:"Златни дама",
  Cheque_Golden_Checkers_Window:"Дизајн на златен чек.",
  Upgrade_Unavailable:"Надградбите се недостапни",
  Bank_Code_Protection:"Заштита на банкарски број",
  Bank_Code_Protection_Word:"Заштитете ги вашите банкарски броеви од користење на оваа апликација која работи на друг уред или за друг корисник. Оваа акција е НЕПОВРАТЛИВА. Продолжи?",
  Bank_Code_Protection_Blocked_Word:"Банкарските броеви што се обидувате да ги користите се резервирани за друг корисник или уред.",
  Bank_Code_Protection_Error_Word:"Потврдата на бројот не успеа. Поврзете се на интернет и обидете се повторно да ја додадете оваа банкарска сметка.",
  Bank_Code_Protection_Set_Error_Word:"Заштитата на банкарските броеви бара да бидете поврзани на интернет. Поврзете се и обидете се повторно.",
  Upgrade_Unavailable_Word:"За жал, имаме проблем да ве потврдиме. Претплатите и надградбите на Чекови во моментов не се достапни за купување во вашата област.",
  PayPal_Payment_Preview:"Преглед на плаќање на PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Изберете PayPal",
  PayPal_Applications:"Апликации за PayPal",
  Purchase_With_Apple_Pay:"Купете со Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Компании",
  Insurance:"Осигурување",
  New_PayPal:"Нов PayPal",
  Pay_By:"Плати до",
  Insure:"Осигурајте",
  Miles:"Милји",
  Payment_Method:"Метод за плаќање",
  Select_Policies:"Изберете Политики",
  Policies:"Политики",
  Policy:"Политика",
  No_PayPal_Account:"Нема сметка на PayPal",
  No_Policies:"Без осигурителни полиси",
  New_Policy:"Нова политика",
  PayPal_Payment:"Плаќање со PayPal",
  PayPal_Payments:"PayPal плаќања",
  No_Payment_Selected:"Не е избрано плаќање",
  Sending_Payment_Word:"Ве молиме почекајте... Ова плаќање се испраќа.",
  Sending_Payments_Word:"Ве молиме почекајте... Плаќањата се испраќаат.",
  No_Payment_Selected_PayPal:"Не е избрано <a routerLink='/folder/Payments'>плаќање со PayPal</a> за испраќање.",
  Payment_Sent:"Испратено плаќање",
  PayPal_Payment_Sent:"Оваа исплата е испратена со PayPal.",
  Copay:"Копирај",
  Dead:"Мртви",
  Alive:"Жив",
  Not_Dead:"Не е мртво",
  Unclaimed:"Непобарана",
  Attempted:"Се обидел",
  Deceased:"Починати",
  Claimed:"Тврди",
  Unpaid:"Неплатено",
  Sending_Payment:"Испраќање на плаќање",
  Sending_Payments:"Испраќање плаќања",
  Send_PayPal_Confirmation:"Дали сакате да ја испратите оваа трансакција со PayPal?",
  Send_PayPal_Confirmation_Word:"Притиснете го зеленото копче за да ја испратите оваа трансакција.",
  Save_Payment_As_Unpaid:"Да се зачува оваа уплата како неплатена?",
  Payment_Pay_Confirmation_PayPal:"Да се зачува оваа уплата како платена?",
  No_Policies_Word:"Додадете ја првата <a routerLink='/folder/Postage/New'>Полиса за осигурување</a> сега.",
  Timesheet_Multiple_Delete_Confirmation:"Дали сте сигурни дека сакате да избришете повеќе временски листови?",
  Select_Multiple_Timesheets_Prompt:"Нема избрани временски листови. Изберете барем еден временски лист.",
  Select_Multiple_Policies_Prompt:"Нема избрани политики. Изберете барем една полиса за осигурување.",
  Policies_Multiple_Delete_Confirmation:"Дали сте сигурни дека сакате да избришете повеќе правила?",
  Company:"Компанијата",
  Add_Company:"Додадете компанија",
  New_Company:"Додадете компанија",
  No_Companies:"Нема компании",
  Enable_Company:"Овозможи компанија",
  Select_Company:"Изберете компанија",
  The_Default_Company:"Стандардната ознака на компанијата.",
  Manage_Companies:"Управување со компании",
  No_Companies_Word:"Проверките ќе користат стандардна компанија.<br />Додајте ја <a routerLink='/folder/Company/New'>првата компанија</a>.",
  Default_Company:"Стандардна компанија",
  Cheques_Unlimited_Word:"Checks Unlimited ви овозможува да печатите онолку чекови колку што сакате.",
  Cheques_Subscription_Word:"Претплатата за чекови ви овозможува да испечатите онолку чекови колку што сакате.",
  You_Own_This_Product:"Вие го поседувате овој производ.",
  Your_Subscription_is_Active:"Вашата претплата е активна.",
  Active_Products:"Активирани производи",
  Purchase_Confirmation:"Купување",
  Purchase_Cheques:"Купување чекови",
  Restore_Purchase:"Вратете ги набавките",
  Erase_Purchase:"Избришете ги набавките",
  Successfully_Purchased:"Успешно купен",
  Enter_Your_Licence_Key:"Ве молиме внесете го вашиот клуч за лиценца на оваа страница за да го активирате овој производ.",
  Licence_Key:"Лиценца клуч",
  Enter_Licence_Key:"Внесете клуч за лиценца",
  Purchased:"Купени",
  Enable_Feature:"Овозможи функција",
  Cancel_Subscription:"Откажете ја претплатата",
  Subscription_Removed:"Претплатата е отстранета",
  Select_Active_Subscription:"Изберете активна претплата за да ја измените.",
  Remove_Subscription_Word:"Дали сте сигурни дека сакате да ја откажете оваа претплата?",
  Delete_Company_Confirmation:"Дали сте сигурни дека сакате да ја избришете целата оваа компанија? ПРЕДУПРЕДУВАЊЕ: Ќе ги изгубите сите зачувани информации!",
  Delete_Default_Company_Word:"Не можете да ја избришете стандардната компанија. Дали сакате да ја ресетирате апликацијата и да ја вратите во стандардната состојба? ПРЕДУПРЕДУВАЊЕ: Ќе ги изгубите сите зачувани информации!",
  Console_Warning_2:"Не ракувајте со ниту една валута користејќи ја оваа апликација која моментално не е ваша.",
  Terms_and_Conditions:"Правила и услови",
  and_the:"и на",
  for:"за",
  Please_Read_Word:"Ве молиме прочитајте и согласете се со",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Некои стапки на конверзија на валути не може да се најдат. Ве молиме поврзете се на интернет.",
  Free:"Бесплатно",
  DB_Erase_Prompt_2:"Целосно да се избрише целата база на податоци за програмери? ПРЕДУПРЕДУВАЊЕ: Ќе ги изгубите сите информации за КУПУВАЊЕ и ПРЕТПЛАТА!",
  Successfully_Imported:"Успешно увезено",
  Select_Postage:"Изберете поштарина",
  No_Postage:"Нема поштенски марки",
  No_Paid_Postage_Word:"Додајте го првиот печат <a routerLink='/folder/Postage/New'>Платена поштарина</a>.",
  Trial_Complete:'Пробната верзија е завршена',
  Please_Upgrade:'Надградете ги Чекови за да продолжите со печатење.',
  Aa:"Аа",
  Color:"Боја",
  Font:"Фонт",
  Guide:"Водич",
  Guides:"Водичи",
  Image:"Слика",
  Zoom:"Зумирајте",
  Logo:"Лого",
  Bank:"Банка",
  MICR:"MICR",
  Total:"Вкупно",
  Cancel:"Откажи",
  Confirm:"Потврди",
  Method:"Метод",
  Biometric_Security:"Биометриска безбедност",
  Please_Login_To_Continue:"Ве молиме најавете се за да продолжите.",
  Complete:"Заврши",
  Sign_Up:"Пријавете се",
  Error:"Грешка",
  Biometrics:"Биометрика",
  Percent:"проценти",
  Zero_Percent:"0%",
  Top_Margin:"Врвна маржа",
  Bottom_Margin:"Долна маргина",
  Left_Margin:"Лева маргина",
  Right_Margin:"Десна маргина",
  MICR_Margin:"MICR Маргина",
  Table_Margin:"Маргина на табелата",
  Address_Margin:"Маргина на адреса",
  Percentage_:"Процент",
  Vacation_Title:"Наслов за одмор",
  Use_PIN:"Користете PIN",
  Loading__:"Се вчитува...",
  Design_Title:"Наслов на дизајн",
  Authorize:"Овластете",
  Key:"Клуч",
  Public_Key:"Јавен клуч",
  Private_Key:"Приватен клуч",
  Authenticate:"Проверете ја автентичноста",
  Last_Payroll:"Последен платен список",
  Last_Calculation:"Последна пресметка",
  Authorized:"Овластен",
  Geo_Authorized:"Гео-локација: Овластен",
  Not_Authorized:"Неовластено",
  Authorization_Complete:"Овластувањето е завршено",
  Geolocation_Authorization:"Овластување за гео-локација",
  Out_of_Bounds:"Надвор од границите",
  Cant_Delete_Default_Design:"Не може да се избрише стандардниот дизајн.",
  Unauthorized_Out_of_Bounds:"Неовластено: надвор од границите",
  Biometric_Authorization:"Биометриска овластување",
  Locating_Please_Wait:"Се лоцира, Ве молиме почекајте...",
  Test_Biometrics:"Тест биометрика",
  Cheque_Margins:"Проверете ги маргините",
  Percentage_Full_Error:"Полето за проценти не може да се постави над 100% проценти.",
  No_Payroll_Text:"Додајте <a routerLink='/folder/Employee/New'>Вработен</a> или <a routerLink='/folder/Payee/New'>примачкувач</a> и <a routerLink='/folder/Schedule/New'>Распоред</a>.",
  Design_Saved:"Дизајнот е зачуван",
  Default_Design_Selected:"Избран стандарден дизајн",
  Partial_Import:"Делумен увоз",
  Partial_Export:"Делумен извоз",
  Entire_Import:"Цел увоз",
  Entire_Export:"Цел извоз",
  Existing_Password:"Ве молиме внесете ја вашата постоечка лозинка:",
  Existing_PIN:"Ве молиме внесете го вашиот постоечки PIN-код:",
  Pin_Change_Header:"Потврда за PIN",
  Pin_Change_SubHeader:"Внесете го вашиот стар PIN број за да ја потврдите промената.",
  Pass_Change_Header:"Потврда на лозинка",
  Pass_Change_SubHeader:"Внесете ја старата лозинка за да ја потврдите промената.",
  PIN_Enter_Message:"Внесете го вашиот PIN за да потврдите.",
  Password_Enter_Message:"Внесете ја вашата лозинка за да потврдите.",
  Pin_Updated_Success:"ПИН-кодот е успешно ажуриран!",
  Pin_Updated_Fail:"ПИН-кодот не може да се ажурира.",
  Pass_Updated_Success:"Лозинката е успешно ажурирана.",
  Pass_Updated_Fail:"Лозинката не може да се ажурира.",
  Preview_Payment:"Преглед на плаќање",
  Preview_Payroll:"Преглед на платен список",
  No_Payments_Created:"Не беа пронајдени плаќања за креирање.",
  Payment_Preview:"Преглед на плаќање",
  Enable_Payee:"Овозможи примач на плаќање",
  Rendered:"Изведено",
  No_Email:"Нема е-пошта",
  Setup_Cheques:"Проверки за поставување",
  name:"Име",
  address:"Адреса",
  address_2:"Адреса Линија 2",
  city:"Градот",
  province:"провинција",
  postal_code:"Поштенски/поштенски број",
  country:"Земја",
  username:"Корисничко име",
  full_name:"Целосно име",
  birthday:"Роденден",
  email:"Е-пошта",
  phone:"Телефон",
  employees:"Вработените",
  addresses:"Адреси",
  payment_amount_limit:"Ограничување на износот на плаќање",
  total_limit:"Вкупен лимит",
  payees:"Уплатници",
  description:"Опис",
  address_line_one:"Адреса линија еден",
  address_line_two:"Адреса линија два",
  image:"Слика",
  account_holder:"Сопственик на сметка",
  cheque_starting_id:"Проверете го Стартниот ID",
  transit_number:"Транзитен број",
  institution_number:"Број на институција",
  designation_number:"Број на ознака",
  account_number:"Број на сметка",
  currency:"Валута",
  signature:"Потпис",
  payment_payroll_limit:"Лимит за плаќање",
  total_limi:"Вкупен лимит",
  date:"Датум",
  printed_memo:"Печатена белешка",
  banks:"Банките",
  signature_image:"Слика со потпис",
  address_name:"Име на адреса",
  notes:"Белешки",
  design:"Дизајн",
  title:"Наслов",
  frequency:"Фреквенција",
  fax:"Факс",
  salaries:"Плати",
  salary_ids:"ИД за плата",
  start_time:"Време на започнување",
  end_time:"Време на завршување",
  paid:"Платено",
  overtime_percentage:"Платен процент",
  overtime_amount:"Платен фиксен износ",
  first_name:"Име",
  last_name:"Презиме",
  moderation:"Умереност",
  create:"Креирај",
  edit:"Уредување",
  destroy:"Уништи",
  day_start_time:"Ден_почеток_време",
  day_end_time:"Ден_крај_време",
  fullname:"Име",
  time:"Време",
  auto_send:"Автоматско испраќање",
  time_method:"Временски метод",
  schedules:"Распореди",
  indefinite_payroll_enabled:"Овозможи на неодредено време",
  amount:"износ",
  repeat:"Повторете",
  always_enabled:"Секогаш овозможено",
  start_date:"Почетен датум",
  end_date:"Крајна дата",
  Cheque_Total:"Проверете го Вкупно",
  Total_Amount:"Вкупна количина:",
  Amounts:"Износи:",
  Images:"Слики",
  Files:"Датотеки",
  Previewing:"Преглед:",
  Insert:"Вметнете",
  Preview_Import:"Преглед на увоз",
  Entry:"Влез",
  Entries:"Записи",
  No_Entries:"Нема записи",
  Import_Type:"Тип на увоз",
  Select_Details:"Изберете Детали",
  Select_Payee:"Изберете примач на плаќање",
  Enable_Holidays:"Овозможи празници",
  Disable_Holidays:"Оневозможи празници",
  Wire_Transfer:"Жичен трансфер",
  New_Export:"Нов извоз",
  Export_Data:"Извези податоци",
  Export_Data_Word:"Изберете го типот на датотека за извоз и преземање.",
  Export_File:"Извези датотека",
  Mode:"Мод",
  Times:"Времиња",
  Widgets:"Додатоци",
  Slider:"Лизгач",
  Set_Details:"Поставете детали",
  Select_Type:"Изберете Тип",
  Display_Slider:"Лизгач за приказ",
  Dashboard_Slider:"Лизгач на контролната табла",
  Dashboard_Mode:"Режим на контролната табла",
  Show_Intro:"Прикажи вовед",
  Show_Payrolls:"Прикажи платен список",
  Show_Holidays:"Прикажи празници",
  Show_Invoices:"Прикажи фактури",
  Show_Cheques:"Прикажи чекови",
  Enabled_Widgets:"Овозможени виџети",
  Disabled_Widgets:"Оневозможени виџети",
  Colors:"Бои",
  Barcodes:"Баркодови",
  View_Timers:"Погледнете ги тајмерите",
  Gradients:"Градиенти",
  No_Info:"Нема информации",
  Disable:"Оневозможи",
  Show_Layer:"Прикажи слоеви",
  Hide_Layer:"Скриј слоеви",
  Text_Layer:"Текст слоеви",
  Secondly:"Второ",
  Minutely:"Минутно",
  nine_am:"9:00 часот",
  five_pm:"5:00 ПОПЛАДНЕ",
  Enable_Address:"Овозможи адреса",
  Invalid_File_Type:"Извинете, избран е неважечки тип на датотека. Поддржан тип на датотека:",
  Error_Updating_Entry:"Извинете, имаше грешка при ажурирањето на овој запис.",
  Schedule_Timing_Alert:"Грешка: времето за започнување на распоредот е поставено на вредност по завршувањето на времето.",
  Select_Multiple_Payments_Prompt:"Нема избрани плаќања. Изберете барем една исплата.",
  Select_Multiple_Cheques_Prompt:"Не се избрани проверки. Изберете барем една проверка.",
  Select_Multiple_Items_Prompt:"Нема избрани ставки. Изберете барем една ставка.",
  Paymemt_Multiple_Delete_Confirmation:"Дали сте сигурни дека сакате да избришете повеќекратни плаќања?",
  Cheque_Multiple_Delete_Confirmation:"Дали сте сигурни дека сакате да избришете повеќе проверки?",
  Payee_Multiple_Delete_Confirmation:"Дали сте сигурни дека сакате да избришете повеќе корисници на плаќање?",
  Employee_Multiple_Delete_Confirmation:"Дали сте сигурни дека сакате да избришете повеќе вработени?",
  MICR_Warning:"Забелешка: некои печатачи и уреди може да не го прикажуваат правилно фонтот MICR.",
  Automatically_Send:"Автоматско испраќање",
  Type:"Тип",
  Payment_Type:"Начин на плаќање",
  Auto_Send:"Автоматско испраќање",
  Automatically_Process:"Автоматски обработува",
  Auto_Process:"Автоматски процес",
  Image_Based:"Заснована на слика",
  Font_Based:"Врз основа на фонтови",
  Rerender:"Повторно рендерирање",
  Rendering:"Рендерирање",
  Render:"Датотеки",
  Top:"Врв",
  Middle:"Среден",
  Bottom:"Долу",
  Top_Left:"Горе лево",
  Top_Center:"Врвен центар",
  Top_Right:"Горе десно",
  Middle_Left:"Средна лева страна",
  Middle_Center:"Среден центар",
  Middle_Right:"Средна десница",
  Bottom_Left:"Долно лево",
  Bottom_Center:"Долниот центар",
  Bottom_Right:"Долу десно",
  Numbers:"Броеви",
  Verified:"Потврдено",
  Paper_Size:"Големина на хартија",
  New_Device:"Нов уред",
  Add_Device:"Додадете уред",
  Remove_Device:"Отстранете го уредот",
  Delete_Device:"Избриши Уред",
  Block_Device:"Блокирај уред",
  Block:"Блокирај",
  Unblock:"Деблокирајте",
  Table:"Табела",
  Scan_Login_Code:"Скенирајте го кодот за најава",
  Login_Code:"Код за најава",
  Scan_Code:"Скенирајте го кодот",
  Scan_QR_Code:"Скенирајте QR-код",
  Select_All:"Селектирај се",
  Deselect_All:"Деселектирај ги сите",
  Increase:"Зголемување",
  Decrease:"Намалување",
  Bold:"Задебелен",
  Text:"Текст",
  Style:"Стил",
  Italic:"Закосени",
  QR_Code:"QR код",
  Barcode:"Баркод",
  Browse_Images:"Прелистајте слики",
  Browse_Files:"Прелистувајте датотеки",
  Background_Image:"Позадинска слика",
  Logo_Image:"Слика на логото",
  Header_Image:"Заглавие слика",
  Bank_Image:"Слика на банката",
  Cut_Lines:"Исечете линии",
  Background:"Позадина",
  License:"Лиценца",
  One_License:"1 лиценца:",
  Licensed:"Лиценцирано за:",
  Not_Licensed:"Не е лиценциран",
  Enter_Serial:"Внесете сериски",
  Serial_Key:"Сериски клуч",
  Serial:"Сериски",
  Product_Key:"Клучот на производот",
  Check_Product_Key:"Проверете го клучот за производот",
  Add_Product_Key:"Додадете клуч за производ",
  Verifying_Purchase:"Се потврдува купувањето...",
  Print_Envelope:"Печати плик",
  Envelope:"Плик",
  Thank_You:"Ви благодарам!",
  Scale:"Скала",
  Print_Scale:"Скала за печатење",
  Borders:"Граници",
  VOID:"ПРАВИЛНИ",
  Void_Cheque:"Проверка на празнина",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ПЛАЌАЈ ПО НАРАЧКА НА:",
  NO_DOLLARS:"БЕЗ ДОЛАРИ ",
  ONE_DOLLAR:"ЕДЕН ДОЛАР",
  DOLLARS:" ДОЛАРИ",
  AND:" И ",
  CENTS:" ЦЕНТИ.",
  NO_:"БР ",
  ONE_:"ЕДЕН ",
  AND_NO_:"И НЕ ",
  _AND_ONE_:"И ЕДЕН ",
  DOLLARS_AND_ONE_CENT:" И ЕДЕН ЦЕНТ.",
  AND_NO_CENTS:" И НУЛА ЦЕНТИ.",
  CHEQUE_PRINT_DATE:"ДАТУМ:",
  CHEQUE_PRINT_MEMO:"МЕМО:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"пратеник",
  Initial_Tasks:"Почетни задачи",
  Inital_Setup:"Почетно поставување",
  Welcome_To:"Добредојдовте во ",
  Welcome:"Добредојдовте",
  Swipe:"Повлечете",
  Intro_Setup:"Воведно поставување",
  Introduction:"Вовед",
  CHEQUE_PRINT_CREDIT:"Напојувано со чекови",
  Title_Intro_Word:"Добредојдовте во чекови",
  Title_Intro:"Вовед во чекови",
  Title_Setup:"Поставување чекови",
  PayPal_Default_Email_Message:"Добивте нов трансфер на PayPal. [Овозможено од чекови]",
  Cheques_App_Export:"Извезено со чекови",
  Post_Intro_Thanks:"Ви благодариме што избравте Чекови. Процесот на поставување сега е завршен.",
  Post_Intro_Word:"Започнете со печатење на вашиот прв чек, додајте идна исплата или додајте вработен во платниот список.",
  Upgrade_Required:"Чековите бара од вас да поседувате попремиум верзија на софтверот за да ја скриете оваа порака и да отклучите дополнителни функции.",
  Upgrade_Title:"Чекови",
  Mailout_Prompt:"Можете дополнително да одберете да ви ги испраќаат проверките по пошта на вашите плати.",
  Mailed_Cheque:"Проверка по пошта: ",
  Mailed_Cheque_Color:"Проверка по пошта (боја): ",
  Terms_Purchase:"Сите електронски купувања со чекови се целосно рефундирани до 30 дена од датумот на купување. Прочитајте ги и согласете се со <a href='#'>Условите и правилата</a> пред да продолжите.",
  Dashboard_Setup:"Поставете го примарниот печатач",
  Dashboard_Add:"Додај ја основната банкарска сметка",
  Dashboard_Customize:"Изберете шаблон за проверка",
  Dashboard_Job_Salary:"Создадете ја вашата работа и додајте ја вашата плата",
  Dashboard_Employees:"Следете ги вработените и примателите на плаќање",
  Dashboard_Print:"Печатете и испратете ги вашите плати",
  Dashboard_Payroll:"Автоматизирајте го печатењето на вашиот платен список",
  Dashboard_PayPal:"Поставување PayPal Payroll / Payouts",
  Begin_Setup:"Започнете со поставување",
  Get_Started:"Почни",
  Purchase:"Купување",
  Lockdown:"Целосно затворање",
  Unlock:"Отклучи",
  Detailed:"Детално",
  Log_In:"Логирај Се",
  Login:"Логирај Се",
  Launch:"Лансира",
  Register:"Регистрирај се",
  Finish:"Заврши",
  List:"Список",
  Weekends:"Викенди",
  Weekly:"Неделно",
  PayPal_Default_Subject:"Нова исплата",
  Payment_Message:"Порака за плаќање",
  PayPal_Default_Payment_Note:"Ви благодарам",
  Setup_Your_Cheqing_Account:"Проверка на сметка",
  Add_Your_Primary_Cheqing_Account:"Додајте ја вашата примарна сметка за проверка.",
  Welcome_Word:"Поедноставување и автоматизирање на управувањето со платите и човечките ресурси.",
  Get_Started_Quickly:"Само одговорете на неколку едноставни прашања кои ќе ни помогнат да започнеме...",
  Done_Intro_Word:"Поставувањето е завршено",
  PIN_Protected:"Заштитени се со лозинка и ПИН",
  Enter_New_PIN:"Внесете нов ПИН-код:",
  Enter_PIN:"Внесете PIN код:",
  Invalid_PIN:"Внесен е неважечки PIN.",
  Account_Identifier:"Идентификатор на сметка",
  New_Account_Identifier:"Нов идентификатор на сметка",
  attempt:"обид",
  attempts:"обиди",
  remaining:"останато",
  Language:"Јазик",
  languages:"Јазици",
  select_languages:"Одбери јазик",
  Deposit:"Депозит",
  Hire_One_Now:"Вработи еден сега",
  App_Locked:"Апликацијата е заклучена.",
  Skip_:"Прескокни",
  Skip_to_Dashboard:"Прескокни до контролната табла",
  Dashboard:"Контролна табла",
  Import:"Увоз",
  Admin:"Администратори",
  New_Admin:"Нов админ",
  Settings:"Поставки",
  Color_Picker:"Брач на бои",
  Font_Picker:"Брач на фонт",
  Logout:"Одјави се",
  Close:"Затвори",
  Close_menu:"Затвори",
  Excel:"Датотека на ексел",
  Csv:"CSV-датотека",
  Sql:"SQL датотека",
  Json:"JSON-датотека",
  Url:"Увези по URL",
  Back:"Назад",
  Timers:"Тајмери",
  Cheque:"Проверете",
  Print:"Печати",
  Designs:"Дизајни",
  Pause_Printing:"Пауза на печатење",
  Resume_Printing:"Продолжи со печатење",
  Printing_Paused:"Печатењето е прекинато",
  PrintingUnavailable:"Извинете! Печатењето е недостапно на овој систем.",
  Prints_Paused:"Печатите се паузирани",
  Administration:"Администрација",
  Loading:"Се вчитува",
  Unnamed:"Неименуван",
  error:"Извинете, оваа проверка не можеше да се отвори за прегледување.",
  No_Cheques_To_Print:"Нема чекови за печатење",
  No_Cheques_To_Print_Word:"Креирај <a routerLink='/folder/Cheque/New'>Нов чек</a>.",
  New_Cheque:"Нова проверка",
  Start_One_Now:"Започни еден сега",
  Edit_Cheque:"Проверка за уредување",
  Select_Cheques:"Изберете проверки",
  Select_Employee:"Избери вработен",
  Default_Printer:"Стандарден печатач",
  Reassign:"Преназначување",
  Configure:"Конфигурирај",
  Template:"Шаблон",
  Designer:"Дизајнер",
  Edit_Designs:"Уреди дизајни",
  New_Design:"Нов дизајн",
  Next:"Следно",
  Save:"Зачувај",
  Name:"Име",
  Mail:"пошта",
  Amount:"Износ",
  Date:"Датум",
  PayPal:"Пејпал",
  Payouts:"Исплати",
  PayPal_Label:"Етикета на PayPal",
  Email_Username:"Е-пошта / корисничко име",
  Client_ID:"Клиент ИД",
  Sandbox_Email:"Е-пошта на Sandbox",
  PayPal_Email:"Е-пошта на PayPal",
  PayPal_Username:"Корисничко име на API",
  PayPal_Payouts:"Исплати на PayPal",
  Select_PayPal_Key:"Изберете клуч за PayPal",
  Secret:"Тајна",
  API_Secret:"API Secret",
  PayPal_API_Keys:"Клучеви на PayPal",
  New_PayPal_Key:"Нов клуч на PayPal",
  Email_Subject:"Предмет на е-пошта",
  Email_Message:"Порака по е-пошта",
  Payout_Options:"Опции за исплата",
  Payment_Note:"Платежна белешка",
  Enable_Employee:"Овозможи вработен",
  Enable_Production_Mode:"Овозможи режим на производство",
  Sandbox_Username:"Корисничко име на Sandbox",
  Sandbox_Signature:"Потпис на песочна кутија",
  Sandbox_Password:"Лозинка за песок",
  Production_Username:"Корисничко име за производство",
  Production_Signature:"Потпис на производство",
  Production_Password:"Лозинка за производство",
  Production_Email:"Производна е-пошта",
  API_Client_ID:"API клиент ID",
  API_Signature:"Потпис на API",
  API_Password:"Лозинка за API",
  API_Username:"Корисничко име на API",
  Secret_Key:"Тајниот клуч",
  Sandbox:"Песочна кутија",
  Production:"Производство",
  Sandbox_Keys:"Клучеви од песок",
  Production_Keys:"Клучеви за производство",
  API_Title:"Наслов на API",
  Production_Mode:"Режим на производство",
  Account_Label:"Етикета на сметката",
  No_PayPal_Setup:"Без PayPal клуч",
  Enable_PayPal_Account:"Овозможи сметка на PayPal",
  No_PayPal_Word:"Додајте го вашиот <a routerLink='/folder/Invoice/New'>клуч на PayPal API</a>.",
  Printed_Memo:"Печатена белешка",
  Employee:"Вработен",
  View_Employee:"Погледни го вработен",
  Mailing_Address:"Поштенска адреса",
  Company_Address:"Адреса на компанијата",
  Select_Company_Address:"Изберете адреса на компанијата",
  Address:"Адреса",
  Any_Day:"Секој ден",
  Address_Name:"Име на адреса",
  Unit:"Единица",
  Account:"сметка",
  Bank_Account:"Банкарска сметка",
  Account_Limits:"Овозможи ограничувања на сметката",
  Payroll:"Платен список",
  New_Payroll:"Нов платен список",
  No_Payroll:"Нема претстојна плата",
  Upcoming_Holiday:"Претстојниот празник:",
  Invoice_Due:"Достасне фактура:",
  New_Invoice:"Нова фактура",
  No_Invoices:"Без фактури",
  No_Invoices_Word:"Креирајте ја првата <a routerLink='/folder/Invoice/New'>фактура</a>.",
  Cheque_Due:"Достаспен чек:",
  Payrolls:"Платни списоци",
  Sandbox_Mode:"Режим на песок",
  Hire:"Вработи",
  Pay:"Плати",
  New:"Ново",
  Add:"Додај",
  Make:"Направи",
  Time:"Време",
  Write:"Напиши",
  Holiday:"Празник",
  Holidays:"Празници",
  Next_Holiday:"Следен одмор:",
  All_Done:"Се е готово!",
  Employees:"Вработени",
  Payees:"Плаќаници",
  Job:"Работа",
  Jobs:"Работни места",
  Invoice:"фактура",
  Invoices:"фактури",
  Vacations:"Одмори",
  Cheques:"Проверки",
  Brand_Cheques:"бренд",
  Payment:"Плаќање",
  Enable_Payment:"Овозможи плаќање",
  Payments:"Плаќања",
  Schedule:"Распоред",
  Schedules:"Распоред",
  Timesheet:"Временски лист",
  Timesheets:"Временски листови",
  Salary:"Плата",
  New_Address:"Нова адреса",
  Address_2:"Адреса Линија 2)",
  _City:"Град",
  _State:"Држава/Пров",
  City:"Град/населба",
  State:"Држава / провинција",
  Postal:"Поштенски / поштенски број",
  ZIP:"Поштенски / ZIP",
  Country:"земја",
  Addresses:"Адреси",
  Required_Options:"Потребни опции",
  Optional_Options:"Факултативни опции",
  Additional_Options:"Дополнителни опции",
  Required:"Задолжително",
  Optional:"Изборен",
  Additional:"Дополнително",
  No_Addresses:"Без адреси",
  New_Address_Word:"Додајте ја првата <a routerLink='/folder/Address/New'>адреса</a>.",
  Select_Address:"Изберете адреса",
  No_Address:"Без адреси",
  Print_Cheque:"Проверка за печатење",
  Print_Cheque_Now:"Печати, провери сега",
  Description:"Опис",
  Pay_To_The_Order_Of:"Плати по нарачка на:",
  Select_Date_Range:"Изберете опсег на датуми",
  Select_Starting_Date:"Изберете датум за почеток",
  Select_Ending_Date:"Изберете го датумот на завршување",
  Select_Date:"Изберете датум",
  Cheque_Date:"Датум на проверка",
  Cheque_Memo:"Проверете го белешката",
  Blank_Cheque:"Празна проверка",
  Blank:"Празно",
  No_Cheques:"Без проверки",
  No_Cheques_Word:"Испечатете ја вашата прва <a routerLink='/folder/Cheque/New'>Проверка</a>.",
  Cheque_Amount_Placeholder:"0,00 долари",
  View_Image:"Прикажи слика",
  View:"Поглед",
  Modify:"Измени",
  Delete:"Избриши",
  Cheque_Paid:"Платено",
  New_Deduction:"Нов одбиток",
  Title:"Наслов",
  Frequency:"Фреквенција",
  Hourly:"На час",
  Daily:"Дневно",
  Weekdays:"Неделни денови",
  BiWeekly:"2 недели",
  TriWeekly:"3 недели",
  Monthly:"Месечно",
  MiMonthly:"2 месеци",
  Quarterly:"Квартален",
  Yearly:"Годишно",
  Every_Week:"Секоја недела",
  Every_Payroll:"Секој платен список",
  Every_Month:"Секој месец",
  Annually:"Годишно",
  Always_Scheduled:"Секогаш закажано",
  Start_Date:"Почетен датум",
  End_Date:"Крајна дата",
  Start_Time:"Време за почеток",
  End_Time:"Време на крајот",
  Deduction_Label:"Одбична етикета",
  Notes:"Белешки",
  Options:"Опции",
  Enable:"Овозможи",
  Select_Deductions:"Изберете одбитоци",
  Deductions:"Одбитоци",
  No_Deductions:"Без одбитоци",
  No_Deductions_Word:"Креирајте го вашиот прв <a routerLink='/folder/Deduction/New'>Одбивање</a>.",
  New_Employee:"Нов вработен",
  No_Title:"Без титула",
  _Name:"Име",
  About_Yourself:"За себе",
  Full_Name:"Целосно име",
  Birthday:"Роденден",
  Email:"Е-пошта",
  SMS:"ПОРАКА",
  Phone:"Телефон",
  Test:"Тест",
  Call:"Повик",
  Fax:"Факс",
  Printed_Note:"Печатена белешка",
  Position:"Позиција",
  Job_Position:"Работна позиција",
  Select_a_Job:"Избери работа",
  Select_a_Salary:"Изберете плата",
  Add_a_Deduction:"Додај одбиток",
  Taxes:"Даноци",
  Add_Taxes:"Додај даноци",
  Date_of_Birth:"Дата на раѓање",
  Email_Address:"И-мејл адреса",
  Phone_Number:"Телефонски број",
  Phone_Call:"Телефонски повик",
  Enable_on_Payroll:"Овозможи на платен список",
  Select_Employees:"Изберете вработени",
  No_Employees:"Без вработени",
  No_Employees_Word:"Додајте го вашиот прв нов <a routerLink='/folder/Employee/New'>Вработен</a>.",
  No_Name:"Нема име",
  Unemployeed:"Невработен",
  Employeed:"Вработен",
  Paid:"Платено",
  Enabled:"Овозможено",
  Disabled:"Оневозможено",
  Fire:"Оган",
  Not_Available:"Не е достапно",
  Not_Available_Word:"Испечатете ја вашата прва <a routerLink='/folder/Invoice/New'>фактура</a> и добијте плата.",
  Select_Invoices:"Избери_фактури",
  Print_Invoice_Word:"Испечатете ја вашата прва <a routerLink='/folder/Invoice/New'>фактура</a> и добијте плата.",
  Invoice_Title:"Наслов на фактура",
  Invoice_Date:"Датум на фактура",
  Due_Date:"Датум на доспевање",
  New_Job:"Нова работа",
  Details:"Детали",
  Customize:"Прилагоди",
  Customize_Dashboard:"Приспособете ја контролната табла",
  Components:"Компоненти",
  No_Components:"Без компоненти",
  Main_Components:"Главни компоненти",
  Smaller_Components:"Помали компоненти",
  Error_Loading_Page:"Грешка при вчитување на оваа страница.",
  Bank_Details:"Детали за банка",
  About_Your_Job:"За вашата работа",
  Your_Schedule:"Вашиот распоред",
  Job_Title:"Работно звање",
  Job_Description:"Опис на работно место",
  Job_Details:"Детали за работа",
  Enable_Job:"Овозможи работа",
  Pay_Period:"Период на плаќање",
  Perpetually_Schedule:"Постојано распоред",
  Select_Jobs:"Изберете работни места",
  No_Jobs:"Без работа",
  Add_Jobs:"Додај работни места",
  No_Jobs_Word:"Додајте ја првата <a routerLink='/folder/Job/New'>Работа</a> на списокот.",
  Count_Employees:"job.employee_count+' Employees'",
  Zero_Employees:"0 вработени",
  New_Leave:"Ново отсуство",
  Leave_Name:"Остави име",
  Paid_Leave:"Платено отсуство",
  Leave_Pay:"Остави плата",
  Indefinite_Leave:"Неопределено отсуство",
  Indefinite_Payroll:"Неопределен платен список",
  Leave:"Остави",
  Add_Schedules:"Додај распоред",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Овозможи отсуство",
  Select_Leaves:"Изберете лисја",
  No_Leaves:"Без листови за отсуство",
  Leave_Of_Absence:"Оставка за отсуство",
  Leaves_Of_Absence:"Лисја на отсуство",
  New_Leave_of_Absense:"Ново отсуство за отсуство",
  No_Leaves_Word:"Додадете го првиот <a routerLink='/folder/Leave/New'>Остави за отсуство</a>.",
  Not_Enabled:"Не е овозможено",
  Absences:"Отсуства",
  Payee:"Приносител",
  New_Payee:"Нов примател",
  Payee_Identifier:"Идентификатор на примач",
  Payee_Name:"Име на примач",
  Payee_Title:"Наслов на примач",
  Payment_Memo:"Плаќање",
  Select_Payees:"Изберете приматели",
  No_Payees:"Без приматели",
  Add_Payee_Note:"Додадете го првиот <a routerLink='/folder/Payee/New'>примач на плати</a>.",
  New_Payees:"Нови приматели",
  About_The_Payment_Schedule:"Распоред на плаќање",
  Your_Payroll_Schedule:"Автоматски платен список",
  New_Payment:"Нова исплата",
  Identifier:"Идентификатор",
  Select:"Избери",
  Memo:"Меморандум",
  Payment_Date:"Датум на плаќање",
  Mark_as_Paid:"Обележи како платено",
  Select_Payments:"Изберете плаќања",
  No_Payments:"Без плаќања",
  No_Payments_Word:"Креирајте го првиот <a routerLink='/folder/Payment/New'>Плаќање</a>.",
  Create_Payroll:"Креирај платен список",
  Properties:"Својства",
  Payroll_Title:"Наслов на платен список",
  Payroll_Notes:"Белешки за платен список",
  Payroll_Setup:"Поставување платен список",
  Tabulate_Payments:"Табеларни плаќања",
  Tabulate:"Табела",
  By:"Од:",
  Payments_By:"Плаќања од страна",
  Timesheets_And_Schedules:"Временски листови и распореди",
  Both:"Двајцата",
  Items:"Предмети",
  Add_Payees:"Додај приматели",
  Add_Account:"Додадете сметка",
  Enable_Account:"Овозможи сметка",
  Enable_Payroll:"Овозможи платен список",
  Print_Payroll:"Печати платен список",
  No_Payrolls:"Без платен список",
  No_Payroll_Word:"Креирајте го вашиот прв <a routerLink='/folder/Payroll/New'>Платен список</a>.",
  View_more:"ГЛЕДАЈ ПОВЕЌЕ",
  Less:"Помалку",
  Add_Payroll:"Додај платен список",
  Select_Payroll:"Изберете платен список",
  About_Your_Salary:"За вашата плата",
  Add_Salaries:"Додадете плати",
  Add_Salary:"Додај плата",
  Salaries:"Плати",
  No_Salaries:"Без плати",
  No_Salaries_Word:"Додадете ја првата <a routerLink='/folder/Salary/New'>Плата</a>.",
  Select_Salaries:"Изберете плати",
  New_Salary:"Нова плата",
  Salary_Name:"Идентификатор на плата",
  Enable_Salary:"Овозможи плата",
  Salary_Amount:"Износ на плата",
  New_Schedule:"Нов распоред",
  Schedule_Title:"Наслов на распоред",
  Add_Address:"Додај адреса",
  Repeat:"Повтори",
  Design:"Дизајн",
  Edit_Design:"Уреди дизајн",
  Edit_this_Design:"Уреди го овој дизајн",
  Repeat_Payment:"Повторено плаќање",
  Enable_Schedule:"Овозможи распоред",
  Never:"Никогаш",
  Select_Schedule:"Изберете распоред",
  No_Schedules:"Без распоред",
  No_Schedules_Word:"Креирајте го првиот <a routerLink='/folder/Schedule/New'>Распоред</a>.",
  New_Administrator:"Нов администратор",
  Username:"Корисничко име",
  First_Name:"Име",
  Last_Name:"Презиме",
  Add_an_Address:"Додај адреса",
  Payment_Limit:"Ограничување по плаќање",
  Total_Limit:"Вкупна граница",
  Select_Accounts:"Изберете сметки",
  No_Administrators:"Без администратори",
  No_Administrators_Word:"Креирајте ја првата <a routerLink='/folder/Administrator/New'>Администраторска сметка</a>.",
  New_Administrators_Word:"Додадете го првиот <a routerLink='/folder/Administrator/New'>Администратор</a>",
  Cloud:"Облак",
  Backup:"Резервна копија",
  Enable_iCloud:"Овозможи iCloud",
  Enable_Google_Drive:"Овозможи Google Drive",
  Enable_Microsoft_OneDrive:"Овозможи Microsoft OneDrive",
  Automatically_Backup:"Автоматска резервна копија",
  FTP_Settings:"FTP Settings",
  URL_File_Prompt:"Ве молиме наведете ја локацијата за датотеката .xls / .xlsx / .json за увоз:",
  URL_SQL_Prompt:"Ве молиме наведете ја локацијата на датотеката SQLite за увоз:",
  FTP_Backup:"Резервна копија на FTP",
  FTP_Import:"Увоз на FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Домаќин",
  Port:"Пристаниште",
  Path:"Пат",
  Data_Path:"Патека на податоци",
  Enable_FTP_Account:"Овозможи FTP сметка",
  HostnameIP:"Име на домаќин",
  Password:"Лозинка",
  Connection_Port:"Пристаниште за поврзување",
  Enable_MySQL_Database:"Овозможи MySQL база на податоци",
  Log:"Дневник",
  Reset:"Ресетирање",
  Erase:"Избриши",
  Export:"Извоз",
  Database:"База на податоци",
  Upload_CSV:"Постави CSV",
  Download_CSV:"Преземи CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Резервна копија на MySQL",
  Internal_Notes:"Внатрешни белешки",
  Root_Path:"Корен пат",
  Select_Backup:"Изберете резервна копија",
  Add_New_Backup:"Додај нова резервна копија",
  First_Backup:"Поставете ја првата резервна копија...",
  Backups:"Резервни копии",
  Add_Backup:"Додај резервна копија",
  No_Backups:"Нема да се најдат резервни копии.",
  Select_Backup_Type:"Изберете го типот на резервна копија што сакате да ја поставите...",
  Backup_Type:"Тип на резервна копија",
  FTP_Drive:"FTP диск",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Возење",
  Microsoft_OneDrive:"Возење",
  Import_Fields:"Полиња за увоз",
  Import_Fields_Word:"Користете го овој дел за да изберете <a routerLink='/folder/Holiday/New'>Увези</a> податоци.",
  Upload:"Прикачи",
  Download:"Преземи",
  Download_JSON:"Преземи како JSON податоци",
  Download_SQL:"Преземи како SQL-датотека",
  New_Bank:"Нова банка",
  New_Account:"Нова сметка",
  New_Bank_Account:"Нова банкарска сметка",
  Upload_Image:"Постави слика",
  Bank_Name:"Име на банка",
  Bank_Address:"Адреса на банка",
  Branch_Address:"Адреса на филијала",
  Address_on_Cheque:"Адреса",
  Cheque_Numbers:"Проверете ги броевите",
  Cheque_Details:"Проверете ги деталите",
  Bank_Logo:"Лого на банката",
  Cheque_ID:"Проверете лична карта",
  Starting_Cheque_ID:"Започнува проверка на ID",
  Transit_Number:"Транзитен број",
  Institution_Number:"Број на институција",
  Designation_Number:"Број на назначување",
  Account_Number:"Број на сметка",
  Limits:"Граници",
  Default_Limits:"Стандардни граници",
  Over_Limit:"Над граница",
  Under_Limit:"Под лимит",
  Payroll_Limit:"Лимит на платен список",
  Enable_Bank_Account:"Овозможи банкарска сметка",
  Select_Account:"Изберете сметка",
  No_Bank_Account:"Нема банкарска сметка",
  No_Bank_Account_Word:"Додајте ја првата <a routerLink='/folder/Accounts/New'>банкарска сметка</a>.",
  Bank_Accounts:"банкарски сметки",
  No_Accounts:"Без сметки",
  No_Accounts_Note:"Додајте ја првата <a routerLink='/folder/Accounts/New'>банкарска сметка</a>.",
  Cheque_Designer:"Проверете го дизајнерот",
  Cheque_Design:"Проверете го дизајнот",
  Select_Design:"Изберете дизајн",
  Cheque_Designs:"Проверете ги дизајните",
  No_Cheque_Designs:"Без дизајни за проверка",
  No_Cheque_Designs_Word:"Создадете свој <a routerLink='/folder/Settings/Cheque/Design/New'>Проверете го дизајнот</a>.",
  Set_Default:"Поставени како основни",
  Default:"Стандардно",
  Remove:"Отстрани",
  Folder:"Папка",
  Edit:"Уредување",
  LoadingDots:"Се вчитува...",
  Add_a_New_File:"Додај <a href='#' (click)='add()'>Нова датотека</a> во",
  this_folder:"оваа папка",
  FTP_Backup_Settings:"Поставки за резервна копија на FTP",
  Secure_File_Transfer:"Безбеден пренос на датотеки",
  New_Holiday:"Нов празник",
  Add_Holiday:"Додај одмор",
  Holiday_Name:"Име на празникот",
  Additional_Pay:"Дополнителна плата",
  Enable_Holiday:"Овозможи одмор",
  Select_Holidays:"Изберете празници",
  No_Holidays:"Без празници",
  No_Holidays_Word:"Додадете го првиот <a routerLink='/folder/Holiday/New'>Јавниот празник</a>.",
  New_Import:"Нов увоз",
  Import_Data:"Увези податоци",
  Import_Data_Word:"Изберете го типот на датотека или методот за подигање по ваш избор.<br /> Ќе можете да изберете кои увезени полиња во датотеката одговараат на кој било параметар во апликацијата откако ќе поставите поддржана датотека.", 
  Import_File:"Увези датотека",
  Link_To_File:"Врска до датотека",
  Select_File:"Изберете датотека",
  New_Moderator:"Нов модератор",
  Allow_Moderation:"Дозволи умереност",
  Create_Paycheques:"Креирај плати",
  Edit_Paycheques_and_Data:"Уреди чекови и податоци",
  Destroy_Data_and_Paycheques:"Уништи податоци и чекови",
  Bonus_Percentage:"Процент на плата",
  Fixed_Amount:"Фиксна сума",
  Select_Moderator:"Изберете модератор",
  No_Moderators:"Без модератори",
  Moderators:"Модератори",
  Moderator_Account:"Креирајте ја првата <a routerLink='/folder/Administrator/New'>сметка на модератор</a>.",
  No_Moderators_Word:"Додајте го првиот <a routerLink='/folder/Administrator/New'>Модератор</a>.",
  Defaults:"Стандардно",
  Provide_Us_Info:"Дајте ни информации",
  Setup_Your_Printer:"Поставете го вашиот печатач",
  Your_Company:"За вашата компанија",
  Company_Name:"Име на компанија",
  Currency:"валута",
  Default_Currency:"Стандардна валута",
  Base_Monthly_Income:"Месечни примања",
  Protection:"Заштита",
  App_Protection:"Заштита на апликацијата",
  PIN_Code_Protection:"Заштита на PIN код",
  App_Protection_Word:"Овозможете безбедносни методи кои помагаат да се заштити вашата сметка.",
  PIN_Code:"PIN кодот",
  Change_PIN:"Промени PIN",
  New_Password:"Нова лозинка",
  Geofence_Protection:"Заштита од гео-ограда",
  Geofence_Area:"Поставете област",
  Distance:"Растојание",
  Setup_Now:"Постави сега",
  Mile:"Милја",
  Km:"Км",
  m:"м",
  Facial_Recognition:"Препознавање на лице",
  Face:"Лице",
  Setup:"Поставување",
  Label:"етикета",
  Password_Protection:"Заштита со лозинка",
  Modify_Password:"Промени ја лозинката",
  New_Tax_Entry:"Нов даночен запис",
  New_Tax:"Нов данок",
  Tax_Label:"Даночна ознака",
  Perpetually_Enabled:"Постојано овозможено",
  Select_Taxes:"Изберете даноци",
  Tax_Deductions:"Даночни одбивања",
  No_Tax_Deductions:"Без даночни намалувања",
  No_Tax_Deductions_Word:"Додадете го првиот одбиток <a routerLink='/folder/Tax/New'>Даночен</a>.",
  New_Timer:"Нов тајмер",
  Start:"Почеток",
  Stop:"Стоп",
  Start_Timer:"Старт тајмер",
  Stop_Timer:"Стоп тајмер",
  Timer_Active:"Активен тајмер",
  Timer:"Тајмер:",
  Timer_Running:"Тајмер: (работи)",
  Save_Timer:"Зачувај тајмер",
  New_Timesheet:"Новиот временски лист",
  Select_Timesheets:"Изберете временски листови",
  Work_Notes:"Работни белешки",
  Entry_Title:"Наслов за влез",
  No_Timesheets:"Без временски листови",
  No_Timesheets_Word:"Додајте го првиот <a routerLink='/folder/Timesheet/New'>Временски лист</a>.",
  Timesheet_Submitted:"Временскиот лист е поднесен",
  Timesheet_Congrats:"Честитки! Листот за вашето време е успешно поднесен. Ви благодариме!",
  Timesheet_Copy:"За да добиете копија од вашите документи, доставете ни ја вашата адреса за е-пошта и/или број на мобилен телефон.",
  Submit:"Поднеси",
  Allow_Notifications:"Дозволи известувања",
  Untitled_Entry:"Нов влез",
  Untitled_Bank:"Банка без наслов",
  Timesheet_Entry:"Внесување на временски лист",
  Work_Description:"Опис на работата",
  Enable_Timesheet:"Овозможи временски лист",
  Submit_Timesheet:"Поднеси временски лист",
  Vacation:"Одмор",
  New_Vacation:"Нов одмор",
  Vacation_Name:"Име на одмор",
  Paid_Vacation:"Платен одмор",
  Vacation_Pay:"Плаќање за годишен одмор",
  Enable_Vacation:"Овозможи одмор",
  Select_Vacations:"Изберете одмор",
  No_Vacations:"Нема одмори",
  No_Vacations_Word:"Креирајте го првиот запис <a routerLink='/folder/Vacation/New'>Одмор</a>.",
  Payroll_Schedule:"Распоред на плати",
  Next_Payroll:"Следен платен список:",
  Upcoming_Payroll:"Претстоен платен список",
  No_Upcoming_Payroll:"Нема претстојна плата",
  Address_Book:"Адресар",
  Archived_Documents:"Архивирани документи",
  Dev_Mode:"Апликација во режим на развој",
  Administrators:"Администратори",
  Privacy:"Приватност",
  None:"Никој",
  Select_Signature:"Изберете потпис",
  No_Signatures:"Без потписи",
  No_Signatures_Word:"Додадете го првиот <a routerLink='/folder/Signature/New'>Потпис</a>.",
  Repeat_Indefinitely:"Повторувај на неодредено време",
  Sign:"Знак",
  Sign_Here:"Потпишете овде",
  Date_Signed:"Датум на потпис",
  Signature_Pad:"Подлога за потписи",
  Account_Holder:"Содржител на сметка",
  Account_Properties:"Својства на сметката",
  Name_On_Cheque:"Име на чек",
  Server_Hostname:"Име на домаќин на серверот / IP",
  Printers:"Принтери",
  No_Printers:"Без печатачи",
  Printer_Exists:"Веќе постои печатач со ова име.",
  No_Printers_Word:"Додајте го првиот <a routerLink='/folder/Printer/New'>Принтер</a>.",
  No_Printer_Alert:"Не е дефиниран печатач. Дали сакате да поставите печатач?",
  Add_Printer:"Додај печатач",
  New_Printer:"Нов печатач",
  Printer_Hostname:"Име на домаќин на печатач / IP",
  Printer_Label:"Етикета за печатач",
  Printer_Protocol:"Протокол за печатач",
  Protocol:"Протокол",
  Email_Print:"Е-пошта",
  AirPrint:"ЕрПринт",
  IPP:"IPP",
  LPD:"ЛПД",
  HPJetDirect:"Сокет",
  Print_Job:"Работа за печатење",
  Printed:"Печатено",
  Not_Printed:"Не е печатено",
  Print_Jobs:"Работа за печатење",
  Print_Queue:"Редица за печатење",
  No_Print_Jobs:"Без задачи за печатење",
  No_Prints:"Креирај нов <a routerLink='/folder/Cheque/New'>Проверете</a> за печатење.",
  Prints:"Печати",
  Find_Printer:"Најди печатач",
  Find_AirPrint_Devices:"Најди ги уредите за AirPrint",
  Select_Printer:"Изберете печатач",
  System_UI:"Системски интерфејс",
  Printer:"Печатач",
  Status:"Статус",
  Preview:"Преглед",
  Enable_Print_Job:"Овозможи задача за печатење",
  Queue_Weight:"Тежина на редот",
  Unlimited:"Неограничено",
  Show_Advanced_Printer_Options:"Прикажи напредни опции за печатач",
  Advanced:"Напредно",
  Location:"Локација",
  Note:"Забелешка",
  Queue_Name:"Име на редот",
  Pages_Printed_Limit:"Ограничување на печатените страници",
  MultiPage_Idle_Time:"Време на чекање со повеќе страници (и)",
  Page_Count_Limit:"Ограничување на бројот на страници",
  Page_Orientation:"Ориентација на страницата",
  Portrait:"Портрет",
  Landscape:"Пејзаж",
  Duplex:"Дуплекс",
  Double_Sided_Printing:"Двострано",
  Duplex_Mode:"Дуплекс режим",
  Duplex_Short:"Кратко",
  Duplex_Long:"Долго",
  Duplex_None:"Никој",
  Color_And_Quality:"Боја и квалитет",
  Monochrome:"Монохроматски",
  Photo_Quality_Prints:"Потпечатоци со квалитет на фотографии",
  Printer_Email:"Е-пошта на печатач",
  Automatically_Downsize:"Автоматско намалување",
  Paper:"Хартија",
  Paper_Name:"Име на хартија",
  Paper_Width:"Ширина на хартија",
  Paper_Height:"Висина на хартија",
  Paper_Autocut_Length:"Должина на автоматско сечење на хартија",
  Margins:"Маргини",
  Page_Margins:"Маргини на страницата",
  Page_Margin_Top:"Маргина на врвот на страницата",
  Page_Margin_Right:"Маргина на десната страна",
  Page_Margin_Bottom:"Маргина на долната страна",
  Page_Margin_Left:"Маргина на левата страна",
  Add_Employees:"Додај вработени",
  Header:"Заглавие",
  Print_A_Page_Header:"Печати заглавие на страница",
  Header_Label:"Етикета на заглавието",
  Header_Page_Index:"Индекс на заглавие на страница",
  Header_Font_Name:"Фонт за заглавие",
  Select_Font:"Изберете фонт",
  Font_Selector:"Избирач на фонтови",
  Header_Font_Size:"Фонт за заглавие",
  Header_Bold:"Заглавие задебелено",
  Header_Italic:"Заглавие курзив",
  Header_Alignment:"Порамнување на заглавието",
  Left:"Лево",
  Center:"Центар",
  Right:"Точно",
  Justified:"Оправдано",
  Header_Font_Color:"Боја на заглавието",
  Select_Color:"Изберете боја",
  Footer:"Футер",
  Print_A_Page_Footer:"Печати подножје на страница",
  Footer_Label:"Етикета на подножјето",
  Footer_Page_Index:"Индекс на подножјето",
  Footer_Font_Name:"Фонт на подножјето",
  Fonts:"Фонтови",
  Done:"Направено",
  Select_Fonts:"Изберете фонтови",
  Footer_Font_Size:"Големина на подножјето",
  Footer_Bold:"Футер Болд",
  Footer_Italic:"Итак на подножјето",
  Footer_Alignment:"Порамнување на подножјето",
  Footer_Font_Color:"Боја на подножјето",
  Page_Copies:"Копии на страници",
  Enable_Printer:"Овозможи печатач",
  Remote_Logging:"Далечинско евидентирање",
  Log_Server:"Дневник на сервер",
  Encryption:"Шифрирање",
  Random_Key:"Случаен клуч",
  Encryption_Key:"Клуч за шифрирање",
  Cheques_Webserver:"Прилагодена база на податоци",
  Learn_How:"Научи како",
  Signature:"Потпис",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Прикажи потпис",
  Printed_Signature:"Печатен потпис",
  Digitally_Sign:"Дигитален знак",
  Digital_Signature:"Дигитален потпис",
  Add_Signature:"Додај потпис",
  Add_Your_Signature:"Додадете го вашиот потпис",
  Enable_Signature:"Овозможи потпис",
  Digitally_Signed:"Дигитално потпишан",
  Insert_Error:"Не може да се зачува проверката поради проблеми со базата на податоци.",
  Delete_Confirmation:"Дали сте сигурни дека сакате да ја избришете оваа информација?",
  Discard_Confirmation:"Дали сте сигурни дека сакате да ги отфрлите овие информации?",
  Discard_Bank_Confirmation:"Дали сте сигурни дека сакате да ја отфрлите оваа сметка?",
  Discard_Printer_Confirmation:"Дали сте сигурни дека сакате да го отфрлите овој печатач?",
  Delete_Bonus_Confirmation:"Дали сте сигурни дека сакате да го избришете овој бонус?",
  Delete_Invoice_Confirmation:"Дали сте сигурни дека сакате да ја избришете оваа фактура?",
  Generated_Cheque:"Генерирана проверка",
  Generated_Invoice:"Генерирана фактура",
  Schedule_Start_Time:"Распоред почеток",
  Schedule_End_Time:"Крај на распоред",
  New_Call:"Нов повик",
  No_Contacts:"Без контакти",
  No_Contacts_Word:"Администраторите, модераторите, вработените и примателите се појавуваат како контакти.",
  PDF_Subject:"финансии",
  PDF_Keywords:"Платен список ПДФ чекови за проверка",
  Printer_Setup:"Поставување печатач",
  Printer_Selection:"Избор на печатач",
  New_Fax:"Нов факс",
  New_Fax_Message:"Нова порака за факс",
  Fax_Machine:"Факс машина",
  Fax_Name:"Име на факс",
  Fax_Email:"Факс-пошта",
  Fax_Number:"Број на факс",
  Contents:"Содржина",
  Fax_Body:"Тело на страницата",
  Header_Word:"Заглавие:",
  Header_Text:"Текст за заглавие",
  Include_Header:"Вклучи заглавие",
  Include_Footer:"Вклучи футер",
  Footer_Word:"Футер:",
  Footer_Text:"Текст во подножјето",
  Attach_a_Cheque:"Прикачи чек",
  Add_Deduction:"Додај одбиток",
  Enable_Fax:"Испрати факс",
  Select_Fax:"Изберете факс",
  No_Faxes:"Без факсови",
  Faxes:"Факсови",
  Save_and_Send:"Испрати факс",
  Save_and_Pay:"Зачувај и плати",
  WARNING:"ПРЕДУПРЕДУВАЊЕ:",
  Remember:"Запомни",
  Printing:"Печатење",
  Printing_Complete:"Печатењето е завршено!\n\n",
  of:"од",
  There_Were:"Имаше ",
  Successful_Prints:"успешни отпечатоци и",
  Unsuccessful_Prints:"неуспешни отпечатоци.",
  PrinterError:"Извинете! Имаше грешка.",
  Printing_:"Печатење...",
  PrinterSuccess:"Документот е отпечатен успешно.",
  PrintersSuccess:"Документите се отпечатени успешно.",
  Print_Cheques:"Печати проверки",
  New_Message:"Нова порака",
  New_Messages:"Нови пораки",
  Read_Message:"Прочитајте ја пораката",
  Write_Message:"Напиши порака",
  Send_Message:"Испрати порака",
  Subject:"Предмет",
  Message:"Порака",
  Writing:"Пишување...",
  Send:"Испрати",
  Set_Date:"Постави датум",
  Set_Time:"Постави време",
  Recieve:"Прими",
  Set_as_Default:"Поставени како основни",
  Default_Account:"Стандардна сметка",
  Default_Design:"Стандарден дизајн",
  Multiple_Cheques:"Проверки (тројни)",
  Account_Mode:"Режим на сметка",
  Multiple_Cheques_Description:"Три проверки на страница.",
  Check_and_Table:"Проверка и табела",
  Check_including_Table:"Проверка и сметководствена табела.",
  Check_Mailer:"Проверете го мејлерот",
  Check_Mailer_Window:"Проверете со прозорец со адреса.",
  DocuGard_Table_Top:"Проверка и табела на DocuGard (горе)",
  DocuGard_Table_Middle:"Проверка и табела на DocuGard (средно)",
  DocuGard_Table_Bottom:"Проверка и табела на DocuGard (долу)",
  DocuGard_Mailer_Top:"DocuGard Испратена проверка (горе)",
  DocuGard_Mailer_Middle:"DocuGard Испратена проверка (средно)",
  DocuGard_Mailer_Bottom:"DocuGard Испратена проверка (долу)",
  DocuGard_Three_Cheques:"ДокуГард проверки (тројни)",
  DocuGard_Cheque_Top:"Проверка на DocuGard (горе)",
  DocuGard_Cheque_Middle:"Проверка на DocuGard (средно)",
  DocuGard_Cheque_Bottom:"Проверка на DocuGard (долу)",
  DocuGard_Three_Cheques_Window:"Три проверки на една страница.",
  DocuGard_Table_Top_Window:"Проверка и табела за заработка.",
  DocuGard_Table_Middle_Window:"Проверка и табела за заработка.",
  DocuGard_Table_Bottom_Window:"Проверка и табела за заработка.",
  DocuGard_Mailer_Top_Window:"Проверка, табела и адреса.",
  DocuGard_Mailer_Middle_Window:"Проверка, табела и адреса.",
  DocuGard_Mailer_Bottom_Window:"Проверка, табела и адреса.",
  DocuGard_Cheque_Top_Window:"Проверете дали има безбедна хартија.",
  DocuGard_Cheque_Middle_Window:"Проверете дали има безбедна хартија.",
  DocuGard_Cheque_Bottom_Window:"Проверете дали има безбедна хартија.",
  Basic_Cheque:"Проверете (горе)",
  Basic_Cheque_Print:"Испечати еден чек.",
  Error_Setting_As_Paid:"Грешка при поставување како платено",
  Add_Attachment:"Додај прилог",
  PrinterUnavailable:"Печатачот е недостапен",
  CreditCardComponent:"Карти",
  PaypalComponent:"Пејпал",
  InteracComponent:"Интерак",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Нов депозит",
  Deposits:"Депозити",
  No_Deposits:"Без депозити",
  Credit_Card_Deposit:"Кредитна картичка",
  New_Credit_Card_Deposit_Message:"Депозит со кредитна картичка",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Депозит на биткоин",
  New_Interac_Deposit:"Интерак",
  New_Interac_Deposit_Message:"Interac Transfer",
  Payments_Limit:"Лимит на плаќање",
  No_Payment_Limit:"Без ограничување за плаќање",
  PayPal_Deposit:"Пејпал",
  PayPal_Deposit_Message:"Пејпал депозит",
  No_Deposits_Word:"Додадете ја првата компанија <a routerLink='/folder/Deposit/New'>Депозит</a>.",
  No_Documents_Specified:"Не беа наведени документи за печатење",
  No_Printers_Added:"Не се пронајдени печатачи. Одете во Поставки > Печатачи за да додадете еден.",
  DB_Erase_Prompt:"Целосно избришете ја целата база на податоци? ПРЕДУПРЕДУВАЊЕ: Ќе ги изгубите сите зачувани информации!",
  Console_Warning:"Не ставајте никаков текст во оваа конзола. Може да се ставите себеси и/или вашата компанија на сериозен ризик.",
  No_Faxes_Word:"Креирајте го првиот <a routerLink='/folder/Fax/New'>Факс</a>.",
  Cheque_Delete_Confirmation:"Дали сте сигурни дека сакате да ја избришете оваа проверка?",
  Design_Delete_Confirmation:"Дали сте сигурни дека сакате да го избришете овој дизајн?",
  Cheque_Pay_Confirmation:"Означи го овој чек како платен? НЕМА да се појави во редот за печатење.",
  Payment_Pay_Confirmation:"Обележете го ова плаќање како платено? НЕМА да се појави во редот за проверка.",
  Delete_Deduction_Confirmation:"Дали сте сигурни дека сакате да го избришете овој одбиток?",
  Delete_Job_Confirmation:"Дали сте сигурни дека сакате да ја избришете оваа работа?",
  Delete_Timesheet_Confirmation:"Дали сте сигурни дека сакате да го избришете овој временски лист?",
  Delete_Schedule_Confirmation:"Дали сте сигурни дека сакате да го избришете овој распоред?",
  Delete_Setting_Confirmation:"Дали сте сигурни дека сакате да ја ресетирате оваа поставка?",
  Delete_Fax_Confirmation:"Дали сте сигурни дека сакате да го избришете овој факс?",
  Delete_File_Confirmation:"Дали сте сигурни дека сакате да ја избришете оваа датотека?",
  Delete_Vacation_Confirmation:"Дали сте сигурни дека сакате да го избришете овој одмор?",
  Delete_Printer_Confirmation:"Дали сте сигурни дека сакате да го избришете овој печатач?",
  Remove_Design_Confirmation:"Дали сте сигурни дека сакате да го избришете овој дизајн?",
  Delete_Payroll_Confirmation:"Дали сте сигурни дека сакате да го избришете овој платен список?",
  Send_Fax_Email_Confirmation:"Дали сакате да испратите факс и е-пошта овие документи?",
  Send_Email_Confirmation:"Дали сакате да го испратите овој документ преку е-пошта?",
  Send_Fax_Confirmation:"Дали сакате да го испратите овој документ по факс?",
  Error_Generating_PDF:"Извинете. Имаше грешка при генерирањето на овој документ.",
  PDF_Error_Saving_Image:"Извинете. Имаше грешка при зачувување на PDF-слика од овој документ.",
  Test_Printer_Confirmation:"Дали сакате да испечатите тест страница на овој печатач?",
  Save_Timesheet_Prompt:"Ве молиме додајте \"Наслов\" или притиснете \"Старт тајмер\" за да зачувате.",
  Remove_Geofence_Prompt:"Дали сте сигурни дека сакате да ја отстраните локацијата на оваа гео-ограда?",
  Delete_Employee_Confirmation:"Дали сте сигурни дека сакате да избришете",
  Fire_Employee_Confirmation:"Дали сте сигурни дека сакате да пукате"
}