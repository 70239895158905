export const Yi = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"דרוקרעכט &קאָפּי; 2023",
  black:"שוואַרץ",
  green:"גרין",
  gold:"גאָלד",
  blue:"בלוי",
  brown:"ברוין",
  purple:"לילאַ",
  pink:"ראָזעווע",
  red:"רויט",
  Swatches:"סוואַטשיז",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Hue",
  Saturation:"זעטיקונג",
  Lightness:"ליכטיקייט",
  Upgrade_To_Pro:"אַפּגרייד צו פּראָ",
  AllFeaturesInclude:"אַלע פֿעיִקייטן אַרייַננעמען:",
  PrintUnlimitedCheques:"דרוק ונלימיטעד טשעקס",
  PremiumChequeDesigns:"פּרעמיום טשעק דיזיינז",
  ManageUnlimitedEmployees:"פירן ונלימיטעד עמפּלויעעס",
  AddUnlimitedPayees:"לייג ונלימיטעד פּייַעעס",
  CreateUnlimitedPayrolls:"שאַפֿן ונלימיטעד פּייראָולז",
  UnlimitedSchedulesandTimeSheets:"אַנלימאַטאַד סקעדזשולז און צייט שיץ",
  BulkPayPalPayouts:"פאַרנעם PayPal פּייאַוט",
  UnlimitedBankAccounts:"אַנלימאַטאַד באַנק אַקאַונץ",
  ManageMultipleCompanies:"פירן קייפל קאָמפּאַניעס",
  TrackInsurancePolicies:"שפּור ינסוראַנסע פּאַלאַסיז",
  Bio_MetricProtection:"ביאָ-מעטריק פּראַטעקשאַן",
  Geo_FenceLock_OutProtection:"געאָ-פענס לאַק-אָוט שוץ",
  Multiple_Companies_Word:"אָנפירונג פון קייפל קאָמפּאַניעס איז ניט בנימצא אָן טשעקס פּרעמיע.",
  PayPal_Payouts_Word:"פּייַפּאַל פּיימאַנץ זענען פאַרקריפּלט אָן טשעקס פּרעמיע.",
  PINProtection:"PIN פּראַטעקשאַן",
  PasswordProtection:"שפּריכוואָרט פּראַטעקשאַן",
  May_Require_Approval:"קען דאַרפן האַסקאָמע.",
  Subscribe:"אַבאָנירן",
  Billed:"בילעד",
  Up:"אַרויף",
  Down:"אַראָפּ",
  Positioning:"פּאַזישאַנינג",
  Marker:"מאַרקער",
  Drag_Marker:"שלעפּן מאַרקער",
  Tagline:"דרוק טשעקס און טאַבולאַטע פּייראָול",
  Marker_Word:"ניצן די מאַרקערס צו גרייס דעם עלעמענט.",
  Pinch_Zoom:"קניפּ פארגרעסער",
  Pinch_Word:"קניפּ צו פארגרעסער דעם עלעמענט.",
  Drag:"שלעפּן",
  Drag_Word:"ניצן דיין פינגער צו שלעפּן עלעמענטן.",
  subscription_alias_word:"אַוטאָ רינוינג אַבאָנעמענט",
  premium_alias_word:"איין-צייט אַפּגרייד פּאַקקאַגע",
  print_alias_word:"דרוק-אויס יחיד טשעקס",
  mode_alias_word:"מאָדע",
  Pro:"פּראָ",
  Pro_word:"פּראָ ווערסיע פארלאנגט.",
  Cant_Delete_Default_Company_Word:"אנטשולדיגט, איר קענען נישט ויסמעקן דיין פעליקייַט פירמע.",
  Reinsert_Default_Designs:"שייַעך-ינסערט דיפאָלט דיזיינז",
  Reinsert_Default_Designs_word:"צי איר ווילן צו שייַעך-אַרייַנלייגן פעליקייַט דיזיינז?",
  Subscription_Active_Disable_Word:"דעם אַבאָנעמענט איז אַקטיוו. וואָלט איר ווי צו באָטל מאַכן דעם אַבאָנעמענט צו טשעקס?",
  Company_Logo:"פירמע לאָגאָ",
  ZERO_:"נול",
  Disclaimer:"אָפּלייקענונג",
  Privacy_Policy:"פּריוואַטקייט פּאָליטיק",
  EULA:"טשעקס EULA",
  Terms_Of_Service:"טערמינען פון דינסט",
  Terms_Of_Use:"טערמינען פון נוצן",
  Refunds:"צוריקצאָל פּאָליטיק",
  Single_Print:"1 קוק",
  Two_Prints:"2 טשעקס",
  Five_Prints:"5 טשעקס",
  Ten_Prints:"10 טשעקס",
  Fifteen_Prints:"15 טשעקס",
  Twenty_Prints:"20 טשעקס",
  Thirty_Prints:"30 טשעקס",
  Image_Added:"בילד צוגעגעבן",
  Image_Preview:"בילד פּריוויו",
  No_Image_Was_Selected:"קיין בילד איז אויסגעקליבן.",
  Cheques_Unlimited:"טשעקס ונלימיטעד",
  _Subscription:"אַבאָנעמענט",
  Subscription:"טשעקס - 1 חודש",
  Two_Month_Subscription:"טשעקס - 2 חדשים",
  Three_Month_Subscription:"טשעקס - 3 חדשים",
  Six_Month_Subscription:"טשעקס - 6 חדשים",
  Twelve_Month_Subscription:"טשעקס - 12 חדשים",
  Cheques_Are_Available:"טשעקס זענען בנימצא צו דרוקן.",
  Cheques_Thank_You:"דאַנקען דיר אַזוי פיל פֿאַר דיין קויפן פון טשעקס! דיין פּערטשאַסאַז זענען איצט אַקטיוו.",
  Upgrade_Required_Two:"סעלעקטירן אַ פּעקל און טאָפּל צאַפּן אויף די פּרייַז קנעפּל צו אָנהייבן דיין קויפן. דרוק פּראָפעסיאָנאַל קוקן פול-קאָליר טשעקס אין סעקונדעס.",
  Month:"חודש",
  Due:"רעכט:",
  Expires:"אויסגיין:",
  Subscription_Active:"אַבאָנעמענט אַקטיוו",
  Subscription_Inactive:"אַבאָנעמענט ינאַקטיוו",
  Purchase_Additional_Cheques:"קויפן נאָך טשעקס?",
  Printable_Cheque:"פּרינטאַבאַל טשעק",
  Printable_Cheques:"פּרינטאַבאַל טשעקס",
  Printable_Cheque_Word:"טשעק איז בנימצא אויף דיין חשבון.",
  Printable_Cheques_Word:"טשעקס זענען בנימצא אויף דיין חשבון.",
  Max_Amount_Message:"די סומע וואָס איר האָט ספּעסיפיעד איז ריטשט איבער די מאַקסימום סומע באַשטימט פֿאַר דעם סיסטעם:",
  Terms_Required_Word:"איר מוזן שטימען צו דעם העסקעם איידער איר פאָרזעצן צו נוצן טשעקס.",
  Subscriptions:"סאַבסקריפּשאַנז",
  Product_Upgrades:"ופּגראַדעס",
  Mailed_Out_Cheques:"פּאָסט-אויס טשעקס",
  Enter_A_Company_Name:"ביטע אַרייַן אַ פירמע נאָמען.",
  Single_Cheques:"איין טשעקס",
  Cheque_Golden:"גאָלדען טשעק",
  Cheque_Golden_Window:"גאָלדען טשעק פּלאַן.",
  Cheque_Green:"גרין טשעק",
  Cheque_Green_Window:"גרין טשעק פּלאַן.",
  Cheque_Red:"רויט טשעק",
  Cheque_Red_Window:"רויט טשעק פּלאַן.",
  Cheque_Yellow:"Yellow טשעק",
  Cheque_Yellow_Window:"געל טשעק פּלאַן.",
  Cheque_Statue_of_Liberty:"סטעטוע אוו ליבערטי",
  Cheque_Statue_of_Liberty_Window:"די סטאַטוע פון ליבערטי טשעק פּלאַן.",
  Cheque_Green_Wave:"גרין וואַווע",
  Cheque_Green_Wave_Window:"גרין טשעק פּלאַן.",
  Cheque_Golden_Weave:"גאָלדען וועאַוו",
  Cheque_Golden_Weave_Window:"עלעגאַנט גאָלדען טשעק פּלאַן.",
  Cheque_Green_Sun:"גרין זון",
  Cheque_Green_Sun_Window:"טיף און קאַמינג טשעק פּלאַן.",
  Cheque_Blue_Checkers:"בלוי טשעקקערס",
  Cheque_Blue_Checkers_Window:"בלוי טשעק פּלאַן.",
  Cashiers_Check:"קאַסירער ס טשעק",
  Cashiers_Check_Window:"קאַסירער ס טשעק נוסח מוסטער.",
  Cheque_Aqua_Checkers:"אַקוואַ טשעקקערס",
  Cheque_Aqua_Checkers_Window:"אַקוואַ טשעק פּלאַן.",
  Cheque_Golden_Checkers:"גאָלדען טשעקקערס",
  Cheque_Golden_Checkers_Window:"גאָלדען טשעק פּלאַן.",
  Upgrade_Unavailable:"ופּגראַדעס אַנאַוויילאַבאַל",
  Bank_Code_Protection:"באַנק נומער פּראַטעקשאַן",
  Bank_Code_Protection_Word:"באַשיצן אייער באַנק נומערן פון געוויינט אויף דעם אַפּ פליסנדיק אויף אן אנדער מיטל אָדער פֿאַר אן אנדער באַניצער. די אַקציע איז יריווערסאַבאַל. פאָרזעצן?",
  Bank_Code_Protection_Blocked_Word:"די באַנק נומערן איר זענט טריינג צו נוצן זענען רעזערווירט פֿאַר אן אנדער באַניצער אָדער מיטל.",
  Bank_Code_Protection_Error_Word:"נומער וועראַפאַקיישאַן איז ניט אַנדערש. ביטע פאַרבינדן צו די אינטערנעט און פּרובירן צו לייגן דעם באַנק חשבון ווידער.",
  Bank_Code_Protection_Set_Error_Word:"באַנק נומער שוץ ריקווייערז אַז איר זענט קאָננעקטעד צו די אינטערנעט. ביטע פאַרבינדן און פּרובירן ווידער.",
  Upgrade_Unavailable_Word:"אנטשולדיגט, מיר האָבן קאָנפליקט צו באַשטעטיקן איר. אַבאָנעמענט און אַפּגריידז צו טשעקס זענען דערווייַל אַנאַוויילאַבאַל פֿאַר קויפן אין דיין געגנט.",
  PayPal_Payment_Preview:"פּייַפּאַל צאָלונג פאָרויסיקע ווייַזונג",
  Apple_Pay:"עפּל פּייַ",
  Select_PayPal:"אויסקלייַבן PayPal",
  PayPal_Applications:"פּייַפּאַל אַפּפּליקאַטיאָנס",
  Purchase_With_Apple_Pay:"קויפן מיט Apple Pay",
  Google_Pay:"Google פּייַ",
  Companies:"קאָמפּאַניעס",
  Insurance:"פאַרזיכערונג",
  New_PayPal:"ניו PayPal",
  Pay_By:"באַצאָלן דורך",
  Insure:"פאַרזיכערן",
  Miles:"מייל",
  Payment_Method:"צאָלונג מעטאָד",
  Select_Policies:"אויסקלייַבן פּאַלאַסיז",
  Policies:"פּאַלאַסיז",
  Policy:"פּאָליטיק",
  No_PayPal_Account:"קיין PayPal חשבון",
  No_Policies:"קיין פאַרזיכערונג פּאָליטיק",
  New_Policy:"ניו פּאָליטיק",
  PayPal_Payment:"פּייַפּאַל צאָלונג",
  PayPal_Payments:"פּייַפּאַל פּייַמענץ",
  No_Payment_Selected:"קיין צאָלונג אויסגעקליבן",
  Sending_Payment_Word:"ביטע וואַרטן... די צאָלונג איז געשיקט.",
  Sending_Payments_Word:"ביטע וואַרטן ... פּיימאַנץ זענען געשיקט.",
  No_Payment_Selected_PayPal:"ניט קיין <a routerLink='/folder/Payments'>PayPal צאָלונג</a> אויסגעקליבן צו שיקן.",
  Payment_Sent:"צאָלונג געשיקט",
  PayPal_Payment_Sent:"די צאָלונג איז געשיקט מיט PayPal.",
  Copay:"Copay",
  Dead:"טויט",
  Alive:"לעבעדיק",
  Not_Dead:"ניט טויט",
  Unclaimed:"אַנקליימד",
  Attempted:"געפרואווט",
  Deceased:"פאַרשטאָרבן",
  Claimed:"קליימד",
  Unpaid:"ניט באַצאָלט",
  Sending_Payment:"שיקט צאָלונג",
  Sending_Payments:"שיקט פּיימאַנץ",
  Send_PayPal_Confirmation:"צי איר ווילן צו שיקן דעם טראַנסאַקטיאָן מיט PayPal?",
  Send_PayPal_Confirmation_Word:"דריקן די גרין קנעפּל צו שיקן דעם טראַנסאַקטיאָן.",
  Save_Payment_As_Unpaid:"היט דעם צאָלונג ווי ניט באַצאָלט?",
  Payment_Pay_Confirmation_PayPal:"היט דעם צאָלונג ווי באַצאָלט?",
  No_Policies_Word:"לייג איצט די ערשטער <a routerLink='/folder/Postage/New'>ינסוראַנסע פּאָליטיק</a>.",
  Timesheet_Multiple_Delete_Confirmation:"זענט איר זיכער אַז איר ווילן צו ויסמעקן קייפל טימעשעעץ?",
  Select_Multiple_Timesheets_Prompt:"קיין צייטשעיץ אויסגעקליבן. אויסקלייַבן בייַ מינדסטער איין טימעשעעט.",
  Select_Multiple_Policies_Prompt:"קיין פּאַלאַסיז אויסגעקליבן. אויסקלייַבן בייַ מינדסטער איין פאַרזיכערונג פּאָליטיק.",
  Policies_Multiple_Delete_Confirmation:"זענט איר זיכער אַז איר ווילן צו ויסמעקן קייפל פּאַלאַסיז?",
  Company:"פירמע",
  Add_Company:"לייג פֿירמע",
  New_Company:"לייג פֿירמע",
  No_Companies:"קיין קאָמפּאַניעס",
  Enable_Company:"געבן פֿירמע",
  Select_Company:"אויסקלייַבן פֿירמע",
  The_Default_Company:"די פעליקייַט פירמע פירמע.",
  Manage_Companies:"פירן קאָמפּאַניעס",
  No_Companies_Word:"טשעקס וועט נוצן אַ פעליקייַט פירמע.<br />לייג די <a routerLink='/folder/Company/New'>ערשטער פירמע</a>.",
  Default_Company:"פעליקייַט פֿירמע",
  Cheques_Unlimited_Word:"טשעקס ונלימיטעד אַלאַוז איר צו דרוקן ווי פילע טשעקס ווי איר ווילט.",
  Cheques_Subscription_Word:"א טשעקס אַבאָנעמענט אַלאַוז איר צו דרוקן ווי פילע טשעקס ווי איר ווילט.",
  You_Own_This_Product:"איר פאַרמאָגן דעם פּראָדוקט.",
  Your_Subscription_is_Active:"דיין אַבאָנעמענט איז אַקטיוו.",
  Active_Products:"אַקטיווייטיד פּראָדוקטן",
  Purchase_Confirmation:"קויפן",
  Purchase_Cheques:"קויפן טשעקס",
  Restore_Purchase:"ומקערן פּערטשאַסאַז",
  Erase_Purchase:"מעקן פּערטשאַסאַז",
  Successfully_Purchased:"הצלחה פּערטשאַסט",
  Enter_Your_Licence_Key:"ביטע אַרייַן דיין דערלויבעניש שליסל אויף דעם בלאַט צו אַקטאַווייט דעם פּראָדוקט.",
  Licence_Key:"ליסענסע שליסל",
  Enter_Licence_Key:"אַרייַן ליסענסע שליסל",
  Purchased:"געקויפט",
  Enable_Feature:"געבן שטריך",
  Cancel_Subscription:"באָטל מאַכן אַבאָנעמענט",
  Subscription_Removed:"אַבאָנעמענט אַוועקגענומען",
  Select_Active_Subscription:"סעלעקטירן אַן אַקטיוו אַבאָנעמענט צו מאָדיפיצירן עס.",
  Remove_Subscription_Word:"זענט איר זיכער איר ווילן צו באָטל מאַכן דעם אַבאָנעמענט?",
  Delete_Company_Confirmation:"זענט איר זיכער אז איר ווילט אויסמעקן די גאנצע פירמע? ווארענונג: איר וועט פאַרלירן אַלע סטאָרד אינפֿאָרמאַציע!",
  Delete_Default_Company_Word:"איר קענען נישט ויסמעקן די פעליקייַט פירמע. וואָלט איר ווי צו באַשטעטיק די אַפּלאַקיישאַן און ומקערן עס צו די פעליקייַט שטאַט? ווארענונג: איר וועט פאַרלירן אַלע סטאָרד אינפֿאָרמאַציע!",
  Console_Warning_2:"דו זאלסט נישט שעפּן קיין קראַנטקייַט ניצן דעם אַפּלאַקיישאַן וואָס איז איצט נישט דייַן.",
  Terms_and_Conditions:"טערמינען און באדינגונגען",
  and_the:"און די",
  for:"פֿאַר",
  Please_Read_Word:"ביטע לייענען און שטימען צו די",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"עטלעכע קראַנטקייַט קאַנווערזשאַן רייץ קען ניט זיין געפֿונען. ביטע פאַרבינדן צו די אינטערנעט.",
  Free:"פרייַ",
  DB_Erase_Prompt_2:"גאָר מעקן די גאנצע דעוועלאָפּער דאַטאַבייס? ווארענונג: איר וועט פאַרלירן אַלע אינפֿאָרמאַציע וועגן קויפן און אַבאָנעמענט!",
  Successfully_Imported:"הצלחה ימפּאָרטיד",
  Select_Postage:"אויסקלייַבן פּאָסטגעלט",
  No_Postage:"קיין פּאָסטאַגע סטאַמפּס",
  No_Paid_Postage_Word:"לייג די ערשטער <a routerLink='/folder/Postage/New'>באַצאָלט פּאָסטאַגע</a> שטעמפּל.",
  Trial_Complete:"פּראָצעס גאַנץ",
  Please_Upgrade:"ביטע אַפּגרייד טשעקס צו פאָרזעצן דרוקן.",
  Aa:"אַאַ",
  Color:"פאַרב",
  Font:"פאָנט",
  Guide:"גייד",
  Guides:"גוידעס",
  Image:"בילד",
  Zoom:"פארגרעסער",
  Logo:"לאָגאָ",
  Bank:"באַנק",
  MICR:"MICR",
  Total:"גאַנץ",
  Cancel:"באָטל מאַכן",
  Confirm:"באַשטעטיקן",
  Method:"מעטאָד",
  Biometric_Security:"ביאָ-מעטריק זיכערהייט",
  Please_Login_To_Continue:"ביטע קלאָץ אין צו פאָרזעצן.",
  Complete:"גאַנץ",
  Sign_Up:"צייכן אַרויף",
  Error:"טעות",
  Biometrics:"ביאָ-מעטריק",
  Percent:"פּראָצענט",
  Zero_Percent:"0%",
  Top_Margin:"שפּיץ גרענעץ",
  Bottom_Margin:"דנאָ גרענעץ",
  Left_Margin:"לינקס גרענעץ",
  Right_Margin:"רעכט גרענעץ",
  MICR_Margin:"MICR גרענעץ",
  Table_Margin:"טיש גרענעץ",
  Address_Margin:"אַדרעס גרענעץ",
  Percentage_:"פּראָצענט",
  Vacation_Title:"וואַקאַציע טיטל",
  Use_PIN:"ניצן PIN",
  Loading__:"לאָדן...",
  Design_Title:"פּלאַן טיטל",
  Authorize:"דערלויבן",
  Key:"שליסל",
  Public_Key:"ציבור שליסל",
  Private_Key:"פּריוואַט שליסל",
  Authenticate:"אָטענטאַקירן",
  Last_Payroll:"לעצטע פּייַראָלל",
  Last_Calculation:"לעצטע קאַלקולאַטיאָן",
  Authorized:"אָטערייזד",
  Geo_Authorized:"געאָ-אָרט: אָטערייזד",
  Not_Authorized:"ניט אָטערייזד",
  Authorization_Complete:"דערלויבעניש גאַנץ",
  Geolocation_Authorization:"געאָ-אָרט דערלויבעניש",
  Out_of_Bounds:"אויס פון באַונדס",
  Cant_Delete_Default_Design:"קענען ניט ויסמעקן אַ פעליקייַט פּלאַן.",
  Unauthorized_Out_of_Bounds:"אַנאָטערייזד: אויס פון באַונדס",
  Biometric_Authorization:"ביאָ-מעטריק דערלויבעניש",
  Locating_Please_Wait:"לאָוקייטינג, ביטע וואַרטן ...",
  Test_Biometrics:"פּרובירן ביאָ-מעטריק",
  Cheque_Margins:"קוק מאַרדזשאַנז",
  Percentage_Full_Error:"דער פּראָצענט פעלד קענען ניט זיין באַשטימט איבער 100% פּראָצענט.",
  No_Payroll_Text:"לייג אַ <a routerLink='/folder/Employee/New'>עמפּלוייז</a> אָדער <a routerLink='/folder/Payee/New'>פּייַעע</a> און אַ <a routerLink='/folder/Schedule /New'>פּלאַן</a>.",
  Design_Saved:"פּלאַן סאַוועד",
  Default_Design_Selected:"פעליקייַט פּלאַן אויסגעקליבן",
  Partial_Import:"פּאַרטיייש ימפּאָרט",
  Partial_Export:"טייל עקספּאָרט",
  Entire_Import:"גאַנץ ימפּאָרט",
  Entire_Export:"גאַנץ עקספּאָרט",
  Existing_Password:"ביטע אַרייַן דיין יגזיסטינג פּאַראָל:",
  Existing_PIN:"ביטע אַרייַן דיין יגזיסטינג PIN קאָד:",
  Pin_Change_Header:"PIN באַשטעטיקונג",
  Pin_Change_SubHeader:"אַרייַן דיין אַלט PIN נומער צו באַשטעטיקן די ענדערונג.",
  Pass_Change_Header:"שפּריכוואָרט באַשטעטיקונג",
  Pass_Change_SubHeader:"אַרייַן דיין אַלט פּאַראָל צו באַשטעטיקן די ענדערונג.",
  PIN_Enter_Message:"אַרייַן דיין PIN צו באַשטעטיקן.",
  Password_Enter_Message:"אַרייַן דיין פּאַראָל צו באַשטעטיקן.",
  Pin_Updated_Success:"PIN דערהייַנטיקט הצלחה!",
  Pin_Updated_Fail:"PIN קען נישט זיין דערהייַנטיקט.",
  Pass_Updated_Success:"שפּריכוואָרט דערהייַנטיקט הצלחה.",
  Pass_Updated_Fail:"שפּריכוואָרט קען ניט זיין דערהייַנטיקט.",
  Preview_Payment:"פאָרויסיקע ווייַזונג צאָלונג",
  Preview_Payroll:"פאָרויסיקע ווייַזונג פּייראָול",
  No_Payments_Created:"קיין פּיימאַנץ זענען געפונען צו זיין באשאפן.",
  Payment_Preview:"צאָלונג פּריוויו",
  Enable_Payee:"געבן פּייַעעע",
  Rendered:"רענדערד",
  No_Email:"קיין אימעיל",
  Setup_Cheques:"סעטאַפּ טשעקס",
  name:"נאָמען",
  address:"אַדרעס",
  address_2:"אַדרעס שורה 2",
  city:"שטאָט",
  province:"פּראָווינץ",
  postal_code:"פּאָסט / זיפּ קאָוד",
  country:"לאַנד",
  username:"נאמען",
  full_name:"גאנצע נאמען",
  birthday:"דיין געבורסטאָג",
  email:"בליצפּאָסט",
  phone:"טעלעפאָן",
  employees:"עמפּלוייז",
  addresses:"ווענדט זיך",
  payment_amount_limit:"צאָלונג סכום לימיט",
  total_limit:"גאַנץ לימיט",
  payees:"פּייַעעס",
  description:"באַשרייַבונג",
  address_line_one:"אַדרעס שורה איין",
  address_line_two:"אַדרעס שורה צוויי",
  image:"בילד",
  account_holder:"חשבון האָלדער",
  cheque_starting_id:"קוק סטאַרטינג שייַן",
  transit_number:"דורכפאָר נומער",
  institution_number:"ינסטיטוטיאָן נומער",
  designation_number:"באַצייכענונג נומער",
  account_number:"חשבון נומער",
  currency:"קראַנטקייַט",
  signature:"כסימע",
  payment_payroll_limit:"צאָלונג לימיט",
  total_limi:"גאַנץ שיעור",
  date:"טאָג",
  printed_memo:"געדרוקט מעמאָ",
  banks:"באַנקס",
  signature_image:"כסימע בילד",
  address_name:"אַדרעס נאָמען",
  notes:"הערות",
  design:"פּלאַן",
  title:"טיטל",
  frequency:"אָפטקייַט",
  fax:"פאַקס",
  salaries:"סאַלעריז",
  salary_ids:"געצאָלט IDs",
  start_time:"אָנהייב צייט",
  end_time:"סוף צייט",
  paid:"באַצאָלט",
  overtime_percentage:"באַצאָלט פּראָצענט",
  overtime_amount:"באַצאָלט פאַרפעסטיקט סומע",
  first_name:"ערשטע נאמען",
  last_name:"לעצטע נאמען",
  moderation:"מאַדעריישאַן",
  create:"שאַפֿן",
  edit:"רעדאַגירן",
  destroy:"צעשטערן",
  day_start_time:"טאָג_סטאַרט_טיים",
  day_end_time:"טאָג_ענד_צייט",
  fullname:"נאָמען",
  time:"צייַט",
  auto_send:"אויטאָמאַטיש שיקן",
  time_method:"צייט מעטאַד",
  schedules:"באשטימטע צייטן",
  indefinite_payroll_enabled:"געבן ינדעפאַנאַטלי",
  amount:"סומע",
  repeat:"איבערחזרן",
  always_enabled:"שטענדיק ענייבאַלד",
  start_date:"אנהייב דאטום",
  end_date:"סוף טאָג",
  Cheque_Total:"קוק גאַנץ",
  Total_Amount:"גאנצע סומע:",
  Amounts:"סומע:",
  Images:"בילדער",
  Files:"טעקעס",
  Previewing:"פאָרויסיקע ווייַזונג:",
  Insert:"אַרייַנלייגן",
  Preview_Import:"פאָרויסיקע ווייַזונג ימפּאָרט",
  Entry:"פּאָזיציע",
  Entries:"איינסן",
  No_Entries:"קיין איינסן",
  Import_Type:"אַרייַנפיר טיפּ",
  Select_Details:"אויסקלייַבן דעטאַילס",
  Select_Payee:"אויסקלייַבן פּייער",
  Enable_Holidays:"געבן האָלידייַס",
  Disable_Holidays:"דיסייבאַל האָלידייַס",
  Wire_Transfer:"ווירע אַריבערפירן",
  New_Export:"ניו עקספּאָרט",
  Export_Data:"אַרויספירן דאַטאַ",
  Export_Data_Word:"סעלעקטירן דעם טיפּ פון טעקע צו אַרויספירן און אראפקאפיע.",
  Export_File:"עקספּאָרט טעקע",
  Mode:"מאָדע",
  Times:"Times",
  Widgets:"ווידזשיץ",
  Slider:"סליידער",
  Set_Details:"שטעלן דעטאַילס",
  Select_Type:"אויסקלייַבן טיפּ",
  Display_Slider:"ווייַז סליידער",
  Dashboard_Slider:"דאַשבאָרד סליידער",
  Dashboard_Mode:"דאַשבאָרד מאָדע",
  Show_Intro:"ווייַזן ינטראָ",
  Show_Payrolls:"ווייַזן פּייראָולז",
  Show_Holidays:"ווייַזן האָלידייַס",
  Show_Invoices:"ווייַזן ינווויסיז",
  Show_Cheques:"ווייַזן טשעקס",
  Enabled_Widgets:"ענאַבלעד ווידזשיץ",
  Disabled_Widgets:"פאַרקריפּלט ווידזשיץ",
  Colors:"קאָלאָרס",
  Barcodes:"באַרקאָדעס",
  View_Timers:"View טיימערז",
  Gradients:"גראַדיענץ",
  No_Info:"קיין אינפֿאָרמאַציע",
  Disable:"דיסייבאַל",
  Show_Layer:"ווייַזן לייַערס",
  Hide_Layer:"באַהאַלטן לייַערס",
  Text_Layer:"טעקסט לייַערס",
  Secondly:"צווייטנס",
  Minutely:"מינוט",
  nine_am:"9:00 צופרי",
  five_pm:"5:00 PM",
  Enable_Address:"געבן אַדרעס",
  Invalid_File_Type:"אנטשולדיגט, אַן אומגילטיק טעקע טיפּ איז אויסגעקליבן. שטיצט טעקע טיפּ:",
  Error_Updating_Entry:"אנטשולדיגט, עס איז געווען אַ טעות אַפּדייט דעם פּאָזיציע.",
  Schedule_Timing_Alert:"טעות: דער פּלאַן סטאַרטינג צייט איז באַשטימט צו אַ ווערט נאָך די סאָף צייט.",
  Select_Multiple_Payments_Prompt:"קיין פּיימאַנץ אויסגעקליבן. אויסקלייַבן בייַ מינדסטער איין צאָלונג.",
  Select_Multiple_Cheques_Prompt:"קיין טשעק אויסגעקליבן. ביטע אויסקלייַבן בייַ מינדסטער איין טשעק.",
  Select_Multiple_Items_Prompt:"קיין זאכן אויסגעקליבן. ביטע אויסקלייַבן בייַ מינדסטער איין נומער.",
  Paymemt_Multiple_Delete_Confirmation:"זענט איר זיכער אַז איר ווילן צו ויסמעקן קייפל פּיימאַנץ?",
  Cheque_Multiple_Delete_Confirmation:"זענט איר זיכער אַז איר ווילן צו ויסמעקן קייפל טשעקס?",
  Payee_Multiple_Delete_Confirmation:"זענט איר זיכער אַז איר ווילן צו ויסמעקן קייפל פּיידיז?",
  Employee_Multiple_Delete_Confirmation:"זענט איר זיכער אַז איר ווילן צו ויסמעקן קייפל עמפּלוייז?",
  MICR_Warning:"באַמערקונג: עטלעכע פּרינטערס און דעוויסעס קען נישט ווייַזן די MICR שריפֿט ריכטיק.",
  Automatically_Send:"אויטאָמאַטיש שיקן",
  Type:"טיפּ",
  Payment_Type:"צאָלונג טיפּ",
  Auto_Send:"אַוטאָ שיקן",
  Automatically_Process:"אויטאָמאַטיש פּראָצעס",
  Auto_Process:"אַוטאָ פּראָצעס",
  Image_Based:"בילד-באזירט",
  Font_Based:"פאָנט-באזירט",
  Rerender:"שייַעך-רעדן",
  Rendering:"רענדערינג",
  Render:"טעקעס",
  Top:"אויבן",
  Middle:"מיטל",
  Bottom:"דנאָ",
  Top_Left:"שפּיץ לינקס",
  Top_Center:"שפּיץ צענטער",
  Top_Right:"Top Right",
  Middle_Left:"מיטל לינקס",
  Middle_Center:"מיטל צענטער",
  Middle_Right:"מיטל רעכט",
  Bottom_Left:"דנאָ לינקס",
  Bottom_Center:"דנאָ צענטער",
  Bottom_Right:"דנאָ רעכט",
  Numbers:"נומערן",
  Verified:"וועראַפייד",
  Paper_Size:"פּאַפּיר גרייס",
  New_Device:"ניו דיווייס",
  Add_Device:"לייג דיווייס",
  Remove_Device:"אַראָפּנעמען דיווייס",
  Delete_Device:"ויסמעקן דיווייס",
  Block_Device:"פאַרשפּאַרן דיווייס",
  Block:"פאַרשפּאַרן",
  Unblock:"ופשליסן",
  Table:"טיש",
  Scan_Login_Code:"יבערקוקן לאָגין קאָד",
  Login_Code:"לאָגין קאָד",
  Scan_Code:"יבערקוקן קאָד",
  Scan_QR_Code:"יבערקוקן QR קאָד",
  Select_All:"וועל אויס אלע",
  Deselect_All:"ויסמעקן אַלע",
  Increase:"העכערונג",
  Decrease:"פאַרמינערן",
  Bold:"דרייסט",
  Text:"טעקסט",
  Style:"סטייל",
  Italic:"ייטאַליק",
  QR_Code:"QR קאָד",
  Barcode:"באַרקאָדע",
  Browse_Images:"בלעטער בילדער",
  Browse_Files:"בלעטער פילעס",
  Background_Image:"הינטערגרונט בילד",
  Logo_Image:"לאָגאָ בילד",
  Header_Image:"כעדער בילד",
  Bank_Image:"באַנק בילד",
  Cut_Lines:"שנייַדן לינעס",
  Background:"הינטערגרונט",
  License:"ליסענסע",
  One_License:"1 ליסענסע:",
  Licensed:"ליסענסעד צו:",
  Not_Licensed:"ניט ליסענסעד",
  Enter_Serial:"אַרייַן סיריאַל",
  Serial_Key:"סיריאַל שליסל",
  Serial:"סיריאַל",
  Product_Key:"פּראָדוקט שליסל",
  Check_Product_Key:"באַשטעטיקן פּראָדוקט שליסל",
  Add_Product_Key:"אַרייַן פּראָדוקט שליסל",
  Verifying_Purchase:"וועראַפייינג קויפן ...",
  Print_Envelope:"דרוק קאָנווערט",
  Envelope:"קאָנווערט",
  Thank_You:"אדאנק!",
  Scale:"וואָג",
  Print_Scale:"דרוק סקאַלע",
  Borders:"געמארקן",
  VOID:"VOID",
  Void_Cheque:"פּאָסל טשעק",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"באַצאָלן צו די סדר פון:",
  NO_DOLLARS:"קיין דאָללאַרס",
  ONE_DOLLAR:"איין דאָללאַר",
  DOLLARS:"דאָללאַרס",
  AND:"און",
  CENTS:"CENTS.",
  NO_:"ניין",
  ONE_:"איינער",
  AND_NO_:"און ניין",
  _AND_ONE_:"און איינער",
  DOLLARS_AND_ONE_CENT:"און איין סענט.",
  AND_NO_CENTS:"און נול סענט.",
  CHEQUE_PRINT_DATE:"דאטום:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_SIGNATUREMP:"מפּ",
  Initial_Tasks:"ערשט טאַסקס",
  Inital_Setup:"ערשט סעטאַפּ",
  Welcome_To:"ברוכים הבאים צו",
  Welcome:"ברוכים הבאים",
  Swipe:"סוויפּ",
  Intro_Setup:"ינטראָ סעטאַפּ",
  Introduction:"הקדמה",
  CHEQUE_PRINT_CREDIT:"פּאַוערד דורך טשעקס",
  Title_Intro_Word:"ברוכים הבאים צו טשעקס",
  Title_Intro:"טשעקס הקדמה",
  Title_Setup:"טשעקס סעטאַפּ",
  PayPal_Default_Email_Message:"איר האָט באקומען אַ נייַע PayPal אַריבערפירן.",
  Cheques_App_Export:"עקספּאָרטירט דורך טשעקס",
  No_Prints_Purchased:"קיין פּרינץ פּערטשאַסט",
  No_Prints_Purchased_Word:"קיין נאָך פּרינץ זענען בנימצא. ביטע קויפן נאָך טשעקס.",
  Post_Intro_Thanks:"דאנק איר פֿאַר טשוזינג טשעקס. דער סעטאַפּ פּראָצעס איז איצט גאַנץ.",
  Post_Intro_Word:"באַקומען סטאַרטעד דורך דרוקן דיין ערשטער טשעק, לייגן אַ צוקונפֿט צאָלונג אָדער לייגן אַן עמפּלוייז צו די פּייראָול.",
  Upgrade_Required:"טשעקס ריקווייערז איר צו פאַרמאָגן אַ מער פּרעמיע ווערסיע פון די ווייכווארג צו באַהאַלטן דעם אָנזאָג און ופשליסן נאָך פֿעיִקייטן.",
  Upgrade_Title:"טשעקס",
  Mailout_Prompt:"איר קען אַדישנאַלי קלייַבן צו האָבן טשעקס פּאָסט-אויס דיין פּייראָול טשעקס פֿאַר איר.",
  Mailed_Cheque:"פּאָסט טשעק",
  Mailed_Cheque_Color:"פּאָסט טשעק (קאָליר)",
  Terms_Purchase:"כל עלעקטראָניש פּערטשאַסאַז מיט טשעקס זענען גאָר ריפאַנדאַבאַל פֿאַר אַרויף צו 30 טעג פֿון די קויפן טאָג. ביטע לייענען און שטימען צו די",
  Dashboard_Setup:"סעטאַפּ די ערשטיק פּרינטער",
  Dashboard_Add:"לייג די ערשטיק באַנק אקאונט",
  Dashboard_Customize:"אויסקלייַבן אַ טשעק מוסטער",
  Dashboard_Job_Salary:"שאַפֿן דיין אַרבעט און לייגן דיין געצאָלט",
  Dashboard_Employees:"שפּור עמפּלוייז & פּייַעעס",
  Dashboard_Print:"דרוק & פּאָסט דיין פּייַטשעקווע",
  Dashboard_Payroll:"אָטאַמייט דיין פּייראָול פּרינטינג",
  Dashboard_PayPal:"סעטאַפּ PayPal פּייַראָלל / פּייאַוץ",
  Begin_Setup:"אָנהייבן סעטאַפּ",
  Get_Started:"הייב אהן",
  Purchase:"קויפן",
  Lockdown:"פארשלאסענקייט",
  Unlock:"ופשליסן",
  Detailed:"דעטאַילעד",
  Log_In:"צייכן אריין",
  Login:"צייכן אריין",
  Launch:"קאַטער",
  Register:"רעגיסטרירן",
  Finish:"ענדיקן",
  List:"רשימה",
  Weekends:"וויקענדז",
  Weekly:"וואכנשריפט",
  PayPal_Default_Subject:"ניו צאָלונג",
  Payment_Message:"צאָלונג מעסעדזש",
  PayPal_Default_Payment_Note:"אדאנק",
  Setup_Your_Cheqing_Account:"טשעק חשבון",
  Add_Your_Primary_Cheqing_Account:"לייג דיין ערשטיק טשעק חשבון.",
  Welcome_Word:"פאַרפּאָשעטערן און אָטאַמייט פּייראָול און מענטש ריסאָרס פאַרוואַלטונג.",
  Get_Started_Quickly:"נאָר ענטפֿערן אַ ביסל פּשוט פֿראגן וואָס וועט העלפֿן אונדז אָנהייבן ...",
  Done_Intro_Word:"סעטאַפּ גאַנץ",
  PIN_Protected:"שפּריכוואָרט & PIN פּראָטעקטעד",
  Enter_New_PIN:"אַרייַן אַ נייַע PIN קאָד:",
  Enter_PIN:"אַרייַן די PIN קאָד:",
  Invalid_PIN:"אומגילטיק PIN איז אריין.",
  Account_Identifier:"אַקאַונט ידענטיפיער",
  New_Account_Identifier:"ניו אַקאַונט ידענטיפיער",
  attempt:"פּרווון",
  attempts:"פרווון",
  remaining:"בלייַבן",
  Language:"שפּראַך",
  languages:"שפּראַכן",
  select_languages:"אויסקלייַבן שפּראַך",
  Deposit:"אַוועקלייגן",
  Hire_One_Now:"דינגען איינער איצט",
  App_Locked:"די אַפּלאַקיישאַן איז פארשפארט.",
  Skip_:"האָפּקען",
  Skip_to_Dashboard:"גיין צו די דאַשבאָרד",
  Dashboard:"דאַשבאָרד",
  Import:"אַרייַנפיר",
  Admin:"אַדמיניסטראַטאָרס",
  New_Admin:"ניו אַדמין",
  Settings:"סעטטינגס",
  Color_Picker:"קאָליר פּיקער",
  Font_Picker:"פאָנט פּיקער",
  Logout:"Logout",
  Close:"נאָענט",
  Close_menu:"נאָענט",
  Excel:"עקססעל טעקע",
  Csv:"CSV טעקע",
  Sql:"SQL טעקע",
  Json:"דזשסאָן טעקע",
  Url:"אַרייַנפיר דורך URL",
  Back:"צוריק",
  Timers:"טיימערס",
  Cheque:"טשעק",
  Print:"דרוק",
  Designs:"דיזיינז",
  Pause_Printing:"פּויזע פּרינטינג",
  Resume_Printing:"נעמענ זיכ ווידער פּרינטינג",
  Printing_Paused:"פּרינטינג פּאַוזד",
  PrintingUnavailable:"אנטשולדיגט! דרוק איז ניט בנימצא אויף דעם סיסטעם.",
  Prints_Paused:"פּרינץ פּאַוזד",
  Administration:"אַדמיניסטראַציע",
  Loading:"Loading",
  Unnamed:"ניטאמאל",
  error:"אנטשולדיגט, דעם טשעק קען נישט זיין געעפנט פֿאַר וויוינג.",
  No_Cheques_To_Print:"קיין טשעקס צו דרוקן",
  No_Cheques_To_Print_Word:"שאַפֿן אַ <a routerLink='/folder/Cheque/New'>ניו טשעק</a>.",
  New_Cheque:"ניו טשעק",
  Start_One_Now:"אָנהייב איין איצט",
  Edit_Cheque:"רעדאַגירן טשעק",
  Select_Cheques:"אויסקלייַבן טשעקס",
  Select_Employee:"אויסקלייַבן אָנגעשטעלטער",
  Default_Printer:"פעליקייַט פּרינטער",
  Reassign:"איבערזעצן",
  Configure:"קאָנפיגורירן",
  Template:"מוסטער",
  Designer:"דיזיינער",
  Edit_Designs:"רעדאַגירן דיזיינז",
  New_Design:"ניו דיזיין",
  Next:"ווייַטער",
  Save:"היט",
  Name:"נאָמען",
  Mail:"פּאָסט",
  Amount:"סומע",
  Date:"טאָג",
  PayPal:"PayPal",
  Payouts:"פּייאַוץ",
  PayPal_Label:"PayPal פירמע",
  Email_Username:"בליצפּאָסט / נאמען",
  Client_ID:"קליענט שייַן",
  Sandbox_Email:"Sandbox Email",
  PayPal_Email:"PayPal Email",
  PayPal_Username:"API נאמען",
  PayPal_Payouts:"PayPal פּייאַוט",
  Select_PayPal_Key:"אויסקלייַבן PayPal Key",
  Secret:"סוד",
  API_Secret:"אַפּי סוד",
  PayPal_API_Keys:"פּייַפּאַל קיז",
  New_PayPal_Key:"ניו PayPal שליסל",
  Email_Subject:"Email טעמע",
  Email_Message:"Email אָנזאָג",
  Payout_Options:"פּייאַוט אָפּציעס",
  Payment_Note:"צאָלונג באַמערקונג",
  Enable_Employee:"געבן אָנגעשטעלטער",
  Enable_Production_Mode:"געבן פּראָדוקציע מאָדע",
  Sandbox_Username:"סאַנדבאָקס נאמען",
  Sandbox_Signature:"סאַנדבאָקס סיגנאַטורע",
  Sandbox_Password:"זאַמדקאַסטן שפּריכוואָרט",
  Production_Username:"פּראָדוקציע נאמען",
  Production_Signature:"פּראָדוקציע סיגנאַטורע",
  Production_Password:"פּראָדוקציע שפּריכוואָרט",
  Production_Email:"פּראָדוקציע בליצפּאָסט",
  API_Client_ID:"API קליענט שייַן",
  API_Signature:"אַפּי סיגנאַטורע",
  API_Password:"אַפּי שפּריכוואָרט",
  API_Username:"API נאמען",
  Secret_Key:"סוד שליסל",
  Sandbox:"זאַמדקאַסטן",
  Production:"פּראָדוקציע",
  Sandbox_Keys:"זאַמדקאַסטן קיז",
  Production_Keys:"פּראָדוקציע קיז",
  API_Title:"API טיטל",
  Production_Mode:"פּראָדוקציע מאָדע",
  Account_Label:"חשבון לאַבעל",
  No_PayPal_Setup:"קיין PayPal שליסל",
  Enable_PayPal_Account:"געבן PayPal אַקאַונט",
  No_PayPal_Word:"לייג דיין <a routerLink='/folder/Invoice/New'>PayPal שליסל אַפּי</a>.",
  Printed_Memo:"געדרוקט מעמאָ",
  Employee:"אָנגעשטעלטער",
  View_Employee:"View אָנגעשטעלטער",
  Mailing_Address:"מיילינג אַדרעס",
  Company_Address:"פירמע אַדרעס",
  Select_Company_Address:"אויסקלייַבן פירמע אַדרעס",
  Address:"אַדרעס",
  Any_Day:"קיין טאָג",
  Address_Name:"אַדרעס נאָמען",
  Unit:"אַפּאַראַט",
  Account:"חשבון",
  Bank_Account:"באַנק אַקאַונט",
  Account_Limits:"געבן אַקאַונט לימאַץ",
  Payroll:"פּייראָול",
  Run:"לויפן",
  Run_Payroll:"לויפן פּייראָול",
  New_Payroll:"ניו פּייראָול",
  No_Payroll:"קיין אַפּקאַמינג פּייראָול",
  Upcoming_Holiday:"קומענדיקע יום טוּב:",
  Invoice_Due:"אינוואָועס פעליק:",
  New_Invoice:"ניו ינווויס",
  No_Invoices:"קיין ינווויסיז",
  No_Invoices_Word:"שאַפֿן די ערשטער <a routerLink='/folder/Invoice/New'>ינוואָיסע</a>.",
  Cheque_Due:"טשעק רעכט:",
  Payrolls:"פּייראָולז",
  Sandbox_Mode:"זאַמדקאַסטן מאָדע",
  Hire:"הירע",
  Pay:"באַצאָלן",
  New:"ניו",
  Add:"לייג צו",
  Make:"מאַכן",
  Time:"צייַט",
  Write:"שרייב",
  Holiday:"יום טוּב",
  Holidays:"האָלידייַס",
  Next_Holiday:"ווייַטער יום טוּב:",
  All_Done:"אלעס געטון!",
  Employees:"עמפּלוייז",
  Payees:"פּייַעעס",
  Job:"אַרבעט",
  Jobs:"דזשאָבס",
  Invoice:"ינווויס",
  Invoices:"ינווויסיז",
  Vacations:"וואַקאַציעס",
  Cheques:"טשעקס",
  Brand_Cheques:"דיין סאָרט",
  Payment:"צאָלונג",
  Enable_Payment:"געבן צאָלונג",
  Payments:"פּיימאַנץ",
  Schedule:"פּלאַן",
  Schedules:"באשטימטע צייטן",
  Timesheet:"צייט בלאַט",
  Timesheets:"צייט שיץ",
  Salary:"געצאָלט",
  New_Address:"ניו אַדרעס",
  Address_2:"אַדרעס (ליניע 2)",
  _City:"שטאָט",
  _State:"שטאַט / פּראָוו",
  City:"שטאָט / טאַונשיפּ",
  State:"שטאַט / פּראַווינס",
  Postal:"פּאָסטאַל / זיפ קאָוד",
  ZIP:"פּאָסט / פאַרשלעסלען",
  Country:"לאַנד",
  Addresses:"ווענדט זיך",
  Required_Options:"פארלאנגט אָפּציעס",
  Optional_Options:"אָפּטיאָנאַל אָפּציעס",
  Additional_Options:"נאָך אָפּציעס",
  Required:"פארלאנגט",
  Optional:"אָפּטיאָנאַל",
  Additional:"נאָך",
  No_Addresses:"קיין אַדרעסעס",
  New_Address_Word:"לייג די ערשטער <a routerLink='/folder/Address/New'>אַדרעס</a>.",
  Select_Address:"אויסקלייַבן אַדרעס",
  No_Address:"קיין אַדרעסעס",
  Print_Cheque:"דרוק טשעק",
  Print_Cheques:"דרוק טשעקס",
  Print_Cheque_Now:"דרוק טשעק איצט",
  Description:"באַשרייַבונג",
  Pay_To_The_Order_Of:"באַצאָלן צו די סדר פון:",
  Select_Date_Range:"אויסקלייַבן טאָג קייט",
  Select_Starting_Date:"אויסקלייַבן סטאַרטינג טאָג",
  Select_Ending_Date:"אויסקלייַבן סאָף טאָג",
  Select_Date:"אויסקלייַבן טאָג",
  Cheque_Date:"קוק טאָג",
  Cheque_Memo:"קוק מעמאָו",
  Blank_Cheque:"בלאַנק טשעק",
  Blank:"בלאַנק",
  No_Cheques:"קיין טשעקס",
  No_Cheques_Word:"דרוק דיין ערשטער <a routerLink='/folder/Cheque/New'>טשעק</a>.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"View בילד",
  View:"View",
  Modify:"מאָדיפיצירן",
  Delete:"ויסמעקן",
  Cheque_Paid:"באַצאָלט",
  New_Deduction:"ניו דעדוקטיאָן",
  Title:"טיטל",
  Frequency:"אָפטקייַט",
  Hourly:"אַורלי",
  Daily:"טעגלעך",
  Weekdays:"וויקדייז",
  BiWeekly:"2 וואָכן",
  TriWeekly:"3 וואָכן",
  Monthly:"כוידעשלעך",
  MiMonthly:"2 חדשים",
  Quarterly:"קאָרטערלי",
  Yearly:"יאָריק",
  Every_Week:"יעדע וואך",
  Every_Payroll:"יעדער פּייראָול",
  Every_Month:"יעדן חודש",
  Annually:"יערלעך",
  Always_Scheduled:"שטענדיק סקעדזשולד",
  Start_Date:"אנהייב דאטום",
  End_Date:"סוף טאָג",
  Start_Time:"אָנהייב צייט",
  End_Time:"סוף צייט",
  Deduction_Label:"דעדוקטיאָן לאַבעל",
  Notes:"הערות",
  Options:"אָפּציעס",
  Enable:"געבן",
  Select_Deductions:"אויסקלייַבן דעדוקטיאָנס",
  Deductions:"דעדוקטיאָנס",
  No_Deductions:"קיין דעדוקטיאָנס",
  No_Deductions_Word:"שאַפֿן דיין ערשטער <a routerLink='/folder/Deduction/New'>דעדוקטיאָן</a>.",
  New_Employee:"ניו אָנגעשטעלטער",
  No_Title:"קיין טיטל",
  _Name:"נאָמען",
  About_Yourself:"וועגן דיך",
  Full_Name:"גאנצע נאמען",
  Birthday:"דיין געבורסטאָג",
  Email:"בליצפּאָסט",
  SMS:"טעקסט",
  Phone:"טעלעפאָן",
  Test:"טעסט",
  Call:"רופן",
  Fax:"פאַקס",
  Printed_Note:"געדרוקט נאטיץ",
  Position:"שטעלע",
  Job_Position:"אַרבעט שטעלע",
  Select_a_Job:"אויסקלייַבן אַ אַרבעט",
  Select_a_Salary:"אויסקלייַבן אַ געצאָלט",
  Add_a_Deduction:"לייג אַ דעדוקטיאָן",
  Taxes:"טאַקסעס",
  Add_Taxes:"לייג טאַקסעס",
  Date_of_Birth:"געבורטס טאג",
  Email_Address:"אימעיל אדרעס",
  Phone_Number:"טעלעפאָן נומער",
  Phone_Call:"טעלעפאָן רוף",
  Enable_on_Payroll:"געבן אויף פּייַראָלל",
  Select_Employees:"אויסקלייַבן עמפּלוייז",
  No_Employees:"קיין עמפּלוייז",
  No_Employees_Word:"לייג דיין ערשטער נייַע <a routerLink='/folder/Employee/New'>עמפּלוייז</a>.",
  No_Name:"קיין נאָמען",
  Unemployeed:"אַרבעטלאָז",
  Employeed:"אנגעשטעלט",
  Paid:"באַצאָלט",
  Enabled:"ערמעגליכט",
  Disabled:"פאַרקריפּלט",
  Fire:"פייער",
  Not_Available:"נישט עוועלעבעל",
  Not_Available_Word:"דרוק דיין ערשטער <a routerLink='/folder/Invoice/New'>ינוואָיסע</a> און באַקומען באַצאָלט.",
  Select_Invoices:"אויסקלייַבן ינווויסיז",
  Print_Invoice_Word:"דרוק דיין ערשטער <a routerLink='/folder/Invoice/New'>ינוואָיסע</a> און באַקומען באַצאָלט.",
  Invoice_Title:"ינווויס טיטל",
  Invoice_Date:"ינווויס טאָג",
  Due_Date:"פעליק טאָג",
  New_Job:"ניו דזשאָב",
  Details:"דעטאַילס",
  Customize:"קאַסטאַמייז",
  Customize_Dashboard:"קאַסטאַמייז דאַשבאָרד",
  Components:"קאַמפּאָונאַנץ",
  No_Components:"קיין קאַמפּאָונאַנץ",
  Main_Components:"הויפּט קאַמפּאָונאַנץ",
  Smaller_Components:"קלענערער קאַמפּאָונאַנץ",
  Error_Loading_Page:"טעות לאָודינג דעם בלאַט.",
  Bank_Details:"באַנק דעטאַילס",
  About_Your_Job:"וועגן דיין אַרבעט",
  Your_Schedule:"דיין פּלאַן",
  Job_Title:"אַרבעט טיטל",
  Job_Description:"אַרבעט באַשרייַבונג",
  Job_Details:"אַרבעט דעטאַילס",
  Enable_Job:"געבן אַרבעט",
  Pay_Period:"באַצאָלן פּעריאָד",
  Perpetually_Schedule:"שטענדיק פּלאַן",
  Select_Jobs:"אויסקלייַבן דזשאָבס",
  No_Jobs:"קיין דזשאָבס",
  Add_Jobs:"לייג דזשאָבס",
  No_Jobs_Word:"לייג די ערשטער <a routerLink='/folder/Job/New'>דזשאב</a> צו דער רשימה.",
  Count_Employees:"job.employee_count+' עמפּלוייז'",
  Zero_Employees:"0 עמפּלוייז",
  New_Leave:"ניו לאָזן",
  Leave_Name:"לאָזן נאָמען",
  Paid_Leave:"באַצאָלט לאָזן",
  Leave_Pay:"לאָזן באַצאָלן",
  Indefinite_Leave:"ינדעפאַנאַט לאָזן",
  Indefinite_Payroll:"ינדעפאַנאַט פּייראָול",
  Leave:"לאָזן",
  Add_Schedules:"לייג סטשעדולעס",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"געבן אַוועק",
  Select_Leaves:"אויסקלייַבן בלעטער",
  No_Leaves:"קיין בלעטער פון אַוועק",
  Leave_Of_Absence:"לאָזן פון אַוועק",
  Leaves_Of_Absence:"בלעטער פון אַוועק",
  New_Leave_of_Absense:"נייַ לאָזן פון אַוועק",
  No_Leaves_Word:"לייג די ערשטער <a routerLink='/folder/Leave/New'>פאַרלאָזן פון אַוועק</a>.",
  Not_Enabled:"ניט ענאַבלעד",
  Absences:"אַבסענסעס",
  Payee:"געצאָלט",
  New_Payee:"נייַ פּייַעעע",
  Payee_Identifier:"פּייעע אידענטיפיאר",
  Payee_Name:"פּייַעעע נאָמען",
  Payee_Title:"פּייַעעע טיטל",
  Payment_Memo:"צאָלונג מעמאָ",
  Select_Payees:"אויסקלייַבן פּייַעעס",
  No_Payees:"קיין פּייַעעס",
  Add_Payee_Note:"לייג די ערשטער <a routerLink='/folder/Payee/New'>פּייַעע</a>.",
  New_Payees:"נייַ פּייַעעס",
  About_The_Payment_Schedule:"צאָלונג פּלאַן",
  Your_Payroll_Schedule:"אָטאַמאַטיק פּייראָול",
  New_Payment:"ניו צאָלונג",
  Identifier:"ידענטיפיער",
  Selected:"אויסגעקליבן",
  Select:"אויסקלייַבן",
  Memo:"מעמאָ",
  Payment_Date:"צאָלונג טאָג",
  Mark_as_Paid:"מארק ווי באַצאָלט",
  Select_Payments:"אויסקלייַבן פּיימאַנץ",
  No_Payments:"קיין פּיימאַנץ",
  No_Payments_Word:"שאַפֿן די ערשטער <a routerLink='/folder/Payment/New'>צאָלונג</a>.",
  Create_Payroll:"שאַפֿן פּייראָול",
  Properties:"פּראָפּערטיעס",
  Payroll_Title:"פּייַראָלל טיטל",
  Payroll_Notes:"פּייראָול נאָטעס",
  Payroll_Setup:"פּייַראָלל סעטאַפּ",
  Tabulate_Payments:"טאַבולאַטע פּיימאַנץ",
  Tabulate:"טאַבולאַט",
  By:"דורך:",
  Payments_By:"פּיימאַנץ דורך",
  Timesheets_And_Schedules:"צייט שיץ & סטשעדולעס",
  Both:"ביידע",
  Items:"זאכן",
  Add_Payees:"לייג פּייַעעס",
  Add_Account:"לייג אַקאַונט",
  Enable_Account:"געבן אַקאַונט",
  Enable_Payroll:"געבן פּייראָול",
  Print_Payroll:"דרוק פּייראָול",
  No_Payrolls:"קיין פּייראָול",
  No_Payroll_Word:"שאַפֿן דיין ערשטער <a routerLink='/folder/Payroll/New'>פּייַראָלל</a>.",
  View_more:"זען מער",
  Less:"ווייניקער",
  Add_Payroll:"לייג פּייראָול",
  Select_Payroll:"אויסקלייַבן פּייראָול",
  About_Your_Salary:"וועגן דיין געצאָלט",
  Add_Salaries:"לייג סאַלעריז",
  Add_Salary:"לייג געצאָלט",
  Salaries:"סאַלעריז",
  No_Salaries:"קיין סאַלעריז",
  No_Salaries_Word:"לייג די ערשטער <a routerLink='/folder/Salary/New'>געצאָלט</a>.",
  Select_Salaries:"אויסקלייַבן סאַלעריז",
  New_Salary:"ניו געצאָלט",
  Salary_Name:"געצאָלט ידענטיפיער",
  Enable_Salary:"געבן געצאָלט",
  Salary_Amount:"געצאָלט סומע",
  New_Schedule:"ניו סקעדזשול",
  Schedule_Title:"פּלאַן טיטל",
  Add_Address:"לייג אַדרעס",
  Repeat:"איבערחזרן",
  Design:"פּלאַן",
  Edit_Design:"רעדאַגירן דיזיין",
  Edit_this_Design:"רעדאַגירן דעם פּלאַן",
  Repeat_Payment:"איבערחזרן צאָלונג",
  Enable_Schedule:"געבן סקעדזשול",
  Never:"קיינמאָל",
  Select_Schedule:"אויסקלייַבן סטשעדולעס",
  No_Schedules:"קיין סקעדזשולז",
  No_Schedules_Word:"שאַפֿן דער ערשטער <a routerLink='/folder/Schedule/New'>פּלאַן</a>.",
  New_Administrator:"ניו אַדמיניסטראַטאָר",
  Username:"נאמען",
  First_Name:"ערשטע נאמען",
  Last_Name:"לעצטע נאמען",
  Add_an_Address:"לייג אַ אַדרעס",
  Payment_Limit:"פּער-פּייַמענט לימיט",
  Total_Limit:"גאַנץ לימיט",
  Select_Accounts:"אויסקלייַבן אַקאַונץ",
  No_Administrators:"קיין אַדמיניסטראַטאָרס",
  No_Administrators_Word:"שאַפֿן דעם ערשטער <a routerLink='/folder/Administrator/New'>אַדמין אַקאַונט</a>.",
  New_Administrators_Word:"לייג דער ערשטער <a routerLink='/folder/Administrator/New'>אַדמיניסטראַטאָר</a>",
  Cloud:"וואָלקן",
  Backup:"באַקקופּ",
  Enable_iCloud:"געבן iCloud",
  Enable_Google_Drive:"געבן Google דרייוו",
  Enable_Microsoft_OneDrive:"געבן Microsoft OneDrive",
  Automatically_Backup:"אויטאָמאַטיש באַקקופּ",
  FTP_Settings:"פטפּ סעטטינגס",
  URL_File_Prompt:"ביטע ספּעציפיצירן דעם אָרט פֿאַר אַ .קסלס / .קסלסקס / .דזשסאָן טעקע צו אַרייַנפיר:",
  URL_SQL_Prompt:"ביטע ספּעציפיצירן דעם אָרט פון די SQLite טעקע צו אַרייַנפיר:",
  FTP_Backup:"פטפּ באַקקופּ",
  FTP_Import:"פטפּ ימפּאָרט",
  FTP:"פטפּ",
  SFTP:"SFTP",
  Host:"האָסט",
  Port:"פּאָרט",
  Path:"דרך",
  Data_Path:"דאַטאַ פּאַט",
  Enable_FTP_Account:"געבן FTP אַקאַונט",
  HostnameIP:"האָסט נאָמען",
  Password:"שפּריכוואָרט",
  Connection_Port:"קשר פּאָרט",
  Enable_MySQL_Database:"געבן MySQL דאַטאַבאַסע",
  Log:"קלאָץ",
  Reset:"באַשטעטיק",
  Erase:"מעקן",
  Export:"עקספּאָרט",
  Database:"דאַטאַבאַסע",
  Upload_CSV:"צופֿעליקער קסוו",
  Download_CSV:"אראפקאפיע CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL באַקקופּ",
  Internal_Notes:"ינערלעך נאָטעס",
  Root_Path:"וואָרצל פּאַט",
  Select_Backup:"אויסקלייַבן באַקקופּ",
  Add_New_Backup:"לייג אַ נייַע באַקקופּ",
  First_Backup:"סעטאַפּ דער ערשטער באַקאַפּ ...",
  Backups:"באַקקופּס",
  Add_Backup:"לייג באַקקופּ",
  No_Backups:"עס זענען קיין באַקאַפּס צו געפֿינען.",
  Select_Backup_Type:"סעלעקטירן דעם טיפּ פון באַקאַפּ איר וואָלט ווי צו סעטאַפּ ...",
  Backup_Type:"באַקקופּ טיפּ",
  FTP_Drive:"פטפּ דרייוו",
  URL_Location:"URL",
  Apple_iCloud:"יקלאָוד",
  Google_Drive:"פאָר",
  Microsoft_OneDrive:"פאָר",
  Import_Fields:"אַרייַנפיר פעלדער",
  Import_Fields_Word:"ניצן דעם אָפּטיילונג צו אויסקלייַבן <a routerLink='/folder/Holiday/New'>ימפּאָרט</a> דאַטן.",
  Upload:"צופֿעליקער",
  Download:"אראפקאפיע",
  Download_JSON:"אראפקאפיע ווי JSON דאַטאַ",
  Download_SQL:"אראפקאפיע ווי סקל טעקע",
  New_Bank:"ניו באַנק",
  New_Account:"ניו חשבון",
  New_Bank_Account:"ניו באַנק אקאונט",
  Upload_Image:"צופֿעליקער בילד",
  Bank_Name:"באַנק נאָמען",
  Bank_Address:"באַנק אַדרעס",
  Branch_Address:"צווייַג אַדרעס",
  Address_on_Cheque:"אַדרעס",
  Cheque_Numbers:"קוק נומבערס",
  Cheque_Details:"קוק דעטאַילס",
  Bank_Logo:"באַנק לאָגאָ",
  Cheque_ID:"קוק ID",
  Starting_Cheque_ID:"סטאַרטינג טשעק שייַן",
  Transit_Number:"דורכפאָר נומער",
  Institution_Number:"ינסטיטוטיאָן נומער",
  Designation_Number:"באַצייכענונג נומער",
  Account_Number:"חשבון נומער",
  Limits:"לימאַץ",
  Default_Limits:"פעליקייַט לימאַץ",
  Over_Limit:"איבער לימיט",
  Under_Limit:"אונטער לימיט",
  Payroll_Limit:"פּייראָול לימיט",
  Enable_Bank_Account:"געבן באַנק אַקאַונט",
  Select_Account:"אויסקלייַבן אַקאַונט",
  No_Bank_Account:"קיין באַנק אקאונט",
  No_Bank_Account_Word:"לייג די ערשטער <a routerLink='/folder/Accounts/New'>באַנק אקאונט</a>.",
  Bank_Accounts:"באַנק אַקאַונץ",
  No_Accounts:"קיין אַקאַונץ",
  No_Accounts_Note:"לייג די ערשטער <a routerLink='/folder/Accounts/New'>באַנק אקאונט</a>.",
  Cheque_Designer:"קוק די דיזיינער",
  Cheque_Design:"קוק דיזיין",
  Select_Design:"אויסקלייַבן אַ פּלאַן",
  Cheque_Designs:"קוק דיזיינז",
  No_Cheque_Designs:"קיין טשעק דיזיינז",
  No_Cheque_Designs_Word:"שאַפֿן דיין אייגענע <a routerLink='/folder/Settings/Cheque/Design/New'>טשעק פּלאַן</a>.",
  Set_Default:"שטעלן ווי דיפאָלט",
  Default:"פעליקייַט",
  Remove:"אַראָפּנעמען",
  Folder:"טעקע",
  Edit:"רעדאַגירן",
  LoadingDots:"לאָדן...",
  Add_a_New_File:"לייג אַ <a href='#' (click)='add()'>ניו טעקע</a> צו",
  this_folder:"דעם טעקע",
  FTP_Backup_Settings:"פטפּ באַקקופּ סעטטינגס",
  Secure_File_Transfer:"זיכער טעקע אַריבערפירן",
  New_Holiday:"ניו יום טוּב",
  Add_Holiday:"לייג האָלידייַ",
  Holiday_Name:"יום טוּב נאָמען",
  Additional_Pay:"נאָך צאָלונג",
  Enable_Holiday:"געבן יום טוּב",
  Select_Holidays:"אויסקלייַבן האָלידייַס",
  No_Holidays:"קיין האָלידייַס",
  No_Holidays_Word:"לייג די ערשטער <a routerLink='/folder/Holiday/New'>ציבור יום טוּב</a>.",
  New_Import:"ניו ימפּאָרט",
  Import_Data:"אַרייַנפיר דאַטאַ",
  Import_Data_Word:"אויסקלייַבן די טיפּ פון טעקע אָדער ופּלאָאַד אופֿן פון דיין טשוזינג.<br /> איר וועט קענען צו אויסקלייַבן וואָס ימפּאָרטיד פעלדער אין אַ טעקע שטימען צו וועלכער פּאַראַמעטער אין די אַפּ נאָך ופּלאָאַדינג אַ געשטיצט טעקע.",
  Import_File:"אַרייַנפיר טעקע",
  Link_To_File:"לינק צו טעקע",
  Select_File:"אויסקלייַבן טעקע",
  New_Moderator:"ניו מאָדעראַטאָר",
  Allow_Moderation:"לאָזן מאַדעריישאַן",
  Create_Paycheques:"שאַפֿן פּייַטשעקוועס",
  Edit_Paycheques_and_Data:"רעדאַגירן פּייַטשעקס און דאַטן",
  Destroy_Data_and_Paycheques:"צעשטערן דאַטן און פּייַטשעקוועס",
  Bonus_Percentage:"פּייַטשעקווע פּראָצענט",
  Fixed_Amount:"פאַרפעסטיקט סומע",
  Select_Moderator:"אויסקלייַבן מאָדעראַטאָר",
  No_Moderators:"קיין מאָדעראַטאָרס",
  Moderators:"מאָדעראַטאָרס",
  Moderator_Account:"שאַפֿן דעם ערשטער <a routerLink='/folder/Administrator/New'>מאַדעראַטאָר חשבון</a>.",
  No_Moderators_Word:"לייג די ערשטער <a routerLink='/folder/Administrator/New'>מאָדעראַטאָר</a>.",
  Defaults:"דיפאָלץ",
  Provide_Us_Info:"צושטעלן אינפֿאָרמאַציע",
  Setup_Your_Printer:"סעטאַפּ דיין פּרינטער",
  Your_Company:"וועגן דיין פירמע",
  Company_Name:"פֿירמע נאָמען",
  Currency:"קראַנטקייַט",
  Default_Currency:"פעליקייַט קראַנטקייַט",
  Base_Monthly_Income:"כוידעשלעך האַכנאָסע",
  Protection:"שוץ",
  App_Protection:"אַפּ פּראַטעקשאַן",
  PIN_Code_Protection:"PIN קאָד פּראַטעקשאַן",
  App_Protection_Word:"געבן זיכערהייט מעטהאָדס וואָס העלפֿן באַשיצן דיין חשבון.",
  PIN_Code:"PIN קאָד",
  Change_PIN:"טוישן די PIN",
  New_Password:"ניו פּאַראָל",
  Geofence_Protection:"געאָ-פענס פּראַטעקשאַן",
  Geofence_Area:"שטעלן שטח",
  Distance:"דיסטאַנסע",
  Setup_Now:"סעטאַפּ איצט",
  Mile:"מייל",
  Km:"קמ",
  m:"עם",
  Facial_Recognition:"פאַסיאַל דערקענונג",
  Face:"פּנים",
  Setup:"שטעל אויף",
  Label:"לאַבעל",
  Password_Protection:"שפּריכוואָרט פּראַטעקשאַן",
  Modify_Password:"מאָדיפיצירן פּאַראָל",
  New_Tax_Entry:"ניו שטייער פּאָזיציע",
  New_Tax:"ניו טאַקס",
  Tax_Label:"שטייַער לאַבעל",
  Perpetually_Enabled:"שטענדיק ענייבאַלד",
  Select_Taxes:"אויסקלייַבן טאַקסעס",
  Tax_Deductions:"שטייער דעדוקטיאָנס",
  No_Tax_Deductions:"קיין שטייער דעדוקטיאָנס",
  No_Tax_Deductions_Word:"לייג די ערשטער <a routerLink='/folder/Tax/New'>שטייַער</a> ויספיר.",
  New_Timer:"ניו טימער",
  Start:"אָנהייב",
  Stop:"אָפּשטעל",
  Start_Timer:"אָנהייב טיימער",
  Stop_Timer:"האַלטן טיימער",
  Timer_Active:"טיימער אַקטיוו",
  Timer:"טיימער:",
  Timer_Running:"טיימער: (לויפן)",
  Save_Timer:"היט טיימער",
  New_Timesheet:"ניו צייט בלאַט",
  Select_Timesheets:"אויסקלייַבן צייט שיץ",
  Work_Notes:"אַרבעט נאָטעס",
  Entry_Title:"פּאָזיציע טיטל",
  No_Timesheets:"קיין צייט שיץ",
  No_Timesheets_Word:"לייג די ערשטע <a routerLink='/folder/Timesheet/New'>צייט-שיט</a>.",
  Timesheet_Submitted:"צייט בלאַט דערלאנגט",
  Timesheet_Congrats:"מאַזל - טאָוו! דיין צייט בלאַט איז הצלחה דערלאנגט. אדאנק!",
  Timesheet_Copy:"צו באַקומען אַ קאָפּיע פון דיין דאָקומענטן, ביטע צושטעלן אונדז דיין E- בריוו אַדרעס און / אָדער רירעוודיק טעלעפאָן נומער.",
  Submit:"פאָרלייגן",
  Allow_Notifications:"לאָזן נאָוטאַפאַקיישאַנז",
  Untitled_Entry:"ניו פּאָזיציע",
  Untitled_Bank:"אן טיטל באַנק",
  Timesheet_Entry:"צייט שיט פּאָזיציע",
  Work_Description:"אַרבעט באַשרייַבונג",
  Enable_Timesheet:"געבן צייט בלאַט",
  Submit_Timesheet:"פאָרלייגן צייט בלאַט",
  Vacation:"וואַקאַציע",
  New_Vacation:"ניו וואַקאַציע",
  Vacation_Name:"וואַקאַציע נאָמען",
  Paid_Vacation:"באַצאָלט וואַקאַציע",
  Vacation_Pay:"וואַקאַציע באַצאָלן",
  Enable_Vacation:"געבן וואַקאַציע",
  Select_Vacations:"אויסקלייַבן וואַקאַציעס",
  No_Vacations:"קיין וואַקאַציעס",
  No_Vacations_Word:"שאַפֿן די ערשטער <a routerLink='/folder/Vacation/New'>וואַקאַציע</a> פּאָזיציע.",
  Payroll_Schedule:"פּייראָול פּלאַן",
  Next_Payroll:"ווייַטער פּייראָול:",
  Upcoming_Payroll:"אַפּקאַמינג פּייראָול",
  No_Upcoming_Payroll:"קיין אַפּקאַמינג פּייראָול",
  Address_Book:"אדרעס בוך",
  Archived_Documents:"אַרטשיוועד דאָקומענטן",
  Dev_Mode:"אַפּפּליקאַטיאָן אין אַנטוויקלונג מאָדע",
  Administrators:"אַדמיניסטראַטאָרס",
  Privacy:"פּריוואַטקייט",
  None:"קיינער",
  Select_Signature:"אויסקלייַבן סיגנאַטורע",
  No_Signatures:"קיין סיגנאַטשערז",
  No_Signatures_Word:"לייג די ערשטער <a routerLink='/folder/Signature/New'>סיגנאַטורע</a>.",
  Repeat_Indefinitely:"איבערחזרן ינדעפאַנאַטלי",
  Sign:"צייכן",
  Sign_Here:"צייכן דאָ",
  Date_Signed:"טאָג געחתמעט",
  Signature_Pad:"סיגנאַטורע פּאַד",
  Account_Holder:"חשבון האָלדער",
  Account_Properties:"אַקאַונט פּראָפּערטיעס",
  Name_On_Cheque:"נאָמען אויף טשעק",
  Server_Hostname:"סערווירער האָסט נאָמען / IP",
  Printers:"פּרינטערס",
  No_Printers:"קיין פּרינטערס",
  Printer_Exists:"א דרוקער מיט דעם נאמען עקזיסטירט שוין.",
  No_Printers_Word:"לייג די ערשטער <a routerLink='/folder/Printer/New'>פּרינטער</a>.",
  No_Printer_Alert:"קיין דרוקער איז דיפיינד. צי איר ווילן צו ינסטאַלירן אַ דרוקער?",
  Add_Printer:"לייג פּרינטער",
  New_Printer:"ניו דרוקער",
  Printer_Hostname:"פּרינטער האָסט נאָמען / IP",
  Printer_Label:"פּרינטער לאַבעל",
  Printer_Protocol:"פּרינטער פּראָטאָקאָל",
  Protocol:"פּראָטאָקאָל",
  Email_Print:"בליצפּאָסט",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"לפּד",
  HPJetDirect:"כאָלעל",
  Print_Job:"דרוק אַרבעט",
  Printed:"געדרוקט",
  Not_Printed:"ניט געדרוקט",
  Print_Jobs:"דרוק דזשאָבס",
  Print_Queue:"דרוק ריי",
  No_Print_Jobs:"קיין דרוק דזשאָבס",
  No_Prints:"שאַפֿן אַ נייַע <a routerLink='/folder/Cheque/New'>טשעק</a> צו דרוקן.",
  Prints:"פּרינץ",
  Find_Printer:"געפֿינען פּרינטער",
  Find_AirPrint_Devices:"געפֿינען AirPrint דעוויסעס",
  Select_Printer:"אויסקלייַבן פּרינטער",
  System_UI:"סיסטעם וי",
  Printer:"דרוקער",
  Status:"סטאַטוס",
  Preview:"פאָרויסיקע ווייַזונג",
  Enable_Print_Job:"געבן דרוק אַרבעט",
  Queue_Weight:"ריי וואָג",
  Unlimited:"ונלימיטעד",
  Show_Advanced_Printer_Options:"ווייַזן אַוואַנסירטע פּרינטער אָפּציעס",
  Advanced:"אַוואַנסירטע",
  Location:"אָרט",
  Note:"נאטיץ",
  Queue_Name:"ריי נאָמען",
  Pages_Printed_Limit:"בלעטער געדרוקט לימיט",
  MultiPage_Idle_Time:"מאַלטי-בלאַט וואַרטן צייט (s)",
  Page_Count_Limit:"באַגרענעצן בלאַט ציילן",
  Page_Orientation:"בלאַט אָריענטירונג",
  Portrait:"פּאָרטרעט",
  Landscape:"לאַנדשאַפט",
  Duplex:"דופּלעקס",
  Double_Sided_Printing:"טאָפּל סיידאַד",
  Duplex_Mode:"דופּלעקס מאָדע",
  Duplex_Short:"קורץ",
  Duplex_Long:"לאנג",
  Duplex_None:"קיינער",
  Color_And_Quality:"קאָליר און קוואַליטעט",
  Monochrome:"מאָנאָטשראָמע",
  Photo_Quality_Prints:"פאָטאָ קוואַליטי פּרינץ",
  Printer_Email:"דרוקער בליצפּאָסט",
  Automatically_Downsize:"אויטאָמאַטיש דאַונסייז",
  Paper:"פּאַפּיר",
  Paper_Name:"פּאַפּיר נאָמען",
  Paper_Width:"פּאַפּיר ברייט",
  Paper_Height:"פּאַפּיר הייך",
  Paper_Autocut_Length:"פּאַפּיר אַוטאָ-שנייַדן לענג",
  Margins:"מאַרדזשאַנז",
  Page_Margins:"בלאַט מאַרדזשאַנז",
  Page_Margin_Top:"שפּיץ בלאַט גרענעץ",
  Page_Margin_Right:"רעכט זייַט גרענעץ",
  Page_Margin_Bottom:"דנאָ בלאַט גרענעץ",
  Page_Margin_Left:"לינקס זייַט גרענעץ",
  Add_Employees:"לייג עמפּלויעעס",
  Header:"כעדער",
  Print_A_Page_Header:"דרוק אַ בלאַט כעדער",
  Header_Label:"כעדער לאַבעל",
  Header_Page_Index:"כעדער בלאַט אינדעקס",
  Header_Font_Name:"כעדער פאָנט",
  Select_Font:"אויסקלייַבן פאָנט",
  Font_Selector:"פאָנט סעלעקטאָר",
  Header_Font_Size:"כעדער פאָנט",
  Header_Bold:"כעדער באָלד",
  Header_Italic:"קאַפּיטל ייטאַליק",
  Header_Alignment:"כעדער אַליינמאַנט",
  Left:"לינקס",
  Center:"צענטער",
  Right:"רעכט",
  Justified:"גערעכטפארטיקט",
  Header_Font_Color:"כעדער קאָליר",
  Select_Color:"אויסקלייַבן קאָליר",
  Footer:"פוטער",
  Print_A_Page_Footer:"דרוק אַ בלאַט פוטער",
  Footer_Label:"פוטער לאַבעל",
  Footer_Page_Index:"פוטער בלאַט אינדעקס",
  Footer_Font_Name:"פוטער פאָנט",
  Fonts:"פאַנץ",
  Done:"געטאן",
  Select_Fonts:"אויסקלייַבן פאַנץ",
  Footer_Font_Size:"פוטער גרייס",
  Footer_Bold:"דרייסט פאָאָטער",
  Footer_Italic:"פוטער ייטאַליק",
  Footer_Alignment:"פוטער אַליינמאַנט",
  Footer_Font_Color:"פוטער קאָליר",
  Page_Copies:"קאפיעס",
  Enable_Printer:"געבן פּרינטער",
  Remote_Logging:"רימאָוט לאָגינג",
  Log_Server:"קלאָץ סערווירער",
  Encryption:"ענקריפּשאַן",
  Random_Key:"טראַפ שליסל",
  Encryption_Key:"ענקריפּשאַן שליסל",
  Cheques_Webserver:"מנהג דאַטאַבאַסע",
  Learn_How:"לערנען ווי",
  Signature:"כסימע",
  Default_Printer_Unit:"אין / סענטימעטער / מם / (פּט)",
  View_Signature:"כסימע",
  Printed_Signature:"געדרוקט חתימה",
  Digitally_Sign:"דיגיטאַללי צייכן",
  Digital_Signature:"דיגיטאַל סיגנאַטורע",
  Add_Signature:"לייג סיגנאַטורע",
  Add_Your_Signature:"לייג דיין כסימע",
  Enable_Signature:"געבן סיגנאַטורע",
  Digitally_Signed:"דיגיטאַל געחתמעט",
  Insert_Error:"ניט געקענט צו ראַטעווען טשעק ווייַל פון דייטאַבייס ישוז.",
  Delete_Confirmation:"זענט איר זיכער אַז איר ווילן צו ויסמעקן די אינפֿאָרמאַציע?",
  Discard_Confirmation:"זענט איר זיכער אַז איר ווילט אַוועקוואַרפן די אינפֿאָרמאַציע?",
  Discard_Bank_Confirmation:"זענט איר זיכער אַז איר ווילט אַוועקוואַרפן דעם חשבון?",
  Discard_Printer_Confirmation:"זענט איר זיכער אַז איר ווילט אַוועקוואַרפן דעם דרוקער?",
  Delete_Bonus_Confirmation:"זענט איר זיכער אַז איר ווילן צו ויסמעקן דעם באָנוס?",
  Delete_Invoice_Confirmation:"זענט איר זיכער איר ווילן צו ויסמעקן דעם ינווויס?",
  Generated_Cheque:"דזשענערייטאַד טשעק",
  Generated_Invoice:"דזשענערייטאַד ינווויס",
  Schedule_Start_Time:"פּלאַן אָנהייב",
  Schedule_End_Time:"פּלאַן סוף",
  New_Call:"ניו רופן",
  No_Contacts:"קיין קאָנטאַקטן",
  No_Contacts_Word:"אַדמיניסטראַטאָרס, מאָדעראַטאָרס, עמפּלוייז און פּייַעס דערשייַנען ווי קאָנטאַקטן.",
  PDF_Subject:"פינאַנצן",
  PDF_Keywords:"פּייַראָלל פּדף טשעק טשעקס",
  Printer_Setup:"פּרינטער סעטאַפּ",
  Printer_Selection:"פּרינטער סעלעקציע",
  New_Fax:"ניו פאַקס",
  New_Fax_Message:"ניו פאַקס אָנזאָג",
  Fax_Machine:"פאַקס מאַשין",
  Fax_Name:"פאַקס נאָמען",
  Fax_Email:"פאַקס עמאַיל",
  Fax_Number:"פאַקס נומער",
  Contents:"אינהאַלט",
  Fax_Body:"בלאַט גוף",
  Header_Word:"קעפל:",
  Header_Text:"כעדער טעקסט",
  Include_Header:"אַרייַננעמען כעדער",
  Include_Footer:"אַרייַננעמען פאָאָטער",
  Footer_Word:"פוטער:",
  Footer_Text:"פוטער טעקסט",
  Attach_a_Cheque:"לייג אַ טשעק",
  Add_Deduction:"לייג דעדוקטיאָן",
  Enable_Fax:"שיקן פאַקס",
  Select_Fax:"אויסקלייַבן פאַקס",
  No_Faxes:"קיין פאַקסעס",
  Faxes:"פאַקסעס",
  Save_and_Send:"שיקן פאַקס",
  Save_and_Pay:"היט און באַצאָלן",
  WARNING:"ווארענונג:",
  Remember:"געדענק",
  Printing:"דרוק",
  Printing_Complete:"פּרינטינג גאַנץ!\n\n",
  of:"פון",
  There_Were:"עס זענען געווען",
  Successful_Prints:"מצליח פּרינץ און",
  Unsuccessful_Prints:"ניט געראָטן פּרינץ.",
  PrinterError:"אנטשולדיגט! עס איז געווען אַ טעות.",
  Printing_:"דרוקן...",
  PrinterSuccess:"דאָקומענט געדרוקט הצלחה.",
  PrintersSuccess:"דאָקומענטן געדרוקט הצלחה.",
  New_Message:"ניו אָנזאָג",
  New_Messages:"ניו אַרטיקלען",
  Read_Message:"לייענען אָנזאָג",
  Write_Message:"שרייב מעסעדזש",
  Send_Message:"שיק א מעסעדזש",
  Subject:"טעמע",
  Message:"אָנזאָג",
  Writing:"שרייבן...",
  Send:"שיקן",
  Set_Date:"שטעלן טאָג",
  Set_Time:"שטעלן צייט",
  Recieve:"באַקומען",
  Set_as_Default:"שטעלן ווי דיפאָלט",
  Default_Account:"פעליקייַט אַקאַונט",
  Default_Design:"פעליקייַט פּלאַן",
  Multiple_Cheques:"דריי טשעקס",
  Account_Mode:"חשבון מאָדע",
  Multiple_Cheques_Description:"דריי טשעקס פּער בלאַט.",
  Check_and_Table:"טשעק & טיש",
  Check_including_Table:"קוק מיט אַקאַונטינג טיש.",
  Check_Mailer:"קוק Mailer",
  Check_Mailer_Window:"קוק מיט אַן אַדרעס פֿענצטער.",
  DocuGard_Table_Top:"גרויס טשעק & טיש",
  DocuGard_Table_Middle:"גרויס טשעק & טיש (מיטן)",
  DocuGard_Table_Bottom:"גרויס טשעק & טיש (דנאָ)",
  DocuGard_Mailer_Top:"גרויס טשעק מיילער",
  DocuGard_Mailer_Middle:"גרויס טשעק מיילער (מיטן)",
  DocuGard_Mailer_Bottom:"גרויס טשעק מיילער (דנאָ)",
  DocuGard_Three_Cheques:"דריי גרויס טשעקס",
  DocuGard_Cheque_Top:"איין גרויס טשעק",
  DocuGard_Cheque_Middle:"גרויס טשעק (מיטן)",
  DocuGard_Cheque_Bottom:"גרויס טשעק (דנאָ)",
  DocuGard_Three_Cheques_Window:"דריי טשעקס אויף איין בלאַט.",
  DocuGard_Table_Top_Window:"טשעק & פאַרדינסט טיש.",
  DocuGard_Table_Middle_Window:"טשעק & פאַרדינסט טיש.",
  DocuGard_Table_Bottom_Window:"טשעק & פאַרדינסט טיש.",
  DocuGard_Mailer_Top_Window:"טשעק, טיש און אַדרעס.",
  DocuGard_Mailer_Middle_Window:"טשעק, טיש און אַדרעס.",
  DocuGard_Mailer_Bottom_Window:"טשעק, טיש און אַדרעס.",
  DocuGard_Cheque_Top_Window:"קוק פֿאַר זיכער פּאַפּיר.",
  DocuGard_Cheque_Middle_Window:"קוק פֿאַר זיכער פּאַפּיר.",
  DocuGard_Cheque_Bottom_Window:"קוק פֿאַר זיכער פּאַפּיר.",
  Basic_Cheque:"איין טשעק",
  Basic_Cheque_Print:"דרוק אויס אַ איין טשעק.",
  Error_Setting_As_Paid:"טעות באַשטעטיקן ווי באַצאָלט",
  Add_Attachment:"לייג אַטאַטשמאַנט",
  PrinterUnavailable:"פּרינטער אַנאַוויילאַבאַל",
  CreditCardComponent:"קאַרדס",
  PaypalComponent:"",
  InteracComponent:"ינטעראַק",
  BitcoinComponent:"ביטקאָין",
  New_Deposit:"ניו אַוועקלייגן",
  Deposits:"דיפּאַזאַץ",
  No_Deposits:"קיין דיפּאַזאַץ",
  Credit_Card_Deposit:"קרעדיט קארטל",
  New_Credit_Card_Deposit_Message:"קרעדיט קאַרטל אַוועקלייגן",
  New_BitCoin_Deposit:"ביטקאָין",
  New_BitCoin_Deposit_Message:"ביטקאָין אַוועקלייגן",
  New_Interac_Deposit:"ינטעראַק",
  New_Interac_Deposit_Message:"ינטעראַק אַריבערפירן",
  Payments_Limit:"צאָלונג לימיט",
  No_Payment_Limit:"קיין צאָלונג לימיט",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"פּייַפּאַל אַוועקלייגן",
  No_Deposits_Word:"לייג די ערשטער פירמע <a routerLink='/folder/Deposit/New'>אַוועקלייגן</a>.",
  No_Documents_Specified:"קיין דאָקומענטן זענען נישט ספּעסיפיעד פֿאַר דרוקן",
  No_Printers_Added:"קיין פּרינטערס זענען געפונען. גיין צו סעטטינגס> פּרינטערס צו לייגן איינער.",
  DB_Erase_Prompt:"גאָר מעקן די גאנצע דאַטאַבייס? ווארענונג: איר וועט פאַרלירן אַלע סטאָרד אינפֿאָרמאַציע!",
  Console_Warning:"צי ניט פּאַפּ קיין טעקסט אין דעם קאַנסאָול. איר קען שטעלן זיך און / אָדער דיין פירמע אין אַ ערנסט ריזיקירן.",
  No_Faxes_Word:"שאַפֿן דער ערשטער <a routerLink='/folder/Fax/New'>פאַקס</a>.",
  Cheque_Delete_Confirmation:"זענט איר זיכער אז איר ווילט אויסמעקן דעם טשעק?",
  Design_Delete_Confirmation:"זענט איר זיכער אַז איר ווילן צו ויסמעקן דעם פּלאַן?",
  Cheque_Pay_Confirmation:"מארק דעם טשעק ווי באַצאָלט? עס וועט נישט דערשייַנען אין די דרוק ריי.",
  Payment_Pay_Confirmation:"מארק דעם צאָלונג ווי באַצאָלט? עס וועט נישט דערשייַנען אין די טשעק ריי.",
  Delete_Deduction_Confirmation:"זענט איר זיכער איר ווילן צו ויסמעקן דעם אַראָפּרעכענען?",
  Delete_Job_Confirmation:"זענט איר זיכער אַז איר ווילן צו ויסמעקן דעם אַרבעט?",
  Delete_Timesheet_Confirmation:"זענט איר זיכער אַז איר ווילן צו ויסמעקן דעם צייט בלאַט?",
  Delete_Schedule_Confirmation:"זענט איר זיכער אַז איר ווילן צו ויסמעקן דעם פּלאַן?",
  Delete_Setting_Confirmation:"זענט איר זיכער אַז איר ווילן צו באַשטעטיק דעם באַשטעטיקן?",
  Delete_Fax_Confirmation:"זענט איר זיכער אז איר ווילט אויסמעקן דעם פאקס?",
  Delete_File_Confirmation:"זענט איר זיכער איר ווילן צו ויסמעקן דעם טעקע?",
  Delete_Vacation_Confirmation:"זענט איר זיכער אַז איר ווילן צו ויסמעקן דעם וואַקאַציע?",
  Delete_Printer_Confirmation:"זענט איר זיכער אַז איר ווילן צו ויסמעקן דעם דרוקער?",
  Remove_Design_Confirmation:"זענט איר זיכער אַז איר ווילן צו ויסמעקן דעם פּלאַן?",
  Delete_Payroll_Confirmation:"זענט איר זיכער אַז איר ווילן צו ויסמעקן דעם פּייראָול?",
  Send_Fax_Email_Confirmation:"צי איר ווילן צו פאַקס און E- בריוו די דאקומענטן?",
  Send_Email_Confirmation:"צי איר ווילן צו E- בריוו דעם דאָקומענט?",
  Send_Fax_Confirmation:"צי איר ווילן צו פאַקס דעם דאָקומענט?",
  Error_Generating_PDF:"אנטשולדיגט. עס איז געווען אַ טעות אין דזשענערייטינג דעם דאָקומענט.",
  PDF_Error_Saving_Image:"אנטשולדיגט. עס איז געווען אַ טעות אין שפּאָרן PDF בילד פון דעם דאָקומענט.",
  Test_Printer_Confirmation:"צי איר ווילן צו דרוקן אַ פּראָבע בלאַט אויף דעם דרוקער?",
  Save_Timesheet_Prompt:"ביטע לייגן אַ 'טיטל' אָדער דריקן 'אָנהייב טיימער' צו ראַטעווען.",
  Remove_Geofence_Prompt:"זענט איר זיכער אַז איר ווילט צונעמען דעם אָרט פון דעם געאָ פּלויט?",
  Delete_Employee_Confirmation:"זענט איר זיכער איר ווילן צו ויסמעקן",
  Fire_Employee_Confirmation:"זענט איר זיכער איר ווילן צו פייַער",
}