import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component'; 
import { HomeComponent } from './home/home.component'; 
import { DemoComponent } from './demo/demo.component'; 
import { ScreenshotsComponent } from './screenshots/screenshots.component'; 
import { FeaturesComponent } from './features/features.component';
import { PlansComponent } from './plans/plans.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'app', component: HomeComponent },
  { path: 'demo', component: DemoComponent },
  { path: 'online', component: DemoComponent },
  { path: 'home', component: HomeComponent },
  { path: 'prices', component: PlansComponent },
  { path: 'plans',  component: PlansComponent },
  { path: 'login',  component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'features', component: FeaturesComponent },
  { path: 'screenshots', component: ScreenshotsComponent },
  { path: '*', component: HomeComponent }
];