export const Sl = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Copyright &copy; 2023",
  black:"Črna",
  green:"Zelena",
  gold:"Zlato",
  blue:"Modra",
  brown:"Rjav",
  purple:"Vijolična",
  pink:"Roza",
  red:"Rdeča",
  Swatches:"Swatchi",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Odtenek",
  Saturation:"Nasičenost",
  Lightness:"Lahkotnost",
  Upgrade_To_Pro:"Nadgradnja na profesionalni nivo",
  AllFeaturesInclude:"Vse funkcije vključujejo:",
  PrintUnlimitedCheques:"Natisnite neomejeno število čekov",
  PremiumChequeDesigns:"Vrhunski modeli čekov",
  ManageUnlimitedEmployees:"Upravljajte neomejeno število zaposlenih",
  AddUnlimitedPayees:"Dodajte neomejeno število prejemnikov plačil",
  CreateUnlimitedPayrolls:"Ustvarite neomejene plačilne liste",
  UnlimitedSchedulesandTimeSheets:"Neomejeni urniki in časovnice",
  BulkPayPalPayouts:"Množična izplačila PayPal",
  UnlimitedBankAccounts:"Neomejeni bančni računi",
  ManageMultipleCompanies:"Upravljajte več podjetij",
  TrackInsurancePolicies:"Sledite zavarovalnim policam",
  Bio_MetricProtection:"Biometrična zaščita",
  Geo_FenceLock_OutProtection:"Geo-Fence Lock-Out Zaščita",
  Multiple_Companies_Word:"Upravljanje več podjetij ni na voljo brez premije Checks.",
  PayPal_Payouts_Word:"Plačila PayPal so onemogočena brez premije za čeke.",
  PINProtection:"PIN zaščita",
  PasswordProtection:"Zaščita z geslom",
  May_Require_Approval:"Morda bo potrebna odobritev.",
  Subscribe:"Naročite se",
  Billed:"Zaračunano",
  Up:"Gor",
  Down:"Dol",
  Positioning:"Pozicioniranje",
  Marker:"Marker",
  Drag_Marker:"Povlecite marker",
  Tagline:"Natisnite čeke in tabelirajte plačilne liste",
  Marker_Word:"Za velikost elementa uporabite oznake.",
  Pinch_Zoom:"Povečava s prsti",
  Pinch_Word:"Stisnite, da povečate element.",
  Drag:"povlecite",
  Drag_Word:"Uporabite prst za vlečenje elementov.",
  subscription_alias_word:"Samodejno obnavljanje naročnine",
  premium_alias_word:"Paket enkratne nadgradnje",
  print_alias_word:"Natisnite posamezne čeke",
  mode_alias_word:"Način",
  Pro:"Pro",
  Pro_word:"Zahtevana različica Pro.",
  Cant_Delete_Default_Company_Word:"Privzetega podjetja žal ne morete izbrisati.",
  Reinsert_Default_Designs:"Znova vstavite privzete modele",
  Reinsert_Default_Designs_word:"Ali želite znova vstaviti privzete modele?",
  Subscription_Active_Disable_Word:"Ta naročnina je aktivna. Ali želite preklicati to naročnino na čeke?",
  Company_Logo:"Logotip podjetja",
  ZERO_:"NIČ",
  Disclaimer:"Zavrnitev odgovornosti",
  Privacy_Policy:"Politika zasebnosti",
  EULA:"Preveri EULA",
  Terms_Of_Service:"Pogoji storitve",
  Terms_Of_Use:"Pogoji uporabe",
  Refunds:"Politika vračil",
  Single_Print:"1 Preverite",
  Two_Prints:"2 Čeki",
  Five_Prints:"5 Čeki",
  Ten_Prints:"10 Čekov",
  Fifteen_Prints:"15 Čeki",
  Twenty_Prints:"20 Čekov",
  Thirty_Prints:"30 Čekov",
  Image_Added:"Slika dodana",
  Image_Preview:"Predogled slike",
  No_Image_Was_Selected:"Nobena slika ni bila izbrana.",
  Cheques_Unlimited:"Čeki Neomejeno",
  _Subscription:"Naročnina",
  Subscription:"Čeki - 1 mesec",
  Two_Month_Subscription:"Čeki - 2 meseca",
  Three_Month_Subscription:"Čeki - 3 mesece",
  Six_Month_Subscription:"Čeki - 6 mesecev",
  Twelve_Month_Subscription:"Čeki - 12 mesecev",
  Cheques_Are_Available:"Čeki so na voljo za tisk.",
  Upgrade_Required_Two:"Izberite paket in se dvakrat dotaknite gumba za ceno, da začnete nakup. V nekaj sekundah natisnite barvne čeke profesionalnega videza.",
  Month:"mesec",
  Due:"Rok:",
  Expires:"poteče:",
  Subscription_Active:"Naročnina aktivna",
  Subscription_Inactive:"Naročnina neaktivna",
  Purchase_Additional_Cheques:"Želite kupiti dodatne čeke?",
  Printable_Cheque:"Ček za tiskanje",
  Printable_Cheques:"Čeki za tiskanje",
  Printable_Cheque_Word:"ček je na voljo na vašem računu.",
  Printable_Cheques_Word:"čeki so na voljo na vašem računu.",
  Max_Amount_Message:"Znesek, ki ste ga določili, je presegel najvišji znesek, nastavljen za ta sistem:",
  Terms_Required_Word:"Preden nadaljujete z uporabo čekov, se morate strinjati s to pogodbo.",
  Subscriptions:"Naročnine",
  Product_Upgrades:"Nadgradnje",
  Mailed_Out_Cheques:"Čeki poslani po pošti",
  Enter_A_Company_Name:"Vnesite ime podjetja.",
  Single_Cheques:"Posamezni pregledi",
  Cheque_Golden:"Zlati ček",
  Cheque_Golden_Window:"Dizajn z zlatim karo.",
  Cheque_Green:"Zelena kljukica",
  Cheque_Green_Window:"Dizajn zelenega kara.",
  Cheque_Red:"Rdeči karo",
  Cheque_Red_Window:"Dizajn rdečega kara.",
  Cheque_Yellow:"Rumeni ček",
  Cheque_Yellow_Window:"Dizajn rumenega kara.",
  Cheque_Statue_of_Liberty:"Kip svobode",
  Cheque_Statue_of_Liberty_Window:"Dizajn karata Kip svobode.",
  Cheque_Green_Wave:"Zeleni val",
  Cheque_Green_Wave_Window:"Dizajn zelenega kara.",
  Cheque_Golden_Weave:"Zlato vezavo",
  Cheque_Golden_Weave_Window:"Eleganten zlati karo dizajn.",
  Cheque_Green_Sun:"Zeleno sonce",
  Cheque_Green_Sun_Window:"Globoko in pomirjujoče karo oblikovanje.",
  Cheque_Blue_Checkers:"Modri damarji",
  Cheque_Blue_Checkers_Window:"Dizajn modrega kara.",
  Cashiers_Check:"Blagajniški ček",
  Cashiers_Check_Window:"Slogovna predloga blagajniškega čeka.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Aqua check dizajn.",
  Cheque_Golden_Checkers:"Zlati dama",
  Cheque_Golden_Checkers_Window:"Dizajn z zlatim karo.",
  Upgrade_Unavailable:"Nadgradnje niso na voljo",
  Bank_Code_Protection:"Zaščita bančne številke",
  Bank_Code_Protection_Word:"Zaščitite svoje bančne številke pred uporabo v tej aplikaciji, ki se izvaja v drugi napravi ali za drugega uporabnika. To dejanje je NEPOVRATNO. Nadaljevati?",
  Bank_Code_Protection_Blocked_Word:"Bančne številke, ki jih poskušate uporabiti, so rezervirane za drugega uporabnika ali napravo.",
  Bank_Code_Protection_Error_Word:"Preverjanje številke ni uspelo. Povežite se z internetom in poskusite znova dodati ta bančni račun.",
  Bank_Code_Protection_Set_Error_Word:"Zaščita bančne številke zahteva, da ste povezani v internet. Povežite se in poskusite znova.",
  Upgrade_Unavailable_Word:"Žal imamo težave pri preverjanju. Naročnine in nadgradnje na Čeke trenutno niso na voljo za nakup na vašem območju.",
  PayPal_Payment_Preview:"Predogled plačila PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Izberite PayPal",
  PayPal_Applications:"Aplikacije PayPal",
  Purchase_With_Apple_Pay:"Nakupujte z Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Podjetja",
  Insurance:"Zavarovanje",
  New_PayPal:"Nov PayPal",
  Pay_By:"Plačaj do",
  Insure:"Zavarovati",
  Miles:"Milje",
  Payment_Method:"Način plačila",
  Select_Policies:"Izberite Politike",
  Policies:"Pravila",
  Policy:"Politika",
  No_PayPal_Account:"Brez PayPal računa",
  No_Policies:"Brez zavarovalnih polic",
  New_Policy:"Nova politika",
  PayPal_Payment:"Plačilo PayPal",
  PayPal_Payments:"PayPal plačila",
  No_Payment_Selected:"Plačilo ni izbrano",
  Sending_Payment_Word:"Počakajte prosim ... To plačilo je poslano.",
  Sending_Payments_Word:"Počakajte prosim ... Plačila se pošiljajo.",
  No_Payment_Selected_PayPal:"Nobeno <a routerLink='/folder/Payments'>Plačilo PayPal</a> ni izbrano za pošiljanje.",
  Payment_Sent:"Plačilo poslano",
  PayPal_Payment_Sent:"To plačilo je bilo poslano s PayPal.",
  Copay:"Doplačilo",
  Dead:"Mrtev",
  Alive:"živ",
  Not_Dead:"Ni mrtev",
  Unclaimed:"Nezahtevano",
  Attempted:"Poskus",
  Deceased:"Pokojni",
  Claimed:"Zahtevano",
  Unpaid:"Neplačano",
  Sending_Payment:"Pošiljanje plačila",
  Sending_Payments:"Pošiljanje plačil",
  Send_PayPal_Confirmation:"Ali želite to transakcijo poslati s PayPal?",
  Send_PayPal_Confirmation_Word:"Pritisnite zeleni gumb za pošiljanje te transakcije.",
  Save_Payment_As_Unpaid:"Shrani to plačilo kot neplačano?",
  Payment_Pay_Confirmation_PayPal:"Shrani to plačilo kot plačano?",
  No_Policies_Word:"Dodajte prvo <a routerLink='/folder/Postage/New'>zavarovalno polico</a> zdaj.",
  Timesheet_Multiple_Delete_Confirmation:"Ali ste prepričani, da želite izbrisati več časovnic?",
  Select_Multiple_Timesheets_Prompt:"Ni izbranih časovnic. Izberite vsaj eno časovnico.",
  Select_Multiple_Policies_Prompt:"Ni izbranih pravilnikov. Izberite vsaj eno zavarovalno polico.",
  Policies_Multiple_Delete_Confirmation:"Ali ste prepričani, da želite izbrisati več pravilnikov?",
  Company:"Podjetje",
  Add_Company:"Dodaj podjetje",
  New_Company:"Dodaj podjetje",
  No_Companies:"Brez podjetij",
  Enable_Company:"Omogoči podjetje",
  Select_Company:"Izberite Podjetje",
  The_Default_Company:"Privzeta oznaka podjetja.",
  Manage_Companies:"Upravljanje podjetij",
  No_Companies_Word:"Čeki bodo uporabili privzeto podjetje.<br />Dodajte <a routerLink='/folder/Company/New'>prvo podjetje</a>.",
  Default_Company:"Privzeto podjetje",
  Cheques_Unlimited_Word:"Checks Unlimited vam omogoča, da natisnete poljubno število čekov.",
  Cheques_Subscription_Word:"Naročnina na čeke vam omogoča, da natisnete poljubno število čekov.",
  You_Own_This_Product:"Ste lastnik tega izdelka.",
  Your_Subscription_is_Active:"Vaša naročnina je aktivna.",
  Active_Products:"Aktivirani izdelki",
  Purchase_Confirmation:"Nakup",
  Purchase_Cheques:"Nakup čekov",
  Restore_Purchase:"Obnovi nakupe",
  Erase_Purchase:"Izbriši nakupe",
  Successfully_Purchased:"Uspešno kupljeno",
  Enter_Your_Licence_Key:"Prosimo, vnesite svoj licenčni ključ na tej strani, da aktivirate ta izdelek.",
  Licence_Key:"Licenčni ključ",
  Enter_Licence_Key:"Vnesite licenčni ključ",
  Purchased:"Kupljeno",
  Enable_Feature:"Omogoči funkcijo",
  Cancel_Subscription:"Prekliči naročnino",
  Subscription_Removed:"Naročnina odstranjena",
  Select_Active_Subscription:"Izberite aktivno naročnino, da jo spremenite.",
  Remove_Subscription_Word:"Ali ste prepričani, da želite preklicati to naročnino?",
  Delete_Company_Confirmation:"Ali ste prepričani, da želite izbrisati celotno podjetje? OPOZORILO: Izgubili boste vse shranjene podatke!",
  Delete_Default_Company_Word:"Privzetega podjetja ne morete izbrisati . Ali želite ponastaviti aplikacijo in jo obnoviti na privzeto stanje? OPOZORILO: Izgubili boste vse shranjene podatke!",
  Console_Warning_2:"S to aplikacijo ne upravljajte z nobeno valuto, ki trenutno ni vaša.",
  Terms_and_Conditions:"Pogoji",
  and_the:"in",
  for:"za",
  Please_Read_Word:"Preberite in se strinjate z",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Nekaterih menjalnih tečajev valut ni bilo mogoče najti. Povežite se z internetom.",
  Free:"prost",
  DB_Erase_Prompt_2:"Popolnoma izbrisati celotno zbirko podatkov razvijalcev? OPOZORILO: Izgubili boste vse podatke o NAKUPU in NAROČNINI!",
  Successfully_Imported:"Uspešno uvoženo",
  Select_Postage:"Izberite Poštnina",
  No_Postage:"Brez poštnih znamk",
  No_Paid_Postage_Word:"Dodajte prvo znamko <a routerLink='/folder/Postage/New'>Plačana Poštnina</a>.",
  Trial_Complete:'Preizkus končan',
  Please_Upgrade:'Za nadaljevanje tiskanja nadgradite čeke.',
  Aa:"Aa",
  Color:"Barva",
  Font:"Pisava",
  Guide:"Vodnik",
  Guides:"Vodniki",
  Image:"Slika",
  Zoom:"Povečava",
  Logo:"Logotip",
  Bank:"Banka",
  MICR:"MICR",
  Total:"Skupaj",
  Cancel:"Prekliči",
  Confirm:"Potrdi",
  Method:"Metoda",
  Biometric_Security:"Biometrična varnost",
  Please_Login_To_Continue:"Za nadaljevanje se prijavite.",
  Complete:"Dokončano",
  Sign_Up:"Prijavite se",
  Error:"Napaka",
  Biometrics:"Biometrika",
  Percent:"Odstotek",
  Zero_Percent:"0 %",
  Top_Margin:"Zgornja marža",
  Bottom_Margin:"Spodnji rob",
  Left_Margin:"Levi rob",
  Right_Margin:"Desni rob",
  MICR_Margin:"MICR marža",
  Table_Margin:"Marža tabele",
  Address_Margin:"Margina naslova",
  Percentage_:"Odstotek",
  Vacation_Title:"Naslov počitnic",
  Use_PIN:"Uporabi PIN",
  Loading__:"Nalaganje...",
  Design_Title:"Naslov oblikovanja",
  Authorize:"Pooblasti",
  Key:"Ključ",
  Public_Key:"Javni ključ",
  Private_Key:"Zasebni ključ",
  Authenticate:"Preveri pristnost",
  Last_Payroll:"Zadnja plačilna lista",
  Last_Calculation:"Zadnji izračun",
  Authorized:"Pooblaščeno",
  Geo_Authorized:"Geografska lokacija: pooblaščeno",
  Not_Authorized:"Ni pooblaščeno",
  Authorization_Complete:"Avtorizacija končana",
  Geolocation_Authorization:"Pooblastilo za geografsko lokacijo",
  Out_of_Bounds:"Izven meja",
  Cant_Delete_Default_Design:"Privzetega dizajna ni mogoče izbrisati.",
  Unauthorized_Out_of_Bounds:"Nepooblaščeno: izven meja",
  Biometric_Authorization:"Biometrična avtorizacija",
  Locating_Please_Wait:"Iskanje, počakajte ...",
  Test_Biometrics:"Preizkus biometričnih podatkov",
  Cheque_Margins:"Preverite robove",
  Percentage_Full_Error:"Polja za odstotek ni mogoče nastaviti na več kot 100 % odstotkov.",
  No_Payroll_Text:"Dodajte <a routerLink='/folder/Employee/New'>Zaposleni</a> ali <a routerLink='/folder/Payee/New'>Prejemnika Plačila</a> in <a routerLink='/folder/Schedule/New'>Urnik</a>.",
  Design_Saved:"Dizajn shranjen",
  Default_Design_Selected:"Izbrana je privzeta zasnova",
  Partial_Import:"Delni uvoz",
  Partial_Export:"Delni izvoz",
  Entire_Import:"Celoten uvoz",
  Entire_Export:"Celoten izvoz",
  Existing_Password:"Prosimo, vnesite svoje obstoječe geslo:",
  Existing_PIN:"Vnesite svojo obstoječo PIN kodo:",
  Pin_Change_Header:"Potrditev PIN-a",
  Pin_Change_SubHeader:"Vnesite svojo staro PIN številko, da potrdite spremembo.",
  Pass_Change_Header:"Potrditev gesla",
  Pass_Change_SubHeader:"Vnesite svoje staro geslo, da potrdite spremembo.",
  PIN_Enter_Message:"Vnesite PIN za potrditev.",
  Password_Enter_Message:"Vnesite svoje geslo za potrditev.",
  Pin_Updated_Success:"PIN je bil uspešno posodobljen!",
  Pin_Updated_Fail:"PIN ni bilo mogoče posodobiti.",
  Pass_Updated_Success:"Geslo je bilo uspešno posodobljeno.",
  Pass_Updated_Fail:"Gesla ni bilo mogoče posodobiti.",
  Preview_Payment:"Predogled plačila",
  Preview_Payroll:"Predogled plačilne liste",
  No_Payments_Created:"Ugotovljeno ni bilo, da bi bila ustvarjena nobena plačila.",
  Payment_Preview:"Predogled plačila",
  Enable_Payee:"Omogoči prejemnik plačila",
  Rendered:"Upodobljeno",
  No_Email:"Brez e-pošte",
  Setup_Cheques:"Preverjanja nastavitev",
  name:"ime",
  address:"naslov",
  address_2:"Naslovna vrstica 2",
  city:"mesto",
  province:"provinca",
  postal_code:"Poštna številka",
  country:"Država",
  username:"Uporabniško ime",
  full_name:"Polno ime",
  birthday:"rojstni dan",
  email:"E-naslov",
  phone:"Telefon",
  employees:"Zaposleni",
  addresses:"Naslovi",
  payment_amount_limit:"Omejitev zneska plačila",
  total_limit:"Skupna omejitev",
  payees:"Prejemniki plačil",
  description:"Opis",
  address_line_one:"Prva vrstica naslova",
  address_line_two:"Naslovna vrstica 2",
  image:"Slika",
  account_holder:"Imetnik računa",
  cheque_starting_id:"Preverite začetni ID",
  transit_number:"Tranzitna številka",
  institution_number:"Številka ustanove",
  designation_number:"Številka oznake",
  account_number:"Številka računa",
  currency:"valuta",
  signature:"Podpis",
  payment_payroll_limit:"Omejitev plačila",
  total_limi:"Skupna omejitev",
  date:"Datum",
  printed_memo:"Tiskana beležka",
  banks:"Banke",
  signature_image:"Podpisna slika",
  address_name:"Naslov Ime",
  notes:"Opombe",
  design:"Oblikovanje",
  title:"Naslov",
  frequency:"Frekvenca",
  fax:"faks",
  salaries:"Plače",
  salary_ids:"ID plače",
  start_time:"Začetni čas",
  end_time:"Končni čas",
  paid:"Plačano",
  overtime_percentage:"Plačani odstotek",
  overtime_amount:"Plačan fiksni znesek",
  first_name:"Ime",
  last_name:"Priimek",
  moderation:"Zmernost",
  create:"Ustvari",
  edit:"Uredi",
  destroy:"Uničiti",
  day_start_time:"Dan_začetek_ura",
  day_end_time:"Dan_end_time",
  fullname:"ime",
  time:"Čas",
  auto_send:"Samodejno pošlji",
  time_method:"Časovna metoda",
  schedules:"Urniki",
  indefinite_payroll_enabled:"Omogoči za nedoločen čas",
  amount:"Znesek",
  repeat:"Ponovi",
  always_enabled:"Vedno omogočeno",
  start_date:"Začetni datum",
  end_date:"Končni datum",
  Cheque_Total:"Preverite Skupaj",
  Total_Amount:"Skupni znesek:",
  Amounts:"Zneski:",
  Images:"Slike",
  Files:"Datoteke",
  Previewing:"Predogled:",
  Insert:"Vstavi",
  Preview_Import:"Predogled uvoza",
  Entry:"Vstop",
  Entries:"Vpisi",
  No_Entries:"Brez vnosov",
  Import_Type:"Vrsta uvoza",
  Select_Details:"Izberite Podrobnosti",
  Select_Payee:"Izberite Prejemnik plačila",
  Enable_Holidays:"Omogoči praznike",
  Disable_Holidays:"Onemogoči praznike",
  Wire_Transfer:"Bančno nakazilo",
  New_Export:"Nov izvoz",
  Export_Data:"Izvozi podatke",
  Export_Data_Word:"Izberite vrsto datoteke za izvoz in prenos.",
  Export_File:"Izvozi datoteko",
  Mode:"način",
  Times:"Časi",
  Widgets:"Pripomočki",
  Slider:"Drsnik",
  Set_Details:"Nastavite podrobnosti",
  Select_Type:"Izberite Vrsta",
  Display_Slider:"Prikaz drsnika",
  Dashboard_Slider:"Drsnik armaturne plošče",
  Dashboard_Mode:"Način nadzorne plošče",
  Show_Intro:"Pokaži uvod",
  Show_Payrolls:"Pokaži plačilne liste",
  Show_Holidays:"Pokaži praznike",
  Show_Invoices:"Pokaži račune",
  Show_Cheques:"Pokaži čeke",
  Enabled_Widgets:"Omogočeni pripomočki",
  Disabled_Widgets:"Onemogočeni pripomočki",
  Colors:"Barve",
  Barcodes:"Črtne kode",
  View_Timers:"Ogled časovnikov",
  Gradients:"Gradienti",
  No_Info:"Brez informacij",
  Disable:"Onemogoči",
  Show_Layer:"Pokaži plasti",
  Hide_Layer:"Skrij plasti",
  Text_Layer:"Besedilne plasti",
  Secondly:"Drugič",
  Minutely:"Na minuto",
  nine_am:"9:00 ZJUTRAJ",
  five_pm:"17.00",
  Enable_Address:"Omogoči naslov",
  Invalid_File_Type:"Oprostite, izbrana je bila neveljavna vrsta datoteke. Podprta vrsta datoteke:",
  Error_Updating_Entry:"Žal je prišlo do napake pri posodabljanju tega vnosa.",
  Schedule_Timing_Alert:"Napaka: začetni čas urnika je nastavljen na vrednost po končnem času.",
  Select_Multiple_Payments_Prompt:"Ni izbranih plačil. Izberite vsaj eno plačilo.",
  Select_Multiple_Cheques_Prompt:"Ni izbranih čekov. Izberite vsaj en ček.",
  Select_Multiple_Items_Prompt:"Ni izbranih elementov. Izberite vsaj en element.",
  Paymemt_Multiple_Delete_Confirmation:"Ali ste prepričani, da želite izbrisati več plačil?",
  Cheque_Multiple_Delete_Confirmation:"Ali ste prepričani, da želite izbrisati več pregledov?",
  Payee_Multiple_Delete_Confirmation:"Ali ste prepričani, da želite izbrisati več prejemnikov plačil?",
  Employee_Multiple_Delete_Confirmation:"Ali ste prepričani, da želite izbrisati več zaposlenih?",
  MICR_Warning:"Opomba: Nekateri tiskalniki in naprave morda ne bodo pravilno prikazali pisave MICR.",
  Automatically_Send:"Samodejno pošlji",
  Type:"Vrsta",
  Payment_Type:"Način plačila",
  Auto_Send:"Samodejno pošiljanje",
  Automatically_Process:"Samodejna obdelava",
  Auto_Process:"Samodejni proces",
  Image_Based:"Na podlagi slike",
  Font_Based:"Na podlagi pisave",
  Rerender:"Ponovno upodabljanje",
  Rendering:"Upodabljanje",
  Render:"Datoteke",
  Top:"Vrh",
  Middle:"Srednji",
  Bottom:"dno",
  Top_Left:"Zgoraj levo",
  Top_Center:"Zgornji center",
  Top_Right:"Zgoraj desno",
  Middle_Left:"Srednja leva",
  Middle_Center:"Srednji center",
  Middle_Right:"Srednje desno",
  Bottom_Left:"Spodaj levo",
  Bottom_Center:"Spodnji center",
  Bottom_Right:"Spodaj desno",
  Numbers:"Številke",
  Verified:"Preverjeno",
  Paper_Size:"Velikost papirja",
  New_Device:"Nova naprava",
  Add_Device:"Dodaj napravo",
  Remove_Device:"Odstranite napravo",
  Delete_Device:"Izbriši napravo",
  Block_Device:"Blokirana naprava",
  Block:"Blokiraj",
  Unblock:"Odblokiraj",
  Table:"Tabela",
  Scan_Login_Code:"Skeniraj prijavno kodo",
  Login_Code:"Koda za prijavo",
  Scan_Code:"Skeniraj kodo",
  Scan_QR_Code:"Skenirajte QR kodo",
  Select_All:"Izberi vse",
  Deselect_All:"Počisti vse",
  Increase:"Porast",
  Decrease:"Zmanjšaj",
  Bold:"Krepko",
  Text:"Besedilo",
  Style:"Slog",
  Italic:"Ležeče",
  QR_Code:"QR koda",
  Barcode:"Črtna koda",
  Browse_Images:"Brskanje po slikah",
  Browse_Files:"Prebrskaj datoteke",
  Background_Image:"Slika ozadja",
  Logo_Image:"Slika logotipa",
  Header_Image:"Slika glave",
  Bank_Image:"Slika banke",
  Cut_Lines:"Rezane črte",
  Background:"Ozadje",
  License:"Licenca",
  One_License:"1 licenca:",
  Licensed:"Licencirano za:",
  Not_Licensed:"Ni licencirano",
  Enter_Serial:"Vnesite serijsko",
  Serial_Key:"Serijski ključ",
  Serial:"Serijsko",
  Product_Key:"Ključ izdelka",
  Check_Product_Key:"Preverite ključ izdelka",
  Add_Product_Key:"Dodajte ključ izdelka",
  Verifying_Purchase:"Preverjanje nakupa ...",
  Print_Envelope:"Tiskanje ovojnice",
  Envelope:"Ovojnica",
  Thank_You:"Hvala vam!",
  Scale:"Lestvica",
  Print_Scale:"Tiskalna lestvica",
  Borders:"Meje",
  VOID:"NIČ",
  Void_Cheque:"Preverjanje neveljavnosti",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"PLAČAJTE PO NAROČILU:",
  NO_DOLLARS:"NI DOLAROV ",
  ONE_DOLLAR:"EN DOLAR",
  DOLLARS:" DOLARJI",
  AND:" IN ",
  CENTS:" CENTOV.",
  NO_:"NE ",
  ONE_:"ENA ",
  AND_NO_:"IN NE ",
  _AND_ONE_:"IN ENA ",
  DOLLARS_AND_ONE_CENT:" IN EN CENT.",
  AND_NO_CENTS:" IN NIČ CENTOV.",
  CHEQUE_PRINT_DATE:"DATUM:",
  CHEQUE_PRINT_MEMO:"OPOMBA:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"poslanec",
  Initial_Tasks:"Začetna opravila",
  Inital_Setup:"Začetna namestitev",
  Welcome_To:"Dobrodošli v ",
  Welcome:"Dobrodošli",
  Swipe:"Potegni",
  Intro_Setup:"Uvodna nastavitev",
  Introduction:"Uvod",
  CHEQUE_PRINT_CREDIT:"Poganjajo čeki",
  Title_Intro_Word:"Dobrodošli v čekih",
  Title_Intro:"Uvod v čeke",
  Title_Setup:"Nastavitev čekov",
  PayPal_Default_Email_Message:"Prejeli ste novo nakazilo PayPal. [Poganjajo Čeki]",
  Cheques_App_Export:"Izvoženo s čeki",
  Post_Intro_Thanks:"Hvala, ker ste izbrali Čeke. Postopek nastavitve je zdaj končan.",
  Post_Intro_Word:"Začnite tako, da natisnete svoj prvi ček, dodajte prihodnje plačilo ali dodajte zaposlene na plačilni seznam.",
  Upgrade_Required:"Čeki zahtevajo, da imate višjo različico programske opreme, da skrijete to sporočilo in odklenete dodatne funkcije.",
  Upgrade_Title:"čeki",
  Mailout_Prompt:"Poleg tega se lahko odločite, da vam Checks pošlje vaše čeke na plačilne liste po pošti.",
  Mailed_Cheque:"Pošt ček: ",
  Mailed_Cheque_Color:"Pošt ček (barva): ",
  Terms_Purchase:"Vse elektronske nakupe s čeki se v celoti povrnejo do 30 dni od datuma nakupa. Preden nadaljujete, preberite <a href='#'>Določila in pogoje</a> in se strinjate z njimi.",
  Dashboard_Setup:"Nastavitev primarnega tiskalnika",
  Dashboard_Add:"Dodaj primarni bančni račun",
  Dashboard_Customize:"Izberi predlogo za preverjanje",
  Dashboard_Job_Salary:"Ustvarite svoje delo in dodajte svojo plačo",
  Dashboard_Employees:"Sledenje zaposlenih in prejemnikov plačil",
  Dashboard_Print:"Natisnite in pošljite svoje plače",
  Dashboard_Payroll:"Avtomatizirajte tiskanje plačilnih listov",
  Dashboard_PayPal:"Nastavitev PayPal Payroll / Izplačila",
  Begin_Setup:"Začni nastavitev",
  Get_Started:"Začeti",
  Purchase:"Nakup",
  Lockdown:"Zaprtje",
  Unlock:"Odkleni",
  Detailed:"Podrobno",
  Log_In:"Vpiši se",
  Login:"Vpiši se",
  Launch:"Kosilo",
  Register:"Registracija",
  Finish:"Končaj",
  List:"seznam",
  Weekends:"Vikendi",
  Weekly:"Tedenski",
  PayPal_Default_Subject:"Novo plačilo",
  Payment_Message:"Sporočilo o plačilu",
  PayPal_Default_Payment_Note:"Hvala vam",
  Setup_Your_Cheqing_Account:"Čekovni račun",
  Add_Your_Primary_Cheqing_Account:"Dodaj svoj primarni čekovni račun.",
  Welcome_Word:"Poenostavite in avtomatizirajte upravljanje plač in človeških virov.",
  Get_Started_Quickly:"Samo odgovorite na nekaj preprostih vprašanj, ki nam bodo pomagala začeti ...",
  Done_Intro_Word:"Nastavitev končana",
  PIN_Protected:"Zaščiteno z geslom in PIN kodo",
  Enter_New_PIN:"Vnesite novo kodo PIN:",
  Enter_PIN:"Vnesite kodo PIN:",
  Invalid_PIN:"Vnešena je neveljavna koda PIN.",
  Account_Identifier:"Identifikator računa",
  New_Account_Identifier:"Nov identifikator računa",
  attempt:"poskus",
  attempts:"poskusi",
  remaining:"ostalo",
  Language:"jezik",
  languages:"Jeziki",
  select_languages:"Izberi jezik",
  Deposit:"Depozit",
  Hire_One_Now:"Najemi enega zdaj",
  App_Locked:"Aplikacija je zaklenjena.",
  Skip_:"Preskoči",
  Skip_to_Dashboard:"Preskoči na nadzorno ploščo",
  Dashboard:"Nadzorna plošča",
  Import:"Uvoz",
  Admin:"Administratorji",
  New_Admin:"Novi skrbnik",
  Settings:"Nastavitve",
  Color_Picker:"Izbirnik barv",
  Font_Picker:"Izbirnik pisav",
  Logout:"Odjava",
  Close:"Zapri",
  Close_menu:"Zapri",
  Excel:"Datoteka Excel",
  Csv:"Datoteka CSV",
  Sql:"Datoteka SQL",
  Json:"Datoteka JSON",
  Url:"Uvozi po URL-ju",
  Back:"Nazaj",
  Timers:"Časovniki",
  Cheque:"preveri",
  Print:"Natisni",
  Designs:"Oblikovanje",
  Pause_Printing:"Premor tiskanja",
  Resume_Printing:"Nadaljuj tiskanje",
  Printing_Paused:"Tiskanje zaustavljeno",
  PrintingUnavailable:"Oprostite! Tiskanje v tem sistemu ni na voljo.",
  Prints_Paused:"Natisi zaustavljeni",
  Administration:"uprava",
  Loading:"Nalaganje",
  Unnamed:"Neimenovani",
  error:"Tega preverjanja žal ni bilo mogoče odpreti za ogled.",
  No_Cheques_To_Print:"Brez čekov za tiskanje",
  No_Cheques_To_Print_Word:"Ustvari <a routerLink='/folder/Cheque/New'>Nov Ček</a>.",
  New_Cheque:"Nov ček",
  Start_One_Now:"Začni eno zdaj",
  Edit_Cheque:"Uredi ček",
  Select_Cheques:"Izberi čeke",
  Select_Employee:"Izberi zaposlenega",
  Default_Printer:"Privzeti tiskalnik",
  Reassign:"Predodeli",
  Configure:"Konfiguriraj",
  Template:"Predloga",
  Designer:"Oblikovalec",
  Edit_Designs:"Uredi načrte",
  New_Design:"Novi dizajn",
  Next:"Naslednji",
  Save:"Shrani",
  Name:"Ime",
  Mail:"pošta",
  Amount:"Znesek",
  Date:"Datum",
  PayPal:"PayPal",
  Payouts:"Izplačila",
  PayPal_Label:"PayPal Label",
  Email_Username:"E-pošta/uporabniško ime",
  Client_ID:"ID stranke",
  Sandbox_Email:"E-pošta peskovnika",
  PayPal_Email:"E-pošta PayPal",
  PayPal_Username:"Uporabniško ime za API",
  PayPal_Payouts:"PayPal Payouts",
  Select_PayPal_Key:"Izberi ključ PayPal",
  Secret:"Skrivnost",
  API_Secret:"Skrivnost API",
  PayPal_API_Keys:"Ključi PayPal",
  New_PayPal_Key:"Nov ključ PayPal",
  Email_Subject:"Zadeva e-pošte",
  Email_Message:"E-poštno sporočilo",
  Payout_Options:"Možnosti izplačil",
  Payment_Note:"Opomba za plačilo",
  Enable_Employee:"Omogoči zaposlenega",
  Enable_Production_Mode:"Omogoči produkcijski način",
  Sandbox_Username:"Uporabniško ime peskovnika",
  Sandbox_Signature:"Podpis peskovnika",
  Sandbox_Password:"Geslo za peskovnik",
  Production_Username:"Produkcijsko uporabniško ime",
  Production_Signature:"Produkcijski podpis",
  Production_Password:"Produkcijsko geslo",
  Production_Email:"E-pošta za proizvodnjo",
  API_Client_ID:"ID odjemalca API",
  API_Signature:"Podpis API",
  API_Password:"Geslo za API",
  API_Username:"Uporabniško ime za API",
  Secret_Key:"Skrivni ključ",
  Sandbox:"peskovnik",
  Production:"Proizvodnja",
  Sandbox_Keys:"Ključi peskovnika",
  Production_Keys:"Proizvodni ključi",
  API_Title:"Naslov API",
  Production_Mode:"Produkcijski način",
  Account_Label:"Oznaka računa",
  No_PayPal_Setup:"Brez PayPal ključa",
  Enable_PayPal_Account:"Omogoči račun PayPal",
  No_PayPal_Word:"Dodajte svoj <a routerLink='/folder/Invoice/New'>Ključ API PayPal</a>.",
  Printed_Memo:"Tiskani zapisek",
  Employee:"zaposleni",
  View_Employee:"Ogled zaposlenega",
  Mailing_Address:"Poštni naslov",
  Company_Address:"Naslov podjetja",
  Select_Company_Address:"Izberi naslov podjetja",
  Address:"naslov",
  Any_Day:"Katerikoli dan",
  Address_Name:"Ime naslova",
  Unit:"enota",
  Account:"Račun",
  Bank_Account:"Bančni račun",
  Account_Limits:"Omogoči omejitve računa",
  Payroll:"Plača",
  New_Payroll:"Nova plačilna lista",
  No_Payroll:"Ni prihajajoče plačilne liste",
  Upcoming_Holiday:"Prihajajoči prazniki:",
  Invoice_Due:"Pravi račun:",
  New_Invoice:"Nov račun",
  No_Invoices:"brez računov",
  No_Invoices_Word:"Ustvarite prvi <a routerLink='/folder/Invoice/New'>Račun</a>.",
  Cheque_Due:"Ček zapade:",
  Payrolls:"Plačne liste",
  Sandbox_Mode:"Način peskovnika",
  Hire:"Najem",
  Pay:"Plačaj",
  New:"Novo",
  Add:"Dodaj",
  Make:"Naredi",
  Time:"čas",
  Write:"Piši",
  Holiday:"Praznik",
  Holidays:"Prazniki",
  Next_Holiday:"Naslednji praznik:",
  All_Done:"Končano!",
  Employees:"Zaposleni",
  Payees:"Prejemniki plačila",
  Job:"Job",
  Jobs:"Službe",
  Invoice:"Račun",
  Invoices:"Računi",
  Vacations:"počitnice",
  Cheques:"Čeki",
  Brand_Cheques:"blagovna znamka",
  Payment:"plačilo",
  Enable_Payment:"Omogoči plačilo",
  Payments:"plačila",
  Schedule:"Urnik",
  Schedules:"Urniki",
  Timesheet:"Časovni list",
  Timesheets:"Časovniki",
  Salary:"plača",
  New_Address:"Nov naslov",
  Address_2:"Naslov (vrstica 2)",
  _City:"mesto",
  _State:"država/prov",
  City:"Mesto/okrožje",
  State:"država/pokrajina",
  Postal:"Poštna številka",
  ZIP:"Pošta / ZIP",
  Country:"Država",
  Addresses:"Naslovi",
  Required_Options:"Zahtevane možnosti",
  Optional_Options:"Neobvezne možnosti",
  Additional_Options:"Dodatne možnosti",
  Required:"Obvezno",
  Optional:"Neobvezno",
  Additional:"Dodatno",
  No_Addresses:"Brez naslovov",
  New_Address_Word:"Dodaj prvi <a routerLink='/folder/Address/New'>Naslov</a>.",
  Select_Address:"Izberi naslov",
  No_Address:"Brez naslovov",
  Print_Cheque:"Natisni ček",
  Print_Cheque_Now:"Natisni preveri zdaj",
  Description:"Opis",
  Pay_To_The_Order_Of:"Plačaj po naročilu:",
  Select_Date_Range:"Izberi časovno obdobje",
  Select_Starting_Date:"Izberi začetni datum",
  Select_Ending_Date:"Izberi končni datum",
  Select_Date:"Izberi datum",
  Cheque_Date:"Datum preverjanja",
  Cheque_Memo:"Preveri beležko",
  Blank_Cheque:"Prazen ček",
  Blank:"Prazno",
  No_Cheques:"brez čekov",
  No_Cheques_Word:"Natisnite svoj prvi <a routerLink='/folder/Cheque/New'>Ček</a>.",
  Cheque_Amount_Placeholder:"0,00 $",
  View_Image:"Ogled slike",
  View:"Pogled",
  Modify:"Spremeni",
  Delete:"Izbriši",
  Cheque_Paid:"Plačano",
  New_Deduction:"Novi odbitek",
  Title:"Naslov",
  Frequency:"Frekvenca",
  Hourly:"na uro",
  Daily:"Dnevno",
  Weekdays:"tednik",
  BiWeekly:"2 tedna",
  TriWeekly:"3 tedni",
  Monthly:"Mesečno",
  MiMonthly:"2 meseca",
  Quarterly:"Četrtletnik",
  Yearly:"letno",
  Every_Week:"Vsak teden",
  Every_Payroll:"Vsaka plačilna lista",
  Every_Month:"Vsak mesec",
  Annually:"letno",
  Always_Scheduled:"Vedno načrtovano",
  Start_Date:"Začetni datum",
  End_Date:"Končni datum",
  Start_Time:"Začetni čas",
  End_Time:"Končni čas",
  Deduction_Label:"Oznaka odbitka",
  Notes:"Opombe",
  Options:"Opcije",
  Enable:"Omogoči",
  Select_Deductions:"Izberi odbitke",
  Deductions:"Odbitki",
  No_Deductions:"Brez odbitkov",
  No_Deductions_Word:"Ustvarite svoj prvi <a routerLink='/folder/Deduction/New'>Odbitek</a>.",
  New_Employee:"Novi zaposleni",
  No_Title:"Brez naslova",
  _Name:"Ime",
  About_Yourself:"O sebi",
  Full_Name:"Polno ime",
  Birthday:"rojstni dan",
  Email:"E-naslov",
  SMS:"SMS",
  Phone:"telefon",
  Test:"Test",
  Call:"Pokliči",
  Fax:"faks",
  Printed_Note:"Tiskana opomba",
  Position:"Položaj",
  Job_Position:"Delovno mesto",
  Select_a_Job:"Izberi službo",
  Select_a_Salary:"Izberite plačo",
  Add_a_Deduction:"Dodaj odbitek",
  Taxes:"davki",
  Add_Taxes:"Dodaj davke",
  Date_of_Birth:"Datum rojstva",
  Email_Address:"Email naslov",
  Phone_Number:"Telefonska številka",
  Phone_Call:"Telefonski klic",
  Enable_on_Payroll:"Omogoči na plačilni listi",
  Select_Employees:"Izberi zaposlene",
  No_Employees:"Brez zaposlenih",
  No_Employees_Word:"Dodajte svojega prvega novega <a routerLink='/folder/Employee/New'>Zaposleni</a>.",
  No_Name:"Brez imena",
  Unemployeed:"Brezposelni",
  Employeed:"Zaposlen",
  Paid:"Plačano",
  Enabled:"Omogočeno",
  Disabled:"Onemogočeno",
  Fire:"ogenj",
  Not_Available:"Ni na voljo",
  Not_Available_Word:"Natisnite svoj prvi <a routerLink='/folder/Invoice/New'>Račun</a> in prejmite plačilo.",
  Select_Invoices:"Izberi_račune",
  Print_Invoice_Word:"Natisnite svoj prvi <a routerLink='/folder/Invoice/New'>Račun</a> in prejmite plačilo.",
  Invoice_Title:"Naslov računa",
  Invoice_Date:"Datum računa",
  Due_Date:"Rok",
  New_Job:"Nova služba",
  Details:"Podrobnosti",
  Customize:"Prilagoditi",
  Customize_Dashboard:"Prilagodi nadzorno ploščo",
  Components:"komponente",
  No_Components:"Brez komponent",
  Main_Components:"Glavne komponente",
  Smaller_Components:"Manje komponente",
  Error_Loading_Page:"Napaka pri nalaganju te strani.",
  Bank_Details:"Bančne podrobnosti",
  About_Your_Job:"O vašem delu",
  Your_Schedule:"Vaš urnik",
  Job_Title:"Naziv delovnega mesta",
  Job_Description:"Opis dela",
  Job_Details:"Podrobnosti o zaposlitvi",
  Enable_Job:"Omogoči opravilo",
  Pay_Period:"Plačilno obdobje",
  Perpetually_Schedule:"Večni urnik",
  Select_Jobs:"Izberi delovna mesta",
  No_Jobs:"Brez delovnih mest",
  Add_Jobs:"Dodaj delovna mesta",
  No_Jobs_Word:"Na seznam dodajte prvo <a routerLink='/folder/Job/New'>Delo</a>.",
  Count_Employees:"job.employee_count+' Employees'",
  Zero_Employees:"0 zaposlenih",
  New_Leave:"Novi dopust",
  Leave_Name:"Zapusti ime",
  Paid_Leave:"Plačani dopust",
  Leave_Pay:"Pusti plačilo",
  Indefinite_Leave:"Dopust za nedoločen čas",
  Indefinite_Payroll:"Neomejeno plačo",
  Leave:"odpusti",
  Add_Schedules:"Dodaj urnike",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Omogoči odsotnost",
  Select_Leaves:"Izberi liste",
  No_Leaves:"Brez odsotnosti",
  Leave_Of_Absence:"Odsotnost",
  Leaves_Of_Absence:"Odsotnost odsotnosti",
  New_Leave_of_Absense:"Nova odsotnost",
  No_Leaves_Word:"Dodaj prvi <a routerLink='/folder/Leave/New'>Odsotnost</a>.",
  Not_Enabled:"Ni omogočeno",
  Absences:"odsotnosti",
  Payee:"Prejemnik plačila",
  New_Payee:"Novi prejemnik plačila",
  Payee_Identifier:"Identifikator prejemnika plačila",
  Payee_Name:"Ime prejemnika plačila",
  Payee_Title:"Naslov prejemnika plačila",
  Payment_Memo:"Plačilni zapisnik",
  Select_Payees:"Izberi prejemnike plačil",
  No_Payees:"Brez prejemnikov plačil",
  Add_Payee_Note:"Dodaj prvega <a routerLink='/folder/Payee/New'>Prejemnika Plačila</a>.",
  New_Payees:"Novi prejemniki plačil",
  About_The_Payment_Schedule:"Urnik plačila",
  Your_Payroll_Schedule:"Samodejni obračun plač",
  New_Payment:"Novo plačilo",
  Identifier:"identifikator",
  Select:"Izberi",
  Memo:"Beležka",
  Payment_Date:"Dan plačila",
  Mark_as_Paid:"Označi kot plačano",
  Select_Payments:"Izberi plačila",
  No_Payments:"brez plačil",
  No_Payments_Word:"Ustvari prvo <a routerLink='/folder/Payment/New'>Plačilo</a>.",
  Create_Payroll:"Ustvari plačilni list",
  Properties:"Lastnosti",
  Payroll_Title:"Naslov plače",
  Payroll_Notes:"Beležke o plačilu",
  Payroll_Setup:"Nastavitev plače",
  Tabulate_Payments:"Tabela plačil",
  Tabulate:"Tabela",
  By:"Od:",
  Payments_By:"Plačila do",
  Timesheets_And_Schedules:"Časovniki in urniki",
  Both:"Oboje",
  Items:"predmeti",
  Add_Payees:"Dodaj prejemnike plačil",
  Add_Account:"Dodaj račun",
  Enable_Account:"Omogoči račun",
  Enable_Payroll:"Omogoči obračun plač",
  Print_Payroll:"Natisni plačilni list",
  No_Payrolls:"brez plače",
  No_Payroll_Word:"Ustvarite svoj prvi <a routerLink='/folder/Payroll/New'>Plačilna Lista</a>.",
  View_more:"POGLEJ VEČ",
  Less:"MANJ",
  Add_Payroll:"Dodaj plačo",
  Select_Payroll:"Izberi obračun plač",
  About_Your_Salary:"O vaši plači",
  Add_Salaries:"Dodaj plače",
  Add_Salary:"Dodaj plačo",
  Salaries:"Plače",
  No_Salaries:"brez plač",
  No_Salaries_Word:"Dodaj prvo <a routerLink='/folder/Salary/New'>Plačo</a>.",
  Select_Salaries:"Izberite plače",
  New_Salary:"Nova plača",
  Salary_Name:"Identifikator plače",
  Enable_Salary:"Omogoči plačo",
  Salary_Amount:"Znesek plače",
  New_Schedule:"Nov urnik",
  Schedule_Title:"Naslov razporeda",
  Add_Address:"Dodaj naslov",
  Repeat:"ponovi",
  Design:"Oblikovanje",
  Edit_Design:"Uredi dizajn",
  Edit_this_Design:"Uredi ta dizajn",
  Repeat_Payment:"ponovno plačilo",
  Enable_Schedule:"Omogoči urnik",
  Never:"Nikoli",
  Select_Schedule:"Izberi urnike",
  No_Schedules:"Brez urnikov",
  No_Schedules_Word:"Ustvari prvi <a routerLink='/folder/Schedule/New'>Urnik</a>.",
  New_Administrator:"Novi skrbnik",
  Username:"Uporabniško ime",
  First_Name:"Ime",
  Last_Name:"Priimek",
  Add_an_Address:"Dodaj naslov",
  Payment_Limit:"Omejitev na plačilo",
  Total_Limit:"skupna omejitev",
  Select_Accounts:"Izberi račune",
  No_Administrators:"Brez skrbnikov",
  No_Administrators_Word:"Ustvarite prvi <a routerLink='/folder/Administrator/New'>Skrbniški Račun</a>.",
  New_Administrators_Word:"Dodaj prvega <a routerLink='/folder/Administrator/New'>Administrator</a>",
  Cloud:"oblak",
  Backup:"Rezerva",
  Enable_iCloud:"Omogoči iCloud",
  Enable_Google_Drive:"Omogoči Google Drive",
  Enable_Microsoft_OneDrive:"Omogoči Microsoft OneDrive",
  Automatically_Backup:"Samodejno varnostno kopiranje",
  FTP_Settings:"Nastavitve FTP",
  URL_File_Prompt:"Prosimo, določite lokacijo za uvoz datoteke .xls / .xlsx / .json:",
  URL_SQL_Prompt:"Prosimo, določite lokacijo datoteke SQLite za uvoz:",
  FTP_Backup:"Varnostno kopiranje FTP",
  FTP_Import:"FTP uvoz",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"gostitelj",
  Port:"pristanišče",
  Path:"pot",
  Data_Path:"Podatkovna pot",
  Enable_FTP_Account:"Omogoči račun FTP",
  HostnameIP:"Ime gostitelja",
  Password:"Geslo",
  Connection_Port:"Priključna vrata",
  Enable_MySQL_Database:"Omogoči bazo podatkov MySQL",
  Log:"Dnevnik",
  Reset:"Ponastaviti",
  Erase:"Izbriši",
  Export:"izvoz",
  Database:"Baza podatkov",
  Upload_CSV:"Naloži CSV",
  Download_CSV:"Prenesi CSV",
  SQL_Database:"URL SQL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Varnostna kopija MySQL",
  Internal_Notes:"Notranji zapiski",
  Root_Path:"Korenska pot",
  Select_Backup:"Izberi varnostno kopiranje",
  Add_New_Backup:"Dodaj novo varnostno kopijo",
  First_Backup:"Nastavitev prve varnostne kopije ...",
  Backups:"Varnostne kopije",
  Add_Backup:"Dodaj varnostno kopijo",
  No_Backups:"Varnostnih kopij ni mogoče najti.",
  Select_Backup_Type:"Izberite vrsto varnostne kopije, ki jo želite nastaviti ...",
  Backup_Type:"Vrsta varnostne kopije",
  FTP_Drive:"FTP pogon",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"vozi",
  Microsoft_OneDrive:"vozi",
  Import_Fields:"Uvozna polja",
  Import_Fields_Word:"S tem razdelkom izberite <a routerLink='/folder/Holiday/New'>Uvozi</a> podatke.",
  Upload:"Naloži",
  Download:"Prenesi",
  Download_JSON:"Prenesi kot podatke JSON",
  Download_SQL:"Prenesi kot datoteko SQL",
  New_Bank:"Nova banka",
  New_Account:"Nov račun",
  New_Bank_Account:"Novi bančni račun",
  Upload_Image:"Naloži sliko",
  Bank_Name:"Ime banke",
  Bank_Address:"Naslov banke",
  Branch_Address:"Naslov podružnice",
  Address_on_Cheque:"naslov",
  Cheque_Numbers:"Preverite številke",
  Cheque_Details:"Preveri podrobnosti",
  Bank_Logo:"Logotip banke",
  Cheque_ID:"Preveri ID",
  Starting_Cheque_ID:"ID začetnega preverjanja",
  Transit_Number:"Tranzitna številka",
  Institution_Number:"Številka ustanove",
  Designation_Number:"Označna številka",
  Account_Number:"Številka računa",
  Limits:"Meje",
  Default_Limits:"Privzete omejitve",
  Over_Limit:"preko omejitve",
  Under_Limit:"Pod mejo",
  Payroll_Limit:"Omejitev plače",
  Enable_Bank_Account:"Omogoči bančni račun",
  Select_Account:"Izberi račun",
  No_Bank_Account:"Brez bančnega računa",
  No_Bank_Account_Word:"Dodaj prvi <a routerLink='/folder/Accounts/New'>Bančni Račun</a>.",
  Bank_Accounts:"Bančni računi",
  No_Accounts:"Brez računov",
  No_Accounts_Note:"Dodaj prvi <a routerLink='/folder/Accounts/New'>Bančni Račun</a>.",
  Cheque_Designer:"Preveri oblikovalca",
  Cheque_Design:"Preveri dizajn",
  Select_Design:"Izberi dizajn",
  Cheque_Designs:"Preveri modele",
  No_Cheque_Designs:"Brez čekovnih modelov",
  No_Cheque_Designs_Word:"Ustvarite svoj <a routerLink='/folder/Settings/Cheque/Design/New'>Preverite Oblikovanje</a>.",
  Set_Default:"Nastavi na privzeto",
  Default:"Privzeto",
  Remove:"Odstrani",
  Folder:"Mapa",
  Edit:"Uredi",
  LoadingDots:"Nalaganje...",
  Add_a_New_File:"Dodaj <a href='#' (klik)='add()'>Novo Datoteko</a> v",
  this_folder:"ta mapa",
  FTP_Backup_Settings:"Nastavitve varnostnega kopiranja FTP",
  Secure_File_Transfer:"varen prenos datotek",
  New_Holiday:"Novi prazniki",
  Add_Holiday:"Dodaj praznik",
  Holiday_Name:"Ime praznika",
  Additional_Pay:"dodatno plačilo",
  Enable_Holiday:"Omogoči počitnice",
  Select_Holidays:"Izberi praznike",
  No_Holidays:"Brez praznikov",
  No_Holidays_Word:"Dodaj prvi <a routerLink='/folder/Holiday/New'>Praznik</a>.",
  New_Import:"Nov uvoz",
  Import_Data:"Uvozi podatke",
  Import_Data_Word:"Izberite vrsto datoteke ali način nalaganja po vaši izbiri.<br /> Po nalaganju podprte datoteke boste lahko izbrali katera koli uvožena polja v datoteki ustrezajo kateremu koli parametru v aplikaciji.", 
  Import_File:"Uvozi datoteko",
  Link_To_File:"Povezava do datoteke",
  Select_File:"Izberi datoteko",
  New_Moderator:"Novi moderator",
  Allow_Moderation:"Dovoli moderiranje",
  Create_Paycheques:"Ustvari plače",
  Edit_Paycheques_and_Data:"Uredi plače in podatke",
  Destroy_Data_and_Paycheques:"Uniči podatke in plače",
  Bonus_Percentage:"Odstotek plače",
  Fixed_Amount:"fiksni znesek",
  Select_Moderator:"Izberi moderatorja",
  No_Moderators:"Brez moderatorjev",
  Moderators:"Moderatorji",
  Moderator_Account:"Ustvarite prvi <a routerLink='/folder/Administrator/New'>Moderator Račun</a>.",
  No_Moderators_Word:"Dodaj prvega <a routerLink='/folder/Administrator/New'>Moderatorja</a>.",
  Defaults:"Privzete nastavitve",
  Provide_Us_Info:"Zagotovite nam informacije",
  Setup_Your_Printer:"Nastavitev tiskalnika",
  Your_Company:"O vašem podjetju",
  Company_Name:"Ime podjetja",
  Currency:"valuta",
  Default_Currency:"Privzeta valuta",
  Base_Monthly_Income:"Mesečni prihodek",
  Protection:"Zaščita",
  App_Protection:"Zaščita aplikacij",
  PIN_Code_Protection:"Zaščita kode PIN",
  App_Protection_Word:"Omogoči varnostne metode, ki pomagajo zaščititi vaš račun.",
  PIN_Code:"PIN koda",
  Change_PIN:"Spremeni PIN",
  New_Password:"Novo geslo",
  Geofence_Protection:"Zaščita geo-ograj",
  Geofence_Area:"Nastavljeno območje",
  Distance:"Razdalja",
  Setup_Now:"Nastavi zdaj",
  Mile:"Mile",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Prepoznavanje obrazov",
  Face:"Obraz",
  Setup:"Nastaviti",
  Label:"oznaka",
  Password_Protection:"Zaščita z geslom",
  Modify_Password:"Spremeni geslo",
  New_Tax_Entry:"Nov vnos davka",
  New_Tax:"Novi davek",
  Tax_Label:"Davčna oznaka",
  Perpetually_Enabled:"trajno omogočeno",
  Select_Taxes:"Izberi davke",
  Tax_Deductions:"davčne olajšave",
  No_Tax_Deductions:"Brez davčnih olajšav",
  No_Tax_Deductions_Word:"Dodaj prvi odbitek <a routerLink='/folder/Tax/New'>Davčni</a> odbitek.",
  New_Timer:"Novi merilnik časa",
  Start:"Začni",
  Stop:"ustavi",
  Start_Timer:"Začni časovnik",
  Stop_Timer:"Ustavi časovnik",
  Timer_Active:"Timer Active",
  Timer:"Časovnik:",
  Timer_Running:"Časovnik: (teče)",
  Save_Timer:"Shrani časovnik",
  New_Timesheet:"Nov časovni list",
  Select_Timesheets:"Izberi urnike",
  Work_Notes:"Delovni zapiski",
  Entry_Title:"Naslov vpisa",
  No_Timesheets:"Brez časovnih listov",
  No_Timesheets_Word:"Dodaj prvi <a routerLink='/folder/Timesheet/New'>Časovni List</a>.",
  Timesheet_Submitted:"Časovni list oddan",
  Timesheet_Congrats:"Čestitamo! Vaš časovni list je bil uspešno oddan. Hvala!",
  Timesheet_Copy:"Če želite prejeti kopijo vaših dokumentov, nam sporočite svoj elektronski naslov in/ali številko mobilnega telefona.",
  Submit:"Pošlji",
  Allow_Notifications:"Dovoli obvestila",
  Untitled_Entry:"Nov vnos",
  Untitled_Bank:"Banka brez naslova",
  Timesheet_Entry:"Vnos časovnega lista",
  Work_Description:"Opis dela",
  Enable_Timesheet:"Omogoči časovni list",
  Submit_Timesheet:"Pošlji časovni list",
  Vacation:"počitnice",
  New_Vacation:"Nove počitnice",
  Vacation_Name:"Ime počitnic",
  Paid_Vacation:"plačan dopust",
  Vacation_Pay:"Plačalo za dopust",
  Enable_Vacation:"Omogoči počitnice",
  Select_Vacations:"Izberi počitnice",
  No_Vacations:"Brez počitnic",
  No_Vacations_Word:"Ustvarite prvi vnos <a routerLink='/folder/Vacation/New'>Počitnice</a>.",
  Payroll_Schedule:"Razpored plač",
  Next_Payroll:"Naslednja plačilna lista:",
  Upcoming_Payroll:"Prihajajoči obračun plač",
  No_Upcoming_Payroll:"Ni prihajajoče plačilne liste",
  Address_Book:"Imenik",
  Archived_Documents:"Arhivirani dokumenti",
  Dev_Mode:"Aplikacija v razvojnem načinu",
  Administrators:"Administratorji",
  Privacy:"Zasebnost",
  None:"Nobena",
  Select_Signature:"Izberi podpis",
  No_Signatures:"Brez podpisov",
  No_Signatures_Word:"Dodaj prvi <a routerLink='/folder/Signature/New'>Podpis</a>.",
  Repeat_Indefinitely:"Ponavljaj neomejeno",
  Sign:"podpis",
  Sign_Here:"Podpiši se tukaj",
  Date_Signed:"Datum podpisa",
  Signature_Pad:"Podpisna ploščica",
  Account_Holder:"Imetnik računa",
  Account_Properties:"Lastnosti računa",
  Name_On_Cheque:"Ime na čeku",
  Server_Hostname:"Ime gostitelja strežnika/IP",
  Printers:"Tiskalniki",
  No_Printers:"Brez tiskalnikov",
  Printer_Exists:'Tiskalnik s tem imenom že obstaja.',
  No_Printers_Word:"Dodaj prvi <a routerLink='/folder/Printer/New'>Tiskalnik</a>.",
  No_Printer_Alert:"Noben tiskalnik ni definiran. Ali želite nastaviti tiskalnik?",
  Add_Printer:"Dodaj tiskalnik",
  New_Printer:"Novi tiskalnik",
  Printer_Hostname:"Ime gostitelja tiskalnika/IP",
  Printer_Label:"Nalepka tiskalnika",
  Printer_Protocol:"Protokol tiskalnika",
  Protocol:"protokol",
  Email_Print:"E-naslov",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"vtičnica",
  Print_Job:"Tiskanje",
  Printed:"natisnjeno",
  Not_Printed:"Ni natisnjeno",
  Print_Jobs:"tiskalna opravila",
  Print_Queue:"Čakalna vrsta za tiskanje",
  No_Print_Jobs:"Brez tiskarskih opravil",
  No_Prints:"Ustvarite nov <a routerLink='/folder/Cheque/New'>Ček</a> za tiskanje.",
  Prints:"Natisi",
  Find_Printer:"Najdi tiskalnik",
  Find_AirPrint_Devices:"Poišči naprave AirPrint",
  Select_Printer:"Izberi tiskalnik",
  System_UI:"System UI",
  Printer:"tiskalnik",
  Status:"Stanje",
  Preview:"Predogled",
  Enable_Print_Job:"Omogoči tiskalno opravilo",
  Queue_Weight:"Teža čakalne vrste",
  Unlimited:"Neomejeno",
  Show_Advanced_Printer_Options:"Pokaži napredne možnosti tiskalnika",
  Advanced:"Napredno",
  Location:"Lokacija",
  Note:"Opomba",
  Queue_Name:"Ime čakalne vrste",
  Pages_Printed_Limit:"Omejitev natisnjenih strani",
  MultiPage_Idle_Time:"Čakalni čas za več strani (s)",
  Page_Count_Limit:"Omejitev števila strani",
  Page_Orientation:"Orientacija strani",
  Portrait:"Portret",
  Landscape:"Pokrajina",
  Duplex:"Duplex",
  Double_Sided_Printing:"Dvostranski",
  Duplex_Mode:"Duplex Mode",
  Duplex_Short:"Kratek",
  Duplex_Long:"Dolga",
  Duplex_None:"Nobena",
  Color_And_Quality:"Barva in kakovost",
  Monochrome:"Enobarvno",
  Photo_Quality_Prints:"Kakovost fotografij",
  Printer_Email:"E-pošta tiskalnika",
  Automatically_Downsize:"Samodejno zmanjšanje",
  Paper:"papir",
  Paper_Name:"Ime papirja",
  Paper_Width:"Širina papirja",
  Paper_Height:"Višina papirja",
  Paper_Autocut_Length:"Dolžina samodejnega reza papirja",
  Margins:"Marže",
  Page_Margins:"Robovi strani",
  Page_Margin_Top:"Rob zgornje strani",
  Page_Margin_Right:"Desni rob strani",
  Page_Margin_Bottom:"Spodnji rob strani",
  Page_Margin_Left:"Levi rob strani",
  Add_Employees:"Dodaj zaposlene",
  Header:"Glava",
  Print_A_Page_Header:"Natisni glavo strani",
  Header_Label:"Oznaka glave",
  Header_Page_Index:"Indeks naslovne strani",
  Header_Font_Name:"Pisava glave",
  Select_Font:"Izberi pisavo",
  Font_Selector:"Izbirnik pisave",
  Header_Font_Size:"Pisava glave",
  Header_Bold:"glava krepko",
  Header_Italic:"Ležeče glave",
  Header_Alignment:"Poravnava glave",
  Left:"levo",
  Center:"Center",
  Right:"Prav",
  Justified:"upravičeno",
  Header_Font_Color:"Barva glave",
  Select_Color:"Izberi barvo",
  Footer:"Noga",
  Print_A_Page_Footer:"Natisni nogo strani",
  Footer_Label:"Oznaka noge",
  Footer_Page_Index:"Indeks strani v nogi",
  Footer_Font_Name:"Pisava noge",
  Fonts:"Pisave",
  Done:"Končano",
  Select_Fonts:"Izberi pisave",
  Footer_Font_Size:"Velikost noge",
  Footer_Bold:"Odebeljeno nogo",
  Footer_Italic:"Footer Italic",
  Footer_Alignment:"Poravnava noge",
  Footer_Font_Color:"Barva noge",
  Page_Copies:"Kopije strani",
  Enable_Printer:"Omogoči tiskalnik",
  Remote_Logging:"Oddaljeno beleženje",
  Log_Server:"Log Server",
  Encryption:"Šifriranje",
  Random_Key:"Naključni ključ",
  Encryption_Key:"Ključ za šifriranje",
  Cheques_Webserver:"Baza podatkov po meri",
  Learn_How:"Nauči se kako",
  Signature:"Podpis",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Ogled podpisa",
  Printed_Signature:"Natisnjen podpis",
  Digitally_Sign:"Digitalno podpisovanje",
  Digital_Signature:"Digitalni podpis",
  Add_Signature:"Dodaj podpis",
  Add_Your_Signature:"Dodaj svoj podpis",
  Enable_Signature:"Omogoči podpis",
  Digitally_Signed:"Digitalno podpisano",
  Insert_Error:"Ček ni mogoče shraniti zaradi težav z bazo podatkov.",
  Delete_Confirmation:"Ali ste prepričani, da želite izbrisati te podatke?",
  Discard_Confirmation:"Ali ste prepričani, da želite zavreči te podatke?",
  Discard_Bank_Confirmation:"Ali ste prepričani, da želite zavreči ta račun?",
  Discard_Printer_Confirmation:"Ali ste prepričani, da želite zavreči ta tiskalnik?",
  Delete_Bonus_Confirmation:"Ali ste prepričani, da želite izbrisati ta bonus?",
  Delete_Invoice_Confirmation:"Ali ste prepričani, da želite izbrisati ta račun?",
  Generated_Cheque:"Ustvarjen ček",
  Generated_Invoice:"Ustvarjen račun",
  Schedule_Start_Time:"Začetek urnika",
  Schedule_End_Time:"Konec razporeda",
  New_Call:"Nov klic",
  No_Contacts:"Brez stikov",
  No_Contacts_Word:"Skrbniki, moderatorji, zaposleni in prejemniki plačil so prikazani kot stiki.",
  PDF_Subject:"finance",
  PDF_Keywords:"plačilni pregledi PDF čeki za preverjanje plače",
  Printer_Setup:"Nastavitev tiskalnika",
  Printer_Selection:"Izbira tiskalnika",
  New_Fax:"Novi faks",
  New_Fax_Message:"Novo sporočilo faksa",
  Fax_Machine:"Faks",
  Fax_Name:"Ime faksa",
  Fax_Email:"E-pošta faksa",
  Fax_Number:"Številka faksa",
  Contents:"vsebina",
  Fax_Body:"Telo strani",
  Header_Word:"Glava:",
  Header_Text:"Besedilo glave",
  Include_Header:"Vključi glavo",
  Include_Footer:"Vključi nogo",
  Footer_Word:"Noga:",
  Footer_Text:"Besedilo v nogi",
  Attach_a_Cheque:"Priložite ček",
  Add_Deduction:"Dodaj odbitek",
  Enable_Fax:"Pošlji faks",
  Select_Fax:"Izberi faks",
  No_Faxes:"Brez faksov",
  Faxes:"faksi",
  Save_and_Send:"Pošlji faks",
  Save_and_Pay:"Shrani in plačaj",
  WARNING:"OPOZORILO:",
  Remember:"Zapomni si",
  Printing:"Tisk",
  Printing_Complete:"Tiskanje končano!",
  of:"od",
  There_Were:"Tam so bili ",
  Successful_Prints:"uspešni natisi in ",
  Unsuccessful_Prints:"neuspešni natisi.",
  PrinterError:"Oprostite! Prišlo je do napake.",
  Printing_:"Tiskanje ...",
  PrinterSuccess:"Dokument je uspešno natisnjen.",
  PrintersSuccess:"Dokumenti so uspešno natisnjeni.",
  Print_Cheques:"Natisni čeke",
  New_Message:"Novo sporočilo",
  New_Messages:"Nova sporočila",
  Read_Message:"Preberi sporočilo",
  Write_Message:"Napiši sporočilo",
  Send_Message:"Pošlji sporočilo",
  Subject:"Zadeva",
  Message:"Sporočilo",
  Writing:"Pisanje ...",
  Send:"Pošlji",
  Set_Date:"Nastavi datum",
  Set_Time:"Nastavi čas",
  Recieve:"Prejmi",
  Set_as_Default:"Nastavi na privzeto",
  Default_Account:"Privzeti račun",
  Default_Design:"Privzeta zasnova",
  Multiple_Cheques:"Čeki (trojni)",
  Account_Mode:"Način računa",
  Multiple_Cheques_Description:"Tri pregledi na stran.",
  Check_and_Table:"Preveri in mizo",
  Check_including_Table:"Čekovna in računovodska miza.",
  Check_Mailer:"Preveri pošto",
  Check_Mailer_Window:"Preveri z naslovnim oknom.",
  DocuGard_Table_Top:"DocuGard Preverite in Tabelo (vrh)",
  DocuGard_Table_Middle:"DocuGard Preverite in Tabelo (sredina)",
  DocuGard_Table_Bottom:"DocuGard Preverite in Tabelo (spodaj)",
  DocuGard_Mailer_Top:"DocuGard Ček in Poštnina (na vrhu)",
  DocuGard_Mailer_Middle:"DocuGard Ček in Poštnina (srednji)",
  DocuGard_Mailer_Bottom:"DocuGard Ček in Poštnina (spodaj)",
  DocuGard_Three_Cheques:"DocuGard Checks (Trojni)",
  DocuGard_Cheque_Top:"DocuGard Check (na vrhu)",
  DocuGard_Cheque_Middle:"DocuGard Check (srednji)",
  DocuGard_Cheque_Bottom:"DocuGard Check (spodaj)",
  DocuGard_Three_Cheques_Window:"Tri pregledi na eni strani.",
  DocuGard_Table_Top_Window:"Tabela čekov in zaslužkov.",
  DocuGard_Table_Middle_Window:"Tabela čekov in zaslužkov.",
  DocuGard_Table_Bottom_Window:"Tabela čekov in zaslužkov.",
  DocuGard_Mailer_Top_Window:"Ček, miza in naslov.",
  DocuGard_Mailer_Middle_Window:"Ček, miza in naslov.",
  DocuGard_Mailer_Bottom_Window:"Ček, miza in naslov.",
  DocuGard_Cheque_Top_Window:"Preveri varen papir.",
  DocuGard_Cheque_Middle_Window:"Preveri varen papir.",
  DocuGard_Cheque_Bottom_Window:"Preveri varen papir.",
  Basic_Cheque:"Preveri (na vrh)",
  Basic_Cheque_Print:"Natisnite en ček.",
  Error_Setting_As_Paid:"Napaka pri nastavitvi kot plačano",
  Add_Attachment:"Dodaj prilogo",
  PrinterUnavailable:"Tiskalnik ni na voljo",
  CreditCardComponent:"kartice",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Nov depozit",
  Deposits:"Depoziti",
  No_Deposits:"brez depozitov",
  Credit_Card_Deposit:"kreditna kartica",
  New_Credit_Card_Deposit_Message:"Depozit s kreditno kartico",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin depozit",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Interac Transfer",
  Payments_Limit:"Omejitev plačila",
  No_Payment_Limit:"Brez omejitve plačila",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal depozit",
  No_Deposits_Word:"Dodaj prvo podjetje <a routerLink='/folder/Deposit/New'>Polog</a>.",
  No_Documents_Specified:"Noben dokument ni bil določen za tiskanje",
  No_Printers_Added:"Najden ni bil noben tiskalnik. Pojdite v Nastavitve > Tiskalniki, da ga dodate.",
  DB_Erase_Prompt:"Popolnoma izbrisati celotno bazo podatkov? OPOZORILO: Izgubili boste vse shranjene podatke!",
  Console_Warning:"Ne prilepite nobenega besedila v to konzolo. Lahko izpostavite sebe in/ali svoje podjetje resnemu tveganju.",
  No_Faxes_Word:"Ustvari prvi <a routerLink='/folder/Fax/New'>Faks</a>.",
  Cheque_Delete_Confirmation:"Ali ste prepričani, da želite izbrisati ta ček?",
  Design_Delete_Confirmation:"Ali ste prepričani, da želite izbrisati ta dizajn?",
  Cheque_Pay_Confirmation:"Označi ta ček kot plačan? NE bo prikazan v čakalni vrsti za tiskanje.",
  Payment_Pay_Confirmation:"Označi to plačilo kot plačano? NE bo prikazano v čakalni čakalni vrsti.",
  Delete_Deduction_Confirmation:"Ali ste prepričani, da želite izbrisati ta odbitek?",
  Delete_Job_Confirmation:"Ali ste prepričani, da želite izbrisati to opravilo?",
  Delete_Timesheet_Confirmation:"Ali ste prepričani, da želite izbrisati ta časovni list?",
  Delete_Schedule_Confirmation:"Ali ste prepričani, da želite izbrisati ta urnik?",
  Delete_Setting_Confirmation:"Ali ste prepričani, da želite ponastaviti to nastavitev?",
  Delete_Fax_Confirmation:"Ali ste prepričani, da želite izbrisati ta faks?",
  Delete_File_Confirmation:"Ali ste prepričani, da želite izbrisati to datoteko?",
  Delete_Vacation_Confirmation:"Ali ste prepričani, da želite izbrisati ta dopust?",
  Delete_Printer_Confirmation:"Ali ste prepričani, da želite izbrisati ta tiskalnik?",
  Remove_Design_Confirmation:"Ali ste prepričani, da želite izbrisati ta dizajn?",
  Delete_Payroll_Confirmation:"Ali ste prepričani, da želite izbrisati to plačilno listo?",
  Send_Fax_Email_Confirmation:"Ali želite te dokumente poslati po faksu in po e-pošti?",
  Send_Email_Confirmation:"Ali želite ta dokument poslati po e-pošti?",
  Send_Fax_Confirmation:"Ali želite faksirati ta dokument?",
  Error_Generating_PDF:"Oprostite. Pri ustvarjanju tega dokumenta je prišlo do napake.",
  PDF_Error_Saving_Image:"Oprostite. Pri shranjevanju slike PDF tega dokumenta je prišlo do napake.",
  Test_Printer_Confirmation:"Ali želite natisniti preskusno stran na tem tiskalniku?",
  Save_Timesheet_Prompt:"Prosimo, dodajte 'Naslov' ali pritisnite 'Zaženi časovnik', da shranite.",
  Remove_Geofence_Prompt:"Ali ste prepričani, da želite odstraniti lokacijo te geo-ograje?",
  Delete_Employee_Confirmation:"Ali ste prepričani, da želite izbrisati",
  Fire_Employee_Confirmation:"Ali ste prepričani, da želite odstreliti"
}