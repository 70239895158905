export const La = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Copyright & copy; 2023",
  black:"Nigreos",
  green:"Viridis",
  gold:"Aurum",
  blue:"Caeruleum",
  brown:"Brown",
  purple:"Purpura",
  pink:"Pink",
  red:"Rubrum",
  Swatches:"Swatches",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Hue",
  Saturation:"Saturatio",
  Lightness:"levitas",
  Upgrade_To_Pro:"Phasellus ut Pro",
  AllFeaturesInclude:"Omnes Features Include:",
  PrintUnlimitedCheques:"Print ft SCUTULATUM",
  PremiumChequeDesigns:"Premium Check Designs",
  ManageUnlimitedEmployees:"Infinitus administrare Employees",
  AddUnlimitedPayees:"Add ft payees",
  CreateUnlimitedPayrolls:"Create Infinitus Payrolls",
  UnlimitedSchedulesandTimeSheets:"Infinitus cedulas et tempus rudentis",
  BulkPayPalPayouts:"Mole PayPal Payouts",
  UnlimitedBankAccounts:"Infinitus Bank Rationes",
  ManageMultipleCompanies:"Multiple Companies Curo",
  TrackInsurancePolicies:"Inuestigandum Insurance Politiae",
  Bio_MetricProtection:"Bio-Metric Protection",
  Geo_FenceLock_OutProtection:"Geo-Fence Lock-Out Praesidium",
  Multiple_Companies_Word:"Multas societates administrandi sine scutulis premium praesto non est.",
  PayPal_Payouts_Word:"PayPal solutiones debiles sine SCUTULATUM premium.",
  PINProtection:"ACUS Praesidium",
  PasswordProtection:"Password Praesidium",
  May_Require_Approval:"Sit eget ligula.",
  Subscribe:"Subscribe",
  Billed:"Occisus",
  Up:"Up",
  Down:"Descende",
  Positioning:"Positioning",
  Marker:"Venalicium",
  Drag_Marker:"Drag Marker",
  Tagline:"Print SCUTULATUM ac Tabulatum Payroll",
  Marker_Word:"Morbi ut elementum tortor.",
  Pinch_Zoom:"Prement Zoom",
  Pinch_Word:"Tern to zoom the elementum.",
  Drag:"Drag",
  Drag_Word:"Digito tuo uteris ad elementa trahe.",
  subscription_alias_word:"Auto-renovatio Subscription",
  premium_alias_word:"Unum tempus upgrade Package",
  print_alias_word:"Print-Ex singulis SCUTULATUM",
  mode_alias_word:"Modus",
  Pro:"Pro",
  Pro_word:"Pro versione desideratur.",
  Cant_Delete_Default_Company_Word:"Ignosce, sis: default societatem tuam delere non potes.",
  Reinsert_Default_Designs:"Re- Insert Default Designs",
  Reinsert_Default_Designs_word:"Visne re-inserere per annum consilia?",
  Subscription_Active_Disable_Word:"Subscriptio haec activa est. Visne hanc subscriptionem rescindere ad Cheques?",
  Company_Logo:"Societas Logo",
  ZERO_:"NIHIL",
  Disclaimer:"Disclaimer",
  Privacy_Policy:"Privacy policy",
  EULA:"EULA checks",
  Terms_Of_Service:"Termini servitii",
  Terms_Of_Use:"Terms of Use",
  Refunds:"Refugium Policy",
  Single_Print:"I Moderare",
  Two_Prints:"2 SCUTULATUM",
  Five_Prints:"5 SCUTULATUM",
  Ten_Prints:"X SCUTULATUM",
  Fifteen_Prints:"15 SCUTULATUM",
  Twenty_Prints:"20 SCUTULATUM",
  Thirty_Prints:"XXX SCUTULATUM",
  Image_Added:"Imago Added",
  Image_Preview:"Imago Praevius",
  No_Image_Was_Selected:"Nullam at mauris lectus.",
  Cheques_Unlimited:"checks ft",
  _Subscription:"Subscription",
  Subscription:"SCUTULATUM - 1 Mensis",
  Two_Month_Subscription:"SCUTULATUM - 2 Menses",
  Three_Month_Subscription:"SCUTULATUM - III mensibus",
  Six_Month_Subscription:"SCUTULATUM - VI Menses",
  Twelve_Month_Subscription:"SCUTULATUM - XII Menses",
  Cheques_Are_Available:"SCUTULATUM praesto sunt ad imprimendum.",
  Upgrade_Required_Two:"Sarcinam eligere et duplex sonum in pretio puga pyga ut satus emptionis tuae. Print professionalem vultus plenus-color compescit in secundis.",
  Month:"Mensis",
  Due:"Ob:",
  Expires:"Exspirat:",
  Subscription_Active:"Subscription Active",
  Subscription_Inactive:"Subscription vestibulum",
  Purchase_Additional_Cheques:"Additional emo checks?",
  Printable_Cheque:"Forma Moderare",
  Printable_Cheques:"Forma SCUTULATUM",
  Printable_Cheque_Word:"reprehendo is available in rationem vestram.",
  Printable_Cheques_Word:"checks sunt available in ratione vestra.",
  Max_Amount_Message:"Quantum tibi certum est, perventum est ad maximam quantitatem huius systematis appositae:",
  Terms_Required_Word:"Huic conventioni assentire debes antequam Cheques utaris.",
  Subscriptions:"Subscriptiones",
  Product_Upgrades:"Upgrades",
  Mailed_Out_Cheques:"CATAPHRACTUS-E SCUTULATUM",
  Enter_A_Company_Name:"Please enter a company name.",
  Single_Cheques:"unum SCUTULATUM",
  Cheque_Golden:"Aureum Check",
  Cheque_Golden_Window:"Aurea perscriptio consilio.",
  Cheque_Green:"Green Moderare",
  Cheque_Green_Window:"Green reprehendo consilio.",
  Cheque_Red:"Red Moderare",
  Cheque_Red_Window:"Red reprehendo consilio.",
  Cheque_Yellow:"Yellow Moderare",
  Cheque_Yellow_Window:"Flavo reprehendo consilio.",
  Cheque_Statue_of_Liberty:"Stauta libertatis",
  Cheque_Statue_of_Liberty_Window:"Statua Libertatis perscriptio designatur.",
  Cheque_Green_Wave:"Viridis unda",
  Cheque_Green_Wave_Window:"Green reprehendo consilio.",
  Cheque_Golden_Weave:"Aureum texere",
  Cheque_Golden_Weave_Window:"Elegans au- ceptum consilium.",
  Cheque_Green_Sun:"Sol viridis",
  Cheque_Green_Sun_Window:"Altum et sedatum represserat consilium.",
  Cheque_Blue_Checkers:"Blue Checkers",
  Cheque_Blue_Checkers_Window:"Blue reprehendo consilio.",
  Cashiers_Check:"CONLECTARIUS scriptor Moderare",
  Cashiers_Check_Window:"CONLECTARIUS perscriptio style templates.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Aqua reprehendo consilio.",
  Cheque_Golden_Checkers:"Aureum Checkers",
  Cheque_Golden_Checkers_Window:"Aurea perscriptio consilio.",
  Upgrade_Unavailable:"Upgrades Unavailable",
  Bank_Code_Protection:"Bank Number Praesidium",
  Bank_Code_Protection_Word:"Numeri ripam tuam protege ne in hac app currentem ad alium fabricam vel ad alium usum adhibeantur. Haec actio irrevocabilis est. Perge?",
  Bank_Code_Protection_Blocked_Word:"Numeri argentarii quos uti conaris reservantur alteri usuario vel artificio.",
  Bank_Code_Protection_Error_Word:"Numerus verificationis defecit. Quaeso coniungere ad interrete et hanc ripam rationem iterum experiri addendo.",
  Bank_Code_Protection_Set_Error_Word:"Argentaria numerus praesidium postulat ut cum interrete coniungaris. Quaeso coniungere et iterum conare.",
  Upgrade_Unavailable_Word:"Ignosce, sis molestus probari tibi. Subscriptiones et upgrades ad SCUTULATUM es currently perpendat pro emptione in area tua.",
  PayPal_Payment_Preview:"Payment Praevius PayPal",
  Apple_Pay:"Lacus Esto",
  Select_PayPal:"Lego PayPal",
  PayPal_Applications:"PayPal Applications",
  Purchase_With_Apple_Pay:"Purchase cum Apple Esto",
  Google_Pay:"Google Reddere",
  Companies:"Societates",
  Insurance:"Cautionum",
  New_PayPal:"Novum PayPal",
  Pay_By:"Per stipendium",
  Insure:"praecavendam",
  Miles:"Miles",
  Payment_Method:"Solucionis methodo",
  Select_Policies:"Select Politiae",
  Policies:"Politiae",
  Policy:"Policy",
  No_PayPal_Account:"Non PayPal Ratio",
  No_Policies:"Nulla Insurance Politiae",
  New_Policy:"Novum Policy",
  PayPal_Payment:"Payment PayPal",
  PayPal_Payments:"PayPal liberate",
  No_Payment_Selected:"Non Payment Selectae",
  Sending_Payment_Word:"Obsecro, exspecta... Haec solutio mittitur.",
  Sending_Payments_Word:"Please wait... Payment are being sent.",
  No_Payment_Selected_PayPal:"Non <a routerLink='/folder/payments'>Praestatio PayPal</a> electo mittere.",
  Payment_Sent:"Payment Sent",
  PayPal_Payment_Sent:"Haec solutio missum est cum PayPal.",
  Copay:"Copay",
  Dead:"Mortuus",
  Alive:"Vivus",
  Not_Dead:"Non Mortuus",
  Unclaimed:"vacantia",
  Attempted:"conatus",
  Deceased:"defuncti",
  Claimed:"petita",
  Unpaid:"insolutis",
  Sending_Payment:"Mittens Payment",
  Sending_Payments:"Mittens liberate",
  Send_PayPal_Confirmation:"Visne hanc rem cum PayPal mittere?",
  Send_PayPal_Confirmation_Word:"Preme viridem conjunctionem ut mittat hanc rem.",
  Save_Payment_As_Unpaid:"Servo hanc mercedem quasi insolutis?",
  Payment_Pay_Confirmation_PayPal:"Servo hanc mercedem quam solvit?",
  No_Policies_Word:"primum <a routerLink='/folder/Postage/New'>Insurance Policy</a> adde .",
  Timesheet_Multiple_Delete_Confirmation:"Certus esne vis multas timesheets delere?",
  Select_Multiple_Timesheets_Prompt:"Nulla a tincidunt lectus. Eligere saltem unum timesheet.",
  Select_Multiple_Policies_Prompt:"Nulla lobortis lectus. Eligere saltem unum assecurationis consilium.",
  Policies_Multiple_Delete_Confirmation:"Visne vero multa consilia delere?",
  Company:"Societas",
  Add_Company:"Add Company",
  New_Company:"Add Company",
  No_Companies:"No Companies",
  Enable_Company:"Admitte Company",
  Select_Company:"Select Company",
  The_Default_Company:"Defalta societas pittacii.",
  Manage_Companies:"Curo Companies",
  No_Companies_Word:"Compescit, defaltam adhibebit societatem.<br /> Adde <a routerLink='/folder/Company/New'>Primam Societatem</a>.",
  Default_Company:"Default Company",
  Cheques_Unlimited_Word:"SCUTULATUM IMMODERATUS permittit ut tot notas imprimas quantum tibi placet.",
  Cheques_Subscription_Word:"Subscriptio SCUTULATUM permittit ut tot notas imprimas quantum vis.",
  You_Own_This_Product:"Quisque id consequat turpis.",
  Your_Subscription_is_Active:"Subscriptio tua activa est.",
  Active_Products:"activated Products",
  Purchase_Confirmation:"Purchase",
  Purchase_Cheques:"Purchase SCUTULATUM",
  Restore_Purchase:"Emit restituet",
  Erase_Purchase:"Extermina emptionibus",
  Successfully_Purchased:"Feliciter Purchased",
  Enter_Your_Licence_Key:"Quaeso, licentia clavem tuam in hac pagina ad hanc paginam strenue.",
  Licence_Key:"Key licentiam",
  Enter_Licence_Key:"Licentia Key Intra",
  Purchased:"empticius",
  Enable_Feature:"Admitte Feature",
  Cancel_Subscription:"Cancel Subscription",
  Subscription_Removed:"Subscriptione remota",
  Select_Active_Subscription:"Subscriptionem activam elige ut eam mitiges.",
  Remove_Subscription_Word:"Visne vero hanc subscriptionem rescindere?",
  Delete_Company_Confirmation:"Visne vero hanc totam societatem delere? MONITUM: Omnia condita notitia perdes!",
  Delete_Default_Company_Word:"Annum societatis delere non potes . Velisne applicationem retexere ac restituere ad statum default? MONITUM: Omnia condita notitia perdes!",
  Console_Warning_2:"Noli tractare aliquem monetam utentem hac applicatione quae nunc tua non est.",
  Terms_and_Conditions:"Terms and Conditions",
  and_the:"et",
  for:"for*",
  Please_Read_Word:"Lege et conveniunt ad",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Nonnullae monetae conversionis rates inveniri non potuerunt. Quaeso coniungere ad interrete.",
  Free:"Liber",
  DB_Erase_Prompt_2:"Integer dele elit database? MONITUM: Perdes omnes AUCTORO ET CONSCRIPTIONE informationes!",
  Successfully_Imported:"Feliciter Imported",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  Select_Postage:"Lego vectigalia",
  No_Postage:"Non vectigalia notat",
  No_Paid_Postage_Word:"Adde primum <a routerLink='/folder/Postage/New'>Pensi Vectigalia</a> notam.",
  Trial_Complete:'Iudicium Complete',
  Please_Upgrade:'Quaeso upgrade SCUTULATUM pergere excudendi.',
  Aa:"Aa",
  Color:"Spalva",
  Font:"Šriftas",
  Guide:"Vadovas",
  Guides:"Vadovai",
  Image:"Vaizdas",
  Zoom:"Padidinti",
  Logo:"Logotipas",
  Bank:"bankas",
  MICR:"MICR",
  Total:"Iš viso",
  Cancel:"Atšaukti",
  Confirm:"Patvirtinti",
  Method:"Metodas",
  Biometric_Security:"Biometrinis saugumas",
  Please_Login_To_Continue:"Prisijunkite, jei norite tęsti.",
  Complete:"Užbaigti",
  Sign_Up:"Registruotis",
  Error:"Klaida",
  Biometrics:"Biometrija",
  Percent:"proc",
  Zero_Percent:"0 %",
  Top_Margin:"Viršutinė marža",
  Bottom_Margin:"Apatinė marža",
  Left_Margin:"Kairė paraštė",
  Right_Margin:"Dešinė paraštė",
  MICR_Margin:"MICR marža",
  Table_Margin:"Lentelės marža",
  Address_Margin:"Adreso marža",
  Percentage_:"Procentas",
  Vacation_Title:"Atostogų pavadinimas",
  Use_PIN:"Naudokite PIN kodą",
  Loading__:"Įkeliama...",
  Design_Title:"Dizaino pavadinimas",
  Authorize:"Įgalioti",
  Key:"Raktas",
  Public_Key:"Viešasis raktas",
  Private_Key:"Privatus raktas",
  Authenticate:"Autentifikuoti",
  Last_Payroll:"Paskutinis darbo užmokestis",
  Last_Calculation:"Paskutinis skaičiavimas",
  Authorized:"Įgaliotas",
  Geo_Authorized:"Geografinė vieta: įgaliota",
  Not_Authorized:"Neįgaliotas",
  Authorization_Complete:"Autorizacija baigta",
  Geolocation_Authorization:"Geografinės vietos leidimas",
  Out_of_Bounds:"Už ribų",
  Cant_Delete_Default_Design:"Negalima ištrinti numatytojo dizaino.",
  Unauthorized_Out_of_Bounds:"Neteisėta: už ribų",
  Biometric_Authorization:"Biometrinis leidimas",
  Locating_Please_Wait:"Rasta, prašome palaukti...",
  Test_Biometrics:"Išbandyti biometriką",
  Cheque_Margins:"Patikrinkite paraštes",
  Percentage_Full_Error:"Procentų laukelis negali būti didesnis nei 100 % procentų.",
  No_Payroll_Text:"Addo <a routerLink='/folder/Employee/New'>Employee</a> vel <a routerLink='/folder/Payee/New'>Payee</a> and a <a routerLink='/folder/Schedule/New'>Pridėkite <a routerLink='/folder/Employee/New'>darbuotoją</a> arba <a routerLink='/folder/Payee/New'>Mokėjimo Gavėją</a> ir <a routerLink='/folder/Schedule/New'>Tvarkaraštis</a>.",
  Design_Saved:"Dizainas išsaugotas",
  Default_Design_Selected:"Pasirinktas numatytasis dizainas",
  Partial_Import:"Dalinis importas",
  Partial_Export:"Dalinis eksportas",
  Entire_Import:"Visas importas",
  Entire_Export:"Visas eksportas",
  Existing_Password:"Įveskite esamą slaptažodį:",
  Existing_PIN:"Įveskite esamą PIN kodą:",
  Pin_Change_Header:"PIN patvirtinimas",
  Pin_Change_SubHeader:"Įveskite seną PIN kodą, kad patvirtintumėte pakeitimą.",
  Pass_Change_Header:"Slaptažodžio patvirtinimas",
  Pass_Change_SubHeader:"Įveskite seną slaptažodį, kad patvirtintumėte pakeitimą.",
  PIN_Enter_Message:"Įveskite PIN kodą, kad patvirtintumėte.",
  Password_Enter_Message:"Įveskite slaptažodį, kad patvirtintumėte.",
  Pin_Updated_Success:"PIN sėkmingai atnaujintas!",
  Pin_Updated_Fail:"Nepavyko atnaujinti PIN.",
  Pass_Updated_Success:"Slaptažodis sėkmingai atnaujintas.",
  Pass_Updated_Fail:"Nepavyko atnaujinti slaptažodžio.",
  Preview_Payment:"Mokėjimo peržiūra",
  Preview_Payroll:"Peržiūrėti darbo užmokestį",
  No_Payments_Created:"Nerasta jokių sukurtų mokėjimų.",
  Payment_Preview:"Mokėjimo peržiūra",
  Enable_Payee:"Įgalinti gavėją",
  Rendered:"Pateikta",
  No_Email:"Nėra el. pašto",
  Setup_Cheques:"Sąrankos patikrinimai",
  name:"vardas",
  address:"Adresas",
  address_2:"Adreso eilutė 2",
  city:"Miestas",
  province:"Provincija",
  postal_code:"Pašto kodas",
  country:"Šalis",
  username:"Vartotojo vardas",
  full_name:"Pilnas vardas",
  birthday:"Gimtadienis",
  email:"El. paštas",
  phone:"Telefonas",
  employees:"Darbuotojai",
  addresses:"Adresai",
  payment_amount_limit:"Mokėjimo sumos limitas",
  total_limit:"Bendras limitas",
  payees:"Gavėjai",
  description:"apibūdinimas",
  address_line_one:"Pirmoji adreso eilutė",
  address_line_two:"Antra adreso eilutė",
  image:"Vaizdas",
  account_holder:"Sąskaitos savininkas",
  cheque_starting_id:"Patikrinkite pradžios ID",
  transit_number:"Tranzito numeris",
  institution_number:"Įstaigos numeris",
  designation_number:"Paskyrimo numeris",
  account_number:"Paskyros numeris",
  currency:"Valiuta",
  signature:"Parašas",
  payment_payroll_limit:"Mokėjimo limitas",
  total_limi:"Bendras limitas",
  date:"Data",
  printed_memo:"Išspausdinta atmintinė",
  banks:"Bankai",
  signature_image:"Parašo vaizdas",
  address_name:"Adresas",
  notes:"Pastabos",
  design:"Dizainas",
  title:"Pavadinimas",
  frequency:"Dažnis",
  fax:"Faksas",
  salaries:"Atlyginimai",
  salary_ids:"Atlyginimo ID",
  start_time:"Pradžios laikas",
  end_time:"Pabaigos laikas",
  paid:"Mokama",
  overtime_percentage:"Mokamas procentas",
  overtime_amount:"Sumokėta fiksuota suma",
  first_name:"Pirmas vardas",
  last_name:"Pavardė",
  moderation:"Saikingumas",
  create:"Sukurti",
  edit:"Redaguoti",
  destroy:"Sunaikink",
  day_start_time:"Dienos_pradžios_laikas",
  day_end_time:"Dienos_pabaigos laikas",
  fullname:"vardas",
  time:"Laikas",
  auto_send:"Automatiškai siųsti",
  time_method:"Laiko metodas",
  schedules:"Tvarkaraščiai",
  indefinite_payroll_enabled:"Įgalinti neribotą laiką",
  amount:"Suma",
  repeat:"Pakartokite",
  always_enabled:"Visada įjungta",
  start_date:"Pradžios data",
  end_date:"Pabaigos data",
  Cheque_Total:"Patikrinkite iš viso",
  Total_Amount:"Visas kiekis:",
  Amounts:"Sumos:",
  Images:"Vaizdai",
  Files:"Failai",
  Previewing:"Peržiūra:",
  Insert:"Įdėti",
  Preview_Import:"Preview Import",
  Entry:"Įėjimas",
  Entries:"Įrašai",
  No_Entries:"Nėra įrašų",
  Import_Type:"Importo tipas",
  Select_Details:"Pasirinkite Išsami informacija",
  Select_Payee:"Pasirinkite Gavėjas",
  Enable_Holidays:"Įgalinti šventes",
  Disable_Holidays:"Išjungti šventes",
  Wire_Transfer:"Pavedimas",
  New_Export:"Naujas eksportas",
  Export_Data:"Eksportuoti duomenis",
  Export_Data_Word:"Pasirinkite failo tipą, kurį norite eksportuoti ir atsisiųsti.",
  Export_File:"Eksportuoti failą",
  Mode:"Režimas",
  Times:"Laikai",
  Widgets:"Valdikliai",
  Slider:"Slankiklis",
  Set_Details:"Nustatyti išsamią informaciją",
  Select_Type:"Pasirinkite Tipas",
  Display_Slider:"Ekrano slankiklis",
  Dashboard_Slider:"Prietaisų skydelio slankiklis",
  Dashboard_Mode:"Prietaisų skydelio režimas",
  Show_Intro:"Rodyti įžangą",
  Show_Payrolls:"Rodyti darbo užmokestį",
  Show_Holidays:"Rodyti šventes",
  Show_Invoices:"Rodyti sąskaitas faktūras",
  Show_Cheques:"Rodyti čekius",
  Enabled_Widgets:"Įjungti valdikliai",
  Disabled_Widgets:"Išjungti valdikliai",
  Colors:"Spalvos",
  Barcodes:"Brūkšniniai kodai",
  View_Timers:"Žiūrėti laikmačius",
  Gradients:"Gradientai",
  No_Info:"Nėra informacijos",
  Disable:"Išjungti",
  Show_Layer:"Rodyti sluoksnius",
  Hide_Layer:"Slėpti sluoksnius",
  Text_Layer:"Teksto sluoksniai",
  Secondly:"Antra",
  Minutely:"Minutėmis",
  nine_am:"9:00 RYTO",
  five_pm:"5:00 VAKARO",
  Enable_Address:"Įgalinti adresą",
  Invalid_File_Type:"Atsiprašome, pasirinktas netinkamas failo tipas. Palaikomas failo tipas:",
  Error_Updating_Entry:"Atsiprašome, atnaujinant šį įrašą įvyko klaida.",
  Schedule_Timing_Alert:"Klaida: tvarkaraščio pradžios laikas nustatytas į vertę po pabaigos laiko.",
  Select_Multiple_Payments_Prompt:"Nepasirinkta jokių mokėjimų. Pasirinkite bent vieną mokėjimą.",
  Select_Multiple_Cheques_Prompt:"Nepasirinkta jokių čekių. Pasirinkite bent vieną čekį.",
  Select_Multiple_Items_Prompt:"Nepasirinkta jokių elementų. Pasirinkite bent vieną elementą.",
  Paymemt_Multiple_Delete_Confirmation:"Ar tikrai norite ištrinti kelis mokėjimus?",
  Cheque_Multiple_Delete_Confirmation:"Ar tikrai norite ištrinti kelis čekius?",
  Payee_Multiple_Delete_Confirmation:"Ar tikrai norite ištrinti kelis mokėjimo gavėjus?",
  Employee_Multiple_Delete_Confirmation:"Ar tikrai norite ištrinti kelis darbuotojus?",
  MICR_Warning:"Pastaba: Kai kurie spausdintuvai ir įrenginiai gali netinkamai rodyti MICR šriftą.",
  Automatically_Send:"Automatiškai siųsti",
  Type:"Tipas",
  Payment_Type:"Mokėjimo tipas",
  Auto_Send:"Automatinis siuntimas",
  Automatically_Process:"Automatiškai apdoroti",
  Auto_Process:"Automatinis procesas",
  Image_Based:"Vaizdo pagrindu",
  Font_Based:"Šrifto pagrindu",
  Rerender:"Iš naujo pateikti",
  Rendering:"Atvaizdavimas",
  Render:"Failai",
  Top:"Į viršų",
  Middle:"Vidurio",
  Bottom:"Apačia",
  Top_Left:"Viršuje kairėje",
  Top_Center:"Viršutinis centras",
  Top_Right:"Viršutinis dešinysis",
  Middle_Left:"Vidurio kairėje",
  Middle_Center:"Vidurinis centras",
  Middle_Right:"Vidurinė dešinė",
  Bottom_Left:"Apačioje kairėje",
  Bottom_Center:"Apatinis centras",
  Bottom_Right:"Apačioje dešinėje",
  Numbers:"Skaičiai",
  Verified:"Patvirtinta",
  Paper_Size:"Popieriaus dydis",
  New_Device:"Naujas Įrenginys",
  Add_Device:"Pridėti įrenginį",
  Remove_Device:"Pašalinti įrenginį",
  Delete_Device:"Ištrinti įrenginį",
  Block_Device:"Blokuoti įrenginį",
  Block:"Blokuoti",
  Unblock:"Atblokuoti",
  Table:"Lentelė",
  Scan_Login_Code:"Nuskaityti prisijungimo kodą",
  Login_Code:"Prisijungimo kodas",
  Scan_Code:"Nuskaityti kodą",
  Scan_QR_Code:"Nuskaityti QR kodą",
  Select_All:"Pasirinkti viską",
  Deselect_All:"Atzymeti visus pasirinkimus",
  Increase:"Padidinti",
  Decrease:"Mažinti",
  Bold:"Paryškintas",
  Text:"Tekstas",
  Style:"Stilius",
  Italic:"Kursyvas",
  QR_Code:"QR kodas",
  Barcode:"Brūkšninis kodas",
  Browse_Images:"Naršyti vaizdus",
  Browse_Files:"Naršyti failus",
  Background_Image:"Fono vaizdas",
  Logo_Image:"Logotipo vaizdas",
  Header_Image:"Antraštės vaizdas",
  Bank_Image:"Banko vaizdas",
  Cut_Lines:"Iškirptos linijos",
  Background:"Fonas",
  License:"Licencija",
  One_License:"1 licencija:",
  Licensed:"Licencijuota:",
  Not_Licensed:"Nelicencijuota",
  Enter_Serial:"Įveskite seriją",
  Serial_Key:"Serijos raktas",
  Serial:"Serijinis",
  Product_Key:"Produkto raktas",
  Check_Product_Key:"Patikrinkite produkto kodą",
  Add_Product_Key:"Pridėti produkto kodą",
  Verifying_Purchase:"Tikrinamas pirkinys...",
  Print_Envelope:"Spausdinti voką",
  Envelope:"Vokas",
  Thank_You:"Ačiū!",
  Scale:"Skalė",
  Print_Scale:"Spausdinimo mastelis",
  Borders:"Sienos",
  VOID:"TUŠTUMA",
  Void_Cheque:"Negaliojantis patikrinimas",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"MOKĖKITE UŽSAKYMĄ:",
  NO_DOLLARS:"JOKIŲ DOLERIŲ ",
  ONE_DOLLAR:"VIENAS DOLERIS",
  DOLLARS:" DOLERIAI",
  AND:" IR ",
  CENTS:" CENTŲ.",
  NO_:"NE ",
  ONE_:"VIENA ",
  AND_NO_:"IR NE ",
  _AND_ONE_:"IR VIENAS ",
  DOLLARS_AND_ONE_CENT:" IR VIENAS CENTAS.",
  AND_NO_CENTS:" IR NULIS CENTŲ.",
  CHEQUE_PRINT_DATE:"DATA:",
  CHEQUE_PRINT_MEMO:"PASTABA:",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Initiale Tasks",
  Inital_Setup:"Initiale Setup",
  Welcome_To:" salve ",
  Welcome:"Ave",
  Swipe:"Tollenone",
  Intro_Setup:"Intro Setup",
  Introduction:"Prooemium",
  CHEQUE_PRINT_CREDIT:"Powered by Cheques",
  Title_Intro_Word:"Welcome to Cheques",
  Title_Intro:"Cheques Introduction",
  Title_Setup:"Reprehendit Setup",
  PayPal_Default_Email_Message:"Novam translationem Nordea accepisti. [Powered by Cheques]",
  Cheques_App_Export:"Exhibita per Cheques",
  Post_Intro_Thanks:"Tibi gratias ago pro eligens Cheques. Quod setup processus nunc completum est.",
  Post_Intro_Word:"Incipe ab excudendi primum reprehendo tuum, addere mercedem futuram vel conductos ad stipendio addere",
  Upgrade_Required:"Reprehendo te postulat ut ampliorem versionem programmatis haberes ut hoc nuntium abscondas et lineamenta additamenta reseras",
  Upgrade_Title:"Reprehendit",
  Mailout_Prompt:"Potes etiam eligere ut SCUTULATUM mail-ex tua stipendia pro te impediunt",
  Mailed_Cheque:"CATAPHRACTUS Perscriptio",
  Mailed_Cheque_Color:"CATAPHRACTUS Perscriptio (Color):",
  Terms_Purchase:"Omnes emptiones electronicae cum SCUTULATIS plene refunduntur pro usque ad 30 dies ab emptione date. Lege et consentire cum <a href='#'> Termini et Conditiones antequam perseverent</a>",
  Dashboard_Setup:"Setup Primarius Typographus",
  Dashboard_Add:"Primary Bank addere Ratio",
  Dashboard_Customize:"Select A Perscriptio Formula",
  Dashboard_Job_Salary:"Create & Job tuum salarium addere",
  Dashboard_Employees:"Track Employees & Payees",
  Dashboard_Print:"Print & Mail tuum paychecks",
  Dashboard_Payroll:"Automate Your Payroll Typographia",
  Dashboard_PayPal:"Setup PayPal Payroll / payouts",
  Begin_Setup:"Incipe Setup",
  Get_Started:"Incipias",
  Purchase:"Emptio",
  Lockdown:"Clausura",
  Unlock:"Recludam",
  Detailed:"Retineo",
  Log_In:"Login",
  Login:"Login",
  Launch:"Lorem",
  Register:"Register",
  Finish:"Perfice",
  List:"Index",
  Weekends:"epistulae",
  Weekly:"Septimana",
  PayPal_Default_Subject:"New Payment",
  Payment_Message:"Nuntius Payment",
  PayPal_Default_Payment_Note:"Gratias tibi",
  Setup_Your_Cheqing_Account:"Ratio CATASTALTICUS",
  Add_Your_Primary_Cheqing_Account:"Prima cheqing rationem tuam addere.",
  Welcome_Word:"Simplificare et automate stipendia ac ope humana administratio.",
  Get_Started_Quickly:"At pauca responde quaestiones simplices quae nos incipias adiuvabunt...",
  Done_Intro_Word:"Setup Complete",
  PIN_Protected:"Password & PIN Protectus",
  Enter_New_PIN:"Intra novum Codicem ACUS:",
  Enter_PIN:"Intra ACUS Code:",
  Invalid_PIN:"ACUS Aliquam ingressus.",
  Account_Identifier:"Ratio Identifier",
  New_Account_Identifier:"New Account Identifier",
  attempt:"conari",
  attempts:"conatus",
  remaining:"reliquiae",
  Language:"Lingua",
  languages:"Linguae",
  select_languages:"Lingua Select",
  Deposit:"Depositum",
  Hire_One_Now:"Hire nunc unus",
  App_Locked:"Applicatio clausa est.",
  Skip_:"Omit",
  Skip_to_Dashboard:"Sit ad Dashboard",
  Dashboard:"Dashboard",
  Import:"Importare",
  Admin:"Administratores",
  New_Admin:"Novum Admin",
  Settings:"Occasus",
  Color_Picker:"Color Picker",
  Font_Picker:"Fontem Picker",
  Logout:"Concludere",
  Close:"Claude",
  Close_menu:"Claude",
  Excel:"Praecedo Tabularium",
  Csv:"CSV Tabularium",
  Sql:"SQL Tabularium",
  Json:"File JSON",
  Url:"Inferre per URL",
  Back:"Retro",
  Timers:"Timers",
  Cheque:"Perscriptio",
  Print:"Printer",
  Designs:"consilia",
  Pause_Printing:"Pausae Typographiae",
  Resume_Printing:"Resume Typographia",
  Printing_Paused:"Impressio Paused",
  PrintingUnavailable:"Ignosce! Typographia est in hac ratio unavailable.",
  Prints_Paused:"Primina Paused",
  Administration:"Administratio",
  Loading:"Loading",
  Unnamed:"sine nomine",
  error:"Ignosce, haec perscriptio aperiri ad videndum non potuit",
  No_Cheques_To_Print:"Non SCUTULATUM imprimere",
  No_Cheques_To_Print_Word:"Perfice <a routerLink='/folder/Cheque/New'>Novam Cheque</a>.",
  New_Cheque:"Novum perscriptio",
  Start_One_Now:"Incipit nunc",
  Edit_Cheque:"Edere perscriptio",
  Select_Cheques:"Select SCUTULATUM",
  Select_Employee:"Eligere Aliquam",
  Default_Printer:"Default Printer",
  Reassign:"Reassign",
  Configure:"configurare",
  Template:"Templum",
  Designer:"Excogitatoris",
  Edit_Designs:"Edere consilia",
  New_Design:"New Design",
  Next:"Deinde",
  Save:"Salvare",
  Name:"Nomen",
  Mail:"Mail",
  Amount:"tantum",
  Date:"Date",
  PayPal:"PayPal",
  Payouts:"Payouts",
  PayPal_Label:"PayPal Label",
  Email_Username:"Inscriptio / Username",
  Client_ID:"Client ID",
  Sandbox_Email:"Sandbox Inscriptio",
  PayPal_Email:"Inscriptio PayPal",
  PayPal_Username:"API Username",
  PayPal_Payouts:"PayPal Payouts",
  Select_PayPal_Key:"Select Nordea Key",
  Secret:"Secreta",
  API_Secret:"API Secretum",
  PayPal_API_Keys:"PayPal Keys",
  New_PayPal_Key:"New PayPal Key",
  Email_Subject:"Subiectum Email",
  Email_Message:"Nuntius Email",
  Payout_Options:"Optiones exsolvere",
  Payment_Note:"Nota Payment",
  Enable_Employee:"Admitte Aliquam",
  Enable_Production_Mode:"Admitte Productionem Modus",
  Sandbox_Username:"Sandbox Username",
  Sandbox_Signature:"Subscriptio Sandbox",
  Sandbox_Password:"Sandbox Password",
  Production_Username:"Usoris Productio",
  Production_Signature:"Productio Subscriptio",
  Production_Password:"Tesseram productio",
  Production_Email:"Inscriptio Productio",
  API_Client_ID:"API Client ID",
  API_Signature:"API Subscriptio",
  API_Password:"API Signum",
  API_Username:"API Username",
  Secret_Key:"Secreta Key",
  Sandbox:"Sandbox",
  Production:"Fabricatio",
  Sandbox_Keys:"Sandbox Keys",
  Production_Keys:"Productio Keys",
  API_Title:"API Title",
  Production_Mode:"Modus productionis",
  Account_Label:"Ratio Label",
  No_PayPal_Setup:"Nihil Nordea Key",
  Enable_PayPal_Account:"Activare PayPal Ratio",
  No_PayPal_Word:"Adde tuum <a routerLink='/folder/Invoice/New'>PayPal API Key</a>.",
  Printed_Memo:"Impressum Memo",
  Employee:"Aliquam",
  View_Employee:"Visum Employee",
  Mailing_Address:"Oratio Sedes",
  Company_Address:"Societas Oratio",
  Select_Company_Address:"Select Oratio Societatis",
  Address:" Oratio ",
  Any_Day:"Quolibet die",
  Address_Name:"Inscriptio nominis",
  Unit:"Unitas",
  Account:"Ratio",
  Bank_Account:"Ratio Bank",
  Account_Limits:"Activare Rationis limites",
  Payroll:"Parollarium",
  New_Payroll:"New Payroll",
  No_Payroll:"Non Upcoming Payroll",
  Upcoming_Holiday:"Festa Upcoming:",
  Invoice_Due:"Ob cautionem:",
  New_Invoice:"New Cautionem",
  No_Invoices:"Invoices nullae",
  No_Invoices_Word:"Create primum <a routerLink='/folder/Invoice/Nova>Cautionem</a>",
  Cheque_Due:"Cheque Ob:",
  Payrolls:"Parollae",
  Sandbox_Mode:"Sandbox Modus",
  Hire:"mercedem",
  Pay:"Redde",
  New:"Novus",
  Add:"Adde",
  Make:"Facere",
  Time:"Tempus",
  Write:"Scribe",
  Holiday:"festus",
  Holidays:"feriae",
  Next_Holiday:"Next Feriae:",
  All_Done:"Omnia fieri!",
  Employees:"Conductos",
  Payees:"Payees",
  Job:"Iob",
  Jobs:" Jobs ",
  Invoice:"Cautionem",
  Invoices:"Invoices",
  Vacations:" Vacationes",
  Cheques:"Reprehendo",
  Brand_Cheques:"Brander",
  Payment:"Pensitatio",
  Enable_Payment:"Admitte Payment",
  Payments:"Pretio",
  Schedule:"Schedule",
  Schedules:"Schedulae",
  Timesheet:"Tempus sheet",
  Timesheets:"Tempus Sheets",
  Salary:"Salarium",
  New_Address:"Nova Oratio",
  Address_2:"Sed sed diam II)",
  _City:"Urbs",
  _State:" Publica / Prov " ,
  City:"Urbs/Villa",
  State:"Civitas/Provinciae",
  Postal:"Postal/ZIP Code",
  ZIP:"Praesens / ZIP",
  Country:"Patriam",
  Addresses:"Oratio",
  Required_Options:"Optiones requisitae",
  Optional_Options:"Optiones libitum",
  Additional_Options:"Optiones additional",
  Required:"requiritur",
  Optional:"Libitum",
  Additional:"Additional",
  No_Addresses:"Inscriptiones nullae",
  New_Address_Word:"Adde primum <a routerLink='/folder/Address/New'>Oratio</a>.",
  Select_Address:"Oratio Select",
  No_Address:"Inscriptiones nullae",
  Print_Cheque:"Print Reprehendo",
  Print_Cheque_Now:"Print Check Now",
  Description:"Descriptio",
  Pay_To_The_Order_Of:"Redde Ordini:",
  Select_Date_Range:"Date Range",
  Select_Starting_Date:"Satus Date",
  Select_Ending_Date:"Select Ending Date",
  Select_Date:"Datum eligere",
  Cheque_Date:"Perscriptio Date",
  Cheque_Memo:"Perscriptio Memo",
  Blank_Cheque:"Perscriptio blank",
  Blank:"blank",
  No_Cheques:"Non SCUTULATUM",
  No_Cheques_Word:"Imprime primum tuum <a routerLink='/folder/Cheque/New'>Perscriptio</a>.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"Vide Imaginem",
  View:"Visum",
  Modify:"Mutificare",
  Delete:"Delere",
  Cheque_Paid:"solvit",
  New_Deduction:"Deductio nova",
  Title:"Titulus",
  Frequency:"frequentatio",
  Hourly:"Horace",
  Daily:"Quotidie",
  Weekdays:"feria feria",
  BiWeekly:"II weeks",
  TriWeekly:"III septimanas",
  Monthly:"menstruum",
  MiMonthly:"II Menses",
  Quarterly:"Quartum",
  Yearly:"annus annus",
  Every_Week:"Omnis Hebdomada",
  Every_Payroll:"Omnis Payroll",
  Every_Month:"Omnis mensis",
  Annually:"annuatim",
  Always_Scheduled:"Semper Scheduled",
  Start_Date:"Initium Date",
  End_Date:"Date finem",
  Start_Time:"Satus tempus",
  End_Time:"Tempus extremum",
  Deduction_Label:"Deductio Label",
  Notes:"Notae",
  Options:"Optiones",
  Enable:"Admitte",
  Select_Deductions:"Deductiones selectae",
  Deductions:"Deductiones",
  No_Deductions:"Non deductiones",
  No_Deductions_Word:"Create primum <a routerLink='/folder/Deduction/New'>Deductio</a>",
  New_Employee:"Novum Employee",
  No_Title:"No Title",
  _Name:"Nomen",
  About_Yourself:"De te ipso",
  Full_Name:"Nomen plenum",
  Birthday:"Natalis",
  Email:"Email",
  SMS:"SMS",
  Phone:"Phone",
  Test:"Testes",
  Call:"Voca",
  Fax:"Fax",
  Printed_Note:"Expressum Nota",
  Position:"Locus",
  Job_Position:"Iob Position",
  Select_a_Job:"Iob elige",
  Select_a_Salary:"Salarium eligere",
  Add_a_Deduction:"Deductionem addere",
  Taxes:"tributa",
  Add_Taxes:"Adde vectigalia",
  Date_of_Birth:"Diem natalis",
  Email_Address:"Inscriptio electronica",
  Phone_Number:"Numerus telephonicus",
  Phone_Call:"Phone Call",
  Enable_on_Payroll:"Admitte in Payroll",
  Select_Employees:"Eligere Employees",
  No_Employees:"Non Employees",
  No_Employees_Word:"Adde primum tuum novum <a routerLink='/folder/Employee/New'>Employee</a>",
  No_Name:"Non nomen",
  Unemployeed:"otiosus",
  Employeed:" usus est ",
  Paid:"solvit",
  Enabled:"para",
  Disabled:"Disabled",
  Fire:"Ignis",
  Not_Available:"Not Available",
  Not_Available_Word:"Scribe primum tuum <a routerLink='/folder/Invoice/New>Cautionem</a> & mercedem",
  Select_Invoices:"Select_Invoices",
  Print_Invoice_Word:"Scribe primum tuum <a routerLink='/folder/Invoice/New>Cautionem</a> & mercedem",
  Invoice_Title:"Titulum cautionem",
  Invoice_Date:"Date cautionem",
  Due_Date:"Ob Date",
  New_Job:"Novum Iob",
  Details:"Singula",
  Customize:"Mos",
  Customize_Dashboard:"Mos Dashboard",
  Components:"Components",
  No_Components:"Non Components",
  Main_Components:"Principium Components",
  Smaller_Components:"Minor Components",
  Error_Loading_Page:"Error hanc paginam levat.",
  Bank_Details:"Details Bank",
  About_Your_Job:"De Iob Tuo",
  Your_Schedule:"Schedula tua",
  Job_Title:"Titulus Job",
  Job_Description:"Descriptio Job",
  Job_Details:"Job Details",
  Enable_Job:"Admitte Iob",
  Pay_Period:"Persolve tempus",
  Perpetually_Schedule:"Schedule perpetuo",
  Select_Jobs:"Select Jobs",
  No_Jobs:"Non Jobs",
  Add_Jobs:"Adde Jobs",
  No_Jobs_Word:"Adde primum <a routerLink='/folder/Job/New'>Iob</a> indicem",
  Count_Employees:"job.employee_count+' Employees",
  Zero_Employees:"0 Employees",
  New_Leave:"Novum discede",
  Leave_Name:"Relinque nomen",
  Paid_Leave:"Persolutis discede",
  Leave_Pay:"Recede redde",
  Indefinite_Leave:"Infinitum discede",
  Indefinite_Payroll:"Infinitum Payroll",
  Leave:"Relinque",
  Add_Schedules:"Schedulas addere",
  Percentage:"C%",
  Enable_Leave_Of_Absence:"Admitte absentiam",
  Select_Leaves:"Folia",
  No_Leaves:"Folia absentiae",
  Leave_Of_Absence:"Reliquero de absentia",
  Leaves_Of_Absence:"Folia absentiae",
  New_Leave_of_Absense:"Nova licentia absentiae",
  No_Leaves_Word:"Adde primum <a routerLink='/folder/Leave/New'>Sine commeatu</a>",
  Not_Enabled:"Non Enabled",
  Absences:"De absentia",
  Payee:"Payee",
  New_Payee:"Novum payee",
  Payee_Identifier:"Payee Identifier",
  Payee_Name:"Payee nomen",
  Payee_Title:"Titulum payee",
  Payment_Memo:"Memo Payment",
  Select_Payees:"Select payees",
  No_Payees:"Non payees",
  Add_Payee_Note:"Adde primum <a routerLink='/folder/Payee/New'>Payee</a>.",
  New_Payees:"New payees",
  About_The_Payment_Schedule:"Schedula solutionis",
  Your_Payroll_Schedule:"Automatic Payroll",
  New_Payment:"New Payment",
  Identifier:"Identifier",
  Select:"Eligere",
  Memo:"Memo",
  Payment_Date:"Date Payment",
  Mark_as_Paid:" Mark ut Paid ",
  Select_Payments:"Select liberate",
  No_Payments:"Non liberate",
  No_Payments_Word:"Create primum <a routerLink='/folder/Payment/New'>Solutio</a>.",
  Create_Payroll:"Create Payroll",
  Properties:"Properties",
  Payroll_Title:"Titulo Payroll",
  Payroll_Notes:"Notae stipendia",
  Payroll_Setup:"Paroll Setup",
  Tabulate_Payments:"Tabulae liberate",
  Tabulate:"Tabulatum",
  By:"Per",
  Payments_By:"Per",
  Timesheets_And_Schedules:"Tempus Sheets & Schedules",
  Both:"Utrumque",
  Items:"Items",
  Add_Payees:"Adde payees",
  Add_Account:"Propter add",
  Enable_Account:"Activare Rationem",
  Enable_Payroll:"Admitte Payroll",
  Print_Payroll:"Print Payroll",
  No_Payrolls:"Non Payroll",
  No_Payroll_Word:"Create primum <a routerLink='/folder/Payroll/New'>Payress</a>",
  View_more:"VIEW_MORE",
  Less:"MINUS",
  Add_Payroll:"Adde Payroll",
  Select_Payroll:"Select Payroll",
  About_Your_Salary:"De Salario Tuo",
  Add_Salaries:"Salaria addere",
  Add_Salary:"Salarium addere",
  Salaries:"stipendia",
  No_Salaries:" Salaria nulla ",
  No_Salaries_Word:"Adde primum <a routerLink='/folder/Salary/New'>Salarium</a>.",
  Select_Salaries:"Selectis salariis",
  New_Salary:"Salarium Novum",
  Salary_Name:"Salarium Identifier",
  Enable_Salary:"Salarium activare",
  Salary_Amount:"Salarium amount",
  New_Schedule:"New Schedule",
  Schedule_Title:"Schedula Title",
  Add_Address:"Adde Oratio",
  Repeat:"Repetere",
  Design:"Consilium",
  Edit_Design:"Edere Design",
  Edit_this_Design:"Edere hoc consilium",
  Repeat_Payment:"Repetere Payment",
  Enable_Schedule:"Admitte Schedule",
  Never:"Numquam",
  Select_Schedule:"Schedulas selectas",
  No_Schedules:"Schedulae nullae",
  No_Schedules_Word:"Create primum <a routerLink='/folder/Schedule/New'>schedulae</a>",
  New_Administrator:"Administrator novus",
  Username:"Username",
  First_Name:"Praenomen",
  Last_Name:"Cognomen",
  Add_an_Address:"Locem adde",
  Payment_Limit:"Per- Payment Limit",
  Total_Limit:"Totus modus",
  Select_Accounts:"Rationes Select",
  No_Administrators:"Non magistratus",
  No_Administrators_Word:"Create primum <a routerLink='/folder/Administrator/New'>Admin Account</a>",
  New_Administrators_Word:"Adde primum <a routerLink='/folder/Administrator/New'>Administratorem</a>",
  Cloud:"Nubem",
  Backup:"Tergum",
  Enable_iCloud:"Admitte iCloud",
  Enable_Google_Drive:"Admitte Google Coegi",
  Enable_Microsoft_OneDrive:"Admitte Microsoft OneDrive",
  Automatically_Backup:"Tergum Automatarie",
  FTP_Settings:"FTP Occasus",
  URL_File_Prompt:"Quaeso locum tabellae .xls/.xlsx/.json importare:",
  URL_SQL_Prompt:"Quaeso locum tabellae SQLite importare:",
  FTP_Backup:"FTP Tergum",
  FTP_Import:"FTP Import",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Hostes",
  Port:"Portus",
  Path:"Viam",
  Data_Path:"Data Path",
  Enable_FTP_Account:"Admitte FTP Account",
  HostnameIP:"Hostname",
  Password:"Signum",
  Connection_Port:"Connexio Portus",
  Enable_MySQL_Database:"Admitte MySQL Database",
  Log:"Locus",
  Reset:"Reponere",
  Erase:"dele",
  Export:"Export",
  Database:"Database",
  Upload_CSV:"Index CSV",
  Download_CSV:"Download CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Tergum MySQL",
  Internal_Notes:"Notae internae",
  Root_Path:"Root Path",
  Select_Backup:"Select Tergum",
  Add_New_Backup:"Novum Tergum addere",
  First_Backup:"Primum tergum pones...",
  Backups:"tergum",
  Add_Backup:"Tergum addere",
  No_Backups:"Nulla tergum inveniendi",
  Select_Backup_Type:"Select genus tergum velis ut habeat...",
  Backup_Type:"Tergum Type",
  FTP_Drive:"FTP Coegi",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Coegi",
  Microsoft_OneDrive:"Coegi",
  Import_Fields:"Agri importare",
  Import_Fields_Word:"Hoc sectione utere desumo <a routerLink='/folder/Holiday/New'>Importare</a> data",
  Upload:"Index",
  Download:"Download",
  Download_JSON:"Download as JSON Data",
  Download_SQL:"Download as SQL Tabularium",
  New_Bank:"Nova Bank",
  New_Account:"New Account",
  New_Bank_Account:"Ratio Novae Bank",
  Upload_Image:"Imago imaginis",
  Bank_Name:"Name",
  Bank_Address:"Inscriptio Bank",
  Branch_Address:"Oratio germen",
  Address_on_Cheque:" Oratio ",
  Cheque_Numbers:"Numeri reprehendo",
  Cheque_Details:"Reprehendo Singula",
  Bank_Logo:"Bank Logo",
  Cheque_ID:"Perscriptio ID",
  Starting_Cheque_ID:"Satus perscriptio ID",
  Transit_Number:"Numerus Transitus",
  Institution_Number:"Institutio Number",
  Designation_Number:"Numerus designatus",
  Account_Number:"Ratio Number",
  Limits:"fines",
  Default_Limits:"Default limites",
  Over_Limit:"Per modum",
  Under_Limit:"Sub limite",
  Payroll_Limit:"Paroll Limit",
  Enable_Bank_Account:"Admitte Bank",
  Select_Account:"Ratio Select",
  No_Bank_Account:"Non Bank",
  No_Bank_Account_Word:"Adde primum <a routerLink='/folder/Accounts/New'>Bankae Rationem</a>",
  Bank_Accounts:"Rationes Bank",
  No_Accounts:"Rationes nullae",
  No_Accounts_Note:"Adde primum <a routerLink='/folder/Accounts/New'>Bankae Rationem</a>",
  Cheque_Designer:"Reprehendo Intentio",
  Cheque_Design:"Reprehendo Design",
  Select_Design:"Deligere consilium",
  Cheque_Designs:"Perscriptio Cogitationes",
  No_Cheque_Designs:"No Perscriptio Cogitationes",
  No_Cheque_Designs_Word:"Create tuum <a routerLink='/folder/Settings/Cheque/Design/New'>Perscriptio Design</a>.",
  Set_Default:"Set quod default",
  Default:"Annum",
  Remove:"Amove",
  Folder:"Folder",
  Edit:"Edere",
  LoadingDots:"Loading...",
  Add_a_New_File:"Addere <a href='#' (click)='add()'>Novum fasciculum</a> ad",
  this_folder:"Hoc folder",
  FTP_Backup_Settings:"FTP Tergum Occasus",
  Secure_File_Transfer:"Secre File Transfer",
  New_Holiday:"Nova Feriae",
  Add_Holiday:"Addere Feriae",
  Holiday_Name:"Festum nomen",
  Additional_Pay:"Insuper et salarium",
  Enable_Holiday:"Admitte Feriae",
  Select_Holidays:"Festa selecta",
  No_Holidays:"Non feriae",
  No_Holidays_Word:"Adde primum <a routerLink='/folder/Holiday/New'>Festum Publicum</a>.",
  New_Import:"Novum Import",
  Import_Data:"Import Data",
  Import_Data_Word:"Select genus documenti vel fasciculi methodi eligendae tuae.<br /> Elige tibi poteris utramlibet agrorum invectionem in fasciculo correspondeas cuicunque modulo in app post limam sustentatam imposuisti",
  Import_File:"Tabularium importare",
  Link_To_File:"Link to File",
  Select_File:"Filium eligere",
  New_Moderator:"Novus Moderator",
  Allow_Moderation:"Permitte modum",
  Create_Paycheques:"Create paychecks",
  Edit_Paycheques_and_Data:"Edere paychecks et data",
  Destroy_Data_and_Paycheques:"Perdes data et paychecks",
  Bonus_Percentage:"Paycheck CENTESIMA",
  Fixed_Amount:"Certum",
  Select_Moderator:"Select Moderator",
  No_Moderators:"No Moderatores",
  Moderators:"Moderatores",
  Moderator_Account:"Create primum <a routerLink='/folder/folder/Administrator/New'>Moderatoris Rationis</a>",
  No_Moderators_Word:"Adde primum <a routerLink='/folder/folder/Administrator/New'>Moderatorem</a>",
  Defaults:"defaltis",
  Provide_Us_Info:"Provide nobis Info",
  Setup_Your_Printer:"Imperatorem tuum constitue",
  Your_Company:"De Your Company",
  Company_Name:"Nomen corporationis",
  Currency:"Currency",
  Default_Currency:"Default Currency",
  Base_Monthly_Income:"Monstralia reditus",
  Protection:"Praesidium",
  App_Protection:"App Praesidium",
  PIN_Code_Protection:"ACUS Codex Praesidium",
  App_Protection_Word:"Admitte modos securitatis qui rationem tuam defendunt adiuvant",
  PIN_Code:"Pin codicem",
  Change_PIN:"ACUS Muta",
  New_Password:"Novum Signum",
  Geofence_Protection:"Geo-Fence Protection",
  Geofence_Area:"Pone Area",
  Distance:"Spatium",
  Setup_Now:"Setup Nunc",
  Mile:"Mile",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Recognitio facialis",
  Face:"faciem",
  Setup:"Setup",
  Label:"Label",
  Password_Protection:"Password Protection",
  Modify_Password:"Modificare Password",
  New_Tax_Entry:"Novum tributum Entry",
  New_Tax:"Textum Novum",
  Tax_Label:"Tributum Label",
  Perpetually_Enabled:"Omnipotens sempiterne",
  Select_Taxes:"Select Vectigalia",
  Tax_Deductions:"Deductiones publicani",
  No_Tax_Deductions:"Non publicani deductiones",
  No_Tax_Deductions_Word:"Adde primum <a routerLink='/folder/Tax/New'>Taxamentum</a> deductionis",
  New_Timer:"Nova Vicis",
  Start:"Initium",
  Stop:"Prohibere",
  Start_Timer:"Initium Vicis",
  Stop_Timer:"Desine Timer",
  Timer_Active:"Timer Active",
  Timer:"Vicis:",
  Timer_Running:"Vicis: (Cursor)",
  Save_Timer:"Salva Timer",
  New_Timesheet:"Novum tempus Sheet",
  Select_Timesheets:"Select Tempus Sheets",
  Work_Notes:"Notae Opus",
  Entry_Title:"Ingressus Titulus",
  No_Timesheets:"Nullum tempus rudentis",
  No_Timesheets_Word:"Adde primum <a routerLink='/folder/Timesheet/New'>Tempus Sheet</a>.",
  Timesheet_Submitted:"Tempus Sheet Submitted",
  Timesheet_Congrats:"Gratulor! Tempus tuum Sheet feliciter submisit. Gratias tibi!",
  Timesheet_Copy:"Ad exemplum litterarum tuarum recipiendum quaeso nobis cum inscriptionem electronicam tuam et/vel mobile phone numerum praebes",
  Submit:"Submittere",
  Allow_Notifications:"Sinite Suspendisse",
  Untitled_Entry:"Description:",
  Untitled_Bank:"Bamaria Untitled",
  Timesheet_Entry:"Tempus Sheet Entry",
  Work_Description:"Descriptio operis",
  Enable_Timesheet:"Admitte tempus Sheet",
  Submit_Timesheet:"Submittere Tempus Sheet",
  Vacation:"Vocationem",
  New_Vacation:"New Vacation",
  Vacation_Name:"Nomen vacationis",
  Paid_Vacation:"Vacationem solvit",
  Vacation_Pay:"Vacationem redde",
  Enable_Vacation:"Admitte Vacationem",
  Select_Vacations:"Select Vacations",
  No_Vacations:"No Vacationes",
  No_Vacations_Word:"Create primum <a routerLink='/folder/Vacation/New'>Vacatio</a> ingressum",
  Payroll_Schedule:"Paroll Schedule",
  Next_Payroll:"Proximo Payroll:",
  Upcoming_Payroll:"Paroll Upcoming",
  No_Upcoming_Payroll:"Non Upcoming Payroll",
  Address_Book:"Inscriptio libro",
  Archived_Documents:"Documenta scrinium",
  Dev_Mode:"Applicatio in Modus Development",
  Administrators:"Administratores",
  Privacy:"Secretum",
  None:"Nihil",
  Select_Signature:"Subscriptio Select",
  No_Signatures:"Subscriptio nulla",
  No_Signatures_Word:"Adde primum <a routerLink='/folder/Signature/New'>Subscriptio</a>",
  Repeat_Indefinitely:"Infinite repete",
  Sign:"Signum",
  Sign_Here:"Sign Hic",
  Date_Signed:"Date Signatur",
  Signature_Pad:"Subscriptio Pad",
  Account_Holder:"Ratio Holder",
  Account_Properties:"Ratio Properties",
  Name_On_Cheque:"Nomen in Cheque",
  Server_Hostname:"Servus Hostname/IP",
  Printers:"Impressorum",
  No_Printers:"Impressores nulli",
  Printer_Exists:"Iam hoc nomine typographus exstat.",
  No_Printers_Word:"Adde primum ipsum <a routerLink='/folder/Printer/New'>Printer</a>.",
  No_Printer_Alert:"Non printer definitur. Vis ut habeat printer?",
  Add_Printer:"Impressor addere",
  New_Printer:"Novus Typographus",
  Printer_Hostname:"Impressor Hostname/IP",
  Printer_Label:"Impressor Label",
  Printer_Protocol:"Protocollum typographicum",
  Protocol:"Protocollum",
  Email_Print:"Email",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Socket",
  Print_Job:"Iob imprimere",
  Printed:"Typis",
  Not_Printed:"Non Typis",
  Print_Jobs:"Print Jobs",
  Print_Queue:"Print Queue",
  No_Print_Jobs:"Non Jobs Print",
  No_Prints:"Novum <a routerLink='/folder/Cheque/New'>Reprehendo</a> imprimere",
  Prints:"Primae",
  Find_Printer:"Imprime Typographus",
  Find_AirPrint_Devices:"AirPrint machinae Reperio",
  Select_Printer:"Select Typographus",
  System_UI:"Ratio UI",
  Printer:"Printer",
  Status:"Status",
  Preview:"Praevisio",
  Enable_Print_Job:"Print Job activare",
  Queue_Weight:"Queue Pondus",
  Unlimited:"immoderatus",
  Show_Advanced_Printer_Options:"Optiones Provectus Ostende Procer",
  Advanced:"profecit",
  Location:"Locus",
  Note:"Nota",
  Queue_Name:"Nomen queue",
  Pages_Printed_Limit:"Paginae Typis Limit",
  MultiPage_Idle_Time:"Multapage expecta tempus",
  Page_Count_Limit:"Page limitis comitis",
  Page_Orientation:"Page Orientatio",
  Portrait:"Imago",
  Landscape:"Tabula",
  Duplex:"Duplex",
  Double_Sided_Printing:"Duplex Sided",
  Duplex_Mode:"Duplex modus",
  Duplex_Short:"Brevis",
  Duplex_Long:"Longum",
  Duplex_None:"Nihil",
  Color_And_Quality:"Color et Qualitas",
  Monochrome:"Monochrome",
  Photo_Quality_Prints:"Photo Quality Prints",
  Printer_Email:"Impressor electronicus",
  Automatically_Downsize:"Automatarie Downsize",
  Paper:"Chartum",
  Paper_Name:"Paper nomen",
  Paper_Width:"Charta Latitudo",
  Paper_Height:"Charta altitudo",
  Paper_Autocut_Length:"Charta Auto-cut Longitudo",
  Margins:"orae",
  Page_Margins:"Paginae Marginibus",
  Page_Margin_Top:"Top Page Margin",
  Page_Margin_Right:"Page Margin",
  Page_Margin_Bottom:"Bottom Page Margine",
  Page_Margin_Left:" Pagina left margin ",
  Add_Employees:"Conductos addere",
  Header:"Caput",
  Print_A_Page_Header:"Imprimendi Pagina Header",
  Header_Label:"Caput Label",
  Header_Page_Index:"Index paginarum Header",
  Header_Font_Name:"Caput Fontis",
  Select_Font:"Fontem electum",
  Font_Selector:"Fontem Selector",
  Header_Font_Size:"Caput Fontis",
  Header_Bold:"Header Audax",
  Header_Italic:"Caput Italicum",
  Header_Alignment:"Header Alignment",
  Left:"reliquit",
  Center:"Metrum",
  Right:"Ius",
  Justified:"Iustificatus",
  Header_Font_Color:"Color Header",
  Select_Color:"Select Colorem",
  Footer:"Footer",
  Print_A_Page_Footer:"Imprimere Pagina Footer",
  Footer_Label:"Foter Label",
  Footer_Page_Index:"Index Page Footer",
  Footer_Font_Name:"Foter Font",
  Fonts:"Lucis Pelvis",
  Done:"Actum est",
  Select_Fonts:"Lucis Pelvis Lego",
  Footer_Font_Size:"Footer Size",
  Footer_Bold:"Footer Medium",
  Footer_Italic:"Footer Italica",
  Footer_Alignment:"Foter Alignment",
  Footer_Font_Color:"Color Footer",
  Page_Copies:"Idea Page",
  Enable_Printer:"Admitte Typographus",
  Remote_Logging:"Logging remotus",
  Log_Server:"Log Servo",
  Encryption:"Encryption",
  Random_Key:"Random Key",
  Encryption_Key:"Encryption Key",
  Cheques_Webserver:"Custom Database",
  Learn_How:"Disce quomodo",
  Signature:"Subscriptio",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Vide Subscriptio",
  Printed_Signature:"Impressum Subscriptio",
  Digitally_Sign:"Digitally Sign",
  Digital_Signature:"Subscriptio digitalis",
  Add_Signature:"Subscriptio addere",
  Add_Your_Signature:"Adde Subscriptio tua",
  Enable_Signature:"Admitte Subscriptio",
  Digitally_Signed:"Digitally Signatus",
  Insert_Error:"Non servare reprehendo ex database exitibus.",
  Delete_Confirmation:"Certe visne hanc informationem delere?",
  Discard_Confirmation:" Certusne esne hoc nuntios abicere?",
  Discard_Bank_Confirmation:"Certe vis hanc rationem abicere?",
  Discard_Printer_Confirmation:"Certe vis hunc typographum abicere?",
  Delete_Bonus_Confirmation:"Certe visne hunc bonum delere?",
  Delete_Invoice_Confirmation:"Certe vis hanc cautionem delere?",
  Generated_Cheque:"Generata Moderare",
  Generated_Invoice:"Cautionem generavit",
  Schedule_Start_Time:"Schedula Committitur",
  Schedule_End_Time:"Schedula Finis",
  New_Call:"Novum Call",
  No_Contacts:"Noti nulli",
  No_Contacts_Word:"Administratores, moderatores, operarii et payees ut contactus apparent",
  PDF_Subject:"Pecuniae",
  PDF_Keywords:"Parollarium paycheck PDF check checks",
  Printer_Setup:"Impressum Setup",
  Printer_Selection:"Printer Electio",
  New_Fax:"Fax Nova",
  New_Fax_Message:"Nuntius Fax Novae",
  Fax_Machine:"Fax machina",
  Fax_Name:"Fax nomen",
  Fax_Email:"Fax Inscriptio",
  Fax_Number:"Numerus Fax",
  Contents:"Contentum",
  Fax_Body:" Pagina Corpus ",
  Header_Word:"Caput",
  Header_Text:"Textus Header",
  Include_Header:"Include Header",
  Include_Footer:"Include Footer",
  Footer_Word:"Footer:",
  Footer_Text:"Footer Text",
  Attach_a_Cheque:"Cheque affigere",
  Add_Deduction:"Deductionem addere",
  Enable_Fax:"Fax mitte",
  Select_Fax:"Fax",
  No_Faxes:"Non Faxes",
  Faxes:"Faxes",
  Save_and_Send:"Fax mitte",
  Save_and_Pay:"Salve et redde",
  WARNING:"MONITUM:",
  Remember:"Memento",
  Printing:"Press",
  Printing_Complete:"Excudendi Complete!\n\n",
  of:"of",
  There_Were:"Erant ",
  Successful_Prints:" prints \" et ",
  Unsuccessful_Prints:"procus infaustus.",
  PrinterError:"Ignosce! Fuit error.",
  Printing_:"Impressio...",
  PrinterSuccess:"Documentum typis feliciter.",
  PrintersSuccess:"Documenta feliciter impressa.",
  Print_Cheques:"Printae SCUTULATUM",
  New_Message:"Nuntius novus",
  New_Messages:"Nuntiationes Novae",
  Read_Message:"Nuntius Legere",
  Write_Message:"Nuntium scribere",
  Send_Message:"Mittere nuntium",
  Subject:"Subiectum",
  Message:"nuntius",
  Writing:"Et Scriptis ...",
  Send:"Mitte",
  Set_Date:"Pone Date",
  Set_Time:"Constitue tempus",
  Recieve:"Accipe",
  Set_as_Default:"Set quod default",
  Default_Account:"Default Account",
  Default_Design:"Default Design",
  Multiple_Cheques:"Reprehendo (Tripple)",
  Account_Mode:"Ratio Modus",
  Multiple_Cheques_Description:"Tres per paginam compescit.",
  Check_and_Table:"Perscriptio & Tabula",
  Check_including_Table:"Reprehendo & rationem mensam.",
  Check_Mailer:"Reprehendo Mailer",
  Check_Mailer_Window:"Reprehendo cum inscriptione fenestra.",
  DocuGard_Table_Top:"DocuGard Perscriptio & Tabula (Top)",
  DocuGard_Table_Middle:"DocuGard Perscriptio & Tabula (Medio)",
  DocuGard_Table_Bottom:"DocuGard Perscriptio & Tabula (Bottom)",
  DocuGard_Mailer_Top:"DocuGard Perscriptio Mailer (Top)",
  DocuGard_Mailer_Middle:"DocuGard Perscriptio Mailer (Medio)",
  DocuGard_Mailer_Bottom:"DocuGard Perscriptio Mailer (Bottom)",
  DocuGard_Three_Cheques:"DocuGard SCUTULATUM (Tripple)",
  DocuGard_Cheque_Top:"DocuGard Perscriptio (Top)",
  DocuGard_Cheque_Middle:"DocuGard Perscriptio (Medio)",
  DocuGard_Cheque_Bottom:"DocuGard Perscriptio (Bottom)",
  DocuGard_Three_Cheques_Window:"Tres pagina una coercet.",
  DocuGard_Table_Top_Window:"Reprehendo & mercedes mensa.",
  DocuGard_Table_Middle_Window:"Reprehendo & mercedes mensa.",
  DocuGard_Table_Bottom_Window:"Reprehendo & mercedes mensa.",
  DocuGard_Mailer_Top_Window:"Perscriptio, mensa, et inscriptio.",
  DocuGard_Mailer_Middle_Window:"Perscriptio, mensa, et inscriptio.",
  DocuGard_Mailer_Bottom_Window:"Perscriptio, mensa, et inscriptio.",
  DocuGard_Cheque_Top_Window:"Reprehendo pro secure paper.",
  DocuGard_Cheque_Middle_Window:"Reprehendo pro secure paper.",
  DocuGard_Cheque_Bottom_Window:"Reprehendo pro secure paper.",
  Basic_Cheque:"Perscriptio (Top)",
  Basic_Cheque_Print:"Imprimere unum reprehendo.",
  Error_Setting_As_Paid:"Error Profecti",
  Add_Attachment:"Adscriptionis addere",
  PrinterUnavailable:"Impressor Unavailable",
  CreditCardComponent:"Cards",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Novum Depositum",
  Deposits:"Deposita",
  No_Deposits:"Nulla deposita",
  Credit_Card_Deposit:"Fidem Card",
  New_Credit_Card_Deposit_Message:"Promeritum Pecto Depositum",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Depositum BitCoin",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Translatio Interac",
  Payments_Limit:"Limit Payment",
  No_Payment_Limit:"Non Payment Limit",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Depositum PayPal",
  No_Deposits_Word:"Adde primam societatem <a routerLink='/folder/Deposit/New'>Depositum</a>.",
  No_Documents_Specified:"Nulla documenta certa imprimendi",
  No_Printers_Added:"Impressores nulli inventi sunt. Vade ad Occasus > Typographos unum addere.",
  DB_Erase_Prompt:"Prorsus totam database dele? MONITUM: Omnia condita notitia perdes!",
  Console_Warning:"Nolite aliquem textum in hunc consolare crustulum. Te ipsum et/vel consortium tuum in gravi periculo pones",
  No_Faxes_Word:"Create primum <a routerLink='/folder/Fax/New'>Fax</a>.",
  Cheque_Delete_Confirmation:"Certe vis hanc reprehendo delere?",
  Design_Delete_Confirmation:"Certe visne hoc consilium delere?",
  Cheque_Pay_Confirmation:"Vide reprehendo quod solvit? Non apparebit in print queue.",
  Payment_Pay_Confirmation:"Vide mercedem quam mercedem? Non apparebit in reprehendo queue.",
  Delete_Deduction_Confirmation:"Certe visne deductionem hanc delere?",
  Delete_Job_Confirmation:"Certe visne hoc officium delere?",
  Delete_Timesheet_Confirmation:"Certe vis hanc timesheet delere?",
  Delete_Schedule_Confirmation:"Certe vis hanc schedulam delere?",
  Delete_Setting_Confirmation:"Certe vis hoc occasum reset?",
  Delete_Fax_Confirmation:"Certe visne hanc fax delere?",
  Delete_File_Confirmation:"Certe visne hunc fasciculum delere?",
  Delete_Vacation_Confirmation:"Certe visne has ferias delere?",
  Delete_Printer_Confirmation:"Certe visne hunc typographum delere?",
  Remove_Design_Confirmation:"Certe visne hoc consilium delere?",
  Delete_Payroll_Confirmation:"Certe visne hunc stipendia delere?",
  Send_Fax_Email_Confirmation:"Visne ut fax et electronica haec documenta?",
  Send_Email_Confirmation:"Visne hoc documentum electronicas?",
  Send_Fax_Confirmation:"Visne hoc documentum fax?",
  Error_Generating_PDF:"Ignosce. Hoc documentum fuit error generans.",
  PDF_Error_Saving_Image:"Ignosce. Error salutaris fuit imago huius documenti PDF",
  Test_Printer_Confirmation:"Visne paginam in hac typographi nota imprimere?",
  Save_Timesheet_Prompt:"Quaeso addere 'Titulum' vel torcular 'Satus Vicis' servare.",
  Remove_Geofence_Prompt:"Certe visne situm huius geo-sepe tollere?",
  Delete_Employee_Confirmation:" Visne vero delere ",
  Fire_Employee_Confirmation:" Certus es ignis vis "
}