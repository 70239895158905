export const Sd = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"ڪاپي رائيٽ ۽ ڪاپي؛ 2023",
  black:"ڪارو",
  green:"سائو",
  gold:"سون",
  blue:"نيرو",
  brown:"ناسي",
  purple:"واڱڻائي",
  pink:"گلابي",
  red:"ڳاڙهو",
  Swatches:"سُوچ",
  HSL:"ايڇ ايس ايل",
  RGB:"آر جي بي",
  Hue:"رنگ",
  Saturation:"سنترپت",
  Lightness:"روشني",
  Upgrade_To_Pro:"پرو ڏانهن اپڊيٽ ڪريو",
  AllFeaturesInclude:"سڀ خصوصيتون شامل آهن:",
  PrintUnlimitedCheques:"لامحدود چيڪ پرنٽ ڪريو",
  PremiumChequeDesigns:"پريميئم چيڪ ڊيزائن",
  ManageUnlimitedEmployees:"لامحدود ملازمن کي منظم ڪريو",
  AddUnlimitedPayees:"لامحدود ادا ڪندڙ شامل ڪريو",
  CreateUnlimitedPayrolls:"لامحدود پگهار ٺاهيو",
  UnlimitedSchedulesandTimeSheets:"لامحدود شيڊول ۽ ٽائيم شيٽ",
  BulkPayPalPayouts:"بلڪ PayPal ادائيگيون",
  UnlimitedBankAccounts:"لا محدود بئنڪ اڪائونٽس",
  ManageMultipleCompanies:"ڪيترن ئي ڪمپنين کي منظم ڪريو",
  TrackInsurancePolicies:"ٽريڪ انشورنس پاليسين",
  Bio_MetricProtection:"بايو ميٽرڪ تحفظ",
  Geo_FenceLock_OutProtection:"جيو-فينس لاڪ آئوٽ تحفظ",
  Multiple_Companies_Word:"ڪيترن ئي ڪمپنين جو انتظام چيڪ پريميئم کانسواءِ دستياب ناهي.",
  PayPal_Payouts_Word:"PayPal ادائگيون چيڪ پريميئم کان سواءِ بند ٿيل آھن.",
  PINProtection:"PIN تحفظ",
  PasswordProtection:"پاسورڊ تحفظ",
  May_Require_Approval:"جي منظوري جي ضرورت ٿي سگھي ٿو.",
  Subscribe:"رڪنيت حاصل ڪريو",
  Billed:"بل ڪيل",
  Up:"مٿي",
  Down:"هيٺ",
  Positioning:"پوزيشن",
  Marker:"مارڪر",
  Drag_Marker:"ڇڪيو مارڪر",
  Tagline:"پرنٽ چيڪ ۽ ٽيبلٽ پي رول",
  Marker_Word:"عنصر کي ماپ ڪرڻ لاء مارڪر استعمال ڪريو.",
  Pinch_Zoom:"پنچ زوم",
  Pinch_Word:"عنصر کي وڏو ڪرڻ لاءِ پنچ ڪريو.",
  Drag:"ڇڪيو",
  Drag_Word:"عناصر کي ڇڪڻ لاء پنھنجي آڱر استعمال ڪريو.",
  subscription_alias_word:"خودڪار تجديد رڪنيت",
  premium_alias_word:"ھڪڙي وقت جي واڌاري پيڪيج",
  print_alias_word:"پرنٽ آئوٽ انفرادي چيڪ",
  mode_alias_word:"موڊ",
  Pro:"پرو",
  Pro_word:"پرو نسخو گھربل.",
  Cant_Delete_Default_Company_Word:"معاف ڪجو، توھان پنھنجي ڊفالٽ ڪمپني کي ختم نٿا ڪري سگھو .",
  Reinsert_Default_Designs:"ٻيهر داخل ڪريو ڊفالٽ ڊيزائن",
  Reinsert_Default_Designs_word:"ڇا توهان ڊفالٽ ڊيزائن کي ٻيهر داخل ڪرڻ چاهيو ٿا؟",
  Subscription_Active_Disable_Word:"هي رڪنيت فعال آهي. ڇا توھان ھن رڪنيت کي منسوخ ڪرڻ چاھيو ٿا چيڪ لاءِ؟",
  Company_Logo:"ڪمپني لوگو",
  ZERO_:"ٻُڙي",
  Disclaimer:"رد ڪرڻ",
  Privacy_Policy:"رازداري پاليسي",
  EULA:"EULA چيڪ ڪري ٿو",
  Terms_Of_Service:"ڪم جا شرط",
  Terms_Of_Use:"استعمال جا شرط",
  Refunds:"واپسي جي پاليسي",
  Single_Print:"1 چيڪ ڪريو",
  Two_Prints:"2 چيڪ",
  Five_Prints:"5 چيڪ",
  Ten_Prints:"10 چيڪ",
  Fifteen_Prints:"15 چيڪ",
  Twenty_Prints:"20 چيڪ",
  Thirty_Prints:"30 چيڪ",
  Image_Added:"تصوير شامل ڪئي وئي",
  Image_Preview:"تصوير جو ڏيک",
  No_Image_Was_Selected:"ڪابه تصوير منتخب نه ڪئي وئي.",
  Cheques_Unlimited:"لامحدود چيڪ ڪريو",
  _Subscription:"رڪنيت",
  Subscription:"چيڪ - 1 مهينو",
  Two_Month_Subscription:"چيڪ - 2 مهينا",
  Three_Month_Subscription:"چيڪ - 3 مهينا",
  Six_Month_Subscription:"چيڪ - 6 مهينا",
  Twelve_Month_Subscription:"چيڪ - 12 مهينا",
  Cheques_Are_Available:"چيڪ پرنٽ ڪرڻ لاء موجود آهن.",
  Upgrade_Required_Two:"ھڪڙو پيڪيج چونڊيو ۽ پنھنجي خريداري شروع ڪرڻ لاءِ قيمت واري بٽڻ تي ڊبل ٽيپ ڪريو. پرنٽ پروفيشنل ڏسندڙ مڪمل-رنگ چيڪ سيڪنڊن ۾.",
  Month:"مهينو",
  Due:"واجب الادا",
  Expires:"ختم ٿئي ٿو:",
  Subscription_Active:"رڪنيت فعال",
  Subscription_Inactive:"رڪنيت غير فعال",
  Purchase_Additional_Cheques:"اضافي چيڪ خريد ڪريو؟",
  Printable_Cheque:"ڇپيل چيڪ",
  Printable_Cheques:"ڇپيل چيڪ",
  Printable_Cheque_Word:"چيڪ توهان جي اڪائونٽ تي موجود آهي.",
  Printable_Cheques_Word:"چيڪ توهان جي اڪائونٽ تي موجود آهن.",
  Max_Amount_Message:"توهان جي بيان ڪيل رقم هن سسٽم لاءِ مقرر ڪيل وڌ کان وڌ رقم تي پهچي چڪي آهي:",
  Terms_Required_Word:"چيڪ استعمال ڪرڻ جاري رکڻ کان پهريان توهان کي هن معاهدي تي متفق ٿيڻ گهرجي.",
  Subscriptions:"رڪنيت",
  Product_Upgrades:"واڌارو",
  Mailed_Out_Cheques:"ٽپال-آئوٽ چيڪ",
  Enter_A_Company_Name:"مھرباني ڪري ھڪڙي ڪمپني جو نالو داخل ڪريو.",
  Single_Cheques:"سنگل چيڪ",
  Cheque_Golden:"گولڊن چيڪ",
  Cheque_Golden_Window:"گولڊن چيڪ ڊيزائن.",
  Cheque_Green:"گرين چيڪ",
  Cheque_Green_Window:"گرين چيڪ ڊيزائن.",
  Cheque_Red:"ڳاڙهو چيڪ",
  Cheque_Red_Window:"لال چيڪ ڊيزائن.",
  Cheque_Yellow:"زرد چيڪ",
  Cheque_Yellow_Window:"زرد چيڪ ڊيزائن.",
  Cheque_Statue_of_Liberty:"آزاديءَ جو مجسمو",
  Cheque_Statue_of_Liberty_Window:"مجسمي آف لبرٽي چيڪ ڊيزائن.",
  Cheque_Green_Wave:"سائي موج",
  Cheque_Green_Wave_Window:"گرين چيڪ ڊيزائن.",
  Cheque_Golden_Weave:"گولڊن بنايو",
  Cheque_Golden_Weave_Window:"خوبصورت گولڊن چيڪ ڊيزائن.",
  Cheque_Green_Sun:"سائو سج",
  Cheque_Green_Sun_Window:"گہرے ۽ پرسکون چيڪ ڊيزائن.",
  Cheque_Blue_Checkers:"بليو چيڪرز",
  Cheque_Blue_Checkers_Window:"بليو چيڪ ڊيزائن.",
  Cashiers_Check:"ڪيشيئر جي چيڪ",
  Cashiers_Check_Window:"ڪيشيئر جي چيڪ واري انداز سان ٺهيل.",
  Cheque_Aqua_Checkers:"ايوا چيڪرز",
  Cheque_Aqua_Checkers_Window:"Aqua چيڪ ڊيزائن.",
  Cheque_Golden_Checkers:"گولڊن چيڪرز",
  Cheque_Golden_Checkers_Window:"گولڊن چيڪ ڊيزائن.",
  Upgrade_Unavailable:"اپڊيٽس دستياب ناهي",
  Bank_Code_Protection:"بئنڪ نمبر تحفظ",
  Bank_Code_Protection_Word:"توهان جي بئنڪ نمبرن کي هن ايپ تي استعمال ٿيڻ کان بچايو جيڪو ڪنهن ٻئي ڊوائيس تي يا ڪنهن ٻئي صارف لاءِ. هي عمل ناقابل واپسي آهي. جاري رکو؟",
  Bank_Code_Protection_Blocked_Word:"بئنڪ نمبر جيڪي توهان استعمال ڪرڻ جي ڪوشش ڪري رهيا آهيو ڪنهن ٻئي صارف يا ڊوائيس لاءِ محفوظ آهن.",
  Bank_Code_Protection_Error_Word:"نمبر جي تصديق ناڪام ٿي وئي آهي. مھرباني ڪري انٽرنيٽ سان ڳنڍيو ۽ ھن بئنڪ اڪائونٽ کي ٻيهر شامل ڪرڻ جي ڪوشش ڪريو.",
  Bank_Code_Protection_Set_Error_Word:"بئنڪ نمبر تحفظ جي ضرورت آهي ته توهان انٽرنيٽ سان ڳنڍيل آهيو. مهرباني ڪري ڳنڍيو ۽ ٻيهر ڪوشش ڪريو.",
  Upgrade_Unavailable_Word:"معاف ڪجو، اسان کي توهان جي تصديق ڪرڻ ۾ مشڪل ٿي رهي آهي. سبسڪرپشن ۽ اپ گريڊ چيڪن تي في الحال توهان جي علائقي ۾ خريداري لاءِ دستياب ناهي.",
  PayPal_Payment_Preview:"PayPal ادائگي جو ڏيک",
  Apple_Pay:"ايپل ادا",
  Select_PayPal:"منتخب ڪريو PayPal",
  PayPal_Applications:"PayPal ايپليڪيشنون",
  Purchase_With_Apple_Pay:"ايپل ادا سان خريد ڪريو",
  Google_Pay:"گوگل ادا",
  Companies:"ڪمپنيون",
  Insurance:"بيمه",
  New_PayPal:"نئون PayPal",
  Pay_By:"ادا ڪندي",
  Insure:"بيمه",
  Miles:"ميلو",
  Payment_Method:"ادائگي جو طريقو",
  Select_Policies:"منتخب ڪريو پاليسيون",
  Policies:"پاليسيون",
  Policy:"پاليسي",
  No_PayPal_Account:"ڪوبه PayPal اڪائونٽ ناهي",
  No_Policies:"ڪا به انشورنس پاليسي ناهي",
  New_Policy:"نئين پاليسي",
  PayPal_Payment:"PayPal ادائگي",
  PayPal_Payments:"PayPal ادائگيون",
  No_Payment_Selected:"ڪابه ادائگي منتخب ٿيل ناهي",
  Sending_Payment_Word:"مھرباني ڪري انتظار ڪريو... ھي ادائيگي موڪلي پئي وڃي.",
  Sending_Payments_Word:"مھرباني ڪري انتظار ڪريو... ادائگيون موڪليون وڃن ٿيون.",
  No_Payment_Selected_PayPal:"نه <a routerLink='/folder/Payments'> PayPal ادائيگي</a> موڪلڻ لاءِ چونڊيو ويو.",
  Payment_Sent:"ادائيگي موڪلي وئي",
  PayPal_Payment_Sent:"هي ادائيگي PayPal سان موڪليو ويو آهي.",
  Copay:"ڪاپي",
  Dead:"مئل",
  Alive:"جيئرو",
  Not_Dead:"نه مئل",
  Unclaimed:"اڻ اعلانيل",
  Attempted:"ڪوشش ڪئي",
  Deceased:"فوت ٿيل",
  Claimed:"دعويٰ ڪئي",
  Unpaid:"ادا نه ڪيل",
  Sending_Payment:"ادائيگي موڪلڻ",
  Sending_Payments:"ادائگي موڪلڻ",
  Send_PayPal_Confirmation:"ڇا توھان ھن ٽرانزيڪشن کي PayPal سان موڪلڻ چاھيو ٿا؟",
  Send_PayPal_Confirmation_Word:"هي ٽرانزيڪشن موڪلڻ لاءِ سائي بٽڻ کي دٻايو.",
  Save_Payment_As_Unpaid:"هن ادائگي کي غير ادا ڪيل طور تي بچايو؟",
  Payment_Pay_Confirmation_PayPal:"هن ادائگي کي محفوظ ڪريو ادا ڪيل طور تي؟",
  No_Policies_Word:"شامل ڪريو پھريون <a routerLink='/folder/Postage/New'> انشورنس پاليسي</a> ھاڻي.",
  Timesheet_Multiple_Delete_Confirmation:"ڇا توهان پڪ سان گھڻن ٽائم شيٽس کي حذف ڪرڻ چاھيو ٿا؟",
  Select_Multiple_Timesheets_Prompt:"ڪابه ٽائم شيٽ چونڊيل ناهي. گهٽ ۾ گهٽ هڪ ٽائيم شيٽ چونڊيو.",
  Select_Multiple_Policies_Prompt:"ڪابه پاليسي نه چونڊيل آهي. گهٽ ۾ گهٽ هڪ انشورنس پاليسي چونڊيو.",
  Policies_Multiple_Delete_Confirmation:"ڇا توھان کي پڪ آھي ته توھان گھڻن پاليسين کي ختم ڪرڻ چاھيو ٿا؟",
  Company:"ڪمپني",
  Add_Company:"ڪمپني شامل ڪريو",
  New_Company:"ڪمپني شامل ڪريو",
  No_Companies:"ڪابه ڪمپنيون",
  Enable_Company:"ڪمپني کي فعال ڪريو",
  Select_Company:"ڪمپني چونڊيو",
  The_Default_Company:"ڊفالٽ ڪمپني ليبل.",
  Manage_Companies:"ڪمپنيون منظم ڪريو",
  No_Companies_Word:"چيڪ هڪ ڊفالٽ ڪمپني استعمال ڪندا .<br /> شامل ڪريو <a routerLink='/folder/Company/New'> پهرين ڪمپني</a>.",
  Default_Company:"ڊفالٽ ڪمپني",
  Cheques_Unlimited_Word:"چيڪ لامحدود توهان کي اجازت ڏئي ٿو ته توهان چاهيو ته ڪيترا چيڪ پرنٽ ڪريو.",
  Cheques_Subscription_Word:"هڪ چيڪ سبسڪرپشن توهان کي اجازت ڏئي ٿو ته توهان چاهيو ته ڪيترا ئي چيڪ پرنٽ ڪريو.",
  You_Own_This_Product:"توھان ھي پراڊڪٽ جا مالڪ آھيو.",
  Your_Subscription_is_Active:"توھان جي رڪنيت فعال آھي.",
  Active_Products:"چالو مصنوعات",
  Purchase_Confirmation:"خريداري",
  Purchase_Cheques:"خريداري چيڪ",
  Restore_Purchase:"خريداري بحال ڪريو",
  Erase_Purchase:"خريداري کي ختم ڪريو",
  Successfully_Purchased:"ڪاميابيءَ سان خريد ڪيو ويو",
  Enter_Your_Licence_Key:"مھرباني ڪري ھن صفحي تي پنھنجي لائسنس جي چيڪ داخل ڪريو ھن پراڊڪٽ کي چالو ڪرڻ لاءِ.",
  Licence_Key:"لائسنس جي چاٻي",
  Enter_Licence_Key:"داخل ڪريو لائسنس چيڪ",
  Purchased:"خريد ڪيل",
  Enable_Feature:"خصوصيت کي فعال ڪريو",
  Cancel_Subscription:"رڪنيت منسوخ ڪريو",
  Subscription_Removed:"رڪنيت ختم ڪئي وئي",
  Select_Active_Subscription:"ان کي تبديل ڪرڻ لاءِ فعال رڪنيت چونڊيو.",
  Remove_Subscription_Word:"ڇا توھان واقعي ھن رڪنيت کي منسوخ ڪرڻ چاھيو ٿا؟",
  Delete_Company_Confirmation:"ڇا توهان واقعي هن سڄي ڪمپني کي ختم ڪرڻ چاهيو ٿا؟ خبردار: توهان سڀ محفوظ ڪيل معلومات وڃائي ويهندا!",
  Delete_Default_Company_Word:"توهان ڊفالٽ ڪمپني کي ختم نه ٿا ڪري سگهو . ڇا توھان ائپليڪيشن کي ري سيٽ ڪرڻ چاھيو ٿا ۽ ان کي ڊفالٽ حالت ۾ بحال ڪرڻ چاھيو ٿا؟ خبردار: توهان سڀ محفوظ ڪيل معلومات وڃائي ويهندا!",
  Console_Warning_2:"هي ايپليڪيشن استعمال ڪندي ڪنهن به ڪرنسي کي هٿي نه ڏيو جيڪا هن وقت توهان جي ناهي.",
  Terms_and_Conditions:"شرط ۽ ضابطا",
  and_the:"۽ جي",
  for:"لاءِ",
  Please_Read_Word:"مهرباني ڪري پڙهو ۽ متفق آهيو",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"ڪجهه ڪرنسي مٽائڻ جي شرح نه ملي سگهي. مهرباني ڪري انٽرنيٽ سان ڳنڍيو.",
  Free:"واندو",
  DB_Erase_Prompt_2:"مڪمل طور تي مڪمل ڊولپر ڊيٽابيس کي ختم ڪريو؟ خبردار: توهان سڀ خريداري ۽ سبسڪرپشن جي معلومات وڃائي ڇڏيندؤ!",
  Successfully_Imported:"ڪاميابيءَ سان درآمد ڪيو ويو",
  Select_Postage:"منتخب ڪريو پوسٽ",
  No_Postage:"ڪابه پوسٽ اسٽيمپ",
  No_Paid_Postage_Word:"شامل ڪريو پھريون <a routerLink='/folder/Postage/New'> ادا ڪيل پوسٽج</a> اسٽام.",
  Trial_Complete:'آزمائش مڪمل',
  Please_Upgrade:'مھرباني ڪري ڇپائي جاري رکڻ لاءِ چيڪ اپ گريڊ ڪريو.',
  Aa:"آ",
  Color:"رنگ",
  Font:"فونٽ",
  Guide:"ھدايت",
  Guides:"رهنما",
  Image:"تصوير",
  Zoom:"زوم",
  Logo:"لوگو",
  Bank:"بئنڪ",
  MICR:"MICR",
  Total:"ڪُل",
  Cancel:"منسوخ ڪريو",
  Confirm:"تصديق ڪريو",
  Method:"طريقو",
  Biometric_Security:"بايو ميٽرڪ سيڪيورٽي",
  Please_Login_To_Continue:"مھرباني ڪري جاري رکڻ لاءِ لاگ ان ٿيو.",
  Complete:"مڪمل",
  Sign_Up:"سائن اپ ڪريو",
  Error:"نقص",
  Biometrics:"بايو ميٽرڪس",
  Percent:"سيڪڙو",
  Zero_Percent:"0%",
  Top_Margin:"مٿي مارجن",
  Bottom_Margin:"هيٺيون مارجن",
  Left_Margin:"کاٻي مارجن",
  Right_Margin:"ساڄي مارجن",
  MICR_Margin:"MICR مارجن",
  Table_Margin:"ٽيبل مارجن",
  Address_Margin:"ائڊريس مارجن",
  Percentage_:"سيڪڙو",
  Vacation_Title:"موڪل جو عنوان",
  Use_PIN:"استعمال ڪريو PIN",
  Loading__:"لوڊ ٿي رهيو آهي...",
  Design_Title:"ڊيزائن عنوان",
  Authorize:"اختيار ڏيڻ",
  Key:"چاٻي",
  Public_Key:"عوامي چاٻي",
  Private_Key:"خانگي چاٻي",
  Authenticate:"تصديق ڪريو",
  Last_Payroll:"آخري پگهار",
  Last_Calculation:"آخري حساب",
  Authorized:"بااختيار",
  Geo_Authorized:"جيو-مقام: مجاز",
  Not_Authorized:"مجاز نه آهي",
  Authorization_Complete:"اختيار مڪمل",
  Geolocation_Authorization:"جيو-مقام اختيار ڪرڻ",
  Out_of_Bounds:"حد کان ٻاهر",
  Cant_Delete_Default_Design:"ڊفالٽ ڊيزائن کي ختم نٿو ڪري سگھجي.",
  Unauthorized_Out_of_Bounds:"غير مجاز: حد کان ٻاهر",
  Biometric_Authorization:"بايو ميٽرڪ اختيار",
  Locating_Please_Wait:"ڳولي رهيو آهي، مهرباني ڪري انتظار ڪريو...",
  Test_Biometrics:"ٽيسٽ بايو ميٽرڪ",
  Cheque_Margins:"مارجن چيڪ ڪريو",
  Percentage_Full_Error:"فيصد فيلڊ 100 سيڪڙو کان مٿي مقرر نه ٿي ڪري سگھجي.",
  No_Payroll_Text:"شامل ڪريو هڪ <a routerLink='/folder/Employee/New'>ملازم</a> or <a routerLink='/folder/Payee/New'>ادا ڪندڙ</a> and a <a routerLink='/folder/Schedule/New'>شيڊول</a>.",
  Design_Saved:"محفوظ ٿيل ڊيزائن",
  Default_Design_Selected:"ڊفالٽ ڊيزائن چونڊيو ويو",
  Partial_Import:"جزوي درآمد",
  Partial_Export:"جزوي برآمد",
  Entire_Import:"پوري درآمد",
  Entire_Export:"مڪمل برآمد",
  Existing_Password:"مھرباني ڪري پنھنجو موجوده پاسورڊ داخل ڪريو:",
  Existing_PIN:"مھرباني ڪري پنھنجو موجوده پن ڪوڊ داخل ڪريو:",
  Pin_Change_Header:"PIN جي تصديق",
  Pin_Change_SubHeader:"تبديلي جي تصديق ڪرڻ لاءِ پنھنجو پراڻو پن نمبر داخل ڪريو.",
  Pass_Change_Header:"پاسورڊ جي تصديق",
  Pass_Change_SubHeader:"تبديلي جي تصديق ڪرڻ لاءِ پنھنجو پراڻو پاسورڊ داخل ڪريو.",
  PIN_Enter_Message:"تصديق ڪرڻ لاءِ پنهنجو پن داخل ڪريو.",
  Password_Enter_Message:"تصديق ڪرڻ لاءِ پنهنجو پاسورڊ داخل ڪريو.",
  Pin_Updated_Success:"PIN ڪاميابيءَ سان اپڊيٽ ٿيو!",
  Pin_Updated_Fail:"PIN اپڊيٽ نه ٿي سگهيو.",
  Pass_Updated_Success:"پاسورڊ ڪاميابي سان اپڊيٽ ڪيو ويو.",
  Pass_Updated_Fail:"پاسورڊ اپڊيٽ نه ٿي سگهيو.",
  Preview_Payment:"ادائگي جو جائزو وٺو",
  Preview_Payroll:"پريو پي رول",
  No_Payments_Created:"ڪا به ادائگي پيدا نه ڪئي وئي.",
  Payment_Preview:"ادائگي جو ڏيک",
  Enable_Payee:"Payee کي فعال ڪريو",
  Rendered:"پيش ڪيل",
  No_Email:"نه اي ميل",
  Setup_Cheques:"سيٽ اپ چيڪ",
  name:"نالو",
  address:"پتو",
  address_2:"ايڊريس لائن 2",
  city:"شهر",
  province:"صوبو",
  postal_code:"پوسٽل / زپ ڪوڊ",
  country:"ملڪ",
  username:"يوزر نالو",
  full_name:"پورو نالو",
  birthday:"سالگراه",
  email:"اي ميل",
  phone:"فون",
  employees:"ملازمن",
  addresses:"پتا",
  payment_amount_limit:"ادائگي جي رقم جي حد",
  total_limit:"ڪل حد",
  payees:"ادا ڪندڙ",
  description:"وصف",
  address_line_one:"ايڊريس لائن ون",
  address_line_two:"ايڊريس لائين ٻه",
  image:"تصوير",
  account_holder:"کاتيدار",
  cheque_starting_id:"چيڪ ڪريو شروعاتي ID",
  transit_number:"ٽرانزٽ نمبر",
  institution_number:"ادارو نمبر",
  designation_number:"نامزدگي نمبر",
  account_number:"اڪائونٽ نمبر",
  currency:"ڪرنسي",
  signature:"دستخط",
  payment_payroll_limit:"ادائگي جي حد",
  total_limi:"ڪل حد",
  date:"تاريخ",
  printed_memo:"ڇپيل ميمو",
  banks:"بئنڪون",
  signature_image:"دستخطي تصوير",
  address_name:"ايڊريس جو نالو",
  notes:"نوٽس",
  design:"ڊيزائن",
  title:"عنوان",
  frequency:"تعدد",
  fax:"فيڪس",
  salaries:"تنخواه",
  salary_ids:"تنخواه IDs",
  start_time:"شروعاتي وقت",
  end_time:"آخري وقت",
  paid:"ادا",
  overtime_percentage:"ادا ڪيل سيڪڙو",
  overtime_amount:"ادا ٿيل مقرر رقم",
  first_name:"پهريون نالو",
  last_name:"آخري نالو",
  moderation:"اعتدال",
  create:"ٺاهيو",
  edit:"ترميم ڪريو",
  destroy:"ناس ڪرڻ",
  day_start_time:"ڏينهن_شروع_وقت",
  day_end_time:"ڏينهن_آخر_وقت",
  fullname:"نالو",
  time:"وقت",
  auto_send:"خودڪار طور تي موڪليو",
  time_method:"وقت جو طريقو",
  schedules:"شيڊول",
  indefinite_payroll_enabled:"غير يقيني طور تي فعال ڪريو",
  amount:"رقم",
  repeat:"ورجائي",
  always_enabled:"هميشه فعال",
  start_date:"شروعاتي تاريخ",
  end_date:"ختم ٿيڻ جي تاريخ",
  Cheque_Total:"مجموعي چيڪ ڪريو",
  Total_Amount:"ڪل رقم:",
  Amounts:"رقم:",
  Images:"تصويرون",
  Files:"فائلون",
  Previewing:"ڏيک ڏيڻ:",
  Insert:"داخل ڪريو",
  Preview_Import:"ڏيکاءُ",
  Entry:"داخلا",
  Entries:"داخلائون",
  No_Entries:"ڪابه داخلا",
  Import_Type:"درآمد جو قسم",
  Select_Details:"منتخب ڪريو تفصيل",
  Select_Payee:"منتخب ڪريو Payee",
  Enable_Holidays:"موڪلن کي فعال ڪريو",
  Disable_Holidays:"موڪلن کي بند ڪريو",
  Wire_Transfer:"وائر جي منتقلي",
  New_Export:"نئون برآمد",
  Export_Data:"ڊيٽا برآمد ڪريو",
  Export_Data_Word:"برآمد ۽ ڊائون لوڊ ڪرڻ لاء فائل جو قسم چونڊيو.",
  Export_File:"ايڪسپورٽ فائل",
  Mode:"موڊ",
  Times:"ٽائمز",
  Widgets:"وجيٽس",
  Slider:"سلائڊر",
  Set_Details:"تفصيلات سيٽ ڪريو",
  Select_Type:"منتخب ڪريو قسم",
  Display_Slider:"ڊسپلي سلائڊر",
  Dashboard_Slider:"ڊيش بورڊ سلائڊر",
  Dashboard_Mode:"ڊيش بورڊ موڊ",
  Show_Intro:"تعارف ڏيکاريو",
  Show_Payrolls:"پگهار ڏيکاريو",
  Show_Holidays:"موڪلون ڏيکاريو",
  Show_Invoices:"انوائس ڏيکاريو",
  Show_Cheques:"چيڪ ڏيکاريو",
  Enabled_Widgets:"فعال ٿيل ويجيٽس",
  Disabled_Widgets:"بند ٿيل ويجيٽس",
  Colors:"رنگ",
  Barcodes:"بارڪوڊس",
  View_Timers:"ٽائمر ڏسو",
  Gradients:"رتبي",
  No_Info:"ڪابه ڄاڻ ناهي",
  Disable:"نااهل",
  Show_Layer:"پرت ڏيکاريو",
  Hide_Layer:"پرت لڪايو",
  Text_Layer:"ٽيڪسٽ پرت",
  Secondly:"ٻيو",
  Minutely:"منٽ ۾",
  nine_am:"9:00 ايم",
  five_pm:"5:00 پي ايم",
  Enable_Address:"ايڊريس کي فعال ڪريو",
  Invalid_File_Type:"معاف ڪجو، هڪ غلط فائل جو قسم چونڊيو ويو. سپورٽ ٿيل فائل جو قسم:",
  Error_Updating_Entry:"معاف ڪجو، هن داخلا کي اپڊيٽ ڪرڻ ۾ هڪ غلطي هئي.",
  Schedule_Timing_Alert:"غلطي: شيڊول جي شروعاتي وقت ختم ٿيڻ واري وقت کان پوء ھڪڙي قدر تي مقرر ڪئي وئي آھي.",
  Select_Multiple_Payments_Prompt:"ڪابه ادائگي منتخب ٿيل ناهي. گهٽ ۾ گهٽ هڪ ادائيگي چونڊيو.",
  Select_Multiple_Cheques_Prompt:"ڪو به چيڪ نه چونڊيو ويو آهي. مھرباني ڪري گھٽ ۾ گھٽ ھڪڙو چيڪ چونڊيو.",
  Select_Multiple_Items_Prompt:"ڪابه شيون منتخب ٿيل نه آهن. مھرباني ڪري گھٽ ۾ گھٽ ھڪڙي شيءِ چونڊيو.",
  Paymemt_Multiple_Delete_Confirmation:"ڇا توھان پڪ آھي گھڻن ادائگين کي ختم ڪرڻ چاھيو ٿا؟",
  Cheque_Multiple_Delete_Confirmation:"ڇا توھان کي پڪ آھي ته توھان گھڻن چيڪن کي ختم ڪرڻ چاھيو ٿا؟",
  Payee_Multiple_Delete_Confirmation:"ڇا توھان پڪ سان گھڻن ادا ڪندڙن کي ختم ڪرڻ چاھيو ٿا؟",
  Employee_Multiple_Delete_Confirmation:"ڇا توھان پڪ آھي گھڻن ملازمن کي ختم ڪرڻ چاھيو ٿا؟",
  MICR_Warning:"نوٽ: ڪجهه پرنٽر ۽ ڊوائيس شايد MICR فونٽ صحيح نموني نه ڏيکارين.",
  Automatically_Send:"خودڪار طور تي موڪليو",
  Type:"قسم",
  Payment_Type:"ادائگي جو قسم",
  Auto_Send:"خودڪار موڪل",
  Automatically_Process:"خودڪار طريقي سان عمل ڪريو",
  Auto_Process:"خودڪار عمل",
  Image_Based:"تصوير جي بنياد تي",
  Font_Based:"فونٽ تي ٻڌل",
  Rerender:"ٻيهر پيش ڪرڻ",
  Rendering:"رنڊڪ",
  Render:"فائلون",
  Top:"مٿي",
  Middle:"وچولي",
  Bottom:"هيٺيون",
  Top_Left:"مٿي کاٻي",
  Top_Center:"مٿي سينٽر",
  Top_Right:"مٿي ساڄي",
  Middle_Left:"وچ ۾ کاٻي",
  Middle_Center:"وچ سينٽر",
  Middle_Right:"وچئين ساڄي",
  Bottom_Left:"هيٺان کاٻي",
  Bottom_Center:"هيٺيون مرڪز",
  Bottom_Right:"هيٺيون ساڄي",
  Numbers:"نمبر",
  Verified:"تصديق ٿيل",
  Paper_Size:"ڪاغذ جي ماپ",
  New_Device:"نئين ڊوائيس",
  Add_Device:"ڊوائيس شامل ڪريو",
  Remove_Device:"ڊوائيس کي هٽايو",
  Delete_Device:"ڊيوائس کي ختم ڪريو",
  Block_Device:"بلاڪ ڊيوائس",
  Block:"بلاڪ",
  Unblock:"بلاڪ ڪريو",
  Table:"ٽيبل",
  Scan_Login_Code:"لاگ ان ڪوڊ اسڪين ڪريو",
  Login_Code:"لاگ ان ڪوڊ",
  Scan_Code:"اسڪين ڪوڊ",
  Scan_QR_Code:"QR ڪوڊ اسڪين ڪريو",
  Select_All:"سڀ چونڊيو",
  Deselect_All:"سڀ ختم ڪريو",
  Increase:"واڌارو",
  Decrease:"گهٽتائي",
  Bold:"بزدل",
  Text:"متن",
  Style:"انداز",
  Italic:"اٽالڪ",
  QR_Code:"QR ڪوڊ",
  Barcode:"بارڪوڊ",
  Browse_Images:"برائوز تصويرون",
  Browse_Files:"براؤز فائلون",
  Background_Image:"پسمنظر جي تصوير",
  Logo_Image:"لوگو تصوير",
  Header_Image:"هيڊر تصوير",
  Bank_Image:"بئنڪ تصوير",
  Cut_Lines:"سٽون لائينون",
  Background:"پس منظر",
  License:"لائسنس",
  One_License:"1 لائسنس:",
  Licensed:"لاءِ لائسنس ٿيل:",
  Not_Licensed:"لائسنس نه آهي",
  Enter_Serial:"سيريل داخل ڪريو",
  Serial_Key:"سيريل چاٻي",
  Serial:"سيريل",
  Product_Key:"پيداوار جي چاٻي",
  Check_Product_Key:"چيڪ ڪريو پراڊڪٽ ڪي",
  Add_Product_Key:"شامل ڪريو پراڊڪٽ ڪي",
  Verifying_Purchase:"خريداري جي تصديق ڪئي پئي وڃي...",
  Print_Envelope:"پرنٽ لفافو",
  Envelope:"لفافو",
  Thank_You:"توهان جي مهرباني!",
  Scale:"اسڪيل",
  Print_Scale:"پرنٽ اسڪيل",
  Borders:"سرحدون",
  VOID:"VOID",
  Void_Cheque:"باطل چيڪ",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"جي آرڊر تي ادا ڪريو:",
  NO_DOLLARS:"نه ڊالر ",
  ONE_DOLLAR:"هڪ ڊالر",
  DOLLARS:" ڊالر",
  AND:" ۽ ",
  CENTS:" سينٽ.",
  NO_:"نه ",
  ONE_:"هڪ ",
  AND_NO_:"۽ نه ",
  _AND_ONE_:"۽ هڪ ",
  DOLLARS_AND_ONE_CENT:" ۽ هڪ سينٽ.",
  AND_NO_CENTS:" ۽ صفر سينٽ.",
  CHEQUE_PRINT_DATE:"تاريخ:",
  CHEQUE_PRINT_MEMO:"ميمو:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"ايم پي",
  Initial_Tasks:"ابتدائي ڪم",
  Inital_Setup:"ابتدائي سيٽ اپ",
  Welcome_To:"ڀليڪار ڪيون ٿا ",
  Welcome:"ڀلي ڪري آيا",
  Swipe:"سوائپ",
  Intro_Setup:"انٽرو سيٽ اپ",
  Introduction:"تعارف",
  CHEQUE_PRINT_CREDIT:"چڪ پاران طاقتور",
  Title_Intro_Word:"چڪ ۾ ڀليڪار",
  Title_Intro:"چڪ جو تعارف",
  Title_Setup:"چيڪ سيٽ اپ",
  PayPal_Default_Email_Message:"توهان هڪ نئون PayPal منتقلي حاصل ڪيو آهي.",
  Cheques_App_Export:"چيڪس پاران برآمد ٿيل",
  Post_Intro_Thanks:"چڪ چونڊڻ لاءِ توهان جي مهرباني. سيٽ اپ جو عمل هاڻي مڪمل ٿي چڪو آهي.",
  Post_Intro_Word:"شروع ڪريو پنھنجي پھرين چيڪ کي ڇپائي، مستقبل جي ادائيگي شامل ڪريو، يا ملازمن کي پگهار ۾ شامل ڪريو.",
  Upgrade_Required:"چيڪز جي ضرورت آهي ته توهان سافٽ ويئر جي هڪ وڌيڪ پريميئم ورزن جو مالڪ بڻجو انهي پيغام کي لڪائڻ ۽ اضافي خاصيتن کي کولڻ لاءِ.",
  Upgrade_Title:"چڪ",
  Mailout_Prompt:"توهان اضافي طور تي چونڊي سگهو ٿا چيڪ ميل-آئوٽ توهان جي پگهار چيڪن لاءِ.",
  Mailed_Cheque:"ميل ٿيل چيڪ: ",
  Mailed_Cheque_Color:"ميل ٿيل چيڪ (رنگ): ",
  Terms_Purchase:"سڀني اليڪٽرانڪ خريداريون چيڪ سان گڏ مڪمل طور تي واپسي جي قابل آھن خريداري جي تاريخ کان 30-ڏينھن تائين. مھرباني ڪري پڙھو ۽ قبول ڪريو <a href='#'>شرائط ۽ ضابطا</a> اڳتي وڌڻ کان اڳ.",
  Dashboard_Setup:"سيٽ اپ پرائمري پرنٽر",
  Dashboard_Add:"پرائمري بئنڪ اڪائونٽ شامل ڪريو",
  Dashboard_Customize:"هڪ چيڪ ٽيمپليٽ چونڊيو",
  Dashboard_Job_Salary:"پنهنجي نوڪري ٺاهيو ۽ پنهنجي تنخواه شامل ڪريو",
  Dashboard_Employees:"ٽرڪ ملازمن ۽ ادا ڪندڙ",
  Dashboard_Print:"پرنٽ ۽ ٽپال توهان جي پگهار چيڪ",
  Dashboard_Payroll:"توهان جي پگهار جي ڇپائي خودڪار ڪريو",
  Dashboard_PayPal:"PayPal پگهار / ادائگي سيٽ اپ ڪريو",
  Begin_Setup:"سيٽ اپ شروع ڪريو",
  Get_Started:"شروع ڪر",
  Purchase:"خريد",
  Lockdown:"بند ڪرڻ",
  Unlock:"ان لاڪ",
  Detailed:"تفصيل",
  Log_In:"لاگ ان",
  Login:"لاگ ان",
  Launch:"لانچ",
  Register:"رجسٽر",
  Finish:"ختم ڪر",
  List:"فهرست",
  Weekends:"هفتيوار",
  Weekly:"هفتيوار",
  PayPal_Default_Subject:"نئين ادائگي",
  Payment_Message:"ادائيگي پيغام",
  PayPal_Default_Payment_Note:"تنهنجي مهرباني",
  Setup_Your_Cheqing_Account:"ڪائونٽ چيڪ ڪرڻ",
  Add_Your_Primary_Cheqing_Account:"پنھنجو پرائمري چيڪنگ کاتو شامل ڪريو.",
  Welcome_Word:"پيرول ۽ انساني وسيلن جي انتظام کي آسان ۽ خودڪار ڪريو.",
  Get_Started_Quickly:"بس ڪجھ سادو سوالن جا جواب جيڪي اسان کي شروع ڪرڻ ۾ مدد ڪندا...",
  Done_Intro_Word:"سيٽ اپ مڪمل",
  PIN_Protected:"پاسورڊ ۽ پن محفوظ ٿيل",
  Enter_New_PIN:"نئون پن ڪوڊ داخل ڪريو:",
  Enter_PIN:"پن ڪوڊ داخل ڪريو:",
  Invalid_PIN:"غلط پن داخل ڪيو ويو.",
  Account_Identifier:"اڪائونٽ سڃاڻپ ڪندڙ",
  New_Account_Identifier:"نئون کاتي جي سڃاڻپ ڪندڙ",
  attempt:"ڪوشش",
  attempts:"ڪوششون",
  remaining:"باقي",
  Language:"ٻولي",
  languages:"ٻوليون",
  select_languages:"ٻولي چونڊيو",
  Deposit:"جمع",
  Hire_One_Now:"هاڻي هڪ ڪراءِ",
  App_Locked:"ايپليڪيشن بند ٿيل آهي.",
  Skip_:"ڇڏي",
  Skip_to_Dashboard:"ڊش بورڊ ڏانھن وڃو",
  Dashboard:"ڊيش بورڊ",
  Import:"درآمد",
  Admin:"منتظم",
  New_Admin:"نئون منتظم",
  Settings:"سيٽنگون",
  Color_Picker:"رنگ چونڊيندڙ",
  Font_Picker:"فونٽ چونڊيندڙ",
  Logout:"ڪم ڇڏڻ",
  Close:"بند",
  Close_menu:"بند",
  Excel:"ايڪسل فائل",
  Csv:"CSV فائل",
  Sql:"SQL فائل",
  Json:"JSON فائل",
  Url:"URL ذريعي درآمد ڪريو",
  Back:"واپس",
  Timers:"ٽائمر",
  Cheque:"چيڪ",
  Print:"پرنٽ",
  Designs:"ڊزائن",
  Pause_Printing:"پاس پرنٽنگ",
  Resume_Printing:"پرنٽنگ ٻيهر شروع ڪريو",
  Printing_Paused:"پرنٽنگ روڪي وئي",
  PrintingUnavailable:"معاف ڪجو! ڇپائي هن سسٽم تي دستياب ناهي.",
  Prints_Paused:"پرنٽ روڪيا ويا",
  Administration:"انتظام",
  Loading:"لوڊنگ",
  Unnamed:"اڻ نام",
  error:"معاف ڪجو، هي چيڪ ڏسڻ لاءِ نه کولي سگهيو.",
  No_Cheques_To_Print:"پرنٽ ڪرڻ لاءِ ڪوبه چيڪ ناهي",
  No_Cheques_To_Print_Word:"هڪ <a routerLink='/folder/Cheque/New'>نئون چيڪ</a> ٺاهيو.",
  New_Cheque:"نئون چيڪ",
  Start_One_Now:"هاڻي شروع ڪريو",
  Edit_Cheque:"چڪ ۾ ترميم ڪريو",
  Select_Cheques:"چڪ چونڊيو",
  Select_Employee:"ملازمت چونڊيو",
  Default_Printer:"ڊفالٽ پرنٽر",
  Reassign:"ٻيهر تفويض",
  Configure:"ترتيب ڏيو",
  Template:"سانچو",
  Designer:"ڊزائنر",
  Edit_Designs:"ڊزائن ۾ ترميم ڪريو",
  New_Design:"نئين ڊيزائن",
  Next:"اڳيون",
  Save:"بچايو",
  Name:"نالو",
  Mail:"ميل",
  Amount:"رقم",
  Date:"تاريخ",
  PayPal:"پي پال",
  Payouts:"ادائگي",
  PayPal_Label:"پي پال ليبل",
  Email_Username:"اي ميل / استعمال ڪندڙ جو نالو",
  Client_ID:"ڪلائنٽ ID",
  Sandbox_Email:"سينڊ باڪس اي ميل",
  PayPal_Email:"پي پال اي ميل",
  PayPal_Username:"API يوزرنيم",
  PayPal_Payouts:"PayPal ادائگيون",
  Select_PayPal_Key:"پي پال چيڪ چونڊيو",
  Secret:"خفيه",
  API_Secret:"API راز",
  PayPal_API_Keys:"پي پال ڪيز",
  New_PayPal_Key:"نئين PayPal چاٻي",
  Email_Subject:"اي ميل مضمون",
  Email_Message:"اي ميل پيغام",
  Payout_Options:"ادائيگي جا اختيار",
  Payment_Note:"ادائيگي نوٽ",
  Enable_Employee:"ملازم کي فعال ڪريو",
  Enable_Production_Mode:"پيداوار موڊ کي فعال ڪريو",
  Sandbox_Username:"Sandbox Username",
  Sandbox_Signature:"سينڊ باڪس دستخط",
  Sandbox_Password:"سينڊ باڪس پاسورڊ",
  Production_Username:"پيداوار جو نالو",
  Production_Signature:"پيداوار دستخط",
  Production_Password:"پيداوار پاسورڊ",
  Production_Email:"پيداوار اي ميل",
  API_Client_ID:"API ڪلائنٽ ID",
  API_Signature:"API دستخط",
  API_Password:"API پاسورڊ",
  API_Username:"API يوزرنيم",
  Secret_Key:"ڳجهي چاٻي",
  Sandbox:"سينڊ باڪس",
  Production:"پيداوار",
  Sandbox_Keys:"سينڊ باڪس ڪيز",
  Production_Keys:"پيداوار ڪيچ",
  API_Title:"API عنوان",
  Production_Mode:"پيداوار موڊ",
  Account_Label:"اڪائونٽ ليبل",
  No_PayPal_Setup:"ڪو به PayPal جي نه",
  Enable_PayPal_Account:"پي پال اڪائونٽ فعال ڪريو",
  No_PayPal_Word:"شامل ڪريو پنھنجو <a routerLink='/folder/Invoice/New'>PayPal API Key</a>.",
  Printed_Memo:"پرنٽ ٿيل ميمو",
  Employee:"ملازم",
  View_Employee:"ملازمت ڏسو",
  Mailing_Address:"مسلسل ايڊريس",
  Company_Address:"ڪمپني جو پتو",
  Select_Company_Address:"ڪمپني ايڊريس چونڊيو",
  Address:"ايڊريس",
  Any_Day:"ڪنهن به ڏينهن",
  Address_Name:"پتي جو نالو",
  Unit:"يونٽ",
  Account:"حساب",
  Bank_Account:"بئنڪ اڪائونٽ",
  Account_Limits:"اڪاؤنٽ جي حدن کي فعال ڪريو",
  Payroll:"پيرول",
  New_Payroll:"نئون پگهار",
  No_Payroll:"ڪو به ايندڙ پگهار نه",
  Upcoming_Holiday:"ايندڙ موڪلون:",
  Invoice_Due:"انوائس واجب الادا:",
  New_Invoice:"نئون انوائس",
  No_Invoices:"ڪوبه انوائس نه",
  No_Invoices_Word:"پهرين <a routerLink='/folder/Invoice/New'>انوائس</a> ٺاهيو.",
  Cheque_Due:"چڪ جي ادائيگي:",
  Payrolls:"پيرولز",
  Sandbox_Mode:"سينڊ باڪس موڊ",
  Hire:"ڪرائي",
  Pay:"ادا",
  New:"نئون",
  Add:"شامل ڪريو",
  Make:"ٺاهي",
  Time:"وقت",
  Write:"لکڻ",
  Holiday:"موڪل",
  Holidays:"موڪلون",
  Next_Holiday:"اڳيون موڪلون:",
  All_Done:"سمورو ڪم مڪمل ٿي ويو!",
  Employees:"ملازمت",
  Payees:"ادا ڪندڙ",
  Job:"نوڪري",
  Jobs:"نوڪريون",
  Invoice:"انوائس",
  Invoices:"انوائسز",
  Vacations:"ڇڪيون",
  Cheques:"چيڪ",
  Brand_Cheques:"برانڊ",
  Payment:"دائگي",
  Enable_Payment:"ادائگي کي فعال ڪريو",
  Payments:"دائگيون",
  Schedule:"شيڊول",
  Schedules:"شيڊول",
  Timesheet:"ٽائم شيٽ",
  Timesheets:"وقت جا ورق",
  Salary:"تنخواه",
  New_Address:"نئون ايڊريس",
  Address_2:"ايڊريس (لائن 2)",
  _City:"شهر",
  _State:"رياست/ پروو",
  City:"شهر / ٽائون شپ",
  State:"رياست / صوبو",
  Postal:"پوسٽل / زپ ڪوڊ",
  ZIP:"پوسٽل / زپ",
  Country:"ملڪ",
  Addresses:"پتا",
  Required_Options:"گهربل آپشنز",
  Optional_Options:"اختياري اختيارات",
  Additional_Options:"اضافي اختيارن",
  Required:"گهربل",
  Optional:"اختياري",
  Additional:"اضافي",
  No_Addresses:"ڪو پتو ناهي",
  New_Address_Word:"شامل ڪريو پھريون <a routerLink='/folder/Address/New'>پتو</a>.",
  Select_Address:"ايڊريس چونڊيو",
  No_Address:"ڪو پتو ناهي",
  Print_Cheque:"پرنٽ چيڪ",
  Print_Cheque_Now:"پرنٽ چيڪ ھاڻي",
  Description:"وضاحت",
  Pay_To_The_Order_Of:"جي آرڊر تي ادا ڪريو:",
  Select_Date_Range:"تاريخ جي حد منتخب ڪريو",
  Select_Starting_Date:"شروعاتي تاريخ چونڊيو",
  Select_Ending_Date:"اختتام جي تاريخ چونڊيو",
  Select_Date:"تاريخ چونڊيو",
  Cheque_Date:"تاريخ چيڪ ڪريو",
  Cheque_Memo:"ميمو چيڪ ڪريو",
  Blank_Cheque:"خالي چيڪ",
  Blank:"خالي",
  No_Cheques:"ڪوبه چيڪ نه",
  No_Cheques_Word:"پنهنجو پهريون <a routerLink='/folder/Cheque/New'>چيڪ</a> پرنٽ ڪريو.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"تصوير ڏسو",
  View:"ڏسو",
  Modify:"تبديل",
  Delete:"حذف",
  Cheque_Paid:"ادا",
  New_Deduction:"نئون ڪٽيون",
  Title:"عنوان",
  Frequency:"تعدد",
  Hourly:"ڪلاڪ",
  Daily:"روزاني",
  Weekdays:"هفتي جا ڏينهن",
  BiWeekly:"2 هفتا",
  TriWeekly:"3 هفتا",
  Monthly:"مهيني",
  MiMonthly:"2 مهينا",
  Quarterly:"چوڌاري",
  Yearly:"سالانه",
  Every_Week:"هر هفتي",
  Every_Payroll:"هر پگهار",
  Every_Month:"هر مهيني",
  Annually:"سالانه",
  Always_Scheduled:"هميشه شيڊول ٿيل",
  Start_Date:"شروع جي تاريخ",
  End_Date:"آخري تاريخ",
  Start_Time:"وقت جي شروعات",
  End_Time:"آخري وقت",
  Deduction_Label:"ڪٽائي ليبل",
  Notes:"نوٽس",
  Options:"اختيارن",
  Enable:"فعال ڪريو",
  Select_Deductions:"ڪٽوليون چونڊيو",
  Deductions:"ڪٽوتيون",
  No_Deductions:"ڪابه ڪٽيون نه",
  No_Deductions_Word:"پنهنجو پهريون <a routerLink='/folder/Deduction/New'>ڪٽجڻ</a> ٺاهيو.",
  New_Employee:"نئون ملازم",
  No_Title:"نه عنوان",
  _Name:"نالو",
  About_Yourself:"پنهنجي باري ۾",
  Full_Name:"پورو نالو",
  Birthday:"سالگراه",
  Email:"اي ميل",
  SMS:"يس ايم ايس",
  Phone:"فون",
  Test:"ٽيسٽ",
  Call:"ڪال",
  Fax:"فيڪس",
  Printed_Note:"پرنٽ ٿيل نوٽ",
  Position:"پوزيشن",
  Job_Position:"نوڪري پوزيشن",
  Select_a_Job:"نوڪري چونڊيو",
  Select_a_Salary:"تنخواه چونڊيو",
  Add_a_Deduction:"ڪيٽون شامل ڪريو",
  Taxes:"ٽيڪس",
  Add_Taxes:"ٽيڪس شامل ڪريو",
  Date_of_Birth:"ڄم جي تاريخ",
  Email_Address:"اي ميل پتو",
  Phone_Number:"فون نمبر",
  Phone_Call:"فون ڪال",
  Enable_on_Payroll:"پيرول تي فعال ڪريو",
  Select_Employees:"ملازمت چونڊيو",
  No_Employees:"ڪوبه ملازم نه",
  No_Employees_Word:"شامل ڪريو پنھنجو پھريون نئون <a routerLink='/folder/Employee/New'>ملازم</a>.",
  No_Name:"نالو ڪونهي",
  Unemployeed:"بيروزگار",
  Employeed:"ملازمت",
  Paid:"ادا",
  Enabled:"فعال",
  Disabled:"معذور",
  Fire:"آگ",
  Not_Available:"دستياب ناهي",
  Not_Available_Word:"پنهنجو پهريون <a routerLink='/folder/Invoice/New'>انوائس</a> پرنٽ ڪريو ۽ ادا ڪريو.",
  Select_Invoices:"منتخب_انوائس",
  Print_Invoice_Word:"پنهنجو پهريون <a routerLink='/folder/Invoice/New'>انوائس</a> پرنٽ ڪريو ۽ ادا ڪريو.",
  Invoice_Title:"انوائس جو عنوان",
  Invoice_Date:"انوائس جي تاريخ",
  Due_Date:"مقرر تاريخ",
  New_Job:"نئين نوڪري",
  Details:"تفصيل",
  Customize:"پسند ڪريو",
  Customize_Dashboard:"ڊيش بورڊ کي ترتيب ڏيو",
  Components:"اجزاء",
  No_Components:"ڪو به اجزاء نه",
  Main_Components:"مکيه اجزاء",
  Smaller_Components:"ننڍا اجزاء",
  Error_Loading_Page:"هن صفحي کي لوڊ ڪرڻ ۾ غلطي.",
  Bank_Details:"بئنڪ جا تفصيل",
  About_Your_Job:"توهان جي نوڪري بابت",
  Your_Schedule:"توهان جو شيڊول",
  Job_Title:"نوڪري جو عنوان",
  Job_Description:"نوڪري جو تفصيل",
  Job_Details:"نوڪري جا تفصيل",
  Enable_Job:"نوڪري کي فعال ڪريو",
  Pay_Period:"ادا جي مدت",
  Perpetually_Schedule:"مستقل شيڊول",
  Select_Jobs:"نوڪريون چونڊيو",
  No_Jobs:"نوڪري نه",
  Add_Jobs:"نوڪريون شامل ڪريو",
  No_Jobs_Word:"شامل ڪريو پھريون <a routerLink='/folder/Job/New'>نوڪري</a> لسٽ ۾.",
  Count_Employees:"job.employee_count+' ملازم'",
  Zero_Employees:"0 ملازم",
  New_Leave:"نئون موڪل",
  Leave_Name:"نالو ڇڏ",
  Paid_Leave:"ادا ٿيل موڪل",
  Leave_Pay:"ادا ڇڏ",
  Indefinite_Leave:"غير معینہ مدت جي موڪل",
  Indefinite_Payroll:"غير معين پگهار",
  Leave:"ڇڏ",
  Add_Schedules:"شيڊول شامل ڪريو",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"غير حاضري کي فعال ڪريو",
  Select_Leaves:"پتي چونڊيو",
  No_Leaves:"غير موجودگيءَ جي ڪابه خبر ناهي",
  Leave_Of_Absence:"غير حاضري جي موڪل",
  Leaves_Of_Absence:"غير حاضري جا پتا",
  New_Leave_of_Absense:"غير حاضري جي نئين موڪل",
  No_Leaves_Word:"شامل ڪريو پھريون <a routerLink='/folder/Leave/New'>غير حاضري جي موڪل</a>.",
  Not_Enabled:"فعال ناهي",
  Absences:"غير حاضري",
  Payee:"ادا ڪندڙ",
  New_Payee:"نئون ادا ڪندڙ",
  Payee_Identifier:"ادا ڪندڙ سڃاڻپ ڪندڙ",
  Payee_Name:"ادا ڪندڙ جو نالو",
  Payee_Title:"ادا ڪندڙ عنوان",
  Payment_Memo:"ادائيگي ميمو",
  Select_Payees:"منتخب ڪريو ادا ڪندڙ",
  No_Payees:"ڪو به ادا نه",
  Add_Payee_Note:"شامل ڪريو پھريون <a routerLink='/folder/Payee/New'>ادا ڪندڙ</a>.",
  New_Payees:"نئون ادا ڪندڙ",
  About_The_Payment_Schedule:"ادائگي جي شيڊول",
  Your_Payroll_Schedule:"خودڪار پگهار",
  New_Payment:"نئين ادائگي",
  Identifier:"سڃاڻندڙ",
  Select:"چونڊيو",
  Memo:"ميمو",
  Payment_Date:"ادائگي جي تاريخ",
  Mark_as_Paid:"ادا ڪيل طور نشان لڳايو",
  Select_Payments:"ادائگي چونڊيو",
  No_Payments:"ڪو به ادائگي نه",
  No_Payments_Word:"پهرين ٺاهيو <a routerLink='/folder/Payment/New'>ادائيگي</a>.",
  Create_Payroll:"پيرول ٺاهيو",
  Properties:"پراپرٽيز",
  Payroll_Title:"پيرول عنوان",
  Payroll_Notes:"پيرول نوٽس",
  Payroll_Setup:"پيرول سيٽ اپ",
  Tabulate_Payments:"ٽيبلٽ ادائيگيون",
  Tabulate:"ٽيبل",
  By:"جي:",
  Payments_By:"ادائگي طرفان",
  Timesheets_And_Schedules:"وقت جا ورق ۽ شيڊول",
  Both:"ٻئي",
  Items:"شيون",
  Add_Payees:"ادا ڪندڙن کي شامل ڪريو",
  Add_Account:"اڪائونٽ شامل ڪريو",
  Enable_Account:"اڪاؤنٽ کي فعال ڪريو",
  Enable_Payroll:"پيرول کي فعال ڪريو",
  Print_Payroll:"پيرول پرنٽ",
  No_Payrolls:"ڪو به پگهار نه",
  No_Payroll_Word:"پنهنجو پهريون <a routerLink='/folder/Payroll/New'>پگهار</a> ٺاهيو.",
  View_more:"وڌيڪ ڏسو",
  Less:"گهٽ",
  Add_Payroll:"پيرول شامل ڪريو",
  Select_Payroll:"پيرول چونڊيو",
  About_Your_Salary:"توهان جي تنخواه جي باري ۾",
  Add_Salaries:"تنخواه شامل ڪريو",
  Add_Salary:"تنخواه شامل ڪريو",
  Salaries:"تنخواه",
  No_Salaries:"نه تنخواه",
  No_Salaries_Word:"شامل ڪريو پھريون <a routerLink='/folder/Salary/New'>تنخواه</a>.",
  Select_Salaries:"تنخواه چونڊيو",
  New_Salary:"نئون تنخواه",
  Salary_Name:"تنخواه جي سڃاڻپ ڪندڙ",
  Enable_Salary:"تنخواه کي فعال ڪريو",
  Salary_Amount:"تنخواه جي رقم",
  New_Schedule:"نئون شيڊول",
  Schedule_Title:"شيڊول جو عنوان",
  Add_Address:"پتا شامل ڪريو",
  Repeat:"ٻيهر",
  Design:"ڊزائن",
  Edit_Design:"ڊزائن ۾ ترميم ڪريو",
  Edit_this_Design:"هن ڊيزائن کي تبديل ڪريو",
  Repeat_Payment:"دوبارو ادائگي",
  Enable_Schedule:"شيڊول کي فعال ڪريو",
  Never:"ڪڏهن به نه",
  Select_Schedule:"منڊل چونڊيو",
  No_Schedules:"ڪو شيڊول ناهي",
  No_Schedules_Word:"پهرين ٺاهيو <a routerLink='/folder/Schedule/New'>شيڊول</a>.",
  New_Administrator:"نئون ايڊمنسٽريٽر",
  Username:"استعمال ڪندڙ",
  First_Name:"پهريون نالو",
  Last_Name:"آخري نالو",
  Add_an_Address:"اي ايڊريس شامل ڪريو",
  Payment_Limit:"في ادائگي جي حد",
  Total_Limit:"مڪمل حد",
  Select_Accounts:"ڪائونٽس چونڊيو",
  No_Administrators:"نه منتظم",
  No_Administrators_Word:" پھريون <a routerLink='/folder/Administrator/New'>ايڊمن اڪائونٽ</a> ٺاھيو.",
  New_Administrators_Word:"شامل ڪريو پھريون <a routerLink='/folder/Administrator/New'>منتظم</a>",
  Cloud:"بادل",
  Backup:"بيڪ اپ",
  Enable_iCloud:"iCloud فعال ڪريو",
  Enable_Google_Drive:"گوگل ڊرائيو کي فعال ڪريو",
  Enable_Microsoft_OneDrive:"Microsoft OneDrive کي فعال ڪريو",
  Automatically_Backup:"خودڪار بيڪ اپ",
  FTP_Settings:"ايف ٽي پي سيٽنگون",
  URL_File_Prompt:"مهرباني ڪري .xls / .xlsx / .json فائل درآمد ڪرڻ لاءِ جڳھ بيان ڪريو:",
  URL_SQL_Prompt:"مهرباني ڪري بيان ڪريو SQLite فائل جو مقام درآمد ڪرڻ لاءِ:",
  FTP_Backup:"FTP بيڪ اپ",
  FTP_Import:"ايف ٽي پي درآمد",
  FTP:"ايف ٽي پي",
  SFTP:"SFTP",
  Host:"ميزبان",
  Port:"پورٽ",
  Path:"رستو",
  Data_Path:"ڊيٽا جو رستو",
  Enable_FTP_Account:"ايف ٽي پي اڪائونٽ فعال ڪريو",
  HostnameIP:"ميزبان جو نالو",
  Password:"پاسورڊ",
  Connection_Port:"ڪنيڪشن پورٽ",
  Enable_MySQL_Database:"MySQL ڊيٽابيس کي فعال ڪريو",
  Log:"لاگ",
  Reset:"ري سيٽ ڪريو",
  Erase:"مٽايو",
  Export:"برآمد",
  Database:"ڊيٽابيس",
  Upload_CSV:"اپ لوڊ ڪريو CSV",
  Download_CSV:"ڊائون لوڊ ڪريو CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL بيڪ اپ",
  Internal_Notes:"اندروني نوٽس",
  Root_Path:"روٽ رستو",
  Select_Backup:"بيڪ اپ چونڊيو",
  Add_New_Backup:"نئون بيڪ اپ شامل ڪريو",
  First_Backup:"پهرين بيڪ اپ سيٽ اپ ڪريو...",
  Backups:"بيڪ اپ",
  Add_Backup:"بيڪ اپ شامل ڪريو",
  No_Backups:"اتي ڪو به بيڪ اپ نه لڌو وڃي.",
  Select_Backup_Type:"بڪ اپ جو قسم چونڊيو جيڪو توهان سيٽ ڪرڻ چاهيو ٿا...",
  Backup_Type:"بڪ اپ جو قسم",
  FTP_Drive:"ايف ٽي پي ڊرائيو",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"ڊرائيو",
  Microsoft_OneDrive:"ڊرائيو",
  Import_Fields:"درآمد فيلڊ",
  Import_Fields_Word:"ڊيٽا <a routerLink='/folder/Holiday/New'>درآمد</a> چونڊڻ لاءِ هي سيڪشن استعمال ڪريو.",
  Upload:"اپ لوڊ",
  Download:"ڊائون لوڊ",
  Download_JSON:"ڊائون لوڊ ڪريو JSON ڊيٽا طور",
  Download_SQL:"ڊائون لوڊ ڪريو SQL فائل طور",
  New_Bank:"نئون بئنڪ",
  New_Account:"نئون کاتو",
  New_Bank_Account:"نئون بئنڪ اڪائونٽ",
  Upload_Image:"تصوير اپ لوڊ ڪريو",
  Bank_Name:"بئنڪ جو نالو",
  Bank_Address:"بينڪ ايڊريس",
  Branch_Address:"برانچ جو پتو",
  Address_on_Cheque:"ايڊريس",
  Cheque_Numbers:"نمبر چيڪ ڪريو",
  Cheque_Details:"تفصيل چيڪ ڪريو",
  Bank_Logo:"بينڪ لوگو",
  Cheque_ID:"ID چيڪ ڪريو",
  Starting_Cheque_ID:"شروعاتي چيڪ ID",
  Transit_Number:"ٽرانزٽ نمبر",
  Institution_Number:"ادارا نمبر",
  Designation_Number:"نامزدگي نمبر",
  Account_Number:"اڪائونٽ نمبر",
  Limits:"حدون",
  Default_Limits:"ڊفالٽ حدون",
  Over_Limit:"حد کان وڌيڪ",
  Under_Limit:"حد کان هيٺ",
  Payroll_Limit:"پيرول جي حد",
  Enable_Bank_Account:"بينڪ اڪائونٽ فعال ڪريو",
  Select_Account:"ڪائونٽ چونڊيو",
  No_Bank_Account:"ڪو به بئنڪ اڪائونٽ نه",
  No_Bank_Account_Word:"شامل ڪريو پھريون <a routerLink='/folder/Accounts/New'>بئنڪ اڪائونٽ</a>.",
  Bank_Accounts:"بينڪ اڪائونٽس",
  No_Accounts:"ڪابه اڪائونٽ نه",
  No_Accounts_Note:"شامل ڪريو پھريون <a routerLink='/folder/Accounts/New'>بئنڪ اڪائونٽ</a>.",
  Cheque_Designer:"ڊزائنر چيڪ ڪريو",
  Cheque_Design:"ڊيزائن چيڪ ڪريو",
  Select_Design:"هڪ ڊزائين چونڊيو",
  Cheque_Designs:"ڊزائنز چيڪ ڪريو",
  No_Cheque_Designs:"ڪابه چيڪ ڊيزائن",
  No_Cheque_Designs_Word:"پنهنجو پنهنجو ٺاهيو <a routerLink='/folder/Settings/Cheque/Design/New'>ڊيزائن چيڪ ڪريو</a>.",
  Set_Default:"ڊفالٽ طور مقرر ڪريو",
  Default:"ڊفالٽ",
  Remove:"هٽايو",
  Folder:"فولڊر",
  Edit:"ترميم",
  LoadingDots:"لوڊ ٿي رهيو آهي...",
  Add_a_New_File:"شامل ڪريو <a href='#' (click)='add()'>نئين فائل</a> ۾",
  this_folder:"هي فولڊر",
  FTP_Backup_Settings:"FTP بيڪ اپ سيٽنگون",
  Secure_File_Transfer:"محفوظ فائل جي منتقلي",
  New_Holiday:"نئون موڪلون",
  Add_Holiday:"عبادت شامل ڪريو",
  Holiday_Name:"عبادت جو نالو",
  Additional_Pay:"اضافي ادا",
  Enable_Holiday:"عيد چالو ڪريو",
  Select_Holidays:"چونڊون موڪلون",
  No_Holidays:"نه موڪلون",
  No_Holidays_Word:"شامل ڪريو پھريون <a routerLink='/folder/Holiday/New'>عوامي موڪلون</a>.",
  New_Import:"نئون درآمد",
  Import_Data:"ڊيٽا درآمد ڪريو",
  Import_Data_Word:"فائل جو قسم چونڊيو يا توهان جي پسند جو طريقو اپلوڊ ڪيو.<br /> توهان هڪ فائل ۾ درآمد ٿيل فيلڊ کي چونڊڻ جي قابل هوندا، جيڪو سپورٽ ٿيل فائل اپ لوڊ ڪرڻ کان پوء ايپ ۾ ڪنهن به پيٽرولر سان ملندو.", 
  Import_File:"درآمد فائل",
  Link_To_File:"فائل سان ڳنڍڻ",
  Select_File:"فائل چونڊيو",
  New_Moderator:"نئون ناظم",
  Allow_Moderation:"عتدال جي اجازت ڏيو",
  Create_Paycheques:"پيچ چيڪ ٺاهيو",
  Edit_Paycheques_and_Data:"پيچچڪس ۽ ڊيٽا کي تبديل ڪريو",
  Destroy_Data_and_Paycheques:"ڊيٽا ۽ پگهار کي تباهه ڪريو",
  Bonus_Percentage:"پيش چيڪ فيصد",
  Fixed_Amount:"مقرر رقم",
  Select_Moderator:"منتظم چونڊيو",
  No_Moderators:"ڪوبه منتظم نه",
  Moderators:"ماڊريٽر",
  Moderator_Account:" پھريون <a routerLink='/folder/Administrator/New'>ماڊريٽر کاتو</a> ٺاھيو.",
  No_Moderators_Word:"شامل ڪريو پھريون <a routerLink='/folder/Administrator/New'>ماڊريٽر</a>.",
  Defaults:"ڊفالٽ",
  Provide_Us_Info:"اسان کي ڄاڻ ڏيو",
  Setup_Your_Printer:"پنهنجو پرنٽر سيٽ ڪريو",
  Your_Company:"توهان جي ڪمپني جي باري ۾",
  Company_Name:"ڪمپني جو نالو",
  Currency:"ڪرنسي",
  Default_Currency:"ڊفالٽ ڪرنسي",
  Base_Monthly_Income:"مهيني آمدني",
  Protection:"تحفظ",
  App_Protection:"ايپ تحفظ",
  PIN_Code_Protection:"پن ڪوڊ تحفظ",
  App_Protection_Word:"سيڪيورٽي طريقن کي فعال ڪريو جيڪي توھان جي اڪائونٽ کي بچائڻ ۾ مدد ڪن ٿيون.",
  PIN_Code:"پن ڪوڊ",
  Change_PIN:"پن تبديل ڪريو",
  New_Password:"نئون پاسورڊ",
  Geofence_Protection:"جيو-فينس تحفظ",
  Geofence_Area:"ايريا مقرر ڪريو",
  Distance:"فاصلو",
  Setup_Now:"هاڻي سيٽ ڪريو",
  Mile:"ميل",
  Km:"ڪلوميٽر",
  m:"م",
  Facial_Recognition:"منهن جي سڃاڻپ",
  Face:"منهن",
  Setup:"سيٽ اپ ڪريو",
  Label:"ليبل",
  Password_Protection:"پاسورڊ تحفظ",
  Modify_Password:"پاسورڊ تبديل ڪريو",
  New_Tax_Entry:"نئون ٽيڪس داخلا",
  New_Tax:"نئون ٽيڪس",
  Tax_Label:"ٽيڪس ليبل",
  Perpetually_Enabled:"دائمي طور تي فعال",
  Select_Taxes:"ٽيڪس چونڊيو",
  Tax_Deductions:"ٽيڪس ڪٽيون",
  No_Tax_Deductions:"ڪو به ٽيڪس ڪٽيون نه",
  No_Tax_Deductions_Word:"شامل ڪريو پھريون <a routerLink='/folder/Tax/New'>ٽيڪس</a> deduction.",
  New_Timer:"نئون ٽائمر",
  Start:"شروع",
  Stop:"روڪ",
  Start_Timer:"ٽائمر شروع ڪريو",
  Stop_Timer:"ٽائمر بند ڪريو",
  Timer_Active:"ٽائمر فعال",
  Timer:"ٽائمر:",
  Timer_Running:"ٽائمر: (هلندڙ)",
  Save_Timer:"سيو ٽائمر",
  New_Timesheet:"نئين وقت جي چادر",
  Select_Timesheets:"وقت جي شيٽ چونڊيو",
  Work_Notes:"ڪم نوٽس",
  Entry_Title:"داخل ٿيڻ جو عنوان",
  No_Timesheets:"وقت جي چادر نه",
  No_Timesheets_Word:"شامل ڪريو پھريون <a routerLink='/folder/Timesheet/New'>ٽائيم شيٽ</a>.",
  Timesheet_Submitted:"وقت جي شيٽ جمع ٿيل",
  Timesheet_Congrats:"مبارڪون! توهان جي ٽائيم شيٽ ڪاميابي سان جمع ٿي وئي آهي. توهان جي مهرباني!",
  Timesheet_Copy:"پنهنجي دستاويزن جي ڪاپي حاصل ڪرڻ لاءِ مهرباني ڪري اسان کي پنهنجو اي ميل پتو ۽/يا موبائل فون نمبر مهيا ڪريو.",
  Submit:"جمع ڪريو",
  Allow_Notifications:"اطلاع جي اجازت ڏيو",
  Untitled_Entry:"نئين داخلا",
  Untitled_Bank:"غير عنوان بئنڪ",
  Timesheet_Entry:"ٽائم شيٽ داخلا",
  Work_Description:"ڪم جي وضاحت",
  Enable_Timesheet:"ٽائم شيٽ کي فعال ڪريو",
  Submit_Timesheet:"وقت جي شيٽ جمع ڪريو",
  Vacation:"ڇڪي",
  New_Vacation:"نئون موڪلون",
  Vacation_Name:"ويڪيشن جو نالو",
  Paid_Vacation:"ادا ڪيل موڪلون",
  Vacation_Pay:"ڇڪي جي ادا",
  Enable_Vacation:"ويڪيشن کي فعال ڪريو",
  Select_Vacations:"منتخب موڪلون",
  No_Vacations:"نه موڪلون",
  No_Vacations_Word:"پهرين <a routerLink='/folder/Vacation/New'>ويڪيشن</a> داخل ڪريو.",
  Payroll_Schedule:"پيرول شيڊول",
  Next_Payroll:"اڳيون پگهار:",
  Upcoming_Payroll:"ايندڙ پگهار",
  No_Upcoming_Payroll:"ڪو به ايندڙ پگهار نه",
  Address_Book:"ايڊريس بوڪ",
  Archived_Documents:"آرڪائيو ٿيل دستاويز",
  Dev_Mode:"ايپليڪيشن ان ڊولپمينٽ موڊ",
  Administrators:"منتظم",
  Privacy:"رازداري",
  None:"ڪو به نه",
  Select_Signature:"دستخط چونڊيو",
  No_Signatures:"ڪابه دستخط نه",
  No_Signatures_Word:"شامل ڪريو پھريون <a routerLink='/folder/Signature/New'>دستخط</a>.",
  Repeat_Indefinitely:"غير معين طور ورجايو",
  Sign:"نشان",
  Sign_Here:"هتي سائن ان ڪريو",
  Date_Signed:"تاريخ دستخط ٿيل",
  Signature_Pad:"دستخط پيڊ",
  Account_Holder:"کاتيدار",
  Account_Properties:"اڪائونٽ پراپرٽيز",
  Name_On_Cheque:"چڪ تي نالو",
  Server_Hostname:"سرور ميزبان نالو / IP",
  Printers:"پرنٽر",
  No_Printers:"نه پرنٽر",
  Printer_Exists:'هن نالي جو هڪ پرنٽر اڳ ۾ ئي موجود آهي.',
  No_Printers_Word:"سڀ کان پھريون شامل ڪريو <a routerLink='/folder/Printer/New'>پرنٽر</a>.",
  No_Printer_Alert:"ڪنهن به پرنٽر جي وضاحت نه ڪئي وئي آهي. ڇا توهان هڪ پرنٽر سيٽ ڪرڻ چاهيندا؟",
  Add_Printer:"پرنٽر شامل ڪريو",
  New_Printer:"نئون پرنٽر",
  Printer_Hostname:"پرنٽر ميزبان نالو / IP",
  Printer_Label:"پرنٽر ليبل",
  Printer_Protocol:"پرنٽر پروٽوڪول",
  Protocol:"پروٽوڪول",
  Email_Print:"اي ميل",
  AirPrint:"ايئر پرنٽ",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"ساکيٽ",
  Print_Job:"پرنٽ جاب",
  Printed:"ڇپيل",
  Not_Printed:"نه ڇپيل",
  Print_Jobs:"پرنٽ جاب",
  Print_Queue:"پرنٽ قطار",
  No_Print_Jobs:"ڪابه پرنٽ نوڪريون",
  No_Prints:"پرنٽ ڪرڻ لاءِ نئون <a routerLink='/folder/Cheque/New'>چيڪ</a> ٺاهيو.",
  Prints:"پرنٽ",
  Find_Printer:"پرنٽر ڳوليو",
  Find_AirPrint_Devices:"Air Print Devices ڳوليو",
  Select_Printer:"پرنٽر چونڊيو",
  System_UI:"سسٽم UI",
  Printer:"پرنٽر",
  Status:"اسٽيٽس",
  Preview:"ڏسو",
  Enable_Print_Job:"پرنٽ جاب کي فعال ڪريو",
  Queue_Weight:"قطار وزن",
  Unlimited:"لامحدود",
  Show_Advanced_Printer_Options:"ڏيکاريو ترقي يافته پرنٽر جا اختيار،",
  Advanced:"ترقي يافته",
  Location:"مقام",
  Note:"نوٽ",
  Queue_Name:"قطار جو نالو",
  Pages_Printed_Limit:"صفحا ڇپيل حد",
  MultiPage_Idle_Time:"ڪيترائي صفحو انتظار جو وقت",
  Page_Count_Limit:"صفحي جي ڳڻپ جي حد",
  Page_Orientation:"صفحي جو رخ",
  Portrait:"پوٽريٽ",
  Landscape:"نظرداري",
  Duplex:"ڊپلڪس",
  Double_Sided_Printing:"ٻه رخا",
  Duplex_Mode:"ڊپليڪس موڊ",
  Duplex_Short:"مختصر",
  Duplex_Long:"ڊگهو",
  Duplex_None:"ڪو به نه",
  Color_And_Quality:"رنگ ۽ معيار",
  Monochrome:"مونوڪروم",
  Photo_Quality_Prints:"فوٽو معيار پرنٽ",
  Printer_Email:"پرنٽر اي ميل",
  Automatically_Downsize:"خودڪار طور تي گھٽ ڪريو",
  Paper:"ڪاغذ",
  Paper_Name:"ڪاغذ جو نالو",
  Paper_Width:"ڪاغذ جي ويڪر",
  Paper_Height:"ڪاغذ جي اوچائي",
  Paper_Autocut_Length:"پيپر آٽو-ڪٽ ڊگھائي",
  Margins:"مارجن",
  Page_Margins:"صفحي جي مارجن",
  Page_Margin_Top:"مٿين صفحو مارجن",
  Page_Margin_Right:"ساڄي صفحو مارجن",
  Page_Margin_Bottom:"هيٺيان صفحو مارجن",
  Page_Margin_Left:"کاٻي صفحي جي مارجن",
  Add_Employees:"ملازمت شامل ڪريو",
  Header:"مٿو",
  Print_A_Page_Header:"پيج هيڊر پرنٽ ڪريو",
  Header_Label:"هيڊر ليبل",
  Header_Page_Index:"هيڊر پيج انڊيڪس",
  Header_Font_Name:"هيڊر فونٽ",
  Select_Font:"فونٽ چونڊيو",
  Font_Selector:"فونٽ سليڪٽر",
  Header_Font_Size:"هيڊر فونٽ",
  Header_Bold:"هيڊر بولڊ",
  Header_Italic:"هيڊر اطالوي",
  Header_Alignment:"هيڊر جي ترتيب",
  Left:"کاٻو",
  Center:"مرڪز",
  Right:"ساڄو",
  Justified:"جائز",
  Header_Font_Color:"هيڊر رنگ",
  Select_Color:"رنگ چونڊيو",
  Footer:"فوٽر",
  Print_A_Page_Footer:"پرنٽ هڪ صفحي جو فوٽر",
  Footer_Label:"فوٽر ليبل",
  Footer_Page_Index:"فوٽر پيج انڊيڪس",
  Footer_Font_Name:"فوٽر فونٽ",
  Fonts:"فونٽ",
  Done:"ٿي ويو",
  Select_Fonts:"فونٽ چونڊيو",
  Footer_Font_Size:"فوٽر سائيز",
  Footer_Bold:"فوٽر بولڊ",
  Footer_Italic:"فوٽر اطالوي",
  Footer_Alignment:"فوٽر جي ترتيب",
  Footer_Font_Color:"فوٽر رنگ",
  Page_Copies:"صفحي ڪاپيون",
  Enable_Printer:"پرنٽر کي فعال ڪريو",
  Remote_Logging:"ريموٽ لاگنگ",
  Log_Server:"لاگ سرور",
  Encryption:"انڪريپشن",
  Random_Key:"بي ترتيب چاٻي",
  Encryption_Key:"انڪريپشن ڪيچ",
  Cheques_Webserver:"ڪسٽم ڊيٽابيس",
  Learn_How:"سکو ڪيئن",
  Signature:"دستخط",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"دستخط ڏسو",
  Printed_Signature:"پرنٽ ٿيل دستخط",
  Digitally_Sign:"ڊجيٽل سائن",
  Digital_Signature:"ڊجيٽل دستخط",
  Add_Signature:"دستخط شامل ڪريو",
  Add_Your_Signature:"پنهنجي دستخط شامل ڪريو",
  Enable_Signature:"دستخط کي فعال ڪريو",
  Digitally_Signed:"ڊجيٽل دستخط ٿيل",
  Insert_Error:"ڊيٽابيس جي مسئلن جي ڪري چيڪ محفوظ ڪرڻ ۾ ناڪامي.",
  Delete_Confirmation:"ڇا توھان واقعي ھن معلومات کي ڊاھڻ چاھيو ٿا؟",
  Discard_Confirmation:"ڇا توھان کي پڪ آھي ته توھان ھن معلومات کي رد ڪرڻ چاھيو ٿا؟",
  Discard_Bank_Confirmation:"ڇا توهان واقعي هن اڪائونٽ کي رد ڪرڻ چاهيو ٿا؟",
  Discard_Printer_Confirmation:"ڇا توھان پڪ آھي ته توھان ھن پرنٽر کي رد ڪرڻ چاھيو ٿا؟",
  Delete_Bonus_Confirmation:"ڇا توهان پڪ آهيو ته توهان هن بونس کي ختم ڪرڻ چاهيو ٿا؟",
  Delete_Invoice_Confirmation:"ڇا توھان کي پڪ آھي ته توھان ھن انوائس کي ختم ڪرڻ چاھيو ٿا؟",
  Generated_Cheque:"پيدا ٿيل چيڪ",
  Generated_Invoice:"پيدا ٿيل انوائس",
  Schedule_Start_Time:"شروعاتي شيڊول",
  Schedule_End_Time:"شيڊول پڄاڻي",
  New_Call:"نئون ڪال",
  No_Contacts:"ڪو به رابطو ناهي",
  No_Contacts_Word:"منتظم، منتظم، ملازم، ۽ ادا ڪندڙ رابطي جي طور تي ظاهر ٿيندا آهن.",
  PDF_Subject:"ماليات",
  PDF_Keywords:"پيرول پگهار چيڪ پي ڊي ايف چيڪ چيڪ",
  Printer_Setup:"پرنٽر سيٽ اپ",
  Printer_Selection:"پرنٽر جي چونڊ",
  New_Fax:"نئون فيڪس",
  New_Fax_Message:"نئون فيڪس پيغام",
  Fax_Machine:"فيڪس مشين",
  Fax_Name:"فيڪس جو نالو",
  Fax_Email:"فيڪس اي ميل",
  Fax_Number:"فيڪس نمبر",
  Contents:"مواد",
  Fax_Body:"صفحي جو جسم",
  Header_Word:"هيڊر:",
  Header_Text:"هيڊر ٽيڪسٽ",
  Include_Header:"هيڊر شامل ڪريو",
  Include_Footer:"فوٽر شامل ڪريو",
  Footer_Word:"فوٽر:",
  Footer_Text:"فوٽر ٽيڪسٽ",
  Attach_a_Cheque:"هڪ چيڪ شامل ڪريو",
  Add_Deduction:"ڪٽائي شامل ڪريو",
  Enable_Fax:"فيڪس موڪليو",
  Select_Fax:"فيڪس چونڊيو",
  No_Faxes:"نه فيڪس",
  Faxes:"فيڪس",
  Save_and_Send:"فيڪس موڪليو",
  Save_and_Pay:"بچايو ۽ ادا ڪريو",
  WARNING:"خبردار:",
  Remember:"ياد رکو",
  Printing:"پرنٽ",
  Printing_Complete:"پرنٽنگ مڪمل!",
  of:"جي",
  There_Were:"هتي هئا ",
  Successful_Prints:"ڪامياب پرنٽ ۽",
  Unsuccessful_Prints:"ناڪام پرنٽ.",
  PrinterError:"معاف ڪجو! ڪا غلطي ٿي وئي.",
  Printing_:"ڇپائي...",
  PrinterSuccess:"دستاويز ڪاميابيءَ سان ڇپجي ويو.",
  PrintersSuccess:"دستاويز ڪاميابي سان ڇپيا.",
  Print_Cheques:"پرنٽ چيڪ",
  New_Message:"نئون پيغام",
  New_Messages:"نئون پيغام",
  Read_Message:"پيغام پڙهو",
  Write_Message:"پيغام لکو",
  Send_Message:"پيغام موڪليو",
  Subject:"موضوع",
  Message:"پيغام",
  Writing:"لکڻ...",
  Send:"موڪليو",
  Set_Date:"تاريخ مقرر ڪريو",
  Set_Time:"وقت مقرر ڪريو",
  Recieve:"وصول",
  Set_as_Default:"ڊفالٽ طور مقرر ڪريو",
  Default_Account:"ڊفالٽ اڪائونٽ",
  Default_Design:"ڊفالٽ ڊيزائن",
  Multiple_Cheques:"چيڪس (ٽيپل)",
  Account_Mode:"اڪائونٽ موڊ",
  Multiple_Cheques_Description:"في صفحو ٽي چيڪ.",
  Check_and_Table:"چيڪ ۽ ٽيبل",
  Check_including_Table:"چيڪ ۽ اڪائونٽنگ ٽيبل.",
  Check_Mailer:"ميل چيڪ ڪريو",
  Check_Mailer_Window:"ايڊريس ونڊو سان چيڪ ڪريو.",
  DocuGard_Table_Top:"DocuGard چيڪ ۽ ٽيبل (مٿي)",
  DocuGard_Table_Middle:"DocuGard چيڪ ۽ ٽيبل (وچون)",
  DocuGard_Table_Bottom:"DocuGard چيڪ ۽ ٽيبل (هيٺيان)",
  DocuGard_Mailer_Top:"DocuGard چيڪ ميلر (مٿي)",
  DocuGard_Mailer_Middle:"DocuGard چيڪ ميلر (وچو)",
  DocuGard_Mailer_Bottom:"DocuGard چيڪ ميلر (هيٺيان)",
  DocuGard_Three_Cheques:"DocuGard چيڪ (Tripple)",
  DocuGard_Cheque_Top:"DocuGard چيڪ (مٿي)",
  DocuGard_Cheque_Middle:"DocuGard چيڪ (وچون)",
  DocuGard_Cheque_Bottom:"DocuGard چيڪ (هيٺيان)",
  DocuGard_Three_Cheques_Window:"هڪ صفحي تي ٽي چيڪ.",
  DocuGard_Table_Top_Window:"چيڪ ۽ آمدني جدول.",
  DocuGard_Table_Middle_Window:"چيڪ ۽ آمدني جدول.",
  DocuGard_Table_Bottom_Window:"چيڪ ۽ آمدني جدول.",
  DocuGard_Mailer_Top_Window:"چڪ ڪريو، ٽيبل، ۽ پتو.",
  DocuGard_Mailer_Middle_Window:"چڪ ڪريو، ٽيبل، ۽ پتو.",
  DocuGard_Mailer_Bottom_Window:"چڪ ڪريو، ٽيبل، ۽ پتو.",
  DocuGard_Cheque_Top_Window:"محفوظ ڪاغذ لاء چيڪ ڪريو.",
  DocuGard_Cheque_Middle_Window:"محفوظ ڪاغذ لاء چيڪ ڪريو.",
  DocuGard_Cheque_Bottom_Window:"محفوظ ڪاغذ لاء چيڪ ڪريو.",
  Basic_Cheque:"چڪ ڪريو (مٿي)",
  Basic_Cheque_Print:"هڪ هڪ چيڪ پرنٽ ڪريو.",
  Error_Setting_As_Paid:"ادا ڪيل طور تي ترتيب ڏيڻ ۾ غلطي",
  Add_Attachment:"منسلڪ شامل ڪريو",
  PrinterUnavailable:"پرنٽر دستياب ناهي",
  CreditCardComponent:"ڪارڊ",
  PaypalComponent:"پي پال",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"نئون جمع",
  Deposits:"جمع",
  No_Deposits:"ڪو به جمع نه",
  Credit_Card_Deposit:"ڪريڊٽ ڪارڊ",
  New_Credit_Card_Deposit_Message:"ڪريڊٽ ڪارڊ جمع",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin جمع",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"انٽرڪ ٽرانسفر",
  Payments_Limit:"ادائگي جي حد",
  No_Payment_Limit:"ڪوبه ادائگي جي حد نه",
  PayPal_Deposit:"پي پال",
  PayPal_Deposit_Message:"پي پال جمع",
  No_Deposits_Word:"پهرين ڪمپني شامل ڪريو <a routerLink='/folder/Deposit/New'>جمع</a>.",
  No_Documents_Specified:"پرنٽنگ لاءِ ڪي به دستاويز بيان نه ڪيا ويا",
  No_Printers_Added:"ڪي به پرنٽر نه مليا. ھڪڙو شامل ڪرڻ لاء سيٽنگون > پرنٽر ڏانھن وڃو.",
  DB_Erase_Prompt:"مڪمل طور تي پوري ڊيٽابيس کي ختم ڪري ڇڏيو؟ خبردار: توھان سڀ ذخيرو ٿيل معلومات وڃائي ويندؤ!",
  Console_Warning:"هن ڪنسول ۾ ڪوبه متن نه پيسٽ ڪريو. توهان شايد پاڻ کي ۽/يا توهان جي ڪمپني کي سخت خطري ۾ وجهي سگهو ٿا.",
  No_Faxes_Word:"پهرين <a routerLink='/folder/Fax/New'>Fax</a> ٺاهيو.",
  Cheque_Delete_Confirmation:"ڇا توھان کي پڪ آھي ته توھان ھن چيڪ کي ختم ڪرڻ چاھيو ٿا؟",
  Design_Delete_Confirmation:"ڇا توھان کي پڪ آھي ته توھان ھن ڊيزائن کي ختم ڪرڻ چاھيو ٿا؟",
  Cheque_Pay_Confirmation:"هن چيڪ کي ادا ڪيل طور نشان لڳايو؟ اهو پرنٽ قطار ۾ ظاهر نه ٿيندو.",
  Payment_Pay_Confirmation:"هن ادائيگي کي ادا ڪيل طور نشان لڳايو؟ اهو چيڪ قطار ۾ ظاهر نه ٿيندو.",
  Delete_Deduction_Confirmation:"ڇا توھان کي پڪ آھي ته توھان ھن ڪٽائي کي ختم ڪرڻ چاھيو ٿا؟",
  Delete_Job_Confirmation:"ڇا توهان کي پڪ آهي ته توهان هن نوڪري کي ختم ڪرڻ چاهيو ٿا؟",
  Delete_Timesheet_Confirmation:"ڇا توھان پڪ آھيو ته توھان ھن ٽائم شيٽ کي ختم ڪرڻ چاھيو ٿا؟",
  Delete_Schedule_Confirmation:"ڇا توھان واقعي ھن شيڊول کي ختم ڪرڻ چاھيو ٿا؟",
  Delete_Setting_Confirmation:"ڇا توھان کي پڪ آھي ته توھان ھن سيٽنگ کي ري سيٽ ڪرڻ چاھيو ٿا؟",
  Delete_Fax_Confirmation:"ڇا توهان واقعي هن فيڪس کي حذف ڪرڻ چاهيو ٿا؟",
  Delete_File_Confirmation:"ڇا توھان واقعي ھن فائل کي ڊاھڻ چاھيو ٿا؟",
  Delete_Vacation_Confirmation:"ڇا توهان واقعي هن موڪل کي ختم ڪرڻ چاهيو ٿا؟",
  Delete_Printer_Confirmation:"ڇا توهان پڪ سان هن پرنٽر کي ختم ڪرڻ چاهيو ٿا؟",
  Remove_Design_Confirmation:"ڇا توھان کي پڪ آھي ته توھان ھن ڊيزائن کي ختم ڪرڻ چاھيو ٿا؟",
  Delete_Payroll_Confirmation:"ڇا توھان کي پڪ آھي ته توھان ھن پگهار کي ختم ڪرڻ چاھيو ٿا؟",
  Send_Fax_Email_Confirmation:"ڇا توهان انهن دستاويزن کي فيڪس ۽ اي ميل ڪرڻ چاهيو ٿا؟",
  Send_Email_Confirmation:"ڇا توھان ھن دستاويز کي اي ميل ڪرڻ چاھيو ٿا؟",
  Send_Fax_Confirmation:"ڇا توھان ھن دستاويز کي فيڪس ڪرڻ چاھيو ٿا؟",
  Error_Generating_PDF:"معاف ڪجو. ھي ڊاڪيومينٽ ٺاھڻ ۾ ھڪ نقص ٿي پيو.",
  PDF_Error_Saving_Image:"معاف ڪجو. هن دستاويز جي PDF تصوير محفوظ ڪرڻ ۾ هڪ غلطي ٿي وئي.",
  Test_Printer_Confirmation:"ڇا توھان ھن پرنٽر تي ٽيسٽ صفحو پرنٽ ڪرڻ چاھيو ٿا؟",
  Save_Timesheet_Prompt:"مهرباني ڪري 'عنوان' شامل ڪريو يا محفوظ ڪرڻ لاءِ 'Start Timer' کي دٻايو.",
  Remove_Geofence_Prompt:"ڇا توھان کي پڪ آھي ته توھان ھن جيو-فينس جي جڳھ کي هٽائڻ چاھيو ٿا؟",
  Delete_Employee_Confirmation:"ڇا توھان کي پڪ آھي ته توھان حذف ڪرڻ چاھيو ٿا",
  Fire_Employee_Confirmation:"ڇا توهان کي پڪ آهي ته توهان فائر ڪرڻ چاهيو ٿا"
}