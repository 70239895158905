export const Ro = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Drepturi de autor &copy; 2023",
  black:"Negru",
  green:"Verde",
  gold:"Aur",
  blue:"Albastru",
  brown:"Maro",
  purple:"Violet",
  pink:"Roz",
  red:"Roșu",
  Swatches:"Mostre",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Nuanţă",
  Saturation:"Saturare",
  Lightness:"Lejeritate",
  Upgrade_To_Pro:"Upgrade la Pro",
  AllFeaturesInclude:"Toate caracteristicile includ:",
  PrintUnlimitedCheques:"Imprimați cecuri nelimitate",
  PremiumChequeDesigns:"Design Premium Check",
  ManageUnlimitedEmployees:"Gestionați angajați nelimitați",
  AddUnlimitedPayees:"Adăugați beneficiari nelimitați",
  CreateUnlimitedPayrolls:"Creați state de plată nelimitate",
  UnlimitedSchedulesandTimeSheets:"Programe și foi de timp nelimitate",
  BulkPayPalPayouts:"Plăți în vrac PayPal",
  UnlimitedBankAccounts:"Conturi bancare nelimitate",
  ManageMultipleCompanies:"Gestionați mai multe companii",
  TrackInsurancePolicies:"Urmăriți polițele de asigurare",
  Bio_MetricProtection:"Protecție biometrică",
  Geo_FenceLock_OutProtection:"Protecție de blocare Geo-Fence",
  Multiple_Companies_Word:"Gestionarea mai multor companii nu este disponibilă fără Cecuri premium.",
  PayPal_Payouts_Word:"Plățile PayPal sunt dezactivate fără cecuri premium.",
  PINProtection:"Protecție PIN",
  PasswordProtection:"Protecție cu parolă",
  May_Require_Approval:"Poate necesita aprobare.",
  Subscribe:"Abonati-va",
  Billed:"Facturat",
  Up:"Sus",
  Down:"Jos",
  Positioning:"Poziționare",
  Marker:"Marker",
  Drag_Marker:"Trageți Marker",
  Tagline:"Imprimați cecurile și intabulați statul de plată",
  Marker_Word:"Utilizați marcatorii pentru a dimensiona elementul.",
  Pinch_Zoom:"Ciupiți Zoom",
  Pinch_Word:"Ciupiți pentru a mări elementul.",
  Drag:"Trage",
  Drag_Word:"Folosiți degetul pentru a trage elemente.",
  subscription_alias_word:"Abonament cu reînnoire automată",
  premium_alias_word:"Pachet de upgrade unic",
  print_alias_word:"Imprimați cecuri individuale",
  mode_alias_word:"Modul",
  Pro:"Pro",
  Pro_word:"Este necesară versiunea Pro.",
  Cant_Delete_Default_Company_Word:"Ne pare rău, nu vă puteți șterge compania prestabilită.",
  Reinsert_Default_Designs:"Reintroduceți modelele implicite",
  Reinsert_Default_Designs_word:"Doriți să reintroduceți modelele implicite?",
  Subscription_Active_Disable_Word:"Acest abonament este activ. Doriți să anulați acest abonament la Cecuri?",
  Company_Logo:"Logoul companiei",
  ZERO_:"ZERO",
  Disclaimer:"Disclaimer",
  Privacy_Policy:"Politica de confidențialitate",
  EULA:"Verifică EULA",
  Terms_Of_Service:"Termenii serviciului",
  Terms_Of_Use:"Termeni de utilizare",
  Refunds:"Politica de rambursare",
  Single_Print:"1 Verificați",
  Two_Prints:"2 Verificări",
  Five_Prints:"5 Verificări",
  Ten_Prints:"10 verificări",
  Fifteen_Prints:"15 cecuri",
  Twenty_Prints:"20 de cecuri",
  Thirty_Prints:"30 de cecuri",
  Image_Added:"Imagine adăugată",
  Image_Preview:"Previzualizare imagine",
  No_Image_Was_Selected:"Nu a fost selectată nicio imagine.",
  Cheques_Unlimited:"Cecuri nelimitate",
  _Subscription:"Abonament",
  Subscription:"Cecuri - 1 lună",
  Two_Month_Subscription:"Cecuri - 2 luni",
  Three_Month_Subscription:"Cecuri - 3 luni",
  Six_Month_Subscription:"Cecuri - 6 luni",
  Twelve_Month_Subscription:"Cecuri - 12 luni",
  Cheques_Are_Available:"Cecurile sunt disponibile pentru tipărire.",
  Upgrade_Required_Two:"Selectați un pachet și atingeți de două ori butonul de preț pentru a începe achiziția. Imprimați cecuri full-color cu aspect profesional în câteva secunde.",
  Month:"Lună",
  Due:"Datorită:",
  Expires:"Expiră:",
  Subscription_Active:"Abonament activ",
  Subscription_Inactive:"Abonament inactiv",
  Purchase_Additional_Cheques:"Cumpărați cecuri suplimentare?",
  Printable_Cheque:"Cec imprimabil",
  Printable_Cheques:"Cecuri imprimabile",
  Printable_Cheque_Word:"cecul este disponibil în contul dvs.",
  Printable_Cheques_Word:"cecuri sunt disponibile în contul dvs.",
  Max_Amount_Message:"Suma pe care ați specificat-o a atins-o peste suma maximă setată pentru acest sistem:",
  Terms_Required_Word:"Trebuie să fiți de acord cu acest acord înainte de a continua să utilizați Cecuri.",
  Subscriptions:"Abonamente",
  Product_Upgrades:"Upgrade-uri",
  Mailed_Out_Cheques:"Cecuri expediate",
  Enter_A_Company_Name:"Vă rugăm să introduceți un nume de companie.",
  Single_Cheques:"Cecuri unice",
  Cheque_Golden:"Cecul de Aur",
  Cheque_Golden_Window:"Design cu cec auriu.",
  Cheque_Green:"Cec verde",
  Cheque_Green_Window:"Design cu cec verde.",
  Cheque_Red:"Cec roșu",
  Cheque_Red_Window:"Design cu cec roșu.",
  Cheque_Yellow:"Cec galben",
  Cheque_Yellow_Window:"Design cu cec galben.",
  Cheque_Statue_of_Liberty:"Statuia Libertății",
  Cheque_Statue_of_Liberty_Window:"Designul cec al Statuii Libertății.",
  Cheque_Green_Wave:"Val verde",
  Cheque_Green_Wave_Window:"Design cu cec verde.",
  Cheque_Golden_Weave:"Țesătură de Aur",
  Cheque_Golden_Weave_Window:"Design elegant cu carouri aurii.",
  Cheque_Green_Sun:"Soarele Verde",
  Cheque_Green_Sun_Window:"Design cec profund și calmant.",
  Cheque_Blue_Checkers:"Dame Albastre",
  Cheque_Blue_Checkers_Window:"Design cu cec albastru.",
  Cashiers_Check:"Cec de casierie",
  Cashiers_Check_Window:"Șablon stil Casier's Check.",
  Cheque_Aqua_Checkers:"Dame Aqua",
  Cheque_Aqua_Checkers_Window:"Design Aqua check.",
  Cheque_Golden_Checkers:"Dame de Aur",
  Cheque_Golden_Checkers_Window:"Design cu cec auriu.",
  Upgrade_Unavailable:"Upgrade-uri indisponibile",
  Bank_Code_Protection:"Protecția numărului bancar",
  Bank_Code_Protection_Word:"Protejați-vă numerele bancare împotriva utilizării în această aplicație care rulează pe alt dispozitiv sau pentru alt utilizator. Această acțiune este IRREVERSIBILĂ. Continua?",
  Bank_Code_Protection_Blocked_Word:"Numerele bancare pe care încercați să le utilizați sunt rezervate pentru alt utilizator sau dispozitiv.",
  Bank_Code_Protection_Error_Word:"Verificarea numărului a eșuat. Conectați-vă la internet și încercați să adăugați din nou acest cont bancar.",
  Bank_Code_Protection_Set_Error_Word:"Protecția numărului bancar necesită să fiți conectat la internet. Vă rugăm să vă conectați și să încercați din nou.",
  Upgrade_Unavailable_Word:"Ne pare rău, întâmpinăm probleme la verificarea dvs. În prezent, abonamentele și upgrade-urile la Cecuri nu sunt disponibile pentru cumpărare în zona dvs.",
  PayPal_Payment_Preview:"Previzualizarea plății PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Selectați PayPal",
  PayPal_Applications:"Aplicații PayPal",
  Purchase_With_Apple_Pay:"Cumpărați cu Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Companii",
  Insurance:"Asigurare",
  New_PayPal:"PayPal nou",
  Pay_By:"Plătit de",
  Insure:"Asigura",
  Miles:"Miles",
  Payment_Method:"Modalitate de plată",
  Select_Policies:"Selectați Politici",
  Policies:"Politici",
  Policy:"Politică",
  No_PayPal_Account:"Fără cont PayPal",
  No_Policies:"Fără polițe de asigurare",
  New_Policy:"Politică nouă",
  PayPal_Payment:"Plata PayPal",
  PayPal_Payments:"Plăți PayPal",
  No_Payment_Selected:"Nicio plată selectată",
  Sending_Payment_Word:"Vă rugăm să așteptați... Această plată este trimisă.",
  Sending_Payments_Word:"Vă rugăm să așteptați... Plățile sunt trimise.",
  No_Payment_Selected_PayPal:"Nu a fost selectată nicio plată <a routerLink='/folder/Payments'>PayPal</a>.",
  Payment_Sent:"Plată trimisă",
  PayPal_Payment_Sent:"Această plată a fost trimisă cu PayPal.",
  Copay:"Copay",
  Dead:"Mort",
  Alive:"În viaţă",
  Not_Dead:"Nu Moartă",
  Unclaimed:"Nerevendicat",
  Attempted:"A încercat",
  Deceased:"Decedat",
  Claimed:"Revendicat",
  Unpaid:"Neplătit",
  Sending_Payment:"Trimiterea plății",
  Sending_Payments:"Trimiterea plăților",
  Send_PayPal_Confirmation:"Doriți să trimiteți această tranzacție cu PayPal?",
  Send_PayPal_Confirmation_Word:"Apăsați butonul verde pentru a trimite această tranzacție.",
  Save_Payment_As_Unpaid:"Salvați această plată ca neplătită?",
  Payment_Pay_Confirmation_PayPal:"Salvați această plată ca plătită?",
  No_Policies_Word:"Adăugați acum prima <a routerLink='/folder/Postage/New'>Poliță de Asigurare</a>.",
  Timesheet_Multiple_Delete_Confirmation:"Sigur doriți să ștergeți mai multe foi de pontaj?",
  Select_Multiple_Timesheets_Prompt:"Nu au fost selectate foi de pontaj. Selectați cel puțin o foaie de pontaj.",
  Select_Multiple_Policies_Prompt:"Nicio politică selectată. Selectați cel puțin o poliță de asigurare.",
  Policies_Multiple_Delete_Confirmation:"Sigur doriți să ștergeți mai multe politici?",
  Company:"Companie",
  Add_Company:"Adăugați o companie",
  New_Company:"Adăugați o companie",
  No_Companies:"Fara Companii",
  Enable_Company:"Activați Compania",
  Select_Company:"Selectați Companie",
  The_Default_Company:"Eticheta implicită a companiei.",
  Manage_Companies:"Gestionați Companii",
  No_Companies_Word:"Verificările vor folosi o companie implicită.<br />Adăugați <a routerLink='/folder/Company/New'>Prima Companie</a>.",
  Default_Company:"Companie implicită",
  Cheques_Unlimited_Word:"Cecuri nelimitate vă permite să imprimați câte cecuri doriți.",
  Cheques_Subscription_Word:"Un abonament Cecuri vă permite să imprimați câte cecuri doriți.",
  You_Own_This_Product:"Dețineți acest produs.",
  Your_Subscription_is_Active:"Abonamentul dvs. este activ.",
  Active_Products:"Produse activate",
  Purchase_Confirmation:"Cumpărare",
  Purchase_Cheques:"Cecuri de cumpărare",
  Restore_Purchase:"Restabiliți achizițiile",
  Erase_Purchase:"Ștergeți achizițiile",
  Successfully_Purchased:"Achizitionat cu succes",
  Enter_Your_Licence_Key:"Vă rugăm să introduceți cheia de licență pe această pagină pentru a activa acest produs.",
  Licence_Key:"Cheie de licență",
  Enter_Licence_Key:"Introduceți cheia de licență",
  Purchased:"Cumparat",
  Enable_Feature:"Activați funcția",
  Cancel_Subscription:"Anuleaza abonarea",
  Subscription_Removed:"Abonament eliminat",
  Select_Active_Subscription:"Selectați un abonament activ pentru a-l modifica.",
  Remove_Subscription_Word:"Sigur doriți să anulați acest abonament?",
  Delete_Company_Confirmation:"Sigur doriți să ștergeți întreaga companie? AVERTISMENT: Veți pierde toate informațiile stocate!",
  Delete_Default_Company_Word:"Nu puteți șterge compania implicită. Doriți să resetați aplicația și să o restabiliți la starea implicită? AVERTISMENT: Veți pierde toate informațiile stocate!",
  Console_Warning_2:"Nu manipulați nicio monedă folosind această aplicație care nu este a dumneavoastră în prezent.",
  Terms_and_Conditions:"Termeni și condiții",
  and_the:"si",
  for:"pentru",
  Please_Read_Word:"Vă rugăm să citiți și să fiți de acord cu",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Unele rate de conversie valutară nu au putut fi găsite. Vă rugăm să vă conectați la internet.",
  Free:"Gratuit",
  DB_Erase_Prompt_2:"Ștergeți complet întreaga bază de date pentru dezvoltatori? AVERTISMENT: Veți pierde toate informațiile de CUMPĂRARE și ABONARE!",
  Successfully_Imported:"Importat cu succes",
  Select_Postage:"Selectați Poștă",
  No_Postage:"Fără timbre poștale",
  No_Paid_Postage_Word:"Adăugați prima ștampilă <a routerLink='/folder/Postage/New'>Poștă plătită</a>.",
  Trial_Complete:'Proba finalizată',
  Please_Upgrade:'Vă rugăm să actualizați Cecuri pentru a continua imprimarea.',
  Aa:"Aa",
  Color:"Culoare",
  Font:"Font",
  Guide:"Ghid",
  Guides:"Ghiduri",
  Image:"Imagine",
  Zoom:"Zoom",
  Logo:"Siglă",
  Bank:"bancă",
  MICR:"MICR",
  Total:"Total",
  Cancel:"Anulare",
  Confirm:"A confirma",
  Method:"Metodă",
  Biometric_Security:"Securitate biometrică",
  Please_Login_To_Continue:"Vă rugăm să vă conectați pentru a continua.",
  Complete:"Complet",
  Sign_Up:"Inscrie-te",
  Error:"Eroare",
  Biometrics:"Biometrică",
  Percent:"La sută",
  Zero_Percent:"0%",
  Top_Margin:"Marja superioară",
  Bottom_Margin:"Marja inferioară",
  Left_Margin:"Marginea stângă",
  Right_Margin:"Marja dreaptă",
  MICR_Margin:"Marja MICR",
  Table_Margin:"Marja tabelului",
  Address_Margin:"Marja de adresă",
  Percentage_:"Procent",
  Vacation_Title:"Titlul de vacanță",
  Use_PIN:"Utilizați codul PIN",
  Loading__:"Se încarcă...",
  Design_Title:"Titlul designului",
  Authorize:"Autoriza",
  Key:"Cheie",
  Public_Key:"Cheie publică",
  Private_Key:"Cheie privată",
  Authenticate:"Autentifica",
  Last_Payroll:"Ultima salarizare",
  Last_Calculation:"Ultimul calcul",
  Authorized:"Autorizat",
  Geo_Authorized:"Locație geografică: Autorizat",
  Not_Authorized:"Neautorizat",
  Authorization_Complete:"Autorizare finalizată",
  Geolocation_Authorization:"Autorizație de localizare geografică",
  Out_of_Bounds:"În afara limitelor",
  Cant_Delete_Default_Design:"Nu se poate șterge un design implicit.",
  Unauthorized_Out_of_Bounds:"Neautorizat: în afara limitelor",
  Biometric_Authorization:"Autorizare biometrică",
  Locating_Please_Wait:"Localizare, vă rugăm să așteptați...",
  Test_Biometrics:"Testați biometrică",
  Cheque_Margins:"Verificați marjele",
  Percentage_Full_Error:"Câmpul procentual nu poate fi setat peste 100% procente.",
  No_Payroll_Text:"Adăugați un <a routerLink='/folder/Employee/New'>Angajat</a> sau <a routerLink='/folder/Payee/New'>Beneficiar</a> și un <a routerLink='/folder/Schedule/New'>Program</a>.",
  Design_Saved:"Design salvat",
  Default_Design_Selected:"Design implicit selectat",
  Partial_Import:"Import parțial",
  Partial_Export:"Export parțial",
  Entire_Import:"Import integral",
  Entire_Export:"Export întreg",
  Existing_Password:"Vă rugăm să introduceți parola existentă:",
  Existing_PIN:"Vă rugăm să introduceți codul PIN existent:",
  Pin_Change_Header:"Confirmare PIN",
  Pin_Change_SubHeader:"Introduceți vechiul număr PIN pentru a confirma modificarea.",
  Pass_Change_Header:"Confirmarea parolei",
  Pass_Change_SubHeader:"Introduceți vechea parolă pentru a confirma modificarea.",
  PIN_Enter_Message:"Introduceți codul PIN pentru a confirma.",
  Password_Enter_Message:"Introduceți parola pentru a confirma.",
  Pin_Updated_Success:"PIN-ul a fost actualizat cu succes!",
  Pin_Updated_Fail:"PIN-ul nu a putut fi actualizat.",
  Pass_Updated_Success:"Parola a fost actualizată cu succes.",
  Pass_Updated_Fail:"Parola nu a putut fi actualizată.",
  Preview_Payment:"Previzualizează plata",
  Preview_Payroll:"Previzualizare salarizare",
  No_Payments_Created:"Nu s-au găsit plăți care să fie create.",
  Payment_Preview:"Previzualizarea plății",
  Enable_Payee:"Activați Beneficiarul",
  Rendered:"Redat",
  No_Email:"Fără e-mail",
  Setup_Cheques:"Verificări de configurare",
  name:"Nume",
  address:"Abordare",
  address_2:"Linia de adresă 2",
  city:"Oraș",
  province:"Provincie",
  postal_code:"Cod poștal",
  country:"Țară",
  username:"Nume de utilizator",
  full_name:"Numele complet",
  birthday:"Zi de nastere",
  email:"E-mail",
  phone:"Telefon",
  employees:"Angajatii",
  addresses:"Adrese",
  payment_amount_limit:"Limita sumei de plată",
  total_limit:"Limită totală",
  payees:"Beneficiarii",
  description:"Descriere",
  address_line_one:"Linia de adresă 1",
  address_line_two:"Linia a doua de adresă",
  image:"Imagine",
  account_holder:"Titular de cont",
  cheque_starting_id:"Verificați ID-ul de pornire",
  transit_number:"Număr de tranzit",
  institution_number:"Numărul instituției",
  designation_number:"Numărul de desemnare",
  account_number:"Numărul de cont",
  currency:"Valută",
  signature:"Semnătură",
  payment_payroll_limit:"Limită de plată",
  total_limi:"Limită totală",
  date:"Data",
  printed_memo:"Notă tipărită",
  banks:"Băncile",
  signature_image:"Imagine de semnătură",
  address_name:"Nume adresa",
  notes:"Note",
  design:"Proiecta",
  title:"Titlu",
  frequency:"Frecvență",
  fax:"Fax",
  salaries:"Salariile",
  salary_ids:"ID-uri de salariu",
  start_time:"Timpul de începere",
  end_time:"Sfârșitul timpului",
  paid:"Plătit",
  overtime_percentage:"Procentul plătit",
  overtime_amount:"Sumă fixă plătită",
  first_name:"Nume",
  last_name:"Numele de familie",
  moderation:"Moderare",
  create:"Crea",
  edit:"Editați | ×",
  destroy:"Distruge",
  day_start_time:"Day_start_time",
  day_end_time:"Day_end_time",
  fullname:"Nume",
  time:"Timp",
  auto_send:"Trimite automat",
  time_method:"Metoda timpului",
  schedules:"Programe",
  indefinite_payroll_enabled:"Activați pe termen nelimitat",
  amount:"Cantitate",
  repeat:"Repeta",
  always_enabled:"Întotdeauna activat",
  start_date:"Data de început",
  end_date:"Data de încheiere",
  Cheque_Total:"Verificați Total",
  Total_Amount:"Valoare totală:",
  Amounts:"Sume:",
  Images:"Imagini",
  Files:"Fișiere",
  Previewing:"Previzualizare:",
  Insert:"Introduce",
  Preview_Import:"Previzualizare import",
  Entry:"Intrare",
  Entries:"Intrări",
  No_Entries:"Fără intrări",
  Import_Type:"Tip de import",
  Select_Details:"Selectați Detalii",
  Select_Payee:"Selectați Beneficiar",
  Enable_Holidays:"Activați Sărbători",
  Disable_Holidays:"Dezactivați Sărbători",
  Wire_Transfer:"Transfer bancar",
  New_Export:"Export nou",
  Export_Data:"Export de date",
  Export_Data_Word:"Selectați tipul de fișier de exportat și descărcat.",
  Export_File:"Exportați fișierul",
  Mode:"Modul",
  Times:"Times",
  Widgets:"Widgeturi",
  Slider:"Glisor",
  Set_Details:"Set Detalii",
  Select_Type:"Selectați Tip",
  Display_Slider:"Slider de afișare",
  Dashboard_Slider:"Glisor pentru tabloul de bord",
  Dashboard_Mode:"Modul tablou de bord",
  Show_Intro:"Afișează introducerea",
  Show_Payrolls:"Afișați salariile",
  Show_Holidays:"Afișează Sărbători",
  Show_Invoices:"Afișați facturile",
  Show_Cheques:"Arată cecuri",
  Enabled_Widgets:"Widgeturi activate",
  Disabled_Widgets:"Widgeturi dezactivate",
  Colors:"Culori",
  Barcodes:"Codurile de bare",
  View_Timers:"Vizualizați temporizatoarele",
  Gradients:"Gradiente",
  No_Info:"Nicio informație",
  Disable:"Dezactivați",
  Show_Layer:"Afișați straturi",
  Hide_Layer:"Ascunde straturile",
  Text_Layer:"Straturi de text",
  Secondly:"În al doilea rând",
  Minutely:"Minut",
  nine_am:"9:00 DIMINEATA",
  five_pm:"5:00 PM",
  Enable_Address:"Activați adresa",
  Invalid_File_Type:"Ne pare rău, a fost selectat un tip de fișier nevalid. Tip de fișier acceptat:",
  Error_Updating_Entry:"Ne pare rău, a apărut o eroare la actualizarea acestei intrări.",
  Schedule_Timing_Alert:"Eroare: ora de începere a programului este setată la o valoare după ora de încheiere.",
  Select_Multiple_Payments_Prompt:"Nu au fost selectate plăți. Selectați cel puțin o plată.",
  Select_Multiple_Cheques_Prompt:"Nu au fost selectate verificări. Vă rugăm să selectați cel puțin o cefă.",
  Select_Multiple_Items_Prompt:"Niciun articol selectat. Vă rugăm să selectați cel puțin un articol.",
  Paymemt_Multiple_Delete_Confirmation:"Sigur doriți să ștergeți mai multe plăți?",
  Cheque_Multiple_Delete_Confirmation:"Sigur doriți să ștergeți mai multe cecuri?",
  Payee_Multiple_Delete_Confirmation:"Sigur doriți să ștergeți mai mulți beneficiari?",
  Employee_Multiple_Delete_Confirmation:"Sigur doriți să ștergeți mai mulți angajați?",
  MICR_Warning:"Notă: Este posibil ca unele imprimante și dispozitive să nu afișeze corect fontul MICR.",
  Automatically_Send:"Trimite automat",
  Type:"Tip",
  Payment_Type:"Tipul de plată",
  Auto_Send:"Trimitere automată",
  Automatically_Process:"Procesează automat",
  Auto_Process:"Proces automat",
  Image_Based:"Bazat pe imagini",
  Font_Based:"Bazat pe fonturi",
  Rerender:"Redare",
  Rendering:"Redare",
  Render:"Fișiere",
  Top:"Top",
  Middle:"Mijloc",
  Bottom:"Partea de jos",
  Top_Left:"Stânga sus",
  Top_Center:"Centru de sus",
  Top_Right:"Sus în dreapta",
  Middle_Left:"Mijloc Stânga",
  Middle_Center:"Centrul de mijloc",
  Middle_Right:"Mijloc dreapta",
  Bottom_Left:"Stânga jos",
  Bottom_Center:"Centru jos",
  Bottom_Right:"Dreapta-jos",
  Numbers:"Numerele",
  Verified:"Verificat",
  Paper_Size:"Dimensiunea hartiei",
  New_Device:"Dispozitiv nou",
  Add_Device:"Adăugați dispozitiv",
  Remove_Device:"Indepartati dispozitivul",
  Delete_Device:"Ștergeți dispozitivul",
  Block_Device:"Blocați dispozitivul",
  Block:"bloc",
  Unblock:"Deblocați",
  Table:"Masa",
  Scan_Login_Code:"Scanați codul de conectare",
  Login_Code:"Cod de autentificare",
  Scan_Code:"Cod de scanare",
  Scan_QR_Code:"Scanați codul QR",
  Select_All:"Selectează tot",
  Deselect_All:"Deselectați Toate",
  Increase:"Crește",
  Decrease:"Scădea",
  Bold:"Îndrăzneţ",
  Text:"Text",
  Style:"Stil",
  Italic:"Cursiv",
  QR_Code:"Cod QR",
  Barcode:"Cod de bare",
  Browse_Images:"Răsfoiți imagini",
  Browse_Files:"Cauta fisiere",
  Background_Image:"Imagine de fundal",
  Logo_Image:"Imagine de siglă",
  Header_Image:"Imagine de antet",
  Bank_Image:"Imagine de bancă",
  Cut_Lines:"Linii de tăiere",
  Background:"fundal",
  License:"Licență",
  One_License:"1 licență:",
  Licensed:"Licențiat la:",
  Not_Licensed:"Nu este licențiat",
  Enter_Serial:"Introduceți Serial",
  Serial_Key:"Cheie de serie",
  Serial:"Serial",
  Product_Key:"Cheie de produs",
  Check_Product_Key:"Verificați cheia produsului",
  Add_Product_Key:"Adăugați cheia produsului",
  Verifying_Purchase:"Se verifică achiziția...",
  Print_Envelope:"Imprimați plicul",
  Envelope:"Plic",
  Thank_You:"Mulțumesc!",
  Scale:"Scară",
  Print_Scale:"Scară de imprimare",
  Borders:"Frontiere",
  VOID:"NUL",
  Void_Cheque:"Verificare nulă",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"PLATĂ LA COMANDA DE:",
  NO_DOLLARS:"FĂRĂ DOLARI ",
  ONE_DOLLAR:"UN DOLAR",
  DOLLARS:" DOLARI",
  AND:" ȘI ",
  CENTS:" CENTI.",
  NO_:"NU ",
  ONE_:"UNU ",
  AND_NO_:"SI NU ",
  _AND_ONE_:"ȘI UNUL ",
  DOLLARS_AND_ONE_CENT:" SI UN CENT.",
  AND_NO_CENTS:" SI ZERO CENTI.",
  CHEQUE_PRINT_DATE:"DATA:",
  CHEQUE_PRINT_MEMO:"NOTIFICARE:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Sarcini inițiale",
  Inital_Setup:"Configurare inițială",
  Welcome_To:"Bun venit la ",
  Welcome:"Bine ati venit",
  Swipe:"Beţivan",
  Intro_Setup:"Configurare introducere",
  Introduction:"Introducere",
  CHEQUE_PRINT_CREDIT:"Alimentat de cecuri",
  Title_Intro_Word:"Bine ați venit la Cecuri",
  Title_Intro:"Introducere cecuri",
  Title_Setup:"Configurare cecuri",
  PayPal_Default_Email_Message:"Ați primit un nou transfer PayPal. [Powered by Cheques]",
  Cheques_App_Export:"Exportat prin cecuri",
  Post_Intro_Thanks:"Vă mulțumim că ați ales Cecuri. Procesul de configurare este acum finalizat.",
  Post_Intro_Word:"Începeți prin a imprima primul dvs. cec, adăugați o plată viitoare sau adăugați angajați la statul de plată.",
  Upgrade_Required:"Cecuri necesită să dețineți o versiune mai premium a software-ului pentru a ascunde acest mesaj și a debloca funcții suplimentare.",
  Upgrade_Title:"Cecuri",
  Mailout_Prompt:"În plus, puteți alege ca Cecurile să vă trimită cecurile de salarizare pentru dvs.",
  Mailed_Cheque:"Cecul trimis prin poștă: ",
  Mailed_Cheque_Color:"Cecul trimis prin poștă (culoare):",
  Terms_Purchase:"Toate achizițiile electronice cu Cecuri sunt complet rambursabile timp de până la 30 de zile de la data achiziției. Vă rugăm să citiți și să acceptați <a href='#'>Termenii și condițiile</a> înainte de a continua.",
  Dashboard_Setup:"Configurați imprimanta principală",
  Dashboard_Add:"Adăugați contul bancar principal",
  Dashboard_Customize:"Selectați un șablon de verificare",
  Dashboard_Job_Salary:"Creează-ți jobul și adaugă-ți salariul",
  Dashboard_Employees:"Urmăriți angajații și salariații",
  Dashboard_Print:"Tipărește și trimite cecurile de plată",
  Dashboard_Payroll:"Automatizați-vă imprimarea salariilor",
  Dashboard_PayPal:"Configurați Payroll/Plăți PayPal",
  Begin_Setup:"Începeți configurarea",
  Get_Started:"Incepe",
  Purchase:"Cumpărare",
  Lockdown:"Carantină",
  Unlock:"Deblocați",
  Detailed:"Detaliat",
  Log_In:"Autentificare",
  Login:"Autentificare",
  Launch:"Lansa",
  Register:"Inregistreaza-te",
  Finish:"Finalizarea",
  List:"Listă",
  Weekends:"Weekend",
  Weekly:"Săptămânal",
  PayPal_Default_Subject:"Plată nouă",
  Payment_Message:"Mesaj de plată",
  PayPal_Default_Payment_Note:"Mulțumesc",
  Setup_Your_Cheqing_Account:"Cont de cecuri",
  Add_Your_Primary_Cheqing_Account:"Adăugați contul dvs. de cecuri principal.",
  Welcome_Word:"Simplificați și automatizați gestionarea salariilor și a resurselor umane.",
  Get_Started_Quickly:"Răspundeți la câteva întrebări simple care ne vor ajuta să începem...",
  Done_Intro_Word:"Configurare finalizată",
  PIN_Protected:"Protejat prin parolă și PIN",
  Enter_New_PIN:"Introduceți un nou cod PIN:",
  Enter_PIN:"Introduceți codul PIN:",
  Invalid_PIN:"S-a introdus codul PIN nevalid.",
  Account_Identifier:"Identificatorul contului",
  New_Account_Identifier:"Identificator de cont nou",
  attempt:"atentat, încercare",
  attempts:"încercări",
  remaining:"rămas",
  Language:"Limba",
  languages:"Limbi",
  select_languages:"Selecteaza limba",
  Deposit:"Depozit",
  Hire_One_Now:"Angajați unul acum",
  App_Locked:"Aplicația este blocată.",
  Skip_:"Ocolire",
  Skip_to_Dashboard:"Săriți la tabloul de bord",
  Dashboard:"Bord",
  Import:"Import",
  Admin:"Administratori",
  New_Admin:"Admin nou",
  Settings:"Setări",
  Color_Picker:"Selector de culoare",
  Font_Picker:"Selector de font",
  Logout:"Deconectare",
  Close:"Închide",
  Close_menu:"Închide",
  Excel:"Fisier Excel",
  Csv:"Fișier CSV",
  Sql:"Fișier SQL",
  Json:"Fișier JSON",
  Url:"Import prin URL",
  Back:"Înapoi",
  Timers:"Cronometre",
  Cheque:"Verifica",
  Print:"Imprimare",
  Designs:"Designuri",
  Pause_Printing:"Întrerupeți imprimarea",
  Resume_Printing:"Reluați imprimarea",
  Printing_Paused:"Imprimare întreruptă",
  PrintingUnavailable:"Ne pare rău! Imprimarea nu este disponibilă pe acest sistem.",
  Prints_Paused:"Imprimări întrerupte",
  Administration:"Administrare",
  Loading:"Se încarcă",
  Unnamed:"Anonim",
  error:"Ne pare rău, această verificare nu a putut fi deschisă pentru vizualizare.",
  No_Cheques_To_Print:"Fără cecuri de imprimat",
  No_Cheques_To_Print_Word:"Creați un <a routerLink='/folder/Cheque/New'>Cec Nou</a>.",
  New_Cheque:"Cecul nou",
  Start_One_Now:"Începe unul acum",
  Edit_Cheque:"Editați verificarea",
  Select_Cheques:"Selectați cecuri",
  Select_Employee:"Selectați un angajat",
  Default_Printer:"Imprimantă implicită",
  Reassign:"Reatribuiți",
  Configure:"Configurați",
  Template:"Șablon",
  Designer:"Designer",
  Edit_Designs:"Editați modele",
  New_Design:"Design nou",
  Next:"Următorul",
  Save:"Salva",
  Name:"Nume",
  Mail:"Poștă",
  Amount:"Cantitate",
  Date:"Data",
  PayPal:"PayPal",
  Payouts:"Plăți",
  PayPal_Label:"Etichetă PayPal",
  Email_Username:"E-mail/Nume utilizator",
  Client_ID:"ID client",
  Sandbox_Email:"E-mail sandbox",
  PayPal_Email:"E-mail PayPal",
  PayPal_Username:"Nume de utilizator API",
  PayPal_Payouts:"Plăți PayPal",
  Select_PayPal_Key:"Selectați cheia PayPal",
  Secret:"Secret",
  API_Secret:"Secretul API",
  PayPal_API_Keys:"Cheile PayPal",
  New_PayPal_Key:"Cheie nouă PayPal",
  Email_Subject:"Subiectul emailului",
  Email_Message:"Mesaj prin poșta electronică",
  Payout_Options:"Opțiuni de plată",
  Payment_Note:"Notă de plată",
  Enable_Employee:"Activați angajatul",
  Enable_Production_Mode:"Activați modul de producție",
  Sandbox_Username:"Nume de utilizator Sandbox",
  Sandbox_Signature:"Semnătură sandbox",
  Sandbox_Password:"Parola Sandbox",
  Production_Username:"Nume utilizator producție",
  Production_Signature:"Semnătura de producție",
  Production_Password:"Parola de producție",
  Production_Email:"E-mail de producție",
  API_Client_ID:"ID client API",
  API_Signature:"Semnătura API",
  API_Password:"Parola API",
  API_Username:"Nume de utilizator API",
  Secret_Key:"Cheie secreta",
  Sandbox:"Cutie cu nisip",
  Production:"Producție",
  Sandbox_Keys:"Cheile sandbox",
  Production_Keys:"Chei de producție",
  API_Title:"Titlul API",
  Production_Mode:"Modul de producție",
  Account_Label:"Eticheta contului",
  No_PayPal_Setup:"Fără cheie PayPal",
  Enable_PayPal_Account:"Activați contul PayPal",
  No_PayPal_Word:"Adăugați <a routerLink='/folder/Invoice/New'>Cheia API PayPal</a>.",
  Printed_Memo:"Memo tipărită",
  Employee:"Angajat",
  View_Employee:"Vedeți angajatul",
  Mailing_Address:"Adresa postala",
  Company_Address:"Adresa companiei",
  Select_Company_Address:"Selectați adresa companiei",
  Address:"Abordare",
  Any_Day:"Orice zi",
  Address_Name:"Numele adresei",
  Unit:"Unitate",
  Account:"Cont",
  Bank_Account:"Cont bancar",
  Account_Limits:"Activați limitele contului",
  Payroll:"Statul de plată",
  New_Payroll:"Statul de plată nou",
  No_Payroll:"Fără salarizare viitoare",
  Upcoming_Holiday:"Vărbătoarea viitoare:",
  Invoice_Due:"Factură datorată:",
  New_Invoice:"Factură nouă",
  No_Invoices:"Fără facturi",
  No_Invoices_Word:"Creați prima <a routerLink='/folder/Invoice/New'>Factura Fiscala</a>.",
  Cheque_Due:"Cec datorat:",
  Payrolls:"Statele de plată",
  Sandbox_Mode:"Modul Sandbox",
  Hire:"Închiriere",
  Pay:"A plati",
  New:"Nou",
  Add:"Adăuga",
  Make:"Face",
  Time:"Timp",
  Write:"Scrie",
  Holiday:"Vacanţă",
  Holidays:"Sărbători",
  Next_Holiday:"Vacanta viitoare:",
  All_Done:"Totul este gata!",
  Employees:"Angajați",
  Payees:"Beneficiari",
  Job:"Loc de munca",
  Jobs:"Locuri de munca",
  Invoice:"Factura fiscala",
  Invoices:"Facturi",
  Vacations:"Vacanțe",
  Cheques:"Cecuri",
  Brand_Cheques:"Marca",
  Payment:"Plată",
  Enable_Payment:"Activați plata",
  Payments:"Plăți",
  Schedule:"Programa",
  Schedules:"Programe",
  Timesheet:"Foaia de timp",
  Timesheets:"Foile de timp",
  Salary:"Salariu",
  New_Address:"Adresa noua",
  Address_2:"Adresă (linia 2)",
  _City:"Oraș",
  _State:"Stat/Prov",
  City:"Oraș / Township",
  State:"Stat / Provincie",
  Postal:"Cod poștal",
  ZIP:"Poștal / ZIP",
  Country:"Țară",
  Addresses:"Adrese",
  Required_Options:"Opțiuni necesare",
  Optional_Options:"Opțiuni opționale",
  Additional_Options:"Opțiuni suplimentare",
  Required:"Necesar",
  Optional:"Opțional",
  Additional:"Adiţional",
  No_Addresses:"Fără adrese",
  New_Address_Word:"Adăugați prima <a routerLink='/folder/Address/New'>Adresă</a>.",
  Select_Address:"Selectați adresa",
  No_Address:"Fără adrese",
  Print_Cheque:"Printați verificarea",
  Print_Cheque_Now:"Imprimați verificarea acum",
  Description:"Descriere",
  Pay_To_The_Order_Of:"Plătiți la comanda de:",
  Select_Date_Range:"Selectați intervalul de date",
  Select_Starting_Date:"Selectați data de începere",
  Select_Ending_Date:"Selectați data de încheiere",
  Select_Date:"Selectează data",
  Cheque_Date:"Verifică data",
  Cheque_Memo:"Verificați nota",
  Blank_Cheque:"Cecul alb",
  Blank:"Gol",
  No_Cheques:"Fără cecuri",
  No_Cheques_Word:"Tipărește primul tău <a routerLink='/folder/Cheque/New'>Verifica</a>.",
  Cheque_Amount_Placeholder:"0,00 USD",
  View_Image:"Vezi imaginea",
  View:"Vedere",
  Modify:"Modifica",
  Delete:"Șterge",
  Cheque_Paid:"Plătit",
  New_Deduction:"Noua deducere",
  Title:"Titlu",
  Frequency:"Frecvență",
  Hourly:"La oră",
  Daily:"Zilnic",
  Weekdays:"Zilele saptamanii",
  BiWeekly:"2 saptamani",
  TriWeekly:"3 saptamani",
  Monthly:"Lunar",
  MiMonthly:"2 luni",
  Quarterly:"Trimestrial",
  Yearly:"Anual",
  Every_Week:"În fiecare săptămână",
  Every_Payroll:"Fiecare salariu",
  Every_Month:"In fiecare luna",
  Annually:"Anual",
  Always_Scheduled:"Întotdeauna programat",
  Start_Date:"Data de început",
  End_Date:"Data de încheiere",
  Start_Time:"Timpul de începere",
  End_Time:"Sfârșitul timpului",
  Deduction_Label:"Etichetă deducere",
  Notes:"Note",
  Options:"Opțiuni",
  Enable:"Permite",
  Select_Deductions:"Selectați deduceri",
  Deductions:"Deduceri",
  No_Deductions:"Fără deduceri",
  No_Deductions_Word:"Creează-ți primul <a routerLink='/folder/Deduction/New'>Deducere</a>.",
  New_Employee:"Angajat nou",
  No_Title:"Fara titlu",
  _Name:"Nume",
  About_Yourself:"Despre tine",
  Full_Name:"Numele complet",
  Birthday:"Zi de nastere",
  Email:"E-mail",
  SMS:"MESAJ",
  Phone:"Telefon",
  Test:"Test",
  Call:"Apel",
  Fax:"Fax",
  Printed_Note:"Notă tipărită",
  Position:"Poziţie",
  Job_Position:"Loc de muncă",
  Select_a_Job:"Selectați un loc de muncă",
  Select_a_Salary:"Selectați un salariu",
  Add_a_Deduction:"Adăugați o deducere",
  Taxes:"Taxe",
  Add_Taxes:"Adăugați taxe",
  Date_of_Birth:"Data de nastere",
  Email_Address:"Adresa de email",
  Phone_Number:"Numar de telefon",
  Phone_Call:"Apel telefonic",
  Enable_on_Payroll:"Activați pe salariu",
  Select_Employees:"Selectați angajați",
  No_Employees:"Fără angajați",
  No_Employees_Word:"Adăugați primul dvs. nou <a routerLink='/folder/Employee/New'>Angajat</a>.",
  No_Name:"Fara nume",
  Unemployeed:"Şomerii",
  Employeed:"Angajat",
  Paid:"Plătit",
  Enabled:"Activat",
  Disabled:"Dezactivat",
  Fire:"Foc",
  Not_Available:"Nu e disponibil",
  Not_Available_Word:"Imprimați prima <a routerLink='/folder/Invoice/New'>Factura Fiscala</a> și primiți plata.",
  Select_Invoices:"Select_Invoices",
  Print_Invoice_Word:"Imprimați prima <a routerLink='/folder/Invoice/New'>Factura Fiscala</a> și primiți plata.",
  Invoice_Title:"Titlul facturii",
  Invoice_Date:"Data facturii",
  Due_Date:"Data scadentă",
  New_Job:"Nou loc de muncă",
  Details:"Detalii",
  Customize:"Personalizați",
  Customize_Dashboard:"Personalizați tabloul de bord",
  Components:"Componente",
  No_Components:"Fără componente",
  Main_Components:"Componentele principale",
  Smaller_Components:"Componente mai mici",
  Error_Loading_Page:"Eroare la încărcarea acestei pagini.",
  Bank_Details:"Detalii bancare",
  About_Your_Job:"Despre jobul tău",
  Your_Schedule:"Programul tau",
  Job_Title:"Denumirea funcției",
  Job_Description:"Descrierea postului",
  Job_Details:"Detalii job",
  Enable_Job:"Activați job",
  Pay_Period:"Perioadă de plată",
  Perpetually_Schedule:"Programează permanent",
  Select_Jobs:"Selectați locuri de muncă",
  No_Jobs:"Fără locuri de muncă",
  Add_Jobs:"Adăugați locuri de muncă",
  No_Jobs_Word:"Adăugați primul <a routerLink='/folder/Job/New'>Loc de Munca</a> la listă.",
  Count_Employees:"job.employee_count+' Angajații'",
  Zero_Employees:"0 angajați",
  New_Leave:"Concediu nou",
  Leave_Name:"Lasă numele",
  Paid_Leave:"Concediu plătit",
  Leave_Pay:"Lasă plata",
  Indefinite_Leave:"Concediu pe termen nedeterminat",
  Indefinite_Payroll:"Statul de plată nedeterminat",
  Leave:"Părăsi",
  Add_Schedules:"Adăugați programe",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Activați absența",
  Select_Leaves:"Selectează frunze",
  No_Leaves:"Fără frunze de absență",
  Leave_Of_Absence:"Concediu",
  Leaves_Of_Absence:"Frunzii de absență",
  New_Leave_of_Absense:"Noua concediu",
  No_Leaves_Word:"Adăugați primul <a routerLink='/folder/Leave/New'>Concediu</a>.",
  Not_Enabled:"Neactivat",
  Absences:"Absențe",
  Payee:"Beneficiar",
  New_Payee:"Noul beneficiar",
  Payee_Identifier:"Identificatorul beneficiarului",
  Payee_Name:"Numele beneficiar",
  Payee_Title:"Titlul beneficiarului",
  Payment_Memo:"Notă de plată",
  Select_Payees:"Selectați beneficiari",
  No_Payees:"Fără beneficiari",
  Add_Payee_Note:"Adăugați primul <a routerLink='/folder/Payee/New'>Beneficiar</a>.",
  New_Payees:"Noi beneficiari",
  About_The_Payment_Schedule:"Program de plată",
  Your_Payroll_Schedule:"Statul de plată automat",
  New_Payment:"Plată nouă",
  Identifier:"Identificator",
  Select:"Selectați",
  Memo:"Notificare",
  Payment_Date:"Data de plată",
  Mark_as_Paid:"Marcați ca plătit",
  Select_Payments:"Selectați plăți",
  No_Payments:"Fără plăți",
  No_Payments_Word:"Creați primul <a routerLink='/folder/Payment/New'>Plată</a>.",
  Create_Payroll:"Creează salariu",
  Properties:"Proprietăți",
  Payroll_Title:"Titlul de salarizare",
  Payroll_Notes:"Note de plată",
  Payroll_Setup:"Configurare salarizare",
  Tabulate_Payments:"Tabulați plăți",
  Tabulate:"Cataloga",
  By:"De:",
  Payments_By:"Plăți prin",
  Timesheets_And_Schedules:"Foile de pontaj și orare",
  Both:"Ambii",
  Items:"Articole",
  Add_Payees:"Adăugați beneficiari",
  Add_Account:"Adaugă cont",
  Enable_Account:"Activați contul",
  Enable_Payroll:"Activați salarizare",
  Print_Payroll:"Tipărește statul de plată",
  No_Payrolls:"Fără salariu",
  No_Payroll_Word:"Creează-ți primul <a routerLink='/folder/Payroll/New'>Salarizare</a>.",
  View_more:"VEZI MAI MULT",
  Less:"MAI PUȚIN",
  Add_Payroll:"Adăugați salariu",
  Select_Payroll:"Selectați salarizare",
  About_Your_Salary:"Despre salariul tău",
  Add_Salaries:"Adăugați salarii",
  Add_Salary:"Adăugați salariul",
  Salaries:"Salarii",
  No_Salaries:"Fără salarii",
  No_Salaries_Word:"Adăugați primul <a routerLink='/folder/Salary/New'>Salariu</a>.",
  Select_Salaries:"Selectați salariile",
  New_Salary:"Salariu nou",
  Salary_Name:"Identificator de salariu",
  Enable_Salary:"Activați salariul",
  Salary_Amount:"Suma salariului",
  New_Schedule:"Program nou",
  Schedule_Title:"Titlul programului",
  Add_Address:"Adaugă adresă",
  Repeat:"Repeta",
  Design:"Proiecta",
  Edit_Design:"Editați design",
  Edit_this_Design:"Editați acest design",
  Repeat_Payment:"Repetați plata",
  Enable_Schedule:"Activați programul",
  Never:"Nu",
  Select_Schedule:"Selectați programe",
  No_Schedules:"Fără program",
  No_Schedules_Word:"Creați primul <a routerLink='/folder/Schedule/New'>Programa</a>.",
  New_Administrator:"Administrator nou",
  Username:"Nume de utilizator",
  First_Name:"Nume",
  Last_Name:"Numele de familie",
  Add_an_Address:"Adăugați o adresă",
  Payment_Limit:"Limita per plată",
  Total_Limit:"Limita totală",
  Select_Accounts:"Selectați conturi",
  No_Administrators:"Fără administratori",
  No_Administrators_Word:"Creați primul <a routerLink='/folder/Administrator/New'>Cont de Administrator</a>.",
  New_Administrators_Word:"Adăugați primul <a routerLink='/folder/Administrator/New'>Administrator</a>",
  Cloud:"Nor",
  Backup:"Backup",
  Enable_iCloud:"Activați iCloud",
  Enable_Google_Drive:"Activați Google Drive",
  Enable_Microsoft_OneDrive:"Activați Microsoft OneDrive",
  Automatically_Backup:"Fă backup automat",
  FTP_Settings:"Setări FTP",
  URL_File_Prompt:"Vă rugăm să specificați locația pentru importarea unui fișier .xls / .xlsx / .json:",
  URL_SQL_Prompt:"Vă rugăm să specificați locația fișierului SQLite de importat:",
  FTP_Backup:"FTP Backup",
  FTP_Import:"Import FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Gazdă",
  Port:"Port",
  Path:"Cale",
  Data_Path:"Calea datelor",
  Enable_FTP_Account:"Activați contul FTP",
  HostnameIP:"Nume gazdă",
  Password:"Parola",
  Connection_Port:"Port de conectare",
  Enable_MySQL_Database:"Activați baza de date MySQL",
  Log:"Buturuga",
  Reset:"Resetare",
  Erase:"Şterge",
  Export:"Export",
  Database:"Bază de date",
  Upload_CSV:"Încărcați CSV",
  Download_CSV:"Descărcați CSV",
  SQL_Database:"URL SQL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Copia de rezervă MySQL",
  Internal_Notes:"Note interne",
  Root_Path:"Cale rădăcină",
  Select_Backup:"Selectați Backup",
  Add_New_Backup:"Adăugați o copie de rezervă nouă",
  First_Backup:"Configurați prima copie de rezervă...",
  Backups:"Backup-uri",
  Add_Backup:"Adăugați o copie de rezervă",
  No_Backups:"Nu există copii de rezervă de găsit.",
  Select_Backup_Type:"Selectați tipul de backup pe care doriți să îl configurați...",
  Backup_Type:"Tip de rezervă",
  FTP_Drive:"Unitate FTP",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Conduce",
  Microsoft_OneDrive:"Conduce",
  Import_Fields:"Importați câmpuri",
  Import_Fields_Word:"Utilizați această secțiune pentru a selecta <a routerLink='/folder/Holiday/New'>Import</a> date.",
  Upload:"Încărcare",
  Download:"Descarca",
  Download_JSON:"Descărcați ca date JSON",
  Download_SQL:"Descărcați ca fișier SQL",
  New_Bank:"Bancă nouă",
  New_Account:"Cont nou",
  New_Bank_Account:"Cont bancar nou",
  Upload_Image:"Incarca imaginea",
  Bank_Name:"Numele băncii",
  Bank_Address:"Adresa băncii",
  Branch_Address:"Adresa filialei",
  Address_on_Cheque:"Abordare",
  Cheque_Numbers:"Verificați numerele",
  Cheque_Details:"Verifică detalii",
  Bank_Logo:"Sigla băncii",
  Cheque_ID:"Verificați ID",
  Starting_Cheque_ID:"Începe verificarea ID",
  Transit_Number:"Număr de tranzit",
  Institution_Number:"Numărul instituției",
  Designation_Number:"Numărul de desemnare",
  Account_Number:"Numar de cont",
  Limits:"Limite",
  Default_Limits:"Limite implicite",
  Over_Limit:"Peste limită",
  Under_Limit:"Sub limită",
  Payroll_Limit:"Limita de salarizare",
  Enable_Bank_Account:"Activați contul bancar",
  Select_Account:"Selectați contul",
  No_Bank_Account:"Fără cont bancar",
  No_Bank_Account_Word:"Adăugați primul <a routerLink='/folder/Accounts/New'>Cont Bancar</a>.",
  Bank_Accounts:"Conturi bancare",
  No_Accounts:"Fără conturi",
  No_Accounts_Note:"Adăugați primul <a routerLink='/folder/Accounts/New'>Cont Bancar</a>.",
  Cheque_Designer:"Verifică Designer",
  Cheque_Design:"Verificați design",
  Select_Design:"Selectați un design",
  Cheque_Designs:"Verificați modelele",
  No_Cheque_Designs:"Fără modele de verificare",
  No_Cheque_Designs_Word:"Creați-vă propriul <a routerLink='/folder/Settings/Cheque/Design/New'>Verificați Design</a>.",
  Set_Default:"Setat ca implicit",
  Default:"Mod implicit",
  Remove:"Elimina",
  Folder:"Pliant",
  Edit:"Editați | ×",
  LoadingDots:"Se încarcă...",
  Add_a_New_File:"Adăugați un <a href='#' (click)='add()'>Fișier nou</a> la",
  this_folder:"acest folder",
  FTP_Backup_Settings:"Setări de backup FTP",
  Secure_File_Transfer:"Transfer securizat de fișiere",
  New_Holiday:"O nouă vacanță",
  Add_Holiday:"Adăugați vacanță",
  Holiday_Name:"Nume de vacanță",
  Additional_Pay:"Plata suplimentară",
  Enable_Holiday:"Activați vacanța",
  Select_Holidays:"Selectați sărbători",
  No_Holidays:"Fără sărbători",
  No_Holidays_Word:"Adăugați primul <a routerLink='/folder/Holiday/New'>Sărbătoare Publică</a>.",
  New_Import:"Import nou",
  Import_Data:"Importați date",
  Import_Data_Word:"Selectați tipul de fișier sau metoda de încărcare pe care o alegeți.<br /> Veți putea selecta câmpurile importate dintr-un fișier care corespund oricărui parametru din aplicație după încărcarea unui fișier acceptat.", 
  Import_File:"Fișier de import",
  Link_To_File:"Link către fișier",
  Select_File:"Selectați fișierul",
  New_Moderator:"Noul Moderator",
  Allow_Moderation:"Permite moderarea",
  Create_Paycheques:"Creează salarii",
  Edit_Paycheques_and_Data:"Editați salariile și datele",
  Destroy_Data_and_Paycheques:"Distrugeți datele și salariile",
  Bonus_Percentage:"Procentul de salariu",
  Fixed_Amount:"Cantitate fixă",
  Select_Moderator:"Selectați moderatorul",
  No_Moderators:"Fără moderatori",
  Moderators:"Moderatori",
  Moderator_Account:"Creați primul <a routerLink='/folder/Administrator/New'>Cont de Moderator</a>.",
  No_Moderators_Word:"Adăugați primul <a routerLink='/folder/Administrator/New'>Moderator</a>.",
  Defaults:"Implicite",
  Provide_Us_Info:"Oferă-ne informații",
  Setup_Your_Printer:"Configurați-vă imprimanta",
  Your_Company:"Despre compania ta",
  Company_Name:"Numele companiei",
  Currency:"Valută",
  Default_Currency:"Moneda implicită",
  Base_Monthly_Income:"Venit lunar",
  Protection:"Protecţie",
  App_Protection:"Protecție aplicație",
  PIN_Code_Protection:"Protecție cu codul PIN",
  App_Protection_Word:"Activați metodele de securitate care vă ajută să vă protejați contul.",
  PIN_Code:"Cod PIN",
  Change_PIN:"Schimba pin-ul",
  New_Password:"Parolă Nouă",
  Geofence_Protection:"Protecție geo-gard",
  Geofence_Area:"Setare zonă",
  Distance:"Distanţă",
  Setup_Now:"Configurați acum",
  Mile:"Mile",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Recunoastere faciala",
  Face:"Față",
  Setup:"Înființat",
  Label:"Eticheta",
  Password_Protection:"Protecție cu parolă",
  Modify_Password:"Modificați parola",
  New_Tax_Entry:"Noua intrare fiscală",
  New_Tax:"Noua taxa",
  Tax_Label:"Etichetă fiscală",
  Perpetually_Enabled:"Activat permanent",
  Select_Taxes:"Selectați taxe",
  Tax_Deductions:"Deduceri fiscale",
  No_Tax_Deductions:"Fără deduceri fiscale",
  No_Tax_Deductions_Word:"Adăugați prima deducere <a routerLink='/folder/Tax/New'>Impozit</a>.",
  New_Timer:"Cronometru nou",
  Start:"Start",
  Stop:"Stop",
  Start_Timer:"Pornire cronometru",
  Stop_Timer:"Oprire cronometru",
  Timer_Active:"Cronometru activ",
  Timer:"Temporizator:",
  Timer_Running:"Cronometru: (Rulează)",
  Save_Timer:"Salvare cronometru",
  New_Timesheet:"Noua foaie de timp",
  Select_Timesheets:"Selectați foile de timp",
  Work_Notes:"Note de lucru",
  Entry_Title:"Titlul intrării",
  No_Timesheets:"Fără foi de timp",
  No_Timesheets_Word:"Adăugați prima <a routerLink='/folder/Timesheet/New'>Foaia de Pontaj</a>.",
  Timesheet_Submitted:"Foaia de pontaj trimisă",
  Timesheet_Congrats:"Felicitări! Foaia de timp a fost trimisă cu succes. Vă mulțumim!",
  Timesheet_Copy:"Pentru a primi o copie a documentelor dumneavoastră vă rugăm să ne furnizați adresa dumneavoastră de e-mail și/sau numărul de telefon mobil.",
  Submit:"Trimite",
  Allow_Notifications:"Permite notificări",
  Untitled_Entry:"Intrare nouă",
  Untitled_Bank:"Banca fără titlu",
  Timesheet_Entry:"Intrare foaie de timp",
  Work_Description:"Descrierea muncii",
  Enable_Timesheet:"Activați foaia de timp",
  Submit_Timesheet:"Trimiteți foaia de timp",
  Vacation:"Concediu de odihna",
  New_Vacation:"O nouă vacanță",
  Vacation_Name:"Numele vacanței",
  Paid_Vacation:"Vacanta platita",
  Vacation_Pay:"Plată de vacanță",
  Enable_Vacation:"Activați vacanța",
  Select_Vacations:"Selectați vacanțe",
  No_Vacations:"Fără vacanțe",
  No_Vacations_Word:"Creați prima intrare <a routerLink='/folder/Vacation/New'>Concediu de Odihna</a>.",
  Payroll_Schedule:"Programul de salarizare",
  Next_Payroll:"Următorul salariu:",
  Upcoming_Payroll:"Statele viitoare",
  No_Upcoming_Payroll:"Fără salarizare viitoare",
  Address_Book:"Carte de adrese",
  Archived_Documents:"Documente arhivate",
  Dev_Mode:"Aplicație în modul de dezvoltare",
  Administrators:"Administratori",
  Privacy:"Confidențialitate",
  None:"Nici unul",
  Select_Signature:"Selectați semnătura",
  No_Signatures:"Fără semnături",
  No_Signatures_Word:"Adăugați prima <a routerLink='/folder/Signature/New'>Semnătură</a>.",
  Repeat_Indefinitely:"Repetă la infinit",
  Sign:"Semn",
  Sign_Here:"Înregistrează aici",
  Date_Signed:"Data semnăturii",
  Signature_Pad:"Bloc de semnătură",
  Account_Holder:"Titular de cont",
  Account_Properties:"Proprietățile contului",
  Name_On_Cheque:"Nume pe cec",
  Server_Hostname:"Nume gazdă/IP server",
  Printers:"Imprimante",
  No_Printers:"Fără imprimante",
  Printer_Exists:"O imprimantă cu acest nume există deja.",
  No_Printers_Word:"Adăugați prima <a routerLink='/folder/Printer/New'>Imprimanta</a>.",
  No_Printer_Alert:"Nu este definită nicio imprimantă. Doriți să configurați o imprimantă?",
  Add_Printer:"Adăugați o imprimantă",
  New_Printer:"Imprimantă nouă",
  Printer_Hostname:"Nume gazdă/IP imprimante",
  Printer_Label:"Etichetă imprimantă",
  Printer_Protocol:"Protocol de imprimantă",
  Protocol:"Protocol",
  Email_Print:"E-mail",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Priză",
  Print_Job:"Lucrări de imprimare",
  Printed:"Tipărit",
  Not_Printed:"Netipărit",
  Print_Jobs:"Lucrări de imprimare",
  Print_Queue:"Coada de imprimare",
  No_Print_Jobs:"Fără lucrări de imprimare",
  No_Prints:"Creați un nou <a routerLink='/folder/Cheque/New'>Cec</a> pentru a imprima.",
  Prints:"Imprimări",
  Find_Printer:"Găsiți imprimantă",
  Find_AirPrint_Devices:"Găsiți dispozitive AirPrint",
  Select_Printer:"Selectați imprimanta",
  System_UI:"Interfața de utilizare a sistemului",
  Printer:"Imprimanta",
  Status:"Stare",
  Preview:"Previzualizare",
  Enable_Print_Job:"Activați lucrarea de imprimare",
  Queue_Weight:"Greutate coadă",
  Unlimited:"Nelimitat",
  Show_Advanced_Printer_Options:"Afișați opțiuni avansate de imprimantă",
  Advanced:"Avansat",
  Location:"Locație",
  Note:"Notă",
  Queue_Name:"Nume coadă",
  Pages_Printed_Limit:"Limita pagini imprimate",
  MultiPage_Idle_Time:"Timp de așteptare în mai multe pagini",
  Page_Count_Limit:"Limita numărului de pagini",
  Page_Orientation:"Orientarea paginii",
  Portrait:"Portret",
  Landscape:"Peisaj",
  Duplex:"Duplex",
  Double_Sided_Printing:"Cu două fețe",
  Duplex_Mode:"Modul duplex",
  Duplex_Short:"Mic de statura",
  Duplex_Long:"Lung",
  Duplex_None:"Nici unul",
  Color_And_Quality:"Culoare și calitate",
  Monochrome:"Monocrom",
  Photo_Quality_Prints:"Imprimări de calitate foto",
  Printer_Email:"E-mail imprimante",
  Automatically_Downsize:"Reduceți automat dimensiunea",
  Paper:"Hârtie",
  Paper_Name:"Nume hârtie",
  Paper_Width:"Lățimea hârtiei",
  Paper_Height:"Înălțimea hârtiei",
  Paper_Autocut_Length:"Lungime tăiere automată a hârtiei",
  Margins:"Marje",
  Page_Margins:"Marje de pagină",
  Page_Margin_Top:"Marja de sus a paginii",
  Page_Margin_Right:"Marja paginii din dreapta",
  Page_Margin_Bottom:"Marja inferioară a paginii",
  Page_Margin_Left:"Marja din stânga paginii",
  Add_Employees:"Adăugați angajați",
  Header:"Antet",
  Print_A_Page_Header:"Tipărește antetul unei pagini",
  Header_Label:"Etichetă antet",
  Header_Page_Index:"Indexul paginii antet",
  Header_Font_Name:"Font pentru antet",
  Select_Font:"Selectați fontul",
  Font_Selector:"Selector de font",
  Header_Font_Size:"Font pentru antet",
  Header_Bold:"Header Bold",
  Header_Italic:"Header Italic",
  Header_Alignment:"Alinierea antetului",
  Left:"Stânga",
  Center:"Centru",
  Right:"Dreapta",
  Justified:"justificat",
  Header_Font_Color:"Culoarea antetului",
  Select_Color:"Selectați culoarea",
  Footer:"Subsol",
  Print_A_Page_Footer:"Tipărește subsolul unei pagini",
  Footer_Label:"Etichetă de subsol",
  Footer_Page_Index:"Indexul paginii de subsol",
  Footer_Font_Name:"Fontul de subsol",
  Fonts:"Fonturi",
  Done:"Terminat",
  Select_Fonts:"Selectați fonturi",
  Footer_Font_Size:"Dimensiunea subsolului",
  Footer_Bold:"Footer Bold",
  Footer_Italic:"Footer Italic",
  Footer_Alignment:"Alinierea subsolului",
  Footer_Font_Color:"Culoarea subsolului",
  Page_Copies:"Copii pagini",
  Enable_Printer:"Activați imprimanta",
  Remote_Logging:"Înregistrare la distanță",
  Log_Server:"Server de jurnal",
  Encryption:"Criptare",
  Random_Key:"Cheie aleatorie",
  Encryption_Key:"Cheie de criptare",
  Cheques_Webserver:"Bază de date personalizată",
  Learn_How:"Afla cum",
  Signature:"Semnătură",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Vezi semnătura",
  Printed_Signature:"Semnătură tipărită",
  Digitally_Sign:"Semnează digital",
  Digital_Signature:"Semnatura digitala",
  Add_Signature:"Adăugați semnătura",
  Add_Your_Signature:"Adăugați semnătura dvs.",
  Enable_Signature:"Activați semnătura",
  Digitally_Signed:"Semnat digital",
  Insert_Error:"Nu se poate salva verificarea din cauza problemelor cu baza de date.",
  Delete_Confirmation:"Sunteți sigur că doriți să ștergeți aceste informații?",
  Discard_Confirmation:"Sunteți sigur că doriți să renunțați la aceste informații?",
  Discard_Bank_Confirmation:"Sunteți sigur că doriți să renunțați la acest cont?",
  Discard_Printer_Confirmation:"Sunteți sigur că doriți să aruncați această imprimantă?",
  Delete_Bonus_Confirmation:"Sunteți sigur că doriți să ștergeți acest bonus?",
  Delete_Invoice_Confirmation:"Sunteți sigur că doriți să ștergeți această factură?",
  Generated_Cheque:"Cecul generat",
  Generated_Invoice:"Factură generată",
  Schedule_Start_Time:"Început program",
  Schedule_End_Time:"Sfârșitul programului",
  New_Call:"Apel nou",
  No_Contacts:"Fără contacte",
  No_Contacts_Word:"Administratorii, moderatorii, angajații și beneficiarii apar ca persoane de contact.",
  PDF_Subject:"finanțe",
  PDF_Keywords:"cec de plată PDF cec de plată",
  Printer_Setup:"Configurarea imprimantei",
  Printer_Selection:"Selectarea imprimantei",
  New_Fax:"Fax nou",
  New_Fax_Message:"Mesaj de fax nou",
  Fax_Machine:"Fax",
  Fax_Name:"Nume fax",
  Fax_Email:"Fax Email",
  Fax_Number:"Numar de fax",
  Contents:"Conținut",
  Fax_Body:"Corpul paginii",
  Header_Word:"Antet:",
  Header_Text:"Antet",
  Include_Header:"Include antet",
  Include_Footer:"Include subsol",
  Footer_Word:"Subsol:",
  Footer_Text:"Text de subsol",
  Attach_a_Cheque:"Atașați un cec",
  Add_Deduction:"Adăugați deducere",
  Enable_Fax:"Trimite fax",
  Select_Fax:"Selectați fax",
  No_Faxes:"Fără faxuri",
  Faxes:"Faxuri",
  Save_and_Send:"Trimite fax",
  Save_and_Pay:"Salvați și plătiți",
  WARNING:"AVERTIZARE:",
  Remember:"Tine minte",
  Printing:"Tipărire",
  Printing_Complete:"Imprimarea finalizată!\n\n",
  of:"de",
  There_Were:"Au existat ",
  Successful_Prints:"printuri de succes și",
  Unsuccessful_Prints:"printări nereușite.",
  PrinterError:"Scuze a fost o eroare.",
  Printing_:"Se imprimă...",
  PrinterSuccess:"Documentul a fost tipărit cu succes.",
  PrintersSuccess:"Documentele au fost tipărite cu succes.",
  Print_Cheques:"Tipărește cecuri",
  New_Message:"Mesaj nou",
  New_Messages:"Mesaje noi",
  Read_Message:"Citește mesajul",
  Write_Message:"Scrie mesaj",
  Send_Message:"Trimite mesaj",
  Subject:"Subiect",
  Message:"Mesaj",
  Writing:"Scris...",
  Send:"Trimite",
  Set_Date:"Seteaza data",
  Set_Time:"Potriveste ora",
  Recieve:"Primește",
  Set_as_Default:"Setat ca implicit",
  Default_Account:"Cont implicit",
  Default_Design:"Design implicit",
  Multiple_Cheques:"Cecuri (Triple)",
  Account_Mode:"Mod cont",
  Multiple_Cheques_Description:"Trei cecuri pe pagină.",
  Check_and_Table:"Verifică și tabel",
  Check_including_Table:"Tabel de verificare și contabilitate.",
  Check_Mailer:"Verifică Mailer",
  Check_Mailer_Window:"Verificați cu o fereastră de adresă.",
  DocuGard_Table_Top:"DocuGard Verificați și Tabel (Sus)",
  DocuGard_Table_Middle:"DocuGard Verificați și Tabel (Middle)",
  DocuGard_Table_Bottom:"DocuGard Verificați și Tabel (jos)",
  DocuGard_Mailer_Top:"DocuGard Cec și Poștă (Sus)",
  DocuGard_Mailer_Middle:"DocuGard Cec și Poștă (Mijloc)",
  DocuGard_Mailer_Bottom:"DocuGard Cec și Poștă (jos)",
  DocuGard_Three_Cheques:"Verificări DocuGard (Triple)",
  DocuGard_Cheque_Top:"Verificare DocuGard (Sus)",
  DocuGard_Cheque_Middle:"Verificare DocuGard (Mijloc)",
  DocuGard_Cheque_Bottom:"Verificare DocuGard (de jos)",
  DocuGard_Three_Cheques_Window:"Trei cecuri pe o singură pagină.",
  DocuGard_Table_Top_Window:"Tabelul de verificare și câștiguri.",
  DocuGard_Table_Middle_Window:"Tabelul de verificare și câștiguri.",
  DocuGard_Table_Bottom_Window:"Tabelul de verificare și câștiguri.",
  DocuGard_Mailer_Top_Window:"Cecul, tabelul și adresa.",
  DocuGard_Mailer_Middle_Window:"Cecul, tabelul și adresa.",
  DocuGard_Mailer_Bottom_Window:"Cecul, tabelul și adresa.",
  DocuGard_Cheque_Top_Window:"Verificați dacă există hârtie sigură.",
  DocuGard_Cheque_Middle_Window:"Verificați dacă există hârtie sigură.",
  DocuGard_Cheque_Bottom_Window:"Verificați dacă există hârtie sigură.",
  Basic_Cheque:"Verificați (sus)",
  Basic_Cheque_Print:"Tipărește un singur cec.",
  Error_Setting_As_Paid:"Eroare la setarea ca plătit",
  Add_Attachment:"Adauga atasament",
  PrinterUnavailable:"Imprimantă indisponibilă",
  CreditCardComponent:"Carduri",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Depozit nou",
  Deposits:"Depozite",
  No_Deposits:"Fără depozite",
  Credit_Card_Deposit:"Card de credit",
  New_Credit_Card_Deposit_Message:"Depozit cu card de credit",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Depozit Bitcoin",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Transfer Interac",
  Payments_Limit:"Limita de plată",
  No_Payment_Limit:"Fără limită de plată",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Depozit PayPal",
  No_Deposits_Word:"Adăugați prima companie <a routerLink='/folder/Deposit/New'>Depozit</a>.",
  No_Documents_Specified:"Nu au fost specificate documente pentru tipărire",
  No_Printers_Added:"Nu au fost găsite imprimante. Accesați Setări > Imprimante pentru a adăuga una.",
  DB_Erase_Prompt:"Ștergeți complet întreaga bază de date? AVERTISMENT: veți pierde toate informațiile stocate!",
  Console_Warning:"Nu lipiți niciun text în această consolă. Vă puteți expune un risc serios pe dvs. și/sau compania dumneavoastră.",
  No_Faxes_Word:"Creați primul <a routerLink='/folder/Fax/New'>Fax</a>.",
  Cheque_Delete_Confirmation:"Sunteți sigur că doriți să ștergeți acest cec?",
  Design_Delete_Confirmation:"Sunteți sigur că doriți să ștergeți acest design?",
  Cheque_Pay_Confirmation:"Marcați acest cec ca plătit? NU va apărea în coada de imprimare.",
  Payment_Pay_Confirmation:"Marcați această plată ca plătită? NU va apărea în coada de cecuri.",
  Delete_Deduction_Confirmation:"Sunteți sigur că doriți să ștergeți această deducere?",
  Delete_Job_Confirmation:"Sunteți sigur că doriți să ștergeți acest job?",
  Delete_Timesheet_Confirmation:"Sunteți sigur că doriți să ștergeți această foaie de pontaj?",
  Delete_Schedule_Confirmation:"Sunteți sigur că doriți să ștergeți acest program?",
  Delete_Setting_Confirmation:"Sunteți sigur că doriți să resetați această setare?",
  Delete_Fax_Confirmation:"Sunteți sigur că doriți să ștergeți acest fax?",
  Delete_File_Confirmation:"Sunteți sigur că doriți să ștergeți acest fișier?",
  Delete_Vacation_Confirmation:"Sunteți sigur că doriți să ștergeți această vacanță?",
  Delete_Printer_Confirmation:"Sunteți sigur că doriți să ștergeți această imprimantă?",
  Remove_Design_Confirmation:"Sunteți sigur că doriți să ștergeți acest design?",
  Delete_Payroll_Confirmation:"Sunteți sigur că doriți să ștergeți acest stat de plată?",
  Send_Fax_Email_Confirmation:"Doriți să trimiteți prin fax și prin e-mail aceste documente?",
  Send_Email_Confirmation:"Doriți să trimiteți acest document prin e-mail?",
  Send_Fax_Confirmation:"Doriți să trimiteți acest document prin fax?",
  Error_Generating_PDF:"Ne pare rău. A apărut o eroare la generarea acestui document.",
  PDF_Error_Saving_Image:"Ne pare rău. A apărut o eroare la salvarea imaginii PDF a acestui document.",
  Test_Printer_Confirmation:"Doriți să imprimați o pagină de test pe această imprimantă?",
  Save_Timesheet_Prompt:"Vă rugăm să adăugați un \"Titlu\" sau apăsați \"Start Timer\" pentru a salva.",
  Remove_Geofence_Prompt:"Sunteți sigur că doriți să eliminați locația acestui gard geografic?",
  Delete_Employee_Confirmation:"Ești sigur că vrei să ștergi ",
  Fire_Employee_Confirmation:"Ești sigur că vrei să tragi"
}