export const Sr = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Ауторска права &цопи; 2023",
  black:"Црн",
  green:"Зелен",
  gold:"Злато",
  blue:"Плави",
  brown:"Браон",
  purple:"Љубичаста",
  pink:"Пинк",
  red:"Црвени",
  Swatches:"Сватцхеви",
  HSL:"ХСЛ",
  RGB:"РГБ",
  Hue:"Нијанса",
  Saturation:"Засићење",
  Lightness:"Лакоћа",
  Upgrade_To_Pro:"Унапреди се у професионалца",
  AllFeaturesInclude:"Све карактеристике укључују:",
  PrintUnlimitedCheques:"Штампајте неограничене чекове",
  PremiumChequeDesigns:"Врхунски дизајни чекова",
  ManageUnlimitedEmployees:"Управљајте неограниченим бројем запослених",
  AddUnlimitedPayees:"Додајте неограничене примаоце плаћања",
  CreateUnlimitedPayrolls:"Направите неограничене платне спискове",
  UnlimitedSchedulesandTimeSheets:"Неограничени распореди и распореди",
  BulkPayPalPayouts:"Групне ПаиПал исплате",
  UnlimitedBankAccounts:"Неограничени банковни рачуни",
  ManageMultipleCompanies:"Управљајте више компанија",
  TrackInsurancePolicies:"Пратите полисе осигурања",
  Bio_MetricProtection:"Био-метричка заштита",
  Geo_FenceLock_OutProtection:"Гео-Фенце Лоцк-Оут заштита",
  Multiple_Companies_Word:"Управљање више компанија није доступно без премијум чекова.",
  PayPal_Payouts_Word:"ПаиПал плаћања су онемогућена без премије чекова.",
  PINProtection:"ПИН Протецтион",
  PasswordProtection:"Заштита лозинком",
  May_Require_Approval:"Може захтевати одобрење.",
  Subscribe:"претплатити се",
  Billed:"Наплаћено",
  Up:"Горе",
  Down:"Доле",
  Positioning:"Позиционирање",
  Marker:"Маркер",
  Drag_Marker:"Превуците маркер",
  Tagline:"Штампање чекова и табела платног списка",
  Marker_Word:"Користите маркере за величину елемента.",
  Pinch_Zoom:"Зумирање прстију",
  Pinch_Word:"Стисните прсте да бисте зумирали елемент.",
  Drag:"Драг",
  Drag_Word:"Користите прст да превучете елементе.",
  subscription_alias_word:"Аутоматско обнављање претплате",
  premium_alias_word:"Једнократни пакет за надоградњу",
  print_alias_word:"Одштампајте појединачне чекове",
  mode_alias_word:"Моде",
  Pro:"Про",
  Pro_word:"Потребна је Про верзија.",
  Cant_Delete_Default_Company_Word:"Жао нам је, не можете да избришете подразумевану компанију.",
  Reinsert_Default_Designs:"Поново уметните подразумеване дизајне",
  Reinsert_Default_Designs_word:"Да ли желите да поново уметнете подразумеване дизајне?",
  Subscription_Active_Disable_Word:"Ова претплата је активна. Да ли желите да откажете ову претплату на Чекове?",
  Company_Logo:"Лого компаније",
  ZERO_:"НУЛА",
  Disclaimer:"Одрицање од одговорности",
  Privacy_Policy:"Правила о приватности",
  EULA:"Проверава ЕУЛА",
  Terms_Of_Service:"Услови коришћења",
  Terms_Of_Use:"Услови коришћења",
  Refunds:"Политика рефундирања",
  Single_Print:"1 Проверите",
  Two_Prints:"2 провере",
  Five_Prints:"5 провере",
  Ten_Prints:"10 чекова",
  Fifteen_Prints:"15 Чекови",
  Twenty_Prints:"20 чекова",
  Thirty_Prints:"30 чекова",
  Image_Added:"Слика додата",
  Image_Preview:"Преглед слике",
  No_Image_Was_Selected:"Није изабрана ниједна слика.",
  Cheques_Unlimited:"Неограничени чекови",
  _Subscription:"Претплата",
  Subscription:"Чекови - 1 месец",
  Two_Month_Subscription:"Чекови - 2 месеца",
  Three_Month_Subscription:"Чекови - 3 месеца",
  Six_Month_Subscription:"Чекови - 6 месеци",
  Twelve_Month_Subscription:"Чекови - 12 месеци",
  Cheques_Are_Available:"Чекови су доступни за штампање.",
  Upgrade_Required_Two:"Изаберите пакет и двапут додирните дугме за цену да бисте започели куповину. Штампајте чекове професионалног изгледа у пуној боји за неколико секунди.",
  Month:"Месец дана",
  Due:"рок:",
  Expires:"Истиче:",
  Subscription_Active:"Претплата активна",
  Subscription_Inactive:"Претплата је неактивна",
  Purchase_Additional_Cheques:"Купити додатне чекове?",
  Printable_Cheque:"Чек за штампање",
  Printable_Cheques:"Чекови за штампање",
  Printable_Cheque_Word:"чек је доступан на вашем налогу.",
  Printable_Cheques_Word:"чекови су доступни на вашем рачуну.",
  Max_Amount_Message:"Износ који сте навели је достигао максималан износ који је постављен за овај систем:",
  Terms_Required_Word:"Морате се сложити са овим уговором пре него што наставите да користите чекове.",
  Subscriptions:"Претплате",
  Product_Upgrades:"Надоградње",
  Mailed_Out_Cheques:"Чекови послати поштом",
  Enter_A_Company_Name:"Унесите назив компаније.",
  Single_Cheques:"Појединачне провере",
  Cheque_Golden:"Златни чек",
  Cheque_Golden_Window:"Дизајн златних карика.",
  Cheque_Green:"Греен Цхецк",
  Cheque_Green_Window:"Дизајн зелених карата.",
  Cheque_Red:"Црвени чек",
  Cheque_Red_Window:"Дизајн црвеног карика.",
  Cheque_Yellow:"Иеллов Цхецк",
  Cheque_Yellow_Window:"Дизајн жуте карике.",
  Cheque_Statue_of_Liberty:"Кип Слободе",
  Cheque_Statue_of_Liberty_Window:"Дизајн провјере Кипа слободе.",
  Cheque_Green_Wave:"Греен Ваве",
  Cheque_Green_Wave_Window:"Дизајн зелених карата.",
  Cheque_Golden_Weave:"Голден Веаве",
  Cheque_Golden_Weave_Window:"Елегантан дизајн златне каре.",
  Cheque_Green_Sun:"Греен Сун",
  Cheque_Green_Sun_Window:"Дубоки и смирујући дизајн чекова.",
  Cheque_Blue_Checkers:"Блуе Цхецкерс",
  Cheque_Blue_Checkers_Window:"Дизајн плаве карике.",
  Cashiers_Check:"Благајнички чек",
  Cashiers_Check_Window:"Шаблон стила благајничког чека.",
  Cheque_Aqua_Checkers:"Акуа Цхецкерс",
  Cheque_Aqua_Checkers_Window:"Акуа цхецк дизајн.",
  Cheque_Golden_Checkers:"Златне даме",
  Cheque_Golden_Checkers_Window:"Дизајн златних карика.",
  Upgrade_Unavailable:"Надоградње недоступно",
  Bank_Code_Protection:"Заштита броја банке",
  Bank_Code_Protection_Word:"Заштитите своје банковне бројеве од коришћења у овој апликацији која ради на другом уређају или за другог корисника. Ова акција је НЕПОВРАТНА. Настави?",
  Bank_Code_Protection_Blocked_Word:"Банковни бројеви које покушавате да користите резервисани су за другог корисника или уређај.",
  Bank_Code_Protection_Error_Word:"Верификација броја није успела. Повежите се на интернет и покушајте поново да додате овај банковни рачун.",
  Bank_Code_Protection_Set_Error_Word:"Заштита броја банке захтева да будете повезани на интернет. Повежите се и покушајте поново.",
  Upgrade_Unavailable_Word:"Жао нам је, имамо проблема да вас верификујемо. Претплате и надоградње на Чекове тренутно нису доступне за куповину у вашој области.",
  PayPal_Payment_Preview:"ПаиПал преглед плаћања",
  Apple_Pay:"Аппле Паи",
  Select_PayPal:"Изаберите ПаиПал",
  PayPal_Applications:"ПаиПал апликације",
  Purchase_With_Apple_Pay:"Купујте са Аппле Паи-ом",
  Google_Pay:"Гоогле Паи",
  Companies:"Компаније",
  Insurance:"Осигурање",
  New_PayPal:"Нови ПаиПал",
  Pay_By:"Платити",
  Insure:"Осигурати",
  Miles:"Милес",
  Payment_Method:"Начин плаћања",
  Select_Policies:"Изаберите Смернице",
  Policies:"Политике",
  Policy:"Полици",
  No_PayPal_Account:"Нема ПаиПал налога",
  No_Policies:"Нема полиса осигурања",
  New_Policy:"Нова политика",
  PayPal_Payment:"ПаиПал Паимент",
  PayPal_Payments:"ПаиПал плаћања",
  No_Payment_Selected:"Није изабрано плаћање",
  Sending_Payment_Word:"Молимо сачекајте... Ова уплата се шаље.",
  Sending_Payments_Word:"Сачекајте... Уплате се шаљу.",
  No_Payment_Selected_PayPal:"Није изабрано <а routerLink='/folder/Payments'>ПаиПал плаћање</а> за слање.",
  Payment_Sent:"Плаћање је послато",
  PayPal_Payment_Sent:"Ова уплата је послата преко ПаиПал-а.",
  Copay:"Цопаи",
  Dead:"Мртав",
  Alive:"Жив",
  Not_Dead:"Није мртав",
  Unclaimed:"Незатражено",
  Attempted:"Покушај",
  Deceased:"Покојни",
  Claimed:"Захтевано",
  Unpaid:"Неплаћено",
  Sending_Payment:"Слање плаћања",
  Sending_Payments:"Слање плаћања",
  Send_PayPal_Confirmation:"Да ли желите да пошаљете ову трансакцију преко ПаиПал-а?",
  Send_PayPal_Confirmation_Word:"Притисните зелено дугме да пошаљете ову трансакцију.",
  Save_Payment_As_Unpaid:"Сачувати ову уплату као неплаћену?",
  Payment_Pay_Confirmation_PayPal:"Сачувати ову уплату као плаћену?",
  No_Policies_Word:"додајте прву <а routerLink='/folder/Postage/New'>полису осигурања</а>.",
  Timesheet_Multiple_Delete_Confirmation:"Да ли сте сигурни да желите да избришете више распореда?",
  Select_Multiple_Timesheets_Prompt:"Није изабран ниједан распоред. Изаберите најмање један распоред.",
  Select_Multiple_Policies_Prompt:"Нема изабраних смерница. Изаберите најмање једну полису осигурања.",
  Policies_Multiple_Delete_Confirmation:"Да ли сте сигурни да желите да избришете више смерница?",
  Company:"Компанија",
  Add_Company:"Додај компанију",
  New_Company:"Додај компанију",
  No_Companies:"Нема компанија",
  Enable_Company:"Омогући компанију",
  Select_Company:"Изаберите компанију",
  The_Default_Company:"Подразумевана ознака компаније.",
  Manage_Companies:"Управљајте компанијама",
  No_Companies_Word:"Провере ће користити подразумевану компанију.<br />Додајте <а routerLink='/folder/Company/New'> прво предузеће</а>.",
  Default_Company:"Дефаулт Цомпани",
  Cheques_Unlimited_Word:"Цхецкс Унлимитед вам омогућава да одштампате онолико чекова колико желите.",
  Cheques_Subscription_Word:"Претплата на чекове вам омогућава да одштампате онолико чекова колико желите.",
  You_Own_This_Product:"Ви сте власник овог производа.",
  Your_Subscription_is_Active:"Ваша претплата је активна.",
  Active_Products:"Активирани производи",
  Purchase_Confirmation:"Куповина",
  Purchase_Cheques:"Куповина чекова",
  Restore_Purchase:"Ресторе Пурцхасес",
  Erase_Purchase:"Избришите куповине",
  Successfully_Purchased:"Успешно купљено",
  Enter_Your_Licence_Key:"Унесите свој лиценцни кључ на овој страници да бисте активирали овај производ.",
  Licence_Key:"Лиценцни кључ",
  Enter_Licence_Key:"Унесите лиценцни кључ",
  Purchased:"Купио",
  Enable_Feature:"Омогући функцију",
  Cancel_Subscription:"Отказати претплату",
  Subscription_Removed:"Претплата је уклоњена",
  Select_Active_Subscription:"Изаберите активну претплату да бисте је изменили.",
  Remove_Subscription_Word:"Да ли сте сигурни да желите да откажете ову претплату?",
  Delete_Company_Confirmation:"Да ли сте сигурни да желите да избришете целу ову компанију? УПОЗОРЕЊЕ: Изгубићете све сачуване информације!",
  Delete_Default_Company_Word:"Не можете избрисати подразумевану компанију. Да ли желите да ресетујете апликацију и вратите је у подразумевано стање? УПОЗОРЕЊЕ: Изгубићете све сачуване информације!",
  Console_Warning_2:"Не рукујте ниједном валутом користећи ову апликацију која тренутно није ваша.",
  Terms_and_Conditions:"Услови",
  and_the:"анд тхе",
  for:"за",
  Please_Read_Word:"Молимо прочитајте и сложите се са",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Неки курсеви конверзије валута нису пронађени. Повежите се на интернет.",
  Free:"бесплатно",
  DB_Erase_Prompt_2:"Потпуно избрисати целу базу података програмера? УПОЗОРЕЊЕ: Изгубит ћете све информације о КУПОВИНИ и ПРЕТПЛАТИ!",
  Successfully_Imported:"Успешно увезено",
  Select_Postage:"Изаберите Поштарина",
  No_Postage:"Нема поштанских маркица",
  No_Paid_Postage_Word:"Додајте прву марку <а routerLink='/folder/Postage/New'>Плаћена поштарина</а>.",
  Trial_Complete:'Триал Цомплете',
  Please_Upgrade:'Надоградите Чекове да бисте наставили са штампањем.',
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ПЛАТИ ПО НАРУЏБИНУ:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  NO_DOLLARS:"БЕЗ ДОЛАРА ",
  ONE_DOLLAR:"ЈЕДАН ДОЛАР",
  DOLLARS:"ДОЛАРИ",
  AND:" И ",
  CENTS:"ЦЕНТС.",
  DOLLARS_AND_ONE_CENT:"И ЈЕДАН ЦЕНТ.",
  AND_NO_CENTS:"И НУЛА ЦЕНТС.",
  CHEQUE_PRINT_DATE:"ДАТУМ:",
  CHEQUE_PRINT_MEMO:"БЕЛЕШКА:",
  CHEQUE_PRINT_SIGNATUREMP:"народни посланик",
  Initial_Tasks:"Почетни задаци",
  Inital_Setup:"Почетно подешавање",
  Welcome_To:"Добродошли у ",
  Welcome:"Добродошли",
  Swipe:"Превлачење",
  Intro_Setup:"Уводно подешавање",
  Introduction:"Увод",
  CHEQUE_PRINT_CREDIT:"Покреће се чековима",
  Title_Intro_Word:"Добро дошли у чекове",
  Title_Intro:"Увод у чекове",
  Title_Setup:"Подешавање чекова",
  PayPal_Default_Email_Message:"Примили сте нови ПаиПал трансфер. [Покреће се чековима]",
  Cheques_App_Export:"Извезено чековима",
  Post_Intro_Thanks:"Хвала вам што сте изабрали Чекове. Процес подешавања је сада завршен.",
  Post_Intro_Word:"Започните тако што ћете одштампати свој први чек, додати будућу уплату или додати запослене на платни списак.",
  Upgrade_Required:"Чекови захтевају да поседујете премијум верзију софтвера да бисте сакрили ову поруку и откључали додатне функције.",
  Upgrade_Title:"чекови",
  Mailout_Prompt:"Можете и да одаберете да вам Чекови шаљу ваше чекове платног списка поштом.",
  Mailed_Cheque:"Чек послат поштом: ",
  Mailed_Cheque_Color:"Чек послат поштом (боја): ",
  Terms_Purchase:"Све електронске куповине са чековима се у потпуности враћају у року од 30 дана од датума куповине. Молимо прочитајте и прихватите <а routerLink='#'>Услове и одредбе</а> пре него што наставите.",
  Dashboard_Setup:"Подешавање примарног штампача",
  Dashboard_Add:"Додај примарни банковни рачун",
  Dashboard_Customize:"Изаберите шаблон за проверу",
  Dashboard_Job_Salary:"Отворите свој посао и додајте своју плату",
  Dashboard_Employees:"Прати запослене и примаоце плаћања",
  Dashboard_Print:"Одштампајте и пошаљите своје плате",
  Dashboard_Payroll:"Аутоматизујте штампање платног списка",
  Dashboard_PayPal:"Подешавање ПаиПал платног списка/исплате",
  Begin_Setup:"Започни подешавање",
  Get_Started:"Почети",
  Purchase:"Куповина",
  Lockdown:"Изолација",
  Unlock:"Откључај",
  Detailed:"Детаљно",
  Log_In:"Пријавите се",
  Login:"Пријавите се",
  Launch:"Лансирање",
  Register:"Регистровати",
  Finish:"Заврши",
  List:"Листа",
  Weekends:"Викенди",
  Weekly:"Недељник",
  PayPal_Default_Subject:"Ново плаћање",
  Payment_Message:"Порука о плаћању",
  PayPal_Default_Payment_Note:"Хвала вам",
  Setup_Your_Cheqing_Account:"Чековни рачун",
  Add_Your_Primary_Cheqing_Account:"Додајте свој примарни рачун за чекање.",
  Welcome_Word:"Поједноставите и аутоматизујте платни списак и управљање људским ресурсима.",
  Get_Started_Quickly:"Само одговорите на неколико једноставних питања која ће нам помоћи да почнемо...",
  Done_Intro_Word:"Подешавање је завршено",
  PIN_Protected:"Заштићено лозинком и ПИН-ом",
  Enter_New_PIN:"Унесите нови ПИН код:",
  Enter_PIN:"Унесите ПИН код:",
  Invalid_PIN:"Унесен је неважећи ПИН.",
  Account_Identifier:"Идентификатор налога",
  New_Account_Identifier:"Нови идентификатор налога",
  attempt:"покушај",
  attempts:"покушаји",
  remaining:"преостало",
  Language:"Језик",
  languages:"Језици",
  select_languages:"Изабери језик",
  Deposit:"Депозит",
  Hire_One_Now:"Унајмите једног сада",
  App_Locked:"Апликација је закључана.",
  Skip_:"Прескочи",
  Skip_to_Dashboard:"Прескочи на контролну таблу",
  Dashboard:"Командна табла",
  Import:"Увоз",
  Admin:"Администратори",
  New_Admin:"Нови администратор",
  Settings:"Подешавања",
  Color_Picker:"Бирач боја",
  Font_Picker:"Бирач фонтова",
  Logout:"Одјавити се",
  Close:"Близу",
  Close_menu:"Близу",
  Excel:"Екцел датотека",
  Csv:"ЦСВ датотека",
  Sql:"СКЛ датотека",
  Json:"ЈСОН датотека",
  Url:"Увези по УРЛ-у",
  Back:"Назад",
  Timers:"Тајмери",
  Cheque:"Проверавати",
  Print:"Штампање",
  Designs:"Дизајни",
  Pause_Printing:"Паузирај штампање",
  Resume_Printing:"Настави штампање",
  Printing_Paused:"Штампање је паузирано",
  PrintingUnavailable:"Извините! Штампање није доступно на овом систему.",
  Prints_Paused:"Отисци су паузирани",
  Administration:"Администрација",
  Loading:"Учитавање",
  Unnamed:"Неименовани",
  error:"Жао нам је, овај чек није могуће отворити за преглед.",
  No_Cheques_To_Print:"Нема чекова за штампање",
  No_Cheques_To_Print_Word:"Креирајте <а routerLink='/folder/Cheque/New'>Нови чек</а>.",
  New_Cheque:"Нови чек",
  Start_One_Now:"Започни један сада",
  Edit_Cheque:"Измени чек",
  Select_Cheques:"Изаберите чекове",
  Select_Employee:"Изаберите запосленог",
  Default_Printer:"Подразумевани штампач",
  Reassign:"Предодели",
  Configure:"Конфигуриши",
  Template:"Шаблон",
  Designer:"Дизајнер",
  Edit_Designs:"Уреди дизајне",
  New_Design:"Нови дизајн",
  Next:"Следећи",
  Save:"Сачувати",
  Name:"Име",
  Mail:"Пошта",
  Amount:"Износ",
  Date:"Датум",
  PayPal:"ПаиПал",
  Payouts:"исплате",
  PayPal_Label:"ПаиПал Лабел",
  Email_Username:"Емаил / Корисничко име",
  Client_ID:"ИД клијента",
  Sandbox_Email:"Сандбок Емаил",
  PayPal_Email:"ПаиПал е-пошта",
  PayPal_Username:"Корисничко име за АПИ",
  PayPal_Payouts:"ПаиПал исплате",
  Select_PayPal_Key:"Изаберите ПаиПал кључ",
  Secret:"Тајна",
  API_Secret:"Тајна АПИ-ја",
  PayPal_API_Keys:"ПаиПал кључеви",
  New_PayPal_Key:"Нови ПаиПал кључ",
  Email_Subject:"Тема е-поште",
  Email_Message:"Е-порука",
  Payout_Options:"Опције исплате",
  Payment_Note:"Напомена о уплати",
  Enable_Employee:"Омогући запосленог",
  Enable_Production_Mode:"Омогући производни режим",
  Sandbox_Username:"Корисничко име у заштићеном окружењу",
  Sandbox_Signature:"Сандбок Сигнатуре",
  Sandbox_Password:"Лозинка за заштићено окружење",
  Production_Username:"Корисничко име за производњу",
  Production_Signature:"Производни потпис",
  Production_Password:"Производна лозинка",
  Production_Email:"Производна е-пошта",
  API_Client_ID:"ИД клијента за АПИ",
  API_Signature:"АПИ потпис",
  API_Password:"АПИ лозинка",
  API_Username:"Корисничко име за АПИ",
  Secret_Key:"Тајни кључ",
  Sandbox:"Пешчаник",
  Production:"Производња",
  Sandbox_Keys:"Кључеви за песак",
  Production_Keys:"Производни кључеви",
  API_Title:"Наслов АПИ-ја",
  Production_Mode:"Производни режим",
  Account_Label:"Ознака налога",
  No_PayPal_Setup:"Нема ПаиПал кључа",
  Enable_PayPal_Account:"Омогући ПаиПал налог",
  No_PayPal_Word:"Додајте свој <а routerLink='/folder/Invoice/New'>ПаиПал АПИ кључ</а>.",
  Printed_Memo:"Штампана белешка",
  Employee:"Запослени",
  View_Employee:"Прикажи запосленог",
  Mailing_Address:"Адреса",
  Company_Address:"Адреса предузећа",
  Select_Company_Address:"Изаберите адресу компаније",
  Address:"Адреса",
  Any_Day:"Било који дан",
  Address_Name:"Назив адресе",
  Unit:"Јединица",
  Account:"Рачун",
  Bank_Account:"Рачун у банци",
  Account_Limits:"Омогући ограничења налога",
  Payroll:"платни списак",
  New_Payroll:"Нови платни списак",
  No_Payroll:"Нема предстојећих платних спискова",
  Upcoming_Holiday:"Предстојећи празник:",
  Invoice_Due:"Доспјела фактура:",
  New_Invoice:"Нова фактура",
  No_Invoices:"Без фактура",
  No_Invoices_Word:"Креирајте прву <а routerLink='/folder/Invoice/New'>фактуру</а>.",
  Cheque_Due:"Доспјели чек:",
  Payrolls:"платне листе",
  Sandbox_Mode:"Сандуче",
  Hire:"Најам",
  Pay:"Плати",
  New:"Нова",
  Add:"Додати",
  Make:"Направити",
  Time:"Време",
  Write:"Пиши",
  Holiday:"Празник",
  Holidays:"Празници",
  Next_Holiday:"Следећи одмор:",
  All_Done:"Завршено!",
  Employees:"Запослени",
  Payees:"Примаоци плаћања",
  Job:"посао",
  Jobs:"Послови",
  Invoice:"Фактура",
  Invoices:"Фактуре",
  Vacations:"Одмор",
  Cheques:"Чекови",
  Brand_Cheques:"Марка",
  Payment:"Плаћање",
  Enable_Payment:"Омогући плаћање",
  Payments:"Плаћања",
  Schedule:"Распоред",
  Schedules:"Распоред",
  Timesheet:"Распоред",
  Timesheets:"Временски листови",
  Salary:"Плата",
  New_Address:"Нова адреса",
  Address_2:"Адреса 2)",
  _City:"Град",
  _State:"Држава/Пров",
  City:"Град/Општина",
  State:"Држава / Покрајина",
  Postal:"Поштански / поштански број",
  ZIP:"Поштански / ЗИП",
  Country:"земља",
  Addresses:"Адресе",
  Required_Options:"Обавезне опције",
  Optional_Options:"Опционе опције",
  Additional_Options:"Додатне опције",
  Required:"Потребан",
  Optional:"Опционо",
  Additional:"Додатни",
  No_Addresses:"Нема адреса",
  New_Address_Word:"Додај прву <а routerLink='/folder/Address/New'>адресу</а>.",
  Select_Address:"Изабери адресу",
  No_Address:"Нема адреса",
  Print_Cheque:"Принт Цхецк",
  Print_Cheque_Now:"Одштампај провери сада",
  Description:"Опис",
  Pay_To_The_Order_Of:"Плати по налогу:",
  Select_Date_Range:"Изаберите период",
  Select_Starting_Date:"Изаберите датум почетка",
  Select_Ending_Date:"Изаберите датум завршетка",
  Select_Date:"Изаберите датум",
  Cheque_Date:"Датум провере",
  Cheque_Memo:"Провери белешку",
  Blank_Cheque:"Бланко чек",
  Blank:"Празно",
  No_Cheques:"Без чекова",
  No_Cheques_Word:"Одштампајте свој први <а routerLink='/folder/Cheque/New'>чек</а>.",
  Cheque_Amount_Placeholder:"0,00 долара",
  View_Image:"Прикажи слику",
  View:"Поглед",
  Modify:"Модификовати",
  Delete:"Избриши",
  Cheque_Paid:"Плаћено",
  New_Deduction:"Нови одбитак",
  Title:"Наслов",
  Frequency:"Фреквенција",
  Hourly:"По сату",
  Daily:"Дневно",
  Weekdays:"радним данима",
  BiWeekly:"2 недеље",
  TriWeekly:"3 недеље",
  Monthly:"месечно",
  MiMonthly:"2 месеца",
  Quarterly:"Квартални",
  Yearly:"Годишње",
  Every_Week:"Сваке недеље",
  Every_Payroll:"Сваки платни списак",
  Every_Month:"Сваког месеца",
  Annually:"Сваке године",
  Always_Scheduled:"Увек заказано",
  Start_Date:"Датум почетка",
  End_Date:"Крајњи датум",
  Start_Time:"Почетно време",
  End_Time:"Време завршетка",
  Deduction_Label:"Ознака одбитка",
  Notes:"Белешке",
  Options:"Опције",
  Enable:"Омогући",
  Select_Deductions:"Изаберите одбитке",
  Deductions:"Одбици",
  No_Deductions:"Без одбитака",
  No_Deductions_Word:"Креирајте свој први <а routerLink='/folder/Deduction/New'>Дедуцтион</а>.",
  New_Employee:"Нови запослени",
  No_Title:"Без наслова",
  _Name:"Име",
  About_Yourself:"О себи",
  Full_Name:"Пуно име",
  Birthday:"Рођендан",
  Email:"Е-пошта",
  SMS:"СМС",
  Phone:"телефон",
  Test:"Тест",
  Call:"Позови",
  Fax:"факс",
  Printed_Note:"Штампана белешка",
  Position:"Позиција",
  Job_Position:"Радно место",
  Select_a_Job:"Изабери посао",
  Select_a_Salary:"Изаберите плату",
  Add_a_Deduction:"Додај одбитак",
  Taxes:"Порези",
  Add_Taxes:"Додај порезе",
  Date_of_Birth:"Датум рођења",
  Email_Address:"Адреса Е-поште",
  Phone_Number:"Број телефона",
  Phone_Call:"Телефонски позив",
  Enable_on_Payroll:"Омогући на платном списку",
  Select_Employees:"Изаберите запослене",
  No_Employees:"Нема запослених",
  No_Employees_Word:"Додајте свог првог новог <а routerLink='/folder/Employee/New'>Емплоиее</а>.",
  No_Name:"Без имена",
  Unemployeed:"Незапослен",
  Employeed:"Запослен",
  Paid:"Плаћено",
  Enabled:"Омогућено",
  Disabled:"Онемогућено",
  Fire:"Ватра",
  Not_Available:"Није доступно",
  Not_Available_Word:"Одштампајте своју прву <а routerLink='/folder/Invoice/New'>фактуру</а> и платите.",
  Select_Invoices:"Изабери_фактуре",
  Print_Invoice_Word:"Одштампајте своју прву <а routerLink='/folder/Invoice/New'>фактуру</а> и платите.",
  Invoice_Title:"Наслов фактуре",
  Invoice_Date:"Датум фактуре",
  Due_Date:"Рок предаје",
  New_Job:"Нови посао",
  Details:"Детаљи",
  Customize:"Прилагоди",
  Customize_Dashboard:"Прилагоди контролну таблу",
  Components:"Компоненте",
  No_Components:"Без компоненти",
  Main_Components:"Главне компоненте",
  Smaller_Components:"Мање компоненте",
  Error_Loading_Page:"Грешка при учитавању ове странице.",
  Bank_Details:"Банковног",
  About_Your_Job:"О твом послу",
  Your_Schedule:"Твој распоред",
  Job_Title:"Звање",
  Job_Description:"Опис посла",
  Job_Details:"Детаљи посла",
  Enable_Job:"Омогући посао",
  Pay_Period:"Период за плаћање",
  Perpetually_Schedule:"Непрекидни распоред",
  Select_Jobs:"Изаберите послове",
  No_Jobs:"Нема послова",
  Add_Jobs:"Додај послове",
  No_Jobs_Word:"Додајте први <а routerLink='/folder/Job/New'>Посао</а> на листу.",
  Count_Employees:"јоб.емплоиее_цоунт+' Запослени'",
  Zero_Employees:"0 запослених",
  New_Leave:"Нови одлазак",
  Leave_Name:"Напусти име",
  Paid_Leave:"Плаћено одсуство",
  Leave_Pay:"Оставите плату",
  Indefinite_Leave:"Неограничено одсуство",
  Indefinite_Payroll:"Неограничено платни списак",
  Leave:"Одлази",
  Add_Schedules:"Додај распореде",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Омогући одсуство",
  Select_Leaves:"Одабери лишће",
  No_Leaves:"Без одсуства",
  Leave_Of_Absence:"Одсуство",
  Leaves_Of_Absence:"Остави одсуства",
  New_Leave_of_Absense:"Ново одсуство",
  No_Leaves_Word:"Додај први <а routerLink='/folder/Leave/New'>одсуство</а>.",
  Not_Enabled:"Није омогућено",
  Absences:"Одсуства",
  Payee:"Прималац уплате",
  New_Payee:"Нови прималац",
  Payee_Identifier:"Идентификатор примаоца уплате",
  Payee_Name:"Име примаоца уплате",
  Payee_Title:"Наслов примаоца уплате",
  Payment_Memo:"Податак о плаћању",
  Select_Payees:"Изаберите примаоце плаћања",
  No_Payees:"Без прималаца плаћања",
  Add_Payee_Note:"Додајте првог <а routerLink='/folder/Payee/New'>Приматеља</а>.",
  New_Payees:"Нови примаоци плаћања",
  About_The_Payment_Schedule:"Динамика плаћања",
  Your_Payroll_Schedule:"Аутоматски обрачун зарада",
  New_Payment:"Ново плаћање",
  Identifier:"Идентификатор",
  Select:"Изабери",
  Memo:"Белешка",
  Payment_Date:"Датум исплате",
  Mark_as_Paid:"Означи као плаћено",
  Select_Payments:"Изаберите плаћања",
  No_Payments:"Без плаћања",
  No_Payments_Word:"Креирајте прву <а routerLink='/folder/Payments/New'>уплату</а>.",
  Create_Payroll:"Креирај платни списак",
  Properties:"Својства",
  Payroll_Title:"Назив платног списка",
  Payroll_Notes:"Белешке о платним списковима",
  Payroll_Setup:"Подешавање платног списка",
  Tabulate_Payments:"Табела плаћања",
  Tabulate:"Табела",
  By:"Од стране:",
  Payments_By:"Плаћања до",
  Timesheets_And_Schedules:"Временски листови и распореди",
  Both:"И једно и друго",
  Items:"Предмети",
  Add_Payees:"Додај примаоце плаћања",
  Add_Account:"Додај налог",
  Enable_Account:"Омогући налог",
  Enable_Payroll:"Омогући обрачун зарада",
  Print_Payroll:"Одштампај платни списак",
  No_Payrolls:"Без платног списка",
  No_Payroll_Word:"Креирајте свој први <а routerLink='/folder/Payroll/New'>Паиролл</а>.",
  View_more:"ПОГЛЕДАЈ ЈОШ",
  Less:"МАЊЕ",
  Add_Payroll:"Додај платни списак",
  Select_Payroll:"Изабери платни списак",
  About_Your_Salary:"О твојој плати",
  Add_Salaries:"Додај плате",
  Add_Salary:"Додај плату",
  Salaries:"Плате",
  No_Salaries:"Без плата",
  No_Salaries_Word:"Додај прву <а routerLink='/folder/Salary/New'>Плату</а>.",
  Select_Salaries:"Изаберите плате",
  New_Salary:"Нова плата",
  Salary_Name:"Идентификатор плате",
  Enable_Salary:"Омогући плату",
  Salary_Amount:"Износ плате",
  New_Schedule:"Нови распоред",
  Schedule_Title:"Наслов распореда",
  Add_Address:"Додај адресу",
  Repeat:"Понављање",
  Design:"Дизајн",
  Edit_Design:"Уреди дизајн",
  Edit_this_Design:"Уреди овај дизајн",
  Repeat_Payment:"Поновно плаћање",
  Enable_Schedule:"Омогући распоред",
  Never:"Никад",
  Select_Schedule:"Изаберите распореде",
  No_Schedules:"Без распореда",
  No_Schedules_Word:"Креирајте први <а routerLink='/folder/Schedule/New'>распоред</а>.",
  New_Administrator:"Нови администратор",
  Username:"Корисничко име",
  First_Name:"Име",
  Last_Name:"Презиме",
  Add_an_Address:"Додај адресу",
  Payment_Limit:"Ограничење по плаћању",
  Total_Limit:"Тотал Лимит",
  Select_Accounts:"Изабери налоге",
  No_Administrators:"Без администратора",
  No_Administrators_Word:"Креирајте први <а routerLink='/folder/Administrator/New'>администраторски налог</а>.",
  New_Administrators_Word:"Додај првог <а routerLink='/folder/Administrator/New'>Администратора</а>",
  Cloud:"Облак",
  Backup:"Резервна копија",
  Enable_iCloud:"Омогући иЦлоуд",
  Enable_Google_Drive:"Омогући Гоогле диск",
  Enable_Microsoft_OneDrive:"Омогући Мицрософт ОнеДриве",
  Automatically_Backup:"Аутоматски резервна копија",
  FTP_Settings:"ФТП подешавања",
  URL_File_Prompt:"Молимо наведите локацију за .клс / .клск / .јсон датотеку за увоз:",
  URL_SQL_Prompt:"Молимо наведите локацију СКЛите датотеке за увоз:",
  FTP_Backup:"ФТП резервна копија",
  FTP_Import:"ФТП увоз",
  FTP:"ФТП",
  SFTP:"СФТП",
  Host:"Домаћин",
  Port:"Лука",
  Path:"Пут",
  Data_Path:"Пут података",
  Enable_FTP_Account:"Омогући ФТП налог",
  HostnameIP:"име домаћина",
  Password:"Лозинка",
  Connection_Port:"Повезни прикључак",
  Enable_MySQL_Database:"Омогући МиСКЛ базу података",
  Log:"Пријава",
  Reset:"Ресетовати",
  Erase:"Избриши",
  Export:"Извоз",
  Database:"База података",
  Upload_CSV:"Отпреми ЦСВ",
  Download_CSV:"Преузми ЦСВ",
  SQL_Database:"СКЛ УРЛ",
  MySQL_Database:"МиСКЛ",
  MySQL_Backup:"МиСКЛ резервна копија",
  Internal_Notes:"Интерне белешке",
  Root_Path:"Коренски пут",
  Select_Backup:"Изабери резервну копију",
  Add_New_Backup:"Додај нову резервну копију",
  First_Backup:"Подесите прву резервну копију...",
  Backups:"Резервне копије",
  Add_Backup:"Додај резервну копију",
  No_Backups:"Нема резервних копија које треба пронаћи.",
  Select_Backup_Type:"Изаберите врсту резервне копије коју желите да подесите...",
  Backup_Type:"Врста резервне копије",
  FTP_Drive:"ФТП диск",
  URL_Location:"УРЛ",
  Apple_iCloud:"иЦлоуд",
  Google_Drive:"Погон",
  Microsoft_OneDrive:"Погон",
  Import_Fields:"Поља за увоз",
  Import_Fields_Word:"Користите овај одељак да изаберете <а routerLink='/folder/Import/New'>Увези</а> податке.",
  Upload:"Отпремити",
  Download:"Преузимање",
  Download_JSON:"Преузми као ЈСОН податке",
  Download_SQL:"Преузми као СКЛ датотеку",
  New_Bank:"Нова банка",
  New_Account:"Нови налог",
  New_Bank_Account:"Нови банковни рачун",
  Upload_Image:"Учитај слику",
  Bank_Name:"Име банке",
  Bank_Address:"Адреса банке",
  Branch_Address:"Грана адреса",
  Address_on_Cheque:"Адреса",
  Cheque_Numbers:"Проверите бројеве",
  Cheque_Details:"Провери детаље",
  Bank_Logo:"Логотип банке",
  Cheque_ID:"Провери ИД",
  Starting_Cheque_ID:"ИД почетне провере",
  Transit_Number:"Транзитни број",
  Institution_Number:"број институције",
  Designation_Number:"Означни број",
  Account_Number:"Број рачуна",
  Limits:"Границе",
  Default_Limits:"Подразумеване границе",
  Over_Limit:"Преко лимита",
  Under_Limit:"испод ограничења",
  Payroll_Limit:"Ограничење платног списка",
  Enable_Bank_Account:"Омогући банковни рачун",
  Select_Account:"Изабери налог",
  No_Bank_Account:"Нема банковног рачуна",
  No_Bank_Account_Word:"Додајте први <а routerLink='/folder/Accounts/New'>банковни рачун</а>.",
  Bank_Accounts:"Банкарски рачуни",
  No_Accounts:"Нема налога",
  No_Accounts_Note:"Додајте први <а routerLink='/folder/Accounts/New'>банковни рачун</а>.",
  Cheque_Designer:"Провери дизајнер",
  Cheque_Design:"Провери дизајн",
  Select_Design:"Изаберите дизајн",
  Cheque_Designs:"Провери дизајн",
  No_Cheque_Designs:"Дизајн без провера",
  No_Cheque_Designs_Word:"Креирајте свој <а routerLink='/folder/Settings/Cheque/Design/New'>Провери дизајн</а>.",
  Set_Default:"Фабрички подешен",
  Default:"Уобичајено",
  Remove:"Уклони",
  Folder:"Фолдер",
  Edit:"Уредити",
  LoadingDots:"Учитавам...",
  Add_a_New_File:"Додај <а routerLink='#' (click)='add()'>Нову датотеку</а> у",
  this_folder:"ова фасцикла",
  FTP_Backup_Settings:"Подешавања резервне копије ФТП-а",
  Secure_File_Transfer:"Безбедан пренос датотека",
  New_Holiday:"Нови празник",
  Add_Holiday:"Додај празник",
  Holiday_Name:"Име празника",
  Additional_Pay:"Додатна плата",
  Enable_Holiday:"Омогући одмор",
  Select_Holidays:"Изаберите празнике",
  No_Holidays:"Без празника",
  No_Holidays_Word:"Додај први <а routerLink='/folder/Holiday/New'>Празник</а>.",
  New_Import:"Нови увоз",
  Import_Data:"Увоз података",
  Import_Data_Word:"Изаберите тип датотеке или метод отпремања по вашем избору.<бр /> Моћи ћете да изаберете која год увезена поља у датотеци одговарају било ком параметру у апликацији након отпремања подржане датотеке.", 
  Import_File:"Увези датотеку",
  Link_To_File:"Веза до датотеке",
  Select_File:"Изаберите фајл",
  New_Moderator:"Нови модератор",
  Allow_Moderation:"Дозволи модерацију",
  Create_Paycheques:"Креирајте плате",
  Edit_Paycheques_and_Data:"Измени плате и податке",
  Destroy_Data_and_Paycheques:"Уништи податке и плате",
  Bonus_Percentage:"Проценат плате",
  Fixed_Amount:"Фиксни износ",
  Select_Moderator:"Изаберите модератора",
  No_Moderators:"Без модератора",
  Moderators:"Модератори",
  Moderator_Account:"Креирајте први <а routerLink='/folder/Administrator/New'>налог модератора</а>.",
  No_Moderators_Word:"Додајте првог <а routerLink='/folder/Administrator/New'>Модератора</а>.",
  Defaults:"Подразумеване вредности",
  Provide_Us_Info:"Пружи нам информације",
  Setup_Your_Printer:"Подесите свој штампач",
  Your_Company:"О вашој компанији",
  Company_Name:"Име компаније",
  Currency:"валута",
  Default_Currency:"Подразумевана валута",
  Base_Monthly_Income:"Месечни приход",
  Protection:"Заштита",
  App_Protection:"Заштита апликација",
  PIN_Code_Protection:"Заштита ПИН кода",
  App_Protection_Word:"Омогући безбедносне методе које помажу у заштити вашег налога.",
  PIN_Code:"Пин код",
  Change_PIN:"Промени ПИН",
  New_Password:"Нова лозинка",
  Geofence_Protection:"Заштита гео-ограде",
  Geofence_Area:"Подесите област",
  Distance:"Удаљеност",
  Setup_Now:"Подесите сада",
  Mile:"Миле",
  Km:"Км",
  m:"м",
  Facial_Recognition:"Препознавање лица",
  Face:"Лице",
  Setup:"Подесити",
  Label:"Етикета",
  Password_Protection:"Заштита лозинком",
  Modify_Password:"Измени лозинку",
  New_Tax_Entry:"Нови порески унос",
  New_Tax:"Нови порез",
  Tax_Label:"Пореска ознака",
  Perpetually_Enabled:"Трајно омогућено",
  Select_Taxes:"Изаберите порезе",
  Tax_Deductions:"Пореске олакшице",
  No_Tax_Deductions:"Без пореских олакшица",
  No_Tax_Deductions_Word:"Додајте први одбитак <а routerLink='/folder/Так/New'>пореза</а>.",
  New_Timer:"Нови тајмер",
  Start:"Почетак",
  Stop:"Зауставити",
  Start_Timer:"Старт тајмер",
  Stop_Timer:"Заустави тајмер",
  Timer_Active:"Тајмер активан",
  Timer:"Тајмер:",
  Timer_Running:"Тајмер: (у току)",
  Save_Timer:"Сачувај тајмер",
  New_Timesheet:"Нови радни лист",
  Select_Timesheets:"Изаберите временске табеле",
  Work_Notes:"Радне белешке",
  Entry_Title:"Наслов уноса",
  No_Timesheets:"Без распореда",
  No_Timesheets_Word:"Додајте први <а routerLink='/folder/Timesheet/New'>Тиме Схеет</а>.",
  Timesheet_Submitted:"Транспорт је достављен",
  Timesheet_Congrats:"Честитамо! Ваш временски лист је успешно послат. Хвала!",
  Timesheet_Copy:"Да бисте примили копију ваших докумената, доставите нам своју адресу е-поште и/или број мобилног телефона.",
  Submit:"Прихвати",
  Allow_Notifications:"Дозволи обавештења",
  Untitled_Entry:"Нови унос",
  Untitled_Bank:"Банка без назива",
  Timesheet_Entry:"Унос радног времена",
  Work_Description:"Опис рада",
  Enable_Timesheet:"Омогући радни лист",
  Submit_Timesheet:"Пошаљи временску тачку",
  Vacation:"Годишњи одмор",
  New_Vacation:"Нови одмор",
  Vacation_Name:"Име одмора",
  Paid_Vacation:"Плаћеног одмора",
  Vacation_Pay:"Плаћа за годишњи одмор",
  Enable_Vacation:"Омогући одмор",
  Select_Vacations:"Изаберите одморе",
  No_Vacations:"Без одмора",
  No_Vacations_Word:"Креирајте први унос <а routerLink='/folder/Vacation/New'>Одмор</а>.",
  Payroll_Schedule:"Распоред зарада",
  Next_Payroll:"Следећи обрачун зарада:",
  Upcoming_Payroll:"Предстојећи обрачун зарада",
  No_Upcoming_Payroll:"Нема предстојећих платних спискова",
  Address_Book:"Адресар",
  Archived_Documents:"Архивирани документи",
  Dev_Mode:"Апликација у развојном режиму",
  Administrators:"Администратори",
  Privacy:"Приватност",
  None:"Ниједан",
  Select_Signature:"Изабери потпис",
  No_Signatures:"Без потписа",
  No_Signatures_Word:"Додај први <а routerLink='/folder/Signature/New'>Потпис</а>.",
  Repeat_Indefinitely:"Неограничено понављање",
  Sign:"знак",
  Sign_Here:"Потпиши овде",
  Date_Signed:"Датум пријаве",
  Signature_Pad:"Пад за потпис",
  Account_Holder:"Власник рачуна",
  Account_Properties:"Својства налога",
  Name_On_Cheque:"Име на чеку",
  Server_Hostname:"Сервер Хостнаме/ИП",
  Printers:"Штампачи",
  No_Printers:"Без штампача",
  Printer_Exists:'Штампач са овим именом већ постоји.',
  No_Printers_Word:"Додајте први <а routerLink='/folder/Printer/New'>Штампач</а>.",
  No_Printer_Alert:"Није дефинисан ниједан штампач. Да ли желите да подесите штампач?",
  Add_Printer:"Додај штампач",
  New_Printer:"Нови штампач",
  Printer_Hostname:"Име хоста штампача/ИП",
  Printer_Label:"Етикета штампача",
  Printer_Protocol:"Протокол штампача",
  Protocol:"Протокол",
  Email_Print:"Е-пошта",
  AirPrint:"АирПринт",
  IPP:"ИПП",
  LPD:"ЛПД",
  HPJetDirect:"Утичница",
  Print_Job:"Посао за штампање",
  Printed:"Штампано",
  Not_Printed:"Није штампано",
  Print_Jobs:"Посао за штампање",
  Print_Queue:"Ред за штампање",
  No_Print_Jobs:"Нема послова за штампање",
  No_Prints:"Креирајте нови <а routerLink='/folder/Cheque/New'>чек</а> за штампање.",
  Prints:"Отисци",
  Find_Printer:"Пронађи штампач",
  Find_AirPrint_Devices:"Пронађи АирПринт уређаје",
  Select_Printer:"Изабери штампач",
  System_UI:"Кориснички интерфејс система",
  Printer:"Штампач",
  Status:"Статус",
  Preview:"Преглед",
  Enable_Print_Job:"Омогући задатак штампања",
  Queue_Weight:"Тежина у реду",
  Unlimited:"Неограничено",
  Show_Advanced_Printer_Options:"Прикажи напредне опције штампача",
  Advanced:"Напредни",
  Location:"Локација",
  Note:"Белешка",
  Queue_Name:"Име реда",
  Pages_Printed_Limit:"Ограничење броја штампаних страница",
  MultiPage_Idle_Time:"Време чекања на више страница (с)",
  Page_Count_Limit:"Ограничење броја страница",
  Page_Orientation:"Оријентација странице",
  Portrait:"Портрет",
  Landscape:"Пејзаж",
  Duplex:"дуплекс",
  Double_Sided_Printing:"Двострано",
  Duplex_Mode:"Дуплек Моде",
  Duplex_Short:"Кратак",
  Duplex_Long:"Дуго",
  Duplex_None:"Ниједан",
  Color_And_Quality:"Боја и квалитет",
  Monochrome:"Монохром",
  Photo_Quality_Prints:"Отисци квалитета фотографија",
  Printer_Email:"Е-пошта штампача",
  Automatically_Downsize:"Аутоматски смањивање",
  Paper:"папир",
  Paper_Name:"Назив папира",
  Paper_Width:"Ширина папира",
  Paper_Height:"Висина папира",
  Paper_Autocut_Length:"Дужина аутоматског сечења папира",
  Margins:"Маргине",
  Page_Margins:"Маргине странице",
  Page_Margin_Top:"Маргина на врху странице",
  Page_Margin_Right:"Десна маргина странице",
  Page_Margin_Bottom:"Доња маргина странице",
  Page_Margin_Left:"Маргина леве странице",
  Add_Employees:"Додај запослене",
  Header:"Заглавље",
  Print_A_Page_Header:"Одштампај заглавље странице",
  Header_Label:"Ознака заглавља",
  Header_Page_Index:"Индекс заглавља",
  Header_Font_Name:"Фонт заглавља",
  Select_Font:"Изабери фонт",
  Font_Selector:"Селектор фонтова",
  Header_Font_Size:"Фонт заглавља",
  Header_Bold:"Подебљано заглавље",
  Header_Italic:"Хеадер Италиц",
  Header_Alignment:"Поравнање заглавља",
  Left:"лево",
  Center:"Центар",
  Right:"Јел тако",
  Justified:"Оправдано",
  Header_Font_Color:"Боја заглавља",
  Select_Color:"Изабери боју",
  Footer:"Подножје",
  Print_A_Page_Footer:"Одштампај подножје странице",
  Footer_Label:"Ознака подножја",
  Footer_Page_Index:"Индекс странице у подножју",
  Footer_Font_Name:"Фонт подножја",
  Fonts:"Фонтови",
  Done:"Готово",
  Select_Fonts:"Изаберите фонтове",
  Footer_Font_Size:"Величина подножја",
  Footer_Bold:"Подножје подебљано",
  Footer_Italic:"Фоотер Италиц",
  Footer_Alignment:"Поравнање подножја",
  Footer_Font_Color:"Боја подножја",
  Page_Copies:"Копије страница",
  Enable_Printer:"Омогући штампач",
  Remote_Logging:"Даљинско евидентирање",
  Log_Server:"Лог сервер",
  Encryption:"Шифровање",
  Random_Key:"Случајни кључ",
  Encryption_Key:"Кључ за шифровање",
  Cheques_Webserver:"Прилагођена база података",
  Learn_How:"Научите како",
  Signature:"Потпис",
  Default_Printer_Unit:"ин/цм/мм/(пт)",
  View_Signature:"Прикажи потпис",
  Printed_Signature:"Штампани потпис",
  Digitally_Sign:"Дигитални знак",
  Digital_Signature:"Дигитални потпис",
  Add_Signature:"Додај потпис",
  Add_Your_Signature:"Додајте свој потпис",
  Enable_Signature:"Омогући потпис",
  Digitally_Signed:"Дигитално потписан",
  Insert_Error:"Није могуће сачувати чек због проблема са базом података.",
  Delete_Confirmation:"Да ли сте сигурни да желите да избришете ове информације?",
  Discard_Confirmation:"Да ли сте сигурни да желите да одбаците ове информације?",
  Discard_Bank_Confirmation:"Да ли сте сигурни да желите да одбаците овај налог?",
  Discard_Printer_Confirmation:"Да ли сте сигурни да желите да одбаците овај штампач?",
  Delete_Bonus_Confirmation:"Да ли сте сигурни да желите да избришете овај бонус?",
  Delete_Invoice_Confirmation:"Да ли сте сигурни да желите да избришете ову фактуру?",
  Generated_Cheque:"Генерисани чек",
  Generated_Invoice:"генерисана фактура",
  Schedule_Start_Time:"Почетак распореда",
  Schedule_End_Time:"Завршетак распореда",
  New_Call:"Нови позив",
  No_Contacts:"Нема контаката",
  No_Contacts_Word:"Администратори, модератори, запослени и примаоци плаћања се појављују као контакти.",
  PDF_Subject:"финансије",
  PDF_Keywords:"платни списак ПДФ чекови чекови",
  Printer_Setup:"Подешавање штампача",
  Printer_Selection:"Избор штампача",
  New_Fax:"Нови факс",
  New_Fax_Message:"Нова факс порука",
  Fax_Machine:"Факс машина",
  Fax_Name:"Име факса",
  Fax_Email:"Факс е-пошта",
  Fax_Number:"Број факса",
  Contents:"Садржај",
  Fax_Body:"Тело странице",
  Header_Word:"Заглавље:",
  Header_Text:"Текст заглавља",
  Include_Header:"Укључи заглавље",
  Include_Footer:"Укључи подножје",
  Footer_Word:"Подножје:",
  Footer_Text:"Текст у подножју",
  Attach_a_Cheque:"Приложи чек",
  Add_Deduction:"Додај одбитак",
  Enable_Fax:"Пошаљи факс",
  Select_Fax:"Изабери факс",
  No_Faxes:"Без факсова",
  Faxes:"факсови",
  Save_and_Send:"Пошаљи факс",
  Save_and_Pay:"Уштеди и плати",
  WARNING:"УПОЗОРЕЊЕ:",
  Remember:"Запамтити",
  Printing:"Штампање",
  Printing_Complete:"Штампање је завршено!",
  of:"од",
  There_Were:"Било ",
  Successful_Prints:"успешне отиске и",
  Unsuccessful_Prints:"неуспели отисци.",
  PrinterError:"Извините! Дошло је до грешке.",
  Printing_:"Штампање...",
  PrinterSuccess:"Документ је успешно одштампан.",
  PrintersSuccess:"Документи су успешно штампани.",
  Print_Cheques:"Штампање чекова",
  New_Message:"Нова порука",
  New_Messages:"Нове поруке",
  Read_Message:"Прочитај поруку",
  Write_Message:"Напиши поруку",
  Send_Message:"Пошаљи поруку",
  Subject:"Предмет",
  Message:"Порука",
  Writing:"Писање...",
  Send:"Пошаљи",
  Set_Date:"Подесити датум",
  Set_Time:"Одредити време",
  Recieve:"Прими",
  Set_as_Default:"Фабрички подешен",
  Default_Account:"Подразумевани налог",
  Default_Design:"Подразумевани дизајн",
  Multiple_Cheques:"Чекови (троструки)",
  Account_Mode:"Режим налога",
  Multiple_Cheques_Description:"Три чека по страници.",
  Check_and_Table:"Провери и сто",
  Check_including_Table:"Табела за проверу и рачуноводство.",
  Check_Mailer:"Провери мејлер",
  Check_Mailer_Window:"Проверите у прозору за адресу.",
  DocuGard_Table_Top:"ДоцуГард провера и сто (врх)",
  DocuGard_Table_Middle:"ДоцуГард провера и сто (средина)",
  DocuGard_Table_Bottom:"ДоцуГард провера и табела (доле)",
  DocuGard_Mailer_Top:"ДоцуГард Цхецк Маилер (Врх)",
  DocuGard_Mailer_Middle:"ДоцуГард Цхецк Маилер (средњи)",
  DocuGard_Mailer_Bottom:"ДоцуГард Цхецк Маилер (доле)",
  DocuGard_Three_Cheques:"ДоцуГард чекови (троструки)",
  DocuGard_Cheque_Top:"ДоцуГард Цхецк (врх)",
  DocuGard_Cheque_Middle:"ДоцуГард Цхецк (средњи)",
  DocuGard_Cheque_Bottom:"ДоцуГард провера (доле)",
  DocuGard_Three_Cheques_Window:"Три чека на једној страници.",
  DocuGard_Table_Top_Window:"Табела чекова и зараде.",
  DocuGard_Table_Middle_Window:"Табела чекова и зараде.",
  DocuGard_Table_Bottom_Window:"Табела чекова и зараде.",
  DocuGard_Mailer_Top_Window:"Чек, сто и адреса.",
  DocuGard_Mailer_Middle_Window:"Чек, сто и адреса.",
  DocuGard_Mailer_Bottom_Window:"Чек, сто и адреса.",
  DocuGard_Cheque_Top_Window:"Провери сигуран папир.",
  DocuGard_Cheque_Middle_Window:"Провери сигуран папир.",
  DocuGard_Cheque_Bottom_Window:"Провери сигуран папир.",
  Basic_Cheque:"Провери (врх)",
  Basic_Cheque_Print:"Одштампајте један чек.",
  Error_Setting_As_Paid:"Грешка при постављању као плаћено",
  Add_Attachment:"Додај прилог",
  PrinterUnavailable:"Штампач недоступан",
  CreditCardComponent:"Картице",
  PaypalComponent:"ПаиПал",
  InteracComponent:"Интерац",
  BitcoinComponent:"Битцоин",
  New_Deposit:"Нови депозит",
  Deposits:"Депозити",
  No_Deposits:"Без депозита",
  Credit_Card_Deposit:"Кредитна картица",
  New_Credit_Card_Deposit_Message:"Депозит кредитне картице",
  New_BitCoin_Deposit:"Битцоин",
  New_BitCoin_Deposit_Message:"Битцоин депозит",
  New_Interac_Deposit:"Интерац",
  New_Interac_Deposit_Message:"Интерац Трансфер",
  Payments_Limit:"Лимит плаћања",
  No_Payment_Limit:"Без ограничења плаћања",
  PayPal_Deposit:"ПаиПал",
  PayPal_Deposit_Message:"ПаиПал депозит",
  No_Deposits_Word:"Додајте прву компанију <а routerLink='/folder/Deposit/New'>Депозит</а>.",
  No_Documents_Specified:"Није одређен документ за штампање",
  No_Printers_Added:"Није пронађен ниједан штампач. Идите на Подешавања > Штампачи да бисте га додали.",
  DB_Erase_Prompt:"Потпуно обрисати целу базу података? УПОЗОРЕЊЕ: Изгубићете све сачуване информације!",
  Console_Warning:"Не лепите никакав текст у ову конзолу. Можете себе и/или своју компанију изложити озбиљном ризику.",
  No_Faxes_Word:"Креирајте први <а routerLink='/folder/Fax/New'>Факс</а>.",
  Cheque_Delete_Confirmation:"Да ли сте сигурни да желите да избришете овај чек?",
  Design_Delete_Confirmation:"Да ли сте сигурни да желите да избришете овај дизајн?",
  Cheque_Pay_Confirmation:"Означити овај чек као плаћен? Неће се појавити у реду за штампање.",
  Payment_Pay_Confirmation:"Означити ову уплату као плаћену? Неће се појавити у реду чекања.",
  Delete_Deduction_Confirmation:"Да ли сте сигурни да желите да избришете овај одбитак?",
  Delete_Job_Confirmation:"Да ли сте сигурни да желите да избришете овај посао?",
  Delete_Timesheet_Confirmation:"Да ли сте сигурни да желите да избришете овај распоред?",
  Delete_Schedule_Confirmation:"Да ли сте сигурни да желите да избришете овај распоред?",
  Delete_Setting_Confirmation:"Да ли сте сигурни да желите да ресетујете ово подешавање?",
  Delete_Fax_Confirmation:"Да ли сте сигурни да желите да избришете овај факс?",
  Delete_File_Confirmation:"Да ли сте сигурни да желите да избришете ову датотеку?",
  Delete_Vacation_Confirmation:"Да ли сте сигурни да желите да избришете овај одмор?",
  Delete_Printer_Confirmation:"Да ли сте сигурни да желите да избришете овај штампач?",
  Remove_Design_Confirmation:"Да ли сте сигурни да желите да избришете овај дизајн?",
  Delete_Payroll_Confirmation:"Да ли сте сигурни да желите да избришете овај платни списак?",
  Send_Fax_Email_Confirmation:"Желите ли да пошаљете факсом и е-поштом ове документе?",
  Send_Email_Confirmation:"Желите ли да пошаљете овај документ е-поштом?",
  Send_Fax_Confirmation:"Да ли желите да пошаљете овај документ факсом?",
  Error_Generating_PDF:"Извините. Дошло је до грешке при генерисању овог документа.",
  PDF_Error_Saving_Image:"Извините. Дошло је до грешке при чувању ПДФ слике овог документа.",
  Test_Printer_Confirmation:"Желите ли да одштампате пробну страницу на овом штампачу?",
  Save_Timesheet_Prompt:"Молимо додајте 'Наслов' или притисните 'Покрени тајмер' да бисте сачували.",
  Remove_Geofence_Prompt:"Да ли сте сигурни да желите да уклоните локацију ове гео-ограде?",
  Delete_Employee_Confirmation:"Да ли сте сигурни да желите да избришете ",
  Fire_Employee_Confirmation:"Да ли сте сигурни да желите да пуцате"
}