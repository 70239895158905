import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
import { WebService } from '../../services/web.service';
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  constructor( private web: WebService ) { }
  @Input('open') open = true;
  @Output('closed') closed = new EventEmitter();
  google() { 
  }
  apple() { 
  }
  microsoft() { 
  }
  close(){
    this.open=false;
    this.closed.emit(0);
  }
  ngOnInit() {

  }
}
