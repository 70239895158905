export const Fi = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Tekijänoikeus &copy; 2023",
  black:"Musta",
  green:"Vihreä",
  gold:"Kulta",
  blue:"Sininen",
  brown:"Ruskea",
  purple:"Violetti",
  pink:"Vaaleanpunainen",
  red:"Punainen",
  Swatches:"Swatchit",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Värisävy",
  Saturation:"Kylläisyys",
  Lightness:"Keveys",
  Upgrade_To_Pro:"Päivittää Pro: hon",
  AllFeaturesInclude:"Kaikki ominaisuudet sisältävät:",
  PrintUnlimitedCheques:"Tulosta rajattomasti shekkejä",
  PremiumChequeDesigns:"Premium Tarkista mallit",
  ManageUnlimitedEmployees:"Hallitse rajoittamatonta määrää työntekijöitä",
  AddUnlimitedPayees:"Lisää rajoittamaton määrä maksunsaajia",
  CreateUnlimitedPayrolls:"Luo rajattomasti palkkalistoja",
  UnlimitedSchedulesandTimeSheets:"Rajoittamaton määrä aikatauluja ja aikataulukoita",
  BulkPayPalPayouts:"Joukko PayPal-maksut",
  UnlimitedBankAccounts:"Rajoittamaton määrä pankkitilejä",
  ManageMultipleCompanies:"Hallitse useita yrityksiä",
  TrackInsurancePolicies:"Seuraa vakuutussopimuksia",
  Bio_MetricProtection:"Biometrinen suojaus",
  Geo_FenceLock_OutProtection:"Geo-aidan lukitussuoja",
  Multiple_Companies_Word:"Useiden yritysten hallinta ei ole mahdollista ilman Checks Premiumia.",
  PayPal_Payouts_Word:"PayPal-maksut on poistettu käytöstä ilman Checks-palkkiota.",
  PINProtection:"PIN-suojaus",
  PasswordProtection:"Salasanasuojaus",
  May_Require_Approval:"Saattaa vaatia hyväksynnän.",
  Subscribe:"Tilaa",
  Billed:"Laskutettu",
  Up:"Ylös",
  Down:"Alas",
  Positioning:"Paikannus",
  Marker:"Merkki",
  Drag_Marker:"Vedä merkki",
  Tagline:"Tulosta shekit ja taulukoita palkkalaskenta",
  Marker_Word:"Käytä merkkejä elementin kokoamiseen.",
  Pinch_Zoom:"Purista zoomaus",
  Pinch_Word:"Zoomaa elementtiä nipistämällä.",
  Drag:"Raahata",
  Drag_Word:"Vedä elementtejä sormella.",
  subscription_alias_word:"Uusiutuva tilaus automaattisesti",
  premium_alias_word:"Kertaluonteinen päivityspaketti",
  print_alias_word:"Tulosta yksittäiset shekit",
  mode_alias_word:"tila",
  Pro:"Pro",
  Pro_word:"Pro-versio vaaditaan.",
  Cant_Delete_Default_Company_Word:"Valitettavasti et voi poistaa oletusyritystäsi.",
  Reinsert_Default_Designs:"Aseta oletusmallit uudelleen",
  Reinsert_Default_Designs_word:"Haluatko lisätä oletusmalleja uudelleen?",
  Subscription_Active_Disable_Word:"Tämä tilaus on aktiivinen. Haluatko peruuttaa tämän Cheques-tilauksen?",
  Company_Logo:"Yrityksen logo",
  ZERO_:"NOLLA",
  Disclaimer:"Vastuuvapauslauseke",
  Privacy_Policy:"Tietosuojakäytäntö",
  EULA:"Tarkistaa EULA",
  Terms_Of_Service:"Käyttöehdot",
  Terms_Of_Use:"Käyttöehdot",
  Refunds:"Palautusoikeus",
  Single_Print:"1 Tarkista",
  Two_Prints:"2 Tarkistaa",
  Five_Prints:"5 tarkastukset",
  Ten_Prints:"10 tarkastusta",
  Fifteen_Prints:"15 tarkastukset",
  Twenty_Prints:"20 shekkiä",
  Thirty_Prints:"30 shekkiä",
  Image_Added:"Kuva lisätty",
  Image_Preview:"Kuvan esikatselu",
  No_Image_Was_Selected:"Yhtään kuvaa ei valittu.",
  Cheques_Unlimited:"Sekkejä rajoittamaton",
  _Subscription:"Tilaus",
  Subscription:"Sekit - 1 kuukausi",
  Two_Month_Subscription:"Tarkastukset - 2 kuukautta",
  Three_Month_Subscription:"Tarkastukset - 3 kuukautta",
  Six_Month_Subscription:"Tarkastukset - 6 kuukautta",
  Twelve_Month_Subscription:"Tarkastukset - 12 kuukautta",
  Cheques_Are_Available:"Sekit ovat tulostettavissa.",
  Upgrade_Required_Two:"Valitse paketti ja aloita ostos kaksoisnapauttamalla hintapainiketta. Tulosta ammattimaisen näköisiä täysvärisekkejä sekunneissa.",
  Month:"Kuukausi",
  Due:"Eräpäivä:",
  Expires:"Vanhenee:",
  Subscription_Active:"Tilaus aktiivinen",
  Subscription_Inactive:"Tilaus ei ole aktiivinen",
  Purchase_Additional_Cheques:"Ostatko lisää sekkejä?",
  Printable_Cheque:"Tulostettava sekki",
  Printable_Cheques:"Tulostettavat shekit",
  Printable_Cheque_Word:"sekki on saatavilla tililläsi.",
  Printable_Cheques_Word:"shekit ovat saatavilla tililläsi.",
  Max_Amount_Message:"Määrittämäsi summa on ylittänyt tälle järjestelmälle asetetun enimmäissumman:",
  Terms_Required_Word:"Sinun on hyväksyttävä tämä sopimus ennen kuin jatkat shekkien käyttöä.",
  Subscriptions:"Tilaukset",
  Product_Upgrades:"Päivitykset",
  Mailed_Out_Cheques:"Postitetut sekit",
  Enter_A_Company_Name:"Anna yrityksen nimi.",
  Single_Cheques:"Yksittäiset tarkastukset",
  Cheque_Golden:"Kultainen shekki",
  Cheque_Golden_Window:"Kultainen ruutukuvio.",
  Cheque_Green:"Vihreä tarkistus",
  Cheque_Green_Window:"Vihreä ruutukuvio.",
  Cheque_Red:"Punainen tarkistus",
  Cheque_Red_Window:"Punainen ruutu design.",
  Cheque_Yellow:"Keltainen tarkistus",
  Cheque_Yellow_Window:"Keltainen ruutu malli.",
  Cheque_Statue_of_Liberty:"Vapaudenpatsas",
  Cheque_Statue_of_Liberty_Window:"Vapaudenpatsaan ruudullinen suunnittelu.",
  Cheque_Green_Wave:"Vihreä aalto",
  Cheque_Green_Wave_Window:"Vihreä ruutukuvio.",
  Cheque_Golden_Weave:"Kultainen kudos",
  Cheque_Golden_Weave_Window:"Tyylikäs kultainen ruutukuvio.",
  Cheque_Green_Sun:"Vihreä aurinko",
  Cheque_Green_Sun_Window:"Syvä ja rauhoittava sekkimuotoilu.",
  Cheque_Blue_Checkers:"Sininen tammi",
  Cheque_Blue_Checkers_Window:"Sininen ruutukuvio.",
  Cashiers_Check:"Kassan shekki",
  Cashiers_Check_Window:"Kassasekin tyylimalli.",
  Cheque_Aqua_Checkers:"Aqua Tammi",
  Cheque_Aqua_Checkers_Window:"Aqua check -suunnittelu.",
  Cheque_Golden_Checkers:"Kultainen tammi",
  Cheque_Golden_Checkers_Window:"Kultainen ruutukuvio.",
  Upgrade_Unavailable:"Päivitykset eivät ole saatavilla",
  Bank_Code_Protection:"Pankkinumeron suojaus",
  Bank_Code_Protection_Word:"Suojaa pankkinumerosi käytöltä tässä toisella laitteella tai toisella käyttäjällä toimivassa sovelluksessa. Tämä toiminta on PERUUTTAMATON. Jatkaa?",
  Bank_Code_Protection_Blocked_Word:"Pankkinumerot, joita yrität käyttää, on varattu toiselle käyttäjälle tai laitteelle.",
  Bank_Code_Protection_Error_Word:"Numeron vahvistus epäonnistui. Muodosta yhteys Internetiin ja yritä lisätä tämä pankkitili uudelleen.",
  Bank_Code_Protection_Set_Error_Word:"Pankkinumeron suojaus edellyttää, että olet yhteydessä Internetiin. Yhdistä ja yritä uudelleen.",
  Upgrade_Unavailable_Word:"Valitettavasti meillä on vaikeuksia vahvistaa sinua. Sekkien tilaukset ja päivitykset eivät ole tällä hetkellä ostettavissa alueellasi.",
  PayPal_Payment_Preview:"PayPal-maksun esikatselu",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Valitse PayPal",
  PayPal_Applications:"PayPal-sovellukset",
  Purchase_With_Apple_Pay:"Osta Apple Paylla",
  Google_Pay:"Google Pay",
  Companies:"Yritykset",
  Insurance:"Vakuutus",
  New_PayPal:"Uusi PayPal",
  Pay_By:"Maksutapa",
  Insure:"Vakuuta",
  Miles:"Mailia",
  Payment_Method:"Maksutapa",
  Select_Policies:"Valitse Käytännöt",
  Policies:"Käytännöt",
  Policy:"Käytäntö",
  No_PayPal_Account:"Ei PayPal-tiliä",
  No_Policies:"Ei vakuutussopimuksia",
  New_Policy:"Uusi politiikka",
  PayPal_Payment:"PayPal Maksu",
  PayPal_Payments:"PayPal-maksut",
  No_Payment_Selected:"Maksua ei ole valittu",
  Sending_Payment_Word:"Odota... Tätä maksua lähetetään.",
  Sending_Payments_Word:"Odota... Maksuja lähetetään.",
  No_Payment_Selected_PayPal:"ei ole valittu <a routerLink='/folder/Payments'>PayPal-maksua</a>.",
  Payment_Sent:"Maksu lähetetty",
  PayPal_Payment_Sent:"Tämä maksu on lähetetty PayPalin kautta.",
  Copay:"Copay",
  Dead:"Kuollut",
  Alive:"Elossa",
  Not_Dead:"Ei ole kuollut",
  Unclaimed:"Lunastamaton",
  Attempted:"Yritetty",
  Deceased:"kuollut",
  Claimed:"Väitetty",
  Unpaid:"Palkaton",
  Sending_Payment:"Maksun lähettäminen",
  Sending_Payments:"Maksujen lähettäminen",
  Send_PayPal_Confirmation:"Haluatko lähettää tämän tapahtuman PayPalin kautta?",
  Send_PayPal_Confirmation_Word:"Lähetä tämä tapahtuma painamalla vihreää painiketta.",
  Save_Payment_As_Unpaid:"Tallennetaanko tämä maksu maksamattomaksi?",
  Payment_Pay_Confirmation_PayPal:"Tallennetaanko tämä maksu maksetuksi?",
  No_Policies_Word:"Lisää ensimmäinen <a routerLink='/folder/Postage/New'>vakuutus</a> nyt.",
  Timesheet_Multiple_Delete_Confirmation:"Haluatko varmasti poistaa useita työaikaraportteja?",
  Select_Multiple_Timesheets_Prompt:"Työaikaraportteja ei ole valittu. Valitse vähintään yksi työaikalomake.",
  Select_Multiple_Policies_Prompt:"Ei valittuja käytäntöjä. Valitse vähintään yksi vakuutus.",
  Policies_Multiple_Delete_Confirmation:"Haluatko varmasti poistaa useita käytäntöjä?",
  Company:"Yhtiö",
  Add_Company:"Lisää yritys",
  New_Company:"Lisää yritys",
  No_Companies:"Ei yrityksiä",
  Enable_Company:"Ota yritys käyttöön",
  Select_Company:"Valitse Yritys",
  The_Default_Company:"Yrityksen oletustunniste.",
  Manage_Companies:"Hallitse yrityksiä",
  No_Companies_Word:"Sekit käyttävät oletusyritystä .<br />Lisää <a routerLink='/folder/Company/New'>ensimmäinen yritys</a>.",
  Default_Company:"Oletusyritys",
  Cheques_Unlimited_Word:"Checks Unlimited -sovelluksella voit tulostaa niin monta shekkiä kuin haluat.",
  Cheques_Subscription_Word:"Sekkitilauksella voit tulostaa niin monta shekkiä kuin haluat.",
  You_Own_This_Product:"Omistat tämän tuotteen.",
  Your_Subscription_is_Active:"Tilauksesi on aktiivinen.",
  Active_Products:"Aktivoidut tuotteet",
  Purchase_Confirmation:"Ostaa",
  Purchase_Cheques:"Ostoshekit",
  Restore_Purchase:"Palauttaa ostot",
  Erase_Purchase:"Poista ostokset",
  Successfully_Purchased:"Onnistuneesti ostettu",
  Enter_Your_Licence_Key:"Aktivoi tämä tuote antamalla lisenssiavaimesi tälle sivulle.",
  Licence_Key:"Lisenssiavain",
  Enter_Licence_Key:"Anna lisenssiavain",
  Purchased:"Osti",
  Enable_Feature:"Ota ominaisuus käyttöön",
  Cancel_Subscription:"Peruuta tilaus",
  Subscription_Removed:"Tilaus poistettu",
  Select_Active_Subscription:"Valitse aktiivinen tilaus muokataksesi sitä.",
  Remove_Subscription_Word:"Haluatko varmasti peruuttaa tämän tilauksen?",
  Delete_Company_Confirmation:"Haluatko varmasti poistaa koko tämän yrityksen? VAROITUS: Menetät kaikki tallennetut tiedot!",
  Delete_Default_Company_Word:"Oletusyritystä ei voi poistaa . Haluatko nollata sovelluksen ja palauttaa sen oletustilaan? VAROITUS: Menetät kaikki tallennetut tiedot!",
  Console_Warning_2:"Älä käsittele valuuttaa tällä sovelluksella, joka ei tällä hetkellä ole sinun.",
  Terms_and_Conditions:"Käyttöehdot",
  and_the:"ja",
  for:"varten",
  Please_Read_Word:"Lue ja hyväksy",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Joitakin valuuttamuuntokursseja ei löytynyt. Muodosta yhteys Internetiin.",
  Free:"Vapaa",
  DB_Erase_Prompt_2:"Poistetaanko koko kehittäjätietokanta kokonaan? VAROITUS: Menetät kaikki OSTO- ja TILAUStiedot!",
  Successfully_Imported:"Onnistuneesti tuotu",
  Select_Postage:"Valitse Postitus",
  No_Postage:"Ei postimerkkejä",
  No_Paid_Postage_Word:"Lisää ensimmäinen <a routerLink='/folder/Postage/New'>Maksullinen postimerkki</a>.",
  Trial_Complete:'Kokeilu valmis',
  Please_Upgrade:'Päivitä shekit jatkaaksesi tulostusta.',
  Aa:"Aa",
  Color:"Väri",
  Font:"Fontti",
  Guide:"Opas",
  Guides:"Oppaat",
  Image:"Kuva",
  Zoom:"Zoomaus",
  Logo:"Logo",
  Bank:"Pankki",
  MICR:"MICR",
  Total:"Kaikki yhteensä",
  Cancel:"Peruuttaa",
  Confirm:"Vahvistaa",
  Method:"Menetelmä",
  Biometric_Security:"Biometrinen turvallisuus",
  Please_Login_To_Continue:"Kirjaudu sisään jatkaaksesi.",
  Complete:"Saattaa loppuun",
  Sign_Up:"Kirjaudu",
  Error:"Virhe",
  Biometrics:"Biometriikka",
  Percent:"Prosenttia",
  Zero_Percent:"0 %",
  Top_Margin:"Ylämarginaali",
  Bottom_Margin:"Alamarginaali",
  Left_Margin:"Vasen marginaali",
  Right_Margin:"Oikea marginaali",
  MICR_Margin:"MICR-marginaali",
  Table_Margin:"Taulukon marginaali",
  Address_Margin:"Osoitteen marginaali",
  Percentage_:"Prosenttiosuus",
  Vacation_Title:"Loman otsikko",
  Use_PIN:"Käytä PIN-koodia",
  Loading__:"Ladataan...",
  Design_Title:"Suunnittelun otsikko",
  Authorize:"Valtuuta",
  Key:"Avain",
  Public_Key:"Julkinen avain",
  Private_Key:"Yksityinen avain",
  Authenticate:"Todentaa",
  Last_Payroll:"Viimeinen palkanlaskenta",
  Last_Calculation:"Viimeinen laskelma",
  Authorized:"Valtuutettu",
  Geo_Authorized:"Maantieteellinen sijainti: Valtuutettu",
  Not_Authorized:"Ei sallittu",
  Authorization_Complete:"Valtuutus valmis",
  Geolocation_Authorization:"Maantieteellisen sijainnin valtuutus",
  Out_of_Bounds:"Rajojen ulkopuolella",
  Cant_Delete_Default_Design:"Oletusmallia ei voi poistaa.",
  Unauthorized_Out_of_Bounds:"Luvaton: rajojen ulkopuolella",
  Biometric_Authorization:"Biometrinen lupa",
  Locating_Please_Wait:"Etsitään, odota...",
  Test_Biometrics:"Testaa biometriikkaa",
  Cheque_Margins:"Tarkista marginaalit",
  Percentage_Full_Error:"Prosenttikentän arvo ei voi olla yli 100 %.",
  No_Payroll_Text:"Lisää <a routerLink='/folder/Employee/New'>työntekijä</a> tai <a routerLink='/folder/Payee/New'>maksunsaaja</a> ja <a routerLink='/folder/Schedule/New'>Aikataulu</a>.",
  Design_Saved:"Suunnittelu tallennettu",
  Default_Design_Selected:"Oletusmuotoilu valittu",
  Partial_Import:"Osittainen tuonti",
  Partial_Export:"Osittainen vienti",
  Entire_Import:"Koko tuonti",
  Entire_Export:"Koko vienti",
  Existing_Password:"Anna nykyinen salasanasi:",
  Existing_PIN:"Anna olemassa oleva PIN-koodisi:",
  Pin_Change_Header:"PIN-koodin vahvistus",
  Pin_Change_SubHeader:"Vahvista muutos antamalla vanha PIN-koodisi.",
  Pass_Change_Header:"Salasanan vahvistus",
  Pass_Change_SubHeader:"Vahvista muutos antamalla vanha salasanasi.",
  PIN_Enter_Message:"Vahvista syöttämällä PIN-koodisi.",
  Password_Enter_Message:"Vahvista antamalla salasanasi.",
  Pin_Updated_Success:"PIN-koodi päivitetty onnistuneesti!",
  Pin_Updated_Fail:"PIN-koodia ei voitu päivittää.",
  Pass_Updated_Success:"Salasana päivitetty onnistuneesti.",
  Pass_Updated_Fail:"Salasanaa ei voitu päivittää.",
  Preview_Payment:"Maksun esikatselu",
  Preview_Payroll:"Esikatselu palkkalaskelma",
  No_Payments_Created:"Maksuja ei löydetty luotua.",
  Payment_Preview:"Maksun esikatselu",
  Enable_Payee:"Ota maksunsaaja käyttöön",
  Rendered:"Renderoitu",
  No_Email:"Ei sähköpostia",
  Setup_Cheques:"Asennuksen tarkistukset",
  name:"Nimi",
  address:"Osoite",
  address_2:"Osoiterivi 2",
  city:"Kaupunki",
  province:"maakunta",
  postal_code:"Postinumero",
  country:"Maa",
  username:"Käyttäjätunnus",
  full_name:"Koko nimi",
  birthday:"Syntymäpäivä",
  email:"Sähköposti",
  phone:"Puhelin",
  employees:"Työntekijät",
  addresses:"Osoitteet",
  payment_amount_limit:"Maksun määräraja",
  total_limit:"Kokonaisraja",
  payees:"Maksunsaajat",
  description:"Kuvaus",
  address_line_one:"Osoiterivi yksi",
  address_line_two:"Osoiterivi kaksi",
  image:"Kuva",
  account_holder:"Tilin haltija",
  cheque_starting_id:"Tarkista aloitustunnus",
  transit_number:"Transit numero",
  institution_number:"Laitoksen numero",
  designation_number:"Nimitysnumero",
  account_number:"Tilinumero",
  currency:"Valuutta",
  signature:"Allekirjoitus",
  payment_payroll_limit:"Maksuraja",
  total_limi:"Kokonaisraja",
  date:"Päivämäärä",
  printed_memo:"Painettu muistio",
  banks:"Pankit",
  signature_image:"Allekirjoituskuva",
  address_name:"Osoite Nimi",
  notes:"Huomautuksia",
  design:"Design",
  title:"Otsikko",
  frequency:"Taajuus",
  fax:"Faksi",
  salaries:"Palkat",
  salary_ids:"Palkkatunnukset",
  start_time:"Aloitusaika",
  end_time:"Loppu aika",
  paid:"Maksettu",
  overtime_percentage:"Maksettu prosentti",
  overtime_amount:"Maksettu kiinteä summa",
  first_name:"Etunimi",
  last_name:"Sukunimi",
  moderation:"Maltillisuus",
  create:"Luoda",
  edit:"Muokata",
  destroy:"Tuhota",
  day_start_time:"Päivän_aloitusaika",
  day_end_time:"Päivän_lopun_aika",
  fullname:"Nimi",
  time:"Aika",
  auto_send:"Lähetä automaattisesti",
  time_method:"Aika-menetelmä",
  schedules:"Aikataulut",
  indefinite_payroll_enabled:"Ota käyttöön toistaiseksi",
  amount:"Määrä",
  repeat:"Toistaa",
  always_enabled:"Aina käytössä",
  start_date:"Aloituspäivämäärä",
  end_date:"Päättymispäivä",
  Cheque_Total:"Tarkista yhteensä",
  Total_Amount:"Kokonaismäärä:",
  Amounts:"Summat:",
  Images:"kuvat",
  Files:"Tiedostot",
  Previewing:"Esikatselu:",
  Insert:"Lisää",
  Preview_Import:"Esikatselu tuonti",
  Entry:"Sisäänpääsy",
  Entries:"merkinnät",
  No_Entries:"Ei merkintöjä",
  Import_Type:"Tuontityyppi",
  Select_Details:"Valitse Tiedot",
  Select_Payee:"Valitse Maksunsaaja",
  Enable_Holidays:"Ota lomat käyttöön",
  Disable_Holidays:"Poista lomat käytöstä",
  Wire_Transfer:"Pankkisiirto",
  New_Export:"Uusi vienti",
  Export_Data:"Vie tiedot",
  Export_Data_Word:"Valitse vietävän ja ladattavan tiedoston tyyppi.",
  Export_File:"Vie tiedosto",
  Mode:"tila",
  Times:"Ajat",
  Widgets:"Widgetit",
  Slider:"Liukusäädin",
  Set_Details:"Aseta tiedot",
  Select_Type:"Valitse Tyyppi",
  Display_Slider:"Näytön liukusäädin",
  Dashboard_Slider:"Kojelaudan liukusäädin",
  Dashboard_Mode:"Kojelautatila",
  Show_Intro:"Näytä esittely",
  Show_Payrolls:"Näytä palkkalaskelmat",
  Show_Holidays:"Show Holidays",
  Show_Invoices:"Näytä laskut",
  Show_Cheques:"Näytä shekit",
  Enabled_Widgets:"Käytössä olevat widgetit",
  Disabled_Widgets:"Poistetut widgetit",
  Colors:"värit",
  Barcodes:"Viivakoodit",
  View_Timers:"Näytä ajastimet",
  Gradients:"Gradientit",
  No_Info:"Ei tietoa",
  Disable:"Poista käytöstä",
  Show_Layer:"Näytä tasot",
  Hide_Layer:"Piilota tasot",
  Text_Layer:"Tekstitasot",
  Secondly:"toiseksi",
  Minutely:"Minuuttisesti",
  nine_am:"9:00",
  five_pm:"17:00",
  Enable_Address:"Ota osoite käyttöön",
  Invalid_File_Type:"Valitettavasti valittiin virheellinen tiedostotyyppi. Tuettu tiedostotyyppi:",
  Error_Updating_Entry:"Anteeksi, tämän merkinnän päivittämisessä tapahtui virhe.",
  Schedule_Timing_Alert:"Virhe: Aikataulun alkamisaika on asetettu arvoon päättymisajan jälkeen.",
  Select_Multiple_Payments_Prompt:"Maksuja ei ole valittu. Valitse vähintään yksi maksu.",
  Select_Multiple_Cheques_Prompt:"Ei valittuja shekkejä. Valitse vähintään yksi sekki.",
  Select_Multiple_Items_Prompt:"Ei valittuja kohteita. Valitse vähintään yksi kohde.",
  Paymemt_Multiple_Delete_Confirmation:"Haluatko varmasti poistaa useita maksuja?",
  Cheque_Multiple_Delete_Confirmation:"Haluatko varmasti poistaa useita shekkejä?",
  Payee_Multiple_Delete_Confirmation:"Haluatko varmasti poistaa useita maksunsaajia?",
  Employee_Multiple_Delete_Confirmation:"Haluatko varmasti poistaa useita työntekijöitä?",
  MICR_Warning:"Huomautus: Jotkin tulostimet ja laitteet eivät ehkä näytä MICR-fonttia oikein.",
  Automatically_Send:"Lähetä automaattisesti",
  Type:"Tyyppi",
  Payment_Type:"Maksutapa",
  Auto_Send:"Automaattinen lähetys",
  Automatically_Process:"Käsittele automaattisesti",
  Auto_Process:"Automaattinen prosessi",
  Image_Based:"Kuvapohjainen",
  Font_Based:"Fonttipohjainen",
  Rerender:"Renderöi uudelleen",
  Rendering:"Renderöinti",
  Render:"Tiedostot",
  Top:"Ylös",
  Middle:"Keski",
  Bottom:"Pohja",
  Top_Left:"Ylävasen",
  Top_Center:"Yläkeskus",
  Top_Right:"Yläoikea",
  Middle_Left:"Keski vasen",
  Middle_Center:"Keskikeskus",
  Middle_Right:"Keskioikea",
  Bottom_Left:"Alhaalla vasemmalla",
  Bottom_Center:"Alakeskus",
  Bottom_Right:"Ala oikea",
  Numbers:"Numerot",
  Verified:"Vahvistettu",
  Paper_Size:"Paperikoko",
  New_Device:"Uusi laite",
  Add_Device:"Lisää laite",
  Remove_Device:"Poista laite",
  Delete_Device:"Poista laite",
  Block_Device:"Estä laite",
  Block:"Lohko",
  Unblock:"Kumoa esto",
  Table:"Pöytä",
  Scan_Login_Code:"Skannaa kirjautumiskoodi",
  Login_Code:"Kirjautumiskoodi",
  Scan_Code:"Skannaa koodi",
  Scan_QR_Code:"Skannaa QR-koodi",
  Select_All:"Valitse kaikki",
  Deselect_All:"Poista kaikki valinta",
  Increase:"Lisääntyä",
  Decrease:"Vähennä",
  Bold:"Lihavoitu",
  Text:"Teksti",
  Style:"Tyyli",
  Italic:"Kursiivi",
  QR_Code:"QR koodi",
  Barcode:"Viivakoodi",
  Browse_Images:"Selaa kuvia",
  Browse_Files:"Selaa tiedostoja",
  Background_Image:"Taustakuva",
  Logo_Image:"Logokuva",
  Header_Image:"Otsikkokuva",
  Bank_Image:"Pankin kuva",
  Cut_Lines:"Leikkaa linjat",
  Background:"Tausta",
  License:"Lisenssi",
  One_License:"1 lisenssi:",
  Licensed:"Lisenssi:",
  Not_Licensed:"Ei lisensoitu",
  Enter_Serial:"Syötä sarja",
  Serial_Key:"Sarjanumero",
  Serial:"Sarja",
  Product_Key:"Tuoteavain",
  Check_Product_Key:"Tarkista tuoteavain",
  Add_Product_Key:"Lisää tuoteavain",
  Verifying_Purchase:"Vahvistetaan ostosta...",
  Print_Envelope:"Tulosta kirjekuori",
  Envelope:"Kirjekuori",
  Thank_You:"Kiitos!",
  Scale:"Mittakaava",
  Print_Scale:"Tulostusasteikko",
  Borders:"Rajat",
  VOID:"VOID",
  Void_Cheque:"Tyhjä tarkistus",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"MAKSA TILAUKSESSA:",
  NO_DOLLARS:"EI DOLLARIA ",
  ONE_DOLLAR:"YKSI DOLLARI",
  DOLLARS:" DOLLARIA",
  AND:" JA ",
  CENTS:" SENTTIÄ.",
  NO_:"EI ",
  ONE_:"YKSI ",
  AND_NO_:"JA EI ",
  _AND_ONE_:"JA YKSI ",
  DOLLARS_AND_ONE_CENT:" JA YKSI SENTTI.",
  AND_NO_CENTS:" JA NOLLA SENTTIÄ.",
  CHEQUE_PRINT_DATE:"PÄIVÄMÄÄRÄ:",
  CHEQUE_PRINT_MEMO:"MUISTIO:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Alkutehtävät",
  Inital_Setup:"Alkuasetukset",
  Welcome_To:"Tervetuloa ",
  Welcome:"Tervetuloa",
  Swipe:"Swipe",
  Intro_Setup:"Intro-asetukset",
  Introduction:"Esittely",
  CHEQUE_PRINT_CREDIT:"Powered by Cheques",
  Title_Intro_Word:"Tervetuloa Chequesiin",
  Title_Intro:"Sekkien esittely",
  Title_Setup:"Sekkien asetukset",
  PayPal_Default_Email_Message:"Olet saanut uuden PayPal-siirron. [Powered by Cheques]",
  Cheques_App_Export:"Viedi sekillä",
  Post_Intro_Thanks:"Kiitos, että valitsit Cheques. Asennusprosessi on nyt valmis.",
  Post_Intro_Word:"Aloita tulostamalla ensimmäinen sekkisi, lisäämällä tuleva maksu tai lisäämällä työntekijä palkkalistalle.",
  Upgrade_Required:"Sekit edellyttävät, että omistat ohjelmiston premium-version, jotta voit piilottaa tämän viestin ja avata lisäominaisuuksia.",
  Upgrade_Title:"Sekit",
  Mailout_Prompt:"Voit lisäksi valita, että Shekki lähettää palkkasekit puolestasi.",
  Mailed_Cheque:"Postitettu sekki: ",
  Mailed_Cheque_Color:"Postitettu sekki (väri): ",
  Terms_Purchase:"Kaikki sähköiset ostokset shekeillä ovat täysin hyvitettävissä 30 päivän ajan ostopäivästä. Lue ja hyväksy <a href='#'>käyttöehdot</a> ennen kuin jatkat.",
  Dashboard_Setup:"Asenna ensisijainen tulostin",
  Dashboard_Add:"Lisää ensisijainen pankkitili",
  Dashboard_Customize:"Valitse tarkistusmalli",
  Dashboard_Job_Salary:"Luo työpaikkasi ja lisää palkkasi",
  Dashboard_Employees:"Seuraa työntekijöitä ja maksunsaajia",
  Dashboard_Print:"Tulosta ja postita palkkasi",
  Dashboard_Payroll:"Automatisoi palkkalaskelman tulostus",
  Dashboard_PayPal:"Setup PayPal Payroll / Payouts",
  Begin_Setup:"Aloita asennus",
  Get_Started:"Aloittaa",
  Purchase:"Ostaa",
  Lockdown:"Sisälle suojautuminen",
  Unlock:"Avata",
  Detailed:"Yksityiskohtainen",
  Log_In:"Kirjaudu sisään",
  Login:"Kirjaudu sisään",
  Launch:"Tuoda markkinoille",
  Register:"Rekisteröidy",
  Finish:"Viedä loppuun",
  List:"Lista",
  Weekends:"Viikonloppuisin",
  Weekly:"Viikoittain",
  PayPal_Default_Subject:"Uusi maksu",
  Payment_Message:"Maksuviesti",
  PayPal_Default_Payment_Note:"Kiitos",
  Setup_Your_Cheqing_Account:"Sekkitili",
  Add_Your_Primary_Cheqing_Account:"Lisää ensisijainen cheqing-tilisi.",
  Welcome_Word:"Yksinkertaista ja automatisoi palkanlaskentaa ja henkilöstöhallintoa.",
  Get_Started_Quickly:"Vastaa vain muutamaan yksinkertaiseen kysymykseen, jotka auttavat meitä pääsemään alkuun...",
  Done_Intro_Word:"Asennus valmis",
  PIN_Protected:"Salasana & PIN suojattu",
  Enter_New_PIN:"Anna uusi PIN-koodi:",
  Enter_PIN:"Anna PIN-koodi:",
  Invalid_PIN:"Virheellinen PIN syötetty.",
  Account_Identifier:"Tilin tunniste",
  New_Account_Identifier:"Uusi tilitunnus",
  attempt:"yrittää",
  attempts:"yrityksiä",
  remaining:"jäljelle jäänyt",
  Language:"Kieli",
  languages:"Kieli (kielet",
  select_languages:"Valitse kieli",
  Deposit:"Tallettaa",
  Hire_One_Now:"Vuokraa yksi nyt",
  App_Locked:"Sovellus on lukittu.",
  Skip_:"Ohita",
  Skip_to_Dashboard:"Skip to the Dashboard",
  Dashboard:"Kojelauta",
  Import:"Tuonti",
  Admin:"Järjestelmänvalvojat",
  New_Admin:"Uusi järjestelmänvalvoja",
  Settings:"Asetukset",
  Color_Picker:"Värinvalitsija",
  Font_Picker:"Fonttivalitsin",
  Logout:"Kirjautua ulos",
  Close:"Kiinni",
  Close_menu:"Kiinni",
  Excel:"Excel-tiedosto",
  Csv:"CSV-tiedosto",
  Sql:"SQL-tiedosto",
  Json:"JSON-tiedosto",
  Url:"Import by URL",
  Back:"Takaisin",
  Timers:"Ajastimet",
  Cheque:"Tarkistaa",
  Print:"Tulosta",
  Designs:"Suunnittelut",
  Pause_Printing:"Keskeytä tulostus",
  Resume_Printing:"Jatka tulostusta",
  Printing_Paused:"Tulostus keskeytetty",
  PrintingUnavailable:"Anteeksi! Tulostus ei ole käytettävissä tässä järjestelmässä.",
  Prints_Paused:"Tulosteet keskeytetty",
  Administration:"Hallinto",
  Loading:"Ladataan",
  Unnamed:"Nimetön",
  error:"Anteeksi, tätä sekkiä ei voitu avata katselua varten.",
  No_Cheques_To_Print:"Ei tulostettavia shekkejä",
  No_Cheques_To_Print_Word:"Luo <a routerLink='/folder/Cheque/New'>uusi sekki</a>.",
  New_Cheque:"Uusi sekki",
  Start_One_Now:"Aloita yksi nyt",
  Edit_Cheque:"Muokkaa tarkistusta",
  Select_Cheques:"Valitse shekit",
  Select_Employee:"Valitse työntekijä",
  Default_Printer:"Oletustulostin",
  Reassign:"määritä uudelleen",
  Configure:"Määritä",
  Template:"Sapluuna",
  Designer:"Suunnittelija",
  Edit_Designs:"Muokkaa malleja",
  New_Design:"Uusi muotoilu",
  Next:"Seuraava",
  Save:"Tallentaa",
  Name:"Nimi",
  Mail:"Mail",
  Amount:"Määrä",
  Date:"Päivämäärä",
  PayPal:"PayPal",
  Payouts:"Maksut",
  PayPal_Label:"PayPal Label",
  Email_Username:"Sähköposti / Käyttäjätunnus",
  Client_ID:"Asiakastunnus",
  Sandbox_Email:"Sandbox Email",
  PayPal_Email:"Paypal sähköposti",
  PayPal_Username:"API-käyttäjänimi",
  PayPal_Payouts:"PayPal-maksut",
  Select_PayPal_Key:"Valitse PayPal-avain",
  Secret:"Salaisuus",
  API_Secret:"API-salaisuus",
  PayPal_API_Keys:"PayPal-avaimet",
  New_PayPal_Key:"Uusi PayPal-avain",
  Email_Subject:"Sähköpostin aihe",
  Email_Message:"Sähköpostiviesti",
  Payout_Options:"Maksuvaihtoehdot",
  Payment_Note:"Maksuilmoitus",
  Enable_Employee:"Ota työntekijä käyttöön",
  Enable_Production_Mode:"Ota tuotantotila käyttöön",
  Sandbox_Username:"Sandbox Username",
  Sandbox_Signature:"Sandbox Signature",
  Sandbox_Password:"hiekkalaatikon salasana",
  Production_Username:"Tuottamisen käyttäjätunnus",
  Production_Signature:"Tuotantoallekirjoitus",
  Production_Password:"Tuotantosalasana",
  Production_Email:"Tuotantosähköposti",
  API_Client_ID:"API-asiakastunnus",
  API_Signature:"API-allekirjoitus",
  API_Password:"API-salasana",
  API_Username:"API-käyttäjänimi",
  Secret_Key:"Salainen avain",
  Sandbox:"Hiekkalaatikko",
  Production:"Tuotanto",
  Sandbox_Keys:"hiekkalaatikon avaimet",
  Production_Keys:"Tuotantoavaimet",
  API_Title:"API otsikko",
  Production_Mode:"Tuotantotila",
  Account_Label:"Tilitunniste",
  No_PayPal_Setup:"Ei PayPal-avainta",
  Enable_PayPal_Account:"Ota PayPal-tili käyttöön",
  No_PayPal_Word:"Add your <a routerLink='/folder/Invoice/New'>PayPal API-avain</a>.",
  Printed_Memo:"Painettu muistio",
  Employee:"Työntekijä",
  View_Employee:"Näytä työntekijä",
  Mailing_Address:"Postitusosoite",
  Company_Address:"Yritys osoite",
  Select_Company_Address:"Valitse yrityksen osoite",
  Address:"Osoite",
  Any_Day:"Milloin tahansa",
  Address_Name:"Osoite Nimi",
  Unit:"Yksikkö",
  Account:"Tili",
  Bank_Account:"Pankkitili",
  Account_Limits:"Ota tilirajoitukset käyttöön",
  Payroll:"Palkkalaskenta",
  New_Payroll:"Uusi palkkalista",
  No_Payroll:"Ei tulevaa palkkaa",
  Upcoming_Holiday:"Tuleva loma:",
  Invoice_Due:"Erääntyvä lasku:",
  New_Invoice:"Uusi lasku",
  No_Invoices:"Ei laskuja",
  No_Invoices_Word:"Luo ensimmäinen <a routerLink='/folder/Invoice/New'>lasku</a>.",
  Cheque_Due:"Sekki erääntyy:",
  Payrolls:"Palkkalaskenta",
  Sandbox_Mode:"Hiekkalaatikko tila",
  Hire:"Vuokraus",
  Pay:"Maksaa",
  New:"Uusi",
  Add:"Lisätä",
  Make:"Tehdä",
  Time:"Aika",
  Write:"Kirjoittaa",
  Holiday:"Loma",
  Holidays:"lomat",
  Next_Holiday:"Seuraava loma:",
  All_Done:"Valmista!",
  Employees:"Työntekijät",
  Payees:"Maksunsaajat",
  Job:"Job",
  Jobs:"Työt",
  Invoice:"Lasku",
  Invoices:"Laskut",
  Vacations:"lomat",
  Cheques:"Tarkistukset",
  Brand_Cheques:"Brändi",
  Payment:"Maksu",
  Enable_Payment:"Ota maksu käyttöön",
  Payments:"Maksut",
  Schedule:"Ajoittaa",
  Schedules:"Aikataulut",
  Timesheet:"Kellokortti",
  Timesheets:"aikataulut",
  Salary:"Palkka",
  New_Address:"Uusi osoite",
  Address_2:"Osoiterivi 2)",
  _City:"Kaupunki",
  _State:"State/Prov",
  City:"Kaupunki / kylä",
  State:"Osavaltio / maakunta",
  Postal:"Postinumero",
  ZIP:"Posti / ZIP",
  Country:"Maa",
  Addresses:"osoitteet",
  Required_Options:"Pakolliset vaihtoehdot",
  Optional_Options:"Valinnaiset vaihtoehdot",
  Additional_Options:"Lisävaihtoehtoja",
  Required:"Edellytetään",
  Optional:"Valinnainen",
  Additional:"Lisä",
  No_Addresses:"Ei osoitteita",
  New_Address_Word:"Lisää ensimmäinen <a routerLink='/folder/Address/New'>osoite</a>.",
  Select_Address:"Valitse osoite",
  No_Address:"Ei osoitteita",
  Print_Cheque:"Tulosta sekki",
  Print_Cheque_Now:"Tulosta tarkistus nyt",
  Description:"Kuvaus",
  Pay_To_The_Order_Of:"Maksa tilaukseen:",
  Select_Date_Range:"Valitse päivämääräalue",
  Select_Starting_Date:"Valitse alkamispäivä",
  Select_Ending_Date:"Valitse päättymispäivä",
  Select_Date:"Valitse päivämäärä",
  Cheque_Date:"Tarkista päivämäärä",
  Cheque_Memo:"Tarkista muistio",
  Blank_Cheque:"Avoin shekki",
  Blank:"Tyhjä",
  No_Cheques:"Ei tarkastuksia",
  No_Cheques_Word:"Tulosta ensimmäinen <a routerLink='/folder/Cheque/New'>sekki</a>.",
  Cheque_Amount_Placeholder:"0,00 $",
  View_Image:"Näytä kuva",
  View:"Näytä",
  Modify:"Muuttaa",
  Delete:"Poistaa",
  Cheque_Paid:"Maksettu",
  New_Deduction:"Uusi vähennys",
  Title:"Title",
  Frequency:"Taajuus",
  Hourly:"Tunneittain",
  Daily:"Päivittäin",
  Weekdays:"Arkisin",
  BiWeekly:"2 viikkoa",
  TriWeekly:"3 viikkoa",
  Monthly:"Kuukausittain",
  MiMonthly:"2 kuukautta",
  Quarterly:"Neljännesvuosittain",
  Yearly:"Vuosittain",
  Every_Week:"Joka viikko",
  Every_Payroll:"Jokainen palkkalista",
  Every_Month:"Joka kuukausi",
  Annually:"Vuosittain",
  Always_Scheduled:"Aina aikataulutettu",
  Start_Date:"Aloituspäivämäärä",
  End_Date:"Päättymispäivä",
  Start_Time:"Aloitusaika",
  End_Time:"Loppu aika",
  Deduction_Label:"Vähennysmerkki",
  Notes:"Muistiinpanot",
  Options:"Vaihtoehdot",
  Enable:"Ota käyttöön",
  Select_Deductions:"Valitse vähennykset",
  Deductions:"Vähennykset",
  No_Deductions:"Ei vähennyksiä",
  No_Deductions_Word:"Luo ensimmäinen <a routerLink='/folder/Deduction/New'>vähennys</a>.",
  New_Employee:"Uusi työntekijä",
  No_Title:"Ei otsikkoa",
  _Name:"Nimi",
  About_Yourself:"Itsestäsi",
  Full_Name:"Koko nimi",
  Birthday:"Syntymäpäivä",
  Email:"Sähköposti",
  SMS:"TEKSTIVIESTI",
  Phone:"Puhelin",
  Test:"Testata",
  Call:"Soittaa puhelimella",
  Fax:"Faksi",
  Printed_Note:"Painettu muistiinpano",
  Position:"Sijainti",
  Job_Position:"Tehtävänimike",
  Select_a_Job:"Valitse työ",
  Select_a_Salary:"Valitse palkka",
  Add_a_Deduction:"Lisää vähennys",
  Taxes:"Verot",
  Add_Taxes:"Lisää veroja",
  Date_of_Birth:"Syntymäaika",
  Email_Address:"Sähköpostiosoite",
  Phone_Number:"Puhelinnumero",
  Phone_Call:"Puhelu",
  Enable_on_Payroll:"Ota käyttöön palkanlaskennassa",
  Select_Employees:"Valitse työntekijät",
  No_Employees:"Ei työntekijöitä",
  No_Employees_Word:"Lisää ensimmäinen uusi <a routerLink='/folder/Employee/New'>työntekijäsi</a>.",
  No_Name:"Ei nimeä",
  Unemployeed:"Työtön",
  Employeed:"Työssä",
  Paid:"Maksettu",
  Enabled:"Käytössä",
  Disabled:"Liikuntarajoitteinen",
  Fire:"Antaa potkut",
  Not_Available:"Ei saatavilla",
  Not_Available_Word:"Tulosta ensimmäinen <a routerLink='/folder/Invoice/New'>lasku</a> ja saat maksun.",
  Select_Invoices:"Select_Invoices",
  Print_Invoice_Word:"Tulosta ensimmäinen <a routerLink='/folder/Invoice/New'>lasku</a> ja saat maksun.",
  Invoice_Title:"Laskun otsikko",
  Invoice_Date:"Laskutus päivämäärä",
  Due_Date:"Eräpäivä",
  New_Job:"Uusi työ",
  Details:"Yksityiskohdat",
  Customize:"muokkaa",
  Customize_Dashboard:"Customize Dashboard",
  Components:"Komponentit",
  No_Components:"Ei komponentteja",
  Main_Components:"Pääkomponentit",
  Smaller_Components:"Pienemmät komponentit",
  Error_Loading_Page:"Virhe ladattaessa tätä sivua.",
  Bank_Details:"Pankkitiedot",
  About_Your_Job:"Tietoja työstäsi",
  Your_Schedule:"Sinun lukujärjestyksesi",
  Job_Title:"Työnimike",
  Job_Description:"Työnkuvaus",
  Job_Details:"Työn tiedot",
  Enable_Job:"Ota työ käyttöön",
  Pay_Period:"Maksukausi",
  Perpetually_Schedule:"Perpetually Schedule",
  Select_Jobs:"Valitse työt",
  No_Jobs:"Ei töitä",
  Add_Jobs:"Lisää työpaikkoja",
  No_Jobs_Word:"Lisää ensimmäinen <a routerLink='/folder/Job/New'>Työ</a> luetteloon.",
  Count_Employees:"job.employee_count+' Employees'",
  Zero_Employees:"0 työntekijää",
  New_Leave:"Uusi loma",
  Leave_Name:"Jätä nimi",
  Paid_Leave:"Palkallinen vapaa",
  Leave_Pay:"Jätä palkka",
  Indefinite_Leave:"Epämääräinen loma",
  Indefinite_Payroll:"Epämääräinen palkanlaskenta",
  Leave:"Jätä",
  Add_Schedules:"Lisää aikatauluja",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Ota poissaolo käyttöön",
  Select_Leaves:"Valitse lehdet",
  No_Leaves:"Ei poissaolon lehtiä",
  Leave_Of_Absence:"Virkavapaa",
  Leaves_Of_Absence:"Poissaolon lehdet",
  New_Leave_of_Absense:"Uusi poissaololoma",
  No_Leaves_Word:"Add the first <a routerLink='/folder/Leave/New'>Virkavapaa</a>.",
  Not_Enabled:"Ei käytössä",
  Absences:"Poissaolot",
  Payee:"Maksunsaaja",
  New_Payee:"Uusi maksunsaaja",
  Payee_Identifier:"Maksunsaajan tunniste",
  Payee_Name:"Maksajan nimi",
  Payee_Title:"Maksunsaajan nimike",
  Payment_Memo:"Maksumuistio",
  Select_Payees:"Valitse maksunsaajat",
  No_Payees:"Ei maksunsaajia",
  Add_Payee_Note:"Lisää ensimmäinen <a routerLink='/folder/Payee/New'>Maksunsaaja</a>.",
  New_Payees:"Uudet maksunsaajat",
  About_The_Payment_Schedule:"Maksuaikataulu",
  Your_Payroll_Schedule:"Automaattinen palkanlaskenta",
  New_Payment:"Uusi maksu",
  Identifier:"tunniste",
  Select:"Valitse",
  Memo:"Muistio",
  Payment_Date:"Maksupäivä",
  Mark_as_Paid:"Merkitse maksetuksi",
  Select_Payments:"Valitse maksut",
  No_Payments:"Ei maksuja",
  No_Payments_Word:"Luo ensimmäinen <a routerLink='/folder/Payment/New'>Maksu</a>.",
  Create_Payroll:"Luo palkkalista",
  Properties:"Ominaisuudet",
  Payroll_Title:"Palkanlaskentanimike",
  Payroll_Notes:"Payroll Notes",
  Payroll_Setup:"Palkanlaskenta",
  Tabulate_Payments:"Tabulaattimaksut",
  Tabulate:"Taulukoida",
  By:"Kirjoittaja:",
  Payments_By:"Maksut",
  Timesheets_And_Schedules:"Aikataulut ja aikataulut",
  Both:"Molemmat",
  Items:"Tuotteet",
  Add_Payees:"Lisää maksunsaajia",
  Add_Account:"Lisää tili",
  Enable_Account:"Ota tili käyttöön",
  Enable_Payroll:"Ota palkanlaskenta käyttöön",
  Print_Payroll:"Tulosta palkkalista",
  No_Payrolls:"Ei palkkaa",
  No_Payroll_Word:"Luo ensimmäinen <a routerLink='/folder/Payroll/New'>Palkanlaskenta</a>.",
  View_more:"KATSO LISÄÄ",
  Less:"VÄHEMMÄN",
  Add_Payroll:"Lisää palkkalista",
  Select_Payroll:"Valitse palkka",
  About_Your_Salary:"Tietoja palkastasi",
  Add_Salaries:"Lisää palkkoja",
  Add_Salary:"Lisää palkka",
  Salaries:"Palkat",
  No_Salaries:"Ei palkkoja",
  No_Salaries_Word:"Lisää ensimmäinen <a routerLink='/folder/Salary/New'>Palkka</a>.",
  Select_Salaries:"Valitse palkat",
  New_Salary:"Uusi palkka",
  Salary_Name:"Palkkatunniste",
  Enable_Salary:"Ota palkka käyttöön",
  Salary_Amount:"Palkan määrä",
  New_Schedule:"Uusi aikataulu",
  Schedule_Title:"Aikataulun otsikko",
  Add_Address:"Lisää osoite",
  Repeat:"Toistaa",
  Design:"Design",
  Edit_Design:"Muokkaa suunnittelua",
  Edit_this_Design:"Muokkaa tätä mallia",
  Repeat_Payment:"Toista maksu",
  Enable_Schedule:"Ota aikataulu käyttöön",
  Never:"Ei koskaan",
  Select_Schedule:"Valitse aikataulut",
  No_Schedules:"Ei aikatauluja",
  No_Schedules_Word:"Luo ensimmäinen <a routerLink='/folder/Schedule/New'>aikataulu</a>.",
  New_Administrator:"Uusi järjestelmänvalvoja",
  Username:"Käyttäjänimi",
  First_Name:"Etunimi",
  Last_Name:"Sukunimi",
  Add_an_Address:"Lisää osoite",
  Payment_Limit:"Per-Payment Limit",
  Total_Limit:"Kokonaisraja",
  Select_Accounts:"Valitse tilit",
  No_Administrators:"Ei järjestelmänvalvojia",
  No_Administrators_Word:"Luo ensimmäinen <a routerLink='/folder/Administrator/New'>järjestelmänvalvojan tili</a>.",
  New_Administrators_Word:"Lisää ensimmäinen <a routerLink='/folder/Administrator/New'>järjestelmänvalvoja</a>",
  Cloud:"Pilvi",
  Backup:"Varmuuskopioida",
  Enable_iCloud:"Ota iCloud käyttöön",
  Enable_Google_Drive:"Ota Google Drive käyttöön",
  Enable_Microsoft_OneDrive:"Ota Microsoft OneDrive käyttöön",
  Automatically_Backup:"Varmuuskopioi automaattisesti",
  FTP_Settings:"FTP-asetukset",
  URL_File_Prompt:"Määritä tuotavan .xls / .xlsx / .json-tiedoston sijainti:",
  URL_SQL_Prompt:"Määritä tuotavan SQLite-tiedoston sijainti:",
  FTP_Backup:"FTP-varmuuskopio",
  FTP_Import:"FTP-tuonti",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"isäntä",
  Port:"Portti",
  Path:"Polku",
  Data_Path:"Tieto polku",
  Enable_FTP_Account:"Ota FTP-tili käyttöön",
  HostnameIP:"isäntänimi",
  Password:"Salasana",
  Connection_Port:"Yhteysportti",
  Enable_MySQL_Database:"Ota MySQL-tietokanta käyttöön",
  Log:"Hirsi",
  Reset:"Palauta",
  Erase:"Poista",
  Export:"Viedä",
  Database:"Tietokanta",
  Upload_CSV:"Lataa CSV",
  Download_CSV:"Lataa CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL-varmuuskopio",
  Internal_Notes:"Sisäiset huomautukset",
  Root_Path:"Juuripolku",
  Select_Backup:"Valitse varmuuskopio",
  Add_New_Backup:"Lisää uusi varmuuskopio",
  First_Backup:"Asenna ensimmäinen varmuuskopio...",
  Backups:"Varmuuskopiot",
  Add_Backup:"Lisää varmuuskopio",
  No_Backups:"Varmuuskopioita ei löydy.",
  Select_Backup_Type:"Valitse varmuuskopion tyyppi, jonka haluat määrittää...",
  Backup_Type:"Varmuuskopiotyyppi",
  FTP_Drive:"FTP-asema",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Ajaa",
  Microsoft_OneDrive:"Ajaa",
  Import_Fields:"Tuontikentät",
  Import_Fields_Word:"Valitse tästä osiosta <a routerLink='/folder/Holiday/New'>Tuo</a> tiedot.",
  Upload:"Lataa",
  Download:"Ladata",
  Download_JSON:"Lataa JSON-datana",
  Download_SQL:"Lataa SQL-tiedostona",
  New_Bank:"Uusi pankki",
  New_Account:"Uusi tili",
  New_Bank_Account:"Uusi pankkitili",
  Upload_Image:"Lataa kuva",
  Bank_Name:"Pankin nimi",
  Bank_Address:"Pankkiosoite",
  Branch_Address:"Haaraliikkeen osoite",
  Address_on_Cheque:"Osoite",
  Cheque_Numbers:"Tarkista numerot",
  Cheque_Details:"Tarkista tiedot",
  Bank_Logo:"Pankin logo",
  Cheque_ID:"Tarkista tunnus",
  Starting_Cheque_ID:"Starting Check ID",
  Transit_Number:"Kauttakulkunumero",
  Institution_Number:"laitoksen numero",
  Designation_Number:"nimitysnumero",
  Account_Number:"Tilinumero",
  Limits:"Rajat",
  Default_Limits:"Oletusrajat",
  Over_Limit:"Yli rajan",
  Under_Limit:"Rajassa",
  Payroll_Limit:"Payroll Limit",
  Enable_Bank_Account:"Ota pankkitili käyttöön",
  Select_Account:"Valitse tili",
  No_Bank_Account:"Ei pankkitiliä",
  No_Bank_Account_Word:"Lisää ensimmäinen <a routerLink='/folder/Accounts/New'>pankkitili</a>.",
  Bank_Accounts:"Pankkitilit",
  No_Accounts:"Ei tilejä",
  No_Accounts_Note:"Lisää ensimmäinen <a routerLink='/folder/Accounts/New'>pankkitili</a>.",
  Cheque_Designer:"Check Designer",
  Cheque_Design:"Tarkista suunnittelu",
  Select_Design:"Valitse malli",
  Cheque_Designs:"Tarkista mallit",
  No_Cheque_Designs:"Ei tarkastusmalleja",
  No_Cheque_Designs_Word:"Luo oma <a routerLink='/folder/Settings/Cheque/Design/New'>Tarkista suunnittelu</a>.",
  Set_Default:"Oletusasetuksena",
  Default:"Oletus",
  Remove:"Poista",
  Folder:"Kansio",
  Edit:"Muokata",
  LoadingDots:"Ladataan...",
  Add_a_New_File:"Lisää <a href='#' (click)='add()'>Uusi tiedosto</a>",
  this_folder:"tämä kansio",
  FTP_Backup_Settings:"FTP-varmuuskopiointiasetukset",
  Secure_File_Transfer:"Suojattu tiedostonsiirto",
  New_Holiday:"Uusi loma",
  Add_Holiday:"Lisää loma",
  Holiday_Name:"Lomapäivän nimi",
  Additional_Pay:"Lisämaksu",
  Enable_Holiday:"Ota loma käyttöön",
  Select_Holidays:"Valitse lomat",
  No_Holidays:"Ei lomaa",
  No_Holidays_Word:"Lisää ensimmäinen <a routerLink='/folder/Holiday/New'>julkinen juhlapäivä</a>.",
  New_Import:"Uusi tuonti",
  Import_Data:"Tuo tiedot",
  Import_Data_Word:"Valitse valitsemasi tiedostotyyppi tai lataustapa.<br /> Voit valita tiedoston tuodut kentät, jotka vastaavat mitä tahansa sovelluksen parametria, kun olet ladannut tuetun tiedoston.",
  Import_File:"Tuo tiedosto",
  Link_To_File:"Linkki tiedostoon",
  Select_File:"Valitse tiedosto",
  New_Moderator:"Uusi moderaattori",
  Allow_Moderation:"Salli moderointi",
  Create_Paycheques:"Luo palkkasekit",
  Edit_Paycheques_and_Data:"Muokkaa palkkatietoja ja tietoja",
  Destroy_Data_and_Paycheques:"Tuhoa tiedot ja palkat",
  Bonus_Percentage:"Paycheck Percentage",
  Fixed_Amount:"Kiinteä summa",
  Select_Moderator:"Valitse moderaattori",
  No_Moderators:"Ei moderaattoreita",
  Moderators:"Moderaattorit",
  Moderator_Account:"Luo ensimmäinen <a routerLink='/folder/Administrator/New'>moderaattoritili</a>.",
  No_Moderators_Word:"Lisää ensimmäinen <a routerLink='/folder/Administrator/New'>moderaattori</a>.",
  Defaults:"Oletukset",
  Provide_Us_Info:"Anna meille tietoja",
  Setup_Your_Printer:"Asenna tulostimesi",
  Your_Company:"Tietoja yrityksestäsi",
  Company_Name:"Yrityksen nimi",
  Currency:"valuutta",
  Default_Currency:"Oletusvaluutta",
  Base_Monthly_Income:"Kuukausitulot",
  Protection:"Suojaus",
  App_Protection:"Sovellussuojaus",
  PIN_Code_Protection:"PIN-koodin suojaus",
  App_Protection_Word:"Ota käyttöön suojausmenetelmät, jotka auttavat suojaamaan tiliäsi.",
  PIN_Code:"Pin-koodi",
  Change_PIN:"Vaihda PIN-koodi",
  New_Password:"Uusi salasana",
  Geofence_Protection:"Geo-aidan suojaus",
  Geofence_Area:"Aseta alue",
  Distance:"Etäisyys",
  Setup_Now:"Asenna nyt",
  Mile:"Mile",
  Km:"Km",
  m:"m",
  Facial_Recognition:"kasvojen tunnistus",
  Face:"Kasvot",
  Setup:"Perustaa",
  Label:"Etiketti",
  Password_Protection:"Salasanan suojaus",
  Modify_Password:"Muokkaa salasanaa",
  New_Tax_Entry:"Uusi verokirja",
  New_Tax:"Uusi vero",
  Tax_Label:"Veromerkki",
  Perpetually_Enabled:"Perpetually Enabled",
  Select_Taxes:"Valitse verot",
  Tax_Deductions:"Verovähennykset",
  No_Tax_Deductions:"Ei verovähennyksiä",
  No_Tax_Deductions_Word:"Lisää ensimmäinen <a routerLink='/folder/Tax/New'>Verottaa</a> -vähennys.",
  New_Timer:"Uusi ajastin",
  Start:"Alkaa",
  Stop:"Lopettaa",
  Start_Timer:"Aloita ajastin",
  Stop_Timer:"Pysäytä ajastin",
  Timer_Active:"Ajastin aktiivinen",
  Timer:"Ajastin:",
  Timer_Running:"Ajastin: (Juoksu)",
  Save_Timer:"Tallenna ajastin",
  New_Timesheet:"Uusi aikataulukko",
  Select_Timesheets:"Valitse aikataulukot",
  Work_Notes:"Työmuistiinpanot",
  Entry_Title:"Syötteen otsikko",
  No_Timesheets:"Ei työaikaloppuja",
  No_Timesheets_Word:"Lisää ensimmäinen <a routerLink='/folder/Timesheet/New'>aikataulukko</a>.",
  Timesheet_Submitted:"Time Sheet Submitted",
  Timesheet_Congrats:"Onnittelut! Aikataulukkosi on lähetetty onnistuneesti. Kiitos!",
  Timesheet_Copy:"Jotta saat kopion asiakirjoistasi, anna meille sähköpostiosoitteesi ja/tai matkapuhelinnumerosi.",
  Submit:"Lähetä",
  Allow_Notifications:"Salli ilmoitukset",
  Untitled_Entry:"Uusi merkintä",
  Untitled_Bank:"Nimetön pankki",
  Timesheet_Entry:"Timeen merkintä",
  Work_Description:"Työn kuvaus",
  Enable_Timesheet:"Ota aikataulukko käyttöön",
  Submit_Timesheet:"Lähetä aikalomake",
  Vacation:"Loma",
  New_Vacation:"Uusi loma",
  Vacation_Name:"Loman nimi",
  Paid_Vacation:"Maksettu loma",
  Vacation_Pay:"Lomapalkka",
  Enable_Vacation:"Ota loma käyttöön",
  Select_Vacations:"Valitse lomat",
  No_Vacations:"Ei lomaa",
  No_Vacations_Word:"Luo ensimmäinen <a routerLink='/folder/Vacation/New'>Loma</a>-merkintä.",
  Payroll_Schedule:"Palkanlaskentataulukko",
  Next_Payroll:"Seuraava palkkalista:",
  Upcoming_Payroll:"Tuleva palkanlaskenta",
  No_Upcoming_Payroll:"Ei tulevaa palkkaa",
  Address_Book:"Osoitekirja",
  Archived_Documents:"Arkistoidut asiakirjat",
  Dev_Mode:"Sovellus kehitystilassa",
  Administrators:"Järjestelmänvalvojat",
  Privacy:"Yksityisyys",
  None:"Ei mitään",
  Select_Signature:"Valitse allekirjoitus",
  No_Signatures:"Ei allekirjoituksia",
  No_Signatures_Word:"Lisää ensimmäinen <a routerLink='/folder/Signature/New'>allekirjoitus</a>.",
  Repeat_Indefinitely:"Toista loputtomasti",
  Sign:"Merkki",
  Sign_Here:"Allekirjoita tähän",
  Date_Signed:"Allekirjoituspäivämäärä",
  Signature_Pad:"Signature Pad",
  Account_Holder:"Tilin haltija",
  Account_Properties:"Tilin ominaisuudet",
  Name_On_Cheque:"Nimi shekissä",
  Server_Hostname:"Palvelimen isäntänimi / IP",
  Printers:"Tulostimet",
  No_Printers:"Ei tulostimia",
  Printer_Exists:"Tämänniminen tulostin on jo olemassa.",
  No_Printers_Word:"Lisää ensimmäinen <a routerLink='/folder/Printer/New'>tulostin</a>.",
  No_Printer_Alert:"Tulostinta ei ole määritetty. Haluatko määrittää tulostimen?",
  Add_Printer:"Lisää tulostin",
  New_Printer:"Uusi tulostin",
  Printer_Hostname:"Tulostimen isäntänimi / IP",
  Printer_Label:"Tulostimen etiketti",
  Printer_Protocol:"Printer Protocol",
  Protocol:"protokolla",
  Email_Print:"Sähköposti",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Pistorasia",
  Print_Job:"Tulostustyö",
  Printed:"Painettu",
  Not_Printed:"Ei painettu",
  Print_Jobs:"Tulostustyöt",
  Print_Queue:"Tulostusjono",
  No_Print_Jobs:"Ei tulostustöitä",
  No_Prints:"Luo uusi <a routerLink='/folder/Cheque/New'>sekki</a> tulostettavaksi.",
  Prints:"Tulosteet",
  Find_Printer:"Etsi tulostin",
  Find_AirPrint_Devices:"Etsi AirPrint-laitteita",
  Select_Printer:"Valitse tulostin",
  System_UI:"Järjestelmän käyttöliittymä",
  Printer:"Tulostin",
  Status:"Tila",
  Preview:"Esikatselu",
  Enable_Print_Job:"Ota tulostustyö käyttöön",
  Queue_Weight:"Jonon paino",
  Unlimited:"Rajoittamaton",
  Show_Advanced_Printer_Options:"Näytä tulostimen lisäasetukset",
  Advanced:"Pitkälle kehittynyt",
  Location:"Sijainti",
  Note:"Merkintä",
  Queue_Name:"Jonon nimi",
  Pages_Printed_Limit:"Pages Printed Limit",
  MultiPage_Idle_Time:"Multipage Wait Time (s)",
  Page_Count_Limit:"Page Count Limit",
  Page_Orientation:"Sivun suunta",
  Portrait:"Muotokuva",
  Landscape:"Maisema",
  Duplex:"Kaksipuolinen",
  Double_Sided_Printing:"Kaksipuolinen",
  Duplex_Mode:"Kaksipuolinen tila",
  Duplex_Short:"Lyhyt",
  Duplex_Long:"pitkä",
  Duplex_None:"Ei mitään",
  Color_And_Quality:"Väri ja laatu",
  Monochrome:"Yksivärinen",
  Photo_Quality_Prints:"Valokuvalaatuiset tulosteet",
  Printer_Email:"Tulostimen sähköposti",
  Automatically_Downsize:"Pienennä kokoa automaattisesti",
  Paper:"Paperi",
  Paper_Name:"Paperin nimi",
  Paper_Width:"Paperin leveys",
  Paper_Height:"Paperin korkeus",
  Paper_Autocut_Length:"Paperin automaattisen leikkauksen pituus",
  Margins:"Marginaalit",
  Page_Margins:"Sivun marginaalit",
  Page_Margin_Top:"Sivun ylämarginaali",
  Page_Margin_Right:"Oikea sivun marginaali",
  Page_Margin_Bottom:"Bottom Page Margin",
  Page_Margin_Left:"Vasen sivun marginaali",
  Add_Employees:"Lisää työntekijöitä",
  Header:"Otsikko",
  Print_A_Page_Header:"Tulosta sivun otsikko",
  Header_Label:"Otsikkomerkki",
  Header_Page_Index:"Otsikkosivuhakemisto",
  Header_Font_Name:"Otsikkofontti",
  Select_Font:"Valitse fontti",
  Font_Selector:"Fontin valitsin",
  Header_Font_Size:"Otsikkofontti",
  Header_Bold:"Header Bold",
  Header_Italic:"Header Italic",
  Header_Alignment:"Otsikon kohdistus",
  Left:"vasen",
  Center:"Keskusta",
  Right:"Oikein",
  Justified:"Oikeutettu",
  Header_Font_Color:"Otsikon väri",
  Select_Color:"Valitse väri",
  Footer:"Alatunniste",
  Print_A_Page_Footer:"Tulosta sivun alatunniste",
  Footer_Label:"Alatunniste",
  Footer_Page_Index:"Alatunnistesivuhakemisto",
  Footer_Font_Name:"Footer Font",
  Fonts:"Fontit",
  Done:"Tehty",
  Select_Fonts:"Valitse kirjasimet",
  Footer_Font_Size:"alatunnisteen koko",
  Footer_Bold:"Footer Bold",
  Footer_Italic:"Footer Italic",
  Footer_Alignment:"Alatunnisteen kohdistus",
  Footer_Font_Color:"Alatunnisteen väri",
  Page_Copies:"Sivun kopiot",
  Enable_Printer:"Ota tulostin käyttöön",
  Remote_Logging:"Etälokikirjaus",
  Log_Server:"Lokipalvelin",
  Encryption:"Salaus",
  Random_Key:"Satunnainen avain",
  Encryption_Key:"Salausavain",
  Cheques_Webserver:"Muokattu tietokanta",
  Learn_How:"Opi kuinka",
  Signature:"Allekirjoitus",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Näytä allekirjoitus",
  Printed_Signature:"Painettu allekirjoitus",
  Digitally_Sign:"Allekirjoita digitaalisesti",
  Digital_Signature:"Digitaalinen allekirjoitus",
  Add_Signature:"Lisää allekirjoitus",
  Add_Your_Signature:"Lisää allekirjoituksesi",
  Enable_Signature:"Ota allekirjoitus käyttöön",
  Digitally_Signed:"Digitaalisesti allekirjoitettu",
  Insert_Error:"Sekkiä ei voida tallentaa tietokantaongelmien vuoksi.",
  Delete_Confirmation:"Haluatko varmasti poistaa nämä tiedot?",
  Discard_Confirmation:"Haluatko varmasti hylätä nämä tiedot?",
  Discard_Bank_Confirmation:"Haluatko varmasti hylätä tämän tilin?",
  Discard_Printer_Confirmation:"Haluatko varmasti hävittää tämän tulostimen?",
  Delete_Bonus_Confirmation:"Haluatko varmasti poistaa tämän bonuksen?",
  Delete_Invoice_Confirmation:"Haluatko varmasti poistaa tämän laskun?",
  Generated_Cheque:"Luotu sekki",
  Generated_Invoice:"Luotu lasku",
  Schedule_Start_Time:"Aloitusaikataulu",
  Schedule_End_Time:"Aikataulu päättyy",
  New_Call:"Uusi kutsu",
  No_Contacts:"Ei yhteystietoja",
  No_Contacts_Word:"Järjestelmänvalvojat, valvojat, työntekijät ja maksunsaajat näkyvät yhteyshenkilöinä.",
  PDF_Subject:"rahoitus",
  PDF_Keywords:"palkanlaskennan palkkasekki PDF-sekit",
  Printer_Setup:"Tulostimen asetukset",
  Printer_Selection:"Tulostimen valinta",
  New_Fax:"Uusi faksi",
  New_Fax_Message:"Uusi faksiviesti",
  Fax_Machine:"Faksi",
  Fax_Name:"Faksin nimi",
  Fax_Email:"Faksi sähköposti",
  Fax_Number:"Faksinumero",
  Contents:"Sisällys",
  Fax_Body:"Sivun runko",
  Header_Word:"Otsikko:",
  Header_Text:"Otsikkoteksti",
  Include_Header:"Sisällytä otsikko",
  Include_Footer:"Sisällytä alatunniste",
  Footer_Word:"Alatunniste:",
  Footer_Text:"Alatunnisteteksti",
  Attach_a_Cheque:"Liitä sekki",
  Add_Deduction:"Lisää vähennys",
  Enable_Fax:"Lähetä faksi",
  Select_Fax:"Valitse faksi",
  No_Faxes:"Ei fakseja",
  Faxes:"Faksit",
  Save_and_Send:"Lähetä faksi",
  Save_and_Pay:"Tallenna ja maksa",
  WARNING:"VAROITUS:",
  Remember:"Muistaa",
  Printing:"tulostus",
  Printing_Complete:"Tulostus valmis!\n\n",
  of:"of",
  There_Were:"Siellä oli ",
  Successful_Prints:"menestyneitä tulosteita ja",
  Unsuccessful_Prints:"epäonnistuneet tulosteet.",
  PrinterError:"Anteeksi! Tapahtui virhe.",
  Printing_:"Tulostetaan...",
  PrinterSuccess:"Asiakirja tulostettu onnistuneesti.",
  PrintersSuccess:"Asiakirjat tulostettu onnistuneesti.",
  Print_Cheques:"Tulosta sekit",
  New_Message:"Uusi viesti",
  New_Messages:"Uusia viestejä",
  Read_Message:"Lue viesti",
  Write_Message:"Kirjoita viesti",
  Send_Message:"Lähetä viesti",
  Subject:"Aihe",
  Message:"Viesti",
  Writing:"Kirjoittaminen...",
  Send:"Lähettää",
  Set_Date:"Aseta päivä",
  Set_Time:"Asettaa aika",
  Recieve:"vastaanottaa",
  Set_as_Default:"Oletusasetuksena",
  Default_Account:"Oletustili",
  Default_Design:"Oletusmuotoilu",
  Multiple_Cheques:"Sekit (Tripple)",
  Account_Mode:"Tilitila",
  Multiple_Cheques_Description:"Kolme shekkiä sivua kohden.",
  Check_and_Table:"Check & Table",
  Check_including_Table:"Tarkistus- ja kirjanpitotaulukko.",
  Check_Mailer:"Check Mailer",
  Check_Mailer_Window:"Tarkista osoiteikkunalla.",
  DocuGard_Table_Top:"DocuGard Check & Table (Yläosa)",
  DocuGard_Table_Middle:"DocuGard Check & Table (Middle)",
  DocuGard_Table_Bottom:"DocuGard Check & Table (alhaalla)",
  DocuGard_Mailer_Top:"DocuGard Check Mailer (Yläosa)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (Middle)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mailer (Bottom)",
  DocuGard_Three_Cheques:"DocuGard Checks (Tripple)",
  DocuGard_Cheque_Top:"DocuGard Check (Yläosa)",
  DocuGard_Cheque_Middle:"DocuGard Check (Middle)",
  DocuGard_Cheque_Bottom:"DocuGard Check (alhaalla)",
  DocuGard_Three_Cheques_Window:"Kolme shekkiä yhdellä sivulla.",
  DocuGard_Table_Top_Window:"Sekki ja tulostaulukko.",
  DocuGard_Table_Middle_Window:"Sekki ja tulostaulukko.",
  DocuGard_Table_Bottom_Window:"Sekki ja tulostaulukko.",
  DocuGard_Mailer_Top_Window:"Tarkista, taulukko ja osoite.",
  DocuGard_Mailer_Middle_Window:"Tarkista, taulukko ja osoite.",
  DocuGard_Mailer_Bottom_Window:"Tarkista, taulukko ja osoite.",
  DocuGard_Cheque_Top_Window:"Tarkista turvallinen paperi.",
  DocuGard_Cheque_Middle_Window:"Tarkista turvallinen paperi.",
  DocuGard_Cheque_Bottom_Window:"Tarkista turvallinen paperi.",
  Basic_Cheque:"Check (Yläosa)",
  Basic_Cheque_Print:"Tulosta yksi sekki.",
  Error_Setting_As_Paid:"Virhe määritettäessä maksetuksi",
  Add_Attachment:"Lisää LIITE",
  PrinterUnavailable:"Tulostin ei saatavilla",
  CreditCardComponent:"Kortit",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Uusi talletus",
  Deposits:"Talletukset",
  No_Deposits:"Ei talletuksia",
  Credit_Card_Deposit:"Luottokortti",
  New_Credit_Card_Deposit_Message:"Luottokorttitalletus",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin-talletus",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Interac Transfer",
  Payments_Limit:"Maksuraja",
  No_Payment_Limit:"Ei maksurajaa",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal-talletus",
  No_Deposits_Word:"Lisää ensimmäinen yrityksen <a routerLink='/folder/Deposit/New'>talletus</a>.",
  No_Documents_Specified:"Ei asiakirjoja määritetty tulostettavaksi",
  No_Printers_Added:"Tulostimia ei löytynyt. Lisää yksi valitsemalla Asetukset > Tulostimet.",
  DB_Erase_Prompt:"Poista koko tietokanta kokonaan? VAROITUS: Menetät kaikki tallennetut tiedot!",
  Console_Warning:"Älä liitä tekstiä tähän konsoliin. Saatat asettaa itsesi ja/tai yrityksesi vakavaan vaaraan.",
  No_Faxes_Word:"Luo ensimmäinen <a routerLink='/folder/Fax/New'>faksi</a>.",
  Cheque_Delete_Confirmation:"Haluatko varmasti poistaa tämän sekin?",
  Design_Delete_Confirmation:"Haluatko varmasti poistaa tämän mallin?",
  Cheque_Pay_Confirmation:"Merkitse tämä sekki maksetuksi? Se EI näy tulostusjonossa.",
  Payment_Pay_Confirmation:"Merkitse tämä maksu maksetuksi? Se EI näy sekkijonossa.",
  Delete_Deduction_Confirmation:"Haluatko varmasti poistaa tämän vähennyksen?",
  Delete_Job_Confirmation:"Haluatko varmasti poistaa tämän työn?",
  Delete_Timesheet_Confirmation:"Haluatko varmasti poistaa tämän aikaraportin?",
  Delete_Schedule_Confirmation:"Haluatko varmasti poistaa tämän aikataulun?",
  Delete_Setting_Confirmation:"Haluatko varmasti nollata tämän asetuksen?",
  Delete_Fax_Confirmation:"Haluatko varmasti poistaa tämän faksin?",
  Delete_File_Confirmation:"Haluatko varmasti poistaa tämän tiedoston?",
  Delete_Vacation_Confirmation:"Haluatko varmasti poistaa tämän loman?",
  Delete_Printer_Confirmation:"Haluatko varmasti poistaa tämän tulostimen?",
  Remove_Design_Confirmation:"Haluatko varmasti poistaa tämän mallin?",
  Delete_Payroll_Confirmation:"Haluatko varmasti poistaa tämän palkkalistan?",
  Send_Fax_Email_Confirmation:"Haluatko faksata ja lähettää sähköpostilla nämä asiakirjat?",
  Send_Email_Confirmation:"Haluatko lähettää tämän asiakirjan sähköpostitse?",
  Send_Fax_Confirmation:"Haluatko faksata tämän asiakirjan?",
  Error_Generating_PDF:"Anteeksi. Tämän asiakirjan luomisessa tapahtui virhe.",
  PDF_Error_Saving_Image:"Anteeksi. Tämän asiakirjan PDF-kuvan tallentamisessa tapahtui virhe.",
  Test_Printer_Confirmation:"Haluatko tulostaa testisivun tällä tulostimella?",
  Save_Timesheet_Prompt:"Lisää nimike tai paina Aloita ajastin tallentaaksesi.",
  Remove_Geofence_Prompt:"Haluatko varmasti poistaa tämän geoaidan sijainnin?",
  Delete_Employee_Confirmation:"Haluatko varmasti poistaa ",
  Fire_Employee_Confirmation:"Haluatko varmasti ampua"
}