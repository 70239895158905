export const Bn = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"কপিরাইট &কপি; 2023",
  black:"কালো",
  green:"সবুজ",
  gold:"সোনা",
  blue:"নীল",
  brown:"বাদামী",
  purple:"বেগুনি",
  pink:"গোলাপী",
  red:"লাল",
  Swatches:"স্য্যাচস",
  HSL:"এইচএসএল",
  RGB:"আরজিবি",
  Hue:"হিউ",
  Saturation:"স্যাচুরেশন",
  Lightness:"হালকাতা",
  Upgrade_To_Pro:"প্রো তে উন্নত করা",
  AllFeaturesInclude:"সমস্ত বৈশিষ্ট্য অন্তর্ভুক্ত:",
  PrintUnlimitedCheques:"আনলিমিটেড চেক প্রিন্ট করুন",
  PremiumChequeDesigns:"প্রিমিয়াম চেক ডিজাইন",
  ManageUnlimitedEmployees:"সীমাহীন কর্মচারীদের পরিচালনা করুন",
  AddUnlimitedPayees:"আনলিমিটেড পেই যোগ করুন",
  CreateUnlimitedPayrolls:"আনলিমিটেড পেরোল তৈরি করুন",
  UnlimitedSchedulesandTimeSheets:"সীমাহীন সময়সূচী এবং সময় পত্রক",
  BulkPayPalPayouts:"বাল্ক পেপ্যাল পেআউট",
  UnlimitedBankAccounts:"আনলিমিটেড ব্যাঙ্ক অ্যাকাউন্ট",
  ManageMultipleCompanies:"একাধিক কোম্পানি পরিচালনা করুন",
  TrackInsurancePolicies:"ট্র্যাক বীমা নীতি",
  Bio_MetricProtection:"বায়ো-মেট্রিক সুরক্ষা",
  Geo_FenceLock_OutProtection:"জিও-বেড়া লক-আউট সুরক্ষা",
  Multiple_Companies_Word:"চেক প্রিমিয়াম ছাড়া একাধিক কোম্পানি পরিচালনা করা যায় না।",
  PayPal_Payouts_Word:"PayPal পেমেন্ট চেক প্রিমিয়াম ছাড়া নিষ্ক্রিয় করা হয়.",
  PINProtection:"পিন সুরক্ষা",
  PasswordProtection:"পাসওয়ার্ড সুরক্ষা",
  May_Require_Approval:"অনুমোদনের প্রয়োজন হতে পারে।",
  Subscribe:"সাবস্ক্রাইব",
  Billed:"বিল করা হয়েছে",
  Up:"উপরে",
  Down:"নিচে",
  Positioning:"পজিশনিং",
  Marker:"মার্কার",
  Drag_Marker:"মার্কার টেনে আনুন",
  Tagline:"প্রিন্ট চেক এবং ট্যাবুলেট বেতন",
  Marker_Word:"উপাদানের আকার দিতে মার্কার ব্যবহার করুন।",
  Pinch_Zoom:"চিমটি জুম",
  Pinch_Word:"উপাদান জুম করতে চিমটি.",
  Drag:"টেনে আনুন",
  Drag_Word:"উপাদান টেনে আনতে আপনার আঙুল ব্যবহার করুন.",
  subscription_alias_word:"অটো-রিনিউয়িং সাবস্ক্রিপশন",
  premium_alias_word:"এককালীন আপগ্রেড প্যাকেজ",
  print_alias_word:"প্রিন্ট-আউট ব্যক্তিগত চেক",
  mode_alias_word:"মোড",
  Pro:"প্রো",
  Pro_word:"প্রো সংস্করণ প্রয়োজন.",
  Cant_Delete_Default_Company_Word:"দুঃখিত, আপনি আপনার ডিফল্ট কোম্পানি মুছে ফেলতে পারবেন না .",
  Reinsert_Default_Designs:"ডিফল্ট ডিজাইন পুনরায় সন্নিবেশ করান",
  Reinsert_Default_Designs_word:"আপনি কি ডিফল্ট ডিজাইন পুনরায় সন্নিবেশ করতে চান?",
  Subscription_Active_Disable_Word:"এই সদস্যতা সক্রিয়. আপনি কি চেকের এই সদস্যতা বাতিল করতে চান?",
  Company_Logo:"কোম্পানী লোগো",
  ZERO_:"শূন্য",
  Disclaimer:"দাবিত্যাগ",
  Privacy_Policy:"গোপনীয়তা নীতি",
  EULA:"EULA চেক করে",
  Terms_Of_Service:"সেবা পাবার শর্ত",
  Terms_Of_Use:"ব্যবহারের শর্তাবলী",
  Refunds:"প্রত্যর্পণ নীতি",
  Single_Print:"1 চেক",
  Two_Prints:"2 চেক",
  Five_Prints:"5 চেক",
  Ten_Prints:"10 চেক",
  Fifteen_Prints:"15 চেক",
  Twenty_Prints:"20 চেক",
  Thirty_Prints:"30 চেক",
  Image_Added:"ইমেজ যোগ করা হয়েছে",
  Image_Preview:"ছবির পূর্বরূপ",
  No_Image_Was_Selected:"কোন ছবি নির্বাচন করা হয়নি.",
  Cheques_Unlimited:"চেক আনলিমিটেড",
  _Subscription:"সাবস্ক্রিপশন",
  Subscription:"চেক - 1 মাস",
  Two_Month_Subscription:"চেক - 2 মাস",
  Three_Month_Subscription:"চেক - 3 মাস",
  Six_Month_Subscription:"চেক - 6 মাস",
  Twelve_Month_Subscription:"চেক - 12 মাস",
  Cheques_Are_Available:"চেক প্রিন্ট করার জন্য উপলব্ধ.",
  Upgrade_Required_Two:"একটি প্যাকেজ নির্বাচন করুন এবং আপনার কেনাকাটা শুরু করতে মূল্য বোতামে ডবল-ট্যাপ করুন৷ সেকেন্ডের মধ্যে পেশাদার চেহারার ফুল-কালার চেক প্রিন্ট করুন।",
  Month:"মাস",
  Due:"বাকি:",
  Expires:"মেয়াদ শেষ:",
  Subscription_Active:"সদস্যতা সক্রিয়",
  Subscription_Inactive:"সদস্যতা নিষ্ক্রিয়",
  Purchase_Additional_Cheques:"অতিরিক্ত চেক কিনবেন?",
  Printable_Cheque:"মুদ্রণযোগ্য চেক",
  Printable_Cheques:"মুদ্রণযোগ্য চেক",
  Printable_Cheque_Word:"চেক আপনার অ্যাকাউন্টে উপলব্ধ।",
  Printable_Cheques_Word:"চেক আপনার অ্যাকাউন্টে উপলব্ধ।",
  Max_Amount_Message:"আপনি যে পরিমাণ নির্দিষ্ট করেছেন তা এই সিস্টেমের জন্য নির্ধারিত সর্বাধিক পরিমাণে পৌঁছেছে:",
  Terms_Required_Word:"চেক ব্যবহার করা চালিয়ে যাওয়ার আগে আপনাকে অবশ্যই এই চুক্তিতে সম্মত হতে হবে।",
  Subscriptions:"সদস্যতা",
  Product_Upgrades:"আপগ্রেড",
  Mailed_Out_Cheques:"মেল আউট চেক",
  Enter_A_Company_Name:"একটি কোম্পানির নাম লিখুন.",
  Single_Cheques:"একক চেক",
  Cheque_Golden:"গোল্ডেন চেক",
  Cheque_Golden_Window:"গোল্ডেন চেক ডিজাইন।",
  Cheque_Green:"সবুজ চেক",
  Cheque_Green_Window:"সবুজ চেক নকশা.",
  Cheque_Red:"লাল চেক",
  Cheque_Red_Window:"লাল চেক ডিজাইন।",
  Cheque_Yellow:"হলুদ চেক",
  Cheque_Yellow_Window:"হলুদ চেক ডিজাইন।",
  Cheque_Statue_of_Liberty:"স্ট্যাচু অফ লিবার্টি",
  Cheque_Statue_of_Liberty_Window:"স্ট্যাচু অফ লিবার্টি চেক ডিজাইন।",
  Cheque_Green_Wave:"সবুজ ঢেউ",
  Cheque_Green_Wave_Window:"সবুজ চেক নকশা.",
  Cheque_Golden_Weave:"গোল্ডেন উইভ",
  Cheque_Golden_Weave_Window:"মার্জিত সোনালী চেক নকশা.",
  Cheque_Green_Sun:"সবুজ সূর্য",
  Cheque_Green_Sun_Window:"গভীর এবং শান্ত চেক নকশা.",
  Cheque_Blue_Checkers:"নীল চেকার",
  Cheque_Blue_Checkers_Window:"নীল চেক ডিজাইন।",
  Cashiers_Check:"ক্যাশিয়ার চেক",
  Cashiers_Check_Window:"ক্যাশিয়ার চেক শৈলী টেমপ্লেট.",
  Cheque_Aqua_Checkers:"অ্যাকোয়া চেকার",
  Cheque_Aqua_Checkers_Window:"অ্যাকোয়া চেক ডিজাইন।",
  Cheque_Golden_Checkers:"গোল্ডেন চেকারস",
  Cheque_Golden_Checkers_Window:"গোল্ডেন চেক ডিজাইন।",
  Upgrade_Unavailable:"আপগ্রেডগুলি অনুপলব্ধ৷",
  Bank_Code_Protection:"ব্যাঙ্ক নম্বর সুরক্ষা",
  Bank_Code_Protection_Word:"অন্য ডিভাইসে বা অন্য ব্যবহারকারীর জন্য চলমান এই অ্যাপে ব্যবহার করা থেকে আপনার ব্যাঙ্ক নম্বরগুলিকে রক্ষা করুন। এই ক্রিয়াটি অপরিবর্তনীয়। চালিয়ে যান?",
  Bank_Code_Protection_Blocked_Word:"আপনি যে ব্যাঙ্ক নম্বরগুলি ব্যবহার করার চেষ্টা করছেন তা অন্য ব্যবহারকারী বা ডিভাইসের জন্য সংরক্ষিত৷",
  Bank_Code_Protection_Error_Word:"নম্বর যাচাই ব্যর্থ হয়েছে. অনুগ্রহ করে ইন্টারনেটের সাথে সংযোগ করুন এবং এই ব্যাঙ্ক অ্যাকাউন্টটি আবার যোগ করার চেষ্টা করুন৷",
  Bank_Code_Protection_Set_Error_Word:"ব্যাঙ্ক নম্বর সুরক্ষার জন্য আপনাকে ইন্টারনেটের সাথে সংযুক্ত থাকতে হবে। অনুগ্রহ করে সংযোগ করুন এবং আবার চেষ্টা করুন।",
  Upgrade_Unavailable_Word:"দুঃখিত, আপনাকে যাচাই করতে আমাদের সমস্যা হচ্ছে৷ সদস্যতা এবং চেকের আপগ্রেডগুলি বর্তমানে আপনার এলাকায় কেনার জন্য অনুপলব্ধ৷",
  PayPal_Payment_Preview:"পেপ্যাল পেমেন্ট প্রিভিউ",
  Apple_Pay:"অ্যাপল পে",
  Select_PayPal:"পেপ্যাল নির্বাচন করুন",
  PayPal_Applications:"পেপ্যাল অ্যাপ্লিকেশন",
  Purchase_With_Apple_Pay:"অ্যাপল পে দিয়ে কিনুন",
  Google_Pay:"Google Pay",
  Companies:"কোম্পানিগুলো",
  Insurance:"বীমা",
  New_PayPal:"নতুন পেপ্যাল",
  Pay_By:"দ্বারা বেতন",
  Insure:"বীমা করুন",
  Miles:"মাইলস",
  Payment_Method:"মূল্যপরিশোধ পদ্ধতি",
  Select_Policies:"নীতি নির্বাচন করুন",
  Policies:"নীতিমালা",
  Policy:"নীতি",
  No_PayPal_Account:"পেপ্যাল অ্যাকাউন্ট নেই",
  No_Policies:"কোন বীমা নীতি",
  New_Policy:"নতুন নীতি",
  PayPal_Payment:"পেপ্যাল পেমেন্ট",
  PayPal_Payments:"পেপ্যাল পেমেন্ট",
  No_Payment_Selected:"কোন পেমেন্ট নির্বাচন করা হয়নি",
  Sending_Payment_Word:"অনুগ্রহ করে অপেক্ষা করুন... এই অর্থপ্রদান পাঠানো হচ্ছে।",
  Sending_Payments_Word:"অনুগ্রহ করে অপেক্ষা করুন... পেমেন্ট পাঠানো হচ্ছে।",
  No_Payment_Selected_PayPal:"কোনো <a routerLink='/folder/Payments'>PayPal পেমেন্ট</a> বেছে নেওয়া হয়নি।",
  Payment_Sent:"পেমেন্ট পাঠানো",
  PayPal_Payment_Sent:"এই পেমেন্ট পেপ্যাল দিয়ে পাঠানো হয়েছে.",
  Copay:"কপি",
  Dead:"মৃত",
  Alive:"জীবিত",
  Not_Dead:"মরে নাই",
  Unclaimed:"দাবিহীন",
  Attempted:"চেষ্টা করেছে",
  Deceased:"মৃত",
  Claimed:"দাবি করেছে",
  Unpaid:"অবৈতনিক",
  Sending_Payment:"পেমেন্ট পাঠানো হচ্ছে",
  Sending_Payments:"পেমেন্ট পাঠানো হচ্ছে",
  Send_PayPal_Confirmation:"আপনি কি পেপ্যালের সাথে এই লেনদেনটি পাঠাতে চান?",
  Send_PayPal_Confirmation_Word:"এই লেনদেন পাঠাতে সবুজ বোতাম টিপুন।",
  Save_Payment_As_Unpaid:"এই পেমেন্টটি অবৈতনিক হিসাবে সংরক্ষণ করবেন?",
  Payment_Pay_Confirmation_PayPal:"পেমেন্ট হিসাবে এই পেমেন্ট সংরক্ষণ?",
  No_Policies_Word:"প্রথম <a routerLink='/folder/Postage/New'>বীমা নীতি</a> যোগ করুন ।",
  Timesheet_Multiple_Delete_Confirmation:"আপনি একাধিক টাইমশীট মুছে ফেলার বিষয়ে নিশ্চিত?",
  Select_Multiple_Timesheets_Prompt:"কোনো টাইমশিট নির্বাচন করা হয়নি। অন্তত একটি টাইমশীট নির্বাচন করুন.",
  Select_Multiple_Policies_Prompt:"কোনো নীতি নির্বাচন করা হয়নি. অন্তত একটি বীমা পলিসি নির্বাচন করুন.",
  Policies_Multiple_Delete_Confirmation:"আপনি একাধিক নীতি মুছে ফেলার বিষয়ে নিশ্চিত?",
  Company:"প্রতিষ্ঠান",
  Add_Company:"কোম্পানি যোগ করুন",
  New_Company:"কোম্পানি যোগ করুন",
  No_Companies:"কোন কোম্পানি নেই",
  Enable_Company:"কোম্পানি সক্ষম করুন",
  Select_Company:"কোম্পানি নির্বাচন করুন",
  The_Default_Company:"ডিফল্ট কোম্পানি লেবেল.",
  Manage_Companies:"কোম্পানি পরিচালনা করুন",
  No_Companies_Word:"কোম্পানি ব্যবহার করবে ৷<br /> <a routerLink='/folder/Company/New'>প্রথম কোম্পানি</a> যোগ করুন ৷",
  Default_Company:"ডিফল্ট কোম্পানি",
  Cheques_Unlimited_Word:"চেক আনলিমিটেড আপনাকে যত খুশি তত চেক প্রিন্ট করতে দেয়।",
  Cheques_Subscription_Word:"একটি চেক সাবস্ক্রিপশন আপনাকে যত খুশি তত চেক প্রিন্ট করতে দেয়।",
  You_Own_This_Product:"আপনি এই পণ্যের মালিক.",
  Your_Subscription_is_Active:"আপনার সদস্যতা সক্রিয় আছে.",
  Active_Products:"সক্রিয় পণ্য",
  Purchase_Confirmation:"ক্রয়",
  Purchase_Cheques:"ক্রয় চেক",
  Restore_Purchase:"কেনাকাটা পুনরুদ্ধার করুন",
  Erase_Purchase:"ক্রয় মুছে ফেলুন",
  Successfully_Purchased:"সফলভাবে কেনা হয়েছে",
  Enter_Your_Licence_Key:"এই পণ্য সক্রিয় করতে এই পৃষ্ঠায় আপনার লাইসেন্স কী লিখুন.",
  Licence_Key:"লাইসেন্স কী",
  Enter_Licence_Key:"লাইসেন্স কী লিখুন",
  Purchased:"ক্রয় করা হয়েছে",
  Enable_Feature:"বৈশিষ্ট্য সক্রিয় করুন",
  Cancel_Subscription:"সদস্যতা বাতিল করুন",
  Subscription_Removed:"সদস্যতা সরানো হয়েছে৷",
  Select_Active_Subscription:"এটি সংশোধন করার জন্য একটি সক্রিয় সদস্যতা নির্বাচন করুন.",
  Remove_Subscription_Word:"আপনি কি নিশ্চিত আপনি এই সদস্যতা বাতিল করতে চান?",
  Delete_Company_Confirmation:"আপনি কি নিশ্চিত আপনি এই সম্পূর্ণ কোম্পানি মুছে ফেলতে চান? সতর্কতা: আপনি সমস্ত সঞ্চিত তথ্য হারাবেন!",
  Delete_Default_Company_Word:"আপনি ডিফল্ট কোম্পানি মুছে ফেলতে পারবেন না . আপনি কি অ্যাপ্লিকেশানটি পুনরায় সেট করতে চান এবং এটিকে ডিফল্ট অবস্থায় পুনরুদ্ধার করতে চান? সতর্কতা: আপনি সমস্ত সঞ্চিত তথ্য হারাবেন!",
  Console_Warning_2:"এই অ্যাপ্লিকেশন ব্যবহার করে কোনো মুদ্রা পরিচালনা করবেন না যা বর্তমানে আপনার নয়।",
  Terms_and_Conditions:"শর্তাবলী",
  and_the:"এবং",
  for:"জন্য",
  Please_Read_Word:"অনুগ্রহ করে পড়ুন এবং সম্মত হন",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"কিছু মুদ্রা রূপান্তর হার খুঁজে পাওয়া যায়নি. ইন্টারনেটে সংযোগ করুন.",
  Free:"বিনামূল্যে",
  DB_Erase_Prompt_2:"সম্পূর্ণরূপে সম্পূর্ণ বিকাশকারী ডাটাবেস মুছে ফেলুন? সতর্কতা: আপনি সমস্ত ক্রয় এবং সদস্যতা তথ্য হারাবেন!",
  Successfully_Imported:"সফলভাবে আমদানি করা হয়েছে৷",
  Select_Postage:"ডাক নির্বাচন করুন",
  No_Postage:"কোনো ডাকটিকিট নেই",
  No_Paid_Postage_Word:"প্রথম <a routerLink='/folder/Postage/New'>প্রদেয় পোস্টেজ</a> স্ট্যাম্প যোগ করুন ।",
  Trial_Complete:'ট্রায়াল সম্পূর্ণ',
  Please_Upgrade:'মুদ্রণ চালিয়ে যেতে চেক আপগ্রেড করুন.',
  Aa:"আআ",
  Color:"রঙ",
  Font:"হরফ",
  Guide:"গাইড",
  Guides:"গাইড",
  Image:"ছবি",
  Zoom:"জুম",
  Logo:"লোগো",
  Bank:"ব্যাংক",
  MICR:"MICR",
  Total:"মোট",
  Cancel:"বাতিল করুন",
  Confirm:"নিশ্চিত করুন",
  Method:"পদ্ধতি",
  Biometric_Security:"বায়োমেট্রিক নিরাপত্তা",
  Please_Login_To_Continue:"অবিরত লগ ইন করুন.",
  Complete:"সম্পূর্ণ",
  Sign_Up:"নিবন্ধন করুন",
  Error:"ত্রুটি",
  Biometrics:"বায়োমেট্রিক্স",
  Percent:"শতাংশ",
  Zero_Percent:"0%",
  Top_Margin:"শীর্ষ মার্জিন",
  Bottom_Margin:"নিচের মার্জিন",
  Left_Margin:"বাম মার্জিন",
  Right_Margin:"ডান মার্জিন",
  MICR_Margin:"MICR মার্জিন",
  Table_Margin:"টেবিল মার্জিন",
  Address_Margin:"ঠিকানা মার্জিন",
  Percentage_:"শতাংশ",
  Vacation_Title:"ছুটির শিরোনাম",
  Use_PIN:"পিন ব্যবহার করুন",
  Loading__:"লোড হচ্ছে...",
  Design_Title:"ডিজাইন শিরোনাম",
  Authorize:"অনুমোদন করা",
  Key:"চাবি",
  Public_Key:"সর্বজনীন কী",
  Private_Key:"ব্যক্তিগত কী",
  Authenticate:"প্রমাণীকরণ",
  Last_Payroll:"শেষ বেতন",
  Last_Calculation:"শেষ হিসাব",
  Authorized:"অনুমোদিত",
  Geo_Authorized:"ভূ-অবস্থান: অনুমোদিত",
  Not_Authorized:"অনুমোদিত না",
  Authorization_Complete:"অনুমোদন সম্পূর্ণ",
  Geolocation_Authorization:"ভূ-অবস্থান অনুমোদন",
  Out_of_Bounds:"সীমার বাইরে",
  Cant_Delete_Default_Design:"একটি ডিফল্ট নকশা মুছে ফেলা যাবে না.",
  Unauthorized_Out_of_Bounds:"অননুমোদিত: সীমার বাইরে",
  Biometric_Authorization:"বায়োমেট্রিক অনুমোদন",
  Locating_Please_Wait:"লোকেটিং করা হচ্ছে, অনুগ্রহ করে অপেক্ষা করুন...",
  Test_Biometrics:"বায়োমেট্রিক্স পরীক্ষা করুন",
  Cheque_Margins:"মার্জিন চেক করুন",
  Percentage_Full_Error:"শতাংশ ক্ষেত্র 100% শতাংশের বেশি সেট করা যাবে না।",
  No_Payroll_Text:"একটি <a routerLink='/folder/Employee/New'>কর্মচারী</a> অথবা <a routerLink='/folder/Payee/New'>প্রদানকারী</a> এবং একটি <a routerLink='/folder/Schedule/New'>সূচি</a>।",
  Design_Saved:"নকশা সংরক্ষিত",
  Default_Design_Selected:"ডিফল্ট ডিজাইন নির্বাচিত",
  Partial_Import:"আংশিক আমদানি",
  Partial_Export:"আংশিক রপ্তানি",
  Entire_Import:"সম্পূর্ণ আমদানি",
  Entire_Export:"সম্পূর্ণ রপ্তানি",
  Existing_Password:"অনুগ্রহ করে আপনার বিদ্যমান পাসওয়ার্ড লিখুন:",
  Existing_PIN:"অনুগ্রহ করে আপনার বিদ্যমান পিন কোড লিখুন:",
  Pin_Change_Header:"পিন নিশ্চিতকরণ",
  Pin_Change_SubHeader:"পরিবর্তন নিশ্চিত করতে আপনার পুরানো পিন নম্বর লিখুন।",
  Pass_Change_Header:"পাসওয়ার্ড নিশ্চিতকরণ",
  Pass_Change_SubHeader:"পরিবর্তন নিশ্চিত করতে আপনার পুরানো পাসওয়ার্ড লিখুন.",
  PIN_Enter_Message:"নিশ্চিত করতে আপনার পিন লিখুন।",
  Password_Enter_Message:"নিশ্চিত করতে আপনার পাসওয়ার্ড লিখুন.",
  Pin_Updated_Success:"পিন সফলভাবে আপডেট হয়েছে!",
  Pin_Updated_Fail:"পিন আপডেট করা যায়নি।",
  Pass_Updated_Success:"পাসওয়ার্ড সফলভাবে আপডেট করা হয়েছে।",
  Pass_Updated_Fail:"পাসওয়ার্ড আপডেট করা যায়নি.",
  Preview_Payment:"প্রিভিউ পেমেন্ট",
  Preview_Payroll:"প্রাকদর্শন বেতন",
  No_Payments_Created:"কোনো পেমেন্ট তৈরি করা পাওয়া যায়নি.",
  Payment_Preview:"পেমেন্ট প্রিভিউ",
  Enable_Payee:"প্রাপক সক্ষম করুন",
  Rendered:"অনুষ্ঠিত",
  No_Email:"কোনো ইমেল নেই",
  Setup_Cheques:"সেটআপ চেক",
  name:"নাম",
  address:"ঠিকানা",
  address_2:"ঠিকানা লাইন ২",
  city:"শহর",
  province:"প্রদেশ",
  postal_code:"পোস্টকোড / জিপকোড",
  country:"দেশ",
  username:"ব্যবহারকারীর নাম",
  full_name:"পুরো নাম",
  birthday:"জন্মদিন",
  email:"ইমেইল",
  phone:"ফোন",
  employees:"কর্মচারীরা",
  addresses:"ঠিকানা",
  payment_amount_limit:"অর্থপ্রদানের পরিমাণ সীমা",
  total_limit:"মোট সীমা",
  payees:"বেতনভোগী",
  description:"বর্ণনা",
  address_line_one:"ঠিকানা লাইন এক",
  address_line_two:"ঠিকানা লাইন দুই",
  image:"ছবি",
  account_holder:"হিসাব ধারী",
  cheque_starting_id:"স্টার্টিং আইডি চেক করুন",
  transit_number:"ট্রানজিট নম্বর",
  institution_number:"প্রতিষ্ঠান নম্বর",
  designation_number:"পদবী নম্বর",
  account_number:"হিসাব নাম্বার",
  currency:"মুদ্রা",
  signature:"স্বাক্ষর",
  payment_payroll_limit:"অর্থপ্রদানের সীমা",
  total_limi:"মোট সীমা",
  date:"তারিখ",
  printed_memo:"প্রিন্টেড মেমো",
  banks:"ব্যাঙ্ক",
  signature_image:"স্বাক্ষর ইমেজ",
  address_name:"ঠিকানার নাম",
  notes:"মন্তব্য",
  design:"ডিজাইন",
  title:"শিরোনাম",
  frequency:"ফ্রিকোয়েন্সি",
  fax:"ফ্যাক্স",
  salaries:"বেতন",
  salary_ids:"বেতন আইডি",
  start_time:"সময় শুরু",
  end_time:"শেষ সময়",
  paid:"পেড",
  overtime_percentage:"প্রদত্ত শতাংশ",
  overtime_amount:"পরিশোধিত নির্দিষ্ট পরিমাণ",
  first_name:"নামের প্রথম অংশ",
  last_name:"নামের শেষাংশ",
  moderation:"সংযম",
  create:"সৃষ্টি",
  edit:"সম্পাদনা করুন",
  destroy:"ধ্বংস",
  day_start_time:"দিনের_শুরু_সময়",
  day_end_time:"দিন_শেষ_সময়",
  fullname:"নাম",
  time:"সময়",
  auto_send:"স্বয়ংক্রিয়ভাবে পাঠান",
  time_method:"সময় পদ্ধতি",
  schedules:"সময়সূচী",
  indefinite_payroll_enabled:"অনির্দিষ্টকালের জন্য সক্ষম করুন",
  amount:"পরিমাণ",
  repeat:"পুনরাবৃত্তি করুন",
  always_enabled:"সর্বদা সক্রিয়",
  start_date:"শুরুর তারিখ",
  end_date:"শেষ তারিখ",
  Cheque_Total:"মোট চেক করুন",
  Total_Amount:"সর্বমোট পরিমাণ:",
  Amounts:"পরিমাণ:",
  Images:"ছবি",
  Files:"নথি পত্র",
  Previewing:"পূর্বরূপ:",
  Insert:"ঢোকান",
  Preview_Import:"পূর্বরূপ আমদানি",
  Entry:"প্রবেশ",
  Entries:"এন্ট্রি",
  No_Entries:"নো এন্ট্রি",
  Import_Type:"আমদানি প্রকার",
  Select_Details:"বিশদ নির্বাচন করুন",
  Select_Payee:"Payee নির্বাচন করুন",
  Enable_Holidays:"ছুটির দিনগুলি সক্ষম করুন৷",
  Disable_Holidays:"ছুটির দিনগুলি অক্ষম করুন",
  Wire_Transfer:"ওয়্যার ট্রান্সফার",
  New_Export:"নতুন রপ্তানি",
  Export_Data:"রপ্তানি তথ্য",
  Export_Data_Word:"রপ্তানি এবং ডাউনলোড করার জন্য ফাইলের ধরন নির্বাচন করুন।",
  Export_File:"ফাইল রপ্তানি করুন",
  Mode:"মোড",
  Times:"বার",
  Widgets:"উইজেট",
  Slider:"স্লাইডার",
  Set_Details:"বিস্তারিত সেট করুন",
  Select_Type:"প্রকার নির্বাচন করুন",
  Display_Slider:"ডিসপ্লে স্লাইডার",
  Dashboard_Slider:"ড্যাশবোর্ড স্লাইডার",
  Dashboard_Mode:"ড্যাশবোর্ড মোড",
  Show_Intro:"ভূমিকা দেখান",
  Show_Payrolls:"বেতন দেখান",
  Show_Holidays:"ছুটির দিন দেখান",
  Show_Invoices:"চালান দেখান",
  Show_Cheques:"চেক দেখান",
  Enabled_Widgets:"সক্রিয় উইজেট",
  Disabled_Widgets:"নিষ্ক্রিয় উইজেট",
  Colors:"রং",
  Barcodes:"বারকোড",
  View_Timers:"টাইমার দেখুন",
  Gradients:"গ্রেডিয়েন্ট",
  No_Info:"কোন তথ্য নেই",
  Disable:"নিষ্ক্রিয় করুন",
  Show_Layer:"স্তরগুলি দেখান",
  Hide_Layer:"স্তর লুকান",
  Text_Layer:"টেক্সট লেয়ার",
  Secondly:"দ্বিতীয়ত",
  Minutely:"মিনিটে",
  nine_am:"সকাল 9 ঃ 00",
  five_pm:"বিকাল 5 ঃ 00 টা",
  Enable_Address:"ঠিকানা সক্ষম করুন",
  Invalid_File_Type:"দুঃখিত, একটি অবৈধ ফাইল প্রকার নির্বাচন করা হয়েছে. সমর্থিত ফাইল প্রকার:",
  Error_Updating_Entry:"দুঃখিত, এই এন্ট্রি আপডেট করার সময় একটি ত্রুটি ছিল৷",
  Schedule_Timing_Alert:"ত্রুটি: সময়সূচী শুরুর সময় শেষ হওয়ার পরে একটি মান সেট করা হয়েছে৷",
  Select_Multiple_Payments_Prompt:"কোন পেমেন্ট নির্বাচন করা হয়নি. অন্তত একটি পেমেন্ট নির্বাচন করুন.",
  Select_Multiple_Cheques_Prompt:"কোন চেক নির্বাচন করা হয়নি. অন্তত একটি চেক নির্বাচন করুন.",
  Select_Multiple_Items_Prompt:"কোন আইটেম নির্বাচন করা হয়নি. অন্তত একটি আইটেম নির্বাচন করুন.",
  Paymemt_Multiple_Delete_Confirmation:"আপনি একাধিক অর্থপ্রদান মুছে ফেলার বিষয়ে নিশ্চিত?",
  Cheque_Multiple_Delete_Confirmation:"আপনি একাধিক চেক মুছে ফেলার বিষয়ে নিশ্চিত?",
  Payee_Multiple_Delete_Confirmation:"আপনি কি নিশ্চিত যে আপনি একাধিক প্রাপক মুছে ফেলতে চান?",
  Employee_Multiple_Delete_Confirmation:"আপনি একাধিক কর্মচারী মুছে ফেলার বিষয়ে নিশ্চিত?",
  MICR_Warning:"দ্রষ্টব্য: কিছু প্রিন্টার এবং ডিভাইস MICR ফন্ট সঠিকভাবে প্রদর্শন নাও করতে পারে।",
  Automatically_Send:"স্বয়ংক্রিয়ভাবে পাঠান",
  Type:"টাইপ",
  Payment_Type:"শোধের ধরণ",
  Auto_Send:"স্বয়ংক্রিয় পাঠান",
  Automatically_Process:"স্বয়ংক্রিয়ভাবে প্রক্রিয়া",
  Auto_Process:"অটো প্রসেস",
  Image_Based:"ছবি ভিত্তিক",
  Font_Based:"হরফ-ভিত্তিক",
  Rerender:"পুনরায় রেন্ডার করুন",
  Rendering:"রেন্ডারিং",
  Render:"নথি পত্র",
  Top:"শীর্ষ",
  Middle:"মধ্য",
  Bottom:"নীচে",
  Top_Left:"উপরে বাঁদিকে",
  Top_Center:"শীর্ষ কেন্দ্র",
  Top_Right:"উপরের ডানে",
  Middle_Left:"মধ্য বাম",
  Middle_Center:"মধ্যম কেন্দ্র",
  Middle_Right:"মধ্য ডান",
  Bottom_Left:"নিচে বামে",
  Bottom_Center:"নীচে কেন্দ্র",
  Bottom_Right:"নিচের ডানে",
  Numbers:"সংখ্যা",
  Verified:"যাচাই",
  Paper_Size:"কাগজের আকার",
  New_Device:"নতুন ডিভাইস",
  Add_Device:"যন্ত্র সংযুক্ত করুন",
  Remove_Device:"ডিভাইস অপসারণ",
  Delete_Device:"ডিভাইস মুছুন",
  Block_Device:"ডিভাইস ব্লক করুন",
  Block:"ব্লক",
  Unblock:"আনব্লক করুন",
  Table:"টেবিল",
  Scan_Login_Code:"লগইন কোড স্ক্যান করুন",
  Login_Code:"লগইন কোড",
  Scan_Code:"স্ক্যান কোড",
  Scan_QR_Code:"QR কোড স্ক্যান করুন",
  Select_All:"সব নির্বাচন করুন",
  Deselect_All:"সব গুলো অনির্বাচিত কর",
  Increase:"বৃদ্ধি",
  Decrease:"হ্রাস",
  Bold:"সাহসী",
  Text:"পাঠ্য",
  Style:"শৈলী",
  Italic:"তির্যক",
  QR_Code:"QR কোড",
  Barcode:"বারকোড",
  Browse_Images:"ইমেজ ব্রাউজ করুন",
  Browse_Files:"ফাইল ব্রাউজ",
  Background_Image:"ব্যাকগ্রাউন্ড ইমেজ",
  Logo_Image:"প্রতীক ছবি",
  Header_Image:"ম্ন ব",
  Bank_Image:"ব্যাঙ্কের ছবি",
  Cut_Lines:"লাইন কাটা",
  Background:"পটভূমি",
  License:"লাইসেন্স",
  One_License:"1 লাইসেন্স:",
  Licensed:"জন্যে অনুমতিপ্রাপ্ত:",
  Not_Licensed:"লাইসেন্সপ্রাপ্ত নয়",
  Enter_Serial:"সিরিয়াল লিখুন",
  Serial_Key:"সিরিয়াল কী",
  Serial:"সিরিয়াল",
  Product_Key:"পণ্য কী",
  Check_Product_Key:"পণ্য কী চেক করুন",
  Add_Product_Key:"পণ্য কী যোগ করুন",
  Verifying_Purchase:"ক্রয় যাচাই করা হচ্ছে...",
  Print_Envelope:"প্রিন্ট খাম",
  Envelope:"খাম",
  Thank_You:"ধন্যবাদ!",
  Scale:"স্কেল",
  Print_Scale:"প্রিন্ট স্কেল",
  Borders:"সীমানা",
  VOID:"অকার্যকর",
  Void_Cheque:"অকার্যকর চেক",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"এর অর্ডারে অর্থ প্রদান করুন:",
  NO_DOLLARS:"কোন ডলার নেই ",
  ONE_DOLLAR:"এক ডলার",
  DOLLARS:" ডলার",
  AND:" এবং ",
  CENTS:" সেন্ট",
  NO_:"না ",
  ONE_:"এক ",
  AND_NO_:"এবং না ",
  _AND_ONE_:"এবং এক ",
  DOLLARS_AND_ONE_CENT:" এবং এক সেন্ট।",
  AND_NO_CENTS:" এবং শূন্য সেন্ট।",
  CHEQUE_PRINT_DATE:"তারিখ:",
  CHEQUE_PRINT_MEMO:"মেমো:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"এমপি",
  Initial_Tasks:"প্রাথমিক কাজ",
  Inital_Setup:"প্রাথমিক সেটআপ",
  Welcome_To:"স্বাগতম ",
  Welcome:"স্বাগত",
  Swipe:"সোয়াইপ",
  Intro_Setup:"ইন্ট্রো সেটআপ",
  Introduction:"পরিচয়",
  CHEQUE_PRINT_CREDIT:"চেক দ্বারা চালিত",
  Title_Intro_Word:"চেকগুলিতে স্বাগতম",
  Title_Intro:"চেক পরিচিতি",
  Title_Setup:"চেক সেটআপ",
  PayPal_Default_Email_Message:"আপনি একটি নতুন পেপ্যাল ​​স্থানান্তর পেয়েছেন৷ [চেক দ্বারা পরিচালিত]",
  Cheques_App_Export:"চেক দ্বারা রপ্তানি করা হয়েছে",
  Post_Intro_Thanks:"চেক নির্বাচন করার জন্য আপনাকে ধন্যবাদ। সেটআপ প্রক্রিয়া এখন সম্পূর্ণ হয়েছে।",
  Post_Intro_Word:"আপনার প্রথম চেক প্রিন্ট করার মাধ্যমে শুরু করুন, ভবিষ্যতের অর্থপ্রদান যোগ করুন বা বেতনের সাথে একজন কর্মচারী যোগ করুন।",
  Upgrade_Required:"এই বার্তাটি লুকিয়ে রাখতে এবং অতিরিক্ত বৈশিষ্ট্যগুলি আনলক করতে চেকের জন্য আপনাকে সফ্টওয়্যারটির আরও প্রিমিয়াম সংস্করণের মালিক হতে হবে।",
  Upgrade_Title:"চেক",
  Mailout_Prompt:"আপনি অতিরিক্তভাবে আপনার বেতনের চেকগুলি আপনার জন্য চেক মেল-আউট করা বেছে নিতে পারেন।",
  Mailed_Cheque:"মেইল করা চেক: ",
  Mailed_Cheque_Color:"মেইল করা চেক (রঙ): ",
  Terms_Purchase:"চেক সহ সমস্ত ইলেকট্রনিক কেনাকাটা ক্রয়ের তারিখ থেকে 30-দিন পর্যন্ত সম্পূর্ণরূপে ফেরতযোগ্য। চালিয়ে যাওয়ার আগে অনুগ্রহ করে <a href='#'>নিয়ম ও শর্তাবলী</a> পড়ুন এবং সম্মত হন।",
  Dashboard_Setup:"প্রাথমিক প্রিন্টার সেটআপ করুন",
  Dashboard_Add:"প্রাথমিক ব্যাঙ্ক অ্যাকাউন্ট যোগ করুন",
  Dashboard_Customize:"একটি চেক টেমপ্লেট নির্বাচন করুন",
  Dashboard_Job_Salary:"আপনার কাজ তৈরি করুন এবং আপনার বেতন যোগ করুন",
  Dashboard_Employees:"কর্মচারি এবং বেতনভোগীদের ট্র্যাক করুন",
  Dashboard_Print:"আপনার পেচেকগুলি প্রিন্ট করুন এবং মেল করুন",
  Dashboard_Payroll:"আপনার বেতনের মুদ্রণ স্বয়ংক্রিয় করুন",
  Dashboard_PayPal:"পেপ্যাল ​​বেতন/পেআউট সেটআপ করুন",
  Begin_Setup:"সেটআপ শুরু করুন",
  Get_Started:"এবার শুরু করা যাক",
  Purchase:"ক্রয়",
  Lockdown:"লকডাউন",
  Unlock:"আনলক",
  Detailed:"বিশদ",
  Log_In:"প্রবেশ করুন",
  Login:"প্রবেশ করুন",
  Launch:"শুরু করা",
  Register:"নিবন্ধন",
  Finish:"সমাপ্ত",
  List:"তালিকা",
  Weekends:"সপ্তাহান্ত",
  Weekly:"সাপ্তাহিক",
  PayPal_Default_Subject:"নতুন অর্থপ্রদান",
  Payment_Message:"পেমেন্ট মেসেজ",
  PayPal_Default_Payment_Note:"ধন্যবাদ",
  Setup_Your_Cheqing_Account:"চেকিং অ্যাকাউন্ট",
  Add_Your_Primary_Cheqing_Account:"আপনার প্রাথমিক চেকিং অ্যাকাউন্ট যোগ করুন।",
  Welcome_Word:"পে-রোল এবং মানব সম্পদ ব্যবস্থাপনাকে সরলীকরণ এবং স্বয়ংক্রিয় করুন।",
  Get_Started_Quickly:"শুধু কয়েকটি সহজ প্রশ্নের উত্তর দিন যা আমাদের শুরু করতে সাহায্য করবে...",
  Done_Intro_Word:"সেটআপ সম্পূর্ণ",
  PIN_Protected:"পাসওয়ার্ড এবং পিন সুরক্ষিত",
  Enter_New_PIN:"একটি নতুন পিন কোড লিখুন:",
  Enter_PIN:"পিন কোড লিখুন:",
  Invalid_PIN:"অবৈধ PIN প্রবেশ করানো হয়েছে।",
  Account_Identifier:"অ্যাকাউন্ট শনাক্তকারী",
  New_Account_Identifier:"নতুন অ্যাকাউন্ট শনাক্তকারী",
  attempt:"প্রচেষ্টা",
  attempts:"প্রচেষ্টা",
  remaining:"অবশিষ্ট",
  Language:"ভাষা",
  languages:"ভাষা",
  select_languages:"ভাষা নির্বাচন কর",
  Deposit:"আমানত",
  Hire_One_Now:"এখনই একজনকে ভাড়া করুন",
  App_Locked:"অ্যাপ্লিকেশনটি লক করা হয়েছে।",
  Skip_:"এড়িয়ে যান",
  Skip_to_Dashboard:"ড্যাশবোর্ডে যান",
  Dashboard:"ড্যাশবোর্ড",
  Import:"আমদানি",
  Admin:"প্রশাসক",
  New_Admin:"নতুন অ্যাডমিন",
  Settings:"সেটিংস",
  Color_Picker:"রঙ বাছাইকারী",
  Font_Picker:"ফন্ট পিকার",
  Logout:"প্রস্থান",
  Close:"বন্ধ",
  Close_menu:"বন্ধ",
  Excel:"এক্সেল ফাইল",
  Csv:"CSV ফাইল",
  Sql:"এসকিউএল ফাইল",
  Json:"JSON ফাইল",
  Url:"ইউআরএল দ্বারা আমদানি",
  Back:"পেছনে",
  Timers:"টাইমার",
  Cheque:"চেক",
  Print:"ছাপা",
  Designs:"ডিজাইন",
  Pause_Printing:"পজ প্রিন্টিং",
  Resume_Printing:"পুনরায় মুদ্রণ শুরু করুন",
  Printing_Paused:"মুদ্রণ থামানো হয়েছে",
  PrintingUnavailable:"দুঃখিত! এই সিস্টেমে মুদ্রণ অনুপলব্ধ।",
  Prints_Paused:"প্রিন্ট পজ করা হয়েছে",
  Administration:"প্রশাসন",
  Loading:"লোড হচ্ছে",
  Unnamed:"নামহীন",
  error:"দুঃখিত, এই চেকটি দেখার জন্য খোলা যায়নি।",
  No_Cheques_To_Print:"প্রিন্ট করার জন্য কোন চেক নেই",
  No_Cheques_To_Print_Word:"একটি <a routerLink='/folder/Cheque/New'>নতুন চেক</a> তৈরি করুন।",
  New_Cheque:"নতুন চেক",
  Start_One_Now:"এখনই শুরু করুন",
  Edit_Cheque:"চেক সম্পাদনা করুন",
  Select_Cheques:"চেক নির্বাচন করুন",
  Select_Employee:"কর্মচারী নির্বাচন করুন",
  Default_Printer:"ডিফল্ট প্রিন্টার",
  Reassign:"পুনরায় বরাদ্দ করা",
  Configure:"সজ্জিত করা",
  Template:"টেমপ্লেট",
  Designer:"নকশাকার",
  Edit_Designs:"ডিজাইন সম্পাদনা করুন",
  New_Design:"নতুন নকশা",
  Next:"পরবর্তী",
  Save:"সংরক্ষণ",
  Name:"নাম",
  Mail:"মেইল",
  Amount:"পরিমাণ",
  Date:"তারিখ",
  PayPal:"পেপ্যাল",
  Payouts:"পেআউট",
  PayPal_Label:"পেপ্যাল ​​লেবেল",
  Email_Username:"ইমেল / ব্যবহারকারীর নাম",
  Client_ID:"ক্লায়েন্ট আইডি",
  Sandbox_Email:"স্যান্ডবক্স ইমেল",
  PayPal_Email:"PayPal এর ইমেইল",
  PayPal_Username:"API ব্যবহারকারীর নাম",
  PayPal_Payouts:"পেপাল পেআউট",
  Select_PayPal_Key:"পেপ্যাল ​​কী নির্বাচন করুন",
  Secret:"গোপন",
  API_Secret:"এপিআই সিক্রেট",
  PayPal_API_Keys:"পেপ্যাল ​​কী",
  New_PayPal_Key:"নতুন পেপ্যাল ​​কী",
  Email_Subject:"ইমেইল বিষয়",
  Email_Message:"ইমেল পত্র",
  Payout_Options:"পেআউট বিকল্প",
  Payment_Note:"পেমেন্ট নোট",
  Enable_Employee:"কর্মচারী সক্ষম করুন",
  Enable_Production_Mode:"উৎপাদন মোড সক্ষম করুন",
  Sandbox_Username:"স্যান্ডবক্স ব্যবহারকারীর নাম",
  Sandbox_Signature:"স্যান্ডবক্স স্বাক্ষর",
  Sandbox_Password:"স্যান্ডবক্স পাসওয়ার্ড",
  Production_Username:"উৎপাদনের ব্যবহারকারীর নাম",
  Production_Signature:"উৎপাদন স্বাক্ষর",
  Production_Password:"উৎপাদন পাসওয়ার্ড",
  Production_Email:"উৎপাদন ইমেল",
  API_Client_ID:"API ক্লায়েন্ট আইডি",
  API_Signature:"এপিআই স্বাক্ষর",
  API_Password:"API পাসওয়ার্ড",
  API_Username:"API ব্যবহারকারীর নাম",
  Secret_Key:"গোপন চাবি",
  Sandbox:"স্যান্ডবক্স",
  Production:"উৎপাদন",
  Sandbox_Keys:"স্যান্ডবক্স কী",
  Production_Keys:"উৎপাদন কী",
  API_Title:"এপিআই শিরোনাম",
  Production_Mode:"উৎপাদন মোড",
  Account_Label:"অ্যাকাউন্ট লেবেল",
  No_PayPal_Setup:"কোন পেপ্যাল ​​কী নেই",
  Enable_PayPal_Account:"পেপ্যাল ​​অ্যাকাউন্ট সক্ষম করুন",
  No_PayPal_Word:"আপনার <a routerLink='/folder/Invoice/New'>PayPal API কী</a> যোগ করুন।",
  Printed_Memo:"প্রিন্টেড মেমো",
  Employee:"কর্মচারী",
  View_Employee:"কর্মচারী দেখুন",
  Mailing_Address:"চিঠি পাঠানোর ঠিকানা",
  Company_Address:"প্রতিস্থান এর ঠিকানা",
  Select_Company_Address:"কোম্পানির ঠিকানা নির্বাচন করুন",
  Address:"ঠিকানা",
  Any_Day:"যে কোন দিন",
  Address_Name:"ঠিকানার নাম",
  Unit:"ইউনিট",
  Account:"অ্যাকাউন্ট",
  Bank_Account:"ব্যাংক হিসাব",
  Account_Limits:"অ্যাকাউন্ট সীমা সক্ষম করুন",
  Payroll:"বেতন",
  New_Payroll:"নতুন বেতন",
  No_Payroll:"কোন আসন্ন বেতন নেই",
  Upcoming_Holiday:"আসন্ন ছুটির দিন:",
  Invoice_Due:"চালান বকেয়া:",
  New_Invoice:"নতুন চালান",
  No_Invoices:"কোন চালান নেই",
  No_Invoices_Word:"প্রথম <a routerLink='/folder/Invoice/New'>ইনভয়েস</a> তৈরি করুন।",
  Cheque_Due:"চেক ডিউ:",
  Payrolls:"বেতন",
  Sandbox_Mode:"স্যান্ডবক্স মোড",
  Hire:"ভাড়া",
  Pay:"পেমেন্ট",
  New:"নতুন",
  Add:"যোগ করুন",
  Make:"বানান",
  Time:"সময়",
  Write:"লিখুন",
  Holiday:"ছুটির দিন",
  Holidays:"ছুটির দিন",
  Next_Holiday:"পরবর্তী ছুটির দিন:",
  All_Done:"সব শেষ!",
  Employees:"কর্মচারী",
  Payees:"প্রদানকারী",
  Job:"চাকরি",
  Jobs:"চাকরি",
  Invoice:"চালান",
  Invoices:"চালান",
  Vacations:"ছুটি",
  Cheques:"চেক",
  Brand_Cheques:"ব্র্যান্ড",
  Payment:"পেমেন্ট",
  Enable_Payment:"পেমেন্ট সক্ষম করুন",
  Payments:"পেমেন্টস",
  Schedule:"সূচি",
  Schedules:"সূচি",
  Timesheet:"টাইম শিট",
  Timesheets:"সময় পত্রক",
  Salary:"বেতন",
  New_Address:"নতুন ঠিকানা",
  Address_2:"ঠিকানা লাইন ২)",
  _City:"শহর",
  _State:"রাষ্ট্র/প্রধান",
  City:"শহর / জনপদ",
  State:"রাজ্য/প্রদেশ",
  Postal:"পোস্টকোড / জিপকোড",
  ZIP:"পোস্টাল / জিপ",
  Country:"দেশ",
  Addresses:"ঠিকানা",
  Required_Options:"প্রয়োজনীয় বিকল্প",
  Optional_Options:"ঐচ্ছিক বিকল্প",
  Additional_Options:"অতিরিক্ত বিকল্প",
  Required:"প্রয়োজনীয়",
  Optional:"ঐচ্ছিক",
  Additional:"অতিরিক্ত",
  No_Addresses:"কোন ঠিকানা নেই",
  New_Address_Word:"প্রথম <a routerLink='/folder/Address/New'>ঠিকানা</a> যোগ করুন।",
  Select_Address:"ঠিকানা নির্বাচন করুন",
  No_Address:"কোন ঠিকানা নেই",
  Print_Cheque:"প্রিন্ট চেক",
  Print_Cheque_Now:"এখনই প্রিন্ট চেক করুন",
  Description:"বর্ণনা",
  Pay_To_The_Order_Of:"অর্ডারে অর্থপ্রদান করুন:",
  Select_Date_Range:"তারিখ পরিসর নির্বাচন করুন",
  Select_Starting_Date:"শুরু করার তারিখ নির্বাচন করুন",
  Select_Ending_Date:"শেষ তারিখ নির্বাচন করুন",
  Select_Date:"তারিখ নির্বাচন করুন",
  Cheque_Date:"তারিখ পরীক্ষা করুন",
  Cheque_Memo:"মেমো চেক করুন",
  Blank_Cheque:"নিরঙ্ক চেক",
  Blank:"ফাঁকা",
  No_Cheques:"কোন চেক নেই",
  No_Cheques_Word:"আপনার প্রথম <a routerLink='/folder/Cheque/New'>চেক</a> প্রিন্ট করুন।",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"ভিউ ইমেজ",
  View:"দেখুন",
  Modify:"পরিবর্তন",
  Delete:"মুছে ফেলা",
  Cheque_Paid:"প্রদান",
  New_Deduction:"নতুন ডিডাকশন",
  Title:"শিরোনাম",
  Frequency:"ফ্রিকোয়েন্সি",
  Hourly:"প্রতি ঘন্টায়",
  Daily:"প্রতিদিন",
  Weekdays:"সাপ্তাহিক দিন",
  BiWeekly:"২ সপ্তাহ",
  TriWeekly:"3 সপ্তাহ",
  Monthly:"মাসিক",
  MiMonthly:"2 মাস",
  Quarterly:"ত্রৈমাসিক",
  Yearly:"বার্ষিক",
  Every_Week:"প্রতি সপ্তাহে",
  Every_Payroll:"প্রতিটি বেতন",
  Every_Month:"প্রতি মাসে",
  Annually:"বার্ষিক",
  Always_Scheduled:"সর্বদা নির্ধারিত",
  Start_Date:"শুরুর তারিখ",
  End_Date:"শেষ তারিখ",
  Start_Time:"সময় শুরু",
  End_Time:"শেষ সময়",
  Deduction_Label:"ডিডাকশন লেবেল",
  Notes:"মন্তব্য",
  Options:"বিকল্প",
  Enable:"সক্ষম করুন",
  Select_Deductions:"ডিডাকশন নির্বাচন করুন",
  Deductions:"ডিডাকশন",
  No_Deductions:"কোন ছাড় নেই",
  No_Deductions_Word:"আপনার প্রথম <a routerLink='/folder/Deduction/New'>ডিডাকশন</a> তৈরি করুন।",
  New_Employee:"নতুন কর্মচারী",
  No_Title:"কোনো শিরোনাম নেই",
  _Name:"নাম",
  About_Yourself:"নিজেকে সম্পর্কে",
  Full_Name:"পুরো নাম",
  Birthday:"জন্মদিন",
  Email:"ইমেইল",
  SMS:"খুদেবার্তা",
  Phone:"ফোন",
  Test:"পরীক্ষা",
  Call:"কল",
  Fax:"ফ্যাক্স",
  Printed_Note:"মুদ্রিত নোট",
  Position:"অবস্থান",
  Job_Position:"চাকুরী পদমর্যাদা",
  Select_a_Job:"একটি কাজ নির্বাচন করুন",
  Select_a_Salary:"একটি বেতন নির্বাচন করুন",
  Add_a_Deduction:"একটি কর্তন যোগ করুন",
  Taxes:"করের",
  Add_Taxes:"কর যোগ করুন",
  Date_of_Birth:"জন্ম তারিখ",
  Email_Address:"ইমেল ঠিকানা",
  Phone_Number:"ফোন নম্বর",
  Phone_Call:"ফোন কল",
  Enable_on_Payroll:"পেরোলে সক্ষম করুন",
  Select_Employees:"কর্মচারী নির্বাচন করুন",
  No_Employees:"কোন কর্মচারী নেই",
  No_Employees_Word:"আপনার প্রথম নতুন <a routerLink='/folder/Employee/New'>কর্মচারী</a> যোগ করুন।",
  No_Name:"নামহীন",
  Unemployeed:"বেকার",
  Employeed:"নিযুক্ত",
  Paid:"প্রদান",
  Enabled:"সক্ষম",
  Disabled:"অক্ষম",
  Fire:"আগুন",
  Not_Available:"পাওয়া যায় না",
  Not_Available_Word:"আপনার প্রথম <a routerLink='/folder/Invoice/New'>ইনভয়েস</a> প্রিন্ট করুন এবং অর্থপ্রদান করুন।",
  Select_Invoices:"সিলেক্ট_ইনভয়েস",
  Print_Invoice_Word:"আপনার প্রথম <a routerLink='/folder/Invoice/New'>ইনভয়েস</a> প্রিন্ট করুন এবং অর্থপ্রদান করুন।",
  Invoice_Title:"চালানের শিরোনাম",
  Invoice_Date:"চালান তারিখ",
  Due_Date:"নির্দিষ্ট তারিখ",
  New_Job:"নতুন চাকরি",
  Details:"বিস্তারিত",
  Customize:"কাস্টমাইজ করুন",
  Customize_Dashboard:"ড্যাশবোর্ড কাস্টমাইজ করুন",
  Components:"উপাদান",
  No_Components:"কোন উপাদান নেই",
  Main_Components:"প্রধান উপাদান",
  Smaller_Components:"ছোট উপাদান",
  Error_Loading_Page:"এই পৃষ্ঠাটি লোড করার সময় ত্রুটি।",
  Bank_Details:"ব্যাংক বিবরণ",
  About_Your_Job:"আপনার কাজ সম্পর্কে",
  Your_Schedule:"আপনার সময়সূচী",
  Job_Title:"কাজের শিরোনাম",
  Job_Description:"কাজের বিবরণী",
  Job_Details:"চাকরির বিস্তারিত",
  Enable_Job:"চাকরি সক্ষম করুন",
  Pay_Period:"বেতনের মেয়াদ",
  Perpetually_Schedule:"নিরন্তর সময়সূচী",
  Select_Jobs:"চাকরি নির্বাচন করুন",
  No_Jobs:"কোন চাকরি নেই",
  Add_Jobs:"চাকরি যোগ করুন",
  No_Jobs_Word:"তালিকায় প্রথম <a routerLink='/folder/Job/New'>জব</a> যোগ করুন।",
  Count_Employees:"job.employee_count+' কর্মচারী'",
  Zero_Employees:"0 কর্মচারী",
  New_Leave:"নতুন ছুটি",
  Leave_Name:"নাম ছেড়ে দিন",
  Paid_Leave:"বেতনভোগী ছুটি",
  Leave_Pay:"পেমেন্ট ছেড়ে দিন",
  Indefinite_Leave:"অনির্দিষ্টকালের ছুটি",
  Indefinite_Payroll:"অনির্দিষ্ট বেতন",
  Leave:"ছাড়ো",
  Add_Schedules:"সূচি যোগ করুন",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"অনুপস্থিতি সক্ষম করুন",
  Select_Leaves:"পাতা নির্বাচন করুন",
  No_Leaves:"অনুপস্থিতির কোন পাতা নেই",
  Leave_Of_Absence:"অনুপস্থিতির ছুটি",
  Leaves_Of_Absence:"অনুপস্থিতির পাতা",
  New_Leave_of_Absense:"নতুন অনুপস্থিতির ছুটি",
  No_Leaves_Word:"প্রথম <a routerLink='/folder/Leave/New'>অনুপস্থিতির ছুটি</a> যোগ করুন।",
  Not_Enabled:"সক্রিয় না",
  Absences:"অনুপস্থিতি",
  Payee:"পেয়ি",
  New_Payee:"নতুন প্রাপক",
  Payee_Identifier:"প্রদানকারী শনাক্তকারী",
  Payee_Name:"পাওনাদারের নাম",
  Payee_Title:"প্রাপক শিরোনাম",
  Payment_Memo:"পেমেন্ট মেমো",
  Select_Payees:"প্রদানকারীদের নির্বাচন করুন",
  No_Payees:"কোন পাওনা নেই",
  Add_Payee_Note:"প্রথম <a routerLink='/folder/Payee/New'>প্রাপ্তি</a> যোগ করুন।",
  New_Payees:"নতুন প্রাপকরা",
  About_The_Payment_Schedule:"প্রদানের সময়সূচী",
  Your_Payroll_Schedule:"স্বয়ংক্রিয় বেতন",
  New_Payment:"নতুন অর্থপ্রদান",
  Identifier:"শনাক্তকারী",
  Select:"নির্বাচন করুন",
  Memo:"মেমো",
  Payment_Date:"টাকা প্রদানের তারিখ",
  Mark_as_Paid:"প্রদান হিসাবে চিহ্নিত করুন",
  Select_Payments:"পেমেন্ট নির্বাচন করুন",
  No_Payments:"কোন পেমেন্ট নেই",
  No_Payments_Word:"প্রথম <a routerLink='/folder/Payment/New'>পেমেন্ট</a> তৈরি করুন।",
  Create_Payroll:"পে-রোল তৈরি করুন",
  Properties:"বৈশিষ্ট্য",
  Payroll_Title:"বেতনের শিরোনাম",
  Payroll_Notes:"পে-রোল নোট",
  Payroll_Setup:"পে-রোল সেটআপ",
  Tabulate_Payments:"টেবিলেট পেমেন্ট",
  Tabulate:"টেবিলেট",
  By:"দ্বারা:",
  Payments_By:"এর দ্বারা অর্থপ্রদান",
  Timesheets_And_Schedules:"সময় পত্রক এবং সময়সূচী",
  Both:"উভয়",
  Items:"আইটেম",
  Add_Payees:"প্রদানকারীদের যোগ করুন",
  Add_Account:"হিসাব যোগ করা",
  Enable_Account:"অ্যাকাউন্ট সক্রিয় করুন",
  Enable_Payroll:"পে-রোল সক্ষম করুন",
  Print_Payroll:"প্রিন্ট পেরোল",
  No_Payrolls:"কোন বেতন নেই",
  No_Payroll_Word:"আপনার প্রথম <a routerLink='/folder/Payroll/New'>বেতন</a> তৈরি করুন।",
  View_more:"আরো দেখুন",
  Less:"কম",
  Add_Payroll:"পে-রোল যোগ করুন",
  Select_Payroll:"পে-রোল নির্বাচন করুন",
  About_Your_Salary:"আপনার বেতন সম্পর্কে",
  Add_Salaries:"বেতন যোগ করুন",
  Add_Salary:"বেতন যোগ করুন",
  Salaries:"বেতন",
  No_Salaries:"কোন বেতন নেই",
  No_Salaries_Word:"প্রথম <a routerLink='/folder/Salary/New'>বেতন</a> যোগ করুন।",
  Select_Salaries:"বেতন নির্বাচন করুন",
  New_Salary:"নতুন বেতন",
  Salary_Name:"বেতন শনাক্তকারী",
  Enable_Salary:"বেতন সক্ষম করুন",
  Salary_Amount:"বেতনের পরিমাণ",
  New_Schedule:"নতুন সময়সূচি",
  Schedule_Title:"সূচির শিরোনাম",
  Add_Address:"ঠিকানা যোগ করুন",
  Repeat:"পুনরাবৃত্তি",
  Design:"ডিজাইন",
  Edit_Design:"ডিজাইন সম্পাদনা করুন",
  Edit_this_Design:"এই ডিজাইনটি সম্পাদনা করুন",
  Repeat_Payment:"রিপিট পেমেন্ট",
  Enable_Schedule:"সূচি সক্ষম করুন",
  Never:"কখনই না",
  Select_Schedule:"সূচি নির্বাচন করুন",
  No_Schedules:"কোন সময়সূচী নেই",
  No_Schedules_Word:"প্রথম <a routerLink='/folder/Schedule/New'>শিডিউল</a> তৈরি করুন।",
  New_Administrator:"নতুন প্রশাসক",
  Username:"ব্যবহারকারীর নাম",
  First_Name:"নামের প্রথম অংশ",
  Last_Name:"নামের শেষাংশ",
  Add_an_Address:"একটি ঠিকানা যোগ করুন",
  Payment_Limit:"প্রতি-পেমেন্ট সীমা",
  Total_Limit:"মোট সীমা",
  Select_Accounts:"অ্যাকাউন্ট নির্বাচন করুন",
  No_Administrators:"কোন প্রশাসক নেই",
  No_Administrators_Word:"প্রথম <a routerLink='/folder/Administrator/New'>অ্যাডমিন অ্যাকাউন্ট</a> তৈরি করুন।",
  New_Administrators_Word:"প্রথম <a routerLink='/folder/Administrator/New'>প্রশাসক</a> যোগ করুন",
  Cloud:"মেঘ",
  Backup:"ব্যাকআপ",
  Enable_iCloud:"আইক্লাউড সক্ষম করুন",
  Enable_Google_Drive:"গুগল ড্রাইভ সক্ষম করুন",
  Enable_Microsoft_OneDrive:"Microsoft OneDrive সক্ষম করুন",
  Automatically_Backup:"স্বয়ংক্রিয়ভাবে ব্যাকআপ",
  FTP_Settings:"FTP সেটিংস",
  URL_File_Prompt:"আমদানি করার জন্য একটি .xls / .xlsx / .json ফাইলের অবস্থান উল্লেখ করুন:",
  URL_SQL_Prompt:"আমদানি করার জন্য SQLite ফাইলের অবস্থান উল্লেখ করুন:",
  FTP_Backup:"এফটিপি ব্যাকআপ",
  FTP_Import:"এফটিপি আমদানি",
  FTP:"এফটিপি",
  SFTP:"SFTP",
  Host:"হোস্ট",
  Port:"বন্দর",
  Path:"পথ",
  Data_Path:"ডেটা পাথ",
  Enable_FTP_Account:"এফটিপি অ্যাকাউন্ট সক্ষম করুন",
  HostnameIP:"হোস্টনেম",
  Password:"পাসওয়ার্ড",
  Connection_Port:"সংযোগ পোর্ট",
  Enable_MySQL_Database:"MySQL ডেটাবেস সক্ষম করুন",
  Log:"লগ",
  Reset:"রিসেট",
  Erase:"মুছুন",
  Export:"রপ্তানি",
  Database:"তথ্যশালা",
  Upload_CSV:"CSV আপলোড করুন",
  Download_CSV:"CSV ডাউনলোড করুন",
  SQL_Database:"SQL URL",
  MySQL_Database:"মাইএসকিউএল",
  MySQL_Backup:"MySQL ব্যাকআপ",
  Internal_Notes:"অভ্যন্তরীণ নোট",
  Root_Path:"মূল পথ",
  Select_Backup:"ব্যাকআপ নির্বাচন করুন",
  Add_New_Backup:"একটি নতুন ব্যাকআপ যোগ করুন",
  First_Backup:"প্রথম ব্যাকআপ সেটআপ করুন...",
  Backups:"ব্যাকআপ",
  Add_Backup:"ব্যাকআপ যোগ করুন",
  No_Backups:"কোন ব্যাকআপ খুঁজে পাওয়া যাবে না।",
  Select_Backup_Type:"আপনি যে ধরনের ব্যাকআপ সেটআপ করতে চান সেটি নির্বাচন করুন...",
  Backup_Type:"ব্যাকআপ টাইপ",
  FTP_Drive:"এফটিপি ড্রাইভ",
  URL_Location:"URL",
  Apple_iCloud:"আইক্লাউড",
  Google_Drive:"ড্রাইভ",
  Microsoft_OneDrive:"ড্রাইভ",
  Import_Fields:"আমদানি ক্ষেত্র",
  Import_Fields_Word:"এই বিভাগটি ব্যবহার করুন <a routerLink='/folder/Holiday/New'>ইমপোর্ট</a> ডেটা নির্বাচন করতে।",
  Upload:"আপলোড",
  Download:"ডাউনলোড",
  Download_JSON:"JSON ডেটা হিসাবে ডাউনলোড করুন",
  Download_SQL:"এসকিউএল ফাইল হিসাবে ডাউনলোড করুন",
  New_Bank:"নতুন ব্যাংক",
  New_Account:"নতুন হিসাব",
  New_Bank_Account:"নতুন ব্যাঙ্ক অ্যাকাউন্ট",
  Upload_Image:"চিত্র আপলোড",
  Bank_Name:"ব্যাংকের নাম",
  Bank_Address:"ব্যাংকের ঠিকানা",
  Branch_Address:"শাখা ঠিকানা",
  Address_on_Cheque:"ঠিকানা",
  Cheque_Numbers:"চেক নম্বর",
  Cheque_Details:"বিশদ বিবরণ পরীক্ষা করুন",
  Bank_Logo:"ব্যাংক লোগো",
  Cheque_ID:"আইডি চেক করুন",
  Starting_Cheque_ID:"চেক আইডি শুরু হচ্ছে",
  Transit_Number:"ট্রানজিট নম্বর",
  Institution_Number:"প্রতিষ্ঠান নম্বর",
  Designation_Number:"পদবী নম্বর",
  Account_Number:"হিসাব নাম্বার",
  Limits:"সীমা",
  Default_Limits:"ডিফল্ট সীমা",
  Over_Limit:"সীমার বাইরে",
  Under_Limit:"সীমার অধীনে",
  Payroll_Limit:"বেতনের সীমা",
  Enable_Bank_Account:"ব্যাঙ্ক অ্যাকাউন্ট সক্ষম করুন",
  Select_Account:"নির্বাচন অ্যাকাউন্ট",
  No_Bank_Account:"কোন ব্যাঙ্ক অ্যাকাউন্ট নেই",
  No_Bank_Account_Word:"প্রথম <a routerLink='/folder/Accounts/New'>ব্যাঙ্ক অ্যাকাউন্ট</a> যোগ করুন।",
  Bank_Accounts:"ব্যাংক হিসাব",
  No_Accounts:"কোন হিসাব নেই",
  No_Accounts_Note:"প্রথম <a routerLink='/folder/Accounts/New'>ব্যাঙ্ক অ্যাকাউন্ট</a> যোগ করুন।",
  Cheque_Designer:"চেক ডিজাইনার",
  Cheque_Design:"চেক ডিজাইন",
  Select_Design:"একটি নকশা নির্বাচন করুন",
  Cheque_Designs:"ডিজাইন চেক করুন",
  No_Cheque_Designs:"কোন চেক ডিজাইন নেই",
  No_Cheque_Designs_Word:"আপনার নিজস্ব <a routerLink='/folder/Settings/Cheque/Design/New'>চেক ডিজাইন</a> তৈরি করুন।",
  Set_Default:"ডিফল্ট হিসাবে সেট করুন",
  Default:"ডিফল্ট",
  Remove:"অপসারণ",
  Folder:"ফোল্ডার",
  Edit:"সম্পাদনা",
  LoadingDots:"লোড হচ্ছে...",
  Add_a_New_File:"এতে একটি <a href='#' (click)='add()'>নতুন ফাইল</a> যোগ করুন",
  this_folder:"এই ফোল্ডার",
  FTP_Backup_Settings:"FTP ব্যাকআপ সেটিংস",
  Secure_File_Transfer:"নিরাপদ ফাইল স্থানান্তর",
  New_Holiday:"নতুন ছুটি",
  Add_Holiday:"ছুটি যোগ করুন",
  Holiday_Name:"ছুটির নাম",
  Additional_Pay:"অতিরিক্ত বেতন",
  Enable_Holiday:"ছুটির দিন সক্ষম করুন",
  Select_Holidays:"ছুটির দিনগুলি নির্বাচন করুন",
  No_Holidays:"ছুটি নেই",
  No_Holidays_Word:"প্রথম <a routerLink='/folder/Holiday/New'>পাবলিক হলিডে</a> যোগ করুন।",
  New_Import:"নতুন আমদানি",
  Import_Data:"তথ্য আমদানি",
  Import_Data_Word:"আপনার পছন্দের ফাইলের ধরন বা আপলোড পদ্ধতি নির্বাচন করুন৷<br /> একটি সমর্থিত ফাইল আপলোড করার পরে আপনি একটি ফাইলের যে কোনো প্যারামিটারের সাথে সামঞ্জস্যপূর্ণ কোনো ফাইলের আমদানি করা ক্ষেত্রগুলি নির্বাচন করতে সক্ষম হবেন ।", 
  Import_File:"ফাইল আমদানি",
  Link_To_File:"ফাইলের লিঙ্ক",
  Select_File:"ফাইল নির্বাচন",
  New_Moderator:"নতুন মডারেটর",
  Allow_Moderation:"সংযম করার অনুমতি দিন",
  Create_Paycheques:"পেচেক তৈরি করুন",
  Edit_Paycheques_and_Data:"পেচেক এবং ডেটা সম্পাদনা করুন",
  Destroy_Data_and_Paycheques:"ডেটা এবং পেচেকগুলি ধ্বংস করুন",
  Bonus_Percentage:"পে-চেক শতাংশ",
  Fixed_Amount:"নির্দিষ্ট পরিমাণ",
  Select_Moderator:"মডারেটর নির্বাচন করুন",
  No_Moderators:"কোন মডারেটর নেই",
  Moderators:"মডারেটর",
  Moderator_Account:"প্রথম <a routerLink='/folder/Administrator/New'>মডারেটর অ্যাকাউন্ট</a> তৈরি করুন।",
  No_Moderators_Word:"প্রথম <a routerLink='/folder/Administrator/New'>মডারেটর</a> যোগ করুন।",
  Defaults:"পূর্ব নির্ধারিত",
  Provide_Us_Info:"আমাদের তথ্য প্রদান করুন",
  Setup_Your_Printer:"আপনার প্রিন্টার সেটআপ করুন",
  Your_Company:"আপনার কোম্পানি সম্পর্কে",
  Company_Name:"কোমপানির নাম",
  Currency:"মুদ্রা",
  Default_Currency:"ডিফল্ট মুদ্রা",
  Base_Monthly_Income:"মাসিক আয়",
  Protection:"সুরক্ষা",
  App_Protection:"অ্যাপ সুরক্ষা",
  PIN_Code_Protection:"পিন কোড সুরক্ষা",
  App_Protection_Word:"নিরাপত্তা পদ্ধতিগুলি সক্ষম করুন যা আপনার অ্যাকাউন্ট সুরক্ষিত করতে সাহায্য করে।",
  PIN_Code:"পিনকোড",
  Change_PIN:"পিন পরিবর্তন করুন",
  New_Password:"নতুন পাসওয়ার্ড",
  Geofence_Protection:"জিও-ফেন প্রোটেকশন",
  Geofence_Area:"সেট এলাকা",
  Distance:"দূরত্ব",
  Setup_Now:"এখনই সেটআপ করুন",
  Mile:"মাইল",
  Km:"কিমি",
  m:"মি",
  Facial_Recognition:"মুখের স্বীকৃতি",
  Face:"মুখ",
  Setup:"সেটআপ",
  Label:"লেবেল",
  Password_Protection:"পাসওয়ার্ড সুরক্ষা",
  Modify_Password:"পাসওয়ার্ড পরিবর্তন",
  New_Tax_Entry:"নতুন ট্যাক্স এন্ট্রি",
  New_Tax:"নতুন কর",
  Tax_Label:"ট্যাক্স লেবেল",
  Perpetually_Enabled:"চিরস্থায়ীভাবে সক্রিয়",
  Select_Taxes:"কর নির্বাচন করুন",
  Tax_Deductions:"কর কর্তন",
  No_Tax_Deductions:"কোন ট্যাক্স ডিডাকশন নেই",
  No_Tax_Deductions_Word:"প্রথম <a routerLink='/folder/Tax/New'>ট্যাক্স</a> ছাড় যোগ করুন।",
  New_Timer:"নতুন টাইমার",
  Start:"শুরু",
  Stop:"থাম",
  Start_Timer:"স্টার্ট টাইমার",
  Stop_Timer:"টাইমার বন্ধ করুন",
  Timer_Active:"টাইমার সক্রিয়",
  Timer:"টাইমার:",
  Timer_Running:"টাইমার: (চলমান)",
  Save_Timer:"টাইমার সংরক্ষণ করুন",
  New_Timesheet:"নতুন সময় পত্র",
  Select_Timesheets:"সময় পত্রক নির্বাচন করুন",
  Work_Notes:"কাজের নোট",
  Entry_Title:"প্রবেশ শিরোনাম",
  No_Timesheets:"নো টাইম শিট",
  No_Timesheets_Word:"প্রথম <a routerLink='/folder/Timesheet/New'>টাইম শীট</a> যোগ করুন।",
  Timesheet_Submitted:"টাইম শিট জমা দেওয়া হয়েছে",
  Timesheet_Congrats:"অভিনন্দন! আপনার সময় পত্র সফলভাবে জমা দেওয়া হয়েছে। ধন্যবাদ!",
  Timesheet_Copy:"আপনার নথিগুলির একটি অনুলিপি পেতে অনুগ্রহ করে আমাদের আপনার ইমেল ঠিকানা এবং/অথবা মোবাইল ফোন নম্বর প্রদান করুন।",
  Submit:"জমা দিন",
  Allow_Notifications:"বিজ্ঞপ্তি অনুমতি দিন",
  Untitled_Entry:"নতুন প্রবেশ",
  Untitled_Bank:"শিরোনামহীন ব্যাংক",
  Timesheet_Entry:"টাইম শিট এন্ট্রি",
  Work_Description:"কাজের বর্ণনা",
  Enable_Timesheet:"টাইম শিট সক্ষম করুন",
  Submit_Timesheet:"সময় পত্র জমা দিন",
  Vacation:"ছুটি",
  New_Vacation:"নতুন ছুটি",
  Vacation_Name:"অবকাশের নাম",
  Paid_Vacation:"প্রদত্ত ছুটি",
  Vacation_Pay:"ছুটি বেতন",
  Enable_Vacation:"ছুটি সক্ষম করুন",
  Select_Vacations:"ছুটি নির্বাচন করুন",
  No_Vacations:"কোন ছুটি নেই",
  No_Vacations_Word:"প্রথম <a routerLink='/folder/Vacation/New'>ছুটি</a> এন্ট্রি তৈরি করুন।",
  Payroll_Schedule:"বেতনের সময়সূচী",
  Next_Payroll:"পরবর্তী বেতন:",
  Upcoming_Payroll:"আসন্ন বেতন",
  No_Upcoming_Payroll:"কোন আসন্ন বেতন নেই",
  Address_Book:"ঠিকানা বই",
  Archived_Documents:"আর্কাইভড ডকুমেন্টস",
  Dev_Mode:"ডেভেলপমেন্ট মোডে অ্যাপ্লিকেশন",
  Administrators:"প্রশাসক",
  Privacy:"গোপনীয়তা",
  None:"কিছুই না",
  Select_Signature:"স্বাক্ষর নির্বাচন করুন",
  No_Signatures:"কোন স্বাক্ষর নেই",
  No_Signatures_Word:"প্রথম <a routerLink='/folder/Signature/New'>স্বাক্ষর</a> যোগ করুন।",
  Repeat_Indefinitely:"অনির্দিষ্টকালের জন্য পুনরাবৃত্তি করুন",
  Sign:"চিহ্ন",
  Sign_Here:"এখানে স্বাক্ষর কর",
  Date_Signed:"তারিখ স্বাক্ষরিত",
  Signature_Pad:"স্বাক্ষর প্যাড",
  Account_Holder:"হিসাব ধারী",
  Account_Properties:"অ্যাকাউন্টের বৈশিষ্ট্য",
  Name_On_Cheque:"চেকে নাম",
  Server_Hostname:"সার্ভার হোস্টনাম / আইপি",
  Printers:"প্রিন্টার",
  No_Printers:"কোন প্রিন্টার নেই",
  Printer_Exists:'এই নামের একটি প্রিন্টার ইতিমধ্যেই বিদ্যমান।',
  No_Printers_Word:"প্রথম <a routerLink='/folder/Printer/New'>প্রিন্টার</a> যোগ করুন।",
  No_Printer_Alert:"কোন প্রিন্টার সংজ্ঞায়িত করা নেই। আপনি কি একটি প্রিন্টার সেটআপ করতে চান?",
  Add_Printer:"প্রিন্টার যোগ করুন",
  New_Printer:"নতুন প্রিন্টার",
  Printer_Hostname:"প্রিন্টার হোস্টনেম / আইপি",
  Printer_Label:"প্রিন্টার লেবেল",
  Printer_Protocol:"প্রিন্টার প্রোটোকল",
  Protocol:"প্রটোকল",
  Email_Print:"ইমেইল",
  AirPrint:"এয়ারপ্রিন্ট",
  IPP:"আইপিপি",
  LPD:"এলপিডি",
  HPJetDirect:"সকেট",
  Print_Job:"প্রিন্ট জব",
  Printed:"মুদ্রিত",
  Not_Printed:"মুদ্রিত হয়নি",
  Print_Jobs:"প্রিন্ট জবস",
  Print_Queue:"মুদ্রণ কিউ",
  No_Print_Jobs:"কোন প্রিন্ট কাজ নেই",
  No_Prints:"প্রিন্ট করার জন্য একটি নতুন <a routerLink='/folder/Cheque/New'>চেক</a> তৈরি করুন।",
  Prints:"প্রিন্ট",
  Find_Printer:"প্রিন্টার খুঁজুন",
  Find_AirPrint_Devices:"এয়ারপ্রিন্ট ডিভাইস খুঁজুন",
  Select_Printer:"প্রিন্টার নির্বাচন করুন",
  System_UI:"সিস্টেম UI",
  Printer:"প্রিন্টার",
  Status:"স্থিতি",
  Preview:"প্রিভিউ",
  Enable_Print_Job:"প্রিন্ট জব সক্ষম করুন",
  Queue_Weight:"সারির ওজন",
  Unlimited:"সীমাহীন",
  Show_Advanced_Printer_Options:"উন্নত প্রিন্টার বিকল্পগুলি দেখান",
  Advanced:"উন্নত",
  Location:"অবস্থান",
  Note:"বিঃদ্রঃ",
  Queue_Name:"সারির নাম",
  Pages_Printed_Limit:"পৃষ্ঠা মুদ্রিত সীমা",
  MultiPage_Idle_Time:"মাল্টিপেজ ওয়েট টাইম(গুলি)",
  Page_Count_Limit:"পৃষ্ঠা গণনার সীমা",
  Page_Orientation:"পৃষ্ঠা ওরিয়েন্টেশন",
  Portrait:"প্রতিকৃতি",
  Landscape:"ল্যান্ডস্কেপ",
  Duplex:"ডুপ্লেক্স",
  Double_Sided_Printing:"দ্বিপার্শ্ব",
  Duplex_Mode:"ডুপ্লেক্স মোড",
  Duplex_Short:"সংক্ষিপ্ত",
  Duplex_Long:"দীর্ঘ",
  Duplex_None:"কিছুই না",
  Color_And_Quality:"রঙ এবং গুণমান",
  Monochrome:"একরঙা",
  Photo_Quality_Prints:"ফটো কোয়ালিটি প্রিন্ট",
  Printer_Email:"প্রিন্টার ইমেল",
  Automatically_Downsize:"স্বয়ংক্রিয়ভাবে ডাউনসাইজ করুন",
  Paper:"কাগজ",
  Paper_Name:"কাগজের নাম",
  Paper_Width:"কাগজের প্রস্থ",
  Paper_Height:"কাগজের উচ্চতা",
  Paper_Autocut_Length:"কাগজ স্বয়ংক্রিয় কাটা দৈর্ঘ্য",
  Margins:"মার্জিন",
  Page_Margins:"পৃষ্ঠা মার্জিন",
  Page_Margin_Top:"শীর্ষ পৃষ্ঠা মার্জিন",
  Page_Margin_Right:"ডান পৃষ্ঠা মার্জিন",
  Page_Margin_Bottom:"নীচের পৃষ্ঠা মার্জিন",
  Page_Margin_Left:"বাম পৃষ্ঠা মার্জিন",
  Add_Employees:"কর্মচারী যোগ করুন",
  Header:"হেডার",
  Print_A_Page_Header:"একটি পৃষ্ঠা শিরোনাম প্রিন্ট করুন",
  Header_Label:"হেডার লেবেল",
  Header_Page_Index:"হেডার পেজ ইনডেক্স",
  Header_Font_Name:"হেডার ফন্ট",
  Select_Font:"ফন্ট নির্বাচন করুন",
  Font_Selector:"ফন্ট নির্বাচক",
  Header_Font_Size:"হেডার ফন্ট",
  Header_Bold:"হেডার বোল্ড",
  Header_Italic:"শিরোনাম তির্যক",
  Header_Alignment:"হেডার সারিবদ্ধকরণ",
  Left:"বাম",
  Center:"কেন্দ্র",
  Right:"ঠিক",
  Justified:"ন্যায়সঙ্গত",
  Header_Font_Color:"হেডারের রঙ",
  Select_Color:"রঙ নির্বাচন করুন",
  Footer:"পাদলেখ",
  Print_A_Page_Footer:"একটি পৃষ্ঠা ফুটার প্রিন্ট করুন",
  Footer_Label:"ফুটার লেবেল",
  Footer_Page_Index:"ফুটার পেজ ইনডেক্স",
  Footer_Font_Name:"ফুটার ফন্ট",
  Fonts:"হরফ",
  Done:"সম্পন্ন",
  Select_Fonts:"ফন্ট নির্বাচন করুন",
  Footer_Font_Size:"ফুটার সাইজ",
  Footer_Bold:"ফুটার বোল্ড",
  Footer_Italic:"ফুটার ইটালিক",
  Footer_Alignment:"ফুটার প্রান্তিককরণ",
  Footer_Font_Color:"ফুটার রঙ",
  Page_Copies:"পৃষ্ঠার অনুলিপি",
  Enable_Printer:"প্রিন্টার সক্ষম করুন",
  Remote_Logging:"দূরবর্তী লগিং",
  Log_Server:"লগ সার্ভার",
  Encryption:"জোড়া লাগানো",
  Random_Key:"র্যান্ডম কী",
  Encryption_Key:"এনক্রিপশন কী",
  Cheques_Webserver:"কাস্টম ডাটাবেস",
  Learn_How:"কিভাবে শিখব",
  Signature:"স্বাক্ষর",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"স্বাক্ষর দেখুন",
  Printed_Signature:"মুদ্রিত স্বাক্ষর",
  Digitally_Sign:"ডিজিটালি সাইন",
  Digital_Signature:"ডিজিটাল স্বাক্ষর",
  Add_Signature:"স্বাক্ষর যোগ করুন",
  Add_Your_Signature:"আপনার স্বাক্ষর যোগ করুন",
  Enable_Signature:"স্বাক্ষর সক্ষম করুন",
  Digitally_Signed:"ডিজিটালি স্বাক্ষরিত",
  Insert_Error:"ডাটাবেস সমস্যার কারণে চেক সংরক্ষণ করতে অক্ষম।",
  Delete_Confirmation:"আপনি কি নিশ্চিত আপনি এই তথ্য মুছে দিতে চান?",
  Discard_Confirmation:"আপনি কি নিশ্চিত যে আপনি এই তথ্যটি বাতিল করতে চান?",
  Discard_Bank_Confirmation:"আপনি কি নিশ্চিত যে আপনি এই অ্যাকাউন্টটি বাতিল করতে চান?",
  Discard_Printer_Confirmation:"আপনি কি নিশ্চিত যে আপনি এই প্রিন্টারটি বাতিল করতে চান?",
  Delete_Bonus_Confirmation:"আপনি কি নিশ্চিত যে আপনি এই বোনাসটি মুছতে চান?",
  Delete_Invoice_Confirmation:"আপনি কি নিশ্চিত যে আপনি এই চালানটি মুছতে চান?",
  Generated_Cheque:"জেনারেটেড চেক",
  Generated_Invoice:"উৎপন্ন চালান",
  Schedule_Start_Time:"শুরু করার সময়সূচী",
  Schedule_End_Time:"শিডিউল শেষ",
  New_Call:"নতুন কল",
  No_Contacts:"কোন পরিচিতি নেই",
  No_Contacts_Word:"প্রশাসক, মডারেটর, কর্মচারী, এবং প্রাপক পরিচিতি হিসাবে উপস্থিত হয়।",
  PDF_Subject:"অর্থ",
  PDF_Keywords:"পে-রোল পেচেক পিডিএফ চেক চেক",
  Printer_Setup:"প্রিন্টার সংযুক্ত করণ",
  Printer_Selection:"প্রিন্টার নির্বাচন",
  New_Fax:"নতুন ফ্যাক্স",
  New_Fax_Message:"নতুন ফ্যাক্স বার্তা",
  Fax_Machine:"ফ্যাক্স মেশিন",
  Fax_Name:"ফ্যাক্সের নাম",
  Fax_Email:"ফ্যাক্স ইমেল",
  Fax_Number:"ফ্যাক্স নম্বর",
  Contents:"বিষয়বস্তু",
  Fax_Body:"পেজ বডি",
  Header_Word:"শিরোনাম:",
  Header_Text:"শিরোনাম",
  Include_Header:"শিরোনাম অন্তর্ভুক্ত করুন",
  Include_Footer:"ফুটার অন্তর্ভুক্ত করুন",
  Footer_Word:"পাদলেখ:",
  Footer_Text:"পাদচরণ",
  Attach_a_Cheque:"একটি চেক সংযুক্ত করুন",
  Add_Deduction:"ডিডাকশন যোগ করুন",
  Enable_Fax:"ফ্যাক্স পাঠান",
  Select_Fax:"ফ্যাক্স নির্বাচন করুন",
  No_Faxes:"কোন ফ্যাক্স নেই",
  Faxes:"ফ্যাক্স",
  Save_and_Send:"ফ্যাক্স পাঠান",
  Save_and_Pay:"সংরক্ষণ করুন এবং অর্থ প্রদান করুন",
  WARNING:"সতর্কতা:",
  Remember:"মনে রেখো",
  Printing:"মুদ্রণ",
  Printing_Complete:"মুদ্রণ সম্পূর্ণ!\n\n",
  of:"এর",
  There_Were:"সেখানে ছিল",
  Successful_Prints:"সফল প্রিন্ট এবং",
  Unsuccessful_Prints:"অব্যর্থ প্রিন্ট।",
  PrinterError:"দুঃখিত, একটি ত্রুটি ছিল.",
  Printing_:"মুদ্রণ...",
  PrinterSuccess:"দস্তাবেজ সফলভাবে মুদ্রিত হয়েছে।",
  PrintersSuccess:"নথিপত্র সফলভাবে মুদ্রিত হয়েছে।",
  Print_Cheques:"প্রিন্ট চেক",
  New_Message:"নতুন বার্তা",
  New_Messages:"নতুন বার্তা",
  Read_Message:"বার্তা পড়ুন",
  Write_Message:"বার্তা লিখুন",
  Send_Message:"বার্তা পাঠান",
  Subject:"বিষয়",
  Message:"বার্তা",
  Writing:"লেখা...",
  Send:"পাঠান",
  Set_Date:"তারিখ ঠিক করা",
  Set_Time:"সেট সময়",
  Recieve:"পান",
  Set_as_Default:"ডিফল্ট হিসাবে সেট করুন",
  Default_Account:"ডিফল্ট অ্যাকাউন্ট",
  Default_Design:"ডিফল্ট ডিজাইন",
  Multiple_Cheques:"চেকস (ট্রিপল)",
  Account_Mode:"অ্যাকাউন্ট মোড",
  Multiple_Cheques_Description:"প্রতি পৃষ্ঠায় তিনটি চেক।",
  Check_and_Table:"চেক এবং টেবিল",
  Check_including_Table:"চেক এবং অ্যাকাউন্টিং টেবিল।",
  Check_Mailer:"চেক মেইলার",
  Check_Mailer_Window:"একটি ঠিকানা উইন্ডো দিয়ে চেক করুন।",
  DocuGard_Table_Top:"ডকুগার্ড চেক এবং টেবিল (শীর্ষ)",
  DocuGard_Table_Middle:"ডকুগার্ড চেক এবং টেবিল (মাঝখানে)",
  DocuGard_Table_Bottom:"ডকুগার্ড চেক এবং টেবিল (নীচে)",
  DocuGard_Mailer_Top:"ডকুগার্ড চেক মেইলার (শীর্ষ)",
  DocuGard_Mailer_Middle:"ডকুগার্ড চেক মেইলার (মাঝারি)",
  DocuGard_Mailer_Bottom:"ডকুগার্ড চেক মেইলার (নীচে)",
  DocuGard_Three_Cheques:"ডকুগার্ড চেক (ট্রিপল)",
  DocuGard_Cheque_Top:"ডকুগার্ড চেক (শীর্ষ)",
  DocuGard_Cheque_Middle:"ডকুগার্ড চেক (মাঝারি)",
  DocuGard_Cheque_Bottom:"ডকুগার্ড চেক (নীচে)",
  DocuGard_Three_Cheques_Window:"এক পৃষ্ঠায় তিনটি চেক।",
  DocuGard_Table_Top_Window:"চেক এবং উপার্জন টেবিল।",
  DocuGard_Table_Middle_Window:"চেক এবং উপার্জন টেবিল।",
  DocuGard_Table_Bottom_Window:"চেক এবং উপার্জন টেবিল।",
  DocuGard_Mailer_Top_Window:"চেক, টেবিল, এবং ঠিকানা।",
  DocuGard_Mailer_Middle_Window:"চেক, টেবিল, এবং ঠিকানা।",
  DocuGard_Mailer_Bottom_Window:"চেক, টেবিল, এবং ঠিকানা।",
  DocuGard_Cheque_Top_Window:"নিরাপদ কাগজের জন্য পরীক্ষা করুন।",
  DocuGard_Cheque_Middle_Window:"নিরাপদ কাগজের জন্য পরীক্ষা করুন।",
  DocuGard_Cheque_Bottom_Window:"নিরাপদ কাগজের জন্য পরীক্ষা করুন।",
  Basic_Cheque:"চেক (শীর্ষ)",
  Basic_Cheque_Print:"একটি চেক প্রিন্ট করুন।",
  Error_Setting_As_Paid:"প্রদত্ত হিসাবে সেট করার ত্রুটি",
  Add_Attachment:"সংযুক্তি যোগ",
  PrinterUnavailable:"প্রিন্টার অনুপলব্ধ",
  CreditCardComponent:"তাস",
  PaypalComponent:"পেপ্যাল",
  InteracComponent:"ইন্টারাক",
  BitcoinComponent:"বিটকয়েন",
  New_Deposit:"নতুন আমানত",
  Deposits:"আমানত",
  No_Deposits:"কোন আমানত নেই",
  Credit_Card_Deposit:"ক্রেডিট কার্ড",
  New_Credit_Card_Deposit_Message:"ক্রেডিট কার্ড ডিপোজিট",
  New_BitCoin_Deposit:"বিটকয়েন",
  New_BitCoin_Deposit_Message:"বিটকয়েন ডিপোজিট",
  New_Interac_Deposit:"ইন্টারাক",
  New_Interac_Deposit_Message:"ইন্টারাক ট্রান্সফার",
  Payments_Limit:"পেমেন্ট লিমিট",
  No_Payment_Limit:"কোন পেমেন্ট লিমিট নেই",
  PayPal_Deposit:"পেপ্যাল",
  PayPal_Deposit_Message:"পেপ্যাল ​​আমানত",
  No_Deposits_Word:"প্রথম কোম্পানি <a routerLink='/folder/Deposit/New'>ডিপোজিট</a> যোগ করুন।",
  No_Documents_Specified:"মুদ্রণের জন্য কোন নথি নির্দিষ্ট করা হয়নি",
  No_Printers_Added:"কোন প্রিন্টার পাওয়া যায়নি। সেটিংস > প্রিন্টার এ যান একটি যোগ করতে।",
  DB_Erase_Prompt:"সম্পূর্ণভাবে সম্পূর্ণ ডাটাবেস মুছে ফেলবেন? সতর্কতা: আপনি সমস্ত সঞ্চিত তথ্য হারাবেন!",
  Console_Warning:"এই কনসোলে কোনো টেক্সট পেস্ট করবেন না। আপনি নিজেকে এবং/অথবা আপনার কোম্পানিকে মারাত্মক ঝুঁকিতে ফেলতে পারেন।",
  No_Faxes_Word:"প্রথম <a routerLink='/folder/Fax/New'>ফ্যাক্স</a> তৈরি করুন।",
  Cheque_Delete_Confirmation:"আপনি কি নিশ্চিত আপনি এই চেকটি মুছে ফেলতে চান?",
  Design_Delete_Confirmation:"আপনি কি নিশ্চিত যে আপনি এই নকশাটি মুছতে চান?",
  Cheque_Pay_Confirmation:"এই চেকটিকে প্রদত্ত হিসাবে চিহ্নিত করুন? এটি প্রিন্ট সারিতে প্রদর্শিত হবে না।",
  Payment_Pay_Confirmation:"এই পেমেন্টটিকে প্রদত্ত হিসাবে চিহ্নিত করুন? এটি চেকের সারিতে প্রদর্শিত হবে না।",
  Delete_Deduction_Confirmation:"আপনি কি নিশ্চিত যে আপনি এই কর্তন মুছে দিতে চান?",
  Delete_Job_Confirmation:"আপনি কি নিশ্চিত যে আপনি এই কাজটি মুছে ফেলতে চান?",
  Delete_Timesheet_Confirmation:"আপনি কি নিশ্চিত যে আপনি এই টাইমশিটটি মুছতে চান?",
  Delete_Schedule_Confirmation:"আপনি কি নিশ্চিত আপনি এই সময়সূচী মুছে দিতে চান?",
  Delete_Setting_Confirmation:"আপনি কি নিশ্চিত যে আপনি এই সেটিংটি পুনরায় সেট করতে চান?",
  Delete_Fax_Confirmation:"আপনি কি নিশ্চিত যে আপনি এই ফ্যাক্সটি মুছতে চান?",
  Delete_File_Confirmation:"আপনি কি নিশ্চিত যে আপনি এই ফাইলটি মুছতে চান?",
  Delete_Vacation_Confirmation:"আপনি কি নিশ্চিত যে আপনি এই ছুটি মুছে দিতে চান?",
  Delete_Printer_Confirmation:"আপনি কি নিশ্চিত যে আপনি এই প্রিন্টারটি মুছতে চান?",
  Remove_Design_Confirmation:"আপনি কি নিশ্চিত যে আপনি এই নকশাটি মুছতে চান?",
  Delete_Payroll_Confirmation:"আপনি কি নিশ্চিত যে আপনি এই বেতন মুছে ফেলতে চান?",
  Send_Fax_Email_Confirmation:"আপনি কি এই নথিগুলি ফ্যাক্স এবং ইমেল করতে চান?",
  Send_Email_Confirmation:"আপনি কি এই নথিটি ইমেল করতে চান?",
  Send_Fax_Confirmation:"আপনি কি এই নথি ফ্যাক্স করতে চান?",
  Error_Generating_PDF:"দুঃখিত। এই নথি তৈরি করার সময় একটি ত্রুটি ছিল।",
  PDF_Error_Saving_Image:"দুঃখিত। এই নথির PDF ইমেজ সংরক্ষণ করার সময় একটি ত্রুটি হয়েছে।",
  Test_Printer_Confirmation:"আপনি কি এই প্রিন্টারে একটি পরীক্ষা পৃষ্ঠা মুদ্রণ করতে চান?",
  Save_Timesheet_Prompt:"অনুগ্রহ করে একটি 'শিরোনাম' যোগ করুন বা সংরক্ষণ করতে 'স্টার্ট টাইমার' টিপুন।",
  Remove_Geofence_Prompt:"আপনি কি নিশ্চিত যে আপনি এই ভূ-বেড়ার অবস্থান সরাতে চান?",
  Delete_Employee_Confirmation:"আপনি মুছে ফেলতে চান ",
  Fire_Employee_Confirmation:"আপনি কি নিশ্চিত আপনি গুলি করতে চান"
}