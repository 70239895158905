export const Gu = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"કૉપિરાઇટ &કૉપી; 2023",
  black:"કાળો",
  green:"લીલા",
  gold:"સોનું",
  blue:"વાદળી",
  brown:"બ્રાઉન",
  purple:"જાંબલી",
  pink:"ગુલાબી",
  red:"લાલ",
  Swatches:"સ્વેચ",
  HSL:"એચએસએલ",
  RGB:"આરજીબી",
  Hue:"હ્યુ",
  Saturation:"સંતૃપ્તિ",
  Lightness:"હળવાશ",
  Upgrade_To_Pro:"પ્રો પર અપગ્રેડ કરો",
  AllFeaturesInclude:"બધી સુવિધાઓમાં શામેલ છે:",
  PrintUnlimitedCheques:"અમર્યાદિત ચેક છાપો",
  PremiumChequeDesigns:"પ્રીમિયમ ચેક ડિઝાઇન્સ",
  ManageUnlimitedEmployees:"અમર્યાદિત કર્મચારીઓનું સંચાલન કરો",
  AddUnlimitedPayees:"અનલિમિટેડ પેઇઝ ઉમેરો",
  CreateUnlimitedPayrolls:"અનલિમિટેડ પેરોલ્સ બનાવો",
  UnlimitedSchedulesandTimeSheets:"અમર્યાદિત સમયપત્રક અને સમય શીટ્સ",
  BulkPayPalPayouts:"બલ્ક પેપાલ ચૂકવણીઓ",
  UnlimitedBankAccounts:"અમર્યાદિત બેંક એકાઉન્ટ્સ",
  ManageMultipleCompanies:"બહુવિધ કંપનીઓનું સંચાલન કરો",
  TrackInsurancePolicies:"ટ્રૅક વીમા પૉલિસી",
  Bio_MetricProtection:"બાયો-મેટ્રિક પ્રોટેક્શન",
  Geo_FenceLock_OutProtection:"જીઓ-ફેન્સ લોક-આઉટ પ્રોટેક્શન",
  Multiple_Companies_Word:"ચેક પ્રીમિયમ વિના બહુવિધ કંપનીઓનું સંચાલન કરવું ઉપલબ્ધ નથી.",
  PayPal_Payouts_Word:"PayPal ચુકવણીઓ ચેક પ્રીમિયમ વિના અક્ષમ છે.",
  PINProtection:"પિન પ્રોટેક્શન",
  PasswordProtection:"પાસવર્ડ પ્રોટેક્શન",
  May_Require_Approval:"મંજૂરીની જરૂર પડી શકે છે.",
  Subscribe:"સબ્સ્ક્રાઇબ કરો",
  Billed:"બિલ કર્યું",
  Up:"ઉપર",
  Down:"નીચે",
  Positioning:"પોઝિશનિંગ",
  Marker:"માર્કર",
  Drag_Marker:"માર્કર ખેંચો",
  Tagline:"પ્રિન્ટ ચેક અને ટેબ્યુલેટ પેરોલ",
  Marker_Word:"તત્વને માપવા માટે માર્કર્સનો ઉપયોગ કરો.",
  Pinch_Zoom:"ચપટી ઝૂમ",
  Pinch_Word:"તત્વને ઝૂમ કરવા માટે પિંચ કરો.",
  Drag:"ખેંચો",
  Drag_Word:"તત્વોને ખેંચવા માટે તમારી આંગળીનો ઉપયોગ કરો.",
  subscription_alias_word:"સ્વતઃ-નવીકરણ સબ્સ્ક્રિપ્શન",
  premium_alias_word:"વન-ટાઇમ અપગ્રેડ પેકેજ",
  print_alias_word:"પ્રિન્ટ-આઉટ વ્યક્તિગત ચેક",
  mode_alias_word:"મોડ",
  Pro:"પ્રો",
  Pro_word:"પ્રો સંસ્કરણ આવશ્યક છે.",
  Cant_Delete_Default_Company_Word:"માફ કરશો, તમે તમારી ડિફોલ્ટ કંપનીને કાઢી શકતા નથી .",
  Reinsert_Default_Designs:"ડિફૉલ્ટ ડિઝાઇન ફરીથી દાખલ કરો",
  Reinsert_Default_Designs_word:"શું તમે ડિફૉલ્ટ ડિઝાઇનને ફરીથી દાખલ કરવા માંગો છો?",
  Subscription_Active_Disable_Word:"આ સબ્સ્ક્રિપ્શન સક્રિય છે. શું તમે ચેકનું આ સબ્સ્ક્રિપ્શન રદ કરવા માંગો છો?",
  Company_Logo:"કંપનીનો લોગો",
  ZERO_:"શૂન્ય",
  Disclaimer:"અસ્વીકરણ",
  Privacy_Policy:"ગોપનીયતા નીતિ",
  EULA:"EULA તપાસે છે",
  Terms_Of_Service:"સેવાની શરતો",
  Terms_Of_Use:"વાપરવાના નિયમો",
  Refunds:"રિફંડ નીતિ",
  Single_Print:"1 તપાસો",
  Two_Prints:"2 ચેક",
  Five_Prints:"5 ચેક",
  Ten_Prints:"10 ચેક",
  Fifteen_Prints:"15 ચેક",
  Twenty_Prints:"20 ચેક",
  Thirty_Prints:"30 ચેક",
  Image_Added:"છબી ઉમેરી",
  Image_Preview:"છબી પૂર્વાવલોકન",
  No_Image_Was_Selected:"કોઈ છબી પસંદ કરવામાં આવી ન હતી.",
  Cheques_Unlimited:"અનલિમિટેડ ચેક કરે છે",
  _Subscription:"સબ્સ્ક્રિપ્શન",
  Subscription:"ચેક - 1 મહિનો",
  Two_Month_Subscription:"ચેક - 2 મહિના",
  Three_Month_Subscription:"ચેક - 3 મહિના",
  Six_Month_Subscription:"ચેક - 6 મહિના",
  Twelve_Month_Subscription:"ચેક - 12 મહિના",
  Cheques_Are_Available:"ચેક પ્રિન્ટ કરવા માટે ઉપલબ્ધ છે.",
  Upgrade_Required_Two:"એક પેકેજ પસંદ કરો અને તમારી ખરીદી શરૂ કરવા માટે કિંમત બટન પર બે વાર ટૅપ કરો. પ્રોફેશનલ દેખાતા ફુલ-કલર ચેકને સેકંડમાં પ્રિન્ટ કરો.",
  Month:"માસ",
  Due:"નિયત:",
  Expires:"સમાપ્તિ:",
  Subscription_Active:"સબ્સ્ક્રિપ્શન સક્રિય",
  Subscription_Inactive:"સબ્સ્ક્રિપ્શન નિષ્ક્રિય",
  Purchase_Additional_Cheques:"વધારાના ચેક ખરીદો છો?",
  Printable_Cheque:"છાપવાયોગ્ય ચેક",
  Printable_Cheques:"છાપવાયોગ્ય તપાસો",
  Printable_Cheque_Word:"ચેક તમારા એકાઉન્ટ પર ઉપલબ્ધ છે.",
  Printable_Cheques_Word:"ચેક તમારા એકાઉન્ટ પર ઉપલબ્ધ છે.",
  Max_Amount_Message:"તમે ઉલ્લેખિત કરેલ રકમ આ સિસ્ટમ માટે નિર્ધારિત મહત્તમ રકમ પર પહોંચી ગઈ છે:",
  Terms_Required_Word:"ચેકનો ઉપયોગ કરવાનું ચાલુ રાખતા પહેલા તમારે આ કરાર સાથે સંમત થવું આવશ્યક છે.",
  Subscriptions:"સબ્સ્ક્રિપ્શન્સ",
  Product_Upgrades:"અપગ્રેડ",
  Mailed_Out_Cheques:"મેઇલ-આઉટ ચેક",
  Enter_A_Company_Name:"કૃપા કરીને કંપનીનું નામ દાખલ કરો.",
  Single_Cheques:"સિંગલ ચેક",
  Cheque_Golden:"ગોલ્ડન ચેક",
  Cheque_Golden_Window:"ગોલ્ડન ચેક ડિઝાઇન.",
  Cheque_Green:"ગ્રીન ચેક",
  Cheque_Green_Window:"ગ્રીન ચેક ડિઝાઇન.",
  Cheque_Red:"રેડ ચેક",
  Cheque_Red_Window:"લાલ ચેક ડિઝાઇન.",
  Cheque_Yellow:"યલો ચેક",
  Cheque_Yellow_Window:"પીળી ચેક ડિઝાઇન.",
  Cheque_Statue_of_Liberty:"સ્વતત્રતા ની મુરતી",
  Cheque_Statue_of_Liberty_Window:"સ્ટેચ્યુ ઓફ લિબર્ટી ચેક ડિઝાઇન.",
  Cheque_Green_Wave:"ગ્રીન વેવ",
  Cheque_Green_Wave_Window:"ગ્રીન ચેક ડિઝાઇન.",
  Cheque_Golden_Weave:"ગોલ્ડન વીવ",
  Cheque_Golden_Weave_Window:"ભવ્ય ગોલ્ડન ચેક ડિઝાઇન.",
  Cheque_Green_Sun:"લીલો સૂર્ય",
  Cheque_Green_Sun_Window:"ઊંડા અને શાંત ચેક ડિઝાઇન.",
  Cheque_Blue_Checkers:"બ્લુ ચેકર્સ",
  Cheque_Blue_Checkers_Window:"બ્લુ ચેક ડિઝાઇન.",
  Cashiers_Check:"કેશિયર ચેક",
  Cashiers_Check_Window:"કેશિયર ચેક શૈલી નમૂનો.",
  Cheque_Aqua_Checkers:"એક્વા ચેકર્સ",
  Cheque_Aqua_Checkers_Window:"એક્વા ચેક ડિઝાઇન.",
  Cheque_Golden_Checkers:"ગોલ્ડન ચેકર્સ",
  Cheque_Golden_Checkers_Window:"ગોલ્ડન ચેક ડિઝાઇન.",
  Upgrade_Unavailable:"અપગ્રેડ અનુપલબ્ધ",
  Bank_Code_Protection:"બેંક નંબર પ્રોટેક્શન",
  Bank_Code_Protection_Word:"તમારા બેંક નંબરોને અન્ય ઉપકરણ પર અથવા અન્ય વપરાશકર્તા માટે ચાલતી આ એપ્લિકેશન પર ઉપયોગમાં લેવાથી સુરક્ષિત કરો. આ ક્રિયા બદલી ન શકાય તેવી છે. ચાલુ રાખીએ?",
  Bank_Code_Protection_Blocked_Word:"તમે જે બેંક નંબરોનો ઉપયોગ કરવાનો પ્રયાસ કરી રહ્યાં છો તે અન્ય વપરાશકર્તા અથવા ઉપકરણ માટે આરક્ષિત છે.",
  Bank_Code_Protection_Error_Word:"નંબર ચકાસણી નિષ્ફળ ગઈ છે. કૃપા કરીને ઇન્ટરનેટથી કનેક્ટ કરો અને આ બેંક એકાઉન્ટને ફરીથી ઉમેરવાનો પ્રયાસ કરો.",
  Bank_Code_Protection_Set_Error_Word:"બેંક નંબર સુરક્ષા માટે જરૂરી છે કે તમે ઇન્ટરનેટથી કનેક્ટેડ હોવ. કૃપા કરીને કનેક્ટ કરો અને ફરી પ્રયાસ કરો.",
  Upgrade_Unavailable_Word:"માફ કરશો, અમને તમારી ચકાસણી કરવામાં મુશ્કેલી આવી રહી છે. ચેકમાં સબ્સ્ક્રિપ્શન્સ અને અપગ્રેડ હાલમાં તમારા વિસ્તારમાં ખરીદી માટે અનુપલબ્ધ છે.",
  PayPal_Payment_Preview:"પેપાલ ચુકવણી પૂર્વાવલોકન",
  Apple_Pay:"એપલ પે",
  Select_PayPal:"પેપાલ પસંદ કરો",
  PayPal_Applications:"પેપાલ એપ્લિકેશન્સ",
  Purchase_With_Apple_Pay:"Apple Pay વડે ખરીદી કરો",
  Google_Pay:"Google Pay",
  Companies:"કંપનીઓ",
  Insurance:"વીમા",
  New_PayPal:"નવું પેપાલ",
  Pay_By:"દ્વારા ચૂકવો",
  Insure:"વીમો",
  Miles:"માઇલ",
  Payment_Method:"ચુકવણી પદ્ધતિ",
  Select_Policies:"નીતિઓ પસંદ કરો",
  Policies:"નીતિઓ",
  Policy:"નીતિ",
  No_PayPal_Account:"કોઈ PayPal એકાઉન્ટ નથી",
  No_Policies:"કોઈ વીમા પૉલિસી નથી",
  New_Policy:"નવી નીતિ",
  PayPal_Payment:"પેપાલ ચુકવણી",
  PayPal_Payments:"પેપાલ ચુકવણીઓ",
  No_Payment_Selected:"કોઈ ચુકવણી પસંદ કરેલ નથી",
  Sending_Payment_Word:"કૃપા કરીને રાહ જુઓ... આ ચુકવણી મોકલવામાં આવી રહી છે.",
  Sending_Payments_Word:"કૃપા કરીને રાહ જુઓ... ચુકવણીઓ મોકલવામાં આવી રહી છે.",
  No_Payment_Selected_PayPal:"કોઈ <a routerLink='/folder/Payments'>PayPal ચુકવણી</a> પસંદ કરી નથી.",
  Payment_Sent:"ચુકવણી મોકલી",
  PayPal_Payment_Sent:"આ ચુકવણી PayPal દ્વારા મોકલવામાં આવી છે.",
  Copay:"નકલ કરો",
  Dead:"મૃત",
  Alive:"જીવંત",
  Not_Dead:"મૃત નથી",
  Unclaimed:"દાવો ન કર્યો",
  Attempted:"પ્રયાસ કર્યો",
  Deceased:"મૃત",
  Claimed:"દાવો કર્યો",
  Unpaid:"અવેતન",
  Sending_Payment:"ચુકવણી મોકલી રહ્યું છે",
  Sending_Payments:"ચુકવણીઓ મોકલી રહ્યું છે",
  Send_PayPal_Confirmation:"શું તમે PayPal સાથે આ વ્યવહાર મોકલવા માંગો છો?",
  Send_PayPal_Confirmation_Word:"આ વ્યવહાર મોકલવા માટે લીલું બટન દબાવો.",
  Save_Payment_As_Unpaid:"આ ચુકવણીને અવેતન તરીકે સાચવીએ?",
  Payment_Pay_Confirmation_PayPal:"આ પેમેન્ટને પેઇડ તરીકે સાચવીએ?",
  No_Policies_Word:"પ્રથમ <a routerLink='/folder/Postage/New'>વીમા પૉલિસી</a> ઉમેરો .",
  Timesheet_Multiple_Delete_Confirmation:"શું તમે ખરેખર બહુવિધ ટાઇમશીટ્સ કાઢી નાખવા માંગો છો?",
  Select_Multiple_Timesheets_Prompt:"કોઈ સમયપત્રક પસંદ કરેલ નથી. ઓછામાં ઓછી એક ટાઈમશીટ પસંદ કરો.",
  Select_Multiple_Policies_Prompt:"કોઈ નીતિઓ પસંદ કરી નથી. ઓછામાં ઓછી એક વીમા પૉલિસી પસંદ કરો.",
  Policies_Multiple_Delete_Confirmation:"શું તમે ખરેખર બહુવિધ નીતિઓ કાઢી નાખવા માંગો છો?",
  Company:"કંપની",
  Add_Company:"કંપની ઉમેરો",
  New_Company:"કંપની ઉમેરો",
  No_Companies:"કોઈ કંપનીઓ નથી",
  Enable_Company:"કંપનીને સક્ષમ કરો",
  Select_Company:"કંપની પસંદ કરો",
  The_Default_Company:"ડિફૉલ્ટ કંપની લેબલ.",
  Manage_Companies:"કંપનીઓનું સંચાલન કરો",
  No_Companies_Word:"ચેક ડિફોલ્ટ કંપનીનો ઉપયોગ કરશે .<br /> <a routerLink='/folder/Company/New'>પ્રથમ કંપની</a> ઉમેરો .",
  Default_Company:"ડિફોલ્ટ કંપની",
  Cheques_Unlimited_Word:"ચેક્સ અનલિમિટેડ તમને ગમે તેટલા ચેક પ્રિન્ટ કરવાની મંજૂરી આપે છે.",
  Cheques_Subscription_Word:"ચેક્સ સબસ્ક્રિપ્શન તમને ગમે તેટલા ચેક પ્રિન્ટ કરવાની મંજૂરી આપે છે.",
  You_Own_This_Product:"તમે આ ઉત્પાદનના માલિક છો.",
  Your_Subscription_is_Active:"તમારું સબ્સ્ક્રિપ્શન સક્રિય છે.",
  Active_Products:"સક્રિય ઉત્પાદનો",
  Purchase_Confirmation:"ખરીદી",
  Purchase_Cheques:"ખરીદી ચેક",
  Restore_Purchase:"ખરીદીઓ પુનઃસ્થાપિત કરો",
  Erase_Purchase:"ખરીદીઓ ભૂંસી નાખો",
  Successfully_Purchased:"સફળતાપૂર્વક ખરીદી",
  Enter_Your_Licence_Key:"આ ઉત્પાદનને સક્રિય કરવા માટે કૃપા કરીને આ પૃષ્ઠ પર તમારી લાઇસન્સ કી દાખલ કરો.",
  Licence_Key:"લાઇસન્સ કી",
  Enter_Licence_Key:"લાઇસન્સ કી દાખલ કરો",
  Purchased:"ખરીદી",
  Enable_Feature:"સુવિધા સક્ષમ કરો",
  Cancel_Subscription:"ઉમેદવારી રદ કરો",
  Subscription_Removed:"સબ્સ્ક્રિપ્શન દૂર કર્યું",
  Select_Active_Subscription:"તેને સંશોધિત કરવા માટે સક્રિય સબ્સ્ક્રિપ્શન પસંદ કરો.",
  Remove_Subscription_Word:"શું તમે ખરેખર આ સબ્સ્ક્રિપ્શન રદ કરવા માંગો છો?",
  Delete_Company_Confirmation:"શું તમે ખરેખર આ સમગ્ર કંપનીને કાઢી નાખવા માંગો છો? ચેતવણી: તમે બધી સંગ્રહિત માહિતી ગુમાવશો!",
  Delete_Default_Company_Word:"તમે ડિફોલ્ટ કંપનીને કાઢી શકતા નથી . શું તમે એપ્લિકેશનને રીસેટ કરવા અને તેને ડિફોલ્ટ સ્થિતિમાં પુનઃસ્થાપિત કરવા માંગો છો? ચેતવણી: તમે બધી સંગ્રહિત માહિતી ગુમાવશો!",
  Console_Warning_2:"આ એપ્લિકેશનનો ઉપયોગ કરીને કોઈપણ ચલણને હેન્ડલ કરશો નહીં જે હાલમાં તમારી નથી.",
  Terms_and_Conditions:"નિયમો અને શરત",
  and_the:"અને",
  for:"માટે",
  Please_Read_Word:"કૃપા કરીને વાંચો અને સંમત થાઓ",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"કેટલાક ચલણ રૂપાંતરણ દરો શોધી શકાયા નથી. કૃપા કરીને ઇન્ટરનેટથી કનેક્ટ કરો.",
  Free:"મફત",
  DB_Erase_Prompt_2:"સંપૂર્ણ વિકાસકર્તા ડેટાબેઝને સંપૂર્ણપણે ભૂંસી નાખીએ? ચેતવણી: તમે બધી ખરીદી અને સબ્સ્ક્રિપ્શન માહિતી ગુમાવશો!",
  Successfully_Imported:"સફળતાપૂર્વક આયાત કર્યું",
  Select_Postage:"પોસ્ટેજ પસંદ કરો",
  No_Postage:"કોઈ પોસ્ટેજ સ્ટેમ્પ નથી",
  No_Paid_Postage_Word:"પ્રથમ <a routerLink='/folder/Postage/New'>પેઇડ પોસ્ટેજ</a> સ્ટેમ્પ ઉમેરો .",
  Trial_Complete:'ટ્રાયલ પૂર્ણ',
  Please_Upgrade:'પ્રિન્ટિંગ ચાલુ રાખવા માટે કૃપા કરીને ચેક અપગ્રેડ કરો.',
  Aa:"અઅ",
  Color:"રંગ",
  Font:"ફોન્ટ",
  Guide:"માર્ગદર્શન",
  Guides:"માર્ગદર્શિકાઓ",
  Image:"છબી",
  Zoom:"ઝૂમ કરો",
  Logo:"લોગો",
  Bank:"બેંક",
  MICR:"MICR",
  Total:"કુલ",
  Cancel:"રદ કરો",
  Confirm:"પુષ્ટિ કરો",
  Method:"પદ્ધતિ",
  Biometric_Security:"બાયોમેટ્રિક સુરક્ષા",
  Please_Login_To_Continue:"ચાલુ રાખવા માટે કૃપા કરીને લૉગિન કરો.",
  Complete:"પૂર્ણ",
  Sign_Up:"સાઇન અપ કરો",
  Error:"ભૂલ",
  Biometrics:"બાયોમેટ્રિક્સ",
  Percent:"ટકા",
  Zero_Percent:"0%",
  Top_Margin:"ટોચનો માર્જિન",
  Bottom_Margin:"બોટમ માર્જિન",
  Left_Margin:"ડાબો હાંસિયો",
  Right_Margin:"જમણો હાંસિયો",
  MICR_Margin:"MICR માર્જિન",
  Table_Margin:"કોષ્ટક માર્જિન",
  Address_Margin:"સરનામું માર્જિન",
  Percentage_:"ટકાવારી",
  Vacation_Title:"વેકેશન શીર્ષક",
  Use_PIN:"PIN નો ઉપયોગ કરો",
  Loading__:"લોડ કરી રહ્યું છે...",
  Design_Title:"ડિઝાઇન શીર્ષક",
  Authorize:"અધિકૃત કરો",
  Key:"કી",
  Public_Key:"સાર્વજનિક કી",
  Private_Key:"ખાનગી કી",
  Authenticate:"પ્રમાણિત કરો",
  Last_Payroll:"છેલ્લું પેરોલ",
  Last_Calculation:"છેલ્લી ગણતરી",
  Authorized:"અધિકૃત",
  Geo_Authorized:"ભૌગોલિક સ્થાન: અધિકૃત",
  Not_Authorized:"અધિકૃત નથી",
  Authorization_Complete:"અધિકૃતતા પૂર્ણ",
  Geolocation_Authorization:"ભૌગોલિક સ્થાન અધિકૃતતા",
  Out_of_Bounds:"બાઉન્ડ્સની બહાર",
  Cant_Delete_Default_Design:"ડિફૉલ્ટ ડિઝાઇન કાઢી શકાતી નથી.",
  Unauthorized_Out_of_Bounds:"અનધિકૃત: હદ બહાર",
  Biometric_Authorization:"બાયોમેટ્રિક અધિકૃતતા",
  Locating_Please_Wait:"શોધી રહ્યું છે, કૃપા કરીને રાહ જુઓ...",
  Test_Biometrics:"બાયોમેટ્રિક્સનું પરીક્ષણ કરો",
  Cheque_Margins:"માર્જિન તપાસો",
  Percentage_Full_Error:"ટકાવારી ક્ષેત્ર 100% ટકાથી વધુ સેટ કરી શકાતું નથી.",
  No_Payroll_Text:"<a routerLink='/folder/Employee/New'>કર્મચારી</a> અથવા <a routerLink='/folder/Payee/New'>ચુકવનાર</a> અને <a routerLink='/folder/Schedule/New'>શેડ્યૂલ</a>.",
  Design_Saved:"ડિઝાઇન સાચવી",
  Default_Design_Selected:"ડિફૉલ્ટ ડિઝાઇન પસંદ કરી",
  Partial_Import:"આંશિક આયાત",
  Partial_Export:"આંશિક નિકાસ",
  Entire_Import:"સમગ્ર આયાત",
  Entire_Export:"સમગ્ર નિકાસ",
  Existing_Password:"કૃપા કરીને તમારો હાલનો પાસવર્ડ દાખલ કરો:",
  Existing_PIN:"કૃપા કરીને તમારો હાલનો પિન કોડ દાખલ કરો:",
  Pin_Change_Header:"PIN પુષ્ટિકરણ",
  Pin_Change_SubHeader:"ફેરફારની પુષ્ટિ કરવા માટે તમારો જૂનો PIN નંબર દાખલ કરો.",
  Pass_Change_Header:"પાસવર્ડ ખાતરી",
  Pass_Change_SubHeader:"ફેરફારની પુષ્ટિ કરવા માટે તમારો જૂનો પાસવર્ડ દાખલ કરો.",
  PIN_Enter_Message:"પુષ્ટિ કરવા માટે તમારો PIN દાખલ કરો.",
  Password_Enter_Message:"પુષ્ટિ કરવા માટે તમારો પાસવર્ડ દાખલ કરો.",
  Pin_Updated_Success:"PIN સફળતાપૂર્વક અપડેટ થયો!",
  Pin_Updated_Fail:"PIN અપડેટ કરી શકાયો નથી.",
  Pass_Updated_Success:"પાસવર્ડ સફળતાપૂર્વક અપડેટ થયો.",
  Pass_Updated_Fail:"પાસવર્ડ અપડેટ કરી શકાયો નથી.",
  Preview_Payment:"પૂર્વાવલોકન ચુકવણી",
  Preview_Payroll:"પેરોલનું પૂર્વાવલોકન કરો",
  No_Payments_Created:"કોઈ ચૂકવણી બનાવવામાં આવી નથી.",
  Payment_Preview:"ચુકવણી પૂર્વાવલોકન",
  Enable_Payee:"લેનારને સક્ષમ કરો",
  Rendered:"રેન્ડર કર્યું",
  No_Email:"કોઈ ઈમેલ નથી",
  Setup_Cheques:"સેટઅપ તપાસો",
  name:"નામ",
  address:"સરનામું",
  address_2:"સરનામાની લાઇન નં 2",
  city:"શહેર",
  province:"પ્રાંત",
  postal_code:"પોસ્ટલ/ઝિપ કોડ",
  country:"દેશ",
  username:"વપરાશકર્તા નામ",
  full_name:"પૂરું નામ",
  birthday:"જન્મદિવસ",
  email:"ઈમેલ",
  phone:"ફોન",
  employees:"કર્મચારીઓ",
  addresses:"સરનામાં",
  payment_amount_limit:"ચુકવણી રકમ મર્યાદા",
  total_limit:"કુલ મર્યાદા",
  payees:"ચૂકવણી કરનારાઓ",
  description:"વર્ણન",
  address_line_one:"સરનામું લાઇન વન",
  address_line_two:"સરનામું લાઇન બે",
  image:"છબી",
  account_holder:"ખાતાધારક",
  cheque_starting_id:"પ્રારંભિક ID તપાસો",
  transit_number:"ટ્રાન્ઝિટ નંબર",
  institution_number:"સંસ્થા નંબર",
  designation_number:"હોદ્દો નંબર",
  account_number:"ખાતા નંબર",
  currency:"ચલણ",
  signature:"સહી",
  payment_payroll_limit:"ચુકવણી મર્યાદા",
  total_limi:"કુલ મર્યાદા",
  date:"તારીખ",
  printed_memo:"પ્રિન્ટેડ મેમો",
  banks:"બેંકો",
  signature_image:"હસ્તાક્ષર છબી",
  address_name:"સરનામું નામ",
  notes:"નોંધો",
  design:"ડિઝાઇન",
  title:"શીર્ષક",
  frequency:"આવર્તન",
  fax:"ફેક્સ",
  salaries:"પગાર",
  salary_ids:"પગાર IDs",
  start_time:"પ્રારંભ સમય",
  end_time:"સમાપ્તિ સમય",
  paid:"ચૂકવેલ",
  overtime_percentage:"ચૂકવેલ ટકાવારી",
  overtime_amount:"ચૂકવેલ નિશ્ચિત રકમ",
  first_name:"પ્રથમ નામ",
  last_name:"છેલ્લું નામ",
  moderation:"મધ્યસ્થતા",
  create:"બનાવો",
  edit:"સંપાદિત કરો",
  destroy:"નાશ",
  day_start_time:"દિવસનો_પ્રારંભ_સમય",
  day_end_time:"દિવસ_અંત_સમય",
  fullname:"નામ",
  time:"સમય",
  auto_send:"આપોઆપ મોકલો",
  time_method:"સમય પદ્ધતિ",
  schedules:"સમયપત્રક",
  indefinite_payroll_enabled:"અનિશ્ચિત રૂપે સક્ષમ કરો",
  amount:"રકમ",
  repeat:"પુનરાવર્તન કરો",
  always_enabled:"હંમેશા સક્ષમ",
  start_date:"પ્રારંભ તારીખ",
  end_date:"અંતિમ તારીખ",
  Cheque_Total:"કુલ તપાસો",
  Total_Amount:"કુલ રકમ:",
  Amounts:"રકમો:",
  Images:"છબીઓ",
  Files:"ફાઈલો",
  Previewing:"પૂર્વાવલોકન:",
  Insert:"દાખલ કરો",
  Preview_Import:"પૂર્વાવલોકન આયાત",
  Entry:"પ્રવેશ",
  Entries:"પ્રવેશો",
  No_Entries:"કોઈ એન્ટ્રી નથી",
  Import_Type:"આયાત પ્રકાર",
  Select_Details:"વિગતો પસંદ કરો",
  Select_Payee:"Payee પસંદ કરો",
  Enable_Holidays:"રજાઓ સક્ષમ કરો",
  Disable_Holidays:"રજાઓ અક્ષમ કરો",
  Wire_Transfer:"વાયર ટ્રાન્સફર",
  New_Export:"નવી નિકાસ",
  Export_Data:"ડેટા નિકાસ કરો",
  Export_Data_Word:"નિકાસ અને ડાઉનલોડ કરવા માટે ફાઇલનો પ્રકાર પસંદ કરો.",
  Export_File:"નિકાસ ફાઇલ",
  Mode:"મોડ",
  Times:"વખત",
  Widgets:"વિજેટ્સ",
  Slider:"સ્લાઇડર",
  Set_Details:"વિગતો સેટ કરો",
  Select_Type:"પ્રકાર પસંદ કરો",
  Display_Slider:"સ્લાઇડર દર્શાવો",
  Dashboard_Slider:"ડેશબોર્ડ સ્લાઇડર",
  Dashboard_Mode:"ડેશબોર્ડ મોડ",
  Show_Intro:"પ્રસ્તાવના બતાવો",
  Show_Payrolls:"પેરોલ્સ બતાવો",
  Show_Holidays:"રજાઓ બતાવો",
  Show_Invoices:"ઇન્વૉઇસેસ બતાવો",
  Show_Cheques:"ચેક બતાવો",
  Enabled_Widgets:"સક્ષમ વિજેટો",
  Disabled_Widgets:"અક્ષમ વિજેટો",
  Colors:"રંગો",
  Barcodes:"બારકોડ",
  View_Timers:"ટાઈમર જુઓ",
  Gradients:"ગ્રેડિયન્ટ્સ",
  No_Info:"કોઈ માહિતી નથી",
  Disable:"અક્ષમ કરો",
  Show_Layer:"સ્તરો બતાવો",
  Hide_Layer:"સ્તરો છુપાવો",
  Text_Layer:"ટેક્સ્ટ સ્તરો",
  Secondly:"બીજું",
  Minutely:"મિનિટે",
  nine_am:"સવારના 9:00",
  five_pm:"5:00 PM",
  Enable_Address:"સરનામું સક્ષમ કરો",
  Invalid_File_Type:"માફ કરશો, એક અમાન્ય ફાઇલ પ્રકાર પસંદ કરવામાં આવ્યો હતો. સપોર્ટેડ ફાઇલ પ્રકાર:",
  Error_Updating_Entry:"માફ કરશો, આ એન્ટ્રી અપડેટ કરવામાં ભૂલ આવી હતી.",
  Schedule_Timing_Alert:"ભૂલ: શેડ્યૂલ શરૂ થવાનો સમય સમાપ્તિ સમય પછીના મૂલ્ય પર સેટ છે.",
  Select_Multiple_Payments_Prompt:"કોઈ ચૂકવણી પસંદ કરેલ નથી. ઓછામાં ઓછી એક ચુકવણી પસંદ કરો.",
  Select_Multiple_Cheques_Prompt:"કોઈ ચેક પસંદ કર્યા નથી. કૃપા કરીને ઓછામાં ઓછો એક ચેક પસંદ કરો.",
  Select_Multiple_Items_Prompt:"કોઈ આઇટમ પસંદ કરી નથી. કૃપા કરીને ઓછામાં ઓછી એક આઇટમ પસંદ કરો.",
  Paymemt_Multiple_Delete_Confirmation:"શું તમે ખરેખર બહુવિધ ચુકવણીઓ કાઢી નાખવા માંગો છો?",
  Cheque_Multiple_Delete_Confirmation:"શું તમે ખરેખર બહુવિધ ચેક કાઢી નાખવા માંગો છો?",
  Payee_Multiple_Delete_Confirmation:"શું તમે ખરેખર બહુવિધ ચુકવણીકારોને કાઢી નાખવા માંગો છો?",
  Employee_Multiple_Delete_Confirmation:"શું તમે ખરેખર બહુવિધ કર્મચારીઓને કાઢી નાખવા માંગો છો?",
  MICR_Warning:"નોંધ: કેટલાક પ્રિન્ટરો અને ઉપકરણો MICR ફોન્ટને યોગ્ય રીતે પ્રદર્શિત કરી શકતા નથી.",
  Automatically_Send:"આપોઆપ મોકલો",
  Type:"પ્રકાર",
  Payment_Type:"ચુકવણીનો પ્રકાર",
  Auto_Send:"સ્વતઃ મોકલો",
  Automatically_Process:"આપમેળે પ્રક્રિયા",
  Auto_Process:"ઓટો પ્રક્રિયા",
  Image_Based:"છબી આધારિત",
  Font_Based:"ફોન્ટ આધારિત",
  Rerender:"ફરીથી રેન્ડર",
  Rendering:"રેન્ડરીંગ",
  Render:"ફાઈલો",
  Top:"ટોચ",
  Middle:"મધ્ય",
  Bottom:"તળિયે",
  Top_Left:"ટોચ ડાબી",
  Top_Center:"ટોચનું કેન્દ્ર",
  Top_Right:"ઉપર જમણે",
  Middle_Left:"મધ્ય ડાબી",
  Middle_Center:"મધ્ય કેન્દ્ર",
  Middle_Right:"મધ્ય જમણે",
  Bottom_Left:"નીચે ડાબી બાજુ",
  Bottom_Center:"બોટમ સેન્ટર",
  Bottom_Right:"નીચે જમણું",
  Numbers:"સંખ્યાઓ",
  Verified:"ચકાસણી",
  Paper_Size:"કાગળનું કદ",
  New_Device:"નવું ઉપકરણ",
  Add_Device:"ઉપકરણ ઉમેરો",
  Remove_Device:"ઉપકરણ દૂર કરો",
  Delete_Device:"ઉપકરણ કાઢી નાખો",
  Block_Device:"ઉપકરણને અવરોધિત કરો",
  Block:"બ્લોક",
  Unblock:"અનાવરોધિત કરો",
  Table:"ટેબલ",
  Scan_Login_Code:"લૉગિન કોડ સ્કેન કરો",
  Login_Code:"લૉગિન કોડ",
  Scan_Code:"સ્કેન કોડ",
  Scan_QR_Code:"QR કોડ સ્કેન કરો",
  Select_All:"બધા પસંદ કરો",
  Deselect_All:"બધાને નાપસંદ કરો",
  Increase:"વધારો",
  Decrease:"ઘટાડો",
  Bold:"બોલ્ડ",
  Text:"ટેક્સ્ટ",
  Style:"શૈલી",
  Italic:"ત્રાંસી",
  QR_Code:"QR કોડ",
  Barcode:"બારકોડ",
  Browse_Images:"છબીઓ બ્રાઉઝ કરો",
  Browse_Files:"ફાઈલો બ્રાઉઝ કરો",
  Background_Image:"પૃષ્ઠભૂમિ છબી",
  Logo_Image:"લોગો છબી",
  Header_Image:"હેડર છબી",
  Bank_Image:"બેંક છબી",
  Cut_Lines:"રેખાઓ કાપો",
  Background:"પૃષ્ઠભૂમિ",
  License:"લાઇસન્સ",
  One_License:"1 લાઇસન્સ:",
  Licensed:"આના માટે લાઇસન્સ:",
  Not_Licensed:"લાઇસન્સ નથી",
  Enter_Serial:"સીરીયલ દાખલ કરો",
  Serial_Key:"સીરીયલ કી",
  Serial:"સીરીયલ",
  Product_Key:"ઉત્પાદન કી",
  Check_Product_Key:"ઉત્પાદન કી તપાસો",
  Add_Product_Key:"ઉત્પાદન કી ઉમેરો",
  Verifying_Purchase:"ખરીદી ચકાસી રહ્યું છે...",
  Print_Envelope:"પરબિડીયું છાપો",
  Envelope:"પરબિડીયું",
  Thank_You:"આભાર!",
  Scale:"સ્કેલ",
  Print_Scale:"પ્રિન્ટ સ્કેલ",
  Borders:"સરહદો",
  VOID:"રદબાતલ",
  Void_Cheque:"રદબાતલ તપાસ",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ના આદેશ પર ચુકવો:",
  NO_DOLLARS:"કોઈ ડૉલર નથી ",
  ONE_DOLLAR:"એક ડોલર",
  DOLLARS:" ડૉલર",
  AND:" અને ",
  CENTS:" સેન્ટ્સ.",
  NO_:"ના ",
  ONE_:"એક ",
  AND_NO_:"અને ના ",
  _AND_ONE_:"અને એક ",
  DOLLARS_AND_ONE_CENT:" અને એક સેન્ટ.",
  AND_NO_CENTS:" અને શૂન્ય સેન્ટ્સ.",
  CHEQUE_PRINT_DATE:"તારીખ:",
  CHEQUE_PRINT_MEMO:"યાદી:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"પ્રારંભિક કાર્યો",
  Inital_Setup:"પ્રારંભિક સેટઅપ",
  Welcome_To:"સ્વાગત ",
  Welcome:"સ્વાગત",
  Swipe:"સ્વાઇપ",
  Intro_Setup:"પરિચય સેટઅપ",
  Introduction:"પરિચય",
  CHEQUE_PRINT_CREDIT:"ચેક દ્વારા સંચાલિત",
  Title_Intro_Word:"ચેકમાં આપનું સ્વાગત છે",
  Title_Intro:"ચેકનો પરિચય",
  Title_Setup:"ચેક્સ સેટઅપ",
  PayPal_Default_Email_Message:"તમે એક નવું પેપાલ ટ્રાન્સફર મેળવ્યું છે. [ચેક્સ દ્વારા સંચાલિત]",
  Cheques_App_Export:"ચેક દ્વારા નિકાસ કરેલ",
  Post_Intro_Thanks:"ચેક પસંદ કરવા બદલ તમારો આભાર. સેટઅપ પ્રક્રિયા હવે પૂર્ણ થઈ ગઈ છે.",
  Post_Intro_Word:"તમારો પ્રથમ ચેક પ્રિન્ટ કરીને, ભાવિ ચુકવણી ઉમેરો અથવા પગારપત્રકમાં કર્મચારીઓને ઉમેરીને પ્રારંભ કરો.",
  Upgrade_Required:"આ સંદેશને છુપાવવા અને વધારાની સુવિધાઓને અનલૉક કરવા માટે તમારે સૉફ્ટવેરનું વધુ પ્રીમિયમ સંસ્કરણ ધરાવવું જરૂરી છે.",
  Upgrade_Title:"ચેક",
  Mailout_Prompt:"તમે વધુમાં તમારા પેરોલ ચેક તમારા માટે ચેક મેઇલ-આઉટ કરવાનું પસંદ કરી શકો છો.",
  Mailed_Cheque:"મેઇલ કરેલ ચેક:",
  Mailed_Cheque_Color:"મેઇલ કરેલ ચેક (રંગ):",
  Terms_Purchase:"ચેક સાથેની તમામ ઈલેક્ટ્રોનિક ખરીદીઓ ખરીદીની તારીખથી 30-દિવસ સુધી સંપૂર્ણપણે રિફંડપાત્ર છે. કૃપા કરીને ચાલુ રાખતા પહેલા <a href='#'>નિયમો અને શરતો</a> વાંચો અને સંમત થાઓ.",
  Dashboard_Setup:"પ્રાથમિક પ્રિન્ટર સેટઅપ કરો",
  Dashboard_Add:"પ્રાથમિક બેંક ખાતું ઉમેરો",
  Dashboard_Customize:"ચેક ટેમ્પલેટ પસંદ કરો",
  Dashboard_Job_Salary:"તમારી નોકરી બનાવો અને તમારો પગાર ઉમેરો",
  Dashboard_Employees:"કર્મચારીઓ અને પગારદારોને ટ્રૅક કરો",
  Dashboard_Print:"તમારા પેચેક્સ છાપો અને મેઇલ કરો",
  Dashboard_Payroll:"તમારા પેરોલ પ્રિન્ટીંગને સ્વચાલિત કરો",
  Dashboard_PayPal:"પેપાલ પેરોલ / પેઆઉટ સેટ કરો",
  Begin_Setup:"સેટઅપ શરૂ કરો",
  Get_Started:"શરૂ કરો",
  Purchase:"ખરીદી",
  Lockdown:"લૉકડાઉન",
  Unlock:"અનલૉક",
  Detailed:"વિગતવાર",
  Log_In:"પ્રવેશ કરો",
  Login:"પ્રવેશ કરો",
  Launch:"લોન્ચ",
  Register:"નોંધણી કરો",
  Finish:"સમાપ્ત",
  List:"યાદી",
  Weekends:"સપ્તાહાંત",
  Weekly:"સાપ્તાહિક",
  PayPal_Default_Subject:"નવી ચુકવણી",
  Payment_Message:"ચુકવણી સંદેશ",
  PayPal_Default_Payment_Note:"આભાર",
  Setup_Your_Cheqing_Account:"ચેકિંગ એકાઉન્ટ",
  Add_Your_Primary_Cheqing_Account:"તમારું પ્રાથમિક ચેકિંગ એકાઉન્ટ ઉમેરો.",
  Welcome_Word:"પેરોલ અને માનવ સંસાધન સંચાલનને સરળ અને સ્વચાલિત કરો.",
  Get_Started_Quickly:"ફક્ત થોડા સરળ પ્રશ્નોના જવાબ આપો જે અમને પ્રારંભ કરવામાં મદદ કરશે...",
  Done_Intro_Word:"સેટઅપ પૂર્ણ",
  PIN_Protected:"પાસવર્ડ અને PIN સુરક્ષિત",
  Enter_New_PIN:"નવો પિન કોડ દાખલ કરો:",
  Enter_PIN:"પીન કોડ દાખલ કરો:",
  Invalid_PIN:"અમાન્ય PIN દાખલ કર્યો.",
  Account_Identifier:"એકાઉન્ટ આઇડેન્ટિફાયર",
  New_Account_Identifier:"નવું એકાઉન્ટ ઓળખકર્તા",
  attempt:"પ્રયત્ન",
  attempts:"પ્રયત્નો",
  remaining:"બાકી",
  Language:"ભાષા",
  languages:"ભાષાઓ",
  select_languages:"ભાષા પસંદ કરો",
  Deposit:"થાપણ",
  Hire_One_Now:"હાય એક ભાડે રાખો",
  App_Locked:"એપ્લિકેશન લૉક છે.",
  Skip_:"છોડો",
  Skip_to_Dashboard:"ડૅશબોર્ડ પર જાઓ",
  Dashboard:"ડેશબોર્ડ",
  Import:"આયાત કરો",
  Admin:"સંચાલકો",
  New_Admin:"નવું એડમિન",
  Settings:"સેટિંગ્સ",
  Color_Picker:"રંગ પીકર",
  Font_Picker:"ફોન્ટ પીકર",
  Logout:"લૉગ આઉટ",
  Close:"બંધ",
  Close_menu:"બંધ",
  Excel:"એક્સેલ ફાઇલ",
  Csv:"CSV ફાઇલ",
  Sql:"SQL ફાઇલ",
  Json:"JSON ફાઇલ",
  Url:"URL દ્વારા આયાત કરો",
  Back:"પાછળ",
  Timers:"ટાઈમર",
  Cheque:"તપાસો",
  Print:"છાપો",
  Designs:"ડિઝાઇન",
  Pause_Printing:"પ્રિંટિંગ થોભાવો",
  Resume_Printing:"પ્રિન્ટિંગ ફરી શરૂ કરો",
  Printing_Paused:"છાપવાનું થોભાવ્યું",
  PrintingUnavailable:"માફ કરશો! આ સિસ્ટમ પર પ્રિન્ટીંગ અનુપલબ્ધ છે.",
  Prints_Paused:"પ્રિન્ટ્સ થોભાવેલ",
  Administration:"વહીવટ",
  Loading:"લોડ કરી રહ્યું છે",
  Unnamed:"અનામી",
  error:"માફ કરશો, આ ચેક જોવા માટે ખોલી શકાયો નથી.",
  No_Cheques_To_Print:"છાપવા માટે કોઈ ચેક નથી",
  No_Cheques_To_Print_Word:"એક <a routerLink='/folder/Cheque/New'>નવો ચેક</a> બનાવો.",
  New_Cheque:"નવો ચેક",
  Start_One_Now:"હવે એક શરૂ કરો",
  Edit_Cheque:"ચેક સંપાદિત કરો",
  Select_Cheques:"ચકાસો પસંદ કરો",
  Select_Employee:"કર્મચારીને પસંદ કરો",
  Default_Printer:"ડિફોલ્ટ પ્રિન્ટર",
  Reassign:"ફરી સોંપણી",
  Configure:"રૂપરેખાંકિત કરો",
  Template:"નમૂનો",
  Designer:"ડિઝાઇનર",
  Edit_Designs:"ડિઝાઇન સંપાદિત કરો",
  New_Design:"નવી ડિઝાઇન",
  Next:"આગલું",
  Save:"સાચવો",
  Name:"નામ",
  Mail:"મેલ",
  Amount:"રકમ",
  Date:"તારીખ",
  PayPal:"પેપાલ",
  Payouts:"ચુકવણી",
  PayPal_Label:"પેપાલ લેબલ",
  Email_Username:"ઇમેઇલ / વપરાશકર્તા નામ",
  Client_ID:"ક્લાયન્ટ આઈડી",
  Sandbox_Email:"સેન્ડબોક્સ ઈમેલ",
  PayPal_Email:"પેપાલ ઈમેલ",
  PayPal_Username:"API વપરાશકર્તા નામ",
  PayPal_Payouts:"પેપાલ ચૂકવણી",
  Select_PayPal_Key:"પેપાલ કી પસંદ કરો",
  Secret:"ગુપ્ત",
  API_Secret:"API સિક્રેટ",
  PayPal_API_Keys:"પેપાલ કી",
  New_PayPal_Key:"નવી પેપાલ કી",
  Email_Subject:"ઈમેલ વિષય",
  Email_Message:"ઇમેઇલ સંદેશ",
  Payout_Options:"ચુકવણી વિકલ્પો",
  Payment_Note:"ચુકવણી નોંધ",
  Enable_Employee:"કર્મચારીને સક્ષમ કરો",
  Enable_Production_Mode:"ઉત્પાદન મોડને સક્ષમ કરો",
  Sandbox_Username:"સેન્ડબોક્સ વપરાશકર્તા નામ",
  Sandbox_Signature:"સેન્ડબોક્સ સહી",
  Sandbox_Password:"સેન્ડબોક્સ પાસવર્ડ",
  Production_Username:"ઉત્પાદન વપરાશકર્તા નામ",
  Production_Signature:"ઉત્પાદન હસ્તાક્ષર",
  Production_Password:"ઉત્પાદન પાસવર્ડ",
  Production_Email:"ઉત્પાદન ઈમેલ",
  API_Client_ID:"API ક્લાયંટ ID",
  API_Signature:"API સહી",
  API_Password:"API પાસવર્ડ",
  API_Username:"API વપરાશકર્તા નામ",
  Secret_Key:"ગુપ્ત ચાવી",
  Sandbox:"સેન્ડબોક્સ",
  Production:"ઉત્પાદન",
  Sandbox_Keys:"સેન્ડબોક્સ કી",
  Production_Keys:"ઉત્પાદન કી",
  API_Title:"API શીર્ષક",
  Production_Mode:"ઉત્પાદન મોડ",
  Account_Label:"એકાઉન્ટ લેબલ",
  No_PayPal_Setup:"કોઈ પેપાલ કી નથી",
  Enable_PayPal_Account:"પેપાલ એકાઉન્ટ સક્ષમ કરો",
  No_PayPal_Word:"તમારી <a routerLink='/folder/Invoice/New'>PayPal API કી</a> ઉમેરો.",
  Printed_Memo:"પ્રિન્ટેડ મેમો",
  Employee:"કર્મચારી",
  View_Employee:"કર્મચારીને જુઓ",
  Mailing_Address:"પત્ર સરનામું",
  Company_Address:"કંપનીનું સરનામું",
  Select_Company_Address:"કંપનીનું સરનામું પસંદ કરો",
  Address:"સરનામું",
  Any_Day:"કોઇ દિવસ",
  Address_Name:"સરનામું નામ",
  Unit:"એકમ",
  Account:"એકાઉન્ટ",
  Bank_Account:"બેંક એકાઉન્ટ",
  Account_Limits:"એકાઉન્ટ લિમિટ્સ સક્ષમ કરો",
  Payroll:"પેરોલ",
  New_Payroll:"નવું પગારપત્રક",
  No_Payroll:"કોઈ આગામી પગારપત્રક નથી",
  Upcoming_Holiday:"આગામી રજા:",
  Invoice_Due:"ઇનવોઇસ બાકી:",
  New_Invoice:"નવું ઇન્વોઇસ",
  No_Invoices:"કોઈ ઇન્વૉઇસેસ નથી",
  No_Invoices_Word:"પ્રથમ <a routerLink='/folder/Invoice/New'>ઇનવોઇસ</a> બનાવો.",
  Cheque_Due:"ચેક ડ્યુ:",
  Payrolls:"પેરોલ્સ",
  Sandbox_Mode:"સેન્ડબોક્સ મોડ",
  Hire:"ભાડે",
  Pay:"ચુકવણી",
  New:"નવું",
  Add:"ઉમેરો",
  Make:"બનાવો",
  Time:"સમય",
  Write:"લખો",
  Holiday:"રજા",
  Holidays:"રજાઓ",
  Next_Holiday:"આગલી રજા:",
  All_Done:"બધુ થઈ ગયું!",
  Employees:"કર્મચારીઓ",
  Payees:"ચૂકવનારા",
  Job:"નોકરી",
  Jobs:"નોકરી",
  Invoice:"ઇનવોઇસ",
  Invoices:"ઈનવોઈસ",
  Vacations:"વેકેશન્સ",
  Cheques:"તપાસ",
  Brand_Cheques:"બ્રાન્ડ",
  Payment:"ચુકવણી",
  Enable_Payment:"ચુકવણી સક્ષમ કરો",
  Payments:"ચુકવણીઓ",
  Schedule:"અનુસૂચિ",
  Schedules:"શિડ્યુલ્સ",
  Timesheet:"સમય પત્રક",
  Timesheets:"સમય શીટ્સ",
  Salary:"પગાર",
  New_Address:"નવું સરનામું",
  Address_2:"સરનામાની લાઇન નં 2)",
  _City:"શહેર",
  _State:"રાજ્ય/પ્રોવ",
  City:"શહેર / ટાઉનશીપ",
  State:"રાજ્ય/પ્રાંત",
  Postal:"પોસ્ટલ / ઝીપ કોડ",
  ZIP:"પોસ્ટલ / ઝીપ",
  Country:"દેશ",
  Addresses:"સરનામા",
  Required_Options:"જરૂરી વિકલ્પો",
  Optional_Options:"વૈકલ્પિક વિકલ્પો",
  Additional_Options:"વધારાના વિકલ્પો",
  Required:"જરૂરી",
  Optional:"વૈકલ્પિક",
  Additional:"વધારાનુ",
  No_Addresses:"કોઈ સરનામાં નથી",
  New_Address_Word:"પ્રથમ <a routerLink='/folder/Address/New'>સરનામું</a> ઉમેરો.",
  Select_Address:"સરનામું પસંદ કરો",
  No_Address:"કોઈ સરનામાં નથી",
  Print_Cheque:"પ્રિન્ટ ચેક",
  Print_Cheque_Now:"હવે છાપો તપાસો",
  Description:"વર્ણન",
  Pay_To_The_Order_Of:"ના આદેશ પર ચુકવો:",
  Select_Date_Range:"તારીખ શ્રેણી પસંદ કરો",
  Select_Starting_Date:"પ્રારંભિક તારીખ પસંદ કરો",
  Select_Ending_Date:"સમાપ્તિ તારીખ પસંદ કરો",
  Select_Date:"તારીખ પસંદ કરો",
  Cheque_Date:"તારીખ તપાસો",
  Cheque_Memo:"મેમો તપાસો",
  Blank_Cheque:"ખાલી તપાસ",
  Blank:"ખાલી",
  No_Cheques:"કોઈ ચેક નથી",
  No_Cheques_Word:"તમારું પ્રથમ <a routerLink='/folder/Cheque/New'>ચેક</a> છાપો.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"છબી જુઓ",
  View:"જુઓ",
  Modify:"સંશોધિત કરો",
  Delete:"કાઢી નાખો",
  Cheque_Paid:"ચુકવેલ",
  New_Deduction:"નવી કપાત",
  Title:"શીર્ષક",
  Frequency:"આવર્તન",
  Hourly:"કલાક દીઠ",
  Daily:"દૈનિક",
  Weekdays:"અઠવાડિયાના દિવસો",
  BiWeekly:"2 અઠવાડિયા",
  TriWeekly:"3 અઠવાડિયા",
  Monthly:"માસિક",
  MiMonthly:"2 મહિના",
  Quarterly:"ત્રિમાસિક",
  Yearly:"વાર્ષિક",
  Every_Week:"દર અઠવાડિયે",
  Every_Payroll:"દરેક પગારપત્રક",
  Every_Month:"દર મહિને",
  Annually:"વાર્ષિક",
  Always_Scheduled:"હંમેશા સુનિશ્ચિત",
  Start_Date:"પ્રારંભ તારીખ",
  End_Date:"અંતિમ તારીખ",
  Start_Time:"પ્રારંભ સમય",
  End_Time:"અંતિમ સમય",
  Deduction_Label:"કપાત લેબલ",
  Notes:"નોંધો",
  Options:"વિકલ્પો",
  Enable:"સક્ષમ કરો",
  Select_Deductions:"કપાત પસંદ કરો",
  Deductions:"કપાત",
  No_Deductions:"કોઈ કપાત નથી",
  No_Deductions_Word:"તમારું પ્રથમ <a routerLink='/folder/Deduction/New'>કર કપાત</a> બનાવો.",
  New_Employee:"નવો કર્મચારી",
  No_Title:"કોઈ શીર્ષક નથી",
  _Name:"નામ",
  About_Yourself:"તમારા વિશે",
  Full_Name:"પૂરું નામ",
  Birthday:"જન્મદિવસ",
  Email:"ઇમેઇલ",
  SMS:"એસએમએસ",
  Phone:"ફોન",
  Test:"પરીક્ષણ",
  Call:"કોલ",
  Fax:"ફેક્સ",
  Printed_Note:"મુદ્રિત નોંધ",
  Position:"પોઝિશન",
  Job_Position:"નોકરીની સ્થિતિ",
  Select_a_Job:"નોકરી પસંદ કરો",
  Select_a_Salary:"પગાર પસંદ કરો",
  Add_a_Deduction:"એક કપાત ઉમેરો",
  Taxes:"કર",
  Add_Taxes:"કર ઉમેરો",
  Date_of_Birth:"જન્મ તારીખ",
  Email_Address:"ઈ - મેઈલ સરનામું",
  Phone_Number:"ફોન નંબર",
  Phone_Call:"ફોન કૉલ",
  Enable_on_Payroll:"પેરોલ પર સક્ષમ કરો",
  Select_Employees:"કર્મચારીઓને પસંદ કરો",
  No_Employees:"કોઈ કર્મચારી નથી",
  No_Employees_Word:"તમારું પ્રથમ નવું <a routerLink='/folder/Employee/New'>કર્મચારી</a> ઉમેરો.",
  No_Name:"અનામી",
  Unemployeed:"બેરોજગાર",
  Employeed:"રોજગાર",
  Paid:"ચુકવેલ",
  Enabled:"સક્ષમ",
  Disabled:"અક્ષમ",
  Fire:"આગ",
  Not_Available:"ઉપલબ્ધ નથી",
  Not_Available_Word:"તમારું પ્રથમ <a routerLink='/folder/Invoice/New'>ઇનવોઇસ</a> પ્રિન્ટ કરો અને ચૂકવણી કરો.",
  Select_Invoices:"પસંદ_ઈનવોઈસ",
  Print_Invoice_Word:"તમારું પ્રથમ <a routerLink='/folder/Invoice/New'>ઇનવોઇસ</a> પ્રિન્ટ કરો અને ચૂકવણી કરો.",
  Invoice_Title:"ઇનવોઇસ શીર્ષક",
  Invoice_Date:"ઇનવોઇસ તારીખ",
  Due_Date:"નિયત તારીખ",
  New_Job:"નવી નોકરી",
  Details:"વિગતો",
  Customize:"કસ્ટમાઇઝ કરો",
  Customize_Dashboard:"ડેશબોર્ડ કસ્ટમાઇઝ કરો",
  Components:"ઘટકો",
  No_Components:"કોઈ ઘટકો નથી",
  Main_Components:"મુખ્ય ઘટકો",
  Smaller_Components:"નાના ઘટકો",
  Error_Loading_Page:"આ પૃષ્ઠ લોડ કરવામાં ભૂલ.",
  Bank_Details:"બેંકની વિગત",
  About_Your_Job:"તમારી નોકરી વિશે",
  Your_Schedule:"તમારું સમયપત્રક",
  Job_Title:"જોબ શીર્ષક",
  Job_Description:"કામનું વર્ણન",
  Job_Details:"નોકરીની વિગતો",
  Enable_Job:"જોબ સક્ષમ કરો",
  Pay_Period:"પગારનો સમયગાળો",
  Perpetually_Schedule:"હંમેશાં શેડ્યૂલ",
  Select_Jobs:"નોકરીઓ પસંદ કરો",
  No_Jobs:"નોકરી નથી",
  Add_Jobs:"નોકરીઓ ઉમેરો",
  No_Jobs_Word:"સૂચિમાં પ્રથમ <a routerLink='/folder/Job/New'>નોકરી</a> ઉમેરો.",
  Count_Employees:"job.employee_count+' કર્મચારીઓ'",
  Zero_Employees:"0 કર્મચારીઓ",
  New_Leave:"નવી રજા",
  Leave_Name:"નામ છોડો",
  Paid_Leave:"પેઇડ લીવ",
  Leave_Pay:"પગાર છોડો",
  Indefinite_Leave:"અનિશ્ચિત રજા",
  Indefinite_Payroll:"અનિશ્ચિત પગારપત્રક",
  Leave:"છોડો",
  Add_Schedules:"શિડ્યુલ ઉમેરો",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"ગેરહાજરી સક્ષમ કરો",
  Select_Leaves:"પાંદડા પસંદ કરો",
  No_Leaves:"ગેરહાજરીના કોઈ પાંદડા નથી",
  Leave_Of_Absence:"ગેરહાજરી રજા",
  Leaves_Of_Absence:"ગેરહાજરીના પાંદડા",
  New_Leave_of_Absense:"ગેરહાજરીની નવી રજા",
  No_Leaves_Word:"પ્રથમ <a routerLink='/folder/Leave/New'>ગેરહાજરીની રજા</a> ઉમેરો.",
  Not_Enabled:"સક્ષમ નથી",
  Absences:"ગેરહાજરી",
  Payee:"ચુકવનાર",
  New_Payee:"નવા લેનાર",
  Payee_Identifier:"ચુકવનાર ઓળખકર્તા",
  Payee_Name:"ચુકવનારનું નામ",
  Payee_Title:"પાયી શીર્ષક",
  Payment_Memo:"ચુકવણી મેમો",
  Select_Payees:"ચૂકવનારને પસંદ કરો",
  No_Payees:"કોઈ ચૂકવનાર નથી",
  Add_Payee_Note:"પ્રથમ <a routerLink='/folder/Payee/New'>ચુકવનાર</a> ઉમેરો.",
  New_Payees:"નવા પેઇઝ",
  About_The_Payment_Schedule:"ચુકવણી શેડ્યૂલ",
  Your_Payroll_Schedule:"ઓટોમેટિક પેરોલ",
  New_Payment:"નવી ચુકવણી",
  Identifier:"ઓળખકર્તા",
  Select:"પસંદ કરો",
  Memo:"યાદી",
  Payment_Date:"ચુકવણીની તારીખ",
  Mark_as_Paid:"ચુકવેલ તરીકે ચિહ્નિત કરો",
  Select_Payments:"ચુકવણીઓ પસંદ કરો",
  No_Payments:"કોઈ ચૂકવણી નથી",
  No_Payments_Word:"પ્રથમ <a routerLink='/folder/Payment/New'>ચુકવણી</a> બનાવો.",
  Create_Payroll:"પેરોલ બનાવો",
  Properties:"ગુણધર્મો",
  Payroll_Title:"પેરોલ શીર્ષક",
  Payroll_Notes:"પેરોલ નોંધો",
  Payroll_Setup:"પેરોલ સેટઅપ",
  Tabulate_Payments:"ટેબ્યુલેટ ચૂકવણી",
  Tabulate:"ટેબ્યુલેટ",
  By:"દ્વારા:",
  Payments_By:"દ્વારા ચૂકવણી",
  Timesheets_And_Schedules:"સમય પત્રકો અને સમયપત્રક",
  Both:"બંને",
  Items:"વસ્તુઓ",
  Add_Payees:"ચુકવનારને ઉમેરો",
  Add_Account:"ખાતું ઉમેરો",
  Enable_Account:"એકાઉન્ટ સક્ષમ કરો",
  Enable_Payroll:"પેરોલ સક્ષમ કરો",
  Print_Payroll:"પેરોલ છાપો",
  No_Payrolls:"કોઈ પેરોલ નથી",
  No_Payroll_Word:"તમારું પ્રથમ <a routerLink='/folder/Payroll/New'>પેરોલ</a> બનાવો.",
  View_more:"વધુ જોવો",
  Less:"ઓછું",
  Add_Payroll:"પેરોલ ઉમેરો",
  Select_Payroll:"પેરોલ પસંદ કરો",
  About_Your_Salary:"તમારા પગાર વિશે",
  Add_Salaries:"પગાર ઉમેરો",
  Add_Salary:"પગાર ઉમેરો",
  Salaries:"પગાર",
  No_Salaries:"કોઈ પગાર નથી",
  No_Salaries_Word:"પ્રથમ <a routerLink='/folder/Salary/New'>પગાર</a> ઉમેરો.",
  Select_Salaries:"પગાર પસંદ કરો",
  New_Salary:"નવો પગાર",
  Salary_Name:"પગાર ઓળખકર્તા",
  Enable_Salary:"પગાર સક્ષમ કરો",
  Salary_Amount:"પગારની રકમ",
  New_Schedule:"નવું સમયપત્રક",
  Schedule_Title:"શેડ્યૂલ શીર્ષક",
  Add_Address:"સરનામું ઉમેરો",
  Repeat:"પુનરાવર્તિત કરો",
  Design:"ડિઝાઇન",
  Edit_Design:"ડિઝાઇન સંપાદિત કરો",
  Edit_this_Design:"આ ડિઝાઇનમાં ફેરફાર કરો",
  Repeat_Payment:"પુનરાવર્તિત ચુકવણી",
  Enable_Schedule:"શેડ્યૂલ સક્ષમ કરો",
  Never:"ક્યારેય",
  Select_Schedule:"શેડ્યુલ્સ પસંદ કરો",
  No_Schedules:"કોઈ શેડ્યૂલ નથી",
  No_Schedules_Word:"પ્રથમ <a routerLink='/folder/Schedule/New'>અનુસૂચિ</a> બનાવો.",
  New_Administrator:"નવા સંચાલક",
  Username:"વપરાશકર્તા નામ",
  First_Name:"પ્રથમ નામ",
  Last_Name:"છેલ્લું નામ",
  Add_an_Address:"એક સરનામું ઉમેરો",
  Payment_Limit:"પ્રતિ-ચુકવણી મર્યાદા",
  Total_Limit:"કુલ મર્યાદા",
  Select_Accounts:"એકાઉન્ટ્સ પસંદ કરો",
  No_Administrators:"કોઈ વહીવટકર્તા નથી",
  No_Administrators_Word:"પ્રથમ <a routerLink='/folder/Administrator/New'>સંચાલકો</a> બનાવો.",
  New_Administrators_Word:"પ્રથમ <a routerLink='/folder/Administrator/New'>સંચાલકો</a> ઉમેરો",
  Cloud:"વાદળ",
  Backup:"બેકઅપ",
  Enable_iCloud:"iCloud સક્ષમ કરો",
  Enable_Google_Drive:"Google ડ્રાઇવ સક્ષમ કરો",
  Enable_Microsoft_OneDrive:"Microsoft OneDrive ને સક્ષમ કરો",
  Automatically_Backup:"ઓટોમેટીકલી બેકઅપ",
  FTP_Settings:"FTP સેટિંગ્સ",
  URL_File_Prompt:"કૃપા કરીને આયાત કરવા માટે .xls / .xlsx / .json ફાઇલ માટે સ્થાન સ્પષ્ટ કરો:",
  URL_SQL_Prompt:"કૃપા કરીને આયાત કરવા માટે SQLite ફાઇલનું સ્થાન સ્પષ્ટ કરો:",
  FTP_Backup:"FTP બેકઅપ",
  FTP_Import:"FTP આયાત",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"યજમાન",
  Port:"બંદર",
  Path:"પાથ",
  Data_Path:"ડેટા પાથ",
  Enable_FTP_Account:"FTP એકાઉન્ટ સક્ષમ કરો",
  HostnameIP:"હોસ્ટનામ",
  Password:"પાસવર્ડ",
  Connection_Port:"કનેક્શન પોર્ટ",
  Enable_MySQL_Database:"MySQL ડેટાબેઝ સક્ષમ કરો",
  Log:"લોગ",
  Reset:"રીસેટ કરો",
  Erase:"ભુસવું",
  Export:"નિકાસ",
  Database:"ડેટાબેઝ",
  Upload_CSV:"CSV અપલોડ કરો",
  Download_CSV:"CSV ડાઉનલોડ કરો",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL બેકઅપ",
  Internal_Notes:"આંતરિક નોંધો",
  Root_Path:"રુટ પાથ",
  Select_Backup:"બેકઅપ પસંદ કરો",
  Add_New_Backup:"નવું બેકઅપ ઉમેરો",
  First_Backup:"પ્રથમ બેકઅપ સેટ કરો...",
  Backups:"બેકઅપ્સ",
  Add_Backup:"બેકઅપ ઉમેરો",
  No_Backups:"મળવા માટે કોઈ બેકઅપ નથી.",
  Select_Backup_Type:"તમે સેટઅપ કરવા માંગો છો તે પ્રકારનો બેકઅપ પસંદ કરો...",
  Backup_Type:"બેકઅપ પ્રકાર",
  FTP_Drive:"FTP ડ્રાઇવ",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"ડ્રાઇવ",
  Microsoft_OneDrive:"ડ્રાઇવ",
  Import_Fields:"આયાત ક્ષેત્રો",
  Import_Fields_Word:"<a routerLink='/folder/Holiday/New'>આયાત</a> ડેટા પસંદ કરવા માટે આ વિભાગનો ઉપયોગ કરો.",
  Upload:"અપલોડ કરો",
  Download:"ડાઉનલોડ કરો",
  Download_JSON:"JSON ડેટા તરીકે ડાઉનલોડ કરો",
  Download_SQL:"SQL ફાઇલ તરીકે ડાઉનલોડ કરો",
  New_Bank:"નવી બેંક",
  New_Account:"નવું ખાતું",
  New_Bank_Account:"નવું બેંક ખાતું",
  Upload_Image:"છબી અપલોડ કરો",
  Bank_Name:"બેંકનું નામ",
  Bank_Address:"બેંક સરનામું",
  Branch_Address:"શાળાનું સરનામું",
  Address_on_Cheque:"સરનામું",
  Cheque_Numbers:"નંબરો તપાસો",
  Cheque_Details:"વિગતો તપાસો",
  Bank_Logo:"બેંકનો લોગો",
  Cheque_ID:"આઇડી તપાસો",
  Starting_Cheque_ID:"ચેક ID શરૂ કરી રહ્યા છીએ",
  Transit_Number:"ટ્રાન્સિટ નંબર",
  Institution_Number:"સંસ્થા નંબર",
  Designation_Number:"હોદ્દો નંબર",
  Account_Number:"ખાતા નંબર",
  Limits:"મર્યાદા",
  Default_Limits:"મૂળભૂત મર્યાદાઓ",
  Over_Limit:"મર્યાદાથી વધુ",
  Under_Limit:"મર્યાદા હેઠળ",
  Payroll_Limit:"પેરોલ મર્યાદા",
  Enable_Bank_Account:"બેંક એકાઉન્ટ સક્ષમ કરો",
  Select_Account:"એકાઉન્ટ પસંદ કરો",
  No_Bank_Account:"કોઈ બેંક એકાઉન્ટ નથી",
  No_Bank_Account_Word:"પ્રથમ <a routerLink='/folder/Accounts/New'>બેંક એકાઉન્ટ</a> ઉમેરો.",
  Bank_Accounts:"બેંક એકાઉન્ટ્સ",
  No_Accounts:"કોઈ એકાઉન્ટ્સ નથી",
  No_Accounts_Note:"પ્રથમ <a routerLink='/folder/Accounts/New'>બેંક એકાઉન્ટ</a> ઉમેરો.",
  Cheque_Designer:"ડીઝાઈનર તપાસો",
  Cheque_Design:"ડિઝાઇન તપાસો",
  Select_Design:"ડિઝાઇન પસંદ કરો",
  Cheque_Designs:"ડિઝાઇન તપાસો",
  No_Cheque_Designs:"કોઈ ચેક ડિઝાઇન નથી",
  No_Cheque_Designs_Word:"તમારી પોતાની <a routerLink='/folder/Settings/Cheque/Design/New'>ચેક ડિઝાઇન</a> બનાવો.",
  Set_Default:"ડિફોલ્ટ તરીકે સેટ કરો",
  Default:"મૂળભૂત",
  Remove:"દૂર કરો",
  Folder:"ફોલ્ડર",
  Edit:"સંપાદિત કરો",
  LoadingDots:"લોડ કરી રહ્યું છે...",
  Add_a_New_File:"આમાં <a href='#' (click)='add()'>નવી ફાઇલ</a> ઉમેરો",
  this_folder:"આ ફોલ્ડર",
  FTP_Backup_Settings:"FTP બેકઅપ સેટિંગ્સ",
  Secure_File_Transfer:"સુરક્ષિત ફાઇલ ટ્રાન્સફર",
  New_Holiday:"નવી રજા",
  Add_Holiday:"રજા ઉમેરો",
  Holiday_Name:"રજાનું નામ",
  Additional_Pay:"વધારાના પગાર",
  Enable_Holiday:"રજા સક્ષમ કરો",
  Select_Holidays:"રજાઓ પસંદ કરો",
  No_Holidays:"કોઈ રજાઓ નથી",
  No_Holidays_Word:"પ્રથમ <a routerLink='/folder/Holiday/New'>જાહેર રજા</a> ઉમેરો.",
  New_Import:"નવી આયાત",
  Import_Data:"ડેટા આયાત કરો",
  Import_Data_Word:"તમારી પસંદગીની ફાઇલ અથવા અપલોડ પદ્ધતિનો પ્રકાર પસંદ કરો.<br /> તમે સમર્થિત ફાઇલ અપલોડ કર્યા પછી એપ્લિકેશનમાંના કોઈપણ પરિમાણને અનુરૂપ ફાઇલમાં આયાત કરેલ ફીલ્ડ પસંદ કરી શકશો .", 
  Import_File:"ફાઈલ આયાત કરો",
  Link_To_File:"ફાઇલની લિંક",
  Select_File:"ફાઇલ પસંદ કરો",
  New_Moderator:"નવા મધ્યસ્થ",
  Allow_Moderation:"મધ્યસ્થતાને મંજૂરી આપો",
  Create_Paycheques:"પેચેક બનાવો",
  Edit_Paycheques_and_Data:"પેચેક અને ડેટા સંપાદિત કરો",
  Destroy_Data_and_Paycheques:"ડેટા અને પેચેક્સનો નાશ કરો",
  Bonus_Percentage:"પેચેક ટકાવારી",
  Fixed_Amount:"નિયત રકમ",
  Select_Moderator:"મધ્યસ્થી પસંદ કરો",
  No_Moderators:"કોઈ મધ્યસ્થી નથી",
  Moderators:"મધ્યસ્થ",
  Moderator_Account:"પ્રથમ <a routerLink='/folder/Administrator/New'>મધ્યસ્થ ખાતું</a> બનાવો.",
  No_Moderators_Word:"પ્રથમ <a routerLink='/folder/Administrator/New'>મધ્યસ્થ</a> ઉમેરો.",
  Defaults:"મૂળભૂત",
  Provide_Us_Info:"અમને માહિતી આપો",
  Setup_Your_Printer:"તમારું પ્રિન્ટર સેટ કરો",
  Your_Company:"તમારી કંપની વિશે",
  Company_Name:"કંપની નું નામ",
  Currency:"ચલણ",
  Default_Currency:"મૂળભૂત ચલણ",
  Base_Monthly_Income:"માસિક આવક",
  Protection:"રક્ષણ",
  App_Protection:"એપ પ્રોટેક્શન",
  PIN_Code_Protection:"પીન કોડ પ્રોટેક્શન",
  App_Protection_Word:"સુરક્ષા પદ્ધતિઓ સક્ષમ કરો જે તમારા એકાઉન્ટને સુરક્ષિત કરવામાં મદદ કરે છે.",
  PIN_Code:"પીન કોડ",
  Change_PIN:"પિન બદલો",
  New_Password:"નવો પાસવર્ડ",
  Geofence_Protection:"જિયો-ફેન્સ પ્રોટેક્શન",
  Geofence_Area:"સેટ એરિયા",
  Distance:"અંતર",
  Setup_Now:"હવે સેટઅપ કરો",
  Mile:"માઇલ",
  Km:"કિમી",
  m:"મ",
  Facial_Recognition:"ચહેરાની ઓળખ",
  Face:"ચહેરો",
  Setup:"સ્થાપના",
  Label:"લેબલ",
  Password_Protection:"પાસવર્ડ પ્રોટેક્શન",
  Modify_Password:"પાસવર્ડ સંશોધિત કરો",
  New_Tax_Entry:"નવી ટેક્સ એન્ટ્રી",
  New_Tax:"નવો કર",
  Tax_Label:"ટેક્સ લેબલ",
  Perpetually_Enabled:"હંમેશાં સક્ષમ",
  Select_Taxes:"કર પસંદ કરો",
  Tax_Deductions:"કર કપાત",
  No_Tax_Deductions:"કોઈ કર કપાત નથી",
  No_Tax_Deductions_Word:"પ્રથમ <a routerLink='/folder/Tax/New'>ટેક્સ</a> કપાત ઉમેરો.",
  New_Timer:"નવું ટાઈમર",
  Start:"શરૂઆત",
  Stop:"બંધ",
  Start_Timer:"સ્ટાર્ટ ટાઈમર",
  Stop_Timer:"ટાઈમર રોકો",
  Timer_Active:"ટાઈમર સક્રિય",
  Timer:"ટાઈમર:",
  Timer_Running:"ટાઈમર: (રનિંગ)",
  Save_Timer:"ટાઈમર સાચવો",
  New_Timesheet:"ન્યુ ટાઈમ શીટ",
  Select_Timesheets:"સમય શીટ્સ પસંદ કરો",
  Work_Notes:"કામની નોંધો",
  Entry_Title:"પ્રવેશ શીર્ષક",
  No_Timesheets:"કોઈ ટાઈમ શીટ્સ નથી",
  No_Timesheets_Word:"પ્રથમ <a routerLink='/folder/Timesheet/New'>સમય શીટ</a> ઉમેરો.",
  Timesheet_Submitted:"સમય પત્રક સબમિટ કર્યું",
  Timesheet_Congrats:"અભિનંદન! તમારી સમયપત્રક સફળતાપૂર્વક સબમિટ કરવામાં આવી છે. આભાર!",
  Timesheet_Copy:"તમારા દસ્તાવેજોની નકલ મેળવવા માટે કૃપા કરીને અમને તમારું ઈમેલ સરનામું અને/અથવા મોબાઈલ ફોન નંબર આપો.",
  Submit:"સબમિટ કરો",
  Allow_Notifications:"સૂચનાઓને મંજૂરી આપો",
  Untitled_Entry:"નવા પ્રવેશ",
  Untitled_Bank:"અનામાંકિત બેંક",
  Timesheet_Entry:"સમય શીટ એન્ટ્રી",
  Work_Description:"કામ વર્ણન",
  Enable_Timesheet:"સમય શીટ સક્ષમ કરો",
  Submit_Timesheet:"સમય પત્રક સબમિટ કરો",
  Vacation:"વેકેશન",
  New_Vacation:"નવું વેકેશન",
  Vacation_Name:"વેકેશન નામ",
  Paid_Vacation:"પેઇડ વેકેશન",
  Vacation_Pay:"વેકેશન પે",
  Enable_Vacation:"વેકેશન સક્ષમ કરો",
  Select_Vacations:"વેકેશન પસંદ કરો",
  No_Vacations:"કોઈ વેકેશન નથી",
  No_Vacations_Word:"પ્રથમ <a routerLink='/folder/Vacation/New'>વેકેશન</a> એન્ટ્રી બનાવો.",
  Payroll_Schedule:"પેરોલ શેડ્યૂલ",
  Next_Payroll:"નેક્સ્ટ પેરોલ:",
  Upcoming_Payroll:"આગામી પગારપત્રક",
  No_Upcoming_Payroll:"કોઈ આગામી પગારપત્રક નથી",
  Address_Book:"સરનામા પુસ્તિકા",
  Archived_Documents:"આર્કાઇવ કરેલા દસ્તાવેજો",
  Dev_Mode:"એપ્લીકેશન ઇન ડેવલપમેન્ટ મોડ",
  Administrators:"સંચાલકો",
  Privacy:"ગોપનીયતા",
  None:"કોઈ નહિ",
  Select_Signature:"હસ્તાક્ષર પસંદ કરો",
  No_Signatures:"કોઈ સહીઓ નથી",
  No_Signatures_Word:"પ્રથમ <a routerLink='/folder/Signature/New'>સહી</a> ઉમેરો.",
  Repeat_Indefinitely:"અનિશ્ચિતપણે પુનરાવર્તન કરો",
  Sign:"હસ્તાક્ષર",
  Sign_Here:"અહીં સહી કરો",
  Date_Signed:"તારીખ હસ્તાક્ષર કર્યા",
  Signature_Pad:"સિગ્નેચર પેડ",
  Account_Holder:"ખાતાધારક",
  Account_Properties:"એકાઉન્ટ પ્રોપર્ટીઝ",
  Name_On_Cheque:"ચેક પર નામ",
  Server_Hostname:"સર્વર હોસ્ટનામ / IP",
  Printers:"પ્રિન્ટર્સ",
  No_Printers:"કોઈ પ્રિન્ટર નથી",
  Printer_Exists:'આ નામનું પ્રિન્ટર પહેલેથી જ અસ્તિત્વમાં છે.',
  No_Printers_Word:"પ્રથમ <a routerLink='/folder/Printer/New'>પ્રિંટર</a> ઉમેરો.",
  No_Printer_Alert:"કોઈ પ્રિન્ટર વ્યાખ્યાયિત નથી. શું તમે પ્રિન્ટર સેટઅપ કરવા માંગો છો?",
  Add_Printer:"પ્રિંટર ઉમેરો",
  New_Printer:"નવું પ્રિન્ટર",
  Printer_Hostname:"પ્રિંટર હોસ્ટનામ / IP",
  Printer_Label:"પ્રિંટર લેબલ",
  Printer_Protocol:"પ્રિંટર પ્રોટોકોલ",
  Protocol:"પ્રોટોકોલ",
  Email_Print:"ઇમેઇલ",
  AirPrint:"એરપ્રિન્ટ",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"સોકેટ",
  Print_Job:"પ્રિન્ટ જોબ",
  Printed:"મુદ્રિત",
  Not_Printed:"છાપાયેલ નથી",
  Print_Jobs:"પ્રિન્ટ જોબ્સ",
  Print_Queue:"પ્રિન્ટ કતાર",
  No_Print_Jobs:"કોઈ પ્રિન્ટ જોબ્સ નથી",
  No_Prints:"છાપવા માટે એક નવું <a routerLink='/folder/Cheque/New'>ચેક</a> બનાવો.",
  Prints:"છાપો",
  Find_Printer:"પ્રિંટર શોધો",
  Find_AirPrint_Devices:"એરપ્રિન્ટ ઉપકરણો શોધો",
  Select_Printer:"પ્રિંટર પસંદ કરો",
  System_UI:"સિસ્ટમ UI",
  Printer:"પ્રિંટર",
  Status:"સ્થિતિ",
  Preview:"પૂર્વાવલોકન",
  Enable_Print_Job:"પ્રિન્ટ જોબ સક્ષમ કરો",
  Queue_Weight:"કતારનું વજન",
  Unlimited:"અમર્યાદિત",
  Show_Advanced_Printer_Options:"અદ્યતન પ્રિન્ટર વિકલ્પો બતાવો",
  Advanced:"અદ્યતન",
  Location:"સ્થાન",
  Note:"નૉૅધ",
  Queue_Name:"કતારનું નામ",
  Pages_Printed_Limit:"પૃષ્ઠ મુદ્રિત મર્યાદા",
  MultiPage_Idle_Time:"મલ્ટિપેજ પ્રતીક્ષા સમય(ઓ)",
  Page_Count_Limit:"પૃષ્ઠ ગણતરી મર્યાદા",
  Page_Orientation:"પૃષ્ઠ ઓરિએન્ટેશન",
  Portrait:"પોટ્રેટ",
  Landscape:"લેન્ડસ્કેપ",
  Duplex:"ડુપ્લેક્સ",
  Double_Sided_Printing:"ડબલ સાઇડેડ",
  Duplex_Mode:"ડુપ્લેક્સ મોડ",
  Duplex_Short:"ટૂંકુ",
  Duplex_Long:"લાંબા",
  Duplex_None:"કોઈ નહિ",
  Color_And_Quality:"રંગ અને ગુણવત્તા",
  Monochrome:"મોનોક્રોમ",
  Photo_Quality_Prints:"ફોટો ક્વોલિટી પ્રિન્ટ્સ",
  Printer_Email:"પ્રિંટર ઈમેલ",
  Automatically_Downsize:"આપમેળે ડાઉનસાઈઝ કરો",
  Paper:"કાગળ",
  Paper_Name:"કાગળનું નામ",
  Paper_Width:"કાગળની પહોળાઈ",
  Paper_Height:"કાગળની ઊંચાઈ",
  Paper_Autocut_Length:"પેપર ઓટો-કટ લંબાઈ",
  Margins:"માર્જિન",
  Page_Margins:"પૃષ્ઠ હાંસિયા",
  Page_Margin_Top:"ટોચ પેજ માર્જિન",
  Page_Margin_Right:"જમણા પૃષ્ઠનો હાંસિયો",
  Page_Margin_Bottom:"બોટમ પેજ માર્જિન",
  Page_Margin_Left:"ડાબું પૃષ્ઠ હાંસિયો",
  Add_Employees:"કર્મચારીઓને ઉમેરો",
  Header:"હેડર",
  Print_A_Page_Header:"પેજ હેડર છાપો",
  Header_Label:"હેડર લેબલ",
  Header_Page_Index:"હેડર પેજ ઇન્ડેક્સ",
  Header_Font_Name:"હેડર ફોન્ટ",
  Select_Font:"ફોન્ટ પસંદ કરો",
  Font_Selector:"ફોન્ટ પસંદગીકાર",
  Header_Font_Size:"હેડર ફોન્ટ",
  Header_Bold:"હેડર બોલ્ડ",
  Header_Italic:"હેડર ઇટાલિક",
  Header_Alignment:"હેડર સંરેખણ",
  Left:"ડાબે",
  Center:"કેન્દ્ર",
  Right:"જમણે",
  Justified:"વાજબી",
  Header_Font_Color:"હેડર કલર",
  Select_Color:"રંગ પસંદ કરો",
  Footer:"ફૂટર",
  Print_A_Page_Footer:"એક પૃષ્ઠ ફૂટર છાપો",
  Footer_Label:"ફૂટર લેબલ",
  Footer_Page_Index:"ફૂટર પેજ ઇન્ડેક્સ",
  Footer_Font_Name:"ફૂટર ફોન્ટ",
  Fonts:"ફોન્ટ્સ",
  Done:"થઈ ગયું",
  Select_Fonts:"ફોન્ટ્સ પસંદ કરો",
  Footer_Font_Size:"ફૂટરનું કદ",
  Footer_Bold:"ફૂટર બોલ્ડ",
  Footer_Italic:"ફુટર ઇટાલિક",
  Footer_Alignment:"ફૂટર સંરેખણ",
  Footer_Font_Color:"ફૂટર કલર",
  Page_Copies:"પૃષ્ઠ નકલો",
  Enable_Printer:"પ્રિંટર સક્ષમ કરો",
  Remote_Logging:"રિમોટ લોગીંગ",
  Log_Server:"લોગ સર્વર",
  Encryption:"એનક્રિપ્શન",
  Random_Key:"રેન્ડમ કી",
  Encryption_Key:"એનક્રિપ્શન કી",
  Cheques_Webserver:"કસ્ટમ ડેટાબેઝ",
  Learn_How:"જાણો કેવી રીતે",
  Signature:"સહી",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"હસ્તાક્ષર જુઓ",
  Printed_Signature:"મુદ્રિત હસ્તાક્ષર",
  Digitally_Sign:"ડિજિટલ સાઇન",
  Digital_Signature:"ડિજિટલ હસ્તાક્ષર",
  Add_Signature:"સહી ઉમેરો",
  Add_Your_Signature:"તમારી સહી ઉમેરો",
  Enable_Signature:"હસ્તાક્ષર સક્ષમ કરો",
  Digitally_Signed:"ડિજિટલ હસ્તાક્ષરિત",
  Insert_Error:"ડેટાબેઝ સમસ્યાઓને કારણે ચેક સાચવવામાં અસમર્થ.",
  Delete_Confirmation:"શું તમે ખરેખર આ માહિતી કાઢી નાખવા માંગો છો?",
  Discard_Confirmation:"શું તમે ખરેખર આ માહિતીને કાઢી નાખવા માંગો છો?",
  Discard_Bank_Confirmation:"શું તમે ખરેખર આ એકાઉન્ટને કાઢી નાખવા માંગો છો?",
  Discard_Printer_Confirmation:"શું તમે ખરેખર આ પ્રિન્ટરને કાઢી નાખવા માંગો છો?",
  Delete_Bonus_Confirmation:"શું તમે ખરેખર આ બોનસ કાઢી નાખવા માંગો છો?",
  Delete_Invoice_Confirmation:"શું તમે ખરેખર આ ઇન્વૉઇસ કાઢી નાખવા માંગો છો?",
  Generated_Cheque:"જનરેટ કરેલ ચેક",
  Generated_Invoice:"જનરેટેડ ઇન્વોઇસ",
  Schedule_Start_Time:"શિડ્યુલ સ્ટાર્ટ",
  Schedule_End_Time:"શેડ્યૂલ એન્ડ",
  New_Call:"નવો કૉલ",
  No_Contacts:"કોઈ સંપર્કો નથી",
  No_Contacts_Word:"વહીવટકર્તાઓ, મધ્યસ્થીઓ, કર્મચારીઓ અને ચૂકવણી કરનારાઓ સંપર્કો તરીકે દેખાય છે.",
  PDF_Subject:"નાણા",
  PDF_Keywords:"પેરોલ પેચેક પીડીએફ ચેક ચેક",
  Printer_Setup:"પ્રિંટર સેટઅપ",
  Printer_Selection:"પ્રિંટર પસંદગી",
  New_Fax:"નવું ફેક્સ",
  New_Fax_Message:"નવો ફેક્સ સંદેશ",
  Fax_Machine:"ફેક્સ મશીન",
  Fax_Name:"ફેક્સ નામ",
  Fax_Email:"ફેક્સ ઈમેલ",
  Fax_Number:"ફેક્સ નંબર",
  Contents:"સામગ્રી",
  Fax_Body:"પૃષ્ઠ મુખ્ય ભાગ",
  Header_Word:"હેડર:",
  Header_Text:"હેડર ટેક્સ્ટ",
  Include_Header:"હેડર શામેલ કરો",
  Include_Footer:"ફૂટર શામેલ કરો",
  Footer_Word:"ફૂટર:",
  Footer_Text:"ફૂટર ટેક્સ્ટ",
  Attach_a_Cheque:"ચેક જોડો",
  Add_Deduction:"કપાત ઉમેરો",
  Enable_Fax:"ફૅક્સ મોકલો",
  Select_Fax:"ફેક્સ પસંદ કરો",
  No_Faxes:"કોઈ ફેક્સ નથી",
  Faxes:"ફેક્સ",
  Save_and_Send:"ફૅક્સ મોકલો",
  Save_and_Pay:"સાચવો અને ચૂકવો",
  WARNING:"ચેતવણી:",
  Remember:"યાદ રાખો",
  Printing:"છાપવું",
  Printing_Complete:"છાપવાનું પૂર્ણ!\n\n",
  of:"માંથી",
  There_Were:"ત્યાં હતા",
  Successful_Prints:"સફળ પ્રિન્ટ અને",
  Unsuccessful_Prints:"અસફળ પ્રિન્ટ.",
  PrinterError:"માફ કરશો! એક ભૂલ હતી.",
  Printing_:"છાપવું...",
  PrinterSuccess:"દસ્તાવેજ સફળતાપૂર્વક છાપવામાં આવ્યો.",
  PrintersSuccess:"દસ્તાવેજો સફળતાપૂર્વક છાપવામાં આવ્યા.",
  Print_Cheques:"ચકાસો છાપો",
  New_Message:"નવો સંદેશ",
  New_Messages:"નવા સંદેશાઓ",
  Read_Message:"સંદેશ વાંચો",
  Write_Message:"સંદેશ લખો",
  Send_Message:"સંદેશ મોકલો",
  Subject:"વિષય",
  Message:"સંદેશ",
  Writing:"લેખન...",
  Send:"મોકલો",
  Set_Date:"તારીખ સેટ કરો",
  Set_Time:"સમય ગોઠવવો",
  Recieve:"પ્રાપ્ત કરો",
  Set_as_Default:"ડિફોલ્ટ તરીકે સેટ કરો",
  Default_Account:"ડિફોલ્ટ એકાઉન્ટ",
  Default_Design:"ડિફોલ્ટ ડિઝાઇન",
  Multiple_Cheques:"ચેક્સ (ટ્રિપલ)",
  Account_Mode:"એકાઉન્ટ મોડ",
  Multiple_Cheques_Description:"પાના દીઠ ત્રણ ચેક.",
  Check_and_Table:"ચેક અને ટેબલ",
  Check_including_Table:"ચેક અને એકાઉન્ટિંગ ટેબલ.",
  Check_Mailer:"મેઈલર તપાસો",
  Check_Mailer_Window:"એક સરનામાં વિન્ડો સાથે તપાસો.",
  DocuGard_Table_Top:"ડોક્યુગાર્ડ ચેક અને ટેબલ (ટોચ)",
  DocuGard_Table_Middle:"ડોક્યુગાર્ડ ચેક અને ટેબલ (મધ્યમ)",
  DocuGard_Table_Bottom:"ડોક્યુગાર્ડ ચેક અને ટેબલ (નીચે)",
  DocuGard_Mailer_Top:"ડોક્યુગાર્ડ ચેક મેઈલર (ટોચ)",
  DocuGard_Mailer_Middle:"ડોક્યુગાર્ડ ચેક મેઈલર (મધ્યમ)",
  DocuGard_Mailer_Bottom:"ડોક્યુગાર્ડ ચેક મેઈલર (નીચે)",
  DocuGard_Three_Cheques:"ડોક્યુગાર્ડ ચેક્સ (ટ્રિપલ)",
  DocuGard_Cheque_Top:"ડોક્યુગાર્ડ ચેક (ટોચ)",
  DocuGard_Cheque_Middle:"ડોક્યુગાર્ડ ચેક (મધ્યમ)",
  DocuGard_Cheque_Bottom:"ડોક્યુગાર્ડ ચેક (નીચે)",
  DocuGard_Three_Cheques_Window:"એક પેજ પર ત્રણ ચેક.",
  DocuGard_Table_Top_Window:"ચેક અને કમાણી ટેબલ.",
  DocuGard_Table_Middle_Window:"ચેક અને કમાણી ટેબલ.",
  DocuGard_Table_Bottom_Window:"ચેક અને કમાણી ટેબલ.",
  DocuGard_Mailer_Top_Window:"ચેક, ટેબલ અને સરનામું.",
  DocuGard_Mailer_Middle_Window:"ચેક, ટેબલ અને સરનામું.",
  DocuGard_Mailer_Bottom_Window:"ચેક, ટેબલ અને સરનામું.",
  DocuGard_Cheque_Top_Window:"સુરક્ષિત કાગળ માટે તપાસો.",
  DocuGard_Cheque_Middle_Window:"સુરક્ષિત કાગળ માટે તપાસો.",
  DocuGard_Cheque_Bottom_Window:"સુરક્ષિત કાગળ માટે તપાસો.",
  Basic_Cheque:"ચેક (ટોચ)",
  Basic_Cheque_Print:"એક જ ચેક છાપો.",
  Error_Setting_As_Paid:"ચૂકવણી તરીકે સેટ કરવામાં ભૂલ",
  Add_Attachment:"જોડાણ ઉમેરો",
  PrinterUnavailable:"પ્રિંટર અનુપલબ્ધ",
  CreditCardComponent:"કાર્ડ",
  PaypalComponent:"પેપાલ",
  InteracComponent:"ઇન્ટરેક",
  BitcoinComponent:"બિટકોઇન",
  New_Deposit:"નવી ડિપોઝિટ",
  Deposits:"થાપણો",
  No_Deposits:"કોઈ ડિપોઝિટ નથી",
  Credit_Card_Deposit:"ક્રેડીટ કાર્ડ",
  New_Credit_Card_Deposit_Message:"ક્રેડિટ કાર્ડ ડિપોઝિટ",
  New_BitCoin_Deposit:"બિટકોઇન",
  New_BitCoin_Deposit_Message:"બિટકોઇન ડિપોઝિટ",
  New_Interac_Deposit:"ઇન્ટરેક",
  New_Interac_Deposit_Message:"ઇન્ટરેક ટ્રાન્સફર",
  Payments_Limit:"ચુકવણી મર્યાદા",
  No_Payment_Limit:"કોઈ ચુકવણી મર્યાદા નથી",
  PayPal_Deposit:"પેપાલ",
  PayPal_Deposit_Message:"પેપાલ ડિપોઝિટ",
  No_Deposits_Word:"પ્રથમ કંપની <a routerLink='/folder/Deposit/New'>થાપણ</a> ઉમેરો.",
  No_Documents_Specified:"છાપવા માટે કોઈ દસ્તાવેજો ઉલ્લેખિત નથી",
  No_Printers_Added:"કોઈ પ્રિન્ટર મળ્યા નથી. એક ઉમેરવા માટે સેટિંગ્સ > પ્રિન્ટર્સ પર જાઓ.",
  DB_Erase_Prompt:"સંપૂર્ણ ડેટાબેઝને સંપૂર્ણપણે ભૂંસી નાખો? ચેતવણી: તમે બધી સંગ્રહિત માહિતી ગુમાવશો!",
  Console_Warning:"આ કન્સોલમાં કોઈપણ ટેક્સ્ટ પેસ્ટ કરશો નહીં. તમે તમારી જાતને અને/અથવા તમારી કંપનીને ગંભીર જોખમમાં મૂકી શકો છો.",
  No_Faxes_Word:"પ્રથમ <a routerLink='/folder/Fax/New'>ફેક્સ</a> બનાવો.",
  Cheque_Delete_Confirmation:"શું તમે ખરેખર આ ચેક કાઢી નાખવા માંગો છો?",
  Design_Delete_Confirmation:"શું તમે ખરેખર આ ડિઝાઇન કાઢી નાખવા માંગો છો?",
  Cheque_Pay_Confirmation:"આ ચેકને ચૂકવેલ તરીકે ચિહ્નિત કરો? તે પ્રિન્ટ કતારમાં દેખાશે નહીં.",
  Payment_Pay_Confirmation:"આ ચુકવણીને ચૂકવેલ તરીકે ચિહ્નિત કરો? તે ચેક કતારમાં દેખાશે નહીં.",
  Delete_Deduction_Confirmation:"શું તમે ખરેખર આ કપાત કાઢી નાખવા માંગો છો?",
  Delete_Job_Confirmation:"શું તમે ખરેખર આ નોકરી કાઢી નાખવા માંગો છો?",
  Delete_Timesheet_Confirmation:"શું તમે ખરેખર આ સમયપત્રક કાઢી નાખવા માંગો છો?",
  Delete_Schedule_Confirmation:"શું તમે ખરેખર આ શેડ્યૂલ કાઢી નાખવા માંગો છો?",
  Delete_Setting_Confirmation:"શું તમે ખરેખર આ સેટિંગ રીસેટ કરવા માંગો છો?",
  Delete_Fax_Confirmation:"શું તમે ખરેખર આ ફેક્સ કાઢી નાખવા માંગો છો?",
  Delete_File_Confirmation:"શું તમે ખરેખર આ ફાઇલ કાઢી નાખવા માંગો છો?",
  Delete_Vacation_Confirmation:"શું તમે ખરેખર આ વેકેશન કાઢી નાખવા માંગો છો?",
  Delete_Printer_Confirmation:"શું તમે ખરેખર આ પ્રિન્ટરને કાઢી નાખવા માંગો છો?",
  Remove_Design_Confirmation:"શું તમે ખરેખર આ ડિઝાઇન કાઢી નાખવા માંગો છો?",
  Delete_Payroll_Confirmation:"શું તમે ખરેખર આ પગારપત્રક કાઢી નાખવા માંગો છો?",
  Send_Fax_Email_Confirmation:"શું તમે આ દસ્તાવેજોને ફેક્સ અને ઈમેલ કરવા માંગો છો?",
  Send_Email_Confirmation:"શું તમે આ દસ્તાવેજ ઈમેલ કરવા માંગો છો?",
  Send_Fax_Confirmation:"શું તમે આ દસ્તાવેજને ફેક્સ કરવા માંગો છો?",
  Error_Generating_PDF:"માફ કરશો. આ દસ્તાવેજ જનરેટ કરવામાં ભૂલ આવી હતી.",
  PDF_Error_Saving_Image:"માફ કરશો. આ દસ્તાવેજની PDF ઇમેજ સાચવવામાં ભૂલ આવી હતી.",
  Test_Printer_Confirmation:"શું તમે આ પ્રિન્ટર પર પરીક્ષણ પૃષ્ઠ છાપવા માંગો છો?",
  Save_Timesheet_Prompt:"કૃપા કરીને એક 'શીર્ષક' ઉમેરો અથવા સાચવવા માટે 'સ્ટાર્ટ ટાઈમર' દબાવો.",
  Remove_Geofence_Prompt:"શું તમે ખરેખર આ જીઓ-ફેન્સનું સ્થાન દૂર કરવા માંગો છો?",
  Delete_Employee_Confirmation:"શું તમે ખરેખર કાઢી નાખવા માંગો છો",
  Fire_Employee_Confirmation:"શું તમે ખરેખર ફાયર કરવા માંગો છો"
}