export const Id = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Hak Cipta &copy; 2023",
  black:"Hitam",
  green:"Hijau",
  gold:"Emas",
  blue:"Biru",
  brown:"Cokelat",
  purple:"Ungu",
  pink:"Merah Jambu",
  red:"Merah",
  Swatches:"Swatch",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Warna",
  Saturation:"Kejenuhan",
  Lightness:"Keringanan",
  Upgrade_To_Pro:"Upgrade ke yang lebih baik",
  AllFeaturesInclude:"Semua Fitur Termasuk:",
  PrintUnlimitedCheques:"Cetak Cek Tanpa Batas",
  PremiumChequeDesigns:"Desain Cek Premium",
  ManageUnlimitedEmployees:"Kelola Karyawan Tanpa Batas",
  AddUnlimitedPayees:"Tambahkan Penerima Pembayaran Tidak Terbatas",
  CreateUnlimitedPayrolls:"Buat Payroll Tanpa Batas",
  UnlimitedSchedulesandTimeSheets:"Jadwal dan Lembar Waktu Tidak Terbatas",
  BulkPayPalPayouts:"Pembayaran PayPal Massal",
  UnlimitedBankAccounts:"Rekening Bank Tidak Terbatas",
  ManageMultipleCompanies:"Kelola Banyak Perusahaan",
  TrackInsurancePolicies:"Lacak Kebijakan Asuransi",
  Bio_MetricProtection:"Perlindungan Bio-Metrik",
  Geo_FenceLock_OutProtection:"Perlindungan Penguncian Geo-Fence",
  Multiple_Companies_Word:"Mengelola beberapa perusahaan tidak tersedia tanpa cek premium.",
  PayPal_Payouts_Word:"Pembayaran PayPal dinonaktifkan tanpa cek premium.",
  PINProtection:"Perlindungan PIN",
  PasswordProtection:"Perlindungan Kata Sandi",
  May_Require_Approval:"Mungkin memerlukan persetujuan.",
  Subscribe:"Langganan",
  Billed:"Ditagih",
  Up:"Ke atas",
  Down:"Turun",
  Positioning:"Penentuan posisi",
  Marker:"Penanda",
  Drag_Marker:"Seret Penanda",
  Tagline:"Cetak Cek dan Tabulasikan Daftar Gaji",
  Marker_Word:"Gunakan Penanda untuk mengukur elemen.",
  Pinch_Zoom:"Zoom Cubit",
  Pinch_Word:"Cubit untuk memperbesar elemen.",
  Drag:"Menyeret",
  Drag_Word:"Gunakan jari Anda untuk menyeret elemen.",
  subscription_alias_word:"Langganan Perpanjangan Otomatis",
  premium_alias_word:"Paket Peningkatan Satu Kali",
  print_alias_word:"Print-Out Cek Individu",
  mode_alias_word:"Mode",
  Pro:"Pro",
  Pro_word:"Diperlukan versi pro.",
  Cant_Delete_Default_Company_Word:"Maaf, Anda tidak dapat menghapus perusahaan bawaan Anda.",
  Reinsert_Default_Designs:"Masukkan Ulang Desain Default",
  Reinsert_Default_Designs_word:"Apakah Anda ingin memasukkan kembali desain default?",
  Subscription_Active_Disable_Word:"Langganan ini aktif. Apakah Anda ingin membatalkan langganan Cek ini?",
  Company_Logo:"Logo perusahaan",
  ZERO_:"NOL",
  Disclaimer:"Penafian",
  Privacy_Policy:"Kebijakan pribadi",
  EULA:"Memeriksa EULA",
  Terms_Of_Service:"Ketentuan Layanan",
  Terms_Of_Use:"Syarat Penggunaan",
  Refunds:"Kebijakan pengembalian",
  Single_Print:"1 Periksa",
  Two_Prints:"2 Cek",
  Five_Prints:"5 Cek",
  Ten_Prints:"10 Cek",
  Fifteen_Prints:"15 Cek",
  Twenty_Prints:"20 Cek",
  Thirty_Prints:"30 Cek",
  Image_Added:"Gambar Ditambahkan",
  Image_Preview:"Pratinjau Gambar",
  No_Image_Was_Selected:"Tidak ada gambar yang dipilih.",
  Cheques_Unlimited:"Cek Tidak Terbatas",
  _Subscription:"Berlangganan",
  Subscription:"Cek - 1 Bulan",
  Two_Month_Subscription:"Cek - 2 Bulan",
  Three_Month_Subscription:"Cek - 3 Bulan",
  Six_Month_Subscription:"Cek - 6 Bulan",
  Twelve_Month_Subscription:"Cek - 12 Bulan",
  Cheques_Are_Available:"Cek tersedia untuk dicetak.",
  Upgrade_Required_Two:"Pilih paket dan ketuk dua kali pada tombol harga untuk memulai pembelian Anda. Cetak cek penuh warna yang tampak profesional dalam hitungan detik.",
  Month:"Bulan",
  Due:"Jatuh tempo:",
  Expires:"Kedaluwarsa:",
  Subscription_Active:"Langganan Aktif",
  Subscription_Inactive:"Langganan Tidak Aktif",
  Purchase_Additional_Cheques:"Beli cek tambahan?",
  Printable_Cheque:"Cek yang Dapat Dicetak",
  Printable_Cheques:"Cek yang Dapat Dicetak",
  Printable_Cheque_Word:"cek tersedia di akun Anda.",
  Printable_Cheques_Word:"cek tersedia di akun Anda.",
  Max_Amount_Message:"Jumlah yang Anda tentukan telah mencapai jumlah maksimum yang ditetapkan untuk sistem ini:",
  Terms_Required_Word:"Anda harus menyetujui perjanjian ini sebelum melanjutkan penggunaan Cek.",
  Subscriptions:"Langganan",
  Product_Upgrades:"Upgrade",
  Mailed_Out_Cheques:"Cek yang Dikirimkan",
  Enter_A_Company_Name:"Masukkan nama perusahaan.",
  Single_Cheques:"Cek Tunggal",
  Cheque_Golden:"Cek Emas",
  Cheque_Golden_Window:"Desain cek emas.",
  Cheque_Green:"Cek Hijau",
  Cheque_Green_Window:"Desain cek hijau.",
  Cheque_Red:"Centang Merah",
  Cheque_Red_Window:"Desain cek merah.",
  Cheque_Yellow:"Cek Kuning",
  Cheque_Yellow_Window:"Desain cek kuning.",
  Cheque_Statue_of_Liberty:"Patung Liberty",
  Cheque_Statue_of_Liberty_Window:"Desain cek Patung Liberty.",
  Cheque_Green_Wave:"Gelombang Hijau",
  Cheque_Green_Wave_Window:"Desain cek hijau.",
  Cheque_Golden_Weave:"Tenunan Emas",
  Cheque_Golden_Weave_Window:"Desain cek emas yang elegan.",
  Cheque_Green_Sun:"Matahari Hijau",
  Cheque_Green_Sun_Window:"Desain cek yang dalam dan menenangkan.",
  Cheque_Blue_Checkers:"Pemeriksa Biru",
  Cheque_Blue_Checkers_Window:"Desain cek biru.",
  Cashiers_Check:"Cek Kasir",
  Cashiers_Check_Window:"Templat gaya Cek Kasir.",
  Cheque_Aqua_Checkers:"Pemeriksa Aqua",
  Cheque_Aqua_Checkers_Window:"Desain cek aqua.",
  Cheque_Golden_Checkers:"Pemeriksa Emas",
  Cheque_Golden_Checkers_Window:"Desain cek emas.",
  Upgrade_Unavailable:"Upgrade Tidak Tersedia",
  Bank_Code_Protection:"Perlindungan Nomor Bank",
  Bank_Code_Protection_Word:"Lindungi nomor bank Anda agar tidak digunakan pada aplikasi ini yang berjalan di perangkat lain atau untuk pengguna lain. Tindakan ini TIDAK DAPAT DIKEMBALIKAN. Melanjutkan?",
  Bank_Code_Protection_Blocked_Word:"Nomor bank yang Anda coba gunakan dicadangkan untuk pengguna atau perangkat lain.",
  Bank_Code_Protection_Error_Word:"Verifikasi nomor gagal. Sambungkan ke internet dan coba tambahkan lagi rekening bank ini.",
  Bank_Code_Protection_Set_Error_Word:"Perlindungan nomor bank mengharuskan Anda terhubung ke internet. Sambungkan dan coba lagi.",
  Upgrade_Unavailable_Word:"Maaf, kami kesulitan memverifikasi Anda. Langganan dan peningkatan ke Cek saat ini tidak tersedia untuk dibeli di wilayah Anda.",
  PayPal_Payment_Preview:"Pratinjau Pembayaran PayPal",
  Apple_Pay:"Apple Bayar",
  Select_PayPal:"Pilih PayPal",
  PayPal_Applications:"Aplikasi PayPal",
  Purchase_With_Apple_Pay:"Beli dengan Apple Pay",
  Google_Pay:"Google Bayar",
  Companies:"Perusahaan",
  Insurance:"Pertanggungan",
  New_PayPal:"PayPal Baru",
  Pay_By:"Membayar dengan",
  Insure:"Memastikan",
  Miles:"Mil",
  Payment_Method:"Cara Pembayaran",
  Select_Policies:"Pilih Kebijakan",
  Policies:"Kebijakan",
  Policy:"Kebijakan",
  No_PayPal_Account:"Tidak Ada Akun PayPal",
  No_Policies:"Tidak Ada Polis Asuransi",
  New_Policy:"Kebijakan Baru",
  PayPal_Payment:"Pembayaran PayPal",
  PayPal_Payments:"Pembayaran PayPal",
  No_Payment_Selected:"Tidak Ada Pembayaran yang Dipilih",
  Sending_Payment_Word:"Harap tunggu... Pembayaran ini sedang dikirim.",
  Sending_Payments_Word:"Harap tunggu... Pembayaran sedang dikirim.",
  No_Payment_Selected_PayPal:"Tidak ada <a routerLink='/folder/Payments'>Pembayaran PayPal</a> yang dipilih untuk dikirim.",
  Payment_Sent:"Pembayaran Terkirim",
  PayPal_Payment_Sent:"Pembayaran ini telah dikirim dengan PayPal.",
  Copay:"Salin",
  Dead:"Mati",
  Alive:"Hidup",
  Not_Dead:"Tidak mati",
  Unclaimed:"Belum diklaim",
  Attempted:"Mencoba",
  Deceased:"Almarhum",
  Claimed:"Diklaim",
  Unpaid:"Belum dibayar",
  Sending_Payment:"Mengirim Pembayaran",
  Sending_Payments:"Mengirim Pembayaran",
  Send_PayPal_Confirmation:"Apakah Anda ingin mengirim transaksi ini dengan PayPal?",
  Send_PayPal_Confirmation_Word:"Tekan tombol hijau untuk mengirim transaksi ini.",
  Save_Payment_As_Unpaid:"Simpan pembayaran ini sebagai belum dibayar?",
  Payment_Pay_Confirmation_PayPal:"Simpan pembayaran ini sebagai dibayar?",
  No_Policies_Word:"Tambahkan <a routerLink='/folder/Postage/New'>Kebijakan Asuransi</a> pertama sekarang.",
  Timesheet_Multiple_Delete_Confirmation:"Yakin ingin menghapus beberapa lembar waktu?",
  Select_Multiple_Timesheets_Prompt:"Tidak ada lembar waktu yang dipilih. Pilih setidaknya satu lembar waktu.",
  Select_Multiple_Policies_Prompt:"Tidak ada kebijakan yang dipilih. Pilih setidaknya satu polis asuransi.",
  Policies_Multiple_Delete_Confirmation:"Yakin ingin menghapus beberapa kebijakan?",
  Company:"Perusahaan",
  Add_Company:"Tambahkan Perusahaan",
  New_Company:"Tambahkan Perusahaan",
  No_Companies:"Tidak ada Perusahaan",
  Enable_Company:"Aktifkan Perusahaan",
  Select_Company:"Pilih Perusahaan",
  The_Default_Company:"Label perusahaan default.",
  Manage_Companies:"Kelola Perusahaan",
  No_Companies_Word:"Cek akan menggunakan perusahaan default.<br />Tambahkan <a routerLink='/folder/Company/New'>Perusahaan Pertama</a>.",
  Default_Company:"Perusahaan bawaan",
  Cheques_Unlimited_Word:"Cek Tidak Terbatas memungkinkan Anda untuk mencetak cek sebanyak yang Anda suka.",
  Cheques_Subscription_Word:"Langganan Cek memungkinkan Anda mencetak cek sebanyak yang diinginkan.",
  You_Own_This_Product:"Anda memiliki produk ini.",
  Your_Subscription_is_Active:"Langganan Anda aktif.",
  Active_Products:"Produk yang Diaktifkan",
  Purchase_Confirmation:"Pembelian",
  Purchase_Cheques:"Cek Pembelian",
  Restore_Purchase:"Mengembalikan pembelian",
  Erase_Purchase:"Hapus Pembelian",
  Successfully_Purchased:"Berhasil Dibeli",
  Enter_Your_Licence_Key:"Silakan masukkan kunci lisensi Anda di halaman ini untuk mengaktifkan produk ini.",
  Licence_Key:"Kunci lisensi",
  Enter_Licence_Key:"Masukan kode lisensi",
  Purchased:"Dibeli",
  Enable_Feature:"Aktifkan Fitur",
  Cancel_Subscription:"Batalkan Langganan",
  Subscription_Removed:"Langganan Dihapus",
  Select_Active_Subscription:"Pilih langganan aktif untuk mengubahnya.",
  Remove_Subscription_Word:"Yakin ingin membatalkan langganan ini?",
  Delete_Company_Confirmation:"Yakin ingin menghapus seluruh perusahaan ini? PERINGATAN: Anda akan kehilangan semua informasi yang tersimpan!",
  Delete_Default_Company_Word:"Anda tidak dapat menghapus perusahaan default. Apakah Anda ingin mengatur ulang aplikasi dan mengembalikannya ke keadaan default? PERINGATAN: Anda akan kehilangan semua informasi yang tersimpan!",
  Console_Warning_2:"Jangan menangani mata uang apa pun menggunakan aplikasi ini yang saat ini bukan milik Anda.",
  Terms_and_Conditions:"syarat dan Ketentuan",
  and_the:"dan",
  for:"untuk",
  Please_Read_Word:"Harap baca dan setujui",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Beberapa kurs konversi mata uang tidak dapat ditemukan. Harap terhubung ke internet.",
  Free:"Bebas",
  DB_Erase_Prompt_2:"Hapus seluruh database pengembang sepenuhnya? PERINGATAN: Anda akan kehilangan semua informasi PEMBELIAN dan BERLANGGANAN!",
  Successfully_Imported:"Berhasil Diimpor",
  Select_Postage:"Pilih Ongkos Kirim",
  No_Postage:"Tidak Ada Prangko",
  No_Paid_Postage_Word:"Tambahkan stempel <a routerLink='/folder/Postage/New'>Ongkos Kirim Dibayar</a> pertama.",
  Trial_Complete:'Uji Coba Selesai',
  Please_Upgrade:'Tingkatkan versi Cek untuk melanjutkan pencetakan.',
  Aa:"Aa",
  Color:"Warna",
  Font:"font",
  Guide:"Memandu",
  Guides:"Panduan",
  Image:"Gambar",
  Zoom:"Perbesar",
  Logo:"Logo",
  Bank:"Bank",
  MICR:"MICR",
  Total:"Total",
  Cancel:"Membatalkan",
  Confirm:"Mengonfirmasi",
  Method:"metode",
  Biometric_Security:"Keamanan Bio-metrik",
  Please_Login_To_Continue:"Silahkan masuk untuk melanjutkan.",
  Complete:"Menyelesaikan",
  Sign_Up:"Daftar",
  Error:"Kesalahan",
  Biometrics:"Biometrik",
  Percent:"Persen",
  Zero_Percent:"0%",
  Top_Margin:"Batas atas",
  Bottom_Margin:"Margin Bawah",
  Left_Margin:"Margin Kiri",
  Right_Margin:"Margin Kanan",
  MICR_Margin:"Margin MICR",
  Table_Margin:"Margin Tabel",
  Address_Margin:"Margin Alamat",
  Percentage_:"Persentase",
  Vacation_Title:"Judul Liburan",
  Use_PIN:"Gunakan PIN",
  Loading__:"Memuat...",
  Design_Title:"Judul Desain",
  Authorize:"Mengizinkan",
  Key:"Kunci",
  Public_Key:"Kunci Publik",
  Private_Key:"Kunci Pribadi",
  Authenticate:"Otentikasi",
  Last_Payroll:"Gaji Terakhir",
  Last_Calculation:"Perhitungan Terakhir",
  Authorized:"Resmi",
  Geo_Authorized:"Geo-Lokasi: Resmi",
  Not_Authorized:"Tidak diizinkan",
  Authorization_Complete:"Otorisasi Selesai",
  Geolocation_Authorization:"Otorisasi lokasi geografis",
  Out_of_Bounds:"Di luar batas",
  Cant_Delete_Default_Design:"Tidak dapat menghapus desain default.",
  Unauthorized_Out_of_Bounds:"Tidak Sah: Di Luar Batas",
  Biometric_Authorization:"Otorisasi Bio-metrik",
  Locating_Please_Wait:"Menemukan, Harap Tunggu...",
  Test_Biometrics:"Uji Bio-metrik",
  Cheque_Margins:"Periksa Margin",
  Percentage_Full_Error:"Bidang persentase tidak dapat disetel lebih dari 100% persen.",
  No_Payroll_Text:"Tambahkan <a routerLink='/folder/Employee/New'>Karyawan</a> atau <a routerLink='/folder/Payee/New'>Penerima Pembayaran</a> dan <a routerLink='/folder/Schedule/New'>Jadwal</a>.",
  Design_Saved:"Desain Disimpan",
  Default_Design_Selected:"Desain Default Dipilih",
  Partial_Import:"Impor Sebagian",
  Partial_Export:"Ekspor Sebagian",
  Entire_Import:"Seluruh Impor",
  Entire_Export:"Seluruh Ekspor",
  Existing_Password:"Silakan masukkan kata sandi Anda yang ada:",
  Existing_PIN:"Silakan masukkan kode PIN Anda yang ada:",
  Pin_Change_Header:"Konfirmasi PIN",
  Pin_Change_SubHeader:"Masukkan nomor PIN lama Anda untuk mengonfirmasi perubahan.",
  Pass_Change_Header:"Konfirmasi kata kunci",
  Pass_Change_SubHeader:"Masukkan kata sandi lama Anda untuk mengonfirmasi perubahan.",
  PIN_Enter_Message:"Masukkan PIN Anda untuk mengonfirmasi.",
  Password_Enter_Message:"Masukkan kata sandi Anda untuk mengonfirmasi.",
  Pin_Updated_Success:"PIN berhasil diperbarui!",
  Pin_Updated_Fail:"PIN tidak dapat diperbarui.",
  Pass_Updated_Success:"Kata sandi berhasil diperbarui.",
  Pass_Updated_Fail:"Kata sandi tidak dapat diperbarui.",
  Preview_Payment:"Pratinjau Pembayaran",
  Preview_Payroll:"Pratinjau Penggajian",
  No_Payments_Created:"Tidak ditemukan pembayaran yang dibuat.",
  Payment_Preview:"Pratinjau Pembayaran",
  Enable_Payee:"Aktifkan Penerima Pembayaran",
  Rendered:"Dirender",
  No_Email:"Tidak Ada Email",
  Setup_Cheques:"Pemeriksaan Pengaturan",
  name:"Nama",
  address:"Alamat",
  address_2:"Alamat Baris 2",
  city:"Kota",
  province:"Propinsi",
  postal_code:"Kode Pos/ZIP",
  country:"Negara",
  username:"Nama belakang",
  full_name:"Nama lengkap",
  birthday:"Hari ulang tahun",
  email:"Surel",
  phone:"Telepon",
  employees:"Para karyawan",
  addresses:"Alamat",
  payment_amount_limit:"Batas Jumlah Pembayaran",
  total_limit:"Batas Total",
  payees:"penerima pembayaran",
  description:"Keterangan",
  address_line_one:"Alamat Baris Satu",
  address_line_two:"Alamat Baris Dua",
  image:"Gambar",
  account_holder:"Pemilik akun",
  cheque_starting_id:"Periksa ID Awal",
  transit_number:"Nomor Transit",
  institution_number:"Nomor Institusi",
  designation_number:"Nomor penunjukan",
  account_number:"Nomor akun",
  currency:"Mata uang",
  signature:"Tanda tangan",
  payment_payroll_limit:"Batas Pembayaran",
  total_limi:"Batas total",
  date:"Tanggal",
  printed_memo:"Memo Tercetak",
  banks:"Bank",
  signature_image:"Gambar Tanda Tangan",
  address_name:"Nama alamat",
  notes:"Catatan",
  design:"Rancangan",
  title:"Judul",
  frequency:"Frekuensi",
  fax:"Fax",
  salaries:"Gaji",
  salary_ids:"ID gaji",
  start_time:"Waktu mulai",
  end_time:"Akhir waktu",
  paid:"Dibayar",
  overtime_percentage:"Persentase yang Dibayar",
  overtime_amount:"Jumlah Tetap yang Dibayar",
  first_name:"Nama depan",
  last_name:"Nama keluarga",
  moderation:"moderasi",
  create:"Membuat",
  edit:"Sunting",
  destroy:"Menghancurkan",
  day_start_time:"Hari_mulai_waktu",
  day_end_time:"Hari_akhir_waktu",
  fullname:"Nama",
  time:"Waktu",
  auto_send:"Kirim Otomatis",
  time_method:"Metode Waktu",
  schedules:"Jadwal",
  indefinite_payroll_enabled:"Aktifkan Tanpa Batas",
  amount:"Jumlah",
  repeat:"Ulang",
  always_enabled:"Selalu Diaktifkan",
  start_date:"Mulai tanggal",
  end_date:"Tanggal Akhir",
  Cheque_Total:"Cek Total",
  Total_Amount:"Jumlah total:",
  Amounts:"Jumlah:",
  Images:"Gambar-gambar",
  Files:"File",
  Previewing:"Pratinjau:",
  Insert:"Memasukkan",
  Preview_Import:"Pratinjau Impor",
  Entry:"Pintu masuk",
  Entries:"Entri",
  No_Entries:"Tidak Ada Entri",
  Import_Type:"Jenis Impor",
  Select_Details:"Pilih Detail",
  Select_Payee:"Pilih Penerima Pembayaran",
  Enable_Holidays:"Aktifkan Liburan",
  Disable_Holidays:"Nonaktifkan Hari Libur",
  Wire_Transfer:"Transfer rekening",
  New_Export:"Ekspor Baru",
  Export_Data:"Ekspor Data",
  Export_Data_Word:"Pilih jenis file yang akan diekspor dan diunduh.",
  Export_File:"Ekspor File",
  Mode:"Mode",
  Times:"Waktu",
  Widgets:"Widget",
  Slider:"Penggeser",
  Set_Details:"Tetapkan Detail",
  Select_Type:"Pilih tipe",
  Display_Slider:"Tampilan Slider",
  Dashboard_Slider:"Penggeser Dasbor",
  Dashboard_Mode:"Mode Dasbor",
  Show_Intro:"Tampilkan Perkenalan",
  Show_Payrolls:"Tampilkan Penggajian",
  Show_Holidays:"Tampilkan Hari Libur",
  Show_Invoices:"Tampilkan Faktur",
  Show_Cheques:"Tampilkan Cek",
  Enabled_Widgets:"Widget yang Diaktifkan",
  Disabled_Widgets:"Widget yang Dinonaktifkan",
  Colors:"warna",
  Barcodes:"Barcode",
  View_Timers:"Lihat Timer",
  Gradients:"Gradien",
  No_Info:"Tidak ada informasi",
  Disable:"Cacat",
  Show_Layer:"Tampilkan Lapisan",
  Hide_Layer:"Sembunyikan Lapisan",
  Text_Layer:"Lapisan Teks",
  Secondly:"Kedua",
  Minutely:"beberapa menit",
  nine_am:"9:00 PAGI",
  five_pm:"17.00",
  Enable_Address:"Aktifkan Alamat",
  Invalid_File_Type:"Maaf, jenis file yang dipilih tidak valid. Jenis file yang didukung:",
  Error_Updating_Entry:"Maaf, terjadi kesalahan saat memperbarui entri ini.",
  Schedule_Timing_Alert:"Kesalahan: Jadwal waktu mulai diatur ke nilai setelah waktu berakhir.",
  Select_Multiple_Payments_Prompt:"Tidak ada pembayaran yang dipilih. Pilih setidaknya satu pembayaran.",
  Select_Multiple_Cheques_Prompt:"Tidak ada pemeriksaan yang dipilih. Harap pilih setidaknya satu centang.",
  Select_Multiple_Items_Prompt:"Tidak ada item yang dipilih. Harap pilih setidaknya satu item.",
  Paymemt_Multiple_Delete_Confirmation:"Apakah Anda yakin ingin menghapus beberapa pembayaran?",
  Cheque_Multiple_Delete_Confirmation:"Anda yakin ingin menghapus beberapa cek?",
  Payee_Multiple_Delete_Confirmation:"Apakah Anda yakin ingin menghapus beberapa penerima pembayaran?",
  Employee_Multiple_Delete_Confirmation:"Apakah Anda yakin ingin menghapus beberapa karyawan?",
  MICR_Warning:"Catatan: Beberapa printer dan perangkat mungkin tidak menampilkan font MICR dengan benar.",
  Automatically_Send:"Kirim Otomatis",
  Type:"Jenis",
  Payment_Type:"Tipe pembayaran",
  Auto_Send:"Kirim Otomatis",
  Automatically_Process:"Proses Otomatis",
  Auto_Process:"Proses Otomatis",
  Image_Based:"Berbasis gambar",
  Font_Based:"Berbasis font",
  Rerender:"Re-render",
  Rendering:"Rendering",
  Render:"File",
  Top:"Atas",
  Middle:"Tengah",
  Bottom:"Bawah",
  Top_Left:"Kiri Atas",
  Top_Center:"tengah atas",
  Top_Right:"Kanan atas",
  Middle_Left:"Kiri Tengah",
  Middle_Center:"Tengah Tengah",
  Middle_Right:"Kanan Tengah",
  Bottom_Left:"Kiri bawah",
  Bottom_Center:"Tengah bawah",
  Bottom_Right:"Kanan bawah",
  Numbers:"angka",
  Verified:"Diverifikasi",
  Paper_Size:"Ukuran kertas",
  New_Device:"Perangkat baru",
  Add_Device:"Tambahkan Perangkat",
  Remove_Device:"Hapus Perangkat",
  Delete_Device:"Hapus Perangkat",
  Block_Device:"Blokir Perangkat",
  Block:"Memblokir",
  Unblock:"Buka blokir",
  Table:"Meja",
  Scan_Login_Code:"Pindai Kode Masuk",
  Login_Code:"Kode Masuk",
  Scan_Code:"Pindai Kode",
  Scan_QR_Code:"Pindai kode QR",
  Select_All:"Pilih Semua",
  Deselect_All:"Batalkan Pilihan Semua",
  Increase:"Meningkat",
  Decrease:"Mengurangi",
  Bold:"Berani",
  Text:"Teks",
  Style:"Gaya",
  Italic:"miring",
  QR_Code:"Kode QR",
  Barcode:"kode batang",
  Browse_Images:"Jelajahi Gambar",
  Browse_Files:"Mencari berkas",
  Background_Image:"Gambar latar belakang",
  Logo_Image:"Gambar logo",
  Header_Image:"Gambar Tajuk",
  Bank_Image:"Gambar Bank",
  Cut_Lines:"Potong Garis",
  Background:"Latar belakang",
  License:"Lisensi",
  One_License:"1 Lisensi:",
  Licensed:"Berlisensi untuk:",
  Not_Licensed:"Tidak Berlisensi",
  Enter_Serial:"Masuk Serial",
  Serial_Key:"Kunci Seri",
  Serial:"Serial",
  Product_Key:"Kunci produk",
  Check_Product_Key:"Periksa Kunci Produk",
  Add_Product_Key:"Tambahkan Kunci Produk",
  Verifying_Purchase:"Memverifikasi Pembelian...",
  Print_Envelope:"Cetak Amplop",
  Envelope:"Amplop",
  Thank_You:"Terima kasih!",
  Scale:"Skala",
  Print_Scale:"Skala Cetak",
  Borders:"perbatasan",
  VOID:"RUANG KOSONG",
  Void_Cheque:"Cek Batal",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"MEMBAYAR ORDER DARI:",
  NO_DOLLARS:"TIDAK ADA DOLAR ",
  ONE_DOLLAR:"SATU DOLAR",
  DOLLARS:" DOLAR",
  AND:" DAN ",
  CENTS:" SEN.",
  NO_:"TIDAK ",
  ONE_:"SATU ",
  AND_NO_:"DAN TIDAK ",
  _AND_ONE_:"DAN SATU ",
  DOLLARS_AND_ONE_CENT:" DAN SATU SEN.",
  AND_NO_CENTS:" DAN NOL SEN.",
  CHEQUE_PRINT_DATE:"TANGGAL:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Tugas Awal",
  Inital_Setup:"Pengaturan Awal",
  Welcome_To:"Selamat Datang di ",
  Welcome:"Selamat datang",
  Swipe:"Geser",
  Intro_Setup:"Pengaturan Pendahuluan",
  Introduction:"Pengantar",
  CHEQUE_PRINT_CREDIT:"Didukung oleh Cek",
  Title_Intro_Word:"Selamat datang di Cek",
  Title_Intro:"Perkenalan Cek",
  Title_Setup:"Pengaturan Cek",
  PayPal_Default_Email_Message:"Anda telah menerima transfer PayPal baru. [Didukung oleh Cek]",
  Cheques_App_Export:"Diekspor dengan Cek",
  Post_Intro_Thanks:"Terima kasih telah memilih Cek. Proses penyiapan sekarang selesai.",
  Post_Intro_Word:"Mulailah dengan mencetak cek pertama Anda, menambahkan pembayaran di masa mendatang, atau menambahkan karyawan ke daftar gaji.",
  Upgrade_Required:"Cek mengharuskan Anda memiliki versi perangkat lunak yang lebih premium untuk menyembunyikan pesan ini dan membuka fitur tambahan.",
  Upgrade_Title:"Cek",
  Mailout_Prompt:"Anda juga dapat memilih agar Cek mengirimkan cek gaji Anda untuk Anda.",
  Mailed_Cheque:"Cek Terkirim:",
  Mailed_Cheque_Color:"Cek Terkirim (Warna):",
  Terms_Purchase:"Semua pembelian elektronik dengan Cek dapat dikembalikan sepenuhnya hingga 30 hari sejak tanggal pembelian. Harap baca dan setujui <a href='#'>Syarat dan Ketentuan</a> sebelum melanjutkan.",
  Dashboard_Setup:"Setup Pencetak Utama",
  Dashboard_Add:"Tambahkan Rekening Bank Utama",
  Dashboard_Customize:"Pilih Template Centang",
  Dashboard_Job_Salary:"Buat Pekerjaan Anda & Tambahkan Gaji Anda",
  Dashboard_Employees:"Lacak Karyawan & Penerima Pembayaran",
  Dashboard_Print:"Cetak & Kirimkan Gaji Anda",
  Dashboard_Payroll:"Otomatiskan Pencetakan Gaji Anda",
  Dashboard_PayPal:"Siapkan Payroll / Pembayaran PayPal",
  Begin_Setup:"Mulai Pengaturan",
  Get_Started:"Memulai",
  Purchase:"Pembelian",
  Lockdown:"Kuncitara",
  Unlock:"Membuka kunci",
  Detailed:"Terperinci",
  Log_In:"Gabung",
  Login:"Gabung",
  Launch:"Meluncurkan",
  Register:"Daftar",
  Finish:"Menyelesaikan",
  List:"Daftar",
  Weekends:"Akhir pekan",
  Weekly:"Mingguan",
  PayPal_Default_Subject:"Pembayaran Baru",
  Payment_Message:"Pesan Pembayaran",
  PayPal_Default_Payment_Note:"Terima kasih",
  Setup_Your_Cheqing_Account:"Rekening Cek",
  Add_Your_Primary_Cheqing_Account:"Tambahkan akun cheqing utama Anda.",
  Welcome_Word:"Sederhanakan dan otomatisasi penggajian dan manajemen sumber daya manusia.",
  Get_Started_Quickly:"Jawab saja beberapa pertanyaan sederhana yang akan membantu kita memulai...",
  Done_Intro_Word:"Pengaturan Selesai",
  PIN_Protected:"Dilindungi Kata Sandi & PIN",
  Enter_New_PIN:"Masukkan Kode PIN baru:",
  Enter_PIN:"Masukkan Kode PIN:",
  Invalid_PIN:"PIN yang dimasukkan salah.",
  Account_Identifier:"Pengidentifikasi Akun",
  New_Account_Identifier:"Pengidentifikasi Akun Baru",
  attempt:"percobaan",
  attempts:"usaha",
  remaining:"tersisa",
  Language:"Bahasa",
  languages:"Bahasa",
  select_languages:"Pilih bahasa",
  Deposit:"Menyetorkan",
  Hire_One_Now:"Pekerjakan Satu Sekarang",
  App_Locked:"Aplikasi terkunci.",
  Skip_:"Melewati",
  Skip_to_Dashboard:"Lewati ke Dasbor",
  Dashboard:"Dasbor",
  Import:"Impor",
  Admin:"Administrator",
  New_Admin:"Admin Baru",
  Settings:"Pengaturan",
  Color_Picker:"Pemilih warna",
  Font_Picker:"Pemilih Font",
  Logout:"Keluar",
  Close:"Menutup",
  Close_menu:"Menutup",
  Excel:"Berkas Excel",
  Csv:"Berkas CSV",
  Sql:"File SQL",
  Json:"Berkas JSON",
  Url:"Impor dengan URL",
  Back:"Kembali",
  Timers:"Pengatur Waktu",
  Cheque:"Memeriksa",
  Print:"Mencetak",
  Designs:"Desain",
  Pause_Printing:"Jeda Pencetakan",
  Resume_Printing:"Lanjutkan Pencetakan",
  Printing_Paused:"Pencetakan Dijeda",
  PrintingUnavailable:"Maaf! Pencetakan tidak tersedia di sistem ini.",
  Prints_Paused:"Cetakan Dijeda",
  Administration:"Administrasi",
  Loading:"Memuat",
  Unnamed:"Tidak disebutkan namanya",
  error:"Maaf, cek ini tidak dapat dibuka untuk dilihat.",
  No_Cheques_To_Print:"Tidak Ada Cek Untuk Dicetak",
  No_Cheques_To_Print_Word:"Buat <a routerLink='/folder/Cheque/New'>Cek Baru</a>.",
  New_Cheque:"Cek Baru",
  Start_One_Now:"Mulai Satu Sekarang",
  Edit_Cheque:"Edit Cek",
  Select_Cheques:"Pilih Cek",
  Select_Employee:"Pilih Karyawan",
  Default_Printer:"Printer Standar",
  Reassign:"Menugaskan kembali",
  Configure:"Konfigurasi",
  Template:"Templat",
  Designer:"Perancang",
  Edit_Designs:"Mengedit Desain",
  New_Design:"Desain baru",
  Next:"Berikutnya",
  Save:"Menyimpan",
  Name:"Nama",
  Mail:"Surat",
  Amount:"Jumlah",
  Date:"Tanggal",
  PayPal:"PayPal",
  Payouts:"Pembayaran",
  PayPal_Label:"Label PayPal",
  Email_Username:"Email / Nama Pengguna",
  Client_ID:"Klien ID",
  Sandbox_Email:"Email Kotak Pasir",
  PayPal_Email:"Email PayPal",
  PayPal_Username:"Nama Pengguna API",
  PayPal_Payouts:"Pembayaran PayPal",
  Select_PayPal_Key:"Pilih Kunci PayPal",
  Secret:"Rahasia",
  API_Secret:"Rahasia API",
  PayPal_API_Keys:"Kunci PayPal",
  New_PayPal_Key:"Kunci PayPal Baru",
  Email_Subject:"Subjek Email",
  Email_Message:"Pesan Email",
  Payout_Options:"Opsi Pembayaran",
  Payment_Note:"Catatan Pembayaran",
  Enable_Employee:"Aktifkan Karyawan",
  Enable_Production_Mode:"Aktifkan Mode Produksi",
  Sandbox_Username:"Nama Pengguna Kotak Pasir",
  Sandbox_Signature:"Tanda Tangan Kotak Pasir",
  Sandbox_Password:"Kata Sandi Kotak Pasir",
  Production_Username:"Nama Pengguna Produksi",
  Production_Signature:"Tanda Tangan Produksi",
  Production_Password:"Kata Sandi Produksi",
  Production_Email:"Email Produksi",
  API_Client_ID:"ID Klien API",
  API_Signature:"Tanda Tangan API",
  API_Password:"Kata Sandi API",
  API_Username:"Nama Pengguna API",
  Secret_Key:"Kunci rahasia",
  Sandbox:"Bak pasir",
  Production:"Produksi",
  Sandbox_Keys:"Kunci Kotak Pasir",
  Production_Keys:"Kunci Produksi",
  API_Title:"Judul API",
  Production_Mode:"Mode Produksi",
  Account_Label:"Label Akun",
  No_PayPal_Setup:"Tidak Ada Kunci PayPal",
  Enable_PayPal_Account:"Aktifkan Akun PayPal",
  No_PayPal_Word:"Tambahkan <a routerLink='/folder/Invoice/New'>Kunci API PayPal</a> Anda.",
  Printed_Memo:"Memo Tercetak",
  Employee:"Karyawan",
  View_Employee:"Lihat Karyawan",
  Mailing_Address:"Alamat surat",
  Company_Address:"Alamat perusahaan",
  Select_Company_Address:"Pilih Alamat Perusahaan",
  Address:"Alamat",
  Any_Day:"Setiap Hari",
  Address_Name:"Nama alamat",
  Unit:"Satuan",
  Account:"Akun",
  Bank_Account:"Akun bank",
  Account_Limits:"Aktifkan Batas Akun",
  Payroll:"Daftar gaji",
  New_Payroll:"Gaji Baru",
  No_Payroll:"Tidak Ada Gaji Mendatang",
  Upcoming_Holiday:"Liburan Mendatang:",
  Invoice_Due:"Faktur Jatuh Tempo:",
  New_Invoice:"Faktur Baru",
  No_Invoices:"Tidak Ada Faktur",
  No_Invoices_Word:"Buat <a routerLink='/folder/Invoice/New'>Faktur</a> pertama.",
  Cheque_Due:"Cek Jatuh Tempo:",
  Payrolls:"Gaji",
  Sandbox_Mode:"Mode Sandbox",
  Hire:"Mempekerjakan",
  Pay:"Membayar",
  New:"Baru",
  Add:"Menambahkan",
  Make:"Membuat",
  Time:"Waktu",
  Write:"Menulis",
  Holiday:"Hari libur",
  Holidays:"Liburan",
  Next_Holiday:"Liburan selanjutnya:",
  All_Done:"Semua selesai!",
  Employees:"Karyawan",
  Payees:"Pembayar",
  Job:"Pekerjaan",
  Jobs:"Pekerjaan",
  Invoice:"Faktur",
  Invoices:"Faktur",
  Vacations:"Liburan",
  Cheques:"Cek",
  Brand_Cheques:"Merek",
  Payment:"Pembayaran",
  Enable_Payment:"Aktifkan Pembayaran",
  Payments:"Pembayaran",
  Schedule:"Jadwal",
  Schedules:"Jadwal",
  Timesheet:"Lembar Waktu",
  Timesheets:"Lembar Waktu",
  Salary:"Gaji",
  New_Address:"Alamat baru",
  Address_2:"Alamat Baris 2)",
  _City:"Kota",
  _State:"Negara Bagian/Provinsi",
  City:"Kota/Kabupaten",
  State:"Negara Bagian / Provinsi",
  Postal:"Kode Pos / ZIP",
  ZIP:"Pos / ZIP",
  Country:"Negara",
  Addresses:"Alamat",
  Required_Options:"Opsi yang Diperlukan",
  Optional_Options:"Opsi Opsional",
  Additional_Options:"Opsi Tambahan",
  Required:"Diperlukan",
  Optional:"Pilihan",
  Additional:"Tambahan",
  No_Addresses:"Tidak Ada Alamat",
  New_Address_Word:"Tambahkan <a routerLink='/folder/Address/New'>Alamat</a> pertama.",
  Select_Address:"Pilih Alamat",
  No_Address:"Tidak Ada Alamat",
  Print_Cheque:"Cetak Cek",
  Print_Cheque_Now:"Cetak Cek Sekarang",
  Description:"Keterangan",
  Pay_To_The_Order_Of:"Bayar Sesuai Pesanan:",
  Select_Date_Range:"Pilih Rentang Tanggal",
  Select_Starting_Date:"Pilih Tanggal Mulai",
  Select_Ending_Date:"Pilih Tanggal Berakhir",
  Select_Date:"Pilih Tanggal",
  Cheque_Date:"Tanggal Cek",
  Cheque_Memo:"Cek Memo",
  Blank_Cheque:"Cek kosong",
  Blank:"Kosong",
  No_Cheques:"Tidak Ada Cek",
  No_Cheques_Word:"Cetak <a routerLink='/folder/Cheque/New'>Cek</a> pertama Anda.",
  Cheque_Amount_Placeholder:"$0,00",
  View_Image:"Lihat gambar",
  View:"Melihat",
  Modify:"Memodifikasi",
  Delete:"Menghapus",
  Cheque_Paid:"Dibayar",
  New_Deduction:"Pengurangan Baru",
  Title:"Judul",
  Frequency:"Frekuensi",
  Hourly:"Per jam",
  Daily:"Sehari-hari",
  Weekdays:"Hari kerja",
  BiWeekly:"2 minggu",
  TriWeekly:"3 minggu",
  Monthly:"Bulanan",
  MiMonthly:"2 bulan",
  Quarterly:"Triwulanan",
  Yearly:"Tahunan",
  Every_Week:"Setiap minggu",
  Every_Payroll:"Setiap Gaji",
  Every_Month:"Setiap bulan",
  Annually:"Setiap tahun",
  Always_Scheduled:"Selalu Terjadwal",
  Start_Date:"Mulai tanggal",
  End_Date:"Tanggal Akhir",
  Start_Time:"Waktu mulai",
  End_Time:"Akhir waktu",
  Deduction_Label:"Label Pengurangan",
  Notes:"Catatan",
  Options:"Pilihan",
  Enable:"Memungkinkan",
  Select_Deductions:"Pilih Pengurangan",
  Deductions:"Pengurangan",
  No_Deductions:"Tanpa Potongan",
  No_Deductions_Word:"Buat <a routerLink='/folder/Deduction/New'>Deduksi</a> pertama Anda.",
  New_Employee:"Karyawan baru",
  No_Title:"Tanpa judul",
  _Name:"Nama",
  About_Yourself:"Tentang dirimu",
  Full_Name:"Nama lengkap",
  Birthday:"Hari ulang tahun",
  Email:"Surel",
  SMS:"SMS",
  Phone:"Telepon",
  Test:"Tes",
  Call:"Panggilan",
  Fax:"Fax",
  Printed_Note:"Catatan Tercetak",
  Position:"Posisi",
  Job_Position:"Posisi pekerjaan",
  Select_a_Job:"Pilih Pekerjaan",
  Select_a_Salary:"Pilih Gaji",
  Add_a_Deduction:"Tambahkan Pengurangan",
  Taxes:"Pajak",
  Add_Taxes:"Tambah Pajak",
  Date_of_Birth:"Tanggal lahir",
  Email_Address:"Alamat email",
  Phone_Number:"Nomor telepon",
  Phone_Call:"Panggilan telepon",
  Enable_on_Payroll:"Aktifkan di Penggajian",
  Select_Employees:"Pilih Karyawan",
  No_Employees:"Tidak Ada Karyawan",
  No_Employees_Word:"Tambahkan <a routerLink='/folder/Employee/New'>Karyawan</a> baru pertama Anda.",
  No_Name:"Tanpa nama",
  Unemployeed:"Penganggur",
  Employeed:"Dipekerjakan",
  Paid:"Dibayar",
  Enabled:"Diaktifkan",
  Disabled:"Dengan disabilitas",
  Fire:"Api",
  Not_Available:"Tidak tersedia",
  Not_Available_Word:"Cetak <a routerLink='/folder/Invoice/New'>Faktur</a> pertama Anda & dapatkan bayaran.",
  Select_Invoices:"Pilih_Faktur",
  Print_Invoice_Word:"Cetak <a routerLink='/folder/Invoice/New'>Faktur</a> pertama Anda & dapatkan bayaran.",
  Invoice_Title:"Judul Faktur",
  Invoice_Date:"Tanggal faktur",
  Due_Date:"Tenggat waktu",
  New_Job:"Pekerjaan Baru",
  Details:"Rincian",
  Customize:"Sesuaikan",
  Customize_Dashboard:"Sesuaikan Dasbor",
  Components:"Komponen",
  No_Components:"Tanpa Komponen",
  Main_Components:"Komponen utama",
  Smaller_Components:"Komponen Lebih Kecil",
  Error_Loading_Page:"Kesalahan saat memuat halaman ini.",
  Bank_Details:"Rincian bank",
  About_Your_Job:"Tentang Pekerjaan Anda",
  Your_Schedule:"Jadwal Anda",
  Job_Title:"Judul pekerjaan",
  Job_Description:"Uraian Tugas",
  Job_Details:"Rincian pekerjaan",
  Enable_Job:"Aktifkan Pekerjaan",
  Pay_Period:"Periode pembayaran",
  Perpetually_Schedule:"Selalu Jadwal",
  Select_Jobs:"Pilih Pekerjaan",
  No_Jobs:"Tidak Ada Pekerjaan",
  Add_Jobs:"Tambahkan Pekerjaan",
  No_Jobs_Word:"Tambahkan <a routerLink='/folder/Job/New'>Pekerjaan</a> pertama ke daftar.",
  Count_Employees:"job.employee_count+' Employee'",
  Zero_Employees:"0 Karyawan",
  New_Leave:"Tinggal Baru",
  Leave_Name:"Tinggalkan Nama",
  Paid_Leave:"Cuti Berbayar",
  Leave_Pay:"Tinggalkan Bayar",
  Indefinite_Leave:"Cuti Tanpa Batas",
  Indefinite_Payroll:"Gaji Tidak Terbatas",
  Leave:"Meninggalkan",
  Add_Schedules:"Tambah Jadwal",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Aktifkan Absen",
  Select_Leaves:"Pilih Daun",
  No_Leaves:"Tanpa Daun Absen",
  Leave_Of_Absence:"Cuti",
  Leaves_Of_Absence:"Daun Absen",
  New_Leave_of_Absense:"Istirahat Baru",
  No_Leaves_Word:"Tambahkan <a routerLink='/folder/Leave/New'>Cuti</a> pertama.",
  Not_Enabled:"Tidak diaktifkan",
  Absences:"Absen",
  Payee:"Pembayar",
  New_Payee:"Penerima Pembayaran Baru",
  Payee_Identifier:"Pengidentifikasi Penerima Pembayaran",
  Payee_Name:"Nama Penerima Pembayaran",
  Payee_Title:"Judul Penerima Pembayaran",
  Payment_Memo:"Memo Pembayaran",
  Select_Payees:"Pilih Penerima Pembayaran",
  No_Payees:"Tidak Ada Penerima Pembayaran",
  Add_Payee_Note:"Tambahkan <a routerLink='/folder/Payee/New'>Penerima Pembayaran</a> pertama.",
  New_Payees:"Penerima Pembayaran Baru",
  About_The_Payment_Schedule:"Jadwal pembayaran",
  Your_Payroll_Schedule:"Penggajian Otomatis",
  New_Payment:"Pembayaran Baru",
  Identifier:"Pengidentifikasi",
  Select:"Pilih",
  Memo:"Memo",
  Payment_Date:"Tanggal pembayaran",
  Mark_as_Paid:"Tandai sebagai Dibayar",
  Select_Payments:"Pilih Pembayaran",
  No_Payments:"Tidak Ada Pembayaran",
  No_Payments_Word:"Buat <a routerLink='/folder/Payment/New'>Pembayaran</a> pertama.",
  Create_Payroll:"Buat Gaji",
  Properties:"Properti",
  Payroll_Title:"Judul Gaji",
  Payroll_Notes:"Catatan Penggajian",
  Payroll_Setup:"Pengaturan Penggajian",
  Tabulate_Payments:"Tabulasi Pembayaran",
  Tabulate:"Mentabulasi",
  By:"Oleh:",
  Payments_By:"Pembayaran Dengan",
  Timesheets_And_Schedules:"Lembar Waktu & Jadwal",
  Both:"Keduanya",
  Items:"Item",
  Add_Payees:"Tambah Penerima Pembayaran",
  Add_Account:"Menambahkan akun",
  Enable_Account:"Aktifkan Akun",
  Enable_Payroll:"Aktifkan Penggajian",
  Print_Payroll:"Cetak Gaji",
  No_Payrolls:"Tidak Ada Gaji",
  No_Payroll_Word:"Buat <a routerLink='/folder/Payroll/New'>Daftar Gaji</a> pertama Anda.",
  View_more:"LIHAT_LEBIH",
  Less:"LEBIH SEDIKIT",
  Add_Payroll:"Tambah Gaji",
  Select_Payroll:"Pilih Penggajian",
  About_Your_Salary:"Tentang Gaji Anda",
  Add_Salaries:"Tambah Gaji",
  Add_Salary:"Tambah Gaji",
  Salaries:"Gaji",
  No_Salaries:"Tidak Ada Gaji",
  No_Salaries_Word:"Tambahkan <a routerLink='/folder/Salary/New'>Gaji</a> pertama.",
  Select_Salaries:"Pilih Gaji",
  New_Salary:"Gaji Baru",
  Salary_Name:"Pengidentifikasi Gaji",
  Enable_Salary:"Aktifkan Gaji",
  Salary_Amount:"Jumlah Gaji",
  New_Schedule:"Jadwal baru",
  Schedule_Title:"Judul Jadwal",
  Add_Address:"Tambahkan alamat",
  Repeat:"Ulang",
  Design:"Desain",
  Edit_Design:"Mengedit Desain",
  Edit_this_Design:"Edit Desain ini",
  Repeat_Payment:"Pembayaran Ulang",
  Enable_Schedule:"Aktifkan Jadwal",
  Never:"Tidak pernah",
  Select_Schedule:"Pilih Jadwal",
  No_Schedules:"Tidak Ada Jadwal",
  No_Schedules_Word:"Buat <a routerLink='/folder/Schedule/New'>Jadwal</a> pertama.",
  New_Administrator:"Pengurus Baru",
  Username:"Nama pengguna",
  First_Name:"Nama depan",
  Last_Name:"Nama keluarga",
  Add_an_Address:"Tambahkan Alamat",
  Payment_Limit:"Batas Per-Pembayaran",
  Total_Limit:"Batas Total",
  Select_Accounts:"Pilih Akun",
  No_Administrators:"Tidak Ada Administrator",
  No_Administrators_Word:"Buat <a routerLink='/folder/Administrator/New'>Akun Admin</a> pertama.",
  New_Administrators_Word:"Tambahkan <a routerLink='/folder/Administrator/New'>Administrator</a> pertama",
  Cloud:"Awan",
  Backup:"Cadangan",
  Enable_iCloud:"Aktifkan iCloud",
  Enable_Google_Drive:"Aktifkan Google Drive",
  Enable_Microsoft_OneDrive:"Aktifkan Microsoft OneDrive",
  Automatically_Backup:"Cadangkan Secara Otomatis",
  FTP_Settings:"Pengaturan FTP",
  URL_File_Prompt:"Silakan tentukan lokasi untuk file .xls / .xlsx / .json yang akan diimpor:",
  URL_SQL_Prompt:"Silakan tentukan lokasi file SQLite yang akan diimpor:",
  FTP_Backup:"Pencadangan FTP",
  FTP_Import:"FTP Impor",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Tuan rumah",
  Port:"Pelabuhan",
  Path:"Jalur",
  Data_Path:"Jalur data",
  Enable_FTP_Account:"Aktifkan Akun FTP",
  HostnameIP:"Nama host",
  Password:"Kata sandi",
  Connection_Port:"Pelabuhan Koneksi",
  Enable_MySQL_Database:"Aktifkan Basis Data MySQL",
  Log:"Catatan",
  Reset:"Mengatur ulang",
  Erase:"Menghapus",
  Export:"Ekspor",
  Database:"Basis Data",
  Upload_CSV:"Unggah CSV",
  Download_CSV:"Unduh CSV",
  SQL_Database:"URL SQL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Cadangan MySQL",
  Internal_Notes:"Catatan Dalam",
  Root_Path:"Jalur Akar",
  Select_Backup:"Pilih Cadangan",
  Add_New_Backup:"Tambahkan Cadangan Baru",
  First_Backup:"Siapkan cadangan pertama...",
  Backups:"Cadangan",
  Add_Backup:"Tambahkan Cadangan",
  No_Backups:"Tidak ada cadangan yang dapat ditemukan.",
  Select_Backup_Type:"Pilih jenis cadangan yang ingin Anda atur...",
  Backup_Type:"Jenis Cadangan",
  FTP_Drive:"Penggerak FTP",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Menyetir",
  Microsoft_OneDrive:"Menyetir",
  Import_Fields:"Impor Bidang",
  Import_Fields_Word:"Gunakan bagian ini untuk memilih data <a routerLink='/folder/Holiday/New'>Impor</a>.",
  Upload:"Mengunggah",
  Download:"Unduh",
  Download_JSON:"Unduh sebagai Data JSON",
  Download_SQL:"Unduh sebagai File SQL",
  New_Bank:"Bank Baru",
  New_Account:"Akun baru",
  New_Bank_Account:"Rekening Bank Baru",
  Upload_Image:"Unggah Gambar",
  Bank_Name:"Nama Bank",
  Bank_Address:"Alamat bank",
  Branch_Address:"Alamat cabang",
  Address_on_Cheque:"Alamat",
  Cheque_Numbers:"Cek Nomor",
  Cheque_Details:"Periksa Detail",
  Bank_Logo:"Logo Bank",
  Cheque_ID:"Cek KTP",
  Starting_Cheque_ID:"Memulai Periksa ID",
  Transit_Number:"Nomor Transit",
  Institution_Number:"Nomor Institusi",
  Designation_Number:"Nomor Penunjukan",
  Account_Number:"Nomor akun",
  Limits:"Batas",
  Default_Limits:"Batas Bawaan",
  Over_Limit:"Melewati batas",
  Under_Limit:"Di Bawah Batas",
  Payroll_Limit:"Batas Gaji",
  Enable_Bank_Account:"Aktifkan Rekening Bank",
  Select_Account:"Pilih Akun",
  No_Bank_Account:"Tidak Ada Rekening Bank",
  No_Bank_Account_Word:"Tambahkan <a routerLink='/folder/Accounts/New'>Rekening Bank</a> pertama.",
  Bank_Accounts:"Akun bank",
  No_Accounts:"Tidak Ada Akun",
  No_Accounts_Note:"Tambahkan <a routerLink='/folder/Accounts/New'>Rekening Bank</a> pertama.",
  Cheque_Designer:"Periksa Desainer",
  Cheque_Design:"Cek Desain",
  Select_Design:"Pilih Desain",
  Cheque_Designs:"Cek Desain",
  No_Cheque_Designs:"Tidak Ada Desain Cek",
  No_Cheque_Designs_Word:"Buat <a routerLink='/folder/Settings/Cheque/Design/New'>Periksa Desain</a> Anda sendiri.",
  Set_Default:"Ditetapkan sebagai default",
  Default:"Bawaan",
  Remove:"Menghapus",
  Folder:"Map",
  Edit:"Mengedit",
  LoadingDots:"Memuat...",
  Add_a_New_File:"Tambahkan <a href='#' (klik)='add()'>File Baru</a> ke",
  this_folder:"folder ini",
  FTP_Backup_Settings:"Pengaturan Cadangan FTP",
  Secure_File_Transfer:"Transfer File Aman",
  New_Holiday:"Liburan Baru",
  Add_Holiday:"Tambah Hari Libur",
  Holiday_Name:"Nama Liburan",
  Additional_Pay:"Pembayaran Tambahan",
  Enable_Holiday:"Aktifkan Liburan",
  Select_Holidays:"Pilih Hari Libur",
  No_Holidays:"Tidak Ada Hari Libur",
  No_Holidays_Word:"Tambahkan <a routerLink='/folder/Holiday/New'>Libur Nasional</a> pertama.",
  New_Import:"Impor Baru",
  Import_Data:"Impor Data",
  Import_Data_Word:"Pilih jenis file atau metode unggah yang Anda pilih.<br /> Anda akan dapat memilih bidang yang diimpor mana pun dalam file yang sesuai dengan parameter mana pun di aplikasi setelah mengunggah file yang didukung.", 
  Import_File:"Impor Berkas",
  Link_To_File:"Tautan ke Berkas",
  Select_File:"Pilih file",
  New_Moderator:"Moderator Baru",
  Allow_Moderation:"Izinkan Moderasi",
  Create_Paycheques:"Buat Gaji",
  Edit_Paycheques_and_Data:"Edit Gaji dan Data",
  Destroy_Data_and_Paycheques:"Hancurkan Data dan Gaji",
  Bonus_Percentage:"Persentase Gaji",
  Fixed_Amount:"Jumlah Tetap",
  Select_Moderator:"Pilih Moderator",
  No_Moderators:"Tidak Ada Moderator",
  Moderators:"Moderator",
  Moderator_Account:"Buat <a routerLink='/folder/Administrator/New'>Akun Moderator</a> pertama.",
  No_Moderators_Word:"Tambahkan <a routerLink='/folder/Administrator/New'>Moderator</a> pertama.",
  Defaults:"Default",
  Provide_Us_Info:"Berikan Kami Info",
  Setup_Your_Printer:"Siapkan Pencetak Anda",
  Your_Company:"Tentang Perusahaan Anda",
  Company_Name:"Nama perusahaan",
  Currency:"Mata uang",
  Default_Currency:"Mata Uang Standar",
  Base_Monthly_Income:"Pendapatan bulanan",
  Protection:"Perlindungan",
  App_Protection:"Perlindungan Aplikasi",
  PIN_Code_Protection:"Perlindungan Kode PIN",
  App_Protection_Word:"Aktifkan metode keamanan yang membantu melindungi akun Anda.",
  PIN_Code:"Kode PIN",
  Change_PIN:"Ganti PIN",
  New_Password:"Kata sandi baru",
  Geofence_Protection:"Perlindungan Geo-Fence",
  Geofence_Area:"Setel Luas",
  Distance:"Jarak",
  Setup_Now:"Setel Sekarang",
  Mile:"Mil",
  Km:"km",
  m:"M",
  Facial_Recognition:"Pengenalan wajah",
  Face:"Wajah",
  Setup:"Mempersiapkan",
  Label:"Label",
  Password_Protection:"Perlindungan Kata Sandi",
  Modify_Password:"Ubah Kata Sandi",
  New_Tax_Entry:"Entri Pajak Baru",
  New_Tax:"Pajak Baru",
  Tax_Label:"Label Pajak",
  Perpetually_Enabled:"Selalu Diaktifkan",
  Select_Taxes:"Pilih Pajak",
  Tax_Deductions:"Pengurangan Pajak",
  No_Tax_Deductions:"Tidak Ada Potongan Pajak",
  No_Tax_Deductions_Word:"Tambahkan potongan <a routerLink='/folder/Tax/New'>Pajak</a> pertama.",
  New_Timer:"Pengatur Waktu Baru",
  Start:"Awal",
  Stop:"Berhenti",
  Start_Timer:"Mulai Timer",
  Stop_Timer:"Hentikan Pengatur Waktu",
  Timer_Active:"Pengatur Waktu Aktif",
  Timer:"Pewaktu:",
  Timer_Running:"Pengatur Waktu: (Berjalan)",
  Save_Timer:"Simpan Pengatur Waktu",
  New_Timesheet:"Lembar Waktu Baru",
  Select_Timesheets:"Pilih Lembar Waktu",
  Work_Notes:"Catatan Kerja",
  Entry_Title:"Judul Masuk",
  No_Timesheets:"Tidak Ada Lembar Waktu",
  No_Timesheets_Word:"Tambahkan <a routerLink='/folder/Timesheet/New'>Lembar Waktu</a> pertama.",
  Timesheet_Submitted:"Lembar Waktu Dikirim",
  Timesheet_Congrats:"Selamat! Lembar waktu Anda telah berhasil dikirimkan. Terima kasih!",
  Timesheet_Copy:"Untuk menerima salinan dokumen Anda, harap berikan kami alamat email dan/atau nomor ponsel Anda.",
  Submit:"Kirim",
  Allow_Notifications:"Izinkan Pemberitahuan",
  Untitled_Entry:"Masukan baru",
  Untitled_Bank:"Bank Tanpa Nama",
  Timesheet_Entry:"Entri Lembar Waktu",
  Work_Description:"Deskripsi Pekerjaan",
  Enable_Timesheet:"Aktifkan Lembar Waktu",
  Submit_Timesheet:"Kirim Lembar Waktu",
  Vacation:"Liburan",
  New_Vacation:"Liburan Baru",
  Vacation_Name:"Nama Liburan",
  Paid_Vacation:"Liburan Berbayar",
  Vacation_Pay:"Pembayaran Liburan",
  Enable_Vacation:"Aktifkan Liburan",
  Select_Vacations:"Pilih Liburan",
  No_Vacations:"Tidak Ada Liburan",
  No_Vacations_Word:"Buat entri <a routerLink='/folder/Vacation/New'>Liburan</a> pertama.",
  Payroll_Schedule:"Jadwal Penggajian",
  Next_Payroll:"Penggajian Berikutnya:",
  Upcoming_Payroll:"Penggajian Mendatang",
  No_Upcoming_Payroll:"Tidak Ada Gaji Mendatang",
  Address_Book:"Buku alamat",
  Archived_Documents:"Dokumen yang Diarsipkan",
  Dev_Mode:"Aplikasi Dalam Mode Pengembangan",
  Administrators:"Administrator",
  Privacy:"Pribadi",
  None:"Tidak ada",
  Select_Signature:"Pilih Tanda Tangan",
  No_Signatures:"Tanpa Tanda Tangan",
  No_Signatures_Word:"Tambahkan <a routerLink='/folder/Signature/New'>Tanda tangan</a> pertama.",
  Repeat_Indefinitely:"Ulangi tanpa batas",
  Sign:"Tanda",
  Sign_Here:"Tanda Tangan Disini",
  Date_Signed:"Tanggal Ditandatangani",
  Signature_Pad:"Papan Tanda Tangan",
  Account_Holder:"Pemilik akun",
  Account_Properties:"Properti Akun",
  Name_On_Cheque:"Nama di Cek",
  Server_Hostname:"Nama Host Server / IP",
  Printers:"Printer",
  No_Printers:"Tidak Ada Printer",
  Printer_Exists:'Printer dengan nama ini sudah ada.',
  No_Printers_Word:"Tambahkan <a routerLink='/folder/Printer/New'>Pencetak</a> pertama.",
  No_Printer_Alert:"Tidak ada printer yang ditentukan. Apakah Anda ingin menyiapkan printer?",
  Add_Printer:"Tambah Pencetak",
  New_Printer:"Printer Baru",
  Printer_Hostname:"Nama Inang / IP Pencetak",
  Printer_Label:"Label Pencetak",
  Printer_Protocol:"Protokol Pencetak",
  Protocol:"Protokol",
  Email_Print:"Surel",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Stopkontak",
  Print_Job:"Tugas Cetak",
  Printed:"Dicetak",
  Not_Printed:"Tidak Dicetak",
  Print_Jobs:"Pekerjaan Cetak",
  Print_Queue:"Antrian print",
  No_Print_Jobs:"Tidak Ada Pekerjaan Cetak",
  No_Prints:"Buat <a routerLink='/folder/Cheque/New'>Cheque</a> baru untuk dicetak.",
  Prints:"Cetakan",
  Find_Printer:"Temukan Pencetak",
  Find_AirPrint_Devices:"Temukan Perangkat AirPrint",
  Select_Printer:"Pilih Pencetak",
  System_UI:"Sistem UI",
  Printer:"Pencetak",
  Status:"Status",
  Preview:"Pratinjau",
  Enable_Print_Job:"Aktifkan Pekerjaan Cetak",
  Queue_Weight:"Berat Antrian",
  Unlimited:"Tak terbatas",
  Show_Advanced_Printer_Options:"Tampilkan Opsi Printer Tingkat Lanjut",
  Advanced:"Canggih",
  Location:"Lokasi",
  Note:"Catatan",
  Queue_Name:"Nama Antrian",
  Pages_Printed_Limit:"Batas Cetak Halaman",
  MultiPage_Idle_Time:"Waktu Tunggu Banyak Halaman",
  Page_Count_Limit:"Batas Jumlah Halaman",
  Page_Orientation:"Orientasi Halaman",
  Portrait:"Potret",
  Landscape:"Lanskap",
  Duplex:"Rangkap",
  Double_Sided_Printing:"Dua sisi",
  Duplex_Mode:"Mode Dupleks",
  Duplex_Short:"Pendek",
  Duplex_Long:"Panjang",
  Duplex_None:"Tidak ada",
  Color_And_Quality:"Warna Dan Kualitas",
  Monochrome:"Satu warna",
  Photo_Quality_Prints:"Cetakan Kualitas Foto",
  Printer_Email:"Email Pencetak",
  Automatically_Downsize:"Pengurangan Secara Otomatis",
  Paper:"Kertas",
  Paper_Name:"Nama Kertas",
  Paper_Width:"Lebar Kertas",
  Paper_Height:"Tinggi Kertas",
  Paper_Autocut_Length:"Panjang Pemotongan Otomatis Kertas",
  Margins:"Margin",
  Page_Margins:"Margin Halaman",
  Page_Margin_Top:"Margin Halaman Atas",
  Page_Margin_Right:"Margin Halaman Kanan",
  Page_Margin_Bottom:"Margin Halaman Bawah",
  Page_Margin_Left:"Margin Halaman Kiri",
  Add_Employees:"Tambah Karyawan",
  Header:"Judul",
  Print_A_Page_Header:"Cetak Header Halaman",
  Header_Label:"Label Tajuk",
  Header_Page_Index:"Indeks Halaman Header",
  Header_Font_Name:"Font Tajuk",
  Select_Font:"Pilih Huruf",
  Font_Selector:"Pemilih Huruf",
  Header_Font_Size:"Font Tajuk",
  Header_Bold:"Kepala Tebal",
  Header_Italic:"Tajuk Miring",
  Header_Alignment:"Penjajaran Tajuk",
  Left:"Kiri",
  Center:"Tengah",
  Right:"Benar",
  Justified:"Dibenarkan",
  Header_Font_Color:"Warna Tajuk",
  Select_Color:"Pilih Warna",
  Footer:"Pejalan kaki",
  Print_A_Page_Footer:"Cetak Footer Halaman",
  Footer_Label:"Label Kaki",
  Footer_Page_Index:"Indeks Halaman Kaki",
  Footer_Font_Name:"Font Kaki",
  Fonts:"Font",
  Done:"Selesai",
  Select_Fonts:"Pilih Font",
  Footer_Font_Size:"Ukuran Kaki",
  Footer_Bold:"Kaki Tebal",
  Footer_Italic:"Kaki Miring",
  Footer_Alignment:"Penyejajaran Kaki",
  Footer_Font_Color:"Warna Kaki",
  Page_Copies:"Salinan Halaman",
  Enable_Printer:"Aktifkan Pencetak",
  Remote_Logging:"Pencatatan Jarak Jauh",
  Log_Server:"Log Server",
  Encryption:"Enkripsi",
  Random_Key:"Kunci Acak",
  Encryption_Key:"Kunci enkripsi",
  Cheques_Webserver:"Basis Data Kustom",
  Learn_How:"Belajar bagaimana",
  Signature:"Tanda tangan",
  Default_Printer_Unit:"dalam/cm/mm/(pt)",
  View_Signature:"Lihat Tanda Tangan",
  Printed_Signature:"Tanda Tangan Tercetak",
  Digitally_Sign:"Tanda Tangan Digital",
  Digital_Signature:"Tanda tangan digital",
  Add_Signature:"Tambahkan Tanda Tangan",
  Add_Your_Signature:"Tambahkan Tanda Tangan Anda",
  Enable_Signature:"Aktifkan Tanda Tangan",
  Digitally_Signed:"Ditandatangani Secara Digital",
  Insert_Error:"Tidak dapat menyimpan cek karena masalah basis data.",
  Delete_Confirmation:"Apakah Anda yakin ingin menghapus informasi ini?",
  Discard_Confirmation:"Apakah Anda yakin ingin membuang informasi ini?",
  Discard_Bank_Confirmation:"Apakah Anda yakin ingin membuang akun ini?",
  Discard_Printer_Confirmation:"Apakah Anda yakin ingin membuang printer ini?",
  Delete_Bonus_Confirmation:"Apakah Anda yakin ingin menghapus bonus ini?",
  Delete_Invoice_Confirmation:"Apakah Anda yakin ingin menghapus faktur ini?",
  Generated_Cheque:"Cek yang Dihasilkan",
  Generated_Invoice:"Faktur yang Dihasilkan",
  Schedule_Start_Time:"Jadwal Mulai",
  Schedule_End_Time:"Jadwal Selesai",
  New_Call:"Panggilan baru",
  No_Contacts:"Tidak Ada Kontak",
  No_Contacts_Word:"Administrator, moderator, karyawan, dan penerima pembayaran muncul sebagai kontak.",
  PDF_Subject:"keuangan",
  PDF_Keywords:"cek gaji cek PDF cek gaji",
  Printer_Setup:"Pengaturan printer",
  Printer_Selection:"Pilihan Pencetak",
  New_Fax:"Faks Baru",
  New_Fax_Message:"Pesan Faks Baru",
  Fax_Machine:"Mesin fax",
  Fax_Name:"Nama Faks",
  Fax_Email:"Email Faks",
  Fax_Number:"Nomor fax",
  Contents:"Isi",
  Fax_Body:"Tubuh Halaman",
  Header_Word:"Kepala:",
  Header_Text:"Teks Tajuk",
  Include_Header:"Sertakan Tajuk",
  Include_Footer:"Sertakan Footer",
  Footer_Word:"kaki:",
  Footer_Text:"Catatan kaki",
  Attach_a_Cheque:"Lampirkan Cek",
  Add_Deduction:"Tambah Pengurangan",
  Enable_Fax:"Kirim Faks",
  Select_Fax:"Pilih Faks",
  No_Faxes:"Tidak Ada Faks",
  Faxes:"Faks",
  Save_and_Send:"Kirim Faks",
  Save_and_Pay:"Simpan dan Bayar",
  WARNING:"PERINGATAN:",
  Remember:"Ingat",
  Printing:"Pencetakan",
  Printing_Complete:"Pencetakan Selesai!\n\n",
  of:"dari",
  There_Were:"Ada ",
  Successful_Prints:" cetakan berhasil dan ",
  Unsuccessful_Prints:" cetakan tidak berhasil.",
  PrinterError:"Maaf! Ada kesalahan.",
  Printing_:"Pencetakan...",
  PrinterSuccess:"Dokumen berhasil dicetak.",
  PrintersSuccess:"Dokumen berhasil dicetak.",
  Print_Cheques:"Cetak Cek",
  New_Message:"Pesan baru",
  New_Messages:"Pesan baru",
  Read_Message:"Baca Pesan",
  Write_Message:"Menulis pesan",
  Send_Message:"Mengirim pesan",
  Subject:"Subjek",
  Message:"Pesan",
  Writing:"Menulis...",
  Send:"Mengirim",
  Set_Date:"Atur tanggal",
  Set_Time:"Atur waktu",
  Recieve:"Terima",
  Set_as_Default:"Ditetapkan sebagai default",
  Default_Account:"Akun Standar",
  Default_Design:"Desain Standar",
  Multiple_Cheques:"Cek (Tripple)",
  Account_Mode:"Modus Akun",
  Multiple_Cheques_Description:"Tiga cek per halaman.",
  Check_and_Table:"Cek & Meja",
  Check_including_Table:"Cek & tabel akuntansi.",
  Check_Mailer:"Cek Surat",
  Check_Mailer_Window:"Periksa dengan jendela alamat.",
  DocuGard_Table_Top:"Cek & Tabel DocuGard (Atas)",
  DocuGard_Table_Middle:"Pemeriksaan & Tabel DocuGard (Tengah)",
  DocuGard_Table_Bottom:"Pemeriksaan & Tabel DocuGard (Bawah)",
  DocuGard_Mailer_Top:"DocuGard Periksa Surat (Atas)",
  DocuGard_Mailer_Middle:"DocuGard Periksa Surat (Tengah)",
  DocuGard_Mailer_Bottom:"DocuGard Periksa Surat (Bawah)",
  DocuGard_Three_Cheques:"Cek DocuGard (Tripple)",
  DocuGard_Cheque_Top:"Cek DocuGard (Atas)",
  DocuGard_Cheque_Middle:"Pemeriksaan DocuGard (Tengah)",
  DocuGard_Cheque_Bottom:"Cek DocuGard (Bawah)",
  DocuGard_Three_Cheques_Window:"Tiga cek pada satu halaman.",
  DocuGard_Table_Top_Window:"Cek & tabel penghasilan.",
  DocuGard_Table_Middle_Window:"Cek & tabel penghasilan.",
  DocuGard_Table_Bottom_Window:"Cek & tabel penghasilan.",
  DocuGard_Mailer_Top_Window:"Cek, meja, dan alamat.",
  DocuGard_Mailer_Middle_Window:"Cek, meja, dan alamat.",
  DocuGard_Mailer_Bottom_Window:"Cek, meja, dan alamat.",
  DocuGard_Cheque_Top_Window:"Periksa kertas aman.",
  DocuGard_Cheque_Middle_Window:"Periksa kertas aman.",
  DocuGard_Cheque_Bottom_Window:"Periksa kertas aman.",
  Basic_Cheque:"Cek (Atas)",
  Basic_Cheque_Print:"Cetak satu cek.",
  Error_Setting_As_Paid:"Error Setting as Paid",
  Add_Attachment:"Tambahkan sisipan",
  PrinterUnavailable:"Printer Tidak Tersedia",
  CreditCardComponent:"Kartu-kartu",
  PaypalComponent:"PayPal",
  InteracComponent:"Interak",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Setoran Baru",
  Deposits:"Setoran",
  No_Deposits:"Tidak Ada Deposit",
  Credit_Card_Deposit:"Kartu kredit",
  New_Credit_Card_Deposit_Message:"Setoran Kartu Kredit",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Setoran BitCoin",
  New_Interac_Deposit:"Interak",
  New_Interac_Deposit_Message:"Transfer Interak",
  Payments_Limit:"Batas Pembayaran",
  No_Payment_Limit:"Tidak Ada Batas Pembayaran",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Setoran PayPal",
  No_Deposits_Word:"Tambahkan <a routerLink='/folder/Deposit/New'>Menyetorkan</a> perusahaan pertama.",
  No_Documents_Specified:"Tidak ada dokumen yang ditentukan untuk dicetak",
  No_Printers_Added:"Tidak ada printer yang ditemukan. Buka Pengaturan > Printer untuk menambahkannya.",
  DB_Erase_Prompt:"Hapus seluruh database? PERINGATAN: Anda akan kehilangan semua informasi yang tersimpan!",
  Console_Warning:"Jangan tempel teks apa pun ke konsol ini. Anda dapat membahayakan diri sendiri dan/atau perusahaan Anda.",
  No_Faxes_Word:"Buat <a routerLink='/folder/Fax/New'>Fax</a> pertama.",
  Cheque_Delete_Confirmation:"Apakah Anda yakin ingin menghapus cek ini?",
  Design_Delete_Confirmation:"Apakah Anda yakin ingin menghapus desain ini?",
  Cheque_Pay_Confirmation:"Tandai cek ini sebagai dibayar? TIDAK akan muncul di antrian cetak.",
  Payment_Pay_Confirmation:"Tandai pembayaran ini sebagai dibayar? TIDAK akan muncul di antrian cek.",
  Delete_Deduction_Confirmation:"Apakah Anda yakin ingin menghapus potongan ini?",
  Delete_Job_Confirmation:"Apakah Anda yakin ingin menghapus pekerjaan ini?",
  Delete_Timesheet_Confirmation:"Apakah Anda yakin ingin menghapus kartu absen ini?",
  Delete_Schedule_Confirmation:"Apakah Anda yakin ingin menghapus jadwal ini?",
  Delete_Setting_Confirmation:"Apakah Anda yakin ingin mengatur ulang pengaturan ini?",
  Delete_Fax_Confirmation:"Apakah Anda yakin ingin menghapus faks ini?",
  Delete_File_Confirmation:"Apakah Anda yakin ingin menghapus file ini?",
  Delete_Vacation_Confirmation:"Apakah Anda yakin ingin menghapus liburan ini?",
  Delete_Printer_Confirmation:"Apakah Anda yakin ingin menghapus printer ini?",
  Remove_Design_Confirmation:"Apakah Anda yakin ingin menghapus desain ini?",
  Delete_Payroll_Confirmation:"Apakah Anda yakin ingin menghapus daftar gaji ini?",
  Send_Fax_Email_Confirmation:"Apakah Anda ingin mengirim faks dan email dokumen-dokumen ini?",
  Send_Email_Confirmation:"Apakah Anda ingin mengirim dokumen ini melalui email?",
  Send_Fax_Confirmation:"Apakah Anda ingin mengirim faks dokumen ini?",
  Error_Generating_PDF:"Maaf. Terjadi kesalahan saat membuat dokumen ini.",
  PDF_Error_Saving_Image:"Maaf. Terjadi kesalahan saat menyimpan gambar PDF dari dokumen ini.",
  Test_Printer_Confirmation:"Apakah Anda ingin mencetak halaman percobaan pada printer ini?",
  Save_Timesheet_Prompt:"Silakan tambahkan 'Judul' atau tekan 'Mulai Timer' untuk menyimpan.",
  Remove_Geofence_Prompt:"Apakah Anda yakin ingin menghapus lokasi geo-pagar ini?",
  Delete_Employee_Confirmation:"Apakah Anda yakin ingin menghapus",
  Fire_Employee_Confirmation:"Apakah kamu yakin ingin menembak"
}