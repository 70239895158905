export const Uz = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"mualliflik huquqi & nusxa; 2023 yil",
  black:"Qora",
  green:"Yashil",
  gold:"Oltin",
  blue:"Moviy",
  brown:"Jigarrang",
  purple:"Siyohrang",
  pink:"Pushti",
  red:"Qizil",
  Swatches:"Swatchlar",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Hue",
  Saturation:"To'yinganlik",
  Lightness:"Yengillik",
  Upgrade_To_Pro:"Pro versiyasiga yangilang",
  AllFeaturesInclude:"Barcha xususiyatlar o'z ichiga oladi:",
  PrintUnlimitedCheques:"Cheksiz cheklarni chop eting",
  PremiumChequeDesigns:"Premium tekshirish dizaynlari",
  ManageUnlimitedEmployees:"Cheksiz xodimlarni boshqaring",
  AddUnlimitedPayees:"Cheksiz to'lovchilarni qo'shing",
  CreateUnlimitedPayrolls:"Cheksiz ish haqi jadvallarini yarating",
  UnlimitedSchedulesandTimeSheets:"Cheksiz jadvallar va vaqt jadvallari",
  BulkPayPalPayouts:"Ommaviy PayPal to'lovlari",
  UnlimitedBankAccounts:"Cheksiz bank hisoblari",
  ManageMultipleCompanies:"Bir nechta kompaniyalarni boshqarish",
  TrackInsurancePolicies:"Sug'urta polislarini kuzatib boring",
  Bio_MetricProtection:"Biometrik himoya",
  Geo_FenceLock_OutProtection:"Geo-Fence Lock-Out himoyasi",
  Multiple_Companies_Word:"Chexlar premiumsiz bir nechta kompaniyalarni boshqarish mumkin emas.",
  PayPal_Payouts_Word:"PayPal to'lovlari Cheklar mukofotisiz o'chirib qo'yilgan.",
  PINProtection:"PIN-kod himoyasi",
  PasswordProtection:"Parol himoyasi",
  May_Require_Approval:"Tasdiqlashni talab qilishi mumkin.",
  Subscribe:"Obuna boʻling",
  Billed:"Hisob-kitob qilingan",
  Up:"Yuqoriga",
  Down:"Pastga",
  Positioning:"Joylashtirish",
  Marker:"Marker",
  Drag_Marker:"Markerni torting",
  Tagline:"Cheklarni chop eting va ish haqi jadvalini tuzing",
  Marker_Word:"Elementning o'lchamini belgilash uchun markerlardan foydalaning.",
  Pinch_Zoom:"Masshtabni chimchilash",
  Pinch_Word:"Elementni kattalashtirish uchun chimchilang.",
  Drag:"Surang",
  Drag_Word:"Elementlarni sudrab borish uchun barmog'ingizdan foydalaning.",
  subscription_alias_word:"Obunani avtomatik yangilash",
  premium_alias_word:"Bir martalik yangilash paketi",
  print_alias_word:"Individual cheklarni chop etish",
  mode_alias_word:"Rejim",
  Pro:"Pro",
  Pro_word:"Pro versiyasi talab qilinadi.",
  Cant_Delete_Default_Company_Word:"Kechirasiz, siz standart kompaniyangizni oʻchira olmaysiz.",
  Reinsert_Default_Designs:"Standart dizaynlarni qayta kiriting",
  Reinsert_Default_Designs_word:"Standart dizaynlarni qayta kiritmoqchimisiz?",
  Subscription_Active_Disable_Word:"Bu obuna faol. Cheklarga obunani bekor qilmoqchimisiz?",
  Company_Logo:"Kompaniya logotipi",
  ZERO_:"NO",
  Disclaimer:"Rad etish",
  Privacy_Policy:"Maxfiylik siyosati",
  EULA:"EULA ni tekshiradi",
  Terms_Of_Service:"Xizmat ko'rsatish shartlari",
  Terms_Of_Use:"Foydalanish shartlari",
  Refunds:"To'lovni qaytarish siyosati",
  Single_Print:"1 Tekshirish",
  Two_Prints:"2 tekshirish",
  Five_Prints:"5 tekshirish",
  Ten_Prints:"10 tekshirish",
  Fifteen_Prints:"15 tekshirish",
  Twenty_Prints:"20 tekshirish",
  Thirty_Prints:"30 tekshirish",
  Image_Added:"Rasm qo'shildi",
  Image_Preview:"Tasvirni oldindan ko'rish",
  No_Image_Was_Selected:"Hech qanday rasm tanlanmagan.",
  Cheques_Unlimited:"Cheksiz cheklar",
  _Subscription:"Obuna",
  Subscription:"Tekshiruvlar - 1 oy",
  Two_Month_Subscription:"Tekshiruvlar - 2 oy",
  Three_Month_Subscription:"Tekshiruvlar - 3 oy",
  Six_Month_Subscription:"Tekshiruvlar - 6 oy",
  Twelve_Month_Subscription:"Tekshiruvlar - 12 oy",
  Cheques_Are_Available:"Cheklarni chop etish mumkin.",
  Cheques_Thank_You:"Cheklarni xarid qilganingiz uchun katta rahmat! Xaridlaringiz endi faol.",
  Upgrade_Required_Two:"Paketni tanlang va xaridni boshlash uchun narx tugmasini ikki marta bosing. Bir necha soniya ichida professional ko'rinishdagi to'liq rangli cheklarni chop eting.",
  Month:"Oy",
  Due:"Muddati:",
  Expires:"Muddati tugaydi:",
  Subscription_Active:"Obuna faol",
  Subscription_Inactive:"Obuna faol emas",
  Purchase_Additional_Cheques:"Qo'shimcha cheklarni sotib olasizmi?",
  Printable_Cheque:"Chop etish mumkin bo'lgan tekshirish",
  Printable_Cheques:"Chop etish mumkin bo'lgan cheklar",
  Printable_Cheque_Word:"chek sizning hisobingizda mavjud.",
  Printable_Cheques_Word:"hisobingizda cheklar mavjud.",
  Max_Amount_Message:"Siz belgilagan miqdor ushbu tizim uchun belgilangan maksimal miqdordan oshib ketdi:",
  Terms_Required_Word:"Cheklardan foydalanishni davom ettirishdan oldin ushbu shartnomaga rozilik bildirishingiz kerak.",
  Subscriptions:"Obunalar",
  Product_Upgrades:"Yangilanishlar",
  Mailed_Out_Cheques:"Pochta orqali yuborilgan cheklar",
  Enter_A_Company_Name:"Iltimos, kompaniya nomini kiriting.",
  Single_Cheques:"Yagona tekshiruvlar",
  Cheque_Golden:"Oltin chek",
  Cheque_Golden_Window:"Oltin chek dizayni.",
  Cheque_Green:"Yashil chek",
  Cheque_Green_Window:"Yashil chek dizayni.",
  Cheque_Red:"Qizil chek",
  Cheque_Red_Window:"Qizil chek dizayni.",
  Cheque_Yellow:"Sariq chek",
  Cheque_Yellow_Window:"Sariq chek dizayni.",
  Cheque_Statue_of_Liberty:"Ozodlik haykali",
  Cheque_Statue_of_Liberty_Window:"Ozodlik haykali dizayni.",
  Cheque_Green_Wave:"Yashil to'lqin",
  Cheque_Green_Wave_Window:"Yashil chek dizayni.",
  Cheque_Golden_Weave:"Oltin to'quv",
  Cheque_Golden_Weave_Window:"Nafis oltin chek dizayni.",
  Cheque_Green_Sun:"Yashil quyosh",
  Cheque_Green_Sun_Window:"Chuqur va tinchlantiruvchi chek dizayni.",
  Cheque_Blue_Checkers:"Moviy shashka",
  Cheque_Blue_Checkers_Window:"Moviy chek dizayni.",
  Cashiers_Check:"Kassir cheki",
  Cashiers_Check_Window:"Kassir cheki uslubi shablon.",
  Cheque_Aqua_Checkers:"Aqua shashka",
  Cheque_Aqua_Checkers_Window:"Aqua tekshiruvi dizayni.",
  Cheque_Golden_Checkers:"Oltin shashka",
  Cheque_Golden_Checkers_Window:"Oltin chek dizayni.",
  Upgrade_Unavailable:"Yangilanishlar mavjud emas",
  Bank_Code_Protection:"Bank raqamini himoya qilish",
  Bank_Code_Protection_Word:"Bank raqamlaringiz boshqa qurilmada yoki boshqa foydalanuvchi uchun ishlaydigan ushbu ilovada ishlatilishidan himoya qiling. Bu harakat ortga qaytmas. Davom ettirilsinmi?",
  Bank_Code_Protection_Blocked_Word:"Siz foydalanmoqchi bo'lgan bank raqamlari boshqa foydalanuvchi yoki qurilma uchun ajratilgan.",
  Bank_Code_Protection_Error_Word:"Raqamni tekshirish amalga oshmadi. Iltimos, internetga ulaning va ushbu bank hisobini qayta qo‘shib ko‘ring.",
  Bank_Code_Protection_Set_Error_Word:"Bank raqamini himoya qilish uchun siz internetga ulangan bo'lishingiz kerak. Iltimos, ulaning va qaytadan urining.",
  Upgrade_Unavailable_Word:"Kechirasiz, sizni tasdiqlashda muammoga duch keldik. Cheklarga obuna va yangilanishlarni hozirda hududingizda xarid qilib bo‘lmaydi.",
  PayPal_Payment_Preview:"PayPal to'lovlarini oldindan ko'rish",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"PayPal-ni tanlang",
  PayPal_Applications:"PayPal ilovalari",
  Purchase_With_Apple_Pay:"Apple Pay bilan xarid qiling",
  Google_Pay:"Google Pay",
  Companies:"Kompaniyalar",
  Insurance:"Sug'urta",
  New_PayPal:"Yangi PayPal",
  Pay_By:"Toʻlash",
  Insure:"Sug'urta qilish",
  Miles:"Milya",
  Payment_Method:"To'lov usuli",
  Select_Policies:"Siyosatlarni tanlang",
  Policies:"Siyosat",
  Policy:"Siyosat",
  No_PayPal_Account:"PayPal hisobi yo'q",
  No_Policies:"Sug'urta polislari yo'q",
  New_Policy:"Yangi siyosat",
  PayPal_Payment:"PayPal to'lovi",
  PayPal_Payments:"PayPal to'lovlari",
  No_Payment_Selected:"Toʻlov tanlanmagan",
  Sending_Payment_Word:"Iltimos, kuting... Bu toʻlov yuborilmoqda.",
  Sending_Payments_Word:"Iltimos, kuting... Toʻlovlar yuborilmoqda.",
  No_Payment_Selected_PayPal:"Yuborish uchun <a routerLink='/folder/Payments'>PayPal to'lovi</a> tanlanmagan.",
  Payment_Sent:"Toʻlov yuborildi",
  PayPal_Payment_Sent:"Bu toʻlov PayPal orqali yuborilgan.",
  Copay:"Copay",
  Dead:"O'lgan",
  Alive:"Tirik",
  Not_Dead:"O'lik emas",
  Unclaimed:"Da'vo qilinmagan",
  Attempted:"Urinish",
  Deceased:"Marhum",
  Claimed:"Da'vo qilingan",
  Unpaid:"To'lanmagan",
  Sending_Payment:"To'lovni yuborish",
  Sending_Payments:"To'lovlarni yuborish",
  Send_PayPal_Confirmation:"Ushbu tranzaksiyani PayPal bilan yubormoqchimisiz?",
  Send_PayPal_Confirmation_Word:"Ushbu tranzaksiyani yuborish uchun yashil tugmani bosing.",
  Save_Payment_As_Unpaid:"Bu toʻlov toʻlanmagan sifatida saqlansinmi?",
  Payment_Pay_Confirmation_PayPal:"Bu toʻlov pulli sifatida saqlansinmi?",
  No_Policies_Word:"Birinchi <a routerLink='/folder/Postage/New'>Sug'urta polisini</a> hozir qo'shing.",
  Timesheet_Multiple_Delete_Confirmation:"Haqiqatan ham bir nechta vaqt jadvallarini oʻchirib tashlamoqchimisiz?",
  Select_Multiple_Timesheets_Prompt:"Hech qanday vaqt jadvali tanlanmagan. Kamida bitta vaqt jadvalini tanlang.",
  Select_Multiple_Policies_Prompt:"Hech qanday siyosat tanlanmagan. Kamida bitta sug'urta polisini tanlang.",
  Policies_Multiple_Delete_Confirmation:"Haqiqatan ham bir nechta siyosatlarni oʻchirib tashlamoqchimisiz?",
  Company:"Kompaniya",
  Add_Company:"Kompaniyani qo'shish",
  New_Company:"Kompaniyani qo'shish",
  No_Companies:"Kompaniyalar yo'q",
  Enable_Company:"Kompaniyani yoqish",
  Select_Company:"Kompaniyani tanlang",
  The_Default_Company:"Standart kompaniya yorlig'i.",
  Manage_Companies:"Kompaniyalarni boshqarish",
  No_Companies_Word:"Cheklar standart kompaniyadan foydalanadi.<br /><a routerLink='/folder/Company/New'>Birinchi Kompaniya</a>ni qo'shing.",
  Default_Company:"Standart kompaniya",
  Cheques_Unlimited_Word:"Cheksiz cheklar sizga xohlagancha cheklarni chop etish imkonini beradi.",
  Cheques_Subscription_Word:"Cheklar obunasi sizga xohlagancha cheklarni chop etish imkonini beradi.",
  You_Own_This_Product:"Siz ushbu mahsulot egasisiz.",
  Your_Subscription_is_Active:"Obunangiz faol.",
  Active_Products:"Faollashtirilgan mahsulotlar",
  Purchase_Confirmation:"Sotib olish",
  Purchase_Cheques:"Sotib olish cheklari",
  Restore_Purchase:"Xaridlarni tiklash",
  Erase_Purchase:"Xaridlarni o'chirish",
  Successfully_Purchased:"Muvaffaqiyatli sotib olindi",
  Enter_Your_Licence_Key:"Ushbu mahsulotni faollashtirish uchun ushbu sahifaga litsenziya kalitingizni kiriting.",
  Licence_Key:"Litsenziya kaliti",
  Enter_Licence_Key:"Litsenziya kalitini kiriting",
  Purchased:"Sotib olingan",
  Enable_Feature:"Funktsiyani yoqish",
  Cancel_Subscription:"Obunani bekor qilish",
  Subscription_Removed:"Obuna olib tashlandi",
  Select_Active_Subscription:"O'zgartirish uchun faol obunani tanlang.",
  Remove_Subscription_Word:"Haqiqatan ham bu obunani bekor qilmoqchimisiz?",
  Delete_Company_Confirmation:"Haqiqatan ham bu kompaniyani butunlay oʻchirib tashlamoqchimisiz? OGOHLANTIRISH: Siz barcha saqlangan ma'lumotlarni yo'qotasiz!",
  Delete_Default_Company_Word:"Siz standart kompaniyani o'chira olmaysiz. Ilovani asl holatiga qaytarmoqchimisiz? OGOHLANTIRISH: Siz barcha saqlangan ma'lumotlarni yo'qotasiz!",
  Console_Warning_2:"Hozirda sizniki bo'lmagan ushbu ilova yordamida hech qanday valyuta bilan ishlamang.",
  Terms_and_Conditions:"Foydalanish shartlari",
  and_the:"va",
  for:"uchun",
  Please_Read_Word:"Iltimos, o'qing va rozilik bildiring",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Ayrim valyuta ayirboshlash kurslari topilmadi. Iltimos, internetga ulaning.",
  Free:"Ozod",
  DB_Erase_Prompt_2:"Butun ishlab chiquvchi ma'lumotlar bazasi butunlay o'chirilsinmi? OGOHLANTIRISH: Siz barcha xarid va OBUNA ma'lumotlarini yo'qotasiz!",
  Successfully_Imported:"Muvaffaqiyatli import qilindi",
  Select_Postage:"Pochta ni tanlang",
  No_Postage:"Pochta markalari yo'q",
  No_Paid_Postage_Word:"Birinchi <a routerLink='/folder/Postage/New'>Pulli pochta</a> markasini qo'shing.",
  Trial_Complete:"Sinov tugallandi",
  Please_Upgrade:"Chop etishni davom ettirish uchun Chexlarni yangilang.",
  Aa:"Aa",
  Color:"Rang",
  Font:"Shrift",
  Guide:"Qoʻllanma",
  Guides:"Qo'llanmalar",
  Image:"Rasm",
  Zoom:"Kattalashtirish",
  Logo:"Logotip",
  Bank:"Bank",
  MICR:"MICR",
  Total:"Jami",
  Cancel:"Bekor qilish",
  Confirm:"Tasdiqlang",
  Method:"Usul",
  Biometric_Security:"Biometrik xavfsizlik",
  Please_Login_To_Continue:"Davom etish uchun tizimga kiring.",
  Complete:"Bajarildi",
  Sign_Up:"Ro'yxatdan o'tish",
  Error:"Xato",
  Biometrics:"Biometrika",
  Percent:"Foiz",
  Zero_Percent:"0%",
  Top_Margin:"Yuqori chegara",
  Bottom_Margin:"Pastki chet",
  Left_Margin:"Chap chekka",
  Right_Margin:"O'ng chekka",
  MICR_Margin:"MICR marjasi",
  Table_Margin:"Jadval chegarasi",
  Address_Margin:"Manzil chegarasi",
  Percentage_:"Foiz",
  Vacation_Title:"Dam olish sarlavhasi",
  Use_PIN:"PIN koddan foydalaning",
  Loading__:"Yuklanmoqda...",
  Design_Title:"Dizayn nomi",
  Authorize:"Ruxsat berish",
  Key:"Kalit",
  Public_Key:"Ochiq kalit",
  Private_Key:"Shaxsiy kalit",
  Authenticate:"Autentifikatsiya qilish",
  Last_Payroll:"Oxirgi ish haqi",
  Last_Calculation:"Oxirgi hisoblash",
  Authorized:"Vakolatli",
  Geo_Authorized:"Geo-joylashuv: Vakolatli",
  Not_Authorized:"Ruxsat berilmagan",
  Authorization_Complete:"Avtorizatsiya tugallandi",
  Geolocation_Authorization:"Geo-joylashuv avtorizatsiyasi",
  Out_of_Bounds:"Chegaradan tashqari",
  Cant_Delete_Default_Design:"Standart dizaynni oʻchirib boʻlmaydi.",
  Unauthorized_Out_of_Bounds:"Ruxsatsiz: chegaradan tashqarida",
  Biometric_Authorization:"Biometrik avtorizatsiya",
  Locating_Please_Wait:"Joylashuv aniqlanmoqda, kuting...",
  Test_Biometrics:"Biometrik test",
  Cheque_Margins:"Chegaralarni tekshiring",
  Percentage_Full_Error:"Foiz maydonini 100% foizdan yuqori qilib belgilash mumkin emas.",
  No_Payroll_Text:"<a routerLink='/folder/Employee/New'>Xodim</a> yoki <a routerLink='/folder/Payee/New'>To'lovchi</a> va <a routerLink='/folder/Schedule/New'>Jadval</a>.",
  Design_Saved:"Dizayn saqlangan",
  Default_Design_Selected:"Standart dizayn tanlangan",
  Partial_Import:"Qisman import",
  Partial_Export:"Qisman eksport",
  Entire_Import:"To'liq import",
  Entire_Export:"To'liq eksport",
  Existing_Password:"Iltimos, mavjud parolingizni kiriting:",
  Existing_PIN:"Iltimos, mavjud PIN kodingizni kiriting:",
  Pin_Change_Header:"PIN-kodni tasdiqlash",
  Pin_Change_SubHeader:"O'zgartirishni tasdiqlash uchun eski PIN raqamingizni kiriting.",
  Pass_Change_Header:"Parolni tasdiqlash",
  Pass_Change_SubHeader:"O'zgartirishni tasdiqlash uchun eski parolingizni kiriting.",
  PIN_Enter_Message:"Tasdiqlash uchun PIN kodingizni kiriting.",
  Password_Enter_Message:"Tasdiqlash uchun parolingizni kiriting.",
  Pin_Updated_Success:"PIN kod muvaffaqiyatli yangilandi!",
  Pin_Updated_Fail:"PIN-kodni yangilab bo‘lmadi.",
  Pass_Updated_Success:"Parol muvaffaqiyatli yangilandi.",
  Pass_Updated_Fail:"Parolni yangilab bo‘lmadi.",
  Preview_Payment:"Toʻlovni oldindan koʻrish",
  Preview_Payroll:"Ish haqini oldindan ko'rish",
  No_Payments_Created:"Hech qanday toʻlov yaratilmagan.",
  Payment_Preview:"Toʻlovni oldindan koʻrish",
  Enable_Payee:"To'lovchini yoqish",
  Rendered:"Ko'rsatilgan",
  No_Email:"Email yo'q",
  Setup_Cheques:"O'rnatish tekshiruvlari",
  name:"Ism",
  address:"Manzil",
  address_2:"Manzil qatori 2",
  city:"Shahar",
  province:"Viloyat",
  postal_code:"Pochta/Pochta indeksi",
  country:"Mamlakat",
  username:"Foydalanuvchi nomi",
  full_name:"To'liq ism",
  birthday:"Tug'ilgan kun",
  email:"Elektron pochta",
  phone:"Telefon",
  employees:"Xodimlar",
  addresses:"Manzillar",
  payment_amount_limit:"To'lov miqdori chegarasi",
  total_limit:"Umumiy chegara",
  payees:"To'lovchilar",
  description:"Tavsif",
  address_line_one:"Birinchi qator manzili",
  address_line_two:"Manzil ikkinchi qator",
  image:"Rasm",
  account_holder:"Hisob egasi",
  cheque_starting_id:"Boshlanish identifikatorini tekshiring",
  transit_number:"Tranzit raqami",
  institution_number:"Tashkilot raqami",
  designation_number:"Belgilash raqami",
  account_number:"Hisob raqami",
  currency:"Valyuta",
  signature:"Imzo",
  payment_payroll_limit:"To'lov chegarasi",
  total_limi:"Umumiy chegara",
  date:"Sana",
  printed_memo:"Chop etilgan eslatma",
  banks:"Banklar",
  signature_image:"Imzo tasviri",
  address_name:"Manzil nomi",
  notes:"Eslatmalar",
  design:"Dizayn",
  title:"Sarlavha",
  frequency:"Chastotasi",
  fax:"Faks",
  salaries:"Maoshlar",
  salary_ids:"Ish haqi identifikatorlari",
  start_time:"Boshlanish vaqti",
  end_time:"Tugash vaqti",
  paid:"To'langan",
  overtime_percentage:"To'langan foiz",
  overtime_amount:"To'langan belgilangan miqdor",
  first_name:"Ism",
  last_name:"Familiya",
  moderation:"Moderatsiya",
  create:"Yaratmoq",
  edit:"Tahrirlash",
  destroy:"Yo'q qilish",
  day_start_time:"kun_boshlanish_vaqti",
  day_end_time:"kun_oxirgi_vaqt",
  fullname:"Ism",
  time:"Vaqt",
  auto_send:"Avtomatik yuborish",
  time_method:"Vaqt usuli",
  schedules:"Jadvallar",
  indefinite_payroll_enabled:"Cheksiz yoqing",
  amount:"Miqdori",
  repeat:"Takrorlang",
  always_enabled:"Har doim yoqilgan",
  start_date:"Boshlanish sanasi",
  end_date:"Tugash sanasi",
  Cheque_Total:"Jami tekshiring",
  Total_Amount:"Umumiy hisob:",
  Amounts:"Miqdor:",
  Images:"Tasvirlar",
  Files:"Fayllar",
  Previewing:"Ko‘rib chiqilmoqda:",
  Insert:"Kiritmoq",
  Preview_Import:"Importni oldindan ko'rish",
  Entry:"Kirish",
  Entries:"Yozuvlar",
  No_Entries:"Yo'q",
  Import_Type:"Import turi",
  Select_Details:"Tafsilotlarni tanlang",
  Select_Payee:"To'lovchini tanlang",
  Enable_Holidays:"Dam olish kunlarini yoqing",
  Disable_Holidays:"Dam olish kunlarini o'chirib qo'ying",
  Wire_Transfer:"Bank pul o'tkazmasi",
  New_Export:"Yangi eksport",
  Export_Data:"Ma'lumotlarni eksport qilish",
  Export_Data_Word:"Eksport qilish va yuklab olish uchun fayl turini tanlang.",
  Export_File:"Faylni eksport qilish",
  Mode:"Rejim",
  Times:"Vaqtlar",
  Widgets:"Vidjetlar",
  Slider:"Slayder",
  Set_Details:"Tafsilotlarni sozlash",
  Select_Type:"Turni tanlang",
  Display_Slider:"Slayderni ko'rsatish",
  Dashboard_Slider:"Boshqaruv paneli slayderi",
  Dashboard_Mode:"Boshqaruv paneli rejimi",
  Show_Intro:"Kirish",
  Show_Payrolls:"Ish haqini ko'rsatish",
  Show_Holidays:"Bayramlarni ko'rsatish",
  Show_Invoices:"Hisob-fakturalarni ko'rsatish",
  Show_Cheques:"Cheklarni ko'rsatish",
  Enabled_Widgets:"Yoqilgan vidjetlar",
  Disabled_Widgets:"O'chirilgan vidjetlar",
  Colors:"Ranglar",
  Barcodes:"Shtrixli kodlar",
  View_Timers:"Taymerlarni ko'rish",
  Gradients:"Gradientlar",
  No_Info:"Ma'lumot yo'q",
  Disable:"Oʻchirish",
  Show_Layer:"Qatlamlarni ko'rsatish",
  Hide_Layer:"Qatlamlarni yashirish",
  Text_Layer:"Matn qatlamlari",
  Secondly:"Ikkinchidan",
  Minutely:"Daqiqada",
  nine_am:"9:00",
  five_pm:"17:00",
  Enable_Address:"Manzilni yoqish",
  Invalid_File_Type:"Kechirasiz, yaroqsiz fayl turi tanlangan. Qo'llab-quvvatlanadigan fayl turi:",
  Error_Updating_Entry:"Kechirasiz, ushbu yozuvni yangilashda xatolik yuz berdi.",
  Schedule_Timing_Alert:"Xato: Jadvalning boshlanish vaqti tugash vaqtidan keyingi qiymatga o'rnatiladi.",
  Select_Multiple_Payments_Prompt:"Hech qanday toʻlov tanlanmagan. Kamida bitta toʻlovni tanlang.",
  Select_Multiple_Cheques_Prompt:"Hech qanday chek tanlanmagan. Iltimos, kamida bitta chekni tanlang.",
  Select_Multiple_Items_Prompt:"Hech qanday element tanlanmagan. Kamida bitta elementni tanlang.",
  Paymemt_Multiple_Delete_Confirmation:"Haqiqatan ham bir nechta toʻlovlarni oʻchirib tashlamoqchimisiz?",
  Cheque_Multiple_Delete_Confirmation:"Haqiqatan ham bir nechta cheklarni oʻchirib tashlamoqchimisiz?",
  Payee_Multiple_Delete_Confirmation:"Haqiqatan ham bir nechta toʻlovchilarni oʻchirib tashlamoqchimisiz?",
  Employee_Multiple_Delete_Confirmation:"Haqiqatan ham bir nechta xodimlarni o'chirib tashlamoqchimisiz?",
  MICR_Warning:"Eslatma: Ba'zi printerlar va qurilmalar MICR shriftini to'g'ri ko'rsatmasligi mumkin.",
  Automatically_Send:"Avtomatik yuborish",
  Type:"Turi",
  Payment_Type:"To'lov turi",
  Auto_Send:"Avtomatik yuborish",
  Automatically_Process:"Avtomatik ishlov berish",
  Auto_Process:"Avtomatik ishlov berish",
  Image_Based:"Tasvirga asoslangan",
  Font_Based:"Shriftga asoslangan",
  Rerender:"Qayta ko'rsatish",
  Rendering:"Renderlash",
  Render:"Fayllar",
  Top:"Yuqori",
  Middle:"O'rta",
  Bottom:"Pastki",
  Top_Left:"Yuqori chap",
  Top_Center:"Yuqori markaz",
  Top_Right:"Yuqori oʻng",
  Middle_Left:"O'rta chap",
  Middle_Center:"O'rta markaz",
  Middle_Right:"O'rta o'ng",
  Bottom_Left:"Pastki chap",
  Bottom_Center:"Pastki markaz",
  Bottom_Right:"Pastki oʻng",
  Numbers:"Raqamlar",
  Verified:"Tasdiqlangan",
  Paper_Size:"Qog'oz o'lchami",
  New_Device:"Yangi qurilma",
  Add_Device:"Qurilma qo'shish",
  Remove_Device:"Qurilmani o'chirish",
  Delete_Device:"Qurilmani o'chirish",
  Block_Device:"Qurilmani bloklash",
  Block:"Bloklash",
  Unblock:"Blokdan chiqarish",
  Table:"Jadval",
  Scan_Login_Code:"Kirish kodini skanerlang",
  Login_Code:"Kirish kodi",
  Scan_Code:"Kodni skanerlash",
  Scan_QR_Code:"QR kodini skanerlang",
  Select_All:"Hammasini belgilash",
  Deselect_All:"Barchasini bekor qilish",
  Increase:"Kattalashtirish; ko'paytirish",
  Decrease:"Kamaytirish",
  Bold:"Qalin",
  Text:"Matn",
  Style:"Uslub",
  Italic:"Kursiv",
  QR_Code:"QR kodi",
  Barcode:"Shtrix-kod",
  Browse_Images:"Tasvirlarni ko'rib chiqish",
  Browse_Files:"Fayllarni ko'rib chiqish",
  Background_Image:"Fon rasmi",
  Logo_Image:"Logotip tasviri",
  Header_Image:"Sarlavha tasviri",
  Bank_Image:"Bank rasmi",
  Cut_Lines:"Chiziqlarni kesish",
  Background:"Fon",
  License:"Litsenziya",
  One_License:"1 Litsenziya:",
  Licensed:"Litsenziyalangan:",
  Not_Licensed:"Litsenziyalanmagan",
  Enter_Serial:"Serialni kiriting",
  Serial_Key:"Seriya kaliti",
  Serial:"Serial",
  Product_Key:"Mahsulot kaliti",
  Check_Product_Key:"Mahsulot kalitini tasdiqlang",
  Add_Product_Key:"Mahsulot kalitini kiriting",
  Verifying_Purchase:"Xarid tasdiqlanmoqda...",
  Print_Envelope:"Konvertni chop etish",
  Envelope:"Konvert",
  Thank_You:"Rahmat!",
  Scale:"Masshtab",
  Print_Scale:"Chop etish shkalasi",
  Borders:"Chegaralar",
  VOID:"BO'LGAN",
  Void_Cheque:"Tekshirish bekor",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"BUYURTMAGA TO'LING:",
  NO_DOLLARS:"DOLLAR YO'Q",
  ONE_DOLLAR:"BIR DOLLAR",
  DOLLARS:"DOLLAR",
  AND:"VA",
  CENTS:"CENTS.",
  NO_:"YO'Q",
  ONE_:"BIR",
  AND_NO_:"VA YO'Q",
  _AND_ONE_:"VA BIR",
  DOLLARS_AND_ONE_CENT:"VA BIR CENT.",
  AND_NO_CENTS:"VA NO CENTS.",
  CHEQUE_PRINT_DATE:"DATE:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_SIGNATUREMP:"deputat",
  Initial_Tasks:"Dastlabki vazifalar",
  Inital_Setup:"Dastlabki sozlash",
  Welcome_To:"Ga Xush kelibsiz",
  Welcome:"Xush kelibsiz",
  Swipe:"Suring",
  Intro_Setup:"Kirish sozlamalari",
  Introduction:"Kirish",
  CHEQUE_PRINT_CREDIT:"Cheklar tomonidan quvvatlanadi",
  Title_Intro_Word:"Cheklarga xush kelibsiz",
  Title_Intro:"Tekshirish Kirish",
  Title_Setup:"O'rnatishni tekshiradi",
  PayPal_Default_Email_Message:"Siz yangi PayPal o'tkazmasini oldingiz.",
  Cheques_App_Export:"Cheklar tomonidan eksport qilingan",
  No_Prints_Purchased:"Hech qanday tasma sotib olinmagan",
  No_Prints_Purchased_Word:"Qo'shimcha nashrlar mavjud emas. Iltimos, qo'shimcha cheklarni xarid qiling.",
  Post_Intro_Thanks:"Cheklarni tanlaganingiz uchun tashakkur. Endi sozlash jarayoni tugallandi.",
  Post_Intro_Word:"Birinchi chekingizni chop etishni boshlang, kelajakdagi to'lovni qo'shing yoki ish haqi jadvaliga xodimlarni qo'shing.",
  Upgrade_Required:"Tekshiruvlar ushbu xabarni yashirish va qoʻshimcha funksiyalarni ochish uchun dasturiy taʼminotning yanada yuqori versiyasiga ega boʻlishingizni talab qiladi.",
  Upgrade_Title:"Tekshiruvlar",
  Mailout_Prompt:"Siz qo'shimcha ravishda Chexlar sizning ish haqi cheklaringizni pochta orqali yuborishni tanlashingiz mumkin.",
  Mailed_Cheque:"Pochta orqali yuborilgan chek",
  Mailed_Cheque_Color:"Pochta orqali yuborilgan chek (rangli)",
  Terms_Purchase:"Cheklar bilan barcha elektron xaridlar xarid qilingan kundan boshlab 30 kungacha to'liq qaytariladi. Iltimos, o'qing va rozilik bildiring",
  Dashboard_Setup:"Asosiy printerni sozlash",
  Dashboard_Add:"Asosiy bank hisobini qo'shing",
  Dashboard_Customize:"Tekshirish shablonini tanlang",
  Dashboard_Job_Salary:"Ish joyingizni yarating va maoshingizni qo'shing",
  Dashboard_Employees:"Xodimlar va to'lovchilarni kuzatib boring",
  Dashboard_Print:"Maoshlaringizni chop eting va yuboring",
  Dashboard_Payroll:"Ish haqini chop etishni avtomatlashtiring",
  Dashboard_PayPal:"PayPal ish haqi / to'lovlarni o'rnating",
  Begin_Setup:"O'rnatishni boshlang",
  Get_Started:"Boshlash",
  Purchase:"Sotib olish",
  Lockdown:"Qatʼiy izolyatsiya",
  Unlock:"Qulfni ochish",
  Detailed:"Batafsil",
  Log_In:"Tizimga kirish",
  Login:"Tizimga kirish",
  Launch:"Ishga tushirish",
  Register:"Roʻyxatdan oʻtish",
  Finish:"Tugatish",
  List:"Roʻyxat",
  Weekends:"Dam olish kunlari",
  Weekly:"Haftalik",
  PayPal_Default_Subject:"Yangi to'lov",
  Payment_Message:"To'lov xabari",
  PayPal_Default_Payment_Note:"Rahmat",
  Setup_Your_Cheqing_Account:"Hisobni tekshirish",
  Add_Your_Primary_Cheqing_Account:"Asosiy chexing hisobingizni qo'shing.",
  Welcome_Word:"Ish haqi va inson resurslarini boshqarishni soddalashtirish va avtomatlashtirish.",
  Get_Started_Quickly:"Boshlashimizga yordam beradigan bir nechta oddiy savollarga javob bering...",
  Done_Intro_Word:"Sozlash tugallandi",
  PIN_Protected:"Parol va PIN-kod bilan himoyalangan",
  Enter_New_PIN:"Yangi PIN kodni kiriting:",
  Enter_PIN:"PIN kodni kiriting:",
  Invalid_PIN:"PIN kod noto‘g‘ri kiritildi.",
  Account_Identifier:"Hisob identifikatori",
  New_Account_Identifier:"Yangi hisob identifikatori",
  attempt:"urinish",
  attempts:"urinishlar",
  remaining:"qolgan",
  Language:"Til",
  languages:"Tillar",
  select_languages:"Tilni tanlang",
  Deposit:"Depozit",
  Hire_One_Now:"Hozir birini yollash",
  App_Locked:"Ilova qulflangan.",
  Skip_:"Oʻtkazib yuborish",
  Skip_to_Dashboard:"Boshqaruv paneliga o‘tish",
  Dashboard:"Boshqaruv paneli",
  Import:"Import",
  Admin:"Administratorlar",
  New_Admin:"Yangi Admin",
  Settings:"Sozlamalar",
  Color_Picker:"Rang tanlash",
  Font_Picker:"Shrift tanlash",
  Logout:"Chiqish",
  Close:"Yopish",
  Close_menu:"Yopish",
  Excel:"Excel fayli",
  Csv:"CSV fayli",
  Sql:"SQL fayli",
  Json:"JSON fayli",
  Url:"URL orqali import qilish",
  Back:"Orqaga",
  Timers:"Taymerlar",
  Cheque:"Tekshirish",
  Print:"Chop etish",
  Designs:"Dizaynlar",
  Pause_Printing:"Chop etishni to‘xtatib turish",
  Resume_Printing:"Chop etishni davom ettirish",
  Printing_Paused:"Chop etish to‘xtatildi",
  PrintingUnavailable:"Kechirasiz! Ushbu tizimda chop etish imkonsiz.",
  Prints_Paused:"Chop etish toʻxtatildi",
  Administration:"Ma'muriyat",
  Loading:"Yuklanmoqda",
  Unnamed:"Nomsiz",
  error:"Kechirasiz, bu chekni ko‘rish uchun ochib bo‘lmadi.",
  No_Cheques_To_Print:"Chop etish uchun cheklar yo'q",
  No_Cheques_To_Print_Word:"<a routerLink='/folder/Cheque/New'>Yangi chek</a> yarating.",
  New_Cheque:"Yangi chek",
  Start_One_Now:"Hoziroq boshlang",
  Edit_Cheque:"Tekshirishni tahrirlash",
  Select_Cheques:"Tekshirish-ni tanlang",
  Select_Employee:"Xodimni tanlang",
  Default_Printer:"Standart printer",
  Reassign:"Qayta tayinlash",
  Configure:"Sozlang",
  Template:"Shablon",
  Designer:"Dizayner",
  Edit_Designs:"Dizaynlarni tahrirlash",
  New_Design:"Yangi dizayn",
  Next:"Keyingisi",
  Save:"Saqlash",
  Name:"Ism",
  Mail:"Pochta",
  Amount:"Miqdori",
  Date:"Sana",
  PayPal:"PayPal",
  Payouts:"To'lovlar",
  PayPal_Label:"PayPal yorlig'i",
  Email_Username:"Elektron pochta / foydalanuvchi nomi",
  Client_ID:"Mijoz identifikatori",
  Sandbox_Email:"Sandbox elektron pochta",
  PayPal_Email:"PayPal elektron pochta",
  PayPal_Username:"API foydalanuvchi nomi",
  PayPal_Payouts:"PayPal to'lovlari",
  Select_PayPal_Key:"PayPal kalitini tanlang",
  Secret:"Sir",
  API_Secret:"API siri",
  PayPal_API_Keys:"PayPal kalitlari",
  New_PayPal_Key:"Yangi PayPal kaliti",
  Email_Subject:"Elektron pochta mavzusi",
  Email_Message:"Elektron pochta xabari",
  Payout_Options:"Toʻlov imkoniyatlari",
  Payment_Note:"To'lov eslatmasi",
  Enable_Employee:"Xodimni yoqish",
  Enable_Production_Mode:"Ishlab chiqarish rejimini yoqing",
  Sandbox_Username:"Sandbox foydalanuvchi nomi",
  Sandbox_Signature:"Sandbox imzosi",
  Sandbox_Password:"Sandbox paroli",
  Production_Username:"Ishlab chiqarish foydalanuvchi nomi",
  Production_Signature:"Ishlab chiqarish imzosi",
  Production_Password:"Ishlab chiqarish uchun parol",
  Production_Email:"Ishlab chiqarish elektron pochtasi",
  API_Client_ID:"API mijoz identifikatori",
  API_Signature:"API imzosi",
  API_Password:"API paroli",
  API_Username:"API foydalanuvchi nomi",
  Secret_Key:"Maxfiy kalit",
  Sandbox:"Sandbox",
  Production:"Ishlab chiqarish",
  Sandbox_Keys:"Sandbox kalitlari",
  Production_Keys:"Ishlab chiqarish kalitlari",
  API_Title:"API sarlavhasi",
  Production_Mode:"Ishlab chiqarish rejimi",
  Account_Label:"Hisob yorlig'i",
  No_PayPal_Setup:"PayPal kaliti yo'q",
  Enable_PayPal_Account:"PayPal hisobini yoqing",
  No_PayPal_Word:"<a routerLink='/folder/Invoice/New'>PayPal API kalitini</a> qo'shing.",
  Printed_Memo:"Chop etilgan eslatma",
  Employee:"Xodim",
  View_Employee:"Xodimni ko'rish",
  Mailing_Address:"Pochta manzili",
  Company_Address:"Kompaniya manzili",
  Select_Company_Address:"Kompaniya manzilini tanlang",
  Address:"Manzil",
  Any_Day:"Har qanday kun",
  Address_Name:"Manzil nomi",
  Unit:"Birlik",
  Account:"Hisob",
  Bank_Account:"Bank hisob raqami",
  Account_Limits:"Hisob cheklovlarini yoqing",
  Payroll:"Ish haqi",
  Run:"Yugurish",
  Run_Payroll:"Ish haqi jadvalini ishga tushirish",
  New_Payroll:"Yangi ish haqi",
  No_Payroll:"Kelgusi ish haqi yo'q",
  Upcoming_Holiday:"Kelgusi bayram:",
  Invoice_Due:"To'lov muddati:",
  New_Invoice:"Yangi hisob-faktura",
  No_Invoices:"Hisob-fakturalar yo'q",
  No_Invoices_Word:"Birinchi <a routerLink='/folder/Invoice/New'>Hisob-faktura</a>ni yarating.",
  Cheque_Due:"Tekshirish muddati:",
  Payrolls:"Ish haqi to'lovlari",
  Sandbox_Mode:"Sandbox rejimi",
  Hire:"Yollash",
  Pay:"To'lash",
  New:"Yangi",
  Add:"Qo'shish",
  Make:"Qilish",
  Time:"Vaqt",
  Write:"Yozing",
  Holiday:"Bayram",
  Holidays:"Bayramlar",
  Next_Holiday:"Keyingi bayram:",
  All_Done:"Hammasi tayyor!",
  Employees:"Xodimlar",
  Payees:"To'lovchilar",
  Job:"Ish",
  Jobs:"Ishlar",
  Invoice:"Hisob-faktura",
  Invoices:"Hisob-fakturalar",
  Vacations:"Dam olish kunlari",
  Cheques:"Tekshiruvlar",
  Brand_Cheques:"Sizning brendingiz",
  Payment:"To'lov",
  Enable_Payment:"Toʻlovni yoqish",
  Payments:"To'lovlar",
  Schedule:"Jadval",
  Schedules:"Jadvallar",
  Timesheet:"Vaqt jadvali",
  Timesheets:"Vaqt jadvallari",
  Salary:"Ish haqi",
  New_Address:"Yangi manzil",
  Address_2:"Manzil (2-qator)",
  _City:"Shahar",
  _State:"Davlat/Prov",
  City:"Shahar / shaharcha",
  State:"Shtat / viloyat",
  Postal:"Pochta / pochta indeksi",
  ZIP:"Pochta / ZIP",
  Country:"Mamlakat",
  Addresses:"Manzillar",
  Required_Options:"Majburiy variantlar",
  Optional_Options:"Majburiy emas",
  Additional_Options:"Qo'shimcha imkoniyatlar",
  Required:"Majburiy",
  Optional:"Ixtiyoriy",
  Additional:"Qo'shimcha",
  No_Addresses:"Manzillar yo'q",
  New_Address_Word:"Birinchi <a routerLink='/folder/Address/New'>Manzil</a>ni qo'shing.",
  Select_Address:"Manzilni tanlang",
  No_Address:"Manzillar yo'q",
  Print_Cheque:"Chop etish tekshiruvi",
  Print_Cheques:"Cheklarni chop etish",
  Print_Cheque_Now:"Chop etish tekshiruvi",
  Description:"Tavsif",
  Pay_To_The_Order_Of:"Buyurtmani to'lash:",
  Select_Date_Range:"Sana oralig'ini tanlang",
  Select_Starting_Date:"Boshlanish sanasini tanlang",
  Select_Ending_Date:"Tugash sanasini tanlang",
  Select_Date:"Sana-ni tanlang",
  Cheque_Date:"Sana tekshiring",
  Cheque_Memo:"Eslatmani tekshiring",
  Blank_Cheque:"Bo'sh chek",
  Blank:"Bo'sh",
  No_Cheques:"Cheksiz",
  No_Cheques_Word:"Birinchi <a routerLink='/folder/Cheque/New'>Chek</a>ni chop eting.",
  Cheque_Amount_Placeholder:"$0,00",
  View_Image:"Rasmni ko'rish",
  View:"Ko'rinish",
  Modify:"O'zgartirish",
  Delete:"Oʻchirish",
  Cheque_Paid:"To'langan",
  New_Deduction:"Yangi chegirma",
  Title:"Sarlavha",
  Frequency:"Chastotasi",
  Hourly:"Soatlik",
  Daily:"Kundalik",
  Weekdays:"Hafta kunlari",
  BiWeekly:"2 hafta",
  TriWeekly:"3 hafta",
  Monthly:"Oylik",
  MiMonthly:"2 oy",
  Quarterly:"Har chorakda",
  Yearly:"Yillik",
  Every_Week:"Har hafta",
  Every_Payroll:"Har bir ish haqi",
  Every_Month:"Har oy",
  Annually:"Har yili",
  Always_Scheduled:"Har doim rejalashtirilgan",
  Start_Date:"Boshlanish sanasi",
  End_Date:"Tugash sanasi",
  Start_Time:"Boshlanish vaqti",
  End_Time:"Tugash vaqti",
  Deduction_Label:"Chegirma yorlig'i",
  Notes:"Eslatmalar",
  Options:"Variantlar",
  Enable:"Yoqish",
  Select_Deductions:"Chegirmalar-ni tanlang",
  Deductions:"Chegirmalar",
  No_Deductions:"Chegirmalarsiz",
  No_Deductions_Word:"Birinchi <a routerLink='/folder/Deduction/New'>Chegirma</a>ni yarating.",
  New_Employee:"Yangi Xodim",
  No_Title:"Sarlavha yo'q",
  _Name:"Ism",
  About_Yourself:"O'zingiz haqingizda",
  Full_Name:"To'liq ism",
  Birthday:"Tug'ilgan kun",
  Email:"Elektron pochta",
  SMS:"SMS",
  Phone:"Telefon",
  Test:"Sinov",
  Call:"Qo'ng'iroq qiling",
  Fax:"Faks",
  Printed_Note:"Chop etilgan eslatma",
  Position:"Lavozim",
  Job_Position:"Lavozimi",
  Select_a_Job:"Ishni tanlang",
  Select_a_Salary:"Ish haqini tanlang",
  Add_a_Deduction:"Chegirma qo'shing",
  Taxes:"Soliqlar",
  Add_Taxes:"Soliqlarni qo'shish",
  Date_of_Birth:"Tug'ilgan kuni",
  Email_Address:"E-pochta manzili",
  Phone_Number:"Telefon raqami",
  Phone_Call:"Telefon qo'ng'irog'i",
  Enable_on_Payroll:"Ish haqi bo'yicha yoqish",
  Select_Employees:"Xodimlarni tanlang",
  No_Employees:"Xodimlar yo'q",
  No_Employees_Word:"Birinchi yangi <a routerLink='/folder/Employee/New'>Xodim</a>ni qo'shing.",
  No_Name:"Ism yo'q",
  Unemployeed:"Ishsiz",
  Employeed:"Ishga joylangan",
  Paid:"To'langan",
  Enabled:"Yoqilgan",
  Disabled:"O'chirilgan",
  Fire:"Yong'in",
  Not_Available:"Mavjud emas",
  Not_Available_Word:"Birinchi <a routerLink='/folder/Invoice/New'>Hisob-faktura</a>ni chop eting va to'lovni oling.",
  Select_Invoices:"Hisob-fakturalarni tanlang",
  Print_Invoice_Word:"Birinchi <a routerLink='/folder/Invoice/New'>Hisob-faktura</a>ni chop eting va to'lovni oling.",
  Invoice_Title:"Hisob-faktura nomi",
  Invoice_Date:"Hisob-faktura sanasi",
  Due_Date:"Muddati",
  New_Job:"Yangi ish",
  Details:"Tafsilotlar",
  Customize:"Moslashtiring",
  Customize_Dashboard:"Boshqaruv panelini sozlash",
  Components:"Komponentlar",
  No_Components:"Komponentlar yo'q",
  Main_Components:"Asosiy komponentlar",
  Smaller_Components:"Kichikroq komponentlar",
  Error_Loading_Page:"Ushbu sahifani yuklashda xatolik yuz berdi.",
  Bank_Details:"Bank tafsilotlari",
  About_Your_Job:"Sizning ishingiz haqida",
  Your_Schedule:"Sizning jadvalingiz",
  Job_Title:"Lavozim",
  Job_Description:"Ish tavsifi",
  Job_Details:"Ish tafsilotlari",
  Enable_Job:"Ishni yoqish",
  Pay_Period:"To'lov muddati",
  Perpetually_Schedule:"Doimiy jadval",
  Select_Jobs:"Jobs-ni tanlang",
  No_Jobs:"Ishlar yo'q",
  Add_Jobs:"Ish qo'shish",
  No_Jobs_Word:"Birinchi <a routerLink='/folder/Job/New'>Ish</a>ni ro'yxatga qo'shing.",
  Count_Employees:"job.employee_count+' Xodimlar'",
  Zero_Employees:"0 Xodimlar",
  New_Leave:"Yangi ta'til",
  Leave_Name:"Ismni qoldiring",
  Paid_Leave:"Pullik ta'til",
  Leave_Pay:"To'lovni qoldiring",
  Indefinite_Leave:"Cheksiz ta'til",
  Indefinite_Payroll:"Cheksiz ish haqi",
  Leave:"Ketish",
  Add_Schedules:"Jadvallarni qo'shish",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Yo‘qlikni yoqish",
  Select_Leaves:"Barglarni tanlang",
  No_Leaves:"Ta'til yo'q",
  Leave_Of_Absence:"Ishdan bo'shatish",
  Leaves_Of_Absence:"Yo'qlik barglari",
  New_Leave_of_Absense:"Yangi ta'til",
  No_Leaves_Word:"Birinchi <a routerLink='/folder/Leave/New'>Ishdan chiqish ta'tilini</a> qo'shing.",
  Not_Enabled:"Yoqilmagan",
  Absences:"Yo'qotishlar",
  Payee:"To'lovchi",
  New_Payee:"Yangi oluvchi",
  Payee_Identifier:"To'lovchi identifikatori",
  Payee_Name:"To'lovchi nomi",
  Payee_Title:"To'lovchi unvoni",
  Payment_Memo:"To'lov eslatmasi",
  Select_Payees:"To'lovchilarni tanlang",
  No_Payees:"To'lovchilar yo'q",
  Add_Payee_Note:"Birinchi <a routerLink='/folder/Payee/New'>To'lovchi</a>ni qo'shing.",
  New_Payees:"Yangi to'lovchilar",
  About_The_Payment_Schedule:"To'lov jadvali",
  Your_Payroll_Schedule:"Avtomatik ish haqi",
  New_Payment:"Yangi to'lov",
  Identifier:"Identifikator",
  Selected:"Tanlangan",
  Select:"Tanlang",
  Memo:"Eslatma",
  Payment_Date:"To'lov sanasi",
  Mark_as_Paid:"To'langan deb belgilang",
  Select_Payments:"To'lovlarni tanlang",
  No_Payments:"Toʻlovlar yoʻq",
  No_Payments_Word:"Birinchi <a routerLink='/folder/Payment/New'>To'lovni</a> yarating.",
  Create_Payroll:"Ish haqi jadvalini yaratish",
  Properties:"Xususiyatlari",
  Payroll_Title:"Ish haqi sarlavhasi",
  Payroll_Notes:"Ish haqi bo'yicha eslatmalar",
  Payroll_Setup:"Ish haqini sozlash",
  Tabulate_Payments:"To'lovlar jadvali",
  Tabulate:"Jadval",
  By:"Muallif:",
  Payments_By:"To'lovlar tomonidan",
  Timesheets_And_Schedules:"Vaqt jadvallari va jadvallar",
  Both:"Ikkalasi ham",
  Items:"Elementlar",
  Add_Payees:"To'lovchilarni qo'shing",
  Add_Account:"Hisob qo'shish",
  Enable_Account:"Hisobni yoqish",
  Enable_Payroll:"Ish haqi hisobini yoqish",
  Print_Payroll:"Ish haqini chop etish",
  No_Payrolls:"Ish haqi yo'q",
  No_Payroll_Word:"Birinchi <a routerLink='/folder/Payroll/New'>Ish haqini</a> yarating.",
  View_more:"MORE_MORE",
  Less:"OZROQ",
  Add_Payroll:"Ish haqini qo'shish",
  Select_Payroll:"Ish haqi ni tanlang",
  About_Your_Salary:"Maoshingiz haqida",
  Add_Salaries:"Ish haqini qo'shish",
  Add_Salary:"Ish haqini qo'shish",
  Salaries:"Maoshlar",
  No_Salaries:"Ish haqi yo'q",
  No_Salaries_Word:"Birinchi <a routerLink='/folder/Salary/New'>Ish haqi</a>ni qo'shing.",
  Select_Salaries:"Ish haqini tanlang",
  New_Salary:"Yangi ish haqi",
  Salary_Name:"Ish haqi identifikatori",
  Enable_Salary:"Maoshni yoqish",
  Salary_Amount:"Ish haqi miqdori",
  New_Schedule:"Yangi Jadval",
  Schedule_Title:"Jadval sarlavhasi",
  Add_Address:"Manzil qo'shish",
  Repeat:"Takrorlang",
  Design:"Dizayn",
  Edit_Design:"Dizaynni tahrirlash",
  Edit_this_Design:"Ushbu dizaynni tahrirlang",
  Repeat_Payment:"To'lovni takrorlash",
  Enable_Schedule:"Jadvalni yoqish",
  Never:"Hech qachon",
  Select_Schedule:"Jadvallarni tanlang",
  No_Schedules:"Jadvallar yo'q",
  No_Schedules_Word:"Birinchi <a routerLink='/folder/Schedule/New'>Jadval</a>ni yarating.",
  New_Administrator:"Yangi administrator",
  Username:"Foydalanuvchi nomi",
  First_Name:"Ism",
  Last_Name:"Familiya",
  Add_an_Address:"Manzil qo'shing",
  Payment_Limit:"Har bir to'lov chegarasi",
  Total_Limit:"Umumiy chegara",
  Select_Accounts:"Hisoblar-ni tanlang",
  No_Administrators:"Administratorlar yo'q",
  No_Administrators_Word:"Birinchi <a routerLink='/folder/Administrator/New'>Administrator hisobini</a> yarating.",
  New_Administrators_Word:"Birinchi <a routerLink='/folder/Administrator/New'>Administrator</a> ni qo'shing",
  Cloud:"Bulut",
  Backup:"Zaxira",
  Enable_iCloud:"iCloud-ni yoqing",
  Enable_Google_Drive:"Google Drive-ni yoqing",
  Enable_Microsoft_OneDrive:"Microsoft OneDrive-ni yoqing",
  Automatically_Backup:"Avtomatik zaxiralash",
  FTP_Settings:"FTP sozlamalari",
  URL_File_Prompt:"Iltimos, import qilinadigan .xls / .xlsx / .json fayli manzilini belgilang:",
  URL_SQL_Prompt:"Import qilinadigan SQLite faylining joylashuvini belgilang:",
  FTP_Backup:"FTP zaxira nusxasi",
  FTP_Import:"FTP importi",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Xost",
  Port:"Port",
  Path:"Yo'l",
  Data_Path:"Ma'lumotlar yo'li",
  Enable_FTP_Account:"FTP hisobini yoqing",
  HostnameIP:"Xost nomi",
  Password:"Parol",
  Connection_Port:"Ulanish porti",
  Enable_MySQL_Database:"MySQL ma'lumotlar bazasini yoqing",
  Log:"Jurnal",
  Reset:"Qayta o'rnatish",
  Erase:"Oʻchirish",
  Export:"Eksport",
  Database:"Ma'lumotlar bazasi",
  Upload_CSV:"CSV yuklash",
  Download_CSV:"CSV-ni yuklab oling",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL zaxira nusxasi",
  Internal_Notes:"Ichki eslatmalar",
  Root_Path:"Ildiz yo'li",
  Select_Backup:"Zaxiralash-ni tanlang",
  Add_New_Backup:"Yangi zaxira nusxasini qo'shing",
  First_Backup:"Birinchi zaxira nusxasini o'rnating...",
  Backups:"Zaxira nusxalari",
  Add_Backup:"Zaxira qo'shish",
  No_Backups:"Hech qanday zaxira nusxalari topilmadi.",
  Select_Backup_Type:"O'rnatmoqchi bo'lgan zaxira turini tanlang...",
  Backup_Type:"Zaxira turi",
  FTP_Drive:"FTP drayveri",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Haydash",
  Microsoft_OneDrive:"Haydash",
  Import_Fields:"Import maydonlari",
  Import_Fields_Word:"<a routerLink='/folder/Holiday/New'>Import</a> ma'lumotlarini tanlash uchun ushbu bo'limdan foydalaning.",
  Upload:"Yuklash",
  Download:"Yuklab olish",
  Download_JSON:"JSON Data sifatida yuklab oling",
  Download_SQL:"SQL fayli sifatida yuklab oling",
  New_Bank:"Yangi bank",
  New_Account:"Yangi hisob",
  New_Bank_Account:"Yangi bank hisobi",
  Upload_Image:"Rasm yuklash",
  Bank_Name:"Bank nomi",
  Bank_Address:"Bank manzili",
  Branch_Address:"Filial manzili",
  Address_on_Cheque:"Manzil",
  Cheque_Numbers:"Raqamlarni tekshiring",
  Cheque_Details:"Tafsilotlarni tekshiring",
  Bank_Logo:"Bank logotipi",
  Cheque_ID:"ID tekshiring",
  Starting_Cheque_ID:"Tekshirish ID ni boshlash",
  Transit_Number:"Tranzit raqami",
  Institution_Number:"Tashkilot raqami",
  Designation_Number:"Belgilash raqami",
  Account_Number:"Hisob raqami",
  Limits:"Cheklovlar",
  Default_Limits:"Standart chegaralar",
  Over_Limit:"Limitdan ortiq",
  Under_Limit:"Limit ostida",
  Payroll_Limit:"Ish haqi limiti",
  Enable_Bank_Account:"Bank hisobini yoqing",
  Select_Account:"Hisobni tanlang",
  No_Bank_Account:"Bank hisobi yo'q",
  No_Bank_Account_Word:"Birinchi <a routerLink='/folder/Accounts/New'>Bank hisobini</a> qo'shing.",
  Bank_Accounts:"Bank hisoblari",
  No_Accounts:"Hisoblar yo'q",
  No_Accounts_Note:"Birinchi <a routerLink='/folder/Accounts/New'>Bank hisobini</a> qo'shing.",
  Cheque_Designer:"Dizaynerni tekshiring",
  Cheque_Design:"Dizaynni tekshiring",
  Select_Design:"Dizaynni tanlang",
  Cheque_Designs:"Dizaynlarni tekshiring",
  No_Cheque_Designs:"Tekshirish dizaynlari yo'q",
  No_Cheque_Designs_Word:"O'zingizning <a routerLink='/folder/Settings/Cheque/Design/New'>Chek dizayni</a> yarating.",
  Set_Default:"Standart sifatida sozlash",
  Default:"Standart",
  Remove:"O'chirish",
  Folder:"Jild",
  Edit:"Tahrirlash",
  LoadingDots:"Yuklanmoqda...",
  Add_a_New_File:"<a href='#' (click)='add()'>Yangi fayl</a> qo'shing",
  this_folder:"bu papka",
  FTP_Backup_Settings:"FTP zaxira sozlamalari",
  Secure_File_Transfer:"Xavfsiz fayl uzatish",
  New_Holiday:"Yangi bayram",
  Add_Holiday:"Bayram qo'shing",
  Holiday_Name:"Bayram nomi",
  Additional_Pay:"Qo'shimcha to'lov",
  Enable_Holiday:"Bayramni yoqish",
  Select_Holidays:"Dam olish kunlarini tanlang",
  No_Holidays:"Bayramlar yo'q",
  No_Holidays_Word:"Birinchi <a routerLink='/folder/Holiday/New'>Davlat bayramini</a> qo'shing.",
  New_Import:"Yangi import",
  Import_Data:"Ma'lumotlarni import qilish",
  Import_Data_Word:"Siz tanlagan fayl turini yoki yuklash usulini tanlang.<br /> Qoʻllab-quvvatlanadigan faylni yuklaganingizdan soʻng, fayldagi qaysi import qilingan maydonlar ilovaning qaysi parametriga mos kelishini tanlashingiz mumkin boʻladi.",
  Import_File:"Faylni import qilish",
  Link_To_File:"Faylga havola",
  Select_File:"Faylni tanlang",
  New_Moderator:"Yangi Moderator",
  Allow_Moderation:"Moderatsiyaga ruxsat berish",
  Create_Paycheques:"Paychexlarni yarating",
  Edit_Paycheques_and_Data:"Ish haqi va ma'lumotlarni tahrirlash",
  Destroy_Data_and_Paycheques:"Ma'lumotlar va to'lovlarni yo'q qiling",
  Bonus_Percentage:"Ish haqi ulushi",
  Fixed_Amount:"Ruxsat etilgan miqdor",
  Select_Moderator:"Moderatorni tanlang",
  No_Moderators:"Moderatorlar yo'q",
  Moderators:"Moderatorlar",
  Moderator_Account:"Birinchi <a routerLink='/folder/Administrator/New'>Moderator hisobini</a> yarating.",
  No_Moderators_Word:"Birinchi <a routerLink='/folder/Administrator/New'>Moderator</a>ni qo'shing.",
  Defaults:"Standartlar",
  Provide_Us_Info:"Ma'lumot bering",
  Setup_Your_Printer:"Printeringizni sozlang",
  Your_Company:"Sizning kompaniyangiz haqida",
  Company_Name:"Shirkat nomi",
  Currency:"Valyuta",
  Default_Currency:"Standart valyuta",
  Base_Monthly_Income:"Oylik daromad",
  Protection:"Himoya",
  App_Protection:"Ilova himoyasi",
  PIN_Code_Protection:"PIN kod himoyasi",
  App_Protection_Word:"Hisobingizni himoya qilishga yordam beradigan xavfsizlik usullarini yoqing.",
  PIN_Code:"PIN kod",
  Change_PIN:"PIN-kodni o'zgartiring",
  New_Password:"Yangi parol",
  Geofence_Protection:"Geo-devor himoyasi",
  Geofence_Area:"Maydonni o'rnatish",
  Distance:"Masofa",
  Setup_Now:"Hozir sozlash",
  Mile:"Milya",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Yuzni tanish",
  Face:"Yuz",
  Setup:"Sozlash; o'rnatish",
  Label:"Yorliq",
  Password_Protection:"Parol himoyasi",
  Modify_Password:"Parolni o'zgartirish",
  New_Tax_Entry:"Yangi soliq yozuvi",
  New_Tax:"Yangi soliq",
  Tax_Label:"Soliq yorlig'i",
  Perpetually_Enabled:"Doimiy yoqilgan",
  Select_Taxes:"Soliqlarni tanlang",
  Tax_Deductions:"Soliq imtiyozlari",
  No_Tax_Deductions:"Soliq chegirmalari yo'q",
  No_Tax_Deductions_Word:"Birinchi <a routerLink='/folder/Tax/New'>Soliq</a> chegirmasini qo'shing.",
  New_Timer:"Yangi taymer",
  Start:"Boshlash",
  Stop:"STOP",
  Start_Timer:"Taymerni ishga tushirish",
  Stop_Timer:"Taymerni to'xtatish",
  Timer_Active:"Taymer faol",
  Timer:"Taymer:",
  Timer_Running:"Taymer: (ishlayapti)",
  Save_Timer:"Taymerni saqlang",
  New_Timesheet:"Yangi vaqt jadvali",
  Select_Timesheets:"Vaqt jadvallarini tanlang",
  Work_Notes:"Ish yozuvlari",
  Entry_Title:"Kirish sarlavhasi",
  No_Timesheets:"Vaqt jadvallari yo'q",
  No_Timesheets_Word:"Birinchi <a routerLink='/folder/Timesheet/New'>Vaqt jadvalini</a> qo'shing.",
  Timesheet_Submitted:"Vaqt jadvali topshirildi",
  Timesheet_Congrats:"Tabriklaymiz! Vaqt jadvalingiz muvaffaqiyatli topshirildi. Rahmat!",
  Timesheet_Copy:"Hujjatlaringiz nusxasini olish uchun bizga elektron pochta manzilingizni va/yoki mobil telefon raqamingizni kiriting.",
  Submit:"Yuborish",
  Allow_Notifications:"Bildirishnomalarga ruxsat berish",
  Untitled_Entry:"Yangi kirish",
  Untitled_Bank:"Nomsiz bank",
  Timesheet_Entry:"Vaqt jadvaliga kirish",
  Work_Description:"Ish tavsifi",
  Enable_Timesheet:"Vaqt jadvalini yoqish",
  Submit_Timesheet:"Vaqt jadvalini yuborish",
  Vacation:"Dam olish",
  New_Vacation:"Yangi dam olish",
  Vacation_Name:"Dam olish nomi",
  Paid_Vacation:"Pullik ta'til",
  Vacation_Pay:"Dam olish to'lovi",
  Enable_Vacation:"Dam olish funksiyasini yoqing",
  Select_Vacations:"Dam olishlarni tanlang",
  No_Vacations:"Dam olish yo'q",
  No_Vacations_Word:"Birinchi <a routerLink='/folder/Vacation/New'>Dam olish</a> yozuvini yarating.",
  Payroll_Schedule:"Ish haqi jadvali",
  Next_Payroll:"Keyingi ish haqi:",
  Upcoming_Payroll:"Kelgusi ish haqi",
  No_Upcoming_Payroll:"Kelgusi ish haqi yo'q",
  Address_Book:"Manzillar kitobi",
  Archived_Documents:"Arxivlangan hujjatlar",
  Dev_Mode:"Dastur ishlab chiqish rejimida",
  Administrators:"Administratorlar",
  Privacy:"Maxfiylik",
  None:"Yo'q",
  Select_Signature:"Imzo-ni tanlang",
  No_Signatures:"Imzolar yo'q",
  No_Signatures_Word:"Birinchi <a routerLink='/folder/Signature/New'>Imzoni</a> qo'shing.",
  Repeat_Indefinitely:"Cheksiz takrorlang",
  Sign:"Imzo",
  Sign_Here:"Bu yerda imzolang",
  Date_Signed:"Imzolangan sana",
  Signature_Pad:"Imzo paneli",
  Account_Holder:"Hisob egasi",
  Account_Properties:"Hisob xususiyatlari",
  Name_On_Cheque:"Chekdagi ism",
  Server_Hostname:"Server Xost nomi / IP",
  Printers:"Printerlar",
  No_Printers:"Printerlar yo'q",
  Printer_Exists:"Bunday nomdagi printer allaqachon mavjud.",
  No_Printers_Word:"Birinchi <a routerLink='/folder/Printer/New'>Printer</a>ni qo'shing.",
  No_Printer_Alert:"Hech qanday printer aniqlanmagan. Printerni sozlashni xohlaysizmi?",
  Add_Printer:"Printer qo'shish",
  New_Printer:"Yangi printer",
  Printer_Hostname:"Printerning xost nomi / IP",
  Printer_Label:"Printer yorlig‘i",
  Printer_Protocol:"Printer protokoli",
  Protocol:"Protokol",
  Email_Print:"Elektron pochta",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Soket",
  Print_Job:"Chop etish ishi",
  Printed:"Chop etilgan",
  Not_Printed:"Chop etilmagan",
  Print_Jobs:"Chop etish ishlari",
  Print_Queue:"Chop etish navbati",
  No_Print_Jobs:"Chop etish ishlari yo‘q",
  No_Prints:"Chop etish uchun yangi <a routerLink='/folder/Cheque/New'>Chek</a> yarating.",
  Prints:"Chop etish",
  Find_Printer:"Printerni toping",
  Find_AirPrint_Devices:"AirPrint qurilmalarini toping",
  Select_Printer:"Printer-ni tanlang",
  System_UI:"Tizim foydalanuvchi interfeysi",
  Printer:"Printer",
  Status:"Holat",
  Preview:"Ko‘rib chiqish",
  Enable_Print_Job:"Chop etish vazifasini yoqish",
  Queue_Weight:"Navbatdagi vazn",
  Unlimited:"Cheksiz",
  Show_Advanced_Printer_Options:"Kengaytirilgan printer parametrlarini ko'rsatish",
  Advanced:"Murakkab",
  Location:"Manzil",
  Note:"Eslatma",
  Queue_Name:"Navbat nomi",
  Pages_Printed_Limit:"Chop etilgan sahifalar chegarasi",
  MultiPage_Idle_Time:"Ko'p sahifali kutish vaqti (lar)",
  Page_Count_Limit:"Sahifalar soni chegarasi",
  Page_Orientation:"Sahifa yoʻnalishi",
  Portrait:"Portret",
  Landscape:"Peyzaj",
  Duplex:"Ikki tomonlama",
  Double_Sided_Printing:"Ikki tomonlama",
  Duplex_Mode:"Dupleks rejimi",
  Duplex_Short:"Qisqa",
  Duplex_Long:"Uzoq",
  Duplex_None:"Yo'q",
  Color_And_Quality:"Rang va Sifat",
  Monochrome:"Monoxrom",
  Photo_Quality_Prints:"Sifatli fotosuratlar",
  Printer_Email:"Printer elektron pochta",
  Automatically_Downsize:"Avtomatik ravishda kichraytirish",
  Paper:"Qog'oz",
  Paper_Name:"Qog'oz nomi",
  Paper_Width:"Qog'oz kengligi",
  Paper_Height:"Qog'oz balandligi",
  Paper_Autocut_Length:"Qog'ozni avtomatik kesish uzunligi",
  Margins:"Chegaralar",
  Page_Margins:"Sahifa chetlari",
  Page_Margin_Top:"Yuqori sahifa cheti",
  Page_Margin_Right:"O'ng sahifa cheti",
  Page_Margin_Bottom:"Pastki sahifa cheti",
  Page_Margin_Left:"Chap sahifa cheti",
  Add_Employees:"Xodimlarni qo'shish",
  Header:"Sarlavha",
  Print_A_Page_Header:"Sahifa sarlavhasini chop etish",
  Header_Label:"Sarlavha yorlig'i",
  Header_Page_Index:"Sarlavha sahifasi indeksi",
  Header_Font_Name:"Sarlavha shrifti",
  Select_Font:"Shrift-ni tanlang",
  Font_Selector:"Shrift tanlash",
  Header_Font_Size:"Sarlavha shrifti",
  Header_Bold:"Sarlavha qalin",
  Header_Italic:"Sarlavha kursiv",
  Header_Alignment:"Sarlavhani tekislash",
  Left:"Chapga",
  Center:"Markaz",
  Right:"To'g'ri",
  Justified:"Oqlangan",
  Header_Font_Color:"Sarlavha rangi",
  Select_Color:"Rangni tanlang",
  Footer:"Altbilgi",
  Print_A_Page_Footer:"Sahifa altbilgisini chop etish",
  Footer_Label:"Altbilgi yorlig'i",
  Footer_Page_Index:"Altbilgi sahifasi indeksi",
  Footer_Font_Name:"Altbilgi shrifti",
  Fonts:"Shriftlar",
  Done:"Bajarildi",
  Select_Fonts:"Shriftlarni tanlang",
  Footer_Font_Size:"Altbilgi hajmi",
  Footer_Bold:"Bold kolontiter",
  Footer_Italic:"Altbilgi kursiv",
  Footer_Alignment:"Footer Alignment",
  Footer_Font_Color:"Altbilgi rangi",
  Page_Copies:"Sahifa nusxalari",
  Enable_Printer:"Printerni yoqish",
  Remote_Logging:"Masofaviy ro'yxatga olish",
  Log_Server:"Jurnal serveri",
  Encryption:"Shifrlash",
  Random_Key:"Tasodifiy kalit",
  Encryption_Key:"Shifrlash kaliti",
  Cheques_Webserver:"Shaxsiy ma'lumotlar bazasi",
  Learn_How:"Qanday qilib",
  Signature:"Imzo",
  Default_Printer_Unit:"d/sm/mm/(pt)",
  View_Signature:"Imzoni ko'rish",
  Printed_Signature:"Chop etilgan imzo",
  Digitally_Sign:"Raqamli imzo",
  Digital_Signature:"Raqamli imzo",
  Add_Signature:"Imzo qo'shish",
  Add_Your_Signature:"Imzo qo'shing",
  Enable_Signature:"Imzoni yoqish",
  Digitally_Signed:"Raqamli imzolangan",
  Insert_Error:"Maʼlumotlar bazasi bilan bogʻliq muammolar tufayli chekni saqlab boʻlmadi.",
  Delete_Confirmation:"Haqiqatan ham bu maʼlumotni oʻchirib tashlamoqchimisiz?",
  Discard_Confirmation:"Haqiqatan ham bu maʼlumotni oʻchirib tashlamoqchimisiz?",
  Discard_Bank_Confirmation:"Haqiqatan ham bu hisobni bekor qilmoqchimisiz?",
  Discard_Printer_Confirmation:"Haqiqatan ham bu printerni bekor qilmoqchimisiz?",
  Delete_Bonus_Confirmation:"Haqiqatan ham bu bonusni oʻchirib tashlamoqchimisiz?",
  Delete_Invoice_Confirmation:"Bu hisob-fakturani o‘chirib tashlamoqchimisiz?",
  Generated_Cheque:"Yaratilgan chek",
  Generated_Invoice:"Yaratilgan hisob-faktura",
  Schedule_Start_Time:"Jadvalni boshlash",
  Schedule_End_Time:"Jadvalning oxiri",
  New_Call:"Yangi qo'ng'iroq",
  No_Contacts:"Kontaktlar yoʻq",
  No_Contacts_Word:"Ma'murlar, moderatorlar, xodimlar va to'lovchilar kontakt sifatida ko'rinadi.",
  PDF_Subject:"moliya",
  PDF_Keywords:"ish haqi to'lovi PDF chek cheklari",
  Printer_Setup:"Printerni sozlash",
  Printer_Selection:"Printerni tanlash",
  New_Fax:"Yangi faks",
  New_Fax_Message:"Yangi faks xabari",
  Fax_Machine:"Faks apparati",
  Fax_Name:"Faks nomi",
  Fax_Email:"Faks elektron pochta",
  Fax_Number:"Faks raqami",
  Contents:"Tarkib",
  Fax_Body:"Sahifa tanasi",
  Header_Word:"Sarlavha:",
  Header_Text:"Sarlavha matni",
  Include_Header:"Sarlavhani qo'shing",
  Include_Footer:"Altbilgini qo'shing",
  Footer_Word:"Altbilgi:",
  Footer_Text:"Altbilgi matni",
  Attach_a_Cheque:"Chekni biriktiring",
  Add_Deduction:"Chegirma qo'shing",
  Enable_Fax:"Faks yuborish",
  Select_Fax:"Faks-ni tanlang",
  No_Faxes:"Fakslar yo'q",
  Faxes:"Fakslar",
  Save_and_Send:"Faks yuborish",
  Save_and_Pay:"Saqlash va to'lash",
  WARNING:"OGOHLANTIRISH:",
  Remember:"Eslab qoling",
  Printing:"Chop etish",
  Printing_Complete:"Chop etish tugallandi!\n\n",
  of:"ning",
  There_Were:"Lar bor edi",
  Successful_Prints:"muvaffaqiyatli chop etish va",
  Unsuccessful_Prints:"muvaffaqiyatsiz chop etish.",
  PrinterError:"Kechirasiz! Xatolik yuz berdi.",
  Printing_:"Chop etilmoqda...",
  PrinterSuccess:"Hujjat muvaffaqiyatli chop etildi.",
  PrintersSuccess:"Hujjatlar muvaffaqiyatli chop etildi.",
  New_Message:"Yangi xabar",
  New_Messages:"Yangi xabarlar",
  Read_Message:"Xabarni o'qish",
  Write_Message:"Xabar yozish",
  Send_Message:"Xabar yuborish",
  Subject:"Mavzu",
  Message:"Xabar",
  Writing:"Yozilmoqda...",
  Send:"Yuborish",
  Set_Date:"Sana belgilash",
  Set_Time:"Vaqtni belgilash",
  Recieve:"Qabul qilish",
  Set_as_Default:"Standart sifatida sozlash",
  Default_Account:"Standart hisob",
  Default_Design:"Standart dizayn",
  Multiple_Cheques:"Uchta tekshirish",
  Account_Mode:"Hisob rejimi",
  Multiple_Cheques_Description:"Har bir sahifada uchta tekshiruv.",
  Check_and_Table:"Tekshirish va jadval",
  Check_including_Table:"Buxgalteriya jadvali bilan tekshiring.",
  Check_Mailer:"Mailerni tekshiring",
  Check_Mailer_Window:"Manzil oynasi bilan tekshiring.",
  DocuGard_Table_Top:"Katta chek va stol",
  DocuGard_Table_Middle:"Katta chek va stol (o'rta)",
  DocuGard_Table_Bottom:"Katta chek va stol (pastki)",
  DocuGard_Mailer_Top:"Katta chekli pochta",
  DocuGard_Mailer_Middle:"Katta chekli pochta jo'natuvchisi (o'rta)",
  DocuGard_Mailer_Bottom:"Katta chekli pochta (pastki)",
  DocuGard_Three_Cheques:"Uchta katta chek",
  DocuGard_Cheque_Top:"Yagona katta chek",
  DocuGard_Cheque_Middle:"Katta chek (O'rta)",
  DocuGard_Cheque_Bottom:"Katta chek (pastki)",
  DocuGard_Three_Cheques_Window:"Bitta sahifada uchta tekshiruv.",
  DocuGard_Table_Top_Window:"Chek va daromad jadvali.",
  DocuGard_Table_Middle_Window:"Chek va daromad jadvali.",
  DocuGard_Table_Bottom_Window:"Chek va daromad jadvali.",
  DocuGard_Mailer_Top_Window:"Chek, jadval va manzil.",
  DocuGard_Mailer_Middle_Window:"Chek, jadval va manzil.",
  DocuGard_Mailer_Bottom_Window:"Chek, jadval va manzil.",
  DocuGard_Cheque_Top_Window:"Xavfsiz qog'oz mavjudligini tekshiring.",
  DocuGard_Cheque_Middle_Window:"Xavfsiz qog'oz mavjudligini tekshiring.",
  DocuGard_Cheque_Bottom_Window:"Xavfsiz qog'oz mavjudligini tekshiring.",
  Basic_Cheque:"Yagona tekshirish",
  Basic_Cheque_Print:"Bitta chekni chop eting.",
  Error_Setting_As_Paid:"Pulli sifatida sozlashda xato",
  Add_Attachment:"Qo'shiq qo'shish",
  PrinterUnavailable:"Printer mavjud emas",
  CreditCardComponent:"Kartalar",
  PaypalComponent:"PayPal",
  InteracComponent:"Interak",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Yangi depozit",
  Deposits:"Depozitlar",
  No_Deposits:"Depozitsiz",
  Credit_Card_Deposit:"Kredit kartasi",
  New_Credit_Card_Deposit_Message:"Kredit karta depoziti",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin depoziti",
  New_Interac_Deposit:"Interak",
  New_Interac_Deposit_Message:"Interak uzatish",
  Payments_Limit:"To'lov chegarasi",
  No_Payment_Limit:"To'lov chegarasi yo'q",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal depoziti",
  No_Deposits_Word:"Birinchi kompaniya <a routerLink='/folder/Deposit/New'>Depozit</a>ni qo'shing.",
  No_Documents_Specified:"Chop etish uchun hech qanday hujjat belgilanmagan",
  No_Printers_Added:"Hech qanday printer topilmadi. Qo'shish uchun Sozlamalar > Printerlar-ga o'ting.",
  DB_Erase_Prompt:"Butun ma'lumotlar bazasi butunlay o'chirilsinmi? OGOHLANTIRISH: Siz barcha saqlangan ma'lumotlarni yo'qotasiz!",
  Console_Warning:"Ushbu konsolga hech qanday matn joylashtirmang. Siz o'zingizni va/yoki kompaniyangizni jiddiy xavf ostiga qo'yishingiz mumkin.",
  No_Faxes_Word:"Birinchi <a routerLink='/folder/Fax/New'>Faks</a>ni yarating.",
  Cheque_Delete_Confirmation:"Haqiqatan ham bu chekni oʻchirib tashlamoqchimisiz?",
  Design_Delete_Confirmation:"Bu dizaynni oʻchirib tashlamoqchimisiz?",
  Cheque_Pay_Confirmation:"Bu chek toʻlangan deb belgilansinmi? U chop etish navbatida ko'rinmaydi.",
  Payment_Pay_Confirmation:"Bu toʻlov toʻlangan deb belgilansinmi? U tekshirish navbatida ko'rinmaydi.",
  Delete_Deduction_Confirmation:"Haqiqatan ham bu chegirmani oʻchirib tashlamoqchimisiz?",
  Delete_Job_Confirmation:"Haqiqatan ham bu ishni oʻchirib tashlamoqchimisiz?",
  Delete_Timesheet_Confirmation:"Haqiqatan ham bu vaqt jadvalini oʻchirib tashlamoqchimisiz?",
  Delete_Schedule_Confirmation:"Haqiqatan ham bu jadvalni oʻchirib tashlamoqchimisiz?",
  Delete_Setting_Confirmation:"Haqiqatan ham bu sozlamani asliga qaytarmoqchimisiz?",
  Delete_Fax_Confirmation:"Haqiqatan ham bu faksni oʻchirib tashlamoqchimisiz?",
  Delete_File_Confirmation:"Haqiqatan ham bu faylni oʻchirib tashlamoqchimisiz?",
  Delete_Vacation_Confirmation:"Haqiqatan ham bu taʼtilni oʻchirib tashlamoqchimisiz?",
  Delete_Printer_Confirmation:"Haqiqatan ham bu printerni oʻchirib tashlamoqchimisiz?",
  Remove_Design_Confirmation:"Bu dizaynni oʻchirib tashlamoqchimisiz?",
  Delete_Payroll_Confirmation:"Haqiqatan ham bu ish haqini oʻchirib tashlamoqchimisiz?",
  Send_Fax_Email_Confirmation:"Ushbu hujjatlarni faks va elektron pochta orqali yubormoqchimisiz?",
  Send_Email_Confirmation:"Ushbu hujjatni elektron pochta orqali yubormoqchimisiz?",
  Send_Fax_Confirmation:"Ushbu hujjatni faks orqali yubormoqchimisiz?",
  Error_Generating_PDF:"Kechirasiz. Ushbu hujjatni yaratishda xatolik yuz berdi.",
  PDF_Error_Saving_Image:"Kechirasiz. Ushbu hujjatning PDF tasvirini saqlashda xatolik yuz berdi.",
  Test_Printer_Confirmation:"Ushbu printerda sinov sahifasini chop qilmoqchimisiz?",
  Save_Timesheet_Prompt:"Saqlash uchun “Sarlavha” qo‘shing yoki “Start timer” tugmasini bosing.",
  Remove_Geofence_Prompt:"Haqiqatan ham bu geo-devor joylashuvini olib tashlamoqchimisiz?",
  Delete_Employee_Confirmation:"Haqiqatan ham oʻchirib tashlamoqchimisiz",
  Fire_Employee_Confirmation:"Haqiqatan ham olov yoqmoqchimisiz"
}