export const Xh = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Ilungelo lokushicilela & ikopi; 2023",
  black:"Mnyama",
  green:"Luhlaza",
  gold:"Igolide",
  blue:"Luhlaza",
  brown:"Ntsundu",
  purple:"Mfusa",
  pink:"Pinki",
  red:"Bomvu",
  Swatches:"Iiswatshi",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Hue",
  Saturation:"Ukwaneliseka",
  Lightness:"Ukukhanya",
  Upgrade_To_Pro:"Nyukela kwiPro",
  AllFeaturesInclude:"Zonke iimpawu zibandakanya:",
  PrintUnlimitedCheques:"Shicilela iitshekhi ezingenamkhawulo",
  PremiumChequeDesigns:"Uyilo lwePremium Check",
  ManageUnlimitedEmployees:"Lawula abaSebenzi abangenamkhawulo",
  AddUnlimitedPayees:"Yongeza iiPayees ezingenamkhawulo",
  CreateUnlimitedPayrolls:"Yenza iiPayrolls ezingenamkhawulo",
  UnlimitedSchedulesandTimeSheets:"IiShedyuli ezingenamkhawulo kunye neeShethi zeXesha",
  BulkPayPalPayouts:"Iintlawulo ezininzi ze-PayPal",
  UnlimitedBankAccounts:"Iiakhawunti zeBhanki ezingenamkhawulo",
  ManageMultipleCompanies:"Lawula iiNkampani ezininzi",
  TrackInsurancePolicies:"Track Imigaqo-nkqubo ye-Inshurensi",
  Bio_MetricProtection:"Ukhuseleko lweBio-Metric",
  Geo_FenceLock_OutProtection:"Geo-Fence Lock-Out Protection",
  Multiple_Companies_Word:"Ukulawula iinkampani ezininzi akufumaneki ngaphandle kwe-Checks premium.",
  PayPal_Payouts_Word:"Iintlawulo ze-PayPal zivaliwe ngaphandle kwe-Checks premium.",
  PINProtection:"Ukhuseleko lwe-PIN",
  PasswordProtection:"Ukhuseleko Lwegama Lokugqithisa",
  May_Require_Approval:"Isenokufuna imvume.",
  Subscribe:"Bhalisa",
  Billed:"Ihlawulwe",
  Up:"Phezulu",
  Down:"Phantsi",
  Positioning:"Ukuma",
  Marker:"Isiphawuli",
  Drag_Marker:"Tsala isiphawuli",
  Tagline:"Shicilela iitshekhi kunye neTheyibhile yoMvuzo",
  Marker_Word:"Sebenzisa iziphawuli ubungakanani bento.",
  Pinch_Zoom:"Pinch Zoom",
  Pinch_Word:"Ncinza ukusondeza isiqalelo.",
  Drag:"Tsala",
  Drag_Word:"Sebenzisa umnwe wakho ukutsala izinto.",
  subscription_alias_word:"Ukuhlaziya Okuzenzekelayo Umrhumo",
  premium_alias_word:"Ipakethe yoPhuculo lwexesha elinye",
  print_alias_word:"Shicilela-Phatha iitshekhi zomntu ngamnye",
  mode_alias_word:"Imowudi",
  Pro:"UPro",
  Pro_word:"Inguqulelo yePro iyafuneka.",
  Cant_Delete_Default_Company_Word:"Uxolo, awukwazi ukucima inkampani yakho engagqibekanga.",
  Reinsert_Default_Designs:"Faka kwakhona uYilo oluMiselweyo",
  Reinsert_Default_Designs_word:"Ngaba uyafuna ukufaka kwakhona uyilo oluhlala lukhona?",
  Subscription_Active_Disable_Word:"Olu rhumo luyasebenza. Ngaba ungathanda ukurhoxisa umrhumo kwiiCheques?",
  Company_Logo:"ILogo yeNkampani",
  ZERO_:"UNOTHI",
  Disclaimer:"Ukuzihlangula",
  Privacy_Policy:"Umthetho wabucala",
  EULA:"Ijonga i-EULA",
  Terms_Of_Service:"Imimmisele yenkonzo",
  Terms_Of_Use:"Imimmiselo yokusebenzisa",
  Refunds:"Ipolisi yembuyekezo",
  Single_Print:"1 Jonga",
  Two_Prints:"2 Iitshekhi",
  Five_Prints:"5 Iitshekhi",
  Ten_Prints:"Iitshekhi ezili-10",
  Fifteen_Prints:"15 Iitshekhi",
  Twenty_Prints:"20 Iitshekhi",
  Thirty_Prints:"30 Iitshekhi",
  Image_Added:"Umfanekiso Wongeziwe",
  Image_Preview:"Imboniso yomfanekiso",
  No_Image_Was_Selected:"Akukho mfanekiso ukhethiweyo.",
  Cheques_Unlimited:"Iitshekhi Unlimited",
  _Subscription:"Umrhumo",
  Subscription:"Iitshekhi - Inyanga e-1",
  Two_Month_Subscription:"Iitshekhi - Iinyanga ezi-2",
  Three_Month_Subscription:"Iitshekhi - Iinyanga ezi-3",
  Six_Month_Subscription:"Iitshekhi - Iinyanga ezi-6",
  Twelve_Month_Subscription:"Iitshekhi - Iinyanga ezili-12",
  Cheques_Are_Available:"Iitshekhi ziyafumaneka ukuze ziprintwe.",
  Cheques_Thank_You:"Enkosi kakhulu ngokuthenga kwakho iitshekhi! Izinto ozithengayo ngoku ziyasebenza.",
  Upgrade_Required_Two:"Khetha ipakethe kwaye ucofe kabini kwiqhosha lexabiso ukuqalisa ukuthenga kwakho. Shicilela iitshekhi ezinemibala epheleleyo ngemizuzwana.",
  Month:"Inyanga",
  Due:"Kufuneka:",
  Expires:"Iphelelwa lixesha:",
  Subscription_Active:"Umrhumo uyasebenza",
  Subscription_Inactive:"Umrhumo awusebenzi",
  Purchase_Additional_Cheques:"Ukuthenga iitshekhi ezongezelelweyo?",
  Printable_Cheque:"Itshekhi eprintwayo",
  Printable_Cheques:"Iitshekhi eziprintwayo",
  Printable_Cheque_Word:"itshekhi iyafumaneka kwiakhawunti yakho.",
  Printable_Cheques_Word:"iitshekhi ziyafumaneka kwiakhawunti yakho.",
  Max_Amount_Message:"Isixa osikhankanyileyo sifikelele kubuninzi bemali ebekiweyo kule nkqubo:",
  Terms_Required_Word:"Kufuneka usivume esi sivumelwano phambi kokuba uqhubeke usebenzisa iitshekhi.",
  Subscriptions:"Imirhumo",
  Product_Upgrades:"Uphuculo",
  Mailed_Out_Cheques:"Iitshekhi ezithunyelwe ngeposi",
  Enter_A_Company_Name:"Nceda ngenisa igama lenkampani.",
  Single_Cheques:"Iitshekhi ezilodwa",
  Cheque_Golden:"Itshekhi yegolide",
  Cheque_Golden_Window:"Uyilo lwetshekhi yegolide.",
  Cheque_Green:"Itshekhi eluhlaza",
  Cheque_Green_Window:"Uyilo lwetshekhi eluhlaza.",
  Cheque_Red:"Itshekhi ebomvu",
  Cheque_Red_Window:"Uyilo lwetshekhi ebomvu.",
  Cheque_Yellow:"Jonga umthubi",
  Cheque_Yellow_Window:"Uyilo lwetshekhi etyheli.",
  Cheque_Statue_of_Liberty:"Isitatyu senkululeko",
  Cheque_Statue_of_Liberty_Window:"Uyilo lwetshekhi loMfanekiso weNkululeko.",
  Cheque_Green_Wave:"Amaza aluhlaza",
  Cheque_Green_Wave_Window:"Uyilo lwetshekhi eluhlaza.",
  Cheque_Golden_Weave:"I-Golden Weave",
  Cheque_Golden_Weave_Window:"Uyilo oluhle lwetshekhi yegolide.",
  Cheque_Green_Sun:"Ilanga eliluhlaza",
  Cheque_Green_Sun_Window:"Uyilo lwetshekhi enzulu kunye nokuzola.",
  Cheque_Blue_Checkers:"Blue Checkers",
  Cheque_Blue_Checkers_Window:"Uyilo lwetshekhi eluhlaza okwesibhakabhaka.",
  Cashiers_Check:"Itshekhi yeCashier",
  Cashiers_Check_Window:"Cashier's Khangela itemplate yesimbo.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Uyilo lwetshekhi lwe-Aqua.",
  Cheque_Golden_Checkers:"Golden Checkers",
  Cheque_Golden_Checkers_Window:"Uyilo lwetshekhi yegolide.",
  Upgrade_Unavailable:"Uphuculo alufumaneki",
  Bank_Code_Protection:"Ukukhuselwa kweNombolo yeBhanki",
  Bank_Code_Protection_Word:"Khusela iinombolo zakho zebhanki zingasetyenziswa kule app esebenza kwesinye isixhobo okanye komnye umsebenzisi. Esi senzo AKUBUYEKELWA. Qhubeka?",
  Bank_Code_Protection_Blocked_Word:"Iinombolo zebhanki ozama ukuzisebenzisa zibekelwe omnye umsebenzisi okanye isixhobo.",
  Bank_Code_Protection_Error_Word:"Ukuqinisekiswa kwenombolo akuphumelelanga. Nceda uqhagamshele kwi-intanethi kwaye uzame ukufaka le akhawunti yebhanki kwakhona.",
  Bank_Code_Protection_Set_Error_Word:"Ukukhuselwa kwenombolo yebhanki kufuna ukuba uqhagamshelwe kwi-intanethi. Nceda uqhagamshele kwaye uzame kwakhona.",
  Upgrade_Unavailable_Word:"Uxolo, sinengxaki ekukuqinisekiseni. Imirhumo kunye nophuculo lweChecks ayifumaneki ngoku ukuba ithengwe kwindawo yakho.",
  PayPal_Payment_Preview:"PayPal Payment Preview",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Khetha i-PayPal",
  PayPal_Applications:"Izicelo zePayPal",
  Purchase_With_Apple_Pay:"Thenga ngeApple Pay",
  Google_Pay:"Google Pay",
  Companies:"Iinkampani",
  Insurance:"I-inshurensi",
  New_PayPal:"I-PayPal entsha",
  Pay_By:"Hlawula Nge",
  Insure:"I-inshurensi",
  Miles:"Iimayile",
  Payment_Method:"Uhlobo lokuhlawula",
  Select_Policies:"Khetha Imigaqo-nkqubo",
  Policies:"Imigaqo-nkqubo",
  Policy:"Umgaqo-nkqubo",
  No_PayPal_Account:"Akukho Akhawunti ye-PayPal",
  No_Policies:"Akukho migaqo-nkqubo ye-Inshurensi",
  New_Policy:"Umgaqo-nkqubo omtsha",
  PayPal_Payment:"Intlawulo yePayPal",
  PayPal_Payments:"Iintlawulo zePayPal",
  No_Payment_Selected:"Akukho Ntlawulo Ikhethiweyo",
  Sending_Payment_Word:"Nceda ulinde... Le ntlawulo iyathunyelwa.",
  Sending_Payments_Word:"Nceda ulinde... Iintlawulo ziyathunyelwa.",
  No_Payment_Selected_PayPal:"Akukho <a routerLink='/folder/Payments'>Intlawulo yePayPal</a> ekhethiweyo ukuba ithunyelwe.",
  Payment_Sent:"Intlawulo ithunyelwe",
  PayPal_Payment_Sent:"Le ntlawulo ithunyelwe ngePayPal.",
  Copay:"Copay",
  Dead:"Ufile",
  Alive:"Uyaphila",
  Not_Dead:"Akufanga",
  Unclaimed:"Akubangwanga",
  Attempted:"Ndizamile",
  Deceased:"Uswelekile",
  Claimed:"Ibango",
  Unpaid:"Engahlawulwanga",
  Sending_Payment:"Ukuthumela Intlawulo",
  Sending_Payments:"Ukuthumela iiNtlawulo",
  Send_PayPal_Confirmation:"Ngaba uyafuna ukuthumela le ntengiselwano ngePayPal?",
  Send_PayPal_Confirmation_Word:"Cofa iqhosha eliluhlaza ukuthumela le ntengiselwano.",
  Save_Payment_As_Unpaid:"Gcina le ntlawulo njengengekahlawulwa?",
  Payment_Pay_Confirmation_PayPal:"Gcina le ntlawulo njengoko ihlawulwe?",
  No_Policies_Word:"Yongeza eyokuqala <a routerLink='/folder/Postage/New'>Polisi ye-Inshurensi</a> ngoku.",
  Timesheet_Multiple_Delete_Confirmation:"Ngaba uqinisekile ukuba ufuna ukucima amaxesha amaninzi?",
  Select_Multiple_Timesheets_Prompt:"Akukho maphepha exesha akhethiweyo. Khetha nokuba linye iphepha lexesha.",
  Select_Multiple_Policies_Prompt:"Akukho migaqo-nkqubo ekhethiweyo. Khetha ubuncinane ipolisi ye-inshurensi enye.",
  Policies_Multiple_Delete_Confirmation:"Uqinisekile ufuna ukucima iinkqubo ezininzi?",
  Company:"Inkampani",
  Add_Company:"Yongeza iNkampani",
  New_Company:"Yongeza iNkampani",
  No_Companies:"Akukho Nkampani",
  Enable_Company:"Yenza iNkampani isebenze",
  Select_Company:"Khetha Inkampani",
  The_Default_Company:"Ileyibhile yenkampani engagqibekanga.",
  Manage_Companies:"Lawula iiNkampani",
  No_Companies_Word:"Iitshekhi ziya kusebenzisa inkampani engagqibekanga.<br />Yongeza <a routerLink='/folder/Company/New'>Inkampani yokuqala</a>.",
  Default_Company:"Inkampani ehlala ikho",
  Cheques_Unlimited_Word:"Iitshekhi ezingenamkhawulo zikuvumela ukuba uprinte iitshekhi ezininzi njengoko uthanda.",
  Cheques_Subscription_Word:"Umrhumo weetshekhi ikuvumela ukuba uprinte iitshekhi ezininzi njengoko uthanda.",
  You_Own_This_Product:"Umnikazi wale mveliso.",
  Your_Subscription_is_Active:"Umrhumo wakho uyasebenza.",
  Active_Products:"Iimveliso ezisebenzayo",
  Purchase_Confirmation:"Thenga",
  Purchase_Cheques:"Iitshekhi zokuthenga",
  Restore_Purchase:"Buyisela Ukuthenga",
  Erase_Purchase:"Cima Ukuthenga",
  Successfully_Purchased:"Ithengwe ngempumelelo",
  Enter_Your_Licence_Key:"Nceda ngenisa isitshixo sakho selayisensi kweli phepha ukuze uvule le mveliso.",
  Licence_Key:"Isitshixo selayisensi",
  Enter_Licence_Key:"Ngenisa iqhosha lelayisensi",
  Purchased:"Ithengiwe",
  Enable_Feature:"Vulela Uphawu",
  Cancel_Subscription:"Rhoxisa uMrhumo",
  Subscription_Removed:"Umrhumo ususiwe",
  Select_Active_Subscription:"Khetha umrhumo osebenzayo ukuze uyiguqule.",
  Remove_Subscription_Word:"Uqinisekile ufuna ukulurhoxisa umrhumo?",
  Delete_Company_Confirmation:"Uqinisekile ufuna ukuyicima yonke le nkampani? ISILUMKISO: Uya kuphulukana nalo lonke ulwazi olugciniweyo!",
  Delete_Default_Company_Word:"Awukwazi ukucima inkampani engagqibekanga. Ngaba ungathanda ukuseta kwakhona inkqubo kwaye uyibuyisele kwimeko emiselweyo? ISILUMKISO: Uya kuphulukana nalo lonke ulwazi olugciniweyo!",
  Console_Warning_2:"Sukuphatha nayiphi na imali usebenzisa olusetyenziso olungelulo olwakho ngoku.",
  Terms_and_Conditions:"Imimmiselo nemiqathango",
  and_the:"kwaye i",
  for:"ngenxa",
  Please_Read_Word:"Nceda ufunde kwaye uvumelane ne",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Amanye amazinga oguqulo lwemali akafunyenwanga. Nceda uqhagamshele kwi-intanethi.",
  Free:"Mahala",
  DB_Erase_Prompt_2:"Ucima ngokupheleleyo yonke idatabase yomphuhlisi? ISILUMKISO: Uya kuphulukana nalo lonke ulwazi lwe-PurCHASE kunye ne-SUBSCRIPTION!",
  Successfully_Imported:"Ithathwe ngaphandle ngempumelelo",
  Select_Postage:"Khetha iPosi",
  No_Postage:"Akukho Izitampu zokuposa",
  No_Paid_Postage_Word:"Yongeza eyokuqala <a routerLink='/folder/Postage/New'>Isitampu sokuPosa esiHlawulweyo</a>.",
  Trial_Complete:"Uvavanyo Lugqityiwe",
  Please_Upgrade:"Nceda uphucule iitshekhi ukuze uqhubeke nokushicilela.",
  Aa:"Aa",
  Color:"Umbala",
  Font:"Ifonti",
  Guide:"Isikhokelo",
  Guides:"Abakhokelayo",
  Image:"Umfanekiso",
  Zoom:"Sondeza",
  Logo:"Ilogo",
  Bank:"Ibhanki",
  MICR:"MICR",
  Total:"Iyonke",
  Cancel:"Rhoxisa",
  Confirm:"Qinisekisa",
  Method:"Indlela",
  Biometric_Security:"Ukhuseleko lwe-Bio-metric",
  Please_Login_To_Continue:"Nceda ungene ukuze uqhubeke.",
  Complete:"Gqibezela",
  Sign_Up:"Bhalisa",
  Error:"Impazamo",
  Biometrics:"I-Bio-metrics",
  Percent:"Ipesenti",
  Zero_Percent:"0%",
  Top_Margin:"Umjikelo oPhezulu",
  Bottom_Margin:"Umda ongezantsi",
  Left_Margin:"Umda wasekhohlo",
  Right_Margin:"Umda wasekunene",
  MICR_Margin:"Umda weMICR",
  Table_Margin:"Umda wetheyibhile",
  Address_Margin:"Umda wedilesi",
  Percentage_:"Ipesenti",
  Vacation_Title:"Isihloko Seholide",
  Use_PIN:"Sebenzisa i-PIN",
  Loading__:"Iyalayisha...",
  Design_Title:"Isihloko soyilo",
  Authorize:"Gunyazisa",
  Key:"Isitshixo",
  Public_Key:"Isitshixo sikawonke-wonke",
  Private_Key:"Isitshixo saBucala",
  Authenticate:"Qinisekisa",
  Last_Payroll:"Umvuzo wokugqibela",
  Last_Calculation:"Ubalo lokugqibela",
  Authorized:"Igunyazisiwe",
  Geo_Authorized:"I-Geo-Indawo: iGunyaziwe",
  Not_Authorized:"AyiGunyazwanga",
  Authorization_Complete:"Ugunyaziso lugqityiwe",
  Geolocation_Authorization:"Geo-indawo ugunyaziso",
  Out_of_Bounds:"Ngaphandle kwemida",
  Cant_Delete_Default_Design:"Ayinakucima uyilo olungagqibekanga.",
  Unauthorized_Out_of_Bounds:"Ngokungagunyaziswanga: Ngaphandle kwemida",
  Biometric_Authorization:"Ugunyaziso lweBio-metric",
  Locating_Please_Wait:"Iyafumana, Nceda ulinde...",
  Test_Biometrics:"Vavanya i-Bio-metrics",
  Cheque_Margins:"Jonga imida",
  Percentage_Full_Error:"Indawo yepesenti ayinakumiselwa ngaphezulu kwe-100% yepesenti.",
  No_Payroll_Text:"Yongeza i <a routerLink='/folder/Employee/New'>Umqeshwa</a> okanye <a routerLink='/folder/Payee/New'>Ohlawulwayo</a> kunye <a routerLink='/folder/Schedule/New'>Shedyuli</a>.",
  Design_Saved:"Uyilo lugciniwe",
  Default_Design_Selected:"Uyilo oluMiselweyo olukhethiweyo",
  Partial_Import:"Ukungeniswa ngaphandle ngokuyinxenye",
  Partial_Export:"Ukuthunyelwa kwamanye amazwe ngokuyinxenye",
  Entire_Import:"Ngenisa ngaphandle ngokupheleleyo",
  Entire_Export:"Ukuthunyelwa ngaphandle ngokupheleleyo",
  Existing_Password:"Nceda ngenisa igama lokugqithisa elikhoyo:",
  Existing_PIN:"Nceda ufake ikhowudi yakho ye-PIN esele ikhona:",
  Pin_Change_Header:"I-PIN isiqinisekiso",
  Pin_Change_SubHeader:"Faka inombolo yakho ye-PIN endala ukuze uqinisekise utshintsho.",
  Pass_Change_Header:"Ukuqinisekiswa Kwegama Lokugqithisa",
  Pass_Change_SubHeader:"Faka igama lokugqithisa lakho elidala ukuze uqinisekise utshintsho.",
  PIN_Enter_Message:"Faka i-PIN yakho ukuze uqinisekise.",
  Password_Enter_Message:"Faka igama lokugqithisa lakho ukuze uqinisekise.",
  Pin_Updated_Success:"I-PIN ihlaziywe ngempumelelo!",
  Pin_Updated_Fail:"I-PIN ayikwazanga ukuhlaziywa.",
  Pass_Updated_Success:"Igama lokugqithisa lihlaziywe ngempumelelo.",
  Pass_Updated_Fail:"Igama lokugqithisa alikwazanga ukuhlaziywa.",
  Preview_Payment:"Intlawulo yokujonga kwangaphambili",
  Preview_Payroll:"Jonga uMvuzo",
  No_Payments_Created:"Akukho ntlawulo zifunyenweyo ukuba zenziwe.",
  Payment_Preview:"Ukujongwa kweNtlawulo",
  Enable_Payee:"Vula uMbhayi",
  Rendered:"Inikezelwe",
  No_Email:"Akukho imeyile",
  Setup_Cheques:"Iitshekhi zokumisela",
  name:"Igama",
  address:"Idilesi",
  address_2:"Umgca wedilesi yesi-2",
  city:"Isixeko",
  province:"Iphondo",
  postal_code:"Ikhowudi / ZIP yePosi",
  country:"Ilizwe",
  username:"Igama lomsebenzisi",
  full_name:"Igama eliphelele",
  birthday:"Usuku lokuzalwa",
  email:"I-imeyile",
  phone:"Ifowuni",
  employees:"Abasebenzi",
  addresses:"Iidilesi",
  payment_amount_limit:"Intlawulo yoMda woMyinge",
  total_limit:"Umda opheleleyo",
  payees:"Abahlawulwayo",
  description:"Inkcazo",
  address_line_one:"Umgca wedilesi wokuqala",
  address_line_two:"Umgca wedilesi yesiBini",
  image:"Umfanekiso",
  account_holder:"Umnikazi we-akhawunti",
  cheque_starting_id:"Jonga i-ID yokuQalisa",
  transit_number:"Inombolo yokuhamba",
  institution_number:"Inombolo yeziko",
  designation_number:"Inombolo yobizo",
  account_number:"Inombolo ye-akhawunti",
  currency:"Imali",
  signature:"Umsayino",
  payment_payroll_limit:"Umda weNtlawulo",
  total_limi:"Umlinganiselo opheleleyo",
  date:"Umhla",
  printed_memo:"IMemo eprintiweyo",
  banks:"Iibhanki",
  signature_image:"Umfanekiso womsayino",
  address_name:"Igama ledilesi",
  notes:"Amanqaku",
  design:"Yila",
  title:"Isihloko",
  frequency:"Ukuphindaphinda",
  fax:"Ifeksi",
  salaries:"Imivuzo",
  salary_ids:"Izazisi zoMvuzo",
  start_time:"Ixesha Lokuqalisa",
  end_time:"Ixesha lokuphela",
  paid:"Ihlawulwe",
  overtime_percentage:"Ipesenti ehlawulwayo",
  overtime_amount:"Imali eHlawulweyo eZinzileyo",
  first_name:"Igama lakho",
  last_name:"Ifani",
  moderation:"Ukumodareyitha",
  create:"Yenza",
  edit:"Hlela",
  destroy:"Tshabalalisa",
  day_start_time:"Usuku_lokuqala_ixesha",
  day_end_time:"Usuku_lokuphela_ixesha",
  fullname:"Igama",
  time:"Ixesha",
  auto_send:"Thumela ngokuzenzekela",
  time_method:"Indlela Yexesha",
  schedules:"Iishedyuli",
  indefinite_payroll_enabled:"Yenza ngokungenasiphelo",
  amount:"Isixa",
  repeat:"Phinda",
  always_enabled:"Ihlala Isebenza",
  start_date:"Umhla wokuqala",
  end_date:"Umhla wokuphela",
  Cheque_Total:"Jonga Iyonke",
  Total_Amount:"Imali iyonke:",
  Amounts:"Izixa:",
  Images:"Imifanekiso",
  Files:"Iifayile",
  Previewing:"Ukujonga kwangaphambili:",
  Insert:"Faka",
  Preview_Import:"Imboniso yokungeniswa ngaphandle",
  Entry:"Ukungena",
  Entries:"Amangeno",
  No_Entries:"Akukho maNgeno",
  Import_Type:"Uhlobo lokuthatha ngaphandle",
  Select_Details:"Khetha Iinkcukacha",
  Select_Payee:"Khetha uMhlawulwa",
  Enable_Holidays:"Vumela iiHolide",
  Disable_Holidays:"Khubaza iiHolide",
  Wire_Transfer:"Udluliselo ngocingo",
  New_Export:"Ukuthunyelwa kwangaphandle okutsha",
  Export_Data:"Thumela iData",
  Export_Data_Word:"Khetha uhlobo lwefayile ukuthumela ngaphandle kunye nokukhuphela.",
  Export_File:"Thumela iFayile",
  Mode:"Imowudi",
  Times:"Amaxesha",
  Widgets:"Iwijethi",
  Slider:"Isilayidi",
  Set_Details:"Seta Iinkcukacha",
  Select_Type:"Khetha Uhlobo",
  Display_Slider:"Bonisa isilayidi",
  Dashboard_Slider:"Isilayidi seDashboard",
  Dashboard_Mode:"Indlela yeDashboard",
  Show_Intro:"Bonisa isingeniso",
  Show_Payrolls:"Bonisa Imirhumo",
  Show_Holidays:"Bonisa iiHolide",
  Show_Invoices:"Bonisa ii-invoyisi",
  Show_Cheques:"Bonisa Iitshekhi",
  Enabled_Widgets:"IiWidgets ezinikwe amandla",
  Disabled_Widgets:"IiWidgets ezikhubazekileyo",
  Colors:"Imibala",
  Barcodes:"Iikhowudi zebhakhowudi",
  View_Timers:"Jonga izibali-xesha",
  Gradients:"Imigangatho",
  No_Info:"Akukho Lwazi",
  Disable:"Khubaza",
  Show_Layer:"Bonisa Iileya",
  Hide_Layer:"Fihla Iileya",
  Text_Layer:"Iileya zombhalo",
  Secondly:"Okwesibini",
  Minutely:"Ngomzuzu",
  nine_am:"9:00 AM",
  five_pm:"5:00 PM",
  Enable_Address:"Vula idilesi",
  Invalid_File_Type:"Uxolo, uhlobo lwefayile olungasebenziyo lukhethiwe. Uhlobo lwefayile exhaswayo:",
  Error_Updating_Entry:"Uxolo, kubekhona impazamo ekuhlaziyeni elingeno.",
  Schedule_Timing_Alert:"Imposiso: Ixesha lokuqala leshedyuli limiselwe kwixabiso emva kwexesha lokuphela.",
  Select_Multiple_Payments_Prompt:"Akukho ntlawulo zikhethiweyo. Khetha ubuncinane intlawulo enye.",
  Select_Multiple_Cheques_Prompt:"Akukho tsheki ikhethiweyo. Nceda ukhethe ubuncinane itshekhi enye.",
  Select_Multiple_Items_Prompt:"Akukho zinto zikhethiweyo. Nceda ukhethe into enye ubuncinane.",
  Paymemt_Multiple_Delete_Confirmation:"Ngaba uqinisekile ukuba ufuna ukucima iintlawulo ezininzi?",
  Cheque_Multiple_Delete_Confirmation:"Uqinisekile ufuna ukucima iitshekhi ezininzi?",
  Payee_Multiple_Delete_Confirmation:"Ngaba uqinisekile ukuba ufuna ukucima abantu abaninzi abahlawulwayo?",
  Employee_Multiple_Delete_Confirmation:"Ngaba uqinisekile ukuba ufuna ukucima abasebenzi abaninzi?",
  MICR_Warning:"Qaphela: Abanye abashicileli kunye nezixhobo zisenokungabonakalisi ifonti ye-MICR ngokuchanekileyo.",
  Automatically_Send:"Thumela ngokuzenzekela",
  Type:"Uhlobo",
  Payment_Type:"Uhlobo lwentlawulo",
  Auto_Send:"Thumela ngokuzenzekela",
  Automatically_Process:"Inkqubo ngokuzenzekela",
  Auto_Process:"Inkqubo ezenzekelayo",
  Image_Based:"Isekwe kwimifanekiso",
  Font_Based:"Ifonti-esekwe",
  Rerender:"Ukunikezela kwakhona",
  Rendering:"Unikezelo",
  Render:"Iifayile",
  Top:"Phezulu",
  Middle:"Phakathi",
  Bottom:"Ezantsi",
  Top_Left:"Phezulu eKhohlo",
  Top_Center:"Iziko eliphezulu",
  Top_Right:"Phezulu Ekunene",
  Middle_Left:"Embindini eKhohlo",
  Middle_Center:"Iziko eliPhakathi",
  Middle_Right:"Embindini wasekunene",
  Bottom_Left:"Ezantsi Ekhohlo",
  Bottom_Center:"Iziko elisezantsi",
  Bottom_Right:"Ezantsi Ekunene",
  Numbers:"Amanani",
  Verified:"Iqinisekisiwe",
  Paper_Size:"Ubungakanani bePhepha",
  New_Device:"Isixhobo Esitsha",
  Add_Device:"Yongeza Isixhobo",
  Remove_Device:"Susa isixhobo",
  Delete_Device:"Cima Isixhobo",
  Block_Device:"Vimba isixhobo",
  Block:"Vimba",
  Unblock:"Vula",
  Table:"Itheyibhile",
  Scan_Login_Code:"Skena iKhowudi yokuNgena",
  Login_Code:"Ikhowudi yokuNgena",
  Scan_Code:"Skena iKhowudi",
  Scan_QR_Code:"Skena iKhowudi yeQR",
  Select_All:"Khetha Zonke",
  Deselect_All:"Sukukhetha Konke",
  Increase:"Nyusa",
  Decrease:"Nciphisa",
  Bold:"Engqindilili",
  Text:"Isicatshulwa",
  Style:"Isimbo",
  Italic:"akekeleyo",
  QR_Code:"Ikhowudi yeQR",
  Barcode:"Ibhakhowudi",
  Browse_Images:"Khangela iMifanekiso",
  Browse_Files:"Khangela Iifayile",
  Background_Image:"Umfanekiso ongasemva",
  Logo_Image:"Umfanekiso weLogo",
  Header_Image:"Umfanekiso ongasentla",
  Bank_Image:"Umfanekiso weBhanki",
  Cut_Lines:"Sika Imigca",
  Background:"Imvelaphi",
  License:"Ilayisensi",
  One_License:"1 ilayisensi:",
  Licensed:"Inikwe ilayisenisi yoku:",
  Not_Licensed:"AyinaLayisenisi",
  Enter_Serial:"Ngena uthotho",
  Serial_Key:"Isitshixo sothotho",
  Serial:"Uthotho",
  Product_Key:"Imveliso yenani",
  Check_Product_Key:"Qinisekisa isitshixo seMveliso",
  Add_Product_Key:"Ngenisa isitshixo seMveliso",
  Verifying_Purchase:"Iqinisekisa ukuThenga...",
  Print_Envelope:"Shicilela imvulophu",
  Envelope:"Imvulophu",
  Thank_You:"Enkosi!",
  Scale:"Isikali",
  Print_Scale:"Printa Isikali",
  Borders:"Imida",
  VOID:"I-VOID",
  Void_Cheque:"Ukujonga iVoid",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"Hlawula UMYALELO WE:",
  NO_DOLLARS:"AKUKHO EDLORA",
  ONE_DOLLAR:"IDOLE ENYE",
  DOLLARS:"I-DOLA",
  AND:"KUNYE",
  CENTS:"IIENTS.",
  NO_:"HAYI",
  ONE_:"NYE",
  AND_NO_:"KUNYE NO",
  _AND_ONE_:"KUNYE",
  DOLLARS_AND_ONE_CENT:"KUNYE NEENTENYE.",
  AND_NO_CENTS:"KUNYE NEZIQINISEKISO.",
  CHEQUE_PRINT_DATE:"UMHLA:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Imisebenzi yokuqala",
  Inital_Setup:"Ukuseta kokuqala",
  Welcome_To:"Wamkelekile kwi",
  Welcome:"Wamkelekile",
  Swipe:"Swayipha",
  Intro_Setup:"Ukuseta Intro",
  Introduction:"Intshayelelo",
  CHEQUE_PRINT_CREDIT:"Ixhaswe ziitshekhi",
  Title_Intro_Word:"Wamkelekile kwiitshekhi",
  Title_Intro:"Iitshekhi Intshayelelo",
  Title_Setup:"Iitshekhi zokumisela",
  PayPal_Default_Email_Message:"Ufumene i-PayPal entsha.",
  Cheques_App_Export:"Ithunyelwa ngaphandle ngeetshekhi",
  No_Prints_Purchased:"Akukho Zishicilelo zithengiweyo",
  No_Prints_Purchased_Word:"Akukho mibhalo yongeziweyo ikhoyo. Nceda uthenge iitshekhi ezongezelelweyo.",
  Post_Intro_Thanks:"Enkosi ngokukhetha iitshekhi. Inkqubo yokuseta ngoku igqityiwe.",
  Post_Intro_Word:"Qalisa ngokuprinta itshekhi yakho yokuqala, yongeza intlawulo yexesha elizayo, okanye wongeze abasebenzi kuluhlu lwemivuzo.",
  Upgrade_Required:"Iitshekhi zifuna ukuba ube noguqulelo olungaphezulu lweprimiyamu yesoftware ukuze ufihle lo myalezo kwaye uvule iimpawu ezongezelelweyo.",
  Upgrade_Title:"Iitshekhi",
  Mailout_Prompt:"Usenokukhetha ukuba ne-Checks mail-out iitshekhi zakho zomvuzo wakho.",
  Mailed_Cheque:"Ngemeyile Khangela",
  Mailed_Cheque_Color:"Khangela ngemeyile (Umbala)",
  Terms_Purchase:"Zonke izinto ezithengiweyo nge-elektroniki ngeetshekhi zibuyiswa ngokupheleleyo ukuya kuthi ga kwiintsuku ezingama-30 ukusuka kumhla wokuthenga. Nceda ufunde kwaye uvumelane ne",
  Dashboard_Setup:"Seta uMshicileli wokuqala",
  Dashboard_Add:"Yongeza iAkhawunti yeBhanki yokuqala",
  Dashboard_Customize:"Khetha iSakhelo sokuHlola",
  Dashboard_Job_Salary:"Yenza umsebenzi wakho kwaye wongeze uMvuzo wakho",
  Dashboard_Employees:"Track Abasebenzi & Payes",
  Dashboard_Print:"Shicilela kwaye uthumele iiPaycheques zakho",
  Dashboard_Payroll:"Automate Your Payroll Printing",
  Dashboard_PayPal:"Seta PayPal Payroll / Iintlawulo",
  Begin_Setup:"Qalisa ukuseta",
  Get_Started:"Qalisa",
  Purchase:"Thenga",
  Lockdown:"Ukuvalelwa",
  Unlock:"Vula",
  Detailed:"Iinkcukacha",
  Log_In:"Ngema",
  Login:"Ngema",
  Launch:"Qalisa",
  Register:"Bhalisa",
  Finish:"Gqiba",
  List:"Uluhlu",
  Weekends:"Ngempelaveki",
  Weekly:"Rhoqo ngeveki",
  PayPal_Default_Subject:"Intlawulo entsha",
  Payment_Message:"Umyalezo wentlawulo",
  PayPal_Default_Payment_Note:"Enkosi",
  Setup_Your_Cheqing_Account:"Ukujonga iAkhawunti",
  Add_Your_Primary_Cheqing_Account:"Yongeza iakhawunti yakho yokuqala.",
  Welcome_Word:"Yenza lula kwaye uzenzele imivuzo kunye nolawulo lwezabasebenzi.",
  Get_Started_Quickly:"Phendula nje imibuzo embalwa elula eya kusinceda siqalise...",
  Done_Intro_Word:"Ukuseta Kugqityiwe",
  PIN_Protected:"Igama lokugqithisa & nePIN ikhuselwe",
  Enter_New_PIN:"Faka ikhowudi entsha ye-PIN:",
  Enter_PIN:"Faka ikhowudi ye-PIN:",
  Invalid_PIN:"I-PIN engasebenziyo ifakiwe.",
  Account_Identifier:"Isichongi seAkhawunti",
  New_Account_Identifier:"Isichongi seAkhawunti Esitsha",
  attempt:"umzamo",
  attempts:"imizamo",
  remaining:"eseleyo",
  Language:"Ulwimi",
  languages:"Iilwimi",
  select_languages:"Khetha ulwimi",
  Deposit:"Idiphozithi",
  Hire_One_Now:"Qasha Omnye Ngoku",
  App_Locked:"Isicelo sitshixiwe.",
  Skip_:"Tsiba",
  Skip_to_Dashboard:"Tsibela kwiDashboard",
  Dashboard:"Dashboard",
  Import:"Ngenisa ngaphandle",
  Admin:"Abalawuli",
  New_Admin:"Umlawuli omtsha",
  Settings:"Iisetingi",
  Color_Picker:"Isikhethi sombala",
  Font_Picker:"Isikhethi sefonti",
  Logout:"Phuma",
  Close:"Vala",
  Close_menu:"Vala",
  Excel:"Ifayile ye-Excel",
  Csv:"Ifayile ye-CSV",
  Sql:"Ifayile yeSQL",
  Json:"Ifayile yeJSON",
  Url:"Ngenisa nge-URL",
  Back:"Emva",
  Timers:"Izibali-xesha",
  Cheque:"Khangela",
  Print:"Shicilela",
  Designs:"Uyilo",
  Pause_Printing:"Misa ukushicilela",
  Resume_Printing:"Qhubeka nokuPrinta",
  Printing_Paused:"Ushicilelo Lumisiwe",
  PrintingUnavailable:"Uxolo! Ushicilelo alufumaneki kule nkqubo.",
  Prints_Paused:"Ushicilelo Lumisiwe",
  Administration:"Ulawulo",
  Loading:"Iyalayisha",
  Unnamed:"Akuchazwanga ngagama",
  error:"Uxolo, olu qwalaselo alukwazanga kuvulwa ukuze lujongwe.",
  No_Cheques_To_Print:"Akukho zitsheki zokuPrinta",
  No_Cheques_To_Print_Word:"Yenza <a routerLink='/folder/Cheque/New'>Itsheki Entsha</a>.",
  New_Cheque:"Itshekhi entsha",
  Start_One_Now:"Qala enye Ngoku",
  Edit_Cheque:"Hlela Khangela",
  Select_Cheques:"Khetha Iitsheki",
  Select_Employee:"Khetha uMsebenzi",
  Default_Printer:"Isishicileli esihlala sihleli",
  Reassign:"Kwabelwe kwakhona",
  Configure:"Qwalasela",
  Template:"Isifanekiso",
  Designer:"Umyili",
  Edit_Designs:"Hlela uYilo",
  New_Design:"Uyilo olutsha",
  Next:"Okulandelayo",
  Save:"Gcina",
  Name:"Igama",
  Mail:"Imeyile",
  Amount:"Isixa",
  Date:"Umhla",
  PayPal:"PayPal",
  Payouts:"Iintlawulo",
  PayPal_Label:"Ileyibhile yePayPal",
  Email_Username:"Imeyile / Igama lomsebenzisi",
  Client_ID:"Isazisi soMxumi",
  Sandbox_Email:"Sandbox Email",
  PayPal_Email:"I-imeyile ye-PayPal",
  PayPal_Username:"Igama lomsebenzisi le-API",
  PayPal_Payouts:"Iintlawulo zePayPal",
  Select_PayPal_Key:"Khetha iqhosha lePayPal",
  Secret:"Imfihlo",
  API_Secret:"Imfihlo ye-API",
  PayPal_API_Keys:"PayPal Keys",
  New_PayPal_Key:"Iqhosha elitsha lePayPal",
  Email_Subject:"Umxholo we-imeyile",
  Email_Message:"Umyalezo we-imeyile",
  Payout_Options:"Iinketho zentlawulo",
  Payment_Note:"Inqaku lentlawulo",
  Enable_Employee:"Nika amandla uMsebenzi",
  Enable_Production_Mode:"Yenza iModi yoMveliso isebenze",
  Sandbox_Username:"Igama lomsebenzisi lebhokisi yesanti",
  Sandbox_Signature:"Sandbox uMsayino",
  Sandbox_Password:"Igama lokugqithisa leSandbox",
  Production_Username:"Igama lomsebenzisi weMveliso",
  Production_Signature:"Utyikityo lweMveliso",
  Production_Password:"Iphasiwedi yeMveliso",
  Production_Email:"I-imeyile yoVeliso",
  API_Client_ID:"API Client ID",
  API_Signature:"API Utyikityo",
  API_Password:"Iphasiwedi ye-API",
  API_Username:"Igama lomsebenzisi le-API",
  Secret_Key:"Isitshixo esiyimfihlo",
  Sandbox:"Ibhokisi yesanti",
  Production:"Imveliso",
  Sandbox_Keys:"Izitshixo zebhokisi yesanti",
  Production_Keys:"Izitshixo zeMveliso",
  API_Title:"Isihloko se-API",
  Production_Mode:"Imo yeMveliso",
  Account_Label:"Ileyibhile yeakhawunti",
  No_PayPal_Setup:"Akukho PayPal Key",
  Enable_PayPal_Account:"Vula iAkhawunti yePayPal",
  No_PayPal_Word:"Yongeza <a routerLink='/folder/Invoice/New'>PayPal API Key</a>.",
  Printed_Memo:"IMemo eprintiweyo",
  Employee:"Umqeshwa",
  View_Employee:"Jonga uMsebenzi",
  Mailing_Address:"Idilise yemeyile",
  Company_Address:"Idilesi yeNkampani",
  Select_Company_Address:"Khetha idilesi yeNkampani",
  Address:"Idilesi",
  Any_Day:"Naluphi na Usuku",
  Address_Name:"Igama ledilesi",
  Unit:"Iyunithi",
  Account:"Iakhawunti",
  Bank_Account:"I-akhawunti yebhanki",
  Account_Limits:"Vula iMida yeAkhawunti",
  Payroll:"Umvuzo",
  Run:"Baleka",
  Run_Payroll:"Qhuba iPayroll",
  New_Payroll:"Umvuzo omtsha",
  No_Payroll:"Akukho Mrhumo uzayo",
  Upcoming_Holiday:"IHolide ezayo:",
  Invoice_Due:"I-invoyisi efunekayo:",
  New_Invoice:"I-invoyisi entsha",
  No_Invoices:"Akukho zi-invoyisi",
  No_Invoices_Word:"Yenza eyokuqala <a routerLink='/folder/Invoice/New'>Invoyisi</a>.",
  Cheque_Due:"Qwalasela iXesha:",
  Payrolls:"Imivuzo",
  Sandbox_Mode:"Imowudi yebhokisi yesanti",
  Hire:"Qesha",
  Pay:"Hlawula",
  New:"Entsha",
  Add:"Yongeza",
  Make:"Yenza",
  Time:"Ixesha",
  Write:"Bhala",
  Holiday:"Iholide",
  Holidays:"Iiholide",
  Next_Holiday:"IHolide elandelayo:",
  All_Done:"Konke kwenzekile!",
  Employees:"Abasebenzi",
  Payees:"Abahlawulwayo",
  Job:"Umsebenzi",
  Jobs:"Imisebenzi",
  Invoice:"I-invoyisi",
  Invoices:"Ii-invoyisi",
  Vacations:"Iiholide",
  Cheques:"Iitshekhi",
  Brand_Cheques:"Igama lakho",
  Payment:"Intlawulo",
  Enable_Payment:"Vumela iNtlawulo",
  Payments:"Iintlawulo",
  Schedule:"Ishedyuli",
  Schedules:"Iishedyuli",
  Timesheet:"Ishiti zexesha",
  Timesheets:"Amaxwebhu eXesha",
  Salary:"Umvuzo",
  New_Address:"Idilesi Entsha",
  Address_2:"Idilesi (Umgca 2)",
  _City:"Isixeko",
  _State:"Ilizwe/imizekeliso",
  City:"Isixeko / Ilokishi",
  State:"Ilizwe / iPhondo",
  Postal:"Ikhowudi / ZIP yePosi",
  ZIP:"Iposi / ZIP",
  Country:"Ilizwe",
  Addresses:"Iidilesi",
  Required_Options:"Iinketho ezifunekayo",
  Optional_Options:"Iinketho ozikhethayo",
  Additional_Options:"Iinketho ezongezelelweyo",
  Required:"Kufuneka",
  Optional:"Ukhetho",
  Additional:"Ezongezelelweyo",
  No_Addresses:"Akukho zidilesi",
  New_Address_Word:"Yongeza eyokuqala <a routerLink='/folder/Address/New'>Idilesi</a>.",
  Select_Address:"Khetha idilesi",
  No_Address:"Akukho zidilesi",
  Print_Cheque:"Khangela iShicilelo",
  Print_Cheques:"Iitshekhi zokuprinta",
  Print_Cheque_Now:"Shicilela Khangela Ngoku",
  Description:"Inkcazo",
  Pay_To_The_Order_Of:"Hlawula uMyalelo we:",
  Select_Date_Range:"Khetha uluhlu lomhla",
  Select_Starting_Date:"Khetha Umhla wokuQalisa",
  Select_Ending_Date:"Khetha Umhla wokuphela",
  Select_Date:"Khetha Umhla",
  Cheque_Date:"Jonga Umhla",
  Cheque_Memo:"Jonga iMemo",
  Blank_Cheque:"Itshekhi engenanto",
  Blank:"Akunanto",
  No_Cheques:"Akukho zitsheki",
  No_Cheques_Word:"Shicilela eyakho yokuqala <a routerLink='/folder/Cheque/New'>Jonga</a>.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"Jonga umfanekiso",
  View:"Jonga",
  Modify:"Guqula",
  Delete:"Cima",
  Cheque_Paid:"Ihlawulwe",
  New_Deduction:"Ukutsalwa okutsha",
  Title:"Isihloko",
  Frequency:"Ukuphindaphinda",
  Hourly:"Ngeyure",
  Daily:"Yonke imihla",
  Weekdays:"Ngeentsuku zeveki",
  BiWeekly:"Iiveki ezi-2",
  TriWeekly:"Iiveki ezi-3",
  Monthly:"Ngenyanga",
  MiMonthly:"Iinyanga ezi-2",
  Quarterly:"Ngekota",
  Yearly:"Ngonyaka",
  Every_Week:"Qho ngeveki",
  Every_Payroll:"Wonke umvuzo",
  Every_Month:"Inyanga qho",
  Annually:"Ngonyaka",
  Always_Scheduled:"Ihleli icwangcisiwe",
  Start_Date:"Umhla wokuqala",
  End_Date:"Umhla wokuphela",
  Start_Time:"Ixesha Lokuqalisa",
  End_Time:"Ixesha lokuphela",
  Deduction_Label:"Ileyibhile yokutsalwa",
  Notes:"Amanqaku",
  Options:"Iinketho",
  Enable:"Vulela",
  Select_Deductions:"Khetha Utsalo",
  Deductions:"Utsalo-mali",
  No_Deductions:"Akukho kutsalwa",
  No_Deductions_Word:"Yenza yakho yokuqala <a routerLink='/folder/Deduction/New'>Ukutsalwa</a>.",
  New_Employee:"UMsebenzi omtsha",
  No_Title:"Akukho Sihloko",
  _Name:"Igama",
  About_Yourself:"Malunga Nawe",
  Full_Name:"Igama eliphelele",
  Birthday:"Usuku lokuzalwa",
  Email:"I-imeyile",
  SMS:"i-SMS",
  Phone:"Ifowuni",
  Test:"Uvavanyo",
  Call:"Fowuna",
  Fax:"Ifeksi",
  Printed_Note:"Inqaku elishicilelweyo",
  Position:"Isikhundla",
  Job_Position:"Isikhundla somsebenzi",
  Select_a_Job:"Khetha Umsebenzi",
  Select_a_Salary:"Khetha uMvuzo",
  Add_a_Deduction:"Yongeza ukutsalwa",
  Taxes:"Iirhafu",
  Add_Taxes:"Yongeza iiRhafu",
  Date_of_Birth:"Umhla wokuzalwa",
  Email_Address:"Idilesi yemeyile",
  Phone_Number:"Inombolo yomnxeba",
  Phone_Call:"Umnxeba wefowni",
  Enable_on_Payroll:"Vula kwi-Payroll",
  Select_Employees:"Khetha Abasebenzi",
  No_Employees:"Akukho basebenzi",
  No_Employees_Word:"Yongeza entsha yakho yokuqala <a routerLink='/folder/Employee/New'>Umqeshwa</a>.",
  No_Name:"Akho gama",
  Unemployeed:"Ukungaphangeli",
  Employeed:"Ndiqeshiwe",
  Paid:"Ihlawulwe",
  Enabled:"Ivuliwe",
  Disabled:"Kukhubazekile",
  Fire:"Umlilo",
  Not_Available:"Ayifumaneki",
  Not_Available_Word:"Shicilela okokuqala <a routerLink='/folder/Invoice/New'>Invoyisi</a> kwaye uhlawulwe.",
  Select_Invoices:"Khetha ii-invoyisi",
  Print_Invoice_Word:"Shicilela okokuqala <a routerLink='/folder/Invoice/New'>Invoyisi</a> kwaye uhlawulwe.",
  Invoice_Title:"Isihloko se-invoyisi",
  Invoice_Date:"Umhla we-invoyisi",
  Due_Date:"Umhla wokuphila",
  New_Job:"Umsebenzi omtsha",
  Details:"Iinkcukacha",
  Customize:"Lungiselela",
  Customize_Dashboard:"Lungiselela Dashboard",
  Components:"Amacandelo",
  No_Components:"Akukho Amacandelo",
  Main_Components:"Amacandelo Angundoqo",
  Smaller_Components:"Amacandelo amancinci",
  Error_Loading_Page:"Impazamo ekulayisheni eli phepha.",
  Bank_Details:"Iinkcukacha zeBhanki",
  About_Your_Job:"Ngomsebenzi Wakho",
  Your_Schedule:"Ishedyuli Yakho",
  Job_Title:"Isihloko somsebenzi",
  Job_Description:"Ingcaciso yomsebenzi",
  Job_Details:"Iinkcukacha zomsebenzi",
  Enable_Job:"Vula umsebenzi",
  Pay_Period:"Ixesha lokuHlawula",
  Perpetually_Schedule:"Ngokuqhubekayo Cwangcisa",
  Select_Jobs:"Khetha Imisebenzi",
  No_Jobs:"Akukho Misebenzi",
  Add_Jobs:"Yongeza Imisebenzi",
  No_Jobs_Word:"Yongeza owokuqala <a routerLink='/folder/Job/New'>Umsebenzi</a> kuluhlu.",
  Count_Employees:"job.employee_count+' Abasebenzi'",
  Zero_Employees:"0 Abasebenzi",
  New_Leave:"Ikhefu Elitsha",
  Leave_Name:"Shiya Igama",
  Paid_Leave:"Ikhefu elihlawulelwayo",
  Leave_Pay:"Shiya Intlawulo",
  Indefinite_Leave:"Ikhefu elingenasiphelo",
  Indefinite_Payroll:"Umvuzo ongapheliyo",
  Leave:"Hamba",
  Add_Schedules:"Yongeza iiShedyuli",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Yenza ukungabikho",
  Select_Leaves:"Khetha Amagqabi",
  No_Leaves:"Akukho Amagqabi Okungabikho",
  Leave_Of_Absence:"Ikhefu lokungabikho",
  Leaves_Of_Absence:"Amagqabi okungabikho",
  New_Leave_of_Absense:"Ikhefu Elitsha Lokungabikho",
  No_Leaves_Word:"Yongeza eyokuqala <a routerLink='/folder/Leave/New'>Ikhefu lokungabikho</a>.",
  Not_Enabled:"Ayivulwanga",
  Absences:"Ukungabikho",
  Payee:"Ohlawulwayo",
  New_Payee:"Umvuzo omtsha",
  Payee_Identifier:"Isichongi soMhlawulwa",
  Payee_Name:"Igama loMhlawulwa",
  Payee_Title:"Isihloko soMhlawulwa",
  Payment_Memo:"IMemo yentlawulo",
  Select_Payees:"Khetha Abahlawulwayo",
  No_Payees:"Akukho Babhatalwa",
  Add_Payee_Note:"Yongeza eyokuqala <a routerLink='/folder/Payee/New'>Ohlawulwayo</a>.",
  New_Payees:"Abahlawulwa abatsha",
  About_The_Payment_Schedule:"IShedyuli yentlawulo",
  Your_Payroll_Schedule:"Ukuhlawula okuzenzekelayo",
  New_Payment:"Intlawulo entsha",
  Identifier:"Isichongi",
  Selected:"Ikhethiwe",
  Select:"Khetha",
  Memo:"Imemo",
  Payment_Date:"Umhla wokuHlawula",
  Mark_as_Paid:"Phawula njengeHlawulweyo",
  Select_Payments:"Khetha Iintlawulo",
  No_Payments:"Akukho ziNtlawulo",
  No_Payments_Word:"Yenza eyokuqala <a routerLink='/folder/Payment/New'>Intlawulo</a>.",
  Create_Payroll:"Yenza iPayroll",
  Properties:"Iipropati",
  Payroll_Title:"Isihloko somvuzo",
  Payroll_Notes:"Amanqaku omvuzo",
  Payroll_Setup:"Ukuseta umvuzo",
  Tabulate_Payments:"Uluhlu lweeNtlawulo",
  Tabulate:"Theyibhile",
  By:"Ngu:",
  Payments_By:"Iintlawulo Ngo",
  Timesheets_And_Schedules:"Amaxwebhu eXesha kunye neeShedyuli",
  Both:"Zombini",
  Items:"Izinto",
  Add_Payees:"Yongeza Abahlawulwayo",
  Add_Account:"Yongeza iAkhawunti",
  Enable_Account:"Vula iAkhawunti",
  Enable_Payroll:"Yenza iPayroll isebenze",
  Print_Payroll:"Shicilela uMvuzo",
  No_Payrolls:"Akukho Mvuzo",
  No_Payroll_Word:"Yenza yakho yokuqala <a routerLink='/folder/Payroll/New'>Umvuzo</a>.",
  View_more:"VIEW_MORE",
  Less:"NGAPHANTSI",
  Add_Payroll:"Yongeza iPayroll",
  Select_Payroll:"Khetha Umvuzo",
  About_Your_Salary:"Malunga noMvuzo Wakho",
  Add_Salaries:"Yongeza imivuzo",
  Add_Salary:"Yongeza uMvuzo",
  Salaries:"Imivuzo",
  No_Salaries:"Akukho Mvuzo",
  No_Salaries_Word:"Yongeza eyokuqala <a routerLink='/folder/Salary/New'>Umvuzo</a>.",
  Select_Salaries:"Khetha Imivuzo",
  New_Salary:"UMvuzo omtsha",
  Salary_Name:"Isichongi soMvuzo",
  Enable_Salary:"Yenza uMvuzo usebenze",
  Salary_Amount:"Isixa somvuzo",
  New_Schedule:"IShedyuli entsha",
  Schedule_Title:"Isihloko seshedyuli",
  Add_Address:"Yongeza idilesi",
  Repeat:"Phinda",
  Design:"Yila",
  Edit_Design:"Hlela uYilo",
  Edit_this_Design:"Hlela oluYilo",
  Repeat_Payment:"Phinda Intlawulo",
  Enable_Schedule:"Yenza iShedyuli isebenze",
  Never:"Ungaze",
  Select_Schedule:"Khetha iiShedyuli",
  No_Schedules:"Akukho ziShedyuli",
  No_Schedules_Word:"Yenza i-<a routerLink='/folder/Schedule/New'>Shedyuli yokuqala</a>.",
  New_Administrator:"Umlawuli Omtsha",
  Username:"Igama lomsebenzisi",
  First_Name:"Igama lakho",
  Last_Name:"Ifani",
  Add_an_Address:"Yongeza idilesi",
  Payment_Limit:"Umda weNtlawulo nganye",
  Total_Limit:"Umda opheleleyo",
  Select_Accounts:"Khetha iiAkhawunti",
  No_Administrators:"Akukho balawuli",
  No_Administrators_Word:"Yenza eyokuqala <a routerLink='/folder/Administrator/New'>Akhawunti yoMlawuli</a>.",
  New_Administrators_Word:"Yongeza eyokuqala <a routerLink='/folder/Administrator/New'>Umlawuli</a>",
  Cloud:"Cloud",
  Backup:"Gcina",
  Enable_iCloud:"Vula iCloud",
  Enable_Google_Drive:"Vula iGoogle Drive",
  Enable_Microsoft_OneDrive:"Vula iMicrosoft OneDrive",
  Automatically_Backup:"Ugcino oluzenzekelayo",
  FTP_Settings:"Izicwangciso zeFTP",
  URL_File_Prompt:"Nceda ucacise indawo yefayile ye-.xls / .xlsx / .json enokuthatha ngaphandle:",
  URL_SQL_Prompt:"Nceda uchaze indawo ekuyo ifayile yeSQLite ethathwa ngaphandle:",
  FTP_Backup:"FTP Backup",
  FTP_Import:"FTP Thatha ngaphandle",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Umamkeli",
  Port:"Izibuko",
  Path:"Indlela",
  Data_Path:"Indlela yeDatha",
  Enable_FTP_Account:"Vula iAkhawunti yeFTP",
  HostnameIP:"Igama lomamkeli",
  Password:"Inombolo yokuvula",
  Connection_Port:"Uqhagamshelo Port",
  Enable_MySQL_Database:"Vula i-MySQL Database",
  Log:"Ngena",
  Reset:"Lungisa kwakhona",
  Erase:"Cima",
  Export:"Thumela ngaphandle",
  Database:"Uvimba weenkcukacha",
  Upload_CSV:"Layisha CSV",
  Download_CSV:"Khuphela i-CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Ugcino lweMySQL",
  Internal_Notes:"Amanqaku Angaphakathi",
  Root_Path:"Ingcambu yeNdlela",
  Select_Backup:"Khetha Gcina",
  Add_New_Backup:"Yongeza ugcino olutsha",
  First_Backup:"Cwangcisa ugcino lokuqala...",
  Backups:"Ugcino",
  Add_Backup:"Yongeza i-Backup",
  No_Backups:"Akukho zigcino zifunyenweyo.",
  Select_Backup_Type:"Khetha uhlobo logcino ongathanda ukulumisela...",
  Backup_Type:"Uhlobo Logcino",
  FTP_Drive:"FTP Drive",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Qhuba",
  Microsoft_OneDrive:"Qhuba",
  Import_Fields:"Imimandla yokungenisa ngaphandle",
  Import_Fields_Word:"Sebenzisa eli candelo ukukhetha <a routerLink='/folder/Holiday/New'>Ngenisa</a> idatha.",
  Upload:"Layisha",
  Download:"Khuphela",
  Download_JSON:"Khuphela njengeDatha yeJSON",
  Download_SQL:"Khuphela njengeFayile yeSQL",
  New_Bank:"IBhanki Entsha",
  New_Account:"Iakhawunti entsha",
  New_Bank_Account:"Iakhawunti entsha yeBhanki",
  Upload_Image:"Layisha umfanekiso",
  Bank_Name:"Igama leBhanki",
  Bank_Address:"Idilesi yeBhanki",
  Branch_Address:"Idilesi yeSebe",
  Address_on_Cheque:"Idilesi",
  Cheque_Numbers:"Jonga amanani",
  Cheque_Details:"Jonga Iinkcukacha",
  Bank_Logo:"ILogo yeBhanki",
  Cheque_ID:"Jonga i-ID",
  Starting_Cheque_ID:"Ukuqala Khangela i-ID",
  Transit_Number:"Inombolo yokuhamba",
  Institution_Number:"Inombolo yeziko",
  Designation_Number:"Inombolo yobizo",
  Account_Number:"Inombolo ye-akhawunti",
  Limits:"Imida",
  Default_Limits:"Imida eMiselweyo",
  Over_Limit:"Ngaphezulu koMda",
  Under_Limit:"Ngaphantsi koMda",
  Payroll_Limit:"Umda woMvuzo",
  Enable_Bank_Account:"Vula iAkhawunti yeBhanki",
  Select_Account:"Khetha iAkhawunti",
  No_Bank_Account:"Akukho Akhawunti yeBhanki",
  No_Bank_Account_Word:"Yongeza eyokuqala <a routerLink='/folder/Accounts/New'>Akhawunti yeBhanki</a>.",
  Bank_Accounts:"Iiakhawunti zeBhanki",
  No_Accounts:"Akukho Akhawunti",
  No_Accounts_Note:"Yongeza eyokuqala <a routerLink='/folder/Accounts/New'>Akhawunti yeBhanki</a>.",
  Cheque_Designer:"Jonga uMyili",
  Cheque_Design:"Jonga uyilo",
  Select_Design:"Khetha uyilo",
  Cheque_Designs:"Jonga uYilo",
  No_Cheque_Designs:"Akukho ziYilo zokuHlola",
  No_Cheque_Designs_Word:"Yenza eyakho <a routerLink='/folder/Settings/Cheque/Design/New'>Jonga uyilo</a>.",
  Set_Default:"Seta njengokuhlala kukho",
  Default:"Ukuhlala kukho",
  Remove:"Susa",
  Folder:"Isiqulathi seefayili",
  Edit:"Hlela",
  LoadingDots:"Iyalayisha...",
  Add_a_New_File:"Yongeza i <a href='#' (cofa)='add()'>Ifayile Entsha</a> ku",
  this_folder:"olu lawulo",
  FTP_Backup_Settings:"FTP Izicwangciso Backup",
  Secure_File_Transfer:"Khusela ukuhanjiswa kweFayile",
  New_Holiday:"IHolide eNtsha",
  Add_Holiday:"Yongeza iHolide",
  Holiday_Name:"Igama leHolide",
  Additional_Pay:"Intlawulo eyongezelelweyo",
  Enable_Holiday:"Vula iHolide",
  Select_Holidays:"Khetha iiHolide",
  No_Holidays:"Akukho holide",
  No_Holidays_Word:"Yongeza eyokuqala <a routerLink='/folder/Holiday/New'>Holide yoLuntu</a>.",
  New_Import:"Ukungenisa Okutsha",
  Import_Data:"Ngenisa idatha",
  Import_Data_Word:"Khetha uhlobo lwefayile okanye indlela yokulayisha oyikhethayo. <br /> Uya kukwazi ukukhetha nayiphi na imihlaba ethathwe ngaphandle kwifayile ehambelana nayo nayiphi iparameter kwi-app emva kokulayisha ifayile exhaswayo.",
  Import_File:"Thatha ngaphandle iFayile",
  Link_To_File:"Ikhonkco kwiFayile",
  Select_File:"Khetha Ifayile",
  New_Moderator:"Imodareyitha entsha",
  Allow_Moderation:"Vumela ukuModareyitha",
  Create_Paycheques:"Yenza iiPaycheques",
  Edit_Paycheques_and_Data:"Hlela iiPaycheques kunye neDatha",
  Destroy_Data_and_Paycheques:"Ukutshabalalisa iDatha kunye neePaycheques",
  Bonus_Percentage:"Ipesenti yePaycheque",
  Fixed_Amount:"Imali eZinzisiweyo",
  Select_Moderator:"Khetha iModare",
  No_Moderators:"Akukho ziModareyitha",
  Moderators:"Iimodareyitha",
  Moderator_Account:"Yenza i-akhawunti yokuqala <a routerLink='/folder/Administrator/New'>Imodareyitha</a>.",
  No_Moderators_Word:"Yongeza eyokuqala <a routerLink='/folder/Administrator/New'>Imodareyitha</a>.",
  Defaults:"Ukungagqibeki",
  Provide_Us_Info:"Nika ulwazi",
  Setup_Your_Printer:"Misela Umshicileli Wakho",
  Your_Company:"Malunga neNkampani yakho",
  Company_Name:"Igama leKhampani",
  Currency:"Imali",
  Default_Currency:"Imali ehlala ikho",
  Base_Monthly_Income:"Umvuzo wenyanga",
  Protection:"Ukhuseleko",
  App_Protection:"Ukhuseleko losetyenziso",
  PIN_Code_Protection:"Ikhowudi ye-PIN yoKhuselo",
  App_Protection_Word:"Vumela iindlela zokhuseleko ezinceda ukukhusela iakhawunti yakho.",
  PIN_Code:"Ikhowudi ye-PIN",
  Change_PIN:"Guqula i-PIN",
  New_Password:"Iphasiwedi entsha",
  Geofence_Protection:"Ukhuseleko lweGeo-Fence",
  Geofence_Area:"Seta indawo",
  Distance:"Umgama",
  Setup_Now:"Cwangcisa Ngoku",
  Mile:"Imayile",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Ukubonwa ngobuso",
  Face:"Ubuso",
  Setup:"Misela",
  Label:"Ileyibhile",
  Password_Protection:"Ukhuseleko Lwegama Lokugqithisa",
  Modify_Password:"Guqula igama lokugqithisa",
  New_Tax_Entry:"UNgeno olutsha lweRhafu",
  New_Tax:"Irhafu Entsha",
  Tax_Label:"Ileyibhile yeRhafu",
  Perpetually_Enabled:"Inikwe amandla ngokuqhubekayo",
  Select_Taxes:"Khetha iiRhafu",
  Tax_Deductions:"Ukutsalwa kwerhafu",
  No_Tax_Deductions:"Akukho Kutsalwa kweRhafu",
  No_Tax_Deductions_Word:"Yongeza eyokuqala <a routerLink='/folder/Tax/New'>Irhafu</a>.",
  New_Timer:"Isibali-xesha esitsha",
  Start:"Qala",
  Stop:"Yeka",
  Start_Timer:"Qalisa isibali-xesha",
  Stop_Timer:"Misa isibali-xesha",
  Timer_Active:"Isibali-xesha siyasebenza",
  Timer:"Isibali-xesha:",
  Timer_Running:"Ixesha: (Ebaleka)",
  Save_Timer:"Gcina isibali-xesha",
  New_Timesheet:"Iphepha Elitsha Lexesha",
  Select_Timesheets:"Khetha Amaxwebhu eXesha",
  Work_Notes:"Amanqaku oMsebenzi",
  Entry_Title:"Isihloko sokuNgena",
  No_Timesheets:"Akukho Amaphepha exesha",
  No_Timesheets_Word:"Yongeza eyokuqala <a routerLink='/folder/Timesheet/New'>Ishiti zexesha</a>.",
  Timesheet_Submitted:"Iphepha lexesha lingenisiwe",
  Timesheet_Congrats:"Sivuyisana nawe! Iphepha lakho lexesha lithunyelwe ngempumelelo. Enkosi!",
  Timesheet_Copy:"Ukufumana ikopi yamaxwebhu akho nceda usinike idilesi ye-imeyile kunye/okanye inombolo yefowuni ephathwayo.",
  Submit:"Ngenisa",
  Allow_Notifications:"Vumela Izaziso",
  Untitled_Entry:"Ungeniso Olutsha",
  Untitled_Bank:"Ibhanki engenamagama",
  Timesheet_Entry:"Ungeno lwePhepha leXesha",
  Work_Description:"Inkcazo yomsebenzi",
  Enable_Timesheet:"Vula iPhepha leXesha",
  Submit_Timesheet:"Ngenisa iPhepha leXesha",
  Vacation:"Ikhefu",
  New_Vacation:"Iholide Entsha",
  Vacation_Name:"Igama Leholide",
  Paid_Vacation:"Ikhefu elihlawulelwayo",
  Vacation_Pay:"Intlawulo yeholide",
  Enable_Vacation:"Vula iKhenketho",
  Select_Vacations:"Khetha Iiholide",
  No_Vacations:"Akukho Ziholide",
  No_Vacations_Word:"Yenza eyokuqala <a routerLink='/folder/Vacation/New'>Iholide</a>.",
  Payroll_Schedule:"IShedyuli yoMvuzo",
  Next_Payroll:"Umvuzo olandelayo:",
  Upcoming_Payroll:"Intlawulo ezayo",
  No_Upcoming_Payroll:"Akukho Mrhumo uzayo",
  Address_Book:"Incwadi yedilesi",
  Archived_Documents:"Amaxwebhu agciniweyo",
  Dev_Mode:"Isicelo Kwimo Yophuhliso",
  Administrators:"Abalawuli",
  Privacy:"Ubumfihlo",
  None:"Akukho nanye",
  Select_Signature:"Khetha uMsayino",
  No_Signatures:"Akukho Misayino",
  No_Signatures_Word:"Yongeza eyokuqala <a routerLink='/folder/Signature/New'>Umsayino</a>.",
  Repeat_Indefinitely:"Phinda ngokungenasiphelo",
  Sign:"Sayina",
  Sign_Here:"Sayina Apha",
  Date_Signed:"Umhla wokusayinwa",
  Signature_Pad:"Utyikityo Pad",
  Account_Holder:"Umnikazi we-akhawunti",
  Account_Properties:"Iipropati zeakhawunti",
  Name_On_Cheque:"Igama kwi-Check",
  Server_Hostname:"Igama lomamkeli weseva / IP",
  Printers:"Abashicileli",
  No_Printers:"Akukho Zishicileli",
  Printer_Exists:"Umshicileli oneli gama sele ekhona.",
  No_Printers_Word:"Yongeza eyokuqala <a routerLink='/folder/Printer/New'>Umshicileli</a>.",
  No_Printer_Alert:"Akukho mshicileli uchaziweyo. Ngaba ungathanda ukuseta umshicileli?",
  Add_Printer:"Yongeza Isishicileli",
  New_Printer:"Isishicileli Esitsha",
  Printer_Hostname:"Igama lomamkeli weprinta / IP",
  Printer_Label:"Ileyibhile yomshicileli",
  Printer_Protocol:"INkqubo yoMshicileli",
  Protocol:"Umgaqo-nkqubo",
  Email_Print:"I-imeyile",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Isiseko",
  Print_Job:"Umsebenzi Wokuprinta",
  Printed:"Ishicilelwe",
  Not_Printed:"Ayiprintwa",
  Print_Jobs:"Shicilela imisebenzi",
  Print_Queue:"Umgca Woshicilelo",
  No_Print_Jobs:"Akukho Misebenzi yoShicilelo",
  No_Prints:"Yenza entsha <a routerLink='/folder/Cheque/New'>Jonga</a> ukuze uprinte.",
  Prints:"Iiprinta",
  Find_Printer:"Fumana Umshicileli",
  Find_AirPrint_Devices:"Fumana izixhobo ze-AirPrint",
  Select_Printer:"Khetha Umshicileli",
  System_UI:"Inkqubo ye-UI",
  Printer:"Umshicileli",
  Status:"Ubume",
  Preview:"Jonga kwangaphambili",
  Enable_Print_Job:"Vula Umsebenzi Wokuprinta",
  Queue_Weight:"Ubunzima bomgca",
  Unlimited:"Unlimited",
  Show_Advanced_Printer_Options:"Bonisa iiNdlela zokuKhetha zesishicileli esikwiNqanaba eliphezulu",
  Advanced:"Ikwinqanaba eliphezulu",
  Location:"Indawo",
  Note:"Phawula",
  Queue_Name:"Igama lomgca",
  Pages_Printed_Limit:"Amaphepha Aprintiweyo umda",
  MultiPage_Idle_Time:"Ixesha lokulinda elinamaphepha amaninzi (s)",
  Page_Count_Limit:"Umda wokubalwa kwephepha",
  Page_Orientation:"Ukuqhelaniswa nephepha",
  Portrait:"Umfanekiso",
  Landscape:"Imbonakalo-mhlaba",
  Duplex:"I-Duplex",
  Double_Sided_Printing:"Amacala amabini",
  Duplex_Mode:"Imowudi yeDuplex",
  Duplex_Short:"Mfutshane",
  Duplex_Long:"Inde",
  Duplex_None:"Akukho nanye",
  Color_And_Quality:"Umbala kunye noMgangatho",
  Monochrome:"I-Monochrome",
  Photo_Quality_Prints:"Iiprinta zoMgangatho weFoto",
  Printer_Email:"Imeyile yesishicileli",
  Automatically_Downsize:"Nciphisa ngokuzenzekelayo",
  Paper:"Iphepha",
  Paper_Name:"Igama Lephepha",
  Paper_Width:"Ububanzi bePhepha",
  Paper_Height:"Ukuphakama Kwephepha",
  Paper_Autocut_Length:"Iphepha-Sika Ubude obuzenzekelayo",
  Margins:"Imida",
  Page_Margins:"Imida yephepha",
  Page_Margin_Top:"Umda wephepha eliphezulu",
  Page_Margin_Right:"Umda wephepha lasekunene",
  Page_Margin_Bottom:"Umda wephepha elisezantsi",
  Page_Margin_Left:"Umda wephepha lasekhohlo",
  Add_Employees:"Yongeza abaSebenzi",
  Header:"Okubhalwe ngasentla kwekhasi",
  Print_A_Page_Header:"Shicilela iphepha elibhalwe ngasentla kwekhasi",
  Header_Label:"Ileyibhile engasentla",
  Header_Page_Index:"Isalathiso sePhepha eliPhezulu",
  Header_Font_Name:"Ifonti Engasentla",
  Select_Font:"Khetha Ifonti",
  Font_Selector:"Umkhethi Wefonti",
  Header_Font_Size:"Ifonti Engasentla",
  Header_Bold:"NONE",
  Header_Italic:"Umbhalo obhalwe ngeheda okekeleyo",
  Header_Alignment:"Ulungelelwaniso lwesihloko",
  Left:"Khohlo",
  Center:"Iziko",
  Right:"Kunene",
  Justified:"Igwetyelwe",
  Header_Font_Color:"Umbala weHeada",
  Select_Color:"Khetha Umbala",
  Footer:"Umbhalo osemazantsi",
  Print_A_Page_Footer:"Shicilela Umbhalo osemazantsi wePhepha",
  Footer_Label:"Ileyibhile esemazantsi",
  Footer_Page_Index:"Isalathiso sePhepha elisezantsi",
  Footer_Font_Name:"Ifonti esezantsi",
  Fonts:"Iifonti",
  Done:"Ugqibile",
  Select_Fonts:"Khetha Iifonti",
  Footer_Font_Size:"Ubungakanani obusezantsi",
  Footer_Bold:"Umbhalo osemazantsi eBold",
  Footer_Italic:"Umbhalo osemazantsi okekeleyo",
  Footer_Alignment:"Ulungelelwaniso lwaMazantsi",
  Footer_Font_Color:"Umbala osemazantsi",
  Page_Copies:"Iikopi zePhepha",
  Enable_Printer:"Vula Isishicileli",
  Remote_Logging:"UkuLogging okukude",
  Log_Server:"Iseva yelogi",
  Encryption:"Uguqulelo oluntsonkothileyo",
  Random_Key:"Isitshixo esingaqhelekanga",
  Encryption_Key:"Iqhosha loguqulelo oluntsonkothileyo",
  Cheques_Webserver:"Custom Database",
  Learn_How:"Funda Indlela",
  Signature:"Umsayino",
  Default_Printer_Unit:"kwi/cm/mm/(pt)",
  View_Signature:"Jonga uMsayino",
  Printed_Signature:"Umtyikityo oshicilelweyo",
  Digitally_Sign:"Sayina ngokwedijithali",
  Digital_Signature:"Umsayino weDijithali",
  Add_Signature:"Yongeza uMsayino",
  Add_Your_Signature:"Yongeza uMsayino Wakho",
  Enable_Signature:"Vula uMsayino",
  Digitally_Signed:"Isayinwe ngokweDijithali",
  Insert_Error:"Ayikwazanga kugcina itshekhi ngenxa yemiba yedatabase.",
  Delete_Confirmation:"Uqinisekile ufuna ukucima olu lwazi?",
  Discard_Confirmation:"Uqinisekile ufuna ukulahla olu lwazi?",
  Discard_Bank_Confirmation:"Uqinisekile ufuna ukulahla le akhawunti?",
  Discard_Printer_Confirmation:"Uqinisekile ufuna ukulahla lo mshicileli?",
  Delete_Bonus_Confirmation:"Uqinisekile ufuna ukucima le bhonasi?",
  Delete_Invoice_Confirmation:"Uqinisekile ufuna ukucima le invoyisi?",
  Generated_Cheque:"Yenziwe Khangela",
  Generated_Invoice:"I-invoyisi eyenziwe",
  Schedule_Start_Time:"Ukuqalisa kweshedyuli",
  Schedule_End_Time:"Ukuphela kweshedyuli",
  New_Call:"Umnxeba Omtsha",
  No_Contacts:"Akukho bafowunelwa",
  No_Contacts_Word:"Abalawuli, iimodareyitha, abasebenzi, nabahlawulwayo babonakala njengabafowunelwa.",
  PDF_Subject:"imali",
  PDF_Keywords:"payroll paycheque PDF check checks",
  Printer_Setup:"Ukuseta uMshicileli",
  Printer_Selection:"UKhetho lwesishicileli",
  New_Fax:"Ifeksi Entsha",
  New_Fax_Message:"Umyalezo omtsha wefeksi",
  Fax_Machine:"Umatshini wefeksi",
  Fax_Name:"Igama lefeksi",
  Fax_Email:"I-imeyile yefeksi",
  Fax_Number:"Inombolo yefeksi",
  Contents:"Imixholo",
  Fax_Body:"Umzimba wephepha",
  Header_Word:"Okubhalwe ngasentla kwekhasi:",
  Header_Text:"Okubhaliweyo okungasentla",
  Include_Header:"Bandakanya Okungasentla",
  Include_Footer:"Bandakanya Umbhalo osemazantsi",
  Footer_Word:"Umbhalo osemazantsi:",
  Footer_Text:"Umbhalo osemazantsi",
  Attach_a_Cheque:"Qhoboshela itshekhi",
  Add_Deduction:"Yongeza ukutsalwa",
  Enable_Fax:"Thumela ifeksi",
  Select_Fax:"Khetha ifeksi",
  No_Faxes:"Akukho ziFeksi",
  Faxes:"Iifeksi",
  Save_and_Send:"Thumela ifeksi",
  Save_and_Pay:"Gcina kwaye Uhlawule",
  WARNING:"ISILUMKISO:",
  Remember:"Khumbula",
  Printing:"Ukushicilela",
  Printing_Complete:"Ushicilelo Lugqityiwe!\n\n",
  of:"ye",
  There_Were:"Babe",
  Successful_Prints:"iprints ngempumelelo kunye",
  Unsuccessful_Prints:"ushicilelo olungaphumelelanga.",
  PrinterError:"Uxolo! Bekukho impazamo.",
  Printing_:"Iyaprinta...",
  PrinterSuccess:"Uxwebhu luprintwe ngempumelelo.",
  PrintersSuccess:"Amaxwebhu ashicilelwe ngempumelelo.",
  New_Message:"Umyalezo omtsha",
  New_Messages:"Imiyalezo emitsha",
  Read_Message:"Funda uMyalezo",
  Write_Message:"Bhala umyalezo",
  Send_Message:"Thumela uMyalezo",
  Subject:"Umxholo",
  Message:"Umyalezo",
  Writing:"Iyabhala...",
  Send:"Thumela",
  Set_Date:"Seta Umhla",
  Set_Time:"Misela Ixesha",
  Recieve:"Yamkela",
  Set_as_Default:"Seta njengokuhlala kukho",
  Default_Account:"Iakhawunti ehlala ikho",
  Default_Design:"Uyilo oluMiselweyo",
  Multiple_Cheques:"Iitshekhi ezintathu",
  Account_Mode:"Imo yeakhawunti",
  Multiple_Cheques_Description:"Iitshekhi ezintathu kwiphepha ngalinye.",
  Check_and_Table:"Khangela & Itheyibhile",
  Check_including_Table:"Jonga kwitafile ye-accounting.",
  Check_Mailer:"Jonga uMmeli",
  Check_Mailer_Window:"Jonga ngefestile yedilesi.",
  DocuGard_Table_Top:"Ukujonga okukhulu kunye neTheyibhile",
  DocuGard_Table_Middle:"Ujongo olukhulu kunye neTheyibhile (Embindini)",
  DocuGard_Table_Bottom:"Ukujonga okukhulu kunye neTheyibhile (Ezantsi)",
  DocuGard_Mailer_Top:"I-Mailer yokuHlola enkulu",
  DocuGard_Mailer_Middle:"Imeyile enkulu yokujonga (Embindini)",
  DocuGard_Mailer_Bottom:"Jonga uMmeli omkhulu (Ezantsi)",
  DocuGard_Three_Cheques:"Iitshekhi ezintathu ezinkulu",
  DocuGard_Cheque_Top:"Itshekhi Enkulu enye",
  DocuGard_Cheque_Middle:"Ujongo olukhulu (phakathi)",
  DocuGard_Cheque_Bottom:"Ukujonga okukhulu (Ezantsi)",
  DocuGard_Three_Cheques_Window:"Iitshekhi ezintathu kwiphepha elinye.",
  DocuGard_Table_Top_Window:"Itshekhi & itheyibhile yemivuzo.",
  DocuGard_Table_Middle_Window:"Itshekhi & itheyibhile yemivuzo.",
  DocuGard_Table_Bottom_Window:"Itshekhi & itheyibhile yemivuzo.",
  DocuGard_Mailer_Top_Window:"Itshekhi, itafile, kunye nedilesi.",
  DocuGard_Mailer_Middle_Window:"Itshekhi, itafile, kunye nedilesi.",
  DocuGard_Mailer_Bottom_Window:"Itshekhi, itafile, kunye nedilesi.",
  DocuGard_Cheque_Top_Window:"Khangela iphepha elikhuselekileyo.",
  DocuGard_Cheque_Middle_Window:"Khangela iphepha elikhuselekileyo.",
  DocuGard_Cheque_Bottom_Window:"Khangela iphepha elikhuselekileyo.",
  Basic_Cheque:"Itshekhi enye",
  Basic_Cheque_Print:"Shicilela itshekhi enye.",
  Error_Setting_As_Paid:"Ukuseta imposiso njengeHlawulweyo",
  Add_Attachment:"Yongeza isiqhoboshelo",
  PrinterUnavailable:"Isishicileli asifumaneki",
  CreditCardComponent:"Amakhadi",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"I-BitCoin",
  New_Deposit:"Idiphozithi entsha",
  Deposits:"Iidiphozithi",
  No_Deposits:"Akukho Idiphozithi",
  Credit_Card_Deposit:"Ikhadi letyala",
  New_Credit_Card_Deposit_Message:"Idiphozithi yekhadi letyala",
  New_BitCoin_Deposit:"I-BitCoin",
  New_BitCoin_Deposit_Message:"Idiphozithi yeBitCoin",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Ukudluliselwa kwe-Interac",
  Payments_Limit:"Umda weNtlawulo",
  No_Payment_Limit:"Akukho Mda weNtlawulo",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Idiphozithi yePayPal",
  No_Deposits_Word:"Yongeza inkampani yokuqala <a routerLink='/folder/Deposit/New'>Idiphozithi</a>.",
  No_Documents_Specified:"Akukho maxwebhu achaziweyo ukuba ashicilelwe",
  No_Printers_Added:"Akukho bashicileli bafunyenweyo. Yiya ku Useto > Izishicileli ukongeza enye.",
  DB_Erase_Prompt:"Ucima ngokupheleleyo uvimba weenkcukacha? ISILUMKISO: Uya kuphulukana nalo lonke ulwazi olugciniweyo!",
  Console_Warning:"Sukuncamathisela nawuphi na umbhalo kule console. Unokuzibeka kunye / okanye inkampani yakho emngciphekweni omkhulu.",
  No_Faxes_Word:"Yenza eyokuqala <a routerLink='/folder/Fax/New'>Ifeksi</a>.",
  Cheque_Delete_Confirmation:"Uqinisekile ufuna ukucima le cheque?",
  Design_Delete_Confirmation:"Uqinisekile ufuna ukulucima oluyilo?",
  Cheque_Pay_Confirmation:"Phawula le chekhi njengebhatelweyo? AYISAYI kuvela kumgca wokuprinta.",
  Payment_Pay_Confirmation:"Phawula le ntlawulo njengehlawulweyo? AYISAYI kuvela kumgca wokutshekisha.",
  Delete_Deduction_Confirmation:"Uqinisekile ufuna ukucima oku kutsalwayo?",
  Delete_Job_Confirmation:"Uqinisekile ufuna ukuwucima lo msebenzi?",
  Delete_Timesheet_Confirmation:"Uqinisekile ufuna ukucima eli xesha?",
  Delete_Schedule_Confirmation:"Uqinisekile ufuna ukucima olu luhlu?",
  Delete_Setting_Confirmation:"Uqinisekile ufuna ukuseta ngokutsha olu seto?",
  Delete_Fax_Confirmation:"Uqinisekile ufuna ukucima le fax?",
  Delete_File_Confirmation:"Uqinisekile ufuna ukucima le fayile?",
  Delete_Vacation_Confirmation:"Uqinisekile ufuna ukucima le holide?",
  Delete_Printer_Confirmation:"Uqinisekile ufuna ukucima lo mshicileli?",
  Remove_Design_Confirmation:"Uqinisekile ufuna ukulucima oluyilo?",
  Delete_Payroll_Confirmation:"Uqinisekile ufuna ukucima le payroll?",
  Send_Fax_Email_Confirmation:"Ngaba uyafuna ukuthumela ngefeksi kunye ne-imeyile kula maxwebhu?",
  Send_Email_Confirmation:"Ngaba uyafuna ukuthumela nge-imeyile olu xwebhu?",
  Send_Fax_Confirmation:"Ngaba ufuna ukuthumela ngefeksi olu xwebhu?",
  Error_Generating_PDF:"Uxolo. Kubekho impazamo ekuveliseni olu xwebhu.",
  PDF_Error_Saving_Image:"Uxolo. Kubekho impazamo ekugcinweni komfanekiso wePDF kolu xwebhu.",
  Test_Printer_Confirmation:"Ngaba ufuna ukuprinta iphepha lovavanyo kulo mshicileli?",
  Save_Timesheet_Prompt:"Nceda wongeze 'Isihloko' okanye ucinezele u-'Qalisa Isibali-xesha' ukugcina.",
  Remove_Geofence_Prompt:"Uqinisekile ufuna ukususa indawo yolucingo lwe geo?",
  Delete_Employee_Confirmation:"Uqinisekile ukuba ufuna ukucima",
  Fire_Employee_Confirmation:"Uqinisekile ukuba ufuna ukutshisa"
}