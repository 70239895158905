export const Pa = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"ਕਾਪੀਰਾਈਟ &ਕਾਪੀ; 2023",
  black:"ਕਾਲਾ",
  green:"ਹਰਾ",
  gold:"ਸੋਨਾ",
  blue:"ਨੀਲਾ",
  brown:"ਭੂਰਾ",
  purple:"ਜਾਮਨੀ",
  pink:"ਗੁਲਾਬੀ",
  red:"ਲਾਲ",
  Swatches:"ਸਵੈਚ",
  HSL:"ਐਚ.ਐਸ.ਐਲ",
  RGB:"ਆਰ.ਜੀ.ਬੀ",
  Hue:"ਰੰਗ",
  Saturation:"ਸੰਤ੍ਰਿਪਤਾ",
  Lightness:"ਹਲਕੀਤਾ",
  Upgrade_To_Pro:"ਪ੍ਰੋ 'ਤੇ ਅੱਪਗ੍ਰੇਡ ਕਰੋ",
  AllFeaturesInclude:"ਸਾਰੀਆਂ ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ ਵਿੱਚ ਸ਼ਾਮਲ ਹਨ:",
  PrintUnlimitedCheques:"ਅਸੀਮਤ ਚੈਕ ਪ੍ਰਿੰਟ ਕਰੋ",
  PremiumChequeDesigns:"ਪ੍ਰੀਮੀਅਮ ਚੈੱਕ ਡਿਜ਼ਾਈਨ",
  ManageUnlimitedEmployees:"ਅਸੀਮਤ ਕਰਮਚਾਰੀਆਂ ਦਾ ਪ੍ਰਬੰਧਨ ਕਰੋ",
  AddUnlimitedPayees:"ਅਸੀਮਤ ਭੁਗਤਾਨਕਰਤਾ ਸ਼ਾਮਲ ਕਰੋ",
  CreateUnlimitedPayrolls:"ਅਸੀਮਤ ਪੇਰੋਲ ਬਣਾਓ",
  UnlimitedSchedulesandTimeSheets:"ਅਸੀਮਤ ਸਮਾਂ-ਸਾਰਣੀਆਂ ਅਤੇ ਸਮਾਂ ਸ਼ੀਟਾਂ",
  BulkPayPalPayouts:"ਥੋਕ ਪੇਪਾਲ ਅਦਾਇਗੀਆਂ",
  UnlimitedBankAccounts:"ਅਸੀਮਤ ਬੈਂਕ ਖਾਤੇ",
  ManageMultipleCompanies:"ਕਈ ਕੰਪਨੀਆਂ ਦਾ ਪ੍ਰਬੰਧਨ ਕਰੋ",
  TrackInsurancePolicies:"ਬੀਮਾ ਪਾਲਿਸੀਆਂ ਨੂੰ ਟਰੈਕ ਕਰੋ",
  Bio_MetricProtection:"ਬਾਇਓ-ਮੀਟ੍ਰਿਕ ਸੁਰੱਖਿਆ",
  Geo_FenceLock_OutProtection:"ਜੀਓ-ਫੈਂਸ ਲਾਕ-ਆਊਟ ਪ੍ਰੋਟੈਕਸ਼ਨ",
  Multiple_Companies_Word:"ਕਈ ਕੰਪਨੀਆਂ ਦਾ ਪ੍ਰਬੰਧਨ ਚੈੱਕ ਪ੍ਰੀਮੀਅਮ ਤੋਂ ਬਿਨਾਂ ਉਪਲਬਧ ਨਹੀਂ ਹੈ।",
  PayPal_Payouts_Word:"PayPal ਭੁਗਤਾਨ ਚੈੱਕ ਪ੍ਰੀਮੀਅਮ ਤੋਂ ਬਿਨਾਂ ਅਯੋਗ ਹਨ।",
  PINProtection:"ਪਿੰਨ ਸੁਰੱਖਿਆ",
  PasswordProtection:"ਪਾਸਵਰਡ ਸੁਰੱਖਿਆ",
  May_Require_Approval:"ਮਨਜ਼ੂਰੀ ਦੀ ਲੋੜ ਹੋ ਸਕਦੀ ਹੈ।",
  Subscribe:"ਸਬਸਕ੍ਰਾਈਬ ਕਰੋ",
  Billed:"ਬਿਲ ਕੀਤਾ ਗਿਆ",
  Up:"ਉੱਪਰ",
  Down:"ਥੱਲੇ, ਹੇਠਾਂ, ਨੀਂਵਾ",
  Positioning:"ਸਥਿਤੀ",
  Marker:"ਮਾਰਕਰ",
  Drag_Marker:"ਮਾਰਕਰ ਨੂੰ ਘਸੀਟੋ",
  Tagline:"ਪ੍ਰਿੰਟ ਚੈਕ ਅਤੇ ਟੇਬੂਲੇਟ ਪੇਰੋਲ",
  Marker_Word:"ਤੱਤ ਨੂੰ ਆਕਾਰ ਦੇਣ ਲਈ ਮਾਰਕਰ ਦੀ ਵਰਤੋਂ ਕਰੋ।",
  Pinch_Zoom:"ਚੂੰਡੀ ਜ਼ੂਮ",
  Pinch_Word:"ਤੱਤ ਨੂੰ ਜ਼ੂਮ ਕਰਨ ਲਈ ਚੂੰਡੀ ਲਗਾਓ।",
  Drag:"ਖਿੱਚੋ",
  Drag_Word:"ਤੱਤਾਂ ਨੂੰ ਖਿੱਚਣ ਲਈ ਆਪਣੀ ਉਂਗਲ ਦੀ ਵਰਤੋਂ ਕਰੋ।",
  subscription_alias_word:"ਸਵੈ-ਨਵੀਨੀਕਰਨ ਗਾਹਕੀ",
  premium_alias_word:"ਵਨ-ਟਾਈਮ ਅੱਪਗ੍ਰੇਡ ਪੈਕੇਜ",
  print_alias_word:"ਪ੍ਰਿੰਟ-ਆਊਟ ਵਿਅਕਤੀਗਤ ਚੈਕ",
  mode_alias_word:"ਮੋਡ",
  Pro:"ਪ੍ਰੋ",
  Pro_word:"ਪ੍ਰੋ ਸੰਸਕਰਣ ਦੀ ਲੋੜ ਹੈ।",
  Cant_Delete_Default_Company_Word:"ਮਾਫ਼ ਕਰਨਾ, ਤੁਸੀਂ ਆਪਣੀ ਡਿਫੌਲਟ ਕੰਪਨੀ ਨੂੰ ਨਹੀਂ ਮਿਟਾ ਸਕਦੇ ।",
  Reinsert_Default_Designs:"ਪੂਰਵ-ਨਿਰਧਾਰਤ ਡਿਜ਼ਾਈਨ ਮੁੜ-ਸ਼ਾਮਲ ਕਰੋ",
  Reinsert_Default_Designs_word:"ਕੀ ਤੁਸੀਂ ਪੂਰਵ-ਨਿਰਧਾਰਤ ਡਿਜ਼ਾਈਨਾਂ ਨੂੰ ਮੁੜ-ਸੰਮਿਲਿਤ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Subscription_Active_Disable_Word:"ਇਹ ਗਾਹਕੀ ਕਿਰਿਆਸ਼ੀਲ ਹੈ। ਕੀ ਤੁਸੀਂ ਚੈੱਕਾਂ ਦੀ ਇਸ ਗਾਹਕੀ ਨੂੰ ਰੱਦ ਕਰਨਾ ਚਾਹੋਗੇ?",
  Company_Logo:"ਕੰਪਨੀ ਦਾ ਲੋਗੋ",
  ZERO_:"ਜ਼ੀਰੋ",
  Disclaimer:"ਬੇਦਾਅਵਾ",
  Privacy_Policy:"ਪਰਾਈਵੇਟ ਨੀਤੀ",
  EULA:"EULA ਦੀ ਜਾਂਚ ਕਰਦਾ ਹੈ",
  Terms_Of_Service:"ਸੇਵਾ ਦੀਆਂ ਸ਼ਰਤਾਂ",
  Terms_Of_Use:"ਵਰਤੋ ਦੀਆਂ ਸ਼ਰਤਾਂ",
  Refunds:"ਰਿਫੰਡ ਨੀਤੀ",
  Single_Print:"1 ਜਾਂਚ ਕਰੋ",
  Two_Prints:"2 ਜਾਂਚਾਂ",
  Five_Prints:"5 ਜਾਂਚਾਂ",
  Ten_Prints:"10 ਜਾਂਚਾਂ",
  Fifteen_Prints:"15 ਜਾਂਚਾਂ",
  Twenty_Prints:"20 ਚੈਕ",
  Thirty_Prints:"30 ਚੈੱਕ",
  Image_Added:"ਚਿੱਤਰ ਸ਼ਾਮਲ ਕੀਤਾ ਗਿਆ",
  Image_Preview:"ਚਿੱਤਰ ਝਲਕ",
  No_Image_Was_Selected:"ਕੋਈ ਚਿੱਤਰ ਨਹੀਂ ਚੁਣਿਆ ਗਿਆ ਸੀ।",
  Cheques_Unlimited:"ਬੇਅੰਤ ਚੈੱਕ ਕਰਦਾ ਹੈ",
  _Subscription:"ਗਾਹਕੀ",
  Subscription:"ਚੈੱਕ - 1 ਮਹੀਨਾ",
  Two_Month_Subscription:"ਚੈੱਕ - 2 ਮਹੀਨੇ",
  Three_Month_Subscription:"ਚੈੱਕ - 3 ਮਹੀਨੇ",
  Six_Month_Subscription:"ਚੈੱਕ - 6 ਮਹੀਨੇ",
  Twelve_Month_Subscription:"ਚੈੱਕ - 12 ਮਹੀਨੇ",
  Cheques_Are_Available:"ਚੈਕ ਛਾਪਣ ਲਈ ਉਪਲਬਧ ਹਨ।",
  Upgrade_Required_Two:"ਇੱਕ ਪੈਕੇਜ ਚੁਣੋ ਅਤੇ ਆਪਣੀ ਖਰੀਦ ਸ਼ੁਰੂ ਕਰਨ ਲਈ ਕੀਮਤ ਬਟਨ 'ਤੇ ਦੋ ਵਾਰ ਟੈਪ ਕਰੋ। ਸਕਿੰਟਾਂ ਵਿੱਚ ਪੇਸ਼ੇਵਰ ਦਿੱਖ ਵਾਲੇ ਫੁੱਲ-ਕਲਰ ਜਾਂਚਾਂ ਨੂੰ ਛਾਪੋ।",
  Month:"ਮਹੀਨਾ",
  Due:"ਬਕਾਇਆ:",
  Expires:"ਮਿਆਦ ਪੁੱਗਦੀ ਹੈ:",
  Subscription_Active:"ਗਾਹਕੀ ਕਿਰਿਆਸ਼ੀਲ",
  Subscription_Inactive:"ਗਾਹਕੀ ਅਕਿਰਿਆਸ਼ੀਲ",
  Purchase_Additional_Cheques:"ਵਾਧੂ ਚੈੱਕ ਖਰੀਦੋ?",
  Printable_Cheque:"ਛਪਣਯੋਗ ਜਾਂਚ",
  Printable_Cheques:"ਛਪਣਯੋਗ ਜਾਂਚਾਂ",
  Printable_Cheque_Word:"ਚੈੱਕ ਤੁਹਾਡੇ ਖਾਤੇ 'ਤੇ ਉਪਲਬਧ ਹੈ।",
  Printable_Cheques_Word:"ਚੈੱਕ ਤੁਹਾਡੇ ਖਾਤੇ 'ਤੇ ਉਪਲਬਧ ਹਨ।",
  Max_Amount_Message:"ਤੁਹਾਡੇ ਦੁਆਰਾ ਨਿਰਧਾਰਤ ਕੀਤੀ ਗਈ ਰਕਮ ਇਸ ਸਿਸਟਮ ਲਈ ਨਿਰਧਾਰਤ ਅਧਿਕਤਮ ਰਕਮ ਤੋਂ ਵੱਧ ਗਈ ਹੈ:",
  Terms_Required_Word:"ਚੈੱਕਾਂ ਦੀ ਵਰਤੋਂ ਕਰਨਾ ਜਾਰੀ ਰੱਖਣ ਤੋਂ ਪਹਿਲਾਂ ਤੁਹਾਨੂੰ ਇਸ ਸਮਝੌਤੇ ਨਾਲ ਸਹਿਮਤ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ।",
  Subscriptions:"ਗਾਹਕੀਆਂ",
  Product_Upgrades:"ਅੱਪਗਰੇਡ",
  Mailed_Out_Cheques:"ਮੇਲ ਕੀਤੇ-ਆਊਟ ਚੈੱਕ",
  Enter_A_Company_Name:"ਕਿਰਪਾ ਕਰਕੇ ਕੰਪਨੀ ਦਾ ਨਾਮ ਦਾਖਲ ਕਰੋ।",
  Single_Cheques:"ਸਿੰਗਲ ਚੈਕ",
  Cheque_Golden:"ਗੋਲਡਨ ਚੈੱਕ",
  Cheque_Golden_Window:"ਗੋਲਡਨ ਚੈੱਕ ਡਿਜ਼ਾਈਨ.",
  Cheque_Green:"ਗ੍ਰੀਨ ਚੈੱਕ",
  Cheque_Green_Window:"ਗ੍ਰੀਨ ਚੈੱਕ ਡਿਜ਼ਾਈਨ.",
  Cheque_Red:"ਲਾਲ ਜਾਂਚ",
  Cheque_Red_Window:"ਲਾਲ ਚੈੱਕ ਡਿਜ਼ਾਈਨ.",
  Cheque_Yellow:"ਪੀਲਾ ਚੈੱਕ",
  Cheque_Yellow_Window:"ਪੀਲਾ ਚੈੱਕ ਡਿਜ਼ਾਈਨ.",
  Cheque_Statue_of_Liberty:"ਸੁਤੰਤਰਤਾ ਦੀ ਮੂਰਤੀ",
  Cheque_Statue_of_Liberty_Window:"ਸਟੈਚੂ ਆਫ਼ ਲਿਬਰਟੀ ਚੈੱਕ ਡਿਜ਼ਾਈਨ.",
  Cheque_Green_Wave:"ਗ੍ਰੀਨ ਵੇਵ",
  Cheque_Green_Wave_Window:"ਗ੍ਰੀਨ ਚੈੱਕ ਡਿਜ਼ਾਈਨ.",
  Cheque_Golden_Weave:"ਗੋਲਡਨ ਵੇਵ",
  Cheque_Golden_Weave_Window:"ਸ਼ਾਨਦਾਰ ਸੁਨਹਿਰੀ ਚੈੱਕ ਡਿਜ਼ਾਈਨ.",
  Cheque_Green_Sun:"ਹਰਾ ਸੂਰਜ",
  Cheque_Green_Sun_Window:"ਡੂੰਘੇ ਅਤੇ ਸ਼ਾਂਤ ਚੈੱਕ ਡਿਜ਼ਾਈਨ.",
  Cheque_Blue_Checkers:"ਬਲੂ ਚੈਕਰਸ",
  Cheque_Blue_Checkers_Window:"ਨੀਲਾ ਚੈੱਕ ਡਿਜ਼ਾਈਨ.",
  Cashiers_Check:"ਕੈਸ਼ੀਅਰ ਦਾ ਚੈੱਕ",
  Cashiers_Check_Window:"ਕੈਸ਼ੀਅਰਜ਼ ਚੈਕ ਸਟਾਈਲ ਟੈਂਪਲੇਟ।",
  Cheque_Aqua_Checkers:"ਐਕਵਾ ਚੈਕਰਸ",
  Cheque_Aqua_Checkers_Window:"ਐਕਵਾ ਚੈੱਕ ਡਿਜ਼ਾਈਨ.",
  Cheque_Golden_Checkers:"ਗੋਲਡਨ ਚੈਕਰਸ",
  Cheque_Golden_Checkers_Window:"ਗੋਲਡਨ ਚੈੱਕ ਡਿਜ਼ਾਈਨ.",
  Upgrade_Unavailable:"ਅੱਪਗ੍ਰੇਡ ਉਪਲਬਧ ਨਹੀਂ ਹਨ",
  Bank_Code_Protection:"ਬੈਂਕ ਨੰਬਰ ਪ੍ਰੋਟੈਕਸ਼ਨ",
  Bank_Code_Protection_Word:"ਕਿਸੇ ਹੋਰ ਡਿਵਾਈਸ 'ਤੇ ਚੱਲ ਰਹੇ ਇਸ ਐਪ 'ਤੇ ਜਾਂ ਕਿਸੇ ਹੋਰ ਉਪਭੋਗਤਾ ਲਈ ਆਪਣੇ ਬੈਂਕ ਨੰਬਰਾਂ ਦੀ ਵਰਤੋਂ ਕੀਤੇ ਜਾਣ ਤੋਂ ਬਚਾਓ। ਇਹ ਕਾਰਵਾਈ ਨਾ ਬਦਲੀ ਜਾ ਸਕਦੀ ਹੈ। ਜਾਰੀ ਰੱਖਣਾ ਹੈ?",
  Bank_Code_Protection_Blocked_Word:"ਤੁਸੀਂ ਜਿਨ੍ਹਾਂ ਬੈਂਕ ਨੰਬਰਾਂ ਦੀ ਵਰਤੋਂ ਕਰਨ ਦੀ ਕੋਸ਼ਿਸ਼ ਕਰ ਰਹੇ ਹੋ, ਉਹ ਕਿਸੇ ਹੋਰ ਉਪਭੋਗਤਾ ਜਾਂ ਡਿਵਾਈਸ ਲਈ ਰਾਖਵੇਂ ਹਨ।",
  Bank_Code_Protection_Error_Word:"ਨੰਬਰ ਪੁਸ਼ਟੀਕਰਨ ਅਸਫਲ ਰਿਹਾ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਇੰਟਰਨੈੱਟ ਨਾਲ ਕਨੈਕਟ ਕਰੋ ਅਤੇ ਇਸ ਬੈਂਕ ਖਾਤੇ ਨੂੰ ਦੁਬਾਰਾ ਜੋੜਨ ਦੀ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
  Bank_Code_Protection_Set_Error_Word:"ਬੈਂਕ ਨੰਬਰ ਸੁਰੱਖਿਆ ਲਈ ਤੁਹਾਨੂੰ ਇੰਟਰਨੈੱਟ ਨਾਲ ਕਨੈਕਟ ਹੋਣ ਦੀ ਲੋੜ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਕਨੈਕਟ ਕਰੋ ਅਤੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
  Upgrade_Unavailable_Word:"ਮਾਫ਼ ਕਰਨਾ, ਸਾਨੂੰ ਤੁਹਾਡੀ ਪੁਸ਼ਟੀ ਕਰਨ ਵਿੱਚ ਸਮੱਸਿਆ ਆ ਰਹੀ ਹੈ। ਚੈੱਕਾਂ ਲਈ ਗਾਹਕੀ ਅਤੇ ਅੱਪਗ੍ਰੇਡ ਵਰਤਮਾਨ ਵਿੱਚ ਤੁਹਾਡੇ ਖੇਤਰ ਵਿੱਚ ਖਰੀਦ ਲਈ ਉਪਲਬਧ ਨਹੀਂ ਹਨ।",
  PayPal_Payment_Preview:"PayPal ਭੁਗਤਾਨ ਪੂਰਵਦਰਸ਼ਨ",
  Apple_Pay:"ਐਪਲ ਪੇ",
  Select_PayPal:"ਪੇਪਾਲ ਚੁਣੋ",
  PayPal_Applications:"ਪੇਪਾਲ ਐਪਲੀਕੇਸ਼ਨਾਂ",
  Purchase_With_Apple_Pay:"ਐਪਲ ਪੇ ਨਾਲ ਖਰੀਦੋ",
  Google_Pay:"Google Pay",
  Companies:"ਕੰਪਨੀਆਂ",
  Insurance:"ਬੀਮਾ",
  New_PayPal:"ਨਵਾਂ ਪੇਪਾਲ",
  Pay_By:"ਦੁਆਰਾ ਭੁਗਤਾਨ ਕਰੋ",
  Insure:"ਬੀਮਾ ਕਰੋ",
  Miles:"ਮੀਲ",
  Payment_Method:"ਭੁਗਤਾਨੇ ਦੇ ਢੰਗ",
  Select_Policies:"ਨੀਤੀਆਂ ਚੁਣੋ",
  Policies:"ਨੀਤੀਆਂ",
  Policy:"ਨੀਤੀ ਨੂੰ",
  No_PayPal_Account:"ਕੋਈ PayPal ਖਾਤਾ ਨਹੀਂ",
  No_Policies:"ਕੋਈ ਬੀਮਾ ਪਾਲਿਸੀ ਨਹੀਂ",
  New_Policy:"ਨਵੀਂ ਨੀਤੀ",
  PayPal_Payment:"ਪੇਪਾਲ ਭੁਗਤਾਨ",
  PayPal_Payments:"ਪੇਪਾਲ ਭੁਗਤਾਨ",
  No_Payment_Selected:"ਕੋਈ ਭੁਗਤਾਨ ਨਹੀਂ ਚੁਣਿਆ ਗਿਆ",
  Sending_Payment_Word:"ਕਿਰਪਾ ਕਰਕੇ ਉਡੀਕ ਕਰੋ... ਇਹ ਭੁਗਤਾਨ ਭੇਜਿਆ ਜਾ ਰਿਹਾ ਹੈ।",
  Sending_Payments_Word:"ਕਿਰਪਾ ਕਰਕੇ ਉਡੀਕ ਕਰੋ... ਭੁਗਤਾਨ ਭੇਜੇ ਜਾ ਰਹੇ ਹਨ।",
  No_Payment_Selected_PayPal:"ਕੋਈ <a routerLink='/folder/Payments'> PayPal ਭੁਗਤਾਨ</a> ਨਹੀਂ ਚੁਣਿਆ ਗਿਆ।",
  Payment_Sent:"ਭੁਗਤਾਨ ਭੇਜਿਆ ਗਿਆ",
  PayPal_Payment_Sent:"ਇਹ ਭੁਗਤਾਨ PayPal ਨਾਲ ਭੇਜਿਆ ਗਿਆ ਹੈ।",
  Copay:"ਕਾਪੀ ਕਰੋ",
  Dead:"ਮਰ ਗਿਆ",
  Alive:"ਜਿੰਦਾ",
  Not_Dead:"ਮਰਿਆ ਨਹੀਂ",
  Unclaimed:"ਲਾਵਾਰਿਸ",
  Attempted:"ਕੋਸ਼ਿਸ਼ ਕੀਤੀ",
  Deceased:"ਮ੍ਰਿਤਕ",
  Claimed:"ਦਾਅਵਾ ਕੀਤਾ",
  Unpaid:"ਬਿਨਾਂ ਭੁਗਤਾਨ ਕੀਤੇ",
  Sending_Payment:"ਭੁਗਤਾਨ ਭੇਜਿਆ ਜਾ ਰਿਹਾ ਹੈ",
  Sending_Payments:"ਭੁਗਤਾਨ ਭੇਜ ਰਿਹਾ ਹੈ",
  Send_PayPal_Confirmation:"ਕੀ ਤੁਸੀਂ ਇਸ ਲੈਣ-ਦੇਣ ਨੂੰ PayPal ਨਾਲ ਭੇਜਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Send_PayPal_Confirmation_Word:"ਇਸ ਲੈਣ-ਦੇਣ ਨੂੰ ਭੇਜਣ ਲਈ ਹਰੇ ਬਟਨ ਨੂੰ ਦਬਾਓ।",
  Save_Payment_As_Unpaid:"ਕੀ ਇਸ ਭੁਗਤਾਨ ਨੂੰ ਬਿਨਾਂ ਭੁਗਤਾਨ ਕੀਤੇ ਵਜੋਂ ਸੁਰੱਖਿਅਤ ਕਰਨਾ ਹੈ?",
  Payment_Pay_Confirmation_PayPal:"ਕੀ ਇਸ ਭੁਗਤਾਨ ਨੂੰ ਅਦਾਇਗੀ ਵਜੋਂ ਸੁਰੱਖਿਅਤ ਕਰਨਾ ਹੈ?",
  No_Policies_Word:"ਪਹਿਲਾ <a routerLink='/folder/Postage/New'> ਬੀਮਾ ਨੀਤੀ</a> ਸ਼ਾਮਲ ਕਰੋ ।",
  Timesheet_Multiple_Delete_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਕਈ ਟਾਈਮਸ਼ੀਟਾਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Select_Multiple_Timesheets_Prompt:"ਕੋਈ ਟਾਈਮਸ਼ੀਟ ਨਹੀਂ ਚੁਣੀ ਗਈ। ਘੱਟੋ-ਘੱਟ ਇੱਕ ਟਾਈਮਸ਼ੀਟ ਚੁਣੋ।",
  Select_Multiple_Policies_Prompt:"ਕੋਈ ਨੀਤੀਆਂ ਨਹੀਂ ਚੁਣੀਆਂ ਗਈਆਂ। ਘੱਟੋ-ਘੱਟ ਇੱਕ ਬੀਮਾ ਪਾਲਿਸੀ ਚੁਣੋ।",
  Policies_Multiple_Delete_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਕਈ ਨੀਤੀਆਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Company:"ਕੰਪਨੀ",
  Add_Company:"ਕੰਪਨੀ ਸ਼ਾਮਲ ਕਰੋ",
  New_Company:"ਕੰਪਨੀ ਸ਼ਾਮਲ ਕਰੋ",
  No_Companies:"ਕੋਈ ਕੰਪਨੀਆਂ ਨਹੀਂ",
  Enable_Company:"ਕੰਪਨੀ ਨੂੰ ਚਾਲੂ ਕਰੋ",
  Select_Company:"ਕੰਪਨੀ ਚੁਣੋ",
  The_Default_Company:"ਪੂਰਵ-ਨਿਰਧਾਰਤ ਕੰਪਨੀ ਲੇਬਲ।",
  Manage_Companies:"ਕੰਪਨੀਆਂ ਦਾ ਪ੍ਰਬੰਧਨ ਕਰੋ",
  No_Companies_Word:"ਕੰਪਨੀ ਦੀ ਵਰਤੋਂ ਕਰਨਗੇ ।<br /> <a routerLink='/folder/Company/New'>ਪਹਿਲੀ ਕੰਪਨੀ</a> ਨੂੰ ਸ਼ਾਮਲ ਕਰੋ ।",
  Default_Company:"ਡਿਫਾਲਟ ਕੰਪਨੀ",
  Cheques_Unlimited_Word:"ਚੈਕ ਅਸੀਮਤ ਤੁਹਾਨੂੰ ਜਿੰਨੇ ਚਾਹੇ ਚੈੱਕ ਪ੍ਰਿੰਟ ਕਰਨ ਦੀ ਇਜਾਜ਼ਤ ਦਿੰਦਾ ਹੈ।",
  Cheques_Subscription_Word:"ਇੱਕ ਚੈਕ ਸਬਸਕ੍ਰਿਪਸ਼ਨ ਤੁਹਾਨੂੰ ਜਿੰਨੇ ਮਰਜ਼ੀ ਚੈੱਕ ਪ੍ਰਿੰਟ ਕਰਨ ਦੀ ਇਜਾਜ਼ਤ ਦਿੰਦਾ ਹੈ।",
  You_Own_This_Product:"ਤੁਸੀਂ ਇਸ ਉਤਪਾਦ ਦੇ ਮਾਲਕ ਹੋ।",
  Your_Subscription_is_Active:"ਤੁਹਾਡੀ ਗਾਹਕੀ ਕਿਰਿਆਸ਼ੀਲ ਹੈ।",
  Active_Products:"ਕਿਰਿਆਸ਼ੀਲ ਉਤਪਾਦ",
  Purchase_Confirmation:"ਖਰੀਦੋ",
  Purchase_Cheques:"ਖਰੀਦਦਾਰੀ ਜਾਂਚਾਂ",
  Restore_Purchase:"ਖਰੀਦਦਾਰੀ ਰੀਸਟੋਰ ਕਰੋ",
  Erase_Purchase:"ਖਰੀਦਾਂ ਨੂੰ ਮਿਟਾਓ",
  Successfully_Purchased:"ਸਫਲਤਾਪੂਰਵਕ ਖਰੀਦਿਆ ਗਿਆ",
  Enter_Your_Licence_Key:"ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਉਤਪਾਦ ਨੂੰ ਕਿਰਿਆਸ਼ੀਲ ਕਰਨ ਲਈ ਇਸ ਪੰਨੇ 'ਤੇ ਆਪਣੀ ਲਾਇਸੈਂਸ ਕੁੰਜੀ ਦਰਜ ਕਰੋ।",
  Licence_Key:"ਲਾਇਸੰਸ ਕੁੰਜੀ",
  Enter_Licence_Key:"ਲਾਇਸੈਂਸ ਕੁੰਜੀ ਦਰਜ ਕਰੋ",
  Purchased:"ਖਰੀਦਿਆ",
  Enable_Feature:"ਵਿਸ਼ੇਸ਼ਤਾ ਨੂੰ ਸਮਰੱਥ ਬਣਾਓ",
  Cancel_Subscription:"ਗਾਹਕੀ ਰੱਦ ਕਰੋ",
  Subscription_Removed:"ਗਾਹਕੀ ਹਟਾਈ ਗਈ",
  Select_Active_Subscription:"ਇਸਨੂੰ ਸੋਧਣ ਲਈ ਇੱਕ ਕਿਰਿਆਸ਼ੀਲ ਗਾਹਕੀ ਚੁਣੋ।",
  Remove_Subscription_Word:"ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਇਸ ਗਾਹਕੀ ਨੂੰ ਰੱਦ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Delete_Company_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਇਸ ਪੂਰੀ ਕੰਪਨੀ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ? ਚੇਤਾਵਨੀ: ਤੁਸੀਂ ਸਾਰੀ ਸਟੋਰ ਕੀਤੀ ਜਾਣਕਾਰੀ ਗੁਆ ਦੇਵੋਗੇ!",
  Delete_Default_Company_Word:"ਤੁਸੀਂ ਡਿਫੌਲਟ ਕੰਪਨੀ ਨੂੰ ਨਹੀਂ ਮਿਟਾ ਸਕਦੇ । ਕੀ ਤੁਸੀਂ ਐਪਲੀਕੇਸ਼ਨ ਨੂੰ ਰੀਸੈਟ ਕਰਨਾ ਅਤੇ ਇਸਨੂੰ ਡਿਫੌਲਟ ਸਥਿਤੀ ਵਿੱਚ ਰੀਸਟੋਰ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ? ਚੇਤਾਵਨੀ: ਤੁਸੀਂ ਸਾਰੀ ਸਟੋਰ ਕੀਤੀ ਜਾਣਕਾਰੀ ਗੁਆ ਦੇਵੋਗੇ!",
  Console_Warning_2:"ਇਸ ਐਪਲੀਕੇਸ਼ਨ ਦੀ ਵਰਤੋਂ ਕਰਦੇ ਹੋਏ ਕਿਸੇ ਵੀ ਮੁਦਰਾ ਦਾ ਪ੍ਰਬੰਧਨ ਨਾ ਕਰੋ ਜੋ ਵਰਤਮਾਨ ਵਿੱਚ ਤੁਹਾਡੀ ਨਹੀਂ ਹੈ।",
  Terms_and_Conditions:"ਨਿਬੰਧਨ ਅਤੇ ਸ਼ਰਤਾਂ",
  and_the:"ਅਤੇ",
  for:"ਲਈ",
  Please_Read_Word:"ਕਿਰਪਾ ਕਰਕੇ ਪੜ੍ਹੋ ਅਤੇ ਸਹਿਮਤ ਹੋਵੋ",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"ਕੁਝ ਮੁਦਰਾ ਪਰਿਵਰਤਨ ਦਰਾਂ ਲੱਭੀਆਂ ਨਹੀਂ ਜਾ ਸਕੀਆਂ। ਕਿਰਪਾ ਕਰਕੇ ਇੰਟਰਨੈੱਟ ਨਾਲ ਕਨੈਕਟ ਕਰੋ।",
  Free:"ਮੁਫ਼ਤ",
  DB_Erase_Prompt_2:"ਪੂਰੇ ਡਿਵੈਲਪਰ ਡੇਟਾਬੇਸ ਨੂੰ ਪੂਰੀ ਤਰ੍ਹਾਂ ਮਿਟਾਉਣਾ ਹੈ? ਚੇਤਾਵਨੀ: ਤੁਸੀਂ ਸਾਰੀ ਖਰੀਦਦਾਰੀ ਅਤੇ ਗਾਹਕੀ ਜਾਣਕਾਰੀ ਗੁਆ ਦੇਵੋਗੇ!",
  Successfully_Imported:"ਸਫਲਤਾਪੂਰਵਕ ਆਯਾਤ ਕੀਤਾ ਗਿਆ",
  Select_Postage:"ਪੋਸਟੇਜ ਚੁਣੋ",
  No_Postage:"ਕੋਈ ਡਾਕ ਟਿਕਟ ਨਹੀਂ",
  No_Paid_Postage_Word:"ਪਹਿਲਾ <a routerLink='/folder/Postage/New'>ਪੇਡ ਡਾਕ</a> ਸਟੈਂਪ ਸ਼ਾਮਲ ਕਰੋ ।",
  Trial_Complete:'ਟ੍ਰਾਇਲ ਪੂਰਾ ਹੋਇਆ',
  Please_Upgrade:'ਕਿਰਪਾ ਕਰਕੇ ਪ੍ਰਿੰਟਿੰਗ ਜਾਰੀ ਰੱਖਣ ਲਈ ਚੈੱਕ ਅੱਪਗ੍ਰੇਡ ਕਰੋ।',
  Aa:"ਏ.ਏ",
  Color:"ਰੰਗ",
  Font:"ਫੌਂਟ",
  Guide:"ਗਾਈਡ",
  Guides:"ਗਾਈਡ",
  Image:"ਚਿੱਤਰ",
  Zoom:"ਜ਼ੂਮ",
  Logo:"ਲੋਗੋ",
  Bank:"ਬੈਂਕ",
  MICR:"MICR",
  Total:"ਕੁੱਲ",
  Cancel:"ਰੱਦ ਕਰੋ",
  Confirm:"ਪੁਸ਼ਟੀ ਕਰੋ",
  Method:"ਢੰਗ",
  Biometric_Security:"ਬਾਇਓ-ਮੈਟ੍ਰਿਕ ਸੁਰੱਖਿਆ",
  Please_Login_To_Continue:"ਜਾਰੀ ਰੱਖਣ ਲਈ ਕਿਰਪਾ ਕਰਕੇ ਲੌਗਇਨ ਕਰੋ।",
  Complete:"ਸੰਪੂਰਨ",
  Sign_Up:"ਸਾਇਨ ਅਪ",
  Error:"ਗਲਤੀ",
  Biometrics:"ਬਾਇਓ-ਮੈਟ੍ਰਿਕਸ",
  Percent:"ਪ੍ਰਤੀਸ਼ਤ",
  Zero_Percent:"0%",
  Top_Margin:"ਸਿਖਰ ਹਾਸ਼ੀਏ",
  Bottom_Margin:"ਹੇਠਲਾ ਹਾਸ਼ੀਆ",
  Left_Margin:"ਖੱਬਾ ਹਾਸ਼ੀਆ",
  Right_Margin:"ਸੱਜਾ ਹਾਸ਼ੀਆ",
  MICR_Margin:"MICR ਮਾਰਜਿਨ",
  Table_Margin:"ਸਾਰਣੀ ਹਾਸ਼ੀਏ",
  Address_Margin:"ਪਤਾ ਮਾਰਜਿਨ",
  Percentage_:"ਪ੍ਰਤੀਸ਼ਤ",
  Vacation_Title:"ਛੁੱਟੀਆਂ ਦਾ ਸਿਰਲੇਖ",
  Use_PIN:"ਪਿੰਨ ਦੀ ਵਰਤੋਂ ਕਰੋ",
  Loading__:"ਲੋਡ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ...",
  Design_Title:"ਡਿਜ਼ਾਈਨ ਸਿਰਲੇਖ",
  Authorize:"ਅਧਿਕਾਰਤ ਕਰੋ",
  Key:"ਕੁੰਜੀ",
  Public_Key:"ਜਨਤਕ ਕੁੰਜੀ",
  Private_Key:"ਨਿੱਜੀ ਕੁੰਜੀ",
  Authenticate:"ਪ੍ਰਮਾਣਿਤ ਕਰੋ",
  Last_Payroll:"ਆਖਰੀ ਤਨਖਾਹ",
  Last_Calculation:"ਆਖਰੀ ਗਣਨਾ",
  Authorized:"ਅਧਿਕਾਰਤ",
  Geo_Authorized:"ਜੀਓ-ਟਿਕਾਣਾ: ਅਧਿਕਾਰਤ",
  Not_Authorized:"ਅਧਿਕਾਰਤ ਨਹੀਂ ਹੈ",
  Authorization_Complete:"ਪ੍ਰਮਾਣੀਕਰਨ ਪੂਰਾ ਹੋਇਆ",
  Geolocation_Authorization:"ਭੂ-ਸਥਾਨ ਪ੍ਰਮਾਣੀਕਰਨ",
  Out_of_Bounds:"ਸੀਮਾ ਤੋਂ ਬਾਹਰ",
  Cant_Delete_Default_Design:"ਡਿਫਾਲਟ ਡਿਜ਼ਾਈਨ ਨੂੰ ਨਹੀਂ ਮਿਟਾਇਆ ਜਾ ਸਕਦਾ ਹੈ।",
  Unauthorized_Out_of_Bounds:"ਅਣਅਧਿਕਾਰਤ: ਸੀਮਾ ਤੋਂ ਬਾਹਰ",
  Biometric_Authorization:"ਬਾਇਓ-ਮੀਟ੍ਰਿਕ ਅਧਿਕਾਰ",
  Locating_Please_Wait:"ਲੱਭ ਰਿਹਾ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ ਉਡੀਕ ਕਰੋ...",
  Test_Biometrics:"ਬਾਇਓ-ਮੈਟ੍ਰਿਕਸ ਦੀ ਜਾਂਚ ਕਰੋ",
  Cheque_Margins:"ਮਾਰਜਿਨਾਂ ਦੀ ਜਾਂਚ ਕਰੋ",
  Percentage_Full_Error:"ਪ੍ਰਤੀਸ਼ਤ ਖੇਤਰ ਨੂੰ 100% ਪ੍ਰਤੀਸ਼ਤ ਤੋਂ ਵੱਧ ਸੈੱਟ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਦਾ ਹੈ।",
  No_Payroll_Text:"ਇੱਕ <a routerLink='/folder/Employee/New'>ਕਰਮਚਾਰੀ</a> ਜਾਂ <a routerLink='/folder/Payee/New'>ਭੁਗਤਾਨ ਕਰਨ ਵਾਲੇ</a> ਅਤੇ ਇੱਕ <a routerLink='/folder/Schedule/New'>ਸ਼ਡਿਊਲ</a>।",
  Design_Saved:"ਡਿਜ਼ਾਈਨ ਸੁਰੱਖਿਅਤ ਕੀਤਾ ਗਿਆ",
  Default_Design_Selected:"ਡਿਫਾਲਟ ਡਿਜ਼ਾਈਨ ਚੁਣਿਆ ਗਿਆ",
  Partial_Import:"ਅੰਸ਼ਕ ਆਯਾਤ",
  Partial_Export:"ਅੰਸ਼ਕ ਨਿਰਯਾਤ",
  Entire_Import:"ਪੂਰਾ ਆਯਾਤ",
  Entire_Export:"ਪੂਰਾ ਨਿਰਯਾਤ",
  Existing_Password:"ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਮੌਜੂਦਾ ਪਾਸਵਰਡ ਦਰਜ ਕਰੋ:",
  Existing_PIN:"ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਮੌਜੂਦਾ ਪਿੰਨ ਕੋਡ ਦਰਜ ਕਰੋ:",
  Pin_Change_Header:"ਪਿੰਨ ਪੁਸ਼ਟੀਕਰਨ",
  Pin_Change_SubHeader:"ਤਬਦੀਲੀ ਦੀ ਪੁਸ਼ਟੀ ਕਰਨ ਲਈ ਆਪਣਾ ਪੁਰਾਣਾ ਪਿੰਨ ਨੰਬਰ ਦਾਖਲ ਕਰੋ।",
  Pass_Change_Header:"ਪਾਸਵਰਡ ਪੁਸ਼ਟੀ",
  Pass_Change_SubHeader:"ਤਬਦੀਲੀ ਦੀ ਪੁਸ਼ਟੀ ਕਰਨ ਲਈ ਆਪਣਾ ਪੁਰਾਣਾ ਪਾਸਵਰਡ ਦਰਜ ਕਰੋ।",
  PIN_Enter_Message:"ਪੁਸ਼ਟੀ ਕਰਨ ਲਈ ਆਪਣਾ ਪਿੰਨ ਦਾਖਲ ਕਰੋ।",
  Password_Enter_Message:"ਪੁਸ਼ਟੀ ਕਰਨ ਲਈ ਆਪਣਾ ਪਾਸਵਰਡ ਦਰਜ ਕਰੋ।",
  Pin_Updated_Success:"PIN ਸਫਲਤਾਪੂਰਵਕ ਅੱਪਡੇਟ ਕੀਤਾ ਗਿਆ!",
  Pin_Updated_Fail:"ਪਿੰਨ ਅੱਪਡੇਟ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਿਆ।",
  Pass_Updated_Success:"ਪਾਸਵਰਡ ਸਫਲਤਾਪੂਰਵਕ ਅੱਪਡੇਟ ਕੀਤਾ ਗਿਆ।",
  Pass_Updated_Fail:"ਪਾਸਵਰਡ ਅੱਪਡੇਟ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਿਆ।",
  Preview_Payment:"ਭੁਗਤਾਨ ਦੀ ਝਲਕ",
  Preview_Payroll:"ਪੇਰੋਲ ਦੀ ਝਲਕ",
  No_Payments_Created:"ਕੋਈ ਅਦਾਇਗੀਆਂ ਨਹੀਂ ਕੀਤੀਆਂ ਗਈਆਂ।",
  Payment_Preview:"ਭੁਗਤਾਨ ਦੀ ਝਲਕ",
  Enable_Payee:"ਭੁਗਤਾਨਕਰਤਾ ਨੂੰ ਸਮਰੱਥ ਬਣਾਓ",
  Rendered:"ਰੈਂਡਰ ਕੀਤਾ",
  No_Email:"ਕੋਈ ਈਮੇਲ ਨਹੀਂ",
  Setup_Cheques:"ਸੈੱਟਅੱਪ ਜਾਂਚਾਂ",
  name:"ਨਾਮ",
  address:"ਪਤਾ",
  address_2:"ਪਤਾ ਲਾਈਨ 2",
  city:"ਸ਼ਹਿਰ",
  province:"ਸੂਬਾ",
  postal_code:"ਡਾਕ / ਜ਼ਿਪ ਕੋਡ",
  country:"ਦੇਸ਼",
  username:"ਉਪਭੋਗਤਾ ਨਾਮ",
  full_name:"ਪੂਰਾ ਨਾਂਮ",
  birthday:"ਜਨਮਦਿਨ",
  email:"ਈ - ਮੇਲ",
  phone:"ਫ਼ੋਨ",
  employees:"ਕਰਮਚਾਰੀ",
  addresses:"ਪਤੇ",
  payment_amount_limit:"ਭੁਗਤਾਨ ਦੀ ਰਕਮ ਸੀਮਾ",
  total_limit:"ਕੁੱਲ ਸੀਮਾ",
  payees:"ਭੁਗਤਾਨ ਕਰਨ ਵਾਲੇ",
  description:"ਵਰਣਨ",
  address_line_one:"ਪਤਾ ਲਾਈਨ ਇੱਕ",
  address_line_two:"ਪਤਾ ਲਾਈਨ ਦੋ",
  image:"ਚਿੱਤਰ",
  account_holder:"ਖਾਤਾ ਧਾਰਕ",
  cheque_starting_id:"ਸ਼ੁਰੂਆਤੀ ID ਦੀ ਜਾਂਚ ਕਰੋ",
  transit_number:"ਆਵਾਜਾਈ ਨੰਬਰ",
  institution_number:"ਸੰਸਥਾ ਦਾ ਨੰਬਰ",
  designation_number:"ਅਹੁਦਾ ਨੰਬਰ",
  account_number:"ਅਕਾਊਂਟ ਨੰਬਰ",
  currency:"ਮੁਦਰਾ",
  signature:"ਦਸਤਖਤ",
  payment_payroll_limit:"ਭੁਗਤਾਨ ਸੀਮਾ",
  total_limi:"ਕੁੱਲ ਸੀਮਾ",
  date:"ਤਾਰੀਖ਼",
  printed_memo:"ਛਪਿਆ ਮੀਮੋ",
  banks:"ਬੈਂਕਾਂ",
  signature_image:"ਦਸਤਖਤ ਚਿੱਤਰ",
  address_name:"ਪਤਾ ਨਾਮ",
  notes:"ਨੋਟਸ",
  design:"ਡਿਜ਼ਾਈਨ",
  title:"ਸਿਰਲੇਖ",
  frequency:"ਬਾਰੰਬਾਰਤਾ",
  fax:"ਫੈਕਸ",
  salaries:"ਤਨਖਾਹ",
  salary_ids:"ਤਨਖਾਹ IDs",
  start_time:"ਸ਼ੁਰੂਆਤੀ ਸਮਾਂ",
  end_time:"ਸਮਾਪਤੀ ਸਮਾਂ",
  paid:"ਦਾ ਭੁਗਤਾਨ",
  overtime_percentage:"ਭੁਗਤਾਨ ਕੀਤਾ ਪ੍ਰਤੀਸ਼ਤ",
  overtime_amount:"ਭੁਗਤਾਨ ਕੀਤੀ ਸਥਿਰ ਰਕਮ",
  first_name:"ਪਹਿਲਾ ਨਾਂ",
  last_name:"ਆਖਰੀ ਨਾਂਮ",
  moderation:"ਸੰਜਮ",
  create:"ਬਣਾਓ",
  edit:"ਸੰਪਾਦਿਤ ਕਰੋ",
  destroy:"ਨਸ਼ਟ ਕਰੋ",
  day_start_time:"ਦਿਨ_ਸ਼ੁਰੂਆਤ_ਸਮਾਂ",
  day_end_time:"ਦਿਨ_ਅੰਤ_ਸਮਾਂ",
  fullname:"ਨਾਮ",
  time:"ਸਮਾਂ",
  auto_send:"ਸਵੈਚਲਿਤ ਤੌਰ 'ਤੇ ਭੇਜੋ",
  time_method:"ਸਮਾਂ ਵਿਧੀ",
  schedules:"ਸਮਾਂ-ਸਾਰਣੀ",
  indefinite_payroll_enabled:"ਅਣਮਿੱਥੇ ਸਮੇਂ ਲਈ ਯੋਗ ਕਰੋ",
  amount:"ਦੀ ਰਕਮ",
  repeat:"ਦੁਹਰਾਓ",
  always_enabled:"ਹਮੇਸ਼ਾ ਸਮਰਥਿਤ",
  start_date:"ਤਾਰੀਖ ਸ਼ੁਰੂ",
  end_date:"ਸਮਾਪਤੀ ਮਿਤੀ",
  Cheque_Total:"ਕੁੱਲ ਚੈੱਕ ਕਰੋ",
  Total_Amount:"ਕੁੱਲ ਮਾਤਰਾ:",
  Amounts:"ਮਾਤਰਾ:",
  Images:"ਚਿੱਤਰ",
  Files:"ਫਾਈਲਾਂ",
  Previewing:"ਪੂਰਵਦਰਸ਼ਨ:",
  Insert:"ਪਾਓ",
  Preview_Import:"ਪੂਰਵਦਰਸ਼ਨ ਆਯਾਤ",
  Entry:"ਦਾਖਲਾ",
  Entries:"ਇੰਦਰਾਜ਼",
  No_Entries:"ਕੋਈ ਐਂਟਰੀਆਂ ਨਹੀਂ",
  Import_Type:"ਆਯਾਤ ਦੀ ਕਿਸਮ",
  Select_Details:"ਵੇਰਵੇ ਚੁਣੋ",
  Select_Payee:"ਭੁਗਤਾਨ ਕਰਤਾ ਦੀ ਚੋਣ ਕਰੋ",
  Enable_Holidays:"ਛੁੱਟੀਆਂ ਨੂੰ ਚਾਲੂ ਕਰੋ",
  Disable_Holidays:"ਛੁੱਟੀਆਂ ਨੂੰ ਅਸਮਰੱਥ ਬਣਾਓ",
  Wire_Transfer:"ਤਾਰ ਇੰਤਕਾਲ",
  New_Export:"ਨਵਾਂ ਨਿਰਯਾਤ",
  Export_Data:"ਡਾਟਾ ਨਿਰਯਾਤ ਕਰੋ",
  Export_Data_Word:"ਨਿਰਯਾਤ ਅਤੇ ਡਾਊਨਲੋਡ ਕਰਨ ਲਈ ਫਾਈਲ ਦੀ ਕਿਸਮ ਚੁਣੋ।",
  Export_File:"ਨਿਰਯਾਤ ਫਾਇਲ",
  Mode:"ਮੋਡ",
  Times:"ਵਾਰ",
  Widgets:"ਵਿਜੇਟਸ",
  Slider:"ਸਲਾਈਡਰ",
  Set_Details:"ਵੇਰਵੇ ਸੈੱਟ ਕਰੋ",
  Select_Type:"ਕਿਸਮ ਚੁਣੋ",
  Display_Slider:"ਡਿਸਪਲੇ ਸਲਾਈਡਰ",
  Dashboard_Slider:"ਡੈਸ਼ਬੋਰਡ ਸਲਾਈਡਰ",
  Dashboard_Mode:"ਡੈਸ਼ਬੋਰਡ ਮੋਡ",
  Show_Intro:"ਪਛਾਣ ਦਿਖਾਓ",
  Show_Payrolls:"ਪੇਰੋਲ ਦਿਖਾਓ",
  Show_Holidays:"ਛੁੱਟੀਆਂ ਦਿਖਾਓ",
  Show_Invoices:"ਚਲਾਨ ਦਿਖਾਓ",
  Show_Cheques:"ਚੈੱਕ ਦਿਖਾਓ",
  Enabled_Widgets:"ਸਮਰਥਿਤ ਵਿਜੇਟਸ",
  Disabled_Widgets:"ਅਯੋਗ ਵਿਜੇਟਸ",
  Colors:"ਰੰਗ",
  Barcodes:"ਬਾਰਕੋਡ",
  View_Timers:"ਟਾਈਮਰ ਦੇਖੋ",
  Gradients:"ਗਰੇਡੀਐਂਟ",
  No_Info:"ਕੋਈ ਜਾਣਕਾਰੀ ਨਹੀਂ",
  Disable:"ਅਸਮਰੱਥ",
  Show_Layer:"ਪਰਤਾਂ ਦਿਖਾਓ",
  Hide_Layer:"ਲੇਅਰਾਂ ਨੂੰ ਲੁਕਾਓ",
  Text_Layer:"ਟੈਕਸਟ ਲੇਅਰਸ",
  Secondly:"ਦੂਜਾ",
  Minutely:"ਮਿੰਟ",
  nine_am:"ਸਵੇਰੇ 9:00 ਵਜੇ",
  five_pm:"ਸ਼ਾਮ 5:00 ਵਜੇ",
  Enable_Address:"ਪਤਾ ਚਾਲੂ ਕਰੋ",
  Invalid_File_Type:"ਮਾਫ਼ ਕਰਨਾ, ਇੱਕ ਅਵੈਧ ਫ਼ਾਈਲ ਕਿਸਮ ਚੁਣੀ ਗਈ ਸੀ। ਸਮਰਥਿਤ ਫਾਈਲ ਕਿਸਮ:",
  Error_Updating_Entry:"ਮਾਫ਼ ਕਰਨਾ, ਇਸ ਐਂਟਰੀ ਨੂੰ ਅੱਪਡੇਟ ਕਰਨ ਵਿੱਚ ਇੱਕ ਤਰੁੱਟੀ ਆਈ ਸੀ।",
  Schedule_Timing_Alert:"ਗੜਬੜ: ਸਮਾਂ-ਸਾਰਣੀ ਸ਼ੁਰੂ ਹੋਣ ਦਾ ਸਮਾਂ ਸਮਾਪਤੀ ਸਮੇਂ ਤੋਂ ਬਾਅਦ ਇੱਕ ਮੁੱਲ 'ਤੇ ਸੈੱਟ ਕੀਤਾ ਗਿਆ ਹੈ।",
  Select_Multiple_Payments_Prompt:"ਕੋਈ ਭੁਗਤਾਨ ਨਹੀਂ ਚੁਣਿਆ ਗਿਆ। ਘੱਟੋ-ਘੱਟ ਇੱਕ ਭੁਗਤਾਨ ਚੁਣੋ।",
  Select_Multiple_Cheques_Prompt:"ਕੋਈ ਚੈਕ ਨਹੀਂ ਚੁਣਿਆ ਗਿਆ। ਕਿਰਪਾ ਕਰਕੇ ਘੱਟੋ-ਘੱਟ ਇੱਕ ਜਾਂਚ ਚੁਣੋ।",
  Select_Multiple_Items_Prompt:"ਕੋਈ ਆਈਟਮਾਂ ਨਹੀਂ ਚੁਣੀਆਂ ਗਈਆਂ। ਕਿਰਪਾ ਕਰਕੇ ਘੱਟੋ-ਘੱਟ ਇੱਕ ਆਈਟਮ ਚੁਣੋ।",
  Paymemt_Multiple_Delete_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਕਈ ਭੁਗਤਾਨਾਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Cheque_Multiple_Delete_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਕਈ ਜਾਂਚਾਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Payee_Multiple_Delete_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਇੱਕ ਤੋਂ ਵੱਧ ਭੁਗਤਾਨ ਕਰਤਾਵਾਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Employee_Multiple_Delete_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ 'ਤੇ ਕਈ ਕਰਮਚਾਰੀਆਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  MICR_Warning:"ਨੋਟ: ਹੋ ਸਕਦਾ ਹੈ ਕਿ ਕੁਝ ਪ੍ਰਿੰਟਰ ਅਤੇ ਡਿਵਾਈਸਾਂ MICR ਫੌਂਟ ਨੂੰ ਸਹੀ ਢੰਗ ਨਾਲ ਪ੍ਰਦਰਸ਼ਿਤ ਨਾ ਕਰ ਸਕਣ।",
  Automatically_Send:"ਸਵੈਚਲਿਤ ਤੌਰ 'ਤੇ ਭੇਜੋ",
  Type:"ਟਾਈਪ ਕਰੋ",
  Payment_Type:"ਭੁਗਤਾਨ ਦੀ ਕਿਸਮ",
  Auto_Send:"ਆਟੋ ਭੇਜੋ",
  Automatically_Process:"ਆਟੋਮੈਟਿਕਲੀ ਪ੍ਰਕਿਰਿਆ",
  Auto_Process:"ਆਟੋ ਪ੍ਰਕਿਰਿਆ",
  Image_Based:"ਚਿੱਤਰ-ਆਧਾਰਿਤ",
  Font_Based:"ਫੌਂਟ-ਅਧਾਰਿਤ",
  Rerender:"ਮੁੜ-ਰੈਂਡਰ",
  Rendering:"ਪੇਸ਼ਕਾਰੀ",
  Render:"ਫਾਈਲਾਂ",
  Top:"ਸਿਖਰ",
  Middle:"ਮਿਡਲ",
  Bottom:"ਥੱਲੇ",
  Top_Left:"ਸਿਖਰ ਖੱਬੇ",
  Top_Center:"ਸਿਖਰ ਕੇਂਦਰ",
  Top_Right:"ਉੱਪਰ ਸੱਜੇ",
  Middle_Left:"ਮੱਧ ਖੱਬੇ",
  Middle_Center:"ਮੱਧ ਕੇਂਦਰ",
  Middle_Right:"ਮੱਧ ਸੱਜਾ",
  Bottom_Left:"ਹੇਠਾਂ ਖੱਬੇ ਪਾਸੇ",
  Bottom_Center:"ਹੇਠਲਾ ਕੇਂਦਰ",
  Bottom_Right:"ਹੇਠਾਂ ਸੱਜੇ",
  Numbers:"ਨੰਬਰ",
  Verified:"ਪ੍ਰਮਾਣਿਤ",
  Paper_Size:"ਕਾਗਜ਼ ਦਾ ਆਕਾਰ",
  New_Device:"ਨਵੀਂ ਡਿਵਾਈਸ",
  Add_Device:"ਡਿਵਾਈਸ ਸ਼ਾਮਲ ਕਰੋ",
  Remove_Device:"ਡਿਵਾਈਸ ਹਟਾਓ",
  Delete_Device:"ਡਿਵਾਈਸ ਮਿਟਾਓ",
  Block_Device:"ਡੀਵਾਈਸ ਨੂੰ ਬਲਾਕ ਕਰੋ",
  Block:"ਬਲਾਕ",
  Unblock:"ਅਨਬਲੌਕ ਕਰੋ",
  Table:"ਟੇਬਲ",
  Scan_Login_Code:"ਲੌਗਇਨ ਕੋਡ ਸਕੈਨ ਕਰੋ",
  Login_Code:"ਲੌਗਇਨ ਕੋਡ",
  Scan_Code:"ਸਕੈਨ ਕੋਡ",
  Scan_QR_Code:"QR ਕੋਡ ਸਕੈਨ ਕਰੋ",
  Select_All:"ਸਾਰਿਆ ਨੂੰ ਚੁਣੋ",
  Deselect_All:"ਸਭ ਨੂੰ ਅਣਚੁਣਿਆ ਕਰੋ",
  Increase:"ਵਧਾਓ",
  Decrease:"ਘਟਾਓ",
  Bold:"ਬੋਲਡ",
  Text:"ਟੈਕਸਟ",
  Style:"ਸ਼ੈਲੀ",
  Italic:"ਇਟਾਲਿਕ",
  QR_Code:"QR ਕੋਡ",
  Barcode:"ਬਾਰਕੋਡ",
  Browse_Images:"ਚਿੱਤਰਾਂ ਨੂੰ ਬ੍ਰਾਊਜ਼ ਕਰੋ",
  Browse_Files:"ਫ਼ਾਈਲਾਂ ਬ੍ਰਾਊਜ਼ ਕਰੋ",
  Background_Image:"ਬੈਕਗ੍ਰਾਊਂਡ ਚਿੱਤਰ",
  Logo_Image:"ਲੋਗੋ ਚਿੱਤਰ",
  Header_Image:"ਸਿਰਲੇਖ ਚਿੱਤਰ",
  Bank_Image:"ਬੈਂਕ ਚਿੱਤਰ",
  Cut_Lines:"ਲਾਈਨਾਂ ਕੱਟੋ",
  Background:"ਪਿਛੋਕੜ",
  License:"ਲਾਇਸੰਸ",
  One_License:"1 ਲਾਇਸੰਸ:",
  Licensed:"ਲਈ ਲਾਇਸੰਸਸ਼ੁਦਾ:",
  Not_Licensed:"ਲਾਇਸੰਸਸ਼ੁਦਾ ਨਹੀਂ ਹੈ",
  Enter_Serial:"ਸੀਰੀਅਲ ਦਰਜ ਕਰੋ",
  Serial_Key:"ਸੀਰੀਅਲ ਕੁੰਜੀ",
  Serial:"ਸੀਰੀਅਲ",
  Product_Key:"ਉਤਪਾਦ ਕੁੰਜੀ",
  Check_Product_Key:"ਉਤਪਾਦ ਕੁੰਜੀ ਦੀ ਜਾਂਚ ਕਰੋ",
  Add_Product_Key:"ਉਤਪਾਦ ਕੁੰਜੀ ਸ਼ਾਮਲ ਕਰੋ",
  Verifying_Purchase:"ਖਰੀਦ ਦੀ ਪੁਸ਼ਟੀ ਕੀਤੀ ਜਾ ਰਹੀ ਹੈ...",
  Print_Envelope:"ਲਿਫਾਫਾ ਪ੍ਰਿੰਟ ਕਰੋ",
  Envelope:"ਲਿਫ਼ਾਫ਼ਾ",
  Thank_You:"ਤੁਹਾਡਾ ਧੰਨਵਾਦ!",
  Scale:"ਸਕੇਲ",
  Print_Scale:"ਪ੍ਰਿੰਟ ਸਕੇਲ",
  Borders:"ਬਾਰਡਰ",
  VOID:"VOID",
  Void_Cheque:"ਵਿਅਰਥ ਜਾਂਚ",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ਦੇ ਆਰਡਰ ਲਈ ਭੁਗਤਾਨ ਕਰੋ:",
  NO_DOLLARS:"ਕੋਈ ਡਾਲਰ ਨਹੀਂ ",
  ONE_DOLLAR:"ਇੱਕ ਡਾਲਰ",
  DOLLARS:" ਡਾਲਰ",
  AND:" ਅਤੇ ",
  CENTS:" ਸੈਂ.",
  NO_:"ਸੰ ",
  ONE_:"ਇੱਕ ",
  AND_NO_:"ਅਤੇ ਸੰ ",
  _AND_ONE_:"ਅਤੇ ਇੱਕ ",
  DOLLARS_AND_ONE_CENT:" ਅਤੇ ਇੱਕ ਸੈਂਟ।",
  AND_NO_CENTS:"ਅਤੇ ਜ਼ੀਰੋ ਸੈਂਟ।",
  CHEQUE_PRINT_DATE:"ਤਾਰੀਖ਼:",
  CHEQUE_PRINT_MEMO:"ਮੀਮੋ:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"ਸ਼ੁਰੂਆਤੀ ਕੰਮ",
  Inital_Setup:"ਸ਼ੁਰੂਆਤੀ ਸੈੱਟਅੱਪ",
  Welcome_To:"ਸਵਾਗਤ ਹੈ ",
  Welcome:"ਜੀ ਆਇਆਂ ਨੂੰ",
  Swipe:"ਸਵਾਈਪ",
  Intro_Setup:"ਜਾਣ-ਪਛਾਣ ਸੈੱਟਅੱਪ",
  Introduction:"ਜਾਣ-ਪਛਾਣ",
  CHEQUE_PRINT_CREDIT:"ਚੈੱਕ ਦੁਆਰਾ ਸੰਚਾਲਿਤ",
  Title_Intro_Word:"ਚੈੱਕਾਂ ਵਿੱਚ ਸੁਆਗਤ ਹੈ",
  Title_Intro:"ਚੈੱਕ ਦੀ ਜਾਣ-ਪਛਾਣ",
  Title_Setup:"ਚੈੱਕ ਸੈੱਟਅੱਪ",
  PayPal_Default_Email_Message:"ਤੁਹਾਨੂੰ ਇੱਕ ਨਵਾਂ ਪੇਪਾਲ ਟ੍ਰਾਂਸਫਰ ਪ੍ਰਾਪਤ ਹੋਇਆ ਹੈ। [ਚੈੱਕ ਦੁਆਰਾ ਸੰਚਾਲਿਤ]",
  Cheques_App_Export:"ਚੈੱਕ ਦੁਆਰਾ ਨਿਰਯਾਤ",
  Post_Intro_Thanks:"ਚੈੱਕ ਚੁਣਨ ਲਈ ਤੁਹਾਡਾ ਧੰਨਵਾਦ। ਸੈੱਟਅੱਪ ਪ੍ਰਕਿਰਿਆ ਹੁਣ ਪੂਰੀ ਹੋ ਗਈ ਹੈ।",
  Post_Intro_Word:"ਆਪਣਾ ਪਹਿਲਾ ਚੈੱਕ ਛਾਪ ਕੇ ਸ਼ੁਰੂਆਤ ਕਰੋ, ਭਵਿੱਖ ਵਿੱਚ ਭੁਗਤਾਨ ਸ਼ਾਮਲ ਕਰੋ, ਜਾਂ ਤਨਖਾਹ ਵਿੱਚ ਕਰਮਚਾਰੀ ਸ਼ਾਮਲ ਕਰੋ।",
  Upgrade_Required:"ਇਸ ਸੁਨੇਹੇ ਨੂੰ ਲੁਕਾਉਣ ਅਤੇ ਵਾਧੂ ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ ਨੂੰ ਅਨਲੌਕ ਕਰਨ ਲਈ ਚੈੱਕਾਂ ਲਈ ਤੁਹਾਨੂੰ ਸਾਫਟਵੇਅਰ ਦੇ ਵਧੇਰੇ ਪ੍ਰੀਮੀਅਮ ਸੰਸਕਰਣ ਦੇ ਮਾਲਕ ਹੋਣ ਦੀ ਲੋੜ ਹੈ।",
  Upgrade_Title:"ਚੈੱਕ",
  Mailout_Prompt:"ਤੁਸੀਂ ਇਸ ਤੋਂ ਇਲਾਵਾ ਤੁਹਾਡੇ ਲਈ ਆਪਣੇ ਪੇਰੋਲ ਚੈੱਕਾਂ ਨੂੰ ਮੇਲ-ਆਊਟ ਕਰਨ ਦੀ ਚੋਣ ਕਰ ਸਕਦੇ ਹੋ।",
  Mailed_Cheque:"ਮੇਲ ਕੀਤੀ ਜਾਂਚ:",
  Mailed_Cheque_Color:"ਮੇਲ ਕੀਤੀ ਜਾਂਚ (ਰੰਗ):",
  Terms_Purchase:"ਚੈੱਕ ਦੇ ਨਾਲ ਸਾਰੀਆਂ ਇਲੈਕਟ੍ਰਾਨਿਕ ਖਰੀਦਦਾਰੀ ਖਰੀਦ ਮਿਤੀ ਤੋਂ 30-ਦਿਨਾਂ ਤੱਕ ਪੂਰੀ ਤਰ੍ਹਾਂ ਵਾਪਸੀਯੋਗ ਹਨ। ਕਿਰਪਾ ਕਰਕੇ ਜਾਰੀ ਰੱਖਣ ਤੋਂ ਪਹਿਲਾਂ <a href='#'>ਨਿਯਮ ਅਤੇ ਸ਼ਰਤਾਂ</a> ਨੂੰ ਪੜ੍ਹੋ ਅਤੇ ਸਹਿਮਤ ਹੋਵੋ।",
  Dashboard_Setup:"ਪ੍ਰਾਇਮਰੀ ਪ੍ਰਿੰਟਰ ਸੈੱਟਅੱਪ ਕਰੋ",
  Dashboard_Add:"ਪ੍ਰਾਇਮਰੀ ਬੈਂਕ ਖਾਤਾ ਜੋੜੋ",
  Dashboard_Customize:"ਚੈੱਕ ਟੈਂਪਲੇਟ ਦੀ ਚੋਣ ਕਰੋ",
  Dashboard_Job_Salary:"ਆਪਣੀ ਨੌਕਰੀ ਬਣਾਓ ਅਤੇ ਆਪਣੀ ਤਨਖਾਹ ਜੋੜੋ",
  Dashboard_Employees:"ਕਰਮਚਾਰੀਆਂ ਅਤੇ ਭੁਗਤਾਨ ਕਰਨ ਵਾਲਿਆਂ ਨੂੰ ਟਰੈਕ ਕਰੋ",
  Dashboard_Print:"ਆਪਣੇ ਪੇਚੈੱਕ ਛਾਪੋ ਅਤੇ ਮੇਲ ਕਰੋ",
  Dashboard_Payroll:"ਆਪਣੀ ਪੇਰੋਲ ਪ੍ਰਿੰਟਿੰਗ ਨੂੰ ਸਵੈਚਲਿਤ ਕਰੋ",
  Dashboard_PayPal:"ਪੇਪਾਲ ਪੇਰੋਲ / ਪੇਆਉਟ ਸੈੱਟਅੱਪ ਕਰੋ",
  Begin_Setup:"ਸੈੱਟਅੱਪ ਸ਼ੁਰੂ ਕਰੋ",
  Get_Started:"ਸ਼ੁਰੂ ਕਰੋ",
  Purchase:"ਖਰੀਦਣਾ",
  Lockdown:"ਤਾਲਾਬੰਦੀ",
  Unlock:"ਅਨਲੌਕ",
  Detailed:"ਵਿਸਤ੍ਰਿਤ",
  Log_In:"ਲਾਗਿਨ",
  Login:"ਲਾਗਿਨ",
  Launch:"ਲਾਂਚ",
  Register:"ਰਜਿਸਟਰ",
  Finish:"ਮੁਕੰਮਲ",
  List:"ਸੂਚੀ",
  Weekends:"ਵੀਕਐਂਡ",
  Weekly:"ਹਫਤਾਵਾਰੀ",
  PayPal_Default_Subject:"ਨਵਾਂ ਭੁਗਤਾਨ",
  Payment_Message:"ਭੁਗਤਾਨ ਸੁਨੇਹਾ",
  PayPal_Default_Payment_Note:"ਤੁਹਾਡਾ ਧੰਨਵਾਦ",
  Setup_Your_Cheqing_Account:"ਚੈਕਿੰਗ ਖਾਤਾ",
  Add_Your_Primary_Cheqing_Account:"ਆਪਣਾ ਪ੍ਰਾਇਮਰੀ ਚੈਕਿੰਗ ਖਾਤਾ ਸ਼ਾਮਲ ਕਰੋ।",
  Welcome_Word:"ਪੈਰੋਲ ਅਤੇ ਮਨੁੱਖੀ ਸਰੋਤ ਪ੍ਰਬੰਧਨ ਨੂੰ ਸਰਲ ਅਤੇ ਸਵੈਚਾਲਤ ਬਣਾਓ।",
  Get_Started_Quickly:"ਬਸ ਕੁਝ ਸਧਾਰਨ ਸਵਾਲਾਂ ਦੇ ਜਵਾਬ ਦਿਓ ਜੋ ਸ਼ੁਰੂ ਕਰਨ ਵਿੱਚ ਸਾਡੀ ਮਦਦ ਕਰਨਗੇ...",
  Done_Intro_Word:"ਸੈੱਟਅੱਪ ਪੂਰਾ",
  PIN_Protected:"ਪਾਸਵਰਡ ਅਤੇ ਪਿੰਨ ਸੁਰੱਖਿਅਤ",
  Enter_New_PIN:"ਇੱਕ ਨਵਾਂ ਪਿੰਨ ਕੋਡ ਦਾਖਲ ਕਰੋ:",
  Enter_PIN:"ਪਿੰਨ ਕੋਡ ਦਰਜ ਕਰੋ:",
  Invalid_PIN:"ਅਵੈਧ ਪਿੰਨ ਦਾਖਲ ਕੀਤਾ ਗਿਆ।",
  Account_Identifier:"ਖਾਤਾ ਪਛਾਣਕਰਤਾ",
  New_Account_Identifier:"ਨਵਾਂ ਖਾਤਾ ਪਛਾਣਕਰਤਾ",
  attempt:"ਕੋਸ਼ਿਸ਼",
  attempts:"ਕੋਸ਼ਿਸ਼ਾਂ",
  remaining:"ਬਾਕੀ",
  Language:"ਭਾਸ਼ਾ",
  languages:"ਭਾਸ਼ਾਵਾਂ",
  select_languages:"ਭਾਸ਼ਾ ਚੁਣੋ",
  Deposit:"ਜਮਾ",
  Hire_One_Now:"ਹੁਣ ਇੱਕ ਨੂੰ ਕਿਰਾਏ 'ਤੇ ਲਓ",
  App_Locked:"ਐਪਲੀਕੇਸ਼ਨ ਲਾਕ ਹੈ।",
  Skip_:"ਛੱਡੋ",
  Skip_to_Dashboard:"ਡੈਸ਼ਬੋਰਡ 'ਤੇ ਜਾਓ",
  Dashboard:"ਡੈਸ਼ਬੋਰਡ",
  Import:"ਆਯਾਤ",
  Admin:"ਪ੍ਰਬੰਧਕ",
  New_Admin:"ਨਵਾਂ ਐਡਮਿਨ",
  Settings:"ਸੈਟਿੰਗ",
  Color_Picker:"ਰੰਗ ਚੋਣਕਾਰ",
  Font_Picker:"ਫੌਂਟ ਚੋਣਕਾਰ",
  Logout:"ਲਾੱਗ ਆਊਟ, ਬਾਹਰ ਆਉਣਾ",
  Close:"ਬੰਦ",
  Close_menu:"ਬੰਦ",
  Excel:"ਐਕਸਲ ਫਾਈਲ",
  Csv:"CSV ਫਾਈਲ",
  Sql:"SQL ਫਾਈਲ",
  Json:"JSON ਫਾਈਲ",
  Url:"URL ਦੁਆਰਾ ਆਯਾਤ ਕਰੋ",
  Back:"ਵਾਪਸ",
  Timers:"ਟਾਈਮਰ",
  Cheque:"ਚੈਕ",
  Print:"ਛਾਪੋ",
  Designs:"ਡਿਜ਼ਾਈਨ",
  Pause_Printing:"ਪ੍ਰਿੰਟਿੰਗ ਰੋਕੋ",
  Resume_Printing:"ਪ੍ਰਿੰਟਿੰਗ ਮੁੜ ਸ਼ੁਰੂ ਕਰੋ",
  Printing_Paused:"ਪ੍ਰਿੰਟਿੰਗ ਰੋਕੀ ਗਈ",
  PrintingUnavailable:"ਮਾਫ਼ ਕਰਨਾ! ਇਸ ਸਿਸਟਮ 'ਤੇ ਪ੍ਰਿੰਟਿੰਗ ਉਪਲਬਧ ਨਹੀਂ ਹੈ।",
  Prints_Paused:"ਪ੍ਰਿੰਟਸ ਵਿਰਾਮ",
  Administration:"ਪ੍ਰਸ਼ਾਸਨ",
  Loading:"ਲੋਡਿੰਗ",
  Unnamed:"ਬੇਨਾਮ",
  error:"ਮਾਫ਼ ਕਰਨਾ, ਇਹ ਚੈੱਕ ਦੇਖਣ ਲਈ ਨਹੀਂ ਖੋਲ੍ਹਿਆ ਜਾ ਸਕਿਆ।",
  No_Cheques_To_Print:"ਪ੍ਰਿੰਟ ਕਰਨ ਲਈ ਕੋਈ ਚੈਕ ਨਹੀਂ",
  No_Cheques_To_Print_Word:"ਇੱਕ <a routerLink='/folder/Cheque/New'>ਨਵਾਂ ਚੈਕ</a> ਬਣਾਓ।",
  New_Cheque:"ਨਵੀਂ ਜਾਂਚ",
  Start_One_Now:"ਹੁਣ ਇੱਕ ਸ਼ੁਰੂ ਕਰੋ",
  Edit_Cheque:"ਚੈੱਕ ਸੰਪਾਦਿਤ ਕਰੋ",
  Select_Cheques:"ਚੈੱਕ ਚੁਣੋ",
  Select_Employee:"ਕਰਮਚਾਰੀ ਦੀ ਚੋਣ ਕਰੋ",
  Default_Printer:"ਡਿਫਾਲਟ ਪ੍ਰਿੰਟਰ",
  Reassign:"ਮੁੜ ਅਸਾਈਨ",
  Configure:"ਸੰਰਚਨਾ",
  Template:"ਟੈਂਪਲੇਟ",
  Designer:"ਡਿਜ਼ਾਈਨਰ",
  Edit_Designs:"ਡਿਜ਼ਾਇਨ ਸੰਪਾਦਿਤ ਕਰੋ",
  New_Design:"ਨਵਾਂ ਡਿਜ਼ਾਈਨ",
  Next:"ਅਗਲਾ",
  Save:"ਸੰਭਾਲੋ",
  Name:"ਨਾਮ",
  Mail:"ਮੇਲ",
  Amount:"ਦੀ ਰਕਮ",
  Date:"ਤਾਰੀਖ਼",
  PayPal:"ਪੇਪਾਲ",
  Payouts:"ਭੁਗਤਾਨ",
  PayPal_Label:"ਪੇਪਾਲ ਲੇਬਲ",
  Email_Username:"ਈਮੇਲ / ਉਪਭੋਗਤਾ ਨਾਮ",
  Client_ID:"ਗਾਹਕ ID",
  Sandbox_Email:"ਸੈਂਡਬਾਕਸ ਈਮੇਲ",
  PayPal_Email:"ਪੇਪਾਲ ਈਮੇਲ",
  PayPal_Username:"API ਉਪਭੋਗਤਾ ਨਾਮ",
  PayPal_Payouts:"ਪੇਪਾਲ ਭੁਗਤਾਨ",
  Select_PayPal_Key:"ਪੇਪਾਲ ਕੁੰਜੀ ਚੁਣੋ",
  Secret:"ਗੁਪਤ",
  API_Secret:"ਏਪੀਆਈ ਸੀਕਰੇਟ",
  PayPal_API_Keys:"ਪੇਪਾਲ ਕੁੰਜੀਆਂ",
  New_PayPal_Key:"ਨਵੀਂ ਪੇਪਾਲ ਕੁੰਜੀ",
  Email_Subject:"ਈਮੇਲ ਵਿਸ਼ਾ",
  Email_Message:"ਈਮੇਲ ਸੁਨੇਹਾ",
  Payout_Options:"ਭੁਗਤਾਨ ਵਿਕਲਪ",
  Payment_Note:"ਭੁਗਤਾਨ ਨੋਟ",
  Enable_Employee:"ਕਰਮਚਾਰੀ ਨੂੰ ਸਮਰੱਥ ਬਣਾਓ",
  Enable_Production_Mode:"ਉਤਪਾਦਨ ਮੋਡ ਨੂੰ ਸਮਰੱਥ ਬਣਾਓ",
  Sandbox_Username:"ਸੈਂਡਬਾਕਸ ਯੂਜ਼ਰਨੇਮ",
  Sandbox_Signature:"ਸੈਂਡਬਾਕਸ ਦਸਤਖਤ",
  Sandbox_Password:"ਸੈਂਡਬਾਕਸ ਪਾਸਵਰਡ",
  Production_Username:"ਉਤਪਾਦਨ ਉਪਭੋਗਤਾ ਨਾਮ",
  Production_Signature:"ਉਤਪਾਦਨ ਦਸਤਖਤ",
  Production_Password:"ਉਤਪਾਦਨ ਪਾਸਵਰਡ",
  Production_Email:"ਉਤਪਾਦਨ ਈਮੇਲ",
  API_Client_ID:"API ਕਲਾਇੰਟ ID",
  API_Signature:"API ਦਸਤਖਤ",
  API_Password:"API ਪਾਸਵਰਡ",
  API_Username:"API ਉਪਭੋਗਤਾ ਨਾਮ",
  Secret_Key:"ਗੁਪਤ ਕੁੰਜੀ",
  Sandbox:"ਸੈਂਡਬਾਕਸ",
  Production:"ਉਤਪਾਦਨ",
  Sandbox_Keys:"ਸੈਂਡਬਾਕਸ ਕੁੰਜੀਆਂ",
  Production_Keys:"ਉਤਪਾਦਨ ਕੁੰਜੀਆਂ",
  API_Title:"API ਸਿਰਲੇਖ",
  Production_Mode:"ਉਤਪਾਦਨ ਮੋਡ",
  Account_Label:"ਖਾਤਾ ਲੇਬਲ",
  No_PayPal_Setup:"ਕੋਈ ਪੇਪਾਲ ਕੁੰਜੀ ਨਹੀਂ",
  Enable_PayPal_Account:"ਪੇਪਾਲ ਖਾਤੇ ਨੂੰ ਸਮਰੱਥ ਬਣਾਓ",
  No_PayPal_Word:"ਆਪਣੀ <a routerLink='/folder/Invoice/New'>PayPal API ਕੁੰਜੀ</a> ਸ਼ਾਮਲ ਕਰੋ।",
  Printed_Memo:"ਪ੍ਰਿੰਟਿਡ ਮੀਮੋ",
  Employee:"ਕਰਮਚਾਰੀ",
  View_Employee:"ਕਰਮਚਾਰੀ ਵੇਖੋ",
  Mailing_Address:"ਮੇਲ ਭੇਜਣ ਦਾ ਪਤਾ",
  Company_Address:"ਕੰਪਨੀ ਦਾ ਪਤਾ",
  Select_Company_Address:"ਕੰਪਨੀ ਦਾ ਪਤਾ ਚੁਣੋ",
  Address:"ਪਤਾ",
  Any_Day:"ਕਿਸੇ ਵੀ ਦਿਨ",
  Address_Name:"ਪਤਾ ਨਾਮ",
  Unit:"ਯੂਨਿਟ",
  Account:"ਖਾਤਾ",
  Bank_Account:"ਬੈੰਕ ਖਾਤਾ",
  Account_Limits:"ਖਾਤਾ ਸੀਮਾਵਾਂ ਨੂੰ ਸਮਰੱਥ ਬਣਾਓ",
  Payroll:"ਪੇਰੋਲ",
  New_Payroll:"ਨਵੀਂ ਤਨਖਾਹ",
  No_Payroll:"ਕੋਈ ਆਗਾਮੀ ਤਨਖਾਹ ਨਹੀਂ",
  Upcoming_Holiday:"ਆਗਾਮੀ ਛੁੱਟੀਆਂ:",
  Invoice_Due:"ਇਨਵੌਇਸ ਬਕਾਇਆ:",
  New_Invoice:"ਨਵਾਂ ਚਲਾਨ",
  No_Invoices:"ਕੋਈ ਇਨਵੌਇਸ ਨਹੀਂ",
  No_Invoices_Word:"ਪਹਿਲਾ <a routerLink='/folder/Invoice/New'>ਇਨਵੌਇਸ</a> ਬਣਾਓ।",
  Cheque_Due:"ਚੈੱਕ ਬਕਾਇਆ:",
  Payrolls:"ਪੇਰੋਲ",
  Sandbox_Mode:"ਸੈਂਡਬਾਕਸ ਮੋਡ",
  Hire:"ਭਾੜੇ",
  Pay:"ਭੁਗਤਾਨ",
  New:"ਨਵਾਂ",
  Add:"ਸ਼ਾਮਲ ਕਰੋ",
  Make:"ਬਣਾਓ",
  Time:"ਸਮਾਂ",
  Write:"ਲਿਖੋ",
  Holiday:"ਛੁੱਟੀ",
  Holidays:"ਛੁੱਟੀਆਂ",
  Next_Holiday:"ਅਗਲੀ ਛੁੱਟੀ:",
  All_Done:"ਸਭ ਹੋ ਗਿਆ!",
  Employees:"ਕਰਮਚਾਰੀ",
  Payees:"ਭੁਗਤਾਨ ਕਰਨ ਵਾਲੇ",
  Job:"ਨੌਕਰੀ",
  Jobs:"ਨੌਕਰੀਆਂ",
  Invoice:"ਚਾਲਾਨ",
  Invoices:"ਇਨਵੌਇਸ",
  Vacations:"ਛੁੱਟੀਆਂ",
  Cheques:"ਚੈੱਕ",
  Brand_Cheques:"ਬ੍ਰਾਂਡ",
  Payment:"ਭੁਗਤਾਨ",
  Enable_Payment:"ਭੁਗਤਾਨ ਯੋਗ ਕਰੋ",
  Payments:"ਭੁਗਤਾਨ",
  Schedule:"ਸਮਾਸੂਚੀ, ਕਾਰਜ - ਕ੍ਰਮ",
  Schedules:"ਸ਼ਡਿਊਲ",
  Timesheet:"ਸਮਾਂ ਸ਼ੀਟ",
  Timesheets:"ਸਮਾਂ ਸ਼ੀਟਾਂ",
  Salary:"ਤਨਖਾਹ",
  New_Address:"ਨਵਾਂ ਪਤਾ",
  Address_2:"ਪਤਾ ਲਾਈਨ 2)",
  _City:"ਸ਼ਹਿਰ",
  _State:"ਰਾਜ/ਪ੍ਰੋਵ",
  City:"ਸ਼ਹਿਰ/ਟਾਊਨਸ਼ਿਪ",
  State:"ਰਾਜ / ਸੂਬਾ",
  Postal:"ਡਾਕ / ਜ਼ਿਪ ਕੋਡ",
  ZIP:"ਡਾਕ / ਜ਼ਿਪ",
  Country:"ਦੇਸ਼",
  Addresses:"ਪਤੇ",
  Required_Options:"ਲੋੜੀਂਦੇ ਵਿਕਲਪ",
  Optional_Options:"ਵਿਕਲਪਿਕ ਵਿਕਲਪ",
  Additional_Options:"ਵਾਧੂ ਵਿਕਲਪ",
  Required:"ਲੋੜੀਂਦਾ",
  Optional:"ਵਿਕਲਪਿਕ",
  Additional:"ਵਾਧੂ",
  No_Addresses:"ਕੋਈ ਪਤਾ ਨਹੀਂ",
  New_Address_Word:"ਪਹਿਲਾ <a routerLink='/folder/Address/New'>ਐਡਰੈੱਸ</a> ਸ਼ਾਮਲ ਕਰੋ।",
  Select_Address:"ਪਤਾ ਚੁਣੋ",
  No_Address:"ਕੋਈ ਪਤਾ ਨਹੀਂ",
  Print_Cheque:"ਪ੍ਰਿੰਟ ਚੈੱਕ",
  Print_Cheque_Now:"ਹੁਣੇ ਪ੍ਰਿੰਟ ਚੈੱਕ ਕਰੋ",
  Description:"ਵੇਰਵਾ",
  Pay_To_The_Order_Of:"ਦੇ ਆਰਡਰ ਲਈ ਭੁਗਤਾਨ ਕਰੋ:",
  Select_Date_Range:"ਤਾਰੀਖ ਰੇਂਜ ਚੁਣੋ",
  Select_Starting_Date:"ਸ਼ੁਰੂਆਤ ਮਿਤੀ ਦੀ ਚੋਣ ਕਰੋ",
  Select_Ending_Date:"ਸਮਾਪਤ ਮਿਤੀ ਚੁਣੋ",
  Select_Date:"ਤਾਰੀਖ ਚੁਣੋ",
  Cheque_Date:"ਤਾਰੀਖ ਦੀ ਜਾਂਚ ਕਰੋ",
  Cheque_Memo:"ਮੈਮੋ ਦੀ ਜਾਂਚ ਕਰੋ",
  Blank_Cheque:"ਖਾਲੀ ਜਾਂਚ",
  Blank:"ਖਾਲੀ",
  No_Cheques:"ਕੋਈ ਚੈਕ ਨਹੀਂ",
  No_Cheques_Word:"ਆਪਣਾ ਪਹਿਲਾ <a routerLink='/folder/Cheque/New'>ਚੈੱਕ</a> ਛਾਪੋ।",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"ਚਿੱਤਰ ਵੇਖੋ",
  View:"ਵੇਖੋ",
  Modify:"ਸੋਧੋ",
  Delete:"ਮਿਟਾਓ",
  Cheque_Paid:"ਦਾ ਭੁਗਤਾਨ",
  New_Deduction:"ਨਵੀਂ ਕਟੌਤੀ",
  Title:"ਸਿਰਲੇਖ",
  Frequency:"ਵਾਰਵਾਰਤਾ",
  Hourly:"ਘੰਟੇਵਾਰ",
  Daily:"ਰੋਜ਼ਾਨਾ",
  Weekdays:"ਹਫ਼ਤੇ ਦੇ ਦਿਨ",
  BiWeekly:"2 ਹਫ਼ਤੇ",
  TriWeekly:"3 ਹਫ਼ਤੇ",
  Monthly:"ਮਾਸਿਕ",
  MiMonthly:"2 ਮਹੀਨੇ",
  Quarterly:"ਤਿਮਾਹੀ",
  Yearly:"ਸਾਲਾਨਾ",
  Every_Week:"ਹਰੈਕ ਹਫ਼ਤੇ",
  Every_Payroll:"ਹਰ ਤਨਖਾਹ",
  Every_Month:"ਹਰ ਮਹੀਨੇ",
  Annually:"ਸਾਲਾਨਾ",
  Always_Scheduled:"ਹਮੇਸ਼ਾ ਤਹਿ",
  Start_Date:"ਤਾਰੀਖ ਸ਼ੁਰੂ",
  End_Date:"ਅੰਤ ਦੀ ਮਿਤੀ",
  Start_Time:"ਸ਼ੁਰੂ ਕਰਨ ਦਾ ਸਮਾਂ",
  End_Time:"ਅੰਤ ਦਾ ਸਮਾਂ",
  Deduction_Label:"ਕਟੌਤੀ ਲੇਬਲ",
  Notes:"ਨੋਟ",
  Options:"ਵਿਕਲਪ",
  Enable:"ਯੋਗ",
  Select_Deductions:"ਕਟੌਤੀਆਂ ਦੀ ਚੋਣ ਕਰੋ",
  Deductions:"ਕਟੌਤੀਆਂ",
  No_Deductions:"ਕੋਈ ਕਟੌਤੀ ਨਹੀਂ",
  No_Deductions_Word:"ਆਪਣਾ ਪਹਿਲਾ <a routerLink='/folder/Deduction/New'>ਕਟੌਤੀ</a> ਬਣਾਓ।",
  New_Employee:"ਨਵਾਂ ਕਰਮਚਾਰੀ",
  No_Title:"ਕੋਈ ਸਿਰਲੇਖ ਨਹੀਂ",
  _Name:"ਨਾਮ",
  About_Yourself:"ਆਪਣੇ ਬਾਰੇ",
  Full_Name:"ਪੂਰਾ ਨਾਂਮ",
  Birthday:"ਜਨਮ ਦਿਨ",
  Email:"ਈ - ਮੇਲ",
  SMS:"SMS",
  Phone:"ਫੋਨ",
  Test:"ਟੈਸਟ",
  Call:"ਕਾਲ",
  Fax:"ਫੈਕਸ",
  Printed_Note:"ਪ੍ਰਿੰਟ ਕੀਤਾ ਨੋਟ",
  Position:"ਪੋਜੀਸ਼ਨ",
  Job_Position:"ਨੌਕਰੀ ਸਥਿਤੀ",
  Select_a_Job:"ਕੋਈ ਨੌਕਰੀ ਚੁਣੋ",
  Select_a_Salary:"ਤਨਖਾਹ ਚੁਣੋ",
  Add_a_Deduction:"ਇੱਕ ਕਟੌਤੀ ਜੋੜੋ",
  Taxes:"ਟੈਕਸ",
  Add_Taxes:"ਟੈਕਸ ਜੋੜੋ",
  Date_of_Birth:"ਜਨਮ ਤਾਰੀਖ",
  Email_Address:"ਈਮੇਲ ਖਾਤਾ",
  Phone_Number:"ਫੋਨ ਨੰਬਰ",
  Phone_Call:"ਫੋਨ ਕਾਲ",
  Enable_on_Payroll:"ਪੇਰੋਲ 'ਤੇ ਯੋਗ ਕਰੋ",
  Select_Employees:"ਕਰਮਚਾਰੀ ਚੁਣੋ",
  No_Employees:"ਕੋਈ ਕਰਮਚਾਰੀ ਨਹੀਂ",
  No_Employees_Word:"ਆਪਣਾ ਪਹਿਲਾ ਨਵਾਂ <a routerLink='/folder/Employee/New'>ਕਰਮਚਾਰੀ</a> ਸ਼ਾਮਲ ਕਰੋ।",
  No_Name:"ਕੋਈ ਨਾਮ ਨਹੀਂ",
  Unemployeed:"ਬੇਰੁਜ਼ਗਾਰ",
  Employeed:"ਰੁਜ਼ਗਾਰ",
  Paid:"ਦਾ ਭੁਗਤਾਨ",
  Enabled:"ਸਮਰੱਥ",
  Disabled:"ਅਯੋਗ",
  Fire:"ਅੱਗ",
  Not_Available:"ਉਪਲਭਦ ਨਹੀ",
  Not_Available_Word:"ਆਪਣਾ ਪਹਿਲਾ <a routerLink='/folder/Invoice/New'>ਇਨਵੌਇਸ</a> ਪ੍ਰਿੰਟ ਕਰੋ ਅਤੇ ਭੁਗਤਾਨ ਕਰੋ।",
  Select_Invoices:"ਚੋਣ_ਇਨਵੌਇਸ",
  Print_Invoice_Word:"ਆਪਣਾ ਪਹਿਲਾ <a routerLink='/folder/Invoice/New'>ਇਨਵੌਇਸ</a> ਪ੍ਰਿੰਟ ਕਰੋ ਅਤੇ ਭੁਗਤਾਨ ਕਰੋ।",
  Invoice_Title:"ਇਨਵੌਇਸ ਟਾਈਟਲ",
  Invoice_Date:"ਇਨਵੌਇਸ ਮਿਤੀ",
  Due_Date:"ਅਦਾਇਗੀ ਤਾਰੀਖ",
  New_Job:"ਨਵਾ ਕੰਮ",
  Details:"ਵੇਰਵੇ",
  Customize:"ਕਸਟਮਾਈਜ਼",
  Customize_Dashboard:"ਡੈਸ਼ਬੋਰਡ ਨੂੰ ਅਨੁਕੂਲਿਤ ਕਰੋ",
  Components:"ਭਾਗ",
  No_Components:"ਕੋਈ ਭਾਗ ਨਹੀਂ",
  Main_Components:"ਮੁੱਖ ਭਾਗ",
  Smaller_Components:"ਛੋਟੇ ਭਾਗ",
  Error_Loading_Page:"ਇਸ ਪੰਨੇ ਨੂੰ ਲੋਡ ਕਰਨ ਵਿੱਚ ਗਲਤੀ।",
  Bank_Details:"ਬੈਂਕ ਵੇਰਵੇ",
  About_Your_Job:"ਤੁਹਾਡੀ ਨੌਕਰੀ ਬਾਰੇ",
  Your_Schedule:"ਤੁਹਾਡੀ ਸਮਾਂ ਸੂਚੀ",
  Job_Title:"ਕੰਮ ਦਾ ਟਾਈਟਲ",
  Job_Description:"ਕੰਮ ਦਾ ਵੇਰਵਾ",
  Job_Details:"ਨੌਕਰੀ ਦਾ ਵੇਰਵਾ",
  Enable_Job:"ਨੌਕਰੀ ਯੋਗ ਕਰੋ",
  Pay_Period:"ਭੁਗਤਾਨ ਦੀ ਮਿਆਦ",
  Perpetually_Schedule:"ਸਥਾਈ ਤੌਰ 'ਤੇ ਅਨੁਸੂਚੀ",
  Select_Jobs:"ਨੌਕਰੀਆਂ ਦੀ ਚੋਣ ਕਰੋ",
  No_Jobs:"ਕੋਈ ਨੌਕਰੀ ਨਹੀਂ",
  Add_Jobs:"ਨੌਕਰੀਆਂ ਸ਼ਾਮਲ ਕਰੋ",
  No_Jobs_Word:"ਸੂਚੀ ਵਿੱਚ ਪਹਿਲਾ <a routerLink='/folder/Job/New'>ਨੌਕਰੀ</a> ਸ਼ਾਮਲ ਕਰੋ।",
  Count_Employees:"job.employee_count+' ਕਰਮਚਾਰੀ'",
  Zero_Employees:"0 ਕਰਮਚਾਰੀ",
  New_Leave:"ਨਵੀਂ ਛੁੱਟੀ",
  Leave_Name:"ਨਾਮ ਛੱਡੋ",
  Paid_Leave:"ਅਦਾਇਗੀ ਛੁੱਟੀ",
  Leave_Pay:"ਪੇਅ ਛੱਡੋ",
  Indefinite_Leave:"ਅਨਿਸ਼ਚਿਤ ਛੁੱਟੀ",
  Indefinite_Payroll:"ਅਨਿਸ਼ਚਿਤ ਤਨਖਾਹ",
  Leave:"ਛੱਡੋ",
  Add_Schedules:"ਸ਼ਡਿਊਲ ਜੋੜੋ",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"ਗੈਰਹਾਜ਼ਰੀ ਯੋਗ ਕਰੋ",
  Select_Leaves:"ਪੱਤੀਆਂ ਦੀ ਚੋਣ ਕਰੋ",
  No_Leaves:"ਗੈਰਹਾਜ਼ਰੀ ਦੀ ਕੋਈ ਪੱਤੀ ਨਹੀਂ",
  Leave_Of_Absence:"ਗੈਰਹਾਜ਼ਰੀ ਦੀ ਛੁੱਟੀ",
  Leaves_Of_Absence:"ਗੈਰਹਾਜ਼ਰੀ ਦੇ ਪੱਤੇ",
  New_Leave_of_Absense:"ਗੈਰਹਾਜ਼ਰੀ ਦੀ ਨਵੀਂ ਛੁੱਟੀ",
  No_Leaves_Word:"ਪਹਿਲਾ <a routerLink='/folder/Leave/New'>ਗੈਰਹਾਜ਼ਰੀ ਦੀ ਛੁੱਟੀ</a> ਸ਼ਾਮਲ ਕਰੋ।",
  Not_Enabled:"ਯੋਗ ਨਹੀਂ",
  Absences:"ਗੈਰਹਾਜ਼ਰੀ",
  Payee:"ਭੁਗਤਾਨ ਕਰਨ ਵਾਲਾ",
  New_Payee:"ਨਵਾਂ ਭੁਗਤਾਨਕਰਤਾ",
  Payee_Identifier:"ਭੁਗਤਾਨਕਰਤਾ ਪਛਾਣਕਰਤਾ",
  Payee_Name:"ਭੁਗਤਾਨ ਕਰਨ ਵਾਲੇ ਦਾ ਨਾਮ",
  Payee_Title:"ਭੁਗਤਾਨ ਕਰਨ ਵਾਲਾ ਸਿਰਲੇਖ",
  Payment_Memo:"ਭੁਗਤਾਨ ਮੈਮੋ",
  Select_Payees:"ਭੁਗਤਾਨਕਰਤਾ ਚੁਣੋ",
  No_Payees:"ਕੋਈ ਭੁਗਤਾਨ ਕਰਨ ਵਾਲੇ ਨਹੀਂ",
  Add_Payee_Note:"ਪਹਿਲਾ <a routerLink='/folder/Payee/New'>ਭੁਗਤਾਨ ਕਰਨ ਵਾਲੇ</a> ਸ਼ਾਮਲ ਕਰੋ।",
  New_Payees:"ਨਵੇਂ ਭੁਗਤਾਨ ਕਰਨ ਵਾਲੇ",
  About_The_Payment_Schedule:"ਭੁਗਤਾਨ ਅਨੁਸੂਚੀ",
  Your_Payroll_Schedule:"ਆਟੋਮੈਟਿਕ ਪੇਰੋਲ",
  New_Payment:"ਨਵਾਂ ਭੁਗਤਾਨ",
  Identifier:"ਪਛਾਣਕਰਤਾ",
  Select:"ਚੁਣੋ",
  Memo:"ਮੀਮੋ",
  Payment_Date:"ਭੁਗਤਾਨ ਦੀ ਮਿਤੀ",
  Mark_as_Paid:"ਭੁਗਤਾਨ ਵਜੋਂ ਮਾਰਕ ਕਰੋ",
  Select_Payments:"ਭੁਗਤਾਨ ਚੁਣੋ",
  No_Payments:"ਕੋਈ ਭੁਗਤਾਨ ਨਹੀਂ",
  No_Payments_Word:"ਪਹਿਲਾ <a routerLink='/folder/Payment/New'>ਭੁਗਤਾਨ</a> ਬਣਾਓ।",
  Create_Payroll:"ਪੇਰੋਲ ਬਣਾਓ",
  Properties:"ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ",
  Payroll_Title:"ਪੇਰੋਲ ਟਾਈਟਲ",
  Payroll_Notes:"ਪੇਰੋਲ ਨੋਟਸ",
  Payroll_Setup:"ਪੇਰੋਲ ਸੈੱਟਅੱਪ",
  Tabulate_Payments:"ਭੁਗਤਾਨ ਸਾਰਣੀ",
  Tabulate:"ਸਾਰਣੀ",
  By:"ਨਾਲ:",
  Payments_By:"ਦੁਆਰਾ ਭੁਗਤਾਨ",
  Timesheets_And_Schedules:"ਸਮਾਂ ਸ਼ੀਟਾਂ ਅਤੇ ਸਮਾਂ-ਸਾਰਣੀ",
  Both:"ਦੋਵੇਂ",
  Items:"ਇਕਾਈ",
  Add_Payees:"ਭੁਗਤਾਨਕਰਤਾ ਸ਼ਾਮਲ ਕਰੋ",
  Add_Account:"ਖਾਤਾ ਜੋੜੋ",
  Enable_Account:"ਖਾਤਾ ਯੋਗ ਕਰੋ",
  Enable_Payroll:"ਪੇਰੋਲ ਨੂੰ ਸਮਰੱਥ ਬਣਾਓ",
  Print_Payroll:"ਪ੍ਰਿੰਟ ਪੇਰੋਲ",
  No_Payrolls:"ਕੋਈ ਤਨਖਾਹ ਨਹੀਂ",
  No_Payroll_Word:"ਆਪਣਾ ਪਹਿਲਾ <a routerLink='/folder/Payroll/New'>ਤਨਖਾਹ</a> ਬਣਾਓ।",
  View_more:"VIEW_MORE",
  Less:"ਘੱਟ",
  Add_Payroll:"ਪੈਰੋਲ ਸ਼ਾਮਲ ਕਰੋ",
  Select_Payroll:"ਪੇਰੋਲ ਚੁਣੋ",
  About_Your_Salary:"ਤੁਹਾਡੀ ਤਨਖਾਹ ਬਾਰੇ",
  Add_Salaries:"ਤਨਖਾਹ ਜੋੜੋ",
  Add_Salary:"ਤਨਖਾਹ ਜੋੜੋ",
  Salaries:"ਤਨਖਾਹ",
  No_Salaries:"ਕੋਈ ਤਨਖਾਹ ਨਹੀਂ",
  No_Salaries_Word:"ਪਹਿਲਾ <a routerLink='/folder/Salary/New'>ਤਨਖਾਹ</a> ਸ਼ਾਮਲ ਕਰੋ।",
  Select_Salaries:"ਤਨਖਾਹ ਚੁਣੋ",
  New_Salary:"ਨਵੀਂ ਤਨਖਾਹ",
  Salary_Name:"ਤਨਖਾਹ ਪਛਾਣਕਰਤਾ",
  Enable_Salary:"ਤਨਖਾਹ ਯੋਗ ਕਰੋ",
  Salary_Amount:"ਤਨਖਾਹ ਦੀ ਰਕਮ",
  New_Schedule:"ਨਵੀਂ ਸਮਾਂ ਸੂਚੀ",
  Schedule_Title:"ਸਡਿਊਲ ਟਾਈਟਲ",
  Add_Address:"ਪਤਾ ਜੋੜੋ",
  Repeat:"ਦੁਹਰਾਓ",
  Design:"ਡਿਜ਼ਾਈਨ",
  Edit_Design:"ਡਿਜ਼ਾਇਨ ਸੋਧੋ",
  Edit_this_Design:"ਇਸ ਡਿਜ਼ਾਈਨ ਨੂੰ ਸੋਧੋ",
  Repeat_Payment:"ਦੁਹਰਾਓ ਭੁਗਤਾਨ",
  Enable_Schedule:"ਸ਼ਡਿਊਲ ਯੋਗ ਕਰੋ",
  Never:"ਕਦੇ ਨਹੀਂ",
  Select_Schedule:"ਸ਼ਡਿਊਲ ਚੁਣੋ",
  No_Schedules:"ਕੋਈ ਸਮਾਂ-ਸੂਚੀ ਨਹੀਂ",
  No_Schedules_Word:"ਪਹਿਲਾ <a routerLink='/folder/Schedule/New'>ਸ਼ਡਿਊਲ</a> ਬਣਾਓ।",
  New_Administrator:"ਨਵਾਂ ਪ੍ਰਸ਼ਾਸਕ",
  Username:"ਉਪਭੋਗਤਾ ਨਾਮ",
  First_Name:"ਪਹਿਲਾ ਨਾਂ",
  Last_Name:"ਆਖੀਰਲਾ ਨਾਂਮ",
  Add_an_Address:"ਇੱਕ ਪਤਾ ਜੋੜੋ",
  Payment_Limit:"ਪ੍ਰਤੀ-ਭੁਗਤਾਨ ਸੀਮਾ",
  Total_Limit:"ਕੁੱਲ ਸੀਮਾ",
  Select_Accounts:"ਖਾਤੇ ਚੁਣੋ",
  No_Administrators:"ਕੋਈ ਪ੍ਰਸ਼ਾਸਕ ਨਹੀਂ",
  No_Administrators_Word:"ਪਹਿਲਾ <a routerLink='/folder/Administrator/New'>ਪ੍ਰਬੰਧਕ ਖਾਤਾ</a> ਬਣਾਓ।",
  New_Administrators_Word:"ਪਹਿਲਾ <a routerLink='/folder/Administrator/New'>ਪ੍ਰਬੰਧਕ</a> ਸ਼ਾਮਲ ਕਰੋ",
  Cloud:"ਬੱਦਲ",
  Backup:"ਬੈਕਅੱਪ",
  Enable_iCloud:"iCloud ਯੋਗ ਕਰੋ",
  Enable_Google_Drive:"ਗੂਗਲ ਡਰਾਈਵ ਨੂੰ ਸਮਰੱਥ ਬਣਾਓ",
  Enable_Microsoft_OneDrive:"Microsoft OneDrive ਨੂੰ ਸਮਰੱਥ ਬਣਾਓ",
  Automatically_Backup:"ਆਟੋਮੈਟਿਕ ਬੈਕਅੱਪ",
  FTP_Settings:"FTP ਸੈਟਿੰਗ",
  URL_File_Prompt:"ਕਿਰਪਾ ਕਰਕੇ ਆਯਾਤ ਕਰਨ ਲਈ .xls / .xlsx / .json ਫਾਈਲ ਲਈ ਟਿਕਾਣਾ ਦਿਓ:",
  URL_SQL_Prompt:"ਕਿਰਪਾ ਕਰਕੇ ਆਯਾਤ ਕਰਨ ਲਈ SQLite ਫਾਈਲ ਦਾ ਟਿਕਾਣਾ ਦਿਓ:",
  FTP_Backup:"FTP ਬੈਕਅੱਪ",
  FTP_Import:"FTP ਆਯਾਤ",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"ਮੇਜ਼ਬਾਨ",
  Port:"ਪੋਰਟ",
  Path:"ਮਾਰਗ",
  Data_Path:"ਡਾਟਾ ਮਾਰਗ",
  Enable_FTP_Account:"FTP ਖਾਤਾ ਯੋਗ ਕਰੋ",
  HostnameIP:"ਹੋਸਟਨਾਮ",
  Password:"ਪਾਸਵਰਡ",
  Connection_Port:"ਕੁਨੈਕਸ਼ਨ ਪੋਰਟ",
  Enable_MySQL_Database:"MySQL ਡੇਟਾਬੇਸ ਨੂੰ ਸਮਰੱਥ ਬਣਾਓ",
  Log:"ਲਾਗ",
  Reset:"ਰੀਸੈੱਟ",
  Erase:"ਮਿਟਾਓ",
  Export:"ਨਿਰਯਾਤ",
  Database:"ਡਾਟਾਬੇਸ",
  Upload_CSV:"CSV ਅੱਪਲੋਡ ਕਰੋ",
  Download_CSV:"CSV ਡਾਊਨਲੋਡ ਕਰੋ",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL ਬੈਕਅੱਪ",
  Internal_Notes:"ਅੰਦਰੂਨੀ ਨੋਟਸ",
  Root_Path:"ਰੂਟ ਮਾਰਗ",
  Select_Backup:"ਬੈਕਅੱਪ ਚੁਣੋ",
  Add_New_Backup:"ਇੱਕ ਨਵਾਂ ਬੈਕਅੱਪ ਸ਼ਾਮਲ ਕਰੋ",
  First_Backup:"ਪਹਿਲਾ ਬੈਕਅੱਪ ਸੈੱਟਅੱਪ ਕਰੋ...",
  Backups:"ਬੈਕਅੱਪ",
  Add_Backup:"ਬੈਕਅੱਪ ਸ਼ਾਮਲ ਕਰੋ",
  No_Backups:"ਲੱਭਣ ਲਈ ਕੋਈ ਬੈਕਅੱਪ ਨਹੀਂ ਹਨ।",
  Select_Backup_Type:"ਬੈਕਅੱਪ ਦੀ ਕਿਸਮ ਚੁਣੋ ਜਿਸ ਨੂੰ ਤੁਸੀਂ ਸੈੱਟਅੱਪ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ...",
  Backup_Type:"ਬੈਕਅੱਪ ਕਿਸਮ",
  FTP_Drive:"FTP ਡਰਾਈਵ",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"ਚਲਾਉਣਾ",
  Microsoft_OneDrive:"ਚਲਾਉਣਾ",
  Import_Fields:"ਆਯਾਤ ਖੇਤਰ",
  Import_Fields_Word:"<a routerLink='/folder/Holiday/New'>ਇੰਪੋਰਟ</a> ਡਾਟਾ ਚੁਣਨ ਲਈ ਇਸ ਸੈਕਸ਼ਨ ਦੀ ਵਰਤੋਂ ਕਰੋ।",
  Upload:"ਅੱਪਲੋਡ",
  Download:"ਡਾਊਨਲੋਡ ਕਰੋ",
  Download_JSON:"JSON ਡੇਟਾ ਦੇ ਤੌਰ ਤੇ ਡਾਊਨਲੋਡ ਕਰੋ",
  Download_SQL:"SQL ਫਾਈਲ ਦੇ ਤੌਰ ਤੇ ਡਾਊਨਲੋਡ ਕਰੋ",
  New_Bank:"ਨਵਾਂ ਬੈਂਕ",
  New_Account:"ਨਵਾ ਖਾਤਾ",
  New_Bank_Account:"ਨਵਾਂ ਬੈਂਕ ਖਾਤਾ",
  Upload_Image:"ਚਿੱਤਰ ਅੱਪਲੋਡ ਕਰੋ",
  Bank_Name:"ਬੈਂਕ ਦਾ ਨਾਮ",
  Bank_Address:"ਬੈਂਕ ਦਾ ਪਤਾ",
  Branch_Address:"ਸ਼ਾਖਾ ਦਾ ਪਤਾ",
  Address_on_Cheque:"ਪਤਾ",
  Cheque_Numbers:"ਨੰਬਰ ਦੀ ਜਾਂਚ ਕਰੋ",
  Cheque_Details:"ਵੇਰਵਿਆਂ ਦੀ ਜਾਂਚ ਕਰੋ",
  Bank_Logo:"ਬੈਂਕ ਲੋਗੋ",
  Cheque_ID:"ਆਈਡੀ ਦੀ ਜਾਂਚ ਕਰੋ",
  Starting_Cheque_ID:"ਚੈੱਕ ID ਸ਼ੁਰੂ ਕਰ ਰਿਹਾ ਹੈ",
  Transit_Number:"ਟ੍ਰਾਂਜ਼ਿਟ ਨੰਬਰ",
  Institution_Number:"ਸੰਸਥਾ ਦਾ ਨੰਬਰ",
  Designation_Number:"ਅਹੁਦਾ ਨੰਬਰ",
  Account_Number:"ਅਕਾਊਂਟ ਨੰਬਰ",
  Limits:"ਸੀਮਾਵਾਂ",
  Default_Limits:"ਡਿਫਾਲਟ ਸੀਮਾਵਾਂ",
  Over_Limit:"ਸੀਮਾ ਤੋਂ ਵੱਧ",
  Under_Limit:"ਸੀਮਾ ਦੇ ਅਧੀਨ",
  Payroll_Limit:"ਪੇਰੋਲ ਸੀਮਾ",
  Enable_Bank_Account:"ਬੈਂਕ ਖਾਤਾ ਚਾਲੂ ਕਰੋ",
  Select_Account:"ਖਾਤਾ ਚੁਣੋ",
  No_Bank_Account:"ਕੋਈ ਬੈਂਕ ਖਾਤਾ ਨਹੀਂ",
  No_Bank_Account_Word:"ਪਹਿਲਾ <a routerLink='/folder/Accounts/New'>ਬੈਂਕ ਖਾਤਾ</a> ਸ਼ਾਮਲ ਕਰੋ।",
  Bank_Accounts:"ਬੈਂਕ ਖਾਤੇ",
  No_Accounts:"ਕੋਈ ਖਾਤੇ ਨਹੀਂ",
  No_Accounts_Note:"ਪਹਿਲਾ <a routerLink='/folder/Accounts/New'>ਬੈਂਕ ਖਾਤਾ</a> ਸ਼ਾਮਲ ਕਰੋ।",
  Cheque_Designer:"ਡਿਜ਼ਾਈਨਰ ਦੀ ਜਾਂਚ ਕਰੋ",
  Cheque_Design:"ਡਿਜ਼ਾਇਨ ਦੀ ਜਾਂਚ ਕਰੋ",
  Select_Design:"ਇੱਕ ਡਿਜ਼ਾਈਨ ਚੁਣੋ",
  Cheque_Designs:"ਡਿਜ਼ਾਇਨ ਦੀ ਜਾਂਚ ਕਰੋ",
  No_Cheque_Designs:"ਕੋਈ ਜਾਂਚ ਡਿਜ਼ਾਈਨ ਨਹੀਂ",
  No_Cheque_Designs_Word:"ਆਪਣਾ ਖੁਦ ਦਾ <a routerLink='/folder/Settings/Cheque/Design/New'>ਚੈਕ ਡਿਜ਼ਾਈਨ</a> ਬਣਾਓ।",
  Set_Default:"ਨੂੰ ਮੂਲ ਰੂਪ ਵਿੱਚ ਸੈੱਟ ਕੀਤਾ",
  Default:"ਡਿਫਾਲਟ",
  Remove:"ਹਟਾਓ",
  Folder:"ਫੋਲਡਰ",
  Edit:"ਸੋਧ",
  LoadingDots:"ਲੋਡ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ...",
  Add_a_New_File:"ਇਸ ਵਿੱਚ ਇੱਕ <a href='#' (click)='add()'>ਨਵੀਂ ਫ਼ਾਈਲ</a> ਸ਼ਾਮਲ ਕਰੋ",
  this_folder:"ਇਹ ਫੋਲਡਰ",
  FTP_Backup_Settings:"FTP ਬੈਕਅੱਪ ਸੈਟਿੰਗ",
  Secure_File_Transfer:"ਸੁਰੱਖਿਅਤ ਫਾਈਲ ਟ੍ਰਾਂਸਫਰ",
  New_Holiday:"ਨਵੀਂ ਛੁੱਟੀ",
  Add_Holiday:"ਛੁੱਟੀ ਸ਼ਾਮਲ ਕਰੋ",
  Holiday_Name:"ਛੁੱਟੀ ਦਾ ਨਾਮ",
  Additional_Pay:"ਵਾਧੂ ਤਨਖਾਹ",
  Enable_Holiday:"ਛੁੱਟੀ ਯੋਗ ਕਰੋ",
  Select_Holidays:"ਛੁੱਟੀਆਂ ਚੁਣੋ",
  No_Holidays:"ਕੋਈ ਛੁੱਟੀਆਂ ਨਹੀਂ",
  No_Holidays_Word:"ਪਹਿਲਾ <a routerLink='/folder/Holiday/New'>ਜਨਤਕ ਛੁੱਟੀਆਂ</a> ਸ਼ਾਮਲ ਕਰੋ।",
  New_Import:"ਨਵਾਂ ਆਯਾਤ",
  Import_Data:"ਡਾਟਾ ਆਯਾਤ ਕਰੋ",
  Import_Data_Word:"ਆਪਣੀ ਪਸੰਦ ਦੀ ਫਾਈਲ ਦੀ ਕਿਸਮ ਜਾਂ ਅਪਲੋਡ ਵਿਧੀ ਦੀ ਚੋਣ ਕਰੋ।<br /> ਤੁਸੀਂ ਸਮਰਥਿਤ ਫਾਈਲ ਨੂੰ ਅਪਲੋਡ ਕਰਨ ਤੋਂ ਬਾਅਦ ਐਪ ਵਿੱਚ ਕਿਸੇ ਵੀ ਮਾਪਦੰਡ ਦੇ ਅਨੁਸਾਰੀ ਫਾਈਲ ਵਿੱਚ ਜੋ ਵੀ ਆਯਾਤ ਕੀਤੇ ਖੇਤਰਾਂ ਨੂੰ ਚੁਣਨ ਦੇ ਯੋਗ ਹੋਵੋਗੇ।", 
  Import_File:"ਫਾਇਲ ਆਯਾਤ ਕਰੋ",
  Link_To_File:"ਫਾਇਲ ਨਾਲ ਲਿੰਕ",
  Select_File:"ਫਾਇਲ ਚੁਣੋ",
  New_Moderator:"ਨਵਾਂ ਸੰਚਾਲਕ",
  Allow_Moderation:"ਸੰਚਾਲਨ ਦੀ ਇਜਾਜ਼ਤ ਦਿਓ",
  Create_Paycheques:"ਪੇਚੈੱਕ ਬਣਾਓ",
  Edit_Paycheques_and_Data:"ਪੇਚੈਕ ਅਤੇ ਡੇਟਾ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰੋ",
  Destroy_Data_and_Paycheques:"ਡੇਟਾ ਅਤੇ ਪੇਚੈਕਾਂ ਨੂੰ ਨਸ਼ਟ ਕਰੋ",
  Bonus_Percentage:"ਪੇਚੈਕ ਪ੍ਰਤੀਸ਼ਤ",
  Fixed_Amount:"ਨਿਸ਼ਚਿਤ ਰਕਮ",
  Select_Moderator:"ਸੰਚਾਲਕ ਚੁਣੋ",
  No_Moderators:"ਕੋਈ ਸੰਚਾਲਕ ਨਹੀਂ",
  Moderators:"ਸੰਚਾਲਕ",
  Moderator_Account:"ਪਹਿਲਾ <a routerLink='/folder/Administrator/New'>ਸੰਚਾਲਕ ਖਾਤਾ</a> ਬਣਾਓ।",
  No_Moderators_Word:"ਪਹਿਲਾ <a routerLink='/folder/Administrator/New'>ਸੰਚਾਲਕ</a> ਸ਼ਾਮਲ ਕਰੋ।",
  Defaults:"ਡਿਫਾਲਟ",
  Provide_Us_Info:"ਸਾਨੂੰ ਜਾਣਕਾਰੀ ਪ੍ਰਦਾਨ ਕਰੋ",
  Setup_Your_Printer:"ਤੁਹਾਡਾ ਪ੍ਰਿੰਟਰ ਸੈੱਟਅੱਪ ਕਰੋ",
  Your_Company:"ਤੁਹਾਡੀ ਕੰਪਨੀ ਬਾਰੇ",
  Company_Name:"ਕੰਪਨੀ ਦਾ ਨਾਂ",
  Currency:"ਮੁਦਰਾ",
  Default_Currency:"ਡਿਫਾਲਟ ਮੁਦਰਾ",
  Base_Monthly_Income:"ਮਾਸੀਕ ਆਮਦਨ",
  Protection:"ਸੁਰੱਖਿਆ",
  App_Protection:"ਐਪ ਪ੍ਰੋਟੈਕਸ਼ਨ",
  PIN_Code_Protection:"ਪਿੰਨ ਕੋਡ ਸੁਰੱਖਿਆ",
  App_Protection_Word:"ਸੁਰੱਖਿਆ ਵਿਧੀਆਂ ਨੂੰ ਸਮਰੱਥ ਬਣਾਓ ਜੋ ਤੁਹਾਡੇ ਖਾਤੇ ਦੀ ਸੁਰੱਖਿਆ ਵਿੱਚ ਮਦਦ ਕਰਦੇ ਹਨ।",
  PIN_Code:"ਪਿੰਨ ਕੋਡ",
  Change_PIN:"ਪਿੰਨ ਬਦਲੋ",
  New_Password:"ਨਵਾਂ ਪਾਸਵਰਡ",
  Geofence_Protection:"ਜੀਓ-ਫੈਂਸ ਪ੍ਰੋਟੈਕਸ਼ਨ",
  Geofence_Area:"ਸੈਟ ਏਰੀਆ",
  Distance:"ਦੂਰੀ",
  Setup_Now:"ਹੁਣ ਸੈੱਟਅੱਪ ਕਰੋ",
  Mile:"ਮੀਲ",
  Km:"ਕਿਮੀ",
  m:"m",
  Facial_Recognition:"ਚਿਹਰੇ ਦੀ ਪਛਾਣ",
  Face:"ਚਿਹਰਾ",
  Setup:"ਸਥਾਪਨਾ ਕਰਨਾ",
  Label:"ਲੇਬਲ",
  Password_Protection:"ਪਾਸਵਰਡ ਸੁਰੱਖਿਆ",
  Modify_Password:"ਪਾਸਵਰਡ ਸੋਧੋ",
  New_Tax_Entry:"ਨਵੀਂ ਟੈਕਸ ਐਂਟਰੀ",
  New_Tax:"ਨਵਾਂ ਟੈਕਸ",
  Tax_Label:"ਟੈਕਸ ਲੇਬਲ",
  Perpetually_Enabled:"ਸਥਾਈ ਤੌਰ 'ਤੇ ਸਮਰੱਥ",
  Select_Taxes:"ਟੈਕਸ ਚੁਣੋ",
  Tax_Deductions:"ਟੈਕਸ ਕਟੌਤੀਆਂ",
  No_Tax_Deductions:"ਕੋਈ ਟੈਕਸ ਕਟੌਤੀ ਨਹੀਂ",
  No_Tax_Deductions_Word:"ਪਹਿਲੀ <a routerLink='/folder/Tax/New'>ਟੈਕਸ</a> ਕਟੌਤੀ ਸ਼ਾਮਲ ਕਰੋ।",
  New_Timer:"ਨਵਾਂ ਟਾਈਮਰ",
  Start:"ਸ਼ੁਰੂ",
  Stop:"ਰੂਕੋ",
  Start_Timer:"ਸਟਾਰਟ ਟਾਈਮਰ",
  Stop_Timer:"ਸਟਾਪ ਟਾਈਮਰ",
  Timer_Active:"ਟਾਈਮਰ ਐਕਟਿਵ",
  Timer:"ਟਾਈਮਰ:",
  Timer_Running:"ਟਾਈਮਰ: (ਚੱਲ ਰਿਹਾ)",
  Save_Timer:"ਸੇਵ ਟਾਈਮਰ",
  New_Timesheet:"ਨਿਊ ਟਾਈਮ ਸ਼ੀਟ",
  Select_Timesheets:"ਸਮਾਂ ਸ਼ੀਟਾਂ ਦੀ ਚੋਣ ਕਰੋ",
  Work_Notes:"ਵਰਕ ਨੋਟਸ",
  Entry_Title:"ਐਂਟਰੀ ਟਾਈਟਲ",
  No_Timesheets:"ਕੋਈ ਟਾਈਮ ਸ਼ੀਟ ਨਹੀਂ",
  No_Timesheets_Word:"ਪਹਿਲਾ <a routerLink='/folder/Timesheet/New'>ਟਾਈਮ ਸ਼ੀਟ</a> ਸ਼ਾਮਲ ਕਰੋ।",
  Timesheet_Submitted:"ਸਮਾਂ ਸ਼ੀਟ ਜਮ੍ਹਾਂ ਕਰਾਈ ਗਈ",
  Timesheet_Congrats:"ਵਧਾਈਆਂ! ਤੁਹਾਡੀ ਸਮਾਂ ਸ਼ੀਟ ਸਫਲਤਾਪੂਰਵਕ ਜਮ੍ਹਾਂ ਹੋ ਗਈ ਹੈ। ਧੰਨਵਾਦ!",
  Timesheet_Copy:"ਆਪਣੇ ਦਸਤਾਵੇਜ਼ਾਂ ਦੀ ਇੱਕ ਕਾਪੀ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ ਕਿਰਪਾ ਕਰਕੇ ਸਾਨੂੰ ਆਪਣਾ ਈਮੇਲ ਪਤਾ ਅਤੇ/ਜਾਂ ਮੋਬਾਈਲ ਫ਼ੋਨ ਨੰਬਰ ਪ੍ਰਦਾਨ ਕਰੋ।",
  Submit:"ਜਮ੍ਹਾਂ ਕਰੋ",
  Allow_Notifications:"ਸੂਚਨਾਵਾਂ ਦੀ ਇਜਾਜ਼ਤ ਦਿਓ",
  Untitled_Entry:"ਨਵੀਂ ਐਂਟਰੀ",
  Untitled_Bank:"ਬਿਨਾਂ ਸਿਰਲੇਖ ਵਾਲਾ ਬੈਂਕ",
  Timesheet_Entry:"ਟਾਈਮ ਸ਼ੀਟ ਐਂਟਰੀ",
  Work_Description:"ਕੰਮ ਦਾ ਵੇਰਵਾ",
  Enable_Timesheet:"ਸਮਾਂ ਸ਼ੀਟ ਯੋਗ ਕਰੋ",
  Submit_Timesheet:"ਸਮਾਂ ਸ਼ੀਟ ਜਮ੍ਹਾਂ ਕਰੋ",
  Vacation:"ਛੁੱਟੀ",
  New_Vacation:"ਨਵੀਂ ਛੁੱਟੀ",
  Vacation_Name:"ਛੁੱਟੀ ਦਾ ਨਾਮ",
  Paid_Vacation:"ਭੁਗਤਾਨ ਕੀਤੀ ਛੁੱਟੀ",
  Vacation_Pay:"ਛੁੱਟੀਆਂ ਦੀ ਤਨਖਾਹ",
  Enable_Vacation:"ਛੁੱਟੀ ਯੋਗ ਕਰੋ",
  Select_Vacations:"ਛੁੱਟੀਆਂ ਦੀ ਚੋਣ ਕਰੋ",
  No_Vacations:"ਕੋਈ ਛੁੱਟੀਆਂ ਨਹੀਂ",
  No_Vacations_Word:"ਪਹਿਲੀ <a routerLink='/folder/Vacation/New'>ਛੁੱਟੀ</a> ਐਂਟਰੀ ਬਣਾਓ।",
  Payroll_Schedule:"ਪੇਰੋਲ ਅਨੁਸੂਚੀ",
  Next_Payroll:"ਅਗਲੀ ਤਨਖਾਹ:",
  Upcoming_Payroll:"ਆਗਾਮੀ ਤਨਖਾਹ",
  No_Upcoming_Payroll:"ਕੋਈ ਆਗਾਮੀ ਤਨਖਾਹ ਨਹੀਂ",
  Address_Book:"ਐਡਰੈੱਸ ਬੁੱਕ",
  Archived_Documents:"ਪੁਰਾਲੇਖ ਦਸਤਾਵੇਜ਼",
  Dev_Mode:"ਵਿਕਾਸ ਮੋਡ ਵਿੱਚ ਐਪਲੀਕੇਸ਼ਨ",
  Administrators:"ਪ੍ਰਬੰਧਕ",
  Privacy:"ਗੋਪਨੀਯਤਾ",
  None:"ਕੋਈ ਨਹੀਂ",
  Select_Signature:"ਦਸਤਖਤ ਚੁਣੋ",
  No_Signatures:"ਕੋਈ ਦਸਤਖਤ ਨਹੀਂ",
  No_Signatures_Word:"ਪਹਿਲਾ <a routerLink='/folder/Signature/New'>ਦਸਤਖਤ</a> ਸ਼ਾਮਲ ਕਰੋ।",
  Repeat_Indefinitely:"ਅਨਿਸ਼ਚਿਤ ਤੌਰ 'ਤੇ ਦੁਹਰਾਓ",
  Sign:"ਚਿੰਨ੍ਹ",
  Sign_Here:"ਇੱਥੇ ਸਾਈਨ ਕਰੋ",
  Date_Signed:"ਦਸਤਖਤ ਮਿਤੀ",
  Signature_Pad:"ਦਸਤਖਤ ਪੈਡ",
  Account_Holder:"ਖਾਤਾ ਧਾਰਕ",
  Account_Properties:"ਖਾਤਾ ਵਿਸ਼ੇਸ਼ਤਾ",
  Name_On_Cheque:"ਚੈੱਕ 'ਤੇ ਨਾਮ",
  Server_Hostname:"ਸਰਵਰ ਹੋਸਟਨਾਮ / IP",
  Printers:"ਪ੍ਰਿੰਟਰ",
  No_Printers:"ਕੋਈ ਪ੍ਰਿੰਟਰ ਨਹੀਂ",
  Printer_Exists:'ਇਸ ਨਾਮ ਦਾ ਇੱਕ ਪ੍ਰਿੰਟਰ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ।',
  No_Printers_Word:"ਸਭ ਤੋਂ ਪਹਿਲਾਂ <a routerLink='/folder/Printer/New'>ਪ੍ਰਿੰਟਰ</a> ਸ਼ਾਮਲ ਕਰੋ।",
  No_Printer_Alert:"ਕੋਈ ਪ੍ਰਿੰਟਰ ਪਰਿਭਾਸ਼ਿਤ ਨਹੀਂ ਹੈ। ਕੀ ਤੁਸੀਂ ਇੱਕ ਪ੍ਰਿੰਟਰ ਸੈੱਟਅੱਪ ਕਰਨਾ ਚਾਹੋਗੇ?",
  Add_Printer:"ਪ੍ਰਿੰਟਰ ਜੋੜੋ",
  New_Printer:"ਨਵਾਂ ਪ੍ਰਿੰਟਰ",
  Printer_Hostname:"ਪ੍ਰਿੰਟਰ ਹੋਸਟਨੇਮ / IP",
  Printer_Label:"ਪ੍ਰਿੰਟਰ ਲੇਬਲ",
  Printer_Protocol:"ਪ੍ਰਿੰਟਰ ਪ੍ਰੋਟੋਕੋਲ",
  Protocol:"ਪ੍ਰੋਟੋਕੋਲ",
  Email_Print:"ਈ - ਮੇਲ",
  AirPrint:"ਏਅਰਪ੍ਰਿੰਟ",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"ਸਾਕਟ",
  Print_Job:"ਪ੍ਰਿੰਟ ਜੌਬ",
  Printed:"ਛਾਪਿਆ",
  Not_Printed:"ਨਹੀਂ ਛਾਪਿਆ",
  Print_Jobs:"ਪ੍ਰਿੰਟ ਜੌਬ",
  Print_Queue:"ਪ੍ਰਿੰਟ ਕਤਾਰ",
  No_Print_Jobs:"ਕੋਈ ਪ੍ਰਿੰਟ ਜੌਬ ਨਹੀਂ",
  No_Prints:"ਪ੍ਰਿੰਟ ਕਰਨ ਲਈ ਇੱਕ ਨਵਾਂ <a routerLink='/folder/Cheque/New'>ਚੈੱਕ</a> ਬਣਾਓ।",
  Prints:"ਪ੍ਰਿੰਟਸ",
  Find_Printer:"ਪ੍ਰਿੰਟਰ ਲੱਭੋ",
  Find_AirPrint_Devices:"ਏਅਰਪ੍ਰਿੰਟ ਡਿਵਾਈਸਾਂ ਲੱਭੋ",
  Select_Printer:"ਪ੍ਰਿੰਟਰ ਚੁਣੋ",
  System_UI:"ਸਿਸਟਮ UI",
  Printer:"ਪ੍ਰਿੰਟਰ",
  Status:"ਸਥਿਤੀ",
  Preview:"ਪੂਰਵਦਰਸ਼ਨ",
  Enable_Print_Job:"ਪ੍ਰਿੰਟ ਜੌਬ ਨੂੰ ਸਮਰੱਥ ਬਣਾਓ",
  Queue_Weight:"ਕਤਾਰ ਭਾਰ",
  Unlimited:"ਬੇਅੰਤ",
  Show_Advanced_Printer_Options:"ਐਡਵਾਂਸਡ ਪ੍ਰਿੰਟਰ ਵਿਕਲਪ ਦਿਖਾਓ",
  Advanced:"ਐਡਵਾਂਸਡ",
  Location:"ਸਥਾਨ",
  Note:"ਨੋਟ",
  Queue_Name:"ਕਤਾਰ ਦਾ ਨਾਮ",
  Pages_Printed_Limit:"ਪੰਨਿਆਂ ਦੀ ਛਪਾਈ ਸੀਮਾ",
  MultiPage_Idle_Time:"ਮਲਟੀਪੇਜ ਉਡੀਕ ਸਮਾਂ",
  Page_Count_Limit:"ਪੰਨਾ ਗਿਣਤੀ ਸੀਮਾ",
  Page_Orientation:"ਪੰਨਾ ਸਥਿਤੀ",
  Portrait:"ਪੋਰਟਰੇਟ",
  Landscape:"ਲੈਂਡਸਕੇਪ",
  Duplex:"ਡੁਪਲੈਕਸ",
  Double_Sided_Printing:"ਦੋ ਪੱਖੀ",
  Duplex_Mode:"ਡੁਪਲੈਕਸ ਮੋਡ",
  Duplex_Short:"ਛੋਟਾ",
  Duplex_Long:"ਲੰਬਾ",
  Duplex_None:"ਕੋਈ ਨਹੀਂ",
  Color_And_Quality:"ਰੰਗ ਅਤੇ ਗੁਣਵੱਤਾ",
  Monochrome:"ਮੋਨੋਕ੍ਰੋਮ",
  Photo_Quality_Prints:"ਫੋਟੋ ਗੁਣਵੱਤਾ ਪ੍ਰਿੰਟਸ",
  Printer_Email:"ਪ੍ਰਿੰਟਰ ਈਮੇਲ",
  Automatically_Downsize:"ਆਟੋਮੈਟਿਕ ਡਾਊਨਸਾਈਜ਼",
  Paper:"ਕਾਗਜ਼",
  Paper_Name:"ਪੇਪਰ ਦਾ ਨਾਮ",
  Paper_Width:"ਪੇਪਰ ਦੀ ਚੌੜਾਈ",
  Paper_Height:"ਕਾਗਜ਼ ਦੀ ਉਚਾਈ",
  Paper_Autocut_Length:"ਪੇਪਰ ਆਟੋ-ਕੱਟ ਲੰਬਾਈ",
  Margins:"ਹਾਸ਼ੀਏ",
  Page_Margins:"ਪੰਨਾ ਮਾਰਜਿਨ",
  Page_Margin_Top:"ਟੌਪ ਪੇਜ ਹਾਸ਼ੀਏ",
  Page_Margin_Right:"ਸੱਜਾ ਪੰਨਾ ਹਾਸ਼ੀਆ",
  Page_Margin_Bottom:"ਹੇਠਲਾ ਪੰਨਾ ਹਾਸ਼ੀਆ",
  Page_Margin_Left:"ਖੱਬੇ ਪੰਨਾ ਹਾਸ਼ੀਆ",
  Add_Employees:"ਕਰਮਚਾਰੀ ਸ਼ਾਮਲ ਕਰੋ",
  Header:"ਸਿਰਲੇਖ",
  Print_A_Page_Header:"ਇੱਕ ਪੰਨਾ ਸਿਰਲੇਖ ਛਾਪੋ",
  Header_Label:"ਸਿਰਲੇਖ ਲੇਬਲ",
  Header_Page_Index:"ਸਿਰਲੇਖ ਪੰਨਾ ਸੂਚਕਾਂਕ",
  Header_Font_Name:"ਸਿਰਲੇਖ ਫੌਂਟ",
  Select_Font:"ਫੌਂਟ ਚੁਣੋ",
  Font_Selector:"ਫੌਂਟ ਚੋਣਕਾਰ",
  Header_Font_Size:"ਸਿਰਲੇਖ ਫੌਂਟ",
  Header_Bold:"ਸਿਰਲੇਖ ਬੋਲਡ",
  Header_Italic:"ਹੈਡਰ ਇਟਾਲਿਕ",
  Header_Alignment:"ਸਿਰਲੇਖ ਅਲਾਈਨਮੈਂਟ",
  Left:"ਖੱਬੇ",
  Center:"ਕੇਂਦਰ",
  Right:"ਸੱਜਾ",
  Justified:"ਜਾਇਜ਼",
  Header_Font_Color:"ਸਿਰਲੇਖ ਦਾ ਰੰਗ",
  Select_Color:"ਰੰਗ ਚੁਣੋ",
  Footer:"ਪਦਲੇਖ",
  Print_A_Page_Footer:"ਇੱਕ ਪੰਨਾ ਫੁੱਟਰ ਛਾਪੋ",
  Footer_Label:"ਫੁੱਟਰ ਲੇਬਲ",
  Footer_Page_Index:"ਪਦਲੇਖ ਪੰਨਾ ਸੂਚਕਾਂਕ",
  Footer_Font_Name:"ਫੁੱਟਰ ਫੌਂਟ",
  Fonts:"ਫੌਂਟ",
  Done:"ਹੋ ਗਿਆ",
  Select_Fonts:"ਫੌਂਟ ਚੁਣੋ",
  Footer_Font_Size:"ਫੁੱਟਰ ਦਾ ਆਕਾਰ",
  Footer_Bold:"ਫੁੱਟਰ ਬੋਲਡ",
  Footer_Italic:"ਫੁੱਟਰ ਇਟਾਲਿਕ",
  Footer_Alignment:"ਫੁੱਟਰ ਅਲਾਈਨਮੈਂਟ",
  Footer_Font_Color:"ਫੁੱਟਰ ਰੰਗ",
  Page_Copies:"ਪੇਜ ਕਾਪੀਆਂ",
  Enable_Printer:"ਪ੍ਰਿੰਟਰ ਚਾਲੂ ਕਰੋ",
  Remote_Logging:"ਰਿਮੋਟ ਲੌਗਿੰਗ",
  Log_Server:"ਲੌਗ ਸਰਵਰ",
  Encryption:"ਇਨਕ੍ਰਿਪਸ਼ਨ",
  Random_Key:"ਬੇਤਰਤੀਬ ਕੁੰਜੀ",
  Encryption_Key:"ਇਨਕ੍ਰਿਪਸ਼ਨ ਕੁੰਜੀ",
  Cheques_Webserver:"ਕਸਟਮ ਡੇਟਾਬੇਸ",
  Learn_How:"ਸਿੱਖੋ ਕਿਵੇਂ",
  Signature:"ਦਸਤਖਤ",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"ਦਸਤਖਤ ਵੇਖੋ",
  Printed_Signature:"ਪ੍ਰਿੰਟ ਕੀਤੇ ਦਸਤਖਤ",
  Digitally_Sign:"ਡਿਜੀਟਲ ਸਾਈਨ",
  Digital_Signature:"ਡਿਜੀਟਲ ਦਸਤਖਤ",
  Add_Signature:"ਦਸਤਖਤ ਸ਼ਾਮਲ ਕਰੋ",
  Add_Your_Signature:"ਆਪਣੇ ਦਸਤਖਤ ਸ਼ਾਮਲ ਕਰੋ",
  Enable_Signature:"ਦਸਤਖਤ ਯੋਗ ਕਰੋ",
  Digitally_Signed:"ਡਿਜੀਟਲ ਦਸਤਖਤ",
  Insert_Error:"ਡਾਟਾਬੇਸ ਸਮੱਸਿਆਵਾਂ ਦੇ ਕਾਰਨ ਚੈੱਕ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰਨ ਵਿੱਚ ਅਸਮਰੱਥ।",
  Delete_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਜਾਣਕਾਰੀ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Discard_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਜਾਣਕਾਰੀ ਨੂੰ ਰੱਦ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Discard_Bank_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਖਾਤੇ ਨੂੰ ਰੱਦ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Discard_Printer_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਪ੍ਰਿੰਟਰ ਨੂੰ ਰੱਦ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Delete_Bonus_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਬੋਨਸ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Delete_Invoice_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਇਨਵੌਇਸ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Generated_Cheque:"ਤਿਆਰ ਕੀਤੀ ਜਾਂਚ",
  Generated_Invoice:"ਬਣਾਇਆ ਇਨਵੌਇਸ",
  Schedule_Start_Time:"ਸ਼ੁਰੂਆਤ ਦਾ ਸਮਾਂ",
  Schedule_End_Time:"ਸ਼ਡਿਊਲ ਦਾ ਅੰਤ",
  New_Call:"ਨਵੀਂ ਕਾਲ",
  No_Contacts:"ਕੋਈ ਸੰਪਰਕ ਨਹੀਂ",
  No_Contacts_Word:"ਪ੍ਰਬੰਧਕ, ਸੰਚਾਲਕ, ਕਰਮਚਾਰੀ, ਅਤੇ ਭੁਗਤਾਨ ਕਰਤਾ ਸੰਪਰਕਾਂ ਵਜੋਂ ਦਿਖਾਈ ਦਿੰਦੇ ਹਨ।",
  PDF_Subject:"ਵਿੱਤ",
  PDF_Keywords:"ਪੇਰੋਲ ਪੇਚੈਕ PDF ਚੈੱਕ ਚੈਕ",
  Printer_Setup:"ਪ੍ਰਿੰਟਰ ਸੈੱਟਅੱਪ",
  Printer_Selection:"ਪ੍ਰਿੰਟਰ ਚੋਣ",
  New_Fax:"ਨਵਾਂ ਫੈਕਸ",
  New_Fax_Message:"ਨਵਾਂ ਫੈਕਸ ਸੁਨੇਹਾ",
  Fax_Machine:"ਫੈਕਸ ਮਸ਼ੀਨ",
  Fax_Name:"ਫੈਕਸ ਨਾਮ",
  Fax_Email:"ਫੈਕਸ ਈਮੇਲ",
  Fax_Number:"ਫੈਕਸ ਨੰਬਰ",
  Contents:"ਸਮੱਗਰੀ",
  Fax_Body:"ਪੇਜ ਬਾਡੀ",
  Header_Word:"ਸਿਰਲੇਖ:",
  Header_Text:"ਸਿਰਲੇਖ ਪਾਠ",
  Include_Header:"ਸਿਰਲੇਖ ਸ਼ਾਮਲ ਕਰੋ",
  Include_Footer:"ਪਦਲੇਖ ਸ਼ਾਮਲ ਕਰੋ",
  Footer_Word:"ਪਦਲੇਖ:",
  Footer_Text:"ਫੁੱਟਰ ਟੈਕਸਟ",
  Attach_a_Cheque:"ਇੱਕ ਚੈੱਕ ਨੱਥੀ ਕਰੋ",
  Add_Deduction:"ਕਟੌਤੀ ਜੋੜੋ",
  Enable_Fax:"ਫੈਕਸ ਭੇਜੋ",
  Select_Fax:"ਫੈਕਸ ਚੁਣੋ",
  No_Faxes:"ਕੋਈ ਫੈਕਸ ਨਹੀਂ",
  Faxes:"ਫੈਕਸ",
  Save_and_Send:"ਫੈਕਸ ਭੇਜੋ",
  Save_and_Pay:"ਬਚਾਓ ਅਤੇ ਭੁਗਤਾਨ ਕਰੋ",
  WARNING:"ਚੇਤਾਵਨੀ:",
  Remember:"ਯਾਦ ਰੱਖਣਾ",
  Printing:"ਪ੍ਰਿੰਟਿੰਗ",
  Printing_Complete:"ਪ੍ਰਿੰਟਿੰਗ ਮੁਕੰਮਲ!\n\n",
  of:"ਦਾ",
  There_Were:"ਉੱਥੇ ਸਨ ",
  Successful_Prints:"ਸਫਲ ਪ੍ਰਿੰਟ ਅਤੇ",
  Unsuccessful_Prints:"ਅਸਫਲ ਪ੍ਰਿੰਟਸ।",
  PrinterError:"ਮਾਫ਼ ਕਰਨਾ! ਕੋਈ ਗੜਬੜ ਹੋ ਗਈ ਸੀ।",
  Printing_:"ਪ੍ਰਿੰਟਿੰਗ...",
  PrinterSuccess:"ਦਸਤਾਵੇਜ਼ ਸਫਲਤਾਪੂਰਵਕ ਛਾਪਿਆ ਗਿਆ।",
  PrintersSuccess:"ਦਸਤਾਵੇਜ਼ ਸਫਲਤਾਪੂਰਵਕ ਛਾਪੇ ਗਏ।",
  Print_Cheques:"ਪ੍ਰਿੰਟ ਚੈਕ",
  New_Message:"ਨਵਾਂ ਸੁਨੇਹਾ",
  New_Messages:"ਨਵੇਂ ਸੁਨੇਹੇ",
  Read_Message:"ਸੁਨੇਹਾ ਪੜ੍ਹੋ",
  Write_Message:"ਸੁਨੇਹਾ ਲਿਖੋ",
  Send_Message:"ਸੁਨੇਹਾ ਭੇਜੋ",
  Subject:"ਵਿਸ਼ਾ",
  Message:"ਸੁਨੇਹਾ",
  Writing:"ਲਿਖਣਾ...",
  Send:"ਭੇਜੋ",
  Set_Date:"ਤਾਰੀਖ ਸੈੱਟ ਕਰੋ",
  Set_Time:"ਸਮਾਂ ਸੈੱਟ ਕਰੋ",
  Recieve:"ਪ੍ਰਾਪਤ ਕਰੋ",
  Set_as_Default:"ਨੂੰ ਮੂਲ ਰੂਪ ਵਿੱਚ ਸੈੱਟ ਕੀਤਾ",
  Default_Account:"ਡਿਫਾਲਟ ਖਾਤਾ",
  Default_Design:"ਡਿਫਾਲਟ ਡਿਜ਼ਾਈਨ",
  Multiple_Cheques:"ਚੈੱਕਸ (ਟ੍ਰਿਪਲ)",
  Account_Mode:"ਖਾਤਾ ਮੋਡ",
  Multiple_Cheques_Description:"ਪ੍ਰਤੀ ਪੰਨੇ 'ਤੇ ਤਿੰਨ ਚੈੱਕ।",
  Check_and_Table:"ਚੈੱਕ ਅਤੇ ਟੇਬਲ",
  Check_including_Table:"ਜਾਂਚ ਅਤੇ ਲੇਖਾ ਸਾਰਣੀ।",
  Check_Mailer:"ਮੇਲਰ ਦੀ ਜਾਂਚ ਕਰੋ",
  Check_Mailer_Window:"ਐਡਰੈੱਸ ਵਿੰਡੋ ਨਾਲ ਚੈੱਕ ਕਰੋ।",
  DocuGard_Table_Top:"ਡਾਕੂਗਾਰਡ ਚੈੱਕ ਅਤੇ ਟੇਬਲ (ਸਿਖਰ)",
  DocuGard_Table_Middle:"ਡਾਕੂਗਾਰਡ ਚੈੱਕ ਅਤੇ ਟੇਬਲ (ਮੱਧ)",
  DocuGard_Table_Bottom:"ਡਾਕੂਗਾਰਡ ਚੈੱਕ ਅਤੇ ਟੇਬਲ (ਹੇਠਾਂ)",
  DocuGard_Mailer_Top:"ਡਾਕੂਗਾਰਡ ਚੈੱਕ ਮੇਲਰ (ਟੌਪ)",
  DocuGard_Mailer_Middle:"ਡਾਕੂਗਾਰਡ ਚੈੱਕ ਮੇਲਰ (ਮਿਡਲ)",
  DocuGard_Mailer_Bottom:"ਡਾਕੂਗਾਰਡ ਚੈੱਕ ਮੇਲਰ (ਹੇਠਾਂ)",
  DocuGard_Three_Cheques:"ਡਾਕੂਗਾਰਡ ਜਾਂਚਾਂ (ਟ੍ਰਿਪਲ)",
  DocuGard_Cheque_Top:"ਡਾਕੂਗਾਰਡ ਚੈੱਕ (ਟੌਪ)",
  DocuGard_Cheque_Middle:"ਡਾਕੂਗਾਰਡ ਚੈੱਕ (ਮਿਡਲ)",
  DocuGard_Cheque_Bottom:"ਡਾਕੂਗਾਰਡ ਚੈੱਕ (ਹੇਠਾਂ)",
  DocuGard_Three_Cheques_Window:"ਇੱਕ ਪੰਨੇ 'ਤੇ ਤਿੰਨ ਜਾਂਚਾਂ।",
  DocuGard_Table_Top_Window:"ਚੈੱਕ ਅਤੇ ਕਮਾਈ ਸਾਰਣੀ।",
  DocuGard_Table_Middle_Window:"ਚੈੱਕ ਅਤੇ ਕਮਾਈ ਸਾਰਣੀ।",
  DocuGard_Table_Bottom_Window:"ਚੈੱਕ ਅਤੇ ਕਮਾਈ ਸਾਰਣੀ।",
  DocuGard_Mailer_Top_Window:"ਚੈੱਕ, ਟੇਬਲ ਅਤੇ ਪਤਾ।",
  DocuGard_Mailer_Middle_Window:"ਚੈੱਕ, ਟੇਬਲ ਅਤੇ ਪਤਾ।",
  DocuGard_Mailer_Bottom_Window:"ਚੈੱਕ, ਟੇਬਲ ਅਤੇ ਪਤਾ।",
  DocuGard_Cheque_Top_Window:"ਸੁਰੱਖਿਅਤ ਕਾਗਜ਼ ਦੀ ਜਾਂਚ ਕਰੋ।",
  DocuGard_Cheque_Middle_Window:"ਸੁਰੱਖਿਅਤ ਕਾਗਜ਼ ਦੀ ਜਾਂਚ ਕਰੋ।",
  DocuGard_Cheque_Bottom_Window:"ਸੁਰੱਖਿਅਤ ਕਾਗਜ਼ ਦੀ ਜਾਂਚ ਕਰੋ।",
  Basic_Cheque:"ਚੈੱਕ (ਸਿਖਰ)",
  Basic_Cheque_Print:"ਇੱਕ ਸਿੰਗਲ ਚੈੱਕ ਛਾਪੋ।",
  Error_Setting_As_Paid:"ਭੁਗਤਾਨ ਵਜੋਂ ਸੈੱਟ ਕਰਨ ਵਿੱਚ ਗਲਤੀ",
  Add_Attachment:"ਅਟੈਚਮੈਂਟ ਜੋੜੋ",
  PrinterUnavailable:"ਪ੍ਰਿੰਟਰ ਅਣਉਪਲਬਧ",
  CreditCardComponent:"ਕਾਰਡ",
  PaypalComponent:"ਪੇਪਾਲ",
  InteracComponent:"ਇੰਟਰੈਕ",
  BitcoinComponent:"ਬਿਟਕੋਇਨ",
  New_Deposit:"ਨਵਾਂ ਡਿਪਾਜ਼ਿਟ",
  Deposits:"ਜਮਾਂ",
  No_Deposits:"ਕੋਈ ਡਿਪਾਜ਼ਿਟ ਨਹੀਂ",
  Credit_Card_Deposit:"ਕਰੇਡਿਟ ਕਾਰਡ",
  New_Credit_Card_Deposit_Message:"ਕ੍ਰੈਡਿਟ ਕਾਰਡ ਡਿਪਾਜ਼ਿਟ",
  New_BitCoin_Deposit:"ਬਿਟਕੋਇਨ",
  New_BitCoin_Deposit_Message:"ਬਿਟਕੋਇਨ ਡਿਪਾਜ਼ਿਟ",
  New_Interac_Deposit:"ਇੰਟਰੈਕ",
  New_Interac_Deposit_Message:"ਇੰਟਰੈਕ ਟ੍ਰਾਂਸਫਰ",
  Payments_Limit:"ਭੁਗਤਾਨ ਸੀਮਾ",
  No_Payment_Limit:"ਕੋਈ ਭੁਗਤਾਨ ਸੀਮਾ ਨਹੀਂ",
  PayPal_Deposit:"ਪੇਪਾਲ",
  PayPal_Deposit_Message:"ਪੇਪਾਲ ਡਿਪਾਜ਼ਿਟ",
  No_Deposits_Word:"ਪਹਿਲੀ ਕੰਪਨੀ <a routerLink='/folder/Deposit/New'>ਜਮ੍ਹਾ</a> ਸ਼ਾਮਲ ਕਰੋ।",
  No_Documents_Specified:"ਪ੍ਰਿੰਟਿੰਗ ਲਈ ਕੋਈ ਦਸਤਾਵੇਜ਼ ਨਹੀਂ ਦਿੱਤੇ ਗਏ ਸਨ",
  No_Printers_Added:"ਕੋਈ ਪ੍ਰਿੰਟਰ ਨਹੀਂ ਮਿਲੇ। ਇੱਕ ਜੋੜਨ ਲਈ ਸੈਟਿੰਗਾਂ > ਪ੍ਰਿੰਟਰਾਂ 'ਤੇ ਜਾਓ।",
  DB_Erase_Prompt:"ਪੂਰਾ ਡਾਟਾਬੇਸ ਪੂਰੀ ਤਰ੍ਹਾਂ ਮਿਟਾਉਣਾ ਹੈ? ਚੇਤਾਵਨੀ: ਤੁਸੀਂ ਸਾਰੀ ਸਟੋਰ ਕੀਤੀ ਜਾਣਕਾਰੀ ਗੁਆ ਦੇਵੋਗੇ!",
  Console_Warning:"ਇਸ ਕੰਸੋਲ ਵਿੱਚ ਕੋਈ ਟੈਕਸਟ ਨਾ ਪੇਸਟ ਕਰੋ। ਤੁਸੀਂ ਆਪਣੇ ਆਪ ਨੂੰ ਅਤੇ/ਜਾਂ ਆਪਣੀ ਕੰਪਨੀ ਨੂੰ ਗੰਭੀਰ ਖਤਰੇ ਵਿੱਚ ਪਾ ਸਕਦੇ ਹੋ।",
  No_Faxes_Word:"ਪਹਿਲਾ <a routerLink='/folder/Fax/New'>ਫੈਕਸ</a> ਬਣਾਓ।",
  Cheque_Delete_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਚੈੱਕ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Design_Delete_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਡਿਜ਼ਾਈਨ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Cheque_Pay_Confirmation:"ਇਸ ਚੈੱਕ ਦੀ ਅਦਾਇਗੀ ਵਜੋਂ ਨਿਸ਼ਾਨਦੇਹੀ ਕਰੋ? ਇਹ ਪ੍ਰਿੰਟ ਕਤਾਰ ਵਿੱਚ ਦਿਖਾਈ ਨਹੀਂ ਦੇਵੇਗਾ।",
  Payment_Pay_Confirmation:"ਇਸ ਭੁਗਤਾਨ ਦੀ ਅਦਾਇਗੀ ਵਜੋਂ ਨਿਸ਼ਾਨਦੇਹੀ ਕਰੋ? ਇਹ ਚੈੱਕ ਕਤਾਰ ਵਿੱਚ ਦਿਖਾਈ ਨਹੀਂ ਦੇਵੇਗਾ।",
  Delete_Deduction_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਕਟੌਤੀ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Delete_Job_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਨੌਕਰੀ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Delete_Timesheet_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਟਾਈਮਸ਼ੀਟ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Delete_Schedule_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਸਮਾਂ-ਸਾਰਣੀ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Delete_Setting_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਸੈਟਿੰਗ ਨੂੰ ਰੀਸੈਟ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Delete_Fax_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਫੈਕਸ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Delete_File_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਫ਼ਾਈਲ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Delete_Vacation_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਛੁੱਟੀ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Delete_Printer_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਪ੍ਰਿੰਟਰ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Remove_Design_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਡਿਜ਼ਾਈਨ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Delete_Payroll_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਪੇਰੋਲ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Send_Fax_Email_Confirmation:"ਕੀ ਤੁਸੀਂ ਇਹਨਾਂ ਦਸਤਾਵੇਜ਼ਾਂ ਨੂੰ ਫੈਕਸ ਅਤੇ ਈਮੇਲ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Send_Email_Confirmation:"ਕੀ ਤੁਸੀਂ ਇਸ ਦਸਤਾਵੇਜ਼ ਨੂੰ ਈਮੇਲ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Send_Fax_Confirmation:"ਕੀ ਤੁਸੀਂ ਇਸ ਦਸਤਾਵੇਜ਼ ਨੂੰ ਫੈਕਸ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Error_Generating_PDF:"ਮਾਫ਼ ਕਰਨਾ। ਇਸ ਦਸਤਾਵੇਜ਼ ਨੂੰ ਬਣਾਉਣ ਵਿੱਚ ਇੱਕ ਤਰੁੱਟੀ ਸੀ।",
  PDF_Error_Saving_Image:"ਮਾਫ਼ ਕਰਨਾ। ਇਸ ਦਸਤਾਵੇਜ਼ ਦੀ PDF ਚਿੱਤਰ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰਨ ਵਿੱਚ ਇੱਕ ਤਰੁੱਟੀ ਸੀ।",
  Test_Printer_Confirmation:"ਕੀ ਤੁਸੀਂ ਇਸ ਪ੍ਰਿੰਟਰ 'ਤੇ ਇੱਕ ਟੈਸਟ ਪੰਨਾ ਛਾਪਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Save_Timesheet_Prompt:"ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ 'ਟਾਈਟਲ' ਜੋੜੋ ਜਾਂ ਸੇਵ ਕਰਨ ਲਈ 'ਸਟਾਰਟ ਟਾਈਮਰ' ਦਬਾਓ।",
  Remove_Geofence_Prompt:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਭੂ-ਵਾੜ ਦੀ ਸਥਿਤੀ ਨੂੰ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  Delete_Employee_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ",
  Fire_Employee_Confirmation:"ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਫਾਇਰ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ"
}