export const Kk = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Авторлық құқық &көшіру; 2023",
  black:"Қара",
  green:"Жасыл",
  gold:"Алтын",
  blue:"Көк",
  brown:"Қоңыр",
  purple:"Күлгін",
  pink:"Қызғылт",
  red:"Қызыл",
  Swatches:"Үлгілер",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Реңк",
  Saturation:"Қанықтылық",
  Lightness:"Жеңілдік",
  Upgrade_To_Pro:"Pro нұсқасына жаңартыңыз",
  AllFeaturesInclude:"Барлық мүмкіндіктерге мыналар кіреді:",
  PrintUnlimitedCheques:"Шексіз чектерді басып шығару",
  PremiumChequeDesigns:"Премиум тексеру дизайндары",
  ManageUnlimitedEmployees:"Шексіз қызметкерлерді басқарыңыз",
  AddUnlimitedPayees:"Шексіз төлеушілерді қосыңыз",
  CreateUnlimitedPayrolls:"Шексіз жалақы қорын жасаңыз",
  UnlimitedSchedulesandTimeSheets:"Шектеусіз кестелер мен уақыт кестелері",
  BulkPayPalPayouts:"Жаппай PayPal төлемдері",
  UnlimitedBankAccounts:"Шексіз банктік шоттар",
  ManageMultipleCompanies:"Бірнеше компанияны басқару",
  TrackInsurancePolicies:"Сақтандыру полистерін қадағалаңыз",
  Bio_MetricProtection:"Биометриялық қорғаныс",
  Geo_FenceLock_OutProtection:"Geo-Fence Lock-Out қорғанысы",
  Multiple_Companies_Word:"Бірнеше компанияны басқару Checks премиумынсыз мүмкін емес.",
  PayPal_Payouts_Word:"PayPal төлемдері Checks премиумынсыз өшіріледі.",
  PINProtection:"PIN қорғау",
  PasswordProtection:"Құпия сөзді қорғау",
  May_Require_Approval:"Мақұлдау қажет болуы мүмкін.",
  Subscribe:"Жазылу",
  Billed:"Есептелген",
  Up:"Жоғары",
  Down:"Төмен",
  Positioning:"Позициялау",
  Marker:"Маркер",
  Drag_Marker:"Маркерді сүйреңіз",
  Tagline:"Чектерді басып шығарыңыз және жалақы кестесін жасаңыз",
  Marker_Word:"Элементтің өлшемін алу үшін маркерлерді пайдаланыңыз.",
  Pinch_Zoom:"Масштабты қысу",
  Pinch_Word:"Элементті үлкейту үшін шымшу.",
  Drag:"сүйреңіз",
  Drag_Word:"Элементтерді сүйреу үшін саусағыңызды пайдаланыңыз.",
  subscription_alias_word:"Жазылымды автоматты түрде жаңарту",
  premium_alias_word:"Бір реттік жаңарту пакеті",
  print_alias_word:"Жеке чектерді басып шығару",
  mode_alias_word:"Режим",
  Pro:"Pro",
  Pro_word:"Pro нұсқасы қажет.",
  Cant_Delete_Default_Company_Word:"Кешіріңіз, сіз әдепкі компанияңызды жоя алмайсыз .",
  Reinsert_Default_Designs:"Әдепкі дизайндарды қайта кірістіріңіз",
  Reinsert_Default_Designs_word:"Әдепкі дизайнды қайта енгізгіңіз келе ме?",
  Subscription_Active_Disable_Word:"Бұл жазылым белсенді. Бұл чектерге жазылудан бас тартқыңыз келе ме?",
  Company_Logo:"Компания логотипі",
  ZERO_:"НӨЛ",
  Disclaimer:"Жауапкершіліктен бас тарту",
  Privacy_Policy:"Құпиялылық саясаты",
  EULA:"EULA тексереді",
  Terms_Of_Service:"Қызмет көрсету шарттары",
  Terms_Of_Use:"Қолдану ережелері",
  Refunds:"Қайтару саясаты",
  Single_Print:"1 Тексеру",
  Two_Prints:"2 Тексеру",
  Five_Prints:"5 Тексеру",
  Ten_Prints:"10 Тексеру",
  Fifteen_Prints:"15 Тексеру",
  Twenty_Prints:"20 Тексеру",
  Thirty_Prints:"30 Тексеру",
  Image_Added:"Сурет қосылды",
  Image_Preview:"Кескінді алдын ала қарау",
  No_Image_Was_Selected:"Ешбір сурет таңдалмады.",
  Cheques_Unlimited:"Шексіз чектер",
  _Subscription:"Жазылым",
  Subscription:"Тексерулер - 1 ай",
  Two_Month_Subscription:"Тексерулер - 2 ай",
  Three_Month_Subscription:"Тексерулер - 3 ай",
  Six_Month_Subscription:"Тексерулер - 6 ай",
  Twelve_Month_Subscription:"Тексерулер - 12 ай",
  Cheques_Are_Available:"Чектерді басып шығаруға болады.",
  Upgrade_Required_Two:"Пакетті таңдап, сатып алуды бастау үшін баға түймесін екі рет түртіңіз. Кәсіби көрінетін толық түсті чектерді секундтарда басып шығарыңыз.",
  Month:"Ай",
  Due:"Мерзімі:",
  Expires:"Мерзімі аяқталады:",
  Subscription_Active:"Жазылым белсенді",
  Subscription_Inactive:"Жазылым белсенді емес",
  Purchase_Additional_Cheques:"Қосымша чектерді сатып алу керек пе?",
  Printable_Cheque:"Басып шығаруға болатын тексеру",
  Printable_Cheques:"Басып шығарылатын чектер",
  Printable_Cheque_Word:"чек сіздің шотыңызда қол жетімді.",
  Printable_Cheques_Word:"Сіздің шотыңызда чектер бар.",
  Max_Amount_Message:"Сіз көрсеткен сома осы жүйе үшін белгіленген максималды сомадан асып кетті:",
  Terms_Required_Word:"Чектерді пайдалануды жалғастырмас бұрын осы келісіммен келісуіңіз керек.",
  Subscriptions:"Жазылымдар",
  Product_Upgrades:"Жаңартулар",
  Mailed_Out_Cheques:"Поштамен жіберілген чектер",
  Enter_A_Company_Name:"Компания атауын енгізіңіз.",
  Single_Cheques:"Бірыңғай тексерулер",
  Cheque_Golden:"Алтын чек",
  Cheque_Golden_Window:"Алтын чек дизайны.",
  Cheque_Green:"Жасыл чек",
  Cheque_Green_Window:"Жасыл чек дизайны.",
  Cheque_Red:"Қызыл чек",
  Cheque_Red_Window:"Қызыл чек дизайны.",
  Cheque_Yellow:"Сары чек",
  Cheque_Yellow_Window:"Сары чек дизайны.",
  Cheque_Statue_of_Liberty:"Азаттық мүсіні",
  Cheque_Statue_of_Liberty_Window:"Бостандық мүсінінің дизайнын тексеру.",
  Cheque_Green_Wave:"Жасыл толқын",
  Cheque_Green_Wave_Window:"Жасыл чек дизайны.",
  Cheque_Golden_Weave:"Алтын тоқыма",
  Cheque_Golden_Weave_Window:"Талғампаз алтын чек дизайны.",
  Cheque_Green_Sun:"Жасыл күн",
  Cheque_Green_Sun_Window:"Терең және тыныштандыратын чек дизайны.",
  Cheque_Blue_Checkers:"Көк дойбы",
  Cheque_Blue_Checkers_Window:"Көк чек дизайны.",
  Cashiers_Check:"Кассалық чек",
  Cashiers_Check_Window:"Кассалық чек стилінің үлгісі.",
  Cheque_Aqua_Checkers:"Аква дойбы",
  Cheque_Aqua_Checkers_Window:"Aqua check дизайны.",
  Cheque_Golden_Checkers:"Алтын дойбы",
  Cheque_Golden_Checkers_Window:"Алтын чек дизайны.",
  Upgrade_Unavailable:"Жаңартулар қолжетімсіз",
  Bank_Code_Protection:"Банк нөмірін қорғау",
  Bank_Code_Protection_Word:"Банк нөмірлерін басқа құрылғыда немесе басқа пайдаланушы үшін жұмыс істейтін осы қолданбада пайдаланудан қорғаңыз. Бұл әрекет ҚАЙТЫМСЫЗ. Жалғастыру керек пе?",
  Bank_Code_Protection_Blocked_Word:"Сіз пайдаланбақ болған банк нөмірлері басқа пайдаланушы немесе құрылғы үшін сақталған.",
  Bank_Code_Protection_Error_Word:"Нөмірді растау сәтсіз аяқталды. Интернетке қосылып, осы банк шотын қайта қосып көріңіз.",
  Bank_Code_Protection_Set_Error_Word:"Банк нөмірін қорғау интернетке қосылуды талап етеді. Қосылып, әрекетті қайталаңыз.",
  Upgrade_Unavailable_Word:"Кешіріңіз, сізді растау мүмкін емес. Жазылымдар мен Checks жаңартуларын қазір аймағыңызда сатып алу мүмкін емес.",
  PayPal_Payment_Preview:"PayPal төлемдерін алдын ала қарау",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"PayPal таңдаңыз",
  PayPal_Applications:"PayPal қолданбалары",
  Purchase_With_Apple_Pay:"Apple Pay арқылы сатып алыңыз",
  Google_Pay:"Google Pay",
  Companies:"Компаниялар",
  Insurance:"Сақтандыру",
  New_PayPal:"Жаңа PayPal",
  Pay_By:"Төлеу",
  Insure:"Сақтандыру",
  Miles:"Миль",
  Payment_Method:"Төлем тәсілі",
  Select_Policies:"Саясаттарды таңдаңыз",
  Policies:"Саясат",
  Policy:"Саясат",
  No_PayPal_Account:"PayPal тіркелгісі жоқ",
  No_Policies:"Сақтандыру полистері жоқ",
  New_Policy:"Жаңа саясат",
  PayPal_Payment:"PayPal төлемі",
  PayPal_Payments:"PayPal төлемдері",
  No_Payment_Selected:"Төлем таңдалмаған",
  Sending_Payment_Word:"Күте тұрыңыз... Бұл төлем жіберілуде.",
  Sending_Payments_Word:"Күте тұрыңыз... Төлемдер жіберілуде.",
  No_Payment_Selected_PayPal:"<a routerLink='/folder/Payments'>PayPal төлемі</a> таңдалмаған.",
  Payment_Sent:"Төлем жіберілді",
  PayPal_Payment_Sent:"Бұл төлем PayPal арқылы жіберілді.",
  Copay:"Копай",
  Dead:"Өлген",
  Alive:"Тірі",
  Not_Dead:"Өлі емес",
  Unclaimed:"Талап етілмеген",
  Attempted:"Әрекет жасалды",
  Deceased:"Марқұм",
  Claimed:"Талап етілген",
  Unpaid:"Төленбеген",
  Sending_Payment:"Төлемді жіберу",
  Sending_Payments:"Төлемдер жіберу",
  Send_PayPal_Confirmation:"Бұл транзакцияны PayPal арқылы жібергіңіз келе ме?",
  Send_PayPal_Confirmation_Word:"Бұл транзакцияны жіберу үшін жасыл түймені басыңыз.",
  Save_Payment_As_Unpaid:"Бұл төлемді төленбеген ретінде сақтау керек пе?",
  Payment_Pay_Confirmation_PayPal:"Бұл төлемді төленген ретінде сақтау керек пе?",
  No_Policies_Word:"Бірінші <a routerLink='/folder/Postage/New'>Сақтандыру полисін</a> қазір қосыңыз .",
  Timesheet_Multiple_Delete_Confirmation:"Бірнеше уақыт кестесін шынымен жойғыңыз келе ме?",
  Select_Multiple_Timesheets_Prompt:"Уақыт кестесі таңдалмаған. Кемінде бір уақыт кестесін таңдаңыз.",
  Select_Multiple_Policies_Prompt:"Ешбір саясат таңдалмаған. Кем дегенде бір сақтандыру полисін таңдаңыз.",
  Policies_Multiple_Delete_Confirmation:"Бірнеше саясатты жойғыңыз келетініне сенімдісіз бе?",
  Company:"Компания",
  Add_Company:"Компанияны қосыңыз",
  New_Company:"Компанияны қосыңыз",
  No_Companies:"Компаниялар жоқ",
  Enable_Company:"Компанияны қосыңыз",
  Select_Company:"Компанияны таңдаңыз",
  The_Default_Company:"Әдепкі компания белгісі.",
  Manage_Companies:"Компанияларды басқару",
  No_Companies_Word:"Тексерулер әдепкі компанияны пайдаланады .<br /> <a routerLink='/folder/Company/New'>бірінші компанияны</a> қосыңыз .",
  Default_Company:"Әдепкі компания",
  Cheques_Unlimited_Word:"Checks Unlimited мүмкіндігінше көптеген чектерді басып шығаруға мүмкіндік береді.",
  Cheques_Subscription_Word:"Checks жазылымы қалағаныңызша чектерді басып шығаруға мүмкіндік береді.",
  You_Own_This_Product:"Бұл өнім сізге тиесілі.",
  Your_Subscription_is_Active:"Жазылымыңыз белсенді.",
  Active_Products:"Белсендірілген өнімдер",
  Purchase_Confirmation:"Сатып алу",
  Purchase_Cheques:"Сатып алу чектері",
  Restore_Purchase:"Сатып алуларды қалпына келтіру",
  Erase_Purchase:"Сатып алуларды өшіру",
  Successfully_Purchased:"Сәтті сатып алынды",
  Enter_Your_Licence_Key:"Бұл өнімді белсендіру үшін осы бетте лицензия кілтін енгізіңіз.",
  Licence_Key:"Лицензия кілті",
  Enter_Licence_Key:"Лицензия кілтін енгізіңіз",
  Purchased:"Сатып алынды",
  Enable_Feature:"Функцияны қосу",
  Cancel_Subscription:"Жазылымнан бас тарту",
  Subscription_Removed:"Жазылым жойылды",
  Select_Active_Subscription:"Оны өзгерту үшін белсенді жазылымды таңдаңыз.",
  Remove_Subscription_Word:"Бұл жазылымнан шынымен бас тартқыңыз келе ме?",
  Delete_Company_Confirmation:"Осы компанияны толығымен жойғыңыз келетініне сенімдісіз бе? ЕСКЕРТУ: Сіз барлық сақталған ақпаратты жоғалтасыз!",
  Delete_Default_Company_Word:"Әдепкі компанияны жою мүмкін емес . Қолданбаны қалпына келтіріп, оны әдепкі күйге қайтарғыңыз келе ме? ЕСКЕРТУ: Сіз барлық сақталған ақпаратты жоғалтасыз!",
  Console_Warning_2:"Қазіргі уақытта сіздікі емес бұл қолданбаны пайдаланып ешбір валютаны пайдаланбаңыз.",
  Terms_and_Conditions:"Ережелер мен шарттар",
  and_the:"және",
  for:"үшін",
  Please_Read_Word:"Оқып, келісесіз",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Кейбір валюталарды айырбастау бағамдары табылмады. Интернетке қосылыңыз.",
  Free:"Тегін",
  DB_Erase_Prompt_2:"Бүкіл әзірлеуші дерекқорын толығымен өшіру керек пе? ЕСКЕРТУ: САТЫП АЛУ және ЖАЗЫЛУ туралы барлық ақпаратты жоғалтасыз!",
  Successfully_Imported:"Сәтті импортталды",
  Select_Postage:"Пошта опциясын таңдаңыз",
  No_Postage:"Пошта маркалары жоқ",
  No_Paid_Postage_Word:"Бірінші <a routerLink='/folder/Postage/New'>Ақылы пошта</a> маркасын қосыңыз .",
  Trial_Complete:'Сынақ аяқталды',
  Please_Upgrade:'Басып шығаруды жалғастыру үшін Checks бағдарламасын жаңартыңыз.',
  Aa:"Аа",
  Color:"Түс",
  Font:"Қаріп",
  Guide:"Гид",
  Guides:"Гидтер",
  Image:"Сурет",
  Zoom:"Масштабтау",
  Logo:"Логотип",
  Bank:"Банк",
  MICR:"MICR",
  Total:"Барлығы",
  Cancel:"Болдырмау",
  Confirm:"Растау",
  Method:"Әдіс",
  Biometric_Security:"Биометриялық қауіпсіздік",
  Please_Login_To_Continue:"Жалғастыру үшін жүйеге кіріңіз.",
  Complete:"Аяқталды",
  Sign_Up:"Тіркелу",
  Error:"Қате",
  Biometrics:"Биометрика",
  Percent:"Пайыз",
  Zero_Percent:"0%",
  Top_Margin:"Жоғарғы маржа",
  Bottom_Margin:"Төменгі маржа",
  Left_Margin:"Сол жақ шет",
  Right_Margin:"Оң жақ шет",
  MICR_Margin:"MICR маржа",
  Table_Margin:"Кесте маржа",
  Address_Margin:"Мекенжай маржасы",
  Percentage_:"Пайыз",
  Vacation_Title:"Демалыс тақырыбы",
  Use_PIN:"PIN кодын пайдаланыңыз",
  Loading__:"Жүктелуде...",
  Design_Title:"Дизайн тақырыбы",
  Authorize:"Рұқсат ету",
  Key:"Кілт",
  Public_Key:"Ашық кілт",
  Private_Key:"Жеке кілт",
  Authenticate:"Аутентификация",
  Last_Payroll:"Соңғы жалақы",
  Last_Calculation:"Соңғы есептеу",
  Authorized:"Рұқсат етілген",
  Geo_Authorized:"Гео-орын: Рұқсат етілген",
  Not_Authorized:"Рұқсат етілмеген",
  Authorization_Complete:"Авторизация аяқталды",
  Geolocation_Authorization:"Гео-орын авторизациясы",
  Out_of_Bounds:"Шектен тыс",
  Cant_Delete_Default_Design:"Әдепкі дизайнды жою мүмкін емес.",
  Unauthorized_Out_of_Bounds:"Рұқсат етілмеген: шекарадан тыс",
  Biometric_Authorization:"Биометриялық авторизация",
  Locating_Please_Wait:"Орналасқан, күте тұрыңыз...",
  Test_Biometrics:"Биометрикалық тестілеу",
  Cheque_Margins:"Маржаларды тексеріңіз",
  Percentage_Full_Error:"Пайыз өрісін 100% пайыздан жоғары орнату мүмкін емес.",
  No_Payroll_Text:"<a routerLink='/folder/Employee/New'>Қызметкер</a> немесе <a routerLink='/folder/Payee/New'>Алушы</a> және <a routerLink='/folder/Schedule/New'>Кесте</a>.",
  Design_Saved:"Дизайн сақталды",
  Default_Design_Selected:"Әдепкі дизайн таңдалды",
  Partial_Import:"Ішінара импорт",
  Partial_Export:"Ішінара экспорт",
  Entire_Import:"Толық импорт",
  Entire_Export:"Толық экспорт",
  Existing_Password:"Бар құпия сөзіңізді енгізіңіз:",
  Existing_PIN:"Қолданыстағы PIN кодты енгізіңіз:",
  Pin_Change_Header:"PIN растау",
  Pin_Change_SubHeader:"Өзгерісті растау үшін ескі PIN кодыңызды енгізіңіз.",
  Pass_Change_Header:"Құпия сөзді растау",
  Pass_Change_SubHeader:"Өзгерісті растау үшін ескі құпия сөзіңізді енгізіңіз.",
  PIN_Enter_Message:"Растау үшін PIN кодын енгізіңіз.",
  Password_Enter_Message:"Растау үшін құпия сөзіңізді енгізіңіз.",
  Pin_Updated_Success:"PIN коды сәтті жаңартылды!",
  Pin_Updated_Fail:"PIN кодын жаңарту мүмкін емес.",
  Pass_Updated_Success:"Құпия сөз сәтті жаңартылды.",
  Pass_Updated_Fail:"Құпия сөзді жаңарту мүмкін емес.",
  Preview_Payment:"Төлемді алдын ала қарау",
  Preview_Payroll:"Жалақыны алдын ала қарау",
  No_Payments_Created:"Жасалған төлемдер табылмады.",
  Payment_Preview:"Төлемді алдын ала қарау",
  Enable_Payee:"Төлеуші мүмкіндігін қосыңыз",
  Rendered:"Көрсетілген",
  No_Email:"Электрондық пошта жоқ",
  Setup_Cheques:"Орнату тексерулері",
  name:"Аты",
  address:"Мекенжай",
  address_2:"Мекенжай жолы 2",
  city:"Қала",
  province:"Облыс",
  postal_code:"Пошта/почта индексі",
  country:"Ел",
  username:"Пайдаланушы аты",
  full_name:"Толық аты",
  birthday:"Туған күн",
  email:"Электрондық пошта",
  phone:"Телефон",
  employees:"Қызметкерлер",
  addresses:"Мекенжайлар",
  payment_amount_limit:"Төлем сомасының шегі",
  total_limit:"Жалпы шек",
  payees:"Төлеушілер",
  description:"Сипаттама",
  address_line_one:"Мекенжайдың бірінші жолы",
  address_line_two:"Мекенжай жолы екінші",
  image:"Сурет",
  account_holder:"Шот иесі",
  cheque_starting_id:"Бастау идентификаторын тексеріңіз",
  transit_number:"Транзит нөмірі",
  institution_number:"Мекеме нөмірі",
  designation_number:"Белгілеу нөмірі",
  account_number:"Шот нөмірі",
  currency:"Валюта",
  signature:"Қол қою",
  payment_payroll_limit:"Төлем лимиті",
  total_limi:"Жалпы шектеу",
  date:"Күн",
  printed_memo:"Басып шығарылған жадынама",
  banks:"Банктер",
  signature_image:"Қолтаңба суреті",
  address_name:"Мекенжай атауы",
  notes:"Ескертпелер",
  design:"Дизайн",
  title:"Тақырып",
  frequency:"Жиілік",
  fax:"Факс",
  salaries:"Жалақылар",
  salary_ids:"Жалақы идентификаторлары",
  start_time:"Басталу уақыты",
  end_time:"Аяқталу уақыты",
  paid:"Ақылы",
  overtime_percentage:"Төленген пайыз",
  overtime_amount:"Төленген тұрақты сома",
  first_name:"Аты",
  last_name:"Тек",
  moderation:"Модерация",
  create:"Жасау",
  edit:"Өңдеу",
  destroy:"Жою",
  day_start_time:"Күннің_басталу_уақыты",
  day_end_time:"Күннің_соңы_уақыты",
  fullname:"Аты",
  time:"Уақыт",
  auto_send:"Автоматты түрде жіберу",
  time_method:"Уақыт әдісі",
  schedules:"Кестелер",
  indefinite_payroll_enabled:"Шексіз қосу",
  amount:"Сома",
  repeat:"Қайталау",
  always_enabled:"Әрқашан қосулы",
  start_date:"Басталатын күн",
  end_date:"Аяқталу күні",
  Cheque_Total:"Барлығын тексеріңіз",
  Total_Amount:"Жалпы сома:",
  Amounts:"Сомалар:",
  Images:"Суреттер",
  Files:"Файлдар",
  Previewing:"Алдын ала қарау:",
  Insert:"Кірістіру",
  Preview_Import:"Импортты алдын ала қарау",
  Entry:"Кіру",
  Entries:"Жазбалар",
  No_Entries:"Жазбалар жоқ",
  Import_Type:"Импорт түрі",
  Select_Details:"Мәліметтер таңдаңыз",
  Select_Payee:"Төлеуші таңдаңыз",
  Enable_Holidays:"Мерекелерді қосыңыз",
  Disable_Holidays:"Мерекелерді өшіру",
  Wire_Transfer:"Ақша аударымы",
  New_Export:"Жаңа экспорт",
  Export_Data:"Деректерді экспорттау",
  Export_Data_Word:"Экспорттау және жүктеп алу үшін файл түрін таңдаңыз.",
  Export_File:"Файлды экспорттау",
  Mode:"Режим",
  Times:"Уақыт",
  Widgets:"Виджеттер",
  Slider:"Слайдер",
  Set_Details:"Мәліметтер орнату",
  Select_Type:"Түр таңдаңыз",
  Display_Slider:"Слайдерді көрсету",
  Dashboard_Slider:"Бақылау тақтасының сырғытпасы",
  Dashboard_Mode:"Бақылау тақтасы режимі",
  Show_Intro:"Кіріспе көрсету",
  Show_Payrolls:"Жалақыны көрсету",
  Show_Holidays:"Мерекелерді көрсету",
  Show_Invoices:"Шот-фактураларды көрсету",
  Show_Cheques:"Чектерді көрсету",
  Enabled_Widgets:"Қосылған виджеттер",
  Disabled_Widgets:"Өшірілген виджеттер",
  Colors:"Түстер",
  Barcodes:"Штрихкодтар",
  View_Timers:"Таймерлерді көру",
  Gradients:"Градиенттер",
  No_Info:"Ақпарат жоқ",
  Disable:"Өшіру",
  Show_Layer:"Қабаттарды көрсету",
  Hide_Layer:"Қабаттарды жасыру",
  Text_Layer:"Мәтін қабаттары",
  Secondly:"Екіншіден",
  Minutely:"Минут сайын",
  nine_am:"9:00",
  five_pm:"17:00",
  Enable_Address:"Мекенжайды қосу",
  Invalid_File_Type:"Кешіріңіз, жарамсыз файл түрі таңдалды. Қолдау көрсетілетін файл түрі:",
  Error_Updating_Entry:"Кешіріңіз, бұл жазбаны жаңарту кезінде қате орын алды.",
  Schedule_Timing_Alert:"Қате: кестенің басталу уақыты аяқталу уақытынан кейінгі мәнге орнатылған.",
  Select_Multiple_Payments_Prompt:"Ешбір төлемдер таңдалмаған. Кем дегенде бір төлемді таңдаңыз.",
  Select_Multiple_Cheques_Prompt:"Ешбір чек таңдалмаған. Кем дегенде бір чек таңдаңыз.",
  Select_Multiple_Items_Prompt:"Ешбір элемент таңдалмаған. Кем дегенде бір элементті таңдаңыз.",
  Paymemt_Multiple_Delete_Confirmation:"Бірнеше төлемдерді жойғыңыз келетініне сенімдісіз бе?",
  Cheque_Multiple_Delete_Confirmation:"Бірнеше тексеруді жойғыңыз келетініне сенімдісіз бе?",
  Payee_Multiple_Delete_Confirmation:"Бірнеше төлем алушыны жойғыңыз келетініне сенімдісіз бе?",
  Employee_Multiple_Delete_Confirmation:"Бірнеше қызметкерді жойғыңыз келетініне сенімдісіз бе?",
  MICR_Warning:"Ескертпе: Кейбір принтерлер мен құрылғылар MICR қаріпін дұрыс көрсетпеуі мүмкін.",
  Automatically_Send:"Автоматты түрде жіберу",
  Type:"Түр",
  Payment_Type:"Төлем түрі",
  Auto_Send:"Автоматты жіберу",
  Automatically_Process:"Автоматты түрде өңдеу",
  Auto_Process:"Автоматты процесс",
  Image_Based:"Суретке негізделген",
  Font_Based:"Қаріпке негізделген",
  Rerender:"Қайта көрсету",
  Rendering:"Рендеринг",
  Render:"Файлдар",
  Top:"Жоғарғы",
  Middle:"Орташа",
  Bottom:"Төменгі",
  Top_Left:"Жоғарғы сол",
  Top_Center:"Жоғарғы орталық",
  Top_Right:"Жоғарғы оң",
  Middle_Left:"Ортаңғы сол",
  Middle_Center:"Орта орталық",
  Middle_Right:"Ортаңғы оң",
  Bottom_Left:"Төменгі сол",
  Bottom_Center:"Төменгі орталық",
  Bottom_Right:"Төменгі оң жақ",
  Numbers:"Сандар",
  Verified:"Тексерілді",
  Paper_Size:"Қағаз өлшемі",
  New_Device:"Жаңа құрылғы",
  Add_Device:"Құрылғы қосу",
  Remove_Device:"Құрылғыны жою",
  Delete_Device:"Құрылғыны жою",
  Block_Device:"Құрылғыны блоктау",
  Block:"Блоктау",
  Unblock:"Блоктан шығару",
  Table:"Кесте",
  Scan_Login_Code:"Логин кодын сканерлеңіз",
  Login_Code:"Кіру коды",
  Scan_Code:"Кодты сканерлеу",
  Scan_QR_Code:"QR кодын сканерлеңіз",
  Select_All:"Барлығын таңдаңыз",
  Deselect_All:"Барлығын таңдауды алып тастаңыз",
  Increase:"Өсу",
  Decrease:"Азайту",
  Bold:"Қалың",
  Text:"Мәтін",
  Style:"Стиль",
  Italic:"курсив",
  QR_Code:"QR коды",
  Barcode:"Штрих-код",
  Browse_Images:"Суреттерді шолу",
  Browse_Files:"Файлдарды шолу",
  Background_Image:"Фондық сурет",
  Logo_Image:"Логотип кескіні",
  Header_Image:"Тақырып суреті",
  Bank_Image:"Банк кескіні",
  Cut_Lines:"Сызықтарды кесу",
  Background:"Фон",
  License:"Лицензия",
  One_License:"1 Лицензия:",
  Licensed:"Лицензияланған:",
  Not_Licensed:"Лицензияланбаған",
  Enter_Serial:"Серияны енгізіңіз",
  Serial_Key:"Сериялық кілт",
  Serial:"Сериялық",
  Product_Key:"Өнімнің кілті",
  Check_Product_Key:"Өнім кілтін тексеріңіз",
  Add_Product_Key:"Өнім кілтін қосыңыз",
  Verifying_Purchase:"Сатып алу расталуда...",
  Print_Envelope:"Конвертті басып шығару",
  Envelope:"Конверт",
  Thank_You:"Рақмет сізге!",
  Scale:"Масштаб",
  Print_Scale:"Басып шығару масштабы",
  Borders:"Шекаралар",
  VOID:"ЖОҚ",
  Void_Cheque:"Жарамсыз тексеру",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ТАПСЫРЫСҚА ТӨЛЕҢІЗ:",
  NO_DOLLARS:"ДОЛЛАР ЖОҚ ",
  ONE_DOLLAR:"БІР ДОЛЛАР",
  DOLLARS:" ДОЛЛАР",
  AND:" ЖӘНЕ ",
  CENTS:" ЦЕНТ.",
  NO_:"ЖОҚ ",
  ONE_:"БІР ",
  AND_NO_:"ЖӘНЕ ЖОҚ ",
  _AND_ONE_:"ЖӘНЕ БІР ",
  DOLLARS_AND_ONE_CENT:" ЖӘНЕ БІР ЦЕНТ.",
  AND_NO_CENTS:" ЖӘНЕ НӨЛ ЦЕНТ.",
  CHEQUE_PRINT_DATE:"КҮНІ:",
  CHEQUE_PRINT_MEMO:"ЕСКЕРТПЕ:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"Депутат",
  Initial_Tasks:"Бастапқы тапсырмалар",
  Inital_Setup:"Бастапқы орнату",
  Welcome_To:"Қош келдіңіз ",
  Welcome:"Қош келдіңіз",
  Swipe:"Сүрту",
  Intro_Setup:"Кіріспе орнату",
  Introduction:"Кіріспе",
  CHEQUE_PRINT_CREDIT:"Чектермен қамтамасыз етілген",
  Title_Intro_Word:"Чектерге қош келдіңіз",
  Title_Intro:"Чектермен таныстыру",
  Title_Setup:"Чектерді орнату",
  PayPal_Default_Email_Message:"Сіз жаңа PayPal аударымын алдыңыз. [Чектермен қамтамасыз етілген]",
  Cheques_App_Export:"Чектермен экспортталады",
  Post_Intro_Thanks:"Чектерді таңдағаныңызға рахмет. Орнату процесі аяқталды.",
  Post_Intro_Word:"Алғашқы чекті басып шығару, болашақ төлемді қосу немесе жалақы тізіміне қызметкерлерді қосу арқылы бастаңыз.",
  Upgrade_Required:"Чектер осы хабарды жасыру және қосымша мүмкіндіктерді ашу үшін бағдарламалық құралдың жоғарырақ нұсқасын иеленуді талап етеді.",
  Upgrade_Title:"Чектер",
  Mailout_Prompt:"Сіз қосымша төлем чектерін өзіңіз үшін Checks пошта арқылы жіберуді таңдай аласыз.",
  Mailed_Cheque:"Поштамен жіберілген чек:",
  Mailed_Cheque_Color:"Поштамен жіберілген чек (түс):",
  Terms_Purchase:"Чектері бар барлық электрондық сатып алулар сатып алған күннен бастап 30 күнге дейін толығымен қайтарылады. Жалғастырмас бұрын <a href='#'>Шарттар мен шарттарды</a> оқып шығыңыз және келісіңіз.",
  Dashboard_Setup:"Негізгі принтерді орнату",
  Dashboard_Add:"Негізгі банк шотын қосу",
  Dashboard_Customize:"Тексеру үлгісін таңдаңыз",
  Dashboard_Job_Salary:"Жұмысыңызды құрыңыз және жалақыңызды қосыңыз",
  Dashboard_Employees:"Қызметкерлер мен төлеушілерді қадағалау",
  Dashboard_Print:"Айлық чектеріңізді басып шығарыңыз және жіберіңіз",
  Dashboard_Payroll:"Жалақыны басып шығаруды автоматтандыру",
  Dashboard_PayPal:"PayPal жалақысын / төлемдерін орнату",
  Begin_Setup:"Орнатуды бастау",
  Get_Started:"Бастау",
  Purchase:"Сатып алу",
  Lockdown:"Карантин",
  Unlock:"Ашу",
  Detailed:"Егжей-тегжейлі",
  Log_In:"Кіру",
  Login:"Кіру",
  Launch:"Іске қосу",
  Register:"Тіркелу",
  Finish:"Аяқтау",
  List:"Тізім",
  Weekends:"Демалыс күндері",
  Weekly:"Апта сайын",
  PayPal_Default_Subject:"Жаңа төлем",
  Payment_Message:"Төлем туралы хабарлама",
  PayPal_Default_Payment_Note:"Рақмет сізге",
  Setup_Your_Cheqing_Account:"Шотты тексеру",
  Add_Your_Primary_Cheqing_Account:"Негізгі тексеру тіркелгіңізді қосыңыз.",
  Welcome_Word:"Еңбекақы төлеу мен адам ресурстарын басқаруды жеңілдету және автоматтандыру",
  Get_Started_Quickly:"Бастауға көмектесетін бірнеше қарапайым сұрақтарға жауап беріңіз...",
  Done_Intro_Word:"Орнату аяқталды",
  PIN_Protected:"Құпия сөз және PIN кодпен қорғалған",
  Enter_New_PIN:"Жаңа PIN кодын енгізіңіз:",
  Enter_PIN:"PIN кодын енгізіңіз:",
  Invalid_PIN:"Жарамсыз PIN енгізілді.",
  Account_Identifier:"Тіркелгі идентификаторы",
  New_Account_Identifier:"Жаңа тіркелгі идентификаторы",
  attempt:"талпыныс",
  attempts:"әрекеттер",
  remaining:"қалған",
  Language:"Тіл",
  languages:"Тілдер",
  select_languages:"Тілді таңдау",
  Deposit:"Депозит",
  Hire_One_Now:"Қазір біреуін жалдаңыз",
  App_Locked:"Қолданба құлыпталған.",
  Skip_:"Өткізіп жіберу",
  Skip_to_Dashboard:"Басқару тақтасына өту",
  Dashboard:"Бақылау тақтасы",
  Import:"импорт",
  Admin:"Әкімшілер",
  New_Admin:"Жаңа әкімші",
  Settings:"Параметрлер",
  Color_Picker:"Түс таңдаушы",
  Font_Picker:"Қаріп таңдау құралы",
  Logout:"Шығу",
  Close:"Жабық",
  Close_menu:"Жабық",
  Excel:"Excel файлы",
  Csv:"CSV файлы",
  Sql:"SQL файлы",
  Json:"JSON файлы",
  Url:"URL арқылы импорттау",
  Back:"Артқа",
  Timers:"Таймерлер",
  Cheque:"Тексеру",
  Print:"Басып шығару",
  Designs:"Дизайндар",
  Pause_Printing:"Басып шығаруды кідірту",
  Resume_Printing:"Басып шығаруды жалғастыру",
  Printing_Paused:"Басып шығару кідіртілді",
  PrintingUnavailable:"Кешіріңіз! Бұл жүйеде басып шығару мүмкін емес.",
  Prints_Paused:"Басып шығару кідіртілді",
  Administration:"Әкімшілік",
  Loading:"Жүктелуде",
  Unnamed:"Аты жоқ",
  error:"Кешіріңіз, бұл чекті көру үшін ашу мүмкін емес.",
  No_Cheques_To_Print:"Басып шығару үшін чектер жоқ",
  No_Cheques_To_Print_Word:"<a routerLink='/folder/Cheque/New'>Жаңа чек</a> жасаңыз.",
  New_Cheque:"Жаңа чек",
  Start_One_Now:"Қазір бірін баста",
  Edit_Cheque:"Тексеруді өңдеу",
  Select_Cheques:"Чектерді таңдау",
  Select_Employee:"Қызметкерді таңдау",
  Default_Printer:"Әдепкі принтер",
  Reassign:"Қайта тағайындау",
  Configure:"Конфигурациялау",
  Template:"Үлгі",
  Designer:"Дизайншы",
  Edit_Designs:"Дизайндарды өңдеу",
  New_Design:"Жаңа дизайн",
  Next:"Келесі",
  Save:"Сақтау",
  Name:"Аты",
  Mail:"Пошта",
  Amount:"Сома",
  Date:"Күні",
  PayPal:"PayPal",
  Payouts:"Төлемдер",
  PayPal_Label:"PayPal белгісі",
  Email_Username:"Электрондық пошта / пайдаланушы аты",
  Client_ID:"Клиент идентификаторы",
  Sandbox_Email:"Sandbox электрондық поштасы",
  PayPal_Email:"PayPal электрондық поштасы",
  PayPal_Username:"API пайдаланушы аты",
  PayPal_Payouts:"PayPal төлемдері",
  Select_PayPal_Key:"PayPal кілтін таңдау",
  Secret:"Құпия",
  API_Secret:"API құпиясы",
  PayPal_API_Keys:"PayPal кілттері",
  New_PayPal_Key:"Жаңа PayPal кілті",
  Email_Subject:"Электрондық пошта тақырыбы",
  Email_Message:"Электрондық пошта хабары",
  Payout_Options:"Төлем опциялары",
  Payment_Note:"Төлем туралы жазба",
  Enable_Employee:"Қызметкерді қосу",
  Enable_Production_Mode:"Өндіріс режимін қосу",
  Sandbox_Username:"Sandbox пайдаланушы аты",
  Sandbox_Signature:"Мүмкіндік қолтаңбасы",
  Sandbox_Password:"Sandbox құпия сөзі",
  Production_Username:"Өндіріс пайдаланушы аты",
  Production_Signature:"Өндірістік қолтаңба",
  Production_Password:"Өндіріс құпия сөзі",
  Production_Email:"Өндіріс электрондық поштасы",
  API_Client_ID:"API клиент идентификаторы",
  API_Signature:"API қолтаңбасы",
  API_Password:"API құпия сөзі",
  API_Username:"API пайдаланушы аты",
  Secret_Key:"Құпия кілт",
  Sandbox:"құмсалғыш",
  Production:"Өндіріс",
  Sandbox_Keys:"Мүмкіндік кілттері",
  Production_Keys:"Өндірістік кілттер",
  API_Title:"API атауы",
  Production_Mode:"Өндіріс режимі",
  Account_Label:"Тіркелгі белгісі",
  No_PayPal_Setup:"PayPal кілті жоқ",
  Enable_PayPal_Account:"PayPal тіркелгісін қосу",
  No_PayPal_Word:"<a routerLink='/folder/Invoice/New'>PayPal API кілтін</a> қосыңыз.",
  Printed_Memo:"Басып шығарылған жадынама",
  Employee:"Қызметкер",
  View_Employee:"Қызметкерді қарау",
  Mailing_Address:"Пошта адресі",
  Company_Address:"Компанияның мекенжайы",
  Select_Company_Address:"Компания мекенжайын таңдау",
  Address:"Мекенжай",
  Any_Day:"Кез келген күн",
  Address_Name:"Мекенжай аты",
  Unit:"Бірлік",
  Account:"Есептік жазба",
  Bank_Account:"Банк шоты",
  Account_Limits:"Тіркелгі шектеулерін қосу",
  Payroll:"Еңбекақы",
  New_Payroll:"Жаңа жалақы",
  No_Payroll:"Алдағы жалақы жоқ",
  Upcoming_Holiday:"Алдағы мереке:",
  Invoice_Due:"Шот-фактураның мерзімі:",
  New_Invoice:"Жаңа шот-фактура",
  No_Invoices:"Шот-фактуралар жоқ",
  No_Invoices_Word:"Бірінші <a routerLink='/folder/Invoice/New'>Шот-фактураны</a> жасаңыз.",
  Cheque_Due:"Төлемді чек:",
  Payrolls:"Еңбекақылар",
  Sandbox_Mode:"құмсалғыш режимі",
  Hire:"жалдау",
  Pay:"Төлем",
  New:"Жаңа",
  Add:"Қосу",
  Make:"Жасау",
  Time:"Уақыт",
  Write:"Жазу",
  Holiday:"Мереке",
  Holidays:"Мерекелер",
  Next_Holiday:"Келесі мереке:",
  All_Done:"Бәрі аяқталды!",
  Employees:"Қызметкерлер",
  Payees:"Төлемшілер",
  Job:"Жұмыс",
  Jobs:"Жұмыс",
  Invoice:"Шот-фактура",
  Invoices:"Шот-фактуралар",
  Vacations:"Демалыс",
  Cheques:"Тексерулер",
  Brand_Cheques:"Бренд",
  Payment:"Төлем",
  Enable_Payment:"Төлемді қосу",
  Payments:"Төлемдер",
  Schedule:"Кесте",
  Schedules:"Кестелер",
  Timesheet:"Уақыт парағы",
  Timesheets:"Уақыт парағы",
  Salary:"Жалақы",
  New_Address:"Жаңа мекенжай",
  Address_2:"Мекенжай (2-жол)",
  _City:"Қала",
  _State:"Мемлекет/Нақыл",
  City:"Қала/кенттік",
  State:"Мемлекет/облыс",
  Postal:"Пошта/почта индексі",
  ZIP:"Пошта / ZIP",
  Country:"Ел",
  Addresses:"Мекенжайлар",
  Required_Options:"Қажетті опциялар",
  Optional_Options:"Қосымша опциялар",
  Additional_Options:"Қосымша опциялар",
  Required:"Міндетті",
  Optional:"Міндетті емес",
  Additional:"Қосымша",
  No_Addresses:"Мекенжай жоқ",
  New_Address_Word:"Бірінші <a routerLink='/folder/Address/New'>Мекенжайды</a> қосыңыз.",
  Select_Address:"Мекенжайды таңдау",
  No_Address:"Мекенжай жоқ",
  Print_Cheque:"Басып шығаруды тексеру",
  Print_Cheque_Now:"Қазір басып шығаруды тексеру",
  Description:"Сипаттамасы",
  Pay_To_The_Order_Of:"Тапсырыс бойынша төлеу:",
  Select_Date_Range:"Күндер ауқымын таңдау",
  Select_Starting_Date:"Бастау күнін таңдаңыз",
  Select_Ending_Date:"Аяқталу күнін таңдаңыз",
  Select_Date:"Күнді таңдау",
  Cheque_Date:"Тексеру күні",
  Cheque_Memo:"Жаднаманы тексеру",
  Blank_Cheque:"Бос чек",
  Blank:"Бос",
  No_Cheques:"Тексерулер жоқ",
  No_Cheques_Word:"Бірінші <a routerLink='/folder/Cheque/New'>тексеруді</a> басып шығарыңыз.",
  Cheque_Amount_Placeholder:"$0,00",
  View_Image:"Суретті қарау",
  View:"Көру",
  Modify:"Өзгерту",
  Delete:"Жою",
  Cheque_Paid:"Ақылы",
  New_Deduction:"Жаңа шегерім",
  Title:"Тақырып",
  Frequency:"Жиілік",
  Hourly:"Сағат сайын",
  Daily:"Күнделікті",
  Weekdays:"Жұмыс күндері",
  BiWeekly:"2 апта",
  TriWeekly:"3 апта",
  Monthly:"Ай сайын",
  MiMonthly:"2 ай",
  Quarterly:"Тоқсан сайын",
  Yearly:"Жыл сайын",
  Every_Week:"Әр аптада",
  Every_Payroll:"Әрбір жалақы",
  Every_Month:"Әр ай сайын",
  Annually:"Жыл сайын",
  Always_Scheduled:"Әрқашан жоспарлы",
  Start_Date:"Басталатын күн",
  End_Date:"Аяқтау күні",
  Start_Time:"Бастау уақыты",
  End_Time:"Ақырғы уақыт",
  Deduction_Label:"Шегерім белгісі",
  Notes:"Ескертулер",
  Options:"Опциялар",
  Enable:"Қосу",
  Select_Deductions:"Шегерімдерді таңдау",
  Deductions:"Шегерімдер",
  No_Deductions:"Шегерімдер жоқ",
  No_Deductions_Word:"Бірінші <a routerLink='/folder/Deduction/New'>Шегерімді</a> жасаңыз.",
  New_Employee:"Жаңа қызметкер",
  No_Title:"Тақырып жоқ",
  _Name:"Аты",
  About_Yourself:"Өзің жайлы",
  Full_Name:"Толық аты",
  Birthday:"Туған күн",
  Email:"Электрондық пошта",
  SMS:"ҚЫСҚАША ХАБАР ҚЫЗМЕТІ",
  Phone:"Телефон",
  Test:"Тест",
  Call:"Қоңырау",
  Fax:"Факс",
  Printed_Note:"Басып шығарылған жазба",
  Position:"Позиция",
  Job_Position:"Жұмыс орны",
  Select_a_Job:"Жұмыс таңдау",
  Select_a_Salary:"Жалақыны таңдаңыз",
  Add_a_Deduction:"Шегерім қосу",
  Taxes:"Салықтар",
  Add_Taxes:"Салықтарды қосу",
  Date_of_Birth:"Туған жылы",
  Email_Address:"Электрондық пошта",
  Phone_Number:"Телефон нөмірі",
  Phone_Call:"Телефон қоңырауы",
  Enable_on_Payroll:"Жалақы бойынша қосу",
  Select_Employees:"Қызметкерлерді таңдау",
  No_Employees:"Қызметкерлер жоқ",
  No_Employees_Word:"Бірінші жаңа <a routerLink='/folder/Employee/New'>Қызметкер</a>ді қосыңыз.",
  No_Name:"Есімі жоқ",
  Unemployeed:"Жұмыссыз",
  Employeed:"Жұмыспен қамтылған",
  Paid:"Ақылы",
  Enabled:"Қосылған",
  Disabled:"Мүгедек",
  Fire:"Өрт",
  Not_Available:"Жоқ",
  Not_Available_Word:"Бірінші <a routerLink='/folder/Invoice/New'>Шот-фактураны</a> басып шығарып, төлем жасаңыз.",
  Select_Invoices:"Шот-фактураларды_таңдау",
  Print_Invoice_Word:"Бірінші <a routerLink='/folder/Invoice/New'>Шот-фактураны</a> басып шығарып, төлем жасаңыз.",
  Invoice_Title:"Шот-фактура атауы",
  Invoice_Date:"Шот-фактура күні",
  Due_Date:"Мерзімнің өту күні",
  New_Job:"Жаңа жұмыс",
  Details:"Егжей",
  Customize:"Реттеу",
  Customize_Dashboard:"Басқару тақтасын теңшеу",
  Components:"Компоненттер",
  No_Components:"Компоненттер жоқ",
  Main_Components:"Негізгі компоненттер",
  Smaller_Components:"Кіші құрамдас бөліктер",
  Error_Loading_Page:"Бұл бетті жүктеу қатесі.",
  Bank_Details:"Банк мәліметтері",
  About_Your_Job:"Жұмысыңыз туралы",
  Your_Schedule:"Сіздің кестеңіз",
  Job_Title:"Қызмет атауы",
  Job_Description:"Қызмет сипаттамасы",
  Job_Details:"Жұмыс туралы мәліметтер",
  Enable_Job:"Тапсырманы қосу",
  Pay_Period:"Төлем мерзімі",
  Perpetually_Schedule:"Мәңгілік кесте",
  Select_Jobs:"Жұмыстарды таңдау",
  No_Jobs:"Жұмыс жоқ",
  Add_Jobs:"Жұмыстарды қосу",
  No_Jobs_Word:"Тізімге бірінші <a routerLink='/folder/Job/New'>Тапсырманы</a> қосыңыз.",
  Count_Employees:"job.employee_count+' Қызметкерлер'",
  Zero_Employees:"0 қызметкер",
  New_Leave:"Жаңа демалыс",
  Leave_Name:"Атын қалдырыңыз",
  Paid_Leave:"Ақылы демалыс",
  Leave_Pay:"Төлемді қалдыру",
  Indefinite_Leave:"Шектеусіз демалыс",
  Indefinite_Payroll:"Шектеусіз жалақы",
  Leave:"Кетіңіз",
  Add_Schedules:"Кестелерді қосу",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Жоқтығын қосу",
  Select_Leaves:"Жапырақтарды таңдау",
  No_Leaves:"Еңбек демалысы жоқ",
  Leave_Of_Absence:"Еңбек демалысы",
  Leaves_Of_Absence:"Бостандық парақтары",
  New_Leave_of_Absense:"Жаңа еңбек демалысы",
  No_Leaves_Word:"Бірінші <a routerLink='/folder/Leave/New'>Қызмет демалысын</a> қосыңыз.",
  Not_Enabled:"Қосу жоқ",
  Absences:"Қатысулар",
  Payee:"Алушы",
  New_Payee:"Жаңа төлем алушы",
  Payee_Identifier:"Алушы идентификаторы",
  Payee_Name:"Алушының аты",
  Payee_Title:"Алушы атауы",
  Payment_Memo:"Төлем туралы жазба",
  Select_Payees:"Алушыларды таңдау",
  No_Payees:"Төлемшілер жоқ",
  Add_Payee_Note:"Бірінші <a routerLink='/folder/Payee/New'>Алушыны</a> қосыңыз.",
  New_Payees:"Жаңа төлеушілер",
  About_The_Payment_Schedule:"Төлем кестесі",
  Your_Payroll_Schedule:"Автоматты жалақы",
  New_Payment:"Жаңа төлем",
  Identifier:"Идентификатор",
  Select:"Таңдау",
  Memo:"Жаднама",
  Payment_Date:"Төлем күні",
  Mark_as_Paid:"Төленді деп белгілеу",
  Select_Payments:"Төлемдерді таңдау",
  No_Payments:"Төлемдер жоқ",
  No_Payments_Word:"Бірінші <a routerLink='/folder/Payment/New'>Төлемді</a> жасаңыз.",
  Create_Payroll:"Жалақыны құру",
  Properties:"Сипаттар",
  Payroll_Title:"Жалақы атауы",
  Payroll_Notes:"Еңбекақы туралы жазбалар",
  Payroll_Setup:"Жалақыны орнату",
  Tabulate_Payments:"Төлемдер кестесі",
  Tabulate:"Кесте",
  By:"Орындаушы:",
  Payments_By:"Төлемдер",
  Timesheets_And_Schedules:"Уақыт кестелері және кестелер",
  Both:"Екеуі",
  Items:"Заттар",
  Add_Payees:"Төлемшілерді қосу",
  Add_Account:"Аккаунт қосу",
  Enable_Account:"Тіркелгіні қосу",
  Enable_Payroll:"Жалақыны қосу",
  Print_Payroll:"Жалақыны басып шығару",
  No_Payrolls:"Жалақы жоқ",
  No_Payroll_Word:"Бірінші <a routerLink='/folder/Payroll/New'>Жалақыны</a> жасаңыз.",
  View_more:"ҚОСЫМША_ҚАРАУ",
  Less:"АЗДАУ",
  Add_Payroll:"Жалақыны қосу",
  Select_Payroll:"Жалақыны таңдау",
  About_Your_Salary:"Сіздің жалақыңыз туралы",
  Add_Salaries:"Жалақыны қосу",
  Add_Salary:"Жалақы қосу",
  Salaries:"Жалақы",
  No_Salaries:"Жалақы жоқ",
  No_Salaries_Word:"Бірінші <a routerLink='/folder/Salary/New'>Жалақыны</a> қосыңыз.",
  Select_Salaries:"Жалақыны таңдау",
  New_Salary:"Жаңа жалақы",
  Salary_Name:"Жалақы идентификаторы",
  Enable_Salary:"Жалақыны қосу",
  Salary_Amount:"Жалақы мөлшері",
  New_Schedule:"Жаңа кесте",
  Schedule_Title:"Кесте атауы",
  Add_Address:"Мекенжай қосу",
  Repeat:"Қайталау",
  Design:"Дизайн",
  Edit_Design:"Дизайнды өңдеу",
  Edit_this_Design:"Осы дизайнды өңдеу",
  Repeat_Payment:"Қайта төлеу",
  Enable_Schedule:"Кестені қосу",
  Never:"Ешқашан",
  Select_Schedule:"Кестелерді таңдау",
  No_Schedules:"Кесте жоқ",
  No_Schedules_Word:"Бірінші <a routerLink='/folder/Schedule/New'>Кестені</a> жасаңыз.",
  New_Administrator:"Жаңа әкімші",
  Username:"Пайдаланушы аты",
  First_Name:"Аты",
  Last_Name:"Тек",
  Add_an_Address:"Мекенжай қосу",
  Payment_Limit:"Бір төлем лимиті",
  Total_Limit:"Жалпы шек",
  Select_Accounts:"Тіркелгілерді таңдау",
  No_Administrators:"Әкімшілер жоқ",
  No_Administrators_Word:"Бірінші <a routerLink='/folder/Administrator/New'>Әкімші тіркелгісін</a> жасаңыз.",
  New_Administrators_Word:"Бірінші <a routerLink='/folder/Administrator/New'>Әкімші</a> қосыңыз",
  Cloud:"Бұлт",
  Backup:"Сақтық көшірме",
  Enable_iCloud:"iCloud қосу",
  Enable_Google_Drive:"Google Drive қосу",
  Enable_Microsoft_OneDrive:"Microsoft OneDrive қосу",
  Automatically_Backup:"Сақтық көшірме автоматты түрде",
  FTP_Settings:"FTP параметрлері",
  URL_File_Prompt:"Импортталатын .xls / .xlsx / .json файлының орнын көрсетіңіз:",
  URL_SQL_Prompt:"Импортталатын SQLite файлының орнын көрсетіңіз:",
  FTP_Backup:"FTP сақтық көшірмесі",
  FTP_Import:"FTP импорты",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Хост",
  Port:"Порт",
  Path:"Жол",
  Data_Path:"Деректер жолы",
  Enable_FTP_Account:"FTP тіркелгісін қосу",
  HostnameIP:"Хост атауы",
  Password:"Пароль",
  Connection_Port:"Қосылу порты",
  Enable_MySQL_Database:"MySQL дерекқорын қосу",
  Log:"журнал",
  Reset:"Қалпына келтіру",
  Erase:"Өшіру",
  Export:"Экспорт",
  Database:"Дерекқор",
  Upload_CSV:"CSV жүктеп салу",
  Download_CSV:"CSV жүктеп алу",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL сақтық көшірмесі",
  Internal_Notes:"Ішкі жазбалар",
  Root_Path:"Түбірлік жол",
  Select_Backup:"Сақтық көшірме жасауды таңдаңыз",
  Add_New_Backup:"Жаңа сақтық көшірме қосу",
  First_Backup:"Бірінші сақтық көшірмені орнату...",
  Backups:"Сақтық көшірмелер",
  Add_Backup:"Сақтық көшірме қосу",
  No_Backups:"Сақтық көшірмелерді табу мүмкін емес",
  Select_Backup_Type:"Орнатқыңыз келетін сақтық көшірме түрін таңдаңыз...",
  Backup_Type:"Сақтық көшірме түрі",
  FTP_Drive:"FTP дискісі",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Жүргізу",
  Microsoft_OneDrive:"Жүргізу",
  Import_Fields:"Импорт өрістері",
  Import_Fields_Word:"<a routerLink='/folder/Holiday/New'>Импорттау</a> деректерін таңдау үшін осы бөлімді пайдаланыңыз.",
  Upload:"Жүктеп салу",
  Download:"Жүктеп алу",
  Download_JSON:"JSON деректері ретінде жүктеп алу",
  Download_SQL:"SQL файлы ретінде жүктеп алу",
  New_Bank:"Жаңа банк",
  New_Account:"Жаңа тіркелгі",
  New_Bank_Account:"Жаңа банк шоты",
  Upload_Image:"Суретті жүктеп салу",
  Bank_Name:"Банк атауы",
  Bank_Address:"Банк мекенжайы",
  Branch_Address:"Филиал мекенжайы",
  Address_on_Cheque:"Мекенжай",
  Cheque_Numbers:"Тексеру нөмірлері",
  Cheque_Details:"Мәліметтерді тексеру",
  Bank_Logo:"Банк логотипі",
  Cheque_ID:"Идентификаторды тексеру",
  Starting_Cheque_ID:"Тексеруді бастау идентификаторы",
  Transit_Number:"Транзит нөмірі",
  Institution_Number:"Мекеме нөмірі",
  Designation_Number:"Тағайындау нөмірі",
  Account_Number:"Шот нөмірі",
  Limits:"Шектер",
  Default_Limits:"Әдепкі шектеулер",
  Over_Limit:"Шектен асып кету",
  Under_Limit:"Шектеу астында",
  Payroll_Limit:"Жалақы лимиті",
  Enable_Bank_Account:"Банк шотын қосу",
  Select_Account:"Тіркелгіні таңдау",
  No_Bank_Account:"Банк шоты жоқ",
  No_Bank_Account_Word:"Бірінші <a routerLink='/folder/Accounts/New'>Банк шотын</a> қосыңыз.",
  Bank_Accounts:"Банк шоттары",
  No_Accounts:"Тіркелгі жоқ",
  No_Accounts_Note:"Бірінші <a routerLink='/folder/Accounts/New'>Банк шотын</a> қосыңыз.",
  Cheque_Designer:"Конструкторды тексеру",
  Cheque_Design:"Дизайнды тексеру",
  Select_Design:"Дизайнды таңдау",
  Cheque_Designs:"Дизайндарды тексеру",
  No_Cheque_Designs:"Тексеру дизайны жоқ",
  No_Cheque_Designs_Word:"Өзіңіздің жеке <a routerLink='/folder/Settings/Cheque/Design/New'>Дизайнды тексеру</a> жасаңыз.",
  Set_Default:"Әдепкі ретінде орнату",
  Default:"Әдепкі",
  Remove:"Жою",
  Folder:"Қалта",
  Edit:"Өңдеу",
  LoadingDots:"Жүктелуде...",
  Add_a_New_File:"<a href='#' (click)='add()'>Жаңа файл</a> қосу",
  this_folder:"осы қалта",
  FTP_Backup_Settings:"FTP сақтық көшірме параметрлері",
  Secure_File_Transfer:"Қауіпсіз файлдарды тасымалдау",
  New_Holiday:"Жаңа мереке",
  Add_Holiday:"Мерекені қосу",
  Holiday_Name:"Мереке атауы",
  Additional_Pay:"Қосымша төлем",
  Enable_Holiday:"Мерекені қосу",
  Select_Holidays:"Мерекелерді таңдау",
  No_Holidays:"Мерекелер жоқ",
  No_Holidays_Word:"Бірінші <a routerLink='/folder/Holiday/New'>Қоғамдық мерекені</a> қосыңыз.",
  New_Import:"Жаңа импорт",
  Import_Data:"Деректерді импорттау",
  Import_Data_Word:"Файл түрін немесе өзіңіз таңдаған жүктеп салу әдісін таңдаңыз.<br /> Қолдау көрсетілетін файлды жүктеп алғаннан кейін файлдағы импортталған өрістердің қайсысы қолданбадағы параметрге сәйкес келетінін таңдай аласыз.", 
  Import_File:"Файлды импорттау",
  Link_To_File:"Файлға сілтеме",
  Select_File:"Файлды таңдау",
  New_Moderator:"Жаңа модератор",
  Allow_Moderation:"Модерацияға рұқсат беру",
  Create_Paycheques:"Жалақы чектерін жасау",
  Edit_Paycheques_and_Data:"Жалақы мен деректерді өңдеу",
  Destroy_Data_and_Paycheques:"Деректерді және жалақы чектерін жою",
  Bonus_Percentage:"Жалақы пайызы",
  Fixed_Amount:"Тіркелген сома",
  Select_Moderator:"Модераторды таңдау",
  No_Moderators:"Модераторлар жоқ",
  Moderators:"Модераторлар",
  Moderator_Account:"Бірінші <a routerLink='/folder/Administrator/New'>Модератор тіркелгісін</a> жасаңыз.",
  No_Moderators_Word:"Бірінші <a routerLink='/folder/Administrator/New'>Модераторды</a> қосыңыз.",
  Defaults:"Әдепкі",
  Provide_Us_Info:"Бізге ақпарат беріңіз",
  Setup_Your_Printer:"Принтеріңізді орнату",
  Your_Company:"Компанияңыз туралы",
  Company_Name:"Компанияның Аты",
  Currency:"Валюта",
  Default_Currency:"Әдепкі валюта",
  Base_Monthly_Income:"Айлық табыс",
  Protection:"Қорғау",
  App_Protection:"Қолданбаны қорғау",
  PIN_Code_Protection:"ПИН-кодты қорғау",
  App_Protection_Word:"Тіркелгіні қорғауға көмектесетін қауіпсіздік әдістерін қосыңыз.",
  PIN_Code:"ПИН-код",
  Change_PIN:"PIN кодын өзгерту",
  New_Password:"Жаңа Құпия Сөз",
  Geofence_Protection:"Геоқоршау қорғанысы",
  Geofence_Area:"Аумақты орнату",
  Distance:"Қашықтық",
  Setup_Now:"Қазір орнату",
  Mile:"Миль",
  Km:"км",
  m:"м",
  Facial_Recognition:"Бетті тану",
  Face:"Бет",
  Setup:"Орнату",
  Label:"Заттаңба",
  Password_Protection:"Құпия сөзді қорғау",
  Modify_Password:"Құпия сөзді өзгерту",
  New_Tax_Entry:"Жаңа салық кірісі",
  New_Tax:"Жаңа салық",
  Tax_Label:"Салық белгісі",
  Perpetually_Enabled:"Мәңгі қосулы",
  Select_Taxes:"Салықтарды таңдау",
  Tax_Deductions:"Салық шегерімдері",
  No_Tax_Deductions:"Салық шегерімдері жоқ",
  No_Tax_Deductions_Word:"Бірінші <a routerLink='/folder/Tax/New'>Салық</a> шегерімін қосыңыз.",
  New_Timer:"Жаңа таймер",
  Start:"Бастау",
  Stop:"Тоқта",
  Start_Timer:"Таймерді бастау",
  Stop_Timer:"Таймерді тоқтату",
  Timer_Active:"Таймер белсенді",
  Timer:"Таймер:",
  Timer_Running:"Таймер: (жүгіру)",
  Save_Timer:"Таймерді сақтау",
  New_Timesheet:"Жаңа уақыт парағы",
  Select_Timesheets:"Уақыт кестелерін таңдау",
  Work_Notes:"Жұмыс жазбалары",
  Entry_Title:"Жазба тақырыбы",
  No_Timesheets:"Уақыт парағы жоқ",
  No_Timesheets_Word:"Бірінші <a routerLink='/folder/Timesheet/New'>Уақыт кестесін</a> қосыңыз.",
  Timesheet_Submitted:"Уақыт парағы жіберілді",
  Timesheet_Congrats:"Құттықтаймыз! Уақыт парағы сәтті жіберілді. Рахмет!",
  Timesheet_Copy:"Құжаттардың көшірмесін алу үшін бізге электрондық пошта мекенжайыңызды және/немесе ұялы телефон нөміріңізді беріңіз.",
  Submit:"Жіберу",
  Allow_Notifications:"Хабарландыруларға рұқсат беру",
  Untitled_Entry:"Жаңа жазба",
  Untitled_Bank:"Атаулы банк",
  Timesheet_Entry:"Уақыт парағын енгізу",
  Work_Description:"Жұмыс сипаттамасы",
  Enable_Timesheet:"Уақыт кестесін қосу",
  Submit_Timesheet:"Уақыт кестесін жіберу",
  Vacation:"Демалыс",
  New_Vacation:"Жаңа демалыс",
  Vacation_Name:"Демалыс атауы",
  Paid_Vacation:"Ақылы демалыс",
  Vacation_Pay:"Демалыс төлемі",
  Enable_Vacation:"Демалысты қосу",
  Select_Vacations:"Демалыстарды таңдау",
  No_Vacations:"Демалыс жоқ",
  No_Vacations_Word:"Бірінші <a routerLink='/folder/Vacation/New'>Демалыс</a> жазбасын жасаңыз.",
  Payroll_Schedule:"Еңбекақы төлеу кестесі",
  Next_Payroll:"Келесі жалақы:",
  Upcoming_Payroll:"Алдағы жалақы",
  No_Upcoming_Payroll:"Алдағы жалақы жоқ",
  Address_Book:"Мекенжай кітапшасы",
  Archived_Documents:"Мұрағатталған құжаттар",
  Dev_Mode:"Қолданба әзірлеу режимінде",
  Administrators:"Әкімшілер",
  Privacy:"Құпиялылық",
  None:"Жоқ",
  Select_Signature:"Қолтаңбаны таңдау",
  No_Signatures:"Қолтаңбалар жоқ",
  No_Signatures_Word:"Бірінші <a routerLink='/folder/Signature/New'>Қолтаңбаны</a> қосыңыз.",
  Repeat_Indefinitely:"Шексіз қайталау",
  Sign:"белгі",
  Sign_Here:"Осында қол қою",
  Date_Signed:"Қол қойылған күні",
  Signature_Pad:"Қол қою тақтасы",
  Account_Holder:"Шот иесі",
  Account_Properties:"Тіркелгі сипаттары",
  Name_On_Cheque:"Чектегі аты-жөні",
  Server_Hostname:"Сервер хост аты/IP",
  Printers:"Принтерлер",
  No_Printers:"Принтерлер жоқ",
  Printer_Exists:'Осындай атпен принтер бұрыннан бар.',
  No_Printers_Word:"Бірінші <a routerLink='/folder/Printer/New'>Принтерді</a> қосыңыз.",
  No_Printer_Alert:"Принтер анықталмаған. Принтерді орнатқыңыз келе ме?",
  Add_Printer:"Принтер қосу",
  New_Printer:"Жаңа принтер",
  Printer_Hostname:"Принтер хост атауы/IP",
  Printer_Label:"Принтер жапсырмасы",
  Printer_Protocol:"Принтер протоколы",
  Protocol:"Хаттама",
  Email_Print:"Электрондық пошта",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"розетка",
  Print_Job:"Басып шығару тапсырмасы",
  Printed:"Басып шығарылған",
  Not_Printed:"Басып шығарылмаған",
  Print_Jobs:"Басып шығару жұмыстары",
  Print_Queue:"Басып шығару кезегі",
  No_Print_Jobs:"Басып шығару жұмыстары жоқ",
  No_Prints:"Басып шығару үшін жаңа <a routerLink='/folder/Cheque/New'>Чек</a> жасаңыз.",
  Prints:"Баспалар",
  Find_Printer:"Принтерді табу",
  Find_AirPrint_Devices:"AirPrint құрылғыларын табу",
  Select_Printer:"Принтерді таңдау",
  System_UI:"Жүйенің пайдаланушы интерфейсі",
  Printer:"Принтер",
  Status:"Мәртебе",
  Preview:"Алдын ала қарау",
  Enable_Print_Job:"Басып шығару жұмысын қосу",
  Queue_Weight:"Кезек салмағы",
  Unlimited:"Шексіз",
  Show_Advanced_Printer_Options:"Қосымша принтер опцияларын көрсету",
  Advanced:"Озат",
  Location:"Орналасқан жері",
  Note:"Ескерту",
  Queue_Name:"Кезек аты",
  Pages_Printed_Limit:"Басып шығарылатын беттер шегі",
  MultiPage_Idle_Time:"Көп бетті күту уақыты (лар)",
  Page_Count_Limit:"Бет санының шегі",
  Page_Orientation:"Бет бағдары",
  Portrait:"Портрет",
  Landscape:"Пейзаж",
  Duplex:"Дуплекс",
  Double_Sided_Printing:"Екі жақты",
  Duplex_Mode:"Дуплексті режим",
  Duplex_Short:"Қысқа",
  Duplex_Long:"Ұзын",
  Duplex_None:"Жоқ",
  Color_And_Quality:"Түс және сапа",
  Monochrome:"Монохромды",
  Photo_Quality_Prints:"Фото сапалы басып шығару",
  Printer_Email:"Принтер электрондық поштасы",
  Automatically_Downsize:"Автоматты түрде кішірейту",
  Paper:"Қағаз",
  Paper_Name:"Қағаз атауы",
  Paper_Width:"Қағаз ені",
  Paper_Height:"Қағаз биіктігі",
  Paper_Autocut_Length:"Қағазды автоматты кесу ұзындығы",
  Margins:"Шектер",
  Page_Margins:"Бет шеттері",
  Page_Margin_Top:"Жоғарғы бет жиегі",
  Page_Margin_Right:"Оң жақ бет жиегі",
  Page_Margin_Bottom:"Төменгі бет жиегі",
  Page_Margin_Left:"Сол жақ бет жиегі",
  Add_Employees:"Қызметкерлерді қосу",
  Header:"Тақырып",
  Print_A_Page_Header:"Бет тақырыбын басып шығару",
  Header_Label:"Тақырып белгісі",
  Header_Page_Index:"Тақырып бетінің индексі",
  Header_Font_Name:"Тақырып қаріпі",
  Select_Font:"Қаріпті таңдау",
  Font_Selector:"Қаріп таңдау құралы",
  Header_Font_Size:"Тақырып қаріпі",
  Header_Bold:"Тақырыбы қалың",
  Header_Italic:"Тақырыбы курсив",
  Header_Alignment:"Тақырыпты туралау",
  Left:"Сол",
  Center:"Орталық",
  Right:"Дұрыс",
  Justified:"Ақталған",
  Header_Font_Color:"Тақырып түсі",
  Select_Color:"Түс таңдау",
  Footer:"Төменгі деректеме",
  Print_A_Page_Footer:"Беттің төменгі колонтитулын басып шығару",
  Footer_Label:"Төменгі деректеме белгісі",
  Footer_Page_Index:"Төменгі деректеме бетінің индексі",
  Footer_Font_Name:"Төменгі деректеме шрифті",
  Fonts:"Қаріптер",
  Done:"Дайын",
  Select_Fonts:"Қаріптерді таңдау",
  Footer_Font_Size:"Төменгі деректеме өлшемі",
  Footer_Bold:"Төменгі деректеме қалың",
  Footer_Italic:"Төменгі деректеме курсив",
  Footer_Alignment:"Төменгі деректемені туралау",
  Footer_Font_Color:"Төменгі деректеме түсі",
  Page_Copies:"Бет көшірмелері",
  Enable_Printer:"Принтерді қосу",
  Remote_Logging:"Қашықтан тіркеу",
  Log_Server:"Журнал сервері",
  Encryption:"Шифрлау",
  Random_Key:"Кездейсоқ кілт",
  Encryption_Key:"Шифрлау кілті",
  Cheques_Webserver:"Арнаулы деректер қоры",
  Learn_How:"Қалай үйрен",
  Signature:"Қолы",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Қолтаңбаны көру",
  Printed_Signature:"Басып шығарылған қолтаңба",
  Digitally_Sign:"Цифрлық қолтаңба",
  Digital_Signature:"Цифрлық қолтаңба",
  Add_Signature:"Қолтаңба қосу",
  Add_Your_Signature:"Қолтаңбаңызды қосыңыз",
  Enable_Signature:"Қолтаңбаны қосу",
  Digitally_Signed:"Цифрлық қолтаңба",
  Insert_Error:"Дерекқор ақауларына байланысты чекті сақтау мүмкін емес.",
  Delete_Confirmation:"Бұл ақпаратты шынымен жойғыңыз келе ме?",
  Discard_Confirmation:"Бұл ақпаратты шынымен жойғыңыз келе ме?",
  Discard_Bank_Confirmation:"Бұл есептік жазбаны шынымен жойғыңыз келе ме?",
  Discard_Printer_Confirmation:"Осы принтерді тастағыңыз келетініне сенімдісіз бе?",
  Delete_Bonus_Confirmation:"Бұл бонусты жойғыңыз келетініне сенімдісіз бе?",
  Delete_Invoice_Confirmation:"Осы шот-фактураны шынымен жойғыңыз келе ме?",
  Generated_Cheque:"Жасалған чек",
  Generated_Invoice:"Жасалған шот-фактура",
  Schedule_Start_Time:"Бастау кестесі",
  Schedule_End_Time:"Кестенің соңы",
  New_Call:"Жаңа қоңырау",
  No_Contacts:"Контактілер жоқ",
  No_Contacts_Word:"Әкімшілер, модераторлар, қызметкерлер және төлеушілер контактілер ретінде көрсетіледі.",
  PDF_Subject:"қаржы",
  PDF_Keywords:"жалақы төлемі PDF чек чектері",
  Printer_Setup:"Принтерді орнату",
  Printer_Selection:"Принтерді таңдау",
  New_Fax:"Жаңа факс",
  New_Fax_Message:"Жаңа факс хабарламасы",
  Fax_Machine:"Факс",
  Fax_Name:"Факс атауы",
  Fax_Email:"Факс электрондық поштасы",
  Fax_Number:"Факс нөмірі",
  Contents:"Мазмұны",
  Fax_Body:"Бет мәтіні",
  Header_Word:"Тақырып:",
  Header_Text:"Тақырып мәтіні",
  Include_Header:"Тақырыпты қосу",
  Include_Footer:"Төменгі деректемені қосу",
  Footer_Word:"Төменгі деректеме:",
  Footer_Text:"Төменгі деректеме мәтіні",
  Attach_a_Cheque:"Чекті тіркеңіз",
  Add_Deduction:"Шегерім қосу",
  Enable_Fax:"Факс жіберу",
  Select_Fax:"Факсты таңдау",
  No_Faxes:"Факстар жоқ",
  Faxes:"Факстар",
  Save_and_Send:"Факс жіберу",
  Save_and_Pay:"Сақтау және төлеу",
  WARNING:"ЕСКЕРТУ:",
  Remember:"Есте сақта",
  Printing:"Басып шығару",
  Printing_Complete:"Басып шығару аяқталды!\n\n",
  of:"ның",
  There_Were:"Сонда болды ",
  Successful_Prints:"сәтті басып шығару және",
  Unsuccessful_Prints:"сәтсіз басып шығару.",
  PrinterError:"Кешіріңіз! Қате орын алды.",
  Printing_:"Басып шығаруда...",
  PrinterSuccess:"Құжат сәтті басып шығарылды.",
  PrintersSuccess:"Құжаттар сәтті басып шығарылды.",
  Print_Cheques:"Чектерді басып шығару",
  New_Message:"Жаңа хабарлама",
  New_Messages:"Жаңа хабарламалар",
  Read_Message:"Хабарды оқу",
  Write_Message:"Хабар жазу",
  Send_Message:"Хат жіберу",
  Subject:"Тақырып",
  Message:"Хабар",
  Writing:"Жазу...",
  Send:"Жіберу",
  Set_Date:"Күнді орнату",
  Set_Time:"Уақытты орнату",
  Recieve:"Алу",
  Set_as_Default:"Әдепкі ретінде орнату",
  Default_Account:"Әдепкі тіркелгі",
  Default_Design:"Әдепкі дизайн",
  Multiple_Cheques:"Чектер (үш есе)",
  Account_Mode:"Тіркелгі режимі",
  Multiple_Cheques_Description:"Әр бетте үш чек.",
  Check_and_Table:"Тексеру және кесте",
  Check_including_Table:"Тексеру және есеп кестесі",
  Check_Mailer:"Пошта жіберушіні тексеру",
  Check_Mailer_Window:"Мекенжай терезесімен тексеріңіз.",
  DocuGard_Table_Top:"DocuGard Check & Table (жоғарғы)",
  DocuGard_Table_Middle:"DocuGard Check & Table (Орта)",
  DocuGard_Table_Bottom:"DocuGard тексеру және кесте (төменгі)",
  DocuGard_Mailer_Top:"DocuGard чек поштасы (жоғарғы)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (Орта)",
  DocuGard_Mailer_Bottom:"DocuGard тексеру поштасы (төменгі)",
  DocuGard_Three_Cheques:"DocuGard тексерулері (үш есе)",
  DocuGard_Cheque_Top:"DocuGard тексеру (жоғарғы)",
  DocuGard_Cheque_Middle:"DocuGard Check (Орта)",
  DocuGard_Cheque_Bottom:"DocuGard Check (төменгі)",
  DocuGard_Three_Cheques_Window:"Бір бетте үш чек",
  DocuGard_Table_Top_Window:"Чек және табыс кестесі",
  DocuGard_Table_Middle_Window:"Чек және табыс кестесі",
  DocuGard_Table_Bottom_Window:"Чек және табыс кестесі",
  DocuGard_Mailer_Top_Window:"Тексеру, кесте және мекенжай.",
  DocuGard_Mailer_Middle_Window:"Тексеру, кесте және мекенжай.",
  DocuGard_Mailer_Bottom_Window:"Тексеру, кесте және мекенжай.",
  DocuGard_Cheque_Top_Window:"Қауіпсіз қағазды тексеріңіз.",
  DocuGard_Cheque_Middle_Window:"Қауіпсіз қағазды тексеріңіз.",
  DocuGard_Cheque_Bottom_Window:"Қауіпсіз қағазды тексеріңіз.",
  Basic_Cheque:"Тексеру (жоғары)",
  Basic_Cheque_Print:"Бір чекті басып шығарыңыз.",
  Error_Setting_As_Paid:"Ақылы ретінде орнату қатесі",
  Add_Attachment:"Тіркеме қосу",
  PrinterUnavailable:"Принтер қолжетімсіз",
  CreditCardComponent:"Карточкалар",
  PaypalComponent:"PayPal",
  InteracComponent:"Интерак",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Жаңа депозит",
  Deposits:"Депозиттер",
  No_Deposits:"Депозиттер жоқ",
  Credit_Card_Deposit:"Несие картасы",
  New_Credit_Card_Deposit_Message:"Несие картасы бойынша депозит",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin депозиті",
  New_Interac_Deposit:"Интерак",
  New_Interac_Deposit_Message:"Интеракт алмасу",
  Payments_Limit:"Төлем лимиті",
  No_Payment_Limit:"Төлем лимиті жоқ",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal депозиті",
  No_Deposits_Word:"Бірінші компанияны <a routerLink='/folder/Deposit/New'>Депозит</a> қосыңыз.",
  No_Documents_Specified:"Басып шығару үшін ешқандай құжат көрсетілмеген",
  No_Printers_Added:"Принтер табылмады. Біреуін қосу үшін \"Параметрлер > Принтерлер\" тармағына өтіңіз.",
  DB_Erase_Prompt:"Бүкіл дерекқорды толығымен өшіру керек пе? ЕСКЕРТУ: барлық сақталған ақпаратты жоғалтасыз!",
  Console_Warning:"Бұл консольге ешбір мәтінді қоймаңыз. Өзіңізге және/немесе компанияңызға үлкен қауіп төндіруі мүмкін.",
  No_Faxes_Word:"Бірінші <a routerLink='/folder/Fax/New'>Факсты</a> жасаңыз.",
  Cheque_Delete_Confirmation:"Бұл чекті шынымен жойғыңыз келе ме?",
  Design_Delete_Confirmation:"Бұл дизайнды шынымен жойғыңыз келе ме?",
  Cheque_Pay_Confirmation:"Осы чекті төленген деп белгілеңіз бе? Ол басып шығару кезегінде КӨРСЕТІЛМЕЙДІ.",
  Payment_Pay_Confirmation:"Осы төлемді төленген деп белгілеңіз бе? Ол чек кезегіне КӨРСЕТІЛМЕЙДІ.",
  Delete_Deduction_Confirmation:"Осы шегеруді шынымен жойғыңыз келе ме?",
  Delete_Job_Confirmation:"Бұл жұмысты шынымен жойғыңыз келе ме?",
  Delete_Timesheet_Confirmation:"Осы уақыт кестесін шынымен жойғыңыз келе ме?",
  Delete_Schedule_Confirmation:"Осы кестені шынымен жойғыңыз келе ме?",
  Delete_Setting_Confirmation:"Бұл параметрді қалпына келтіргіңіз келетініне сенімдісіз бе?",
  Delete_Fax_Confirmation:"Осы факсты шынымен жойғыңыз келе ме?",
  Delete_File_Confirmation:"Бұл файлды шынымен жойғыңыз келе ме?",
  Delete_Vacation_Confirmation:"Бұл демалысты шынымен жойғыңыз келе ме?",
  Delete_Printer_Confirmation:"Осы принтерді шынымен жойғыңыз келе ме?",
  Remove_Design_Confirmation:"Бұл дизайнды шынымен жойғыңыз келе ме?",
  Delete_Payroll_Confirmation:"Осы жалақы тізімін шынымен жойғыңыз келе ме?",
  Send_Fax_Email_Confirmation:"Осы құжаттарды факспен және электрондық поштамен жібергіңіз келе ме?",
  Send_Email_Confirmation:"Осы құжатты электрондық пошта арқылы жібергіңіз келе ме?",
  Send_Fax_Confirmation:"Осы құжатты факспен жібергіңіз келе ме?",
  Error_Generating_PDF:"Кешіріңіз. Бұл құжатты жасау кезінде қате орын алды.",
  PDF_Error_Saving_Image:"Кешіріңіз. Бұл құжаттың PDF кескінін сақтау кезінде қате орын алды.",
  Test_Printer_Confirmation:"Осы принтерде сынақ бетін басып шығарғыңыз келе ме?",
  Save_Timesheet_Prompt:"Сақтау үшін \"Тақырып\" қосыңыз немесе \"Таймерді бастау\" түймесін басыңыз.",
  Remove_Geofence_Prompt:"Осы геоқоршаудың орнын шынымен жойғыңыз келе ме?",
  Delete_Employee_Confirmation:"Жойғыңыз келетініне сенімдісіз бе",
  Fire_Employee_Confirmation:"Сіз шынымен от алғыңыз келе ме?"
}