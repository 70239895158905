export const Tr = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  Copyright:"Telif hakkı &kopyala; 2023",
  black:"Siyah",
  green:"Yeşil",
  gold:"Altın",
  blue:"Mavi",
  brown:"Kahverengi",
  purple:"Mor",
  pink:"Pembe",
  red:"Kırmızı",
  Swatches:"Renk Örnekleri",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Renk Tonu",
  Saturation:"Doyma",
  Lightness:"Hafiflik",
  Upgrade_To_Pro:"Pro'ya yükselt",
  AllFeaturesInclude:"Tüm Özellikler şunları içerir:",
  PrintUnlimitedCheques:"Sınırsız Çek Yazdır",
  PremiumChequeDesigns:"Premium Çek Tasarımları",
  ManageUnlimitedEmployees:"Sınırsız Çalışanı Yönetin",
  AddUnlimitedPayees:"Sınırsız Alacaklı Ekle",
  CreateUnlimitedPayrolls:"Sınırsız Bordro Oluşturun",
  UnlimitedSchedulesandTimeSheets:"Sınırsız Program ve Zaman Çizelgeleri",
  BulkPayPalPayouts:"Toplu PayPal Ödemeleri",
  UnlimitedBankAccounts:"Sınırsız Banka Hesabı",
  ManageMultipleCompanies:"Birden Çok Şirketi Yönetin",
  TrackInsurancePolicies:"Sigorta Poliçelerini Takip Edin",
  Bio_MetricProtection:"Biyometrik Koruma",
  Geo_FenceLock_OutProtection:"Geo-Fence Kilitleme Koruması",
  Multiple_Companies_Word:"Çekler primi olmadan birden fazla şirketi yönetmek mümkün değildir.",
  PayPal_Payouts_Word:"Çek primi olmadan PayPal ödemeleri devre dışı bırakılır.",
  PINProtection:"PIN Koruması",
  PasswordProtection:"Şifre Koruması",
  May_Require_Approval:"Onay gerektirebilir.",
  Subscribe:"Abone",
  Billed:"Faturalandırılan",
  Up:"Yukarı",
  Down:"Aşağı",
  Positioning:"konumlandırma",
  Marker:"İşaretleyici",
  Drag_Marker:"Sürükle İşaretçisi",
  Tagline:"Çekleri Yazdırın ve Bordro Çizelgesi Oluşturun",
  Marker_Word:"Öğeyi boyutlandırmak için İşaretçileri kullanın.",
  Pinch_Zoom:"Sıkıştırarak Yakınlaştır",
  Pinch_Word:"Öğeyi yakınlaştırmak için sıkıştırın.",
  Drag:"Sürüklemek",
  Drag_Word:"Öğeleri sürüklemek için parmağınızı kullanın.",
  subscription_alias_word:"Otomatik yenilenen abonelik",
  premium_alias_word:"Tek Seferlik Yükseltme Paketi",
  print_alias_word:"Bireysel Çeklerin Yazdırılması",
  mode_alias_word:"mod",
  Pro:"profesyonel",
  Pro_word:"Pro sürümü gerekli.",
  Cant_Delete_Default_Company_Word:"Üzgünüz, varsayılan şirketinizi silemezsiniz.",
  Reinsert_Default_Designs:"Varsayılan Tasarımları Yeniden Ekle",
  Reinsert_Default_Designs_word:"Varsayılan tasarımları yeniden eklemek istiyor musunuz?",
  Subscription_Active_Disable_Word:"Bu abonelik aktiftir. Bu Çek aboneliğini iptal etmek ister misiniz?",
  Company_Logo:"Şirket logosu",
  ZERO_:"SIFIR",
  Disclaimer:"Feragatname",
  Privacy_Policy:"Gizlilik Politikası",
  EULA:"EULA'yı kontrol eder",
  Terms_Of_Service:"Kullanım Şartları",
  Terms_Of_Use:"Kullanım Şartları",
  Refunds:"Geri ödeme politikası",
  Single_Print:"1 Kontrol",
  Two_Prints:"2 Kontrol",
  Five_Prints:"5 Kontrol",
  Ten_Prints:"10 Kontrol",
  Fifteen_Prints:"15 Kontrol",
  Twenty_Prints:"20 Kontrol",
  Thirty_Prints:"30 Kontrol",
  Image_Added:"Resim Eklendi",
  Image_Preview:"Resim Önizleme",
  No_Image_Was_Selected:"Resim seçilmedi.",
  Cheques_Unlimited:"Sınırsız Kontrol",
  _Subscription:"abonelik",
  Subscription:"Çekler - 1 Ay",
  Two_Month_Subscription:"Çekler - 2 Ay",
  Three_Month_Subscription:"Çekler - 3 Ay",
  Six_Month_Subscription:"Çekler - 6 Ay",
  Twelve_Month_Subscription:"Çekler - 12 Ay",
  Cheques_Are_Available:"Çekler yazdırılabilir.",
  Upgrade_Required_Two:"Bir paket seçin ve satın alma işleminizi başlatmak için fiyat düğmesine iki kez dokunun. Saniyeler içinde profesyonel görünümlü tam renkli çekler yazdırın.",
  Month:"Ay",
  Due:"Vadesi dolmuş:",
  Expires:"geçerlilik süresi:",
  Subscription_Active:"Abonelik Aktif",
  Subscription_Inactive:"Abonelik Etkin Değil",
  Purchase_Additional_Cheques:"Ek çekler satın alıyor musunuz?",
  Printable_Cheque:"Yazdırılabilir Kontrol",
  Printable_Cheques:"Yazdırılabilir Çekler",
  Printable_Cheque_Word:"çek hesabınızda mevcut.",
  Printable_Cheques_Word:"Çekler hesabınızda mevcuttur.",
  Max_Amount_Message:"Belirttiğiniz miktar bu sistem için belirlenen maksimum miktarın üzerine çıktı:",
  Terms_Required_Word:"Çekleri kullanmaya devam etmeden önce bu sözleşmeyi kabul etmelisiniz.",
  Subscriptions:"Abonelikler",
  Product_Upgrades:"Yükseltmeler",
  Mailed_Out_Cheques:"Postayla Gönderilen Çekler",
  Enter_A_Company_Name:"Lütfen bir şirket adı girin.",
  Single_Cheques:"Tek Çekler",
  Cheque_Golden:"Altın Çek",
  Cheque_Golden_Window:"Altın onay tasarımı.",
  Cheque_Green:"Yeşil Kontrol",
  Cheque_Green_Window:"Yeşil onay tasarımı.",
  Cheque_Red:"Kırmızı Onay",
  Cheque_Red_Window:"Kırmızı onay tasarımı.",
  Cheque_Yellow:"Sarı Kontrol",
  Cheque_Yellow_Window:"Sarı onay tasarımı.",
  Cheque_Statue_of_Liberty:"Özgürlük Heykeli",
  Cheque_Statue_of_Liberty_Window:"Özgürlük Anıtı çek tasarımı.",
  Cheque_Green_Wave:"Yeşil Dalga",
  Cheque_Green_Wave_Window:"Yeşil onay tasarımı.",
  Cheque_Golden_Weave:"altın örgü",
  Cheque_Golden_Weave_Window:"Zarif altın çek tasarımı.",
  Cheque_Green_Sun:"yeşil güneş",
  Cheque_Green_Sun_Window:"Derin ve sakinleştirici çek tasarımı.",
  Cheque_Blue_Checkers:"mavi dama",
  Cheque_Blue_Checkers_Window:"Mavi onay tasarımı.",
  Cashiers_Check:"Kasiyer Çeki",
  Cashiers_Check_Window:"Kasiyer Çeki stil şablonu.",
  Cheque_Aqua_Checkers:"Su Daması",
  Cheque_Aqua_Checkers_Window:"Aqua kontrol tasarımı.",
  Cheque_Golden_Checkers:"altın dama",
  Cheque_Golden_Checkers_Window:"Altın onay tasarımı.",
  Upgrade_Unavailable:"Yükseltmeler Kullanılamıyor",
  Bank_Code_Protection:"Banka Numarası Koruması",
  Bank_Code_Protection_Word:"Banka numaralarınızı başka bir cihazda veya başka bir kullanıcı için çalışan bu uygulamada kullanılmaktan koruyun. Bu eylem GERİ DÖNÜLMEZ. Devam etmek?",
  Bank_Code_Protection_Blocked_Word:"Kullanmaya çalıştığınız banka numaraları başka bir kullanıcı veya cihaz için ayrılmıştır.",
  Bank_Code_Protection_Error_Word:"Numara doğrulama başarısız oldu. Lütfen internete bağlanın ve bu banka hesabını tekrar eklemeyi deneyin.",
  Bank_Code_Protection_Set_Error_Word:"Banka numarası koruması, internete bağlı olmanızı gerektirir. Lütfen bağlanın ve tekrar deneyin.",
  Upgrade_Unavailable_Word:"Üzgünüz, sizi doğrulamakta sorun yaşıyoruz. Checks abonelikleri ve yükseltmeleri şu anda bölgenizde satın alınamıyor.",
  PayPal_Payment_Preview:"PayPal Ödeme Önizlemesi",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"PayPal'ı seçin",
  PayPal_Applications:"PayPal Başvuruları",
  Purchase_With_Apple_Pay:"Apple Pay ile satın alın",
  Google_Pay:"Google Pay",
  Companies:"şirketler",
  Insurance:"Sigorta",
  New_PayPal:"Yeni PayPal",
  Pay_By:"İle ödemek",
  Insure:"Sigortalamak",
  Miles:"Mil",
  Payment_Method:"Ödeme yöntemi",
  Select_Policies:"Politikaları Seçin",
  Policies:"politikalar",
  Policy:"Politika",
  No_PayPal_Account:"PayPal Hesabı Yok",
  No_Policies:"Sigorta Poliçesi Yok",
  New_Policy:"Yeni politika",
  PayPal_Payment:"PayPal ödemesi",
  PayPal_Payments:"PayPal Ödemeleri",
  No_Payment_Selected:"Ödeme Seçilmedi",
  Sending_Payment_Word:"Lütfen bekleyin... Bu ödeme gönderiliyor.",
  Sending_Payments_Word:"Lütfen bekleyin... Ödemeler gönderiliyor.",
  No_Payment_Selected_PayPal:"Gönderilecek <a routerLink='/folder/Payments'>PayPal ödemesi</a> seçilmedi.",
  Payment_Sent:"Ödeme gönderildi",
  PayPal_Payment_Sent:"Bu ödeme PayPal ile gönderilmiştir.",
  Copay:"Copay",
  Dead:"Ölü",
  Alive:"Canlı",
  Not_Dead:"Ölmedi",
  Unclaimed:"sahipsiz",
  Attempted:"denendi",
  Deceased:"Merhum",
  Claimed:"talep edildi",
  Unpaid:"ödenmemiş",
  Sending_Payment:"Ödeme Gönderme",
  Sending_Payments:"Ödeme Gönderme",
  Send_PayPal_Confirmation:"Bu işlemi PayPal ile göndermek istiyor musunuz?",
  Send_PayPal_Confirmation_Word:"Bu işlemi göndermek için yeşil düğmeye basın.",
  Save_Payment_As_Unpaid:"Bu ödeme ödenmemiş olarak kaydedilsin mi?",
  Payment_Pay_Confirmation_PayPal:"Bu ödeme ödendi olarak kaydedilsin mi?",
  No_Policies_Word:"İlk <a routerLink='/folder/Postage/New'>Sigorta Poliçesini</a> şimdi ekleyin.",
  Timesheet_Multiple_Delete_Confirmation:"Birden çok zaman çizelgesini silmek istediğinizden emin misiniz?",
  Select_Multiple_Timesheets_Prompt:"Zaman çizelgesi seçilmedi. En az bir zaman çizelgesi seçin.",
  Select_Multiple_Policies_Prompt:"Politika seçilmedi. En az bir sigorta poliçesi seçin.",
  Policies_Multiple_Delete_Confirmation:"Birden çok ilkeyi silmek istediğinizden emin misiniz?",
  Company:"Şirket",
  Add_Company:"Şirket Ekle",
  New_Company:"Şirket Ekle",
  No_Companies:"Şirket Yok",
  Enable_Company:"Şirketi Etkinleştir",
  Select_Company:"Şirket Seçin",
  The_Default_Company:"Varsayılan şirket etiketi.",
  Manage_Companies:"Şirketleri Yönet",
  No_Companies_Word:"Kontroller varsayılan bir şirket kullanacak.<br /><a routerLink='/folder/Company/New'>ilk Şirketi</a> ekleyin.",
  Default_Company:"Varsayılan Şirket",
  Cheques_Unlimited_Word:"Checks Unlimited, istediğiniz kadar çek yazdırmanıza olanak tanır.",
  Cheques_Subscription_Word:"Çekler aboneliği, istediğiniz kadar çek yazdırmanıza olanak tanır.",
  You_Own_This_Product:"Bu ürünün sahibi sizsiniz.",
  Your_Subscription_is_Active:"Aboneliğiniz aktif.",
  Active_Products:"Aktif Ürünler",
  Purchase_Confirmation:"Satın almak",
  Purchase_Cheques:"Satın Alma Çekleri",
  Restore_Purchase:"Satın Alınanları Geri Yükle",
  Erase_Purchase:"Satın Alınanları Sil",
  Successfully_Purchased:"Başarıyla Satın Alındı",
  Enter_Your_Licence_Key:"Bu ürünü etkinleştirmek için lütfen bu sayfada lisans anahtarınızı girin.",
  Licence_Key:"Lisans anahtarı",
  Enter_Licence_Key:"Lisans kodunu giriniz",
  Purchased:"satın alındı",
  Enable_Feature:"Özelliği Etkinleştir",
  Cancel_Subscription:"Aboneliği iptal et",
  Subscription_Removed:"Abonelik Kaldırıldı",
  Select_Active_Subscription:"Değiştirmek için aktif bir abonelik seçin.",
  Remove_Subscription_Word:"Bu aboneliği iptal etmek istediğinizden emin misiniz?",
  Delete_Company_Confirmation:"Bu şirketin tamamını silmek istediğinizden emin misiniz? UYARI: Saklanan tüm bilgileri kaybedeceksiniz!",
  Delete_Default_Company_Word:"Varsayılan şirketi silemezsiniz. Uygulamayı sıfırlamak ve varsayılan durumuna geri yüklemek ister misiniz? UYARI: Saklanan tüm bilgileri kaybedeceksiniz!",
  Console_Warning_2:"Şu anda size ait olmayan bu uygulamayı kullanarak herhangi bir para birimini işlemeyin.",
  Terms_and_Conditions:"Şartlar ve koşullar",
  and_the:"ve",
  for:"için",
  Please_Read_Word:"Lütfen okuyun ve kabul edin",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Bazı para birimi dönüştürme oranları bulunamadı. Lütfen internete bağlanın.",
  Free:"Özgür",
  DB_Erase_Prompt_2:"Geliştirici veritabanının tamamı tamamen silinsin mi? UYARI: Tüm SATIN ALMA ve ABONELİK bilgilerini kaybedeceksiniz!",
  Successfully_Imported:"Başarıyla İçe Aktarıldı",
  Select_Postage:"Posta ücreti seçin",
  No_Postage:"posta pulu yok",
  No_Paid_Postage_Word:"İlk <a routerLink='/folder/Postage/New'>Ödenmiş Posta</a> damgasını ekleyin.",
  Trial_Complete:"Deneme TAMAMLANDI",
  Please_Upgrade:"Yazdırmaya devam etmek için lütfen Çekleri yükseltin.",
  Aa:"aa",
  Color:"Renk",
  Font:"Yazı tipi",
  Guide:"Rehber",
  Guides:"Kılavuzlar",
  Image:"resim",
  Zoom:"yakınlaştır",
  Logo:"Logo",
  Bank:"Banka",
  MICR:"MİKR",
  Total:"Toplam",
  Cancel:"İptal etmek",
  Confirm:"Onaylamak",
  Method:"Yöntem",
  Biometric_Security:"Biyometrik Güvenlik",
  Please_Login_To_Continue:"Devam etmek için lütfen giriş yapınız.",
  Complete:"Tamamlamak",
  Sign_Up:"Üye olmak",
  Error:"Hata",
  Biometrics:"biyometrikler",
  Percent:"Yüzde",
  Zero_Percent:"%0",
  Top_Margin:"Üst boşluk",
  Bottom_Margin:"Alt Kenar Boşluğu",
  Left_Margin:"Sol Kenar Boşluğu",
  Right_Margin:"Sağ Kenar Boşluğu",
  MICR_Margin:"MICR Marjı",
  Table_Margin:"Tablo Kenar Boşluğu",
  Address_Margin:"Adres Marjı",
  Percentage_:"Yüzde",
  Vacation_Title:"Tatil Başlığı",
  Use_PIN:"PIN kullan",
  Loading__:"Yükleniyor...",
  Design_Title:"Tasarım Başlığı",
  Authorize:"Yetki vermek",
  Key:"Anahtar",
  Public_Key:"Genel anahtar",
  Private_Key:"Özel anahtar",
  Authenticate:"Kimlik Doğrula",
  Last_Payroll:"Son Bordro",
  Last_Calculation:"Son Hesaplama",
  Authorized:"Yetkili",
  Geo_Authorized:"Coğrafi Konum: Yetkili",
  Not_Authorized:"Yetkili değil",
  Authorization_Complete:"Yetkilendirme Tamamlandı",
  Geolocation_Authorization:"Coğrafi Konum Yetkilendirmesi",
  Out_of_Bounds:"Sınır Dışı",
  Cant_Delete_Default_Design:"Varsayılan bir tasarım silinemez.",
  Unauthorized_Out_of_Bounds:"Yetkisiz: Sınır Dışı",
  Biometric_Authorization:"Biyometrik Yetkilendirme",
  Locating_Please_Wait:"Bulunuyor, Lütfen Bekleyin...",
  Test_Biometrics:"Biyo-metrikleri test edin",
  Cheque_Margins:"Kenar Boşluklarını Kontrol Et",
  Percentage_Full_Error:"Yüzde alanı yüzde 100'ün üzerine ayarlanamaz.",
  No_Payroll_Text:"Bir <a routerLink='/folder/Employee/New'>Çalışan</a> veya <a routerLink='/folder/Payee/New'>Alacaklı</a> ve bir <a routerLink='/folder/Schedule ekleyin /Yeni'>Program</a>.",
  Design_Saved:"Tasarım Kaydedildi",
  Default_Design_Selected:"Varsayılan Tasarım Seçildi",
  Partial_Import:"Kısmi İthalat",
  Partial_Export:"Kısmi İhracat",
  Entire_Import:"Tüm İthalat",
  Entire_Export:"Tüm İhracat",
  Existing_Password:"Lütfen mevcut şifrenizi giriniz:",
  Existing_PIN:"Lütfen mevcut PIN kodunuzu girin:",
  Pin_Change_Header:"PIN Onayı",
  Pin_Change_SubHeader:"Değişikliği onaylamak için eski PIN numaranızı girin.",
  Pass_Change_Header:"Şifre onayı",
  Pass_Change_SubHeader:"Değişikliği onaylamak için eski şifrenizi girin.",
  PIN_Enter_Message:"Onaylamak için PIN'inizi girin.",
  Password_Enter_Message:"Onaylamak için şifrenizi girin.",
  Pin_Updated_Success:"PIN başarıyla güncellendi!",
  Pin_Updated_Fail:"PIN güncellenemedi.",
  Pass_Updated_Success:"Şifre başarıyla güncellendi.",
  Pass_Updated_Fail:"Parola güncellenemedi.",
  Preview_Payment:"Önizleme Ödemesi",
  Preview_Payroll:"Bordro Önizle",
  No_Payments_Created:"Oluşturulacak ödeme bulunamadı.",
  Payment_Preview:"Ödeme Önizlemesi",
  Enable_Payee:"Alacaklıyı etkinleştir",
  Rendered:"işlenmiş",
  No_Email:"Email yok",
  Setup_Cheques:"Kurulum Kontrolleri",
  name:"İsim",
  address:"Adres",
  address_2:"Adres Satırı 2",
  city:"Şehir",
  province:"Vilayet",
  postal_code:"Posta/Posta Kodu",
  country:"Ülke",
  username:"Kullanıcı adı",
  full_name:"Ad Soyad",
  birthday:"Doğum günü",
  email:"E-posta",
  phone:"Telefon",
  employees:"Çalışanlar",
  addresses:"adresler",
  payment_amount_limit:"Ödeme Tutarı Limiti",
  total_limit:"Toplam Limit",
  payees:"alacaklılar",
  description:"Tanım",
  address_line_one:"Adres Satırı Bir",
  address_line_two:"Adres Satırı İki",
  image:"resim",
  account_holder:"Hesap sahibi",
  cheque_starting_id:"Başlangıç Kimliğini Kontrol Edin",
  transit_number:"Transit Numarası",
  institution_number:"Kurum Numarası",
  designation_number:"Atama Numarası",
  account_number:"Hesap numarası",
  currency:"Para birimi",
  signature:"İmza",
  payment_payroll_limit:"Ödeme Limiti",
  total_limi:"Toplam limit",
  date:"Tarih",
  printed_memo:"Basılı Not",
  banks:"Bankalar",
  signature_image:"İmza Resmi",
  address_name:"Adres adı",
  notes:"notlar",
  design:"Tasarım",
  title:"Başlık",
  frequency:"Sıklık",
  fax:"Faks",
  salaries:"maaşlar",
  salary_ids:"Maaş kimlikleri",
  start_time:"Başlangıç saati",
  end_time:"Bitiş zamanı",
  paid:"Paralı",
  overtime_percentage:"Ödenen Yüzde",
  overtime_amount:"Ödenen Sabit Tutar",
  first_name:"İlk adı",
  last_name:"Soy isim",
  moderation:"ılımlılık",
  create:"Yaratmak",
  edit:"Düzenlemek",
  destroy:"Tahrip etmek",
  day_start_time:"Day_start_time",
  day_end_time:"Day_end_time",
  fullname:"İsim",
  time:"Zaman",
  auto_send:"Otomatik gönder",
  time_method:"Zaman Yöntemi",
  schedules:"Programları",
  indefinite_payroll_enabled:"Süresiz Olarak Etkinleştir",
  amount:"Miktar",
  repeat:"Tekrarlamak",
  always_enabled:"Her Zaman Etkin",
  start_date:"Başlangıç tarihi",
  end_date:"Bitiş tarihi",
  Cheque_Total:"Toplamı Kontrol Et",
  Total_Amount:"Toplam tutar:",
  Amounts:"Tutarlar:",
  Images:"Görüntüler",
  Files:"Dosyalar",
  Previewing:"önizleme:",
  Insert:"Sokmak",
  Preview_Import:"Önizleme İçe Aktarma",
  Entry:"giriş",
  Entries:"Girdileri",
  No_Entries:"Giriş Yok",
  Import_Type:"İthalat Türü",
  Select_Details:"Ayrıntıları Seçin",
  Select_Payee:"Alacaklı Seç",
  Enable_Holidays:"Tatilleri Etkinleştir",
  Disable_Holidays:"Tatilleri Devre Dışı Bırak",
  Wire_Transfer:"Elektronik transfer",
  New_Export:"Yeni İhracat",
  Export_Data:"Verileri Dışa Aktar",
  Export_Data_Word:"Dışa aktarılacak ve indirilecek dosya türünü seçin.",
  Export_File:"Aktarma dosyası",
  Mode:"mod",
  Times:"Zamanlar",
  Widgets:"Widget'lar",
  Slider:"kaydırıcı",
  Set_Details:"Ayrıntıları Ayarla",
  Select_Type:"Tür Seçin",
  Display_Slider:"Kaydırıcıyı Görüntüle",
  Dashboard_Slider:"Pano Kaydırıcısı",
  Dashboard_Mode:"Pano Modu",
  Show_Intro:"Tanıtımı Göster",
  Show_Payrolls:"Bordroları Göster",
  Show_Holidays:"Tatilleri Göster",
  Show_Invoices:"Faturaları Göster",
  Show_Cheques:"Kontrolleri Göster",
  Enabled_Widgets:"Etkin Widget'lar",
  Disabled_Widgets:"Devre Dışı Widget'lar",
  Colors:"Renkler",
  Barcodes:"Barkodlar",
  View_Timers:"Zamanlayıcıları Görüntüle",
  Gradients:"degradeler",
  No_Info:"Bilgi yok",
  Disable:"Devre dışı bırakmak",
  Show_Layer:"Katmanları Göster",
  Hide_Layer:"Katmanları Gizle",
  Text_Layer:"Metin Katmanları",
  Secondly:"ikincisi",
  Minutely:"Dakikada",
  nine_am:"09:00",
  five_pm:"17:00",
  Enable_Address:"Adresi Etkinleştir",
  Invalid_File_Type:"Üzgünüz, geçersiz bir dosya türü seçildi. Desteklenen dosya türü:",
  Error_Updating_Entry:"Üzgünüz, bu giriş güncellenirken bir hata oluştu.",
  Schedule_Timing_Alert:"Hata: Program başlangıç zamanı, bitiş zamanından sonra bir değere ayarlandı.",
  Select_Multiple_Payments_Prompt:"Seçili ödeme yok. En az bir ödeme seçin.",
  Select_Multiple_Cheques_Prompt:"Kontrol seçilmedi. Lütfen en az bir çek seçin.",
  Select_Multiple_Items_Prompt:"Seçili öğe yok. Lütfen en az bir öğe seçin.",
  Paymemt_Multiple_Delete_Confirmation:"Birden çok ödemeyi silmek istediğinizden emin misiniz?",
  Cheque_Multiple_Delete_Confirmation:"Birden çok çek silmek istediğinizden emin misiniz?",
  Payee_Multiple_Delete_Confirmation:"Birden çok alacaklıyı silmek istediğinizden emin misiniz?",
  Employee_Multiple_Delete_Confirmation:"Birden çok çalışanı silmek istediğinizden emin misiniz?",
  MICR_Warning:"Not: Bazı yazıcılar ve aygıtlar MICR yazı tipini doğru göstermeyebilir.",
  Automatically_Send:"Otomatik gönder",
  Type:"Tip",
  Payment_Type:"Ödeme türü",
  Auto_Send:"Otomatik Gönder",
  Automatically_Process:"Otomatik Olarak İşle",
  Auto_Process:"Otomatik İşlem",
  Image_Based:"Görüntü tabanlı",
  Font_Based:"Yazı tipi tabanlı",
  Rerender:"yeniden oluştur",
  Rendering:"oluşturma",
  Render:"Dosyalar",
  Top:"Tepe",
  Middle:"Orta",
  Bottom:"Alt",
  Top_Left:"Sol üst",
  Top_Center:"Üst merkez",
  Top_Right:"Sağ üst",
  Middle_Left:"Orta Sol",
  Middle_Center:"Orta Merkez",
  Middle_Right:"Orta Sağ",
  Bottom_Left:"Sol alt",
  Bottom_Center:"Alt merkez",
  Bottom_Right:"Sağ alt",
  Numbers:"Sayılar",
  Verified:"doğrulandı",
  Paper_Size:"Kağıt boyutu",
  New_Device:"Yeni cihaz",
  Add_Device:"Cihaz ekle",
  Remove_Device:"Aygıtı kaldır",
  Delete_Device:"Cihazı Sil",
  Block_Device:"Cihazı Engelle",
  Block:"Engellemek",
  Unblock:"engeli kaldırmak",
  Table:"Masa",
  Scan_Login_Code:"Giriş Kodunu Tara",
  Login_Code:"Giriş Kodu",
  Scan_Code:"Tarama Kodu",
  Scan_QR_Code:"QR Kodunu Tara",
  Select_All:"Hepsini seç",
  Deselect_All:"Hiçbirini seçme",
  Increase:"Arttırmak",
  Decrease:"Azaltmak",
  Bold:"Gözü pek",
  Text:"Metin",
  Style:"stil",
  Italic:"İtalik",
  QR_Code:"QR kod",
  Barcode:"barkod",
  Browse_Images:"Görsellere Gözat",
  Browse_Files:"Dosyalara Gözat",
  Background_Image:"Arka plan görüntüsü",
  Logo_Image:"Logo resmi",
  Header_Image:"Başlık görüntü",
  Bank_Image:"Banka Resmi",
  Cut_Lines:"Kesik Çizgiler",
  Background:"Arka plan",
  License:"Lisans",
  One_License:"1 Lisans:",
  Licensed:"Lisanslı:",
  Not_Licensed:"Lisanslı değil",
  Enter_Serial:"Seri girin",
  Serial_Key:"Seri anahtarı",
  Serial:"Seri",
  Product_Key:"Ürün anahtarı",
  Check_Product_Key:"Ürün Anahtarını Doğrulayın",
  Add_Product_Key:"Ürün anahtarını girin",
  Verifying_Purchase:"Satın Alma Doğrulanıyor...",
  Print_Envelope:"Zarf Yazdır",
  Envelope:"Mektup",
  Thank_You:"Teşekkür ederim!",
  Scale:"Ölçek",
  Print_Scale:"Baskı Ölçeği",
  Borders:"Kenarlıklar",
  VOID:"GEÇERSİZ",
  Void_Cheque:"Geçersiz Kontrol",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"EMRİNE ÖDEYİNİZ:",
  NO_DOLLARS:"DOLAR YOK",
  ONE_DOLLAR:"BİR DOLAR",
  DOLLARS:"DOLAR",
  AND:"VE",
  CENTS:" SENTLER.",
  NO_:"HAYIR",
  ONE_:"BİR",
  AND_NO_:"VE HAYIR",
  _AND_ONE_:"VE BİR",
  DOLLARS_AND_ONE_CENT:"VE BİR SENT.",
  AND_NO_CENTS:" VE SIFIR SENT.",
  CHEQUE_PRINT_DATE:"TARİH:",
  CHEQUE_PRINT_MEMO:"HAFIZA:",
  CHEQUE_PRINT_SIGNATUREMP:"Milletvekili",
  Initial_Tasks:"İlk Görevler",
  Inital_Setup:"İlk kurulum",
  Welcome_To:"Hoşgeldiniz",
  Welcome:"Hoş geldin",
  Swipe:"Tokatlamak",
  Intro_Setup:"Giriş Kurulumu",
  Introduction:"giriiş",
  CHEQUE_PRINT_CREDIT:"Kontroller tarafından desteklenmektedir",
  Title_Intro_Word:"Checks'e hoş geldiniz",
  Title_Intro:"Çekler Giriş",
  Title_Setup:"Kontrol Kurulumu",
  PayPal_Default_Email_Message:"Yeni bir PayPal havalesi aldınız.",
  Cheques_App_Export:"Çeklerle İhracat",
  No_Prints_Purchased:"Baskı Satın Alınmadı",
  No_Prints_Purchased_Word:"Ek baskı mevcut değildir. Lütfen ek çek satın alın.",
  Post_Intro_Thanks:"Çekleri seçtiğiniz için teşekkür ederiz. Kurulum işlemi şimdi tamamlandı.",
  Post_Intro_Word:"İlk çekinizi yazdırarak başlayın, gelecekteki bir ödeme ekleyin veya bordroya bir çalışan ekleyin.",
  Upgrade_Required:"Checks, bu mesajı gizlemek ve ek özelliklerin kilidini açmak için yazılımın daha premium bir sürümüne sahip olmanızı gerektirir.",
  Upgrade_Title:"çekler",
  Mailout_Prompt:"Ek olarak, maaş bordrosu çeklerinizin sizin için Çekler tarafından postalanmasını da seçebilirsiniz.",
  Mailed_Cheque:"Posta Çeki",
  Mailed_Cheque_Color:"Posta Çeki (Renkli)",
  Terms_Purchase:"Çeklerle yapılan tüm elektronik satın alımlar, satın alma tarihinden itibaren 30 güne kadar tamamen iade edilebilir. Lütfen okuyun ve kabul edin",
  Dashboard_Setup:"Birincil Yazıcıyı Kurun",
  Dashboard_Add:"Birincil Banka Hesabını Ekle",
  Dashboard_Customize:"Bir Kontrol Şablonu Seçin",
  Dashboard_Job_Salary:"İşinizi Oluşturun ve Maaşınızı Ekleyin",
  Dashboard_Employees:"Çalışanları ve Alacaklıları Takip Edin",
  Dashboard_Print:"Maaş Çeklerinizi Yazdırın ve Postalayın",
  Dashboard_Payroll:"Bordro Yazdırmanızı Otomatikleştirin",
  Dashboard_PayPal:"PayPal Bordrosu / Ödemelerini Ayarlayın",
  Begin_Setup:"Kuruluma Başlayın",
  Get_Started:"Başlamak",
  Purchase:"Satın almak",
  Lockdown:"Karantina",
  Unlock:"Kilidini aç",
  Detailed:"Detaylı",
  Log_In:"Giriş yapmak",
  Login:"Giriş yapmak",
  Launch:"Öğle yemeği",
  Register:"Kayıt olmak",
  Finish:"Sona ermek",
  List:"Liste",
  Weekends:"Hafta sonları",
  Weekly:"Haftalık",
  PayPal_Default_Subject:"Yeni Ödeme",
  Payment_Message:"Ödeme Mesajı",
  PayPal_Default_Payment_Note:"Teşekkür ederim",
  Setup_Your_Cheqing_Account:"çek hesabı",
  Add_Your_Primary_Cheqing_Account:"Birincil çek hesabınızı ekleyin.",
  Welcome_Word:"Bordro ve insan kaynakları yönetimini basitleştirin ve otomatikleştirin.",
  Get_Started_Quickly:"Başlamamıza yardımcı olacak birkaç basit soruyu yanıtlamanız yeterli...",
  Done_Intro_Word:"Kurulum tamamlandı",
  PIN_Protected:"Parola ve PIN Korumalı",
  Enter_New_PIN:"Yeni bir PIN Kodu girin:",
  Enter_PIN:"PIN Kodunu girin:",
  Invalid_PIN:"Geçersiz PIN girildi.",
  Account_Identifier:"Hesap Tanımlayıcı",
  New_Account_Identifier:"Yeni Hesap Tanımlayıcı",
  attempt:"girişim",
  attempts:"denemeler",
  remaining:"geriye kalan",
  Language:"Dil",
  languages:"Diller",
  select_languages:"Dil Seçin",
  Deposit:"Depozito",
  Hire_One_Now:"Şimdi Birini İşe Alın",
  App_Locked:"Uygulama kilitli.",
  Skip_:"Atlamak",
  Skip_to_Dashboard:"Kontrol Paneline Geç",
  Dashboard:"Gösterge Paneli",
  Import:"İçe aktarmak",
  Admin:"Yöneticiler",
  New_Admin:"Yeni Yönetici",
  Settings:"Ayarlar",
  Color_Picker:"Renk seçici",
  Font_Picker:"Yazı Tipi Seçici",
  Logout:"Çıkış Yap",
  Close:"Kapalı",
  Close_menu:"Kapalı",
  Excel:"Excel Dosyası",
  Csv:"CSV Dosyası",
  Sql:"SQL Dosyası",
  Json:"JSON Dosyası",
  Url:"URL ile içe aktar",
  Back:"Geri",
  Timers:"Zamanlayıcılar",
  Cheque:"Kontrol etmek",
  Print:"Yazdır",
  Designs:"Tasarımlar",
  Pause_Printing:"Yazdırmayı Duraklat",
  Resume_Printing:"Yazdırmaya Devam Et",
  Printing_Paused:"Yazdırma Duraklatıldı",
  PrintingUnavailable:"Üzgünüm! Bu sistemde yazdırma yapılamaz.",
  Prints_Paused:"Baskılar Duraklatıldı",
  Administration:"Yönetim",
  Loading:"Yükleniyor",
  Unnamed:"isimsiz",
  error:"Üzgünüz, bu çek görüntülenmek üzere açılamadı.",
  No_Cheques_To_Print:"Yazdırılacak Çek Yok",
  No_Cheques_To_Print_Word:"Bir <a routerLink='/folder/Cheque/New'>Yeni Çek</a> oluşturun.",
  New_Cheque:"Yeni Çek",
  Start_One_Now:"Şimdi Birini Başlatın",
  Edit_Cheque:"Kontrolü Düzenle",
  Select_Cheques:"Çekleri Seçin",
  Select_Employee:"Çalışan Seç",
  Default_Printer:"Varsayılan yazıcı",
  Reassign:"yeniden atama",
  Configure:"Yapılandır",
  Template:"Şablon",
  Designer:"tasarımcı",
  Edit_Designs:"Tasarımları Düzenle",
  New_Design:"Yeni tasarım",
  Next:"Sonraki",
  Save:"Kaydetmek",
  Name:"İsim",
  Mail:"Posta",
  Amount:"Miktar",
  Date:"Tarih",
  PayPal:"paypal",
  Payouts:"ödemeler",
  PayPal_Label:"PayPal Etiketi",
  Email_Username:"E-posta / Kullanıcı adı",
  Client_ID:"Müşteri Kimliği",
  Sandbox_Email:"Korumalı Alan E-postası",
  PayPal_Email:"PayPal E-postası",
  PayPal_Username:"API Kullanıcı Adı",
  PayPal_Payouts:"PayPal Ödemeleri",
  Select_PayPal_Key:"PayPal Anahtarını Seçin",
  Secret:"Gizli",
  API_Secret:"API Sırrı",
  PayPal_API_Keys:"PayPal Anahtarları",
  New_PayPal_Key:"Yeni PayPal Anahtarı",
  Email_Subject:"E-posta konu",
  Email_Message:"E-posta mesajı",
  Payout_Options:"Ödeme Seçenekleri",
  Payment_Note:"Ödeme Notu",
  Enable_Employee:"Çalışanı Etkinleştir",
  Enable_Production_Mode:"Üretim Modunu Etkinleştir",
  Sandbox_Username:"Sandbox Kullanıcı Adı",
  Sandbox_Signature:"Sandbox İmzası",
  Sandbox_Password:"Korumalı Alan Parolası",
  Production_Username:"Üretim Kullanıcı Adı",
  Production_Signature:"Üretim İmzası",
  Production_Password:"Üretim Şifresi",
  Production_Email:"Üretim E-postası",
  API_Client_ID:"API Müşteri Kimliği",
  API_Signature:"API İmzası",
  API_Password:"API Şifresi",
  API_Username:"API Kullanıcı Adı",
  Secret_Key:"Gizli anahtar",
  Sandbox:"Kum havuzu",
  Production:"Üretme",
  Sandbox_Keys:"Korumalı Alan Anahtarları",
  Production_Keys:"Üretim Anahtarları",
  API_Title:"API Başlığı",
  Production_Mode:"Üretim Modu",
  Account_Label:"Hesap Etiketi",
  No_PayPal_Setup:"PayPal Anahtarı Yok",
  Enable_PayPal_Account:"PayPal Hesabını Etkinleştir",
  No_PayPal_Word:"<a routerLink='/folder/Invoice/New'>PayPal API Anahtarınızı</a> ekleyin.",
  Printed_Memo:"Basılı Not",
  Employee:"Çalışan",
  View_Employee:"Çalışanı Görüntüle",
  Mailing_Address:"Posta adresi",
  Company_Address:"şirket adresi",
  Select_Company_Address:"Şirket Adresi Seçiniz",
  Address:"Adres",
  Any_Day:"Herhangi bir gün",
  Address_Name:"Adres adı",
  Unit:"Birim",
  Account:"Hesap",
  Bank_Account:"Banka hesabı",
  Account_Limits:"Hesap Limitlerini Etkinleştir",
  Payroll:"Maaş bordrosu",
  Run:"Koşmak",
  Run_Payroll:"Bordro Çalıştır",
  New_Payroll:"Yeni Bordro",
  No_Payroll:"Yaklaşan Bordro Yok",
  Upcoming_Holiday:"Yaklaşan Tatil:",
  Invoice_Due:"Fatura Vadesi:",
  New_Invoice:"Yeni fatura",
  No_Invoices:"fatura yok",
  No_Invoices_Word:"İlk <a routerLink='/folder/Invoice/New'>Fatura</a>'yı oluşturun.",
  Cheque_Due:"Kontrol Vadesi:",
  Payrolls:"bordrolar",
  Sandbox_Mode:"Sandbox Modu",
  Hire:"Kiralamak",
  Pay:"Ödemek",
  New:"Yeni",
  Add:"Eklemek",
  Make:"Yapmak",
  Time:"Zaman",
  Write:"Yazmak",
  Holiday:"Tatil",
  Holidays:"Bayram",
  Next_Holiday:"Sonraki tatil:",
  All_Done:"Hepsi tamam!",
  Employees:"Çalışanlar",
  Payees:"alacaklılar",
  Job:"İş",
  Jobs:"Meslekler",
  Invoice:"Fatura",
  Invoices:"faturalar",
  Vacations:"tatiller",
  Cheques:"çekler",
  Brand_Cheques:"Senin markan",
  Payment:"Ödeme",
  Enable_Payment:"Ödemeyi Etkinleştir",
  Payments:"ödemeler",
  Schedule:"Takvim",
  Schedules:"Programları",
  Timesheet:"Zaman planı",
  Timesheets:"Zaman Çizelgeleri",
  Salary:"Maaş",
  New_Address:"Yeni adres",
  Address_2:"Adres Satırı 2)",
  _City:"Şehir",
  _State:"Eyalet/İl",
  City:"Şehir / İlçe",
  State:"Eyalet / İl",
  Postal:"Posta / Posta Kodu",
  ZIP:"Posta / ZIP",
  Country:"Ülke",
  Addresses:"adresler",
  Required_Options:"Gerekli Seçenekler",
  Optional_Options:"Opsiyonel Seçenekler",
  Additional_Options:"Ekstra seçenekler",
  Required:"Gerekli",
  Optional:"İsteğe bağlı",
  Additional:"Ek olarak",
  No_Addresses:"Adres Yok",
  New_Address_Word:"İlk <a routerLink='/folder/Address/New'>Adres</a>'i ekleyin.",
  Select_Address:"Adres Seç",
  No_Address:"Adres Yok",
  Print_Cheque:"Çek Yazdır",
  Print_Cheques:"Çekleri Yazdır",
  Print_Cheque_Now:"Yazdır Şimdi Kontrol Et",
  Description:"Tanım",
  Pay_To_The_Order_Of:"Emrine ödeyiniz:",
  Select_Date_Range:"Tarih Aralığı Seçin",
  Select_Starting_Date:"Başlangıç Tarihini Seçin",
  Select_Ending_Date:"Bitiş Tarihini Seçin",
  Select_Date:"Tarih seç",
  Cheque_Date:"Kontrol Tarihi",
  Cheque_Memo:"Notu Kontrol Et",
  Blank_Cheque:"Boş onay",
  Blank:"Boşluk",
  No_Cheques:"Kontrol Yok",
  No_Cheques_Word:"İlk <a routerLink='/folder/Cheque/New'>Çek</a>'inizi yazdırın.",
  Cheque_Amount_Placeholder:"0,00 dolar",
  View_Image:"Görseli göster",
  View:"Görüş",
  Modify:"Değiştir",
  Delete:"Silmek",
  Cheque_Paid:"Paralı",
  New_Deduction:"Yeni Kesinti",
  Title:"Başlık",
  Frequency:"Sıklık",
  Hourly:"Saatlik",
  Daily:"Günlük",
  Weekdays:"Hafta içi",
  BiWeekly:"2 hafta",
  TriWeekly:"3 hafta",
  Monthly:"Aylık",
  MiMonthly:"2 ay",
  Quarterly:"Üç ayda bir",
  Yearly:"Yıllık",
  Every_Week:"Her hafta",
  Every_Payroll:"Her Bordro",
  Every_Month:"Her ay",
  Annually:"yıllık",
  Always_Scheduled:"Her Zaman Planlanmış",
  Start_Date:"Başlangıç tarihi",
  End_Date:"Bitiş tarihi",
  Start_Time:"Başlangıç saati",
  End_Time:"Bitiş zamanı",
  Deduction_Label:"Kesinti Etiketi",
  Notes:"notlar",
  Options:"Seçenekler",
  Enable:"Olanak vermek",
  Select_Deductions:"Kesintileri Seçin",
  Deductions:"Kesintiler",
  No_Deductions:"Kesinti Yok",
  No_Deductions_Word:"İlk <a routerLink='/folder/Deduction/New'>Kesinti</a>'nizi oluşturun.",
  New_Employee:"Yeni çalışan",
  No_Title:"Başlık yok",
  _Name:"İsim",
  About_Yourself:"Senin hakkında",
  Full_Name:"Ad Soyad",
  Birthday:"Doğum günü",
  Email:"E-posta",
  SMS:"SMS",
  Phone:"Telefon",
  Test:"Ölçek",
  Call:"Arama",
  Fax:"Faks",
  Printed_Note:"Basılı Not",
  Position:"Konum",
  Job_Position:"İş pozisyonu",
  Select_a_Job:"Bir İş Seçin",
  Select_a_Salary:"Bir Maaş Seçin",
  Add_a_Deduction:"Kesinti Ekle",
  Taxes:"vergiler",
  Add_Taxes:"Vergi Ekle",
  Date_of_Birth:"Doğum tarihi",
  Email_Address:"E-posta Adresi",
  Phone_Number:"Telefon numarası",
  Phone_Call:"Telefon görüşmesi",
  Enable_on_Payroll:"Bordroda Etkinleştir",
  Select_Employees:"Çalışan Seç",
  No_Employees:"Çalışan Yok",
  No_Employees_Word:"İlk yeni <a routerLink='/folder/Employee/New'>Çalışanınızı</a> ekleyin.",
  No_Name:"İsimsiz",
  Unemployeed:"İşsiz",
  Employeed:"çalışan",
  Paid:"Paralı",
  Enabled:"Etkinleştirilmiş",
  Disabled:"Engelli",
  Fire:"Ateş",
  Not_Available:"Müsait değil",
  Not_Available_Word:"İlk <a routerLink='/folder/Invoice/New'>Faturanızı</a> yazdırın ve ödeme alın.",
  Select_Invoices:"Faturaları Seçin",
  Print_Invoice_Word:"İlk <a routerLink='/folder/Invoice/New'>Faturanızı</a> yazdırın ve ödeme alın.",
  Invoice_Title:"Fatura Başlığı",
  Invoice_Date:"Fatura tarihi",
  Due_Date:"Bitiş tarihi",
  New_Job:"Yeni iş",
  Details:"Detaylar",
  Customize:"Özelleştirmek",
  Customize_Dashboard:"Kontrol Panelini Özelleştir",
  Components:"Bileşenler",
  No_Components:"Bileşen Yok",
  Main_Components:"Ana bileşenler",
  Smaller_Components:"Daha Küçük Bileşenler",
  Error_Loading_Page:"Bu sayfa yüklenirken hata oluştu.",
  Bank_Details:"Banka detayları",
  About_Your_Job:"İşiniz Hakkında",
  Your_Schedule:"Programınız",
  Job_Title:"İş unvanı",
  Job_Description:"İş tanımı",
  Job_Details:"İş Detayları",
  Enable_Job:"İşi Etkinleştir",
  Pay_Period:"Ödeme periyodu",
  Perpetually_Schedule:"Sürekli Planla",
  Select_Jobs:"İş Seç",
  No_Jobs:"İş yok",
  Add_Jobs:"İş Ekle",
  No_Jobs_Word:"İlk <a routerLink='/folder/Job/New'>İş</a>'i listeye ekleyin.",
  Count_Employees:"job.employee_count+' Çalışanlar'",
  Zero_Employees:"0 çalışan",
  New_Leave:"yeni izin",
  Leave_Name:"İsim bırakın",
  Paid_Leave:"Ücretli izin",
  Leave_Pay:"Ödeme bırakın",
  Indefinite_Leave:"Süresiz İzin",
  Indefinite_Payroll:"Süresiz Bordro",
  Leave:"Ayrılmak",
  Add_Schedules:"Program Ekle",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Devamsızlığı Etkinleştir",
  Select_Leaves:"Yaprakları Seçin",
  No_Leaves:"Devamsızlık İzni Yok",
  Leave_Of_Absence:"Devamsızlık İzni",
  Leaves_Of_Absence:"Yokluk yaprakları",
  New_Leave_of_Absense:"Yeni İzin",
  No_Leaves_Word:"İlk <a routerLink='/folder/Leave/New'>Devamsızlık İzni</a>'ı ekleyin.",
  Not_Enabled:"Etkin değil",
  Absences:"devamsızlık",
  Payee:"Alacaklı",
  New_Payee:"Yeni Alacaklı",
  Payee_Identifier:"Alacaklı Tanımlayıcı",
  Payee_Name:"Alıcı adı",
  Payee_Title:"Alacaklı Unvanı",
  Payment_Memo:"Ödeme Dekontu",
  Select_Payees:"Alacaklıları Seçin",
  No_Payees:"Alacaklı Yok",
  Add_Payee_Note:"İlk <a routerLink='/folder/Payee/New'>Alacaklı</a>'yı ekleyin.",
  New_Payees:"Yeni Alacaklılar",
  About_The_Payment_Schedule:"Ödeme PLANI",
  Your_Payroll_Schedule:"Otomatik Bordro",
  New_Payment:"Yeni Ödeme",
  Identifier:"tanımlayıcı",
  Selected:"Seçilmiş",
  Select:"Seçme",
  Memo:"Hafıza",
  Payment_Date:"Ödeme tarihi",
  Mark_as_Paid:"Ödendi Olarak İşaretle",
  Select_Payments:"Ödemeleri Seçin",
  No_Payments:"Ödeme Yok",
  No_Payments_Word:"İlk <a routerLink='/folder/Payment/New'>Ödeme</a>'yi oluşturun.",
  Create_Payroll:"Bordro Oluştur",
  Properties:"Özellikler",
  Payroll_Title:"Bordro Başlığı",
  Payroll_Notes:"Bordro Notları",
  Payroll_Setup:"Bordro Kurulumu",
  Tabulate_Payments:"Ödemeleri Çizelgele",
  Tabulate:"tablolaştır",
  By:"İle:",
  Payments_By:"Ödemeler",
  Timesheets_And_Schedules:"Zaman Çizelgeleri ve Programlar",
  Both:"İkisi birden",
  Items:"Öğeler",
  Add_Payees:"Alacaklı Ekle",
  Add_Account:"Hesap eklemek",
  Enable_Account:"Hesabı Etkinleştir",
  Enable_Payroll:"Bordroyu Etkinleştir",
  Print_Payroll:"Bordro Yazdır",
  No_Payrolls:"Bordro Yok",
  No_Payroll_Word:"İlk <a routerLink='/folder/Payroll/New'>Maaş bordrosu</a>'unuzu oluşturun.",
  View_more:"DAHA FAZLA GÖSTER",
  Less:"AZ",
  Add_Payroll:"Bordro Ekle",
  Select_Payroll:"Bordro Seç",
  About_Your_Salary:"Maaşınız Hakkında",
  Add_Salaries:"Maaş Ekle",
  Add_Salary:"Maaş Ekle",
  Salaries:"maaşlar",
  No_Salaries:"maaş yok",
  No_Salaries_Word:"İlk <a routerLink='/folder/Salary/New'>Maaş</a>'ı ekleyin.",
  Select_Salaries:"Maaş Seç",
  New_Salary:"Yeni Maaş",
  Salary_Name:"Maaş Tanımlayıcı",
  Enable_Salary:"Maaşı Etkinleştir",
  Salary_Amount:"Maaş Tutarı",
  New_Schedule:"Yeni program",
  Schedule_Title:"Program Başlığı",
  Add_Address:"Adres Ekle",
  Repeat:"Tekrarlamak",
  Design:"Tasarım",
  Edit_Design:"Tasarımı Düzenle",
  Edit_this_Design:"Bu Tasarımı Düzenle",
  Repeat_Payment:"Tekrar Ödeme",
  Enable_Schedule:"Zamanlamayı Etkinleştir",
  Never:"Asla",
  Select_Schedule:"Programları Seçin",
  No_Schedules:"Program Yok",
  No_Schedules_Word:"İlk <a routerLink='/folder/Schedule/New'>Programı</a> oluşturun.",
  New_Administrator:"Yeni Yönetici",
  Username:"Kullanıcı adı",
  First_Name:"İlk adı",
  Last_Name:"Soy isim",
  Add_an_Address:"Adres Ekle",
  Payment_Limit:"Ödeme Başına Limit",
  Total_Limit:"Toplam Limit",
  Select_Accounts:"Hesapları Seçin",
  No_Administrators:"Yönetici Yok",
  No_Administrators_Word:"İlk <a routerLink='/folder/Administrator/New'>Yönetici Hesabı</a>'nı oluşturun.",
  New_Administrators_Word:"İlk <a routerLink='/folder/Administrator/New'>Yönetici</a>'yi ekleyin",
  Cloud:"Bulut",
  Backup:"Destek olmak",
  Enable_iCloud:"iCloud'u etkinleştir",
  Enable_Google_Drive:"Google Drive'ı etkinleştir",
  Enable_Microsoft_OneDrive:"Microsoft OneDrive'ı etkinleştirin",
  Automatically_Backup:"Otomatik Yedekleme",
  FTP_Settings:"FTP Ayarları",
  URL_File_Prompt:"Lütfen içe aktarılacak bir .xls / .xlsx / .json dosyasının konumunu belirtin:",
  URL_SQL_Prompt:"Lütfen içe aktarılacak SQLite dosyasının konumunu belirtin:",
  FTP_Backup:"FTP Yedekleme",
  FTP_Import:"FTP İçe Aktarma",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Ev sahibi",
  Port:"Liman",
  Path:"Yol",
  Data_Path:"Veri yolu",
  Enable_FTP_Account:"FTP Hesabını Etkinleştir",
  HostnameIP:"Ana bilgisayar adı",
  Password:"Şifre",
  Connection_Port:"Bağlantı Noktası",
  Enable_MySQL_Database:"MySQL Veritabanını Etkinleştir",
  Log:"Kayıt",
  Reset:"Sıfırla",
  Erase:"Sil",
  Export:"İhracat",
  Database:"Veri tabanı",
  Upload_CSV:"CSV Yükle",
  Download_CSV:"CSV'yi indir",
  SQL_Database:"SQL URL'si",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL Yedekleme",
  Internal_Notes:"Dahili Notlar",
  Root_Path:"Kök Yolu",
  Select_Backup:"Yedekleme Seç",
  Add_New_Backup:"Yeni Bir Yedek Ekle",
  First_Backup:"İlk yedeklemeyi kurun...",
  Backups:"Yedekler",
  Add_Backup:"Yedek Ekle",
  No_Backups:"Bulunacak yedek yok.",
  Select_Backup_Type:"Kurmak istediğiniz yedekleme türünü seçin...",
  Backup_Type:"Yedekleme Türü",
  FTP_Drive:"FTP Sürücüsü",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Sürmek",
  Microsoft_OneDrive:"Sürmek",
  Import_Fields:"Alanları İçe Aktar",
  Import_Fields_Word:"Verileri <a routerLink='/folder/Holiday/New'>İçe Aktar</a>'ı seçmek için bu bölümü kullanın.",
  Upload:"Yüklemek",
  Download:"İndirmek",
  Download_JSON:"JSON Verisi olarak indir",
  Download_SQL:"SQL Dosyası Olarak İndir",
  New_Bank:"Yeni Banka",
  New_Account:"Yeni hesap",
  New_Bank_Account:"Yeni Banka Hesabı",
  Upload_Image:"Fotoğraf yükleniyor",
  Bank_Name:"Banka adı",
  Bank_Address:"Banka adresi",
  Branch_Address:"Şube adresi",
  Address_on_Cheque:"Adres",
  Cheque_Numbers:"Kontrol Numaraları",
  Cheque_Details:"Ayrıntıları Kontrol Et",
  Bank_Logo:"banka logosu",
  Cheque_ID:"Kimliği kontrol et",
  Starting_Cheque_ID:"Kontrol Kimliği Başlatılıyor",
  Transit_Number:"Transit Numarası",
  Institution_Number:"Kurum Numarası",
  Designation_Number:"Atama Numarası",
  Account_Number:"Hesap numarası",
  Limits:"Limitler",
  Default_Limits:"Varsayılan Limitler",
  Over_Limit:"Sınırın üzerinde",
  Under_Limit:"Limitin Altında",
  Payroll_Limit:"Bordro Limiti",
  Enable_Bank_Account:"Banka Hesabını Etkinleştir",
  Select_Account:"Hesap Seç",
  No_Bank_Account:"Banka Hesabı Yok",
  No_Bank_Account_Word:"İlk <a routerLink='/folder/Accounts/New'>Banka Hesabı</a>'nı ekleyin.",
  Bank_Accounts:"Banka hesabı",
  No_Accounts:"Hesap Yok",
  No_Accounts_Note:"İlk <a routerLink='/folder/Accounts/New'>Banka Hesabı</a>'nı ekleyin.",
  Cheque_Designer:"Kontrol Tasarımcısı",
  Cheque_Design:"Tasarımı Kontrol Et",
  Select_Design:"Bir Tasarım Seçin",
  Cheque_Designs:"Kontrol Tasarımları",
  No_Cheque_Designs:"Çek Tasarımı Yok",
  No_Cheque_Designs_Word:"Kendi <a routerLink='/folder/Settings/Cheque/Design/New'>Çek Tasarımınızı</a> oluşturun.",
  Set_Default:"Varsayılan olarak ayarla",
  Default:"Varsayılan",
  Remove:"Kaldırmak",
  Folder:"Dosya",
  Edit:"Düzenlemek",
  LoadingDots:"Yükleniyor...",
  Add_a_New_File:"Şuraya bir <a href='#' (click)='add()'>Yeni Dosya</a> ekleyin",
  this_folder:"bu klasör",
  FTP_Backup_Settings:"FTP Yedekleme Ayarları",
  Secure_File_Transfer:"Güvenli Dosya Aktarımı",
  New_Holiday:"Yeni Tatil",
  Add_Holiday:"Tatil Ekle",
  Holiday_Name:"tatil adı",
  Additional_Pay:"Ek Ödeme",
  Enable_Holiday:"Tatili Etkinleştir",
  Select_Holidays:"Tatilleri Seçin",
  No_Holidays:"tatil yok",
  No_Holidays_Word:"İlk <a routerLink='/folder/Holiday/New'>Resmi Tatil</a>'i ekleyin.",
  New_Import:"Yeni İthalat",
  Import_Data:"Verileri İçe Aktar",
  Import_Data_Word:"Seçtiğiniz dosya türünü veya yükleme yöntemini seçin.<br /> Desteklenen bir dosyayı yükledikten sonra, bir dosyadaki içe aktarılan alanların uygulamadaki hangi parametreye karşılık geldiğini seçebileceksiniz.",
  Import_File:"Önemli dosya",
  Link_To_File:"Dosyaya Bağlantı",
  Select_File:"Dosya Seç",
  New_Moderator:"Yeni Moderatör",
  Allow_Moderation:"Denetime İzin Ver",
  Create_Paycheques:"Maaş Çekleri Oluşturun",
  Edit_Paycheques_and_Data:"Maaş Çeklerini ve Verileri Düzenle",
  Destroy_Data_and_Paycheques:"Verileri ve Maaş Çeklerini Yok Etme",
  Bonus_Percentage:"Maaş Yüzdesi",
  Fixed_Amount:"Sabit miktar",
  Select_Moderator:"Moderatör Seçin",
  No_Moderators:"Moderatör Yok",
  Moderators:"moderatörler",
  Moderator_Account:"İlk <a routerLink='/folder/Administrator/New'>Moderatör Hesabı</a>'nı oluşturun.",
  No_Moderators_Word:"İlk <a routerLink='/folder/Administrator/New'>Moderator</a>'u ekleyin.",
  Defaults:"varsayılanlar",
  Provide_Us_Info:"Bilgi Sağlayın",
  Setup_Your_Printer:"Yazıcınızı Kurun",
  Your_Company:"Şirketiniz Hakkında",
  Company_Name:"Firma Adı",
  Currency:"Para birimi",
  Default_Currency:"Varsayılan Para Birimi",
  Base_Monthly_Income:"Aylık gelir",
  Protection:"Koruma",
  App_Protection:"Uygulama Koruması",
  PIN_Code_Protection:"PIN Kodu Koruması",
  App_Protection_Word:"Hesabınızı korumaya yardımcı olan güvenlik yöntemlerini etkinleştirin.",
  PIN_Code:"PIN Kodu",
  Change_PIN:"Pin'i değiştir",
  New_Password:"Yeni Şifre",
  Geofence_Protection:"Coğrafi Çit Koruması",
  Geofence_Area:"Alanı Ayarla",
  Distance:"Mesafe",
  Setup_Now:"Şimdi kur",
  Mile:"Mil",
  Km:"km",
  m:"M",
  Facial_Recognition:"Yüz tanıma",
  Face:"Yüz",
  Setup:"Kurmak",
  Label:"Etiket",
  Password_Protection:"Şifre Koruması",
  Modify_Password:"Parolayı Değiştir",
  New_Tax_Entry:"Yeni Vergi Girişi",
  New_Tax:"Yeni Vergi",
  Tax_Label:"Bandrol",
  Perpetually_Enabled:"Sürekli Etkin",
  Select_Taxes:"Vergileri Seçin",
  Tax_Deductions:"Vergi kesintileri",
  No_Tax_Deductions:"Vergi İndirimi Yok",
  No_Tax_Deductions_Word:"İlk <a routerLink='/folder/Tax/New'>Vergi</a> kesintisini ekleyin.",
  New_Timer:"Yeni Zamanlayıcı",
  Start:"Başlangıç",
  Stop:"Durmak",
  Start_Timer:"Zamanlayıcıyı Başlat",
  Stop_Timer:"Zamanlayıcıyı Durdur",
  Timer_Active:"Zamanlayıcı Aktif",
  Timer:"Zamanlayıcı:",
  Timer_Running:"Zamanlayıcı: (Çalışıyor)",
  Save_Timer:"Zamanlayıcıyı Kaydet",
  New_Timesheet:"Yeni Zaman Çizelgesi",
  Select_Timesheets:"Zaman Çizelgelerini Seçin",
  Work_Notes:"Çalışma Notları",
  Entry_Title:"Giriş Başlığı",
  No_Timesheets:"Zaman Çizelgesi Yok",
  No_Timesheets_Word:"İlk <a routerLink='/folder/Timesheet/New'>Zaman Çizelgesini</a> ekleyin.",
  Timesheet_Submitted:"Zaman Çizelgesi Gönderildi",
  Timesheet_Congrats:"Tebrikler! Zaman Çizelgeniz başarıyla gönderildi. Teşekkür ederim!",
  Timesheet_Copy:"Belgelerinizin bir kopyasını almak için lütfen bize e-posta adresinizi ve/veya cep telefonu numaranızı verin.",
  Submit:"Göndermek",
  Allow_Notifications:"Bildirimlere İzin Ver",
  Untitled_Entry:"Yeni giriş",
  Untitled_Bank:"İsimsiz Banka",
  Timesheet_Entry:"Zaman Çizelgesi Girişi",
  Work_Description:"iş tanımı",
  Enable_Timesheet:"Zaman Çizelgesini Etkinleştir",
  Submit_Timesheet:"Zaman Çizelgesini Gönder",
  Vacation:"Tatil",
  New_Vacation:"Yeni Tatil",
  Vacation_Name:"Tatil Adı",
  Paid_Vacation:"Ödenmiş tatil",
  Vacation_Pay:"Tatil ödemesi",
  Enable_Vacation:"Tatili Etkinleştir",
  Select_Vacations:"Tatilleri Seçin",
  No_Vacations:"tatil yok",
  No_Vacations_Word:"İlk <a routerLink='/folder/Vacation/New'>Tatil</a> girişini oluşturun.",
  Payroll_Schedule:"Bordro Takvimi",
  Next_Payroll:"Sonraki Bordro:",
  Upcoming_Payroll:"Yaklaşan Bordro",
  No_Upcoming_Payroll:"Yaklaşan Bordro Yok",
  Address_Book:"Adres defteri",
  Archived_Documents:"Arşivlenen Belgeler",
  Dev_Mode:"Geliştirme Modunda Uygulama",
  Administrators:"Yöneticiler",
  Privacy:"Mahremiyet",
  None:"Hiçbiri",
  Select_Signature:"İmza Seç",
  No_Signatures:"İmza Yok",
  No_Signatures_Word:"İlk <a routerLink='/folder/Signature/New'>Signature</a>'ı ekleyin.",
  Repeat_Indefinitely:"Süresiz Tekrarla",
  Sign:"İmza",
  Sign_Here:"Burayı imzalayın",
  Date_Signed:"İmza Tarihi",
  Signature_Pad:"İmza Pedi",
  Account_Holder:"Hesap sahibi",
  Account_Properties:"Hesap Özellikleri",
  Name_On_Cheque:"Çekteki İsim",
  Server_Hostname:"Sunucu Ana Bilgisayar Adı / IP",
  Printers:"Yazıcılar",
  No_Printers:"Yazıcı Yok",
  Printer_Exists:"Bu ada sahip bir yazıcı zaten var.",
  No_Printers_Word:"İlk <a routerLink='/folder/Printer/New'>Yazıcıyı</a> ekleyin.",
  No_Printer_Alert:"Yazıcı tanımlanmadı. Bir yazıcı kurmak ister misiniz?",
  Add_Printer:"Yazıcı ekle",
  New_Printer:"Yeni Yazıcı",
  Printer_Hostname:"Yazıcı Ana Bilgisayar Adı / IP",
  Printer_Label:"Yazıcı Etiketi",
  Printer_Protocol:"Yazıcı Protokolü",
  Protocol:"Protokol",
  Email_Print:"E-posta",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Priz",
  Print_Job:"Yazdırma İşi",
  Printed:"baskılı",
  Not_Printed:"Basılmamış",
  Print_Jobs:"Yazdırma İşleri",
  Print_Queue:"Yazdırma kuyruğu",
  No_Print_Jobs:"Yazdırma İşi Yok",
  No_Prints:"Yazdırmak için yeni bir <a routerLink='/folder/Cheque/New'>Check</a> oluşturun.",
  Prints:"Baskılar",
  Find_Printer:"Yazıcı bul",
  Find_AirPrint_Devices:"AirPrint Cihazlarını Bulun",
  Select_Printer:"Yazıcı Seç",
  System_UI:"Sistem kullanıcı arayüzü",
  Printer:"Yazıcı",
  Status:"Durum",
  Preview:"Ön izleme",
  Enable_Print_Job:"Yazdırma İşini Etkinleştir",
  Queue_Weight:"Kuyruk Ağırlığı",
  Unlimited:"Sınırsız",
  Show_Advanced_Printer_Options:"Gelişmiş Yazıcı Seçeneklerini Göster",
  Advanced:"Gelişmiş",
  Location:"Konum",
  Note:"Not",
  Queue_Name:"Sıra Adı",
  Pages_Printed_Limit:"Yazdırılan Sayfa Sınırı",
  MultiPage_Idle_Time:"Çok Sayfalı Bekleme Süresi (ler)",
  Page_Count_Limit:"Sayfa Sayısı Sınırı",
  Page_Orientation:"Sayfa yönlendirmesi",
  Portrait:"Vesika",
  Landscape:"Manzara",
  Duplex:"dubleks",
  Double_Sided_Printing:"Çift taraflı",
  Duplex_Mode:"Çift Yönlü Mod",
  Duplex_Short:"Kısa",
  Duplex_Long:"Uzun",
  Duplex_None:"Hiçbiri",
  Color_And_Quality:"Renk Ve Kalite",
  Monochrome:"tek renkli",
  Photo_Quality_Prints:"Fotoğraf Kalitesinde Baskılar",
  Printer_Email:"Yazıcı E-postası",
  Automatically_Downsize:"Otomatik Olarak Küçültme",
  Paper:"Kağıt",
  Paper_Name:"Kağıt Adı",
  Paper_Width:"Kağıt Genişliği",
  Paper_Height:"Kağıt Yüksekliği",
  Paper_Autocut_Length:"Kağıt Otomatik Kesme Uzunluğu",
  Margins:"kenar boşlukları",
  Page_Margins:"Sayfa Kenar Boşlukları",
  Page_Margin_Top:"Üst Sayfa Kenar Boşluğu",
  Page_Margin_Right:"Sağ Sayfa Kenar Boşluğu",
  Page_Margin_Bottom:"Alt Sayfa Kenar Boşluğu",
  Page_Margin_Left:"Sol Sayfa Kenar Boşluğu",
  Add_Employees:"Çalışan Ekle",
  Header:"Başlık",
  Print_A_Page_Header:"Sayfa Başlığı Yazdır",
  Header_Label:"Başlık Etiketi",
  Header_Page_Index:"Başlık Sayfası Dizini",
  Header_Font_Name:"Başlık Yazı Tipi",
  Select_Font:"Yazı Tipi Seç",
  Font_Selector:"Yazı Tipi Seçici",
  Header_Font_Size:"Başlık Yazı Tipi",
  Header_Bold:"Başlık Kalın",
  Header_Italic:"Başlık İtalik",
  Header_Alignment:"Başlık Hizalama",
  Left:"Sol",
  Center:"merkez",
  Right:"Sağ",
  Justified:"Haklı",
  Header_Font_Color:"Başlık Rengi",
  Select_Color:"Renk seç",
  Footer:"Altbilgi",
  Print_A_Page_Footer:"Sayfa Altbilgisi Yazdır",
  Footer_Label:"Altbilgi Etiketi",
  Footer_Page_Index:"Alt Bilgi Sayfası Dizini",
  Footer_Font_Name:"Altbilgi Yazı Tipi",
  Fonts:"yazı tipleri",
  Done:"Tamamlamak",
  Select_Fonts:"Yazı Tiplerini Seçin",
  Footer_Font_Size:"Altbilgi Boyutu",
  Footer_Bold:"Altbilgi Kalın",
  Footer_Italic:"Altbilgi İtalik",
  Footer_Alignment:"Altbilgi Hizalaması",
  Footer_Font_Color:"Alt Bilgi Rengi",
  Page_Copies:"Sayfa Kopyaları",
  Enable_Printer:"Yazıcıyı Etkinleştir",
  Remote_Logging:"Uzaktan Kayıt",
  Log_Server:"Günlük Sunucusu",
  Encryption:"şifreleme",
  Random_Key:"Rastgele Anahtar",
  Encryption_Key:"Şifreleme anahtarı",
  Cheques_Webserver:"Özel Veritabanı",
  Learn_How:"Nasıl öğrenilir",
  Signature:"İmza",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"İmzayı Görüntüle",
  Printed_Signature:"Basılı İmza",
  Digitally_Sign:"Dijital Olarak İmzalayın",
  Digital_Signature:"Elektronik imza",
  Add_Signature:"İmza Ekle",
  Add_Your_Signature:"İmzanızı Ekleyin",
  Enable_Signature:"İmzayı Etkinleştir",
  Digitally_Signed:"Dijital İmzalı",
  Insert_Error:"Veritabanı sorunları nedeniyle çek kaydedilemiyor.",
  Delete_Confirmation:"Bu bilgiyi silmek istediğinizden emin misiniz?",
  Discard_Confirmation:"Bu bilgiyi silmek istediğinizden emin misiniz?",
  Discard_Bank_Confirmation:"Bu hesabı silmek istediğinizden emin misiniz?",
  Discard_Printer_Confirmation:"Bu yazıcıyı atmak istediğinizden emin misiniz?",
  Delete_Bonus_Confirmation:"Bu bonusu silmek istediğinizden emin misiniz?",
  Delete_Invoice_Confirmation:"Bu faturayı silmek istediğinizden emin misiniz?",
  Generated_Cheque:"Oluşturulan Kontrol",
  Generated_Invoice:"Oluşturulan Fatura",
  Schedule_Start_Time:"Başlatmayı Planla",
  Schedule_End_Time:"Program Sonu",
  New_Call:"Yeni Çağrı",
  No_Contacts:"Kişi Yok",
  No_Contacts_Word:"Yöneticiler, moderatörler, çalışanlar ve alacaklılar ilgili kişi olarak görünür.",
  PDF_Subject:"finans",
  PDF_Keywords:"bordro maaş çeki PDF çek çekleri",
  Printer_Setup:"Yazıcı Kurulumu",
  Printer_Selection:"Yazıcı Seçimi",
  New_Fax:"Yeni Faks",
  New_Fax_Message:"Yeni Faks Mesajı",
  Fax_Machine:"Faks makinesi",
  Fax_Name:"Faks Adı",
  Fax_Email:"Faks E-postası",
  Fax_Number:"Faks numarası",
  Contents:"İçindekiler",
  Fax_Body:"Sayfa Gövdesi",
  Header_Word:"Başlık:",
  Header_Text:"Başlık metni",
  Include_Header:"Başlığı Dahil Et",
  Include_Footer:"Altbilgiyi Dahil Et",
  Footer_Word:"Altbilgi:",
  Footer_Text:"Altbilgi metni",
  Attach_a_Cheque:"Çek Ekle",
  Add_Deduction:"Kesinti Ekle",
  Enable_Fax:"Faks Gönder",
  Select_Fax:"Faks Seç",
  No_Faxes:"Faks Yok",
  Faxes:"Fakslar",
  Save_and_Send:"Faks Gönder",
  Save_and_Pay:"Kaydet ve Öde",
  WARNING:"UYARI:",
  Remember:"Hatırlamak",
  Printing:"Baskı",
  Printing_Complete:"Yazdırma Tamamlandı!\n\n",
  of:"ile ilgili",
  There_Were:"Vardı",
  Successful_Prints:"başarılı baskılar ve",
  Unsuccessful_Prints:" başarısız baskılar.",
  PrinterError:"Üzgünüm! Bir hata oluştu.",
  Printing_:"Baskı...",
  PrinterSuccess:"Belge başarıyla yazdırıldı.",
  PrintersSuccess:"Belgeler başarıyla yazdırıldı.",
  New_Message:"Yeni Mesaj",
  New_Messages:"Yeni Mesajlar",
  Read_Message:"Mesajı oku",
  Write_Message:"Mesaj Yaz",
  Send_Message:"Mesaj gönder",
  Subject:"Ders",
  Message:"İleti",
  Writing:"Yazı...",
  Send:"Göndermek",
  Set_Date:"Tarih ayarla",
  Set_Time:"Ayarlanan zaman",
  Recieve:"Almak",
  Set_as_Default:"Varsayılan olarak ayarla",
  Default_Account:"Varsayılan Hesap",
  Default_Design:"Varsayılan Tasarım",
  Multiple_Cheques:"Üç Kontrol",
  Account_Mode:"Hesap Modu",
  Multiple_Cheques_Description:"Sayfa başına üç kontrol.",
  Check_and_Table:"Çek ve Tablo",
  Check_including_Table:"Muhasebe tablosu ile kontrol edin.",
  Check_Mailer:"Postayı Kontrol Et",
  Check_Mailer_Window:"Bir adres penceresi ile kontrol edin.",
  DocuGard_Table_Top:"Büyük Çek ve Tablo",
  DocuGard_Table_Middle:"Büyük Çek & Tablo (Orta)",
  DocuGard_Table_Bottom:"Büyük Kontrol & Tablo (Alt)",
  DocuGard_Mailer_Top:"Büyük Çek Postası",
  DocuGard_Mailer_Middle:"Büyük Çek Postası (Orta)",
  DocuGard_Mailer_Bottom:"Büyük Çek Postası (Alt)",
  DocuGard_Three_Cheques:"Üç Büyük Çek",
  DocuGard_Cheque_Top:"Tek Büyük Kontrol",
  DocuGard_Cheque_Middle:"Büyük Kontrol (Orta)",
  DocuGard_Cheque_Bottom:"Büyük Çek (Alt)",
  DocuGard_Three_Cheques_Window:"Bir sayfada üç kontrol.",
  DocuGard_Table_Top_Window:"Çek ve kazanç tablosu.",
  DocuGard_Table_Middle_Window:"Çek ve kazanç tablosu.",
  DocuGard_Table_Bottom_Window:"Çek ve kazanç tablosu.",
  DocuGard_Mailer_Top_Window:"Çek, tablo ve adres.",
  DocuGard_Mailer_Middle_Window:"Çek, tablo ve adres.",
  DocuGard_Mailer_Bottom_Window:"Çek, tablo ve adres.",
  DocuGard_Cheque_Top_Window:"Güvenli kağıt olup olmadığını kontrol edin.",
  DocuGard_Cheque_Middle_Window:"Güvenli kağıt olup olmadığını kontrol edin.",
  DocuGard_Cheque_Bottom_Window:"Güvenli kağıt olup olmadığını kontrol edin.",
  Basic_Cheque:"Tek Kontrol",
  Basic_Cheque_Print:"Tek bir çek yazdırın.",
  Error_Setting_As_Paid:"Ödendi Olarak Ayarlama Hatası",
  Add_Attachment:"Ek Ekle",
  PrinterUnavailable:"Yazıcı Kullanılamıyor",
  CreditCardComponent:"kartlar",
  PaypalComponent:"paypal",
  InteracComponent:"etkileşim",
  BitcoinComponent:"Bitcoin",
  New_Deposit:"Yeni Mevduat",
  Deposits:"Mevduat",
  No_Deposits:"Depozito Yok",
  Credit_Card_Deposit:"Kredi kartı",
  New_Credit_Card_Deposit_Message:"Kredi Kartı Depozitosu",
  New_BitCoin_Deposit:"Bitcoin",
  New_BitCoin_Deposit_Message:"BitCoin Depozitosu",
  New_Interac_Deposit:"etkileşim",
  New_Interac_Deposit_Message:"Etkileşim Transferi",
  Payments_Limit:"Ödeme Limiti",
  No_Payment_Limit:"Ödeme Limiti Yok",
  PayPal_Deposit:"paypal",
  PayPal_Deposit_Message:"PayPal Depozitosu",
  No_Deposits_Word:"İlk şirket <a routerLink='/folder/Deposit/New'>Depozito</a>'i ekleyin.",
  No_Documents_Specified:"Yazdırma için hiçbir belge belirtilmedi",
  No_Printers_Added:"Yazıcı bulunamadı. Bir tane eklemek için Ayarlar > Yazıcılar'a gidin.",
  DB_Erase_Prompt:"Veritabanının tamamı tamamen silinsin mi? UYARI: Saklanan tüm bilgileri kaybedeceksiniz!",
  Console_Warning:"Bu konsola herhangi bir metin yapıştırmayın. Kendinizi ve/veya şirketinizi ciddi şekilde riske atabilirsiniz.",
  No_Faxes_Word:"İlk <a routerLink='/folder/Fax/New'>Faks</a>'ı oluşturun.",
  Cheque_Delete_Confirmation:"Bu çeki silmek istediğinizden emin misiniz?",
  Design_Delete_Confirmation:"Bu tasarımı silmek istediğinizden emin misiniz?",
  Cheque_Pay_Confirmation:"Bu çek ödendi olarak işaretlensin mi? Yazdırma kuyruğunda GÖRÜNMEYECEKTİR.",
  Payment_Pay_Confirmation:"Bu ödeme ödendi olarak işaretlensin mi? Kontrol kuyruğunda GÖRÜNMEYECEKTİR.",
  Delete_Deduction_Confirmation:"Bu kesintiyi silmek istediğinizden emin misiniz?",
  Delete_Job_Confirmation:"Bu işi silmek istediğinizden emin misiniz?",
  Delete_Timesheet_Confirmation:"Bu zaman çizelgesini silmek istediğinizden emin misiniz?",
  Delete_Schedule_Confirmation:"Bu programı silmek istediğinizden emin misiniz?",
  Delete_Setting_Confirmation:"Bu ayarı sıfırlamak istediğinizden emin misiniz?",
  Delete_Fax_Confirmation:"Bu faksı silmek istediğinizden emin misiniz?",
  Delete_File_Confirmation:"Bu dosyayı silmek istediğinizden emin misiniz?",
  Delete_Vacation_Confirmation:"Bu tatili silmek istediğinizden emin misiniz?",
  Delete_Printer_Confirmation:"Bu yazıcıyı silmek istediğinizden emin misiniz?",
  Remove_Design_Confirmation:"Bu tasarımı silmek istediğinizden emin misiniz?",
  Delete_Payroll_Confirmation:"Bu maaş bordrosunu silmek istediğinizden emin misiniz?",
  Send_Fax_Email_Confirmation:"Bu belgeleri fakslayıp e-postayla göndermek istiyor musunuz?",
  Send_Email_Confirmation:"Bu belgeyi e-postayla göndermek istiyor musunuz?",
  Send_Fax_Confirmation:"Bu belgeyi fakslamak istiyor musunuz?",
  Error_Generating_PDF:"Üzgünüm. Bu belge oluşturulurken bir hata oluştu.",
  PDF_Error_Saving_Image:"Üzgünüm. Bu belgenin PDF görüntüsü kaydedilirken bir hata oluştu.",
  Test_Printer_Confirmation:"Bu yazıcıda bir test sayfası yazdırmak istiyor musunuz?",
  Save_Timesheet_Prompt:"Lütfen bir 'Başlık' ekleyin veya kaydetmek için 'Zamanlayıcıyı Başlat'a basın.",
  Remove_Geofence_Prompt:"Bu coğrafi çitin konumunu kaldırmak istediğinizden emin misiniz?",
  Delete_Employee_Confirmation:"Silmek istediğine emin misin",
  Fire_Employee_Confirmation:"Ateş etmek istediğinden emin misin?"
}