export const Zh = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"版权所有2023年",
  black:"黑色的",
  green:"绿色的",
  gold:"金子",
  blue:"蓝色的",
  brown:"棕色的",
  purple:"紫色的",
  pink:"粉色的",
  red:"红色的",
  Swatches:"色板",
  HSL:"华硕",
  RGB:"红绿蓝",
  Hue:"色调",
  Saturation:"饱和",
  Lightness:"亮度",
  Upgrade_To_Pro:"升级到专业版",
  AllFeaturesInclude:"所有功能包括：",
  PrintUnlimitedCheques:"打印无限支票",
  PremiumChequeDesigns:"高级支票设计",
  ManageUnlimitedEmployees:"管理无限员工",
  AddUnlimitedPayees:"添加无限收款人",
  CreateUnlimitedPayrolls:"创建无限的工资单",
  UnlimitedSchedulesandTimeSheets:"无限的时间表和时间表",
  BulkPayPalPayouts:"批量支付贝宝",
  UnlimitedBankAccounts:"无限银行账户",
  ManageMultipleCompanies:"管理多家公司",
  TrackInsurancePolicies:"跟踪保险政策",
  Bio_MetricProtection:"生物特征保护",
  Geo_FenceLock_OutProtection:"地理围栏锁定保护",
  Multiple_Companies_Word:"如果没有 支票保费，则无法管理多家公司。",
  PayPal_Payouts_Word:"如果没有 支票保费，贝宝 付款将被禁用。",
  PINProtection:"密码保护",
  PasswordProtection:"密码保护",
  May_Require_Approval:"可能需要批准。",
  Subscribe:"订阅",
  Billed:"计费",
  Up:"向上",
  Down:"向下",
  Positioning:"定位",
  Marker:"标记",
  Drag_Marker:"拖动标记",
  Tagline:"打印支票和制表工资",
  Marker_Word:"使用标记来调整元素的大小。",
  Pinch_Zoom:"捏缩放",
  Pinch_Word:"双指缩放元素。",
  Drag:"拖",
  Drag_Word:"用手指拖动元素。",
  subscription_alias_word:"自动续订订阅",
  premium_alias_word:"一次性升级包",
  print_alias_word:"打印个人支票",
  mode_alias_word:"模式",
  Pro:"临",
  Pro_word:"需要专业版。",
  Cant_Delete_Default_Company_Word:"抱歉，您不能删除您的默认公司。",
  Reinsert_Default_Designs:"重新插入默认设计",
  Reinsert_Default_Designs_word:"您要重新插入默认设计吗？",
  Subscription_Active_Disable_Word:"此订阅有效。您想取消对 Cheques 的订阅吗？",
  Company_Logo:"公司标志",
  ZERO_:"零",
  Disclaimer:"免责声明",
  Privacy_Policy:"隐私政策",
  EULA:"检查 EULA",
  Terms_Of_Service:"服务条款",
  Terms_Of_Use:"使用条款",
  Refunds:"退款政策",
  Single_Print:"1 检查",
  Two_Prints:"2 支票",
  Five_Prints:"5 检查",
  Ten_Prints:"10张支票",
  Fifteen_Prints:"15 支票",
  Twenty_Prints:"20张支票",
  Thirty_Prints:"30 张支票",
  Image_Added:"图片已添加",
  Image_Preview:"图片预览",
  No_Image_Was_Selected:"没有选择图像。",
  Cheques_Unlimited:"支票无限",
  _Subscription:"订阅",
  Subscription:"支票 - 1 个月",
  Two_Month_Subscription:"支票 - 2 个月",
  Three_Month_Subscription:"支票 - 3 个月",
  Six_Month_Subscription:"支票 - 6 个月",
  Twelve_Month_Subscription:"支票 - 12 个月",
  Cheques_Are_Available:"支票可供打印。",
  Cheques_Thank_You:"非常感谢您购买支票！您的购买现已生效。",
  Upgrade_Required_Two:"选择一个套餐并双击价格按钮开始购买。在几秒钟内打印出具有专业外观的全彩支票。",
  Month:"月",
  Due:"到期的：",
  Expires:"过期：",
  Subscription_Active:"订阅有效",
  Subscription_Inactive:"订阅无效",
  Purchase_Additional_Cheques:"购买额外的支票？",
  Printable_Cheque:"可打印支票",
  Printable_Cheques:"可打印支票",
  Printable_Cheque_Word:"支票可用于您的帐户。",
  Printable_Cheques_Word:"支票可用于您的帐户。",
  Max_Amount_Message:"您指定的金额已超过为本系统设置的最大金额：",
  Terms_Required_Word:"在继续使用支票之前，您必须同意本协议。",
  Subscriptions:"订阅",
  Product_Upgrades:"升级",
  Mailed_Out_Cheques:"邮寄支票",
  Enter_A_Company_Name:"请输入公司名称。",
  Single_Cheques:"单一支票",
  Cheque_Golden:"黄金支票",
  Cheque_Golden_Window:"金色支票设计。",
  Cheque_Green:"绿色检查",
  Cheque_Green_Window:"绿色检查设计。",
  Cheque_Red:"红色格子",
  Cheque_Red_Window:"红色检查设计。",
  Cheque_Yellow:"黄色格子",
  Cheque_Yellow_Window:"黄色检查设计。",
  Cheque_Statue_of_Liberty:"自由女神像",
  Cheque_Statue_of_Liberty_Window:"自由女神像检查设计。",
  Cheque_Green_Wave:"绿波",
  Cheque_Green_Wave_Window:"绿色检查设计。",
  Cheque_Golden_Weave:"金织",
  Cheque_Golden_Weave_Window:"优雅的金色格子设计。",
  Cheque_Green_Sun:"绿太阳",
  Cheque_Green_Sun_Window:"深沉而平静的格纹设计。",
  Cheque_Blue_Checkers:"蓝色跳棋",
  Cheque_Blue_Checkers_Window:"蓝色检查设计。",
  Cashiers_Check:"本票",
  Cashiers_Check_Window:"银行本票样式模板。",
  Cheque_Aqua_Checkers:"水上跳棋",
  Cheque_Aqua_Checkers_Window:"水色检查设计。",
  Cheque_Golden_Checkers:"金跳棋",
  Cheque_Golden_Checkers_Window:"金色支票设计。",
  Upgrade_Unavailable:"升级不可用",
  Bank_Code_Protection:"银行号码保护",
  Bank_Code_Protection_Word:"保护您的银行号码不被用于在另一台设备上运行的此应用程序或用于其他用户。此操作不可逆转。继续？",
  Bank_Code_Protection_Blocked_Word:"您尝试使用的银行号码是为其他用户或设备保留的。",
  Bank_Code_Protection_Error_Word:"号码验证失败。请连接到互联网并尝试重新添加此银行帐户。",
  Bank_Code_Protection_Set_Error_Word:"银行号码保护要求您连接到互联网。请连接并重试。",
  Upgrade_Unavailable_Word:"抱歉，我们无法验证您。当前无法在您所在的地区购买 支票 的订阅和升级。",
  PayPal_Payment_Preview:"贝宝付款预览",
  Apple_Pay:"苹果支付",
  Select_PayPal:"选择贝宝",
  PayPal_Applications:"贝宝应用程序",
  Purchase_With_Apple_Pay:"使用 Apple Pay 购买",
  Google_Pay:"谷歌支付",
  Companies:"公司",
  Insurance:"保险",
  New_PayPal:"新贝宝",
  Pay_By:"支付方式",
  Insure:"保证",
  Miles:"英里",
  Payment_Method:"付款方式",
  Select_Policies:"选择政策",
  Policies:"政策",
  Policy:"政策",
  No_PayPal_Account:"没有贝宝账户",
  No_Policies:"没有保险单",
  New_Policy:"新政策",
  PayPal_Payment:"贝宝支付",
  PayPal_Payments:"贝宝付款",
  No_Payment_Selected:"未选择付款",
  Sending_Payment_Word:"请稍候... 正在发送这笔付款。",
  Sending_Payments_Word:"请稍候... 正在发送付款。",
  No_Payment_Selected_PayPal:"没有选择要发送的 <a routerLink='/folder/Payments'>贝宝 付款</a>。",
  Payment_Sent:"付款已发送",
  PayPal_Payment_Sent:"此付款已通过 PayPal 发送。",
  Copay:"共付额",
  Dead:"死的",
  Alive:"活",
  Not_Dead:"没死",
  Unclaimed:"无人认领",
  Attempted:"尝试过",
  Deceased:"死者",
  Claimed:"声称",
  Unpaid:"未付",
  Sending_Payment:"发送付款",
  Sending_Payments:"发送付款",
  Send_PayPal_Confirmation:"您要使用 PayPal 发送此交易吗？",
  Send_PayPal_Confirmation_Word:"按绿色按钮发送此交易。",
  Save_Payment_As_Unpaid:"将这笔付款保存为未付款？",
  Payment_Pay_Confirmation_PayPal:"将此付款保存为已付款？",
  No_Policies_Word:"现在添加第一张<a routerLink='/folder/Postage/New'>保险政策</a>。",
  Timesheet_Multiple_Delete_Confirmation:"您确定要删除多个时间表吗？",
  Select_Multiple_Timesheets_Prompt:"未选择时间表。选择至少一个时间表。",
  Select_Multiple_Policies_Prompt:"未选择政策。至少选择一项保险单。",
  Policies_Multiple_Delete_Confirmation:"您确定要删除多个策略吗？",
  Company:"公司",
  Add_Company:"添加公司",
  New_Company:"添加公司",
  No_Companies:"没有公司",
  Enable_Company:"启用公司",
  Select_Company:"选择公司",
  The_Default_Company:"默认公司标签。",
  Manage_Companies:"管理公司",
  No_Companies_Word:"支票将使用默认公司。<br />添加<a routerLink='/folder/Company/New'>第一个公司</a>。",
  Default_Company:"默认公司",
  Cheques_Unlimited_Word:"Checks Unlimited 允许您打印任意数量的支票。",
  Cheques_Subscription_Word:"支票订阅允许您打印任意数量的支票。",
  You_Own_This_Product:"您拥有此产品。",
  Your_Subscription_is_Active:"您的订阅有效。",
  Active_Products:"活化产品",
  Purchase_Confirmation:"购买",
  Purchase_Cheques:"采购支票",
  Restore_Purchase:"恢复购买",
  Erase_Purchase:"擦除购买",
  Successfully_Purchased:"购买成功",
  Enter_Your_Licence_Key:"请在此页面上输入您的许可证密钥以激活此产品。",
  Licence_Key:"注册码",
  Enter_Licence_Key:"输入许可证密钥",
  Purchased:"已购买",
  Enable_Feature:"启用功能",
  Cancel_Subscription:"取消订阅",
  Subscription_Removed:"订阅已删除",
  Select_Active_Subscription:"选择一个活动订阅以对其进行修改。",
  Remove_Subscription_Word:"您确定要取消此订阅吗？",
  Delete_Company_Confirmation:"您确定要删除整个公司吗？警告：您将丢失所有存储的信息！",
  Delete_Default_Company_Word:"您不能删除默认公司。您想重置应用程序并将其恢复到默认状态吗？警告：您将丢失所有存储的信息！",
  Console_Warning_2:"不要使用此应用程序处理当前不属于您的任何货币。",
  Terms_and_Conditions:"条款和条件",
  and_the:"和",
  for:"为了",
  Please_Read_Word:"请阅读并同意",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"找不到某些货币兑换率。请连接到互联网。",
  Free:"自由的",
  DB_Erase_Prompt_2:"彻底擦除整个开发者数据库？警告：您将丢失所有购买和订阅信息！",
  Successfully_Imported:"导入成功",
  Select_Postage:"选择邮费",
  No_Postage:"没有邮票",
  No_Paid_Postage_Word:"添加第一个 <a routerLink='/folder/Postage/New'>已付邮费</a> 邮票。",
  Trial_Complete:"试用完成",
  Please_Upgrade:"请升级支票以继续打印。",
  Aa:"啊",
  Color:"颜色",
  Font:"字体",
  Guide:"指导",
  Guides:"导游",
  Image:"图像",
  Zoom:"飞涨",
  Logo:"标识",
  Bank:"银行",
  MICR:"微创",
  Total:"全部的",
  Cancel:"取消",
  Confirm:"确认",
  Method:"方法",
  Biometric_Security:"生物识别安全",
  Please_Login_To_Continue:"请登录访问更多内容。",
  Complete:"完全的",
  Sign_Up:"报名",
  Error:"错误",
  Biometrics:"生物识别",
  Percent:"百分",
  Zero_Percent:"0%",
  Top_Margin:"顶边距",
  Bottom_Margin:"底边距",
  Left_Margin:"左边距",
  Right_Margin:"右边距",
  MICR_Margin:"MICR 保证金",
  Table_Margin:"表格边距",
  Address_Margin:"地址保证金",
  Percentage_:"百分比",
  Vacation_Title:"假期标题",
  Use_PIN:"使用密码",
  Loading__:"加载中...",
  Design_Title:"设计标题",
  Authorize:"授权",
  Key:"钥匙",
  Public_Key:"公钥",
  Private_Key:"私钥",
  Authenticate:"认证",
  Last_Payroll:"最后的工资单",
  Last_Calculation:"最后计算",
  Authorized:"授权",
  Geo_Authorized:"地理位置：授权",
  Not_Authorized:"未经授权",
  Authorization_Complete:"授权完成",
  Geolocation_Authorization:"地理位置授权",
  Out_of_Bounds:"越界",
  Cant_Delete_Default_Design:"无法删除默认设计。",
  Unauthorized_Out_of_Bounds:"未经授权：越界",
  Biometric_Authorization:"生物识别授权",
  Locating_Please_Wait:"定位中，请稍候...",
  Test_Biometrics:"测试生物识别",
  Cheque_Margins:"检查保证金",
  Percentage_Full_Error:"百分比字段不能设置超过 100% 百分比。",
  No_Payroll_Text:"添加一个 <a routerLink='/folder/Employee/New'>员工</a> 或 <a routerLink='/folder/Payee/New'>收款人</a> 和一个 <a routerLink='/folder/Schedule/New'>时间表</a>。",
  Design_Saved:"设计已保存",
  Default_Design_Selected:"选定的默认设计",
  Partial_Import:"部分进口",
  Partial_Export:"部分出口",
  Entire_Import:"整个导入",
  Entire_Export:"整体出口",
  Existing_Password:"请输入您现有的密码：",
  Existing_PIN:"请输入您现有的 PIN 码：",
  Pin_Change_Header:"密码确认",
  Pin_Change_SubHeader:"输入您的旧 PIN 码以确认更改。",
  Pass_Change_Header:"确认密码",
  Pass_Change_SubHeader:"输入您的旧密码以确认更改。",
  PIN_Enter_Message:"输入您的 PIN 码进行确认。",
  Password_Enter_Message:"输入您的密码进行确认。",
  Pin_Updated_Success:"PIN 更新成功！",
  Pin_Updated_Fail:"无法更新 PIN。",
  Pass_Updated_Success:"密码更新成功。",
  Pass_Updated_Fail:"无法更新密码。",
  Preview_Payment:"预览付款",
  Preview_Payroll:"预览工资单",
  No_Payments_Created:"未发现已创建任何付款。",
  Payment_Preview:"付款预览",
  Enable_Payee:"启用收款人",
  Rendered:"呈现",
  No_Email:"没有电子邮件",
  Setup_Cheques:"设置检查",
  name:"姓名",
  address:"地址",
  address_2:"地址行 2",
  city:"城市",
  province:"省",
  postal_code:"邮政编码",
  country:"国家",
  username:"用户名",
  full_name:"全名",
  birthday:"生日",
  email:"电子邮件",
  phone:"电话",
  employees:"雇员",
  addresses:"地址",
  payment_amount_limit:"付款限额",
  total_limit:"总限额",
  payees:"收款人",
  description:"描述",
  address_line_one:"地址第一行",
  address_line_two:"地址第二行",
  image:"图像",
  account_holder:"帐户持有人",
  cheque_starting_id:"检查起始 ID",
  transit_number:"中转号码",
  institution_number:"机构编号",
  designation_number:"编号",
  account_number:"帐号",
  currency:"货币",
  signature:"签名",
  payment_payroll_limit:"支付限额",
  total_limi:"总限额",
  date:"日期",
  printed_memo:"打印的备忘录",
  banks:"银行",
  signature_image:"签名图片",
  address_name:"地址名称",
  notes:"笔记",
  design:"设计",
  title:"标题",
  frequency:"频率",
  fax:"传真",
  salaries:"工资",
  salary_ids:"工资 ID",
  start_time:"开始时间",
  end_time:"时间结束",
  paid:"有薪酬的",
  overtime_percentage:"付费百分比",
  overtime_amount:"支付固定金额",
  first_name:"名",
  last_name:"姓",
  moderation:"适度",
  create:"创造",
  edit:"编辑",
  destroy:"破坏",
  day_start_time:"Day_start_time",
  day_end_time:"日终时间",
  fullname:"姓名",
  time:"时间",
  auto_send:"自动发送",
  time_method:"时间法",
  schedules:"时间表",
  indefinite_payroll_enabled:"无限期启用",
  amount:"数量",
  repeat:"重复",
  always_enabled:"始终启用",
  start_date:"开始日期",
  end_date:"结束日期",
  Cheque_Total:"检查总计",
  Total_Amount:"总金额：",
  Amounts:"金额：",
  Images:"图片",
  Files:"文件",
  Previewing:"预览：",
  Insert:"插入",
  Preview_Import:"预览导入",
  Entry:"入口",
  Entries:"条目",
  No_Entries:"没有条目",
  Import_Type:"导入类型",
  Select_Details:"选择详细信息",
  Select_Payee:"选择收款人",
  Enable_Holidays:"启用假期",
  Disable_Holidays:"禁用假期",
  Wire_Transfer:"电汇",
  New_Export:"新出口",
  Export_Data:"导出数据",
  Export_Data_Word:"选择要导出和下载的文件类型。",
  Export_File:"导出文件",
  Mode:"模式",
  Times:"次",
  Widgets:"小部件",
  Slider:"滑块",
  Set_Details:"设置细节",
  Select_Type:"选择类型",
  Display_Slider:"显示滑块",
  Dashboard_Slider:"仪表板滑块",
  Dashboard_Mode:"仪表板模式",
  Show_Intro:"节目介绍",
  Show_Payrolls:"显示工资单",
  Show_Holidays:"展会假期",
  Show_Invoices:"显示发票",
  Show_Cheques:"显示支票",
  Enabled_Widgets:"启用的小部件",
  Disabled_Widgets:"禁用的小部件",
  Colors:"颜色",
  Barcodes:"条形码",
  View_Timers:"查看计时器",
  Gradients:"渐变",
  No_Info:"没有信息",
  Disable:"停用",
  Show_Layer:"显示图层",
  Hide_Layer:"隐藏图层",
  Text_Layer:"文字图层",
  Secondly:"第二",
  Minutely:"每分钟",
  nine_am:"9:00 AM",
  five_pm:"5:00 PM",
  Enable_Address:"启用地址",
  Invalid_File_Type:"抱歉，选择了无效的文件类型。支持的文件类型：",
  Error_Updating_Entry:"抱歉，更新此条目时出错。",
  Schedule_Timing_Alert:"错误：计划开始时间设置为结束时间之后的值。",
  Select_Multiple_Payments_Prompt:"未选择付款。选择至少一项付款。",
  Select_Multiple_Cheques_Prompt:"未选择检查。请至少选择一张支票。",
  Select_Multiple_Items_Prompt:"没有选择项目。请至少选择一项。",
  Paymemt_Multiple_Delete_Confirmation:"您确定要删除多笔付款吗？",
  Cheque_Multiple_Delete_Confirmation:"您确定要删除多张支票吗？",
  Payee_Multiple_Delete_Confirmation:"您确定要删除多个收款人吗？",
  Employee_Multiple_Delete_Confirmation:"您确定要删除多个员工吗？",
  MICR_Warning:"注意：某些打印机和设备可能无法正确显示 MICR 字体。",
  Automatically_Send:"自动发送",
  Type:"类型",
  Payment_Type:"付款方式",
  Auto_Send:"自动发送",
  Automatically_Process:"自动处理",
  Auto_Process:"自动处理",
  Image_Based:"基于图像",
  Font_Based:"基于字体",
  Rerender:"重新渲染",
  Rendering:"渲染",
  Render:"文件",
  Top:"顶部",
  Middle:"中间",
  Bottom:"底部",
  Top_Left:"左上方",
  Top_Center:"顶部中心",
  Top_Right:"右上",
  Middle_Left:"中左",
  Middle_Center:"中间中心",
  Middle_Right:"中右",
  Bottom_Left:"左下方",
  Bottom_Center:"底部中心",
  Bottom_Right:"右下方",
  Numbers:"数字",
  Verified:"已验证",
  Paper_Size:"纸张尺寸",
  New_Device:"新设备",
  Add_Device:"添加设备",
  Remove_Device:"删除设备",
  Delete_Device:"删除设备",
  Block_Device:"块设备",
  Block:"堵塞",
  Unblock:"解锁",
  Table:"桌子",
  Scan_Login_Code:"扫描登录码",
  Login_Code:"登入密码",
  Scan_Code:"扫码",
  Scan_QR_Code:"扫描二维码",
  Select_All:"全选",
  Deselect_All:"取消全选",
  Increase:"增加",
  Decrease:"减少",
  Bold:"大胆的",
  Text:"文本",
  Style:"风格",
  Italic:"斜体",
  QR_Code:"二维码",
  Barcode:"条码",
  Browse_Images:"浏览图片",
  Browse_Files:"浏览文件",
  Background_Image:"背景图",
  Logo_Image:"形象标识",
  Header_Image:"标题图片",
  Bank_Image:"银行形象",
  Cut_Lines:"切割线",
  Background:"背景",
  License:"执照",
  One_License:"1 执照：",
  Licensed:"授权给：",
  Not_Licensed:"未获许可",
  Enter_Serial:"输入序列号",
  Serial_Key:"序列号",
  Serial:"连续剧",
  Product_Key:"产品密钥",
  Check_Product_Key:"验证产品密钥",
  Add_Product_Key:"输入产品密钥",
  Verifying_Purchase:"正在验证购买...",
  Print_Envelope:"打印信封",
  Envelope:"信封",
  Thank_You:"谢谢你！",
  Scale:"规模",
  Print_Scale:"打印比例",
  Borders:"边框",
  VOID:"空白",
  Void_Cheque:"作废支票",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"支付订单：",
  NO_DOLLARS:"没有美元",
  ONE_DOLLAR:"一美元",
  DOLLARS:"美元",
  AND:"和",
  CENTS:"美分。",
  NO_:"不",
  ONE_:"一",
  AND_NO_:"和不",
  _AND_ONE_:"和一个",
  DOLLARS_AND_ONE_CENT:"和一分钱。",
  AND_NO_CENTS:"和零美分。",
  CHEQUE_PRINT_DATE:"日期：",
  CHEQUE_PRINT_MEMO:"备忘录：",
  CHEQUE_PRINT_SIGNATUREMP:"国会议员",
  Initial_Tasks:"初始任务",
  Inital_Setup:"初始设置",
  Welcome_To:"欢迎来到",
  Welcome:"欢迎",
  Swipe:"滑动",
  Intro_Setup:"简介设置",
  Introduction:"介绍",
  CHEQUE_PRINT_CREDIT:"由支票提供支持",
  Title_Intro_Word:"欢迎使用支票",
  Title_Intro:"支票介绍",
  Title_Setup:"检查设置",
  PayPal_Default_Email_Message:"您收到了一笔新的 PayPal 转账。",
  Cheques_App_Export:"支票出口",
  No_Prints_Purchased:"没有购买印刷品",
  No_Prints_Purchased_Word:"没有额外的印刷品可用。请购买额外的支票。",
  Post_Intro_Thanks:"感谢您选择支票。设置过程现已完成。",
  Post_Intro_Word:"从打印您的第一张支票开始，添加未来付款或将员工添加到工资单中。",
  Upgrade_Required:"Checks 要求您拥有更高版本的软件才能隐藏此消息并解锁其他功能。",
  Upgrade_Title:"支票",
  Mailout_Prompt:"您还可以选择让 Checks 为您邮寄工资支票。",
  Mailed_Cheque:"邮寄支票",
  Mailed_Cheque_Color:"邮寄支票（彩色）",
  Terms_Purchase:"自购买之日起 30 天内，所有使用支票进行的电子购买均可全额退款。请阅读并同意",
  Dashboard_Setup:"设置主打印机",
  Dashboard_Add:"添加主要银行账户",
  Dashboard_Customize:"选择检查模板",
  Dashboard_Job_Salary:"创造你的工作并增加你的薪水",
  Dashboard_Employees:"跟踪员工和收款人",
  Dashboard_Print:"打印并邮寄您的薪水支票",
  Dashboard_Payroll:"自动化您的工资单打印",
  Dashboard_PayPal:"设置 PayPal 薪资/付款",
  Begin_Setup:"开始设置",
  Get_Started:"开始",
  Purchase:"购买",
  Lockdown:"封锁",
  Unlock:"开锁",
  Detailed:"详细的",
  Log_In:"登录",
  Login:"登录",
  Launch:"发射",
  Register:"登记",
  Finish:"结束",
  List:"列表",
  Weekends:"周末",
  Weekly:"每周",
  PayPal_Default_Subject:"新付款",
  Payment_Message:"付款讯息",
  PayPal_Default_Payment_Note:"谢谢",
  Setup_Your_Cheqing_Account:"支票账户",
  Add_Your_Primary_Cheqing_Account:"添加您的主要支票账户。",
  Welcome_Word:"简化和自动化薪资和人力资源管理。",
  Get_Started_Quickly:"只需回答几个简单的问题，这将帮助我们开始......",
  Done_Intro_Word:"安装完成",
  PIN_Protected:"密码和 PIN 保护",
  Enter_New_PIN:"输入新的 PIN 码：",
  Enter_PIN:"输入密码：",
  Invalid_PIN:"输入的 PIN 无效。",
  Account_Identifier:"帐户标识符",
  New_Account_Identifier:"新帐户标识符",
  attempt:"试图",
  attempts:"尝试",
  remaining:"其余的",
  Language:"语言",
  languages:"语言",
  select_languages:"选择语言",
  Deposit:"订金",
  Hire_One_Now:"现在雇一个",
  App_Locked:"该应用程序已锁定。",
  Skip_:"跳过",
  Skip_to_Dashboard:"跳到仪表板",
  Dashboard:"仪表板",
  Import:"进口",
  Admin:"管理员",
  New_Admin:"新管理员",
  Settings:"设置",
  Color_Picker:"选色器",
  Font_Picker:"字体选择器",
  Logout:"登出",
  Close:"关闭",
  Close_menu:"关闭",
  Excel:"文件",
  Csv:"CSV文件",
  Sql:"文件",
  Json:"JSON文件",
  Url:"通过 URL 导入",
  Back:"后退",
  Timers:"计时器",
  Cheque:"查看",
  Print:"打印",
  Designs:"设计",
  Pause_Printing:"暂停打印",
  Resume_Printing:"恢复打印",
  Printing_Paused:"打印暂停",
  PrintingUnavailable:"对不起！在此系统上无法打印。",
  Prints_Paused:"打印暂停",
  Administration:"行政",
  Loading:"加载中",
  Unnamed:"无名",
  error:"抱歉，无法打开此支票进行查看。",
  No_Cheques_To_Print:"没有要打印的支票",
  No_Cheques_To_Print_Word:"创建一张<a routerLink='/folder/Cheque/New'>新支票</a>。",
  New_Cheque:"新支票",
  Start_One_Now:"现在开始",
  Edit_Cheque:"编辑检查",
  Select_Cheques:"选择支票",
  Select_Employee:"选择员工",
  Default_Printer:"默认打印机",
  Reassign:"重新分配",
  Configure:"配置",
  Template:"模板",
  Designer:"设计师",
  Edit_Designs:"编辑设计",
  New_Design:"新设计",
  Next:"下一个",
  Save:"节省",
  Name:"姓名",
  Mail:"邮件",
  Amount:"数量",
  Date:"日期",
  PayPal:"贝宝",
  Payouts:"支出",
  PayPal_Label:"贝宝标签",
  Email_Username:"电子邮件/用户名",
  Client_ID:"客户端编号",
  Sandbox_Email:"沙箱电子邮件",
  PayPal_Email:"贝宝邮箱",
  PayPal_Username:"API用户名",
  PayPal_Payouts:"贝宝付款",
  Select_PayPal_Key:"选择贝宝密钥",
  Secret:"秘密",
  API_Secret:"API 秘密",
  PayPal_API_Keys:"贝宝钥匙",
  New_PayPal_Key:"新的贝宝密钥",
  Email_Subject:"电子邮件主题",
  Email_Message:"电邮讯息",
  Payout_Options:"支付选项",
  Payment_Note:"付款单",
  Enable_Employee:"启用员工",
  Enable_Production_Mode:"启用生产模式",
  Sandbox_Username:"沙盒用户名",
  Sandbox_Signature:"沙盒签名",
  Sandbox_Password:"沙盒密码",
  Production_Username:"生产用户名",
  Production_Signature:"生产签名",
  Production_Password:"制作密码",
  Production_Email:"生产电子邮件",
  API_Client_ID:"API 客户端 ID",
  API_Signature:"API 签名",
  API_Password:"接口密码",
  API_Username:"API用户名",
  Secret_Key:"密钥",
  Sandbox:"沙盒",
  Production:"生产",
  Sandbox_Keys:"沙箱密钥",
  Production_Keys:"生产密钥",
  API_Title:"API标题",
  Production_Mode:"生产方式",
  Account_Label:"账户标签",
  No_PayPal_Setup:"没有贝宝密钥",
  Enable_PayPal_Account:"启用贝宝账户",
  No_PayPal_Word:"添加您的 <a routerLink='/folder/Invoice/New'>贝宝 API 密钥</a>。",
  Printed_Memo:"打印的备忘录",
  Employee:"员工",
  View_Employee:"查看员工",
  Mailing_Address:"邮寄地址",
  Company_Address:"公司地址",
  Select_Company_Address:"选择公司地址",
  Address:"地址",
  Any_Day:"任何一天",
  Address_Name:"地址名称",
  Unit:"单元",
  Account:"帐户",
  Bank_Account:"银行账户",
  Account_Limits:"启用账户限制",
  Payroll:"工资单",
  Run:"跑步",
  Run_Payroll:"运行薪资",
  New_Payroll:"新工资单",
  No_Payroll:"没有即将到来的工资单",
  Upcoming_Holiday:"即将到来的假期：",
  Invoice_Due:"发票到期：",
  New_Invoice:"新发票",
  No_Invoices:"没有发票",
  No_Invoices_Word:"创建第一个 <a routerLink='/folder/Invoice/New'>发票</a>。",
  Cheque_Due:"检查到期：",
  Payrolls:"工资单",
  Sandbox_Mode:"沙盒模式",
  Hire:"聘请",
  Pay:"支付",
  New:"新的",
  Add:"添加",
  Make:"制作",
  Time:"时间",
  Write:"写",
  Holiday:"假期",
  Holidays:"假期",
  Next_Holiday:"下一个假期：",
  All_Done:"全做完了！",
  Employees:"雇员",
  Payees:"收款人",
  Job:"工作",
  Jobs:"工作",
  Invoice:"发票",
  Invoices:"发票",
  Vacations:"假期",
  Cheques:"支票",
  Brand_Cheques:"你的品牌",
  Payment:"支付",
  Enable_Payment:"启用付款",
  Payments:"付款",
  Schedule:"日程",
  Schedules:"时间表",
  Timesheet:"时间表",
  Timesheets:"时间表",
  Salary:"薪水",
  New_Address:"新地址",
  Address_2:"地址（第 2 行）",
  _City:"城市",
  _State:"州/省",
  City:"城市/乡镇",
  State:"州/省",
  Postal:"邮政编码",
  ZIP:"邮政/邮编",
  Country:"国家",
  Addresses:"地址",
  Required_Options:"必需的选项",
  Optional_Options:"可选选项",
  Additional_Options:"其他选项",
  Required:"必需的",
  Optional:"选修的",
  Additional:"额外的",
  No_Addresses:"没有地址",
  New_Address_Word:"添加第一个 <a routerLink='/folder/Address/New'>地址</a>。",
  Select_Address:"选择地址",
  No_Address:"没有地址",
  Print_Cheque:"打印支票",
  Print_Cheques:"打印支票",
  Print_Cheque_Now:"立即打印支票",
  Description:"描述",
  Pay_To_The_Order_Of:"支付给以下订单：",
  Select_Date_Range:"选择日期范围",
  Select_Starting_Date:"选择开始日期",
  Select_Ending_Date:"选择结束日期",
  Select_Date:"选择日期",
  Cheque_Date:"检查日期",
  Cheque_Memo:"检查备忘录",
  Blank_Cheque:"空白支票",
  Blank:"空白的",
  No_Cheques:"没有支票",
  No_Cheques_Word:"打印您的第一张<a routerLink='/folder/Cheque/New'>支票</a>。",
  Cheque_Amount_Placeholder:"0.00 美元",
  View_Image:"看图",
  View:"看法",
  Modify:"调整",
  Delete:"删除",
  Cheque_Paid:"有薪酬的",
  New_Deduction:"新扣除",
  Title:"标题",
  Frequency:"频率",
  Hourly:"每小时",
  Daily:"日常的",
  Weekdays:"平日",
  BiWeekly:"2周",
  TriWeekly:"3周",
  Monthly:"每月",
  MiMonthly:"2个月",
  Quarterly:"季刊",
  Yearly:"每年",
  Every_Week:"每周",
  Every_Payroll:"每个工资单",
  Every_Month:"每个月",
  Annually:"每年",
  Always_Scheduled:"总是预定",
  Start_Date:"开始日期",
  End_Date:"结束日期",
  Start_Time:"开始时间",
  End_Time:"时间结束",
  Deduction_Label:"扣除标签",
  Notes:"笔记",
  Options:"选项",
  Enable:"使能够",
  Select_Deductions:"选择扣除",
  Deductions:"扣除额",
  No_Deductions:"没有扣除",
  No_Deductions_Word:"创建您的第一个 <a routerLink='/folder/Deduction/New'>扣除</a>。",
  New_Employee:"新员工",
  No_Title:"无题",
  _Name:"姓名",
  About_Yourself:"关于你自己",
  Full_Name:"全名",
  Birthday:"生日",
  Email:"电子邮件",
  SMS:"短信",
  Phone:"电话",
  Test:"测试",
  Call:"称呼",
  Fax:"传真",
  Printed_Note:"打印的笔记",
  Position:"位置",
  Job_Position:"工作职位",
  Select_a_Job:"选择工作",
  Select_a_Salary:"选择薪水",
  Add_a_Deduction:"添加扣除",
  Taxes:"税收",
  Add_Taxes:"加税",
  Date_of_Birth:"出生日期",
  Email_Address:"电子邮件地址",
  Phone_Number:"电话号码",
  Phone_Call:"电话",
  Enable_on_Payroll:"在工资单上启用",
  Select_Employees:"选择员工",
  No_Employees:"没有员工",
  No_Employees_Word:"添加您的第一个新<a routerLink='/folder/Employee/New'>员工</a>。",
  No_Name:"无名",
  Unemployeed:"失业",
  Employeed:"在职",
  Paid:"有薪酬的",
  Enabled:"启用",
  Disabled:"残疾人",
  Fire:"火",
  Not_Available:"无法使用",
  Not_Available_Word:"打印您的第一个<a routerLink='/folder/Invoice/New'>发票</a>并获得报酬。",
  Select_Invoices:"选择发票",
  Print_Invoice_Word:"打印您的第一个<a routerLink='/folder/Invoice/New'>发票</a>并获得报酬。",
  Invoice_Title:"发票标题",
  Invoice_Date:"发票日期",
  Due_Date:"到期日",
  New_Job:"新工作",
  Details:"细节",
  Customize:"定制",
  Customize_Dashboard:"自定义仪表板",
  Components:"成分",
  No_Components:"没有组件",
  Main_Components:"主要成分",
  Smaller_Components:"更小的组件",
  Error_Loading_Page:"加载此页面时出错。",
  Bank_Details:"银行明细",
  About_Your_Job:"关于你的工作",
  Your_Schedule:"你的日程安排",
  Job_Title:"职称",
  Job_Description:"职位描述",
  Job_Details:"职位详情",
  Enable_Job:"启用作业",
  Pay_Period:"薪水期",
  Perpetually_Schedule:"永久安排",
  Select_Jobs:"选择职位",
  No_Jobs:"没有工作",
  Add_Jobs:"添加职位",
  No_Jobs_Word:"将第一个 <a routerLink='/folder/Job/New'>工作</a> 添加到列表中。",
  Count_Employees:"job.employee_count+'雇员'",
  Zero_Employees:"0 名员工",
  New_Leave:"新假",
  Leave_Name:"留下名字",
  Paid_Leave:"带薪休假",
  Leave_Pay:"带薪休假",
  Indefinite_Leave:"无限期休假",
  Indefinite_Payroll:"无限期薪资",
  Leave:"离开",
  Add_Schedules:"添加时间表",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"启用缺席",
  Select_Leaves:"选择树叶",
  No_Leaves:"没有请假",
  Leave_Of_Absence:"请假",
  Leaves_Of_Absence:"请假",
  New_Leave_of_Absense:"新的请假",
  No_Leaves_Word:"添加第一个 <a routerLink='/folder/Leave/New'>请假</a>。",
  Not_Enabled:"未启用",
  Absences:"缺席",
  Payee:"收款人",
  New_Payee:"新收款人",
  Payee_Identifier:"收款人识别码",
  Payee_Name:"收款人姓名",
  Payee_Title:"收款人名称",
  Payment_Memo:"付款备忘录",
  Select_Payees:"选择收款人",
  No_Payees:"没有收款人",
  Add_Payee_Note:"添加第一个<a routerLink='/folder/Payee/New'>收款人</a>。",
  New_Payees:"新收款人",
  About_The_Payment_Schedule:"付款时间表",
  Your_Payroll_Schedule:"自动发薪",
  New_Payment:"新付款",
  Identifier:"标识符",
  Selected:"已选",
  Select:"选择",
  Memo:"备忘录",
  Payment_Date:"付款日期",
  Mark_as_Paid:"标记为已付款",
  Select_Payments:"选择付款方式",
  No_Payments:"没有付款",
  No_Payments_Word:"创建第一个 <a routerLink='/folder/Payment/New'>支付</a>。",
  Create_Payroll:"创建工资单",
  Properties:"特性",
  Payroll_Title:"工资标题",
  Payroll_Notes:"工资单",
  Payroll_Setup:"薪资设置",
  Tabulate_Payments:"制表付款",
  Tabulate:"制表",
  By:"经过：",
  Payments_By:"付款方式",
  Timesheets_And_Schedules:"时间表和时间表",
  Both:"两个都",
  Items:"项目",
  Add_Payees:"添加收款人",
  Add_Account:"新增帐户",
  Enable_Account:"启用帐户",
  Enable_Payroll:"启用薪资",
  Print_Payroll:"打印工资单",
  No_Payrolls:"没有工资单",
  No_Payroll_Word:"创建您的第一个 <a routerLink='/folder/Payroll/New'>工资单</a>。",
  View_more:"查看更多",
  Less:"较少的",
  Add_Payroll:"添加工资单",
  Select_Payroll:"选择薪资",
  About_Your_Salary:"关于你的薪水",
  Add_Salaries:"添加工资",
  Add_Salary:"加工资",
  Salaries:"工资",
  No_Salaries:"没有薪水",
  No_Salaries_Word:"添加第一个 <a routerLink='/folder/Salary/New'>薪水</a>。",
  Select_Salaries:"选择工资",
  New_Salary:"新工资",
  Salary_Name:"工资标识符",
  Enable_Salary:"启用工资",
  Salary_Amount:"工资金额",
  New_Schedule:"新时间表",
  Schedule_Title:"时间表标题",
  Add_Address:"添加地址",
  Repeat:"重复",
  Design:"设计",
  Edit_Design:"编辑设计",
  Edit_this_Design:"编辑此设计",
  Repeat_Payment:"重复付款",
  Enable_Schedule:"启用时间表",
  Never:"绝不",
  Select_Schedule:"选择时间表",
  No_Schedules:"没有时间表",
  No_Schedules_Word:"创建第一个 <a routerLink='/folder/Schedule/New'>日程</a>。",
  New_Administrator:"新管理员",
  Username:"用户名",
  First_Name:"名",
  Last_Name:"姓",
  Add_an_Address:"添加地址",
  Payment_Limit:"每次付款限额",
  Total_Limit:"总限额",
  Select_Accounts:"选择账户",
  No_Administrators:"没有管理员",
  No_Administrators_Word:"创建第一个 <a routerLink='/folder/Administrator/New'>管理员帐户</a>。",
  New_Administrators_Word:"添加第一个<a routerLink='/folder/Administrator/New'>行政人员</a>",
  Cloud:"云",
  Backup:"备份",
  Enable_iCloud:"启用 iCloud",
  Enable_Google_Drive:"启用谷歌云端硬盘",
  Enable_Microsoft_OneDrive:"启用 Microsoft OneDrive",
  Automatically_Backup:"自动备份",
  FTP_Settings:"FTP设置",
  URL_File_Prompt:"请指定要导入的 .xls / .xlsx / .json 文件的位置：",
  URL_SQL_Prompt:"请指定要导入的 SQLite 文件的位置：",
  FTP_Backup:"FTP 备份",
  FTP_Import:"FTP导入",
  FTP:"文件传输协议",
  SFTP:"SFTP协议",
  Host:"主持人",
  Port:"港口",
  Path:"小路",
  Data_Path:"数据路径",
  Enable_FTP_Account:"启用 FTP 帐户",
  HostnameIP:"主机名",
  Password:"密码",
  Connection_Port:"连接端口",
  Enable_MySQL_Database:"启用 MySQL 数据库",
  Log:"日志",
  Reset:"重置",
  Erase:"擦除",
  Export:"出口",
  Database:"数据库",
  Upload_CSV:"上传 CSV",
  Download_CSV:"下载 CSV",
  SQL_Database:"网址",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL 备份",
  Internal_Notes:"内部笔记",
  Root_Path:"根路径",
  Select_Backup:"选择备份",
  Add_New_Backup:"添加新备份",
  First_Backup:"设置第一个备份...",
  Backups:"备份",
  Add_Backup:"添加备份",
  No_Backups:"找不到备份。",
  Select_Backup_Type:"选择您要设置的备份类型...",
  Backup_Type:"备份类型",
  FTP_Drive:"FTP驱动器",
  URL_Location:"网址",
  Apple_iCloud:"云端",
  Google_Drive:"驾驶",
  Microsoft_OneDrive:"驾驶",
  Import_Fields:"导入字段",
  Import_Fields_Word:"使用此部分选择<a routerLink='/folder/Holiday/New'>导入</a>数据。",
  Upload:"上传",
  Download:"下载",
  Download_JSON:"下载为 JSON 数据",
  Download_SQL:"下载为 SQL 文件",
  New_Bank:"新银行",
  New_Account:"新账户",
  New_Bank_Account:"新银行账户",
  Upload_Image:"上传图片",
  Bank_Name:"银行名",
  Bank_Address:"银行地址",
  Branch_Address:"分行地址",
  Address_on_Cheque:"地址",
  Cheque_Numbers:"检查号码",
  Cheque_Details:"检查详情",
  Bank_Logo:"银行标志",
  Cheque_ID:"检查身份证",
  Starting_Cheque_ID:"开始检查 ID",
  Transit_Number:"中转号码",
  Institution_Number:"机构编号",
  Designation_Number:"编号",
  Account_Number:"帐号",
  Limits:"限制",
  Default_Limits:"默认限制",
  Over_Limit:"超限",
  Under_Limit:"下限",
  Payroll_Limit:"工资限额",
  Enable_Bank_Account:"启用银行账户",
  Select_Account:"选择账户",
  No_Bank_Account:"没有银行账户",
  No_Bank_Account_Word:"添加第一个 <a routerLink='/folder/Accounts/New'>银行账户</a>。",
  Bank_Accounts:"银行账户",
  No_Accounts:"没有帐户",
  No_Accounts_Note:"添加第一个 <a routerLink='/folder/Accounts/New'>银行账户</a>。",
  Cheque_Designer:"检查设计师",
  Cheque_Design:"检查设计",
  Select_Design:"选择设计",
  Cheque_Designs:"检查设计",
  No_Cheque_Designs:"无检查设计",
  No_Cheque_Designs_Word:"创建您自己的<a routerLink='/folder/Settings/Cheque/Design/New'>支票设计</a>。",
  Set_Default:"设为默认",
  Default:"默认",
  Remove:"消除",
  Folder:"文件夹",
  Edit:"编辑",
  LoadingDots:"加载中...",
  Add_a_New_File:"添加一个 <a href='#' (click)='add()'>新文件</a> 到",
  this_folder:"这个文件夹",
  FTP_Backup_Settings:"FTP 备份设置",
  Secure_File_Transfer:"安全文件传输",
  New_Holiday:"新假期",
  Add_Holiday:"添加假期",
  Holiday_Name:"节日名称",
  Additional_Pay:"附加费",
  Enable_Holiday:"启用假期",
  Select_Holidays:"选择假期",
  No_Holidays:"没有假期",
  No_Holidays_Word:"添加第一个<a routerLink='/folder/Holiday/New'>公共假期</a>。",
  New_Import:"新导入",
  Import_Data:"导入数据",
  Import_Data_Word:"选择您选择的文件类型或上传方法。<br />上传支持的文件后，您将能够选择文件中导入的字段对应于应用程序中的任何参数。",
  Import_File:"导入文件",
  Link_To_File:"链接到文件",
  Select_File:"选择文件",
  New_Moderator:"新版主",
  Allow_Moderation:"允许审核",
  Create_Paycheques:"创建薪水支票",
  Edit_Paycheques_and_Data:"编辑薪水支票和数据",
  Destroy_Data_and_Paycheques:"销毁数据和薪水支票",
  Bonus_Percentage:"工资支票百分比",
  Fixed_Amount:"固定值",
  Select_Moderator:"选择版主",
  No_Moderators:"没有版主",
  Moderators:"主持人",
  Moderator_Account:"创建第一个 <a routerLink='/folder/Administrator/New'>版主帐户</a>。",
  No_Moderators_Word:"添加第一个 <a routerLink='/folder/Administrator/New'>版主</a>。",
  Defaults:"默认值",
  Provide_Us_Info:"提供资料",
  Setup_Your_Printer:"设置您的打印机",
  Your_Company:"关于贵公司",
  Company_Name:"公司名称",
  Currency:"货币",
  Default_Currency:"默认货币",
  Base_Monthly_Income:"月收入",
  Protection:"保护",
  App_Protection:"应用保护",
  PIN_Code_Protection:"PIN码保护",
  App_Protection_Word:"启用有助于保护您帐户的安全方法。",
  PIN_Code:"PIN码",
  Change_PIN:"更改密码",
  New_Password:"新密码",
  Geofence_Protection:"地理围栏保护",
  Geofence_Area:"设置区域",
  Distance:"距离",
  Setup_Now:"立即设置",
  Mile:"英里",
  Km:"公里",
  m:"米",
  Facial_Recognition:"面部识别",
  Face:"脸",
  Setup:"设置",
  Label:"标签",
  Password_Protection:"密码保护",
  Modify_Password:"修改密码",
  New_Tax_Entry:"新的税收条目",
  New_Tax:"新税",
  Tax_Label:"税标",
  Perpetually_Enabled:"永久启用",
  Select_Taxes:"选择税种",
  Tax_Deductions:"减税",
  No_Tax_Deductions:"没有税收减免",
  No_Tax_Deductions_Word:"添加第一个 <a routerLink='/folder/Tax/New'>税</a> 扣除。",
  New_Timer:"新定时器",
  Start:"开始",
  Stop:"停止",
  Start_Timer:"开始定时器",
  Stop_Timer:"停止定时器",
  Timer_Active:"定时器激活",
  Timer:"定时器：",
  Timer_Running:"定时器：（运行）",
  Save_Timer:"保存定时器",
  New_Timesheet:"新时间表",
  Select_Timesheets:"选择时间表",
  Work_Notes:"工作笔记",
  Entry_Title:"作品名称",
  No_Timesheets:"没有时间表",
  No_Timesheets_Word:"添加第一个 <a routerLink='/folder/Timesheet/New'>时间表</a>。",
  Timesheet_Submitted:"时间表已提交",
  Timesheet_Congrats:"恭喜！您的时间表已成功提交。谢谢你！",
  Timesheet_Copy:"要接收您的文件副本，请向我们提供您的电子邮件地址和/或手机号码。",
  Submit:"提交",
  Allow_Notifications:"允许通知",
  Untitled_Entry:"新条目",
  Untitled_Bank:"无题银行",
  Timesheet_Entry:"时间表条目",
  Work_Description:"工作描述",
  Enable_Timesheet:"启用时间表",
  Submit_Timesheet:"提交时间表",
  Vacation:"假期",
  New_Vacation:"新假期",
  Vacation_Name:"假期名称",
  Paid_Vacation:"带薪休假",
  Vacation_Pay:"假期工资",
  Enable_Vacation:"启用休假",
  Select_Vacations:"选择假期",
  No_Vacations:"没有假期",
  No_Vacations_Word:"创建第一个 <a routerLink='/folder/Vacation/New'>假期</a> 条目。",
  Payroll_Schedule:"薪资表",
  Next_Payroll:"下一个工资单：",
  Upcoming_Payroll:"即将到来的工资单",
  No_Upcoming_Payroll:"没有即将到来的工资单",
  Address_Book:"地址簿",
  Archived_Documents:"归档文件",
  Dev_Mode:"开发模式下的应用",
  Administrators:"管理员",
  Privacy:"隐私",
  None:"没有任何",
  Select_Signature:"选择签名",
  No_Signatures:"没有签名",
  No_Signatures_Word:"添加第一个 <a routerLink='/folder/Signature/New'>签名</a>。",
  Repeat_Indefinitely:"无限重复",
  Sign:"符号",
  Sign_Here:"在这里签名",
  Date_Signed:"签署日期",
  Signature_Pad:"签名板",
  Account_Holder:"帐户持有人",
  Account_Properties:"帐户属性",
  Name_On_Cheque:"支票上的名字",
  Server_Hostname:"服务器主机名/IP",
  Printers:"打印机",
  No_Printers:"没有打印机",
  Printer_Exists:"已存在同名打印机。",
  No_Printers_Word:"添加第一个 <a routerLink='/folder/Printer/New'>打印机</a>。",
  No_Printer_Alert:"未定义打印机。您想安装打印机吗？",
  Add_Printer:"添加打印机",
  New_Printer:"新打印机",
  Printer_Hostname:"打印机主机名/IP",
  Printer_Label:"打印机标签",
  Printer_Protocol:"打印机协议",
  Protocol:"协议",
  Email_Print:"电子邮件",
  AirPrint:"空气打印",
  IPP:"独立生产计划",
  LPD:"液化石油气",
  HPJetDirect:"插座",
  Print_Job:"打印作业",
  Printed:"打印",
  Not_Printed:"未打印",
  Print_Jobs:"打印作业",
  Print_Queue:"打印队列",
  No_Print_Jobs:"无打印作业",
  No_Prints:"创建一个新的 <a routerLink='/folder/Cheque/New'>查看</a> 进行打印。",
  Prints:"印刷",
  Find_Printer:"查找打印机",
  Find_AirPrint_Devices:"查找 AirPrint 设备",
  Select_Printer:"选择打印机",
  System_UI:"系统界面",
  Printer:"打印机",
  Status:"地位",
  Preview:"预览",
  Enable_Print_Job:"启用打印作业",
  Queue_Weight:"队列权重",
  Unlimited:"无限",
  Show_Advanced_Printer_Options:"显示高级打印机选项",
  Advanced:"先进的",
  Location:"地点",
  Note:"笔记",
  Queue_Name:"队列名称",
  Pages_Printed_Limit:"打印页数限制",
  MultiPage_Idle_Time:"多页等待时间（秒）",
  Page_Count_Limit:"页数限制",
  Page_Orientation:"页面方向",
  Portrait:"肖像",
  Landscape:"景观",
  Duplex:"双工",
  Double_Sided_Printing:"两面性",
  Duplex_Mode:"双工模式",
  Duplex_Short:"短的",
  Duplex_Long:"长的",
  Duplex_None:"没有任何",
  Color_And_Quality:"颜色和质量",
  Monochrome:"单色",
  Photo_Quality_Prints:"照片质量打印",
  Printer_Email:"打印机电子邮件",
  Automatically_Downsize:"自动缩小",
  Paper:"纸",
  Paper_Name:"论文名称",
  Paper_Width:"纸张宽度",
  Paper_Height:"纸张高度",
  Paper_Autocut_Length:"自动切纸长度",
  Margins:"边距",
  Page_Margins:"页边距",
  Page_Margin_Top:"首页边距",
  Page_Margin_Right:"右页边距",
  Page_Margin_Bottom:"底部页边距",
  Page_Margin_Left:"左页边距",
  Add_Employees:"添加员工",
  Header:"标头",
  Print_A_Page_Header:"打印页眉",
  Header_Label:"标头标签",
  Header_Page_Index:"标题页索引",
  Header_Font_Name:"标题字体",
  Select_Font:"选择字体",
  Font_Selector:"字体选择器",
  Header_Font_Size:"标题字体",
  Header_Bold:"标题粗体",
  Header_Italic:"标题斜体",
  Header_Alignment:"标题对齐",
  Left:"左边",
  Center:"中心",
  Right:"正确的",
  Justified:"合理的",
  Header_Font_Color:"标题颜色",
  Select_Color:"选择颜色",
  Footer:"页脚",
  Print_A_Page_Footer:"打印页脚",
  Footer_Label:"页脚标签",
  Footer_Page_Index:"页脚索引",
  Footer_Font_Name:"页脚字体",
  Fonts:"字体",
  Done:"完毕",
  Select_Fonts:"选择字体",
  Footer_Font_Size:"页脚大小",
  Footer_Bold:"页脚粗体",
  Footer_Italic:"页脚斜体",
  Footer_Alignment:"页脚对齐",
  Footer_Font_Color:"页脚颜色",
  Page_Copies:"页数",
  Enable_Printer:"启用打印机",
  Remote_Logging:"远程记录",
  Log_Server:"日志服务器",
  Encryption:"加密",
  Random_Key:"随机密钥",
  Encryption_Key:"加密密钥",
  Cheques_Webserver:"自定义数据库",
  Learn_How:"学习如何",
  Signature:"签名",
  Default_Printer_Unit:"英寸/厘米/毫米/（点）",
  View_Signature:"查看签名",
  Printed_Signature:"印刷签名",
  Digitally_Sign:"数字签名",
  Digital_Signature:"电子签名",
  Add_Signature:"添加签名",
  Add_Your_Signature:"添加您的签名",
  Enable_Signature:"启用签名",
  Digitally_Signed:"数字签名",
  Insert_Error:"由于数据库问题无法保存支票。",
  Delete_Confirmation:"您确定要删除此信息吗？",
  Discard_Confirmation:"您确定要放弃此信息吗？",
  Discard_Bank_Confirmation:"您确定要放弃此帐户吗？",
  Discard_Printer_Confirmation:"您确定要丢弃这台打印机吗？",
  Delete_Bonus_Confirmation:"您确定要删除此奖励吗？",
  Delete_Invoice_Confirmation:"您确定要删除此发票吗？",
  Generated_Cheque:"生成支票",
  Generated_Invoice:"生成的发票",
  Schedule_Start_Time:"计划开始",
  Schedule_End_Time:"计划结束",
  New_Call:"新来电",
  No_Contacts:"没有联系人",
  No_Contacts_Word:"管理员、版主、员工和收款人显示为联系人。",
  PDF_Subject:"财政",
  PDF_Keywords:"工资支票 PDF 支票支票",
  Printer_Setup:"打印机设置",
  Printer_Selection:"打印机选择",
  New_Fax:"新传真",
  New_Fax_Message:"新传真信息",
  Fax_Machine:"传真机",
  Fax_Name:"传真名称",
  Fax_Email:"传真电邮",
  Fax_Number:"传真号",
  Contents:"内容",
  Fax_Body:"页面正文",
  Header_Word:"标头：",
  Header_Text:"标题文本",
  Include_Header:"包括标题",
  Include_Footer:"包括页脚",
  Footer_Word:"页脚：",
  Footer_Text:"页脚文本",
  Attach_a_Cheque:"附上支票",
  Add_Deduction:"加减",
  Enable_Fax:"发送传真",
  Select_Fax:"选择传真",
  No_Faxes:"没有传真",
  Faxes:"传真",
  Save_and_Send:"发送传真",
  Save_and_Pay:"保存并支付",
  WARNING:"警告：",
  Remember:"记住",
  Printing:"印刷",
  Printing_Complete:"打印完成！\n\n",
  of:"的",
  There_Were:"有",
  Successful_Prints:"成功的打印和",
  Unsuccessful_Prints:"不成功的打印。",
  PrinterError:"对不起！有一个错误。",
  Printing_:"印刷...",
  PrinterSuccess:"文档打印成功。",
  PrintersSuccess:"文档打印成功。",
  New_Message:"新消息",
  New_Messages:"新消息",
  Read_Message:"阅读消息",
  Write_Message:"写信息",
  Send_Message:"发信息",
  Subject:"主题",
  Message:"信息",
  Writing:"写作...",
  Send:"发送",
  Set_Date:"设置日期",
  Set_Time:"设置时间",
  Recieve:"收到",
  Set_as_Default:"设为默认",
  Default_Account:"默认帐户",
  Default_Design:"默认设计",
  Multiple_Cheques:"三检",
  Account_Mode:"账户模式",
  Multiple_Cheques_Description:"每页三张支票。",
  Check_and_Table:"检查表",
  Check_including_Table:"核对会计表。",
  Check_Mailer:"检查邮件",
  Check_Mailer_Window:"检查地址窗口。",
  DocuGard_Table_Top:"大支票和桌子",
  DocuGard_Table_Middle:"大支票和桌子（中）",
  DocuGard_Table_Bottom:"大支票和桌子（底部）",
  DocuGard_Mailer_Top:"大支票邮寄",
  DocuGard_Mailer_Middle:"大型支票邮寄袋（中）",
  DocuGard_Mailer_Bottom:"大型支票邮寄箱（底部）",
  DocuGard_Three_Cheques:"三张大支票",
  DocuGard_Cheque_Top:"单张大额支票",
  DocuGard_Cheque_Middle:"大格子（中）",
  DocuGard_Cheque_Bottom:"大格子（底部）",
  DocuGard_Three_Cheques_Window:"一页上三张支票。",
  DocuGard_Table_Top_Window:"检查和收入表。",
  DocuGard_Table_Middle_Window:"检查和收入表。",
  DocuGard_Table_Bottom_Window:"检查和收入表。",
  DocuGard_Mailer_Top_Window:"支票、表格和地址。",
  DocuGard_Mailer_Middle_Window:"支票、表格和地址。",
  DocuGard_Mailer_Bottom_Window:"支票、表格和地址。",
  DocuGard_Cheque_Top_Window:"检查安全纸。",
  DocuGard_Cheque_Middle_Window:"检查安全纸。",
  DocuGard_Cheque_Bottom_Window:"检查安全纸。",
  Basic_Cheque:"单支票",
  Basic_Cheque_Print:"打印一张支票。",
  Error_Setting_As_Paid:"错误设置为付费",
  Add_Attachment:"添加附件",
  PrinterUnavailable:"打印机不可用",
  CreditCardComponent:"牌",
  PaypalComponent:"贝宝",
  InteracComponent:"互动",
  BitcoinComponent:"比特币",
  New_Deposit:"新存款",
  Deposits:"存款",
  No_Deposits:"没有存款",
  Credit_Card_Deposit:"信用卡",
  New_Credit_Card_Deposit_Message:"信用卡存款",
  New_BitCoin_Deposit:"比特币",
  New_BitCoin_Deposit_Message:"比特币存款",
  New_Interac_Deposit:"互动",
  New_Interac_Deposit_Message:"交互传输",
  Payments_Limit:"支付限额",
  No_Payment_Limit:"无支付限额",
  PayPal_Deposit:"贝宝",
  PayPal_Deposit_Message:"贝宝存款",
  No_Deposits_Word:"添加第一家公司<a routerLink='/folder/Deposit/New'>订金</a>。",
  No_Documents_Specified:"没有指定要打印的文档",
  No_Printers_Added:"未找到打印机。转到“设置”>“打印机”以添加一台。",
  DB_Erase_Prompt:"彻底擦除整个数据库？警告：您将丢失所有存储的信息！",
  Console_Warning:"不要将任何文本粘贴到此控制台中。您可能会使自己和/或您的公司面临严重风险。",
  No_Faxes_Word:"创建第一个 <a routerLink='/folder/Fax/New'>传真</a>。",
  Cheque_Delete_Confirmation:"您确定要删除这张支票吗？",
  Design_Delete_Confirmation:"您确定要删除此设计吗？",
  Cheque_Pay_Confirmation:"将这张支票标记为已付？它不会出现在打印队列中。",
  Payment_Pay_Confirmation:"将此付款标记为已付款？它不会出现在检查队列中。",
  Delete_Deduction_Confirmation:"您确定要删除此扣除吗？",
  Delete_Job_Confirmation:"您确定要删除此作业吗？",
  Delete_Timesheet_Confirmation:"您确定要删除此时间表吗？",
  Delete_Schedule_Confirmation:"您确定要删除此计划吗？",
  Delete_Setting_Confirmation:"您确定要重置此设置吗？",
  Delete_Fax_Confirmation:"您确定要删除此传真吗？",
  Delete_File_Confirmation:"您确定要删除此文件吗？",
  Delete_Vacation_Confirmation:"您确定要删除这个假期吗？",
  Delete_Printer_Confirmation:"您确定要删除这台打印机吗？",
  Remove_Design_Confirmation:"您确定要删除此设计吗？",
  Delete_Payroll_Confirmation:"您确定要删除此工资单吗？",
  Send_Fax_Email_Confirmation:"您要传真和通过电子邮件发送这些文件吗？",
  Send_Email_Confirmation:"您要通过电子邮件发送此文档吗？",
  Send_Fax_Confirmation:"您要传真这份文件吗？",
  Error_Generating_PDF:"对不起。生成此文档时出错。",
  PDF_Error_Saving_Image:"对不起。保存此文档的 PDF 图像时出错。",
  Test_Printer_Confirmation:"您想在此打印机上打印测试页吗？",
  Save_Timesheet_Prompt:"请添加“标题”或按“开始计时器”进行保存。",
  Remove_Geofence_Prompt:"您确定要删除此地理围栏的位置吗？",
  Delete_Employee_Confirmation:"你确定你要删除",
  Fire_Employee_Confirmation:"你确定要开火吗"
}