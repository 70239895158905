export const Uk = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  Copyright:"Copyright &copy; 2023 рік",
  black:"чорний",
  green:"Зелений",
  gold:"золото",
  blue:"Синій",
  brown:"Коричневий",
  purple:"Фіолетовий",
  pink:"Рожевий",
  red:"Червоний",
  Swatches:"Зразки",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Відтінок",
  Saturation:"насиченість",
  Lightness:"Легкість",
  Upgrade_To_Pro:"Оновлення до Pro",
  AllFeaturesInclude:"Усі функції включають:",
  PrintUnlimitedCheques:"Друкуйте необмежену кількість чеків",
  PremiumChequeDesigns:"Дизайн преміум-чек",
  ManageUnlimitedEmployees:"Керуйте необмеженою кількістю співробітників",
  AddUnlimitedPayees:"Додайте необмежену кількість одержувачів",
  CreateUnlimitedPayrolls:"Створіть необмежену кількість заробітних плат",
  UnlimitedSchedulesandTimeSheets:"Необмежена кількість розкладів і табелів",
  BulkPayPalPayouts:"Масові виплати PayPal",
  UnlimitedBankAccounts:"Необмежена кількість банківських рахунків",
  ManageMultipleCompanies:"Керуйте кількома компаніями",
  TrackInsurancePolicies:"Відстежуйте страхові поліси",
  Bio_MetricProtection:"Біометричний захист",
  Geo_FenceLock_OutProtection:"Захист від блокування Geo-Fence",
  Multiple_Companies_Word:"Управління декількома компаніями недоступне без премії Checks.",
  PayPal_Payouts_Word:"Платежі PayPal вимкнено без премії за чеки.",
  PINProtection:"Захист PIN-кодом",
  PasswordProtection:"Захист паролем",
  May_Require_Approval:"Може вимагати схвалення.",
  Subscribe:"Підпишіться",
  Billed:"Виставлений рахунок",
  Up:"вгору",
  Down:"вниз",
  Positioning:"Позиціонування",
  Marker:"Маркер",
  Drag_Marker:"Маркер перетягування",
  Tagline:"Друкуйте чеки та складайте таблиці із заробітної плати",
  Marker_Word:"Використовуйте маркери для визначення розміру елемента.",
  Pinch_Zoom:"Збільшення щипками",
  Pinch_Word:"Зведіть пальці, щоб збільшити елемент.",
  Drag:"Перетягнути",
  Drag_Word:"Перетягуйте елементи пальцем.",
  subscription_alias_word:"Автоматичне поновлення підписки",
  premium_alias_word:"Пакет одноразового оновлення",
  print_alias_word:"Роздрукуйте індивідуальні чеки",
  mode_alias_word:"Режим",
  Pro:"Pro",
  Pro_word:"Потрібна версія Pro.",
  Cant_Delete_Default_Company_Word:"На жаль, ви не можете видалити компанію за замовчуванням.",
  Reinsert_Default_Designs:"Повторно вставте шаблони за замовчуванням",
  Reinsert_Default_Designs_word:"Хочете повторно вставити шаблони за замовчуванням?",
  Subscription_Active_Disable_Word:"Ця підписка активна. Чи бажаєте ви скасувати цю підписку на чеки?",
  Company_Logo:"Логотип компанії",
  ZERO_:"НУЛЬ",
  Disclaimer:"Відмова від відповідальності",
  Privacy_Policy:"Політика конфіденційності",
  EULA:"Перевіряє EULA",
  Terms_Of_Service:"Умови використання",
  Terms_Of_Use:"Умови використання",
  Refunds:"Політика відшкодування",
  Single_Print:"1 перевірка",
  Two_Prints:"2 Перевірки",
  Five_Prints:"5 Перевірки",
  Ten_Prints:"10 Перевірки",
  Fifteen_Prints:"15 Перевірки",
  Twenty_Prints:"20 перевірок",
  Thirty_Prints:"30 чеків",
  Image_Added:"Зображення додано",
  Image_Preview:"Попередній перегляд зображення",
  No_Image_Was_Selected:"Жодне зображення не вибрано.",
  Cheques_Unlimited:"Чеки необмежені",
  _Subscription:"Підписка",
  Subscription:"Чеки - 1 місяць",
  Two_Month_Subscription:"Чеки - 2 місяці",
  Three_Month_Subscription:"Чеки - 3 міс",
  Six_Month_Subscription:"Чеки - 6 міс",
  Twelve_Month_Subscription:"Чеки - 12 міс",
  Cheques_Are_Available:"Чеки доступні для друку.",
  Upgrade_Required_Two:"Виберіть пакет і двічі торкніться кнопки ціни, щоб розпочати покупку. Друкуйте повноколірні чеки професійного вигляду за лічені секунди.",
  Month:"місяць",
  Due:"Термін:",
  Expires:"Термін дії:",
  Subscription_Active:"Підписка активна",
  Subscription_Inactive:"Підписка неактивна",
  Purchase_Additional_Cheques:"Придбати додаткові чеки?",
  Printable_Cheque:"Чек для друку",
  Printable_Cheques:"Роздруковані чеки",
  Printable_Cheque_Word:"чек доступний на вашому рахунку.",
  Printable_Cheques_Word:"чеки доступні на вашому рахунку.",
  Max_Amount_Message:"Вказана вами сума перевищила максимальну суму, встановлену для цієї системи:",
  Terms_Required_Word:"Ви повинні погодитися з цією угодою, перш ніж продовжувати використовувати чеки.",
  Subscriptions:"Підписки",
  Product_Upgrades:"Оновлення",
  Mailed_Out_Cheques:"Чеки, що надсилаються поштою",
  Enter_A_Company_Name:"Будь ласка, введіть назву компанії.",
  Single_Cheques:"Одиничні перевірки",
  Cheque_Golden:"Золотий чек",
  Cheque_Golden_Window:"Дизайн золотої клітки.",
  Cheque_Green:"Зелена галочка",
  Cheque_Green_Window:"Зелений чек дизайн.",
  Cheque_Red:"Червоний чек",
  Cheque_Red_Window:"Червона перевірка дизайн.",
  Cheque_Yellow:"Жовтий чек",
  Cheque_Yellow_Window:"Жовтий чек дизайн.",
  Cheque_Statue_of_Liberty:"Статуя Свободи",
  Cheque_Statue_of_Liberty_Window:"Дизайн перевірки статуї свободи.",
  Cheque_Green_Wave:"Зелена хвиля",
  Cheque_Green_Wave_Window:"Зелений чек дизайн.",
  Cheque_Golden_Weave:"Золоте переплетення",
  Cheque_Golden_Weave_Window:"Елегантний дизайн у золоту клітинку.",
  Cheque_Green_Sun:"Зелене сонечко",
  Cheque_Green_Sun_Window:"Глибокий і заспокійливий дизайн в клітинку.",
  Cheque_Blue_Checkers:"Сині шашки",
  Cheque_Blue_Checkers_Window:"Блакитна перевірка дизайн.",
  Cashiers_Check:"Касовий чек",
  Cashiers_Check_Window:"Шаблон стилю касового чека.",
  Cheque_Aqua_Checkers:"Аква шашки",
  Cheque_Aqua_Checkers_Window:"Аква чек дизайн.",
  Cheque_Golden_Checkers:"Золоті шашки",
  Cheque_Golden_Checkers_Window:"Дизайн золотої клітки.",
  Upgrade_Unavailable:"Оновлення недоступні",
  Bank_Code_Protection:"Захист банківського номера",
  Bank_Code_Protection_Word:"Захистіть свої банківські номери від використання в цій програмі, яка працює на іншому пристрої або для іншого користувача. Ця дія є НЕЗВОРТНОЮ. Продовжити?",
  Bank_Code_Protection_Blocked_Word:"Банківські номери, які ви намагаєтеся використовувати, зарезервовані для іншого користувача або пристрою.",
  Bank_Code_Protection_Error_Word:"Не вдалося перевірити номер. Підключіться до Інтернету та спробуйте знову додати цей банківський рахунок.",
  Bank_Code_Protection_Set_Error_Word:"Для захисту банківського номера потрібне підключення до Інтернету. Підключіться та повторіть спробу.",
  Upgrade_Unavailable_Word:"Вибачте, у нас виникли проблеми з перевіркою. Підписки та оновлення до Checks наразі недоступні для покупки у вашому регіоні.",
  PayPal_Payment_Preview:"Попередній перегляд платежів PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Виберіть PayPal",
  PayPal_Applications:"Програми PayPal",
  Purchase_With_Apple_Pay:"Купуйте за допомогою Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Компанії",
  Insurance:"страхування",
  New_PayPal:"Новий PayPal",
  Pay_By:"Оплатити",
  Insure:"Страхувати",
  Miles:"Майлз",
  Payment_Method:"Спосіб оплати",
  Select_Policies:"Виберіть Політика",
  Policies:"політики",
  Policy:"політика",
  No_PayPal_Account:"Немає рахунку PayPal",
  No_Policies:"Без страхових полісів",
  New_Policy:"Нова політика",
  PayPal_Payment:"Оплата PayPal",
  PayPal_Payments:"Платежі PayPal",
  No_Payment_Selected:"Платіж не вибрано",
  Sending_Payment_Word:"Зачекайте... Цей платіж надсилається.",
  Sending_Payments_Word:"Будь ласка, зачекайте... Платежі надсилаються.",
  No_Payment_Selected_PayPal:"Не вибрано жодного <a routerLink='/folder/Payments'>платіжу PayPal</a> для надсилання.",
  Payment_Sent:"Платіж надіслано",
  PayPal_Payment_Sent:"Цей платіж було надіслано через PayPal.",
  Copay:"Доплата",
  Dead:"Мертвий",
  Alive:"Живий",
  Not_Dead:"Не мертвий",
  Unclaimed:"Незатребуваний",
  Attempted:"Спроба",
  Deceased:"Покійний",
  Claimed:"Заявлено",
  Unpaid:"Неоплачений",
  Sending_Payment:"Відправлення платежу",
  Sending_Payments:"Відправлення платежів",
  Send_PayPal_Confirmation:"Ви бажаєте надіслати цю трансакцію через PayPal?",
  Send_PayPal_Confirmation_Word:"Натисніть зелену кнопку, щоб надіслати цю транзакцію.",
  Save_Payment_As_Unpaid:"Зберегти цей платіж як неоплачений?",
  Payment_Pay_Confirmation_PayPal:"Зберегти цей платіж як оплачений?",
  No_Policies_Word:"Додайте перший <a routerLink='/folder/Postage/New'>Страховий поліс</a> зараз.",
  Timesheet_Multiple_Delete_Confirmation:"Ви впевнені, що бажаєте видалити кілька табелів?",
  Select_Multiple_Timesheets_Prompt:"Табелі не вибрано. Виберіть принаймні один табель.",
  Select_Multiple_Policies_Prompt:"Політики не вибрано. Виберіть хоча б один страховий поліс.",
  Policies_Multiple_Delete_Confirmation:"Ви впевнені, що бажаєте видалити кілька політик?",
  Company:"Компанія",
  Add_Company:"Додати компанію",
  New_Company:"Додати компанію",
  No_Companies:"Немає компаній",
  Enable_Company:"Увімкнути компанію",
  Select_Company:"Виберіть Компанія",
  The_Default_Company:"Мітка компанії за умовчанням.",
  Manage_Companies:"Керуйте компаніями",
  No_Companies_Word:"У перевірках використовуватиметься компанія за умовчанням.<br />Додайте <a routerLink='/folder/Company/New'>першу компанію</a>.",
  Default_Company:"Компанія за замовчуванням",
  Cheques_Unlimited_Word:"Checks Unlimited дозволяє друкувати скільки завгодно чеків.",
  Cheques_Subscription_Word:"Підписка на чеки дозволяє друкувати скільки завгодно чеків.",
  You_Own_This_Product:"Ви володієте цим продуктом.",
  Your_Subscription_is_Active:"Ваша підписка активна.",
  Active_Products:"Активовані продукти",
  Purchase_Confirmation:"Купівля",
  Purchase_Cheques:"Купівля чеків",
  Restore_Purchase:"Відновити покупки",
  Erase_Purchase:"Видалити покупки",
  Successfully_Purchased:"Успішно придбано",
  Enter_Your_Licence_Key:"Будь ласка, введіть свій ліцензійний ключ на цій сторінці, щоб активувати цей продукт.",
  Licence_Key:"Ліцензійний ключ",
  Enter_Licence_Key:"Введіть ліцензійний ключ",
  Purchased:"Придбаний",
  Enable_Feature:"Увімкнути функцію",
  Cancel_Subscription:"Скасувати підписку",
  Subscription_Removed:"Підписку видалено",
  Select_Active_Subscription:"Виберіть активну підписку, щоб змінити її.",
  Remove_Subscription_Word:"Ви впевнені, що бажаєте скасувати цю підписку?",
  Delete_Company_Confirmation:"Ви впевнені, що хочете видалити всю цю компанію? УВАГА: ви втратите всю збережену інформацію!",
  Delete_Default_Company_Word:"Ви не можете видалити компанію за замовчуванням. Бажаєте скинути програму та повернути її до стандартного стану? УВАГА: ви втратите всю збережену інформацію!",
  Console_Warning_2:"Не обробляйте валюту за допомогою цієї програми, яка зараз не є вашою.",
  Terms_and_Conditions:"Правила та умови",
  and_the:"і",
  for:"для",
  Please_Read_Word:"Прочитайте та погодьтеся з",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Не вдалося знайти деякі курси конвертації валют. Підключіться до Інтернету.",
  Free:"безкоштовно",
  DB_Erase_Prompt_2:"Повністю стерти всю базу даних розробника? УВАГА: ви втратите всю інформацію про ПОКУПКУ та ПІДПИСКУ!",
  Successfully_Imported:"Успішно імпортовано",
  Select_Postage:"Виберіть Поштові витрати",
  No_Postage:"Без поштових марок",
  No_Paid_Postage_Word:"Додайте першу марку <a routerLink='/folder/Postage/New'>Paid Postage</a>.",
  Trial_Complete:"Випробування завершено",
  Please_Upgrade:"Оновіть чеки, щоб продовжити друк.",
  Aa:"Аа",
  Color:"колір",
  Font:"Шрифт",
  Guide:"Гід",
  Guides:"Посібники",
  Image:"Зображення",
  Zoom:"Збільшити",
  Logo:"логотип",
  Bank:"банк",
  MICR:"МІКР",
  Total:"Всього",
  Cancel:"Скасувати",
  Confirm:"Підтвердити",
  Method:"метод",
  Biometric_Security:"Біометрична безпека",
  Please_Login_To_Continue:"Увійдіть, щоб продовжити.",
  Complete:"Повний",
  Sign_Up:"Зареєструватися",
  Error:"Помилка",
  Biometrics:"Біометрія",
  Percent:"Відсоток",
  Zero_Percent:"0%",
  Top_Margin:"Верхнє поле",
  Bottom_Margin:"Нижнє поле",
  Left_Margin:"Ліве поле",
  Right_Margin:"Праве поле",
  MICR_Margin:"Маржа MICR",
  Table_Margin:"Поле таблиці",
  Address_Margin:"Маржа адреси",
  Percentage_:"Відсоток",
  Vacation_Title:"Титул відпустки",
  Use_PIN:"Використовуйте PIN-код",
  Loading__:"Завантаження...",
  Design_Title:"Назва дизайну",
  Authorize:"Авторизувати",
  Key:"ключ",
  Public_Key:"відкритий ключ",
  Private_Key:"Приватний ключ",
  Authenticate:"Автентифікувати",
  Last_Payroll:"Остання заробітна плата",
  Last_Calculation:"Останній розрахунок",
  Authorized:"Авторизований",
  Geo_Authorized:"Геолокація: авторизовано",
  Not_Authorized:"Не авторизовано",
  Authorization_Complete:"Авторизація завершена",
  Geolocation_Authorization:"Авторизація геолокації",
  Out_of_Bounds:"Поза меж",
  Cant_Delete_Default_Design:"Неможливо видалити дизайн за замовчуванням.",
  Unauthorized_Out_of_Bounds:"Несанкціоновано: поза межами",
  Biometric_Authorization:"Біометрична авторизація",
  Locating_Please_Wait:"Пошук, зачекайте...",
  Test_Biometrics:"Тест Біометрія",
  Cheque_Margins:"Перевірте поля",
  Percentage_Full_Error:"Поле відсотка не може перевищувати 100% відсотків.",
  No_Payroll_Text:"Додайте <a routerLink='/folder/Employee/New'>співробітника</a> або <a routerLink='/folder/Payee/New'>одержувача</a> та <a routerLink='/folder/Schedule /New'>Розклад</a>.",
  Design_Saved:"Дизайн збережено",
  Default_Design_Selected:"Вибрано дизайн за замовчуванням",
  Partial_Import:"Частковий імпорт",
  Partial_Export:"Частковий експорт",
  Entire_Import:"Весь імпорт",
  Entire_Export:"Весь експорт",
  Existing_Password:"Введіть існуючий пароль:",
  Existing_PIN:"Будь ласка, введіть ваш існуючий PIN-код:",
  Pin_Change_Header:"Підтвердження PIN-коду",
  Pin_Change_SubHeader:"Введіть свій старий PIN-код, щоб підтвердити зміну.",
  Pass_Change_Header:"Підтвердження паролю",
  Pass_Change_SubHeader:"Введіть свій старий пароль, щоб підтвердити зміну.",
  PIN_Enter_Message:"Введіть PIN-код для підтвердження.",
  Password_Enter_Message:"Введіть пароль для підтвердження.",
  Pin_Updated_Success:"PIN-код успішно оновлено!",
  Pin_Updated_Fail:"Не вдалося оновити PIN-код.",
  Pass_Updated_Success:"Пароль успішно оновлено.",
  Pass_Updated_Fail:"Не вдалося оновити пароль.",
  Preview_Payment:"Попередній платіж",
  Preview_Payroll:"Попередній перегляд заробітної плати",
  No_Payments_Created:"Не знайдено створених платежів.",
  Payment_Preview:"Попередній перегляд платежу",
  Enable_Payee:"Увімкнути одержувача",
  Rendered:"Візуалізовано",
  No_Email:"Немає електронної пошти",
  Setup_Cheques:"Перевірки налаштувань",
  name:"Ім'я",
  address:"Адреса",
  address_2:"Адресний рядок 2",
  city:"Місто",
  province:"Провінція",
  postal_code:"Поштовий індекс",
  country:"Країна",
  username:"Ім'я користувача",
  full_name:"Повне ім'я",
  birthday:"день народження",
  email:"Електронна пошта",
  phone:"Телефон",
  employees:"Співробітники",
  addresses:"Адреси",
  payment_amount_limit:"Ліміт суми платежу",
  total_limit:"Загальний ліміт",
  payees:"одержувачі",
  description:"опис",
  address_line_one:"Перший рядок адреси",
  address_line_two:"Другий рядок адреси",
  image:"Зображення",
  account_holder:"Власник рахунку",
  cheque_starting_id:"Перевірте стартовий ідентифікатор",
  transit_number:"Транзитний номер",
  institution_number:"Номер закладу",
  designation_number:"Номер позначення",
  account_number:"Номер рахунку",
  currency:"Валюта",
  signature:"Підпис",
  payment_payroll_limit:"Ліміт платежу",
  total_limi:"Загальний ліміт",
  date:"Дата",
  printed_memo:"Друкована пам'ятка",
  banks:"Банки",
  signature_image:"Зображення підпису",
  address_name:"Адреса Ім'я",
  notes:"Примітки",
  design:"Дизайн",
  title:"Назва",
  frequency:"Частота",
  fax:"Факс",
  salaries:"Заробітна плата",
  salary_ids:"Ідентифікатори зарплати",
  start_time:"Час початку",
  end_time:"Час закінчення",
  paid:"Платний",
  overtime_percentage:"Оплачений відсоток",
  overtime_amount:"Виплачена фіксована сума",
  first_name:"Ім'я",
  last_name:"Прізвище",
  moderation:"Модерація",
  create:"Створити",
  edit:"Редагувати",
  destroy:"Знищити",
  day_start_time:"Час_початку_дня",
  day_end_time:"День_кінець_часу",
  fullname:"Ім'я",
  time:"час",
  auto_send:"Автоматично надсилати",
  time_method:"Метод часу",
  schedules:"Розклади",
  indefinite_payroll_enabled:"Увімкнути на невизначений термін",
  amount:"Сума",
  repeat:"Повторіть",
  always_enabled:"Завжди ввімкнено",
  start_date:"Дата початку",
  end_date:"Дата закінчення",
  Cheque_Total:"Перевірити Total",
  Total_Amount:"Загальна кількість:",
  Amounts:"Суми:",
  Images:"Зображення",
  Files:"Файли",
  Previewing:"Попередній перегляд:",
  Insert:"Вставка",
  Preview_Import:"Попередній перегляд імпорту",
  Entry:"Вхід",
  Entries:"Записи",
  No_Entries:"Немає записів",
  Import_Type:"Тип імпорту",
  Select_Details:"Виберіть Деталі",
  Select_Payee:"Виберіть Одержувач",
  Enable_Holidays:"Увімкнути свята",
  Disable_Holidays:"Вимкнути свята",
  Wire_Transfer:"Банківський переказ",
  New_Export:"Новий експорт",
  Export_Data:"Експорт даних",
  Export_Data_Word:"Виберіть тип файлу для експорту та завантаження.",
  Export_File:"Експорт файлу",
  Mode:"Режим",
  Times:"Часи",
  Widgets:"Віджети",
  Slider:"повзунок",
  Set_Details:"Деталі набору",
  Select_Type:"Виберіть Тип",
  Display_Slider:"Повзунок дисплея",
  Dashboard_Slider:"Слайдер приладової панелі",
  Dashboard_Mode:"Режим приладової панелі",
  Show_Intro:"Показати вступ",
  Show_Payrolls:"Показати платіжні відомості",
  Show_Holidays:"Шоу Свята",
  Show_Invoices:"Показати рахунки-фактури",
  Show_Cheques:"Показати чеки",
  Enabled_Widgets:"Увімкнені віджети",
  Disabled_Widgets:"Вимкнені віджети",
  Colors:"Кольори",
  Barcodes:"Штрих-коди",
  View_Timers:"Перегляд таймерів",
  Gradients:"градієнти",
  No_Info:"Немає інформації",
  Disable:"Вимкнути",
  Show_Layer:"Показати шари",
  Hide_Layer:"Приховати шари",
  Text_Layer:"Текстові шари",
  Secondly:"По друге",
  Minutely:"щохвилини",
  nine_am:"9:00 ранку",
  five_pm:"17:00",
  Enable_Address:"Увімкнути адресу",
  Invalid_File_Type:"Вибачте, вибрано недійсний тип файлу. Підтримувані типи файлів:",
  Error_Updating_Entry:"На жаль, під час оновлення цього запису сталася помилка.",
  Schedule_Timing_Alert:"Помилка: час початку розкладу встановлено на значення після часу завершення.",
  Select_Multiple_Payments_Prompt:"Платежі не вибрано. Виберіть хоча б один платіж.",
  Select_Multiple_Cheques_Prompt:"Чек не вибрано. Виберіть хоча б один чек.",
  Select_Multiple_Items_Prompt:"Елементи не вибрано. Виберіть принаймні один елемент.",
  Paymemt_Multiple_Delete_Confirmation:"Ви впевнені, що бажаєте видалити кілька платежів?",
  Cheque_Multiple_Delete_Confirmation:"Ви впевнені, що бажаєте видалити кілька чеків?",
  Payee_Multiple_Delete_Confirmation:"Ви впевнені, що бажаєте видалити кількох одержувачів?",
  Employee_Multiple_Delete_Confirmation:"Ви впевнені, що хочете видалити кількох співробітників?",
  MICR_Warning:"Примітка. Деякі принтери та пристрої можуть неправильно відображати шрифт MICR.",
  Automatically_Send:"Автоматично надсилати",
  Type:"Тип",
  Payment_Type:"Тип оплати",
  Auto_Send:"Автоматичне надсилання",
  Automatically_Process:"Автоматично обробляти",
  Auto_Process:"Автоматичний процес",
  Image_Based:"На основі зображення",
  Font_Based:"На основі шрифту",
  Rerender:"Повторний рендер",
  Rendering:"Візуалізація",
  Render:"Файли",
  Top:"Топ",
  Middle:"Середній",
  Bottom:"Дно",
  Top_Left:"Зліва вгорі",
  Top_Center:"Верхній центр",
  Top_Right:"Верхній правий",
  Middle_Left:"Середній лівий",
  Middle_Center:"Середній центр",
  Middle_Right:"Середній правий",
  Bottom_Left:"Внизу зліва",
  Bottom_Center:"Нижній центр",
  Bottom_Right:"Внизу праворуч",
  Numbers:"Числа",
  Verified:"Перевірено",
  Paper_Size:"Розмір паперу",
  New_Device:"Новий пристрій",
  Add_Device:"Додати пристрій",
  Remove_Device:"Видалити пристрій",
  Delete_Device:"Видалити пристрій",
  Block_Device:"Блокувати пристрій",
  Block:"Блокувати",
  Unblock:"Розблокувати",
  Table:"Таблиця",
  Scan_Login_Code:"Сканувати код входу",
  Login_Code:"Код входу",
  Scan_Code:"Сканувати код",
  Scan_QR_Code:"Відскануйте QR-код",
  Select_All:"Вибрати все",
  Deselect_All:"Зняти вибір із усіх",
  Increase:"Збільшити",
  Decrease:"Зниження",
  Bold:"жирний",
  Text:"текст",
  Style:"Стиль",
  Italic:"Курсив",
  QR_Code:"QR-код",
  Barcode:"Штрих-код",
  Browse_Images:"Перегляньте зображення",
  Browse_Files:"Огляд файлів",
  Background_Image:"Фонове зображення",
  Logo_Image:"Зображення логотипу",
  Header_Image:"Зображення заголовка",
  Bank_Image:"Зображення банку",
  Cut_Lines:"Лінії розрізу",
  Background:"Фон",
  License:"Ліцензія",
  One_License:"1 Ліцензія:",
  Licensed:"Ліцензія на:",
  Not_Licensed:"Без ліцензії",
  Enter_Serial:"Введіть серійний номер",
  Serial_Key:"Серійний ключ",
  Serial:"Серійний",
  Product_Key:"Ключ продукту",
  Check_Product_Key:"Перевірте ключ продукту",
  Add_Product_Key:"Введіть ключ продукту",
  Verifying_Purchase:"Перевірка покупки...",
  Print_Envelope:"Роздрукувати конверт",
  Envelope:"конверт",
  Thank_You:"Дякую тобі!",
  Scale:"масштаб",
  Print_Scale:"Шкала друку",
  Borders:"Кордони",
  VOID:"НЕПУСТНИЙ",
  Void_Cheque:"Перевірка недійсності",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ПЛАТИТИ ЗА НАКАЗОМ:",
  NO_DOLLARS:"НЕМАЄ ДОЛАРІВ",
  ONE_DOLLAR:"ОДИН ДОЛАР",
  DOLLARS:"ДОЛАРІВ",
  AND:"І",
  CENTS:" ЦЕНТИ.",
  NO_:"НІ",
  ONE_:"ОДИН",
  AND_NO_:"І НІ",
  _AND_ONE_:"І ОДИН",
  DOLLARS_AND_ONE_CENT:"І ОДИН ЦЕНТ.",
  AND_NO_CENTS:" І НУЛЬ ЦЕНТІВ.",
  CHEQUE_PRINT_DATE:"ДАТА:",
  CHEQUE_PRINT_MEMO:"ПАМ'ЯТКА:",
  CHEQUE_PRINT_SIGNATUREMP:"депутат",
  Initial_Tasks:"Початкові завдання",
  Inital_Setup:"Початкове налаштування",
  Welcome_To:"Ласкаво просимо до",
  Welcome:"Ласкаво просимо",
  Swipe:"Проведіть пальцем",
  Intro_Setup:"Вступне налаштування",
  Introduction:"вступ",
  CHEQUE_PRINT_CREDIT:"Працює на перевірках",
  Title_Intro_Word:"Ласкаво просимо до Чеків",
  Title_Intro:"Перевірки Введення",
  Title_Setup:"Перевіряє налаштування",
  PayPal_Default_Email_Message:"Ви отримали новий переказ PayPal.",
  Cheques_App_Export:"Експортується за чеками",
  No_Prints_Purchased:"Роздруківки не куплено",
  No_Prints_Purchased_Word:"Додаткові відбитки недоступні. Будь ласка, придбайте додаткові чеки.",
  Post_Intro_Thanks:"Дякуємо, що вибрали чеки. На цьому процес налаштування завершено.",
  Post_Intro_Word:"Почніть, роздрукувавши свій перший чек, додавши майбутній платіж або додавши співробітників до списку заробітної плати.",
  Upgrade_Required:"Щоб приховати це повідомлення та розблокувати додаткові функції, для Checks потрібно мати преміум-версію програмного забезпечення.",
  Upgrade_Title:"Чеки",
  Mailout_Prompt:"Ви також можете вибрати, щоб Checks надсилав вам чеки із заробітної плати поштою.",
  Mailed_Cheque:"Відправлений чек",
  Mailed_Cheque_Color:"Відправлений чек (кольоровий)",
  Terms_Purchase:"Усі електронні покупки за допомогою чеків підлягають повному відшкодуванню протягом 30 днів із дати покупки. Прочитайте та погодьтеся з",
  Dashboard_Setup:"Налаштування основного принтера",
  Dashboard_Add:"Додайте основний банківський рахунок",
  Dashboard_Customize:"Виберіть шаблон перевірки",
  Dashboard_Job_Salary:"Створіть свою роботу та додайте зарплату",
  Dashboard_Employees:"Відстежуйте співробітників і одержувачів",
  Dashboard_Print:"Роздрукуйте та надішліть свої чеки про зарплату",
  Dashboard_Payroll:"Автоматизуйте друк заробітної плати",
  Dashboard_PayPal:"Налаштування заробітної плати / виплат PayPal",
  Begin_Setup:"Розпочати налаштування",
  Get_Started:"Почати",
  Purchase:"Купівля",
  Lockdown:"Закриття",
  Unlock:"Розблокувати",
  Detailed:"Детальний",
  Log_In:"Увійти",
  Login:"Логін",
  Launch:"Запуск",
  Register:"зареєструватися",
  Finish:"Закінчити",
  List:"Список",
  Weekends:"Вихідні",
  Weekly:"Щотижня",
  PayPal_Default_Subject:"Нова оплата",
  Payment_Message:"Платіжне повідомлення",
  PayPal_Default_Payment_Note:"Дякую",
  Setup_Your_Cheqing_Account:"Чековий рахунок",
  Add_Your_Primary_Cheqing_Account:"Додайте свій основний поточний рахунок.",
  Welcome_Word:"Спростіть і автоматизуйте нарахування заробітної плати та управління людськими ресурсами.",
  Get_Started_Quickly:"Просто дайте відповідь на кілька простих запитань, які допоможуть нам почати...",
  Done_Intro_Word:"Налаштування завершено",
  PIN_Protected:"Захищено паролем і PIN-кодом",
  Enter_New_PIN:"Введіть новий PIN-код:",
  Enter_PIN:"Введіть PIN-код:",
  Invalid_PIN:"Введено недійсний PIN-код.",
  Account_Identifier:"Ідентифікатор облікового запису",
  New_Account_Identifier:"Новий ідентифікатор облікового запису",
  attempt:"спроба",
  attempts:"спроби",
  remaining:"залишилося",
  Language:"Мова",
  languages:"Мови",
  select_languages:"Оберіть мову",
  Deposit:"депозит",
  Hire_One_Now:"Найміть одного зараз",
  App_Locked:"Програма заблокована.",
  Skip_:"Пропустити",
  Skip_to_Dashboard:"Перейти до інформаційної панелі",
  Dashboard:"Панель приладів",
  Import:"Імпорт",
  Admin:"Адміністратори",
  New_Admin:"Новий адмін",
  Settings:"Налаштування",
  Color_Picker:"Палітра кольорів",
  Font_Picker:"Вибір шрифту",
  Logout:"Вийти",
  Close:"Закрити",
  Close_menu:"Закрити",
  Excel:"Файл Excel",
  Csv:"Файл CSV",
  Sql:"Файл SQL",
  Json:"Файл JSON",
  Url:"Імпорт за URL-адресою",
  Back:"Назад",
  Timers:"Таймери",
  Cheque:"Перевірте",
  Print:"Роздрукувати",
  Designs:"Конструкції",
  Pause_Printing:"Призупинити друк",
  Resume_Printing:"Відновити друк",
  Printing_Paused:"Друк призупинено",
  PrintingUnavailable:"Вибачте! Друк недоступний у цій системі.",
  Prints_Paused:"Друк призупинено",
  Administration:"Адміністрація",
  Loading:"Завантаження",
  Unnamed:"Без назви",
  error:"На жаль, цей чек неможливо відкрити для перегляду.",
  No_Cheques_To_Print:"Немає чеків для друку",
  No_Cheques_To_Print_Word:"Створіть <a routerLink='/folder/Cheque/New'>новий чек</a>.",
  New_Cheque:"Новий чек",
  Start_One_Now:"Почніть один зараз",
  Edit_Cheque:"Редагувати перевірку",
  Select_Cheques:"Виберіть Чеки",
  Select_Employee:"Виберіть Працівник",
  Default_Printer:"Принтер за умовчанням",
  Reassign:"Перепризначити",
  Configure:"Налаштувати",
  Template:"Шаблон",
  Designer:"Дизайнер",
  Edit_Designs:"Редагувати дизайни",
  New_Design:"Новий дизайн",
  Next:"Далі",
  Save:"зберегти",
  Name:"Ім'я",
  Mail:"Пошта",
  Amount:"Сума",
  Date:"Дата",
  PayPal:"PayPal",
  Payouts:"Виплати",
  PayPal_Label:"Етикетка PayPal",
  Email_Username:"Електронна пошта / ім'я користувача",
  Client_ID:"ID клієнта",
  Sandbox_Email:"Пісочниця електронної пошти",
  PayPal_Email:"Електронна пошта PayPal",
  PayPal_Username:"Ім'я користувача API",
  PayPal_Payouts:"Виплати PayPal",
  Select_PayPal_Key:"Виберіть ключ PayPal",
  Secret:"Секрет",
  API_Secret:"Секрет API",
  PayPal_API_Keys:"Ключі PayPal",
  New_PayPal_Key:"Новий ключ PayPal",
  Email_Subject:"Тема електронного листа",
  Email_Message:"Повідомлення електронною поштою",
  Payout_Options:"Варіанти виплат",
  Payment_Note:"Примітка про оплату",
  Enable_Employee:"Увімкнути працівника",
  Enable_Production_Mode:"Увімкнути режим виробництва",
  Sandbox_Username:"Ім'я користувача пісочниці",
  Sandbox_Signature:"Пісочниця Підпис",
  Sandbox_Password:"Пароль пісочниці",
  Production_Username:"Виробниче ім'я користувача",
  Production_Signature:"Виробничий підпис",
  Production_Password:"Виробничий пароль",
  Production_Email:"Виробнича електронна пошта",
  API_Client_ID:"ID клієнта API",
  API_Signature:"Підпис API",
  API_Password:"Пароль API",
  API_Username:"Ім'я користувача API",
  Secret_Key:"Секретний ключ",
  Sandbox:"Пісочниця",
  Production:"виробництво",
  Sandbox_Keys:"Ключі від пісочниці",
  Production_Keys:"Виробничі ключі",
  API_Title:"Назва API",
  Production_Mode:"Режим виробництва",
  Account_Label:"Мітка облікового запису",
  No_PayPal_Setup:"Немає ключа PayPal",
  Enable_PayPal_Account:"Увімкніть обліковий запис PayPal",
  No_PayPal_Word:"Додайте свій <a routerLink='/folder/Invoice/New'>ключ API PayPal</a>.",
  Printed_Memo:"Друкована пам'ятка",
  Employee:"Співробітник",
  View_Employee:"Переглянути співробітника",
  Mailing_Address:"Поштова адреса",
  Company_Address:"адреса компанії",
  Select_Company_Address:"Виберіть адресу компанії",
  Address:"Адреса",
  Any_Day:"Будь-який день",
  Address_Name:"Адреса Ім'я",
  Unit:"одиниця",
  Account:"Обліковий запис",
  Bank_Account:"Банківський рахунок",
  Account_Limits:"Увімкнути обмеження облікового запису",
  Payroll:"Нарахування заробітної плати",
  Run:"бігти",
  Run_Payroll:"Запустіть Payroll",
  New_Payroll:"Нова платіжна відомість",
  No_Payroll:"Немає майбутньої заробітної плати",
  Upcoming_Holiday:"Майбутнє свято:",
  Invoice_Due:"Рахунок-фактура до оплати:",
  New_Invoice:"Новий рахунок-фактура",
  No_Invoices:"Без рахунків-фактур",
  No_Invoices_Word:"Створіть перший <a routerLink='/folder/Invoice/New'>рахунок-фактуру</a>.",
  Cheque_Due:"Оплата чеку:",
  Payrolls:"Платіжні відомості",
  Sandbox_Mode:"Режим пісочниці",
  Hire:"Найняти",
  Pay:"платити",
  New:"новий",
  Add:"додати",
  Make:"зробити",
  Time:"час",
  Write:"Напишіть",
  Holiday:"Свято",
  Holidays:"свята",
  Next_Holiday:"Наступне свято:",
  All_Done:"Все зроблено!",
  Employees:"Співробітники",
  Payees:"одержувачі",
  Job:"Робота",
  Jobs:"Вакансії",
  Invoice:"рахунок-фактура",
  Invoices:"Рахунки-фактури",
  Vacations:"Канікули",
  Cheques:"Чеки",
  Brand_Cheques:"Ваш бренд",
  Payment:"Оплата",
  Enable_Payment:"Увімкнути оплату",
  Payments:"Платежі",
  Schedule:"розклад",
  Schedules:"Розклади",
  Timesheet:"Розклад",
  Timesheets:"Табелі обліку робочого часу",
  Salary:"Зарплата",
  New_Address:"Нова адреса",
  Address_2:"Адресний рядок 2)",
  _City:"Місто",
  _State:"Штат/пров",
  City:"Місто/селище",
  State:"Штат/провінція",
  Postal:"Поштовий індекс",
  ZIP:"Поштовий / ZIP",
  Country:"Країна",
  Addresses:"Адреси",
  Required_Options:"Необхідні параметри",
  Optional_Options:"Додаткові параметри",
  Additional_Options:"Додаткові параметри",
  Required:"вимагається",
  Optional:"Додатково",
  Additional:"Додатковий",
  No_Addresses:"Немає адрес",
  New_Address_Word:"Додайте першу <a routerLink='/folder/Address/New'>адресу</a>.",
  Select_Address:"Виберіть Адреса",
  No_Address:"Немає адрес",
  Print_Cheque:"Роздрукувати чек",
  Print_Cheques:"Друк чеків",
  Print_Cheque_Now:"Роздрукуйте перевірку зараз",
  Description:"опис",
  Pay_To_The_Order_Of:"Платити за наказом:",
  Select_Date_Range:"Виберіть діапазон дат",
  Select_Starting_Date:"Виберіть дату початку",
  Select_Ending_Date:"Виберіть Кінцева дата",
  Select_Date:"Виберіть Дата",
  Cheque_Date:"Дата перевірки",
  Cheque_Memo:"Перевірте Memo",
  Blank_Cheque:"Пустий чек",
  Blank:"Пустий",
  No_Cheques:"Без перевірок",
  No_Cheques_Word:"Роздрукуйте свій перший <a routerLink='/folder/Cheque/New'>чек</a>.",
  Cheque_Amount_Placeholder:"0,00 доларів США",
  View_Image:"Переглянути зображення",
  View:"Переглянути",
  Modify:"Змінити",
  Delete:"Видалити",
  Cheque_Paid:"Платний",
  New_Deduction:"Нова дедукція",
  Title:"Назва",
  Frequency:"Частота",
  Hourly:"щогодини",
  Daily:"Щодня",
  Weekdays:"будні",
  BiWeekly:"2 тижні",
  TriWeekly:"3 тижні",
  Monthly:"Щомісяця",
  MiMonthly:"2 місяці",
  Quarterly:"Щоквартально",
  Yearly:"щорічно",
  Every_Week:"Кожного тижня",
  Every_Payroll:"Кожна заробітна плата",
  Every_Month:"Щомісяця",
  Annually:"Щорічно",
  Always_Scheduled:"Завжди за розкладом",
  Start_Date:"Дата початку",
  End_Date:"Дата закінчення",
  Start_Time:"Час початку",
  End_Time:"Час закінчення",
  Deduction_Label:"Мітка відрахування",
  Notes:"Примітки",
  Options:"Опції",
  Enable:"Увімкнути",
  Select_Deductions:"Виберіть Відрахування",
  Deductions:"Відрахування",
  No_Deductions:"Без відрахувань",
  No_Deductions_Word:"Створіть свій перший <a routerLink='/folder/Deduction/New'>Відрахування</a>.",
  New_Employee:"Новий працівник",
  No_Title:"Без назви",
  _Name:"Ім'я",
  About_Yourself:"Про себе",
  Full_Name:"Повне ім'я",
  Birthday:"день народження",
  Email:"Електронна пошта",
  SMS:"СМС",
  Phone:"Телефон",
  Test:"Тест",
  Call:"Телефонуйте",
  Fax:"Факс",
  Printed_Note:"Друкована примітка",
  Position:"Позиція",
  Job_Position:"Посада",
  Select_a_Job:"Виберіть роботу",
  Select_a_Salary:"Виберіть зарплату",
  Add_a_Deduction:"Додати відрахування",
  Taxes:"податки",
  Add_Taxes:"Додайте податки",
  Date_of_Birth:"Дата народження",
  Email_Address:"Адреса електронної пошти",
  Phone_Number:"Номер телефону",
  Phone_Call:"Телефонний дзвінок",
  Enable_on_Payroll:"Увімкнути в Payroll",
  Select_Employees:"Виберіть Employees",
  No_Employees:"Немає співробітників",
  No_Employees_Word:"Додайте свого першого нового <a routerLink='/folder/Employee/New'>співробітника</a>.",
  No_Name:"Без назви",
  Unemployeed:"Безробітний",
  Employeed:"Працевлаштований",
  Paid:"Платний",
  Enabled:"Увімкнено",
  Disabled:"Вимкнено",
  Fire:"Вогонь",
  Not_Available:"Недоступний",
  Not_Available_Word:"Роздрукуйте свій перший <a routerLink='/folder/Invoice/New'>рахунок-фактуру</a> та отримуйте гроші.",
  Select_Invoices:"Виберіть Рахунки-фактури",
  Print_Invoice_Word:"Роздрукуйте свій перший <a routerLink='/folder/Invoice/New'>рахунок-фактуру</a> та отримуйте гроші.",
  Invoice_Title:"Назва рахунку-фактури",
  Invoice_Date:"Дата рахунку-фактури",
  Due_Date:"Термін виконання",
  New_Job:"Нова робота",
  Details:"Подробиці",
  Customize:"Налаштувати",
  Customize_Dashboard:"Налаштувати інформаційну панель",
  Components:"компоненти",
  No_Components:"Без компонентів",
  Main_Components:"Основні компоненти",
  Smaller_Components:"Менші компоненти",
  Error_Loading_Page:"Помилка завантаження цієї сторінки.",
  Bank_Details:"Банківські реквізити",
  About_Your_Job:"Про вашу роботу",
  Your_Schedule:"Ваш розклад",
  Job_Title:"Назва посади",
  Job_Description:"Описання роботи",
  Job_Details:"Деталі роботи",
  Enable_Job:"Увімкнути завдання",
  Pay_Period:"Період оплати",
  Perpetually_Schedule:"Постійний графік",
  Select_Jobs:"Виберіть Вакансії",
  No_Jobs:"Немає роботи",
  Add_Jobs:"Додати вакансії",
  No_Jobs_Word:"Додайте перше <a routerLink='/folder/Job/New'>завдання</a> до списку.",
  Count_Employees:"job.employee_count+'Співробітники'",
  Zero_Employees:"0 співробітників",
  New_Leave:"Нова відпустка",
  Leave_Name:"Залиште ім'я",
  Paid_Leave:"Оплачувану відпустку",
  Leave_Pay:"Залиште платити",
  Indefinite_Leave:"Безстрокова відпустка",
  Indefinite_Payroll:"Безстроковий нарахування заробітної плати",
  Leave:"Залишати",
  Add_Schedules:"Додати розклади",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Увімкнути відсутність",
  Select_Leaves:"Виберіть листя",
  No_Leaves:"Жодних відпусток",
  Leave_Of_Absence:"Відпустка",
  Leaves_Of_Absence:"Листи відсутності",
  New_Leave_of_Absense:"Нова відпустка",
  No_Leaves_Word:"Додайте першу <a routerLink='/folder/Leave/New'>відпустку</a>.",
  Not_Enabled:"Не ввімкнено",
  Absences:"Прогули",
  Payee:"одержувач",
  New_Payee:"Новий одержувач",
  Payee_Identifier:"Ідентифікатор одержувача",
  Payee_Name:"Ім'я одержувача",
  Payee_Title:"Назва одержувача",
  Payment_Memo:"Платіжна довідка",
  Select_Payees:"Виберіть Одержувачі",
  No_Payees:"Немає одержувачів",
  Add_Payee_Note:"Додайте першого <a routerLink='/folder/Payee/New'>одержувача</a>.",
  New_Payees:"Нові одержувачі",
  About_The_Payment_Schedule:"Графік платежів",
  Your_Payroll_Schedule:"Автоматичний нарахування заробітної плати",
  New_Payment:"Нова оплата",
  Identifier:"Ідентифікатор",
  Selected:"Вибране",
  Select:"Виберіть",
  Memo:"Пам'ятка",
  Payment_Date:"Дата оплати",
  Mark_as_Paid:"Позначити як оплачене",
  Select_Payments:"Виберіть Платежі",
  No_Payments:"Ніяких платежів",
  No_Payments_Word:"Створіть перший <a routerLink='/folder/Payment/New'>Платіж</a>.",
  Create_Payroll:"Створення платіжної відомості",
  Properties:"Властивості",
  Payroll_Title:"Назва заробітної плати",
  Payroll_Notes:"Примітки до заробітної плати",
  Payroll_Setup:"Налаштування заробітної плати",
  Tabulate_Payments:"Таблиця платежів",
  Tabulate:"Табулювати",
  By:"Автор:",
  Payments_By:"Платежі",
  Timesheets_And_Schedules:"Табелі та розклади",
  Both:"Обидва",
  Items:"Предмети",
  Add_Payees:"Додати одержувачів",
  Add_Account:"Додати обліковий запис",
  Enable_Account:"Увімкнути обліковий запис",
  Enable_Payroll:"Увімкнути заробітну плату",
  Print_Payroll:"Роздрукувати платіжну відомість",
  No_Payrolls:"Без заробітної плати",
  No_Payroll_Word:"Створіть свою першу <a routerLink='/folder/Payroll/New'>Зарплатну відомість</a>.",
  View_more:"ДИВИТИСЬ БІЛЬШЕ",
  Less:"МЕНШЕ",
  Add_Payroll:"Додати платіжну відомість",
  Select_Payroll:"Виберіть Заробітна плата",
  About_Your_Salary:"Про вашу зарплату",
  Add_Salaries:"Додайте зарплати",
  Add_Salary:"Додайте зарплату",
  Salaries:"Заробітна плата",
  No_Salaries:"Жодної зарплати",
  No_Salaries_Word:"Додайте першу <a routerLink='/folder/Salary/New'>зарплату</a>.",
  Select_Salaries:"Виберіть Зарплати",
  New_Salary:"Нова зарплата",
  Salary_Name:"Ідентифікатор зарплати",
  Enable_Salary:"Увімкнути зарплату",
  Salary_Amount:"Розмір заробітної плати",
  New_Schedule:"Новий розклад",
  Schedule_Title:"Назва розкладу",
  Add_Address:"Додати адресу",
  Repeat:"Повторіть",
  Design:"Дизайн",
  Edit_Design:"Редагувати дизайн",
  Edit_this_Design:"Редагувати цей дизайн",
  Repeat_Payment:"Повторити платіж",
  Enable_Schedule:"Увімкнути розклад",
  Never:"Ніколи",
  Select_Schedule:"Виберіть Розклади",
  No_Schedules:"Немає розкладів",
  No_Schedules_Word:"Створіть перший <a routerLink='/folder/Schedule/New'>Розклад</a>.",
  New_Administrator:"Новий адміністратор",
  Username:"Ім'я користувача",
  First_Name:"Ім'я",
  Last_Name:"Прізвище",
  Add_an_Address:"Додайте адресу",
  Payment_Limit:"Ліміт на платіж",
  Total_Limit:"Загальний ліміт",
  Select_Accounts:"Виберіть Облікові записи",
  No_Administrators:"Без адміністраторів",
  No_Administrators_Word:"Створіть перший <a routerLink='/folder/Administrator/New'>обліковий запис адміністратора</a>.",
  New_Administrators_Word:"Додайте першого <a routerLink='/folder/Administrator/New'>адміністратора</a>",
  Cloud:"Хмара",
  Backup:"Резервне копіювання",
  Enable_iCloud:"Увімкніть iCloud",
  Enable_Google_Drive:"Увімкніть Google Drive",
  Enable_Microsoft_OneDrive:"Увімкніть Microsoft OneDrive",
  Automatically_Backup:"Автоматичне резервне копіювання",
  FTP_Settings:"Налаштування FTP",
  URL_File_Prompt:"Укажіть розташування файлу .xls / .xlsx / .json для імпорту:",
  URL_SQL_Prompt:"Укажіть розташування файлу SQLite для імпорту:",
  FTP_Backup:"Резервне копіювання FTP",
  FTP_Import:"Імпорт FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Хост",
  Port:"Порт",
  Path:"шлях",
  Data_Path:"Шлях даних",
  Enable_FTP_Account:"Увімкнути обліковий запис FTP",
  HostnameIP:"Ім'я хоста",
  Password:"Пароль",
  Connection_Port:"Порт підключення",
  Enable_MySQL_Database:"Увімкнути базу даних MySQL",
  Log:"Журнал",
  Reset:"Скинути",
  Erase:"Стерти",
  Export:"Експорт",
  Database:"База даних",
  Upload_CSV:"Завантажте CSV",
  Download_CSV:"Завантажити CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Резервне копіювання MySQL",
  Internal_Notes:"Внутрішні примітки",
  Root_Path:"Кореневий шлях",
  Select_Backup:"Виберіть Резервне копіювання",
  Add_New_Backup:"Додайте нову резервну копію",
  First_Backup:"Налаштувати першу резервну копію...",
  Backups:"Резервні копії",
  Add_Backup:"Додати резервну копію",
  No_Backups:"Немає резервних копій.",
  Select_Backup_Type:"Виберіть тип резервного копіювання, який ви хочете налаштувати...",
  Backup_Type:"Тип резервного копіювання",
  FTP_Drive:"Диск FTP",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Драйв",
  Microsoft_OneDrive:"Драйв",
  Import_Fields:"Імпорт полів",
  Import_Fields_Word:"Використовуйте цей розділ, щоб вибрати <a routerLink='/folder/Holiday/New'>Імпортувати</a> дані.",
  Upload:"Завантажити",
  Download:"Завантажити",
  Download_JSON:"Завантажити як дані JSON",
  Download_SQL:"Завантажити як файл SQL",
  New_Bank:"Новий банк",
  New_Account:"Новий акаунт",
  New_Bank_Account:"Новий банківський рахунок",
  Upload_Image:"Завантажити зображення",
  Bank_Name:"Назва банку",
  Bank_Address:"Адреса банку",
  Branch_Address:"Адреса відділення",
  Address_on_Cheque:"Адреса",
  Cheque_Numbers:"Перевірте номери",
  Cheque_Details:"Перевірте деталі",
  Bank_Logo:"Логотип банку",
  Cheque_ID:"Перевірте посвідчення особи",
  Starting_Cheque_ID:"Початок перевірки ідентифікатора",
  Transit_Number:"Транзитний номер",
  Institution_Number:"Номер закладу",
  Designation_Number:"Номер позначення",
  Account_Number:"Номер рахунку",
  Limits:"Межі",
  Default_Limits:"Обмеження за замовчуванням",
  Over_Limit:"Перевищення ліміту",
  Under_Limit:"Під обмеженням",
  Payroll_Limit:"Ліміт заробітної плати",
  Enable_Bank_Account:"Увімкнути банківський рахунок",
  Select_Account:"Виберіть обліковий запис",
  No_Bank_Account:"Без банківського рахунку",
  No_Bank_Account_Word:"Додайте перший <a routerLink='/folder/Accounts/New'>банківський рахунок</a>.",
  Bank_Accounts:"Банківські рахунки",
  No_Accounts:"Немає облікових записів",
  No_Accounts_Note:"Додайте перший <a routerLink='/folder/Accounts/New'>банківський рахунок</a>.",
  Cheque_Designer:"Перевірте конструктор",
  Cheque_Design:"Перевірте дизайн",
  Select_Design:"Виберіть дизайн",
  Cheque_Designs:"Перевірте дизайни",
  No_Cheque_Designs:"Без перевірки дизайнів",
  No_Cheque_Designs_Word:"Створіть власний <a routerLink='/folder/Settings/Cheque/Design/New'>дизайн чека</a>.",
  Set_Default:"Встановити за замовчуванням",
  Default:"За замовчуванням",
  Remove:"видалити",
  Folder:"Папка",
  Edit:"Редагувати",
  LoadingDots:"Завантаження...",
  Add_a_New_File:"Додайте <a href='#' (click)='add()'>новий файл</a> до",
  this_folder:"цю папку",
  FTP_Backup_Settings:"Налаштування резервного копіювання FTP",
  Secure_File_Transfer:"Безпечна передача файлів",
  New_Holiday:"Нове свято",
  Add_Holiday:"Додати свято",
  Holiday_Name:"Назва свята",
  Additional_Pay:"Додаткова оплата",
  Enable_Holiday:"Увімкнути відпустку",
  Select_Holidays:"Виберіть «Свята».",
  No_Holidays:"Без свят",
  No_Holidays_Word:"Додайте перше <a routerLink='/folder/Holiday/New'>державне свято</a>.",
  New_Import:"Новий імпорт",
  Import_Data:"Імпорт даних",
  Import_Data_Word:"Виберіть потрібний тип файлу або спосіб завантаження.<br /> Після завантаження підтримуваного файлу ви зможете вибрати будь-які імпортовані поля у файлі, які відповідають будь-якому параметру в програмі.",
  Import_File:"Імпорт файлу",
  Link_To_File:"Посилання на файл",
  Select_File:"Виберіть Файл",
  New_Moderator:"Новий модератор",
  Allow_Moderation:"Дозволити модерацію",
  Create_Paycheques:"Створення платіжних чеків",
  Edit_Paycheques_and_Data:"Редагувати чеки та дані",
  Destroy_Data_and_Paycheques:"Знищення даних і чеків",
  Bonus_Percentage:"Відсоток зарплати",
  Fixed_Amount:"Фіксована сума",
  Select_Moderator:"Виберіть Модератор",
  No_Moderators:"Без модераторів",
  Moderators:"Модератори",
  Moderator_Account:"Створіть перший <a routerLink='/folder/Administrator/New'>обліковий запис модератора</a>.",
  No_Moderators_Word:"Додайте першого <a routerLink='/folder/Administrator/New'>Модератора</a>.",
  Defaults:"За замовчуванням",
  Provide_Us_Info:"Надати інформацію",
  Setup_Your_Printer:"Налаштуйте свій принтер",
  Your_Company:"Про вашу компанію",
  Company_Name:"Назва компанії",
  Currency:"Валюта",
  Default_Currency:"Валюта за умовчанням",
  Base_Monthly_Income:"Місячний дохід",
  Protection:"захист",
  App_Protection:"Захист програм",
  PIN_Code_Protection:"Захист PIN-кодом",
  App_Protection_Word:"Увімкніть методи безпеки, які допоможуть захистити ваш обліковий запис.",
  PIN_Code:"PIN-код",
  Change_PIN:"Змінити PIN-код",
  New_Password:"Новий пароль",
  Geofence_Protection:"Захист геоогорожі",
  Geofence_Area:"Встановити область",
  Distance:"Відстань",
  Setup_Now:"Налаштувати зараз",
  Mile:"Миля",
  Km:"км",
  m:"м",
  Facial_Recognition:"Розпізнавання обличчя",
  Face:"Обличчя",
  Setup:"Налаштування",
  Label:"Мітка",
  Password_Protection:"Захист паролем",
  Modify_Password:"Змінити пароль",
  New_Tax_Entry:"Новий податковий запис",
  New_Tax:"Новий податок",
  Tax_Label:"Податкова етикетка",
  Perpetually_Enabled:"Постійно ввімкнено",
  Select_Taxes:"Виберіть Податки",
  Tax_Deductions:"Податкові відрахування",
  No_Tax_Deductions:"Жодних податкових відрахувань",
  No_Tax_Deductions_Word:"Додайте перший <a routerLink='/folder/Tax/New'>Податок</a> відрахування.",
  New_Timer:"Новий таймер",
  Start:"старт",
  Stop:"СТІЙ",
  Start_Timer:"Запустити таймер",
  Stop_Timer:"Зупинити таймер",
  Timer_Active:"Таймер активний",
  Timer:"Таймер:",
  Timer_Running:"Таймер: (працює)",
  Save_Timer:"Зберегти таймер",
  New_Timesheet:"Новий табель обліку робочого часу",
  Select_Timesheets:"Виберіть «Табель обліку робочого часу».",
  Work_Notes:"Примітки до роботи",
  Entry_Title:"Заголовок запису",
  No_Timesheets:"Немає табелів обліку робочого часу",
  No_Timesheets_Word:"Додайте перший <a routerLink='/folder/Timesheet/New'>табель робочого часу</a>.",
  Timesheet_Submitted:"Табель відведено",
  Timesheet_Congrats:"Щиро вітаю! Ваш табель успішно надіслано. Дякую тобі!",
  Timesheet_Copy:"Щоб отримати копію ваших документів, будь ласка, надайте нам свою електронну адресу та/або номер мобільного телефону.",
  Submit:"Надіслати",
  Allow_Notifications:"Дозволити сповіщення",
  Untitled_Entry:"Новий запис",
  Untitled_Bank:"Банк без назви",
  Timesheet_Entry:"Введення табеля робочого часу",
  Work_Description:"Опис Роботи",
  Enable_Timesheet:"Увімкнути табелі робочого часу",
  Submit_Timesheet:"Подайте табель обліку робочого часу",
  Vacation:"Відпустка",
  New_Vacation:"Нові канікули",
  Vacation_Name:"Назва відпустки",
  Paid_Vacation:"Оплачувана відпустка",
  Vacation_Pay:"Оплата відпустки",
  Enable_Vacation:"Увімкнути відпустку",
  Select_Vacations:"Виберіть Відпустки",
  No_Vacations:"Без канікул",
  No_Vacations_Word:"Створіть перший запис <a routerLink='/folder/Vacation/New'>Vacation</a>.",
  Payroll_Schedule:"Графік нарахування заробітної плати",
  Next_Payroll:"Наступна зарплата:",
  Upcoming_Payroll:"Майбутня заробітна плата",
  No_Upcoming_Payroll:"Немає майбутньої заробітної плати",
  Address_Book:"Адресна книга",
  Archived_Documents:"Архівні документи",
  Dev_Mode:"Програма в режимі розробки",
  Administrators:"Адміністратори",
  Privacy:"Конфіденційність",
  None:"Жодного",
  Select_Signature:"Виберіть Підпис",
  No_Signatures:"Без підписів",
  No_Signatures_Word:"Додайте перший <a routerLink='/folder/Signature/New'>підпис</a>.",
  Repeat_Indefinitely:"Повторювати безкінечно",
  Sign:"Знак",
  Sign_Here:"Підпишіть тут",
  Date_Signed:"Дата підписання",
  Signature_Pad:"Блокнот для підпису",
  Account_Holder:"Власник рахунку",
  Account_Properties:"Властивості облікового запису",
  Name_On_Cheque:"Ім'я на чеку",
  Server_Hostname:"Ім'я хоста сервера / IP",
  Printers:"Принтери",
  No_Printers:"Немає принтерів",
  Printer_Exists:"Принтер із такою назвою вже існує.",
  No_Printers_Word:"Додайте перший <a routerLink='/folder/Printer/New'>принтер</a>.",
  No_Printer_Alert:"Принтер не визначено. Бажаєте налаштувати принтер?",
  Add_Printer:"Додати принтер",
  New_Printer:"Новий принтер",
  Printer_Hostname:"Ім'я хосту принтера / IP",
  Printer_Label:"Принтер етикетки",
  Printer_Protocol:"Протокол принтера",
  Protocol:"Протокол",
  Email_Print:"Електронна пошта",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Розетка",
  Print_Job:"Завдання друку",
  Printed:"Роздрукований",
  Not_Printed:"Не надруковано",
  Print_Jobs:"Завдання друку",
  Print_Queue:"Черга друку",
  No_Print_Jobs:"Немає завдань друку",
  No_Prints:"Створіть новий <a routerLink='/folder/Cheque/New'>чек</a> для друку.",
  Prints:"Принти",
  Find_Printer:"Знайдіть принтер",
  Find_AirPrint_Devices:"Знайдіть пристрої AirPrint",
  Select_Printer:"Виберіть Принтер",
  System_UI:"Інтерфейс системи",
  Printer:"Принтер",
  Status:"Статус",
  Preview:"Попередній перегляд",
  Enable_Print_Job:"Увімкнути завдання друку",
  Queue_Weight:"Вага черги",
  Unlimited:"Необмежений",
  Show_Advanced_Printer_Options:"Показати додаткові параметри принтера",
  Advanced:"Просунутий",
  Location:"Місцезнаходження",
  Note:"Примітка",
  Queue_Name:"Назва черги",
  Pages_Printed_Limit:"Ліміт надрукованих сторінок",
  MultiPage_Idle_Time:"Багатосторінковий час очікування (с)",
  Page_Count_Limit:"Обмеження кількості сторінок",
  Page_Orientation:"Орієнтація сторінки",
  Portrait:"Портрет",
  Landscape:"Пейзаж",
  Duplex:"Дуплекс",
  Double_Sided_Printing:"Двосторонній",
  Duplex_Mode:"Дуплексний режим",
  Duplex_Short:"Короткий",
  Duplex_Long:"Довго",
  Duplex_None:"Жодного",
  Color_And_Quality:"Колір і якість",
  Monochrome:"Монохромний",
  Photo_Quality_Prints:"Якісний друк фотографій",
  Printer_Email:"Електронна пошта принтера",
  Automatically_Downsize:"Автоматично зменшувати",
  Paper:"Папір",
  Paper_Name:"Назва паперу",
  Paper_Width:"Ширина паперу",
  Paper_Height:"Висота паперу",
  Paper_Autocut_Length:"Довжина автоматичного обрізання паперу",
  Margins:"Поля",
  Page_Margins:"Поля сторінки",
  Page_Margin_Top:"Верхнє поле сторінки",
  Page_Margin_Right:"Праве поле сторінки",
  Page_Margin_Bottom:"Нижнє поле сторінки",
  Page_Margin_Left:"Ліве поле сторінки",
  Add_Employees:"Додати співробітників",
  Header:"Заголовок",
  Print_A_Page_Header:"Друк верхнього колонтитула сторінки",
  Header_Label:"Мітка заголовка",
  Header_Page_Index:"Індекс верхньої сторінки",
  Header_Font_Name:"Шрифт заголовка",
  Select_Font:"Виберіть Шрифт",
  Font_Selector:"Вибір шрифту",
  Header_Font_Size:"Шрифт заголовка",
  Header_Bold:"Заголовок Жирний",
  Header_Italic:"Заголовок курсивом",
  Header_Alignment:"Вирівнювання заголовка",
  Left:"Ліворуч",
  Center:"центр",
  Right:"правильно",
  Justified:"Виправдано",
  Header_Font_Color:"Колір заголовка",
  Select_Color:"Виберіть Колір",
  Footer:"нижній колонтитул",
  Print_A_Page_Footer:"Друк нижнього колонтитула сторінки",
  Footer_Label:"Мітка нижнього колонтитула",
  Footer_Page_Index:"Індекс сторінки нижнього колонтитула",
  Footer_Font_Name:"Шрифт нижнього колонтитула",
  Fonts:"Шрифти",
  Done:"Готово",
  Select_Fonts:"Виберіть Шрифти",
  Footer_Font_Size:"Розмір нижнього колонтитула",
  Footer_Bold:"Нижній колонтитул Жирний",
  Footer_Italic:"Нижній колонтитул Курсив",
  Footer_Alignment:"Вирівнювання нижнього колонтитула",
  Footer_Font_Color:"Колір нижнього колонтитула",
  Page_Copies:"Копії сторінок",
  Enable_Printer:"Увімкнути принтер",
  Remote_Logging:"Віддалене ведення журналу",
  Log_Server:"Сервер журналу",
  Encryption:"Шифрування",
  Random_Key:"Випадковий ключ",
  Encryption_Key:"Ключ шифрування",
  Cheques_Webserver:"Спеціальна база даних",
  Learn_How:"Дізнайтесь як",
  Signature:"Підпис",
  Default_Printer_Unit:"дюйм/см/мм/(пт)",
  View_Signature:"Переглянути підпис",
  Printed_Signature:"Друкований підпис",
  Digitally_Sign:"Цифровий підпис",
  Digital_Signature:"Цифровий підпис",
  Add_Signature:"Додати підпис",
  Add_Your_Signature:"Додайте свій підпис",
  Enable_Signature:"Увімкнути підпис",
  Digitally_Signed:"Цифровий підпис",
  Insert_Error:"Не вдалося зберегти перевірку через проблеми з базою даних.",
  Delete_Confirmation:"Ви впевнені, що хочете видалити цю інформацію?",
  Discard_Confirmation:"Ви впевнені, що бажаєте видалити цю інформацію?",
  Discard_Bank_Confirmation:"Ви впевнені, що бажаєте видалити цей обліковий запис?",
  Discard_Printer_Confirmation:"Ви впевнені, що хочете видалити цей принтер?",
  Delete_Bonus_Confirmation:"Ви впевнені, що хочете видалити цей бонус?",
  Delete_Invoice_Confirmation:"Ви впевнені, що бажаєте видалити цей рахунок?",
  Generated_Cheque:"Згенерований чек",
  Generated_Invoice:"Сформований рахунок-фактура",
  Schedule_Start_Time:"Початок розкладу",
  Schedule_End_Time:"Кінець розкладу",
  New_Call:"Новий виклик",
  No_Contacts:"Немає контактів",
  No_Contacts_Word:"Адміністратори, модератори, співробітники та одержувачі відображаються як контакти.",
  PDF_Subject:"фінанси",
  PDF_Keywords:"заробітна плата paycheque PDF check checks",
  Printer_Setup:"Налаштування принтера",
  Printer_Selection:"Вибір принтера",
  New_Fax:"Новий факс",
  New_Fax_Message:"Нове факсове повідомлення",
  Fax_Machine:"Факс",
  Fax_Name:"Назва факсу",
  Fax_Email:"Факс Електронна пошта",
  Fax_Number:"Номер факсу",
  Contents:"Зміст",
  Fax_Body:"Тіло сторінки",
  Header_Word:"Заголовок:",
  Header_Text:"Текст заголовка",
  Include_Header:"Включити заголовок",
  Include_Footer:"Включити нижній колонтитул",
  Footer_Word:"нижній колонтитул:",
  Footer_Text:"Текст нижнього колонтитула",
  Attach_a_Cheque:"Додайте чек",
  Add_Deduction:"Додати відрахування",
  Enable_Fax:"Надіслати факс",
  Select_Fax:"Виберіть Факс",
  No_Faxes:"Без факсів",
  Faxes:"Факси",
  Save_and_Send:"Надіслати факс",
  Save_and_Pay:"Заощаджуйте та платіть",
  WARNING:"УВАГА:",
  Remember:"Пам'ятайте",
  Printing:"Друк",
  Printing_Complete:"Друк завершено!\n\n",
  of:"з",
  There_Were:"Там були",
  Successful_Prints:"вдалі відбитки та",
  Unsuccessful_Prints:"« невдалі відбитки.",
  PrinterError:"Вибачте! Там була помилка.",
  Printing_:"Друк...",
  PrinterSuccess:"Документ успішно надруковано.",
  PrintersSuccess:"Документи надруковано успішно.",
  New_Message:"Нове повідомлення",
  New_Messages:"Нові повідомлення",
  Read_Message:"Прочитати повідомлення",
  Write_Message:"Написати повідомлення",
  Send_Message:"Відправити повідомлення",
  Subject:"Тема",
  Message:"повідомлення",
  Writing:"Написання...",
  Send:"Надіслати",
  Set_Date:"Встановити дату",
  Set_Time:"Встановити час",
  Recieve:"Отримати",
  Set_as_Default:"Встановити за замовчуванням",
  Default_Account:"Обліковий запис за умовчанням",
  Default_Design:"Дизайн за замовчуванням",
  Multiple_Cheques:"Три перевірки",
  Account_Mode:"Режим облікового запису",
  Multiple_Cheques_Description:"Три перевірки на сторінку.",
  Check_and_Table:"Чек і таблиця",
  Check_including_Table:"Звірити з обліковою таблицею.",
  Check_Mailer:"Перевірте Mailer",
  Check_Mailer_Window:"Перевірити в адресному вікні.",
  DocuGard_Table_Top:"Великий чек і стіл",
  DocuGard_Table_Middle:"Великий чек і стіл (посередині)",
  DocuGard_Table_Bottom:"Велика перевірка та стіл (знизу)",
  DocuGard_Mailer_Top:"Розсилка великих чеків",
  DocuGard_Mailer_Middle:"Великий чековий лист (середній)",
  DocuGard_Mailer_Bottom:"Велика чекова скринька (внизу)",
  DocuGard_Three_Cheques:"Три великі чеки",
  DocuGard_Cheque_Top:"Один великий чек",
  DocuGard_Cheque_Middle:"Велика клітка (середня)",
  DocuGard_Cheque_Bottom:"Великий чек (знизу)",
  DocuGard_Three_Cheques_Window:"Три чеки на одній сторінці.",
  DocuGard_Table_Top_Window:"Таблиця чеків і доходів.",
  DocuGard_Table_Middle_Window:"Таблиця чеків і доходів.",
  DocuGard_Table_Bottom_Window:"Таблиця чеків і доходів.",
  DocuGard_Mailer_Top_Window:"Чек, таблиця та адреса.",
  DocuGard_Mailer_Middle_Window:"Чек, таблиця та адреса.",
  DocuGard_Mailer_Bottom_Window:"Чек, таблиця та адреса.",
  DocuGard_Cheque_Top_Window:"Перевірте наявність безпечного паперу.",
  DocuGard_Cheque_Middle_Window:"Перевірте наявність безпечного паперу.",
  DocuGard_Cheque_Bottom_Window:"Перевірте наявність безпечного паперу.",
  Basic_Cheque:"Єдиний чек",
  Basic_Cheque_Print:"Роздрукуйте один чек.",
  Error_Setting_As_Paid:"Помилка встановлення платного",
  Add_Attachment:"Додати вкладення",
  PrinterUnavailable:"Принтер недоступний",
  CreditCardComponent:"картки",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"Біткойн",
  New_Deposit:"Новий депозит",
  Deposits:"Депозити",
  No_Deposits:"Без депозитів",
  Credit_Card_Deposit:"Кредитна карта",
  New_Credit_Card_Deposit_Message:"Депозит кредитною карткою",
  New_BitCoin_Deposit:"Біткойн",
  New_BitCoin_Deposit_Message:"Депозит BitCoin",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Interac Transfer",
  Payments_Limit:"Ліміт платежу",
  No_Payment_Limit:"Без ліміту платежу",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Депозит PayPal",
  No_Deposits_Word:"Додайте першу компанію <a routerLink='/folder/Deposit/New'>Депозит</a>.",
  No_Documents_Specified:"Документи для друку не вказано",
  No_Printers_Added:"Принтерів не знайдено. Перейдіть у «Налаштування» > «Принтери», щоб додати один.",
  DB_Erase_Prompt:"Повністю стерти всю базу даних? УВАГА: ви втратите всю збережену інформацію!",
  Console_Warning:"Не вставляйте жодного тексту в цю консоль. Ви можете поставити себе та/або свою компанію під серйозний ризик.",
  No_Faxes_Word:"Створіть перший <a routerLink='/folder/Fax/New'>факс</a>.",
  Cheque_Delete_Confirmation:"Ви впевнені, що хочете видалити цей чек?",
  Design_Delete_Confirmation:"Ви впевнені, що хочете видалити цей дизайн?",
  Cheque_Pay_Confirmation:"Позначити цей чек як оплачений? Він НЕ з'явиться в черзі друку.",
  Payment_Pay_Confirmation:"Позначити цей платіж як оплачений? Він НЕ з'явиться в черзі перевірки.",
  Delete_Deduction_Confirmation:"Ви впевнені, що хочете видалити це відрахування?",
  Delete_Job_Confirmation:"Ви впевнені, що хочете видалити цю роботу?",
  Delete_Timesheet_Confirmation:"Ви впевнені, що бажаєте видалити цей табель?",
  Delete_Schedule_Confirmation:"Ви впевнені, що хочете видалити цей розклад?",
  Delete_Setting_Confirmation:"Ви впевнені, що бажаєте скинути це налаштування?",
  Delete_Fax_Confirmation:"Ви впевнені, що хочете видалити цей факс?",
  Delete_File_Confirmation:"Ви впевнені, що хочете видалити цей файл?",
  Delete_Vacation_Confirmation:"Ви впевнені, що хочете видалити цю відпустку?",
  Delete_Printer_Confirmation:"Ви впевнені, що хочете видалити цей принтер?",
  Remove_Design_Confirmation:"Ви впевнені, що хочете видалити цей дизайн?",
  Delete_Payroll_Confirmation:"Ви впевнені, що хочете видалити цю платіжну відомість?",
  Send_Fax_Email_Confirmation:"Ви бажаєте надіслати ці документи факсом або електронною поштою?",
  Send_Email_Confirmation:"Ви бажаєте надіслати цей документ електронною поштою?",
  Send_Fax_Confirmation:"Ви бажаєте надіслати цей документ факсом?",
  Error_Generating_PDF:"вибач Під час створення цього документа сталася помилка.",
  PDF_Error_Saving_Image:"вибач Під час збереження PDF-зображення цього документа сталася помилка.",
  Test_Printer_Confirmation:"Бажаєте надрукувати пробну сторінку на цьому принтері?",
  Save_Timesheet_Prompt:"Додайте «Назва» або натисніть «Запустити таймер», щоб зберегти.",
  Remove_Geofence_Prompt:"Ви впевнені, що хочете видалити розташування цієї геозони?",
  Delete_Employee_Confirmation:"Ви впевнені, що хочете видалити",
  Fire_Employee_Confirmation:"Ви впевнені, що хочете звільнити?"
}