export const Bg = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Авторски права &copy; 2023 г",
  black:"черен",
  green:"Зелено",
  gold:"злато",
  blue:"Син",
  brown:"кафяво",
  purple:"Лилаво",
  pink:"Розово",
  red:"червен",
  Swatches:"Мостри",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Hue",
  Saturation:"Насищане",
  Lightness:"Лекота",
  Upgrade_To_Pro:"Надстройте до Pro",
  AllFeaturesInclude:"Всички функции включват:",
  PrintUnlimitedCheques:"Печатайте неограничени чекове",
  PremiumChequeDesigns:"Първокласни дизайни на чекове",
  ManageUnlimitedEmployees:"Управлявайте неограничен брой служители",
  AddUnlimitedPayees:"Добавяне на неограничени получатели",
  CreateUnlimitedPayrolls:"Създайте неограничени ведомости за заплати",
  UnlimitedSchedulesandTimeSheets:"Неограничени графици и разписания",
  BulkPayPalPayouts:"Групови изплащания на PayPal",
  UnlimitedBankAccounts:"Неограничени банкови сметки",
  ManageMultipleCompanies:"Управлявайте множество компании",
  TrackInsurancePolicies:"Проследете застрахователните полици",
  Bio_MetricProtection:"Биометрична защита",
  Geo_FenceLock_OutProtection:"Защита от блокиране на геоограда",
  Multiple_Companies_Word:"Управлението на множество компании не е достъпно без премия за чекове.",
  PayPal_Payouts_Word:"Плащанията чрез PayPal са деактивирани без премия за чекове.",
  PINProtection:"ПИН защита",
  PasswordProtection:"Защита с парола",
  May_Require_Approval:"Може да изисква одобрение.",
  Subscribe:"Абонирай се",
  Billed:"Таксуван",
  Up:"нагоре",
  Down:"Надолу",
  Positioning:"Позициониране",
  Marker:"Маркер",
  Drag_Marker:"Плъзнете маркер",
  Tagline:"Отпечатване на чекове и таблично изготвяне на заплати",
  Marker_Word:"Използвайте маркерите, за да оразмерите елемента.",
  Pinch_Zoom:"Увеличаване с щипка",
  Pinch_Word:"Щипнете, за да увеличите елемента.",
  Drag:"Плъзнете",
  Drag_Word:"Използвайте пръста си, за да плъзнете елементи.",
  subscription_alias_word:"Автоматично подновяване на абонамент",
  premium_alias_word:"Пакет за еднократна надстройка",
  print_alias_word:"Разпечатайте индивидуални чекове",
  mode_alias_word:"Режим",
  Pro:"Професионалист",
  Pro_word:"Необходима е Pro версия.",
  Cant_Delete_Default_Company_Word:"За съжаление не можете да изтриете фирмата си по подразбиране.",
  Reinsert_Default_Designs:"Повторно вмъкване на дизайни по подразбиране",
  Reinsert_Default_Designs_word:"Искате ли да вмъкнете отново дизайните по подразбиране?",
  Subscription_Active_Disable_Word:"Този абонамент е активен. Искате ли да анулирате този абонамент за Чекове?",
  Company_Logo:"Лого на компанията",
  ZERO_:"НУЛА",
  Disclaimer:"Опровержение",
  Privacy_Policy:"Политика за поверителност",
  EULA:"Проверява EULA",
  Terms_Of_Service:"Условия за ползване",
  Terms_Of_Use:"Условия за ползване",
  Refunds:"Политика за възстановяване на средства",
  Single_Print:"1 Проверете",
  Two_Prints:"2 проверки",
  Five_Prints:"5 проверки",
  Ten_Prints:"10 проверки",
  Fifteen_Prints:"15 проверки",
  Twenty_Prints:"20 проверки",
  Thirty_Prints:"30 проверки",
  Image_Added:"Изображението е добавено",
  Image_Preview:"Визуализация на изображението",
  No_Image_Was_Selected:"Не е избрано изображение.",
  Cheques_Unlimited:"Неограничени чекове",
  _Subscription:"Абонамент",
  Subscription:"Чекове - 1 месец",
  Two_Month_Subscription:"Чекове - 2 месеца",
  Three_Month_Subscription:"Чекове - 3 месеца",
  Six_Month_Subscription:"Чекове - 6 месеца",
  Twelve_Month_Subscription:"Чекове - 12 месеца",
  Cheques_Are_Available:"Чековете са налични за печат.",
  Upgrade_Required_Two:"Изберете пакет и докоснете двукратно бутона за цена, за да започнете покупката си. Отпечатвайте професионално изглеждащи пълноцветни чекове за секунди.",
  Month:"месец",
  Due:"В следствие:",
  Expires:"Изтича:",
  Subscription_Active:"Абонаментът е активен",
  Subscription_Inactive:"Абонаментът е неактивен",
  Purchase_Additional_Cheques:"Закупуване на допълнителни чекове?",
  Printable_Cheque:"Чек за печат",
  Printable_Cheques:"Чекове за печат",
  Printable_Cheque_Word:"чек е наличен във вашия акаунт.",
  Printable_Cheques_Word:"чекове са налични във вашия акаунт.",
  Max_Amount_Message:"Посочената от вас сума надвишава максималната сума, зададена за тази система:",
  Terms_Required_Word:"Трябва да приемете това споразумение, преди да продължите да използвате чекове.",
  Subscriptions:"Абонаменти",
  Product_Upgrades:"Надстройки",
  Mailed_Out_Cheques:"Изпратени по пощата чекове",
  Enter_A_Company_Name:"Моля, въведете име на фирма.",
  Single_Cheques:"Единични проверки",
  Cheque_Golden:"Златен чек",
  Cheque_Golden_Window:"Дизайн със златно каре.",
  Cheque_Green:"Зелен чек",
  Cheque_Green_Window:"Дизайн в зелено каре.",
  Cheque_Red:"Червен чек",
  Cheque_Red_Window:"Дизайн на червено каре.",
  Cheque_Yellow:"Жълт чек",
  Cheque_Yellow_Window:"Дизайн с жълто каре.",
  Cheque_Statue_of_Liberty:"Статуя на свободата",
  Cheque_Statue_of_Liberty_Window:"Дизайн на карето на Статуята на свободата.",
  Cheque_Green_Wave:"Зелена вълна",
  Cheque_Green_Wave_Window:"Дизайн в зелено каре.",
  Cheque_Golden_Weave:"Златна тъкан",
  Cheque_Golden_Weave_Window:"Елегантен дизайн в златисто каре.",
  Cheque_Green_Sun:"Зелено слънце",
  Cheque_Green_Sun_Window:"Дълбок и успокояващ кариран дизайн.",
  Cheque_Blue_Checkers:"Сини пулове",
  Cheque_Blue_Checkers_Window:"Дизайн в синьо каре.",
  Cashiers_Check:"Касиерски чек",
  Cashiers_Check_Window:"Шаблон за стил на касиерски чек.",
  Cheque_Aqua_Checkers:"Аква шашки",
  Cheque_Aqua_Checkers_Window:"Аква чек дизайн.",
  Cheque_Golden_Checkers:"Златни пулове",
  Cheque_Golden_Checkers_Window:"Дизайн със златно каре.",
  Upgrade_Unavailable:"Няма налични надстройки",
  Bank_Code_Protection:"Защита на банков номер",
  Bank_Code_Protection_Word:"Защитете банковите си номера от използване на това приложение, работещо на друго устройство или за друг потребител. Това действие е НЕОБРАТИМО. Продължи?",
  Bank_Code_Protection_Blocked_Word:"Банковите номера, които се опитвате да използвате, са запазени за друг потребител или устройство.",
  Bank_Code_Protection_Error_Word:"Проверката на номера е неуспешна. Моля, свържете се с интернет и опитайте да добавите тази банкова сметка отново.",
  Bank_Code_Protection_Set_Error_Word:"Защитата на банков номер изисква да сте свързани с интернет. Моля, свържете се и опитайте отново.",
  Upgrade_Unavailable_Word:"Съжаляваме, имаме проблеми с проверката ви. Абонаменти и надстройки на Чекове в момента не са налични за покупка във вашия район.",
  PayPal_Payment_Preview:"Визуализация на плащанията в PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Изберете PayPal",
  PayPal_Applications:"Приложения на PayPal",
  Purchase_With_Apple_Pay:"Купувайте с Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Компании",
  Insurance:"Застраховка",
  New_PayPal:"Нов PayPal",
  Pay_By:"Плати чрез",
  Insure:"Застраховайте",
  Miles:"Майлс",
  Payment_Method:"Начин на плащане",
  Select_Policies:"Изберете Правила",
  Policies:"Политики",
  Policy:"Политика",
  No_PayPal_Account:"Няма акаунт в PayPal",
  No_Policies:"Без застрахователни полици",
  New_Policy:"Нова политика",
  PayPal_Payment:"PayPal плащане",
  PayPal_Payments:"PayPal плащания",
  No_Payment_Selected:"Няма избрано плащане",
  Sending_Payment_Word:"Моля, изчакайте... Това плащане се изпраща.",
  Sending_Payments_Word:"Моля, изчакайте... Плащанията се изпращат.",
  No_Payment_Selected_PayPal:"Не е избрано <a routerLink='/folder/Payments'> плащане чрез PayPal</a> за изпращане.",
  Payment_Sent:"Плащането е изпратено",
  PayPal_Payment_Sent:"Това плащане е изпратено с PayPal.",
  Copay:"Доплащане",
  Dead:"Мъртъв",
  Alive:"жив",
  Not_Dead:"Не е мъртъв",
  Unclaimed:"Непотърсен",
  Attempted:"Опит",
  Deceased:"починал",
  Claimed:"Твърдеше",
  Unpaid:"Неплатени",
  Sending_Payment:"Изпращане на плащане",
  Sending_Payments:"Изпращане на плащания",
  Send_PayPal_Confirmation:"Искате ли да изпратите тази транзакция с PayPal?",
  Send_PayPal_Confirmation_Word:"Натиснете зеления бутон, за да изпратите тази транзакция.",
  Save_Payment_As_Unpaid:"Да се запази ли това плащане като неплатено?",
  Payment_Pay_Confirmation_PayPal:"Да се запази ли това плащане като платено?",
  No_Policies_Word:"Добавете първата <a routerLink='/folder/Postage/New'> застрахователна полица</a> сега.",
  Timesheet_Multiple_Delete_Confirmation:"Сигурни ли сте, че искате да изтриете множество графики?",
  Select_Multiple_Timesheets_Prompt:"Няма избрани графици. Изберете поне един график.",
  Select_Multiple_Policies_Prompt:"Няма избрани правила. Изберете поне една застрахователна полица.",
  Policies_Multiple_Delete_Confirmation:"Сигурни ли сте, че искате да изтриете няколко правила?",
  Company:"Компания",
  Add_Company:"Добавете фирма",
  New_Company:"Добавете фирма",
  No_Companies:"Без фирми",
  Enable_Company:"Активиране на компанията",
  Select_Company:"Изберете Компания",
  The_Default_Company:"Фирменият етикет по подразбиране.",
  Manage_Companies:"Управление на компании",
  No_Companies_Word:"Проверките ще използват фирма по подразбиране.<br />Добавете <a routerLink='/folder/Company/New'>първата компания</a>.",
  Default_Company:"Фирма по подразбиране",
  Cheques_Unlimited_Word:"Checks Unlimited ви позволява да отпечатате колкото искате чекове.",
  Cheques_Subscription_Word:"Абонаментът за чекове ви позволява да отпечатате колкото искате чекове.",
  You_Own_This_Product:"Вие притежавате този продукт.",
  Your_Subscription_is_Active:"Вашият абонамент е активен.",
  Active_Products:"Активирани продукти",
  Purchase_Confirmation:"Покупка",
  Purchase_Cheques:"Чекове за покупка",
  Restore_Purchase:"Възстановяване на покупките",
  Erase_Purchase:"Изтриване на покупки",
  Successfully_Purchased:"Успешно закупен",
  Enter_Your_Licence_Key:"Моля, въведете вашия лицензен ключ на тази страница, за да активирате този продукт.",
  Licence_Key:"Лицензионен ключ",
  Enter_Licence_Key:"Въведете лицензионен ключ",
  Purchased:"Закупени",
  Enable_Feature:"Активиране на функцията",
  Cancel_Subscription:"Отказ от абонамент",
  Subscription_Removed:"Абонаментът е премахнат",
  Select_Active_Subscription:"Изберете активен абонамент, за да го промените.",
  Remove_Subscription_Word:"Сигурни ли сте, че искате да анулирате този абонамент?",
  Delete_Company_Confirmation:"Сигурни ли сте, че искате да изтриете цялата тази компания? ПРЕДУПРЕЖДЕНИЕ: Ще загубите цялата съхранена информация!",
  Delete_Default_Company_Word:"Не можете да изтриете фирмата по подразбиране. Искате ли да нулирате приложението и да го възстановите до състоянието по подразбиране? ПРЕДУПРЕЖДЕНИЕ: Ще загубите цялата съхранена информация!",
  Console_Warning_2:"Не обработвайте никаква валута с това приложение, която в момента не е ваша.",
  Terms_and_Conditions:"Правила и условия",
  and_the:"и на",
  for:"за",
  Please_Read_Word:"Моля, прочетете и се съгласете с",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Някои валутни курсове не можаха да бъдат намерени. Моля, свържете се с интернет.",
  Free:"Безплатно",
  DB_Erase_Prompt_2:"Напълно да изтриете цялата база данни на разработчиците? ПРЕДУПРЕЖДЕНИЕ: Ще загубите цялата информация ЗА ПОКУПКА и АБОНАМЕНТ!",
  Successfully_Imported:"Успешно импортиран",
  Select_Postage:"Изберете Пощенски разходи",
  No_Postage:"Без пощенски марки",
  No_Paid_Postage_Word:"Добавете първата марка <a routerLink='/folder/Postage/New'>Платени пощенски услуги</a>.",
  Trial_Complete:'Пробният период е завършен',
  Please_Upgrade:'Моля, надстройте Чекове, за да продължите да печатате.',
  Aa:"Аа",
  Color:"Цвят",
  Font:"Шрифт",
  Guide:"Ръководство",
  Guides:"водачи",
  Image:"Образ",
  Zoom:"Увеличаване",
  Logo:"Лого",
  Bank:"банка",
  MICR:"MICR",
  Total:"Обща сума",
  Cancel:"Отмяна",
  Confirm:"Потвърдете",
  Method:"Метод",
  Biometric_Security:"Биометрична сигурност",
  Please_Login_To_Continue:"Моля, влезте, за да продължите.",
  Complete:"Завършен",
  Sign_Up:"Регистрирай се",
  Error:"Грешка",
  Biometrics:"Биометрия",
  Percent:"Процент",
  Zero_Percent:"0%",
  Top_Margin:"Горен марж",
  Bottom_Margin:"Долен марж",
  Left_Margin:"Ляво поле",
  Right_Margin:"Десен марж",
  MICR_Margin:"MICR марж",
  Table_Margin:"Марж на таблицата",
  Address_Margin:"Марж на адреса",
  Percentage_:"Процент",
  Vacation_Title:"Заглавие на ваканция",
  Use_PIN:"Използвайте ПИН",
  Loading__:"Зареждане...",
  Design_Title:"Заглавие на дизайна",
  Authorize:"Разрешете",
  Key:"Ключ",
  Public_Key:"Публичен ключ",
  Private_Key:"Частен ключ",
  Authenticate:"Удостоверяване",
  Last_Payroll:"Последна ведомост",
  Last_Calculation:"Последно изчисление",
  Authorized:"Упълномощен",
  Geo_Authorized:"Гео-местоположение: разрешено",
  Not_Authorized:"Неоторизиран",
  Authorization_Complete:"Упълномощаването е завършено",
  Geolocation_Authorization:"Разрешение за геолокация",
  Out_of_Bounds:"Извън границите",
  Cant_Delete_Default_Design:"Не може да се изтрие дизайн по подразбиране.",
  Unauthorized_Out_of_Bounds:"Неупълномощен: Извън границите",
  Biometric_Authorization:"Биометрично разрешение",
  Locating_Please_Wait:"Намиране, моля изчакайте...",
  Test_Biometrics:"Тествайте биометрика",
  Cheque_Margins:"Проверете маржовете",
  Percentage_Full_Error:"Процентното поле не може да бъде зададено над 100% процента.",
  No_Payroll_Text:"Добавете <a routerLink='/folder/Employee/New'>Служител</a> или <a routerLink='/folder/Payee/New'>Получател</a> и <a routerLink='/folder/Schedule/New'>График</a>.",
  Design_Saved:"Дизайнът е запазен",
  Default_Design_Selected:"Избран е дизайн по подразбиране",
  Partial_Import:"Частичен внос",
  Partial_Export:"Частичен износ",
  Entire_Import:"Цял внос",
  Entire_Export:"Цял износ",
  Existing_Password:"Моля, въведете съществуващата си парола:",
  Existing_PIN:"Моля, въведете съществуващия си ПИН код:",
  Pin_Change_Header:"ПИН потвърждение",
  Pin_Change_SubHeader:"Въведете стария си ПИН номер, за да потвърдите промяната.",
  Pass_Change_Header:"Потвърждение на парола",
  Pass_Change_SubHeader:"Въведете старата си парола, за да потвърдите промяната.",
  PIN_Enter_Message:"Въведете своя ПИН, за да потвърдите.",
  Password_Enter_Message:"Въведете паролата си, за да потвърдите.",
  Pin_Updated_Success:"ПИН кодът е актуализиран успешно!",
  Pin_Updated_Fail:"ПИН кодът не можа да бъде актуализиран.",
  Pass_Updated_Success:"Паролата е актуализирана успешно.",
  Pass_Updated_Fail:"Паролата не можа да бъде актуализирана.",
  Preview_Payment:"Предварителен преглед на плащането",
  Preview_Payroll:"Преглед на ведомост",
  No_Payments_Created:"Не бяха открити направени плащания.",
  Payment_Preview:"Предварителен преглед на плащането",
  Enable_Payee:"Активиране на Получател",
  Rendered:"Рендериран",
  No_Email:"Няма имейл",
  Setup_Cheques:"Проверки за настройка",
  name:"име",
  address:"Адрес",
  address_2:"Адрес 2",
  city:"град",
  province:"провинция",
  postal_code:"Пощенски/пощенски код",
  country:"Държава",
  username:"Потребителско име",
  full_name:"Пълно име",
  birthday:"рожден ден",
  email:"електронна поща",
  phone:"Телефон",
  employees:"Служители",
  addresses:"Адреси",
  payment_amount_limit:"Лимит на сумата на плащането",
  total_limit:"Общ лимит",
  payees:"Получатели",
  description:"Описание",
  address_line_one:"Адрес първи",
  address_line_two:"Адрес втори ред",
  image:"Образ",
  account_holder:"Титуляр на партида",
  cheque_starting_id:"Проверете началния идентификатор",
  transit_number:"Транзитен номер",
  institution_number:"Номер на институция",
  designation_number:"Номер на обозначение",
  account_number:"Номер на сметка",
  currency:"Валута",
  signature:"Подпис",
  payment_payroll_limit:"Лимит за плащане",
  total_limi:"Общо ограничение",
  date:"Дата",
  printed_memo:"Печатна бележка",
  banks:"банки",
  signature_image:"Подпис изображение",
  address_name:"Име на адреса",
  notes:"Бележки",
  design:"Дизайн",
  title:"Заглавие",
  frequency:"Честота",
  fax:"факс",
  salaries:"Заплати",
  salary_ids:"Идент. № на заплатите",
  start_time:"Начален час",
  end_time:"Крайно време",
  paid:"Платено",
  overtime_percentage:"Платен процент",
  overtime_amount:"Платена фиксирана сума",
  first_name:"Първо име",
  last_name:"Фамилия",
  moderation:"Умереност",
  create:"Създайте",
  edit:"редактиране",
  destroy:"Унищожи",
  day_start_time:"Day_start_time",
  day_end_time:"Day_end_time",
  fullname:"име",
  time:"Време",
  auto_send:"Автоматично изпращане",
  time_method:"Времеви метод",
  schedules:"Графици",
  indefinite_payroll_enabled:"Активиране за неопределено време",
  amount:"Количество",
  repeat:"Повторете",
  always_enabled:"Винаги активиран",
  start_date:"Начална дата",
  end_date:"Крайна дата",
  Cheque_Total:"Проверете Общо",
  Total_Amount:"Обща сума:",
  Amounts:"Суми:",
  Images:"Изображения",
  Files:"Файлове",
  Previewing:"Визуализация:",
  Insert:"Вмъкване",
  Preview_Import:"Импортиране на предварителен преглед",
  Entry:"Влизане",
  Entries:"Вписвания",
  No_Entries:"Няма записи",
  Import_Type:"Тип на импортиране",
  Select_Details:"Изберете Подробности",
  Select_Payee:"Изберете Получател",
  Enable_Holidays:"Активирайте празници",
  Disable_Holidays:"Деактивирайте празниците",
  Wire_Transfer:"Банков превод",
  New_Export:"Нов износ",
  Export_Data:"Експортиране на данни",
  Export_Data_Word:"Изберете типа файл за експортиране и изтегляне.",
  Export_File:"Експортиране на файл",
  Mode:"режим",
  Times:"времена",
  Widgets:"Widgets",
  Slider:"Плъзгач",
  Set_Details:"Задайте подробности",
  Select_Type:"Изберете Тип",
  Display_Slider:"Плъзгач на дисплея",
  Dashboard_Slider:"Плъзгач на таблото",
  Dashboard_Mode:"Режим на таблото",
  Show_Intro:"Покажи въведение",
  Show_Payrolls:"Показване на заплати",
  Show_Holidays:"Покажи празници",
  Show_Invoices:"Показване на фактури",
  Show_Cheques:"Покажи чекове",
  Enabled_Widgets:"Разрешени джаджи",
  Disabled_Widgets:"Деактивирани джаджи",
  Colors:"Цветове",
  Barcodes:"Баркодове",
  View_Timers:"Преглед на таймери",
  Gradients:"Градиенти",
  No_Info:"Няма информация",
  Disable:"Деактивирайте",
  Show_Layer:"Показване на слоеве",
  Hide_Layer:"Скриване на слоеве",
  Text_Layer:"Текстови слоеве",
  Secondly:"Второ",
  Minutely:"на минута",
  nine_am:"9:00 часа сутринта",
  five_pm:"17:00 ч",
  Enable_Address:"Активиране на адрес",
  Invalid_File_Type:"За съжаление е избран невалиден файлов тип. Поддържан тип файл:",
  Error_Updating_Entry:"За съжаление възникна грешка при актуализирането на този запис.",
  Schedule_Timing_Alert:"Грешка: Началният час на графика е зададен на стойност след крайния час.",
  Select_Multiple_Payments_Prompt:"Няма избрани плащания. Изберете поне едно плащане.",
  Select_Multiple_Cheques_Prompt:"Няма избрани проверки. Моля, изберете поне една проверка.",
  Select_Multiple_Items_Prompt:"Няма избрани елементи. Моля, изберете поне един елемент.",
  Paymemt_Multiple_Delete_Confirmation:"Наистина ли искате да изтриете няколко плащания?",
  Cheque_Multiple_Delete_Confirmation:"Наистина ли искате да изтриете няколко проверки?",
  Payee_Multiple_Delete_Confirmation:"Наистина ли искате да изтриете няколко бенефициента?",
  Employee_Multiple_Delete_Confirmation:"Наистина ли искате да изтриете няколко служители?",
  MICR_Warning:"Забележка: Някои принтери и устройства може да не показват правилно шрифта MICR.",
  Automatically_Send:"Автоматично изпращане",
  Type:"Тип",
  Payment_Type:"Вид плащане",
  Auto_Send:"Автоматично изпращане",
  Automatically_Process:"Автоматично обработване",
  Auto_Process:"Автоматичен процес",
  Image_Based:"На базата на изображения",
  Font_Based:"Базиран на шрифт",
  Rerender:"Повторно изобразяване",
  Rendering:"Изобразяване",
  Render:"Файлове",
  Top:"Горна част",
  Middle:"Среден",
  Bottom:"отдолу",
  Top_Left:"Горе вляво",
  Top_Center:"Горен център",
  Top_Right:"Горе в дясно",
  Middle_Left:"Среден ляв",
  Middle_Center:"Среден център",
  Middle_Right:"Средно дясно",
  Bottom_Left:"Долу вляво",
  Bottom_Center:"Долен център",
  Bottom_Right:"Долу вдясно",
  Numbers:"Числа",
  Verified:"Потвърдено",
  Paper_Size:"Размер на хартията",
  New_Device:"Ново устройство",
  Add_Device:"Добави устройство",
  Remove_Device:"Премахване на устройството",
  Delete_Device:"Изтриване на устройството",
  Block_Device:"Блокиране на устройството",
  Block:"Блокиране",
  Unblock:"Отблокиране",
  Table:"Таблица",
  Scan_Login_Code:"Сканирайте кода за вход",
  Login_Code:"Код за вход",
  Scan_Code:"Код за сканиране",
  Scan_QR_Code:"Сканирайте QR код",
  Select_All:"Избери всички",
  Deselect_All:"Премахнете избора на всички",
  Increase:"Нараства",
  Decrease:"Намаляване",
  Bold:"Удебелен",
  Text:"Текст",
  Style:"стил",
  Italic:"Курсив",
  QR_Code:"QR код",
  Barcode:"Баркод",
  Browse_Images:"Преглед на изображения",
  Browse_Files:"Преглед на файлове",
  Background_Image:"Фоново изображение",
  Logo_Image:"Изображение на логото",
  Header_Image:"Заглавно изображение",
  Bank_Image:"Изображение на банката",
  Cut_Lines:"Линии на изрязване",
  Background:"Заден план",
  License:"Разрешително",
  One_License:"1 лиценз:",
  Licensed:"Лицензиран за:",
  Not_Licensed:"Не е лицензиран",
  Enter_Serial:"Въведете сериен номер",
  Serial_Key:"Сериен ключ",
  Serial:"Сериен",
  Product_Key:"Продуктов ключ",
  Check_Product_Key:"Проверете продуктовия ключ",
  Add_Product_Key:"Добавете продуктов ключ",
  Verifying_Purchase:"Покупката се потвърждава...",
  Print_Envelope:"Печат на плик",
  Envelope:"Плик",
  Thank_You:"Благодаря ти!",
  Scale:"Мащаб",
  Print_Scale:"Печатна скала",
  Borders:"Граници",
  VOID:"НИЩАВА",
  Void_Cheque:"Проверка за невалидна",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ПЛАЩАТЕ ПО ПОРЪЧКА НА:",
  NO_DOLLARS:"БЕЗ ДОЛАРИ ",
  ONE_DOLLAR:"ЕДИН ДОЛАР",
  DOLLARS:" ДОЛАРИ",
  AND:" И ",
  CENTS:" CENTS.",
  NO_:"НЕ ",
  ONE_:"ЕДИН ",
  AND_NO_:"И НЕ ",
  _AND_ONE_:"И ЕДНО ",
  DOLLARS_AND_ONE_CENT:" И ЕДИН ЦЕНТ.",
  AND_NO_CENTS:" И НУЛА СТЕНТА.",
  CHEQUE_PRINT_DATE:"ДАТА:",
  CHEQUE_PRINT_MEMO:"БЕЛЕЖКА:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"депутат",
  Initial_Tasks:"Първоначални задачи",
  Inital_Setup:"Първоначална настройка",
  Welcome_To:"Добре дошли в ",
  Welcome:"Добре дошли",
  Swipe:"Прекарайте пръст",
  Intro_Setup:"Въвеждаща настройка",
  Introduction:"Въведение",
  CHEQUE_PRINT_CREDIT:"Осъществено от чекове",
  Title_Intro_Word:"Добре дошли в чековете",
  Title_Intro:"Въведение в чековете",
  Title_Setup:"Настройка на чекове",
  PayPal_Default_Email_Message:"Получихте нов превод в PayPal. [Осъществено от чекове]",
  Cheques_App_Export:"Експортирано с чекове",
  Post_Intro_Thanks:"Благодарим ви, че избрахте Чекове. Процесът на настройка вече е завършен.",
  Post_Intro_Word:"Започнете, като разпечатате първия си чек, добавете бъдещо плащане или добавете служители към ведомостта.",
  Upgrade_Required:"Cheques изисква да притежавате по-премиум версия на софтуера, за да скриете това съобщение и да отключите допълнителни функции.",
  Upgrade_Title:"чекове",
  Mailout_Prompt:"Можете допълнително да изберете чекове да изпращат по пощата вашите чекове за заплати вместо вас.",
  Mailed_Cheque:"Изпратен чек: ",
  Mailed_Cheque_Color:"Изпратен чек (цвят): ",
  Terms_Purchase:"Всички електронни покупки с чекове подлежат на пълно възстановяване за период до 30 дни от датата на покупка. Моля, прочетете и се съгласете с <a href='#'>Общите условия</a>, преди да продължите.",
  Dashboard_Setup:"Настройка на основния принтер",
  Dashboard_Add:"Добавяне на основната банкова сметка",
  Dashboard_Customize:"Изберете шаблон за проверка",
  Dashboard_Job_Salary:"Създайте своята работа и добавете своята заплата",
  Dashboard_Employees:"Проследяване на служители и получатели",
  Dashboard_Print:"Отпечатайте и изпратете чековете си по пощата",
  Dashboard_Payroll:"Автоматизирайте отпечатването на вашата ведомост",
  Dashboard_PayPal:"Настройване на PayPal Payroll/Изплащания",
  Begin_Setup:"Започнете настройка",
  Get_Started:"Първи стъпки",
  Purchase:"Покупка",
  Lockdown:"Затваряне",
  Unlock:"Отключване",
  Detailed:"Подробно",
  Log_In:"Влизане",
  Login:"Влизане",
  Launch:"Стартиране",
  Register:"Регистрирам",
  Finish:"Завършек",
  List:"списък",
  Weekends:"Уикенди",
  Weekly:"седмичен",
  PayPal_Default_Subject:"Ново плащане",
  Payment_Message:"Съобщение за плащане",
  PayPal_Default_Payment_Note:"Благодаря ти",
  Setup_Your_Cheqing_Account:"Чекова сметка",
  Add_Your_Primary_Cheqing_Account:"Добавете основната си сметка за проверка.",
  Welcome_Word:"Опростете и автоматизирайте управлението на заплатите и човешките ресурси.",
  Get_Started_Quickly:"Просто отговорете на няколко прости въпроса, които ще ни помогнат да започнем...",
  Done_Intro_Word:"Настройката завършена",
  PIN_Protected:"Защитени с парола и ПИН",
  Enter_New_PIN:"Въведете нов ПИН код:",
  Enter_PIN:"Въведете ПИН код:",
  Invalid_PIN:"Въведен е невалиден ПИН.",
  Account_Identifier:"Идентификатор на акаунт",
  New_Account_Identifier:"Нов идентификатор на акаунт",
  attempt:"опит",
  attempts:"опити",
  remaining:"оставащ",
  Language:"език",
  languages:"езици",
  select_languages:"Избери език",
  Deposit:"депозит",
  Hire_One_Now:"Наемете един сега",
  App_Locked:"Приложението е заключено.",
  Skip_:"Пропускане",
  Skip_to_Dashboard:"Прескочи към таблото за управление",
  Dashboard:"Табло",
  Import:"Импорт",
  Admin:"Администратори",
  New_Admin:"Нов администратор",
  Settings:"Настройки",
  Color_Picker:"Средство за избиране на цвят",
  Font_Picker:"Избор на шрифтове",
  Logout:"Излез от профила си",
  Close:"Близо",
  Close_menu:"Близо",
  Excel:"Excel файл",
  Csv:"CSV файл",
  Sql:"SQL файл",
  Json:"JSON файл",
  Url:"Импортиране по URL",
  Back:"Обратно",
  Timers:"таймери",
  Cheque:"Проверка",
  Print:"Печат",
  Designs:"Дизайн",
  Pause_Printing:"Пауза на печата",
  Resume_Printing:"Възобновяване на печата",
  Printing_Paused:"Печатът е на пауза",
  PrintingUnavailable:"Съжаляваме! Печатът не е наличен в тази система.",
  Prints_Paused:"Разпечатките са на пауза",
  Administration:"Администрация",
  Loading:"Зареждане",
  Unnamed:"Без име",
  error:"За съжаление тази проверка не можа да бъде отворена за преглед.",
  No_Cheques_To_Print:"Без проверки за отпечатване",
  No_Cheques_To_Print_Word:"Създайте <a routerLink='/folder/Cheque/New'>Нов чек</a>.",
  New_Cheque:"Нов чек",
  Start_One_Now:"Започнете едно сега",
  Edit_Cheque:"Редактиране на проверка",
  Select_Cheques:"Избор на проверки",
  Select_Employee:"Избор на служител",
  Default_Printer:"Принтер по подразбиране",
  Reassign:"Преназначи",
  Configure:"Конфигуриране",
  Template:"Шаблон",
  Designer:"Дизайнер",
  Edit_Designs:"Редактиране на дизайни",
  New_Design:"Нов дизайн",
  Next:"Следващия",
  Save:"Запазване",
  Name:"Име",
  Mail:"поща",
  Amount:"Количество",
  Date:"Дата",
  PayPal:"PayPal",
  Payouts:"Изплащания",
  PayPal_Label:"Етикет на PayPal",
  Email_Username:"Имейл / потребителско име",
  Client_ID:"Идентификатор на клиента",
  Sandbox_Email:"Имейл в пясъчна среда",
  PayPal_Email:"Имейл на PayPal",
  PayPal_Username:"Потребителско име на API",
  PayPal_Payouts:"Изплащания на PayPal",
  Select_PayPal_Key:"Изберете ключ за PayPal",
  Secret:"Тайна",
  API_Secret:"тайна на API",
  PayPal_API_Keys:"Ключове на PayPal",
  New_PayPal_Key:"Нов ключ за PayPal",
  Email_Subject:"Тема на имейла",
  Email_Message:"Имейл съобщение",
  Payout_Options:"Опции за изплащане",
  Payment_Note:"Бележка за плащане",
  Enable_Employee:"Активиране на служител",
  Enable_Production_Mode:"Активиране на производствен режим",
  Sandbox_Username:"Потребителско име в пясъчна среда",
  Sandbox_Signature:"Sandbox Signature",
  Sandbox_Password:"Парола за пясъчна среда",
  Production_Username:"Потребителско име за производство",
  Production_Signature:"Производствен подпис",
  Production_Password:"Производствена парола",
  Production_Email:"Производствен имейл",
  API_Client_ID:"Идентификатор на клиента на API",
  API_Signature:"подпис на API",
  API_Password:"Парола за API",
  API_Username:"Потребителско име на API",
  Secret_Key:"Секретен ключ",
  Sandbox:"пясъчник",
  Production:"Производство",
  Sandbox_Keys:"Ключове за пясъчна кутия",
  Production_Keys:"Производствени ключове",
  API_Title:"Заглавие на API",
  Production_Mode:"Производствен режим",
  Account_Label:"Етикет на акаунт",
  No_PayPal_Setup:"Без ключ за PayPal",
  Enable_PayPal_Account:"Активиране на PayPal акаунт",
  No_PayPal_Word:"Добавете своя <a routerLink='/folder/Invoice/New'>ключ за API на PayPal</a>.",
  Printed_Memo:"Печатна бележка",
  Employee:"Служител",
  View_Employee:"Преглед на служител",
  Mailing_Address:"Пощенски адрес",
  Company_Address:"Адрес на компанията",
  Select_Company_Address:"Изберете адрес на фирма",
  Address:"Адрес",
  Any_Day:"Всеки ден",
  Address_Name:"Име на адрес",
  Unit:"Мерна единица",
  Account:"Сметка",
  Bank_Account:"Банкова сметка",
  Account_Limits:"Активиране на ограниченията на акаунта",
  Payroll:"Заплата",
  New_Payroll:"Нова ведомост",
  No_Payroll:"Няма предстояща ведомост",
  Upcoming_Holiday:"Предстоящ празник:",
  Invoice_Due:"Дължим на фактура:",
  New_Invoice:"Нова фактура",
  No_Invoices:"Без фактури",
  No_Invoices_Word:"Създайте първата <a routerLink='/folder/Invoice/New'>фактура</a>.",
  Cheque_Due:"Дължим на чек:",
  Payrolls:"Заплати",
  Sandbox_Mode:"Режим пясъчна кутия",
  Hire:"Наемане",
  Pay:"Плащане",
  New:"Нов",
  Add:"Добавяне",
  Make:"Направи",
  Time:"време",
  Write:"Пиши",
  Holiday:"Празник",
  Holidays:"Почивни дни",
  Next_Holiday:"Следваща почивка:",
  All_Done:"Готово!",
  Employees:"Служители",
  Payees:"Получатели",
  Job:"работа",
  Jobs:"Работни места",
  Invoice:"фактура",
  Invoices:"фактури",
  Vacations:"Почивки",
  Cheques:"Проверки",
  Brand_Cheques:"Марка",
  Payment:"Плащане",
  Enable_Payment:"Активиране на плащане",
  Payments:"плащания",
  Schedule:"График",
  Schedules:"Графици",
  Timesheet:"Технически лист",
  Timesheets:"Списъци с часове",
  Salary:"Заплата",
  New_Address:"Нов адрес",
  Address_2:"Адрес (ред 2)",
  _City:"Град",
  _State:"Щат/Пров",
  City:"Град/Град",
  State:"щат/провинция",
  Postal:"Пощенски код",
  ZIP:"Пощенски / ZIP",
  Country:"Страна",
  Addresses:"Адреси",
  Required_Options:"Необходими опции",
  Optional_Options:"Опции по избор",
  Additional_Options:"Допълнителни опции",
  Required:"Задължително",
  Optional:"По избор",
  Additional:"Допълнителен",
  No_Addresses:"Без адреси",
  New_Address_Word:"Добавете първия <a routerLink='/folder/Address/New'>адрес</a>.",
  Select_Address:"Избор на адрес",
  No_Address:"Без адреси",
  Print_Cheque:"Проверка за отпечатване",
  Print_Cheque_Now:"Отпечатайте чек сега",
  Description:"описание",
  Pay_To_The_Order_Of:"Плащане по реда на:",
  Select_Date_Range:"Избор на период от време",
  Select_Starting_Date:"Изберете начална дата",
  Select_Ending_Date:"Изберете крайна дата",
  Select_Date:"Избор на дата",
  Cheque_Date:"Дата на проверка",
  Cheque_Memo:"Проверка на бележка",
  Blank_Cheque:"Празен чек",
  Blank:"Празно",
  No_Cheques:"Без проверки",
  No_Cheques_Word:"Отпечатайте първата си <a routerLink='/folder/Cheque/New'>чек</a>.",
  Cheque_Amount_Placeholder:"$0,00",
  View_Image:"Преглед на изображението",
  View:"Преглед",
  Modify:"Промяна",
  Delete:"Изтрий",
  Cheque_Paid:"Платено",
  New_Deduction:"Нова дедукция",
  Title:"Заглавие",
  Frequency:"Честота",
  Hourly:"почасово",
  Daily:"Ежедневно",
  Weekdays:"Делни дни",
  BiWeekly:"Две седмици",
  TriWeekly:"3 седмици",
  Monthly:"Месечно",
  MiMonthly:"2 месеца",
  Quarterly:"Тримесечно",
  Yearly:"Ежегодно",
  Every_Week:"Всяка седмица",
  Every_Payroll:"Всяка ведомост",
  Every_Month:"Всеки месец",
  Annually:"Ежегодно",
  Always_Scheduled:"Винаги насрочено",
  Start_Date:"Начална дата",
  End_Date:"Крайна дата",
  Start_Time:"Начален час",
  End_Time:"Крайно време",
  Deduction_Label:"Етикет за приспадане",
  Notes:"Бележки",
  Options:"Настроики",
  Enable:"Активиране",
  Select_Deductions:"Изберете удръжки",
  Deductions:"Удръжки",
  No_Deductions:"Без удръжки",
  No_Deductions_Word:"Създайте своя първи <a routerLink='/folder/Deduction/New'>Приспадане</a>.",
  New_Employee:"Нов служител",
  No_Title:"Без заглавие",
  _Name:"Име",
  About_Yourself:"За себе си",
  Full_Name:"Пълно име",
  Birthday:"Рожден ден",
  Email:"Електронна поща",
  SMS:"СМС",
  Phone:"телефон",
  Test:"Тест",
  Call:"Повикване",
  Fax:"факс",
  Printed_Note:"Печатна бележка",
  Position:"Позиция",
  Job_Position:"Работна позиция",
  Select_a_Job:"Изберете работа",
  Select_a_Salary:"Изберете заплата",
  Add_a_Deduction:"Добавяне на приспадане",
  Taxes:"данъци",
  Add_Taxes:"Добавяне на данъци",
  Date_of_Birth:"Дата на раждане",
  Email_Address:"Имейл адрес",
  Phone_Number:"Телефонен номер",
  Phone_Call:"Телефонно обаждане",
  Enable_on_Payroll:"Активиране при заплащане",
  Select_Employees:"Избор на служители",
  No_Employees:"Без служители",
  No_Employees_Word:"Добавете първия си нов <a routerLink='/folder/Employee/New'>Служител</a>.",
  No_Name:"Без име",
  Unemployeed:"Безработен",
  Employeed:"Нает",
  Paid:"Платено",
  Enabled:"Активирано",
  Disabled:"Хора с увреждания",
  Fire:"Огън",
  Not_Available:"Не е наличен",
  Not_Available_Word:"Отпечатайте първата си <a routerLink='/folder/Invoice/New'>фактура</a> и получете плащане.",
  Select_Invoices:"Избор_фактури",
  Print_Invoice_Word:"Отпечатайте първата си <a routerLink='/folder/Invoice/New'>фактура</a> и получете плащане.",
  Invoice_Title:"Заглавие на фактурата",
  Invoice_Date:"Датата на фактурата",
  Due_Date:"Дата на падежа",
  New_Job:"Нова работа",
  Details:"Подробности",
  Customize:"Персонализиране",
  Customize_Dashboard:"Персонализиране на таблото",
  Components:"Компоненти",
  No_Components:"Без компоненти",
  Main_Components:"Главни компоненти",
  Smaller_Components:"По-малки компоненти",
  Error_Loading_Page:"Грешка при зареждането на тази страница.",
  Bank_Details:"Банкова информация",
  About_Your_Job:"За вашата работа",
  Your_Schedule:"Вашият график",
  Job_Title:"Позиция на заеманата длъжност",
  Job_Description:"Описание на работата",
  Job_Details:"Подробности за работа",
  Enable_Job:"Активиране на работа",
  Pay_Period:"Период на плащане",
  Perpetually_Schedule:"Постоянен график",
  Select_Jobs:"Избор на работни места",
  No_Jobs:"Няма работа",
  Add_Jobs:"Добавяне на работни места",
  No_Jobs_Word:"Добавете първата <a routerLink='/folder/Job/New'>Работа</a> към списъка.",
  Count_Employees:"job.employee_count+' Служители'",
  Zero_Employees:"0 служители",
  New_Leave:"Нов отпуск",
  Leave_Name:"Оставете име",
  Paid_Leave:"Платен отпуск",
  Leave_Pay:"Оставете заплащането",
  Indefinite_Leave:"Безсрочен отпуск",
  Indefinite_Payroll:"Неограничена заплата",
  Leave:"Остави",
  Add_Schedules:"Добавяне на графици",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Активиране на отсъствието",
  Select_Leaves:"Избор на листа",
  No_Leaves:"Без отпуски",
  Leave_Of_Absence:"Отпуск",
  Leaves_Of_Absence:"Отпуска от отсъствие",
  New_Leave_of_Absense:"Нов отпуск",
  No_Leaves_Word:"Добавете първия <a routerLink='/folder/Leave/New'>отпуск</a>.",
  Not_Enabled:"Не е активирано",
  Absences:"Отсъствия",
  Payee:"Получател",
  New_Payee:"Нов бенефициент",
  Payee_Identifier:"Идентификатор на бенефициента",
  Payee_Name:"Име на платеца",
  Payee_Title:"Заглавие на получателя",
  Payment_Memo:"Платежно бележка",
  Select_Payees:"Избор на получатели",
  No_Payees:"Без бенефициенти",
  Add_Payee_Note:"Добавете първия <a routerLink='/folder/Payee/New'>Получател</a>.",
  New_Payees:"Нови получатели",
  About_The_Payment_Schedule:"Схема на плащане",
  Your_Payroll_Schedule:"Автоматична ведомост",
  New_Payment:"Ново плащане",
  Identifier:"идентификатор",
  Select:"Избор",
  Memo:"Бележка",
  Payment_Date:"Дата за плащане",
  Mark_as_Paid:"Маркиране като платено",
  Select_Payments:"Избор на плащания",
  No_Payments:"Без плащания",
  No_Payments_Word:"Създайте първото <a routerLink='/folder/Payment/New'>Плащане</a>.",
  Create_Payroll:"Създаване на ведомост",
  Properties:"Имоти",
  Payroll_Title:"Заглавие на заплати",
  Payroll_Notes:"Бележки за заплати",
  Payroll_Setup:"Настройка на заплатите",
  Tabulate_Payments:"Плащания в таблица",
  Tabulate:"Таблица",
  By:"От:",
  Payments_By:"Плащания от",
  Timesheets_And_Schedules:"Разписание и графици",
  Both:"И двете",
  Items:"Артикули",
  Add_Payees:"Добавяне на получатели",
  Add_Account:"Добавяне на профил",
  Enable_Account:"Активиране на акаунта",
  Enable_Payroll:"Активиране на заплащане",
  Print_Payroll:"Печатайте ведомост",
  No_Payrolls:"Без заплати",
  No_Payroll_Word:"Създайте своя първи <a routerLink='/folder/Payroll/New'>ТРЗ</a>.",
  View_more:"ВИЖ ПОВЕЧЕ",
  Less:"ПО-МАЛКО",
  Add_Payroll:"Добавяне на ведомост",
  Select_Payroll:"Избор на ведомост",
  About_Your_Salary:"Относно вашата заплата",
  Add_Salaries:"Добавяне на заплати",
  Add_Salary:"Добавяне на заплата",
  Salaries:"Заплати",
  No_Salaries:"Без заплати",
  No_Salaries_Word:"Добавете първата <a routerLink='/folder/Salary/New'>Заплата</a>.",
  Select_Salaries:"Избор на заплати",
  New_Salary:"Нова заплата",
  Salary_Name:"Идентификатор на заплата",
  Enable_Salary:"Активиране на заплата",
  Salary_Amount:"Размер на заплата",
  New_Schedule:"Нов график",
  Schedule_Title:"Заглавие на графика",
  Add_Address:"Добавяне на адрес",
  Repeat:"повторете",
  Design:"Дизайн",
  Edit_Design:"Редактиране на дизайна",
  Edit_this_Design:"Редактиране на този дизайн",
  Repeat_Payment:"Повторно плащане",
  Enable_Schedule:"Активиране на графика",
  Never:"Никога",
  Select_Schedule:"Избор на графици",
  No_Schedules:"Без графици",
  No_Schedules_Word:"Създайте първия <a routerLink='/folder/Schedule/New'>График</a>.",
  New_Administrator:"Нов администратор",
  Username:"Потребителско име",
  First_Name:"Първо име",
  Last_Name:"Фамилия",
  Add_an_Address:"Добавяне на адрес",
  Payment_Limit:"Ограничение на плащане",
  Total_Limit:"Общ лимит",
  Select_Accounts:"Избор на акаунти",
  No_Administrators:"Без администратори",
  No_Administrators_Word:"Създайте първия <a routerLink='/folder/Administrator/New'>администраторски акаунт</a>.",
  New_Administrators_Word:"Добавете първия <a routerLink='/folder/Administrator/New'>Администратор</a>",
  Cloud:"облак",
  Backup:"Резервно копие",
  Enable_iCloud:"Активиране на iCloud",
  Enable_Google_Drive:"Активиране на Google Drive",
  Enable_Microsoft_OneDrive:"Активиране на Microsoft OneDrive",
  Automatically_Backup:"Автоматично архивиране",
  FTP_Settings:"FTP настройки",
  URL_File_Prompt:"Моля, посочете местоположението за .xls / .xlsx / .json файл за импортиране:",
  URL_SQL_Prompt:"Моля, посочете местоположението на файла SQLite за импортиране:",
  FTP_Backup:"FTP архивиране",
  FTP_Import:"FTP импортиране",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"домакин",
  Port:"пристанище",
  Path:"пътека",
  Data_Path:"Път на данни",
  Enable_FTP_Account:"Активиране на FTP акаунт",
  HostnameIP:"Име на хост",
  Password:"Парола",
  Connection_Port:"Порт за връзка",
  Enable_MySQL_Database:"Активиране на MySQL база данни",
  Log:"Дневник",
  Reset:"Нулиране",
  Erase:"Изтрива",
  Export:"Експорт",
  Database:"База данни",
  Upload_CSV:"Качване на CSV",
  Download_CSV:"Изтегляне на CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Архив на MySQL",
  Internal_Notes:"Вътрешни бележки",
  Root_Path:"Корен път",
  Select_Backup:"Изберете архивиране",
  Add_New_Backup:"Добавяне на нов архив",
  First_Backup:"Настройте първото резервно копие...",
  Backups:"Резервни копия",
  Add_Backup:"Добавяне на резервно копие",
  No_Backups:"Няма резервни копия за намиране.",
  Select_Backup_Type:"Изберете типа архивиране, което искате да настроите...",
  Backup_Type:"Тип резервно копие",
  FTP_Drive:"FTP устройство",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Шофиране",
  Microsoft_OneDrive:"Шофиране",
  Import_Fields:"Полета за импортиране",
  Import_Fields_Word:"Използвайте този раздел, за да изберете <a routerLink='/folder/Holiday/New'>Импортиране</a> данни.",
  Upload:"Качване",
  Download:"Изтегли",
  Download_JSON:"Изтегляне като JSON данни",
  Download_SQL:"Изтегляне като SQL файл",
  New_Bank:"Нова банка",
  New_Account:"Нов акаунт",
  New_Bank_Account:"Нова банкова сметка",
  Upload_Image:"Качи изображение",
  Bank_Name:"Име на банката",
  Bank_Address:"Банкова сметка",
  Branch_Address:"Адрес на филиала",
  Address_on_Cheque:"Адрес",
  Cheque_Numbers:"Проверка на номера",
  Cheque_Details:"Проверете подробности",
  Bank_Logo:"Лого на банката",
  Cheque_ID:"Проверка на ID",
  Starting_Cheque_ID:"Начална проверка ID",
  Transit_Number:"Транзитен номер",
  Institution_Number:"Номер на институция",
  Designation_Number:"Номер на обозначение",
  Account_Number:"Номер на сметка",
  Limits:"Граници",
  Default_Limits:"Граници по подразбиране",
  Over_Limit:"Над лимита",
  Under_Limit:"Под лимит",
  Payroll_Limit:"Ограничение на заплатите",
  Enable_Bank_Account:"Активиране на банкова сметка",
  Select_Account:"Избор на акаунт",
  No_Bank_Account:"Без банкова сметка",
  No_Bank_Account_Word:"Добавете първата <a routerLink='/folder/Accounts/New'>банкова сметка</a>.",
  Bank_Accounts:"Банкови акаунти",
  No_Accounts:"Без акаунти",
  No_Accounts_Note:"Добавете първата <a routerLink='/folder/Accounts/New'>банкова сметка</a>.",
  Cheque_Designer:"Проверка на дизайнера",
  Cheque_Design:"Проверка на дизайна",
  Select_Design:"Изберете дизайн",
  Cheque_Designs:"Проверете дизайни",
  No_Cheque_Designs:"Без чек дизайни",
  No_Cheque_Designs_Word:"Създайте свой собствен <a routerLink='/folder/Settings/Cheque/Design/New'>Проверете дизайн</a>.",
  Set_Default:"Е активирана по подразбиране",
  Default:"По подразбиране",
  Remove:"Премахване",
  Folder:"Папка",
  Edit:"Редактиране",
  LoadingDots:"Зареждане...",
  Add_a_New_File:"Добавяне на <a href='#' (клик)='add()'>Нов файл</a> към",
  this_folder:"тази папка",
  FTP_Backup_Settings:"Настройки за архивиране на FTP",
  Secure_File_Transfer:"Сигурно прехвърляне на файлове",
  New_Holiday:"Нов празник",
  Add_Holiday:"Добавяне на празник",
  Holiday_Name:"Име на празника",
  Additional_Pay:"Допълнително заплащане",
  Enable_Holiday:"Активиране на почивка",
  Select_Holidays:"Изберете празници",
  No_Holidays:"Без празници",
  No_Holidays_Word:"Добавяне на първия <a routerLink='/folder/Holiday/New'>Официален празник</a>.",
  New_Import:"Нов внос",
  Import_Data:"Импортиране на данни",
  Import_Data_Word:"Изберете типа файл или метода на качване по ваш избор.<br /> Ще можете да изберете кои импортирани полета във файл съответстват на който и да е параметър в приложението след качване на поддържан файл.", 
  Import_File:"Импортиране на файл",
  Link_To_File:"Връзка към файл",
  Select_File:"Избери файл",
  New_Moderator:"Нов модератор",
  Allow_Moderation:"Разрешаване на модериране",
  Create_Paycheques:"Създаване на чекове на заплати",
  Edit_Paycheques_and_Data:"Редактиране на заплати и данни",
  Destroy_Data_and_Paycheques:"Унищожи данни и заплати",
  Bonus_Percentage:"Процент на заплата",
  Fixed_Amount:"Фиксирана сума",
  Select_Moderator:"Изберете модератор",
  No_Moderators:"Без модератори",
  Moderators:"Модератори",
  Moderator_Account:"Създайте първия <a routerLink='/folder/Administrator/New'>Акаунт на модератор</a>.",
  No_Moderators_Word:"Добавете първия <a routerLink='/folder/Administrator/New'>Модератор</a>.",
  Defaults:"По подразбиране",
  Provide_Us_Info:"предоставете ни информация",
  Setup_Your_Printer:"Настройте вашия принтер",
  Your_Company:"За вашата компания",
  Company_Name:"Име на фирмата",
  Currency:"Валута",
  Default_Currency:"Валута по подразбиране",
  Base_Monthly_Income:"Месечен приход",
  Protection:"Защита",
  App_Protection:"Защита на приложението",
  PIN_Code_Protection:"Защита на ПИН код",
  App_Protection_Word:"Активирайте методи за защита, които помагат за защитата на вашия акаунт.",
  PIN_Code:"ПИН код",
  Change_PIN:"Промяна на ПИН",
  New_Password:"Нова парола",
  Geofence_Protection:"Защита на гео-ограда",
  Geofence_Area:"Задаване на площ",
  Distance:"Разстояние",
  Setup_Now:"Настройте сега",
  Mile:"миля",
  Km:"км",
  m:"м",
  Facial_Recognition:"Разпознаване на лица",
  Face:"лице",
  Setup:"Настройвам",
  Label:"етикет",
  Password_Protection:"Защита с парола",
  Modify_Password:"Промяна на парола",
  New_Tax_Entry:"Ново данъчно вписване",
  New_Tax:"Нов данък",
  Tax_Label:"Данъчен етикет",
  Perpetually_Enabled:"Постоянно активиран",
  Select_Taxes:"Изберете данъци",
  Tax_Deductions:"Данъчни облекчения",
  No_Tax_Deductions:"Без данъчни облекчения",
  No_Tax_Deductions_Word:"Добавете първото приспадане на <a routerLink='/folder/Tax/New'>данък</a>.",
  New_Timer:"Нов таймер",
  Start:"Старт",
  Stop:"Спри се",
  Start_Timer:"Стартиране на таймера",
  Stop_Timer:"Стоп таймер",
  Timer_Active:"Таймер активен",
  Timer:"Таймер:",
  Timer_Running:"Таймер: (работи)",
  Save_Timer:"Запазване на таймера",
  New_Timesheet:"Нов график",
  Select_Timesheets:"Избор на графики",
  Work_Notes:"Работни бележки",
  Entry_Title:"Заглавие на записа",
  No_Timesheets:"Без таблици за време",
  No_Timesheets_Word:"Добавете първия <a routerLink='/folder/Timesheet/New'>Графичен лист</a>.",
  Timesheet_Submitted:"Изпратен график",
  Timesheet_Congrats:"Поздравления! Вашият график за време е изпратен успешно. Благодаря!",
  Timesheet_Copy:"За да получите копие на вашите документи, моля, предоставете ни вашия имейл адрес и/или номер на мобилен телефон.",
  Submit:"Изпращане",
  Allow_Notifications:"Разрешаване на известия",
  Untitled_Entry:"Нов вход",
  Untitled_Bank:"Банка без име",
  Timesheet_Entry:"Въвеждане на времеви лист",
  Work_Description:"Работно описание",
  Enable_Timesheet:"Активиране на графика",
  Submit_Timesheet:"Изпращане на график",
  Vacation:"Ваканция",
  New_Vacation:"Нова ваканция",
  Vacation_Name:"Име на ваканция",
  Paid_Vacation:"Платена ваканция",
  Vacation_Pay:"Ваканционно плащане",
  Enable_Vacation:"Активиране на ваканция",
  Select_Vacations:"Избор на ваканции",
  No_Vacations:"Без ваканции",
  No_Vacations_Word:"Създайте първия запис <a routerLink='/folder/Vacation/New'>Ваканция</a>.",
  Payroll_Schedule:"График за заплати",
  Next_Payroll:"Следваща ведомост:",
  Upcoming_Payroll:"Предстояща ведомост",
  No_Upcoming_Payroll:"Няма предстояща ведомост",
  Address_Book:"Адресна книга",
  Archived_Documents:"Архивирани документи",
  Dev_Mode:"Приложение в режим на разработка",
  Administrators:"Администратори",
  Privacy:"Поверителност",
  None:"Нито един",
  Select_Signature:"Избор на подпис",
  No_Signatures:"Без подписи",
  No_Signatures_Word:"Добавете първия <a routerLink='/folder/Signature/New'>Подпис</a>.",
  Repeat_Indefinitely:"Повтаряйте неограничено",
  Sign:"Знак",
  Sign_Here:"Подпишете тук",
  Date_Signed:"Дата на подписване",
  Signature_Pad:"Подписен блок",
  Account_Holder:"Титуляр на партида",
  Account_Properties:"Свойства на акаунта",
  Name_On_Cheque:"Име на чека",
  Server_Hostname:"Име на хост на сървъра / IP",
  Printers:"Принтери",
  No_Printers:"Без принтери",
  Printer_Exists:"Принтер с това име вече съществува.",
  No_Printers_Word:"Добавете първия <a routerLink='/folder/Printer/New'>Принтер</a>.",
  No_Printer_Alert:"Нито един принтер не е дефиниран. Искате ли да настроите принтер?",
  Add_Printer:"Добавяне на принтер",
  New_Printer:"Нов принтер",
  Printer_Hostname:"Име на хост на принтер / IP",
  Printer_Label:"Етикет за принтер",
  Printer_Protocol:"Протокол за принтер",
  Protocol:"протокол",
  Email_Print:"Електронна поща",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"гнездо",
  Print_Job:"Задание за печат",
  Printed:"Отпечатан",
  Not_Printed:"Не е отпечатано",
  Print_Jobs:"Задачи за печат",
  Print_Queue:"Опашка за печат",
  No_Print_Jobs:"Без печатни задания",
  No_Prints:"Създайте нов <a routerLink='/folder/Cheque/New'>чек</a> за отпечатване.",
  Prints:"Разпечатки",
  Find_Printer:"Намиране на принтер",
  Find_AirPrint_Devices:"Намерете AirPrint устройства",
  Select_Printer:"Избор на принтер",
  System_UI:"Системен потребителски интерфейс",
  Printer:"принтер",
  Status:"Състояние",
  Preview:"Преглед",
  Enable_Print_Job:"Активиране на заданието за печат",
  Queue_Weight:"Тегло на опашката",
  Unlimited:"Неограничен",
  Show_Advanced_Printer_Options:"Показване на разширени опции за принтер",
  Advanced:"Разширени",
  Location:"Местоположение",
  Note:"Забележка",
  Queue_Name:"Име на опашката",
  Pages_Printed_Limit:"Ограничение на отпечатаните страници",
  MultiPage_Idle_Time:"Време за изчакване на няколко страници (и)",
  Page_Count_Limit:"Ограничение на броя на страниците",
  Page_Orientation:"Ориентация на страницата",
  Portrait:"Портрет",
  Landscape:"Пейзаж",
  Duplex:"дуплекс",
  Double_Sided_Printing:"Двустранен",
  Duplex_Mode:"Дуплекс режим",
  Duplex_Short:"Къс",
  Duplex_Long:"Дълга",
  Duplex_None:"Нито един",
  Color_And_Quality:"Цвят и качество",
  Monochrome:"Монохромен",
  Photo_Quality_Prints:"Разпечатки с качество на снимките",
  Printer_Email:"Имейл на принтера",
  Automatically_Downsize:"Автоматично намаляване на размера",
  Paper:"хартия",
  Paper_Name:"Име на хартия",
  Paper_Width:"Ширина на хартията",
  Paper_Height:"Височина на хартията",
  Paper_Autocut_Length:"Дължина на автоматично рязане на хартия",
  Margins:"Маржове",
  Page_Margins:"Полета на страницата",
  Page_Margin_Top:"Поле на горната страница",
  Page_Margin_Right:"Дясно поле на страницата",
  Page_Margin_Bottom:"Долен поле на страницата",
  Page_Margin_Left:"Ляво поле на страницата",
  Add_Employees:"Добавяне на служители",
  Header:"заглавка",
  Print_A_Page_Header:"Отпечатване на заглавка на страница",
  Header_Label:"Етикет на заглавието",
  Header_Page_Index:"Индекс на заглавната страница",
  Header_Font_Name:"Шрифт на заглавката",
  Select_Font:"Избор на шрифт",
  Font_Selector:"Избор на шрифт",
  Header_Font_Size:"Шрифт на заглавката",
  Header_Bold:"Удебелен заглавка",
  Header_Italic:"Заглавен курсив",
  Header_Alignment:"Подравняване на заглавката",
  Left:"Наляво",
  Center:"център",
  Right:"Дясно",
  Justified:"оправдано",
  Header_Font_Color:"Цвят на заглавката",
  Select_Color:"Избор на цвят",
  Footer:"Долен колонтитул",
  Print_A_Page_Footer:"Отпечатване на долен колонтитул на страница",
  Footer_Label:"Етикет на долния колонтитул",
  Footer_Page_Index:"Индекс на долен колонтитул",
  Footer_Font_Name:"Шрифт на долния колонтитул",
  Fonts:"Шрифтове",
  Done:"Свършен",
  Select_Fonts:"Избор на шрифтове",
  Footer_Font_Size:"Размер на долния колонтитул",
  Footer_Bold:"Удебелен долен колонтитул",
  Footer_Italic:"Кусив в долния колонтитул",
  Footer_Alignment:"Подравняване на долния колонтитул",
  Footer_Font_Color:"Цвят на долния колонтитул",
  Page_Copies:"Копия на страници",
  Enable_Printer:"Активиране на принтера",
  Remote_Logging:"Отдалечено регистриране",
  Log_Server:"Сървър за регистрационни файлове",
  Encryption:"криптиране",
  Random_Key:"Случаен ключ",
  Encryption_Key:"Ключ за криптиране",
  Cheques_Webserver:"Персонализирана база данни",
  Learn_How:"Научи как",
  Signature:"Подпис",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Преглед на подписа",
  Printed_Signature:"Печатен подпис",
  Digitally_Sign:"Дигитален знак",
  Digital_Signature:"цифров подпис",
  Add_Signature:"Добавяне на подпис",
  Add_Your_Signature:"Добавете своя подпис",
  Enable_Signature:"Активиране на подписа",
  Digitally_Signed:"Цифрово подписан",
  Insert_Error:"Не може да се запише чека поради проблеми с базата данни.",
  Delete_Confirmation:"Сигурни ли сте, че искате да изтриете тази информация?",
  Discard_Confirmation:"Сигурни ли сте, че искате да изхвърлите тази информация?",
  Discard_Bank_Confirmation:"Сигурни ли сте, че искате да отхвърлите този акаунт?",
  Discard_Printer_Confirmation:"Сигурни ли сте, че искате да изхвърлите този принтер?",
  Delete_Bonus_Confirmation:"Сигурни ли сте, че искате да изтриете този бонус?",
  Delete_Invoice_Confirmation:"Сигурни ли сте, че искате да изтриете тази фактура?",
  Generated_Cheque:"генерирана проверка",
  Generated_Invoice:"генерирана фактура",
  Schedule_Start_Time:"Старт на график",
  Schedule_End_Time:"Край на графика",
  New_Call:"Ново обаждане",
  No_Contacts:"Няма контакти",
  No_Contacts_Word:"Администраторите, модераторите, служителите и получателите се показват като контакти.",
  PDF_Subject:"финанси",
  PDF_Keywords:"чекове за заплата PDF чекове за заплата",
  Printer_Setup:"Настройка на принтера",
  Printer_Selection:"Избор на принтер",
  New_Fax:"Нов факс",
  New_Fax_Message:"Ново факс съобщение",
  Fax_Machine:"Факс апарат",
  Fax_Name:"Име на факс",
  Fax_Email:"Факс имейл",
  Fax_Number:"Номер на факс",
  Contents:"Съдържание",
  Fax_Body:"Тяло на страницата",
  Header_Word:"Заглавка:",
  Header_Text:"Заглавен текст",
  Include_Header:"Включи заглавие",
  Include_Footer:"Включи долен колонтитул",
  Footer_Word:"Долен колонтитул:",
  Footer_Text:"Текст в долния колонтитул",
  Attach_a_Cheque:"Прикачете чек",
  Add_Deduction:"Добавяне на приспадане",
  Enable_Fax:"Изпращане на факс",
  Select_Fax:"Избор на факс",
  No_Faxes:"Без факсове",
  Faxes:"факсове",
  Save_and_Send:"Изпращане на факс",
  Save_and_Pay:"Спести и плати",
  WARNING:"ВНИМАНИЕ:",
  Remember:"Помня",
  Printing:"Печат",
  Printing_Complete:"Отпечатването завърши!\n\n",
  of:"на",
  There_Were:"Имаше ",
  Successful_Prints:"успешни отпечатъци и",
  Unsuccessful_Prints:"неуспешни разпечатки.",
  PrinterError:"Съжалявам! Възникна грешка.",
  Printing_:"Печат...",
  PrinterSuccess:"Документът е отпечатан успешно.",
  PrintersSuccess:"Документите са отпечатани успешно.",
  Print_Cheques:"Печат на чекове",
  New_Message:"Ново съобщение",
  New_Messages:"Нови съобщения",
  Read_Message:"Прочетете съобщението",
  Write_Message:"Напиши съобщение",
  Send_Message:"Изпрати съобщение",
  Subject:"Предмет",
  Message:"Съобщение",
  Writing:"Пиша...",
  Send:"Изпрати",
  Set_Date:"Задаване на дата",
  Set_Time:"Задаване на време",
  Recieve:"получи",
  Set_as_Default:"Е активирана по подразбиране",
  Default_Account:"Акаунт по подразбиране",
  Default_Design:"Дизайн по подразбиране",
  Multiple_Cheques:"Проверки (Тройно)",
  Account_Mode:"Режим на акаунт",
  Multiple_Cheques_Description:"Три проверки на страница.",
  Check_and_Table:"Проверка и маса",
  Check_including_Table:"Чекова и счетоводна таблица.",
  Check_Mailer:"Проверка на Mailer",
  Check_Mailer_Window:"Проверка с адресен прозорец.",
  DocuGard_Table_Top:"Проверка и маса на DocuGard (отгоре)",
  DocuGard_Table_Middle:"Проверка и таблица на DocuGard (среда)",
  DocuGard_Table_Bottom:"Проверка и таблица на DocuGard (отдолу)",
  DocuGard_Mailer_Top:"DocuGard Check Mailer (отгоре)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (в средата)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mailer (отдолу)",
  DocuGard_Three_Cheques:"DocuGard Checks (Tripple)",
  DocuGard_Cheque_Top:"DocuGard Check (отгоре)",
  DocuGard_Cheque_Middle:"DocuGard Check (среда)",
  DocuGard_Cheque_Bottom:"DocuGard Check (отдолу)",
  DocuGard_Three_Cheques_Window:"Три проверки на една страница.",
  DocuGard_Table_Top_Window:"Таблица с чекове и печалби.",
  DocuGard_Table_Middle_Window:"Таблица с чекове и печалби.",
  DocuGard_Table_Bottom_Window:"Таблица с чекове и печалби.",
  DocuGard_Mailer_Top_Window:"Проверка, маса и адрес.",
  DocuGard_Mailer_Middle_Window:"Проверка, маса и адрес.",
  DocuGard_Mailer_Bottom_Window:"Проверка, маса и адрес.",
  DocuGard_Cheque_Top_Window:"Проверете за защитена хартия.",
  DocuGard_Cheque_Middle_Window:"Проверете за защитена хартия.",
  DocuGard_Cheque_Bottom_Window:"Проверете за защитена хартия.",
  Basic_Cheque:"Проверка (отгоре)",
  Basic_Cheque_Print:"Отпечатайте единичен чек.",
  Error_Setting_As_Paid:"Грешка при настройка като платено",
  Add_Attachment:"Добави прикачен файл",
  PrinterUnavailable:"Принтерът не е наличен",
  CreditCardComponent:"карти",
  PaypalComponent:"PayPal",
  InteracComponent:"Интерак",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Нов депозит",
  Deposits:"депозити",
  No_Deposits:"Без депозити",
  Credit_Card_Deposit:"Кредитна карта",
  New_Credit_Card_Deposit_Message:"Депозит с кредитна карта",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Биткойн депозит",
  New_Interac_Deposit:"Интерак",
  New_Interac_Deposit_Message:"Interac Transfer",
  Payments_Limit:"Лмит на плащане",
  No_Payment_Limit:"Без лимит за плащане",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Пейпал депозит",
  No_Deposits_Word:"Добавете първата компания <a routerLink='/folder/Deposit/New'>Депозит</a>.",
  No_Documents_Specified:"Няма посочени документи за печат",
  No_Printers_Added:"Няма намерени принтери. Отидете на Настройки > Принтери, за да добавите такъв.",
  DB_Erase_Prompt:"Да изтриете изцяло цялата база данни? ПРЕДУПРЕЖДЕНИЕ: Ще загубите цялата съхранена информация!",
  Console_Warning:"Не поставяйте никакъв текст в тази конзола. Може да изложите себе си и/или вашата компания на сериозен риск.",
  No_Faxes_Word:"Създайте първия <a routerLink='/folder/Fax/New'>Факс</a>.",
  Cheque_Delete_Confirmation:"Сигурни ли сте, че искате да изтриете тази проверка?",
  Design_Delete_Confirmation:"Сигурни ли сте, че искате да изтриете този дизайн?",
  Cheque_Pay_Confirmation:"Означаване на този чек като платен? Той НЯМА да се появи в опашката за печат.",
  Payment_Pay_Confirmation:"Означаване на това плащане като платено? То НЯМА да се появи в опашката за чекове.",
  Delete_Deduction_Confirmation:"Сигурни ли сте, че искате да изтриете това приспадане?",
  Delete_Job_Confirmation:"Сигурни ли сте, че искате да изтриете тази работа?",
  Delete_Timesheet_Confirmation:"Сигурни ли сте, че искате да изтриете този график?",
  Delete_Schedule_Confirmation:"Сигурни ли сте, че искате да изтриете този график?",
  Delete_Setting_Confirmation:"Сигурни ли сте, че искате да нулирате тази настройка?",
  Delete_Fax_Confirmation:"Сигурни ли сте, че искате да изтриете този факс?",
  Delete_File_Confirmation:"Сигурни ли сте, че искате да изтриете този файл?",
  Delete_Vacation_Confirmation:"Сигурни ли сте, че искате да изтриете тази ваканция?",
  Delete_Printer_Confirmation:"Сигурни ли сте, че искате да изтриете този принтер?",
  Remove_Design_Confirmation:"Сигурни ли сте, че искате да изтриете този дизайн?",
  Delete_Payroll_Confirmation:"Сигурни ли сте, че искате да изтриете тази ведомост?",
  Send_Fax_Email_Confirmation:"Искате ли да изпратите по факс и имейл тези документи?",
  Send_Email_Confirmation:"Искате ли да изпратите този документ по имейл?",
  Send_Fax_Confirmation:"Искате ли да изпратите този документ по факс?",
  Error_Generating_PDF:"Съжаляваме. Възникна грешка при генерирането на този документ.",
  PDF_Error_Saving_Image:"Съжаляваме. Възникна грешка при запазването на PDF изображение на този документ.",
  Test_Printer_Confirmation:"Искате ли да отпечатате тестова страница на този принтер?",
  Save_Timesheet_Prompt:"Моля, добавете 'Заглавие' или натиснете 'Стартиране на таймера', за да запазите.",
  Remove_Geofence_Prompt:"Сигурни ли сте, че искате да премахнете местоположението на тази гео-ограда?",
  Delete_Employee_Confirmation:"Сигурен ли си, че искаш да изтриеш ",
  Fire_Employee_Confirmation:"Сигурни ли сте, че искате да стреляте"
}