export const Sk = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Autorské práva &copy; 2023",
  black:"Čierna",
  green:"Zelená",
  gold:"Zlato",
  blue:"Modrá",
  brown:"Hnedá",
  purple:"Fialová",
  pink:"Ružová",
  red:"Červená",
  Swatches:"Vzorkovníky",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Hue",
  Saturation:"Sýtosť",
  Lightness:"Ľahkosť",
  Upgrade_To_Pro:"Inovujte na Pro",
  AllFeaturesInclude:"Všetky funkcie zahŕňajú:",
  PrintUnlimitedCheques:"Tlačte neobmedzené šeky",
  PremiumChequeDesigns:"Prémiové vzory šekov",
  ManageUnlimitedEmployees:"Spravujte neobmedzený počet zamestnancov",
  AddUnlimitedPayees:"Pridajte neobmedzených príjemcov",
  CreateUnlimitedPayrolls:"Vytvárajte neobmedzené mzdy",
  UnlimitedSchedulesandTimeSheets:"Neobmedzené rozvrhy a časové rozvrhy",
  BulkPayPalPayouts:"Hromadné platby cez PayPal",
  UnlimitedBankAccounts:"Neobmedzené bankové účty",
  ManageMultipleCompanies:"Spravujte viacero spoločností",
  TrackInsurancePolicies:"Sledujte poistné zmluvy",
  Bio_MetricProtection:"Biometrická ochrana",
  Geo_FenceLock_OutProtection:"Ochrana proti uzamknutiu Geo-Fence",
  Multiple_Companies_Word:"Správa viacerých spoločností nie je dostupná bez prémie šekov.",
  PayPal_Payouts_Word:"Platby PayPal sú deaktivované bez prémie šekov.",
  PINProtection:"Ochrana PIN",
  PasswordProtection:"Ochrana heslom",
  May_Require_Approval:"Môže vyžadovať schválenie.",
  Subscribe:"Prihlásiť sa na odber",
  Billed:"Fakturované",
  Up:"Hore",
  Down:"Dole",
  Positioning:"Polohovanie",
  Marker:"Marker",
  Drag_Marker:"Presuňte značku",
  Tagline:"Tlač šekov a tabuľka miezd",
  Marker_Word:"Pomocou značiek upravte veľkosť prvku.",
  Pinch_Zoom:"Stiahnutie priblíženia",
  Pinch_Word:"Stiahnutím prstov priblížite prvok.",
  Drag:"Potiahnite",
  Drag_Word:"Prstom presúvajte prvky.",
  subscription_alias_word:"Automatické obnovenie predplatného",
  premium_alias_word:"Jednorazový aktualizačný balík",
  print_alias_word:"Vytlačte si jednotlivé šeky",
  mode_alias_word:"Režim",
  Pro:"Pro",
  Pro_word:"Vyžaduje sa Pro verzia.",
  Cant_Delete_Default_Company_Word:"Ľutujeme, predvolenú spoločnosť nemôžete odstrániť.",
  Reinsert_Default_Designs:"Znova vložte predvolené vzory",
  Reinsert_Default_Designs_word:"Chcete znova vložiť predvolené návrhy?",
  Subscription_Active_Disable_Word:"Toto predplatné je aktívne. Chcete zrušiť toto predplatné šekov?",
  Company_Logo:"Logo spoločnosti",
  ZERO_:"NULA",
  Disclaimer:"Vylúčenie zodpovednosti",
  Privacy_Policy:"Zásady ochrany osobných údajov",
  EULA:"Kontroluje EULA",
  Terms_Of_Service:"Podmienky služby",
  Terms_Of_Use:"Podmienky používania",
  Refunds:"Pravidlá vrátenia peňazí",
  Single_Print:"1 Skontrolujte",
  Two_Prints:"2 Kontroly",
  Five_Prints:"5 Kontroly",
  Ten_Prints:"10 kontrol",
  Fifteen_Prints:"15 Kontroly",
  Twenty_Prints:"20 šekov",
  Thirty_Prints:"30 šekov",
  Image_Added:"Obrázok bol pridaný",
  Image_Preview:"Ukážka obrázka",
  No_Image_Was_Selected:"Nebol vybratý žiadny obrázok.",
  Cheques_Unlimited:"Neobmedzené šeky",
  _Subscription:"Predplatné",
  Subscription:"Šeky - 1 mesiac",
  Two_Month_Subscription:"Šeky - 2 mesiace",
  Three_Month_Subscription:"Šeky - 3 mesiace",
  Six_Month_Subscription:"Šeky - 6 mesiacov",
  Twelve_Month_Subscription:"Šeky - 12 mesiacov",
  Cheques_Are_Available:"Šeky sú k dispozícii na tlač.",
  Upgrade_Required_Two:"Vyberte balík a dvojitým klepnutím na tlačidlo ceny začnite nákup. Tlačte profesionálne vyzerajúce plnofarebné šeky za pár sekúnd.",
  Month:"mesiac",
  Due:"Termín:",
  Expires:"Platnosť vyprší:",
  Subscription_Active:"Predplatné je aktívne",
  Subscription_Inactive:"Predplatné je neaktívne",
  Purchase_Additional_Cheques:"Kúpiť ďalšie šeky?",
  Printable_Cheque:"Šek pre tlač",
  Printable_Cheques:"Šeky na tlač",
  Printable_Cheque_Word:"šek je k dispozícii na vašom účte.",
  Printable_Cheques_Word:"šeky sú k dispozícii na vašom účte.",
  Max_Amount_Message:"Suma, ktorú ste zadali, prekročila maximálnu sumu nastavenú pre tento systém:",
  Terms_Required_Word:"Pred ďalším používaním šekov musíte súhlasiť s touto dohodou.",
  Subscriptions:"Predplatné",
  Product_Upgrades:"Upgrady",
  Mailed_Out_Cheques:"Zaslané šeky",
  Enter_A_Company_Name:"Zadajte názov spoločnosti.",
  Single_Cheques:"Jednorazové šeky",
  Cheque_Golden:"Zlatý šek",
  Cheque_Golden_Window:"Zlatý kárový dizajn.",
  Cheque_Green:"Zelená kontrola",
  Cheque_Green_Window:"Zelený kárový dizajn.",
  Cheque_Red:"Červená kontrola",
  Cheque_Red_Window:"Červený kárový dizajn.",
  Cheque_Yellow:"Žltá kontrola",
  Cheque_Yellow_Window:"Žltý kárový dizajn.",
  Cheque_Statue_of_Liberty:"Socha slobody",
  Cheque_Statue_of_Liberty_Window:"Šachový dizajn Sochy slobody.",
  Cheque_Green_Wave:"Zelená vlna",
  Cheque_Green_Wave_Window:"Zelený kárový dizajn.",
  Cheque_Golden_Weave:"Golden Weave",
  Cheque_Golden_Weave_Window:"Elegantný zlatý károvaný dizajn.",
  Cheque_Green_Sun:"Zelené slnko",
  Cheque_Green_Sun_Window:"Hlboký a upokojujúci kárový dizajn.",
  Cheque_Blue_Checkers:"Modrá dáma",
  Cheque_Blue_Checkers_Window:"Modrý kárový dizajn.",
  Cashiers_Check:"Pokladničný šek",
  Cashiers_Check_Window:"Šablóna štýlu pokladničnej kontroly.",
  Cheque_Aqua_Checkers:"Aqua dáma",
  Cheque_Aqua_Checkers_Window:"Dizajn Aqua check.",
  Cheque_Golden_Checkers:"Zlatá dáma",
  Cheque_Golden_Checkers_Window:"Zlatý kárový dizajn.",
  Upgrade_Unavailable:"Inovácie nie sú k dispozícii",
  Bank_Code_Protection:"Ochrana čísla banky",
  Bank_Code_Protection_Word:"Chráňte svoje bankové čísla pred použitím v tejto aplikácii spustenej na inom zariadení alebo pre iného používateľa. Táto akcia je NEVRATNÁ. Ďalej?",
  Bank_Code_Protection_Blocked_Word:"Čísla bánk, ktoré sa pokúšate použiť, sú vyhradené pre iného používateľa alebo zariadenie.",
  Bank_Code_Protection_Error_Word:"Overenie čísla zlyhalo. Pripojte sa k internetu a skúste znova pridať tento bankový účet.",
  Bank_Code_Protection_Set_Error_Word:"Ochrana bankového čísla vyžaduje, aby ste boli pripojení na internet. Pripojte sa a skúste to znova.",
  Upgrade_Unavailable_Word:"Ľutujeme, máme problém s overením. Vo vašej oblasti momentálne nie je možné zakúpiť predplatné a inovácie na Šeky.",
  PayPal_Payment_Preview:"Ukážka platby PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Vyberte PayPal",
  PayPal_Applications:"Aplikácie PayPal",
  Purchase_With_Apple_Pay:"Nákup cez Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Spoločnosti",
  Insurance:"poistenie",
  New_PayPal:"Nový PayPal",
  Pay_By:"Pay By",
  Insure:"Poistiť",
  Miles:"Miles",
  Payment_Method:"Spôsob platby",
  Select_Policies:"Vyberte položku Zásady",
  Policies:"Postupy",
  Policy:"politika",
  No_PayPal_Account:"Žiadny účet PayPal",
  No_Policies:"Žiadne poistné zmluvy",
  New_Policy:"Nová politika",
  PayPal_Payment:"PayPal platba",
  PayPal_Payments:"PayPal platby",
  No_Payment_Selected:"Nie je vybratá žiadna platba",
  Sending_Payment_Word:"Čakajte prosím... Táto platba sa odosiela.",
  Sending_Payments_Word:"Čakajte prosím... Platby sa odosielajú.",
  No_Payment_Selected_PayPal:"nie je vybratá žiadna <a routerLink='/folder/Payments'>Platba PayPal</a>.",
  Payment_Sent:"Platba odoslaná",
  PayPal_Payment_Sent:"Táto platba bola odoslaná cez PayPal.",
  Copay:"Copay",
  Dead:"Mŕtvy",
  Alive:"Nažive",
  Not_Dead:"Nie mŕtvy",
  Unclaimed:"Nenárokované",
  Attempted:"Pokus",
  Deceased:"Zosnulý",
  Claimed:"Nárokované",
  Unpaid:"Nezaplatené",
  Sending_Payment:"Odoslanie platby",
  Sending_Payments:"Odosielanie platieb",
  Send_PayPal_Confirmation:"Chcete odoslať túto transakciu cez PayPal?",
  Send_PayPal_Confirmation_Word:"Pre odoslanie tejto transakcie stlačte zelené tlačidlo.",
  Save_Payment_As_Unpaid:"Chcete túto platbu uložiť ako nezaplatenú?",
  Payment_Pay_Confirmation_PayPal:"Uložiť túto platbu ako zaplatenú?",
  No_Policies_Word:"pridajte prvé <a routerLink='/folder/Postage/New'>Poistné pravidlá</a>.",
  Timesheet_Multiple_Delete_Confirmation:"Naozaj chcete odstrániť viaceré časové výkazy?",
  Select_Multiple_Timesheets_Prompt:"Nie sú vybraté žiadne časové výkazy. Vyberte aspoň jeden časový rozvrh.",
  Select_Multiple_Policies_Prompt:"Nie sú vybraté žiadne zásady. Vyberte si aspoň jedno poistenie.",
  Policies_Multiple_Delete_Confirmation:"Naozaj chcete odstrániť viaceré pravidlá?",
  Company:"Spoločnosť",
  Add_Company:"Pridať spoločnosť",
  New_Company:"Pridať spoločnosť",
  No_Companies:"Žiadne spoločnosti",
  Enable_Company:"Povoliť spoločnosť",
  Select_Company:"Vyberte položku Spoločnosť",
  The_Default_Company:"Predvolený štítok spoločnosti.",
  Manage_Companies:"Riadiť spoločnosti",
  No_Companies_Word:"Kontroly budú používať predvolenú spoločnosť.<br />Pridajte <a routerLink='/folder/Company/New'>Prvú Spoločnosť</a>.",
  Default_Company:"Predvolená spoločnosť",
  Cheques_Unlimited_Word:"Checks Unlimited vám umožňuje vytlačiť toľko šekov, koľko chcete.",
  Cheques_Subscription_Word:"Predplatné šekov vám umožňuje vytlačiť toľko šekov, koľko chcete.",
  You_Own_This_Product:"Vlastníte tento produkt.",
  Your_Subscription_is_Active:"Vaše predplatné je aktívne.",
  Active_Products:"Aktivované produkty",
  Purchase_Confirmation:"Nákup",
  Purchase_Cheques:"Šeky nákupu",
  Restore_Purchase:"Obnoviť nákupy",
  Erase_Purchase:"Vymazať nákupy",
  Successfully_Purchased:"Úspešne zakúpené",
  Enter_Your_Licence_Key:"Na aktiváciu tohto produktu zadajte svoj licenčný kľúč na tejto stránke.",
  Licence_Key:"Licenčný kľúč",
  Enter_Licence_Key:"Zadajte licenčný kľúč",
  Purchased:"Kúpené",
  Enable_Feature:"Povoliť funkciu",
  Cancel_Subscription:"Zrušiť predplatné",
  Subscription_Removed:"Predplatné bolo odstránené",
  Select_Active_Subscription:"Vyberte aktívne predplatné, aby ste ho mohli upraviť.",
  Remove_Subscription_Word:"Naozaj chcete zrušiť tento odber?",
  Delete_Company_Confirmation:"Naozaj chcete odstrániť celú túto spoločnosť? UPOZORNENIE: Prídete o všetky uložené informácie!",
  Delete_Default_Company_Word:"Predvolenú spoločnosť nemôžete odstrániť . Chcete resetovať aplikáciu a obnoviť ju do predvoleného stavu? UPOZORNENIE: Prídete o všetky uložené informácie!",
  Console_Warning_2:"Nemanipulujte so žiadnou menou pomocou tejto aplikácie, ktorá momentálne nie je vaša.",
  Terms_and_Conditions:"Podmienky",
  and_the:"a",
  for:"pre",
  Please_Read_Word:"Prečítajte si prosím a súhlaste s",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Niektoré menové prevodné kurzy sa nepodarilo nájsť. Pripojte sa k internetu.",
  Free:"zadarmo",
  DB_Erase_Prompt_2:"Úplne vymazať celú databázu vývojárov? UPOZORNENIE: Stratíte všetky informácie o NÁKUPE a PREDPLATNÍ!",
  Successfully_Imported:"Úspešne importované",
  Select_Postage:"Vyberte Poštovné",
  No_Postage:"Žiadne poštové známky",
  No_Paid_Postage_Word:"Pridajte prvú známku <a routerLink='/folder/Postage/New'>Platené poštovné</a>.",
  Trial_Complete:'Skúška dokončená',
  Please_Upgrade:'Ak chcete pokračovať v tlači, inovujte Kontroly.',
  Aa:"Aa",
  Color:"Farba",
  Font:"Písmo",
  Guide:"Sprievodca",
  Guides:"Sprievodcovia",
  Image:"Obrázok",
  Zoom:"Zoom",
  Logo:"Logo",
  Bank:"breh",
  MICR:"MICR",
  Total:"Celkom",
  Cancel:"Zrušiť",
  Confirm:"Potvrďte",
  Method:"Metóda",
  Biometric_Security:"Biometrické zabezpečenie",
  Please_Login_To_Continue:"Prosím prihláste sa pre pokračovanie.",
  Complete:"Dokončiť",
  Sign_Up:"Prihlásiť Se",
  Error:"Chyba",
  Biometrics:"Biometria",
  Percent:"percent",
  Zero_Percent:"0%",
  Top_Margin:"Horný okraj",
  Bottom_Margin:"Spodný okraj",
  Left_Margin:"Ľavý okraj",
  Right_Margin:"Pravý okraj",
  MICR_Margin:"MICR Marža",
  Table_Margin:"Okraj tabuľky",
  Address_Margin:"Okraj adresy",
  Percentage_:"Percento",
  Vacation_Title:"Dovolenkový titul",
  Use_PIN:"Použite PIN",
  Loading__:"Načítava...",
  Design_Title:"Názov dizajnu",
  Authorize:"Povoliť",
  Key:"kľúč",
  Public_Key:"Verejný kľúč",
  Private_Key:"Súkromný kľúč",
  Authenticate:"Overiť",
  Last_Payroll:"Posledná výplatná páska",
  Last_Calculation:"Posledný výpočet",
  Authorized:"Autorizované",
  Geo_Authorized:"Geo-Lokalita: Autorizovaná",
  Not_Authorized:"Neoprávnené",
  Authorization_Complete:"Autorizácia je dokončená",
  Geolocation_Authorization:"Autorizácia geografickej polohy",
  Out_of_Bounds:"Mimo hranice",
  Cant_Delete_Default_Design:"Predvolený dizajn nie je možné odstrániť.",
  Unauthorized_Out_of_Bounds:"Neoprávnené: Mimo hraníc",
  Biometric_Authorization:"Biometrická autorizácia",
  Locating_Please_Wait:"Hľadám, čakajte...",
  Test_Biometrics:"Testovanie biometrických údajov",
  Cheque_Margins:"Skontrolujte okraje",
  Percentage_Full_Error:"Percentuálne pole nemôže byť nastavené na viac ako 100 %.",
  No_Payroll_Text:"Pridajte <a routerLink='/folder/Employee/New'>Zamestnanec</a> alebo <a routerLink='/folder/Payee/New'>Príjemca Platby</a> a <a routerLink='/folder/Schedule/New'>Plán</a>.",
  Design_Saved:"Dizajn bol uložený",
  Default_Design_Selected:"Predvolený dizajn vybratý",
  Partial_Import:"Čiastočný import",
  Partial_Export:"Čiastočný export",
  Entire_Import:"Celý import",
  Entire_Export:"Celý export",
  Existing_Password:"Zadajte svoje existujúce heslo:",
  Existing_PIN:"Zadajte svoj existujúci PIN kód:",
  Pin_Change_Header:"Potvrdenie PIN",
  Pin_Change_SubHeader:"Zadajte svoje staré číslo PIN na potvrdenie zmeny.",
  Pass_Change_Header:"Potvrdenie hesla",
  Pass_Change_SubHeader:"Zadajte svoje staré heslo na potvrdenie zmeny.",
  PIN_Enter_Message:"Pre potvrdenie zadajte svoj PIN.",
  Password_Enter_Message:"Zadajte svoje heslo na potvrdenie.",
  Pin_Updated_Success:"PIN bol úspešne aktualizovaný!",
  Pin_Updated_Fail:"Kód PIN sa nepodarilo aktualizovať.",
  Pass_Updated_Success:"Heslo bolo úspešne aktualizované.",
  Pass_Updated_Fail:"Heslo sa nepodarilo aktualizovať.",
  Preview_Payment:"Náhľad platby",
  Preview_Payroll:"Náhľad mzdy",
  No_Payments_Created:"Nenašli sa žiadne platby na vytvorenie.",
  Payment_Preview:"Ukážka platby",
  Enable_Payee:"Povoliť príjemcu platby",
  Rendered:"Vykreslené",
  No_Email:"Žiadny e-mail",
  Setup_Cheques:"Kontroly nastavenia",
  name:"názov",
  address:"Adresa",
  address_2:"2. riadok adresy",
  city:"Mesto",
  province:"Provincie",
  postal_code:"PSČ",
  country:"Krajina",
  username:"Používateľské meno",
  full_name:"Celé meno",
  birthday:"narodeniny",
  email:"Email",
  phone:"Telefón",
  employees:"zamestnancov",
  addresses:"Adresy",
  payment_amount_limit:"Limit výšky platby",
  total_limit:"Celkový limit",
  payees:"Príjemcovia platieb",
  description:"Popis",
  address_line_one:"Prvý riadok adresy",
  address_line_two:"Druhý riadok adresy",
  image:"Obrázok",
  account_holder:"Majiteľ účtu",
  cheque_starting_id:"Skontrolujte počiatočné ID",
  transit_number:"Číslo tranzitu",
  institution_number:"Číslo inštitúcie",
  designation_number:"Číslo označenia",
  account_number:"Číslo účtu",
  currency:"mena",
  signature:"Podpis",
  payment_payroll_limit:"Platobný limit",
  total_limi:"Celkový limit",
  date:"Dátum",
  printed_memo:"Tlačená poznámka",
  banks:"banky",
  signature_image:"Podpisový obrázok",
  address_name:"Meno adresy",
  notes:"Poznámky",
  design:"Dizajn",
  title:"Názov",
  frequency:"Frekvencia",
  fax:"Fax",
  salaries:"platy",
  salary_ids:"Platové preukazy",
  start_time:"Doba spustenia",
  end_time:"Čas ukončenia",
  paid:"Zaplatené",
  overtime_percentage:"Zaplatené percento",
  overtime_amount:"Zaplatená pevná suma",
  first_name:"Krstné meno",
  last_name:"Priezvisko",
  moderation:"Moderovanie",
  create:"Vytvorte",
  edit:"Upraviť",
  destroy:"Zničiť",
  day_start_time:"Day_start_time",
  day_end_time:"Day_end_time",
  fullname:"názov",
  time:"čas",
  auto_send:"Automaticky odoslať",
  time_method:"Časová metóda",
  schedules:"Rozvrhy",
  indefinite_payroll_enabled:"Povoliť na neurčito",
  amount:"Suma",
  repeat:"Opakujte",
  always_enabled:"Vždy povolené",
  start_date:"Dátum začiatku",
  end_date:"Dátum ukončenia",
  Cheque_Total:"Skontrolujte súčet",
  Total_Amount:"Celková suma:",
  Amounts:"čiastky:",
  Images:"snímky",
  Files:"Súbory",
  Previewing:"Ukážka:",
  Insert:"Vložiť",
  Preview_Import:"Ukážka importu",
  Entry:"Vstup",
  Entries:"Príspevky",
  No_Entries:"Žiadne záznamy",
  Import_Type:"Typ importu",
  Select_Details:"Vyberte Podrobnosti",
  Select_Payee:"Vyberte položku Príjemca platby",
  Enable_Holidays:"Povoliť sviatky",
  Disable_Holidays:"Zakázať sviatky",
  Wire_Transfer:"Drôtový prenos",
  New_Export:"Nový export",
  Export_Data:"Exportovať údaje",
  Export_Data_Word:"Vyberte typ súboru, ktorý chcete exportovať a stiahnuť.",
  Export_File:"Exportovať súbor",
  Mode:"Režim",
  Times:"Times",
  Widgets:"Miniaplikácie",
  Slider:"Posúvač",
  Set_Details:"Nastaviť Podrobnosti",
  Select_Type:"Vyberte Typ",
  Display_Slider:"Posuvník zobrazenia",
  Dashboard_Slider:"Posuvník prístrojovej dosky",
  Dashboard_Mode:"Režim palubnej dosky",
  Show_Intro:"Zobraziť úvod",
  Show_Payrolls:"Zobraziť mzdy",
  Show_Holidays:"Zobraziť sviatky",
  Show_Invoices:"Zobraziť faktúry",
  Show_Cheques:"Zobraziť šeky",
  Enabled_Widgets:"Povolené miniaplikácie",
  Disabled_Widgets:"Zakázané widgety",
  Colors:"Farby",
  Barcodes:"Čiarové kódy",
  View_Timers:"Zobraziť časovače",
  Gradients:"Prechody",
  No_Info:"Žiadne informácie",
  Disable:"Zakázať",
  Show_Layer:"Zobraziť vrstvy",
  Hide_Layer:"Skryť vrstvy",
  Text_Layer:"Textové vrstvy",
  Secondly:"Po druhé",
  Minutely:"Na minútu",
  nine_am:"09:00",
  five_pm:"17:00",
  Enable_Address:"Povoliť adresu",
  Invalid_File_Type:"Ľutujeme, bol vybratý neplatný typ súboru. Podporovaný typ súboru:",
  Error_Updating_Entry:"Ľutujeme, pri aktualizácii tohto záznamu sa vyskytla chyba.",
  Schedule_Timing_Alert:"Chyba: Čas začiatku plánu je nastavený na hodnotu po čase ukončenia.",
  Select_Multiple_Payments_Prompt:"Nie sú vybraté žiadne platby. Vyberte aspoň jednu platbu.",
  Select_Multiple_Cheques_Prompt:"Nie sú vybraté žiadne kontroly. Vyberte aspoň jeden šek.",
  Select_Multiple_Items_Prompt:"Nie sú vybraté žiadne položky. Vyberte aspoň jednu položku.",
  Paymemt_Multiple_Delete_Confirmation:"Naozaj chcete odstrániť viaceré platby?",
  Cheque_Multiple_Delete_Confirmation:"Naozaj chcete odstrániť viaceré kontroly?",
  Payee_Multiple_Delete_Confirmation:"Naozaj chcete odstrániť viacerých príjemcov platieb?",
  Employee_Multiple_Delete_Confirmation:"Naozaj chcete odstrániť viacerých zamestnancov?",
  MICR_Warning:"Poznámka: Niektoré tlačiarne a zariadenia nemusia správne zobraziť písmo MICR.",
  Automatically_Send:"Automaticky odoslať",
  Type:"Typ",
  Payment_Type:"Typ platby",
  Auto_Send:"Automatické odosielanie",
  Automatically_Process:"Automaticky spracovať",
  Auto_Process:"Auto Process",
  Image_Based:"Na základe obrázkov",
  Font_Based:"Na základe písma",
  Rerender:"Znova vykresliť",
  Rendering:"Vykresľovanie",
  Render:"Súbory",
  Top:"Hore",
  Middle:"Stredná",
  Bottom:"Spodná časť",
  Top_Left:"Hore v ľavo",
  Top_Center:"Stred hore",
  Top_Right:"Hore vpravo",
  Middle_Left:"Stred vľavo",
  Middle_Center:"Stredný stred",
  Middle_Right:"Stred vpravo",
  Bottom_Left:"Dole vľavo",
  Bottom_Center:"Stred dole",
  Bottom_Right:"Vpravo dole",
  Numbers:"čísla",
  Verified:"Overené",
  Paper_Size:"Veľkosť papiera",
  New_Device:"Nové zariadenie",
  Add_Device:"Pridať zariadenie",
  Remove_Device:"Odstrániť zariadenie",
  Delete_Device:"Odstrániť zariadenie",
  Block_Device:"Blokovať zariadenie",
  Block:"Blokovať",
  Unblock:"Odblokovať",
  Table:"Tabuľka",
  Scan_Login_Code:"Naskenujte prihlasovací kód",
  Login_Code:"Prihlasovací kód",
  Scan_Code:"Skenovať kód",
  Scan_QR_Code:"Naskenujte QR kód",
  Select_All:"Vybrať všetko",
  Deselect_All:"Odznačiť všetko",
  Increase:"Zvýšiť",
  Decrease:"Znížiť",
  Bold:"Tučné",
  Text:"Text",
  Style:"Štýl",
  Italic:"kurzíva",
  QR_Code:"QR kód",
  Barcode:"Čiarový kód",
  Browse_Images:"Prehľadávať obrázky",
  Browse_Files:"Prehľadávať súbory",
  Background_Image:"Obrázok na pozadí",
  Logo_Image:"Obrázok loga",
  Header_Image:"Obrázok hlavičky",
  Bank_Image:"Obrázok banky",
  Cut_Lines:"Čiary rezu",
  Background:"Pozadie",
  License:"Licencia",
  One_License:"1 licencia:",
  Licensed:"Licencované na:",
  Not_Licensed:"Bez licencie",
  Enter_Serial:"Zadajte sériové číslo",
  Serial_Key:"Sériový kľúč",
  Serial:"sériový",
  Product_Key:"Produktový kľúč",
  Check_Product_Key:"Skontrolujte kód Product Key",
  Add_Product_Key:"Pridať kód Product Key",
  Verifying_Purchase:"Overuje sa nákup...",
  Print_Envelope:"Tlačová obálka",
  Envelope:"Obálka",
  Thank_You:"Ďakujem!",
  Scale:"Mierka",
  Print_Scale:"Mierka tlače",
  Borders:"Hranice",
  VOID:"VOID",
  Void_Cheque:"Kontrola neplatnosti",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"PLATÍTE NA OBJEDNÁVKU:",
  NO_DOLLARS:"BEZ DOLÁROV ",
  ONE_DOLLAR:"JEDEN DOLÁR",
  DOLLARS:" DOLÁROV",
  AND:" A ",
  CENTS:" CENTOV.",
  NO_:"NIE ",
  ONE_:"JEDEN ",
  AND_NO_:"A NIE ",
  _AND_ONE_:"A JEDEN ",
  DOLLARS_AND_ONE_CENT:" A JEDEN CENT.",
  AND_NO_CENTS:" A NULA CENTOV.",
  CHEQUE_PRINT_DATE:"DÁTUM:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Počiatočné úlohy",
  Inital_Setup:"Úvodné nastavenie",
  Welcome_To:"Vitajte v ",
  Welcome:"Vitajte",
  Swipe:"Potiahnuť",
  Intro_Setup:"Nastavenie úvodu",
  Introduction:"Úvod",
  CHEQUE_PRINT_CREDIT:"Poháňané šekmi",
  Title_Intro_Word:"Vitajte v šekoch",
  Title_Intro:"Úvod šekov",
  Title_Setup:"Nastavenie šekov",
  PayPal_Default_Email_Message:"Dostali ste nový prevod PayPal. [Powered by Cheques]",
  Cheques_App_Export:"Exportované šekmi",
  Post_Intro_Thanks:"Ďakujeme, že ste si vybrali šeky. Proces nastavenia je teraz dokončený.",
  Post_Intro_Word:"Začnite vytlačením svojho prvého šeku, pridaním budúcej platby alebo pridaním zamestnancov na výplatnú pásku.",
  Upgrade_Required:"Šeky vyžadujú, aby ste vlastnili prémiovejšiu verziu softvéru, aby ste skryli túto správu a odomkli ďalšie funkcie.",
  Upgrade_Title:"Šeky",
  Mailout_Prompt:"Dodatočne si môžete zvoliť, aby Šeky posielali vaše výplatné šeky za vás.",
  Mailed_Cheque:"Poštou šekom: ",
  Mailed_Cheque_Color:"Poštový šek (farebne): ",
  Terms_Purchase:"Všetky elektronické nákupy pomocou šekov sú plne refundovateľné až do 30 dní od dátumu nákupu. Pred pokračovaním si prečítajte <a href='#'>Zmluvné podmienky</a> a vyjadrite s nimi súhlas.",
  Dashboard_Setup:"Nastavenie primárnej tlačiarne",
  Dashboard_Add:"Pridať hlavný bankový účet",
  Dashboard_Customize:"Vyberte šablónu kontroly",
  Dashboard_Job_Salary:"Vytvorte si prácu a pridajte svoj plat",
  Dashboard_Employees:"Sledovať zamestnancov a príjemcov",
  Dashboard_Print:"Vytlačte a odošlite svoje výplaty poštou",
  Dashboard_Payroll:"Automatizujte svoju tlač miezd",
  Dashboard_PayPal:"Nastavenie miezd / výplat PayPal",
  Begin_Setup:"Začať nastavenie",
  Get_Started:"Začať",
  Purchase:"Nákup",
  Lockdown:"Obmedzenie pohybu",
  Unlock:"Odomknúť",
  Detailed:"Podrobné",
  Log_In:"Prihlásiť sa",
  Login:"Prihlásiť sa",
  Launch:"Spustiť",
  Register:"Registrovať",
  Finish:"Skončiť",
  List:"Zoznam",
  Weekends:"Víkendy",
  Weekly:"Týždenne",
  PayPal_Default_Subject:"Nová platba",
  Payment_Message:"Platobná správa",
  PayPal_Default_Payment_Note:"Ďakujem",
  Setup_Your_Cheqing_Account:"Kontrolný účet",
  Add_Your_Primary_Cheqing_Account:"Pridajte svoj primárny účet cheqing.",
  Welcome_Word:"Zjednodušte a zautomatizujte riadenie miezd a ľudských zdrojov.",
  Get_Started_Quickly:"Stačí odpovedať na niekoľko jednoduchých otázok, ktoré nám pomôžu začať...",
  Done_Intro_Word:"Nastavenie dokončené",
  PIN_Protected:"Chránené heslom a kódom PIN",
  Enter_New_PIN:"Zadajte nový PIN kód:",
  Enter_PIN:"Zadajte PIN kód:",
  Invalid_PIN:"Zadali ste neplatný PIN.",
  Account_Identifier:"Identifikátor účtu",
  New_Account_Identifier:"Identifikátor nového účtu",
  attempt:"pokus",
  attempts:"pokusy",
  remaining:"zostávajúci",
  Language:"Jazyk",
  languages:"Jazyky",
  select_languages:"Zvoľ jazyk",
  Deposit:"Záloha",
  Hire_One_Now:"Hire One Now",
  App_Locked:"Aplikácia je uzamknutá.",
  Skip_:"Preskočiť",
  Skip_to_Dashboard:"Preskočiť na informačný panel",
  Dashboard:"Palubná doska",
  Import:"Import",
  Admin:"Správcovia",
  New_Admin:"Nový správca",
  Settings:"Nastavenie",
  Color_Picker:"Výber farieb",
  Font_Picker:"Výber písma",
  Logout:"Odhlásiť sa",
  Close:"Zavrieť",
  Close_menu:"Zavrieť",
  Excel:"Súbor Excel",
  Csv:"Súbor CSV",
  Sql:"Súbor SQL",
  Json:"Súbor JSON",
  Url:"Importovať podľa URL",
  Back:"Späť",
  Timers:"Časovače",
  Cheque:"Skontrolovať",
  Print:"Tlačiť",
  Designs:"Návrhy",
  Pause_Printing:"Pozastaviť tlač",
  Resume_Printing:"Obnoviť tlač",
  Printing_Paused:"Tlač pozastavená",
  PrintingUnavailable:"Prepáčte! Tlač nie je v tomto systéme k dispozícii.",
  Prints_Paused:"Tlače pozastavené",
  Administration:"Administrácia",
  Loading:"Načítava",
  Unnamed:"Nemenovaný",
  error:"Je nám ľúto, tento šek sa nepodarilo otvoriť na zobrazenie.",
  No_Cheques_To_Print:"Žiadne šeky na tlač",
  No_Cheques_To_Print_Word:"Vytvorte <a routerLink='/folder/Cheque/New'>Nový Šek</a>.",
  New_Cheque:"Nový šek",
  Start_One_Now:"Začať teraz",
  Edit_Cheque:"Kontrola úprav",
  Select_Cheques:"Vybrať kontroly",
  Select_Employee:"Vybrať zamestnanca",
  Default_Printer:"Predvolená tlačiareň",
  Reassign:"Znova prideliť",
  Configure:"Konfigurovať",
  Template:"Šablóna",
  Designer:"Návrhár",
  Edit_Designs:"Upraviť návrhy",
  New_Design:"Nový dizajn",
  Next:"Ďalšie",
  Save:"Uložiť",
  Name:"Názov",
  Mail:"Pošta",
  Amount:"Suma",
  Date:"Dátum",
  PayPal:"PayPal",
  Payouts:"Výplaty",
  PayPal_Label:"Štítok PayPal",
  Email_Username:"E-mail / používateľské meno",
  Client_ID:"ID klienta",
  Sandbox_Email:"E-mail karantény",
  PayPal_Email:"E-mail PayPal",
  PayPal_Username:"Používateľské meno API",
  PayPal_Payouts:"Výplaty cez PayPal",
  Select_PayPal_Key:"Vybrať kľúč PayPal",
  Secret:"Tajomstvo",
  API_Secret:"Tajomstvo API",
  PayPal_API_Keys:"PayPal Keys",
  New_PayPal_Key:"Nový kľúč PayPal",
  Email_Subject:"Predmet emailu",
  Email_Message:"E-mailová správa",
  Payout_Options:"Možnosti výplaty",
  Payment_Note:"Poznámka k platbe",
  Enable_Employee:"Povoliť zamestnanca",
  Enable_Production_Mode:"Povoliť produkčný režim",
  Sandbox_Username:"Používateľské meno karantény",
  Sandbox_Signature:"Podpis v karanténe",
  Sandbox_Password:"Heslo karantény",
  Production_Username:"Výrobné používateľské meno",
  Production_Signature:"Výrobný podpis",
  Production_Password:"Výrobné heslo",
  Production_Email:"Výrobný e-mail",
  API_Client_ID:"ID klienta API",
  API_Signature:"Podpis API",
  API_Password:"Heslo API",
  API_Username:"Používateľské meno API",
  Secret_Key:"Tajný kľúč",
  Sandbox:"Sandbox",
  Production:"Výroba",
  Sandbox_Keys:"Kľúče pieskoviska",
  Production_Keys:"Výrobné kľúče",
  API_Title:"Názov API",
  Production_Mode:"Výrobný režim",
  Account_Label:"Štítok účtu",
  No_PayPal_Setup:"Žiadny kľúč PayPal",
  Enable_PayPal_Account:"Povoliť účet PayPal",
  No_PayPal_Word:"Pridajte svoj <a routerLink='/folder/Invoice/New'>PayPal API Kľúč</a>.",
  Printed_Memo:"Tlačená poznámka",
  Employee:"Zamestnanec",
  View_Employee:"Zobraziť zamestnanca",
  Mailing_Address:"Mailová adresa",
  Company_Address:"Adresa firmy",
  Select_Company_Address:"Vyberte adresu spoločnosti",
  Address:"Adresa",
  Any_Day:"Ktorýkoľvek deň",
  Address_Name:"Meno adresy",
  Unit:"Jednotka",
  Account:"účet",
  Bank_Account:"Bankový účet",
  Account_Limits:"Povoliť limity účtu",
  Payroll:"Mzda",
  New_Payroll:"Nová výplatná páska",
  No_Payroll:"Žiadne nadchádzajúce výplaty",
  Upcoming_Holiday:"Nadchádzajúce prázdniny:",
  Invoice_Due:"Splatná faktúra:",
  New_Invoice:"Nová faktúra",
  No_Invoices:"Žiadne faktúry",
  No_Invoices_Word:"Vytvorte prvú <a routerLink='/folder/Invoice/New'>Faktúra</a>.",
  Cheque_Due:"Splatný šek:",
  Payrolls:"Mzdy",
  Sandbox_Mode:"Režim karantény",
  Hire:"Prenájom",
  Pay:"Zaplatiť",
  New:"Nový",
  Add:"Pridať",
  Make:"Urobiť",
  Time:"Čas",
  Write:"Napíš",
  Holiday:"Dovolenka",
  Holidays:"Prázdniny",
  Next_Holiday:"Budúce prázdniny:",
  All_Done:"Všetko hotové!",
  Employees:"Zamestnanci",
  Payees:"Príjemcovia",
  Job:"práca",
  Jobs:"pracovné miesta",
  Invoice:"Faktúra",
  Invoices:"Faktúry",
  Vacations:"Dovolenky",
  Cheques:"Šeky",
  Brand_Cheques:"značka",
  Payment:"Platba",
  Enable_Payment:"Povoliť platbu",
  Payments:"Platby",
  Schedule:"Rozvrh",
  Schedules:"Rozvrhy",
  Timesheet:"Časový výkaz",
  Timesheets:"Časové výkazy",
  Salary:"Plat",
  New_Address:"Nová adresa",
  Address_2:"2. riadok adresy)",
  _City:"Mesto",
  _State:"Štát/Pozemie",
  City:"Mesto / obec",
  State:"Štát / provincia",
  Postal:"PSČ",
  ZIP:"Pošta / PSČ",
  Country:"Krajina",
  Addresses:"Adresy",
  Required_Options:"Požadované možnosti",
  Optional_Options:"Voliteľné možnosti",
  Additional_Options:"Ďalšie možnosti",
  Required:"Požadovaný",
  Optional:"Voliteľné",
  Additional:"Dodatočné",
  No_Addresses:"Žiadne adresy",
  New_Address_Word:"Pridajte prvú <a routerLink='/folder/Address/New'>Adresa</a>.",
  Select_Address:"Vybrať adresu",
  No_Address:"Žiadne adresy",
  Print_Cheque:"Kontrola tlače",
  Print_Cheque_Now:"Vytlačiť kontrolu teraz",
  Description:"Popis",
  Pay_To_The_Order_Of:"Platba na objednávku:",
  Select_Date_Range:"Vybrať rozsah dátumov",
  Select_Starting_Date:"Vyberte dátum spustenia",
  Select_Ending_Date:"Vybrať dátum ukončenia",
  Select_Date:"Vybrať dátum",
  Cheque_Date:"Dátum kontroly",
  Cheque_Memo:"Skontrolovať poznámku",
  Blank_Cheque:"Prázdna kontrola",
  Blank:"Prázdne",
  No_Cheques:"Žiadne kontroly",
  No_Cheques_Word:"Vytlačte si svoj prvý <a routerLink='/folder/Cheque/New'>šek</a>.",
  Cheque_Amount_Placeholder:"0,00 USD",
  View_Image:"Pozrieť obrázok",
  View:"Vyhliadka",
  Modify:"Upraviť",
  Delete:"Odstrániť",
  Cheque_Paid:"Zaplatené",
  New_Deduction:"Nový odpočet",
  Title:"Názov",
  Frequency:"Frekvencia",
  Hourly:"Hodinovo",
  Daily:"Denne",
  Weekdays:"Dni v týždni",
  BiWeekly:"2 týždne",
  TriWeekly:"3 týždne",
  Monthly:"Mesačne",
  MiMonthly:"2 mesiace",
  Quarterly:"Štvrťročne",
  Yearly:"ročne",
  Every_Week:"Každý týždeň",
  Every_Payroll:"Každá výplatná páska",
  Every_Month:"Každý mesiac",
  Annually:"Ročne",
  Always_Scheduled:"Vždy naplánované",
  Start_Date:"Dátum začiatku",
  End_Date:"Dátum ukončenia",
  Start_Time:"Doba spustenia",
  End_Time:"Čas konca",
  Deduction_Label:"Štítok odpočtu",
  Notes:"Poznámky",
  Options:"Možnosti",
  Enable:"Povoliť",
  Select_Deductions:"Vybrať odpočty",
  Deductions:"Zrážky",
  No_Deductions:"Žiadne zrážky",
  No_Deductions_Word:"Vytvorte si svoj prvý <a routerLink='/folder/Deduction/New'>Odpočet</a>.",
  New_Employee:"Nový zamestnanec",
  No_Title:"Bez názvu",
  _Name:"Názov",
  About_Yourself:"O sebe",
  Full_Name:"Celé meno",
  Birthday:"narodeniny",
  Email:"E-mail",
  SMS:"SMS",
  Phone:"Telefón",
  Test:"Test",
  Call:"Volať",
  Fax:"Fax",
  Printed_Note:"Tlačená poznámka",
  Position:"pozícia",
  Job_Position:"Pracovná pozícia",
  Select_a_Job:"Vyberte prácu",
  Select_a_Salary:"Vyberte plat",
  Add_a_Deduction:"Pridať odpočet",
  Taxes:"dane",
  Add_Taxes:"Pridať dane",
  Date_of_Birth:"Dátum narodenia",
  Email_Address:"Emailová adresa",
  Phone_Number:"Telefónne číslo",
  Phone_Call:"Hovor",
  Enable_on_Payroll:"Povoliť na výplatnej listine",
  Select_Employees:"Vybrať zamestnancov",
  No_Employees:"Žiadni zamestnanci",
  No_Employees_Word:"Pridajte svojho prvého nového <a routerLink='/folder/Employee/New'>Zamestnanec</a>.",
  No_Name:"Bez mena",
  Unemployeed:"Nezamestnaní",
  Employeed:"Zamestnaný",
  Paid:"Zaplatené",
  Enabled:"Povolené",
  Disabled:"Zakázané",
  Fire:"Oheň",
  Not_Available:"Nie je k dispozícií",
  Not_Available_Word:"Vytlačte si svoju prvú <a routerLink='/folder/Invoice/New'>Faktúru</a> a získajte zaplatené.",
  Select_Invoices:"Vybrať_faktúry",
  Print_Invoice_Word:"Vytlačte si svoju prvú <a routerLink='/folder/Invoice/New'>Faktúru</a> a získajte zaplatené.",
  Invoice_Title:"Názov faktúry",
  Invoice_Date:"Dátum vystavenia faktúry",
  Due_Date:"Dátum splatnosti",
  New_Job:"Nová práca",
  Details:"Podrobnosti",
  Customize:"Prispôsobiť",
  Customize_Dashboard:"Prispôsobiť informačný panel",
  Components:"Komponenty",
  No_Components:"Žiadne komponenty",
  Main_Components:"Hlavné komponenty",
  Smaller_Components:"Menšie komponenty",
  Error_Loading_Page:"Chyba pri načítavaní tejto stránky.",
  Bank_Details:"Bankové údaje",
  About_Your_Job:"O vašej práci",
  Your_Schedule:"Váš rozvrh",
  Job_Title:"Názov práce",
  Job_Description:"Popis práce",
  Job_Details:"Podrobnosti o práci",
  Enable_Job:"Povoliť úlohu",
  Pay_Period:"Výplatná lehota",
  Perpetually_Schedule:"Perpetually Schedule",
  Select_Jobs:"Vybrať úlohy",
  No_Jobs:"Žiadne úlohy",
  Add_Jobs:"Pridať úlohy",
  No_Jobs_Word:"Pridajte prvú <a routerLink='/folder/Job/New'>Úlohu</a> do zoznamu.",
  Count_Employees:"job.employee_count+'Zamestnanci'",
  Zero_Employees:"0 zamestnancov",
  New_Leave:"Nová dovolenka",
  Leave_Name:"Nechajte meno",
  Paid_Leave:"Platená dovolenka",
  Leave_Pay:"Nechať zaplatiť",
  Indefinite_Leave:"Dovolenka na dobu neurčitú",
  Indefinite_Payroll:"Neurčitá výplatná listina",
  Leave:"Odísť",
  Add_Schedules:"Pridať plány",
  Percentage:"100 %",
  Enable_Leave_Of_Absence:"Povoliť neprítomnosť",
  Select_Leaves:"Vybrať listy",
  No_Leaves:"Žiadne listy neprítomnosti",
  Leave_Of_Absence:"Dovolenka neprítomnosti",
  Leaves_Of_Absence:"Listy neprítomnosti",
  New_Leave_of_Absense:"Nová dovolenka v neprítomnosti",
  No_Leaves_Word:"Pridať prvé <a routerLink='/folder/Leave/New'>Neprítomnosť</a>.",
  Not_Enabled:"Nepovolené",
  Absences:"Neprítomnosti",
  Payee:"Príjemca platby",
  New_Payee:"Nový príjemca",
  Payee_Identifier:"Identifikátor príjemcu",
  Payee_Name:"Meno príjemcu platby",
  Payee_Title:"Názov príjemcu",
  Payment_Memo:"Doklad o platbe",
  Select_Payees:"Vybrať príjemcov",
  No_Payees:"Žiadni príjemcovia",
  Add_Payee_Note:"Pridať prvého <a routerLink='/folder/Payee/New'>Príjemcu Platby</a>.",
  New_Payees:"Noví príjemcovia",
  About_The_Payment_Schedule:"Rozvrh platieb",
  Your_Payroll_Schedule:"Automatická výplatná páska",
  New_Payment:"Nová platba",
  Identifier:"Identifikátor",
  Select:"Vybrať",
  Memo:"Memo",
  Payment_Date:"Dátum platby",
  Mark_as_Paid:"Označiť ako zaplatené",
  Select_Payments:"Vybrať platby",
  No_Payments:"Žiadne platby",
  No_Payments_Word:"Vytvorte prvú <a routerLink='/folder/Payment/New'>Platbu</a>.",
  Create_Payroll:"Vytvoriť mzdový list",
  Properties:"Vlastnosti",
  Payroll_Title:"Mzdový titul",
  Payroll_Notes:"Výpisy zo mzdy",
  Payroll_Setup:"Nastavenie miezd",
  Tabulate_Payments:"Tabuľkové platby",
  Tabulate:"Tabuľka",
  By:"Od:",
  Payments_By:"Platby podľa",
  Timesheets_And_Schedules:"Časové výkazy a rozvrhy",
  Both:"Obaja",
  Items:"Položky",
  Add_Payees:"Pridať príjemcov",
  Add_Account:"Pridať účet",
  Enable_Account:"Povoliť účet",
  Enable_Payroll:"Povoliť mzdy",
  Print_Payroll:"Tlač mzdy",
  No_Payrolls:"Bez výplatnej pásky",
  No_Payroll_Word:"Vytvorte si svoj prvý <a routerLink='/folder/Payroll/New'>Mzdy</a>.",
  View_more:"VIDIEŤ VIAC",
  Less:"MENEJ",
  Add_Payroll:"Pridať mzdy",
  Select_Payroll:"Vybrať mzdy",
  About_Your_Salary:"O vašom plate",
  Add_Salaries:"Pridať platy",
  Add_Salary:"Pridať plat",
  Salaries:"Platy",
  No_Salaries:"Žiadne platy",
  No_Salaries_Word:"Pridať prvý <a routerLink='/folder/Salary/New'>Plat</a>.",
  Select_Salaries:"Vybrať platy",
  New_Salary:"Nový plat",
  Salary_Name:"Identifikátor platu",
  Enable_Salary:"Povoliť plat",
  Salary_Amount:"Výška platu",
  New_Schedule:"Nový rozvrh",
  Schedule_Title:"Názov rozvrhu",
  Add_Address:"Pridať adresu",
  Repeat:"Opakovať",
  Design:"Dizajn",
  Edit_Design:"Upraviť dizajn",
  Edit_this_Design:"Upraviť tento dizajn",
  Repeat_Payment:"Opakovaná platba",
  Enable_Schedule:"Povoliť plán",
  Never:"Nikdy",
  Select_Schedule:"Vybrať plány",
  No_Schedules:"Žiadne plány",
  No_Schedules_Word:"Vytvorte prvý <a routerLink='/folder/Schedule/New'>Rozvrh</a>.",
  New_Administrator:"Nový správca",
  Username:"Používateľské meno",
  First_Name:"Krstné meno",
  Last_Name:"Priezvisko",
  Add_an_Address:"Pridať adresu",
  Payment_Limit:"Limit na platbu",
  Total_Limit:"Celkový limit",
  Select_Accounts:"Vybrať účty",
  No_Administrators:"Žiadni správcovia",
  No_Administrators_Word:"Vytvorte prvý <a routerLink='/folder/Administrator/New'>Účet Správcu</a>.",
  New_Administrators_Word:"Pridať prvého <a routerLink='/folder/Administrator/New'>Administrator</a>",
  Cloud:"Oblak",
  Backup:"Záloha",
  Enable_iCloud:"Povoliť iCloud",
  Enable_Google_Drive:"Povoliť Disk Google",
  Enable_Microsoft_OneDrive:"Povoliť Microsoft OneDrive",
  Automatically_Backup:"Automaticky zálohovať",
  FTP_Settings:"Nastavenia FTP",
  URL_File_Prompt:"Uveďte umiestnenie pre súbor .xls / .xlsx / .json na import:",
  URL_SQL_Prompt:"Uveďte umiestnenie súboru SQLite na import:",
  FTP_Backup:"Záloha FTP",
  FTP_Import:"Import FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Hostiteľ",
  Port:"Port",
  Path:"Cesta",
  Data_Path:"Dátová cesta",
  Enable_FTP_Account:"Povoliť FTP účet",
  HostnameIP:"Meno hosťa",
  Password:"Heslo",
  Connection_Port:"Pripojovací port",
  Enable_MySQL_Database:"Povoliť databázu MySQL",
  Log:"Denník",
  Reset:"Obnoviť",
  Erase:"Vymazať",
  Export:"Export",
  Database:"Databáza",
  Upload_CSV:"Nahrať CSV",
  Download_CSV:"Stiahnuť CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Záloha MySQL",
  Internal_Notes:"Interné poznámky",
  Root_Path:"Koreňová cesta",
  Select_Backup:"Vybrať zálohu",
  Add_New_Backup:"Pridať novú zálohu",
  First_Backup:"Nastaviť prvú zálohu...",
  Backups:"Zálohy",
  Add_Backup:"Pridať zálohu",
  No_Backups:"Nenašli sa žiadne zálohy.",
  Select_Backup_Type:"Vyberte typ zálohy, ktorú chcete nastaviť...",
  Backup_Type:"Typ zálohy",
  FTP_Drive:"FTP disk",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Drive",
  Microsoft_OneDrive:"Drive",
  Import_Fields:"Importovať polia",
  Import_Fields_Word:"V tejto časti vyberte <a routerLink='/folder/Holiday/New'>Importovať</a> údaje.",
  Upload:"Nahrať",
  Download:"Stiahnuť ▼",
  Download_JSON:"Stiahnuť ako údaje JSON",
  Download_SQL:"Stiahnuť ako súbor SQL",
  New_Bank:"Nová banka",
  New_Account:"Nový účet",
  New_Bank_Account:"Nový bankový účet",
  Upload_Image:"Nahrať obrázok",
  Bank_Name:"Názov banky",
  Bank_Address:"Adresa banky",
  Branch_Address:"Adresa pobočky",
  Address_on_Cheque:"Adresa",
  Cheque_Numbers:"Skontrolovať čísla",
  Cheque_Details:"Skontrolovať podrobnosti",
  Bank_Logo:"Logo banky",
  Cheque_ID:"Skontrolovať ID",
  Starting_Cheque_ID:"Začiatok kontroly ID",
  Transit_Number:"Číslo tranzitu",
  Institution_Number:"Číslo inštitúcie",
  Designation_Number:"číslo označenia",
  Account_Number:"Číslo účtu",
  Limits:"Limity",
  Default_Limits:"Predvolené limity",
  Over_Limit:"Cez limit",
  Under_Limit:"Pod limitom",
  Payroll_Limit:"Mzdový limit",
  Enable_Bank_Account:"Povoliť bankový účet",
  Select_Account:"Vybrať účet",
  No_Bank_Account:"Žiadny bankový účet",
  No_Bank_Account_Word:"Pridajte prvý <a routerLink='/folder/Accounts/New'>Bankový Účet</a>.",
  Bank_Accounts:"Bankové účty",
  No_Accounts:"Žiadne účty",
  No_Accounts_Note:"Pridajte prvý <a routerLink='/folder/Accounts/New'>Bankový Účet</a>.",
  Cheque_Designer:"Skontrolovať dizajnéra",
  Cheque_Design:"Skontrolovať dizajn",
  Select_Design:"Vyberte dizajn",
  Cheque_Designs:"Skontrolovať návrhy",
  No_Cheque_Designs:"Návrhy bez kontroly",
  No_Cheque_Designs_Word:"Vytvorte si vlastný <a routerLink='/folder/Settings/Cheque/Design/New'>Skontrolujte Dizajn</a>.",
  Set_Default:"Nastaviť ako predvolenú",
  Default:"Predvolené",
  Remove:"Odstrániť",
  Folder:"Priečinok",
  Edit:"Upraviť",
  LoadingDots:"Načítava...",
  Add_a_New_File:"Pridať <a href='#' (click)='add()'>Nový Súbor</a> do",
  this_folder:"tento priečinok",
  FTP_Backup_Settings:"Nastavenia zálohovania FTP",
  Secure_File_Transfer:"Zabezpečený prenos súborov",
  New_Holiday:"Nový sviatok",
  Add_Holiday:"Pridať sviatok",
  Holiday_Name:"Názov dovolenky",
  Additional_Pay:"Dodatočný plat",
  Enable_Holiday:"Povoliť dovolenku",
  Select_Holidays:"Vybrať sviatky",
  No_Holidays:"Žiadne prázdniny",
  No_Holidays_Word:"Pridať prvý <a routerLink='/folder/Holiday/New'>Verejne Prazdniny</a>.",
  New_Import:"Nový import",
  Import_Data:"Importovať údaje",
  Import_Data_Word:"Vyberte typ súboru alebo spôsob nahrávania podľa vlastného výberu.<br /> Po nahraní podporovaného súboru budete môcť vybrať, ktoré importované polia v súbore zodpovedajú parametrom v aplikácii.", 
  Import_File:"Importovať súbor",
  Link_To_File:"Odkaz na súbor",
  Select_File:"Vybrať súbor",
  New_Moderator:"Nový moderátor",
  Allow_Moderation:"Povoliť moderovanie",
  Create_Paycheques:"Vytvoriť výplatné pásky",
  Edit_Paycheques_and_Data:"Upraviť výplaty a údaje",
  Destroy_Data_and_Paycheques:"Zničte údaje a výplaty",
  Bonus_Percentage:"Percento výplaty",
  Fixed_Amount:"Pevná suma",
  Select_Moderator:"Vybrať moderátora",
  No_Moderators:"Žiadni moderátori",
  Moderators:"Moderátori",
  Moderator_Account:"Vytvorte prvý <a routerLink='/folder/Administrator/New'>Účet Moderátora</a>.",
  No_Moderators_Word:"Pridajte prvého <a routerLink='/folder/Administrator/New'>Moderátora</a>.",
  Defaults:"Predvolené",
  Provide_Us_Info:"Poskytnite nám informácie",
  Setup_Your_Printer:"Nastaviť tlačiareň",
  Your_Company:"O vašej spoločnosti",
  Company_Name:"Meno spoločnosti",
  Currency:"Mena",
  Default_Currency:"Predvolená mena",
  Base_Monthly_Income:"Mesačný príjem",
  Protection:"Ochrana",
  App_Protection:"Ochrana aplikácií",
  PIN_Code_Protection:"Ochrana PIN kódom",
  App_Protection_Word:"Povoliť metódy zabezpečenia, ktoré pomáhajú chrániť váš účet.",
  PIN_Code:"PIN kód",
  Change_PIN:"Zmeniť PIN",
  New_Password:"Nové heslo",
  Geofence_Protection:"Ochrana geoplotu",
  Geofence_Area:"Nastaviť oblasť",
  Distance:"Vzdialenosť",
  Setup_Now:"Nastaviť teraz",
  Mile:"Míľa",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Rozpoznávanie tváre",
  Face:"Tvár",
  Setup:"Nastaviť",
  Label:"Štítok",
  Password_Protection:"Ochrana heslom",
  Modify_Password:"Upraviť heslo",
  New_Tax_Entry:"Nový daňový zápis",
  New_Tax:"Nová daň",
  Tax_Label:"daňový štítok",
  Perpetually_Enabled:"Trvalo povolené",
  Select_Taxes:"Vybrať dane",
  Tax_Deductions:"Daňové odpočty",
  No_Tax_Deductions:"Žiadne daňové odpočty",
  No_Tax_Deductions_Word:"Pridajte prvý <a routerLink='/folder/Tax/New'>Daňový</a> odpočet.",
  New_Timer:"Nový časovač",
  Start:"Štart",
  Stop:"Stop",
  Start_Timer:"Spustiť časovač",
  Stop_Timer:"Zastaviť časovač",
  Timer_Active:"Časovač aktívny",
  Timer:"Časovač:",
  Timer_Running:"Časovač: (Spustený)",
  Save_Timer:"Uložiť časovač",
  New_Timesheet:"Nový časový rozvrh",
  Select_Timesheets:"Vybrať časové výkazy",
  Work_Notes:"Pracovné poznámky",
  Entry_Title:"Vstupný názov",
  No_Timesheets:"Žiadne časové výkazy",
  No_Timesheets_Word:"Pridať prvý <a routerLink='/folder/Timesheet/New'>Časový Výkaz</a>.",
  Timesheet_Submitted:"Časový výkaz odoslaný",
  Timesheet_Congrats:"Blahoželáme! Váš časový výkaz bol úspešne odoslaný. Ďakujeme!",
  Timesheet_Copy:"Ak chcete získať kópiu svojich dokumentov, poskytnite nám svoju e-mailovú adresu a/alebo číslo mobilného telefónu.",
  Submit:"Predložiť",
  Allow_Notifications:"Povoliť upozornenia",
  Untitled_Entry:"Nový vstup",
  Untitled_Bank:"Banka bez názvu",
  Timesheet_Entry:"Zápis do časového výkazu",
  Work_Description:"Popis práce",
  Enable_Timesheet:"Povoliť časový rozvrh",
  Submit_Timesheet:"Odoslať časový výkaz",
  Vacation:"Dovolenka",
  New_Vacation:"Nová dovolenka",
  Vacation_Name:"Názov dovolenky",
  Paid_Vacation:"Platená dovolenka",
  Vacation_Pay:"Platenie za dovolenku",
  Enable_Vacation:"Povoliť dovolenku",
  Select_Vacations:"Vybrať dovolenky",
  No_Vacations:"Žiadne prázdniny",
  No_Vacations_Word:"Vytvorte prvú položku <a routerLink='/folder/Vacation/New'>Dovolenka</a>.",
  Payroll_Schedule:"Mzdový rozvrh",
  Next_Payroll:"Ďalšia výplatná páska:",
  Upcoming_Payroll:"Nadchádzajúce mzdy",
  No_Upcoming_Payroll:"Žiadne nadchádzajúce výplaty",
  Address_Book:"Adresár",
  Archived_Documents:"Archivované dokumenty",
  Dev_Mode:"Aplikácia v režime vývoja",
  Administrators:"Správcovia",
  Privacy:"Ochrana osobných údajov",
  None:"Žiadne",
  Select_Signature:"Vybrať podpis",
  No_Signatures:"Žiadne podpisy",
  No_Signatures_Word:"Pridať prvý <a routerLink='/folder/Signature/New'>Podpis</a>.",
  Repeat_Indefinitely:"Opakovať na neurčito",
  Sign:"Podpísať",
  Sign_Here:"Tu sa podpíšte",
  Date_Signed:"Dátum podpisu",
  Signature_Pad:"Podpisová podložka",
  Account_Holder:"Majiteľ účtu",
  Account_Properties:"Vlastnosti účtu",
  Name_On_Cheque:"Meno na šeku",
  Server_Hostname:"Názov hostiteľa / IP servera",
  Printers:"Tlačiarne",
  No_Printers:"Žiadne tlačiarne",
  Printer_Exists:'Tlačiareň s týmto názvom už existuje.',
  No_Printers_Word:"Pridať úplne prvú <a routerLink='/folder/Printer/New'>Tlačiareň</a>.",
  No_Printer_Alert:"Nie je definovaná žiadna tlačiareň. Chcete nastaviť tlačiareň?",
  Add_Printer:"Pridať tlačiareň",
  New_Printer:"Nová tlačiareň",
  Printer_Hostname:"Názov hostiteľa tlačiarne / IP",
  Printer_Label:"Štítok tlačiarne",
  Printer_Protocol:"Protokol tlačiarne",
  Protocol:"Protokol",
  Email_Print:"E-mail",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Zásuvka",
  Print_Job:"Tlačová úloha",
  Printed:"Vytlačené",
  Not_Printed:"Nevytlačené",
  Print_Jobs:"Tlačové úlohy",
  Print_Queue:"Tlačový front",
  No_Print_Jobs:"Žiadne tlačové úlohy",
  No_Prints:"Vytvorte nový <a routerLink='/folder/Cheque/New'>Šek</a> na tlač.",
  Prints:"Výtlačky",
  Find_Printer:"Nájsť tlačiareň",
  Find_AirPrint_Devices:"Nájsť zariadenia AirPrint",
  Select_Printer:"Vybrať tlačiareň",
  System_UI:"Používateľské rozhranie systému",
  Printer:"Tlačiareň",
  Status:"Postavenie",
  Preview:"Náhľad",
  Enable_Print_Job:"Povoliť tlačovú úlohu",
  Queue_Weight:"Hmotnosť poradia",
  Unlimited:"Neobmedzené",
  Show_Advanced_Printer_Options:"Zobraziť rozšírené možnosti tlačiarne",
  Advanced:"Pokročilé",
  Location:"Poloha",
  Note:"Poznámka",
  Queue_Name:"Názov fronty",
  Pages_Printed_Limit:"Limit vytlačených strán",
  MultiPage_Idle_Time:"Čakacia doba na viac strán (s)",
  Page_Count_Limit:"Limit počtu stránok",
  Page_Orientation:"Orientácia stránky",
  Portrait:"Portrét",
  Landscape:"Krajina",
  Duplex:"Duplex",
  Double_Sided_Printing:"Obojstranný",
  Duplex_Mode:"Duplexný režim",
  Duplex_Short:"Krátky",
  Duplex_Long:"Dlhé",
  Duplex_None:"Žiadne",
  Color_And_Quality:"Farba a kvalita",
  Monochrome:"Čiernobielo",
  Photo_Quality_Prints:"Výtlačky v kvalite fotografie",
  Printer_Email:"E-mail tlačiarne",
  Automatically_Downsize:"Automaticky zmenšiť",
  Paper:"Papier",
  Paper_Name:"Názov papiera",
  Paper_Width:"Šírka papiera",
  Paper_Height:"Výška papiera",
  Paper_Autocut_Length:"Dĺžka automatického orezania papiera",
  Margins:"Okraje",
  Page_Margins:"Okraje stránky",
  Page_Margin_Top:"Okraj hornej strany",
  Page_Margin_Right:"Pravý okraj stránky",
  Page_Margin_Bottom:"Okraj spodnej strany",
  Page_Margin_Left:"Ľavý okraj stránky",
  Add_Employees:"Pridať zamestnancov",
  Header:"Hlavička",
  Print_A_Page_Header:"Vytlačiť hlavičku strany",
  Header_Label:"Štítok hlavičky",
  Header_Page_Index:"Index hlavičky stránky",
  Header_Font_Name:"Písmo hlavičky",
  Select_Font:"Vybrať písmo",
  Font_Selector:"Výber písma",
  Header_Font_Size:"Písmo hlavičky",
  Header_Bold:"Tučné hlavička",
  Header_Italic:"Hlavička kurzíva",
  Header_Alignment:"Zarovnanie hlavičky",
  Left:"vľavo",
  Center:"Stred",
  Right:"Správny",
  Justified:"Odôvodnený",
  Header_Font_Color:"Farba hlavičky",
  Select_Color:"Vybrať farbu",
  Footer:"Päta",
  Print_A_Page_Footer:"Vytlačiť pätu strany",
  Footer_Label:"Štítok päty",
  Footer_Page_Index:"Index stránky päty",
  Footer_Font_Name:"Písmo päty",
  Fonts:"Písma",
  Done:"Hotový",
  Select_Fonts:"Vybrať písma",
  Footer_Font_Size:"Veľkosť päty",
  Footer_Bold:"Päta tučné",
  Footer_Italic:"Päta kurzíva",
  Footer_Alignment:"Zarovnanie päty",
  Footer_Font_Color:"Farba päty",
  Page_Copies:"Kópie stránok",
  Enable_Printer:"Povoliť tlačiareň",
  Remote_Logging:"Prihlásenie na diaľku",
  Log_Server:"Prihlasovací server",
  Encryption:"šifrovanie",
  Random_Key:"Náhodný kľúč",
  Encryption_Key:"šifrovací kľúč",
  Cheques_Webserver:"Vlastná databáza",
  Learn_How:"Naučte sa ako",
  Signature:"Podpis",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Zobraziť podpis",
  Printed_Signature:"Tlačený podpis",
  Digitally_Sign:"Digitálne podpísať",
  Digital_Signature:"Digitálny podpis",
  Add_Signature:"Pridať podpis",
  Add_Your_Signature:"Pridať svoj podpis",
  Enable_Signature:"Povoliť podpis",
  Digitally_Signed:"Digitálne podpísané",
  Insert_Error:"Nedá sa uložiť šek kvôli problémom s databázou.",
  Delete_Confirmation:"Naozaj chcete vymazať tieto informácie?",
  Discard_Confirmation:"Naozaj chcete zahodiť tieto informácie?",
  Discard_Bank_Confirmation:"Naozaj chcete zahodiť tento účet?",
  Discard_Printer_Confirmation:"Naozaj chcete túto tlačiareň zahodiť?",
  Delete_Bonus_Confirmation:"Naozaj chcete odstrániť tento bonus?",
  Delete_Invoice_Confirmation:"Naozaj chcete vymazať túto faktúru?",
  Generated_Cheque:"Vygenerovaná kontrola",
  Generated_Invoice:"Vygenerovaná faktúra",
  Schedule_Start_Time:"Spustenie plánu",
  Schedule_End_Time:"Koniec plánu",
  New_Call:"Nový hovor",
  No_Contacts:"Žiadne kontakty",
  No_Contacts_Word:"Administrátori, moderátori, zamestnanci a príjemcovia platieb sa zobrazujú ako kontakty.",
  PDF_Subject:"financie",
  PDF_Keywords:"kontrolné šeky PDF na výplatnú pásku",
  Printer_Setup:"Nastavenie tlačiarne",
  Printer_Selection:"Výber tlačiarne",
  New_Fax:"Nový fax",
  New_Fax_Message:"Nová faxová správa",
  Fax_Machine:"Fax",
  Fax_Name:"Názov faxu",
  Fax_Email:"Fax email",
  Fax_Number:"Číslo faxu",
  Contents:"Obsah",
  Fax_Body:"Telo stránky",
  Header_Word:"Hlavička:",
  Header_Text:"Text hlavičky",
  Include_Header:"Zahrnúť hlavičku",
  Include_Footer:"Zahrnúť pätu",
  Footer_Word:"Päta:",
  Footer_Text:"Text päty",
  Attach_a_Cheque:"Priložiť šek",
  Add_Deduction:"Pridať odpočet",
  Enable_Fax:"Odoslať fax",
  Select_Fax:"Vybrať fax",
  No_Faxes:"Žiadne faxy",
  Faxes:"Faxy",
  Save_and_Send:"Odoslať fax",
  Save_and_Pay:"Uložiť a zaplatiť",
  WARNING:"POZOR:",
  Remember:"Pamätať",
  Printing:"Tlač",
  Printing_Complete:"Tlač je dokončená!\n\n",
  of:"z",
  There_Were:"Boli tam",
  Successful_Prints:"úspešné výtlačky a ",
  Unsuccessful_Prints:"neúspešné výtlačky.",
  PrinterError:"Prepáčte! Vyskytla sa chyba.",
  Printing_:"Tlač...",
  PrinterSuccess:"Dokument sa úspešne vytlačil.",
  PrintersSuccess:"Dokumenty sa úspešne vytlačili.",
  Print_Cheques:"Tlač šekov",
  New_Message:"Nová správa",
  New_Messages:"Nové správy",
  Read_Message:"Prečítať správu",
  Write_Message:"Napísať správu",
  Send_Message:"Poslať správu",
  Subject:"Predmet",
  Message:"Správa",
  Writing:"Píšem...",
  Send:"Odoslať",
  Set_Date:"Nastaviť dátum",
  Set_Time:"Nastav čas",
  Recieve:"Prijať",
  Set_as_Default:"Nastaviť ako predvolenú",
  Default_Account:"Predvolený účet",
  Default_Design:"Predvolený dizajn",
  Multiple_Cheques:"Šeky (Trojnásobok)",
  Account_Mode:"Režim účtu",
  Multiple_Cheques_Description:"Tri kontroly na stránku.",
  Check_and_Table:"Kontrola a tabuľka",
  Check_including_Table:"Kontrolná a účtovná tabuľka.",
  Check_Mailer:"Skontrolovať poštovú schránku",
  Check_Mailer_Window:"Skontrolujte pomocou okna s adresou.",
  DocuGard_Table_Top:"DocuGard Kontrola a Tabuľka (Hore)",
  DocuGard_Table_Middle:"DocuGard Kontrola a Tabuľka (Uprostred)",
  DocuGard_Table_Bottom:"DocuGard Kontrola a Tabuľka (Dole)",
  DocuGard_Mailer_Top:"DocuGard Šek a Poštovné (Hore)",
  DocuGard_Mailer_Middle:"DocuGard Šek a Poštovné (Uprostred)",
  DocuGard_Mailer_Bottom:"DocuGard Šek a Poštovné (Dole)",
  DocuGard_Three_Cheques:"Kontroly DocuGard (Trojité)",
  DocuGard_Cheque_Top:"DocuGard Check (Hore)",
  DocuGard_Cheque_Middle:"Kontrola DocuGard (Uprostred)",
  DocuGard_Cheque_Bottom:"Kontrola DocuGard (Dole)",
  DocuGard_Three_Cheques_Window:"Tri kontroly na jednej strane.",
  DocuGard_Table_Top_Window:"Tabuľka šekov a zárobkov.",
  DocuGard_Table_Middle_Window:"Tabuľka šekov a zárobkov.",
  DocuGard_Table_Bottom_Window:"Tabuľka šekov a zárobkov.",
  DocuGard_Mailer_Top_Window:"Šek, stôl a adresa.",
  DocuGard_Mailer_Middle_Window:"Šek, stôl a adresa.",
  DocuGard_Mailer_Bottom_Window:"Šek, stôl a adresa.",
  DocuGard_Cheque_Top_Window:"Skontrolujte bezpečný papier.",
  DocuGard_Cheque_Middle_Window:"Skontrolujte bezpečný papier.",
  DocuGard_Cheque_Bottom_Window:"Skontrolujte bezpečný papier.",
  Basic_Cheque:"Skontrolovať (hore)",
  Basic_Cheque_Print:"Vytlačte jeden šek.",
  Error_Setting_As_Paid:"Chyba pri nastavení ako platené",
  Add_Attachment:"Pridať prílohu",
  PrinterUnavailable:"Tlačiareň nedostupná",
  CreditCardComponent:"Karty",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Nový vklad",
  Deposits:"vklady",
  No_Deposits:"Žiadne vklady",
  Credit_Card_Deposit:"Kreditná karta",
  New_Credit_Card_Deposit_Message:"Vklad kreditnou kartou",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Vklad bitcoinov",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Interac Transfer",
  Payments_Limit:"Platobný limit",
  No_Payment_Limit:"Žiadny platobný limit",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Vklad cez PayPal",
  No_Deposits_Word:"Pridať prvú spoločnosť <a routerLink='/folder/Deposit/New'>Vklad</a>.",
  No_Documents_Specified:"Neboli špecifikované žiadne dokumenty na tlač",
  No_Printers_Added:"Nenašli sa žiadne tlačiarne. Ak chcete jednu pridať, prejdite do časti Nastavenia > Tlačiarne.",
  DB_Erase_Prompt:"Úplne vymazať celú databázu? UPOZORNENIE: Prídete o všetky uložené informácie!",
  Console_Warning:"Do tejto konzoly nevkladajte žiadny text. Môžete vystaviť seba a/alebo svoju spoločnosť vážnemu riziku.",
  No_Faxes_Word:"Vytvorte prvý <a routerLink='/folder/Fax/New'>Fax</a>.",
  Cheque_Delete_Confirmation:"Naozaj chcete vymazať tento šek?",
  Design_Delete_Confirmation:"Naozaj chcete odstrániť tento dizajn?",
  Cheque_Pay_Confirmation:"Označiť tento šek ako zaplatený? NEZOBRAZÍ sa v tlačovom fronte.",
  Payment_Pay_Confirmation:"Označiť túto platbu ako zaplatenú? NEZOBRAZÍ sa v rade šekov.",
  Delete_Deduction_Confirmation:"Naozaj chcete odstrániť tento odpočet?",
  Delete_Job_Confirmation:"Naozaj chcete odstrániť túto úlohu?",
  Delete_Timesheet_Confirmation:"Naozaj chcete odstrániť tento časový rozvrh?",
  Delete_Schedule_Confirmation:"Naozaj chcete odstrániť tento plán?",
  Delete_Setting_Confirmation:"Naozaj chcete toto nastavenie resetovať?",
  Delete_Fax_Confirmation:"Naozaj chcete vymazať tento fax?",
  Delete_File_Confirmation:"Naozaj chcete odstrániť tento súbor?",
  Delete_Vacation_Confirmation:"Naozaj chcete odstrániť túto dovolenku?",
  Delete_Printer_Confirmation:"Naozaj chcete odstrániť túto tlačiareň?",
  Remove_Design_Confirmation:"Naozaj chcete odstrániť tento dizajn?",
  Delete_Payroll_Confirmation:"Naozaj chcete vymazať túto výplatnú pásku?",
  Send_Fax_Email_Confirmation:"Chcete tieto dokumenty odoslať faxom a e-mailom?",
  Send_Email_Confirmation:"Chcete poslať tento dokument e-mailom?",
  Send_Fax_Confirmation:"Chcete faxovať tento dokument?",
  Error_Generating_PDF:"Prepáčte. Pri generovaní tohto dokumentu sa vyskytla chyba.",
  PDF_Error_Saving_Image:"Prepáčte. Pri ukladaní obrázka PDF tohto dokumentu sa vyskytla chyba.",
  Test_Printer_Confirmation:"Chcete vytlačiť skúšobnú stránku na tejto tlačiarni?",
  Save_Timesheet_Prompt:"Prosím pridajte 'Názov' alebo stlačte 'Spustiť časovač' pre uloženie.",
  Remove_Geofence_Prompt:"Naozaj chcete odstrániť polohu tohto geografického plotu?",
  Delete_Employee_Confirmation:"Naozaj chcete odstrániť ",
  Fire_Employee_Confirmation:"Si si istý, že chceš strieľať?"
}