export const Fr = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Droit d'auteur &copie; 2023",
  black:"Noir",
  green:"Vert",
  gold:"Or",
  blue:"Bleu",
  brown:"Brun",
  purple:"Violet",
  pink:"Rose",
  red:"Rouge",
  Swatches:"Échantillons",
  HSL:"LGV",
  RGB:"RVB",
  Hue:"Teinte",
  Saturation:"Saturation",
  Lightness:"Légèreté",
  Upgrade_To_Pro:"Passer à Pro",
  AllFeaturesInclude:"Toutes les fonctionnalités incluent :",
  PrintUnlimitedCheques:"Imprimer des chèques illimités",
  PremiumChequeDesigns:"Conceptions de chèques de qualité supérieure",
  ManageUnlimitedEmployees:"Gérer un nombre illimité d'employés",
  AddUnlimitedPayees:"Ajouter des bénéficiaires illimités",
  CreateUnlimitedPayrolls:"Créer des paies illimitées",
  UnlimitedSchedulesandTimeSheets:"Horaires et feuilles de temps illimités",
  BulkPayPalPayouts:"Paiements PayPal en masse",
  UnlimitedBankAccounts:"Comptes bancaires illimités",
  ManageMultipleCompanies:"Gérer plusieurs entreprises",
  TrackInsurancePolicies:"Suivre les polices d'assurance",
  Bio_MetricProtection:"Protection bio-métrique",
  Geo_FenceLock_OutProtection:"Protection de verrouillage Geo-Fence",
  Multiple_Companies_Word:"La gestion de plusieurs entreprises n'est pas disponible sans chèques premium.",
  PayPal_Payouts_Word:"Les paiements PayPal sont désactivés sans chèques premium.",
  PINProtection:"Protection par NIP",
  PasswordProtection:"Mot de passe de protection",
  May_Require_Approval:"Peut nécessiter une approbation.",
  Subscribe:"S'abonner",
  Billed:"Facturé",
  Up:"En haut",
  Down:"Bas",
  Positioning:"Positionnement",
  Marker:"Marqueur",
  Drag_Marker:"Faites glisser le marqueur",
  Tagline:"Imprimer des chèques et compiler la paie",
  Marker_Word:"Utilisez les marqueurs pour dimensionner l'élément.",
  Pinch_Zoom:"Zoom par pincement",
  Pinch_Word:"Pincez pour agrandir l'élément.",
  Drag:"Glisser",
  Drag_Word:"Utilisez votre doigt pour faire glisser des éléments.",
  subscription_alias_word:"Abonnement à renouvellement automatique",
  premium_alias_word:"Forfait de mise à niveau unique",
  print_alias_word:"Impression des chèques individuels",
  mode_alias_word:"Mode",
  Pro:"Pro",
  Pro_word:"Version Pro requise.",
  Cant_Delete_Default_Company_Word:"Désolé, vous ne pouvez pas supprimer votre entreprise par défaut.",
  Reinsert_Default_Designs:"Réinsérer les conceptions par défaut",
  Reinsert_Default_Designs_word:"Voulez-vous réinsérer les conceptions par défaut ?",
  Subscription_Active_Disable_Word:"Cet abonnement est actif. Souhaitez-vous résilier cet abonnement aux Chèques ?",
  Company_Logo:"Logo d'entreprise",
  ZERO_:"ZÉRO",
  Disclaimer:"Clause de non-responsabilité",
  Privacy_Policy:"politique de confidentialité",
  EULA:"Vérifie le CLUF",
  Terms_Of_Service:"Conditions d'utilisation",
  Terms_Of_Use:"Conditions d'utilisation",
  Refunds:"Politique de remboursement",
  Single_Print:"1 Chèque",
  Two_Prints:"2 Chèques",
  Five_Prints:"5 Chèques",
  Ten_Prints:"10 Chèques",
  Fifteen_Prints:"15 Chèques",
  Twenty_Prints:"20 Chèques",
  Thirty_Prints:"30 Chèques",
  Image_Added:"Image ajoutée",
  Image_Preview:"Aperçu de l'image",
  No_Image_Was_Selected:"Aucune image n'a été sélectionnée.",
  Cheques_Unlimited:"Chèques Illimités",
  _Subscription:"Abonnement",
  Subscription:"Chèques - 1 Mois",
  Two_Month_Subscription:"Chèques - 2 Mois",
  Three_Month_Subscription:"Chèques - 3 Mois",
  Six_Month_Subscription:"Chèques - 6 Mois",
  Twelve_Month_Subscription:"Chèques - 12 Mois",
  Cheques_Are_Available:"Les chèques peuvent être imprimés.",
  Upgrade_Required_Two:"Sélectionnez un forfait et appuyez deux fois sur le bouton de prix pour commencer votre achat. Imprimez des chèques couleur d'aspect professionnel en quelques secondes.",
  Month:"Mois",
  Due:"Exigible:",
  Expires:"Expire :",
  Subscription_Active:"Abonnement actif",
  Subscription_Inactive:"Abonnement inactif",
  Purchase_Additional_Cheques:"Acheter des chèques supplémentaires ?",
  Printable_Cheque:"Chèque imprimable",
  Printable_Cheques:"Chèques imprimables",
  Printable_Cheque_Word:"chèque est disponible sur votre compte.",
  Printable_Cheques_Word:"des chèques sont disponibles sur votre compte.",
  Max_Amount_Message:"Le montant que vous avez spécifié a dépassé le montant maximum défini pour ce système :",
  Terms_Required_Word:"Vous devez accepter cet accord avant de continuer à utiliser Chèques.",
  Subscriptions:"Abonnements",
  Product_Upgrades:"Mises à jour",
  Mailed_Out_Cheques:"Chèques envoyés par la poste",
  Enter_A_Company_Name:"Veuillez entrer un nom d'entreprise.",
  Single_Cheques:"Chèques uniques",
  Cheque_Golden:"Chèque doré",
  Cheque_Golden_Window:"Conception à carreaux dorés.",
  Cheque_Green:"Coche verte",
  Cheque_Green_Window:"Conception à carreaux verts.",
  Cheque_Red:"Chèque rouge",
  Cheque_Red_Window:"Conception à carreaux rouges.",
  Cheque_Yellow:"Chèque jaune",
  Cheque_Yellow_Window:"Conception à carreaux jaunes.",
  Cheque_Statue_of_Liberty:"Statue de la Liberté",
  Cheque_Statue_of_Liberty_Window:"Motif à carreaux de la Statue de la Liberté.",
  Cheque_Green_Wave:"Vague verte",
  Cheque_Green_Wave_Window:"Conception à carreaux verts.",
  Cheque_Golden_Weave:"Tissage doré",
  Cheque_Golden_Weave_Window:"Design élégant à carreaux dorés.",
  Cheque_Green_Sun:"Soleil vert",
  Cheque_Green_Sun_Window:"Motif à carreaux profond et apaisant.",
  Cheque_Blue_Checkers:"Dames bleues",
  Cheque_Blue_Checkers_Window:"Conception à carreaux bleus.",
  Cashiers_Check:"Chèque de banque",
  Cashiers_Check_Window:"Modèle de style de chèque de banque.",
  Cheque_Aqua_Checkers:"Dames aquatiques",
  Cheque_Aqua_Checkers_Window:"Conception à carreaux aquatiques.",
  Cheque_Golden_Checkers:"Dames dorées",
  Cheque_Golden_Checkers_Window:"Conception à carreaux dorés.",
  Upgrade_Unavailable:"Mises à jour non disponibles",
  Bank_Code_Protection:"Protection du numéro bancaire",
  Bank_Code_Protection_Word:"Protégez vos numéros bancaires contre l'utilisation de cette application en cours d'exécution sur un autre appareil ou pour un autre utilisateur. Cette action est IRRÉVERSIBLE. Continuer?",
  Bank_Code_Protection_Blocked_Word:"Les numéros de banque que vous essayez d'utiliser sont réservés à un autre utilisateur ou appareil.",
  Bank_Code_Protection_Error_Word:"La vérification du numéro a échoué. Veuillez vous connecter à Internet et réessayer d'ajouter ce compte bancaire.",
  Bank_Code_Protection_Set_Error_Word:"La protection du numéro bancaire nécessite que vous soyez connecté à Internet. Veuillez vous connecter et réessayer.",
  Upgrade_Unavailable_Word:"Désolé, nous avons du mal à vous vérifier. Les abonnements et les mises à niveau des chèques ne sont actuellement pas disponibles à l'achat dans votre région.",
  PayPal_Payment_Preview:"Aperçu du paiement PayPal",
  Apple_Pay:"Payer Apple",
  Select_PayPal:"Sélectionnez Paypal",
  PayPal_Applications:"Demandes PayPal",
  Purchase_With_Apple_Pay:"Acheter avec Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Entreprises",
  Insurance:"Assurance",
  New_PayPal:"Nouveau PayPal",
  Pay_By:"Payer par",
  Insure:"Assurer",
  Miles:"Milles",
  Payment_Method:"Mode de paiement",
  Select_Policies:"Sélectionnez les politiques",
  Policies:"Stratégies",
  Policy:"Politique",
  No_PayPal_Account:"Pas de compte PayPal",
  No_Policies:"Aucune police d'assurance",
  New_Policy:"Nouvelle politique",
  PayPal_Payment:"Paiement PayPal",
  PayPal_Payments:"Paiements PayPal",
  No_Payment_Selected:"Aucun paiement sélectionné",
  Sending_Payment_Word:"Veuillez patienter... Ce paiement est en cours d'envoi.",
  Sending_Payments_Word:"Veuillez patienter... Les paiements sont en cours d'envoi.",
  No_Payment_Selected_PayPal:"Aucun <a routerLink='/folder/Payments'>paiement PayPal</a> sélectionné pour l'envoi.",
  Payment_Sent:"Paiement envoyé",
  PayPal_Payment_Sent:"Ce paiement a été envoyé avec PayPal.",
  Copay:"Co-paiement",
  Dead:"Mort",
  Alive:"Vivant",
  Not_Dead:"Pas mort",
  Unclaimed:"Non réclamés",
  Attempted:"Tentative",
  Deceased:"Défunt",
  Claimed:"Revendiqué",
  Unpaid:"Non payé",
  Sending_Payment:"Envoi du paiement",
  Sending_Payments:"Envoi de paiements",
  Send_PayPal_Confirmation:"Voulez-vous envoyer cette transaction avec PayPal ?",
  Send_PayPal_Confirmation_Word:"Appuyez sur le bouton vert pour envoyer cette transaction.",
  Save_Payment_As_Unpaid:"Enregistrer ce paiement comme impayé ?",
  Payment_Pay_Confirmation_PayPal:"Enregistrer ce paiement comme payé ?",
  No_Policies_Word:"Ajoutez la première <a routerLink='/folder/Postage/New'>police d'assurance</a> maintenant.",
  Timesheet_Multiple_Delete_Confirmation:"Voulez-vous vraiment supprimer plusieurs feuilles de temps ?",
  Select_Multiple_Timesheets_Prompt:"Aucune feuille de temps sélectionnée. Sélectionnez au moins une feuille de temps.",
  Select_Multiple_Policies_Prompt:"Aucune stratégie sélectionnée. Sélectionnez au moins une police d'assurance.",
  Policies_Multiple_Delete_Confirmation:"Voulez-vous vraiment supprimer plusieurs stratégies ?",
  Company:"Entreprise",
  Add_Company:"Ajouter une entreprise",
  New_Company:"Ajouter une entreprise",
  No_Companies:"Aucune entreprise",
  Enable_Company:"Activer la société",
  Select_Company:"Sélectionnez l'entreprise",
  The_Default_Company:"Le libellé de l'entreprise par défaut.",
  Manage_Companies:"Gérer les entreprises",
  No_Companies_Word:"Les chèques utiliseront une société par défaut.<br />Ajoutez la <a routerLink='/folder/Company/New'>première société</a>.",
  Default_Company:"Entreprise par défaut",
  Cheques_Unlimited_Word:"Chèques illimités vous permet d'imprimer autant de chèques que vous le souhaitez.",
  Cheques_Subscription_Word:"Un abonnement Chèques vous permet d'imprimer autant de chèques que vous le souhaitez.",
  You_Own_This_Product:"Vous possédez ce produit.",
  Your_Subscription_is_Active:"Votre abonnement est actif.",
  Active_Products:"Produits activés",
  Purchase_Confirmation:"Achat",
  Purchase_Cheques:"Chèques d'achat",
  Restore_Purchase:"Restaurer les achats",
  Erase_Purchase:"Effacer les achats",
  Successfully_Purchased:"Acheté avec succès",
  Enter_Your_Licence_Key:"Veuillez entrer votre clé de licence sur cette page pour activer ce produit.",
  Licence_Key:"Clé de licence",
  Enter_Licence_Key:"Entrez la clé de licence",
  Purchased:"Acheté",
  Enable_Feature:"Activer la fonctionnalité",
  Cancel_Subscription:"Annuler l'abonnement",
  Subscription_Removed:"Abonnement supprimé",
  Select_Active_Subscription:"Sélectionnez un abonnement actif afin de le modifier.",
  Remove_Subscription_Word:"Voulez-vous vraiment annuler cet abonnement ?",
  Delete_Company_Confirmation:"Voulez-vous vraiment supprimer l'intégralité de cette société ? AVERTISSEMENT : Vous perdrez toutes les informations stockées !",
  Delete_Default_Company_Word:"Vous ne pouvez pas supprimer la société par défaut. Souhaitez-vous réinitialiser l'application et la restaurer à l'état par défaut ? AVERTISSEMENT : Vous perdrez toutes les informations stockées !",
  Console_Warning_2:"Ne manipulez aucune devise en utilisant cette application qui n'est pas actuellement la vôtre.",
  Terms_and_Conditions:"Termes et conditions",
  and_the:"et le",
  for:"pour",
  Please_Read_Word:"Veuillez lire et accepter les",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Certains taux de conversion de devises sont introuvables. Veuillez vous connecter à Internet.",
  Free:"Gratuit",
  DB_Erase_Prompt_2:"Effacer complètement toute la base de données des développeurs ? ATTENTION : Vous perdrez toutes les informations d'ACHAT et d'ABONNEMENT !",
  Successfully_Imported:"Importé avec succès",
  Select_Postage:"Sélectionnez Frais de port",
  No_Postage:"Pas de timbres-poste",
  No_Paid_Postage_Word:"Ajoutez le premier timbre <a routerLink='/folder/Postage/New'>Affranchissement payé</a>.",
  Trial_Complete:'Essai terminé',
  Please_Upgrade:'Veuillez mettre à niveau Chèques pour continuer l\'impression.',
  Aa:"Aa",
  Color:"Couleur",
  Font:"Police de caractère",
  Guide:"Guide",
  Guides:"Guides",
  Image:"Image",
  Zoom:"Zoom",
  Logo:"Logo",
  Bank:"Banque",
  MICR:"MIC",
  Total:"Total",
  Cancel:"Annuler",
  Confirm:"Confirmer",
  Method:"Méthode",
  Biometric_Security:"Sécurité biométrique",
  Please_Login_To_Continue:"Veuillez vous connecter pour continuer.",
  Complete:"Complet",
  Sign_Up:"S'inscrire",
  Error:"Erreur",
  Biometrics:"Bio-métrique",
  Percent:"Pour cent",
  Zero_Percent:"0%",
  Top_Margin:"Marge supérieure",
  Bottom_Margin:"Marge inférieure",
  Left_Margin:"Marge de gauche",
  Right_Margin:"Marge droite",
  MICR_Margin:"Marge MICR",
  Table_Margin:"Marge du tableau",
  Address_Margin:"Marge d'adresse",
  Percentage_:"Pourcentage",
  Vacation_Title:"Titre de vacances",
  Use_PIN:"Utiliser le NIP",
  Loading__:"Chargement...",
  Design_Title:"Titre de conception",
  Authorize:"Autoriser",
  Key:"Clé",
  Public_Key:"Clé publique",
  Private_Key:"Clé privée",
  Authenticate:"Authentifier",
  Last_Payroll:"Dernière paie",
  Last_Calculation:"Dernier calcul",
  Authorized:"Autorisé",
  Geo_Authorized:"Géolocalisation : autorisée",
  Not_Authorized:"Pas autorisé",
  Authorization_Complete:"Autorisation terminée",
  Geolocation_Authorization:"Autorisation de géolocalisation",
  Out_of_Bounds:"Hors limites",
  Cant_Delete_Default_Design:"Impossible de supprimer un design par défaut.",
  Unauthorized_Out_of_Bounds:"Non autorisé : hors limites",
  Biometric_Authorization:"Autorisation biométrique",
  Locating_Please_Wait:"Localisation, veuillez patienter...",
  Test_Biometrics:"Testez la biométrie",
  Cheque_Margins:"Vérifier les marges",
  Percentage_Full_Error:"Le champ de pourcentage ne peut pas être défini sur 100 %.",
  No_Payroll_Text:"Ajoutez un <a routerLink='/folder/Employee/New'>Employee</a> ou <a routerLink='/folder/Payee/New'>Payee</a> et un <a routerLink='/folder/Schedule/New'>Planification</a>.",
  Design_Saved:"Conception enregistrée",
  Default_Design_Selected:"Conception par défaut sélectionnée",
  Partial_Import:"Importation partielle",
  Partial_Export:"Exportation partielle",
  Entire_Import:"Importation complète",
  Entire_Export:"Exportation entière",
  Existing_Password:"Veuillez saisir votre mot de passe existant :",
  Existing_PIN:"Veuillez saisir votre code PIN existant :",
  Pin_Change_Header:"Confirmation du NIP",
  Pin_Change_SubHeader:"Entrez votre ancien code PIN pour confirmer le changement.",
  Pass_Change_Header:"Confirmation mot de passe",
  Pass_Change_SubHeader:"Entrez votre ancien mot de passe pour confirmer le changement.",
  PIN_Enter_Message:"Saisissez votre code PIN pour confirmer.",
  Password_Enter_Message:"Entrez votre mot de passe pour confirmer.",
  Pin_Updated_Success:"PIN mis à jour avec succès !",
  Pin_Updated_Fail:"Le code PIN n'a pas pu être mis à jour.",
  Pass_Updated_Success:"Mot de passe mis à jour avec succès.",
  Pass_Updated_Fail:"Le mot de passe n'a pas pu être mis à jour.",
  Preview_Payment:"Aperçu du paiement",
  Preview_Payroll:"Aperçu de la paie",
  No_Payments_Created:"Aucun paiement n'a été créé.",
  Payment_Preview:"Aperçu du paiement",
  Enable_Payee:"Activer le bénéficiaire",
  Rendered:"Rendu",
  No_Email:"Pas d'e-mail",
  Setup_Cheques:"Vérifications de configuration",
  name:"Nom",
  address:"Adresse",
  address_2:"Adresse Ligne 2",
  city:"Ville",
  province:"Province",
  postal_code:"Code postal/postal",
  country:"Pays",
  username:"Nom d'utilisateur",
  full_name:"Nom et prénom",
  birthday:"Date d'anniversaire",
  email:"E-mail",
  phone:"Téléphoner",
  employees:"Des employés",
  addresses:"Adresses",
  payment_amount_limit:"Limite du montant du paiement",
  total_limit:"Limite totale",
  payees:"Bénéficiaires",
  description:"La description",
  address_line_one:"Adresse ligne 1",
  address_line_two:"Adresse ligne deux",
  image:"Image",
  account_holder:"Titulaire de compte",
  cheque_starting_id:"Vérifier l'ID de départ",
  transit_number:"Numéro de transit",
  institution_number:"Numéro de l'établissement",
  designation_number:"Numéro de désignation",
  account_number:"Numéro de compte",
  currency:"Devise",
  signature:"Signature",
  payment_payroll_limit:"Limite de paiement",
  total_limi:"Limite totale",
  date:"Date",
  printed_memo:"Mémo imprimé",
  banks:"Banques",
  signature_image:"Image signée",
  address_name:"Nom de l'adresse",
  notes:"Remarques",
  design:"Concevoir",
  title:"Titre",
  frequency:"La fréquence",
  fax:"Fax",
  salaries:"Les salaires",
  salary_ids:"ID de salaire",
  start_time:"Heure de début",
  end_time:"Heure de fin",
  paid:"Payé",
  overtime_percentage:"Pourcentage payé",
  overtime_amount:"Montant fixe payé",
  first_name:"Prénom",
  last_name:"Nom de famille",
  moderation:"Modération",
  create:"Créer",
  edit:"Éditer",
  destroy:"Détruire",
  day_start_time:"Day_start_time",
  day_end_time:"Day_end_time",
  fullname:"Nom",
  time:"Temps",
  auto_send:"Envoyer automatiquement",
  time_method:"Méthode de temps",
  schedules:"Des horaires",
  indefinite_payroll_enabled:"Activer indéfiniment",
  amount:"Montant",
  repeat:"Répéter",
  always_enabled:"Toujours activé",
  start_date:"Date de début",
  end_date:"Date de fin",
  Cheque_Total:"Vérifier le total",
  Total_Amount:"Montant total:",
  Amounts:"Les montants:",
  Images:"Images",
  Files:"Des dossiers",
  Previewing:"Aperçu :",
  Insert:"Insérer",
  Preview_Import:"Aperçu de l'importation",
  Entry:"Entrée",
  Entries:"Entrées",
  No_Entries:"Aucune entrée",
  Import_Type:"Type d'importation",
  Select_Details:"Sélectionnez Détails",
  Select_Payee:"Sélectionnez le bénéficiaire",
  Enable_Holidays:"Activer les jours fériés",
  Disable_Holidays:"Désactiver les vacances",
  Wire_Transfer:"Virement bancaire",
  New_Export:"Nouvelle exportation",
  Export_Data:"Exporter des données",
  Export_Data_Word:"Sélectionnez le type de fichier à exporter et à télécharger.",
  Export_File:"Exporter le fichier",
  Mode:"Mode",
  Times:"Fois",
  Widgets:"Widget",
  Slider:"Glissière",
  Set_Details:"Définir les détails",
  Select_Type:"Sélectionner le genre",
  Display_Slider:"Afficher le curseur",
  Dashboard_Slider:"Curseur du tableau de bord",
  Dashboard_Mode:"Mode tableau de bord",
  Show_Intro:"Afficher l'introduction",
  Show_Payrolls:"Afficher les paies",
  Show_Holidays:"Afficher les jours fériés",
  Show_Invoices:"Afficher les factures",
  Show_Cheques:"Afficher les chèques",
  Enabled_Widgets:"Widgets activés",
  Disabled_Widgets:"Widgets désactivés",
  Colors:"Couleurs",
  Barcodes:"Codes-barres",
  View_Timers:"Afficher les minuteurs",
  Gradients:"Dégradés",
  No_Info:"Pas d'information",
  Disable:"Désactiver",
  Show_Layer:"Afficher les calques",
  Hide_Layer:"Masquer les calques",
  Text_Layer:"Calques de texte",
  Secondly:"Deuxièmement",
  Minutely:"Minutieusement",
  nine_am:"09H00",
  five_pm:"17H00",
  Enable_Address:"Activer l'adresse",
  Invalid_File_Type:"Désolé, un type de fichier non valide a été sélectionné. Type de fichier pris en charge :",
  Error_Updating_Entry:"Désolé, une erreur s'est produite lors de la mise à jour de cette entrée.",
  Schedule_Timing_Alert:"Erreur : L'heure de début de la planification est définie sur une valeur postérieure à l'heure de fin.",
  Select_Multiple_Payments_Prompt:"Aucun paiement sélectionné. Sélectionnez au moins un paiement.",
  Select_Multiple_Cheques_Prompt:"Aucun chèque sélectionné. Veuillez sélectionner au moins un chèque.",
  Select_Multiple_Items_Prompt:"Aucun élément sélectionné. Veuillez sélectionner au moins un élément.",
  Paymemt_Multiple_Delete_Confirmation:"Voulez-vous vraiment supprimer plusieurs paiements ?",
  Cheque_Multiple_Delete_Confirmation:"Voulez-vous vraiment supprimer plusieurs chèques ?",
  Payee_Multiple_Delete_Confirmation:"Voulez-vous vraiment supprimer plusieurs bénéficiaires ?",
  Employee_Multiple_Delete_Confirmation:"Voulez-vous vraiment supprimer plusieurs employés ?",
  MICR_Warning:"Remarque : Certaines imprimantes et certains périphériques peuvent ne pas afficher correctement la police MICR.",
  Automatically_Send:"Envoyer automatiquement",
  Type:"Taper",
  Payment_Type:"Type de paiement",
  Auto_Send:"Envoi automatique",
  Automatically_Process:"Traiter automatiquement",
  Auto_Process:"Processus automatique",
  Image_Based:"Basé sur l'image",
  Font_Based:"Basé sur la police",
  Rerender:"Re-rendre",
  Rendering:"Le rendu",
  Render:"Des dossiers",
  Top:"Haut",
  Middle:"Milieu",
  Bottom:"Fond",
  Top_Left:"En haut à gauche",
  Top_Center:"Centre supérieur",
  Top_Right:"En haut à droite",
  Middle_Left:"Milieu gauche",
  Middle_Center:"Milieu Centre",
  Middle_Right:"Milieu droit",
  Bottom_Left:"En bas à gauche",
  Bottom_Center:"En bas au centre",
  Bottom_Right:"En bas à droite",
  Numbers:"Nombres",
  Verified:"Vérifié",
  Paper_Size:"Taille de papier",
  New_Device:"Nouvel appareil",
  Add_Device:"Ajouter un appareil",
  Remove_Device:"Enlevez l'appareil",
  Delete_Device:"Supprimer l'appareil",
  Block_Device:"Bloquer l'appareil",
  Block:"Bloquer",
  Unblock:"Débloquer",
  Table:"Table",
  Scan_Login_Code:"Scanner le code de connexion",
  Login_Code:"Code de connexion",
  Scan_Code:"Scanner le code",
  Scan_QR_Code:"Scanner le code QR",
  Select_All:"Tout sélectionner",
  Deselect_All:"Tout déselectionner",
  Increase:"Augmenter",
  Decrease:"Diminuer",
  Bold:"Audacieux",
  Text:"Texte",
  Style:"Style",
  Italic:"Italique",
  QR_Code:"QR Code",
  Barcode:"code à barre",
  Browse_Images:"Parcourir les images",
  Browse_Files:"Parcourir les fichiers",
  Background_Image:"Image de fond",
  Logo_Image:"Image logo",
  Header_Image:"Image d'en-tête",
  Bank_Image:"Image de la banque",
  Cut_Lines:"Lignes de coupe",
  Background:"Arrière plan",
  License:"Licence",
  One_License:"1 Licence :",
  Licensed:"Autorisé à:",
  Not_Licensed:"Pas de licence",
  Enter_Serial:"Entrez le numéro de série",
  Serial_Key:"Clé de série",
  Serial:"En série",
  Product_Key:"Clé de produit",
  Check_Product_Key:"Vérifier la clé de produit",
  Add_Product_Key:"Ajouter une clé de produit",
  Verifying_Purchase:"Vérification de l'achat...",
  Print_Envelope:"Imprimer l'enveloppe",
  Envelope:"Enveloppe",
  Thank_You:"Merci!",
  Scale:"Échelle",
  Print_Scale:"Échelle d'impression",
  Borders:"Les frontières",
  VOID:"ANNULER",
  Void_Cheque:"Chèque annulé",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"PAYER À L'ORDRE DE:",
  NO_DOLLARS:"PAS DE DOLLARS ",
  ONE_DOLLAR:"UN DOLLAR",
  DOLLARS:" DOLLARS",
  AND:" ET ",
  CENTS:" CENTS.",
  NO_:"NON ",
  ONE_:"UNE ",
  AND_NO_:"ET NON ",
  _AND_ONE_:"ET UNE ",
  DOLLARS_AND_ONE_CENT:" ET UN CENT.",
  AND_NO_CENTS:" ET ZÉRO CENTS.",
  CHEQUE_PRINT_DATE:"DATE:",
  CHEQUE_PRINT_MEMO:"NOTE:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"député",
  Initial_Tasks:"Tâches initiales",
  Inital_Setup:"Configuration initiale",
  Welcome_To:"Bienvenue à ",
  Welcome:"Bienvenue",
  Swipe:"Balayer",
  Intro_Setup:"Configuration d'introduction",
  Introduction:"Introduction",
  CHEQUE_PRINT_CREDIT:"Propulsé par des chèques",
  Title_Intro_Word:"Bienvenue aux Chèques",
  Title_Intro:"Présentation des chèques",
  Title_Setup:"Configuration des chèques",
  PayPal_Default_Email_Message:"Vous avez reçu un nouveau virement PayPal. [Propulsé par Chèques]",
  Cheques_App_Export:"Exporté par chèques",
  Post_Intro_Thanks:"Merci d'avoir choisi Chèques. Le processus de configuration est maintenant terminé.",
  Post_Intro_Word:"Commencez par imprimer votre premier chèque, ajoutez un paiement futur ou ajoutez un employé à la liste de paie.",
  Upgrade_Required:"Les chèques nécessitent que vous possédiez une version plus premium du logiciel afin de masquer ce message et de débloquer des fonctionnalités supplémentaires.",
  Upgrade_Title:"Chèques",
  Mailout_Prompt:"Vous pouvez également choisir de faire envoyer par la poste vos chèques de paie par Checks.",
  Mailed_Cheque:"Chèque postal :",
  Mailed_Cheque_Color:"Chèque postal (couleur) :",
  Terms_Purchase:"Tous les achats électroniques avec chèques sont entièrement remboursables jusqu'à 30 jours à compter de la date d'achat. Veuillez lire et accepter les <a href='#'>Conditions générales</a> avant de continuer.",
  Dashboard_Setup:"Configurer l'imprimante principale",
  Dashboard_Add:"Ajouter le compte bancaire principal",
  Dashboard_Customize:"Sélectionner un modèle de chèque",
  Dashboard_Job_Salary:"Créez votre emploi et ajoutez votre salaire",
  Dashboard_Employees:"Suivre les employés et les bénéficiaires",
  Dashboard_Print:"Imprimez et envoyez vos chèques de paie",
  Dashboard_Payroll:"Automatisez l'impression de votre paie",
  Dashboard_PayPal:"Configurer la paie/les paiements PayPal",
  Begin_Setup:"Commencer la configuration",
  Get_Started:"Commencer",
  Purchase:"Acheter",
  Lockdown:"Confinement",
  Unlock:"Ouvrir",
  Detailed:"Détaillé",
  Log_In:"Connexion",
  Login:"Connexion",
  Launch:"Lancer",
  Register:"S'inscrire",
  Finish:"Finir",
  List:"Lister",
  Weekends:"Fins de semaine",
  Weekly:"Hebdomadaire",
  PayPal_Default_Subject:"Nouveau Paiement",
  Payment_Message:"Message de paiement",
  PayPal_Default_Payment_Note:"Je vous remercie",
  Setup_Your_Cheqing_Account:"Compte de chèques",
  Add_Your_Primary_Cheqing_Account:"Ajoutez votre compte de chèques principal.",
  Welcome_Word:"Simplifier et automatiser la gestion de la paie et des ressources humaines.",
  Get_Started_Quickly:"Répondez simplement à quelques questions simples qui nous aideront à démarrer...",
  Done_Intro_Word:"Configuration terminée",
  PIN_Protected:"Mot de passe et code PIN protégés",
  Enter_New_PIN:"Entrez un nouveau code PIN :",
  Enter_PIN:"Entrez le code PIN :",
  Invalid_PIN:"PIN invalide saisi.",
  Account_Identifier:"Identifiant de compte",
  New_Account_Identifier:"Nouvel identifiant de compte",
  attempt:"tentative",
  attempts:"tentatives",
  remaining:"restant",
  Language:"Langue",
  languages:"Langues",
  select_languages:"Choisir la langue",
  Deposit:"Dépôt",
  Hire_One_Now:"Engagez-en un maintenant",
  App_Locked:"L'application est verrouillée.",
  Skip_:"Sauter",
  Skip_to_Dashboard:"Passer au tableau de bord",
  Dashboard:"Tableau de bord",
  Import:"Importer",
  Admin:"Administrateurs",
  New_Admin:"Nouvel administrateur",
  Settings:"Réglages",
  Color_Picker:"Pipette à couleurs",
  Font_Picker:"Sélecteur de polices",
  Logout:"Se déconnecter",
  Close:"Fermer",
  Close_menu:"Fermer",
  Excel:"Fichier Excel",
  Csv:"Fichier CSV",
  Sql:"Fichier SQL",
  Json:"Fichier JSON",
  Url:"Importer par URL",
  Back:"Retour",
  Timers:"Minuteries",
  Cheque:"Vérifier",
  Print:"Imprimer",
  Designs:"Dessins",
  Pause_Printing:"Suspendre l'impression",
  Resume_Printing:"Reprendre l'impression",
  Printing_Paused:"Impression en pause",
  PrintingUnavailable:"Désolé ! L'impression n'est pas disponible sur ce système.",
  Prints_Paused:"Impressions en pause",
  Administration:"Administration",
  Loading:"Chargement",
  Unnamed:"Anonyme",
  error:"Désolé, ce chèque n'a pas pu être ouvert pour consultation.",
  No_Cheques_To_Print:"Aucun chèque à imprimer",
  No_Cheques_To_Print_Word:"Créer un <a routerLink='/folder/Cheque/New'>Nouveau Chèque</a>.",
  New_Cheque:"Nouveau chèque",
  Start_One_Now:"Démarrez-en un maintenant",
  Edit_Cheque:"Modifier le chèque",
  Select_Cheques:"Sélectionner les chèques",
  Select_Employee:"Sélectionner un employé",
  Default_Printer:"Imprimante par défaut",
  Reassign:"Réaffecter",
  Configure:"Configurer",
  Template:"Modèle",
  Designer:"Designer",
  Edit_Designs:"Modifier les dessins",
  New_Design:"Nouveau design",
  Next:"Suivant",
  Save:"Sauvegarder",
  Name:"Nom",
  Mail:"Poster",
  Amount:"Quantité",
  Date:"Date",
  PayPal:"Pay Pal",
  Payouts:"Paiements",
  PayPal_Label:"Libellé Paypal",
  Email_Username:"E-mail / Nom d'utilisateur",
  Client_ID:"Identité du client",
  Sandbox_Email:"E-mail bac à sable",
  PayPal_Email:"Email Paypal",
  PayPal_Username:"Nom d'utilisateur API",
  PayPal_Payouts:"Paiements PayPal",
  Select_PayPal_Key:"Sélectionnez la clé PayPal",
  Secret:"Secret",
  API_Secret:"API secrète",
  PayPal_API_Keys:"Clés Paypal",
  New_PayPal_Key:"Nouvelle clé PayPal",
  Email_Subject:"Sujet du courriel",
  Email_Message:"Message électronique",
  Payout_Options:"Options de paiement",
  Payment_Note:"Bon de paiement",
  Enable_Employee:"Activer l'employé",
  Enable_Production_Mode:"Activer le mode de production",
  Sandbox_Username:"Nom d'utilisateur du bac à sable",
  Sandbox_Signature:"Sandbox Signature",
  Sandbox_Password:"Mot de passe du bac à sable",
  Production_Username:"Nom d'utilisateur de production",
  Production_Signature:"Signature de fabrication",
  Production_Password:"Mot de passe de production",
  Production_Email:"E-mail de production",
  API_Client_ID:"Identifiant client API",
  API_Signature:"Signature API",
  API_Password:"Mot de passe API",
  API_Username:"Nom d'utilisateur API",
  Secret_Key:"Clef secrète",
  Sandbox:"Bac à sable",
  Production:"Production",
  Sandbox_Keys:"Clés de bac à sable",
  Production_Keys:"Clés de fabrication",
  API_Title:"Titre de l'API",
  Production_Mode:"Mode de fabrication",
  Account_Label:"Libellé de compte",
  No_PayPal_Setup:"Pas de clé PayPal",
  Enable_PayPal_Account:"Activer le compte PayPal",
  No_PayPal_Word:"Ajoutez votre <a routerLink='/folder/Invoice/New'>Clé API PayPal</a>.",
  Printed_Memo:"Mémo imprimé",
  Employee:"Employé",
  View_Employee:"Afficher l'employé",
  Mailing_Address:"Adresse postale",
  Company_Address:"Adresse de la société",
  Select_Company_Address:"Sélectionner l'adresse de l'entreprise",
  Address:"Adresse",
  Any_Day:"N'importe quel jour",
  Address_Name:"Nom de l'adresse",
  Unit:"Unité",
  Account:"Compte",
  Bank_Account:"Compte bancaire",
  Account_Limits:"Activer les limites de compte",
  Payroll:"Paie",
  New_Payroll:"Nouvelle paie",
  No_Payroll:"Pas de paie à venir",
  Upcoming_Holiday:"Vacances à venir :",
  Invoice_Due:"Facture due :",
  New_Invoice:"Nouvelle facture",
  No_Invoices:"Pas de factures",
  No_Invoices_Word:"Créer la première <a routerLink='/folder/Invoice/New'>Facture</a>.",
  Cheque_Due:"Chèque dû :",
  Payrolls:"Fiches de paie",
  Sandbox_Mode:"Mode bac à sable",
  Hire:"Embaucher",
  Pay:"Payer",
  New:"Nouvelle",
  Add:"Ajouter",
  Make:"Faire",
  Time:"Temps",
  Write:"Écrivez",
  Holiday:"Vacances",
  Holidays:"Vacances",
  Next_Holiday:"Les prochaines vacances:",
  All_Done:"Terminé!",
  Employees:"Des employés",
  Payees:"Bénéficiaires",
  Job:"Travail",
  Jobs:"Travaux",
  Invoice:"Facture d'achat",
  Invoices:"Factures",
  Vacations:"Les vacances",
  Cheques:"Chèques",
  Brand_Cheques:"Marque",
  Payment:"Paiement",
  Enable_Payment:"Activer le paiement",
  Payments:"Paiements",
  Schedule:"Programme",
  Schedules:"Des horaires",
  Timesheet:"Emploi du temps",
  Timesheets:"Feuilles de temps",
  Salary:"Un salaire",
  New_Address:"Nouvelle adresse",
  Address_2:"Adresse Ligne 2)",
  _City:"Ville",
  _State:"État/Prov",
  City:"Ville / Canton",
  State:"Etat/Province",
  Postal:"Code postal / postal",
  ZIP:"Poste / Code postal",
  Country:"Pays",
  Addresses:"Adresses",
  Required_Options:"Options requises",
  Optional_Options:" Options facultatives ",
  Additional_Options:"Options additionelles",
  Required:"Obligatoire",
  Optional:"Optionnel",
  Additional:"Supplémentaire",
  No_Addresses:"Aucune adresse",
  New_Address_Word:"Ajouter la première <a routerLink='/folder/Address/New'>Adresse</a>.",
  Select_Address:"Sélectionner l'adresse",
  No_Address:"Aucune adresse",
  Print_Cheque:"Imprimer le chèque",
  Print_Cheque_Now:"Imprimer le chèque maintenant",
  Description:"La description",
  Pay_To_The_Order_Of:"Payer à l'ordre de:",
  Select_Date_Range:"Sélectionner une plage de dates",
  Select_Starting_Date:"Sélectionner la date de début",
  Select_Ending_Date:"Sélectionner la date de fin",
  Select_Date:"Sélectionner une date",
  Cheque_Date:"Date de vérification, date du chèque",
  Cheque_Memo:"Vérifier le mémo",
  Blank_Cheque:"Chèque en blanc",
  Blank:"Vierge",
  No_Cheques:"Aucun contrôle",
  No_Cheques_Word:"Imprimez votre premier <a routerLink='/folder/Cheque/New'>Chèque</a>.",
  Cheque_Amount_Placeholder:"0,00 $",
  View_Image:"Voir l'image",
  View:"Voir",
  Modify:"Modifier",
  Delete:"Supprimer",
  Cheque_Paid:"Payé",
  New_Deduction:"Nouvelle déduction",
  Title:"Titre",
  Frequency:"La fréquence",
  Hourly:"Horaire",
  Daily:"Du quotidien",
  Weekdays:"Jours de la semaine",
  BiWeekly:"2 semaines",
  TriWeekly:"3 semaines",
  Monthly:"Mensuel",
  MiMonthly:"2 mois",
  Quarterly:"Trimestriel",
  Yearly:"Annuel",
  Every_Week:"Toutes les semaines",
  Every_Payroll:"Chaque paie",
  Every_Month:"Chaque mois",
  Annually:"Annuellement",
  Always_Scheduled:"Toujours programmé",
  Start_Date:"Date de début",
  End_Date:"Date de fin",
  Start_Time:"Heure de début",
  End_Time:"Heure de fin",
  Deduction_Label:"Étiquette de déduction",
  Notes:"Remarques",
  Options:"Options",
  Enable:"Activer",
  Select_Deductions:"Sélectionnez les déductions",
  Deductions:"Déductions",
  No_Deductions:"Aucune déduction",
  No_Deductions_Word:"Créez votre première <a routerLink='/folder/Deduction/New'>Déduction</a>.",
  New_Employee:"Nouvel employé",
  No_Title:"Pas de titre",
  _Name:"Nom",
  About_Yourself:"A propos de toi",
  Full_Name:"Nom complet",
  Birthday:"Date d'anniversaire",
  Email:"E-mail",
  SMS:"SMS",
  Phone:"Téléphoner",
  Test:"Test",
  Call:"Appeler",
  Fax:"Fax",
  Printed_Note:"Note imprimée",
  Position:"Position",
  Job_Position:"Poste",
  Select_a_Job:"Sélectionnez un travail",
  Select_a_Salary:"Sélectionnez un salaire",
  Add_a_Deduction:"Ajouter une déduction",
  Taxes:"Impôts",
  Add_Taxes:"Ajouter des taxes",
  Date_of_Birth:"Date de naissance",
  Email_Address:"Adresse e-mail",
  Phone_Number:"Numéro de téléphone",
  Phone_Call:"Appel téléphonique",
  Enable_on_Payroll:"Activer sur la paie",
  Select_Employees:"Sélectionner les employés",
  No_Employees:"Pas d'employés",
  No_Employees_Word:"Ajoutez votre premier nouvel <a routerLink='/folder/Employee/New'>Employé</a>.",
  No_Name:"Sans nom",
  Unemployeed:"Sans emploi",
  Employeed:"Salarié",
  Paid:"Payé",
  Enabled:"Activée",
  Disabled:"Désactivée",
  Fire:"Feu",
  Not_Available:"Indisponible",
  Not_Available_Word:"Imprimez votre première <a routerLink='/folder/Invoice/New'>Facture</a> et soyez payé.",
  Select_Invoices:"Select_Factures",
  Print_Invoice_Word:"Imprimez votre première <a routerLink='/folder/Invoice/New'>Facture</a> et soyez payé.",
  Invoice_Title:"Titre de la facture",
  Invoice_Date:"Date de facturation",
  Due_Date:"Date d'échéance",
  New_Job:"Nouveau travail",
  Details:"Des détails",
  Customize:"Personnaliser",
  Customize_Dashboard:"Personnaliser le tableau de bord",
  Components:"Composants",
  No_Components:"Aucun composant",
  Main_Components:"Composants principaux",
  Smaller_Components:"Petits composants",
  Error_Loading_Page:"Erreur lors du chargement de cette page.",
  Bank_Details:"Coordonnées bancaires",
  About_Your_Job:"À propos de votre travail",
  Your_Schedule:"Votre horaire",
  Job_Title:"Titre d'emploi",
  Job_Description:"Description de l'emploi",
  Job_Details:"Détails du poste",
  Enable_Job:"Activer le travail",
  Pay_Period:"Période de paie",
  Perpetually_Schedule:"Programme perpétuel",
  Select_Jobs:"Sélectionner les travaux",
  No_Jobs:"Pas d'emplois",
  Add_Jobs:"Ajouter des emplois",
  No_Jobs_Word:"Ajouter le premier <a routerLink='/folder/Job/New'>Tâche</a> à la liste.",
  Count_Employees:"job.employee_count+'Employés'",
  Zero_Employees:"0 Employés",
  New_Leave:"Nouveau congé",
  Leave_Name:"Laisser le nom",
  Paid_Leave:"Congés payés",
  Leave_Pay:"Abandonner le paiement",
  Indefinite_Leave:"Congé indéfini",
  Indefinite_Payroll:"Paie Indéfinie",
  Leave:"Laisser",
  Add_Schedules:"Ajouter des horaires",
  Percentage:"100 %",
  Enable_Leave_Of_Absence:"Activer les absences",
  Select_Leaves:"Sélectionner les feuilles",
  No_Leaves:"Pas de congés",
  Leave_Of_Absence:"Congé d'absence",
  Leaves_Of_Absence:"Congés d'absence",
  New_Leave_of_Absense:"Nouveau congé autorisé",
  No_Leaves_Word:"Ajouter le premier <a routerLink='/folder/Leave/New'>Congé Autorisé</a>.",
  Not_Enabled:"Pas activé",
  Absences:"Absences",
  Payee:"Bénéficiaire",
  New_Payee:"Nouveau Bénéficiaire",
  Payee_Identifier:"Identifiant du bénéficiaire",
  Payee_Name:"Le nom du bénéficiaire",
  Payee_Title:"Titre du bénéficiaire",
  Payment_Memo:"Note de paiement",
  Select_Payees:"Sélectionner les bénéficiaires",
  No_Payees:"Aucun bénéficiaire",
  Add_Payee_Note:"Ajouter le premier <a routerLink='/folder/Payee/New'>Bénéficiaire</a>.",
  New_Payees:"Nouveaux Bénéficiaires",
  About_The_Payment_Schedule:"Calendrier de paiement",
  Your_Payroll_Schedule:"Paie automatique",
  New_Payment:"Nouveau Paiement",
  Identifier:"Identifiant",
  Select:"Sélectionner",
  Memo:"Note",
  Payment_Date:"Date de paiement",
  Mark_as_Paid:"Marquer comme payé",
  Select_Payments:"Sélectionnez Paiements",
  No_Payments:"Aucun paiement",
  No_Payments_Word:"Créer le premier <a routerLink='/folder/Payment/New'>Paiement</a>.",
  Create_Payroll:"Créer la paie",
  Properties:"Propriétés",
  Payroll_Title:"Titre de la paie",
  Payroll_Notes:"Notes de paie",
  Payroll_Setup:"Configuration de la paie",
  Tabulate_Payments:"Tabuler les paiements",
  Tabulate:"Tabuler",
  By:"Par:",
  Payments_By:"Paiements par",
  Timesheets_And_Schedules:"Feuilles de temps et horaires",
  Both:"Les deux",
  Items:"Articles",
  Add_Payees:"Ajouter des bénéficiaires",
  Add_Account:"Ajouter un compte",
  Enable_Account:"Activer le compte",
  Enable_Payroll:"Activer la paie",
  Print_Payroll:"Imprimer la paie",
  No_Payrolls:"Pas de paie",
  No_Payroll_Word:"Créez votre premier <a routerLink='/folder/Payroll/New'>Paie</a>.",
  View_more:"VOIR PLUS",
  Less:"MOINS",
  Add_Payroll:"Ajouter la paie",
  Select_Payroll:"Sélectionner la paie",
  About_Your_Salary:"À propos de votre salaire",
  Add_Salaries:"Ajouter des salaires",
  Add_Salary:"Ajouter un salaire",
  Salaries:"Les salaires",
  No_Salaries:"Pas de salaire",
  No_Salaries_Word:"Ajouter le premier <a routerLink='/folder/Salary/New'>Salaire</a>.",
  Select_Salaries:"Sélectionner les salaires",
  New_Salary:"Nouveau salaire",
  Salary_Name:"Identifiant de salaire",
  Enable_Salary:"Activer le salaire",
  Salary_Amount:"Montant du salaire",
  New_Schedule:"Nouveau programme",
  Schedule_Title:"Titre de l'annexe",
  Add_Address:"Ajoutez l'adresse",
  Repeat:"Répéter",
  Design:"Conception",
  Edit_Design:"Modifier la conception",
  Edit_this_Design:"Modifier ce design",
  Repeat_Payment:"Paiement répété",
  Enable_Schedule:"Activer le calendrier",
  Never:"Jamais",
  Select_Schedule:"Sélectionner les horaires",
  No_Schedules:"Pas d'horaires",
  No_Schedules_Word:"Créer le premier <a routerLink='/folder/Schedule/New'>Planification</a>.",
  New_Administrator:"Nouvel administrateur",
  Username:"Nom d'utilisateur",
  First_Name:"Prénom",
  Last_Name:"Nom de famille",
  Add_an_Address:"Ajouter une adresse",
  Payment_Limit:"Limite par paiement",
  Total_Limit:"Limite totale",
  Select_Accounts:"Sélectionner des comptes",
  No_Administrators:"Aucun administrateur",
  No_Administrators_Word:"Créez le premier <a routerLink='/folder/Administrator/New'>Compte Administrateur</a>.",
  New_Administrators_Word:"Ajouter le premier <a routerLink='/folder/Administrator/New'>Administrateur</a>",
  Cloud:"Nuage",
  Backup:"Sauvegarde",
  Enable_iCloud:"Activer iCloud",
  Enable_Google_Drive:"Activer Google Drive",
  Enable_Microsoft_OneDrive:"Activer Microsoft OneDrive",
  Automatically_Backup:"Sauvegarder automatiquement",
  FTP_Settings:"Paramètres FTP",
  URL_File_Prompt:"Veuillez spécifier l'emplacement d'un fichier .xls / .xlsx / .json à importer :",
  URL_SQL_Prompt:"Veuillez spécifier l'emplacement du fichier SQLite à importer :",
  FTP_Backup:"Sauvegarde FTP",
  FTP_Import:"Importation FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Héberger",
  Port:"Port",
  Path:"Chemin",
  Data_Path:"Chemin de données",
  Enable_FTP_Account:"Activer le compte FTP",
  HostnameIP:"Nom d'hôte",
  Password:"Mot de passe",
  Connection_Port:" Port de connexion ",
  Enable_MySQL_Database:"Activer la base de données MySQL",
  Log:"Enregistrer",
  Reset:"Réinitialiser",
  Erase:"Effacer",
  Export:"Exportation",
  Database:"Base de données",
  Upload_CSV:"Télécharger CSV",
  Download_CSV:"Télécharger CSV",
  SQL_Database:"URL SQL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Sauvegarde MySQL",
  Internal_Notes:"Notes internes",
  Root_Path:"Chemin racine",
  Select_Backup:"Sélectionner Sauvegarde",
  Add_New_Backup:"Ajouter une nouvelle sauvegarde",
  First_Backup:"Configurer la première sauvegarde...",
  Backups:"sauvegardes",
  Add_Backup:"Ajouter une sauvegarde",
  No_Backups:"Il n'y a pas de sauvegardes à trouver.",
  Select_Backup_Type:"Sélectionnez le type de sauvegarde que vous souhaitez configurer...",
  Backup_Type:"Type de sauvegarde",
  FTP_Drive:"Drive FTP",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Conduire",
  Microsoft_OneDrive:"Conduire",
  Import_Fields:"Champs d'importation",
  Import_Fields_Word:"Utilisez cette section pour sélectionner <a routerLink='/folder/Holiday/New'>Importer</a> les données.",
  Upload:"Télécharger",
  Download:"Télécharger",
  Download_JSON:"Télécharger en tant que données JSON",
  Download_SQL:"Télécharger en tant que fichier SQL",
  New_Bank:"Nouvelle banque",
  New_Account:"Nouveau compte",
  New_Bank_Account:"Nouveau compte bancaire",
  Upload_Image:"Télécharger une image",
  Bank_Name:"Nom de banque",
  Bank_Address:"Adresse de la banque",
  Branch_Address:"Adresse de la succursale",
  Address_on_Cheque:"Adresse",
  Cheque_Numbers:"Vérifier les numéros",
  Cheque_Details:"Vérifier les détails",
  Bank_Logo:"Logo de la Banque",
  Cheque_ID:"Vérifier l'identité",
  Starting_Cheque_ID:"Démarrage de la vérification de l'ID",
  Transit_Number:"Numéro de transit",
  Institution_Number:"Numéro d'établissement",
  Designation_Number:"Numéro de désignation",
  Account_Number:"Numéro de compte",
  Limits:"Limites",
  Default_Limits:"Limites par défaut",
  Over_Limit:"Au-dessus de la limite",
  Under_Limit:"Sous limite",
  Payroll_Limit:"Limite de paie",
  Enable_Bank_Account:"Activer le compte bancaire",
  Select_Account:"Sélectionner un compte",
  No_Bank_Account:"Pas de compte bancaire",
  No_Bank_Account_Word:"Ajouter le premier <a routerLink='/folder/Accounts/New'>Compte Bancaire</a>.",
  Bank_Accounts:"Comptes bancaires",
  No_Accounts:"Aucun compte",
  No_Accounts_Note:"Ajouter le premier <a routerLink='/folder/Accounts/New'>Compte Bancaire</a>.",
  Cheque_Designer:"Concepteur de chèques",
  Cheque_Design:"Vérifiez la conception",
  Select_Design:"Sélectionnez un modèle",
  Cheque_Designs:"Vérifier les dessins",
  No_Cheque_Designs:"Pas de conceptions de contrôle",
  No_Cheque_Designs_Word:"Créez votre propre <a routerLink='/folder/Settings/Cheque/Design/New'>Conception du Chèque</a>.",
  Set_Default:"Définir par défaut",
  Default:"Défaut",
  Remove:"Supprimer",
  Folder:"Dossier",
  Edit:"Éditer",
  LoadingDots:"Chargement...",
  Add_a_New_File:"Ajouter un <a href='#' (click)='add()'>Nouveau fichier</a> à",
  this_folder:"ce dossier",
  FTP_Backup_Settings:"Paramètres de sauvegarde FTP",
  Secure_File_Transfer:"Transfert de fichiers sécurisé",
  New_Holiday:"Nouvelles vacances",
  Add_Holiday:"Ajouter un jour férié",
  Holiday_Name:"Nom du jour férié",
  Additional_Pay:"Rémunération supplémentaire",
  Enable_Holiday:"Activer les vacances",
  Select_Holidays:"Sélectionnez les jours fériés",
  No_Holidays:"Pas de vacances",
  No_Holidays_Word:"Ajouter le premier <a routerLink='/folder/Holiday/New'>Férié public</a>.",
  New_Import:"Nouvelle importation",
  Import_Data:"Importer des données",
  Import_Data_Word:"Sélectionnez le type de fichier ou la méthode de téléchargement de votre choix.<br /> Vous pourrez sélectionner les champs importés dans un fichier correspondant à n'importe quel paramètre de l'application après avoir téléchargé un fichier pris en charge.", 
  Import_File:"Importer le fichier",
  Link_To_File:"Lien vers le fichier",
  Select_File:"Choisir le dossier",
  New_Moderator:"Nouveau modérateur",
  Allow_Moderation:"Autoriser la modération",
  Create_Paycheques:"Créer des chèques de paie",
  Edit_Paycheques_and_Data:"Modifier les chèques de paie et les données",
  Destroy_Data_and_Paycheques:"Détruire les données et les chèques de paie",
  Bonus_Percentage:"Pourcentage du chèque de paie",
  Fixed_Amount:"Montant fixé",
  Select_Moderator:"Sélectionner un modérateur",
  No_Moderators:"Pas de modérateurs",
  Moderators:"Modérateurs",
  Moderator_Account:"Créez le premier <a routerLink='/folder/Administrator/New'>compte modérateur</a>.",
  No_Moderators_Word:"Ajoutez le premier <a routerLink='/folder/Administrator/New'>Modérateur</a>.",
  Defaults:"Par défaut",
  Provide_Us_Info:"Fournissez-nous des informations",
  Setup_Your_Printer:"Configurer votre imprimante",
  Your_Company:"À propos de votre entreprise",
  Company_Name:"Nom de la compagnie",
  Currency:"Devise",
  Default_Currency:"Devise par défaut",
  Base_Monthly_Income:"Revenu mensuel",
  Protection:"Protection",
  App_Protection:"Protection des applications",
  PIN_Code_Protection:"Protection par code PIN",
  App_Protection_Word:"Activez les méthodes de sécurité qui aident à protéger votre compte.",
  PIN_Code:"Code PIN",
  Change_PIN:"Modifier le NIP",
  New_Password:"Nouveau mot de passe",
  Geofence_Protection:"Protection géo-clôture",
  Geofence_Area:"Définir la zone",
  Distance:"Distance",
  Setup_Now:"Configurer maintenant",
  Mile:"Mile",
  Km:"Km",
  m:"m",
  Facial_Recognition:"La reconnaissance faciale",
  Face:"Visage",
  Setup:"Installer",
  Label:"Étiqueter",
  Password_Protection:"Mot de passe de protection",
  Modify_Password:"Modifier le mot de passe",
  New_Tax_Entry:"Nouvelle entrée fiscale",
  New_Tax:"Nouvelle taxe",
  Tax_Label:"Étiquette fiscale",
  Perpetually_Enabled:"Perpétuellement activé",
  Select_Taxes:"Sélectionnez les taxes",
  Tax_Deductions:"Déductions fiscales",
  No_Tax_Deductions:"Aucune déduction fiscale",
  No_Tax_Deductions_Word:"Ajouter la première <a routerLink='/folder/Tax/New'>déduction fiscale</a>.",
  New_Timer:"Nouvelle minuterie",
  Start:"Démarrer",
  Stop:"Arrêter",
  Start_Timer:"Démarrer la minuterie",
  Stop_Timer:"Arrêter le minuteur",
  Timer_Active:"Minuterie active",
  Timer:"Minuteur:",
  Timer_Running:"Minuteur : (en cours d'exécution)",
  Save_Timer:"Enregistrer la minuterie",
  New_Timesheet:"Nouvelle feuille de temps",
  Select_Timesheets:"Sélectionner les feuilles de temps",
  Work_Notes:"Notes de travail",
  Entry_Title:"Titre d'entrée",
  No_Timesheets:"Pas de feuilles de temps",
  No_Timesheets_Word:"Ajouter la première <a routerLink='/folder/Timesheet/New'>Feuille de temps</a>.",
  Timesheet_Submitted:"Feuille de temps soumise",
  Timesheet_Congrats:"Félicitations ! Votre feuille de temps a été soumise avec succès. Merci !",
  Timesheet_Copy:"Pour recevoir une copie de vos documents, veuillez nous fournir votre adresse e-mail et/ou votre numéro de téléphone portable.",
  Submit:"Soumettre",
  Allow_Notifications:"Autoriser les notifications",
  Untitled_Entry:"Nouvelle entrée",
  Untitled_Bank:"Banque sans titre",
  Timesheet_Entry:"Saisie des feuilles de temps",
  Work_Description:"Description du travail",
  Enable_Timesheet:"Activer la feuille de temps",
  Submit_Timesheet:"Soumettre la feuille de temps",
  Vacation:"Vacances",
  New_Vacation:"Nouvelles vacances",
  Vacation_Name:"Nom de vacances",
  Paid_Vacation:"Congés payés",
  Vacation_Pay:"Paiement de vacances",
  Enable_Vacation:"Activer les vacances",
  Select_Vacations:"Sélectionner vacances",
  No_Vacations:"Pas de vacances",
  No_Vacations_Word:"Créer la première entrée <a routerLink='/folder/Vacation/New'>Vacances</a>.",
  Payroll_Schedule:"Calendrier de la paie",
  Next_Payroll:"Prochaine paie :",
  Upcoming_Payroll:" Paie à venir ",
  No_Upcoming_Payroll:"Pas de paie à venir",
  Address_Book:"Carnet d'adresses",
  Archived_Documents:"Documents archivés",
  Dev_Mode:"Application en mode développement",
  Administrators:"Administrateurs",
  Privacy:"Vie privée",
  None:"Rien",
  Select_Signature:"Sélectionnez Signature",
  No_Signatures:"Pas de signature",
  No_Signatures_Word:"Ajouter la première <a routerLink='/folder/Signature/New'>Signature</a>.",
  Repeat_Indefinitely:"Répéter indéfiniment",
  Sign:"Signe",
  Sign_Here:"Signez ici",
  Date_Signed:"Date de signature",
  Signature_Pad:"Bloc-notes",
  Account_Holder:"Titulaire de compte",
  Account_Properties:"Propriétés du compte",
  Name_On_Cheque:"Nom sur le chèque",
  Server_Hostname:"Nom d'hôte / IP du serveur",
  Printers:"Imprimantes",
  No_Printers:"Pas d'imprimantes",
  Printer_Exists:'Une imprimante de ce nom existe déjà.',
  No_Printers_Word:"Ajouter la toute première <a routerLink='/folder/Printer/New'>Imprimante</a>.",
  No_Printer_Alert:"Aucune imprimante n'est définie. Voulez-vous configurer une imprimante?",
  Add_Printer:"Ajouter une imprimante",
  New_Printer:"Nouvelle imprimante",
  Printer_Hostname:"Nom d'hôte/IP de l'imprimante",
  Printer_Label:"Étiquette de l'imprimante",
  Printer_Protocol:"Protocole d'imprimante",
  Protocol:"Protocole",
  Email_Print:"E-mail",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Prise",
  Print_Job:"Travail d'impression",
  Printed:"Imprimé",
  Not_Printed:"Non imprimé",
  Print_Jobs:"Travaux d'impression",
  Print_Queue:"File d'attente d'impression",
  No_Print_Jobs:"Pas de travaux d'impression",
  No_Prints:"Créer un nouveau <a routerLink='/folder/Cheque/New'>Chèque</a> à imprimer.",
  Prints:"Estampes",
  Find_Printer:"Rechercher une imprimante",
  Find_AirPrint_Devices:"Rechercher des appareils AirPrint",
  Select_Printer:"Sélectionner une imprimante",
  System_UI:"Interface utilisateur système",
  Printer:"Imprimante",
  Status:"Statut",
  Preview:"Aperçu",
  Enable_Print_Job:"Activer le travail d'impression",
  Queue_Weight:"Poids de la file d'attente",
  Unlimited:"Illimité",
  Show_Advanced_Printer_Options:"Afficher les options avancées de l'imprimante",
  Advanced:"Avancée",
  Location:"Emplacement",
  Note:"Noter",
  Queue_Name:"Nom de la file d'attente",
  Pages_Printed_Limit:"Limite de pages imprimées",
  MultiPage_Idle_Time:"Temps d'attente multipage (s)",
  Page_Count_Limit:"Limite du nombre de pages",
  Page_Orientation:"Orientation des pages",
  Portrait:"Portrait",
  Landscape:"Paysage",
  Duplex:"Duplex",
  Double_Sided_Printing:"Double face",
  Duplex_Mode:"mode duplex",
  Duplex_Short:"Court",
  Duplex_Long:"Long",
  Duplex_None:"Rien",
  Color_And_Quality:"Couleur Et Qualité",
  Monochrome:"Monochrome",
  Photo_Quality_Prints:"Impressions de qualité photo",
  Printer_Email:"E-mail de l'imprimante",
  Automatically_Downsize:"Réduire automatiquement la taille",
  Paper:"Papier",
  Paper_Name:"Nom du papier",
  Paper_Width:"Largeur du papier",
  Paper_Height:"Hauteur du papier",
  Paper_Autocut_Length:"Longueur de coupe automatique du papier",
  Margins:"Marges",
  Page_Margins:"Marges de pages",
  Page_Margin_Top:"Marge du haut de la page",
  Page_Margin_Right:"Marge droite de la page",
  Page_Margin_Bottom:"Marge bas de page",
  Page_Margin_Left:"Marge de page gauche",
  Add_Employees:"Employés Ajouter",
  Header:"Entête",
  Print_A_Page_Header:"Imprimer un en-tête de page",
  Header_Label:"Libellé d'en-tête",
  Header_Page_Index:"Index de la page d'en-tête",
  Header_Font_Name:"Police d'en-tête",
  Select_Font:"Sélectionner la police",
  Font_Selector:"Sélecteur de polices",
  Header_Font_Size:"Police d'en-tête",
  Header_Bold:"En-tête Bold",
  Header_Italic:"En-tête italique",
  Header_Alignment:"Alignement d'en-tête",
  Left:"À gauche",
  Center:"Centre",
  Right:"À droite",
  Justified:"Justifié",
  Header_Font_Color:"En-tête Couleur",
  Select_Color:"Sélectionnez la couleur",
  Footer:"Bas de page",
  Print_A_Page_Footer:"Imprimer un pied de page",
  Footer_Label:"Libellé de pied de page",
  Footer_Page_Index:"Pied de page Index",
  Footer_Font_Name:"Police de pied de page",
  Fonts:"Polices",
  Done:"Fait",
  Select_Fonts:"Sélectionner les polices",
  Footer_Font_Size:"Taille du pied de page",
  Footer_Bold:"Pied de page en gras",
  Footer_Italic:"Pied de page en italique",
  Footer_Alignment:"Alignement du pied de page",
  Footer_Font_Color:"Couleur du pied de page",
  Page_Copies:"Copies de pages",
  Enable_Printer:"Activer l'imprimante",
  Remote_Logging:"Enregistrement à distance",
  Log_Server:"Serveur de journaux",
  Encryption:"Chiffrement",
  Random_Key:"Clé aléatoire",
  Encryption_Key:"Clé de cryptage",
  Cheques_Webserver:"Base de données personnalisée",
  Learn_How:"Apprendre",
  Signature:"Signature",
  Default_Printer_Unit:"po/cm/mm/(pt)",
  View_Signature:"Afficher les signatures",
  Printed_Signature:"Signature imprimée",
  Digitally_Sign:"Signer numériquement",
  Digital_Signature:"Signature numérique",
  Add_Signature:"Ajouter une signature",
  Add_Your_Signature:"Ajoutez votre signature",
  Enable_Signature:"Activer la signature",
  Digitally_Signed:"Signé numériquement",
  Insert_Error:"Impossible d'enregistrer la vérification en raison de problèmes de base de données.",
  Delete_Confirmation:"Êtes-vous sûr de vouloir supprimer ces informations?",
  Discard_Confirmation:"Êtes-vous sûr de vouloir supprimer ces informations?",
  Discard_Bank_Confirmation:"Êtes-vous sûr de vouloir supprimer ce compte?",
  Discard_Printer_Confirmation:"Êtes-vous sûr de vouloir jeter cette imprimante?",
  Delete_Bonus_Confirmation:"Êtes-vous sûr de vouloir supprimer ce bonus?",
  Delete_Invoice_Confirmation:"Êtes-vous sûr de vouloir supprimer cette facture?",
  Generated_Cheque:"Chèque généré",
  Generated_Invoice:"Facture générée",
  Schedule_Start_Time:"Début de l'horaire",
  Schedule_End_Time:"Fin du programme",
  New_Call:"Nouvel appel",
  No_Contacts:"Aucun contact",
  No_Contacts_Word:"Les administrateurs, les modérateurs, les employés et les bénéficiaires apparaissent comme des contacts.",
  PDF_Subject:"finances",
  PDF_Keywords:"fiche de paie chèque de paie PDF chèque chèques",
  Printer_Setup:"Configuration de l'imprimante",
  Printer_Selection:"Sélection de l'imprimante",
  New_Fax:"Nouveau fax",
  New_Fax_Message:"Nouveau message de télécopie",
  Fax_Machine:"Télécopieur",
  Fax_Name:"Nom de fax",
  Fax_Email:"Fax E-mail",
  Fax_Number:"Numéro de fax",
  Contents:"Contenu",
  Fax_Body:"Page Body",
  Header_Word:"Entête:",
  Header_Text:"En-tête",
  Include_Header:"Inclure l'en-tête",
  Include_Footer:"Inclure le pied de page",
  Footer_Word:"Bas de page:",
  Footer_Text:"Texte de pied de page",
  Attach_a_Cheque:"Joindre un chèque",
  Add_Deduction:"Ajouter une déduction",
  Enable_Fax:"Envoyer une télécopie",
  Select_Fax:"Sélectionner télécopie",
  No_Faxes:"Pas de fax",
  Faxes:"Fax",
  Save_and_Send:"Envoyer une télécopie",
  Save_and_Pay:"Économisez et payez",
  WARNING:"AVERTISSEMENT:",
  Remember:"Rappelles toi",
  Printing:"Impression",
  Printing_Complete:"Impression terminée !\n\n",
  of:"de",
  There_Were:"Il y avait ",
  Successful_Prints:" tirages réussis et ",
  Unsuccessful_Prints:"Impressions infructueuses.",
  PrinterError:"Désolé, il y a eu une erreur.",
  Printing_:"Impression...",
  PrinterSuccess:"Document imprimé avec succès.",
  PrintersSuccess:"Documents imprimés avec succès.",
  Print_Cheques:"Imprimer les chèques",
  New_Message:"Nouveau message",
  New_Messages:"Nouveaux messages",
  Read_Message:"Lire le message",
  Write_Message:"Écrire un message",
  Send_Message:"Envoyer le message",
  Subject:"Sujet",
  Message:"Message",
  Writing:"L'écriture...",
  Send:"Envoyer",
  Set_Date:"Régler la date",
  Set_Time:"Régler le temps",
  Recieve:"Recevoir",
  Set_as_Default:"Définir par défaut",
  Default_Account:"Compte par défaut",
  Default_Design:"Conception par défaut",
  Multiple_Cheques:"Chèques (Triple)",
  Account_Mode:"Mode compte",
  Multiple_Cheques_Description:"Trois chèques par page.",
  Check_and_Table:"Check & Table",
  Check_including_Table:"Chèque & tableau comptable.",
  Check_Mailer:"Vérifiez l'expéditeur",
  Check_Mailer_Window:"Vérifier avec une fenêtre d'adresse.",
  DocuGard_Table_Top:"DocuGard Chèque & Tableau (Haut)",
  DocuGard_Table_Middle:"DocuGard Chèque & Tableau (Milieu)",
  DocuGard_Table_Bottom:"DocuGard Chèque & Tableau (Bas)",
  DocuGard_Mailer_Top:"DocuGard Chèque Postal (Haut)",
  DocuGard_Mailer_Middle:"DocuGard Chèque Postal (Milieu)",
  DocuGard_Mailer_Bottom:"DocuGard Chèque Postal (Bas)",
  DocuGard_Three_Cheques:"Vérifications DocuGard (Triple)",
  DocuGard_Cheque_Top:"DocuGard Check (Haut)",
  DocuGard_Cheque_Middle:"DocuGard Check (Milieu)",
  DocuGard_Cheque_Bottom:"Vérification DocuGard (en bas)",
  DocuGard_Three_Cheques_Window:"Trois chèques sur une page.",
  DocuGard_Table_Top_Window:"Vérification et tableau des gains.",
  DocuGard_Table_Middle_Window:"Vérification et tableau des gains.",
  DocuGard_Table_Bottom_Window:"Vérification et tableau des gains.",
  DocuGard_Mailer_Top_Window:"Chèque, table et adresse.",
  DocuGard_Mailer_Middle_Window:"Chèque, table et adresse.",
  DocuGard_Mailer_Bottom_Window:"Chèque, table et adresse.",
  DocuGard_Cheque_Top_Window:"Vérifier le papier sécurisé.",
  DocuGard_Cheque_Middle_Window:"Vérifier le papier sécurisé.",
  DocuGard_Cheque_Bottom_Window:"Vérifier le papier sécurisé.",
  Basic_Cheque:"Vérifier (Haut)",
  Basic_Cheque_Print:"Imprimez un seul chèque.",
  Error_Setting_As_Paid:"Erreur de définition comme payé",
  Add_Attachment:"Ajouter une pièce jointe",
  PrinterUnavailable:"Imprimante indisponible",
  CreditCardComponent:"Cartes",
  PaypalComponent:"Pay Pal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Nouveau dépôt",
  Deposits:"Dépôts",
  No_Deposits:"Aucun dépôt",
  Credit_Card_Deposit:"Carte de crédit",
  New_Credit_Card_Deposit_Message:"Dépôt par carte de crédit",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Dépôt BitCoin",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Transfert Interac",
  Payments_Limit:"Limite de paiement",
  No_Payment_Limit:"Aucune limite de paiement",
  PayPal_Deposit:"Pay Pal",
  PayPal_Deposit_Message:"Dépôt Paypal",
  No_Deposits_Word:"Ajouter la première société <a routerLink='/folder/Deposit/New'>Dépôt</a>.",
  No_Documents_Specified:"Aucun document n'a été spécifié pour l'impression",
  No_Printers_Added:"Aucune imprimante n'a été trouvée. Accédez à Paramètres > Imprimantes pour en ajouter une.",
  DB_Erase_Prompt:"Effacer complètement l'intégralité de la base de données? ATTENTION : Vous perdrez toutes les informations stockées !",
  Console_Warning:"Ne collez aucun texte dans cette console. Vous pourriez vous mettre en danger, vous et/ou votre entreprise.",
  No_Faxes_Word:"Créer le premier <a routerLink='/folder/Fax/New'>Fax</a>.",
  Cheque_Delete_Confirmation:"Êtes-vous sûr de vouloir supprimer ce chèque?",
  Design_Delete_Confirmation:"Êtes-vous sûr de vouloir supprimer ce design?",
  Cheque_Pay_Confirmation:"Marquer ce chèque comme payé? Il n'apparaîtra PAS dans la file d'attente d'impression.",
  Payment_Pay_Confirmation:"Marquer ce paiement comme payé? Il n'apparaîtra PAS dans la file d'attente des chèques.",
  Delete_Deduction_Confirmation:"Êtes-vous sûr de vouloir supprimer cette déduction?",
  Delete_Job_Confirmation:"Êtes-vous sûr de vouloir supprimer ce travail?",
  Delete_Timesheet_Confirmation:"Êtes-vous sûr de vouloir supprimer cette feuille de temps?",
  Delete_Schedule_Confirmation:"Êtes-vous sûr de vouloir supprimer ce programme?",
  Delete_Setting_Confirmation:"Êtes-vous sûr de vouloir réinitialiser ce paramètre?",
  Delete_Fax_Confirmation:"Êtes-vous sûr de vouloir supprimer ce fax?",
  Delete_File_Confirmation:"Êtes-vous sûr de vouloir supprimer ce fichier?",
  Delete_Vacation_Confirmation:"Êtes-vous sûr de vouloir supprimer ces vacances?",
  Delete_Printer_Confirmation:"Êtes-vous sûr de vouloir supprimer cette imprimante?",
  Remove_Design_Confirmation:"Êtes-vous sûr de vouloir supprimer ce design?",
  Delete_Payroll_Confirmation:"Etes-vous sûr de vouloir supprimer cette paie?",
  Send_Fax_Email_Confirmation:"Voulez-vous faxer et envoyer ces documents par e-mail?",
  Send_Email_Confirmation:"Voulez-vous envoyer ce document par e-mail?",
  Send_Fax_Confirmation:"Voulez-vous faxer ce document?",
  Error_Generating_PDF:"Désolé. Une erreur s'est produite lors de la génération de ce document.",
  PDF_Error_Saving_Image:"Désolé. Une erreur s'est produite lors de l'enregistrement de l'image PDF de ce document.",
  Test_Printer_Confirmation:"Voulez-vous imprimer une page de test sur cette imprimante?",
  Save_Timesheet_Prompt:"Veuillez ajouter un 'Titre' ou appuyer sur 'Démarrer le minuteur' pour enregistrer.",
  Remove_Geofence_Prompt:"Êtes-vous sûr de vouloir supprimer l'emplacement de cette barrière géographique?",
  Delete_Employee_Confirmation:"Etes-vous sûr que vous voulez supprimer ",
  Fire_Employee_Confirmation:"Etes-vous sûr de vouloir tirer"
}