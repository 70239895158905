export const Li = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Copyright & copy; 2023",
  black:"Nigreos",
  green:"Viridis",
  gold:"Aurum",
  blue:"Caeruleum",
  brown:"Brown",
  purple:"Purpura",
  pink:"Pink",
  red:"Rubrum",
  Swatches:"Swatches",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Hue",
  Saturation:"Saturatio",
  Lightness:"levitas",
  Upgrade_To_Pro:"Phasellus ut Pro",
  AllFeaturesInclude:"Omnes Features Include:",
  PrintUnlimitedCheques:"Print ft SCUTULATUM",
  PremiumChequeDesigns:"Premium Check Designs",
  ManageUnlimitedEmployees:"Infinitus administrare Employees",
  AddUnlimitedPayees:"Add ft payees",
  CreateUnlimitedPayrolls:"Create Infinitus Payrolls",
  UnlimitedSchedulesandTimeSheets:"Infinitus cedulas et tempus rudentis",
  BulkPayPalPayouts:"Mole PayPal Payouts",
  UnlimitedBankAccounts:"Infinitus Bank Rationes",
  ManageMultipleCompanies:"Multiple Companies Curo",
  TrackInsurancePolicies:"Inuestigandum Insurance Politiae",
  Bio_MetricProtection:"Bio-Metric Protection",
  Geo_FenceLock_OutProtection:"Geo-Fence Lock-Out Praesidium",
  Multiple_Companies_Word:"Multas societates administrandi sine scutulis premium praesto non est.",
  PayPal_Payouts_Word:"PayPal solutiones debiles sine SCUTULATUM premium.",
  PINProtection:"ACUS Praesidium",
  PasswordProtection:"Password Praesidium",
  May_Require_Approval:"Sit eget ligula.",
  Subscribe:"Subscribe",
  Billed:"Occisus",
  Up:"Up",
  Down:"Descende",
  Positioning:"Positioning",
  Marker:"Venalicium",
  Drag_Marker:"Drag Marker",
  Tagline:"Print SCUTULATUM ac Tabulatum Payroll",
  Marker_Word:"Morbi ut elementum tortor.",
  Pinch_Zoom:"Prement Zoom",
  Pinch_Word:"Tern to zoom the elementum.",
  Drag:"Drag",
  Drag_Word:"Digito tuo uteris ad elementa trahe.",
  subscription_alias_word:"Auto-renovatio Subscription",
  premium_alias_word:"Unum tempus upgrade Package",
  print_alias_word:"Print-Ex singulis SCUTULATUM",
  mode_alias_word:"Modus",
  Pro:"Pro",
  Pro_word:"Pro versione desideratur.",
  Cant_Delete_Default_Company_Word:"Ignosce, sis: default societatem tuam delere non potes.",
  Reinsert_Default_Designs:"Re- Insert Default Designs",
  Reinsert_Default_Designs_word:"Visne re-inserere per annum consilia?",
  Subscription_Active_Disable_Word:"Subscriptio haec activa est. Visne hanc subscriptionem rescindere ad Cheques?",
  Company_Logo:"Societas Logo",
  ZERO_:"NIHIL",
  Disclaimer:"Disclaimer",
  Privacy_Policy:"Privacy policy",
  EULA:"EULA checks",
  Terms_Of_Service:"Termini servitii",
  Terms_Of_Use:"Terms of Use",
  Refunds:"Refugium Policy",
  Single_Print:"I Moderare",
  Two_Prints:"2 SCUTULATUM",
  Five_Prints:"5 SCUTULATUM",
  Ten_Prints:"X SCUTULATUM",
  Fifteen_Prints:"15 SCUTULATUM",
  Twenty_Prints:"20 SCUTULATUM",
  Thirty_Prints:"XXX SCUTULATUM",
  Image_Added:"Imago Added",
  Image_Preview:"Imago Praevius",
  No_Image_Was_Selected:"Nullam at mauris lectus.",
  Cheques_Unlimited:"checks ft",
  _Subscription:"Subscription",
  Subscription:"SCUTULATUM - 1 Mensis",
  Two_Month_Subscription:"SCUTULATUM - 2 Menses",
  Three_Month_Subscription:"SCUTULATUM - III mensibus",
  Six_Month_Subscription:"SCUTULATUM - VI Menses",
  Twelve_Month_Subscription:"SCUTULATUM - XII Menses",
  Cheques_Are_Available:"SCUTULATUM praesto sunt ad imprimendum.",
  Upgrade_Required_Two:"Sarcinam eligere et duplex sonum in pretio puga pyga ut satus emptionis tuae. Print professionalem vultus plenus-color compescit in secundis.",
  Month:"Mensis",
  Due:"Ob:",
  Expires:"Exspirat:",
  Subscription_Active:"Subscription Active",
  Subscription_Inactive:"Subscription vestibulum",
  Purchase_Additional_Cheques:"Additional emo checks?",
  Printable_Cheque:"Forma Moderare",
  Printable_Cheques:"Forma SCUTULATUM",
  Printable_Cheque_Word:"reprehendo is available in rationem vestram.",
  Printable_Cheques_Word:"checks sunt available in ratione vestra.",
  Max_Amount_Message:"Quantum tibi certum est, perventum est ad maximam quantitatem huius systematis appositae:",
  Terms_Required_Word:"Huic conventioni assentire debes antequam Cheques utaris.",
  Subscriptions:"Subscriptiones",
  Product_Upgrades:"Upgrades",
  Mailed_Out_Cheques:"CATAPHRACTUS-E SCUTULATUM",
  Enter_A_Company_Name:"Please enter a company name.",
  Single_Cheques:"unum SCUTULATUM",
  Cheque_Golden:"Aureum Check",
  Cheque_Golden_Window:"Aurea perscriptio consilio.",
  Cheque_Green:"Green Moderare",
  Cheque_Green_Window:"Green reprehendo consilio.",
  Cheque_Red:"Red Moderare",
  Cheque_Red_Window:"Red reprehendo consilio.",
  Cheque_Yellow:"Yellow Moderare",
  Cheque_Yellow_Window:"Flavo reprehendo consilio.",
  Cheque_Statue_of_Liberty:"Stauta libertatis",
  Cheque_Statue_of_Liberty_Window:"Statua Libertatis perscriptio designatur.",
  Cheque_Green_Wave:"Viridis unda",
  Cheque_Green_Wave_Window:"Green reprehendo consilio.",
  Cheque_Golden_Weave:"Aureum texere",
  Cheque_Golden_Weave_Window:"Elegans au- ceptum consilium.",
  Cheque_Green_Sun:"Sol viridis",
  Cheque_Green_Sun_Window:"Altum et sedatum represserat consilium.",
  Cheque_Blue_Checkers:"Blue Checkers",
  Cheque_Blue_Checkers_Window:"Blue reprehendo consilio.",
  Cashiers_Check:"CONLECTARIUS scriptor Moderare",
  Cashiers_Check_Window:"CONLECTARIUS perscriptio style templates.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Aqua reprehendo consilio.",
  Cheque_Golden_Checkers:"Aureum Checkers",
  Cheque_Golden_Checkers_Window:"Aurea perscriptio consilio.",
  Upgrade_Unavailable:"Upgrades Unavailable",
  Bank_Code_Protection:"Bank Number Praesidium",
  Bank_Code_Protection_Word:"Numeri ripam tuam protege ne in hac app currentem ad alium fabricam vel ad alium usum adhibeantur. Haec actio irrevocabilis est. Perge?",
  Bank_Code_Protection_Blocked_Word:"Numeri argentarii quos uti conaris reservantur alteri usuario vel artificio.",
  Bank_Code_Protection_Error_Word:"Numerus verificationis defecit. Quaeso coniungere ad interrete et hanc ripam rationem iterum experiri addendo.",
  Bank_Code_Protection_Set_Error_Word:"Argentaria numerus praesidium postulat ut cum interrete coniungaris. Quaeso coniungere et iterum conare.",
  Upgrade_Unavailable_Word:"Ignosce, sis molestus probari tibi. Subscriptiones et upgrades ad SCUTULATUM es currently perpendat pro emptione in area tua.",
  PayPal_Payment_Preview:"Payment Praevius PayPal",
  Apple_Pay:"Lacus Esto",
  Select_PayPal:"Lego PayPal",
  PayPal_Applications:"PayPal Applications",
  Purchase_With_Apple_Pay:"Purchase cum Apple Esto",
  Google_Pay:"Google Reddere",
  Companies:"Societates",
  Insurance:"Cautionum",
  New_PayPal:"Novum PayPal",
  Pay_By:"Per stipendium",
  Insure:"praecavendam",
  Miles:"Miles",
  Payment_Method:"Solucionis methodo",
  Select_Policies:"Select Politiae",
  Policies:"Politiae",
  Policy:"Policy",
  No_PayPal_Account:"Non PayPal Ratio",
  No_Policies:"Nulla Insurance Politiae",
  New_Policy:"Novum Policy",
  PayPal_Payment:"Payment PayPal",
  PayPal_Payments:"PayPal liberate",
  No_Payment_Selected:"Non Payment Selectae",
  Sending_Payment_Word:"Obsecro, exspecta... Haec solutio mittitur.",
  Sending_Payments_Word:"Please wait... Payment are being sent.",
  No_Payment_Selected_PayPal:"Non <a routerLink='/folder/payments'>Praestatio PayPal</a> electo mittere.",
  Payment_Sent:"Payment Sent",
  PayPal_Payment_Sent:"Haec solutio missum est cum PayPal.",
  Copay:"Copay",
  Dead:"Mortuus",
  Alive:"Vivus",
  Not_Dead:"Non Mortuus",
  Unclaimed:"vacantia",
  Attempted:"conatus",
  Deceased:"defuncti",
  Claimed:"petita",
  Unpaid:"insolutis",
  Sending_Payment:"Mittens Payment",
  Sending_Payments:"Mittens liberate",
  Send_PayPal_Confirmation:"Visne hanc rem cum PayPal mittere?",
  Send_PayPal_Confirmation_Word:"Preme viridem conjunctionem ut mittat hanc rem.",
  Save_Payment_As_Unpaid:"Servo hanc mercedem quasi insolutis?",
  Payment_Pay_Confirmation_PayPal:"Servo hanc mercedem quam solvit?",
  No_Policies_Word:"primum <a routerLink='/folder/Postage/New'>Cautionum Policy</a> adde .",
  Timesheet_Multiple_Delete_Confirmation:"Certus esne vis multas timesheets delere?",
  Select_Multiple_Timesheets_Prompt:"Nulla a tincidunt lectus. Eligere saltem unum timesheet.",
  Select_Multiple_Policies_Prompt:"Nulla lobortis lectus. Eligere saltem unum assecurationis consilium.",
  Policies_Multiple_Delete_Confirmation:"Visne vero multa consilia delere?",
  Company:"Societas",
  Add_Company:"Add Company",
  New_Company:"Add Company",
  No_Companies:"No Companies",
  Enable_Company:"Admitte Company",
  Select_Company:"Select Company",
  The_Default_Company:"Defalta societas pittacii.",
  Manage_Companies:"Curo Companies",
  No_Companies_Word:"Compescit, defaltam adhibebit societatem.<br /> Adde <a routerLink='/folder/Company/New'>primam Societatem</a>.",
  Default_Company:"Default Company",
  Cheques_Unlimited_Word:"SCUTULATUM IMMODERATUS permittit ut tot notas imprimas quantum tibi placet.",
  Cheques_Subscription_Word:"Subscriptio SCUTULATUM permittit ut tot notas imprimas quantum vis.",
  You_Own_This_Product:"Quisque id consequat turpis.",
  Your_Subscription_is_Active:"Subscriptio tua activa est.",
  Active_Products:"activated Products",
  Purchase_Confirmation:"Purchase",
  Purchase_Cheques:"Purchase SCUTULATUM",
  Restore_Purchase:"Emit restituet",
  Erase_Purchase:"Extermina emptionibus",
  Successfully_Purchased:"Feliciter Purchased",
  Enter_Your_Licence_Key:"Quaeso, licentia clavem tuam in hac pagina ad hanc paginam strenue.",
  Licence_Key:"Key licentiam",
  Enter_Licence_Key:"Licentia Key Intra",
  Purchased:"empticius",
  Enable_Feature:"Admitte Feature",
  Cancel_Subscription:"Cancel Subscription",
  Subscription_Removed:"Subscriptione remota",
  Select_Active_Subscription:"Subscriptionem activam elige ut eam mitiges.",
  Remove_Subscription_Word:"Visne vero hanc subscriptionem rescindere?",
  Delete_Company_Confirmation:"Visne vero hanc totam societatem delere? MONITUM: Omnia condita notitia perdes!",
  Delete_Default_Company_Word:"Annum societatis delere non potes . Velisne applicationem retexere ac restituere ad statum default? MONITUM: Omnia condita notitia perdes!",
  Console_Warning_2:"Noli tractare aliquem monetam utentem hac applicatione quae nunc tua non est.",
  Terms_and_Conditions:"Terms and Conditions",
  and_the:"et",
  for:"for",
  Please_Read_Word:"Lege et conveniunt ad",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Nonnullae monetae conversionis rates inveniri non potuerunt. Quaeso coniungere ad interrete.",
  Free:"Liber",
  DB_Erase_Prompt_2:"Integer dele elit database? MONITUM: Perdes omnes AUCTORO ET CONSCRIPTIONE informationes!",
  Successfully_Imported:"Feliciter Imported",
  Select_Postage:"Lego vectigalia",
  No_Postage:"Non vectigalia notat",
  No_Paid_Postage_Word:"Adde primum <a routerLink='/folder/Postage/New'>Pensi Vectigalia</a> notam.",
  Trial_Complete:'Iudicium Complete',
  Please_Upgrade:'Quaeso upgrade SCUTULATUM pergere excudendi.',
  Aa:"Aa",
  Color:"Spalva",
  Font:"Šriftas",
  Guide:"Vadovas",
  Guides:"Vadovai",
  Image:"Vaizdas",
  Zoom:"Padidinti",
  Logo:"Logotipas",
  Bank:"bankas",
  MICR:"MICR",
  Total:"Iš viso",
  Cancel:"Atšaukti",
  Confirm:"Patvirtinti",
  Method:"Metodas",
  Biometric_Security:"Biometrinis saugumas",
  Please_Login_To_Continue:"Prisijunkite, jei norite tęsti.",
  Complete:"Užbaigti",
  Sign_Up:"Registruotis",
  Error:"Klaida",
  Biometrics:"Biometrija",
  Percent:"proc",
  Zero_Percent:"0 %",
  Top_Margin:"Viršutinė marža",
  Bottom_Margin:"Apatinė marža",
  Left_Margin:"Kairė paraštė",
  Right_Margin:"Dešinė paraštė",
  MICR_Margin:"MICR marža",
  Table_Margin:"Lentelės marža",
  Address_Margin:"Adreso marža",
  Percentage_:"Procentas",
  Vacation_Title:"Atostogų pavadinimas",
  Use_PIN:"Naudokite PIN kodą",
  Loading__:"Įkeliama...",
  Design_Title:"Dizaino pavadinimas",
  Authorize:"Įgalioti",
  Key:"Raktas",
  Public_Key:"Viešasis raktas",
  Private_Key:"Privatus raktas",
  Authenticate:"Autentifikuoti",
  Last_Payroll:"Paskutinis darbo užmokestis",
  Last_Calculation:"Paskutinis skaičiavimas",
  Authorized:"Įgaliotas",
  Geo_Authorized:"Geografinė vieta: įgaliota",
  Not_Authorized:"Neįgaliotas",
  Authorization_Complete:"Autorizacija baigta",
  Geolocation_Authorization:"Geografinės vietos leidimas",
  Out_of_Bounds:"Už ribų",
  Cant_Delete_Default_Design:"Negalima ištrinti numatytojo dizaino.",
  Unauthorized_Out_of_Bounds:"Neteisėta: už ribų",
  Biometric_Authorization:"Biometrinis leidimas",
  Locating_Please_Wait:"Rasta, prašome palaukti...",
  Test_Biometrics:"Išbandyti biometriką",
  Cheque_Margins:"Patikrinkite paraštes",
  Percentage_Full_Error:"Procentų laukelis negali būti didesnis nei 100 % procentų.",
  No_Payroll_Text:"Pridėkite <a routerLink='/folder/Employee/New'>Darbuotoją</a> arba <a routerLink='/folder/Payee/New'>Mokėjimo Gavėją</a> ir <a routerLink='/folder/Schedule/New'>Tvarkaraštis</a>.",
  Design_Saved:"Dizainas išsaugotas",
  Default_Design_Selected:"Pasirinktas numatytasis dizainas",
  Partial_Import:"Dalinis importas",
  Partial_Export:"Dalinis eksportas",
  Entire_Import:"Visas importas",
  Entire_Export:"Visas eksportas",
  Existing_Password:"Įveskite esamą slaptažodį:",
  Existing_PIN:"Įveskite esamą PIN kodą:",
  Pin_Change_Header:"PIN patvirtinimas",
  Pin_Change_SubHeader:"Įveskite seną PIN kodą, kad patvirtintumėte pakeitimą.",
  Pass_Change_Header:"Slaptažodžio patvirtinimas",
  Pass_Change_SubHeader:"Įveskite seną slaptažodį, kad patvirtintumėte pakeitimą.",
  PIN_Enter_Message:"Įveskite PIN kodą, kad patvirtintumėte.",
  Password_Enter_Message:"Įveskite slaptažodį, kad patvirtintumėte.",
  Pin_Updated_Success:"PIN sėkmingai atnaujintas!",
  Pin_Updated_Fail:"Nepavyko atnaujinti PIN.",
  Pass_Updated_Success:"Slaptažodis sėkmingai atnaujintas.",
  Pass_Updated_Fail:"Nepavyko atnaujinti slaptažodžio.",
  Preview_Payment:"Mokėjimo peržiūra",
  Preview_Payroll:"Peržiūrėti darbo užmokestį",
  No_Payments_Created:"Nerasta jokių sukurtų mokėjimų.",
  Payment_Preview:"Mokėjimo peržiūra",
  Enable_Payee:"Įgalinti gavėją",
  Rendered:"Pateikta",
  No_Email:"Nėra el. pašto",
  Setup_Cheques:"Sąrankos patikrinimai",
  name:"vardas",
  address:"Adresas",
  address_2:"Adreso eilutė 2",
  city:"Miestas",
  province:"Provincija",
  postal_code:"Pašto kodas",
  country:"Šalis",
  username:"Vartotojo vardas",
  full_name:"Pilnas vardas",
  birthday:"Gimtadienis",
  email:"El. paštas",
  phone:"Telefonas",
  employees:"Darbuotojai",
  addresses:"Adresai",
  payment_amount_limit:"Mokėjimo sumos limitas",
  total_limit:"Bendras limitas",
  payees:"Gavėjai",
  description:"apibūdinimas",
  address_line_one:"Pirmoji adreso eilutė",
  address_line_two:"Antra adreso eilutė",
  image:"Vaizdas",
  account_holder:"Sąskaitos savininkas",
  cheque_starting_id:"Patikrinkite pradžios ID",
  transit_number:"Tranzito numeris",
  institution_number:"Įstaigos numeris",
  designation_number:"Paskyrimo numeris",
  account_number:"Paskyros numeris",
  currency:"Valiuta",
  signature:"Parašas",
  payment_payroll_limit:"Mokėjimo limitas",
  total_limi:"Bendras limitas",
  date:"Data",
  printed_memo:"Išspausdinta atmintinė",
  banks:"Bankai",
  signature_image:"Parašo vaizdas",
  address_name:"Adresas",
  notes:"Pastabos",
  design:"Dizainas",
  title:"Pavadinimas",
  frequency:"Dažnis",
  fax:"Faksas",
  salaries:"Atlyginimai",
  salary_ids:"Atlyginimo ID",
  start_time:"Pradžios laikas",
  end_time:"Pabaigos laikas",
  paid:"Mokama",
  overtime_percentage:"Mokamas procentas",
  overtime_amount:"Sumokėta fiksuota suma",
  first_name:"Pirmas vardas",
  last_name:"Pavardė",
  moderation:"Saikingumas",
  create:"Sukurti",
  edit:"Redaguoti",
  destroy:"Sunaikink",
  day_start_time:"Dienos_pradžios_laikas",
  day_end_time:"Dienos_pabaigos laikas",
  fullname:"vardas",
  time:"Laikas",
  auto_send:"Automatiškai siųsti",
  time_method:"Laiko metodas",
  schedules:"Tvarkaraščiai",
  indefinite_payroll_enabled:"Įgalinti neribotą laiką",
  amount:"Suma",
  repeat:"Pakartokite",
  always_enabled:"Visada įjungta",
  start_date:"Pradžios data",
  end_date:"Pabaigos data",
  Cheque_Total:"Patikrinkite iš viso",
  Total_Amount:"Visas kiekis:",
  Amounts:"Sumos:",
  Images:"Vaizdai",
  Files:"Failai",
  Previewing:"Peržiūra:",
  Insert:"Įdėti",
  Preview_Import:"Preview Import",
  Entry:"Įėjimas",
  Entries:"Įrašai",
  No_Entries:"Nėra įrašų",
  Import_Type:"Importo tipas",
  Select_Details:"Pasirinkite Išsami informacija",
  Select_Payee:"Pasirinkite Gavėjas",
  Enable_Holidays:"Įgalinti šventes",
  Disable_Holidays:"Išjungti šventes",
  Wire_Transfer:"Pavedimas",
  New_Export:"Naujas eksportas",
  Export_Data:"Eksportuoti duomenis",
  Export_Data_Word:"Pasirinkite failo tipą, kurį norite eksportuoti ir atsisiųsti.",
  Export_File:"Eksportuoti failą",
  Mode:"Režimas",
  Times:"Laikai",
  Widgets:"Valdikliai",
  Slider:"Slankiklis",
  Set_Details:"Nustatyti išsamią informaciją",
  Select_Type:"Pasirinkite Tipas",
  Display_Slider:"Ekrano slankiklis",
  Dashboard_Slider:"Prietaisų skydelio slankiklis",
  Dashboard_Mode:"Prietaisų skydelio režimas",
  Show_Intro:"Rodyti įžangą",
  Show_Payrolls:"Rodyti darbo užmokestį",
  Show_Holidays:"Rodyti šventes",
  Show_Invoices:"Rodyti sąskaitas faktūras",
  Show_Cheques:"Rodyti čekius",
  Enabled_Widgets:"Įjungti valdikliai",
  Disabled_Widgets:"Išjungti valdikliai",
  Colors:"Spalvos",
  Barcodes:"Brūkšniniai kodai",
  View_Timers:"Žiūrėti laikmačius",
  Gradients:"Gradientai",
  No_Info:"Nėra informacijos",
  Disable:"Išjungti",
  Show_Layer:"Rodyti sluoksnius",
  Hide_Layer:"Slėpti sluoksnius",
  Text_Layer:"Teksto sluoksniai",
  Secondly:"Antra",
  Minutely:"Minutėmis",
  nine_am:"9:00 RYTO",
  five_pm:"5:00 VAKARO",
  Enable_Address:"Įgalinti adresą",
  Invalid_File_Type:"Atsiprašome, pasirinktas netinkamas failo tipas. Palaikomas failo tipas:",
  Error_Updating_Entry:"Atsiprašome, atnaujinant šį įrašą įvyko klaida.",
  Schedule_Timing_Alert:"Klaida: tvarkaraščio pradžios laikas nustatytas į vertę po pabaigos laiko.",
  Select_Multiple_Payments_Prompt:"Nepasirinkta jokių mokėjimų. Pasirinkite bent vieną mokėjimą.",
  Select_Multiple_Cheques_Prompt:"Nepasirinkta jokių čekių. Pasirinkite bent vieną čekį.",
  Select_Multiple_Items_Prompt:"Nepasirinkta jokių elementų. Pasirinkite bent vieną elementą.",
  Paymemt_Multiple_Delete_Confirmation:"Ar tikrai norite ištrinti kelis mokėjimus?",
  Cheque_Multiple_Delete_Confirmation:"Ar tikrai norite ištrinti kelis čekius?",
  Payee_Multiple_Delete_Confirmation:"Ar tikrai norite ištrinti kelis mokėjimo gavėjus?",
  Employee_Multiple_Delete_Confirmation:"Ar tikrai norite ištrinti kelis darbuotojus?",
  MICR_Warning:"Pastaba: Kai kurie spausdintuvai ir įrenginiai gali netinkamai rodyti MICR šriftą.",
  Automatically_Send:"Automatiškai siųsti",
  Type:"Tipas",
  Payment_Type:"Mokėjimo tipas",
  Auto_Send:"Automatinis siuntimas",
  Automatically_Process:"Automatiškai apdoroti",
  Auto_Process:"Automatinis procesas",
  Image_Based:"Vaizdo pagrindu",
  Font_Based:"Šrifto pagrindu",
  Rerender:"Iš naujo pateikti",
  Rendering:"Atvaizdavimas",
  Render:"Failai",
  Top:"Į viršų",
  Middle:"Vidurio",
  Bottom:"Apačia",
  Top_Left:"Viršuje kairėje",
  Top_Center:"Viršutinis centras",
  Top_Right:"Viršutinis dešinysis",
  Middle_Left:"Vidurio kairėje",
  Middle_Center:"Vidurinis centras",
  Middle_Right:"Vidurinė dešinė",
  Bottom_Left:"Apačioje kairėje",
  Bottom_Center:"Apatinis centras",
  Bottom_Right:"Apačioje dešinėje",
  Numbers:"Skaičiai",
  Verified:"Patvirtinta",
  Paper_Size:"Popieriaus dydis",
  New_Device:"Naujas Įrenginys",
  Add_Device:"Pridėti įrenginį",
  Remove_Device:"Pašalinti įrenginį",
  Delete_Device:"Ištrinti įrenginį",
  Block_Device:"Blokuoti įrenginį",
  Block:"Blokuoti",
  Unblock:"Atblokuoti",
  Table:"Lentelė",
  Scan_Login_Code:"Nuskaityti prisijungimo kodą",
  Login_Code:"Prisijungimo kodas",
  Scan_Code:"Nuskaityti kodą",
  Scan_QR_Code:"Nuskaityti QR kodą",
  Select_All:"Pasirinkti viską",
  Deselect_All:"Atzymeti visus pasirinkimus",
  Increase:"Padidinti",
  Decrease:"Mažinti",
  Bold:"Paryškintas",
  Text:"Tekstas",
  Style:"Stilius",
  Italic:"Kursyvas",
  QR_Code:"QR kodas",
  Barcode:"Brūkšninis kodas",
  Browse_Images:"Naršyti vaizdus",
  Browse_Files:"Naršyti failus",
  Background_Image:"Fono vaizdas",
  Logo_Image:"Logotipo vaizdas",
  Header_Image:"Antraštės vaizdas",
  Bank_Image:"Banko vaizdas",
  Cut_Lines:"Iškirptos linijos",
  Background:"Fonas",
  License:"Licencija",
  One_License:"1 licencija:",
  Licensed:"Licencijuota:",
  Not_Licensed:"Nelicencijuota",
  Enter_Serial:"Įveskite seriją",
  Serial_Key:"Serijos raktas",
  Serial:"Serijinis",
  Product_Key:"Produkto raktas",
  Check_Product_Key:"Patikrinkite produkto kodą",
  Add_Product_Key:"Pridėti produkto kodą",
  Verifying_Purchase:"Tikrinamas pirkinys...",
  Print_Envelope:"Spausdinti voką",
  Envelope:"Vokas",
  Thank_You:"Ačiū!",
  Scale:"Skalė",
  Print_Scale:"Spausdinimo mastelis",
  Borders:"Sienos",
  VOID:"TUŠTUMA",
  Void_Cheque:"Negaliojantis patikrinimas",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"MOKĖKITE UŽSAKYMĄ:",
  NO_DOLLARS:"JOKIŲ DOLERIŲ ",
  ONE_DOLLAR:"VIENAS DOLERIS",
  DOLLARS:" DOLERIAI",
  AND:" IR ",
  CENTS:" CENTŲ.",
  NO_:"NE ",
  ONE_:"VIENA ",
  AND_NO_:"IR NE ",
  _AND_ONE_:"IR VIENAS ",
  DOLLARS_AND_ONE_CENT:" IR VIENAS CENTAS.",
  AND_NO_CENTS:" IR NULIS CENTŲ.",
  CHEQUE_PRINT_DATE:"DATA:",
  CHEQUE_PRINT_MEMO:"PASTABA:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Pradinės užduotys",
  Inital_Setup:"Pradinė sąranka",
  Welcome_To:"Sveiki atvykę į ",
  Welcome:"Sveiki",
  Swipe:"Braukti",
  Intro_Setup:"Įvadinė sąranka",
  Introduction:"Įvadas",
  CHEQUE_PRINT_CREDIT:"Pagalba čekiais",
  Title_Intro_Word:"Sveiki atvykę į Čekius",
  Title_Intro:"Čekių įvadas",
  Title_Setup:"Čekių nustatymas",
  PayPal_Default_Email_Message:"Gavote naują PayPal pervedimą. [Pagalba čekiais]",
  Cheques_App_Export:"Eksportuota čekiais",
  Post_Intro_Thanks:"Dėkojame, kad pasirinkote Čekius. Sąrankos procesas baigtas.",
  Post_Intro_Word:"Pradėkite atsispausdindami pirmąjį čekį, pridėkite būsimą mokėjimą arba įtraukite darbuotoją į darbo užmokesčio sąrašą",
  Upgrade_Required:"Čekiams reikia turėti geresnę programinės įrangos versiją, kad galėtumėte paslėpti šį pranešimą ir atrakinti papildomas funkcijas.",
  Upgrade_Title:"Čekiai",
  Mailout_Prompt:"Galite papildomai pasirinkti, kad Čekiai išsiųstų jūsų darbo užmokesčio čekius už jus",
  Mailed_Cheque:"Paštu išsiųstas čekis: ",
  Mailed_Cheque_Color:"Paštu išsiųstas čekis (spalva): ",
  Terms_Purchase:"Visi elektroniniai pirkiniai su čekiais yra visiškai grąžinami iki 30 dienų nuo pirkimo datos. Prieš tęsdami perskaitykite ir sutikite su <a href='#'>taisyklėmis ir nuostatomis</a>.",
  Dashboard_Setup:"Nustatyti pagrindinį spausdintuvą",
  Dashboard_Add:"Pridėti pagrindinę banko sąskaitą",
  Dashboard_Customize:"Pasirinkite patikrinimo šabloną",
  Dashboard_Job_Salary:"Sukurkite savo darbą ir pridėkite atlyginimą",
  Dashboard_Employees:"Sekti darbuotojus ir gavėjus",
  Dashboard_Print:"Atspausdinti ir išsiųsti savo darbo užmokesčio čekius",
  Dashboard_Payroll:"Automatizuokite savo darbo užmokesčio spausdinimą",
  Dashboard_PayPal:"Nustatyti PayPal darbo užmokestį / išmokėjimus",
  Begin_Setup:"Pradėti sąranką",
  Get_Started:"Pradėti",
  Purchase:"Pirkimas",
  Lockdown:"Izoliacija",
  Unlock:"Atrakinti",
  Detailed:"Detalus",
  Log_In:"Prisijungti",
  Login:"Prisijungti",
  Launch:"Paleisti",
  Register:"Registruotis",
  Finish:"Baigti",
  List:"Sąrašas",
  Weekends:"Savaitgaliai",
  Weekly:"Savaitė",
  PayPal_Default_Subject:"Naujas mokėjimas",
  Payment_Message:"Mokėjimo žinutė",
  PayPal_Default_Payment_Note:"Ačiū",
  Setup_Your_Cheqing_Account:"Eitinė sąskaita",
  Add_Your_Primary_Cheqing_Account:"Pridėti pagrindinę čekio sąskaitą",
  Welcome_Word:"Supaprastinkite ir automatizuokite darbo užmokesčio ir žmogiškųjų išteklių valdymą",
  Get_Started_Quickly:"Tiesiog atsakykite į kelis paprastus klausimus, kurie padės mums pradėti...",
  Done_Intro_Word:"Sąranka baigta",
  PIN_Protected:"Apsaugotas slaptažodžiu ir PIN kodu",
  Enter_New_PIN:"Įveskite naują PIN kodą:",
  Enter_PIN:"Įveskite PIN kodą:",
  Invalid_PIN:"Įvestas neteisingas PIN kodas.",
  Account_Identifier:"Paskyros identifikatorius",
  New_Account_Identifier:"Naujas paskyros identifikatorius",
  attempt:"bandymas",
  attempts:"bandymai",
  remaining:"likęs",
  Language:"Kalba",
  languages:"Kalbos",
  select_languages:"Pasirinkite kalbą",
  Deposit:"Užstatas",
  Hire_One_Now:"Išsamdykite vieną dabar",
  App_Locked:"Programa užrakinta.",
  Skip_:"Praleisti",
  Skip_to_Dashboard:"Peršokti į prietaisų skydelį",
  Dashboard:"Prietaisų skydelis",
  Import:"Importuoti",
  Admin:"Administratoriai",
  New_Admin:"Naujas administratorius",
  Settings:"Nustatymai",
  Color_Picker:"Spalvų rinkėjas",
  Font_Picker:"Šrifto rinkiklis",
  Logout:"Atsijungti",
  Close:"Uždaryti",
  Close_menu:"Uždaryti",
  Excel:"Excel failas",
  Csv:"CSV failas",
  Sql:"SQL failas",
  Json:"JSON failas",
  Url:"Importuoti pagal URL",
  Back:"Atgal",
  Timers:"Laikmačiai",
  Cheque:"Patikrinti",
  Print:"Spausdinti",
  Designs:"Dizainas",
  Pause_Printing:"Pristabdyti spausdinimą",
  Resume_Printing:"Tęsti spausdinimą",
  Printing_Paused:"Spausdinimas pristabdytas",
  PrintingUnavailable:"Atsiprašome! Šioje sistemoje spausdinti negalima.",
  Prints_Paused:"Spaudiniai pristabdyti",
  Administration:"Administracija",
  Loading:"Įkeliama",
  Unnamed:"Be vardo",
  error:"Atsiprašome, šio čekio nepavyko atidaryti peržiūrai.",
  No_Cheques_To_Print:"Nereikia spausdinti čekių",
  No_Cheques_To_Print_Word:"Sukurti <a routerLink='/folder/Cheque/New'>Naują Čekį</a>.",
  New_Cheque:"Naujas čekis",
  Start_One_Now:"Pradėti vieną dabar",
  Edit_Cheque:"Redaguoti čekį",
  Select_Cheques:"Pasirinkti čekius",
  Select_Employee:"Pasirinkite darbuotoją",
  Default_Printer:"Numatytasis spausdintuvas",
  Reassign:"Perskirti",
  Configure:"Konfigūruoti",
  Template:"Šablonas",
  Designer:"Dizaineris",
  Edit_Designs:"Redaguoti dizainą",
  New_Design:"Naujas dizainas",
  Next:"Kitas",
  Save:"Sutaupyti",
  Name:"Vardas",
  Mail:"Paštas",
  Amount:"Suma",
  Date:"Data",
  PayPal:"PayPal",
  Payouts:"Išmokos",
  PayPal_Label:"PayPal etiketė",
  Email_Username:"El. paštas / Vartotojo vardas",
  Client_ID:"Kliento ID",
  Sandbox_Email:"Smėlio dėžės el. paštas",
  PayPal_Email:"PayPal el. paštas",
  PayPal_Username:"API vartotojo vardas",
  PayPal_Payouts:"PayPal išmokėjimai",
  Select_PayPal_Key:"Pasirinkite PayPal raktą",
  Secret:"Paslaptis",
  API_Secret:"API paslaptis",
  PayPal_API_Keys:"PayPal raktai",
  New_PayPal_Key:"Naujas PayPal raktas",
  Email_Subject:"Elektroninio laiško tema",
  Email_Message:"El. pašto žinutė",
  Payout_Options:"Išmokėjimo parinktys",
  Payment_Note:"Mokėjimo pažyma",
  Enable_Employee:"Įgalinti darbuotoją",
  Enable_Production_Mode:"Įjungti gamybos režimą",
  Sandbox_Username:"Smėlio dėžės vartotojo vardas",
  Sandbox_Signature:"Smėlio dėžės parašas",
  Sandbox_Password:"Smėlio dėžės slaptažodis",
  Production_Username:"Gamybos vartotojo vardas",
  Production_Signature:"Gamybos parašas",
  Production_Password:"Gamybos slaptažodis",
  Production_Email:"Gamybos el. paštas",
  API_Client_ID:"API kliento ID",
  API_Signature:"API parašas",
  API_Password:"API slaptažodis",
  API_Username:"API vartotojo vardas",
  Secret_Key:"Slaptasis raktas",
  Sandbox:"Smėlio dėžė",
  Production:"Gamyba",
  Sandbox_Keys:"Smėlio dėžės raktai",
  Production_Keys:"Gamybos raktai",
  API_Title:"API pavadinimas",
  Production_Mode:"Gamybos režimas",
  Account_Label:"Paskyros etiketė",
  No_PayPal_Setup:"Nėra PayPal rakto",
  Enable_PayPal_Account:"Įjungti PayPal sąskaitą",
  No_PayPal_Word:"Pridėkite savo <a routerLink='/folder/Invoice/New'>PayPal API Raktą</a>.",
  Printed_Memo:"Išspausdinta atmintinė",
  Employee:"Darbuotojas",
  View_Employee:"Žiūrėti darbuotoją",
  Mailing_Address:"Pašto adresas",
  Company_Address:"Kompanijos adresas",
  Select_Company_Address:"Pasirinkite įmonės adresą",
  Address:"Adresas",
  Any_Day:"Bet kurią dieną",
  Address_Name:"Adresas",
  Unit:"Vieta",
  Account:"Paskyra",
  Bank_Account:"Banko sąskaita",
  Account_Limits:"Įgalinti paskyros apribojimus",
  Payroll:"Darbo užmokestis",
  New_Payroll:"Naujas darbo užmokestis",
  No_Payroll:"Nėra būsimo darbo užmokesčio",
  Upcoming_Holiday:"Artėjančios šventės:",
  Invoice_Due:"Apmokėta sąskaita:",
  New_Invoice:"Nauja sąskaita",
  No_Invoices:"Sąskaitų faktūrų nėra",
  No_Invoices_Word:"Sukurti pirmąją <a routerLink='/folder/Invoice/New'>Sąskaitą Faktūrą</a>.",
  Cheque_Due:"Mokėtas čekis:",
  Payrolls:"Darbo užmokestis",
  Sandbox_Mode:"Smėlio dėžės režimas",
  Hire:"Samdyti",
  Pay:"Mokėti",
  New:"Naujas",
  Add:"Papildyti",
  Make:"Padaryti",
  Time:"Laikas",
  Write:"Rašyti",
  Holiday:"Šventė",
  Holidays:"Atostogos",
  Next_Holiday:"Kita šventė:",
  All_Done:"Viskas padaryta!",
  Employees:"Darbuotojai",
  Payees:"Mokėjimo gavėjai",
  Job:"Darbas",
  Jobs:"Darbai",
  Invoice:"Sąskaita faktūra",
  Invoices:"Sąskaitos faktūros",
  Vacations:"Atostogos",
  Cheques:"Patikros",
  Brand_Cheques:"Prekės ženklas",
  Payment:"Mokėjimas",
  Enable_Payment:"Įjungti mokėjimą",
  Payments:"Mokėjimai",
  Schedule:"Tvarkaraštis",
  Schedules:"Tvarkaraščiai",
  Timesheet:"Laiko žiniaraštis",
  Timesheets:"Laiko žiniaraščiai",
  Salary:"Atlyginimas",
  New_Address:"Naujas adresas",
  Address_2:"Adreso eilutė 2)",
  _City:"Miestas",
  _State:"Valstybė/prov",
  City:"Miestas / miestelis",
  State:"valstybė / provincija",
  Postal:"Pašto / pašto kodas",
  ZIP:"Paštas / paštomatas",
  Country:"Šalis",
  Addresses:"Adresai",
  Required_Options:"Reikalingos parinktys",
  Optional_Options:"Pasirenkamos parinktys",
  Additional_Options:"Papildomos parinktys",
  Required:"Reikalingas",
  Optional:"Neprivaloma",
  Additional:"Papildomas",
  No_Addresses:"Nėra adresų",
  New_Address_Word:"Pridėti pirmąjį <a routerLink='/folder/Address/New'>Adresą</a>.",
  Select_Address:"Pasirinkti adresą",
  No_Address:"Nėra adresų",
  Print_Cheque:"Spausdinti čekį",
  Print_Cheque_Now:"Spausdinti patikrinimą dabar",
  Description:"Apibūdinimas",
  Pay_To_The_Order_Of:"Mokėti pagal užsakymą:",
  Select_Date_Range:"Pasirinkti dienų seką",
  Select_Starting_Date:"Pasirinkite pradžios datą",
  Select_Ending_Date:"Pasirinkite pabaigos datą",
  Select_Date:"Pasirinkite datą",
  Cheque_Date:"Patikrinti datą",
  Cheque_Memo:"Patikrinti atmintinę",
  Blank_Cheque:"Tuščias čekis",
  Blank:"Tuščias",
  No_Cheques:"Jokių patikrinimų",
  No_Cheques_Word:"Atsispausdinkite pirmąjį <a routerLink='/folder/Cheque/New'>Patikrinti</a>",
  Cheque_Amount_Placeholder:"0,00 USD",
  View_Image:"Peržiūrėti Nuotrauka",
  View:"Žiūrėti",
  Modify:"Keisti",
  Delete:"Ištrinti",
  Cheque_Paid:"Mokama",
  New_Deduction:"Naujas atskaitymas",
  Title:"Pavadinimas",
  Frequency:"Dažnis",
  Hourly:"Kas valandą",
  Daily:"Kasdien",
  Weekdays:"Darbo dienomis",
  BiWeekly:"2 savaitės",
  TriWeekly:"3 savaitės",
  Monthly:"Mėnesinis",
  MiMonthly:"2 mėnesiai",
  Quarterly:"Kas ketvirtį",
  Yearly:"Kasmet",
  Every_Week:"Kiekvieną savaitę",
  Every_Payroll:"Kiekvienas darbo užmokestis",
  Every_Month:"Kiekvieną mėnesį",
  Annually:"Kasmet",
  Always_Scheduled:"Visada suplanuota",
  Start_Date:"Pradžios data",
  End_Date:"Pabaigos data",
  Start_Time:"Pradžios laikas",
  End_Time:"Pabaigos laikas",
  Deduction_Label:"Atskaitymo etiketė",
  Notes:"Pastabos",
  Options:"Galimybės",
  Enable:"Įjungti",
  Select_Deductions:"Pasirinkti atskaitymus",
  Deductions:"Išskaitymai",
  No_Deductions:"Jokių atskaitymų",
  No_Deductions_Word:"Sukurkite pirmąjį <a routerLink='/folder/Deduction/New'>Išskaitymą</a>.",
  New_Employee:"Naujas darbuotojas",
  No_Title:"Be pavadinimo",
  _Name:"Vardas",
  About_Yourself:"Apie save",
  Full_Name:"Pilnas vardas",
  Birthday:"Gimtadienis",
  Email:"El. paštas",
  SMS:"TRUMPOJI ŽINUTĖ",
  Phone:"Telefonas",
  Test:"Bandymas",
  Call:"skambinti",
  Fax:"faksas",
  Printed_Note:"Atspausdintas užrašas",
  Position:"Pozicija",
  Job_Position:"Darbo vieta",
  Select_a_Job:"Pasirinkite darbą",
  Select_a_Salary:"Pasirinkite atlyginimą",
  Add_a_Deduction:"Pridėti atskaitymą",
  Taxes:"Mokesčiai",
  Add_Taxes:"Pridėti mokesčius",
  Date_of_Birth:"Gimimo data",
  Email_Address:"Elektroninio pašto adresas",
  Phone_Number:"Telefono numeris",
  Phone_Call:"Skambutis",
  Enable_on_Payroll:"Įgalinti darbo užmokestį",
  Select_Employees:"Pasirinkite darbuotojus",
  No_Employees:"Nėra darbuotojų",
  No_Employees_Word:"Pridėti pirmąjį naują <a routerLink='/folder/Employee/New'>Darbuotoją</a>.",
  No_Name:"Be vardo",
  Unemployeed:"Bedarbis",
  Employeed:"Įdarbintas",
  Paid:"Mokama",
  Enabled:"Įjungtas",
  Disabled:"Išjungta",
  Fire:"Ugnis",
  Not_Available:"Nepasiekiamas",
  Not_Available_Word:"Išspausdinkite pirmąją <a routerLink='/folder/Invoice/New'>Sąskaitą Faktūrą</a> ir gaukite mokėjimą.",
  Select_Invoices:"Select_invoices",
  Print_Invoice_Word:"Išspausdinkite pirmąją <a routerLink='/folder/Invoice/New'>Sąskaitą Faktūrą</a> ir gaukite mokėjimą.",
  Invoice_Title:"Sąskaitos faktūros pavadinimas",
  Invoice_Date:"Sąskaitos data",
  Due_Date:"Terminas",
  New_Job:"Naujas darbas",
  Details:"Detalės",
  Customize:"Pritaikyti",
  Customize_Dashboard:"Tinkinti prietaisų skydelį",
  Components:"Komponentai",
  No_Components:"Be komponentų",
  Main_Components:"Pagrindiniai komponentai",
  Smaller_Components:"Mažesni komponentai",
  Error_Loading_Page:"Klaida įkeliant šį puslapį.",
  Bank_Details:"Banko duomenis",
  About_Your_Job:"Apie tavo darbą",
  Your_Schedule:"Jūsų tvarkaraštis",
  Job_Title:"Darbo pavadinimas",
  Job_Description:"Darbo aprašymas",
  Job_Details:"Darbo informacija",
  Enable_Job:"Įgalinti darbą",
  Pay_Period:"Mokėjimo laikotarpis",
  Perpetually_Schedule:"Nuolatinis tvarkaraštis",
  Select_Jobs:"Pasirinkti darbus",
  No_Jobs:"Nėra darbo",
  Add_Jobs:"Pridėti darbo vietų",
  No_Jobs_Word:"Pridėti pirmąjį <a routerLink='/folder/Job/New'>Darbą</a> į sąrašą.",
  Count_Employees:"job.employee_count+' Darbuotojai'",
  Zero_Employees:"0 darbuotojų",
  New_Leave:"Naujos atostogos",
  Leave_Name:"Palik vardą",
  Paid_Leave:"Išeitinė kompensacija",
  Leave_Pay:"Palikti atlyginimą",
  Indefinite_Leave:"Neterminuotos atostogos",
  Indefinite_Payroll:"Neribotas darbo užmokestis",
  Leave:"Palik",
  Add_Schedules:"Pridėti tvarkaraščius",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Įjungti nebuvimą",
  Select_Leaves:"Pasirinkti lapus",
  No_Leaves:"Jokių nebuvimo lapų",
  Leave_Of_Absence:"Nebuvimo atostogos",
  Leaves_Of_Absence:"Nebuvimo lapai",
  New_Leave_of_Absense:"Naujos nebuvimo atostogos",
  No_Leaves_Word:"Pridėti pirmąjį <a routerLink='/folder/Leave/New'>Nebuvimo Atostogas</a>.",
  Not_Enabled:"Neįjungta",
  Absences:"Nebuvimas",
  Payee:"Mokėjimo gavėjas",
  New_Payee:"Naujas gavėjas",
  Payee_Identifier:"Mokėjimo gavėjo identifikatorius",
  Payee_Name:"Gavėjo vardas",
  Payee_Title:"Mokėjimo gavėjo pavadinimas",
  Payment_Memo:"Mokėjimo pažyma",
  Select_Payees:"Pasirinkti gavėjus",
  No_Payees:"Nėra gavėjų",
  Add_Payee_Note:"Pridėti pirmąjį <a routerLink='/folder/Payee/New'>Mokėjimo Gavėją</a>.",
  New_Payees:"Nauji gavėjai",
  About_The_Payment_Schedule:"Mokėjimo planas",
  Your_Payroll_Schedule:"Automatinis darbo užmokestis",
  New_Payment:"Naujas mokėjimas",
  Identifier:"Identifikatorius",
  Select:"Pasirinkti",
  Memo:"Atmintinė",
  Payment_Date:"Mokėjimo diena",
  Mark_as_Paid:"Pažymėti kaip apmokėtą",
  Select_Payments:"Pasirinkti mokėjimus",
  No_Payments:"Mokėjimų nėra",
  No_Payments_Word:"Sukurti pirmąjį <a routerLink='/folder/Payment/New'>Mokėjimą</a>.",
  Create_Payroll:"Sukurti darbo užmokestį",
  Properties:"Ypatybės",
  Payroll_Title:"Darbo užmokesčio pavadinimas",
  Payroll_Notes:"Atlyginimo pažymos",
  Payroll_Setup:"Darbo užmokesčio sąranka",
  Tabulate_Payments:"Mokėjimai lentelėse",
  Tabulate:"Lentelė",
  By:"Pagal:",
  Payments_By:"Mokėjimai iki",
  Timesheets_And_Schedules:"Laiko žiniaraščiai ir tvarkaraščiai",
  Both:"Abu",
  Items:"Daiktai",
  Add_Payees:"Pridėti gavėjus",
  Add_Account:"Pridėti paskyrą",
  Enable_Account:"Įjungti paskyrą",
  Enable_Payroll:"Įjungti darbo užmokestį",
  Print_Payroll:"Spausdinti darbo užmokestį",
  No_Payrolls:"Nėra darbo užmokesčio",
  No_Payroll_Word:"Sukurkite savo pirmąjį <a routerLink='/folder/Payroll/New'>Payress</a>.",
  View_more:"PERŽIŪRĖTI DAUGIAU",
  Less:"MAžiau",
  Add_Payroll:"Pridėti darbo užmokestį",
  Select_Payroll:"Pasirinkite darbo užmokestį",
  About_Your_Salary:"Apie tavo atlyginimą",
  Add_Salaries:"Pridėti atlyginimus",
  Add_Salary:"Pridėti atlyginimą",
  Salaries:"Atlyginimai",
  No_Salaries:"Jokių atlyginimų",
  No_Salaries_Word:"Pridėti pirmąjį <a routerLink='/folder/Salary/New'>Atlyginimą</a>.",
  Select_Salaries:"Pasirinkite atlyginimus",
  New_Salary:"Naujas atlyginimas",
  Salary_Name:"Atlyginimo identifikatorius",
  Enable_Salary:"Įjungti atlyginimą",
  Salary_Amount:"Atlyginimo dydis",
  New_Schedule:"Naujas tvarkaraštis",
  Schedule_Title:"Tvarkaraščio pavadinimas",
  Add_Address:"Pridėti adresą",
  Repeat:"Pakartoti",
  Design:"Dizainas",
  Edit_Design:"Redaguoti dizainą",
  Edit_this_Design:"Redaguoti šį dizainą",
  Repeat_Payment:"Pakartotinis mokėjimas",
  Enable_Schedule:"Įjungti tvarkaraštį",
  Never:"Niekada",
  Select_Schedule:"Pasirinkti tvarkaraščius",
  No_Schedules:"Nėra tvarkaraščių",
  No_Schedules_Word:"Sukurti pirmąjį <a routerLink='/folder/Schedule/New'>Tvarkaraštį</a>.",
  New_Administrator:"Naujas administratorius",
  Username:"Vartotojo vardas",
  First_Name:"Pirmas vardas",
  Last_Name:"Pavardė",
  Add_an_Address:"Pridėti adresą",
  Payment_Limit:"Mokėjimo limitas",
  Total_Limit:"Bendras limitas",
  Select_Accounts:"Pasirinkti paskyras",
  No_Administrators:"Nėra administratorių",
  No_Administrators_Word:"Sukurkite pirmąją <a routerLink='/folder/Administrator/New'>Administratoriaus paskyrą</a>.",
  New_Administrators_Word:"Pridėti pirmąjį <a routerLink='/folder/Administrator/New'>Administratorių</a>",
  Cloud:"Debesis",
  Backup:"Atsarginė kopija",
  Enable_iCloud:"Įjungti iCloud",
  Enable_Google_Drive:"Įgalinti Google diską",
  Enable_Microsoft_OneDrive:"Įgalinti Microsoft OneDrive",
  Automatically_Backup:"Automatiškai kurti atsargines kopijas",
  FTP_Settings:"FTP nustatymai",
  URL_File_Prompt:"Nurodykite .xls / .xlsx / .json failo, kurį norite importuoti, vietą:",
  URL_SQL_Prompt:"Nurodykite importuojamo SQLite failo vietą:",
  FTP_Backup:"FTP atsarginė kopija",
  FTP_Import:"FTP importas",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Šeimininkas",
  Port:"Uostas",
  Path:"Kelias",
  Data_Path:"Duomenų kelias",
  Enable_FTP_Account:"Įgalinti FTP paskyrą",
  HostnameIP:"Pagrindinio kompiuterio vardas",
  Password:"Slaptažodis",
  Connection_Port:"Prisijungimo prievadas",
  Enable_MySQL_Database:"Įjungti MySQL duomenų bazę",
  Log:"Žurnalas",
  Reset:"Atstatyti",
  Erase:"Ištrinti",
  Export:"Eksportuoti",
  Database:"Duomenų bazė",
  Upload_CSV:"Įkelti CSV",
  Download_CSV:"Atsisiųsti CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL atsarginė kopija",
  Internal_Notes:"Vidinės pastabos",
  Root_Path:"Šaknų kelias",
  Select_Backup:"Pasirinkite atsarginę kopiją",
  Add_New_Backup:"Pridėti naują atsarginę kopiją",
  First_Backup:"Nustatyti pirmąją atsarginę kopiją...",
  Backups:"Atsarginės kopijos",
  Add_Backup:"Pridėti atsarginę kopiją",
  No_Backups:"Nėra jokių atsarginių kopijų rasti.",
  Select_Backup_Type:"Pasirinkite atsarginės kopijos tipą, kurį norite nustatyti...",
  Backup_Type:"Atsarginės kopijos tipas",
  FTP_Drive:"FTP diskas",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Vairuoti",
  Microsoft_OneDrive:"Vairuoti",
  Import_Fields:"Importo laukai",
  Import_Fields_Word:"Naudokite šią skiltį norėdami pasirinkti <a routerLink='/folder/Holiday/New'>Importuoti</a> duomenis.",
  Upload:"Įkelti",
  Download:"Parsisiųsti",
  Download_JSON:"Atsisiųsti kaip JSON duomenis",
  Download_SQL:"Atsisiųsti kaip SQL failą",
  New_Bank:"Naujas bankas",
  New_Account:"Nauja paskyra",
  New_Bank_Account:"Nauja banko sąskaita",
  Upload_Image:"Įkelti paveikslėlį",
  Bank_Name:"Banko pavadinimas",
  Bank_Address:"Banko adresas",
  Branch_Address:"Filialo adresas",
  Address_on_Cheque:"Adresas",
  Cheque_Numbers:"Patikrinti numerius",
  Cheque_Details:"Patikrinti informaciją",
  Bank_Logo:"Banko logotipas",
  Cheque_ID:"Patikrinti ID",
  Starting_Cheque_ID:"Pradžios patikrinimo ID",
  Transit_Number:"Tranzito numeris",
  Institution_Number:"Įstaigos numeris",
  Designation_Number:"Paskyrimo numeris",
  Account_Number:"Paskyros numeris",
  Limits:"ribos",
  Default_Limits:"Numatytosios ribos",
  Over_Limit:"Per ribą",
  Under_Limit:"Pagal limitą",
  Payroll_Limit:"Darbo užmokesčio limitas",
  Enable_Bank_Account:"Įjungti banko sąskaitą",
  Select_Account:"Pasirinkti paskyrą",
  No_Bank_Account:"Nėra banko sąskaitos",
  No_Bank_Account_Word:"Pridėti pirmąją <a routerLink='/folder/Accounts/New'>Banko Sąskaitą</a>.",
  Bank_Accounts:"Banko sąskaitos",
  No_Accounts:"Nėra sąskaitų",
  No_Accounts_Note:"Pridėti pirmąją <a routerLink='/folder/Accounts/New'>Banko Sąskaitą</a>.",
  Cheque_Designer:"Check Designer",
  Cheque_Design:"Patikrinti dizainą",
  Select_Design:"Pasirinkite dizainą",
  Cheque_Designs:"Patikrinti dizainą",
  No_Cheque_Designs:"Nėra čekių dizaino",
  No_Cheque_Designs_Word:"Sukurkite savo <a routerLink='/folder/Settings/Cheque/Design/New'>Reprehendo Consilio</a>.",
  Set_Default:"Nustatyti kaip numatytąjį",
  Default:"Numatytas",
  Remove:"Pašalinti",
  Folder:"Aplankas",
  Edit:"Redaguoti",
  LoadingDots:"Įkeliama...",
  Add_a_New_File:"Pridėti <a href='#' (click)='add()'>naują failą</a> prie",
  this_folder:"šis aplankas",
  FTP_Backup_Settings:"FTP atsarginės kopijos nustatymai",
  Secure_File_Transfer:"Saugus failų perkėlimas",
  New_Holiday:"Nauja šventė",
  Add_Holiday:"Pridėti atostogas",
  Holiday_Name:"Šventės vardas",
  Additional_Pay:"Papildomas atlyginimas",
  Enable_Holiday:"Įjungti atostogas",
  Select_Holidays:"Pasirinkite šventes",
  No_Holidays:"Jokių atostogų",
  No_Holidays_Word:"Pridėti pirmąją <a routerLink='/folder/Holiday/New'>Valstybinę šventę</a>.",
  New_Import:"Naujas importas",
  Import_Data:"Importuoti duomenis",
  Import_Data_Word:"Pasirinkite failo tipą arba pasirinktą įkėlimo metodą.<br /> Įkėlę palaikomą failą galėsite pasirinkti, kurie importuoti failo laukai atitinka bet kurį programos parametrą.", 
  Import_File:"Importuoti failą",
  Link_To_File:"Nuoroda į failą",
  Select_File:"Pasirinkti failą",
  New_Moderator:"Naujas moderatorius",
  Allow_Moderation:"Leisti moderuoti",
  Create_Paycheques:"Sukurti darbo užmokesčio čekius",
  Edit_Paycheques_and_Data:"Redaguoti atlyginimus ir duomenis",
  Destroy_Data_and_Paycheques:"Sunaikinti duomenis ir atlyginimus",
  Bonus_Percentage:"Atlyginimo procentas",
  Fixed_Amount:"Nustatyta suma",
  Select_Moderator:"Pasirinkti moderatorių",
  No_Moderators:"Moderatorių nėra",
  Moderators:"Moderatoriai",
  Moderator_Account:"Sukurkite pirmąją <a routerLink='/folder/Administrator/New'>Moderatoriaus paskyrą</a>.",
  No_Moderators_Word:"Pridėti pirmąjį <a routerLink='/folder/Administrator/New'>Moderatorių</a>.",
  Defaults:"Numatytieji",
  Provide_Us_Info:"Suteikite mums informaciją",
  Setup_Your_Printer:"Nustatykite spausdintuvą",
  Your_Company:"Apie jūsų įmonę",
  Company_Name:"Įmonės pavadinimas",
  Currency:"Valiuta",
  Default_Currency:"Numatytoji valiuta",
  Base_Monthly_Income:"Mėnesinės pajamos",
  Protection:"Apsauga",
  App_Protection:"Programos apsauga",
  PIN_Code_Protection:"PIN kodo apsauga",
  App_Protection_Word:"Įgalinti saugos metodus, kurie padeda apsaugoti jūsų paskyrą",
  PIN_Code:"PIN kodas",
  Change_PIN:"Keisti PIN kodą",
  New_Password:"Naujas Slaptažodis",
  Geofence_Protection:"Geo-tvoros apsauga",
  Geofence_Area:"Nustatyti sritį",
  Distance:"Atstumas",
  Setup_Now:"Nustatyti dabar",
  Mile:"Mile",
  Km:"km",
  m:"m",
  Facial_Recognition:"Veido atpažinimas",
  Face:"Veidas",
  Setup:"Sąranka",
  Label:"Etiketė",
  Password_Protection:"Slaptažodžio apsauga",
  Modify_Password:"Keisti slaptažodį",
  New_Tax_Entry:"Naujas mokesčių įrašas",
  New_Tax:"Naujasis mokestis",
  Tax_Label:"Mokesčių etiketė",
  Perpetually_Enabled:"Nuolat įjungta",
  Select_Taxes:"Pasirinkite mokesčius",
  Tax_Deductions:"Mokesčių atskaitymai",
  No_Tax_Deductions:"Jokių mokesčių atskaitymų",
  No_Tax_Deductions_Word:"Pridėti pirmąjį <a routerLink='/folder/Tax/New'>Mokesčio</a> atskaitą.",
  New_Timer:"Naujas laikmatis",
  Start:"Pradėti",
  Stop:"Sustabdyti",
  Start_Timer:"Pradėti laikmatį",
  Stop_Timer:"Sustabdyti laikmatį",
  Timer_Active:"Laikmatis aktyvus",
  Timer:"Laikmatis:",
  Timer_Running:"Laikmatis: (veikia)",
  Save_Timer:"Išsaugoti laikmatį",
  New_Timesheet:"Naujas laiko apskaitos žiniaraštis",
  Select_Timesheets:"Pasirinkite laiko žiniaraščius",
  Work_Notes:"Darbo pastabos",
  Entry_Title:"Įrašo pavadinimas",
  No_Timesheets:"Nėra laiko lapų",
  No_Timesheets_Word:"Pridėti pirmąjį <a routerLink='/folder/Timesheet/New'>Laiko lapą</a>.",
  Timesheet_Submitted:"Laiko žiniaraštis pateiktas",
  Timesheet_Congrats:"Sveikiname! Jūsų darbo laiko apskaitos žiniaraštis sėkmingai pateiktas. Ačiū!",
  Timesheet_Copy:"Norėdami gauti dokumentų kopijas, pateikite mums savo el. pašto adresą ir (arba) mobiliojo telefono numerį.",
  Submit:"Pateikti",
  Allow_Notifications:"Leisti pranešimus",
  Untitled_Entry:"Naujas įėjimas",
  Untitled_Bank:"Bankas be pavadinimo",
  Timesheet_Entry:"Įrašas laiko apskaitos žiniaraštyje",
  Work_Description:"Darbo aprašymas",
  Enable_Timesheet:"Įgalinti laiko lapą",
  Submit_Timesheet:"Pateikti laiko lapą",
  Vacation:"Atostogos",
  New_Vacation:"Naujos atostogos",
  Vacation_Name:"Atostogų pavadinimas",
  Paid_Vacation:"Mokamos atostogos",
  Vacation_Pay:"Atostogų apmokėjimas",
  Enable_Vacation:"Įjungti atostogas",
  Select_Vacations:"Pasirinkite atostogas",
  No_Vacations:"Jokių atostogų",
  No_Vacations_Word:"Sukurkite pirmąjį įrašą <a routerLink='/folder/Vacation/New'>Atostogos</a>",
  Payroll_Schedule:"Darbo užmokesčio tvarkaraštis",
  Next_Payroll:"Kitas darbo užmokestis:",
  Upcoming_Payroll:"Būsimas darbo užmokestis",
  No_Upcoming_Payroll:"Nėra būsimo darbo užmokesčio",
  Address_Book:"Adresų knyga",
  Archived_Documents:"Archyvuoti dokumentai",
  Dev_Mode:"Taikymas kūrimo režimu",
  Administrators:"Administratoriai",
  Privacy:"Privatumas",
  None:"Nė vienas",
  Select_Signature:"Pasirinkti parašą",
  No_Signatures:"Nėra parašų",
  No_Signatures_Word:"Pridėti pirmąjį <a routerLink='/folder/Signature/New'>Parašą</a>.",
  Repeat_Indefinitely:"Kartoti neribotą laiką",
  Sign:"Pasirask",
  Sign_Here:"Pasirašyti čia",
  Date_Signed:"Pasirašymo data",
  Signature_Pad:"Parašo bloknotas",
  Account_Holder:"Sąskaitos savininkas",
  Account_Properties:"Paskyros ypatybės",
  Name_On_Cheque:"Vardas ant čekio",
  Server_Hostname:"Serverio prieglobos pavadinimas / IP",
  Printers:"Spausdintuvai",
  No_Printers:"Nėra spausdintuvų",
  Printer_Exists:"Spausdintuvas tokiu pavadinimu jau yra.",
  No_Printers_Word:"Pridėkite patį pirmąjį <a routerLink='/folder/Printer/New'>Spausdintuvą</a>.",
  No_Printer_Alert:"Nė vienas spausdintuvas nenustatytas. Ar norite nustatyti spausdintuvą?",
  Add_Printer:"Pridėti spausdintuvą",
  New_Printer:"Naujas spausdintuvas",
  Printer_Hostname:"Spausdintuvo pagrindinio kompiuterio pavadinimas / IP",
  Printer_Label:"Spausdintuvo etiketė",
  Printer_Protocol:"Spausdintuvo protokolas",
  Protocol:"Protokolas",
  Email_Print:"El. paštas",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Lizdas",
  Print_Job:"Spausdinimo darbas",
  Printed:"Išspausdinta",
  Not_Printed:"Nespausdinta",
  Print_Jobs:"Spausdinimo darbai",
  Print_Queue:"Spausdinimo eilė",
  No_Print_Jobs:"Nėra spausdinimo darbų",
  No_Prints:"Sukurkite naują <a routerLink='/folder/Cheque/New'>Čekį</a>, kad galėtumėte spausdinti.",
  Prints:"spaudiniai",
  Find_Printer:"Rasti spausdintuvą",
  Find_AirPrint_Devices:"Rasti AirPrint įrenginius",
  Select_Printer:"Pasirinkti spausdintuvą",
  System_UI:"Sistemos vartotojo sąsaja",
  Printer:"Spausdintuvas",
  Status:"Būsena",
  Preview:"Peržiūra",
  Enable_Print_Job:"Įjungti spausdinimo užduotį",
  Queue_Weight:"Eilės svoris",
  Unlimited:"Neribotas",
  Show_Advanced_Printer_Options:"Rodyti išplėstines spausdintuvo parinktis",
  Advanced:"Išplėstinė",
  Location:"vieta",
  Note:"Pastaba",
  Queue_Name:"Eilės pavadinimas",
  Pages_Printed_Limit:"Puslapių spausdinimo limitas",
  MultiPage_Idle_Time:"Kelių puslapių laukimo laikas (s)",
  Page_Count_Limit:"Puslapių skaičiaus riba",
  Page_Orientation:"Puslapio orientacija",
  Portrait:"Portretas",
  Landscape:"Peizažas",
  Duplex:"Dvipusis",
  Double_Sided_Printing:"Dvipusis",
  Duplex_Mode:"Dvipusis režimas",
  Duplex_Short:"Trumpas",
  Duplex_Long:"Ilgas",
  Duplex_None:"Nė vienas",
  Color_And_Quality:"Spalva ir kokybė",
  Monochrome:"Vienspalvis",
  Photo_Quality_Prints:"Nuotraukų kokybės spaudiniai",
  Printer_Email:"Spausdintuvo el. paštas",
  Automatically_Downsize:"Automatiškai sumažinti",
  Paper:"Popierius",
  Paper_Name:"Popieriaus pavadinimas",
  Paper_Width:"Popieriaus plotis",
  Paper_Height:"Popieriaus aukštis",
  Paper_Autocut_Length:"Automatinio popieriaus pjovimo ilgis",
  Margins:"Maržos",
  Page_Margins:"Puslapių paraštės",
  Page_Margin_Top:"Viršutinė puslapio paraštė",
  Page_Margin_Right:"Dešinė puslapio paraštė",
  Page_Margin_Bottom:"Apatinio puslapio paraštė",
  Page_Margin_Left:"Kairė puslapio paraštė",
  Add_Employees:"Pridėti darbuotojų",
  Header:"Antraštė",
  Print_A_Page_Header:"Spausdinti puslapio antraštę",
  Header_Label:"Antraštės etiketė",
  Header_Page_Index:"Antraštės puslapio indeksas",
  Header_Font_Name:"Antraštės šriftas",
  Select_Font:"Pasirinkite šriftą",
  Font_Selector:"Šrifto parinkiklis",
  Header_Font_Size:"Antraštės šriftas",
  Header_Bold:"Antraštė paryškinta",
  Header_Italic:"Antraštė kursyvas",
  Header_Alignment:"Antraštės lygiavimas",
  Left:"Kairė",
  Center:"Centras",
  Right:"Teisingai",
  Justified:"Pateisinama",
  Header_Font_Color:"Antraštės spalva",
  Select_Color:"Pasirinkite spalvą",
  Footer:"Poraštė",
  Print_A_Page_Footer:"Spausdinti puslapio poraštę",
  Footer_Label:"Poraštės etiketė",
  Footer_Page_Index:"Poraštės puslapio indeksas",
  Footer_Font_Name:"Poraštės šriftas",
  Fonts:"Šriftai",
  Done:"Padaryta",
  Select_Fonts:"Pasirinkti šriftus",
  Footer_Font_Size:"Poraštės dydis",
  Footer_Bold:"Poraštė paryškinta",
  Footer_Italic:"Poraštė kursyvas",
  Footer_Alignment:"Poraštės lygiavimas",
  Footer_Font_Color:"Poraštės spalva",
  Page_Copies:"Puslapių kopijos",
  Enable_Printer:"Įjungti spausdintuvą",
  Remote_Logging:"Nuotolinis registravimas",
  Log_Server:"Žurnalų serveris",
  Encryption:"Šifravimas",
  Random_Key:"Atsitiktinis raktas",
  Encryption_Key:"Šifravimo raktas",
  Cheques_Webserver:"Tinkinta duomenų bazė",
  Learn_How:"Išmokti kaip",
  Signature:"Parašas",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Žiūrėti parašą",
  Printed_Signature:"Atspausdintas parašas",
  Digitally_Sign:"Pasirašyk skaitmeniniu būdu",
  Digital_Signature:"Elektroninis parašas",
  Add_Signature:"Pridėti parašą",
  Add_Your_Signature:"Pridėti parašą",
  Enable_Signature:"Įjungti parašą",
  Digitally_Signed:"Pasirašyta skaitmeniniu būdu",
  Insert_Error:"Neįmanoma išsaugoti čekio dėl duomenų bazės problemų.",
  Delete_Confirmation:"Ar tikrai norite ištrinti šią informaciją?",
  Discard_Confirmation:"Ar tikrai norite atmesti šią informaciją?",
  Discard_Bank_Confirmation:"Ar tikrai norite atmesti šią paskyrą?",
  Discard_Printer_Confirmation:"Ar tikrai norite išmesti šį spausdintuvą?",
  Delete_Bonus_Confirmation:"Ar tikrai norite ištrinti šią premiją?",
  Delete_Invoice_Confirmation:"Ar tikrai norite ištrinti šią sąskaitą faktūrą?",
  Generated_Cheque:"Sukurtas čekis",
  Generated_Invoice:"Sukurta sąskaita faktūra",
  Schedule_Start_Time:"Pradėti planuoti",
  Schedule_End_Time:"Tvarkaraščio pabaiga",
  New_Call:"Naujas skambutis",
  No_Contacts:"Nėra kontaktų",
  No_Contacts_Word:"Administratoriai, moderatoriai, darbuotojai ir mokėjimo gavėjai rodomi kaip kontaktai",
  PDF_Subject:"finansai",
  PDF_Keywords:"darbo užmokesčio čekio PDF čekio čekiai",
  Printer_Setup:"Spausdintuvo sąranka",
  Printer_Selection:"Spausdintuvo pasirinkimas",
  New_Fax:"Naujas faksas",
  New_Fax_Message:"Naujas fakso pranešimas",
  Fax_Machine:"Fakso aparatas",
  Fax_Name:"Fakso pavadinimas",
  Fax_Email:"Fakso el. paštas",
  Fax_Number:"Fakso numeris",
  Contents:"Turinys",
  Fax_Body:"Puslapio turinys",
  Header_Word:"Antraštė:",
  Header_Text:"Antraštės tekstas",
  Include_Header:"Įtraukti antraštę",
  Include_Footer:"Įtraukti poraštę",
  Footer_Word:"Poraštė:",
  Footer_Text:"Poraštės tekstas",
  Attach_a_Cheque:"Pridėkite čekį",
  Add_Deduction:"Pridėti atskaitymą",
  Enable_Fax:"Siųsti faksogramą",
  Select_Fax:"Pasirinkti faksą",
  No_Faxes:"Nėra faksų",
  Faxes:"Faksai",
  Save_and_Send:"Siųsti faksogramą",
  Save_and_Pay:"Sutaupyk ir mokėk",
  WARNING:"ĮSPĖJIMAS:",
  Remember:"Prisiminti",
  Printing:"Spausdinimas",
  Printing_Complete:"Spausdinimas baigtas!",
  of:"apie",
  There_Were:"Ten buvo ",
  Successful_Prints:"sėkmingi spaudiniai ir",
  Unsuccessful_Prints:"nesėkmingi spaudiniai",
  PrinterError:"Atsiprašome! Įvyko klaida.",
  Printing_:"Spausdinimas...",
  PrinterSuccess:"Dokumentas sėkmingai išspausdintas",
  PrintersSuccess:"Dokumentai sėkmingai išspausdinti",
  Print_Cheques:"Spausdinti čekius",
  New_Message:"Nauja žinutė",
  New_Messages:"Nauji pranešimai",
  Read_Message:"Skaityti pranešimą",
  Write_Message:"Rašyti žinutę",
  Send_Message:"Siųsti žinutę",
  Subject:"Tema",
  Message:"Pranešimas",
  Writing:"Rašau...",
  Send:"Siųsti",
  Set_Date:"Nustatyti datą",
  Set_Time:"Nustatyti laiką",
  Recieve:"Gauti",
  Set_as_Default:"Nustatyti kaip numatytąjį",
  Default_Account:"Numatytoji sąskaita",
  Default_Design:"Numatytasis dizainas",
  Multiple_Cheques:"Checks (trigubas)",
  Account_Mode:"Paskyros režimas",
  Multiple_Cheques_Description:"Trys patikrinimai puslapyje.",
  Check_and_Table:"Check & Table",
  Check_including_Table:"Patikrinimo ir apskaitos lentelė",
  Check_Mailer:"Patikrinti laiškininką",
  Check_Mailer_Window:"Patikrinti su adreso langu",
  DocuGard_Table_Top:"DocuGard Check & Table (viršuje)",
  DocuGard_Table_Middle:"DocuGard Check & Table (vidurinis)",
  DocuGard_Table_Bottom:"DocuGard Check & Table (apačioje)",
  DocuGard_Mailer_Top:"DocuGard Check Mailer (viršuje)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (Vidurinis)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mailer (apačioje)",
  DocuGard_Three_Cheques:"DocuGard patikrinimai (trigubai)",
  DocuGard_Cheque_Top:"DocuGard Check (viršuje)",
  DocuGard_Cheque_Middle:"DocuGard Check (vidurinis)",
  DocuGard_Cheque_Bottom:"DocuGard Check (apačioje)",
  DocuGard_Three_Cheques_Window:"Trys patikrinimai viename puslapyje",
  DocuGard_Table_Top_Window:"Check & pelno lentelė",
  DocuGard_Table_Middle_Window:"Check & pelno lentelė",
  DocuGard_Table_Bottom_Window:"Check & pelno lentelė",
  DocuGard_Mailer_Top_Window:"Check, table ir address.",
  DocuGard_Mailer_Middle_Window:"Check, table ir address.",
  DocuGard_Mailer_Bottom_Window:"Check, table ir address.",
  DocuGard_Cheque_Top_Window:"Patikrinkite, ar nėra saugaus popieriaus",
  DocuGard_Cheque_Middle_Window:"Patikrinkite, ar nėra saugaus popieriaus",
  DocuGard_Cheque_Bottom_Window:"Patikrinkite, ar nėra saugaus popieriaus",
  Basic_Cheque:"Patikrinti (viršuje)",
  Basic_Cheque_Print:"Išspausdinkite vieną čekį.",
  Error_Setting_As_Paid:"Klaida nustatant kaip mokamą",
  Add_Attachment:"Pridėti priedą",
  PrinterUnavailable:"Spausdintuvas nepasiekiamas",
  CreditCardComponent:"Kortos",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Naujas indėlis",
  Deposits:"Indėliai",
  No_Deposits:"Nėra indėlių",
  Credit_Card_Deposit:"kreditinė kortelė",
  New_Credit_Card_Deposit_Message:"Indėlis į kredito kortelę",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin indėlis",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Interac Transfer",
  Payments_Limit:"Mokėjimo limitas",
  No_Payment_Limit:"Nėra mokėjimo limito",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal indėlis",
  No_Deposits_Word:"Pridėti pirmąją įmonės <a routerLink='/folder/Deposit/New'>Įmoką</a>.",
  No_Documents_Specified:"Nebuvo nurodyta spausdinti dokumentų",
  No_Printers_Added:"Spausdintuvų nerasta. Eikite į Nustatymai > Spausdintuvai, kad pridėtumėte.",
  DB_Erase_Prompt:"Visiškai ištrinti visą duomenų bazę? ĮSPĖJIMAS: prarasite visą saugomą informaciją!",
  Console_Warning:"Neįklijuokite jokio teksto į šią konsolę. Galite rizikuoti sau ir (arba) savo įmonei.",
  No_Faxes_Word:"Sukurti pirmąjį <a routerLink='/folder/Fax/New'>Faksogramą</a>.",
  Cheque_Delete_Confirmation:"Ar tikrai norite ištrinti šį čekį?",
  Design_Delete_Confirmation:"Ar tikrai norite ištrinti šį dizainą?",
  Cheque_Pay_Confirmation:"Pažymėti šį čekį kaip apmokėtą? Jis NEBUS rodomas spausdinimo eilėje.",
  Payment_Pay_Confirmation:"Pažymėti šį mokėjimą kaip sumokėtą? Jis NEBUS rodomas čekių eilėje.",
  Delete_Deduction_Confirmation:"Ar tikrai norite ištrinti šį atskaitymą?",
  Delete_Job_Confirmation:"Ar tikrai norite ištrinti šį darbą?",
  Delete_Timesheet_Confirmation:"Ar tikrai norite ištrinti šį darbo laiko apskaitos žiniaraštį?",
  Delete_Schedule_Confirmation:"Ar tikrai norite ištrinti šį tvarkaraštį?",
  Delete_Setting_Confirmation:"Ar tikrai norite iš naujo nustatyti šį nustatymą?",
  Delete_Fax_Confirmation:"Ar tikrai norite ištrinti šią faksogramą?",
  Delete_File_Confirmation:"Ar tikrai norite ištrinti šį failą?",
  Delete_Vacation_Confirmation:"Ar tikrai norite ištrinti šias atostogas?",
  Delete_Printer_Confirmation:"Ar tikrai norite ištrinti šį spausdintuvą?",
  Remove_Design_Confirmation:"Ar tikrai norite ištrinti šį dizainą?",
  Delete_Payroll_Confirmation:"Ar tikrai norite ištrinti šį darbo užmokesčio sąrašą?",
  Send_Fax_Email_Confirmation:"Ar norite siųsti faksu ir el. paštu šiuos dokumentus?",
  Send_Email_Confirmation:"Ar norite išsiųsti šį dokumentą el. paštu?",
  Send_Fax_Confirmation:"Ar norite išsiųsti šį dokumentą faksu?",
  Error_Generating_PDF:"Atsiprašome. Generuojant šį dokumentą įvyko klaida.",
  PDF_Error_Saving_Image:"Atsiprašome. Išsaugant šio dokumento PDF vaizdą įvyko klaida.",
  Test_Printer_Confirmation:"Ar norite atspausdinti bandomąjį puslapį šiuo spausdintuvu?",
  Save_Timesheet_Prompt:"Pridėkite pavadinimą arba paspauskite Pradėti laikmatį, kad išsaugotumėte.",
  Remove_Geofence_Prompt:"Ar tikrai norite pašalinti šios geografinės tvoros vietą?",
  Delete_Employee_Confirmation:"Ar esate įsitikinę, kad norite ištrinti ",
  Fire_Employee_Confirmation:"Ar tikrai norite iššauti"
}