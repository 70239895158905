export const Eu = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Copyright &copy; 2023",
  black:"Beltz",
  green:"Berde",
  gold:"Urre",
  blue:"Urdin",
  brown:"Marroi",
  purple:"More",
  pink:"Arrosa",
  red:"Gorri",
  Swatches:"Laginak",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Hue",
  Saturation:"Saturazioa",
  Lightness:"Arintasuna",
  Upgrade_To_Pro:"Berritu Pro-ra",
  AllFeaturesInclude:"Ezaugarri guztiek barne hartzen dute:",
  PrintUnlimitedCheques:"Inprimatu mugagabeko txekeak",
  PremiumChequeDesigns:"Premium Check Diseinuak",
  ManageUnlimitedEmployees:"Kudeatu langile mugagabeak",
  AddUnlimitedPayees:"Gehitu hartzaile mugagabeak",
  CreateUnlimitedPayrolls:"Sortu nomina mugagabeak",
  UnlimitedSchedulesandTimeSheets:"Ordutegi eta ordutegi mugagabeak",
  BulkPayPalPayouts:"PayPal-eko ordainketak",
  UnlimitedBankAccounts:"Banku-kontu mugagabeak",
  ManageMultipleCompanies:"Kudeatu hainbat enpresa",
  TrackInsurancePolicies:"Jarraipena Aseguru Polizak",
  Bio_MetricProtection:"Babes Biometrikoa",
  Geo_FenceLock_OutProtection:"Geo-Hesia blokeatzeko babesa",
  Multiple_Companies_Word:"Hainbat enpresa kudeatzea ez dago erabilgarri Checks premium gabe.",
  PayPal_Payouts_Word:"PayPal-eko ordainketak desgaituta daude Txekeen primarik gabe.",
  PINProtection:"PIN babesa",
  PasswordProtection:"Pasahitz babesa",
  May_Require_Approval:"Baliteke onarpena eskatu.",
  Subscribe:"Harpidetu",
  Billed:"Fakturatua",
  Up:"Gora",
  Down:"Behera",
  Positioning:"Kokatzea",
  Marker:"Markagailua",
  Drag_Marker:"Arrastatu Markatzailea",
  Tagline:"Txekeak inprimatu eta nominak taularatu",
  Marker_Word:"Erabili Markagailuak elementuaren tamaina egiteko.",
  Pinch_Zoom:"Pinch Zoom",
  Pinch_Word:"Atxikatu elementua handitzeko.",
  Drag:"Arrastatu",
  Drag_Word:"Erabili hatza elementuak arrastatu.",
  subscription_alias_word:"Harpidetza automatikoki berritzea",
  premium_alias_word:"Behin-behineko bertsio berritzeko paketea",
  print_alias_word:"Inprimatu Banakako Txekeak",
  mode_alias_word:"Era",
  Pro:"Pro",
  Pro_word:"Pro bertsioa behar da.",
  Cant_Delete_Default_Company_Word:"Barkatu, ezin duzu zure enpresa lehenetsia ezabatu.",
  Reinsert_Default_Designs:"Berriro txertatu lehenetsitako diseinuak",
  Reinsert_Default_Designs_word:"Diseinu lehenetsiak berriro txertatu nahi dituzu?",
  Subscription_Active_Disable_Word:"Harpidetza hau aktibo dago. Txekeetarako harpidetza bertan behera utzi nahi duzu?",
  Company_Logo:"Enpresaren logotipoa",
  ZERO_:"ZERO",
  Disclaimer:"Erantzukizuna",
  Privacy_Policy:"Pribatutasun politika",
  EULA:"EULA egiaztatzen du",
  Terms_Of_Service:"Zerbitzu-baldintzak",
  Terms_Of_Use:"Erabilera baldintzak",
  Refunds:"Itzulketa-politika",
  Single_Print:"1 Egiaztatu",
  Two_Prints:"2 Txekeak",
  Five_Prints:"5 Egiaztapenak",
  Ten_Prints:"10 txekeak",
  Fifteen_Prints:"15 Txekeak",
  Twenty_Prints:"20 txekeak",
  Thirty_Prints:"30 txekeak",
  Image_Added:"Irudia gehitu da",
  Image_Preview:"Irudiaren aurrebista",
  No_Image_Was_Selected:"Ez da irudirik hautatu.",
  Cheques_Unlimited:"Txekeak mugagabeak",
  _Subscription:"Harpidetza",
  Subscription:"Txekeak - Hilabete 1",
  Two_Month_Subscription:"Txekeak - 2 hilabete",
  Three_Month_Subscription:"Txekeak - 3 hilabete",
  Six_Month_Subscription:"Txekeak - 6 hilabete",
  Twelve_Month_Subscription:"Txekeak - 12 hilabete",
  Cheques_Are_Available:"Txekeak inprimatzeko eskuragarri daude.",
  Upgrade_Required_Two:"Hautatu pakete bat eta sakatu bi aldiz prezioaren botoia zure erosketa hasteko. Inprimatu itxura profesionaleko kolore osoko txekeak segundotan.",
  Month:"Hilabetea",
  Due:"Epea:",
  Expires:"Iraungitzea:",
  Subscription_Active:"Harpidetza aktiboa",
  Subscription_Inactive:"Harpidetza Inaktibo",
  Purchase_Additional_Cheques:"Txeke gehigarriak erosi nahi dituzu?",
  Printable_Cheque:"Txeke inprimagarria",
  Printable_Cheques:"Txeke inprimagarriak",
  Printable_Cheque_Word:"txekea eskuragarri dago zure kontuan.",
  Printable_Cheques_Word:"txekeak eskuragarri daude zure kontuan.",
  Max_Amount_Message:"Zehaztu duzun zenbatekoa sistema honetarako ezarritako gehienezko zenbatekoaren gainetik iritsi da:",
  Terms_Required_Word:"Akordio hau onartu behar duzu Txekeak erabiltzen jarraitu aurretik.",
  Subscriptions:"Harpidetzak",
  Product_Upgrades:"Berrikuntzak",
  Mailed_Out_Cheques:"Posta bidez bidalitako txekeak",
  Enter_A_Company_Name:"Mesedez, idatzi enpresaren izena.",
  Single_Cheques:"Txeke bakarrak",
  Cheque_Golden:"Urrezko Txekea",
  Cheque_Golden_Window:"Urrezko txeke diseinua.",
  Cheque_Green:"Txeke berdea",
  Cheque_Green_Window:"Txeke berdearen diseinua.",
  Cheque_Red:"Txeke gorria",
  Cheque_Red_Window:"Txeke gorrien diseinua.",
  Cheque_Yellow:"Txeke horia",
  Cheque_Yellow_Window:"Txeke horiaren diseinua.",
  Cheque_Statue_of_Liberty:"Askatasunaren Estatua",
  Cheque_Statue_of_Liberty_Window:"Askatasunaren Estatua txekearen diseinua.",
  Cheque_Green_Wave:"Olatu Berdea",
  Cheque_Green_Wave_Window:"Txeke berdearen diseinua.",
  Cheque_Golden_Weave:"Urrezko Ehundura",
  Cheque_Golden_Weave_Window:"Urrezko koadroen diseinu dotorea.",
  Cheque_Green_Sun:"Eguzki Berdea",
  Cheque_Green_Sun_Window:"Txekearen diseinu sakona eta lasaigarria.",
  Cheque_Blue_Checkers:"Dama Urdinak",
  Cheque_Blue_Checkers_Window:"Check urdinaren diseinua.",
  Cashiers_Check:"Kutxazainaren Txekea",
  Cashiers_Check_Window:"Kutxazainaren Txekearen estiloaren txantiloia.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Aqua check diseinua.",
  Cheque_Golden_Checkers:"Urrezko Damak",
  Cheque_Golden_Checkers_Window:"Urrezko txeke diseinua.",
  Upgrade_Unavailable:"Bertsioak ez daude erabilgarri",
  Bank_Code_Protection:"Banku Zenbakien Babesa",
  Bank_Code_Protection_Word:"Babestu zure banku-zenbakiak beste gailu batean exekutatzen ari den aplikazio honetan edo beste erabiltzaile batek erabil ez ditzan. Ekintza hau ATZERAEZINA da. Jarraitu nahi duzu?",
  Bank_Code_Protection_Blocked_Word:"Erabiltzen saiatzen ari zaren banku-zenbakiak beste erabiltzaile edo gailu baterako gordeta daude.",
  Bank_Code_Protection_Error_Word:"Zenbakiaren egiaztapenak huts egin du. Konektatu Internetera eta saiatu berriro banku-kontu hau gehitzen.",
  Bank_Code_Protection_Set_Error_Word:"Banku-zenbakien babesak Internetera konektatuta egotea eskatzen du. Konektatu eta saiatu berriro.",
  Upgrade_Unavailable_Word:"Barkatu, arazoak izaten ari gara zu egiaztatzeko. Une honetan, Checks-en harpidetzak eta bertsio-berritzeak ezin dira erosi zure inguruan.",
  PayPal_Payment_Preview:"PayPal ordainketaren aurrebista",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Hautatu PayPal",
  PayPal_Applications:"PayPal aplikazioak",
  Purchase_With_Apple_Pay:"Erosi Apple Pay-rekin",
  Google_Pay:"Google Pay",
  Companies:"Enpresak",
  Insurance:"Aseguruak",
  New_PayPal:"PayPal berria",
  Pay_By:"Ordaindu",
  Insure:"Aseguratu",
  Miles:"Kilometroak",
  Payment_Method:"Ordainketa-metodoa",
  Select_Policies:"Hautatu Politikak",
  Policies:"Politikak",
  Policy:"Politika",
  No_PayPal_Account:"Ez dago PayPal konturik",
  No_Policies:"Aseguru Polizarik ez",
  New_Policy:"Politika berria",
  PayPal_Payment:"PayPal Ordainketa",
  PayPal_Payments:"PayPal Ordainketak",
  No_Payment_Selected:"Ez da ordainketarik hautatu",
  Sending_Payment_Word:"Mesedez, itxaron... Ordainketa hau bidaltzen ari da.",
  Sending_Payments_Word:"Mesedez, itxaron... Ordainketak bidaltzen ari dira.",
  No_Payment_Selected_PayPal:"Ez da hautatu bidaltzeko <a routerLink='/folder/Payments'>PayPal ordainketa</a>.",
  Payment_Sent:"Ordainketa bidalita",
  PayPal_Payment_Sent:"Ordainketa hau PayPal-ekin bidali da.",
  Copay:"Kopurua",
  Dead:"Hildakoak",
  Alive:"Bizirik",
  Not_Dead:"Hilik ez",
  Unclaimed:"Erreklamatu gabekoa",
  Attempted:"Saiakerak",
  Deceased:"Hildakoa",
  Claimed:"Erreklamatua",
  Unpaid:"Ordaindu gabe",
  Sending_Payment:"Ordainketa bidaltzea",
  Sending_Payments:"Ordainketak bidaltzea",
  Send_PayPal_Confirmation:"Transakzio hau PayPal-ekin bidali nahi duzu?",
  Send_PayPal_Confirmation_Word:"Sakatu botoi berdea transakzio hau bidaltzeko.",
  Save_Payment_As_Unpaid:"Ordainketa ordaindu gabeko gisa gorde nahi duzu?",
  Payment_Pay_Confirmation_PayPal:"Ordainketa hau ordainduta gorde nahi duzu?",
  No_Policies_Word:"Gehitu orain lehen <a routerLink='/folder/Postage/New'>aseguru-poliza</a>.",
  Timesheet_Multiple_Delete_Confirmation:"Ziur ordu-orri bat baino gehiago ezabatu nahi dituzula?",
  Select_Multiple_Timesheets_Prompt:"Ez dago ordutegirik hautatu. Hautatu gutxienez ordu-orri bat.",
  Select_Multiple_Policies_Prompt:"Ez da politikarik hautatu. Hautatu gutxienez aseguru-poliza bat.",
  Policies_Multiple_Delete_Confirmation:"Ziur hainbat gidalerro ezabatu nahi dituzula?",
  Company:"Enpresa",
  Add_Company:"Gehitu Enpresa",
  New_Company:"Gehitu Enpresa",
  No_Companies:"Enpresarik ez",
  Enable_Company:"Gaitu Enpresa",
  Select_Company:"Hautatu Enpresa",
  The_Default_Company:"Enpresaren etiketa lehenetsia.",
  Manage_Companies:"Enpresak kudeatu",
  No_Companies_Word:"Egiaztapenek lehenetsitako enpresa bat erabiliko dute .<br />Gehitu <a routerLink='/folder/Company/New'>lehen konpainia</a>.",
  Default_Company:"Enpresa lehenetsia",
  Cheques_Unlimited_Word:"Txeke mugagabeak nahi adina txeke inprimatzeko aukera ematen du.",
  Cheques_Subscription_Word:"Txekeen harpidetzak nahi adina txeke inprimatzeko aukera ematen du.",
  You_Own_This_Product:"Produktu honen jabea zara.",
  Your_Subscription_is_Active:"Zure harpidetza aktibo dago.",
  Active_Products:"Aktibatutako produktuak",
  Purchase_Confirmation:"Erosketa",
  Purchase_Cheques:"Erosketa Txekeak",
  Restore_Purchase:"Berreskuratu Erosketak",
  Erase_Purchase:"Ezabatu Erosketak",
  Successfully_Purchased:"Arrakastaz erosi da",
  Enter_Your_Licence_Key:"Mesedez, idatzi zure lizentzia-gakoa orri honetan produktu hau aktibatzeko.",
  Licence_Key:"Lizentzia-gakoa",
  Enter_Licence_Key:"Sartu lizentzia-gakoa",
  Purchased:"Erosia",
  Enable_Feature:"Gaitu eginbidea",
  Cancel_Subscription:"Utzi harpidetza",
  Subscription_Removed:"Harpidetza kendu da",
  Select_Active_Subscription:"Hautatu harpidetza aktibo bat aldatzeko.",
  Remove_Subscription_Word:"Ziur harpidetza bertan behera utzi nahi duzula?",
  Delete_Company_Confirmation:"Ziur enpresa osoa ezabatu nahi duzula? OHARRA: gordetako informazio guztia galduko duzu!",
  Delete_Default_Company_Word:"Ezin duzu enpresa lehenetsia ezabatu. Aplikazioa berrezarri eta lehenetsitako egoerara berreskuratu nahi duzu? OHARRA: gordetako informazio guztia galduko duzu!",
  Console_Warning_2:"Ez kudeatu zurea ez den aplikazio hau erabiliz monetarik.",
  Terms_and_Conditions:"Baldintzak",
  and_the:"eta",
  for:"rentzat",
  Please_Read_Word:"Mesedez, irakurri eta onartu",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Ezin izan dira aurkitu moneta bihurketa-tasa batzuk. Mesedez, konektatu Internetera.",
  Free:"Doan",
  DB_Erase_Prompt_2:"Garatzaileen datu-base osoa guztiz ezabatu? OHARRA: EROSKETA eta HARPIDETZA informazio guztia galduko duzu!",
  Successfully_Imported:"Behar bezala inportatu da",
  Select_Postage:"Hautatu Posta",
  No_Postage:"Posta-zigiluak ez",
  No_Paid_Postage_Word:"Gehitu lehen <a routerLink='/folder/Postage/New'>Ordaindutako Posta</a> zigilua.",
  Trial_Complete:'Proba amaitu',
  Please_Upgrade:'Mesedez, eguneratu Txekeak inprimatzen jarraitzeko.',
  Aa:"Aa",
  Color:"Kolore",
  Font:"Letra-tipoa",
  Guide:"Gida",
  Guides:"Gidak",
  Image:"Irudia",
  Zoom:"Zoomatu",
  Logo:"Logotipoa",
  Bank:"Bankua",
  MICR:"MICR",
  Total:"Guztira",
  Cancel:"Utzi",
  Confirm:"Berretsi",
  Method:"Metodoa",
  Biometric_Security:"Segurtasun biometrikoa",
  Please_Login_To_Continue:"Mesedez, hasi saioa jarraitzeko.",
  Complete:"Osatu",
  Sign_Up:"Izena eman",
  Error:"Errorea",
  Biometrics:"Biometria",
  Percent:"Ehunekoa",
  Zero_Percent:"%0",
  Top_Margin:"Goiko Marjina",
  Bottom_Margin:"Beheko Marjina",
  Left_Margin:"Ezkerreko Marjina",
  Right_Margin:"Eskuineko Marjina",
  MICR_Margin:"MICR Marjina",
  Table_Margin:"Taula Marjina",
  Address_Margin:"Helbide Marjina",
  Percentage_:"Ehunekoa",
  Vacation_Title:"Oporren Izenburua",
  Use_PIN:"Erabili PINa",
  Loading__:"Kargatzen...",
  Design_Title:"Diseinuaren Izenburua",
  Authorize:"Baimena eman",
  Key:"Gakoa",
  Public_Key:"Gako publikoa",
  Private_Key:"Gako pribatua",
  Authenticate:"Egiaztatu",
  Last_Payroll:"Azken nomina",
  Last_Calculation:"Azken kalkulua",
  Authorized:"Baimenduta",
  Geo_Authorized:"Geo-kokapena: Baimenduta",
  Not_Authorized:"Ez Baimenik",
  Authorization_Complete:"Baimena osatuta",
  Geolocation_Authorization:"Geokokapenerako Baimena",
  Out_of_Bounds:"Mugetatik kanpo",
  Cant_Delete_Default_Design:"Ezin da diseinu lehenetsi bat ezabatu.",
  Unauthorized_Out_of_Bounds:"Baimenik gabe: Mugetatik kanpo",
  Biometric_Authorization:"Baimen biometrikoa",
  Locating_Please_Wait:"Bilatzen, itxaron mesedez...",
  Test_Biometrics:"Proba biometrikoak",
  Cheque_Margins:"Egiaztatu Marjinak",
  Percentage_Full_Error:"Ehuneko eremua ezin da ehuneko 100etik gora ezarri.",
  No_Payroll_Text:"Gehitu <a routerLink='/folder/Employee/New'>Langilea</a> edo <a routerLink='/folder/Payee/New'>Ordenatzailea</a> eta <a routerLink='/folder/Schedule/New'>Ordutegia</a>.",
  Design_Saved:"Diseinua gordeta",
  Default_Design_Selected:"Diseinu lehenetsia hautatuta",
  Partial_Import:"Inportazio partziala",
  Partial_Export:"Esportazio partziala",
  Entire_Import:"Inportazio osoa",
  Entire_Export:"Esportazio osoa",
  Existing_Password:"Mesedez, sartu lehendik duzun pasahitza:",
  Existing_PIN:"Mesedez, sartu lehendik duzun PIN kodea:",
  Pin_Change_Header:"PIN berrespena",
  Pin_Change_SubHeader:"Sartu zure PIN zenbaki zaharra aldaketa berresteko.",
  Pass_Change_Header:"Pasahitza berrespena",
  Pass_Change_SubHeader:"Idatzi pasahitz zaharra aldaketa berresteko.",
  PIN_Enter_Message:"Sartu PINa berresteko.",
  Password_Enter_Message:"Sartu zure pasahitza berresteko.",
  Pin_Updated_Success:"PINa behar bezala eguneratu da!",
  Pin_Updated_Fail:"Ezin izan da PINa eguneratu.",
  Pass_Updated_Success:"Pasahitza behar bezala eguneratu da.",
  Pass_Updated_Fail:"Ezin izan da pasahitza eguneratu.",
  Preview_Payment:"Aurreikusi ordainketa",
  Preview_Payroll:"Aurreikusi nominak",
  No_Payments_Created:"Ez da aurkitu ordainketarik sortu zenik.",
  Payment_Preview:"Ordainketa aurrebista",
  Enable_Payee:"Gaitu hartzailea",
  Rendered:"Errendatua",
  No_Email:"Ez dago posta elektronikorik",
  Setup_Cheques:"Konfigurazio egiaztapenak",
  name:"Izena",
  address:"Helbidea",
  address_2:"Helbide-lerroa 2",
  city:"Hiria",
  province:"Probintzia",
  postal_code:"Posta Kodea",
  country:"Herrialdea",
  username:"Erabiltzaile izena",
  full_name:"Izen osoa",
  birthday:"Urtebetetzea",
  email:"Posta elektronikoa",
  phone:"Mugikorra",
  employees:"Langileak",
  addresses:"Helbideak",
  payment_amount_limit:"Ordainketa zenbatekoaren muga",
  total_limit:"Muga osoa",
  payees:"Ordainduak",
  description:"Deskribapena",
  address_line_one:"Helbide lerro bat",
  address_line_two:"Helbidearen bigarren lerroa",
  image:"Irudia",
  account_holder:"Kontuaren titularra",
  cheque_starting_id:"Egiaztatu hasierako ID",
  transit_number:"Garraio Zenbakia",
  institution_number:"Erakundearen zenbakia",
  designation_number:"Izendapen Zenbakia",
  account_number:"Kontu zenbakia",
  currency:"Moneta",
  signature:"Sinadura",
  payment_payroll_limit:"Ordainketa Muga",
  total_limi:"Muga osoa",
  date:"Data",
  printed_memo:"Inprimatutako Oharra",
  banks:"Bankuak",
  signature_image:"Sinadura Irudia",
  address_name:"Helbidea Izena",
  notes:"Oharrak",
  design:"Diseinua",
  title:"Izenburua",
  frequency:"Maiztasuna",
  fax:"Faxa",
  salaries:"Soldatak",
  salary_ids:"Soldata IDak",
  start_time:"Hasiera Ordua",
  end_time:"Amaiera Ordua",
  paid:"Ordaindua",
  overtime_percentage:"Ordaindutako ehunekoa",
  overtime_amount:"Ordaindutako Zenbateko Finkoa",
  first_name:"Izena",
  last_name:"Abizena",
  moderation:"Moderazioa",
  create:"Sortu",
  edit:"Editatu",
  destroy:"Suntsitu",
  day_start_time:"Eguna_hasiera_ordua",
  day_end_time:"Eguna_amaiera_ordua",
  fullname:"Izena",
  time:"Denbora",
  auto_send:"Bidali automatikoki",
  time_method:"Denbora Metodoa",
  schedules:"Ordutegiak",
  indefinite_payroll_enabled:"Gaitu mugagabean",
  amount:"Zenbatekoa",
  repeat:"Errepikatu",
  always_enabled:"Beti gaituta",
  start_date:"Hasiera data",
  end_date:"Amaiera-data",
  Cheque_Total:"Egiaztatu Guztira",
  Total_Amount:"Kopuru osoa:",
  Amounts:"Zenbatekoak:",
  Images:"Irudiak",
  Files:"Fitxategiak",
  Previewing:"Aurreikuspena:",
  Insert:"Txertatu",
  Preview_Import:"Aurrebista inportazioa",
  Entry:"Sarrera",
  Entries:"Sarrerak",
  No_Entries:"Ez dago sarrerarik",
  Import_Type:"Inportazio mota",
  Select_Details:"Hautatu Xehetasunak",
  Select_Payee:"Hautatu Ordainatzailea",
  Enable_Holidays:"Gaitu Oporrak",
  Disable_Holidays:"Desgaitu oporrak",
  Wire_Transfer:"Alanbre-transferentzia",
  New_Export:"Esportazio berria",
  Export_Data:"Esportatu datuak",
  Export_Data_Word:"Hautatu esportatu eta deskargatu nahi duzun fitxategi mota.",
  Export_File:"Esportatu fitxategia",
  Mode:"Modu",
  Times:"Garaiak",
  Widgets:"Widget-ak",
  Slider:"Graduatzailea",
  Set_Details:"Ezarri xehetasunak",
  Select_Type:"Hautatu Mota",
  Display_Slider:"Bistaratu graduatzailea",
  Dashboard_Slider:"Arbelaren graduatzailea",
  Dashboard_Mode:"Arbel modua",
  Show_Intro:"Erakutsi sarrera",
  Show_Payrolls:"Erakutsi nominak",
  Show_Holidays:"Erakutsi oporrak",
  Show_Invoices:"Erakutsi fakturak",
  Show_Cheques:"Erakutsi txekeak",
  Enabled_Widgets:"Gaitutako widgetak",
  Disabled_Widgets:"Desgaitutako widgetak",
  Colors:"Koloreak",
  Barcodes:"Barra-kodeak",
  View_Timers:"Ikusi tenporizadoreak",
  Gradients:"Gradienteak",
  No_Info:"Ez dago informaziorik",
  Disable:"Desgaitu",
  Show_Layer:"Erakutsi geruzak",
  Hide_Layer:"Ezkutatu geruzak",
  Text_Layer:"Testu-geruzak",
  Secondly:"Bigarrenik",
  Minutely:"Minutu batean",
  nine_am:"9:00etan",
  five_pm:"17:00etan",
  Enable_Address:"Gaitu Helbidea",
  Invalid_File_Type:"Barkatu, fitxategi mota baliogabe bat hautatu da. Onartutako fitxategi mota:",
  Error_Updating_Entry:"Errore bat gertatu da sarrera hau eguneratzean.",
  Schedule_Timing_Alert:"Errorea: programazioaren hasiera-ordua amaiera-orduaren ondorengo balio batean ezartzen da.",
  Select_Multiple_Payments_Prompt:"Ez dago ordainketarik hautatu. Hautatu gutxienez ordainketa bat.",
  Select_Multiple_Cheques_Prompt:"Ez da txekerik hautatu. Hautatu gutxienez txeke bat.",
  Select_Multiple_Items_Prompt:"Ez da elementurik hautatu. Hautatu gutxienez elementu bat.",
  Paymemt_Multiple_Delete_Confirmation:"Ziur ordainketa anitz ezabatu nahi dituzula?",
  Cheque_Multiple_Delete_Confirmation:"Ziur txeke bat baino gehiago ezabatu nahi dituzula?",
  Payee_Multiple_Delete_Confirmation:"Ziur hartzaile anitz ezabatu nahi dituzula?",
  Employee_Multiple_Delete_Confirmation:"Ziur hainbat langile ezabatu nahi dituzula?",
  MICR_Warning:"Oharra: baliteke inprimagailu eta gailu batzuek MICR letra-tipoa behar bezala ez bistaratzea.",
  Automatically_Send:"Bidali automatikoki",
  Type:"Mota",
  Payment_Type:"Ordainketa Mota",
  Auto_Send:"Bidalketa automatikoa",
  Automatically_Process:"Automatikoki prozesatu",
  Auto_Process:"Prozesu automatikoa",
  Image_Based:"Irudietan oinarrituta",
  Font_Based:"Letra-tipoetan oinarrituta",
  Rerender:"Berriro errendatu",
  Rendering:"Errendatzea",
  Render:"Fitxategiak",
  Top:"Goiena",
  Middle:"Erdikoa",
  Bottom:"Behean",
  Top_Left:"Goiko Ezkerrean",
  Top_Center:"Goiko erdigunea",
  Top_Right:"Goian Eskuinekoa",
  Middle_Left:"Erdi Ezkerra",
  Middle_Center:"Erdiko Zentroa",
  Middle_Right:"Erdiko Eskuin",
  Bottom_Left:"Beheko Ezkerrean",
  Bottom_Center:"Beheko Erdian",
  Bottom_Right:"Behean Eskuinean",
  Numbers:"Zenbakiak",
  Verified:"Egiaztatuta",
  Paper_Size:"Paperaren Tamaina",
  New_Device:"Gailu berria",
  Add_Device:"Gehitu gailua",
  Remove_Device:"Kendu gailua",
  Delete_Device:"Ezabatu gailua",
  Block_Device:"Blokeatu gailua",
  Block:"Blokea",
  Unblock:"Desblokeatu",
  Table:"Taula",
  Scan_Login_Code:"Eskaneatu saioa hasteko kodea",
  Login_Code:"Saioa hasteko kodea",
  Scan_Code:"Eskaneatu kodea",
  Scan_QR_Code:"Eskaneatu QR kodea",
  Select_All:"Hautatu guztiak",
  Deselect_All:"Deshautatu Guztiak",
  Increase:"Handitu",
  Decrease:"Gutxitu",
  Bold:"Lodia",
  Text:"Testua",
  Style:"Estiloa",
  Italic:"Etzana",
  QR_Code:"QR kodea",
  Barcode:"Barra-kodea",
  Browse_Images:"Arakatu Irudiak",
  Browse_Files:"Arakatu fitxategiak",
  Background_Image:"Atzeko planoko irudia",
  Logo_Image:"Logotipoaren irudia",
  Header_Image:"Goiburuko irudia",
  Bank_Image:"Banku Irudia",
  Cut_Lines:"Moztu Lerroak",
  Background:"Aurrekariak",
  License:"Lizentzia",
  One_License:"1 Lizentzia:",
  Licensed:"Lizentziatua:",
  Not_Licensed:"Lizentziarik gabe",
  Enter_Serial:"Sartu Seriea",
  Serial_Key:"Serie-gakoa",
  Serial:"Seriekoa",
  Product_Key:"Produktuaren gakoa",
  Check_Product_Key:"Egiaztatu produktuaren gakoa",
  Add_Product_Key:"Gehitu produktu-gakoa",
  Verifying_Purchase:"Erosketa egiaztatzen...",
  Print_Envelope:"Inprimatu gutun-azala",
  Envelope:"Gutunazala",
  Thank_You:"Eskerrik asko!",
  Scale:"Eskala",
  Print_Scale:"Inprimatu Eskala",
  Borders:"Mugak",
  VOID:"HUTSAK",
  Void_Cheque:"Hutsaren egiaztapena",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ORDAINDU ESKAERA:",
  NO_DOLLARS:"DOLARI EZ ",
  ONE_DOLLAR:"DOLAR BAT",
  DOLLARS:" DOLARRAK",
  AND:" ETA ",
  CENTS:" ZENTIMOAK.",
  NO_:"EZ ",
  ONE_:"BAT ",
  AND_NO_:"ETA EZ ",
  _AND_ONE_:"ETA BAT ",
  DOLLARS_AND_ONE_CENT:" ETA ZENIMO BAT.",
  AND_NO_CENTS:" ETA ZERO ZENIMO.",
  CHEQUE_PRINT_DATE:"DATA:",
  CHEQUE_PRINT_MEMO:"MEMORIA:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Hasierako zereginak",
  Inital_Setup:"Hasierako konfigurazioa",
  Welcome_To:"Ongi etorria ",
  Welcome:"Ongi etorri",
  Swipe:"Irrastatu",
  Intro_Setup:"Sarrera konfigurazioa",
  Introduction:"Sarrera",
  CHEQUE_PRINT_CREDIT:"Txekeek bultzatuta",
  Title_Intro_Word:"Ongi etorri Txekeetara",
  Title_Intro:"Txekeen Sarrera",
  Title_Setup:"Txekeen konfigurazioa",
  PayPal_Default_Email_Message:"Paypal transferentzia berri bat jaso duzu. [Txekeen bidez]",
  Cheques_App_Export:"Txekeen bidez esportatua",
  Post_Intro_Thanks:"Eskerrik asko Txekeak aukeratzeagatik. Konfigurazio prozesua amaitu da.",
  Post_Intro_Word:"Hasi zure lehen txekea inprimatzen, gehitu etorkizuneko ordainketa bat edo gehitu langile bat nominan",
  Upgrade_Required:"Txekeek softwarearen bertsio premiumago baten jabe izatea eskatzen dute mezu hau ezkutatzeko eta eginbide gehigarriak desblokeatzeko",
  Upgrade_Title:"Txekeak",
  Mailout_Prompt:"Gainera, Txekeak zure nomina-txekeak posta elektronikoz bidaltzea aukera dezakezu.",
  Mailed_Cheque:"Postaz bidalitako txekea: ",
  Mailed_Cheque_Color:"Postaz bidalitako txekea (kolorea): ",
  Terms_Purchase:"Txekeekin egindako erosketa elektroniko guztiak guztiz itzultzen dira erosketa-datatik 30 eguneko epean. Mesedez, irakurri eta onartu <a href='#'>Zehaztapenak eta Baldintzak</a> jarraitu aurretik.",
  Dashboard_Setup:"Konfiguratu inprimagailu nagusia",
  Dashboard_Add:"Gehitu banku-kontu nagusia",
  Dashboard_Customize:"Hautatu egiaztapen-txantiloia",
  Dashboard_Job_Salary:"Sortu zure lana eta gehitu zure soldata",
  Dashboard_Employees:"Langileen eta ordainsarien jarraipena",
  Dashboard_Print:"Inprimatu eta bidali zure soldata-txekeak",
  Dashboard_Payroll:"Automatizatu zure nominak inprimatzea",
  Dashboard_PayPal:"Konfiguratu PayPal nomina / ordainketak",
  Begin_Setup:"Hasi konfigurazioa",
  Get_Started:"Hasi",
  Purchase:"Erosi",
  Lockdown:"Konfinamendu",
  Unlock:"Desblokeatu",
  Detailed:"Xehatza",
  Log_In:"Saioa hasi",
  Login:"Saioa hasi",
  Launch:"Abian jarri",
  Register:"Erregistratu",
  Finish:"Amaitu",
  List:"Zerrenda",
  Weekends:"Asteburuak",
  Weekly:"Asteko",
  PayPal_Default_Subject:"Ordainketa berria",
  Payment_Message:"Ordainketa-mezua",
  PayPal_Default_Payment_Note:"Eskerrik asko",
  Setup_Your_Cheqing_Account:"Kontua korrontea",
  Add_Your_Primary_Cheqing_Account:"Gehitu zure kontu korronte nagusia.",
  Welcome_Word:"Sinplifikatu eta automatizatu nominak eta giza baliabideen kudeaketa",
  Get_Started_Quickly:"Hasten lagunduko diguten galdera erraz batzuei erantzun besterik ez dago...",
  Done_Intro_Word:"Konfigurazioa osatuta",
  PIN_Protected:"Pasahitza eta PIN babestua",
  Enter_New_PIN:"Sartu PIN kode berri bat:",
  Enter_PIN:"Sartu PIN kodea:",
  Invalid_PIN:"PIN baliogabea sartu da.",
  Account_Identifier:"Kontuaren identifikatzailea",
  New_Account_Identifier:"Kontu identifikatzaile berria",
  attempt:"saiakera",
  attempts:"saiakerak",
  remaining:"gehienak",
  Language:"Hizkuntza",
  languages:"Hizkuntzak",
  select_languages:"Hautatu hizkuntza",
  Deposit:"gordailua",
  Hire_One_Now:"Kontratatu bat orain",
  App_Locked:"Aplikazioa blokeatuta dago.",
  Skip_:"Saltatu",
  Skip_to_Dashboard:"Saltatu Arbelera",
  Dashboard:"Arbela",
  Import:"Inportatu",
  Admin:"Administratzaileak",
  New_Admin:"Administratzaile berria",
  Settings:"Ezarpenak",
  Color_Picker:"Kolore-hautatzailea",
  Font_Picker:"Letra-tipo-hautatzailea",
  Logout:"Saioa amaitu",
  Close:"Itxi",
  Close_menu:"Itxi",
  Excel:"Excel fitxategia",
  Csv:"CSV fitxategia",
  Sql:"SQL fitxategia",
  Json:"JSON fitxategia",
  Url:"Inportatu URL bidez",
  Back:"Itzuli",
  Timers:"Tenporizadoreak",
  Cheque:"Egiaztatu",
  Print:"Inprimatu",
  Designs:"Diseinuak",
  Pause_Printing:"Pausatu inprimaketa",
  Resume_Printing:"Berrekin inprimatzea",
  Printing_Paused:"Inprimatzea pausatuta",
  PrintingUnavailable:"Barkatu! Sistema honetan inprimatzea ez dago erabilgarri.",
  Prints_Paused:"Inprimaketak pausatuta",
  Administration:"Administrazioa",
  Loading:"Kargatzen",
  Unnamed:"Izenik gabe",
  error:"Barkatu, ezin izan da txeke hau ireki ikusteko.",
  No_Cheques_To_Print:"Ez dago txekerik inprimatzeko",
  No_Cheques_To_Print_Word:"Sortu <a routerLink='/folder/Cheque/New'>Txeke berria</a>.",
  New_Cheque:"Txeke berria",
  Start_One_Now:"Hasi orain bat",
  Edit_Cheque:"Editatu egiaztapena",
  Select_Cheques:"Hautatu egiaztapenak",
  Select_Employee:"Hautatu langilea",
  Default_Printer:"Inprimagailu lehenetsia",
  Reassign:"Esleitu",
  Configure:"Konfiguratu",
  Template:"Txantiloia",
  Designer:"Diseinatzailea",
  Edit_Designs:"Editatu diseinuak",
  New_Design:"Diseinu berria",
  Next:"Hurrengoa",
  Save:"Gorde",
  Name:"Izena",
  Mail:"Posta",
  Amount:"Kopurua",
  Date:"Data",
  PayPal:"PayPal",
  Payouts:"Ordainketak",
  PayPal_Label:"PayPal etiketa",
  Email_Username:"E-posta / Erabiltzaile izena",
  Client_ID:"Bezero ID",
  Sandbox_Email:"Sandbox posta elektronikoa",
  PayPal_Email:"PayPal posta elektronikoa",
  PayPal_Username:"API erabiltzaile-izena",
  PayPal_Payouts:"PayPal ordainketak",
  Select_PayPal_Key:"Hautatu PayPal gakoa",
  Secret:"Sekretua",
  API_Secret:"API sekretua",
  PayPal_API_Keys:"PayPal giltzak",
  New_PayPal_Key:"Paypal gako berria",
  Email_Subject:"E-posta gaia",
  Email_Message:"Mezu elektronikoa",
  Payout_Options:"Ordaintzeko aukerak",
  Payment_Note:"Ordainketa-oharra",
  Enable_Employee:"Gaitu langilea",
  Enable_Production_Mode:"Gaitu ekoizpen modua",
  Sandbox_Username:"Sandbox erabiltzaile-izena",
  Sandbox_Signature:"Sandbox sinadura",
  Sandbox_Password:"Sandbox pasahitza",
  Production_Username:"Produkzioaren erabiltzaile-izena",
  Production_Signature:"Ekoizpen sinadura",
  Production_Password:"Ekoizpen pasahitza",
  Production_Email:"Ekoizpen posta elektronikoa",
  API_Client_ID:"API bezeroaren IDa",
  API_Signature:"API sinadura",
  API_Password:"API pasahitza",
  API_Username:"API erabiltzaile-izena",
  Secret_Key:"Gako sekretua",
  Sandbox:"Sandbox",
  Production:"Ekoizpena",
  Sandbox_Keys:"Sandbox teklak",
  Production_Keys:"Ekoizpen gakoak",
  API_Title:"API titulua",
  Production_Mode:"Ekoizpen modua",
  Account_Label:"Kontuaren etiketa",
  No_PayPal_Setup:"Paypal gakorik ez",
  Enable_PayPal_Account:"Gaitu PayPal kontua",
  No_PayPal_Word:"Gehitu zure <a routerLink='/folder/Invoice/New'>PayPal API gakoa</a>.",
  Printed_Memo:"Ohar inprimatua",
  Employee:"Langilea",
  View_Employee:"Ikusi langilea",
  Mailing_Address:"Posta helbidea",
  Company_Address:"Enpresaren helbidea",
  Select_Company_Address:"Hautatu enpresaren helbidea",
  Address:"Helbidea",
  Any_Day:"Edozein egun",
  Address_Name:"Helbidearen izena",
  Unit:"Unitatea",
  Account:"Kontua",
  Bank_Account:"Banku-kontua",
  Account_Limits:"Gaitu kontuaren mugak",
  Payroll:"Nomina",
  New_Payroll:"Nomina berria",
  No_Payroll:"Datorren nominarik ez",
  Upcoming_Holiday:"Datorren oporrak:",
  Invoice_Due:"Faktura:",
  New_Invoice:"Faktura berria",
  No_Invoices:"Fakturarik ez",
  No_Invoices_Word:"Sortu lehen <a routerLink='/folder/Invoice/New'>Faktura</a>.",
  Cheque_Due:"Txekea:",
  Payrolls:"Nominak",
  Sandbox_Mode:"Sandbox modua",
  Hire:"kontratatu",
  Pay:"Ordaindu",
  New:"Berria",
  Add:"Gehitu",
  Make:"Egin",
  Time:"Denbora",
  Write:"Idatzi",
  Holiday:"Oporrak",
  Holidays:"Oporrak",
  Next_Holiday:"Hurrengo oporrak:",
  All_Done:"Dena eginda!",
  Employees:"Langileak",
  Payees:"Ordadunak",
  Job:"Lana",
  Jobs:"Lanpostuak",
  Invoice:"Faktura",
  Invoices:"Fakturak",
  Vacations:"Oporrak",
  Cheques:"Txekeak",
  Brand_Cheques:"Marka",
  Payment:"Ordainketa",
  Enable_Payment:"Gaitu ordainketa",
  Payments:"Ordainketak",
  Schedule:"Ordutegia",
  Schedules:"Ordutegiak",
  Timesheet:"Denbora-orria",
  Timesheets:"Denbora-orriak",
  Salary:"Soldata",
  New_Address:"Helbide berria",
  Address_2:"Helbidea (2. lerroa)",
  _City:"Hiria",
  _State:"Estatua/Prov",
  City:"Hiria / udalerria",
  State:"Estatua / Probintzia",
  Postal:"Posta / Postal Kodea",
  ZIP:"Posta / ZIP",
  Country:"Herrialdea",
  Addresses:"Helbideak",
  Required_Options:"Beharrezko aukerak",
  Optional_Options:"Aukerazko aukerak",
  Additional_Options:"Aukera gehigarriak",
  Required:"Beharrezkoa",
  Optional:"Hautazkoa",
  Additional:"Gehigarria",
  No_Addresses:"Helbiderik ez",
  New_Address_Word:"Gehitu lehen <a routerLink='/folder/Address/New'>Helbidea</a>.",
  Select_Address:"Hautatu helbidea",
  No_Address:"Helbiderik ez",
  Print_Cheque:"Inprimatu egiaztapena",
  Print_Cheque_Now:"Inprimatu Egiaztatu orain",
  Description:"Deskribapena",
  Pay_To_The_Order_Of:"Ordaindu:-ren aginduari",
  Select_Date_Range:"Hautatu data tartea",
  Select_Starting_Date:"Hautatu hasierako data",
  Select_Ending_Date:"Hautatu amaiera data",
  Select_Date:"Hautatu data",
  Cheque_Date:"Egiaztatu data",
  Cheque_Memo:"Egiaztatu oharra",
  Blank_Cheque:"Txeke hutsa",
  Blank:"Hutsik",
  No_Cheques:"Txekerik ez",
  No_Cheques_Word:"Inprimatu zure lehen <a routerLink='/folder/Cheque/New'>Egiaztatu</a>.",
  Cheque_Amount_Placeholder:"0,00 $",
  View_Image:"Ikusi irudia",
  View:"Ikusi",
  Modify:"Aldatu",
  Delete:"Ezabatu",
  Cheque_Paid:"Ordaindua",
  New_Deduction:"Kenkari berria",
  Title:"Izenburua",
  Frequency:"Maiztasuna",
  Hourly:"Orduka",
  Daily:"Egunerokoa",
  Weekdays:"Astegunak",
  BiWeekly:"2 aste",
  TriWeekly:"3 aste",
  Monthly:"Hileroko",
  MiMonthly:"2 hilabete",
  Quarterly:"Hiruhilekoan",
  Yearly:"Urtero",
  Every_Week:"Astero",
  Every_Payroll:"Nomina bakoitza",
  Every_Month:"Hilero",
  Annually:"Urtero",
  Always_Scheduled:"Beti programatuta",
  Start_Date:"Hasiera data",
  End_Date:"Amaiera data",
  Start_Time:"Hasteko ordua",
  End_Time:"Amaiera garaia",
  Deduction_Label:"Dedukzioaren etiketa",
  Notes:"Oharrak",
  Options:"Aukerak",
  Enable:"Gaitu",
  Select_Deductions:"Hautatu kenkariak",
  Deductions:"Kenkariak",
  No_Deductions:"Kenkaririk gabe",
  No_Deductions_Word:"Sortu zure lehen <a routerLink='/folder/Deduction/New'>Kenkaria</a>.",
  New_Employee:"Langile berria",
  No_Title:"Izenbururik gabe",
  _Name:"Izena",
  About_Yourself:"Zure buruari buruz",
  Full_Name:"Izen osoa",
  Birthday:"Urtebetetzea",
  Email:"E-posta",
  SMS:"SMS",
  Phone:"Mugikorra",
  Test:"Proba",
  Call:"Deitu",
  Fax:"Faxa",
  Printed_Note:"Oharra inprimatua",
  Position:"Kokapena",
  Job_Position:"Lanpostua",
  Select_a_Job:"Hautatu lan bat",
  Select_a_Salary:"Hautatu soldata bat",
  Add_a_Deduction:"Gehitu kenkari bat",
  Taxes:"Zergak",
  Add_Taxes:"Gehitu zergak",
  Date_of_Birth:"Jaioteguna",
  Email_Address:"Posta elektroniko helbidea",
  Phone_Number:"Telefono zenbakia",
  Phone_Call:"Telefono dei",
  Enable_on_Payroll:"Gaitu nominan",
  Select_Employees:"Hautatu langileak",
  No_Employees:"Langilerik ez",
  No_Employees_Word:"Gehitu zure lehen <a routerLink='/folder/Employee/New'>Langile</a> berria.",
  No_Name:"Izenik ez",
  Unemployeed:"Langabezian",
  Employeed:"Enplegatua",
  Paid:"Ordaindua",
  Enabled:"Gaituta",
  Disabled:"Desgaituta",
  Fire:"Sute",
  Not_Available:"Ez dago erabilgarri",
  Not_Available_Word:"Inprimatu zure lehen <a routerLink='/folder/Invoice/New'>Faktura</a> eta jaso ordaindu.",
  Select_Invoices:"Hautatu_fakturak",
  Print_Invoice_Word:"Inprimatu zure lehen <a routerLink='/folder/Invoice/New'>Faktura</a> eta jaso ordaindu.",
  Invoice_Title:"Fakturaren izenburua",
  Invoice_Date:"Fakturaren data",
  Due_Date:"Epemuga data",
  New_Job:"Lan berria",
  Details:"Xehetasunak",
  Customize:"Pertsonalizatu",
  Customize_Dashboard:"Pertsonalizatu panela",
  Components:"Osagaiak",
  No_Components:"Osagairik ez",
  Main_Components:"Osagai nagusiak",
  Smaller_Components:"Osagai txikiagoak",
  Error_Loading_Page:"Errorea orri hau kargatzean.",
  Bank_Details:"Banku datuak",
  About_Your_Job:"Zure lanari buruz",
  Your_Schedule:"Zure ordutegia",
  Job_Title:"Lanpostuaren izena",
  Job_Description:"Lanaren deskribapena",
  Job_Details:"Lanaren xehetasunak",
  Enable_Job:"Gaitu lana",
  Pay_Period:"Ordainketa epea",
  Perpetually_Schedule:"Betiko programatu",
  Select_Jobs:"Hautatu lanak",
  No_Jobs:"Lanik ez",
  Add_Jobs:"Gehitu lanak",
  No_Jobs_Word:"Gehitu lehen <a routerLink='/folder/Job/New'>Lana</a> zerrendara.",
  Count_Employees:"job.employee_count+' Langileak'",
  Zero_Employees:"0 langile",
  New_Leave:"Uzte berria",
  Leave_Name:"Utzi izena",
  Paid_Leave:"Ordaindutako baimena",
  Leave_Pay:"Utzi ordaindu",
  Indefinite_Leave:"Lizentzia mugagabea",
  Indefinite_Payroll:"Nomina mugagabea",
  Leave:"Utzi",
  Add_Schedules:"Gehitu ordutegiak",
  Percentage:"%100",
  Enable_Leave_Of_Absence:"Gaitu absentzia",
  Select_Leaves:"Hautatu hostoak",
  No_Leaves:"Absentzia hostorik gabe",
  Leave_Of_Absence:"Bazentzia",
  Leaves_Of_Absence:"Absentzia-orriak",
  New_Leave_of_Absense:"Bazentzia berria",
  No_Leaves_Word:"Gehitu lehen <a routerLink='/folder/Leave/New'>Bazentzia</a>.",
  Not_Enabled:"Ez gaituta",
  Absences:"Ausentzia",
  Payee:"Ordenatzailea",
  New_Payee:"Ordenatzaile berria",
  Payee_Identifier:"Ordenatzailearen identifikatzailea",
  Payee_Name:"Ordenatzailearen izena",
  Payee_Title:"Ordenatzailearen titulua",
  Payment_Memo:"Ordainketa-oharra",
  Select_Payees:"Hautatu hartzailea",
  No_Payees:"Ordaindurik ez",
  Add_Payee_Note:"Gehitu lehen <a routerLink='/folder/Payee/New'>Ordenatzailea</a>.",
  New_Payees:"Ordaindu berriak",
  About_The_Payment_Schedule:"Ordainketa egutegia",
  Your_Payroll_Schedule:"Nomina automatikoa",
  New_Payment:"Ordainketa berria",
  Identifier:"Identifikatzailea",
  Select:"Hautatu",
  Memo:"Oharra",
  Payment_Date:"Ordainketa-data",
  Mark_as_Paid:"Markatu ordaindutako gisa",
  Select_Payments:"Hautatu Ordainketak",
  No_Payments:"Ordainketarik ez",
  No_Payments_Word:"Sortu lehen <a routerLink='/folder/Payment/New'>Ordainketa</a>.",
  Create_Payroll:"Sortu nomina",
  Properties:"Propietateak",
  Payroll_Title:"Nominaren titulua",
  Payroll_Notes:"Nominaren oharrak",
  Payroll_Setup:"Nominaren konfigurazioa",
  Tabulate_Payments:"Ordainketak taularatu",
  Tabulate:"Tabulatu",
  By:"Nork:",
  Payments_By:"Ordainketak",
  Timesheets_And_Schedules:"Ordutegiak eta ordutegiak",
  Both:"Biak",
  Items:"Elementuak",
  Add_Payees:"Gehitu ordaintzaileak",
  Add_Account:"Kontua gehitu",
  Enable_Account:"Gaitu kontua",
  Enable_Payroll:"Gaitu nomina",
  Print_Payroll:"Inprimatu nomina",
  No_Payrolls:"Nominarik ez",
  No_Payroll_Word:"Sortu zure lehen <a routerLink='/folder/Payroll/New'>Nomina</a>.",
  View_more:"IKUSI_GEHIAGO",
  Less:"GUTXIAGO",
  Add_Payroll:"Gehitu nomina",
  Select_Payroll:"Hautatu nomina",
  About_Your_Salary:"Zure soldatari buruz",
  Add_Salaries:"Gehitu soldatak",
  Add_Salary:"Gehitu soldata",
  Salaries:"Soldata",
  No_Salaries:"Soldarik ez",
  No_Salaries_Word:"Gehitu lehen <a routerLink='/folder/Salary/New'>Solda</a>.",
  Select_Salaries:"Hautatu soldatak",
  New_Salary:"Soldata berria",
  Salary_Name:"Soldata identifikatzailea",
  Enable_Salary:"Gaitu soldata",
  Salary_Amount:"Soldata zenbatekoa",
  New_Schedule:"Ordutegi berria",
  Schedule_Title:"Egitarauaren izenburua",
  Add_Address:"Gehitu helbidea",
  Repeat:"Errepikatu",
  Design:"Diseinua",
  Edit_Design:"Editatu diseinua",
  Edit_this_Design:"Editatu diseinu hau",
  Repeat_Payment:"Errepikatu ordainketa",
  Enable_Schedule:"Gaitu programazioa",
  Never:"Inoiz ez",
  Select_Schedule:"Hautatu ordutegiak",
  No_Schedules:"Ordutegirik ez",
  No_Schedules_Word:"Sortu lehen <a routerLink='/folder/Schedule/New'>Programa</a>.",
  New_Administrator:"Administratzaile berria",
  Username:"Erabiltzaile izena",
  First_Name:"Izena",
  Last_Name:"Abizena",
  Add_an_Address:"Gehitu Helbidea",
  Payment_Limit:"Ordainketa bakoitzeko muga",
  Total_Limit:"Muga osoa",
  Select_Accounts:"Hautatu kontuak",
  No_Administrators:"Administratzailerik ez",
  No_Administrators_Word:"Sortu lehen <a routerLink='/folder/Administrator/New'>Admin kontua</a>.",
  New_Administrators_Word:"Gehitu lehen <a routerLink='/folder/Administrator/New'>Administratzailea</a>",
  Cloud:"Hodei",
  Backup:"Babeskopia",
  Enable_iCloud:"Gaitu iCloud",
  Enable_Google_Drive:"Gaitu Google Drive",
  Enable_Microsoft_OneDrive:"Gaitu Microsoft OneDrive",
  Automatically_Backup:"Automatikoki babeskopia",
  FTP_Settings:"FTP ezarpenak",
  URL_File_Prompt:"Mesedez, zehaztu .xls / .xlsx / .json fitxategi baten kokapena inportatzeko:",
  URL_SQL_Prompt:"Mesedez, zehaztu inportatzeko SQLite fitxategiaren kokapena:",
  FTP_Backup:"FTP babeskopia",
  FTP_Import:"FTP inportazioa",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Ostalari",
  Port:"Porta",
  Path:"Bidea",
  Data_Path:"Datuen bidea",
  Enable_FTP_Account:"Gaitu FTP kontua",
  HostnameIP:"Ostalari izena",
  Password:"Pasahitza",
  Connection_Port:"Konexio portua",
  Enable_MySQL_Database:"Gaitu MySQL datu-basea",
  Log:"Erregistroa",
  Reset:"Berrezarri",
  Erase:"Ezabatu",
  Export:"Esportatu",
  Database:"Datu-basea",
  Upload_CSV:"Kargatu CSV",
  Download_CSV:"Deskargatu CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL babeskopia",
  Internal_Notes:"Barne oharrak",
  Root_Path:"Root bidea",
  Select_Backup:"Hautatu babeskopia",
  Add_New_Backup:"Gehitu babeskopia berria",
  First_Backup:"Konfiguratu lehen babeskopia...",
  Backups:"Babeskopia",
  Add_Backup:"Gehitu babeskopia",
  No_Backups:"Ez dago babeskopirik aurkitu behar.",
  Select_Backup_Type:"Hautatu konfiguratu nahi duzun babeskopia mota...",
  Backup_Type:"Bakopia mota",
  FTP_Drive:"FTP Drive",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Gidatu",
  Microsoft_OneDrive:"Gidatu",
  Import_Fields:"Inportatu eremuak",
  Import_Fields_Word:"Erabili atal hau <a routerLink='/folder/Holiday/New'>Inportatu</a> datuak hautatzeko.",
  Upload:"Kargatu",
  Download:"Deskargatu",
  Download_JSON:"Deskargatu JSON datu gisa",
  Download_SQL:"Deskargatu SQL fitxategi gisa",
  New_Bank:"Banku berria",
  New_Account:"Kontu berria",
  New_Bank_Account:"Banku-kontu berria",
  Upload_Image:"Kargatu irudia",
  Bank_Name:"Bankuaren izena",
  Bank_Address:"Bankuaren helbidea",
  Branch_Address:"Sukurtsalen helbidea",
  Address_on_Cheque:"Helbidea",
  Cheque_Numbers:"Egiaztatu zenbakiak",
  Cheque_Details:"Egiaztatu xehetasunak",
  Bank_Logo:"Bankuaren logotipoa",
  Cheque_ID:"Egiaztatu ID",
  Starting_Cheque_ID:"Identifikazioa egiaztatzen hasten",
  Transit_Number:"Garraio zenbakia",
  Institution_Number:"Erakunde zenbakia",
  Designation_Number:"Izendapen zenbakia",
  Account_Number:"Kontu zenbakia",
  Limits:"Mugak",
  Default_Limits:"Lehenetsitako mugak",
  Over_Limit:"Muga gaindi",
  Under_Limit:"Mugapean",
  Payroll_Limit:"Nominaren muga",
  Enable_Bank_Account:"Gaitu banku-kontua",
  Select_Account:"Hautatu kontua",
  No_Bank_Account:"Banku-konturik ez",
  No_Bank_Account_Word:"Gehitu lehen <a routerLink='/folder/Accounts/New'>Banku-kontua</a>.",
  Bank_Accounts:"Banku-kontuak",
  No_Accounts:"Konturik ez",
  No_Accounts_Note:"Gehitu lehen <a routerLink='/folder/Accounts/New'>Banku-kontua</a>.",
  Cheque_Designer:"Egiaztatu diseinatzailea",
  Cheque_Design:"Egiaztatu diseinua",
  Select_Design:"Hautatu diseinu bat",
  Cheque_Designs:"Egiaztatu diseinuak",
  No_Cheque_Designs:"Ez egiaztatu diseinurik",
  No_Cheque_Designs_Word:"Sortu zure <a routerLink='/folder/Settings/Cheque/Design/New'>Egiaztatu diseinua</a>.",
  Set_Default:"Ezarri lehenetsi gisa",
  Default:"Lehenetsia",
  Remove:"Kendu",
  Folder:"Karpeta",
  Edit:"Editatu",
  LoadingDots:"Kargatzen...",
  Add_a_New_File:"Gehitu <a href='#' (click)='add()'>Fitxategi berria</a> honi",
  this_folder:"karpeta hau",
  FTP_Backup_Settings:"FTP babeskopia ezarpenak",
  Secure_File_Transfer:"Fitxategien transferentzia segurua",
  New_Holiday:"Opor berria",
  Add_Holiday:"Gehitu oporrak",
  Holiday_Name:"Oporren izena",
  Additional_Pay:"Ordainketa gehigarria",
  Enable_Holiday:"Gaitu oporrak",
  Select_Holidays:"Hautatu oporrak",
  No_Holidays:"Oporrik ez",
  No_Holidays_Word:"Gehitu lehen <a routerLink='/folder/Holiday/New'>Oporrak</a>.",
  New_Import:"Inportazio berria",
  Import_Data:"Inportatu datuak",
  Import_Data_Word:"Hautatu nahi duzun fitxategi-mota edo kargatzeko metodoa.<br /> Fitxategi batean inportatutako eremuak hautatu ahal izango dituzu aplikazioko edozein parametrori dagozkion fitxategi bateragarriak kargatu ondoren.", 
  Import_File:"Inportatu fitxategia",
  Link_To_File:"Esteka fitxategira",
  Select_File:"Hautatu fitxategia",
  New_Moderator:"Moderatzaile berria",
  Allow_Moderation:"Baimendu moderazioa",
  Create_Paycheques:"Sortu soldatak",
  Edit_Paycheques_and_Data:"Editatu soldatak eta datuak",
  Destroy_Data_and_Paycheques:"Suntsitu datuak eta soldata-txekeak",
  Bonus_Percentage:"Ordainketa-ehunekoa",
  Fixed_Amount:"Kopuru finkoa",
  Select_Moderator:"Hautatu moderatzailea",
  No_Moderators:"Moderatzailerik ez",
  Moderators:"Moderatzaileak",
  Moderator_Account:"Sortu lehen <a routerLink='/folder/Administrator/New'>Moderatzaile kontua</a>.",
  No_Moderators_Word:"Gehitu lehen <a routerLink='/folder/Administrator/New'>Moderatzailea</a>.",
  Defaults:"Lehenetsiak",
  Provide_Us_Info:"Eman iezaguzu informazioa",
  Setup_Your_Printer:"Konfiguratu zure inprimagailua",
  Your_Company:"Zure enpresari buruz",
  Company_Name:"Enpresaren izena",
  Currency:"Moneta",
  Default_Currency:"Moneta lehenetsia",
  Base_Monthly_Income:"Hileko diru-sarrerak",
  Protection:"Babes",
  App_Protection:"Aplikazioen babesa",
  PIN_Code_Protection:"PIN kodea babestea",
  App_Protection_Word:"Gaitu zure kontua babesten laguntzen duten segurtasun-metodoak.",
  PIN_Code:"PIN kodea",
  Change_PIN:"Aldatu PINa",
  New_Password:"Pasahitz berria",
  Geofence_Protection:"Geo-hesiaren babesa",
  Geofence_Area:"Ezarri eremua",
  Distance:"Distantzia",
  Setup_Now:"Konfiguratu orain",
  Mile:"Milia",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Aurpegi-ezagutza",
  Face:"Aurpegia",
  Setup:"Konfigurazioa",
  Label:"Etiketa",
  Password_Protection:"Pasahitz babesa",
  Modify_Password:"Aldatu pasahitza",
  New_Tax_Entry:"Zerga sarrera berria",
  New_Tax:"Zerga berria",
  Tax_Label:"Zerga-etiketa",
  Perpetually_Enabled:"Betiko gaituta",
  Select_Taxes:"Hautatu zergak",
  Tax_Deductions:"Zerga kenkariak",
  No_Tax_Deductions:"Zerga kenkaririk gabe",
  No_Tax_Deductions_Word:"Gehitu lehen <a routerLink='/folder/Tax/New'>Zerga</a> kenkaria.",
  New_Timer:"Tenporizadore berria",
  Start:"Hasi",
  Stop:"Gelditu",
  Start_Timer:"Hasi tenporizadorea",
  Stop_Timer:"Gelditu tenporizadorea",
  Timer_Active:"Tenporizadorea aktiboa",
  Timer:"Tenporizadorea:",
  Timer_Running:"Tenporizadorea: (Abian)",
  Save_Timer:"Gorde tenporizadorea",
  New_Timesheet:"Denbora-orria berria",
  Select_Timesheets:"Hautatu ordu-orriak",
  Work_Notes:"Lan oharrak",
  Entry_Title:"Sarreraren izenburua",
  No_Timesheets:"Denbora-orririk ez",
  No_Timesheets_Word:"Gehitu lehen <a routerLink='/folder/Timesheet/New'>Denbora-orria</a>.",
  Timesheet_Submitted:"Bidalketa-orria",
  Timesheet_Congrats:"Zorionak! Zure denbora-orria behar bezala bidali da. Eskerrik asko!",
  Timesheet_Copy:"Zure dokumentuen kopia bat jasotzeko, eman iezaguzu zure helbide elektronikoa eta/edo telefono mugikorra.",
  Submit:"Bidali",
  Allow_Notifications:"Onartu jakinarazpenak",
  Untitled_Entry:"Sarrera berria",
  Untitled_Bank:"Izenbururik gabeko Bankua",
  Timesheet_Entry:"Ordu-orriaren sarrera",
  Work_Description:"Lanaren deskribapena",
  Enable_Timesheet:"Gaitu ordu-orria",
  Submit_Timesheet:"Bidali ordu-orria",
  Vacation:"Oporrak",
  New_Vacation:"Opor berriak",
  Vacation_Name:"Oporren izena",
  Paid_Vacation:"Ordaindutako oporrak",
  Vacation_Pay:"Oporrak ordaindu",
  Enable_Vacation:"Gaitu oporrak",
  Select_Vacations:"Hautatu oporrak",
  No_Vacations:"Oporrik ez",
  No_Vacations_Word:"Sortu lehen <a routerLink='/folder/Vacation/New'>Oporrak</a> sarrera.",
  Payroll_Schedule:"Nominaren egutegia",
  Next_Payroll:"Hurrengo nomina:",
  Upcoming_Payroll:"Datorren nomina",
  No_Upcoming_Payroll:"Datorren nominarik ez",
  Address_Book:"Helbide liburu",
  Archived_Documents:"Artxibatutako dokumentuak",
  Dev_Mode:"Aplikazioa garapen moduan",
  Administrators:"Administratzaileak",
  Privacy:"Pribatutasuna",
  None:"Inor ez",
  Select_Signature:"Hautatu sinadura",
  No_Signatures:"Sinadurarik ez",
  No_Signatures_Word:"Gehitu lehen <a routerLink='/folder/Signature/New'>Sinadura</a>.",
  Repeat_Indefinitely:"Errepikatu mugagabean",
  Sign:"Sinatu",
  Sign_Here:"Sinatu hemen",
  Date_Signed:"Sinatze data",
  Signature_Pad:"Sinadura Pad",
  Account_Holder:"Kontuaren titularra",
  Account_Properties:"Kontuaren propietateak",
  Name_On_Cheque:"Izena txekean",
  Server_Hostname:"Zerbitzariaren ostalari izena / IP",
  Printers:"Inprimagailuak",
  No_Printers:"Inprimagailurik ez",
  Printer_Exists:'Izen honen inprimagailu bat badago jada.',
  No_Printers_Word:"Gehitu lehen <a routerLink='/folder/Printer/New'>Inprimagailua</a>.",
  No_Printer_Alert:"Ez dago inprimagailurik definituta. Inprimagailu bat konfiguratu nahi duzu?",
  Add_Printer:"Gehitu inprimagailua",
  New_Printer:"Inprimagailu berria",
  Printer_Hostname:"Inprimagailuaren ostalari-izena / IP",
  Printer_Label:"Inprimagailuaren etiketa",
  Printer_Protocol:"Inprimagailuen protokoloa",
  Protocol:"Protokoloa",
  Email_Print:"E-posta",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Socket",
  Print_Job:"Inprimatu lana",
  Printed:"Inprimatuta",
  Not_Printed:"Ez dago inprimatuta",
  Print_Jobs:"Inprimatu lanak",
  Print_Queue:"Inprimatzeko ilara",
  No_Print_Jobs:"Inprimatze-lanik ez",
  No_Prints:"Sortu <a routerLink='/folder/Cheque/New'>Txeke</a> berri bat inprimatzeko.",
  Prints:"Inprimaketak",
  Find_Printer:"Bilatu inprimagailua",
  Find_AirPrint_Devices:"Bilatu AirPrint gailuak",
  Select_Printer:"Hautatu inprimagailua",
  System_UI:"Sistemaren UI",
  Printer:"Inprimagailua",
  Status:"Egoera",
  Preview:"Aurrebista",
  Enable_Print_Job:"Gaitu inprimatzeko lana",
  Queue_Weight:"Ilararen pisua",
  Unlimited:"Mugagabea",
  Show_Advanced_Printer_Options:"Erakutsi inprimagailuaren aukera aurreratuak",
  Advanced:"Aurreratua",
  Location:"Kokapena",
  Note:"Ohar",
  Queue_Name:"Ilararen izena",
  Pages_Printed_Limit:"Orriak inprimatutako muga",
  MultiPage_Idle_Time:"Orrialde anitzeko itxaron-denbora (k)",
  Page_Count_Limit:"Orrialde kopuruaren muga",
  Page_Orientation:"Orrialdearen orientazioa",
  Portrait:"Erretratua",
  Landscape:"Paisaia",
  Duplex:"Duplexa",
  Double_Sided_Printing:"alde bikoitza",
  Duplex_Mode:"Duplex modua",
  Duplex_Short:"Laburra",
  Duplex_Long:"Luzea",
  Duplex_None:"Inor ez",
  Color_And_Quality:"Kolorea eta kalitatea",
  Monochrome:"Monokromoa",
  Photo_Quality_Prints:"Argazki kalitatezko inprimaketak",
  Printer_Email:"Inprimagailuaren posta elektronikoa",
  Automatically_Downsize:"Automatikoki txikitu",
  Paper:"Papera",
  Paper_Name:"Paperaren izena",
  Paper_Width:"Paperaren zabalera",
  Paper_Height:"Paperaren altuera",
  Paper_Autocut_Length:"Paperaren mozketa automatikoko luzera",
  Margins:"Marjinak",
  Page_Margins:"Orrialde marjinak",
  Page_Margin_Top:"Goiko orriaren marjina",
  Page_Margin_Right:"Eskuineko orriaren marjina",
  Page_Margin_Bottom:"Beheko orriaren marjina",
  Page_Margin_Left:"Ezkerreko orriaren marjina",
  Add_Employees:"Gehitu langileak",
  Header:"Goiburua",
  Print_A_Page_Header:"Inprimatu orrialde baten goiburua",
  Header_Label:"Goiburuaren etiketa",
  Header_Page_Index:"Goiburuen orriaren aurkibidea",
  Header_Font_Name:"Goiburuaren letra",
  Select_Font:"Hautatu letra-tipoa",
  Font_Selector:"Letra-tipo-hautatzailea",
  Header_Font_Size:"Goiburuaren letra",
  Header_Bold:"Goiburua lodia",
  Header_Italic:"Goiburua etzana",
  Header_Alignment:"Goiburuen lerrokatzea",
  Left:"Ezkerra",
  Center:"Zentroa",
  Right:"Oso",
  Justified:"Justifikatua",
  Header_Font_Color:"Goiburuaren kolorea",
  Select_Color:"Hautatu kolorea",
  Footer:"Oina",
  Print_A_Page_Footer:"Orrialde baten orri-oina inprimatu",
  Footer_Label:"Oinaren etiketa",
  Footer_Page_Index:"Oin-orriaren aurkibidea",
  Footer_Font_Name:"Oinaren letra-tipoa",
  Fonts:"Letra-tipoak",
  Done:"Bukatu",
  Select_Fonts:"Hautatu letra-tipoak",
  Footer_Font_Size:"Oinaren tamaina",
  Footer_Bold:"Footer Bold",
  Footer_Italic:"Oin-oina etzana",
  Footer_Alignment:"Oinaren lerrokatzea",
  Footer_Font_Color:"Oinaren kolorea",
  Page_Copies:"Orrialdearen kopiak",
  Enable_Printer:"Gaitu inprimagailua",
  Remote_Logging:"Urrutiko erregistroa",
  Log_Server:"Erregistro zerbitzaria",
  Encryption:"Enkriptatzea",
  Random_Key:"Ausazko tekla",
  Encryption_Key:"Enkriptatzeko gakoa",
  Cheques_Webserver:"Datu-base pertsonalizatua",
  Learn_How:"Ikasi nola",
  Signature:"Sinadura",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Ikusi sinadura",
  Printed_Signature:"Inprimatutako sinadura",
  Digitally_Sign:"Sinatu digitalki",
  Digital_Signature:"Sinadura digitala",
  Add_Signature:"Gehitu sinadura",
  Add_Your_Signature:"Gehitu zure sinadura",
  Enable_Signature:"Gaitu sinadura",
  Digitally_Signed:"Digitalki sinatuta",
  Insert_Error:"Ezin da gorde txekea datu-basearen arazoengatik.",
  Delete_Confirmation:"Ziur informazio hau ezabatu nahi duzula?",
  Discard_Confirmation:"Ziur informazio hau baztertu nahi duzula?",
  Discard_Bank_Confirmation:"Ziur kontu hau baztertu nahi duzula?",
  Discard_Printer_Confirmation:"Ziur inprimagailu hau baztertu nahi duzula?",
  Delete_Bonus_Confirmation:"Ziur hobaria ezabatu nahi duzula?",
  Delete_Invoice_Confirmation:"Ziur faktura hau ezabatu nahi duzula?",
  Generated_Cheque:"Sortutako egiaztagiria",
  Generated_Invoice:"Sortutako faktura",
  Schedule_Start_Time:"Egitarauaren hasiera",
  Schedule_End_Time:"Egitarauaren amaiera",
  New_Call:"Dei berria",
  No_Contacts:"Kontaturik ez",
  No_Contacts_Word:"Administratzaileak, moderatzaileak, langileak eta ordainsariak kontaktu gisa agertzen dira.",
  PDF_Subject:"finantza",
  PDF_Keywords:"nominaren ordainsaria PDF txekeen txekea",
  Printer_Setup:"Inprimagailuaren konfigurazioa",
  Printer_Selection:"Inprimagailuaren hautaketa",
  New_Fax:"Fax berria",
  New_Fax_Message:"Fax mezu berria",
  Fax_Machine:"Fax Makina",
  Fax_Name:"Faxaren izena",
  Fax_Email:"Faxa posta elektronikoa",
  Fax_Number:"Fax zenbakia",
  Contents:"Edukiak",
  Fax_Body:"Orriaren gorputza",
  Header_Word:"Goiburua:",
  Header_Text:"Goiburuaren testua",
  Include_Header:"Sartu goiburua",
  Include_Footer:"Sartu oina",
  Footer_Word:"Oina:",
  Footer_Text:"Oinaren testua",
  Attach_a_Cheque:"Entsi txeke bat",
  Add_Deduction:"Gehitu kenkaria",
  Enable_Fax:"Bidali faxa",
  Select_Fax:"Hautatu faxa",
  No_Faxes:"Faxirik ez",
  Faxes:"Faxa",
  Save_and_Send:"Bidali faxa",
  Save_and_Pay:"Gorde eta Ordaindu",
  WARNING:"OHARRA:",
  Remember:"Gogoratu",
  Printing:"Inprimatzea",
  Printing_Complete:"Inprimatzea amaituta!\n\n",
  of:"ko",
  There_Were:"Baziren",
  Successful_Prints:" inprimaketa arrakastatsuak eta ",
  Unsuccessful_Prints:" arrakastarik gabeko inprimaketak.",
  PrinterError:"Barkatu! Errore bat gertatu da.",
  Printing_:"Inprimatzen...",
  PrinterSuccess:"Dokumentua behar bezala inprimatu da.",
  PrintersSuccess:"Dokumentuak behar bezala inprimatu dira.",
  Print_Cheques:"Inprimatu txekeak",
  New_Message:"Mezu berria",
  New_Messages:"Mezu berriak",
  Read_Message:"Irakurri mezua",
  Write_Message:"Idatzi mezua",
  Send_Message:"Mezua bidali",
  Subject:"Gaia",
  Message:"Mezua",
  Writing:"Idazten...",
  Send:"Bidali",
  Set_Date:"Ezarri data",
  Set_Time:"Ezarri ordua",
  Recieve:"Jaso",
  Set_as_Default:"Ezarri lehenetsi gisa",
  Default_Account:"Kontu lehenetsia",
  Default_Design:"Diseinu lehenetsia",
  Multiple_Cheques:"Txekeak (hirukiak)",
  Account_Mode:"Kontuaren modua",
  Multiple_Cheques_Description:"Hiru egiaztapen orrialde bakoitzeko.",
  Check_and_Table:"Egiaztatu eta taula",
  Check_including_Table:"Egiaztatu eta kontabilitate taula.",
  Check_Mailer:"Egiaztatu posta elektronikoa",
  Check_Mailer_Window:"Egiaztatu helbide-leiho batekin.",
  DocuGard_Table_Top:"DocuGard Check & Table (Goian)",
  DocuGard_Table_Middle:"DocuGard Check & Table (Erdian)",
  DocuGard_Table_Bottom:"DocuGard Check & Table (behean)",
  DocuGard_Mailer_Top:"DocuGard Check Mailer (Goian)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (Erdian)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mailer (behean)",
  DocuGard_Three_Cheques:"DocuGard egiaztapenak (Tripple)",
  DocuGard_Cheque_Top:"DocuGard Check (Goian)",
  DocuGard_Cheque_Middle:"DocuGard Check (Erdian)",
  DocuGard_Cheque_Bottom:"DocuGard Check (behean)",
  DocuGard_Three_Cheques_Window:"Hiru egiaztapen orrialde batean.",
  DocuGard_Table_Top_Window:"Egiaztatu eta irabazien taula.",
  DocuGard_Table_Middle_Window:"Egiaztatu eta irabazien taula.",
  DocuGard_Table_Bottom_Window:"Egiaztatu eta irabazien taula.",
  DocuGard_Mailer_Top_Window:"Egiaztatu, taula eta helbidea.",
  DocuGard_Mailer_Middle_Window:"Egiaztatu, taula eta helbidea.",
  DocuGard_Mailer_Bottom_Window:"Egiaztatu, taula eta helbidea.",
  DocuGard_Cheque_Top_Window:"Egiaztatu paper segurua.",
  DocuGard_Cheque_Middle_Window:"Egiaztatu paper segurua.",
  DocuGard_Cheque_Bottom_Window:"Egiaztatu paper segurua.",
  Basic_Cheque:"Egiaztatu (Goian)",
  Basic_Cheque_Print:"Inprimatu txeke bakarra.",
  Error_Setting_As_Paid:"Errorea ordaindutako gisa ezartzean",
  Add_Attachment:"Gehitu eranskina",
  PrinterUnavailable:"Inprimagailua ez dago erabilgarri",
  CreditCardComponent:"txartelak",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Gordailu berria",
  Deposits:"Gordailuak",
  No_Deposits:"Gordailurik ez",
  Credit_Card_Deposit:"Kreditu txartela",
  New_Credit_Card_Deposit_Message:"Kreditu txartelaren gordailua",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin gordailua",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Interac transferentzia",
  Payments_Limit:"Ordainketa muga",
  No_Payment_Limit:"Ordainketa mugarik ez",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal gordailua",
  No_Deposits_Word:"Gehitu lehen konpainia <a routerLink='/folder/Deposit/New'>Gordailua</a>.",
  No_Documents_Specified:"Ez da dokumenturik zehaztu inprimatzeko",
  No_Printers_Added:"Ez da inprimagailurik aurkitu. Joan Ezarpenak > Inprimagailuak atalera bat gehitzeko.",
  DB_Erase_Prompt:"Erabat ezabatu datu-base osoa? KONTUZ: gordetako informazio guztia galduko duzu!",
  Console_Warning:"Ez itsatsi testurik kontsola honetan. Zure burua eta/edo zure enpresa arrisku larrian jar ditzakezu.",
  No_Faxes_Word:"Sortu lehen <a routerLink='/folder/Fax/New'>Faxa</a>.",
  Cheque_Delete_Confirmation:"Ziur txeke hau ezabatu nahi duzula?",
  Design_Delete_Confirmation:"Ziur diseinu hau ezabatu nahi duzula?",
  Cheque_Pay_Confirmation:"Markatu txeke hau ordainduta? Ez da inprimatzeko ilaran agertuko.",
  Payment_Pay_Confirmation:"Markatu ordainketa hau ordainduta? Ez da txeke-ilaran agertuko.",
  Delete_Deduction_Confirmation:"Ziur al zaude kenkari hau ezabatu nahi duzula?",
  Delete_Job_Confirmation:"Ziur al zaude lan hau ezabatu nahi duzula?",
  Delete_Timesheet_Confirmation:"Ziur ordu-orria ezabatu nahi duzula?",
  Delete_Schedule_Confirmation:"Ziur ordutegi hau ezabatu nahi duzula?",
  Delete_Setting_Confirmation:"Ziur ezarpen hau berrezarri nahi duzula?",
  Delete_Fax_Confirmation:"Ziur fax hau ezabatu nahi duzula?",
  Delete_File_Confirmation:"Ziur fitxategi hau ezabatu nahi duzula?",
  Delete_Vacation_Confirmation:"Ziur oporrak ezabatu nahi dituzula?",
  Delete_Printer_Confirmation:"Ziur inprimagailu hau ezabatu nahi duzula?",
  Remove_Design_Confirmation:"Ziur diseinu hau ezabatu nahi duzula?",
  Delete_Payroll_Confirmation:"Ziur nomina hau ezabatu nahi duzula?",
  Send_Fax_Email_Confirmation:"Dokumentu hauek faxez eta posta elektronikoz bidali nahi dituzu?",
  Send_Email_Confirmation:"Dokumentu hau posta elektronikoz bidali nahi duzu?",
  Send_Fax_Confirmation:"Dokumentu hau faxez bidali nahi al duzu?",
  Error_Generating_PDF:"Barkatu. Errore bat gertatu da dokumentu hau sortzean.",
  PDF_Error_Saving_Image:"Barkatu. Errore bat gertatu da dokumentu honen PDF irudia gordetzean.",
  Test_Printer_Confirmation:"Inprimagailu honetan proba-orri bat inprimatu nahi duzu?",
  Save_Timesheet_Prompt:"Mesedez, gehitu 'Izenburua' edo sakatu 'Hasi tenporizadorea' gordetzeko.",
  Remove_Geofence_Prompt:"Ziur geo-hesi honen kokapena kendu nahi duzula?",
  Delete_Employee_Confirmation:"Ziur ezabatu nahi duzula",
  Fire_Employee_Confirmation:"Ziur al zaude su egin nahi duzula"
}