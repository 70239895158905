export const Lo = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"ລິຂະສິດ & ສໍາເນົາ; 2023",
  black:"ດຳ",
  green:"ສີຂຽວ",
  gold:"ຄໍາ",
  blue:"ສີຟ້າ",
  brown:"ສີນ້ຳຕານ",
  purple:"ສີມ່ວງ",
  pink:"ສີບົວ",
  red:"ສີແດງ",
  Swatches:"ສີ",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"ເຫວ",
  Saturation:"ການອີ່ມຕົວ",
  Lightness:"ຄວາມສະຫວ່າງ",
  Upgrade_To_Pro:"ອັບເກຣດເປັນ Pro",
  AllFeaturesInclude:"ຄຸນນະສົມບັດທັງຫມົດລວມມີ:",
  PrintUnlimitedCheques:"ພິມການກວດສອບບໍ່ຈໍາກັດ",
  PremiumChequeDesigns:"ແບບກວດສອບທີ່ນິຍົມ",
  ManageUnlimitedEmployees:"ການຄຸ້ມຄອງພະນັກງານບໍ່ຈໍາກັດ",
  AddUnlimitedPayees:"ເພີ່ມຜູ້ຮັບເງິນບໍ່ຈໍາກັດ",
  CreateUnlimitedPayrolls:"ສ້າງເງິນເດືອນບໍ່ຈໍາກັດ",
  UnlimitedSchedulesandTimeSheets:"ຕາຕະລາງເວລາບໍ່ຈໍາກັດແລະຕາຕະລາງເວລາ",
  BulkPayPalPayouts:"ການຈ່າຍເງິນ PayPal ຈໍານວນຫລາຍ",
  UnlimitedBankAccounts:"ບັນຊີທະນາຄານບໍ່ຈໍາກັດ",
  ManageMultipleCompanies:"ຄຸ້ມຄອງຫຼາຍບໍລິສັດ",
  TrackInsurancePolicies:"ຕິດຕາມນະໂຍບາຍການປະກັນໄພ",
  Bio_MetricProtection:"ການປົກປ້ອງ Bio-Metric",
  Geo_FenceLock_OutProtection:"Geo-Fence Lock-Out ການປົກປ້ອງ",
  Multiple_Companies_Word:"ການຄຸ້ມຄອງບໍລິສັດຫຼາຍແຫ່ງແມ່ນບໍ່ສາມາດໃຊ້ໄດ້ໂດຍບໍ່ມີ Checks ເບັ້ຽກັນ.",
  PayPal_Payouts_Word:"ການຈ່າຍເງິນ PayPal ຖືກປິດການນຳໃຊ້ໂດຍບໍ່ມີ Checks ເບັ້ຽກັນ.",
  PINProtection:"ການປົກປ້ອງ PIN",
  PasswordProtection:"ການປົກປ້ອງລະຫັດຜ່ານ",
  May_Require_Approval:"ອາດຈະຕ້ອງການການອະນຸມັດ.",
  Subscribe:"ຈອງ",
  Billed:"ຮຽກເກັບ",
  Up:"ຂຶ້ນ",
  Down:"ລົງ",
  Positioning:"ການຈັດຕໍາແໜ່ງ",
  Marker:"ເຄື່ອງໝາຍ",
  Drag_Marker:"ລາກເຄື່ອງໝາຍ",
  Tagline:"ພິມເຊັກແລະຕາຕະລາງການຈ່າຍເງິນ",
  Marker_Word:"ໃຊ້ Markers ເພື່ອຂະຫນາດອົງປະກອບ.",
  Pinch_Zoom:"Pinch ຊູມ",
  Pinch_Word:"Pinch ເພື່ອຊູມອົງປະກອບ.",
  Drag:"ລາກ",
  Drag_Word:"ໃຊ້ນິ້ວມືຂອງທ່ານເພື່ອລາກອົງປະກອບ.",
  subscription_alias_word:"ການຕໍ່ອາຍຸການສະໝັກໃຊ້ອັດຕະໂນມັດ",
  premium_alias_word:"ແພັກເກດການຍົກລະດັບເທື່ອດຽວ",
  print_alias_word:"ພິມອອກກວດສອບບຸກຄົນ",
  mode_alias_word:"ໂໝດ",
  Pro:"ໂປຣ",
  Pro_word:"ຕ້ອງການລຸ້ນ Pro.",
  Cant_Delete_Default_Company_Word:"ຂໍອະໄພ, ທ່ານ ບໍ່ສາມາດ ລຶບບໍລິສັດເລີ່ມຕົ້ນຂອງທ່ານໄດ້.",
  Reinsert_Default_Designs:"ໃສ່ການອອກແບບເລີ່ມຕົ້ນຄືນໃໝ່",
  Reinsert_Default_Designs_word:"ທ່ານຕ້ອງການໃສ່ການອອກແບບເລີ່ມຕົ້ນຄືນໃໝ່ບໍ?",
  Subscription_Active_Disable_Word:"ການສະໝັກໃຊ້ນີ້ເປີດຢູ່. ທ່ານຕ້ອງການຍົກເລີກການສະໝັກໃຊ້ Cheques ນີ້ບໍ?",
  Company_Logo:"ໂລໂກ້ຂອງບໍລິສັດ",
  ZERO_:"ZERO",
  Disclaimer:"ປະຕິເສດຄວາມຮັບຜິດຊອບ",
  Privacy_Policy:"ນະໂຍບາຍຄວາມເປັນສ່ວນຕົວ",
  EULA:"ກວດສອບ EULA",
  Terms_Of_Service:"ເງື່ອນໄຂການໃຫ້ບໍລິການ",
  Terms_Of_Use:"ເງື່ອນໄຂການນໍາໃຊ້",
  Refunds:"ນະໂຍບາຍການຄືນເງິນ",
  Single_Print:"1 ກວດສອບ",
  Two_Prints:"2 ກວດກາ",
  Five_Prints:"5 ກວດກາ",
  Ten_Prints:"10 ການກວດສອບ",
  Fifteen_Prints:"15 ການກວດກາ",
  Twenty_Prints:"20 ເຊັກ",
  Thirty_Prints:"30 ເຊັກ",
  Image_Added:"ເພີ່ມຮູບແລ້ວ",
  Image_Preview:"ຕົວຢ່າງຮູບພາບ",
  No_Image_Was_Selected:"ບໍ່ມີຮູບໃດຖືກເລືອກ.",
  Cheques_Unlimited:"ກວດສອບບໍ່ຈໍາກັດ",
  _Subscription:"ການສະໝັກໃຊ້",
  Subscription:"ກວດ - 1 ເດືອນ",
  Two_Month_Subscription:"ກວດ - 2 ເດືອນ",
  Three_Month_Subscription:"ກວດ - 3 ເດືອນ",
  Six_Month_Subscription:"ກວດ - 6 ເດືອນ",
  Twelve_Month_Subscription:"ກວດ - 12 ເດືອນ",
  Cheques_Are_Available:"ການກວດສອບມີໃຫ້ພິມ.",
  Upgrade_Required_Two:"ເລືອກແພັກເກດ ແລະແຕະສອງເທື່ອໃສ່ປຸ່ມລາຄາເພື່ອເລີ່ມການຊື້ຂອງທ່ານ. ພິມແບບມືອາຊີບເບິ່ງການກວດສອບສີເຕັມໃນວິນາທີ.",
  Month:"ເດືອນ",
  Due:"ຮອດກຳນົດ:",
  Expires:"ໝົດອາຍຸ:",
  Subscription_Active:"ສະໝັກໃຊ້ຢູ່",
  Subscription_Inactive:"ການສະໝັກໃຊ້ບໍ່ເຄື່ອນໄຫວ",
  Purchase_Additional_Cheques:"ຊື້ເຊັກເພີ່ມເຕີມບໍ?",
  Printable_Cheque:"ກວດສອບການພິມ",
  Printable_Cheques:"ການກວດທີ່ສາມາດພິມໄດ້",
  Printable_Cheque_Word:"ການກວດສອບແມ່ນມີຢູ່ໃນບັນຊີຂອງທ່ານ.",
  Printable_Cheques_Word:"ການກວດສອບມີຢູ່ໃນບັນຊີຂອງທ່ານ.",
  Max_Amount_Message:"ຈໍານວນທີ່ທ່ານໄດ້ລະບຸໄວ້ໄດ້ເກີນຈໍານວນສູງສຸດທີ່ກໍານົດໄວ້ສໍາລັບລະບົບນີ້:",
  Terms_Required_Word:"ທ່ານຕ້ອງຕົກລົງເຫັນດີກັບຂໍ້ຕົກລົງນີ້ກ່ອນທີ່ຈະສືບຕໍ່ນໍາໃຊ້ Cheques.",
  Subscriptions:"ການສະໝັກໃຊ້",
  Product_Upgrades:"ການຍົກລະດັບ",
  Mailed_Out_Cheques:"ການກວດສອບທາງໄປສະນີ",
  Enter_A_Company_Name:"ກະລຸນາໃສ່ຊື່ບໍລິສັດ.",
  Single_Cheques:"ການກວດຄັ້ງດຽວ",
  Cheque_Golden:"Golden Check",
  Cheque_Golden_Window:"ການອອກແບບການກວດສອບສີທອງ.",
  Cheque_Green:"ກວດສີຂຽວ",
  Cheque_Green_Window:"ການອອກແບບການກວດກາສີຂຽວ.",
  Cheque_Red:"ກວດສອບສີແດງ",
  Cheque_Red_Window:"ການອອກແບບການກວດສອບສີແດງ.",
  Cheque_Yellow:"ກວດສີເຫຼືອງ",
  Cheque_Yellow_Window:"ການອອກແບບກວດສີເຫຼືອງ.",
  Cheque_Statue_of_Liberty:"ຮູບປັ້ນຂອງເສລີພາບ",
  Cheque_Statue_of_Liberty_Window:"ການອອກແບບການກວດສອບຮູບປັ້ນເສລີພາບ.",
  Cheque_Green_Wave:"ຄື້ນສີຂຽວ",
  Cheque_Green_Wave_Window:"ການອອກແບບການກວດກາສີຂຽວ.",
  Cheque_Golden_Weave:"Golden Weave",
  Cheque_Golden_Weave_Window:"ການອອກແບບການກວດສອບສີທອງງາມ.",
  Cheque_Green_Sun:"ແສງຕາເວັນສີຂຽວ",
  Cheque_Green_Sun_Window:"ການອອກແບບການກວດສອບເລິກແລະສະຫງົບ.",
  Cheque_Blue_Checkers:"ເຄື່ອງກວດສີຟ້າ",
  Cheque_Blue_Checkers_Window:"ການອອກແບບການກວດສອບສີຟ້າ.",
  Cashiers_Check:"Cashier'\s Check",
  Cashiers_Check_Window:"ແມ່ແບບແບບ Cashier\'s Check.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"ການອອກແບບການກວດສອບ Aqua.",
  Cheque_Golden_Checkers:"Golden Checkers",
  Cheque_Golden_Checkers_Window:"ການອອກແບບການກວດສອບສີທອງ.",
  Upgrade_Unavailable:"ບໍ່ສາມາດອັບເກຣດໄດ້",
  Bank_Code_Protection:"ການປົກປ້ອງໝາຍເລກທະນາຄານ",
  Bank_Code_Protection_Word:"ປົກປ້ອງໝາຍເລກທະນາຄານຂອງທ່ານຈາກການຖືກໃຊ້ຢູ່ໃນແອັບນີ້ທີ່ແລ່ນຢູ່ໃນອຸປະກອນອື່ນ ຫຼືສຳລັບຜູ້ໃຊ້ອື່ນ. ການກະທຳນີ້ແມ່ນບໍ່ສາມາດຍົກເລີກໄດ້. ສືບຕໍ່?",
  Bank_Code_Protection_Blocked_Word:"ໝາຍເລກທະນາຄານທີ່ທ່ານກຳລັງພະຍາຍາມໃຊ້ແມ່ນສະຫງວນໄວ້ໃຫ້ກັບຜູ້ໃຊ້ ຫຼືອຸປະກອນອື່ນ.",
  Bank_Code_Protection_Error_Word:"ການຢັ້ງຢືນຕົວເລກລົ້ມເຫລວ. ກະລຸນາເຊື່ອມຕໍ່ອິນເຕີເນັດ ແລະລອງເພີ່ມບັນຊີທະນາຄານນີ້ອີກຄັ້ງ.",
  Bank_Code_Protection_Set_Error_Word:"ການປົກປ້ອງໝາຍເລກທະນາຄານຮຽກຮ້ອງໃຫ້ທ່ານເຊື່ອມຕໍ່ອິນເຕີເນັດ. ກະລຸນາເຊື່ອມຕໍ່ແລ້ວລອງໃໝ່.",
  Upgrade_Unavailable_Word:"ຂໍອະໄພ, ພວກເຮົາມີບັນຫາໃນການຢັ້ງຢືນເຈົ້າ. ການສະໝັກໃຊ້ ແລະການອັບເກຣດເປັນ Cheques ແມ່ນບໍ່ສາມາດໃຊ້ໄດ້ສໍາລັບການຊື້ໃນພື້ນທີ່ຂອງທ່ານ.",
  PayPal_Payment_Preview:"ຕົວຢ່າງການຈ່າຍເງິນ PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"ເລືອກ PayPal",
  PayPal_Applications:"ແອັບພລິເຄຊັນ PayPal",
  Purchase_With_Apple_Pay:"ຊື້ດ້ວຍ Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"ບໍລິສັດ",
  Insurance:"ປະກັນໄພ",
  New_PayPal:"PayPal ໃໝ່",
  Pay_By:"ຈ່າຍໂດຍ",
  Insure:"ປະກັນໄພ",
  Miles:"ໄມ",
  Payment_Method:"ວິທີການຊໍາລະເງິນ",
  Select_Policies:"ເລືອກນະໂຍບາຍ",
  Policies:"ນະໂຍບາຍ",
  Policy:"ນະໂຍບາຍ",
  No_PayPal_Account:"ບໍ່ມີບັນຊີ PayPal",
  No_Policies:"ບໍ່ມີນະໂຍບາຍປະກັນໄພ",
  New_Policy:"ນະໂຍບາຍໃໝ່",
  PayPal_Payment:"ການຈ່າຍເງິນ PayPal",
  PayPal_Payments:"ການຈ່າຍເງິນ PayPal",
  No_Payment_Selected:"ບໍ່ມີການຈ່າຍເງິນທີ່ເລືອກ",
  Sending_Payment_Word:"ກະລຸນາລໍຖ້າ... ການຈ່າຍເງິນນີ້ກຳລັງຖືກສົ່ງ.",
  Sending_Payments_Word:"ກະລຸນາລໍຖ້າ... ການຈ່າຍເງິນກຳລັງຖືກສົ່ງ.",
  No_Payment_Selected_PayPal:"ບໍ່ໄດ້ເລືອກ <a routerLink='/folder/Payments'>ການຈ່າຍເງິນ PayPal</a> ເພື່ອສົ່ງ.",
  Payment_Sent:"ສົ່ງການຈ່າຍເງິນແລ້ວ",
  PayPal_Payment_Sent:"ການຈ່າຍເງິນນີ້ຖືກສົ່ງໄປດ້ວຍ PayPal.",
  Copay:"ສຳເນົາ",
  Dead:"ຕາຍ",
  Alive:"ມີຊີວິດຢູ່",
  Not_Dead:"ບໍ່ຕາຍ",
  Unclaimed:"ບໍ່ໄດ້ອ້າງສິດ",
  Attempted:"ພະຍາຍາມ",
  Deceased:"ເສຍຊີວິດ",
  Claimed:"ອ້າງສິດ",
  Unpaid:"ບໍ່ໄດ້ຈ່າຍ",
  Sending_Payment:"ກຳລັງສົ່ງການຈ່າຍເງິນ",
  Sending_Payments:"ສົ່ງການຈ່າຍເງິນ",
  Send_PayPal_Confirmation:"ທ່ານຕ້ອງການສົ່ງທຸລະກໍານີ້ກັບ PayPal ບໍ?",
  Send_PayPal_Confirmation_Word:"ກົດປຸ່ມສີຂຽວເພື່ອສົ່ງທຸລະກໍານີ້.",
  Save_Payment_As_Unpaid:"ບັນທຶກການຈ່າຍເງິນນີ້ເປັນທີ່ຍັງບໍ່ໄດ້ຈ່າຍບໍ?",
  Payment_Pay_Confirmation_PayPal:"ບັນທຶກການຈ່າຍເງິນນີ້ເປັນການຈ່າຍເງິນບໍ?",
  No_Policies_Word:"ເພີ່ມ <a routerLink='/folder/Postage/New'>ນະໂຍບາຍປະກັນໄພ</a> ທຳອິດ ດຽວນີ້.",
  Timesheet_Multiple_Delete_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບເວລາຫຼາຍແຜ່ນ?",
  Select_Multiple_Timesheets_Prompt:"ບໍ່ໄດ້ເລືອກຕາຕະລາງເວລາ. ເລືອກຢ່າງໜ້ອຍໜຶ່ງຕາຕະລາງເວລາ.",
  Select_Multiple_Policies_Prompt:"ບໍ່ໄດ້ເລືອກນະໂຍບາຍ. ເລືອກຢ່າງໜ້ອຍໜຶ່ງນະໂຍບາຍປະກັນໄພ.",
  Policies_Multiple_Delete_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບນະໂຍບາຍຫຼາຍອັນ?",
  Company:"ບໍລິສັດ",
  Add_Company:"ເພີ່ມບໍລິສັດ",
  New_Company:"ເພີ່ມບໍລິສັດ",
  No_Companies:"ບໍ່ມີບໍລິສັດ",
  Enable_Company:"ເປີດໃຊ້ບໍລິສັດ",
  Select_Company:"ເລືອກບໍລິສັດ",
  The_Default_Company:"ປ້າຍຊື່ບໍລິສັດເລີ່ມຕົ້ນ.",
  Manage_Companies:"ຄຸ້ມຄອງບໍລິສັດ",
  No_Companies_Word:"ການກວດສອບຈະໃຊ້ ບໍລິສັດເລີ່ມຕົ້ນ.<br />ເພີ່ມ <a routerLink='/folder/Company/New'>ບໍລິສັດທໍາອິດ</a>.",
  Default_Company:"ບໍລິສັດເລີ່ມຕົ້ນ",
  Cheques_Unlimited_Word:"Checks Unlimited ອະນຸຍາດໃຫ້ທ່ານພິມການກວດສອບຫຼາຍເທົ່າທີ່ທ່ານຕ້ອງການ.",
  Cheques_Subscription_Word:"ການສະໝັກໃຊ້ Checks ອະນຸຍາດໃຫ້ທ່ານພິມເຊັກໄດ້ຫຼາຍເທົ່າທີ່ທ່ານຕ້ອງການ.",
  You_Own_This_Product:"ທ່ານເປັນເຈົ້າຂອງຜະລິດຕະພັນນີ້.",
  Your_Subscription_is_Active:"ການສະໝັກໃຊ້ຂອງທ່ານດຳເນີນຢູ່.",
  Active_Products:"ຜະລິດຕະພັນທີ່ເປີດໃຊ້",
  Purchase_Confirmation:"ຊື້",
  Purchase_Cheques:"ຊື້ເຊັກ",
  Restore_Purchase:"ການຟື້ນຟູການຊື້",
  Erase_Purchase:"ລຶບການຊື້",
  Successfully_Purchased:"ຊື້ສຳເລັດແລ້ວ",
  Enter_Your_Licence_Key:"ກະລຸນາໃສ່ລະຫັດໃບອະນຸຍາດຂອງທ່ານໃນຫນ້ານີ້ເພື່ອເປີດໃຊ້ຜະລິດຕະພັນນີ້.",
  Licence_Key:"ລະຫັດໃບອະນຸຍາດ",
  Enter_Licence_Key:"ໃສ່ລະຫັດໃບອະນຸຍາດ",
  Purchased:"ຊື້ແລ້ວ",
  Enable_Feature:"ເປີດໃຊ້ຄຸນສົມບັດ",
  Cancel_Subscription:"ຍົກເລີກການສະໝັກໃຊ້",
  Subscription_Removed:"ລຶບການສະໝັກໃຊ້ອອກແລ້ວ",
  Select_Active_Subscription:"ເລືອກການສະໝັກໃຊ້ທີ່ເຄື່ອນໄຫວຢູ່ເພື່ອແກ້ໄຂມັນ.",
  Remove_Subscription_Word:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການຍົກເລີກການສະໝັກໃຊ້ນີ້?",
  Delete_Company_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບບໍລິສັດທັງໝົດນີ້? ຄຳເຕືອນ: ທ່ານຈະສູນເສຍຂໍ້ມູນທີ່ເກັບໄວ້ທັງໝົດ!",
  Delete_Default_Company_Word:"ທ່ານ ບໍ່ສາມາດ ລຶບບໍລິສັດເລີ່ມຕົ້ນໄດ້. ທ່ານຕ້ອງການທີ່ຈະປັບຄໍາຮ້ອງສະຫມັກແລະຟື້ນຟູມັນກັບສະຖານະການເລີ່ມຕົ້ນ? ຄຳເຕືອນ: ທ່ານຈະສູນເສຍຂໍ້ມູນທີ່ເກັບໄວ້ທັງໝົດ!",
  Console_Warning_2:"ຢ່າຈັດການສະກຸນເງິນໃດໆໂດຍໃຊ້ແອັບພລິເຄຊັນນີ້ທີ່ບໍ່ແມ່ນຂອງເຈົ້າໃນປັດຈຸບັນ.",
  Terms_and_Conditions:"ຂໍ້ກໍານົດແລະເງື່ອນໄຂ",
  and_the:"ແລະ",
  for:"ສໍາລັບ",
  Please_Read_Word:"ກະລຸນາອ່ານແລະຕົກລົງເຫັນດີກັບ",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"ບໍ່ພົບອັດຕາການປ່ຽນແປງບາງສະກຸນເງິນ. ກະລຸນາເຊື່ອມຕໍ່ອິນເຕີເນັດ.",
  Free:"ຟຣີ",
  DB_Erase_Prompt_2:"ລຶບຖານຂໍ້ມູນຜູ້ພັດທະນາທັງໝົດອອກໝົດບໍ? ຄຳເຕືອນ: ທ່ານຈະສູນເສຍຂໍ້ມູນການຊື້ ແລະ ການສະໝັກໃຊ້ທັງໝົດ!",
  Successfully_Imported:"ນຳເຂົ້າສຳເລັດແລ້ວ",
  Select_Postage:"ເລືອກ Postage",
  No_Postage:"ບໍ່ມີບັດໄປສະນີ",
  No_Paid_Postage_Word:"ເພີ່ມສະແຕມ <a routerLink='/folder/Postage/New'>ຈ່າຍຄ່າໄປສະນີ</a> ທຳອິດ .",
  Trial_Complete:'ການທົດລອງສໍາເລັດ',
  Please_Upgrade:'ກະລຸນາອັບເກຣດເຊັກເພື່ອສືບຕໍ່ພິມ.',
  Aa:"ອ່າ",
  Color:"ສີ",
  Font:"ຟອນ",
  Guide:"ຄູ່ມື",
  Guides:"ຜູ້ແນະນຳ",
  Image:"ຮູບພາບ",
  Zoom:"ຊູມ",
  Logo:"ໂລໂກ້",
  Bank:"ທະນາຄານ",
  MICR:"MICR",
  Total:"ທັງໝົດ",
  Cancel:"ຍົກເລີກ",
  Confirm:"ຢືນຢັນ",
  Method:"ວິທີການ",
  Biometric_Security:"ຄວາມປອດໄພທາງຊີວະມິຕິ",
  Please_Login_To_Continue:"ກະລຸນາເຂົ້າສູ່ລະບົບເພື່ອສືບຕໍ່.",
  Complete:"ສໍາເລັດ",
  Sign_Up:"ລົງທະບຽນ",
  Error:"ຜິດພາດ",
  Biometrics:"ຊີວະມິຕິ",
  Percent:"ເປີເຊັນ",
  Zero_Percent:"0%",
  Top_Margin:"ຂອບເທິງ",
  Bottom_Margin:"ຂອບລຸ່ມ",
  Left_Margin:"ຂອບຊ້າຍ",
  Right_Margin:"ຂອບຂວາ",
  MICR_Margin:"ຂອບ MICR",
  Table_Margin:"ຂອບຕາຕະລາງ",
  Address_Margin:"ຂອບທີ່ຢູ່",
  Percentage_:"ເປີເຊັນ",
  Vacation_Title:"ຊື່ວັນພັກຜ່ອນ",
  Use_PIN:"ໃຊ້ PIN",
  Loading__:"ກຳລັງໂຫລດ...",
  Design_Title:"ຫົວຂໍ້ການອອກແບບ",
  Authorize:"ອະນຸຍາດ",
  Key:"ກະແຈ",
  Public_Key:"ລະຫັດສາທາລະນະ",
  Private_Key:"ກະແຈສ່ວນຕົວ",
  Authenticate:"ພິສູດຢືນຢັນ",
  Last_Payroll:"ເງິນເດືອນສຸດທ້າຍ",
  Last_Calculation:"ການຄິດໄລ່ຫຼ້າສຸດ",
  Authorized:"ອະນຸຍາດ",
  Geo_Authorized:"ສະຖານທີ່ຕັ້ງພູມສາດ: ໄດ້ຮັບອະນຸຍາດ",
  Not_Authorized:"ບໍ່ໄດ້ຮັບອະນຸຍາດ",
  Authorization_Complete:"ການອະນຸມັດສໍາເລັດ",
  Geolocation_Authorization:"ການອະນຸຍາດທີ່ຕັ້ງພູມສາດ",
  Out_of_Bounds:"ອອກຈາກຂອບເຂດ",
  Cant_Delete_Default_Design:"ບໍ່ສາມາດລຶບການອອກແບບເລີ່ມຕົ້ນໄດ້.",
  Unauthorized_Out_of_Bounds:"ບໍ່ໄດ້ຮັບອະນຸຍາດ: ເກີນຂອບເຂດ",
  Biometric_Authorization:"ການອະນຸຍາດ biometric",
  Locating_Please_Wait:"ກຳລັງຊອກຫາ, ກະລຸນາລໍຖ້າ...",
  Test_Biometrics:"ທົດສອບ biometric",
  Cheque_Margins:"ກວດເບິ່ງຂອບ",
  Percentage_Full_Error:"ຊ່ອງຂໍ້ມູນເປີເຊັນບໍ່ສາມາດຖືກຕັ້ງເກີນ 100% ເປີເຊັນ.",
  No_Payroll_Text:"ເພີ່ມ <a routerLink='/folder/Employee/New'>ລູກ​ຈ້າງ</a> ຫຼື <an routerLink='/folder/Payee/New'>ຜູ້ຈ່າຍເງິນ</a> ແລະ <a routerLink='/folder/Schedule/New'>ຕາຕະລາງ</a>.",
  Design_Saved:"ບັນທຶກການອອກແບບແລ້ວ",
  Default_Design_Selected:"ເລືອກການອອກແບບເລີ່ມຕົ້ນແລ້ວ",
  Partial_Import:"ການນໍາເຂົ້າບາງສ່ວນ",
  Partial_Export:"ການສົ່ງອອກບາງສ່ວນ",
  Entire_Import:"ນຳເຂົ້າທັງໝົດ",
  Entire_Export:"ສົ່ງອອກທັງໝົດ",
  Existing_Password:"ກະລຸນາໃສ່ລະຫັດຜ່ານທີ່ມີຢູ່ຂອງທ່ານ:",
  Existing_PIN:"ກະລຸນາໃສ່ລະຫັດ PIN ທີ່ມີຢູ່ຂອງທ່ານ:",
  Pin_Change_Header:"ການຢືນຢັນ PIN",
  Pin_Change_SubHeader:"ໃສ່ລະຫັດ PIN ເກົ່າຂອງທ່ານເພື່ອຢືນຢັນການປ່ຽນແປງ.",
  Pass_Change_Header:"ການຢືນຢັນລະຫັດຜ່ານ",
  Pass_Change_SubHeader:"ໃສ່ລະຫັດຜ່ານເກົ່າຂອງທ່ານເພື່ອຢືນຢັນການປ່ຽນແປງ.",
  PIN_Enter_Message:"ໃສ່ລະຫັດ PIN ຂອງທ່ານເພື່ອຢືນຢັນ.",
  Password_Enter_Message:"ໃສ່ລະຫັດຜ່ານຂອງທ່ານເພື່ອຢືນຢັນ.",
  Pin_Updated_Success:"ອັບເດດ PIN ສຳເລັດແລ້ວ!",
  Pin_Updated_Fail:"PIN ບໍ່ສາມາດອັບເດດໄດ້.",
  Pass_Updated_Success:"ອັບເດດລະຫັດຜ່ານສຳເລັດແລ້ວ.",
  Pass_Updated_Fail:"ບໍ່ສາມາດອັບເດດລະຫັດຜ່ານໄດ້.",
  Preview_Payment:"ເບິ່ງຕົວຢ່າງການຈ່າຍເງິນ",
  Preview_Payroll:"ເບິ່ງຕົວຢ່າງເງິນເດືອນ",
  No_Payments_Created:"ບໍ່ພົບການຈ່າຍເງິນທີ່ຖືກສ້າງຂຶ້ນ.",
  Payment_Preview:"ຕົວຢ່າງການຈ່າຍເງິນ",
  Enable_Payee:"ເປີດໃຊ້ຜູ້ຮັບເງິນ",
  Rendered:"ບັນຍາຍ",
  No_Email:"ບໍ່ມີອີເມວ",
  Setup_Cheques:"ຕັ້ງຄ່າການກວດສອບ",
  name:"ຊື່",
  address:"ທີ່ຢູ່",
  address_2:"ແຖວທີ່ຢູ່ 2",
  city:"ເມືອງ",
  province:"ແຂວງ",
  postal_code:"ລະຫັດໄປສະນີ/ລະຫັດໄປສະນີ",
  country:"ປະເທດ",
  username:"ຊື່ຜູ້ໃຊ້",
  full_name:"ຊື່ເຕັມ",
  birthday:"ວັນເດືອນປີເກີດ",
  email:"ອີເມວ",
  phone:"ໂທລະສັບ",
  employees:"ພະນັກງານ",
  addresses:"ທີ່ຢູ່",
  payment_amount_limit:"ຈໍາກັດຈໍານວນການຈ່າຍເງິນ",
  total_limit:"ຈຳກັດທັງໝົດ",
  payees:"ຜູ້ຮັບເງິນ",
  description:"ລາຍລະອຽດ",
  address_line_one:"ທີ່ຢູ່ Line One",
  address_line_two:"ທີ່ຢູ່ແຖວສອງ",
  image:"ຮູບພາບ",
  account_holder:"ເຈົ້າຂອງບັນຊີ",
  cheque_starting_id:"ກວດເບິ່ງ ID ເລີ່ມຕົ້ນ",
  transit_number:"ໝາຍເລກໂດຍສານ",
  institution_number:"ໝາຍເລກສະຖາບັນ",
  designation_number:"ໝາຍເລກກຳນົດ",
  account_number:"ເລກບັນຊີ",
  currency:"ສະກຸນເງິນ",
  signature:"ລາຍເຊັນ",
  payment_payroll_limit:"ຈຳກັດການຈ່າຍເງິນ",
  total_limi:"ຈຳກັດທັງໝົດ",
  date:"ວັນທີ",
  printed_memo:"ພິມບັນທຶກ",
  banks:"ທະນາຄານ",
  signature_image:"ຮູບພາບລາຍເຊັນ",
  address_name:"ຊື່ທີ່ຢູ່",
  notes:"ບັນທຶກ",
  design:"ອອກແບບ",
  title:"ຫົວຂໍ້",
  frequency:"ຄວາມຖີ່",
  fax:"ແຟັກ",
  salaries:"ເງິນເດືອນ",
  salary_ids:"ID ເງິນເດືອນ",
  start_time:"ເວລາເລີ່ມຕົ້ນ",
  end_time:"ເວລາສິ້ນສຸດ",
  paid:"ຈ່າຍແລ້ວ",
  overtime_percentage:"ເປີເຊັນທີ່ຈ່າຍ",
  overtime_amount:"ຈ່າຍເປັນຈໍານວນຄົງທີ່",
  first_name:"ຊື່ແທ້",
  last_name:"ນາມສະກຸນ",
  moderation:"ປານກາງ",
  create:"ສ້າງ",
  edit:"ແກ້ໄຂ",
  destroy:"ທໍາລາຍ",
  day_start_time:"ມື້_ເລີ່ມ_ເວລາ",
  day_end_time:"ມື້_ທ້າຍ_ເວລາ",
  fullname:"ຊື່",
  time:"ເວລາ",
  auto_send:"ສົ່ງອັດຕະໂນມັດ",
  time_method:"ວິທີການເວລາ",
  schedules:"ຕາຕະລາງ",
  indefinite_payroll_enabled:"ເປີດນຳໃຊ້ແບບບໍ່ມີກຳນົດ",
  amount:"ຈໍານວນ",
  repeat:"ເຮັດຊ້ຳ",
  always_enabled:"ເປີດໃຊ້ງານສະເໝີ",
  start_date:"ວັນທີ່ເລີ່ມ",
  end_date:"ວັນທີສິ້ນສຸດ",
  Cheque_Total:"ກວດເບິ່ງທັງໝົດ",
  Total_Amount:"ຈໍານວນທັງຫມົດ:",
  Amounts:"ຈຳນວນ:",
  Images:"ຮູບພາບ",
  Files:"ໄຟລ໌",
  Previewing:"ກຳລັງເບິ່ງຕົວຢ່າງ:",
  Insert:"ແຊກ",
  Preview_Import:"ເບິ່ງຕົວຢ່າງການນໍາເຂົ້າ",
  Entry:"ເຂົ້າ",
  Entries:"ລາຍການ",
  No_Entries:"ບໍ່ມີລາຍການ",
  Import_Type:"ປະເພດການນໍາເຂົ້າ",
  Select_Details:"ເລືອກລາຍລະອຽດ",
  Select_Payee:"ເລືອກຜູ້ຮັບເງິນ",
  Enable_Holidays:"ເປີດໃຊ້ວັນພັກ",
  Disable_Holidays:"ປິດການໃຊ້ງານວັນພັກ",
  Wire_Transfer:"ໂອນສາຍ",
  New_Export:"ການສົ່ງອອກໃຫມ່",
  Export_Data:"ສົ່ງອອກຂໍ້ມູນ",
  Export_Data_Word:"ເລືອກປະເພດຂອງໄຟລ໌ທີ່ຈະສົ່ງອອກແລະດາວໂຫຼດ.",
  Export_File:"ໄຟລ໌ສົ່ງອອກ",
  Mode:"ໂໝດ",
  Times:"ເວລາ",
  Widgets:"ວິດເຈັດ",
  Slider:"ຕົວເລື່ອນ",
  Set_Details:"ກໍານົດລາຍລະອຽດ",
  Select_Type:"ເລືອກປະເພດ",
  Display_Slider:"ສະແດງຕົວເລື່ອນ",
  Dashboard_Slider:"ຕົວເລື່ອນ Dashboard",
  Dashboard_Mode:"ໂໝດ Dashboard",
  Show_Intro:"ສະແດງບົດແນະນຳ",
  Show_Payrolls:"ສະແດງເງິນເດືອນ",
  Show_Holidays:"ສະແດງວັນພັກ",
  Show_Invoices:"ສະແດງໃບແຈ້ງໜີ້",
  Show_Cheques:"ສະແດງການກວດສອບ",
  Enabled_Widgets:"ເປີດໃຊ້ Widgets",
  Disabled_Widgets:"Widgets ທີ່ຖືກປິດໃຊ້ງານ",
  Colors:"ສີ",
  Barcodes:"ບາໂຄດ",
  View_Timers:"ເບິ່ງໂມງຈັບເວລາ",
  Gradients:"ຊັ້ນສີ",
  No_Info:"ບໍ່ມີຂໍ້ມູນ",
  Disable:"ປິດໃຊ້ງານ",
  Show_Layer:"ສະແດງຊັ້ນຂໍ້ມູນ",
  Hide_Layer:"ເຊື່ອງຊັ້ນຂໍ້ມູນ",
  Text_Layer:"ຊັ້ນຂໍ້ຄວາມ",
  Secondly:"ອັນທີສອງ",
  Minutely:"ນາທີ",
  nine_am:"9:00 AM",
  five_pm:"5:00 ໂມງແລງ",
  Enable_Address:"ເປີດໃຊ້ທີ່ຢູ່",
  Invalid_File_Type:"ຂໍອະໄພ, ປະເພດໄຟລ໌ທີ່ບໍ່ຖືກຕ້ອງຖືກເລືອກ. ປະເພດໄຟລ໌ທີ່ຮອງຮັບ:",
  Error_Updating_Entry:"ຂໍອະໄພ, ມີຄວາມຜິດພາດໃນການອັບເດດລາຍການນີ້.",
  Schedule_Timing_Alert:"ຜິດພາດ: ເວລາເລີ່ມຕົ້ນກຳນົດເວລາເປັນຄ່າຫຼັງຈາກເວລາສິ້ນສຸດ.",
  Select_Multiple_Payments_Prompt:"ບໍ່ໄດ້ເລືອກການຈ່າຍເງິນ. ເລືອກຢ່າງໜ້ອຍໜຶ່ງການຈ່າຍເງິນ.",
  Select_Multiple_Cheques_Prompt:"ບໍ່ໄດ້ເລືອກການກວດສອບ. ກະລຸນາເລືອກຢ່າງໜ້ອຍໜຶ່ງການກວດສອບ.",
  Select_Multiple_Items_Prompt:"ບໍ່ໄດ້ເລືອກລາຍການ. ກະລຸນາເລືອກຢ່າງໜ້ອຍໜຶ່ງລາຍການ.",
  Paymemt_Multiple_Delete_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບການຈ່າຍເງິນຫຼາຍອັນ?",
  Cheque_Multiple_Delete_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບການກວດສອບຫຼາຍອັນ?",
  Payee_Multiple_Delete_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບຜູ້ຈ່າຍເງິນຫຼາຍອັນ?",
  Employee_Multiple_Delete_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບພະນັກງານຫຼາຍຄົນ?",
  MICR_Warning:"ໝາຍເຫດ: ບາງເຄື່ອງພິມ ແລະອຸປະກອນອາດຈະບໍ່ສະແດງຟອນ MICR ຢ່າງຖືກຕ້ອງ.",
  Automatically_Send:"ສົ່ງອັດຕະໂນມັດ",
  Type:"ປະເພດ",
  Payment_Type:"ປະເພດການຈ່າຍເງິນ",
  Auto_Send:"ສົ່ງອັດຕະໂນມັດ",
  Automatically_Process:"ອັດຕະໂນມັດຂະບວນການ",
  Auto_Process:"ຂະບວນການອັດຕະໂນມັດ",
  Image_Based:"ຕາມຮູບພາບ",
  Font_Based:"ອີງໃສ່ຕົວອັກສອນ",
  Rerender:"ສະແດງຄືນໃໝ່",
  Rendering:"ກຳລັງສະແດງຜົນ",
  Render:"ໄຟລ໌",
  Top:"ເທິງ",
  Middle:"ກາງ",
  Bottom:"ລຸ່ມ",
  Top_Left:"ຊ້າຍເທິງ",
  Top_Center:"ສູນສູງສຸດ",
  Top_Right:"ຂວາເທິງ",
  Middle_Left:"ກາງຊ້າຍ",
  Middle_Center:"ສູນກາງ",
  Middle_Right:"ຂວາກາງ",
  Bottom_Left:"ຊ້າຍລຸ່ມ",
  Bottom_Center:"ກາງລຸ່ມ",
  Bottom_Right:"ຂວາລຸ່ມ",
  Numbers:"ຕົວເລກ",
  Verified:"ຢັ້ງຢືນແລ້ວ",
  Paper_Size:"ຂະໜາດເຈ້ຍ",
  New_Device:"ອຸປະກອນໃໝ່",
  Add_Device:"ເພີ່ມອຸປະກອນ",
  Remove_Device:"ເອົາອຸປະກອນອອກ",
  Delete_Device:"ລຶບອຸປະກອນ",
  Block_Device:"ຂັດຂວາງອຸປະກອນ",
  Block:"ຕັນ",
  Unblock:"ປົດບລັອກ",
  Table:"ໂຕະ",
  Scan_Login_Code:"ສະແກນລະຫັດເຂົ້າສູ່ລະບົບ",
  Login_Code:"ລະຫັດເຂົ້າສູ່ລະບົບ",
  Scan_Code:"ສະແກນລະຫັດ",
  Scan_QR_Code:"ສະແກນລະຫັດ QR",
  Select_All:"ເລືອກທັງຫມົດ",
  Deselect_All:"ຍົກເລີກການເລືອກທັງໝົດ",
  Increase:"ເພີ່ມຂຶ້ນ",
  Decrease:"ຫຼຸດລົງ",
  Bold:"ກ້າຫານ",
  Text:"ຂໍ້ຄວາມ",
  Style:"ແບບ",
  Italic:"ຕົວອຽງ",
  QR_Code:"ລະຫັດ QR",
  Barcode:"ບາໂຄດ",
  Browse_Images:"ຊອກຫາຮູບພາບ",
  Browse_Files:"ຊອກຫາໄຟລ໌",
  Background_Image:"ຮູບພາບພື້ນຫຼັງ",
  Logo_Image:"ຮູບໂລໂກ້",
  Header_Image:"ຮູບພາບສ່ວນຫົວ",
  Bank_Image:"ຮູບພາບທະນາຄານ",
  Cut_Lines:"ຕັດເສັ້ນ",
  Background:"ຄວາມເປັນມາ",
  License:"ໃບອະນຸຍາດ",
  One_License:"1 ໃບອະນຸຍາດ:",
  Licensed:"ອະນຸຍາດໃຫ້:",
  Not_Licensed:"ບໍ່ມີໃບອະນຸຍາດ",
  Enter_Serial:"ໃສ່ Serial",
  Serial_Key:"Serial Key",
  Serial:"ລໍາດັບ",
  Product_Key:"ລະຫັດຜະລິດຕະພັນ",
  Check_Product_Key:"ກວດເບິ່ງລະຫັດຜະລິດຕະພັນ",
  Add_Product_Key:"ເພີ່ມລະຫັດຜະລິດຕະພັນ",
  Verifying_Purchase:"ກຳລັງກວດສອບການຊື້...",
  Print_Envelope:"ພິມຊອງຈົດໝາຍ",
  Envelope:"ຊອງຈົດໝາຍ",
  Thank_You:"ຂອບໃຈ!",
  Scale:"ຂະໜາດ",
  Print_Scale:"ຂະໜາດພິມ",
  Borders:"ຊາຍແດນ",
  VOID:"ໂມໂຫ",
  Void_Cheque:"ການກວດສອບໂມ້",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ຈ່າຍໃຫ້ຄໍາສັ່ງຂອງ:",
  NO_DOLLARS:"ບໍ່ມີໂດລາ ",
  ONE_DOLLAR:"ນຶ່ງໂດລາ",
  DOLLARS:" ໂດລາ",
  AND:" ແລະ ",
  CENTS:" ເຊັນ.",
  NO_:"ບໍ່ ",
  ONE_:"ຫນຶ່ງ ",
  AND_NO_:"ແລະບໍ່ ",
  _AND_ONE_:"ແລະຫນຶ່ງ ",
  DOLLARS_AND_ONE_CENT:" ແລະຫນຶ່ງເຊັນ.",
  AND_NO_CENTS:" ແລະສູນສູນ.",
  CHEQUE_PRINT_DATE:"ວັນທີ:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"ວຽກງານເບື້ອງຕົ້ນ",
  Inital_Setup:"ການຕິດຕັ້ງເບື້ອງຕົ້ນ",
  Welcome_To:"ຍິນ​ດີ​ຕ້ອນ​ຮັບ​ສູ່ ",
  Welcome:"ຍິນດີຕ້ອນຮັບ",
  Swipe:"ປັດ",
  Intro_Setup:"ຕັ້ງຄ່າແນະນໍາ",
  Introduction:"ແນະນໍາ",
  CHEQUE_PRINT_CREDIT:"ຂັບເຄື່ອນໂດຍ ກວດກາ",
  Title_Intro_Word:"ຍິນດີຕ້ອນຮັບກັບ ກວດກາ",
  Title_Intro:"ການແນະນໍາການກວດສອບ",
  Title_Setup:"ຕັ້ງຄ່າການກວດສອບ",
  PayPal_Default_Email_Message:"ທ່ານໄດ້ຮັບການໂອນ PayPal ໃໝ່. [ຂັບເຄື່ອນໂດຍ ກວດກາ]",
  Cheques_App_Export:"ສົ່ງອອກໂດຍການກວດສອບ",
  Post_Intro_Thanks:"ຂໍຂອບໃຈທີ່ທ່ານເລືອກ ກວດກາ. ຂະບວນການຕິດຕັ້ງແມ່ນສໍາເລັດແລ້ວ.",
  Post_Intro_Word:"ເລີ່ມຕົ້ນໂດຍການພິມອອກເຊັກທໍາອິດຂອງທ່ານ, ເພີ່ມການຈ່າຍເງິນໃນອະນາຄົດ, ຫຼືເພີ່ມພະນັກງານເຂົ້າໃນບັນຊີເງິນເດືອນ.",
  Upgrade_Required:"ກວດກາ ຮຽກຮ້ອງໃຫ້ເຈົ້າເປັນເຈົ້າຂອງຊອບແວລຸ້ນທີ່ນິຍົມກວ່າເພື່ອເຊື່ອງຂໍ້ຄວາມນີ້ ແລະປົດລັອກຄຸນສົມບັດເພີ່ມເຕີມ.",
  Upgrade_Title:"ກວດເຊັກ",
  Mailout_Prompt:"ນອກຈາກນັ້ນ, ທ່ານອາດຈະເລືອກທີ່ຈະມີ Checks mail-out ການກວດສອບເງິນເດືອນຂອງທ່ານສໍາລັບທ່ານ.",
  Mailed_Cheque:"ການກວດກາທາງໄປສະນີ:",
  Mailed_Cheque_Color:"ການກວດກາທາງໄປສະນີ (ສີ):",
  Terms_Purchase:"ທຸກການຊື້ເຄື່ອງອີເລັກໂທຣນິກດ້ວຍເຊັກແມ່ນສາມາດຄືນເງິນໄດ້ເຕັມທີ່ພາຍໃນ 30 ວັນນັບຈາກມື້ທີ່ຊື້. ກະລຸນາອ່ານ ແລະຕົກລົງເຫັນດີກັບ <a href='#'>ຂໍ້ກຳນົດ ແລະເງື່ອນໄຂ</a> ກ່ອນທີ່ຈະດຳເນີນການຕໍ່.",
  Dashboard_Setup:"ຕັ້ງຄ່າເຄື່ອງພິມຕົ້ນຕໍ",
  Dashboard_Add:"ເພີ່ມບັນຊີທະນາຄານຕົ້ນຕໍ",
  Dashboard_Customize:"ເລືອກແມ່ແບບການກວດສອບ",
  Dashboard_Job_Salary:"ສ້າງວຽກຂອງເຈົ້າ ແລະເພີ່ມເງິນເດືອນຂອງເຈົ້າ",
  Dashboard_Employees:"ຕິດຕາມພະນັກງານ ແລະຜູ້ຮັບເງິນ",
  Dashboard_Print:"ພິມ ແລະສົ່ງເຊັກເງິນຂອງເຈົ້າ",
  Dashboard_Payroll:"ການພິມເງິນເດືອນຂອງເຈົ້າອັດຕະໂນມັດ",
  Dashboard_PayPal:"ຕັ້ງຄ່າ Payroll / Payouts",
  Begin_Setup:"ເລີ່ມຕົ້ນການຕິດຕັ້ງ",
  Get_Started:"ເລີ່ມ​ຕົ້ນ",
  Purchase:"ຊື້",
  Lockdown:"ການ​ປິດ​ຂັງ",
  Unlock:"ປົດລັອກ",
  Detailed:"ລາຍລະອຽດ",
  Log_In:"ເຂົ້າ​ສູ່​ລະ​ບົບ",
  Login:"ເຂົ້າ​ສູ່​ລະ​ບົບ",
  Launch:"ເປີດຕົວ",
  Register:"ລົງທະບຽນ",
  Finish:"ສໍາເລັດຮູບ",
  List:"ບັນຊີລາຍຊື່",
  Weekends:"ທ້າຍອາທິດ",
  Weekly:"ປະຈໍາອາທິດ",
  PayPal_Default_Subject:"ການຈ່າຍເງິນໃຫມ່",
  Payment_Message:"ຂໍ້ຄວາມການຈ່າຍເງິນ",
  PayPal_Default_Payment_Note:"ຂອບ​ໃຈ",
  Setup_Your_Cheqing_Account:"ກວດສອບບັນຊີ",
  Add_Your_Primary_Cheqing_Account:"ເພີ່ມບັນຊີເຊັກຫຼັກຂອງເຈົ້າ.",
  Welcome_Word:"ເຮັດໃຫ້ງ່າຍດາຍແລະອັດຕະໂນມັດການຈ້າງງານແລະການຄຸ້ມຄອງຊັບພະຍາກອນມະນຸດ.",
  Get_Started_Quickly:"ພຽງແຕ່ຕອບຄໍາຖາມງ່າຍໆຈໍານວນຫນ້ອຍທີ່ຈະຊ່ວຍໃຫ້ພວກເຮົາເລີ່ມຕົ້ນ ... ",
  Done_Intro_Word:"ການຕິດຕັ້ງສໍາເລັດ",
  PIN_Protected:"ລະຫັດຜ່ານ & PIN ປ້ອງກັນ",
  Enter_New_PIN:"ໃສ່ລະຫັດ PIN ໃໝ່:",
  Enter_PIN:"ໃສ່ລະຫັດ PIN:",
  Invalid_PIN:"ປ້ອນ PIN ບໍ່ຖືກຕ້ອງ.",
  Account_Identifier:"ຕົວລະບຸບັນຊີ",
  New_Account_Identifier:"ຕົວລະບຸບັນຊີໃໝ່",
  attempt:"ຄວາມພະຍາຍາມ",
  attempts:"ຄວາມພະຍາຍາມ",
  remaining:"ຍັງເຫຼືອ",
  Language:"ພາສາ",
  languages:"ພາສາ",
  select_languages:"ເລືອກພາສາ",
  Deposit:"ເງິນຝາກ",
  Hire_One_Now:"ຈ້າງຄົນດຽວດຽວນີ້",
  App_Locked:"ແອັບພລິເຄຊັນຖືກລັອກ.",
  Skip_:"ຂ້າມ",
  Skip_to_Dashboard:"ຂ້າມໄປຫາ Dashboard",
  Dashboard:"ແຜງໜ້າປັດ",
  Import:"ນໍາເຂົ້າ",
  Admin:"ຜູ້ບໍລິຫານ",
  New_Admin:"ຜູ້ບໍລິຫານໃໝ່",
  Settings:"ການຕັ້ງຄ່າ",
  Color_Picker:"ຕົວເລືອກສີ",
  Font_Picker:"ຕົວເລືອກຕົວອັກສອນ",
  Logout:"ອອກ​ຈາກ​ລະ​ບົບ",
  Close:"ປິດ",
  Close_menu:"ປິດ",
  Excel:"ໄຟລ໌ Excel",
  Csv:"ໄຟລ໌ CSV",
  Sql:"ໄຟລ໌ SQL",
  Json:"ໄຟລ໌ JSON",
  Url:"ນໍາເຂົ້າໂດຍ URL",
  Back:"ກັບຄືນ",
  Timers:"ເຄື່ອງຈັບເວລາ",
  Cheque:"ກວດສອບ",
  Print:"ພິມ",
  Designs:"ການອອກແບບ",
  Pause_Printing:"ຢຸດການພິມ",
  Resume_Printing:"ສືບຕໍ່ການພິມ",
  Printing_Paused:"ຢຸດການພິມ",
  PrintingUnavailable:"ຂໍອະໄພ! ການພິມບໍ່ສາມາດໃຊ້ໄດ້ໃນລະບົບນີ້.",
  Prints_Paused:"ຢຸດການພິມ",
  Administration:"ການບໍລິຫານ",
  Loading:"ກຳລັງໂຫຼດ",
  Unnamed:"ບໍ່ມີຊື່",
  error:"ຂໍອະໄພ, ການກວດສອບນີ້ບໍ່ສາມາດເປີດເພື່ອເບິ່ງໄດ້.",
  No_Cheques_To_Print:"ບໍ່ມີການກວດກາເພື່ອພິມ",
  No_Cheques_To_Print_Word:"ສ້າງ <a routerLink='/folder/Cheque/New'>ເຊັກໃຫມ່</a>.",
  New_Cheque:"ການກວດສອບໃຫມ່",
  Start_One_Now:"ເລີ່ມຕົ້ນຫນຶ່ງດຽວນີ້",
  Edit_Cheque:"ກວດສອບການດັດແກ້",
  Select_Cheques:"ເລືອກການກວດສອບ",
  Select_Employee:"ເລືອກພະນັກງານ",
  Default_Printer:"ເຄື່ອງພິມເລີ່ມຕົ້ນ",
  Reassign:"ມອບໝາຍໃໝ່",
  Configure:"ຕັ້ງຄ່າ",
  Template:"ແມ່ແບບ",
  Designer:"ຜູ້ອອກແບບ",
  Edit_Designs:"ດັດແກ້ການອອກແບບ",
  New_Design:"ການອອກແບບໃຫມ່",
  Next:"ຕໍ່ໄປ",
  Save:"ບັນທຶກ",
  Name:"ຊື່",
  Mail:"ຈົດໝາຍ",
  Amount:"ຈໍາ​ນວນ",
  Date:"ວັນທີ",
  PayPal:"PayPal",
  Payouts:"ການຈ່າຍເງິນ",
  PayPal_Label:"ປ້າຍຊື່ PayPal",
  Email_Username:"ອີເມລ໌ / ຊື່ຜູ້ໃຊ້",
  Client_ID:"ລະຫັດລູກຄ້າ",
  Sandbox_Email:"Sandbox Email",
  PayPal_Email:"ອີເມວ PayPal",
  PayPal_Username:"ຊື່ຜູ້ໃຊ້ API",
  PayPal_Payouts:"ການຈ່າຍເງິນ PayPal",
  Select_PayPal_Key:"ເລືອກລະຫັດ PayPal",
  Secret:"ລັບ",
  API_Secret:"ຄວາມລັບ API",
  PayPal_API_Keys:"ກະແຈ PayPal",
  New_PayPal_Key:"ລະຫັດ PayPal ໃຫມ່",
  Email_Subject:"ຫົວຂໍ້ອີເມວ",
  Email_Message:"ຂໍ້ຄວາມອີເມວ",
  Payout_Options:"ທາງເລືອກການຈ່າຍເງິນ",
  Payment_Note:"ຫມາຍເຫດການຈ່າຍເງິນ",
  Enable_Employee:"ເປີດໃຫ້ພະນັກງານ",
  Enable_Production_Mode:"ເປີດໃຊ້ຮູບແບບການຜະລິດ",
  Sandbox_Username:"ຊື່ຜູ້ໃຊ້ Sandbox",
  Sandbox_Signature:"ລາຍເຊັນ Sandbox",
  Sandbox_Password:"ລະຫັດຜ່ານ Sandbox",
  Production_Username:"ຊື່ຜູ້ໃຊ້ການຜະລິດ",
  Production_Signature:"ລາຍເຊັນການຜະລິດ",
  Production_Password:"ລະຫັດຜ່ານການຜະລິດ",
  Production_Email:"ອີເມລ໌ການຜະລິດ",
  API_Client_ID:"ລະຫັດລູກຄ້າ API",
  API_Signature:"ລາຍເຊັນ API",
  API_Password:"ລະຫັດຜ່ານ API",
  API_Username:"ຊື່ຜູ້ໃຊ້ API",
  Secret_Key:"ກະແຈລັບ",
  Sandbox:"ກ່ອງຊາຍ",
  Production:"ການຜະລິດ",
  Sandbox_Keys:"ກະແຈ Sandbox",
  Production_Keys:"ກະແຈການຜະລິດ",
  API_Title:"ຊື່ API",
  Production_Mode:"ຮູບແບບການຜະລິດ",
  Account_Label:"ປ້າຍບັນຊີ",
  No_PayPal_Setup:"ບໍ່ມີກະແຈ PayPal",
  Enable_PayPal_Account:"ເປີດໃຊ້ບັນຊີ PayPal",
  No_PayPal_Word:"ເພີ່ມ <a routerLink='/folder/Invoice/New'>PayPal API Key</a> ຂອງທ່ານ.",
  Printed_Memo:"ບັນທຶກການພິມ",
  Employee:"ລູກ​ຈ້າງ",
  View_Employee:"ເບິ່ງພະນັກງານ",
  Mailing_Address:"ທີ່ຢູ່ທາງໄປສະນີ",
  Company_Address:"ທີ່ຢູ່ບໍລິສັດ",
  Select_Company_Address:"ເລືອກທີ່ຢູ່ບໍລິສັດ",
  Address:"ທີ່ຢູ່",
  Any_Day:"ມື້ໃດ",
  Address_Name:"ຊື່ທີ່ຢູ່",
  Unit:"ຫົວໜ່ວຍ",
  Account:"ບັນຊີ",
  Bank_Account:"ບັນ​ຊີ​ທະ​ນາ​ຄານ",
  Account_Limits:"ເປີດໃຊ້ຂອບເຂດຈໍາກັດບັນຊີ",
  Payroll:"ເງິນເດືອນ",
  New_Payroll:"ເງິນເດືອນໃຫມ່",
  No_Payroll:"ບໍ່ມີເງິນເດືອນທີ່ຈະມາເຖິງ",
  Upcoming_Holiday:"ວັນພັກທີ່ຈະມາເຖິງ:",
  Invoice_Due:"ໃບແຈ້ງໜີ້ຄົບກຳນົດ:",
  New_Invoice:"ໃບແຈ້ງໜີ້ໃໝ່",
  No_Invoices:"ບໍ່ມີໃບເກັບເງິນ",
  No_Invoices_Word:"ສ້າງ <a routerLink='/folder/Invoice/New'>ໃບເກັບເງິນ</a> ທຳອິດ.",
  Cheque_Due:"ເຊັກຄົບກຳນົດ:",
  Payrolls:"ເງິນເດືອນ",
  Sandbox_Mode:"ໂໝດ Sandbox",
  Hire:"ຈ້າງ",
  Pay:"ຈ່າຍ",
  New:"ໃຫມ່",
  Add:"ຕື່ມ",
  Make:"ເຮັດ",
  Time:"ເວລາ",
  Write:"ຂຽນ",
  Holiday:"ວັນພັກ",
  Holidays:"ວັນພັກ",
  Next_Holiday:"ວັນພັກຕໍ່ໄປ:",
  All_Done:"ຫມົດແລ້ວ!",
  Employees:"ພະນັກງານ",
  Payees:"ຜູ້ຈ່າຍເງິນ",
  Job:"ວຽກ",
  Jobs:"ວຽກ",
  Invoice:"ໃບແຈ້ງໜີ້",
  Invoices:"ໃບແຈ້ງໜີ້",
  Vacations:"ວັນພັກ",
  Cheques:"ກວດກາ",
  Brand_Cheques:"ຍີ່ຫໍ້",
  Payment:"ການຈ່າຍເງິນ",
  Enable_Payment:"ເຮັດໃຫ້ສາມາດຊໍາລະເງິນ",
  Payments:"ການຈ່າຍເງິນ",
  Schedule:"ຕາຕະລາງ",
  Schedules:"ຕາຕະລາງ",
  Timesheet:"ຕາຕະລາງເວລາ",
  Timesheets:"ຕາຕະລາງເວລາ",
  Salary:"ເງິນເດືອນ",
  New_Address:"ທີ່ຢູ່ໃໝ່",
  Address_2:"ທີ່ຢູ່ (ແຖວ 2)",
  _City:"ເມືອງ",
  _State:"ລັດ/ສຸພາສິດ",
  City:"ເມືອງ/ເມືອງ",
  State:"ລັດ/ແຂວງ",
  Postal:"ລະຫັດໄປສະນີ/ລະຫັດໄປສະນີ",
  ZIP:"ໄປສະນີ / ZIP",
  Country:"ປະເທດ",
  Addresses:"ທີ່ຢູ່",
  Required_Options:"ຕົວເລືອກທີ່ຕ້ອງການ",
  Optional_Options:"ທາງເລືອກທາງເລືອກ",
  Additional_Options:"ທາງເລືອກເພີ່ມເຕີມ",
  Required:"ຕ້ອງການ",
  Optional:"ທາງເລືອກ",
  Additional:"ເພີ່ມເຕີມ",
  No_Addresses:"ບໍ່ມີທີ່ຢູ່",
  New_Address_Word:"ເພີ່ມ <a routerLink='/folder/Address/New'>ທີ່ຢູ່</a> ອັນທຳອິດ.",
  Select_Address:"ເລືອກທີ່ຢູ່",
  No_Address:"ບໍ່ມີທີ່ຢູ່",
  Print_Cheque:"ກວດສອບການພິມ",
  Print_Cheque_Now:"ພິມກວດສອບດຽວນີ້",
  Description:"ຄໍາອະທິບາຍ",
  Pay_To_The_Order_Of:"ຈ່າຍຕາມຄໍາສັ່ງຂອງ:",
  Select_Date_Range:"ເລືອກຊ່ວງວັນທີ",
  Select_Starting_Date:"ເລືອກວັນທີເລີ່ມຕົ້ນ",
  Select_Ending_Date:"ເລືອກວັນທີສິ້ນສຸດ",
  Select_Date:"ເລືອກວັນທີ",
  Cheque_Date:"ກວດສອບວັນທີ",
  Cheque_Memo:"ກວດເບິ່ງບັນທຶກ",
  Blank_Cheque:"ການກວດສອບຫວ່າງເປົ່າ",
  Blank:"ເປົ່າ",
  No_Cheques:"ບໍ່ມີການກວດສອບ",
  No_Cheques_Word:"ພິມ <a routerLink='/folder/Cheque/New'>ກວດສອບ</a> ອັນທຳອິດຂອງທ່ານ.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"ເບິ່ງຮູບພາບ",
  View:"ເບິ່ງ",
  Modify:"ແກ້ໄຂ",
  Delete:"ລຶບ",
  Cheque_Paid:"ຈ່າຍ",
  New_Deduction:"ການຫັກອອກໃໝ່",
  Title:"ຫົວຂໍ້",
  Frequency:"ຄວາມຖີ່",
  Hourly:"ຊົ່ວໂມງ",
  Daily:"ປະຈໍາວັນ",
  Weekdays:"ມື້ເຮັດວຽກ",
  BiWeekly:"2 ອາທິດ",
  TriWeekly:"3 ອາທິດ",
  Monthly:"ປະຈໍາເດືອນ",
  MiMonthly:"2 ເດືອນ",
  Quarterly:"ປະຈໍາໄຕມາດ",
  Yearly:"ລາຍປີ",
  Every_Week:"ທຸກໆ​ອາ​ທິດ",
  Every_Payroll:"ທຸກໆເງິນເດືອນ",
  Every_Month:"ທຸກໆ​ເດືອນ",
  Annually:"ປະຈໍາປີ",
  Always_Scheduled:"ກຳນົດເວລາສະເໝີ",
  Start_Date:"ວັນ​ທີ່​ເລີ່ມ",
  End_Date:"ວັນທີສິ້ນສຸດ",
  Start_Time:"ເວລາເລີ່ມຕົ້ນ",
  End_Time:"ເວລາສິ້ນສຸດ",
  Deduction_Label:"ປ້າຍການຫັກອອກ",
  Notes:"ບັນທຶກ",
  Options:"ທາງເລືອກ",
  Enable:"ເປີດໃຊ້ງານ",
  Select_Deductions:"ເລືອກການຫັກອອກ",
  Deductions:"ການຫັກລົບ",
  No_Deductions:"ບໍ່ມີການຫັກລົບ",
  No_Deductions_Word:"ສ້າງ <a routerLink='/folder/Deduction/New'>ການຫັກອອກ</a> ອັນທຳອິດຂອງທ່ານ.",
  New_Employee:"ພະນັກງານໃຫມ່",
  No_Title:"ບໍ່ມີຊື່",
  _Name:"ຊື່",
  About_Yourself:"ກ່ຽວ​ກັບ​ຕົວ​ທ່ານ​ເອງ",
  Full_Name:"ຊື່​ເຕັມ",
  Birthday:"ວັນເກີດ",
  Email:"ອີເມລ໌",
  SMS:"ຂໍ້​ຄວາມ",
  Phone:"ໂທລະສັບ",
  Test:"ການທົດສອບ",
  Call:"ໂທ",
  Fax:"ແຟັກ",
  Printed_Note:"ບັນທຶກການພິມ",
  Position:"ຕໍາແຫນ່ງ",
  Job_Position:"ຕໍາແໜ່ງວຽກ",
  Select_a_Job:"ເລືອກວຽກ",
  Select_a_Salary:"ເລືອກເງິນເດືອນ",
  Add_a_Deduction:"ຕື່ມການຫັກອອກ",
  Taxes:"ພາສີ",
  Add_Taxes:"ເພີ່ມພາສີ",
  Date_of_Birth:"ວັນ​ເດືອນ​ປີ​ເກີດ",
  Email_Address:"ທີ່​ຢູ່​ອີ​ເມວ",
  Phone_Number:"ເບີ​ໂທລະ​ສັບ",
  Phone_Call:"ໂທລະ​ສັບ",
  Enable_on_Payroll:"ເປີດໃຊ້ງານເງິນເດືອນ",
  Select_Employees:"ເລືອກພະນັກງານ",
  No_Employees:"ບໍ່ມີພະນັກງານ",
  No_Employees_Word:"ເພີ່ມ <a routerLink='/folder/Employee/New'>ລູກ​ຈ້າງ</a> ໃໝ່ທຳອິດຂອງເຈົ້າ.",
  No_Name:"ບໍ່​ມີ​ຊື່",
  Unemployeed:"ຄົນຫວ່າງງານ",
  Employeed:"ຈ້າງງານ",
  Paid:"ຈ່າຍ",
  Enabled:"ເປີດໃຊ້ງານ",
  Disabled:"ພິການ",
  Fire:"ໄຟ",
  Not_Available:"ບໍ່​ສາ​ມາດ​ໃຊ້​ໄດ້",
  Not_Available_Word:"ພິມ <a routerLink='/folder/Invoice/New'>ໃບແຈ້ງໜີ້</a> ທຳອິດຂອງເຈົ້າ ແລະຮັບເງິນ.",
  Select_Invoices:"Select_Invoices",
  Print_Invoice_Word:"ພິມ <a routerLink='/folder/Invoice/New'>ໃບແຈ້ງໜີ້</a> ທຳອິດຂອງເຈົ້າ ແລະຮັບເງິນ.",
  Invoice_Title:"ຊື່ໃບແຈ້ງໜີ້",
  Invoice_Date:"ວັນ​ທີ່​ໃບ​ເກັບ​ເງິນ",
  Due_Date:"ວັນ​ຄົບ​ກໍາ​ນົດ",
  New_Job:"ວຽກໃຫມ່",
  Details:"ລາຍລະອຽດ",
  Customize:"ປັບແຕ່ງ",
  Customize_Dashboard:"ປັບແຕ່ງ Dashboard",
  Components:"ອົງປະກອບ",
  No_Components:"ບໍ່ມີອົງປະກອບ",
  Main_Components:"ອົງປະກອບຕົ້ນຕໍ",
  Smaller_Components:"ອົງປະກອບຂະຫນາດນ້ອຍ",
  Error_Loading_Page:"ຜິດພາດໃນການໂຫລດໜ້ານີ້.",
  Bank_Details:"ລາຍລະອຽດທະນາຄານ",
  About_Your_Job:"ກ່ຽວກັບວຽກຂອງເຈົ້າ",
  Your_Schedule:"ຕາຕະລາງຂອງທ່ານ",
  Job_Title:"ຕໍາ​ແຫນ່ງ",
  Job_Description:"ລາຍລະອຽດວຽກ",
  Job_Details:"ລາຍລະອຽດວຽກເຮັດງານທໍາ",
  Enable_Job:"ເປີດໃຊ້ວຽກ",
  Pay_Period:"ໄລຍະເວລາຈ່າຍ",
  Perpetually_Schedule:"ກໍານົດເວລາຕະຫຼອດ",
  Select_Jobs:"ເລືອກວຽກ",
  No_Jobs:"ບໍ່ມີວຽກເຮັດ",
  Add_Jobs:"ເພີ່ມວຽກ",
  No_Jobs_Word:"ເພີ່ມ <a routerLink='/folder/Job/New'>ວຽກ</a> ທຳອິດໃສ່ລາຍການ.",
  Count_Employees:"job.employee_count+' ພະນັກງານ'",
  Zero_Employees:"0 ພະນັກງານ",
  New_Leave:"ການອອກໃຫມ່",
  Leave_Name:"ອອກຊື່",
  Paid_Leave:"ການ​ອອກ​ຈາກ​ເງິນ​"​,
  Leave_Pay:"ອອກຈາກການຈ່າຍເງິນ",
  Indefinite_Leave:"ການອອກຈາກບໍ່ມີກໍານົດ",
  Indefinite_Payroll:"ເງິນເດືອນບໍ່ກໍານົດ",
  Leave:"ອອກ",
  Add_Schedules:"ເພີ່ມຕາຕະລາງ",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"ເປີດໃຊ້ການບໍ່ມີ",
  Select_Leaves:"ເລືອກໃບ",
  No_Leaves:"ບໍ່ມີໃບທີ່ບໍ່ມີ",
  Leave_Of_Absence:"ອອກຈາກການບໍ່ມີ",
  Leaves_Of_Absence:"ໃບຂອງການຂາດ",
  New_Leave_of_Absense:"ການອອກຈາກການອອກໃຫມ່",
  No_Leaves_Word:"ເພີ່ມ <a routerLink='/folder/Leave/New'>ການອອກຈາກການບໍ່ມີຄົນທໍາອິດ</a>.",
  Not_Enabled:"ບໍ່ໄດ້ເປີດໃຊ້ງານ",
  Absences:"ຂາດ",
  Payee:"ຜູ້ຈ່າຍເງິນ",
  New_Payee:"ຜູ້ຮັບເງິນໃໝ່",
  Payee_Identifier:"ຕົວລະບຸຜູ້ຮັບເງິນ",
  Payee_Name:"ຊື່ຜູ້ຮັບເງິນ",
  Payee_Title:"ຊື່ຜູ້ຮັບເງິນ",
  Payment_Memo:"ບັນທຶກການຈ່າຍເງິນ",
  Select_Payees:"ເລືອກຜູ້ຮັບເງິນ",
  No_Payees:"ບໍ່ມີຜູ້ຈ່າຍເງິນ",
  Add_Payee_Note:"ເພີ່ມ <a routerLink='/folder/Payee/New'>ຜູ້ຮັບເງິນ</a> ອັນທຳອິດ.",
  New_Payees:"ຜູ້ຮັບເງິນໃຫມ່",
  About_The_Payment_Schedule:"ຕາຕະລາງການຈ່າຍເງິນ",
  Your_Payroll_Schedule:"ເງິນເດືອນອັດຕະໂນມັດ",
  New_Payment:"ການຈ່າຍເງິນໃຫມ່",
  Identifier:"ຕົວລະບຸ",
  Select:"ເລືອກ",
  Memo:"ບັນທຶກ",
  Payment_Date:"ວັນທີຊໍາລະ",
  Mark_as_Paid:"ໝາຍເປັນຈ່າຍ",
  Select_Payments:"ເລືອກການຈ່າຍເງິນ",
  No_Payments:"ບໍ່ມີການຈ່າຍເງິນ",
  No_Payments_Word:"ສ້າງ <a routerLink='/folder/Payment/New'>ການຈ່າຍເງິນ</a> ອັນທຳອິດ.",
  Create_Payroll:"ສ້າງເງິນເດືອນ",
  Properties:"ຄຸນສົມບັດ",
  Payroll_Title:"ຫົວຂໍ້ເງິນເດືອນ",
  Payroll_Notes:"ບັນທຶກເງິນເດືອນ",
  Payroll_Setup:"ການຕັ້ງຄ່າເງິນເດືອນ",
  Tabulate_Payments:"ຕາຕະລາງການຈ່າຍເງິນ",
  Tabulate:"ຕາຕະລາງ",
  By:"ໂດຍ:",
  Payments_By:"ການຈ່າຍເງິນໂດຍ",
  Timesheets_And_Schedules:"ຕາຕະລາງເວລາແລະຕາຕະລາງ",
  Both:"ທັງສອງ",
  Items:"ລາຍການ",
  Add_Payees:"ເພີ່ມຜູ້ຮັບເງິນ",
  Add_Account:"ເພີ່ມ​ບັນ​ຊີ",
  Enable_Account:"ເປີດໃຊ້ບັນຊີ",
  Enable_Payroll:"ເປີດໃຊ້ວຽກ Payroll",
  Print_Payroll:"ພິມເງິນເດືອນ",
  No_Payrolls:"ບໍ່ມີເງິນເດືອນ",
  No_Payroll_Word:"ສ້າງ <a routerLink='/folder/Payroll/New'>ເງິນເດືອນ</a> ອັນທຳອິດຂອງເຈົ້າ.",
  View_more:"VIEW_MORE",
  Less:"ຫນ້ອຍ",
  Add_Payroll:"ຕື່ມເງິນເດືອນ",
  Select_Payroll:"ເລືອກເງິນເດືອນ",
  About_Your_Salary:"ກ່ຽວກັບເງິນເດືອນຂອງເຈົ້າ",
  Add_Salaries:"ເພີ່ມເງິນເດືອນ",
  Add_Salary:"ຕື່ມເງິນເດືອນ",
  Salaries:"ເງິນເດືອນ",
  No_Salaries:"ບໍ່ມີເງິນເດືອນ",
  No_Salaries_Word:"ເພີ່ມ <a routerLink='/folder/Salary/New'>ເງິນເດືອນ</a> ອັນທຳອິດ.",
  Select_Salaries:"ເລືອກເງິນເດືອນ",
  New_Salary:"ເງິນເດືອນໃໝ່",
  Salary_Name:"ຕົວລະບຸເງິນເດືອນ",
  Enable_Salary:"ເປີດນໍາໃຊ້ເງິນເດືອນ",
  Salary_Amount:"ຈໍານວນເງິນເດືອນ",
  New_Schedule:"ຕາຕະລາງໃຫມ່",
  Schedule_Title:"ຫົວຂໍ້ຕາຕະລາງ",
  Add_Address:"ຕື່ມທີ່ຢູ່",
  Repeat:"ເຮັດເລື້ມຄືນ",
  Design:"ອອກ​ແບບ",
  Edit_Design:"ດັດແກ້ການອອກແບບ",
  Edit_this_Design:"ແກ້ໄຂການອອກແບບນີ້",
  Repeat_Payment:"ຊໍາລະຄືນ",
  Enable_Schedule:"ເປີດໃຊ້ຕາຕະລາງ",
  Never:"ບໍ່ເຄີຍ",
  Select_Schedule:"ເລືອກຕາຕະລາງ",
  No_Schedules:"ບໍ່ມີຕາຕະລາງ",
  No_Schedules_Word:"ສ້າງ <a routerLink='/folder/Schedule/New'>ຕາຕະລາງ</a> ອັນທຳອິດ.",
  New_Administrator:"ຜູ້ບໍລິຫານໃຫມ່",
  Username:"ຊື່ຜູ້ໃຊ້",
  First_Name:"ຊື່​ແທ້",
  Last_Name:"ນາມ​ສະ​ກຸນ",
  Add_an_Address:"ເພີ່ມທີ່ຢູ່",
  Payment_Limit:"ຂອບເຂດຈໍາກັດຕໍ່ການຈ່າຍເງິນ",
  Total_Limit:"ຂອບເຂດຈໍາກັດທັງຫມົດ",
  Select_Accounts:"ເລືອກບັນຊີ",
  No_Administrators:"ບໍ່ມີຜູ້ບໍລິຫານ",
  No_Administrators_Word:"ສ້າງ <a routerLink='/folder/Administrator/New'>ບັນຊີຜູ້ເບິ່ງແຍງລະບົບ</a> ອັນທຳອິດ.",
  New_Administrators_Word:"ເພີ່ມ <a routerLink='/folder/Administrator/New'>ຜູ້​ບໍ​ລິ​ຫານ</a> ອັນທຳອິດ",
  Cloud:"ເມກ",
  Backup:"ສໍາຮອງຂໍ້ມູນ",
  Enable_iCloud:"ເປີດໃຊ້ iCloud",
  Enable_Google_Drive:"ເປີດໃຊ້ Google Drive",
  Enable_Microsoft_OneDrive:"ເປີດໃຊ້ Microsoft OneDrive",
  Automatically_Backup:"ສໍາຮອງຂໍ້ມູນອັດຕະໂນມັດ",
  FTP_Settings:"ການຕັ້ງຄ່າ FTP",
  URL_File_Prompt: "ກະລຸນາລະບຸສະຖານທີ່ສຳລັບໄຟລ໌ .xls / .xlsx / .json ທີ່ຈະນຳເຂົ້າ:",
  URL_SQL_Prompt:"ກະລຸນາລະບຸສະຖານທີ່ຂອງໄຟລ໌ SQLite ທີ່ຈະນໍາເຂົ້າ:",
  FTP_Backup:"ການສໍາຮອງຂໍ້ມູນ FTP",
  FTP_Import:"ການນໍາເຂົ້າ FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"ເຈົ້າພາບ",
  Port:"ພອດ",
  Path:"ເສັ້ນທາງ",
  Data_Path:"ເສັ້ນທາງຂໍ້ມູນ",
  Enable_FTP_Account:"ເປີດໃຊ້ບັນຊີ FTP",
  HostnameIP:"ຊື່ເຈົ້າພາບ",
  Password:"ລະຫັດຜ່ານ",
  Connection_Port:"ພອດເຊື່ອມຕໍ່",
  Enable_MySQL_Database:"ເປີດໃຊ້ຖານຂໍ້ມູນ MySQL",
  Log:"ບັນທຶກ",
  Reset:"ຣີເຊັດ",
  Erase:"ລົບ",
  Export:"ສົ່ງອອກ",
  Database:"ຖານຂໍ້ມູນ",
  Upload_CSV:"ອັບໂຫລດ CSV",
  Download_CSV:"ດາວໂຫລດ CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"ການສໍາຮອງຂໍ້ມູນ MySQL",
  Internal_Notes:"ບັນທຶກພາຍໃນ",
  Root_Path:"ເສັ້ນທາງຮາກ",
  Select_Backup:"ເລືອກສໍາຮອງຂໍ້ມູນ",
  Add_New_Backup:"ຕື່ມການສໍາຮອງຂໍ້ມູນໃຫມ່",
  First_Backup:"ຕັ້ງຄ່າການສໍາຮອງຂໍ້ມູນທໍາອິດ ... ",
  Backups:"ສໍາຮອງ",
  Add_Backup:"ຕື່ມການສໍາຮອງຂໍ້ມູນ",
  No_Backups:"ບໍ່ມີການສໍາຮອງທີ່ຈະພົບເຫັນ.",
  Select_Backup_Type:"ເລືອກປະເພດຂອງການສໍາຮອງຂໍ້ມູນທີ່ທ່ານຕ້ອງການທີ່ຈະຕັ້ງຄ່າ...",
  Backup_Type:"ປະເພດການສໍາຮອງຂໍ້ມູນ",
  FTP_Drive:"FTP Drive",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"ຂັບ",
  Microsoft_OneDrive:"ຂັບ",
  Import_Fields:"ຂົງເຂດການນໍາເຂົ້າ",
  Import_Fields_Word:"ໃຊ້ພາກນີ້ເພື່ອເລືອກ <a routerLink='/folder/Holiday/New'>ນຳເຂົ້າ</a> data.",
  Upload:"ອັບໂຫລດ",
  Download:"ດາວໂຫລດ",
  Download_JSON:"ດາວໂຫຼດເປັນຂໍ້ມູນ JSON",
  Download_SQL:"ດາວໂຫຼດເປັນໄຟລ໌ SQL",
  New_Bank:"ທະນາຄານໃຫມ່",
  New_Account:"ບັນຊີໃຫມ່",
  New_Bank_Account:"ບັນຊີທະນາຄານໃໝ່",
  Upload_Image:"ອັບໂຫລດຮູບພາບ",
  Bank_Name:"ຊື່ທະນາຄານ",
  Bank_Address:"ທີ່ຢູ່ທະນາຄານ",
  Branch_Address:"ທີ່ຢູ່ສາຂາ",
  Address_on_Cheque:"ທີ່ຢູ່",
  Cheque_Numbers:"ກວດເລກ",
  Cheque_Details:"ກວດເບິ່ງລາຍລະອຽດ",
  Bank_Logo:"ໂລໂກ້ທະນາຄານ",
  Cheque_ID:"ກວດ ID",
  Starting_Cheque_ID:"ເລີ່ມກວດ ID",
  Transit_Number:"ໝາຍເລກໂດຍສານ",
  Institution_Number:"ໝາຍເລກສະຖາບັນ",
  Designation_Number:"ຫມາຍເລກກໍານົດ",
  Account_Number:"ເລກບັນຊີ",
  Limits:"ຂອບເຂດຈໍາກັດ",
  Default_Limits:"ຂອບເຂດຈໍາກັດເລີ່ມຕົ້ນ",
  Over_Limit:"ເກີນຂອບເຂດ",
  Under_Limit:"ພາຍໃຕ້ຂອບເຂດຈໍາກັດ",
  Payroll_Limit:"ຂອບເຂດຈໍາກັດເງິນເດືອນ",
  Enable_Bank_Account:"ເປີດໃຊ້ບັນຊີທະນາຄານ",
  Select_Account:"ເລືອກບັນຊີ",
  No_Bank_Account:"ບໍ່ມີບັນຊີທະນາຄານ",
  No_Bank_Account_Word:"ເພີ່ມ <a routerLink='/folder/Accounts/New'>ບັນຊີທະນາຄານ</a> ອັນທຳອິດ.",
  Bank_Accounts:"ບັນຊີທະນາຄານ",
  No_Accounts:"ບໍ່ມີບັນຊີ",
  No_Accounts_Note:"ເພີ່ມ <a routerLink='/folder/Accounts/New'>ບັນຊີທະນາຄານ</a> ອັນທຳອິດ.",
  Cheque_Designer:"ກວດສອບການອອກແບບ",
  Cheque_Design:"ກວດສອບການອອກແບບ",
  Select_Design:"ເລືອກການອອກແບບ",
  Cheque_Designs:"ກວດສອບການອອກແບບ",
  No_Cheque_Designs:"ບໍ່ມີການອອກແບບການກວດສອບ",
  No_Cheque_Designs_Word:"ສ້າງ <a routerLink='/folder/Settings/Cheque/Design/New'>ກວດສອບການອອກແບບ</a> ຂອງທ່ານເອງ.",
  Set_Default:"ກໍາ​ນົດ​ເປັນ​ຄ່າ​ເລີ່ມ​ຕົ້ນ",
  Default:"ຄ່າເລີ່ມຕົ້ນ",
  Remove:"ເອົາອອກ",
  Folder:"ໂຟເດີ",
  Edit:"ແກ້ໄຂ",
  LoadingDots:"ກຳລັງໂຫລດ...",
  Add_a_New_File:"ເພີ່ມ <a href='#' (click)='add()'>ໄຟລ໌ໃໝ່</a> ໃສ່",
  this_folder:"ໂຟນເດີນີ້",
  FTP_Backup_Settings:"ການຕັ້ງຄ່າການສໍາຮອງຂໍ້ມູນ FTP",
  Secure_File_Transfer:"ການໂອນໄຟລ໌ທີ່ປອດໄພ",
  New_Holiday:"ວັນພັກໃຫມ່",
  Add_Holiday:"ເພີ່ມວັນພັກ",
  Holiday_Name:"ຊື່ວັນພັກ",
  Additional_Pay:"ຈ່າຍເພີ່ມເຕີມ",
  Enable_Holiday:"ເປີດໃຊ້ວັນພັກ",
  Select_Holidays:"ເລືອກວັນພັກ",
  No_Holidays:"ບໍ່ມີວັນພັກ",
  No_Holidays_Word:"ເພີ່ມ <a routerLink='/folder/Holiday/New'>ວັນພັກຜ່ອນສາທາລະນະ</a> ອັນທຳອິດ.",
  New_Import:"ການນໍາເຂົ້າໃຫມ່",
  Import_Data:"ຂໍ້ມູນການນໍາເຂົ້າ",
  Import_Data_Word:"ເລືອກປະເພດຂອງໄຟລ໌ ຫຼືວິທີການອັບໂຫລດທີ່ທ່ານເລືອກ.<br /> ທ່ານຈະສາມາດເລືອກຊ່ອງຂໍ້ມູນໃດນຶ່ງທີ່ນໍາເຂົ້າມາໃນໄຟລ໌ທີ່ກົງກັບ ພາຣາມິເຕີອັນໃດນຶ່ງໃນແອັບຯ ຫຼັງຈາກອັບໂຫລດໄຟລ໌ທີ່ຮອງຮັບ.", 
  Import_File:"ການນໍາເຂົ້າໄຟລ໌",
  Link_To_File:"ການເຊື່ອມຕໍ່ກັບໄຟລ໌",
  Select_File:"ເລືອກໄຟລ໌",
  New_Moderator:"ຜູ້ຄວບຄຸມໃຫມ່",
  Allow_Moderation:"ອະນຸຍາດໃຫ້ປານກາງ",
  Create_Paycheques:"ສ້າງເງິນເດືອນ",
  Edit_Paycheques_and_Data:"ແກ້ໄຂບັນຊີເງິນເດືອນແລະຂໍ້ມູນ",
  Destroy_Data_and_Paycheques:"ທໍາລາຍຂໍ້ມູນແລະຈ່າຍເງິນ",
  Bonus_Percentage:"ເປີເຊັນການຈ່າຍເງິນ",
  Fixed_Amount:"ຈໍານວນຄົງທີ່",
  Select_Moderator:"ເລືອກ Moderator",
  No_Moderators:"ບໍ່ມີຜູ້ຄວບຄຸມ",
  Moderators:"ຜູ້ຄວບຄຸມ",
  Moderator_Account:"ສ້າງ <a routerLink='/folder/Administrator/New'>ບັນຊີ ຜູ້ກະບົດ</a> ອັນທຳອິດ.",
  No_Moderators_Word:"ເພີ່ມ <a routerLink='/folder/Administrator/New'>ຜູ້ກະບົດ</a> ອັນທຳອິດ.",
  Defaults:"ຄ່າເລີ່ມຕົ້ນ",
  Provide_Us_Info:"ໃຫ້ຂໍ້ມູນພວກເຮົາ",
  Setup_Your_Printer:"ຕັ້ງຄ່າເຄື່ອງພິມຂອງເຈົ້າ",
  Your_Company:"ກ່ຽວກັບບໍລິສັດຂອງເຈົ້າ",
  Company_Name:"ຊື່​ບໍ​ລິ​ສັດ",
  Currency:"ສະກຸນເງິນ",
  Default_Currency:"ສະກຸນເງິນເລີ່ມຕົ້ນ",
  Base_Monthly_Income:"ລາຍໄດ້ປະຈໍາເດືອນ",
  Protection:"ການປົກປ້ອງ",
  App_Protection:"ການປົກປ້ອງແອັບຯ",
  PIN_Code_Protection:"ການປົກປ້ອງລະຫັດ PIN",
  App_Protection_Word:"ເປີດໃຊ້ວິທີການຄວາມປອດໄພທີ່ຊ່ວຍປົກປ້ອງບັນຊີຂອງທ່ານ.",
  PIN_Code:"ລະຫັດ PIN",
  Change_PIN:"ປ່ຽນ PIN",
  New_Password:"ລະຫັດຜ່ານໃໝ່",
  Geofence_Protection:"ການປົກປ້ອງຮົ້ວພູມສາດ",
  Geofence_Area:"ພື້ນທີ່ກໍານົດ",
  Distance:"ໄລຍະທາງ",
  Setup_Now:"ຕັ້ງຄ່າດຽວນີ້",
  Mile:"ໄມ",
  Km:"ກມ",
  m:"ມ",
  Facial_Recognition:"ການຮັບຮູ້ໃບຫນ້າ",
  Face:"ໃບຫນ້າ",
  Setup:"ຕັ້ງ​ຄ່າ",
  Label:"ປ້າຍຊື່",
  Password_Protection:"ການປົກປ້ອງລະຫັດຜ່ານ",
  Modify_Password:"ແກ້ໄຂລະຫັດຜ່ານ",
  New_Tax_Entry:"ການເຂົ້າພາສີໃຫມ່",
  New_Tax:"ພາສີໃຫມ່",
  Tax_Label:"ປ້າຍພາສີ",
  Perpetually_Enabled:"ເປີດໃຊ້ຕະຫຼອດການ",
  Select_Taxes:"ເລືອກພາສີ",
  Tax_Deductions:"ການຫັກພາສີ",
  No_Tax_Deductions:"ບໍ່ມີການຫັກພາສີ",
  No_Tax_Deductions_Word:"ເພີ່ມການຫັກ <a routerLink='/folder/Tax/New'>ພາສີ</a> ທຳອິດ",
  New_Timer:"ເຄື່ອງຈັບເວລາໃໝ່",
  Start:"ເລີ່ມຕົ້ນ",
  Stop:"ຢຸດ",
  Start_Timer:"ຈັບເວລາການເລີ່ມຕົ້ນ",
  Stop_Timer:"ເຄື່ອງຈັບເວລາຢຸດ",
  Timer_Active:"ຈັບເວລາການເຄື່ອນໄຫວ",
  Timer:"ໂມງຈັບເວລາ:",
  Timer_Running:"ໂມງຈັບເວລາ: (ແລ່ນ)",
  Save_Timer:"ປະຫຍັດເວລາ",
  New_Timesheet:"ແຜ່ນເວລາໃຫມ່",
  Select_Timesheets:"ເລືອກຕາຕະລາງເວລາ",
  Work_Notes:"ບັນທຶກການເຮັດວຽກ",
  Entry_Title:"ຫົວຂໍ້ການເຂົ້າ",
  No_Timesheets:"ບໍ່ມີເອກະສານເວລາ",
  No_Timesheets_Word:"ເພີ່ມ <a routerLink='/folder/Timesheet/New'>ໃບເວລາ</a> ອັນທຳອິດ.",
  Timesheet_Submitted:"ເອກະສານເວລາສົ່ງ",
  Timesheet_Congrats:"ຊົມເຊີຍ! ໃບເວລາຂອງເຈົ້າຖືກສົ່ງສຳເລັດແລ້ວ. ຂອບໃຈ!",
  Timesheet_Copy:"ເພື່ອຮັບສຳເນົາເອກະສານຂອງເຈົ້າ ກະລຸນາສະໜອງທີ່ຢູ່ອີເມວ ແລະ/ຫຼື ເບີໂທລະສັບຂອງເຈົ້າ.",
  Submit:"ສົ່ງ",
  Allow_Notifications:"ອະນຸຍາດການແຈ້ງເຕືອນ",
  Untitled_Entry:"ການເຂົ້າໃຫມ່",
  Untitled_Bank:"ບໍ່ມີຊື່ທະນາຄານ",
  Timesheet_Entry:"ການເຂົ້າຕາຕະລາງເວລາ",
  Work_Description:"ລາຍລະອຽດການເຮັດວຽກ",
  Enable_Timesheet:"ເປີດໃຊ້ຕາຕະລາງເວລາ",
  Submit_Timesheet:"ສົ່ງເອກະສານເວລາ",
  Vacation:"ພັກ",
  New_Vacation:"ພັກໃຫມ່",
  Vacation_Name:"ຊື່ພັກ",
  Paid_Vacation:"ການພັກຜ່ອນທີ່ຈ່າຍ",
  Vacation_Pay:"ຄ່າຈ້າງວັນພັກ",
  Enable_Vacation:"ເຮັດໃຫ້ສາມາດພັກ",
  Select_Vacations:"ເລືອກພັກ",
  No_Vacations:"ບໍ່ມີວັນພັກຜ່ອນ",
  No_Vacations_Word:"ສ້າງລາຍການ <a routerLink='/folder/Vacation/New'>ພັກ</a> ທຳອິດ",
  Payroll_Schedule:"ຕາຕະລາງເງິນເດືອນ",
  Next_Payroll:"ເງິນເດືອນຕໍ່ໄປ:",
  Upcoming_Payroll:"ເງິນເດືອນທີ່ຈະມາເຖິງ",
  No_Upcoming_Payroll:"ບໍ່ມີເງິນເດືອນທີ່ຈະມາເຖິງ",
  Address_Book:"ປື້ມ​ທີ່​ຢູ່",
  Archived_Documents:"ເອກະສານທີ່ເກັບໄວ້",
  Dev_Mode:"ຄໍາຮ້ອງສະຫມັກໃນຮູບແບບການພັດທະນາ",
  Administrators:"ຜູ້ບໍລິຫານ",
  Privacy:"ຄວາມເປັນສ່ວນຕົວ",
  None:"ບໍ່ມີ",
  Select_Signature:"ເລືອກລາຍເຊັນ",
  No_Signatures:"ບໍ່ມີລາຍເຊັນ",
  No_Signatures_Word:"ເພີ່ມ <a routerLink='/folder/Signature/New'>ລາຍເຊັນ</a> ອັນທຳອິດ.",
  Repeat_Indefinitely:"ເຮັດຊ້ໍາອີກຄັ້ງ",
  Sign:"ເຊັນ",
  Sign_Here:"ລົງຊື່ທີ່ນີ້",
  Date_Signed:"ວັນທີລົງນາມ",
  Signature_Pad:"ແຜ່ນລາຍເຊັນ",
  Account_Holder:"ເຈົ້າ​ຂອງ​ບັນ​ຊີ",
  Account_Properties:"ຄຸນສົມບັດບັນຊີ",
  Name_On_Cheque:"ຊື່ກ່ຽວກັບກວດສອບ",
  Server_Hostname:"Server Hostname / IP",
  Printers:"ເຄື່ອງພິມ",
  No_Printers:"ບໍ່ມີເຄື່ອງພິມ",
  Printer_Exists:'ເຄື່ອງພິມຊື່ນີ້ມີຢູ່ແລ້ວ.',
  No_Printers_Word:"ເພີ່ມ <a routerLink='/folder/Printer/New'>ເຄື່ອງພິມ</a> ອັນທຳອິດທີ່ສຸດ.",
  No_Printer_Alert:"ບໍ່ມີເຄື່ອງພິມໃດຖືກກຳນົດ. ທ່ານຕ້ອງການຕັ້ງເຄື່ອງພິມບໍ?",
  Add_Printer:"ເພີ່ມເຄື່ອງພິມ",
  New_Printer:"Printer ໃຫມ່",
  Printer_Hostname:"ຊື່ເຈົ້າພາບເຄື່ອງພິມ / IP",
  Printer_Label:"ປ້າຍເຄື່ອງພິມ",
  Printer_Protocol:"ພິທີການເຄື່ອງພິມ",
  Protocol:"ພິທີການ",
  Email_Print:"ອີເມລ໌",
  AirPrint:"ການພິມທາງອາກາດ",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"ເຕົ້າຮັບ",
  Print_Job:"ວຽກພິມ",
  Printed:"ພິມ",
  Not_Printed:"ບໍ່ໄດ້ພິມ",
  Print_Jobs:"ວຽກພິມ",
  Print_Queue:"ຄິວພິມ",
  No_Print_Jobs:"ບໍ່ມີວຽກພິມ",
  No_Prints:"ສ້າງ <a routerLink='/folder/Cheque/New'>ກວດສອບ</a> ໃໝ່ເພື່ອພິມ.",
  Prints:"ພິມ",
  Find_Printer:"ຊອກຫາເຄື່ອງພິມ",
  Find_AirPrint_Devices:"ຊອກຫາອຸປະກອນ AirPrint",
  Select_Printer:"ເລືອກເຄື່ອງພິມ",
  System_UI:"UI ຂອງລະບົບ",
  Printer:"ເຄື່ອງພິມ",
  Status:"ສະຖານະ",
  Preview:"ເບິ່ງຕົວຢ່າງ",
  Enable_Print_Job:"ເປີດໃຊ້ວຽກພິມ",
  Queue_Weight:"ນ້ໍາຫນັກແຖວ",
  Unlimited:"ບໍ່ຈໍາກັດ",
  Show_Advanced_Printer_Options:"ສະແດງຕົວເລືອກເຄື່ອງພິມແບບພິເສດ",
  Advanced:"ຂັ້ນສູງ",
  Location:"ສະຖານທີ່",
  Note:"ຫມາຍ​ເຫດ​",
  Queue_Name:"ຊື່ຄິວ",
  Pages_Printed_Limit:"ຈຳກັດໜ້າທີ່ພິມ",
  MultiPage_Idle_Time:"ເວລາລໍຖ້າຫຼາຍໜ້າ",
  Page_Count_Limit:"ຈຳກັດຈຳນວນໜ້າ",
  Page_Orientation:"ທິດທາງຫນ້າ",
  Portrait:"ຮູບຄົນ",
  Landscape:"ພູມສັນຖານ",
  Duplex:"ຄູ່",
  Double_Sided_Printing:"ສອງເທົ່າ ຂ້າງ",
  Duplex_Mode:"ໂໝດ ສອງຊັ້ນ",
  Duplex_Short:"ສັ້ນ",
  Duplex_Long:"ຍາວ",
  Duplex_None:"ບໍ່ມີ",
  Color_And_Quality:"ສີແລະຄຸນນະພາບ",
  Monochrome:"ໂມໂນໂຄມ",
  Photo_Quality_Prints:"ການພິມຄຸນນະພາບຮູບພາບ",
  Printer_Email:"ອີເມລ໌ເຄື່ອງພິມ",
  Automatically_Downsize:"ຫຼຸດລົງອັດຕະໂນມັດ",
  Paper:"ເຈ້ຍ",
  Paper_Name:"ຊື່ເຈ້ຍ",
  Paper_Width:"ຄວາມກວ້າງຂອງເຈ້ຍ",
  Paper_Height:"ຄວາມສູງຂອງເຈ້ຍ",
  Paper_Autocut_Length:"ຄວາມຍາວຕັດອັດຕະໂນມັດຂອງເຈ້ຍ",
  Margins:"ຂອບ",
  Page_Margins:"ຂອບໜ້າ",
  Page_Margin_Top:"ຂອບໜ້າເທິງ",
  Page_Margin_Right:"ຂອບໜ້າຂວາ",
  Page_Margin_Bottom:"ຂອບໜ້າລຸ່ມ",
  Page_Margin_Left:"ຂອບໜ້າຊ້າຍ",
  Add_Employees:"ເພີ່ມພະນັກງານ",
  Header:"ຫົວ",
  Print_A_Page_Header:"ພິມຫົວຂໍ້ໜ້າ",
  Header_Label:"ປ້າຍກຳກັບຫົວ",
  Header_Page_Index:"ດັດຊະນີໜ້າຫົວ",
  Header_Font_Name:"ຕົວອັກສອນສ່ວນຫົວ",
  Select_Font:"ເລືອກຕົວອັກສອນ",
  Font_Selector:"ຕົວເລືອກຕົວອັກສອນ",
  Header_Font_Size:"ຕົວອັກສອນສ່ວນຫົວ",
  Header_Bold:"ຫົວຕົວໜາ",
  Header_Italic:"ຫົວໂຕອຽງ",
  Header_Alignment:"ການຈັດຮຽງຫົວ",
  Left:"ຊ້າຍ",
  Center:"ສູນ",
  Right:"ສິດ",
  Justified:"ສົມເຫດສົມຜົນ",
  Header_Font_Color:"ສີສ່ວນຫົວ",
  Select_Color:"ເລືອກສີ",
  Footer:"ສ່ວນທ້າຍ",
  Print_A_Page_Footer:"ພິມສ່ວນທ້າຍໜ້າ",
  Footer_Label:"ປ້າຍຊື່ສ່ວນທ້າຍ",
  Footer_Page_Index:"ດັດຊະນີໜ້າສ່ວນທ້າຍ",
  Footer_Font_Name:"ຟອນສ່ວນທ້າຍ",
  Fonts:"ຕົວອັກສອນ",
  Done:"ແລ້ວໆ",
  Select_Fonts:"ເລືອກຕົວອັກສອນ",
  Footer_Font_Size:"ຂະຫນາດສ່ວນທ້າຍ",
  Footer_Bold:"Footer Bold",
  Footer_Italic:"ຕົວອຽງສ່ວນທ້າຍ",
  Footer_Alignment:"ການຈັດຮຽງທ້າຍ",
  Footer_Font_Color:"ສີສ່ວນທ້າຍ",
  Page_Copies:"ສຳເນົາໜ້າ",
  Enable_Printer:"ເປີດໃຊ້ເຄື່ອງພິມ",
  Remote_Logging:"ບັນທຶກໄລຍະໄກ",
  Log_Server:"ເຊີບເວີບັນທຶກ",
  Encryption:"ການເຂົ້າລະຫັດ",
  Random_Key:"ກະແຈແບບສຸ່ມ",
  Encryption_Key:"ລະຫັດການເຂົ້າລະຫັດ",
  Cheques_Webserver:"ຖານຂໍ້ມູນແບບກຳນົດເອງ",
  Learn_How:"ຮຽນຮູ້ວິທີ",
  Signature:"ລາຍເຊັນ",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"ເບິ່ງລາຍເຊັນ",
  Printed_Signature:"ລາຍເຊັນທີ່ພິມອອກ",
  Digitally_Sign:"ເຊັນຊື່ດິຈິຕອນ",
  Digital_Signature:"ລາຍເຊັນດິຈິຕອນ",
  Add_Signature:"ຕື່ມລາຍເຊັນ",
  Add_Your_Signature:"ຕື່ມລາຍເຊັນຂອງເຈົ້າ",
  Enable_Signature:"ເປີດໃຊ້ລາຍເຊັນ",
  Digitally_Signed:"ເຊັນຊື່ດິຈິຕອນ",
  Insert_Error:"ບໍ່ສາມາດບັນທຶກການກວດສອບໄດ້ເນື່ອງຈາກບັນຫາຖານຂໍ້ມູນ.",
  Delete_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບຂໍ້ມູນນີ້ອອກ?",
  Discard_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການຍົກເລີກຂໍ້ມູນນີ້?",
  Discard_Bank_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການຍົກເລີກບັນຊີນີ້?",
  Discard_Printer_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການຍົກເລີກເຄື່ອງພິມນີ້?",
  Delete_Bonus_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບໂບນັດນີ້?",
  Delete_Invoice_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບໃບແຈ້ງໜີ້ນີ້ອອກ?",
  Generated_Cheque:"ການກວດສອບທີ່ສ້າງຂຶ້ນ",
  Generated_Invoice:"ໃບເກັບເງິນທີ່ສ້າງຂຶ້ນ",
  Schedule_Start_Time:"ຕາຕະລາງເລີ່ມຕົ້ນ",
  Schedule_End_Time:"ຕາຕະລາງສິ້ນສຸດ",
  New_Call:"ໂທໃໝ່",
  No_Contacts:"ບໍ່ມີການຕິດຕໍ່",
  No_Contacts_Word:"ຜູ້ບໍລິຫານ, ຜູ້ຄວບຄຸມ, ພະນັກງານ, ແລະຜູ້ຮັບເງິນປະກົດວ່າເປັນຜູ້ຕິດຕໍ່.",
  PDF_Subject:"ການເງິນ",
  PDF_Keywords:"ບັນຊີຈ່າຍເງິນເດືອນ ເຊັກເຊັກ PDF",
  Printer_Setup:"ຕັ້ງຄ່າເຄື່ອງພິມ",
  Printer_Selection:"ການເລືອກເຄື່ອງພິມ",
  New_Fax:"ແຟັກໃໝ່",
  New_Fax_Message:"ຂໍ້ຄວາມແຟັກໃໝ່",
  Fax_Machine:"ເຄື່ອງແຟັກ",
  Fax_Name:"ຊື່ແຟັກ",
  Fax_Email:"ແຟັກອີເມລ໌",
  Fax_Number:"ໝາຍເລກແຟັກ",
  Contents:"ເນື້ອໃນ",
  Fax_Body:"ເນື້ອໃນຂອງຫນ້າ",
  Header_Word:"ຫົວ:",
  Header_Text:"ຂໍ້ຄວາມສ່ວນຫົວ",
  Include_Header:"ລວມເອົາສ່ວນຫົວ",
  Include_Footer:"ລວມເອົາສ່ວນທ້າຍ",
  Footer_Word:"ສ່ວນທ້າຍ:",
  Footer_Text:"ຂໍ້ຄວາມສ່ວນທ້າຍ",
  Attach_a_Cheque:"ຄັດຕິດເຊັກ",
  Add_Deduction:"ຕື່ມການຫັກອອກ",
  Enable_Fax:"ສົ່ງແຟັກ",
  Select_Fax:"ເລືອກແຟັກ",
  No_Faxes:"ບໍ່ມີແຟັກ",
  Faxes:"ແຟັກ",
  Save_and_Send:"ສົ່ງແຟັກ",
  Save_and_Pay:"ປະຫຍັດແລະຈ່າຍ",
  WARNING:"ຄໍາເຕືອນ:",
  Remember:"ຈື່",
  Printing:"ການພິມ",
  Printing_Complete:"ພິມສຳເລັດແລ້ວ!\n\n",
  of:"ຂອງ",
  There_Were:"ໄດ້​ມີ ",
  Successful_Prints:"ການພິມສໍາເລັດຮູບແລະ",
  Unsuccessful_Prints:"ການພິມບໍ່ສຳເລັດ.",
  PrinterError:"ຂໍອະໄພ! ມີຂໍ້ຜິດພາດເກີດຂຶ້ນ.",
  Printing_:"ການພິມ...",
  PrinterSuccess:"ພິມເອກະສານສຳເລັດແລ້ວ.",
  PrintersSuccess:"ພິມເອກະສານສຳເລັດແລ້ວ.",
  Print_Cheques:"ພິມກວດສອບ",
  New_Message:"ຂໍ້ຄວາມໃຫມ່",
  New_Messages:"ຂໍ້ຄວາມໃຫມ່",
  Read_Message:"ອ່ານຂໍ້ຄວາມ",
  Write_Message:"ຂຽນຂໍ້ຄວາມ",
  Send_Message:"ສົ່ງ​ຂໍ້​ຄວາມ",
  Subject:"ວິຊາ",
  Message:"ຂໍ້ຄວາມ",
  Writing:"ການ​ຂຽນ...",
  Send:"ສົ່ງ",
  Set_Date:"ກໍານົດວັນທີ",
  Set_Time:"ກໍານົດເວລາ",
  Recieve:"ຮັບ",
  Set_as_Default:"ກໍາ​ນົດ​ເປັນ​ຄ່າ​ເລີ່ມ​ຕົ້ນ",
  Default_Account:"ບັນຊີເລີ່ມຕົ້ນ",
  Default_Design:"ການອອກແບບເລີ່ມຕົ້ນ",
  Multiple_Cheques:"ເຊັກ (ສາມ)",
  Account_Mode:"ຮູບແບບບັນຊີ",
  Multiple_Cheques_Description:"ສາມເຊັກຕໍ່ຫນ້າ.",
  Check_and_Table:"ກວດສອບແລະຕາຕະລາງ",
  Check_including_Table:"ຕາຕະລາງການກວດສອບແລະບັນຊີ.",
  Check_Mailer:"ກວດຈົດໝາຍ",
  Check_Mailer_Window:"ກວດເບິ່ງດ້ວຍປ່ອງຢ້ຽມທີ່ຢູ່.",
  DocuGard_Table_Top:"DocuGard ກວດ​ສອບ​ແລະ​ບັນ​ຊີ​ (ທາງເທິງ)",
  DocuGard_Table_Middle:"DocuGard ກວດ​ສອບ​ແລະ​ບັນ​ຊີ​(ກາງ)",
  DocuGard_Table_Bottom:"DocuGard ກວດ​ສອບ​ແລະ​ບັນ​ຊີ​ (ລຸ່ມ)",
  DocuGard_Mailer_Top:"DocuGard ເຊັກທາງໄປສະນີ (ເທິງ)",
  DocuGard_Mailer_Middle:"DocuGard ເຊັກທາງໄປສະນີ (ກາງ)",
  DocuGard_Mailer_Bottom:"DocuGard ເຊັກທາງໄປສະນີ (ລຸ່ມ)",
  DocuGard_Three_Cheques:"DocuGard ກວດສອບ (ສາມເທື່ອ)",
  DocuGard_Cheque_Top:"DocuGard ກວດສອບ (ເທິງ)",
  DocuGard_Cheque_Middle:"DocuGard ກວດສອບ (ກາງ)",
  DocuGard_Cheque_Bottom:"DocuGard ກວດສອບ (ລຸ່ມ)",
  DocuGard_Three_Cheques_Window:"ສາມເຊັກໃນຫນຶ່ງຫນ້າ.",
  DocuGard_Table_Top_Window:"ຕາຕະລາງການກວດສອບແລະລາຍຮັບ.",
  DocuGard_Table_Middle_Window:"ຕາຕະລາງການກວດສອບແລະລາຍຮັບ.",
  DocuGard_Table_Bottom_Window:"ຕາຕະລາງການກວດສອບແລະລາຍຮັບ.",
  DocuGard_Mailer_Top_Window:"ກວດເບິ່ງ, ຕາຕະລາງ, ແລະທີ່ຢູ່.",
  DocuGard_Mailer_Middle_Window:"ກວດເບິ່ງ, ຕາຕະລາງ, ແລະທີ່ຢູ່.",
  DocuGard_Mailer_Bottom_Window:"ກວດເບິ່ງ, ຕາຕະລາງ, ແລະທີ່ຢູ່.",
  DocuGard_Cheque_Top_Window:"ກວດເບິ່ງເຈ້ຍທີ່ປອດໄພ.",
  DocuGard_Cheque_Middle_Window:"ກວດເບິ່ງເຈ້ຍທີ່ປອດໄພ.",
  DocuGard_Cheque_Bottom_Window:"ກວດເບິ່ງເຈ້ຍທີ່ປອດໄພ.",
  Basic_Cheque:"ກວດເບິ່ງ (ເທິງ)",
  Basic_Cheque_Print:"ພິມເຊັກຄັ້ງດຽວ.",
  Error_Setting_As_Paid:"ການຕັ້ງຄວາມຜິດພາດທີ່ຈ່າຍເປັນ",
  Add_Attachment:"ຕື່ມໄຟລ໌ແນບ",
  PrinterUnavailable:"ເຄື່ອງພິມບໍ່ສາມາດໃຊ້ໄດ້",
  CreditCardComponent:"ບັດ",
  PaypalComponent:"PayPal",
  InteracComponent:"ໂຕ້ຕອບ",
  BitcoinComponent:"BitCoin",
  New_Deposit:"ເງິນຝາກໃຫມ່",
  Deposits:"ເງິນຝາກ",
  No_Deposits:"ບໍ່ມີເງິນຝາກ",
  Credit_Card_Deposit:"ບັດ​ເຄຣ​ດິດ",
  New_Credit_Card_Deposit_Message:"ເງິນຝາກບັດເຄຣດິດ",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"ເງິນຝາກ Bitcoin",
  New_Interac_Deposit:"ໂຕ້ຕອບ",
  New_Interac_Deposit_Message:"ການໂອນລະຫວ່າງປະເທດ",
  Payments_Limit:"ຂອບເຂດຈໍາກັດການຈ່າຍເງິນ",
  No_Payment_Limit:"ບໍ່ມີຂອບເຂດຈໍາກັດການຈ່າຍເງິນ",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"ເງິນຝາກ PayPal",
  No_Deposits_Word:"ເພີ່ມບໍລິສັດທໍາອິດ <a routerLink='/folder/Deposit/New'>ຝາກເງິນ</a>.",
  No_Documents_Specified:"ບໍ່ໄດ້ລະບຸເອກະສານສໍາລັບການພິມ",
  No_Printers_Added:"ບໍ່ພົບເຄື່ອງພິມໃດໆ. ໄປທີ່ການຕັ້ງຄ່າ > ເຄື່ອງພິມເພື່ອເພີ່ມເຄື່ອງພິມ.",
  DB_Erase_Prompt:"ລຶບຖານຂໍ້ມູນທັງໝົດອອກໝົດບໍ? ຄຳເຕືອນ: ທ່ານຈະສູນເສຍຂໍ້ມູນທີ່ເກັບໄວ້ທັງໝົດ!",
  Console_Warning:"ຢ່າວາງຂໍ້ຄວາມໃດໆເຂົ້າໄປໃນ console ນີ້. ທ່ານອາດຈະເຮັດໃຫ້ຕົວທ່ານເອງແລະ / ຫຼືບໍລິສັດຂອງທ່ານມີຄວາມສ່ຽງທີ່ຮ້າຍແຮງ.",
  No_Faxes_Word:"ສ້າງ <a routerLink='/folder/Fax/New'>ແຟັກ</a> ອັນທຳອິດ.",
  Cheque_Delete_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບການກວດສອບນີ້?",
  Design_Delete_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບການອອກແບບນີ້?",
  Cheque_Pay_Confirmation:"ໝາຍເຊັກນີ້ເປັນເງິນບໍ? ມັນຈະບໍ່ປາກົດຢູ່ໃນຄິວພິມ.",
  Payment_Pay_Confirmation:"ໝາຍການຈ່າຍເງິນນີ້ເປັນການຈ່າຍເງິນບໍ? ມັນຈະບໍ່ປາກົດຢູ່ໃນຄິວເຊັກ.",
  Delete_Deduction_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບການຫັກອອກນີ້?",
  Delete_Job_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບວຽກນີ້?",
  Delete_Timesheet_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບຕາຕະລາງເວລານີ້?",
  Delete_Schedule_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບຕາຕະລາງນີ້?",
  Delete_Setting_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການຣີເຊັດການຕັ້ງຄ່ານີ້?",
  Delete_Fax_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບແຟັກນີ້?",
  Delete_File_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບໄຟລ໌ນີ້?",
  Delete_Vacation_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບວັນພັກຜ່ອນນີ້ອອກ?",
  Delete_Printer_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບເຄື່ອງພິມນີ້ອອກ?",
  Remove_Design_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບການອອກແບບນີ້?",
  Delete_Payroll_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບບັນຊີເງິນເດືອນນີ້?",
  Send_Fax_Email_Confirmation:"ທ່ານຕ້ອງການແຟັກແລະອີເມລ໌ເອກະສານເຫຼົ່ານີ້ບໍ?",
  Send_Email_Confirmation:"ທ່ານຕ້ອງການສົ່ງອີເມວເອກະສານນີ້ບໍ?",
  Send_Fax_Confirmation:"ທ່ານຕ້ອງການແຟັກເອກະສານນີ້ບໍ?",
  Error_Generating_PDF:"ຂໍອະໄພ. ມີຄວາມຜິດພາດໃນການສ້າງເອກະສານນີ້.",
  PDF_Error_Saving_Image:"ຂໍອະໄພ. ເກີດຄວາມຜິດພາດໃນການບັນທຶກຮູບ PDF ຂອງເອກະສານນີ້.",
  Test_Printer_Confirmation:"ທ່ານຕ້ອງການພິມຫນ້າທົດສອບຢູ່ໃນເຄື່ອງພິມນີ້ບໍ?",
  Save_Timesheet_Prompt:"ກະລຸນາເພີ່ມ ຫົວຂໍ້ ຫຼືກົດ ເລີ່ມໂມງຈັບເວລາ ເພື່ອບັນທຶກ.",
  Remove_Geofence_Prompt:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການເອົາທີ່ຕັ້ງຂອງຮົ້ວພູມສາດນີ້ອອກ?",
  Delete_Employee_Confirmation:"ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບ",
  Fire_Employee_Confirmation:"ເຈົ້າແນ່ໃຈບໍວ່າເຈົ້າຕ້ອງການໄຟ"
}