export const Ta = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  Copyright:"பதிப்புரிமை &நகல்; 2023",
  black:"கருப்பு",
  green:"பச்சை",
  gold:"தங்கம்",
  blue:"நீலம்",
  brown:"பழுப்பு",
  purple:"ஊதா",
  pink:"இளஞ்சிவப்பு",
  red:"சிவப்பு",
  Swatches:"ஸ்வாட்சுகள்",
  HSL:"எச்.எஸ்.எல்",
  RGB:"RGB",
  Hue:"சாயல்",
  Saturation:"செறிவூட்டல்",
  Lightness:"லேசான தன்மை",
  Upgrade_To_Pro:"Pro ஆக மேம்படுத்தவும்",
  AllFeaturesInclude:"அனைத்து அம்சங்களும் அடங்கும்:",
  PrintUnlimitedCheques:"வரம்பற்ற காசோலைகளை அச்சிடுங்கள்",
  PremiumChequeDesigns:"பிரீமியம் சோதனை வடிவமைப்புகள்",
  ManageUnlimitedEmployees:"வரம்பற்ற பணியாளர்களை நிர்வகிக்கவும்",
  AddUnlimitedPayees:"வரம்பற்ற பணம் பெறுபவர்களைச் சேர்க்கவும்",
  CreateUnlimitedPayrolls:"வரம்பற்ற ஊதியங்களை உருவாக்கவும்",
  UnlimitedSchedulesandTimeSheets:"வரம்பற்ற அட்டவணைகள் மற்றும் நேரத் தாள்கள்",
  BulkPayPalPayouts:"மொத்த பேபால் பேஅவுட்கள்",
  UnlimitedBankAccounts:"வரம்பற்ற வங்கி கணக்குகள்",
  ManageMultipleCompanies:"பல நிறுவனங்களை நிர்வகிக்கவும்",
  TrackInsurancePolicies:"காப்பீட்டுக் கொள்கைகளைக் கண்காணிக்கவும்",
  Bio_MetricProtection:"பயோ-மெட்ரிக் பாதுகாப்பு",
  Geo_FenceLock_OutProtection:"புவி வேலி லாக்-அவுட் பாதுகாப்பு",
  Multiple_Companies_Word:"காசோலைகள் பிரீமியம் இல்லாமல் பல நிறுவனங்களை நிர்வகிப்பது கிடைக்காது.",
  PayPal_Payouts_Word:"காசோலைகள் பிரீமியம் இல்லாமல் பேபால் கொடுப்பனவுகள் முடக்கப்பட்டுள்ளன.",
  PINProtection:"பின் பாதுகாப்பு",
  PasswordProtection:"கடவுச்சொல் பாதுகாப்பு",
  May_Require_Approval:"ஒப்புதல் தேவைப்படலாம்.",
  Subscribe:"பதிவு",
  Billed:"கட்டணம் வசூலிக்கப்பட்டது",
  Up:"மேலே",
  Down:"கீழ்",
  Positioning:"நிலைப்படுத்துதல்",
  Marker:"குறிப்பான்",
  Drag_Marker:"குறிப்பானை இழுக்கவும்",
  Tagline:"காசோலைகளை அச்சிடவும் மற்றும் ஊதிய அட்டவணையை உருவாக்கவும்",
  Marker_Word:"உறுப்பை அளவிட குறிப்பான்களைப் பயன்படுத்தவும்.",
  Pinch_Zoom:"பிஞ்ச் ஜூம்",
  Pinch_Word:"உறுப்பை பெரிதாக்க பிஞ்ச் செய்யவும்.",
  Drag:"இழுக்கவும்",
  Drag_Word:"உறுப்புகளை இழுக்க உங்கள் விரலைப் பயன்படுத்தவும்.",
  subscription_alias_word:"தானாக புதுப்பித்தல் சந்தா",
  premium_alias_word:"ஒரு முறை மேம்படுத்தல் தொகுப்பு",
  print_alias_word:"தனிப்பட்ட காசோலைகளை அச்சிடுதல்",
  mode_alias_word:"பயன்முறை",
  Pro:"ப்ரோ",
  Pro_word:"ப்ரோ பதிப்பு தேவை.",
  Cant_Delete_Default_Company_Word:"மன்னிக்கவும், உங்கள் இயல்புநிலை நிறுவனத்தை உங்களால் நீக்க முடியாது.",
  Reinsert_Default_Designs:"இயல்புநிலை வடிவமைப்புகளை மீண்டும் செருகவும்",
  Reinsert_Default_Designs_word:"இயல்புநிலை வடிவமைப்புகளை மீண்டும் செருக விரும்புகிறீர்களா?",
  Subscription_Active_Disable_Word:"இந்த சந்தா செயலில் உள்ளது. காசோலைகளுக்கான இந்த சந்தாவை ரத்து செய்ய விரும்புகிறீர்களா?",
  Company_Logo:"நிறுவனத்தின் லோகோ",
  ZERO_:"பூஜ்யம்",
  Disclaimer:"மறுப்பு",
  Privacy_Policy:"தனியுரிமைக் கொள்கை",
  EULA:"EULA ஐ சரிபார்க்கிறது",
  Terms_Of_Service:"சேவை விதிமுறைகள்",
  Terms_Of_Use:"பயன்பாட்டு விதிமுறைகளை",
  Refunds:"பணத்தைத் திரும்பப்பெறுதல் கொள்கை",
  Single_Print:"1 சரிபார்க்கவும்",
  Two_Prints:"2 காசோலைகள்",
  Five_Prints:"5 காசோலைகள்",
  Ten_Prints:"10 காசோலைகள்",
  Fifteen_Prints:"15 காசோலைகள்",
  Twenty_Prints:"20 காசோலைகள்",
  Thirty_Prints:"30 காசோலைகள்",
  Image_Added:"படம் சேர்க்கப்பட்டது",
  Image_Preview:"பட முன்னோட்டம்",
  No_Image_Was_Selected:"படம் எதுவும் தேர்ந்தெடுக்கப்படவில்லை.",
  Cheques_Unlimited:"வரம்பற்ற காசோலைகள்",
  _Subscription:"சந்தா",
  Subscription:"காசோலைகள் - 1 மாதம்",
  Two_Month_Subscription:"காசோலைகள் - 2 மாதங்கள்",
  Three_Month_Subscription:"காசோலைகள் - 3 மாதங்கள்",
  Six_Month_Subscription:"காசோலைகள் - 6 மாதங்கள்",
  Twelve_Month_Subscription:"காசோலைகள் - 12 மாதங்கள்",
  Cheques_Are_Available:"காசோலைகள் அச்சிட கிடைக்கின்றன.",
  Upgrade_Required_Two:"ஒரு பேக்கேஜைத் தேர்ந்தெடுத்து, உங்கள் வாங்குதலைத் தொடங்க விலை பட்டனை இருமுறை தட்டவும். தொழில்முறை தோற்றம் கொண்ட முழு வண்ண காசோலைகளை நொடிகளில் அச்சிடுங்கள்.",
  Month:"மாதம்",
  Due:"செலுத்த வேண்டியவை:",
  Expires:"காலாவதியாகும்:",
  Subscription_Active:"சந்தா செயலில் உள்ளது",
  Subscription_Inactive:"சந்தா செயலற்றது",
  Purchase_Additional_Cheques:"கூடுதல் காசோலைகளை வாங்கவா?",
  Printable_Cheque:"அச்சிடக்கூடிய காசோலை",
  Printable_Cheques:"அச்சிடக்கூடிய காசோலைகள்",
  Printable_Cheque_Word:"உங்கள் கணக்கில் காசோலை உள்ளது.",
  Printable_Cheques_Word:"உங்கள் கணக்கில் காசோலைகள் உள்ளன.",
  Max_Amount_Message:"நீங்கள் குறிப்பிட்டுள்ள தொகை, இந்த அமைப்பிற்கு அமைக்கப்பட்டுள்ள அதிகபட்சத் தொகையை எட்டியுள்ளது:",
  Terms_Required_Word:"காசோலைகளைத் தொடர்ந்து பயன்படுத்துவதற்கு முன் இந்த ஒப்பந்தத்தை நீங்கள் ஒப்புக் கொள்ள வேண்டும்.",
  Subscriptions:"சந்தாக்கள்",
  Product_Upgrades:"மேம்படுத்துகிறது",
  Mailed_Out_Cheques:"அஞ்சல் மூலம் அனுப்பப்பட்ட காசோலைகள்",
  Enter_A_Company_Name:"நிறுவனத்தின் பெயரை உள்ளிடவும்.",
  Single_Cheques:"ஒற்றை காசோலைகள்",
  Cheque_Golden:"கோல்டன் காசோலை",
  Cheque_Golden_Window:"கோல்டன் காசோலை வடிவமைப்பு.",
  Cheque_Green:"பச்சை காசோலை",
  Cheque_Green_Window:"பச்சை காசோலை வடிவமைப்பு.",
  Cheque_Red:"சிவப்பு சோதனை",
  Cheque_Red_Window:"சிவப்பு காசோலை வடிவமைப்பு.",
  Cheque_Yellow:"மஞ்சள் காசோலை",
  Cheque_Yellow_Window:"மஞ்சள் காசோலை வடிவமைப்பு.",
  Cheque_Statue_of_Liberty:"சுதந்திர தேவி சிலை",
  Cheque_Statue_of_Liberty_Window:"லிபர்ட்டி சிலை காசோலை வடிவமைப்பு.",
  Cheque_Green_Wave:"பச்சை அலை",
  Cheque_Green_Wave_Window:"பச்சை காசோலை வடிவமைப்பு.",
  Cheque_Golden_Weave:"தங்க நெசவு",
  Cheque_Golden_Weave_Window:"நேர்த்தியான தங்க காசோலை வடிவமைப்பு.",
  Cheque_Green_Sun:"பச்சை சூரியன்",
  Cheque_Green_Sun_Window:"ஆழமான மற்றும் அமைதியான காசோலை வடிவமைப்பு.",
  Cheque_Blue_Checkers:"நீல செக்கர்ஸ்",
  Cheque_Blue_Checkers_Window:"நீல காசோலை வடிவமைப்பு.",
  Cashiers_Check:"காசாளர் காசோலை",
  Cashiers_Check_Window:"காசாளர் காசோலை பாணி டெம்ப்ளேட்.",
  Cheque_Aqua_Checkers:"அக்வா செக்கர்ஸ்",
  Cheque_Aqua_Checkers_Window:"அக்வா காசோலை வடிவமைப்பு.",
  Cheque_Golden_Checkers:"கோல்டன் செக்கர்ஸ்",
  Cheque_Golden_Checkers_Window:"கோல்டன் காசோலை வடிவமைப்பு.",
  Upgrade_Unavailable:"மேம்படுத்தல்கள் கிடைக்கவில்லை",
  Bank_Code_Protection:"வங்கி எண் பாதுகாப்பு",
  Bank_Code_Protection_Word:"வேறொரு சாதனத்தில் அல்லது மற்றொரு பயனருக்கு இயங்கும் இந்தப் பயன்பாட்டில் உங்கள் வங்கி எண்களைப் பயன்படுத்தாமல் பாதுகாக்கவும். இந்தச் செயல் மீள முடியாதது. தொடரவா?",
  Bank_Code_Protection_Blocked_Word:"நீங்கள் பயன்படுத்த முயற்சிக்கும் வங்கி எண்கள் மற்றொரு பயனர் அல்லது சாதனத்திற்காக ஒதுக்கப்பட்டுள்ளன.",
  Bank_Code_Protection_Error_Word:"எண் சரிபார்ப்பு தோல்வியடைந்தது. இணையத்துடன் இணைத்து, இந்த வங்கிக் கணக்கைச் சேர்க்க மீண்டும் முயற்சிக்கவும்.",
  Bank_Code_Protection_Set_Error_Word:"வங்கி எண் பாதுகாப்பிற்கு நீங்கள் இணையத்துடன் இணைக்கப்பட்டிருக்க வேண்டும். இணைத்து மீண்டும் முயற்சிக்கவும்.",
  Upgrade_Unavailable_Word:"மன்னிக்கவும், உங்களைச் சரிபார்ப்பதில் சிக்கலை எதிர்கொள்கிறோம். காசோலைகளுக்கான சந்தாக்கள் மற்றும் மேம்படுத்தல்கள் உங்கள் பகுதியில் வாங்குவதற்கு தற்போது கிடைக்கவில்லை.",
  PayPal_Payment_Preview:"பேபால் கட்டண முன்னோட்டம்",
  Apple_Pay:"ஆப்பிள் பே",
  Select_PayPal:"பேபால் தேர்ந்தெடுக்கவும்",
  PayPal_Applications:"பேபால் பயன்பாடுகள்",
  Purchase_With_Apple_Pay:"ஆப்பிள் பே மூலம் வாங்கவும்",
  Google_Pay:"கூகிள் பே",
  Companies:"நிறுவனங்கள்",
  Insurance:"காப்பீடு",
  New_PayPal:"புதிய பேபால்",
  Pay_By:"மூலம் செலுத்தவும்",
  Insure:"காப்பீடு செய்யுங்கள்",
  Miles:"மைல்கள்",
  Payment_Method:"பணம் செலுத்தும் முறை",
  Select_Policies:"கொள்கைகளைத் தேர்ந்தெடுக்கவும்",
  Policies:"கொள்கைகள்",
  Policy:"கொள்கை",
  No_PayPal_Account:"பேபால் கணக்கு இல்லை",
  No_Policies:"காப்பீட்டுக் கொள்கைகள் இல்லை",
  New_Policy:"புதிய கொள்கை",
  PayPal_Payment:"பேபால் கட்டணம்",
  PayPal_Payments:"பேபால் கொடுப்பனவுகள்",
  No_Payment_Selected:"கட்டணம் எதுவும் தேர்ந்தெடுக்கப்படவில்லை",
  Sending_Payment_Word:"தயவுசெய்து காத்திருக்கவும்... இந்த கட்டணம் அனுப்பப்படுகிறது.",
  Sending_Payments_Word:"காத்திருக்கவும்... பணம் அனுப்பப்படுகிறது.",
  No_Payment_Selected_PayPal:"அனுப்புவதற்கு <a routerLink='/folder/Payments'>பேபால் கட்டணம்</a> தேர்ந்தெடுக்கப்படவில்லை.",
  Payment_Sent:"பணம் அனுப்பப்பட்டது",
  PayPal_Payment_Sent:"இந்த கட்டணம் பேபால் மூலம் அனுப்பப்பட்டது.",
  Copay:"நகலெடுக்கவும்",
  Dead:"இறந்து போனது",
  Alive:"உயிருடன்",
  Not_Dead:"இறக்கவில்லை",
  Unclaimed:"உரிமை கோரப்படாதது",
  Attempted:"முயற்சித்தது",
  Deceased:"இறந்தார்",
  Claimed:"உரிமை கோரப்பட்டது",
  Unpaid:"செலுத்தப்படாதது",
  Sending_Payment:"பணம் அனுப்புகிறது",
  Sending_Payments:"பணம் அனுப்புகிறது",
  Send_PayPal_Confirmation:"இந்த பரிவர்த்தனையை பேபால் மூலம் அனுப்ப விரும்புகிறீர்களா?",
  Send_PayPal_Confirmation_Word:"இந்த பரிவர்த்தனையை அனுப்ப பச்சை பட்டனை அழுத்தவும்.",
  Save_Payment_As_Unpaid:"இந்தக் கட்டணத்தைச் செலுத்தாததாகச் சேமிக்கவா?",
  Payment_Pay_Confirmation_PayPal:"இந்தக் கட்டணத்தைச் செலுத்தியதாகச் சேமிக்கவா?",
  No_Policies_Word:"இப்போது முதல் <a routerLink='/folder/Postage/New'>காப்பீட்டுக் கொள்கையைச்</a> சேர்க்கவும்.",
  Timesheet_Multiple_Delete_Confirmation:"பல நேரத்தாள்களை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  Select_Multiple_Timesheets_Prompt:"நேரத்தாள்கள் எதுவும் தேர்ந்தெடுக்கப்படவில்லை. குறைந்தபட்சம் ஒரு நேரத்தாளைத் தேர்ந்தெடுக்கவும்.",
  Select_Multiple_Policies_Prompt:"கொள்கைகள் எதுவும் தேர்ந்தெடுக்கப்படவில்லை. குறைந்தபட்சம் ஒரு காப்பீட்டுக் கொள்கையைத் தேர்ந்தெடுக்கவும்.",
  Policies_Multiple_Delete_Confirmation:"பல கொள்கைகளை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  Company:"நிறுவனம்",
  Add_Company:"நிறுவனத்தைச் சேர்க்கவும்",
  New_Company:"நிறுவனத்தைச் சேர்க்கவும்",
  No_Companies:"நிறுவனங்கள் இல்லை",
  Enable_Company:"நிறுவனத்தை இயக்கு",
  Select_Company:"நிறுவனத்தைத் தேர்ந்தெடுக்கவும்",
  The_Default_Company:"இயல்புநிலை நிறுவன லேபிள்.",
  Manage_Companies:"நிறுவனங்களை நிர்வகிக்கவும்",
  No_Companies_Word:"சரிபார்ப்புகள் இயல்புநிலை நிறுவனத்தைப் பயன்படுத்தும்.<br /><a routerLink='/folder/Company/New'>முதல் நிறுவனத்தைச்</a> சேர்க்கவும்.",
  Default_Company:"இயல்புநிலை நிறுவனம்",
  Cheques_Unlimited_Word:"காசோலைகள் அன்லிமிடெட் நீங்கள் விரும்பும் பல காசோலைகளை அச்சிட அனுமதிக்கிறது.",
  Cheques_Subscription_Word:"ஒரு காசோலை சந்தா நீங்கள் விரும்பும் பல காசோலைகளை அச்சிட அனுமதிக்கிறது.",
  You_Own_This_Product:"இந்த தயாரிப்பு உங்களுக்கு சொந்தமானது.",
  Your_Subscription_is_Active:"உங்கள் சந்தா செயலில் உள்ளது.",
  Active_Products:"செயல்படுத்தப்பட்ட தயாரிப்புகள்",
  Purchase_Confirmation:"கொள்முதல்",
  Purchase_Cheques:"கொள்முதல் காசோலைகள்",
  Restore_Purchase:"கொள்முதலை திரும்பப்பெறு",
  Erase_Purchase:"வாங்குதல்களை அழிக்கவும்",
  Successfully_Purchased:"வெற்றிகரமாக வாங்கப்பட்டது",
  Enter_Your_Licence_Key:"இந்த தயாரிப்பைச் செயல்படுத்த, இந்தப் பக்கத்தில் உங்கள் உரிம விசையை உள்ளிடவும்.",
  Licence_Key:"உரிம விசை",
  Enter_Licence_Key:"உரிம விசையை உள்ளிடவும்",
  Purchased:"வாங்கப்பட்டது",
  Enable_Feature:"அம்சத்தை இயக்கு",
  Cancel_Subscription:"சந்தாவை ரத்துசெய்",
  Subscription_Removed:"சந்தா அகற்றப்பட்டது",
  Select_Active_Subscription:"செயலில் உள்ள சந்தாவை மாற்ற, அதைத் தேர்ந்தெடுக்கவும்.",
  Remove_Subscription_Word:"இந்தச் சந்தாவை நிச்சயமாக ரத்துசெய்ய விரும்புகிறீர்களா?",
  Delete_Company_Confirmation:"இந்த முழு நிறுவனத்தையும் நிச்சயமாக நீக்க விரும்புகிறீர்களா? எச்சரிக்கை: சேமிக்கப்பட்ட அனைத்து தகவல்களையும் இழப்பீர்கள்!",
  Delete_Default_Company_Word:"நீங்கள் இயல்புநிலை நிறுவனத்தை நீக்க முடியாது. பயன்பாட்டை மீட்டமைத்து இயல்பு நிலைக்கு மீட்டமைக்க விரும்புகிறீர்களா? எச்சரிக்கை: சேமிக்கப்பட்ட அனைத்து தகவல்களையும் இழப்பீர்கள்!",
  Console_Warning_2:"தற்போது உங்களிடம் இல்லாத இந்தப் பயன்பாட்டைப் பயன்படுத்தி எந்த நாணயத்தையும் கையாள வேண்டாம்.",
  Terms_and_Conditions:"விதிமுறைகள் மற்றும் நிபந்தனைகள்",
  and_the:"மற்றும் இந்த",
  for:"க்கான",
  Please_Read_Word:"தயவு செய்து படித்து ஒத்துக்கொள்ளுங்கள்",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"சில நாணய மாற்று விகிதங்களைக் கண்டறிய முடியவில்லை. இணையத்துடன் இணைக்கவும்.",
  Free:"இலவசம்",
  DB_Erase_Prompt_2:"முழு டெவலப்பர் தரவுத்தளத்தையும் முழுமையாக அழிக்கவா? எச்சரிக்கை: அனைத்து கொள்முதல் மற்றும் சந்தா தகவல்களையும் இழப்பீர்கள்!",
  Successfully_Imported:"வெற்றிகரமாக இறக்குமதி செய்யப்பட்டது",
  Select_Postage:"அஞ்சல் கட்டணத்தைத் தேர்ந்தெடுக்கவும்",
  No_Postage:"தபால் தலைகள் இல்லை",
  No_Paid_Postage_Word:"முதல் <a routerLink='/folder/Postage/New'>கட்டண அஞ்சல்</a> முத்திரையைச் சேர்க்கவும்.",
  Trial_Complete:"சோதனை முடிந்தது",
  Please_Upgrade:"அச்சிடுவதைத் தொடர, காசோலைகளை மேம்படுத்தவும்.",
  Aa:"ஆ",
  Color:"நிறம்",
  Font:"எழுத்துரு",
  Guide:"வழிகாட்டி",
  Guides:"வழிகாட்டிகள்",
  Image:"படம்",
  Zoom:"பெரிதாக்கு",
  Logo:"சின்னம்",
  Bank:"வங்கி",
  MICR:"MICR",
  Total:"மொத்தம்",
  Cancel:"ரத்து செய்",
  Confirm:"உறுதிப்படுத்தவும்",
  Method:"முறை",
  Biometric_Security:"பயோமெட்ரிக் பாதுகாப்பு",
  Please_Login_To_Continue:"தொடர உள்நுழையவும்.",
  Complete:"முழுமை",
  Sign_Up:"பதிவு செய்யவும்",
  Error:"பிழை",
  Biometrics:"பயோ மெட்ரிக்ஸ்",
  Percent:"சதவீதம்",
  Zero_Percent:"0%",
  Top_Margin:"மேல் விளிம்பு",
  Bottom_Margin:"கீழ் விளிம்பு",
  Left_Margin:"இடது ஓரம்",
  Right_Margin:"வலது விளிம்பு",
  MICR_Margin:"MICR விளிம்பு",
  Table_Margin:"அட்டவணை விளிம்பு",
  Address_Margin:"முகவரி விளிம்பு",
  Percentage_:"சதவிதம்",
  Vacation_Title:"விடுமுறை தலைப்பு",
  Use_PIN:"பின்னைப் பயன்படுத்தவும்",
  Loading__:"ஏற்றுகிறது...",
  Design_Title:"வடிவமைப்பு தலைப்பு",
  Authorize:"அங்கீகரிக்கவும்",
  Key:"முக்கிய",
  Public_Key:"பொது விசை",
  Private_Key:"தனிப்பட்ட விசை",
  Authenticate:"அங்கீகரிக்கவும்",
  Last_Payroll:"கடைசி ஊதியம்",
  Last_Calculation:"கடைசி கணக்கீடு",
  Authorized:"அங்கீகரிக்கப்பட்டது",
  Geo_Authorized:"புவி இருப்பிடம்: அங்கீகரிக்கப்பட்டது",
  Not_Authorized:"அங்கீகரிக்கப்படவில்லை",
  Authorization_Complete:"அங்கீகாரம் முடிந்தது",
  Geolocation_Authorization:"புவி இருப்பிட அங்கீகாரம்",
  Out_of_Bounds:"எல்லைக்கு வெளியே",
  Cant_Delete_Default_Design:"இயல்பு வடிவமைப்பை நீக்க முடியாது.",
  Unauthorized_Out_of_Bounds:"அங்கீகரிக்கப்படாதது: எல்லைக்கு வெளியே",
  Biometric_Authorization:"பயோ-மெட்ரிக் அங்கீகாரம்",
  Locating_Please_Wait:"கண்டுபிடிக்கிறது, காத்திருக்கவும்...",
  Test_Biometrics:"பயோ மெட்ரிக் சோதனை",
  Cheque_Margins:"விளிம்புகளை சரிபார்க்கவும்",
  Percentage_Full_Error:"சதவீத புலத்தை 100% சதவீதத்திற்கு மேல் அமைக்க முடியாது.",
  No_Payroll_Text:"ஒரு <a routerLink='/folder/Employee/New'>பணியாளர்</a> அல்லது <a routerLink='/folder/Payee/New'>பணம் செலுத்துபவர்</a> மற்றும் <a routerLink='/folder/Schedule ஐச் சேர்க்கவும் /புதிய'>அட்டவணை</a>.",
  Design_Saved:"வடிவமைப்பு சேமிக்கப்பட்டது",
  Default_Design_Selected:"இயல்புநிலை வடிவமைப்பு தேர்ந்தெடுக்கப்பட்டது",
  Partial_Import:"பகுதி இறக்குமதி",
  Partial_Export:"பகுதி ஏற்றுமதி",
  Entire_Import:"முழு இறக்குமதி",
  Entire_Export:"முழு ஏற்றுமதி",
  Existing_Password:"ஏற்கனவே உள்ள கடவுச்சொல்லை உள்ளிடவும்:",
  Existing_PIN:"உங்கள் தற்போதைய பின் குறியீட்டை உள்ளிடவும்:",
  Pin_Change_Header:"பின் உறுதிப்படுத்தல்",
  Pin_Change_SubHeader:"மாற்றத்தை உறுதிப்படுத்த உங்கள் பழைய PIN எண்ணை உள்ளிடவும்.",
  Pass_Change_Header:"கடவுச்சொல் உறுதிப்படுத்தல்",
  Pass_Change_SubHeader:"மாற்றத்தை உறுதிப்படுத்த உங்கள் பழைய கடவுச்சொல்லை உள்ளிடவும்.",
  PIN_Enter_Message:"உறுதிப்படுத்த உங்கள் பின்னை உள்ளிடவும்.",
  Password_Enter_Message:"உறுதிப்படுத்த உங்கள் கடவுச்சொல்லை உள்ளிடவும்.",
  Pin_Updated_Success:"பின் வெற்றிகரமாக புதுப்பிக்கப்பட்டது!",
  Pin_Updated_Fail:"பின்னை புதுப்பிக்க முடியவில்லை.",
  Pass_Updated_Success:"கடவுச்சொல் வெற்றிகரமாக புதுப்பிக்கப்பட்டது.",
  Pass_Updated_Fail:"கடவுச்சொல்லை புதுப்பிக்க முடியவில்லை.",
  Preview_Payment:"கட்டணத்தின் முன்னோட்டம்",
  Preview_Payroll:"முன்பார்வை ஊதியம்",
  No_Payments_Created:"பணம் எதுவும் உருவாக்கப்படவில்லை.",
  Payment_Preview:"கட்டண முன்னோட்டம்",
  Enable_Payee:"பணம் பெறுபவரை இயக்கு",
  Rendered:"வழங்கப்பட்டுள்ளது",
  No_Email:"மின்னஞ்சல் இல்லை",
  Setup_Cheques:"அமைவு சோதனைகள்",
  name:"பெயர்",
  address:"முகவரி",
  address_2:"முகவரி வரி 2",
  city:"நகரம்",
  province:"மாகாணம்",
  postal_code:"அஞ்சல்/ஜிப் குறியீடு",
  country:"நாடு",
  username:"பயனர் பெயர்",
  full_name:"முழு பெயர்",
  birthday:"பிறந்தநாள்",
  email:"மின்னஞ்சல்",
  phone:"தொலைபேசி",
  employees:"பணியாளர்கள்",
  addresses:"முகவரிகள்",
  payment_amount_limit:"செலுத்தும் தொகை வரம்பு",
  total_limit:"மொத்த வரம்பு",
  payees:"பணம் பெறுபவர்கள்",
  description:"விளக்கம்",
  address_line_one:"முகவரி வரி ஒன்று",
  address_line_two:"முகவரி வரி இரண்டு",
  image:"படம்",
  account_holder:"கணக்கு வைத்திருப்பவர்",
  cheque_starting_id:"தொடக்க ஐடியைச் சரிபார்க்கவும்",
  transit_number:"போக்குவரத்து எண்",
  institution_number:"நிறுவன எண்",
  designation_number:"பதவி எண்",
  account_number:"கணக்கு எண்",
  currency:"நாணய",
  signature:"கையெழுத்து",
  payment_payroll_limit:"கட்டண வரம்பு",
  total_limi:"மொத்த வரம்பு",
  date:"தேதி",
  printed_memo:"அச்சிடப்பட்ட மெமோ",
  banks:"வங்கிகள்",
  signature_image:"கையெழுத்து படம்",
  address_name:"முகவரி பெயர்",
  notes:"குறிப்புகள்",
  design:"வடிவமைப்பு",
  title:"தலைப்பு",
  frequency:"அதிர்வெண்",
  fax:"தொலைநகல்",
  salaries:"சம்பளம்",
  salary_ids:"சம்பள அடையாளங்கள்",
  start_time:"ஆரம்பிக்கும் நேரம்",
  end_time:"முடிவு நேரம்",
  paid:"செலுத்தப்பட்டது",
  overtime_percentage:"செலுத்தப்பட்ட சதவீதம்",
  overtime_amount:"செலுத்தப்பட்ட நிலையான தொகை",
  first_name:"முதல் பெயர்",
  last_name:"கடைசி பெயர்",
  moderation:"நிதானம்",
  create:"உருவாக்கு",
  edit:"தொகு",
  destroy:"அழிக்கவும்",
  day_start_time:"நாள்_தொடக்க_நேரம்",
  day_end_time:"நாள்_இறுதி_நேரம்",
  fullname:"பெயர்",
  time:"நேரம்",
  auto_send:"தானாக அனுப்பு",
  time_method:"நேர முறை",
  schedules:"அட்டவணைகள்",
  indefinite_payroll_enabled:"காலவரையின்றி இயக்கு",
  amount:"தொகை",
  repeat:"மீண்டும் செய்யவும்",
  always_enabled:"எப்போதும் இயக்கப்பட்டிருக்கும்",
  start_date:"தொடக்க தேதி",
  end_date:"கடைசி தேதி",
  Cheque_Total:"மொத்தத்தை சரிபார்க்கவும்",
  Total_Amount:"மொத்த தொகை:",
  Amounts:"தொகைகள்:",
  Images:"படங்கள்",
  Files:"கோப்புகள்",
  Previewing:"முன்னோட்டம்:",
  Insert:"செருகு",
  Preview_Import:"முன்னோட்ட இறக்குமதி",
  Entry:"நுழைவு",
  Entries:"உள்ளீடுகள்",
  No_Entries:"உள்ளீடுகள் இல்லை",
  Import_Type:"இறக்குமதி வகை",
  Select_Details:"விவரங்களைத் தேர்ந்தெடுக்கவும்",
  Select_Payee:"பணம் செலுத்துபவரைத் தேர்ந்தெடுக்கவும்",
  Enable_Holidays:"விடுமுறை நாட்களை இயக்கு",
  Disable_Holidays:"விடுமுறை நாட்களை முடக்கு",
  Wire_Transfer:"கம்பி பரிமாற்றம்",
  New_Export:"புதிய ஏற்றுமதி",
  Export_Data:"தரவு ஏற்றுமதி",
  Export_Data_Word:"ஏற்றுமதி மற்றும் பதிவிறக்குவதற்கான கோப்பு வகையைத் தேர்ந்தெடுக்கவும்.",
  Export_File:"கோப்பு ஏற்றுமதி",
  Mode:"பயன்முறை",
  Times:"நேரங்கள்",
  Widgets:"விட்ஜெட்டுகள்",
  Slider:"ஸ்லைடர்",
  Set_Details:"விவரங்களை அமைக்கவும்",
  Select_Type:"வகையைத் தேர்ந்தெடுக்கவும்",
  Display_Slider:"காட்சி ஸ்லைடர்",
  Dashboard_Slider:"டாஷ்போர்டு ஸ்லைடர்",
  Dashboard_Mode:"டாஷ்போர்டு பயன்முறை",
  Show_Intro:"அறிமுகத்தைக் காட்டு",
  Show_Payrolls:"சம்பளப்பட்டியல்களைக் காட்டு",
  Show_Holidays:"விடுமுறை நாட்களைக் காட்டு",
  Show_Invoices:"இன்வாய்ஸ்களைக் காட்டு",
  Show_Cheques:"காசோலைகளைக் காட்டு",
  Enabled_Widgets:"இயக்கப்பட்ட விட்ஜெட்டுகள்",
  Disabled_Widgets:"முடக்கப்பட்ட விட்ஜெட்டுகள்",
  Colors:"வண்ணங்கள்",
  Barcodes:"பார்கோடுகள்",
  View_Timers:"டைமர்களைக் காண்க",
  Gradients:"சாய்வுகள்",
  No_Info:"தகவல் இல்லை",
  Disable:"முடக்கு",
  Show_Layer:"அடுக்குகளைக் காட்டு",
  Hide_Layer:"அடுக்குகளை மறை",
  Text_Layer:"உரை அடுக்குகள்",
  Secondly:"இரண்டாவதாக",
  Minutely:"நிமிடத்திற்கு",
  nine_am:"காலை 9.00 மணி",
  five_pm:"மாலை 5:00",
  Enable_Address:"முகவரியை இயக்கு",
  Invalid_File_Type:"மன்னிக்கவும், தவறான கோப்பு வகை தேர்ந்தெடுக்கப்பட்டது. ஆதரிக்கப்படும் கோப்பு வகை:",
  Error_Updating_Entry:"மன்னிக்கவும், இந்த பதிவை புதுப்பிப்பதில் பிழை.",
  Schedule_Timing_Alert:"பிழை: அட்டவணை தொடக்க நேரம் முடிவு நேரத்திற்குப் பிறகு மதிப்பிற்கு அமைக்கப்பட்டுள்ளது.",
  Select_Multiple_Payments_Prompt:"கட்டணம் எதுவும் தேர்ந்தெடுக்கப்படவில்லை. குறைந்தபட்சம் ஒரு கட்டணத்தைத் தேர்ந்தெடுக்கவும்.",
  Select_Multiple_Cheques_Prompt:"காசோலை எதுவும் தேர்ந்தெடுக்கப்படவில்லை. குறைந்தது ஒரு காசோலையைத் தேர்ந்தெடுக்கவும்.",
  Select_Multiple_Items_Prompt:"உருப்படிகள் எதுவும் தேர்ந்தெடுக்கப்படவில்லை. குறைந்தபட்சம் ஒரு உருப்படியைத் தேர்ந்தெடுக்கவும்.",
  Paymemt_Multiple_Delete_Confirmation:"பல கட்டணங்களை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  Cheque_Multiple_Delete_Confirmation:"பல காசோலைகளை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  Payee_Multiple_Delete_Confirmation:"பல பணம் பெறுபவர்களை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  Employee_Multiple_Delete_Confirmation:"பல ஊழியர்களை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  MICR_Warning:"குறிப்பு: சில அச்சுப்பொறிகளும் சாதனங்களும் MICR எழுத்துருவைச் சரியாகக் காட்டாமல் இருக்கலாம்.",
  Automatically_Send:"தானாக அனுப்பு",
  Type:"வகை",
  Payment_Type:"கட்டணம் வகை",
  Auto_Send:"தானாக அனுப்பு",
  Automatically_Process:"தானாக செயலாக்கம்",
  Auto_Process:"தானியங்கு செயல்முறை",
  Image_Based:"படத்தை அடிப்படையாகக் கொண்டது",
  Font_Based:"எழுத்துரு அடிப்படையிலானது",
  Rerender:"மீண்டும் வழங்கு",
  Rendering:"வழங்குதல்",
  Render:"கோப்புகள்",
  Top:"மேல்",
  Middle:"நடுத்தர",
  Bottom:"கீழே",
  Top_Left:"மேல் இடது",
  Top_Center:"மேல் மையம்",
  Top_Right:"மேல் வலது",
  Middle_Left:"மத்திய இடது",
  Middle_Center:"நடுத்தர மையம்",
  Middle_Right:"மத்திய வலது",
  Bottom_Left:"கீழே இடது",
  Bottom_Center:"கீழ் மையம்",
  Bottom_Right:"கீழ் வலது",
  Numbers:"எண்கள்",
  Verified:"சரிபார்க்கப்பட்டது",
  Paper_Size:"காகித அளவு",
  New_Device:"புதிய சாதனம்",
  Add_Device:"சாதனத்தைச் சேர்க்கவும்",
  Remove_Device:"சாதனத்தை அகற்று",
  Delete_Device:"சாதனத்தை நீக்கு",
  Block_Device:"சாதனத்தைத் தடு",
  Block:"தடு",
  Unblock:"தடைநீக்கு",
  Table:"மேசை",
  Scan_Login_Code:"உள்நுழைவு குறியீட்டை ஸ்கேன் செய்யவும்",
  Login_Code:"உள்நுழைவு குறியீடு",
  Scan_Code:"ஸ்கேன் குறியீடு",
  Scan_QR_Code:"QR குறியீட்டை ஸ்கேன் செய்யவும்",
  Select_All:"அனைத்தையும் தெரிவுசெய்",
  Deselect_All:"அனைத்து தெரிவுகளையும் நிராகரி",
  Increase:"அதிகரி",
  Decrease:"குறைக்கவும்",
  Bold:"தடித்த",
  Text:"உரை",
  Style:"உடை",
  Italic:"சாய்வு",
  QR_Code:"க்யு ஆர் குறியீடு",
  Barcode:"பார்கோடு",
  Browse_Images:"படங்களை உலாவவும்",
  Browse_Files:"கோப்புகளை உலாவவும்",
  Background_Image:"பின்னணி படம்",
  Logo_Image:"லோகோ படம்",
  Header_Image:"பட தலைப்பு",
  Bank_Image:"வங்கி படம்",
  Cut_Lines:"வெட்டு வரிகள்",
  Background:"பின்னணி",
  License:"உரிமம்",
  One_License:"1 உரிமம்:",
  Licensed:"உரிமம் பெற்றது:",
  Not_Licensed:"உரிமம் பெறவில்லை",
  Enter_Serial:"சீரியலை உள்ளிடவும்",
  Serial_Key:"தொடர் விசை",
  Serial:"தொடர்",
  Product_Key:"தயாரிப்பு திறவு கோல்",
  Check_Product_Key:"தயாரிப்பு விசையை சரிபார்க்கவும்",
  Add_Product_Key:"தயாரிப்பு விசையை உள்ளிடவும்",
  Verifying_Purchase:"வாங்குதலைச் சரிபார்க்கிறது...",
  Print_Envelope:"அச்சு உறை",
  Envelope:"உறை",
  Thank_You:"நன்றி!",
  Scale:"அளவுகோல்",
  Print_Scale:"அச்சு அளவு",
  Borders:"எல்லைகள்",
  VOID:"VOID",
  Void_Cheque:"வெற்றிட சோதனை",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ஆணைக்கிணங்க செலுத்து:",
  NO_DOLLARS:"டாலர்கள் இல்லை",
  ONE_DOLLAR:"ஒரு டாலர்",
  DOLLARS:"டாலர்கள்",
  AND:"மற்றும்",
  CENTS:"சென்ட்ஸ்.",
  NO_:"இல்லை",
  ONE_:"ஒன்று",
  AND_NO_:"மற்றும் இல்லை",
  _AND_ONE_:"மற்றும் ஒன்று",
  DOLLARS_AND_ONE_CENT:"மற்றும் ஒரு சென்ட்.",
  AND_NO_CENTS:"மற்றும் செண்ட்ஸ்.",
  CHEQUE_PRINT_DATE:"தேதி:",
  CHEQUE_PRINT_MEMO:"மெமோ:",
  CHEQUE_PRINT_SIGNATUREMP:"எம்.பி",
  Initial_Tasks:"ஆரம்ப பணிகள்",
  Inital_Setup:"ஆரம்ப அமைப்பு",
  Welcome_To:"வரவேற்கிறோம்",
  Welcome:"வரவேற்பு",
  Swipe:"ஸ்வைப் செய்யவும்",
  Intro_Setup:"அறிமுக அமைப்பு",
  Introduction:"அறிமுகம்",
  CHEQUE_PRINT_CREDIT:"காசோலைகள் மூலம் இயக்கப்படுகிறது",
  Title_Intro_Word:"காசோலைகளுக்கு வரவேற்கிறோம்",
  Title_Intro:"சரிபார்ப்பு அறிமுகம்",
  Title_Setup:"அமைப்பை சரிபார்க்கிறது",
  PayPal_Default_Email_Message:"புதிய பேபால் பரிமாற்றத்தைப் பெற்றுள்ளீர்கள்.",
  Cheques_App_Export:"காசோலைகள் மூலம் ஏற்றுமதி செய்யப்பட்டது",
  No_Prints_Purchased:"அச்சிட்டுகள் எதுவும் வாங்கப்படவில்லை",
  No_Prints_Purchased_Word:"கூடுதல் அச்சிட்டுகள் எதுவும் கிடைக்கவில்லை. தயவுசெய்து கூடுதல் காசோலைகளை வாங்கவும்.",
  Post_Intro_Thanks:"காசோலைகளைத் தேர்ந்தெடுத்ததற்கு நன்றி. அமைவு செயல்முறை இப்போது முடிந்தது.",
  Post_Intro_Word:"உங்கள் முதல் காசோலையை அச்சிடுவதன் மூலம் தொடங்கவும், எதிர்கால கட்டணத்தைச் சேர்க்கவும் அல்லது ஊதியத்தில் பணியாளர்களைச் சேர்க்கவும்.",
  Upgrade_Required:"இந்தச் செய்தியை மறைப்பதற்கும் கூடுதல் அம்சங்களைத் திறப்பதற்கும் மென்பொருளின் பிரீமியம் பதிப்பை நீங்கள் சொந்தமாக வைத்திருக்க வேண்டும்.",
  Upgrade_Title:"காசோலைகள்",
  Mailout_Prompt:"உங்களுக்கான ஊதிய காசோலைகளை அஞ்சல் மூலம் அனுப்புவதற்கு நீங்கள் தேர்வு செய்யலாம்.",
  Mailed_Cheque:"அஞ்சல் மூலம் சரிபார்க்கப்பட்டது",
  Mailed_Cheque_Color:"அஞ்சல் காசோலை (நிறம்)",
  Terms_Purchase:"காசோலைகளுடன் கூடிய அனைத்து மின்னணு கொள்முதல்களும் வாங்கிய தேதியிலிருந்து 30 நாட்கள் வரை முழுமையாகத் திருப்பியளிக்கப்படும். தயவு செய்து படித்து ஒத்துக்கொள்ளுங்கள்",
  Dashboard_Setup:"முதன்மை அச்சுப்பொறியை அமைக்கவும்",
  Dashboard_Add:"முதன்மை வங்கிக் கணக்கைச் சேர்க்கவும்",
  Dashboard_Customize:"ஒரு காசோலை டெம்ப்ளேட்டைத் தேர்ந்தெடுக்கவும்",
  Dashboard_Job_Salary:"உங்கள் வேலையை உருவாக்கி உங்கள் சம்பளத்தைச் சேர்க்கவும்",
  Dashboard_Employees:"பணியாளர்கள் மற்றும் பணம் பெறுபவர்களைக் கண்காணிக்கவும்",
  Dashboard_Print:"உங்கள் காசோலைகளை அச்சிட்டு அஞ்சல் செய்யவும்",
  Dashboard_Payroll:"உங்கள் சம்பளப்பட்டியல் அச்சிடலை தானியங்குபடுத்துங்கள்",
  Dashboard_PayPal:"பேபால் சம்பளப்பட்டியல் / கொடுப்பனவுகளை அமைக்கவும்",
  Begin_Setup:"அமைப்பைத் தொடங்கவும்",
  Get_Started:"தொடங்குங்கள்",
  Purchase:"கொள்முதல்",
  Lockdown:"முடக்குதல்",
  Unlock:"திறக்கவும்",
  Detailed:"விரிவான",
  Log_In:"உள்நுழைய",
  Login:"உள்நுழைய",
  Launch:"துவக்கவும்",
  Register:"பதிவு",
  Finish:"முடிக்கவும்",
  List:"பட்டியல்",
  Weekends:"வார இறுதி நாட்கள்",
  Weekly:"வாரந்தோறும்",
  PayPal_Default_Subject:"புதிய கட்டணம்",
  Payment_Message:"பணம் செலுத்தும் செய்தி",
  PayPal_Default_Payment_Note:"நன்றி",
  Setup_Your_Cheqing_Account:"கணக்கு சரிபார்த்தல்",
  Add_Your_Primary_Cheqing_Account:"உங்கள் முதன்மை சரிபார்ப்புக் கணக்கைச் சேர்க்கவும்.",
  Welcome_Word:"ஊதியம் மற்றும் மனித வள நிர்வாகத்தை எளிமைப்படுத்தவும் தானியங்குபடுத்தவும்.",
  Get_Started_Quickly:"தொடங்குவதற்கு உதவும் சில எளிய கேள்விகளுக்குப் பதிலளிக்கவும்...",
  Done_Intro_Word:"அமைவு முடிந்தது",
  PIN_Protected:"கடவுச்சொல் மற்றும் பின் பாதுகாக்கப்பட்டது",
  Enter_New_PIN:"புதிய பின் குறியீட்டை உள்ளிடவும்:",
  Enter_PIN:"பின் குறியீட்டை உள்ளிடவும்:",
  Invalid_PIN:"தவறான பின் உள்ளிடப்பட்டது.",
  Account_Identifier:"கணக்கு அடையாளங்காட்டி",
  New_Account_Identifier:"புதிய கணக்கு அடையாளங்காட்டி",
  attempt:"முயற்சி",
  attempts:"முயற்சிகள்",
  remaining:"மீதமுள்ள",
  Language:"மொழி",
  languages:"மொழிகள்",
  select_languages:"மொழியை தேர்ந்தெடுங்கள்",
  Deposit:"வைப்பு",
  Hire_One_Now:"இப்போது ஒருவரை நியமிக்கவும்",
  App_Locked:"பயன்பாடு பூட்டப்பட்டுள்ளது.",
  Skip_:"தவிர்க்கவும்",
  Skip_to_Dashboard:"டாஷ்போர்டுக்கு செல்க",
  Dashboard:"டாஷ்போர்டு",
  Import:"இறக்குமதி",
  Admin:"நிர்வாகிகள்",
  New_Admin:"புதிய நிர்வாகி",
  Settings:"அமைப்புகள்",
  Color_Picker:"வண்ண தெரிவு",
  Font_Picker:"எழுத்துரு தேர்வு",
  Logout:"வெளியேறு",
  Close:"நெருக்கமான",
  Close_menu:"நெருக்கமான",
  Excel:"எக்செல் கோப்பு",
  Csv:"CSV கோப்பு",
  Sql:"SQL கோப்பு",
  Json:"JSON கோப்பு",
  Url:"URL மூலம் இறக்குமதி செய்யவும்",
  Back:"மீண்டும்",
  Timers:"டைமர்கள்",
  Cheque:"காசோலை",
  Print:"அச்சிடுக",
  Designs:"வடிவமைப்புகள்",
  Pause_Printing:"அச்சிடும் இடைநிறுத்தம்",
  Resume_Printing:"மீண்டும் அச்சிடுதல்",
  Printing_Paused:"அச்சிடுதல் இடைநிறுத்தப்பட்டது",
  PrintingUnavailable:"மன்னிக்கவும்! இந்த அமைப்பில் அச்சிடுதல் கிடைக்கவில்லை.",
  Prints_Paused:"அச்சுகள் இடைநிறுத்தப்பட்டன",
  Administration:"நிர்வாகம்",
  Loading:"ஏற்றுகிறது",
  Unnamed:"பெயரிடப்படாதது",
  error:"மன்னிக்கவும், இந்த காசோலையை பார்ப்பதற்காக திறக்க முடியவில்லை.",
  No_Cheques_To_Print:"அச்சிட காசோலைகள் இல்லை",
  No_Cheques_To_Print_Word:"<a routerLink='/folder/Cheque/New'>புதிய காசோலையை</a> உருவாக்கவும்.",
  New_Cheque:"புதிய காசோலை",
  Start_One_Now:"இப்போது ஒன்றைத் தொடங்குங்கள்",
  Edit_Cheque:"சரிபார்ப்பைத் திருத்து",
  Select_Cheques:"காசோலைகளைத் தேர்ந்தெடுக்கவும்",
  Select_Employee:"பணியாளரைத் தேர்ந்தெடுக்கவும்",
  Default_Printer:"இயல்புநிலை அச்சுப்பொறி",
  Reassign:"மறுஒதுக்கீடு",
  Configure:"கட்டமைக்கவும்",
  Template:"டெம்ப்ளேட்",
  Designer:"வடிவமைப்பாளர்",
  Edit_Designs:"வடிவமைப்புகளைத் திருத்தவும்",
  New_Design:"புதிய வடிவமைப்பு",
  Next:"அடுத்தது",
  Save:"சேமிக்கவும்",
  Name:"பெயர்",
  Mail:"அஞ்சல்",
  Amount:"தொகை",
  Date:"தேதி",
  PayPal:"பேபால்",
  Payouts:"கொடுப்பனவுகள்",
  PayPal_Label:"பேபால் லேபிள்",
  Email_Username:"மின்னஞ்சல் / பயனர் பெயர்",
  Client_ID:"வாடிக்கையாளர் ஐடி",
  Sandbox_Email:"சாண்ட்பாக்ஸ் மின்னஞ்சல்",
  PayPal_Email:"பேபால் மின்னஞ்சல்",
  PayPal_Username:"API பயனர்பெயர்",
  PayPal_Payouts:"பேபால் பேஅவுட்கள்",
  Select_PayPal_Key:"பேபால் விசையைத் தேர்ந்தெடுக்கவும்",
  Secret:"இரகசியம்",
  API_Secret:"API ரகசியம்",
  PayPal_API_Keys:"பேபால் விசைகள்",
  New_PayPal_Key:"புதிய பேபால் கீ",
  Email_Subject:"மின்னஞ்சல் பொருள்",
  Email_Message:"மின்னஞ்சல் செய்தி",
  Payout_Options:"பணம் செலுத்தும் விருப்பங்கள்",
  Payment_Note:"கட்டணக் குறிப்பு",
  Enable_Employee:"பணியாளரை இயக்கு",
  Enable_Production_Mode:"உற்பத்தி பயன்முறையை இயக்கவும்",
  Sandbox_Username:"சாண்ட்பாக்ஸ் பயனர்பெயர்",
  Sandbox_Signature:"சாண்ட்பாக்ஸ் கையொப்பம்",
  Sandbox_Password:"சாண்ட்பாக்ஸ் கடவுச்சொல்",
  Production_Username:"தயாரிப்பு பயனர்பெயர்",
  Production_Signature:"உற்பத்தி கையொப்பம்",
  Production_Password:"தயாரிப்பு கடவுச்சொல்",
  Production_Email:"தயாரிப்பு மின்னஞ்சல்",
  API_Client_ID:"API கிளையண்ட் ஐடி",
  API_Signature:"API கையொப்பம்",
  API_Password:"API கடவுச்சொல்",
  API_Username:"API பயனர்பெயர்",
  Secret_Key:"இரகசிய விசை",
  Sandbox:"சாண்ட்பாக்ஸ்",
  Production:"உற்பத்தி",
  Sandbox_Keys:"சாண்ட்பாக்ஸ் விசைகள்",
  Production_Keys:"உற்பத்தி விசைகள்",
  API_Title:"API தலைப்பு",
  Production_Mode:"உற்பத்தி முறை",
  Account_Label:"கணக்கு லேபிள்",
  No_PayPal_Setup:"பேபால் விசை இல்லை",
  Enable_PayPal_Account:"பேபால் கணக்கை இயக்கவும்",
  No_PayPal_Word:"உங்கள் <a routerLink='/folder/Invoice/New'>பேபால் API விசையைச்</a> சேர்க்கவும்.",
  Printed_Memo:"அச்சிடப்பட்ட மெமோ",
  Employee:"பணியாளர்",
  View_Employee:"பணியாளரைப் பார்க்கவும்",
  Mailing_Address:"அஞ்சல் முகவரி",
  Company_Address:"நிறுவனத்தின் முகவரி",
  Select_Company_Address:"நிறுவனத்தின் முகவரியைத் தேர்ந்தெடுக்கவும்",
  Address:"முகவரி",
  Any_Day:"எந்த நாளும்",
  Address_Name:"முகவரி பெயர்",
  Unit:"அலகு",
  Account:"கணக்கு",
  Bank_Account:"வங்கி கணக்கு",
  Account_Limits:"கணக்கு வரம்புகளை இயக்கவும்",
  Payroll:"ஊதியம்",
  Run:"ஓடு",
  Run_Payroll:"ஊதியத்தை இயக்கவும்",
  New_Payroll:"புதிய ஊதியம்",
  No_Payroll:"வரவிருக்கும் ஊதியம் இல்லை",
  Upcoming_Holiday:"வரவிருக்கும் விடுமுறை:",
  Invoice_Due:"நிலுவைத் தொகை:",
  New_Invoice:"புதிய விலைப்பட்டியல்",
  No_Invoices:"இன்வாய்ஸ்கள் இல்லை",
  No_Invoices_Word:"முதல் <a routerLink='/folder/Invoice/New'>இன்வாய்ஸை</a> உருவாக்கவும்.",
  Cheque_Due:"நிலுவைத் தொகையைச் சரிபார்க்கவும்:",
  Payrolls:"ஊதியங்கள்",
  Sandbox_Mode:"சாண்ட்பாக்ஸ் பயன்முறை",
  Hire:"பணியமர்த்தவும்",
  Pay:"செலுத்து",
  New:"புதியது",
  Add:"கூட்டு",
  Make:"செய்ய",
  Time:"நேரம்",
  Write:"எழுது",
  Holiday:"விடுமுறை",
  Holidays:"விடுமுறை",
  Next_Holiday:"அடுத்த விடுமுறை:",
  All_Done:"அனைத்தும் முடிந்தது!",
  Employees:"பணியாளர்கள்",
  Payees:"பணம் பெறுபவர்கள்",
  Job:"வேலை",
  Jobs:"வேலைகள்",
  Invoice:"விலைப்பட்டியல்",
  Invoices:"இன்வாய்ஸ்கள்",
  Vacations:"விடுமுறைகள்",
  Cheques:"காசோலைகள்",
  Brand_Cheques:"உங்கள் பிராண்ட்",
  Payment:"பணம் செலுத்துதல்",
  Enable_Payment:"கட்டணத்தை இயக்கு",
  Payments:"கொடுப்பனவுகள்",
  Schedule:"அட்டவணை",
  Schedules:"அட்டவணைகள்",
  Timesheet:"நேர தாள்",
  Timesheets:"நேரத் தாள்கள்",
  Salary:"சம்பளம்",
  New_Address:"புதிய முகவரி",
  Address_2:"முகவரி வரி 2)",
  _City:"நகரம்",
  _State:"மாநிலம்/நிபுணர்",
  City:"நகரம் / டவுன்ஷிப்",
  State:"மாநிலம் / மாகாணம்",
  Postal:"அஞ்சல் / அஞ்சல் குறியீடு",
  ZIP:"அஞ்சல் / ஜிப்",
  Country:"நாடு",
  Addresses:"முகவரிகள்",
  Required_Options:"தேவையான விருப்பங்கள்",
  Optional_Options:"விருப்ப விருப்பங்கள்",
  Additional_Options:"கூடுதல் விருப்பங்கள்",
  Required:"தேவை",
  Optional:"விருப்பமானது",
  Additional:"கூடுதல்",
  No_Addresses:"முகவரிகள் இல்லை",
  New_Address_Word:"முதல் <a routerLink='/folder/Address/New'>முகவரியைச்</a> சேர்க்கவும்.",
  Select_Address:"முகவரியைத் தேர்ந்தெடுக்கவும்",
  No_Address:"முகவரிகள் இல்லை",
  Print_Cheque:"அச்சு சரிபார்ப்பு",
  Print_Cheques:"அச்சு காசோலைகள்",
  Print_Cheque_Now:"அச்சிடுக இப்போது சரிபார்க்கவும்",
  Description:"விளக்கம்",
  Pay_To_The_Order_Of:"ஆணைக்கிணங்க செலுத்து:",
  Select_Date_Range:"தேதி வரம்பைத் தேர்ந்தெடுக்கவும்",
  Select_Starting_Date:"தொடக்க தேதியைத் தேர்ந்தெடுக்கவும்",
  Select_Ending_Date:"முடிவு தேதியைத் தேர்ந்தெடுக்கவும்",
  Select_Date:"தேதியைத் தேர்ந்தெடுக்கவும்",
  Cheque_Date:"தேதியைச் சரிபார்க்கவும்",
  Cheque_Memo:"மெமோவை சரிபார்க்கவும்",
  Blank_Cheque:"வெற்று சோதனை",
  Blank:"வெற்று",
  No_Cheques:"காசோலைகள் இல்லை",
  No_Cheques_Word:"உங்கள் முதல் <a routerLink='/folder/Cheque/New'>காசோலையை</a> அச்சிடவும்.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"படத்தைப் பார்க்கவும்",
  View:"காண்க",
  Modify:"மாற்றியமைக்கவும்",
  Delete:"அழி",
  Cheque_Paid:"செலுத்தப்பட்டது",
  New_Deduction:"புதிய விலக்கு",
  Title:"தலைப்பு",
  Frequency:"அதிர்வெண்",
  Hourly:"மணிநேரம்",
  Daily:"தினசரி",
  Weekdays:"வார நாட்கள்",
  BiWeekly:"2 வாரங்கள்",
  TriWeekly:"3 வாரங்கள்",
  Monthly:"மாதாந்திர",
  MiMonthly:"2 மாதங்கள்",
  Quarterly:"காலாண்டு",
  Yearly:"ஆண்டுதோறும்",
  Every_Week:"ஒவ்வொரு வாரமும்",
  Every_Payroll:"ஒவ்வொரு ஊதியம்",
  Every_Month:"ஒவ்வொரு மாதமும்",
  Annually:"ஆண்டுதோறும்",
  Always_Scheduled:"எப்போதும் திட்டமிடப்பட்டுள்ளது",
  Start_Date:"தொடக்க தேதி",
  End_Date:"கடைசி தேதி",
  Start_Time:"ஆரம்பிக்கும் நேரம்",
  End_Time:"முடிவு நேரம்",
  Deduction_Label:"கழித்தல் லேபிள்",
  Notes:"குறிப்புகள்",
  Options:"விருப்பங்கள்",
  Enable:"இயக்கு",
  Select_Deductions:"விலக்குகளைத் தேர்ந்தெடுக்கவும்",
  Deductions:"விலக்குகள்",
  No_Deductions:"விலக்குகள் இல்லை",
  No_Deductions_Word:"உங்கள் முதல் <a routerLink='/folder/Deduction/New'>கழித்தல்</a> ஐ உருவாக்கவும்.",
  New_Employee:"புதிய பணியாளர்",
  No_Title:"தலைப்பு இல்லை",
  _Name:"பெயர்",
  About_Yourself:"உங்களை பற்றி",
  Full_Name:"முழு பெயர்",
  Birthday:"பிறந்தநாள்",
  Email:"மின்னஞ்சல்",
  SMS:"எஸ்எம்எஸ்",
  Phone:"தொலைபேசி",
  Test:"சோதனை",
  Call:"அழைப்பு",
  Fax:"தொலைநகல்",
  Printed_Note:"அச்சிடப்பட்ட குறிப்பு",
  Position:"பதவி",
  Job_Position:"வேலை நிலை",
  Select_a_Job:"ஒரு வேலையைத் தேர்ந்தெடுக்கவும்",
  Select_a_Salary:"சம்பளத்தைத் தேர்ந்தெடுக்கவும்",
  Add_a_Deduction:"விலக்கு சேர்க்கவும்",
  Taxes:"வரிகள்",
  Add_Taxes:"வரிகளைச் சேர்க்கவும்",
  Date_of_Birth:"பிறந்த தேதி",
  Email_Address:"மின்னஞ்சல் முகவரி",
  Phone_Number:"தொலைபேசி எண்",
  Phone_Call:"தொலைபேசி அழைப்பு",
  Enable_on_Payroll:"ஊதியத்தில் இயக்கவும்",
  Select_Employees:"பணியாளர்களைத் தேர்ந்தெடுக்கவும்",
  No_Employees:"பணியாளர்கள் இல்லை",
  No_Employees_Word:"உங்கள் முதல் புதிய <a routerLink='/folder/Employee/New'>பணியாளரைச்</a> சேர்க்கவும்.",
  No_Name:"பெயர் இல்லை",
  Unemployeed:"வேலையில்லாதவர்",
  Employeed:"பணியமர்த்தப்பட்டார்",
  Paid:"செலுத்தப்பட்டது",
  Enabled:"இயக்கப்பட்டது",
  Disabled:"முடக்கப்பட்டது",
  Fire:"தீ",
  Not_Available:"கிடைக்கவில்லை",
  Not_Available_Word:"உங்கள் முதல் <a routerLink='/folder/Invoice/New'>விலைப்பட்டியல்</a> அச்சிட்டு பணம் பெறவும்.",
  Select_Invoices:"இன்வாய்ஸ்களைத் தேர்ந்தெடுக்கவும்",
  Print_Invoice_Word:"உங்கள் முதல் <a routerLink='/folder/Invoice/New'>விலைப்பட்டியல்</a> அச்சிட்டு பணம் பெறவும்.",
  Invoice_Title:"விலைப்பட்டியல் தலைப்பு",
  Invoice_Date:"விலைப்பட்டியல் தேதி",
  Due_Date:"இறுதி தேதி",
  New_Job:"புதிய வேலை",
  Details:"விவரங்கள்",
  Customize:"தனிப்பயனாக்கலாம்",
  Customize_Dashboard:"டாஷ்போர்டைத் தனிப்பயனாக்கு",
  Components:"கூறுகள்",
  No_Components:"கூறுகள் இல்லை",
  Main_Components:"முக்கிய கூறுகள்",
  Smaller_Components:"சிறிய கூறுகள்",
  Error_Loading_Page:"இந்தப் பக்கத்தை ஏற்றுவதில் பிழை.",
  Bank_Details:"வங்கி விவரங்கள்",
  About_Your_Job:"உங்கள் வேலையைப் பற்றி",
  Your_Schedule:"உங்கள் அட்டவணை",
  Job_Title:"வேலை தலைப்பு",
  Job_Description:"வேலை விவரம்",
  Job_Details:"வேலை விவரங்கள்",
  Enable_Job:"வேலையை இயக்கு",
  Pay_Period:"செலுத்தும் காலம்",
  Perpetually_Schedule:"நிரந்தரமாக அட்டவணை",
  Select_Jobs:"வேலைகளைத் தேர்ந்தெடுக்கவும்",
  No_Jobs:"வேலைகள் இல்லை",
  Add_Jobs:"வேலைகளைச் சேர்க்கவும்",
  No_Jobs_Word:"பட்டியலில் முதல் <a routerLink='/folder/Job/New'>வேலை</a> ஐச் சேர்க்கவும்.",
  Count_Employees:"job.employee_count+' பணியாளர்கள்'",
  Zero_Employees:"0 பணியாளர்கள்",
  New_Leave:"புதிய விடுப்பு",
  Leave_Name:"பெயரை விடுங்கள்",
  Paid_Leave:"ஊதியத்துடன் விடுமுறை",
  Leave_Pay:"லீவ் பே",
  Indefinite_Leave:"காலவரையற்ற விடுப்பு",
  Indefinite_Payroll:"காலவரையற்ற ஊதியம்",
  Leave:"கிளம்பு",
  Add_Schedules:"அட்டவணைகளைச் சேர்க்கவும்",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"இல்லாததை இயக்கு",
  Select_Leaves:"இலைகளைத் தேர்ந்தெடுக்கவும்",
  No_Leaves:"இல்லாத இலைகள் இல்லை",
  Leave_Of_Absence:"இல்லாத விடுப்பு",
  Leaves_Of_Absence:"இல்லாத இலைகள்",
  New_Leave_of_Absense:"இல்லாத புதிய விடுப்பு",
  No_Leaves_Word:"முதல் <a routerLink='/folder/Leave/New'>இல்லாத விடுப்பு</a> ஐச் சேர்க்கவும்.",
  Not_Enabled:"இயக்கப்படவில்லை",
  Absences:"இல்லாதவை",
  Payee:"பணம் பெறுபவர்",
  New_Payee:"புதிய பணம் பெறுபவர்",
  Payee_Identifier:"பணம் பெறுபவர் அடையாளங்காட்டி",
  Payee_Name:"பணம் பெறுபவர் பெயர்",
  Payee_Title:"பணம் பெறுபவர் தலைப்பு",
  Payment_Memo:"பேமெண்ட் மெமோ",
  Select_Payees:"பணம் செலுத்துபவர்களைத் தேர்ந்தெடுக்கவும்",
  No_Payees:"பணம் பெறுவோர் இல்லை",
  Add_Payee_Note:"முதல் <a routerLink='/folder/Payee/New'>பணம் செலுத்துபவர்</a>ஐச் சேர்க்கவும்.",
  New_Payees:"புதிய பணம் பெறுபவர்கள்",
  About_The_Payment_Schedule:"கட்டண அட்டவணை",
  Your_Payroll_Schedule:"தானியங்கி ஊதியம்",
  New_Payment:"புதிய கட்டணம்",
  Identifier:"அடையாளங்காட்டி",
  Selected:"தேர்ந்தெடுக்கப்பட்டது",
  Select:"தேர்ந்தெடு",
  Memo:"மெமோ",
  Payment_Date:"கட்டணம் தேதி",
  Mark_as_Paid:"பணம் செலுத்தியதாகக் குறிக்கவும்",
  Select_Payments:"கொடுப்பனவுகளைத் தேர்ந்தெடுக்கவும்",
  No_Payments:"கட்டணம் இல்லை",
  No_Payments_Word:"முதல் <a routerLink='/folder/Payment/New'>கட்டணம்</a> ஐ உருவாக்கவும்.",
  Create_Payroll:"ஊதியத்தை உருவாக்கவும்",
  Properties:"பண்புகள்",
  Payroll_Title:"ஊதிய தலைப்பு",
  Payroll_Notes:"ஊதிய குறிப்புகள்",
  Payroll_Setup:"ஊதிய அமைப்பு",
  Tabulate_Payments:"கட்டணங்களை அட்டவணைப்படுத்தவும்",
  Tabulate:"அட்டவணை",
  By:"மூலம்:",
  Payments_By:"மூலம் பணம் செலுத்துதல்",
  Timesheets_And_Schedules:"நேரத் தாள்கள் & அட்டவணைகள்",
  Both:"இரண்டும்",
  Items:"பொருட்களை",
  Add_Payees:"பணம் பெறுபவர்களைச் சேர்க்கவும்",
  Add_Account:"கணக்கு சேர்க்க",
  Enable_Account:"கணக்கை இயக்கு",
  Enable_Payroll:"ஊதியத்தை இயக்கு",
  Print_Payroll:"ஊதியத்தை அச்சிடுங்கள்",
  No_Payrolls:"ஊதியம் இல்லை",
  No_Payroll_Word:"உங்கள் முதல் <a routerLink='/folder/Payroll/New'>ஊதியம்</a> உருவாக்கவும்.",
  View_more:"மேலும் பார்க்க",
  Less:"குறைவாக",
  Add_Payroll:"சம்பளப்பட்டியலைச் சேர்க்கவும்",
  Select_Payroll:"சம்பளப்பட்டியலைத் தேர்ந்தெடுக்கவும்",
  About_Your_Salary:"உங்கள் சம்பளம் பற்றி",
  Add_Salaries:"சம்பளம் சேர்க்கவும்",
  Add_Salary:"சம்பளம் சேர்க்கவும்",
  Salaries:"சம்பளம்",
  No_Salaries:"சம்பளம் இல்லை",
  No_Salaries_Word:"முதல் <a routerLink='/folder/Salary/New'>Salary</a>ஐச் சேர்க்கவும்.",
  Select_Salaries:"சம்பளத்தைத் தேர்ந்தெடுக்கவும்",
  New_Salary:"புதிய சம்பளம்",
  Salary_Name:"சம்பள அடையாளங்காட்டி",
  Enable_Salary:"சம்பளத்தை இயக்கு",
  Salary_Amount:"சம்பள தொகை",
  New_Schedule:"புதிய அட்டவணை",
  Schedule_Title:"அட்டவணை தலைப்பு",
  Add_Address:"முகவரியைச் சேர்க்கவும்",
  Repeat:"மீண்டும் செய்யவும்",
  Design:"வடிவமைப்பு",
  Edit_Design:"வடிவமைப்பைத் திருத்தவும்",
  Edit_this_Design:"இந்த வடிவமைப்பைத் திருத்தவும்",
  Repeat_Payment:"மீண்டும் செலுத்துதல்",
  Enable_Schedule:"அட்டவணையை இயக்கு",
  Never:"ஒருபோதும் இல்லை",
  Select_Schedule:"அட்டவணைகளைத் தேர்ந்தெடுக்கவும்",
  No_Schedules:"அட்டவணைகள் இல்லை",
  No_Schedules_Word:"முதல் <a routerLink='/folder/Schedule/New'>அட்டவணையை</a> உருவாக்கவும்.",
  New_Administrator:"புதிய நிர்வாகி",
  Username:"பயனர் பெயர்",
  First_Name:"முதல் பெயர்",
  Last_Name:"கடைசி பெயர்",
  Add_an_Address:"முகவரியைச் சேர்க்கவும்",
  Payment_Limit:"ஒரு கட்டண வரம்பு",
  Total_Limit:"மொத்த வரம்பு",
  Select_Accounts:"கணக்குகளைத் தேர்ந்தெடுக்கவும்",
  No_Administrators:"நிர்வாகிகள் இல்லை",
  No_Administrators_Word:"முதல் <a routerLink='/folder/Administrator/New'>நிர்வாகக் கணக்கை</a> உருவாக்கவும்.",
  New_Administrators_Word:"முதல் <a routerLink='/folder/Administrator/New'>நிர்வாகியை</a> சேர்க்கவும்",
  Cloud:"மேகம்",
  Backup:"காப்புப்பிரதி",
  Enable_iCloud:"iCloud ஐ இயக்கவும்",
  Enable_Google_Drive:"Google இயக்ககத்தை இயக்கு",
  Enable_Microsoft_OneDrive:"Microsoft OneDrive ஐ இயக்கவும்",
  Automatically_Backup:"தானாக காப்புப்பிரதி எடுக்கவும்",
  FTP_Settings:"FTP அமைப்புகள்",
  URL_File_Prompt:"இறக்குமதி செய்வதற்கான .xls / .xlsx / .json கோப்பின் இருப்பிடத்தைக் குறிப்பிடவும்:",
  URL_SQL_Prompt:"இறக்குமதி செய்ய SQLite கோப்பின் இருப்பிடத்தைக் குறிப்பிடவும்:",
  FTP_Backup:"FTP காப்புப்பிரதி",
  FTP_Import:"FTP இறக்குமதி",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"தொகுப்பாளர்",
  Port:"துறைமுகம்",
  Path:"பாதை",
  Data_Path:"தரவு பாதை",
  Enable_FTP_Account:"FTP கணக்கை இயக்கவும்",
  HostnameIP:"புரவலன்-பெயர்",
  Password:"கடவுச்சொல்",
  Connection_Port:"இணைப்பு துறைமுகம்",
  Enable_MySQL_Database:"MySQL தரவுத்தளத்தை இயக்கவும்",
  Log:"பதிவு",
  Reset:"மீட்டமை",
  Erase:"அழிக்கவும்",
  Export:"ஏற்றுமதி",
  Database:"தரவுத்தளம்",
  Upload_CSV:"CSV ஐப் பதிவேற்றவும்",
  Download_CSV:"CSV ஐப் பதிவிறக்கவும்",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL காப்புப்பிரதி",
  Internal_Notes:"உள் குறிப்புகள்",
  Root_Path:"ரூட் பாதை",
  Select_Backup:"காப்புப்பிரதியைத் தேர்ந்தெடுக்கவும்",
  Add_New_Backup:"புதிய காப்புப்பிரதியைச் சேர்க்கவும்",
  First_Backup:"முதல் காப்புப்பிரதியை அமைக்கவும்...",
  Backups:"காப்புப்பிரதிகள்",
  Add_Backup:"காப்புப்பிரதியைச் சேர்க்கவும்",
  No_Backups:"காப்புப்பிரதிகள் எதுவும் இல்லை.",
  Select_Backup_Type:"நீங்கள் அமைக்க விரும்பும் காப்புப் பிரதி வகையைத் தேர்ந்தெடுக்கவும்...",
  Backup_Type:"காப்பு வகை",
  FTP_Drive:"FTP இயக்ககம்",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"ஓட்டு",
  Microsoft_OneDrive:"ஓட்டு",
  Import_Fields:"இறக்குமதி புலங்கள்",
  Import_Fields_Word:"<a routerLink='/folder/Holiday/New'>இறக்குமதி</a> தரவைத் தேர்ந்தெடுக்க இந்தப் பிரிவைப் பயன்படுத்தவும்.",
  Upload:"பதிவேற்றவும்",
  Download:"பதிவிறக்க Tamil",
  Download_JSON:"JSON டேட்டாவாகப் பதிவிறக்கவும்",
  Download_SQL:"SQL கோப்பாக பதிவிறக்கவும்",
  New_Bank:"புதிய வங்கி",
  New_Account:"புதிய கணக்கு",
  New_Bank_Account:"புதிய வங்கி கணக்கு",
  Upload_Image:"படத்தை பதிவேற்றம் செய்யவும்",
  Bank_Name:"வங்கி பெயர்",
  Bank_Address:"வங்கி முகவரி",
  Branch_Address:"கிளை விலாசம்",
  Address_on_Cheque:"முகவரி",
  Cheque_Numbers:"எண்களைச் சரிபார்க்கவும்",
  Cheque_Details:"விவரங்களைச் சரிபார்க்கவும்",
  Bank_Logo:"வங்கி லோகோ",
  Cheque_ID:"ஐடியை சரிபார்க்கவும்",
  Starting_Cheque_ID:"சரிபார்ப்பு ஐடியைத் தொடங்குகிறது",
  Transit_Number:"போக்குவரத்து எண்",
  Institution_Number:"நிறுவன எண்",
  Designation_Number:"பதவி எண்",
  Account_Number:"கணக்கு எண்",
  Limits:"வரம்புகள்",
  Default_Limits:"இயல்பு வரம்புகள்",
  Over_Limit:"வரம்புக்கு மேல்",
  Under_Limit:"வரம்பின் கீழ்",
  Payroll_Limit:"ஊதிய வரம்பு",
  Enable_Bank_Account:"வங்கி கணக்கை இயக்கவும்",
  Select_Account:"கணக்கைத் தேர்ந்தெடுக்கவும்",
  No_Bank_Account:"வங்கி கணக்கு இல்லை",
  No_Bank_Account_Word:"முதல் <a routerLink='/folder/Accounts/New'>வங்கி கணக்கைச்</a> சேர்க்கவும்.",
  Bank_Accounts:"வங்கி கணக்குகள்",
  No_Accounts:"கணக்குகள் இல்லை",
  No_Accounts_Note:"முதல் <a routerLink='/folder/Accounts/New'>வங்கி கணக்கைச்</a> சேர்க்கவும்.",
  Cheque_Designer:"வடிவமைப்பாளரைச் சரிபார்க்கவும்",
  Cheque_Design:"வடிவமைப்பைச் சரிபார்க்கவும்",
  Select_Design:"வடிவமைப்பைத் தேர்ந்தெடுக்கவும்",
  Cheque_Designs:"வடிவமைப்புகளை சரிபார்க்கவும்",
  No_Cheque_Designs:"டிசைன்களை சரிபார்க்கவும் இல்லை",
  No_Cheque_Designs_Word:"உங்கள் சொந்த <a routerLink='/folder/Settings/Cheque/Design/New'>காசோலை வடிவமைப்பு</a> உருவாக்கவும்.",
  Set_Default:"இயல்புநிலைக்கு அமை",
  Default:"இயல்புநிலை",
  Remove:"அகற்று",
  Folder:"கோப்புறை",
  Edit:"தொகு",
  LoadingDots:"ஏற்றுகிறது...",
  Add_a_New_File:"ஒரு <a href='#' (click)='add()'>புதிய கோப்பை</a> சேர்க்கவும்",
  this_folder:"இந்த கோப்புறை",
  FTP_Backup_Settings:"FTP காப்பு அமைப்புகள்",
  Secure_File_Transfer:"பாதுகாப்பான கோப்பு பரிமாற்றம்",
  New_Holiday:"புதிய விடுமுறை",
  Add_Holiday:"விடுமுறையைச் சேர்க்கவும்",
  Holiday_Name:"விடுமுறை பெயர்",
  Additional_Pay:"கூடுதல் ஊதியம்",
  Enable_Holiday:"விடுமுறையை இயக்கு",
  Select_Holidays:"விடுமுறை நாட்களைத் தேர்ந்தெடுக்கவும்",
  No_Holidays:"விடுமுறைகள் இல்லை",
  No_Holidays_Word:"முதல் <a routerLink='/folder/Holiday/New'>பொது விடுமுறையை</a> சேர்க்கவும்.",
  New_Import:"புதிய இறக்குமதி",
  Import_Data:"தரவு இறக்குமதி",
  Import_Data_Word:"நீங்கள் தேர்ந்தெடுக்கும் கோப்பு வகை அல்லது பதிவேற்ற முறையைத் தேர்ந்தெடுக்கவும்.<br /> ஆதரிக்கப்படும் கோப்பைப் பதிவேற்றிய பிறகு, பயன்பாட்டில் உள்ள எந்த அளவுருவுடன் தொடர்புடைய கோப்பில் இறக்குமதி செய்யப்பட்ட புலங்களை நீங்கள் தேர்ந்தெடுக்க முடியும்.",
  Import_File:"கோப்பை இறக்குமதி செய்யவும்",
  Link_To_File:"கோப்பிற்கான இணைப்பு",
  Select_File:"கோப்பைத் தேர்ந்தெடுக்கவும்",
  New_Moderator:"புதிய மதிப்பீட்டாளர்",
  Allow_Moderation:"நிதானத்தை அனுமதிக்கவும்",
  Create_Paycheques:"காசோலைகளை உருவாக்கவும்",
  Edit_Paycheques_and_Data:"காசோலைகள் மற்றும் தரவைத் திருத்தவும்",
  Destroy_Data_and_Paycheques:"தரவு மற்றும் காசோலைகளை அழிக்கவும்",
  Bonus_Percentage:"சம்பள காசோலை சதவீதம்",
  Fixed_Amount:"நிர்ணயிக்கப்பட்ட தொகை",
  Select_Moderator:"மதிப்பீட்டாளரைத் தேர்ந்தெடுக்கவும்",
  No_Moderators:"மதிப்பீட்டாளர்கள் இல்லை",
  Moderators:"மதிப்பீட்டாளர்கள்",
  Moderator_Account:"முதல் <a routerLink='/folder/Administrator/New'>மதிப்பீட்டாளர் கணக்கை</a> உருவாக்கவும்.",
  No_Moderators_Word:"முதல் <a routerLink='/folder/Administrator/New'>மதிப்பீட்டாளர்</a> ஐச் சேர்க்கவும்.",
  Defaults:"இயல்புநிலைகள்",
  Provide_Us_Info:"தகவலை வழங்கவும்",
  Setup_Your_Printer:"உங்கள் அச்சுப்பொறியை அமைக்கவும்",
  Your_Company:"உங்கள் நிறுவனம் பற்றி",
  Company_Name:"நிறுவனத்தின் பெயர்",
  Currency:"நாணய",
  Default_Currency:"இயல்புநிலை நாணயம்",
  Base_Monthly_Income:"மாத வருமானம்",
  Protection:"பாதுகாப்பு",
  App_Protection:"பயன்பாட்டு பாதுகாப்பு",
  PIN_Code_Protection:"பின் குறியீடு பாதுகாப்பு",
  App_Protection_Word:"உங்கள் கணக்கைப் பாதுகாக்க உதவும் பாதுகாப்பு முறைகளை இயக்கவும்.",
  PIN_Code:"அஞ்சல் குறியீடு",
  Change_PIN:"பின்னை மாற்றவும்",
  New_Password:"புதிய கடவுச்சொல்",
  Geofence_Protection:"புவி வேலி பாதுகாப்பு",
  Geofence_Area:"பகுதியை அமைக்கவும்",
  Distance:"தூரம்",
  Setup_Now:"இப்போது அமைக்கவும்",
  Mile:"மைல்",
  Km:"கி.மீ",
  m:"மீ",
  Facial_Recognition:"முக அங்கீகாரம்",
  Face:"முகம்",
  Setup:"அமைவு",
  Label:"லேபிள்",
  Password_Protection:"கடவுச்சொல் பாதுகாப்பு",
  Modify_Password:"கடவுச்சொல்லை மாற்றவும்",
  New_Tax_Entry:"புதிய வரி நுழைவு",
  New_Tax:"புதிய வரி",
  Tax_Label:"வரி லேபிள்",
  Perpetually_Enabled:"நிரந்தரமாக இயக்கப்பட்டது",
  Select_Taxes:"வரிகளைத் தேர்ந்தெடுக்கவும்",
  Tax_Deductions:"வரி விலக்குகள்",
  No_Tax_Deductions:"வரி விலக்குகள் இல்லை",
  No_Tax_Deductions_Word:"முதல் <a routerLink='/folder/Tax/New'>வரி</a> விலக்கைச் சேர்க்கவும்.",
  New_Timer:"புதிய டைமர்",
  Start:"தொடங்கு",
  Stop:"நிறுத்து",
  Start_Timer:"டைமரைத் தொடங்கவும்",
  Stop_Timer:"டைமரை நிறுத்து",
  Timer_Active:"டைமர் செயலில் உள்ளது",
  Timer:"டைமர்:",
  Timer_Running:"டைமர்: (இயங்கும்)",
  Save_Timer:"டைமரை சேமிக்கவும்",
  New_Timesheet:"புதிய நேர தாள்",
  Select_Timesheets:"நேரத் தாள்களைத் தேர்ந்தெடுக்கவும்",
  Work_Notes:"வேலை குறிப்புகள்",
  Entry_Title:"நுழைவு தலைப்பு",
  No_Timesheets:"நேரத் தாள்கள் இல்லை",
  No_Timesheets_Word:"முதல் <a routerLink='/folder/Timesheet/New'>நேர தாள்</a>ஐச் சேர்க்கவும்.",
  Timesheet_Submitted:"நேர தாள் சமர்ப்பிக்கப்பட்டது",
  Timesheet_Congrats:"வாழ்த்துகள்! உங்கள் நேரத் தாள் வெற்றிகரமாகச் சமர்ப்பிக்கப்பட்டது. நன்றி!",
  Timesheet_Copy:"உங்கள் ஆவணங்களின் நகலைப் பெற, உங்கள் மின்னஞ்சல் முகவரி மற்றும்/அல்லது மொபைல் ஃபோன் எண்ணை எங்களுக்கு வழங்கவும்.",
  Submit:"சமர்ப்பிக்கவும்",
  Allow_Notifications:"அறிவிப்புகளை அனுமதிக்கவும்",
  Untitled_Entry:"புதிய நுழைவு",
  Untitled_Bank:"பெயரிடப்படாத வங்கி",
  Timesheet_Entry:"நேர தாள் உள்ளீடு",
  Work_Description:"வேலை விளக்கம்",
  Enable_Timesheet:"நேர தாளை இயக்கவும்",
  Submit_Timesheet:"நேர தாளை சமர்ப்பிக்கவும்",
  Vacation:"விடுமுறை",
  New_Vacation:"புதிய விடுமுறை",
  Vacation_Name:"விடுமுறை பெயர்",
  Paid_Vacation:"ஊதிய விடுமுறை",
  Vacation_Pay:"விடுமுறை ஊதியம்",
  Enable_Vacation:"விடுமுறையை இயக்கு",
  Select_Vacations:"விடுமுறைகளைத் தேர்ந்தெடுக்கவும்",
  No_Vacations:"விடுமுறைகள் இல்லை",
  No_Vacations_Word:"முதல் <a routerLink='/folder/Vacation/New'>Vacation</a> உள்ளீட்டை உருவாக்கவும்.",
  Payroll_Schedule:"ஊதிய அட்டவணை",
  Next_Payroll:"அடுத்த ஊதியம்:",
  Upcoming_Payroll:"வரவிருக்கும் ஊதியம்",
  No_Upcoming_Payroll:"வரவிருக்கும் ஊதியம் இல்லை",
  Address_Book:"முகவரி புத்தகம்",
  Archived_Documents:"காப்பகப்படுத்தப்பட்ட ஆவணங்கள்",
  Dev_Mode:"வளர்ச்சி பயன்முறையில் பயன்பாடு",
  Administrators:"நிர்வாகிகள்",
  Privacy:"தனியுரிமை",
  None:"இல்லை",
  Select_Signature:"கையொப்பத்தைத் தேர்ந்தெடுக்கவும்",
  No_Signatures:"கையெழுத்து இல்லை",
  No_Signatures_Word:"முதல் <a routerLink='/folder/Signature/New'>கையொப்பத்தைச்</a> சேர்க்கவும்.",
  Repeat_Indefinitely:"காலவரையின்றி மீண்டும் செய்யவும்",
  Sign:"கையெழுத்து",
  Sign_Here:"இங்கே கையப்பம் இடவும்",
  Date_Signed:"கையொப்பமிட்ட தேதி",
  Signature_Pad:"சிக்னேச்சர் பேட்",
  Account_Holder:"கணக்கு வைத்திருப்பவர்",
  Account_Properties:"கணக்கு பண்புகள்",
  Name_On_Cheque:"காசோலையில் பெயர்",
  Server_Hostname:"சர்வர் ஹோஸ்ட்-பெயர் / ஐபி",
  Printers:"பிரிண்டர்கள்",
  No_Printers:"அச்சுப்பொறிகள் இல்லை",
  Printer_Exists:"இந்தப் பெயரில் ஒரு பிரிண்டர் ஏற்கனவே உள்ளது.",
  No_Printers_Word:"முதல் <a routerLink='/folder/Printer/New'>அச்சுப்பொறி</a> ஐச் சேர்க்கவும்.",
  No_Printer_Alert:"எந்த அச்சுப்பொறியும் வரையறுக்கப்படவில்லை. பிரிண்டரை அமைக்க விரும்புகிறீர்களா?",
  Add_Printer:"அச்சுப்பொறியைச் சேர்க்கவும்",
  New_Printer:"புதிய பிரிண்டர்",
  Printer_Hostname:"பிரிண்டர் ஹோஸ்ட்-பெயர் / ஐபி",
  Printer_Label:"அச்சுப்பொறி லேபிள்",
  Printer_Protocol:"அச்சுப்பொறி நெறிமுறை",
  Protocol:"நெறிமுறை",
  Email_Print:"மின்னஞ்சல்",
  AirPrint:"ஏர்பிரிண்ட்",
  IPP:"IPP",
  LPD:"எல்பிடி",
  HPJetDirect:"சாக்கெட்",
  Print_Job:"அச்சு வேலை",
  Printed:"அச்சிடப்பட்டது",
  Not_Printed:"அச்சிடப்படவில்லை",
  Print_Jobs:"அச்சு வேலைகள்",
  Print_Queue:"அச்சு வரிசை",
  No_Print_Jobs:"அச்சு வேலைகள் இல்லை",
  No_Prints:"அச்சிடுவதற்கு புதிய <a routerLink='/folder/Cheque/New'>காசோலையை</a> உருவாக்கவும்.",
  Prints:"அச்சிடுகிறது",
  Find_Printer:"அச்சுப்பொறியைக் கண்டுபிடி",
  Find_AirPrint_Devices:"ஏர்பிரிண்ட் சாதனங்களைக் கண்டறியவும்",
  Select_Printer:"அச்சுப்பொறியைத் தேர்ந்தெடுக்கவும்",
  System_UI:"கணினி UI",
  Printer:"பிரிண்டர்",
  Status:"நிலை",
  Preview:"முன்னோட்ட",
  Enable_Print_Job:"அச்சு வேலையை இயக்கு",
  Queue_Weight:"வரிசை எடை",
  Unlimited:"வரம்பற்ற",
  Show_Advanced_Printer_Options:"மேம்பட்ட அச்சுப்பொறி விருப்பங்களைக் காட்டு",
  Advanced:"மேம்படுத்தபட்ட",
  Location:"இடம்",
  Note:"குறிப்பு",
  Queue_Name:"வரிசை பெயர்",
  Pages_Printed_Limit:"பக்கங்கள் அச்சிடப்பட்ட வரம்பு",
  MultiPage_Idle_Time:"பல பக்க காத்திருப்பு நேரம் (கள்)",
  Page_Count_Limit:"பக்க எண்ணிக்கை வரம்பு",
  Page_Orientation:"பக்க நோக்குநிலை",
  Portrait:"உருவப்படம்",
  Landscape:"நிலப்பரப்பு",
  Duplex:"இரட்டை",
  Double_Sided_Printing:"இரட்டை பக்க",
  Duplex_Mode:"இரட்டை முறை",
  Duplex_Short:"குறுகிய",
  Duplex_Long:"நீளமானது",
  Duplex_None:"இல்லை",
  Color_And_Quality:"நிறம் மற்றும் தரம்",
  Monochrome:"ஒரே வண்ணமுடையது",
  Photo_Quality_Prints:"புகைப்படத் தர அச்சிட்டு",
  Printer_Email:"அச்சுப்பொறி மின்னஞ்சல்",
  Automatically_Downsize:"தானாக குறைக்கவும்",
  Paper:"காகிதம்",
  Paper_Name:"காகித பெயர்",
  Paper_Width:"காகித அகலம்",
  Paper_Height:"காகித உயரம்",
  Paper_Autocut_Length:"காகித தானாக வெட்டு நீளம்",
  Margins:"விளிம்புகள்",
  Page_Margins:"பக்க விளிம்புகள்",
  Page_Margin_Top:"மேல் பக்க விளிம்பு",
  Page_Margin_Right:"வலது பக்க விளிம்பு",
  Page_Margin_Bottom:"கீழ் பக்க விளிம்பு",
  Page_Margin_Left:"இடது பக்க விளிம்பு",
  Add_Employees:"பணியாளர்களைச் சேர்க்கவும்",
  Header:"தலைப்பு",
  Print_A_Page_Header:"ஒரு பக்க தலைப்பை அச்சிடுங்கள்",
  Header_Label:"தலைப்பு லேபிள்",
  Header_Page_Index:"தலைப்பு பக்க அட்டவணை",
  Header_Font_Name:"தலைப்பு எழுத்துரு",
  Select_Font:"எழுத்துருவைத் தேர்ந்தெடுக்கவும்",
  Font_Selector:"எழுத்துரு தேர்வி",
  Header_Font_Size:"தலைப்பு எழுத்துரு",
  Header_Bold:"தடிமனான தலைப்பு",
  Header_Italic:"தலைப்பு சாய்வு",
  Header_Alignment:"தலைப்பு சீரமைப்பு",
  Left:"விட்டு",
  Center:"மையம்",
  Right:"சரி",
  Justified:"நியாயப்படுத்தப்பட்டது",
  Header_Font_Color:"தலைப்பு நிறம்",
  Select_Color:"வண்ணத்தைத் தேர்ந்தெடுக்கவும்",
  Footer:"அடிக்குறிப்பு",
  Print_A_Page_Footer:"ஒரு பக்க அடிக்குறிப்பை அச்சிடுக",
  Footer_Label:"அடிக்குறிப்பு லேபிள்",
  Footer_Page_Index:"அடிக்குறிப்பு பக்க அட்டவணை",
  Footer_Font_Name:"அடிக்குறிப்பு எழுத்துரு",
  Fonts:"எழுத்துருக்கள்",
  Done:"முடிந்தது",
  Select_Fonts:"எழுத்துருக்களைத் தேர்ந்தெடுக்கவும்",
  Footer_Font_Size:"அடிக்குறிப்பு அளவு",
  Footer_Bold:"அடிக்குறிப்பு தடித்த",
  Footer_Italic:"சாய்வு அடிக்குறிப்பு",
  Footer_Alignment:"அடிக்குறிப்பு சீரமைப்பு",
  Footer_Font_Color:"அடிக்குறிப்பு நிறம்",
  Page_Copies:"பக்க பிரதிகள்",
  Enable_Printer:"அச்சுப்பொறியை இயக்கு",
  Remote_Logging:"ரிமோட் லாக்கிங்",
  Log_Server:"பதிவு சேவையகம்",
  Encryption:"குறியாக்கம்",
  Random_Key:"ரேண்டம் கீ",
  Encryption_Key:"குறியாக்க விசை",
  Cheques_Webserver:"தனிப்பயன் தரவுத்தளம்",
  Learn_How:"எப்படி என்பதை அறிக",
  Signature:"கையெழுத்து",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"கையொப்பத்தைப் பார்க்கவும்",
  Printed_Signature:"அச்சிடப்பட்ட கையொப்பம்",
  Digitally_Sign:"டிஜிட்டல் கையொப்பம்",
  Digital_Signature:"டிஜிட்டல் கையொப்பம்",
  Add_Signature:"கையொப்பத்தைச் சேர்க்கவும்",
  Add_Your_Signature:"உங்கள் கையொப்பத்தைச் சேர்க்கவும்",
  Enable_Signature:"கையொப்பத்தை இயக்கு",
  Digitally_Signed:"டிஜிட்டல் கையொப்பமிடப்பட்டது",
  Insert_Error:"தரவுத்தளச் சிக்கல்களால் காசோலையைச் சேமிக்க முடியவில்லை.",
  Delete_Confirmation:"இந்தத் தகவலை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  Discard_Confirmation:"இந்தத் தகவலை நிச்சயமாக நிராகரிக்க விரும்புகிறீர்களா?",
  Discard_Bank_Confirmation:"இந்தக் கணக்கை நிச்சயமாக நிராகரிக்க விரும்புகிறீர்களா?",
  Discard_Printer_Confirmation:"இந்த அச்சுப்பொறியை நிச்சயமாக நிராகரிக்க விரும்புகிறீர்களா?",
  Delete_Bonus_Confirmation:"இந்த போனஸை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  Delete_Invoice_Confirmation:"இந்த இன்வாய்ஸை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  Generated_Cheque:"உருவாக்கப்பட்ட காசோலை",
  Generated_Invoice:"உருவாக்கப்பட்ட விலைப்பட்டியல்",
  Schedule_Start_Time:"அட்டவணை தொடக்கம்",
  Schedule_End_Time:"அட்டவணை முடிவு",
  New_Call:"புதிய அழைப்பு",
  No_Contacts:"தொடர்புகள் இல்லை",
  No_Contacts_Word:"நிர்வாகிகள், மதிப்பீட்டாளர்கள், பணியாளர்கள் மற்றும் பணம் பெறுபவர்கள் தொடர்புகளாகத் தோன்றுவார்கள்.",
  PDF_Subject:"நிதி",
  PDF_Keywords:"ஊதியம் செலுத்தும் காசோலை PDF காசோலைகள்",
  Printer_Setup:"அச்சுப்பொறி அமைப்பு",
  Printer_Selection:"அச்சுப்பொறி தேர்வு",
  New_Fax:"புதிய தொலைநகல்",
  New_Fax_Message:"புதிய தொலைநகல் செய்தி",
  Fax_Machine:"தொலைநகல் இயந்திரம்",
  Fax_Name:"தொலைநகல் பெயர்",
  Fax_Email:"தொலைநகல் மின்னஞ்சல்",
  Fax_Number:"தொலைநகல் எண்",
  Contents:"உள்ளடக்கம்",
  Fax_Body:"பக்க உடல்",
  Header_Word:"தலைப்பு:",
  Header_Text:"தலைப்பு உரை",
  Include_Header:"தலைப்பைச் சேர்க்கவும்",
  Include_Footer:"அடிக்குறிப்பைச் சேர்க்கவும்",
  Footer_Word:"அடிக்குறிப்பு:",
  Footer_Text:"அடிக்குறிப்பு உரை",
  Attach_a_Cheque:"ஒரு காசோலையை இணைக்கவும்",
  Add_Deduction:"விலக்கு சேர்க்கவும்",
  Enable_Fax:"தொலைநகல் அனுப்பவும்",
  Select_Fax:"தொலைநகல் தேர்ந்தெடுக்கவும்",
  No_Faxes:"தொலைநகல் இல்லை",
  Faxes:"தொலைநகல்கள்",
  Save_and_Send:"தொலைநகல் அனுப்பவும்",
  Save_and_Pay:"சேமித்து பணம் செலுத்துங்கள்",
  WARNING:"எச்சரிக்கை:",
  Remember:"நினைவில் கொள்ளுங்கள்",
  Printing:"அச்சிடுதல்",
  Printing_Complete:"அச்சிடுதல் முடிந்தது!\n\n",
  of:"இன்",
  There_Were:"அங்கு",
  Successful_Prints:"வெற்றிகரமான அச்சிட்டு மற்றும்",
  Unsuccessful_Prints:"தோல்வியுற்ற அச்சிட்டுகள்.",
  PrinterError:"மன்னிக்கவும்! பிழை ஏற்பட்டது.",
  Printing_:"அச்சிடுகிறது...",
  PrinterSuccess:"ஆவணம் அச்சிடப்பட்டது.",
  PrintersSuccess:"ஆவணங்கள் வெற்றிகரமாக அச்சிடப்பட்டன.",
  New_Message:"புதிய தகவல்",
  New_Messages:"புதிய செய்திகள்",
  Read_Message:"செய்தியைப் படியுங்கள்",
  Write_Message:"செய்தியை எழுதுங்கள்",
  Send_Message:"செய்தி அனுப்ப",
  Subject:"பொருள்",
  Message:"செய்தி",
  Writing:"எழுதுவது...",
  Send:"அனுப்பு",
  Set_Date:"தேதியை அமைக்கவும்",
  Set_Time:"நேரத்தை அமைக்கவும்",
  Recieve:"பெறு",
  Set_as_Default:"இயல்புநிலைக்கு அமை",
  Default_Account:"இயல்புநிலை கணக்கு",
  Default_Design:"இயல்புநிலை வடிவமைப்பு",
  Multiple_Cheques:"மூன்று காசோலைகள்",
  Account_Mode:"கணக்கு முறை",
  Multiple_Cheques_Description:"ஒரு பக்கத்திற்கு மூன்று காசோலைகள்.",
  Check_and_Table:"சரிபார்க்கவும் மற்றும் அட்டவணை",
  Check_including_Table:"கணக்கியல் அட்டவணையுடன் சரிபார்க்கவும்.",
  Check_Mailer:"அஞ்சலை சரிபார்க்கவும்",
  Check_Mailer_Window:"முகவரி சாளரத்தில் சரிபார்க்கவும்.",
  DocuGard_Table_Top:"பெரிய காசோலை & அட்டவணை",
  DocuGard_Table_Middle:"பெரிய சோதனை மற்றும் அட்டவணை (நடுத்தர)",
  DocuGard_Table_Bottom:"பெரிய சோதனை மற்றும் அட்டவணை (கீழே)",
  DocuGard_Mailer_Top:"பெரிய காசோலை அஞ்சல்",
  DocuGard_Mailer_Middle:"பெரிய செக் மெயிலர் (நடுத்தர)",
  DocuGard_Mailer_Bottom:"பெரிய காசோலை அஞ்சல் (கீழே)",
  DocuGard_Three_Cheques:"மூன்று பெரிய காசோலைகள்",
  DocuGard_Cheque_Top:"ஒற்றை பெரிய காசோலை",
  DocuGard_Cheque_Middle:"பெரிய காசோலை (நடுத்தர)",
  DocuGard_Cheque_Bottom:"பெரிய காசோலை (கீழே)",
  DocuGard_Three_Cheques_Window:"ஒரு பக்கத்தில் மூன்று காசோலைகள்.",
  DocuGard_Table_Top_Window:"சரிபார்ப்பு மற்றும் வருவாய் அட்டவணை.",
  DocuGard_Table_Middle_Window:"சரிபார்ப்பு மற்றும் வருவாய் அட்டவணை.",
  DocuGard_Table_Bottom_Window:"சரிபார்ப்பு மற்றும் வருவாய் அட்டவணை.",
  DocuGard_Mailer_Top_Window:"காசோலை, அட்டவணை மற்றும் முகவரி.",
  DocuGard_Mailer_Middle_Window:"காசோலை, அட்டவணை மற்றும் முகவரி.",
  DocuGard_Mailer_Bottom_Window:"காசோலை, அட்டவணை மற்றும் முகவரி.",
  DocuGard_Cheque_Top_Window:"பாதுகாப்பான காகிதத்தை சரிபார்க்கவும்.",
  DocuGard_Cheque_Middle_Window:"பாதுகாப்பான காகிதத்தை சரிபார்க்கவும்.",
  DocuGard_Cheque_Bottom_Window:"பாதுகாப்பான காகிதத்தை சரிபார்க்கவும்.",
  Basic_Cheque:"ஒற்றை சரிபார்ப்பு",
  Basic_Cheque_Print:"ஒரு காசோலையை அச்சிடுங்கள்.",
  Error_Setting_As_Paid:"பணம் செலுத்தியதாக அமைப்பதில் பிழை",
  Add_Attachment:"இணைப்பை சேர்க்கவும்",
  PrinterUnavailable:"அச்சுப்பொறி கிடைக்கவில்லை",
  CreditCardComponent:"அட்டைகள்",
  PaypalComponent:"பேபால்",
  InteracComponent:"இண்டராக்",
  BitcoinComponent:"பிட்காயின்",
  New_Deposit:"புதிய வைப்பு",
  Deposits:"வைப்புத்தொகை",
  No_Deposits:"வைப்புத்தொகை இல்லை",
  Credit_Card_Deposit:"கடன் அட்டை",
  New_Credit_Card_Deposit_Message:"கிரெடிட் கார்டு வைப்பு",
  New_BitCoin_Deposit:"பிட்காயின்",
  New_BitCoin_Deposit_Message:"BitCoin வைப்பு",
  New_Interac_Deposit:"இண்டராக்",
  New_Interac_Deposit_Message:"இண்டராக் பரிமாற்றம்",
  Payments_Limit:"கட்டண வரம்பு",
  No_Payment_Limit:"கட்டண வரம்பு இல்லை",
  PayPal_Deposit:"பேபால்",
  PayPal_Deposit_Message:"பேபால் வைப்பு",
  No_Deposits_Word:"முதல் நிறுவனத்தைச் சேர்க்கவும் <a routerLink='/folder/Deposit/New'>டெபாசிட்</a>.",
  No_Documents_Specified:"அச்சிடுவதற்கு ஆவணங்கள் எதுவும் குறிப்பிடப்படவில்லை",
  No_Printers_Added:"பிரிண்டர்கள் எதுவும் கிடைக்கவில்லை. ஒன்றைச் சேர்க்க, அமைப்புகள் > பிரிண்டர்கள் என்பதற்குச் செல்லவும்.",
  DB_Erase_Prompt:"முழு தரவுத்தளத்தையும் முழுமையாக அழிக்கவா? எச்சரிக்கை: சேமிக்கப்பட்ட அனைத்து தகவல்களையும் இழப்பீர்கள்!",
  Console_Warning:"இந்த கன்சோலில் எந்த உரையையும் ஒட்ட வேண்டாம். உங்களை மற்றும்/அல்லது உங்கள் நிறுவனத்தை நீங்கள் கடுமையான ஆபத்தில் ஆழ்த்தலாம்.",
  No_Faxes_Word:"முதல் <a routerLink='/folder/Fax/New'>Fax</a> ஐ உருவாக்கவும்.",
  Cheque_Delete_Confirmation:"இந்த காசோலையை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  Design_Delete_Confirmation:"இந்த வடிவமைப்பை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  Cheque_Pay_Confirmation:"இந்த காசோலை பணம் செலுத்தியதாகக் குறிக்கவா? இது அச்சு வரிசையில் தோன்றாது.",
  Payment_Pay_Confirmation:"இந்தக் கட்டணத்தை செலுத்தியதாகக் குறிக்கவா? இது காசோலை வரிசையில் தோன்றாது.",
  Delete_Deduction_Confirmation:"இந்த விலக்கை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  Delete_Job_Confirmation:"இந்த வேலையை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  Delete_Timesheet_Confirmation:"இந்த டைம்ஷீட்டை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  Delete_Schedule_Confirmation:"இந்த அட்டவணையை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  Delete_Setting_Confirmation:"இந்த அமைப்பை மீட்டமைக்க விரும்புகிறீர்களா?",
  Delete_Fax_Confirmation:"இந்த தொலைநகல் நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  Delete_File_Confirmation:"இந்தக் கோப்பை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  Delete_Vacation_Confirmation:"இந்த விடுமுறையை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  Delete_Printer_Confirmation:"இந்த அச்சுப்பொறியை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  Remove_Design_Confirmation:"இந்த வடிவமைப்பை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  Delete_Payroll_Confirmation:"இந்த ஊதியத்தை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
  Send_Fax_Email_Confirmation:"இந்த ஆவணங்களை தொலைநகல் செய்து மின்னஞ்சல் செய்ய விரும்புகிறீர்களா?",
  Send_Email_Confirmation:"இந்த ஆவணத்தை மின்னஞ்சல் செய்ய வேண்டுமா?",
  Send_Fax_Confirmation:"இந்த ஆவணத்தை தொலைநகல் செய்ய விரும்புகிறீர்களா?",
  Error_Generating_PDF:"மன்னிக்கவும். இந்த ஆவணத்தை உருவாக்குவதில் பிழை.",
  PDF_Error_Saving_Image:"மன்னிக்கவும். இந்த ஆவணத்தின் PDF படத்தை சேமிப்பதில் பிழை.",
  Test_Printer_Confirmation:"இந்த பிரிண்டரில் சோதனைப் பக்கத்தை அச்சிட விரும்புகிறீர்களா?",
  Save_Timesheet_Prompt:"சேமிக்க, 'தலைப்பை' சேர்க்கவும் அல்லது 'ஸ்டார்ட் டைமரை' அழுத்தவும்.",
  Remove_Geofence_Prompt:"இந்த புவி வேலியின் இருப்பிடத்தை நிச்சயமாக அகற்ற விரும்புகிறீர்களா?",
  Delete_Employee_Confirmation:"இதை நீக்க வேண்டும் என்பதில் உறுதியாக இருக்கிறீர்களா",
  Fire_Employee_Confirmation:"நீங்கள் நிச்சயமாக சுட விரும்புகிறீர்களா?"
}