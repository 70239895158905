export const Prices = {
	"Afghanistan": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Albania": {
		"currency":"USD",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.39",
		"chequeslicence_3mo":"17.99",
		"chequeslicence_6mo":"34.19",
		"chequeslicence_12mo":"62.39",
		"cheques_print_1":"1.19",
		"cheques_print_2":"2.49",
		"cheques_print_5":"5.99",
		"cheques_print_10":"11.99",
		"cheques_print_15":"17.99",
		"cheques_print_20":"23.99",
		"cheques_print_30":"35.99",
		"cheques_znvr9f":"84.99",
		"cheques_mailed_black_white":"3.49",
		"cheques_znvr9f_color":"6.99"
	},
	"Algeria": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Angola": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Anguilla": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Antigua and Barbuda": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Argentina": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Armenia": {
		"currency":"USD",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"17.99",
		"chequeslicence_6mo":"33.99",
		"chequeslicence_12mo":"62.99",
		"cheques_print_1":"1.19",
		"cheques_print_2":"2.49",
		"cheques_print_5":"5.99",
		"cheques_print_10":"11.99",
		"cheques_print_15":"17.99",
		"cheques_print_20":"23.99",
		"cheques_print_30":"35.99",
		"cheques_znvr9f":"84.99",
		"cheques_mailed_black_white":"3.49",
		"cheques_znvr9f_color":"6.99"
	},
	"Australia": {
		"currency":"AUD",
		"chequeslicence":"7.99",
		"chequeslicence_2mo":"15.49",
		"chequeslicence_3mo":"23.99",
		"chequeslicence_6mo":"44.99",
		"chequeslicence_12mo":"83.99",
		"cheques_print_1":"1.49",
		"cheques_print_2":"2.99",
		"cheques_print_5":"7.99",
		"cheques_print_10":"14.99",
		"cheques_print_15":"22.99",
		"cheques_print_20":"30.99",
		"cheques_print_30":"46.99",
		"cheques_znvr9f":"109.99",
		"cheques_mailed_black_white":"4.49",
		"cheques_znvr9f_color":"9.99"
	},
	"Austria": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"17.99",
		"chequeslicence_6mo":"33.99",
		"chequeslicence_12mo":"62.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Azerbaijan": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Bahamas": {
		"currency":"USD",
		"chequeslicence":"5.49",
		"chequeslicence_2mo":"10.49",
		"chequeslicence_3mo":"16.49",
		"chequeslicence_6mo":"30.99",
		"chequeslicence_12mo":"57.99",
		"cheques_print_1":"1.09",
		"cheques_print_2":"2.29",
		"cheques_print_5":"5.49",
		"cheques_print_10":"10.99",
		"cheques_print_15":"16.99",
		"cheques_print_20":"21.99",
		"cheques_print_30":"32.99",
		"cheques_znvr9f":"74.99",
		"cheques_mailed_black_white":"3.49",
		"cheques_znvr9f_color":"6.99"
	},
	"Bahrain": {
		"currency":"USD",
		"chequeslicence":"5.49",
		"chequeslicence_2mo":"10.49",
		"chequeslicence_3mo":"16.49",
		"chequeslicence_6mo":"30.99",
		"chequeslicence_12mo":"57.99",
		"cheques_print_1":"1.09",
		"cheques_print_2":"2.29",
		"cheques_print_5":"5.49",
		"cheques_print_10":"10.99",
		"cheques_print_15":"16.99",
		"cheques_print_20":"21.99",
		"cheques_print_30":"32.99",
		"cheques_znvr9f":"74.99",
		"cheques_mailed_black_white":"3.49",
		"cheques_znvr9f_color":"6.99"
	},
	"Barbados": {
		"currency":"USD",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"17.49",
		"chequeslicence_6mo":"32.99",
		"chequeslicence_12mo":"61.99",
		"cheques_print_1":"1.19",
		"cheques_print_2":"2.49",
		"cheques_print_5":"5.99",
		"cheques_print_10":"11.99",
		"cheques_print_15":"17.99",
		"cheques_print_20":"23.99",
		"cheques_print_30":"35.99",
		"cheques_znvr9f":"84.99",
		"cheques_mailed_black_white":"3.49",
		"cheques_znvr9f_color":"6.99"
	},
	"Belarus": {
		"currency":"USD",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"17.99",
		"chequeslicence_6mo":"33.99",
		"chequeslicence_12mo":"62.99",
		"cheques_print_1":"1.19",
		"cheques_print_2":"2.49",
		"cheques_print_5":"5.99",
		"cheques_print_10":"11.99",
		"cheques_print_15":"17.99",
		"cheques_print_20":"23.99",
		"cheques_print_30":"35.99",
		"cheques_znvr9f":"84.99",
		"cheques_mailed_black_white":"3.49",
		"cheques_znvr9f_color":"6.99"
	},
	"Belgium": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"18.49",
		"chequeslicence_6mo":"34.99",
		"chequeslicence_12mo":"63.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Belize": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Benin": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Bermuda": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Bhutan": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Bolivia": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Bosnia and Herzegovina": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"17.49",
		"chequeslicence_6mo":"33.99",
		"chequeslicence_12mo":"61.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Botswana": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Brazil": {
		"currency":"BRL",
		"chequeslicence":"25.9",
		"chequeslicence_2mo":"48.9",
		"chequeslicence_3mo":"76.9",
		"chequeslicence_6mo":"144.9",
		"chequeslicence_12mo":"264.9",
		"cheques_print_1":"4.9",
		"cheques_print_2":"10.9",
		"cheques_print_5":"27.9",
		"cheques_print_10":"54.9",
		"cheques_print_15":"84.9",
		"cheques_print_20":"109.9",
		"cheques_print_30":"169.9",
		"cheques_znvr9f":"399.9",
		"cheques_mailed_black_white":"16.9",
		"cheques_znvr9f_color":"34.9"
	},
	"British Virgin Islands": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Brunei": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Bulgaria": {
		"currency":"BGN",
		"chequeslicence":"11.99",
		"chequeslicence_2mo":"22.99",
		"chequeslicence_3mo":"35.99",
		"chequeslicence_6mo":"66.99",
		"chequeslicence_12mo":"122.99",
		"cheques_print_1":"1.99",
		"cheques_print_2":"4.49",
		"cheques_print_5":"10.99",
		"cheques_print_10":"21.99",
		"cheques_print_15":"32.99",
		"cheques_print_20":"42.99",
		"cheques_print_30":"64.99",
		"cheques_znvr9f":"149.99",
		"cheques_mailed_black_white":"6.49",
		"cheques_znvr9f_color":"12.99"
	},
	"Burkina Faso": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Cambodia": {
		"currency":"USD",
		"chequeslicence":"5.49",
		"chequeslicence_2mo":"10.49",
		"chequeslicence_3mo":"16.49",
		"chequeslicence_6mo":"30.99",
		"chequeslicence_12mo":"57.99",
		"cheques_print_1":"1.09",
		"cheques_print_2":"2.29",
		"cheques_print_5":"5.49",
		"cheques_print_10":"10.99",
		"cheques_print_15":"16.99",
		"cheques_print_20":"21.99",
		"cheques_print_30":"32.99",
		"cheques_znvr9f":"74.99",
		"cheques_mailed_black_white":"3.49",
		"cheques_znvr9f_color":"6.99"
	},
	"Cameroon": {
		"currency":"USD",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"17.99",
		"chequeslicence_6mo":"33.99",
		"chequeslicence_12mo":"61.99",
		"cheques_print_1":"1.19",
		"cheques_print_2":"2.49",
		"cheques_print_5":"5.99",
		"cheques_print_10":"11.99",
		"cheques_print_15":"17.99",
		"cheques_print_20":"23.99",
		"cheques_print_30":"35.99",
		"cheques_znvr9f":"84.99",
		"cheques_mailed_black_white":"3.49",
		"cheques_znvr9f_color":"6.99"
	},
	"Canada": {
		"currency":"CAD",
		"chequeslicence":"6.49",
		"chequeslicence_2mo":"12.49",
		"chequeslicence_3mo":"19.49",
		"chequeslicence_6mo":"36.99",
		"chequeslicence_12mo":"67.99",
		"cheques_print_1":"1.39",
		"cheques_print_2":"2.79",
		"cheques_print_5":"6.99",
		"cheques_print_10":"13.99",
		"cheques_print_15":"20.99",
		"cheques_print_20":"27.99",
		"cheques_print_30":"39.99",
		"cheques_znvr9f":"99.99",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Cape Verde": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Cayman Islands": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Chad": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Chile": {
		"currency":"CLP",
		"chequeslicence":"5500",
		"chequeslicence_2mo":"10500",
		"chequeslicence_3mo":"16500",
		"chequeslicence_6mo":"30900",
		"chequeslicence_12mo":"56900",
		"cheques_print_1":"890",
		"cheques_print_2":"1900",
		"cheques_print_5":"3900",
		"cheques_print_10":"8900",
		"cheques_print_15":"13900",
		"cheques_print_20":"17900",
		"cheques_print_30":"26900",
		"cheques_znvr9f":"64900",
		"cheques_mailed_black_white":"2500",
		"cheques_znvr9f_color":"4900"
	},
	"China mainland": {
		"currency":"CNY",
		"chequeslicence":"35",
		"chequeslicence_2mo":"68",
		"chequeslicence_3mo":"103",
		"chequeslicence_6mo":"198",
		"chequeslicence_12mo":"363",
		"cheques_print_1":"6",
		"cheques_print_2":"12",
		"cheques_print_5":"30",
		"cheques_print_10":"68",
		"cheques_print_15":"98",
		"cheques_print_20":"128",
		"cheques_print_30":"198",
		"cheques_znvr9f":"448",
		"cheques_mailed_black_white":"18",
		"cheques_znvr9f_color":"40"
	},
	"Colombia": {
		"currency":"COP",
		"chequeslicence":"25900",
		"chequeslicence_2mo":"49900",
		"chequeslicence_3mo":"77900",
		"chequeslicence_6mo":"147900",
		"chequeslicence_12mo":"269900",
		"cheques_print_1":"4900",
		"cheques_print_2":"8900",
		"cheques_print_5":"22900",
		"cheques_print_10":"44900",
		"cheques_print_15":"69900",
		"cheques_print_20":"89900",
		"cheques_print_30":"139900",
		"cheques_znvr9f":"329900",
		"cheques_mailed_black_white":"13900",
		"cheques_znvr9f_color":"27900"
	},
	"Congo, Democratic Republic of the": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Congo, Republic of the": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Costa Rica": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Cote D'Ivoire": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Croatia": {
		"currency":"HRK",
		"chequeslicence":"47.99",
		"chequeslicence_2mo":"89.99",
		"chequeslicence_3mo":"139.99",
		"chequeslicence_6mo":"269.99",
		"chequeslicence_12mo":"494.99",
		"cheques_print_1":"8.99",
		"cheques_print_2":"16.99",
		"cheques_print_5":"42.99",
		"cheques_print_10":"89.99",
		"cheques_print_15":"129.99",
		"cheques_print_20":"169.99",
		"cheques_print_30":"249.99",
		"cheques_znvr9f":"599.99",
		"cheques_mailed_black_white":"24.99",
		"cheques_znvr9f_color":"49.99"
	},
	"Cyprus": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"17.99",
		"chequeslicence_6mo":"33.99",
		"chequeslicence_12mo":"62.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Czech Republic": {
		"currency":"CZK",
		"chequeslicence":"149",
		"chequeslicence_2mo":"289",
		"chequeslicence_3mo":"449",
		"chequeslicence_6mo":"859",
		"chequeslicence_12mo":"1590",
		"cheques_print_1":"25",
		"cheques_print_2":"49",
		"cheques_print_5":"129",
		"cheques_print_10":"249",
		"cheques_print_15":"379",
		"cheques_print_20":"499",
		"cheques_print_30":"779",
		"cheques_znvr9f":"1790",
		"cheques_mailed_black_white":"79",
		"cheques_znvr9f_color":"149"
	},
	"Denmark": {
		"currency":"DKK",
		"chequeslicence":"45",
		"chequeslicence_2mo":"89",
		"chequeslicence_3mo":"139",
		"chequeslicence_6mo":"269",
		"chequeslicence_12mo":"489",
		"cheques_print_1":"9",
		"cheques_print_2":"17",
		"cheques_print_5":"45",
		"cheques_print_10":"89",
		"cheques_print_15":"129",
		"cheques_print_20":"169",
		"cheques_print_30":"249",
		"cheques_znvr9f":"599",
		"cheques_mailed_black_white":"25",
		"cheques_znvr9f_color":"49"
	},
	"Dominica": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Dominican Republic": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Ecuador": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Egypt": {
		"currency":"EGP",
		"chequeslicence":"94.99",
		"chequeslicence_2mo":"179.99",
		"chequeslicence_3mo":"289.99",
		"chequeslicence_6mo":"549.99",
		"chequeslicence_12mo":"999.99",
		"cheques_print_1":"16.99",
		"cheques_print_2":"32.99",
		"cheques_print_5":"79.99",
		"cheques_print_10":"169.99",
		"cheques_print_15":"249.99",
		"cheques_print_20":"329.99",
		"cheques_print_30":"499.99",
		"cheques_znvr9f":"1199.99",
		"cheques_mailed_black_white":"49.99",
		"cheques_znvr9f_color":"99.99"
	},
	"El Salvador": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Estonia": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"17.99",
		"chequeslicence_6mo":"33.99",
		"chequeslicence_12mo":"62.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Eswatini": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Fiji": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Finland": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.99",
		"chequeslicence_3mo":"18.49",
		"chequeslicence_6mo":"35.99",
		"chequeslicence_12mo":"64.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"France": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"18.49",
		"chequeslicence_6mo":"34.99",
		"chequeslicence_12mo":"63.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Gabon": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Gambia": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Georgia": {
		"currency":"USD",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"17.49",
		"chequeslicence_6mo":"33.99",
		"chequeslicence_12mo":"61.99",
		"cheques_print_1":"1.19",
		"cheques_print_2":"2.49",
		"cheques_print_5":"5.99",
		"cheques_print_10":"11.99",
		"cheques_print_15":"17.99",
		"cheques_print_20":"23.99",
		"cheques_print_30":"35.99",
		"cheques_znvr9f":"84.99",
		"cheques_mailed_black_white":"3.49",
		"cheques_znvr9f_color":"6.99"
	},
	"Germany": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"17.99",
		"chequeslicence_6mo":"33.99",
		"chequeslicence_12mo":"62.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Ghana": {
		"currency":"USD",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"17.99",
		"chequeslicence_6mo":"33.99",
		"chequeslicence_12mo":"61.99",
		"cheques_print_1":"1.19",
		"cheques_print_2":"2.49",
		"cheques_print_5":"5.99",
		"cheques_print_10":"11.99",
		"cheques_print_15":"17.99",
		"cheques_print_20":"23.99",
		"cheques_print_30":"35.99",
		"cheques_znvr9f":"84.99",
		"cheques_mailed_black_white":"3.49",
		"cheques_znvr9f_color":"6.99"
	},
	"Greece": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.99",
		"chequeslicence_3mo":"18.49",
		"chequeslicence_6mo":"35.99",
		"chequeslicence_12mo":"64.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Grenada": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Guatemala": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Guinea-Bissau": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Guyana": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Honduras": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Hong Kong": {
		"currency":"HKD",
		"chequeslicence":"38",
		"chequeslicence_2mo":"78",
		"chequeslicence_3mo":"118",
		"chequeslicence_6mo":"223",
		"chequeslicence_12mo":"413",
		"cheques_print_1":"8",
		"cheques_print_2":"15",
		"cheques_print_5":"38",
		"cheques_print_10":"78",
		"cheques_print_15":"118",
		"cheques_print_20":"158",
		"cheques_print_30":"238",
		"cheques_znvr9f":"548",
		"cheques_mailed_black_white":"23",
		"cheques_znvr9f_color":"48"
	},
	"Hungary": {
		"currency":"HUF",
		"chequeslicence":"2590",
		"chequeslicence_2mo":"4990",
		"chequeslicence_3mo":"7990",
		"chequeslicence_6mo":"14990",
		"chequeslicence_12mo":"27990",
		"cheques_print_1":"399",
		"cheques_print_2":"799",
		"cheques_print_5":"1990",
		"cheques_print_10":"3990",
		"cheques_print_15":"6490",
		"cheques_print_20":"7990",
		"cheques_print_30":"12990",
		"cheques_znvr9f":"29990",
		"cheques_mailed_black_white":"1290",
		"cheques_znvr9f_color":"2490"
	},
	"Iceland": {
		"currency":"USD",
		"chequeslicence":"6.19",
		"chequeslicence_2mo":"11.77",
		"chequeslicence_3mo":"18.59",
		"chequeslicence_6mo":"35.33",
		"chequeslicence_12mo":"64.47",
		"cheques_print_1":"1.19",
		"cheques_print_2":"2.49",
		"cheques_print_5":"5.99",
		"cheques_print_10":"11.99",
		"cheques_print_15":"17.99",
		"cheques_print_20":"23.99",
		"cheques_print_30":"35.99",
		"cheques_znvr9f":"84.99",
		"cheques_mailed_black_white":"3.49",
		"cheques_znvr9f_color":"6.99"
	},
	"India": {
		"currency":"INR",
		"chequeslicence":"489",
		"chequeslicence_2mo":"919",
		"chequeslicence_3mo":"1449",
		"chequeslicence_6mo":"2749",
		"chequeslicence_12mo":"5100",
		"cheques_print_1":"89",
		"cheques_print_2":"179",
		"cheques_print_5":"449",
		"cheques_print_10":"899",
		"cheques_print_15":"1299",
		"cheques_print_20":"1799",
		"cheques_print_30":"2699",
		"cheques_znvr9f":"5900",
		"cheques_mailed_black_white":"269",
		"cheques_znvr9f_color":"549"
	},
	"Indonesia": {
		"currency":"IDR",
		"chequeslicence":"85000",
		"chequeslicence_2mo":"159000",
		"chequeslicence_3mo":"249000",
		"chequeslicence_6mo":"469000",
		"chequeslicence_12mo":"859000",
		"cheques_print_1":"16000",
		"cheques_print_2":"35000",
		"cheques_print_5":"79000",
		"cheques_print_10":"159000",
		"cheques_print_15":"249000",
		"cheques_print_20":"329000",
		"cheques_print_30":"479000",
		"cheques_znvr9f":"1199000",
		"cheques_mailed_black_white":"49000",
		"cheques_znvr9f_color":"99000"
	},
	"Iraq": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Ireland": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.99",
		"chequeslicence_3mo":"18.49",
		"chequeslicence_6mo":"34.99",
		"chequeslicence_12mo":"64.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Israel": {
		"currency":"ILS",
		"chequeslicence":"15.9",
		"chequeslicence_2mo":"31.9",
		"chequeslicence_3mo":"49.9",
		"chequeslicence_6mo":"93.9",
		"chequeslicence_12mo":"171.9",
		"cheques_print_1":"3.5",
		"cheques_print_2":"6.9",
		"cheques_print_5":"17.9",
		"cheques_print_10":"35.9",
		"cheques_print_15":"53.9",
		"cheques_print_20":"69.9",
		"cheques_print_30":"105.9",
		"cheques_znvr9f":"249.9",
		"cheques_mailed_black_white":"10.9",
		"cheques_znvr9f_color":"19.9"
	},
	"Italy": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.99",
		"chequeslicence_3mo":"18.49",
		"chequeslicence_6mo":"34.99",
		"chequeslicence_12mo":"64.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Jamaica": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Japan": {
		"currency":"JPY",
		"chequeslicence":"680",
		"chequeslicence_2mo":"1300",
		"chequeslicence_3mo":"2100",
		"chequeslicence_6mo":"3900",
		"chequeslicence_12mo":"7200",
		"cheques_print_1":"120",
		"cheques_print_2":"250",
		"cheques_print_5":"610",
		"cheques_print_10":"1220",
		"cheques_print_15":"1840",
		"cheques_print_20":"2440",
		"cheques_print_30":"3680",
		"cheques_znvr9f":"8500",
		"cheques_mailed_black_white":"370",
		"cheques_znvr9f_color":"730"
	},
	"Jordan": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Kazakhstan": {
		"currency":"KZT",
		"chequeslicence":"2590",
		"chequeslicence_2mo":"4990",
		"chequeslicence_3mo":"7790",
		"chequeslicence_6mo":"14790",
		"chequeslicence_12mo":"26990",
		"cheques_print_1":"499",
		"cheques_print_2":"999",
		"cheques_print_5":"2490",
		"cheques_print_10":"4990",
		"cheques_print_15":"7490",
		"cheques_print_20":"9990",
		"cheques_print_30":"14990",
		"cheques_znvr9f":"34990",
		"cheques_mailed_black_white":"1490",
		"cheques_znvr9f_color":"2990"
	},
	"Kenya": {
		"currency":"USD",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"10.99",
		"chequeslicence_3mo":"17.49",
		"chequeslicence_6mo":"32.99",
		"chequeslicence_12mo":"60.99",
		"cheques_print_1":"1.19",
		"cheques_print_2":"2.49",
		"cheques_print_5":"5.99",
		"cheques_print_10":"11.99",
		"cheques_print_15":"17.99",
		"cheques_print_20":"23.99",
		"cheques_print_30":"35.99",
		"cheques_znvr9f":"84.99",
		"cheques_mailed_black_white":"3.49",
		"cheques_znvr9f_color":"6.99"
	},
	"Korea, Republic of": {
		"currency":"KRW",
		"chequeslicence":"7500",
		"chequeslicence_2mo":"14000",
		"chequeslicence_3mo":"22000",
		"chequeslicence_6mo":"42000",
		"chequeslicence_12mo":"77000",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Kosovo": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"17.99",
		"chequeslicence_6mo":"33.99",
		"chequeslicence_12mo":"61.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Kuwait": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"1.09",
		"cheques_print_2":"2.29",
		"cheques_print_5":"5.49",
		"cheques_print_10":"10.99",
		"cheques_print_15":"16.99",
		"cheques_print_20":"21.99",
		"cheques_print_30":"32.99",
		"cheques_znvr9f":"74.99",
		"cheques_mailed_black_white":"3.49",
		"cheques_znvr9f_color":"6.99"
	},
	"Kyrgyzstan": {
		"currency":"USD",
		"chequeslicence":"5.49",
		"chequeslicence_2mo":"10.99",
		"chequeslicence_3mo":"16.99",
		"chequeslicence_6mo":"31.99",
		"chequeslicence_12mo":"58.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Laos": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Latvia": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"18.49",
		"chequeslicence_6mo":"34.99",
		"chequeslicence_12mo":"63.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Lebanon": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Liberia": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Libya": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Lithuania": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"18.49",
		"chequeslicence_6mo":"34.99",
		"chequeslicence_12mo":"63.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Luxembourg": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"17.49",
		"chequeslicence_6mo":"33.99",
		"chequeslicence_12mo":"61.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Macau": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Madagascar": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Malawi": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"3.9",
		"cheques_print_2":"7.9",
		"cheques_print_5":"19.9",
		"cheques_print_10":"39.9",
		"cheques_print_15":"59.9",
		"cheques_print_20":"79.9",
		"cheques_print_30":"129.9",
		"cheques_znvr9f":"299.9",
		"cheques_mailed_black_white":"12.9",
		"cheques_znvr9f_color":"24.9"
	},
	"Malaysia": {
		"currency":"MYR",
		"chequeslicence":"23.9",
		"chequeslicence_2mo":"45.9",
		"chequeslicence_3mo":"72.9",
		"chequeslicence_6mo":"134.9",
		"chequeslicence_12mo":"249.9",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Maldives": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Mali": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Malta": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"17.99",
		"chequeslicence_6mo":"33.99",
		"chequeslicence_12mo":"61.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Mauritania": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Mauritius": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"25",
		"cheques_print_2":"49",
		"cheques_print_5":"129",
		"cheques_print_10":"249",
		"cheques_print_15":"379",
		"cheques_print_20":"499",
		"cheques_print_30":"749",
		"cheques_znvr9f":"1799",
		"cheques_mailed_black_white":"75",
		"cheques_znvr9f_color":"149"
	},
	"Mexico": {
		"currency":"MXN",
		"chequeslicence":"115",
		"chequeslicence_2mo":"219",
		"chequeslicence_3mo":"349",
		"chequeslicence_6mo":"659",
		"chequeslicence_12mo":"1209",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Micronesia": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"1.19",
		"cheques_print_2":"2.49",
		"cheques_print_5":"5.99",
		"cheques_print_10":"11.99",
		"cheques_print_15":"17.99",
		"cheques_print_20":"23.99",
		"cheques_print_30":"35.99",
		"cheques_znvr9f":"84.99",
		"cheques_mailed_black_white":"3.49",
		"cheques_znvr9f_color":"6.99"
	},
	"Moldova": {
		"currency":"USD",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"17.99",
		"chequeslicence_6mo":"33.99",
		"chequeslicence_12mo":"62.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Mongolia": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.79",
		"cheques_print_2":"1.69",
		"cheques_print_5":"3.99",
		"cheques_print_10":"7.99",
		"cheques_print_15":"12.99",
		"cheques_print_20":"16.99",
		"cheques_print_30":"24.99",
		"cheques_znvr9f":"59.99",
		"cheques_mailed_black_white":"2.49",
		"cheques_znvr9f_color":"4.99"
	},
	"Montenegro": {
		"currency":"EUR",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"52.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Montserrat": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Morocco": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Mozambique": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Myanmar": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Namibia": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Nauru": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Nepal": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Netherlands": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"18.49",
		"chequeslicence_6mo":"34.99",
		"chequeslicence_12mo":"63.99",
		"cheques_print_1":"1.69",
		"cheques_print_2":"3.49",
		"cheques_print_5":"8.99",
		"cheques_print_10":"16.99",
		"cheques_print_15":"25.99",
		"cheques_print_20":"34.99",
		"cheques_print_30":"49.99",
		"cheques_znvr9f":"119.99",
		"cheques_mailed_black_white":"4.99",
		"cheques_znvr9f_color":"9.99"
	},
	"New Zealand": {
		"currency":"NZD",
		"chequeslicence":"9.49",
		"chequeslicence_2mo":"17.99",
		"chequeslicence_3mo":"27.99",
		"chequeslicence_6mo":"52.99",
		"chequeslicence_12mo":"97.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Nicaragua": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Niger": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"400",
		"cheques_print_2":"800",
		"cheques_print_5":"2000",
		"cheques_print_10":"3900",
		"cheques_print_15":"5900",
		"cheques_print_20":"8200",
		"cheques_print_30":"12200",
		"cheques_znvr9f":"28900",
		"cheques_mailed_black_white":"1200",
		"cheques_znvr9f_color":"2500"
	},
	"Nigeria": {
		"currency":"NGN",
		"chequeslicence":"2300",
		"chequeslicence_2mo":"4400",
		"chequeslicence_3mo":"6900",
		"chequeslicence_6mo":"13200",
		"chequeslicence_12mo":"23900",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"North Macedonia": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"11",
		"cheques_print_2":"22",
		"cheques_print_5":"55",
		"cheques_print_10":"109",
		"cheques_print_15":"159",
		"cheques_print_20":"219",
		"cheques_print_30":"329",
		"cheques_znvr9f":"749",
		"cheques_mailed_black_white":"33",
		"cheques_znvr9f_color":"65"
	},
	"Norway": {
		"currency":"NOK",
		"chequeslicence":"59",
		"chequeslicence_2mo":"115",
		"chequeslicence_3mo":"185",
		"chequeslicence_6mo":"345",
		"chequeslicence_12mo":"639",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Oman": {
		"currency":"USD",
		"chequeslicence":"5.49",
		"chequeslicence_2mo":"9.99",
		"chequeslicence_3mo":"15.99",
		"chequeslicence_6mo":"29.99",
		"chequeslicence_12mo":"54.99",
		"cheques_print_1":"180",
		"cheques_print_2":"370",
		"cheques_print_5":"900",
		"cheques_print_10":"1900",
		"cheques_print_15":"2700",
		"cheques_print_20":"3700",
		"cheques_print_30":"5500",
		"cheques_znvr9f":"12900",
		"cheques_mailed_black_white":"550",
		"cheques_znvr9f_color":"1100"
	},
	"Pakistan": {
		"currency":"PKR",
		"chequeslicence":"1100",
		"chequeslicence_2mo":"2100",
		"chequeslicence_3mo":"3300",
		"chequeslicence_6mo":"6300",
		"chequeslicence_12mo":"11900",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Palau": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Panama": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Papua New Guinea": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Paraguay": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"3.9",
		"cheques_print_2":"6.9",
		"cheques_print_5":"17.9",
		"cheques_print_10":"37.9",
		"cheques_print_15":"54.9",
		"cheques_print_20":"74.9",
		"cheques_print_30":"109.9",
		"cheques_znvr9f":"249.9",
		"cheques_mailed_black_white":"10.9",
		"cheques_znvr9f_color":"22.9"
	},
	"Peru": {
		"currency":"PEN",
		"chequeslicence":"18.9",
		"chequeslicence_2mo":"36.9",
		"chequeslicence_3mo":"57.9",
		"chequeslicence_6mo":"109.9",
		"chequeslicence_12mo":"199.9",
		"cheques_print_1":"49",
		"cheques_print_2":"99",
		"cheques_print_5":"249",
		"cheques_print_10":"499",
		"cheques_print_15":"749",
		"cheques_print_20":"999",
		"cheques_print_30":"1490",
		"cheques_znvr9f":"3490",
		"cheques_mailed_black_white":"149",
		"cheques_znvr9f_color":"299"
	},
	"Philippines": {
		"currency":"PHP",
		"chequeslicence":"279",
		"chequeslicence_2mo":"539",
		"chequeslicence_3mo":"849",
		"chequeslicence_6mo":"1590",
		"chequeslicence_12mo":"2950",
		"cheques_print_1":"4.99",
		"cheques_print_2":"8.99",
		"cheques_print_5":"23.99",
		"cheques_print_10":"47.99",
		"cheques_print_15":"69.99",
		"cheques_print_20":"94.99",
		"cheques_print_30":"139.99",
		"cheques_znvr9f":"329.99",
		"cheques_mailed_black_white":"13.99",
		"cheques_znvr9f_color":"27.99"
	},
	"Poland": {
		"currency":"PLN",
		"chequeslicence":"29.99",
		"chequeslicence_2mo":"57.99",
		"chequeslicence_3mo":"87.99",
		"chequeslicence_6mo":"167.99",
		"chequeslicence_12mo":"309.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Portugal": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.99",
		"chequeslicence_3mo":"18.49",
		"chequeslicence_6mo":"34.99",
		"chequeslicence_12mo":"64.99",
		"cheques_print_1":"3.69",
		"cheques_print_2":"7.29",
		"cheques_print_5":"17.99",
		"cheques_print_10":"36.99",
		"cheques_print_15":"54.99",
		"cheques_print_20":"74.99",
		"cheques_print_30":"109.99",
		"cheques_znvr9f":"249.99",
		"cheques_mailed_black_white":"10.99",
		"cheques_znvr9f_color":"20.99"
	},
	"Qatar": {
		"currency":"QAR",
		"chequeslicence":"17.99",
		"chequeslicence_2mo":"34.99",
		"chequeslicence_3mo":"54.99",
		"chequeslicence_6mo":"104.99",
		"chequeslicence_12mo":"192.99",
		"cheques_print_1":"4.99",
		"cheques_print_2":"9.99",
		"cheques_print_5":"24.99",
		"cheques_print_10":"49.99",
		"cheques_print_15":"74.99",
		"cheques_print_20":"99.99",
		"cheques_print_30":"149.99",
		"cheques_znvr9f":"349.99",
		"cheques_mailed_black_white":"14.99",
		"cheques_znvr9f_color":"29.99"
	},
	"Romania": {
		"currency":"RON",
		"chequeslicence":"28.99",
		"chequeslicence_2mo":"54.99",
		"chequeslicence_3mo":"87.99",
		"chequeslicence_6mo":"167.99",
		"chequeslicence_12mo":"304.99",
		"cheques_print_1":"99",
		"cheques_print_2":"179",
		"cheques_print_5":"449",
		"cheques_print_10":"899",
		"cheques_print_15":"1390",
		"cheques_print_20":"1790",
		"cheques_print_30":"2790",
		"cheques_znvr9f":"6490",
		"cheques_mailed_black_white":"279",
		"cheques_znvr9f_color":"549"
	},
	"Russia": {
		"currency":"RUB",
		"chequeslicence":"349",
		"chequeslicence_2mo":"699",
		"chequeslicence_3mo":"1090",
		"chequeslicence_6mo":"2050",
		"chequeslicence_12mo":"3790",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Rwanda": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"S√£o Tom√© and Pr√≠ncipe": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Saudi Arabia": {
		"currency":"SAR",
		"chequeslicence":"20.99",
		"chequeslicence_2mo":"42.99",
		"chequeslicence_3mo":"64.99",
		"chequeslicence_6mo":"122.99",
		"chequeslicence_12mo":"224.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Senegal": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Serbia": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"17.99",
		"chequeslicence_6mo":"33.99",
		"chequeslicence_12mo":"62.99",
		"cheques_print_1":"3.99",
		"cheques_print_2":"7.99",
		"cheques_print_5":"19.99",
		"cheques_print_10":"39.99",
		"cheques_print_15":"64.99",
		"cheques_print_20":"84.99",
		"cheques_print_30":"129.99",
		"cheques_znvr9f":"299.99",
		"cheques_mailed_black_white":"12.99",
		"cheques_znvr9f_color":"24.99"
	},
	"Seychelles": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Sierra Leone": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Singapore": {
		"currency":"SGD",
		"chequeslicence":"7.48",
		"chequeslicence_2mo":"14.48",
		"chequeslicence_3mo":"21.98",
		"chequeslicence_6mo":"42.98",
		"chequeslicence_12mo":"77.98",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Slovakia": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"17.99",
		"chequeslicence_6mo":"33.99",
		"chequeslicence_12mo":"62.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Slovenia": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.99",
		"chequeslicence_3mo":"18.49",
		"chequeslicence_6mo":"34.99",
		"chequeslicence_12mo":"63.99",
		"cheques_print_1":"1.48",
		"cheques_print_2":"2.98",
		"cheques_print_5":"6.98",
		"cheques_print_10":"14.98",
		"cheques_print_15":"21.98",
		"cheques_print_20":"28.98",
		"cheques_print_30":"44.98",
		"cheques_znvr9f":"98.98",
		"cheques_mailed_black_white":"4.48",
		"cheques_znvr9f_color":"8.98"
	},
	"Solomon Islands": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"South Africa": {
		"currency":"ZAR",
		"chequeslicence":"99.99",
		"chequeslicence_2mo":"189.99",
		"chequeslicence_3mo":"289.99",
		"chequeslicence_6mo":"559.99",
		"chequeslicence_12mo":"999.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Spain": {
		"currency":"EUR",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"18.49",
		"chequeslicence_6mo":"34.99",
		"chequeslicence_12mo":"63.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Sri Lanka": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"16.99",
		"cheques_print_2":"32.99",
		"cheques_print_5":"79.99",
		"cheques_print_10":"169.99",
		"cheques_print_15":"249.99",
		"cheques_print_20":"329.99",
		"cheques_print_30":"499.99",
		"cheques_znvr9f":"1199.99",
		"cheques_mailed_black_white":"49.99",
		"cheques_znvr9f_color":"99.99"
	},
	"St. Kitts and Nevis": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"1200",
		"cheques_print_2":"2500",
		"cheques_print_5":"5900",
		"cheques_print_10":"12000",
		"cheques_print_15":"19000",
		"cheques_print_20":"25000",
		"cheques_print_30":"37000",
		"cheques_znvr9f":"89000",
		"cheques_mailed_black_white":"3900",
		"cheques_znvr9f_color":"7500"
	},
	"St. Lucia": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"St. Vincent and the Grenadines": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Suriname": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Sweden": {
		"currency":"SEK",
		"chequeslicence":"65",
		"chequeslicence_2mo":"129",
		"chequeslicence_3mo":"199",
		"chequeslicence_6mo":"379",
		"chequeslicence_12mo":"695",
		"cheques_print_1":"11",
		"cheques_print_2":"20",
		"cheques_print_5":"55",
		"cheques_print_10":"109",
		"cheques_print_15":"159",
		"cheques_print_20":"219",
		"cheques_print_30":"329",
		"cheques_znvr9f":"749",
		"cheques_mailed_black_white":"30",
		"cheques_znvr9f_color":"65"
	},
	"Switzerland": {
		"currency":"CHF",
		"chequeslicence":"5",
		"chequeslicence_2mo":"10",
		"chequeslicence_3mo":"15.5",
		"chequeslicence_6mo":"30",
		"chequeslicence_12mo":"54",
		"cheques_print_1":"1",
		"cheques_print_2":"2",
		"cheques_print_5":"5",
		"cheques_print_10":"10:",
		"cheques_print_15":"15:",
		"cheques_print_20":"20:",
		"cheques_print_30":"29:",
		"cheques_znvr9f":"70",
		"cheques_mailed_black_white":"3",
		"cheques_znvr9f_color":"6"
	},
	"Taiwan": {
		"currency":"TWD",
		"chequeslicence":"160",
		"chequeslicence_2mo":"320",
		"chequeslicence_3mo":"490",
		"chequeslicence_6mo":"930",
		"chequeslicence_12mo":"1730",
		"cheques_print_1":"33",
		"cheques_print_2":"70",
		"cheques_print_5":"170",
		"cheques_print_10":"330",
		"cheques_print_15":"490",
		"cheques_print_20":"670",
		"cheques_print_30":"990",
		"cheques_znvr9f":"2290",
		"cheques_mailed_black_white":"100",
		"cheques_znvr9f_color":"190"
	},
	"Tajikistan": {
		"currency":"USD",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"10.99",
		"chequeslicence_3mo":"17.49",
		"chequeslicence_6mo":"32.99",
		"chequeslicence_12mo":"59.99",
		"cheques_print_1":"1.19",
		"cheques_print_2":"2.49",
		"cheques_print_5":"5.99",
		"cheques_print_10":"11.99",
		"cheques_print_15":"17.99",
		"cheques_print_20":"23.99",
		"cheques_print_30":"35.99",
		"cheques_znvr9f":"84.99",
		"cheques_mailed_black_white":"3.49",
		"cheques_znvr9f_color":"6.99"
	},
	"Tanzania": {
		"currency":"TZS",
		"chequeslicence":"11500",
		"chequeslicence_2mo":"22900",
		"chequeslicence_3mo":"34900",
		"chequeslicence_6mo":"65900",
		"chequeslicence_12mo":"122900",
		"cheques_print_1":"2200",
		"cheques_print_2":"4500",
		"cheques_print_5":"10900",
		"cheques_print_10":"21900",
		"cheques_print_15":"32900",
		"cheques_print_20":"44900",
		"cheques_print_30":"64900",
		"cheques_znvr9f":"149900",
		"cheques_mailed_black_white":"6500",
		"cheques_znvr9f_color":"12900"
	},
	"Thailand": {
		"currency":"THB",
		"chequeslicence":"189",
		"chequeslicence_2mo":"369",
		"chequeslicence_3mo":"579",
		"chequeslicence_6mo":"1100",
		"chequeslicence_12mo":"2000",
		"cheques_print_1":"35",
		"cheques_print_2":"69",
		"cheques_print_5":"179",
		"cheques_print_10":"349",
		"cheques_print_15":"549",
		"cheques_print_20":"729",
		"cheques_print_30":"1100",
		"cheques_znvr9f":"2500",
		"cheques_mailed_black_white":"99",
		"cheques_znvr9f_color":"219"
	},
	"Tonga": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Trinidad and Tobago": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Tunisia": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Turkey": {
		"currency":"TRY",
		"chequeslicence":"114.99",
		"chequeslicence_2mo":"224.99",
		"chequeslicence_3mo":"349.99",
		"chequeslicence_6mo":"649.99",
		"chequeslicence_12mo":"1199.99",
		"cheques_print_1":"21.99",
		"cheques_print_2":"44.99",
		"cheques_print_5":"109.99",
		"cheques_print_10":"219.99",
		"cheques_print_15":"329.99",
		"cheques_print_20":"449.99",
		"cheques_print_30":"649.99",
		"cheques_znvr9f":"1599.99",
		"cheques_mailed_black_white":"69.99",
		"cheques_znvr9f_color":"129.99"
	},
	"Turkmenistan": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Turks and Caicos Islands": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Uganda": {
		"currency":"USD",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"17.49",
		"chequeslicence_6mo":"33.99",
		"chequeslicence_12mo":"61.99",
		"cheques_print_1":"1.19",
		"cheques_print_2":"2.49",
		"cheques_print_5":"5.99",
		"cheques_print_10":"11.99",
		"cheques_print_15":"17.99",
		"cheques_print_20":"23.99",
		"cheques_print_30":"35.99",
		"cheques_znvr9f":"84.99",
		"cheques_mailed_black_white":"3.49",
		"cheques_znvr9f_color":"6.99"
	},
	"Ukraine": {
		"currency":"USD",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"17.99",
		"chequeslicence_6mo":"33.99",
		"chequeslicence_12mo":"62.99",
		"cheques_print_1":"1.19",
		"cheques_print_2":"2.49",
		"cheques_print_5":"5.99",
		"cheques_print_10":"11.99",
		"cheques_print_15":"17.99",
		"cheques_print_20":"23.99",
		"cheques_print_30":"35.99",
		"cheques_znvr9f":"84.99",
		"cheques_mailed_black_white":"3.49",
		"cheques_znvr9f_color":"6.99"
	},
	"United Arab Emirates": {
		"currency":"AED",
		"chequeslicence":"18.99",
		"chequeslicence_2mo":"36.99",
		"chequeslicence_3mo":"57.99",
		"chequeslicence_6mo":"109.99",
		"chequeslicence_12mo":"199.99",
		"cheques_print_1":"3.69",
		"cheques_print_2":"7.29",
		"cheques_print_5":"17.99",
		"cheques_print_10":"36.99",
		"cheques_print_15":"54.99",
		"cheques_print_20":"74.99",
		"cheques_print_30":"109.99",
		"cheques_znvr9f":"249.99",
		"cheques_mailed_black_white":"10.99",
		"cheques_znvr9f_color":"20.99"
	},
	"United Kingdom": {
		"currency":"GBP",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.99",
		"chequeslicence_3mo":"15.49",
		"chequeslicence_6mo":"28.99",
		"chequeslicence_12mo":"52.99",
		"cheques_print_1":"0.89",
		"cheques_print_2":"1.79",
		"cheques_print_5":"4.49",
		"cheques_print_10":"8.99",
		"cheques_print_15":"12.99",
		"cheques_print_20":"17.99",
		"cheques_print_30":"25.99",
		"cheques_znvr9f":"59.99",
		"cheques_mailed_black_white":"2.49",
		"cheques_znvr9f_color":"4.99"
	},
	"United States": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Uruguay": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.99",
		"chequeslicence_3mo":"15.49",
		"chequeslicence_6mo":"28.99",
		"chequeslicence_12mo":"52.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Uzbekistan": {
		"currency":"USD",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"10.99",
		"chequeslicence_3mo":"17.49",
		"chequeslicence_6mo":"32.99",
		"chequeslicence_12mo":"59.99",
		"cheques_print_1":"1.19",
		"cheques_print_2":"2.49",
		"cheques_print_5":"5.99",
		"cheques_print_10":"11.99",
		"cheques_print_15":"17.99",
		"cheques_print_20":"23.99",
		"cheques_print_30":"35.99",
		"cheques_znvr9f":"84.99",
		"cheques_mailed_black_white":"3.49",
		"cheques_znvr9f_color":"6.99"
	},
	"Vanuatu": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Venezuela": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Vietnam": {
		"currency":"VND",
		"chequeslicence":"119000",
		"chequeslicence_2mo":"229000",
		"chequeslicence_3mo":"349000",
		"chequeslicence_6mo":"669000",
		"chequeslicence_12mo":"1249000",
		"cheques_print_1":"22000",
		"cheques_print_2":"45000",
		"cheques_print_5":"109000",
		"cheques_print_10":"219000",
		"cheques_print_15":"329000",
		"cheques_print_20":"449000",
		"cheques_print_30":"699000",
		"cheques_znvr9f":"1499000",
		"cheques_mailed_black_white":"69000",
		"cheques_znvr9f_color":"129000"
	},
	"Yemen": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Zambia": {
		"currency":"USD",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"9.49",
		"chequeslicence_3mo":"14.99",
		"chequeslicence_6mo":"28.49",
		"chequeslicence_12mo":"51.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Zimbabwe": {
		"currency":"USD",
		"chequeslicence":"5.99",
		"chequeslicence_2mo":"11.49",
		"chequeslicence_3mo":"17.99",
		"chequeslicence_6mo":"33.99",
		"chequeslicence_12mo":"62.99",
		"cheques_print_1":"1.19",
		"cheques_print_2":"2.49",
		"cheques_print_5":"5.99",
		"cheques_print_10":"11.99",
		"cheques_print_15":"17.99",
		"cheques_print_20":"23.99",
		"cheques_print_30":"35.99",
		"cheques_znvr9f":"84.99",
		"cheques_mailed_black_white":"3.49",
		"cheques_znvr9f_color":"6.99"
	}
};