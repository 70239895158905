export const Haw = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Kuleana kope & kope; 2023",
  black:"ʻeleʻele",
  green:"ʻōmaʻomaʻo",
  gold:"gula",
  blue:"Polū",
  brown:"Palaunu",
  purple:"Poni",
  pink:"Ākala",
  red:"ʻulaʻula",
  Swatches:"Swatch",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Hue",
  Saturation:"Hoʻohui",
  Lightness:"Māmā",
  Upgrade_To_Pro:"Hoʻonui iā Pro",
  AllFeaturesInclude:"Loaʻa nā hiʻohiʻona a pau:",
  PrintUnlimitedCheques:"E paʻi i nā hōʻoia palena ʻole",
  PremiumChequeDesigns:"Nā Hoʻolālā Hoʻolālā Premium",
  ManageUnlimitedEmployees:"Hoʻoponopono i nā limahana palena ʻole",
  AddUnlimitedPayees:"Hoʻohui i nā mea uku palena ʻole",
  CreateUnlimitedPayrolls:"Hana i nā uku uku palena ʻole",
  UnlimitedSchedulesandTimeSheets:"Nā Papa Hana ʻole a me nā Pepa Manawa",
  BulkPayPalPayouts:"Uku Uku PayPal Nui",
  UnlimitedBankAccounts:"Nā Waihona Panakō palena ʻole",
  ManageMultipleCompanies:"Hooponopono i na hui lehulehu",
  TrackInsurancePolicies:"E hahai i nā kulekele inikua",
  Bio_MetricProtection:"Palekana Bio-Metric",
  Geo_FenceLock_OutProtection:"Pale Geo-Papa Laka-waho",
  Multiple_Companies_Word:"ʻAʻole loaʻa ka mālama ʻana i nā ʻoihana he nui me ka uku ʻole o Checks.",
  PayPal_Payouts_Word:"Hoʻopau ʻia nā uku PayPal me ka uku ʻole ʻo Checks.",
  PINProtection:"Pale PIN",
  PasswordProtection:"Pale ʻōlelo huna",
  May_Require_Approval:"Pono paha e ʻae ʻia.",
  Subscribe:"Kau inoa",
  Billed:"Pili ʻia",
  Up:"I luna",
  Down:"I lalo",
  Positioning:"Ho\ʻonohonoho",
  Marker:"Hoailona",
  Drag_Marker:"Kaha kaha",
  Tagline:"Pa\ʻi i nā hō\ʻoia a me ka papa helu uku",
  Marker_Word:"E ho\ʻohana i nā Markers e ho\ʻonui i ka mea.",
  Pinch_Zoom:"Pinch Zoom",
  Pinch_Word:"Pin i ka ho\ʻonui \ʻana i ka mea.",
  Drag:"Kauō",
  Drag_Word:"E ho\ʻohana i kou manamana lima e kauo i nā mea.",
  subscription_alias_word:"Ho\ʻopa\ʻa inoa hou-aunoa",
  premium_alias_word:"Pākē Ho\ʻonui Ho\ʻokahi Manawa",
  print_alias_word:"Pa\ʻi-Make Pa\ʻi Pa\ʻi",
  mode_alias_word:"Ke ano",
  Pro:"Pro",
  Pro_word:"Pono ka mana pro.",
  Cant_Delete_Default_Company_Word:"E kala mai, \ʻa\ʻole hiki iā \ʻoe ke holoi i kāu hui pa\ʻamau.",
  Reinsert_Default_Designs:"Ho\ʻokomo hou i nā ho\ʻolālā pa\ʻamau",
  Reinsert_Default_Designs_word:"Makemake \ʻoe e ho\ʻokomo hou i nā ho\ʻolālā pa\ʻamau?",
  Subscription_Active_Disable_Word:"Ho\ʻoikaika kēia kau inoa. Makemake \ʻoe e kāpae i kēia kau inoa iā Cheques?",
  Company_Logo:"Logo Hui",
  ZERO_:"\ʻOLE",
  Disclaimer:"Ho\ʻolele",
  Privacy_Policy:"Kulekele pilikino",
  EULA:"Nānā EULA",
  Terms_Of_Service:"Kūlana o ka lawelawe",
  Terms_Of_Use:"Nā \ʻōlelo ho\ʻohana",
  Refunds:"Kulekele Ho\ʻiho\ʻi",
  Single_Print:"1 Nānā",
  Two_Prints:"2 Nānā",
  Five_Prints:"5 Nānā",
  Ten_Prints:"10 Nānā",
  Fifteen_Prints:"15 Nānā",
  Twenty_Prints:"20 Hooia",
  Thirty_Prints:"30 Hooia",
  Image_Added:"Ho\ʻohui \ʻia ke ki\ʻi",
  Image_Preview:"Nānā Ki\ʻi",
  No_Image_Was_Selected:"\ʻA\ʻohe ki\ʻi i koho \ʻia.",
  Cheques_Unlimited:"Nānā palena \ʻole",
  _Subscription:"Kau inoa",
  Subscription:"Nānā - 1 mahina",
  Two_Month_Subscription:"Nānā - 2 mahina",
  Three_Month_Subscription:"Nānā - 3 mahina",
  Six_Month_Subscription:"Nānā - 6 mahina",
  Twelve_Month_Subscription:"Nānā - 12 mahina",
  Cheques_Are_Available:"Loa\ʻa nā māka e pa\ʻi.",
  Upgrade_Required_Two:"E koho i kahi pū\ʻolo a kaomi pālua i ke pihi kumukū\ʻai e ho\ʻomaka i kāu kū\ʻai. E pa\ʻi i nā loiloi kala piha i nā kekona.",
  Month:"Mahina",
  Due:"Pono:",
  Expires:"Pau:",
  Subscription_Active:"Kau inoa Active",
  Subscription_Inactive:"\ʻA\ʻole i hana \ʻia ke kau inoa",
  Purchase_Additional_Cheques:"E kū\ʻai i nā kiko\ʻī hou aku?",
  Printable_Cheque:"Hiki ke pa\ʻi \ʻia",
  Printable_Cheques:"Palapala Kii",
  Printable_Cheque_Word:"Loa\ʻa ka māka ma kāu mo\ʻokāki.",
  Printable_Cheques_Word:"Loa\ʻa nā māka ma kāu mo\ʻokāki.",
  Max_Amount_Message:"\ʻO ka nui āu i \ʻōlelo ai ua hiki i ka nui o ka nui i ho\ʻonohonoho \ʻia no kēia \ʻōnaehana:",
  Terms_Required_Word:"Pono \ʻoe e \ʻae i kēia \ʻaelike ma mua o ka ho\ʻomau \ʻana i ka ho\ʻohana \ʻana i nā Cheques.",
  Subscriptions:"Kau inoa",
  Product_Upgrades:"Ho\ʻonui",
  Mailed_Out_Cheques:"Na Leka-waho",
  Enter_A_Company_Name:"E \ʻolu\ʻolu e ho\ʻokomo i kahi inoa \ʻoihana.",
  Single_Cheques:"Hooia Hookahi",
  Cheque_Golden:"Hoike Gula",
  Cheque_Golden_Window:"Ho\ʻolālā nānā gula.",
  Cheque_Green:"Nānā \ʻōma\ʻoma\ʻo",
  Cheque_Green_Window:"Ho\ʻolālā nānā \ʻōma\ʻoma\ʻo.",
  Cheque_Red:"Nānā Ulaula",
  Cheque_Red_Window:"Ho\ʻolālā nānā \ʻula\ʻula.",
  Cheque_Yellow:"Hō\ʻike Melemele",
  Cheque_Yellow_Window:"Ho\ʻolālā nānā melemele.",
  Cheque_Statue_of_Liberty:"\ʻO ke ki\ʻi kū\ʻoko\ʻa",
  Cheque_Statue_of_Liberty_Window:"\ʻO ka Statue of Liberty check design.",
  Cheque_Green_Wave:"Nalu \ʻōma\ʻoma\ʻo",
  Cheque_Green_Wave_Window:"Ho\ʻolālā nānā \ʻōma\ʻoma\ʻo.",
  Cheque_Golden_Weave:"Ua ulana gula",
  Cheque_Golden_Weave_Window:"Ho\ʻolālā nānā gula nani.",
  Cheque_Green_Sun:"Lā \ʻōma\ʻoma\ʻo",
  Cheque_Green_Sun_Window:"Ho\ʻolālā nānā hohonu a mālie.",
  Cheque_Blue_Checkers:"Blue Checkers",
  Cheque_Blue_Checkers_Window:"Ho\ʻolālā nānā polū.",
  Cashiers_Check:"Heke a ka Luna Waiwai",
  Cashiers_Check_Window:"Kaila Kaila Kāleka Kāleka.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Ho\ʻolālā nānā wai.",
  Cheque_Golden_Checkers:"Na Kahu Gula",
  Cheque_Golden_Checkers_Window:"Ho\ʻolālā nānā gula.",
  Upgrade_Unavailable:"\ʻA\ʻole loa\ʻa nā ho\ʻomaika\ʻi",
  Bank_Code_Protection:"Palekana Helu Bank",
  Bank_Code_Protection_Word:"E pale i kāu mau helu panakō mai ka ho\ʻohana \ʻia \ʻana ma kēia polokalamu e holo ana ma kahi mea ho\ʻohana \ʻē a\ʻe. He IRREVERSIBLE kēia hana. E ho\ʻomau?",
  Bank_Code_Protection_Blocked_Word:"\ʻO nā helu panakō āu e ho\ʻā\ʻo nei e ho\ʻohana ua mālama \ʻia no kekahi mea ho\ʻohana a mea hana \ʻē a\ʻe.",
  Bank_Code_Protection_Error_Word:"Ua hā\ʻule ka hō\ʻoia helu. E \ʻolu\ʻolu e ho\ʻopili i ka pūnaewele a ho\ʻā\ʻo e ho\ʻohui hou i kēia waihona waihona.",
  Bank_Code_Protection_Set_Error_Word:"Pono ka pale helu panakō e ho\ʻopili \ʻoe i ka pūnaewele. E \ʻolu\ʻolu e ho\ʻohui a ho\ʻā\ʻo hou.",
  Upgrade_Unavailable_Word:"E kala mai, pilikia mākou i ka hō\ʻoia \ʻana iā \ʻoe. \ʻA\ʻole loa\ʻa nā kau inoa a me nā ho\ʻomaika\ʻi i nā Checks no ke kū\ʻai \ʻana ma kou wahi.",
  PayPal_Payment_Preview:"Namua Uku PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"E koho iā PayPal",
  PayPal_Applications:"Nā noi PayPal",
  Purchase_With_Apple_Pay:"Kū\ʻai me Apple Pay",
  Google_Pay:"Google Uku",
  Companies:"Nā hui",
  Insurance:"Inikua",
  New_PayPal:"PayPal hou",
  Pay_By:"Uku Ma",
  Insure:"Inikua",
  Miles:"Mile",
  Payment_Method:"Uku Uku",
  Select_Policies:"E koho i nā kulekele",
  Policies:"Nā kulekele",
  Policy:"Kulekele",
  No_PayPal_Account:"\ʻA\ʻohe mo\ʻokāki PayPal",
  No_Policies:"\ʻA\ʻohe Kulekele Inikua",
  New_Policy:"Kulekele Hou",
  PayPal_Payment:"Uku PayPal",
  PayPal_Payments:"Uku PayPal",
  No_Payment_Selected:"\ʻA\ʻohe uku i koho \ʻia",
  Sending_Payment_Word:"E \ʻolu\ʻolu e kali... Ke ho\ʻouna \ʻia nei kēia uku.",
  Sending_Payments_Word:"E \ʻolu\ʻolu e kali... Ke ho\ʻouna \ʻia nei nā uku.",
  No_Payment_Selected_PayPal:"\ʻA\ʻohe <a routerLink='/folder/Payments'>PayPal uku</a> i koho \ʻia e ho\ʻouna.",
  Payment_Sent:"Uku Hoounaia",
  PayPal_Payment_Sent:"Ua ho\ʻouna \ʻia kēia uku me PayPal.",
  Copay:"Copay",
  Dead:"Make",
  Alive:"Ola",
  Not_Dead:"\ʻA\ʻole Make",
  Unclaimed:"\ʻA\ʻole koi \ʻia",
  Attempted:"Hoao",
  Deceased:"Ua make",
  Claimed:"Koi \ʻia",
  Unpaid:"Uku ole ia",
  Sending_Payment:"Hoouna Uku",
  Sending_Payments:"Hoouna Uku",
  Send_PayPal_Confirmation:"Makemake \ʻoe e ho\ʻouna i kēia kālepa me PayPal?",
  Send_PayPal_Confirmation_Word:"E kaomi i ke pihi \ʻōma\ʻoma\ʻo e ho\ʻouna i kēia kālepa.",
  Save_Payment_As_Unpaid:"E mālama i kēia uku \ʻa\ʻole i uku \ʻia?",
  Payment_Pay_Confirmation_PayPal:"E mālama i kēia uku e like me ka uku?",
  No_Policies_Word:"Ho\ʻohui i ka <a routerLink='/folder/Postage/New'>Kulekele Inikua</a> i kēia manawa.",
  Timesheet_Multiple_Delete_Confirmation:"Makemake \ʻoe e holoi i nā pepa manawa he nui?",
  Select_Multiple_Timesheets_Prompt:"\ʻA\ʻohe pepa manawa i koho \ʻia. E koho i ho\ʻokahi pepa manawa.",
  Select_Multiple_Policies_Prompt:"\ʻA\ʻohe kulekele i koho \ʻia. E koho i ho\ʻokahi kulekele \ʻinikua.",
  Policies_Multiple_Delete_Confirmation:"Makemake \ʻoe e holoi i nā kulekele lehulehu?",
  Company:"Hui",
  Add_Company:"Pāku\ʻi Hui",
  New_Company:"Pāku\ʻi Hui",
  No_Companies:"\ʻA\ʻohe Hui",
  Enable_Company:"Hiki i ka Hui",
  Select_Company:"E koho i ka Hui",
  The_Default_Company:"\ʻO ka lepili \ʻoihana pa\ʻamau.",
  Manage_Companies:"Hooponopono i na Hui",
  No_Companies_Word:"E ho\ʻohana nā māka i kahi hui pa\ʻamau.<br />Ho\ʻohui i ka <a routerLink='/folder/Company/New'>hui mua</a>.",
  Default_Company:"Hui Pa\ʻamau",
  Cheques_Unlimited_Word:"\ʻAe \ʻo Nānā palena ʻole iā \ʻoe e pa\ʻi i nā helu he nui e like me kou makemake.",
  Cheques_Subscription_Word:"Hiki iā \ʻoe ke pa\ʻi i nā helu helu he nui e like me kou makemake.",
  You_Own_This_Product:"Nou keia huahana.",
  Your_Subscription_is_Active:"Ho\ʻoikaika kāu kau inoa.",
  Active_Products:"Nā Huahana Ho\ʻola",
  Purchase_Confirmation:"Kū\ʻai",
  Purchase_Cheques:"Nā Palapala Kū\ʻai",
  Restore_Purchase:"Ho\ʻiho\ʻi i nā Kū\ʻai",
  Erase_Purchase:"Holoi i nā Kū\ʻai",
  Successfully_Purchased:"Ua kū\ʻai maika\ʻi \ʻia",
  Enter_Your_Licence_Key:"E \ʻolu\ʻolu e ho\ʻokomo i kāu kī laikini ma kēia \ʻao\ʻao e ho\ʻā i kēia huahana.",
  Licence_Key:"Ki Laikini",
  Enter_Licence_Key:"E hookomo i ke Ki Laikini",
  Purchased:"Kū\ʻai \ʻia",
  Enable_Feature:"E ho'ā i ka hi\ʻohi\ʻona",
  Cancel_Subscription:"Ho\ʻopau i ke kau inoa",
  Subscription_Removed:"Wehe ia ke kau inoa",
  Select_Active_Subscription:"E koho i ke kau inoa hana i mea e ho\ʻololi ai.",
  Remove_Subscription_Word:"Makemake \ʻoe e kāpae i kēia kau inoa?",
  Delete_Company_Confirmation:"Makemake \ʻoe e holoi i kēia hui holo\ʻoko\ʻa? WARNING: E nalowale ana \ʻoe i nā \ʻike i mālama \ʻia!",
  Delete_Default_Company_Word:"\ʻA\ʻole hiki iā \ʻoe ke holoi i ka \ʻoihana pa\ʻamau. Makemake \ʻoe e ho\ʻiho\ʻi hou i ka palapala noi a ho\ʻiho\ʻi i ke kūlana pa\ʻamau? WARNING: E nalowale ana \ʻoe i nā \ʻike i mālama \ʻia!",
  Console_Warning_2:"Mai mālama i kekahi kālā me ka ho\ʻohana \ʻana i kēia noi \ʻa\ʻole iā \ʻoe i kēia manawa.",
  Terms_and_Conditions:"Nā Kūlana a me nā Kūlana",
  and_the:"a me ka",
  for:"no ka mea",
  Please_Read_Word:"E \ʻolu\ʻolu e heluhelu a \ʻae i ka",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"\ʻA\ʻole i loa\ʻa kekahi mau helu ho\ʻololi kālā. E \ʻolu\ʻolu e ho\ʻopili i ka pūnaewele.",
  Free:"Kuokoa",
  DB_Erase_Prompt_2:"Holoi loa i ka waihona mea ho\ʻomohala holo\ʻoko\ʻa? WARNING: E lilo ana \ʻoe i nā \ʻike a pau KĀ\ʻAI a me ka SUBSCRIPTION!",
  Successfully_Imported:"Ho\ʻokomo maika\ʻi \ʻia",
  Select_Postage:"E koho i ka leka uila",
  No_Postage:"\ʻA\ʻohe Pepa Leta",
  No_Paid_Postage_Word:"Ho\ʻohui i ka pe\ʻa mua <a routerLink='/folder/Postage/New'>Uku Leta</a>.",
  Trial_Complete:'Pau ka hookolokolo',
  Please_Upgrade:'E \ʻolu\ʻolu e ho\ʻomaika\ʻi i nā Checks e ho\ʻomau i ka pa\ʻi \ʻana.',
  Aa:"Aa",
  Color:"kala kala",
  Font:"Font",
  Guide:"Alakai",
  Guides:"Nā alakaʻi",
  Image:"Kiʻi",
  Zoom:"Zoom",
  Logo:"Logo",
  Bank:"Panakō",
  MICR:"MICR",
  Total:"Huina",
  Cancel:"Hoʻopau",
  Confirm:"E hōʻoia",
  Method:"Kaʻina hana",
  Biometric_Security:"Palekana Bio-metric",
  Please_Login_To_Continue:"E ʻoluʻolu e ʻeʻe e hoʻomau.",
  Complete:"Paʻa",
  Sign_Up:"Kau inoa",
  Error:"Kuhihewa",
  Biometrics:"Bio-meka",
  Percent:"pākēneka",
  Zero_Percent:"0%",
  Top_Margin:"ʻO ka palena luna",
  Bottom_Margin:"Kaʻao lalo",
  Left_Margin:"Palena hema",
  Right_Margin:"ʻAkau ʻākau",
  MICR_Margin:"MICR palena",
  Table_Margin:"Palena Papa",
  Address_Margin:"Palena helu wahi",
  Percentage_:"pākēneka",
  Vacation_Title:"Poʻo inoa hoʻomaha",
  Use_PIN:"E hoʻohana i ka PIN",
  Loading__:"Ke hoʻouka nei...",
  Design_Title:"Inoa Hoʻolālā",
  Authorize:"Manaʻo",
  Key:"Ki",
  Public_Key:"Ki lehulehu",
  Private_Key:"Kī Kuʻuna",
  Authenticate:"Hōʻoiaʻiʻo",
  Last_Payroll:"Ka uku hope loa",
  Last_Calculation:"Helu hope",
  Authorized:"ʻAe ʻia",
  Geo_Authorized:"Geo-Wāhi: Mana",
  Not_Authorized:"ʻAʻole ʻae ʻia",
  Authorization_Complete:"Paʻa ka mana",
  Geolocation_Authorization:"ʻAe ʻia Geo-wahi",
  Out_of_Bounds:"Ma waho o nā palena",
  Cant_Delete_Default_Design:"ʻAʻole hiki ke holoi i kahi hoʻolālā paʻamau.",
  Unauthorized_Out_of_Bounds:"ʻAʻole ʻae ʻia: Ma waho o nā palena",
  Biometric_Authorization:"Manaʻo Bio-metric",
  Locating_Please_Wait:"Ke ʻimi nei, e ʻoluʻolu e kali...",
  Test_Biometrics:"Ho'āʻo Bio-metrics",
  Cheque_Margins:"E nānā i nā palena",
  Percentage_Full_Error:"ʻAʻole hiki ke hoʻonohonoho ʻia ka pā pākēneka ma luna o 100% pākēneka.",
  No_Payroll_Text:"Hoʻohui i kahi <a routerLink='/folder/Employee/New'>Paahana</a> a i ʻole <a routerLink='/folder/Payee/New'>Uku</a> a me kahi <a routerLink='/folder/Schedule/New'>Paʻa manawa</a>.",
  Design_Saved:"Hoʻolālā i mālama ʻia",
  Default_Design_Selected:"Hoʻolālā Default i koho ʻia",
  Partial_Import:"Lawe hapa",
  Partial_Export:"Hoʻokuʻu hapa",
  Entire_Import:"Hoʻokomo piha",
  Entire_Export:"Hoʻopuka holoʻokoʻa",
  Existing_Password:"E ʻoluʻolu e hoʻokomo i kāu ʻōlelo huna:",
  Existing_PIN:"E ʻoluʻolu e hoʻokomo i kāu code PIN i kēia manawa:",
  Pin_Change_Header:"Hōʻoia PIN",
  Pin_Change_SubHeader:"E hoʻokomo i kāu helu PIN kahiko e hōʻoia i ka hoʻololi.",
  Pass_Change_Header:"Hōʻoia ʻōlelo huna",
  Pass_Change_SubHeader:"E hoʻokomo i kāu ʻōlelo huna kahiko e hōʻoia i ka hoʻololi.",
  PIN_Enter_Message:"E hoʻokomo i kāu PIN e hōʻoia.",
  Password_Enter_Message:"E hoʻokomo i kāu ʻōlelo huna e hōʻoia.",
  Pin_Updated_Success:"Ua hoʻololi maikaʻi ʻia ka PIN!",
  Pin_Updated_Fail:"ʻAʻole hiki ke hōʻano hou ʻia ka PIN.",
  Pass_Updated_Success:"Ua hōʻano hou ʻia ka ʻōlelo huna.",
  Pass_Updated_Fail:"ʻAʻole hiki ke hōʻano hou ʻia ka ʻōlelo huna.",
  Preview_Payment:"Namua Uku",
  Preview_Payroll:"Nānā i ka uku uku",
  No_Payments_Created:"ʻAʻohe uku i ʻike ʻia e hana ʻia.",
  Payment_Preview:"Namua Uku",
  Enable_Payee:"E ho'ā i ka mea uku",
  Rendered:"Hāʻawi ʻia",
  No_Email:"ʻAʻohe leka uila",
  Setup_Cheques:"Nānā Hoʻonohonoho",
  name:"Inoa",
  address:"Heluhelu",
  address_2:"Laina helu helu 2",
  city:"Kulanakauhale",
  province:"Panalaau",
  postal_code:"Leta/Helu kuhi",
  country:"ʻāina",
  username:"inoa hoʻohana",
  full_name:"Inoa piha",
  birthday:"Lā hānau",
  email:"leka uila",
  phone:"Kelepona",
  employees:"Nā limahana",
  addresses:"Heluhelu",
  payment_amount_limit:"Ka palena huina kālā",
  total_limit:"Ka palena pau",
  payees:"Ka poe uku",
  description:"wehewehe",
  address_line_one:"Helu Laina Ekahi",
  address_line_two:"Helu Laina Elua",
  image:"Kiʻi",
  account_holder:"Paʻa moʻokāki",
  cheque_starting_id:"E nānā i ka ID hoʻomaka",
  transit_number:"Helu Transit",
  institution_number:"Helu Hui",
  designation_number:"Helu Koho",
  account_number:"Helu moʻokāki",
  currency:"Kālā",
  signature:"Kaulima",
  payment_payroll_limit:"Palena Uku",
  total_limi:"Ka palena pau",
  date:"Lā",
  printed_memo:"Paʻi ʻia Memo",
  banks:"Nā panakō",
  signature_image:"Kiʻi pūlima",
  address_name:"Helu inoa",
  notes:"Nā memo",
  design:"Hoʻolālā",
  title:"Poʻo inoa",
  frequency:"Ka pinepine",
  fax:"Fax",
  salaries:"Uku",
  salary_ids:"Nā ID uku",
  start_time:"Manawa Hoomaka",
  end_time:"Manawa Hoopau",
  paid:"Ukuia",
  overtime_percentage:"Pākēneka i uku ʻia",
  overtime_amount:"Uku Paa",
  first_name:"Inoa mua",
  last_name:"Inoa hope",
  moderation:"Ka hoʻohaʻahaʻa",
  create:"Hana",
  edit:"Hoʻoponopono",
  destroy:"E luku",
  day_start_time:"La_hoomaka_manawa",
  day_end_time:"Lā_hope_manawa",
  fullname:"Inoa",
  time:"Manawa",
  auto_send:"Hoʻouna 'akomi",
  time_method:"Ka Manawa",
  schedules:"Nā papa kuhikuhi",
  indefinite_payroll_enabled:"Ho'ā mau loa",
  amount:"Ka nui",
  repeat:"E hana hou",
  always_enabled:"Hiki mau",
  start_date:"Lā Hoʻomaka",
  end_date:"Lā Hoʻopau",
  Cheque_Total:"Nānā Huina",
  Total_Amount:"Huina Nui:",
  Amounts:"Nā huina:",
  Images:"Nā kiʻi",
  Files:"Nā waihona",
  Previewing:"Nānamua:",
  Insert:"Hookomo",
  Preview_Import:"Nāmua lawe mai",
  Entry:"Komo",
  Entries:"Nā mea komo",
  No_Entries:"ʻAʻohe Koho",
  Import_Type:"ʻAno lawe mai",
  Select_Details:"E koho i nā kikoʻī",
  Select_Payee:"E koho i ka mea uku",
  Enable_Holidays:"Hiki i nā lā hoʻomaha",
  Disable_Holidays:"Hoʻopau i nā lā hoʻomaha",
  Wire_Transfer:"Uea Uea",
  New_Export:"Hoʻopuka Hou",
  Export_Data:"Hoʻokuʻu i ka ʻikepili",
  Export_Data_Word:"E koho i ke ʻano o ka faila e hoʻoiho a hoʻoiho.",
  Export_File:"Waihona waihona",
  Mode:"Ke ano",
  Times:"Manawa",
  Widgets:"Nā Widget",
  Slider:"Mea pahee",
  Set_Details:"Hoʻonohonoho i nā kikoʻī",
  Select_Type:"E koho i ke ʻano",
  Display_Slider:"Hōʻike Slider",
  Dashboard_Slider:"Mea hoʻoheheʻe Dashboard",
  Dashboard_Mode:"Ke ʻano papa kuhikuhi",
  Show_Intro:"Hōʻike Intro",
  Show_Payrolls:"Hōʻike i nā uku uku",
  Show_Holidays:"Hōʻike i nā lā hoʻomaha",
  Show_Invoices:"Hōʻike i nā Invoice",
  Show_Cheques:"Hōʻike i nā hōʻoia",
  Enabled_Widgets:"Nā Widget i hoʻohana ʻia",
  Disabled_Widgets:"Nā Widget hoʻopale",
  Colors:"Nā kala",
  Barcodes:"Barcodes",
  View_Timers:"Nānā Manawa",
  Gradients:"Gradients",
  No_Info:"ʻAʻohe ʻike",
  Disable:"Hoʻopau",
  Show_Layer:"Hōʻike i nā ʻāpana",
  Hide_Layer:"Hūnā Lae",
  Text_Layer:"Nā Papa kikokiko",
  Secondly:"ʻO ka lua",
  Minutely:"minuke",
  nine_am:"9:00 AM",
  five_pm:"5:00 PM",
  Enable_Address:"E ho'ā i ka helu wahi",
  Invalid_File_Type:"E kala mai, ua koho ʻia kekahi ʻano faila hewa ʻole. ʻAno waihona i kākoʻo ʻia:",
  Error_Updating_Entry:"E kala mai, ua hewa ka hoʻonui ʻana i kēia helu.",
  Schedule_Timing_Alert:"Hapa: Hoʻonohonoho ʻia ka manawa hoʻomaka i kahi waiwai ma hope o ka manawa pau.",
  Select_Multiple_Payments_Prompt:"ʻAʻohe uku i koho ʻia. E koho i hoʻokahi uku.",
  Select_Multiple_Cheques_Prompt:"ʻAʻohe māka i koho ʻia. E ʻoluʻolu e koho i hoʻokahi hōʻoia.",
  Select_Multiple_Items_Prompt:"ʻAʻohe mea i koho ʻia. E ʻoluʻolu e koho i hoʻokahi mea.",
  Paymemt_Multiple_Delete_Confirmation:"Makemake ʻoe e holoi i nā uku he nui?",
  Cheque_Multiple_Delete_Confirmation:"Makemake ʻoe e holoi i nā loiloi he nui?",
  Payee_Multiple_Delete_Confirmation:"Makemake ʻoe e holoi i nā mea uku he nui?",
  Employee_Multiple_Delete_Confirmation:"Makemake ʻoe e holoi i nā limahana lehulehu?",
  MICR_Warning:"'Ōlelo Aʻo: ʻAʻole hōʻike pololei paha kekahi mau mea paʻi a me nā mea hana i ka font MICR.",
  Automatically_Send:"Hoʻouna 'akomi",
  Type:"ʻAno",
  Payment_Type:"ʻAno Uku",
  Auto_Send:"Hoʻouna ʻakomi",
  Automatically_Process:"Hana 'akomi",
  Auto_Process:"Kaʻina Auto",
  Image_Based:"Ma muli o ke kiʻi",
  Font_Based:"Ma muli o ke kikokikona",
  Rerender:"Hāʻawi hou",
  Rendering:"Hāʻawi",
  Render:"Nā waihona",
  Top:"luna",
  Middle:"Waena",
  Bottom:"Malalo",
  Top_Left:"Hema hema",
  Top_Center:"Kikowaena luna",
  Top_Right:"ʻAkau ʻākau",
  Middle_Left:"Waena hema",
  Middle_Center:"Waena Waena",
  Middle_Right:"Waena akau",
  Bottom_Left:"Hema hema",
  Bottom_Center:"Kikowaena lalo",
  Bottom_Right:"Malalo akau",
  Numbers:"Heluhelu",
  Verified:"Hōʻoia",
  Paper_Size:"Nui Pepa",
  New_Device:"Mea Hou",
  Add_Device:"Pākuʻi Mea",
  Remove_Device:"Wehe i ka Mea Hana",
  Delete_Device:"Holoi Mea",
  Block_Device:"Paʻa Mea",
  Block:"Palaka",
  Unblock:"Wehe",
  Table:"Papakaukau",
  Scan_Login_Code:"E nānā i ke code komo ʻana",
  Login_Code:"Code komo",
  Scan_Code:"Code Scan",
  Scan_QR_Code:"E ʻimi i ke code QR",
  Select_All:"E koho i nā mea a pau",
  Deselect_All:"Wehe i ke koho a pau",
  Increase:"Hoʻonui",
  Decrease:"E emi iho",
  Bold:"wiwo ole",
  Text:"kikokikona",
  Style:"Kaila",
  Italic:"Ikaika",
  QR_Code:"QR Code",
  Barcode:"Barcode",
  Browse_Images:"Nānā Kiʻi",
  Browse_Files:"Huli i nā faila",
  Background_Image:"Kiʻi Kūʻē",
  Logo_Image:"Kiʻi Logo",
  Header_Image:"Kiʻi Poʻo",
  Bank_Image:"Kiʻi Panakō",
  Cut_Lines:"ʻOki Laina",
  Background:"Kāpae",
  License:"Laikini",
  One_License:"1 Laikini:",
  Licensed:"Laikini ia:",
  Not_Licensed:"ʻAʻole Laikini",
  Enter_Serial:"E komo i ka Serial",
  Serial_Key:"Kiʻi Serial",
  Serial:"Serial",
  Product_Key:"Kiʻi Huahana",
  Check_Product_Key:"E nānā i ke kī huahana",
  Add_Product_Key:"Hoʻohui i ke kī huahana",
  Verifying_Purchase:"Ke hōʻoia nei i ke kūʻai ʻana...",
  Print_Envelope:"Paʻi Envelope",
  Envelope:"Envelope",
  Thank_You:"Mahalo iā ʻoe!",
  Scale:"Kaulike",
  Print_Scale:"Paʻi Paʻi",
  Borders:"Nā palena",
  VOID:"PONO",
  Void_Cheque:"Hōʻoia Void",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"UKU I KE KAUOHA O:",
  NO_DOLLARS:"AOLE DALA ",
  ONE_DOLLAR:"HOOKAHI DALA",
  DOLLARS:" DALA",
  AND:" A ME ",
  CENTS:" KENETA.",
  NO_:"ʻAʻOLE ",
  ONE_:"ʻEKAHI ",
  AND_NO_:"A ʻAʻOLE ",
  _AND_ONE_:"A ME KEKAHI ",
  DOLLARS_AND_ONE_CENT:" A ME KENETA.",
  AND_NO_CENTS:" A ME NA KENETA.",
  CHEQUE_PRINT_DATE:"LA:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Nā hana mua",
  Inital_Setup:"Hoʻonohonoho mua",
  Welcome_To:"Aloha iā",
  Welcome:"Welina",
  Swipe:"Swipe",
  Intro_Setup:"Intro Setup",
  Introduction:"Hoʻolauna",
  CHEQUE_PRINT_CREDIT:"Powered by Cheques",
  Title_Intro_Word:"Welcome to Cheques",
  Title_Intro:"Hōʻike i nā hōʻoia",
  Title_Setup:"Ka hoʻonohonoho hōʻoia",
  PayPal_Default_Email_Message:"Ua loaʻa iā ʻoe kahi hoʻoili PayPal hou. [Powered by Cheques]",
  Cheques_App_Export:"Hoʻopuka ʻia e nā kāʻei",
  Post_Intro_Thanks:"Mahalo iā ʻoe no ke koho ʻana i nā ʻeke. Ua pau ke kaʻina hana hoʻonohonoho.",
  Post_Intro_Word:"E hoʻomaka ma ka paʻi ʻana i kāu helu helu mua, e hoʻohui i kahi uku e hiki mai ana, a i ʻole e hoʻohui i kahi limahana i ka uku uku.",
  Upgrade_Required:"Pono ʻo Cheques e loaʻa iā ʻoe kahi mana ʻoi aku ka maikaʻi o ka polokalamu i mea e hūnā ai i kēia memo a wehe i nā hiʻohiʻona hou.",
  Upgrade_Title:"Nā hōʻoia",
  Mailout_Prompt:"Hiki paha iā ʻoe ke koho e hoʻouna aku iā Checks i kāu mau loiloi uku no ʻoe.",
  Mailed_Cheque:"Hōʻike leka uila: ",
  Mailed_Cheque_Color:"Hoʻopaʻa Leka (Laula): ",
  Terms_Purchase:"Hoʻihoʻi piha ʻia nā kūʻai uila a pau me Checks a hiki i 30 mau lā mai ka lā kūʻai.",
  Dashboard_Setup:"E hoʻonohonoho i ka mea paʻi kiʻi mua",
  Dashboard_Add:"Hoʻohui i ka moʻokāki Bank Primary",
  Dashboard_Customize:"E koho i kahi hōʻoia hōʻoia",
  Dashboard_Job_Salary:"E hana i kāu hana a hoʻohui i kāu uku",
  Dashboard_Employees:"Hālama i nā limahana a me nā mea uku",
  Dashboard_Print:"E paʻi a leka i kāu mau uku uku",
  Dashboard_Payroll:"E hoʻopili i kāu paʻi uku uku",
  Dashboard_PayPal:"Hoʻonohonoho PayPal Payroll / Uku uku",
  Begin_Setup:"E hoʻomaka i ka hoʻonohonoho",
  Get_Started:"E hoʻomaka",
  Purchase:"kūʻai",
  Lockdown:"Lockdown",
  Unlock:"Wehe",
  Detailed:"Detail",
  Log_In:"Loaʻa",
  Login:"Login",
  Launch:"Hoʻokuʻu",
  Register:"Kakau inoa",
  Finish:"Hoʻopau",
  List:"Papa inoa",
  Weekends:"Nā hopena pule",
  Weekly:"Ka pule pule",
  PayPal_Default_Subject:"Ka uku hou",
  Payment_Message:"Memo Uku",
  PayPal_Default_Payment_Note:"Mahalo iā 'oe",
  Setup_Your_Cheqing_Account:"Hōʻike moʻokāki",
  Add_Your_Primary_Cheqing_Account:"Hoʻohui i kāu moʻokāki mua.",
  Welcome_Word:"E hoʻomaʻamaʻa a hoʻokaʻawale i ka uku uku a me ka hoʻokele waiwai kanaka.",
  Get_Started_Quickly:"E pane wale i kekahi mau nīnau maʻalahi e kōkua iā mākou e hoʻomaka...",
  Done_Intro_Word:"Paʻa ka hoʻonohonoho",
  PIN_Protected:"Paʻa ʻia ka ʻōlelo huna a me ka PIN",
  Enter_New_PIN:"E hoʻokomo i kahi code PIN hou:",
  Enter_PIN:"E hoʻokomo i ka PIN Code:",
  Invalid_PIN:"Ua hoʻokomo ʻia ka PIN hewa.",
  Account_Identifier:"Ka mea hōʻike moʻokāki",
  New_Account_Identifier:"Ka mea hōʻike moʻokāki hou",
  attempt:"ho'āʻo",
  attempts:"ho'āʻo",
  remaining:"koe",
  Language:"ʻŌlelo",
  languages:"'Ōlelo",
  select_languages:"E koho i ka ʻōlelo",
  Deposit:" Waihona",
  Hire_One_Now:"Hoolimalima i kekahi i keia manawa",
  App_Locked:"Loa ka palapala noi.",
  Skip_:"Holo",
  Skip_to_Dashboard:"Holo i ka papa kuhikuhi",
  Dashboard:"Papa kuhikuhi",
  Import:"Hoʻokomo",
  Admin:"Na Luna Hooponopono",
  New_Admin:"Admin Hou",
  Settings:"Nā hoʻonohonoho",
  Color_Picker:"Ka Mea koho kala",
  Font_Picker:"Ka Mea koho Font",
  Logout:"Lele",
  Close:"Polo",
  Close_menu:"Polo",
  Excel:"Faila Excel",
  Csv:"Faila CSV",
  Sql:"SQL File",
  Json:"Kōnae JSON",
  Url:"Kai mai ma ka URL",
  Back:"Hoʻi",
  Timers:"Nā manawa",
  Cheque:"Holo",
  Print:"Paʻi",
  Designs:"Nā Hoʻolālā",
  Pause_Printing:"Pause Paʻi",
  Resume_Printing:"E hoʻomau i ka paʻi ʻana",
  Printing_Paused:"Hoʻomaha ka paʻi ʻana",
  PrintingUnavailable:"E kala mai! ʻAʻole hiki ke paʻi ʻia ma kēia ʻōnaehana.",
  Prints_Paused:"Hoʻomaha ʻia nā paʻi",
  Administration:"Hoʻoponopono",
  Loading:"Ke hoʻouka nei",
  Unnamed:"Ka inoa ʻole",
  error:"E kala mai, ʻaʻole hiki ke wehe ʻia kēia māka no ka nānā ʻana.",
  No_Cheques_To_Print:"ʻAʻohe hōʻoia e paʻi",
  No_Cheques_To_Print_Word:"E hana i <a routerLink='/folder/Cheque/New'>Nā Palapala Hou</a>.",
  New_Cheque:"Hōʻike Hou",
  Start_One_Now:"E hoʻomaka i kahi i kēia manawa",
  Edit_Cheque:"Edit Check",
  Select_Cheques:"E koho i nā hōʻoia",
  Select_Employee:"E koho i ka limahana",
  Default_Printer:"Paʻi Paʻamau",
  Reassign:"Kau hou",
  Configure:"Hoʻonohonoho",
  Template:"Pahana",
  Designer:"mea hoʻolālā",
  Edit_Designs:"Hoʻoponopono Hoʻolālā",
  New_Design:"Hoʻolālā Hou",
  Next:"Next",
  Save:"E mālama",
  Name:"Inoa",
  Mail:"Mail",
  Amount:"Nui",
  Date:"La",
  PayPal:"PayPal",
  Payouts:"Ka uku",
  PayPal_Label:"PayPal Label",
  Email_Username:"Email / inoa mea hoʻohana",
  Client_ID:"ID mea kūʻai aku",
  Sandbox_Email:"Eka leka uila",
  PayPal_Email:"PayPal Email",
  PayPal_Username:"Inoa hoʻohana API",
  PayPal_Payouts:"PayPal uku",
  Select_PayPal_Key:"E koho i ke kī PayPal",
  Secret:"Huna",
  API_Secret:"API huna",
  PayPal_API_Keys:"PayPal kī",
  New_PayPal_Key:"Kī PayPal Hou",
  Email_Subject:"Ke kumuhana leka uila",
  Email_Message:"Memo leka uila",
  Payout_Options:"Nā koho uku",
  Payment_Note:"Payment Note",
  Enable_Employee:"E ho'ā i ka limahana",
  Enable_Production_Mode:"E ho'ā i ka hana hana",
  Sandbox_Username:"Pahu Sandbox Username",
  Sandbox_Signature:"Sandbox Signature",
  Sandbox_Password:"Pahu Sandbox",
  Production_Username:"Ka inoa hoʻohana hana",
  Production_Signature:"Ka inoa hana",
  Production_Password:"Hui hua'ōlelo hana",
  Production_Email:"Ka leka uila hana",
  API_Client_ID:"API Client ID",
  API_Signature:"Kakaulima API",
  API_Password:"API password",
  API_Username:"Inoa hoʻohana API",
  Secret_Key:"Kī huna",
  Sandbox:"Pahu Sand",
  Production:"Hoʻohua",
  Sandbox_Keys:"Nā kī pahu one",
  Production_Keys:"Kī Hoʻohua",
  API_Title:"API Title",
  Production_Mode:"Ke ano hana",
  Account_Label:"Lepa moʻokāki",
  No_PayPal_Setup:"ʻAʻohe kī PayPal",
  Enable_PayPal_Account:"E ho'ā i ka moʻokāki PayPal",
  No_PayPal_Word:"Hoʻohui i kāu <a routerLink='/folder/Invoice/New'>PayPal API Key</a>.",
  Printed_Memo:"Memo i paʻi ʻia",
  Employee:"Pahana",
  View_Employee:"Nānā i ka limahana",
  Mailing_Address:"Helu Leka",
  Company_Address:"Heluhelu Hui",
  Select_Company_Address:"E koho i ka helu wahi o ka hui",
  Address:"Address",
  Any_Day:"Kekahi lā",
  Address_Name:"Inoa helu wahi",
  Unit:"Uni",
  Account:"Moʻokāki",
  Bank_Account:"Bank moʻokāki",
  Account_Limits:"E ho'ā i nā palena moʻokāki",
  Payroll:"Ka uku uku",
  New_Payroll:"Ka uku uku hou",
  No_Payroll:"ʻAʻohe uku e hiki mai ana",
  Upcoming_Holiday:"Halima e hiki mai ana:",
  Invoice_Due:"Aia ka Invoice:",
  New_Invoice:"Invoice Hou",
  No_Invoices:"ʻAʻohe Invoice",
  No_Invoices_Word:"E hana i ka <a routerLink='/folder/Invoice/New'>Invoice</a> mua.",
  Cheque_Due:"Ka helu ʻana:",
  Payrolls:"Ka uku uku",
  Sandbox_Mode:"Ke ano pahu one",
  Hire:"Hoolimalima",
  Pay:"Uku",
  New:"mea hou",
  Add:"Hoʻohui",
  Make:"Make",
  Time:"Manawa",
  Write:"Kākau",
  Holiday:"Hoʻomaha",
  Holidays:"Hoʻomaha",
  Next_Holiday:"Hoʻomaha hoʻomaha aʻe:",
  All_Done:"Ua pau!",
  Employees:"Nā limahana",
  Payees:"Ka poe uku",
  Job:"Ioba",
  Jobs:"Nā hana",
  Invoice:"Invoice",
  Invoices:"Invoices",
  Vacations:"Hoʻomaha",
  Cheques:"Nā hōʻoia",
  Brand_Cheques:"Brand",
  Payment:"Uku",
  Enable_Payment:"E ho'ā i ka uku",
  Payments:"Uku",
  Schedule:"Papa kuhikuhi",
  Schedules:"Nā papahana",
  Timesheet:"Pepa manawa",
  Timesheets:"Pepa manawa",
  Salary:"Uku",
  New_Address:"Heluhelu hou",
  Address_2:"Heluhelu (Line 2)",
  _City:"Kulanakauhale",
  _State:"State/Prov",
  City:"Kulanakauhale / Kaona",
  State:"Ka Moku'āina / Moku'āina",
  Postal:"Ka Leka / ZIP Code",
  ZIP:"Leta / ZIP",
  Country:"ʻāina",
  Addresses:"Nā helu wahi",
  Required_Options:"Nā Koho Pono",
  Optional_Options:"Nā koho koho",
  Additional_Options:"Nā Koho Hou",
  Required:"Pono",
  Optional:"Koho",
  Additional:"Hoʻohui",
  No_Addresses:"ʻAʻohe helu wahi",
  New_Address_Word:"Hoʻohui i ka mua <a routerLink='/folder/Address/New'>Heluhelu</a>.",
  Select_Address:"E koho i ka helu wahi",
  No_Address:"ʻAʻohe helu wahi",
  Print_Cheque:"Mālama paʻi",
  Print_Cheque_Now:"E paʻi i kēia manawa",
  Description:"Ka wehewehe",
  Pay_To_The_Order_Of:"E uku i ke Kauoha o:",
  Select_Date_Range:"E koho i ka La La",
  Select_Starting_Date:"E koho i ka lā hoʻomaka",
  Select_Ending_Date:"E koho i ka lā hoʻopau",
  Select_Date:"E koho i ka lā",
  Cheque_Date:"Ka lā nānā",
  Cheque_Memo:"E nānā memo",
  Blank_Cheque:"Hōʻike Paʻaka",
  Blank:"Pakeha",
  No_Cheques:"ʻAʻohe hōʻoia",
  No_Cheques_Word:"E paʻi i kāu mua <a routerLink='/folder/Cheque/New'>Nānā</a>.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"Nānā Kiʻi",
  View:"Nānā",
  Modify:"Hoʻololi",
  Delete:"Holoi",
  Cheque_Paid:"Ukuia",
  New_Deduction:"Hoʻemi hou",
  Title:"Poʻo inoa",
  Frequency:"Frequency",
  Hourly:"I kēlā me kēia hola",
  Daily:"ʻO kēlā me kēia lā",
  Weekdays:"Nā lā pule",
  BiWeekly:"2 pule",
  TriWeekly:"3 pule",
  Monthly:"Mahina",
  MiMonthly:"2 mahina",
  Quarterly:"Kāhāhā",
  Yearly:"makahiki",
  Every_Week:"I kēlā me kēia pule",
  Every_Payroll:"ʻO kēlā me kēia uku uku",
  Every_Month:"I kēlā me kēia mahina",
  Annually:"I kēlā me kēia makahiki",
  Always_Scheduled:"Hoʻonohonoho mau ʻia",
  Start_Date:"La hoʻomaka",
  End_Date:"La Hoʻopau",
  Start_Time:"Wā hoʻomaka",
  End_Time:"Ka manawa hope",
  Deduction_Label:"Lepa Hoʻoemi",
  Notes:"Nā memo",
  Options:"Nā koho",
  Enable:"E ho'ā",
  Select_Deductions:"E koho i nā unuhi",
  Deductions:"Hoʻemi",
  No_Deductions:"ʻAʻohe Hoʻemi",
  No_Deductions_Word:"E hana i kāu <a routerLink='/folder/Deduction/New'>Wehewehe</a> mua.",
  New_Employee:"He limahana hou",
  No_Title:"ʻAʻohe inoa",
  _Name:"Inoa",
  About_Yourself:"E pili ana iā ʻoe iho",
  Full_Name:"Inoa piha",
  Birthday:"Lā hānau",
  Email:"Leka uila",
  SMS:"SMS",
  Phone:"Kelepona",
  Test:"Ho'āʻo",
  Call:"Kahea",
  Fax:"Fax",
  Printed_Note:"Memo i paʻi ʻia",
  Position:"Ke kūlana",
  Job_Position:"Ke kūlana hana",
  Select_a_Job:"E koho i kahi hana",
  Select_a_Salary:"E koho i ka uku",
  Add_a_Deduction:"Hoʻohui i kahi unuhi",
  Taxes:"ʻauhau",
  Add_Taxes:"Hoʻohui i nā ʻauhau",
  Date_of_Birth:"Lā hānau",
  Email_Address:"Helu leka uila",
  Phone_Number:"Helu kelepona",
  Phone_Call:"Kahea kelepona",
  Enable_on_Payroll:"E ho'ā i ka uku uku",
  Select_Employees:"E koho i nā limahana",
  No_Employees:"ʻAʻohe limahana",
  No_Employees_Word:"Hoʻohui i kāu mea hou <a routerLink='/folder/Employee/New'>Paahana</a>.",
  No_Name:"ʻAʻohe inoa",
  Unemployeed:"ʻAʻole hana",
  Employeed:"Hoʻohana",
  Paid:"Ukuia",
  Enabled:"Hāʻia",
  Disabled:"Hāʻole",
  Fire:"Ahi",
  Not_Available:"ʻAʻole i loaʻa",
  Not_Available_Word:"E paʻi i kāu <a routerLink='/folder/Invoice/New'>Invoice</a> mua a loaʻa ka uku.",
  Select_Invoices:"Select_Invoices",
  Print_Invoice_Word:"E paʻi i kāu <a routerLink='/folder/Invoice/New'>Invoice</a> mua a loaʻa ka uku.",
  Invoice_Title:"Inoa Invoice",
  Invoice_Date:"La Invoice",
  Due_Date:"Palena pau",
  New_Job:"Ka Hana Hou",
  Details:"Nā kiko'ī",
  Customize:"Hoʻopilikino",
  Customize_Dashboard:"Hoʻopilikino Dashboard",
  Components:"Nā mea",
  No_Components:"ʻAʻohe ʻāpana",
  Main_Components:"Nā Mea Nui",
  Smaller_Components:"Nā mea liʻiliʻi",
  Error_Loading_Page:"Hāhewa i ka hoʻouka ʻana i kēia ʻaoʻao.",
  Bank_Details:"Nā kiko'ī Bank",
  About_Your_Job:"E pili ana i kāu hana",
  Your_Schedule:"Kau Papa Hana",
  Job_Title:"Ka inoa hana",
  Job_Description:"Ka wehewehe hana",
  Job_Details:"Nili Hana",
  Enable_Job:"E ho'ā i ka hana",
  Pay_Period:"Ka wā uku",
  Perpetually_Schedule:"Hoʻolālā mau loa",
  Select_Jobs:"E koho i nā hana",
  No_Jobs:"ʻAʻohe hana",
  Add_Jobs:"Hoʻohui i nā hana",
  No_Jobs_Word:"Hoʻohui i ka <a routerLink='/folder/Job/New'>Job</a> mua i ka papa inoa.",
  Count_Employees:"job.employee_count+' Employees'",
  Zero_Employees:"0 na limahana",
  New_Leave:"Haalele Hou",
  Leave_Name:"Haʻalele i ka inoa",
  Paid_Leave:"Haalele uku",
  Leave_Pay:"Haalele i ka uku",
  Indefinite_Leave:"Haalele pau ole",
  Indefinite_Payroll:"Ka uku uku pau ole",
  Leave:"Haʻalele",
  Add_Schedules:"Hoʻohui i nā papa manawa",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"E ho'ā i ka haʻalele",
  Select_Leaves:"E koho i nā lau",
  No_Leaves:"ʻAʻohe lau o ka ʻole",
  Leave_Of_Absence:"Haʻalele ʻole",
  Leaves_Of_Absence:"Leaves of Absence",
  New_Leave_of_Absense:"Haalele hou o ka haʻalele",
  No_Leaves_Word:"Hoʻohui i ka mua <a routerLink='/folder/Leave/New'>Haalele</a>.",
  Not_Enabled:"ʻAʻole hiki ke hana",
  Absences:"Haalele",
  Payee:"Ka mea uku",
  New_Payee:"Ka mea uku hou",
  Payee_Identifier:"Identifier ka mea uku",
  Payee_Name:"Ka inoa o ka mea uku",
  Payee_Title:"Ka inoa o ka mea uku",
  Payment_Memo:"Memo Uku",
  Select_Payees:"E koho i nā mea uku",
  No_Payees:"ʻAʻohe mea uku",
  Add_Payee_Note:"Hoʻohui i ka mua <a routerLink='/folder/Payee/New'>Uku</a>.",
  New_Payees:"Nā mea uku hou",
  About_The_Payment_Schedule:"Ka Papa Hana Uku",
  Your_Payroll_Schedule:"Uku Aunoa",
  New_Payment:"Ka uku hou",
  Identifier:"mea hōʻike",
  Select:"E koho",
  Memo:"Memo",
  Payment_Date:"La uku",
  Mark_as_Paid:"E kaha e like me ka uku",
  Select_Payments:"E koho i nā uku",
  No_Payments:"ʻAʻohe uku",
  No_Payments_Word:"E hana i ka <a routerLink='/folder/Payment/New'>Uku</a> mua.",
  Create_Payroll:"E hana i ka uku uku",
  Properties:"Nā Waiwai",
  Payroll_Title:"Uku Uku",
  Payroll_Notes:"Nā palapala uku uku",
  Payroll_Setup:"Hoʻonohonoho uku",
  Tabulate_Payments:"E helu i nā uku",
  Tabulate:"Tabulate",
  By:"Na:",
  Payments_By:"Uku Na",
  Timesheets_And_Schedules:"Nā Pepa manawa a me nā papa manawa",
  Both:"ʻelua",
  Items:"Nā mea",
  Add_Payees:"Hoʻohui i nā mea uku",
  Add_Account:"Hoʻohui moʻokāki",
  Enable_Account:"E ho'ā i ka moʻokāki",
  Enable_Payroll:"E ho'ā i ka uku uku",
  Print_Payroll:"Pai i ka uku uku",
  No_Payrolls:"ʻAʻole uku uku",
  No_Payroll_Word:"E hana i kāu <a routerLink='/folder/Payroll/New'>Ka uku uku</a> mua.",
  View_more:"NANA HOU",
  Less:"MI",
  Add_Payroll:"Hoʻohui i ka uku uku",
  Select_Payroll:"E koho i ka uku uku",
  About_Your_Salary:"E pili ana i kāu uku",
  Add_Salaries:"Hoʻohui i nā uku",
  Add_Salary:"Hoʻohui i ka uku",
  Salaries:"Uku",
  No_Salaries:"ʻAʻohe uku",
  No_Salaries_Word:"Hoʻohui i ka mua <a routerLink='/folder/Salary/New'>Uku</a>.",
  Select_Salaries:"E koho i nā uku uku",
  New_Salary:"Uku hou",
  Salary_Name:"Identifier Uku",
  Enable_Salary:"E ho'ā i ka uku",
  Salary_Amount:"Ka nui o ka uku",
  New_Schedule:"Ka Papa Hana Hou",
  Schedule_Title:"Ka inoa papa inoa",
  Add_Address:"Hoʻohui i ka helu wahi",
  Repeat:"E hana hou",
  Design:"Hoʻolālā",
  Edit_Design:"Hoʻoponopono Hoʻolālā",
  Edit_this_Design:"Hoʻoponopono i kēia Hoʻolālā",
  Repeat_Payment:"Uku hou",
  Enable_Schedule:"E ho'ā i ka papahana",
  Never:"Aole loa",
  Select_Schedule:"E koho i nā papahana",
  No_Schedules:"ʻAʻohe papa manawa",
  No_Schedules_Word:"E hana i ka mua <a routerLink='/folder/Schedule/New'>Papa kuhikuhi</a>.",
  New_Administrator:"Ka Luna Hooponopono Hou",
  Username:"Inoa inoa",
  First_Name:"Inoa mua",
  Last_Name:"Inoa hope",
  Add_an_Address:"Hoʻohui i kahi helu wahi",
  Payment_Limit:"Ka palena o ka uku",
  Total_Limit:"Ka palena pau",
  Select_Accounts:"E koho i nā moʻokāki",
  No_Administrators:"ʻAʻohe Luna Hoʻokele",
  No_Administrators_Word:"E hana i ka mua <a routerLink='/folder/Administrator/New'>moʻokāki Admin</a>.",
  New_Administrators_Word:"Hoʻohui i ka mua <a routerLink='/folder/Administrator/New'>Luna hooponopono</a>",
  Cloud:"Kapua",
  Backup:"Paʻa",
  Enable_iCloud:"E ho'ā i ka iCloud",
  Enable_Google_Drive:"E ho'ā iā Google Drive",
  Enable_Microsoft_OneDrive:"E ho'ā iā Microsoft OneDrive",
  Automatically_Backup:"Hoʻihoʻi aunoa",
  FTP_Settings:"Nā hoʻonohonoho FTP",
  URL_File_Prompt:"E ʻoluʻolu e kuhikuhi i ka wahi no kahi faila .xls / .xlsx / .json e lawe mai ai:",
  URL_SQL_Prompt:"E ʻoluʻolu e kuhikuhi i kahi o ka faila SQLite e lawe mai ai:",
  FTP_Backup:"FTP Backup",
  FTP_Import:"Hoʻokomo FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Hoʻokipa",
  Port:"Awa",
  Path:"Alaala",
  Data_Path:"Alaʻikepili",
  Enable_FTP_Account:"E ho'ā i ka moʻokāki FTP",
  HostnameIP:"Inoa inoa",
  Password:"Hua huna",
  Connection_Port:"Port Connection",
  Enable_MySQL_Database:"E ho'ā i ka MySQL Database",
  Log:"Loaʻa",
  Reset:"Reset",
  Erase:"E holoi",
  Export:"Export",
  Database:"Ka waihona waihona",
  Upload_CSV:"Hoʻouka CSV",
  Download_CSV:"Hoʻoiho iā CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Hoʻihoʻi MySQL",
  Internal_Notes:"Memo Kuloko",
  Root_Path:"Ala Aʻa",
  Select_Backup:"E koho i ka waihona",
  Add_New_Backup:"Hoʻohui i kahi waihona hou",
  First_Backup:"E hoʻonohonoho i ka waihona mua...",
  Backups:"Nā waihona",
  Add_Backup:"Add Backup",
  No_Backups:"ʻAʻohe mea hoʻihoʻi e ʻike ʻia.",
  Select_Backup_Type:"E koho i ke ʻano o ka waihona āu e makemake ai e hoʻonohonoho...",
  Backup_Type:"Ke ʻano hoʻihoʻi",
  FTP_Drive:"FTP Drive",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Kaa",
  Microsoft_OneDrive:"Kaa",
  Import_Fields:"Mahele Lawe mai",
  Import_Fields_Word:"E hoʻohana i kēia ʻāpana e koho i <a routerLink='/folder/Holiday/New'>Lawe mai</a> data.",
  Upload:"Hoʻouka",
  Download:"Hoʻoiho",
  Download_JSON:"Hoʻoiho e like me ka ʻikepili JSON",
  Download_SQL:"Hoʻoiho ma ke ʻano he faila SQL",
  New_Bank:"Baneko hou",
  New_Account:"He moʻokāki hou",
  New_Bank_Account:"He waihona waihona hou",
  Upload_Image:"Hoʻouka Kiʻi",
  Bank_Name:"Inoa Panakō",
  Bank_Address:"Heluhelu Panakō",
  Branch_Address:"Helu lālā",
  Address_on_Cheque:"Address",
  Cheque_Numbers:"E nānā i nā helu",
  Cheque_Details:"E nānā i nā kiko'ī",
  Bank_Logo:"Loaʻa Bank",
  Cheque_ID:"E nānā i ka ID",
  Starting_Cheque_ID:"E hoʻomaka ana e nānā i ka ID",
  Transit_Number:"Helu Transit",
  Institution_Number:"Helu Hui",
  Designation_Number:"Helu koho",
  Account_Number:"Helu moʻokāki",
  Limits:"Nā palena",
  Default_Limits:"Ka palena paʻamau",
  Over_Limit:"Oi aku ka palena",
  Under_Limit:"Ma lalo o ka palena",
  Payroll_Limit:"Ka palena uku",
  Enable_Bank_Account:"E ho'ā i ka waihona kālā",
  Select_Account:"E koho i ka moʻokāki",
  No_Bank_Account:"ʻAʻohe Bank Account",
  No_Bank_Account_Word:"Hoʻohui i ka mua <a routerLink='/folder/Accounts/New'>Waihona Bank</a>.",
  Bank_Accounts:"Nā waihona kālā",
  No_Accounts:"ʻAʻohe moʻokāki",
  No_Accounts_Note:"Hoʻohui i ka mua <a routerLink='/folder/Accounts/New'>Waihona Bank</a>.",
  Cheque_Designer:"E nānā i ka mea hoʻolālā",
  Cheque_Design:"E nānā i ka hoʻolālā",
  Select_Design:"E koho i kahi hoʻolālā",
  Cheque_Designs:"E nānā i nā hoʻolālā",
  No_Cheque_Designs:"ʻAʻohe hoʻolālā hōʻoia",
  No_Cheque_Designs_Word:"E hana i kāu iho <a routerLink='/folder/Settings/Cheque/Design/New'>Nānā Hoʻolālā</a>.",
  Set_Default:"E hoʻonoho ma ke ʻano he paʻamau",
  Default:"Paʻamau",
  Remove:"Wehe",
  Folder:"Pūnaewele",
  Edit:"Hoʻoponopono",
  LoadingDots:"Ke hoʻouka nei...",
  Add_a_New_File:"Hoʻohui i kahi <a href='#' (click)='add()'>Faila Hou</a> iā",
  this_folder:"kēia waihona",
  FTP_Backup_Settings:"Nā hoʻonohonoho hoʻihoʻi FTP",
  Secure_File_Transfer:"Hoʻololi i nā faila palekana",
  New_Holiday:"Hoʻomaha Hou",
  Add_Holiday:"Add Holiday",
  Holiday_Name:"Inoa Hoʻomaha",
  Additional_Pay:"Uku hou",
  Enable_Holiday:"E ho'ā i ka lā hoʻomaha",
  Select_Holidays:"E koho i nā lā hoʻomaha",
  No_Holidays:"ʻAʻohe lā hoʻomaha",
  No_Holidays_Word:"Hoʻohui i ka mua <a routerLink='/folder/Holiday/New'>Hoomaha Aupuni</a>.",
  New_Import:"Hoʻokomo hou",
  Import_Data:"Ikepili lawe mai",
  Import_Data_Word:"ʻia.", 
  Import_File:"Hoʻokomo faila",
  Link_To_File:"Loaʻa i ka faila",
  Select_File:"E koho i ka faila",
  New_Moderator:"Hoʻoponopono Hou",
  Allow_Moderation:"E ʻae i ka hoʻohaʻahaʻa",
  Create_Paycheques:"E hana i nā uku uku",
  Edit_Paycheques_and_Data:"Hoʻoponopono i nā uku uku a me nā ʻikepili",
  Destroy_Data_and_Paycheques:"E hoʻopau i ka ʻikepili a me nā uku uku",
  Bonus_Percentage:"Paheneti uku",
  Fixed_Amount:"Nui Paʻa",
  Select_Moderator:"E koho i ka Moderator",
  No_Moderators:"ʻAʻohe mea hoʻoponopono",
  Moderators:"Mōderator",
  Moderator_Account:"E hana i ka mua <a routerLink='/folder/Administrator/New'>Moʻokāki Lunahooponopono</a>.",
  No_Moderators_Word:"Hoʻohui i ka mua <a routerLink='/folder/Administrator/New'>Lunahooponopono</a>.",
  Defaults:"Paʻamau",
  Provide_Us_Info:"E hāʻawi mai iā mākou i ka ʻike",
  Setup_Your_Printer:"E hoʻonohonoho i kāu mea paʻi",
  Your_Company:"E pili ana i kāu hui",
  Company_Name:"Inoa Hui",
  Currency:"Kālā",
  Default_Currency:"Kālā Paʻamau",
  Base_Monthly_Income:"Ka loaa o ka mahina",
  Protection:"Hoʻomalu",
  App_Protection:"Hoʻopalekana App",
  PIN_Code_Protection:"Ka palekana PIN Code",
  App_Protection_Word:"E ho'ā i nā ala palekana e kōkua i ka pale ʻana i kāu moʻokāki.",
  PIN_Code:"Kōpae PIN",
  Change_PIN:"Hoʻololi PIN",
  New_Password:"Hui huna hou",
  Geofence_Protection:"Paʻa Geo-Paʻa",
  Geofence_Area:"E hoʻonohonoho i kahi",
  Distance:"Ka mamao",
  Setup_Now:"Hoʻonohonoho i kēia manawa",
  Mile:"Mile",
  Km:"Km",
  m:"m",
  Facial_Recognition:"ʻIke Maka",
  Face:"He alo",
  Setup:"Hoʻonoho",
  Label:"Lepa",
  Password_Protection:"Paʻi ʻōlelo huna",
  Modify_Password:"Hoʻololi i ka ʻōlelo huna",
  New_Tax_Entry:"Ka Hookomo Auhau Hou",
  New_Tax:"Auhau Hou",
  Tax_Label:"Lepa ʻauhau",
  Perpetually_Enabled:"Hana mau loa",
  Select_Taxes:"E koho i nā ʻauhau",
  Tax_Deductions:"Hoemi Auhau",
  No_Tax_Deductions:"Aole Hoemi Auhau",
  No_Tax_Deductions_Word:"Hoʻohui i ka unuhi mua <a routerLink='/folder/Tax/New'>ʻauhau</a>.",
  New_Timer:"Ka manawa hou",
  Start:"Hoʻomaka",
  Stop:"Kuhi",
  Start_Timer:"Hoʻomaka manawa",
  Stop_Timer:"Hooki i ka manawa",
  Timer_Active:"Ka manawa hana",
  Timer:"Ka manawa:",
  Timer_Running:"Ka hola manawa: (Ke holo nei)",
  Save_Timer:"E mālama i ka manawa",
  New_Timesheet:"Pepa Manawa Hou",
  Select_Timesheets:"E koho i nā pepa manawa",
  Work_Notes:"Nā memo hana",
  Entry_Title:"Ka inoa komo",
  No_Timesheets:"ʻAʻohe Pepa Manawa",
  No_Timesheets_Word:"Hoʻohui i ka mua <a routerLink='/folder/Timesheet/New'>Manawa Pepa</a>.",
  Timesheet_Submitted:"Hoʻouna ʻia ka Pepa Manawa",
  Timesheet_Congrats:"Aloha ʻoe! Ua hoʻouna maikaʻi ʻia kāu Pepa manawa. Mahalo!",
  Timesheet_Copy:"No ka loaʻa ʻana o kahi kope o kāu mau palapala e ʻoluʻolu e hāʻawi mai iā mākou i kāu leka uila a/a i ʻole helu kelepona paʻalima.",
  Submit:"Hoʻouna",
  Allow_Notifications:"ʻAe i nā leka hoʻomaopopo",
  Untitled_Entry:"Komo Hou",
  Untitled_Bank:"Bakoʻa ʻole",
  Timesheet_Entry:"Ka helu helu manawa",
  Work_Description:"Ka wehewehe hana",
  Enable_Timesheet:"E ho'ā i ka Pepa Manawa",
  Submit_Timesheet:"Hoʻouna i ka pepa manawa",
  Vacation:"Hoʻomaha",
  New_Vacation:"Hoomaha Hou",
  Vacation_Name:"Inoa hoʻomaha",
  Paid_Vacation:"Uka hoomaha",
  Vacation_Pay:"Uku hoomaha",
  Enable_Vacation:"E ho'ā i ka hoʻomaha",
  Select_Vacations:"E koho i nā hoʻomaha",
  No_Vacations:"ʻAʻohe hoʻomaha",
  No_Vacations_Word:"E hana i ka helu mua <a routerLink='/folder/Vacation/New'>Vacation</a>.",
  Payroll_Schedule:"Ka Papa Hana Uku",
  Next_Payroll:"Ka uku uku aʻe:",
  Upcoming_Payroll:"Ka uku e hiki mai ana",
  No_Upcoming_Payroll:"ʻAʻohe uku e hiki mai ana",
  Address_Book:"Puke helu wahi",
  Archived_Documents:"Nā Palapala Hoʻopaʻa ʻia",
  Dev_Mode:"Ka noi ma ke ʻano hoʻomohala",
  Administrators:"Na Luna Hooponopono",
  Privacy:"Privacy",
  None:"ʻAʻole",
  Select_Signature:"E koho i ka inoa",
  No_Signatures:"ʻAʻohe pūlima",
  No_Signatures_Word:"Hoʻohui i ka mua <a routerLink='/folder/Signature/New'>Signature</a>.",
  Repeat_Indefinitely:"E hana hou no ka pau ole",
  Sign:"Hoailona",
  Sign_Here:"E kau inoa maanei",
  Date_Signed:"La i kau inoa",
  Signature_Pad:"Papa pūlima",
  Account_Holder:"Paʻa moʻokāki",
  Account_Properties:"Nā waiwai moʻokāki",
  Name_On_Cheque:"Inoa ma ke Kee",
  Server_Hostname:"Inoa Hoʻokele / IP",
  Printers:"Na mea pa'i",
  No_Printers:"ʻAʻohe mea paʻi",
  Printer_Exists:'Aia ka mea pa\'i ma keia inoa.',
  No_Printers_Word:"Hoʻohui i ka <a routerLink='/folder/Printer/New'>Mea paʻi</a> mua loa.",
  No_Printer_Alert:"ʻAʻohe mea paʻi i wehewehe ʻia. Makemake ʻoe e hoʻonohonoho i kahi paʻi?",
  Add_Printer:"Pākuʻi Paʻi",
  New_Printer:"Paʻi Paʻi Hou",
  Printer_Hostname:"Inoa hoʻokipa / IP",
  Printer_Label:"Lepa paʻi",
  Printer_Protocol:"Paʻi Paipalapala",
  Protocol:"Kūkākūkā",
  Email_Print:"Leka uila",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Socket",
  Print_Job:"Pprint Job",
  Printed:"Paʻi ʻia",
  Not_Printed:"Aole i paiia",
  Print_Jobs:"Paʻi Hana",
  Print_Queue:"Print Queue",
  No_Print_Jobs:"ʻAʻohe hana paʻi",
  No_Prints:"E hana i <a routerLink='/folder/Cheque/New'>Nānā</a> hou e paʻi.",
  Prints:"Paʻi",
  Find_Printer:"E ʻimi i ka mea paʻi",
  Find_AirPrint_Devices:"E ʻimi i nā polokalamu AirPrint",
  Select_Printer:"Select Printer",
  System_UI:"UI Pūnaehana",
  Printer:"Paʻi",
  Status:"Ke kūlana",
  Preview:"Preview",
  Enable_Print_Job:"E ho'ā i ka hana paʻi",
  Queue_Weight:"Ke kaumaha o ka pila",
  Unlimited:"Ka palena ʻole",
  Show_Advanced_Printer_Options:"Hōʻike i nā koho paʻi kiʻekiʻe",
  Advanced:"Kōmua",
  Location:"He wahi",
  Note:"Memo",
  Queue_Name:"Inoa Line",
  Pages_Printed_Limit:"Ka palena paʻi ʻia nā ʻaoʻao",
  MultiPage_Idle_Time:"Wā kali ʻaoʻao (mau)",
  Page_Count_Limit:"Ka palena helu ʻaoʻao",
  Page_Orientation:"Hōʻike ʻaoʻao",
  Portrait:"Kiʻi",
  Landscape:"Hoʻolālā",
  Duplex:"Duplex",
  Double_Sided_Printing:"ʻaoʻao ʻelua",
  Duplex_Mode:"Keʻano Duplex",
  Duplex_Short:"Pokole",
  Duplex_Long:"Lōʻihi",
  Duplex_None:"ʻAʻole",
  Color_And_Quality:"Ka waihoʻoluʻu a me ka maikaʻi",
  Monochrome:"Monochrome",
  Photo_Quality_Prints:"Photo Quality Prints",
  Printer_Email:"Eka leka uila",
  Automatically_Downsize:"Hoʻoemi Aunoa",
  Paper:"Pepa",
  Paper_Name:"Inoa Pepa",
  Paper_Width:"Ka Laulā Pepa",
  Paper_Height:"Kiʻekiʻe Pepa",
  Paper_Autocut_Length:"Ka lōʻihi oki 'akomi Pepa",
  Margins:"Margins",
  Page_Margins:"Ka palena ʻaoʻao",
  Page_Margin_Top:"Ka palena ʻaoʻao luna",
  Page_Margin_Right:"Ka palena ʻaoʻao ʻākau",
  Page_Margin_Bottom:"Ka palena ʻaoʻao lalo",
  Page_Margin_Left:"Ka palena ʻaoʻao hema",
  Add_Employees:"Hoʻohui i nā limahana",
  Header:"He poʻo",
  Print_A_Page_Header:"Print A Page Header",
  Header_Label:"Lepa Poʻo",
  Header_Page_Index:"Header Page Index",
  Header_Font_Name:"Header Font",
  Select_Font:"E koho Font",
  Font_Selector:"Ka Mea Koho Font",
  Header_Font_Size:"Header Font",
  Header_Bold:"Header Bold",
  Header_Italic:"Header Italic",
  Header_Alignment:"Hoʻoponopono poʻo",
  Left:"Hema",
  Center:"waena",
  Right:"Akau",
  Justified:"Aponoia",
  Header_Font_Color:"Laula Poʻo",
  Select_Color:"E koho i ke kala",
  Footer:"Waewae",
  Print_A_Page_Footer:"Print A Page Footer",
  Footer_Label:"Lepa wāwae",
  Footer_Page_Index:"Heluhelu ʻAoʻao Footer",
  Footer_Font_Name:"Footer Font",
  Fonts:"Pono",
  Done:"Ua pau",
  Select_Fonts:"E koho i nā Fonts",
  Footer_Font_Size:"Ka nui o ka wāwae",
  Footer_Bold:"Ka wāwae wāwae",
  Footer_Italic:"Wae'a Italic",
  Footer_Alignment:"Hoʻoholo wāwae",
  Footer_Font_Color:"Ka waihoʻoluʻu wāwae",
  Page_Copies:"Kope ʻAoʻao",
  Enable_Printer:"E ho'ā i ka mea paʻi",
  Remote_Logging:"Logging mamao",
  Log_Server:"Loaʻa Pūnaewele",
  Encryption:"Hoʻopili",
  Random_Key:"Kī Kuʻuna",
  Encryption_Key:"Kī Hoʻopili",
  Cheques_Webserver:"Kūnaewele maʻamau",
  Learn_How:"E aʻo pehea",
  Signature:"Kakaulima",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Nānā pūlima",
  Printed_Signature:"Ka inoa i paʻi ʻia",
  Digitally_Sign:"Kakaulima Kikohoʻe",
  Digital_Signature:"Ka inoa kikohoʻe",
  Add_Signature:"Hoʻohui pūlima",
  Add_Your_Signature:"Hoʻohui i kāu pūlima",
  Enable_Signature:"E ho'ā i ka pūlima",
  Digitally_Signed:"Kakaulima Kikohoʻe",
  Insert_Error:"ʻAʻole hiki ke mālama i ka nānā ma muli o nā pilikia waihona.",
  Delete_Confirmation:"Makemake paha ʻoe e holoi i kēia ʻike?",
  Discard_Confirmation:"Makemake paha ʻoe e hoʻolei i kēia ʻike?",
  Discard_Bank_Confirmation:"Makemake paha ʻoe e kāpae i kēia moʻokāki?",
  Discard_Printer_Confirmation:"Makemake paha ʻoe e hoʻolei i kēia mea paʻi?",
  Delete_Bonus_Confirmation:"Makemake paha ʻoe e holoi i kēia bonus?",
  Delete_Invoice_Confirmation:"Makemake paha ʻoe e holoi i kēia pepa pepa?",
  Generated_Cheque:"Hōʻike ʻia",
  Generated_Invoice:"Hanaina Invoice",
  Schedule_Start_Time:"Hoʻomaka Hoʻonohonoho",
  Schedule_End_Time:"Hoʻopau ka papa inoa",
  New_Call:"Kahea Hou",
  No_Contacts:"ʻAʻohe Hoʻokaʻaʻike",
  No_Contacts_Word:"ʻIke ʻia nā luna hoʻomalu, nā mea hoʻoponopono, nā limahana, a me nā mea uku e like me nā pilina.",
  PDF_Subject:"waiwai",
  PDF_Keywords:"nā ʻike helu PDF uku uku",
  Printer_Setup:"Hoʻonohonoho paʻi",
  Printer_Selection:"Koho Paʻi",
  New_Fax:"Fax Hou",
  New_Fax_Message:"Memo Fax Hou",
  Fax_Machine:"Mekini Fax",
  Fax_Name:"Inoa Fax",
  Fax_Email:"Fax Email",
  Fax_Number:"Helu Fax",
  Contents:"Nā maʻiʻo",
  Fax_Body:"Kino ʻAoʻao",
  Header_Word:"Poʻo:",
  Header_Text:"Header Text",
  Include_Header:"E hoʻokomo i ke poʻo",
  Include_Footer:"E hoʻokomo i ka wāwae",
  Footer_Word:"Waewae:",
  Footer_Text:"Ke kikokikona wawae",
  Attach_a_Cheque:"E hoʻopili i kahi leka",
  Add_Deduction:"Add Deduction",
  Enable_Fax:"Hoʻouna Fax",
  Select_Fax:"E koho i ka Fax",
  No_Faxes:"ʻAʻohe Fax",
  Faxes:"Faxes",
  Save_and_Send:"Hoʻouna Fax",
  Save_and_Pay:"E mālama a uku",
  WARNING:"'ĀLAI:",
  Remember:"E hoʻomanaʻo",
  Printing:"Paʻi",
  Printing_Complete:"Paʻi ʻia!\n\n",
  of:"o",
  There_Were:"Aia aku nei ",
  Successful_Prints:" nā paʻi holomua a me ",
  Unsuccessful_Prints:" nā paʻi pono ʻole.",
  PrinterError:"E kala mai! Ua hewa.",
  Printing_:"Ke paʻi ʻana...",
  PrinterSuccess:"Paʻi maikaʻi ʻia ka palapala.",
  PrintersSuccess:"Paʻi maikaʻi ʻia nā palapala.",
  Print_Cheques:"Nā Paʻi Paʻi",
  New_Message:"Memo Hou",
  New_Messages:"Nā memo hou",
  Read_Message:"Heluhelu i ka memo",
  Write_Message:"Kākau i ka memo",
  Send_Message:"Send Message",
  Subject:"Kumuhana",
  Message:"Memo",
  Writing:"Kākau ʻana...",
  Send:"Hoʻouna",
  Set_Date:"Hoʻonohonoho i ka lā",
  Set_Time:"Hoʻonohonoho i ka manawa",
  Recieve:"Loaʻa",
  Set_as_Default:"E hoʻonoho ma ke ʻano he paʻamau",
  Default_Account:"Moʻokāki Default",
  Default_Design:"Hoʻolālā Default",
  Multiple_Cheques:"Nā Nānā (Triple)",
  Account_Mode:"Keʻano moʻokāki",
  Multiple_Cheques_Description:"ʻEkolu nānā i kēlā me kēia ʻaoʻao.",
  Check_and_Table:"Nānā & Papa",
  Check_including_Table:"E nānā a me ka papa helu helu.",
  Check_Mailer:"E nānā i ka mea leka uila",
  Check_Mailer_Window:"E nānā me ka puka aniani helu wahi.",
  DocuGard_Table_Top:"DocuGard Nānā a me ka Papa (Ma luna)",
  DocuGard_Table_Middle:"DocuGard Nānā a me ka Papa (Waena)",
  DocuGard_Table_Bottom:"DocuGard Nānā a me ka Papa (Ma lalo)",
  DocuGard_Mailer_Top:"DocuGard Nānā Leka (Ma luna)",
  DocuGard_Mailer_Middle:"DocuGard Nānā Leka (Waena)",
  DocuGard_Mailer_Bottom:"DocuGard Nānā Leka (Ma lalo)",
  DocuGard_Three_Cheques:"Nā Nānā DocuGard (Triple)",
  DocuGard_Cheque_Top:"DocuGard Check (Ma luna)",
  DocuGard_Cheque_Middle:"DocuGard Check (Waena)",
  DocuGard_Cheque_Bottom:"Hōʻike DocuGard (lalo)",
  DocuGard_Three_Cheques_Window:"ʻEkolu nānā ma ka ʻaoʻao hoʻokahi.",
  DocuGard_Table_Top_Window:"Pakaukau hōʻoia a me ka loaʻa kālā.",
  DocuGard_Table_Middle_Window:"Pakaukau hōʻoia a me ka loaʻa kālā.",
  DocuGard_Table_Bottom_Window:"Pakaukau hōʻoia a me ka loaʻa kālā.",
  DocuGard_Mailer_Top_Window:"E nānā, papaʻaina, a me ka helu wahi.",
  DocuGard_Mailer_Middle_Window:"E nānā, papaʻaina, a me ka helu wahi.",
  DocuGard_Mailer_Bottom_Window:"E nānā, papaʻaina, a me ka helu wahi.",
  DocuGard_Cheque_Top_Window:"E nānā i ka pepa paʻa.",
  DocuGard_Cheque_Middle_Window:"E nānā i ka pepa paʻa.",
  DocuGard_Cheque_Bottom_Window:"E nānā i ka pepa paʻa.",
  Basic_Cheque:"E nānā (luna)",
  Basic_Cheque_Print:"E paʻi i hoʻokahi kope.",
  Error_Setting_As_Paid:"Hāhewa ka hoʻonohonoho ʻana e like me ka uku",
  Add_Attachment:"Add Attachment",
  PrinterUnavailable:"Aʻole loaʻa ka mea paʻi",
  CreditCardComponent:"Kaleka",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Ka Waihona Hou",
  Deposits:"Ka Waihona",
  No_Deposits:"ʻAʻohe waihona",
  Credit_Card_Deposit:"Kāleka kāki",
  New_Credit_Card_Deposit_Message:"Ka Waihona Kāleka Hoʻolilo",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Ka Waihona Bitcoin",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Hoʻololi Interac",
  Payments_Limit:"Ka palena uku",
  No_Payment_Limit:"ʻAʻohe palena uku",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal waiho kālā",
  No_Deposits_Word:"Hoʻohui i ka hui mua <a routerLink='/folder/Deposit/New'>Waihona</a>.",
  No_Documents_Specified:"ʻAʻohe palapala i kuhikuhi ʻia no ke paʻi ʻana",
  No_Printers_Added:"ʻAʻole i loaʻa nā mea paʻi. E hele i ka Settings > Printers to add one.",
  DB_Erase_Prompt:"E holoi loa i ka waihona waihona holoʻokoʻa? WARNING: E nalowale ana ʻoe i nā ʻike i mālama ʻia!",
  Console_Warning:"Mai hoʻopili i kekahi kikokikona i loko o kēia console. Hiki iā ʻoe ke hoʻokau iā ʻoe iho a/a i kāu hui i kahi pilikia koʻikoʻi.",
  No_Faxes_Word:"E hana i ka <a routerLink='/folder/Fax/New'>Fax</a> mua.",
  Cheque_Delete_Confirmation:"Makemake paha ʻoe e holoi i kēia hōkeo?",
  Design_Delete_Confirmation:"Makemake paha ʻoe e holoi i kēia hoʻolālā?",
  Cheque_Pay_Confirmation:"E hōʻailona i kēia helu i uku ʻia? ʻAʻole e ʻike ʻia ma ka pila paʻi.",
  Payment_Pay_Confirmation:"E hōʻailona i kēia uku e like me ka uku? ʻAʻole e ʻike ʻia ma ka pila helu.",
  Delete_Deduction_Confirmation:"Makemake paha ʻoe e holoi i kēia unuhi?",
  Delete_Job_Confirmation:"Makemake paha ʻoe e holoi i kēia hana?",
  Delete_Timesheet_Confirmation:"Makemake paha ʻoe e holoi i kēia pepa manawa?",
  Delete_Schedule_Confirmation:"Makemake paha ʻoe e holoi i kēia papahana?",
  Delete_Setting_Confirmation:"Makemake paha ʻoe e hoʻihoʻi hou i kēia hoʻonohonoho?",
  Delete_Fax_Confirmation:"Makemake anei ʻoe e holoi i kēia fax?",
  Delete_File_Confirmation:"Makemake paha ʻoe e holoi i kēia faila?",
  Delete_Vacation_Confirmation:"Makemake paha ʻoe e holoi i kēia hoʻomaha?",
  Delete_Printer_Confirmation:"Makemake paha ʻoe e holoi i kēia mea paʻi?",
  Remove_Design_Confirmation:"Makemake paha ʻoe e holoi i kēia hoʻolālā?",
  Delete_Payroll_Confirmation:"Makemake paha ʻoe e holoi i kēia uku uku?",
  Send_Fax_Email_Confirmation:"Makemake ʻoe e fax a leka uila i kēia mau palapala?",
  Send_Email_Confirmation:"Makemake ʻoe e leka uila i kēia palapala?",
  Send_Fax_Confirmation:"Makemake ʻoe e fax i kēia palapala?",
  Error_Generating_PDF:"E kala mai. Ua hewa ka hana ʻana i kēia palapala.",
  PDF_Error_Saving_Image:"E kala mai. Ua hewa ka mālama ʻana i ke kiʻi PDF o kēia palapala.",
  Test_Printer_Confirmation:"Makemake ʻoe e paʻi i kahi ʻaoʻao hoʻāʻo ma kēia paʻi?",
  Save_Timesheet_Prompt:"E ʻoluʻolu e hoʻohui i kahi 'Title' a i ʻole kaomi 'Start Timer' e mālama ai.",
  Remove_Geofence_Prompt:"Makemake paha ʻoe e wehe i kahi o kēia geo-pa?",
  Delete_Employee_Confirmation:"Makemake paha ʻoe e holoi ",
  Fire_Employee_Confirmation:"Makemake paha ʻoe e puhi"
}