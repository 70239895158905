export const Pt = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Direitos autorais &cópia; 2023",
  black:"Preto",
  green:"Verde",
  gold:"Ouro",
  blue:"Azul",
  brown:"Marrom",
  purple:"Roxo",
  pink:"Rosa",
  red:"Vermelho",
  Swatches:"Amostras",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Matiz",
  Saturation:"Saturação",
  Lightness:"Leveza",
  Upgrade_To_Pro:"Atualize para Pro",
  AllFeaturesInclude:"Todos os recursos incluem:",
  PrintUnlimitedCheques:"Imprimir cheques ilimitados",
  PremiumChequeDesigns:"Modelos de cheques premium",
  ManageUnlimitedEmployees:"Gerenciar funcionários ilimitados",
  AddUnlimitedPayees:"Adicionar Beneficiários Ilimitados",
  CreateUnlimitedPayrolls:"Crie folhas de pagamento ilimitadas",
  UnlimitedSchedulesandTimeSheets:"Horários Ilimitados e Folhas de Horário",
  BulkPayPalPayouts:"Pagamentos em massa do PayPal",
  UnlimitedBankAccounts:"Contas Bancárias Ilimitadas",
  ManageMultipleCompanies:"Gerenciar várias empresas",
  TrackInsurancePolicies:"Rastrear apólices de seguro",
  Bio_MetricProtection:"Proteção biométrica",
  Geo_FenceLock_OutProtection:"Proteção de Bloqueio de Geo-Fence",
  Multiple_Companies_Word:"O gerenciamento de várias empresas não está disponível sem o prêmio Cheques.",
  PayPal_Payouts_Word:"Os pagamentos do PayPal são desativados sem cheques premium.",
  PINProtection:"Proteção de PIN",
  PasswordProtection:"Proteção de senha",
  May_Require_Approval:"Pode exigir aprovação.",
  Subscribe:"Se inscrever",
  Billed:"Faturado",
  Up:"Acima",
  Down:"Abaixo",
  Positioning:"Posicionamento",
  Marker:"Marcador",
  Drag_Marker:"Marcador de arrastar",
  Tagline:"Imprima cheques e tabule a folha de pagamento",
  Marker_Word:"Use os marcadores para dimensionar o elemento.",
  Pinch_Zoom:"Apertar Zoom",
  Pinch_Word:"Aperte para ampliar o elemento.",
  Drag:"Arrastar",
  Drag_Word:"Use o dedo para arrastar os elementos.",
  subscription_alias_word:"Assinatura com renovação automática",
  premium_alias_word:"Pacote de atualização única",
  print_alias_word:"Cheques Individuais Impressos",
  mode_alias_word:"Modo",
  Pro:"Pró",
  Pro_word:"Versão Pro necessária.",
  Cant_Delete_Default_Company_Word:"Desculpe, você não pode excluir sua empresa padrão.",
  Reinsert_Default_Designs:"Reinserir designs padrão",
  Reinsert_Default_Designs_word:"Deseja reinserir designs padrão?",
  Subscription_Active_Disable_Word:"Esta assinatura está ativa. Deseja cancelar esta subscrição de Cheques?",
  Company_Logo:"logotipo da empresa",
  ZERO_:"ZERO",
  Disclaimer:"Isenção de responsabilidade",
  Privacy_Policy:"política de Privacidade",
  EULA:"Verifica o EULA",
  Terms_Of_Service:"Termos de serviço",
  Terms_Of_Use:"Termos de uso",
  Refunds:"Politica de reembolso",
  Single_Print:"1 Cheque",
  Two_Prints:"2 cheques",
  Five_Prints:"5 cheques",
  Ten_Prints:"10 cheques",
  Fifteen_Prints:"15 cheques",
  Twenty_Prints:"20 cheques",
  Thirty_Prints:"30 cheques",
  Image_Added:"Imagem adicionada",
  Image_Preview:"Pré-visualização de imagem",
  No_Image_Was_Selected:"Nenhuma imagem foi selecionada.",
  Cheques_Unlimited:"Cheques Ilimitados",
  _Subscription:"Inscrição",
  Subscription:"Cheques - 1 Mês",
  Two_Month_Subscription:"Cheques - 2 Meses",
  Three_Month_Subscription:"Cheques - 3 Meses",
  Six_Month_Subscription:"Cheques - 6 Meses",
  Twelve_Month_Subscription:"Cheques - 12 Meses",
  Cheques_Are_Available:"Os cheques estão disponíveis para impressão.",
  Cheques_Thank_You:"Muito obrigado por sua compra de Cheques! Suas compras agora estão ativas.",
  Upgrade_Required_Two:"Selecione um pacote e toque duas vezes no botão de preço para iniciar sua compra. Imprima cheques coloridos com aparência profissional em segundos.",
  Month:"Mês",
  Due:"Devido:",
  Expires:"Expira:",
  Subscription_Active:"Assinatura ativa",
  Subscription_Inactive:"Assinatura inativa",
  Purchase_Additional_Cheques:"Comprar cheques adicionais?",
  Printable_Cheque:"Cheque imprimível",
  Printable_Cheques:"Cheques imprimíveis",
  Printable_Cheque_Word:"cheque está disponível em sua conta.",
  Printable_Cheques_Word:"cheques estão disponíveis em sua conta.",
  Max_Amount_Message:"O valor que você especificou ultrapassou o valor máximo definido para este sistema:",
  Terms_Required_Word:"Você deve concordar com este acordo antes de continuar a usar Cheques.",
  Subscriptions:"Assinaturas",
  Product_Upgrades:"Atualizações",
  Mailed_Out_Cheques:"Cheques enviados pelo correio",
  Enter_A_Company_Name:"Insira o nome de uma empresa.",
  Single_Cheques:"Cheques Simples",
  Cheque_Golden:"cheque dourado",
  Cheque_Golden_Window:"Projeto de verificação dourada.",
  Cheque_Green:"cheque verde",
  Cheque_Green_Window:"Projeto de seleção verde.",
  Cheque_Red:"cheque vermelho",
  Cheque_Red_Window:"Projeto de verificação vermelha.",
  Cheque_Yellow:"Cheque Amarelo",
  Cheque_Yellow_Window:"Projeto de seleção amarela.",
  Cheque_Statue_of_Liberty:"Estátua da Liberdade",
  Cheque_Statue_of_Liberty_Window:"O desenho do cheque da Estátua da Liberdade.",
  Cheque_Green_Wave:"onda verde",
  Cheque_Green_Wave_Window:"Projeto de seleção verde.",
  Cheque_Golden_Weave:"trama dourada",
  Cheque_Golden_Weave_Window:"Elegante design de cheque dourado.",
  Cheque_Green_Sun:"sol verde",
  Cheque_Green_Sun_Window:"Design de cheque profundo e calmante.",
  Cheque_Blue_Checkers:"damas azuis",
  Cheque_Blue_Checkers_Window:"Projeto de verificação azul.",
  Cashiers_Check:"Cheque administrativo",
  Cashiers_Check_Window:"Modelo de estilo de cheque administrativo.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Projeto de seleção Aqua.",
  Cheque_Golden_Checkers:"Damas Douradas",
  Cheque_Golden_Checkers_Window:"Projeto de verificação dourada.",
  Upgrade_Unavailable:"Atualizações indisponíveis",
  Bank_Code_Protection:"Proteção do Número do Banco",
  Bank_Code_Protection_Word:"Proteja seus números bancários de serem usados neste aplicativo em execução em outro dispositivo ou para outro usuário. Esta ação é IRREVERSÍVEL. Continuar?",
  Bank_Code_Protection_Blocked_Word:"Os números bancários que você está tentando usar estão reservados para outro usuário ou dispositivo.",
  Bank_Code_Protection_Error_Word:"A verificação do número falhou. Conecte-se à Internet e tente adicionar esta conta bancária novamente.",
  Bank_Code_Protection_Set_Error_Word:"A proteção do número do banco requer que você esteja conectado à Internet. Conecte-se e tente novamente.",
  Upgrade_Unavailable_Word:"Desculpe, estamos tendo problemas para verificar você. No momento, as assinaturas e atualizações para Cheques não estão disponíveis para compra em sua área.",
  PayPal_Payment_Preview:"Pré-visualização de pagamento PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Selecione PayPal",
  PayPal_Applications:"Aplicativos do PayPal",
  Purchase_With_Apple_Pay:"Compre com Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Empresas",
  Insurance:"Seguro",
  New_PayPal:"Novo PayPal",
  Pay_By:"Pagar por",
  Insure:"Garantir",
  Miles:"milhas",
  Payment_Method:"Forma de pagamento",
  Select_Policies:"Selecionar Políticas",
  Policies:"Políticas",
  Policy:"Política",
  No_PayPal_Account:"Sem conta PayPal",
  No_Policies:"Sem apólices de seguro",
  New_Policy:"Nova política",
  PayPal_Payment:"Pagamento PayPal",
  PayPal_Payments:"Pagamentos PayPal",
  No_Payment_Selected:"Nenhum pagamento selecionado",
  Sending_Payment_Word:"Aguarde... Este pagamento está sendo enviado.",
  Sending_Payments_Word:"Aguarde... Os pagamentos estão sendo enviados.",
  No_Payment_Selected_PayPal:"Nenhum <a routerLink='/folder/Payments'>pagamento PayPal</a> selecionado para enviar.",
  Payment_Sent:"Pagamento enviado",
  PayPal_Payment_Sent:"Este pagamento foi enviado com PayPal.",
  Copay:"Copay",
  Dead:"Morto",
  Alive:"Vivo",
  Not_Dead:"Não Morto",
  Unclaimed:"não reclamado",
  Attempted:"Tentada",
  Deceased:"Morto",
  Claimed:"Reivindicado",
  Unpaid:"não pago",
  Sending_Payment:"Enviando Pagamento",
  Sending_Payments:"Envio de pagamentos",
  Send_PayPal_Confirmation:"Deseja enviar esta transação com PayPal?",
  Send_PayPal_Confirmation_Word:"Pressione o botão verde para enviar esta transação.",
  Save_Payment_As_Unpaid:"Salvar este pagamento como não pago?",
  Payment_Pay_Confirmation_PayPal:"Salvar este pagamento como pago?",
  No_Policies_Word:"Adicione a primeira <a routerLink='/folder/Postage/New'>Apólice de Seguro</a> agora.",
  Timesheet_Multiple_Delete_Confirmation:"Tem certeza de que deseja excluir vários quadros de horários?",
  Select_Multiple_Timesheets_Prompt:"Nenhum quadro de horários selecionado. Selecione pelo menos um quadro de horários.",
  Select_Multiple_Policies_Prompt:"Nenhuma política selecionada. Selecione pelo menos uma apólice de seguro.",
  Policies_Multiple_Delete_Confirmation:"Tem certeza de que deseja excluir várias políticas?",
  Company:"Empresa",
  Add_Company:"Adicionar empresa",
  New_Company:"Adicionar empresa",
  No_Companies:"Sem empresas",
  Enable_Company:"Ativar empresa",
  Select_Company:"Selecione a empresa",
  The_Default_Company:"O rótulo padrão da empresa.",
  Manage_Companies:"Gerenciar empresas",
  No_Companies_Word:"As verificações usarão uma empresa padrão.<br />Adicione a <a routerLink='/folder/Company/New'>primeira empresa</a>.",
  Default_Company:"Empresa Padrão",
  Cheques_Unlimited_Word:"Checks Unlimited permite que você imprima quantos cheques quiser.",
  Cheques_Subscription_Word:"Uma assinatura Cheques permite que você imprima quantos cheques quiser.",
  You_Own_This_Product:"Você possui este produto.",
  Your_Subscription_is_Active:"Sua assinatura está ativa.",
  Active_Products:"Produtos ativados",
  Purchase_Confirmation:"Comprar",
  Purchase_Cheques:"cheques de compra",
  Restore_Purchase:"Restaurar compras",
  Erase_Purchase:"Apagar compras",
  Successfully_Purchased:"Comprado com sucesso",
  Enter_Your_Licence_Key:"Insira sua chave de licença nesta página para ativar este produto.",
  Licence_Key:"Chave de licença",
  Enter_Licence_Key:"Insira chave da licença",
  Purchased:"Comprado",
  Enable_Feature:"Ativar recurso",
  Cancel_Subscription:"Cancelar assinatura",
  Subscription_Removed:"Assinatura removida",
  Select_Active_Subscription:"Selecione uma assinatura ativa para modificá-la.",
  Remove_Subscription_Word:"Tem certeza de que deseja cancelar esta assinatura?",
  Delete_Company_Confirmation:"Tem certeza de que deseja excluir toda esta empresa? AVISO: Você perderá todas as informações armazenadas!",
  Delete_Default_Company_Word:"Você não pode excluir a empresa padrão. Gostaria de redefinir o aplicativo e restaurá-lo ao estado padrão? AVISO: Você perderá todas as informações armazenadas!",
  Console_Warning_2:"Não manuseie nenhuma moeda usando este aplicativo que não seja seu no momento.",
  Terms_and_Conditions:"Termos e Condições",
  and_the:"e a",
  for:"para",
  Please_Read_Word:"Por favor, leia e concorde com os",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Algumas taxas de conversão de moeda não foram encontradas. Conecte-se à Internet.",
  Free:"Livre",
  DB_Erase_Prompt_2:"Apagar completamente todo o banco de dados do desenvolvedor? AVISO: Você perderá todas as informações de COMPRA e ASSINATURA!",
  Successfully_Imported:"Importado com sucesso",
  Select_Postage:"Selecione postagem",
  No_Postage:"Sem selos postais",
  No_Paid_Postage_Word:"Adicione o primeiro selo <a routerLink='/folder/Postage/New'>Post Postal</a>.",
  Trial_Complete:"Avaliação concluída",
  Please_Upgrade:"Atualize os cheques para continuar imprimindo.",
  Aa:"aa",
  Color:"Cor",
  Font:"Fonte",
  Guide:"Guia",
  Guides:"Guias",
  Image:"Imagem",
  Zoom:"Ampliação",
  Logo:"Logotipo",
  Bank:"Banco",
  MICR:"MICR",
  Total:"Total",
  Cancel:"Cancelar",
  Confirm:"confirme",
  Method:"Método",
  Biometric_Security:"Segurança biométrica",
  Please_Login_To_Continue:"Por favor faça o login para continuar.",
  Complete:"Completo",
  Sign_Up:"Inscrever-se",
  Error:"Erro",
  Biometrics:"biometria",
  Percent:"Por cento",
  Zero_Percent:"0%",
  Top_Margin:"Margem superior",
  Bottom_Margin:"Margem inferior",
  Left_Margin:"Margem esquerda",
  Right_Margin:"Margem direita",
  MICR_Margin:"Margem MICR",
  Table_Margin:"Margem da Tabela",
  Address_Margin:"Margem de endereço",
  Percentage_:"Percentagem",
  Vacation_Title:"Título de férias",
  Use_PIN:"Usar PIN",
  Loading__:"Carregando...",
  Design_Title:"Título do projeto",
  Authorize:"Autorizar",
  Key:"Chave",
  Public_Key:"Chave pública",
  Private_Key:"Chave privada",
  Authenticate:"Autenticar",
  Last_Payroll:"última folha de pagamento",
  Last_Calculation:"último cálculo",
  Authorized:"Autorizado",
  Geo_Authorized:"Geo-localização: autorizado",
  Not_Authorized:"Não autorizado",
  Authorization_Complete:"Autorização Completa",
  Geolocation_Authorization:"Autorização de localização geográfica",
  Out_of_Bounds:"Fora dos limites",
  Cant_Delete_Default_Design:"Não é possível excluir um design padrão.",
  Unauthorized_Out_of_Bounds:"Não autorizado: fora dos limites",
  Biometric_Authorization:"Autorização biométrica",
  Locating_Please_Wait:"Localizando, aguarde...",
  Test_Biometrics:"Biometria de teste",
  Cheque_Margins:"Verificar Margens",
  Percentage_Full_Error:"O campo de porcentagem não pode ser definido acima de 100%.",
  No_Payroll_Text:"Adicione um <a routerLink='/folder/Employee/New'>Funcionário</a> ou <a routerLink='/folder/Payee/New'>Beneficiário</a> e um <a routerLink='/folder/Schedule /Novo'>Cronograma</a>.",
  Design_Saved:"Projeto salvo",
  Default_Design_Selected:"Design Padrão Selecionado",
  Partial_Import:"Importação Parcial",
  Partial_Export:"Exportação Parcial",
  Entire_Import:"Importação inteira",
  Entire_Export:"Exportação inteira",
  Existing_Password:"Por favor, digite sua senha existente:",
  Existing_PIN:"Digite seu código PIN existente:",
  Pin_Change_Header:"Confirmação de PIN",
  Pin_Change_SubHeader:"Digite seu número PIN antigo para confirmar a alteração.",
  Pass_Change_Header:"ConfirmaÇão Da Senha",
  Pass_Change_SubHeader:"Digite sua senha antiga para confirmar a alteração.",
  PIN_Enter_Message:"Digite seu PIN para confirmar.",
  Password_Enter_Message:"Digite sua senha para confirmar.",
  Pin_Updated_Success:"PIN atualizado com sucesso!",
  Pin_Updated_Fail:"O PIN não pôde ser atualizado.",
  Pass_Updated_Success:"Senha atualizada com sucesso.",
  Pass_Updated_Fail:"A senha não pôde ser atualizada.",
  Preview_Payment:"Visualizar pagamento",
  Preview_Payroll:"Visualizar folha de pagamento",
  No_Payments_Created:"Nenhum pagamento foi encontrado para ser criado.",
  Payment_Preview:"visualização de pagamento",
  Enable_Payee:"Ativar Beneficiário",
  Rendered:"Renderizado",
  No_Email:"Sem e-mail",
  Setup_Cheques:"Verificações de configuração",
  name:"Nome",
  address:"Endereço",
  address_2:"endereço linha 2",
  city:"Cidade",
  province:"Província",
  postal_code:"Código postal",
  country:"País",
  username:"Nome de usuário",
  full_name:"Nome completo",
  birthday:"Aniversário",
  email:"E-mail",
  phone:"Telefone",
  employees:"Funcionários",
  addresses:"Endereços",
  payment_amount_limit:"Limite do valor do pagamento",
  total_limit:"Limite total",
  payees:"Beneficiários",
  description:"Descrição",
  address_line_one:"Endereço Linha Um",
  address_line_two:"Endereço Linha Dois",
  image:"Imagem",
  account_holder:"titular da conta",
  cheque_starting_id:"Verifique a ID inicial",
  transit_number:"Número de trânsito",
  institution_number:"Número da Instituição",
  designation_number:"Número de designação",
  account_number:"Número de conta",
  currency:"Moeda",
  signature:"Assinatura",
  payment_payroll_limit:"Limite de pagamento",
  total_limi:"limite total",
  date:"Data",
  printed_memo:"Memorando Impresso",
  banks:"bancos",
  signature_image:"Imagem de Assinatura",
  address_name:"Nome do endereço",
  notes:"Notas",
  design:"Projeto",
  title:"Título",
  frequency:"Frequência",
  fax:"Fax",
  salaries:"Salários",
  salary_ids:"IDs de salário",
  start_time:"hora de início",
  end_time:"Fim do tempo",
  paid:"Pago",
  overtime_percentage:"Porcentagem Paga",
  overtime_amount:"Valor Fixo Pago",
  first_name:"Primeiro nome",
  last_name:"Sobrenome",
  moderation:"Moderação",
  create:"Criar",
  edit:"Editar",
  destroy:"Destruir",
  day_start_time:"Day_start_time",
  day_end_time:"Day_end_time",
  fullname:"Nome",
  time:"Tempo",
  auto_send:"Enviar automaticamente",
  time_method:"Método de tempo",
  schedules:"Horários",
  indefinite_payroll_enabled:"Ativar Indefinidamente",
  amount:"Quantia",
  repeat:"Repita",
  always_enabled:"Sempre ativado",
  start_date:"Data de início",
  end_date:"Data final",
  Cheque_Total:"Verificar total",
  Total_Amount:"Montante total:",
  Amounts:"Valores:",
  Images:"Imagens",
  Files:"arquivos",
  Previewing:"Visualizando:",
  Insert:"Inserir",
  Preview_Import:"Visualizar Importação",
  Entry:"Entrada",
  Entries:"entradas",
  No_Entries:"Sem Entradas",
  Import_Type:"Tipo de Importação",
  Select_Details:"Selecione Detalhes",
  Select_Payee:"Selecione Beneficiário",
  Enable_Holidays:"Ativar feriados",
  Disable_Holidays:"Desativar feriados",
  Wire_Transfer:"Transferência bancária",
  New_Export:"Nova exportação",
  Export_Data:"Exportar dados",
  Export_Data_Word:"Selecione o tipo de arquivo para exportar e baixar.",
  Export_File:"Exportar arquivo",
  Mode:"Modo",
  Times:"Horários",
  Widgets:"Widgets",
  Slider:"Controle deslizante",
  Set_Details:"Definir detalhes",
  Select_Type:"Selecione o tipo",
  Display_Slider:"Controle deslizante de exibição",
  Dashboard_Slider:"Controle deslizante do painel",
  Dashboard_Mode:"Modo Painel",
  Show_Intro:"Mostrar introdução",
  Show_Payrolls:"Mostrar folhas de pagamento",
  Show_Holidays:"Mostrar feriados",
  Show_Invoices:"Mostrar faturas",
  Show_Cheques:"Mostrar cheques",
  Enabled_Widgets:"Widgets ativados",
  Disabled_Widgets:"Widgets desativados",
  Colors:"cores",
  Barcodes:"Códigos de barra",
  View_Timers:"Ver temporizadores",
  Gradients:"Gradientes",
  No_Info:"Nenhuma informação",
  Disable:"Desativar",
  Show_Layer:"Mostrar Camadas",
  Hide_Layer:"Ocultar Camadas",
  Text_Layer:"Camadas de texto",
  Secondly:"em segundo lugar",
  Minutely:"Minuciosamente",
  nine_am:"9:00 DA MANHÃ",
  five_pm:"17:00",
  Enable_Address:"Ativar endereço",
  Invalid_File_Type:"Desculpe, um tipo de arquivo inválido foi selecionado. Tipo de arquivo suportado:",
  Error_Updating_Entry:"Desculpe, houve um erro ao atualizar esta entrada.",
  Schedule_Timing_Alert:"Erro: A hora de início do agendamento está definida para um valor após a hora de término.",
  Select_Multiple_Payments_Prompt:"Nenhum pagamento selecionado. Selecione pelo menos um pagamento.",
  Select_Multiple_Cheques_Prompt:"Nenhuma verificação selecionada. Selecione pelo menos um cheque.",
  Select_Multiple_Items_Prompt:"Nenhum item selecionado. Selecione pelo menos um item.",
  Paymemt_Multiple_Delete_Confirmation:"Tem certeza de que deseja excluir vários pagamentos?",
  Cheque_Multiple_Delete_Confirmation:"Tem certeza de que deseja excluir vários cheques?",
  Payee_Multiple_Delete_Confirmation:"Tem certeza de que deseja excluir vários beneficiários?",
  Employee_Multiple_Delete_Confirmation:"Tem certeza de que deseja excluir vários funcionários?",
  MICR_Warning:"Nota: Algumas impressoras e dispositivos podem não exibir a fonte MICR corretamente.",
  Automatically_Send:"Enviar automaticamente",
  Type:"Tipo",
  Payment_Type:"Tipo de pagamento",
  Auto_Send:"Envio Automático",
  Automatically_Process:"Processar Automaticamente",
  Auto_Process:"Processo Automático",
  Image_Based:"Baseado em imagem",
  Font_Based:"Baseado em fonte",
  Rerender:"Re-renderizar",
  Rendering:"Renderização",
  Render:"arquivos",
  Top:"Principal",
  Middle:"Meio",
  Bottom:"Fundo",
  Top_Left:"Canto superior esquerdo",
  Top_Center:"Centro Superior",
  Top_Right:"Canto superior direito",
  Middle_Left:"Meio Esquerdo",
  Middle_Center:"centro médio",
  Middle_Right:"meio direito",
  Bottom_Left:"Inferior esquerdo",
  Bottom_Center:"Central inferior",
  Bottom_Right:"Canto inferior direito",
  Numbers:"Números",
  Verified:"Verificado",
  Paper_Size:"Tamanho do papel",
  New_Device:"Novo dispositivo",
  Add_Device:"Adicionar Dispositivo",
  Remove_Device:"Remover dispositivo",
  Delete_Device:"Excluir dispositivo",
  Block_Device:"dispositivo de bloqueio",
  Block:"Bloquear",
  Unblock:"Desbloquear",
  Table:"Mesa",
  Scan_Login_Code:"Escanear código de login",
  Login_Code:"Código de acesso",
  Scan_Code:"Escanear código",
  Scan_QR_Code:"Escanear código QR",
  Select_All:"Selecionar tudo",
  Deselect_All:"Desmarcar todos",
  Increase:"Aumentar",
  Decrease:"Diminuir",
  Bold:"Audacioso",
  Text:"Texto",
  Style:"Estilo",
  Italic:"itálico",
  QR_Code:"Código QR",
  Barcode:"código de barras",
  Browse_Images:"Procurar Imagens",
  Browse_Files:"Procurar arquivos",
  Background_Image:"Imagem de fundo",
  Logo_Image:"Imagem do logotipo",
  Header_Image:"Imagem de cabeçalho",
  Bank_Image:"Imagem do Banco",
  Cut_Lines:"Linhas de corte",
  Background:"Fundo",
  License:"Licença",
  One_License:"1 Licença:",
  Licensed:"Licenciado para:",
  Not_Licensed:"Não licenciado",
  Enter_Serial:"Digite o número de série",
  Serial_Key:"Chave serial",
  Serial:"Serial",
  Product_Key:"Chave do produto",
  Check_Product_Key:"Verifique a chave do produto",
  Add_Product_Key:"Insira a chave do produto",
  Verifying_Purchase:"Verificando compra...",
  Print_Envelope:"imprimir envelope",
  Envelope:"Envelope",
  Thank_You:"Obrigado!",
  Scale:"Escala",
  Print_Scale:"Escala de impressão",
  Borders:"Fronteiras",
  VOID:"VAZIO",
  Void_Cheque:"Cheque vazio",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"PAGAR PARA A ORDEM DE:",
  NO_DOLLARS:"SEM DÓLARES",
  ONE_DOLLAR:"UM DÓLAR",
  DOLLARS:"DÓLARES",
  AND:"E",
  CENTS:"CENTAVOS.",
  NO_:"NÃO",
  ONE_:"UM",
  AND_NO_:"E NÃO",
  _AND_ONE_:"E UM",
  DOLLARS_AND_ONE_CENT:"E UM CENTAVO.",
  AND_NO_CENTS:"E ZERO CENTAVOS.",
  CHEQUE_PRINT_DATE:"DATA:",
  CHEQUE_PRINT_MEMO:"MEMORANDO:",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Tarefas Iniciais",
  Inital_Setup:"Configuração inicial",
  Welcome_To:"Bem-vindo ao",
  Welcome:"Bem-vindo",
  Swipe:"Deslize",
  Intro_Setup:"Configuração de introdução",
  Introduction:"Introdução",
  CHEQUE_PRINT_CREDIT:"Alimentado por Cheques",
  Title_Intro_Word:"Bem-vindo a Cheques",
  Title_Intro:"Cheques Introdução",
  Title_Setup:"Verifica a configuração",
  PayPal_Default_Email_Message:"Você recebeu uma nova transferência do PayPal.",
  Cheques_App_Export:"Exportado por Cheques",
  No_Prints_Purchased:"Nenhuma impressão comprada",
  No_Prints_Purchased_Word:"Nenhuma impressão adicional está disponível. Por favor, compre cheques adicionais.",
  Post_Intro_Thanks:"Obrigado por escolher Cheques. O processo de configuração está agora completo.",
  Post_Intro_Word:"Comece imprimindo seu primeiro cheque, adicione um pagamento futuro ou adicione um funcionário à folha de pagamento.",
  Upgrade_Required:"As verificações exigem que você possua uma versão mais premium do software para ocultar esta mensagem e desbloquear recursos adicionais.",
  Upgrade_Title:"Verificações",
  Mailout_Prompt:"Além disso, você pode optar por enviar cheques por correio para seus cheques de folha de pagamento.",
  Mailed_Cheque:"Cheque enviado pelo correio",
  Mailed_Cheque_Color:"Cheque Enviado (Cor)",
  Terms_Purchase:"Todas as compras eletrônicas com Cheques são totalmente reembolsáveis por até 30 dias a partir da data da compra. Por favor, leia e concorde com os",
  Dashboard_Setup:"Configurar a impressora principal",
  Dashboard_Add:"Adicionar a conta bancária principal",
  Dashboard_Customize:"Selecione um modelo de cheque",
  Dashboard_Job_Salary:"Crie seu trabalho e adicione seu salário",
  Dashboard_Employees:"Rastrear funcionários e beneficiários",
  Dashboard_Print:"Imprima e envie seus contracheques",
  Dashboard_Payroll:"Automatize a impressão da sua folha de pagamento",
  Dashboard_PayPal:"Configurar folha de pagamento/pagamentos do PayPal",
  Begin_Setup:"Iniciar configuração",
  Get_Started:"Iniciar",
  Purchase:"Comprar",
  Lockdown:"Confinamento",
  Unlock:"desbloquear",
  Detailed:"Detalhado",
  Log_In:"Conecte-se",
  Login:"Conecte-se",
  Launch:"Lançar",
  Register:"Registro",
  Finish:"Terminar",
  List:"Lista",
  Weekends:"Finais de semana",
  Weekly:"Semanalmente",
  PayPal_Default_Subject:"Novo Pagamento",
  Payment_Message:"Mensagem de pagamento",
  PayPal_Default_Payment_Note:"Obrigado",
  Setup_Your_Cheqing_Account:"Conta corrente",
  Add_Your_Primary_Cheqing_Account:"Adicione sua conta corrente principal.",
  Welcome_Word:"Simplifique e automatize a folha de pagamento e o gerenciamento de recursos humanos.",
  Get_Started_Quickly:"Basta responder a algumas perguntas simples que nos ajudarão a começar...",
  Done_Intro_Word:"Configuração concluída",
  PIN_Protected:"Protegido por senha e PIN",
  Enter_New_PIN:"Digite um novo código PIN:",
  Enter_PIN:"Insira o código PIN:",
  Invalid_PIN:"PIN inválido digitado.",
  Account_Identifier:"Identificador de conta",
  New_Account_Identifier:"Novo identificador de conta",
  attempt:"tentar",
  attempts:"tentativas",
  remaining:"restante",
  Language:"Linguagem",
  languages:"línguas",
  select_languages:"Selecione o idioma",
  Deposit:"Depósito",
  Hire_One_Now:"Contrate um agora",
  App_Locked:"O aplicativo está bloqueado.",
  Skip_:"Pular",
  Skip_to_Dashboard:"Pular para o painel",
  Dashboard:"Painel",
  Import:"Importar",
  Admin:"Administradores",
  New_Admin:"Novo Administrador",
  Settings:"Configurações",
  Color_Picker:"Seletor de cores",
  Font_Picker:"Seletor de fonte",
  Logout:"Sair",
  Close:"Fechar",
  Close_menu:"Fechar",
  Excel:"Arquivo Excel",
  Csv:"Arquivo CSV",
  Sql:"Arquivo SQL",
  Json:"Arquivo JSON",
  Url:"Importar por URL",
  Back:"Voltar",
  Timers:"Temporizadores",
  Cheque:"Verificar",
  Print:"Imprimir",
  Designs:"Projetos",
  Pause_Printing:"Pausar impressão",
  Resume_Printing:"Retomar impressão",
  Printing_Paused:"Impressão pausada",
  PrintingUnavailable:"Desculpe! A impressão não está disponível neste sistema.",
  Prints_Paused:"Impressões pausadas",
  Administration:"Administração",
  Loading:"Carregando",
  Unnamed:"Sem nome",
  error:"Desculpe, este cheque não pôde ser aberto para visualização.",
  No_Cheques_To_Print:"Sem cheques para imprimir",
  No_Cheques_To_Print_Word:"Crie um <a routerLink='/folder/Cheque/New'>Novo Cheque</a>.",
  New_Cheque:"Novo Cheque",
  Start_One_Now:"Comece um agora",
  Edit_Cheque:"Editar Cheque",
  Select_Cheques:"Selecionar Cheques",
  Select_Employee:"Selecione Funcionário",
  Default_Printer:"Impressora padrão",
  Reassign:"reatribuir",
  Configure:"configurar",
  Template:"Modelo",
  Designer:"Designer",
  Edit_Designs:"Editar designs",
  New_Design:"Novo design",
  Next:"Próximo",
  Save:"Salvar",
  Name:"Nome",
  Mail:"Correspondência",
  Amount:"Quantia",
  Date:"Data",
  PayPal:"PayPal",
  Payouts:"Pagamentos",
  PayPal_Label:"Etiqueta do PayPal",
  Email_Username:"E-mail / Nome de usuário",
  Client_ID:"ID do Cliente",
  Sandbox_Email:"E-mail Sandbox",
  PayPal_Email:"Email do Paypal",
  PayPal_Username:"Nome de usuário da API",
  PayPal_Payouts:"Pagamentos do PayPal",
  Select_PayPal_Key:"Selecione a chave do PayPal",
  Secret:"Segredo",
  API_Secret:"Segredo da API",
  PayPal_API_Keys:"Chaves do PayPal",
  New_PayPal_Key:"Nova chave do PayPal",
  Email_Subject:"Assunto do email",
  Email_Message:"Mensagem de email",
  Payout_Options:"Opções de pagamento",
  Payment_Note:"Nota de pagamento",
  Enable_Employee:"Ativar Funcionário",
  Enable_Production_Mode:"Ativar modo de produção",
  Sandbox_Username:"Nome de usuário da caixa de areia",
  Sandbox_Signature:"Assinatura Sandbox",
  Sandbox_Password:"Senha do Sandbox",
  Production_Username:"Nome de usuário de produção",
  Production_Signature:"Assinatura de Produção",
  Production_Password:"Senha de produção",
  Production_Email:"E-mail de produção",
  API_Client_ID:"ID do cliente da API",
  API_Signature:"Assinatura da API",
  API_Password:"Senha da API",
  API_Username:"Nome de usuário da API",
  Secret_Key:"Chave secreta",
  Sandbox:"Caixa de areia",
  Production:"Produção",
  Sandbox_Keys:"Chaves da caixa de areia",
  Production_Keys:"Chaves de produção",
  API_Title:"Título da API",
  Production_Mode:"Modo de produção",
  Account_Label:"Rótulo da conta",
  No_PayPal_Setup:"Sem chave do PayPal",
  Enable_PayPal_Account:"Ativar conta do PayPal",
  No_PayPal_Word:"Adicione sua <a routerLink='/folder/Invoice/New'>Chave de API do PayPal</a>.",
  Printed_Memo:"Memorando Impresso",
  Employee:"Funcionário",
  View_Employee:"Ver Funcionário",
  Mailing_Address:"Endereço para correspondência",
  Company_Address:"Endereço da companhia",
  Select_Company_Address:"Selecione o endereço da empresa",
  Address:"Endereço",
  Any_Day:"Qualquer dia",
  Address_Name:"Nome do endereço",
  Unit:"Unidade",
  Account:"Conta",
  Bank_Account:"Conta bancária",
  Account_Limits:"Ativar limites de conta",
  Payroll:"Folha de pagamento",
  Run:"Correr",
  Run_Payroll:"Executar folha de pagamento",
  New_Payroll:"Nova folha de pagamento",
  No_Payroll:"Nenhuma folha de pagamento futura",
  Upcoming_Holiday:"Próximos feriados:",
  Invoice_Due:"Vencimento da Fatura:",
  New_Invoice:"Nova Fatura",
  No_Invoices:"Sem Faturas",
  No_Invoices_Word:"Crie a primeira <a routerLink='/folder/Invoice/New'>Fatura</a>.",
  Cheque_Due:"Cheque Vencido:",
  Payrolls:"Folhas de pagamento",
  Sandbox_Mode:"modo caixa de areia",
  Hire:"Contratar",
  Pay:"Pagar",
  New:"Novo",
  Add:"Adicionar",
  Make:"Fazer",
  Time:"Tempo",
  Write:"Escrever",
  Holiday:"Feriado",
  Holidays:"Feriados",
  Next_Holiday:"Próximo feriado:",
  All_Done:"Tudo feito!",
  Employees:"Funcionários",
  Payees:"Beneficiários",
  Job:"Trabalho",
  Jobs:"Empregos",
  Invoice:"Fatura",
  Invoices:"Faturas",
  Vacations:"Férias",
  Cheques:"Verificações",
  Brand_Cheques:"A tua marca",
  Payment:"Pagamento",
  Enable_Payment:"Ativar pagamento",
  Payments:"Pagamentos",
  Schedule:"Agendar",
  Schedules:"Horários",
  Timesheet:"Planilha de horário",
  Timesheets:"Folhas de Ponto",
  Salary:"Salário",
  New_Address:"Novo endereço",
  Address_2:"Endereço linha 2)",
  _City:"Cidade",
  _State:"Estado/Província",
  City:"Cidade / Município",
  State:"Estado / Província",
  Postal:"Código postal",
  ZIP:"Postal / CEP",
  Country:"País",
  Addresses:"Endereços",
  Required_Options:"Opções Necessárias",
  Optional_Options:"Opções Opcionais",
  Additional_Options:"Opções adicionais",
  Required:"Obrigatório",
  Optional:"Opcional",
  Additional:"Adicional",
  No_Addresses:"Sem endereços",
  New_Address_Word:"Adicione o primeiro <a routerLink='/folder/Address/New'>Endereço</a>.",
  Select_Address:"Selecione o endereço",
  No_Address:"Sem endereços",
  Print_Cheque:"Imprimir Cheque",
  Print_Cheques:"Imprimir Cheques",
  Print_Cheque_Now:"Imprimir cheque agora",
  Description:"Descrição",
  Pay_To_The_Order_Of:"Pagar para a ordem de:",
  Select_Date_Range:"Selecione o intervalo de datas",
  Select_Starting_Date:"Selecione a data de início",
  Select_Ending_Date:"Selecione a data final",
  Select_Date:"Selecione a data",
  Cheque_Date:"Data de verificação",
  Cheque_Memo:"Verifique memorando",
  Blank_Cheque:"Cheque em branco",
  Blank:"Em branco",
  No_Cheques:"sem cheques",
  No_Cheques_Word:"Imprima seu primeiro <a routerLink='/folder/Cheque/New'>Verificar</a>.",
  Cheque_Amount_Placeholder:"$ 0,00",
  View_Image:"Ver imagem",
  View:"Visualizar",
  Modify:"Modificar",
  Delete:"Excluir",
  Cheque_Paid:"Pago",
  New_Deduction:"Nova Dedução",
  Title:"Título",
  Frequency:"Frequência",
  Hourly:"De hora em hora",
  Daily:"Diário",
  Weekdays:"Dias da semana",
  BiWeekly:"2 semanas",
  TriWeekly:"3 semanas",
  Monthly:"Por mês",
  MiMonthly:"2 meses",
  Quarterly:"Trimestral",
  Yearly:"Anual",
  Every_Week:"Toda semana",
  Every_Payroll:"Cada folha de pagamento",
  Every_Month:"Todo mês",
  Annually:"Anualmente",
  Always_Scheduled:"Sempre agendado",
  Start_Date:"Data de início",
  End_Date:"Data final",
  Start_Time:"hora de início",
  End_Time:"Fim do tempo",
  Deduction_Label:"Etiqueta de Dedução",
  Notes:"Notas",
  Options:"Opções",
  Enable:"Habilitar",
  Select_Deductions:"Selecione Deduções",
  Deductions:"Deduções",
  No_Deductions:"sem deduções",
  No_Deductions_Word:"Crie sua primeira <a routerLink='/folder/Deduction/New'>Dedução</a>.",
  New_Employee:"Novo empregado",
  No_Title:"sem título",
  _Name:"Nome",
  About_Yourself:"Sobre si mesmo",
  Full_Name:"Nome completo",
  Birthday:"Aniversário",
  Email:"E-mail",
  SMS:"SMS",
  Phone:"Telefone",
  Test:"Teste",
  Call:"Chamar",
  Fax:"Fax",
  Printed_Note:"Nota Impressa",
  Position:"Posição",
  Job_Position:"Cargo",
  Select_a_Job:"Selecione um trabalho",
  Select_a_Salary:"Selecione um salário",
  Add_a_Deduction:"Adicionar uma dedução",
  Taxes:"impostos",
  Add_Taxes:"Adicionar impostos",
  Date_of_Birth:"Data de nascimento",
  Email_Address:"Endereço de email",
  Phone_Number:"Número de telefone",
  Phone_Call:"Chamada telefónica",
  Enable_on_Payroll:"Ativar na folha de pagamento",
  Select_Employees:"Selecione Funcionários",
  No_Employees:"Sem Funcionários",
  No_Employees_Word:"Adicione seu primeiro novo <a routerLink='/folder/Employee/New'>Funcionário</a>.",
  No_Name:"sem nome",
  Unemployeed:"Desempregado",
  Employeed:"Empregado",
  Paid:"Pago",
  Enabled:"Habilitado",
  Disabled:"Desabilitado",
  Fire:"Fogo",
  Not_Available:"Não disponível",
  Not_Available_Word:"Imprima sua primeira <a routerLink='/folder/Invoice/New'>Fatura</a> e receba o pagamento.",
  Select_Invoices:"Selecione Faturas",
  Print_Invoice_Word:"Imprima sua primeira <a routerLink='/folder/Invoice/New'>Fatura</a> e receba o pagamento.",
  Invoice_Title:"Título da fatura",
  Invoice_Date:"Data da fatura",
  Due_Date:"Data de vencimento",
  New_Job:"Novo emprego",
  Details:"Detalhes",
  Customize:"Customizar",
  Customize_Dashboard:"Personalizar painel",
  Components:"Componentes",
  No_Components:"Sem Componentes",
  Main_Components:"Componentes principais",
  Smaller_Components:"Componentes menores",
  Error_Loading_Page:"Erro ao carregar esta página.",
  Bank_Details:"Detalhes bancários",
  About_Your_Job:"Sobre o seu trabalho",
  Your_Schedule:"Sua programação",
  Job_Title:"Cargo",
  Job_Description:"Descrição do trabalho",
  Job_Details:"Detalhes do trabalho",
  Enable_Job:"Ativar trabalho",
  Pay_Period:"Período de pagamento",
  Perpetually_Schedule:"Cronograma perpétuo",
  Select_Jobs:"Selecionar trabalhos",
  No_Jobs:"Sem empregos",
  Add_Jobs:"Adicionar trabalhos",
  No_Jobs_Word:"Adicione o primeiro <a routerLink='/folder/Job/New'>Trabalho</a> à lista.",
  Count_Employees:"job.employee_count+' Empregados'",
  Zero_Employees:"0 Empregados",
  New_Leave:"Nova Licença",
  Leave_Name:"Deixar nome",
  Paid_Leave:"Licença remunerada",
  Leave_Pay:"Deixar de Pagar",
  Indefinite_Leave:"Licença Indefinida",
  Indefinite_Payroll:"Folha de Pagamento Indefinida",
  Leave:"Deixar",
  Add_Schedules:"Adicionar horários",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Habilitar Ausência",
  Select_Leaves:"Selecione Folhas",
  No_Leaves:"Sem licenças",
  Leave_Of_Absence:"Licença",
  Leaves_Of_Absence:"Ausências",
  New_Leave_of_Absense:"Nova Licença",
  No_Leaves_Word:"Adicione a primeira <a routerLink='/folder/Leave/New'>Licença</a>.",
  Not_Enabled:"Não habilitado",
  Absences:"ausências",
  Payee:"Beneficiário",
  New_Payee:"Novo Beneficiário",
  Payee_Identifier:"Identificador do Beneficiário",
  Payee_Name:"Nome do Beneficiário",
  Payee_Title:"Título do Beneficiário",
  Payment_Memo:"Nota de pagamento",
  Select_Payees:"Selecione Beneficiários",
  No_Payees:"Nenhum Beneficiário",
  Add_Payee_Note:"Adicione o primeiro <a routerLink='/folder/Payee/New'>Beneficiário</a>.",
  New_Payees:"Novos Beneficiários",
  About_The_Payment_Schedule:"Agenda de pagamentos",
  Your_Payroll_Schedule:"Folha de pagamento automática",
  New_Payment:"Novo Pagamento",
  Identifier:"identificador",
  Selected:"Selecionado",
  Select:"Selecione",
  Memo:"Memorando",
  Payment_Date:"Data de pagamento",
  Mark_as_Paid:"Marcar como pago",
  Select_Payments:"Selecione Pagamentos",
  No_Payments:"Sem Pagamentos",
  No_Payments_Word:"Crie o primeiro <a routerLink='/folder/Payment/New'>Pagamento</a>.",
  Create_Payroll:"Criar folha de pagamento",
  Properties:"Propriedades",
  Payroll_Title:"Título da folha de pagamento",
  Payroll_Notes:"Notas de folha de pagamento",
  Payroll_Setup:"Configuração da folha de pagamento",
  Tabulate_Payments:"Tabular Pagamentos",
  Tabulate:"Tabular",
  By:"Por:",
  Payments_By:"Pagamentos por",
  Timesheets_And_Schedules:"Folhas de Horário e Programações",
  Both:"Ambos",
  Items:"Unid",
  Add_Payees:"Adicionar Beneficiários",
  Add_Account:"Adicionar Conta",
  Enable_Account:"Ativar conta",
  Enable_Payroll:"Ativar folha de pagamento",
  Print_Payroll:"Imprimir folha de pagamento",
  No_Payrolls:"Sem folha de pagamento",
  No_Payroll_Word:"Crie sua primeira <a routerLink='/folder/Payroll/New'>Folha de pagamento</a>.",
  View_more:"VEJA MAIS",
  Less:"MENOS",
  Add_Payroll:"Adicionar folha de pagamento",
  Select_Payroll:"Selecione Folha de Pagamento",
  About_Your_Salary:"Sobre o seu salário",
  Add_Salaries:"Adicionar Salários",
  Add_Salary:"Adicionar Salário",
  Salaries:"Salários",
  No_Salaries:"Sem Salário",
  No_Salaries_Word:"Adicione o primeiro <a routerLink='/folder/Salary/New'>Salário</a>.",
  Select_Salaries:"Selecione Salários",
  New_Salary:"Novo Salário",
  Salary_Name:"Identificador de salário",
  Enable_Salary:"Ativar Salário",
  Salary_Amount:"Salário",
  New_Schedule:"Novo cronograma",
  Schedule_Title:"Título do cronograma",
  Add_Address:"Adicionar endereço",
  Repeat:"Repita",
  Design:"Projeto",
  Edit_Design:"Editar desenho",
  Edit_this_Design:"Editar este desenho",
  Repeat_Payment:"Repita o pagamento",
  Enable_Schedule:"Ativar agendamento",
  Never:"Nunca",
  Select_Schedule:"Selecione Horários",
  No_Schedules:"Sem Horários",
  No_Schedules_Word:"Crie a primeira <a routerLink='/folder/Schedule/New'>Agenda</a>.",
  New_Administrator:"Novo administrador",
  Username:"Nome de usuário",
  First_Name:"Primeiro nome",
  Last_Name:"Sobrenome",
  Add_an_Address:"Adicionar um endereço",
  Payment_Limit:"Limite por pagamento",
  Total_Limit:"Limite total",
  Select_Accounts:"Selecionar contas",
  No_Administrators:"Sem administradores",
  No_Administrators_Word:"Crie a primeira <a routerLink='/folder/Administrator/New'>Admin Account</a>.",
  New_Administrators_Word:"Adicione o primeiro <a routerLink='/folder/Administrator/New'>Administrador</a>",
  Cloud:"Nuvem",
  Backup:"Cópia de segurança",
  Enable_iCloud:"Ativar iCloud",
  Enable_Google_Drive:"Ativar o Google Drive",
  Enable_Microsoft_OneDrive:"Habilitar Microsoft OneDrive",
  Automatically_Backup:"Backup automático",
  FTP_Settings:"Configurações de FTP",
  URL_File_Prompt:"Especifique o local para importar um arquivo .xls / .xlsx / .json:",
  URL_SQL_Prompt:"Especifique a localização do arquivo SQLite a ser importado:",
  FTP_Backup:"Backup FTP",
  FTP_Import:"Importação FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Hospedar",
  Port:"Porta",
  Path:"Caminho",
  Data_Path:"Caminho de Dados",
  Enable_FTP_Account:"Ativar conta FTP",
  HostnameIP:"Nome de anfitrião",
  Password:"Senha",
  Connection_Port:"Porta de Conexão",
  Enable_MySQL_Database:"Ativar banco de dados MySQL",
  Log:"Registro",
  Reset:"Reiniciar",
  Erase:"Apagar",
  Export:"Exportar",
  Database:"Base de dados",
  Upload_CSV:"Carregar CSV",
  Download_CSV:"Baixar CSV",
  SQL_Database:"URL SQL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Backup do MySQL",
  Internal_Notes:"Notas Internas",
  Root_Path:"Caminho Raiz",
  Select_Backup:"Selecione backup",
  Add_New_Backup:"Adicionar um novo backup",
  First_Backup:"Configurar o primeiro backup...",
  Backups:"backups",
  Add_Backup:"Adicionar backup",
  No_Backups:"Não há backups a serem encontrados.",
  Select_Backup_Type:"Selecione o tipo de backup que você gostaria de configurar...",
  Backup_Type:"Tipo de backup",
  FTP_Drive:"Unidade FTP",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Dirigir",
  Microsoft_OneDrive:"Dirigir",
  Import_Fields:"Importar campos",
  Import_Fields_Word:"Use esta seção para selecionar <a routerLink='/folder/Holiday/New'>Importar</a> dados.",
  Upload:"Carregar",
  Download:"Download",
  Download_JSON:"Baixar como dados JSON",
  Download_SQL:"Baixar como arquivo SQL",
  New_Bank:"novo banco",
  New_Account:"Nova conta",
  New_Bank_Account:"Nova Conta Bancária",
  Upload_Image:"Enviar Imagem",
  Bank_Name:"Nome do banco",
  Bank_Address:"Endereço do banco",
  Branch_Address:"Endereço da Filial",
  Address_on_Cheque:"Endereço",
  Cheque_Numbers:"Números de verificação",
  Cheque_Details:"Verificar detalhes",
  Bank_Logo:"logotipo do banco",
  Cheque_ID:"Verificar ID",
  Starting_Cheque_ID:"ID do cheque inicial",
  Transit_Number:"Número de trânsito",
  Institution_Number:"Número da Instituição",
  Designation_Number:"Número de designação",
  Account_Number:"Número de conta",
  Limits:"Limites",
  Default_Limits:"Limites padrão",
  Over_Limit:"Acima do limite",
  Under_Limit:"Abaixo do limite",
  Payroll_Limit:"Limite da folha de pagamento",
  Enable_Bank_Account:"Ativar conta bancária",
  Select_Account:"Selecione a conta",
  No_Bank_Account:"Sem conta bancária",
  No_Bank_Account_Word:"Adicione a primeira <a routerLink='/folder/Accounts/New'>Conta bancária</a>.",
  Bank_Accounts:"Contas bancárias",
  No_Accounts:"Sem contas",
  No_Accounts_Note:"Adicione a primeira <a routerLink='/folder/Accounts/New'>Conta bancária</a>.",
  Cheque_Designer:"Verifique Designer",
  Cheque_Design:"Verifique o projeto",
  Select_Design:"Selecione um desenho",
  Cheque_Designs:"Verifique os designs",
  No_Cheque_Designs:"Sem Verificar Projetos",
  No_Cheque_Designs_Word:"Crie seu próprio <a routerLink='/folder/Settings/Cheque/Design/New'>Design de Cheque</a>.",
  Set_Default:"Definir como padrão",
  Default:"Padrão",
  Remove:"Remover",
  Folder:"Pasta",
  Edit:"Editar",
  LoadingDots:"Carregando...",
  Add_a_New_File:"Adicione um <a href='#' (clique)='add()'>Novo arquivo</a> para",
  this_folder:"esta pasta",
  FTP_Backup_Settings:"Configurações de Backup de FTP",
  Secure_File_Transfer:"Transferência segura de arquivos",
  New_Holiday:"Novo feriado",
  Add_Holiday:"Adicionar Feriado",
  Holiday_Name:"Nome do feriado",
  Additional_Pay:"Pagamento Adicional",
  Enable_Holiday:"Ativar feriado",
  Select_Holidays:"Selecione feriados",
  No_Holidays:"sem feriados",
  No_Holidays_Word:"Adicione o primeiro <a routerLink='/folder/Holiday/New'>Feriado</a>.",
  New_Import:"Nova Importação",
  Import_Data:"Importar dados",
  Import_Data_Word:"Selecione o tipo de arquivo ou método de upload de sua escolha.<br /> Você poderá selecionar quaisquer campos importados em um arquivo que correspondam a qualquer parâmetro no aplicativo depois de carregar um arquivo suportado.",
  Import_File:"Importar arquivo",
  Link_To_File:"Link para arquivo",
  Select_File:"Selecione o arquivo",
  New_Moderator:"Novo moderador",
  Allow_Moderation:"Permitir moderação",
  Create_Paycheques:"Criar cheques de pagamento",
  Edit_Paycheques_and_Data:"Editar contracheques e dados",
  Destroy_Data_and_Paycheques:"Destruir dados e cheques de pagamento",
  Bonus_Percentage:"Porcentagem do Salário",
  Fixed_Amount:"Quantia fixa",
  Select_Moderator:"Selecionar moderador",
  No_Moderators:"Sem moderadores",
  Moderators:"Moderadores",
  Moderator_Account:"Crie a primeira <a routerLink='/folder/Administrator/New'>Conta de moderador</a>.",
  No_Moderators_Word:"Adicione o primeiro <a routerLink='/folder/Administrator/New'>Moderador</a>.",
  Defaults:"Padrões",
  Provide_Us_Info:"Fornecer informações",
  Setup_Your_Printer:"Configure sua impressora",
  Your_Company:"Sobre sua empresa",
  Company_Name:"nome da empresa",
  Currency:"Moeda",
  Default_Currency:"Moeda padrão",
  Base_Monthly_Income:"Renda Mensal",
  Protection:"Proteção",
  App_Protection:"Proteção de aplicativo",
  PIN_Code_Protection:"Proteção de código PIN",
  App_Protection_Word:"Habilite métodos de segurança que ajudam a proteger sua conta.",
  PIN_Code:"Código PIN",
  Change_PIN:"Alterar PIN",
  New_Password:"Nova Senha",
  Geofence_Protection:"Proteção de cerca geográfica",
  Geofence_Area:"Definir área",
  Distance:"Distância",
  Setup_Now:"Configurar agora",
  Mile:"Milha",
  Km:"km",
  m:"m",
  Facial_Recognition:"Reconhecimento facial",
  Face:"Face",
  Setup:"Configurar",
  Label:"Rótulo",
  Password_Protection:"Proteção de senha",
  Modify_Password:"Modificar senha",
  New_Tax_Entry:"Nova entrada de imposto",
  New_Tax:"Novo imposto",
  Tax_Label:"Etiqueta Fiscal",
  Perpetually_Enabled:"Perpetuamente Ativado",
  Select_Taxes:"Selecione impostos",
  Tax_Deductions:"Dedução de taxas",
  No_Tax_Deductions:"Sem deduções fiscais",
  No_Tax_Deductions_Word:"Adicione a primeira dedução <a routerLink='/folder/Tax/New'>Imposto</a>.",
  New_Timer:"Novo Temporizador",
  Start:"Começar",
  Stop:"Parar",
  Start_Timer:"Iniciar Temporizador",
  Stop_Timer:"Parar Temporizador",
  Timer_Active:"Temporizador ativo",
  Timer:"Cronômetro:",
  Timer_Running:"Temporizador: (em execução)",
  Save_Timer:"Salvar Temporizador",
  New_Timesheet:"Nova folha de ponto",
  Select_Timesheets:"Selecione planilhas de tempo",
  Work_Notes:"Notas de trabalho",
  Entry_Title:"Título da Entrada",
  No_Timesheets:"Sem planilhas de tempo",
  No_Timesheets_Word:"Adicione a primeira <a routerLink='/folder/Timesheet/New'>Planilha de horário</a>.",
  Timesheet_Submitted:"Folha de Pontos Enviada",
  Timesheet_Congrats:"Parabéns! Sua planilha de horas foi enviada com sucesso. Obrigado!",
  Timesheet_Copy:"Para receber uma cópia dos seus documentos, por favor, indique-nos o seu endereço de e-mail e/ou número de telemóvel.",
  Submit:"Enviar",
  Allow_Notifications:"Permitir notificações",
  Untitled_Entry:"Nova entrada",
  Untitled_Bank:"Banco sem título",
  Timesheet_Entry:"Entrada da folha de ponto",
  Work_Description:"Descrição do Trabalho",
  Enable_Timesheet:"Ativar planilha de horas",
  Submit_Timesheet:"Enviar planilha de horas",
  Vacation:"Férias",
  New_Vacation:"Novas férias",
  Vacation_Name:"Nome das férias",
  Paid_Vacation:"Férias pagas",
  Vacation_Pay:"Pagamento de férias",
  Enable_Vacation:"Ativar férias",
  Select_Vacations:"Selecione Férias",
  No_Vacations:"sem férias",
  No_Vacations_Word:"Crie a primeira entrada <a routerLink='/folder/Vacation/New'>Férias</a>.",
  Payroll_Schedule:"Cronograma de folha de pagamento",
  Next_Payroll:"Próxima folha de pagamento:",
  Upcoming_Payroll:"Próxima folha de pagamento",
  No_Upcoming_Payroll:"Nenhuma folha de pagamento futura",
  Address_Book:"Livro de endereços",
  Archived_Documents:"Documentos Arquivados",
  Dev_Mode:"Aplicativo em modo de desenvolvimento",
  Administrators:"Administradores",
  Privacy:"Privacidade",
  None:"Nenhum",
  Select_Signature:"Selecione Assinatura",
  No_Signatures:"Sem assinaturas",
  No_Signatures_Word:"Adicione a primeira <a routerLink='/folder/Signature/New'>Assinatura</a>.",
  Repeat_Indefinitely:"Repita indefinidamente",
  Sign:"Sinal",
  Sign_Here:"Assine aqui",
  Date_Signed:"Data Assinada",
  Signature_Pad:"Bloco de assinaturas",
  Account_Holder:"titular da conta",
  Account_Properties:"Propriedades da conta",
  Name_On_Cheque:"Nome no Cheque",
  Server_Hostname:"Nome do host do servidor / IP",
  Printers:"impressoras",
  No_Printers:"Sem impressoras",
  Printer_Exists:"Já existe uma impressora com este nome.",
  No_Printers_Word:"Adicione a primeira <a routerLink='/folder/Printer/New'>Impressora</a>.",
  No_Printer_Alert:"Nenhuma impressora está definida. Gostaria de configurar uma impressora?",
  Add_Printer:"Adicionar impressora",
  New_Printer:"Nova impressora",
  Printer_Hostname:"Nome do host / IP da impressora",
  Printer_Label:"Etiqueta da impressora",
  Printer_Protocol:"Protocolo da impressora",
  Protocol:"Protocolo",
  Email_Print:"E-mail",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"soquete",
  Print_Job:"Trabalho de impressão",
  Printed:"Impresso",
  Not_Printed:"Não Impresso",
  Print_Jobs:"Trabalhos de impressão",
  Print_Queue:"fila de impressão",
  No_Print_Jobs:"Nenhum trabalho de impressão",
  No_Prints:"Crie um novo <a routerLink='/folder/Cheque/New'>Verificar</a> para imprimir.",
  Prints:"Estampas",
  Find_Printer:"Encontrar impressora",
  Find_AirPrint_Devices:"Encontrar dispositivos AirPrint",
  Select_Printer:"Selecione a impressora",
  System_UI:"IU do sistema",
  Printer:"Impressora",
  Status:"Status",
  Preview:"Visualização",
  Enable_Print_Job:"Ativar trabalho de impressão",
  Queue_Weight:"Peso da Fila",
  Unlimited:"Ilimitado",
  Show_Advanced_Printer_Options:"Mostrar opções avançadas da impressora",
  Advanced:"Avançado",
  Location:"Localização",
  Note:"Observação",
  Queue_Name:"Nome da Fila",
  Pages_Printed_Limit:"Limite de Páginas Impressas",
  MultiPage_Idle_Time:"Tempo(s) de espera de várias páginas",
  Page_Count_Limit:"Limite de contagem de páginas",
  Page_Orientation:"Orientação da página",
  Portrait:"Retrato",
  Landscape:"Paisagem",
  Duplex:"Duplex",
  Double_Sided_Printing:"Dupla face",
  Duplex_Mode:"Modo Duplex",
  Duplex_Short:"Curto",
  Duplex_Long:"Longo",
  Duplex_None:"Nenhum",
  Color_And_Quality:"cor e qualidade",
  Monochrome:"Monocromático",
  Photo_Quality_Prints:"Impressões de qualidade fotográfica",
  Printer_Email:"E-mail da impressora",
  Automatically_Downsize:"Reduzir Automaticamente",
  Paper:"Papel",
  Paper_Name:"Nome do papel",
  Paper_Width:"Largura do papel",
  Paper_Height:"Altura do papel",
  Paper_Autocut_Length:"Comprimento do corte automático do papel",
  Margins:"Margens",
  Page_Margins:"Margens da página",
  Page_Margin_Top:"Margem superior da página",
  Page_Margin_Right:"Margem direita da página",
  Page_Margin_Bottom:"Margem inferior da página",
  Page_Margin_Left:"Margem Esquerda da Página",
  Add_Employees:"Adicionar Funcionários",
  Header:"Cabeçalho",
  Print_A_Page_Header:"Imprimir um cabeçalho de página",
  Header_Label:"Rótulo de Cabeçalho",
  Header_Page_Index:"Índice da página de cabeçalho",
  Header_Font_Name:"Fonte do Cabeçalho",
  Select_Font:"Selecione a fonte",
  Font_Selector:"Seletor de fonte",
  Header_Font_Size:"Fonte do Cabeçalho",
  Header_Bold:"Cabeçalho Negrito",
  Header_Italic:"Cabeçalho Itálico",
  Header_Alignment:"Alinhamento do Cabeçalho",
  Left:"Esquerda",
  Center:"Centro",
  Right:"Certo",
  Justified:"justificado",
  Header_Font_Color:"Cor do Cabeçalho",
  Select_Color:"Selecione a cor",
  Footer:"Rodapé",
  Print_A_Page_Footer:"Imprimir um rodapé de página",
  Footer_Label:"Rótulo de Rodapé",
  Footer_Page_Index:"Índice da página de rodapé",
  Footer_Font_Name:"Fonte do Rodapé",
  Fonts:"Fontes",
  Done:"Feito",
  Select_Fonts:"Selecionar Fontes",
  Footer_Font_Size:"Tamanho do Rodapé",
  Footer_Bold:"Rodapé Negrito",
  Footer_Italic:"Rodapé Itálico",
  Footer_Alignment:"Alinhamento do Rodapé",
  Footer_Font_Color:"Cor do Rodapé",
  Page_Copies:"Cópias de página",
  Enable_Printer:"Ativar impressora",
  Remote_Logging:"Registro Remoto",
  Log_Server:"Servidor de log",
  Encryption:"Criptografia",
  Random_Key:"Chave Aleatória",
  Encryption_Key:"Chave de encriptação",
  Cheques_Webserver:"Banco de dados personalizado",
  Learn_How:"Aprenda como",
  Signature:"Assinatura",
  Default_Printer_Unit:"pol./cm/mm/(pt)",
  View_Signature:"Ver Assinatura",
  Printed_Signature:"Assinatura Impressa",
  Digitally_Sign:"Assinar digitalmente",
  Digital_Signature:"Assinatura digital",
  Add_Signature:"Adicionar assinatura",
  Add_Your_Signature:"Adicione sua assinatura",
  Enable_Signature:"Ativar assinatura",
  Digitally_Signed:"Assinado digitalmente",
  Insert_Error:"Não foi possível salvar a verificação devido a problemas no banco de dados.",
  Delete_Confirmation:"Tem certeza de que deseja excluir essas informações?",
  Discard_Confirmation:"Tem certeza de que deseja descartar esta informação?",
  Discard_Bank_Confirmation:"Tem certeza de que deseja descartar esta conta?",
  Discard_Printer_Confirmation:"Tem certeza de que deseja descartar esta impressora?",
  Delete_Bonus_Confirmation:"Tem certeza de que deseja excluir este bônus?",
  Delete_Invoice_Confirmation:"Tem certeza de que deseja excluir esta fatura?",
  Generated_Cheque:"Cheque Gerado",
  Generated_Invoice:"Fatura Gerada",
  Schedule_Start_Time:"Agendar início",
  Schedule_End_Time:"Término agendado",
  New_Call:"Nova chamada",
  No_Contacts:"Sem contatos",
  No_Contacts_Word:"Administradores, moderadores, funcionários e beneficiários aparecem como contatos.",
  PDF_Subject:"finanças",
  PDF_Keywords:"folha de pagamento cheques PDF cheques",
  Printer_Setup:"Configuração da impressora",
  Printer_Selection:"Seleção de impressora",
  New_Fax:"Novo Fax",
  New_Fax_Message:"Nova mensagem de fax",
  Fax_Machine:"Maquina de fax",
  Fax_Name:"Nome do Fax",
  Fax_Email:"Fax E-mail",
  Fax_Number:"Número de fax",
  Contents:"Conteúdo",
  Fax_Body:"corpo da página",
  Header_Word:"Cabeçalho:",
  Header_Text:"Texto do Cabeçalho",
  Include_Header:"Incluir Cabeçalho",
  Include_Footer:"Incluir Rodapé",
  Footer_Word:"Rodapé:",
  Footer_Text:"Texto de rodapé",
  Attach_a_Cheque:"Anexe um cheque",
  Add_Deduction:"Adicionar Dedução",
  Enable_Fax:"Enviar fax",
  Select_Fax:"Selecione Fax",
  No_Faxes:"Sem faxes",
  Faxes:"Faxes",
  Save_and_Send:"Enviar fax",
  Save_and_Pay:"Economize e pague",
  WARNING:"AVISO:",
  Remember:"Lembrar",
  Printing:"Impressão",
  Printing_Complete:"Impressão concluída!\n\n",
  of:"de",
  There_Were:"Havia",
  Successful_Prints:"impressões de sucesso e",
  Unsuccessful_Prints:"impressões sem sucesso.",
  PrinterError:"Desculpe! Havia um erro.",
  Printing_:"Impressão...",
  PrinterSuccess:"Documento impresso com sucesso.",
  PrintersSuccess:"Documentos impressos com sucesso.",
  New_Message:"Nova mensagem",
  New_Messages:"Novas mensagens",
  Read_Message:"Leia a mensagem",
  Write_Message:"Escrever mensagem",
  Send_Message:"Enviar mensagem",
  Subject:"Assunto",
  Message:"Mensagem",
  Writing:"Escrita...",
  Send:"Enviar",
  Set_Date:"Definir data",
  Set_Time:"Definir tempo",
  Recieve:"Receber",
  Set_as_Default:"Definir como padrão",
  Default_Account:"Conta padrão",
  Default_Design:"Design Padrão",
  Multiple_Cheques:"três cheques",
  Account_Mode:"Modo de conta",
  Multiple_Cheques_Description:"Três cheques por página.",
  Check_and_Table:"Cheque e Tabela",
  Check_including_Table:"Verifique com a tabela de contabilidade.",
  Check_Mailer:"Checar Mailer",
  Check_Mailer_Window:"Verifique com uma janela de endereço.",
  DocuGard_Table_Top:"Cheque e Mesa Grandes",
  DocuGard_Table_Middle:"Cheque Grande e Mesa (Meio)",
  DocuGard_Table_Bottom:"Cheque grande e mesa (inferior)",
  DocuGard_Mailer_Top:"Enviador de cheques grande",
  DocuGard_Mailer_Middle:"Enviador de cheques grande (meio)",
  DocuGard_Mailer_Bottom:"Enviador de cheques grande (inferior)",
  DocuGard_Three_Cheques:"Três Cheques Grandes",
  DocuGard_Cheque_Top:"Cheque Único Grande",
  DocuGard_Cheque_Middle:"Cheque Grande (Meio)",
  DocuGard_Cheque_Bottom:"Cheque Grande (Inferior)",
  DocuGard_Three_Cheques_Window:"Três cheques em uma página.",
  DocuGard_Table_Top_Window:"Cheque e tabela de ganhos.",
  DocuGard_Table_Middle_Window:"Cheque e tabela de ganhos.",
  DocuGard_Table_Bottom_Window:"Cheque e tabela de ganhos.",
  DocuGard_Mailer_Top_Window:"Cheque, mesa e endereço.",
  DocuGard_Mailer_Middle_Window:"Cheque, mesa e endereço.",
  DocuGard_Mailer_Bottom_Window:"Cheque, mesa e endereço.",
  DocuGard_Cheque_Top_Window:"Verifique se há papel seguro.",
  DocuGard_Cheque_Middle_Window:"Verifique se há papel seguro.",
  DocuGard_Cheque_Bottom_Window:"Verifique se há papel seguro.",
  Basic_Cheque:"Cheque Simples",
  Basic_Cheque_Print:"Imprima um único cheque.",
  Error_Setting_As_Paid:"Erro ao definir como pago",
  Add_Attachment:"Juntar anexo",
  PrinterUnavailable:"Impressora indisponível",
  CreditCardComponent:"cartões",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Novo Depósito",
  Deposits:"Depósitos",
  No_Deposits:"Sem Depósitos",
  Credit_Card_Deposit:"Cartão de crédito",
  New_Credit_Card_Deposit_Message:"Depósito de cartão de crédito",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Depósito BitCoin",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Transferência Interac",
  Payments_Limit:"Limite de pagamento",
  No_Payment_Limit:"Sem Limite de Pagamento",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Depósito PayPal",
  No_Deposits_Word:"Adicione o primeiro <a routerLink='/folder/Deposit/New'>Depósito</a> da primeira empresa.",
  No_Documents_Specified:"Nenhum documento foi especificado para impressão",
  No_Printers_Added:"Nenhuma impressora foi encontrada. Vá para Configurações > Impressoras para adicionar uma.",
  DB_Erase_Prompt:"Apagar completamente todo o banco de dados? AVISO: Você perderá todas as informações armazenadas!",
  Console_Warning:"Não cole nenhum texto neste console. Você pode colocar a si mesmo e/ou sua empresa em sério risco.",
  No_Faxes_Word:"Crie o primeiro <a routerLink='/folder/Fax/New'></a>.",
  Cheque_Delete_Confirmation:"Tem certeza de que deseja excluir este cheque?",
  Design_Delete_Confirmation:"Tem certeza de que deseja excluir este design?",
  Cheque_Pay_Confirmation:"Marcar este cheque como pago? Ele NÃO aparecerá na fila de impressão.",
  Payment_Pay_Confirmation:"Marcar este pagamento como pago? NÃO aparecerá na fila de cheques.",
  Delete_Deduction_Confirmation:"Tem certeza de que deseja excluir esta dedução?",
  Delete_Job_Confirmation:"Tem certeza de que deseja excluir este trabalho?",
  Delete_Timesheet_Confirmation:"Tem certeza de que deseja excluir esta planilha de horas?",
  Delete_Schedule_Confirmation:"Tem certeza de que deseja excluir esta programação?",
  Delete_Setting_Confirmation:"Tem certeza de que deseja redefinir esta configuração?",
  Delete_Fax_Confirmation:"Tem certeza de que deseja excluir este fax?",
  Delete_File_Confirmation:"Tem certeza de que deseja excluir este arquivo?",
  Delete_Vacation_Confirmation:"Tem certeza de que deseja excluir estas férias?",
  Delete_Printer_Confirmation:"Tem certeza de que deseja excluir esta impressora?",
  Remove_Design_Confirmation:"Tem certeza de que deseja excluir este design?",
  Delete_Payroll_Confirmation:"Tem certeza de que deseja excluir esta folha de pagamento?",
  Send_Fax_Email_Confirmation:"Deseja enviar esses documentos por fax e e-mail?",
  Send_Email_Confirmation:"Deseja enviar este documento por e-mail?",
  Send_Fax_Confirmation:"Deseja enviar este documento por fax?",
  Error_Generating_PDF:"Desculpe. Ocorreu um erro ao gerar este documento.",
  PDF_Error_Saving_Image:"Desculpe. Ocorreu um erro ao salvar a imagem PDF deste documento.",
  Test_Printer_Confirmation:"Deseja imprimir uma página de teste nesta impressora?",
  Save_Timesheet_Prompt:"Adicione um 'Título' ou pressione 'Iniciar Timer' para salvar.",
  Remove_Geofence_Prompt:"Tem certeza de que deseja remover a localização desta cerca geográfica?",
  Delete_Employee_Confirmation:"Tem certeza de que deseja excluir",
  Fire_Employee_Confirmation:"Tem certeza que quer disparar"
}