import { Injectable,Inject,NgZone } from '@angular/core';
import { environment } from '../../environments/environment';
import * as Languages from '../../environments/languages/index';
@Injectable()
export class WebService {

	constructor(
		public zone: NgZone
	) { }
	public country 	= 'United States';
	public word 	= Languages[environment.language];
	public Locale   = environment.locale;
	localize() {
		if (navigator.language) {
			this.Locale=navigator.language;
		} else {
			this.Locale=environment.locale; 
		}
	}

}