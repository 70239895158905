export const Af = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Kopiereg &kopie; 2023",
  black:"Swart",
  green:"Groen",
  gold:"Goud",
  blue:"Blou",
  brown:"Bruin",
  purple:"Pers",
  pink:"Pienk",
  red:"Rooi",
  Swatches:"Monsters",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Tint",
  Saturation:"Versadiging",
  Lightness:"Ligte",
  Upgrade_To_Pro:"Gradeer op na Pro",
  AllFeaturesInclude:"Alle kenmerke sluit in:",
  PrintUnlimitedCheques:"Druk onbeperkte tjeks",
  PremiumChequeDesigns:"Premium Tjek ontwerpe",
  ManageUnlimitedEmployees:"Bestuur onbeperkte werknemers",
  AddUnlimitedPayees:"Voeg onbeperkte begunstigdes by",
  CreateUnlimitedPayrolls:"Skep onbeperkte betaalstate",
  UnlimitedSchedulesandTimeSheets:"Onbeperkte skedules en tydstate",
  BulkPayPalPayouts:"Grootmaat PayPal-uitbetalings",
  UnlimitedBankAccounts:"Onbeperkte bankrekeninge",
  ManageMultipleCompanies:"Bestuur verskeie maatskappye",
  TrackInsurancePolicies:"Volg versekeringspolisse",
  Bio_MetricProtection:"Bio-metriese beskerming",
  Geo_FenceLock_OutProtection:"Geo-heining-uitsluitbeskerming",
  Multiple_Companies_Word:"Die bestuur van verskeie maatskappye is nie beskikbaar sonder Tjeks-premie nie.",
  PayPal_Payouts_Word:"PayPal-betalings is gedeaktiveer sonder Tjeks-premie.",
  PINProtection:"PIN-beskerming",
  PasswordProtection:"Wagwoordbeskerming",
  May_Require_Approval:"Mag goedkeuring vereis.",
  Subscribe:"Teken in",
  Billed:"Gefaktureer",
  Up:"Op",
  Down:"Af",
  Positioning:"Posisionering",
  Marker:"Merker",
  Drag_Marker:"Sleep Merker",
  Tagline:"Druk tjeks en tabuleer betaalstaat",
  Marker_Word:"Gebruik die merkers om die element te grootte.",
  Pinch_Zoom:"Knyp Zoom",
  Pinch_Word:"Knyp om die element in te zoem.",
  Drag:"Sleep",
  Drag_Word:"Gebruik jou vinger om elemente te sleep.",
  subscription_alias_word:"Outo-hernuwing van intekening",
  premium_alias_word:"Eenmalige opgraderingspakket",
  print_alias_word:"Druk individuele tjeks uit",
  mode_alias_word:"Wyse",
  Pro:"Pro",
  Pro_word:"Pro weergawe vereis.",
  Cant_Delete_Default_Company_Word:"Jammer, jy kan nie jou verstekmaatskappy uitvee nie.",
  Reinsert_Default_Designs:"Voeg verstekontwerpe weer in",
  Reinsert_Default_Designs_word:"Wil jy verstekontwerpe weer invoeg?",
  Subscription_Active_Disable_Word:"Hierdie intekening is aktief. Wil jy hierdie intekening op Tjeks kanselleer?",
  Company_Logo:"Maatskappy Logo",
  ZERO_:"NUL",
  Disclaimer:"Vrywaring",
  Privacy_Policy:"Privaatheidsbeleid",
  EULA:"Kontroleer EULA",
  Terms_Of_Service:"Diensbepalings",
  Terms_Of_Use:"Gebruiksvoorwaardes",
  Refunds:"Terugbetalingsbeleid",
  Single_Print:"1 Kontroleer",
  Two_Prints:"2 Tjeks",
  Five_Prints:"5 Tjeks",
  Ten_Prints:"10 Tjeks",
  Fifteen_Prints:"15 Tjeks",
  Twenty_Prints:"20 Tjeks",
  Thirty_Prints:"30 Tjeks",
  Image_Added:"Beeld bygevoeg",
  Image_Preview:"Prentvoorskou",
  No_Image_Was_Selected:"Geen prent is gekies nie.",
  Cheques_Unlimited:"Tjeks Onbeperk",
  _Subscription:"Intekening",
  Subscription:"Tjeks - 1 maand",
  Two_Month_Subscription:"Tjeks - 2 maande",
  Three_Month_Subscription:"Tjeks - 3 maande",
  Six_Month_Subscription:"Tjeks - 6 maande",
  Twelve_Month_Subscription:"Tjeks - 12 maande",
  Cheques_Are_Available:"Tjeks is beskikbaar om uit te druk.",
  Upgrade_Required_Two:"Kies 'n pakket en dubbeltik op die prysknoppie om jou aankoop te begin. Druk professionele volkleur tjeks in sekondes.",
  Month:"Maand",
  Due:"Verval:",
  Expires:"Verval:",
  Subscription_Active:"Intekening aktief",
  Subscription_Inactive:"Intekening onaktief",
  Purchase_Additional_Cheques:"Koop bykomende tjeks?",
  Printable_Cheque:"Drukbare tjek",
  Printable_Cheques:"Drukbare tjeks",
  Printable_Cheque_Word:"tjek is beskikbaar op jou rekening.",
  Printable_Cheques_Word:"tjeks is beskikbaar op jou rekening.",
  Max_Amount_Message:"Die bedrag wat jy gespesifiseer het, het meer as die maksimum bedrag bereik wat vir hierdie stelsel gestel is:",
  Terms_Required_Word:"U moet tot hierdie ooreenkoms instem voordat u voortgaan om tjeks te gebruik.",
  Subscriptions:"Subskripsies",
  Product_Upgrades:"Opgraderings",
  Mailed_Out_Cheques:"Uitgeposde tjeks",
  Enter_A_Company_Name:"Voer asseblief 'n maatskappynaam in.",
  Single_Cheques:"Enkel tjeks",
  Cheque_Golden:"Goue Tjek",
  Cheque_Golden_Window:"Goue tjek ontwerp.",
  Cheque_Green:"Groen Tjek",
  Cheque_Green_Window:"Groen tjek ontwerp.",
  Cheque_Red:"Rooi Tjek",
  Cheque_Red_Window:"Rooi tjek ontwerp.",
  Cheque_Yellow:"Geel Tjek",
  Cheque_Yellow_Window:"Geel tjek ontwerp.",
  Cheque_Statue_of_Liberty:"Standbeeld van Vryheid",
  Cheque_Statue_of_Liberty_Window:"Die Statue of Liberty-tjekontwerp.",
  Cheque_Green_Wave:"Groen Golf",
  Cheque_Green_Wave_Window:"Groen tjek ontwerp.",
  Cheque_Golden_Weave:"Goue Weefsel",
  Cheque_Golden_Weave_Window:"Elegante goue tjek-ontwerp.",
  Cheque_Green_Sun:"Groen Son",
  Cheque_Green_Sun_Window:"Diep en kalmerende tjekontwerp.",
  Cheque_Blue_Checkers:"Blou Checkers",
  Cheque_Blue_Checkers_Window:"Blou tjek ontwerp.",
  Cashiers_Check:"Kassiertjek",
  Cashiers_Check_Window:"Kassier se Tjek styl sjabloon.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Aqua tjek ontwerp.",
  Cheque_Golden_Checkers:"Goue Checkers",
  Cheque_Golden_Checkers_Window:"Goue tjek ontwerp.",
  Upgrade_Unavailable:"Opgraderings nie beskikbaar nie",
  Bank_Code_Protection:"Banknommerbeskerming",
  Bank_Code_Protection_Word:"handeling is ONOMKEERBAAR. Aanhou?",
  Bank_Code_Protection_Blocked_Word:"Die banknommers wat jy probeer gebruik, is gereserveer vir 'n ander gebruiker of toestel.",
  Bank_Code_Protection_Error_Word:"Nommerverifikasie het misluk. Koppel asseblief aan die internet en probeer om hierdie bankrekening weer by te voeg.",
  Bank_Code_Protection_Set_Error_Word:"Banknommerbeskerming vereis dat jy aan die internet gekoppel is. Koppel asseblief en probeer weer.",
  Upgrade_Unavailable_Word:"area nie.",
  PayPal_Payment_Preview:"PayPal-betalingvoorskou",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Kies PayPal",
  PayPal_Applications:"PayPal Aansoeke",
  Purchase_With_Apple_Pay:"Koop met Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Maatskappye",
  Insurance:"Versekering",
  New_PayPal:"Nuwe PayPal",
  Pay_By:"Betaal deur",
  Insure:"Verseker",
  Miles:"Myle",
  Payment_Method:"Betalings metode",
  Select_Policies:"Kies Beleide",
  Policies:"Beleide",
  Policy:"Beleid",
  No_PayPal_Account:"Geen PayPal-rekening nie",
  No_Policies:"Geen Versekeringspolisse nie",
  New_Policy:"Nuwe Beleid",
  PayPal_Payment:"PayPal-betaling",
  PayPal_Payments:"PayPal-betalings",
  No_Payment_Selected:"Geen betaling gekies nie",
  Sending_Payment_Word:"Wag asseblief... Hierdie betaling word gestuur.",
  Sending_Payments_Word:"Wag asseblief... Betalings word gestuur.",
  No_Payment_Selected_PayPal:"Geen <a routerLink='/folder/Payments'>PayPal-betaling</a> gekies om te stuur.",
  Payment_Sent:"Betaling gestuur",
  PayPal_Payment_Sent:"Hierdie betaling is met PayPal gestuur.",
  Copay:"Copay",
  Dead:"Dood",
  Alive:"Lewendig",
  Not_Dead:"Nie dood nie",
  Unclaimed:"Onopgeëis",
  Attempted:"Poging",
  Deceased:"Oorledene",
  Claimed:"Geëis",
  Unpaid:"Onbetaald",
  Sending_Payment:"Stuur betaling",
  Sending_Payments:"Stuur betalings",
  Send_PayPal_Confirmation:"Wil jy hierdie transaksie met PayPal stuur?",
  Send_PayPal_Confirmation_Word:"Druk die groen knoppie om hierdie transaksie te stuur.",
  Save_Payment_As_Unpaid:"Stoor hierdie betaling as onbetaald?",
  Payment_Pay_Confirmation_PayPal:"Stoor hierdie betaling as betaal?",
  No_Policies_Word:"Voeg nou die eerste <a routerLink='/folder/Postage/New'>Versekeringspolis</a> by.",
  Timesheet_Multiple_Delete_Confirmation:"Is jy seker jy wil veelvuldige tydstate uitvee?",
  Select_Multiple_Timesheets_Prompt:"Geen tydstate gekies nie. Kies ten minste een tydstaat.",
  Select_Multiple_Policies_Prompt:"Geen beleide gekies nie. Kies ten minste een versekeringspolis.",
  Policies_Multiple_Delete_Confirmation:"Is jy seker jy wil veelvuldige beleide uitvee?",
  Company:"Maatskappy",
  Add_Company:"Voeg maatskappy by",
  New_Company:"Voeg maatskappy by",
  No_Companies:"Geen Maatskappye",
  Enable_Company:"Aktiveer Maatskappy",
  Select_Company:"Kies Maatskappy",
  The_Default_Company:"Die standaard maatskappy etiket.",
  Manage_Companies:"Bestuur Maatskappye",
  No_Companies_Word:"Tjeks sal 'n verstekmaatskappy gebruik .<br />Voeg die <a routerLink='/folder/Company/New'>eerste maatskappy</a> by.",
  Default_Company:"Verstek Maatskappy",
  Cheques_Unlimited_Word:"Tjeks Onbeperk laat jou toe om soveel tjeks te druk as wat jy wil.",
  Cheques_Subscription_Word:"'n Tjeks-intekening laat jou toe om soveel tjeks te druk as wat jy wil.",
  You_Own_This_Product:"Jy besit hierdie produk.",
  Your_Subscription_is_Active:"Jou intekening is aktief.",
  Active_Products:"Geaktiveerde produkte",
  Purchase_Confirmation:"Aankoop",
  Purchase_Cheques:"Koop Tjeks",
  Restore_Purchase:"Herstel aankope",
  Erase_Purchase:"Vee aankope uit",
  Successfully_Purchased:"Suksesvol gekoop",
  Enter_Your_Licence_Key:"Voer asseblief jou lisensiesleutel op hierdie bladsy in om hierdie produk te aktiveer.",
  Licence_Key:"Lisensie sleutel",
  Enter_Licence_Key:"Voer lisensiesleutel in",
  Purchased:"Gekoop",
  Enable_Feature:"Aktiveer kenmerk",
  Cancel_Subscription:"Kanselleer intekening",
  Subscription_Removed:"Intekening verwyder",
  Select_Active_Subscription:"Kies 'n aktiewe intekening om dit te wysig.",
  Remove_Subscription_Word:"Is jy seker jy wil hierdie intekening kanselleer?",
  Delete_Company_Confirmation:"Is jy seker jy wil hierdie hele maatskappy uitvee? WAARSKUWING: Jy sal alle gestoorde inligting verloor!",
  Delete_Default_Company_Word:"WAARSKUWING: Jy sal alle gestoorde inligting verloor!",
  Console_Warning_2:"Moenie enige geldeenheid hanteer met hierdie toepassing wat nie tans joune is nie.",
  Terms_and_Conditions:"Bepalings en voorwaardes",
  and_the:"en die",
  for:"vir",
  Please_Read_Word:"Lees asseblief en stem in tot die",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Sommige geldeenheidomskakelingskoerse kon nie gevind word nie. Koppel asseblief aan die internet.",
  Free:"Vry",
  DB_Erase_Prompt_2:"Vee die hele ontwikkelaardatabasis heeltemal uit? WAARSKUWING: Jy sal alle KOOP- en INTEKEN-inligting verloor!",
  Successfully_Imported:"Suksesvol ingevoer",
  Select_Postage:"Kies Posgeld",
  No_Postage:"Geen posseëls nie",
  No_Paid_Postage_Word:"Voeg die eerste <a routerLink='/folder/Postage/New'>Betaalde Pos-stempel</a> by.",
  Trial_Complete:'Verhoor voltooi',
  Please_Upgrade:'Gradeer asseblief tjeks op om voort te gaan met druk.',
  Aa:"Aa",
  Color:"Kleur",
  Font:"Lettertipe",
  Guide:"Gids",
  Guides:"Gidse",
  Image:"Beeld",
  Zoom:"Zoem",
  Logo:"Logo",
  Bank:"Bank",
  MICR:"MIKR",
  Total:"Totaal",
  Cancel:"Kanselleer",
  Confirm:"Bevestig",
  Method:"Metode",
  Biometric_Security:"Bio-metriese sekuriteit",
  Please_Login_To_Continue:"Meld asseblief aan om voort te gaan.",
  Complete:"Voltooi",
  Sign_Up:"Teken aan",
  Error:"Fout",
  Biometrics:"Biometrie",
  Percent:"Persentasie",
  Zero_Percent:"0%",
  Top_Margin:"Top Marge",
  Bottom_Margin:"Onderste kantlyn",
  Left_Margin:"Linkerkantlyn",
  Right_Margin:"Regterkantlyn",
  MICR_Margin:"MICR Marge",
  Table_Margin:"Tabel Marge",
  Address_Margin:"Adres Marge",
  Percentage_:"Persentasie",
  Vacation_Title:"Vakansie titel",
  Use_PIN:"Gebruik PIN",
  Loading__:"Laai tans...",
  Design_Title:"Ontwerp titel",
  Authorize:"Magtig",
  Key:"Sleutel",
  Public_Key:"Publieke sleutel",
  Private_Key:"Privaat sleutel",
  Authenticate:"Verifieer",
  Last_Payroll:"Laaste betaalstaat",
  Last_Calculation:"Laaste berekening",
  Authorized:"Gemagtig",
  Geo_Authorized:"Geo-ligging: gemagtig",
  Not_Authorized:"Nie gemagtig nie",
  Authorization_Complete:"Magtiging voltooi",
  Geolocation_Authorization:"Geo-ligging Magtiging",
  Out_of_Bounds:"Buite perke",
  Cant_Delete_Default_Design:"Kan nie 'n verstekontwerp uitvee nie.",
  Unauthorized_Out_of_Bounds:"Ongemagtig: Buite perke",
  Biometric_Authorization:"Bio-metriese magtiging",
  Locating_Please_Wait:"Opspoor, wag asseblief...",
  Test_Biometrics:"Toets Biometrie",
  Cheque_Margins:"Gaan Marges na",
  Percentage_Full_Error:"Die persentasie veld kan nie meer as 100% persent gestel word nie.",
  No_Payroll_Text:"Voeg 'n <a routerLink='/folder/Employee/New'>Werknemer</a> of <a routerLink='/folder/Payee/New'>Begunstigde</a> en 'n <a routerLink='/folder/Schedule/New'>Skedule</a>.",
  Design_Saved:"Ontwerp gestoor",
  Default_Design_Selected:"Verstek ontwerp gekies",
  Partial_Import:"Gedeeltelike invoer",
  Partial_Export:"Gedeeltelike uitvoer",
  Entire_Import:"Hele invoer",
  Entire_Export:"Hele uitvoer",
  Existing_Password:"Voer asseblief jou bestaande wagwoord in:",
  Existing_PIN:"Voer asseblief jou bestaande PIN-kode in:",
  Pin_Change_Header:"PIN-bevestiging",
  Pin_Change_SubHeader:"Voer jou ou PIN-nommer in om die verandering te bevestig.",
  Pass_Change_Header:"Wagwoord bevestiging",
  Pass_Change_SubHeader:"Voer jou ou wagwoord in om die verandering te bevestig.",
  PIN_Enter_Message:"Voer jou PIN in om te bevestig.",
  Password_Enter_Message:"Voer jou wagwoord in om te bevestig.",
  Pin_Updated_Success:"PIN is suksesvol opgedateer!",
  Pin_Updated_Fail:"PIN kon nie opgedateer word nie.",
  Pass_Updated_Success:"Wagwoord is suksesvol opgedateer.",
  Pass_Updated_Fail:"Wagwoord kon nie opgedateer word nie.",
  Preview_Payment:"Voorskou betaling",
  Preview_Payroll:"Voorskou Payroll",
  No_Payments_Created:"Geen betalings kon geskep word nie.",
  Payment_Preview:"Betaling Voorskou",
  Enable_Payee:"Aktiveer begunstigde",
  Rendered:"Weergegee",
  No_Email:"Geen e-pos nie",
  Setup_Cheques:"Opstel tjeks",
  name:"Naam",
  address:"Adres",
  address_2:"Adreslyn 2",
  city:"Stad",
  province:"Provinsie",
  postal_code:"Poskode",
  country:"Land",
  username:"Gebruikersnaam",
  full_name:"Volle naam",
  birthday:"Verjaarsdag",
  email:"E-pos",
  phone:"Foon",
  employees:"Werknemers",
  addresses:"Adresse",
  payment_amount_limit:"Betalingsbedragbeperking",
  total_limit:"Totale limiet",
  payees:"Begunstigdes",
  description:"Beskrywing",
  address_line_one:"Adres reël een",
  address_line_two:"Adres reël twee",
  image:"Beeld",
  account_holder:"Rekeninghouer",
  cheque_starting_id:"Gaan Begin-ID na",
  transit_number:"Transitnommer",
  institution_number:"Inrigtingnommer",
  designation_number:"Aanwysingsnommer",
  account_number:"Rekening nommer",
  currency:"Geldeenheid",
  signature:"Handtekening",
  payment_payroll_limit:"Betalingslimiet",
  total_limi:"Totale limiet",
  date:"Datum",
  printed_memo:"Gedrukte Memo",
  banks:"Banke",
  signature_image:"Handtekening beeld",
  address_name:"Adres Naam",
  notes:"Notas",
  design:"Ontwerp",
  title:"Titel",
  frequency:"Frekwensie",
  fax:"Faks",
  salaries:"Salarisse",
  salary_ids:"Salaris ID's",
  start_time:"Begin Tyd",
  end_time:"Eind tyd",
  paid:"Betaal",
  overtime_percentage:"Betaalde persentasie",
  overtime_amount:"Betaal vaste bedrag",
  first_name:"Eerste naam",
  last_name:"Van",
  moderation:"Moderering",
  create:"Skep",
  edit:"Wysig",
  destroy:"Vernietig",
  day_start_time:"Dag_begin_tyd",
  day_end_time:"Dag_eindtyd",
  fullname:"Naam",
  time:"Tyd",
  auto_send:"Stuur outomaties",
  time_method:"Tyd metode",
  schedules:"Skedules",
  indefinite_payroll_enabled:"Aktiveer onbepaald",
  amount:"Bedrag",
  repeat:"Herhaal",
  always_enabled:"Altyd geaktiveer",
  start_date:"Begindatum",
  end_date:"Einddatum",
  Cheque_Total:"Kontroleer Totaal",
  Total_Amount:"Totale bedrag:",
  Amounts:"Bedrae:",
  Images:"Beelde",
  Files:"Lêers",
  Previewing:"Voorbeskou:",
  Insert:"Voeg in",
  Preview_Import:"Voorskou invoer",
  Entry:"Inskrywing",
  Entries:"Inskrywings",
  No_Entries:"Geen inskrywings",
  Import_Type:"Tipe invoer",
  Select_Details:"Kies Besonderhede",
  Select_Payee:"Kies Begunstigde",
  Enable_Holidays:"Aktiveer Vakansies",
  Disable_Holidays:"Deaktiveer Vakansies",
  Wire_Transfer:"Elektroniese oorbetaling",
  New_Export:"Nuwe uitvoer",
  Export_Data:"Voer data uit",
  Export_Data_Word:"Kies die tipe lêer om uit te voer en af te laai.",
  Export_File:"Voer lêer uit",
  Mode:"Wyse",
  Times:"Tye",
  Widgets:"Widgets",
  Slider:"Skuifbalk",
  Set_Details:"Stel besonderhede in",
  Select_Type:"Kies Tipe",
  Display_Slider:"Vertoon glyer",
  Dashboard_Slider:"Dashboard Slider",
  Dashboard_Mode:"Dashboard-modus",
  Show_Intro:"Wys inleiding",
  Show_Payrolls:"Wys betaalstate",
  Show_Holidays:"Wys Vakansies",
  Show_Invoices:"Wys fakture",
  Show_Cheques:"Wys tjeks",
  Enabled_Widgets:"Geaktiveerde Widgets",
  Disabled_Widgets:"Gedeaktiveerde Widgets",
  Colors:"Kleure",
  Barcodes:"Strepiekodes",
  View_Timers:"Kyk Timers",
  Gradients:"Gradiënte",
  No_Info:"Geen inligting",
  Disable:"Deaktiveer",
  Show_Layer:"Wys lae",
  Hide_Layer:"Versteek lae",
  Text_Layer:"Tekslae",
  Secondly:"Tweedens",
  Minutely:"Minute",
  nine_am:"09:00",
  five_pm:"17:00",
  Enable_Address:"Aktiveer adres",
  Invalid_File_Type:"Jammer, 'n ongeldige lêertipe is gekies. Ondersteunde lêertipe:",
  Error_Updating_Entry:"Jammer, daar was 'n fout met die opdatering van hierdie inskrywing.",
  Schedule_Timing_Alert:"Fout: Die skedule-begintyd is op 'n waarde na die eindtyd gestel.",
  Select_Multiple_Payments_Prompt:"Geen betalings gekies nie. Kies ten minste een betaling.",
  Select_Multiple_Cheques_Prompt:"Geen tjeks gekies nie. Kies asseblief ten minste een tjek.",
  Select_Multiple_Items_Prompt:"Geen items gekies nie. Kies asseblief ten minste een item.",
  Paymemt_Multiple_Delete_Confirmation:"Is jy seker jy wil veelvuldige betalings uitvee?",
  Cheque_Multiple_Delete_Confirmation:"Is jy seker jy wil veelvuldige tjeks uitvee?",
  Payee_Multiple_Delete_Confirmation:"Is jy seker jy wil veelvuldige begunstigdes uitvee?",
  Employee_Multiple_Delete_Confirmation:"Is jy seker jy wil veelvuldige werknemers uitvee?",
  MICR_Warning:"Let wel: Sommige drukkers en toestelle sal dalk nie die MICR-lettertipe korrek vertoon nie.",
  Automatically_Send:"Stuur outomaties",
  Type:"Tik",
  Payment_Type:"Tipe van betaling",
  Auto_Send:"Outo stuur",
  Automatically_Process:"Verwerk outomaties",
  Auto_Process:"Outo-proses",
  Image_Based:"Beeldgebaseer",
  Font_Based:"Font-gebaseer",
  Rerender:"Herbeeld",
  Rendering:"Weervertoning",
  Render:"Lêers",
  Top:"Top",
  Middle:"Middel",
  Bottom:"Onderkant",
  Top_Left:"Links bo",
  Top_Center:"Top Sentrum",
  Top_Right:"Regs bo",
  Middle_Left:"Middel links",
  Middle_Center:"Middel sentrum",
  Middle_Right:"Middel regs",
  Bottom_Left:"Links onder",
  Bottom_Center:"Onderste middel",
  Bottom_Right:"Onder regs",
  Numbers:"Getalle",
  Verified:"Geverifieer",
  Paper_Size:"Papiergrootte",
  New_Device:"Nuwe Toestel",
  Add_Device:"Voeg Toestel by",
  Remove_Device:"Verwyder Toestel",
  Delete_Device:"Vee Toestel uit",
  Block_Device:"Blokkeer Toestel",
  Block:"Blok",
  Unblock:"Deblokkeer",
  Table:"Tafel",
  Scan_Login_Code:"Skandeer aanmeldkode",
  Login_Code:"Aanteken kode",
  Scan_Code:"Skandeer kode",
  Scan_QR_Code:"Skandeer QR-kode",
  Select_All:"Kies Alles",
  Deselect_All:"Ontkies Alles",
  Increase:"Verhoog",
  Decrease:"Verminder",
  Bold:"Vet",
  Text:"Teks",
  Style:"Styl",
  Italic:"Kursief",
  QR_Code:"QR kode",
  Barcode:"Strepiekode",
  Browse_Images:"Blaai deur prente",
  Browse_Files:"Blaai deur lêers",
  Background_Image:"Agtergrondprent",
  Logo_Image:"Logo beeld",
  Header_Image:"Opskrif prent",
  Bank_Image:"Bank Beeld",
  Cut_Lines:"Sny lyne",
  Background:"Agtergrond",
  License:"Lisensie",
  One_License:"1 lisensie:",
  Licensed:"Gelisensieer om:",
  Not_Licensed:"Nie gelisensieer nie",
  Enter_Serial:"Voer Serial in",
  Serial_Key:"Reeks sleutel",
  Serial:"Reeks",
  Product_Key:"Produk sleutel",
  Check_Product_Key:"Gaan produksleutel na",
  Add_Product_Key:"Voeg produksleutel by",
  Verifying_Purchase:"Verifieer tans aankoop …",
  Print_Envelope:"Druk koevert",
  Envelope:"Koevert",
  Thank_You:"Dankie!",
  Scale:"Skaal",
  Print_Scale:"Druk skaal",
  Borders:"Grense",
  VOID:"NIETIG",
  Void_Cheque:"Nietige tjek",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"BETAAL IN DIE ORDE VAN:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  NO_DOLLARS:"GEEN DOLLARS ",
  ONE_DOLLAR:"EEN DOLLAR",
  DOLLARS:" DOLLARS",
  AND:" EN ",
  CENTS:" SENTE.",
  NO_:"GEEN ",
  ONE_:"EEN ",
  AND_NO_:"EN NEE ",
  _AND_ONE_:"EN EEN ",
  DOLLARS_AND_ONE_CENT:" EN EEN SENT.",
  AND_NO_CENTS:" EN NUL SENTE.",
  CHEQUE_PRINT_DATE:"DATUM:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks: "Aanvanklike Take",
  Inital_Setup: "Aanvanklike Opstelling",
  Welcome_To: "Welkom by ",
  Welcome: "Welkom",
  Swipe: "Swiep",
  Intro_Setup: "Inleidingsopstelling",
  Introduction: "Inleiding",
  CHEQUE_PRINT_CREDIT:"Aangedryf deur Tjeks.",
  Title_Intro_Word: "Welkom by Tjeks",
  Title_Intro: "Tjeks Inleiding",
  Title_Setup: "Kontroleer Opstelling",
  PayPal_Default_Email_Message: "Jy het 'n nuwe PayPal-oordrag ontvang, aangedryf deur Tjeks.",
  Cheques_App_Export: "Uitgevoer deur Tjeks",
  Post_Intro_Thanks:"Dankie dat jy Tjeks gekies het. Die opstelproses is nou voltooi.",
  Post_Intro_Word:"Begin deur jou eerste tjek uit te druk, 'n toekomstige betaling by te voeg of 'n werknemers by die betaalstaat te voeg.",
  Upgrade_Required:"Checks vereis dat jy 'n meer premium weergawe van die sagteware besit om hierdie boodskap te versteek en bykomende kenmerke te ontsluit.",
  Upgrade_Title:"Tjeks",
  Mailout_Prompt:"Jy kan kies dat Tjeks jou betaalstaattjeks vir jou pos.",
  Mailed_Cheque:"Geposde Tjek: ",
  Mailed_Cheque_Color:"Geposde Tjek (Kleur): ",
  Terms_Purchase: "Alle elektroniese aankope met Tjeks is ten volle terugbetaalbaar vir tot 30 dae vanaf aankoopdatum. Lees asseblief en stem in tot die <a href='#'>Bepalings en Voorwaardes</a> voordat jy voortgaan.",
  Dashboard_Setup: "Stel die primêre drukker op",
  Dashboard_Add: "Voeg die primêre bankrekening by",
  Dashboard_Customize: "Kies die Check Template",
  Dashboard_Job_Salary: "Skep jou werk en voeg jou salaris by",
  Dashboard_Employees: "Volg Werknemers en Begunstigdes",
  Dashboard_Print: "Druk en pos jou salaristjeks",
  Dashboard_Payroll: "Outomatiseer jou betaalstaatdrukwerk",
  Dashboard_PayPal: "Stel PayPal-betaalstaat-uitbetalings op",
  Begin_Setup: "Begin opstelling",
  Get_Started: "Begin",
  Purchase: "Aankoop",
  Lockdown: "Inperking",
  Unlock: "Ontsluit",
  Detailed:"Gedetailleerd",
  Log_In:"Teken aan",
  Login:"Teken aan",
  Launch:"Begin",
  Register:"Registreer",
  Finish:"Voltooi",
  List:"Lys",
  Weekends:"Naweke",
  Weekly:"Weekliks",
  PayPal_Default_Subject: "Nuwe betaling",
  Payment_Message: "Betaling Boodskap",
  PayPal_Default_Payment_Note: "Dankie",
  Setup_Your_Cheqing_Account: "Tjekrekening",
  Add_Your_Primary_Cheqing_Account: "Voeg jou primêre tjekrekening by.",
  Welcome_Word: "Vereenvoudig en outomatiseer betaalstaat- en menslikehulpbronbestuur.",
  Get_Started_Quickly: "Beantwoord net 'n paar eenvoudige vrae wat ons sal help om aan die gang te kom...",
  Done_Intro_Word:"Opstelling Voltooi",
  PIN_Protected: "Wagwoord en PIN beskerm",
  Enter_New_PIN: "Voer 'n nuwe PIN-kode in:",
  Enter_PIN: "Voer PIN-kode in:",
  Invalid_PIN: "Ongeldige PIN ingevoer.",
  Account_Identifier: "Rekening-identifiseerder",
  New_Account_Identifier: "Nuwe rekening-identifiseerder",
  attempt: "poging",
  attempts: "pogings",
  remaining: "oorblywende",
  Language: "Taal",
  languages: "Tale",
  select_languages: "Kies Taal",
  Deposit: "Deposito",
  Hire_One_Now: "Huur nou een",
  App_Locked: "Die toepassing is gesluit.",
  Skip_: "Huppel",
  Skip_to_Dashboard: "Slaan oor na die Dashboard",
  Dashboard:"Dashboard",
  Import:"Invoer",
  Admin:"Administrateurs",
  New_Admin:"Nuwe Administrateur",
  Settings:"Instellings",
  Color_Picker: "Kleurkieser",
  Font_Picker: "Lettertipe-kieser",
  Logout:"Teken Uit",
  Close:"Naby",
  Close_menu:"Naby",
  Excel:"Excel lêer",
  Csv:"CSV-lêer",
  Sql:"SQL-lêer",
  Json:"JSON-lêer",
  Url:"Voer in volgens URL",
  Back:"Terug",
  Timers:"Timers",
  Cheque:"Tjek",
  Print:"Druk",
  Designs:"Ontwerpe",
  Pause_Printing:"Onderbreek Druk",
  Resume_Printing:"Hervat Drukwerk",
  Printing_Paused:"Drukwerk onderbreek",
  PrintingUnavailable:"Jammer! Drukwerk is nie op hierdie stelsel beskikbaar nie.",
  Prints_Paused:"Afdrukke Onderbreek",
  Administration:"Administrasie",
  Loading:"Laai tans",
  Unnamed:"Naamloos",
  error:"Jammer, hierdie tjek kon nie oopgemaak word vir besigtiging nie.",
  No_Cheques_To_Print:"Geen tjeks om uit te druk nie",
  No_Cheques_To_Print_Word:"Skep 'n <a routerLink='/folder/Cheque/New'>Nuwe tjek</a>.",
  New_Cheque:"Nuwe Tjek",
  Start_One_Now: "Begin Nou Een",
  Edit_Cheque:"Wysig Tjek",
  Select_Cheques:"Kies Tjeks",
  Select_Employee:"Kies Werknemer",
  Default_Printer:"Standaarddrukker",
  Reassign:"Hertoewys",
  Configure:"Stel op",
  Template:"Sjabloon",
  Designer: "Ontwerper",
  Edit_Designs: "Redigeer Ontwerpe",
  New_Design: "Nuwe Ontwerp",
  Next:"Volgende",
  Save:"Stoor",
  Name:"Naam",
  Mail:"Pos",
  Amount:"Bedrag",
  Date:"Datum",
  PayPal:"PayPal",
  Payouts:"Uitbetalings",
  PayPal_Label:"PayPal-etiket",
  Email_Username:"E-pos / Gebruikersnaam",
  Client_ID:"Kliënt-ID",
  Sandbox_Email:"Sandbox-e-pos",
  PayPal_Email:"PayPal-e-pos",
  PayPal_Username:"API-gebruikersnaam",
  PayPal_Payouts:"PayPal-uitbetalings",
  Select_PayPal_Key:"Kies PayPal-sleutel",
  Secret:"Geheim",
  API_Secret:"API Geheim",
  PayPal_API_Keys:"PayPal-sleutels",
  New_PayPal_Key:"Nuwe PayPal-sleutel",
  Email_Subject: "E-pos Onderwerp",
  Email_Message: "E-pos Boodskap",
  Payout_Options: "Uitbetalingsopsies",
  Payment_Note: "Betalingsnota",
  Enable_Employee:"Aktiveer Werknemer",
  Enable_Production_Mode:"Aktiveer Produksiemodus",
  Sandbox_Username: "Sandbox Gebruikersnaam",
  Sandbox_Signature: "Sandbox Handtekening",
  Sandbox_Password: "Sandbox Wagwoord",
  Production_Username: "Produksie Gebruikersnaam",
  Production_Signature: "Produksie Handtekening",
  Production_Password: "Produksie Wagwoord",
  Production_Email:"Produksie-e-pos",
  API_Client_ID:"API-kliënt-ID",
  API_Signature:"API handtekening",
  API_Password:"API-wagwoord",
  API_Username:"API-gebruikersnaam",
  Secret_Key: "Geheime sleutel",
  Sandbox: "Sandbox",
  Production: "Produksie",
  Sandbox_Keys: "Sandbox Sleutels",
  Production_Keys: "Produksiesleutels",
  API_Title:"API Titel",
  Production_Mode:"Produksiemodus",
  Account_Label: "Rekeningetiket",
  No_PayPal_Setup:"Geen PayPal-sleutel nie",
  Enable_PayPal_Account:"Aktiveer PayPal-rekening",
  No_PayPal_Word:"Voeg jou <a routerLink='/folder/Invoice/New'>PayPal API-sleutel</a> by.",
  Printed_Memo:"Gedrukte Memo",
  Employee:"Werknemer",
  View_Employee:"Bekyk Werknemer",
  Mailing_Address:"Posadres",
  Company_Address:"Maatskappy Adres",
  Select_Company_Address:"Kies Maatskappy-adres",
  Address:"Adres",
  Any_Day:"Enige Dag",
  Address_Name:"Adres Naam",
  Unit:"Eenheid",
  Account:"Rekening",
  Bank_Account:"Bankrekening",
  Account_Limits:"Aktiveer rekeninglimiete",
  Payroll: "Betaalstaat",
  New_Payroll: "Nuwe Betaalstaat",
  No_Payroll: "Geen komende betaalstaat nie",
  Upcoming_Holiday: "Komende Vakansie:",
  Invoice_Due: "Faktuur Verskuldig:",
  New_Invoice: "Nuwe Faktuur",
  No_Invoices: "Geen Fakture Nie",
  No_Invoices_Word: "Skep die eerste <a routerLink='/folder/Invoice/New'>Faktuur</a>.",
  Cheque_Due: "Tjek Verskuldig:",
  Payrolls: "Betaalstate",
  Sandbox_Mode: "Sandbox-modus",
  Hire: "Huur",
  Pay: "Betaal",
  New: "Nuut",
  Add: "Voeg by",
  Make: "Maak",
  Time: "Tyd",
  Write: "Skryf",
  Holiday: "Vakansie",
  Holidays: "Vakansies",
  Next_Holiday: "Volgende Vakansie:",
  All_Done: "Alles klaar.",
  Employees:"Werknemers",
  Payees:"Begunstigdes",
  Job:"Job",
  Jobs:"Werksgeleenthede",
  Invoice:"Faktuur",
  Invoices:"Fakture",
  Vacations:"Vakansies",
  Cheques:"Tjeks",
  Brand_Cheques:"Handelsmerk",
  Payment:"Betaling",
  Enable_Payment:"Aktiveer Betaling",
  Payments:"Betalings",
  Schedule: "Skedule",
  Schedules: "Skedules",
  Timesheet:"Tydstaat",
  Timesheets:"Tydstate",
  Salary:"Salaris",
  New_Address:"Nuwe Adres",
  Address_2: "Adreslyn 2",
  _City: "Stad",
  _State: "Staat/Prov",
  City: "Stad / Dorpsgebied",
  State: "Staat / Provinsie",
  Postal: "Poskode",
  ZIP: "Poskode",
  Country: "Land",
  Addresses: "Adresse",
  Required_Options:"Vereiste Opsies",
  Optional_Options:"Opsionele Opsies",
  Additional_Options:"Bykomende Opsies",
  Required:"Vereis",
  Optional:"Opsioneel",
  Additional:"Bykomende",
  No_Addresses: "Geen Adresse",
  New_Address_Word: "Voeg die eerste <a routerLink='/folder/Address/New'>Adres</a> by.",
  Select_Address: "Kies Adres",
  No_Address: "Geen Adresse",
  Print_Cheque: "Druk Tjek",
  Print_Cheque_Now: "Druk Check Nou",
  Description: "Beskrywing",
  Pay_To_The_Order_Of: "Betaal aan die Orde van:",
  Select_Date_Range: "Kies Datumreeks",
  Select_Starting_Date: "Kies Begindatum",
  Select_Ending_Date: "Kies Einddatum",
  Select_Date: "Kies Datum",
  Cheque_Date: "Kontroleer Datum",
  Cheque_Memo: "Gaan Memo na",
  Blank_Cheque:"Leë Tjek",
  Blank:"Leeg",
  No_Cheques: "Geen Tjeks",
  No_Cheques_Word: "Druk jou eerste <a routerLink='/folder/Cheque/New'>Tjeks</a>.",
  Cheque_Amount_Placeholder: "$0.00",
  View_Image: "Bekyk Beeld",
  View: "Beskou",
  Modify: "Verander",
  Delete: "Vee Uit",
  Cheque_Paid: "Betaal",
  New_Deduction: "Nuwe Aftrekking",
  Title: "Titel",
  Frequency: "Frekwensie",
  Hourly: "Uurliks",
  Daily: "Daagliks",
  Weekdays: "Weeksdae",
  BiWeekly: "2 Weke",
  TriWeekly: "3 Weke",
  Monthly: "Maandeliks",
  MiMonthly: "2 Maande",
  Quarterly: "Kwartaalliks",
  Yearly: "Jaarliks",
  Every_Week: "Elke Week",
  Every_Payroll: "Elke Betaalstaat",
  Every_Month: "Elke Maand",
  Annually: "Jaarliks",
  Always_Scheduled: "Altyd Geskeduleer",
  Start_Date: "Begindatum",
  End_Date: "Einddatum",
  Start_Time: "Begin Tyd",
  End_Time: "Eind Tyd",
  Deduction_Label: "Aftrekking Etiket",
  Notes: "Notas",
  Options: "Opsies",
  Enable: "Aktiveer",
  Select_Deductions:"Kies Aftrekkings",
  Deductions:"Aftrekkings",
  No_Deductions:"Geen aftrekkings nie",
  No_Deductions_Word:"Skep jou eerste <a routerLink='/folder/Deduction/New'>Aftrekking</a>.",
  New_Employee:"Nuwe Werknemer",
  No_Title: "Geen titel nie",
  _Name: "Naam",
  About_Yourself: "Oor jouself",
  Full_Name: "Volle Naam",
  Birthday: "Verjaarsdag",
  Email: "E-pos",
  SMS: "SMS",
  Phone: "Foon",
  Test:"Toets",
  Call:"Bel",
  Fax:"Faks",
  Printed_Note: "Gedrukte nota",
  Position: "Posisie",
  Job_Position: "Werk Posisie",
  Select_a_Job: "Kies 'n Werk",
  Select_a_Salary: "Kies 'n Salaris",
  Add_a_Deduction: "Voeg 'n Aftrekking by",
  Taxes: "Belasting",
  Add_Taxes: "Voeg Belasting by",
  Date_of_Birth: "Geboortedatum",
  Email_Address: "E-pos adres",
  Phone_Number: "Telefoon nommer",
  Phone_Call: "Oproep",
  Enable_on_Payroll: "Aktiveer op Payroll",
  Select_Employees: "Kies Werknemers",
  No_Employees: "Geen Werknemers",
  No_Employees_Word: "Voeg jou eerste nuwe <a routerLink='/folder/Employee/New'>Werknemer</a> by.",
  No_Name:"Geen naam",
  Unemployeed:"Werkloos",
  Employeed:"In diens geneem",
  Paid:"Betaal",
  Enabled:"Geaktiveer",
  Disabled:"Gestrem",
  Fire:"Vuur",
  Not_Available:"Nie beskikbaar nie",
  Not_Available_Word:"Druk jou eerste <a routerLink='/folder/Invoice/New'>Faktuur</a> en word betaal.",
  Select_Invoices: "Kies Fakture",
  Print_Invoice_Word:"Druk jou eerste <a routerLink='/folder/Invoice/New'>Faktuur</a> en word betaal.",
  Invoice_Title:"Faktuurtitel",
  Invoice_Date:"Faktuurdatum",
  Due_Date:"Sperdatum",
  New_Job: "Nuwe Werk",
  Details: "Besonderhede",
  Customize: "Pasmaak",
  Customize_Dashboard: "Pasmaak Dashboard",
  Components: "Komponente",
  No_Components: "Geen komponente nie",
  Main_Components: "Hoofkomponente",
  Smaller_Components: "Kleiner komponente",
  Error_Loading_Page: "Kon nie hierdie bladsy laai nie.",
  Bank_Details: "Bankbesonderhede",
  About_Your_Job: "Oor jou werk",
  Your_Schedule: "Jou skedule",
  Job_Title: "Werkstitel",
  Job_Description: "Pos beskrywing",
  Job_Details: "Posbesonderhede",
  Enable_Job: "Aktiveer Job",
  Pay_Period: "Betaalperiode",
  Perpetually_Schedule: "Voortdurend skeduleer",
  Select_Jobs: "Kies Jobs",
  No_Jobs: "Geen werk nie",
  Add_Jobs: "Voeg werk by",
  No_Jobs_Word: "Voeg die eerste <a routerLink='/folder/Job/New'>Job</a> by die lys.",
  Count_Employees: "job.employee_count+' Werknemers'",
  Zero_Employees: "0 Werknemers",
  New_Leave: "Nuwe verlof",
  Leave_Name: "Los Naam",
  Paid_Leave: "Betaalde verlof",
  Leave_Pay: "Los Pay",
  Indefinite_Leave: "Onbepaalde verlof",
  Indefinite_Payroll: "Onbepaalde betaalstaat",
  Leave: "Werksverlof",
  Add_Schedules: "Voeg skedules by",
  Percentage: "100%",
  Enable_Leave_Of_Absence: "Aktiveer afwesigheid",
  Select_Leaves: "Kies blare",
  No_Leaves: "Geen blare van afwesigheid nie",
  Leave_Of_Absence: "Verlof tot afwesigheid",
  Leaves_Of_Absence: "Blare van afwesigheid",
  New_Leave_of_Absense: "Nuwe Verlof tot Afwesigheid",
  No_Leaves_Word: "Voeg die eerste <a routerLink='/folder/Leave/New'>Verlof tot afwesigheid</a> by.",
  Not_Enabled: "Nie geaktiveer nie",
  Absences: "Afwesighede",
  Payee:"Begunstigde",
  New_Payee: "Nuwe begunstigde",
  Payee_Identifier: "Begunstigde-identifiseerder",
  Payee_Name: "Begunstigde Naam",
  Payee_Title: "Begunstigde titel",
  Payment_Memo: "Betalingsmemo",
  Select_Payees: "Select Payees",
  No_Payees: "Kies Begunstigdes",
  Add_Payee_Note: "Voeg die eerste <a routerLink='/folder/Payee/New'>Betaalnemer</a> by.",
  New_Payees: "Nuwe Begunstigdes",
  About_The_Payment_Schedule: "Betalingskedule",
  Your_Payroll_Schedule: "Outomatiese Betaalstaat",
  New_Payment: "Nuwe Betaling",
  Identifier: "Identifiseerder",
  Select: "Kies",
  Memo: "Memo",
  Payment_Date: "Betaaldatum",
  Mark_as_Paid: "Betaal",
  Select_Payments: "Kies betalings",
  No_Payments: "Geen betalings",
  No_Payments_Word: "Skep die eerste <a routerLink='/folder/Payment/New'>Betaling</a>.",
  Create_Payroll: "Skep Betaalstaat",
  Properties: "Eienskappe",
  Payroll_Title: "Betaalstaat Titel",
  Payroll_Notes: "Betaalstaatnotas",
  Payroll_Setup: "Betaalstaat Opstelling",
  Tabulate_Payments: "Tabuleer betalings",
  Tabulate: "Tabuleer",
  By: "Deur:",
  Payments_By: "Betalings deur",
  Timesheets_And_Schedules: "Tydstate en Skedules",
  Both: "Albei",
  Items: "Items",
  Add_Payees: "Voeg begunstigdes by",
  Add_Account: "Voeg rekening",
  Enable_Account: "Aktiveer rekening",
  Enable_Payroll: "Aktiveer betaalstaat",
  Print_Payroll: "Druk betaalstaat",
  No_Payrolls: "Geen betaalstaat nie",
  No_Payroll_Word: "Skep jou eerste <a routerLink='/folder/Payroll/New'>betaalstaat</a> by.",
  View_more:"SIEN MEER",
  Less:"MINDER",
  Add_Payroll:"Voeg betaalstaat by",
  Select_Payroll: "Kies betaalstaat",
  About_Your_Salary: "Oor jou salaris",
  Add_Salaries: "Voeg salarisse by",
  Add_Salary: "Voeg salaris by",
  Salaries: "Salarisse",
  No_Salaries: "Geen salarisse",
  No_Salaries_Word: "Voeg die eerste <a routerLink='/folder/Salary/New'>Salaris</a> by.",
  Select_Salaries: "Kies Salarisse",
  New_Salary: "Nuwe Salaris",
  Salary_Name: "Salaris Identifiseerder",
  Enable_Salary: "Aktiveer Salaris",
  Salary_Amount: "Salaris Bedrag",
  New_Schedule: "Nuwe Skedule",
  Schedule_Title: "Skedule Titel",
  Add_Address: "Voeg adres by",
  Repeat: "Herhaal",
  Design: "Ontwerp",
  Edit_Design: "Wysig ontwerp",
  Edit_this_Design: "Wysig hierdie ontwerp",
  Repeat_Payment: "Herhaal betaling",
  Enable_Schedule: "Aktiveer skedule",
  Never: "Nooit nie",
  Select_Schedule: "Kies skedules",
  No_Schedules: "Geen skedules",
  No_Schedules_Word: "Skep die eerste <a routerLink='/folder/Schedule/New'>Skedule</a>.",
  New_Administrator: "Nuwe Administrateur",
  Username: "Gebruikersnaam",
  First_Name: "Eerste naam",
  Last_Name: "Van",
  Add_an_Address: "Voeg 'n adres by",
  Payment_Limit: "Betalingslimiet",
  Total_Limit: "Totale limiet",
  Select_Accounts: "Kies Rekeninge",
  No_Administrators: "Geen administrateurs nie",
  No_Administrators_Word: "Skep die eerste <a routerLink='/folder/Administrator/New'>Admin-rekening</a>.",
  New_Administrators_Word: "Voeg die eerste <a routerLink='/folder/Administrator/New'>Administrateur</a> by.",
  Cloud: "Wolk",
  Backup: "Ondersteuning",
  Enable_iCloud:"Aktiveer iCloud",
  Enable_Google_Drive:"Aktiveer Google Drive",
  Enable_Microsoft_OneDrive:"Aktiveer Microsoft OneDrive",
  Automatically_Backup:"Rugsteun outomaties",
  FTP_Settings:"FTP-instellings",
  URL_File_Prompt:"Spesifiseer asseblief die ligging vir 'n .xls / .xlsx / .json-lêer om in te voer:",
  URL_SQL_Prompt:"Spesifiseer asseblief die ligging van die SQLite-lêer om in te voer:",
  FTP_Backup:"FTP-rugsteun",
  FTP_Import:"FTP invoer",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Gasheer",
  Port:"Port",
  Path:"Pad",
  Data_Path:"Datapad",
  Enable_FTP_Account:"Aktiveer FTP-rekening",
  HostnameIP: "Gasheernaam",
  Password: "Wagwoord",
  Connection_Port: "Verbindingspoort",
  Enable_MySQL_Database: "Aktiveer MySQL-databasis",
  Log: "Meld",
  Reset: "Stel terug",
  Erase: "Vee uit",
  Export: "Uitvoer",
  Database: "Databasis",
  Upload_CSV: "Laai CSV op",
  Download_CSV: "Laai CSV af",
  SQL_Database: "SQL URL",
  MySQL_Database: "MySQL",
  MySQL_Backup: "MySQL-rugsteun",
  Internal_Notes:"Interne notas",
  Root_Path: "Wortelpad",
  Select_Backup:"Kies Rugsteun",
  Add_New_Backup:"Voeg 'n nuwe rugsteun by",
  First_Backup:"Stel die eerste rugsteun op...",
  Backups:"Rugsteun",
  Add_Backup:"Voeg rugsteun by",
  No_Backups:"Daar is geen rugsteun te vinde nie.",
  Select_Backup_Type:"Kies die tipe rugsteun wat jy wil opstel...",
  Backup_Type:"Rugsteuntipe",
  FTP_Drive:"FTP Drive",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Drive",
  Microsoft_OneDrive:"Drive",
  Import_Fields:"Voer velde in",
  Import_Fields_Word:"Gebruik hierdie afdeling om <a routerLink='/folder/Holiday/New'>Invoer</a> data te kies.",
  Upload: "Laai op",
  Download:"Aflaai",
  Download_JSON:"Laai af as JSON-data",
  Download_SQL:"Laai af as SQL-lêer",
  New_Bank: "Nuwe Bank",
  New_Account: "Nuwe rekening",
  New_Bank_Account: "Nuwe Bankrekening",
  Upload_Image: "Laai prent op",
  Bank_Name: "Bank Naam",
  Bank_Address: "Bankadres",
  Branch_Address: "Takadres",
  Address_on_Cheque: "Adres",
  Cheque_Numbers: "Kontroleer nommers",
  Cheque_Details: "Gaan Besonderhede na",
  Bank_Logo: "Bank Logo",
  Cheque_ID: "Tjek-ID na",
  Starting_Cheque_ID: "Begin tjek-ID",
  Transit_Number: "Transitnommer",
  Institution_Number: "Inrigtingnommer",
  Designation_Number: "Aanwysingsnommer",
  Account_Number: "Rekening nommer",
  Limits: "Perke",
  Default_Limits: "Verstek limiete",
  Over_Limit: "Oor limiet",
  Under_Limit: "Onder Limiet",
  Payroll_Limit: "Betaalstaat limiet",
  Enable_Bank_Account: "Aktiveer bankrekening",
  Select_Account: "Kies Rekening",
  No_Bank_Account: "Geen bankrekening nie",
  No_Bank_Account_Word: "Voeg die eerste <a routerLink='/folder/Accounts/New'>Bankrekening</a> by.",
  Bank_Accounts: "Bank rekeninge",
  No_Accounts: "Geen rekeninge nie",
  No_Accounts_Note: "Voeg die eerste <a routerLink='/folder/Accounts/New'>Bankrekening</a> by.",
  Cheque_Designer: "Kontroleer Ontwerper",
  Cheque_Design: "Kontroleer Ontwerp",
  Select_Design: "Kies 'n ontwerp",
  Cheque_Designs: "Kontroleer ontwerpe",
  No_Cheque_Designs: "Geen tjekontwerpe nie",
  No_Cheque_Designs_Word: "Skep jou eie <a routerLink='/folder/Settings/Cheque/Design/New'>Kontroleer Ontwerp</a>.",
  Set_Default: "Stel as standaard",
  Default: "Verstek",
  Remove: "Verwyder",
  Folder: "Gids",
  Edit: "Wysig",
  LoadingDots: "Laai tans...",
  Add_a_New_File: "Voeg 'n <a href='#' (click)='add()'>nuwe lêer</a> by",
  this_folder: "hierdie gids",
  FTP_Backup_Settings: "FTP-rugsteuninstellings",
  Secure_File_Transfer: "Veilige lêeroordrag",
  New_Holiday: "Nuwe Vakansie",
  Add_Holiday: "Voeg vakansie by",
  Holiday_Name: "Vakansie Naam",
  Additional_Pay: "Bykomende betaling",
  Enable_Holiday: "Aktiveer Vakansie",
  Select_Holidays: "Kies Vakansies",
  No_Holidays: "Geen vakansiedae nie",
  No_Holidays_Word: "Voeg die eerste <a routerLink='/folder/Holiday/New'>Openbare Vakansiedag</a> by.",
  New_Import: "Nuwe invoer",
  Import_Data: "Voer data in",
  Import_Data_Word: "Kies die tipe lêer of oplaaimetode van jou keuse.<br /> Jy sal kan kies watter ingevoerde velde in 'n lêer ooreenstem met watter parameter ook al in die toepassing nadat jy 'n ondersteunde lêer opgelaai het.",
  Import_File:"Voer lêer in",
  Link_To_File:"Skakel na lêer",
  Select_File:"Kies Lêer",
  New_Moderator:"Nuwe moderator",
  Allow_Moderation: "Laat moderering toe",
  Create_Paycheques: "Skep Paychecks",
  Edit_Paycheques_and_Data: "Wysig salarisse en data",
  Destroy_Data_and_Paycheques: "Vernietig data en salarisse",
  Bonus_Percentage: "Paycheck persentasie",
  Fixed_Amount: "Vaste Bedrag",
  Select_Moderator: "Kies Moderator",
  No_Moderators: "Geen moderators nie",
  Moderators: "Moderators",
  Moderator_Account: "Skep die eerste <a routerLink='/folder/Administrator/New'>Moderatorrekening</a>.",
  No_Moderators_Word:"Voeg die eerste <a routerLink='/folder/Administrator/New'>Moderator</a> by.",
  Defaults: "Verstek",
  Provide_Us_Info: "Gee ons Inligting",
  Setup_Your_Printer: "Stel jou Drukker op",
  Your_Company: "Oor jou maatskappy",
  Company_Name: "Maatskappynaam",
  Currency: "Geldeenheid",
  Default_Currency: "Verstek geldeenheid",
  Base_Monthly_Income: "Maandelikse inkomste",
  Protection: "Beskerming",
  App_Protection: "Programbeskerming",
  PIN_Code_Protection: "PIN-kode beskerming",
  App_Protection_Word: "Aktiveer sekuriteitmetodes wat help om jou rekening te beskerm.",
  PIN_Code: "PIN Code",
  Change_PIN: "Verander PIN",
  New_Password: "Nuwe Wagwoord",
  Geofence_Protection: "Geo-heiningbeskerming",
  Geofence_Area: "Stel Area",
  Distance: "Afstand",
  Setup_Now: "Stel nou op",
  Mile: "Myl",
  Km: "Km",
  m: "m",
  Facial_Recognition: "Gesigsherkenning",
  Face: "Gesig",
  Setup: "Stel op",
  Label: "Etiket",
  Password_Protection: "Wagwoordbeskerming",
  Modify_Password: "Verander wagwoord",
  New_Tax_Entry: "Nuwe belastinginskrywing",
  New_Tax: "Nuwe belasting",
  Tax_Label: "Belasting Etiket",
  Perpetually_Enabled: "Voortdurend Geaktiveer",
  Select_Taxes: "Kies Belasting",
  Tax_Deductions: "Belastingaftrekkings",
  No_Tax_Deductions: "Geen belastingaftrekkings nie",
  No_Tax_Deductions_Word: "Voeg die eerste <a routerLink='/folder/Tax/New'>Belasting</a>-aftrekking by.",
  New_Timer: "Nuwe Timer",
  Start: "Begin",
  Stop: "Stop",
  Start_Timer: "Begin Timer",
  Stop_Timer: "Stop Timer",
  Timer_Active: "Timer aktief",
  Timer:"Timer:",
  Timer_Running: "Timer: (hardloop)",
  Save_Timer: "Stoor Timer",
  New_Timesheet: "Nuwe Tydstaat",
  Select_Timesheets: "Kies Tydstate",
  Work_Notes: "Werknotas",
  Entry_Title: "Inskrywingstitel",
  No_Timesheets: "Geen Tydstate",
  No_Timesheets_Word: "Voeg die eerste <a routerLink='/folder/Timesheet/New'>Tydstaat</a> by.",
  Timesheet_Submitted: "Tydstaat ingedien",
  Timesheet_Congrats: "Baie geluk! Jou tydstaat is suksesvol ingedien. Dankie!",
  Timesheet_Copy: "Om 'n afskrif van jou dokumente te ontvang, gee ons asseblief jou e-posadres en/of selfoonnommer.",
  Submit: "Indien",
  Allow_Notifications: "Allow Notifications",
  Untitled_Entry:"Nuwe Inskrywing",
  Untitled_Bank:"Ongetitelde Bank",
  Timesheet_Entry: "Tydstaatinskrywing",
  Work_Description: "Werk Beskrywing",
  Enable_Timesheet: "Aktiveer Tydstaat",
  Submit_Timesheet: "Dien tydstaat in",
  Vacation: "Vakansie",
  New_Vacation: "Nuwe Vakansie",
  Vacation_Name: "Vakansie Naam",
  Paid_Vacation: "Betaalde Vakansie",
  Vacation_Pay: "Vakansiegeld",
  Enable_Vacation: "Aktiveer Vakansie",
  Select_Vacations: "Kies Vakansies",
  No_Vacations: "Geen Vakansies",
  No_Vacations_Word: "Skep die eerste <a routerLink='/folder/Vacation/New'>Vakansie</a>-inskrywing.",
  Payroll_Schedule: "Betaalstaatskedule",
  Next_Payroll: "Volgende betaalstaat:",
  Upcoming_Payroll: "Komende betaalstaat",
  No_Upcoming_Payroll: "Geen komende betaalstaat nie",
  Address_Book:"Adres Boek",
  Archived_Documents:"Geargiveerde Dokumente",
  Dev_Mode:"Toepassing in ontwikkelingsmodus",
  Administrators:"Administrateurs",
  Privacy:"Privaatheid",
  None:"Geen",
  Select_Signature:"Kies Handtekening",
  No_Signatures:"Geen handtekeninge nie",
  No_Signatures_Word: "Voeg die eerste <a routerLink='/folder/Signature/New'>Handtekening</a> by.",
  Repeat_Indefinitely:"Herhaal onbepaald",
  Sign: "Teken",
  Sign_Here:"Teken Hier",
  Date_Signed:"Datum Geteken",
  Signature_Pad:"Handtekening Pad",
  Account_Holder:"Rekeninghouer",
  Account_Properties:"Rekeningeienskappe",
  Name_On_Cheque:"Naam op tjek",
  Server_Hostname: "Bediener Gasheernaam / IP",
  Printers: "Drukkers",
  No_Printers: "Geen drukkers nie",
  Printer_Exists:"'n Drukker met hierdie naam bestaan reeds.",
  No_Printers_Word: "Voeg die heel eerste <a routerLink='/folder/Printer/New'>drukker</a> by.",
  No_Printer_Alert: "Geen drukker is gedefinieer nie. Wil jy 'n drukker opstel?",
  Add_Printer: "Voeg Drukker by",
  New_Printer: "Nuwe Drukker",
  Printer_Hostname: "Drukker Gasheernaam / IP",
  Printer_Label: "Drukker Etiket",
  Printer_Protocol: "Drukker protokol",
  Protocol: "Protokol",
  Email_Print: "E-pos",
  AirPrint: "AirPrint",
  IPP: "IPP",
  LPD: "LPD",
  HPJetDirect: "HP Sok",
  Print_Job: "Drukwerk",
  Printed: "Gedruk",
  Not_Printed: "Nie Gedruk nie",
  Print_Jobs: "Drukwerk",
  Print_Queue: "Druk tou",
  No_Print_Jobs: "Geen druktake nie",
  No_Prints: "Skep 'n nuwe <a routerLink='/folder/Cheque/New'>tjek</a> om te druk.",
  Prints: "Afdrukke",
  Find_Printer: "Vind Drukker",
  Find_AirPrint_Devices: "Vind AirPrint-toestelle",
  Select_Printer: "Kies Drukker",
  System_UI: "Stelsel UI",
  Printer: "Drukker",
  Status: "Status",
  Preview: "Voorskou",
  Enable_Print_Job: "Aktiveer druktaak",
  Queue_Weight:"Tou Gewig",
  Unlimited:"Onbeperk",
  Show_Advanced_Printer_Options: "Wys gevorderde drukkeropsies",
  Advanced: "Advanced",
  Location: "Ligging",
  Note: "Let wel",
  Queue_Name: "Waglys Naam",
  Pages_Printed_Limit:"Bladsye Gedruk Limiet",
  MultiPage_Idle_Time:"Veelbladsye wagtyd (tweede)",
  Page_Count_Limit:"Bladsytelling limiet",
  Page_Orientation:"Bladsy-oriëntasie",
  Portrait:"Portret",
  Landscape:"Landskap",
  Duplex:"Dupleks",
  Double_Sided_Printing:"Tweesydig",
  Duplex_Mode:"Dupleksmodus",
  Duplex_Short:"Kort",
  Duplex_Long:"Lank",
  Duplex_None:"Geen",
  Color_And_Quality:"Kleur En Kwaliteit",
  Monochrome:"Monochroom",
  Photo_Quality_Prints:"Foto Kwaliteit",
  Printer_Email: "Drukker e-pos",
  Automatically_Downsize:"Verminder outomaties",
  Paper:"Papier",
  Paper_Name:"Papier Naam",
  Paper_Width:"Papier breedte",
  Paper_Height:"Papier Hoogte",
  Paper_Autocut_Length:"Papier lengte",
  Margins:"Marges",
  Page_Margins:"Bladsykantlyne",
  Page_Margin_Top:"Bladsykantlyn bo",
  Page_Margin_Right:"Bladsykantlyn regs",
  Page_Margin_Bottom:"Bladsykantlyn onder",
  Page_Margin_Left:"Bladsykantlyn links",
  Add_Employees:"Voeg werknemers by",
  Header:"Opskrif",
  Print_A_Page_Header:"Druk 'n bladsyopskrif",
  Header_Label:"Opskrif etiket",
  Header_Page_Index:"Kopblad-indeks",
  Header_Font_Name:"Kopskrif lettertipe",
  Select_Font:"Kies Font",
  Font_Selector:"Lettertipe kieser",
  Header_Font_Size:"Kopskrif lettertipe",
  Header_Bold:"Kopskrif vetgedruk",
  Header_Italic:"Kopskrif kursief",
  Header_Alignment:"Kopbelyning",
  Left:"Links",
  Center:"Sentrum",
  Right:"Reg",
  Justified:"Geregverdigde",
  Header_Font_Color:"Kopkop kleur",
  Select_Color:"Kies Kleur",
  Footer:"Voetskrif",
  Print_A_Page_Footer:"Druk 'n bladsyvoetskrif",
  Footer_Label:"Voetskrif etiket",
  Footer_Page_Index:"Bladsy-indeks",
  Footer_Font_Name:"Voetskrif lettertipe",
  Fonts:"Lettertipes",
  Done:"Klaar",
  Select_Fonts:"Kies lettertipes",
  Footer_Font_Size:"Voetskrif grootte",
  Footer_Bold:"Voetskrif Vet",
  Footer_Italic:"Voetskrif kursief",
  Footer_Alignment:"Voetskrifbelyning",
  Footer_Font_Color:"Voetskrif kleur",
  Page_Copies:"Bladsy Kopieë",
  Enable_Printer:"Aktiveer Drukker",
  Remote_Logging: "Afgeleë Aantekening",
  Log_Server: "Teken bediener",
  Encryption: "Enkripsie",
  Random_Key: "Veilige sleutel",
  Encryption_Key: "Enkripsiesleutel",
  Cheques_Webserver: "Pasgemaakte databasis",
  Learn_How: "Leer hoe",
  Signature: "Handtekening",
  Default_Printer_Unit: "in/cm/mm/(pt)",
  View_Signature: "Bekyk Handtekening",
  Printed_Signature: "Gedrukte Handtekening",
  Digitally_Sign: "Teken digitaal",
  Digital_Signature: "Digitale handtekening",
  Add_Signature: "Voeg handtekening by",
  Add_Your_Signature:"Voeg jou handtekening by",
  Enable_Signature: "Aktiveer handtekening",
  Digitally_Signed: "Digitaal Geteken",
  Insert_Error:"Kan nie tjek stoor nie weens databasiskwessies.",
  Delete_Confirmation:"Is jy seker jy wil hierdie inligting uitvee?",
  Discard_Confirmation:"Is jy seker jy wil hierdie inligting weggooi?",
  Discard_Bank_Confirmation:"Is jy seker jy wil hierdie rekening weggooi?",
  Discard_Printer_Confirmation:"Is jy seker jy wil hierdie drukker weggooi?",
  Delete_Bonus_Confirmation:"Is jy seker jy wil hierdie bonus uitvee?",
  Delete_Invoice_Confirmation:"Is jy seker jy wil hierdie faktuur uitvee?",
  Generated_Cheque: "Gegenereerde tjek",
  Generated_Invoice: "Gegenereerde faktuur",
  Schedule_Start_Time: "Skedule Begin",
  Schedule_End_Time: "Skedule Einde",
  New_Call: "Nuwe oproep",
  No_Contacts: "Geen kontakte nie",
  No_Contacts_Word: "Administrateurs, moderators, werknemers en begunstigdes verskyn as kontakte.",
  PDF_Subject: "finansies",
  PDF_Keywords: "payroll paycheck PDF tjek tjeks",
  Printer_Setup: "Drukkeropstelling",
  Printer_Selection: "Drukker seleksie",
  New_Fax:"Nuwe Faks",
  New_Fax_Message: "Nuwe Faksboodskap",
  Fax_Machine: "Faksmasjien",
  Fax_Name: "Faks Naam",
  Fax_Email: "Faks e-pos",
  Fax_Number: "Faksnommer",
  Contents: "Inhoud",
  Fax_Body: "Bladsy liggaam",
  Header_Word: "Opskrif:",
  Header_Text: "Kop teks",
  Include_Header: "Sluit kopskrif in",
  Include_Footer: "Sluit voetskrif in",
  Footer_Word: "Voetskrif:",
  Footer_Text: "Voettekst",
  Attach_a_Cheque: "Heg 'n tjek aan",
  Add_Deduction: "Voeg aftrekking by",
  Enable_Fax: "Stuur Faks",
  Select_Fax: "Kies Faks",
  No_Faxes: "Geen fakse nie",
  Faxes: "Fakse",
  Save_and_Send: "Stuur Faks",
  Save_and_Pay: "Spaar en Betaal",
  WARNING: "WAARSKUWING:",
  Remember: "Onthou",
  Printing: "Drukwerk",
  Printing_Complete: "Drukwerk voltooi!\n\n",
  of: "van",
  There_Were: "Daar was ",
  Successful_Prints: " suksesvolle afdrukke en ",
  Unsuccessful_Prints: " onsuksesvolle afdrukke.",
  PrinterError: "Jammer! Daar was 'n fout.",
  Printing_: "Druk tans...",
  PrinterSuccess: "Dokument suksesvol gedruk.",
  PrintersSuccess: "Dokumente is suksesvol gedruk.",
  Print_Cheques: "Druk tjeks",
  New_Message: "Nuwe Boodskap",
  New_Messages: "Nuwe Boodskappe",
  Read_Message: "Lees Boodskap",
  Write_Message: "Skryf Boodskap",
  Send_Message: "Stuur Boodskap",
  Subject: "Vak",
  Message: "Boodskap",
  Writing: "Skryf tans...",
  Send: "Stuur",
  Set_Date: "Stel datum",
  Set_Time: "Vasgestelde tyd",
  Recieve: "Ontvang",
  Set_as_Default: "Stel as standaard",
  Default_Account: "Verstekrekening",
  Default_Design: "Verstek ontwerp",
  Multiple_Cheques: "Tjeks (drievoudig)",
  Account_Mode: "Rekeningmodus",
  Multiple_Cheques_Description: "Drie tjeks per bladsy.",
  Check_and_Table: "Tjek & Tabel",
  Check_including_Table: "Tjek en rekeningkundige tabel.",
  Check_Mailer: "Gaan Mailer na",
  Check_Mailer_Window: "Kontroleer met 'n adresvenster.",
  DocuGard_Table_Top:"DocuGard Tjek en Tafel (Bo)",
  DocuGard_Table_Middle:"DocuGard Tjek en Tafel (Middel)",
  DocuGard_Table_Bottom:"DocuGard Tjek en Tabel (Onder)",
  DocuGard_Mailer_Top:"DocuGard Gepos Tjek (Bo)",
  DocuGard_Mailer_Middle:"DocuGard Gepos Tjek (Middel)",
  DocuGard_Mailer_Bottom:"DocuGard Gepos Tjek (Onder)",
  DocuGard_Three_Cheques:"DocuGard-tjeks (Drievoudig)",
  DocuGard_Cheque_Top:"DocuGard-tjek (Bo)",
  DocuGard_Cheque_Middle:"DocuGard-tjek (Middel)",
  DocuGard_Cheque_Bottom:"DocuGard-tjek (Onder)",
  DocuGard_Three_Cheques_Window:"Drie tjeks op een bladsy.",
  DocuGard_Table_Top_Window: "Tjek en verdienste tabel.",
  DocuGard_Table_Middle_Window: "Tjek en verdienste tabel.",
  DocuGard_Table_Bottom_Window: "Tjek en verdienste tabel.",
  DocuGard_Mailer_Top_Window: "Kontroleer, tabel en adres.",
  DocuGard_Mailer_Middle_Window: "Kontroleer, tabel en adres.",
  DocuGard_Mailer_Bottom_Window: "Kontroleer, tabel en adres.",
  DocuGard_Cheque_Top_Window: "Kyk vir veilige papier.",
  DocuGard_Cheque_Middle_Window: "Kyk vir veilige papier.",
  DocuGard_Cheque_Bottom_Window: "Kyk vir veilige papier.",
  Basic_Cheque: "Tjek (Bo)",
  Basic_Cheque_Print: "Druk 'n enkele tjek uit.",
  Error_Setting_As_Paid: "Fout instel as betaald",
  Add_Attachment: "Voeg aanhangsel by",
  PrinterUnavailable: "Drukker nie beskikbaar nie",
  CreditCardComponent:"Kaarte",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Nuwe deposito",
  Deposits:"Deposito's",
  No_Deposits:"Geen deposito's",
  Credit_Card_Deposit:"Kredietkaart",
  New_Credit_Card_Deposit_Message:"Kredietkaartdeposito",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Bitcoin Deposito",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Interac-oordrag",
  Payments_Limit: "Betalingslimiet",
  No_Payment_Limit: "Geen betalingslimiet nie",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal Deposito",
  No_Deposits_Word:"Voeg die eerste maatskappy <a routerLink='/folder/Deposit/New'>Deposito</a> by.",
  No_Documents_Specified: "Geen dokumente is vir druk gespesifiseer nie",
  No_Printers_Added: "Geen drukkers is gevind nie. Gaan na Instellings > Drukkers om een by te voeg.",
  DB_Erase_Prompt: "Vee die hele databasis heeltemal uit? WAARSKUWING: Jy sal alle gestoorde inligting verloor!",
  Console_Warning: "Moenie enige teks in hierdie konsole plak nie. Jy kan jouself en/of jou maatskappy in ernstige gevaar stel.",
  No_Faxes_Word: "Skep die eerste <a routerLink='/folder/Fax/New'>Faks</a>.",
  Cheque_Delete_Confirmation:"Is jy seker jy wil hierdie tjek uitvee?",
  Design_Delete_Confirmation:"Is jy seker jy wil hierdie ontwerp uitvee?",
  Cheque_Pay_Confirmation:"Merk hierdie tjek as betaal? Dit sal NIE in die drukwaglys verskyn nie.",
  Payment_Pay_Confirmation:"Merk hierdie betaling as betaal? Dit sal NIE in die kontrole-tou verskyn nie.",
  Delete_Deduction_Confirmation:"Is jy seker jy wil hierdie aftrekking uitvee?",
  Delete_Job_Confirmation:"Is jy seker jy wil hierdie werk uitvee?",
  Delete_Timesheet_Confirmation:"Is jy seker jy wil hierdie tydstaat uitvee?",
  Delete_Schedule_Confirmation:"Is jy seker jy wil hierdie skedule uitvee?",
  Delete_Setting_Confirmation:"Is jy seker jy wil hierdie instelling terugstel?",
  Delete_Fax_Confirmation:"Is jy seker jy wil hierdie faks uitvee?",
  Delete_File_Confirmation:"Is jy seker jy wil hierdie lêer uitvee?",
  Delete_Vacation_Confirmation:"Is jy seker jy wil hierdie vakansie uitvee?",
  Delete_Printer_Confirmation:"Is jy seker jy wil hierdie drukker uitvee?",
  Remove_Design_Confirmation:"Is jy seker jy wil hierdie ontwerp uitvee?",
  Delete_Payroll_Confirmation:"Is jy seker jy wil hierdie betaalstaat uitvee?",
  Send_Fax_Email_Confirmation:"Wil jy hierdie dokumente faks en e-pos?",
  Send_Email_Confirmation:"Wil jy hierdie dokument per e-pos stuur?",
  Send_Fax_Confirmation:"Wil jy hierdie dokument faks?",
  Error_Generating_PDF: "Jammer. Kon nie hierdie dokument genereer nie.",
  PDF_Error_Saving_Image: "Jammer. Kon nie PDF-prent van hierdie dokument stoor nie.",
  Test_Printer_Confirmation:"Wil jy 'n toetsbladsy op hierdie drukker druk?",
  Save_Timesheet_Prompt:"Voeg asseblief 'n 'Titel' by of druk 'Begin Timer' om te stoor.",
  Remove_Geofence_Prompt: "Is jy seker jy wil die ligging van hierdie geo-heining verwyder?",
  Delete_Employee_Confirmation:"Is jy seker jy wil uitvee ",
  Fire_Employee_Confirmation:"Is jy seker jy wil vuur "
}