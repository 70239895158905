export const Ka = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"საავტორო უფლება & ასლი; 2023 წელი",
  black:"შავი",
  green:"მწვანე",
  gold:"ოქრო",
  blue:"ლურჯი",
  brown:"ყავისფერი",
  purple:"მეწამული",
  pink:"ვარდისფერი",
  red:"წითელი",
  Swatches:"სვოჩები",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"ელფერი",
  Saturation:"გაჯერება",
  Lightness:"სიმსუბუქე",
  Upgrade_To_Pro:"გადადით Pro-ზე",
  AllFeaturesInclude:"ყველა მახასიათებელი მოიცავს:",
  PrintUnlimitedCheques:"დაბეჭდეთ შეუზღუდავი ჩეკები",
  PremiumChequeDesigns:"პრემიუმ შემოწმების დიზაინები",
  ManageUnlimitedEmployees:"მართეთ შეუზღუდავი თანამშრომლები",
  AddUnlimitedPayees:"დაამატეთ შეუზღუდავი გადამხდელები",
  CreateUnlimitedPayrolls:"შექმენით შეუზღუდავი სახელფასო სია",
  UnlimitedSchedulesandTimeSheets:"შეუზღუდავი განრიგი და დროის ფურცლები",
  BulkPayPalPayouts:"მასობრივი PayPal გადახდები",
  UnlimitedBankAccounts:"შეუზღუდავი საბანკო ანგარიშები",
  ManageMultipleCompanies:"მრავალი კომპანიის მართვა",
  TrackInsurancePolicies:"აკონტროლეთ სადაზღვევო პოლისი",
  Bio_MetricProtection:"ბიომეტრული დაცვა",
  Geo_FenceLock_OutProtection:"Geo-Fence Lock-out დაცვა",
  Multiple_Companies_Word:"მრავალი კომპანიის მართვა არ არის ხელმისაწვდომი ჩეკების პრემიის გარეშე.",
  PayPal_Payouts_Word:"PayPal-ის გადახდები გამორთულია ჩეკების პრემიის გარეშე.",
  PINProtection:"PIN დაცვა",
  PasswordProtection:"პაროლის დაცვა",
  May_Require_Approval:"შეიძლება მოითხოვოს დამტკიცება.",
  Subscribe:"გამოწერა",
  Billed:"დარიცხული",
  Up:"ზემოთ",
  Down:"ქვემოთ",
  Positioning:"პოზიციონირება",
  Marker:"მარკერი",
  Drag_Marker:"ჩაათრიეთ მარკერი",
  Tagline:"ჩეკების დაბეჭდვა და სახელფასო ცხრილების შედგენა",
  Marker_Word:"გამოიყენეთ მარკერები ელემენტის გასაზომად.",
  Pinch_Zoom:"Pinch Zoom",
  Pinch_Word:"დააჭირე ელემენტის გასადიდებლად.",
  Drag:"გადაათრიეთ",
  Drag_Word:"გამოიყენეთ თითი ელემენტების გადასატანად.",
  subscription_alias_word:"ხელმოწერის ავტომატური განახლება",
  premium_alias_word:"ერთჯერადი განახლების პაკეტი",
  print_alias_word:"ამობეჭდვა ინდივიდუალური ჩეკები",
  mode_alias_word:"რეჟიმი",
  Pro:"პრო",
  Pro_word:"საჭიროა პრო ვერსია.",
  Cant_Delete_Default_Company_Word:"უკაცრავად, თქვენ არ შეგიძლიათ წაშალოთ თქვენი ნაგულისხმევი კომპანია.",
  Reinsert_Default_Designs:"ხელახლა ჩადეთ ნაგულისხმევი დიზაინები",
  Reinsert_Default_Designs_word:"გსურთ ხელახლა ჩასვათ ნაგულისხმევი დიზაინები?",
  Subscription_Active_Disable_Word:"ეს გამოწერა აქტიურია. გსურთ გააუქმოთ ჩეკების ეს გამოწერა?",
  Company_Logo:"კომპანიის ლოგო",
  ZERO_:"ᲜᲣᲚᲘ",
  Disclaimer:"პასუხისმგებლობის უარყოფა",
  Privacy_Policy:"Კონფიდენციალურობის პოლიტიკა",
  EULA:"ამოწმებს EULA",
  Terms_Of_Service:"Მომსახურების პირობები",
  Terms_Of_Use:"Მოხმარების პირობები",
  Refunds:"თანხის დაბრუნების პოლიტიკა",
  Single_Print:"1 შეამოწმეთ",
  Two_Prints:"2 ჩეკები",
  Five_Prints:"5 ჩეკები",
  Ten_Prints:"10 ჩეკი",
  Fifteen_Prints:"15 ჩეკები",
  Twenty_Prints:"20 ჩეკები",
  Thirty_Prints:"30 ჩეკი",
  Image_Added:"სურათი დამატებულია",
  Image_Preview:"სურათის გადახედვა",
  No_Image_Was_Selected:"სურათი არ იყო არჩეული.",
  Cheques_Unlimited:"ამოწმებს შეუზღუდავი",
  _Subscription:"გამოწერა",
  Subscription:"ჩეკები - 1 თვე",
  Two_Month_Subscription:"ჩეკები - 2 თვე",
  Three_Month_Subscription:"ჩეკები - 3 თვე",
  Six_Month_Subscription:"ჩეკები - 6 თვე",
  Twelve_Month_Subscription:"ჩეკები - 12 თვე",
  Cheques_Are_Available:"ჩეკები ხელმისაწვდომია დასაბეჭდად.",
  Upgrade_Required_Two:"აირჩიეთ პაკეტი და ორჯერ შეეხეთ ფასის ღილაკს, რომ დაიწყოთ შეძენა. დაბეჭდეთ პროფესიონალური გარეგნობის სრული ფერადი ჩეკები წამებში.",
  Month:"თვე",
  Due:"Გამო:",
  Expires:"ვადა იწურება:",
  Subscription_Active:"გამოწერა აქტიურია",
  Subscription_Inactive:"გამოწერა არააქტიურია",
  Purchase_Additional_Cheques:"იყიდეთ დამატებითი ჩეკები?",
  Printable_Cheque:"დასაბეჭდი შემოწმება",
  Printable_Cheques:"დასაბეჭდი ჩეკები",
  Printable_Cheque_Word:"შემოწმება ხელმისაწვდომია თქვენს ანგარიშზე.",
  Printable_Cheques_Word:"ჩეკები ხელმისაწვდომია თქვენს ანგარიშზე.",
  Max_Amount_Message:"თქვენ მიერ მითითებული თანხა აღემატება ამ სისტემისთვის დადგენილ მაქსიმალურ რაოდენობას:",
  Terms_Required_Word:"თქვენ უნდა დაეთანხმოთ ამ შეთანხმებას, სანამ გააგრძელებთ ჩეკების გამოყენებას.",
  Subscriptions:"გამოწერები",
  Product_Upgrades:"განახლებები",
  Mailed_Out_Cheques:"ფოსტით გამოგზავნილი ჩეკები",
  Enter_A_Company_Name:"გთხოვთ შეიყვანოთ კომპანიის სახელი.",
  Single_Cheques:"ერთჯერადი ჩეკები",
  Cheque_Golden:"ოქროს ჩეკი",
  Cheque_Golden_Window:"ოქროს ჩეკის დიზაინი.",
  Cheque_Green:"მწვანე შემოწმება",
  Cheque_Green_Window:"მწვანე ჩეკის დიზაინი.",
  Cheque_Red:"წითელი ჩეკი",
  Cheque_Red_Window:"წითელი ჩეკის დიზაინი.",
  Cheque_Yellow:"ყვითელი ჩეკი",
  Cheque_Yellow_Window:"ყვითელი ჩეკის დიზაინი.",
  Cheque_Statue_of_Liberty:"Თავისუფლების ქანდაკება",
  Cheque_Statue_of_Liberty_Window:"თავისუფლების ქანდაკების შემოწმების დიზაინი.",
  Cheque_Green_Wave:"მწვანე ტალღა",
  Cheque_Green_Wave_Window:"მწვანე ჩეკის დიზაინი.",
  Cheque_Golden_Weave:"ოქროს ქსოვა",
  Cheque_Golden_Weave_Window:"ელეგანტური ოქროს ჩეკის დიზაინი.",
  Cheque_Green_Sun:"მწვანე მზე",
  Cheque_Green_Sun_Window:"ღრმა და დამამშვიდებელი შემოწმების დიზაინი.",
  Cheque_Blue_Checkers:"ლურჯი ქვები",
  Cheque_Blue_Checkers_Window:"ლურჯი ჩეკის დიზაინი.",
  Cashiers_Check:"სალაროს ჩეკი",
  Cashiers_Check_Window:"სალარო ჩეკის სტილის შაბლონი.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"აკვა შემოწმების დიზაინი.",
  Cheque_Golden_Checkers:"ოქროს ქვები",
  Cheque_Golden_Checkers_Window:"ოქროს ჩეკის დიზაინი.",
  Upgrade_Unavailable:"განახლებები მიუწვდომელია",
  Bank_Code_Protection:"ბანკის ნომრის დაცვა",
  Bank_Code_Protection_Word:"დაიცავით თქვენი ბანკის ნომრები ამ აპში, რომელიც მუშაობს სხვა მოწყობილობაზე ან სხვა მომხმარებლისთვის. ეს ქმედება შეუქცევადია. გაგრძელება?",
  Bank_Code_Protection_Blocked_Word:"ბანკის ნომრები, რომელთა გამოყენებასაც ცდილობთ, დაცულია სხვა მომხმარებლისთვის ან მოწყობილობისთვის.",
  Bank_Code_Protection_Error_Word:"ნომრის დადასტურება ვერ მოხერხდა. გთხოვთ, დაუკავშირდეთ ინტერნეტს და ხელახლა სცადოთ ამ საბანკო ანგარიშის დამატება.",
  Bank_Code_Protection_Set_Error_Word:"ბანკის ნომრის დაცვა მოითხოვს ინტერნეტთან დაკავშირებას. გთხოვთ, დაუკავშირდეთ და სცადოთ ხელახლა.",
  Upgrade_Unavailable_Word:"უკაცრავად, თქვენი დადასტურების პრობლემა გვაქვს. ჩეკების ხელმოწერები და განახლებები ამჟამად მიუწვდომელია თქვენს რეგიონში შესაძენად.",
  PayPal_Payment_Preview:"PayPal გადახდის გადახედვა",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"აირჩიეთ PayPal",
  PayPal_Applications:"PayPal აპლიკაციები",
  Purchase_With_Apple_Pay:"შეიძინეთ Apple Pay-ით",
  Google_Pay:"Google Pay",
  Companies:"კომპანიები",
  Insurance:"დაზღვევა",
  New_PayPal:"ახალი PayPal",
  Pay_By:"გადახდა By",
  Insure:"დააზღვევი",
  Miles:"მილები",
  Payment_Method:"Გადახდის საშუალება",
  Select_Policies:"აირჩიეთ პოლიტიკა",
  Policies:"პოლიტიკა",
  Policy:"პოლიტიკა",
  No_PayPal_Account:"არ არის PayPal ანგარიში",
  No_Policies:"არავითარი სადაზღვევო პოლისი",
  New_Policy:"ახალი პოლიტიკა",
  PayPal_Payment:"PayPal გადახდა",
  PayPal_Payments:"PayPal გადახდები",
  No_Payment_Selected:"გადახდა არ არის არჩეული",
  Sending_Payment_Word:"გთხოვთ დაელოდოთ... ეს გადახდა იგზავნება.",
  Sending_Payments_Word:"გთხოვთ დაელოდოთ... გადახდები იგზავნება.",
  No_Payment_Selected_PayPal:"არ არის შერჩეული <a routerLink='/folder/Payments'>PayPal გადახდა</a>.",
  Payment_Sent:"გადახდა გაიგზავნა",
  PayPal_Payment_Sent:"ეს გადახდა გაიგზავნა PayPal-ით.",
  Copay:"გადაიხადე",
  Dead:"მკვდარი",
  Alive:"ცოცხალი",
  Not_Dead:"არა მკვდარი",
  Unclaimed:"გამოუცხადებელი",
  Attempted:"ცდილობდა",
  Deceased:"გარდაცვლილი",
  Claimed:"ამტკიცებდა",
  Unpaid:"გადაუხდელი",
  Sending_Payment:"გადახდის გაგზავნა",
  Sending_Payments:"გადახდების გაგზავნა",
  Send_PayPal_Confirmation:"გსურთ ამ ტრანზაქციის გაგზავნა PayPal-ით?",
  Send_PayPal_Confirmation_Word:"დააჭირეთ მწვანე ღილაკს ამ ტრანზაქციის გასაგზავნად.",
  Save_Payment_As_Unpaid:"შეინახოთ ეს გადახდა, როგორც გადაუხდელი?",
  Payment_Pay_Confirmation_PayPal:"შეინახოთ ეს გადახდა, როგორც გადახდილი?",
  No_Policies_Word:"დაამატეთ პირველი <a routerLink='/folder/Postage/New'>დაზღვევის პოლისი</a> ახლავე.",
  Timesheet_Multiple_Delete_Confirmation:"დარწმუნებული ხართ, რომ გსურთ წაშალოთ მრავალი დროის ცხრილი?",
  Select_Multiple_Timesheets_Prompt:"დროის ცხრილები არ არის არჩეული. აირჩიეთ მინიმუმ ერთი დროის ცხრილი.",
  Select_Multiple_Policies_Prompt:"პოლიტიკა არჩეული არ არის. აირჩიეთ მინიმუმ ერთი სადაზღვევო პოლისი.",
  Policies_Multiple_Delete_Confirmation:"დარწმუნებული ხართ, რომ გსურთ წაშალოთ მრავალი პოლიტიკა?",
  Company:"კომპანია",
  Add_Company:"კომპანიის დამატება",
  New_Company:"კომპანიის დამატება",
  No_Companies:"არ არის კომპანიები",
  Enable_Company:"კომპანიის ჩართვა",
  Select_Company:"აირჩიეთ კომპანია",
  The_Default_Company:"კომპანიის ნაგულისხმევი ეტიკეტი.",
  Manage_Companies:"კომპანიების მართვა",
  No_Companies_Word:"ჩეკები გამოიყენებენ ნაგულისხმევ კომპანიას.<br />დაამატეთ <a routerLink='/folder/Company/New'>პირველი კომპანია</a>.",
  Default_Company:"ნაგულისხმევი კომპანია",
  Cheques_Unlimited_Word:"Checks Unlimited გაძლევთ საშუალებას დაბეჭდოთ იმდენი ჩეკი, რამდენიც გსურთ.",
  Cheques_Subscription_Word:"ჩეკების გამოწერა საშუალებას გაძლევთ დაბეჭდოთ იმდენი ჩეკი, რამდენიც გსურთ.",
  You_Own_This_Product:"თქვენ ფლობთ ამ პროდუქტს.",
  Your_Subscription_is_Active:"თქვენი გამოწერა აქტიურია.",
  Active_Products:"გააქტიურებული პროდუქტები",
  Purchase_Confirmation:"Შესყიდვა",
  Purchase_Cheques:"ჩეკების შეძენა",
  Restore_Purchase:"შესყიდვების აღდგენა",
  Erase_Purchase:"შესყიდვების წაშლა",
  Successfully_Purchased:"წარმატებით შეძენილი",
  Enter_Your_Licence_Key:"გთხოვთ, შეიყვანოთ თქვენი ლიცენზიის გასაღები ამ გვერდზე ამ პროდუქტის გასააქტიურებლად.",
  Licence_Key:"ლიცენზიის გასაღები",
  Enter_Licence_Key:"შეიყვანეთ ლიცენზიის გასაღები",
  Purchased:"ნაყიდი",
  Enable_Feature:"ფუნქციის ჩართვა",
  Cancel_Subscription:"გააუქმეთ გამოწერა",
  Subscription_Removed:"გამოწერა ამოღებულია",
  Select_Active_Subscription:"აირჩიეთ აქტიური გამოწერა მისი შესაცვლელად.",
  Remove_Subscription_Word:"დარწმუნებული ხართ, რომ გსურთ ამ გამოწერის გაუქმება?",
  Delete_Company_Confirmation:"დარწმუნებული ხართ, რომ გსურთ მთელი კომპანიის წაშლა? გაფრთხილება: თქვენ დაკარგავთ ყველა შენახულ ინფორმაციას!",
  Delete_Default_Company_Word:"თქვენ არ შეგიძლიათ წაშალოთ ნაგულისხმევი კომპანია. გსურთ აპლიკაციის გადატვირთვა და ნაგულისხმევ მდგომარეობაში აღდგენა? გაფრთხილება: თქვენ დაკარგავთ ყველა შენახულ ინფორმაციას!",
  Console_Warning_2:"ნუ ამუშავებთ არცერთ ვალუტას ამ აპლიკაციის გამოყენებით, რომელიც ამჟამად თქვენი არ არის.",
  Terms_and_Conditions:"Ვადები და პირობები",
  and_the:"და",
  for:"ამისთვის",
  Please_Read_Word:"გთხოვთ წაიკითხოთ და დაეთანხმოთ",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"ზოგიერთი ვალუტის კონვერტაციის კურსი ვერ მოიძებნა. გთხოვთ დაუკავშირდეთ ინტერნეტს.",
  Free:"უფასო",
  DB_Erase_Prompt_2:"მთლიანად წაშალოთ დეველოპერის მონაცემთა ბაზა? გაფრთხილება: თქვენ დაკარგავთ ყველა ინფორმაციას შესყიდვისა და გამოწერის შესახებ!",
  Successfully_Imported:"წარმატებით იმპორტირებული",
  Select_Postage:"აირჩიეთ საფოსტო გადასახადი",
  No_Postage:"არ არის საფოსტო მარკები",
  No_Paid_Postage_Word:"დაამატეთ პირველი <a routerLink='/folder/Postage/New'>ფასიანი საფოსტო ბეჭედი</a>.",
  Trial_Complete:'საცდელი დასრულება',
  Please_Upgrade:'გთხოვთ, განაახლოთ ჩეკები ბეჭდვის გასაგრძელებლად.',
  Aa:"Aa",
  Color:"ფერი",
  Font:"შრიფტი",
  Guide:"გზამკვლევი",
  Guides:"გიდები",
  Image:"გამოსახულება",
  Zoom:"მასშტაბირება",
  Logo:"ლოგო",
  Bank:"ბანკი",
  MICR:"MICR",
  Total:"სულ",
  Cancel:"გაუქმება",
  Confirm:"დაადასტურეთ",
  Method:"მეთოდი",
  Biometric_Security:"ბიომეტრიული უსაფრთხოება",
  Please_Login_To_Continue:"Გასაგრძელებლად გაიარეთ ავტორიზაცია.",
  Complete:"სრული",
  Sign_Up:"დარეგისტრირდით",
  Error:"შეცდომა",
  Biometrics:"ბიომეტრიკა",
  Percent:"პროცენტი",
  Zero_Percent:"0%",
  Top_Margin:"ზედა ზღვარი",
  Bottom_Margin:"ქვედა ზღვარი",
  Left_Margin:"მარცხენა ზღვარი",
  Right_Margin:"მარჯვენა ზღვარი",
  MICR_Margin:"MICR ზღვარი",
  Table_Margin:"მაგიდის ზღვარი",
  Address_Margin:"მისამართის ზღვარი",
  Percentage_:"პროცენტი",
  Vacation_Title:"შვებულების სათაური",
  Use_PIN:"გამოიყენეთ PIN",
  Loading__:"Ჩატვირთვა...",
  Design_Title:"დიზაინის სათაური",
  Authorize:"ავტორიზაცია",
  Key:"Გასაღები",
  Public_Key:"საჯარო გასაღები",
  Private_Key:"პირადი გასაღები",
  Authenticate:"ავთენტიფიკაცია",
  Last_Payroll:"ბოლო სახელფასო",
  Last_Calculation:"ბოლო გაანგარიშება",
  Authorized:"ავტორიზებული",
  Geo_Authorized:"გეო-მდებარეობა: ავტორიზებული",
  Not_Authorized:"არა ავტორიზებული",
  Authorization_Complete:"ავტორიზაცია დასრულებულია",
  Geolocation_Authorization:"გეო-მდებარეობის ავტორიზაცია",
  Out_of_Bounds:"საზღვრებს გარეთ",
  Cant_Delete_Default_Design:"ნაგულისხმევი დიზაინის წაშლა შეუძლებელია.",
  Unauthorized_Out_of_Bounds:"არაავტორიზებული: საზღვრებს გარეთ",
  Biometric_Authorization:"ბიომეტრიული ავტორიზაცია",
  Locating_Please_Wait:"მდებარეობა, გთხოვთ დაელოდოთ...",
  Test_Biometrics:"ტესტი ბიომეტრიკა",
  Cheque_Margins:"შეამოწმეთ მინდვრები",
  Percentage_Full_Error:"პროცენტული ველი არ შეიძლება დაყენდეს 100% პროცენტზე მეტი.",
  No_Payroll_Text:"დაამატეთ <a routerLink='/folder/Employee/New'>თანამშრომლი</a> ან <a routerLink='/folder/Payee/New'>გადახდის მიმღები</a> და <a routerLink='/folder/Schedule/New'>განრიგი</a>.",
  Design_Saved:"დიზაინი შენახულია",
  Default_Design_Selected:"არჩეულია ნაგულისხმევი დიზაინი",
  Partial_Import:"ნაწილობრივი იმპორტი",
  Partial_Export:"ნაწილობრივი ექსპორტი",
  Entire_Import:"მთლიანი იმპორტი",
  Entire_Export:"მთლიანი ექსპორტი",
  Existing_Password:"გთხოვთ, შეიყვანოთ თქვენი არსებული პაროლი:",
  Existing_PIN:"გთხოვთ, შეიყვანოთ თქვენი არსებული PIN კოდი:",
  Pin_Change_Header:"PIN დადასტურება",
  Pin_Change_SubHeader:"შეიყვანეთ თქვენი ძველი PIN ნომერი ცვლილების დასადასტურებლად.",
  Pass_Change_Header:"Პაროლის დადასტურება",
  Pass_Change_SubHeader:"შეიყვანეთ თქვენი ძველი პაროლი ცვლილების დასადასტურებლად.",
  PIN_Enter_Message:"დასადასტურებლად შეიყვანეთ თქვენი PIN.",
  Password_Enter_Message:"შეიყვანეთ თქვენი პაროლი დასადასტურებლად.",
  Pin_Updated_Success:"PIN-კოდი წარმატებით განახლდა!",
  Pin_Updated_Fail:"PIN ვერ განახლდა.",
  Pass_Updated_Success:"პაროლი წარმატებით განახლდა.",
  Pass_Updated_Fail:"პაროლი ვერ განახლდა.",
  Preview_Payment:"გადახდის წინასწარი გადახედვა",
  Preview_Payroll:"სახელფასო გადახედვა",
  No_Payments_Created:"გადახდები არ იქნა შექმნილი.",
  Payment_Preview:"გადახდის გადახედვა",
  Enable_Payee:"გადახდის მიმღების ჩართვა",
  Rendered:"გაწეული",
  No_Email:"ელფოსტა არ არის",
  Setup_Cheques:"დაყენების შემოწმებები",
  name:"სახელი",
  address:"მისამართი",
  address_2:"მისამართის ხაზი 2",
  city:"ქალაქი",
  province:"პროვინცია",
  postal_code:"საფოსტო/ ZIP კოდი",
  country:"ქვეყანა",
  username:"მომხმარებლის სახელი",
  full_name:"Სრული სახელი",
  birthday:"დაბადების დღე",
  email:"ფოსტა",
  phone:"ტელეფონი",
  employees:"თანამშრომლები",
  addresses:"მისამართები",
  payment_amount_limit:"გადახდის ოდენობის ლიმიტი",
  total_limit:"ჯამური ლიმიტი",
  payees:"გადამხდელები",
  description:"აღწერა",
  address_line_one:"მისამართის პირველი ხაზი",
  address_line_two:"მისამართის ხაზი მეორე",
  image:"გამოსახულება",
  account_holder:"Ანგარიშის მფლობელი",
  cheque_starting_id:"შეამოწმეთ საწყისი ID",
  transit_number:"ტრანზიტის ნომერი",
  institution_number:"დაწესებულების ნომერი",
  designation_number:"აღნიშვნის ნომერი",
  account_number:"Ანგარიშის ნომერი",
  currency:"ვალუტა",
  signature:"ხელმოწერა",
  payment_payroll_limit:"გადახდის ლიმიტი",
  total_limi:"ჯამური ლიმიტი",
  date:"თარიღი",
  printed_memo:"ნაბეჭდი მემორანდუმი",
  banks:"ბანკები",
  signature_image:"ხელმოწერის სურათი",
  address_name:"Მისამართი",
  notes:"შენიშვნები",
  design:"დიზაინი",
  title:"სათაური",
  frequency:"სიხშირე",
  fax:"ფაქსი",
  salaries:"ხელფასები",
  salary_ids:"ხელფასის პირადობის მოწმობები",
  start_time:"დაწყების დრო",
  end_time:"Დროის დასასრული",
  paid:"გადახდილი",
  overtime_percentage:"გადახდილი პროცენტი",
  overtime_amount:"გადახდილი ფიქსირებული თანხა",
  first_name:"Სახელი",
  last_name:"Გვარი",
  moderation:"ზომიერება",
  create:"Შექმნა",
  edit:"რედაქტირება",
  destroy:"გაანადგურე",
  day_start_time:"დღის_დაწყების_დრო",
  day_end_time:"დღის_დამთავრების_დრო",
  fullname:"სახელი",
  time:"დრო",
  auto_send:"ავტომატური გაგზავნა",
  time_method:"დროის მეთოდი",
  schedules:"განრიგი",
  indefinite_payroll_enabled:"განუსაზღვრელი ვადით ჩართვა",
  amount:"თანხა",
  repeat:"გაიმეორეთ",
  always_enabled:"ყოველთვის ჩართულია",
  start_date:"Დაწყების თარიღი",
  end_date:"Დასრულების თარიღი",
  Cheque_Total:"შეამოწმეთ სულ",
  Total_Amount:"Მთლიანი რაოდენობა:",
  Amounts:"თანხები:",
  Images:"სურათები",
  Files:"ფაილები",
  Previewing:"გადახედვა:",
  Insert:"ჩასმა",
  Preview_Import:"წინასწარი იმპორტი",
  Entry:"შესვლა",
  Entries:"ჩანაწერები",
  No_Entries:"ჩანაწერები არ არის",
  Import_Type:"იმპორტის ტიპი",
  Select_Details:"აირჩიეთ დეტალები",
  Select_Payee:"აირჩიეთ გადახდის მიმღები",
  Enable_Holidays:"ჩართეთ არდადეგები",
  Disable_Holidays:"გამორთეთ არდადეგები",
  Wire_Transfer:"Საკაბელო გადაცემა",
  New_Export:"ახალი ექსპორტი",
  Export_Data:"მონაცემების ექსპორტი",
  Export_Data_Word:"აირჩიეთ ფაილის ტიპი ექსპორტისთვის და გადმოსაწერად.",
  Export_File:"ფაილის ექსპორტი",
  Mode:"რეჟიმი",
  Times:"ჯერ",
  Widgets:"ვიჯეტები",
  Slider:"სლაიდერი",
  Set_Details:"დეტალების დაყენება",
  Select_Type:"აირჩიეთ ტიპი",
  Display_Slider:"ჩვენების სლაიდერი",
  Dashboard_Slider:"დაფის სლაიდერი",
  Dashboard_Mode:"დაფის რეჟიმი",
  Show_Intro:"შესავლის ჩვენება",
  Show_Payrolls:"ხელფასების ჩვენება",
  Show_Holidays:"არდადეგების ჩვენება",
  Show_Invoices:"ინვოისების ჩვენება",
  Show_Cheques:"ჩეკების ჩვენება",
  Enabled_Widgets:"ჩართული ვიჯეტები",
  Disabled_Widgets:"გამორთული ვიჯეტები",
  Colors:"Ფერები",
  Barcodes:"შტრიხკოდები",
  View_Timers:"ტაიმერების ნახვა",
  Gradients:"გრადიენტები",
  No_Info:"არანაირი ინფორმაცია",
  Disable:"გამორთვა",
  Show_Layer:"ფენების ჩვენება",
  Hide_Layer:"ფენების დამალვა",
  Text_Layer:"ტექსტის ფენები",
  Secondly:"მეორეც",
  Minutely:"წუთში",
  nine_am:"დილის 9:00 საათი",
  five_pm:"17:00 საათი",
  Enable_Address:"მისამართის ჩართვა",
  Invalid_File_Type:"უკაცრავად, არჩეულია ფაილის არასწორი ტიპი. მხარდაჭერილი ფაილის ტიპი:",
  Error_Updating_Entry:"უკაცრავად, ამ ჩანაწერის განახლებისას მოხდა შეცდომა.",
  Schedule_Timing_Alert:"შეცდომა: განრიგის დაწყების დრო დაყენებულია მნიშვნელობაზე დასრულების დროის შემდეგ.",
  Select_Multiple_Payments_Prompt:"გადახდები არ არის არჩეული. აირჩიეთ მინიმუმ ერთი გადახდა.",
  Select_Multiple_Cheques_Prompt:"შემოწმებები არ არის არჩეული. გთხოვთ, აირჩიოთ მინიმუმ ერთი ჩეკი.",
  Select_Multiple_Items_Prompt:"არჩეული ელემენტი არ არის. გთხოვთ, აირჩიოთ მინიმუმ ერთი ელემენტი.",
  Paymemt_Multiple_Delete_Confirmation:"დარწმუნებული ხართ, რომ გსურთ წაშალოთ მრავალი გადახდა?",
  Cheque_Multiple_Delete_Confirmation:"დარწმუნებული ხართ, რომ გსურთ წაშალოთ მრავალი შემოწმება?",
  Payee_Multiple_Delete_Confirmation:"დარწმუნებული ხართ, რომ გსურთ წაშალოთ მრავალი გადამხდელი?",
  Employee_Multiple_Delete_Confirmation:"დარწმუნებული ხართ, რომ გსურთ წაშალოთ რამდენიმე თანამშრომელი?",
  MICR_Warning:"შენიშვნა: ზოგიერთმა პრინტერმა და მოწყობილობამ შეიძლება სწორად არ გამოაჩინოს MICR შრიფტი.",
  Automatically_Send:"ავტომატური გაგზავნა",
  Type:"ტიპი",
  Payment_Type:"Გადახდის ტიპი",
  Auto_Send:"ავტომატური გაგზავნა",
  Automatically_Process:"ავტომატური დამუშავება",
  Auto_Process:"ავტომატური პროცესი",
  Image_Based:"გამოსახულებაზე დაფუძნებული",
  Font_Based:"შრიფტზე დაფუძნებული",
  Rerender:"ხელახლა რენდერი",
  Rendering:"რენდერირება",
  Render:"ფაილები",
  Top:"ზედა",
  Middle:"Შუა",
  Bottom:"ქვედა",
  Top_Left:"Ზედა მარცხენა",
  Top_Center:"ტოპ ცენტრი",
  Top_Right:"Ზედა მარჯვენა",
  Middle_Left:"შუა მარცხენა",
  Middle_Center:"შუა ცენტრი",
  Middle_Right:"შუა მარჯვენა",
  Bottom_Left:"Ქვედა მარცხენა",
  Bottom_Center:"ქვედა ცენტრი",
  Bottom_Right:"ქვედა მარჯვენა",
  Numbers:"ნომრები",
  Verified:"დამოწმებულია",
  Paper_Size:"ქაღალდის ზომა",
  New_Device:"ახალი მოწყობილობა",
  Add_Device:"მოწყობილობის დამატება",
  Remove_Device:"მოწყობილობის ამოღება",
  Delete_Device:"მოწყობილობის წაშლა",
  Block_Device:"მოწყობილობის დაბლოკვა",
  Block:"ბლოკირება",
  Unblock:"განბლოკვა",
  Table:"მაგიდა",
  Scan_Login_Code:"შესვლის კოდის სკანირება",
  Login_Code:"შესვლის კოდი",
  Scan_Code:"კოდის სკანირება",
  Scan_QR_Code:"QR კოდის სკანირება",
  Select_All:"Მონიშნე ყველა",
  Deselect_All:"Გააუქმეთ ყველა მონიშვნა",
  Increase:"Მომატება",
  Decrease:"შემცირება",
  Bold:"თამამი",
  Text:"ტექსტი",
  Style:"სტილი",
  Italic:"დახრილი",
  QR_Code:"Ქრ კოდი",
  Barcode:"შტრიხკოდი",
  Browse_Images:"სურათების დათვალიერება",
  Browse_Files:"ფაილების დათვალიერება",
  Background_Image:"ფონის სურათი",
  Logo_Image:"ლოგოს სურათი",
  Header_Image:"სათაურის გამოსახულება",
  Bank_Image:"ბანკის სურათი",
  Cut_Lines:"გაჭრა ხაზები",
  Background:"ფონი",
  License:"ლიცენზია",
  One_License:"1 ლიცენზია:",
  Licensed:"ლიცენზირებული:",
  Not_Licensed:"არა ლიცენზირებული",
  Enter_Serial:"შეიყვანეთ სერიალი",
  Serial_Key:"Სერიული გასაღები",
  Serial:"სერიალი",
  Product_Key:"Პროდუქტის გასაღები",
  Check_Product_Key:"შეამოწმეთ პროდუქტის გასაღები",
  Add_Product_Key:"დაამატეთ პროდუქტის გასაღები",
  Verifying_Purchase:"მიმდინარეობს შესყიდვის დადასტურება...",
  Print_Envelope:"კონვერტის დაბეჭდვა",
  Envelope:"კონვერტი",
  Thank_You:"Გმადლობთ!",
  Scale:"მასშტაბი",
  Print_Scale:"ბეჭდვის მასშტაბი",
  Borders:"საზღვრები",
  VOID:"ბათილად",
  Void_Cheque:"ბათილი შემოწმება",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"გადაიხადეთ შეკვეთით:",
  NO_DOLLARS:"დოლარის გარეშე ",
  ONE_DOLLAR:"ᲔᲠᲗᲘ ᲓᲝᲚᲐᲠᲘ",
  DOLLARS:" დოლარი",
  AND:" და ",
  CENTS:" CENTS.",
  NO_:"არა ",
  ONE_:"ერთი ",
  AND_NO_:"ᲓᲐ ᲐᲠᲐ ",
  _AND_ONE_:"ᲓᲐ ᲔᲠᲗᲘ ",
  DOLLARS_AND_ONE_CENT:" და ერთი ცენტი.",
  AND_NO_CENTS:" და ნულოვანი ცენტი.",
  CHEQUE_PRINT_DATE:"თარიღი:",
  CHEQUE_PRINT_MEMO:"შენიშვნა:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"დეპუტატი",
  Initial_Tasks:"საწყისი ამოცანები",
  Inital_Setup:"საწყისი დაყენება",
  Welcome_To:"Კეთილი იყოს თქვენი მობრძანება ",
  Welcome:"მოგესალმებით",
  Swipe:"გადაფურცვლა",
  Intro_Setup:"შესავალი დაყენება",
  Introduction:"შესავალი",
  CHEQUE_PRINT_CREDIT:"ჩეკებით უზრუნველყოფილი",
  Title_Intro_Word:"კეთილი იყოს თქვენი მობრძანება ჩეკებში",
  Title_Intro:"ჩეკების შესავალი",
  Title_Setup:"ჩეკების დაყენება",
  PayPal_Default_Email_Message:"თქვენ მიიღეთ ახალი PayPal გადარიცხვა. [მოწოდებულია ჩეკებით]",
  Cheques_App_Export:"ექსპორტირებული ჩეკებით",
  Post_Intro_Thanks:"გმადლობთ, რომ აირჩიეთ ჩეკები. დაყენების პროცესი ახლა დასრულებულია.",
  Post_Intro_Word:"დაიწყეთ თქვენი პირველი ჩეკის ამობეჭდვით, დაამატეთ მომავალი გადახდა ან დაამატეთ თანამშრომლები სახელფასო სიაში.",
  Upgrade_Required:"ჩეკები მოითხოვს თქვენ ფლობდეთ პროგრამული უზრუნველყოფის უფრო პრემიუმ ვერსიას, რათა დამალოთ ეს შეტყობინება და განბლოკოთ დამატებითი ფუნქციები.",
  Upgrade_Title:"ჩეკები",
  Mailout_Prompt:"შეგიძლიათ დამატებით აირჩიოთ ჩეკების გაგზავნა თქვენი სახელფასო ჩეკები თქვენთვის.",
  Mailed_Cheque:"ფოსტით გაგზავნილი შემოწმება: ",
  Mailed_Cheque_Color:"ფოსტით გაგზავნილი შემოწმება (ფერი): ",
  Terms_Purchase:"ჩეკებით ყველა ელექტრონული შესყიდვა სრულად ანაზღაურდება 30 დღის განმავლობაში შეძენის დღიდან. გთხოვთ, წაიკითხოთ და დაეთანხმოთ <a href='#'>წესებსა და პირობებს</a> სანამ გააგრძელებთ.",
  Dashboard_Setup:"პირველადი პრინტერის დაყენება",
  Dashboard_Add:"დაამატე ძირითადი საბანკო ანგარიში",
  Dashboard_Customize:"აირჩიეთ შემოწმების შაბლონი",
  Dashboard_Job_Salary:"შექმენით თქვენი სამუშაო და დაამატეთ თქვენი ხელფასი",
  Dashboard_Employees:"ადევნეთ თვალი თანამშრომლებს და გადამხდელებს",
  Dashboard_Print:"დაბეჭდეთ და გაგზავნეთ თქვენი ხელფასი",
  Dashboard_Payroll:"თქვენი სახელფასო ბეჭდვის ავტომატიზაცია",
  Dashboard_PayPal:"PayPal სახელფასო ფონდის/გადახდების დაყენება",
  Begin_Setup:"დაყენების დაწყება",
  Get_Started:"Დაიწყე",
  Purchase:"Შესყიდვა",
  Lockdown:"Მკაცრი იზოლაცია",
  Unlock:"განბლოკვა",
  Detailed:"დეტალური",
  Log_In:"Შესვლა",
  Login:"Შესვლა",
  Launch:"გაშვება",
  Register:"რეგისტრაცია",
  Finish:"დასრულება",
  List:"სია",
  Weekends:"შაბათ-კვირა",
  Weekly:"კვირეული",
  PayPal_Default_Subject:"ახალი გადახდა",
  Payment_Message:"გადახდის შეტყობინება",
  PayPal_Default_Payment_Note:"Გმადლობთ",
  Setup_Your_Cheqing_Account:"ანგარიშის შემოწმება",
  Add_Your_Primary_Cheqing_Account:"დაამატეთ თქვენი ძირითადი შემოწმების ანგარიში.",
  Welcome_Word:"ხელფასის და ადამიანური რესურსების მართვის გამარტივება და ავტომატიზაცია.",
  Get_Started_Quickly:"უბრალოდ უპასუხეთ რამდენიმე მარტივ კითხვას, რომელიც დაგვეხმარება დაწყებაში...",
  Done_Intro_Word:"დაყენება დასრულებულია",
  PIN_Protected:"პაროლი და PIN დაცულია",
  Enter_New_PIN:"შეიყვანეთ ახალი PIN კოდი:",
  Enter_PIN:"შეიყვანეთ PIN კოდი:",
  Invalid_PIN:"შეყვანილია არასწორი PIN.",
  Account_Identifier:"ანგარიშის იდენტიფიკატორი",
  New_Account_Identifier:"ახალი ანგარიშის იდენტიფიკატორი",
  attempt:"მცდელობა",
  attempts:"მცდელობები",
  remaining:"დარჩენილი",
  Language:"Ენა",
  languages:"ენები",
  select_languages:"Აირჩიეთ ენა",
  Deposit:"ანაბარი",
  Hire_One_Now:"დაიქირავეთ ერთი ახლა",
  App_Locked:"აპლიკაცია დაბლოკილია.",
  Skip_:"გამოტოვება",
  Skip_to_Dashboard:"გადასვლა დაფაზე",
  Dashboard:"დაფა",
  Import:"იმპორტი",
  Admin:"ადმინისტრატორები",
  New_Admin:"ახალი ადმინი",
  Settings:"პარამეტრები",
  Color_Picker:"ფერების ამომრჩევი",
  Font_Picker:"ფონტის ამომრჩევი",
  Logout:"გამოსვლა",
  Close:"დახურვა",
  Close_menu:"დახურვა",
  Excel:"Excel ფაილი",
  Csv:"CSV ფაილი",
  Sql:"SQL ფაილი",
  Json:"JSON ფაილი",
  Url:"იმპორტი URL-ით",
  Back:"უკან",
  Timers:"ტაიმერი",
  Cheque:"Ჩეკი",
  Print:"ბეჭდვა",
  Designs:"დიზაინი",
  Pause_Printing:"დაპაუზება ბეჭდვა",
  Resume_Printing:"რეზიუმე ბეჭდვა",
  Printing_Paused:"ბეჭდვა შეჩერებულია",
  PrintingUnavailable:"ბოდიში! ბეჭდვა მიუწვდომელია ამ სისტემაზე.",
  Prints_Paused:"ანაბეჭდები შეჩერებულია",
  Administration:"ადმინისტრაცია",
  Loading:"Ჩატვირთვა",
  Unnamed:"უსახელო",
  error:"ბოდიში, ეს შემოწმება ვერ გაიხსნა სანახავად.",
  No_Cheques_To_Print:"არ არის ჩეკები დასაბეჭდად",
  No_Cheques_To_Print_Word:"შექმენით <a routerLink='/folder/Cheque/New'>ახალი ჩეკი</a>.",
  New_Cheque:"ახალი ჩეკი",
  Start_One_Now:"დაიწყე ერთი ახლა",
  Edit_Cheque:"შემოწმების რედაქტირება",
  Select_Cheques:"აირჩიეთ ჩეკები",
  Select_Employee:"აირჩიე თანამშრომელი",
  Default_Printer:"ნაგულისხმევი პრინტერი",
  Reassign:"ხელახლა მინიჭება",
  Configure:"კონფიგურაცია",
  Template:"თარგი",
  Designer:"დიზაინერი",
  Edit_Designs:"დიზაინის რედაქტირება",
  New_Design:"Ახალი დიზაინი",
  Next:"შემდეგი",
  Save:"Შენახვა",
  Name:"სახელი",
  Mail:"ფოსტა",
  Amount:"თანხა",
  Date:"თარიღი",
  PayPal:"PayPal",
  Payouts:"გადახდები",
  PayPal_Label:"PayPal Label",
  Email_Username:"ელფოსტა / მომხმარებლის სახელი",
  Client_ID:"კლიენტის ID",
  Sandbox_Email:"Sandbox Email",
  PayPal_Email:"PayPal Email",
  PayPal_Username:"API მომხმარებლის სახელი",
  PayPal_Payouts:"PayPal Payouts",
  Select_PayPal_Key:"აირჩიეთ PayPal გასაღები",
  Secret:"საიდუმლო",
  API_Secret:"API Secret",
  PayPal_API_Keys:"PayPal Keys",
  New_PayPal_Key:"ახალი PayPal გასაღები",
  Email_Subject:"ელფოსტის თემა",
  Email_Message:"ელფოსტის შეტყობინება",
  Payout_Options:"გადახდის ვარიანტები",
  Payment_Note:"გადახდის შენიშვნა",
  Enable_Employee:"ჩართეთ თანამშრომელი",
  Enable_Production_Mode:"წარმოების რეჟიმის ჩართვა",
  Sandbox_Username:"Sandbox მომხმარებლის სახელი",
  Sandbox_Signature:"Sandbox Signature",
  Sandbox_Password:"Sandbox პაროლი",
  Production_Username:"წარმოების მომხმარებლის სახელი",
  Production_Signature:"წარმოების ხელმოწერა",
  Production_Password:"წარმოების პაროლი",
  Production_Email:"წარმოების ელექტრონული ფოსტა",
  API_Client_ID:"API კლიენტის ID",
  API_Signature:"API ხელმოწერა",
  API_Password:"API პაროლი",
  API_Username:"API მომხმარებლის სახელი",
  Secret_Key:"საიდუმლო გასაღები",
  Sandbox:"ქვიშის ყუთი",
  Production:"წარმოება",
  Sandbox_Keys:"Sandbox Keys",
  Production_Keys:"წარმოების გასაღებები",
  API_Title:"API სათაური",
  Production_Mode:"წარმოების რეჟიმი",
  Account_Label:"ანგარიშის ლეიბლი",
  No_PayPal_Setup:"არა PayPal გასაღები",
  Enable_PayPal_Account:"PayPal ანგარიშის ჩართვა",
  No_PayPal_Word:"დაამატეთ თქვენი <a routerLink='/folder/Invoice/New'>PayPal API გასაღები</a>.",
  Printed_Memo:"დაბეჭდილი მემორანდუმი",
  Employee:"თანამშრომელი",
  View_Employee:"თანამშრომლის ნახვა",
  Mailing_Address:"Საფოსტო მისამართი",
  Company_Address:"Კომპანიის მისამართი",
  Select_Company_Address:"აირჩიეთ კომპანიის მისამართი",
  Address:"მისამართი",
  Any_Day:"ნებისმიერი დღე",
  Address_Name:"Მისამართი",
  Unit:"ერთეული",
  Account:"ანგარიში",
  Bank_Account:"Საბანკო ანგარიში",
  Account_Limits:"ანგარიშის ლიმიტების ჩართვა",
  Payroll:"ხელფასი",
  New_Payroll:"ახალი სახელფასო",
  No_Payroll:"მომავალი სახელფასო თანხა არ არის",
  Upcoming_Holiday:"მოახლოებული დღესასწაული:",
  Invoice_Due:"გადასახდელი ინვოისი:",
  New_Invoice:"ახალი ინვოისი",
  No_Invoices:"ინვოისების გარეშე",
  No_Invoices_Word:"შექმენით პირველი <a routerLink='/folder/Invoice/New'>ინვოისი</a>.",
  Cheque_Due:"ჩეკის ვადა:",
  Payrolls:"ხელფასები",
  Sandbox_Mode:"Sandbox რეჟიმი",
  Hire:"Დაქირავება",
  Pay:"გადახდა",
  New:"ახალი",
  Add:"დამატება",
  Make:"Გააკეთოს",
  Time:"დრო",
  Write:"დაწერე",
  Holiday:"დღესასწაული",
  Holidays:"დღესასწაულები",
  Next_Holiday:"Შემდეგი არდადეგები:",
  All_Done:"Ყველაფერი შესრულებულია!",
  Employees:"თანამშრომლები",
  Payees:"გადამხდელები",
  Job:"Სამუშაო",
  Jobs:"სამუშაოები",
  Invoice:"ინვოისი",
  Invoices:"ინვოისები",
  Vacations:"შვებულება",
  Cheques:"ჩეკები",
  Brand_Cheques:"ბრენდი",
  Payment:"გადახდა",
  Enable_Payment:"გადახდის ჩართვა",
  Payments:"გადახდები",
  Schedule:"განრიგი",
  Schedules:"განრიგი",
  Timesheet:"Დრო ფურცელი",
  Timesheets:"დროის ფურცლები",
  Salary:"ხელფასი",
  New_Address:"Ახალი მისამართი",
  Address_2:"მისამართი (ხაზი 2)",
  _City:"ქალაქი",
  _State:"სახელმწიფო/პროვი",
  City:"ქალაქი / ქალაქი",
  State:"სახელმწიფო / პროვინცია",
  Postal:"საფოსტო / საფოსტო კოდი",
  ZIP:"ფოსტა / ZIP",
  Country:"ქვეყანა",
  Addresses:"მისამართები",
  Required_Options:"საჭირო პარამეტრები",
  Optional_Options:"სურვილისამებრ პარამეტრები",
  Additional_Options:"დამატებითი პარამეტრები",
  Required:"აუცილებელი",
  Optional:"სურვილისამებრ",
  Additional:"დამატებითი",
  No_Addresses:"მისამართების გარეშე",
  New_Address_Word:"დაამატე პირველი <a routerLink='/folder/Address/New'>მისამართი</a>.",
  Select_Address:"აირჩიეთ მისამართი",
  No_Address:"მისამართების გარეშე",
  Print_Cheque:"ბეჭდვის შემოწმება",
  Print_Cheque_Now:"დაბეჭდვა შეამოწმეთ ახლა",
  Description:"აღწერა",
  Pay_To_The_Order_Of:"გადაიხადე შეკვეთაზე:",
  Select_Date_Range:"აირჩიეთ თარიღის დიაპაზონი",
  Select_Starting_Date:"აირჩიეთ დაწყების თარიღი",
  Select_Ending_Date:"აირჩიეთ დასრულების თარიღი",
  Select_Date:"აირჩიეთ თარიღი",
  Cheque_Date:"შემოწმების თარიღი",
  Cheque_Memo:"შეამოწმეთ მემო",
  Blank_Cheque:"ცარიელი ჩეკი",
  Blank:"ცარიელი",
  No_Cheques:"შემოწმების გარეშე",
  No_Cheques_Word:"დაბეჭდეთ თქვენი პირველი <a routerLink='/folder/Cheque/New'>შემოწმება</a>.",
  Cheque_Amount_Placeholder:"0.00$",
  View_Image:"Სურათის ნახვა",
  View:"ნახვა",
  Modify:"შეცვლა",
  Delete:"წაშლა",
  Cheque_Paid:"გადახდილი",
  New_Deduction:"ახალი გამოქვითვა",
  Title:"სათაური",
  Frequency:"სიხშირე",
  Hourly:"საათიანი",
  Daily:"ყოველდღიური",
  Weekdays:"Კვირის დღეები",
  BiWeekly:"2 კვირა",
  TriWeekly:"3 კვირა",
  Monthly:"თვიური",
  MiMonthly:"2 თვე",
  Quarterly:"კვარტალური",
  Yearly:"წლიურად",
  Every_Week:"Ყოველ კვირა",
  Every_Payroll:"ყოველი სახელფასო",
  Every_Month:"Ყოველ თვე",
  Annually:"წლიურად",
  Always_Scheduled:"ყოველთვის დაგეგმილი",
  Start_Date:"Დაწყების თარიღი",
  End_Date:"Დასრულების თარიღი",
  Start_Time:"დაწყების დრო",
  End_Time:"Დროის დასასრული",
  Deduction_Label:"გამოქვითვის ეტიკეტი",
  Notes:"შენიშვნები",
  Options:"Პარამეტრები",
  Enable:"ჩართვა",
  Select_Deductions:"აირჩიეთ გამოქვითვები",
  Deductions:"გამოქვითვები",
  No_Deductions:"გამოქვითვების გარეშე",
  No_Deductions_Word:"შექმენით თქვენი პირველი <a routerLink='/folder/Deduction/New'>გამოქვითვა</a>.",
  New_Employee:"Ახალი თანამშრომელი",
  No_Title:"Უსათაურო",
  _Name:"სახელი",
  About_Yourself:"შენს შესახებ",
  Full_Name:"Სრული სახელი",
  Birthday:"დაბადების დღე",
  Email:"ელ.ფოსტა",
  SMS:"ᲔᲡᲔᲛᲔᲡᲘ",
  Phone:"ტელეფონი",
  Test:"ტესტი",
  Call:"ზარი",
  Fax:"ფაქსი",
  Printed_Note:"დაბეჭდილი შენიშვნა",
  Position:"პოზიცია",
  Job_Position:"Თანამდებობა",
  Select_a_Job:"აირჩიე სამუშაო",
  Select_a_Salary:"აირჩიე ხელფასი",
  Add_a_Deduction:"დაამატე გამოქვითვა",
  Taxes:"Გადასახადები",
  Add_Taxes:"გადასახადების დამატება",
  Date_of_Birth:"Დაბადების თარიღი",
  Email_Address:"Ელექტრონული მისამართი",
  Phone_Number:"Ტელეფონის ნომერი",
  Phone_Call:"Სატელეფონო ზარი",
  Enable_on_Payroll:"ხელფასის ჩართვა",
  Select_Employees:"აირჩიეთ თანამშრომლები",
  No_Employees:"არა თანამშრომლები",
  No_Employees_Word:"დაამატეთ თქვენი პირველი ახალი <a routerLink='/folder/Employee/New'>თანამშრომლები</a>.",
  No_Name:"Სახელის გარეშე",
  Unemployeed:"უმუშევარი",
  Employeed:"დასაქმებული",
  Paid:"გადახდილი",
  Enabled:"ჩართულია",
  Disabled:"ინვალიდი",
  Fire:"ცეცხლი",
  Not_Available:"Მიუწვდომელია",
  Not_Available_Word:"დაბეჭდეთ თქვენი პირველი <a routerLink='/folder/Invoice/New'>ინვოისი</a> და მიიღეთ თანხა.",
  Select_Invoices:"Select_Invoices",
  Print_Invoice_Word:"დაბეჭდეთ თქვენი პირველი <a routerLink='/folder/Invoice/New'>ინვოისი</a> და მიიღეთ თანხა.",
  Invoice_Title:"ინვოისის სათაური",
  Invoice_Date:"ინვოისის თარიღი",
  Due_Date:"Თარიღის თანახმად",
  New_Job:"Ახალი სამუშაო",
  Details:"დეტალები",
  Customize:"მორგება",
  Customize_Dashboard:"დაფის მორგება",
  Components:"კომპონენტები",
  No_Components:"კომპონენტების გარეშე",
  Main_Components:"მთავარი კომპონენტები",
  Smaller_Components:"პატარა კომპონენტები",
  Error_Loading_Page:"შეცდომა ამ გვერდის ჩატვირთვისას.",
  Bank_Details:"Საბანკო დეტალები",
  About_Your_Job:"შენი სამუშაოს შესახებ",
  Your_Schedule:"თქვენი განრიგი",
  Job_Title:"Თანამდებობა",
  Job_Description:"Სამსახურის აღწერა",
  Job_Details:"სამსახურის დეტალები",
  Enable_Job:"ჩართვა სამუშაო",
  Pay_Period:"გადახდის პერიოდი",
  Perpetually_Schedule:"მუდმივი განრიგი",
  Select_Jobs:"აირჩიეთ ვაკანსიები",
  No_Jobs:"სამუშაო არ არის",
  Add_Jobs:"დაამატე ვაკანსიები",
  No_Jobs_Word:"დაამატეთ სიაში პირველი <a routerLink='/folder/Job/New'>სამუშაო</a>.",
  Count_Employees:"job.employee_count+' Employees'",
  Zero_Employees:"0 თანამშრომელი",
  New_Leave:"ახალი შვებულება",
  Leave_Name:"დატოვე სახელი",
  Paid_Leave:"Ანაზღაურებადი შვებულება",
  Leave_Pay:"გადახდების დატოვება",
  Indefinite_Leave:"განუსაზღვრელი შვებულება",
  Indefinite_Payroll:"გაურკვეველი სახელფასო ფონდი",
  Leave:"დატოვე",
  Add_Schedules:"განრიგის დამატება",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"არყოფნის ჩართვა",
  Select_Leaves:"აირჩიე ფოთლები",
  No_Leaves:"უარსობის ფურცლები",
  Leave_Of_Absence:"არყოფნის შვებულება",
  Leaves_Of_Absence:"არყოფნის ფოთლები",
  New_Leave_of_Absense:"ახალი შვებულება",
  No_Leaves_Word:"დაამატეთ პირველი <a routerLink='/folder/Leave/New'>არყოფნის შვებულება</a>.",
  Not_Enabled:"არ არის ჩართული",
  Absences:"არყოფნები",
  Payee:"გადახდის მიმღები",
  New_Payee:"ახალი მიმღები",
  Payee_Identifier:"გადახდის მიმღების იდენტიფიკატორი",
  Payee_Name:"Გადამხდელის სახელი",
  Payee_Title:"გადახდის მიმღების წოდება",
  Payment_Memo:"გადახდის მემორანდუმი",
  Select_Payees:"აირჩიე გადამხდელები",
  No_Payees:"არ არის გადამხდელი",
  Add_Payee_Note:"დაამატე პირველი <a routerLink='/folder/Payee/New'>გადახდის მიმღები</a>.",
  New_Payees:"ახალი გადამხდელები",
  About_The_Payment_Schedule:"Გადახდის გრაფიკი",
  Your_Payroll_Schedule:"ავტომატური სახელფასო",
  New_Payment:"ახალი გადახდა",
  Identifier:"იდენტიფიკატორი",
  Select:"აირჩიე",
  Memo:"მემო",
  Payment_Date:"Გადახდის დღე",
  Mark_as_Paid:"მონიშვნა, როგორც გადახდილი",
  Select_Payments:"აირჩიეთ გადახდები",
  No_Payments:"გადახდების გარეშე",
  No_Payments_Word:"შექმენით პირველი <a routerLink='/folder/Payment/New'>გადახდა</a>.",
  Create_Payroll:"შექმენით სახელფასო სია",
  Properties:"Თვისებები",
  Payroll_Title:"ხელფასის დასახელება",
  Payroll_Notes:"ხელფასის შენიშვნები",
  Payroll_Setup:"ხელფასის დაყენება",
  Tabulate_Payments:"გადახდების ცხრილი",
  Tabulate:"ტაბულა",
  By:"by:",
  Payments_By:"გადახდები მიერ",
  Timesheets_And_Schedules:"დროის ცხრილები და განრიგი",
  Both:"ორივე",
  Items:"ნივთები",
  Add_Payees:"გადამხდელების დამატება",
  Add_Account:"ანგარიშის დამატება",
  Enable_Account:"ანგარიშის ჩართვა",
  Enable_Payroll:"ხელფასის ჩართვა",
  Print_Payroll:"ხელფასის დაბეჭდვა",
  No_Payrolls:"ხელფასის გარეშე",
  No_Payroll_Word:"შექმენით თქვენი პირველი <a routerLink='/folder/Payroll/New'>ხელფასის სია</a>.",
  View_more:"VIEW_MORE",
  Less:"ᲜᲐᲙᲚᲔᲑᲘ",
  Add_Payroll:"ხელფასის დამატება",
  Select_Payroll:"აირჩიე სახელფასო",
  About_Your_Salary:"თქვენი ხელფასის შესახებ",
  Add_Salaries:"დაამატე ხელფასები",
  Add_Salary:"ხელფასის დამატება",
  Salaries:"ხელფასები",
  No_Salaries:"ხელფასის გარეშე",
  No_Salaries_Word:"დაამატეთ პირველი <a routerLink='/folder/Salary/New'>ხელფასი</a>.",
  Select_Salaries:"აირჩიეთ ხელფასები",
  New_Salary:"ახალი ხელფასი",
  Salary_Name:"ხელფასის იდენტიფიკატორი",
  Enable_Salary:"ხელფასის ჩართვა",
  Salary_Amount:"ხელფასის ოდენობა",
  New_Schedule:"ახალი განრიგი",
  Schedule_Title:"განრიგის სათაური",
  Add_Address:"მისამართის დამატება",
  Repeat:"გაიმეორე",
  Design:"დიზაინი",
  Edit_Design:"დიზაინის რედაქტირება",
  Edit_this_Design:"ამ დიზაინის რედაქტირება",
  Repeat_Payment:"განმეორებითი გადახდა",
  Enable_Schedule:"განრიგის ჩართვა",
  Never:"არასოდეს",
  Select_Schedule:"აირჩიეთ განრიგი",
  No_Schedules:"განრიგის გარეშე",
  No_Schedules_Word:"შექმენით პირველი <a routerLink='/folder/Schedule/New'>განრიგი</a>.",
  New_Administrator:"ახალი ადმინისტრატორი",
  Username:"მომხმარებლის სახელი",
  First_Name:"Სახელი",
  Last_Name:"Გვარი",
  Add_an_Address:"დაამატე მისამართი",
  Payment_Limit:"თითო გადახდის ლიმიტი",
  Total_Limit:"სულ ლიმიტი",
  Select_Accounts:"აირჩიეთ ანგარიშები",
  No_Administrators:"ადმინისტრატორები არ არიან",
  No_Administrators_Word:"შექმენით პირველი <a routerLink='/folder/Administrator/New'>ადმინისტრატორის ანგარიში</a>.",
  New_Administrators_Word:"დაამატე პირველი <a routerLink='/folder/Administrator/New'>ადმინისტრატორი</a>",
  Cloud:"ღრუბელი",
  Backup:"სარეზერვო",
  Enable_iCloud:"ჩართეთ iCloud",
  Enable_Google_Drive:"ჩართეთ Google Drive",
  Enable_Microsoft_OneDrive:"ჩართეთ Microsoft OneDrive",
  Automatically_Backup:"ავტომატური სარეზერვო ასლის შექმნა",
  FTP_Settings:"FTP პარამეტრები",
  URL_File_Prompt:"გთხოვთ, მიუთითოთ .xls / .xlsx / .json ფაილის ადგილმდებარეობა იმპორტისთვის:",
  URL_SQL_Prompt:"გთხოვთ, მიუთითოთ SQLite ფაილის მდებარეობა იმპორტისთვის:",
  FTP_Backup:"FTP სარეზერვო",
  FTP_Import:"FTP იმპორტი",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"მასპინძელი",
  Port:"პორტი",
  Path:"გზა",
  Data_Path:"მონაცემთა გზა",
  Enable_FTP_Account:"ჩართეთ FTP ანგარიში",
  HostnameIP:"Მასპინძლის სახელი",
  Password:"პაროლი",
  Connection_Port:"დაკავშირების პორტი",
  Enable_MySQL_Database:"ჩართეთ MySQL მონაცემთა ბაზა",
  Log:"ლოგი",
  Reset:"გადატვირთვა",
  Erase:"წაშლა",
  Export:"ექსპორტი",
  Database:"Მონაცემთა ბაზა",
  Upload_CSV:"ატვირთვა CSV",
  Download_CSV:"ჩამოტვირთეთ CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL სარეზერვო ასლი",
  Internal_Notes:"შიდა შენიშვნები",
  Root_Path:"ძირეული გზა",
  Select_Backup:"აირჩიეთ სარეზერვო საშუალება",
  Add_New_Backup:"ახალი სარეზერვო ასლის დამატება",
  First_Backup:"პირველი სარეზერვო ასლის დაყენება...",
  Backups:"სარეზერვო ასლები",
  Add_Backup:"სარეზერვო ასლის დამატება",
  No_Backups:"სარეზერვო ასლები არ მოიძებნება.",
  Select_Backup_Type:"აირჩიეთ სარეზერვო ასლის ტიპი, რომლის დაყენებაც გსურთ...",
  Backup_Type:"სარეზერვო ტიპი",
  FTP_Drive:"FTP Drive",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"მანქანა",
  Microsoft_OneDrive:"მანქანა",
  Import_Fields:"იმპორტის სფეროები",
  Import_Fields_Word:"გამოიყენეთ ეს განყოფილება <a routerLink='/folder/Holiday/New'>იმპორტის</a> მონაცემების ასარჩევად.",
  Upload:"ატვირთვა",
  Download:"ჩამოტვირთვა",
  Download_JSON:"ჩამოტვირთვა როგორც JSON მონაცემები",
  Download_SQL:"ჩამოტვირთვა როგორც SQL ფაილი",
  New_Bank:"ახალი ბანკი",
  New_Account:"Ახალი ანგარიში",
  New_Bank_Account:"ახალი საბანკო ანგარიში",
  Upload_Image:"Სურათის ატვირთვა",
  Bank_Name:"Ბანკის სახელი",
  Bank_Address:"Ბანკის მისამართი",
  Branch_Address:"Ფილიალის მისამართი",
  Address_on_Cheque:"მისამართი",
  Cheque_Numbers:"შეამოწმეთ ნომრები",
  Cheque_Details:"შეამოწმეთ დეტალები",
  Bank_Logo:"ბანკის ლოგო",
  Cheque_ID:"შეამოწმეთ ID",
  Starting_Cheque_ID:"დაწყებული შემოწმების ID",
  Transit_Number:"ტრანზიტის ნომერი",
  Institution_Number:"დაწესებულების ნომერი",
  Designation_Number:"დანიშნულების ნომერი",
  Account_Number:"Ანგარიშის ნომერი",
  Limits:"საზღვრები",
  Default_Limits:"ნაგულისხმევი ლიმიტები",
  Over_Limit:"ლიმიტის გადაჭარბება",
  Under_Limit:"ლიმიტის ქვეშ",
  Payroll_Limit:"ხელფასის ლიმიტი",
  Enable_Bank_Account:"ჩართეთ საბანკო ანგარიში",
  Select_Account:"აირჩიე ანგარიში",
  No_Bank_Account:"არ არის საბანკო ანგარიში",
  No_Bank_Account_Word:"დაამატე პირველი <a routerLink='/folder/Accounts/New'>საბანკო ანგარიში</a>.",
  Bank_Accounts:"საბანკო ანგარიშები",
  No_Accounts:"ანგარიშები არ არის",
  No_Accounts_Note:"დაამატე პირველი <a routerLink='/folder/Accounts/New'>საბანკო ანგარიში</a>.",
  Cheque_Designer:"შემოწმების დიზაინერი",
  Cheque_Design:"შეამოწმეთ დიზაინი",
  Select_Design:"აირჩიე დიზაინი",
  Cheque_Designs:"შეამოწმეთ დიზაინი",
  No_Cheque_Designs:"შემოწმების დიზაინის გარეშე",
  No_Cheque_Designs_Word:"შექმენით თქვენი საკუთარი <a routerLink='/folder/Settings/Cheque/Design/New'>შეამოწმეთ დიზაინი</a>.",
  Set_Default:"დაყენება ნაგულისხმევად",
  Default:"ნაგულისხმევი",
  Remove:"ამოღება",
  Folder:"საქაღალდე",
  Edit:"რედაქტირება",
  LoadingDots:"Ჩატვირთვა...",
  Add_a_New_File:"დაამატე <a href='#' (click)='add()'>ახალი ფაილი</a>",
  this_folder:"ეს საქაღალდე",
  FTP_Backup_Settings:"FTP სარეზერვო პარამეტრები",
  Secure_File_Transfer:"უსაფრთხო ფაილების გადაცემა",
  New_Holiday:"ახალი დღესასწაული",
  Add_Holiday:"დაამატეთ დღესასწაული",
  Holiday_Name:"დღესასწაულის სახელი",
  Additional_Pay:"დამატებითი ანაზღაურება",
  Enable_Holiday:"ჩართეთ დღესასწაული",
  Select_Holidays:"აირჩიეთ არდადეგები",
  No_Holidays:"არდადეგები",
  No_Holidays_Word:"დაამატე პირველი <a routerLink='/folder/Holiday/New'>საჯარო დღესასწაული</a>.",
  New_Import:"ახალი იმპორტი",
  Import_Data:"მონაცემების იმპორტი",
  Import_Data_Word:"აირჩიეთ ფაილის ტიპი ან ატვირთვის მეთოდი თქვენი არჩევანით.<br /> მხარდაჭერილი ფაილის ატვირთვის შემდეგ თქვენ შეძლებთ აირჩიოთ ფაილში იმპორტირებული ველები, რომლებიც შეესაბამება აპში არსებულ პარამეტრებს.", 
  Import_File:"ფაილის იმპორტი",
  Link_To_File:"ფაილის ბმული",
  Select_File:"Მონიშნე ფაილი",
  New_Moderator:"ახალი მოდერატორი",
  Allow_Moderation:"მოდერაციის დაშვება",
  Create_Paycheques:"შექმენით ხელფასი",
  Edit_Paycheques_and_Data:"ანაზღაურება და მონაცემების რედაქტირება",
  Destroy_Data_and_Paycheques:"გაანადგურე მონაცემები და ხელფასი",
  Bonus_Percentage:"ხელფასის პროცენტი",
  Fixed_Amount:"ფიქსირებული თანხა",
  Select_Moderator:"აირჩიე მოდერატორი",
  No_Moderators:"მოდერატორების გარეშე",
  Moderators:"მოდერატორები",
  Moderator_Account:"შექმენით პირველი <a routerLink='/folder/Administrator/New'>მოდერატორის ანგარიში</a>.",
  No_Moderators_Word:"დაამატე პირველი <a routerLink='/folder/Administrator/New'>მოდერატორი</a>.",
  Defaults:"ნაგულისხმევი",
  Provide_Us_Info:"მოგვიწოდეთ ინფორმაცია",
  Setup_Your_Printer:"დააყენეთ თქვენი პრინტერი",
  Your_Company:"თქვენი კომპანიის შესახებ",
  Company_Name:"Კომპანიის სახელი",
  Currency:"ვალუტა",
  Default_Currency:"ნაგულისხმევი ვალუტა",
  Base_Monthly_Income:"Თვიური შემოსავალი",
  Protection:"დაცვა",
  App_Protection:"აპლიკაციის დაცვა",
  PIN_Code_Protection:"PIN კოდის დაცვა",
  App_Protection_Word:"ჩართეთ უსაფრთხოების მეთოდები, რომლებიც დაგეხმარებათ თქვენი ანგარიშის დაცვაში.",
  PIN_Code:"Პინ კოდი",
  Change_PIN:"PIN-ის შეცვლა",
  New_Password:"Ახალი პაროლი",
  Geofence_Protection:"გეო-ღობეების დაცვა",
  Geofence_Area:"დაყენებული ზონა",
  Distance:"მანძილი",
  Setup_Now:"დაყენება ახლა",
  Mile:"მაილი",
  Km:"კმ",
  m:"მ",
  Facial_Recognition:"სახის ამოცნობა",
  Face:"სახე",
  Setup:"Აწყობა",
  Label:"ლეიბლი",
  Password_Protection:"პაროლის დაცვა",
  Modify_Password:"პაროლის შეცვლა",
  New_Tax_Entry:"ახალი საგადასახადო ჩანაწერი",
  New_Tax:"ახალი გადასახადი",
  Tax_Label:"საგადასახადო ეტიკეტი",
  Perpetually_Enabled:"სამუდამოდ ჩართულია",
  Select_Taxes:"აირჩიეთ გადასახადები",
  Tax_Deductions:"საგადასახადო გამოქვითვები",
  No_Tax_Deductions:"საგადასახადო გამოქვითვა არ არის",
  No_Tax_Deductions_Word:"დაამატეთ პირველი <a routerLink='/folder/Tax/New'>გადასახადის</a> გამოქვითვა.",
  New_Timer:"ახალი ტაიმერი",
  Start:"დაწყება",
  Stop:"გაჩერდი",
  Start_Timer:"დაწყების ტაიმერი",
  Stop_Timer:"ტაიმერის გაჩერება",
  Timer_Active:"ტაიმერი აქტიური",
  Timer:"ტაიმერი:",
  Timer_Running:"ტაიმერი: (გაშვებული)",
  Save_Timer:"ტაიმერის შენახვა",
  New_Timesheet:"ახალი დროის ფურცელი",
  Select_Timesheets:"აირჩიეთ დროის ცხრილები",
  Work_Notes:"სამუშაო შენიშვნები",
  Entry_Title:"შესვლის სათაური",
  No_Timesheets:"დროის ფურცლების გარეშე",
  No_Timesheets_Word:"დაამატე პირველი <a routerLink='/folder/Timesheet/New'>დროის ცხრილი</a>.",
  Timesheet_Submitted:"დროის ფურცელი გაგზავნილია",
  Timesheet_Congrats:"გილოცავთ! თქვენი დროის ფურცელი წარმატებით იქნა გაგზავნილი. გმადლობთ!",
  Timesheet_Copy:"თქვენი დოკუმენტების ასლის მისაღებად გთხოვთ მოგვაწოდოთ თქვენი ელ.ფოსტის მისამართი და/ან მობილური ტელეფონის ნომერი.",
  Submit:"გაგზავნა",
  Allow_Notifications:"შეტყობინებების დაშვება",
  Untitled_Entry:"Ახალი ჩანაწერი",
  Untitled_Bank:"უსახელო ბანკი",
  Timesheet_Entry:"დროის ფურცლის შესვლა",
  Work_Description:"Სამუშაოს აღწერა",
  Enable_Timesheet:"დროის ცხრილის ჩართვა",
  Submit_Timesheet:"დროის ფურცლის გაგზავნა",
  Vacation:"შვებულება",
  New_Vacation:"ახალი შვებულება",
  Vacation_Name:"შვებულების სახელი",
  Paid_Vacation:"Გადახდილი შვებულება",
  Vacation_Pay:"შვებულების ანაზღაურება",
  Enable_Vacation:"შვებულების ჩართვა",
  Select_Vacations:"აირჩიეთ არდადეგები",
  No_Vacations:"არ დასვენება",
  No_Vacations_Word:"შექმენით პირველი <a routerLink='/folder/Vacation/New'>შვებულება</a> ჩანაწერი.",
  Payroll_Schedule:"ხელფასის განრიგი",
  Next_Payroll:"შემდეგი სახელფასო სია:",
  Upcoming_Payroll:"მომავალი სახელფასო სია",
  No_Upcoming_Payroll:"მომავალი სახელფასო თანხა არ არის",
  Address_Book:"Მისამართების წიგნი",
  Archived_Documents:"საარქივო დოკუმენტები",
  Dev_Mode:"აპლიკაცია განვითარების რეჟიმში",
  Administrators:"ადმინისტრატორები",
  Privacy:"კონფიდენციალურობა",
  None:"არცერთი",
  Select_Signature:"აირჩიეთ ხელმოწერა",
  No_Signatures:"ხელმოწერების გარეშე",
  No_Signatures_Word:"დაამატეთ პირველი <a routerLink='/folder/Signature/New'>ხელმოწერა</a>.",
  Repeat_Indefinitely:"გაიმეორეთ განუსაზღვრელი ვადით",
  Sign:"Ნიშანი",
  Sign_Here:"Აქ მოაწერეთ ხელი",
  Date_Signed:"Ხელმოწერის თარიღი",
  Signature_Pad:"ხელმოწერის პანელი",
  Account_Holder:"Ანგარიშის მფლობელი",
  Account_Properties:"ანგარიშის თვისებები",
  Name_On_Cheque:"სახელი ჩეკზე",
  Server_Hostname:"სერვერის ჰოსტის სახელი / IP",
  Printers:"პრინტერები",
  No_Printers:"პრინტერების გარეშე",
  Printer_Exists:'ამ სახელის პრინტერი უკვე არსებობს.',
  No_Printers_Word:"დაამატე პირველი <a routerLink='/folder/Printer/New'>პრინტერი</a>.",
  No_Printer_Alert:"პრინტერი არ არის განსაზღვრული. გსურთ პრინტერის დაყენება?",
  Add_Printer:"პრინტერის დამატება",
  New_Printer:"ახალი პრინტერი",
  Printer_Hostname:"პრინტერის ჰოსტის სახელი / IP",
  Printer_Label:"პრინტერის ეტიკეტი",
  Printer_Protocol:"პრინტერის პროტოკოლი",
  Protocol:"Ოქმი",
  Email_Print:"ელ.ფოსტა",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"სოკეტი",
  Print_Job:"ბეჭდვის სამუშაო",
  Printed:"დაბეჭდილი",
  Not_Printed:"არ არის დაბეჭდილი",
  Print_Jobs:"ბეჭდვის სამუშაოები",
  Print_Queue:"ბეჭდვის რიგი",
  No_Print_Jobs:"ბეჭდვის სამუშაოები არ არის",
  No_Prints:"შექმენით ახალი <a routerLink='/folder/Cheque/New'>ჩეკი</a> დასაბეჭდად.",
  Prints:"ანაბეჭდები",
  Find_Printer:"იპოვე პრინტერი",
  Find_AirPrint_Devices:"იპოვე AirPrint მოწყობილობები",
  Select_Printer:"აირჩიეთ პრინტერი",
  System_UI:"სისტემის ინტერფეისი",
  Printer:"პრინტერი",
  Status:"სტატუსები",
  Preview:"გადახედვა",
  Enable_Print_Job:"ბეჭდვის სამუშაოს ჩართვა",
  Queue_Weight:"რიდის წონა",
  Unlimited:"შეუზღუდავი",
  Show_Advanced_Printer_Options:"პრინტერის გაფართოებული პარამეტრების ჩვენება",
  Advanced:"Მოწინავე",
  Location:"ადგილმდებარეობა",
  Note:"Შენიშვნა",
  Queue_Name:"რიდის სახელი",
  Pages_Printed_Limit:"გვერდების დაბეჭდილი ლიმიტი",
  MultiPage_Idle_Time:"მრავალგვერდიანი ლოდინის დრო(ები)",
  Page_Count_Limit:"გვერდების რაოდენობის ლიმიტი",
  Page_Orientation:"გვერდის ორიენტაცია",
  Portrait:"პორტრეტი",
  Landscape:"პეიზაჟი",
  Duplex:"დუპლექსი",
  Double_Sided_Printing:"ორმხრივი",
  Duplex_Mode:"დუპლექსის რეჟიმი",
  Duplex_Short:"მოკლე",
  Duplex_Long:"გრძელი",
  Duplex_None:"არცერთი",
  Color_And_Quality:"ფერი და ხარისხი",
  Monochrome:"მონოქრომული",
  Photo_Quality_Prints:"ფოტოს ხარისხის ბეჭდვა",
  Printer_Email:"პრინტერის ელ.ფოსტა",
  Automatically_Downsize:"ავტომატური შემცირება",
  Paper:"ქაღალდი",
  Paper_Name:"ქაღალდის სახელი",
  Paper_Width:"ქაღალდის სიგანე",
  Paper_Height:"ქაღალდის სიმაღლე",
  Paper_Autocut_Length:"ქაღალდის ავტომატური ჭრის სიგრძე",
  Margins:"ზღვრები",
  Page_Margins:"გვერდის მინდვრები",
  Page_Margin_Top:"ზედა გვერდის ზღვარი",
  Page_Margin_Right:"გვერდის მარჯვენა ზღვარი",
  Page_Margin_Bottom:"ქვედა გვერდის ზღვარი",
  Page_Margin_Left:"მარცხენა გვერდის ზღვარი",
  Add_Employees:"დაამატე თანამშრომლები",
  Header:"სათაური",
  Print_A_Page_Header:"გვერდის სათაურის ამობეჭდვა",
  Header_Label:"Header Label",
  Header_Page_Index:"სათაურის გვერდის ინდექსი",
  Header_Font_Name:"Header Font",
  Select_Font:"აირჩიეთ შრიფტი",
  Font_Selector:"ფონტის ამომრჩევი",
  Header_Font_Size:"Header Font",
  Header_Bold:"Header Bold",
  Header_Italic:"სათაური დახრილი",
  Header_Alignment:"სათაურის გასწორება",
  Left:"მარცხნივ",
  Center:"ცენტრი",
  Right:"მართალი",
  Justified:"გამართლებული",
  Header_Font_Color:"სათაურის ფერი",
  Select_Color:"აირჩიე ფერი",
  Footer:"ძირი",
  Print_A_Page_Footer:"Print A Page Footer",
  Footer_Label:"ფუტერ ლეიბლი",
  Footer_Page_Index:"ქვედა გვერდის ინდექსი",
  Footer_Font_Name:"Foter Font",
  Fonts:"ფონტები",
  Done:"Შესრულებულია",
  Select_Fonts:"აირჩიეთ შრიფტები",
  Footer_Font_Size:"ფუტერის ზომა",
  Footer_Bold:"Foter Bold",
  Footer_Italic:"ფუტერი დახრილი",
  Footer_Alignment:"ძირის გასწორება",
  Footer_Font_Color:"ძირის ფერი",
  Page_Copies:"გვერდის ასლები",
  Enable_Printer:"პრინტერის ჩართვა",
  Remote_Logging:"დისტანციური აღრიცხვა",
  Log_Server:"ლოგის სერვერი",
  Encryption:"დაშიფვრა",
  Random_Key:"შემთხვევითი გასაღები",
  Encryption_Key:"დაშიფვრის გასაღები",
  Cheques_Webserver:"მორგებული მონაცემთა ბაზა",
  Learn_How:"Ისწავლე როგორ",
  Signature:"ხელმოწერა",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"ხელმოწერის ნახვა",
  Printed_Signature:"დაბეჭდილი ხელმოწერა",
  Digitally_Sign:"ციფრული ნიშანი",
  Digital_Signature:"Ციფრული ხელმოწერა",
  Add_Signature:"ხელმოწერის დამატება",
  Add_Your_Signature:"დაამატე შენი ხელმოწერა",
  Enable_Signature:"ხელმოწერის ჩართვა",
  Digitally_Signed:"ციფრულად ხელმოწერილი",
  Insert_Error:"შემოწმების შენახვა შეუძლებელია მონაცემთა ბაზის პრობლემების გამო.",
  Delete_Confirmation:"დარწმუნებული ხართ, რომ გსურთ ამ ინფორმაციის წაშლა?",
  Discard_Confirmation:"დარწმუნებული ხართ, რომ გსურთ ამ ინფორმაციის გაუქმება?",
  Discard_Bank_Confirmation:"დარწმუნებული ხართ, რომ გსურთ ამ ანგარიშის გაუქმება?",
  Discard_Printer_Confirmation:"დარწმუნებული ხართ, რომ გსურთ ამ პრინტერის გაუქმება?",
  Delete_Bonus_Confirmation:"დარწმუნებული ხართ, რომ გსურთ ამ ბონუსის წაშლა?",
  Delete_Invoice_Confirmation:"დარწმუნებული ხართ, რომ გსურთ ამ ინვოისის წაშლა?",
  Generated_Cheque:"გენერირებული ჩეკი",
  Generated_Invoice:"გენერირებული ინვოისი",
  Schedule_Start_Time:"დაწყების განრიგი",
  Schedule_End_Time:"განრიგის დასასრული",
  New_Call:"ახალი ზარი",
  No_Contacts:"კონტაქტები არ არის",
  No_Contacts_Word:"ადმინისტრატორები, მოდერატორები, თანამშრომლები და გადამხდელები ჩნდებიან როგორც კონტაქტები.",
  PDF_Subject:"ფინანსები",
  PDF_Keywords:"ხელფასის ხელფასები PDF შემოწმების ჩეკები",
  Printer_Setup:"პრინტერის დაყენება",
  Printer_Selection:"პრინტერის შერჩევა",
  New_Fax:"ახალი ფაქსი",
  New_Fax_Message:"ახალი ფაქსის შეტყობინება",
  Fax_Machine:"ფაქსის აპარატი",
  Fax_Name:"ფაქსის სახელი",
  Fax_Email:"ფაქსი ელ.ფოსტა",
  Fax_Number:"Ფაქსის ნომერი",
  Contents:"შინაარსი",
  Fax_Body:"გვერდის სხეული",
  Header_Word:"სათაური:",
  Header_Text:"სათაურის ტექსტი",
  Include_Header:"ჰადერის ჩართვა",
  Include_Footer:"ჩართეთ ქვედა კოლონტიტული",
  Footer_Word:"ძირი:",
  Footer_Text:"ძირის ტექსტი",
  Attach_a_Cheque:"დაამაგრეთ ჩეკი",
  Add_Deduction:"დაამატე გამოქვითვა",
  Enable_Fax:"ფაქსის გაგზავნა",
  Select_Fax:"აირჩიეთ ფაქსი",
  No_Faxes:"ფაქსების გარეშე",
  Faxes:"ფაქსები",
  Save_and_Send:"ფაქსის გაგზავნა",
  Save_and_Pay:"დაზოგე და გადაიხადე",
  WARNING:"გაფრთხილება:",
  Remember:"გახსოვდეს",
  Printing:"ბეჭდვა",
  Printing_Complete:"ბეჭდვა დასრულებულია!\n\n",
  of:"ის",
  There_Were:"Იქ იყო ",
  Successful_Prints:"წარმატებული ანაბეჭდები და",
  Unsuccessful_Prints:"წარუმატებელი ანაბეჭდები.",
  PrinterError:"ბოდიში, იყო შეცდომა.",
  Printing_:"ბეჭდვა...",
  PrinterSuccess:"დოკუმენტი წარმატებით დაიბეჭდა.",
  PrintersSuccess:"დოკუმენტები წარმატებით დაიბეჭდა.",
  Print_Cheques:"დაბეჭდილი ჩეკები",
  New_Message:"Ახალი შეტყობინება",
  New_Messages:"ახალი შეტყობინებები",
  Read_Message:"წაიკითხეთ შეტყობინება",
  Write_Message:"დაწერეთ შეტყობინება",
  Send_Message:"შეტყობინებების გაგზავნა",
  Subject:"საგანი",
  Message:"მესიჯი",
  Writing:"Წერა...",
  Send:"გაგზავნა",
  Set_Date:"თარიღის დაყენება",
  Set_Time:"Დროის დაყენება",
  Recieve:"მიღება",
  Set_as_Default:"დაყენება ნაგულისხმევად",
  Default_Account:"ნაგულისხმევი ანგარიში",
  Default_Design:"ნაგულისხმევი დიზაინი",
  Multiple_Cheques:"ჩეკები (სამმაგი)",
  Account_Mode:"ანგარიშის რეჟიმი",
  Multiple_Cheques_Description:"სამი შემოწმება თითო გვერდზე.",
  Check_and_Table:"შემოწმება და ცხრილი",
  Check_including_Table:"შემოწმება და აღრიცხვის ცხრილი.",
  Check_Mailer:"შეამოწმეთ მეილერი",
  Check_Mailer_Window:"შეამოწმეთ მისამართის ფანჯრით.",
  DocuGard_Table_Top:"DocuGard Check & Table (ზემოთ)",
  DocuGard_Table_Middle:"DocuGard Check & Table (შუა)",
  DocuGard_Table_Bottom:"DocuGard Check & Table (ქვემოთ)",
  DocuGard_Mailer_Top:"DocuGard Check Mailer (Top)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (შუაში)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mailer (ქვედა)",
  DocuGard_Three_Cheques:"DocuGard Checks (Tripple)",
  DocuGard_Cheque_Top:"DocuGard Check (ზედა)",
  DocuGard_Cheque_Middle:"DocuGard Check (შუაში)",
  DocuGard_Cheque_Bottom:"DocuGard შემოწმება (ქვედა)",
  DocuGard_Three_Cheques_Window:"სამი შემოწმება ერთ გვერდზე.",
  DocuGard_Table_Top_Window:"შემოწმება და შემოსავლების ცხრილი.",
  DocuGard_Table_Middle_Window:"შემოწმება და შემოსავლების ცხრილი.",
  DocuGard_Table_Bottom_Window:"შემოწმება და შემოსავლების ცხრილი.",
  DocuGard_Mailer_Top_Window:"შემოწმება, ცხრილი და მისამართი.",
  DocuGard_Mailer_Middle_Window:"შემოწმება, ცხრილი და მისამართი.",
  DocuGard_Mailer_Bottom_Window:"შემოწმება, ცხრილი და მისამართი.",
  DocuGard_Cheque_Top_Window:"შეამოწმეთ უსაფრთხო ქაღალდი.",
  DocuGard_Cheque_Middle_Window:"შეამოწმეთ უსაფრთხო ქაღალდი.",
  DocuGard_Cheque_Bottom_Window:"შეამოწმეთ უსაფრთხო ქაღალდი.",
  Basic_Cheque:"შეამოწმეთ (ზედა)",
  Basic_Cheque_Print:"დაბეჭდე ერთი ჩეკი.",
  Error_Setting_As_Paid:"შეცდომა ფასიანად დაყენებისას",
  Add_Attachment:"დანართის დამატება",
  PrinterUnavailable:"პრინტერი მიუწვდომელია",
  CreditCardComponent:"ბარათები",
  PaypalComponent:"PayPal",
  InteracComponent:"ინტერაკი",
  BitcoinComponent:"ბიტკოინი",
  New_Deposit:"ახალი ანაბარი",
  Deposits:"დეპოზიტები",
  No_Deposits:"დეპოზიტების გარეშე",
  Credit_Card_Deposit:"Საკრედიტო ბარათი",
  New_Credit_Card_Deposit_Message:"საკრედიტო ბარათის ანაბარი",
  New_BitCoin_Deposit:"ბიტკოინი",
  New_BitCoin_Deposit_Message:"ბიტკოინის დეპოზიტი",
  New_Interac_Deposit:"ინტერაკი",
  New_Interac_Deposit_Message:"ინტერაკ ტრანსფერი",
  Payments_Limit:"გადახდის ლიმიტი",
  No_Payment_Limit:"გადახდის ლიმიტის გარეშე",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"პეიპალის ანაბარი",
  No_Deposits_Word:"დაამატე პირველი კომპანია <a routerLink='/folder/Deposit/New'>დეპოზიტი</a>.",
  No_Documents_Specified:"არცერთი დოკუმენტი არ იყო მითითებული დასაბეჭდად",
  No_Printers_Added:"პრინტერები ვერ მოიძებნა. გადადით პარამეტრები > პრინტერები ერთის დასამატებლად.",
  DB_Erase_Prompt:"სრულად წაშალეთ მთელი მონაცემთა ბაზა? გაფრთხილება: თქვენ დაკარგავთ ყველა შენახულ ინფორმაციას!",
  Console_Warning:"არ ჩასვათ რაიმე ტექსტი ამ კონსოლში. თქვენ შეიძლება თავი და/ან თქვენი კომპანია სერიოზული რისკის ქვეშ დააყენოთ.",
  No_Faxes_Word:"შექმენით პირველი <a routerLink='/folder/Fax/New'>ფაქსი</a>.",
  Cheque_Delete_Confirmation:"დარწმუნებული ხართ, რომ გსურთ ამ ჩეკის წაშლა?",
  Design_Delete_Confirmation:"დარწმუნებული ხართ, რომ გსურთ ამ დიზაინის წაშლა?",
  Cheque_Pay_Confirmation:"მონიშნეთ ეს ჩეკი, როგორც ფასიანი? ის არ გამოჩნდება ბეჭდვის რიგში.",
  Payment_Pay_Confirmation:"მონიშნეთ ეს გადახდა, როგორც გადახდილი? ის არ გამოჩნდება ჩეკის რიგში.",
  Delete_Deduction_Confirmation:"დარწმუნებული ხართ, რომ გსურთ ამ გამოქვითვის წაშლა?",
  Delete_Job_Confirmation:"დარწმუნებული ხართ, რომ გსურთ ამ სამუშაოს წაშლა?",
  Delete_Timesheet_Confirmation:"დარწმუნებული ხართ, რომ გსურთ ამ დროის ცხრილის წაშლა?",
  Delete_Schedule_Confirmation:"დარწმუნებული ხართ, რომ გსურთ ამ განრიგის წაშლა?",
  Delete_Setting_Confirmation:"დარწმუნებული ხართ, რომ გსურთ ამ პარამეტრის გადატვირთვა?",
  Delete_Fax_Confirmation:"დარწმუნებული ხართ, რომ გსურთ ამ ფაქსის წაშლა?",
  Delete_File_Confirmation:"დარწმუნებული ხართ, რომ გსურთ ამ ფაილის წაშლა?",
  Delete_Vacation_Confirmation:"დარწმუნებული ხართ, რომ გსურთ ამ შვებულების წაშლა?",
  Delete_Printer_Confirmation:"დარწმუნებული ხართ, რომ გსურთ ამ პრინტერის წაშლა?",
  Remove_Design_Confirmation:"დარწმუნებული ხართ, რომ გსურთ ამ დიზაინის წაშლა?",
  Delete_Payroll_Confirmation:"დარწმუნებული ხართ, რომ გსურთ წაშალოთ ეს სახელფასო სია?",
  Send_Fax_Email_Confirmation:"გსურთ ამ დოკუმენტების ფაქსით და ელექტრონული ფოსტით გაგზავნა?",
  Send_Email_Confirmation:"გსურთ ამ დოკუმენტის ელექტრონული ფოსტით გაგზავნა?",
  Send_Fax_Confirmation:"გსურთ ამ დოკუმენტის ფაქსით გაგზავნა?",
  Error_Generating_PDF:"ბოდიში. ამ დოკუმენტის გენერირებისას მოხდა შეცდომა.",
  PDF_Error_Saving_Image:"ბოდიში. მოხდა შეცდომა ამ დოკუმენტის PDF სურათის შენახვისას.",
  Test_Printer_Confirmation:"გსურთ სატესტო გვერდის დაბეჭდვა ამ პრინტერზე?",
  Save_Timesheet_Prompt:"გთხოვთ, დაამატოთ 'სათაური' ან დააჭირეთ 'ტაიმერის დაწყებას' შესანახად.",
  Remove_Geofence_Prompt:"დარწმუნებული ხართ, რომ გსურთ ამ გეო-ღობის მდებარეობის ამოღება?",
  Delete_Employee_Confirmation:"Დარწმუნებული ხარ რომ წაშლა გინდა ",
  Fire_Employee_Confirmation:"დარწმუნებული ხარ, რომ გინდა გასროლა?"
}