export const Sq = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"E drejta e autorit &kopje; 2023",
  black:"E zezë",
  green:"E gjelbër",
  gold:"Ari",
  blue:"Blu",
  brown:"Kafe",
  purple:"Vjollcë",
  pink:"Rozë",
  red:"E kuqe",
  Swatches:"Swatches",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Hue",
  Saturation:"Ngopja",
  Lightness:"Lehtësia",
  Upgrade_To_Pro:"Përmirësojeni në Pro",
  AllFeaturesInclude:"Të gjitha veçoritë përfshijnë:",
  PrintUnlimitedCheques:"Printoni kontrolle të pakufizuara",
  PremiumChequeDesigns:"Modelet e kontrollit premium",
  ManageUnlimitedEmployees:"Menaxhoni punonjës të pakufizuar",
  AddUnlimitedPayees:"Shtoni pagues të pakufizuar",
  CreateUnlimitedPayrolls:"Krijoni listëpagesa të pakufizuara",
  UnlimitedSchedulesandTimeSheets:"Orare të pakufizuara dhe fletë kohore",
  BulkPayPalPayouts:"Pagesa me shumicë PayPal",
  UnlimitedBankAccounts:"Llogari bankare të pakufizuara",
  ManageMultipleCompanies:"Menaxhoni kompani të shumta",
  TrackInsurancePolicies:"Ndiqni policat e sigurimit",
  Bio_MetricProtection:"Mbrojtja Biometrike",
  Geo_FenceLock_OutProtection:"Mbrojtja nga bllokimi i gjeo-gardhit",
  Multiple_Companies_Word:"Menaxhimi i shumë kompanive nuk është i disponueshëm pa premium çeqe.",
  PayPal_Payouts_Word:"Pagesat e PayPal janë çaktivizuar pa premium çeqe.",
  PINProtection:"Mbrojtja PIN",
  PasswordProtection:"Mbrojtja me fjalëkalim",
  May_Require_Approval:"Mund të kërkojë miratim.",
  Subscribe:"Abonohu",
  Billed:"Faturuar",
  Up:"Lart",
  Down:"Poshtë",
  Positioning:"Pozicionimi",
  Marker:"Shënues",
  Drag_Marker:"Zvarrit shënuesin",
  Tagline:"Printimi i çeqeve dhe tabelimi i listës së pagave",
  Marker_Word:"Përdorni Markuesit për të përmasuar elementin.",
  Pinch_Zoom:"Zmadhoni majë",
  Pinch_Word:"Pini për të zmadhuar elementin.",
  Drag:"Zvarritni",
  Drag_Word:"Përdorni gishtin për të tërhequr elementë.",
  subscription_alias_word:"Abonim me rinovim automatik",
  premium_alias_word:"Paketa e Përmirësimit Një herë",
  print_alias_word:"Shtypur kontrolle individuale",
  mode_alias_word:"Modaliteti",
  Pro:"Pro",
  Pro_word:"Kërkohet versioni Pro.",
  Cant_Delete_Default_Company_Word:"Na vjen keq, nuk mund ta fshini kompaninë tuaj të paracaktuar.",
  Reinsert_Default_Designs:"Ri-fut modelet e parazgjedhura",
  Reinsert_Default_Designs_word:"Dëshiron të rifusësh modelet e paracaktuara?",
  Subscription_Active_Disable_Word:"Ky abonim është aktiv. Dëshiron ta anulosh këtë abonim te Çeqet?",
  Company_Logo:"Logoja e kompanisë",
  ZERO_:"ZERO",
  Disclaimer:"Mohim përgjegjësie",
  Privacy_Policy:"Politika e privatësisë",
  EULA:"Kontrollon EULA",
  Terms_Of_Service:"Kushtet e Shërbimit",
  Terms_Of_Use:"Kushtet e Përdorimit",
  Refunds:"Politika e rimbursimit",
  Single_Print:"1 Kontrollo",
  Two_Prints:"2 Kontrolle",
  Five_Prints:"5 Kontrolle",
  Ten_Prints:"10 kontrolle",
  Fifteen_Prints:"15 Kontrolle",
  Twenty_Prints:"20 Çeqe",
  Thirty_Prints:"30 Çeqe",
  Image_Added:"Imazhi u shtua",
  Image_Preview:"Pamja paraprake e imazhit",
  No_Image_Was_Selected:"Nuk u zgjodh asnjë imazh.",
  Cheques_Unlimited:"Kontrolle të pakufizuara",
  _Subscription:"Abonimi",
  Subscription:"Kontrollet - 1 muaj",
  Two_Month_Subscription:"Kontrollet - 2 muaj",
  Three_Month_Subscription:"Kontrollet - 3 muaj",
  Six_Month_Subscription:"Kontrollet - 6 muaj",
  Twelve_Month_Subscription:"Kontrollet - 12 muaj",
  Cheques_Are_Available:"Kontrollet janë të disponueshme për t'u printuar.",
  Upgrade_Required_Two:"Zgjidhni një paketë dhe prekni dy herë butonin e çmimit për të filluar blerjen tuaj. Printoni kontrolle me ngjyra të plota me pamje profesionale në sekonda.",
  Month:"Muaj",
  Due:"I detyrueshëm:",
  Expires:"Skadon:",
  Subscription_Active:"Abonimi aktiv",
  Subscription_Inactive:"Abonimi Joaktiv",
  Purchase_Additional_Cheques:"Të blini çeqe shtesë?",
  Printable_Cheque:"Kontroll i printueshëm",
  Printable_Cheques:"Çeqe të printueshme",
  Printable_Cheque_Word:"kontrolli është i disponueshëm në llogarinë tuaj.",
  Printable_Cheques_Word:"çeqet janë të disponueshme në llogarinë tuaj.",
  Max_Amount_Message:"Shuma që keni specifikuar ka arritur mbi shumën maksimale të caktuar për këtë sistem:",
  Terms_Required_Word:"Ju duhet të pranoni këtë marrëveshje përpara se të vazhdoni të përdorni Çeqet.",
  Subscriptions:"Abonimet",
  Product_Upgrades:"Përmirësimet",
  Mailed_Out_Cheques:"Kontrollet e dërguara me postë",
  Enter_A_Company_Name:"Ju lutemi shkruani një emër kompanie.",
  Single_Cheques:"Kontrollet e vetme",
  Cheque_Golden:"Çeku i Artë",
  Cheque_Golden_Window:"Dizajni i çekut të artë.",
  Cheque_Green:"Kontroll i gjelbër",
  Cheque_Green_Window:"Dizajni i çekut të gjelbër.",
  Cheque_Red:"Kontroll i kuq",
  Cheque_Red_Window:"Dizajni i çekut të kuq.",
  Cheque_Yellow:"Çeku i verdhë",
  Cheque_Yellow_Window:"Dizajni i çekut të verdhë.",
  Cheque_Statue_of_Liberty:"Statuja e Lirise",
  Cheque_Statue_of_Liberty_Window:"Dizajni i kontrollit të Statujës së Lirisë.",
  Cheque_Green_Wave:"Vala e Gjelbër",
  Cheque_Green_Wave_Window:"Dizajni i çekut të gjelbër.",
  Cheque_Golden_Weave:"Endje e Artë",
  Cheque_Golden_Weave_Window:"Dizajn elegant i çekut të artë.",
  Cheque_Green_Sun:"Dielli i Gjelbër",
  Cheque_Green_Sun_Window:"Dizajn kontrolli i thellë dhe qetësues.",
  Cheque_Blue_Checkers:"Damë blu",
  Cheque_Blue_Checkers_Window:"Dizajni i çekut blu.",
  Cashiers_Check:"Çeku i arkës",
  Cashiers_Check_Window:"Modeli i stilit të çekut të arkës.",
  Cheque_Aqua_Checkers:"Damë Aqua",
  Cheque_Aqua_Checkers_Window:"Dizajni i kontrollit Aqua.",
  Cheque_Golden_Checkers:"Damë e artë",
  Cheque_Golden_Checkers_Window:"Dizajni i çekut të artë.",
  Upgrade_Unavailable:"Përmirësimet nuk janë të disponueshme",
  Bank_Code_Protection:"Mbrojtja e numrit të bankës",
  Bank_Code_Protection_Word:"Mbroni numrat tuaj të bankës që të mos përdoren në këtë aplikacion që funksionon në një pajisje tjetër ose për një përdorues tjetër. Ky veprim është i pakthyeshëm. Të vazhdohet?",
  Bank_Code_Protection_Blocked_Word:"Numrat e bankës që po përpiqeni të përdorni janë të rezervuar për një përdorues ose pajisje tjetër.",
  Bank_Code_Protection_Error_Word:"Verifikimi i numrit dështoi. Lidhu me internetin dhe provo ta shtosh sërish këtë llogari bankare.",
  Bank_Code_Protection_Set_Error_Word:"Mbrojtja e numrave të bankës kërkon që të jeni të lidhur me internetin. Lidhu dhe provo sërish.",
  Upgrade_Unavailable_Word:"Na vjen keq, ne kemi probleme për t'ju verifikuar. Abonimet dhe përmirësimet e çeqeve nuk janë aktualisht të disponueshme për blerje në zonën tuaj.",
  PayPal_Payment_Preview:"Pamja paraprake e pagesës PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Zgjidhni PayPal",
  PayPal_Applications:"Aplikacionet PayPal",
  Purchase_With_Apple_Pay:"Blini me Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Kompanitë",
  Insurance:"Sigurimi",
  New_PayPal:"PayPal i ri",
  Pay_By:"Paguaj me",
  Insure:"Siguruar",
  Miles:"Milje",
  Payment_Method:"Metoda e pagesës",
  Select_Policies:"Zgjidhni Politikat",
  Policies:"politikat",
  Policy:"Politika",
  No_PayPal_Account:"Nuk ka llogari PayPal",
  No_Policies:"Nuk ka polica sigurimi",
  New_Policy:"Politika e re",
  PayPal_Payment:"Pagesa PayPal",
  PayPal_Payments:"Pagesat e PayPal",
  No_Payment_Selected:"Nuk u zgjodh asnjë pagesë",
  Sending_Payment_Word:"Ju lutemi prisni... Kjo pagesë po dërgohet.",
  Sending_Payments_Word:"Ju lutemi prisni... Pagesat po dërgohen.",
  No_Payment_Selected_PayPal:"Asnjë <a routerLink='/folder/Payments'>pagesë PayPal</a> nuk është zgjedhur për të dërguar.",
  Payment_Sent:"Pagesa u dërgua",
  PayPal_Payment_Sent:"Kjo pagesë është dërguar me PayPal.",
  Copay:"Paguaj",
  Dead:"I vdekur",
  Alive:"I gjallë",
  Not_Dead:"Jo i vdekur",
  Unclaimed:"E pa pretenduar",
  Attempted:"U përpoq",
  Deceased:"I vdekur",
  Claimed:"E pretenduar",
  Unpaid:"E papaguar",
  Sending_Payment:"Dërgimi i pagesës",
  Sending_Payments:"Dërgimi i pagesave",
  Send_PayPal_Confirmation:"Dëshiron ta dërgosh këtë transaksion me PayPal?",
  Send_PayPal_Confirmation_Word:"Shtypni butonin e gjelbër për të dërguar këtë transaksion.",
  Save_Payment_As_Unpaid:"Të ruhet kjo pagesë si e papaguar?",
  Payment_Pay_Confirmation_PayPal:"Të ruhet kjo pagesë si e paguar?",
  No_Policies_Word:"Shto <a routerLink='/folder/Postage/New'>Policinë e parë të sigurimit</a> tani.",
  Timesheet_Multiple_Delete_Confirmation:"Jeni i sigurt që dëshironi të fshini fletë kohore të shumta?",
  Select_Multiple_Timesheets_Prompt:"Nuk është zgjedhur fletë kohore. Zgjidhni të paktën një fletë kohore.",
  Select_Multiple_Policies_Prompt:"Nuk ka politika të zgjedhura. Zgjidhni të paktën një politikë sigurimi.",
  Policies_Multiple_Delete_Confirmation:"Jeni i sigurt që dëshironi të fshini disa politika?",
  Company:"Kompania",
  Add_Company:"Shto kompani",
  New_Company:"Shto kompani",
  No_Companies:"Nuk ka kompani",
  Enable_Company:"Aktivizo kompaninë",
  Select_Company:"Zgjidhni Kompani",
  The_Default_Company:"Etiketa e paracaktuar e kompanisë.",
  Manage_Companies:"Menaxhoni kompanitë",
  No_Companies_Word:"Kontrollet do të përdorin një kompani të paracaktuar.<br />Shto <a routerLink='/folder/Company/New'>Kompaninë e parë</a>.",
  Default_Company:"Kompania e paracaktuar",
  Cheques_Unlimited_Word:"Checks Unlimited ju lejon të printoni sa më shumë çeqe që dëshironi.",
  Cheques_Subscription_Word:"Një abonim i çeqeve ju lejon të printoni sa më shumë çeqe të doni.",
  You_Own_This_Product:"Ju zotëroni këtë produkt.",
  Your_Subscription_is_Active:"Abonimi juaj është aktiv.",
  Active_Products:"Produktet e aktivizuara",
  Purchase_Confirmation:"Blerje",
  Purchase_Cheques:"Kontrollet e blerjeve",
  Restore_Purchase:"Rivendos blerjet",
  Erase_Purchase:"Fshij blerjet",
  Successfully_Purchased:"Blerë me sukses",
  Enter_Your_Licence_Key:"Ju lutemi vendosni çelësin tuaj të licencës në këtë faqe për të aktivizuar këtë produkt.",
  Licence_Key:"Çelësi i licencës",
  Enter_Licence_Key:"Fut çelësin e licencës",
  Purchased:"I blere",
  Enable_Feature:"Aktivizo funksionin",
  Cancel_Subscription:"Anulo abonimin",
  Subscription_Removed:"Abonimi u hoq",
  Select_Active_Subscription:"Zgjidhni një abonim aktiv për ta modifikuar atë.",
  Remove_Subscription_Word:"Je i sigurt që dëshiron ta anulosh këtë abonim?",
  Delete_Company_Confirmation:"Jeni i sigurt që dëshironi të fshini të gjithë këtë kompani? PARALAJMËRIM: Do të humbisni të gjitha informacionet e ruajtura!",
  Delete_Default_Company_Word:"Ju nuk mund të fshini kompaninë e paracaktuar. Dëshironi të rivendosni aplikacionin dhe ta rivendosni atë në gjendjen e paracaktuar? PARALAJMËRIM: Do të humbisni të gjitha informacionet e ruajtura!",
  Console_Warning_2:"Mos përdorni asnjë monedhë duke përdorur këtë aplikacion, i cili aktualisht nuk është i juaji.",
  Terms_and_Conditions:"Termat dhe Kushtet",
  and_the:"dhe",
  for:"për",
  Please_Read_Word:"Ju lutemi lexoni dhe pajtohuni me",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Disa norma të konvertimit të monedhës nuk mund të gjendeshin. Lidhu me internetin.",
  Free:"Falas",
  DB_Erase_Prompt_2:"Të fshihet plotësisht e gjithë baza e të dhënave të zhvilluesit? PARALAJMËRIM: Do të humbisni të gjitha informacionet e BLERJES dhe ABONIMIT!",
  Successfully_Imported:"Importuar me sukses",
  Select_Postage:"Zgjidhni Tarifa postare",
  No_Postage:"Nuk ka pulla postare",
  No_Paid_Postage_Word:"pullën e parë <a routerLink='/folder/Postage/New'>Tage Poste me pagesë</a>.",
  Trial_Complete:'Prova e përfunduar',
  Please_Upgrade:'Përmirëso Çeqet për të vazhduar printimin.',
  Aa:"Aa",
  Color:"Ngjyrë",
  Font:"Fonti",
  Guide:"Udhëzues",
  Guides:"Udhëzues",
  Image:"Imazhi",
  Zoom:"Zmadhoni",
  Logo:"Logo",
  Bank:"Banka",
  MICR:"MICR",
  Total:"Total",
  Cancel:"Anulo",
  Confirm:"Konfirmo",
  Method:"Metoda",
  Biometric_Security:"Siguria biometrike",
  Please_Login_To_Continue:"Ju lutemi identifikohuni për të vazhduar.",
  Complete:"Kompletuar",
  Sign_Up:"Regjistrohu",
  Error:"Gabim",
  Biometrics:"Bio-metrika",
  Percent:"Përqindje",
  Zero_Percent:"0%",
  Top_Margin:"Marzhi i lartë",
  Bottom_Margin:"Marzhi i poshtëm",
  Left_Margin:"Marzhi i majtë",
  Right_Margin:"Marzhi i djathtë",
  MICR_Margin:"Marzhi MICR",
  Table_Margin:"Marzhi i tabelës",
  Address_Margin:"Marzhi i Adresës",
  Percentage_:"Përqindje",
  Vacation_Title:"Titulli i pushimeve",
  Use_PIN:"Përdorni PIN",
  Loading__:"Po ngarkohet...",
  Design_Title:"Titulli i dizajnit",
  Authorize:"Autorizoj",
  Key:"Celës",
  Public_Key:"Çelësi publik",
  Private_Key:"Çelësi Privat",
  Authenticate:"Autentifiko",
  Last_Payroll:"Lista e fundit e pagave",
  Last_Calculation:"Llogaritja e fundit",
  Authorized:"I autorizuar",
  Geo_Authorized:"Gjeo-Vendndodhja: E autorizuar",
  Not_Authorized:"I paautorizuar",
  Authorization_Complete:"Autorizimi i përfunduar",
  Geolocation_Authorization:"Autorizim gjeo-lokacioni",
  Out_of_Bounds:"Jashtë caqeve",
  Cant_Delete_Default_Design:"Nuk mund të fshihet një dizajn i paracaktuar.",
  Unauthorized_Out_of_Bounds:"I paautorizuar: Jashtë kufijve",
  Biometric_Authorization:"Autorizimi biometrik",
  Locating_Please_Wait:"Po gjendet, ju lutemi prisni...",
  Test_Biometrics:"Testi i biometrisë",
  Cheque_Margins:"Kontrollo Marzhet",
  Percentage_Full_Error:"Fusha e përqindjes nuk mund të vendoset mbi 100% përqind.",
  No_Payroll_Text:"Shto një <a routerLink='/folder/Employee/New'>Punonjës</a> ose <a routerLink='/folder/Payee/New'>Pagues</a> dhe një <a routerLink='/folder/Schedule/New'>Orari</a>.",
  Design_Saved:"Dizajni u ruajt",
  Default_Design_Selected:"U zgjodh dizajni i parazgjedhur",
  Partial_Import:"Import i pjesshëm",
  Partial_Export:"Eksporti i pjesshëm",
  Entire_Import:"I gjithë importi",
  Entire_Export:"Eksporti i plotë",
  Existing_Password:"Ju lutemi shkruani fjalëkalimin tuaj ekzistues:",
  Existing_PIN:"Ju lutemi shkruani kodin tuaj PIN ekzistues:",
  Pin_Change_Header:"Konfirmimi PIN",
  Pin_Change_SubHeader:"Futni numrin tuaj të vjetër PIN për të konfirmuar ndryshimin.",
  Pass_Change_Header:"Konfirmimi i fjalëkalimit",
  Pass_Change_SubHeader:"Futni fjalëkalimin tuaj të vjetër për të konfirmuar ndryshimin.",
  PIN_Enter_Message:"Fusni PIN-in tuaj për të konfirmuar.",
  Password_Enter_Message:"Fusni fjalëkalimin tuaj për të konfirmuar.",
  Pin_Updated_Success:"PIN-i u përditësua me sukses!",
  Pin_Updated_Fail:"PIN-i nuk mund të përditësohej.",
  Pass_Updated_Success:"Fjalëkalimi u përditësua me sukses.",
  Pass_Updated_Fail:"Fjalëkalimi nuk mund të përditësohej.",
  Preview_Payment:"Parashikimi i pagesës",
  Preview_Payroll:"Parashikimi i listës së pagave",
  No_Payments_Created:"Nuk u gjet asnjë pagesë për t'u krijuar.",
  Payment_Preview:"Pamja paraprake e pagesës",
  Enable_Payee:"Aktivizo përfituesin e pagesës",
  Rendered:"E dhënë",
  No_Email:"Nuk ka email",
  Setup_Cheques:"Kontrollet e konfigurimit",
  name:"Emri",
  address:"Adresë",
  address_2:"Rreshti 2 i adresës",
  city:"Qyteti",
  province:"Provinca",
  postal_code:"Kodi Postar/ZIP",
  country:"Vendi",
  username:"Emri i përdoruesit",
  full_name:"Emri i plotë",
  birthday:"ditëlindjen",
  email:"Email",
  phone:"Telefoni",
  employees:"Punonjësit",
  addresses:"Adresat",
  payment_amount_limit:"Kufiri i shumës së pagesës",
  total_limit:"Kufiri total",
  payees:"Të paguarit",
  description:"Përshkrim",
  address_line_one:"Linja e parë e adresës",
  address_line_two:"Rreshti i dytë i adresës",
  image:"Imazhi",
  account_holder:"Mbajtësi i llogarisë",
  cheque_starting_id:"Kontrolloni ID-në e fillimit",
  transit_number:"Numri i tranzitit",
  institution_number:"Numri i institucionit",
  designation_number:"Numri i përcaktimit",
  account_number:"Numri i llogarisë",
  currency:"Monedha",
  signature:"Nënshkrimi",
  payment_payroll_limit:"Kufiri i pagesës",
  total_limi:"Kufiri total",
  date:"Data",
  printed_memo:"Memo e shtypur",
  banks:"bankat",
  signature_image:"Imazhi i nënshkrimit",
  address_name:"Emri i adreses",
  notes:"Shënime",
  design:"Dizajn",
  title:"Titulli",
  frequency:"Frekuenca",
  fax:"Faks",
  salaries:"Pagat",
  salary_ids:"ID-të e pagave",
  start_time:"Koha e nisjes",
  end_time:"Koha e Fundit",
  paid:"I paguar",
  overtime_percentage:"Përqindja e paguar",
  overtime_amount:"Shuma fikse e paguar",
  first_name:"Emri",
  last_name:"Mbiemri",
  moderation:"Moderimi",
  create:"Krijo",
  edit:"Redakto",
  destroy:"Shkatërroni",
  day_start_time:"Dita_fillimi_koha",
  day_end_time:"ora_fundi_ditës",
  fullname:"Emri",
  time:"Koha",
  auto_send:"Dërgo automatikisht",
  time_method:"Metoda e kohës",
  schedules:"Oraret",
  indefinite_payroll_enabled:"Aktivizo për një kohë të pacaktuar",
  amount:"Shuma",
  repeat:"Përsëriteni",
  always_enabled:"Gjithmonë i aktivizuar",
  start_date:"Data e fillimit",
  end_date:"Data e përfundimit",
  Cheque_Total:"Kontrolloni Totalin",
  Total_Amount:"Shuma totale:",
  Amounts:"Shumat:",
  Images:"Imazhet",
  Files:"Skedarët",
  Previewing:"Parashikimi:",
  Insert:"Fut",
  Preview_Import:"Parapamje Importi",
  Entry:"Hyrja",
  Entries:"Regjistrimet",
  No_Entries:"Nuk ka hyrje",
  Import_Type:"Lloji i importit",
  Select_Details:"Zgjidhni Detajet",
  Select_Payee:"Zgjidhni përfituesin e pagesës",
  Enable_Holidays:"Aktivizo pushimet",
  Disable_Holidays:"Çaktivizo pushimet",
  Wire_Transfer:"Transferta me tela",
  New_Export:"Eksporti i ri",
  Export_Data:"Eksporto të dhëna",
  Export_Data_Word:"Zgjidhni llojin e skedarit për të eksportuar dhe shkarkuar.",
  Export_File:"Eksporto skedarin",
  Mode:"Modaliteti",
  Times:"Kohët",
  Widgets:"Widgets",
  Slider:"Rrëshqitës",
  Set_Details:"Vendosni Detajet",
  Select_Type:"Zgjidhni Lloji",
  Display_Slider:"Rrëshqitësi i ekranit",
  Dashboard_Slider:"Rrëshqitësi i pultit",
  Dashboard_Mode:"Modaliteti i panelit të kontrollit",
  Show_Intro:"Shfaq hyrjen",
  Show_Payrolls:"Shfaq listëpagesat",
  Show_Holidays:"Shfaq Festat",
  Show_Invoices:"Shfaq faturat",
  Show_Cheques:"Shfaq çeqet",
  Enabled_Widgets:"Miniaplikacionet e aktivizuara",
  Disabled_Widgets:"Miniaplikacionet me aftësi të kufizuara",
  Colors:"Ngjyrat",
  Barcodes:"Barkodet",
  View_Timers:"Shiko kohëmatësit",
  Gradients:"Gradientët",
  No_Info:"Nuk ka informacion",
  Disable:"Çaktivizo",
  Show_Layer:"Shfaq shtresat",
  Hide_Layer:"Fshih shtresat",
  Text_Layer:"Shtresat e tekstit",
  Secondly:"Së dyti",
  Minutely:"Minutisht",
  nine_am:"09:00",
  five_pm:"5:00 PASDITE",
  Enable_Address:"Aktivizo adresën",
  Invalid_File_Type:"Na vjen keq, u zgjodh një lloj skedari i pavlefshëm. Lloji i skedarit të mbështetur:",
  Error_Updating_Entry:"Na vjen keq, ka pasur një gabim gjatë përditësimit të kësaj hyrjeje.",
  Schedule_Timing_Alert:"Gabim: Orari i fillimit të orarit caktohet në një vlerë pas kohës së përfundimit.",
  Select_Multiple_Payments_Prompt:"Nuk janë zgjedhur pagesa. Zgjidhni të paktën një pagesë.",
  Select_Multiple_Cheques_Prompt:"Nuk janë zgjedhur kontrolle. Ju lutemi zgjidhni të paktën një çek.",
  Select_Multiple_Items_Prompt:"Nuk u zgjodh asnjë artikull. Ju lutemi zgjidhni të paktën një artikull.",
  Paymemt_Multiple_Delete_Confirmation:"Jeni i sigurt që dëshironi të fshini pagesa të shumta?",
  Cheque_Multiple_Delete_Confirmation:"Jeni i sigurt që dëshironi të fshini kontrolle të shumta?",
  Payee_Multiple_Delete_Confirmation:"Jeni i sigurt që dëshironi të fshini shumë përfitues?",
  Employee_Multiple_Delete_Confirmation:"Jeni i sigurt që dëshironi të fshini shumë punonjës?",
  MICR_Warning:"Shënim: Disa printera dhe pajisje mund të mos e shfaqin saktë fontin MICR.",
  Automatically_Send:"Dërgo automatikisht",
  Type:"Lloji",
  Payment_Type:"Lloji i pageses",
  Auto_Send:"Dërgo automatikisht",
  Automatically_Process:"Përpunoni automatikisht",
  Auto_Process:"Procesi automatik",
  Image_Based:"I bazuar në imazh",
  Font_Based:"Bazuar në font",
  Rerender:"Ri-reporto",
  Rendering:"Rendering",
  Render:"Skedarët",
  Top:"Top",
  Middle:"E mesme",
  Bottom:"Poshtë",
  Top_Left:"Majtas lart",
  Top_Center:"Qendra kryesore",
  Top_Right:"Lart djathtas",
  Middle_Left:"E majta e mesme",
  Middle_Center:"Qendra e Mesme",
  Middle_Right:"E djathta e mesme",
  Bottom_Left:"Poshtë Majtas",
  Bottom_Center:"Qendra e poshtme",
  Bottom_Right:"Poshtë djathtas",
  Numbers:"Numrat",
  Verified:"Verifikuar",
  Paper_Size:"Madhësia e letrës",
  New_Device:"Pajisja e re",
  Add_Device:"Shto pajisje",
  Remove_Device:"Hiq pajisjen",
  Delete_Device:"Fshi pajisjen",
  Block_Device:"Blloko pajisjen",
  Block:"Blloko",
  Unblock:"Zhblloko",
  Table:"Tabela",
  Scan_Login_Code:"Skanoni kodin e hyrjes",
  Login_Code:"Kodi i hyrjes",
  Scan_Code:"Skanoni kodin",
  Scan_QR_Code:"Skanoni kodin QR",
  Select_All:"Selektoj të gjitha",
  Deselect_All:"Çzgjidh të gjitha",
  Increase:"Rrit",
  Decrease:"Ulje",
  Bold:"E guximshme",
  Text:"Teksti",
  Style:"Stili",
  Italic:"Kursi",
  QR_Code:"Kodi QR",
  Barcode:"Barkodi",
  Browse_Images:"Shfletoni Imazhet",
  Browse_Files:"Shfletoni skedarët",
  Background_Image:"Imazhi i sfondit",
  Logo_Image:"Imazhi i logos",
  Header_Image:"Imazhi i kokës",
  Bank_Image:"Imazhi i Bankës",
  Cut_Lines:"Linjat e prera",
  Background:"Sfondi",
  License:"Liçensë",
  One_License:"1 Licencë:",
  Licensed:"Licencuar për:",
  Not_Licensed:"I pa licencuar",
  Enter_Serial:"Futni Serialin",
  Serial_Key:"Çelësi Serial",
  Serial:"Serial",
  Product_Key:"Çelësi i produktit",
  Check_Product_Key:"Kontrolloni çelësin e produktit",
  Add_Product_Key:"Shto çelësin e produktit",
  Verifying_Purchase:"Po verifikon blerjen...",
  Print_Envelope:"Zarf i printuar",
  Envelope:"zarf",
  Thank_You:"Faleminderit!",
  Scale:"Shkalla",
  Print_Scale:"Shkalla e printimit",
  Borders:"kufijtë",
  VOID:"I PAPOSHTË",
  Void_Cheque:"Kontrolli i pavlefshëm",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"PAGUANI ME POROSI TË:",
  NO_DOLLARS:"JO DOLLARE ",
  ONE_DOLLAR:"NJE DOLLAR",
  DOLLARS:" DOLARA",
  AND:" DHE ",
  CENTS:" CENT.",
  NO_:"NR ",
  ONE_:"NJË ",
  AND_NO_:"DHE JO ",
  _AND_ONE_:"DHE NJË ",
  DOLLARS_AND_ONE_CENT:" DHE NJË CENT.",
  AND_NO_CENTS:" DHE ZERO CENT.",
  CHEQUE_PRINT_DATE:"DATA:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"deputeti",
  Initial_Tasks:"Detyrat fillestare",
  Inital_Setup:"Konfigurimi fillestar",
  Welcome_To:"Mire se erdhet ne ",
  Welcome:"Mirëserdhe",
  Swipe:"Rrëshqitni",
  Intro_Setup:"Intro Setup",
  Introduction:"Prezantimi",
  CHEQUE_PRINT_CREDIT:"Mundësuar nga Çeqet",
  Title_Intro_Word:"Mirëserdhe në Çeqe",
  Title_Intro:"Hyrja e çeqeve",
  Title_Setup:"Konfigurimi i çeqeve",
  PayPal_Default_Email_Message:"Ju keni marrë një transfertë të re PayPal. [Powered by Cheques]",
  Cheques_App_Export:"Eksportuar me Çeqe",
  Post_Intro_Thanks:"Faleminderit që zgjodhët Çeqet. Procesi i konfigurimit tani ka përfunduar.",
  Post_Intro_Word:"Filloni duke shtypur çekun tuaj të parë, shtoni një pagesë të ardhshme ose shtoni një punonjës në listën e pagave.",
  Upgrade_Required:"Çekat kërkojnë që ju të zotëroni një version më premium të softuerit në mënyrë që të fshehni këtë mesazh dhe të zhbllokoni veçori shtesë.",
  Upgrade_Title:"Çeqe",
  Mailout_Prompt:"Ju gjithashtu mund të zgjidhni që Çeqet të dërgojnë me postë çeqet e listës së pagave për ju.",
  Mailed_Cheque:"Kontrolli i dërguar me postë: ",
  Mailed_Cheque_Color:"Çek me postë (Ngjyrë): ",
  Terms_Purchase:"Të gjitha blerjet elektronike me çeqe janë plotësisht të rimbursueshme deri në 30 ditë nga data e blerjes. Lexoni dhe pranoni <a href='#'>Kushtet dhe kushtet</a> përpara se të vazhdoni.",
  Dashboard_Setup:"Konfiguro printerin primar",
  Dashboard_Add:"Shto llogarinë kryesore bankare",
  Dashboard_Customize:"Zgjidh një model kontrolli",
  Dashboard_Job_Salary:"Krijoni punën tuaj dhe shtoni rrogën tuaj",
  Dashboard_Employees:"Gjurmo punonjësit dhe të paguarit",
  Dashboard_Print:"Shtypni dhe dërgoni me postë çeqet tuaja të pagave",
  Dashboard_Payroll:"Automato printimin e listës suaj të pagave",
  Dashboard_PayPal:"Konfiguroni listën e pagave / pagesat e PayPal",
  Begin_Setup:"Fillimi i konfigurimit",
  Get_Started:"Fillo",
  Purchase:"Blerje",
  Lockdown:"Izolim",
  Unlock:"Hap",
  Detailed:"I detajuar",
  Log_In:"Identifikohu",
  Login:"Hyrja",
  Launch:"Nisja",
  Register:"Regjistrohu",
  Finish:"Përfundo",
  List:"Listë",
  Weekends:"Fundjavë",
  Weekly:"Javore",
  PayPal_Default_Subject:"Pagesa e re",
  Payment_Message:"Mesazhi i pagesës",
  PayPal_Default_Payment_Note:"Faleminderit",
  Setup_Your_Cheqing_Account:"Kontrollimi i llogarisë",
  Add_Your_Primary_Cheqing_Account:"Shto llogarinë tënde kryesore të kontrollit.",
  Welcome_Word:"Thjeshtoni dhe automatizoni menaxhimin e listës së pagave dhe burimeve njerëzore.",
  Get_Started_Quickly:"Thjesht përgjigjuni disa pyetjeve të thjeshta që do të na ndihmojnë të fillojmë...",
  Done_Intro_Word:"Konfigurimi përfundoi",
  PIN_Protected:"Fjalëkalimi dhe PIN i mbrojtur",
  Enter_New_PIN:"Fut një kod të ri PIN:",
  Enter_PIN:"Fut kodin PIN:",
  Invalid_PIN:"U fut PIN i pavlefshëm.",
  Account_Identifier:"Identifikuesi i llogarisë",
  New_Account_Identifier:"Identifikuesi i ri i llogarisë",
  attempt:"përpjekje",
  attempts:"përpjekje",
  remaining:"e mbetur",
  Language:"Gjuhe",
  languages:"Gjuhët",
  select_languages:"Zgjidh gjuhen",
  Deposit:"Depozita",
  Hire_One_Now:"Punëso një tani",
  App_Locked:"Aplikacioni është i kyçur.",
  Skip_:"Kapërce",
  Skip_to_Dashboard:"Kalo te paneli i kontrollit",
  Dashboard:"Pulti",
  Import:"Import",
  Admin:"Administrat",
  New_Admin:"Administ i ri",
  Settings:"Cilësimet",
  Color_Picker:"Zgjedhësi i ngjyrave",
  Font_Picker:"Zgjedhësi i shkronjave",
  Logout:"Shkyç",
  Close:"Afër",
  Close_menu:"Afër",
  Excel:"Skedari Excel",
  Csv:"Skedari CSV",
  Sql:"Skedar SQL",
  Json:"Skedari JSON",
  Url:"Import me URL",
  Back:"Kthehu",
  Timers:"Timers",
  Cheque:"Kontrollo",
  Print:"Printo",
  Designs:"Dizajni",
  Pause_Printing:"Ndalo shtypjen",
  Resume_Printing:"Rifillimi i printimit",
  Printing_Paused:"Printimi u ndal",
  PrintingUnavailable:"Na falni! Printimi nuk ofrohet në këtë sistem.",
  Prints_Paused:"Shtypjet u ndaluan",
  Administration:"Administrata",
  Loading:"Po ngarkohet",
  Unnamed:"Pa emër",
  error:"Na vjen keq, ky kontroll nuk mund të hapej për shikim.",
  No_Cheques_To_Print:"Nuk ka kontrolle për të printuar",
  No_Cheques_To_Print_Word:"Krijoni një <a routerLink='/folder/Cheque/New'>Çek i ri</a>.",
  New_Cheque:"Çek i ri",
  Start_One_Now:"Fillo një Tani",
  Edit_Cheque:"Ndrysho kontrollin",
  Select_Cheques:"Zgjidh kontrollet",
  Select_Employee:"Zgjidhni punonjësin",
  Default_Printer:"Printeri i parazgjedhur",
  Reassign:"Ricaktimi",
  Configure:"Konfiguro",
  Template:"Model",
  Designer:"Dizajner",
  Edit_Designs:"Redakto dizajne",
  New_Design:"Dizajn i ri",
  Next:"Tjetër",
  Save:"Ruaj",
  Name:"Emri",
  Mail:"Mail",
  Amount:"Shuma",
  Date:"Data",
  PayPal:"PayPal",
  Payouts:"Pagesat",
  PayPal_Label:"Etiketa PayPal",
  Email_Username:"Email / Emri i përdoruesit",
  Client_ID:"ID-ja e klientit",
  Sandbox_Email:"Email Sandbox",
  PayPal_Email:"Email PayPal",
  PayPal_Username:"Emri i përdoruesit të API",
  PayPal_Payouts:"Pagesat e PayPal",
  Select_PayPal_Key:"Zgjidh çelësin e PayPal",
  Secret:"Sekreti",
  API_Secret:"API Sekret",
  PayPal_API_Keys:"Çelësat e PayPal",
  New_PayPal_Key:"Çelësi i ri PayPal",
  Email_Subject:"Subjekti i emailit",
  Email_Message:"Mesazhi me email",
  Payout_Options:"Opsionet e pagesës",
  Payment_Note:"Shënim pagese",
  Enable_Employee:"Aktivizo punonjësin",
  Enable_Production_Mode:"Aktivizo modalitetin e prodhimit",
  Sandbox_Username:"Emri i përdoruesit Sandbox",
  Sandbox_Signature:"Nënshkrimi i kutisë së rërës",
  Sandbox_Password:"Fjalëkalimi i kutisë së rërës",
  Production_Username:"Emri i përdoruesit të prodhimit",
  Production_Signature:"Nënshkrimi i prodhimit",
  Production_Password:"Fjalëkalimi i prodhimit",
  Production_Email:"Email i prodhimit",
  API_Client_ID:"ID-ja e klientit API",
  API_Signature:"Nënshkrimi i API",
  API_Password:"Fjalëkalimi API",
  API_Username:"Emri i përdoruesit të API",
  Secret_Key:"Çelësi sekret",
  Sandbox:"Sandbox",
  Production:"Prodhimi",
  Sandbox_Keys:"Çelësat e kutisë së rërës",
  Production_Keys:"Çelësat e prodhimit",
  API_Title:"Titulli i API",
  Production_Mode:"Mënyra e prodhimit",
  Account_Label:"Etiketa e llogarisë",
  No_PayPal_Setup:"Pa çelës PayPal",
  Enable_PayPal_Account:"Aktivizo llogarinë PayPal",
  No_PayPal_Word:"Shto <a routerLink='/folder/Invoice/New'>Çelës API të PayPal</a>.",
  Printed_Memo:"Memo e shtypur",
  Employee:"Punonjës",
  View_Employee:"Shiko punonjësin",
  Mailing_Address:"Adresa postare",
  Company_Address:"Adresa e kompanise",
  Select_Company_Address:"Zgjidh adresën e kompanisë",
  Address:"Adresë",
  Any_Day:"Çdo ditë",
  Address_Name:"Emri i adreses",
  Unit:"Njësi",
  Account:"Llogaria",
  Bank_Account:"Llogari bankare",
  Account_Limits:"Aktivizo kufijtë e llogarisë",
  Payroll:"Lista e pagave",
  New_Payroll:"Lista e re e pagave",
  No_Payroll:"Nuk ka listë të ardhshme të pagave",
  Upcoming_Holiday:"Festa e ardhshme:",
  Invoice_Due:"Fatura e detyrueshme:",
  New_Invoice:"Faturë e re",
  No_Invoices:"Pa fatura",
  No_Invoices_Word:"Krijoni <a routerLink='/folder/Invoice/New'>Faturën</a> të parë.",
  Cheque_Due:"Çeku me vonesë:",
  Payrolls:"Listat e pagave",
  Sandbox_Mode:"Modaliteti i kutisë së rërës",
  Hire:"Puna me qira",
  Pay:"Paguaj",
  New:"I ri",
  Add:"Shto",
  Make:"Bëj",
  Time:"Koha",
  Write:"Shkruaj",
  Holiday:"Pushime",
  Holidays:"Pushime",
  Next_Holiday:"Festa e ardhshme:",
  All_Done:"Gjithçka u krye!",
  Employees:"Punonjësit",
  Payees:"Paguesit",
  Job:"Punë",
  Jobs:"Punë",
  Invoice:"Faturë",
  Invoices:"Faturat",
  Vacations:"Pushimet",
  Cheques:"Çeqe",
  Brand_Cheques:"Marka",
  Payment:"Pagesa",
  Enable_Payment:"Aktivizo pagesën",
  Payments:"Pagesat",
  Schedule:"Orari",
  Schedules:"Oraret",
  Timesheet:"Fleta e kohës",
  Timesheets:"Fletët e kohës",
  Salary:"Paga",
  New_Address:"Adresa e re",
  Address_2:"Adresa (Rreshti 2)",
  _City:"Qyteti",
  _State:"Shteti / Prova",
  City:"Qytet/Qytet",
  State:"Shteti / Provinca",
  Postal:"Kodi Postar / Postar",
  ZIP:"Posta / ZIP",
  Country:"Vendi",
  Addresses:"Adresat",
  Required_Options:"Opsionet e nevojshme",
  Optional_Options:"Opsionet opsionale",
  Additional_Options:"Opsione shtesë",
  Required:"Kërkohet",
  Optional:"Opsionale",
  Additional:"shtesë",
  No_Addresses:"Nuk ka adresa",
  New_Address_Word:"Shto adresën e parë <a routerLink='/folder/Address/New'>Adresa</a>.",
  Select_Address:"Zgjidh adresën",
  No_Address:"Nuk ka adresa",
  Print_Cheque:"Printo kontroll",
  Print_Cheque_Now:"Printo kontrollo tani",
  Description:"Përshkrim",
  Pay_To_The_Order_Of:"Paguaj me porosi të:",
  Select_Date_Range:"Zgjidh diapazonin e datave",
  Select_Starting_Date:"Zgjidhni datën e fillimit",
  Select_Ending_Date:"Zgjidhni datën e përfundimit",
  Select_Date:"Zgjidh datën",
  Cheque_Date:"Data e kontrollit",
  Cheque_Memo:"Kontrollo memo",
  Blank_Cheque:"Çek bosh",
  Blank:"Bosh",
  No_Cheques:"Pa kontrolle",
  No_Cheques_Word:"Shtypni <a routerLink='/folder/Cheque/New'>Kontrollo</a> tuaj të parë.",
  Cheque_Amount_Placeholder:"0,00 dollarë",
  View_Image:"Shiko imazhin",
  View:"Pamje",
  Modify:"Modifiko",
  Delete:"Fshi",
  Cheque_Paid:"I paguar",
  New_Deduction:"Zbritja e re",
  Title:"Titulli",
  Frequency:"Frekuenca",
  Hourly:"Për orë",
  Daily:"E përditshme",
  Weekdays:"Ditët e javës",
  BiWeekly:"2 javë",
  TriWeekly:"3 jave",
  Monthly:"Mujore",
  MiMonthly:"2 muaj",
  Quarterly:"Tremujore",
  Yearly:"Vjetor",
  Every_Week:"Çdo javë",
  Every_Payroll:"Çdo listë pagese",
  Every_Month:"Cdo muaj",
  Annually:"Çdo vit",
  Always_Scheduled:"Gjithmonë i planifikuar",
  Start_Date:"Data e fillimit",
  End_Date:"Data e përfundimit",
  Start_Time:"Koha e nisjes",
  End_Time:"Koha e Fundit",
  Deduction_Label:"Etiketa e zbritjes",
  Notes:"Shënime",
  Options:"Opsione",
  Enable:"Aktivizo",
  Select_Deductions:"Zgjidhni zbritjet",
  Deductions:"Zbritjet",
  No_Deductions:"Pa zbritje",
  No_Deductions_Word:"Krijoni <a routerLink='/folder/Deduction/New'>Zbritja</a> tuaj të parë.",
  New_Employee:"Punonjës i ri",
  No_Title:"Pa titull",
  _Name:"Emri",
  About_Yourself:"Për veten",
  Full_Name:"Emri i plotë",
  Birthday:"Ditëlindja",
  Email:"Email",
  SMS:"SMS",
  Phone:"Telefon",
  Test:"Test",
  Call:"Telefonatë",
  Fax:"Fax",
  Printed_Note:"Shënim i shtypur",
  Position:"Pozicioni",
  Job_Position:"Pozicioni i punës",
  Select_a_Job:"Zgjidh një punë",
  Select_a_Salary:"Zgjidh një pagë",
  Add_a_Deduction:"Shto një zbritje",
  Taxes:"Taksat",
  Add_Taxes:"Shto taksat",
  Date_of_Birth:"Data e lindjes",
  Email_Address:"Adresa e emailit",
  Phone_Number:"Numri i telefonit",
  Phone_Call:"Telefonatë",
  Enable_on_Payroll:"Aktivizo në listën e pagave",
  Select_Employees:"Përzgjidhni punonjësit",
  No_Employees:"Pa punonjës",
  No_Employees_Word:"Shto <a routerLink='/folder/Employee/New'>Punonjësin tënd të parë të ri</a>.",
  No_Name:"Pa emer",
  Unemployeed:"i papunë",
  Employeed:"i punësuar",
  Paid:"I paguar",
  Enabled:"Aktivizuar",
  Disabled:"Me aftësi të kufizuara",
  Fire:"Zjarri",
  Not_Available:"I padisponueshem",
  Not_Available_Word:"Printoni <a routerLink='/folder/Invoice/New'>Faturën</a> tuaj të parë dhe paguhuni.",
  Select_Invoices:"Zgjidh_faturat",
  Print_Invoice_Word:"Printoni <a routerLink='/folder/Invoice/New'>Faturën</a> tuaj të parë dhe paguhuni.",
  Invoice_Title:"Titulli i faturës",
  Invoice_Date:"Data e faturës",
  Due_Date:"Data e afatit",
  New_Job:"Punë e re",
  Details:"Detajet",
  Customize:"Përshtati",
  Customize_Dashboard:"Personalizo panelin e kontrollit",
  Components:"Përbërësit",
  No_Components:"Pa përbërës",
  Main_Components:"Përbërësit kryesorë",
  Smaller_Components:"Përbërësit më të vegjël",
  Error_Loading_Page:"Gabim gjatë ngarkimit të kësaj faqeje.",
  Bank_Details:"Te dhenat e Bankes",
  About_Your_Job:"Rreth punës suaj",
  Your_Schedule:"Orari juaj",
  Job_Title:"Titulli i punës",
  Job_Description:"Përshkrimi i punës",
  Job_Details:"Detajet e punës",
  Enable_Job:"Aktivizo punën",
  Pay_Period:"Periudha e pagesës",
  Perpetually_Schedule:"Perpetually Program",
  Select_Jobs:"Zgjidh punët",
  No_Jobs:"Nuk ka punë",
  Add_Jobs:"Shto punë",
  No_Jobs_Word:"Shto <a routerLink='/folder/Job/New'>Punën e parë</a> në listë.",
  Count_Employees:"job.employee_count+' Punonjës'",
  Zero_Employees:"0 punonjës",
  New_Leave:"Leje e re",
  Leave_Name:"Lini emrin",
  Paid_Leave:"Leje me pagesë",
  Leave_Pay:"Paga e pushimit",
  Indefinite_Leave:"Leje të pacaktuar",
  Indefinite_Payroll:"Paga e pacaktuar",
  Leave:"Largohu",
  Add_Schedules:"Shto oraret",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Aktivizo mungesën",
  Select_Leaves:"Zgjidh gjethet",
  No_Leaves:"Pa gjethe Mungese",
  Leave_Of_Absence:"Leja e Mungeses",
  Leaves_Of_Absence:"Fletet e Mungeses",
  New_Leave_of_Absense:"Leja e re e mungesës",
  No_Leaves_Word:"Shto <a routerLink='/folder/Leave/New'>Lejen e parë të Mungesë</a>.",
  Not_Enabled:"Jo i aktivizuar",
  Absences:"Mungesat",
  Payee:"Paguesi",
  New_Payee:"Paguesi i ri",
  Payee_Identifier:"Identifikuesi i të paguarit",
  Payee_Name:"Emri i të paguarit",
  Payee_Title:"Titulli i të paguarit",
  Payment_Memo:"Memo pagese",
  Select_Payees:"Zgjidhni të paguarit",
  No_Payees:"Nuk ka pagues",
  Add_Payee_Note:"Shto <a routerLink='/folder/Payee/New'>Pagesën e parë</a>.",
  New_Payees:"Paguesit e rinj",
  About_The_Payment_Schedule:"Orari i pagesave",
  Your_Payroll_Schedule:"Paga automatike",
  New_Payment:"Pagesa e re",
  Identifier:"Identifikuesi",
  Select:"Zgjidh",
  Memo:"Memo",
  Payment_Date:"Data e pagesës",
  Mark_as_Paid:"Shëno si të paguar",
  Select_Payments:"Zgjidhni Pagesat",
  No_Payments:"Nuk ka pagesa",
  No_Payments_Word:"Krijoni <a routerLink='/folder/Payment/New'>Pagesën e parë</a>.",
  Create_Payroll:"Krijoni listën e pagave",
  Properties:"Vetitë",
  Payroll_Title:"Titulli i listës së pagave",
  Payroll_Notes:"Shënimet e listës së pagave",
  Payroll_Setup:"Konfigurimi i listës së pagave",
  Tabulate_Payments:"Tabela e pagesave",
  Tabulate:"Tabela",
  By:"Nga:",
  Payments_By:"Pagesat nga",
  Timesheets_And_Schedules:"Fletët dhe oraret",
  Both:"Të dyja",
  Items:"Artikuj",
  Add_Payees:"Shto të paguarit",
  Add_Account:"Shtoni Llogari",
  Enable_Account:"Aktivizo llogarinë",
  Enable_Payroll:"Aktivizo listën e pagave",
  Print_Payroll:"Printo listën e pagave",
  No_Payrolls:"Pa listë pagese",
  No_Payroll_Word:"Krijoni listën tuaj të parë <a routerLink='/folder/Payroll/New'>Pagas</a>.",
  View_more:"VIEW_MORE",
  Less:"PAK",
  Add_Payroll:"Shto listën e pagave",
  Select_Payroll:"Zgjidh listën e pagave",
  About_Your_Salary:"Rreth pagës suaj",
  Add_Salaries:"Shto rroga",
  Add_Salary:"Shto rrogë",
  Salaries:"Pagat",
  No_Salaries:"Pa rroga",
  No_Salaries_Word:"Shto <a routerLink='/folder/Salary/New'>Paga e parë</a>.",
  Select_Salaries:"Zgjidhni pagat",
  New_Salary:"Paga e re",
  Salary_Name:"Identifikuesi i pagës",
  Enable_Salary:"Aktivizo pagën",
  Salary_Amount:"Shuma e pagës",
  New_Schedule:"Orari i ri",
  Schedule_Title:"Titulli i orarit",
  Add_Address:"Shto adresën",
  Repeat:"Përsëriteni",
  Design:"Dizajn",
  Edit_Design:"Edit Design",
  Edit_this_Design:"Ndrysho këtë dizajn",
  Repeat_Payment:"Pagesa e përsëritur",
  Enable_Schedule:"Aktivizo orarin",
  Never:"Asnjëherë",
  Select_Schedule:"Zgjidhni oraret",
  No_Schedules:"Nuk ka orare",
  No_Schedules_Word:"Krijoni <a routerLink='/folder/Schedule/New'>Orari</a> të parë.",
  New_Administrator:"Administratori i ri",
  Username:"Emri i përdoruesit",
  First_Name:"Emri",
  Last_Name:"Mbiemri",
  Add_an_Address:"Shto një adresë",
  Payment_Limit:"Limiti për pagesë",
  Total_Limit:"Limiti total",
  Select_Accounts:"Zgjidh llogaritë",
  No_Administrators:"Pa administratorë",
  No_Administrators_Word:"Krijoni <a routerLink='/folder/Administrator/New'>Llogarinë e parë të Administratorit</a>.",
  New_Administrators_Word:"Shto <a routerLink='/folder/Administrator/New'>Administratorin e parë</a>",
  Cloud:"Re",
  Backup:"Rezervimi",
  Enable_iCloud:"Aktivizo iCloud",
  Enable_Google_Drive:"Aktivizo Google Drive",
  Enable_Microsoft_OneDrive:"Aktivizo Microsoft OneDrive",
  Automatically_Backup:"Rezervimi automatik",
  FTP_Settings:"Cilësimet e FTP",
  URL_File_Prompt:"Ju lutemi, specifikoni vendndodhjen për një skedar .xls / .xlsx / .json për të importuar:",
  URL_SQL_Prompt:"Ju lutemi specifikoni vendndodhjen e skedarit SQLite për të importuar:",
  FTP_Backup:"Rezervimi i FTP",
  FTP_Import:"Importi FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Mikpritës",
  Port:"Port",
  Path:"Rrugë",
  Data_Path:"Rruga e të dhënave",
  Enable_FTP_Account:"Aktivizo llogarinë FTP",
  HostnameIP:"Emri i hostit",
  Password:"Fjalëkalimi",
  Connection_Port:"Porti i lidhjes",
  Enable_MySQL_Database:"Aktivizo bazën e të dhënave MySQL",
  Log:"Regjistri",
  Reset:"Rivendos",
  Erase:"Fshi",
  Export:"Eksport",
  Database:"Baza e të dhënave",
  Upload_CSV:"Ngarko CSV",
  Download_CSV:"Shkarko CSV",
  SQL_Database:"URL SQL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Rezervimi i MySQL",
  Internal_Notes:"Shënime të brendshme",
  Root_Path:"Rruga e rrënjës",
  Select_Backup:"Zgjidhni kopje rezervë",
  Add_New_Backup:"Shto një kopje rezervë të re",
  First_Backup:"Konfiguro rezervimin e parë...",
  Backups:"Rezervimet",
  Add_Backup:"Shto kopje rezervë",
  No_Backups:"Nuk ka asnjë kopje rezervë për t'u gjetur.",
  Select_Backup_Type:"Zgjidhni llojin e kopjes rezervë që dëshironi të konfiguroni...",
  Backup_Type:"Lloji rezervë",
  FTP_Drive:"FTP Drive",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Drive",
  Microsoft_OneDrive:"Drive",
  Import_Fields:"Fushat e importit",
  Import_Fields_Word:"Përdorni këtë seksion për të zgjedhur të dhëna <a routerLink='/folder/Holiday/New'>Importoni</a>.",
  Upload:"Ngarkoni",
  Download:"Shkarko",
  Download_JSON:"Shkarko si të dhëna JSON",
  Download_SQL:"Shkarko si skedar SQL",
  New_Bank:"Banka e re",
  New_Account:"Llogari e re",
  New_Bank_Account:"Llogaria e re bankare",
  Upload_Image:"Ngarko imazh",
  Bank_Name:"Emri i bankes",
  Bank_Address:"Adresa e bankës",
  Branch_Address:"Adresa e degës",
  Address_on_Cheque:"Adresë",
  Cheque_Numbers:"Kontrollo numrat",
  Cheque_Details:"Kontrollo detajet",
  Bank_Logo:"Logo e bankës",
  Cheque_ID:"Kontrollo ID",
  Starting_Cheque_ID:"Fillimi i ID-së së kontrollit",
  Transit_Number:"Numri i tranzitit",
  Institution_Number:"Numri i institucionit",
  Designation_Number:"Numri i përcaktimit",
  Account_Number:"Numri i llogarisë",
  Limits:"Limitet",
  Default_Limits:"Kufijtë e paracaktuar",
  Over_Limit:"Mbi limit",
  Under_Limit:"Nën kufi",
  Payroll_Limit:"Limiti i listës së pagave",
  Enable_Bank_Account:"Aktivizo llogarinë bankare",
  Select_Account:"Zgjidh llogarinë",
  No_Bank_Account:"Pa llogari bankare",
  No_Bank_Account_Word:"Shto <a routerLink='/folder/Accounts/New'>Llogarinë e parë Bankare</a>.",
  Bank_Accounts:"Llogari bankare",
  No_Accounts:"Nuk ka llogari",
  No_Accounts_Note:"Shto <a routerLink='/folder/Accounts/New'>Llogarinë e parë Bankare</a>.",
  Cheque_Designer:"Check Designer",
  Cheque_Design:"Kontrollo dizajnin",
  Select_Design:"Zgjidh një dizajn",
  Cheque_Designs:"Kontrollo dizajnet",
  No_Cheque_Designs:"Nuk ka modele kontrolli",
  No_Cheque_Designs_Word:"Krijoni <a routerLink='/folder/Settings/Cheque/Design/New'>Check Design</a> tuajin.",
  Set_Default:"Vendose si paresore",
  Default:"E parazgjedhur",
  Remove:"Hiq",
  Folder:"Dosje",
  Edit:"Redakto",
  LoadingDots:"Po ngarkohet...",
  Add_a_New_File:"Shto një <a href='#' (kliko)='add()'>Skedar të ri</a>",
  this_folder:"kjo dosje",
  FTP_Backup_Settings:"Cilësimet e rezervimit të FTP",
  Secure_File_Transfer:"Transferim i sigurt i skedarëve",
  New_Holiday:"Festa e Re",
  Add_Holiday:"Shto pushime",
  Holiday_Name:"Emri i festës",
  Additional_Pay:"Pagesa shtesë",
  Enable_Holiday:"Aktivizo pushimet",
  Select_Holidays:"Zgjidh pushimet",
  No_Holidays:"Nuk ka pushime",
  No_Holidays_Word:"Shto <a routerLink='/folder/Holiday/New'>Pushime publike</a> të parë.",
  New_Import:"Import i ri",
  Import_Data:"Importoni të dhënat",
  Import_Data_Word:"Zgjidhni llojin e skedarit ose metodën e ngarkimit që keni zgjedhur.<br /> Ju do të jeni në gjendje të zgjidhni cilat fusha të importuara në një skedar korrespondojnë me cilindo parametr në aplikacion pas ngarkimit të një skedari të mbështetur.", 
  Import_File:"Importo skedarin",
  Link_To_File:"Lidhja me skedarin",
  Select_File:"Zgjidh skedarin",
  New_Moderator:"Moderator i ri",
  Allow_Moderation:"Lejo moderimin",
  Create_Paycheques:"Krijoni çeqe pagash",
  Edit_Paycheques_and_Data:"Redakto pagesat dhe të dhënat",
  Destroy_Data_and_Paycheques:"Shkatërroni të dhënat dhe çeqet e pagave",
  Bonus_Percentage:"Përqindja e pagave",
  Fixed_Amount:"Shuma fikse",
  Select_Moderator:"Zgjidh Moderatorin",
  No_Moderators:"Pa Moderatorë",
  Moderators:"Moderatorët",
  Moderator_Account:"Krijoni llogarinë e parë <a routerLink='/folder/Administrator/New'>Moderator</a>.",
  No_Moderators_Word:"Shto <a routerLink='/folder/Administrator/New'>Moderatorin e parë</a>.",
  Defaults:"Të paracaktuarat",
  Provide_Us_Info:"Na jep informacion",
  Setup_Your_Printer:"Konfiguro printerin tënd",
  Your_Company:"Rreth kompanisë suaj",
  Company_Name:"Emri i Kompanise",
  Currency:"Valuta",
  Default_Currency:"Valuta e paracaktuar",
  Base_Monthly_Income:"Të ardhurat mujore",
  Protection:"Mbrojtja",
  App_Protection:"Mbrojtja e aplikacionit",
  PIN_Code_Protection:"Mbrojtja e kodit PIN",
  App_Protection_Word:"Aktivizo metodat e sigurisë që ndihmojnë në mbrojtjen e llogarisë suaj.",
  PIN_Code:"Kodi pin",
  Change_PIN:"Ndrysho PIN",
  New_Password:"Fjalëkalim i ri",
  Geofence_Protection:"Mbrojtja e gjeo-gardhit",
  Geofence_Area:"Vendosja e zonës",
  Distance:"Distanca",
  Setup_Now:"Konfiguro tani",
  Mile:"Mile",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Njohja e fytyrës",
  Face:"Fytyra",
  Setup:"Konfigurimi",
  Label:"Etiketa",
  Password_Protection:"Mbrojtja e fjalëkalimit",
  Modify_Password:"Ndrysho fjalëkalimin",
  New_Tax_Entry:"Hyrja e re tatimore",
  New_Tax:"Taksa e re",
  Tax_Label:"Etiketa tatimore",
  Perpetually_Enabled:"Aktivizuar përgjithmonë",
  Select_Taxes:"Zgjidhni taksat",
  Tax_Deductions:"Zbritje tatimore",
  No_Tax_Deductions:"Nuk ka zbritje tatimore",
  No_Tax_Deductions_Word:"Shto zbritjen e parë <a routerLink='/folder/Tax/New'>Taksën</a>.",
  New_Timer:"New Timer",
  Start:"Fillimi",
  Stop:"Stop",
  Start_Timer:"Start Timer",
  Stop_Timer:"Stop Timer",
  Timer_Active:"Timeri aktiv",
  Timer:"Timeri:",
  Timer_Running:"Kohëmatësi: (duke punuar)",
  Save_Timer:"Ruaj kohëmatësin",
  New_Timesheet:"Fleta e Kohës së Re",
  Select_Timesheets:"Zgjidh fletët e kohës",
  Work_Notes:"Shënimet e punës",
  Entry_Title:"Titulli i hyrjes",
  No_Timesheets:"Nuk ka fletë kohore",
  No_Timesheets_Word:"Shto <a routerLink='/folder/Timesheet/New'>Fleta kohore</a> e parë.",
  Timesheet_Submitted:"Fleta kohore e dorëzuar",
  Timesheet_Congrats:"Urime! Fleta juaj e kohës është dorëzuar me sukses. Faleminderit!",
  Timesheet_Copy:"Për të marrë një kopje të dokumenteve tuaja, ju lutemi na jepni adresën tuaj të emailit dhe/ose numrin e telefonit celular.",
  Submit:"Dorëzo",
  Allow_Notifications:"Lejo njoftimet",
  Untitled_Entry:"Hyrje e re",
  Untitled_Bank:"Banka pa titull",
  Timesheet_Entry:"Hyrja e fletës së kohës",
  Work_Description:"Pershkrimi i punes",
  Enable_Timesheet:"Aktivizo fletën e kohës",
  Submit_Timesheet:"Dorëzo fletën e kohës",
  Vacation:"Pushime",
  New_Vacation:"Pushime të reja",
  Vacation_Name:"Emri i pushimeve",
  Paid_Vacation:"Pushime me pagesë",
  Vacation_Pay:"Paga për pushime",
  Enable_Vacation:"Aktivizo pushimet",
  Select_Vacations:"Zgjidh pushimet",
  No_Vacations:"Nuk ka pushime",
  No_Vacations_Word:"Krijo hyrjen e parë <a routerLink='/folder/Vacation/New'>Pushime</a>.",
  Payroll_Schedule:"Programi i pagave",
  Next_Payroll:"Paga e ardhshme:",
  Upcoming_Payroll:"Paga e ardhshme",
  No_Upcoming_Payroll:"Nuk ka listë të ardhshme të pagave",
  Address_Book:"Libri i adresave",
  Archived_Documents:"Dokumentet e arkivuara",
  Dev_Mode:"Aplikimi në modalitetin e zhvillimit",
  Administrators:"Administrat",
  Privacy:"Privatësia",
  None:"Asnje",
  Select_Signature:"Zgjidh nënshkrimin",
  No_Signatures:"Pa nënshkrime",
  No_Signatures_Word:"Shto <a routerLink='/folder/Signature/New'>Nënshkrimin e parë</a>.",
  Repeat_Indefinitely:"Përsëriteni pafundësisht",
  Sign:"Shenjë",
  Sign_Here:"Regjistrohu këtu",
  Date_Signed:"Data e nënshkrimit",
  Signature_Pad:"Pastë nënshkrimi",
  Account_Holder:"Mbajtësi i llogarisë",
  Account_Properties:"Vetitë e llogarisë",
  Name_On_Cheque:"Emri në çek",
  Server_Hostname:"Emri i serverit / IP",
  Printers:"Printerë",
  No_Printers:"Pa printera",
  Printer_Exists:"Një printer me këtë emër ekziston tashmë.",
  No_Printers_Word:"Shto <a routerLink='/folder/Printer/New'>Printerin e parë</a>.",
  No_Printer_Alert:"Asnjë printer nuk është përcaktuar. Dëshiron të konfigurosh një printer?",
  Add_Printer:"Shto printer",
  New_Printer:"Printer i ri",
  Printer_Hostname:"Emri i hostit / IP i printerit",
  Printer_Label:"Etiketa e printerit",
  Printer_Protocol:"Protokolli i printerit",
  Protocol:"Protokolli",
  Email_Print:"Email",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Prizë",
  Print_Job:"Puna e printimit",
  Printed:"E shtypur",
  Not_Printed:"Jo i shtypur",
  Print_Jobs:"Punët e printimit",
  Print_Queue:"Radha e printimit",
  No_Print_Jobs:"Nuk ka punë printimi",
  No_Prints:"Krijoni një <a routerLink='/folder/Cheque/New'>Çek</a> të ri për të printuar.",
  Prints:"Printime",
  Find_Printer:"Gjeni printerin",
  Find_AirPrint_Devices:"Gjeni pajisjet AirPrint",
  Select_Printer:"Zgjidh printerin",
  System_UI:"Ndërfaqja e Sistemit",
  Printer:"Printer",
  Status:"Statusi",
  Preview:"Paraafishimi",
  Enable_Print_Job:"Aktivizo punën e printimit",
  Queue_Weight:"Pesha e radhës",
  Unlimited:"i pakufizuar",
  Show_Advanced_Printer_Options:"Shfaq opsionet e avancuara të printerit",
  Advanced:"E avancuar",
  Location:"Vendndodhja",
  Note:"Shënim",
  Queue_Name:"Emri i radhës",
  Pages_Printed_Limit:"Limiti i printimit të faqeve",
  MultiPage_Idle_Time:"Koha e pritjes me shumë faqe",
  Page_Count_Limit:"Limiti i numrit të faqeve",
  Page_Orientation:"Orientimi i faqes",
  Portrait:"Portret",
  Landscape:"Peizazh",
  Duplex:"Duplex",
  Double_Sided_Printing:"Dyaneshem",
  Duplex_Mode:"Modaliteti i dyfishtë",
  Duplex_Short:"Shkurt",
  Duplex_Long:"i gjatë",
  Duplex_None:"Asnje",
  Color_And_Quality:"Ngjyra dhe cilësia",
  Monochrome:"Monokrom",
  Photo_Quality_Prints:"Printime me cilësi fotografike",
  Printer_Email:"Email printeri",
  Automatically_Downsize:"Zvogëlimi automatik",
  Paper:"Letër",
  Paper_Name:"Emri i letrës",
  Paper_Width:"Gjerësia e letrës",
  Paper_Height:"Lartësia e letrës",
  Paper_Autocut_Length:"Gjatësia e prerjes automatike të letrës",
  Margins:"Margjinat",
  Page_Margins:"Margjinat e faqes",
  Page_Margin_Top:"Margjina e faqes kryesore",
  Page_Margin_Right:"Margjina e faqes së djathtë",
  Page_Margin_Bottom:"Marzhi i faqes së poshtme",
  Page_Margin_Left:"Margjina e faqes së majtë",
  Add_Employees:"Shto punonjës",
  Header:"Titulli",
  Print_A_Page_Header:"Printo një kokë faqeje",
  Header_Label:"Etiketa e kokës",
  Header_Page_Index:"Indeksi i faqes së kokës",
  Header_Font_Name:"Fonti i kokës",
  Select_Font:"Zgjidh fontin",
  Font_Selector:"Zgjedhësi i shkronjave",
  Header_Font_Size:"Fonti i kokës",
  Header_Bold:"Header Bold",
  Header_Italic:"Titulli i pjerrët",
  Header_Alignment:"Rreshtimi i kokës",
  Left:"Majtas",
  Center:"Qendra",
  Right:"E drejta",
  Justified:"E justifikuar",
  Header_Font_Color:"Ngjyra e kokës",
  Select_Color:"Zgjidhni ngjyrën",
  Footer:"Futer",
  Print_A_Page_Footer:"Printo një fund faqeje",
  Footer_Label:"Etiketa e fundit",
  Footer_Page_Index:"Indeksi i faqes në fund të faqes",
  Footer_Font_Name:"Font Footer",
  Fonts:"Fontet",
  Done:"U krye",
  Select_Fonts:"Zgjidh fontet",
  Footer_Font_Size:"Madhësia e fundores",
  Footer_Bold:"Foter Bold",
  Footer_Italic:"Foter Italic",
  Footer_Alignment:"Rreshtimi i këmbës",
  Footer_Font_Color:"Ngjyra e fundores",
  Page_Copies:"Kopje faqesh",
  Enable_Printer:"Aktivizo printerin",
  Remote_Logging:"Regjistrimi në distancë",
  Log_Server:"Serveri i regjistrave",
  Encryption:"Kriptimi",
  Random_Key:"Çelësi i rastësishëm",
  Encryption_Key:"Çelësi i enkriptimit",
  Cheques_Webserver:"Baza e të dhënave me porosi",
  Learn_How:"Mësoni si",
  Signature:"Nënshkrimi",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Shiko nënshkrimin",
  Printed_Signature:"Nënshkrimi i shtypur",
  Digitally_Sign:"Shenja dixhitale",
  Digital_Signature:"Nënshkrimi dixhital",
  Add_Signature:"Shto nënshkrim",
  Add_Your_Signature:"Shto nënshkrimin tënd",
  Enable_Signature:"Aktivizo nënshkrimin",
  Digitally_Signed:"E nënshkruar dixhitalisht",
  Insert_Error:"Nuk mund të ruhet kontrolli për shkak të problemeve të bazës së të dhënave.",
  Delete_Confirmation:"Je i sigurt që dëshiron ta fshish këtë informacion?",
  Discard_Confirmation:"Je i sigurt që dëshiron ta heqësh këtë informacion?",
  Discard_Bank_Confirmation:"Je i sigurt që dëshiron ta heqësh këtë llogari?",
  Discard_Printer_Confirmation:"Je i sigurt që dëshiron ta heqësh këtë printer?",
  Delete_Bonus_Confirmation:"Je i sigurt që dëshiron ta fshish këtë bonus?",
  Delete_Invoice_Confirmation:"Je i sigurt që dëshiron ta fshish këtë faturë?",
  Generated_Cheque:"Çek i krijuar",
  Generated_Invoice:"Faturë e krijuar",
  Schedule_Start_Time:"Orari i fillimit",
  Schedule_End_Time:"Fundi i orarit",
  New_Call:"Thirrje e re",
  No_Contacts:"Nuk ka kontakte",
  No_Contacts_Word:"Administratorët, moderatorët, punonjësit dhe paguesit shfaqen si kontakte.",
  PDF_Subject:"financat",
  PDF_Keywords:"Çekët e pagave të kontrollit PDF",
  Printer_Setup:"Konfigurimi i printerit",
  Printer_Selection:"Zgjedhja e printerit",
  New_Fax:"Fax i ri",
  New_Fax_Message:"Mesazhi i ri me faks",
  Fax_Machine:"Makinë faksi",
  Fax_Name:"Emri i faksit",
  Fax_Email:"Email me faks",
  Fax_Number:"Numri i faksit",
  Contents:"Përmbajtja",
  Fax_Body:"Trupi i faqes",
  Header_Word:"Titulli:",
  Header_Text:"Teksti i titullit",
  Include_Header:"Përfshi kokën",
  Include_Footer:"Përfshi fundin",
  Footer_Word:"Futeri:",
  Footer_Text:"Teksti në fund",
  Attach_a_Cheque:"Bashkni një çek",
  Add_Deduction:"Shto zbritjen",
  Enable_Fax:"Dërgo faks",
  Select_Fax:"Zgjidhni Faksin",
  No_Faxes:"Pa fakse",
  Faxes:"Fakset",
  Save_and_Send:"Dërgo faks",
  Save_and_Pay:"Ruaj dhe paguaj",
  WARNING:"KUJDES:",
  Remember:"Mos harroni",
  Printing:"Printim",
  Printing_Complete:"Printimi përfundoi!\n\n",
  of:"nga",
  There_Were:"Ka pasur",
  Successful_Prints:"printime të suksesshme dhe ",
  Unsuccessful_Prints:"printime të pasuksesshme.",
  PrinterError:"Më falni! Kishte një gabim.",
  Printing_:"Printimi...",
  PrinterSuccess:"Dokumenti u printua me sukses.",
  PrintersSuccess:"Dokumentet u printuan me sukses.",
  Print_Cheques:"Printo kontrolle",
  New_Message:"Mesazh i ri",
  New_Messages:"Mesazhe të reja",
  Read_Message:"Lexo mesazhin",
  Write_Message:"Shkruaj një mesazh",
  Send_Message:"Dërgoni mesazh",
  Subject:"Subjekti",
  Message:"Mesazhi",
  Writing:"Të shkruarit...",
  Send:"Dërgo",
  Set_Date:"Vendosni datën",
  Set_Time:"Vendos oren",
  Recieve:"Merr",
  Set_as_Default:"Vendose si paresore",
  Default_Account:"Llogaria e paracaktuar",
  Default_Design:"Dizajni i parazgjedhur",
  Multiple_Cheques:"Checks (Triple)",
  Account_Mode:"Modaliteti i llogarisë",
  Multiple_Cheques_Description:"Tre kontrolle për faqe.",
  Check_and_Table:"Kontrollo dhe tabelë",
  Check_including_Table:"Tabela e kontrollit dhe kontabilitetit.",
  Check_Mailer:"Kontrollo postuesin",
  Check_Mailer_Window:"Kontrollo me një dritare adrese.",
  DocuGard_Table_Top:"Kontrolli dhe tabela e DocuGard (lart)",
  DocuGard_Table_Middle:"Kontrolli dhe tabela e DocuGard (në mes)",
  DocuGard_Table_Bottom:"Kontrolli dhe tabela e DocuGard (poshtë)",
  DocuGard_Mailer_Top:"DocuGard Check Mailer (lart)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (Mes)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mailer (poshtë)",
  DocuGard_Three_Cheques:"Kontrollet e DocuGard (Tripple)",
  DocuGard_Cheque_Top:"Kontrolli i DocuGard (lart)",
  DocuGard_Cheque_Middle:"Kontrolli i DocuGard (Mes)",
  DocuGard_Cheque_Bottom:"Kontrolli i DocuGard (poshtë)",
  DocuGard_Three_Cheques_Window:"Tre kontrolle në një faqe.",
  DocuGard_Table_Top_Window:"Tabela e kontrollit dhe fitimeve.",
  DocuGard_Table_Middle_Window:"Tabela e kontrollit dhe fitimeve.",
  DocuGard_Table_Bottom_Window:"Tabela e kontrollit dhe fitimeve.",
  DocuGard_Mailer_Top_Window:"Kontrollo, tabelë dhe adresë.",
  DocuGard_Mailer_Middle_Window:"Kontrollo, tabelë dhe adresë.",
  DocuGard_Mailer_Bottom_Window:"Kontrollo, tabelë dhe adresë.",
  DocuGard_Cheque_Top_Window:"Kontrollo për letër të sigurt.",
  DocuGard_Cheque_Middle_Window:"Kontrollo për letër të sigurt.",
  DocuGard_Cheque_Bottom_Window:"Kontrollo për letër të sigurt.",
  Basic_Cheque:"Kontrollo (sipër)",
  Basic_Cheque_Print:"Shtypni një çek të vetëm.",
  Error_Setting_As_Paid:"Gabim në vendosjen si të paguar",
  Add_Attachment:"Shto një bashkëngjitje",
  PrinterUnavailable:"Printeri nuk disponohet",
  CreditCardComponent:"Karta",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Depozita e re",
  Deposits:"Depozita",
  No_Deposits:"Pa depozita",
  Credit_Card_Deposit:"Kartë Krediti",
  New_Credit_Card_Deposit_Message:"Depozitë me kartë krediti",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Depozita BitCoin",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Transferimi Interac",
  Payments_Limit:"Limiti i pagesës",
  No_Payment_Limit:"Pa limit pagese",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Depozita në PayPal",
  No_Deposits_Word:"Shto kompaninë e parë <a routerLink='/folder/Deposit/New'>Depozitë</a>.",
  No_Documents_Specified:"Asnjë dokument nuk ishte specifikuar për printim",
  No_Printers_Added:"Nuk u gjet asnjë printer. Shko te Settings > Printers për të shtuar një.",
  DB_Erase_Prompt:"E fshini plotësisht të gjithë bazën e të dhënave? PARALAJMËRIM: Do të humbisni të gjitha informacionet e ruajtura!",
  Console_Warning:"Mos ngjisni asnjë tekst në këtë tastierë. Mund ta vendosni veten dhe/ose kompaninë tuaj në rrezik serioz.",
  No_Faxes_Word:"Krijoni <a routerLink='/folder/Fax/New'>Fax-in e parë</a> të parë.",
  Cheque_Delete_Confirmation:"Je i sigurt që dëshiron ta fshish këtë kontroll?",
  Design_Delete_Confirmation:"Je i sigurt që dëshiron ta fshish këtë dizajn?",
  Cheque_Pay_Confirmation:"Shënoje këtë çek si të paguar? NUK do të shfaqet në radhën e printimit.",
  Payment_Pay_Confirmation:"Shënoje këtë pagesë si të paguar? NUK do të shfaqet në radhën e çeqeve.",
  Delete_Deduction_Confirmation:"Je i sigurt që dëshiron ta fshish këtë zbritje?",
  Delete_Job_Confirmation:"A je i sigurt që dëshiron ta fshish këtë punë?",
  Delete_Timesheet_Confirmation:"Je i sigurt që dëshiron ta fshish këtë fletë kohore?",
  Delete_Schedule_Confirmation:"Je i sigurt që dëshiron ta fshish këtë orar?",
  Delete_Setting_Confirmation:"Je i sigurt që dëshiron ta rivendosësh këtë cilësim?",
  Delete_Fax_Confirmation:"Je i sigurt që dëshiron ta fshish këtë faks?",
  Delete_File_Confirmation:"Je i sigurt që dëshiron ta fshish këtë skedar?",
  Delete_Vacation_Confirmation:"A je i sigurt që dëshiron ta fshish këtë pushim?",
  Delete_Printer_Confirmation:"Je i sigurt që dëshiron ta fshish këtë printer?",
  Remove_Design_Confirmation:"Je i sigurt që dëshiron ta fshish këtë dizajn?",
  Delete_Payroll_Confirmation:"A je i sigurt që dëshiron ta fshish këtë listë pagash?",
  Send_Fax_Email_Confirmation:"A doni t'i dërgoni me faks dhe email këto dokumente?",
  Send_Email_Confirmation:"A doni t'i dërgoni email këtij dokumenti?",
  Send_Fax_Confirmation:"A doni ta dërgoni me faks këtë dokument?",
  Error_Generating_PDF:"Na falni. Pati një gabim gjatë krijimit të këtij dokumenti.",
  PDF_Error_Saving_Image:"Na falni. Pati një gabim gjatë ruajtjes së imazhit PDF të këtij dokumenti.",
  Test_Printer_Confirmation:"Doni të printoni një faqe testimi në këtë printer?",
  Save_Timesheet_Prompt:"Ju lutemi shtoni një 'Titull' ose shtypni 'Start Timer' për të ruajtur.",
  Remove_Geofence_Prompt:"A jeni i sigurt që dëshironi të hiqni vendndodhjen e këtij gjeo-gardhi?",
  Delete_Employee_Confirmation:"A jeni i sigurt që dëshironi të fshini",
  Fire_Employee_Confirmation:"A jeni i sigurt se doni të qëlloni"
}