import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { WebService } from '../services/web.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor(
    private web: WebService,
    private router: Router) {
  }
  price=environment.prices;
  go(l?) {
    this.router.navigate([l]);
  }
  demo() {
    window.location.href="https://chequesapp.com";
    // this.go('online'); 
  }
  view_pricing() {
    this.go('pricing');
  }
  month() {
    this.go('pricing');
  }
  buy() {
    this.go('pricing');
  }
  licence() {
    this.go('pricing');
  }
  trial() {
   this.go('signup'); 
  }
  screens() {
    this.go('screenshots');
  }
  register() {
    this.go('signup');
  }
  ios() {
    var url = "/dist/0.8.1.2/Cheques.exe";
    // document.location.href = url;
  }
  buy_one_time() {
    this.go('pricing');
  }
  mac() {
    var url = "/dist/2.0.1/Cheques-mac-x64/Cheques-v2.0.dmg";
    document.location.href = url;
  }
  linux() {
    if(confirm('The current version of Cheques (2.0.1) has not yet been tested on Linux operating systems. Please proceed at your own risk.')){      
      var url = "/dist/2.0.1/Cheques-linux-x64/Cheques";
      document.location.href = url;
    }
  }
  windows() {
    if(confirm('The current version of Cheques (2.0.1) has not yet been tested on Microsoft Windows. Please proceed at your own risk.')){      
      var url = "/dist/2.0.1/Cheques-win32-x64/Cheques.exe";
      document.location.href = url;
    }
  }
  ngOnInit() {
  }
}