export const Cy = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Hawlfraint &copi; 2023",
  black:"Du",
  green:"Gwyrdd",
  gold:"Aur",
  blue:"Glas",
  brown:"Brown",
  purple:"Porffor",
  pink:"Pinc",
  red:"Coch",
  Swatches:"Swatches",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Llew",
  Saturation:"Dirlawnder",
  Lightness:"Ysgafnder",
  Upgrade_To_Pro:"Uwchraddio i Pro",
  AllFeaturesInclude:"Mae pob nodwedd yn cynnwys:",
  PrintUnlimitedCheques:"Argraffu Gwiriadau Anghyfyngedig",
  PremiumChequeDesigns:"Dyluniadau Gwirio Premiwm",
  ManageUnlimitedEmployees:"Rheoli Gweithwyr Anghyfyngedig",
  AddUnlimitedPayees:"Ychwanegu Talwyr Anghyfyngedig",
  CreateUnlimitedPayrolls:"Creu Cyflogres Anghyfyngedig",
  UnlimitedSchedulesandTimeSheets:"Amserlenni a Thaflenni Amser Anghyfyngedig",
  BulkPayPalPayouts:"Swmp Taliadau PayPal",
  UnlimitedBankAccounts:"Cyfrifon Banc Anghyfyngedig",
  ManageMultipleCompanies:"Rheoli Cwmnïau Lluosog",
  TrackInsurancePolicies:"Trac Polisïau Yswiriant",
  Bio_MetricProtection:"Diogelu Bio-Metrig",
  Geo_FenceLock_OutProtection:"Diogelu Cloi Allan Geo-Fensys",
  Multiple_Companies_Word:"Nid yw rheoli cwmnïau lluosog ar gael heb y premiwm Gwiriadau.",
  PayPal_Payouts_Word:"Mae taliadau PayPal wedi'u hanalluogi heb y premiwm sieciau.",
  PINProtection:"Diogelu PIN",
  PasswordProtection:"Diogelu Cyfrinair",
  May_Require_Approval:"Efallai y bydd angen cymeradwyaeth.",
  Subscribe:"Tanysgrifio",
  Billed:"Wedi'i filio",
  Up:"I fyny",
  Down:"I lawr",
  Positioning:"Lleoli",
  Marker:"Marciwr",
  Drag_Marker:"Marciwr Llusgwch",
  Tagline:"Argraffu sieciau a thablau o'r gyflogres",
  Marker_Word:"Defnyddiwch y Marcwyr i faint yr elfen.",
  Pinch_Zoom:"Chwyddo Pinsied",
  Pinch_Word:"Pinsio i chwyddo'r elfen.",
  Drag:"Llusgwch",
  Drag_Word:"Defnyddiwch eich bys i lusgo elfennau.",
  subscription_alias_word:"Adnewyddu Tanysgrifiad yn Awtomatig",
  premium_alias_word:"Pecyn Uwchraddio Un Amser",
  print_alias_word:"Argraffu Gwiriadau Unigol",
  mode_alias_word:"Modd",
  Pro:"Proffesiynol",
  Pro_word:"Angen fersiwn pro.",
  Cant_Delete_Default_Company_Word:"Mae'n ddrwg gennym, ni allwch ddileu eich cwmni diofyn.",
  Reinsert_Default_Designs:"Ail-osod Cynlluniau Rhagosodedig",
  Reinsert_Default_Designs_word:"Ydych chi am ail-osod dyluniadau rhagosodedig?",
  Subscription_Active_Disable_Word:"Mae'r tanysgrifiad hwn yn weithredol. Hoffech chi ganslo'r tanysgrifiad hwn i Checks?",
  Company_Logo:"Logo'r Cwmni",
  ZERO_:"ZERO",
  Disclaimer:"Ymwadiad",
  Privacy_Policy:"Polisi Preifatrwydd",
  EULA:"Gwiriadau EULA",
  Terms_Of_Service:"Telerau Gwasanaeth",
  Terms_Of_Use:"Telerau Defnyddio",
  Refunds:"Polisi Ad-daliad",
  Single_Print:"1 Gwirio",
  Two_Prints:"2 siec",
  Five_Prints:"5 Gwirion",
  Ten_Prints:"10 Gwirion",
  Fifteen_Prints:"15 Gwirion",
  Twenty_Prints:"20 Gwirion",
  Thirty_Prints:"30 Gwirion",
  Image_Added:"Ychwanegwyd Delwedd",
  Image_Preview:"Rhagolwg Delwedd",
  No_Image_Was_Selected:"Ni ddewiswyd delwedd.",
  Cheques_Unlimited:"Gwiriadau Unlimited",
  _Subscription:"Tanysgrifiad",
  Subscription:"Sieciau - 1 Mis",
  Two_Month_Subscription:"Sieciau - 2 fis",
  Three_Month_Subscription:"Sieciau - 3 mis",
  Six_Month_Subscription:"Sieciau - 6 mis",
  Twelve_Month_Subscription:"Sieciau - 12 Mis",
  Cheques_Are_Available:"Mae sieciau ar gael i'w hargraffu.",
  Upgrade_Required_Two:"Dewiswch becyn a thapiwch ddwywaith ar y botwm pris i gychwyn eich pryniant. Argraffu sieciau lliw-llawn proffesiynol mewn eiliadau.",
  Month:"Mis",
  Due:"Yn ddyledus:",
  Expires:"Yn dod i ben:",
  Subscription_Active:"Tanysgrifiad Gweithredol",
  Subscription_Inactive:"Tanysgrifiad Anweithredol",
  Purchase_Additional_Cheques:"Prynu sieciau ychwanegol?",
  Printable_Cheque:"Gwiriad Argraffadwy",
  Printable_Cheques:"Gwiriadau Argraffadwy",
  Printable_Cheque_Word:"siec ar gael ar eich cyfrif.",
  Printable_Cheques_Word:"sieciau ar gael ar eich cyfrif.",
  Max_Amount_Message:"Mae'r swm a nodwyd gennych wedi cyrraedd dros yr uchafswm a osodwyd ar gyfer y system hon:",
  Terms_Required_Word:"Rhaid i chi gytuno i'r cytundeb hwn cyn parhau i ddefnyddio sieciau.",
  Subscriptions:"Tanysgrifiadau",
  Product_Upgrades:"Uwchraddiadau",
  Mailed_Out_Cheques:"Gwiriadau Wedi'u Postio",
  Enter_A_Company_Name:"Rhowch enw cwmni.",
  Single_Cheques:"Gwiriadau Sengl",
  Cheque_Golden:"Gwiriad Aur",
  Cheque_Golden_Window:"Dyluniad siec euraidd.",
  Cheque_Green:"Gwiriad Gwyrdd",
  Cheque_Green_Window:"Dyluniad siec gwyrdd.",
  Cheque_Red:"Gwiriad Coch",
  Cheque_Red_Window:"Dyluniad siec coch.",
  Cheque_Yellow:"Gwiriad Melyn",
  Cheque_Yellow_Window:"Dyluniad siec melyn.",
  Cheque_Statue_of_Liberty:"Cerflun o Ryddid",
  Cheque_Statue_of_Liberty_Window:"Dyluniad siec y Statue of Liberty.",
  Cheque_Green_Wave:"Ton Werdd",
  Cheque_Green_Wave_Window:"Dyluniad siec gwyrdd.",
  Cheque_Golden_Weave:"Gwehydd Aur",
  Cheque_Golden_Weave_Window:"Dyluniad siec euraidd cain.",
  Cheque_Green_Sun:"Haul Gwyrdd",
  Cheque_Green_Sun_Window:"Dyluniad siec dwfn a thawel.",
  Cheque_Blue_Checkers:"Gwirwyr Glas",
  Cheque_Blue_Checkers_Window:"Dyluniad siec glas.",
  Cashiers_Check:"Siec yr Ariannwr",
  Cashiers_Check_Window:"Templed arddull Siec yr Ariannwr.",
  Cheque_Aqua_Checkers:"Gwirwyr Dŵr",
  Cheque_Aqua_Checkers_Window:"Dyluniad gwirio Aqua.",
  Cheque_Golden_Checkers:"Gwirwyr Aur",
  Cheque_Golden_Checkers_Window:"Dyluniad siec euraidd.",
  Upgrade_Unavailable:"Uwchraddiadau Ddim ar gael",
  Bank_Code_Protection:"Gwarchod Rhif Banc",
  Bank_Code_Protection_Word:"Amddiffynnwch eich rhifau banc rhag cael eu defnyddio ar yr ap hwn sy'n rhedeg ar ddyfais arall neu ar gyfer defnyddiwr arall. Mae'r weithred hon yn ANGHOFAL. Parhau?",
  Bank_Code_Protection_Blocked_Word:"Mae'r rhifau banc rydych chi'n ceisio eu defnyddio wedi'u cadw ar gyfer defnyddiwr neu ddyfais arall.",
  Bank_Code_Protection_Error_Word:"Mae dilysu rhif wedi methu. Cysylltwch â'r rhyngrwyd a cheisiwch ychwanegu'r cyfrif banc hwn eto.",
  Bank_Code_Protection_Set_Error_Word:"Mae amddiffyniad rhif banc yn ei gwneud yn ofynnol i chi gael eich cysylltu â'r rhyngrwyd. Cysylltwch a cheisiwch eto.",
  Upgrade_Unavailable_Word:"Mae'n ddrwg gennym, rydym yn cael trafferth i'ch dilysu. Nid yw tanysgrifiadau ac uwchraddiadau i Checks ar gael i'w prynu yn eich ardal ar hyn o bryd.",
  PayPal_Payment_Preview:"Rhagolwg Taliad PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Dewiswch PayPal",
  PayPal_Applications:"Ceisiadau PayPal",
  Purchase_With_Apple_Pay:"Prynu gydag Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Cwmnïau",
  Insurance:"Yswiriant",
  New_PayPal:"PayPal newydd",
  Pay_By:"Talu Erbyn",
  Insure:"Yswirio",
  Miles:"Milltiroedd",
  Payment_Method:"Dull talu",
  Select_Policies:"Dewiswch Bolisïau",
  Policies:"Polisïau",
  Policy:"Polisi",
  No_PayPal_Account:"Dim Cyfrif PayPal",
  No_Policies:"Dim Polisïau Yswiriant",
  New_Policy:"Polisi Newydd",
  PayPal_Payment:"Taliad PayPal",
  PayPal_Payments:"Taliadau PayPal",
  No_Payment_Selected:"Dim Taliad wedi'i Ddewis",
  Sending_Payment_Word:"Arhoswch... Mae'r taliad hwn yn cael ei anfon.",
  Sending_Payments_Word:"Arhoswch... Mae taliadau'n cael eu hanfon.",
  No_Payment_Selected_PayPal:"Ni ddewiswyd <a routerLink='/folder/Payments'>taliad PayPal</a> i'w anfon.",
  Payment_Sent:"Anfonwyd Taliad",
  PayPal_Payment_Sent:"Mae'r taliad hwn wedi'i anfon gyda PayPal.",
  Copay:"Copi",
  Dead:"Marw",
  Alive:"Yn fyw",
  Not_Dead:"Ddim yn Farw",
  Unclaimed:"Heb ei hawlio",
  Attempted:"Wedi ceisio",
  Deceased:"Ymadawedig",
  Claimed:"Hawliwyd",
  Unpaid:"Di-dâl",
  Sending_Payment:"Anfon Taliad",
  Sending_Payments:"Anfon Taliadau",
  Send_PayPal_Confirmation:"Ydych chi am anfon y trafodiad hwn gyda PayPal?",
  Send_PayPal_Confirmation_Word:"Pwyswch y botwm gwyrdd i anfon y trafodiad hwn.",
  Save_Payment_As_Unpaid:"Cadw'r taliad hwn fel taliad heb ei dalu?",
  Payment_Pay_Confirmation_PayPal:"Cadw'r taliad hwn fel y'i talwyd?",
  No_Policies_Word:"Ychwanegwch y <a routerLink='/folder/Postage/New'>Polisi Yswiriant</a> nawr.",
  Timesheet_Multiple_Delete_Confirmation:"Ydych chi'n siŵr eich bod am ddileu sawl taflen amser?",
  Select_Multiple_Timesheets_Prompt:"Dim taflenni amser wedi'u dewis. Dewiswch o leiaf un daflen amser.",
  Select_Multiple_Policies_Prompt:"Dim polisïau wedi'u dewis. Dewiswch o leiaf un polisi yswiriant.",
  Policies_Multiple_Delete_Confirmation:"Ydych chi'n siŵr eich bod am ddileu polisïau lluosog?",
  Company:"Cwmni",
  Add_Company:"Ychwanegu Cwmni",
  New_Company:"Ychwanegu Cwmni",
  No_Companies:"Dim Cwmnïau",
  Enable_Company:"Galluogi Cwmni",
  Select_Company:"Dewis Cwmni",
  The_Default_Company:"Label y cwmni rhagosodedig.",
  Manage_Companies:"Rheoli Cwmnïau",
  No_Companies_Word:"Bydd sieciau'n defnyddio cwmni diofyn.<br /> Ychwanegwch y <a routerLink='/folder/Company/New'>Cwmni cyntaf</a>.",
  Default_Company:"Cwmni Diofyn",
  Cheques_Unlimited_Word:"Mae Checks Unlimited yn caniatáu ichi argraffu cymaint o sieciau ag y dymunwch.",
  Cheques_Subscription_Word:"Mae tanysgrifiad Checks yn caniatáu ichi argraffu cymaint o sieciau ag y dymunwch.",
  You_Own_This_Product:"Chi sy'n berchen ar y cynnyrch hwn.",
  Your_Subscription_is_Active:"Mae eich tanysgrifiad yn weithredol.",
  Active_Products:"Cynhyrchion Actifedig",
  Purchase_Confirmation:"Prynu",
  Purchase_Cheques:"Sieciau Prynu",
  Restore_Purchase:"Adfer Pryniannau",
  Erase_Purchase:"Dileu Pryniannau",
  Successfully_Purchased:"Wedi'i Brynu'n Llwyddiannus",
  Enter_Your_Licence_Key:"Rhowch eich allwedd trwydded ar y dudalen hon i actifadu'r cynnyrch hwn.",
  Licence_Key:"Allwedd trwydded",
  Enter_Licence_Key:"Rhowch Allwedd y Drwydded",
  Purchased:"Prynwyd",
  Enable_Feature:"Galluogi Nodwedd",
  Cancel_Subscription:"Canslo Tanysgrifiad",
  Subscription_Removed:"Tynnwyd y Tanysgrifiad",
  Select_Active_Subscription:"Dewiswch danysgrifiad gweithredol er mwyn ei addasu.",
  Remove_Subscription_Word:"Ydych chi'n siŵr eich bod am ganslo'r tanysgrifiad hwn?",
  Delete_Company_Confirmation:"Ydych chi'n siŵr eich bod am ddileu'r cwmni cyfan hwn? RHYBUDD: Byddwch yn colli'r holl wybodaeth sydd wedi'i storio!",
  Delete_Default_Company_Word:"Ni allwch ddileu'r cwmni rhagosodedig. Hoffech chi ailosod y cais a'i adfer i'r cyflwr rhagosodedig? RHYBUDD: Byddwch yn colli'r holl wybodaeth sydd wedi'i storio!",
  Console_Warning_2:"Peidiwch â thrin unrhyw arian cyfred gan ddefnyddio'r rhaglen hon nad yw'n eiddo i chi ar hyn o bryd.",
  Terms_and_Conditions:"Telerau ac Amodau",
  and_the:"a'r",
  for:"canys",
  Please_Read_Word:"Darllenwch a chytunwch i'r",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Ni ellid dod o hyd i rai cyfraddau trosi arian cyfred. Cysylltwch â'r rhyngrwyd.",
  Free:"Rhad ac am ddim",
  DB_Erase_Prompt_2:"Dileu cronfa ddata gyfan y datblygwr yn llwyr? RHYBUDD: Byddwch yn colli'r holl wybodaeth PRYNU a TANYSGRIFIAD!",
  Successfully_Imported:"Wedi'i fewnforio'n llwyddiannus",
  Select_Postage:"Dewiswch Postio",
  No_Postage:"Dim Stampiau Post",
  No_Paid_Postage_Word:"Ychwanegwch y stamp <a routerLink='/folder/Postage/New'>Post Taledig</a> cyntaf .",
  Trial_Complete:'Treial wedi\'i Gwblhau',
  Please_Upgrade:'Uwchraddiwch Checks i barhau i argraffu.',
  Aa:"Aa",
  Color:"Lliw",
  Font:"Ffont",
  Guide:"Tywysydd",
  Guides:"Tywyswyr",
  Image:"Delwedd",
  Zoom:"Chwyddo",
  Logo:"Logo",
  Bank:"Banc",
  MICR:"MICR",
  Total:"Cyfanswm",
  Cancel:"Canslo",
  Confirm:"Cadarnhau",
  Method:"Dull",
  Biometric_Security:"Diogelwch Bio-metrig",
  Please_Login_To_Continue:"Mewngofnodwch i barhau.",
  Complete:"Cyflawn",
  Sign_Up:"Cofrestru",
  Error:"Gwall",
  Biometrics:"Bio-metreg",
  Percent:"Canran",
  Zero_Percent:"0%",
  Top_Margin:"Ymyl Uchaf",
  Bottom_Margin:"Ymyl Gwaelod",
  Left_Margin:"Ymyl Chwith",
  Right_Margin:"Ymyl Dde",
  MICR_Margin:"Ymyl MICR",
  Table_Margin:"Ymyl Tabl",
  Address_Margin:"Ymyl Cyfeiriad",
  Percentage_:"Canran",
  Vacation_Title:"Teitl Gwyliau",
  Use_PIN:"Defnyddiwch PIN",
  Loading__:"Wrthi'n llwytho...",
  Design_Title:"Teitl y Dyluniad",
  Authorize:"Awdurdodi",
  Key:"Allwedd",
  Public_Key:"Allwedd Gyhoeddus",
  Private_Key:"Allwedd Breifat",
  Authenticate:"Dilysu",
  Last_Payroll:"Cyflogau diweddaf",
  Last_Calculation:"Cyfrifiad Diweddaf",
  Authorized:"Awdurdodedig",
  Geo_Authorized:"Geo-Lleoliad: Awdurdodedig",
  Not_Authorized:"Heb ei Awdurdodi",
  Authorization_Complete:"Awdurdodiad wedi'i Gwblhau",
  Geolocation_Authorization:"Awdurdodiad Geo-leoliad",
  Out_of_Bounds:"Allan o Ffiniau",
  Cant_Delete_Default_Design:"Methu dileu dyluniad rhagosodedig.",
  Unauthorized_Out_of_Bounds:"Anawdurdodedig: Allan o Ffiniau",
  Biometric_Authorization:"Awdurdodiad Bio-fetrig",
  Locating_Please_Wait:"Lleoli, os gwelwch yn dda Aros...",
  Test_Biometrics:"Profi Bio-metreg",
  Cheque_Margins:"Gwirio Ymylon",
  Percentage_Full_Error:"Ni ellir gosod y maes canrannol dros 100% y cant.",
  No_Payroll_Text:"Ychwanegu <a routerLink='/folder/Employee/New'>Gweithiwr</a> neu <a routerLink='/folder/Payee/New'>Talu</a> a <a routerLink='/folder/Schedule/New'>Atodlen</a>.",
  Design_Saved:"Cynllun wedi'i Gadw",
  Default_Design_Selected:"Dyluniad diofyn wedi'i ddewis",
  Partial_Import:"Mewnforio Rhannol",
  Partial_Export:"Allforio Rhannol",
  Entire_Import:"Mewnforio Cyfan",
  Entire_Export:"Allforio Cyfan",
  Existing_Password:"Rhowch eich cyfrinair presennol:",
  Existing_PIN:"Rhowch eich cod PIN presennol:",
  Pin_Change_Header:"Cadarnhad PIN",
  Pin_Change_SubHeader:"Rhowch eich hen rif PIN i gadarnhau'r newid.",
  Pass_Change_Header:"Cadarnhad Cyfrinair",
  Pass_Change_SubHeader:"Rhowch eich hen gyfrinair i gadarnhau'r newid.",
  PIN_Enter_Message:"Rhowch eich PIN i gadarnhau.",
  Password_Enter_Message:"Rhowch eich cyfrinair i gadarnhau.",
  Pin_Updated_Success:"Wedi diweddaru PIN yn llwyddiannus!",
  Pin_Updated_Fail:"Nid oedd modd diweddaru PIN.",
  Pass_Updated_Success:"Cyfrinair wedi'i ddiweddaru'n llwyddiannus.",
  Pass_Updated_Fail:"Nid oedd modd diweddaru'r cyfrinair.",
  Preview_Payment:"Taliad Rhagolwg",
  Preview_Payroll:"Rhagolwg o'r Gyflogres",
  No_Payments_Created:"Ni chanfuwyd bod unrhyw daliadau wedi'u creu.",
  Payment_Preview:"Rhagolwg Taliad",
  Enable_Payee:"Galluogi Talai",
  Rendered:"Wedi'i rendro",
  No_Email:"Dim E-bost",
  Setup_Cheques:"Gwiriadau Gosod",
  name:"Enw",
  address:"Cyfeiriad",
  address_2:"Llinell Cyfeiriad 2",
  city:"Dinas",
  province:"Talaith",
  postal_code:"Côd Post/ZIP",
  country:"Gwlad",
  username:"Enw defnyddiwr",
  full_name:"Enw llawn",
  birthday:"Penblwydd",
  email:"Ebost",
  phone:"Ffon",
  employees:"Gweithwyr",
  addresses:"Cyfeiriadau",
  payment_amount_limit:"Terfyn Swm Talu",
  total_limit:"Terfyn Cyfanswm",
  payees:"Taledig",
  description:"Disgrifiad",
  address_line_one:"Llinell Cyfeiriad Un",
  address_line_two:"Cyfeiriad Llinell Dau",
  image:"Delwedd",
  account_holder:"Deiliad y Cyfrif",
  cheque_starting_id:"Gwiriwch ID Cychwyn",
  transit_number:"Rhif Tramwy",
  institution_number:"Rhif Sefydliad",
  designation_number:"Rhif Dynodiad",
  account_number:"Rhif cyfrif",
  currency:"Arian cyfred",
  signature:"Llofnod",
  payment_payroll_limit:"Terfyn Talu",
  total_limi:"Cyfanswm terfyn",
  date:"Dyddiad",
  printed_memo:"Memo Argraffedig",
  banks:"Banciau",
  signature_image:"Delwedd Llofnod",
  address_name:"Enw Cyfeiriad",
  notes:"Nodiadau",
  design:"Dylunio",
  title:"Teitl",
  frequency:"Amlder",
  fax:"Ffacs",
  salaries:"Cyflogau",
  salary_ids:"IDau cyflog",
  start_time:"Amser Dechrau",
  end_time:"Amser Gorffen",
  paid:"Talwyd",
  overtime_percentage:"Canran Taledig",
  overtime_amount:"Swm Sefydlog a Dalwyd",
  first_name:"Enw cyntaf",
  last_name:"Enw olaf",
  moderation:"Cymedroldeb",
  create:"Creu",
  edit:"Golygu",
  destroy:"Dinistrio",
  day_start_time:"amser_cychwyn_dydd",
  day_end_time:"Diwrnod_diwedd_amser",
  fullname:"Enw",
  time:"Amser",
  auto_send:"Anfon yn Awtomatig",
  time_method:"Dull Amser",
  schedules:"Atodlenni",
  indefinite_payroll_enabled:"Galluogi Amhenodol",
  amount:"Swm",
  repeat:"Ailadrodd",
  always_enabled:"Wedi'i Galluogi bob amser",
  start_date:"Dyddiad cychwyn",
  end_date:"Dyddiad Gorffen",
  Cheque_Total:"Gwirio Cyfanswm",
  Total_Amount:"Cyfanswm:",
  Amounts:"Swm:",
  Images:"Delweddau",
  Files:"Ffeiliau",
  Previewing:"Rhagolwg:",
  Insert:"mewnosod",
  Preview_Import:"Mewnforio Rhagolwg",
  Entry:"Mynediad",
  Entries:"Cofnodion",
  No_Entries:"Dim Cofnodion",
  Import_Type:"Math Mewnforio",
  Select_Details:"Dewiswch Manylion",
  Select_Payee:"Dewiswch Talai",
  Enable_Holidays:"Galluogi Gwyliau",
  Disable_Holidays:"Gwyliau Analluogi",
  Wire_Transfer:"Trosglwyddo Gwifren",
  New_Export:"Allforio Newydd",
  Export_Data:"Data Allforio",
  Export_Data_Word:"Dewiswch y math o ffeil i'w hallforio a'i lawrlwytho.",
  Export_File:"Ffeil Allforio",
  Mode:"Modd",
  Times:"Amseroedd",
  Widgets:"Teclynnau",
  Slider:"llithrydd",
  Set_Details:"Manylion Set",
  Select_Type:"Dewiswch Math",
  Display_Slider:"Arddangos Slider",
  Dashboard_Slider:"Llithrydd Dangosfwrdd",
  Dashboard_Mode:"Modd Dangosfwrdd",
  Show_Intro:"Dangos Intro",
  Show_Payrolls:"Dangos Cyflogau",
  Show_Holidays:"Gwyliau Sioe",
  Show_Invoices:"Dangos Anfonebau",
  Show_Cheques:"Dangos Gwiriadau",
  Enabled_Widgets:"Widgets Galluogi",
  Disabled_Widgets:"Widgets Anabl",
  Colors:"Lliwiau",
  Barcodes:"Codau bar",
  View_Timers:"Gweld Amseryddion",
  Gradients:"Graddiant",
  No_Info:"Dim Gwybodaeth",
  Disable:"Analluogi",
  Show_Layer:"Dangos Haenau",
  Hide_Layer:"Cuddio Haenau",
  Text_Layer:"Haenau Testun",
  Secondly:"Yn ail",
  Minutely:"Munud",
  nine_am:"9:00 AM",
  five_pm:"5:00 PM",
  Enable_Address:"Galluogi Cyfeiriad",
  Invalid_File_Type:"Mae'n ddrwg gennym, dewiswyd math ffeil annilys. Math o ffeil â chymorth:",
  Error_Updating_Entry:"Mae'n ddrwg gennym, roedd gwall wrth ddiweddaru'r cofnod hwn.",
  Schedule_Timing_Alert:"Gwall: Mae amser cychwyn yr amserlen wedi'i osod i werth ar ôl yr amser gorffen.",
  Select_Multiple_Payments_Prompt:"Dim taliadau wedi'u dewis. Dewiswch o leiaf un taliad.",
  Select_Multiple_Cheques_Prompt:"Dim sieciau wedi'u dewis. Dewiswch o leiaf un siec.",
  Select_Multiple_Items_Prompt:"Dim eitemau wedi'u dewis. Dewiswch o leiaf un eitem.",
  Paymemt_Multiple_Delete_Confirmation:"A ydych yn siŵr eich bod am ddileu taliadau lluosog?",
  Cheque_Multiple_Delete_Confirmation:"Ydych chi'n siŵr eich bod am ddileu sieciau lluosog?",
  Payee_Multiple_Delete_Confirmation:"A ydych yn siŵr eich bod am ddileu sawl taledig?",
  Employee_Multiple_Delete_Confirmation:"A ydych yn siŵr eich bod am ddileu gweithwyr lluosog?",
  MICR_Warning:"Nodyn: Efallai na fydd rhai argraffwyr a dyfeisiau yn arddangos y ffont MICR yn gywir.",
  Automatically_Send:"Anfon yn Awtomatig",
  Type:"Math",
  Payment_Type:"Math o Daliad",
  Auto_Send:"Anfon Auto",
  Automatically_Process:"Prosesu'n Awtomatig",
  Auto_Process:"Proses Auto",
  Image_Based:"Yn seiliedig ar ddelwedd",
  Font_Based:"Yn seiliedig ar ffontiau",
  Rerender:"Ail-rendro",
  Rendering:"Rendro",
  Render:"Ffeiliau",
  Top:"Brig",
  Middle:"Canol",
  Bottom:"Gwaelod",
  Top_Left:"Chwith Uchaf",
  Top_Center:"Canolfan Uchaf",
  Top_Right:"Dde Uchaf",
  Middle_Left:"Chwith Canol",
  Middle_Center:"Canolfan Ganol",
  Middle_Right:"Dde Ganol",
  Bottom_Left:"Chwith gwaelod",
  Bottom_Center:"Canolfan Gwaelod",
  Bottom_Right:"Dde gwaelod",
  Numbers:"Rhifau",
  Verified:"Wedi'i wirio",
  Paper_Size:"Maint Papur",
  New_Device:"Dyfais Newydd",
  Add_Device:"Ychwanegu Dyfais",
  Remove_Device:"Dileu Dyfais",
  Delete_Device:"Dileu Dyfais",
  Block_Device:"Dyfais Bloc",
  Block:"Bloc",
  Unblock:"Dadrwystro",
  Table:"Bwrdd",
  Scan_Login_Code:"Sganiwch y Cod Mewngofnodi",
  Login_Code:"Cod Mewngofnodi",
  Scan_Code:"Cod Sganio",
  Scan_QR_Code:"Sganiwch y Cod QR",
  Select_All:"Dewiswch Pawb",
  Deselect_All:"Dad-ddewis Pawb",
  Increase:"Cynyddu",
  Decrease:"Gostyngiad",
  Bold:"Beiddgar",
  Text:"Testun",
  Style:"Arddull",
  Italic:"Italaidd",
  QR_Code:"Cod QR",
  Barcode:"Cod bar",
  Browse_Images:"Pori Delweddau",
  Browse_Files:"Pori Ffeiliau",
  Background_Image:"Delwedd Gefndir",
  Logo_Image:"Delwedd Logo",
  Header_Image:"Delwedd Pennawd",
  Bank_Image:"Delwedd Banc",
  Cut_Lines:"Llinellau Torri",
  Background:"Cefndir",
  License:"Trwydded",
  One_License:"1 trwydded:",
  Licensed:"Trwyddedig i:",
  Not_Licensed:"Heb Drwydded",
  Enter_Serial:"Rhowch Gyfres",
  Serial_Key:"Allwedd Gyfresol",
  Serial:"Cyfresol",
  Product_Key:"Allwedd Cynnyrch",
  Check_Product_Key:"Gwiriwch Allwedd Cynnyrch",
  Add_Product_Key:"Ychwanegu Allwedd Cynnyrch",
  Verifying_Purchase:"Wrthi'n Gwirio Pryniant...",
  Print_Envelope:"Amlen Argraffu",
  Envelope:"Amlen",
  Thank_You:"Diolch!",
  Scale:"Graddfa",
  Print_Scale:"Graddfa Argraffu",
  Borders:"Ffiniau",
  VOID:"GWAG",
  Void_Cheque:"Gwiriad Gwag",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"TÂL I'R GORCHYMYN O:",
  NO_DOLLARS:"DIM DOLERAU ",
  ONE_DOLLAR:"UN DOLAR",
  DOLLARS:" DOLWYR",
  AND:" AC ",
  CENTS:" CANRIFOEDD.",
  NO_:"RHIF ",
  ONE_:"UN ",
  AND_NO_:"A RHIF ",
  _AND_ONE_:"AC UN ",
  DOLLARS_AND_ONE_CENT:" AC UN CENT.",
  AND_NO_CENTS:" A ZERO CANRIFOEDD.",
  CHEQUE_PRINT_DATE:"DYDDIAD:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"AS",
  Initial_Tasks:"Tasgau Cychwynnol",
  Inital_Setup:"Gosodiad Cychwynnol",
  Welcome_To:"Croeso i ",
  Welcome:"Croeso",
  Swipe:"Swipe",
  Intro_Setup:"Gosod Intro",
  Introduction:"Cyflwyniad",
  CHEQUE_PRINT_CREDIT:"Wedi'i Bweru gan Sieciau",
  Title_Intro_Word:"Croeso i Sieciau",
  Title_Intro:"Cyflwyniad sieciau",
  Title_Setup:"Gosod sieciau",
  PayPal_Default_Email_Message:"Rydych chi wedi derbyn trosglwyddiad PayPal newydd. [Wedi'i bweru gan sieciau]",
  Cheques_App_Export:"Wedi'i allforio gan sieciau",
  Post_Intro_Thanks:"Diolch am ddewis Sieciau. Mae'r broses gosod bellach wedi'i chwblhau.",
  Post_Intro_Word:"Dechreuwch drwy argraffu eich siec gyntaf, ychwanegu taliad yn y dyfodol, neu ychwanegu cyflogai at y gyflogres.",
  Upgrade_Required:"Mae sieciau yn ei gwneud yn ofynnol i chi fod yn berchen ar fersiwn mwy premiwm o'r feddalwedd er mwyn cuddio'r neges hon a datgloi nodweddion ychwanegol.",
  Upgrade_Title:"Sieciau",
  Mailout_Prompt:"Gallwch hefyd ddewis cael Checks i bostio eich sieciau cyflogres i chi.",
  Mailed_Cheque:"Siec wedi'i bostio: " ,
  Mailed_Cheque_Color:"Siec wedi'i bostio (Lliw): " ,
  Terms_Purchase:"Mae pob pryniant electronig gyda sieciau yn ad-daladwy am hyd at 30 diwrnod o'r dyddiad prynu. Darllenwch a chytunwch i'r <a href='#'>Telerau ac Amodau</a> cyn parhau.",
  Dashboard_Setup:"Gosod yr Argraffydd Cynradd",
  Dashboard_Add:"Ychwanegu'r Prif Gyfrif Banc",
  Dashboard_Customize:"Dewiswch Templed Gwirio",
  Dashboard_Job_Salary:"Creu Eich Swydd ac Ychwanegu Eich Cyflog",
  Dashboard_Employees:"Tracio Gweithwyr a Chyflogeion",
  Dashboard_Print:"Argraffu a Post Eich Gwiriadau Talu",
  Dashboard_Payroll:"Awtomeiddio Eich Argraffu Cyflogres",
  Dashboard_PayPal:"Gosod Cyflogres PayPal / Talu Allan",
  Begin_Setup:"Dechrau Gosod",
  Get_Started:"Dechrau",
  Purchase:"Prynu",
  Lockdown:"Cyfyngiadau symud",
  Unlock:"Datgloi",
  Detailed:"Manwl",
  Log_In:"Mewngofnodi",
  Login:"Mewngofnodi",
  Launch:"Lansio",
  Register:"Cofrestru",
  Finish:"Gorffen",
  List:"Rhestr",
  Weekends:"Penwythnosau",
  Weekly:"Wythnosol",
  PayPal_Default_Subject:"Taliad Newydd",
  Payment_Message:"Neges Taliad",
  PayPal_Default_Payment_Note:"Diolch",
  Setup_Your_Cheqing_Account:"Cyfrif Gwirio",
  Add_Your_Primary_Cheqing_Account:"Ychwanegwch eich prif gyfrif siec.",
  Welcome_Word:"Symleiddio ac awtomeiddio rheoli cyflogres ac adnoddau dynol.",
  Get_Started_Quickly:"Atebwch ychydig o gwestiynau syml a fydd yn ein helpu i ddechrau...",
  Done_Intro_Word:"Gosod Cwblhau",
  PIN_Protected:"Gwarchodir Cyfrinair a PIN",
  Enter_New_PIN:"Rhowch god PIN newydd:",
  Enter_PIN:"Rhowch y cod PIN:",
  Invalid_PIN:"Rhoddwyd PIN annilys.",
  Account_Identifier:"Dynodwr Cyfrif",
  New_Account_Identifier:"Dynodwr Cyfrif Newydd",
  attempt:"ymgais",
  attempts:"ymdrechion",
  remaining:"yn weddill",
  Language:"Iaith",
  languages:"Ieithoedd",
  select_languages:"Dewis Iaith",
  Deposit:"Blaendal",
  Hire_One_Now:"Llogwch Un Nawr",
  App_Locked:"Mae'r cais wedi'i gloi.",
  Skip_:"Neidio",
  Skip_to_Dashboard:"Neidio i'r Dangosfwrdd",
  Dashboard:"Dangosfwrdd",
  Import:"Mewnforio",
  Admin:"Gweinyddwyr",
  New_Admin:"Gweinyddwr Newydd",
  Settings:"Gosodiadau",
  Color_Picker:"Dewiswr Lliw",
  Font_Picker:"Dewiswr Ffont",
  Logout:"Allgofnodi",
  Close:"Yn agos",
  Close_menu:"Yn agos",
  Excel:"Ffeil Excel",
  Csv:"Ffeil CSV",
  Sql:"Ffeil SQL",
  Json:"Ffeil JSON",
  Url:"Mewnforio trwy URL",
  Back:"Yn ôl",
  Timers:"Amserwyr",
  Cheque:"Gwirio",
  Print:"Argraffu",
  Designs:"Dyluniadau",
  Pause_Printing:"Seibiant Argraffu",
  Resume_Printing:"Ail-ddechrau Argraffu",
  Printing_Paused:"Argraffu Wedi'i Seibio",
  PrintingUnavailable:"Mae'n ddrwg gennym! Nid yw argraffu ar gael ar y system hon.",
  Prints_Paused:"Argraffiadau wedi'u Seibio",
  Administration:"Gweinyddiaeth",
  Loading:"Llwytho",
  Unnamed:"Dienw",
  error:"Mae'n ddrwg gennym, nid oedd modd agor y siec hon i'w gweld.",
  No_Cheques_To_Print:"Dim Sieciau i Argraffu",
  No_Cheques_To_Print_Word:"Creu <a routerLink='/folder/Cheque/New'>Siec Newydd</a>.",
  New_Cheque:"Gwiriad Newydd",
  Start_One_Now:"Dechrau Un Nawr",
  Edit_Cheque:"Golygu Gwiriad",
  Select_Cheques:"Dewis Gwiriadau",
  Select_Employee:"Dewis Gweithiwr",
  Default_Printer:"Argraffydd Rhagosodedig",
  Reassign:"Ailbennu",
  Configure:"Ffurfweddu",
  Template:"Templed",
  Designer:"Dylunydd",
  Edit_Designs:"Golygu Dyluniadau",
  New_Design:"Dyluniad Newydd",
  Next:"Nesaf",
  Save:"Cadw",
  Name:"Enw",
  Mail:"Post",
  Amount:"Swm",
  Date:"Dyddiad",
  PayPal:"PayPal",
  Payouts:"Taliadau",
  PayPal_Label:"Label PayPal",
  Email_Username:"E-bost / Enw Defnyddiwr",
  Client_ID:"ID Cleient",
  Sandbox_Email:"E-bost blwch tywod",
  PayPal_Email:"E-bost PayPal",
  PayPal_Username:"Enw Defnyddiwr API",
  PayPal_Payouts:"Taliadau PayPal",
  Select_PayPal_Key:"Dewis Allwedd PayPal",
  Secret:"Cyfrinachol",
  API_Secret:"Cyfrinach API",
  PayPal_API_Keys:"Allweddi PayPal",
  New_PayPal_Key:"Allwedd PayPal Newydd",
  Email_Subject:"Pwnc E-bost",
  Email_Message:"Neges E-bost",
  Payout_Options:"Dewisiadau Talu",
  Payment_Note:"Nodyn Taliad",
  Enable_Employee:"Galluogi Gweithiwr",
  Enable_Production_Mode:"Galluogi Modd Cynhyrchu",
  Sandbox_Username:"Enw Defnyddiwr Blwch Tywod",
  Sandbox_Signature:"Llofnod blwch tywod",
  Sandbox_Password:"Cyfrinair blwch tywod",
  Production_Username:"Enw Defnyddiwr Cynhyrchu",
  Production_Signature:"Llofnod Cynhyrchu",
  Production_Password:"Cyfrinair Cynhyrchu",
  Production_Email:"E-bost Cynhyrchu",
  API_Client_ID:"ID Cleient API",
  API_Signature:"Llofnod API",
  API_Password:"Cyfrinair API",
  API_Username:"Enw Defnyddiwr API",
  Secret_Key:"Allwedd Ddirgel",
  Sandbox:"Blwch tywod",
  Production:"Cynhyrchu",
  Sandbox_Keys:"Allweddi Blwch Tywod",
  Production_Keys:"Allweddi Cynhyrchu",
  API_Title:"Teitl API",
  Production_Mode:"Modd Cynhyrchu",
  Account_Label:"Label Cyfrif",
  No_PayPal_Setup:"Dim Allwedd PayPal",
  Enable_PayPal_Account:"Galluogi Cyfrif PayPal",
  No_PayPal_Word:"Ychwanegu eich <a routerLink='/folder/Invoice/New'>Allwedd API PayPal</a>.",
  Printed_Memo:"Memo Argraffwyd",
  Employee:"Gweithiwr",
  View_Employee:"Gweld Gweithiwr",
  Mailing_Address:"Cyfeiriad postio",
  Company_Address:"Cyfeiriad Cwmni",
  Select_Company_Address:"Dewis Cyfeiriad Cwmni",
  Address:"Cyfeiriad",
  Any_Day:"Unrhyw ddiwrnod",
  Address_Name:"Enw Cyfeiriad",
  Unit:"Uned",
  Account:"Cyfrif",
  Bank_Account:"Cyfrif banc",
  Account_Limits:"Galluogi Cyfyngiadau Cyfrif",
  Payroll:"Cyflogres",
  New_Payroll:"Cyflogres Newydd",
  No_Payroll:"Dim Cyflogres ar Ddod",
  Upcoming_Holiday:"Gwyliau i ddod:",
  Invoice_Due:"Anfoneb yn ddyledus:",
  New_Invoice:"Anfoneb Newydd",
  No_Invoices:"Dim Anfonebau",
  No_Invoices_Word:"Creu'r <a routerLink='/folder/Invoice/New'>Anfoneb</a> cyntaf.",
  Cheque_Due:"Siec yn ddyledus:",
  Payrolls:"Cyflogau",
  Sandbox_Mode:"Modd blwch tywod",
  Hire:"Llogi",
  Pay:"Talu",
  New:"Newydd",
  Add:"Ychwanegu",
  Make:"Creu",
  Time:"Amser",
  Write:"Ysgrifennu",
  Holiday:"gwyliau",
  Holidays:"Gwyliau",
  Next_Holiday:"Gwyl nesaf:",
  All_Done:"Popeth Wedi'i Wneud!",
  Employees:"Cyflogeion",
  Payees:"Taledigion",
  Job:"Swydd",
  Jobs:"Swyddi",
  Invoice:"Anfoneb",
  Invoices:"Anfonebau",
  Vacations:"Gwyliau",
  Cheques:"Gwiriadau",
  Brand_Cheques:"Brand",
  Payment:"Talu",
  Enable_Payment:"Galluogi Taliad",
  Payments:"Taliadau",
  Schedule:"Atodlen",
  Schedules:"Atodlenni",
  Timesheet:"Taflen Amser",
  Timesheets:"Taflenni Amser",
  Salary:"Cyflog",
  New_Address:"Cyfeiriad Newydd",
  Address_2:"Cyfeiriad (llinell 2)",
  _City:"Dinas",
  _State:"Cyflwr/Prov",
  City:"Dinas / trefgordd",
  State:"Talaith / Talaith",
  Postal:"Cod post / ZIP",
  ZIP:"Post / ZIP",
  Country:"Gwlad",
  Addresses:"Cyfeiriadau",
  Required_Options:"Dewisiadau Gofynnol",
  Optional_Options:"Dewisiadau Dewisol",
  Additional_Options:"Dewisiadau Ychwanegol",
  Required:"Angenrheidiol",
  Optional:"Dewisol",
  Additional:"Ychwanegol",
  No_Addresses:"Na Cyfeiriadau",
  New_Address_Word:"Ychwanegu'r <a routerLink='/folder/Address/New'>Cyfeiriad</a> cyntaf.",
  Select_Address:"Dewis Cyfeiriad",
  No_Address:"Na Cyfeiriadau",
  Print_Cheque:"Gwiriad Argraffu",
  Print_Cheque_Now:"Print Gwirio Nawr",
  Description:"Disgrifiad",
  Pay_To_The_Order_Of:"Tâl i'r Gorchymyn o:",
  Select_Date_Range:"Dewis Ystod Dyddiad",
  Select_Starting_Date:"Dewis Dyddiad Cychwyn",
  Select_Ending_Date:"Dewis Dyddiad Gorffen",
  Select_Date:"Dewis Dyddiad",
  Cheque_Date:"Dyddiad Gwirio",
  Cheque_Memo:"Gwirio Memo",
  Blank_Cheque:"Gwag Gwiriad",
  Blank:"Blank",
  No_Cheques:"Dim Gwiriadau",
  No_Cheques_Word:"Argraffwch eich <a routerLink='/folder/Cheque/New'>Gwiriad</a> cyntaf.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"Gweld Delwedd",
  View:"Gweld",
  Modify:"Addasu",
  Delete:"Dileu",
  Cheque_Paid:"Talwyd",
  New_Deduction:"Didyniad Newydd",
  Title:"Teitl",
  Frequency:"Amlder",
  Hourly:"Awr",
  Daily:"Dyddiol",
  Weekdays:"Dyddiau'r wythnos",
  BiWeekly:"2 Wythnosau",
  TriWeekly:"3 wythnos",
  Monthly:"Yn fisol",
  MiMonthly:"2 Fis",
  Quarterly:"Chwarterol",
  Yearly:"Blynyddol",
  Every_Week:"Pob wythnos",
  Every_Payroll:"Pob cyflogres",
  Every_Month:"Pob mis",
  Annually:"Yn flynyddol",
  Always_Scheduled:"Bob amser a drefnwyd",
  Start_Date:"Dyddiad cychwyn",
  End_Date:"Dyddiad Gorffen",
  Start_Time:"Amser cychwyn",
  End_Time:"Amser Gorffen",
  Deduction_Label:"Label Didyniad",
  Notes:"Nodiadau",
  Options:"Dewisiadau",
  Enable:"Galluogi",
  Select_Deductions:"Dewis Didyniadau",
  Deductions:"Didyniadau",
  No_Deductions:"Dim Didyniadau",
  No_Deductions_Word:"Creu eich <a routerLink='/folder/Deduction/New'>Didyniad</a> cyntaf.",
  New_Employee:"Gweithiwr Newydd",
  No_Title:"Dim Teitl",
  _Name:"Enw",
  About_Yourself:"Amdanoch chi eich hun",
  Full_Name:"Enw llawn",
  Birthday:"Pen-blwydd",
  Email:"E-bost",
  SMS:"SMS",
  Phone:"Ffôn",
  Test:"Prawf",
  Call:"Galw",
  Fax:"Ffacs",
  Printed_Note:"Nodyn Argraffwyd",
  Position:"Swydd",
  Job_Position:"Swyddi Swydd",
  Select_a_Job:"Dewis Swydd",
  Select_a_Salary:"Dewiswch Gyflog",
  Add_a_Deduction:"Ychwanegu Didyniad",
  Taxes:"Trethi",
  Add_Taxes:"Ychwanegu Trethi",
  Date_of_Birth:"Dyddiad Geni",
  Email_Address:"Cyfeiriad ebost",
  Phone_Number:"Rhif ffôn",
  Phone_Call:"Galwad ffon",
  Enable_on_Payroll:"Galluogi ar y Gyflogres",
  Select_Employees:"Dewis Gweithwyr",
  No_Employees:"Dim Gweithwyr",
  No_Employees_Word:"Ychwanegwch eich <a routerLink='/folder/Employee/New'>Gweithiwr</a> cyntaf.",
  No_Name:"Dim enw",
  Unemployeed:"Di-waith",
  Employeed:"Cyflogedig",
  Paid:"Talwyd",
  Enabled:"Galluogi",
  Disabled:"anabl",
  Fire:"Tân",
  Not_Available:"Dim ar gael",
  Not_Available_Word:"Argraffwch eich <a routerLink='/folder/Invoice/New'>Anfoneb</a> cyntaf a chael eich talu.",
  Select_Invoices:"Dewis_Anfonebau",
  Print_Invoice_Word:"Argraffwch eich <a routerLink='/folder/Invoice/New'>Anfoneb</a> cyntaf a chael eich talu.",
  Invoice_Title:"Teitl yr Anfoneb",
  Invoice_Date:"Dyddiad yr Anfoneb",
  Due_Date:"Dyddiad dyledus",
  New_Job:"Swydd newydd",
  Details:"Manylion",
  Customize:"Addasu",
  Customize_Dashboard:"Addasu Dangosfwrdd",
  Components:"Cydrannau",
  No_Components:"Dim Cydrannau",
  Main_Components:"Prif gydrannau",
  Smaller_Components:"Cydrannau Llai",
  Error_Loading_Page:"Gwall wrth lwytho'r dudalen hon.",
  Bank_Details:"Manylion banc",
  About_Your_Job:"Am Eich Swydd",
  Your_Schedule:"Eich amserlen",
  Job_Title:"Teitl swydd",
  Job_Description:"Disgrifiad Swydd",
  Job_Details:"Manylion y Swydd",
  Enable_Job:"Galluogi Swydd",
  Pay_Period:"Cyfnod Tâl",
  Perpetually_Schedule:"Trefnu'n Barhaol",
  Select_Jobs:"Dewis Swyddi",
  No_Jobs:"Dim Swyddi",
  Add_Jobs:"Ychwanegu Swyddi",
  No_Jobs_Word:"Ychwanegwch y <a routerLink='/folder/Job/New'>Swydd</a> i'r rhestr.",
  Count_Employees:"job.employee_count+' Gweithwyr'",
  Zero_Employees:"0 Gweithiwr",
  New_Leave:"Gadael Newydd",
  Leave_Name:"Gadael Enw",
  Paid_Leave:"Aibiant â Thâl",
  Leave_Pay:"Gadael Tâl",
  Indefinite_Leave:"Gadael Amhenodol",
  Indefinite_Payroll:"Cyflogres Amhenodol",
  Leave:"Gadael",
  Add_Schedules:"Ychwanegu Atodlenni",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Galluogi Absenoldeb",
  Select_Leaves:"Dewis Dail",
  No_Leaves:"Dim Dail Absenoldeb",
  Leave_Of_Absence:"Caniatâd Absenoldeb",
  Leaves_Of_Absence:"Dail Absenoldeb",
  New_Leave_of_Absense:"Caniatâd Absenoldeb Newydd",
  No_Leaves_Word:"Ychwanegu'r <a routerLink='/folder/Leave/New'>Gadael Absenoldeb</a> cyntaf.",
  Not_Enabled:"Heb Alluogi",
  Absences:"Absenoldebau",
  Payee:"Talai",
  New_Payee:"Talai Newydd",
  Payee_Identifier:"Dynodwr Talai",
  Payee_Name:"Enw'r Talai",
  Payee_Title:"Teitl Talai",
  Payment_Memo:"Memo Taliad",
  Select_Payees:"Dewis y Talai",
  No_Payees:"Dim Talu",
  Add_Payee_Note:"Ychwanegu'r <a routerLink='/folder/Payee/New'>Payee</a> cyntaf.",
  New_Payees:"Taledigion Newydd",
  About_The_Payment_Schedule:"Amserlen Dalu",
  Your_Payroll_Schedule:"Cyflogres Awtomatig",
  New_Payment:"Taliad Newydd",
  Identifier:"Dynodwr",
  Select:"Dewis",
  Memo:"Memo",
  Payment_Date:"Dyddiad talu",
  Mark_as_Paid:"Marcio fel Taledig",
  Select_Payments:"Dewis Taliadau",
  No_Payments:"Dim Taliadau",
  No_Payments_Word:"Creu'r <a routerLink='/folder/Payment/New'>Taliad</a> cyntaf.",
  Create_Payroll:"Creu Cyflogres",
  Properties:"Priodweddau",
  Payroll_Title:"Teitl y Gyflogres",
  Payroll_Notes:"Nodiadau Cyflogres",
  Payroll_Setup:"Gosod cyflogres",
  Tabulate_Payments:"Taliadau Tabl",
  Tabulate:"Tabl",
  By:"Gan:",
  Payments_By:"Taliadau Erbyn",
  Timesheets_And_Schedules:"Taflenni Amser ac Amserlenni",
  Both:"Y ddau",
  Items:"Eitemau",
  Add_Payees:"Ychwanegu Tâl",
  Add_Account:"Ychwanegu Cyfrif",
  Enable_Account:"Galluogi Cyfrif",
  Enable_Payroll:"Galluogi'r Gyflogres",
  Print_Payroll:"Argraffu'r Gyflogres",
  No_Payrolls:"Dim Cyflogres",
  No_Payroll_Word:"Creu eich <a routerLink='/folder/Payroll/New'>Cyflogres</a> cyntaf.",
  View_more:"VIEW_MORE",
  Less:"LLAI",
  Add_Payroll:"Ychwanegu'r Gyflogres",
  Select_Payroll:"Dewis y Gyflogres",
  About_Your_Salary:"Am Eich Cyflog",
  Add_Salaries:"Ychwanegu Cyflogau",
  Add_Salary:"Ychwanegu Cyflog",
  Salaries:"Cyflogau",
  No_Salaries:"Dim Cyflog",
  No_Salaries_Word:"Ychwanegu'r <a routerLink='/folder/Salary/New'>Cyflog</a> cyntaf.",
  Select_Salaries:"Dewis Cyflogau",
  New_Salary:"Cyflog Newydd",
  Salary_Name:"Dynodwr Cyflog",
  Enable_Salary:"Galluogi Cyflog",
  Salary_Amount:"Swm Cyflog",
  New_Schedule:"Atodlen Newydd",
  Schedule_Title:"Teitl yr Atodlen",
  Add_Address:"Ychwanegu Cyfeiriad",
  Repeat:"Ailadrodd",
  Design:"Dylunio",
  Edit_Design:"Golygu dyluniad",
  Edit_this_Design:"Golygu'r Dyluniad hwn",
  Repeat_Payment:"Taliad Ailadrodd",
  Enable_Schedule:"Galluogi Atodlen",
  Never:"Byth",
  Select_Schedule:"Dewis Atodlenni",
  No_Schedules:"Dim Atodlenni",
  No_Schedules_Word:"Creu'r <a routerLink='/folder/Schedule/New'>Atodlen</a> cyntaf.",
  New_Administrator:"Gweinyddwr Newydd",
  Username:"Enw defnyddiwr",
  First_Name:"Enw cyntaf",
  Last_Name:"Enw olaf",
  Add_an_Address:"Ychwanegu Cyfeiriad",
  Payment_Limit:"Terfyn Talu Fesulol",
  Total_Limit:"Terfyn Cyfanswm",
  Select_Accounts:"Dewis Cyfrifon",
  No_Administrators:"Dim Gweinyddwyr",
  No_Administrators_Word:"Creu'r <a routerLink='/folder/Administrator/New'>Cyfrif Gweinyddol</a> cyntaf.",
  New_Administrators_Word:"Ychwanegu'r <a routerLink='/folder/Administrator/New'>Gweinyddwr</a> cyntaf",
  Cloud:"Cwmwl",
  Backup:"Wrth gefn",
  Enable_iCloud:"Galluogi iCloud",
  Enable_Google_Drive:"Galluogi Google Drive",
  Enable_Microsoft_OneDrive:"Galluogi Microsoft OneDrive",
  Automatically_Backup:"Gwneud copi wrth gefn yn awtomatig",
  FTP_Settings:"Gosodiadau FTP",
  URL_File_Prompt:"Nodwch y lleoliad ar gyfer ffeil .xls / .xlsx / .json i fewnforio:",
  URL_SQL_Prompt:"Nodwch leoliad y ffeil SQLite i'w mewnforio:",
  FTP_Backup:"Wrth gefn FTP",
  FTP_Import:"Mewnforio FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Gwesteiwr",
  Port:"Porthladd",
  Path:"Llwybr",
  Data_Path:"Llwybr Data",
  Enable_FTP_Account:"Galluogi Cyfrif FTP",
  HostnameIP:"Enw gwesteiwr",
  Password:"Cyfrinair",
  Connection_Port:"Porth Cysylltiad",
  Enable_MySQL_Database:"Galluogi Cronfa Ddata MySQL",
  Log:"Log",
  Reset:"Ail gychwyn",
  Erase:"Dileu",
  Export:"Allforio",
  Database:"Cronfa ddata",
  Upload_CSV:"Lanlwytho CSV",
  Download_CSV:"Lawrlwytho CSV",
  SQL_Database:"URL SQL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Wrth gefn MySQL",
  Internal_Notes:"Nodiadau Mewnol",
  Root_Path:"Llwybr gwraidd",
  Select_Backup:"Dewis copi wrth gefn",
  Add_New_Backup:"Ychwanegu copi wrth gefn newydd",
  First_Backup:"Gosod y copi wrth gefn cyntaf...",
  Backups:"Wrth gefn",
  Add_Backup:"Ychwanegu copi wrth gefn",
  No_Backups:"Does dim copïau wrth gefn i'w canfod.",
  Select_Backup_Type:"Dewiswch y math o gopi wrth gefn yr hoffech ei osod...",
  Backup_Type:"Math wrth gefn",
  FTP_Drive:"FTP Drive",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Gyrru",
  Microsoft_OneDrive:"Gyrru",
  Import_Fields:"Mewnforio Meysydd",
  Import_Fields_Word:"Defnyddiwch yr adran hon i ddewis <a routerLink='/folder/Holiday/New'>Mewnforio</a> data.",
  Upload:"Llwytho i fyny",
  Download:"Lawrlwytho",
  Download_JSON:"Lawrlwytho fel Data JSON",
  Download_SQL:"Lawrlwytho fel Ffeil SQL",
  New_Bank:"Banc Newydd",
  New_Account:"Cyfrif Newydd",
  New_Bank_Account:"Cyfrif Banc Newydd",
  Upload_Image:"Llwytho Delwedd",
  Bank_Name:"Enw Banc",
  Bank_Address:"Cyfeiriad Banc",
  Branch_Address:"Cyfeiriad Cangen",
  Address_on_Cheque:"Cyfeiriad",
  Cheque_Numbers:"Gwirio Rhifau",
  Cheque_Details:"Gwirio Manylion",
  Bank_Logo:"Logo Banc",
  Cheque_ID:"Gwirio ID",
  Starting_Cheque_ID:"Dechrau Gwiriad ID",
  Transit_Number:"Rhif Trafnidiaeth",
  Institution_Number:"Rhif Sefydliad",
  Designation_Number:"Rhif Dynodiad",
  Account_Number:"Rhif cyfrif",
  Limits:"Terfynau",
  Default_Limits:"Terfynau Diofyn",
  Over_Limit:"Dros y Terfyn",
  Under_Limit:"O dan Gyfyngiad",
  Payroll_Limit:"Terfyn Cyflogres",
  Enable_Bank_Account:"Galluogi Cyfrif Banc",
  Select_Account:"Dewis Cyfrif",
  No_Bank_Account:"Dim Cyfrif Banc",
  No_Bank_Account_Word:"Ychwanegu'r <a routerLink='/folder/Accounts/New'>Cyfrif Banc</a> cyntaf.",
  Bank_Accounts:"Cyfrifon Banc",
  No_Accounts:"Dim Cyfrifon",
  No_Accounts_Note:"Ychwanegu'r <a routerLink='/folder/Accounts/New'>Cyfrif Banc</a> cyntaf.",
  Cheque_Designer:"Gwirio Dylunydd",
  Cheque_Design:"Gwirio Dyluniad",
  Select_Design:"Dewis Dyluniad",
  Cheque_Designs:"Gwirio Dyluniadau",
  No_Cheque_Designs:"Dim Dyluniadau Gwiriad",
  No_Cheque_Designs_Word:"Creu eich <a routerLink='/folder/Settings/Cheque/Design/New'>Gwirio Dyluniad</a> eich hun.",
  Set_Default:"Osod fel ddiofyn",
  Default:"Diofyn",
  Remove:"Dileu",
  Folder:"Ffolder",
  Edit:"Golygu",
  LoadingDots:"Yn llwytho...",
  Add_a_New_File:"Ychwanegu <a href='#' (click)='add()'>Ffeil Newydd</a> i",
  this_folder:"y ffolder yma",
  FTP_Backup_Settings:"Gosodiadau wrth gefn FTP",
  Secure_File_Transfer:"Trosglwyddo Ffeil yn Ddiogel",
  New_Holiday:"Gwyliau Newydd",
  Add_Holiday:"Ychwanegu Gwyliau",
  Holiday_Name:"Enw Gwyliau",
  Additional_Pay:"Tâl Ychwanegol",
  Enable_Holiday:"Galluogi Gwyliau",
  Select_Holidays:"Dewis Gwyliau",
  No_Holidays:"Dim Gwyliau",
  No_Holidays_Word:"Ychwanegu'r <a routerLink='/folder/Holiday/New'>Gwyliau Cyhoeddus</a> cyntaf.",
  New_Import:"Mewnforio Newydd",
  Import_Data:"Mewnforio Data",
  Import_Data_Word:"Dewiswch y math o ffeil neu ddull llwytho i fyny o'ch dewis.<br /> Byddwch yn gallu dewis pa feysydd bynnag a fewnforiwyd mewn ffeil sy'n cyfateb i ba baramedr bynnag yn yr ap ar ôl uwchlwytho ffeil a gefnogir.", 
  Import_File:"Mewnforio Ffeil",
  Link_To_File:"Cyswllt i Ffeil",
  Select_File:"Dewis Ffeil",
  New_Moderator:"Safonwr Newydd",
  Allow_Moderation:"Caniatáu Cymedroli",
  Create_Paycheques:"Creu Paychecks",
  Edit_Paycheques_and_Data:"Golygu Gwiriadau Talu a Data",
  Destroy_Data_and_Paycheques:"Difa Data a Gwiriadau Talu",
  Bonus_Percentage:"Canran Siec Talu",
  Fixed_Amount:"Swm Sefydlog",
  Select_Moderator:"Dewis Cymedrolwr",
  No_Moderators:"Dim Cymedrolwyr",
  Moderators:"Safonwyr",
  Moderator_Account:"Creu'r <a routerLink='/folder/Administrator/New'>Cyfrif Cymedrolwr</a> cyntaf.",
  No_Moderators_Word:"Ychwanegu'r <a routerLink='/folder/Administrator/New'>Safonwr</a> cyntaf.",
  Defaults:"Diofynion",
  Provide_Us_Info:"Rhowch Wybodaeth i Ni",
  Setup_Your_Printer:"Gosod Eich Argraffydd",
  Your_Company:"Am Eich Cwmni",
  Company_Name:"Enw'r Cwmni",
  Currency:"arian cyfred",
  Default_Currency:"Arian diofyn",
  Base_Monthly_Income:"Incwm Misol",
  Protection:"Amddiffyn",
  App_Protection:"Diogelu Apiau",
  PIN_Code_Protection:"Diogelu Cod PIN",
  App_Protection_Word:"Galluogi dulliau diogelwch sy'n helpu i amddiffyn eich cyfrif.",
  PIN_Code:"Cod PIN",
  Change_PIN:"Newid PIN",
  New_Password:"Cyfrinair newydd",
  Geofence_Protection:"Geo-Fens Amddiffyn",
  Geofence_Area:"Ardal Gosod",
  Distance:"Pellter",
  Setup_Now:"Gosod Nawr",
  Mile:"Milltir",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Cydnabyddiaeth Wyneb",
  Face:"Wyneb",
  Setup:"Gosod",
  Label:"Label",
  Password_Protection:"Diogelu Cyfrinair",
  Modify_Password:"Addasu Cyfrinair",
  New_Tax_Entry:"Mynediad Treth Newydd",
  New_Tax:"Treth Newydd",
  Tax_Label:"Label Treth",
  Perpetually_Enabled:"Wedi Galluogi'n Barhaol",
  Select_Taxes:"Dewis Trethi",
  Tax_Deductions:"Didyniadau Treth",
  No_Tax_Deductions:"Dim Didyniadau Treth",
  No_Tax_Deductions_Word:"Ychwanegu'r <a routerLink='/folder/Tax/New'>Treth</a> cyntaf.",
  New_Timer:"Amserydd Newydd",
  Start:"Dechrau",
  Stop:"Stopio",
  Start_Timer:"Amserydd cychwyn",
  Stop_Timer:"Amserydd Stopio",
  Timer_Active:"Amserydd Actif",
  Timer:"Amserydd:",
  Timer_Running:"Amserydd: (Rhedeg)",
  Save_Timer:"Arbed amserydd",
  New_Timesheet:"Taflen Amser Newydd",
  Select_Timesheets:"Dewiswch Daflenni Amser",
  Work_Notes:"Nodiadau Gwaith",
  Entry_Title:"Teitl Mynediad",
  No_Timesheets:"Dim Taflenni Amser",
  No_Timesheets_Word:"Ychwanegu'r <a routerLink='/folder/Timesheet/New'>Taflen Amser</a> cyntaf.",
  Timesheet_Submitted:"Cyflwyno Taflen Amser",
  Timesheet_Congrats:"Llongyfarchiadau! Mae eich Taflen Amser wedi'i chyflwyno'n llwyddiannus. Diolch!",
  Timesheet_Copy:"I dderbyn copi o'ch dogfennau rhowch eich cyfeiriad e-bost a/neu rif ffôn symudol i ni.",
  Submit:"Cyflwyno",
  Allow_Notifications:"Caniatáu Hysbysiadau",
  Untitled_Entry:"Mynediad Newydd",
  Untitled_Bank:"Banc Di-deitl",
  Timesheet_Entry:"Mynediad Taflen Amser",
  Work_Description:"Disgrifiad o Waith",
  Enable_Timesheet:"Galluogi Taflen Amser",
  Submit_Timesheet:"Cyflwyno Taflen Amser",
  Vacation:"Gwyliau",
  New_Vacation:"Gwyliau Newydd",
  Vacation_Name:"Enw Gwyliau",
  Paid_Vacation:"Gwyliau â Thâl",
  Vacation_Pay:"Tâl Gwyliau",
  Enable_Vacation:"Galluogi Gwyliau",
  Select_Vacations:"Dewis Gwyliau",
  No_Vacations:"Dim Gwyliau",
  No_Vacations_Word:"Creu'r cofnod <a routerLink='/folder/Vacation/New'>Gwyliau</a> cyntaf.",
  Payroll_Schedule:"Rhaglen Gyflogres",
  Next_Payroll:"Cyflogres Nesaf:",
  Upcoming_Payroll:"Cyflogres i ddod",
  No_Upcoming_Payroll:"Dim Cyflogres ar Ddod",
  Address_Book:"Llyfr cyfeiriadau",
  Archived_Documents:"Dogfennau wedi'u Harchifo",
  Dev_Mode:"Cais yn y Modd Datblygu",
  Administrators:"Gweinyddwyr",
  Privacy:"Preifatrwydd",
  None:"Dim",
  Select_Signature:"Dewis Llofnod",
  No_Signatures:"Dim Llofnod",
  No_Signatures_Word:"Ychwanegu'r <a routerLink='/folder/Signature/New'>Llofnod</a> cyntaf.",
  Repeat_Indefinitely:"Ailadrodd am gyfnod amhenodol",
  Sign:"Arwydd",
  Sign_Here:"Arwyddo Yma",
  Date_Signed:"Dyddiad Arwyddo",
  Signature_Pad:"Pad Llofnod",
  Account_Holder:"Deiliad Cyfrif",
  Account_Properties:"Priodweddau Cyfrif",
  Name_On_Cheque:"Enw ar Siec",
  Server_Hostname:"Enw Gwesteiwr Gweinydd / IP",
  Printers:"Argraffwyr",
  No_Printers:"Dim Argraffwyr",
  Printer_Exists:"Mae argraffydd o'r enw hwn eisoes yn bodoli.",
  No_Printers_Word:"Ychwanegu'r <a routerLink='/folder/Printer/New'>Argraffydd</a> cyntaf.",
  No_Printer_Alert:"Nid oes unrhyw argraffydd wedi ei ddiffinio. Hoffech chi osod argraffydd?",
  Add_Printer:"Ychwanegu Argraffydd",
  New_Printer:"Argraffydd Newydd",
  Printer_Hostname:"Enw gwesteiwr / IP Argraffydd",
  Printer_Label:"Label Argraffydd",
  Printer_Protocol:"Protocol Argraffydd",
  Protocol:"Protocol",
  Email_Print:"E-bost",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Soced",
  Print_Job:"Swydd Argraffu",
  Printed:"Argraffwyd",
  Not_Printed:"Heb Argraffu",
  Print_Jobs:"Swyddi Argraffu",
  Print_Queue:"Ciw Argraffu",
  No_Print_Jobs:"Dim Swyddi Argraffu",
  No_Prints:"Creu <a routerLink='/folder/Cheque/New'>Siec</a> newydd i'w argraffu.",
  Prints:"Printiau",
  Find_Printer:"Dod o hyd i Argraffydd",
  Find_AirPrint_Devices:"Dod o hyd i Dyfeisiau AirPrint",
  Select_Printer:"Dewis Argraffydd",
  System_UI:"UI system",
  Printer:"Argraffydd",
  Status:"Statws",
  Preview:"Rhagolwg",
  Enable_Print_Job:"Galluogi Swydd Argraffu",
  Queue_Weight:"Pwysau Ciw",
  Unlimited:"Anghyfyngedig",
  Show_Advanced_Printer_Options:"Dangos Opsiynau Argraffydd Uwch",
  Advanced:"Uwch",
  Location:"Lleoliad",
  Note:"Nodyn",
  Queue_Name:"Enw'r Ciw",
  Pages_Printed_Limit:"Terfyn Tudalennau Argraffwyd",
  MultiPage_Idle_Time:"Amser(au) Aros Lluosog",
  Page_Count_Limit:"Terfyn Cyfrif Tudalen",
  Page_Orientation:"Cyfeiriadedd Tudalen",
  Portrait:"Portread",
  Landscape:"Tirwedd",
  Duplex:"Duplex",
  Double_Sided_Printing:"Ochr Ddwbl",
  Duplex_Mode:"Modd Deublyg",
  Duplex_Short:"Byr",
  Duplex_Long:"Hir",
  Duplex_None:"Dim",
  Color_And_Quality:"Lliw ac Ansawdd",
  Monochrome:"Unlliw",
  Photo_Quality_Prints:"Printiau Ansawdd Llun",
  Printer_Email:"E-bost Argraffydd",
  Automatically_Downsize:"Llai o faint yn awtomatig",
  Paper:"Papur",
  Paper_Name:"Enw papur",
  Paper_Width:"Lled Papur",
  Paper_Height:"Uchder Papur",
  Paper_Autocut_Length:"Hyd Torri'n Awtomatig Papur",
  Margins:"Ymyl",
  Page_Margins:"Ymylon Tudalen",
  Page_Margin_Top:"Ymyl y Dudalen Uchaf",
  Page_Margin_Right:"Ymyl y Dudalen Dde",
  Page_Margin_Bottom:"Ymyl Tudalen Waelod",
  Page_Margin_Left:"Ymyl Tudalen Chwith",
  Add_Employees:"Ychwanegu Gweithwyr",
  Header:"Pennawd",
  Print_A_Page_Header:"Argraffu Pennawd Tudalen",
  Header_Label:"Label Pennawd",
  Header_Page_Index:"Mynegai Tudalen Pennawd",
  Header_Font_Name:"Header Font",
  Select_Font:"Dewis Ffont",
  Font_Selector:"Dewisydd Ffont",
  Header_Font_Size:"Header Font",
  Header_Bold:"Pennawd Trwm",
  Header_Italic:"Pennawd italig",
  Header_Alignment:"Aliniad Pennawd",
  Left:"Chwith",
  Center:"Canolfan",
  Right:"Cywir",
  Justified:"Cyfiawnhau",
  Header_Font_Color:"Lliw Pennawd",
  Select_Color:"Dewis Lliw",
  Footer:"Troedyn",
  Print_A_Page_Footer:"Argraffu Troedyn Tudalen",
  Footer_Label:"Label Troedyn",
  Footer_Page_Index:"Mynegai Tudalen Troedyn",
  Footer_Font_Name:"Ffont Troedyn",
  Fonts:"Ffontiau",
  Done:"Gwneud",
  Select_Fonts:"Dewis Ffontiau",
  Footer_Font_Size:"Maint y Troedyn",
  Footer_Bold:"Footer Bold",
  Footer_Italic:"Troedyn italig",
  Footer_Alignment:"Aliniad Troedyn",
  Footer_Font_Color:"Lliw Troedyn",
  Page_Copies:"Copïau tudalen",
  Enable_Printer:"Galluogi Argraffydd",
  Remote_Logging:"Logio o Bell",
  Log_Server:"Gweinydd Log",
  Encryption:"Amgryptio",
  Random_Key:"Allwedd ar hap",
  Encryption_Key:"Allwedd Amgryptio",
  Cheques_Webserver:"Cronfa Ddata Cwsmer",
  Learn_How:"Dysgu Sut",
  Signature:"Llofnod",
  Default_Printer_Unit:"mewn/cm/mm/(pt)",
  View_Signature:"Gweld Llofnod",
  Printed_Signature:"Llofnod Argraffwyd",
  Digitally_Sign:"Arwyddo'n Ddigidol",
  Digital_Signature:"Llofnod Digidol",
  Add_Signature:"Ychwanegu Llofnod",
  Add_Your_Signature:"Ychwanegu Eich Llofnod",
  Enable_Signature:"Galluogi Llofnod",
  Digitally_Signed:"Arwyddo'n Ddigidol",
  Insert_Error:"Methu cadw siec oherwydd problemau cronfa ddata.",
  Delete_Confirmation:"Ydych chi'n siŵr eich bod am ddileu'r wybodaeth hon?",
  Discard_Confirmation:"Ydych chi'n siŵr eich bod am gael gwared ar y wybodaeth hon?",
  Discard_Bank_Confirmation:"Ydych chi'n siŵr eich bod am gael gwared ar y cyfrif hwn?",
  Discard_Printer_Confirmation:"Ydych chi'n siŵr eich bod am daflu'r argraffydd hwn?",
  Delete_Bonus_Confirmation:"Ydych chi'n siŵr eich bod am ddileu'r bonws hwn?",
  Delete_Invoice_Confirmation:"Ydych chi'n siŵr eich bod am ddileu'r anfoneb hon?",
  Generated_Cheque:"Siec a Gynhyrchwyd",
  Generated_Invoice:"Anfoneb Cynhyrchwyd",
  Schedule_Start_Time:"Dechrau Amserlen",
  Schedule_End_Time:"Diwedd yr Amserlen",
  New_Call:"Galwad Newydd",
  No_Contacts:"Dim Cysylltiadau",
  No_Contacts_Word:"Mae gweinyddwyr, cymedrolwyr, gweithwyr, a thalwyr yn ymddangos fel cysylltiadau.",
  PDF_Subject:"cyllid",
  PDF_Keywords:"Sieciau siec PDF cyflogres cyflogres",
  Printer_Setup:"Gosod Argraffydd",
  Printer_Selection:"Dewis Argraffydd",
  New_Fax:"Ffacs Newydd",
  New_Fax_Message:"Neges Ffacs Newydd",
  Fax_Machine:"Machine Ffacs",
  Fax_Name:"Enw ffacs",
  Fax_Email:"E-bost ffacs",
  Fax_Number:"Rhif ffacs",
  Contents:"Cynnwys",
  Fax_Body:"Corff Tudalen",
  Header_Word:"Pennawd:",
  Header_Text:"Testun Pennawd",
  Include_Header:"Cynnwys Pennawd",
  Include_Footer:"Cynnwys Troedyn",
  Footer_Word:"Troedyn:",
  Footer_Text:"Testun Troedyn",
  Attach_a_Cheque:"Atodwch siec",
  Add_Deduction:"Ychwanegu Didyniad",
  Enable_Fax:"Anfon Ffacs",
  Select_Fax:"Dewis Ffacs",
  No_Faxes:"Dim ffacs",
  Faxes:"Ffacs",
  Save_and_Send:"Anfon Ffacs",
  Save_and_Pay:"Arbed a Thalu",
  WARNING:"RHYBUDD:",
  Remember:"Cofiwch",
  Printing:"Argraffu",
  Printing_Complete:"Argraffu wedi'i Gwblhau!\n\n",
  of:"o",
  There_Were:"Roedd yna",
  Successful_Prints:" printiau llwyddiannus a " ,
  Unsuccessful_Prints:"argraffiadau aflwyddiannus.",
  PrinterError:"Mae'n ddrwg gennym! Roedd gwall.",
  Printing_:"Argraffu...",
  PrinterSuccess:"Dogfen wedi'i hargraffu'n llwyddiannus.",
  PrintersSuccess:"Dogfennau wedi'u hargraffu'n llwyddiannus.",
  Print_Cheques:"Gwiriadau Argraffu",
  New_Message:"Neges Newydd",
  New_Messages:"Negeseuon Newydd",
  Read_Message:"Darllen Neges",
  Write_Message:"Ysgrifennu Neges",
  Send_Message:"Anfon Neges",
  Subject:"Pwnc",
  Message:"Neges",
  Writing:"Ysgrifennu...",
  Send:"Anfon",
  Set_Date:"Gosod dyddiad",
  Set_Time:"Time Set",
  Recieve:"Derbyn",
  Set_as_Default:"Osod fel ddiofyn",
  Default_Account:"Cyfrif Diofyn",
  Default_Design:"Dyluniad Diofyn",
  Multiple_Cheques:"Gwiriadau (Triphlyg)",
  Account_Mode:"Modd Cyfrif",
  Multiple_Cheques_Description:"Tri siec ar bob tudalen.",
  Check_and_Table:"Gwirio & Tabl",
  Check_including_Table:"Tabl gwirio a chyfrifo.",
  Check_Mailer:"Gwirio Postiwr",
  Check_Mailer_Window:"Gwiriwch gyda ffenestr cyfeiriad.",
  DocuGard_Table_Top:"Gwiriad a Thabl DocuGard (Brig)",
  DocuGard_Table_Middle:"Gwiriad a Thabl DocuGard (Canol)",
  DocuGard_Table_Bottom:"Gwiriad a Thabl DocuGard (Gwaelod)",
  DocuGard_Mailer_Top:"Postiwr Gwirio DocuGard (Brig)",
  DocuGard_Mailer_Middle:"Postiwr Gwirio DocuGard (Canol)",
  DocuGard_Mailer_Bottom:"Postiwr Gwirio DocuGard (Gwaelod)",
  DocuGard_Three_Cheques:"Gwiriadau DocuGard (Tripple)",
  DocuGard_Cheque_Top:"Gwiriad DocuGard (Brig)",
  DocuGard_Cheque_Middle:"Gwiriad DocuGard (Canol)",
  DocuGard_Cheque_Bottom:"Gwiriad DocuGard (Gwaelod)",
  DocuGard_Three_Cheques_Window:"Tri siec ar un dudalen.",
  DocuGard_Table_Top_Window:"Tabl gwirio ac enillion.",
  DocuGard_Table_Middle_Window:"Tabl gwirio ac enillion.",
  DocuGard_Table_Bottom_Window:"Tabl gwirio ac enillion.",
  DocuGard_Mailer_Top_Window:"Gwirio, bwrdd, a chyfeiriad.",
  DocuGard_Mailer_Middle_Window:"Gwirio, bwrdd, a chyfeiriad.",
  DocuGard_Mailer_Bottom_Window:"Gwirio, bwrdd, a chyfeiriad.",
  DocuGard_Cheque_Top_Window:"Gwiriwch am bapur diogel.",
  DocuGard_Cheque_Middle_Window:"Gwiriwch am bapur diogel.",
  DocuGard_Cheque_Bottom_Window:"Gwiriwch am bapur diogel.",
  Basic_Cheque:"Gwirio (Top)",
  Basic_Cheque_Print:"Argraffu siec sengl.",
  Error_Setting_As_Paid:"Gwall Gosod fel Taledig",
  Add_Attachment:"Ychwanegu atodiad",
  PrinterUnavailable:"Argraffydd ddim ar gael",
  CreditCardComponent:"Cardiau",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Blaendal Newydd",
  Deposits:"Adnau",
  No_Deposits:"Dim Blaendaliadau",
  Credit_Card_Deposit:"Cerdyn credyd",
  New_Credit_Card_Deposit_Message:"Blaendal Cerdyn Credyd",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin Blaendal",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Trosglwyddo Interac",
  Payments_Limit:"Terfyn Talu",
  No_Payment_Limit:"Dim Terfyn Talu",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Blaendal PayPal",
  No_Deposits_Word:"Ychwanegu'r cwmni cyntaf <a routerLink='/folder/Deposit/New'>Blaendal</a>.",
  No_Documents_Specified:"Ni phenodwyd unrhyw ddogfennau i'w hargraffu",
  No_Printers_Added:"Ni chanfuwyd unrhyw argraffwyr. Ewch i Gosodiadau > Argraffwyr i ychwanegu un.",
  DB_Erase_Prompt:"Dileu'r gronfa ddata gyfan yn llwyr? RHYBUDD: Byddwch yn colli'r holl wybodaeth sydd wedi'i storio!",
  Console_Warning:"Peidiwch â gludo unrhyw destun i'r consol hwn. Gallwch roi eich hun a/neu eich cwmni mewn perygl difrifol.",
  No_Faxes_Word:"Creu'r <a routerLink='/folder/Fax/New'>Ffacs</a> cyntaf.",
  Cheque_Delete_Confirmation:"Ydych chi'n siŵr eich bod am ddileu'r siec hwn?",
  Design_Delete_Confirmation:"Ydych chi'n siŵr eich bod am ddileu'r cynllun hwn?",
  Cheque_Pay_Confirmation:"Marcio bod y siec hon wedi'i thalu? NI fydd yn ymddangos yn y ciw argraffu.",
  Payment_Pay_Confirmation:"Marcio bod y taliad hwn wedi'i dalu? NI fydd yn ymddangos yn y ciw siec.",
  Delete_Deduction_Confirmation:"Ydych chi'n siŵr eich bod am ddileu'r didyniad hwn?",
  Delete_Job_Confirmation:"Ydych chi'n siŵr eich bod am ddileu'r swydd hon?",
  Delete_Timesheet_Confirmation:"Ydych chi'n siŵr eich bod am ddileu'r daflen amser hon?",
  Delete_Schedule_Confirmation:"Ydych chi'n siŵr eich bod am ddileu'r amserlen hon?",
  Delete_Setting_Confirmation:"Ydych chi'n siŵr eich bod am ailosod y gosodiad hwn?",
  Delete_Fax_Confirmation:"Ydych chi'n siŵr eich bod am ddileu'r ffacs hwn?",
  Delete_File_Confirmation:"Ydych chi'n siŵr eich bod am ddileu'r ffeil hon?",
  Delete_Vacation_Confirmation:"Ydych chi'n siŵr eich bod am ddileu'r gwyliau hyn?",
  Delete_Printer_Confirmation:"Ydych chi'n siŵr eich bod am ddileu'r argraffydd hwn?",
  Remove_Design_Confirmation:"Ydych chi'n siŵr eich bod am ddileu'r cynllun hwn?",
  Delete_Payroll_Confirmation:"Ydych chi'n siŵr eich bod am ddileu'r gyflogres hon?",
  Send_Fax_Email_Confirmation:"Ydych chi am ffacsio ac e-bostio'r dogfennau hyn?",
  Send_Email_Confirmation:"Ydych chi am e-bostio'r ddogfen hon?",
  Send_Fax_Confirmation:"Ydych chi am ffacsio'r ddogfen hon?",
  Error_Generating_PDF:"Mae'n ddrwg gennym. Bu gwall wrth gynhyrchu'r ddogfen hon.",
  PDF_Error_Saving_Image:"Mae'n ddrwg gennym. Roedd gwall wrth gadw delwedd PDF o'r ddogfen hon.",
  Test_Printer_Confirmation:"Ydych chi am argraffu tudalen brawf ar yr argraffydd hwn?",
  Save_Timesheet_Prompt:"Ychwanegwch 'Teitl' neu pwyswch 'Amserydd Cychwyn' i gadw.",
  Remove_Geofence_Prompt:"Ydych chi'n siŵr eich bod am ddileu lleoliad y geo-ffens hon?",
  Delete_Employee_Confirmation:"Ydych chi'n siŵr eich bod am ddileu",
  Fire_Employee_Confirmation:"Ydych chi'n siŵr eich bod chi eisiau tanio"
}