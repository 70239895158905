import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  constructor(private router: Router) {
  }

  go(l?) {
    this.router.navigate([l]);
  }

  view_pricing() {
    this.go('pricing');
  }
  
  trial() {
    this.go('signup');
  }
  
  screens() {
    this.go('screenshots');
  }

  register() {
    this.go('signup');
  }

  ngOnInit() {
  }

}
