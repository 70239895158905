export const Cs = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Copyright &copy; 2023",
  black:"Černá",
  green:"Zelená",
  gold:"Zlato",
  blue:"Modrý",
  brown:"Hnědý",
  purple:"Nachový",
  pink:"Růžový",
  red:"Červené",
  Swatches:"Vzorníky",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Odstín",
  Saturation:"Nasycení",
  Lightness:"Světlost",
  Upgrade_To_Pro:"Upgradujte na Pro",
  AllFeaturesInclude:"Všechny funkce zahrnují:",
  PrintUnlimitedCheques:"Tisk neomezených šeků",
  PremiumChequeDesigns:"Prémiové šekové vzory",
  ManageUnlimitedEmployees:"Spravujte neomezený počet zaměstnanců",
  AddUnlimitedPayees:"Přidejte neomezené příjemce",
  CreateUnlimitedPayrolls:"Vytvářejte neomezené mzdy",
  UnlimitedSchedulesandTimeSheets:"Neomezené rozvrhy a časové rozvrhy",
  BulkPayPalPayouts:"Hromadné platby přes PayPal",
  UnlimitedBankAccounts:"Neomezené bankovní účty",
  ManageMultipleCompanies:"Správa více společností",
  TrackInsurancePolicies:"Sledujte pojistné smlouvy",
  Bio_MetricProtection:"Biometrická ochrana",
  Geo_FenceLock_OutProtection:"Ochrana proti uzamčení Geo-Fence",
  Multiple_Companies_Word:"Správa více společností není dostupná bez prémie šeků.",
  PayPal_Payouts_Word:"Platby PayPal jsou zakázány bez prémie šeků.",
  PINProtection:"Ochrana PIN",
  PasswordProtection:"Ochrana heslem",
  May_Require_Approval:"Může vyžadovat schválení.",
  Subscribe:"předplatit",
  Billed:"Účtováno",
  Up:"Nahoru",
  Down:"Dolů",
  Positioning:"Polohování",
  Marker:"Popisovač",
  Drag_Marker:"Přetáhněte značku",
  Tagline:"Tisk šeků a tabulky mezd",
  Marker_Word:"Pomocí značek upravte velikost prvku.",
  Pinch_Zoom:"Přiblížení sevřením",
  Pinch_Word:"Stažením prstů přiblížíte prvek.",
  Drag:"Táhnout",
  Drag_Word:"Prsty přetahujte.",
  subscription_alias_word:"Automatické obnovení předplatného",
  premium_alias_word:"Jednorázový balíček upgradu",
  print_alias_word:"Vytiskněte si jednotlivé šeky",
  mode_alias_word:"Režim",
  Pro:"Pro",
  Pro_word:"Vyžaduje se verze Pro.",
  Cant_Delete_Default_Company_Word:"Je nám líto, ale svou výchozí společnost nelze smazat.",
  Reinsert_Default_Designs:"Znovu vložte výchozí návrhy",
  Reinsert_Default_Designs_word:"Chcete znovu vložit výchozí návrhy?",
  Subscription_Active_Disable_Word:"Toto předplatné je aktivní. Chcete zrušit toto předplatné šeků?",
  Company_Logo:"Logo společnosti",
  ZERO_:"NULA",
  Disclaimer:"Zřeknutí se odpovědnosti",
  Privacy_Policy:"Zásady ochrany osobních údajů",
  EULA:"Kontroluje EULA",
  Terms_Of_Service:"Podmínky služby",
  Terms_Of_Use:"Podmínky použití",
  Refunds:"Zásady vrácení peněz",
  Single_Print:"1 Zkontrolujte",
  Two_Prints:"2 Kontroly",
  Five_Prints:"5 Kontroly",
  Ten_Prints:"10 kontrol",
  Fifteen_Prints:"15 Kontroly",
  Twenty_Prints:"20 šeků",
  Thirty_Prints:"30 šeků",
  Image_Added:"Obrázek přidán",
  Image_Preview:"Náhled obrázku",
  No_Image_Was_Selected:"Nebyl vybrán žádný obrázek.",
  Cheques_Unlimited:"Kontroly neomezeně",
  _Subscription:"Předplatné",
  Subscription:"Kontroly - 1 měsíc",
  Two_Month_Subscription:"Kontroly - 2 měsíce",
  Three_Month_Subscription:"Kontroly - 3 měsíce",
  Six_Month_Subscription:"Kontroly - 6 měsíců",
  Twelve_Month_Subscription:"Kontroly - 12 měsíců",
  Cheques_Are_Available:"Šeky jsou k dispozici k tisku.",
  Upgrade_Required_Two:"Vyberte balíček a poklepáním na tlačítko ceny zahajte nákup. Tiskněte profesionálně vypadající plnobarevné šeky během několika sekund.",
  Month:"Měsíc",
  Due:"Z důvodu:",
  Expires:"Platnost vyprší:",
  Subscription_Active:"Předplatné je aktivní",
  Subscription_Inactive:"Předplatné je neaktivní",
  Purchase_Additional_Cheques:"Koupit další šeky?",
  Printable_Cheque:"Tisknout šek",
  Printable_Cheques:"Šeky k vytištění",
  Printable_Cheque_Word:"šek je k dispozici na vašem účtu.",
  Printable_Cheques_Word:"šeky jsou k dispozici na vašem účtu.",
  Max_Amount_Message:"Částka, kterou jste zadali, přesáhla maximální částku nastavenou pro tento systém:",
  Terms_Required_Word:"Než budete pokračovat v používání šeků, musíte s touto dohodou souhlasit.",
  Subscriptions:"Předplatné",
  Product_Upgrades:"Upgrady",
  Mailed_Out_Cheques:"Odeslané šeky",
  Enter_A_Company_Name:"Zadejte název společnosti.",
  Single_Cheques:"Jednotlivé kontroly",
  Cheque_Golden:"Zlatý šek",
  Cheque_Golden_Window:"Design zlatého šeku.",
  Cheque_Green:"Zelená kontrola",
  Cheque_Green_Window:"Zelený šekový design.",
  Cheque_Red:"Červená kontrola",
  Cheque_Red_Window:"Červený šekový design.",
  Cheque_Yellow:"Žlutá kontrola",
  Cheque_Yellow_Window:"Žlutý šekový design.",
  Cheque_Statue_of_Liberty:"Socha svobody",
  Cheque_Statue_of_Liberty_Window:"Šachový design sochy svobody.",
  Cheque_Green_Wave:"Zelená vlna",
  Cheque_Green_Wave_Window:"Zelený šekový design.",
  Cheque_Golden_Weave:"Zlatá vazba",
  Cheque_Golden_Weave_Window:"Elegantní zlatý károvaný design.",
  Cheque_Green_Sun:"Zelené slunce",
  Cheque_Green_Sun_Window:"Hluboký a uklidňující design šeku.",
  Cheque_Blue_Checkers:"Modrá dáma",
  Cheque_Blue_Checkers_Window:"Modrý šekový design.",
  Cashiers_Check:"Pokladní šek",
  Cashiers_Check_Window:"Šablona stylu pokladní kontroly.",
  Cheque_Aqua_Checkers:"Aqua dáma",
  Cheque_Aqua_Checkers_Window:"Design Aqua check.",
  Cheque_Golden_Checkers:"Zlatá dáma",
  Cheque_Golden_Checkers_Window:"Design zlatého šeku.",
  Upgrade_Unavailable:"Upgrady nejsou k dispozici",
  Bank_Code_Protection:"Ochrana bankovního čísla",
  Bank_Code_Protection_Word:"Chraňte svá bankovní čísla před použitím v této aplikaci spuštěné na jiném zařízení nebo pro jiného uživatele. Tato akce je NEVRATNÁ. Pokračovat?",
  Bank_Code_Protection_Blocked_Word:"Čísla bank, která se pokoušíte použít, jsou rezervována pro jiného uživatele nebo zařízení.",
  Bank_Code_Protection_Error_Word:"Ověření čísla se nezdařilo. Připojte se k internetu a zkuste znovu přidat tento bankovní účet.",
  Bank_Code_Protection_Set_Error_Word:"Ochrana bankovního čísla vyžaduje, abyste byli připojeni k internetu. Připojte se a zkuste to znovu.",
  Upgrade_Unavailable_Word:"Litujeme, máme potíže s ověřením. Předplatné a upgrady na Šeky nejsou momentálně ve vaší oblasti k dispozici.",
  PayPal_Payment_Preview:"Náhled platby PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Vyberte PayPal",
  PayPal_Applications:"Aplikace PayPal",
  Purchase_With_Apple_Pay:"Nakupujte pomocí Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Společnosti",
  Insurance:"Pojištění",
  New_PayPal:"Nový PayPal",
  Pay_By:"Pay By",
  Insure:"Pojistit",
  Miles:"Miles",
  Payment_Method:"Způsob platby",
  Select_Policies:"Vyberte Zásady",
  Policies:"Opatření",
  Policy:"Politika",
  No_PayPal_Account:"Žádný účet PayPal",
  No_Policies:"Žádné pojistné smlouvy",
  New_Policy:"Nová politika",
  PayPal_Payment:"Platba přes PayPal",
  PayPal_Payments:"PayPal platby",
  No_Payment_Selected:"Není vybrána žádná platba",
  Sending_Payment_Word:"Čekejte prosím... Tato platba se odesílá.",
  Sending_Payments_Word:"Čekejte prosím... Platby se odesílají.",
  No_Payment_Selected_PayPal:"Nebyla vybrána žádná <a routerLink='/folder/Payments'>Platba PayPal</a> k odeslání.",
  Payment_Sent:"Platba odeslána",
  PayPal_Payment_Sent:"Tato platba byla odeslána přes PayPal.",
  Copay:"Copay",
  Dead:"Mrtví",
  Alive:"Naživu",
  Not_Dead:"Ne mrtvý",
  Unclaimed:"Nenárokováno",
  Attempted:"Pokus",
  Deceased:"Zesnulý",
  Claimed:"Nárokováno",
  Unpaid:"Nezaplaceno",
  Sending_Payment:"Odeslání platby",
  Sending_Payments:"Odesílání plateb",
  Send_PayPal_Confirmation:"Chcete odeslat tuto transakci přes PayPal?",
  Send_PayPal_Confirmation_Word:"Stisknutím zeleného tlačítka tuto transakci odešlete.",
  Save_Payment_As_Unpaid:"Uložit tuto platbu jako nezaplacenou?",
  Payment_Pay_Confirmation_PayPal:"Uložit tuto platbu jako zaplacenou?",
  No_Policies_Word:"přidejte první <a routerLink='/folder/Postage/New'>Pojistnou smlouvu</a>.",
  Timesheet_Multiple_Delete_Confirmation:"Opravdu chcete smazat více časových výkazů?",
  Select_Multiple_Timesheets_Prompt:"Nejsou vybrány žádné časové rozvrhy. Vyberte alespoň jeden časový rozvrh.",
  Select_Multiple_Policies_Prompt:"Nejsou vybrány žádné zásady. Vyberte alespoň jedno pojištění.",
  Policies_Multiple_Delete_Confirmation:"Opravdu chcete smazat více zásad?",
  Company:"Společnost",
  Add_Company:"Přidat společnost",
  New_Company:"Přidat společnost",
  No_Companies:"Žádné společnosti",
  Enable_Company:"Povolit společnost",
  Select_Company:"Vyberte Společnost",
  The_Default_Company:"Výchozí štítek společnosti.",
  Manage_Companies:"Správa společností",
  No_Companies_Word:"Kontroly budou používat výchozí společnost.<br /> Přidejte <a routerLink='/folder/Company/New'>první společnost</a>.",
  Default_Company:"Výchozí společnost",
  Cheques_Unlimited_Word:"Checks Unlimited vám umožňuje vytisknout tolik šeků, kolik chcete.",
  Cheques_Subscription_Word:"Předplatné šeků vám umožňuje vytisknout tolik šeků, kolik chcete.",
  You_Own_This_Product:"Vlastníte tento produkt.",
  Your_Subscription_is_Active:"Vaše předplatné je aktivní.",
  Active_Products:"Aktivované produkty",
  Purchase_Confirmation:"Nákup",
  Purchase_Cheques:"Kontroly nákupu",
  Restore_Purchase:"Obnovit nákupy",
  Erase_Purchase:"Vymazat nákupy",
  Successfully_Purchased:"Úspěšně zakoupeno",
  Enter_Your_Licence_Key:"Pro aktivaci tohoto produktu prosím zadejte svůj licenční klíč na této stránce.",
  Licence_Key:"Licenční klíč",
  Enter_Licence_Key:"Zadejte licenční klíč",
  Purchased:"Zakoupeno",
  Enable_Feature:"Povolit funkci",
  Cancel_Subscription:"Zrušit předplatné",
  Subscription_Removed:"Předplatné odstraněno",
  Select_Active_Subscription:"Vyberte aktivní předplatné, abyste jej mohli upravit.",
  Remove_Subscription_Word:"Opravdu chcete zrušit toto předplatné?",
  Delete_Company_Confirmation:"Opravdu chcete smazat celou tuto společnost? VAROVÁNÍ: Ztratíte všechny uložené informace!",
  Delete_Default_Company_Word:"Výchozí společnost nelze smazat . Chcete aplikaci resetovat a obnovit do výchozího stavu? VAROVÁNÍ: Ztratíte všechny uložené informace!",
  Console_Warning_2:"Nemanipulujte s žádnou měnou pomocí této aplikace, která momentálně není vaše.",
  Terms_and_Conditions:"Pravidla a podmínky",
  and_the:"a",
  for:"pro",
  Please_Read_Word:"Přečtěte si prosím a odsouhlaste",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Některé směnné kurzy měn nebyly nalezeny. Připojte se prosím k internetu.",
  Free:"Volný, uvolnit",
  DB_Erase_Prompt_2:"Úplně vymazat celou vývojářskou databázi? VAROVÁNÍ: Ztratíte všechny informace o NÁKUPU a PŘEDPLATÍ!",
  Successfully_Imported:"Úspěšně importováno",
  Select_Postage:"Vyberte Poštovné",
  No_Postage:"Žádné poštovní známky",
  No_Paid_Postage_Word:"Přidejte první známku <a routerLink='/folder/Postage/New'>Zaplacené poštovné</a>.",
  Trial_Complete:'Zkušební verze dokončena',
  Please_Upgrade:'Chcete-li pokračovat v tisku, aktualizujte Kontroly.',
  Aa:"Aa",
  Color:"Barva",
  Font:"Písmo",
  Guide:"Průvodce",
  Guides:"Průvodci",
  Image:"obraz",
  Zoom:"Zvětšení",
  Logo:"Logo",
  Bank:"banka",
  MICR:"MICR",
  Total:"Celkový",
  Cancel:"zrušení",
  Confirm:"Potvrdit",
  Method:"Metoda",
  Biometric_Security:"Biometrické zabezpečení",
  Please_Login_To_Continue:"Pro pokračování se prosím přihlašte.",
  Complete:"Kompletní",
  Sign_Up:"Přihlásit se",
  Error:"Chyba",
  Biometrics:"Biometrie",
  Percent:"Procento",
  Zero_Percent:"0 %",
  Top_Margin:"Horní okraj",
  Bottom_Margin:"Dolní okraj",
  Left_Margin:"Levý okraj",
  Right_Margin:"Pravý okraj",
  MICR_Margin:"MICR Marže",
  Table_Margin:"Okraj tabulky",
  Address_Margin:"Okraj adresy",
  Percentage_:"Procento",
  Vacation_Title:"Dovolená titul",
  Use_PIN:"Použijte PIN",
  Loading__:"Načítání...",
  Design_Title:"Název designu",
  Authorize:"Povolit",
  Key:"Klíč",
  Public_Key:"Veřejný klíč",
  Private_Key:"Soukromý klíč",
  Authenticate:"Ověřit",
  Last_Payroll:"Poslední výplatní páska",
  Last_Calculation:"Poslední výpočet",
  Authorized:"Autorizovaný",
  Geo_Authorized:"Geo-Lokalita: Autorizovaná",
  Not_Authorized:"Neautorizovaný",
  Authorization_Complete:"Autorizace dokončena",
  Geolocation_Authorization:"Oprávnění k geografické poloze",
  Out_of_Bounds:"Mimo meze",
  Cant_Delete_Default_Design:"Výchozí design nelze smazat.",
  Unauthorized_Out_of_Bounds:"Neoprávněné: Mimo hranice",
  Biometric_Authorization:"Biometrická autorizace",
  Locating_Please_Wait:"Nachází se, čekejte prosím...",
  Test_Biometrics:"Testování biometrických údajů",
  Cheque_Margins:"Zkontrolujte okraje",
  Percentage_Full_Error:"Procentuální pole nelze nastavit na více než 100 % procent.",
  No_Payroll_Text:"Přidejte <a routerLink='/folder/Employee/New'>Zaměstnanec</a> nebo <a routerLink='/folder/Payee/New'>Pyee</a> a <a routerLink='/folder/Schedule/New'>Plán</a>.",
  Design_Saved:"Návrh uložen",
  Default_Design_Selected:"Výchozí návrh vybrán",
  Partial_Import:"Částečný import",
  Partial_Export:"Částečný export",
  Entire_Import:"Celý import",
  Entire_Export:"Celý export",
  Existing_Password:"Zadejte své stávající heslo:",
  Existing_PIN:"Zadejte prosím svůj stávající PIN kód:",
  Pin_Change_Header:"Potvrzení PIN",
  Pin_Change_SubHeader:"Pro potvrzení změny zadejte svůj starý PIN.",
  Pass_Change_Header:"Potvrzení hesla",
  Pass_Change_SubHeader:"Pro potvrzení změny zadejte své staré heslo.",
  PIN_Enter_Message:"Pro potvrzení zadejte svůj PIN.",
  Password_Enter_Message:"Pro potvrzení zadejte své heslo.",
  Pin_Updated_Success:"PIN byl úspěšně aktualizován!",
  Pin_Updated_Fail:"PIN se nepodařilo aktualizovat.",
  Pass_Updated_Success:"Heslo bylo úspěšně aktualizováno.",
  Pass_Updated_Fail:"Heslo se nepodařilo aktualizovat.",
  Preview_Payment:"Náhled platby",
  Preview_Payroll:"Náhled mezd",
  No_Payments_Created:"Nebyly nalezeny žádné platby k vytvoření.",
  Payment_Preview:"Náhled platby",
  Enable_Payee:"Povolit příjemce platby",
  Rendered:"Vykresleno",
  No_Email:"Žádný e-mail",
  Setup_Cheques:"Kontroly nastavení",
  name:"název",
  address:"Adresa",
  address_2:"2. řádek adresy",
  city:"Město",
  province:"Provincie",
  postal_code:"Poštovní směrovací číslo",
  country:"Země",
  username:"uživatelské jméno",
  full_name:"Celé jméno",
  birthday:"Narozeniny",
  email:"E-mailem",
  phone:"Telefon",
  employees:"Zaměstnanci",
  addresses:"Adresy",
  payment_amount_limit:"Limit částky platby",
  total_limit:"Celkový limit",
  payees:"Příjemci platby",
  description:"Popis",
  address_line_one:"První řádek adresy",
  address_line_two:"Druhý řádek adresy",
  image:"obraz",
  account_holder:"Majitel účtu",
  cheque_starting_id:"Zkontrolujte počáteční ID",
  transit_number:"Číslo tranzitu",
  institution_number:"Číslo instituce",
  designation_number:"Číslo označení",
  account_number:"Číslo účtu",
  currency:"Měna",
  signature:"Podpis",
  payment_payroll_limit:"Platební limit",
  total_limi:"Celkový limit",
  date:"datum",
  printed_memo:"Tištěná poznámka",
  banks:"banky",
  signature_image:"Obrázek podpisu",
  address_name:"Název adresy",
  notes:"Poznámky",
  design:"Design",
  title:"Titul",
  frequency:"Frekvence",
  fax:"Fax",
  salaries:"platy",
  salary_ids:"Platové průkazy",
  start_time:"Doba spuštění",
  end_time:"Čas ukončení",
  paid:"Zaplaceno",
  overtime_percentage:"Vyplacené procento",
  overtime_amount:"Vyplacená pevná částka",
  first_name:"Jméno",
  last_name:"Příjmení",
  moderation:"Umírněnost",
  create:"Vytvořit",
  edit:"Upravit",
  destroy:"Zničit",
  day_start_time:"Day_start_time",
  day_end_time:"Day_end_time",
  fullname:"název",
  time:"Čas",
  auto_send:"Automaticky odeslat",
  time_method:"Časová metoda",
  schedules:"Jízdní řády",
  indefinite_payroll_enabled:"Povolit na neurčito",
  amount:"Množství",
  repeat:"Opakovat",
  always_enabled:"Vždy povoleno",
  start_date:"Počáteční datum",
  end_date:"Datum ukončení",
  Cheque_Total:"Zkontrolujte celkem",
  Total_Amount:"Celková částka:",
  Amounts:"Množství:",
  Images:"snímky",
  Files:"Soubory",
  Previewing:"Náhled:",
  Insert:"Vložit",
  Preview_Import:"Náhled importu",
  Entry:"Vstup",
  Entries:"Příspěvky",
  No_Entries:"Žádné záznamy",
  Import_Type:"Typ importu",
  Select_Details:"Vyberte Podrobnosti",
  Select_Payee:"Vyberte Příjemce platby",
  Enable_Holidays:"Povolit svátky",
  Disable_Holidays:"Zakázat svátky",
  Wire_Transfer:"Bankovní převod",
  New_Export:"Nový export",
  Export_Data:"Export dat",
  Export_Data_Word:"Vyberte typ souboru, který chcete exportovat a stáhnout.",
  Export_File:"Exportovat soubor",
  Mode:"Režim",
  Times:"Times",
  Widgets:"Widgety",
  Slider:"Posuvník",
  Set_Details:"Nastavit Podrobnosti",
  Select_Type:"Vyberte Typ",
  Display_Slider:"Posuvník zobrazení",
  Dashboard_Slider:"Posuvník na palubní desce",
  Dashboard_Mode:"Režim palubní desky",
  Show_Intro:"Zobrazit úvod",
  Show_Payrolls:"Zobrazit výplatní pásku",
  Show_Holidays:"Zobrazit svátky",
  Show_Invoices:"Zobrazit faktury",
  Show_Cheques:"Zobrazit kontroly",
  Enabled_Widgets:"Povolené widgety",
  Disabled_Widgets:"Zakázané widgety",
  Colors:"Barvy",
  Barcodes:"Čárové kódy",
  View_Timers:"Zobrazit časovače",
  Gradients:"Přechody",
  No_Info:"Žádné informace",
  Disable:"Zakázat",
  Show_Layer:"Zobrazit vrstvy",
  Hide_Layer:"Skrýt vrstvy",
  Text_Layer:"Textové vrstvy",
  Secondly:"Za druhé",
  Minutely:"Po minutě",
  nine_am:"9:00",
  five_pm:"17:00",
  Enable_Address:"Povolit adresu",
  Invalid_File_Type:"Litujeme, byl vybrán neplatný typ souboru. Podporovaný typ souboru:",
  Error_Updating_Entry:"Omlouváme se, při aktualizaci tohoto záznamu došlo k chybě.",
  Schedule_Timing_Alert:"Chyba: Čas zahájení plánu je nastaven na hodnotu po čase ukončení.",
  Select_Multiple_Payments_Prompt:"Nejsou vybrány žádné platby. Vyberte alespoň jednu platbu.",
  Select_Multiple_Cheques_Prompt:"Nejsou vybrány žádné kontroly. Vyberte prosím alespoň jeden šek.",
  Select_Multiple_Items_Prompt:"Nejsou vybrány žádné položky. Vyberte prosím alespoň jednu položku.",
  Paymemt_Multiple_Delete_Confirmation:"Opravdu chcete smazat více plateb?",
  Cheque_Multiple_Delete_Confirmation:"Opravdu chcete smazat více kontrol?",
  Payee_Multiple_Delete_Confirmation:"Opravdu chcete smazat více příjemců platby?",
  Employee_Multiple_Delete_Confirmation:"Opravdu chcete smazat více zaměstnanců?",
  MICR_Warning:"Poznámka: Některé tiskárny a zařízení nemusí zobrazit písmo MICR správně.",
  Automatically_Send:"Automaticky odeslat",
  Type:"Typ",
  Payment_Type:"Způsob platby",
  Auto_Send:"Automatické odeslání",
  Automatically_Process:"Automaticky zpracovat",
  Auto_Process:"Auto Process",
  Image_Based:"Na základě obrázků",
  Font_Based:"Na základě písma",
  Rerender:"Znovu vykreslit",
  Rendering:"Vykreslování",
  Render:"Soubory",
  Top:"Horní",
  Middle:"Střední",
  Bottom:"Dno",
  Top_Left:"Vlevo nahoře",
  Top_Center:"Nahoru Střed",
  Top_Right:"Vpravo nahoře",
  Middle_Left:"Uprostřed vlevo",
  Middle_Center:"Střed střed",
  Middle_Right:"Uprostřed vpravo",
  Bottom_Left:"Vlevo dole",
  Bottom_Center:"Spodní střed",
  Bottom_Right:"Vpravo dole",
  Numbers:"čísla",
  Verified:"Ověřeno",
  Paper_Size:"Velikost papíru",
  New_Device:"Nové zařízení",
  Add_Device:"Přidat zařízení",
  Remove_Device:"Odebrat zařízení",
  Delete_Device:"Smazat zařízení",
  Block_Device:"Blokovat zařízení",
  Block:"Blok",
  Unblock:"Odblokovat",
  Table:"Stůl",
  Scan_Login_Code:"Naskenujte přihlašovací kód",
  Login_Code:"Přihlašovací kód",
  Scan_Code:"Skenovat kód",
  Scan_QR_Code:"Naskenujte QR kód",
  Select_All:"Vybrat vše",
  Deselect_All:"Odznačit vše",
  Increase:"Zvýšit",
  Decrease:"Pokles",
  Bold:"tučně",
  Text:"Text",
  Style:"Styl",
  Italic:"kurzíva",
  QR_Code:"QR kód",
  Barcode:"čárový kód",
  Browse_Images:"Procházet obrázky",
  Browse_Files:"Procházet soubory",
  Background_Image:"Obrázek na pozadí",
  Logo_Image:"Obrázek loga",
  Header_Image:"Obrázek záhlaví",
  Bank_Image:"Obrázek banky",
  Cut_Lines:"Řezné čáry",
  Background:"Pozadí",
  License:"Licence",
  One_License:"1 licence:",
  Licensed:"Licence:",
  Not_Licensed:"Bez licence",
  Enter_Serial:"Zadejte sériové číslo",
  Serial_Key:"Sériový klíč",
  Serial:"Seriál",
  Product_Key:"Product Key",
  Check_Product_Key:"Zkontrolujte produktový klíč",
  Add_Product_Key:"Přidat kód Product Key",
  Verifying_Purchase:"Ověřování nákupu...",
  Print_Envelope:"Tisknout obálku",
  Envelope:"Obálka",
  Thank_You:"Děkuji!",
  Scale:"Měřítko",
  Print_Scale:"Měřítko tisku",
  Borders:"Hranice",
  VOID:"ZRUŠIT",
  Void_Cheque:"Kontrola neplatnosti",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"PLATÍTE NA OBJEDNÁVKU:",
  NO_DOLLARS:"ŽÁDNÉ DOLARY ",
  ONE_DOLLAR:"JEDEN DOLAR",
  DOLLARS:" DOLARŮ",
  AND:" A ",
  CENTS:" CENTŮ.",
  NO_:"NE ",
  ONE_:"JEDEN ",
  AND_NO_:"A ŽÁDNÁ ",
  _AND_ONE_:"A JEDEN ",
  DOLLARS_AND_ONE_CENT:" A JEDEN CENT.",
  AND_NO_CENTS:" A NULA CENTŮ.",
  CHEQUE_PRINT_DATE:"DATUM:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Počáteční úkoly",
  Inital_Setup:"Počáteční nastavení",
  Welcome_To:"Vítejte v ",
  Welcome:"Vítejte",
  Swipe:"Výpad",
  Intro_Setup:"Intro Setup",
  Introduction:"Úvod",
  CHEQUE_PRINT_CREDIT:"Pohání šeky",
  Title_Intro_Word:"Vítejte v šekech",
  Title_Intro:"Úvod šeků",
  Title_Setup:"Nastavení šeků",
  PayPal_Default_Email_Message:"Obdrželi jste nový převod PayPal. [Powered by Cheques]",
  Cheques_App_Export:"Exportováno šekem",
  Post_Intro_Thanks:"Děkujeme, že jste si vybrali šeky. Proces nastavení je nyní dokončen.",
  Post_Intro_Word:"Začněte vytištěním prvního šeku, přidáním budoucí platby nebo přidáním zaměstnance na výplatní pásku.",
  Upgrade_Required:"Šeky vyžadují, abyste vlastnili prémiovější verzi softwaru, abyste skryli tuto zprávu a odemkli další funkce.",
  Upgrade_Title:"Šeky",
  Mailout_Prompt:"Dodatečně si můžete zvolit, aby za vás šeky zasílal šeky.",
  Mailed_Cheque:"Poštovní šek: ",
  Mailed_Cheque_Color:"Poštou šek (barva): ",
  Terms_Purchase:"Všechny elektronické nákupy pomocí šeků jsou plně vratné po dobu až 30 dnů od data nákupu. Než budete pokračovat, přečtěte si a odsouhlaste <a href='#'>Smluvní podmínky</a>.",
  Dashboard_Setup:"Nastavení primární tiskárny",
  Dashboard_Add:"Přidat primární bankovní účet",
  Dashboard_Customize:"Vyberte kontrolní šablonu",
  Dashboard_Job_Salary:"Vytvořte si práci a přidejte svůj plat",
  Dashboard_Employees:"Sledování zaměstnanců a příjemců",
  Dashboard_Print:"Vytiskněte a odešlete své výplaty poštou",
  Dashboard_Payroll:"Automatizujte tisk mezd",
  Dashboard_PayPal:"Nastavit PayPal mzdy / výplaty",
  Begin_Setup:"Zahájit nastavení",
  Get_Started:"Začít",
  Purchase:"Nákup",
  Lockdown:"Izolování",
  Unlock:"Odemknout",
  Detailed:"Detailní",
  Log_In:"Přihlásit se",
  Login:"Přihlásit se",
  Launch:"Zahájení",
  Register:"Registrovat",
  Finish:"Dokončit",
  List:"Seznam",
  Weekends:"víkendy",
  Weekly:"Týdně",
  PayPal_Default_Subject:"Nová platba",
  Payment_Message:"Platební zpráva",
  PayPal_Default_Payment_Note:"Děkuju",
  Setup_Your_Cheqing_Account:"Kontrolní účet",
  Add_Your_Primary_Cheqing_Account:"Přidejte svůj primární účet cheqing.",
  Welcome_Word:"Zjednodušte a automatizujte mzdovou agendu a řízení lidských zdrojů.",
  Get_Started_Quickly:"Stačí odpovědět na pár jednoduchých otázek, které nám pomohou začít...",
  Done_Intro_Word:"Nastavení dokončeno",
  PIN_Protected:"Chráněno heslem a PINem",
  Enter_New_PIN:"Zadejte nový PIN kód:",
  Enter_PIN:"Zadejte PIN kód:",
  Invalid_PIN:"Zadán neplatný PIN.",
  Account_Identifier:"Identifikátor účtu",
  New_Account_Identifier:"Identifikátor nového účtu",
  attempt:"pokus",
  attempts:"pokusy",
  remaining:"zbývající",
  Language:"Jazyk",
  languages:"Jazyky",
  select_languages:"Zvolte jazyk",
  Deposit:"Vklad",
  Hire_One_Now:"Hire One Now",
  App_Locked:"Aplikace je uzamčena.",
  Skip_:"Přeskočit",
  Skip_to_Dashboard:"Přeskočit na řídicí panel",
  Dashboard:"Přístrojová deska",
  Import:"Import",
  Admin:"Administrátoři",
  New_Admin:"Nový správce",
  Settings:"Nastavení",
  Color_Picker:"Výběr barvy",
  Font_Picker:"Výběr písma",
  Logout:"Odhlásit se",
  Close:"Zavřít",
  Close_menu:"Zavřít",
  Excel:"Soubor Excel",
  Csv:"Soubor CSV",
  Sql:"Soubor SQL",
  Json:"Soubor JSON",
  Url:"Importovat podle URL",
  Back:"Zadní",
  Timers:"Časovače",
  Cheque:"Šek",
  Print:"Tisk",
  Designs:"Designy",
  Pause_Printing:"Pozastavit tisk",
  Resume_Printing:"Obnovit tisk",
  Printing_Paused:"Tisk pozastaven",
  PrintingUnavailable:"Omlouváme se! Tisk v tomto systému není k dispozici.",
  Prints_Paused:"Tisky pozastaveny",
  Administration:"Správa",
  Loading:"Načítání",
  Unnamed:"Bezejmený",
  error:"Je nám líto, tento šek nelze otevřít pro zobrazení.",
  No_Cheques_To_Print:"Žádné kontroly k tisku",
  No_Cheques_To_Print_Word:"Vytvořit <a routerLink='/folder/Cheque/New'>Nový šek</a>.",
  New_Cheque:"Nový šek",
  Start_One_Now:"Začněte hned teď",
  Edit_Cheque:"Kontrola úprav",
  Select_Cheques:"Vybrat kontroly",
  Select_Employee:"Vybrat zaměstnance",
  Default_Printer:"Výchozí tiskárna",
  Reassign:"Změnit přiřazení",
  Configure:"Konfigurovat",
  Template:"Šablona",
  Designer:"Návrhář",
  Edit_Designs:"Upravit návrhy",
  New_Design:"Nový design",
  Next:"Další",
  Save:"Uložit",
  Name:"Název",
  Mail:"Pošta",
  Amount:"Množství",
  Date:"Datum",
  PayPal:"PayPal",
  Payouts:"Výplaty",
  PayPal_Label:"PayPal Label",
  Email_Username:"E-mail / uživatelské jméno",
  Client_ID:"ID klienta",
  Sandbox_Email:"E-mail Sandbox",
  PayPal_Email:"E-mail PayPal",
  PayPal_Username:"Uživatelské jméno API",
  PayPal_Payouts:"Výplaty přes PayPal",
  Select_PayPal_Key:"Vyberte klíč PayPal",
  Secret:"Tajný",
  API_Secret:"tajemství API",
  PayPal_API_Keys:"PayPal Keys",
  New_PayPal_Key:"Nový klíč PayPal",
  Email_Subject:"Předmět emailu",
  Email_Message:"E-mailová zpráva",
  Payout_Options:"Možnosti výplaty",
  Payment_Note:"Poznámka k platbě",
  Enable_Employee:"Povolit zaměstnance",
  Enable_Production_Mode:"Povolit produkční režim",
  Sandbox_Username:"Uživatelské jméno pískoviště",
  Sandbox_Signature:"Sandbox Signbox",
  Sandbox_Password:"Heslo pískoviště",
  Production_Username:"Produkční uživatelské jméno",
  Production_Signature:"Výrobní podpis",
  Production_Password:"Výrobní heslo",
  Production_Email:"Výrobní e-mail",
  API_Client_ID:"ID klienta API",
  API_Signature:"Podpis API",
  API_Password:"Heslo API",
  API_Username:"Uživatelské jméno API",
  Secret_Key:"Tajný klíč",
  Sandbox:"Sandbox",
  Production:"Výroba",
  Sandbox_Keys:"Klíče pískoviště",
  Production_Keys:"Výrobní klíče",
  API_Title:"Název API",
  Production_Mode:"Výrobní režim",
  Account_Label:"Štítek účtu",
  No_PayPal_Setup:"Žádný klíč PayPal",
  Enable_PayPal_Account:"Povolit účet PayPal",
  No_PayPal_Word:"Přidejte svůj <a routerLink='/folder/Invoice/New'>PayPal Klíč API</a>.",
  Printed_Memo:"Tištěná poznámka",
  Employee:"Zaměstnanec",
  View_Employee:"Zobrazit zaměstnance",
  Mailing_Address:"Emailová adresa",
  Company_Address:"Adresa společnosti",
  Select_Company_Address:"Vyberte adresu společnosti",
  Address:"Adresa",
  Any_Day:"Každý den",
  Address_Name:"Název adresy",
  Unit:"Jednotka",
  Account:"Účet",
  Bank_Account:"Bankovní účet",
  Account_Limits:"Povolit limity účtu",
  Payroll:"Výplatní páska",
  New_Payroll:"Nová výplatní listina",
  No_Payroll:"Žádná nadcházející výplata",
  Upcoming_Holiday:"Nadcházející prázdniny:",
  Invoice_Due:"Splatná faktura:",
  New_Invoice:"Nová faktura",
  No_Invoices:"Žádné faktury",
  No_Invoices_Word:"Vytvořte první <a routerLink='/folder/Invoice/New'>Faktura</a>.",
  Cheque_Due:"Splatný šek:",
  Payrolls:"Mzdy",
  Sandbox_Mode:"Režim Sandbox",
  Hire:"Pronájem",
  Pay:"Platit",
  New:"Nový",
  Add:"Přidat",
  Make:"Udělat",
  Time:"Čas",
  Write:"Napsat",
  Holiday:"Dovolená",
  Holidays:"Dovolená",
  Next_Holiday:"Příští prázdniny:",
  All_Done:"Vše hotovo!",
  Employees:"Zaměstnanci",
  Payees:"Příjemci",
  Job:"Práce",
  Jobs:"Pracovní místa",
  Invoice:"Faktura",
  Invoices:"Faktury",
  Vacations:"Dovolená",
  Cheques:"Kontroly",
  Brand_Cheques:"Značka",
  Payment:"Způsob platby",
  Enable_Payment:"Povolit platbu",
  Payments:"Platby",
  Schedule:"Plán",
  Schedules:"Rozvrhy",
  Timesheet:"Rozvrh hodin",
  Timesheets:"Časové výkazy",
  Salary:"Plat",
  New_Address:"Nová adresa",
  Address_2:"2. řádek adresy)",
  _City:"Město",
  _State:"Stát/Provin",
  City:"Město / obec",
  State:"Stát/provincie",
  Postal:"Poštovní směrovací číslo",
  ZIP:"Pošta / PSČ",
  Country:"Země",
  Addresses:"Adresy",
  Required_Options:"Požadované možnosti",
  Optional_Options:"Volitelné možnosti",
  Additional_Options:"Další možnosti",
  Required:"Požadované",
  Optional:"Volitelný",
  Additional:"Další",
  No_Addresses:"Žádné adresy",
  New_Address_Word:"Přidejte první <a routerLink='/folder/Address/New'>Adresa</a>.",
  Select_Address:"Vybrat adresu",
  No_Address:"Žádné adresy",
  Print_Cheque:"Kontrola tisku",
  Print_Cheque_Now:"Vytisknout zkontrolovat nyní",
  Description:"Popis",
  Pay_To_The_Order_Of:"Zaplaťte podle objednávky:",
  Select_Date_Range:"Vybrat časové období",
  Select_Starting_Date:"Vyberte datum zahájení",
  Select_Ending_Date:"Vyberte datum ukončení",
  Select_Date:"Vybrat datum",
  Cheque_Date:"Datum kontroly",
  Cheque_Memo:"Zkontrolovat poznámku",
  Blank_Cheque:"Prázdný šek",
  Blank:"Prázdný",
  No_Cheques:"Žádné kontroly",
  No_Cheques_Word:"Vytiskněte svůj první <a routerLink='/folder/Cheque/New'>šek</a>.",
  Cheque_Amount_Placeholder:"0,00 $",
  View_Image:"Zobrazit obrázek",
  View:"Pohled",
  Modify:"Modifikovat",
  Delete:"Vymazat",
  Cheque_Paid:"Zaplaceno",
  New_Deduction:"Nový odpočet",
  Title:"Titul",
  Frequency:"Frekvence",
  Hourly:"Hodinově",
  Daily:"Denně",
  Weekdays:"pracovní dny",
  BiWeekly:"2 týdny",
  TriWeekly:"3 týdny",
  Monthly:"Měsíční",
  MiMonthly:"2 měsíce",
  Quarterly:"Čtvrtletní",
  Yearly:"Roční",
  Every_Week:"Každý týden",
  Every_Payroll:"Každá výplatní listina",
  Every_Month:"Každý měsíc",
  Annually:"Každoročně",
  Always_Scheduled:"Vždy naplánováno",
  Start_Date:"Datum zahájení",
  End_Date:"Datum ukončení",
  Start_Time:"Doba spuštění",
  End_Time:"Čas konce",
  Deduction_Label:"Dedukční štítek",
  Notes:"Poznámky",
  Options:"Možnosti",
  Enable:"Umožnit",
  Select_Deductions:"Vybrat odpočty",
  Deductions:"Odpočty",
  No_Deductions:"Žádné srážky",
  No_Deductions_Word:"Vytvořte svůj první <a routerLink='/folder/Deduction/New'>Odpočet</a>.",
  New_Employee:"Nový zaměstnanec",
  No_Title:"Bez názvu",
  _Name:"Název",
  About_Yourself:"O sobě",
  Full_Name:"Celé jméno",
  Birthday:"Narozeniny",
  Email:"E-mailem",
  SMS:"SMS",
  Phone:"Telefon",
  Test:"Test",
  Call:"Volání",
  Fax:"Fax",
  Printed_Note:"Tištěná poznámka",
  Position:"Pozice",
  Job_Position:"Pracovní pozice",
  Select_a_Job:"Vyberte práci",
  Select_a_Salary:"Vyberte plat",
  Add_a_Deduction:"Přidat odpočet",
  Taxes:"daně",
  Add_Taxes:"Přidat daně",
  Date_of_Birth:"Datum narození",
  Email_Address:"Emailová adresa",
  Phone_Number:"Telefonní číslo",
  Phone_Call:"Telefonát",
  Enable_on_Payroll:"Povolit na výplatní listině",
  Select_Employees:"Vybrat zaměstnance",
  No_Employees:"Žádní zaměstnanci",
  No_Employees_Word:"Přidejte svého prvního nového <a routerLink='/folder/Employee/New'>Zaměstnanec</a>.",
  No_Name:"Beze jména",
  Unemployeed:"Bez práce",
  Employeed:"Zaměstnán",
  Paid:"Zaplaceno",
  Enabled:"Povoleno",
  Disabled:"Zakázáno",
  Fire:"Oheň",
  Not_Available:"Není dostupný",
  Not_Available_Word:"Vytiskněte svou první <a routerLink='/folder/Invoice/New'>fakturu</a> a získejte zaplaceno.",
  Select_Invoices:"Vybrat_faktury",
  Print_Invoice_Word:"Vytiskněte svou první <a routerLink='/folder/Invoice/New'>fakturu</a> a získejte zaplaceno.",
  Invoice_Title:"Název faktury",
  Invoice_Date:"Datum faktury",
  Due_Date:"Datum splatnosti",
  New_Job:"Nová práce",
  Details:"Podrobnosti",
  Customize:"Přizpůsobit",
  Customize_Dashboard:"Přizpůsobit řídicí panel",
  Components:"Komponenty",
  No_Components:"Žádné součásti",
  Main_Components:"Hlavní komponenty",
  Smaller_Components:"Menší součásti",
  Error_Loading_Page:"Chyba při načítání této stránky.",
  Bank_Details:"Bankovní detaily",
  About_Your_Job:"O vaší práci",
  Your_Schedule:"Tvůj rozvrh",
  Job_Title:"Pracovní pozice",
  Job_Description:"Popis práce",
  Job_Details:"Podrobnosti o práci",
  Enable_Job:"Povolit úlohu",
  Pay_Period:"Platební období",
  Perpetually_Schedule:"Perpetually Schedule",
  Select_Jobs:"Vybrat úlohy",
  No_Jobs:"Žádná práce",
  Add_Jobs:"Přidat úlohy",
  No_Jobs_Word:"Přidejte do seznamu první <a routerLink='/folder/Job/New'>Práce</a>.",
  Count_Employees:"job.employee_count+' Employees'",
  Zero_Employees:"0 zaměstnanců",
  New_Leave:"Nová dovolená",
  Leave_Name:"Nechte jméno",
  Paid_Leave:"Placené volno",
  Leave_Pay:"Nechat zaplatit",
  Indefinite_Leave:"Dovolená na dobu neurčitou",
  Indefinite_Payroll:"Neurčitá výplatní listina",
  Leave:"Odejít",
  Add_Schedules:"Přidat plány",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Povolit nepřítomnost",
  Select_Leaves:"Vybrat listy",
  No_Leaves:"Žádné listy nepřítomnosti",
  Leave_Of_Absence:"Dovolená v nepřítomnosti",
  Leaves_Of_Absence:"Listy nepřítomnosti",
  New_Leave_of_Absense:"Nová dovolená v nepřítomnosti",
  No_Leaves_Word:"Přidat první <a routerLink='/folder/Leave/New'>Nepřítomnost</a>.",
  Not_Enabled:"Nepovoleno",
  Absences:"Absence",
  Payee:"příjemce platby",
  New_Payee:"Nový příjemce",
  Payee_Identifier:"Identifikátor příjemce platby",
  Payee_Name:"Jméno příjemce platby",
  Payee_Title:"Titul příjemce platby",
  Payment_Memo:"Oznámení o platbě",
  Select_Payees:"Vybrat příjemce",
  No_Payees:"Žádní příjemci",
  Add_Payee_Note:"Přidat prvního <a routerLink='/folder/Payee/New'>příjemce platby</a>.",
  New_Payees:"Noví příjemci",
  About_The_Payment_Schedule:"Platební kalendář",
  Your_Payroll_Schedule:"Automatická výplatní páska",
  New_Payment:"Nová platba",
  Identifier:"Identifikátor",
  Select:"Vybrat",
  Memo:"Memo",
  Payment_Date:"Datum splatnosti",
  Mark_as_Paid:"Označit jako zaplacené",
  Select_Payments:"Vybrat platby",
  No_Payments:"Žádné platby",
  No_Payments_Word:"Vytvořte první <a routerLink='/folder/Payment/New'>Platbu</a>.",
  Create_Payroll:"Vytvořit mzdu",
  Properties:"Vlastnosti",
  Payroll_Title:"Mzdový titul",
  Payroll_Notes:"Výplatní listy",
  Payroll_Setup:"Nastavení mezd",
  Tabulate_Payments:"Tabulkové platby",
  Tabulate:"Tabelovat",
  By:"Podle:",
  Payments_By:"Platby podle",
  Timesheets_And_Schedules:"Časové rozvrhy a rozvrhy",
  Both:"Oba",
  Items:"Položky",
  Add_Payees:"Přidat příjemce",
  Add_Account:"Přidat účet",
  Enable_Account:"Povolit účet",
  Enable_Payroll:"Povolit výplatní pásku",
  Print_Payroll:"Tisk mezd",
  No_Payrolls:"Žádná výplatní listina",
  No_Payroll_Word:"Vytvořte si první <a routerLink='/folder/Payroll/New'>Výplatní listinu</a>.",
  View_more:"UKÁZAT VÍCE",
  Less:"MÉNĚ",
  Add_Payroll:"Přidat výplatní pásku",
  Select_Payroll:"Vybrat mzdy",
  About_Your_Salary:"O vašem platu",
  Add_Salaries:"Přidat platy",
  Add_Salary:"Přidat plat",
  Salaries:"Platy",
  No_Salaries:"Žádné platy",
  No_Salaries_Word:"Přidat první <a routerLink='/folder/Salary/New'>Plat</a>.",
  Select_Salaries:"Vybrat platy",
  New_Salary:"Nový plat",
  Salary_Name:"Identifikátor platu",
  Enable_Salary:"Povolit plat",
  Salary_Amount:"Výše mzdy",
  New_Schedule:"Nový rozvrh",
  Schedule_Title:"Název rozvrhu",
  Add_Address:"Přidat adresu",
  Repeat:"Opakovat",
  Design:"Design",
  Edit_Design:"Upravit design",
  Edit_this_Design:"Upravit tento design",
  Repeat_Payment:"Opakovaná platba",
  Enable_Schedule:"Povolit plán",
  Never:"Nikdy",
  Select_Schedule:"Vybrat plány",
  No_Schedules:"Žádné plány",
  No_Schedules_Word:"Vytvořte první <a routerLink='/folder/Schedule/New'>Plán</a>.",
  New_Administrator:"Nový správce",
  Username:"Uživatelské jméno",
  First_Name:"Jméno",
  Last_Name:"Příjmení",
  Add_an_Address:"Přidat adresu",
  Payment_Limit:"Limit na platbu",
  Total_Limit:"Celkový limit",
  Select_Accounts:"Vybrat účty",
  No_Administrators:"Žádní správci",
  No_Administrators_Word:"Vytvořte první <a routerLink='/folder/Administrator/New'>účet správce</a>.",
  New_Administrators_Word:"Přidat prvního <a routerLink='/folder/Administrator/New'>Administrator</a>",
  Cloud:"Mrak",
  Backup:"Záloha",
  Enable_iCloud:"Povolit iCloud",
  Enable_Google_Drive:"Povolit Disk Google",
  Enable_Microsoft_OneDrive:"Povolit Microsoft OneDrive",
  Automatically_Backup:"Automaticky zálohovat",
  FTP_Settings:"Nastavení FTP",
  URL_File_Prompt:"Uveďte prosím umístění souboru .xls / .xlsx / .json k importu:",
  URL_SQL_Prompt:"Upřesněte prosím umístění souboru SQLite k importu:",
  FTP_Backup:"Záloha FTP",
  FTP_Import:"Import FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Hostitel",
  Port:"Přístav",
  Path:"Cesta",
  Data_Path:"Datová cesta",
  Enable_FTP_Account:"Povolit FTP účet",
  HostnameIP:"Název hostitele",
  Password:"Heslo",
  Connection_Port:"Připojovací port",
  Enable_MySQL_Database:"Povolit databázi MySQL",
  Log:"Protokol",
  Reset:"Resetovat",
  Erase:"Vymazat",
  Export:"Vývozní",
  Database:"Databáze",
  Upload_CSV:"Nahrát CSV",
  Download_CSV:"Stáhnout CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Záloha MySQL",
  Internal_Notes:"Interní poznámky",
  Root_Path:"Kořenová cesta",
  Select_Backup:"Vybrat zálohu",
  Add_New_Backup:"Přidat novou zálohu",
  First_Backup:"Nastavit první zálohu...",
  Backups:"Zálohy",
  Add_Backup:"Přidat zálohu",
  No_Backups:"Nebyly nalezeny žádné zálohy.",
  Select_Backup_Type:"Vyberte typ zálohy, kterou chcete nastavit...",
  Backup_Type:"Typ zálohy",
  FTP_Drive:"FTP disk",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Řídit",
  Microsoft_OneDrive:"Řídit",
  Import_Fields:"Importovat pole",
  Import_Fields_Word:"V této části vyberte <a routerLink='/folder/Holiday/New'>Importovat</a> data.",
  Upload:"Nahrát",
  Download:"Stažení",
  Download_JSON:"Stáhnout jako data JSON",
  Download_SQL:"Stáhnout jako soubor SQL",
  New_Bank:"Nová banka",
  New_Account:"Nový účet",
  New_Bank_Account:"Nový bankovní účet",
  Upload_Image:"Nahrát obrázek",
  Bank_Name:"Jméno banky",
  Bank_Address:"Adresa banky",
  Branch_Address:"Adresa pobočky",
  Address_on_Cheque:"Adresa",
  Cheque_Numbers:"Kontrola čísel",
  Cheque_Details:"Zkontrolovat podrobnosti",
  Bank_Logo:"Logo banky",
  Cheque_ID:"Zkontrolovat ID",
  Starting_Cheque_ID:"Začínající kontrolní ID",
  Transit_Number:"Číslo tranzitu",
  Institution_Number:"Číslo instituce",
  Designation_Number:"číslo označení",
  Account_Number:"Číslo účtu",
  Limits:"Limity",
  Default_Limits:"Výchozí limity",
  Over_Limit:"Přes limit",
  Under_Limit:"Pod limitem",
  Payroll_Limit:"Mzdový limit",
  Enable_Bank_Account:"Povolit bankovní účet",
  Select_Account:"Vybrat účet",
  No_Bank_Account:"Žádný bankovní účet",
  No_Bank_Account_Word:"Přidat první <a routerLink='/folder/Accounts/New'>bankovní účet</a>.",
  Bank_Accounts:"Bankovní účty",
  No_Accounts:"Žádné účty",
  No_Accounts_Note:"Přidat první <a routerLink='/folder/Accounts/New'>bankovní účet</a>.",
  Cheque_Designer:"Zkontrolovat návrháře",
  Cheque_Design:"Zkontrolovat design",
  Select_Design:"Vyberte design",
  Cheque_Designs:"Zkontrolovat návrhy",
  No_Cheque_Designs:"Návrhy bez kontroly",
  No_Cheque_Designs_Word:"Vytvořte si vlastní <a routerLink='/folder/Settings/Cheque/Design/New'>Zkontrolujte Design</a>.",
  Set_Default:"Nastavit jako výchozí",
  Default:"Výchozí",
  Remove:"Odstranit",
  Folder:"Složka",
  Edit:"Upravit",
  LoadingDots:"Načítání...",
  Add_a_New_File:"Přidat <a href='#' (click)='add()'>Nový soubor</a> do",
  this_folder:"tato složka",
  FTP_Backup_Settings:"Nastavení zálohování FTP",
  Secure_File_Transfer:"Zabezpečený přenos souborů",
  New_Holiday:"Nový svátek",
  Add_Holiday:"Přidat svátek",
  Holiday_Name:"Jméno dovolené",
  Additional_Pay:"Příplatek",
  Enable_Holiday:"Povolit dovolenou",
  Select_Holidays:"Vybrat svátky",
  No_Holidays:"Žádné prázdniny",
  No_Holidays_Word:"Přidat první <a routerLink='/folder/Holiday/New'>Veřejná dovolená</a>.",
  New_Import:"Nový import",
  Import_Data:"Importovat data",
  Import_Data_Word:"Vyberte typ souboru nebo způsob nahrávání podle svého výběru.<br /> Po nahrání podporovaného souboru budete moci vybrat, která importovaná pole v souboru odpovídají jakémukoli parametru v aplikaci.", 
  Import_File:"Importovat soubor",
  Link_To_File:"Odkaz na soubor",
  Select_File:"Zvolte soubor",
  New_Moderator:"Nový moderátor",
  Allow_Moderation:"Povolit moderování",
  Create_Paycheques:"Vytvořit výplatní pásku",
  Edit_Paycheques_and_Data:"Upravit výplaty a data",
  Destroy_Data_and_Paycheques:"Zničit data a výplaty",
  Bonus_Percentage:"Procento výplaty",
  Fixed_Amount:"Pevná částka",
  Select_Moderator:"Vybrat moderátora",
  No_Moderators:"Žádní moderátoři",
  Moderators:"Moderátoři",
  Moderator_Account:"Vytvořte první <a routerLink='/folder/Administrator/New'>účet moderátora</a>.",
  No_Moderators_Word:"Přidat prvního <a routerLink='/folder/Administrator/New'>Moderator</a>.",
  Defaults:"Výchozí",
  Provide_Us_Info:"Poskytněte nám informace",
  Setup_Your_Printer:"Nastavení tiskárny",
  Your_Company:"O vaší společnosti",
  Company_Name:"Jméno společnosti",
  Currency:"Měna",
  Default_Currency:"Výchozí měna",
  Base_Monthly_Income:"Měsíční příjem",
  Protection:"Ochrana",
  App_Protection:"Ochrana aplikací",
  PIN_Code_Protection:"Ochrana PIN kódem",
  App_Protection_Word:"Povolte metody zabezpečení, které pomáhají chránit váš účet.",
  PIN_Code:"PIN kód",
  Change_PIN:"Změnit PIN",
  New_Password:"Nové heslo",
  Geofence_Protection:"Ochrana geoplotu",
  Geofence_Area:"Nastavit oblast",
  Distance:"Vzdálenost",
  Setup_Now:"Nastavit nyní",
  Mile:"Míle",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Rozpoznávání obličeje",
  Face:"Tvář",
  Setup:"Založit",
  Label:"Označení",
  Password_Protection:"Ochrana heslem",
  Modify_Password:"Upravit heslo",
  New_Tax_Entry:"Nové daňové přiznání",
  New_Tax:"Nová daň",
  Tax_Label:"daňový štítek",
  Perpetually_Enabled:"Trvale povoleno",
  Select_Taxes:"Vybrat daně",
  Tax_Deductions:"Daňové odpočty",
  No_Tax_Deductions:"Žádné daňové odpočty",
  No_Tax_Deductions_Word:"Přidejte první <a routerLink='/folder/Tax/New'>daňový</a> odpočet.",
  New_Timer:"Nový časovač",
  Start:"Start",
  Stop:"Stop",
  Start_Timer:"Spustit časovač",
  Stop_Timer:"Zastavit časovač",
  Timer_Active:"Časovač aktivní",
  Timer:"Časovač:",
  Timer_Running:"Časovač: (běží)",
  Save_Timer:"Uložit časovač",
  New_Timesheet:"Nový časový rozvrh",
  Select_Timesheets:"Vybrat časové rozvrhy",
  Work_Notes:"Pracovní poznámky",
  Entry_Title:"Vstupní název",
  No_Timesheets:"Žádné časové rozvrhy",
  No_Timesheets_Word:"Přidat první <a routerLink='/folder/Timesheet/New'>Rozvrh hodin</a>.",
  Timesheet_Submitted:"Časový výkaz předložen",
  Timesheet_Congrats:"Gratulujeme! Váš časový rozvrh byl úspěšně odeslán. Děkujeme!",
  Timesheet_Copy:"Chcete-li obdržet kopii svých dokumentů, poskytněte nám svou e-mailovou adresu a/nebo číslo mobilního telefonu.",
  Submit:"Předložit",
  Allow_Notifications:"Povolit upozornění",
  Untitled_Entry:"Nový záznam",
  Untitled_Bank:"Banka bez názvu",
  Timesheet_Entry:"Zápis do časového rozvrhu",
  Work_Description:"Popis práce",
  Enable_Timesheet:"Povolit časový rozvrh",
  Submit_Timesheet:"Odeslat časový rozvrh",
  Vacation:"Dovolená",
  New_Vacation:"Nová dovolená",
  Vacation_Name:"Název dovolené",
  Paid_Vacation:"Placená dovolená",
  Vacation_Pay:"Platba za dovolenou",
  Enable_Vacation:"Povolit dovolenou",
  Select_Vacations:"Vybrat dovolenou",
  No_Vacations:"Žádné prázdniny",
  No_Vacations_Word:"Vytvořte první položku <a routerLink='/folder/Vacation/New'>Dovolená</a>.",
  Payroll_Schedule:"Mzdový rozvrh",
  Next_Payroll:"Další výplatní páska:",
  Upcoming_Payroll:"Nadcházející výplatní listina",
  No_Upcoming_Payroll:"Žádná nadcházející výplata",
  Address_Book:"Adresář",
  Archived_Documents:"Archivované dokumenty",
  Dev_Mode:"Aplikace v režimu vývoje",
  Administrators:"Administrátoři",
  Privacy:"Soukromí",
  None:"Žádný",
  Select_Signature:"Vybrat podpis",
  No_Signatures:"Žádné podpisy",
  No_Signatures_Word:"Přidat první <a routerLink='/folder/Signature/New'>Podpis</a>.",
  Repeat_Indefinitely:"Opakovat donekonečna",
  Sign:"Podepsat",
  Sign_Here:"Zde se podepište",
  Date_Signed:"Datum podepsání",
  Signature_Pad:"Podpisová podložka",
  Account_Holder:"Majitel účtu",
  Account_Properties:"Vlastnosti účtu",
  Name_On_Cheque:"Jméno na šeku",
  Server_Hostname:"Název hostitele / IP serveru",
  Printers:"Tiskárny",
  No_Printers:"Žádné tiskárny",
  Printer_Exists:'Tiskárna s tímto názvem již existuje.',
  No_Printers_Word:"Přidat úplně první <a routerLink='/folder/Printer/New'>Tiskárna</a>.",
  No_Printer_Alert:"Není definována žádná tiskárna. Chcete nastavit tiskárnu?",
  Add_Printer:"Přidat tiskárnu",
  New_Printer:"Nová tiskárna",
  Printer_Hostname:"Název hostitele tiskárny / IP",
  Printer_Label:"Štítek tiskárny",
  Printer_Protocol:"Protokol tiskárny",
  Protocol:"Protokol",
  Email_Print:"E-mailem",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Zásuvka",
  Print_Job:"Tisková úloha",
  Printed:"Tištěno",
  Not_Printed:"Nevytištěno",
  Print_Jobs:"Tiskové úlohy",
  Print_Queue:"Tisková fronta",
  No_Print_Jobs:"Žádné tiskové úlohy",
  No_Prints:"Vytvořte nový <a routerLink='/folder/Cheque/New'>šek</a> k tisku.",
  Prints:"Tisky",
  Find_Printer:"Najít tiskárnu",
  Find_AirPrint_Devices:"Najít zařízení AirPrint",
  Select_Printer:"Vybrat tiskárnu",
  System_UI:"uživatelské rozhraní systému",
  Printer:"Tiskárna",
  Status:"Postavení",
  Preview:"Náhled",
  Enable_Print_Job:"Povolit tiskovou úlohu",
  Queue_Weight:"Hmotnost fronty",
  Unlimited:"Neomezený",
  Show_Advanced_Printer_Options:"Zobrazit pokročilé možnosti tiskárny",
  Advanced:"Pokročilý",
  Location:"Umístění",
  Note:"Poznámka",
  Queue_Name:"Název fronty",
  Pages_Printed_Limit:"Limit vytištěných stránek",
  MultiPage_Idle_Time:"Doba čekání na více stránek (s)",
  Page_Count_Limit:"Limit počtu stránek",
  Page_Orientation:"Orientace stránky",
  Portrait:"Portrét",
  Landscape:"Krajina",
  Duplex:"Duplex",
  Double_Sided_Printing:"Oboustranný",
  Duplex_Mode:"Duplexní režim",
  Duplex_Short:"Krátký",
  Duplex_Long:"Dlouho",
  Duplex_None:"Žádný",
  Color_And_Quality:"Barva a kvalita",
  Monochrome:"Černobílý",
  Photo_Quality_Prints:"Tisky ve fotografické kvalitě",
  Printer_Email:"E-mail tiskárny",
  Automatically_Downsize:"Automaticky zmenšit",
  Paper:"Papír",
  Paper_Name:"Název papíru",
  Paper_Width:"Šířka papíru",
  Paper_Height:"Výška papíru",
  Paper_Autocut_Length:"Délka automatického ořezu papíru",
  Margins:"Okraje",
  Page_Margins:"Okraje stránky",
  Page_Margin_Top:"Okraj horní stránky",
  Page_Margin_Right:"Pravý okraj stránky",
  Page_Margin_Bottom:"Okraj spodní stránky",
  Page_Margin_Left:"Levý okraj stránky",
  Add_Employees:"Přidat zaměstnance",
  Header:"Záhlaví",
  Print_A_Page_Header:"Vytisknout záhlaví stránky",
  Header_Label:"Štítek záhlaví",
  Header_Page_Index:"Rejstřík záhlaví stránky",
  Header_Font_Name:"Písmo záhlaví",
  Select_Font:"Vybrat písmo",
  Font_Selector:"Výběr písma",
  Header_Font_Size:"Písmo záhlaví",
  Header_Bold:"Tučné záhlaví",
  Header_Italic:"Záhlaví kurzíva",
  Header_Alignment:"Zarovnání záhlaví",
  Left:"Vlevo, odjet",
  Center:"Centrum",
  Right:"Že jo",
  Justified:"Odůvodněné",
  Header_Font_Color:"Barva záhlaví",
  Select_Color:"Vybrat barvu",
  Footer:"Zápatí",
  Print_A_Page_Footer:"Vytisknout zápatí stránky",
  Footer_Label:"Štítek zápatí",
  Footer_Page_Index:"Rejstřík zápatí stránky",
  Footer_Font_Name:"Písmo zápatí",
  Fonts:"Písma",
  Done:"Hotovo",
  Select_Fonts:"Vybrat písma",
  Footer_Font_Size:"Velikost zápatí",
  Footer_Bold:"Tučné zápatí",
  Footer_Italic:"kurzíva v zápatí",
  Footer_Alignment:"Zarovnání zápatí",
  Footer_Font_Color:"Barva zápatí",
  Page_Copies:"Kopie stránek",
  Enable_Printer:"Povolit tiskárnu",
  Remote_Logging:"Vzdálené protokolování",
  Log_Server:"Přihlašovací server",
  Encryption:"Šifrování",
  Random_Key:"Náhodný klíč",
  Encryption_Key:"Šifrovací klíč",
  Cheques_Webserver:"Vlastní databáze",
  Learn_How:"Naučte se jak",
  Signature:"Podpis",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Zobrazit podpis",
  Printed_Signature:"Tištěný podpis",
  Digitally_Sign:"Digitálně podepsat",
  Digital_Signature:"Digitální podpis",
  Add_Signature:"Přidat podpis",
  Add_Your_Signature:"Přidejte svůj podpis",
  Enable_Signature:"Povolit podpis",
  Digitally_Signed:"Digitálně podepsáno",
  Insert_Error:"Nelze uložit šek kvůli problémům s databází.",
  Delete_Confirmation:"Opravdu chcete smazat tyto informace?",
  Discard_Confirmation:"Opravdu chcete tyto informace zahodit?",
  Discard_Bank_Confirmation:"Opravdu chcete tento účet zahodit?",
  Discard_Printer_Confirmation:"Opravdu chcete tuto tiskárnu zahodit?",
  Delete_Bonus_Confirmation:"Opravdu chcete smazat tento bonus?",
  Delete_Invoice_Confirmation:"Opravdu chcete smazat tuto fakturu?",
  Generated_Cheque:"Vygenerovaný šek",
  Generated_Invoice:"Vygenerovaná faktura",
  Schedule_Start_Time:"Spuštění plánu",
  Schedule_End_Time:"Konec plánu",
  New_Call:"Nové volání",
  No_Contacts:"Žádné kontakty",
  No_Contacts_Word:"Administrátoři, moderátoři, zaměstnanci a příjemci plateb se zobrazují jako kontakty.",
  PDF_Subject:"finance",
  PDF_Keywords:"kontrolní šeky PDF výplatní páska",
  Printer_Setup:"Nastavení tiskárny",
  Printer_Selection:"Výběr tiskárny",
  New_Fax:"Nový fax",
  New_Fax_Message:"Nová faxová zpráva",
  Fax_Machine:"Fax",
  Fax_Name:"Faxové jméno",
  Fax_Email:"Faxový e-mail",
  Fax_Number:"Číslo faxu",
  Contents:"Obsah",
  Fax_Body:"Tělo stránky",
  Header_Word:"Záhlaví:",
  Header_Text:"Text záhlaví",
  Include_Header:"Zahrnout záhlaví",
  Include_Footer:"Zahrnout zápatí",
  Footer_Word:"Zápatí:",
  Footer_Text:"Text zápatí",
  Attach_a_Cheque:"Přiložit šek",
  Add_Deduction:"Přidat odpočet",
  Enable_Fax:"Odeslat fax",
  Select_Fax:"Vybrat fax",
  No_Faxes:"Žádné faxy",
  Faxes:"Faxy",
  Save_and_Send:"Odeslat fax",
  Save_and_Pay:"Uložit a zaplatit",
  WARNING:"VAROVÁNÍ:",
  Remember:"Pamatovat",
  Printing:"Tisk",
  Printing_Complete:"Tisk dokončen!\n\n",
  of:"z",
  There_Were:"Byly tam",
  Successful_Prints:"úspěšné výtisky a ",
  Unsuccessful_Prints:"neúspěšné tisky.",
  PrinterError:"Promiňte! Došlo k chybě.",
  Printing_:"Tisk...",
  PrinterSuccess:"Dokument se úspěšně vytiskl.",
  PrintersSuccess:"Dokumenty se úspěšně vytiskly.",
  Print_Cheques:"Tisk šeků",
  New_Message:"Nová zpráva",
  New_Messages:"Nové zprávy",
  Read_Message:"Přečíst zprávu",
  Write_Message:"Napsat zprávu",
  Send_Message:"Poslat zprávu",
  Subject:"Předmět",
  Message:"Zpráva",
  Writing:"Psaní...",
  Send:"Poslat",
  Set_Date:"Nastavit datum",
  Set_Time:"Nastavit čas",
  Recieve:"Přijmout",
  Set_as_Default:"Nastavit jako výchozí",
  Default_Account:"Výchozí účet",
  Default_Design:"Výchozí design",
  Multiple_Cheques:"Šeky (trojnásobné)",
  Account_Mode:"Režim účtu",
  Multiple_Cheques_Description:"Tři kontroly na stránku.",
  Check_and_Table:"Kontrola a tabulka",
  Check_including_Table:"Kontrolní a účetní tabulka.",
  Check_Mailer:"Zkontrolovat poštovní schránku",
  Check_Mailer_Window:"Zkontrolujte pomocí okna adresy.",
  DocuGard_Table_Top:"DocuGard Kontrola a stůl (horní)",
  DocuGard_Table_Middle:"DocuGard Kontrola a stůl (uprostřed)",
  DocuGard_Table_Bottom:"DocuGard Kontrola a stůl (dole)",
  DocuGard_Mailer_Top:"DocuGard Zkontrolovat poštu (nahoře)",
  DocuGard_Mailer_Middle:"DocuGard Zkontrolovat poštu (uprostřed)",
  DocuGard_Mailer_Bottom:"DocuGard Zkontrolovat poštu (dole)",
  DocuGard_Three_Cheques:"DocuGard Kontroly (trojnásobný)",
  DocuGard_Cheque_Top:"DocuGard Šek (nahoře)",
  DocuGard_Cheque_Middle:"DocuGard Šek (uprostřed)",
  DocuGard_Cheque_Bottom:"Kontrola DocuGard (dole)",
  DocuGard_Three_Cheques_Window:"Tři kontroly na jedné stránce.",
  DocuGard_Table_Top_Window:"Tabulka šeků a výdělků.",
  DocuGard_Table_Middle_Window:"Tabulka šeků a výdělků.",
  DocuGard_Table_Bottom_Window:"Tabulka šeků a výdělků.",
  DocuGard_Mailer_Top_Window:"Šek, stůl a adresa.",
  DocuGard_Mailer_Middle_Window:"Šek, stůl a adresa.",
  DocuGard_Mailer_Bottom_Window:"Šek, stůl a adresa.",
  DocuGard_Cheque_Top_Window:"Zkontrolujte zabezpečený papír.",
  DocuGard_Cheque_Middle_Window:"Zkontrolujte zabezpečený papír.",
  DocuGard_Cheque_Bottom_Window:"Zkontrolujte zabezpečený papír.",
  Basic_Cheque:"Zkontrolovat (nahoře)",
  Basic_Cheque_Print:"Vytiskněte jeden šek.",
  Error_Setting_As_Paid:"Chyba nastavení jako placené",
  Add_Attachment:"Přidat přílohu",
  PrinterUnavailable:"Tiskárna není k dispozici",
  CreditCardComponent:"Karty",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Nový vklad",
  Deposits:"vklady",
  No_Deposits:"Žádné vklady",
  Credit_Card_Deposit:"Kreditní karta",
  New_Credit_Card_Deposit_Message:"Vklad kreditní kartou",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Vklad bitcoinů",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Interac Transfer",
  Payments_Limit:"Platební limit",
  No_Payment_Limit:"Žádný platební limit",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal vklad",
  No_Deposits_Word:"Přidat první společnost <a routerLink='/folder/Deposit/New'>Vklad</a>.",
  No_Documents_Specified:"Nebyly zadány žádné dokumenty pro tisk",
  No_Printers_Added:"Nebyly nalezeny žádné tiskárny. Chcete-li jednu přidat, přejděte do Nastavení > Tiskárny.",
  DB_Erase_Prompt:"Úplně vymazat celou databázi? VAROVÁNÍ: Ztratíte všechny uložené informace!",
  Console_Warning:"Do této konzole nevkládejte žádný text. Můžete vystavit sebe a/nebo svou společnost vážnému riziku.",
  No_Faxes_Word:"Vytvořte první <a routerLink='/folder/Fax/New'>Fax</a>.",
  Cheque_Delete_Confirmation:"Opravdu chcete smazat tento šek?",
  Design_Delete_Confirmation:"Opravdu chcete smazat tento design?",
  Cheque_Pay_Confirmation:"Označit tento šek jako zaplacený? NEZOBRAZÍ se v tiskové frontě.",
  Payment_Pay_Confirmation:"Označit tuto platbu jako zaplacenou? NEZOBRAZÍ se ve frontě šeků.",
  Delete_Deduction_Confirmation:"Opravdu chcete smazat tento odpočet?",
  Delete_Job_Confirmation:"Opravdu chcete smazat tuto úlohu?",
  Delete_Timesheet_Confirmation:"Opravdu chcete smazat tento časový rozvrh?",
  Delete_Schedule_Confirmation:"Opravdu chcete smazat tento plán?",
  Delete_Setting_Confirmation:"Opravdu chcete toto nastavení resetovat?",
  Delete_Fax_Confirmation:"Opravdu chcete smazat tento fax?",
  Delete_File_Confirmation:"Opravdu chcete smazat tento soubor?",
  Delete_Vacation_Confirmation:"Opravdu chcete smazat tuto dovolenou?",
  Delete_Printer_Confirmation:"Opravdu chcete smazat tuto tiskárnu?",
  Remove_Design_Confirmation:"Opravdu chcete smazat tento design?",
  Delete_Payroll_Confirmation:"Opravdu chcete smazat tuto výplatní listinu?",
  Send_Fax_Email_Confirmation:"Chcete tyto dokumenty odeslat faxem a e-mailem?",
  Send_Email_Confirmation:"Chcete poslat tento dokument e-mailem?",
  Send_Fax_Confirmation:"Chcete tento dokument odeslat faxem?",
  Error_Generating_PDF:"Omlouváme se. Při generování tohoto dokumentu došlo k chybě.",
  PDF_Error_Saving_Image:"Omlouváme se. Při ukládání obrázku PDF tohoto dokumentu došlo k chybě.",
  Test_Printer_Confirmation:"Chcete na této tiskárně vytisknout zkušební stránku?",
  Save_Timesheet_Prompt:"Prosím přidejte 'Titul' nebo stiskněte 'Start Timer' pro uložení.",
  Remove_Geofence_Prompt:"Opravdu chcete odstranit polohu tohoto geografického plotu?",
  Delete_Employee_Confirmation:"Jste si jistí, že to chcete smazat ",
  Fire_Employee_Confirmation:"Jsi si jistý, že chceš střílet?"
}