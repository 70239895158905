export const Ar = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"حقوق النشر والنسخ ؛ 2023",
  black:"أسود",
  green:"أخضر",
  gold:"ذهب",
  blue:"أزرق",
  brown:"بني",
  purple:"أرجواني",
  pink:"لون القرنفل",
  red:"أحمر",
  Swatches:"حوامل",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"مسحة",
  Saturation:"التشبع",
  Lightness:"خفة",
  Upgrade_To_Pro:"التطور للاحترافية",
  AllFeaturesInclude:"تشمل جميع الميزات:",
  PrintUnlimitedCheques:"طباعة شيكات غير محدودة",
  PremiumChequeDesigns:"تصاميم الشيكات الممتازة",
  ManageUnlimitedEmployees:"إدارة عدد غير محدود من الموظفين",
  AddUnlimitedPayees:"أضف غير محدود من المدفوعات",
  CreateUnlimitedPayrolls:"إنشاء كشوف رواتب غير محدودة",
  UnlimitedSchedulesandTimeSheets:"جداول زمنية وجداول زمنية غير محدودة",
  BulkPayPalPayouts:"مدفوعات PayPal المجمعة",
  UnlimitedBankAccounts:"حسابات بنكية غير محدودة",
  ManageMultipleCompanies:"إدارة شركات متعددة",
  TrackInsurancePolicies:"تتبع سياسات التأمين",
  Bio_MetricProtection:"الحماية البيومترية",
  Geo_FenceLock_OutProtection:"حماية تأمين السياج الجغرافي",
  Multiple_Companies_Word:"لا تتوفر إدارة عدة شركات بدون علاوة الشيكات.",
  PayPal_Payouts_Word:"يتم تعطيل مدفوعات PayPal بدون علاوة الشيكات.",
  PINProtection:"حماية PIN",
  PasswordProtection:"حماية كلمة المرور",
  May_Require_Approval:"قد تتطلب الموافقة.",
  Subscribe:"يشترك",
  Billed:"فاتورة",
  Up:"أعلى",
  Down:"تحت",
  Positioning:"التمركز",
  Marker:"علامة",
  Drag_Marker:"اسحب ماركر",
  Tagline:"طباعة الشيكات وجدولة الرواتب",
  Marker_Word:"استخدم العلامات لتحديد حجم العنصر.",
  Pinch_Zoom:"قرصة التكبير",
  Pinch_Word:"قرصة لتكبير العنصر.",
  Drag:"يجر",
  Drag_Word:"استخدم إصبعك لسحب العناصر.",
  subscription_alias_word:"التجديد التلقائي للاشتراك",
  premium_alias_word:"حزمة ترقية لمرة واحدة",
  print_alias_word:"طباعة الشيكات الفردية",
  mode_alias_word:"وضع",
  Pro:"طليعة",
  Pro_word:"الإصدار المحترف مطلوب.",
  Cant_Delete_Default_Company_Word:"عذرا ، لا يمكنك حذف شركتك الافتراضية.",
  Reinsert_Default_Designs:"أعد إدراج التصاميم الافتراضية",
  Reinsert_Default_Designs_word:"هل تريد إعادة إدخال التصاميم الافتراضية؟",
  Subscription_Active_Disable_Word:"هذا الاشتراك نشط. هل ترغب في إلغاء هذا الاشتراك في الشيكات؟",
  Company_Logo:"شعار الشركة",
  ZERO_:"صفر",
  Disclaimer:"تنصل",
  Privacy_Policy:"سياسة الخصوصية",
  EULA:"الشيكات EULA",
  Terms_Of_Service:"شروط الخدمة",
  Terms_Of_Use:"شروط الاستخدام",
  Refunds:"سياسة الاسترجاع",
  Single_Print:"1 تحقق",
  Two_Prints:"2 الشيكات",
  Five_Prints:"5 الشيكات",
  Ten_Prints:"10 شيكات",
  Fifteen_Prints:"15 شيكات",
  Twenty_Prints:"20 شيكات",
  Thirty_Prints:"30 شيكات",
  Image_Added:"تمت إضافة الصورة",
  Image_Preview:"معاينة الصورة",
  No_Image_Was_Selected:"لم يتم اختيار أي صورة.",
  Cheques_Unlimited:"الشيكات غير محدودة",
  _Subscription:"الاشتراك",
  Subscription:"الشيكات - 1 شهر",
  Two_Month_Subscription:"الشيكات - شهرين",
  Three_Month_Subscription:"الشيكات - 3 أشهر",
  Six_Month_Subscription:"الشيكات - 6 شهور",
  Twelve_Month_Subscription:"الشيكات - 12 شهرًا",
  Cheques_Are_Available:"الشيكات متاحة للطباعة.",
  Upgrade_Required_Two:"حدد حزمة وانقر نقرًا مزدوجًا على زر السعر لبدء عملية الشراء. اطبع شيكات الألوان الكاملة ذات المظهر الاحترافي في ثوانٍ.",
  Month:"شهر",
  Due:"حق:",
  Expires:"تنتهي:",
  Subscription_Active:"الاشتراك نشط",
  Subscription_Inactive:"الاشتراك غير نشط",
  Purchase_Additional_Cheques:"شراء شيكات إضافية؟",
  Printable_Cheque:"شيك قابل للطباعة",
  Printable_Cheques:"الشيكات القابلة للطباعة",
  Printable_Cheque_Word:"الاختيار متاح في حسابك.",
  Printable_Cheques_Word:"الشيكات متوفرة في حسابك.",
  Max_Amount_Message:"تجاوز المبلغ الذي حددته الحد الأقصى للمبلغ المحدد لهذا النظام:",
  Terms_Required_Word:"يجب أن توافق على هذه الاتفاقية قبل الاستمرار في استخدام الشيكات.",
  Subscriptions:"الاشتراكات",
  Product_Upgrades:"ترقيات",
  Mailed_Out_Cheques:"الشيكات المرسلة بالبريد",
  Enter_A_Company_Name:"الرجاء إدخال اسم شركة.",
  Single_Cheques:"الشيكات الفردية",
  Cheque_Golden:"الشيك الذهبي",
  Cheque_Golden_Window:"تصميم الشيك الذهبي.",
  Cheque_Green:"الشيك الأخضر",
  Cheque_Green_Window:"تصميم الشيك الأخضر.",
  Cheque_Red:"الشيك الأحمر",
  Cheque_Red_Window:"تصميم الشيك الأحمر.",
  Cheque_Yellow:"الشيك الأصفر",
  Cheque_Yellow_Window:"تصميم الشيك باللون الأصفر.",
  Cheque_Statue_of_Liberty:"تمثال الحرية",
  Cheque_Statue_of_Liberty_Window:"تمثال الحرية بتصميم الشيك.",
  Cheque_Green_Wave:"موجة خضراء",
  Cheque_Green_Wave_Window:"تصميم الشيك الأخضر.",
  Cheque_Golden_Weave:"نسج ذهبي",
  Cheque_Golden_Weave_Window:"تصميم شيك ذهبي أنيق.",
  Cheque_Green_Sun:"جرين صن",
  Cheque_Green_Sun_Window:"تصميم فحص عميق ومهدئ.",
  Cheque_Blue_Checkers:"لعبة الداما الزرقاء",
  Cheque_Blue_Checkers_Window:"تصميم الشيك الأزرق.",
  Cashiers_Check:"شيك أمين الصندوق",
  Cashiers_Check_Window:"قالب نمط شيك أمين الصندوق.",
  Cheque_Aqua_Checkers:"لعبة الداما المائية",
  Cheque_Aqua_Checkers_Window:"تصميم تحقق أكوا.",
  Cheque_Golden_Checkers:"لعبة الداما الذهبية",
  Cheque_Golden_Checkers_Window:"تصميم الشيك الذهبي.",
  Upgrade_Unavailable:"الترقيات غير متوفرة",
  Bank_Code_Protection:"حماية رقم البنك",
  Bank_Code_Protection_Word:"احمِ أرقامك المصرفية من استخدامها على هذا التطبيق الذي يعمل على جهاز آخر أو لمستخدم آخر. هذا العمل لا رجعة فيه. يكمل؟",
  Bank_Code_Protection_Blocked_Word:"أرقام البنك التي تحاول استخدامها محجوزة لمستخدم أو جهاز آخر.",
  Bank_Code_Protection_Error_Word:"فشل التحقق من الرقم. الرجاء الاتصال بالإنترنت ومحاولة إضافة هذا الحساب المصرفي مرة أخرى.",
  Bank_Code_Protection_Set_Error_Word:"تتطلب حماية رقم البنك أن تكون متصلاً بالإنترنت. يرجى الاتصال والمحاولة مرة أخرى.",
  Upgrade_Unavailable_Word:"آسف ، نحن نواجه مشكلة في التحقق منك. الاشتراكات والترقيات للشيكات غير متاحة حاليًا للشراء في منطقتك.",
  PayPal_Payment_Preview:"معاينة PayPal للدفع",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"حدد PayPal",
  PayPal_Applications:"تطبيقات PayPal",
  Purchase_With_Apple_Pay:"الشراء مع Apple Pay",
  Google_Pay:"جوجل باي",
  Companies:"شركات",
  Insurance:"تأمين",
  New_PayPal:"PayPal جديد",
  Pay_By:"ادفع عن طريق",
  Insure:"تأمين",
  Miles:"اميال",
  Payment_Method:"طريقة الدفع او السداد",
  Select_Policies:"حدد السياسات",
  Policies:"سياسات",
  Policy:"سياسة",
  No_PayPal_Account:"لا يوجد حساب PayPal",
  No_Policies:"لا توجد وثائق تأمين",
  New_Policy:"سياسة جديدة",
  PayPal_Payment:"الدفع باي بال",
  PayPal_Payments:"مدفوعات PayPal",
  No_Payment_Selected:"لم يتم تحديد الدفع",
  Sending_Payment_Word:"الرجاء الانتظار ... جاري إرسال هذه الدفعة.",
  Sending_Payments_Word:"الرجاء الانتظار ... جاري إرسال الدفعات.",
  No_Payment_Selected_PayPal:"لم يتم تحديد <a routerLink='/folder/Payments'>دفع PayPal</a> لإرساله.",
  Payment_Sent:"ارسلت الدفعه",
  PayPal_Payment_Sent:"تم إرسال هذه الدفعة من خلال PayPal.",
  Copay:"Copay",
  Dead:"ميت",
  Alive:"على قيد الحياة",
  Not_Dead:"ليس ميت",
  Unclaimed:"غير مطالب به",
  Attempted:"حاول",
  Deceased:"فقيد",
  Claimed:"ادعى",
  Unpaid:"غير مدفوعة",
  Sending_Payment:"إرسال الدفع",
  Sending_Payments:"إرسال المدفوعات",
  Send_PayPal_Confirmation:"هل تريد إرسال هذه المعاملة مع PayPal؟",
  Send_PayPal_Confirmation_Word:"اضغط على الزر الأخضر لإرسال هذه المعاملة.",
  Save_Payment_As_Unpaid:"حفظ هذه الدفعة على أنها غير مدفوعة؟",
  Payment_Pay_Confirmation_PayPal:"حفظ هذا الدفع كما دفعت؟",
  No_Policies_Word:"أضف أول <a routerLink='/folder/Postage/New'>بوليصة تأمين</a> الآن.",
  Timesheet_Multiple_Delete_Confirmation:"هل أنت متأكد أنك تريد حذف جداول زمنية متعددة؟",
  Select_Multiple_Timesheets_Prompt:"لم يتم اختيار أي جداول زمنية. حدد جدول زمني واحد على الأقل.",
  Select_Multiple_Policies_Prompt:"لم يتم تحديد سياسات. حدد بوليصة تأمين واحدة على الأقل.",
  Policies_Multiple_Delete_Confirmation:"هل أنت متأكد أنك تريد حذف سياسات متعددة؟",
  Company:"شركة",
  Add_Company:"أضف شركة",
  New_Company:"أضف شركة",
  No_Companies:"لا توجد شركات",
  Enable_Company:"تمكين الشركة",
  Select_Company:"حدد شركة",
  The_Default_Company:"تسمية الشركة الافتراضية.",
  Manage_Companies:"إدارة الشركات",
  No_Companies_Word:"ستستخدم الشيكات شركة افتراضية. <br /> أضف <a routerLink='/folder/Company/New'>الشركة الأولى</a>.",
  Default_Company:"الشركة الافتراضية",
  Cheques_Unlimited_Word:"تتيح لك Checks Unlimited طباعة أكبر عدد تريده من الشيكات.",
  Cheques_Subscription_Word:"يتيح لك اشتراك الشيكات طباعة أكبر عدد تريده من الشيكات.",
  You_Own_This_Product:"أنت تملك هذا المنتج.",
  Your_Subscription_is_Active:"اشتراكك نشط.",
  Active_Products:"المنتجات المنشطة",
  Purchase_Confirmation:"شراء",
  Purchase_Cheques:"شراء الشيكات",
  Restore_Purchase:"استعادة المشتريات",
  Erase_Purchase:"محو المشتريات",
  Successfully_Purchased:"تم شراؤها بنجاح",
  Enter_Your_Licence_Key:"الرجاء إدخال مفتاح الترخيص الخاص بك في هذه الصفحة لتنشيط هذا المنتج.",
  Licence_Key:"مفتاح الترخيص",
  Enter_Licence_Key:"أدخل مفتاح الترخيص",
  Purchased:"تم شراؤها",
  Enable_Feature:"تمكين الميزة",
  Cancel_Subscription:"إلغاء الاشتراك",
  Subscription_Removed:"تمت إزالة الاشتراك",
  Select_Active_Subscription:"حدد اشتراكًا نشطًا لتعديله.",
  Remove_Subscription_Word:"هل أنت متأكد أنك تريد إلغاء هذا الاشتراك؟",
  Delete_Company_Confirmation:"هل أنت متأكد أنك تريد حذف هذه الشركة بأكملها؟ تحذير: ستفقد جميع المعلومات المخزنة!",
  Delete_Default_Company_Word:"المخزنة!",
  Console_Warning_2:"لا تتعامل مع أي عملة باستخدام هذا التطبيق الذي لا يخصك حاليًا.",
  Terms_and_Conditions:"الأحكام والشروط",
  and_the:"و ال",
  for:"ل",
  Please_Read_Word:"يرجى قراءة والموافقة على",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"لا يمكن العثور على بعض أسعار تحويل العملات. الرجاء الاتصال بالإنترنت.",
  Free:"حر",
  DB_Erase_Prompt_2:"هل تريد محو قاعدة بيانات المطورين بالكامل؟ تحذير: ستفقد جميع معلومات الشراء والاشتراك!",
  Successfully_Imported:"تم الاستيراد بنجاح",
  Select_Postage:"حدد طابع البريد",
  No_Postage:"لا طوابع بريدية",
  No_Paid_Postage_Word:"أضف أول طابع <a routerLink='/folder/Postage/New'>البريد المدفوع</a>.",
  Trial_Complete:'اكتملت التجربة',
  Please_Upgrade:'الرجاء ترقية الشيكات لمتابعة الطباعة.',
  Aa:"أأ",
  Color:"اللون",
  Font:"الخط",
  Guide:"مرشد",
  Guides:"خطوط إرشاد",
  Image:"صورة",
  Zoom:"تكبير",
  Logo:"شعار",
  Bank:"بنك",
  MICR:"MICR",
  Total:"المجموع",
  Cancel:"يلغي",
  Confirm:"يتأكد",
  Method:"طريقة",
  Biometric_Security:"الأمن الحيوي المتري",
  Please_Login_To_Continue:"الرجاء تسجيل الدخول للمتابعة.",
  Complete:"مكتمل",
  Sign_Up:"اشتراك",
  Error:"خطأ",
  Biometrics:"المقاييس الحيوية",
  Percent:"نسبه مئويه",
  Zero_Percent:"0٪",
  Top_Margin:"الهامش العلوي",
  Bottom_Margin:"الهامش السفلي",
  Left_Margin:"الهامش الأيسر",
  Right_Margin:"الهامش الأيمن",
  MICR_Margin:"هامش MICR",
  Table_Margin:"هامش الجدول",
  Address_Margin:"هامش العنوان",
  Percentage_:"نسبة مئوية",
  Vacation_Title:"عنوان الإجازة",
  Use_PIN:"استخدم رقم التعريف الشخصي",
  Loading__:"جار التحميل...",
  Design_Title:"عنوان التصميم",
  Authorize:"تفويض",
  Key:"مفتاح",
  Public_Key:"المفتاح العمومي",
  Private_Key:"مفتاح سري",
  Authenticate:"المصادقة",
  Last_Payroll:"آخر كشوف رواتب",
  Last_Calculation:"آخر حساب",
  Authorized:"مخول",
  Geo_Authorized:"الموقع الجغرافي: مصرح به",
  Not_Authorized:"غير مخول",
  Authorization_Complete:"اكتمل التفويض",
  Geolocation_Authorization:"ترخيص الموقع الجغرافي",
  Out_of_Bounds:"خارج الحدود",
  Cant_Delete_Default_Design:"لا يمكن حذف التصميم الافتراضي.",
  Unauthorized_Out_of_Bounds:"غير مصرح به: خارج الحدود",
  Biometric_Authorization:"ترخيص Bio-metric",
  Locating_Please_Wait:"تحديد الموقع ، يرجى الانتظار ...",
  Test_Biometrics:"اختبار المقاييس الحيوية",
  Cheque_Margins:"تحقق الهوامش",
  Percentage_Full_Error:"لا يمكن تعيين حقل النسبة المئوية على 100٪.",
  No_Payroll_Text:"أضف <a routerLink='/folder/Employee/New'>موظف</a> أو <a routerLink='/folder/Payee/New'>المدفوع لأمره</a> و <a routerLink='/folder/Schedule/New'>الجدول</a>.",
  Design_Saved:"تم حفظ التصميم",
  Default_Design_Selected:"تم تحديد التصميم الافتراضي",
  Partial_Import:"استيراد جزئي",
  Partial_Export:"تصدير جزئي",
  Entire_Import:"استيراد كامل",
  Entire_Export:"تصدير كامل",
  Existing_Password:"الرجاء إدخال كلمة المرور الحالية الخاصة بك:",
  Existing_PIN:"الرجاء إدخال رمز PIN الحالي الخاص بك:",
  Pin_Change_Header:"تأكيد PIN",
  Pin_Change_SubHeader:"أدخل رقم PIN القديم لتأكيد التغيير.",
  Pass_Change_Header:"تأكيد كلمة المرور",
  Pass_Change_SubHeader:"أدخل كلمة المرور القديمة لتأكيد التغيير.",
  PIN_Enter_Message:"أدخل رقم التعريف الشخصي الخاص بك للتأكيد.",
  Password_Enter_Message:"أدخل كلمة المرور الخاصة بك للتأكيد.",
  Pin_Updated_Success:"تم تحديث رقم التعريف الشخصي بنجاح!",
  Pin_Updated_Fail:"تعذر تحديث رقم التعريف الشخصي.",
  Pass_Updated_Success:"تم تحديث كلمة السر بنجاح.",
  Pass_Updated_Fail:"تعذر تحديث كلمة المرور.",
  Preview_Payment:"معاينة الدفع",
  Preview_Payroll:"معاينة كشوف المرتبات",
  No_Payments_Created:"لم يتم العثور على مدفوعات ليتم إنشاؤها.",
  Payment_Preview:"معاينة الدفع",
  Enable_Payee:"تمكين المدفوع لأمره",
  Rendered:"المقدمة",
  No_Email:"لا البريد الإلكتروني",
  Setup_Cheques:"إعداد الشيكات",
  name:"اسم",
  address:"تبوك",
  address_2:"سطر العنوان 2",
  city:"مدينة",
  province:"المحافظة",
  postal_code:"الرمز البريدي / الرمز البريدي",
  country:"دولة",
  username:"اسم المستخدم",
  full_name:"الاسم الكامل",
  birthday:"عيد الميلاد",
  email:"البريد الإلكتروني",
  phone:"هاتف",
  employees:"الموظفين",
  addresses:"عناوين",
  payment_amount_limit:"حد مبلغ الدفع",
  total_limit:"إجمالي الحد",
  payees:"المدفوع لهم",
  description:"وصف",
  address_line_one:"سطر العنوان الأول",
  address_line_two:"سطر العنوان الثاني",
  image:"صورة",
  account_holder:"مالك الحساب",
  cheque_starting_id:"تحقق من معرف البداية",
  transit_number:"رقم العبور",
  institution_number:"رقم المؤسسة",
  designation_number:"رقم التعيين",
  account_number:"رقم حساب",
  currency:"عملة",
  signature:"التوقيع",
  payment_payroll_limit:"حد الدفع",
  total_limi:"الحد الإجمالي",
  date:"تاريخ",
  printed_memo:"مذكرة مطبوعة",
  banks:"البنوك",
  signature_image:"صورة التوقيع",
  address_name:"اسم عنوان",
  notes:"ملحوظات",
  design:"تصميم",
  title:"عنوان",
  frequency:"تكرار",
  fax:"فاكس",
  salaries:"الرواتب",
  salary_ids:"معرفات الرواتب",
  start_time:"وقت البدء",
  end_time:"وقت النهاية",
  paid:"مدفوع",
  overtime_percentage:"النسبة المئوية المدفوعة",
  overtime_amount:"المبلغ الثابت المدفوع",
  first_name:"الاسم الاول",
  last_name:"الكنية",
  moderation:"الاعتدال",
  create:"خلق",
  edit:"يحرر",
  destroy:"هدم",
  day_start_time:"اليوم_البدء_الوقت",
  day_end_time:"Day_end_time",
  fullname:"اسم",
  time:"زمن",
  auto_send:"إرسال تلقائيا",
  time_method:"طريقة الوقت",
  schedules:"جداول",
  indefinite_payroll_enabled:"تمكين لأجل غير مسمى",
  amount:"مقدار",
  repeat:"يكرر",
  always_enabled:"ممكّن دائمًا",
  start_date:"تاريخ البدء",
  end_date:"تاريخ الانتهاء",
  Cheque_Total:"تحقق المجموع",
  Total_Amount:"المبلغ الإجمالي:",
  Amounts:"كميات:",
  Images:"الصور",
  Files:"الملفات",
  Previewing:"المعاينة:",
  Insert:"إدراج",
  Preview_Import:"معاينة الاستيراد",
  Entry:"دخول",
  Entries:"إدخالات",
  No_Entries:"لا إدخالات",
  Import_Type:"نوع الاستيراد",
  Select_Details:"حدد التفاصيل",
  Select_Payee:"حدد المدفوع لأمره",
  Enable_Holidays:"تفعيل الإجازات",
  Disable_Holidays:"تعطيل الإجازات",
  Wire_Transfer:"تحويلة كهربية بالسلك",
  New_Export:"تصدير جديد",
  Export_Data:"تصدير البيانات",
  Export_Data_Word:"حدد نوع الملف المراد تصديره وتنزيله.",
  Export_File:"ملف التصدير",
  Mode:"الوضع",
  Times:"مرات",
  Widgets:"الحاجيات",
  Slider:"المنزلق",
  Set_Details:"تعيين التفاصيل",
  Select_Type:"اختر صنف",
  Display_Slider:"عرض المنزلق",
  Dashboard_Slider:"المنزلق لوحة القيادة",
  Dashboard_Mode:"وضع لوحة القيادة",
  Show_Intro:"إظهار مقدمة",
  Show_Payrolls:"إظهار كشوف المرتبات",
  Show_Holidays:"عرض الإجازات",
  Show_Invoices:"إظهار الفواتير",
  Show_Cheques:"إظهار الشيكات",
  Enabled_Widgets:"الحاجيات الممكّنة",
  Disabled_Widgets:"الحاجيات المعوقين",
  Colors:"الألوان",
  Barcodes:"الباركود",
  View_Timers:"مشاهدة الموقتات",
  Gradients:"التدرجات",
  No_Info:"لا توجد معلومات",
  Disable:"تعطيل",
  Show_Layer:"إظهار الطبقات",
  Hide_Layer:"إخفاء الطبقات",
  Text_Layer:"طبقات النص",
  Secondly:"ثانيًا",
  Minutely:"بدقة",
  nine_am:"09:00",
  five_pm:"5:00 مساء",
  Enable_Address:"تمكين العنوان",
  Invalid_File_Type:"عذرا ، تم تحديد نوع ملف غير صالح. نوع الملف المدعوم:",
  Error_Updating_Entry:"عذرا ، كان هناك خطأ في تحديث هذا الإدخال.",
  Schedule_Timing_Alert:"خطأ: يتم تعيين وقت بدء الجدول على قيمة بعد وقت الانتهاء.",
  Select_Multiple_Payments_Prompt:"لم يتم تحديد مدفوعات. حدد دفعة واحدة على الأقل.",
  Select_Multiple_Cheques_Prompt:"لم يتم اختيار الشيكات. الرجاء تحديد شيك واحد على الأقل.",
  Select_Multiple_Items_Prompt:"لم يتم تحديد عناصر. الرجاء تحديد عنصر واحد على الأقل.",
  Paymemt_Multiple_Delete_Confirmation:"هل أنت متأكد أنك تريد حذف مدفوعات متعددة؟",
  Cheque_Multiple_Delete_Confirmation:"هل أنت متأكد أنك تريد حذف عدة عمليات تحقق؟",
  Payee_Multiple_Delete_Confirmation:"هل أنت متأكد أنك تريد حذف العديد من المدفوع لهم؟",
  Employee_Multiple_Delete_Confirmation:"هل أنت متأكد أنك تريد حذف عدة موظفين؟",
  MICR_Warning:"ملاحظة: قد لا تعرض بعض الطابعات والأجهزة خط MICR بشكل صحيح.",
  Automatically_Send:"إرسال تلقائيا",
  Type:"يكتب",
  Payment_Type:"نوع الدفع",
  Auto_Send:"الإرسال التلقائي",
  Automatically_Process:"معالجة تلقائيا",
  Auto_Process:"عملية تلقائية",
  Image_Based:"القائم على الصورة",
  Font_Based:"على أساس الخط",
  Rerender:"إعادة التصيير",
  Rendering:"استدعاء",
  Render:"الملفات",
  Top:"قمة",
  Middle:"وسط",
  Bottom:"الأسفل",
  Top_Left:"أعلى اليسار",
  Top_Center:"مركز أعلى",
  Top_Right:"فوق على اليمين",
  Middle_Left:"وسط اليسار",
  Middle_Center:"منتصف المركز",
  Middle_Right:"وسط اليمين",
  Bottom_Left:"أسفل اليسار",
  Bottom_Center:"مركز القاع",
  Bottom_Right:"أسفل اليمين",
  Numbers:"أعداد",
  Verified:"تم التحقق",
  Paper_Size:"حجم الورق",
  New_Device:"جهاز جديد",
  Add_Device:"اضف جهاز",
  Remove_Device:"إنزع الجهاز",
  Delete_Device:"حذف الجهاز",
  Block_Device:"جهاز الحظر",
  Block:"حاجز",
  Unblock:"رفع الحظر",
  Table:"الطاولة",
  Scan_Login_Code:"امسح رمز تسجيل الدخول",
  Login_Code:"كود الدخول",
  Scan_Code:"كود المسح",
  Scan_QR_Code:"مسح رمز الاستجابة السريعة",
  Select_All:"اختر الكل",
  Deselect_All:"الغاء تحديد الكل",
  Increase:"زيادة",
  Decrease:"تخفيض",
  Bold:"عريض",
  Text:"نص",
  Style:"أسلوب",
  Italic:"مائل",
  QR_Code:"رمز الاستجابة السريعة",
  Barcode:"الرمز الشريطي",
  Browse_Images:"تصفح الصور",
  Browse_Files:"تصفح ملفات",
  Background_Image:"الصورة الخلفية",
  Logo_Image:"صورة الشعار",
  Header_Image:"صورة رأس",
  Bank_Image:"صورة البنك",
  Cut_Lines:"قطع الخطوط",
  Background:"خلفية",
  License:"رخصة",
  One_License:"1 رخصة:",
  Licensed:"المرخص لها:",
  Not_Licensed:"غير مرخص",
  Enter_Serial:"أدخل المسلسل",
  Serial_Key:"رقم مسلسل",
  Serial:"مسلسل",
  Product_Key:"مفتاح المنتج",
  Check_Product_Key:"تحقق من مفتاح المنتج",
  Add_Product_Key:"أضف مفتاح المنتج",
  Verifying_Purchase:"التحقق من الشراء ...",
  Print_Envelope:"مغلف طباعة",
  Envelope:"ظرف",
  Thank_You:"شكرا لك!",
  Scale:"مقياس",
  Print_Scale:"مقياس الطباعة",
  Borders:"الحدود",
  VOID:"فارغ",
  Void_Cheque:"صك باطل",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"دفع لأمر:",
  NO_DOLLARS:"لا دولار",
  ONE_DOLLAR:"دولار واحد",
  DOLLARS:"دولار",
  AND:"و",
  CENTS:"سنتات.",
  NO_:"رقم",
  ONE_:"واحد",
  AND_NO_:"و لا",
  _AND_ONE_:"و واحد",
  DOLLARS_AND_ONE_CENT:"سنت واحد.",
  AND_NO_CENTS:"و صفر سنتات.",
  CHEQUE_PRINT_DATE:"تاريخ:",
  CHEQUE_PRINT_MEMO:"مذكرة:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100" ,
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"المهام الأولية",
  Inital_Setup:"الإعداد الأولي" ,
  Welcome_To:"مرحبا بك في ",
  Welcome:"مرحبا",
  Swipe:"انتقاد" ,
  Intro_Setup:"إعداد المقدمة",
  Introduction:"مقدمة",
  CHEQUE_PRINT_CREDIT:"بدعم من الشيكات" ,
  Title_Intro_Word:"مرحبًا بك في الشيكات" ,
  Title_Intro:"مقدمة الشيكات" ,
  Title_Setup:"الشيكات الإعداد",
  PayPal_Default_Email_Message:"لقد تلقيت تحويل PayPal جديدًا. [مدعوم من الشيكات]" ,
  Cheques_App_Export:"تم تصديره عن طريق الشيكات" ,
  Post_Intro_Thanks:"شكرًا لاختيارك الشيكات. اكتملت عملية الإعداد الآن." ,
  Post_Intro_Word:"ابدأ بطباعة الشيك الأول أو إضافة دفعة مستقبلية أو إضافة موظف إلى كشوف المرتبات." ,
  Upgrade_Required:"تتطلب الشيكات أن تمتلك إصدارًا أكثر تميزًا من البرنامج لإخفاء هذه الرسالة وإلغاء قفل الميزات الإضافية." ,
  Upgrade_Title:"الفحوصات",
  Mailout_Prompt:"يمكنك أيضًا اختيار أن تقوم الشيكات بإرسال شيكات كشوف المرتبات إليك بالبريد." ,
  Mailed_Cheque:"شيك بالبريد:" ,
  Mailed_Cheque_Color:"شيك بالبريد (ملون):" ,
  Terms_Purchase:"جميع المشتريات الإلكترونية باستخدام الشيكات قابلة للاسترداد بالكامل لمدة تصل إلى 30 يومًا من تاريخ الشراء. يُرجى قراءة <a href='#'> البنود والشروط </a> والموافقة عليها قبل المتابعة." ,
  Dashboard_Setup:"إعداد الطابعة الأساسية" ,
  Dashboard_Add:"إضافة الحساب المصرفي الأساسي" ,
  Dashboard_Customize:"حدد قالب التحقق" ,
  Dashboard_Job_Salary:"أنشئ وظيفتك وأضف راتبك" ,
  Dashboard_Employees:"تعقب الموظفين والمدفوعين",
  Dashboard_Print:"طباعة وإرسال شيكات الراتب الخاصة بك بالبريد" ,
  Dashboard_Payroll:"أتمتة طباعة كشوف المرتبات الخاصة بك" ,
  Dashboard_PayPal:"إعداد كشوف المرتبات / المدفوعات PayPal" ,
  Begin_Setup:"بدء الإعداد",
  Get_Started:"البدء",
  Purchase:"شراء",
  Lockdown:"الإغلاق الكامل",
  Unlock:"الغاء القفل",
  Detailed:"مفصلة",
  Log_In:"تسجيل الدخول",
  Login:"تسجيل الدخول",
  Launch:"إطلاق",
  Register:"يسجل",
  Finish:"ينهي",
  List:"قائمة",
  Weekends:"عطلات نهاية الأسبوع",
  Weekly:"أسبوعي",
  PayPal_Default_Subject:"دفعة جديدة",
  Payment_Message:"رسالة الدفع" ,
  PayPal_Default_Payment_Note:"شكرا لكم",
  Setup_Your_Cheqing_Account:"الحساب الجاري",
  Add_Your_Primary_Cheqing_Account:"أضف حساب الشيكات الأساسي الخاص بك." ,
  Welcome_Word:"تبسيط وأتمتة إدارة الرواتب والموارد البشرية.",
  Get_Started_Quickly:"فقط أجب على بعض الأسئلة البسيطة التي ستساعدنا في البدء ..." ,
  Done_Intro_Word:"الإعداد الكامل",
  PIN_Protected:"حماية كلمة المرور ورقم التعريف الشخصي" ,
  Enter_New_PIN:"أدخل رمز PIN جديدًا:" ,
  Enter_PIN:"أدخل رمز PIN:" ,
  Invalid_PIN:"تم إدخال رقم التعريف الشخصي غير صالح.",
  Account_Identifier:"معرف الحساب",
  New_Account_Identifier:"معرف حساب جديد",
  attempt:"محاولة",
  attempts:"محاولات" ,
  remaining:"متبقي",
  Language:"لغة",
  languages:"اللغات",
  select_languages:"اختار اللغة",
  Deposit:"الوديعة",
  Hire_One_Now:"استئجار واحد الآن" ,
  App_Locked:"التطبيق مغلق.",
  Skip_:"يتخطى",
  Skip_to_Dashboard:"تخطي إلى لوحة التحكم" ,
  Dashboard:"لوحة القيادة",
  Import:"يستورد",
  Admin:"المسؤولون",
  New_Admin:"المسؤول الجديد",
  Settings:"إعدادات",
  Color_Picker:"أداة انتقاء اللون",
  Font_Picker:"منتقي الخطوط" ,
  Logout:"تسجيل خروج",
  Close:"قريب",
  Close_menu:"قريب",
  Excel:"ملف اكسل",
  Csv:"ملف CSV" ,
  Sql:"ملف SQL" ,
  Json:"ملف JSON" ,
  Url:"استيراد بواسطة URL" ,
  Back:"عودة",
  Timers:"الموقتات",
  Cheque:"التحقق من",
  Print:"مطبعة",
  Designs:"تصميمات",
  Pause_Printing:"إيقاف الطباعة مؤقتًا" ,
  Resume_Printing:"استئناف الطباعة",
  Printing_Paused:"الطباعة متوقفة مؤقتًا",
  PrintingUnavailable:"عذرا! الطباعة غير متوفرة على هذا النظام.",
  Prints_Paused:"تم إيقاف الطباعة مؤقتًا",
  Administration:"الادارة",
  Loading:"جار التحميل",
  Unnamed:"بدون اسم",
  error:"عذرًا ، لا يمكن فتح هذا الشيك للعرض." ,
  No_Cheques_To_Print:"لا توجد شيكات للطباعة" ,
  No_Cheques_To_Print_Word:"قم بإنشاء <a routerLink='/folder/Cheque/New'>فحص جديد</a>." ,
  New_Cheque:"شيك جديد",
  Start_One_Now:"ابدأ واحد الآن" ,
  Edit_Cheque:"تحرير الشيك" ,
  Select_Cheques:"تحديد الشيكات" ,
  Select_Employee:"حدد الموظف" ,
  Default_Printer:"الطابعة الافتراضية",
  Reassign:"إعادة تعيين",
  Configure:"تهيئة",
  Template:"نموذج",
  Designer:"مصمم",
  Edit_Designs:"تحرير التصاميم" ,
  New_Design:"تصميم جديد",
  Next:"التالي",
  Save:"يحفظ",
  Name:"اسم",
  Mail:"بريد",
  Amount:"مقدار",
  Date:"تاريخ",
  PayPal:"PayPal",
  Payouts:"المدفوعات",
  PayPal_Label:"تسمية PayPal",
  Email_Username:"البريد الإلكتروني / اسم المستخدم" ,
  Client_ID:"معرف العميل" ,
  Sandbox_Email:"اختبار بريد الالكتروني",
  PayPal_Email:"بريد باي بال",
  PayPal_Username:"اسم مستخدم API" ,
  PayPal_Payouts:"مدفوعات PayPal",
  Select_PayPal_Key:"حدد مفتاح PayPal" ,
  Secret:"سر",
  API_Secret:"سر واجهة برمجة التطبيقات" ,
  PayPal_API_Keys:"مفاتيح PayPal",
  New_PayPal_Key:"مفتاح PayPal الجديد" ,
  Email_Subject:"موضوع البريد الإلكتروني",
  Email_Message:"رسالة الكترونية",
  Payout_Options:"خيارات الدفع",
  Payment_Note:"مذكرة الدفع" ,
  Enable_Employee:"تمكين الموظف",
  Enable_Production_Mode:"تمكين وضع الإنتاج" ,
  Sandbox_Username:"اسم مستخدم اختبار" ,
  Sandbox_Signature:"توقيع اختبار" ,
  Sandbox_Password:"كلمة مرور اختبار" ,
  Production_Username:"اسم مستخدم الإنتاج" ,
  Production_Signature:"توقيع الإنتاج" ,
  Production_Password:"كلمة مرور الإنتاج" ,
  Production_Email:"البريد الإلكتروني للإنتاج" ,
  API_Client_ID:"معرف عميل API" ,
  API_Signature:"توقيع API" ,
  API_Password:"كلمة مرور API" ,
  API_Username:"اسم مستخدم API" ,
  Secret_Key:"المفتاح السري" ,
  Sandbox:"اختبار",
  Production:"إنتاج",
  Sandbox_Keys:"مفاتيح الحماية",
  Production_Keys:"مفاتيح الإنتاج",
  API_Title:"عنوان API" ,
  Production_Mode:"وضع الإنتاج",
  Account_Label:"تصنيف الحساب",
  No_PayPal_Setup:"لا يوجد مفتاح PayPal" ,
  Enable_PayPal_Account:"تمكين حساب PayPal" ,
  No_PayPal_Word:"أضف <a routerLink='/folder/Invoice/New'>مفتاح واجهة برمجة تطبيقات PayPal</a>." ,
  Printed_Memo:"مذكرة مطبوعة" ,
  Employee:"موظف",
  View_Employee:"عرض الموظف" ,
  Mailing_Address:"عنوان المراسلة",
  Company_Address:"عنوان الشركة",
  Select_Company_Address:"حدد عنوان الشركة" ,
  Address:"تبوك",
  Any_Day:"اي يوم",
  Address_Name:"اسم عنوان",
  Unit:"وحدة",
  Account:"الحساب",
  Bank_Account:"حساب البنك",
  Account_Limits:"تمكين حدود الحساب" ,
  Payroll:"كشف رواتب",
  New_Payroll:"كشوف مرتبات جديدة",
  No_Payroll:"لا يوجد كشوف مرتبات قادمة",
  Upcoming_Holiday:"العطلة القادمة:",
  Invoice_Due:"فاتورة مستحقة:",
  New_Invoice:"فاتورة جديدة",
  No_Invoices:"لا فواتير",
  No_Invoices_Word:"قم بإنشاء أول <a routerLink='/folder/Invoice/New'>فاتورة</a>." ,
  Cheque_Due:"الشيك المستحق:" ,
  Payrolls:"كشوف المرتبات",
  Sandbox_Mode:"وضع الحماية",
  Hire:"تأجير",
  Pay:"دفع",
  New:"جديد",
  Add:"يضيف",
  Make:"يجعلون",
  Time:"زمن",
  Write:"اكتب",
  Holiday:"يوم الاجازة",
  Holidays:"الأعياد",
  Next_Holiday:"العطلة القادمة:",
  All_Done:"كله تمام!",
  Employees:"الموظفين",
  Payees:"المدفوع لهم",
  Job:"مهنة",
  Jobs:"وظائف",
  Invoice:"فاتورة",
  Invoices:"الفواتير",
  Vacations:"الاجازات",
  Cheques:"الفحوصات",
  Brand_Cheques:"ماركة",
  Payment:"دفع",
  Enable_Payment:"تمكين الدفع" ,
  Payments:"المدفوعات",
  Schedule:"برنامج",
  Schedules:"جداول",
  Timesheet:"ورقة التوقيت",
  Timesheets:"جداول زمنية" ,
  Salary:"مرتب",
  New_Address:"عنوان جديد",
  Address_2:"سطر العنوان 2)",
  _City:"مدينة",
  _State:"الولاية / الإقليم",
  City:"المدينة / البلدة",
  State:"الولاية / المقاطعة",
  Postal:"الرمز البريدي / الرمز البريدي" ,
  ZIP:"البريدي / الرمز البريدي",
  Country:"دولة",
  Addresses:"عناوين",
  Required_Options:"الخيارات المطلوبة",
  Optional_Options:"خيارات اختيارية" ,
  Additional_Options:"خيارات اضافية",
  Required:"مطلوب",
  Optional:"اختياري",
  Additional:"إضافي",
  No_Addresses:"بلا عناوين" ,
  New_Address_Word:"أضف أول <a routerLink='/folder/Address/New'>العنوان</a>." ,
  Select_Address:"حدد العنوان" ,
  No_Address:"بلا عناوين" ,
  Print_Cheque:"طباعة شيك" ,
  Print_Cheque_Now:"Print Check Now" ,
  Description:"وصف",
  Pay_To_The_Order_Of:"دفع لأمر:",
  Select_Date_Range:"تحديد النطاق الزمني" ,
  Select_Starting_Date:"حدد تاريخ البدء" ,
  Select_Ending_Date:"حدد تاريخ الانتهاء" ,
  Select_Date:"حدد تاريخ",
  Cheque_Date:"راجع التاريخ",
  Cheque_Memo:"مذكرة التحقق" ,
  Blank_Cheque:"شيك على بياض",
  Blank:"فارغ",
  No_Cheques:"لا يوجد شيكات",
  No_Cheques_Word:"اطبع أول <a routerLink='/folder/Cheque/New'>تحقق</a>." ,
  Cheque_Amount_Placeholder:"0.00 دولار",
  View_Image:"عرض الصورة",
  View:"رأي",
  Modify:"تعديل",
  Delete:"حذف",
  Cheque_Paid:"دفع",
  New_Deduction:"خصم جديد",
  Title:"عنوان",
  Frequency:"تكرار",
  Hourly:"ساعيا",
  Daily:"اليومي",
  Weekdays:"أيام الأسبوع",
  BiWeekly:"2 أسابيع",
  TriWeekly:"3 أسابيع",
  Monthly:"شهريا",
  MiMonthly:"2 أشهر",
  Quarterly:"ربعي",
  Yearly:"سنوي",
  Every_Week:"كل اسبوع",
  Every_Payroll:"كل كشوف مرتبات" ,
  Every_Month:"كل شهر",
  Annually:"سنويا",
  Always_Scheduled:"مجدول دائمًا",
  Start_Date:"تاريخ البدء",
  End_Date:"تاريخ الانتهاء",
  Start_Time:"وقت البدء",
  End_Time:"وقت النهاية",
  Deduction_Label:"تسمية الخصم",
  Notes:"ملحوظات",
  Options:"خيارات",
  Enable:"يمكن",
  Select_Deductions:"تحديد الخصومات",
  Deductions:"الخصومات",
  No_Deductions:"لا استقطاعات",
  No_Deductions_Word:"قم بإنشاء أول <a routerLink='/folder/Deduction/New'>خصم</a>." ,
  New_Employee:"موظف جديد",
  No_Title:"بلا عنوان",
  _Name:"اسم",
  About_Yourself:"عن نفسك",
  Full_Name:"الاسم الكامل",
  Birthday:"عيد ميلاد",
  Email:"بريد الالكتروني",
  SMS:"رسالة قصيرة",
  Phone:"هاتف",
  Test:"اختبار",
  Call:"يتصل",
  Fax:"فاكس",
  Printed_Note:"ملاحظة مطبوعة" ,
  Position:"موضع",
  Job_Position:"وظيفه",
  Select_a_Job:"اختر وظيفة" ,
  Select_a_Salary:"حدد الراتب" ,
  Add_a_Deduction:"إضافة خصم" ,
  Taxes:"الضرائب",
  Add_Taxes:"إضافة الضرائب",
  Date_of_Birth:"تاريخ الولادة",
  Email_Address:"عنوان البريد الالكترونى",
  Phone_Number:"رقم التليفون",
  Phone_Call:"مكالمة هاتفية",
  Enable_on_Payroll:"تمكين في كشوف المرتبات",
  Select_Employees:"تحديد الموظفين" ,
  No_Employees:"لا موظفين",
  No_Employees_Word:"أضف أول <a routerLink='/folder/Employee/New'>موظف</a> جديد لك." ,
  No_Name:"بلا اسم" ,
  Unemployeed:"عاطلين عن العمل",
  Employeed:"موظف",
  Paid:"دفع",
  Enabled:"ممكّن",
  Disabled:"عاجز",
  Fire:"حريق",
  Not_Available:"غير متاح",
  Not_Available_Word:"اطبع أول <a routerLink='/folder/Invoice/New'>فاتورة</a> واحصل على الأموال.",
  Select_Invoices:"Select_Invices" ,
  Print_Invoice_Word:"اطبع أول <a routerLink='/folder/Invoice/New'>فاتورة</a> واحصل على الأموال.",
  Invoice_Title:"عنوان الفاتورة",
  Invoice_Date:"تاريخ الفاتورة",
  Due_Date:"تاريخ الاستحقاق",
  New_Job:"مهنة جديدة",
  Details:"تفاصيل",
  Customize:"يعدل أو يكيف",
  Customize_Dashboard:"تخصيص لوحة التحكم" ,
  Components:"عناصر",
  No_Components:"لا توجد مكونات",
  Main_Components:"المكونات الرئيسية",
  Smaller_Components:"مكونات أصغر",
  Error_Loading_Page:"خطأ في تحميل هذه الصفحة." ,
  Bank_Details:"التفاصيل المصرفية",
  About_Your_Job:"عن عملك",
  Your_Schedule:"الجدول الزمني الخاص بك" ,
  Job_Title:"المسمى الوظيفي",
  Job_Description:"المسمى الوظيفي",
  Job_Details:"تفاصيل الوظيفة",
  Enable_Job:"تمكين الوظيفة",
  Pay_Period:"فترة الدفع",
  Perpetually_Schedule:"الجدولة الدائمة",
  Select_Jobs:"تحديد الوظائف" ,
  No_Jobs:"لا توجد وظائف",
  Add_Jobs:"إضافة وظائف",
  No_Jobs_Word:"أضف أول <a routerLink='/folder/Job/New'>المهمة</a> إلى القائمة." ,
  Count_Employees:"job.employee_count+' موظف'",
  Zero_Employees:"0 موظف",
  New_Leave:"إجازة جديدة" ,
  Leave_Name:"اترك الاسم" ,
  Paid_Leave:"إجازة مدفوعة الأجر",
  Leave_Pay:"ترك الدفع",
  Indefinite_Leave:"إجازة لأجل غير مسمى",
  Indefinite_Payroll:"كشوف المرتبات لأجل غير مسمى",
  Leave:"يترك",
  Add_Schedules:"إضافة جداول",
  Percentage:"100٪",
  Enable_Leave_Of_Absence:"تمكين الغياب",
  Select_Leaves:"حدد الأوراق" ,
  No_Leaves:"لا أوراق الغياب" ,
  Leave_Of_Absence:"إجازة الغياب" ,
  Leaves_Of_Absence:"أوراق الغياب" ,
  New_Leave_of_Absense:"إجازة الغياب الجديدة" ,
  No_Leaves_Word:"أضف أول <a routerLink='/folder/Leave/New'>إجازة الغياب</a>." ,
  Not_Enabled:"غير مفعل",
  Absences:"الغياب",
  Payee:"المستفيد",
  New_Payee:"المستفيد الجديد",
  Payee_Identifier:"معرف المدفوع لأمره",
  Payee_Name:"اسم المستفيد",
  Payee_Title:"عنوان المدفوع لأمره",
  Payment_Memo:"مذكرة الدفع" ,
  Select_Payees:"حدد المدفوع لهم" ,
  No_Payees:"لا مستفيدون" ,
  Add_Payee_Note:"أضف أول <a routerLink='/folder/Payee/New'>المدفوع لأمره</a>." ,
  New_Payees:"المستفيدون الجدد",
  About_The_Payment_Schedule:"جدول الدفع",
  Your_Payroll_Schedule:"كشوف المرتبات التلقائية",
  New_Payment:"دفعة جديدة",
  Identifier:"المعرف",
  Select:"يختار",
  Memo:"مذكرة",
  Payment_Date:"يوم الدفع او الاستحقاق",
  Mark_as_Paid:"وضع علامة كمدفوع" ,
  Select_Payments:"حدد المدفوعات" ,
  No_Payments:"لا مدفوعات",
  No_Payments_Word:"قم بإنشاء أول <a routerLink='/folder/Payment/New'>دفعة</a>." ,
  Create_Payroll:"إنشاء كشوف المرتبات",
  Properties:"الخصائص",
  Payroll_Title:"عنوان المرتبات",
  Payroll_Notes:"ملاحظات كشوف المرتبات",
  Payroll_Setup:"إعداد كشوف المرتبات",
  Tabulate_Payments:"جدولة المدفوعات",
  Tabulate:"جدولة",
  By:"بواسطة:",
  Payments_By:"المدفوعات بواسطة",
  Timesheets_And_Schedules:"جداول زمنية وجداول زمنية",
  Both:"على حد سواء",
  Items:"أغراض",
  Add_Payees:"إضافة المستفيدين",
  Add_Account:"إنشاء حساب",
  Enable_Account:"تمكين الحساب",
  Enable_Payroll:"تمكين كشوف المرتبات",
  Print_Payroll:"طباعة كشوف المرتبات" ,
  No_Payrolls:"لا يوجد كشوف مرتبات",
  No_Payroll_Word:"قم بإنشاء أول <a routerLink='/folder/Payroll/New'> كشوف رواتب </a>." ,
  View_more:"عرض المزيد",
  Less:"أقل",
  Add_Payroll:"إضافة كشوف المرتبات" ,
  Select_Payroll:"حدد كشوف المرتبات" ,
  About_Your_Salary:"حول راتبك" ,
  Add_Salaries:"إضافة رواتب",
  Add_Salary:"إضافة الراتب" ,
  Salaries:"المرتبات",
  No_Salaries:"لا رواتب",
  No_Salaries_Word:"أضف أول <a routerLink='/folder/Salary/New'>راتب</a>." ,
  Select_Salaries:"تحديد الرواتب" ,
  New_Salary:"الراتب الجديد",
  Salary_Name:"معرّف الراتب",
  Enable_Salary:"تمكين الراتب",
  Salary_Amount:"مبلغ الراتب",
  New_Schedule:"جدول جديد",
  Schedule_Title:"عنوان الجدول",
  Add_Address:"اضف عنوان",
  Repeat:"يكرر",
  Design:"تصميم",
  Edit_Design:"تحرير التصميم" ,
  Edit_this_Design:"تحرير هذا التصميم" ,
  Repeat_Payment:"إعادة الدفع" ,
  Enable_Schedule:"تمكين الجدول",
  Never:"مطلقا",
  Select_Schedule:"حدد الجداول" ,
  No_Schedules:"لا توجد جداول",
  No_Schedules_Word:"قم بإنشاء أول <a routerLink='/folder/Schedule/New'>الجدول</a>." ,
  New_Administrator:"مسؤول جديد",
  Username:"اسم المستخدم",
  First_Name:"الاسم الاول",
  Last_Name:"اسم العائلة",
  Add_an_Address:"إضافة عنوان",
  Payment_Limit:"حد الدفع",
  Total_Limit:"الحد الإجمالي",
  Select_Accounts:"تحديد الحسابات" ,
  No_Administrators:"لا يوجد مشرفون",
  No_Administrators_Word:"قم بإنشاء أول <a routerLink='/folder/Administrator/New'>حساب مشرف</a>." ,
  New_Administrators_Word:"أضف أول <a routerLink='/folder/Administrator/New'>مدير</a>" ,
  Cloud:"سحاب",
  Backup:"دعم",
  Enable_iCloud:"تمكين iCloud" ,
  Enable_Google_Drive:"تمكين Google Drive" ,
  Enable_Microsoft_OneDrive:"تمكين Microsoft OneDrive" ,
  Automatically_Backup:"النسخ الاحتياطي التلقائي" ,
  FTP_Settings:"إعدادات FTP",
  URL_File_Prompt:"الرجاء تحديد موقع ملف .xls / .xlsx / .json لاستيراده:" ,
  URL_SQL_Prompt:"الرجاء تحديد موقع ملف SQLite المراد استيراده:" ,
  FTP_Backup:"FTP Backup",
  FTP_Import:"استيراد FTP" ,
  FTP:"FTP" ,
  SFTP:"SFTP" ,
  Host:"يستضيف",
  Port:"ميناء",
  Path:"طريق",
  Data_Path:"مسار البيانات",
  Enable_FTP_Account:"تمكين حساب FTP" ,
  HostnameIP:"اسم المضيف",
  Password:"كلمة المرور",
  Connection_Port:"منفذ الاتصال" ,
  Enable_MySQL_Database:"تمكين قاعدة بيانات MySQL" ,
  Log:"سجل",
  Reset:"إعادة ضبط",
  Erase:"محو",
  Export:"يصدر",
  Database:"قاعدة البيانات",
  Upload_CSV:"تحميل CSV" ,
  Download_CSV:"تنزيل CSV" ,
  SQL_Database:"SQL URL" ,
  MySQL_Database:"MySQL" ,
  MySQL_Backup:"MySQL دعم" ,
  Internal_Notes:"ملاحظات داخلية",
  Root_Path:"مسار الجذر" ,
  Select_Backup:"حدد نسخة احتياطية" ,
  Add_New_Backup:"إضافة نسخة احتياطية جديدة" ,
  First_Backup:"إعداد النسخة الاحتياطية الأولى ..." ,
  Backups:"النسخ الاحتياطية",
  Add_Backup:"إضافة نسخة احتياطية" ,
  No_Backups:"لا توجد نسخ احتياطية يمكن العثور عليها." ,
  Select_Backup_Type:"حدد نوع النسخة الاحتياطية التي ترغب في إعدادها ..." ,
  Backup_Type:"نوع النسخ الاحتياطي",
  FTP_Drive:"محرك FTP" ,
  URL_Location:"URL" ,
  Apple_iCloud:"iCloud" ,
  Google_Drive:"قيادة",
  Microsoft_OneDrive:"قيادة",
  Import_Fields:"استيراد الحقول",
  Import_Fields_Word:"استخدم هذا القسم لتحديد بيانات <a routerLink='/folder/Holiday/New'>استيراد</a>." ,
  Upload:"تحميل",
  Download:"تحميل",
  Download_JSON:"تنزيل بتنسيق JSON البيانات" ,
  Download_SQL:"تنزيل كملف SQL" ,
  New_Bank:"بنك جديد" ,
  New_Account:"حساب جديد",
  New_Bank_Account:"حساب مصرفي جديد",
  Upload_Image:"تحميل الصور",
  Bank_Name:"اسم البنك",
  Bank_Address:"عنوان البنك",
  Branch_Address:"عنوان فرع",
  Address_on_Cheque:"تبوك",
  Cheque_Numbers:"التحقق من الأرقام" ,
  Cheque_Details:"التحقق من التفاصيل" ,
  Bank_Logo:"شعار البنك" ,
  Cheque_ID:"التحقق من الهوية" ,
  Starting_Cheque_ID:"بدء التحقق من المعرف" ,
  Transit_Number:"رقم العبور",
  Institution_Number:"رقم المؤسسة" ,
  Designation_Number:"رقم التعيين",
  Account_Number:"رقم حساب",
  Limits:"حدود",
  Default_Limits:"الحدود الافتراضية",
  Over_Limit:"فوق الحدود",
  Under_Limit:"تحت الحد",
  Payroll_Limit:"حد الرواتب",
  Enable_Bank_Account:"تمكين الحساب المصرفي" ,
  Select_Account:"حدد حساب",
  No_Bank_Account:"لا يوجد حساب مصرفي",
  No_Bank_Account_Word:"أضف أول <a routerLink='/folder/Accounts/New'>حساب مصرفي</a>." ,
  Bank_Accounts:"حسابات بنكية",
  No_Accounts:"لا حسابات" ,
  No_Accounts_Note:"أضف أول <a routerLink='/folder/Accounts/New'>حساب مصرفي</a>." ,
  Cheque_Designer:"تحقق من المصمم" ,
  Cheque_Design:"التحقق من التصميم" ,
  Select_Design:"حدد تصميمًا" ,
  Cheque_Designs:"تحقق من التصاميم" ,
  No_Cheque_Designs:"لا يوجد تحقق من التصاميم" ,
  No_Cheque_Designs_Word:"قم بإنشاء <a routerLink='/folder/Settings/Cheque/Design/New'>فحص التصميم</a> الخاص بك." ,
  Set_Default:"تعيين كافتراضي",
  Default:"تقصير",
  Remove:"إزالة",
  Folder:"مجلد",
  Edit:"تعديل",
  LoadingDots:"جار التحميل...",
  Add_a_New_File:"إضافة <a href='#' (click)='add()'> ملف جديد </a> إلى" ,
  this_folder:"هذا المجلد",
  FTP_Backup_Settings:"إعدادات النسخ الاحتياطي لبروتوكول نقل الملفات" ,
  Secure_File_Transfer:"نقل الملفات الآمن",
  New_Holiday:"عطلة جديدة",
  Add_Holiday:"إضافة عطلة" ,
  Holiday_Name:"اسم العطلة" ,
  Additional_Pay:"أجر إضافي" ,
  Enable_Holiday:"تمكين عطلة" ,
  Select_Holidays:"حدد أيام العطل" ,
  No_Holidays:"لا عطلات" ,
  No_Holidays_Word:"أضف أول <a routerLink='/folder/Holiday/New'>عطلة عامة</a>." ,
  New_Import:"استيراد جديد",
  Import_Data:"بيانات الاستيراد",
  Import_Data_Word:"حدد نوع الملف أو طريقة التحميل التي تختارها. <br /> ستتمكن من تحديد الحقول المستوردة في ملف تتوافق مع أي معلمة في التطبيق بعد تحميل ملف مدعوم.",
  Import_File:"استيراد ملف",
  Link_To_File:"ارتباط بالملف" ,
  Select_File:"تحديد ملف" ,
  New_Moderator:"منسق جديد",
  Allow_Moderation:"السماح بالإشراف" ,
  Create_Paycheques:"إنشاء شيكات رواتب" ,
  Edit_Paycheques_and_Data:"تحرير شيكات الرواتب والبيانات" ,
  Destroy_Data_and_Paycheques:"إتلاف البيانات وشيكات الرواتب" ,
  Bonus_Percentage:"نسبة شيك الراتب",
  Fixed_Amount:"مبلغ ثابت",
  Select_Moderator:"حدد وسيط" ,
  No_Moderators:"لا يوجد مشرفون",
  Moderators:"الوسطاء",
  Moderator_Account:"قم بإنشاء أول <a routerLink='/folder/Administrator/New'>حساب وسيط</a>." ,
  No_Moderators_Word:"أضف أول <a routerLink='/folder/Administrator/New'>الوسيط</a>." ,
  Defaults:"الافتراضيات",
  Provide_Us_Info:"زودنا بالمعلومات" ,
  Setup_Your_Printer:"إعداد الطابعة الخاصة بك" ,
  Your_Company:"حول شركتك" ,
  Company_Name:"اسم الشركة",
  Currency:"عملة",
  Default_Currency:"العملة الافتراضية",
  Base_Monthly_Income:"الدخل الشهري",
  Protection:"حماية",
  App_Protection:"حماية التطبيقات",
  PIN_Code_Protection:"حماية رمز PIN" ,
  App_Protection_Word:"تمكين طرق الأمان التي تساعد في حماية حسابك.",
  PIN_Code:"رمز PIN" ,
  Change_PIN:"تغيير رقم التعريف الشخصي" ,
  New_Password:"كلمة السر الجديدة",
  Geofence_Protection:"حماية السياج الجغرافي" ,
  Geofence_Area:"تعيين المنطقة" ,
  Distance:"مسافة",
  Setup_Now:"الإعداد الآن",
  Mile:"ميل",
  Km:"كم",
  m:"م" ,
  Facial_Recognition:"التعرف على الوجه",
  Face:"وجه",
  Setup:"يثبت",
  Label:"ملصق",
  Password_Protection:"حماية كلمة المرور",
  Modify_Password:"تعديل كلمة المرور",
  New_Tax_Entry:"إدخال ضريبي جديد" ,
  New_Tax:"ضريبة جديدة",
  Tax_Label:"التصنيف الضريبي",
  Perpetually_Enabled:"ممكّن دائمًا",
  Select_Taxes:"حدد الضرائب" ,
  Tax_Deductions:"التخفيضات الضريبية",
  No_Tax_Deductions:"لا استقطاعات ضريبية",
  No_Tax_Deductions_Word:"أضف خصم <a routerLink='/folder/Tax/New'>الضريبة</a> الأول.",
  New_Timer:"مؤقت جديد",
  Start:"يبدأ",
  Stop:"قف",
  Start_Timer:"بدء الموقت",
  Stop_Timer:"Stop Timer",
  Timer_Active:"المؤقت نشط",
  Timer:"المؤقت:" ,
  Timer_Running:"المؤقت: (قيد التشغيل)" ,
  Save_Timer:"حفظ المؤقت",
  New_Timesheet:"صحيفة زمنية جديدة",
  Select_Timesheets:"حدد جداول الوقت" ,
  Work_Notes:"ملاحظات العمل",
  Entry_Title:"عنوان الإدخال",
  No_Timesheets:"لا أوراق الوقت" ,
  No_Timesheets_Word:"أضف أول <a routerLink='/folder/Timesheet/New'>جدول زمني</a>." ,
  Timesheet_Submitted:"تم تقديم صحيفة الوقت",
  Timesheet_Congrats:"مبروك! لقد تم تقديم جدولك الزمني بنجاح. شكرا لك!" ,
  Timesheet_Copy:"لتلقي نسخة من مستنداتك ، يرجى تزويدنا بعنوان بريدك الإلكتروني و / أو رقم هاتفك المحمول." ,
  Submit:"إرسال",
  Allow_Notifications:"السماح بالإشعارات" ,
  Untitled_Entry:"دخول جديد",
  Untitled_Bank:"بنك بلا عنوان",
  Timesheet_Entry:"إدخال صحيفة الوقت",
  Work_Description:"وصف العمل",
  Enable_Timesheet:"تمكين الجدول الزمني" ,
  Submit_Timesheet:"إرسال الجدول الزمني" ,
  Vacation:"عطلة",
  New_Vacation:"إجازة جديدة",
  Vacation_Name:"اسم العطلة",
  Paid_Vacation:"اجازه مدفوعة",
  Vacation_Pay:"مدفوعات الإجازة",
  Enable_Vacation:"تمكين الإجازة",
  Select_Vacations:"حدد الإجازات" ,
  No_Vacations:"لا عطلات" ,
  No_Vacations_Word:"أنشئ أول إدخال <a routerLink='/folder/Vacation/New'>عطلة</a>." ,
  Payroll_Schedule:"جدول الرواتب",
  Next_Payroll:"كشوف المرتبات التالية:" ,
  Upcoming_Payroll:"كشوف المرتبات القادمة",
  No_Upcoming_Payroll:"لا يوجد كشوف مرتبات قادمة",
  Address_Book:"دليل العناوين",
  Archived_Documents:"المستندات المؤرشفة",
  Dev_Mode:"التطبيق في وضع التطوير" ,
  Administrators:"المسؤولون",
  Privacy:"خصوصية",
  None:"لا أحد",
  Select_Signature:"حدد التوقيع" ,
  No_Signatures:"لا توجد توقيعات" ,
  No_Signatures_Word:"أضف أول <a routerLink='/folder/Signature/New'>التوقيع</a>." ,
  Repeat_Indefinitely:"كرر إلى أجل غير مسمى" ,
  Sign:"لافتة",
  Sign_Here:"وقع هنا",
  Date_Signed:"تاريخ التوقيع",
  Signature_Pad:"لوحة التوقيع" ,
  Account_Holder:"مالك الحساب",
  Account_Properties:"خصائص الحساب" ,
  Name_On_Cheque:"الاسم عند الاختيار" ,
  Server_Hostname:"اسم مضيف الخادم / IP" ,
  Printers:"طابعات",
  No_Printers:"لا توجد طابعات",
  Printer_Exists:"توجد طابعة بهذا الاسم بالفعل.",
  No_Printers_Word:"أضف أول <a routerLink='/folder/Printer/New'>طابعة</a>." ,
  No_Printer_Alert:"لم يتم تعريف طابعة. هل ترغب في إعداد طابعة؟" ,
  Add_Printer:"إضافة طابعة",
  New_Printer:"طابعة جديدة",
  Printer_Hostname:"اسم مضيف الطابعة / IP" ,
  Printer_Label:"تسمية الطابعة" ,
  Printer_Protocol:"بروتوكول الطابعة" ,
  Protocol:"بروتوكول",
  Email_Print:"بريد الالكتروني",
  AirPrint:"AirPrint",
  IPP:"IPP" ,
  LPD:"LPD" ,
  HPJetDirect:"قابس كهرباء",
  Print_Job:"مهمة الطباعة" ,
  Printed:"مطبوعة" ,
  Not_Printed:"غير مطبوعة" ,
  Print_Jobs:"مهام الطباعة",
  Print_Queue:"قائمة انتظار الطباعة",
  No_Print_Jobs:"لا توجد مهام طباعة" ,
  No_Prints:"قم بإنشاء <a routerLink='/folder/Cheque/New'>تحقق</a> جديدًا للطباعة." ,
  Prints:"مطبوعات",
  Find_Printer:"جد الطابعة",
  Find_AirPrint_Devices:"ابحث عن أجهزة AirPrint",
  Select_Printer:"تحديد الطابعة" ,
  System_UI:"واجهة مستخدم النظام" ,
  Printer:"طابعة",
  Status:"حالة",
  Preview:"معاينة",
  Enable_Print_Job:"تمكين مهمة الطباعة" ,
  Queue_Weight:"وزن قائمة الانتظار",
  Unlimited:"غير محدود",
  Show_Advanced_Printer_Options:"إظهار خيارات الطابعة المتقدمة" ,
  Advanced:"متقدم",
  Location:"موقع",
  Note:"ملحوظة",
  Queue_Name:"اسم قائمة الانتظار" ,
  Pages_Printed_Limit:"حد الصفحات المطبوعة",
  MultiPage_Idle_Time:"وقت (أوقات) انتظار متعدد الصفحات" ,
  Page_Count_Limit:"حد عدد الصفحات",
  Page_Orientation:"اتجاه الصفحة",
  Portrait:"لوحة",
  Landscape:"المناظر الطبيعيه",
  Duplex:"دوبلكس",
  Double_Sided_Printing:"بجانبين",
  Duplex_Mode:"وضع الازدواج",
  Duplex_Short:"قصيرة",
  Duplex_Long:"طويل",
  Duplex_None:"لا أحد",
  Color_And_Quality:"اللون والجودة" ,
  Monochrome:"أحادية اللون",
  Photo_Quality_Prints:"مطبوعات بجودة الصور" ,
  Printer_Email:"بريد إلكتروني للطابعة" ,
  Automatically_Downsize:"تقليص الحجم تلقائيًا" ,
  Paper:"ورق",
  Paper_Name:"اسم الورقة" ,
  Paper_Width:"عرض الورق",
  Paper_Height:"ارتفاع الورق",
  Paper_Autocut_Length:"طول القطع التلقائي للورق",
  Margins:"الهوامش",
  Page_Margins:"هوامش الصفحة",
  Page_Margin_Top:"هامش الصفحة العليا" ,
  Page_Margin_Right:"هامش الصفحة اليمنى" ,
  Page_Margin_Bottom:"هامش الصفحة السفلي" ,
  Page_Margin_Left:"هامش الصفحة اليسرى" ,
  Add_Employees:"إضافة موظفين",
  Header:"رأس",
  Print_A_Page_Header:"طباعة رأس الصفحة" ,
  Header_Label:"عنوان العنوان",
  Header_Page_Index:"فهرس صفحة الرأس" ,
  Header_Font_Name:"خط الرأس" ,
  Select_Font:"تحديد الخط" ,
  Font_Selector:"محدد الخط",
  Header_Font_Size:"خط الرأس" ,
  Header_Bold:"رأس بولد",
  Header_Italic:"رأس مائل",
  Header_Alignment:"محاذاة الرأس" ,
  Left:"اليسار",
  Center:"مركز",
  Right:"حق",
  Justified:"مبرر",
  Header_Font_Color:"لون الرأس",
  Select_Color:"إختر لون",
  Footer:"تذييل",
  Print_A_Page_Footer:"طباعة تذييل الصفحة" ,
  Footer_Label:"تسمية التذييل",
  Footer_Page_Index:"فهرس صفحة التذييل" ,
  Footer_Font_Name:"خط التذييل",
  Fonts:"الخطوط",
  Done:"فعله",
  Select_Fonts:"تحديد الخطوط" ,
  Footer_Font_Size:"حجم التذييل",
  Footer_Bold:"تذييل عريض",
  Footer_Italic:"تذييل مائل",
  Footer_Alignment:"محاذاة التذييل",
  Footer_Font_Color:"لون التذييل",
  Page_Copies:"نُسخ الصفحة" ,
  Enable_Printer:"تمكين الطابعة" ,
  Remote_Logging:"التسجيل عن بعد",
  Log_Server:"خادم السجل" ,
  Encryption:"التشفير",
  Random_Key:"مفتاح عشوائي",
  Encryption_Key:"مفتاح التشفير",
  Cheques_Webserver:"قاعدة بيانات مخصصة" ,
  Learn_How:"تعلم كيف",
  Signature:"إمضاء",
  Default_Printer_Unit:"in / cm / mm / (pt)" ,
  View_Signature:"عرض التوقيع" ,
  Printed_Signature:"التوقيع المطبوع" ,
  Digitally_Sign:"التوقيع الرقمي" ,
  Digital_Signature:"توقيع إلكتروني",
  Add_Signature:"إضافة توقيع" ,
  Add_Your_Signature:"أضف توقيعك" ,
  Enable_Signature:"تمكين التوقيع" ,
  Digitally_Signed:"موقع رقمياً",
  Insert_Error:"تعذر حفظ الشيك بسبب مشاكل في قاعدة البيانات." ,
  Delete_Confirmation:"هل أنت متأكد أنك تريد حذف هذه المعلومات؟" ,
  Discard_Confirmation:"هل أنت متأكد أنك تريد تجاهل هذه المعلومات؟" ,
  Discard_Bank_Confirmation:"هل أنت متأكد أنك تريد تجاهل هذا الحساب؟" ,
  Discard_Printer_Confirmation:"هل أنت متأكد أنك تريد تجاهل هذه الطابعة؟" ,
  Delete_Bonus_Confirmation:"هل أنت متأكد أنك تريد حذف هذه المكافأة؟" ,
  Delete_Invoice_Confirmation:"هل أنت متأكد أنك تريد حذف هذه الفاتورة؟" ,
  Generated_Cheque:"تحقق تم إنشاؤه",
  Generated_Invoice:"الفاتورة المُنشأة",
  Schedule_Start_Time:"جدولة البدء",
  Schedule_End_Time:"إنهاء الجدول",
  New_Call:"مكالمة جديدة",
  No_Contacts:"لا توجد جهات اتصال" ,
  No_Contacts_Word:"يظهر المسؤولون والمشرفون والموظفون والمستفيدون كجهات اتصال.",
  PDF_Subject:"المالية",
  PDF_Keywords:"شيكات الرواتب بصيغة PDF",
  Printer_Setup:"إعدادات الطابعه",
  Printer_Selection:"تحديد الطابعة",
  New_Fax:"فاكس جديد",
  New_Fax_Message:"رسالة فاكس جديدة" ,
  Fax_Machine:"جهاز فاكس",
  Fax_Name:"اسم الفاكس",
  Fax_Email:"فاكس البريد الإلكتروني" ,
  Fax_Number:"رقم الفاكس",
  Contents:"محتويات",
  Fax_Body:"نص الصفحة" ,
  Header_Word:"العنوان:" ,
  Header_Text:"نص العنوان" ,
  Include_Header:"تضمين العنوان" ,
  Include_Footer:"تضمين التذييل",
  Footer_Word:"تذييل:",
  Footer_Text:"نص التذييل",
  Attach_a_Cheque:"إرفاق شيك" ,
  Add_Deduction:"إضافة الخصم",
  Enable_Fax:"إرسال الفاكس" ,
  Select_Fax:"حدد الفاكس" ,
  No_Faxes:"لا توجد فاكسات",
  Faxes:"فاكسات",
  Save_and_Send:"إرسال الفاكس" ,
  Save_and_Pay:"حفظ وادفع",
  WARNING:"تحذير:",
  Remember:"يتذكر",
  Printing:"طباعة",
  Printing_Complete:"اكتملت الطباعة!",
  of:"من",
  There_Were:"كانت هناك ",
  Successful_Prints:"مطبوعات ناجحة و" ,
  Unsuccessful_Prints:"مطبوعات غير ناجحة." ,
  PrinterError:"آسف، كان هناك خطأ.",
  Printing_:"طباعة ..." ,
  PrinterSuccess:"تمت طباعة المستند بنجاح.",
  PrintersSuccess:"تمت طباعة المستندات بنجاح." ,
  Print_Cheques:"طباعة الشيكات",
  New_Message:"رسالة جديدة",
  New_Messages:"رسائل جديدة",
  Read_Message:"اقرأ الرساله",
  Write_Message:"اكتب رسالة",
  Send_Message:"إرسال رسالة" ,
  Subject:"موضوع",
  Message:"رسالة",
  Writing:"جاري الكتابة...",
  Send:"إرسال",
  Set_Date:"تعيين التاريخ" ,
  Set_Time:"ضبط الوقت",
  Recieve:"تلقي" ,
  Set_as_Default:"تعيين كافتراضي",
  Default_Account:"الحساب الافتراضي" ,
  Default_Design:"التصميم الافتراضي" ,
  Multiple_Cheques:"الشيكات (تربل)" ,
  Account_Mode:"وضع الحساب" ,
  Multiple_Cheques_Description:"ثلاثة شيكات لكل صفحة.",
  Check_and_Table:"فحص وجدول",
  Check_including_Table:"جدول الشيكات والمحاسبة.",
  Check_Mailer:"تحقق من بريد" ,
  Check_Mailer_Window:"تحقق مع نافذة العنوان." ,
  DocuGard_Table_Top:"فحص وجدول DocuGard (أعلى)" ,
  DocuGard_Table_Middle:"فحص وجدول DocuGard (في الوسط)" ,
  DocuGard_Table_Bottom:"فحص وجدول DocuGard (الجزء السفلي)" ,
  DocuGard_Mailer_Top:"DocuGard فحص البريد (الجزء العلوي)" ,
  DocuGard_Mailer_Middle:"DocuGard فحص البريد (الأوسط)" ,
  DocuGard_Mailer_Bottom:"DocuGard فحص البريد (الجزء السفلي)" ,
  DocuGard_Three_Cheques:"شيكات DocuGard (تربل)" ,
  DocuGard_Cheque_Top:"فحص DocuGard (الجزء العلوي)" ,
  DocuGard_Cheque_Middle:"DocuGard التحقق من (الأوسط)" ,
  DocuGard_Cheque_Bottom:"DocuGard التحقق من (الجزء السفلي)" ,
  DocuGard_Three_Cheques_Window:"ثلاث تدقيقات في صفحة واحدة.",
  DocuGard_Table_Top_Window:"جدول الشيكات والأرباح.",
  DocuGard_Table_Middle_Window:"جدول الشيكات والأرباح.",
  DocuGard_Table_Bottom_Window:"جدول الشيكات والأرباح.",
  DocuGard_Mailer_Top_Window:"تحقق ، وجدول ، وعنوان." ,
  DocuGard_Mailer_Middle_Window:"تحقق ، وجدول ، وعنوان." ,
  DocuGard_Mailer_Bottom_Window:"تحقق ، وجدول ، وعنوان." ,
  DocuGard_Cheque_Top_Window:"تحقق من وجود ورق آمن." ,
  DocuGard_Cheque_Middle_Window:"تحقق من وجود ورق آمن." ,
  DocuGard_Cheque_Bottom_Window:"تحقق من وجود ورق آمن." ,
  Basic_Cheque:"تحقق (أعلى)" ,
  Basic_Cheque_Print:"اطبع شيكًا واحدًا.",
  Error_Setting_As_Paid:"خطأ في تعيين كمدفوع" ,
  Add_Attachment:"إضافة مرفق",
  PrinterUnavailable:"الطابعة غير متوفرة" ,
  CreditCardComponent:"البطاقات",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"بيتكوين",
  New_Deposit:"إيداع جديد",
  Deposits:"الودائع",
  No_Deposits:"لا ودائع",
  Credit_Card_Deposit:"بطاقة إئتمان",
  New_Credit_Card_Deposit_Message:"إيداع بطاقة الائتمان",
  New_BitCoin_Deposit:"بيتكوين",
  New_BitCoin_Deposit_Message:"إيداع بيتكوين" ,
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Interac تحويل",
  Payments_Limit:"حد الدفع",
  No_Payment_Limit:"لا يوجد حد للدفع",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"إيداع PayPal" ,
  No_Deposits_Word:"أضف أول شركة <a routerLink='/folder/Deposit/New'>الوديعة</a> للشركة الأولى." ,
  No_Documents_Specified:"لم يتم تحديد مستندات للطباعة" ,
  No_Printers_Added:"لم يتم العثور على طابعات. انتقل إلى الإعدادات> الطابعات لإضافة واحدة." ,
  DB_Erase_Prompt:"هل تريد محو قاعدة البيانات بالكامل؟ تحذير: ستفقد جميع المعلومات المخزنة!" ,
  Console_Warning:"لا تلصق أي نص في وحدة التحكم هذه. قد تعرض نفسك و / أو شركتك لخطر جسيم." ,
  No_Faxes_Word:"قم بإنشاء أول <a routerLink='/folder/Fax/New'>فاكس</a>.",
  Cheque_Delete_Confirmation:"هل أنت متأكد أنك تريد حذف هذا الاختيار؟" ,
  Design_Delete_Confirmation:"هل أنت متأكد أنك تريد حذف هذا التصميم؟" ,
  Cheque_Pay_Confirmation:"ضع علامة على هذا الشيك كمدفوع؟ لن يظهر في قائمة انتظار الطباعة." ,
  Payment_Pay_Confirmation:"ضع علامة على هذه الدفعة على أنها مدفوعة؟ لن تظهر في قائمة انتظار الشيكات." ,
  Delete_Deduction_Confirmation:"هل أنت متأكد أنك تريد حذف هذا الخصم؟" ,
  Delete_Job_Confirmation:"هل أنت متأكد أنك تريد حذف هذه الوظيفة؟" ,
  Delete_Timesheet_Confirmation:"هل أنت متأكد أنك تريد حذف الجدول الزمني هذا؟" ,
  Delete_Schedule_Confirmation:"هل أنت متأكد أنك تريد حذف هذا الجدول الزمني؟" ,
  Delete_Setting_Confirmation:"هل أنت متأكد أنك تريد إعادة تعيين هذا الإعداد؟" ,
  Delete_Fax_Confirmation:"هل أنت متأكد أنك تريد حذف هذا الفاكس؟" ,
  Delete_File_Confirmation:"هل أنت متأكد أنك تريد حذف هذا الملف؟" ,
  Delete_Vacation_Confirmation:"هل أنت متأكد أنك تريد حذف هذه العطلة؟" ,
  Delete_Printer_Confirmation:"هل أنت متأكد أنك تريد حذف هذه الطابعة؟" ,
  Remove_Design_Confirmation:"هل أنت متأكد أنك تريد حذف هذا التصميم؟" ,
  Delete_Payroll_Confirmation:"هل أنت متأكد أنك تريد حذف قائمة الرواتب هذه؟",
  Send_Fax_Email_Confirmation:"هل تريد إرسال هذه المستندات بالفاكس وإرسالها بالبريد الإلكتروني؟" ,
  Send_Email_Confirmation:"هل تريد إرسال هذا المستند عبر البريد الإلكتروني؟" ,
  Send_Fax_Confirmation:"هل تريد إرسال هذا المستند بالفاكس؟" ,
  Error_Generating_PDF:"معذرة ، حدث خطأ في إنشاء هذا المستند.",
  PDF_Error_Saving_Image:"معذرة ، حدث خطأ أثناء حفظ صورة PDF لهذا المستند." ,
  Test_Printer_Confirmation:"هل تريد طباعة صفحة اختبار على هذه الطابعة؟" ,
  Save_Timesheet_Prompt:"الرجاء إضافة\" عنوان \"أو الضغط على\" بدء الموقت \"للحفظ." ,
  Remove_Geofence_Prompt:"هل أنت متأكد أنك تريد إزالة موقع هذا السياج الجغرافي؟" ,
  Delete_Employee_Confirmation:"هل أنت متأكد أنك تريد حذف ",
  Fire_Employee_Confirmation:"هل أنت متأكد أنك تريد إطلاق"
}