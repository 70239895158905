export const Tt = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"Заказга түләгез:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  Author_Name: "Liam Hogan",
  Copyright:"Copyright & copy; 2023",
  black:"Кара",
  green:"Яшел",
  gold:"Алтын",
  blue:"Зәңгәр",
  brown:"Браун",
  purple:"Алсу",
  pink:"Алсу",
  red:"Кызыл",
  Swatches:"Алмашлар",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Хью",
  Saturation:"Тозу",
  Lightness:"Lightиңеллек",
  Upgrade_To_Pro:"Прога яңарту",
  AllFeaturesInclude:"Барлык үзенчәлекләр дә керә:",
  PrintUnlimitedCheques:"Чикләнмәгән чекларны бастырыгыз",
  PremiumChequeDesigns:"Премиум тикшерү дизайннары",
  ManageUnlimitedEmployees:"Чикләнмәгән хезмәткәрләр белән идарә итү",
  AddUnlimitedPayees:"Чикләнмәгән түләүчеләрне өстәгез",
  CreateUnlimitedPayrolls:"Чикләнмәгән хезмәт хакы булдыру",
  UnlimitedSchedulesandTimeSheets:"Чикләнмәгән расписаниеләр һәм вакыт таблицалары",
  BulkPayPalPayouts:"Күпләп түләү",
  UnlimitedBankAccounts:"Чикләнмәгән банк счетлары",
  ManageMultipleCompanies:"Берничә компания белән идарә итү",
  TrackInsurancePolicies:"Страховкалау политикасы",
  Bio_MetricProtection:"Био-метрик саклау",
  Geo_FenceLock_OutProtection:"Гео-койма ябылу",
  Multiple_Companies_Word:"Чеклар премиясеннән башка берничә компания белән идарә итү мөмкин түгел.",
  PayPal_Payouts_Word:"PayPal түләүләре Чеклар премиясе булмаганда инвалид.",
  PINProtection:"ПИН саклау",
  PasswordProtection:"Серсүзне саклау",
  May_Require_Approval:"Рөхсәт таләп ителергә мөмкин.",
  Subscribe:"Подписать",
  Billed:"Хисап",
  Up:"Upгары",
  Down:"Түбән",
  Positioning:"Позицияләү",
  Marker:"Маркер",
  Drag_Marker:"Маркерны тарту",
  Tagline:"Чекларны бастыру һәм хезмәт хакы таблицасы",
  Marker_Word:"Элементны зурлау өчен Маркерларны кулланыгыз.",
  Pinch_Zoom:"Зурлау",
  Pinch_Word:"Элементны зурайту өчен чымчык.",
  Drag:"Сөйләү",
  Drag_Word:"Элементларны тарту өчен бармагыгызны кулланыгыз.",
  subscription_alias_word:"Авто-яңарту",
  premium_alias_word:"Бер тапкыр яңарту пакеты",
  print_alias_word:"Индивидуаль тикшерүләр",
  mode_alias_word:"Тәртип",
  Pro:"Про",
  Pro_word:"Про версия кирәк.",
  Cant_Delete_Default_Company_Word:"Гафу итегез, сез килешмәгән компанияне бетерә алмыйсыз.",
  Reinsert_Default_Designs:"Килешү дизайннарын яңадан кертү",
  Reinsert_Default_Designs_word:"Килешү дизайннарын яңадан кертергә телисезме?",
  Subscription_Active_Disable_Word:"Бу язылу актив. Чекларга бу язылудан баш тартырга телисезме?",
  Company_Logo:"Компания логотибы",
  ZERO_:"ЗЕРО",
  Disclaimer:"Аннан баш тарту",
  Privacy_Policy:"Шәхсилек сәясәте",
  EULA:"EULA тикшерә",
  Terms_Of_Service:"Куллану шартлары",
  Terms_Of_Use:"Куллану шартлары",
  Refunds:"Кире кайтару политикасы",
  Single_Print:"1 Тикшерегез",
  Two_Prints:"2 чек",
  Five_Prints:"5 тикшерү",
  Ten_Prints:"10 чек",
  Fifteen_Prints:"15 чек",
  Twenty_Prints:"20 чек",
  Thirty_Prints:"30 чек",
  Image_Added:"Рәсем өстәлде",
  Image_Preview:"Рәсем алдан карау",
  No_Image_Was_Selected:"Рәсем сайланмады.",
  Cheques_Unlimited:"Чикләр",
  _Subscription:"Подписка",
  Subscription:"Чеклар - 1 ай",
  Two_Month_Subscription:"Чеклар - 2 ай",
  Three_Month_Subscription:"Чеклар - 3 ай",
  Six_Month_Subscription:"Чеклар - 6 ай",
  Twelve_Month_Subscription:"Чеклар - 12 ай",
  Cheques_Are_Available:"Чеклар бастырырга мөмкин.",
  Upgrade_Required_Two:"Сатып алуны башлау өчен пакетны сайлагыз һәм бәя төймәсенә икеләтә басыгыз. Профессиональ төсле төсле тикшерүләрне секундларда бастырыгыз.",
  Month:"Ай",
  Due:"Бурыч:",
  Expires:"Вакытлары:",
  Subscription_Active:"Подписка актив",
  Subscription_Inactive:"Подписка актив түгел",
  Purchase_Additional_Cheques:"Өстәмә чеклар сатып аласызмы?",
  Printable_Cheque:"Басыла торган чек",
  Printable_Cheques:"Басыла торган чеклар",
  Printable_Cheque_Word:"чек сезнең счетта бар.",
  Printable_Cheques_Word:"чеклар сезнең хисапта бар.",
  Max_Amount_Message:"Сез күрсәткән сумма бу система өчен куелган максималь суммадан артты:",
  Terms_Required_Word:"Чекларны куллануны дәвам итәр алдыннан сез бу килешүгә ризалашырга тиеш.",
  Subscriptions:"Подписка",
  Product_Upgrades:"Яңартулар",
  Mailed_Out_Cheques:"Хатлар",
  Enter_A_Company_Name:"Зинһар, компания исемен кертегез.",
  Single_Cheques:"Бер тикшерү",
  Cheque_Golden:"Алтын тикшерү",
  Cheque_Golden_Window:"Алтын тикшерү дизайны.",
  Cheque_Green:"Яшел тикшерү",
  Cheque_Green_Window:"Яшел тикшерү дизайны.",
  Cheque_Red:"Кызыл тикшерү",
  Cheque_Red_Window:"Кызыл тикшерү дизайны.",
  Cheque_Yellow:"Сары тикшерү",
  Cheque_Yellow_Window:"Сары тикшерү дизайны.",
  Cheque_Statue_of_Liberty:"Ирек хәйкәле",
  Cheque_Statue_of_Liberty_Window:"Азатлык сыны тикшерү дизайны.",
  Cheque_Green_Wave:"Яшел дулкын",
  Cheque_Green_Wave_Window:"Яшел тикшерү дизайны.",
  Cheque_Golden_Weave:"Алтын туку",
  Cheque_Golden_Weave_Window:"Элегант алтын тикшерү дизайны.",
  Cheque_Green_Sun:"Яшел кояш",
  Cheque_Green_Sun_Window:"Тирән һәм тынычландыргыч тикшерү дизайны.",
  Cheque_Blue_Checkers:"Зәңгәр шашка",
  Cheque_Blue_Checkers_Window:"Зәңгәр тикшерү дизайны.",
  Cashiers_Check:"Кассир чек",
  Cashiers_Check_Window:"Кассирны тикшерү стиле шаблоны.",
  Cheque_Aqua_Checkers:"Су тикшерүчеләр",
  Cheque_Aqua_Checkers_Window:"Аквай тикшерү дизайны.",
  Cheque_Golden_Checkers:"Алтын Шашка",
  Cheque_Golden_Checkers_Window:"Алтын тикшерү дизайны.",
  Upgrade_Unavailable:"Яңартулар юк",
  Bank_Code_Protection:"Банк номерын саклау",
  Bank_Code_Protection_Word:"Банк номерларыгызны бу кушымтада бүтән җайланмада яки бүтән кулланучы өчен кулланудан саклагыз. Бу гамәл IRREVERSIBLE. Дәвам итегез?",
  Bank_Code_Protection_Blocked_Word:"Сез кулланырга теләгән банк номерлары бүтән кулланучы яки җайланма өчен сакланган.",
  Bank_Code_Protection_Error_Word:"Санны тикшерү уңышсыз. Зинһар, интернетка тоташыгыз һәм бу банк счетын яңадан өстәргә тырышыгыз.",
  Bank_Code_Protection_Set_Error_Word:"Банк номерын саклау сезнең интернетка тоташуыгызны таләп итә. Зинһар, тоташтырыгыз һәм кабатлап карагыз.",
  Upgrade_Unavailable_Word:"Гафу итегез, без сезне тикшерүдә кыенлыклар кичерәбез. Подписка һәм чекларга яңарту хәзерге вакытта сезнең районда сатып алына алмый.",
  PayPal_Payment_Preview:"PayPal түләү алдан карау",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"PayPal сайлагыз",
  PayPal_Applications:"PayPal кушымталары",
  Purchase_With_Apple_Pay:"Apple Pay белән сатып алу",
  Google_Pay:"Google Pay",
  Companies:"Компанияләр",
  Insurance:"Страховкалау",
  New_PayPal:"Яңа PayPal",
  Pay_By:"Түләү",
  Insure:"Страховкалау",
  Miles:"Майлз",
  Payment_Method:"Түләү ысулы",
  Select_Policies:"Сәясәтне сайлагыз",
  Policies:"Сәясәт",
  Policy:"Сәясәт",
  No_PayPal_Account:"PayPal счеты юк",
  No_Policies:"Страховка политикасы юк",
  New_Policy:"Яңа политика",
  PayPal_Payment:"PayPal түләү",
  PayPal_Payments:"PayPal түләүләре",
  No_Payment_Selected:"Түләү сайланмаган",
  Sending_Payment_Word:"Зинһар, көтегез ... Бу түләү җибәрелә.",
  Sending_Payments_Word:"Зинһар, көтегез ... Түләүләр җибәрелә.",
  No_Payment_Selected_PayPal:"Noибәрү өчен <a routerLink='/folder/Payments'> PayPal түләү </a> юк.",
  Payment_Sent:"Түләү җибәрелде",
  PayPal_Payment_Sent:"Бу түләү PayPal белән җибәрелгән.",
  Copay:"Күчереп язу",
  Dead:"Deadле",
  Alive:"Исән",
  Not_Dead:"Deadле түгел",
  Unclaimed:"Игътибар ителмәгән",
  Attempted:"Сынап карадылар",
  Deceased:"Алдан",
  Claimed:"Игътибар",
  Unpaid:"Түләнмәгән",
  Sending_Payment:"Түләү җибәрү",
  Sending_Payments:"Түләү җибәрү",
  Send_PayPal_Confirmation:"Бу операцияне PayPal белән җибәрергә телисезме?",
  Send_PayPal_Confirmation_Word:"Бу операцияне җибәрү өчен яшел төймәгә басыгыз.",
  Save_Payment_As_Unpaid:"Бу түләүне түләнмәгән итеп саклыйсызмы?",
  Payment_Pay_Confirmation_PayPal:"Бу түләүне түләнгәнчә саклагызмы?",
  No_Policies_Word:"Беренче <a routerLink='/folder/Postage/New'> страховка полисы </a> өстәгез.",
  Timesheet_Multiple_Delete_Confirmation:"Берничә тапкыр таблицаны бетерергә телисезме?",
  Select_Multiple_Timesheets_Prompt:"Вакыт таблицасы сайланмады. Ким дигәндә бер тапкыр таблицаны сайлагыз.",
  Select_Multiple_Policies_Prompt:"Сәясәт сайланмаган. Ким дигәндә бер страховка полисын сайлагыз.",
  Policies_Multiple_Delete_Confirmation:"Сез берничә политиканы бетерергә телисезме?",
  Company:"Компания",
  Add_Company:"Компания өстәргә",
  New_Company:"Компания өстәргә",
  No_Companies:"Компанияләр юк",
  Enable_Company:"Компанияне рөхсәт итегез",
  Select_Company:"Компанияне сайлагыз",
  The_Default_Company:"Килешенгән компания ярлыгы.",
  Manage_Companies:"Компанияләр белән идарә итү",
  No_Companies_Word:"Чеклар килешү буенча компания кулланачак. <br /> <a routerLink='/folder/Company/New'> беренче компания </a> өстәгез.",
  Default_Company:"Килешү компаниясе",
  Cheques_Unlimited_Word:"Чекләр чикләнмәгән, сез теләгәнчә бастырырга мөмкинлек бирә.",
  Cheques_Subscription_Word:"Чекларга язылу сезгә теләгәнчә чекларны бастырырга мөмкинлек бирә.",
  You_Own_This_Product:"Сез бу продуктка ия.",
  Your_Subscription_is_Active:"Сезнең язылу актив.",
  Active_Products:"Активлаштырылган продуктлар",
  Purchase_Confirmation:"Сатып алу",
  Purchase_Cheques:"Чеклар сатып алу",
  Restore_Purchase:"Сатып алуларны торгызу",
  Erase_Purchase:"Сатып алуларны бетерү",
  Successfully_Purchased:"Уңышлы сатып алынды",
  Enter_Your_Licence_Key:"Зинһар, бу продуктны активлаштыру өчен лицензия ачкычын бу биткә кертегез.",
  Licence_Key:"Лицензия ачкычы",
  Enter_Licence_Key:"Лицензия ачкычын кертегез",
  Purchased:"Сатып алынган",
  Enable_Feature:"Функцияне кушу",
  Cancel_Subscription:"Подпискадан баш тарту",
  Subscription_Removed:"Подписка алынды",
  Select_Active_Subscription:"Аны үзгәртү өчен актив язылуны сайлагыз.",
  Remove_Subscription_Word:"Сез бу язылудан баш тартырга телисезме?",
  Delete_Company_Confirmation:"Сез бу компанияне бетерергә телисезме? Кисәтү: Сез сакланган барлык мәгълүматны югалтырсыз!",
  Delete_Default_Company_Word:"Килешмәгән компанияне бетерә алмыйсыз. Кушымтаны яңадан урнаштырырга һәм аны килешенгән хәлгә кайтарырга телисезме? Кисәтү: Сез сакланган барлык мәгълүматны югалтырсыз!",
  Console_Warning_2:"Хәзерге вакытта сезнеке булмаган бу кушымтаны кулланып бернинди валюта белән эшләмәгез.",
  Terms_and_Conditions:"Шартлар һәм килешүләр",
  and_the:"һәм",
  for:"өчен",
  Please_Read_Word:"Зинһар, укыгыз һәм риза",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Кайбер валюта конверсия ставкалары табылмады. Зинһар, интернетка тоташыгыз.",
  Free:"Бушлай",
  DB_Erase_Prompt_2:"Барлык уйлап табучылар базасын тулысынча бетерергә? Кисәтү: Сез барлык САКЛАУ andӘМ ПАБРИКА мәгълүматларын югалтачаксыз!",
  Successfully_Imported:"Уңышлы импорт",
  Select_Postage:"Почта сайлагыз",
  No_Postage:"Почта маркалары юк",
  No_Paid_Postage_Word:"Беренче <a routerLink='/folder/Postage/New'> Түләүле почта </a> маркасын өстәгез.",
  Trial_Complete:"Сынау тәмамланды",
  Please_Upgrade:"Зинһар, бастыруны дәвам итәр өчен чекларны яңартыгыз.",
  Aa:"Аа",
  Color:"Төс",
  Font:"Шрифт",
  Guide:"Белешмәлек",
  Guides:"Белешмәләр",
  Image:"Рәсем",
  Zoom:"Зурайту",
  Logo:"Логотип",
  Bank:"Банк",
  MICR:"MICR",
  Total:"Барлыгы",
  Cancel:"Баш тарту",
  Confirm:"Ышандыр",
  Method:"Метод",
  Biometric_Security:"Био-метрик куркынычсызлык",
  Please_Login_To_Continue:"Алга таба керегез.",
  Complete:"Тәмам",
  Sign_Up:"Теркәлергә",
  Error:"Хата",
  Biometrics:"Био-метрика",
  Percent:"Процент",
  Zero_Percent:"0%",
  Top_Margin:"Marгары Маргин",
  Bottom_Margin:"Түбән Маргин",
  Left_Margin:"Сул Маргин",
  Right_Margin:"Уң Маргин",
  MICR_Margin:"MICR Margin",
  Table_Margin:"Өстәл марҗасы",
  Address_Margin:"Маргин",
  Percentage_:"Процент",
  Vacation_Title:"Каникул исеме",
  Use_PIN:"PIN кулланыгыз",
  Loading__:"Йөкләү ...",
  Design_Title:"Дизайн исеме",
  Authorize:"Рөхсәт итегез",
  Key:"Ачкыч",
  Public_Key:"Ачык ачкыч",
  Private_Key:"Шәхси ачкыч",
  Authenticate:"Тикшерү",
  Last_Payroll:"Соңгы хезмәт хакы",
  Last_Calculation:"Соңгы исәпләү",
  Authorized:"Рөхсәтле",
  Geo_Authorized:"Гео-урнашу: Рөхсәтле",
  Not_Authorized:"Рөхсәт ителмәгән",
  Authorization_Complete:"Рөхсәт тулы",
  Geolocation_Authorization:"Гео-урнашу авторизациясе",
  Out_of_Bounds:"Чиктән",
  Cant_Delete_Default_Design:"Килешенгән дизайнны бетерә алмый.",
  Unauthorized_Out_of_Bounds:"Рөхсәтсез: Чиктән",
  Biometric_Authorization:"Био-метрик авторизация",
  Locating_Please_Wait:"Урын, зинһар, көтегез ...",
  Test_Biometrics:"Био-метриканы сынау",
  Cheque_Margins:"Маргиннарны тикшерегез",
  Percentage_Full_Error:"Процент кырын 100% тан артык итеп куеп булмый.",
  No_Payroll_Text:"<a routerLink='/folder/Employee/New'> Хезмәтче </a> яки <a routerLink='/folder/Payee/New'> Түләүче </a> һәм <routerLink = '/ папка / Расписание өстәргә. / Яңа '> Расписание </a>.",
  Design_Saved:"Дизайн сакланган",
  Default_Design_Selected:"Килешенгән дизайн сайланган",
  Partial_Import:"Өлешчә импорт",
  Partial_Export:"Өлешчә экспорт",
  Entire_Import:"Бөтен импорт",
  Entire_Export:"Бөтен экспорт",
  Existing_Password:"Зинһар, булган серсүзне кертегез:",
  Existing_PIN:"Зинһар, булган PIN-кодны кертегез:",
  Pin_Change_Header:"PIN раслау",
  Pin_Change_SubHeader:"Oldзгәрешне раслау өчен иске PIN номерыгызны кертегез.",
  Pass_Change_Header:"Серсүзне раслау",
  Pass_Change_SubHeader:"Oldзгәрешне раслау өчен иске серсүзне кертегез.",
  PIN_Enter_Message:"Раслау өчен PIN-ны кертегез.",
  Password_Enter_Message:"Раслау өчен серсүзне кертегез.",
  Pin_Updated_Success:"PIN уңышлы яңартылды!",
  Pin_Updated_Fail:"PIN яңартылып булмады.",
  Pass_Updated_Success:"Серсүз уңышлы яңартылды.",
  Pass_Updated_Fail:"Серсүзне яңартып булмады.",
  Preview_Payment:"Түләү",
  Preview_Payroll:"Хезмәт хакын алдан карау",
  No_Payments_Created:"Түләүләр табылмады.",
  Payment_Preview:"Түләү алдан карау",
  Enable_Payee:"Түләүчене рөхсәт итегез",
  Rendered:"Күрсәтелгән",
  No_Email:"Электрон почта юк",
  Setup_Cheques:"Тикшерү",
  name:"Исем",
  address:"Адрес",
  address_2:"2 нче адрес",
  city:"Шәһәр",
  province:"Өлкә",
  postal_code:"Почта / почта индексы",
  country:"Ил",
  username:"Кулланучы исеме",
  full_name:"Тулы исем",
  birthday:"Туган көн",
  email:"Электрон почта",
  phone:"Телефон",
  employees:"Эшчеләр",
  addresses:"Адреслар",
  payment_amount_limit:"Түләү күләме лимиты",
  total_limit:"Гомуми чик",
  payees:"Түләүчеләр",
  description:"Тасвирлау",
  address_line_one:"Беренче юл",
  address_line_two:"Икенче юл",
  image:"Рәсем",
  account_holder:"Хисап хуҗасы",
  cheque_starting_id:"Башлау таныклыгын тикшерегез",
  transit_number:"Транзит номеры",
  institution_number:"Институт номеры",
  designation_number:"Билгеләү номеры",
  account_number:"Хисап номеры",
  currency:"Валюта",
  signature:"Имза",
  payment_payroll_limit:"Түләү лимиты",
  total_limi:"Гомуми чик",
  date:"Дата",
  printed_memo:"Басылган истәлек",
  banks:"Банклар",
  signature_image:"Имза рәсеме",
  address_name:"Адрес исеме",
  notes:"Искәрмәләр",
  design:"Дизайн",
  title:"Титул",
  frequency:"Ешлык",
  fax:"Факс",
  salaries:"Хезмәт хакы",
  salary_ids:"Хезмәт хакы таныклыгы",
  start_time:"Башлау вакыты",
  end_time:"Ахыр вакыт",
  paid:"Түләүле",
  overtime_percentage:"Түләүле процент",
  overtime_amount:"Түләүле күләм",
  first_name:"Исем",
  last_name:"Фамилия",
  moderation:"Модерация",
  create:"Ярат",
  edit:"Редакцияләү",
  destroy:"Destимерү",
  day_start_time:"Day_start_time",
  day_end_time:"Day_end_time",
  fullname:"Исем",
  time:"Вакыт",
  auto_send:"Автоматик җибәрү",
  time_method:"Вакыт методы",
  schedules:"Расписание",
  indefinite_payroll_enabled:"Билгесез куш",
  amount:"Күләм",
  repeat:"Кабатлау",
  always_enabled:"Waysәрвакыт кушылган",
  start_date:"Башлангыч көне",
  end_date:"Ахыр датасы",
  Cheque_Total:"Барысын да тикшерегез",
  Total_Amount:"Тулы сумма:",
  Amounts:"Суммалар:",
  Images:"Рәсемнәр",
  Files:"Файллар",
  Previewing:"Алдан карау:",
  Insert:"Керү",
  Preview_Import:"Импортны алдан карау",
  Entry:"Керү",
  Entries:"Язмалар",
  No_Entries:"Керүләр юк",
  Import_Type:"Импорт төре",
  Select_Details:"Детальләрне сайлагыз",
  Select_Payee:"Түләүчене сайлагыз",
  Enable_Holidays:"Бәйрәмнәрне рөхсәт итегез",
  Disable_Holidays:"Бәйрәмнәрне сүндерегез",
  Wire_Transfer:"Чыбыклы тапшыру",
  New_Export:"Яңа экспорт",
  Export_Data:"Экспорт мәгълүматлары",
  Export_Data_Word:"Экспортлау һәм йөкләү өчен файл төрен сайлагыз.",
  Export_File:"Файлны экспортлау",
  Mode:"Тәртип",
  Times:"Times",
  Widgets:"Виджетлар",
  Slider:"Слайдер",
  Set_Details:"Детальләр куегыз",
  Select_Type:"Типны сайлагыз",
  Display_Slider:"Слайдерны күрсәтү",
  Dashboard_Slider:"Такта слайдеры",
  Dashboard_Mode:"Такта режимы",
  Show_Intro:"Керү",
  Show_Payrolls:"Хезмәт хакын күрсәт",
  Show_Holidays:"Бәйрәмнәрне күрсәт",
  Show_Invoices:"Счет-фактураларны күрсәтегез",
  Show_Cheques:"Чекларны күрсәт",
  Enabled_Widgets:"Виджетлар кушылган",
  Disabled_Widgets:"Инвалид виджетлар",
  Colors:"Төсләр",
  Barcodes:"Штрих-кодлар",
  View_Timers:"Таймерларны карау",
  Gradients:"Градиентлар",
  No_Info:"Мәгълүмат юк",
  Disable:"Сүндер",
  Show_Layer:"Катламнарны күрсәт",
  Hide_Layer:"Катламнарны яшерегез",
  Text_Layer:"Текст катламнары",
  Secondly:"Икенчедән",
  Minutely:"Минут",
  nine_am:"9.00",
  five_pm:"5:00 PM",
  Enable_Address:"Адресны кушу",
  Invalid_File_Type:"Гафу итегез, файлның яраксыз төре сайланды. Ярдәм ителгән файл төре:",
  Error_Updating_Entry:"Гафу итегез, бу язманы яңартуда хата килеп чыкты.",
  Schedule_Timing_Alert:"Хата: башлану графигы бетү вакытыннан соң кыйммәткә куела.",
  Select_Multiple_Payments_Prompt:"Түләүләр сайланмады. Ким дигәндә бер түләү сайлагыз.",
  Select_Multiple_Cheques_Prompt:"Чек сайланмаган. Зинһар, ким дигәндә бер чекны сайлагыз.",
  Select_Multiple_Items_Prompt:"Сайланган әйберләр юк. Зинһар, ким дигәндә бер пункт сайлагыз.",
  Paymemt_Multiple_Delete_Confirmation:"Берничә түләүләрне бетерергә телисезме?",
  Cheque_Multiple_Delete_Confirmation:"Берничә чекны бетерергә телисезме?",
  Payee_Multiple_Delete_Confirmation:"Сез берничә түләүчене бетерергә телисезме?",
  Employee_Multiple_Delete_Confirmation:"Сез берничә хезмәткәрне бетерергә телисезме?",
  MICR_Warning:"Искәрмә: Кайбер принтерлар һәм җайланмалар MICR шрифтын дөрес күрсәтә алмыйлар.",
  Automatically_Send:"Автоматик җибәрү",
  Type:"Тип",
  Payment_Type:"Түләү төре",
  Auto_Send:"Авто җибәрү",
  Automatically_Process:"Автоматик процесс",
  Auto_Process:"Авто процесс",
  Image_Based:"Рәсемгә нигезләнгән",
  Font_Based:"Шрифтка нигезләнгән",
  Rerender:"Кабатлау",
  Rendering:"Рендеринг",
  Render:"Файллар",
  Top:".Гары",
  Middle:"Урта",
  Bottom:"Түбәндә",
  Top_Left:"Өстә сул",
  Top_Center:"Topгары Centerзәк",
  Top_Right:"Rightгары уңда",
  Middle_Left:"Урта сул",
  Middle_Center:"Урта Centerзәк",
  Middle_Right:"Урта уң",
  Bottom_Left:"Түбән сулда",
  Bottom_Center:"Түбән Centerзәк",
  Bottom_Right:"Түбән уңда",
  Numbers:"Саннар",
  Verified:"Тикшерелгән",
  Paper_Size:"Кәгазь размеры",
  New_Device:"Яңа җайланма",
  Add_Device:"Devайланма өстәгез",
  Remove_Device:"Devайланманы бетерегез",
  Delete_Device:"Devайланманы бетерү",
  Block_Device:"Devайланманы блоклау",
  Block:"Блок",
  Unblock:"Блокны ачу",
  Table:"Таблица",
  Scan_Login_Code:"Керү кодын сканерлау",
  Login_Code:"Керү коды",
  Scan_Code:"Сканерлау коды",
  Scan_QR_Code:"QR кодны сканерлау",
  Select_All:"Барысын да сайлагыз",
  Deselect_All:"Барысын да сайлагыз",
  Increase:"Күтәрелү",
  Decrease:"Азайту",
  Bold:"Калын",
  Text:"Текст",
  Style:"Стиль",
  Italic:"Италик",
  QR_Code:"QR коды",
  Barcode:"Штрих-код",
  Browse_Images:"Рәсемнәрне карау",
  Browse_Files:"Файлларны карау",
  Background_Image:"Фон рәсем",
  Logo_Image:"Логотип Рәсем",
  Header_Image:"Баш рәсем",
  Bank_Image:"Банк образы",
  Cut_Lines:"Сызыкларны кисегез",
  Background:"Фон",
  License:"Лицензия",
  One_License:"1 Лицензия:",
  Licensed:"Лицензияләнгән:",
  Not_Licensed:"Лицензияле түгел",
  Enter_Serial:"Сериягә керегез",
  Serial_Key:"Серия ачкычы",
  Serial:"Серия",
  Product_Key:"Продукция ачкычы",
  Check_Product_Key:"Продукция ачкычын тикшерегез",
  Add_Product_Key:"Продукция ачкычын кертегез",
  Verifying_Purchase:"Сатып алуны тикшерү ...",
  Print_Envelope:"Конвертны бастыру",
  Envelope:"Конверт",
  Thank_You:"Рәхмәт!",
  Scale:"Масштаб",
  Print_Scale:"Масштаб бастыру",
  Borders:"Чикләр",
  VOID:"Тавыш",
  Void_Cheque:"Тикшерү",
  NO_DOLLARS:"ДОЛЛАР юк",
  ONE_DOLLAR:"БЕР ДОЛЛАР",
  DOLLARS:"КУЛЛАР",
  AND:".Әм",
  CENTS:" ENTентрлар.",
  NO_:"NOК",
  ONE_:"БЕР",
  AND_NO_:"NOК",
  _AND_ONE_:"ONӘМ БЕР",
  DOLLARS_AND_ONE_CENT:"ONӘМ БЕР ENTЕНТ.",
  AND_NO_CENTS:" ZӘМ ЗЕРО ENTЕНТЛАР.",
  CHEQUE_PRINT_DATE:"ДАТА:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_SIGNATUREMP:"Депутат",
  Initial_Tasks:"Башлангыч биремнәр",
  Inital_Setup:"Башлангыч көйләү",
  Welcome_To:"Рәхим итегез",
  Welcome:"Рәхим итегез",
  Swipe:"Сөртү",
  Intro_Setup:"Керү",
  Introduction:"Кереш сүз",
  CHEQUE_PRINT_CREDIT:"Чеклар белән эшләнә",
  Title_Intro_Word:"Чекларга рәхим итегез",
  Title_Intro:"Кереш тикшерүләр",
  Title_Setup:"Тикшерү",
  PayPal_Default_Email_Message:"Сез яңа PayPal күчерүен алдыгыз.",
  Cheques_App_Export:"Чеклар",
  No_Prints_Purchased:"Басмалар юк",
  No_Prints_Purchased_Word:"Өстәмә басмалар юк. Зинһар, өстәмә чеклар сатып алыгыз.",
  Post_Intro_Thanks:"Чекларны сайлаган өчен рәхмәт. Хәзер урнаштыру процессы тәмамланды.",
  Post_Intro_Word:"Беренче чекны бастырып башлап җибәрегез, киләчәк түләүне өстәгез, яисә хезмәт хакына хезмәткәрләр өстәгез.",
  Upgrade_Required:"Чеклар бу хәбәрне яшерү һәм өстәмә үзенчәлекләрне ачу өчен программа тәэминатының премиум версиясенә ия булырга тиеш.",
  Upgrade_Title:"Чеклар",
  Mailout_Prompt:"Сез өстәмә рәвештә сезнең өчен хезмәт хакы тикшерүләрен тикшерә аласыз.",
  Mailed_Cheque:"Почта аша тикшерү",
  Mailed_Cheque_Color:"Почта тикшерү (төс)",
  Terms_Purchase:"Чеклар белән барлык электрон сатып алулар сатып алынган көннән 30 көнгә кадәр тулысынча кире кайтарыла. Зинһар, укыгыз һәм риза",
  Dashboard_Setup:"Беренчел принтерны урнаштыру",
  Dashboard_Add:"Башлангыч банк счетын өстәгез",
  Dashboard_Customize:"Шаблонны сайлагыз",
  Dashboard_Job_Salary:"Эшегезне булдырыгыз һәм хезмәт хакыгызны өстәгез",
  Dashboard_Employees:"Хезмәткәрләрне һәм түләүчеләрне күзәтегез",
  Dashboard_Print:"Сезнең хезмәт хакыгызны бастырыгыз һәм җибәрегез",
  Dashboard_Payroll:"Сезнең хезмәт хакыгызны бастыруны автоматлаштыру",
  Dashboard_PayPal:"PayPal хезмәт хакы / түләү",
  Begin_Setup:"Урнаштыруны башлау",
  Get_Started:"Башлау",
  Purchase:"Сатып алу",
  Lockdown:"Блоклау",
  Unlock:"Блокны ачу",
  Detailed:"Деталь",
  Log_In:"Керергә",
  Login:"Керергә",
  Launch:"Башлау",
  Register:"Регистрация",
  Finish:"Тәмам",
  List:"Исемлек",
  Weekends:"Ял көннәре",
  Weekly:"Атна саен",
  PayPal_Default_Subject:"Яңа түләү",
  Payment_Message:"Түләү хәбәре",
  PayPal_Default_Payment_Note:"Рәхмәт",
  Setup_Your_Cheqing_Account:"Хисапны тикшерү",
  Add_Your_Primary_Cheqing_Account:"Төп тикшерү счетын өстәгез.",
  Welcome_Word:"Хезмәт хакы һәм кеше ресурслары белән идарә итүне гадиләштерү һәм автоматлаштыру.",
  Get_Started_Quickly:"Башларга ярдәм итәчәк берничә гади сорауга җавап бирегез ...",
  Done_Intro_Word:"Урнаштыру тулы",
  PIN_Protected:"Серсүз & PIN сакланган",
  Enter_New_PIN:"Яңа PIN код кертегез:",
  Enter_PIN:"PIN кодны кертегез:",
  Invalid_PIN:"Яраксыз PIN керде.",
  Account_Identifier:"Хисап идентификаторы",
  New_Account_Identifier:"Яңа хисап идентификаторы",
  attempt:"омтылыш",
  attempts:"омтылышлары",
  remaining:"калган",
  Language:"Тел",
  languages:"Телләр",
  select_languages:"Телне сайлагыз",
  Deposit:"Депозит",
  Hire_One_Now:"Хәзер эшкә алыгыз",
  App_Locked:"Кушымта бикле.",
  Skip_:"Сикереп тор",
  Skip_to_Dashboard:"Тактага күчү",
  Dashboard:"Такталар",
  Import:"Импорт",
  Admin:"Администраторлар",
  New_Admin:"Яңа Администратор",
  Settings:"Көйләүләр",
  Color_Picker:"Төс сайлау",
  Font_Picker:"Шрифт сайлау",
  Logout:"Чыгарга",
  Close:"Ябу",
  Close_menu:"Ябу",
  Excel:"Excel Файл",
  Csv:"CSV файл",
  Sql:"SQL Файл",
  Json:"JSON Файл",
  Url:"URL буенча импорт",
  Back:"Кире",
  Timers:"Таймерлар",
  Cheque:"Тикшерегез",
  Print:"Басма",
  Designs:"Дизайннар",
  Pause_Printing:"Басуны туктату",
  Resume_Printing:"Басуны дәвам итү",
  Printing_Paused:"Басма туктады",
  PrintingUnavailable:"Гафу итегез! Бу системада бастыру мөмкин түгел.",
  Prints_Paused:"Басмалар туктатылды",
  Administration:"Администрация",
  Loading:"Йөкләү",
  Unnamed:"Исемсез",
  error:"Гафу итегез, бу чекны карау өчен ачып булмады.",
  No_Cheques_To_Print:"Басу өчен тикшерүләр юк",
  No_Cheques_To_Print_Word:"<a routerLink='/folder/Cheque/New'> Яңа тикшерү </a> ясагыз.",
  New_Cheque:"Яңа тикшерү",
  Start_One_Now:"Берсен хәзер башлау",
  Edit_Cheque:"Чекны үзгәртү",
  Select_Cheques:"Чекларны сайлагыз",
  Select_Employee:"Хезмәткәрне сайлагыз",
  Default_Printer:"Килешү принтер",
  Reassign:"Кабатлау",
  Configure:"Конфигурацияләү",
  Template:"Шаблон",
  Designer:"Дизайнер",
  Edit_Designs:"Дизайннарны үзгәртү",
  New_Design:"Яңа дизайн",
  Next:"Алга",
  Save:"Саклагыз",
  Name:"Исем",
  Mail:"Почта",
  Amount:"Күләм",
  Date:"Дата",
  PayPal:"PayPal",
  Payouts:"Түләү",
  PayPal_Label:"PayPal ярлыгы",
  Email_Username:"Электрон почта / кулланучы исеме",
  Client_ID:"Клиент таныклыгы",
  Sandbox_Email:"Сандугач электрон почтасы",
  PayPal_Email:"PayPal электрон почтасы",
  PayPal_Username:"API кулланучы исеме",
  PayPal_Payouts:"PayPal түләүләре",
  Select_PayPal_Key:"PayPal ачкычын сайлагыз",
  Secret:"Сер",
  API_Secret:"API сере",
  PayPal_API_Keys:"PayPal ачкычлары",
  New_PayPal_Key:"Яңа PayPal ачкычы",
  Email_Subject:"Электрон почта темасы",
  Email_Message:"Электрон почта хәбәре",
  Payout_Options:"Түләү вариантлары",
  Payment_Note:"Түләү турында искәрмә",
  Enable_Employee:"Хезмәткәрне рөхсәт итү",
  Enable_Production_Mode:"Producитештерү режимын эшләгез",
  Sandbox_Username:"Сандбокс кулланучы исеме",
  Sandbox_Signature:"Сандугач имзасы",
  Sandbox_Password:"Сандугач серсүз",
  Production_Username:"Кулланучының исеме",
  Production_Signature:"Производство имзасы",
  Production_Password:"Производство серсүзе",
  Production_Email:"Электрон почта",
  API_Client_ID:"API клиент ID",
  API_Signature:"API имзасы",
  API_Password:"API серсүз",
  API_Username:"API кулланучы исеме",
  Secret_Key:"Серле ачкыч",
  Sandbox:"Сандугач",
  Production:"Producитештерү",
  Sandbox_Keys:"Сандбокс ачкычлары",
  Production_Keys:"Производство ачкычлары",
  API_Title:"API исеме",
  Production_Mode:"Производство режимы",
  Account_Label:"Хисап язмасы",
  No_PayPal_Setup:"PayPal ачкычы юк",
  Enable_PayPal_Account:"PayPal счетын кушу",
  No_PayPal_Word:"Сезнең <a routerLink='/folder/Invoice/New'> PayPal API ачкычы </a> өстәгез.",
  Printed_Memo:"Басылган истәлек",
  Employee:"Хезмәтче",
  View_Employee:"Хезмәткәрне карау",
  Mailing_Address:"Почта адресы",
  Company_Address:"Компания адресы",
  Select_Company_Address:"Компания адресын сайлагыз",
  Address:"Адрес",
  Any_Day:"Теләсә нинди көн",
  Address_Name:"Адрес исеме",
  Unit:"Берәмлек",
  Account:"Хисап",
  Bank_Account:"Банк хисабы",
  Account_Limits:"Хисап чикләрен кушу",
  Payroll:"Хезмәт хакы",
  Run:"Йөгер",
  Run_Payroll:"Хезмәт хакын эшләгез",
  New_Payroll:"Яңа хезмәт хакы",
  No_Payroll:"Киләчәк хезмәт хакы юк",
  Upcoming_Holiday:"Киләчәк бәйрәм:",
  Invoice_Due:"Счет-фактурасы:",
  New_Invoice:"Яңа счет-фактурасы",
  No_Invoices:"Счет-фактуралар юк",
  No_Invoices_Word:"Беренче <a routerLink='/folder/Invoice/New'> Фактура </a> ясагыз.",
  Cheque_Due:"Тикшерү:",
  Payrolls:"Хезмәт хакы",
  Sandbox_Mode:"Сандугач режимы",
  Hire:"Аренда",
  Pay:"Түләү",
  New:"Яңа",
  Add:"Кушу",
  Make:"Ясарга",
  Time:"Вакыт",
  Write:"Яз",
  Holiday:"Бәйрәм",
  Holidays:"Каникуллар",
  Next_Holiday:"Киләсе бәйрәм:",
  All_Done:"Барысы да эшләнде!",
  Employees:"Эшчеләр",
  Payees:"Түләүчеләр",
  Job:"Эш",
  Jobs:"Эшләр",
  Invoice:"Фактура",
  Invoices:"Счет-фактуралар",
  Vacations:"Каникуллар",
  Cheques:"Чеклар",
  Brand_Cheques:"Сезнең бренд",
  Payment:"Түләү",
  Enable_Payment:"Түләү мөмкинлеген бирегез",
  Payments:"Түләү",
  Schedule:"Расписание",
  Schedules:"Расписание",
  Timesheet:"Вакыт таблицасы",
  Timesheets:"Вакыт таблицалары",
  Salary:"Хезмәт хакы",
  New_Address:"Яңа адрес",
  Address_2:"Адрес (2 юл)",
  _City:"Шәһәр",
  _State:"Дәүләт / Пров",
  City:"Шәһәр / поселок",
  State:"Дәүләт / өлкә",
  Postal:"Почта / почта индексы",
  ZIP:"Почта / почта",
  Country:"Ил",
  Addresses:"Адреслар",
  Required_Options:"Кирәкле вариантлар",
  Optional_Options:"Ихтимал вариантлар",
  Additional_Options:"Өстәмә вариантлар",
  Required:"Кирәк",
  Optional:"Ихтимал",
  Additional:"Өстәмә",
  No_Addresses:"Адреслар юк",
  New_Address_Word:"Беренче <a routerLink='/folder/Address/New'> Адрес </a> өстәгез.",
  Select_Address:"Адресны сайлагыз",
  No_Address:"Адреслар юк",
  Print_Cheque:"Басма",
  Print_Cheques:"Чекларны бастыру",
  Print_Cheque_Now:"Хәзер тикшерү",
  Description:"Тасвирлау",
  Pay_To_The_Order_Of:"Орденга түләү:",
  Select_Date_Range:"Дата диапазонын сайлагыз",
  Select_Starting_Date:"Башлау датасын сайлагыз",
  Select_Ending_Date:"Ахыр датасын сайлагыз",
  Select_Date:"Датаны сайлагыз",
  Cheque_Date:"Датаны тикшерегез",
  Cheque_Memo:"Хәтерне тикшерегез",
  Blank_Cheque:"Буш тикшерү",
  Blank:"Буш",
  No_Cheques:"Чек юк",
  No_Cheques_Word:"Беренче <a routerLink='/folder/Cheque/New'> тикшерегез </a>.",
  Cheque_Amount_Placeholder:" $ 0.00",
  View_Image:"Рәсемне карау",
  View:"Карарга",
  Modify:".Згәртү",
  Delete:"Бетерү",
  Cheque_Paid:"Түләүле",
  New_Deduction:"Яңа ташлама",
  Title:"Титул",
  Frequency:"Ешлык",
  Hourly:"Сәгать саен",
  Daily:"Көн саен",
  Weekdays:"Ял көннәре",
  BiWeekly:"2 атна",
  TriWeekly:"3 атна",
  Monthly:"Ай саен",
  MiMonthly:"2 ай",
  Quarterly:"Квартал",
  Yearly:"Ел саен",
  Every_Week:"Атна саен",
  Every_Payroll:"Everyәр хезмәт хакы",
  Every_Month:"Everyәр ай",
  Annually:"Ел саен",
  Always_Scheduled:"Waysәрвакыт планлаштырылган",
  Start_Date:"Башлангыч көне",
  End_Date:"Ахыр датасы",
  Start_Time:"Башлау вакыты",
  End_Time:"Ахыр вакыт",
  Deduction_Label:"Аеру этикеткасы",
  Notes:"Искәрмәләр",
  Options:"Вариантлар",
  Enable:"Килеш",
  Select_Deductions:"Аеруларны сайлагыз",
  Deductions:"Чыгарылышлар",
  No_Deductions:"Чыгарылышлар юк",
  No_Deductions_Word:"Беренче <a routerLink='/folder/Deduction/New'> Deduction </a> ясагыз.",
  New_Employee:"Яңа хезмәткәр",
  No_Title:"Титул юк",
  _Name:"Исем",
  About_Yourself:"Yourselfзең турында",
  Full_Name:"Тулы исем",
  Birthday:"Туган көн",
  Email:"Электрон почта",
  SMS:"SMS",
  Phone:"Телефон",
  Test:"Тест",
  Call:"Шалтыратыгыз",
  Fax:"Факс",
  Printed_Note:"Басылган искәрмә",
  Position:"Позиция",
  Job_Position:"Эш урыны",
  Select_a_Job:"Эш сайлагыз",
  Select_a_Salary:"Хезмәт хакын сайлагыз",
  Add_a_Deduction:"Керү",
  Taxes:"Салым",
  Add_Taxes:"Салым өстәгез",
  Date_of_Birth:"Туган көн",
  Email_Address:"Мэйл адресы",
  Phone_Number:"Телефон номеры",
  Phone_Call:"Телефон шалтыратуы",
  Enable_on_Payroll:"Хезмәт хакын рөхсәт итегез",
  Select_Employees:"Эшчеләрне сайлагыз",
  No_Employees:"Хезмәткәр юк",
  No_Employees_Word:"Беренче яңа <a routerLink='/folder/Employee/New'> Хезмәтче </a> өстәгез.",
  No_Name:"Исем юк",
  Unemployeed:"Эшсез",
  Employeed:"Эштә",
  Paid:"Түләүле",
  Enabled:"Эшләтелгән",
  Disabled:"Инвалид",
  Fire:"Ут",
  Not_Available:"Кулланып булмый",
  Not_Available_Word:"Беренче <a routerLink='/folder/Invoice/New'> Фактура </a> бастырыгыз һәм түләгез.",
  Select_Invoices:"Счет-фактураны сайлагыз",
  Print_Invoice_Word:"Беренче <a routerLink='/folder/Invoice/New'> Фактура </a> бастырыгыз һәм түләгез.",
  Invoice_Title:"Фактура исеме",
  Invoice_Date:"Фактура датасы",
  Due_Date:"Түләү срогы",
  New_Job:"Яңа эш",
  Details:"Детальләр",
  Customize:"Көйләгез",
  Customize_Dashboard:"Тактаны көйләгез",
  Components:"Компонентлар",
  No_Components:"Компонентлар юк",
  Main_Components:"Төп компонентлар",
  Smaller_Components:"Кечкенә компонентлар",
  Error_Loading_Page:"Бу битне йөкләүдә хата.",
  Bank_Details:"Банк мәгълүматлары",
  About_Your_Job:"Сезнең эш турында",
  Your_Schedule:"Сезнең график",
  Job_Title:"Хезмәт урыны",
  Job_Description:"Эш тасвиры",
  Job_Details:"Эш турында мәгълүмат",
  Enable_Job:"Эшне рөхсәт итегез",
  Pay_Period:"Түләү вакыты",
  Perpetually_Schedule:"Мәңгелек график",
  Select_Jobs:"Эшләрне сайлагыз",
  No_Jobs:"Эш юк",
  Add_Jobs:"Эш өстәү",
  No_Jobs_Word:"Исемлеккә беренче <a routerLink='/folder/Job/New'> Эш </a> өстәгез.",
  Count_Employees:"job.employee_count + 'Эшчеләр'",
  Zero_Employees:"0 Эшчеләр",
  New_Leave:"Яңа отпуск",
  Leave_Name:"Исем калдыру",
  Paid_Leave:"Түләүле отпуск",
  Leave_Pay:"Түләүдән китегез",
  Indefinite_Leave:"Билгесез китү",
  Indefinite_Payroll:"Билгесез хезмәт хакы",
  Leave:"Кит",
  Add_Schedules:"Расписание өстәү",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Abк",
  Select_Leaves:"Яфракларны сайлагыз",
  No_Leaves:"Abк",
  Leave_Of_Absence:"Enceк",
  Leaves_Of_Absence:"Abклык яфраклары",
  New_Leave_of_Absense:"Яңа юклык",
  No_Leaves_Word:"Беренче <a routerLink='/folder/Leave/New'> юклык рөхсәте </a> өстәгез.",
  Not_Enabled:"Кушылмаган",
  Absences:"К",
  Payee:"Түләүче",
  New_Payee:"Яңа түләүче",
  Payee_Identifier:"Түләүче идентификаторы",
  Payee_Name:"Түләүче исеме",
  Payee_Title:"Түләүче исеме",
  Payment_Memo:"Түләү турында истәлек",
  Select_Payees:"Түләүчеләрне сайлагыз",
  No_Payees:"Түләүчеләр юк",
  Add_Payee_Note:"Беренче <a routerLink='/folder/Payee/New'> түләүче </a> өстәгез.",
  New_Payees:"Яңа түләүчеләр",
  About_The_Payment_Schedule:"Түләү графигы",
  Your_Payroll_Schedule:"Автоматик хезмәт хакы",
  New_Payment:"Яңа түләү",
  Identifier:"Идентификатор",
  Selected:"Сайланган",
  Select:"Сайлагыз",
  Memo:"Хәтер",
  Payment_Date:"Түләү датасы",
  Mark_as_Paid:"Түләүле итеп билгеләргә",
  Select_Payments:"Түләүләрне сайлагыз",
  No_Payments:"Түләү юк",
  No_Payments_Word:"Беренче <a routerLink='/folder/Payment/New'> Түләү </a> ясагыз.",
  Create_Payroll:"Хезмәт хакы булдыру",
  Properties:"Сыйфатлар",
  Payroll_Title:"Хезмәт хакы исеме",
  Payroll_Notes:"Хезмәт хакы турында искәрмәләр",
  Payroll_Setup:"Хезмәт хакын көйләү",
  Tabulate_Payments:"Түләү таблицасы",
  Tabulate:"Таблица",
  By:"Автор:",
  Payments_By:"Түләүләр",
  Timesheets_And_Schedules:"Вакыт таблицалары һәм расписаниеләр",
  Both:"Икесе дә",
  Items:"Предметлар",
  Add_Payees:"Түләүчеләрне өстәгез",
  Add_Account:"Хисап өстәү",
  Enable_Account:"Хисапны кушу",
  Enable_Payroll:"Хезмәт хакын кушу",
  Print_Payroll:"Хезмәт хакын бастыру",
  No_Payrolls:"Хезмәт хакы юк",
  No_Payroll_Word:"Беренче <a routerLink='/folder/Payroll/New'> хезмәт хакы </a> ясагыз.",
  View_more:"VIEW_MORE",
  Less:"КИМРӘК",
  Add_Payroll:"Хезмәт хакын өстәгез",
  Select_Payroll:"Хезмәт хакын сайлагыз",
  About_Your_Salary:"Сезнең хезмәт хакы турында",
  Add_Salaries:"Хезмәт хакы өстәргә",
  Add_Salary:"Хезмәт хакы өстәргә",
  Salaries:"Хезмәт хакы",
  No_Salaries:"Хезмәт хакы юк",
  No_Salaries_Word:"Беренче <a routerLink='/folder/Salary/New'> хезмәт хакы </a> өстәгез.",
  Select_Salaries:"Хезмәт хакын сайлагыз",
  New_Salary:"Яңа хезмәт хакы",
  Salary_Name:"Хезмәт хакы идентификаторы",
  Enable_Salary:"Хезмәт хакын кушу",
  Salary_Amount:"Хезмәт хакы күләме",
  New_Schedule:"Яңа график",
  Schedule_Title:"Расписание исеме",
  Add_Address:"Адрес өстәгез",
  Repeat:"Кабатлау",
  Design:"Дизайн",
  Edit_Design:"Дизайнны үзгәртү",
  Edit_this_Design:"Бу дизайнны үзгәртү",
  Repeat_Payment:"Түләү",
  Enable_Schedule:"Расписание кушу",
  Never:"Беркайчан да",
  Select_Schedule:"Расписаниеләрне сайлагыз",
  No_Schedules:"Расписание юк",
  No_Schedules_Word:"Беренче <a routerLink='/folder/Schedule/New'> Расписание </a> ясагыз.",
  New_Administrator:"Яңа Администратор",
  Username:"Кулланучы исеме",
  First_Name:"Исем",
  Last_Name:"Фамилия",
  Add_an_Address:"Адрес өстәгез",
  Payment_Limit:"Түләү лимиты",
  Total_Limit:"Гомуми чик",
  Select_Accounts:"Хисапларны сайлагыз",
  No_Administrators:"Администратор юк",
  No_Administrators_Word:"Беренче <a routerLink='/folder/Administrator/New'> Администратор счеты </a> ясагыз.",
  New_Administrators_Word:"Беренче <a routerLink='/folder/Administrator/New'> Администратор </a> өстәгез",
  Cloud:"Болыт",
  Backup:"Резервлау",
  Enable_iCloud:"ICloud кушу",
  Enable_Google_Drive:"Google Drive'ны кушу",
  Enable_Microsoft_OneDrive:"Microsoft OneDrive кушу",
  Automatically_Backup:"Автоматик резервлау",
  FTP_Settings:"FTP көйләүләре",
  URL_File_Prompt:"Зинһар, импорт өчен .xls / .xlsx / .json файлының урнашкан урынын күрсәтегез:",
  URL_SQL_Prompt:"Зинһар, импорт өчен SQLite файлының урнашкан урынын күрсәтегез:",
  FTP_Backup:"FTP резервлау",
  FTP_Import:"FTP импорт",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Алып баручы",
  Port:"Порт",
  Path:".Л",
  Data_Path:"Мәгълүматлар юлы",
  Enable_FTP_Account:"FTP счетын кушу",
  HostnameIP:"Хост-исем",
  Password:"Серсүз",
  Connection_Port:"Бәйләнеш порты",
  Enable_MySQL_Database:"MySQL мәгълүмат базасын эшләгез",
  Log:"Керегез",
  Reset:"Бетерү",
  Erase:"Бетерү",
  Export:"Экспорт",
  Database:"Мәгълүматлар базасы",
  Upload_CSV:"CSV йөкләү",
  Download_CSV:"CSV йөкләү",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL резервлау",
  Internal_Notes:"Эчке язмалар",
  Root_Path:"Тамыр юлы",
  Select_Backup:"Резервны сайлагыз",
  Add_New_Backup:"Яңа резервны өстәгез",
  First_Backup:"Беренче резервны урнаштырыгыз ...",
  Backups:"Резервлау",
  Add_Backup:"Резервны өстәгез",
  No_Backups:"Резервлар табылмый.",
  Select_Backup_Type:"Сез урнаштырырга теләгән резерв төрен сайлагыз ...",
  Backup_Type:"Резерв төре",
  FTP_Drive:"FTP Drive",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Драйвер",
  Microsoft_OneDrive:"Драйвер",
  Import_Fields:"Импорт кырлары",
  Import_Fields_Word:"<a routerLink='/folder/Holiday/New'> Импорт </a> мәгълүматларын сайлау өчен бу бүлекне кулланыгыз.",
  Upload:"Йөкләү",
  Download:"Йөкләү",
  Download_JSON:"JSON Мәгълүматы итеп йөкләү",
  Download_SQL:"SQL Файл итеп йөкләү",
  New_Bank:"Яңа Банк",
  New_Account:"Яңа хисап",
  New_Bank_Account:"Яңа банк счеты",
  Upload_Image:"Рәсемне йөкләү",
  Bank_Name:"Банк исеме",
  Bank_Address:"Банк адресы",
  Branch_Address:"Филиал адресы",
  Address_on_Cheque:"Адрес",
  Cheque_Numbers:"Саннарны тикшерегез",
  Cheque_Details:"Детальләрне тикшерегез",
  Bank_Logo:"Банк логотибы",
  Cheque_ID:"ID таныклыгын тикшерегез",
  Starting_Cheque_ID:"Тикшерү таныклыгы",
  Transit_Number:"Транзит номеры",
  Institution_Number:"Институт номеры",
  Designation_Number:"Билгеләү номеры",
  Account_Number:"Хисап номеры",
  Limits:"Чикләр",
  Default_Limits:"Килешү чикләре",
  Over_Limit:"Чиктән тыш",
  Under_Limit:"Чик астында",
  Payroll_Limit:"Хезмәт хакы чикләре",
  Enable_Bank_Account:"Банк счетын рөхсәт итегез",
  Select_Account:"Хисапны сайлагыз",
  No_Bank_Account:"Банк счеты юк",
  No_Bank_Account_Word:"Беренче <a routerLink='/folder/Accounts/New'> Банк счеты </a> өстәгез.",
  Bank_Accounts:"Банк счетлары",
  No_Accounts:"Хисаплар юк",
  No_Accounts_Note:"Беренче <a routerLink='/folder/Accounts/New'> Банк счеты </a> өстәгез.",
  Cheque_Designer:"Дизайнерны тикшерегез",
  Cheque_Design:"Дизайнны тикшерегез",
  Select_Design:"Дизайнны сайлагыз",
  Cheque_Designs:"Дизайннарны тикшерегез",
  No_Cheque_Designs:"Тикшерү дизайны юк",
  No_Cheque_Designs_Word:"<a <a routerLink='/folder/Settings/Cheque/Design/New'> Дизайнны тикшерү </a> ясагыз.",
  Set_Default:"Килешү итеп куегыз",
  Default:"Килешү",
  Remove:"Чыгар",
  Folder:"Папка",
  Edit:"Редакцияләү",
  LoadingDots:"Йөкләү ...",
  Add_a_New_File:"<a href='#' (click)='add()'> Яңа Файл </a> өстәргә",
  this_folder:"бу папка",
  FTP_Backup_Settings:"FTP резерв көйләүләре",
  Secure_File_Transfer:"Куркынычсыз файл тапшыру",
  New_Holiday:"Яңа бәйрәм",
  Add_Holiday:"Бәйрәм өстәгез",
  Holiday_Name:"Бәйрәм исеме",
  Additional_Pay:"Өстәмә түләү",
  Enable_Holiday:"Бәйрәмне кушу",
  Select_Holidays:"Бәйрәмнәрне сайлагыз",
  No_Holidays:"Бәйрәм юк",
  No_Holidays_Word:"Беренче <a routerLink='/folder/Holiday/New'> җәмәгать бәйрәме </a> өстәгез.",
  New_Import:"Яңа импорт",
  Import_Data:"Мәгълүматны импортлау",
  Import_Data_Word:"Сез сайлаган файлның төрен яки йөкләү ысулын сайлагыз. <br /> Файлдан кертелгән кырларның кайсысы кушымтадагы параметрларга туры килүен сайлый аласыз, ярдәмче файлны йөкләгәннән соң.",
  Import_File:"Файлны импортлау",
  Link_To_File:"Файлга сылтама",
  Select_File:"Файлны сайлагыз",
  New_Moderator:"Яңа модератор",
  Allow_Moderation:"Модерациягә рөхсәт итегез",
  Create_Paycheques:"Paycheques ясагыз",
  Edit_Paycheques_and_Data:"Paycheques һәм Мәгълүматны үзгәртү",
  Destroy_Data_and_Paycheques:"Мәгълүматны һәм хезмәт хакын юк итү",
  Bonus_Percentage:"Түләү проценты",
  Fixed_Amount:"Билгеләнгән сумма",
  Select_Moderator:"Модераторны сайлагыз",
  No_Moderators:"Модераторлар юк",
  Moderators:"Модераторлар",
  Moderator_Account:"Беренче <a routerLink='/folder/Administrator/New'> Модератор Хисап </a> ясагыз.",
  No_Moderators_Word:"Беренче <a routerLink='/folder/Administrator/New'> Модератор </a> өстәгез.",
  Defaults:"Килешүләр",
  Provide_Us_Info:"Мәгълүмат бирегез",
  Setup_Your_Printer:"Принтерыгызны урнаштырыгыз",
  Your_Company:"Сезнең компания турында",
  Company_Name:"Оешма исеме",
  Currency:"Валюта",
  Default_Currency:"Килешенгән валюта",
  Base_Monthly_Income:"Айлык керемнәр",
  Protection:"Саклау",
  App_Protection:"Кушымта саклау",
  PIN_Code_Protection:"PIN кодны саклау",
  App_Protection_Word:"Сезнең счетыгызны сакларга ярдәм итүче куркынычсызлык ысулларын эшләгез.",
  PIN_Code:"PIN коды",
  Change_PIN:"PIN үзгәртү",
  New_Password:"Яңа серсүз",
  Geofence_Protection:"Гео-коймадан саклау",
  Geofence_Area:"Районны куегыз",
  Distance:"Дистанция",
  Setup_Now:"Хәзер урнаштыру",
  Mile:"Мил",
  Km:"Км",
  m:"м",
  Facial_Recognition:"Йөзне тану",
  Face:"Йөз",
  Setup:"Кору",
  Label:"Ярлык",
  Password_Protection:"Серсүзне саклау",
  Modify_Password:"Серсүзне үзгәртү",
  New_Tax_Entry:"Яңа салым кертү",
  New_Tax:"Яңа салым",
  Tax_Label:"Салым билгесе",
  Perpetually_Enabled:"Мәңгегә кушылган",
  Select_Taxes:"Салымнарны сайлагыз",
  Tax_Deductions:"Салым ташламалары",
  No_Tax_Deductions:"Салым ташламалары юк",
  No_Tax_Deductions_Word:"Беренче <a routerLink='/folder/Tax/New'> Салым </a> ташламасын өстәгез.",
  New_Timer:"Яңа Таймер",
  Start:"Башлау",
  Stop:"Тукта",
  Start_Timer:"Таймерны башлау",
  Stop_Timer:"Таймерны туктату",
  Timer_Active:"Таймер Актив",
  Timer:"Таймер:",
  Timer_Running:"Таймер: (Йөгерү)",
  Save_Timer:"Таймерны саклагыз",
  New_Timesheet:"Яңа вакыт таблицасы",
  Select_Timesheets:"Вакыт таблицаларын сайлагыз",
  Work_Notes:"Эш язмалары",
  Entry_Title:"Керү исеме",
  No_Timesheets:"Вакыт таблицалары юк",
  No_Timesheets_Word:"Беренче <a routerLink='/folder/Timesheet/New'> Вакыт таблицасын өстәгез </a>.",
  Timesheet_Submitted:"Вакыт таблицасы",
  Timesheet_Congrats:"Котлыйбыз! Сезнең вакыт таблицасы уңышлы тапшырылды. Рәхмәт!",
  Timesheet_Copy:"Документларның күчермәсен алу өчен безгә электрон почта адресыгызны һәм / яки кәрәзле телефон номерыгызны бирегез.",
  Submit:"Тапшыр",
  Allow_Notifications:"Хәбәрләргә рөхсәт итегез",
  Untitled_Entry:"Яңа керү",
  Untitled_Bank:"Исемсез банк",
  Timesheet_Entry:"Вакыт таблицасына керү",
  Work_Description:"Эш тасвирламасы",
  Enable_Timesheet:"Вакыт таблицасын кушу",
  Submit_Timesheet:"Вакыт таблицасын җибәрегез",
  Vacation:"Каникул",
  New_Vacation:"Яңа каникул",
  Vacation_Name:"Каникул исеме",
  Paid_Vacation:"Түләүле ял",
  Vacation_Pay:"Каникул түләү",
  Enable_Vacation:"Ял итү",
  Select_Vacations:"Каникулларны сайлагыз",
  No_Vacations:"Каникуллар юк",
  No_Vacations_Word:"Беренче <a routerLink='/folder/Vacation/New'> Ял итү </a> язмасын ясагыз.",
  Payroll_Schedule:"Хезмәт хакы расписаниесе",
  Next_Payroll:"Киләсе хезмәт хакы:",
  Upcoming_Payroll:"Киләчәк хезмәт хакы",
  No_Upcoming_Payroll:"Киләчәк хезмәт хакы юк",
  Address_Book:"Адрес китабы",
  Archived_Documents:"Архивланган документлар",
  Dev_Mode:"Developmentсеш режимында куллану",
  Administrators:"Администраторлар",
  Privacy:"Хосусыйлык",
  None:"Беркем дә юк",
  Select_Signature:"Имзаны сайлагыз",
  No_Signatures:"Имзалар юк",
  No_Signatures_Word:"Беренче <a routerLink='/folder/Signature/New'> имза </a> өстәгез.",
  Repeat_Indefinitely:"Билгесез кабатлагыз",
  Sign:"Билге",
  Sign_Here:"Монда кул куегыз",
  Date_Signed:"Имзаланган дата",
  Signature_Pad:"Имза тактасы",
  Account_Holder:"Хисап хуҗасы",
  Account_Properties:"Хисап үзенчәлекләре",
  Name_On_Cheque:"Чекта исем",
  Server_Hostname:"Сервер Хост-исеме / IP",
  Printers:"Принтерлар",
  No_Printers:"Принтерлар юк",
  Printer_Exists:"Бу исемдәге принтер инде бар.",
  No_Printers_Word:"Беренче <a routerLink='/folder/Printer/New'> Принтер </a> өстәгез.",
  No_Printer_Alert:"Принтер билгеләнмәгән. Принтер урнаштырырга телисезме?",
  Add_Printer:"Принтер өстәү",
  New_Printer:"Яңа принтер",
  Printer_Hostname:"Принтер Хост-исем / IP",
  Printer_Label:"Принтер ярлыгы",
  Printer_Protocol:"Принтер беркетмәсе",
  Protocol:"Протокол",
  Email_Print:"Электрон почта",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Сокет",
  Print_Job:"Эшне бастыру",
  Printed:"Басылган",
  Not_Printed:"Басылмаган",
  Print_Jobs:"Эшләрне бастыру",
  Print_Queue:"Басма чират",
  No_Print_Jobs:"Басма эше юк",
  No_Prints:"Басу өчен яңа <a routerLink='/folder/Cheque/New'> Check </a> ясагыз.",
  Prints:"Басмалар",
  Find_Printer:"Принтерны табыгыз",
  Find_AirPrint_Devices:"AirPrint җайланмаларын табыгыз",
  Select_Printer:"Принтерны сайлагыз",
  System_UI:"UI системасы",
  Printer:"Принтер",
  Status:"Статус",
  Preview:"Алдан карау",
  Enable_Print_Job:"Басма эшләрен рөхсәт итегез",
  Queue_Weight:"Чират авырлыгы",
  Unlimited:"Чикләнмәгән",
  Show_Advanced_Printer_Options:"Алга киткән принтер вариантларын күрсәтегез",
  Advanced:"Алга киткән",
  Location:"Урыны",
  Note:"Тамга",
  Queue_Name:"Чират исеме",
  Pages_Printed_Limit:"Битләр чикләнгән",
  MultiPage_Idle_Time:"Күп битле көтү вакыты",
  Page_Count_Limit:"Бит санау чикләре",
  Page_Orientation:"Бит юнәлеше",
  Portrait:"Портрет",
  Landscape:"Пейзаж",
  Duplex:"Дуплекс",
  Double_Sided_Printing:"Ике яклы",
  Duplex_Mode:"Дуплекс режимы",
  Duplex_Short:"Кыска",
  Duplex_Long:"Озын",
  Duplex_None:"Беркем дә юк",
  Color_And_Quality:"Төс һәм сыйфат",
  Monochrome:"Монохром",
  Photo_Quality_Prints:"Фото сыйфаты басмалары",
  Printer_Email:"Принтер электрон почтасы",
  Automatically_Downsize:"Автоматик рәвештә түбәнәйтү",
  Paper:"Кәгазь",
  Paper_Name:"Кәгазь исеме",
  Paper_Width:"Кәгазь киңлеге",
  Paper_Height:"Кәгазь биеклек",
  Paper_Autocut_Length:"Кәгазь Авто-киселгән озынлык",
  Margins:"Маргинс",
  Page_Margins:"Бит Маргинс",
  Page_Margin_Top:"Pageгары бит Маргин",
  Page_Margin_Right:"Уң бит Маргин",
  Page_Margin_Bottom:"Түбән бит",
  Page_Margin_Left:"Сул бит",
  Add_Employees:"Эшчеләр өстәгез",
  Header:"Башлам",
  Print_A_Page_Header:"Бит башын бастырыгыз",
  Header_Label:"Башлам этикеткасы",
  Header_Page_Index:"Баш бит индексы",
  Header_Font_Name:"Баш шрифт",
  Select_Font:"Шрифтны сайлагыз",
  Font_Selector:"Шрифт селекторы",
  Header_Font_Size:"Баш шрифт",
  Header_Bold:"Калын калын",
  Header_Italic:"Башлам италик",
  Header_Alignment:"Башлам тигезләү",
  Left:"Сулда",
  Center:"Centerзәк",
  Right:"Дөрес",
  Justified:"Акланган",
  Header_Font_Color:"Баш төс",
  Select_Color:"Төсне сайлагыз",
  Footer:"Аяк асты",
  Print_A_Page_Footer:"Битнең аскы өлешен бастырыгыз",
  Footer_Label:"Аяк асты ярлыгы",
  Footer_Page_Index:"Астагы бит индексы",
  Footer_Font_Name:"Астагы шрифт",
  Fonts:"Шрифтлар",
  Done:"Булды",
  Select_Fonts:"Шрифтларны сайлагыз",
  Footer_Font_Size:"Аяк асты размеры",
  Footer_Bold:"Аяк асты калын",
  Footer_Italic:"Искәрмә",
  Footer_Alignment:"Аяк асты тигезләү",
  Footer_Font_Color:"Аяк төсе",
  Page_Copies:"Бит күчермәләре",
  Enable_Printer:"Принтерны кушу",
  Remote_Logging:"Ерак теркәлү",
  Log_Server:"Керү серверы",
  Encryption:"Шифрлау",
  Random_Key:"Очраклы ачкыч",
  Encryption_Key:"Шифрлау ачкычы",
  Cheques_Webserver:"Махсус мәгълүмат базасы",
  Learn_How:"Ничек белергә",
  Signature:"Имза",
  Default_Printer_Unit:"/ см / мм / (pt)",
  View_Signature:"Имзаны карау",
  Printed_Signature:"Басылган имза",
  Digitally_Sign:"Санлы имза",
  Digital_Signature:"Санлы имза",
  Add_Signature:"Имза өстәргә",
  Add_Your_Signature:"Имзаны өстәгез",
  Enable_Signature:"Имзаны кушу",
  Digitally_Signed:"Санлы имзаланган",
  Insert_Error:"Мәгълүматлар базасы проблемалары аркасында тикшерүне саклый алмый.",
  Delete_Confirmation:"Сез бу мәгълүматны бетерергә телисезме?",
  Discard_Confirmation:"Сез бу мәгълүматны кире кагарга телисезме?",
  Discard_Bank_Confirmation:"Сез бу хисапны кире кагарга телисезме?",
  Discard_Printer_Confirmation:"Сез бу принтерны ташларга телисезме?",
  Delete_Bonus_Confirmation:"Сез бу бонусны бетерергә телисезме?",
  Delete_Invoice_Confirmation:"Сез бу счет-фактураны бетерергә телисезме?",
  Generated_Cheque:"Генерацияләнгән тикшерү",
  Generated_Invoice:"Фактура",
  Schedule_Start_Time:"График башлау",
  Schedule_End_Time:"Расписание ахыры",
  New_Call:"Яңа шалтырату",
  No_Contacts:"Контактлар юк",
  No_Contacts_Word:"Администраторлар, модераторлар, хезмәткәрләр, түләүчеләр контактлар булып күренәләр.",
  PDF_Subject:"финанслар",
  PDF_Keywords:"хезмәт хакы хезмәт хакы PDF тикшерү",
  Printer_Setup:"Принтер урнаштыру",
  Printer_Selection:"Принтер сайлау",
  New_Fax:"Яңа факс",
  New_Fax_Message:"Яңа факс хәбәр",
  Fax_Machine:"Факс машинасы",
  Fax_Name:"Факс исеме",
  Fax_Email:"Факс электрон почтасы",
  Fax_Number:"Факс номеры",
  Contents:"Эчтәлек",
  Fax_Body:"Бит гәүдәсе",
  Header_Word:"Башлам:",
  Header_Text:"Баш текст",
  Include_Header:"Башламны кертегез",
  Include_Footer:"Аяк астын кертегез",
  Footer_Word:"Астагы:",
  Footer_Text:"Астагы текст",
  Attach_a_Cheque:"Чек беркетегез",
  Add_Deduction:"Керү",
  Enable_Fax:"Факс җибәр",
  Select_Fax:"Факсны сайлагыз",
  No_Faxes:"Факс юк",
  Faxes:"Факс",
  Save_and_Send:"Факс җибәр",
  Save_and_Pay:"Саклагыз һәм түләгез",
  WARNING:"Кисәтү:",
  Remember:"Онытма",
  Printing:"Басма",
  Printing_Complete:"Басма тулы! \ N \ n",
  of:"of",
  There_Were:"Булды",
  Successful_Prints:"уңышлы басмалар һәм",
  Unsuccessful_Prints:" уңышсыз басмалар.",
  PrinterError:"Гафу итегез! Хата бар.",
  Printing_:"Басма ...",
  PrinterSuccess:"Документ уңышлы бастырылды.",
  PrintersSuccess:"Документлар уңышлы бастырылды.",
  New_Message:"Яңа хәбәр",
  New_Messages:"Яңа хәбәрләр",
  Read_Message:"Хәбәрне уку",
  Write_Message:"Хәбәр языгыз",
  Send_Message:"Хәбәр җибәр",
  Subject:"Тема",
  Message:"Хәбәр",
  Writing:"Язу ...",
  Send:"Sendибәр",
  Set_Date:"Датаны билгеләгез",
  Set_Time:"Вакытны билгеләгез",
  Recieve:"Ал",
  Set_as_Default:"Килешү итеп куегыз",
  Default_Account:"Килешү Хисап",
  Default_Design:"Килешү дизайны",
  Multiple_Cheques:"Өч тикшерү",
  Account_Mode:"Хисап режимы",
  Multiple_Cheques_Description:"Бер биткә өч тикшерү.",
  Check_and_Table:"Тикшерү",
  Check_including_Table:"Бухгалтерлык таблицасы белән тикшерегез.",
  Check_Mailer:"Хатны тикшерегез",
  Check_Mailer_Window:"Адрес тәрәзәсе белән тикшерегез.",
  DocuGard_Table_Top:"Зур тикшерү һәм таблица",
  DocuGard_Table_Middle:"Зур тикшерү һәм өстәл (Урта)",
  DocuGard_Table_Bottom:"Зур тикшерү һәм өстәл (аста)",
  DocuGard_Mailer_Top:"Зур тикшерү почтасы",
  DocuGard_Mailer_Middle:"Зур тикшерү почтасы (Урта)",
  DocuGard_Mailer_Bottom:"Зур тикшерү почтасы (аста)",
  DocuGard_Three_Cheques:"Өч зур тикшерү",
  DocuGard_Cheque_Top:"Бер зур тикшерү",
  DocuGard_Cheque_Middle:"Зур тикшерү (Урта)",
  DocuGard_Cheque_Bottom:"Зур тикшерү (аста)",
  DocuGard_Three_Cheques_Window:"Бер биттә өч тикшерү.",
  DocuGard_Table_Top_Window:"Тикшерү һәм табыш таблицасы.",
  DocuGard_Table_Middle_Window:"Тикшерү һәм табыш таблицасы.",
  DocuGard_Table_Bottom_Window:"Тикшерү һәм табыш таблицасы.",
  DocuGard_Mailer_Top_Window:"Тикшерү, таблица һәм адрес.",
  DocuGard_Mailer_Middle_Window:"Тикшерү, таблица һәм адрес.",
  DocuGard_Mailer_Bottom_Window:"Тикшерү, таблица һәм адрес.",
  DocuGard_Cheque_Top_Window:"Куркынычсыз кәгазьне тикшерегез.",
  DocuGard_Cheque_Middle_Window:"Куркынычсыз кәгазьне тикшерегез.",
  DocuGard_Cheque_Bottom_Window:"Куркынычсыз кәгазьне тикшерегез.",
  Basic_Cheque:"Бердәм тикшерү",
  Basic_Cheque_Print:"Бер чекны бастырыгыз.",
  Error_Setting_As_Paid:"Түләүле итеп көйләүдә хата",
  Add_Attachment:"Кушымта",
  PrinterUnavailable:"Принтер юк",
  CreditCardComponent:"Карточкалар",
  PaypalComponent:"PayPal",
  InteracComponent:"Интерак",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Яңа депозит",
  Deposits:"Депозитлар",
  No_Deposits:"Депозитлар юк",
  Credit_Card_Deposit:"Кредит картас",
  New_Credit_Card_Deposit_Message:"Кредит картасы",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin депозиты",
  New_Interac_Deposit:"Интерак",
  New_Interac_Deposit_Message:"Интерак Трансфер",
  Payments_Limit:"Түләү лимиты",
  No_Payment_Limit:"Түләү лимиты юк",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal депозиты",
  No_Deposits_Word:"Беренче компанияне өстәгез <a routerLink='/folder/Deposit/New'> Депозит </a>.",
  No_Documents_Specified:"Басу өчен бернинди документлар да күрсәтелмәгән",
  No_Printers_Added:"Принтерлар табылмады. Берсен өстәр өчен көйләүләр> Принтерларга керегез.",
  DB_Erase_Prompt:"Бөтен мәгълүмат базасын тулысынча бетерергә? Кисәтү: Сез сакланган барлык мәгълүматны югалтырсыз!",
  Console_Warning:"Бу консолга текстны ябыштырмагыз. Сез үзегезне һәм / яки компаниягезне куркыныч астына куярга мөмкин.",
  No_Faxes_Word:"Беренче <a routerLink='/folder/Fax/New'> Факс </a> ясагыз.",
  Cheque_Delete_Confirmation:"Сез бу чекны бетерергә телисезме?",
  Design_Delete_Confirmation:"Сез бу дизайнны бетерергә телисезме?",
  Cheque_Pay_Confirmation:"Бу чекны түләүле дип билгеләргә? Бу бастыру чиратында күренмәячәк.",
  Payment_Pay_Confirmation:"Бу түләүне түләнгән дип билгеләргә? Бу тикшерү чиратында күренмәячәк.",
  Delete_Deduction_Confirmation:"Сез бу ташламаны бетерергә телисезме?",
  Delete_Job_Confirmation:"Сез бу эшне бетерергә телисезме?",
  Delete_Timesheet_Confirmation:"Сез бу вакыт таблицасын бетерергә телисезме?",
  Delete_Schedule_Confirmation:"Сез бу графикны бетерергә телисезме?",
  Delete_Setting_Confirmation:"Сез бу көйләнүне яңадан урнаштырырга телисезме?",
  Delete_Fax_Confirmation:"Сез бу факсны бетерергә телисезме?",
  Delete_File_Confirmation:"Сез бу файлны бетерергә телисезме?",
  Delete_Vacation_Confirmation:"Сез бу каникулны бетерергә телисезме?",
  Delete_Printer_Confirmation:"Сез бу принтерны бетерергә телисезме?",
  Remove_Design_Confirmation:"Сез бу дизайнны бетерергә телисезме?",
  Delete_Payroll_Confirmation:"Сез бу хезмәт хакын бетерергә телисезме?",
  Send_Fax_Email_Confirmation:"Сез бу документларны факс белән җибәрергә телисезме?",
  Send_Email_Confirmation:"Сез бу документны электрон почтага җибәрергә телисезме?",
  Send_Fax_Confirmation:"Бу документны факсларга телисезме?",
  Error_Generating_PDF:"Гафу итегез. Бу документны ясауда хата бар.",
  PDF_Error_Saving_Image:"Гафу итегез. Бу документның PDF рәсемен саклауда хата бар.",
  Test_Printer_Confirmation:"Сез бу принтерда тест битен бастырырга телисезме?",
  Save_Timesheet_Prompt:"Зинһар, 'Титул' өстәгез яки саклау өчен 'Старт Таймер' басыгыз.",
  Remove_Geofence_Prompt:"Сез бу гео-койманың урынын бетерергә телисезме?",
  Delete_Employee_Confirmation:"Сез бетерергә телисезме?",
  Fire_Employee_Confirmation:"Сез ут кабызырга телисезме?"
}