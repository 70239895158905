export const Be = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Copyright &copy; 2023 год",
  black:"Чорны",
  green:"Зялёны",
  gold:"золата",
  blue:"Сіні",
  brown:"Карычневы",
  purple:"Фіялетавы",
  pink:"Ружовы",
  red:"Чырвоны",
  Swatches:"Узоры",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Адценне",
  Saturation:"Насычанасць",
  Lightness:"Лёгкасць",
  Upgrade_To_Pro:"Абнаўленне да Pro",
  AllFeaturesInclude:"Усе функцыі ўключаюць у сябе:",
  PrintUnlimitedCheques:"Друкуйце неабмежаваную колькасць чэкаў",
  PremiumChequeDesigns:"Дызайн прэміум-чэкаў",
  ManageUnlimitedEmployees:"Кіруйце неабмежаванай колькасцю супрацоўнікаў",
  AddUnlimitedPayees:"Дадайце неабмежаваную колькасць атрымальнікаў",
  CreateUnlimitedPayrolls:"Стварыце неабмежаваную колькасць заработнай платы",
  UnlimitedSchedulesandTimeSheets:"Неабмежаваныя расклады і табелі працоўнага часу",
  BulkPayPalPayouts:"Масавыя выплаты PayPal",
  UnlimitedBankAccounts:"Неабмежаваная колькасць банкаўскіх рахункаў",
  ManageMultipleCompanies:"Кіруйце некалькімі кампаніямі",
  TrackInsurancePolicies:"Сачыце за страхавымі полісамі",
  Bio_MetricProtection:"Біяметрычная абарона",
  Geo_FenceLock_OutProtection:"Geo-Fence Lock-Out Абарона",
  Multiple_Companies_Word:"Кіраванне некалькімі кампаніямі немагчыма без прэміум-класа Checks.",
  PayPal_Payouts_Word:"Плацяжы PayPal адключаны без прэміяльных чэкаў.",
  PINProtection:"Абарона PIN-кодам",
  PasswordProtection:"Абарона паролем",
  May_Require_Approval:"Можа спатрэбіцца ўзгадненне.",
  Subscribe:"Падпісвайцеся",
  Billed:"Выстаўлены рахунак",
  Up:"Уверх",
  Down:"Уніз",
  Positioning:"Пазіцыянаванне",
  Marker:"Маркер",
  Drag_Marker:"Маркер перацягвання",
  Tagline:"Друкуйце чэкі і складайце табліцы заработнай платы",
  Marker_Word:"Выкарыстоўвайце маркеры для памеру элемента.",
  Pinch_Zoom:"Павялічыць пальцам",
  Pinch_Word:"Сцісніце пальцам, каб павялічыць элемент.",
  Drag:"Перацягнуць",
  Drag_Word:"Перацягвайце элементы пальцам.",
  subscription_alias_word:"Аўтаматычнае падаўжэнне падпіскі",
  premium_alias_word:"Пакет аднаразовага абнаўлення",
  print_alias_word:"Раздрукуйце індывідуальныя чэкі",
  mode_alias_word:"Рэжым",
  Pro:"профі",
  Pro_word:"Патрабуецца версія Pro.",
  Cant_Delete_Default_Company_Word:"На жаль, вы не можаце выдаліць кампанію па змаўчанні.",
  Reinsert_Default_Designs:"Паўторна ўставіць дызайн па змаўчанні",
  Reinsert_Default_Designs_word:"Вы жадаеце паўторна ўставіць дызайн па змаўчанні?",
  Subscription_Active_Disable_Word:"Гэта падпіска актыўная. Хочаце адмяніць падпіску на Cheques?",
  Company_Logo:"Лагатып кампаніі",
  ZERO_:"НУЛЬ",
  Disclaimer:"Адмова ад адказнасці",
  Privacy_Policy:"Палітыка прыватнасці",
  EULA:"Правярае EULA",
  Terms_Of_Service:"Ўмовы абслугоўвання",
  Terms_Of_Use:"Умовы выкарыстання",
  Refunds:"Палітыка вяртання сродкаў",
  Single_Print:"1 Праверце",
  Two_Prints:"2 Праверкі",
  Five_Prints:"5 Праверкі",
  Ten_Prints:"10 Праверкі",
  Fifteen_Prints:"15 Праверкі",
  Twenty_Prints:"20 чэкаў",
  Thirty_Prints:"30 чэкаў",
  Image_Added:"Выява дададзена",
  Image_Preview:"Папярэдні прагляд выявы",
  No_Image_Was_Selected:"Выява не выбрана.",
  Cheques_Unlimited:"Чэкі неабмежаваныя",
  _Subscription:"Падпіска",
  Subscription:"Праверкі - 1 месяц",
  Two_Month_Subscription:"Праверкі - 2 месяцы",
  Three_Month_Subscription:"Праверкі - 3 месяцы",
  Six_Month_Subscription:"Праверкі - 6 месяцаў",
  Twelve_Month_Subscription:"Чэкі - 12 месяцаў",
  Cheques_Are_Available:"Чэкі даступныя для друку.",
  Upgrade_Required_Two:"Выберыце пакет і двойчы націсніце на кнопку цаны, каб пачаць пакупку. Друкуйце поўнакаляровыя чэкі прафесійнага выгляду за лічаныя секунды.",
  Month:"Месяц",
  Due:"Тэрмін:",
  Expires:"Тэрмін дзеяння:",
  Subscription_Active:"Падпіска актыўная",
  Subscription_Inactive:"Падпіска неактыўная",
  Purchase_Additional_Cheques:"Набыць дадатковыя чэкі?",
  Printable_Cheque:"Чэк для друку",
  Printable_Cheques:"Чэкі для друку",
  Printable_Cheque_Word:"чэк даступны на вашым рахунку.",
  Printable_Cheques_Word:"чэкі даступныя на вашым рахунку.",
  Max_Amount_Message:"Указаная вамі сума перавышае максімальную суму, усталяваную для гэтай сістэмы:",
  Terms_Required_Word:"Вы павінны пагадзіцца з гэтым пагадненнем, перш чым працягваць выкарыстоўваць чэкі.",
  Subscriptions:"Падпіскі",
  Product_Upgrades:"Абнаўлення",
  Mailed_Out_Cheques:"Высланыя чэкі",
  Enter_A_Company_Name:"Калі ласка, увядзіце назву кампаніі.",
  Single_Cheques:"Адзінкавыя чэкі",
  Cheque_Golden:"Залаты чэк",
  Cheque_Golden_Window:"Дызайн у залатую клетку.",
  Cheque_Green:"Зялёная галачка",
  Cheque_Green_Window:"Дызайн у зялёную клетку.",
  Cheque_Red:"Чырвоная галачка",
  Cheque_Red_Window:"Чырвоны дызайн у клетку.",
  Cheque_Yellow:"Жоўты чэк",
  Cheque_Yellow_Window:"Дызайн у жоўтую клетку.",
  Cheque_Statue_of_Liberty:"Статуя Свабоды",
  Cheque_Statue_of_Liberty_Window:"Дызайн чэка Статуі Свабоды.",
  Cheque_Green_Wave:"Зялёная хваля",
  Cheque_Green_Wave_Window:"Дызайн у зялёную клетку.",
  Cheque_Golden_Weave:"Залатое перапляценне",
  Cheque_Golden_Weave_Window:"Элегантны дызайн у залатую клетку.",
  Cheque_Green_Sun:"Зялёнае сонца",
  Cheque_Green_Sun_Window:"Глыбокі і заспакаяльны дызайн у клетку.",
  Cheque_Blue_Checkers:"Сінія шашкі",
  Cheque_Blue_Checkers_Window:"Сіні дызайн у клетку.",
  Cashiers_Check:"Касавы чэк",
  Cashiers_Check_Window:"Шаблон стылю касавага чэка.",
  Cheque_Aqua_Checkers:"Аква Шашкі",
  Cheque_Aqua_Checkers_Window:"Дызайн аквачэка.",
  Cheque_Golden_Checkers:"Залатыя шашкі",
  Cheque_Golden_Checkers_Window:"Дызайн у залатую клетку.",
  Upgrade_Unavailable:"Абнаўленні недаступныя",
  Bank_Code_Protection:"Абарона банкаўскага нумара",
  Bank_Code_Protection_Word:"Абараніце свае банкаўскія нумары ад выкарыстання ў гэтай праграме, якая працуе на іншай прыладзе або для іншага карыстальніка. Гэта дзеянне НЕЗВАРАТНА. Працягнуць?",
  Bank_Code_Protection_Blocked_Word:"Банкаўскія нумары, якія вы спрабуеце выкарыстоўваць, зарэзерваваны для іншага карыстальніка або прылады.",
  Bank_Code_Protection_Error_Word:"Збой праверкі нумара. Калі ласка, падключыцеся да Інтэрнэту і паспрабуйце дадаць гэты банкаўскі рахунак яшчэ раз.",
  Bank_Code_Protection_Set_Error_Word:"Абарона банкаўскага нумара патрабуе падключэння да Інтэрнэту. Падключыцеся і паўтарыце спробу.",
  Upgrade_Unavailable_Word:"На жаль, у нас узніклі праблемы з праверкай вас. Падпіскі і абнаўленні Checks зараз недаступныя для пакупкі ў вашым рэгіёне.",
  PayPal_Payment_Preview:"Папярэдні прагляд аплаты PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Выберыце PayPal",
  PayPal_Applications:"Прыкладанні PayPal",
  Purchase_With_Apple_Pay:"Купляйце з дапамогай Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Кампаніі",
  Insurance:"Страхоўка",
  New_PayPal:"Новы PayPal",
  Pay_By:"Аплата",
  Insure:"Страхаваць",
  Miles:"Майлз",
  Payment_Method:"Спосаб аплаты",
  Select_Policies:"Выберыце Палітыкі",
  Policies:"Палітыкі",
  Policy:"Палітыка",
  No_PayPal_Account:"Няма ўліковага запісу PayPal",
  No_Policies:"Няма страхавых полісаў",
  New_Policy:"Новая палітыка",
  PayPal_Payment:"Аплата PayPal",
  PayPal_Payments:"Плацяжы PayPal",
  No_Payment_Selected:"Плацёж не выбраны",
  Sending_Payment_Word:"Пачакайце... Плацёж адпраўляецца.",
  Sending_Payments_Word:"Калі ласка, пачакайце... Плацяжы адпраўляюцца.",
  No_Payment_Selected_PayPal:"не выбраны <a routerLink='/folder/Payments'>плацёж PayPal</a>.",
  Payment_Sent:"Аплата адпраўлена",
  PayPal_Payment_Sent:"Гэты плацеж быў адпраўлены праз PayPal.",
  Copay:"Даплата",
  Dead:"Мёртвы",
  Alive:"Жывы",
  Not_Dead:"Не мёртвы",
  Unclaimed:"Незапатрабаваны",
  Attempted:"Спроба",
  Deceased:"Нябожчык",
  Claimed:"Заяўлены",
  Unpaid:"Неаплачаны",
  Sending_Payment:"Адпраўка плацяжу",
  Sending_Payments:"Адпраўка плацяжоў",
  Send_PayPal_Confirmation:"Вы жадаеце адправіць гэту транзакцыю праз PayPal?",
  Send_PayPal_Confirmation_Word:"Націсніце зялёную кнопку, каб адправіць гэтую транзакцыю.",
  Save_Payment_As_Unpaid:"Захаваць гэты плацеж як неаплачаны?",
  Payment_Pay_Confirmation_PayPal:"Захаваць гэты плацеж як аплачаны?",
  No_Policies_Word:"Дадайце першы <a routerLink='/folder/Postage/New'> Страхавы поліс</a> зараз.",
  Timesheet_Multiple_Delete_Confirmation:"Вы ўпэўнены, што хочаце выдаліць некалькі табеляў уліку працоўнага часу?",
  Select_Multiple_Timesheets_Prompt:"Табліцы працоўнага часу не выбраны. Выберыце хаця б адзін табель уліку працоўнага часу.",
  Select_Multiple_Policies_Prompt:"Палітыкі не выбраны. Выберыце хаця б адзін страхавы поліс.",
  Policies_Multiple_Delete_Confirmation:"Вы ўпэўнены, што хочаце выдаліць некалькі палітык?",
  Company:"Кампанія",
  Add_Company:"Дадаць кампанію",
  New_Company:"Дадаць кампанію",
  No_Companies:"Няма кампаній",
  Enable_Company:"Уключыць кампанію",
  Select_Company:"Выберыце Кампанія",
  The_Default_Company:"Ярлык кампаніі па змаўчанні.",
  Manage_Companies:"Кіраванне кампаніямі",
  No_Companies_Word:"Для праверкі будзе выкарыстоўвацца кампанія па змаўчанні.<br />Дадайце <a routerLink='/folder/Company/New'> першую кампанію</a>.",
  Default_Company:"Кампанія па змаўчанні",
  Cheques_Unlimited_Word:"Checks Unlimited дазваляе друкаваць колькі заўгодна чэкаў.",
  Cheques_Subscription_Word:"Падпіска на чэкі дазваляе друкаваць колькі заўгодна чэкаў.",
  You_Own_This_Product:"Вы валодаеце гэтым прадуктам.",
  Your_Subscription_is_Active:"Ваша падпіска актыўная.",
  Active_Products:"Актываваныя прадукты",
  Purchase_Confirmation:"Купля",
  Purchase_Cheques:"Купля чэкаў",
  Restore_Purchase:"Аднавіць пакупкі",
  Erase_Purchase:"Сцерці пакупкі",
  Successfully_Purchased:"Паспяхова набыта",
  Enter_Your_Licence_Key:"Калі ласка, увядзіце ліцэнзійны ключ на гэтай старонцы, каб актываваць гэты прадукт.",
  Licence_Key:"Ліцэнзійны ключ",
  Enter_Licence_Key:"Увядзіце ліцэнзійны ключ",
  Purchased:"Набыты",
  Enable_Feature:"Уключыць функцыю",
  Cancel_Subscription:"Адмяніць падпіску",
  Subscription_Removed:"Падпіска выдалена",
  Select_Active_Subscription:"Выберыце актыўную падпіску, каб змяніць яе.",
  Remove_Subscription_Word:"Вы ўпэўнены, што хочаце скасаваць гэту падпіску?",
  Delete_Company_Confirmation:"Вы ўпэўнены, што хочаце выдаліць усю гэту кампанію? ПАПЯРЭДЖАННЕ: Вы страціце ўсю захаваную інфармацыю!",
  Delete_Default_Company_Word:"Вы не можаце выдаліць кампанію па змаўчанні. Вы жадаеце скінуць налады прыкладання і аднавіць яго стан па змаўчанні? ПАПЯРЭДЖАННЕ: Вы страціце ўсю захаваную інфармацыю!",
  Console_Warning_2:"З дапамогай гэтага прыкладання нельга апрацоўваць валюту, якая зараз не належыць вам.",
  Terms_and_Conditions:"Правілы і ўмовы",
  and_the:"і",
  for:"для",
  Please_Read_Word:"Калі ласка, прачытайце і згадзіцеся з",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Некаторыя курсы канверсіі валют не знойдзены. Калі ласка, падключыцеся да Інтэрнэту.",
  Free:"Бясплатна",
  DB_Erase_Prompt_2:"Цалкам сцерці ўсю базу дадзеных распрацоўшчыка? ПАПЯРЭДЖАННЕ: Вы страціце ўсю інфармацыю аб КУПЛЯХ і ПАДПІСКЕ!",
  Successfully_Imported:"Паспяхова імпартавана",
  Select_Postage:"Выберыце Паштовыя выдаткі",
  No_Postage:"Няма паштовых марак",
  No_Paid_Postage_Word:"Дадайце першую марку <a routerLink='/folder/Postage/New'>Аплачаная пашта</a>.",
  Trial_Complete:'Выпрабаванне завершана',
  Please_Upgrade:'Каб працягнуць друк, абнавіце чэкі.',
  Aa:"Аа",
  Color:"Колер",
  Font:"Шрыфт",
  Guide:"Кіраўніцтва",
  Guides:"Кіраўніцтва",
  Image:"Малюнак",
  Zoom:"Павялічыць",
  Logo:"Лагатып",
  Bank:"Банк",
  MICR:"MICR",
  Total:"Усяго",
  Cancel:"Адмяніць",
  Confirm:"Пацвердзіць",
  Method:"Метад",
  Biometric_Security:"Біяметрычная бяспека",
  Please_Login_To_Continue:"Калі ласка, увайдзіце, каб працягнуць.",
  Complete:"Завяршыць",
  Sign_Up:"Зарэгістравацца",
  Error:"Памылка",
  Biometrics:"Біяметрыка",
  Percent:"Працэнт",
  Zero_Percent:"0%",
  Top_Margin:"Верхняя маржа",
  Bottom_Margin:"Ніжняе поле",
  Left_Margin:"Левае поле",
  Right_Margin:"Правае поле",
  MICR_Margin:"Маржа MICR",
  Table_Margin:"Маржа табліцы",
  Address_Margin:"Адрасная маржа",
  Percentage_:"Працэнт",
  Vacation_Title:"Назва водпуску",
  Use_PIN:"Выкарыстоўвайце PIN-код",
  Loading__:"Ідзе загрузка...",
  Design_Title:"Назва дызайну",
  Authorize:"Аўтарызаваць",
  Key:"Ключ",
  Public_Key:"Адкрыты ключ",
  Private_Key:"Закрыты ключ",
  Authenticate:"Аўтэнтыфікацыя",
  Last_Payroll:"Апошняя зарплата",
  Last_Calculation:"Апошні разлік",
  Authorized:"Упаўнаважаны",
  Geo_Authorized:"Геаразмяшчэнне: аўтарызаваны",
  Not_Authorized:"Не ўпаўнаважаны",
  Authorization_Complete:"Аўтарызацыя завершана",
  Geolocation_Authorization:"Аўтарызацыя геалакацыі",
  Out_of_Bounds:"Па-за межамі",
  Cant_Delete_Default_Design:"Немагчыма выдаліць дызайн па змаўчанні.",
  Unauthorized_Out_of_Bounds:"Несанкцыянавана: па-за межамі",
  Biometric_Authorization:"Біяметрычная аўтарызацыя",
  Locating_Please_Wait:"Месцазнаходжанне, калі ласка, пачакайце...",
  Test_Biometrics:"Тэст біяметрыі",
  Cheque_Margins:"Праверце палі",
  Percentage_Full_Error:"У полі адсотка нельга задаць больш за 100%.",
  No_Payroll_Text:"Дадайце <a routerLink='/folder/Employee/New'>Супрацоўніка</a> або <a routerLink='/folder/Payee/New'>атрымальніка</a> і <a routerLink='/folder/Schedule/New'>Расклад</a>.",
  Design_Saved:"Дызайн захаваны",
  Default_Design_Selected:"Дызайн па змаўчанні выбраны",
  Partial_Import:"Частковы імпарт",
  Partial_Export:"Частковы экспарт",
  Entire_Import:"Увесь імпарт",
  Entire_Export:"Увесь экспарт",
  Existing_Password:"Калі ласка, увядзіце існуючы пароль:",
  Existing_PIN:"Калі ласка, увядзіце існуючы PIN-код:",
  Pin_Change_Header:"Пацвярджэнне PIN-кода",
  Pin_Change_SubHeader:"Увядзіце свой стары PIN-код, каб пацвердзіць змяненне.",
  Pass_Change_Header:"Пацверджанне пароля",
  Pass_Change_SubHeader:"Увядзіце свой стары пароль, каб пацвердзіць змены.",
  PIN_Enter_Message:"Увядзіце PIN-код для пацверджання.",
  Password_Enter_Message:"Увядзіце свой пароль для пацверджання.",
  Pin_Updated_Success:"PIN-код паспяхова абноўлены!",
  Pin_Updated_Fail:"Немагчыма абнавіць PIN-код.",
  Pass_Updated_Success:"Пароль паспяхова абноўлены.",
  Pass_Updated_Fail:"Немагчыма абнавіць пароль.",
  Preview_Payment:"Аплата папярэдняга прагляду",
  Preview_Payroll:"Папярэдні прагляд заработнай платы",
  No_Payments_Created:"Не было знойдзена стварэнне плацяжоў.",
  Payment_Preview:"Папярэдні прагляд аплаты",
  Enable_Payee:"Уключыць атрымальніка плацяжу",
  Rendered:"Вынесены",
  No_Email:"Няма электроннай пошты",
  Setup_Cheques:"Праверкі ўстаноўкі",
  name:"Імя",
  address:"Адрас",
  address_2:"Адрасны радок 2",
  city:"Горад",
  province:"Правінцыя",
  postal_code:"Паштовы індэкс",
  country:"Краіна",
  username:"Імя карыстальніка",
  full_name:"Поўнае імя",
  birthday:"Дзень нараджэння",
  email:"Электронная пошта",
  phone:"Тэлефон",
  employees:"Супрацоўнікі",
  addresses:"Адрасы",
  payment_amount_limit:"Ліміт сумы плацяжу",
  total_limit:"Агульны ліміт",
  payees:"Атрымальнікі плацяжоў",
  description:"Апісанне",
  address_line_one:"Адрасны радок",
  address_line_two:"Адрасны радок 2",
  image:"Малюнак",
  account_holder:"Уладальнік рахунку",
  cheque_starting_id:"Праверце стартавы ідэнтыфікатар",
  transit_number:"Транзітны нумар",
  institution_number:"Нумар установы",
  designation_number:"Нумар абазначэння",
  account_number:"Нумар акаўнта",
  currency:"Валюта",
  signature:"Подпіс",
  payment_payroll_limit:"Ліміт аплаты",
  total_limi:"Агульны ліміт",
  date:"Дата",
  printed_memo:"Друкаваная памятка",
  banks:"Банкі",
  signature_image:"Выява подпісу",
  address_name:"Адрас Імя",
  notes:"Нататкі",
  design:"Дызайн",
  title:"Загаловак",
  frequency:"Частата",
  fax:"Факс",
  salaries:"Заробкі",
  salary_ids:"Ідэнтыфікатары заробкаў",
  start_time:"Час пачатку",
  end_time:"Час заканчэння",
  paid:"Платныя",
  overtime_percentage:"Аплачаныя працэнты",
  overtime_amount:"Выплачваецца фіксаваная сума",
  first_name:"Імя",
  last_name:"Прозвішча",
  moderation:"Умеранасць",
  create:"Стварыць",
  edit:"Рэдагаваць",
  destroy:"Знішчыць",
  day_start_time:"Дзень_пачатак_час",
  day_end_time:"Дзень_канец_час",
  fullname:"Імя",
  time:"Час",
  auto_send:"Аўтаматычна адправіць",
  time_method:"Метад часу",
  schedules:"Расклады",
  indefinite_payroll_enabled:"Уключыць бестэрмінова",
  amount:"Сума",
  repeat:"Паўтарыце",
  always_enabled:"Заўсёды ўключана",
  start_date:"Дата пачатку",
  end_date:"Дата заканчэння",
  Cheque_Total:"Праверце Усяго",
  Total_Amount:"Агульная колькасць:",
  Amounts:"Сумы:",
  Images:"Выявы",
  Files:"Файлы",
  Previewing:"Папярэдні прагляд:",
  Insert:"Устаўка",
  Preview_Import:"Імпарт папярэдняга прагляду",
  Entry:"Уваход",
  Entries:"Запісы",
  No_Entries:"Няма запісаў",
  Import_Type:"Тып імпарту",
  Select_Details:"Выберыце Дэталі",
  Select_Payee:"Выберыце Атрымальнік плацяжу",
  Enable_Holidays:"Уключыць святы",
  Disable_Holidays:"Адключыць святы",
  Wire_Transfer:"Банкаўскі перавод",
  New_Export:"Новы экспарт",
  Export_Data:"Экспарт даных",
  Export_Data_Word:"Выберыце тып файла для экспарту і загрузкі.",
  Export_File:"Экспарт файла",
  Mode:"Рэжым",
  Times:"Часы",
  Widgets:"Віджэты",
  Slider:"Паўзунок",
  Set_Details:"Усталюйце дэталі",
  Select_Type:"Выберыце Тып",
  Display_Slider:"Паўзунок дысплея",
  Dashboard_Slider:"Паўзунок прыборнай панэлі",
  Dashboard_Mode:"Рэжым прыборнай панэлі",
  Show_Intro:"Паказаць увядзенне",
  Show_Payrolls:"Паказаць заработную плату",
  Show_Holidays:"Паказаць святы",
  Show_Invoices:"Паказаць рахункі-фактуры",
  Show_Cheques:"Паказаць чэкі",
  Enabled_Widgets:"Уключаныя віджэты",
  Disabled_Widgets:"Адключаныя віджэты",
  Colors:"Колеры",
  Barcodes:"Штрых-коды",
  View_Timers:"Прагляд таймераў",
  Gradients:"Градыенты",
  No_Info:"Няма інфармацыі",
  Disable:"Адключыць",
  Show_Layer:"Паказаць слаі",
  Hide_Layer:"Схаваць пласты",
  Text_Layer:"Слоі тэксту",
  Secondly:"Па-другое",
  Minutely:"Хвілінна",
  nine_am:"9:00 раніцы",
  five_pm:"17:00",
  Enable_Address:"Уключыць адрас",
  Invalid_File_Type:"На жаль, быў абраны няправільны тып файла. Падтрымліваемы тып файла:",
  Error_Updating_Entry:"На жаль, пры абнаўленні гэтага запісу адбылася памылка.",
  Schedule_Timing_Alert:"Памылка: час пачатку раскладу ўстаноўлены на значэнне пасля часу заканчэння.",
  Select_Multiple_Payments_Prompt:"Плацяжы не выбраны. Выберыце хаця б адзін плацёж.",
  Select_Multiple_Cheques_Prompt:"Праверкі не выбраны. Выберыце хаця б адзін чэк.",
  Select_Multiple_Items_Prompt:"Няма выбраных элементаў. Выберыце хаця б адзін элемент.",
  Paymemt_Multiple_Delete_Confirmation:"Вы ўпэўнены, што хочаце выдаліць некалькі плацяжоў?",
  Cheque_Multiple_Delete_Confirmation:"Вы ўпэўнены, што хочаце выдаліць некалькі чэкаў?",
  Payee_Multiple_Delete_Confirmation:"Вы ўпэўнены, што хочаце выдаліць некалькіх атрымальнікаў плацяжоў?",
  Employee_Multiple_Delete_Confirmation:"Вы ўпэўнены, што хочаце выдаліць некалькіх супрацоўнікаў?",
  MICR_Warning:"Заўвага: некаторыя прынтэры і прылады могуць няправільна адлюстроўваць шрыфт MICR.",
  Automatically_Send:"Аўтаматычна адправіць",
  Type:"Тып",
  Payment_Type:"Тып аплаты",
  Auto_Send:"Аўтаматычная адпраўка",
  Automatically_Process:"Аўтаматычна апрацоўваць",
  Auto_Process:"Аўтаматычны працэс",
  Image_Based:"На аснове малюнкаў",
  Font_Based:"На аснове шрыфтоў",
  Rerender:"Паўторная візуалізацыя",
  Rendering:"Рэндэрынг",
  Render:"Файлы",
  Top:"Зверху",
  Middle:"Сярэдні",
  Bottom:"Ніжні",
  Top_Left:"Злева ўверсе",
  Top_Center:"Верхні цэнтр",
  Top_Right:"Справа ўверсе",
  Middle_Left:"Сярэдні левы",
  Middle_Center:"Сярэдні цэнтр",
  Middle_Right:"Сярэдні правы",
  Bottom_Left:"Унізе злева",
  Bottom_Center:"Ніжні цэнтр",
  Bottom_Right:"Справа ўнізе",
  Numbers:"Лічбы",
  Verified:"Праверана",
  Paper_Size:"Памер паперы",
  New_Device:"Новая прылада",
  Add_Device:"Дадаць прыладу",
  Remove_Device:"Выдаліце прыладу",
  Delete_Device:"Выдаліць прыладу",
  Block_Device:"Блокавая прылада",
  Block:"Блакаваць",
  Unblock:"Разблакіраваць",
  Table:"Табліца",
  Scan_Login_Code:"Сканіраванне кода ўваходу",
  Login_Code:"Код ўваходу",
  Scan_Code:"Код сканавання",
  Scan_QR_Code:"Сканіраванне QR-кода",
  Select_All:"Абраць усё",
  Deselect_All:"Адмяніце выбар усіх",
  Increase:"Павялічыць",
  Decrease:"Зніжэнне",
  Bold:"Тоўсты",
  Text:"Тэкст",
  Style:"Стыль",
  Italic:"Курсіў",
  QR_Code:"QR-код",
  Barcode:"Штрых-код",
  Browse_Images:"Прагляд малюнкаў",
  Browse_Files:"Прагляд файлаў",
  Background_Image:"Фонавы малюнак",
  Logo_Image:"Выява лагатыпа",
  Header_Image:"Выява загалоўка",
  Bank_Image:"Выява банка",
  Cut_Lines:"Лініі разрэзу",
  Background:"Фон",
  License:"Ліцэнзія",
  One_License:"1 ліцэнзія:",
  Licensed:"Ліцэнзія на:",
  Not_Licensed:"Не мае ліцэнзіі",
  Enter_Serial:"Увядзіце Serial",
  Serial_Key:"Серыйны ключ",
  Serial:"Серыял",
  Product_Key:"Ключ прадукту",
  Check_Product_Key:"Праверце ключ прадукту",
  Add_Product_Key:"Дадаць ключ прадукту",
  Verifying_Purchase:"Праверка пакупкі...",
  Print_Envelope:"Раздрукаваць канверт",
  Envelope:"Канверт",
  Thank_You:"Дзякуй!",
  Scale:"Маштаб",
  Print_Scale:"Маштаб друку",
  Borders:"Межы",
  VOID:"ПУСТЫЯ",
  Void_Cheque:"Праверка несапраўднасці",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ПЛАЦІЦЬ ПА ЗАМАДУ:",
  NO_DOLLARS:"НЕ ДАЛЯРАЎ ",
  ONE_DOLLAR:"АДЗІН ДОЛЯР",
  DOLLARS:" ДАЛЯРЫ",
  AND:" І ",
  CENTS:" ЦЭНТЫ.",
  NO_:"НЕ ",
  ONE_:"АДЗІН ",
  AND_NO_:"І НЕ ",
  _AND_ONE_:"І АДЗІН ",
  DOLLARS_AND_ONE_CENT:" І АДЗІН ЦЭНТ.",
  AND_NO_CENTS:" І НУЛЬ цэнтаў.",
  CHEQUE_PRINT_DATE:"ДАТА:",
  CHEQUE_PRINT_MEMO:"ПАМЯТА:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"дэпутат",
  Initial_Tasks:"Пачатковыя задачы",
  Inital_Setup:"Пачатковая налада",
  Welcome_To:"Сардэчна запрашаем ",
  Welcome:"Сардэчна запрашаем",
  Swipe:"Правядзіце пальцам",
  Intro_Setup:"Уступная налада",
  Introduction:"Уводзіны",
  CHEQUE_PRINT_CREDIT:"Працуе на чэкі",
  Title_Intro_Word:"Сардэчна запрашаем у чэкі",
  Title_Intro:"Увядзенне чэкаў",
  Title_Setup:"Настройка чэкаў",
  PayPal_Default_Email_Message:"Вы атрымалі новы перавод PayPal. [Працуе на чэкі]",
  Cheques_App_Export:"Экспартаваны чэкамі",
  Post_Intro_Thanks:"Дзякуй за выбар Чэкі. Працэс налады завершаны.",
  Post_Intro_Word:"Пачніце з раздрукоўкі свайго першага чэка, дадайце будучы плацёж або дадайце супрацоўнікаў у заработную плату.",
  Upgrade_Required:"Cheques патрабуе, каб вы валодалі больш прэміум-версіяй праграмнага забеспячэння, каб схаваць гэтае паведамленне і разблакіраваць дадатковыя функцыі.",
  Upgrade_Title:"чэкі",
  Mailout_Prompt:"Вы можаце дадаткова выбраць, каб Checks рассылаў вам чэкі на заработную плату.",
  Mailed_Cheque:"Праверка па пошце: ",
  Mailed_Cheque_Color:"Дасланы чэк (колер): ",
  Terms_Purchase:"Усе электронныя пакупкі з чэкамі цалкам вяртаюцца на працягу 30 дзён з даты пакупкі. Калі ласка, прачытайце і згадзіцеся з <a href='#'>Умовамі</a>, перш чым працягнуць.",
  Dashboard_Setup:"Настройка асноўнага друкаркі",
  Dashboard_Add:"Дадаць асноўны банкаўскі рахунак",
  Dashboard_Customize:"Выберыце шаблон праверкі",
  Dashboard_Job_Salary:"Стварыць сваю працу і дадаць сваю зарплату",
  Dashboard_Employees:"Адсочваць супрацоўнікаў і атрымальнікаў плацяжоў",
  Dashboard_Print:"Раздрукуйце і адпраўце свае чэкі па пошце",
  Dashboard_Payroll:"Аўтаматызаваць раздрукоўку заработнай платы",
  Dashboard_PayPal:"Наладзіць PayPal Payroll / выплаты",
  Begin_Setup:"Пачаць наладу",
  Get_Started:"Пачаць",
  Purchase:"Купіць",
  Lockdown:"Каранцін",
  Unlock:"Разблакіраваць",
  Detailed:"Падрабязней",
  Log_In:"Увайсці",
  Login:"Увайсці",
  Launch:"Запуск",
  Register:"Рэгістрацыя",
  Finish:"Скончыць",
  List:"Спіс",
  Weekends:"Выходныя",
  Weekly:"Штыдзень",
  PayPal_Default_Subject:"Новы плацёж",
  Payment_Message:"Плацёжнае паведамленне",
  PayPal_Default_Payment_Note:"Дзякуй",
  Setup_Your_Cheqing_Account:"Чэкавы рахунак",
  Add_Your_Primary_Cheqing_Account:"Дадайце свой асноўны чэкавы рахунак.",
  Welcome_Word:"Спрасціць і аўтаматызаваць налічэнне заработнай платы і кіраванне чалавечымі рэсурсамі.",
  Get_Started_Quickly:"Проста адкажыце на некалькі простых пытанняў, якія дапамогуць нам пачаць...",
  Done_Intro_Word:"Настройка завершана",
  PIN_Protected:"Абароненыя паролем і PIN-кодам",
  Enter_New_PIN:"Увядзіце новы PIN-код:",
  Enter_PIN:"Увядзіце PIN-код:",
  Invalid_PIN:"Уведзены няправільны PIN-код.",
  Account_Identifier:"Ідэнтыфікатар акаўнта",
  New_Account_Identifier:"Новы ідэнтыфікатар акаўнта",
  attempt:"спроба",
  attempts:"спробы",
  remaining:"засталося",
  Language:"Мова",
  languages:"Мовы",
  select_languages:"Выберыце мову",
  Deposit:"Дэпазіт",
  Hire_One_Now:"Наймайце аднаго зараз",
  App_Locked:"Прыкладанне заблакіравана.",
  Skip_:"Прапусціць",
  Skip_to_Dashboard:"Перайсці да прыборнай панэлі",
  Dashboard:"Прыборная панэль",
  Import:"Імпарт",
  Admin:"Адміністратары",
  New_Admin:"Новы адмін",
  Settings:"Настройкі",
  Color_Picker:"Падбор колеру",
  Font_Picker:"Падбор шрыфтоў",
  Logout:"Выйсці з сістэмы",
  Close:"Блізка",
  Close_menu:"Блізка",
  Excel:"Файл Excel",
  Csv:"файл CSV",
  Sql:"Файл SQL",
  Json:"файл JSON",
  Url:"Імпарт па URL",
  Back:"Назад",
  Timers:"Таймеры",
  Cheque:"Праверыць",
  Print:"Друк",
  Designs:"Дызайн",
  Pause_Printing:"Паўза друку",
  Resume_Printing:"Узнавіць друк",
  Printing_Paused:"Друк прыпынены",
  PrintingUnavailable:"Прабачце! Друк недаступны ў гэтай сістэме.",
  Prints_Paused:"Друкі прыпынены",
  Administration:"Адміністрацыя",
  Loading:"Загрузка",
  Unnamed:"Безназоўны",
  error:"На жаль, гэты чэк не можа быць адкрыты для прагляду.",
  No_Cheques_To_Print:"Чэкаў для друку няма",
  No_Cheques_To_Print_Word:"Стварыць <a routerLink='/folder/Cheque/New'>Новы чэк</a>.",
  New_Cheque:"Новы чэк",
  Start_One_Now:"Пачні адзін зараз",
  Edit_Cheque:"Рэдагаваць праверку",
  Select_Cheques:"Выбраць чэкі",
  Select_Employee:"Выбраць супрацоўніка",
  Default_Printer:"Прынтэр па змаўчанні",
  Reassign:"Перазначыць",
  Configure:"Наладзіць",
  Template:"Шаблон",
  Designer:"Дызайнер",
  Edit_Designs:"Рэдагаваць дызайн",
  New_Design:"Новы дызайн",
  Next:"Далей",
  Save:"Захаваць",
  Name:"Імя",
  Mail:"Пошта",
  Amount:"Сума",
  Date:"Дата",
  PayPal:"PayPal",
  Payouts:"Выплаты",
  PayPal_Label:"Этыкетка PayPal",
  Email_Username:"Электронная пошта / Імя карыстальніка",
  Client_ID:"Ідэнтыфікатар кліента",
  Sandbox_Email:"Электронная пошта ў пясочніцы",
  PayPal_Email:"Электронная пошта PayPal",
  PayPal_Username:"Імя карыстальніка API",
  PayPal_Payouts:"Выплаты PayPal",
  Select_PayPal_Key:"Выберыце ключ PayPal",
  Secret:"Сакрэт",
  API_Secret:"Сакрэт API",
  PayPal_API_Keys:"Ключы PayPal",
  New_PayPal_Key:"Новы ключ PayPal",
  Email_Subject:"Тэма электроннай пошты",
  Email_Message:"Паведамленне па электроннай пошце",
  Payout_Options:"Параметры выплат",
  Payment_Note:"Аплатная запіска",
  Enable_Employee:"Уключыць супрацоўніка",
  Enable_Production_Mode:"Уключыць рэжым вытворчасці",
  Sandbox_Username:"Імя карыстальніка пясочніцы",
  Sandbox_Signature:"Подпіс пясочніцы",
  Sandbox_Password:"Пароль пясочніцы",
  Production_Username:"Вытворчае імя карыстальніка",
  Production_Signature:"Вытворчы подпіс",
  Production_Password:"Вытворчы пароль",
  Production_Email:"Вытворчы ліст",
  API_Client_ID:"Ідэнтыфікатар кліента API",
  API_Signature:"Подпіс API",
  API_Password:"Пароль API",
  API_Username:"Імя карыстальніка API",
  Secret_Key:"Сакрэтны ключ",
  Sandbox:"Пясочніца",
  Production:"Вытворчасць",
  Sandbox_Keys:"Клавішы пясочніцы",
  Production_Keys:"Вытворчыя ключы",
  API_Title:"Назва API",
  Production_Mode:"Вытворчы рэжым",
  Account_Label:"Этыкетка ўліковага запісу",
  No_PayPal_Setup:"Без ключа PayPal",
  Enable_PayPal_Account:"Уключыць уліковы запіс PayPal",
  No_PayPal_Word:"Дадайце свой <a routerLink='/folder/Invoice/New'>ключ API PayPal</a>.",
  Printed_Memo:"Друкаваная памятка",
  Employee:"Супрацоўнік",
  View_Employee:"Прагледзець супрацоўніка",
  Mailing_Address:"Паштовы адрас",
  Company_Address:"Адрас кампаніі",
  Select_Company_Address:"Выберыце адрас кампаніі",
  Address:"Адрас",
  Any_Day:"Любы дзень",
  Address_Name:"Назва адраса",
  Unit:"Адзінка",
  Account:"Рахунак",
  Bank_Account:"Банкаўскі рахунак",
  Account_Limits:"Уключыць ліміты ўліковага запісу",
  Payroll:"Заработная плата",
  New_Payroll:"Новая заработная плата",
  No_Payroll:"Няма маючай адбыцца заработнай платы",
  Upcoming_Holiday:"Надыходзячае свята:",
  Invoice_Due:"Рахунак-фактура да аплаты:",
  New_Invoice:"Новы рахунак-фактура",
  No_Invoices:"Без рахункаў",
  No_Invoices_Word:"Стварыць першы <a routerLink='/folder/Invoice/New'>Рахунак-фактуру</a>.",
  Cheque_Due:"Чэк да аплаты:",
  Payrolls:"Заработная плата",
  Sandbox_Mode:"Рэжым пясочніцы",
  Hire:"Наняць",
  Pay:"Плаціць",
  New:"Новы",
  Add:"Дадаць",
  Make:"Зрабіць",
  Time:"Час",
  Write:"Пішы",
  Holiday:"Свята",
  Holidays:"Святы",
  Next_Holiday:"Наступнае свята:",
  All_Done:"Усё зроблена!",
  Employees:"Супрацоўнікі",
  Payees:"Атрымальнікі плацяжоў",
  Job:"Праца",
  Jobs:"Праца",
  Invoice:"Рахунак-фактура",
  Invoices:"Рахункі-фактуры",
  Vacations:"Канікулы",
  Cheques:"Праверкі",
  Brand_Cheques:"Брэнд",
  Payment:"Аплата",
  Enable_Payment:"Уключыць аплату",
  Payments:"Плацяжы",
  Schedule:"Графік",
  Schedules:"Графік",
  Timesheet:"Табель працоўнага часу",
  Timesheets:"Табліцы часу",
  Salary:"Заробак",
  New_Address:"Новы адрас",
  Address_2:"Адрас (радок 2)",
  _City:"Горад",
  _State:"Дзяржава/Прав",
  City:"Горад / пасёлак",
  State:"Штат / правінцыя",
  Postal:"Паштовы індэкс",
  ZIP:"Паштовы / ZIP",
  Country:"Краіна",
  Addresses:"Адрасы",
  Required_Options:"Неабходныя параметры",
  Optional_Options:"Дадатковыя параметры",
  Additional_Options:"Дадатковыя параметры",
  Required:"Абавязкова",
  Optional:"Неабавязкова",
  Additional:"Дадаткова",
  No_Addresses:"Без адрасоў",
  New_Address_Word:"Дадаць першы <a routerLink='/folder/Address/New'>Адрас</a>.",
  Select_Address:"Выберыце адрас",
  No_Address:"Без адрасоў",
  Print_Cheque:"Праверка друку",
  Print_Cheque_Now:"Раздрукаваць праверку зараз",
  Description:"Апісанне",
  Pay_To_The_Order_Of:"Аплаціць у парадку:",
  Select_Date_Range:"Выберыце дыяпазон дат",
  Select_Starting_Date:"Выберыце дату пачатку",
  Select_Ending_Date:"Выберыце дату заканчэння",
  Select_Date:"Выберыце дату",
  Cheque_Date:"Праверыць дату",
  Cheque_Memo:"Праверыць памятку",
  Blank_Cheque:"Пусты чэк",
  Blank:"Пусты",
  No_Cheques:"Без чэкаў",
  No_Cheques_Word:"Надрукуйце свой першы <a routerLink='/folder/Cheque/New'>Чэк</a>.",
  Cheque_Amount_Placeholder:"$0,00",
  View_Image:"Прагледзець малюнак",
  View:"Выгляд",
  Modify:"Змяніць",
  Delete:"Выдаліць",
  Cheque_Paid:"Аплочаны",
  New_Deduction:"Новы вылік",
  Title:"Назва",
  Frequency:"Частота",
  Hourly:"Штогадзінна",
  Daily:"Штодня",
  Weekdays:"Будні",
  BiWeekly:"2 тыдні",
  TriWeekly:"3 тыдні",
  Monthly:"Штомесяц",
  MiMonthly:"2 месяцы",
  Quarterly:"Квартальнік",
  Yearly:"Штогод",
  Every_Week:"Кожны тыдзень",
  Every_Payroll:"Кожная заработная плата",
  Every_Month:"Кожны месяц",
  Annually:"Штогод",
  Always_Scheduled:"Заўсёды па раскладзе",
  Start_Date:"Дата пачатку",
  End_Date:"Дата заканчэння",
  Start_Time:"Час пачатку",
  End_Time:"Канец час",
  Deduction_Label:"Этыкетка выліку",
  Notes:"Нататкі",
  Options:"Параметры",
  Enable:"Уключыць",
  Select_Deductions:"Абярыце адлічэнні",
  Deductions:"Вылікі",
  No_Deductions:"Без адлічэнняў",
  No_Deductions_Word:"Стварыце свой першы <a routerLink='/folder/Deduction/New'>Дэдукцыя</a>.",
  New_Employee:"Новы супрацоўнік",
  No_Title:"Без назвы",
  _Name:"Імя",
  About_Yourself:"Пра сябе",
  Full_Name:"Поўнае імя",
  Birthday:"Дзень нараджэння",
  Email:"Электронная пошта",
  SMS:"SMS",
  Phone:"Тэлефон",
  Test:"Тэст",
  Call:"Выклік",
  Fax:"Факс",
  Printed_Note:"Друкаваная нататка",
  Position:"Пазіцыя",
  Job_Position:"Працоўная пасада",
  Select_a_Job:"Выберыце працу",
  Select_a_Salary:"Выберыце заробак",
  Add_a_Deduction:"Дадаць адлік",
  Taxes:"Падаткі",
  Add_Taxes:"Дадаць падаткі",
  Date_of_Birth:"Дата нараджэння",
  Email_Address:"Адрас электроннай пошты",
  Phone_Number:"Нумар тэлефона",
  Phone_Call:"Тэлефонны званок",
  Enable_on_Payroll:"Уключыць на заработнай плаце",
  Select_Employees:"Абярыце супрацоўнікаў",
  No_Employees:"Без супрацоўнікаў",
  No_Employees_Word:"Дадайце свой першы новы <a routerLink='/folder/Employee/New'>Супрацоўнік</a>.",
  No_Name:"Без імя",
  Unemployeed:"Беспрацоўны",
  Employeed:"Заняты",
  Paid:"Аплочаны",
  Enabled:"Уключана",
  Disabled:"Інвалід",
  Fire:"Агонь",
  Not_Available:"Не даступны",
  Not_Available_Word:"Раздрукуйце свой першы <a routerLink='/folder/Invoice/New'>Рахунак-фактуру</a> і атрымайце аплату.",
  Select_Invoices:"Select_Invoices",
  Print_Invoice_Word:"Раздрукуйце свой першы <a routerLink='/folder/Invoice/New'>Рахунак-фактуру</a> і атрымайце аплату.",
  Invoice_Title:"Назва рахунка-фактуры",
  Invoice_Date:"Дата рахунка-фактуры",
  Due_Date:"Тэрмін",
  New_Job:"Новая праца",
  Details:"Падрабязнасці",
  Customize:"Наладзіць",
  Customize_Dashboard:"Наладзіць прыборную панэль",
  Components:"Кампаненты",
  No_Components:"Без кампанентаў",
  Main_Components:"Галоўныя кампаненты",
  Smaller_Components:"Дробныя кампаненты",
  Error_Loading_Page:"Памылка загрузкі гэтай старонкі.",
  Bank_Details:"Банкаўскія рэквізіты",
  About_Your_Job:"Пра вашу працу",
  Your_Schedule:"Ваш расклад",
  Job_Title:"Пасада",
  Job_Description:"Апісанне працы",
  Job_Details:"Падрабязнасці працы",
  Enable_Job:"Уключыць працу",
  Pay_Period:"Перыяд аплаты",
  Perpetually_Schedule:"Пастаянны расклад",
  Select_Jobs:"Выберыце вакансіі",
  No_Jobs:"Без працы",
  Add_Jobs:"Дадаць вакансіі",
  No_Jobs_Word:"Дадаць першае <a routerLink='/folder/Job/New'>Заданьне</a> у спіс.",
  Count_Employees:"job.employee_count+' Супрацоўнікі",
  Zero_Employees:"0 супрацоўнікаў",
  New_Leave:"Новы адпачынак",
  Leave_Name:"Пакінуць імя",
  Paid_Leave:"Аплатны водпуск",
  Leave_Pay:"Пакінуць заробак",
  Indefinite_Leave:"Бестэрміновы водпуск",
  Indefinite_Payroll:"Бестэрміновая заработная плата",
  Leave:"Сыходзь",
  Add_Schedules:"Дадаць расклад",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Уключыць адсутнасць",
  Select_Leaves:"Выбраць лісце",
  No_Leaves:"Няма адсутнасці",
  Leave_Of_Absence:"Адпачынак",
  Leaves_Of_Absence:"Лісткі адсутнасці",
  New_Leave_of_Absense:"Новы водпуск",
  No_Leaves_Word:"Дадаць першы <a routerLink='/folder/Leave/New'>адпачынак</a>.",
  Not_Enabled:"Не ўключана",
  Absences:"Адсутнасці",
  Payee:"Атрымальнік плацяжу",
  New_Payee:"Новы атрымальнік плацяжу",
  Payee_Identifier:"Ідэнтыфікатар атрымальніка плацяжу",
  Payee_Name:"Імя атрымальніка плацяжу",
  Payee_Title:"Назва атрымальніка плацяжу",
  Payment_Memo:"Плацёжная запіска",
  Select_Payees:"Абярыце атрымальнікаў плацяжоў",
  No_Payees:"Без атрымальнікаў плацяжоў",
  Add_Payee_Note:"Дадаць першага <a routerLink='/folder/Payee/New'>атрымальніка</a>.",
  New_Payees:"Новыя атрымальнікі плацяжоў",
  About_The_Payment_Schedule:"Графік плацяжоў",
  Your_Payroll_Schedule:"Аўтаматычная заработная плата",
  New_Payment:"Новы плацёж",
  Identifier:"Ідэнтыфікатар",
  Select:"Выбраць",
  Memo:"Памятка",
  Payment_Date:"Дата аплаты",
  Mark_as_Paid:"Пазначыць як аплачанае",
  Select_Payments:"Выберыце плацяжы",
  No_Payments:"Без плацяжоў",
  No_Payments_Word:"Стварыць першы <a routerLink='/folder/Payment/New'>Плацёж</a>.",
  Create_Payroll:"Стварыць заработную плату",
  Properties:"Уласцівасці",
  Payroll_Title:"Назва заработнай платы",
  Payroll_Notes:"Заработная плата",
  Payroll_Setup:"Настройка заработнай платы",
  Tabulate_Payments:"Табліца плацяжоў",
  Tabulate:"Табліца",
  By:"Па:",
  Payments_By:"Плацяжы",
  Timesheets_And_Schedules:"Табліцы часу і расклады",
  Both:"Абодва",
  Items:"Прадметы",
  Add_Payees:"Дадаць атрымальнікаў плацяжоў",
  Add_Account:"Дадаць уліковы запіс",
  Enable_Account:"Уключыць уліковы запіс",
  Enable_Payroll:"Уключыць заработную плату",
  Print_Payroll:"Раздрукаваць заработную плату",
  No_Payrolls:"Без заработнай платы",
  No_Payroll_Word:"Стварыць свой першы <a routerLink='/folder/Payroll/New'>Разлік заработнай платы</a>.",
  View_more:"VIEW_MORE",
  Less:"МЕНШ",
  Add_Payroll:"Дадаць заработную плату",
  Select_Payroll:"Выбраць заработную плату",
  About_Your_Salary:"Пра ваш заробак",
  Add_Salaries:"Дадаць заробкі",
  Add_Salary:"Дадаць заробак",
  Salaries:"Заробкі",
  No_Salaries:"Без заробкаў",
  No_Salaries_Word:"Дадаць першы <a routerLink='/folder/Salary/New'>Заробак</a>.",
  Select_Salaries:"Выберыце заробкі",
  New_Salary:"Новы заробак",
  Salary_Name:"Ідэнтыфікатар заробку",
  Enable_Salary:"Уключыць зарплату",
  Salary_Amount:"Сума заробку",
  New_Schedule:"Новы расклад",
  Schedule_Title:"Назва раскладу",
  Add_Address:"Дадаць адрас",
  Repeat:"Паўтарыць",
  Design:"Дызайн",
  Edit_Design:"Рэдагаваць дызайн",
  Edit_this_Design:"Рэдагаваць гэты дызайн",
  Repeat_Payment:"Паўторны плацёж",
  Enable_Schedule:"Уключыць расклад",
  Never:"Ніколі",
  Select_Schedule:"Выбраць расклад",
  No_Schedules:"Без раскладу",
  No_Schedules_Word:"Стварыць першы <a routerLink='/folder/Schedule/New'>Расклад</a>.",
  New_Administrator:"Новы адміністратар",
  Username:"Імя карыстальніка",
  First_Name:"Імя",
  Last_Name:"Прозвішча",
  Add_an_Address:"Дадаць адрас",
  Payment_Limit:"Ліміт за аплату",
  Total_Limit:"Агульны ліміт",
  Select_Accounts:"Выбраць рахункі",
  No_Administrators:"Без адміністратараў",
  No_Administrators_Word:"Стварыць першы <a routerLink='/folder/Administrator/New'>уліковы запіс адміністратара</a>.",
  New_Administrators_Word:"Дадаць першы <a routerLink='/folder/Administrator/New'>Адміністратар</a>",
  Cloud:"Воблачка",
  Backup:"Рэзервовае капіраванне",
  Enable_iCloud:"Уключыць iCloud",
  Enable_Google_Drive:"Уключыць Дыск Google",
  Enable_Microsoft_OneDrive:"Уключыць Microsoft OneDrive",
  Automatically_Backup:"Аўтаматычна рэзервовае капіраванне",
  FTP_Settings:"Настройкі FTP",
  URL_File_Prompt:"Калі ласка, укажыце месцазнаходжанне файла .xls / .xlsx / .json для імпарту:",
  URL_SQL_Prompt:"Калі ласка, укажыце месцазнаходжанне файла SQLite для імпарту:",
  FTP_Backup:"Рэзервовае капіраванне FTP",
  FTP_Import:"Імпарт FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Гаспадар",
  Port:"Порт",
  Path:"Шлях",
  Data_Path:"Шлях даных",
  Enable_FTP_Account:"Уключыць уліковы запіс FTP",
  HostnameIP:"Імя хаста",
  Password:"Пароль",
  Connection_Port:"Порт злучэння",
  Enable_MySQL_Database:"Уключыць базу даных MySQL",
  Log:"Бярвенне",
  Reset:"Скід",
  Erase:"Сцерці",
  Export:"Экспарт",
  Database:"База даных",
  Upload_CSV:"Загрузіць CSV",
  Download_CSV:"Спампаваць CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Рэзервовае капіраванне MySQL",
  Internal_Notes:"Унутраныя нататкі",
  Root_Path:"Каранёвы шлях",
  Select_Backup:"Выберыце рэзервовую копію",
  Add_New_Backup:"Дадаць новую рэзервовую копію",
  First_Backup:"Наладзіць першую рэзервовую копію...",
  Backups:"Рэзервовыя копіі",
  Add_Backup:"Дадаць рэзервовую копію",
  No_Backups:"Не знойдзена рэзервовых копій.",
  Select_Backup_Type:"Выберыце тып рэзервовай копіі, які вы хочаце наладзіць...",
  Backup_Type:"Тып рэзервовага капіявання",
  FTP_Drive:"FTP Drive",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Драйв",
  Microsoft_OneDrive:"Драйв",
  Import_Fields:"Імпартныя палі",
  Import_Fields_Word:"Выкарыстоўвайце гэты раздзел, каб выбраць <a routerLink='/folder/Holiday/New'>Імпарт</a> даных.",
  Upload:"Загрузіць",
  Download:"Спампаваць",
  Download_JSON:"Спампаваць як дадзеныя JSON",
  Download_SQL:"Спампаваць як файл SQL",
  New_Bank:"Новы банк",
  New_Account:"Новы рахунак",
  New_Bank_Account:"Новы банкаўскі рахунак",
  Upload_Image:"Загрузіць малюнак",
  Bank_Name:"Назва банка",
  Bank_Address:"Адрас банка",
  Branch_Address:"Адрас філіяла",
  Address_on_Cheque:"Адрас",
  Cheque_Numbers:"Праверыць лічбы",
  Cheque_Details:"Праверце дэталі",
  Bank_Logo:"Лагатып банка",
  Cheque_ID:"Праверыць ідэнтыфікатар",
  Starting_Cheque_ID:"Пачатковая праверка ID",
  Transit_Number:"Транзітны нумар",
  Institution_Number:"Нумар установы",
  Designation_Number:"Нумар абазначэння",
  Account_Number:"Нумар акаўнта",
  Limits:"Ліміты",
  Default_Limits:"Ліміты па змаўчанні",
  Over_Limit:"За ліміт",
  Under_Limit:"Пад лімітам",
  Payroll_Limit:"Ліміт заработнай платы",
  Enable_Bank_Account:"Уключыць банкаўскі рахунак",
  Select_Account:"Выбраць уліковы запіс",
  No_Bank_Account:"Без банкаўскага рахунку",
  No_Bank_Account_Word:"Дадаць першы <a routerLink='/folder/Accounts/New'>Банкаўскі рахунак</a>.",
  Bank_Accounts:"Банкаўскія рахункі",
  No_Accounts:"Без рахункаў",
  No_Accounts_Note:"Дадаць першы <a routerLink='/folder/Accounts/New'>Банкаўскі рахунак</a>.",
  Cheque_Designer:"Праверка дызайнера",
  Cheque_Design:"Праверце дызайн",
  Select_Design:"Выберыце дызайн",
  Cheque_Designs:"Праверыць дызайн",
  No_Cheque_Designs:"Без праверкі дызайну",
  No_Cheque_Designs_Word:"Стварыце свой уласны <a routerLink='/folder/Settings/Cheque/Design/New'>Праверце дызайн</a>.",
  Set_Default:"Ўсталяваць па змаўчанні",
  Default:"Па змаўчанні",
  Remove:"Выдаліць",
  Folder:"Папка",
  Edit:"Рэдагаваць",
  LoadingDots:"Загрузка...",
  Add_a_New_File:"Дадаць <a href='#' (пстрыкніце)='add()'>Новы файл</a> у",
  this_folder:"гэта папка",
  FTP_Backup_Settings:"Настройкі рэзервовага капіявання FTP",
  Secure_File_Transfer:"Бяспечная перадача файлаў",
  New_Holiday:"Новае свята",
  Add_Holiday:"Дадаць свята",
  Holiday_Name:"Імя свята",
  Additional_Pay:"Дадатковая аплата",
  Enable_Holiday:"Уключыць адпачынак",
  Select_Holidays:"Абярыце святы",
  No_Holidays:"Без святаў",
  No_Holidays_Word:"Дадаць першы <a routerLink='/folder/Holiday/New'>дзяржаўнае свята</a>.",
  New_Import:"Новы імпарт",
  Import_Data:"Імпарт даных",
  Import_Data_Word:"Выберыце тып файла або спосаб загрузкі па вашаму выбару.<br /> Вы зможаце выбраць, якія імпартаваныя палі ў файле адпавядаюць любому параметру ў дадатку пасля загрузкі файла, які падтрымліваецца.", 
  Import_File:"Файл імпарту",
  Link_To_File:"Спасылка на файл",
  Select_File:"Выбраць файл",
  New_Moderator:"Новы мадэратар",
  Allow_Moderation:"Дазволіць мадэрацыю",
  Create_Paycheques:"Ствараць заробкі",
  Edit_Paycheques_and_Data:"Рэдагаваць зарплаты і даныя",
  Destroy_Data_and_Paycheques:"Знішчыць дадзеныя і заробкі",
  Bonus_Percentage:"Працэнт заробку",
  Fixed_Amount:"Фіксаваная сума",
  Select_Moderator:"Абярыце мадэратара",
  No_Moderators:"Без мадэратараў",
  Moderators:"Мадэратары",
  Moderator_Account:"Стварыць першы <a routerLink='/folder/Administrator/New'>уліковы запіс мадэратара</a>.",
  No_Moderators_Word:"Дадаць першы <a routerLink='/folder/Administrator/New'>Мадэратар</a>.",
  Defaults:"Па змаўчанні",
  Provide_Us_Info:"Падайце нам інфармацыю",
  Setup_Your_Printer:"Наладзіць прынтэр",
  Your_Company:"Аб вашай кампаніі",
  Company_Name:"Назва кампаніі",
  Currency:"Валюта",
  Default_Currency:"Валюта па змаўчанні",
  Base_Monthly_Income:"Штомесячны даход",
  Protection:"Абарона",
  App_Protection:"Абарона прыкладання",
  PIN_Code_Protection:"Абарона PIN-кода",
  App_Protection_Word:"Уключыць метады бяспекі, якія дапамагаюць абараніць ваш уліковы запіс.",
  PIN_Code:"PIN-код",
  Change_PIN:"Змяніць PIN-код",
  New_Password:"Новы пароль",
  Geofence_Protection:"Ахова геаагароджы",
  Geofence_Area:"Устанавіць вобласць",
  Distance:"Адлегласць",
  Setup_Now:"Наладзіць зараз",
  Mile:"Міля",
  Km:"Км",
  m:"м",
  Facial_Recognition:"Распазнанне твараў",
  Face:"Твар",
  Setup:"Усталяваць",
  Label:"Этыкетка",
  Password_Protection:"Абарона паролем",
  Modify_Password:"Змяніць пароль",
  New_Tax_Entry:"Новы падатковы запіс",
  New_Tax:"Новы падатак",
  Tax_Label:"Падатковая этыкетка",
  Perpetually_Enabled:"Пастаянна ўключана",
  Select_Taxes:"Абярыце падаткі",
  Tax_Deductions:"Падатковыя вылікі",
  No_Tax_Deductions:"Без падатковых вылікаў",
  No_Tax_Deductions_Word:"Дадаць першы <a routerLink='/folder/Tax/New'>Падатковы</a> вылік.",
  New_Timer:"Новы таймер",
  Start:"Пачатак",
  Stop:"Стоп",
  Start_Timer:"Таймер запуску",
  Stop_Timer:"Стоп таймер",
  Timer_Active:"Таймер актыўны",
  Timer:"Таймер:",
  Timer_Running:"Таймер: (Запуск)",
  Save_Timer:"Захаваць таймер",
  New_Timesheet:"Новы табель часу",
  Select_Timesheets:"Выберыце табліцы часу",
  Work_Notes:"Рабочыя нататкі",
  Entry_Title:"Назва запісу",
  No_Timesheets:"Няма таблічак уліку часу",
  No_Timesheets_Word:"Дадаць першы <a routerLink='/folder/Timesheet/New'>Табель працы</a>.",
  Timesheet_Submitted:"Табель часу адпраўлены",
  Timesheet_Congrats:"Віншуем! Ваш табель часу паспяхова адпраўлены. Дзякуй!",
  Timesheet_Copy:"Каб атрымаць копію вашых дакументаў, пакажыце нам свой адрас электроннай пошты і/або нумар мабільнага тэлефона.",
  Submit:"Адправіць",
  Allow_Notifications:"Дазволіць апавяшчэнні",
  Untitled_Entry:"Новы запіс",
  Untitled_Bank:"Банк без назвы",
  Timesheet_Entry:"Запіс працоўнага часу",
  Work_Description:"Апісанне працы",
  Enable_Timesheet:"Уключыць табель часу",
  Submit_Timesheet:"Адправіць табелі часу",
  Vacation:"Канікулы",
  New_Vacation:"Новыя вакацыі",
  Vacation_Name:"Назва водпуску",
  Paid_Vacation:"Аплатны адпачынак",
  Vacation_Pay:"Аплата водпуску",
  Enable_Vacation:"Уключыць адпачынак",
  Select_Vacations:"Выберыце вакацыі",
  No_Vacations:"Без водпуску",
  No_Vacations_Word:"Стварыць першы запіс <a routerLink='/folder/Vacation/New'>Канікулы</a>.",
  Payroll_Schedule:"Графік заработнай платы",
  Next_Payroll:"Наступная заработная плата:",
  Upcoming_Payroll:"Будучая зарплата",
  No_Upcoming_Payroll:"Няма маючай адбыцца заработнай платы",
  Address_Book:"Адрасная кніга",
  Archived_Documents:"Архіўныя дакументы",
  Dev_Mode:"Прыкладанне ў рэжыме распрацоўкі",
  Administrators:"Адміністратары",
  Privacy:"Прыватнасць",
  None:"Няма",
  Select_Signature:"Выбраць подпіс",
  No_Signatures:"Без подпісаў",
  No_Signatures_Word:"Дадаць першую <a routerLink='/folder/Signature/New'>Подпіс</a>.",
  Repeat_Indefinitely:"Паўтараць бясконца",
  Sign:"Знак",
  Sign_Here:"Падпішыце тут",
  Date_Signed:"Дата падпісання",
  Signature_Pad:"Падпісны блок",
  Account_Holder:"Уладальнік рахунку",
  Account_Properties:"Уласцівасці акаўнта",
  Name_On_Cheque:"Імя на чэку",
  Server_Hostname:"Імя хаста сервера / IP",
  Printers:"Друкаркі",
  No_Printers:"Без друкарак",
  Printer_Exists:'Прынтэр з такім імем ужо існуе.',
  No_Printers_Word:"Дадайце самы першы <a routerLink='/folder/Printer/New'>прынтэр</a>.",
  No_Printer_Alert:"Прынтэр не вызначаны. Вы жадаеце наладзіць прынтэр?",
  Add_Printer:"Дадаць прынтэр",
  New_Printer:"Новы прынтэр",
  Printer_Hostname:"Імя хаста друкаркі / IP",
  Printer_Label:"Этыкетка для друкаркі",
  Printer_Protocol:"Пратакол друкаркі",
  Protocol:"Пратакол",
  Email_Print:"Электронная пошта",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Разетка",
  Print_Job:"Заданне на друк",
  Printed:"Надрукаваны",
  Not_Printed:"Не надрукавана",
  Print_Jobs:"Заданні для друку",
  Print_Queue:"Чарга друку",
  No_Print_Jobs:"Без працы для друку",
  No_Prints:"Стварыць новы <a routerLink='/folder/Cheque/New'>Чэк</a> для друку.",
  Prints:"Друкі",
  Find_Printer:"Знайсці прынтэр",
  Find_AirPrint_Devices:"Знайсці прылады AirPrint",
  Select_Printer:"Выбраць прынтэр",
  System_UI:"Інтэрфейс сістэмы",
  Printer:"Друкарка",
  Status:"Статус",
  Preview:"Папярэдні прагляд",
  Enable_Print_Job:"Уключыць заданне друку",
  Queue_Weight:"Вага чаргі",
  Unlimited:"Неабмежавана",
  Show_Advanced_Printer_Options:"Паказаць дадатковыя параметры друкаркі",
  Advanced:"Пашыраны",
  Location:"Размяшчэнне",
  Note:"Нататка",
  Queue_Name:"Імя чаргі",
  Pages_Printed_Limit:"Ліміт надрукаваных старонак",
  MultiPage_Idle_Time:"Час чакання на шмат старонак (с)",
  Page_Count_Limit:"Абмежаванне колькасці старонак",
  Page_Orientation:"Арыентацыя старонкі",
  Portrait:"Партрэт",
  Landscape:"Пейзаж",
  Duplex:"Дуплекс",
  Double_Sided_Printing:"Двухбаковы",
  Duplex_Mode:"Дуплексны рэжым",
  Duplex_Short:"Кароткі",
  Duplex_Long:"Доўга",
  Duplex_None:"Няма",
  Color_And_Quality:"Колер і якасць",
  Monochrome:"Манахромны",
  Photo_Quality_Prints:"Якасныя адбіткі фатаграфій",
  Printer_Email:"Электронная пошта друкаркі",
  Automatically_Downsize:"Аўтаматычна памяншаць",
  Paper:"Папера",
  Paper_Name:"Назва паперы",
  Paper_Width:"Шырыня паперы",
  Paper_Height:"Вышыня паперы",
  Paper_Autocut_Length:"Даўжыня аўтаматычнага разрэзу паперы",
  Margins:"Маржы",
  Page_Margins:"Паля старонкі",
  Page_Margin_Top:"Поле верхняй старонкі",
  Page_Margin_Right:"Правае поле старонкі",
  Page_Margin_Bottom:"Ніжняе поле старонкі",
  Page_Margin_Left:"Левы край старонкі",
  Add_Employees:"Дадаць супрацоўнікаў",
  Header:"Загаловак",
  Print_A_Page_Header:"Раздрукаваць загаловак старонкі",
  Header_Label:"Загалоўная этыкетка",
  Header_Page_Index:"Індэкс загалоўнай старонкі",
  Header_Font_Name:"Шрыфт загалоўка",
  Select_Font:"Выбраць шрыфт",
  Font_Selector:"Выбар шрыфтоў",
  Header_Font_Size:"Шрыфт загалоўка",
  Header_Bold:"Загаловак тлусты",
  Header_Italic:"Загаловак курсіў",
  Header_Alignment:"Выраўноўванне загалоўка",
  Left:"Злева",
  Center:"Цэнтр",
  Right:"Правільна",
  Justified:"Апраўданы",
  Header_Font_Color:"Колер загалоўка",
  Select_Color:"Выбраць колер",
  Footer:"Ніжняя калантытула",
  Print_A_Page_Footer:"Раздрукаваць калонтытул",
  Footer_Label:"Этыкетка калантытула",
  Footer_Page_Index:"Індэкс старонкі калантытула",
  Footer_Font_Name:"Шрыфт ніжняга калонтытула",
  Fonts:"Шрыфты",
  Done:"Гатова",
  Select_Fonts:"Выбраць шрыфты",
  Footer_Font_Size:"Памер калантытула",
  Footer_Bold:"Ніжняга калонтытула тлусты",
  Footer_Italic:"Кусіўны калантытул",
  Footer_Alignment:"Выраўноўванне ніжняга калонтытула",
  Footer_Font_Color:"Колер ніжняга калонтытула",
  Page_Copies:"Копіі старонак",
  Enable_Printer:"Уключыць прынтэр",
  Remote_Logging:"Аддаленая рэгістрацыя",
  Log_Server:"Сервер часопісаў",
  Encryption:"Шыфраванне",
  Random_Key:"Выпадковы ключ",
  Encryption_Key:"Ключ шыфравання",
  Cheques_Webserver:"Карыстальніцкая база даных",
  Learn_How:"Даведацца, як",
  Signature:"Подпіс",
  Default_Printer_Unit:"дзюймы/см/мм/(пт)",
  View_Signature:"Прагледзець подпіс",
  Printed_Signature:"Друкаваны подпіс",
  Digitally_Sign:"Лічбавы знак",
  Digital_Signature:"Лічбавы подпіс",
  Add_Signature:"Дадаць подпіс",
  Add_Your_Signature:"Дадайце свой подпіс",
  Enable_Signature:"Уключыць подпіс",
  Digitally_Signed:"Лічбавы подпіс",
  Insert_Error:"Немагчыма захаваць чэк з-за праблем з базай дадзеных.",
  Delete_Confirmation:"Вы ўпэўнены, што хочаце выдаліць гэтую інфармацыю?",
  Discard_Confirmation:"Вы ўпэўнены, што хочаце выдаліць гэтую інфармацыю?",
  Discard_Bank_Confirmation:"Вы ўпэўнены, што хочаце выдаліць гэты ўліковы запіс?",
  Discard_Printer_Confirmation:"Вы ўпэўнены, што жадаеце адмовіцца ад гэтага прынтара?",
  Delete_Bonus_Confirmation:"Вы ўпэўнены, што хочаце выдаліць гэты бонус?",
  Delete_Invoice_Confirmation:"Вы ўпэўнены, што хочаце выдаліць гэты рахунак?",
  Generated_Cheque:"Створаны чэк",
  Generated_Invoice:"Створаны рахунак-фактура",
  Schedule_Start_Time:"Пачатак раскладу",
  Schedule_End_Time:"Канец раскладу",
  New_Call:"Новы званок",
  No_Contacts:"Без кантактаў",
  No_Contacts_Word:"Адміністратары, мадэратары, супрацоўнікі і атрымальнікі плацяжоў паказваюцца ў якасці кантактаў.",
  PDF_Subject:"фінансы",
  PDF_Keywords:"заработная плата чэкі PDF чэкі",
  Printer_Setup:"Настройка друкаркі",
  Printer_Selection:"Выбар друкаркі",
  New_Fax:"Новы факс",
  New_Fax_Message:"Новае факс-паведамленне",
  Fax_Machine:"Факс",
  Fax_Name:"Імя факса",
  Fax_Email:"Электронная пошта факса",
  Fax_Number:"Нумар факса",
  Contents:"Змест",
  Fax_Body:"Тэз старонкі",
  Header_Word:"Загаловак:",
  Header_Text:"Тэкст загалоўка",
  Include_Header:"Уключыць загаловак",
  Include_Footer:"Уключыць калантытул",
  Footer_Word:"Ніжня калонтытул:",
  Footer_Text:"Тэкст ніжняга калонтытула",
  Attach_a_Cheque:"Далучыць чэк",
  Add_Deduction:"Дадаць адлік",
  Enable_Fax:"Адправіць факс",
  Select_Fax:"Выбраць факс",
  No_Faxes:"Без факсаў",
  Faxes:"Факсы",
  Save_and_Send:"Адправіць факс",
  Save_and_Pay:"Захаваць і аплаціць",
  WARNING:"УВАГА:",
  Remember:"Памятай",
  Printing:"Друк",
  Printing_Complete:"Друк завершаны!\n\n",
  of:"з",
  There_Were:"Там былі ",
  Successful_Prints:" паспяховыя адбіткі і ",
  Unsuccessful_Prints:"няўдалыя адбіткі.",
  PrinterError:"Прабачце! Адбылася памылка.",
  Printing_:"Друк...",
  PrinterSuccess:"Дакумент паспяхова надрукаваны.",
  PrintersSuccess:"Дакументы надрукаваныя паспяхова.",
  Print_Cheques:"Раздрукаваць чэкі",
  New_Message:"Новае паведамленне",
  New_Messages:"Новыя паведамленні",
  Read_Message:"Прачытаць паведамленне",
  Write_Message:"Напісаць паведамленне",
  Send_Message:"Адправіць паведамленне",
  Subject:"Тэма",
  Message:"Паведамленне",
  Writing:"Пішу...",
  Send:"Адправіць",
  Set_Date:"Устанавіць дату",
  Set_Time:"Устанавіць час",
  Recieve:"Атрымаць",
  Set_as_Default:"Ўсталяваць па змаўчанні",
  Default_Account:"Уліковы запіс па змаўчанні",
  Default_Design:"Дызайн па змаўчанні",
  Multiple_Cheques:"Праверкі (тройны)",
  Account_Mode:"Рэжым акаўнта",
  Multiple_Cheques_Description:"Тры праверкі на старонцы.",
  Check_and_Table:"Праверка і табліца",
  Check_including_Table:"Праверка-бухгалтарская табліца.",
  Check_Mailer:"Праверыць рассылку",
  Check_Mailer_Window:"Праверце ў адрасным акне.",
  DocuGard_Table_Top:"Праверка і стол DocuGard (зверху)",
  DocuGard_Table_Middle:"Праверка і табліца DocuGard (Сярэдзіна)",
  DocuGard_Table_Bottom:"Праверка і табліца DocuGard (унізе)",
  DocuGard_Mailer_Top:"DocuGard Check Mailer (Уверсе)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (Сярэдні)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mailer (унізе)",
  DocuGard_Three_Cheques:"DocuGard Checks (Tripple)",
  DocuGard_Cheque_Top:"DocuGard Check (Уверсе)",
  DocuGard_Cheque_Middle:"Праверка DocuGard (Сярэдні)",
  DocuGard_Cheque_Bottom:"Праверка DocuGard (унізе)",
  DocuGard_Three_Cheques_Window:"Тры праверкі на адной старонцы.",
  DocuGard_Table_Top_Window:"Табліца чэкаў і даходаў.",
  DocuGard_Table_Middle_Window:"Табліца чэкаў і даходаў.",
  DocuGard_Table_Bottom_Window:"Табліца чэкаў і даходаў.",
  DocuGard_Mailer_Top_Window:"Чэк, стол і адрас.",
  DocuGard_Mailer_Middle_Window:"Чэк, стол і адрас.",
  DocuGard_Mailer_Bottom_Window:"Чэк, стол і адрас.",
  DocuGard_Cheque_Top_Window:"Праверце надзейнасць паперы.",
  DocuGard_Cheque_Middle_Window:"Праверце надзейнасць паперы.",
  DocuGard_Cheque_Bottom_Window:"Праверце надзейнасць паперы.",
  Basic_Cheque:"Праверыць (зверху)",
  Basic_Cheque_Print:"Раздрукаваць адзін чэк.",
  Error_Setting_As_Paid:"Памылка ўстаноўкі як платнае",
  Add_Attachment:"Дадаць укладанне",
  PrinterUnavailable:"Прынтэр недаступны",
  CreditCardComponent:"Карты",
  PaypalComponent:"PayPal",
  InteracComponent:"Інтэрак",
  BitcoinComponent:"Біткоін",
  New_Deposit:"Новы дэпазіт",
  Deposits:"дэпазіты",
  No_Deposits:"Без дэпазітаў",
  Credit_Card_Deposit:"Крэдытная картка",
  New_Credit_Card_Deposit_Message:"Дэпазіт па крэдытнай карце",
  New_BitCoin_Deposit:"Біткоін",
  New_BitCoin_Deposit_Message:"Дэпазіт у біткойнах",
  New_Interac_Deposit:"Інтэрак",
  New_Interac_Deposit_Message:"Interac Transfer",
  Payments_Limit:"Ліміт аплаты",
  No_Payment_Limit:"Без ліміту аплаты",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Дэпазіт PayPal",
  No_Deposits_Word:"Дадаць першую кампанію <a routerLink='/folder/Deposit/New'>Дэпазіт</a>.",
  No_Documents_Specified:"Дакументы для друку не паказаны",
  No_Printers_Added:"Прынтэры не знойдзены. Перайдзіце ў Налады > Прынтэры, каб дадаць адзін.",
  DB_Erase_Prompt:"Поўнасцю сцерці ўсю базу дадзеных? ПАПЯРЭДЖАННЕ: Вы страціце ўсю захаваную інфармацыю!",
  Console_Warning:"Не ўстаўляйце тэкст у гэтую кансоль. Вы можаце падвяргаць сябе і/ці сваю кампанію сур'ёзнай рызыцы.",
  No_Faxes_Word:"Стварыць першы <a routerLink='/folder/Fax/New'>Факс</a>.",
  Cheque_Delete_Confirmation:"Вы ўпэўнены, што хочаце выдаліць гэты чэк?",
  Design_Delete_Confirmation:"Вы ўпэўнены, што хочаце выдаліць гэты дызайн?",
  Cheque_Pay_Confirmation:"Пазначыць гэты чэк як аплачаны? Ён НЕ з'явіцца ў чарзе друку.",
  Payment_Pay_Confirmation:"Пазначыць гэты плацёж як аплачаны? Ён НЕ з'явіцца ў чарзе чэкаў.",
  Delete_Deduction_Confirmation:"Вы ўпэўнены, што хочаце выдаліць гэты вылік?",
  Delete_Job_Confirmation:"Вы ўпэўнены, што хочаце выдаліць гэтае заданне?",
  Delete_Timesheet_Confirmation:"Вы ўпэўнены, што хочаце выдаліць гэты расклад?",
  Delete_Schedule_Confirmation:"Вы ўпэўнены, што хочаце выдаліць гэты расклад?",
  Delete_Setting_Confirmation:"Вы ўпэўнены, што хочаце скінуць гэту наладу?",
  Delete_Fax_Confirmation:"Вы ўпэўнены, што хочаце выдаліць гэты факс?",
  Delete_File_Confirmation:"Вы ўпэўнены, што хочаце выдаліць гэты файл?",
  Delete_Vacation_Confirmation:"Вы ўпэўнены, што хочаце выдаліць гэты адпачынак?",
  Delete_Printer_Confirmation:"Вы ўпэўнены, што хочаце выдаліць гэты прынтэр?",
  Remove_Design_Confirmation:"Вы ўпэўнены, што хочаце выдаліць гэты дызайн?",
  Delete_Payroll_Confirmation:"Вы ўпэўнены, што жадаеце выдаліць гэтую заработную плату?",
  Send_Fax_Email_Confirmation:"Вы хочаце адправіць гэтыя дакументы па факсе і па электроннай пошце?",
  Send_Email_Confirmation:"Вы хочаце адправіць гэты дакумент па электроннай пошце?",
  Send_Fax_Confirmation:"Вы хочаце адправіць гэты дакумент па факсе?",
  Error_Generating_PDF:"Прабачце. Пры стварэнні гэтага дакумента адбылася памылка.",
  PDF_Error_Saving_Image:"Прабачце. Адбылася памылка пры захаванні выявы PDF гэтага дакумента.",
  Test_Printer_Confirmation:"Вы хочаце раздрукаваць тэставую старонку на гэтым прынтары?",
  Save_Timesheet_Prompt:"Калі ласка, дадайце 'Назва' або націсніце 'Пачаць таймер', каб захаваць.",
  Remove_Geofence_Prompt:"Вы ўпэўнены, што хочаце выдаліць месцазнаходжанне гэтай геаагароджы?",
  Delete_Employee_Confirmation:"Вы ўпэўнены, што хочаце выдаліць",
  Fire_Employee_Confirmation:"Вы ўпэўнены, што хочаце звольніць"
}