export const Mr = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"कॉपीराइट &कॉपी; 2023",
  black:"काळा",
  green:"हिरवा",
  gold:"सोने",
  blue:"निळा",
  brown:"तपकिरी",
  purple:"जांभळा",
  pink:"गुलाबी",
  red:"लाल",
  Swatches:"स्वॅच",
  HSL:"एचएसएल",
  RGB:"RGB",
  Hue:"रंग",
  Saturation:"संपृक्तता",
  Lightness:"हलकेपणा",
  Upgrade_To_Pro:"प्रो वर श्रेणीसुधारित करा",
  AllFeaturesInclude:"सर्व वैशिष्ट्यांमध्ये हे समाविष्ट आहे:",
  PrintUnlimitedCheques:"अमर्यादित धनादेश मुद्रित करा",
  PremiumChequeDesigns:"प्रीमियम चेक डिझाईन्स",
  ManageUnlimitedEmployees:"अमर्यादित कर्मचारी व्यवस्थापित करा",
  AddUnlimitedPayees:"अमर्यादित प्राप्तकर्ता जोडा",
  CreateUnlimitedPayrolls:"अमर्यादित पेरोल्स तयार करा",
  UnlimitedSchedulesandTimeSheets:"अमर्यादित वेळापत्रक आणि वेळ पत्रके",
  BulkPayPalPayouts:"मोठ्या प्रमाणात PayPal पेआउट",
  UnlimitedBankAccounts:"अमर्यादित बँक खाती",
  ManageMultipleCompanies:"एकाधिक कंपन्या व्यवस्थापित करा",
  TrackInsurancePolicies:"विमा पॉलिसींचा मागोवा घ्या",
  Bio_MetricProtection:"बायो-मेट्रिक संरक्षण",
  Geo_FenceLock_OutProtection:"जिओ-फेंस लॉक-आउट संरक्षण",
  Multiple_Companies_Word:"अनेक कंपन्यांचे व्यवस्थापन चेक प्रीमियमशिवाय उपलब्ध नाही.",
  PayPal_Payouts_Word:"PayPal देयके चेक प्रीमियमशिवाय अक्षम केली जातात.",
  PINProtection:"पिन संरक्षण",
  PasswordProtection:"पासवर्ड संरक्षण",
  May_Require_Approval:"मंजुरीची आवश्यकता असू शकते.",
  Subscribe:"सदस्यता घ्या",
  Billed:"बिल केले",
  Up:"वर",
  Down:"खाली",
  Positioning:"पोझिशनिंग",
  Marker:"मार्कर",
  Drag_Marker:"मार्कर ड्रॅग करा",
  Tagline:"चेक छापा आणि पेरोल टेबल करा",
  Marker_Word:"घटक आकार देण्यासाठी मार्कर वापरा.",
  Pinch_Zoom:"चिमूटभर झूम",
  Pinch_Word:"घटक झूम करण्यासाठी पिंच करा.",
  Drag:"ड्रॅग करा",
  Drag_Word:"घटक ड्रॅग करण्यासाठी तुमचे बोट वापरा.",
  subscription_alias_word:"स्वयं-नूतनीकरण सदस्यता",
  premium_alias_word:"एक-वेळ अपग्रेड पॅकेज",
  print_alias_word:"वैयक्तिक चेक प्रिंट-आउट",
  mode_alias_word:"मोड",
  Pro:"प्रो",
  Pro_word:"प्रो आवृत्ती आवश्यक आहे.",
  Cant_Delete_Default_Company_Word:"क्षमस्व, तुम्ही तुमची डीफॉल्ट कंपनी हटवू शकत नाही .",
  Reinsert_Default_Designs:"डीफॉल्ट डिझाईन्स पुन्हा घाला",
  Reinsert_Default_Designs_word:"तुम्हाला डीफॉल्ट डिझाईन्स पुन्हा घालायचे आहेत का?",
  Subscription_Active_Disable_Word:"ही सदस्यता सक्रिय आहे. तुम्ही चेकची ही सदस्यता रद्द करू इच्छिता?",
  Company_Logo:"कंपनी लोगो",
  ZERO_:"शून्य",
  Disclaimer:"अस्वीकरण",
  Privacy_Policy:"गोपनीयता धोरण",
  EULA:"EULA तपासते",
  Terms_Of_Service:"सेवा अटी",
  Terms_Of_Use:"वापरण्याच्या अटी",
  Refunds:"परतावा धोरण",
  Single_Print:"1 तपासा",
  Two_Prints:"2 धनादेश",
  Five_Prints:"5 चेक",
  Ten_Prints:"10 धनादेश",
  Fifteen_Prints:"15 धनादेश",
  Twenty_Prints:"20 धनादेश",
  Thirty_Prints:"30 चेक",
  Image_Added:"प्रतिमा जोडली",
  Image_Preview:"प्रतिमा पूर्वावलोकन",
  No_Image_Was_Selected:"कोणतीही प्रतिमा निवडली नाही.",
  Cheques_Unlimited:"चेक अनलिमिटेड",
  _Subscription:"वर्गणी",
  Subscription:"धनादेश - 1 महिना",
  Two_Month_Subscription:"धनादेश - 2 महिने",
  Three_Month_Subscription:"धनादेश - 3 महिने",
  Six_Month_Subscription:"धनादेश - 6 महिने",
  Twelve_Month_Subscription:"धनादेश - 12 महिने",
  Cheques_Are_Available:"चेक छापण्यासाठी उपलब्ध आहेत.",
  Upgrade_Required_Two:"एक पॅकेज निवडा आणि तुमची खरेदी सुरू करण्यासाठी किंमत बटणावर दोनदा टॅप करा. काही सेकंदात व्यावसायिक दिसणारे पूर्ण-रंगाचे चेक प्रिंट करा.",
  Month:"महिना",
  Due:"देय:",
  Expires:"कालबाह्य:",
  Subscription_Active:"सदस्यता सक्रिय",
  Subscription_Inactive:"सदस्यता निष्क्रिय",
  Purchase_Additional_Cheques:"अतिरिक्त चेक खरेदी करायचे?",
  Printable_Cheque:"प्रिंट करण्यायोग्य चेक",
  Printable_Cheques:"छापण्यायोग्य धनादेश",
  Printable_Cheque_Word:"चेक तुमच्या खात्यावर उपलब्ध आहे.",
  Printable_Cheques_Word:"धनादेश तुमच्या खात्यावर उपलब्ध आहेत.",
  Max_Amount_Message:"तुम्ही निर्दिष्ट केलेली रक्कम या प्रणालीसाठी सेट केलेल्या कमाल रकमेवर पोहोचली आहे:",
  Terms_Required_Word:"चेक वापरणे सुरू ठेवण्यापूर्वी तुम्ही या कराराशी सहमत असणे आवश्यक आहे.",
  Subscriptions:"सदस्यता",
  Product_Upgrades:"अपग्रेड",
  Mailed_Out_Cheques:"मेल-आउट चेक",
  Enter_A_Company_Name:"कृपया कंपनीचे नाव एंटर करा.",
  Single_Cheques:"सिंगल चेक",
  Cheque_Golden:"गोल्डन चेक",
  Cheque_Golden_Window:"गोल्डन चेक डिझाइन.",
  Cheque_Green:"ग्रीन चेक",
  Cheque_Green_Window:"ग्रीन चेक डिझाइन.",
  Cheque_Red:"रेड चेक",
  Cheque_Red_Window:"लाल चेक डिझाइन.",
  Cheque_Yellow:"पिवळा चेक",
  Cheque_Yellow_Window:"पिवळा चेक डिझाइन.",
  Cheque_Statue_of_Liberty:"स्टॅच्यू ऑफ लिबर्टी",
  Cheque_Statue_of_Liberty_Window:"स्टॅच्यू ऑफ लिबर्टी चेक डिझाइन.",
  Cheque_Green_Wave:"हिरवी लाट",
  Cheque_Green_Wave_Window:"ग्रीन चेक डिझाइन.",
  Cheque_Golden_Weave:"सोनेरी विणणे",
  Cheque_Golden_Weave_Window:"मोहक सोनेरी चेक डिझाइन.",
  Cheque_Green_Sun:"हिरवा सूर्य",
  Cheque_Green_Sun_Window:"खोल आणि शांत चेक डिझाइन.",
  Cheque_Blue_Checkers:"ब्लू चेकर्स",
  Cheque_Blue_Checkers_Window:"ब्लू चेक डिझाइन.",
  Cashiers_Check:"कॅशियर चेक",
  Cashiers_Check_Window:"कॅशियर चेक शैली टेम्पलेट.",
  Cheque_Aqua_Checkers:"एक्वा चेकर्स",
  Cheque_Aqua_Checkers_Window:"एक्वा चेक डिझाइन.",
  Cheque_Golden_Checkers:"गोल्डन चेकर्स",
  Cheque_Golden_Checkers_Window:"गोल्डन चेक डिझाइन.",
  Upgrade_Unavailable:"अपग्रेड अनुपलब्ध",
  Bank_Code_Protection:"बँक क्रमांक संरक्षण",
  Bank_Code_Protection_Word:"दुसर्‍या डिव्हाइसवर किंवा दुसर्‍या वापरकर्त्यासाठी चालणार्‍या या अॅपवर तुमचे बँक नंबर वापरण्यापासून संरक्षित करा. ही क्रिया अपरिवर्तनीय आहे. सुरू?",
  Bank_Code_Protection_Blocked_Word:"तुम्ही वापरण्याचा प्रयत्न करत असलेले बँक क्रमांक दुसऱ्या वापरकर्त्यासाठी किंवा डिव्हाइससाठी राखीव आहेत.",
  Bank_Code_Protection_Error_Word:"क्रमांक पडताळणी अयशस्वी झाली आहे. कृपया इंटरनेटशी कनेक्ट करा आणि हे बँक खाते पुन्हा जोडण्याचा प्रयत्न करा.",
  Bank_Code_Protection_Set_Error_Word:"बँक क्रमांक संरक्षणासाठी तुम्ही इंटरनेटशी कनेक्ट केलेले असणे आवश्यक आहे. कृपया कनेक्ट करा आणि पुन्हा प्रयत्न करा.",
  Upgrade_Unavailable_Word:"क्षमस्व, आम्हाला तुमची पडताळणी करण्यात समस्या येत आहे. तुमच्या क्षेत्रातील खरेदीसाठी सदस्‍यत्‍व आणि चेकचे अपग्रेड सध्या अनुपलब्ध आहेत.",
  PayPal_Payment_Preview:"PayPal पेमेंट पूर्वावलोकन",
  Apple_Pay:"ऍपल पे",
  Select_PayPal:"PayPal निवडा",
  PayPal_Applications:"PayPal अनुप्रयोग",
  Purchase_With_Apple_Pay:"Apple Pay सह खरेदी करा",
  Google_Pay:"Google Pay",
  Companies:"कंपन्या",
  Insurance:"विमा",
  New_PayPal:"नवीन PayPal",
  Pay_By:"द्वारे पे",
  Insure:"विमा करा",
  Miles:"मैल",
  Payment_Method:"देयक पद्धत",
  Select_Policies:"धोरणे निवडा",
  Policies:"धोरणे",
  Policy:"धोरण",
  No_PayPal_Account:"PayPal खाते नाही",
  No_Policies:"विमा पॉलिसी नाहीत",
  New_Policy:"नवीन धोरण",
  PayPal_Payment:"PayPal पेमेंट",
  PayPal_Payments:"PayPal देयके",
  No_Payment_Selected:"कोणतेही पेमेंट निवडलेले नाही",
  Sending_Payment_Word:"कृपया प्रतीक्षा करा... हे पेमेंट पाठवले जात आहे.",
  Sending_Payments_Word:"कृपया प्रतीक्षा करा... देयके पाठवली जात आहेत.",
  No_Payment_Selected_PayPal:"कोणतेही <a routerLink='/folder/Payments'>PayPal पेमेंट</a> निवडले नाही.",
  Payment_Sent:"पेमेंट पाठवले",
  PayPal_Payment_Sent:"हे पेमेंट PayPal ने पाठवले आहे.",
  Copay:"कॉपी करा",
  Dead:"मृत",
  Alive:"जिवंत",
  Not_Dead:"मेलेली नाही",
  Unclaimed:"दावा न केलेला",
  Attempted:"प्रयत्न केला",
  Deceased:"मृत",
  Claimed:"दावा केला",
  Unpaid:"न भरलेले",
  Sending_Payment:"पेमेंट पाठवत आहे",
  Sending_Payments:"पेमेंट पाठवत आहे",
  Send_PayPal_Confirmation:"तुम्हाला हा व्यवहार PayPal सह पाठवायचा आहे का?",
  Send_PayPal_Confirmation_Word:"हा व्यवहार पाठवण्यासाठी हिरवे बटण दाबा.",
  Save_Payment_As_Unpaid:"हे पेमेंट न भरलेले म्हणून सेव्ह करायचे?",
  Payment_Pay_Confirmation_PayPal:"हे पेमेंट पेड म्हणून जतन करायचे?",
  No_Policies_Word:"प्रथम <a routerLink='/folder/Postage/New'>विमा पॉलिसी</a> आता जोडा .",
  Timesheet_Multiple_Delete_Confirmation:"तुम्हाला खात्री आहे की तुम्ही एकाधिक टाइमशीट हटवू इच्छिता?",
  Select_Multiple_Timesheets_Prompt:"कोणतीही टाइमशीट निवडलेली नाही. किमान एक टाइमशीट निवडा.",
  Select_Multiple_Policies_Prompt:"कोणतीही धोरणे निवडलेली नाहीत. किमान एक विमा पॉलिसी निवडा.",
  Policies_Multiple_Delete_Confirmation:"तुम्हाला खात्री आहे की तुम्ही एकाधिक पॉलिसी हटवू इच्छिता?",
  Company:"कंपनी",
  Add_Company:"कंपनी जोडा",
  New_Company:"कंपनी जोडा",
  No_Companies:"कंपन्या नाहीत",
  Enable_Company:"कंपनी सक्षम करा",
  Select_Company:"कंपनी निवडा",
  The_Default_Company:"डीफॉल्ट कंपनी लेबल.",
  Manage_Companies:"कंपन्या व्यवस्थापित करा",
  No_Companies_Word:"चेक डीफॉल्ट कंपनी वापरतील .<br /> <a routerLink='/folder/Company/New'>पहिली कंपनी</a> जोडा .",
  Default_Company:"डीफॉल्ट कंपनी",
  Cheques_Unlimited_Word:"चेक अनलिमिटेड तुम्हाला तुम्हाला हवे तितके चेक प्रिंट करण्याची परवानगी देतो.",
  Cheques_Subscription_Word:"चेक सबस्क्रिप्शन तुम्हाला तुम्हाला हवे तितके चेक प्रिंट करण्याची परवानगी देते.",
  You_Own_This_Product:"तुम्ही या उत्पादनाचे मालक आहात.",
  Your_Subscription_is_Active:"तुमची सदस्यता सक्रिय आहे.",
  Active_Products:"सक्रिय उत्पादने",
  Purchase_Confirmation:"खरेदी",
  Purchase_Cheques:"खरेदीचे धनादेश",
  Restore_Purchase:"खरेदी पुनर्संचयित",
  Erase_Purchase:"खरेदी पुसून टाका",
  Successfully_Purchased:"यशस्वीरित्या खरेदी केले",
  Enter_Your_Licence_Key:"हे उत्पादन सक्रिय करण्यासाठी कृपया या पृष्ठावर तुमची परवाना की प्रविष्ट करा.",
  Licence_Key:"परवाना की",
  Enter_Licence_Key:"परवाना की प्रविष्ट करा",
  Purchased:"विकत घेतले",
  Enable_Feature:"वैशिष्ट्य सक्षम करा",
  Cancel_Subscription:"सदस्यता रद्द करा",
  Subscription_Removed:"सदस्यता काढली",
  Select_Active_Subscription:"ते सुधारित करण्यासाठी सक्रिय सदस्यता निवडा.",
  Remove_Subscription_Word:"तुमची खात्री आहे की तुम्ही ही सदस्यता रद्द करू इच्छिता?",
  Delete_Company_Confirmation:"तुमची खात्री आहे की तुम्ही ही संपूर्ण कंपनी हटवू इच्छिता? चेतावणी: तुम्ही सर्व संग्रहित माहिती गमवाल!",
  Delete_Default_Company_Word:"तुम्ही डीफॉल्ट कंपनी हटवू शकत नाही . तुम्ही ॲप्लिकेशन रीसेट करू आणि डीफॉल्ट स्थितीत रिस्टोअर करू इच्छिता? चेतावणी: तुम्ही सर्व संग्रहित माहिती गमवाल!",
  Console_Warning_2:"हा अनुप्रयोग वापरून कोणतेही चलन हाताळू नका जे सध्या तुमचे नाही.",
  Terms_and_Conditions:"नियम आणि अटी",
  and_the:"आणि ते",
  for:"च्या साठी",
  Please_Read_Word:"कृपया वाचा आणि त्यास सहमती द्या",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"काही चलन रूपांतरण दर सापडले नाहीत. कृपया इंटरनेटशी कनेक्ट करा.",
  Free:"फुकट",
  DB_Erase_Prompt_2:"संपूर्ण डेव्हलपर डेटाबेस पूर्णपणे मिटवायचा? चेतावणी: तुम्ही सर्व खरेदी आणि सदस्यता माहिती गमवाल!",
  Successfully_Imported:"यशस्वीरित्या आयात केले",
  Select_Postage:"टपाल निवडा",
  No_Postage:"टपाल तिकिटे नाहीत",
  No_Paid_Postage_Word:"पहिला <a routerLink='/folder/Postage/New'>सशुल्क पोस्टेज</a> स्टॅम्प जोडा .",
  Trial_Complete:'चाचणी पूर्ण',
  Please_Upgrade:'कृपया मुद्रण सुरू ठेवण्यासाठी चेक अपग्रेड करा.',
  Aa:"आ",
  Color:"रंग",
  Font:"फॉन्ट",
  Guide:"मार्गदर्शन",
  Guides:"मार्गदर्शक",
  Image:"प्रतिमा",
  Zoom:"झूम करा",
  Logo:"लोगो",
  Bank:"बँक",
  MICR:"MICR",
  Total:"एकूण",
  Cancel:"रद्द करा",
  Confirm:"पुष्टी",
  Method:"पद्धत",
  Biometric_Security:"बायोमेट्रिक सुरक्षा",
  Please_Login_To_Continue:"कृपया सुरू ठेवण्यासाठी लॉग इन करा.",
  Complete:"पूर्ण",
  Sign_Up:"साइन अप करा",
  Error:"त्रुटी",
  Biometrics:"बायोमेट्रिक्स",
  Percent:"टक्के",
  Zero_Percent:"०%",
  Top_Margin:"शीर्ष समास",
  Bottom_Margin:"तळ समास",
  Left_Margin:"डावा समास",
  Right_Margin:"उजवा समास",
  MICR_Margin:"MICR मार्जिन",
  Table_Margin:"टेबल समास",
  Address_Margin:"पत्ता समास",
  Percentage_:"टक्केवारी",
  Vacation_Title:"सुट्टीचे शीर्षक",
  Use_PIN:"पिन वापरा",
  Loading__:"लोड करत आहे...",
  Design_Title:"डिझाइन शीर्षक",
  Authorize:"अधिकृत करा",
  Key:"की",
  Public_Key:"सार्वजनिक की",
  Private_Key:"खाजगी की",
  Authenticate:"प्रमाणित करा",
  Last_Payroll:"शेवटचे वेतन",
  Last_Calculation:"शेवटची गणना",
  Authorized:"अधिकृत",
  Geo_Authorized:"भौगोलिक-स्थान: अधिकृत",
  Not_Authorized:"अधिकृत नाही",
  Authorization_Complete:"अधिकृतता पूर्ण",
  Geolocation_Authorization:"भौगोलिक-स्थान अधिकृतता",
  Out_of_Bounds:"मर्यादेच्या बाहेर",
  Cant_Delete_Default_Design:"डीफॉल्ट डिझाइन हटवू शकत नाही.",
  Unauthorized_Out_of_Bounds:"अनधिकृत: मर्यादेबाहेर",
  Biometric_Authorization:"बायोमेट्रिक अधिकृतता",
  Locating_Please_Wait:"शोधत आहे, कृपया प्रतीक्षा करा...",
  Test_Biometrics:"बायोमेट्रिक्सची चाचणी घ्या",
  Cheque_Margins:"मार्जिन तपासा",
  Percentage_Full_Error:"टक्केवारी फील्ड 100% टक्क्यांपेक्षा जास्त सेट केले जाऊ शकत नाही.",
  No_Payroll_Text:"एक <a routerLink='/folder/Employee/New'>कर्मचारी</a> किंवा <a routerLink='/folder/Payee/New'>पायी</a> आणि <a routerLink='/folder/Schedule/New'>शेड्यूल</a>.",
  Design_Saved:"डिझाइन जतन केले",
  Default_Design_Selected:"डिफॉल्ट डिझाइन निवडले",
  Partial_Import:"आंशिक आयात",
  Partial_Export:"आंशिक निर्यात",
  Entire_Import:"संपूर्ण आयात",
  Entire_Export:"संपूर्ण निर्यात",
  Existing_Password:"कृपया तुमचा विद्यमान पासवर्ड प्रविष्ट करा:",
  Existing_PIN:"कृपया तुमचा विद्यमान पिन कोड प्रविष्ट करा:",
  Pin_Change_Header:"पिन पुष्टीकरण",
  Pin_Change_SubHeader:"बदलाची पुष्टी करण्यासाठी तुमचा जुना पिन नंबर एंटर करा.",
  Pass_Change_Header:"पासवर्ड पुष्टीकरण",
  Pass_Change_SubHeader:"बदलाची पुष्टी करण्यासाठी तुमचा जुना पासवर्ड एंटर करा.",
  PIN_Enter_Message:"पुष्टी करण्यासाठी तुमचा पिन प्रविष्ट करा.",
  Password_Enter_Message:"पुष्टी करण्यासाठी तुमचा पासवर्ड एंटर करा.",
  Pin_Updated_Success:"पिन यशस्वीरित्या अपडेट झाला!",
  Pin_Updated_Fail:"पिन अपडेट करता आला नाही.",
  Pass_Updated_Success:"पासवर्ड यशस्वीरित्या अपडेट केला.",
  Pass_Updated_Fail:"पासवर्ड अपडेट करता आला नाही.",
  Preview_Payment:"पूर्वावलोकन पेमेंट",
  Preview_Payroll:"पेरोलचे पूर्वावलोकन करा",
  No_Payments_Created:"कोणतीही देयके तयार केल्याचे आढळले नाही.",
  Payment_Preview:"पेमेंट पूर्वावलोकन",
  Enable_Payee:"प्राप्तकर्ता सक्षम करा",
  Rendered:"प्रस्तुत केले",
  No_Email:"ईमेल नाही",
  Setup_Cheques:"सेटअप चेक",
  name:"नाव",
  address:"पत्ता",
  address_2:"पत्ता ओळ 2",
  city:"शहर",
  province:"प्रांत",
  postal_code:"पोस्टल/पिन कोड",
  country:"देश",
  username:"वापरकर्तानाव",
  full_name:"पूर्ण नाव",
  birthday:"वाढदिवस",
  email:"ईमेल",
  phone:"फोन",
  employees:"कर्मचारी",
  addresses:"पत्ते",
  payment_amount_limit:"देयक रक्कम मर्यादा",
  total_limit:"एकूण मर्यादा",
  payees:"पैसे देणारे",
  description:"वर्णन",
  address_line_one:"पत्ता ओळ एक",
  address_line_two:"पत्ता ओळ दोन",
  image:"प्रतिमा",
  account_holder:"खातेधारक",
  cheque_starting_id:"प्रारंभ आयडी तपासा",
  transit_number:"संक्रमण क्रमांक",
  institution_number:"संस्था क्रमांक",
  designation_number:"पदनाम क्रमांक",
  account_number:"खाते क्रमांक",
  currency:"चलन",
  signature:"स्वाक्षरी",
  payment_payroll_limit:"पेमेंट मर्यादा",
  total_limi:"एकूण मर्यादा",
  date:"तारीख",
  printed_memo:"छापील मेमो",
  banks:"बँका",
  signature_image:"स्वाक्षरी प्रतिमा",
  address_name:"पत्त्याचे नाव",
  notes:"नोट्स",
  design:"रचना",
  title:"शीर्षक",
  frequency:"वारंवारता",
  fax:"फॅक्स",
  salaries:"पगार",
  salary_ids:"पगार आयडी",
  start_time:"सुरवातीची वेळ",
  end_time:"समाप्तीचा कालावधी",
  paid:"पैसे दिले",
  overtime_percentage:"दिलेली टक्केवारी",
  overtime_amount:"भरलेली निश्चित रक्कम",
  first_name:"पहिले नाव",
  last_name:"आडनाव",
  moderation:"संयम",
  create:"तयार करा",
  edit:"सुधारणे",
  destroy:"नष्ट करा",
  day_start_time:"दिवसाची_प्रारंभ_वेळ",
  day_end_time:"दिवस_समाप्ती_वेळ",
  fullname:"नाव",
  time:"वेळ",
  auto_send:"स्वयंचलितपणे पाठवा",
  time_method:"वेळ पद्धत",
  schedules:"वेळापत्रक",
  indefinite_payroll_enabled:"अनिश्चित काळासाठी सक्षम करा",
  amount:"रक्कम",
  repeat:"पुन्हा करा",
  always_enabled:"नेहमी सक्षम",
  start_date:"प्रारंभ तारीख",
  end_date:"शेवटची तारीख",
  Cheque_Total:"एकूण तपासा",
  Total_Amount:"एकूण रक्कम:",
  Amounts:"रक्कम:",
  Images:"प्रतिमा",
  Files:"फाईल्स",
  Previewing:"पूर्वावलोकन करत आहे:",
  Insert:"घाला",
  Preview_Import:"पूर्वावलोकन आयात",
  Entry:"प्रवेश",
  Entries:"नोंदी",
  No_Entries:"नोंदी नाहीत",
  Import_Type:"आयात प्रकार",
  Select_Details:"तपशील निवडा",
  Select_Payee:"प्राप्तकर्ता निवडा",
  Enable_Holidays:"सुट्ट्या सक्षम करा",
  Disable_Holidays:"सुट्ट्या अक्षम करा",
  Wire_Transfer:"वायर ट्रान्सफर",
  New_Export:"नवीन निर्यात",
  Export_Data:"डेटा निर्यात करा",
  Export_Data_Word:"निर्यात आणि डाउनलोड करण्यासाठी फाइल प्रकार निवडा.",
  Export_File:"फाईल निर्यात करा",
  Mode:"मोड",
  Times:"वेळा",
  Widgets:"विजेट्स",
  Slider:"स्लाइडर",
  Set_Details:"तपशील सेट करा",
  Select_Type:"प्रकार निवडा",
  Display_Slider:"स्लाइडर प्रदर्शित करा",
  Dashboard_Slider:"डॅशबोर्ड स्लाइडर",
  Dashboard_Mode:"डॅशबोर्ड मोड",
  Show_Intro:"परिचय दाखवा",
  Show_Payrolls:"पगार दाखवा",
  Show_Holidays:"सुट्ट्या दाखवा",
  Show_Invoices:"पावत्या दाखवा",
  Show_Cheques:"चेक दाखवा",
  Enabled_Widgets:"सक्षम विजेट",
  Disabled_Widgets:"अक्षम विजेट्स",
  Colors:"रंग",
  Barcodes:"बारकोड",
  View_Timers:"टाइमर पहा",
  Gradients:"ग्रेडियंट",
  No_Info:"माहिती नाही",
  Disable:"अक्षम करा",
  Show_Layer:"स्तर दाखवा",
  Hide_Layer:"स्तर लपवा",
  Text_Layer:"मजकूर स्तर",
  Secondly:"दुसरे म्हणजे",
  Minutely:"मिनिटाला",
  nine_am:"सकाळी ९:००",
  five_pm:"संध्याकाळी ५:००",
  Enable_Address:"पत्ता सक्षम करा",
  Invalid_File_Type:"क्षमस्व, अवैध फाइल प्रकार निवडला गेला. समर्थित फाइल प्रकार:",
  Error_Updating_Entry:"क्षमस्व, ही प्रविष्टी अद्यतनित करताना त्रुटी आली.",
  Schedule_Timing_Alert:"त्रुटी: शेड्यूल सुरू होण्याची वेळ समाप्तीच्या वेळेनंतर मूल्यावर सेट केली आहे.",
  Select_Multiple_Payments_Prompt:"कोणतीही देयके निवडलेली नाहीत. किमान एक पेमेंट निवडा.",
  Select_Multiple_Cheques_Prompt:"कोणतेही धनादेश निवडलेले नाहीत. कृपया किमान एक चेक निवडा.",
  Select_Multiple_Items_Prompt:"कोणतेही आयटम निवडले नाहीत. कृपया किमान एक आयटम निवडा.",
  Paymemt_Multiple_Delete_Confirmation:"तुम्हाला खात्री आहे की तुम्ही एकाधिक पेमेंट हटवू इच्छिता?",
  Cheque_Multiple_Delete_Confirmation:"तुम्हाला खात्री आहे की तुम्ही एकाधिक चेक हटवू इच्छिता?",
  Payee_Multiple_Delete_Confirmation:"तुम्हाला खात्री आहे की तुम्ही एकाधिक प्राप्तकर्ता हटवू इच्छिता?",
  Employee_Multiple_Delete_Confirmation:"तुम्हाला खात्री आहे की तुम्ही एकाधिक कर्मचारी हटवू इच्छिता?",
  MICR_Warning:"टीप: काही प्रिंटर आणि उपकरणे MICR फॉन्ट योग्यरित्या प्रदर्शित करू शकत नाहीत.",
  Automatically_Send:"स्वयंचलितपणे पाठवा",
  Type:"प्रकार",
  Payment_Type:"पैसे भरण्याची पध्दत",
  Auto_Send:"स्वयं पाठवा",
  Automatically_Process:"स्वयंचलितपणे प्रक्रिया करा",
  Auto_Process:"ऑटो प्रक्रिया",
  Image_Based:"प्रतिमा-आधारित",
  Font_Based:"फॉन्ट-आधारित",
  Rerender:"पुन्हा प्रस्तुत करा",
  Rendering:"प्रस्तुतीकरण",
  Render:"फाईल्स",
  Top:"शीर्षस्थानी",
  Middle:"मधला",
  Bottom:"तळ",
  Top_Left:"वर डावीकडे",
  Top_Center:"शीर्ष केंद्र",
  Top_Right:"वर उजवीकडे",
  Middle_Left:"मध्य डावीकडे",
  Middle_Center:"मध्य केंद्र",
  Middle_Right:"मध्य उजवा",
  Bottom_Left:"खाली डावीकडे",
  Bottom_Center:"तळ केंद्र",
  Bottom_Right:"तळ उजवीकडे",
  Numbers:"संख्या",
  Verified:"सत्यापित",
  Paper_Size:"कागदाचा आकार",
  New_Device:"नवीन डिव्हाइस",
  Add_Device:"डिव्हाइस जोडा",
  Remove_Device:"डिव्हाइस काढा",
  Delete_Device:"डिव्हाइस हटवा",
  Block_Device:"डिव्हाइस ब्लॉक करा",
  Block:"ब्लॉक करा",
  Unblock:"अनब्लॉक करा",
  Table:"टेबल",
  Scan_Login_Code:"लॉगिन कोड स्कॅन करा",
  Login_Code:"लॉगिन कोड",
  Scan_Code:"स्कॅन कोड",
  Scan_QR_Code:"QR कोड स्कॅन करा",
  Select_All:"सर्व निवडा",
  Deselect_All:"सर्वांची निवड रद्द करा",
  Increase:"वाढवा",
  Decrease:"कमी करा",
  Bold:"धीट",
  Text:"मजकूर",
  Style:"शैली",
  Italic:"तिर्यक",
  QR_Code:"QR कोड",
  Barcode:"बारकोड",
  Browse_Images:"प्रतिमा ब्राउझ करा",
  Browse_Files:"फाइल्स ब्राउझ करा",
  Background_Image:"पार्श्वभूमी प्रतिमा",
  Logo_Image:"लोगो प्रतिमा",
  Header_Image:"शीर्षलेख प्रतिमा",
  Bank_Image:"बँक प्रतिमा",
  Cut_Lines:"ओळी कट करा",
  Background:"पार्श्वभूमी",
  License:"परवाना",
  One_License:"1 परवाना:",
  Licensed:"यासाठी परवानाकृत:",
  Not_Licensed:"परवाना नाही",
  Enter_Serial:"मालिका प्रविष्ट करा",
  Serial_Key:"सिरीयल की",
  Serial:"मालिका",
  Product_Key:"उत्पादन की",
  Check_Product_Key:"उत्पादन की तपासा",
  Add_Product_Key:"उत्पादन की जोडा",
  Verifying_Purchase:"खरेदी सत्यापित करत आहे...",
  Print_Envelope:"लिफाफा मुद्रित करा",
  Envelope:"लिफाफा",
  Thank_You:"धन्यवाद!",
  Scale:"स्केल",
  Print_Scale:"प्रिंट स्केल",
  Borders:"सीमा",
  VOID:"शून्य",
  Void_Cheque:"शून्य तपासणी",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"च्या ऑर्डरसाठी पैसे द्या:",
  NO_DOLLARS:"डॉलर्स नाहीत ",
  ONE_DOLLAR:"एक डॉलर",
  DOLLARS:" डॉलर्स",
  AND:" आणि ",
  CENTS:" सेंट.",
  NO_:"नाही ",
  ONE_:"एक ",
  AND_NO_:"आणि नाही ",
  _AND_ONE_:"आणि एक ",
  DOLLARS_AND_ONE_CENT:" आणि एक सेंट.",
  AND_NO_CENTS:" आणि शून्य सेंट.",
  CHEQUE_PRINT_DATE:"तारीख:",
  CHEQUE_PRINT_MEMO:"मेमो:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"खासदार",
  Initial_Tasks:"प्रारंभिक कार्य",
  Inital_Setup:"प्रारंभिक सेटअप",
  Welcome_To:"आपले स्वागत आहे ",
  Welcome:"स्वागत आहे",
  Swipe:"स्वाइप",
  Intro_Setup:"परिचय सेटअप",
  Introduction:"परिचय",
  CHEQUE_PRINT_CREDIT:"चेक्सद्वारे समर्थित",
  Title_Intro_Word:"चेक्समध्ये आपले स्वागत आहे",
  Title_Intro:"चेक्स परिचय",
  Title_Setup:"चेक्स सेटअप",
  PayPal_Default_Email_Message:"तुम्हाला नवीन PayPal हस्तांतरण प्राप्त झाले आहे. [चेक्सद्वारे समर्थित]",
  Cheques_App_Export:"चेक्सद्वारे निर्यात केलेले",
  Post_Intro_Thanks:"चेक निवडल्याबद्दल धन्यवाद. सेटअप प्रक्रिया आता पूर्ण झाली आहे.",
  Post_Intro_Word:"तुमचा पहिला धनादेश छापून सुरुवात करा, भविष्यातील पेमेंट जोडा किंवा पेरोलमध्ये कर्मचारी जोडा.",
  Upgrade_Required:"हा संदेश लपवण्यासाठी आणि अतिरिक्त वैशिष्‍ट्ये अनलॉक करण्‍यासाठी चेकसाठी तुमच्‍या सॉफ्टवेअरच्‍या अधिक प्रिमियम आवृत्‍तीच्‍या मालकीची आवश्‍यकता आहे.",
  Upgrade_Title:"चेक",
  Mailout_Prompt:"तुम्ही तुमच्यासाठी तुमचे पेरोल चेक चेक मेल-आउट करणे देखील निवडू शकता.",
  Mailed_Cheque:"मेल केलेला चेक:",
  Mailed_Cheque_Color:"मेल केलेला चेक (रंग):",
  Terms_Purchase:"चेक्ससह सर्व इलेक्ट्रॉनिक खरेदी खरेदीच्या तारखेपासून ३०-दिवसांपर्यंत पूर्णपणे परत करण्यायोग्य आहेत. कृपया पुढे जाण्यापूर्वी <a href='#'>अटी आणि नियम</a> वाचा आणि सहमती द्या.",
  Dashboard_Setup:"प्राथमिक प्रिंटर सेटअप करा",
  Dashboard_Add:"प्राथमिक बँक खाते जोडा",
  Dashboard_Customize:"चेक टेम्पलेट निवडा",
  Dashboard_Job_Salary:"तुमची नोकरी तयार करा आणि तुमचा पगार जोडा",
  Dashboard_Employees:"कर्मचारी आणि प्राप्तकर्त्यांचा मागोवा घ्या",
  Dashboard_Print:"तुमचे पेचेक प्रिंट करा आणि मेल करा",
  Dashboard_Payroll:"तुमचे पेरोल प्रिंटिंग स्वयंचलित करा",
  Dashboard_PayPal:"पेपल पेरोल / पेआउट सेट करा",
  Begin_Setup:"सेटअप सुरू करा",
  Get_Started:"सुरु करूया",
  Purchase:"खरेदी",
  Lockdown:"लॉकडाउन",
  Unlock:"अनलॉक",
  Detailed:"तपशीलवार",
  Log_In:"लॉग इन",
  Login:"लॉग इन",
  Launch:"लाँच",
  Register:"नोंदणी करा",
  Finish:"समाप्त",
  List:"यादी",
  Weekends:"विकेंड",
  Weekly:"साप्ताहिक",
  PayPal_Default_Subject:"नवीन पेमेंट",
  Payment_Message:"पेमेंट संदेश",
  PayPal_Default_Payment_Note:"धन्यवाद",
  Setup_Your_Cheqing_Account:"चेकिंग खाते",
  Add_Your_Primary_Cheqing_Account:"तुमचे प्राथमिक चेकिंग खाते जोडा.",
  Welcome_Word:"पेरोल आणि मानव संसाधन व्यवस्थापन सुलभ आणि स्वयंचलित करा.",
  Get_Started_Quickly:"काही सोप्या प्रश्नांची उत्तरे द्या जी आम्हाला सुरुवात करण्यात मदत करतील...",
  Done_Intro_Word:"सेटअप पूर्ण",
  PIN_Protected:"पासवर्ड आणि पिन संरक्षित",
  Enter_New_PIN:"नवीन पिन कोड प्रविष्ट करा:",
  Enter_PIN:"पिन कोड प्रविष्ट करा:",
  Invalid_PIN:"अवैध पिन प्रविष्ट केला.",
  Account_Identifier:"खाते ओळखकर्ता",
  New_Account_Identifier:"नवीन खाते ओळखकर्ता",
  attempt:"प्रयत्न",
  attempts:"प्रयत्न",
  remaining:"उर्वरित",
  Language:"इंग्रजी",
  languages:"भाषा",
  select_languages:"भाषा निवडा",
  Deposit:"ठेव",
  Hire_One_Now:"आता एक भाड्याने घ्या",
  App_Locked:"अॅप्लिकेशन लॉक केले आहे.",
  Skip_:"वगळा",
  Skip_to_Dashboard:"डॅशबोर्डवर जा",
  Dashboard:"डॅशबोर्ड",
  Import:"आयात",
  Admin:"प्रशासक",
  New_Admin:"नवीन प्रशासक",
  Settings:"सेटिंग्ज",
  Color_Picker:"रंग पिकर",
  Font_Picker:"फॉन्ट पिकर",
  Logout:"बाहेर पडणे",
  Close:"बंद",
  Close_menu:"बंद",
  Excel:"एक्सेल फाइल",
  Csv:"CSV फाइल",
  Sql:"SQL फाइल",
  Json:"JSON फाइल",
  Url:"URL द्वारे आयात करा",
  Back:"मागे",
  Timers:"टाइमर",
  Cheque:"तपासा",
  Print:"प्रिंट",
  Designs:"डिझाइन",
  Pause_Printing:"पॉज प्रिंटिंग",
  Resume_Printing:"मुद्रण पुन्हा सुरू करा",
  Printing_Paused:"मुद्रण थांबवले",
  PrintingUnavailable:"क्षमस्व! या प्रणालीवर मुद्रण अनुपलब्ध आहे.",
  Prints_Paused:"प्रिंट्स विराम दिला",
  Administration:"प्रशासन",
  Loading:"लोड करत आहे",
  Unnamed:"अनाम",
  error:"माफ करा, हा चेक पाहण्यासाठी उघडता आला नाही.",
  No_Cheques_To_Print:"मुद्रित करण्यासाठी कोणतेही धनादेश नाहीत",
  No_Cheques_To_Print_Word:"<a routerLink='/folder/Cheque/New'>नवीन चेक</a> तयार करा.",
  New_Cheque:"नवीन तपासणी",
  Start_One_Now:"आता प्रारंभ करा",
  Edit_Cheque:"चेक संपादित करा",
  Select_Cheques:"चेक निवडा",
  Select_Employee:"कर्मचारी निवडा",
  Default_Printer:"डिफॉल्ट प्रिंटर",
  Reassign:"पुन्हा नियुक्त करा",
  Configure:"कॉन्फिगर करा",
  Template:"टेम्पलेट",
  Designer:"डिझायनर",
  Edit_Designs:"डिझाइन संपादित करा",
  New_Design:"नवीन डिझाईन",
  Next:"पुढे",
  Save:"जतन करा",
  Name:"नाव",
  Mail:"मेल",
  Amount:"रक्कम",
  Date:"तारीख",
  PayPal:"पेपल",
  Payouts:"पेआउट्स",
  PayPal_Label:"पेपल लेबल",
  Email_Username:"ईमेल / वापरकर्तानाव",
  Client_ID:"क्लायंट आयडी",
  Sandbox_Email:"सँडबॉक्स ईमेल",
  PayPal_Email:"PayPal ईमेल",
  PayPal_Username:"API वापरकर्तानाव",
  PayPal_Payouts:"पेपल पेआउट्स",
  Select_PayPal_Key:"पेपल की निवडा",
  Secret:"गुप्त",
  API_Secret:"एपीआय सीक्रेट",
  PayPal_API_Keys:"पेपल की",
  New_PayPal_Key:"नवीन पेपल की",
  Email_Subject:"ईमेल विषय",
  Email_Message:"ईमेल संदेश",
  Payout_Options:"पेआउट पर्याय",
  Payment_Note:"पेमेंट नोट",
  Enable_Employee:"कर्मचारी सक्षम करा",
  Enable_Production_Mode:"उत्पादन मोड सक्षम करा",
  Sandbox_Username:"सँडबॉक्स वापरकर्तानाव",
  Sandbox_Signature:"सँडबॉक्स स्वाक्षरी",
  Sandbox_Password:"सँडबॉक्स पासवर्ड",
  Production_Username:"उत्पादन वापरकर्तानाव",
  Production_Signature:"उत्पादन स्वाक्षरी",
  Production_Password:"उत्पादन पासवर्ड",
  Production_Email:"उत्पादन ईमेल",
  API_Client_ID:"एपीआय क्लायंट आयडी",
  API_Signature:"API स्वाक्षरी",
  API_Password:"API पासवर्ड",
  API_Username:"API वापरकर्तानाव",
  Secret_Key:"गुप्त की",
  Sandbox:"सँडबॉक्स",
  Production:"उत्पादन",
  Sandbox_Keys:"सँडबॉक्स की",
  Production_Keys:"उत्पादन की",
  API_Title:"API शीर्षक",
  Production_Mode:"उत्पादन मोड",
  Account_Label:"खाते लेबल",
  No_PayPal_Setup:"पेपल की नाही",
  Enable_PayPal_Account:"पेपल खाते सक्षम करा",
  No_PayPal_Word:"तुमची <a routerLink='/folder/Invoice/New'> की</a> जोडा.",
  Printed_Memo:"मुद्रित मेमो",
  Employee:"कर्मचारी",
  View_Employee:"कर्मचारी पहा",
  Mailing_Address:"पत्र व्यवहाराचा पत्ता",
  Company_Address:"कंपनीचा पत्ता",
  Select_Company_Address:"कंपनीचा पत्ता निवडा",
  Address:"पत्ता",
  Any_Day:"कोणत्याही दिवशी",
  Address_Name:"पत्त्याचे नाव",
  Unit:"युनिट",
  Account:"खाते",
  Bank_Account:"बँक खाते",
  Account_Limits:"खाते मर्यादा सक्षम करा",
  Payroll:"पगार",
  New_Payroll:"नवीन पगार",
  No_Payroll:"आगामी वेतन नाही",
  Upcoming_Holiday:"आगामी सुट्टी:",
  Invoice_Due:"इनव्हॉइस देय:",
  New_Invoice:"नवीन बीजक",
  No_Invoices:"कोणत्याही पावत्या नाहीत",
  No_Invoices_Word:"पहिले <a routerLink='/folder/Invoice/New'>चालन</a> तयार करा.",
  Cheque_Due:"चेक देय:",
  Payrolls:"पगार",
  Sandbox_Mode:"सँडबॉक्स मोड",
  Hire:"भाडे",
  Pay:"पे",
  New:"नवीन",
  Add:"जोडा",
  Make:"बनवा",
  Time:"वेळ",
  Write:"लिहा",
  Holiday:"सुट्टी",
  Holidays:"सुट्ट्या",
  Next_Holiday:"पुढील सुट्टी:",
  All_Done:"पूर्ण झाले!",
  Employees:"कर्मचारी",
  Payees:"पैसे घेणारे",
  Job:"नोकरी",
  Jobs:"नोकरी",
  Invoice:"चालन",
  Invoices:"पावत्या",
  Vacations:"सुट्ट्या",
  Cheques:"चेक्स",
  Brand_Cheques:"ब्रँड",
  Payment:"पेमेंट",
  Enable_Payment:"पेमेंट सक्षम करा",
  Payments:"देयके",
  Schedule:"शेड्यूल",
  Schedules:"शेड्युल्स",
  Timesheet:"वेळ पत्रक",
  Timesheets:"वेळ पत्रके",
  Salary:"पगार",
  New_Address:"नवीन पत्ता",
  Address_2:"पत्ता ओळ 2)",
  _City:"शहर",
  _State:"राज्य/प्रोव्ह",
  City:"शहर / टाउनशिप",
  State:"राज्य/प्रांत",
  Postal:"पोस्टल / पिन कोड",
  ZIP:"पोस्टल / झिप",
  Country:"देश",
  Addresses:"पत्ते",
  Required_Options:"आवश्यक पर्याय",
  Optional_Options:"पर्यायी पर्याय",
  Additional_Options:"अतिरिक्त पर्याय",
  Required:"आवश्यक",
  Optional:"पर्यायी",
  Additional:"अतिरिक्त",
  No_Addresses:"पत्ते नाहीत",
  New_Address_Word:"पहिला <a routerLink='/folder/Address/New'>पत्ता</a> जोडा.",
  Select_Address:"पत्ता निवडा",
  No_Address:"पत्ते नाहीत",
  Print_Cheque:"प्रिंट चेक",
  Print_Cheque_Now:"आता छापा तपासा",
  Description:"वर्णन",
  Pay_To_The_Order_Of:"ऑर्डरला पैसे द्या:",
  Select_Date_Range:"तारीख श्रेणी निवडा",
  Select_Starting_Date:"प्रारंभ तारीख निवडा",
  Select_Ending_Date:"समाप्ती तारीख निवडा",
  Select_Date:"तारीख निवडा",
  Cheque_Date:"तारीख तपासा",
  Cheque_Memo:"मेमो तपासा",
  Blank_Cheque:"कोरा चेक",
  Blank:"रिक्त",
  No_Cheques:"कोणतेही धनादेश नाहीत",
  No_Cheques_Word:"तुमचे पहिले <a routerLink='/folder/Cheque/New'>चेक</a> प्रिंट करा.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"प्रतिमा पहा",
  View:"पहा",
  Modify:"सुधारित करा",
  Delete:"हटवा",
  Cheque_Paid:"पेड",
  New_Deduction:"नवीन कपात",
  Title:"शीर्षक",
  Frequency:"वारंवारता",
  Hourly:"तासाने",
  Daily:"दैनिक",
  Weekdays:"आठवड्याचे दिवस",
  BiWeekly:"2 आठवडे",
  TriWeekly:"3 आठवडे",
  Monthly:"मासिक",
  MiMonthly:"2 महिने",
  Quarterly:"त्रैमासिक",
  Yearly:"वार्षिक",
  Every_Week:"दर आठवड्याला",
  Every_Payroll:"प्रत्येक पगार",
  Every_Month:"दर महिन्याला",
  Annually:"वार्षिक",
  Always_Scheduled:"नेहमी अनुसूचित",
  Start_Date:"प्रारंभ तारीख",
  End_Date:"शेवटची तारीख",
  Start_Time:"सुरवातीची वेळ",
  End_Time:"समाप्तीचा कालावधी",
  Deduction_Label:"वजावट लेबल",
  Notes:"नोट्स",
  Options:"पर्याय",
  Enable:"सक्षम करा",
  Select_Deductions:"वजावट निवडा",
  Deductions:"वजावट",
  No_Deductions:"कोणतीही वजावट नाही",
  No_Deductions_Word:"तुमचे पहिले <a routerLink='/folder/Deduction/New'>वजावट</a> तयार करा.",
  New_Employee:"नवीन कर्मचारी",
  No_Title:"शीर्षक नाही",
  _Name:"नाव",
  About_Yourself:"स्वत: बद्दल",
  Full_Name:"पूर्ण नाव",
  Birthday:"वाढदिवस",
  Email:"ईमेल",
  SMS:"एसएमएस",
  Phone:"फोन",
  Test:"चाचणी",
  Call:"कॉल",
  Fax:"फॅक्स",
  Printed_Note:"मुद्रित नोट",
  Position:"स्थिती",
  Job_Position:"नोकरीची स्थिती",
  Select_a_Job:"नोकरी निवडा",
  Select_a_Salary:"पगार निवडा",
  Add_a_Deduction:"एक कपात जोडा",
  Taxes:"कर",
  Add_Taxes:"कर जोडा",
  Date_of_Birth:"जन्मतारीख",
  Email_Address:"ईमेल पत्ता",
  Phone_Number:"फोन नंबर",
  Phone_Call:"फोन कॉल",
  Enable_on_Payroll:"पगारावर सक्षम करा",
  Select_Employees:"कर्मचारी निवडा",
  No_Employees:"कर्मचारी नाही",
  No_Employees_Word:"तुमचे पहिले नवीन <a routerLink='/folder/Employee/New'>कर्मचारी</a> जोडा.",
  No_Name:"नाव नाही",
  Unemployeed:"बेरोजगार",
  Employeed:"रोजगार",
  Paid:"पेड",
  Enabled:"सक्षम",
  Disabled:"अक्षम",
  Fire:"आग",
  Not_Available:"उपलब्ध नाही",
  Not_Available_Word:"तुमचे पहिले <a routerLink='/folder/Invoice/New'>चलन</a> प्रिंट करा आणि पैसे मिळवा.",
  Select_Invoices:"सिलेक्ट_इनव्हॉइस",
  Print_Invoice_Word:"तुमचे पहिले <a routerLink='/folder/Invoice/New'>चलन</a> प्रिंट करा आणि पैसे मिळवा.",
  Invoice_Title:"चालन शीर्षक",
  Invoice_Date:"चालन तारीख",
  Due_Date:"देय तारीख",
  New_Job:"नवीन नोकरी",
  Details:"तपशील",
  Customize:"सानुकूलित करा",
  Customize_Dashboard:"डॅशबोर्ड सानुकूलित करा",
  Components:"घटक",
  No_Components:"कोणतेही घटक नाहीत",
  Main_Components:"मुख्य घटक",
  Smaller_Components:"लहान घटक",
  Error_Loading_Page:"हे पृष्ठ लोड करताना त्रुटी.",
  Bank_Details:"बँक तपशील",
  About_Your_Job:"तुमच्या नोकरीबद्दल",
  Your_Schedule:"तुमचे वेळापत्रक",
  Job_Title:"नोकरीचे शीर्षक",
  Job_Description:"कामाचे स्वरूप",
  Job_Details:"नोकरीचे तपशील",
  Enable_Job:"नोकरी सक्षम करा",
  Pay_Period:"पगार कालावधी",
  Perpetually_Schedule:"कायमचे वेळापत्रक",
  Select_Jobs:"नोकरी निवडा",
  No_Jobs:"नोकरी नाही",
  Add_Jobs:"नोकरी जोडा",
  No_Jobs_Word:"सूचीमध्ये पहिले <a routerLink='/folder/Job/New'>नोकरी</a> जोडा.",
  Count_Employees:"job.employee_count+' कर्मचारी'",
  Zero_Employees:"0 कर्मचारी",
  New_Leave:"नवीन रजा",
  Leave_Name:"नाव सोडा",
  Paid_Leave:"पगारी रजा",
  Leave_Pay:"पगार सोडा",
  Indefinite_Leave:"अनिश्चित रजा",
  Indefinite_Payroll:"अनिश्चित वेतन",
  Leave:"सोडा",
  Add_Schedules:"शेड्युल जोडा",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"अनुपस्थिती सक्षम करा",
  Select_Leaves:"पाने निवडा",
  No_Leaves:"अनुपस्थितीची पाने नाहीत",
  Leave_Of_Absence:"अनुपस्थितीत सोडून",
  Leaves_Of_Absence:"अनुपस्थितीची पाने",
  New_Leave_of_Absense:"नवीन अनुपस्थितीची सुट्टी",
  No_Leaves_Word:"प्रथम <a routerLink='/folder/Leave/New'>अनुपस्थिती सोडा</a> जोडा.",
  Not_Enabled:"सक्षम नाही",
  Absences:"अनुपस्थिती",
  Payee:"पैसेदार",
  New_Payee:"नवीन प्राप्तकर्ता",
  Payee_Identifier:"पेय आयडेंटिफायर",
  Payee_Name:"प्राप्तीचे नाव",
  Payee_Title:"पायी शीर्षक",
  Payment_Memo:"पेमेंट मेमो",
  Select_Payees:"पैसे निवडा",
  No_Payees:"पैसे नाहीत",
  Add_Payee_Note:"प्रथम <a routerLink='/folder/Payee/New'>पैसे देणारा</a> जोडा.",
  New_Payees:"नवीन प्राप्तकर्ता",
  About_The_Payment_Schedule:"पेमेंट वेळापत्रक",
  Your_Payroll_Schedule:"स्वयंचलित वेतन",
  New_Payment:"नवीन पेमेंट",
  Identifier:"आयडेंटिफायर",
  Select:"निवडा",
  Memo:"मेमो",
  Payment_Date:"पगाराची तारीख",
  Mark_as_Paid:"पेड म्हणून चिन्हांकित करा",
  Select_Payments:"पेमेंट्स निवडा",
  No_Payments:"पेमेंट नाही",
  No_Payments_Word:"पहिले <a routerLink='/folder/Payment/New'>पेमेंट</a> तयार करा.",
  Create_Payroll:"पेरोल तयार करा",
  Properties:"गुणधर्म",
  Payroll_Title:"पेरोल शीर्षक",
  Payroll_Notes:"पेरोल नोट्स",
  Payroll_Setup:"पेरोल सेटअप",
  Tabulate_Payments:"देयके सारणी",
  Tabulate:"सारणी",
  By:"द्वारा:",
  Payments_By:"द्वारे देयके",
  Timesheets_And_Schedules:"वेळ पत्रके आणि वेळापत्रक",
  Both:"दोन्ही",
  Items:"वस्तू",
  Add_Payees:"पैसे जोडा",
  Add_Account:"खाते जोडा",
  Enable_Account:"खाते सक्षम करा",
  Enable_Payroll:"पेरोल सक्षम करा",
  Print_Payroll:"प्रिंट पेरोल",
  No_Payrolls:"पगार नाही",
  No_Payroll_Word:"तुमचे पहिले <a routerLink='/folder/Payroll/New'>पेरोल</a> तयार करा.",
  View_more:"अधिक प I हा",
  Less:"कमी",
  Add_Payroll:"पगार जोडा",
  Select_Payroll:"पेरोल निवडा",
  About_Your_Salary:"तुमच्या पगाराबद्दल",
  Add_Salaries:"पगार जोडा",
  Add_Salary:"पगार जोडा",
  Salaries:"पगार",
  No_Salaries:"पगार नाही",
  No_Salaries_Word:"पहिले <a routerLink='/folder/Salary/New'>पगार</a> जोडा.",
  Select_Salaries:"पगार निवडा",
  New_Salary:"नवीन पगार",
  Salary_Name:"पगार ओळखकर्ता",
  Enable_Salary:"पगार सक्षम करा",
  Salary_Amount:"पगाराची रक्कम",
  New_Schedule:"नवीन वेळापत्रक",
  Schedule_Title:"शेड्यूल शीर्षक",
  Add_Address:"पत्ता जोडा",
  Repeat:"पुनरावृत्ती",
  Design:"डिझाइन",
  Edit_Design:"डिझाइन संपादित करा",
  Edit_this_Design:"हे डिझाइन संपादित करा",
  Repeat_Payment:"रिपीट पेमेंट",
  Enable_Schedule:"शेड्यूल सक्षम करा",
  Never:"कधीच नाही",
  Select_Schedule:"शेड्युल निवडा",
  No_Schedules:"कोणतेही वेळापत्रक नाही",
  No_Schedules_Word:"प्रथम <a routerLink='/folder/Schedule/New'>शेड्यूल</a> तयार करा.",
  New_Administrator:"नवीन प्रशासक",
  Username:"वापरकर्तानाव",
  First_Name:"पहिले नाव",
  Last_Name:"आडनाव",
  Add_an_Address:"पत्ता जोडा",
  Payment_Limit:"प्रति-पेमेंट मर्यादा",
  Total_Limit:"एकूण मर्यादा",
  Select_Accounts:"खाती निवडा",
  No_Administrators:"प्रशासक नाहीत",
  No_Administrators_Word:"प्रथम <a routerLink='/folder/Administrator/New'>प्रशासन खाते</a> तयार करा.",
  New_Administrators_Word:"प्रथम <a routerLink='/folder/Administrator/New'>प्रशासक</a> जोडा",
  Cloud:"ढग",
  Backup:"बॅकअप",
  Enable_iCloud:"iCloud सक्षम करा",
  Enable_Google_Drive:"Google ड्राइव्ह सक्षम करा",
  Enable_Microsoft_OneDrive:"Microsoft OneDrive सक्षम करा",
  Automatically_Backup:"स्वयंचलितपणे बॅकअप",
  FTP_Settings:"FTP सेटिंग्ज",
  URL_File_Prompt:"इम्पोर्ट करण्यासाठी कृपया .xls / .xlsx / .json फाइलसाठी स्थान निर्दिष्ट करा:",
  URL_SQL_Prompt:"कृपया आयात करण्यासाठी SQLite फाइलचे स्थान निर्दिष्ट करा:",
  FTP_Backup:"FTP बॅकअप",
  FTP_Import:"FTP आयात",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"होस्ट",
  Port:"बंदर",
  Path:"पथ",
  Data_Path:"डेटा पथ",
  Enable_FTP_Account:"FTP खाते सक्षम करा",
  HostnameIP:"होस्टनाव",
  Password:"पासवर्ड",
  Connection_Port:"कनेक्शन पोर्ट",
  Enable_MySQL_Database:"MySQL डेटाबेस सक्षम करा",
  Log:"लॉग",
  Reset:"रीसेट",
  Erase:"मिटवा",
  Export:"निर्यात",
  Database:"डेटाबेस",
  Upload_CSV:"CSV अपलोड करा",
  Download_CSV:"CSV डाउनलोड करा",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL बॅकअप",
  Internal_Notes:"अंतर्गत नोट्स",
  Root_Path:"रूट पथ",
  Select_Backup:"बॅकअप निवडा",
  Add_New_Backup:"नवीन बॅकअप जोडा",
  First_Backup:"पहिला बॅकअप सेट करा...",
  Backups:"बॅकअप",
  Add_Backup:"बॅकअप जोडा",
  No_Backups:"तेथे कोणतेही बॅकअप सापडत नाहीत.",
  Select_Backup_Type:"तुम्ही सेटअप करू इच्छित असलेल्या बॅकअपचा प्रकार निवडा...",
  Backup_Type:"बॅकअप प्रकार",
  FTP_Drive:"FTP ड्राइव्ह",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"ड्राइव्ह",
  Microsoft_OneDrive:"ड्राइव्ह",
  Import_Fields:"आयात फील्ड",
  Import_Fields_Word:"<a routerLink='/folder/Holiday/New'>आयात</a> डेटा निवडण्यासाठी हा विभाग वापरा.",
  Upload:"अपलोड",
  Download:"डाउनलोड करा",
  Download_JSON:"JSON डेटा म्हणून डाउनलोड करा",
  Download_SQL:"SQL फाइल म्हणून डाउनलोड करा",
  New_Bank:"नवीन बँक",
  New_Account:"नवीन खाते",
  New_Bank_Account:"नवीन बँक खाते",
  Upload_Image:"प्रतिमा अपलोड करा",
  Bank_Name:"बँकेचे नाव",
  Bank_Address:"बँकेचा पत्ता",
  Branch_Address:"शाखेचा पत्ता",
  Address_on_Cheque:"पत्ता",
  Cheque_Numbers:"नंबर तपासा",
  Cheque_Details:"तपशील तपासा",
  Bank_Logo:"बँकेचा लोगो",
  Cheque_ID:"आयडी तपासा",
  Starting_Cheque_ID:"चेक आयडी सुरू करत आहे",
  Transit_Number:"ट्रान्झिट नंबर",
  Institution_Number:"संस्थेचा क्रमांक",
  Designation_Number:"पदनाम क्रमांक",
  Account_Number:"खाते क्रमांक",
  Limits:"मर्यादा",
  Default_Limits:"डिफॉल्ट मर्यादा",
  Over_Limit:"मर्यादेपेक्षा जास्त",
  Under_Limit:"मर्यादेखाली",
  Payroll_Limit:"पगार मर्यादा",
  Enable_Bank_Account:"बँक खाते सक्षम करा",
  Select_Account:"खाते निवडा",
  No_Bank_Account:"कोणतेही बँक खाते नाही",
  No_Bank_Account_Word:"पहिले <a routerLink='/folder/Accounts/New'>बँक खाते</a> जोडा.",
  Bank_Accounts:"बँक खाती",
  No_Accounts:"खाते नाहीत",
  No_Accounts_Note:"पहिले <a routerLink='/folder/Accounts/New'>बँक खाते</a> जोडा.",
  Cheque_Designer:"डिझायनर तपासा",
  Cheque_Design:"डिझाईन तपासा",
  Select_Design:"डिझाइन निवडा",
  Cheque_Designs:"डिझाइन तपासा",
  No_Cheque_Designs:"नो चेक डिझाईन्स",
  No_Cheque_Designs_Word:"तुमचे स्वतःचे <a routerLink='/folder/Settings/Cheque/Design/New'>चेक डिझाइन</a> तयार करा.",
  Set_Default:"डीफॉल्ट म्हणून सेट",
  Default:"डिफॉल्ट",
  Remove:"काढून टाका",
  Folder:"फोल्डर",
  Edit:"सुधारणे",
  LoadingDots:"लोड करत आहे...",
  Add_a_New_File:"यामध्ये <a href='#' (click)='add()'>नवीन फाइल</a> जोडा",
  this_folder:"हे फोल्डर",
  FTP_Backup_Settings:"FTP बॅकअप सेटिंग्ज",
  Secure_File_Transfer:"सुरक्षित फाइल हस्तांतरण",
  New_Holiday:"नवीन सुट्टी",
  Add_Holiday:"सुट्टी जोडा",
  Holiday_Name:"सुट्टीचे नाव",
  Additional_Pay:"अतिरिक्त वेतन",
  Enable_Holiday:"सुट्टी सक्षम करा",
  Select_Holidays:"सुट्ट्या निवडा",
  No_Holidays:"सुट्ट्या नाहीत",
  No_Holidays_Word:"प्रथम <a routerLink='/folder/Holiday/New'>सार्वजनिक सुट्टी</a> जोडा.",
  New_Import:"नवीन आयात",
  Import_Data:"डेटा आयात करा",
  Import_Data_Word:"तुम्ही निवडलेल्या फाइलचा प्रकार किंवा अपलोड करण्याची पद्धत निवडा.<br /> तुम्ही समर्थित फाइल अपलोड केल्यानंतर अॅपमधील कोणत्याही पॅरामीटरशी संबंधित फाइलमधील आयात केलेली फील्ड निवडण्यास सक्षम असाल .", 
  Import_File:"आयात फाइल",
  Link_To_File:"फाइलचा दुवा",
  Select_File:"फाइल निवडा",
  New_Moderator:"नवीन नियंत्रक",
  Allow_Moderation:"मॉडरेशनला परवानगी द्या",
  Create_Paycheques:"पेचेक तयार करा",
  Edit_Paycheques_and_Data:"पेचेक आणि डेटा संपादित करा",
  Destroy_Data_and_Paycheques:"डेटा आणि पेचेक नष्ट करा",
  Bonus_Percentage:"पेचेक टक्केवारी",
  Fixed_Amount:"निश्चित रक्कम",
  Select_Moderator:"नियंत्रक निवडा",
  No_Moderators:"नियंत्रक नाहीत",
  Moderators:"नियंत्रक",
  Moderator_Account:"प्रथम <a routerLink='/folder/Administrator/New'>मॉडरेटर खाते</a> तयार करा.",
  No_Moderators_Word:"प्रथम <a routerLink='/folder/Administrator/New'>मॉडरेटर</a> जोडा.",
  Defaults:"डिफॉल्ट",
  Provide_Us_Info:"आम्हाला माहिती द्या",
  Setup_Your_Printer:"तुमचा प्रिंटर सेट करा",
  Your_Company:"तुमच्या कंपनीबद्दल",
  Company_Name:"कंपनीचे नाव",
  Currency:"चलन",
  Default_Currency:"डिफॉल्ट चलन",
  Base_Monthly_Income:"मासिक उत्पन्न",
  Protection:"संरक्षण",
  App_Protection:"अ‍ॅप संरक्षण",
  PIN_Code_Protection:"पिन कोड संरक्षण",
  App_Protection_Word:"तुमच्या खात्याचे संरक्षण करण्यात मदत करणाऱ्या सुरक्षा पद्धती सक्षम करा.",
  PIN_Code:"पिन कोड",
  Change_PIN:"पिन बदला",
  New_Password:"नवीन पासवर्ड",
  Geofence_Protection:"भू-कुंपण संरक्षण",
  Geofence_Area:"क्षेत्र निश्चित करा",
  Distance:"अंतर",
  Setup_Now:"आता सेट करा",
  Mile:"मैल",
  Km:"किमी",
  m:"मी",
  Facial_Recognition:"चेहऱ्याची ओळख",
  Face:"चेहरा",
  Setup:"सेटअप",
  Label:"लेबल",
  Password_Protection:"संकेतशब्द संरक्षण",
  Modify_Password:"संकेतशब्द सुधारित करा",
  New_Tax_Entry:"नवीन कर प्रवेश",
  New_Tax:"नवीन कर",
  Tax_Label:"कर लेबल",
  Perpetually_Enabled:"कायमस्वरूपी सक्षम",
  Select_Taxes:"कर निवडा",
  Tax_Deductions:"कर कपात",
  No_Tax_Deductions:"कोणतीही कर कपात नाही",
  No_Tax_Deductions_Word:"पहिली <a routerLink='/folder/Tax/New'>कर</a> कपात जोडा.",
  New_Timer:"नवीन टाइमर",
  Start:"प्रारंभ",
  Stop:"थांबा",
  Start_Timer:"स्टार्ट टाइमर",
  Stop_Timer:"टाईमर थांबवा",
  Timer_Active:"टाइमर सक्रिय",
  Timer:"टाइमर:",
  Timer_Running:"टाइमर: (धावतो)",
  Save_Timer:"टायमर जतन करा",
  New_Timesheet:"नवीन वेळ पत्रक",
  Select_Timesheets:"वेळ पत्रके निवडा",
  Work_Notes:"वर्क नोट्स",
  Entry_Title:"प्रवेश शीर्षक",
  No_Timesheets:"टाइम शीट्स नाही",
  No_Timesheets_Word:"पहिले <a routerLink='/folder/Timesheet/New'>वेळ पत्रक</a> जोडा.",
  Timesheet_Submitted:"वेळ पत्रक सबमिट केले",
  Timesheet_Congrats:"अभिनंदन! तुमचे टाइम शीट यशस्वीरित्या सबमिट केले गेले आहे. धन्यवाद!",
  Timesheet_Copy:"तुमच्या दस्तऐवजांची एक प्रत प्राप्त करण्यासाठी कृपया आम्हाला तुमचा ईमेल पत्ता आणि/किंवा मोबाईल फोन नंबर प्रदान करा.",
  Submit:"प्रस्तुत करणे",
  Allow_Notifications:"सूचनांना परवानगी द्या",
  Untitled_Entry:"नवीन प्रवेश",
  Untitled_Bank:"शीर्षक नसलेली बँक",
  Timesheet_Entry:"टाइम शीट एंट्री",
  Work_Description:"कामाचे वर्णन",
  Enable_Timesheet:"वेळ पत्रक सक्षम करा",
  Submit_Timesheet:"वेळ पत्रक सबमिट करा",
  Vacation:"सुट्टी",
  New_Vacation:"नवीन सुट्टी",
  Vacation_Name:"सुट्टीचे नाव",
  Paid_Vacation:"पेड सुट्टी",
  Vacation_Pay:"सुट्टीचे वेतन",
  Enable_Vacation:"सुट्टी सक्षम करा",
  Select_Vacations:"सुट्ट्या निवडा",
  No_Vacations:"सुट्ट्या नाहीत",
  No_Vacations_Word:"पहिली <a routerLink='/folder/Vacation/New'>सुट्टी</a> एंट्री तयार करा.",
  Payroll_Schedule:"पेरोल वेळापत्रक",
  Next_Payroll:"पुढील वेतन:",
  Upcoming_Payroll:"आगामी वेतनपट",
  No_Upcoming_Payroll:"आगामी वेतन नाही",
  Address_Book:"अॅड्रेस बुक",
  Archived_Documents:"संग्रहित दस्तऐवज",
  Dev_Mode:"विकास मोडमध्ये अनुप्रयोग",
  Administrators:"प्रशासक",
  Privacy:"गोपनीयता",
  None:"काहीही नाही",
  Select_Signature:"स्वाक्षरी निवडा",
  No_Signatures:"कोणत्याही स्वाक्षऱ्या नाहीत",
  No_Signatures_Word:"प्रथम <a routerLink='/folder/Signature/New'>स्वाक्षरी</a> जोडा.",
  Repeat_Indefinitely:"अनिश्चितपणे पुनरावृत्ती करा",
  Sign:"चिन्ह",
  Sign_Here:"इथे सही करा",
  Date_Signed:"स्वाक्षरीची तारीख",
  Signature_Pad:"सिग्नेचर पॅड",
  Account_Holder:"खातेधारक",
  Account_Properties:"खाते गुणधर्म",
  Name_On_Cheque:"चेकवर नाव",
  Server_Hostname:"सर्व्हर होस्टनाव / IP",
  Printers:"मुद्रक",
  No_Printers:"प्रिंटर नाही",
  Printer_Exists:'या नावाचा प्रिंटर आधीपासून अस्तित्वात आहे.',
  No_Printers_Word:"प्रथम <a routerLink='/folder/Printer/New'>प्रिंटर</a> जोडा.",
  No_Printer_Alert:"कोणताही प्रिंटर परिभाषित नाही. तुम्हाला प्रिंटर सेटअप करायचा आहे का?",
  Add_Printer:"प्रिंटर जोडा",
  New_Printer:"नवीन प्रिंटर",
  Printer_Hostname:"प्रिंटर होस्टनाव / IP",
  Printer_Label:"प्रिंटर लेबल",
  Printer_Protocol:"प्रिंटर प्रोटोकॉल",
  Protocol:"प्रोटोकॉल",
  Email_Print:"ईमेल",
  AirPrint:"एअरप्रिंट",
  IPP:"आयपीपी",
  LPD:"LPD",
  HPJetDirect:"सॉकेट",
  Print_Job:"प्रिंट जॉब",
  Printed:"मुद्रित",
  Not_Printed:"मुद्रित नाही",
  Print_Jobs:"प्रिंट जॉब्स",
  Print_Queue:"प्रिंट रांग",
  No_Print_Jobs:"नो प्रिंट जॉब्स",
  No_Prints:"मुद्रित करण्यासाठी एक नवीन <a routerLink='/folder/Cheque/New'>चेक</a> तयार करा.",
  Prints:"प्रिंट्स",
  Find_Printer:"प्रिंटर शोधा",
  Find_AirPrint_Devices:"एअरप्रिंट उपकरण शोधा",
  Select_Printer:"प्रिंटर निवडा",
  System_UI:"सिस्टम UI",
  Printer:"प्रिंटर",
  Status:"स्थिती",
  Preview:"पूर्वावलोकन",
  Enable_Print_Job:"प्रिंट जॉब सक्षम करा",
  Queue_Weight:"रांगेचे वजन",
  Unlimited:"अमर्यादित",
  Show_Advanced_Printer_Options:"प्रगत प्रिंटर पर्याय दर्शवा",
  Advanced:"प्रगत",
  Location:"स्थान",
  Note:"नोट",
  Queue_Name:"रांगेचे नाव",
  Pages_Printed_Limit:"पानांची मुद्रित मर्यादा",
  MultiPage_Idle_Time:"मल्टीपेज प्रतीक्षा वेळ(चे)",
  Page_Count_Limit:"पृष्ठ मोजणी मर्यादा",
  Page_Orientation:"पृष्ठ अभिमुखता",
  Portrait:"पोर्ट्रेट",
  Landscape:"लँडस्केप",
  Duplex:"डुप्लेक्स",
  Double_Sided_Printing:"दुहेरी बाजू",
  Duplex_Mode:"डुप्लेक्स मोड",
  Duplex_Short:"लहान",
  Duplex_Long:"लांब",
  Duplex_None:"काहीही नाही",
  Color_And_Quality:"रंग आणि गुणवत्ता",
  Monochrome:"मोनोक्रोम",
  Photo_Quality_Prints:"फोटो क्वालिटी प्रिंट्स",
  Printer_Email:"प्रिंटर ईमेल",
  Automatically_Downsize:"स्वयंचलितपणे आकार कमी करा",
  Paper:"कागद",
  Paper_Name:"कागदी नाव",
  Paper_Width:"कागदाची रुंदी",
  Paper_Height:"कागदाची उंची",
  Paper_Autocut_Length:"पेपर ऑटो-कट लांबी",
  Margins:"मार्जिन",
  Page_Margins:"पृष्ठ समास",
  Page_Margin_Top:"शीर्ष पृष्ठ समास",
  Page_Margin_Right:"उजवे पान समास",
  Page_Margin_Bottom:"तळाशी पृष्ठ समास",
  Page_Margin_Left:"डावा पृष्ठ समास",
  Add_Employees:"कर्मचारी जोडा",
  Header:"हेडर",
  Print_A_Page_Header:"पेज हेडर प्रिंट करा",
  Header_Label:"हेडर लेबल",
  Header_Page_Index:"हेडर पृष्ठ अनुक्रमणिका",
  Header_Font_Name:"हेडर फॉन्ट",
  Select_Font:"फॉन्ट निवडा",
  Font_Selector:"फॉन्ट सिलेक्टर",
  Header_Font_Size:"हेडर फॉन्ट",
  Header_Bold:"हेडर ठळक",
  Header_Italic:"हेडर इटालिक",
  Header_Alignment:"हेडर संरेखन",
  Left:"डावीकडे",
  Center:"केंद्र",
  Right:"बरोबर",
  Justified:"न्यायिक",
  Header_Font_Color:"हेडर कलर",
  Select_Color:"रंग निवडा",
  Footer:"तळटीप",
  Print_A_Page_Footer:"पृष्ठ तळटीप मुद्रित करा",
  Footer_Label:"फूटर लेबल",
  Footer_Page_Index:"फूटर पेज इंडेक्स",
  Footer_Font_Name:"फूटर फॉन्ट",
  Fonts:"फॉन्ट",
  Done:"झाले",
  Select_Fonts:"फॉन्ट निवडा",
  Footer_Font_Size:"तळाचा आकार",
  Footer_Bold:"फुटर ठळक",
  Footer_Italic:"फूटर इटालिक",
  Footer_Alignment:"फूटर संरेखन",
  Footer_Font_Color:"तळाचा रंग",
  Page_Copies:"पृष्ठ प्रती",
  Enable_Printer:"प्रिंटर सक्षम करा",
  Remote_Logging:"रिमोट लॉगिंग",
  Log_Server:"लॉग सर्व्हर",
  Encryption:"एनक्रिप्शन",
  Random_Key:"यादृच्छिक की",
  Encryption_Key:"एनक्रिप्शन की",
  Cheques_Webserver:"सानुकूल डेटाबेस",
  Learn_How:"कसे शिका",
  Signature:"स्वाक्षरी",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"स्वाक्षरी पहा",
  Printed_Signature:"मुद्रित स्वाक्षरी",
  Digitally_Sign:"डिजिटल स्वाक्षरी",
  Digital_Signature:"डिजिटल स्वाक्षरी",
  Add_Signature:"स्वाक्षरी जोडा",
  Add_Your_Signature:"तुमची स्वाक्षरी जोडा",
  Enable_Signature:"स्वाक्षरी सक्षम करा",
  Digitally_Signed:"डिजिटल स्वाक्षरी केलेले",
  Insert_Error:"डेटाबेस समस्यांमुळे चेक जतन करण्यात अक्षम.",
  Delete_Confirmation:"तुम्हाला खात्री आहे की तुम्ही ही माहिती हटवू इच्छिता?",
  Discard_Confirmation:"तुम्हाला खात्री आहे की तुम्ही ही माहिती टाकून देऊ इच्छिता?",
  Discard_Bank_Confirmation:"तुम्हाला खात्री आहे की तुम्ही हे खाते टाकून देऊ इच्छिता?",
  Discard_Printer_Confirmation:"तुम्हाला खात्री आहे की तुम्ही हा प्रिंटर टाकून देऊ इच्छिता?",
  Delete_Bonus_Confirmation:"तुम्हाला खात्री आहे की तुम्ही हा बोनस हटवू इच्छिता?",
  Delete_Invoice_Confirmation:"तुम्हाला खात्री आहे की तुम्ही हे बीजक हटवू इच्छिता?",
  Generated_Cheque:"व्युत्पन्न चेक",
  Generated_Invoice:"व्युत्पन्न बीजक",
  Schedule_Start_Time:"सुरुवातीचे वेळापत्रक",
  Schedule_End_Time:"शेड्यूल समाप्त",
  New_Call:"नवीन कॉल",
  No_Contacts:"कोणतेही संपर्क नाहीत",
  No_Contacts_Word:"प्रशासक, नियंत्रक, कर्मचारी आणि पैसे घेणारे संपर्क म्हणून दिसतात.",
  PDF_Subject:"वित्त",
  PDF_Keywords:"पेरोल पेचेक पीडीएफ चेक चेक",
  Printer_Setup:"प्रिंटर सेटअप",
  Printer_Selection:"प्रिंटर निवड",
  New_Fax:"नवीन फॅक्स",
  New_Fax_Message:"नवीन फॅक्स संदेश",
  Fax_Machine:"फॅक्स मशीन",
  Fax_Name:"फॅक्स नाव",
  Fax_Email:"फॅक्स ईमेल",
  Fax_Number:"फॅक्स क्रमांक",
  Contents:"सामग्री",
  Fax_Body:"पेज बॉडी",
  Header_Word:"हेडर:",
  Header_Text:"हेडर मजकूर",
  Include_Header:"शीर्षलेख समाविष्ट करा",
  Include_Footer:"तळटीप समाविष्ट करा",
  Footer_Word:"तळटीप:",
  Footer_Text:"तळ मजकूर",
  Attach_a_Cheque:"चेक संलग्न करा",
  Add_Deduction:"वजावट जोडा",
  Enable_Fax:"फॅक्स पाठवा",
  Select_Fax:"फॅक्स निवडा",
  No_Faxes:"फॅक्स नाहीत",
  Faxes:"फॅक्स",
  Save_and_Send:"फॅक्स पाठवा",
  Save_and_Pay:"जतन करा आणि पैसे द्या",
  WARNING:"चेतावणी:",
  Remember:"लक्षात ठेवा",
  Printing:"मुद्रण",
  Printing_Complete:"मुद्रण पूर्ण!\n\n",
  of:"चा",
  There_Were:"तेथे होते ",
  Successful_Prints:"यशस्वी प्रिंट आणि",
  Unsuccessful_Prints:"अयशस्वी प्रिंट.",
  PrinterError:"माफ करा! एक त्रुटी आली.",
  Printing_:"मुद्रण...",
  PrinterSuccess:"दस्तऐवज यशस्वीरित्या छापले गेले.",
  PrintersSuccess:"दस्तऐवज यशस्वीरित्या छापले गेले.",
  Print_Cheques:"चेक्स छापणे",
  New_Message:"नवीन संदेश",
  New_Messages:"नवीन संदेश",
  Read_Message:"संदेश वाचा",
  Write_Message:"संदेश लिहा",
  Send_Message:"संदेश पाठवा",
  Subject:"विषय",
  Message:"संदेश",
  Writing:"लेखन...",
  Send:"पाठवा",
  Set_Date:"तारीख सेट करा",
  Set_Time:"वेळ सेट करा",
  Recieve:"मिळवा",
  Set_as_Default:"डीफॉल्ट म्हणून सेट",
  Default_Account:"डीफॉल्ट खाते",
  Default_Design:"डिफॉल्ट डिझाइन",
  Multiple_Cheques:"चेक्स (तिप्पट)",
  Account_Mode:"खाते मोड",
  Multiple_Cheques_Description:"प्रति पान तीन चेक.",
  Check_and_Table:"चेक आणि टेबल",
  Check_including_Table:"चेक आणि अकाउंटिंग टेबल.",
  Check_Mailer:"मेलर तपासा",
  Check_Mailer_Window:"अॅड्रेस विंडोसह तपासा.",
  DocuGard_Table_Top:"DocuGard चेक आणि टेबल (शीर्ष)",
  DocuGard_Table_Middle:"DocuGard चेक आणि टेबल (मध्यम)",
  DocuGard_Table_Bottom:"DocuGard चेक आणि टेबल (तळाशी)",
  DocuGard_Mailer_Top:"DocuGard चेक मेलर (टॉप)",
  DocuGard_Mailer_Middle:"DocuGard चेक मेलर (मध्यम)",
  DocuGard_Mailer_Bottom:"DocuGard चेक मेलर (तळाशी)",
  DocuGard_Three_Cheques:"DocuGard चेक (ट्रिपल)",
  DocuGard_Cheque_Top:"DocuGard चेक (शीर्ष)",
  DocuGard_Cheque_Middle:"DocuGard चेक (मध्यम)",
  DocuGard_Cheque_Bottom:"DocuGard चेक (तळाशी)",
  DocuGard_Three_Cheques_Window:"एका पानावर तीन चेक.",
  DocuGard_Table_Top_Window:"चेक आणि कमाई टेबल.",
  DocuGard_Table_Middle_Window:"चेक आणि कमाई टेबल.",
  DocuGard_Table_Bottom_Window:"चेक आणि कमाई टेबल.",
  DocuGard_Mailer_Top_Window:"तपासा, टेबल आणि पत्ता.",
  DocuGard_Mailer_Middle_Window:"तपासा, टेबल आणि पत्ता.",
  DocuGard_Mailer_Bottom_Window:"तपासा, टेबल आणि पत्ता.",
  DocuGard_Cheque_Top_Window:"सुरक्षित कागद तपासा.",
  DocuGard_Cheque_Middle_Window:"सुरक्षित कागद तपासा.",
  DocuGard_Cheque_Bottom_Window:"सुरक्षित कागद तपासा.",
  Basic_Cheque:"चेक (शीर्ष)",
  Basic_Cheque_Print:"एकच चेक प्रिंट करा.",
  Error_Setting_As_Paid:"पेड म्हणून सेट करताना त्रुटी",
  Add_Attachment:"संलग्नक जोडा",
  PrinterUnavailable:"प्रिंटर अनुपलब्ध",
  CreditCardComponent:"कार्डे",
  PaypalComponent:"पेपल",
  InteracComponent:"इंटरॅक",
  BitcoinComponent:"बिटकॉइन",
  New_Deposit:"नवीन ठेव",
  Deposits:"ठेवी",
  No_Deposits:"ठेवी नाहीत",
  Credit_Card_Deposit:"क्रेडीट कार्ड",
  New_Credit_Card_Deposit_Message:"क्रेडिट कार्ड ठेव",
  New_BitCoin_Deposit:"बिटकॉइन",
  New_BitCoin_Deposit_Message:"बिटकॉइन ठेव",
  New_Interac_Deposit:"इंटरॅक",
  New_Interac_Deposit_Message:"इंटरॅक ट्रान्सफर",
  Payments_Limit:"पेमेंट मर्यादा",
  No_Payment_Limit:"पेमेंट मर्यादा नाही",
  PayPal_Deposit:"पेपल",
  PayPal_Deposit_Message:"पेपल ठेव",
  No_Deposits_Word:"पहिली कंपनी <a routerLink='/folder/Deposit/New'>ठेवी</a> जोडा.",
  No_Documents_Specified:"छपाईसाठी कोणतेही दस्तऐवज निर्दिष्ट केलेले नाहीत",
  No_Printers_Added:"कोणतेही प्रिंटर आढळले नाहीत. एक जोडण्यासाठी सेटिंग्ज > प्रिंटर वर जा.",
  DB_Erase_Prompt:"संपूर्ण डेटाबेस पूर्णपणे मिटवायचा? चेतावणी: तुम्ही सर्व संग्रहित माहिती गमावाल!",
  Console_Warning:"या कन्सोलमध्ये कोणताही मजकूर पेस्ट करू नका. तुम्ही स्वतःला आणि/किंवा तुमच्या कंपनीला गंभीर धोका देऊ शकता.",
  No_Faxes_Word:"प्रथम <a routerLink='/folder/Fax/New'>फॅक्स</a> तयार करा.",
  Cheque_Delete_Confirmation:"तुम्हाला खात्री आहे की तुम्ही हा चेक हटवू इच्छिता?",
  Design_Delete_Confirmation:"तुम्हाला खात्री आहे की तुम्ही हे डिझाइन हटवू इच्छिता?",
  Cheque_Pay_Confirmation:"हा चेक पेड म्हणून चिन्हांकित करा? तो प्रिंट रांगेत दिसणार नाही.",
  Payment_Pay_Confirmation:"हे पेमेंट पेड म्हणून चिन्हांकित करा? ते चेकच्या रांगेत दिसणार नाही.",
  Delete_Deduction_Confirmation:"तुम्हाला खात्री आहे की तुम्ही ही वजावट हटवू इच्छिता?",
  Delete_Job_Confirmation:"तुम्हाला खात्री आहे की तुम्ही ही नोकरी हटवू इच्छिता?",
  Delete_Timesheet_Confirmation:"तुम्हाला नक्की हे टाइमशीट हटवायचे आहे का?",
  Delete_Schedule_Confirmation:"तुम्हाला खात्री आहे की तुम्ही हे वेळापत्रक हटवू इच्छिता?",
  Delete_Setting_Confirmation:"तुम्हाला खात्री आहे की तुम्ही ही सेटिंग रीसेट करू इच्छिता?",
  Delete_Fax_Confirmation:"तुम्हाला खात्री आहे की तुम्ही हा फॅक्स हटवू इच्छिता?",
  Delete_File_Confirmation:"तुम्हाला नक्की ही फाईल हटवायची आहे का?",
  Delete_Vacation_Confirmation:"तुम्हाला खात्री आहे की तुम्ही ही सुट्टी हटवू इच्छिता?",
  Delete_Printer_Confirmation:"तुम्हाला खात्री आहे की तुम्ही हा प्रिंटर हटवू इच्छिता?",
  Remove_Design_Confirmation:"तुम्हाला खात्री आहे की तुम्ही हे डिझाइन हटवू इच्छिता?",
  Delete_Payroll_Confirmation:"तुम्हाला खात्री आहे की तुम्ही हा वेतनपट हटवू इच्छिता?",
  Send_Fax_Email_Confirmation:"तुम्हाला हे दस्तऐवज फॅक्स आणि ईमेल करायचे आहेत का?",
  Send_Email_Confirmation:"तुम्हाला हा दस्तऐवज ईमेल करायचा आहे का?",
  Send_Fax_Confirmation:"तुम्हाला हा दस्तऐवज फॅक्स करायचा आहे का?",
  Error_Generating_PDF:"क्षमस्व. हा दस्तऐवज तयार करताना त्रुटी आली.",
  PDF_Error_Saving_Image:"क्षमस्व. या दस्तऐवजाची PDF प्रतिमा जतन करताना त्रुटी आली.",
  Test_Printer_Confirmation:"तुम्हाला या प्रिंटरवर चाचणी पृष्ठ मुद्रित करायचे आहे का?",
  Save_Timesheet_Prompt:"कृपया 'शीर्षक' जोडा किंवा सेव्ह करण्यासाठी 'स्टार्ट टाइमर' दाबा.",
  Remove_Geofence_Prompt:"तुम्हाला खात्री आहे की तुम्ही या भू-कुंपणाचे स्थान काढू इच्छिता?",
  Delete_Employee_Confirmation:"तुम्हाला नक्की डिलीट करायचे आहे का ",
  Fire_Employee_Confirmation:"तुम्हाला नक्की फायर करायचे आहे का"
}