import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { WebService } from '../services/web.service';
@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['../home/home.component.css','./plans.component.css']
})
export class PlansComponent implements OnInit {

  constructor(
    private web: WebService
    ) { }
  full_price = 99.99;
  Features=['Phone','Fax','Multiple Printers','PDF'];
  price=environment.prices;

  public products = [
    {id:"chequeslicence",months:"1",cheques:"-1",mode:"subscription",price:environment.prices[this.web.country].chequeslicence,currency:environment.prices[this.web.country].currency,alias:this.web.word.Subscription,type:'PAID_SUBSCRIPTION'},
    {id:"chequeslicence_2mo",months:"2",cheques:"-1",mode:"subscription",price:environment.prices[this.web.country].chequeslicence_2mo,currency:environment.prices[this.web.country].currency,alias:this.web.word.Two_Month_Subscription,type:'PAID_SUBSCRIPTION'},
    {id:"chequeslicence_3mo",months:"3",cheques:"-1",mode:"subscription",price:environment.prices[this.web.country].chequeslicence_3mo,currency:environment.prices[this.web.country].currency,alias:this.web.word.Three_Month_Subscription,type:'PAID_SUBSCRIPTION'},
    {id:"chequeslicence_6mo",months:"6",cheques:"-1",mode:"subscription",price:environment.prices[this.web.country].chequeslicence_6mo,currency:environment.prices[this.web.country].currency,alias:this.web.word.Six_Month_Subscription,type:'PAID_SUBSCRIPTION'},
    {id:"chequeslicence_12mo",months:"12",cheques:"-1",mode:"subscription",price:environment.prices[this.web.country].chequeslicence_12mo,currency:environment.prices[this.web.country].currency,alias:this.web.word.Twelve_Month_Subscription,type:'PAID_SUBSCRIPTION'},
    {id:"cheques_znvr9f",months:"-1",cheques:"-1",mode:"premium",price:environment.prices[this.web.country].cheques_znvr9f,currency:environment.prices[this.web.country].currency,alias:this.web.word.Cheques_Unlimited,type:'NON_CONSUMABLE'},
    {id:"cheques_print_1",months:"-1",cheques:"1",mode:"print",price:environment.prices[this.web.country].cheques_print_1,currency:environment.prices[this.web.country].currency,alias:this.web.word.Single_Print,type:'CONSUMABLE'},
    {id:"cheques_print_2",months:"-1",cheques:"2",mode:"print",price:environment.prices[this.web.country].cheques_print_2,currency:environment.prices[this.web.country].currency,alias:this.web.word.Two_Prints,type:'CONSUMABLE'},
    {id:"cheques_print_5",months:"-1",cheques:"5",mode:"print",price:environment.prices[this.web.country].cheques_print_5,currency:environment.prices[this.web.country].currency,alias:this.web.word.Five_Prints,type:'CONSUMABLE'},
    {id:"cheques_print_10",months:"-1",cheques:"10",mode:"print",price:environment.prices[this.web.country].cheques_print_10,currency:environment.prices[this.web.country].currency,alias:this.web.word.Ten_Prints,type:'CONSUMABLE'},
    {id:"cheques_print_15",months:"-1",cheques:"15",mode:"print",price:environment.prices[this.web.country].cheques_print_15,currency:environment.prices[this.web.country].currency,alias:this.web.word.Fifteen_Prints,type:'CONSUMABLE'},
    {id:"cheques_print_20",months:"-1",cheques:"20",mode:"print",price:environment.prices[this.web.country].cheques_print_20,currency:environment.prices[this.web.country].currency,alias:this.web.word.Twenty_Prints,type:'CONSUMABLE'},
    {id:"cheques_print_30",months:"-1",cheques:"30",mode:"print",price:environment.prices[this.web.country].cheques_print_30,currency:environment.prices[this.web.country].currency,alias:this.web.word.Thirty_Prints,type:'CONSUMABLE'},
    // {id:"cheques_mailed_black_white",mode:"real_mail",price:environment.prices[this.web.country].cheques_mailed_black_white,currency:environment.prices[this.web.country].currency,alias:this.web.word.Mailed_Cheque,type:'CONSUMABLE'},
    // {id:"cheques_znvr9f_color",mode:"real_mail",price:environment.prices[this.web.country].cheques_znvr9f_color,currency:environment.prices[this.web.country].currency,alias:this.web.word.Mailed_Cheque_Color,type:'CONSUMABLE'}
  ];

  buy(p?){
    alert('Purchases for upgrades are available from inside the application.');
  }
  ngOnInit() {

    // this.web.country='';

  }

}
