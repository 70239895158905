export const Ne = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"प्रतिलिपि अधिकार &प्रतिलिपि; २०२३",
  black:"कालो",
  green:"हरियो",
  gold:"सुन",
  blue:"निलो",
  brown:"खैरो",
  purple:"बैजनी",
  pink:"गुलाबी",
  red:"रातो",
  Swatches:"स्वचहरू",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"रङ",
  Saturation:"संतृप्ति",
  Lightness:"हल्कापन",
  Upgrade_To_Pro:"प्रो मा अपग्रेड गर्नुहोस्",
  AllFeaturesInclude:"सबै सुविधाहरू समावेश:",
  PrintUnlimitedCheques:"असीमित चेक छाप्नुहोस्",
  PremiumChequeDesigns:"प्रिमियम चेक डिजाइनहरू",
  ManageUnlimitedEmployees:"असीमित कर्मचारीहरू प्रबन्ध गर्नुहोस्",
  AddUnlimitedPayees:"असीमित भुक्तानीहरू थप्नुहोस्",
  CreateUnlimitedPayrolls:"असीमित पेरोलहरू सिर्जना गर्नुहोस्",
  UnlimitedSchedulesandTimeSheets:"असीमित तालिका र समय पानाहरू",
  BulkPayPalPayouts:"थोक PayPal भुक्तानीहरू",
  UnlimitedBankAccounts:"असीमित बैंक खाताहरू",
  ManageMultipleCompanies:"धेरै कम्पनीहरू प्रबन्ध गर्नुहोस्",
  TrackInsurancePolicies:"ट्र्याक बीमा नीतिहरू",
  Bio_MetricProtection:"बायो-मेट्रिक संरक्षण",
  Geo_FenceLock_OutProtection:"जियो-फेंस लक-आउट संरक्षण",
  Multiple_Companies_Word:"चेक प्रिमियम बिना धेरै कम्पनीहरू प्रबन्ध गर्न उपलब्ध छैन।",
  PayPal_Payouts_Word:"PayPal भुक्तानीहरू चेक प्रिमियम बिना असक्षम छन्।",
  PINProtection:"PIN सुरक्षा",
  PasswordProtection:"पासवर्ड संरक्षण",
  May_Require_Approval:"स्वीकृति आवश्यक हुन सक्छ।",
  Subscribe:"सदस्यता लिनुहोस्",
  Billed:"बिल गरियो",
  Up:"माथि",
  Down:"तल",
  Positioning:"स्थिति निर्धारण",
  Marker:"मार्कर",
  Drag_Marker:"मार्कर तान्नुहोस्",
  Tagline:"प्रिन्ट चेक र ट्याब्युलेट पेरोल",
  Marker_Word:"तत्वलाई आकार दिन मार्करहरू प्रयोग गर्नुहोस्।",
  Pinch_Zoom:"पिन्च जुम",
  Pinch_Word:"तत्व जुम गर्न पिन्च गर्नुहोस्।",
  Drag:"तान्नुहोस्",
  Drag_Word:"तत्वहरू तान्न आफ्नो औंला प्रयोग गर्नुहोस्।",
  subscription_alias_word:"स्वतः नवीकरण सदस्यता",
  premium_alias_word:"एक-पटक अपग्रेड प्याकेज",
  print_alias_word:"प्रिन्ट-आउट व्यक्तिगत चेकहरू",
  mode_alias_word:"मोड",
  Pro:"प्रो",
  Pro_word:"प्रो संस्करण आवश्यक छ।",
  Cant_Delete_Default_Company_Word:"माफ गर्नुहोस्, तपाईं आफ्नो पूर्वनिर्धारित कम्पनी मेटाउन सक्नुहुन्न ।",
  Reinsert_Default_Designs:"पूर्वनिर्धारित डिजाइनहरू पुन: घुसाउनुहोस्",
  Reinsert_Default_Designs_word:"के तपाइँ पूर्वनिर्धारित डिजाइनहरू पुन: सम्मिलित गर्न चाहनुहुन्छ?",
  Subscription_Active_Disable_Word:"यो सदस्यता सक्रिय छ। के तपाईं चेकको यो सदस्यता रद्द गर्न चाहनुहुन्छ?",
  Company_Logo:"कम्पनीको लोगो",
  ZERO_:"शून्य",
  Disclaimer:"अस्वीकरण",
  Privacy_Policy:"गोपनीयता नीति",
  EULA:"EULA जाँच गर्दछ",
  Terms_Of_Service:"सेवाका सर्तहरु",
  Terms_Of_Use:"प्रयोगका सर्तहरू",
  Refunds:"फिर्ता नीति",
  Single_Print:"१ जाँच गर्नुहोस्",
  Two_Prints:"२ चेक",
  Five_Prints:"५ चेक",
  Ten_Prints:"१० चेक",
  Fifteen_Prints:"१५ चेक",
  Twenty_Prints:"२० चेक",
  Thirty_Prints:"30 चेक",
  Image_Added:"छवि थपियो",
  Image_Preview:"छवि पूर्वावलोकन",
  No_Image_Was_Selected:"कुनै छवि चयन गरिएको थिएन।",
  Cheques_Unlimited:"चेक असीमित",
  _Subscription:"सदस्यता",
  Subscription:"चेक - 1 महिना",
  Two_Month_Subscription:"चेक - 2 महिना",
  Three_Month_Subscription:"चेक - 3 महिना",
  Six_Month_Subscription:"चेक - 6 महिना",
  Twelve_Month_Subscription:"चेक - 12 महिना",
  Cheques_Are_Available:"चेकहरू छाप्न उपलब्ध छन्।",
  Upgrade_Required_Two:"प्याकेज चयन गर्नुहोस् र आफ्नो खरिद सुरु गर्न मूल्य बटनमा डबल ट्याप गर्नुहोस्। सेकेन्डमा पेशेवर देखिने पूर्ण-रंग जाँचहरू छाप्नुहोस्।",
  Month:"महिना",
  Due:"बाँकी:",
  Expires:"म्याद सकिन्छ:",
  Subscription_Active:"सदस्यता सक्रिय",
  Subscription_Inactive:"सदस्यता निष्क्रिय",
  Purchase_Additional_Cheques:"थप चेकहरू खरिद गर्ने?",
  Printable_Cheque:"मुद्रण योग्य जाँच",
  Printable_Cheques:"प्रिन्ट योग्य जाँचहरू",
  Printable_Cheque_Word:"चेक तपाईको खातामा उपलब्ध छ।",
  Printable_Cheques_Word:"चेकहरू तपाईंको खातामा उपलब्ध छन्।",
  Max_Amount_Message:"तपाईंले निर्दिष्ट गर्नुभएको रकम यस प्रणालीको लागि सेट गरिएको अधिकतम रकममा पुगेको छ:",
  Terms_Required_Word:"तपाईंले चेकहरू प्रयोग गर्न जारी राख्नु अघि यो सम्झौतामा सहमत हुनुपर्छ।",
  Subscriptions:"सदस्यताहरू",
  Product_Upgrades:"अपग्रेडहरू",
  Mailed_Out_Cheques:"मेल-आउट चेकहरू",
  Enter_A_Company_Name:"कृपया कम्पनीको नाम प्रविष्ट गर्नुहोस्।",
  Single_Cheques:"एकल जाँचहरू",
  Cheque_Golden:"गोल्डेन चेक",
  Cheque_Golden_Window:"गोल्डेन चेक डिजाइन।",
  Cheque_Green:"हरियो जाँच",
  Cheque_Green_Window:"हरियो चेक डिजाइन।",
  Cheque_Red:"रातो जाँच",
  Cheque_Red_Window:"रातो चेक डिजाइन।",
  Cheque_Yellow:"पहेंलो जाँच",
  Cheque_Yellow_Window:"पहेंलो चेक डिजाइन।",
  Cheque_Statue_of_Liberty:"स्ट्याचु अफ लिबर्टी",
  Cheque_Statue_of_Liberty_Window:"स्ट्याचु अफ लिबर्टी चेक डिजाइन।",
  Cheque_Green_Wave:"हरियो लहर",
  Cheque_Green_Wave_Window:"हरियो चेक डिजाइन।",
  Cheque_Golden_Weave:"सुनौलो बुनाई",
  Cheque_Golden_Weave_Window:"सुरुचिपूर्ण सुनौलो चेक डिजाइन।",
  Cheque_Green_Sun:"हरियो सूर्य",
  Cheque_Green_Sun_Window:"गहिरो र शान्त चेक डिजाइन।",
  Cheque_Blue_Checkers:"निलो चेकर्स",
  Cheque_Blue_Checkers_Window:"नीलो चेक डिजाइन।",
  Cashiers_Check:"क्यासियर चेक",
  Cashiers_Check_Window:"क्यासियर चेक शैली टेम्प्लेट।",
  Cheque_Aqua_Checkers:"एक्वा चेकर्स",
  Cheque_Aqua_Checkers_Window:"एक्वा चेक डिजाइन।",
  Cheque_Golden_Checkers:"गोल्डेन चेकर्स",
  Cheque_Golden_Checkers_Window:"गोल्डेन चेक डिजाइन।",
  Upgrade_Unavailable:"अपग्रेडहरू उपलब्ध छैनन्",
  Bank_Code_Protection:"बैंक नम्बर संरक्षण",
  Bank_Code_Protection_Word:"अर्को यन्त्रमा वा अर्को प्रयोगकर्ताको लागि चलिरहेको यो एपमा प्रयोग हुनबाट आफ्नो बैंक नम्बरहरू सुरक्षित गर्नुहोस्। यो कार्य अपरिवर्तनीय छ। जारी राख्ने हो?",
  Bank_Code_Protection_Blocked_Word:"तपाईले प्रयोग गर्न खोज्नु भएको बैंक नम्बरहरू अर्को प्रयोगकर्ता वा उपकरणको लागि आरक्षित छन्।",
  Bank_Code_Protection_Error_Word:"नम्बर प्रमाणिकरण असफल भएको छ। कृपया इन्टरनेटमा जडान गर्नुहोस् र यो बैंक खाता फेरि थप्ने प्रयास गर्नुहोस्।",
  Bank_Code_Protection_Set_Error_Word:"बैंक नम्बर सुरक्षाको लागि तपाई इन्टरनेटमा जडान हुनु आवश्यक छ। कृपया जडान गरी पुन: प्रयास गर्नुहोस्।",
  Upgrade_Unavailable_Word:"माफ गर्नुहोस्, हामीलाई तपाइँ प्रमाणित गर्न समस्या भइरहेको छ। सदस्यताहरू र चेकहरूमा अपग्रेडहरू हाल तपाईंको क्षेत्रमा खरिदका लागि उपलब्ध छैनन्।",
  PayPal_Payment_Preview:"PayPal भुक्तानी पूर्वावलोकन",
  Apple_Pay:"एप्पल पे",
  Select_PayPal:"PayPal चयन गर्नुहोस्",
  PayPal_Applications:"PayPal अनुप्रयोगहरू",
  Purchase_With_Apple_Pay:"Apple Pay मार्फत खरिद गर्नुहोस्",
  Google_Pay:"Google Pay",
  Companies:"कम्पनीहरु",
  Insurance:"बीमा",
  New_PayPal:"नयाँ PayPal",
  Pay_By:"द्वारा तिर्नुहोस्",
  Insure:"बीमा गर्नुहोस्",
  Miles:"माइल",
  Payment_Method:"भुक्तानी विधि",
  Select_Policies:"नीतिहरू चयन गर्नुहोस्",
  Policies:"नीतिहरू",
  Policy:"नीति",
  No_PayPal_Account:"PayPal खाता छैन",
  No_Policies:"कुनै बीमा नीतिहरू छैनन्",
  New_Policy:"नयाँ नीति",
  PayPal_Payment:"PayPal भुक्तानी",
  PayPal_Payments:"PayPal भुक्तानीहरू",
  No_Payment_Selected:"कुनै भुक्तानी चयन गरिएको छैन",
  Sending_Payment_Word:"कृपया पर्खनुहोस्... यो भुक्तानी पठाइँदैछ।",
  Sending_Payments_Word:"कृपया पर्खनुहोस्... भुक्तानी पठाइँदै छ।",
  No_Payment_Selected_PayPal:"कुनै <a routerLink='/folder/Payments'>PayPal भुक्तानी</a> चयन गरिएको छैन।",
  Payment_Sent:"भुक्तानी पठाइयो",
  PayPal_Payment_Sent:"यो भुक्तानी PayPal मार्फत पठाइएको छ।",
  Copay:"प्रतिलिपि गर्नुहोस्",
  Dead:"मृत",
  Alive:"जीवित",
  Not_Dead:"मरेको होइन",
  Unclaimed:"दावी नगरिएको",
  Attempted:"कोशिश गर्नु",
  Deceased:"मृतक",
  Claimed:"दाबी गरे",
  Unpaid:"भुक्तान नगरिएको",
  Sending_Payment:"भुक्तानी पठाउँदै",
  Sending_Payments:"भुक्तानी पठाउँदै",
  Send_PayPal_Confirmation:"के तपाईं PayPal मार्फत यो कारोबार पठाउन चाहनुहुन्छ?",
  Send_PayPal_Confirmation_Word:"यो लेनदेन पठाउन हरियो बटन थिच्नुहोस्।",
  Save_Payment_As_Unpaid:"यो भुक्तानीलाई भुक्तान नगरिएको रूपमा बचत गर्ने हो?",
  Payment_Pay_Confirmation_PayPal:"यो भुक्तानी भुक्तान गरिएको रूपमा बचत गर्ने हो?",
  No_Policies_Word:"पहिले <a routerLink='/folder/Postage/New'>बीमा नीति</a> अहिले थप्नुहोस् ।",
  Timesheet_Multiple_Delete_Confirmation:"के तपाइँ धेरै टाइमशिटहरू मेटाउन निश्चित हुनुहुन्छ?",
  Select_Multiple_Timesheets_Prompt:"कुनै टाइमशिट चयन गरिएको छैन। कम्तिमा एउटा टाइमशीट चयन गर्नुहोस्।",
  Select_Multiple_Policies_Prompt:"कुनै नीतिहरू चयन गरिएको छैन। कम्तिमा एउटा बीमा नीति चयन गर्नुहोस्।",
  Policies_Multiple_Delete_Confirmation:"के तपाईं धेरै नीतिहरू मेटाउन निश्चित हुनुहुन्छ?",
  Company:"कम्पनी",
  Add_Company:"कम्पनी थप्नुहोस्",
  New_Company:"कम्पनी थप्नुहोस्",
  No_Companies:"कुनै कम्पनीहरू छैनन्",
  Enable_Company:"कम्पनी सक्षम गर्नुहोस्",
  Select_Company:"कम्पनी चयन गर्नुहोस्",
  The_Default_Company:"पूर्वनिर्धारित कम्पनी लेबल।",
  Manage_Companies:"कम्पनीहरू प्रबन्ध गर्नुहोस्",
  No_Companies_Word:"चेकहरूले पूर्वनिर्धारित कम्पनी प्रयोग गर्नेछ ।<br /> <a routerLink='/folder/Company/New'>पहिलो कम्पनी</a> थप्नुहोस् ।",
  Default_Company:"पूर्वनिर्धारित कम्पनी",
  Cheques_Unlimited_Word:"चेक अनलिमिटेडले तपाईंलाई आफूले चाहे जति चेकहरू छाप्न अनुमति दिन्छ।",
  Cheques_Subscription_Word:"चेक सदस्यताले तपाईंलाई आफूले चाहे जति चेकहरू छाप्न अनुमति दिन्छ।",
  You_Own_This_Product:"तपाईं यो उत्पादनको मालिक हुनुहुन्छ।",
  Your_Subscription_is_Active:"तपाईंको सदस्यता सक्रिय छ।",
  Active_Products:"सक्रिय उत्पादनहरू",
  Purchase_Confirmation:"खरिद गर्नुहोस्",
  Purchase_Cheques:"खरिद चेकहरू",
  Restore_Purchase:"खरिदहरू पुनर्स्थापित गर्नुहोस्",
  Erase_Purchase:"खरिदहरू मेटाउनुहोस्",
  Successfully_Purchased:"सफलतापूर्वक खरिद गरियो",
  Enter_Your_Licence_Key:"यो उत्पादन सक्रिय गर्नको लागि कृपया यस पृष्ठमा तपाईंको इजाजतपत्र कुञ्जी प्रविष्ट गर्नुहोस्।",
  Licence_Key:"लाइसेन्स कुञ्जी",
  Enter_Licence_Key:"लाइसेन्स कुञ्जी प्रविष्ट गर्नुहोस्",
  Purchased:"खरिद गरियो",
  Enable_Feature:"सुविधा सक्षम गर्नुहोस्",
  Cancel_Subscription:"सदस्यता रद्द गर्नुहोस्",
  Subscription_Removed:"सदस्यता हटाइयो",
  Select_Active_Subscription:"यसलाई परिमार्जन गर्नको लागि सक्रिय सदस्यता चयन गर्नुहोस्।",
  Remove_Subscription_Word:"के तपाइँ यो सदस्यता रद्द गर्न निश्चित हुनुहुन्छ?",
  Delete_Company_Confirmation:"के तपाइँ यो सम्पूर्ण कम्पनी मेटाउन निश्चित हुनुहुन्छ? चेतावनी: तपाईंले सबै भण्डारण जानकारी गुमाउनुहुनेछ!",
  Delete_Default_Company_Word:"तपाईं पूर्वनिर्धारित कम्पनी मेटाउन सक्नुहुन्न । के तपाइँ एप रिसेट गर्न र यसलाई पूर्वनिर्धारित अवस्थामा पुनर्स्थापित गर्न चाहनुहुन्छ? चेतावनी: तपाईंले सबै भण्डारण जानकारी गुमाउनुहुनेछ!",
  Console_Warning_2:"यो अनुप्रयोग प्रयोग गरेर कुनै पनि मुद्रा ह्यान्डल नगर्नुहोस् जुन हाल तपाईंको होइन।",
  Terms_and_Conditions:"नियम र सर्तहरू",
  and_the:"र",
  for:"को लागी",
  Please_Read_Word:"कृपया पढ्नुहोस् र सहमत हुनुहोस्",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"केही मुद्रा रूपान्तरण दरहरू फेला पार्न सकिएन। कृपया इन्टरनेटमा जडान गर्नुहोस्।",
  Free:"नि:शुल्क",
  DB_Erase_Prompt_2:"सम्पूर्ण विकासकर्ता डाटाबेस पूर्ण रूपमा मेटाउने हो? चेतावनी: तपाईंले सबै खरिद र सदस्यता जानकारी गुमाउनुहुनेछ!",
  Successfully_Imported:"सफलतापूर्वक आयात गरियो",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ १००",
  Select_Postage:"डाक चयन गर्नुहोस्",
  No_Postage:"कुनै हुलाक टिकट छैन",
  No_Paid_Postage_Word:"पहिलो <a routerLink='/folder/Postage/New'>भुक्तानी हुलाक</a> स्ट्याम्प थप्नुहोस् ।",
  Trial_Complete:'परीक्षण पूरा',
  Please_Upgrade:'कृपया मुद्रण जारी राख्न चेक अपग्रेड गर्नुहोस्।',
  Aa:"आ",
  Color:"रङ",
  Font:"फन्ट",
  Guide:"गाइड",
  Guides:"गाइडहरू",
  Image:"छवि",
  Zoom:"जुम",
  Logo:"लोगो",
  Bank:"बैंक",
  MICR:"MICR",
  Total:"कुल",
  Cancel:"रद्द गर्नुहोस्",
  Confirm:"पुष्टि गर्नुहोस्",
  Method:"विधि",
  Biometric_Security:"बायोमेट्रिक सुरक्षा",
  Please_Login_To_Continue:"जारी राख्न कृपया लगइन गर्नुहोस्।",
  Complete:"पूरा",
  Sign_Up:"साइन अप",
  Error:"त्रुटि",
  Biometrics:"बायोमेट्रिक्स",
  Percent:"प्रतिशत",
  Zero_Percent:"०%",
  Top_Margin:"शीर्ष मार्जिन",
  Bottom_Margin:"तल्लो मार्जिन",
  Left_Margin:"बायाँ मार्जिन",
  Right_Margin:"दायाँ मार्जिन",
  MICR_Margin:"MICR मार्जिन",
  Table_Margin:"तालिका मार्जिन",
  Address_Margin:"ठेगाना मार्जिन",
  Percentage_:"प्रतिशत",
  Vacation_Title:"छुट्टी शीर्षक",
  Use_PIN:"PIN प्रयोग गर्नुहोस्",
  Loading__:"लोड गर्दै...",
  Design_Title:"डिजाइन शीर्षक",
  Authorize:"अधिकार दिनुहोस्",
  Key:"कुञ्जी",
  Public_Key:"सार्वजनिक कुञ्जी",
  Private_Key:"निजी कुञ्जी",
  Authenticate:"प्रमाणीकरण गर्नुहोस्",
  Last_Payroll:"अन्तिम पेरोल",
  Last_Calculation:"अन्तिम गणना",
  Authorized:"अधिकृत",
  Geo_Authorized:"भौगोलिक स्थान: अधिकृत",
  Not_Authorized:"अधिकृत छैन",
  Authorization_Complete:"प्राधिकरण पूरा भयो",
  Geolocation_Authorization:"भौगोलिक स्थान प्राधिकरण",
  Out_of_Bounds:"सीमा बाहिर",
  Cant_Delete_Default_Design:"पूर्वनिर्धारित डिजाइन मेटाउन सकिँदैन।",
  Unauthorized_Out_of_Bounds:"अनाधिकृत: सीमा बाहिर",
  Biometric_Authorization:"बायोमेट्रिक प्राधिकरण",
  Locating_Please_Wait:"पत्ता लगाउँदै, कृपया प्रतीक्षा गर्नुहोस्...",
  Test_Biometrics:"बायोमेट्रिक्स परीक्षण गर्नुहोस्",
  Cheque_Margins:"मार्जिन जाँच गर्नुहोस्",
  Percentage_Full_Error:"प्रतिशत क्षेत्र 100% प्रतिशत भन्दा बढी सेट गर्न सकिँदैन।",
  No_Payroll_Text:"<a routerLink='/folder/Employee/New'>कर्मचारी</a> वा <a routerLink='/folder/Payee/New'>भुक्तानी गर्ने</a> र <a routerLink='/folder/Schedule/New'>तालिका</a>।",
  Design_Saved:"डिजाइन सुरक्षित गरियो",
  Default_Design_Selected:"पूर्वनिर्धारित डिजाइन चयन गरियो",
  Partial_Import:"आंशिक आयात",
  Partial_Export:"आंशिक निर्यात",
  Entire_Import:"सम्पूर्ण आयात",
  Entire_Export:"सम्पूर्ण निर्यात",
  Existing_Password:"कृपया आफ्नो अवस्थित पासवर्ड प्रविष्ट गर्नुहोस्:",
  Existing_PIN:"कृपया आफ्नो अवस्थित PIN कोड प्रविष्ट गर्नुहोस्:",
  Pin_Change_Header:"PIN पुष्टिकरण",
  Pin_Change_SubHeader:"परिवर्तन पुष्टि गर्न आफ्नो पुरानो PIN नम्बर प्रविष्ट गर्नुहोस्।",
  Pass_Change_Header:"पासवर्ड पुष्टिकरण",
  Pass_Change_SubHeader:"परिवर्तन पुष्टि गर्न आफ्नो पुरानो पासवर्ड प्रविष्ट गर्नुहोस्।",
  PIN_Enter_Message:"पुष्टि गर्न आफ्नो PIN प्रविष्ट गर्नुहोस्।",
  Password_Enter_Message:"पुष्टि गर्न आफ्नो पासवर्ड प्रविष्ट गर्नुहोस्।",
  Pin_Updated_Success:"PIN सफलतापूर्वक अद्यावधिक गरियो!",
  Pin_Updated_Fail:"PIN अद्यावधिक गर्न सकिएन।",
  Pass_Updated_Success:"पासवर्ड सफलतापूर्वक अद्यावधिक गरियो।",
  Pass_Updated_Fail:"पासवर्ड अपडेट गर्न सकिएन।",
  Preview_Payment:"पूर्वावलोकन भुक्तानी",
  Preview_Payroll:"पूर्वावलोकन पेरोल",
  No_Payments_Created:"कुनै भुक्तानीहरू सिर्जना भएको फेला परेन।",
  Payment_Preview:"भुक्तानी पूर्वावलोकन",
  Enable_Payee:"भुक्तानीकर्ता सक्षम गर्नुहोस्",
  Rendered:"रेन्डर गरियो",
  No_Email:"कुनै इमेल छैन",
  Setup_Cheques:"सेटअप जाँचहरू",
  name:"नाम",
  address:"ठेगाना",
  address_2:"ठेगाना लाइन 2",
  city:"सहर",
  province:"प्रान्त",
  postal_code:"हुलाक / जिप कोड",
  country:"देश",
  username:"प्रयोगकर्ता नाम",
  full_name:"पुरा नाम",
  birthday:"जन्मदिन",
  email:"इमेल",
  phone:"फोन",
  employees:"कर्मचारीहरु",
  addresses:"ठेगानाहरू",
  payment_amount_limit:"भुक्तानी रकम सीमा",
  total_limit:"कुल सीमा",
  payees:"भुक्तानी गर्नेहरू",
  description:"विवरण",
  address_line_one:"ठेगाना लाइन एक",
  address_line_two:"ठेगाना लाइन दुई",
  image:"छवि",
  account_holder:"खातावाला",
  cheque_starting_id:"सुरु आईडी जाँच गर्नुहोस्",
  transit_number:"ट्रान्जिट नम्बर",
  institution_number:"संस्था नम्बर",
  designation_number:"पदनाम नम्बर",
  account_number:"खाता नम्बर",
  currency:"मुद्रा",
  signature:"हस्ताक्षर",
  payment_payroll_limit:"भुक्तानी सीमा",
  total_limi:"कुल सीमा",
  date:"मिति",
  printed_memo:"छापिएको मेमो",
  banks:"बैंकहरू",
  signature_image:"हस्ताक्षर छवि",
  address_name:"ठेगाना नाम",
  notes:"नोटहरू",
  design:"डिजाइन",
  title:"शीर्षक",
  frequency:"आवृत्ति",
  fax:"फ्याक्स",
  salaries:"तलब",
  salary_ids:"तलब आईडीहरू",
  start_time:"सुरु समय",
  end_time:"अन्त्य समय",
  paid:"भुक्तान गरियो",
  overtime_percentage:"भुक्तान प्रतिशत",
  overtime_amount:"भुक्तान निश्चित रकम",
  first_name:"पहिलो नाम",
  last_name:"थर",
  moderation:"मध्यस्थता",
  create:"सिर्जना गर्नुहोस्",
  edit:"सम्पादन गर्नुहोस्",
  destroy:"नष्ट",
  day_start_time:"दिन_सुरु_समय",
  day_end_time:"दिन_अन्त_समय",
  fullname:"नाम",
  time:"समय",
  auto_send:"स्वचालित रूपमा पठाउनुहोस्",
  time_method:"समय विधि",
  schedules:"तालिका",
  indefinite_payroll_enabled:"अनिश्चित रूपमा सक्षम गर्नुहोस्",
  amount:"रकम",
  repeat:"दोहोर्याउनुहोस्",
  always_enabled:"सधैं सक्षम",
  start_date:"सुरू मिति",
  end_date:"अन्त्य मिति",
  Cheque_Total:"कुल जाँच गर्नुहोस्",
  Total_Amount:"कुल रकम:",
  Amounts:"रकम:",
  Images:"छविहरू",
  Files:"फाइलहरू",
  Previewing:"पूर्वावलोकन गर्दै:",
  Insert:"घुसाउनुहोस्",
  Preview_Import:"पूर्वावलोकन आयात",
  Entry:"प्रवेश",
  Entries:"प्रविष्टिहरू",
  No_Entries:"कुनै प्रविष्टिहरू छैनन्",
  Import_Type:"आयात प्रकार",
  Select_Details:"विवरणहरू चयन गर्नुहोस्",
  Select_Payee:"भुक्तानीकर्ता चयन गर्नुहोस्",
  Enable_Holidays:"बिदाहरू सक्षम गर्नुहोस्",
  Disable_Holidays:"छुट्टीहरू असक्षम गर्नुहोस्",
  Wire_Transfer:"तारद्वारा हस्तान्तरण",
  New_Export:"नयाँ निर्यात",
  Export_Data:"डाटा निर्यात गर्नुहोस्",
  Export_Data_Word:"निर्यात र डाउनलोड गर्न फाइल को प्रकार चयन गर्नुहोस्।",
  Export_File:"फाइल निर्यात गर्नुहोस्",
  Mode:"मोड",
  Times:"समय",
  Widgets:"विजेटहरू",
  Slider:"स्लाइडर",
  Set_Details:"विवरणहरू सेट गर्नुहोस्",
  Select_Type:"प्रकार चयन गर्नुहोस्",
  Display_Slider:"स्लाइडर प्रदर्शन गर्नुहोस्",
  Dashboard_Slider:"ड्यासबोर्ड स्लाइडर",
  Dashboard_Mode:"ड्यासबोर्ड मोड",
  Show_Intro:"परिचय देखाउनुहोस्",
  Show_Payrolls:"पेरोल देखाउनुहोस्",
  Show_Holidays:"बिदाहरू देखाउनुहोस्",
  Show_Invoices:"इनभ्वाइसहरू देखाउनुहोस्",
  Show_Cheques:"चेक देखाउनुहोस्",
  Enabled_Widgets:"सक्षम विजेटहरू",
  Disabled_Widgets:"असक्षम विजेटहरू",
  Colors:"रंगहरू",
  Barcodes:"बारकोडहरू",
  View_Timers:"टाइमरहरू हेर्नुहोस्",
  Gradients:"ग्रेडियन्टहरू",
  No_Info:"कुनै जानकारी छैन",
  Disable:"असक्षम पार्नुहोस्",
  Show_Layer:"तहहरू देखाउनुहोस्",
  Hide_Layer:"तहहरू लुकाउनुहोस्",
  Text_Layer:"पाठ तहहरू",
  Secondly:"दोस्रो",
  Minutely:"मिनेटमा",
  nine_am:"बिहान ९:००",
  five_pm:"बेलुका ५:००",
  Enable_Address:"ठेगाना सक्षम गर्नुहोस्",
  Invalid_File_Type:"माफ गर्नुहोस्, एउटा अवैध फाइल प्रकार चयन गरिएको थियो। समर्थित फाइल प्रकार:",
  Error_Updating_Entry:"माफ गर्नुहोस्, यो प्रविष्टि अपडेट गर्दा त्रुटि भयो।",
  Schedule_Timing_Alert:"त्रुटि: समय तालिका सुरु हुने समय समाप्त हुने समय पछि मानमा सेट गरिएको छ।",
  Select_Multiple_Payments_Prompt:"कुनै भुक्तानी चयन गरिएको छैन। कम्तिमा एउटा भुक्तानी चयन गर्नुहोस्।",
  Select_Multiple_Cheques_Prompt:"कुनै चेक चयन गरिएको छैन। कृपया कम्तिमा एउटा चेक चयन गर्नुहोस्।",
  Select_Multiple_Items_Prompt:"कुनै वस्तु चयन गरिएको छैन। कृपया कम्तिमा एउटा वस्तु चयन गर्नुहोस्।",
  Paymemt_Multiple_Delete_Confirmation:"के तपाइँ धेरै भुक्तानीहरू मेटाउन निश्चित हुनुहुन्छ?",
  Cheque_Multiple_Delete_Confirmation:"के तपाईं धेरै चेकहरू मेटाउन निश्चित हुनुहुन्छ?",
  Payee_Multiple_Delete_Confirmation:"के तपाईं धेरै भुक्तानीकर्ताहरू मेटाउन निश्चित हुनुहुन्छ?",
  Employee_Multiple_Delete_Confirmation:"के तपाईं धेरै कर्मचारीहरूलाई मेटाउन निश्चित हुनुहुन्छ?",
  MICR_Warning:"नोट: केही प्रिन्टर र उपकरणहरूले MICR फन्ट सही रूपमा प्रदर्शन नगर्न सक्छन्।",
  Automatically_Send:"स्वचालित रूपमा पठाउनुहोस्",
  Type:"टाइप गर्नुहोस्",
  Payment_Type:"भुक्तानी प्रकार",
  Auto_Send:"स्वतः पठाउनुहोस्",
  Automatically_Process:"स्वचालित रूपमा प्रक्रिया",
  Auto_Process:"स्वत: प्रक्रिया",
  Image_Based:"छवि आधारित",
  Font_Based:"फन्ट-आधारित",
  Rerender:"पुन: रेन्डर गर्नुहोस्",
  Rendering:"प्रतिपादन",
  Render:"फाइलहरू",
  Top:"शीर्ष",
  Middle:"मध्य",
  Bottom:"तल",
  Top_Left:"माथिल्लो बाया",
  Top_Center:"शीर्ष केन्द्र",
  Top_Right:"शीर्ष दायाँ",
  Middle_Left:"मध्य बायाँ",
  Middle_Center:"मध्य केन्द्र",
  Middle_Right:"मध्य दायाँ",
  Bottom_Left:"तल बायाँ",
  Bottom_Center:"तल्लो केन्द्र",
  Bottom_Right:"तल दायाँ",
  Numbers:"नम्बरहरू",
  Verified:"प्रमाणित",
  Paper_Size:"कागज आकार",
  New_Device:"नयाँ यन्त्र",
  Add_Device:"यन्त्र थप्नुहोस्",
  Remove_Device:"यन्त्र हटाउनुहोस्",
  Delete_Device:"यन्त्र मेटाउनुहोस्",
  Block_Device:"यन्त्र ब्लक गर्नुहोस्",
  Block:"ब्लक",
  Unblock:"अनब्लक गर्नुहोस्",
  Table:"तालिका",
  Scan_Login_Code:"लगइन कोड स्क्यान गर्नुहोस्",
  Login_Code:"लगइन कोड",
  Scan_Code:"स्क्यान कोड",
  Scan_QR_Code:"QR कोड स्क्यान गर्नुहोस्",
  Select_All:"सबै छान्नु",
  Deselect_All:"सबै अचयन गर्नुहोस्",
  Increase:"बढ्नु",
  Decrease:"घटाउनुहोस्",
  Bold:"बोल्ड",
  Text:"पाठ",
  Style:"शैली",
  Italic:"इटालिक",
  QR_Code:"QR कोड",
  Barcode:"बारकोड",
  Browse_Images:"छविहरू ब्राउज गर्नुहोस्",
  Browse_Files:"फाइलहरू ब्राउज गर्नुहोस्",
  Background_Image:"पृष्ठभूमि छवि",
  Logo_Image:"लोगो छवि",
  Header_Image:"हेडर छवि",
  Bank_Image:"बैंक छवि",
  Cut_Lines:"लाइनहरू काट्नुहोस्",
  Background:"पृष्ठभूमि",
  License:"इजाजतपत्र",
  One_License:"१ इजाजतपत्र:",
  Licensed:"लाई इजाजतपत्र:",
  Not_Licensed:"लाइसेन्स छैन",
  Enter_Serial:"सिरियल प्रविष्ट गर्नुहोस्",
  Serial_Key:"सिरियल कुञ्जी",
  Serial:"सिरियल",
  Product_Key:"उत्पादन कुञ्जी",
  Check_Product_Key:"उत्पादन कुञ्जी जाँच गर्नुहोस्",
  Add_Product_Key:"उत्पादन कुञ्जी थप्नुहोस्",
  Verifying_Purchase:"खरिद प्रमाणीकरण गर्दै...",
  Print_Envelope:"खाम छाप्नुहोस्",
  Envelope:"खाम",
  Thank_You:"धन्यवाद!",
  Scale:"स्केल",
  Print_Scale:"प्रिन्ट स्केल",
  Borders:"सीमानाहरू",
  VOID:"शून्य",
  Void_Cheque:"शून्य जाँच",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"को अर्डरमा भुक्तान गर्नुहोस्:",
  NO_DOLLARS:"कुनै डलर छैन ",
  ONE_DOLLAR:"एक डलर",
  DOLLARS:" डलर",
  AND:" र ",
  CENTS:"सेन्ट",
  NO_:"NO ",
  ONE_:"ONE ",
  AND_NO_:"र नं ",
  _AND_ONE_:"र एक ",
  DOLLARS_AND_ONE_CENT:" र एक सेन्ट।",
  AND_NO_CENTS:" र शून्य सेन्ट।",
  CHEQUE_PRINT_DATE:"मिति:",
  CHEQUE_PRINT_MEMO:"मेमो:",
  CHEQUE_PRINT_SIGNATUREMP:"एमपी",
  Initial_Tasks:"प्रारम्भिक कार्यहरू",
  Inital_Setup:"प्रारम्भिक सेटअप",
  Welcome_To:"स्वागत छ",
  Welcome:"स्वागत छ",
  Swipe:"स्वाइप",
  Intro_Setup:"परिचय सेटअप",
  Introduction:"परिचय",
  CHEQUE_PRINT_CREDIT:"चेक द्वारा संचालित",
  Title_Intro_Word:"चेकहरूमा स्वागत छ",
  Title_Intro:"चेक परिचय",
  Title_Setup:"चेक सेटअप",
  PayPal_Default_Email_Message:"तपाईंले नयाँ PayPal स्थानान्तरण प्राप्त गर्नुभएको छ। [चेकहरूद्वारा संचालित]",
  Cheques_App_Export:"चेकहरू द्वारा निर्यात गरिएको",
  Post_Intro_Thanks:"चेकहरू छनौट गर्नुभएकोमा धन्यवाद। सेटअप प्रक्रिया अब पूरा भएको छ।",
  Post_Intro_Word:"तपाईंको पहिलो चेक छापेर सुरु गर्नुहोस्, भविष्यको भुक्तानी थप्नुहोस्, वा पेरोलमा कर्मचारीहरू थप्नुहोस्।",
  Upgrade_Required:"चेकहरूले यो सन्देश लुकाउन र थप सुविधाहरू अनलक गर्न सफ्टवेयरको थप प्रिमियम संस्करणको स्वामित्व लिन आवश्यक छ।",
  Upgrade_Title:"चेक",
  Mailout_Prompt:"तपाईँले थप रूपमा आफ्नो पेरोल चेकहरू तपाईंको लागि चेक मेल-आउट गर्न छनौट गर्न सक्नुहुन्छ।",
  Mailed_Cheque:"मेल गरिएको चेक:",
  Mailed_Cheque_Color:"मेल गरिएको जाँच (रङ):",
  Terms_Purchase:"चेकहरू भएका सबै इलेक्ट्रोनिक खरिदहरू खरिद मितिदेखि ३०-दिनसम्मका लागि पूर्ण रूपमा फिर्ता योग्य छन्। कृपया जारी राख्नु अघि <a href='#'>नियम र सर्तहरू</a> पढ्नुहोस् र सहमत हुनुहोस्।",
  Dashboard_Setup:"प्राथमिक प्रिन्टर सेटअप गर्नुहोस्",
  Dashboard_Add:"प्राथमिक बैंक खाता थप्नुहोस्",
  Dashboard_Customize:"चेक टेम्प्लेट चयन गर्नुहोस्",
  Dashboard_Job_Salary:"आफ्नो जागिर सिर्जना गर्नुहोस् र आफ्नो तलब थप्नुहोस्",
  Dashboard_Employees:"कर्मचारी र भुक्तानीकर्ताहरू ट्र्याक गर्नुहोस्",
  Dashboard_Print:"तपाईँको पेचेकहरू छाप्नुहोस् र मेल गर्नुहोस्",
  Dashboard_Payroll:"तपाईँको पेरोल मुद्रण स्वचालित",
  Dashboard_PayPal:"Setup PayPal Payroll / Payouts",
  Begin_Setup:"सेटअप सुरु गर्नुहोस्",
  Get_Started:"सुरु गर",
  Purchase:"खरिद",
  Lockdown:"लकडाउन",
  Unlock:"अनलक",
  Detailed:"विस्तृत",
  Log_In:"लग - इन",
  Login:"लग - इन",
  Launch:"सुरुवात",
  Register:"दर्ता",
  Finish:"समाप्त",
  List:"सूची",
  Weekends:"सप्ताहांत",
  Weekly:"साप्ताहिक",
  PayPal_Default_Subject:"नयाँ भुक्तानी",
  Payment_Message:"भुक्तानी सन्देश",
  PayPal_Default_Payment_Note:"धन्यवाद",
  Setup_Your_Cheqing_Account:"चेकिङ खाता",
  Add_Your_Primary_Cheqing_Account:"तपाईँको प्राथमिक चेकिङ खाता थप्नुहोस्।",
  Welcome_Word:"पेरोल र मानव संसाधन व्यवस्थापनलाई सरल र स्वचालित बनाउनुहोस्।",
  Get_Started_Quickly:"केही सरल प्रश्नहरूको जवाफ दिनुहोस् जसले हामीलाई सुरु गर्न मद्दत गर्नेछ...",
  Done_Intro_Word:"सेटअप पूरा",
  PIN_Protected:"पासवर्ड र पिन सुरक्षित",
  Enter_New_PIN:"नयाँ पिन कोड प्रविष्ट गर्नुहोस्:",
  Enter_PIN:"पिन कोड प्रविष्ट गर्नुहोस्:",
  Invalid_PIN:"अमान्य PIN प्रविष्ट गरियो।",
  Account_Identifier:"खाता पहिचानकर्ता",
  New_Account_Identifier:"नयाँ खाता पहिचानकर्ता",
  attempt:"प्रयास",
  attempts:"प्रयास",
  remaining:"बाँकी",
  Language:"भाषा",
  languages:"भाषाहरु",
  select_languages:"भाषा छनोट गर्नुस",
  Deposit:"निक्षेप",
  Hire_One_Now:"अहिले भाडामा लिनुहोस्",
  App_Locked:"एप्लिकेशन लक गरिएको छ।",
  Skip_:"छोड्नुहोस्",
  Skip_to_Dashboard:"ड्यासबोर्डमा जानुहोस्",
  Dashboard:"ड्यासबोर्ड",
  Import:"आयात",
  Admin:"प्रशासकहरू",
  New_Admin:"नयाँ प्रशासक",
  Settings:"सेटिङ",
  Color_Picker:"रङ पिकर",
  Font_Picker:"फन्ट पिकर",
  Logout:"बाहिर निस्कनु",
  Close:"बन्द",
  Close_menu:"बन्द",
  Excel:"एक्सेल फाइल",
  Csv:"CSV फाइल",
  Sql:"SQL फाइल",
  Json:"JSON फाइल",
  Url:"URL द्वारा आयात गर्नुहोस्",
  Back:"पछाडि",
  Timers:"टाइमर",
  Cheque:"जाँच",
  Print:"छाप",
  Designs:"डिजाइनहरू",
  Pause_Printing:"पज प्रिन्टिङ",
  Resume_Printing:"रिजुम प्रिन्टिङ",
  Printing_Paused:"प्रिन्टिङ रोकियो",
  PrintingUnavailable:"माफ गर्नुहोस्! यो प्रणालीमा मुद्रण अनुपलब्ध छ।",
  Prints_Paused:"प्रिन्टहरू रोकियो",
  Administration:"प्रशासन",
  Loading:"लोड गर्दै",
  Unnamed:"अनाम",
  error:"माफ गर्नुहोस्, यो चेक हेर्नको लागि खोल्न सकिएन।",
  No_Cheques_To_Print:"प्रिन्ट गर्न कुनै चेक छैन",
  No_Cheques_To_Print_Word:"एक <a routerLink='/folder/Cheque/New'>नयाँ चेक</a> सिर्जना गर्नुहोस्।",
  New_Cheque:"नयाँ जाँच",
  Start_One_Now:"अहिले नै सुरु गर्नुहोस्",
  Edit_Cheque:"जाँच सम्पादन गर्नुहोस्",
  Select_Cheques:"चेकहरू चयन गर्नुहोस्",
  Select_Employee:"कर्मचारी चयन गर्नुहोस्",
  Default_Printer:"पूर्वनिर्धारित प्रिन्टर",
  Reassign:"पुन: असाइन गर्नुहोस्",
  Configure:"कन्फिगर",
  Template:"टेम्प्लेट",
  Designer:"डिजाइनर",
  Edit_Designs:"डिजाइनहरू सम्पादन गर्नुहोस्",
  New_Design:"नयाँ डिजाइन",
  Next:"अर्को",
  Save:"बचत",
  Name:"नाम",
  Mail:"पत्र",
  Amount:"रकम",
  Date:"मिति",
  PayPal:"PayPal",
  Payouts:"भुक्तानी",
  PayPal_Label:"PayPal लेबल",
  Email_Username:"इमेल / प्रयोगकर्ता नाम",
  Client_ID:"ग्राहक आईडी",
  Sandbox_Email:"स्यान्डबक्स इमेल",
  PayPal_Email:"PayPal इमेल",
  PayPal_Username:"एपीआई प्रयोगकर्ता नाम",
  PayPal_Payouts:"PayPal भुक्तानी",
  Select_PayPal_Key:"PayPal कुञ्जी चयन गर्नुहोस्",
  Secret:"गोप्य",
  API_Secret:"एपीआई गोप्य",
  PayPal_API_Keys:"PayPal कुञ्जी",
  New_PayPal_Key:"नयाँ PayPal कुञ्जी",
  Email_Subject:"इमेल विषय",
  Email_Message:"इमेल सन्देश",
  Payout_Options:"भुक्तानी विकल्प",
  Payment_Note:"भुक्तानी नोट",
  Enable_Employee:"कर्मचारी सक्षम गर्नुहोस्",
  Enable_Production_Mode:"उत्पादन मोड सक्षम गर्नुहोस्",
  Sandbox_Username:"स्यान्डबक्स प्रयोगकर्ता नाम",
  Sandbox_Signature:"स्यान्डबक्स हस्ताक्षर",
  Sandbox_Password:"स्यान्डबक्स पासवर्ड",
  Production_Username:"उत्पादन प्रयोगकर्ता नाम",
  Production_Signature:"उत्पादन हस्ताक्षर",
  Production_Password:"उत्पादन पासवर्ड",
  Production_Email:"उत्पादन इमेल",
  API_Client_ID:"एपीआई ग्राहक आईडी",
  API_Signature:"एपीआई हस्ताक्षर",
  API_Password:"एपीआई पासवर्ड",
  API_Username:"एपीआई प्रयोगकर्ता नाम",
  Secret_Key:"गोप्य कुञ्जी",
  Sandbox:"स्यान्डबक्स",
  Production:"उत्पादन",
  Sandbox_Keys:"स्यान्डबक्स कुञ्जी",
  Production_Keys:"उत्पादन कुञ्जी",
  API_Title:"एपीआई शीर्षक",
  Production_Mode:"उत्पादन मोड",
  Account_Label:"खाता लेबल",
  No_PayPal_Setup:"कुनै PayPal कुञ्जी छैन",
  Enable_PayPal_Account:"PayPal खाता सक्षम गर्नुहोस्",
  No_PayPal_Word:"तपाईंको <a routerLink='/folder/Invoice/New'>PayPal API कुञ्जी</a> थप्नुहोस्।",
  Printed_Memo:"मुद्रित मेमो",
  Employee:"कर्मचारी",
  View_Employee:"कर्मचारी हेर्नुहोस्",
  Mailing_Address:"पत्राचार ठेगाना",
  Company_Address:"कम्पनीको ठेगाना",
  Select_Company_Address:"कम्पनी ठेगाना चयन गर्नुहोस्",
  Address:"ठेगाना",
  Any_Day:"कुनै दिन",
  Address_Name:"ठेगाना नाम",
  Unit:"एकाइ",
  Account:"खाता",
  Bank_Account:"बैंक खाता",
  Account_Limits:"खाता सीमा सक्षम गर्नुहोस्",
  Payroll:"पैरोल",
  New_Payroll:"नयाँ पेरोल",
  No_Payroll:"कुनै आगामी पेरोल छैन",
  Upcoming_Holiday:"आगामी बिदा:",
  Invoice_Due:"इनभ्वाइस देय:",
  New_Invoice:"नयाँ इनभ्वाइस",
  No_Invoices:"कुनै बीजक छैन",
  No_Invoices_Word:"पहिलो <a routerLink='/folder/Invoice/New'>इनभ्वाइस</a> सिर्जना गर्नुहोस्।",
  Cheque_Due:"चेक देय:",
  Payrolls:"पेरोल",
  Sandbox_Mode:"स्यान्डबक्स मोड",
  Hire:"भाडा",
  Pay:"भुक्तानी",
  New:"नयाँ",
  Add:"थप्नुहोस्",
  Make:"बनाउनु",
  Time:"समय",
  Write:"लेख्नुहोस्",
  Holiday:"बिदा",
  Holidays:"बिदा",
  Next_Holiday:"अर्को छुट्टी:",
  All_Done:"सबै सकियो!",
  Employees:"कर्मचारीहरु",
  Payees:"भुक्तानी गर्नेहरू",
  Job:"नोकरी",
  Jobs:"नोकरी",
  Invoice:"इनभ्वाइस",
  Invoices:"इनभ्वाइसहरू",
  Vacations:"बिदा",
  Cheques:"जाँच",
  Brand_Cheques:"ब्रान्ड",
  Payment:"भुक्तानी",
  Enable_Payment:"भुक्तानी सक्षम गर्नुहोस्",
  Payments:"भुक्तानी",
  Schedule:"तालिका",
  Schedules:"तालिका",
  Timesheet:"समय पाना",
  Timesheets:"समय पाना",
  Salary:"तलब",
  New_Address:"नयाँ ठेगाना",
  Address_2:"ठेगाना (लाइन २)",
  _City:"शहर",
  _State:"राज्य/प्रोभ",
  City:"शहर / टाउनशिप",
  State:"राज्य / प्रदेश",
  Postal:"हुलाक / जिप कोड",
  ZIP:"पोस्टल / जिप",
  Country:"देश",
  Addresses:"ठेगाना",
  Required_Options:"आवश्यक विकल्प",
  Optional_Options:"वैकल्पिक विकल्प",
  Additional_Options:"अतिरिक्त विकल्प",
  Required:"आवश्यक",
  Optional:"वैकल्पिक",
  Additional:"अतिरिक्त",
  No_Addresses:"कुनै ठेगाना छैन",
  New_Address_Word:"पहिलो <a routerLink='/folder/Address/New'>ठेगाना</a> थप्नुहोस्।",
  Select_Address:"ठेगाना चयन गर्नुहोस्",
  No_Address:"कुनै ठेगाना छैन",
  Print_Cheque:"प्रिन्ट चेक",
  Print_Cheque_Now:"अब छाप्नुहोस् जाँच गर्नुहोस्",
  Description:"विवरण",
  Pay_To_The_Order_Of:"को अर्डरमा भुक्तान गर्नुहोस्:",
  Select_Date_Range:"मिति दायरा चयन गर्नुहोस्",
  Select_Starting_Date:"सुरु मिति चयन गर्नुहोस्",
  Select_Ending_Date:"समापन मिति चयन गर्नुहोस्",
  Select_Date:"मिति चयन गर्नुहोस्",
  Cheque_Date:"मिति जाँच गर्नुहोस्",
  Cheque_Memo:"मेमो जाँच गर्नुहोस्",
  Blank_Cheque:"खाली चेक",
  Blank:"रिक्त",
  No_Cheques:"कुनै चेक छैन",
  No_Cheques_Word:"तपाईंको पहिलो <a routerLink='/folder/Cheque/New'>चेक</a> छाप्नुहोस्।",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"छवि हेर्नुहोस्",
  View:"हेर्नुहोस्",
  Modify:"परिमार्जन",
  Delete:"मेटाउन",
  Cheque_Paid:"भुक्तानी",
  New_Deduction:"नयाँ कटौती",
  Title:"शीर्षक",
  Frequency:"आवृत्ति",
  Hourly:"घण्टा प्रति घण्टा",
  Daily:"दैनिक",
  Weekdays:"साप्ताहिक दिन",
  BiWeekly:"2 हप्ता",
  TriWeekly:"३ हप्ता",
  Monthly:"मासिक",
  MiMonthly:"२ महिना",
  Quarterly:"त्रैमासिक",
  Yearly:"वार्षिक",
  Every_Week:"हरेक हप्ता",
  Every_Payroll:"हरेक पेरोल",
  Every_Month:"हरेक महिना",
  Annually:"वार्षिक",
  Always_Scheduled:"सधैं अनुसूचित",
  Start_Date:"सुरू मिति",
  End_Date:"अन्त मिति",
  Start_Time:"सुरु समय",
  End_Time:"अन्त समय",
  Deduction_Label:"कटौती लेबल",
  Notes:"नोटहरू",
  Options:"विकल्प",
  Enable:"सक्षम गर्नुहोस्",
  Select_Deductions:"कटौतीहरू चयन गर्नुहोस्",
  Deductions:"कटौती",
  No_Deductions:"कुनै कटौती छैन",
  No_Deductions_Word:"तपाईंको पहिलो <a routerLink='/folder/Deduction/New'>कटौती</a> सिर्जना गर्नुहोस्।",
  New_Employee:"नयाँ कर्मचारी",
  No_Title:"शीर्षक छैन",
  _Name:"नाम",
  About_Yourself:"आफ्नो बारेमा",
  Full_Name:"पुरा नाम",
  Birthday:"जन्मदिन",
  Email:"इमेल",
  SMS:"एस एम एस",
  Phone:"फोन",
  Test:"परीक्षण",
  Call:"कल",
  Fax:"फ्याक्स",
  Printed_Note:"मुद्रित नोट",
  Position:"स्थिति",
  Job_Position:"जागिर पद",
  Select_a_Job:"एक काम चयन गर्नुहोस्",
  Select_a_Salary:"तलब छान्नुहोस्",
  Add_a_Deduction:"कटौती थप्नुहोस्",
  Taxes:"कर",
  Add_Taxes:"कर थप्नुहोस्",
  Date_of_Birth:"जन्म मिति",
  Email_Address:"इ - मेल ठेगाना",
  Phone_Number:"फोन नम्बर",
  Phone_Call:"फोन कल",
  Enable_on_Payroll:"पेरोलमा सक्षम गर्नुहोस्",
  Select_Employees:"कर्मचारीहरू चयन गर्नुहोस्",
  No_Employees:"कर्मचारी छैन",
  No_Employees_Word:"तपाईंको पहिलो नयाँ <a routerLink='/folder/Employee/New'>कर्मचारी</a> थप्नुहोस्।",
  No_Name:"नाम छैन",
  Unemployeed:"बेरोजगार",
  Employeed:"रोजगार",
  Paid:"भुक्तानी",
  Enabled:"सक्षम",
  Disabled:"अक्षम",
  Fire:"आगो",
  Not_Available:"उपलब्ध छैन",
  Not_Available_Word:"तपाईँको पहिलो <a routerLink='/folder/Invoice/New'>इनभ्वाइस</a> छाप्नुहोस् र भुक्तान गर्नुहोस्।",
  Select_Invoices:"चयन_इनभ्वाइसहरू",
  Print_Invoice_Word:"तपाईँको पहिलो <a routerLink='/folder/Invoice/New'>इनभ्वाइस</a> छाप्नुहोस् र भुक्तान गर्नुहोस्।",
  Invoice_Title:"इनभ्वाइस शीर्षक",
  Invoice_Date:"इनभ्वाइस मिति",
  Due_Date:"समय मिति",
  New_Job:"नयाँ जागीर",
  Details:"विवरण",
  Customize:"अनुकूलन",
  Customize_Dashboard:"ड्यासबोर्ड अनुकूलित गर्नुहोस्",
  Components:"घटकहरू",
  No_Components:"कुनै कम्पोनेन्ट छैन",
  Main_Components:"मुख्य अवयवहरू",
  Smaller_Components:"साना अवयवहरू",
  Error_Loading_Page:"यो पृष्ठ लोड गर्दा त्रुटि।",
  Bank_Details:"बैंक विवरण",
  About_Your_Job:"तपाईंको कामको बारेमा",
  Your_Schedule:"तपाईको तालिका",
  Job_Title:"नोकरी शीर्षक",
  Job_Description:"कामको विवरण",
  Job_Details:"कामको विवरण",
  Enable_Job:"कार्य सक्षम गर्नुहोस्",
  Pay_Period:"भुक्तानी अवधि",
  Perpetually_Schedule:"सधैं अनुसूची",
  Select_Jobs:"कार्य चयन गर्नुहोस्",
  No_Jobs:"नोकरी छैन",
  Add_Jobs:"काम थप्नुहोस्",
  No_Jobs_Word:"सूचीमा पहिलो <a routerLink='/folder/Job/New'>जागिर</a> थप्नुहोस्।",
  Count_Employees:"job.employee_count+' कर्मचारीहरु'",
  Zero_Employees:"० कर्मचारी",
  New_Leave:"नयाँ बिदा",
  Leave_Name:"नाम छोड्नुहोस्",
  Paid_Leave:"भुक्तानी बिदा",
  Leave_Pay:"भुक्तानी छोड्नुहोस्",
  Indefinite_Leave:"अनिश्चित बिदा",
  Indefinite_Payroll:"अनिश्चित वेतन",
  Leave:"छोड",
  Add_Schedules:"तालिका थप्नुहोस्",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"अनुपस्थिति सक्षम गर्नुहोस्",
  Select_Leaves:"पातहरू चयन गर्नुहोस्",
  No_Leaves:"अनुपस्थितिको कुनै पात छैन",
  Leave_Of_Absence:"अनुपस्थिति बिदा",
  Leaves_Of_Absence:"अनुपस्थिति को पात",
  New_Leave_of_Absense:"नयाँ अनुपस्थिति बिदा",
  No_Leaves_Word:"पहिलो <a routerLink='/folder/Leave/New'>अनुपस्थिति बिदा</a> थप्नुहोस्।",
  Not_Enabled:"सक्षम गरिएको छैन",
  Absences:"अनुपस्थिति",
  Payee:"भुक्तानी",
  New_Payee:"नयाँ भुक्तानीकर्ता",
  Payee_Identifier:"भुक्तानी पहिचानकर्ता",
  Payee_Name:"भुक्तानी गर्ने नाम",
  Payee_Title:"भुक्तानी शीर्षक",
  Payment_Memo:"भुक्तानी मेमो",
  Select_Payees:"भुक्तानीहरू चयन गर्नुहोस्",
  No_Payees:"भुक्तानी छैन",
  Add_Payee_Note:"पहिलो <a routerLink='/folder/Payee/New'>भुक्तानी गर्ने</a> थप्नुहोस्।",
  New_Payees:"नयाँ भुक्तानीकर्ताहरू",
  About_The_Payment_Schedule:"भुक्तानी तालिका",
  Your_Payroll_Schedule:"स्वचालित पेरोल",
  New_Payment:"नयाँ भुक्तानी",
  Identifier:"पहिचानकर्ता",
  Select:"चयन",
  Memo:"मेमो",
  Payment_Date:"भुक्तानी मिति",
  Mark_as_Paid:"भुक्तानीको रूपमा चिन्ह लगाउनुहोस्",
  Select_Payments:"भुक्तानी चयन गर्नुहोस्",
  No_Payments:"कुनै भुक्तानी छैन",
  No_Payments_Word:"पहिलो <a routerLink='/folder/Payment/New'>भुक्तानी</a> सिर्जना गर्नुहोस्।",
  Create_Payroll:"पेरोल सिर्जना गर्नुहोस्",
  Properties:"गुण",
  Payroll_Title:"पेरोल शीर्षक",
  Payroll_Notes:"पेरोल नोटहरू",
  Payroll_Setup:"पेरोल सेटअप",
  Tabulate_Payments:"भुक्तानी तालिका",
  Tabulate:"तालिका",
  By:"द्वारा:",
  Payments_By:"द्वारा भुक्तानी",
  Timesheets_And_Schedules:"समय पाना र तालिका",
  Both:"दुबै",
  Items:"वस्तुहरू",
  Add_Payees:"भुक्तानीहरू थप्नुहोस्",
  Add_Account:"खाता थप्नुहोस्",
  Enable_Account:"खाता सक्षम गर्नुहोस्",
  Enable_Payroll:"पेरोल सक्षम गर्नुहोस्",
  Print_Payroll:"पेरोल छाप्नुहोस्",
  No_Payrolls:"नो पेरोल",
  No_Payroll_Word:"तपाईंको पहिलो <a routerLink='/folder/Payroll/New'>तलब</a> सिर्जना गर्नुहोस्।",
  View_more:"थप हेर्नुहोस्",
  Less:"थोरै",
  Add_Payroll:"पेरोल थप्नुहोस्",
  Select_Payroll:"पेरोल चयन गर्नुहोस्",
  About_Your_Salary:"तपाईको तलबको बारेमा",
  Add_Salaries:"तलब थप्नुहोस्",
  Add_Salary:"तलब थप्नुहोस्",
  Salaries:"तलब",
  No_Salaries:"तलब छैन",
  No_Salaries_Word:"पहिलो <a routerLink='/folder/Salary/New'>तलब</a> थप्नुहोस्।",
  Select_Salaries:"तलब चयन गर्नुहोस्",
  New_Salary:"नयाँ तलब",
  Salary_Name:"तलब पहिचानकर्ता",
  Enable_Salary:"तलब सक्षम गर्नुहोस्",
  Salary_Amount:"तलब रकम",
  New_Schedule:"नयाँ तालिका",
  Schedule_Title:"तालिका शीर्षक",
  Add_Address:"ठेगाना थप्नुहोस्",
  Repeat:"दोहोर्याउनुहोस्",
  Design:"डिजाइन",
  Edit_Design:"डिजाइन सम्पादन गर्नुहोस्",
  Edit_this_Design:"यो डिजाइन सम्पादन गर्नुहोस्",
  Repeat_Payment:"दोहोर्याउनुहोस् भुक्तानी",
  Enable_Schedule:"तालिका सक्षम गर्नुहोस्",
  Never:"कहिल्यै होइन",
  Select_Schedule:"तालिका चयन गर्नुहोस्",
  No_Schedules:"कुनै तालिका छैन",
  No_Schedules_Word:"पहिलो <a routerLink='/folder/Schedule/New'>तालिका</a> सिर्जना गर्नुहोस्।",
  New_Administrator:"नयाँ प्रशासक",
  Username:"प्रयोगकर्ता नाम",
  First_Name:"पहिलो नाम",
  Last_Name:"थर",
  Add_an_Address:"ठेगाना थप्नुहोस्",
  Payment_Limit:"प्रति-भुक्तानी सीमा",
  Total_Limit:"कुल सीमा",
  Select_Accounts:"खाताहरू चयन गर्नुहोस्",
  No_Administrators:"प्रशासकहरू छैनन्",
  No_Administrators_Word:"पहिलो <a routerLink='/folder/Administrator/New'>व्यवस्थापक खाता</a> सिर्जना गर्नुहोस्।",
  New_Administrators_Word:"पहिलो <a routerLink='/folder/Administrator/New'>प्रशासक</a> थप्नुहोस्",
  Cloud:"बादल",
  Backup:"ब्याकअप",
  Enable_iCloud:"iCloud सक्षम गर्नुहोस्",
  Enable_Google_Drive:"Google ड्राइभ सक्षम गर्नुहोस्",
  Enable_Microsoft_OneDrive:"Microsoft OneDrive सक्षम गर्नुहोस्",
  Automatically_Backup:"स्वचालित रूपमा ब्याकअप",
  FTP_Settings:"FTP सेटिङ",
  URL_File_Prompt:"कृपया आयात गर्नको लागि .xls / .xlsx / .json फाइलको लागि स्थान निर्दिष्ट गर्नुहोस्:",
  URL_SQL_Prompt:"इम्पोर्ट गर्नको लागि SQLite फाइलको स्थान निर्दिष्ट गर्नुहोस्:",
  FTP_Backup:"FTP ब्याकअप",
  FTP_Import:"FTP आयात",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"होस्ट",
  Port:"पोर्ट",
  Path:"मार्ग",
  Data_Path:"डेटा पथ",
  Enable_FTP_Account:"FTP खाता सक्षम गर्नुहोस्",
  HostnameIP:"होस्टनाम",
  Password:"पासवर्ड",
  Connection_Port:"जडान पोर्ट",
  Enable_MySQL_Database:"MySQL डाटाबेस सक्षम गर्नुहोस्",
  Log:"लग",
  Reset:"रिसेट",
  Erase:"मेट्नुहोस्",
  Export:"निर्यात",
  Database:"डाटाबेस",
  Upload_CSV:"CSV अपलोड गर्नुहोस्",
  Download_CSV:"CSV डाउनलोड गर्नुहोस्",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL ब्याकअप",
  Internal_Notes:"आन्तरिक नोटहरू",
  Root_Path:"मूल मार्ग",
  Select_Backup:"ब्याकअप चयन गर्नुहोस्",
  Add_New_Backup:"नयाँ ब्याकअप थप्नुहोस्",
  First_Backup:"पहिलो ब्याकअप सेटअप गर्नुहोस्...",
  Backups:"ब्याकअप",
  Add_Backup:"ब्याकअप थप्नुहोस्",
  No_Backups:"फेला पार्ने कुनै ब्याकअपहरू छैनन्।",
  Select_Backup_Type:"तपाईंले सेटअप गर्न चाहनुभएको ब्याकअपको प्रकार चयन गर्नुहोस्...",
  Backup_Type:"ब्याकअप प्रकार",
  FTP_Drive:"FTP ड्राइभ",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"ड्राइभ",
  Microsoft_OneDrive:"ड्राइभ",
  Import_Fields:"आयात क्षेत्रहरू",
  Import_Fields_Word:"<a routerLink='/folder/Holiday/New'>आयात</a> डेटा चयन गर्न यो खण्ड प्रयोग गर्नुहोस्।",
  Upload:"अपलोड",
  Download:"डाउनलोड",
  Download_JSON:"JSON डाटाको रूपमा डाउनलोड गर्नुहोस्",
  Download_SQL:"SQL फाइलको रूपमा डाउनलोड गर्नुहोस्",
  New_Bank:"नयाँ बैंक",
  New_Account:"नयाँ खाता",
  New_Bank_Account:"नयाँ बैंक खाता",
  Upload_Image:"छवि अपलोड गर्नुहोस्",
  Bank_Name:"बैंकको नाम",
  Bank_Address:"बैंक ठेगाना",
  Branch_Address:"शाखा ठेगाना",
  Address_on_Cheque:"ठेगाना",
  Cheque_Numbers:"नम्बरहरू जाँच गर्नुहोस्",
  Cheque_Details:"विवरणहरू जाँच गर्नुहोस्",
  Bank_Logo:"बैंक लोगो",
  Cheque_ID:"आईडी जाँच गर्नुहोस्",
  Starting_Cheque_ID:"जाँच आईडी सुरु गर्दै",
  Transit_Number:"ट्रान्जिट नम्बर",
  Institution_Number:"संस्था नम्बर",
  Designation_Number:"पदनाम नम्बर",
  Account_Number:"खाता नम्बर",
  Limits:"सीमा",
  Default_Limits:"पूर्वनिर्धारित सीमाहरू",
  Over_Limit:"सीमा भन्दा बढी",
  Under_Limit:"सीमा अन्तर्गत",
  Payroll_Limit:"भुक्तानी सीमा",
  Enable_Bank_Account:"बैंक खाता सक्षम गर्नुहोस्",
  Select_Account:"खाता चयन गर्नुहोस्",
  No_Bank_Account:"बैंक खाता छैन",
  No_Bank_Account_Word:"पहिलो <a routerLink='/folder/Accounts/New'>बैंक खाता</a> थप्नुहोस्।",
  Bank_Accounts:"बैंक खाता",
  No_Accounts:"कुनै खाता छैन",
  No_Accounts_Note:"पहिलो <a routerLink='/folder/Accounts/New'>बैंक खाता</a> थप्नुहोस्।",
  Cheque_Designer:"डिजाइनर जाँच गर्नुहोस्",
  Cheque_Design:"डिजाईन जाँच गर्नुहोस्",
  Select_Design:"एक डिजाइन चयन गर्नुहोस्",
  Cheque_Designs:"डिजाइनहरू जाँच गर्नुहोस्",
  No_Cheque_Designs:"कुनै चेक डिजाइन छैन",
  No_Cheque_Designs_Word:"आफ्नो आफ्नै <a routerLink='/folder/Settings/Cheque/Design/New'>डिजाइन जाँच गर्नुहोस्</a> सिर्जना गर्नुहोस्।",
  Set_Default:"पूर्वनिर्धारित रूपमा सेट गर्नुहोस्",
  Default:"पूर्वनिर्धारित",
  Remove:"हटाउनु",
  Folder:"फोल्डर",
  Edit:"सम्पादन",
  LoadingDots:"लोड गर्दै...",
  Add_a_New_File:"एउटा <a href='#' (क्लिक)='add()'>नयाँ फाइल</a> थप्नुहोस्",
  this_folder:"यो फोल्डर",
  FTP_Backup_Settings:"FTP ब्याकअप सेटिङ",
  Secure_File_Transfer:"सुरक्षित फाइल स्थानान्तरण",
  New_Holiday:"नयाँ छुट्टी",
  Add_Holiday:"बिदा थप्नुहोस्",
  Holiday_Name:"बिदाको नाम",
  Additional_Pay:"अतिरिक्त तलब",
  Enable_Holiday:"बिदा सक्षम गर्नुहोस्",
  Select_Holidays:"बिदाहरू चयन गर्नुहोस्",
  No_Holidays:"कुनै बिदा छैन",
  No_Holidays_Word:"पहिलो <a routerLink='/folder/Holiday/New'>सार्वजनिक विदा</a> थप्नुहोस्।",
  New_Import:"नयाँ आयात",
  Import_Data:"डाटा आयात गर्नुहोस्",
  Import_Data_Word:"तपाईको छनौटको फाइल वा अपलोड विधिको प्रकार चयन गर्नुहोस्।<br /> तपाईंले समर्थित फाइल अपलोड गरेपछि एपमा जुनसुकै प्यारामिटरसँग मेल खाने फाइलमा आयात गरिएका क्षेत्रहरू चयन गर्न सक्षम हुनुहुनेछ ।", 
  Import_File:"फाइल आयात गर्नुहोस्",
  Link_To_File:"फाइलमा लिङ्क",
  Select_File:"फाइल चयन गर्नुहोस्",
  New_Moderator:"नयाँ मध्यस्थ",
  Allow_Moderation:"मध्यस्थता अनुमति दिनुहोस्",
  Create_Paycheques:"पेचेकहरू सिर्जना गर्नुहोस्",
  Edit_Paycheques_and_Data:"पेचेक र डाटा सम्पादन गर्नुहोस्",
  Destroy_Data_and_Paycheques:"डेटा र पेचेकहरू नष्ट गर्नुहोस्",
  Bonus_Percentage:"पेचेक प्रतिशत",
  Fixed_Amount:"निश्चित रकम",
  Select_Moderator:"मध्यस्थ चयन गर्नुहोस्",
  No_Moderators:"कुनै मध्यस्थकर्ता छैन",
  Moderators:"मध्यस्थहरू",
  Moderator_Account:"पहिलो <a routerLink='/folder/Administrator/New'>मध्यस्थ खाता</a> सिर्जना गर्नुहोस्।",
  No_Moderators_Word:"पहिलो <a routerLink='/folder/Administrator/New'>मध्यस्थ</a> थप्नुहोस्।",
  Defaults:"पूर्वनिर्धारित",
  Provide_Us_Info:"हामीलाई जानकारी प्रदान गर्नुहोस्",
  Setup_Your_Printer:"तपाईँको प्रिन्टर सेटअप गर्नुहोस्",
  Your_Company:"तपाईंको कम्पनीको बारेमा",
  Company_Name:"कम्पनी नाम",
  Currency:"मुद्रा",
  Default_Currency:"पूर्वनिर्धारित मुद्रा",
  Base_Monthly_Income:"महिनाको आम्दानी",
  Protection:"संरक्षण",
  App_Protection:"एप संरक्षण",
  PIN_Code_Protection:"पिन कोड सुरक्षा",
  App_Protection_Word:"तपाईंको खाता सुरक्षित गर्न मद्दत गर्ने सुरक्षा विधिहरू सक्षम गर्नुहोस्।",
  PIN_Code:"पिन कोड",
  Change_PIN:"पिन परिवर्तन गर्नुहोस्",
  New_Password:"नया पासवर्ड",
  Geofence_Protection:"जियो-फेन्स संरक्षण",
  Geofence_Area:"क्षेत्र सेट गर्नुहोस्",
  Distance:"दूरी",
  Setup_Now:"अहिले सेटअप गर्नुहोस्",
  Mile:"माइल",
  Km:"किमि",
  m:"m",
  Facial_Recognition:"अनुहार पहिचान",
  Face:"अनुहार",
  Setup:"सेटअप",
  Label:"लेबल",
  Password_Protection:"पासवर्ड सुरक्षा",
  Modify_Password:"पासवर्ड परिमार्जन गर्नुहोस्",
  New_Tax_Entry:"नयाँ कर प्रविष्टि",
  New_Tax:"नयाँ कर",
  Tax_Label:"कर लेबल",
  Perpetually_Enabled:"सधैं सक्षम",
  Select_Taxes:"करहरू चयन गर्नुहोस्",
  Tax_Deductions:"कर कटौती",
  No_Tax_Deductions:"कुनै कर कटौती छैन",
  No_Tax_Deductions_Word:"पहिलो <a routerLink='/folder/Tax/New'>कर</a> कटौती थप्नुहोस्।",
  New_Timer:"नयाँ टाइमर",
  Start:"सुरुवात",
  Stop:"रोक",
  Start_Timer:"टाइमर सुरु गर्नुहोस्",
  Stop_Timer:"टाइमर रोक्नुहोस्",
  Timer_Active:"टाइमर सक्रिय",
  Timer:"टाइमर:",
  Timer_Running:"टाइमर: (दौडिरहेको)",
  Save_Timer:"टाइमर बचत गर्नुहोस्",
  New_Timesheet:"नयाँ समय पाना",
  Select_Timesheets:"समय पानाहरू चयन गर्नुहोस्",
  Work_Notes:"कार्य नोट",
  Entry_Title:"प्रवेश शीर्षक",
  No_Timesheets:"कुनै समय पाना छैन",
  No_Timesheets_Word:"पहिलो <a routerLink='/folder/Timesheet/New'>समय पाना</a> थप्नुहोस्।",
  Timesheet_Submitted:"समय पाना पेश गरियो",
  Timesheet_Congrats:"बधाई छ! तपाईको समय पाना सफलतापूर्वक पेस गरिएको छ। धन्यवाद!",
  Timesheet_Copy:"तपाईंको कागजातहरूको प्रतिलिपि प्राप्त गर्न कृपया हामीलाई तपाईंको इमेल ठेगाना र/वा मोबाइल फोन नम्बर प्रदान गर्नुहोस्।",
  Submit:"सबमिट",
  Allow_Notifications:"सूचनाहरूलाई अनुमति दिनुहोस्",
  Untitled_Entry:"नयाँ प्रविष्टि",
  Untitled_Bank:"शीर्षकविहीन बैंक",
  Timesheet_Entry:"समय पाना प्रविष्टि",
  Work_Description:"काम विवरण",
  Enable_Timesheet:"समय पाना सक्षम गर्नुहोस्",
  Submit_Timesheet:"समय पाना पेश गर्नुहोस्",
  Vacation:"विदा",
  New_Vacation:"नयाँ छुट्टी",
  Vacation_Name:"बिदाको नाम",
  Paid_Vacation:"भुक्तानी बिदा",
  Vacation_Pay:"बिदा भुक्तान",
  Enable_Vacation:"बिदा सक्षम गर्नुहोस्",
  Select_Vacations:"विदाहरू चयन गर्नुहोस्",
  No_Vacations:"कुनै बिदा छैन",
  No_Vacations_Word:"पहिलो <a routerLink='/folder/Vacation/New'>विदा</a> प्रविष्टि सिर्जना गर्नुहोस्।",
  Payroll_Schedule:"भुक्तानी तालिका",
  Next_Payroll:"अर्को पेरोल:",
  Upcoming_Payroll:"आगामी पेरोल",
  No_Upcoming_Payroll:"कुनै आगामी पेरोल छैन",
  Address_Book:"ठेगाना पुस्तिका",
  Archived_Documents:"संग्रहित कागजातहरू",
  Dev_Mode:"विकास मोडमा आवेदन",
  Administrators:"प्रशासकहरू",
  Privacy:"गोपनीयता",
  None:"कुनै पनि छैन",
  Select_Signature:"हस्ताक्षर चयन गर्नुहोस्",
  No_Signatures:"कुनै हस्ताक्षर छैन",
  No_Signatures_Word:"पहिलो <a routerLink='/folder/Signature/New'>हस्ताक्षर</a> थप्नुहोस्।",
  Repeat_Indefinitely:"अनिश्चित रूपमा दोहोर्याउनुहोस्",
  Sign:"साइन",
  Sign_Here:"यहाँ साइन इन गर्नुहोस्",
  Date_Signed:"हस्ताक्षर गरिएको मिति",
  Signature_Pad:"सिग्नेचर प्याड",
  Account_Holder:"खाता धारक",
  Account_Properties:"खाता गुण",
  Name_On_Cheque:"चेकमा नाम",
  Server_Hostname:"सर्भर होस्टनाम / आईपी",
  Printers:"मुद्रकहरू",
  No_Printers:"कुनै प्रिन्टर छैन",
  Printer_Exists:'यस नामको प्रिन्टर पहिले नै अवस्थित छ।',
  No_Printers_Word:"पहिलो नै <a routerLink='/folder/Printer/New'>प्रिन्टर</a> थप्नुहोस्।",
  No_Printer_Alert:"कुनै प्रिन्टर परिभाषित गरिएको छैन। के तपाइँ प्रिन्टर सेटअप गर्न चाहनुहुन्छ?",
  Add_Printer:"प्रिन्टर थप्नुहोस्",
  New_Printer:"नयाँ प्रिन्टर",
  Printer_Hostname:"प्रिन्टर होस्टनाम / IP",
  Printer_Label:"प्रिन्टर लेबल",
  Printer_Protocol:"प्रिन्टर प्रोटोकल",
  Protocol:"प्रोटोकल",
  Email_Print:"इमेल",
  AirPrint:"एयरप्रिन्ट",
  IPP:"आईपीपी",
  LPD:"LPD",
  HPJetDirect:"सकेट",
  Print_Job:"प्रिन्ट कार्य",
  Printed:"मुद्रित",
  Not_Printed:"छापिएको छैन",
  Print_Jobs:"प्रिन्ट कार्य",
  Print_Queue:"प्रिन्ट लाम",
  No_Print_Jobs:"कुनै छाप्ने काम छैन",
  No_Prints:"छाप्नको लागि नयाँ <a routerLink='/folder/Cheque/New'>चेक</a> सिर्जना गर्नुहोस्।",
  Prints:"प्रिन्टहरू",
  Find_Printer:"मुद्रक खोज्नुहोस्",
  Find_AirPrint_Devices:"एयरप्रिन्ट यन्त्रहरू फेला पार्नुहोस्",
  Select_Printer:"प्रिन्टर चयन गर्नुहोस्",
  System_UI:"प्रणाली UI",
  Printer:"मुद्रक",
  Status:"स्थिति",
  Preview:"पूर्वावलोकन",
  Enable_Print_Job:"प्रिन्ट कार्य सक्षम गर्नुहोस्",
  Queue_Weight:"लाम वजन",
  Unlimited:"असीमित",
  Show_Advanced_Printer_Options:"उन्नत प्रिन्टर विकल्पहरू देखाउनुहोस्",
  Advanced:"उन्नत",
  Location:"स्थान",
  Note:"नोट",
  Queue_Name:"लाम नाम",
  Pages_Printed_Limit:"पृष्ठ मुद्रित सीमा",
  MultiPage_Idle_Time:"बहु पृष्ठ प्रतीक्षा समय (हरू)",
  Page_Count_Limit:"पृष्ठ गणना सीमा",
  Page_Orientation:"पृष्ठ अभिमुखीकरण",
  Portrait:"पोर्ट्रेट",
  Landscape:"ल्याण्डस्केप",
  Duplex:"डुप्लेक्स",
  Double_Sided_Printing:"दुइतर्फी",
  Duplex_Mode:"डुप्लेक्स मोड",
  Duplex_Short:"छोटो",
  Duplex_Long:"लामो",
  Duplex_None:"कुनै पनि छैन",
  Color_And_Quality:"रङ र गुणस्तर",
  Monochrome:"मोनोक्रोम",
  Photo_Quality_Prints:"फोटो गुणस्तर प्रिन्ट",
  Printer_Email:"प्रिन्टर इमेल",
  Automatically_Downsize:"स्वचालित रूपमा डाउनसाइज",
  Paper:"कागज",
  Paper_Name:"कागजको नाम",
  Paper_Width:"कागज चौडाइ",
  Paper_Height:"कागजको उचाइ",
  Paper_Autocut_Length:"कागज स्वतः काट्ने लम्बाइ",
  Margins:"मार्जिन",
  Page_Margins:"पृष्ठ मार्जिन",
  Page_Margin_Top:"शीर्ष पृष्ठ मार्जिन",
  Page_Margin_Right:"दायाँ पृष्ठ मार्जिन",
  Page_Margin_Bottom:"तल पृष्ठ मार्जिन",
  Page_Margin_Left:"बायाँ पृष्ठ मार्जिन",
  Add_Employees:"कर्मचारी थप्नुहोस्",
  Header:"हेडर",
  Print_A_Page_Header:"एक पृष्ठ हेडर छाप्नुहोस्",
  Header_Label:"हेडर लेबल",
  Header_Page_Index:"हेडर पृष्ठ अनुक्रमणिका",
  Header_Font_Name:"हेडर फन्ट",
  Select_Font:"फन्ट चयन गर्नुहोस्",
  Font_Selector:"फन्ट चयनकर्ता",
  Header_Font_Size:"हेडर फन्ट",
  Header_Bold:"हेडर बोल्ड",
  Header_Italic:"हेडर इटालिक",
  Header_Alignment:"हेडर पङ्क्तिबद्धता",
  Left:"बायाँ",
  Center:"केन्द्र",
  Right:"सही",
  Justified:"उचित",
  Header_Font_Color:"हेडर रङ",
  Select_Color:"रङ चयन गर्नुहोस्",
  Footer:"फुटर",
  Print_A_Page_Footer:"एक पृष्ठ फुटर छाप्नुहोस्",
  Footer_Label:"फुटर लेबल",
  Footer_Page_Index:"फुटर पृष्ठ अनुक्रमणिका",
  Footer_Font_Name:"फुटर फन्ट",
  Fonts:"फन्ट",
  Done:"सम्पन्न",
  Select_Fonts:"फन्टहरू चयन गर्नुहोस्",
  Footer_Font_Size:"फुटर साइज",
  Footer_Bold:"फुटर बोल्ड",
  Footer_Italic:"फुटर इटालिक",
  Footer_Alignment:"फुटर पङ्क्तिबद्धता",
  Footer_Font_Color:"फुटर रङ",
  Page_Copies:"पृष्ठ प्रतिलिपिहरू",
  Enable_Printer:"प्रिन्टर सक्षम गर्नुहोस्",
  Remote_Logging:"रिमोट लगिङ",
  Log_Server:"लग सर्भर",
  Encryption:"इन्क्रिप्सन",
  Random_Key:"अनियमित कुञ्जी",
  Encryption_Key:"इन्क्रिप्सन कुञ्जी",
  Cheques_Webserver:"कस्टम डाटाबेस",
  Learn_How:"कसरी जान्नुहोस्",
  Signature:"हस्ताक्षर",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"हस्ताक्षर हेर्नुहोस्",
  Printed_Signature:"मुद्रित हस्ताक्षर",
  Digitally_Sign:"डिजिटल साइन",
  Digital_Signature:"डिजिटल हस्ताक्षर",
  Add_Signature:"हस्ताक्षर थप्नुहोस्",
  Add_Your_Signature:"तपाईंको हस्ताक्षर थप्नुहोस्",
  Enable_Signature:"हस्ताक्षर सक्षम गर्नुहोस्",
  Digitally_Signed:"डिजिटल हस्ताक्षरित",
  Insert_Error:"डेटाबेस समस्याहरूको कारण चेक बचत गर्न असमर्थ।",
  Delete_Confirmation:"के तपाइँ यो जानकारी मेटाउन निश्चित हुनुहुन्छ?",
  Discard_Confirmation:"के तपाइँ यो जानकारी खारेज गर्न निश्चित हुनुहुन्छ?",
  Discard_Bank_Confirmation:"के तपाइँ यो खाता खारेज गर्न निश्चित हुनुहुन्छ?",
  Discard_Printer_Confirmation:"के तपाइँ यो प्रिन्टर खारेज गर्न निश्चित हुनुहुन्छ?",
  Delete_Bonus_Confirmation:"के तपाइँ यो बोनस मेटाउन निश्चित हुनुहुन्छ?",
  Delete_Invoice_Confirmation:"के तपाइँ यो इनभ्वाइस मेटाउन निश्चित हुनुहुन्छ?",
  Generated_Cheque:"उत्पन्न चेक",
  Generated_Invoice:"उत्पन्न बीजक",
  Schedule_Start_Time:"सुरुको समय तालिका",
  Schedule_End_Time:"तालिका अन्त्य",
  New_Call:"नयाँ कल",
  No_Contacts:"कुनै सम्पर्क छैन",
  No_Contacts_Word:"प्रशासकहरू, मध्यस्थकर्ताहरू, कर्मचारीहरू, र भुक्तानीकर्ताहरू सम्पर्कको रूपमा देखा पर्दछन्।",
  PDF_Subject:"वित्त",
  PDF_Keywords:"पेरोल पेचेक PDF चेक चेक",
  Printer_Setup:"प्रिन्टर सेटअप",
  Printer_Selection:"मुद्रक चयन",
  New_Fax:"नयाँ फ्याक्स",
  New_Fax_Message:"नयाँ फ्याक्स सन्देश",
  Fax_Machine:"फ्याक्स मेसिन",
  Fax_Name:"फ्याक्स नाम",
  Fax_Email:"फ्याक्स इमेल",
  Fax_Number:"फ्याक्स नम्बर",
  Contents:"सामग्री",
  Fax_Body:"पृष्ठको मुख्य भाग",
  Header_Word:"हेडर:",
  Header_Text:"हेडर पाठ",
  Include_Header:"हेडर समावेश गर्नुहोस्",
  Include_Footer:"फुटर समावेश गर्नुहोस्",
  Footer_Word:"फुटर:",
  Footer_Text:"फुटर पाठ",
  Attach_a_Cheque:"चेक संलग्न गर्नुहोस्",
  Add_Deduction:"कटौती थप्नुहोस्",
  Enable_Fax:"फ्याक्स पठाउनुहोस्",
  Select_Fax:"फ्याक्स चयन गर्नुहोस्",
  No_Faxes:"कुनै फ्याक्स छैन",
  Faxes:"फ्याक्स",
  Save_and_Send:"फ्याक्स पठाउनुहोस्",
  Save_and_Pay:"बचत र भुक्तान",
  WARNING:"चेतावनी:",
  Remember:"सम्झनु",
  Printing:"मुद्रण",
  Printing_Complete:"प्रिन्टिङ पूरा भयो!\n\n",
  of:"को",
  There_Were:"थियो",
  Successful_Prints:"सफल प्रिन्ट र",
  Unsuccessful_Prints:"असफल प्रिन्टहरू।",
  PrinterError:"माफ गर्नुहोस्! त्यहाँ त्रुटि थियो।",
  Printing_:"छाप्दै...",
  PrinterSuccess:"कागजात सफलतापूर्वक छापियो।",
  PrintersSuccess:"कागजातहरू सफलतापूर्वक छापियो।",
  Print_Cheques:"प्रिन्ट चेक",
  New_Message:"नयाँ सन्देश",
  New_Messages:"नयाँ सन्देशहरू",
  Read_Message:"सन्देश पढ्नुहोस्",
  Write_Message:"सन्देश लेख्नुहोस्",
  Send_Message:"सन्देश पठाउनुहोस्",
  Subject:"विषय",
  Message:"सन्देश",
  Writing:"लेख्दै...",
  Send:"पठाउनुहोस्",
  Set_Date:"मिति सेट गर्नुहोस्",
  Set_Time:"समय सेट गर्नुहोस्",
  Recieve:"प्राप्त",
  Set_as_Default:"पूर्वनिर्धारित रूपमा सेट गर्नुहोस्",
  Default_Account:"पूर्वनिर्धारित खाता",
  Default_Design:"पूर्वनिर्धारित डिजाइन",
  Multiple_Cheques:"जाँच (ट्रिपल)",
  Account_Mode:"खाता मोड",
  Multiple_Cheques_Description:"प्रति पृष्ठ तीन चेक।",
  Check_and_Table:"जाँच र तालिका",
  Check_including_Table:"जाँच र लेखा तालिका।",
  Check_Mailer:"चेक मेलर",
  Check_Mailer_Window:"ठेगाना विन्डोको साथ जाँच गर्नुहोस्।",
  DocuGard_Table_Top:"DocuGard जाँच र तालिका (शीर्ष)",
  DocuGard_Table_Middle:"DocuGard जाँच र तालिका (मध्य)",
  DocuGard_Table_Bottom:"DocuGard जाँच र तालिका (तल)",
  DocuGard_Mailer_Top:"DocuGard चेक मेलर (शीर्ष)",
  DocuGard_Mailer_Middle:"DocuGard चेक मेलर (मध्य)",
  DocuGard_Mailer_Bottom:"DocuGard चेक मेलर (तल)",
  DocuGard_Three_Cheques:"डकुगार्ड चेक (ट्रिपल)",
  DocuGard_Cheque_Top:"DocuGard चेक (शीर्ष)",
  DocuGard_Cheque_Middle:"डकुगार्ड चेक (मध्य)",
  DocuGard_Cheque_Bottom:"DocuGard चेक (तल)",
  DocuGard_Three_Cheques_Window:"एउटा पृष्ठमा तीनवटा जाँच।",
  DocuGard_Table_Top_Window:"जाँच र आय तालिका।",
  DocuGard_Table_Middle_Window:"जाँच र आय तालिका।",
  DocuGard_Table_Bottom_Window:"जाँच र आय तालिका।",
  DocuGard_Mailer_Top_Window:"जाँच गर्नुहोस्, टेबल र ठेगाना।",
  DocuGard_Mailer_Middle_Window:"जाँच गर्नुहोस्, टेबल र ठेगाना।",
  DocuGard_Mailer_Bottom_Window:"जाँच गर्नुहोस्, टेबल र ठेगाना।",
  DocuGard_Cheque_Top_Window:"सुरक्षित कागजको लागि जाँच गर्नुहोस्।",
  DocuGard_Cheque_Middle_Window:"सुरक्षित कागजको लागि जाँच गर्नुहोस्।",
  DocuGard_Cheque_Bottom_Window:"सुरक्षित कागजको लागि जाँच गर्नुहोस्।",
  Basic_Cheque:"जाँच (शीर्ष)",
  Basic_Cheque_Print:"एकल चेक छाप्नुहोस्।",
  Error_Setting_As_Paid:"भुक्तानीको रूपमा सेटिङ त्रुटि",
  Add_Attachment:"संलग्नक थप्नुहोस्",
  PrinterUnavailable:"प्रिन्टर अनुपलब्ध",
  CreditCardComponent:"कार्ड",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"बिटकोइन",
  New_Deposit:"नयाँ निक्षेप",
  Deposits:"निक्षेप",
  No_Deposits:"कुनै जम्मा छैन",
  Credit_Card_Deposit:"क्रेडिट कार्ड",
  New_Credit_Card_Deposit_Message:"क्रेडिट कार्ड निक्षेप",
  New_BitCoin_Deposit:"बिटकोइन",
  New_BitCoin_Deposit_Message:"बिटकोइन निक्षेप",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"इन्टरक ट्रान्सफर",
  Payments_Limit:"भुक्तानी सीमा",
  No_Payment_Limit:"भुक्तानी सीमा छैन",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal जम्मा",
  No_Deposits_Word:"पहिलो कम्पनी <a routerLink='/folder/Deposit/New'>जम्मा</a> थप्नुहोस्।",
  No_Documents_Specified:"प्रिन्ट गर्नका लागि कुनै कागजातहरू निर्दिष्ट गरिएको थिएन",
  No_Printers_Added:"कुनै प्रिन्टरहरू फेला परेनन्। एउटा थप्नको लागि सेटिङहरू > प्रिन्टरहरूमा जानुहोस्।",
  DB_Erase_Prompt:"पूरै डाटाबेस पूर्ण रूपमा मेटाउने? चेतावनी: तपाईंले सबै भण्डारण जानकारी गुमाउनु हुनेछ!",
  Console_Warning:"यस कन्सोलमा कुनै पनि पाठ टाँस्नुहोस्। तपाईं आफैलाई र/वा आफ्नो कम्पनीलाई गम्भीर जोखिममा पार्न सक्नुहुन्छ।",
  No_Faxes_Word:"पहिलो <a routerLink='/folder/Fax/New'>Fax</a> सिर्जना गर्नुहोस्।",
  Cheque_Delete_Confirmation:"के तपाइँ यो चेक मेटाउन निश्चित हुनुहुन्छ?",
  Design_Delete_Confirmation:"के तपाइँ यो डिजाइन मेटाउन निश्चित हुनुहुन्छ?",
  Cheque_Pay_Confirmation:"यो चेकलाई भुक्तान गरिएको भनी चिन्ह लगाउनुहोस्? यो प्रिन्ट लाइनमा देखिने छैन।",
  Payment_Pay_Confirmation:"यो भुक्तानीलाई भुक्तान भनी चिन्ह लगाउनुहोस्? यो चेक लाइनमा देखिने छैन।",
  Delete_Deduction_Confirmation:"के तपाइँ यो कटौती मेटाउन निश्चित हुनुहुन्छ?",
  Delete_Job_Confirmation:"के तपाई यो जागिर मेटाउन निश्चित हुनुहुन्छ?",
  Delete_Timesheet_Confirmation:"के तपाइँ यो टाइमशिट मेटाउन निश्चित हुनुहुन्छ?",
  Delete_Schedule_Confirmation:"के तपाइँ यो समयतालिका मेटाउन निश्चित हुनुहुन्छ?",
  Delete_Setting_Confirmation:"के तपाइँ यो सेटिङ रिसेट गर्न निश्चित हुनुहुन्छ?",
  Delete_Fax_Confirmation:"के तपाइँ यो फ्याक्स मेटाउन निश्चित हुनुहुन्छ?",
  Delete_File_Confirmation:"के तपाइँ यो फाइल मेटाउन निश्चित हुनुहुन्छ?",
  Delete_Vacation_Confirmation:"के तपाइँ यो बिदा मेटाउन निश्चित हुनुहुन्छ?",
  Delete_Printer_Confirmation:"के तपाइँ यो प्रिन्टर मेटाउन निश्चित हुनुहुन्छ?",
  Remove_Design_Confirmation:"के तपाइँ यो डिजाइन मेटाउन निश्चित हुनुहुन्छ?",
  Delete_Payroll_Confirmation:"के तपाइँ यो पेरोल मेटाउन निश्चित हुनुहुन्छ?",
  Send_Fax_Email_Confirmation:"के तपाइँ यी कागजातहरू फ्याक्स र इमेल गर्न चाहनुहुन्छ?",
  Send_Email_Confirmation:"के तपाइँ यो कागजात इमेल गर्न चाहनुहुन्छ?",
  Send_Fax_Confirmation:"के तपाइँ यो कागजात फ्याक्स गर्न चाहनुहुन्छ?",
  Error_Generating_PDF:"माफ गर्नुहोस्। यो कागजात सिर्जना गर्दा त्रुटि भयो।",
  PDF_Error_Saving_Image:"माफ गर्नुहोस्। यस कागजातको PDF छवि बचत गर्दा त्रुटि भयो।",
  Test_Printer_Confirmation:"के तपाइँ यो प्रिन्टरमा परीक्षण पृष्ठ छाप्न चाहनुहुन्छ?",
  Save_Timesheet_Prompt:"कृपया एउटा 'शीर्षक' थप्नुहोस् वा बचत गर्न 'स्टार्ट टाइमर' थिच्नुहोस्।",
  Remove_Geofence_Prompt:"के तपाइँ यो भू-बारको स्थान हटाउन निश्चित हुनुहुन्छ?",
  Delete_Employee_Confirmation:"के तपाइँ निश्चित हुनुहुन्छ कि तपाइँ मेटाउन चाहनुहुन्छ",
  Fire_Employee_Confirmation:"के तपाइँ निश्चित हुनुहुन्छ कि तपाइँ फायर गर्न चाहनुहुन्छ"
}