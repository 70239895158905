export const Es = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Derechos de autor &copiar; 2023",
  black:"Negro",
  green:"Verde",
  gold:"Oro",
  blue:"Azul",
  brown:"Marrón",
  purple:"Púrpura",
  pink:"Rosa",
  red:"Rojo",
  Swatches:"Muestras",
  HSL:"LGV",
  RGB:"RGB",
  Hue:"Matiz",
  Saturation:"Saturación",
  Lightness:"Ligereza",
  Upgrade_To_Pro:"Actualízate a Pro",
  AllFeaturesInclude:"Todas las características incluyen:",
  PrintUnlimitedCheques:"Imprimir cheques ilimitados",
  PremiumChequeDesigns:"Diseños de cheques premium",
  ManageUnlimitedEmployees:"Administrar empleados ilimitados",
  AddUnlimitedPayees:"Agregar beneficiarios ilimitados",
  CreateUnlimitedPayrolls:"Crear Nóminas Ilimitadas",
  UnlimitedSchedulesandTimeSheets:"Horarios y hojas de tiempo ilimitados",
  BulkPayPalPayouts:"Pagos masivos de PayPal",
  UnlimitedBankAccounts:"Cuentas bancarias ilimitadas",
  ManageMultipleCompanies:"Administrar varias empresas",
  TrackInsurancePolicies:"Seguimiento de pólizas de seguro",
  Bio_MetricProtection:"Protección biométrica",
  Geo_FenceLock_OutProtection:"Protección de bloqueo de geovalla",
  Multiple_Companies_Word:"La gestión de varias empresas no está disponible sin la prima de Cheques.",
  PayPal_Payouts_Word:"Los pagos de PayPal están deshabilitados sin la prima de Cheques.",
  PINProtection:"Protección PIN",
  PasswordProtection:"Protección de contraseña",
  May_Require_Approval:"Puede requerir aprobación.",
  Subscribe:"Suscribir",
  Billed:"Facturado",
  Up:"Arriba",
  Down:"Abajo",
  Positioning:"Posicionamiento",
  Marker:"Marcador",
  Drag_Marker:"Marcador de arrastre",
  Tagline:"Imprimir cheques y tabular la nómina",
  Marker_Word:"Use los Marcadores para dimensionar el elemento.",
  Pinch_Zoom:"Zoom de pellizco",
  Pinch_Word:"Pellizque para hacer zoom en el elemento.",
  Drag:"Arrastrar",
  Drag_Word:"Usa tu dedo para arrastrar elementos.",
  subscription_alias_word:"Suscripción de renovación automática",
  premium_alias_word:"Paquete de actualización único",
  print_alias_word:"Impresión de cheques individuales",
  mode_alias_word:"Modo",
  Pro:"Pro",
  Pro_word:"Se requiere versión Pro.",
  Cant_Delete_Default_Company_Word:"Lo sentimos, no puede eliminar su empresa predeterminada.",
  Reinsert_Default_Designs:"Reinsertar diseños predeterminados",
  Reinsert_Default_Designs_word:"¿Desea volver a insertar diseños predeterminados?",
  Subscription_Active_Disable_Word:"Esta suscripción está activa. ¿Le gustaría cancelar esta suscripción a Cheques?",
  Company_Logo:"Logo de la compañía",
  ZERO_:"CERO",
  Disclaimer:"Descargo de responsabilidad",
  Privacy_Policy:"política de privacidad",
  EULA:"Comprueba el CLUF",
  Terms_Of_Service:"Términos de servicio",
  Terms_Of_Use:"Condiciones de uso",
  Refunds:"Politica de reembolso",
  Single_Print:"1 cheque",
  Two_Prints:"2 cheques",
  Five_Prints:"5 cheques",
  Ten_Prints:"10 cheques",
  Fifteen_Prints:"15 cheques",
  Twenty_Prints:"20 cheques",
  Thirty_Prints:"30 cheques",
  Image_Added:"Imagen añadida",
  Image_Preview:"Vista previa de la imagen",
  No_Image_Was_Selected:"No se seleccionó ninguna imagen.",
  Cheques_Unlimited:"Cheques Ilimitados",
  _Subscription:"Suscripción",
  Subscription:"Cheques - 1 Mes",
  Two_Month_Subscription:"Cheques - 2 Meses",
  Three_Month_Subscription:"Cheques - 3 Meses",
  Six_Month_Subscription:"Cheques - 6 Meses",
  Twelve_Month_Subscription:"Cheques - 12 Meses",
  Cheques_Are_Available:"Los cheques están disponibles para imprimir.",
  Upgrade_Required_Two:"Seleccione un paquete y toque dos veces el botón de precio para comenzar su compra. Imprima cheques a todo color de aspecto profesional en segundos.",
  Month:"Mes",
  Due:"Adeudado:",
  Expires:"Caduca:",
  Subscription_Active:"Suscripción activa",
  Subscription_Inactive:"Suscripción inactiva",
  Purchase_Additional_Cheques:"¿Comprar cheques adicionales?",
  Printable_Cheque:"Cheque imprimible",
  Printable_Cheques:"Cheques imprimibles",
  Printable_Cheque_Word:"el cheque está disponible en su cuenta.",
  Printable_Cheques_Word:"los cheques están disponibles en su cuenta.",
  Max_Amount_Message:"La cantidad que ha especificado ha superado la cantidad máxima establecida para este sistema:",
  Terms_Required_Word:"Debe aceptar este acuerdo antes de continuar usando Cheques.",
  Subscriptions:"Suscripciones",
  Product_Upgrades:"Actualizaciones",
  Mailed_Out_Cheques:"Cheques enviados por correo",
  Enter_A_Company_Name:"Introduzca un nombre de empresa.",
  Single_Cheques:"Cheques individuales",
  Cheque_Golden:"cheque dorado",
  Cheque_Golden_Window:"Diseño de cheques dorados.",
  Cheque_Green:"cheque verde",
  Cheque_Green_Window:"Diseño de cheques verdes.",
  Cheque_Red:"cheque rojo",
  Cheque_Red_Window:"Diseño de cuadros rojos.",
  Cheque_Yellow:"Cheque amarillo",
  Cheque_Yellow_Window:"Diseño de cuadros amarillos.",
  Cheque_Statue_of_Liberty:"Estatua de la Libertad",
  Cheque_Statue_of_Liberty_Window:"El diseño del cheque de la Estatua de la Libertad.",
  Cheque_Green_Wave:"Ola Verde",
  Cheque_Green_Wave_Window:"Diseño de cheques verdes.",
  Cheque_Golden_Weave:"tejido dorado",
  Cheque_Golden_Weave_Window:"Elegante diseño de cuadros dorados.",
  Cheque_Green_Sun:"sol verde",
  Cheque_Green_Sun_Window:"Diseño de cuadros profundo y relajante.",
  Cheque_Blue_Checkers:"Damas azules",
  Cheque_Blue_Checkers_Window:"Diseño de cuadros azules.",
  Cashiers_Check:"Cheque de caja",
  Cashiers_Check_Window:"Plantilla de estilo de cheque de caja.",
  Cheque_Aqua_Checkers:"Damas de agua",
  Cheque_Aqua_Checkers_Window:"Diseño de cuadros acuáticos.",
  Cheque_Golden_Checkers:"damas de oro",
  Cheque_Golden_Checkers_Window:"Diseño de cheques dorados.",
  Upgrade_Unavailable:"Actualizaciones no disponibles",
  Bank_Code_Protection:"Protección del número de banco",
  Bank_Code_Protection_Word:"Proteja sus números bancarios para que no se utilicen en esta aplicación que se ejecuta en otro dispositivo o para otro usuario. Esta acción es IRREVERSIBLE. ¿Continuar?",
  Bank_Code_Protection_Blocked_Word:"Los números de banco que intenta utilizar están reservados para otro usuario o dispositivo.",
  Bank_Code_Protection_Error_Word:"La verificación del número ha fallado. Conéctese a Internet e intente agregar esta cuenta bancaria nuevamente.",
  Bank_Code_Protection_Set_Error_Word:"La protección del número de banco requiere que esté conectado a Internet. Conéctate e inténtalo de nuevo.",
  Upgrade_Unavailable_Word:"Lo sentimos, estamos teniendo problemas para verificarte. Actualmente, las suscripciones y las actualizaciones de Cheques no están disponibles para su compra en su área.",
  PayPal_Payment_Preview:"Vista previa de pago de PayPal",
  Apple_Pay:"pago de manzana",
  Select_PayPal:"Seleccione Paypal",
  PayPal_Applications:"Aplicaciones de PayPal",
  Purchase_With_Apple_Pay:"Compra con Apple Pay",
  Google_Pay:"Pago de Google",
  Companies:"Compañías",
  Insurance:"Seguro",
  New_PayPal:"Nuevo Paypal",
  Pay_By:"Pagado por",
  Insure:"Asegurar",
  Miles:"Millas",
  Payment_Method:"Método de pago",
  Select_Policies:"Seleccionar políticas",
  Policies:"Políticas",
  Policy:"Política",
  No_PayPal_Account:"Sin cuenta PayPal",
  No_Policies:"Sin pólizas de seguro",
  New_Policy:"Nueva politica",
  PayPal_Payment:"Pago PayPal",
  PayPal_Payments:"Pagos de PayPal",
  No_Payment_Selected:"Ningún pago seleccionado",
  Sending_Payment_Word:"Por favor espere... Este pago está siendo enviado.",
  Sending_Payments_Word:"Espere... Los pagos se están enviando.",
  No_Payment_Selected_PayPal:"Ningún <a routerLink='/folder/Payments'>pago de PayPal</a> seleccionado para enviar.",
  Payment_Sent:"Pago enviado",
  PayPal_Payment_Sent:"Este pago ha sido enviado con PayPal.",
  Copay:"copago",
  Dead:"Muerto",
  Alive:"Vivo",
  Not_Dead:"No muerto",
  Unclaimed:"no reclamado",
  Attempted:"Intentó",
  Deceased:"Fallecido",
  Claimed:"Reclamado",
  Unpaid:"No pagado",
  Sending_Payment:"Envío de pago",
  Sending_Payments:"Envío de pagos",
  Send_PayPal_Confirmation:"¿Quieres enviar esta transacción con PayPal?",
  Send_PayPal_Confirmation_Word:"Presione el botón verde para enviar esta transacción.",
  Save_Payment_As_Unpaid:"¿Guardar este pago como no pagado?",
  Payment_Pay_Confirmation_PayPal:"¿Guardar este pago como pagado?",
  No_Policies_Word:"Agregue la primera <a routerLink='/folder/Postage/New'>Póliza de seguro</a> ahora.",
  Timesheet_Multiple_Delete_Confirmation:"¿Está seguro de que desea eliminar varias hojas de tiempo?",
  Select_Multiple_Timesheets_Prompt:"No se han seleccionado partes de horas. Seleccione al menos una hoja de tiempo.",
  Select_Multiple_Policies_Prompt:"No se han seleccionado políticas. Seleccione al menos una póliza de seguro.",
  Policies_Multiple_Delete_Confirmation:"¿Está seguro de que desea eliminar varias políticas?",
  Company:"Compañía",
  Add_Company:"Agregar empresa",
  New_Company:"Agregar empresa",
  No_Companies:"Sin Empresas",
  Enable_Company:"Habilitar empresa",
  Select_Company:"Seleccionar empresa",
  The_Default_Company:"La etiqueta de empresa predeterminada.",
  Manage_Companies:"Administrar Empresas",
  No_Companies_Word:"Los cheques utilizarán una empresa predeterminada.<br />Agregue la <a routerLink='/folder/Company/New'>primera empresa</a>.",
  Default_Company:"Empresa predeterminada",
  Cheques_Unlimited_Word:"Checks Unlimited le permite imprimir tantos cheques como desee.",
  Cheques_Subscription_Word:"Una suscripción a Cheques le permite imprimir tantos cheques como desee.",
  You_Own_This_Product:"Eres el propietario de este producto.",
  Your_Subscription_is_Active:"Su suscripción está activa.",
  Active_Products:"Productos activados",
  Purchase_Confirmation:"Compra",
  Purchase_Cheques:"cheques de compra",
  Restore_Purchase:"Restaurar las compras",
  Erase_Purchase:"Borrar Compras",
  Successfully_Purchased:"Comprado con éxito",
  Enter_Your_Licence_Key:"Ingrese su clave de licencia en esta página para activar este producto.",
  Licence_Key:"Clave de licencia",
  Enter_Licence_Key:"Introduzca la clave de la licencia",
  Purchased:"comprado",
  Enable_Feature:"Habilitar función",
  Cancel_Subscription:"Cancelar suscripción",
  Subscription_Removed:"Suscripción eliminada",
  Select_Active_Subscription:"Seleccione una suscripción activa para modificarla.",
  Remove_Subscription_Word:"¿Está seguro de que desea cancelar esta suscripción?",
  Delete_Company_Confirmation:"¿Está seguro de que desea eliminar toda esta empresa? ADVERTENCIA: ¡Perderá toda la información almacenada!",
  Delete_Default_Company_Word:"No puede eliminar la empresa predeterminada. ¿Le gustaría restablecer la aplicación y restaurarla al estado predeterminado? ADVERTENCIA: ¡Perderá toda la información almacenada!",
  Console_Warning_2:"No manipule ninguna moneda usando esta aplicación que no sea suya actualmente.",
  Terms_and_Conditions:"Términos y condiciones",
  and_the:"y el",
  for:"para",
  Please_Read_Word:"Por favor, lea y acepte las",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Algunas tasas de conversión de moneda no se pudieron encontrar. Por favor conectarse a Internet.",
  Free:"Gratis",
  DB_Erase_Prompt_2:"¿Borrar por completo toda la base de datos de desarrolladores? ADVERTENCIA: ¡Perderá toda la información de COMPRA y SUSCRIPCIÓN!",
  Successfully_Imported:"Importado con éxito",
  Select_Postage:"Seleccionar franqueo",
  No_Postage:"Sin sellos postales",
  No_Paid_Postage_Word:"Agregue el primer sello <a routerLink='/folder/Postage/New'>franqueo pagado</a>.",
  Trial_Complete:'Prueba completa',
  Please_Upgrade:'Actualice los cheques para continuar con la impresión.',
  Aa:"Aa",
  Color:"Color",
  Font:"Fuente",
  Guide:"Guía",
  Guides:"Guías",
  Image:"Imagen",
  Zoom:"Zoom",
  Logo:"Logo",
  Bank:"Banco",
  MICR:"MICR",
  Total:"Total",
  Cancel:"Cancelar",
  Confirm:"Confirmar",
  Method:"Método",
  Biometric_Security:"Seguridad biométrica",
  Please_Login_To_Continue:"Por favor inicie sesión para continuar.",
  Complete:"Completo",
  Sign_Up:"Inscribirse",
  Error:"Error",
  Biometrics:"biometría",
  Percent:"Por ciento",
  Zero_Percent:"0%",
  Top_Margin:"Margen superior",
  Bottom_Margin:"Margen inferior",
  Left_Margin:"Margen izquierdo",
  Right_Margin:"Margen derecho",
  MICR_Margin:"Margen MICR",
  Table_Margin:"Margen de tabla",
  Address_Margin:"Margen de dirección",
  Percentage_:"Porcentaje",
  Vacation_Title:"Título de vacaciones",
  Use_PIN:"Usar PIN",
  Loading__:"Cargando...",
  Design_Title:"Título del diseño",
  Authorize:"Autorizar",
  Key:"Llave",
  Public_Key:"Llave pública",
  Private_Key:"Llave privada",
  Authenticate:"Autenticar",
  Last_Payroll:"Última nómina",
  Last_Calculation:"Último cálculo",
  Authorized:"Autorizado",
  Geo_Authorized:"Geo-Ubicación: Autorizado",
  Not_Authorized:"No autorizado",
  Authorization_Complete:"Autorización completa",
  Geolocation_Authorization:"Autorización de geolocalización",
  Out_of_Bounds:"Fuera de los límites",
  Cant_Delete_Default_Design:"No se puede eliminar un diseño predeterminado.",
  Unauthorized_Out_of_Bounds:"No autorizado: fuera de los límites",
  Biometric_Authorization:"Autorización biométrica",
  Locating_Please_Wait:"Localizando, por favor espere...",
  Test_Biometrics:"Prueba biométrica",
  Cheque_Margins:"Comprobar márgenes",
  Percentage_Full_Error:"El campo de porcentaje no se puede establecer sobre el 100% por ciento.",
  No_Payroll_Text:"Agregue un <a routerLink='/folder/Employee/New'>Employee</a> o <a routerLink='/folder/Payee/New'>Payee</a> y un <a routerLink='/folder/Schedule/New'>Horario</a>.",
  Design_Saved:"Diseño guardado",
  Default_Design_Selected:"Diseño predeterminado seleccionado",
  Partial_Import:"Importación parcial",
  Partial_Export:"Exportación parcial",
  Entire_Import:"Importación completa",
  Entire_Export:"Exportación completa",
  Existing_Password:"Por favor ingrese su contraseña actual:",
  Existing_PIN:"Ingrese su código PIN actual:",
  Pin_Change_Header:"Confirmación de PIN",
  Pin_Change_SubHeader:"Ingrese su antiguo número PIN para confirmar el cambio.",
  Pass_Change_Header:"Confirmación de contraseña",
  Pass_Change_SubHeader:"Ingrese su contraseña anterior para confirmar el cambio.",
  PIN_Enter_Message:"Ingrese su PIN para confirmar.",
  Password_Enter_Message:"Introduzca su contraseña para confirmar.",
  Pin_Updated_Success:"¡PIN actualizado con éxito!",
  Pin_Updated_Fail:"No se pudo actualizar el PIN.",
  Pass_Updated_Success:"Contraseña actualizada exitosamente.",
  Pass_Updated_Fail:"No se pudo actualizar la contraseña.",
  Preview_Payment:"Vista previa de pago",
  Preview_Payroll:"Vista previa de nómina",
  No_Payments_Created:"No se encontraron pagos creados.",
  Payment_Preview:"Vista previa de pago",
  Enable_Payee:"Habilitar beneficiario",
  Rendered:"prestado",
  No_Email:"Sin correo electrónico",
  Setup_Cheques:"Comprobaciones de configuración",
  name:"Nombre",
  address:"Dirección",
  address_2:"Línea de dirección 2",
  city:"Ciudad",
  province:"Provincia",
  postal_code:"Código postal/ZIP",
  country:"País",
  username:"Nombre de usuario",
  full_name:"Nombre completo",
  birthday:"Cumpleaños",
  email:"Correo electrónico",
  phone:"Teléfono",
  employees:"Empleados",
  addresses:"direcciones",
  payment_amount_limit:"Límite de cantidad de pago",
  total_limit:"Límite total",
  payees:"Beneficiarios",
  description:"Descripción",
  address_line_one:"Dirección Línea Uno",
  address_line_two:"Línea de dirección dos",
  image:"Imagen",
  account_holder:"Titular de la cuenta",
  cheque_starting_id:"Comprobar ID inicial",
  transit_number:"Número de Tránsito",
  institution_number:"Número de institución",
  designation_number:"Número de designación",
  account_number:"Número de cuenta",
  currency:"Divisa",
  signature:"Firma",
  payment_payroll_limit:"Límite de pago",
  total_limi:"Límite total",
  date:"Fecha",
  printed_memo:"Nota impresa",
  banks:"Bancos",
  signature_image:"Imagen de firma",
  address_name:"Nombre de la dirección",
  notes:"notas",
  design:"Diseño",
  title:"Título",
  frequency:"Frecuencia",
  fax:"Fax",
  salaries:"Salarios",
  salary_ids:"ID de salario",
  start_time:"Hora de inicio",
  end_time:"Hora de finalización",
  paid:"Pagado",
  overtime_percentage:"Porcentaje pagado",
  overtime_amount:"Importe fijo pagado",
  first_name:"Primer nombre",
  last_name:"Apellido",
  moderation:"Moderación",
  create:"Crear",
  edit:"Editar",
  destroy:"Destruir",
  day_start_time:"día_inicio_hora",
  day_end_time:"día_final_hora",
  fullname:"Nombre",
  time:"Tiempo",
  auto_send:"Enviar automáticamente",
  time_method:"Método de tiempo",
  schedules:"Horarios",
  indefinite_payroll_enabled:"Habilitar indefinidamente",
  amount:"Monto",
  repeat:"Repetir",
  always_enabled:"Siempre habilitado",
  start_date:"Fecha de inicio",
  end_date:"Fecha final",
  Cheque_Total:"Total de cheques",
  Total_Amount:"Cantidad total:",
  Amounts:"cantidades:",
  Images:"Imágenes",
  Files:"archivos",
  Previewing:"Vista previa:",
  Insert:"Insertar",
  Preview_Import:"Vista previa de importación",
  Entry:"Entrada",
  Entries:"Entradas",
  No_Entries:"No entrar",
  Import_Type:"Tipo de importación",
  Select_Details:"Seleccionar detalles",
  Select_Payee:"Seleccionar beneficiario",
  Enable_Holidays:"Habilitar vacaciones",
  Disable_Holidays:"Deshabilitar días festivos",
  Wire_Transfer:"Transferencia bancaria",
  New_Export:"Nueva exportación",
  Export_Data:"Exportar datos",
  Export_Data_Word:"Seleccione el tipo de archivo para exportar y descargar.",
  Export_File:"Exportar archivo",
  Mode:"Modo",
  Times:"Veces",
  Widgets:"Widgets",
  Slider:"Deslizador",
  Set_Details:"Establecer detalles",
  Select_Type:"Seleccione tipo",
  Display_Slider:"Deslizador de pantalla",
  Dashboard_Slider:"Deslizador del tablero",
  Dashboard_Mode:"Modo de tablero",
  Show_Intro:"Mostrar introducción",
  Show_Payrolls:"Mostrar nóminas",
  Show_Holidays:"Mostrar días festivos",
  Show_Invoices:"Mostrar facturas",
  Show_Cheques:"Mostrar cheques",
  Enabled_Widgets:"Widgets habilitados",
  Disabled_Widgets:"Widgets deshabilitados",
  Colors:"Colores",
  Barcodes:"códigos de barras",
  View_Timers:"Ver temporizadores",
  Gradients:"Gradientes",
  No_Info:"Sin información",
  Disable:"Deshabilitar",
  Show_Layer:"Mostrar capas",
  Hide_Layer:"Ocultar capas",
  Text_Layer:"Capas de texto",
  Secondly:"En segundo lugar",
  Minutely:"Minuciosamente",
  nine_am:"09 A.M",
  five_pm:"5:00 PM",
  Enable_Address:"Habilitar dirección",
  Invalid_File_Type:"Lo sentimos, se seleccionó un tipo de archivo no válido. Tipo de archivo admitido:",
  Error_Updating_Entry:"Lo sentimos, hubo un error al actualizar esta entrada.",
  Schedule_Timing_Alert:"Error: la hora de inicio del programa se establece en un valor posterior a la hora de finalización.",
  Select_Multiple_Payments_Prompt:"No se han seleccionado pagos. Seleccione al menos un pago.",
  Select_Multiple_Cheques_Prompt:"No hay cheques seleccionados. Seleccione al menos un cheque.",
  Select_Multiple_Items_Prompt:"No hay elementos seleccionados. Seleccione al menos un artículo.",
  Paymemt_Multiple_Delete_Confirmation:"¿Está seguro de que desea eliminar varios pagos?",
  Cheque_Multiple_Delete_Confirmation:"¿Está seguro de que desea eliminar varios cheques?",
  Payee_Multiple_Delete_Confirmation:"¿Está seguro de que desea eliminar varios beneficiarios?",
  Employee_Multiple_Delete_Confirmation:"¿Está seguro de que desea eliminar varios empleados?",
  MICR_Warning:"Nota: Es posible que algunas impresoras y dispositivos no muestren la fuente MICR correctamente.",
  Automatically_Send:"Enviar automáticamente",
  Type:"Escribe",
  Payment_Type:"Tipo de pago",
  Auto_Send:"Envío automático",
  Automatically_Process:"Procesar automáticamente",
  Auto_Process:"Proceso automático",
  Image_Based:"basado en imágenes",
  Font_Based:"basado en fuentes",
  Rerender:"volver a renderizar",
  Rendering:"Representación",
  Render:"archivos",
  Top:"Parte superior",
  Middle:"Medio",
  Bottom:"Abajo",
  Top_Left:"Arriba a la izquierda",
  Top_Center:"Centro Superior",
  Top_Right:"Parte superior derecha",
  Middle_Left:"Medio Izquierdo",
  Middle_Center:"Medio Centro",
  Middle_Right:"medio derecho",
  Bottom_Left:"Abajo a la izquierda",
  Bottom_Center:"Parte inferior central",
  Bottom_Right:"Abajo a la derecha",
  Numbers:"Números",
  Verified:"Verificado",
  Paper_Size:"Tamaño de papel",
  New_Device:"Nuevo dispositivo",
  Add_Device:"Añadir dispositivo",
  Remove_Device:"Retire el dispositivo",
  Delete_Device:"Eliminar dispositivo",
  Block_Device:"Dispositivo de bloque",
  Block:"Bloquear",
  Unblock:"Desatascar",
  Table:"Mesa",
  Scan_Login_Code:"Escanear código de inicio de sesión",
  Login_Code:"Código de inicio de sesión",
  Scan_Code:"Escanear código",
  Scan_QR_Code:"Escanear código QR",
  Select_All:"Seleccionar todo",
  Deselect_All:"Deseleccionar todo",
  Increase:"Aumentar",
  Decrease:"Disminuir",
  Bold:"Audaz",
  Text:"Texto",
  Style:"Estilo",
  Italic:"Itálico",
  QR_Code:"Código QR",
  Barcode:"Código de barras",
  Browse_Images:"Examinar imágenes",
  Browse_Files:"Búsqueda de archivos",
  Background_Image:"Imagen de fondo",
  Logo_Image:"Logotipo",
  Header_Image:"Imagen de cabecera",
  Bank_Image:"Imagen del banco",
  Cut_Lines:"Líneas de corte",
  Background:"Fondo",
  License:"Licencia",
  One_License:"1 Licencia:",
  Licensed:"Licenciado para:",
  Not_Licensed:"Sin licencia",
  Enter_Serial:"Introducir número de serie",
  Serial_Key:"Clave serial",
  Serial:"De serie",
  Product_Key:"Clave de producto",
  Check_Product_Key:"Comprobar clave de producto",
  Add_Product_Key:"Agregar clave de producto",
  Verifying_Purchase:"Verificando compra...",
  Print_Envelope:"Imprimir sobre",
  Envelope:"Sobre",
  Thank_You:"¡Gracias!",
  Scale:"Escala",
  Print_Scale:"Escala de impresión",
  Borders:"Fronteras",
  VOID:"VACÍO",
  Void_Cheque:"Cheque nulo",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"PAGAR A LA ORDEN DE:",
  NO_DOLLARS:"SIN DÓLARES ",
  ONE_DOLLAR:"UN DÓLAR",
  DOLLARS:" DÓLARES",
  AND:" Y ",
  CENTS:" CENTAVOS.",
  NO_:"NO ",
  ONE_:"UNA ",
  AND_NO_:"Y NO ",
  _AND_ONE_:"Y UNO ",
  DOLLARS_AND_ONE_CENT:" Y UN CENTAVO.",
  AND_NO_CENTS:" Y CERO CENTAVOS.",
  CHEQUE_PRINT_DATE:"FECHA:",
  CHEQUE_PRINT_MEMO:"MEMORÁNDUM:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Tareas Iniciales",
  Inital_Setup:"Configuración Inicial",
  Welcome_To:"Bienvenido a ",
  Welcome:"Bienvenidos",
  Swipe:"Golpe fuerte",
  Intro_Setup:"Configuración de introducción",
  Introduction:"Introducción",
  CHEQUE_PRINT_CREDIT:"Impulsado por cheques",
  Title_Intro_Word:"Bienvenidos a Cheques",
  Title_Intro:"Introducción de cheques",
  Title_Setup:"Configuración de cheques",
  PayPal_Default_Email_Message:"Ha recibido una nueva transferencia de PayPal. [Powered by Cheques]",
  Cheques_App_Export:"Exportado por Cheques",
  Post_Intro_Thanks:"Gracias por elegir Cheques. El proceso de configuración ahora está completo.",
  Post_Intro_Word:"Comience imprimiendo su primer cheque, agregue un pago futuro o agregue un empleado a la nómina.",
  Upgrade_Required:"Cheques requiere que tengas una versión más premium del software para ocultar este mensaje y desbloquear funciones adicionales.",
  Upgrade_Title:"Cheques",
  Mailout_Prompt:"Además, puede elegir que Checks envíe sus cheques de nómina por correo.",
  Mailed_Cheque:"Cheque enviado por correo: ",
  Mailed_Cheque_Color:"Cheque enviado por correo (color): ",
  Terms_Purchase:"Todas las compras electrónicas con cheques son totalmente reembolsables por hasta 30 días a partir de la fecha de compra. Lea y acepte los <a href='#'>Términos y condiciones</a> antes de continuar.",
  Dashboard_Setup:"Configurar la impresora principal",
  Dashboard_Add:"Agregar la cuenta bancaria principal",
  Dashboard_Customize:"Seleccione una plantilla de cheque",
  Dashboard_Job_Salary:"Cree su trabajo y agregue su salario",
  Dashboard_Employees:"Rastrear empleados y beneficiarios",
  Dashboard_Print:"Imprima y envíe por correo sus cheques de pago",
  Dashboard_Payroll:"Automatiza la impresión de tu nómina",
  Dashboard_PayPal:"Configurar nómina/pagos de PayPal",
  Begin_Setup:"Comenzar configuración",
  Get_Started:"Empezar",
  Purchase:"Compra",
  Lockdown:"Aislamiento",
  Unlock:"Desbloquear",
  Detailed:"Detallado",
  Log_In:"Iniciar sesión",
  Login:"Acceso",
  Launch:"Lanzamiento",
  Register:"Registrarse",
  Finish:"Terminar",
  List:"Lista",
  Weekends:"fines de semana",
  Weekly:"Semanalmente",
  PayPal_Default_Subject:"Nuevo Pago",
  Payment_Message:"Mensaje de pago",
  PayPal_Default_Payment_Note:"Gracias",
  Setup_Your_Cheqing_Account:"Cuenta corriente",
  Add_Your_Primary_Cheqing_Account:"Agregue su cuenta de cheques principal.",
  Welcome_Word:"Simplifique y automatice la gestión de nóminas y recursos humanos.",
  Get_Started_Quickly:"Solo responda algunas preguntas simples que nos ayudarán a comenzar...",
  Done_Intro_Word:"Instalación completa",
  PIN_Protected:"Protegido con contraseña y PIN",
  Enter_New_PIN:"Ingrese un nuevo código PIN:",
  Enter_PIN:"Ingrese el código PIN:",
  Invalid_PIN:"PIN inválido ingresado.",
  Account_Identifier:"Identificador de cuenta",
  New_Account_Identifier:"Identificador de cuenta nueva",
  attempt:"intento",
  attempts:"intentos",
  remaining:"restante",
  Language:"Idioma",
  languages:"Idiomas",
  select_languages:"Seleccione el idioma",
  Deposit:"Depositar",
  Hire_One_Now:"Contrata uno ahora",
  App_Locked:"La aplicación está bloqueada.",
  Skip_:"Saltar",
  Skip_to_Dashboard:"Saltar al panel de control",
  Dashboard:"Tablero de mandos",
  Import:"Importar",
  Admin:"Administradores",
  New_Admin:"Nuevo administrador",
  Settings:"Ajustes",
  Color_Picker:"Selector de color",
  Font_Picker:"Selector de fuente",
  Logout:"Cerrar sesión",
  Close:"Cerca",
  Close_menu:"Cerca",
  Excel:"Archivo Excel",
  Csv:"Archivo CSV",
  Sql:"Archivo SQL",
  Json:"Archivo JSON",
  Url:"Importar por URL",
  Back:"Atrás",
  Timers:"Temporizadores",
  Cheque:"Cheque",
  Print:"Impresión",
  Designs:"Diseños",
  Pause_Printing:"Pausar impresión",
  Resume_Printing:"Reanudar impresión",
  Printing_Paused:"Impresión en pausa",
  PrintingUnavailable:"¡Lo sentimos! La impresión no está disponible en este sistema.",
  Prints_Paused:"Impresiones en pausa",
  Administration:"Administración",
  Loading:"Cargando",
  Unnamed:"Sin nombre",
  error:"Lo sentimos, este cheque no se pudo abrir para verlo.",
  No_Cheques_To_Print:"No hay cheques para imprimir",
  No_Cheques_To_Print_Word:"Crear un <a routerLink='/carpeta/Cheque/New'>Nuevo Cheque</a>.",
  New_Cheque:"Nuevo cheque",
  Start_One_Now:"Comience uno ahora",
  Edit_Cheque:"Editar cheque",
  Select_Cheques:"Seleccionar cheques",
  Select_Employee:"Seleccionar empleado",
  Default_Printer:"Impresora predeterminada",
  Reassign:"Reasignar",
  Configure:"Configurar",
  Template:"Plantilla",
  Designer:"Diseñador",
  Edit_Designs:"Editar diseños",
  New_Design:"Nuevo diseño",
  Next:"Próximo",
  Save:"Ahorrar",
  Name:"Nombre",
  Mail:"Correo",
  Amount:"Monto",
  Date:"Fecha",
  PayPal:"PayPal",
  Payouts:"Pagos",
  PayPal_Label:"Etiqueta de PayPal",
  Email_Username:"Correo electrónico / Nombre de usuario",
  Client_ID:"Identificación del cliente",
  Sandbox_Email:"Correo electrónico de zona de pruebas",
  PayPal_Email:"E-mail de Paypal",
  PayPal_Username:"Nombre de usuario de la API",
  PayPal_Payouts:"Pagos de PayPal",
  Select_PayPal_Key:"Seleccione la clave de PayPal",
  Secret:"Secreto",
  API_Secret:"Secreto API",
  PayPal_API_Keys:"Claves de PayPal",
  New_PayPal_Key:"Nueva clave de PayPal",
  Email_Subject:"Asunto del email",
  Email_Message:"Mensaje de correo electrónico",
  Payout_Options:"Opciones de pago",
  Payment_Note:"Nota de pago",
  Enable_Employee:"Habilitar empleado",
  Enable_Production_Mode:"Habilitar modo de producción",
  Sandbox_Username:"Nombre de usuario de la zona de pruebas",
  Sandbox_Signature:"Firma de la zona de pruebas",
  Sandbox_Password:"Contraseña de la zona de pruebas",
  Production_Username:"Nombre de usuario de producción",
  Production_Signature:"Firma de producción",
  Production_Password:"Contraseña de producción",
  Production_Email:"Correo electrónico de producción",
  API_Client_ID:"ID de cliente de API",
  API_Signature:"Firma API",
  API_Password:"Contraseña API",
  API_Username:"Nombre de usuario de la API",
  Secret_Key:"Llave secreta",
  Sandbox:"Salvadera",
  Production:"Producción",
  Sandbox_Keys:"Claves de la caja de arena",
  Production_Keys:"Claves de producción",
  API_Title:"Título de la API",
  Production_Mode:"Modo de producción",
  Account_Label:"Etiqueta de cuenta",
  No_PayPal_Setup:"Sin clave de PayPal",
  Enable_PayPal_Account:"Habilitar cuenta PayPal",
  No_PayPal_Word:"Agregue su <a routerLink='/carpeta/Factura/Nueva'>Clave API de PayPal</a>.",
  Printed_Memo:"Memorándum impreso",
  Employee:"Empleado",
  View_Employee:"Ver empleado",
  Mailing_Address:"Dirección de envio",
  Company_Address:"Dirección de la empresa",
  Select_Company_Address:"Seleccione la dirección de la empresa",
  Address:"Habla a",
  Any_Day:"Cualquier día",
  Address_Name:"Nombre de la dirección",
  Unit:"Unidad",
  Account:"Cuenta",
  Bank_Account:"Cuenta bancaria",
  Account_Limits:"Habilitar límites de cuenta",
  Payroll:"Nómina de sueldos",
  New_Payroll:"Nueva Nómina",
  No_Payroll:"Sin Nómina Próxima",
  Upcoming_Holiday:"Próximas vacaciones:",
  Invoice_Due:"Factura vencida:",
  New_Invoice:"Nueva Factura",
  No_Invoices:"Sin facturas",
  No_Invoices_Word:"Cree la primera <a routerLink='/carpeta/Factura/Nueva'>Factura</a>.",
  Cheque_Due:"Cheque vencido:",
  Payrolls:"nóminas",
  Sandbox_Mode:"Modo sandbox",
  Hire:"Contratar",
  Pay:"Pagar",
  New:"Nuevo",
  Add:"Agregar",
  Make:"Fabricar",
  Time:"Hora",
  Write:"Escribir",
  Holiday:"Vacaciones",
  Holidays:"Días festivos",
  Next_Holiday:"Siguientes vacaciones:",
  All_Done:"¡Todo listo!",
  Employees:"Empleados",
  Payees:"Beneficiarios",
  Job:"Trabajo",
  Jobs:"Trabajos",
  Invoice:"Factura",
  Invoices:"Facturas",
  Vacations:"Vacaciones",
  Cheques:"Cheques",
  Brand_Cheques:"Marca",
  Payment:"Pago",
  Enable_Payment:"Habilitar pago",
  Payments:"Pagos",
  Schedule:"Calendario",
  Schedules:"Horarios",
  Timesheet:"Hoja de tiempo",
  Timesheets:"Hojas de horas",
  Salary:"Salario",
  New_Address:"Nueva direccion",
  Address_2:"Dirección (Línea 2)",
  _City:"Ciudad",
  _State:"Estado/Prov",
  City:"Ciudad / Municipio",
  State:"Provincia del estado",
  Postal:"Código Postal / ZIP",
  ZIP:"Postal / Código Postal",
  Country:"País",
  Addresses:"Direcciones",
  Required_Options:"Opciones requeridas",
  Optional_Options:"Opciones Opcionales",
  Additional_Options:"Opciones adicionales",
  Required:"Requerido",
  Optional:"Opcional",
  Additional:"Adicional",
  No_Addresses:"Sin direcciones",
  New_Address_Word:"Agregue la primera <a routerLink='/carpeta/Dirección/Nueva'>Dirección</a>.",
  Select_Address:"Seleccionar dirección",
  No_Address:"Sin direcciones",
  Print_Cheque:"Imprimir cheque",
  Print_Cheque_Now:"Imprimir cheque ahora",
  Description:"Descripción",
  Pay_To_The_Order_Of:"Pagar a la orden de:",
  Select_Date_Range:"Seleccionar rango de fechas",
  Select_Starting_Date:"Seleccionar fecha de inicio",
  Select_Ending_Date:"Seleccionar fecha de finalización",
  Select_Date:"Seleccione fecha",
  Cheque_Date:"Comprobar Fecha",
  Cheque_Memo:"Comprobar nota",
  Blank_Cheque:"Cheque en blanco",
  Blank:"Blanco",
  No_Cheques:"Sin cheques",
  No_Cheques_Word:"Imprime tu primer <a routerLink='/folder/Cheque/New'>Cheque</a>.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"Ver imagen",
  View:"Vista",
  Modify:"Modificar",
  Delete:"Borrar",
  Cheque_Paid:"Pagado",
  New_Deduction:"Nueva Deducción",
  Title:"Título",
  Frequency:"Frecuencia",
  Hourly:"Cada hora",
  Daily:"A diario",
  Weekdays:"días laborables",
  BiWeekly:"2 semanas",
  TriWeekly:"3 semanas",
  Monthly:"Mensual",
  MiMonthly:"2 meses",
  Quarterly:"Trimestral",
  Yearly:"Anual",
  Every_Week:"Cada semana",
  Every_Payroll:"Cada Nómina",
  Every_Month:"Cada mes",
  Annually:"Anualmente",
  Always_Scheduled:"Siempre Programado",
  Start_Date:"Fecha de inicio",
  End_Date:"Fecha final",
  Start_Time:"Hora de inicio",
  End_Time:"Hora de finalización",
  Deduction_Label:"Etiqueta de deducción",
  Notes:"Notas",
  Options:"Opciones",
  Enable:"Permitir",
  Select_Deductions:"Seleccionar Deducciones",
  Deductions:"Deducciones",
  No_Deductions:"Sin deducciones",
  No_Deductions_Word:"Cree su primera <a routerLink='/carpeta/Deducción/Nueva'>Deducción</a>.",
  New_Employee:"Nuevo empleado",
  No_Title:"Sin título",
  _Name:"Nombre",
  About_Yourself:"Acerca de ti mismo",
  Full_Name:"Nombre completo",
  Birthday:"Cumpleaños",
  Email:"Correo electrónico",
  SMS:"SMS",
  Phone:"Teléfono",
  Test:"Prueba",
  Call:"Llamar",
  Fax:"Fax",
  Printed_Note:"Nota impresa",
  Position:"Posición",
  Job_Position:"Puesto de trabajo",
  Select_a_Job:"Seleccione un trabajo",
  Select_a_Salary:"Seleccione un salario",
  Add_a_Deduction:"Agregar una deducción",
  Taxes:"Impuestos",
  Add_Taxes:"Añadir Impuestos",
  Date_of_Birth:"Fecha de nacimiento",
  Email_Address:"Dirección de correo electrónico",
  Phone_Number:"Número de teléfono",
  Phone_Call:"Llamada telefónica",
  Enable_on_Payroll:"Habilitar en Nómina",
  Select_Employees:"Seleccionar Empleados",
  No_Employees:"Sin empleados",
  No_Employees_Word:"Agregue su primer nuevo <a routerLink='/carpeta/Empleado/Nuevo'>Empleado</a>.",
  No_Name:"Sin nombre",
  Unemployeed:"Desempleados",
  Employeed:"empleado",
  Paid:"Pagado",
  Enabled:"Activado",
  Disabled:"Discapacitado",
  Fire:"Fuego",
  Not_Available:"No disponible",
  Not_Available_Word:"Imprime tu primera <a routerLink='/folder/Invoice/New'>Factura</a> y recibe el pago.",
  Select_Invoices:"Seleccionar_Facturas",
  Print_Invoice_Word:"Imprime tu primera <a routerLink='/folder/Invoice/New'>Factura</a> y recibe el pago.",
  Invoice_Title:"Título de la factura",
  Invoice_Date:"Fecha de la factura",
  Due_Date:"Fecha de vencimiento",
  New_Job:"Nuevo trabajo",
  Details:"Detalles",
  Customize:"Personalizar",
  Customize_Dashboard:"Personalizar panel",
  Components:"Componentes",
  No_Components:"Sin componentes",
  Main_Components:"Componentes principales",
  Smaller_Components:"Componentes más pequeños",
  Error_Loading_Page:"Error al cargar esta página.",
  Bank_Details:"Detalles del banco",
  About_Your_Job:"Sobre tu trabajo",
  Your_Schedule:"Tu agenda",
  Job_Title:"Título profesional",
  Job_Description:"Descripción del trabajo",
  Job_Details:"Detalles del trabajo",
  Enable_Job:"Habilitar trabajo",
  Pay_Period:"Período de pago",
  Perpetually_Schedule:"Programación perpetua",
  Select_Jobs:"Seleccionar trabajos",
  No_Jobs:"No trabajos",
  Add_Jobs:"Agregar trabajos",
  No_Jobs_Word:"Agregue el primer <a routerLink='/carpeta/Trabajo/Nuevo'>Trabajo</a> a la lista.",
  Count_Employees:"job.employee_count+'Empleados'",
  Zero_Employees:"0 empleados",
  New_Leave:"Nueva licencia",
  Leave_Name:"Dejar Nombre",
  Paid_Leave:"Vacaciones pagadas",
  Leave_Pay:"Dejar Pagar",
  Indefinite_Leave:"licencia indefinida",
  Indefinite_Payroll:"Nómina Indefinida",
  Leave:"Salir",
  Add_Schedules:"Añadir Horarios",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Habilitar Ausencia",
  Select_Leaves:"Seleccionar hojas",
  No_Leaves:"Sin permisos de ausencia",
  Leave_Of_Absence:"Excedencia",
  Leaves_Of_Absence:"Permisos de ausencia",
  New_Leave_of_Absense:"Nueva excedencia",
  No_Leaves_Word:"Agregue el primer <a routerLink='/folder/Leave/New'>Permiso de Ausencia</a>.",
  Not_Enabled:"No disponible",
  Absences:"Ausencias",
  Payee:"Tenedor",
  New_Payee:"Nuevo beneficiario",
  Payee_Identifier:"Identificador del Beneficiario",
  Payee_Name:"Nombre del beneficiario",
  Payee_Title:"Título del beneficiario",
  Payment_Memo:"Memorándum de pago",
  Select_Payees:"Seleccionar beneficiarios",
  No_Payees:"Sin beneficiarios",
  Add_Payee_Note:"Agregue el primer <a routerLink='/carpeta/Beneficiario/Nuevo'>Beneficiario</a>.",
  New_Payees:"Nuevos beneficiarios",
  About_The_Payment_Schedule:"Calendario de pago",
  Your_Payroll_Schedule:"Nómina Automática",
  New_Payment:"Nuevo Pago",
  Identifier:"Identificador",
  Select:"Seleccione",
  Memo:"Memorándum",
  Payment_Date:"Fecha de pago",
  Mark_as_Paid:"Marcar como pagado",
  Select_Payments:"Seleccionar Pagos",
  No_Payments:"Sin pagos",
  No_Payments_Word:"Cree el primer <a routerLink='/carpeta/Pago/Nuevo'>Pago</a>.",
  Create_Payroll:"Crear Nómina",
  Properties:"Propiedades",
  Payroll_Title:"Título de Nómina",
  Payroll_Notes:"Notas de nómina",
  Payroll_Setup:"Configuración de nómina",
  Tabulate_Payments:"Tabular Pagos",
  Tabulate:"Tabular",
  By:"Por:",
  Payments_By:"Pagos por",
  Timesheets_And_Schedules:"Hojas de tiempo y horarios",
  Both:"Ambos",
  Items:"Elementos",
  Add_Payees:"Agregar Beneficiarios",
  Add_Account:"Añadir cuenta",
  Enable_Account:"Habilitar cuenta",
  Enable_Payroll:"Habilitar Nómina",
  Print_Payroll:"Imprimir Nómina",
  No_Payrolls:"Sin Nómina",
  No_Payroll_Word:"Cree su primera <a routerLink='/folder/Payroll/New'>Nómina de Sueldos</a>.",
  View_more:"VER MÁS",
  Less:"MENOS",
  Add_Payroll:"Añadir Nómina",
  Select_Payroll:"Seleccionar Nómina",
  About_Your_Salary:"Acerca de tu salario",
  Add_Salaries:"Añadir Salarios",
  Add_Salary:"Agregar Salario",
  Salaries:"Salarios",
  No_Salaries:"Sin salarios",
  No_Salaries_Word:"Agregue el primer <a routerLink='/carpeta/Salario/Nuevo'>Salario</a>.",
  Select_Salaries:"Seleccionar Salarios",
  New_Salary:"Nuevo Salario",
  Salary_Name:"Identificador de salario",
  Enable_Salary:"Habilitar salario",
  Salary_Amount:"Cantidad del salario",
  New_Schedule:"Nuevo horario",
  Schedule_Title:"Título del horario",
  Add_Address:"Añadir dirección",
  Repeat:"Repetir",
  Design:"Diseño",
  Edit_Design:"Editar diseño",
  Edit_this_Design:"Editar este diseño",
  Repeat_Payment:"Repetir Pago",
  Enable_Schedule:"Habilitar horario",
  Never:"Nunca",
  Select_Schedule:"Seleccionar Horarios",
  No_Schedules:"Sin Horarios",
  No_Schedules_Word:"Cree el primer <a routerLink='/folder/Schedule/New'>Horario</a>.",
  New_Administrator:"Nuevo Administrador",
  Username:"Nombre de usuario",
  First_Name:"Primer nombre",
  Last_Name:"Apellido",
  Add_an_Address:"Añadir una dirección",
  Payment_Limit:"Límite por pago",
  Total_Limit:"Límite total",
  Select_Accounts:"Seleccionar Cuentas",
  No_Administrators:"Sin administradores",
  No_Administrators_Word:"Cree la primera <a routerLink='/carpeta/Administrador/Nueva'>Cuenta de administrador</a>.",
  New_Administrators_Word:"Agregue el primer <a routerLink='/carpeta/Administrador/Nuevo'>Administrador</a>",
  Cloud:"Nube",
  Backup:"Respaldo",
  Enable_iCloud:"Habilitar iCloud",
  Enable_Google_Drive:"Habilitar Google Drive",
  Enable_Microsoft_OneDrive:"Habilitar Microsoft OneDrive",
  Automatically_Backup:"Copia de seguridad automática",
  FTP_Settings:"Configuración FTP",
  URL_File_Prompt:"Por favor, especifique la ubicación para importar un archivo .xls/.xlsx/.json:",
  URL_SQL_Prompt:"Por favor, especifique la ubicación del archivo SQLite para importar:",
  FTP_Backup:"Copia de seguridad FTP",
  FTP_Import:"Importación FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Anfitrión",
  Port:"Puerto",
  Path:"Sendero",
  Data_Path:"Ruta de datos",
  Enable_FTP_Account:"Habilitar cuenta FTP",
  HostnameIP:"Nombre de host",
  Password:"Contraseña",
  Connection_Port:"Puerto de conexión",
  Enable_MySQL_Database:"Habilitar base de datos MySQL",
  Log:"Tronco",
  Reset:"Reiniciar",
  Erase:"Borrar",
  Export:"Exportar",
  Database:"Base de datos",
  Upload_CSV:"Subir CSV",
  Download_CSV:"Descargar CSV",
  SQL_Database:"URL SQL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Copia de seguridad MySQL",
  Internal_Notes:"Notas internas",
  Root_Path:"Ruta raíz",
  Select_Backup:"Seleccionar copia de seguridad",
  Add_New_Backup:"Agregar una nueva copia de seguridad",
  First_Backup:"Configurar la primera copia de seguridad...",
  Backups:"Copias de seguridad",
  Add_Backup:"Añadir copia de seguridad",
  No_Backups:"No se pueden encontrar copias de seguridad.",
  Select_Backup_Type:"Seleccione el tipo de copia de seguridad que desea configurar...",
  Backup_Type:"Tipo de copia de seguridad",
  FTP_Drive:"Unidad FTP",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Conducir",
  Microsoft_OneDrive:"Conducir",
  Import_Fields:"Importar Campos",
  Import_Fields_Word:"Use esta sección para seleccionar <a routerLink='/carpeta/Vacaciones/Nuevo'>Importar</a> datos.",
  Upload:"Subir",
  Download:"Descargar",
  Download_JSON:"Descargar como datos JSON",
  Download_SQL:"Descargar como archivo SQL",
  New_Bank:"Nuevo Banco",
  New_Account:"Nueva cuenta",
  New_Bank_Account:"Nueva Cuenta Bancaria",
  Upload_Image:"Cargar imagen",
  Bank_Name:"Nombre del banco",
  Bank_Address:"Dirección del banco",
  Branch_Address:"Dirección de sucursal",
  Address_on_Cheque:"Habla a",
  Cheque_Numbers:"Números de verificación",
  Cheque_Details:"Ver detalles",
  Bank_Logo:"Logotipo del banco",
  Cheque_ID:"Comprobar identificación",
  Starting_Cheque_ID:"Iniciando verificación de identificación",
  Transit_Number:"Número de Tránsito",
  Institution_Number:"Número de institución",
  Designation_Number:"Número de designación",
  Account_Number:"Número de cuenta",
  Limits:"Límites",
  Default_Limits:"Límites predeterminados",
  Over_Limit:"Sobre el limite",
  Under_Limit:"Por debajo del límite",
  Payroll_Limit:"Límite de Nómina",
  Enable_Bank_Account:"Habilitar cuenta bancaria",
  Select_Account:"Seleccionar cuenta",
  No_Bank_Account:"Sin cuenta bancaria",
  No_Bank_Account_Word:"Agregue la primera <a routerLink='/carpeta/Cuentas/Nueva'>Cuenta bancaria</a>.",
  Bank_Accounts:"Cuentas bancarias",
  No_Accounts:"Sin cuentas",
  No_Accounts_Note:"Agregue la primera <a routerLink='/carpeta/Cuentas/Nueva'>Cuenta bancaria</a>.",
  Cheque_Designer:"Diseñador de cheques",
  Cheque_Design:"Diseño de cheques",
  Select_Design:"Seleccione un diseño",
  Cheque_Designs:"Diseños de cheques",
  No_Cheque_Designs:"Sin diseños de cheques",
  No_Cheque_Designs_Word:"Cree su propio <a routerLink='/folder/Settings/Cheque/Design/New'>Diseño de Cheques</a>.",
  Set_Default:"Establecer por defecto",
  Default:"Defecto",
  Remove:"Eliminar",
  Folder:"Carpeta",
  Edit:"Editar",
  LoadingDots:"Cargando...",
  Add_a_New_File:"Agregar un <a href='#' (clic)='add()'>Nuevo archivo</a> a",
  this_folder:"esta carpeta",
  FTP_Backup_Settings:"Configuración de copia de seguridad de FTP",
  Secure_File_Transfer:"Transferencia segura de archivos",
  New_Holiday:"Nuevas vacaciones",
  Add_Holiday:"Añadir vacaciones",
  Holiday_Name:"Nombre de vacaciones",
  Additional_Pay:"Pago Adicional",
  Enable_Holiday:"Habilitar vacaciones",
  Select_Holidays:"Seleccionar días festivos",
  No_Holidays:"Sin vacaciones",
  No_Holidays_Word:"Agregue el primer <a routerLink='/folder/Holiday/New'>Día festivo</a>.",
  New_Import:"Nueva importación",
  Import_Data:"Datos de importacion",
  Import_Data_Word:"Seleccione el tipo de archivo o el método de carga de su elección.<br /> Podrá seleccionar los campos importados en un archivo que correspondan a cualquier parámetro en la aplicación después de cargar un archivo compatible",
   
  Import_File:"Importar archivo",
  Link_To_File:"Enlace a archivo",
  Select_File:"Seleccione Archivo",
  New_Moderator:"Nuevo moderador",
  Allow_Moderation:"Permitir moderación",
  Create_Paycheques:"Crear cheques de pago",
  Edit_Paycheques_and_Data:"Editar cheques de pago y datos",
  Destroy_Data_and_Paycheques:"Destruir datos y cheques de pago",
  Bonus_Percentage:"Porcentaje de cheque de pago",
  Fixed_Amount:"Cantidad fija",
  Select_Moderator:"Seleccionar moderador",
  No_Moderators:"Sin moderadores",
  Moderators:"Moderadores",
  Moderator_Account:"Cree la primera <a routerLink='/carpeta/Administrador/Nueva'>Cuenta de moderador</a>.",
  No_Moderators_Word:"Agregue el primer <a routerLink='/carpeta/Administrador/Nuevo'>Moderador</a>.",
  Defaults:"Predeterminados",
  Provide_Us_Info:"Proporcione información",
  Setup_Your_Printer:"Configura tu impresora",
  Your_Company:"Acerca de su empresa",
  Company_Name:"Nombre de empresa",
  Currency:"Divisa",
  Default_Currency:"Moneda predeterminada",
  Base_Monthly_Income:"Ingreso mensual",
  Protection:"Proteccion",
  App_Protection:"Protección de aplicaciones",
  PIN_Code_Protection:"Protección de código PIN",
  App_Protection_Word:"Habilite métodos de seguridad que ayuden a proteger su cuenta.",
  PIN_Code:"Código PIN",
  Change_PIN:"Cambiar PIN",
  New_Password:"Nueva contraseña",
  Geofence_Protection:"Protección de Geo-Cerca",
  Geofence_Area:"Establecer área",
  Distance:"Distancia",
  Setup_Now:"Configurar ahora",
  Mile:"Milla",
  Km:"Km",
  m:"metro",
  Facial_Recognition:"Reconocimiento facial",
  Face:"Rostro",
  Setup:"Configuración",
  Label:"Etiqueta",
  Password_Protection:"Protección de contraseña",
  Modify_Password:"Modificar la contraseña",
  New_Tax_Entry:"Nueva Entrada Tributaria",
  New_Tax:"Nuevo Impuesto",
  Tax_Label:"Etiqueta de impuestos",
  Perpetually_Enabled:"Habilitado perpetuamente",
  Select_Taxes:"Seleccionar Impuestos",
  Tax_Deductions:"Deducciones Fiscales",
  No_Tax_Deductions:"Sin deducciones fiscales",
  No_Tax_Deductions_Word:"Agregue la primera deducción de <a routerLink='/folder/Tax/New'>Impuesto</a>.",
  New_Timer:"Nuevo temporizador",
  Start:"Comienzo",
  Stop:"Detener",
  Start_Timer:"Iniciar temporizador",
  Stop_Timer:"Detener temporizador",
  Timer_Active:"Temporizador activo",
  Timer:"Temporizador:",
  Timer_Running:"Temporizador: (en ejecución)",
  Save_Timer:"Guardar temporizador",
  New_Timesheet:"Nueva hoja de tiempo",
  Select_Timesheets:"Seleccionar hojas de tiempo",
  Work_Notes:"Notas de trabajo",
  Entry_Title:"Título de entrada",
  No_Timesheets:"Sin hojas de tiempo",
  No_Timesheets_Word:"Agregue la primera <a routerLink='/folder/Timesheet/New'>Hoja de horas</a>.",
  Timesheet_Submitted:"Hoja de tiempo enviada",
  Timesheet_Congrats:"¡Felicitaciones! Su hoja de tiempo se ha enviado con éxito. ¡Gracias!",
  Timesheet_Copy:"Para recibir una copia de sus documentos, proporciónenos su dirección de correo electrónico y/o número de teléfono móvil.",
  Submit:"Entregar",
  Allow_Notifications:"Permitir notificaciones",
  Untitled_Entry:"Nueva entrada",
  Untitled_Bank:"Banco sin título",
  Timesheet_Entry:"Entrada de hoja de tiempo",
  Work_Description:"Descripción del trabajo",
  Enable_Timesheet:"Habilitar hoja de tiempo",
  Submit_Timesheet:"Enviar hoja de tiempo",
  Vacation:"Vacaciones",
  New_Vacation:"Nuevas vacaciones",
  Vacation_Name:"Nombre de vacaciones",
  Paid_Vacation:"Vacaciones pagas",
  Vacation_Pay:"Pago de vacaciones",
  Enable_Vacation:"Habilitar vacaciones",
  Select_Vacations:"Seleccionar Vacaciones",
  No_Vacations:"Sin vacaciones",
  No_Vacations_Word:"Cree la primera entrada <a routerLink='/folder/Vacation/New'>Vacaciones</a>.",
  Payroll_Schedule:"Calendario de nómina",
  Next_Payroll:"Siguiente Nómina:",
  Upcoming_Payroll:"Nómina Próxima",
  No_Upcoming_Payroll:"Sin Nómina Próxima",
  Address_Book:"Directorio",
  Archived_Documents:"Documentos Archivados",
  Dev_Mode:"Aplicación en modo de desarrollo",
  Administrators:"Administradores",
  Privacy:"Intimidad",
  None:"Ninguna",
  Select_Signature:"Seleccionar Firma",
  No_Signatures:"Sin firmas",
  No_Signatures_Word:"Agregue la primera <a routerLink='/carpeta/Firma/Nueva'>Firma</a>.",
  Repeat_Indefinitely:"Repetir indefinidamente",
  Sign:"Firmar",
  Sign_Here:"Firma aqui",
  Date_Signed:"Fecha de firma",
  Signature_Pad:"Bloc de firmas",
  Account_Holder:"Titular de la cuenta",
  Account_Properties:"Propiedades de la cuenta",
  Name_On_Cheque:"Nombre en el cheque",
  Server_Hostname:"Nombre de host del servidor/IP",
  Printers:"Impresoras",
  No_Printers:"Sin impresoras",
  Printer_Exists:'Ya existe una impresora con este nombre.',
  No_Printers_Word:"Agregue la primera <a routerLink='/carpeta/Impresora/Nueva'>Impresora</a>.",
  No_Printer_Alert:"No se ha definido ninguna impresora. ¿Desea configurar una impresora?",
  Add_Printer:"Añadir Impresora",
  New_Printer:"Impresora nueva",
  Printer_Hostname:"Nombre de host/IP de la impresora",
  Printer_Label:"Etiqueta de impresora",
  Printer_Protocol:"Protocolo de impresora",
  Protocol:"Protocolo",
  Email_Print:"Correo electrónico",
  AirPrint:"Impresión aérea",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Enchufe",
  Print_Job:"Trabajo de impresión",
  Printed:"Impreso",
  Not_Printed:"No impreso",
  Print_Jobs:"Trabajos de impresión",
  Print_Queue:"Cola de impresión",
  No_Print_Jobs:"Sin trabajos de impresión",
  No_Prints:"Cree un nuevo <a routerLink='/carpeta/Cheque/New'>Cheque</a> para imprimir.",
  Prints:"Huellas dactilares",
  Find_Printer:"Buscar impresora",
  Find_AirPrint_Devices:"Buscar dispositivos AirPrint",
  Select_Printer:"Seleccionar Impresora",
  System_UI:"IU del sistema",
  Printer:"Impresora",
  Status:"Estado",
  Preview:"Avance",
  Enable_Print_Job:"Habilitar trabajo de impresión",
  Queue_Weight:"Peso de cola",
  Unlimited:"Ilimitado",
  Show_Advanced_Printer_Options:"Mostrar opciones avanzadas de impresora",
  Advanced:"Avanzado",
  Location:"Ubicación",
  Note:"Nota",
  Queue_Name:"Nombre de la cola",
  Pages_Printed_Limit:"Límite de páginas impresas",
  MultiPage_Idle_Time:"Tiempo de espera multipágina (s)",
  Page_Count_Limit:"Límite de recuento de páginas",
  Page_Orientation:"Orientación de la página",
  Portrait:"Retrato",
  Landscape:"Paisaje",
  Duplex:"Dúplex",
  Double_Sided_Printing:"Doble cara",
  Duplex_Mode:"Modo dúplex",
  Duplex_Short:"Corto",
  Duplex_Long:"Largo",
  Duplex_None:"Ninguna",
  Color_And_Quality:"Color Y Calidad",
  Monochrome:"Monocromo",
  Photo_Quality_Prints:"Impresiones de calidad fotográfica",
  Printer_Email:"Correo electrónico de la impresora",
  Automatically_Downsize:"Reducir tamaño automáticamente",
  Paper:"Papel",
  Paper_Name:"Nombre de papel",
  Paper_Width:"Ancho de papel",
  Paper_Height:"Altura del papel",
  Paper_Autocut_Length:"Longitud de corte automático de papel",
  Margins:"Márgenes",
  Page_Margins:"Márgenes de página",
  Page_Margin_Top:"Margen de página superior",
  Page_Margin_Right:"Margen de página derecho",
  Page_Margin_Bottom:"Margen inferior de la página",
  Page_Margin_Left:"Margen de página izquierdo",
  Add_Employees:"Añadir Empleados",
  Header:"Encabezamiento",
  Print_A_Page_Header:"Imprimir un encabezado de página",
  Header_Label:"Etiqueta de encabezado",
  Header_Page_Index:"Índice de página de encabezado",
  Header_Font_Name:"Fuente de encabezado",
  Select_Font:"Seleccionar fuente",
  Font_Selector:"Selector de fuente",
  Header_Font_Size:"Fuente de encabezado",
  Header_Bold:"Encabezado en negrita",
  Header_Italic:"Encabezado cursiva",
  Header_Alignment:"Alineación de encabezados",
  Left:"Izquierda",
  Center:"Centrar",
  Right:"Correcto",
  Justified:"Justificado",
  Header_Font_Color:"Color del encabezado",
  Select_Color:"Seleccionar el color",
  Footer:"Pie de página",
  Print_A_Page_Footer:"Imprimir un pie de página",
  Footer_Label:"Etiqueta de pie de página",
  Footer_Page_Index:"Índice de página de pie de página",
  Footer_Font_Name:"Fuente de pie de página",
  Fonts:"Fuentes",
  Done:"Hecho",
  Select_Fonts:"Seleccionar fuentes",
  Footer_Font_Size:"Tamaño de pie de página",
  Footer_Bold:"Pie de página en negrita",
  Footer_Italic:"Pie de página en cursiva",
  Footer_Alignment:"Alineación de pie de página",
  Footer_Font_Color:"Color de pie de página",
  Page_Copies:"Copias de página",
  Enable_Printer:"Habilitar impresora",
  Remote_Logging:"Registro remoto",
  Log_Server:"Servidor de registro",
  Encryption:"Cifrado",
  Random_Key:"Clave aleatoria",
  Encryption_Key:"Clave de encriptación",
  Cheques_Webserver:"Base de datos personalizada",
  Learn_How:"Aprender cómo",
  Signature:"Firma",
  Default_Printer_Unit:"pulgadas/cm/mm/(pt)",
  View_Signature:"Ver firma",
  Printed_Signature:"Firma impresa",
  Digitally_Sign:"Firmar digitalmente",
  Digital_Signature:"Firma digital",
  Add_Signature:"Agregar firma",
  Add_Your_Signature:"Agregue su firma",
  Enable_Signature:"Habilitar firma",
  Digitally_Signed:"Firmado digitalmente",
  Insert_Error:"No se pudo guardar el cheque debido a problemas con la base de datos.",
  Delete_Confirmation:"¿Está seguro de que desea eliminar esta información?",
  Discard_Confirmation:"¿Estás seguro de que quieres descartar esta información?",
  Discard_Bank_Confirmation:"¿Estás seguro de que quieres descartar esta cuenta?",
  Discard_Printer_Confirmation:"¿Está seguro de que desea descartar esta impresora?",
  Delete_Bonus_Confirmation:"¿Está seguro de que desea eliminar este bono?",
  Delete_Invoice_Confirmation:"¿Está seguro de que desea eliminar esta factura?",
  Generated_Cheque:"Cheque generado",
  Generated_Invoice:"Factura Generada",
  Schedule_Start_Time:"Programar inicio",
  Schedule_End_Time:"Fin del horario",
  New_Call:"Nueva llamada",
  No_Contacts:"Sin contactos",
  No_Contacts_Word:"Los administradores, moderadores, empleados y beneficiarios aparecen como contactos.",
  PDF_Subject:"finanzas",
  PDF_Keywords:"nómina cheque pago PDF cheque cheques",
  Printer_Setup:"Configuración de la impresora",
  Printer_Selection:"Selección de impresora",
  New_Fax:"Nuevo Fax",
  New_Fax_Message:"Nuevo mensaje de fax",
  Fax_Machine:"Máquina de fax",
  Fax_Name:"Nombre de fax",
  Fax_Email:"Correo electrónico de fax",
  Fax_Number:"Número de fax",
  Contents:"Contenido",
  Fax_Body:"Cuerpo de página",
  Header_Word:"Encabezamiento:",
  Header_Text:"Texto de cabecera",
  Include_Header:"Incluir encabezado",
  Include_Footer:"Incluir pie de página",
  Footer_Word:"Pie de página:",
  Footer_Text:"Texto de pie de página",
  Attach_a_Cheque:"Adjunte un cheque",
  Add_Deduction:"Añadir Deducción",
  Enable_Fax:"Enviar fax",
  Select_Fax:"Seleccionar Fax",
  No_Faxes:"Sin faxes",
  Faxes:"Faxes",
  Save_and_Send:"Enviar fax",
  Save_and_Pay:"Ahorra y paga",
  WARNING:"ADVERTENCIA:",
  Remember:"Recordar",
  Printing:"Impresión",
  Printing_Complete:"¡Impresión completa!\n\n",
  of:"de",
  There_Were:"Había ",
  Successful_Prints:"impresiones exitosas y",
  Unsuccessful_Prints:" impresiones fallidas.",
  PrinterError:"Lo sentimos, ha habido un error.",
  Printing_:"Impresión...",
  PrinterSuccess:"Documento impreso correctamente.",
  PrintersSuccess:"Documentos impresos correctamente.",
  Print_Cheques:"Imprimir cheques",
  New_Message:"Nuevo mensaje",
  New_Messages:"Nuevos mensajes",
  Read_Message:"Leer el mensaje",
  Write_Message:"Escribe un mensaje",
  Send_Message:"Enviar mensaje",
  Subject:"Sujeto",
  Message:"Mensaje",
  Writing:"Escribiendo...",
  Send:"Enviar",
  Set_Date:"Establece la fecha",
  Set_Time:"Fijar tiempo",
  Recieve:"Recibir",
  Set_as_Default:"Establecer por defecto",
  Default_Account:"Cuenta predeterminada",
  Default_Design:"Diseño predeterminado",
  Multiple_Cheques:"Cheques (Triple)",
  Account_Mode:"Modo de cuenta",
  Multiple_Cheques_Description:"Tres cheques por página.",
  Check_and_Table:"Cheque y tabla",
  Check_including_Table:"Cuadro de cheques y contabilidad.",
  Check_Mailer:"Comprobar correo",
  Check_Mailer_Window:"Comprobar con una ventana de dirección.",
  DocuGard_Table_Top:"DocuGard Check & Table (Arriba)",
  DocuGard_Table_Middle:"DocuGard Check & Table (Medio)",
  DocuGard_Table_Bottom:"Comprobación y tabla de DocuGard (abajo)",
  DocuGard_Mailer_Top:"DocuGard Check Mailer (arriba)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (Medio)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mailer (abajo)",
  DocuGard_Three_Cheques:"Controles DocuGard (Triple)",
  DocuGard_Cheque_Top:"Comprobación de DocuGard (arriba)",
  DocuGard_Cheque_Middle:"Comprobación de DocuGard (centro)",
  DocuGard_Cheque_Bottom:"Comprobación de DocuGard (abajo)",
  DocuGard_Three_Cheques_Window:"Tres cheques en una página.",
  DocuGard_Table_Top_Window:"Tabla de cheques y ganancias.",
  DocuGard_Table_Middle_Window:"Tabla de cheques y ganancias.",
  DocuGard_Table_Bottom_Window:"Tabla de cheques y ganancias.",
  DocuGard_Mailer_Top_Window:"Cheque, mesa y dirección.",
  DocuGard_Mailer_Middle_Window:"Cheque, mesa y dirección.",
  DocuGard_Mailer_Bottom_Window:"Cheque, mesa y dirección.",
  DocuGard_Cheque_Top_Window:"Buscar papel seguro.",
  DocuGard_Cheque_Middle_Window:"Buscar papel seguro.",
  DocuGard_Cheque_Bottom_Window:"Buscar papel seguro.",
  Basic_Cheque:"Verificar (Arriba)",
  Basic_Cheque_Print:"Imprimir un solo cheque.",
  Error_Setting_As_Paid:"Error al configurar como pagado",
  Add_Attachment:"Añadir un adjunto",
  PrinterUnavailable:"Impresora no disponible",
  CreditCardComponent:"Tarjetas",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Nuevo Depósito",
  Deposits:"Depósitos",
  No_Deposits:"Sin depósitos",
  Credit_Card_Deposit:"Tarjeta de crédito",
  New_Credit_Card_Deposit_Message:"Depósito con tarjeta de crédito",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Depósito de Bitcoin",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Transferencia Interac",
  Payments_Limit:"Límite de pago",
  No_Payment_Limit:"Sin límite de pago",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Depósito de PayPal",
  No_Deposits_Word:"Agregue la primera empresa <a routerLink='/folder/Deposit/New'>Depósito</a>.",
  No_Documents_Specified:"No se especificaron documentos para imprimir",
  No_Printers_Added:"No se encontraron impresoras. Vaya a Configuración > Impresoras para agregar una.",
  DB_Erase_Prompt:"¿Borrar completamente toda la base de datos? ADVERTENCIA: ¡Perderás toda la información almacenada!",
  Console_Warning:"No pegues ningún texto en esta consola. Puedes ponerte a ti y/o a tu empresa en grave riesgo.",
  No_Faxes_Word:"Cree el primer <a routerLink='/carpeta/Fax/Nuevo'>Fax</a>.",
  Cheque_Delete_Confirmation:"¿Está seguro de que desea eliminar este cheque?",
  Design_Delete_Confirmation:"¿Está seguro de que desea eliminar este diseño?",
  Cheque_Pay_Confirmation:"¿Marcar este cheque como pagado? NO aparecerá en la cola de impresión.",
  Payment_Pay_Confirmation:"¿Marcar este pago como pagado? NO aparecerá en la cola de cheques.",
  Delete_Deduction_Confirmation:"¿Está seguro de que desea eliminar esta deducción?",
  Delete_Job_Confirmation:"¿Está seguro de que desea eliminar este trabajo?",
  Delete_Timesheet_Confirmation:"¿Está seguro de que desea eliminar esta hoja de tiempo?",
  Delete_Schedule_Confirmation:"¿Estás seguro de que quieres eliminar este horario?",
  Delete_Setting_Confirmation:"¿Está seguro de que desea restablecer esta configuración?",
  Delete_Fax_Confirmation:"¿Está seguro de que desea eliminar este fax?",
  Delete_File_Confirmation:"¿Está seguro de que desea eliminar este archivo?",
  Delete_Vacation_Confirmation:"¿Seguro que quieres borrar estas vacaciones?",
  Delete_Printer_Confirmation:"¿Está seguro de que desea eliminar esta impresora?",
  Remove_Design_Confirmation:"¿Está seguro de que desea eliminar este diseño?",
  Delete_Payroll_Confirmation:"¿Está seguro de que desea eliminar esta nómina?",
  Send_Fax_Email_Confirmation:"¿Desea enviar por fax y correo electrónico estos documentos?",
  Send_Email_Confirmation:"¿Quieres enviar este documento por correo electrónico?",
  Send_Fax_Confirmation:"¿Quiere enviar por fax este documento?",
  Error_Generating_PDF:"Lo sentimos. Hubo un error al generar este documento.",
  PDF_Error_Saving_Image:"Lo sentimos. Hubo un error al guardar la imagen PDF de este documento.",
  Test_Printer_Confirmation:"¿Desea imprimir una página de prueba en esta impresora?",
  Save_Timesheet_Prompt:"Agregue un 'Título' o presione 'Iniciar temporizador' para guardar.",
  Remove_Geofence_Prompt:"¿Está seguro de que desea eliminar la ubicación de esta geo-cerca?",
  Delete_Employee_Confirmation:"Estas seguro que quieres borrarlo ",
  Fire_Employee_Confirmation:"¿Estás seguro de que quieres disparar?"
}