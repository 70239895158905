export const Hu = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Copyright &copy; 2023",
  black:"Fekete",
  green:"Zöld",
  gold:"Arany",
  blue:"Kék",
  brown:"Barna",
  purple:"Lila",
  pink:"Rózsaszín",
  red:"Piros",
  Swatches:"Színminták",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Színárnyalat",
  Saturation:"Telítettség",
  Lightness:"Könnyűség",
  Upgrade_To_Pro:"Frissítés Pro verzióra",
  AllFeaturesInclude:"Minden szolgáltatás a következőket tartalmazza:",
  PrintUnlimitedCheques:"Nyomtasson korlátlan számú csekket",
  PremiumChequeDesigns:"Prémium csekk minták",
  ManageUnlimitedEmployees:"Korlátlan számú alkalmazott kezelése",
  AddUnlimitedPayees:"Korlátlan kedvezményezettek hozzáadása",
  CreateUnlimitedPayrolls:"Korlátlan bérszámfejtés létrehozása",
  UnlimitedSchedulesandTimeSheets:"Korlátlan számú ütemterv és munkaidő",
  BulkPayPalPayouts:"Tömeges PayPal kifizetések",
  UnlimitedBankAccounts:"Korlátlan számú bankszámla",
  ManageMultipleCompanies:"Több vállalat kezelése",
  TrackInsurancePolicies:"Kövesse nyomon a biztosítási kötvényeket",
  Bio_MetricProtection:"Biometrikus védelem",
  Geo_FenceLock_OutProtection:"Geo-Fence Lock-Out védelem",
  Multiple_Companies_Word:"Több cég kezelése nem érhető el Csekk prémium nélkül.",
  PayPal_Payouts_Word:"A PayPal-fizetések le vannak tiltva a csekk prémium nélkül.",
  PINProtection:"PIN védelem",
  PasswordProtection:"Jelszó védelem",
  May_Require_Approval:"Jóváhagyást igényelhet.",
  Subscribe:"Iratkozz fel",
  Billed:"Kiszámlázott",
  Up:"Fel",
  Down:"Le",
  Positioning:"Elhelyezés",
  Marker:"Jelző",
  Drag_Marker:"Drag Marker",
  Tagline:"Csekk nyomtatása és bérszámfejtés táblázata",
  Marker_Word:"Használja a Markereket az elem méretéhez.",
  Pinch_Zoom:"Csipetnyi nagyítás",
  Pinch_Word:"Csípje össze az elemet az elem nagyításához.",
  Drag:"Húzza",
  Drag_Word:"Használja az ujját az elemek húzásához.",
  subscription_alias_word:"Előfizetés automatikus megújítása",
  premium_alias_word:"Egyszeri frissítési csomag",
  print_alias_word:"Egyedi csekk kinyomtatása",
  mode_alias_word:"Mód",
  Pro:"Pro",
  Pro_word:"Pro verzió szükséges.",
  Cant_Delete_Default_Company_Word:"Sajnáljuk, nem törölheti alapértelmezett cégét.",
  Reinsert_Default_Designs:"Helyezze be újra az alapértelmezett terveket",
  Reinsert_Default_Designs_word:"Szeretné újra beszúrni az alapértelmezett terveket?",
  Subscription_Active_Disable_Word:"Ez az előfizetés aktív. Szeretné lemondani ezt a Csekk-előfizetést?",
  Company_Logo:"Vállalati logó",
  ZERO_:"NULLA",
  Disclaimer:"Jogi nyilatkozat",
  Privacy_Policy:"Adatvédelmi irányelvek",
  EULA:"Ellenőrzi az EULA-t",
  Terms_Of_Service:"Szolgáltatás feltételei",
  Terms_Of_Use:"Használati feltételek",
  Refunds:"Pénzvisszatérítési eljárás",
  Single_Print:"1 Ellenőrizze",
  Two_Prints:"2 Ellenőrzés",
  Five_Prints:"5 Ellenőrzés",
  Ten_Prints:"10 csekk",
  Fifteen_Prints:"15 Csekk",
  Twenty_Prints:"20 csekk",
  Thirty_Prints:"30 csekk",
  Image_Added:"Kép hozzáadva",
  Image_Preview:"Kép előnézete",
  No_Image_Was_Selected:"Nincs kép kiválasztva.",
  Cheques_Unlimited:"Korlátlan csekk",
  _Subscription:"Feliratkozás",
  Subscription:"Csekk - 1 hónap",
  Two_Month_Subscription:"Csekk - 2 hónap",
  Three_Month_Subscription:"Csekk - 3 hónap",
  Six_Month_Subscription:"Csekk - 6 hónap",
  Twelve_Month_Subscription:"Csekk - 12 hónap",
  Cheques_Are_Available:"A csekkek kinyomtathatók.",
  Upgrade_Required_Two:"Válasszon ki egy csomagot, és koppintson duplán az ár gombra a vásárlás megkezdéséhez. Nyomtasson professzionális megjelenésű színes csekket másodpercek alatt.",
  Month:"Hónap",
  Due:"Esedékes:",
  Expires:"Lejár:",
  Subscription_Active:"Előfizetés aktív",
  Subscription_Inactive:"Az előfizetés inaktív",
  Purchase_Additional_Cheques:"Vásárol további csekket?",
  Printable_Cheque:"Nyomtatható csekk",
  Printable_Cheques:"Nyomtatható csekk",
  Printable_Cheque_Word:"csekk elérhető a fiókjában.",
  Printable_Cheques_Word:"csekkek állnak rendelkezésre a fiókjában.",
  Max_Amount_Message:"Az Ön által megadott összeg meghaladja az ehhez a rendszerhez beállított maximális összeget:",
  Terms_Required_Word:"A Csekk használatának folytatása előtt el kell fogadnia ezt a megállapodást.",
  Subscriptions:"Előfizetések",
  Product_Upgrades:"Frissítések",
  Mailed_Out_Cheques:"Kiküldött csekkek",
  Enter_A_Company_Name:"Adjon meg egy cégnevet.",
  Single_Cheques:"Egyszeri ellenőrzések",
  Cheque_Golden:"Arany csekk",
  Cheque_Golden_Window:"Arany csekk design.",
  Cheque_Green:"Zöld csekk",
  Cheque_Green_Window:"Zöld kockás design.",
  Cheque_Red:"Piros csekk",
  Cheque_Red_Window:"Piros kockás design.",
  Cheque_Yellow:"Sárga csekk",
  Cheque_Yellow_Window:"Sárga kockás kivitel.",
  Cheque_Statue_of_Liberty:"Szabadságszobor",
  Cheque_Statue_of_Liberty_Window:"A Szabadság-szobor ellenőrzése.",
  Cheque_Green_Wave:"Zöld Hullám",
  Cheque_Green_Wave_Window:"Zöld kockás design.",
  Cheque_Golden_Weave:"Golden Weave",
  Cheque_Golden_Weave_Window:"Elegáns arany kockás kivitel.",
  Cheque_Green_Sun:"Zöld Nap",
  Cheque_Green_Sun_Window:"Mély és nyugtató csekk kialakítás.",
  Cheque_Blue_Checkers:"Kék Dáma",
  Cheque_Blue_Checkers_Window:"Kék kockás kivitel.",
  Cashiers_Check:"Pénztári csekk",
  Cashiers_Check_Window:"Pénztárcsekk stílussablon.",
  Cheque_Aqua_Checkers:"Aqua Dáma",
  Cheque_Aqua_Checkers_Window:"Aqua check design.",
  Cheque_Golden_Checkers:"Arany Dáma",
  Cheque_Golden_Checkers_Window:"Arany csekk design.",
  Upgrade_Unavailable:"Nem érhetők el frissítések",
  Bank_Code_Protection:"Bankszámvédelem",
  Bank_Code_Protection_Word:"Óvja bankszámait attól, hogy egy másik eszközön vagy egy másik felhasználó számára futtassák ezt az alkalmazást. Ez a művelet visszafordíthatatlan. Folytatni?",
  Bank_Code_Protection_Blocked_Word:"A használni kívánt bankszámok egy másik felhasználó vagy eszköz számára vannak fenntartva.",
  Bank_Code_Protection_Error_Word:"A szám ellenőrzése nem sikerült. Kérjük, csatlakozzon az internethez, és próbálja meg újra hozzáadni ezt a bankszámlát.",
  Bank_Code_Protection_Set_Error_Word:"A bankszámvédelem megköveteli, hogy csatlakozzon az internethez. Kérjük, csatlakozzon, és próbálja újra.",
  Upgrade_Unavailable_Word:"Sajnáljuk, problémába ütköztünk az Ön ellenőrzése során. A csekkekre vonatkozó előfizetések és frissítések jelenleg nem vásárolhatók meg az Ön területén.",
  PayPal_Payment_Preview:"PayPal fizetési előnézet",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Válassza a PayPal lehetőséget",
  PayPal_Applications:"PayPal alkalmazások",
  Purchase_With_Apple_Pay:"Vásároljon az Apple Pay segítségével",
  Google_Pay:"Google Pay",
  Companies:"Vállalatok",
  Insurance:"Biztosítás",
  New_PayPal:"Új PayPal",
  Pay_By:"Fizetés:",
  Insure:"Biztosítani",
  Miles:"Mérföld",
  Payment_Method:"Fizetési mód",
  Select_Policies:"Válassza a Házirendek lehetőséget",
  Policies:"Irányelvek",
  Policy:"Irányelv",
  No_PayPal_Account:"Nincs PayPal számla",
  No_Policies:"Nincs biztosítási kötvény",
  New_Policy:"Új szabályzat",
  PayPal_Payment:"PayPal fizetés",
  PayPal_Payments:"PayPal fizetések",
  No_Payment_Selected:"Nincs fizetés kiválasztva",
  Sending_Payment_Word:"Kérjük, várjon... A fizetés elküldése folyamatban van.",
  Sending_Payments_Word:"Kérjük, várjon... A kifizetések küldése folyamatban van.",
  No_Payment_Selected_PayPal:"Nincs kiválasztva küldendő <a routerLink='/folder/Payments'>PayPal Fizetés</a>.",
  Payment_Sent:"Fizetés elküldve",
  PayPal_Payment_Sent:"Ezt a fizetést a PayPal segítségével küldtük el.",
  Copay:"Copay",
  Dead:"Halott",
  Alive:"Élő",
  Not_Dead:"Nem halt meg",
  Unclaimed:"Nem igényelt",
  Attempted:"Megpróbált",
  Deceased:"Elhunyt",
  Claimed:"Azt állította",
  Unpaid:"Kifizetetlen",
  Sending_Payment:"Fizetés küldése",
  Sending_Payments:"Fizetések küldése",
  Send_PayPal_Confirmation:"El akarja küldeni ezt a tranzakciót a PayPal segítségével?",
  Send_PayPal_Confirmation_Word:"Nyomja meg a zöld gombot a tranzakció elküldéséhez.",
  Save_Payment_As_Unpaid:"Menti ezt a fizetést kifizetetlenként?",
  Payment_Pay_Confirmation_PayPal:"Menti ezt a fizetést kifizetettként?",
  No_Policies_Word:"Adja hozzá most az első <a routerLink='/folder/Postage/New'>Biztosítási Kötvényt</a>.",
  Timesheet_Multiple_Delete_Confirmation:"Biztos benne, hogy több munkaidő-nyilvántartást szeretne törölni?",
  Select_Multiple_Timesheets_Prompt:"Nincs kiválasztva munkaidő-nyilvántartás. Válasszon ki legalább egy munkaidő-nyilvántartást.",
  Select_Multiple_Policies_Prompt:"Nincsenek kiválasztva házirendek. Válasszon ki legalább egy biztosítást.",
  Policies_Multiple_Delete_Confirmation:"Biztosan törölni szeretne több irányelvet?",
  Company:"Vállalat",
  Add_Company:"Cég hozzáadása lehetőségre",
  New_Company:"Cég hozzáadása lehetőségre",
  No_Companies:"Nincsenek cégek",
  Enable_Company:"Vállalat engedélyezése",
  Select_Company:"Válassza a Vállalat lehetőséget",
  The_Default_Company:"Az alapértelmezett cégcímke.",
  Manage_Companies:"Cégek kezelése",
  No_Companies_Word:"céget használ .<br />Adja hozzá az <a routerLink='/folder/Company/New'>Első Vállalatot</a>.",
  Default_Company:"Alapértelmezett vállalat",
  Cheques_Unlimited_Word:"A Checks Unlimited lehetővé teszi, hogy tetszőleges számú csekket nyomtasson ki.",
  Cheques_Subscription_Word:"A csekk-előfizetéssel annyi csekket nyomtathat, amennyit csak akar.",
  You_Own_This_Product:"Ön a termék tulajdonosa.",
  Your_Subscription_is_Active:"Az előfizetésed aktív.",
  Active_Products:"Aktivált termékek",
  Purchase_Confirmation:"Vásárlás",
  Purchase_Cheques:"Vásárlási csekk",
  Restore_Purchase:"Vásárlás visszaállítása",
  Erase_Purchase:"Vásárlások törlése",
  Successfully_Purchased:"Sikeres vásárlás",
  Enter_Your_Licence_Key:"Kérjük, adja meg licenckulcsát ezen az oldalon a termék aktiválásához.",
  Licence_Key:"Licenckulcs",
  Enter_Licence_Key:"Írja be a licenckulcsot",
  Purchased:"Vásárolt",
  Enable_Feature:"Funkció engedélyezése",
  Cancel_Subscription:"Feliratkozás visszavonása",
  Subscription_Removed:"Előfizetés eltávolítva",
  Select_Active_Subscription:"Válasszon egy aktív előfizetést a módosításához.",
  Remove_Subscription_Word:"Biztosan lemondja ezt az előfizetést?",
  Delete_Company_Confirmation:"Biztosan törli ezt a teljes céget? FIGYELMEZTETÉS: Minden tárolt információ elveszik!",
  Delete_Default_Company_Word:"Nem törölheti az alapértelmezett céget. Szeretné visszaállítani az alkalmazást, és visszaállítani az alapértelmezett állapotba? FIGYELMEZTETÉS: Minden tárolt információ elveszik!",
  Console_Warning_2:"Ne kezeljen olyan valutát ezzel az alkalmazással, amely jelenleg nem az Öné.",
  Terms_and_Conditions:"Felhasználási feltételek",
  and_the:"és a",
  for:"számára",
  Please_Read_Word:"Kérjük, olvassa el és fogadja el a",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Egyes valutaátváltási árfolyamok nem találhatók. Kérjük, csatlakozzon az internethez.",
  Free:"Ingyenes",
  DB_Erase_Prompt_2:"Teljesen törli a teljes fejlesztői adatbázist? FIGYELMEZTETÉS: Elveszít minden VÁSÁRLÁSI és ELŐFIZETÉSI információt!",
  Successfully_Imported:"Sikeres importálás",
  Select_Postage:"Válassza a Postaköltséget",
  No_Postage:"Nincs posta bélyeg",
  No_Paid_Postage_Word:"Adja hozzá az első <a routerLink='/folder/Postage/New'>Fizetett Postaköltség</a> bélyeget.",
  Trial_Complete:'A próbaverzió befejeződött',
  Please_Upgrade:'A nyomtatás folytatásához frissítse a csekkeket.',
  Aa:"Aa",
  Color:"Szín",
  Font:"Betűtípus",
  Guide:"Útmutató",
  Guides:"Útmutatók",
  Image:"Kép",
  Zoom:"Zoomolás",
  Logo:"Logó",
  Bank:"Bank",
  MICR:"MICR",
  Total:"Teljes",
  Cancel:"Megszünteti",
  Confirm:"megerősít",
  Method:"Módszer",
  Biometric_Security:"Biometrikus biztonság",
  Please_Login_To_Continue:"A folytatáshoz jelentkezzen be.",
  Complete:"teljes",
  Sign_Up:"Regisztrálj",
  Error:"Hiba",
  Biometrics:"Biometria",
  Percent:"Százalék",
  Zero_Percent:"0%",
  Top_Margin:"Felső margó",
  Bottom_Margin:"Alsó margó",
  Left_Margin:"Bal margó",
  Right_Margin:"Jobb margó",
  MICR_Margin:"MICR margó",
  Table_Margin:"Táblázat margója",
  Address_Margin:"Cím margója",
  Percentage_:"Százalék",
  Vacation_Title:"Nyaralás címe",
  Use_PIN:"PIN kód használata",
  Loading__:"Betöltés...",
  Design_Title:"Tervezési cím",
  Authorize:"Engedélyezze",
  Key:"Kulcs",
  Public_Key:"Nyilvános kulcs",
  Private_Key:"Privát kulcs",
  Authenticate:"Hitelesítés",
  Last_Payroll:"Utolsó bérszámfejtés",
  Last_Calculation:"Utolsó számítás",
  Authorized:"Felhatalmazott",
  Geo_Authorized:"Földrajzi hely: Engedélyezett",
  Not_Authorized:"Nem engedélyezett",
  Authorization_Complete:"Az engedélyezés kész",
  Geolocation_Authorization:"Földrajzi hely engedélyezése",
  Out_of_Bounds:"Kötetlenül",
  Cant_Delete_Default_Design:"Az alapértelmezett terv nem törölhető.",
  Unauthorized_Out_of_Bounds:"Jogosulatlan: határokon kívül",
  Biometric_Authorization:"Biometrikus engedélyezés",
  Locating_Please_Wait:"Helymeghatározás, kérem várjon...",
  Test_Biometrics:"Biometria tesztelése",
  Cheque_Margins:"Ellenőrizze a margókat",
  Percentage_Full_Error:"A százalékos mező nem állítható 100% fölé.",
  No_Payroll_Text:"Adjon hozzá egy <a routerLink='/folder/Employee/New'>Alkalmazottat</a> vagy <a routerLink='/folder/Payee/New'>Kedvezményezettet</a> és egy <a routerLink='/folder/Schedule/New'>Ütemezés</a>.",
  Design_Saved:"Tervezés mentve",
  Default_Design_Selected:"Alapértelmezett kialakítás kiválasztva",
  Partial_Import:"Részleges import",
  Partial_Export:"Részleges export",
  Entire_Import:"Teljes import",
  Entire_Export:"Teljes export",
  Existing_Password:"Kérjük, adja meg meglévő jelszavát:",
  Existing_PIN:"Kérjük, adja meg meglévő PIN kódját:",
  Pin_Change_Header:"PIN megerősítése",
  Pin_Change_SubHeader:"A módosítás megerősítéséhez írja be régi PIN-kódját.",
  Pass_Change_Header:"Jelszó megerősítése",
  Pass_Change_SubHeader:"Adja meg régi jelszavát a módosítás megerősítéséhez.",
  PIN_Enter_Message:"A megerősítéshez írja be a PIN-kódot.",
  Password_Enter_Message:"A megerősítéshez adja meg jelszavát.",
  Pin_Updated_Success:"A PIN-kód sikeresen frissítve!",
  Pin_Updated_Fail:"A PIN-kód nem frissíthető.",
  Pass_Updated_Success:"A jelszó sikeresen frissítve.",
  Pass_Updated_Fail:"A jelszót nem sikerült frissíteni.",
  Preview_Payment:"Fizetés előnézete",
  Preview_Payroll:"Bérszámfejtés előnézete",
  No_Payments_Created:"Nem találtunk kifizetést létrehozva.",
  Payment_Preview:"Fizetési előnézet",
  Enable_Payee:"Kedvezményezett engedélyezése",
  Rendered:"Renderelt",
  No_Email:"Nincs e-mail",
  Setup_Cheques:"Beállítási ellenőrzések",
  name:"Név",
  address:"Cím",
  address_2:"Cím sor 2",
  city:"Város",
  province:"Tartomány",
  postal_code:"Postai / irányítószám",
  country:"Ország",
  username:"Felhasználónév",
  full_name:"Teljes név",
  birthday:"Születésnap",
  email:"Email",
  phone:"Telefon",
  employees:"Alkalmazottak",
  addresses:"Címek",
  payment_amount_limit:"Fizetési összeghatár",
  total_limit:"Teljes limit",
  payees:"Kedvezményezettek",
  description:"Leírás",
  address_line_one:"Cím első sora",
  address_line_two:"Cím második sora",
  image:"Kép",
  account_holder:"Számlatulajdonos",
  cheque_starting_id:"Ellenőrizze a kezdőazonosítót",
  transit_number:"Tranzitszám",
  institution_number:"Intézmény száma",
  designation_number:"Megnevezési szám",
  account_number:"Számlaszám",
  currency:"Valuta",
  signature:"Aláírás",
  payment_payroll_limit:"Fizetési limit",
  total_limi:"Teljes limit",
  date:"Dátum",
  printed_memo:"Nyomtatott feljegyzés",
  banks:"Bankok",
  signature_image:"Aláírási kép",
  address_name:"Cím Név",
  notes:"Megjegyzések",
  design:"Tervezés",
  title:"Cím",
  frequency:"Frekvencia",
  fax:"Fax",
  salaries:"Fizetések",
  salary_ids:"Fizetési azonosítók",
  start_time:"Kezdési idő",
  end_time:"Idő vége",
  paid:"Fizetett",
  overtime_percentage:"Fizetett százalék",
  overtime_amount:"Fizetett fix összeg",
  first_name:"Keresztnév",
  last_name:"Vezetéknév",
  moderation:"Moderálás",
  create:"Teremt",
  edit:"Szerkesztés",
  destroy:"Elpusztítani",
  day_start_time:"Day_start_time",
  day_end_time:"Day_end_time",
  fullname:"Név",
  time:"Idő",
  auto_send:"Automatikus küldés",
  time_method:"Idő módszer",
  schedules:"Menetrendek",
  indefinite_payroll_enabled:"Engedélyezze határozatlan ideig",
  amount:"Összeg",
  repeat:"Ismétlés",
  always_enabled:"Mindig engedélyezve",
  start_date:"Kezdő dátum",
  end_date:"Befejezés dátuma",
  Cheque_Total:"Ellenőrizze Összesen",
  Total_Amount:"Teljes összeg:",
  Amounts:"Összegek:",
  Images:"Képek",
  Files:"Fájlok",
  Previewing:"Előnézet:",
  Insert:"Beszúrás",
  Preview_Import:"Előnézet importálása",
  Entry:"Belépés",
  Entries:"Bejegyzés",
  No_Entries:"Nincs bejegyzés",
  Import_Type:"Importálás típusa",
  Select_Details:"Válassza a Részletek lehetőséget",
  Select_Payee:"Válassza a Kedvezményezett lehetőséget",
  Enable_Holidays:"Ünnepnapok engedélyezése",
  Disable_Holidays:"Ünnepnapok letiltása",
  Wire_Transfer:"Vezetékes átvitel",
  New_Export:"Új export",
  Export_Data:"Adatok exportálása",
  Export_Data_Word:"Válassza ki az exportálandó és letöltendő fájl típusát.",
  Export_File:"Fájl exportálása",
  Mode:"Mód",
  Times:"Times",
  Widgets:"Widgetek",
  Slider:"Csúszka",
  Set_Details:"Részletek beállítása",
  Select_Type:"Válassza a Típus lehetőséget",
  Display_Slider:"Display Slider",
  Dashboard_Slider:"Irányítópult csúszka",
  Dashboard_Mode:"Irányítópult mód",
  Show_Intro:"Show Intro",
  Show_Payrolls:"Bérjegyzékek megjelenítése",
  Show_Holidays:"Ünnepek megjelenítése",
  Show_Invoices:"Számlák megjelenítése",
  Show_Cheques:"Csekk megjelenítése",
  Enabled_Widgets:"Engedélyezett widgetek",
  Disabled_Widgets:"Letiltott widgetek",
  Colors:"Színek",
  Barcodes:"Vonalkódok",
  View_Timers:"Időzítők megtekintése",
  Gradients:"Színátmenetek",
  No_Info:"Nincs információ",
  Disable:"Letiltás",
  Show_Layer:"Rétegek megjelenítése",
  Hide_Layer:"Rétegek elrejtése",
  Text_Layer:"Szövegrétegek",
  Secondly:"Másodszor",
  Minutely:"Percenként",
  nine_am:"DÉLELŐTT 9:00",
  five_pm:"05:00",
  Enable_Address:"Cím engedélyezése",
  Invalid_File_Type:"Sajnáljuk, érvénytelen fájltípus lett kiválasztva. Támogatott fájltípus:",
  Error_Updating_Entry:"Elnézést, hiba történt a bejegyzés frissítésekor.",
  Schedule_Timing_Alert:"Hiba: Az ütemezés kezdő időpontja a befejezési időpont utáni értékre van beállítva.",
  Select_Multiple_Payments_Prompt:"Nincs kiválasztva fizetés. Válasszon ki legalább egy fizetést.",
  Select_Multiple_Cheques_Prompt:"Nincsenek kiválasztva csekk. Kérjük, válasszon legalább egy csekket.",
  Select_Multiple_Items_Prompt:"Nincsenek kiválasztva elemek. Kérjük, válasszon legalább egy elemet.",
  Paymemt_Multiple_Delete_Confirmation:"Biztosan törölni szeretne több fizetést?",
  Cheque_Multiple_Delete_Confirmation:"Biztosan törölni szeretne több csekket?",
  Payee_Multiple_Delete_Confirmation:"Biztos benne, hogy több kedvezményezettet szeretne törölni?",
  Employee_Multiple_Delete_Confirmation:"Biztosan törölni szeretne több alkalmazottat?",
  MICR_Warning:"Megjegyzés: Előfordulhat, hogy egyes nyomtatók és eszközök nem jelenítik meg megfelelően a MICR betűtípust.",
  Automatically_Send:"Automatikus küldés",
  Type:"típus",
  Payment_Type:"Fizetési mód",
  Auto_Send:"Automatikus küldés",
  Automatically_Process:"Automatikus feldolgozás",
  Auto_Process:"Automatikus folyamat",
  Image_Based:"Kép alapú",
  Font_Based:"Betűtípus alapú",
  Rerender:"Újra renderelni",
  Rendering:"Renderelés",
  Render:"Fájlok",
  Top:"Top",
  Middle:"Középső",
  Bottom:"Alsó",
  Top_Left:"Bal felső",
  Top_Center:"Felső központ",
  Top_Right:"Jobb felső",
  Middle_Left:"Bal középső",
  Middle_Center:"Középső központ",
  Middle_Right:"Jobb középső",
  Bottom_Left:"Bal alsó",
  Bottom_Center:"Alsó központ",
  Bottom_Right:"Jobb alsó",
  Numbers:"Számok",
  Verified:"Ellenőrzött",
  Paper_Size:"Papírméret",
  New_Device:"Új készülék",
  Add_Device:"Eszköz hozzáadása",
  Remove_Device:"Eszköz eltávolítása",
  Delete_Device:"Eszköz törlése",
  Block_Device:"Eszköz blokkolása",
  Block:"Blokk",
  Unblock:"Blokkolás feloldása",
  Table:"asztal",
  Scan_Login_Code:"Bejelentkezési kód beolvasása",
  Login_Code:"Bejelentkezési kód",
  Scan_Code:"Scan Code",
  Scan_QR_Code:"QR kód beolvasása",
  Select_All:"Mindet kiválaszt",
  Deselect_All:"Törölje az Összes kijelölését",
  Increase:"Növekedés",
  Decrease:"Csökken",
  Bold:"Bátor",
  Text:"Szöveg",
  Style:"Stílus",
  Italic:"Dőlt",
  QR_Code:"QR-kód",
  Barcode:"Vonalkód",
  Browse_Images:"Képek böngészése",
  Browse_Files:"Tallózás a fájlok között",
  Background_Image:"Háttérkép",
  Logo_Image:"Logo kép",
  Header_Image:"Fejléckép",
  Bank_Image:"Bankkép",
  Cut_Lines:"Vágott vonalak",
  Background:"Háttér",
  License:"Engedély",
  One_License:"1 engedély:",
  Licensed:"Engedélyezve:",
  Not_Licensed:"Nem licencelt",
  Enter_Serial:"Írja be a sorozatot",
  Serial_Key:"Termékkulcs",
  Serial:"Sorozatszám",
  Product_Key:"Termékkulcs",
  Check_Product_Key:"Ellenőrizze a termékkulcsot",
  Add_Product_Key:"Termékkulcs hozzáadása",
  Verifying_Purchase:"Vásárlás ellenőrzése...",
  Print_Envelope:"Nyomtasson borítékot",
  Envelope:"Boríték",
  Thank_You:"Köszönöm!",
  Scale:"Skála",
  Print_Scale:"Nyomtatási lépték",
  Borders:"Határok",
  VOID:"ÜRES",
  Void_Cheque:"Érvénytelen ellenőrzés",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"FIZETÉS MEGRENDELÉSÉRE:",
  NO_DOLLARS:"NINCS DOLLÁR ",
  ONE_DOLLAR:"EGY DOLLÁR",
  DOLLARS:" DOLLÁROK",
  AND:" ÉS ",
  CENTS:" CENT.",
  NO_:"NEM ",
  ONE_:"EGY ",
  AND_NO_:"ÉS NEM ",
  _AND_ONE_:"ÉS EGY ",
  DOLLARS_AND_ONE_CENT:" ÉS EGY CENT.",
  AND_NO_CENTS:" ÉS NULLA CENT.",
  CHEQUE_PRINT_DATE:"DÁTUM:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Kezdeti feladatok",
  Inital_Setup:"Kezdő beállítás",
  Welcome_To:"Isten hozott a ",
  Welcome:"Üdvözöljük",
  Swipe:"Elcsór",
  Intro_Setup:"Bevezető beállítás",
  Introduction:"Bevezetés",
  CHEQUE_PRINT_CREDIT:"Csekkekkel működik",
  Title_Intro_Word:"Üdvözöljük a csekkeknél",
  Title_Intro:"Csekk bemutatása",
  Title_Setup:"Csekk beállítása",
  PayPal_Default_Email_Message:"Új PayPal-átutalást kapott. [Powered by Cheques]",
  Cheques_App_Export:"Csekkekkel exportálva",
  Post_Intro_Thanks:"Köszönjük, hogy a csekkeket választotta. A beállítási folyamat befejeződött.",
  Post_Intro_Word:"Kezdje ki az első csekk kinyomtatásával, adjon hozzá egy jövőbeli kifizetést, vagy vegyen fel egy alkalmazottat a bérjegyzékbe.",
  Upgrade_Required:"A csekkekhez a szoftver prémium verziójával kell rendelkeznie, hogy elrejtse ezt az üzenetet és feloldja a további funkciókat.",
  Upgrade_Title:"Csekk",
  Mailout_Prompt:"Dönthet továbbá úgy, hogy a Csekk postázza a bérszámfejtési csekkjeit.",
  Mailed_Cheque:"Mailed Check: ",
  Mailed_Cheque_Color:"Postán elküldött csekk (szín): ",
  Terms_Purchase:"Minden csekkel végzett elektronikus vásárlás teljes mértékben visszatéríthető a vásárlás dátumától számított 30 napig. Kérjük, olvassa el és fogadja el az <a href='#'>Általános Szerződési Feltételeket</a>, mielőtt folytatná.",
  Dashboard_Setup:"Az elsődleges nyomtató beállítása",
  Dashboard_Add:"Elsődleges bankszámla hozzáadása",
  Dashboard_Customize:"Select A Check Template",
  Dashboard_Job_Salary:"Hozza létre állását és adja hozzá fizetését",
  Dashboard_Employees:"Alkalmazottak és kedvezményezettek nyomon követése",
  Dashboard_Print:"Nyomtassa ki és küldje el a fizetését",
  Dashboard_Payroll:"Automatizálja a bérszámfejtést",
  Dashboard_PayPal:"PayPal bérszámfejtés / kifizetések beállítása",
  Begin_Setup:"Begin Setup",
  Get_Started:"Fogj neki",
  Purchase:"Vásárlás",
  Lockdown:"Lezárás",
  Unlock:"Kinyit",
  Detailed:"Részletes",
  Log_In:"Belépés",
  Login:"Belépés",
  Launch:"Dob",
  Register:"Regisztráció",
  Finish:"Befejez",
  List:"Lista",
  Weekends:"Hétvégén",
  Weekly:"Heti",
  PayPal_Default_Subject:"Új fizetés",
  Payment_Message:"Fizetési üzenet",
  PayPal_Default_Payment_Note:"Köszönöm",
  Setup_Your_Cheqing_Account:"Csekkszámla",
  Add_Your_Primary_Cheqing_Account:"Add your first checking account.",
  Welcome_Word:"A bérszámfejtés és az emberi erőforrás menedzsment egyszerűsítése és automatizálása.",
  Get_Started_Quickly:"Csak válaszoljon néhány egyszerű kérdésre, amelyek segítenek az indulásban...",
  Done_Intro_Word:"Beállítás kész",
  PIN_Protected:"Jelszó és PIN-kóddal védett",
  Enter_New_PIN:"Írjon be új PIN kódot:",
  Enter_PIN:"Írja be a PIN kódot:",
  Invalid_PIN:"Érvénytelen PIN kódot írt be.",
  Account_Identifier:"Számlaazonosító",
  New_Account_Identifier:"Új fiókazonosító",
  attempt:"kísérlet",
  attempts:"kísérletek",
  remaining:"többi",
  Language:"Nyelv",
  languages:"Nyelvek",
  select_languages:"Válasszon nyelvet",
  Deposit:"Letét",
  Hire_One_Now:"Béreljen most egyet",
  App_Locked:"Az alkalmazás zárolva van.",
  Skip_:"kihagyás",
  Skip_to_Dashboard:"Ugrás az irányítópultra",
  Dashboard:"Irányítópult",
  Import:"Importálás",
  Admin:"Adminisztrátorok",
  New_Admin:"Új adminisztrátor",
  Settings:"Beállítások",
  Color_Picker:"Színválasztó",
  Font_Picker:"Betűtípusválasztó",
  Logout:"Kijelentkezés",
  Close:"Bezárás",
  Close_menu:"Bezárás",
  Excel:"Excel fájl",
  Csv:"CSV fájl",
  Sql:"SQL fájl",
  Json:"JSON fájl",
  Url:"Importálás URL alapján",
  Back:"Vissza",
  Timers:"Időzítők",
  Cheque:"Jelölje be",
  Print:"Nyomtatás",
  Designs:"Dizájnok",
  Pause_Printing:"Nyomtatás szüneteltetése",
  Resume_Printing:"Nyomtatás folytatása",
  Printing_Paused:"A nyomtatás szünetel",
  PrintingUnavailable:"Sajnos! A nyomtatás nem érhető el ezen a rendszeren.",
  Prints_Paused:"A nyomtatás szünetel",
  Administration:"Adminisztráció",
  Loading:"Betöltés",
  Unnamed:"Névtelen",
  error:"Sajnos ez a csekk nem nyitható meg megtekintésre.",
  No_Cheques_To_Print:"Nincs kinyomtatandó csekk",
  No_Cheques_To_Print_Word:"Hozzon létre egy <a routerLink='/folder/Cheque/New'>Új Csekk</a>.",
  New_Cheque:"Új csekk",
  Start_One_Now:"Kezdj egyet most",
  Edit_Cheque:"Edit Check",
  Select_Cheques:"Csekk kiválasztása",
  Select_Employee:"Válasszon alkalmazottat",
  Default_Printer:"Alapértelmezett nyomtató",
  Reassign:"Újra hozzárendelés",
  Configure:"Beállítás",
  Template:"Sablon",
  Designer:"Tervező",
  Edit_Designs:"Dizájnok szerkesztése",
  New_Design:"Új dizájn",
  Next:"Következő",
  Save:"Megment",
  Name:"Név",
  Mail:"Levél",
  Amount:"Összeg",
  Date:"Dátum",
  PayPal:"PayPal",
  Payouts:"Kifizetések",
  PayPal_Label:"PayPal Label",
  Email_Username:"E-mail / Felhasználónév",
  Client_ID:"Ügyfélazonosító",
  Sandbox_Email:"Sandbox Email",
  PayPal_Email:"PayPal e-mail",
  PayPal_Username:"API felhasználónév",
  PayPal_Payouts:"PayPal kifizetések",
  Select_PayPal_Key:"PayPal kulcs kiválasztása",
  Secret:"Titok",
  API_Secret:"API titkos",
  PayPal_API_Keys:"PayPal kulcsok",
  New_PayPal_Key:"Új PayPal kulcs",
  Email_Subject:"Email tárgy",
  Email_Message:"E-mail üzenet",
  Payout_Options:"Kifizetési lehetőségek",
  Payment_Note:"Fizetési megjegyzés",
  Enable_Employee:"Alkalmazott engedélyezése",
  Enable_Production_Mode:"Produkciós mód engedélyezése",
  Sandbox_Username:"Sandbox felhasználónév",
  Sandbox_Signature:"Sandbox Signature",
  Sandbox_Password:"Sandbox jelszó",
  Production_Username:"Produkciós felhasználónév",
  Production_Signature:"Produkciós aláírás",
  Production_Password:"Gyártó jelszó",
  Production_Email:"Produkciós e-mail",
  API_Client_ID:"API ügyfél-azonosító",
  API_Signature:"API aláírás",
  API_Password:"API jelszó",
  API_Username:"API felhasználónév",
  Secret_Key:"Titkos kulcs",
  Sandbox:"Sandbox",
  Production:"Termelés",
  Sandbox_Keys:"Sandbox Keys",
  Production_Keys:"Produkciós kulcsok",
  API_Title:"API cím",
  Production_Mode:"Produkciós mód",
  Account_Label:"Számlacímke",
  No_PayPal_Setup:"Nincs PayPal kulcs",
  Enable_PayPal_Account:"PayPal fiók engedélyezése",
  No_PayPal_Word:"Add your <a routerLink='/folder/Invoice/New'>PayPal API Kulcs</a>.",
  Printed_Memo:"Nyomtatott memo",
  Employee:"Munkavállaló",
  View_Employee:"Munkavállaló megtekintése",
  Mailing_Address:"Levelezési cím",
  Company_Address:"Cég címe",
  Select_Company_Address:"Válassza ki a cég címét",
  Address:"Cím",
  Any_Day:"Bármikor",
  Address_Name:"Cím Név",
  Unit:"Mértékegység",
  Account:"Számla",
  Bank_Account:"Bankszámla",
  Account_Limits:"Fiókkorlátok engedélyezése",
  Payroll:"Bérszámfejtés",
  New_Payroll:"Új bérszámfejtés",
  No_Payroll:"Nincs közelgő bérszámfejtés",
  Upcoming_Holiday:"Közelgő ünnep:",
  Invoice_Due:"Számla esedékessége:",
  New_Invoice:"Új számla",
  No_Invoices:"Nincs számla",
  No_Invoices_Word:"Hozza létre az első <a routerLink='/folder/Invoice/New'>Számlát</a>.",
  Cheque_Due:"Csekk esedékessége:",
  Payrolls:"Bérszámfejtés",
  Sandbox_Mode:"Tesztelői üzemmód",
  Hire:"Bérel",
  Pay:"Fizetés",
  New:"Új",
  Add:"Hozzáadás",
  Make:"Készíts",
  Time:"Idő",
  Write:"Ír",
  Holiday:"Ünnep",
  Holidays:"Ünnepek",
  Next_Holiday:"Következő nyaralás:",
  All_Done:"Minden kész!",
  Employees:"Alkalmazottak",
  Payees:"Kedvezményezettek",
  Job:"Munka",
  Jobs:"Munkák",
  Invoice:"Számla",
  Invoices:"Számlák",
  Vacations:"nyaralás",
  Cheques:"Csekk",
  Brand_Cheques:"Márka",
  Payment:"Fizetés",
  Enable_Payment:"Fizetés engedélyezése",
  Payments:"Kifizetések",
  Schedule:"Menetrend",
  Schedules:"menetrendek",
  Timesheet:"Jelenléti ív",
  Timesheets:"Időtáblák",
  Salary:"Fizetés",
  New_Address:"Új cím",
  Address_2:"Cím sor 2)",
  _City:"Város",
  _State:"Állam/Prov",
  City:"Város / Település",
  State:"Állam / Tartomány",
  Postal:"Postai / irányítószám",
  ZIP:"Posta / ZIP",
  Country:"Ország",
  Addresses:"Címek",
  Required_Options:"Szükséges opciók",
  Optional_Options:"Opcionális opciók",
  Additional_Options:"További lehetőségek",
  Required:"Kívánt",
  Optional:"Választható",
  Additional:"További",
  No_Addresses:"Nincs cím",
  New_Address_Word:"Add az első <a routerLink='/folder/Address/New'>Címet</a>.",
  Select_Address:"Cím kiválasztása",
  No_Address:"Nincs cím",
  Print_Cheque:"Nyomtatási csekk",
  Print_Cheque_Now:"Nyomtatási ellenőrzés most",
  Description:"Leírás",
  Pay_To_The_Order_Of:"Fizetési sorrend:",
  Select_Date_Range:"Dátumtartomány kiválasztása",
  Select_Starting_Date:"Válassza ki a kezdő dátumot",
  Select_Ending_Date:"Select Ending Date",
  Select_Date:"Dátum kiválasztása",
  Cheque_Date:"Dátum ellenőrzése",
  Cheque_Memo:"Megjegyzés ellenőrzése",
  Blank_Cheque:"Biankó csekk",
  Blank:"Üres",
  No_Cheques:"Nincs ellenőrzés",
  No_Cheques_Word:"Nyomtassa ki az első <a routerLink='/folder/Cheque/New'>csekket</a>.",
  Cheque_Amount_Placeholder:"0,00 USD",
  View_Image:"Kép megtekintése",
  View:"Kilátás",
  Modify:"Módosít",
  Delete:"Töröl",
  Cheque_Paid:"Fizetett",
  New_Deduction:"Új levonás",
  Title:"Cím",
  Frequency:"Frekvencia",
  Hourly:"Óránkénti",
  Daily:"Napi",
  Weekdays:"Hétköznapok",
  BiWeekly:"2 hét",
  TriWeekly:"3 hét",
  Monthly:"Havi",
  MiMonthly:"2 hónap",
  Quarterly:"Negyedévenként",
  Yearly:"Évi",
  Every_Week:"Minden héten",
  Every_Payroll:"Minden bérszámfejtés",
  Every_Month:"Minden hónap",
  Annually:"Évente",
  Always_Scheduled:"Mindig ütemezett",
  Start_Date:"Kezdő dátum",
  End_Date:"Befejezés dátuma",
  Start_Time:"Kezdési idő",
  End_Time:"Idő vége",
  Deduction_Label:"Levonási címke",
  Notes:"Jegyzetek",
  Options:"Opciók",
  Enable:"Engedélyezze",
  Select_Deductions:"Válasszon levonásokat",
  Deductions:"Levonások",
  No_Deductions:"Nincs levonás",
  No_Deductions_Word:"Create your first <a routerLink='/folder/Deduction/New'>Levonás</a>.",
  New_Employee:"Új alkalmazott",
  No_Title:"Nincs cím",
  _Name:"Név",
  About_Yourself:"Magadról",
  Full_Name:"Teljes név",
  Birthday:"Születésnap",
  Email:"Email",
  SMS:"SMS",
  Phone:"Telefon",
  Test:"Teszt",
  Call:"Hívás",
  Fax:"Fax",
  Printed_Note:"Nyomtatott feljegyzés",
  Position:"Pozíció",
  Job_Position:"Munkaköri beosztás",
  Select_a_Job:"Válasszon állást",
  Select_a_Salary:"Válasszon fizetést",
  Add_a_Deduction:"Levonás hozzáadása",
  Taxes:"Adók",
  Add_Taxes:"Adók hozzáadása",
  Date_of_Birth:"Születési dátum",
  Email_Address:"Email cím",
  Phone_Number:"Telefonszám",
  Phone_Call:"Telefon hívás",
  Enable_on_Payroll:"Engedélyezés bérszámfejtésre",
  Select_Employees:"Alkalmazottak kiválasztása",
  No_Employees:"Nincs alkalmazott",
  No_Employees_Word:"Add your first new <a routerLink='/folder/Employee/New'>Munkavállaló</a>.",
  No_Name:"Névtelen",
  Unemployeed:"Munkanélküli",
  Employeed:"Munkavállaló",
  Paid:"Fizetett",
  Enabled:"Engedélyezve",
  Disabled:"Tiltva",
  Fire:"Tűz",
  Not_Available:"Nem elérhető",
  Not_Available_Word:"Nyomtassa ki első <a routerLink='/folder/Invoice/New'>Számláját</a> és kapja meg a fizetést.",
  Select_Invoices:"Select_Invoices",
  Print_Invoice_Word:"Nyomtassa ki első <a routerLink='/folder/Invoice/New'>Számláját</a> és kapja meg a fizetést.",
  Invoice_Title:"Számla címe",
  Invoice_Date:"Számla kiállítási dátuma",
  Due_Date:"Határidő",
  New_Job:"Új Munka",
  Details:"Részletek",
  Customize:"Testreszab",
  Customize_Dashboard:"Irányítópult testreszabása",
  Components:"Alkatrészek",
  No_Components:"Nincs komponens",
  Main_Components:"Fő összetevők",
  Smaller_Components:"Kisebb alkatrészek",
  Error_Loading_Page:"Hiba az oldal betöltésekor.",
  Bank_Details:"Banki adatok",
  About_Your_Job:"A munkádról",
  Your_Schedule:"A beosztásod",
  Job_Title:"Munka megnevezése",
  Job_Description:"Munkaköri leírás",
  Job_Details:"Munka részletei",
  Enable_Job:"Munka engedélyezése",
  Pay_Period:"Fizetési időszak",
  Perpetually_Schedule:"Örökös ütemezés",
  Select_Jobs:"Select Jobs",
  No_Jobs:"Nincs munkahely",
  Add_Jobs:"Munkák hozzáadása",
  No_Jobs_Word:"Adja hozzá az első <a routerLink='/folder/Job/New'>Munkát</a> a listához.",
  Count_Employees:"job.employee_count+' Employees'",
  Zero_Employees:"0 alkalmazott",
  New_Leave:"Új szabadság",
  Leave_Name:"Hagyja el nevét",
  Paid_Leave:"Fizetett szabadság",
  Leave_Pay:"Hagyja a fizetést",
  Indefinite_Leave:"Határozatlan idejű szabadság",
  Indefinite_Payroll:"Határozatlan bérszámfejtés",
  Leave:"Elhagy",
  Add_Schedules:"Ütemezések hozzáadása",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"A távollét engedélyezése",
  Select_Leaves:"Select Leaves",
  No_Leaves:"Nincs távollét levele",
  Leave_Of_Absence:"Távolléti szabadság",
  Leaves_Of_Absence:"A távollét levelei",
  New_Leave_of_Absense:"Új távolléti szabadság",
  No_Leaves_Word:"Add the first <a routerLink='/folder/Leave/New'>Távolléti Szabadság</a>.",
  Not_Enabled:"Nem engedélyezett",
  Absences:"hiányzások",
  Payee:"Kedvezményezett",
  New_Payee:"Új kedvezményezett",
  Payee_Identifier:"Kedvezményezett azonosítója",
  Payee_Name:"Kedvezményezett neve",
  Payee_Title:"Kedvezményezett címe",
  Payment_Memo:"Fizetési jegy",
  Select_Payees:"Kedvezményezettek kiválasztása",
  No_Payees:"Nincs kedvezményezett",
  Add_Payee_Note:"Add the first <a routerLink='/folder/Payee/New'>Kedvezményezett</a>.",
  New_Payees:"Új kedvezményezettek",
  About_The_Payment_Schedule:"Fizetési ütemterv",
  Your_Payroll_Schedule:"Automatikus bérszámfejtés",
  New_Payment:"Új fizetés",
  Identifier:"Azonosító",
  Select:"Kiválasztás",
  Memo:"Memo",
  Payment_Date:"Fizetés nap",
  Mark_as_Paid:"Megjelölés fizetettként",
  Select_Payments:"Kifizetések kiválasztása",
  No_Payments:"Nincs fizetés",
  No_Payments_Word:"Create the first <a routerLink='/folder/Payment/New'>Fizetés</a>.",
  Create_Payroll:"Bérjegyzék létrehozása",
  Properties:"Tulajdonságok",
  Payroll_Title:"Bérszámfejtési cím",
  Payroll_Notes:"Bérszámfejtés",
  Payroll_Setup:"Bérszámfejtés beállítása",
  Tabulate_Payments:"Táblázatos kifizetések",
  Tabulate:"Csoportosít",
  By:"Által:",
  Payments_By:"Fizetések:",
  Timesheets_And_Schedules:"Időtáblák és menetrendek",
  Both:"Mindkét",
  Items:"Tételek",
  Add_Payees:"Kedvezményezettek hozzáadása",
  Add_Account:"Fiók hozzáadása",
  Enable_Account:"Fiók engedélyezése",
  Enable_Payroll:"Bérszámfejtés engedélyezése",
  Print_Payroll:"Bérjegyzék nyomtatása",
  No_Payrolls:"Nincs bérszámfejtés",
  No_Payroll_Word:"Create your first <a routerLink='/folder/Payroll/New'>Bérszámfejtés</a>.",
  View_more:"MUTASS TÖBBET",
  Less:"KEVÉSBÉ",
  Add_Payroll:"Bérjegyzék hozzáadása",
  Select_Payroll:"Bérszámfejtés kiválasztása",
  About_Your_Salary:"A fizetésedről",
  Add_Salaries:"Bérek hozzáadása",
  Add_Salary:"Bér hozzáadása",
  Salaries:"Bérek",
  No_Salaries:"Nincs fizetés",
  No_Salaries_Word:"Add the first <a routerLink='/folder/Salary/New'>Fizetés</a>.",
  Select_Salaries:"Válasszon fizetések",
  New_Salary:"Új fizetés",
  Salary_Name:"Bér azonosító",
  Enable_Salary:"Bér engedélyezése",
  Salary_Amount:"Bérösszeg",
  New_Schedule:"Új menetrend",
  Schedule_Title:"Ütemezési cím",
  Add_Address:"Cím hozzáadása",
  Repeat:"Ismétlés",
  Design:"Tervezés",
  Edit_Design:"Dizájn szerkesztése",
  Edit_this_Design:"Edit this Design",
  Repeat_Payment:"Ismétlési fizetés",
  Enable_Schedule:"Ütemezés engedélyezése",
  Never:"Soha",
  Select_Schedule:"Select Schules",
  No_Schedules:"Nincs menetrend",
  No_Schedules_Word:"Hozza létre az első <a routerLink='/folder/Schedule/New'>Ütemezést</a>.",
  New_Administrator:"Új adminisztrátor",
  Username:"Felhasználónév",
  First_Name:"Keresztnév",
  Last_Name:"Vezetéknév",
  Add_an_Address:"Cím hozzáadása",
  Payment_Limit:"Fizetési limit",
  Total_Limit:"Teljes határ",
  Select_Accounts:"Fiókok kiválasztása",
  No_Administrators:"Nincsenek adminisztrátorok",
  No_Administrators_Word:"Hozza létre az első <a routerLink='/folder/Administrator/New'>Rendszergazdai fiókot</a>.",
  New_Administrators_Word:"Adja hozzá az első <a routerLink='/folder/Administrator/New'>Rendszergazdát</a>",
  Cloud:"Felhő",
  Backup:"Biztonsági mentés",
  Enable_iCloud:"Az iCloud engedélyezése",
  Enable_Google_Drive:"Enable Google Drive",
  Enable_Microsoft_OneDrive:"A Microsoft OneDrive engedélyezése",
  Automatically_Backup:"Automatikus biztonsági mentés",
  FTP_Settings:"FTP beállítások",
  URL_File_Prompt:"Kérjük, adja meg az importálandó .xls / .xlsx / .json fájl helyét:",
  URL_SQL_Prompt:"Kérjük, adja meg az importálandó SQLite fájl helyét:",
  FTP_Backup:"FTP biztonsági mentés",
  FTP_Import:"FTP import",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Házigazda",
  Port:"Kikötő",
  Path:"Pálya",
  Data_Path:"Adat útvonal",
  Enable_FTP_Account:"FTP-fiók engedélyezése",
  HostnameIP:"Hostnév",
  Password:"Jelszó",
  Connection_Port:"Csatlakozási port",
  Enable_MySQL_Database:"Enable MySQL Database",
  Log:"napló",
  Reset:"Visszaállítás",
  Erase:"Törli",
  Export:"Export",
  Database:"Adatbázis",
  Upload_CSV:"CSV feltöltése",
  Download_CSV:"CSV letöltése",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL biztonsági mentés",
  Internal_Notes:"Belső megjegyzések",
  Root_Path:"Gyökérút",
  Select_Backup:"Select Backup",
  Add_New_Backup:"Új biztonsági másolat hozzáadása",
  First_Backup:"Az első biztonsági mentés beállítása...",
  Backups:"Biztonsági másolatok",
  Add_Backup:"Biztonsági másolat hozzáadása",
  No_Backups:"Nem találhatók biztonsági mentések.",
  Select_Backup_Type:"Válassza ki a beállítani kívánt biztonsági mentés típusát...",
  Backup_Type:"Biztonsági mentés típusa",
  FTP_Drive:"FTP meghajtó",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Hajtás",
  Microsoft_OneDrive:"Hajtás",
  Import_Fields:"Mezők importálása",
  Import_Fields_Word:"Használja ezt a részt az <a routerLink='/folder/Holiday/New'>Adatok Importálása</a> kiválasztásához.",
  Upload:"Feltöltés",
  Download:"Letöltés",
  Download_JSON:"Letöltés JSON-adatként",
  Download_SQL:"Letöltés SQL fájlként",
  New_Bank:"Új bank",
  New_Account:"Új fiók",
  New_Bank_Account:"Új bankszámla",
  Upload_Image:"Kép feltöltése",
  Bank_Name:"A bank neve",
  Bank_Address:"Bank cím",
  Branch_Address:"fiók címe",
  Address_on_Cheque:"Cím",
  Cheque_Numbers:"Számok ellenőrzése",
  Cheque_Details:"Részletek ellenőrzése",
  Bank_Logo:"bank logója",
  Cheque_ID:"Az azonosító ellenőrzése",
  Starting_Cheque_ID:"Starting Check ID",
  Transit_Number:"Tranzitszám",
  Institution_Number:"Intézményszám",
  Designation_Number:"Megjelölési szám",
  Account_Number:"Számlaszám",
  Limits:"Korlátok",
  Default_Limits:"Alapértelmezett határértékek",
  Over_Limit:"Túllépés",
  Under_Limit:"Limit alatt",
  Payroll_Limit:"Bérszámfejtési limit",
  Enable_Bank_Account:"Bankszámla engedélyezése",
  Select_Account:"Fiók kiválasztása",
  No_Bank_Account:"Nincs bankszámla",
  No_Bank_Account_Word:"Add az első <a routerLink='/folder/Accounts/New'>Bankszámlát</a>.",
  Bank_Accounts:"Bankszámlák",
  No_Accounts:"Nincs fiók",
  No_Accounts_Note:"Add az első <a routerLink='/folder/Accounts/New'>Bankszámlát</a>.",
  Cheque_Designer:"Check Designer",
  Cheque_Design:"Check Design",
  Select_Design:"Válasszon designt",
  Cheque_Designs:"Check Designs",
  No_Cheque_Designs:"No Check Designs",
  No_Cheque_Designs_Word:"Hozzon létre saját <a routerLink='/folder/Settings/Cheque/Design/New'>Ellenőrizze a Tervezést</a>.",
  Set_Default:"Beállítás alapértelmezettként",
  Default:"Alapértelmezett",
  Remove:"Eltávolítás",
  Folder:"Mappa",
  Edit:"Szerkesztés",
  LoadingDots:"Betöltés...",
  Add_a_New_File:"Add a href='#' (click)='add()'>New File</a> to",
  this_folder:"ez a mappa",
  FTP_Backup_Settings:"FTP biztonsági mentési beállítások",
  Secure_File_Transfer:"Biztonságos fájlátvitel",
  New_Holiday:"Új ünnep",
  Add_Holiday:"Ünnep hozzáadása",
  Holiday_Name:"Ünnepi név",
  Additional_Pay:"Kiegészítő fizetés",
  Enable_Holiday:"Szünidő engedélyezése",
  Select_Holidays:"Select Holidays",
  No_Holidays:"Nincs ünnep",
  No_Holidays_Word:"Add the first <a routerLink='/folder/Holiday/New'>Nemzeti Ünnep</a>.",
  New_Import:"Új import",
  Import_Data:"Adatok importálása",
  Import_Data_Word:"Válassza ki a kívánt fájltípust vagy feltöltési módot.<br /> A támogatott fájl feltöltése után kiválaszthatja, hogy egy fájl melyik importált mezője felel meg az alkalmazás bármely paraméterének.",
  Import_File:"Fájl importálása",
  Link_To_File:"Hivatkozás a fájlhoz",
  Select_File:"Válaszd ki a fájlt",
  New_Moderator:"Új moderátor",
  Allow_Moderation:"Moderálás engedélyezése",
  Create_Paycheques:"Bércsekk létrehozása",
  Edit_Paycheques_and_Data:"Bérek és adatok szerkesztése",
  Destroy_Data_and_Paycheques:"Destroy Data and Paychecks",
  Bonus_Percentage:"Béres százalék",
  Fixed_Amount:"Állandó mennyiség",
  Select_Moderator:"Moderátor kiválasztása",
  No_Moderators:"Nincs moderátor",
  Moderators:"Moderátorok",
  Moderator_Account:"Hozza létre az első <a routerLink='/folder/Administrator/New'>Moderátor fiókot</a>.",
  No_Moderators_Word:"Add az első <a routerLink='/folder/Administrator/New'>Moderátort</a>.",
  Defaults:"Alapértelmezések",
  Provide_Us_Info:"Adjon nekünk információkat",
  Setup_Your_Printer:"Nyomtató beállítása",
  Your_Company:"A cégedről",
  Company_Name:"Cégnév",
  Currency:"Valuta",
  Default_Currency:"Alapértelmezett pénznem",
  Base_Monthly_Income:"Havi bevétel",
  Protection:"Védelem",
  App_Protection:"Alkalmazásvédelem",
  PIN_Code_Protection:"PIN kód védelem",
  App_Protection_Word:"Engedélyezze a fiók védelmét segítő biztonsági módszereket.",
  PIN_Code:"PIN-kód",
  Change_PIN:"PIN módosítása",
  New_Password:"Új jelszó",
  Geofence_Protection:"Geo-kerítés védelem",
  Geofence_Area:"Terület beállítása",
  Distance:"Távolság",
  Setup_Now:"Beállítás most",
  Mile:"Mérföld",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Arcfelismerő",
  Face:"Arc",
  Setup:"Beállít",
  Label:"Címke",
  Password_Protection:"Jelszó védelem",
  Modify_Password:"Jelszó módosítása",
  New_Tax_Entry:"Új adóbejegyzés",
  New_Tax:"Új adó",
  Tax_Label:"Adócímke",
  Perpetually_Enabled:"Örökké engedélyezve",
  Select_Taxes:"Adók kiválasztása",
  Tax_Deductions:"Adólevonások",
  No_Tax_Deductions:"Nincs adólevonás",
  No_Tax_Deductions_Word:"Add az első <a routerLink='/folder/Tax/New'>Adó</a> levonást.",
  New_Timer:"Új időzítő",
  Start:"Rajt",
  Stop:"Álljon meg",
  Start_Timer:"Időzítő indítása",
  Stop_Timer:"Időzítő leállítása",
  Timer_Active:"Időzítő aktív",
  Timer:"Időzítő:",
  Timer_Running:"Időzítő: (Futó)",
  Save_Timer:"Időzítő mentése",
  New_Timesheet:"Új időlap",
  Select_Timesheets:"Select Time Sheets",
  Work_Notes:"Munkajegyzetek",
  Entry_Title:"Bejegyzés címe",
  No_Timesheets:"Nincs munkaidő lap",
  No_Timesheets_Word:"Add az első <a routerLink='/folder/Timesheet/New'>Időlapot</a>.",
  Timesheet_Submitted:"Időlap benyújtva",
  Timesheet_Congrats:"Gratulálunk! Munkaidő-nyilvántartása sikeresen elküldve. Köszönjük!",
  Timesheet_Copy:"Ha másolatot szeretne kapni dokumentumairól, kérjük, adja meg e-mail címét és/vagy mobiltelefonszámát.",
  Submit:"Beküldés",
  Allow_Notifications:"Értesítések engedélyezése",
  Untitled_Entry:"Új bejegyzés",
  Untitled_Bank:"Névtelen bank",
  Timesheet_Entry:"Időlap bejegyzés",
  Work_Description:"Munka leírás",
  Enable_Timesheet:"Időlap engedélyezése",
  Submit_Timesheet:"Időlap benyújtása",
  Vacation:"Vakáció",
  New_Vacation:"Új vakáció",
  Vacation_Name:"Üdülés neve",
  Paid_Vacation:"Fizetett szabadság",
  Vacation_Pay:"Vakációs fizetés",
  Enable_Vacation:"Enable Vacation",
  Select_Vacations:"Válassza ki a vakációkat",
  No_Vacations:"Nincs vakáció",
  No_Vacations_Word:"Hozza létre az első <a routerLink='/folder/Vacation/New'>Vakáció</a> bejegyzést.",
  Payroll_Schedule:"Bérszámfejtés",
  Next_Payroll:"Next Payroll:",
  Upcoming_Payroll:"Közelgő bérszámfejtés",
  No_Upcoming_Payroll:"Nincs közelgő bérszámfejtés",
  Address_Book:"Címjegyzék",
  Archived_Documents:"Archivált dokumentumok",
  Dev_Mode:"Alkalmazás fejlesztési módban",
  Administrators:"Adminisztrátorok",
  Privacy:"Magánélet",
  None:"Egyik sem",
  Select_Signature:"Aláírás kiválasztása",
  No_Signatures:"Nincs aláírás",
  No_Signatures_Word:"Add az első <a routerLink='/folder/Signature/New'>Aláírást</a>.",
  Repeat_Indefinitely:"Határtalanul ismételd",
  Sign:"Jel",
  Sign_Here:"Itt írd alá",
  Date_Signed:"Foglalt időpont",
  Signature_Pad:"Aláíró pad",
  Account_Holder:"Számlatulajdonos",
  Account_Properties:"Fióktulajdonságok",
  Name_On_Cheque:"Név a csekken",
  Server_Hostname:"Szerver hosztnév / IP",
  Printers:"Nyomtatók",
  No_Printers:"Nincs nyomtató",
  Printer_Exists:"Már létezik ilyen nevű nyomtató.",
  No_Printers_Word:"Adja hozzá a legelső <a routerLink='/folder/Printer/New'>Nyomtatót</a>.",
  No_Printer_Alert:"Nincs nyomtató meghatározva. Szeretne nyomtatót beállítani?",
  Add_Printer:"Nyomtató hozzáadása",
  New_Printer:"Új nyomtató",
  Printer_Hostname:"Nyomtató hosztnév / IP-cím",
  Printer_Label:"Nyomtató címke",
  Printer_Protocol:"Nyomtató protokoll",
  Protocol:"Jegyzőkönyv",
  Email_Print:"Email",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Foglalat",
  Print_Job:"Nyomtatási feladat",
  Printed:"Nyomtatott",
  Not_Printed:"Nincs nyomtatva",
  Print_Jobs:"Nyomtatási munkák",
  Print_Queue:"Nyomtatási sor",
  No_Print_Jobs:"Nincs nyomtatási munka",
  No_Prints:"Hozzon létre egy új <a routerLink='/folder/Cheque/New'>csekket</a> a nyomtatáshoz. ",
  Prints:"nyomatok",
  Find_Printer:"Nyomtató keresése",
  Find_AirPrint_Devices:"AirPrint-eszközök keresése",
  Select_Printer:"Nyomtató kiválasztása",
  System_UI:"Rendszer felhasználói felület",
  Printer:"Nyomtató",
  Status:"Állapot",
  Preview:"Előnézet",
  Enable_Print_Job:"Nyomtatási feladat engedélyezése",
  Queue_Weight:"Várólista súlya",
  Unlimited:"Korlátlan",
  Show_Advanced_Printer_Options:"Speciális nyomtatóbeállítások megjelenítése",
  Advanced:"Fejlett",
  Location:"Elhelyezkedés",
  Note:"Jegyzet",
  Queue_Name:"Várólista neve",
  Pages_Printed_Limit:"Oldalok nyomtatási korlátja",
  MultiPage_Idle_Time:"Többoldalas várakozási idő (s)",
  Page_Count_Limit:"Oldalszám korlát",
  Page_Orientation:"Oldaltájolás",
  Portrait:"Portré",
  Landscape:"Tájkép",
  Duplex:"Duplex",
  Double_Sided_Printing:"Kétoldalú",
  Duplex_Mode:"Duplex mód",
  Duplex_Short:"Rövid",
  Duplex_Long:"Hosszú",
  Duplex_None:"Egyik sem",
  Color_And_Quality:"Szín és minőség",
  Monochrome:"Monokróm",
  Photo_Quality_Prints:"Fotóminőségű nyomatok",
  Printer_Email:"Nyomtató e-mail",
  Automatically_Downsize:"Automatikus méretcsökkentés",
  Paper:"Papír",
  Paper_Name:"Papírnév",
  Paper_Width:"Papírszélesség",
  Paper_Height:"Papír magasság",
  Paper_Autocut_Length:"Papír automatikus vágási hossza",
  Margins:"Margók",
  Page_Margins:"Oldalmargók",
  Page_Margin_Top:"Felső oldal margója",
  Page_Margin_Right:"Jobb oldalmargó",
  Page_Margin_Bottom:"Alsó oldal margója",
  Page_Margin_Left:"Bal oldali margó",
  Add_Employees:"Alkalmazottak hozzáadása",
  Header:"Fejléc",
  Print_A_Page_Header:"Oldalfejléc nyomtatása",
  Header_Label:"Fejléc címke",
  Header_Page_Index:"Fejléc oldalindex",
  Header_Font_Name:"Fejléc betűtípus",
  Select_Font:"Select Font",
  Font_Selector:"Betűtípusválasztó",
  Header_Font_Size:"Fejléc betűtípus",
  Header_Bold:"Fejléc félkövér",
  Header_Italic:"Fejléc dőlt",
  Header_Alignment:"Fejléc igazítása",
  Left:"Bal",
  Center:"Központ",
  Right:"Jobb",
  Justified:"Indokolt",
  Header_Font_Color:"Fejléc színe",
  Select_Color:"Szín kiválasztása",
  Footer:"lábléc",
  Print_A_Page_Footer:"Print A Page Footer",
  Footer_Label:"Lábléc címke",
  Footer_Page_Index:"Footer Page Index",
  Footer_Font_Name:"Footer Font",
  Fonts:"Betűtípusok",
  Done:"Kész",
  Select_Fonts:"Select Fonts",
  Footer_Font_Size:"Lábléc mérete",
  Footer_Bold:"Footer Bold",
  Footer_Italic:"Dőlt lábléc",
  Footer_Alignment:"Lábléc igazítása",
  Footer_Font_Color:"Lábléc színe",
  Page_Copies:"Oldalmásolatok",
  Enable_Printer:"Nyomtató engedélyezése",
  Remote_Logging:"Távoli naplózás",
  Log_Server:"naplószerver",
  Encryption:"Titkosítás",
  Random_Key:"Véletlenszerű kulcs",
  Encryption_Key:"Titkosítási kulcs",
  Cheques_Webserver:"Egyéni adatbázis",
  Learn_How:"Megtanulják, hogyan",
  Signature:"Aláírás",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Aláírás megtekintése",
  Printed_Signature:"Nyomtatott aláírás",
  Digitally_Sign:"Digitális aláírás",
  Digital_Signature:"Digitális aláírás",
  Add_Signature:"Aláírás hozzáadása",
  Add_Your_Signature:"Adja hozzá aláírását",
  Enable_Signature:"Aláírás engedélyezése",
  Digitally_Signed:"Digitálisan aláírva",
  Insert_Error:"Nem sikerült menteni a csekket adatbázis-problémák miatt.",
  Delete_Confirmation:"Biztosan törölni akarja ezt az információt?",
  Discard_Confirmation:"Biztosan el akarja vetni ezt az információt?",
  Discard_Bank_Confirmation:"Biztosan el akarja vetni ezt a fiókot?",
  Discard_Printer_Confirmation:"Biztosan el akarja dobni ezt a nyomtatót?",
  Delete_Bonus_Confirmation:"Biztosan törölni akarja ezt a bónuszt?",
  Delete_Invoice_Confirmation:"Biztosan törli ezt a számlát?",
  Generated_Cheque:"Generált csekk",
  Generated_Invoice:"Generált számla",
  Schedule_Start_Time:"Indítás ütemezése",
  Schedule_End_Time:"Ütemezés vége",
  New_Call:"Új hívás",
  No_Contacts:"Nincs kapcsolat",
  No_Contacts_Word:"Az adminisztrátorok, moderátorok, alkalmazottak és kedvezményezettek kapcsolattartóként jelennek meg.",
  PDF_Subject:"pénzügyek",
  PDF_Keywords:"bérszámfejtési fizetési csekk PDF csekk",
  Printer_Setup:"Nyomtató beállítása",
  Printer_Selection:"Nyomtató kiválasztása",
  New_Fax:"Új fax",
  New_Fax_Message:"Új faxüzenet",
  Fax_Machine:"Fax",
  Fax_Name:"Faxnév",
  Fax_Email:"Fax e-mail",
  Fax_Number:"Faxszámra",
  Contents:"Tartalom",
  Fax_Body:"Oldaltörzs",
  Header_Word:"Fejléc:",
  Header_Text:"Fejléc",
  Include_Header:"Fejléc szerepeltetése",
  Include_Footer:"Include Footer",
  Footer_Word:"Lábléc:",
  Footer_Text:"Lábléc szövege",
  Attach_a_Cheque:"Csekk csatolása",
  Add_Deduction:"Levonás hozzáadása",
  Enable_Fax:"Fax küldése",
  Select_Fax:"Select Fax",
  No_Faxes:"Nincs fax",
  Faxes:"Faxok",
  Save_and_Send:"Fax küldése",
  Save_and_Pay:"Mentés és fizetés",
  WARNING:"FIGYELEM:",
  Remember:"Emlékezik",
  Printing:"Nyomtatás",
  Printing_Complete:"A nyomtatás kész!\n\n",
  of:"nak,-nek",
  There_Were:"Ott volt ",
  Successful_Prints:"sikeres nyomatok és",
  Unsuccessful_Prints:"sikertelen nyomatok.",
  PrinterError:"Elnézést! Hiba történt.",
  Printing_:"Nyomtatás...",
  PrinterSuccess:"A dokumentum nyomtatása sikeresen megtörtént.",
  PrintersSuccess:"A dokumentumok sikeresen kinyomtatva.",
  Print_Cheques:"Csekk nyomtatása",
  New_Message:"Új üzenet",
  New_Messages:"Új üzenetek",
  Read_Message:"Üzenet olvasása",
  Write_Message:"Üzenet írása",
  Send_Message:"Üzenet küldése",
  Subject:"Tantárgy",
  Message:"Üzenet",
  Writing:"Írás...",
  Send:"Küld",
  Set_Date:"Dátum beállítása",
  Set_Time:"Beállítani az időt",
  Recieve:"Fogadás",
  Set_as_Default:"Beállítás alapértelmezettként",
  Default_Account:"Alapértelmezett fiók",
  Default_Design:"Alapértelmezett kialakítás",
  Multiple_Cheques:"Checks (Tripple)",
  Account_Mode:"Fiók mód",
  Multiple_Cheques_Description:"Három csekk oldalanként.",
  Check_and_Table:"Check & Table",
  Check_including_Table:"Check & accounting table.",
  Check_Mailer:"Check Mailer",
  Check_Mailer_Window:"Ellenőrizze címablakkal.",
  DocuGard_Table_Top:"DocuGard Check & Table (felül)",
  DocuGard_Table_Middle:"DocuGard Check & Table (Middle)",
  DocuGard_Table_Bottom:"DocuGard Check & Table (alul)",
  DocuGard_Mailer_Top:"DocuGard Check Mailer (Felső)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (Middle)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mailer (alul)",
  DocuGard_Three_Cheques:"DocuGard ellenőrzések (hármas)",
  DocuGard_Cheque_Top:"DocuGard Check (Felső)",
  DocuGard_Cheque_Middle:"DocuGard Check (Middle)",
  DocuGard_Cheque_Bottom:"DocuGard ellenőrzés (alul)",
  DocuGard_Three_Cheques_Window:"Három csekk egy oldalon.",
  DocuGard_Table_Top_Window:"Csekk és bevétel táblázat.",
  DocuGard_Table_Middle_Window:"Csekk és bevétel táblázat.",
  DocuGard_Table_Bottom_Window:"Csekk és bevétel táblázat.",
  DocuGard_Mailer_Top_Window:"Csekk, táblázat és cím.",
  DocuGard_Mailer_Middle_Window:"Csekk, táblázat és cím.",
  DocuGard_Mailer_Bottom_Window:"Csekk, táblázat és cím.",
  DocuGard_Cheque_Top_Window:"Ellenőrizze a biztonságos papírt.",
  DocuGard_Cheque_Middle_Window:"Ellenőrizze a biztonságos papírt.",
  DocuGard_Cheque_Bottom_Window:"Ellenőrizze a biztonságos papírt.",
  Basic_Cheque:"Check (Felső)",
  Basic_Cheque_Print:"Nyomtasson ki egyetlen csekket.",
  Error_Setting_As_Paid:"Hiba a fizetőssé beállításkor",
  Add_Attachment:"Melléklet hozzáadása",
  PrinterUnavailable:"A nyomtató nem elérhető",
  CreditCardComponent:"kártyák",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Új letét",
  Deposits:"betétek",
  No_Deposits:"Nincs betét",
  Credit_Card_Deposit:"Hitelkártya",
  New_Credit_Card_Deposit_Message:"Hitelkártya befizetés",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin befizetés",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Interac Transfer",
  Payments_Limit:"Fizetési limit",
  No_Payment_Limit:"Nincs fizetési limit",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal befizetés",
  No_Deposits_Word:"Add the first company <a routerLink='/folder/Deposit/New'>Letét</a>.",
  No_Documents_Specified:"Nincsenek nyomtatandó dokumentumok",
  No_Printers_Added:"Nem található nyomtató. Nyomtatók hozzáadásához lépjen a Beállítások > Nyomtatók menüpontra.",
  DB_Erase_Prompt:"Teljesen törli a teljes adatbázist? FIGYELMEZTETÉS: Minden tárolt információ elveszik!",
  Console_Warning:"Ne illesszen be szöveget ebbe a konzolba. Komoly veszélynek teheti ki magát és/vagy cégét.",
  No_Faxes_Word:"Hozza létre az első <a routerLink='/folder/Fax/New'>Faxot</a>.",
  Cheque_Delete_Confirmation:"Biztosan törli ezt a csekket?",
  Design_Delete_Confirmation:"Biztosan törli ezt a tervet?",
  Cheque_Pay_Confirmation:"Megjelöli ezt a csekket kifizetettként? NEM fog megjelenni a nyomtatási sorban.",
  Payment_Pay_Confirmation:"Megjelöli ezt a fizetést kifizetettként? NEM fog megjelenni a csekksorban.",
  Delete_Deduction_Confirmation:"Biztosan törölni szeretné ezt a levonást?",
  Delete_Job_Confirmation:"Biztosan törli ezt a munkát?",
  Delete_Timesheet_Confirmation:"Biztosan törölni akarja ezt a munkaidő-nyilvántartást?",
  Delete_Schedule_Confirmation:"Biztosan törli ezt az ütemezést?",
  Delete_Setting_Confirmation:"Biztosan vissza akarja állítani ezt a beállítást?",
  Delete_Fax_Confirmation:"Biztosan törli ezt a faxot?",
  Delete_File_Confirmation:"Biztosan törli ezt a fájlt?",
  Delete_Vacation_Confirmation:"Biztosan törölni akarja ezt a nyaralást?",
  Delete_Printer_Confirmation:"Biztosan törli ezt a nyomtatót?",
  Remove_Design_Confirmation:"Biztosan törli ezt a tervet?",
  Delete_Payroll_Confirmation:"Biztosan törli ezt a bérjegyzéket?",
  Send_Fax_Email_Confirmation:"Szeretné faxon és e-mailben elküldeni ezeket a dokumentumokat?",
  Send_Email_Confirmation:"El akarja küldeni ezt a dokumentumot e-mailben?",
  Send_Fax_Confirmation:"Akarja faxolni ezt a dokumentumot?",
  Error_Generating_PDF:"Elnézést. Hiba történt a dokumentum generálásakor.",
  PDF_Error_Saving_Image:"Elnézést. Hiba történt a dokumentum PDF-képének mentésekor.",
  Test_Printer_Confirmation:"Szeretne tesztoldalt nyomtatni ezen a nyomtatón?",
  Save_Timesheet_Prompt:"Kérjük, adjon hozzá egy 'címet' vagy nyomja meg az 'Időzítő indítása' gombot a mentéshez.",
  Remove_Geofence_Prompt:"Biztosan eltávolítja ennek a geokerítésnek a helyét?",
  Delete_Employee_Confirmation:"Biztos vagy benne, hogy törölni akarod ",
  Fire_Employee_Confirmation:"Biztos, hogy ki akarsz lőni?"
}