export const Bs = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Autorska prava &copy; 2023",
  black:"Crno",
  green:"Zeleno",
  gold:"Zlato",
  blue:"Plava",
  brown:"Brown",
  purple:"Ljubičasta",
  pink:"Pink",
  red:"Crveni",
  Swatches:"Swatchevi",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Hue",
  Saturation:"Saturation",
  Lightness:"Lakoća",
  Upgrade_To_Pro:"Nadogradite na Pro",
  AllFeaturesInclude:"Sve karakteristike uključuju:",
  PrintUnlimitedCheques:"Štampajte neograničene čekove",
  PremiumChequeDesigns:"Premium Check Designs",
  ManageUnlimitedEmployees:"Upravljajte neograničenim brojem zaposlenih",
  AddUnlimitedPayees:"Dodajte neograničene primatelje",
  CreateUnlimitedPayrolls:"Kreirajte neograničene platne liste",
  UnlimitedSchedulesandTimeSheets:"Neograničeni rasporedi i rasporedi",
  BulkPayPalPayouts:"Grupne PayPal isplate",
  UnlimitedBankAccounts:"Neograničeni bankovni računi",
  ManageMultipleCompanies:"Upravljajte više kompanija",
  TrackInsurancePolicies:"Pratite polise osiguranja",
  Bio_MetricProtection:"Bio-metrička zaštita",
  Geo_FenceLock_OutProtection:"Geo-Fence Lock-Out zaštita",
  Multiple_Companies_Word:"Upravljanje više kompanija nije dostupno bez premije čekova.",
  PayPal_Payouts_Word:"PayPal plaćanja su onemogućena bez premije čekova.",
  PINProtection:"PIN zaštita",
  PasswordProtection:"Zaštita lozinkom",
  May_Require_Approval:"Može zahtijevati odobrenje.",
  Subscribe:"Pretplatite se",
  Billed:"Naplaćeno",
  Up:"Gore",
  Down:"Dole",
  Positioning:"Pozicioniranje",
  Marker:"Marker",
  Drag_Marker:"Povucite marker",
  Tagline:"Štampanje čekova i tabela platnog spiska",
  Marker_Word:"Koristite markere za veličinu elementa.",
  Pinch_Zoom:"Zumiranje prstiju",
  Pinch_Word:"Stisnite za zumiranje elementa.",
  Drag:"Drag",
  Drag_Word:"Koristite prst za prevlačenje elemenata.",
  subscription_alias_word:"Automatsko obnavljanje pretplate",
  premium_alias_word:"Jednokratni paket nadogradnje",
  print_alias_word:"Ispis pojedinačnih čekova",
  mode_alias_word:"Mode",
  Pro:"Pro",
  Pro_word:"Potrebna je Pro verzija.",
  Cant_Delete_Default_Company_Word:"Žao nam je, ne možete izbrisati svoju zadanu kompaniju.",
  Reinsert_Default_Designs:"Ponovno umetnite zadane dizajne",
  Reinsert_Default_Designs_word:"Želite li ponovo umetnuti zadane dizajne?",
  Subscription_Active_Disable_Word:"Ova pretplata je aktivna. Želite li otkazati pretplatu na Čekove?",
  Company_Logo:"Logo kompanije",
  ZERO_:"NULA",
  Disclaimer:"Odricanje od odgovornosti",
  Privacy_Policy:"Politika privatnosti",
  EULA:"Provjerava EULA",
  Terms_Of_Service:"Uslovi korištenja",
  Terms_Of_Use:"Pravila korištenja",
  Refunds:"Politika povrata novca",
  Single_Print:"1 Provjera",
  Two_Prints:"2 provjere",
  Five_Prints:"5 provjere",
  Ten_Prints:"10 čekova",
  Fifteen_Prints:"15 Provjere",
  Twenty_Prints:"20 čekova",
  Thirty_Prints:"30 čekova",
  Image_Added:"Slika dodana",
  Image_Preview:"Pregled slike",
  No_Image_Was_Selected:"Nije odabrana nijedna slika.",
  Cheques_Unlimited:"Čekovi neograničeni",
  _Subscription:"Pretplata",
  Subscription:"Čekovi - 1 mjesec",
  Two_Month_Subscription:"Čekovi - 2 mjeseca",
  Three_Month_Subscription:"Čekovi - 3 mjeseca",
  Six_Month_Subscription:"Čekovi - 6 mjeseci",
  Twelve_Month_Subscription:"Čekovi - 12 mjeseci",
  Cheques_Are_Available:"Čekovi su dostupni za štampanje.",
  Upgrade_Required_Two:"Odaberite paket i dvaput dodirnite dugme za cijenu da započnete kupovinu. Odštampajte čekove profesionalnog izgleda u punoj boji za nekoliko sekundi.",
  Month:"Mjesec",
  Due:"Rok:",
  Expires:"Ističe:",
  Subscription_Active:"Pretplata aktivna",
  Subscription_Inactive:"Pretplata je neaktivna",
  Purchase_Additional_Cheques:"Kupiti dodatne čekove?",
  Printable_Cheque:"Printable Check",
  Printable_Cheques:"Čekovi za štampanje",
  Printable_Cheque_Word:"ček je dostupan na vašem računu.",
  Printable_Cheques_Word:"čekovi su dostupni na vašem računu.",
  Max_Amount_Message:"Iznos koji ste naveli dostigao je maksimalan iznos postavljen za ovaj sistem:",
  Terms_Required_Word:"Morate prihvatiti ovaj ugovor prije nego što nastavite koristiti čekove.",
  Subscriptions:"Pretplate",
  Product_Upgrades:"Nadogradnje",
  Mailed_Out_Cheques:"Čekovi poslani poštom",
  Enter_A_Company_Name:"Unesite naziv kompanije.",
  Single_Cheques:"Pojedinačne provjere",
  Cheque_Golden:"Zlatni ček",
  Cheque_Golden_Window:"Dizajn zlatnih karica.",
  Cheque_Green:"Zelena provjera",
  Cheque_Green_Window:"Dizajn zelenih karata.",
  Cheque_Red:"Crveni ček",
  Cheque_Red_Window:"Dizajn crvene kare.",
  Cheque_Yellow:"Žuti ček",
  Cheque_Yellow_Window:"Dizajn žute kare.",
  Cheque_Statue_of_Liberty:"Kip slobode",
  Cheque_Statue_of_Liberty_Window:"Dizajn provjere Kipa slobode.",
  Cheque_Green_Wave:"Green Wave",
  Cheque_Green_Wave_Window:"Dizajn zelenih karata.",
  Cheque_Golden_Weave:"Golden Weave",
  Cheque_Golden_Weave_Window:"Elegantan dizajn zlatne kare.",
  Cheque_Green_Sun:"Green Sun",
  Cheque_Green_Sun_Window:"Duboki i smirujući dizajn.",
  Cheque_Blue_Checkers:"Blue Checkers",
  Cheque_Blue_Checkers_Window:"Dizajn plave karike.",
  Cashiers_Check:"Blagajnički ček",
  Cashiers_Check_Window:"Šablon stila blagajničkog čeka.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Aqua check dizajn.",
  Cheque_Golden_Checkers:"Zlatne dame",
  Cheque_Golden_Checkers_Window:"Dizajn zlatnih karica.",
  Upgrade_Unavailable:"Nadogradnje nedostupno",
  Bank_Code_Protection:"Zaštita bankovnog broja",
  Bank_Code_Protection_Word:"Zaštitite svoje bankovne brojeve od korištenja u ovoj aplikaciji koja radi na drugom uređaju ili za drugog korisnika. Ova akcija je NEPOVRATNA. Nastaviti?",
  Bank_Code_Protection_Blocked_Word:"Bankovni brojevi koje pokušavate koristiti rezervirani su za drugog korisnika ili uređaj.",
  Bank_Code_Protection_Error_Word:"Provjera broja nije uspjela. Povežite se na internet i pokušajte ponovo dodati ovaj bankovni račun.",
  Bank_Code_Protection_Set_Error_Word:"Zaštita bankovnog broja zahtijeva da budete povezani na internet. Povežite se i pokušajte ponovo.",
  Upgrade_Unavailable_Word:"Žao nam je, imamo problema s vašom verifikacijom. Pretplate i nadogradnje na Čekove trenutno nisu dostupne za kupovinu u vašem području.",
  PayPal_Payment_Preview:"PayPal pregled plaćanja",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Odaberite PayPal",
  PayPal_Applications:"PayPal aplikacije",
  Purchase_With_Apple_Pay:"Kupujte uz Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Kompanije",
  Insurance:"Osiguranje",
  New_PayPal:"Novi PayPal",
  Pay_By:"Pay By",
  Insure:"Osigurati",
  Miles:"Miles",
  Payment_Method:"Način plaćanja",
  Select_Policies:"Odaberite Pravila",
  Policies:"Politike",
  Policy:"Policy",
  No_PayPal_Account:"Nema PayPal računa",
  No_Policies:"Nema polisa osiguranja",
  New_Policy:"Nova politika",
  PayPal_Payment:"PayPal plaćanje",
  PayPal_Payments:"PayPal plaćanja",
  No_Payment_Selected:"Nije odabrano plaćanje",
  Sending_Payment_Word:"Molimo pričekajte... Ova uplata se šalje.",
  Sending_Payments_Word:"Molimo pričekajte... Uplate se šalju.",
  No_Payment_Selected_PayPal:"Nije odabrano <a routerLink='/folder/Payments'> PayPal plaćanje</a> za slanje.",
  Payment_Sent:"Plaćanje poslano",
  PayPal_Payment_Sent:"Ova uplata je poslana putem PayPal-a.",
  Copay:"Copay",
  Dead:"Smrt",
  Alive:"Živ",
  Not_Dead:"Nije mrtav",
  Unclaimed:"Nezatraženo",
  Attempted:"Pokušaj",
  Deceased:"Preminuo",
  Claimed:"Zahtevano",
  Unpaid:"Neplaćeno",
  Sending_Payment:"Slanje plaćanja",
  Sending_Payments:"Slanje plaćanja",
  Send_PayPal_Confirmation:"Želite li poslati ovu transakciju putem PayPal-a?",
  Send_PayPal_Confirmation_Word:"Pritisnite zeleno dugme da pošaljete ovu transakciju.",
  Save_Payment_As_Unpaid:"Sačuvati ovu uplatu kao neplaćenu?",
  Payment_Pay_Confirmation_PayPal:"Sačuvati ovu uplatu kao plaćenu?",
  No_Policies_Word:"Dodajte prvu <a routerLink='/folder/Postage/New'> polisu osiguranja</a> odmah.",
  Timesheet_Multiple_Delete_Confirmation:"Jeste li sigurni da želite izbrisati više radnih listova?",
  Select_Multiple_Timesheets_Prompt:"Nema odabranih rasporeda. Odaberite barem jedan raspored.",
  Select_Multiple_Policies_Prompt:"Nema odabranih pravila. Odaberite barem jednu polisu osiguranja.",
  Policies_Multiple_Delete_Confirmation:"Jeste li sigurni da želite izbrisati više pravila?",
  Company:"Kompanija",
  Add_Company:"Dodaj kompaniju",
  New_Company:"Dodaj kompaniju",
  No_Companies:"Nema kompanija",
  Enable_Company:"Omogući kompaniju",
  Select_Company:"Odaberite kompaniju",
  The_Default_Company:"Zadana oznaka kompanije.",
  Manage_Companies:"Upravljajte kompanijama",
  No_Companies_Word:"Provjere će koristiti zadanu kompaniju.<br />Dodajte <a routerLink='/folder/Company/New'> prvu kompaniju</a>.",
  Default_Company:"Default Company",
  Cheques_Unlimited_Word:"Checks Unlimited vam omogućava da odštampate onoliko čekova koliko želite.",
  Cheques_Subscription_Word:"Pretplata na čekove vam omogućava da odštampate onoliko čekova koliko želite.",
  You_Own_This_Product:"Vi posjedujete ovaj proizvod.",
  Your_Subscription_is_Active:"Vaša pretplata je aktivna.",
  Active_Products:"Aktivirani proizvodi",
  Purchase_Confirmation:"Kupovina",
  Purchase_Cheques:"Kupovina čekova",
  Restore_Purchase:"Vraćanje kupovine",
  Erase_Purchase:"Izbriši kupovine",
  Successfully_Purchased:"Uspješno kupljeno",
  Enter_Your_Licence_Key:"Unesite svoj licencni ključ na ovoj stranici da aktivirate ovaj proizvod.",
  Licence_Key:"Licencni ključ",
  Enter_Licence_Key:"Unesite licencni ključ",
  Purchased:"Kupljeno",
  Enable_Feature:"Omogući funkciju",
  Cancel_Subscription:"Otkažite pretplatu",
  Subscription_Removed:"Pretplata je uklonjena",
  Select_Active_Subscription:"Odaberite aktivnu pretplatu da biste je izmijenili.",
  Remove_Subscription_Word:"Jeste li sigurni da želite otkazati ovu pretplatu?",
  Delete_Company_Confirmation:"Jeste li sigurni da želite izbrisati cijelu ovu kompaniju? UPOZORENJE: Izgubit ćete sve pohranjene podatke!",
  Delete_Default_Company_Word:"Ne možete izbrisati zadanu kompaniju. Želite li resetirati aplikaciju i vratiti je na zadano stanje? UPOZORENJE: Izgubit ćete sve pohranjene podatke!",
  Console_Warning_2:"Ne rukujte nijednom valutom koristeći ovu aplikaciju koja trenutno nije vaša.",
  Terms_and_Conditions:"Odredbe i uslovi",
  and_the:"i",
  for:"za",
  Please_Read_Word:"Molimo pročitajte i složite se sa",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Neki kursevi konverzije valuta nisu pronađeni. Povežite se na internet.",
  Free:"Besplatno",
  DB_Erase_Prompt_2:"Potpuno izbrisati cijelu bazu podataka programera? UPOZORENJE: Izgubit ćete sve informacije o KUPOVINI i PRETPLATI!",
  Successfully_Imported:"Uspješno uvezeno",
  Select_Postage:"Odaberite Poštarina",
  No_Postage:"Nema poštanskih markica",
  No_Paid_Postage_Word:"Dodajte prvu marku <a routerLink='/folder/Postage/New'> Plaćena poštarina</a>.",
  Trial_Complete:'Trial Complete',
  Please_Upgrade:'Molimo nadogradite Čekove da nastavite sa štampanjem.',
  Aa:"Aa",
  Color:"Boja",
  Font:"Font",
  Guide:"Vodič",
  Guides:"Vodiči",
  Image:"Slika",
  Zoom:"Zoom",
  Logo:"Logo",
  Bank:"Banka",
  MICR:"MICR",
  Total:"Ukupno",
  Cancel:"Otkaži",
  Confirm:"Potvrdi",
  Method:"Metoda",
  Biometric_Security:"Biometrička sigurnost",
  Please_Login_To_Continue:"Molimo prijavite se da nastavite.",
  Complete:"Završeno",
  Sign_Up:"Prijaviti se",
  Error:"Greška",
  Biometrics:"Biometrija",
  Percent:"Procenat",
  Zero_Percent:"0%",
  Top_Margin:"Gornja margina",
  Bottom_Margin:"Donja margina",
  Left_Margin:"Lijeva margina",
  Right_Margin:"Desna margina",
  MICR_Margin:"MICR margina",
  Table_Margin:"Margina tabele",
  Address_Margin:"Margina adrese",
  Percentage_:"Postotak",
  Vacation_Title:"Vacation Title",
  Use_PIN:"Koristite PIN",
  Loading__:"Učitavanje...",
  Design_Title:"Naslov dizajna",
  Authorize:"Ovlastiti",
  Key:"Ključ",
  Public_Key:"Javni ključ",
  Private_Key:"Privatni ključ",
  Authenticate:"Authenticate",
  Last_Payroll:"Poslednji platni spisak",
  Last_Calculation:"Last Calculation",
  Authorized:"Ovlašćeni",
  Geo_Authorized:"Geo-lokacija: Ovlaštena",
  Not_Authorized:"Nije ovlašteno",
  Authorization_Complete:"Autorizacija je završena",
  Geolocation_Authorization:"Autorizacija geolokacije",
  Out_of_Bounds:"Van granica",
  Cant_Delete_Default_Design:"Nije moguće izbrisati zadani dizajn.",
  Unauthorized_Out_of_Bounds:"Neovlašteno: Van granica",
  Biometric_Authorization:"Biometrijska autorizacija",
  Locating_Please_Wait:"Lociranje, sačekajte...",
  Test_Biometrics:"Test Bio-metrics",
  Cheque_Margins:"Provjerite margine",
  Percentage_Full_Error:"Procentualno polje se ne može postaviti preko 100% posto.",
  No_Payroll_Text:"Dodajte <a routerLink='/folder/Employee/New'>Zaposleni</a> ili <a routerLink='/folder/Payee/New'>Primatelja</a> i <a routerLink='/folder/Schedule/New'>Raspored</a>.",
  Design_Saved:"Dizajn je sačuvan",
  Default_Design_Selected:"Zadani dizajn je odabran",
  Partial_Import:"Djelomični uvoz",
  Partial_Export:"Djelomični izvoz",
  Entire_Import:"Cijeli uvoz",
  Entire_Export:"Cijeli izvoz",
  Existing_Password:"Unesite svoju postojeću lozinku:",
  Existing_PIN:"Unesite svoj postojeći PIN kod:",
  Pin_Change_Header:"PIN potvrda",
  Pin_Change_SubHeader:"Unesite svoj stari PIN broj da potvrdite promjenu.",
  Pass_Change_Header:"Potvrda lozinke",
  Pass_Change_SubHeader:"Unesite svoju staru lozinku da potvrdite promjenu.",
  PIN_Enter_Message:"Unesite svoj PIN za potvrdu.",
  Password_Enter_Message:"Unesite svoju lozinku za potvrdu.",
  Pin_Updated_Success:"PIN je uspješno ažuriran!",
  Pin_Updated_Fail:"PIN nije mogao biti ažuriran.",
  Pass_Updated_Success:"Lozinka je uspješno ažurirana.",
  Pass_Updated_Fail:"Lozinka se nije mogla ažurirati.",
  Preview_Payment:"Pregled plaćanja",
  Preview_Payroll:"Pregled platnog spiska",
  No_Payments_Created:"Nisu pronađena plaćanja.",
  Payment_Preview:"Pregled plaćanja",
  Enable_Payee:"Omogući primaoca uplate",
  Rendered:"Rendered",
  No_Email:"Nema e-pošte",
  Setup_Cheques:"Setup Checks",
  name:"Ime",
  address:"Adresa",
  address_2:"Adresni red 2",
  city:"Grad",
  province:"Provincija",
  postal_code:"Poštanski broj",
  country:"Država",
  username:"Korisničko ime",
  full_name:"Puno ime",
  birthday:"Rođendan",
  email:"Email",
  phone:"Telefon",
  employees:"Zaposleni",
  addresses:"Adrese",
  payment_amount_limit:"Ograničenje iznosa plaćanja",
  total_limit:"Total Limit",
  payees:"Primatelji plaćanja",
  description:"Opis",
  address_line_one:"Adresa Linija jedan",
  address_line_two:"Adresa, red dva",
  image:"Slika",
  account_holder:"Vlasnik računa",
  cheque_starting_id:"Provjerite početni ID",
  transit_number:"Tranzitni broj",
  institution_number:"Broj institucije",
  designation_number:"Broj oznake",
  account_number:"Broj računa",
  currency:"Valuta",
  signature:"Potpis",
  payment_payroll_limit:"Ograničenje plaćanja",
  total_limi:"Ukupno ograničenje",
  date:"Datum",
  printed_memo:"Printed Memo",
  banks:"Banke",
  signature_image:"Signature Image",
  address_name:"Adresa Ime",
  notes:"Bilješke",
  design:"Dizajn",
  title:"Naslov",
  frequency:"Frekvencija",
  fax:"Fax",
  salaries:"Plate",
  salary_ids:"ID plate",
  start_time:"Vrijeme početka",
  end_time:"Vrijeme završetka",
  paid:"Plaćeno",
  overtime_percentage:"Plaćeni procenat",
  overtime_amount:"Plaćeni fiksni iznos",
  first_name:"Ime",
  last_name:"Prezime",
  moderation:"Umjerenost",
  create:"Stvoriti",
  edit:"Uredi",
  destroy:"Uništiti",
  day_start_time:"Dan_start_time",
  day_end_time:"Dan_kraj_vrijeme",
  fullname:"Ime",
  time:"Vrijeme",
  auto_send:"Automatically Send",
  time_method:"Vremenski metod",
  schedules:"Raspored",
  indefinite_payroll_enabled:"Omogući neograničeno",
  amount:"Iznos",
  repeat:"Ponovi",
  always_enabled:"Uvijek omogućeno",
  start_date:"Datum početka",
  end_date:"Datum završetka",
  Cheque_Total:"Provjerite Total",
  Total_Amount:"Ukupan iznos:",
  Amounts:"Iznosi:",
  Images:"Slike",
  Files:"Fajlovi",
  Previewing:"Pregled:",
  Insert:"Insert",
  Preview_Import:"Pregled uvoza",
  Entry:"Entry",
  Entries:"Unose",
  No_Entries:"Nema unosa",
  Import_Type:"Vrsta uvoza",
  Select_Details:"Odaberite Detalji",
  Select_Payee:"Odaberite Primatelj plaćanja",
  Enable_Holidays:"Omogućite praznike",
  Disable_Holidays:"Onemogući praznike",
  Wire_Transfer:"Žični prenos",
  New_Export:"Novi izvoz",
  Export_Data:"Izvoz podataka",
  Export_Data_Word:"Odaberite vrstu datoteke za izvoz i preuzimanje.",
  Export_File:"Izvezi datoteku",
  Mode:"Mode",
  Times:"Times",
  Widgets:"Widgeti",
  Slider:"Klizač",
  Set_Details:"Postavite detalje",
  Select_Type:"Odaberite Vrsta",
  Display_Slider:"Klizač prikaza",
  Dashboard_Slider:"Klizač za instrument tablu",
  Dashboard_Mode:"Način rada na kontrolnoj tabli",
  Show_Intro:"Show Intro",
  Show_Payrolls:"Prikaži platne liste",
  Show_Holidays:"Prikaži praznike",
  Show_Invoices:"Prikaži fakture",
  Show_Cheques:"Prikaži čekove",
  Enabled_Widgets:"Omogućeni vidžeti",
  Disabled_Widgets:"Disabled Widgets",
  Colors:"Boje",
  Barcodes:"Bar kodovi",
  View_Timers:"Pogledaj tajmere",
  Gradients:"Gradijent",
  No_Info:"Nema informacija",
  Disable:"Onemogući",
  Show_Layer:"Prikaži slojeve",
  Hide_Layer:"Sakrij slojeve",
  Text_Layer:"Slojevi teksta",
  Secondly:"Drugo",
  Minutely:"Na minut",
  nine_am:"9:00 AM",
  five_pm:"17:00",
  Enable_Address:"Omogući adresu",
  Invalid_File_Type:"Nažalost, odabrana je nevažeća vrsta datoteke. Podržana vrsta datoteke:",
  Error_Updating_Entry:"Žao nam je, došlo je do greške prilikom ažuriranja ovog unosa.",
  Schedule_Timing_Alert:"Greška: Vrijeme početka rasporeda je postavljeno na vrijednost nakon vremena završetka.",
  Select_Multiple_Payments_Prompt:"Nema odabranih plaćanja. Odaberite barem jedno plaćanje.",
  Select_Multiple_Cheques_Prompt:"Nema odabranih provjera. Molimo odaberite barem jednu provjeru.",
  Select_Multiple_Items_Prompt:"Nije odabrana nijedna stavka. Molimo odaberite barem jednu stavku.",
  Paymemt_Multiple_Delete_Confirmation:"Jeste li sigurni da želite izbrisati više uplata?",
  Cheque_Multiple_Delete_Confirmation:"Jeste li sigurni da želite izbrisati više provjera?",
  Payee_Multiple_Delete_Confirmation:"Jeste li sigurni da želite izbrisati više primatelja?",
  Employee_Multiple_Delete_Confirmation:"Jeste li sigurni da želite izbrisati više zaposlenih?",
  MICR_Warning:"Napomena: Neki štampači i uređaji možda neće ispravno prikazati MICR font.",
  Automatically_Send:"Automatically Send",
  Type:"Tip",
  Payment_Type:"Vrsta plaćanja",
  Auto_Send:"Auto Send",
  Automatically_Process:"Automatski proces",
  Auto_Process:"Auto Process",
  Image_Based:"Zasnovano na slici",
  Font_Based:"Font-based",
  Rerender:"Re-render",
  Rendering:"Rendering",
  Render:"Fajlovi",
  Top:"Top",
  Middle:"Srednji",
  Bottom:"Dno",
  Top_Left:"Na vrhu lijevo",
  Top_Center:"Top Center",
  Top_Right:"Gore desno",
  Middle_Left:"Srednja lijeva",
  Middle_Center:"Srednji centar",
  Middle_Right:"Srednje desno",
  Bottom_Left:"Dolje lijevo",
  Bottom_Center:"Bottom Center",
  Bottom_Right:"Dolje desno",
  Numbers:"Brojevi",
  Verified:"Verified",
  Paper_Size:"Veličina papira",
  New_Device:"Novi uređaj",
  Add_Device:"Dodaj uređaj",
  Remove_Device:"Uklonite uređaj",
  Delete_Device:"Izbriši uređaj",
  Block_Device:"Blokiraj uređaj",
  Block:"Blokiraj",
  Unblock:"Deblokiraj",
  Table:"Table",
  Scan_Login_Code:"Skenirajte kod za prijavu",
  Login_Code:"Login Code",
  Scan_Code:"Scan Code",
  Scan_QR_Code:"Skenirajte QR kod",
  Select_All:"Označi sve",
  Deselect_All:"Odznači sve",
  Increase:"Povećati",
  Decrease:"Smanjenje",
  Bold:"Bold",
  Text:"Tekst",
  Style:"Stil",
  Italic:"Kurziv",
  QR_Code:"QR kod",
  Barcode:"Barkod",
  Browse_Images:"Pregledaj slike",
  Browse_Files:"Browse Files",
  Background_Image:"Pozadinska slika",
  Logo_Image:"Logo Image",
  Header_Image:"Header Image",
  Bank_Image:"Bank Image",
  Cut_Lines:"Cut Lines",
  Background:"Pozadina",
  License:"Licenca",
  One_License:"1 licenca:",
  Licensed:"Licencirano za:",
  Not_Licensed:"Nije licencirano",
  Enter_Serial:"Unesite serijski",
  Serial_Key:"Serijski ključ",
  Serial:"Serial",
  Product_Key:"Ključ proizvoda",
  Check_Product_Key:"Provjerite ključ proizvoda",
  Add_Product_Key:"Dodajte ključ proizvoda",
  Verifying_Purchase:"Potvrđivanje kupovine...",
  Print_Envelope:"Print Envelope",
  Envelope:"Koverta",
  Thank_You:"Hvala ti!",
  Scale:"Scale",
  Print_Scale:"Print Scale",
  Borders:"Granice",
  VOID:"VOID",
  Void_Cheque:"Void Check",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"PLAĆANJE PO NARUDŽBINU:",
  NO_DOLLARS:"BEZ DOLARA ",
  ONE_DOLLAR:"ONE DOLLAR",
  DOLLARS:" DOLARA",
  AND:" I ",
  CENTS:" CENTI.",
  NO_:"NO ",
  ONE_:"JEDAN ",
  AND_NO_:"I NE ",
  _AND_ONE_:"I JEDAN ",
  DOLLARS_AND_ONE_CENT:" I JEDAN CENT.",
  AND_NO_CENTS:" I NULA CENTI.",
  CHEQUE_PRINT_DATE:"DATUM:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"narodni poslanik",
  Initial_Tasks:"Početni zadaci",
  Inital_Setup:"Početno podešavanje",
  Welcome_To:"Dobrodošli ",
  Welcome:"Dobrodošli",
  Swipe:"Prevlačenje",
  Intro_Setup:"Uvodna postavka",
  Introduction:"Uvod",
  CHEQUE_PRINT_CREDIT:"Pokreće se čekovima",
  Title_Intro_Word:"Dobro došli u čekove",
  Title_Intro:"Uvod u čekove",
  Title_Setup:"Podešavanje čekova",
  PayPal_Default_Email_Message:"Primili ste novi PayPal transfer. [Pokrenuto čekovima]",
  Cheques_App_Export:"Izvezeno čekovima",
  Post_Intro_Thanks:"Hvala vam što ste odabrali Čekove. Proces podešavanja je sada završen.",
  Post_Intro_Word:"Započnite tako što ćete odštampati svoj prvi ček, dodati buduću uplatu ili dodati zaposlene na platni spisak.",
  Upgrade_Required:"Cheques zahtijeva da posjedujete premium verziju softvera kako biste sakrili ovu poruku i otključali dodatne funkcije.",
  Upgrade_Title:"čekovi",
  Mailout_Prompt:"Možete dodatno odabrati da vam čekovi šalju vaše čekove na platni spisak poštom.",
  Mailed_Cheque:"Poslan ček: ",
  Mailed_Cheque_Color:"Poslan ček (boja): ",
  Terms_Purchase:"Sve elektronske kupovine sa čekovima se u potpunosti vraćaju u roku od 30 dana od datuma kupovine. Molimo pročitajte i prihvatite <a href='#'>Uslove i odredbe</a> prije nego nastavite.",
  Dashboard_Setup:"Podešavanje primarnog štampača",
  Dashboard_Add:"Dodaj primarni bankovni račun",
  Dashboard_Customize:"Odaberite predložak za provjeru",
  Dashboard_Job_Salary:"Kreirajte svoj posao i dodajte svoju platu",
  Dashboard_Employees:"Prati zaposlene i primaoce plaćanja",
  Dashboard_Print:"Odštampajte i pošaljite svoje plate",
  Dashboard_Payroll:"Automatizirajte štampanje platnog spiska",
  Dashboard_PayPal:"Podešavanje PayPal Payroll / Isplate",
  Begin_Setup:"Započni postavljanje",
  Get_Started:"Započni",
  Purchase:"Kupovina",
  Lockdown:"Zaključano",
  Unlock:"otključaj",
  Detailed:"Detaljno",
  Log_In:"Ulogovati se",
  Login:"Ulogovati se",
  Launch:"Pokreni",
  Register:"Registracija",
  Finish:"Završi",
  List:"Lista",
  Weekends:"vikendima",
  Weekly:"Nedeljnik",
  PayPal_Default_Subject:"Novo plaćanje",
  Payment_Message:"Poruka o plaćanju",
  PayPal_Default_Payment_Note:"Hvala ti",
  Setup_Your_Cheqing_Account:"Provjera računa",
  Add_Your_Primary_Cheqing_Account:"Dodajte svoj primarni račun za čekanje.",
  Welcome_Word:"Pojednostavite i automatizujte obračun plaća i upravljanje ljudskim resursima.",
  Get_Started_Quickly:"Samo odgovorite na nekoliko jednostavnih pitanja koja će nam pomoći da počnemo...",
  Done_Intro_Word:"Podešavanje završeno",
  PIN_Protected:"Zaštićeno lozinkom i PIN-om",
  Enter_New_PIN:"Unesite novi PIN kod:",
  Enter_PIN:"Unesite PIN kod:",
  Invalid_PIN:"Unesen je nevažeći PIN.",
  Account_Identifier:"Identifikator računa",
  New_Account_Identifier:"Novi identifikator računa",
  attempt:"pokušaj",
  attempts:"pokušaji",
  remaining:"preostalo",
  Language:"jezik",
  languages:"jezici",
  select_languages:"Odaberi jezik",
  Deposit:"Depozit",
  Hire_One_Now:"Unajmite jednog sada",
  App_Locked:"Aplikacija je zaključana.",
  Skip_:"Preskoči",
  Skip_to_Dashboard:"Preskoči na kontrolnu tablu",
  Dashboard:"Komandna tabla",
  Import:"uvoz",
  Admin:"Administratori",
  New_Admin:"Novi administrator",
  Settings:"Postavke",
  Color_Picker:"Birač boja",
  Font_Picker:"Birač fonta",
  Logout:"Odjaviti se",
  Close:"Zatvori",
  Close_menu:"Zatvori",
  Excel:"Excel fajl",
  Csv:"CSV fajl",
  Sql:"SQL fajl",
  Json:"JSON fajl",
  Url:"Uvoz po URL-u",
  Back:"Nazad",
  Timers:"tajmeri",
  Cheque:"provjera",
  Print:"Štampanje",
  Designs:"Dizajni",
  Pause_Printing:"Pauziraj štampanje",
  Resume_Printing:"Nastavi štampanje",
  Printing_Paused:"Štampanje pauzirano",
  PrintingUnavailable:"Izvinite! Štampanje nije dostupno na ovom sistemu.",
  Prints_Paused:"Otisci pauzirani",
  Administration:"Administracija",
  Loading:"Učitavanje",
  Unnamed:"Neimenovani",
  error:"Žao nam je, ovaj ček nije moguće otvoriti za pregled.",
  No_Cheques_To_Print:"Nema čekova za štampanje",
  No_Cheques_To_Print_Word:"Kreirajte <a routerLink='/folder/Cheque/New'>Novi ček</a>.",
  New_Cheque:"Novi ček",
  Start_One_Now:"Započni jedan sada",
  Edit_Cheque:"Uredi provjeru",
  Select_Cheques:"Odaberi čekove",
  Select_Employee:"Odaberite zaposlenog",
  Default_Printer:"Podrazumevani štampač",
  Reassign:"Predodijeliti",
  Configure:"Konfiguriraj",
  Template:"Šablon",
  Designer:"Dizajner",
  Edit_Designs:"Uredi dizajne",
  New_Design:"Novi dizajn",
  Next:"Sljedeći",
  Save:"Sačuvaj",
  Name:"ime",
  Mail:"pošta",
  Amount:"iznos",
  Date:"Datum",
  PayPal:"PayPal",
  Payouts:"isplate",
  PayPal_Label:"PayPal Label",
  Email_Username:"Email / Korisničko ime",
  Client_ID:"ID klijenta",
  Sandbox_Email:"Sandbox Email",
  PayPal_Email:"PayPal e-pošta",
  PayPal_Username:"Korisničko ime za API",
  PayPal_Payouts:"PayPal isplate",
  Select_PayPal_Key:"Odaberi PayPal ključ",
  Secret:"Tajna",
  API_Secret:"Tajna API-ja",
  PayPal_API_Keys:"PayPal ključevi",
  New_PayPal_Key:"Novi PayPal ključ",
  Email_Subject:"Predmet e-pošte",
  Email_Message:"E-mail poruka",
  Payout_Options:"Opcije isplate",
  Payment_Note:"Napomena o uplati",
  Enable_Employee:"Enable Employee",
  Enable_Production_Mode:"Omogući način rada",
  Sandbox_Username:"Sandbox korisničko ime",
  Sandbox_Signature:"Sandbox Signature",
  Sandbox_Password:"Sandbox lozinka",
  Production_Username:"Produkcijsko korisničko ime",
  Production_Signature:"Proizvodni potpis",
  Production_Password:"Proizvodna lozinka",
  Production_Email:"Email za proizvodnju",
  API_Client_ID:"API ID klijenta",
  API_Signature:"API potpis",
  API_Password:"API lozinka",
  API_Username:"Korisničko ime za API",
  Secret_Key:"Tajni ključ",
  Sandbox:"Sandbox",
  Production:"Proizvodnja",
  Sandbox_Keys:"Sandbox Keys",
  Production_Keys:"Proizvodni ključevi",
  API_Title:"Naslov API-ja",
  Production_Mode:"Proizvodni način",
  Account_Label:"Oznaka računa",
  No_PayPal_Setup:"Nema PayPal ključa",
  Enable_PayPal_Account:"Omogući PayPal račun",
  No_PayPal_Word:"Dodajte svoj <a routerLink='/folder/Invoice/New'>PayPal API ključ</a>.",
  Printed_Memo:"Štampani dopis",
  Employee:"Zaposleni",
  View_Employee:"Prikaži zaposlenog",
  Mailing_Address:"Poštanska adresa",
  Company_Address:"Adresa kompanije",
  Select_Company_Address:"Odaberi adresu kompanije",
  Address:"Adresa",
  Any_Day:"Bilo koji dan",
  Address_Name:"Naziv adrese",
  Unit:"Jedinica",
  Account:"Račun",
  Bank_Account:"Bankovni račun",
  Account_Limits:"Omogući ograničenja računa",
  Payroll:"platni spisak",
  New_Payroll:"Novi platni spisak",
  No_Payroll:"Nema predstojećih plata",
  Upcoming_Holiday:"Nadolazeći praznik:",
  Invoice_Due:"Dospjeli račun:",
  New_Invoice:"Nova faktura",
  No_Invoices:"Bez faktura",
  No_Invoices_Word:"Kreirajte prvi <a routerLink='/folder/Invoice/New'>Fakturu</a>.",
  Cheque_Due:"Dospjeli ček:",
  Payrolls:"platne liste",
  Sandbox_Mode:"Sandbox način rada",
  Hire:"Najam",
  Pay:"Plati",
  New:"Novo",
  Add:"Dodati",
  Make:"Napravi",
  Time:"Vrijeme",
  Write:"Pisati",
  Holiday:"praznik",
  Holidays:"praznici",
  Next_Holiday:"Sljedeći praznik:",
  All_Done:"Sve završeno!",
  Employees:"Zaposleni",
  Payees:"Primatelji uplate",
  Job:"posao",
  Jobs:"poslovi",
  Invoice:"faktura",
  Invoices:"Fakture",
  Vacations:"Odmor",
  Cheques:"provjere",
  Brand_Cheques:"Brend",
  Payment:"Plaćanje",
  Enable_Payment:"Omogući plaćanje",
  Payments:"Plaćanja",
  Schedule:"Raspored",
  Schedules:"Rasporedi",
  Timesheet:"Time Sheet",
  Timesheets:"Time Sheets",
  Salary:"plata",
  New_Address:"Nova adresa",
  Address_2:"Adresa (red 2)",
  _City:"grad",
  _State:"Država/Prov",
  City:"Grad / naselje",
  State:"Država / Pokrajina",
  Postal:"Poštanski broj",
  ZIP:"Poštanski / ZIP",
  Country:"zemlja",
  Addresses:"Adrese",
  Required_Options:"Obavezne opcije",
  Optional_Options:"Opcione opcije",
  Additional_Options:"Dodatne opcije",
  Required:"Obavezno",
  Optional:"Neobavezno",
  Additional:"Dodatno",
  No_Addresses:"Nema adresa",
  New_Address_Word:"Dodajte prvu <a routerLink='/folder/Address/New'>adresu</a>.",
  Select_Address:"Odaberi adresu",
  No_Address:"Nema adresa",
  Print_Cheque:"Print Check",
  Print_Cheque_Now:"Odštampaj provjeri sada",
  Description:"Opis",
  Pay_To_The_Order_Of:"Plati po nalogu:",
  Select_Date_Range:"Odaberite raspon datuma",
  Select_Starting_Date:"Odaberi datum početka",
  Select_Ending_Date:"Odaberite datum završetka",
  Select_Date:"Odaberi datum",
  Cheque_Date:"Datum provjere",
  Cheque_Memo:"Provjeri bilješku",
  Blank_Cheque:"Prazni ček",
  Blank:"Prazno",
  No_Cheques:"bez čekova",
  No_Cheques_Word:"Odštampajte svoju prvu <a routerLink='/folder/Cheque/New'>ček</a>.",
  Cheque_Amount_Placeholder:"0,00 dolara",
  View_Image:"Prikaži sliku",
  View:"Pogled",
  Modify:"Izmijeniti",
  Delete:"Izbriši",
  Cheque_Paid:"Plaćeno",
  New_Deduction:"Novi odbitak",
  Title:"Naslov",
  Frequency:"Frekvencija",
  Hourly:"Po satu",
  Daily:"Dnevno",
  Weekdays:"radnim danima",
  BiWeekly:"2 sedmice",
  TriWeekly:"3 sedmice",
  Monthly:"Mjesečni",
  MiMonthly:"2 mjeseca",
  Quarterly:"Kvartalni",
  Yearly:"Godišnje",
  Every_Week:"Svake sedmice",
  Every_Payroll:"Svaki platni spisak",
  Every_Month:"Svaki mjesec",
  Annually:"Godišnje",
  Always_Scheduled:"Uvijek na rasporedu",
  Start_Date:"Datum početka",
  End_Date:"Datum završetka",
  Start_Time:"Vrijeme početka",
  End_Time:"Vrijeme kraja",
  Deduction_Label:"Oznaka odbitka",
  Notes:"Bilješke",
  Options:"Opcije",
  Enable:"Omogući",
  Select_Deductions:"Odaberite odbitke",
  Deductions:"Odbici",
  No_Deductions:"Bez odbitaka",
  No_Deductions_Word:"Kreirajte svoj prvi <a routerLink='/folder/Deduction/New'>Odbitak</a>.",
  New_Employee:"Novi zaposlenik",
  No_Title:"Bez naslova",
  _Name:"ime",
  About_Yourself:"O sebi",
  Full_Name:"Puno ime",
  Birthday:"Rođendan",
  Email:"Email",
  SMS:"SMS",
  Phone:"telefon",
  Test:"Test",
  Call:"pozovi",
  Fax:"faks",
  Printed_Note:"Štampana bilješka",
  Position:"Pozicija",
  Job_Position:"Pozicija za posao",
  Select_a_Job:"Odaberi posao",
  Select_a_Salary:"Odaberi platu",
  Add_a_Deduction:"Dodaj odbitak",
  Taxes:"Porezi",
  Add_Taxes:"Dodajte poreze",
  Date_of_Birth:"Datum rođenja",
  Email_Address:"E-mail adresa",
  Phone_Number:"Telefonski broj",
  Phone_Call:"Telefonski poziv",
  Enable_on_Payroll:"Omogući na platnom spisku",
  Select_Employees:"Odaberite zaposlene",
  No_Employees:"Nema zaposlenih",
  No_Employees_Word:"Dodajte svoj prvi novi <a routerLink='/folder/Employee/New'>Zaposleni</a>.",
  No_Name:"Bez imena",
  Unemployeed:"Nezaposleni",
  Employeed:"Zaposlen",
  Paid:"Plaćeno",
  Enabled:"Omogućeno",
  Disabled:"Invalid",
  Fire:"vatra",
  Not_Available:"Nije dostupno",
  Not_Available_Word:"Odštampajte svoju prvu <a routerLink='/folder/Invoice/New'>fakturu</a> i platite.",
  Select_Invoices:"Odaberi_Fakture",
  Print_Invoice_Word:"Odštampajte svoju prvu <a routerLink='/folder/Invoice/New'>fakturu</a> i platite.",
  Invoice_Title:"Naslov fakture",
  Invoice_Date:"Datum računa",
  Due_Date:"Rok dospijeća",
  New_Job:"Novi posao",
  Details:"Detalji",
  Customize:"Prilagodi",
  Customize_Dashboard:"Prilagodi kontrolnu tablu",
  Components:"Komponente",
  No_Components:"Bez komponenti",
  Main_Components:"Glavne komponente",
  Smaller_Components:"Manje komponente",
  Error_Loading_Page:"Greška pri učitavanju ove stranice.",
  Bank_Details:"Bankovni detalji",
  About_Your_Job:"O tvom poslu",
  Your_Schedule:"Vaš raspored",
  Job_Title:"Naziv posla",
  Job_Description:"Opis posla",
  Job_Details:"Detalji posla",
  Enable_Job:"Omogući posao",
  Pay_Period:"Platni period",
  Perpetually_Schedule:"trajni raspored",
  Select_Jobs:"Odaberi poslove",
  No_Jobs:"Nema poslova",
  Add_Jobs:"Dodaj poslove",
  No_Jobs_Word:"Dodajte prvi <a routerLink='/folder/Job/New'>Posao</a> na listu.",
  Count_Employees:"job.employee_count+' Employees'",
  Zero_Employees:"0 zaposlenih",
  New_Leave:"Novi odlazak",
  Leave_Name:"Napusti ime",
  Paid_Leave:"Plaćeno odsustvo",
  Leave_Pay:"Ostavite platu",
  Indefinite_Leave:"Neograničeno odsustvo",
  Indefinite_Payroll:"Neograničeni platni spisak",
  Leave:"Odlazi",
  Add_Schedules:"Dodaj rasporede",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Omogući odsustvo",
  Select_Leaves:"Odaberi lišće",
  No_Leaves:"Bez odsustva",
  Leave_Of_Absence:"Odsustvo",
  Leaves_Of_Absence:"Odsustvo",
  New_Leave_of_Absense:"Novo odsustvo",
  No_Leaves_Word:"Dodajte prvi <a routerLink='/folder/Leave/New'>odsustvo</a>.",
  Not_Enabled:"Nije omogućeno",
  Absences:"Izostanci",
  Payee:"Primalac uplate",
  New_Payee:"Novi primalac uplate",
  Payee_Identifier:"Identifikator primaoca uplate",
  Payee_Name:"Ime platioca",
  Payee_Title:"Naslov primaoca uplate",
  Payment_Memo:"Podatak o plaćanju",
  Select_Payees:"Odaberi primaoce plaćanja",
  No_Payees:"Bez primalaca plaćanja",
  Add_Payee_Note:"Dodajte prvog <a routerLink='/folder/Payee/New'>Primatelja</a>.",
  New_Payees:"Novi primaoci uplate",
  About_The_Payment_Schedule:"Raspored plaćanja",
  Your_Payroll_Schedule:"Automatski obračun zarada",
  New_Payment:"Novo plaćanje",
  Identifier:"identifikator",
  Select:"Odaberi",
  Memo:"Memorandum",
  Payment_Date:"Datum plaćanja",
  Mark_as_Paid:"Označi kao plaćeno",
  Select_Payments:"Odaberi plaćanja",
  No_Payments:"Bez plaćanja",
  No_Payments_Word:"Kreirajte prvu <a routerLink='/folder/Payment/New'>Uplatu</a>.",
  Create_Payroll:"Kreiraj platni spisak",
  Properties:"Svojstva",
  Payroll_Title:"Naziv platnog spiska",
  Payroll_Notes:"Bilješke o plaćama",
  Payroll_Setup:"Postavljanje platnog spiska",
  Tabulate_Payments:"Tabela plaćanja",
  Tabulate:"Tabela",
  By:"Od:",
  Payments_By:"Plaćanja do",
  Timesheets_And_Schedules:"Vremenski listovi i rasporedi",
  Both:"oba",
  Items:"Predmeti",
  Add_Payees:"Dodaj primaoce plaćanja",
  Add_Account:"Dodaj račun",
  Enable_Account:"Omogući račun",
  Enable_Payroll:"Omogući obračun plaća",
  Print_Payroll:"Odštampaj platni spisak",
  No_Payrolls:"Bez platnog spiska",
  No_Payroll_Word:"Kreirajte svoj prvi <a routerLink='/folder/Payroll/New'>platni spisak</a>.",
  View_more:"VIDI VIŠE",
  Less:"MANJE",
  Add_Payroll:"Dodaj platni spisak",
  Select_Payroll:"Odaberi platni spisak",
  About_Your_Salary:"O tvojoj plati",
  Add_Salaries:"Dodajte plate",
  Add_Salary:"Dodaj platu",
  Salaries:"plate",
  No_Salaries:"Bez plata",
  No_Salaries_Word:"Dodajte prvu <a routerLink='/folder/Salary/New'>Platu</a>.",
  Select_Salaries:"Odaberite plate",
  New_Salary:"Nova plata",
  Salary_Name:"Identifikator plate",
  Enable_Salary:"Omogući platu",
  Salary_Amount:"Iznos plate",
  New_Schedule:"Novi raspored",
  Schedule_Title:"Naslov rasporeda",
  Add_Address:"Dodaj adresu",
  Repeat:"ponoviti",
  Design:"Dizajn",
  Edit_Design:"Uredi dizajn",
  Edit_this_Design:"Uredi ovaj dizajn",
  Repeat_Payment:"Ponovno plaćanje",
  Enable_Schedule:"Omogući raspored",
  Never:"Nikad",
  Select_Schedule:"Izaberite rasporede",
  No_Schedules:"Bez rasporeda",
  No_Schedules_Word:"Kreirajte prvi <a routerLink='/folder/Schedule/New'>raspored</a>.",
  New_Administrator:"Novi administrator",
  Username:"Korisničko ime",
  First_Name:"Ime",
  Last_Name:"Prezime",
  Add_an_Address:"Dodaj adresu",
  Payment_Limit:"Ograničenje po plaćanju",
  Total_Limit:"Ukupni limit",
  Select_Accounts:"Odaberi račune",
  No_Administrators:"Bez administratora",
  No_Administrators_Word:"Kreirajte prvi <a routerLink='/folder/Administrator/New'>Admin račun</a>.",
  New_Administrators_Word:"Dodajte prvog <a routerLink='/folder/Administrator/New'>Administratora</a>",
  Cloud:"Oblak",
  Backup:"Rezervna kopija",
  Enable_iCloud:"Omogući iCloud",
  Enable_Google_Drive:"Omogući Google disk",
  Enable_Microsoft_OneDrive:"Omogući Microsoft OneDrive",
  Automatically_Backup:"Automatski sigurnosno kopiranje",
  FTP_Settings:"FTP postavke",
  URL_File_Prompt:"Molimo navedite lokaciju za .xls / .xlsx / .json datoteku za uvoz:",
  URL_SQL_Prompt:"Molimo navedite lokaciju SQLite datoteke za uvoz:",
  FTP_Backup:"FTP sigurnosna kopija",
  FTP_Import:"FTP uvoz",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"domaćin",
  Port:"Luka",
  Path:"Put",
  Data_Path:"Putanja podataka",
  Enable_FTP_Account:"Omogući FTP račun",
  HostnameIP:"ime domaćina",
  Password:"Lozinka",
  Connection_Port:"Priključak za povezivanje",
  Enable_MySQL_Database:"Omogući MySQL bazu podataka",
  Log:"Dnevnik",
  Reset:"Resetovati",
  Erase:"Izbriši",
  Export:"izvoz",
  Database:"baza podataka",
  Upload_CSV:"Učitaj CSV",
  Download_CSV:"Preuzmi CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL Backup",
  Internal_Notes:"Interne bilješke",
  Root_Path:"Root Path",
  Select_Backup:"Odaberi sigurnosnu kopiju",
  Add_New_Backup:"Dodaj novu sigurnosnu kopiju",
  First_Backup:"Podesite prvu sigurnosnu kopiju...",
  Backups:"Rezervne kopije",
  Add_Backup:"Dodaj sigurnosnu kopiju",
  No_Backups:"Nema rezervnih kopija koje treba pronaći.",
  Select_Backup_Type:"Odaberite vrstu sigurnosne kopije koju želite postaviti...",
  Backup_Type:"Vrsta rezervne kopije",
  FTP_Drive:"FTP disk",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"vožnja",
  Microsoft_OneDrive:"vožnja",
  Import_Fields:"Polja za uvoz",
  Import_Fields_Word:"Koristite ovaj odjeljak za odabir <a routerLink='/folder/Holiday/New'>Uvoz</a> podataka.",
  Upload:"Učitaj",
  Download:"Skinuti",
  Download_JSON:"Preuzmi kao JSON podatke",
  Download_SQL:"Preuzmi kao SQL datoteku",
  New_Bank:"Nova banka",
  New_Account:"Novi račun",
  New_Bank_Account:"Novi bankovni račun",
  Upload_Image:"Učitaj sliku",
  Bank_Name:"Ime banke",
  Bank_Address:"Adresa banke",
  Branch_Address:"Adresa filijale",
  Address_on_Cheque:"Adresa",
  Cheque_Numbers:"Provjerite brojeve",
  Cheque_Details:"Provjeri detalje",
  Bank_Logo:"Logotip banke",
  Cheque_ID:"Provjeri ID",
  Starting_Cheque_ID:"Početni ID provjere",
  Transit_Number:"Tranzitni broj",
  Institution_Number:"broj ustanove",
  Designation_Number:"označni broj",
  Account_Number:"Broj računa",
  Limits:"Granice",
  Default_Limits:"Zadana ograničenja",
  Over_Limit:"preko limita",
  Under_Limit:"ispod limita",
  Payroll_Limit:"Ograničenje platnog spiska",
  Enable_Bank_Account:"Omogući bankovni račun",
  Select_Account:"Odaberi račun",
  No_Bank_Account:"Nema bankovnog računa",
  No_Bank_Account_Word:"Dodajte prvi <a routerLink='/folder/Accounts/New'>bankovni račun</a>.",
  Bank_Accounts:"bankovni računi",
  No_Accounts:"Nema računa",
  No_Accounts_Note:"Dodajte prvi <a routerLink='/folder/Accounts/New'>bankovni račun</a>.",
  Cheque_Designer:"Provjeri dizajnera",
  Cheque_Design:"Provjeri dizajn",
  Select_Design:"Odaberite dizajn",
  Cheque_Designs:"Provjeri dizajne",
  No_Cheque_Designs:"Bez provjere dizajna",
  No_Cheque_Designs_Word:"Kreirajte svoj vlastiti <a routerLink='/folder/Settings/Cheque/Design/New'>Provjerite dizajn</a>.",
  Set_Default:"Postavi kao zadano",
  Default:"Zadano",
  Remove:"Ukloni",
  Folder:"Folder",
  Edit:"Uredi",
  LoadingDots:"Učitavanje...",
  Add_a_New_File:"Dodajte <a href='#' (kliknite)='add()'>Novi fajl</a> u",
  this_folder:"ovaj folder",
  FTP_Backup_Settings:"Postavke FTP sigurnosne kopije",
  Secure_File_Transfer:"Siguran prijenos datoteka",
  New_Holiday:"Novi praznik",
  Add_Holiday:"Dodaj praznik",
  Holiday_Name:"Ime praznika",
  Additional_Pay:"Dodatna plaća",
  Enable_Holiday:"Omogući odmor",
  Select_Holidays:"Odaberi praznike",
  No_Holidays:"Bez praznika",
  No_Holidays_Word:"Dodajte prvi <a routerLink='/folder/Holiday/New'>Praznik</a>.",
  New_Import:"Novi uvoz",
  Import_Data:"Uvoz podataka",
  Import_Data_Word:"Odaberite vrstu datoteke ili način otpremanja po vašem izboru.<br /> Moći ćete odabrati koja god uvezena polja u datoteci odgovaraju bilo kojem parametru u aplikaciji nakon učitavanja podržane datoteke.", 
  Import_File:"Uvezi fajl",
  Link_To_File:"Link do fajla",
  Select_File:"Odaberi datoteku",
  New_Moderator:"Novi moderator",
  Allow_Moderation:"Dozvoli moderaciju",
  Create_Paycheques:"Kreiraj plate",
  Edit_Paycheques_and_Data:"Uredi plate i podatke",
  Destroy_Data_and_Paycheques:"Uništi podatke i plate",
  Bonus_Percentage:"Procenat plate",
  Fixed_Amount:"fiksni iznos",
  Select_Moderator:"Odaberi moderatora",
  No_Moderators:"Bez moderatora",
  Moderators:"Moderatori",
  Moderator_Account:"Kreirajte prvi <a routerLink='/folder/Administrator/New'>nalog moderatora</a>.",
  No_Moderators_Word:"Dodajte prvog <a routerLink='/folder/Administrator/New'>Moderatora</a>.",
  Defaults:"Zadano",
  Provide_Us_Info:"Dajte nam informacije",
  Setup_Your_Printer:"Podesite svoj štampač",
  Your_Company:"O vašoj kompaniji",
  Company_Name:"Ime kompanije",
  Currency:"Valuta",
  Default_Currency:"Zadana valuta",
  Base_Monthly_Income:"Mjesecna zarada",
  Protection:"zaštita",
  App_Protection:"Zaštita aplikacija",
  PIN_Code_Protection:"Zaštita PIN koda",
  App_Protection_Word:"Omogućite sigurnosne metode koje pomažu u zaštiti vašeg računa.",
  PIN_Code:"PIN Code",
  Change_PIN:"Promijeni PIN",
  New_Password:"Nova šifra",
  Geofence_Protection:"Zaštita geo-ograde",
  Geofence_Area:"Set Area",
  Distance:"Razdaljina",
  Setup_Now:"Podesite sada",
  Mile:"Mile",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Prepoznavanje lica",
  Face:"lice",
  Setup:"Postaviti",
  Label:"Etiketa",
  Password_Protection:"Zaštita lozinkom",
  Modify_Password:"Izmijeni lozinku",
  New_Tax_Entry:"Novi unos poreza",
  New_Tax:"Novi porez",
  Tax_Label:"Poreska oznaka",
  Perpetually_Enabled:"Trajno omogućeno",
  Select_Taxes:"Odaberite poreze",
  Tax_Deductions:"Poreske olakšice",
  No_Tax_Deductions:"Bez poreskih olakšica",
  No_Tax_Deductions_Word:"Dodajte prvi <a routerLink='/folder/Tax/New'>porezni</a> odbitak.",
  New_Timer:"Novi tajmer",
  Start:"Počni",
  Stop:"Stani",
  Start_Timer:"Start Timer",
  Stop_Timer:"Zaustavi tajmer",
  Timer_Active:"Timer Active",
  Timer:"Tajmer:",
  Timer_Running:"Tajmer: (u radu)",
  Save_Timer:"Save Timer",
  New_Timesheet:"Novi radni list",
  Select_Timesheets:"Izaberite raspored sati",
  Work_Notes:"Radne bilješke",
  Entry_Title:"Naslov unosa",
  No_Timesheets:"Bez vremenskih tablica",
  No_Timesheets_Word:"Dodajte prvi <a routerLink='/folder/Timesheet/New'>Time Sheet</a>.",
  Timesheet_Submitted:"Time Sheet dostavljen",
  Timesheet_Congrats:"Čestitamo! Vaš vremenski list je uspješno poslat. Hvala!",
  Timesheet_Copy:"Da biste dobili kopiju vaših dokumenata, molimo da nam dostavite svoju adresu e-pošte i/ili broj mobilnog telefona.",
  Submit:"Pošalji",
  Allow_Notifications:"Dozvoli obavještenja",
  Untitled_Entry:"Novi ulaz",
  Untitled_Bank:"Untitled Bank",
  Timesheet_Entry:"Unos radnog vremena",
  Work_Description:"Opis posla",
  Enable_Timesheet:"Omogući evidenciju radnog vremena",
  Submit_Timesheet:"Slanje radnog vremena",
  Vacation:"Odmor",
  New_Vacation:"Novi odmor",
  Vacation_Name:"Naziv odmora",
  Paid_Vacation:"Plaćeni odmor",
  Vacation_Pay:"Regres",
  Enable_Vacation:"Omogući odmor",
  Select_Vacations:"Odaberi odmor",
  No_Vacations:"Bez odmora",
  No_Vacations_Word:"Kreirajte prvi unos <a routerLink='/folder/Vacation/New'>Odmor</a>.",
  Payroll_Schedule:"Raspored zarada",
  Next_Payroll:"Sljedeća platna lista:",
  Upcoming_Payroll:"Predstojeći obračun plaća",
  No_Upcoming_Payroll:"Nema predstojećih plata",
  Address_Book:"Adresar",
  Archived_Documents:"Arhivirani dokumenti",
  Dev_Mode:"Aplikacija u razvojnom modu",
  Administrators:"Administratori",
  Privacy:"Privatnost",
  None:"Nijedan",
  Select_Signature:"Odaberi potpis",
  No_Signatures:"Bez potpisa",
  No_Signatures_Word:"Dodajte prvi <a routerLink='/folder/Signature/New'>Potpis</a>.",
  Repeat_Indefinitely:"Ponavljati neograničeno",
  Sign:"znak",
  Sign_Here:"Potpisati ovdje",
  Date_Signed:"Datum potpisa",
  Signature_Pad:"Pad za potpis",
  Account_Holder:"Vlasnik računa",
  Account_Properties:"Svojstva računa",
  Name_On_Cheque:"Ime na čeku",
  Server_Hostname:"Server Hostname / IP",
  Printers:"Štampači",
  No_Printers:"Bez štampača",
  Printer_Exists:'Štampač pod ovim imenom već postoji.',
  No_Printers_Word:"Dodajte prvi <a routerLink='/folder/Printer/New'>Štampač</a>.",
  No_Printer_Alert:"Nije definisan nijedan štampač. Želite li podesiti štampač?",
  Add_Printer:"Dodaj štampač",
  New_Printer:"Novi štampač",
  Printer_Hostname:"Printer Hostname / IP",
  Printer_Label:"Etiketa za štampač",
  Printer_Protocol:"Protokol štampača",
  Protocol:"protokol",
  Email_Print:"Email",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"utičnica",
  Print_Job:"Posao za štampanje",
  Printed:"Štampano",
  Not_Printed:"Nije štampano",
  Print_Jobs:"Poslovi štampanja",
  Print_Queue:"Red za štampanje",
  No_Print_Jobs:"Bez poslova štampanja",
  No_Prints:"Kreirajte novi <a routerLink='/folder/Cheque/New'>ček</a> za štampanje.",
  Prints:"Otisci",
  Find_Printer:"Pronađi štampač",
  Find_AirPrint_Devices:"Pronađi AirPrint uređaje",
  Select_Printer:"Odaberi štampač",
  System_UI:"Sistemsko korisničko sučelje",
  Printer:"Štampač",
  Status:"Status",
  Preview:"Pregled",
  Enable_Print_Job:"Omogući zadatak štampanja",
  Queue_Weight:"Težina u redu",
  Unlimited:"Neograničeno",
  Show_Advanced_Printer_Options:"Prikaži napredne opcije štampača",
  Advanced:"Napredni",
  Location:"Lokacija",
  Note:"Bilješka",
  Queue_Name:"Ime reda",
  Pages_Printed_Limit:"Ograničenje broja štampanih stranica",
  MultiPage_Idle_Time:"Vrijeme čekanja na više stranica (s)",
  Page_Count_Limit:"Ograničenje broja stranica",
  Page_Orientation:"Orijentacija stranice",
  Portrait:"Portret",
  Landscape:"Pejzaž",
  Duplex:"dupleks",
  Double_Sided_Printing:"dvostrano",
  Duplex_Mode:"Duplex Mode",
  Duplex_Short:"Kratko",
  Duplex_Long:"Dugo",
  Duplex_None:"Nijedan",
  Color_And_Quality:"Boja i kvalitet",
  Monochrome:"Monohrom",
  Photo_Quality_Prints:"Kvalitet fotografija",
  Printer_Email:"E-pošta štampača",
  Automatically_Downsize:"Automatski smanjivanje",
  Paper:"papir",
  Paper_Name:"Naziv papira",
  Paper_Width:"Širina papira",
  Paper_Height:"Visina papira",
  Paper_Autocut_Length:"Dužina automatskog rezanja papira",
  Margins:"Margine",
  Page_Margins:"Margine stranice",
  Page_Margin_Top:"Margina na vrhu stranice",
  Page_Margin_Right:"Desna margina stranice",
  Page_Margin_Bottom:"Donja margina stranice",
  Page_Margin_Left:"Margina lijeve strane",
  Add_Employees:"Dodaj zaposlene",
  Header:"Zaglavlje",
  Print_A_Page_Header:"Odštampaj zaglavlje stranice",
  Header_Label:"Header Label",
  Header_Page_Index:"Indeks zaglavlja",
  Header_Font_Name:"Font zaglavlja",
  Select_Font:"Odaberi font",
  Font_Selector:"Selektor fontova",
  Header_Font_Size:"Font zaglavlja",
  Header_Bold:"Podebljano zaglavlje",
  Header_Italic:"Hader Italic",
  Header_Alignment:"Poravnavanje zaglavlja",
  Left:"lijevo",
  Center:"Centar",
  Right:"Tačno",
  Justified:"opravdano",
  Header_Font_Color:"Boja zaglavlja",
  Select_Color:"Odaberi boju",
  Footer:"Podnožje",
  Print_A_Page_Footer:"Odštampaj podnožje stranice",
  Footer_Label:"Oznaka podnožja",
  Footer_Page_Index:"Indeks stranice u podnožju",
  Footer_Font_Name:"Font podnožja",
  Fonts:"Fontovi",
  Done:"Gotovo",
  Select_Fonts:"Odaberi fontove",
  Footer_Font_Size:"Veličina podnožja",
  Footer_Bold:"Podnožje podebljano",
  Footer_Italic:"Footer Italic",
  Footer_Alignment:"Poravnanje podnožja",
  Footer_Font_Color:"Boja podnožja",
  Page_Copies:"Kopije stranica",
  Enable_Printer:"Omogući štampač",
  Remote_Logging:"Daljinsko evidentiranje",
  Log_Server:"Log server",
  Encryption:"Enkripcija",
  Random_Key:"Slučajni ključ",
  Encryption_Key:"Ključ za šifriranje",
  Cheques_Webserver:"Prilagođena baza podataka",
  Learn_How:"Nauci kako",
  Signature:"Potpis",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Pogledaj potpis",
  Printed_Signature:"Štampani potpis",
  Digitally_Sign:"Digitalni potpis",
  Digital_Signature:"Digitalni potpis",
  Add_Signature:"Dodaj potpis",
  Add_Your_Signature:"Dodajte svoj potpis",
  Enable_Signature:"Omogući potpis",
  Digitally_Signed:"Digitalno potpisan",
  Insert_Error:"Nije moguće sačuvati ček zbog problema s bazom podataka.",
  Delete_Confirmation:"Jeste li sigurni da želite izbrisati ove informacije?",
  Discard_Confirmation:"Jeste li sigurni da želite odbaciti ovu informaciju?",
  Discard_Bank_Confirmation:"Jeste li sigurni da želite odbaciti ovaj račun?",
  Discard_Printer_Confirmation:"Jeste li sigurni da želite odbaciti ovaj štampač?",
  Delete_Bonus_Confirmation:"Jeste li sigurni da želite izbrisati ovaj bonus?",
  Delete_Invoice_Confirmation:"Jeste li sigurni da želite izbrisati ovu fakturu?",
  Generated_Cheque:"generirana provjera",
  Generated_Invoice:"generisana faktura",
  Schedule_Start_Time:"Početak rasporeda",
  Schedule_End_Time:"Završetak rasporeda",
  New_Call:"Novi poziv",
  No_Contacts:"Nema kontakata",
  No_Contacts_Word:"Administratori, moderatori, zaposlenici i primaoci plaćanja pojavljuju se kao kontakti.",
  PDF_Subject:"finansije",
  PDF_Keywords:"platni spisak PDF čekovi čekovi",
  Printer_Setup:"Podešavanje štampača",
  Printer_Selection:"Odabir štampača",
  New_Fax:"Novi faks",
  New_Fax_Message:"Nova faks poruka",
  Fax_Machine:"Faks uređaj",
  Fax_Name:"Naziv faksa",
  Fax_Email:"Fax Email",
  Fax_Number:"Broj faksa",
  Contents:"Sadržaj",
  Fax_Body:"Tijelo stranice",
  Header_Word:"Zaglavlje:",
  Header_Text:"Tekst zaglavlja",
  Include_Header:"Uključi zaglavlje",
  Include_Footer:"Uključi podnožje",
  Footer_Word:"Podnožje:",
  Footer_Text:"Tekst u podnožju",
  Attach_a_Cheque:"Priložiti ček",
  Add_Deduction:"Dodaj odbitak",
  Enable_Fax:"Pošalji faks",
  Select_Fax:"Odaberi faks",
  No_Faxes:"Bez faksova",
  Faxes:"faksovi",
  Save_and_Send:"Pošalji faks",
  Save_and_Pay:"Uštedi i plati",
  WARNING:"UPOZORENJE:",
  Remember:"Zapamti",
  Printing:"štampanje",
  Printing_Complete:"Štampanje je završeno!\n\n",
  of:"od",
  There_Were:"Bili su ",
  Successful_Prints:" uspješni otisci i ",
  Unsuccessful_Prints:"neuspjeli otisci.",
  PrinterError:"Izvinite! Došlo je do greške.",
  Printing_:"Štampanje...",
  PrinterSuccess:"Dokument je uspešno odštampan.",
  PrintersSuccess:"Dokumenti su uspešno odštampani.",
  Print_Cheques:"Štampanje čekova",
  New_Message:"Nova poruka",
  New_Messages:"Nove poruke",
  Read_Message:"Pročitaj poruku",
  Write_Message:"Napiši poruku",
  Send_Message:"Pošalji poruku",
  Subject:"Predmet",
  Message:"poruka",
  Writing:"Pisanje...",
  Send:"Pošalji",
  Set_Date:"Postavi datum",
  Set_Time:"Podesi vrijeme",
  Recieve:"Primi",
  Set_as_Default:"Postavi kao zadano",
  Default_Account:"Zadani račun",
  Default_Design:"Zadani dizajn",
  Multiple_Cheques:"Čekovi (trostruki)",
  Account_Mode:"Način računa",
  Multiple_Cheques_Description:"Tri čeka po stranici.",
  Check_and_Table:"Provjeri i sto",
  Check_including_Table:"Check & Accounting Table.",
  Check_Mailer:"Provjeri mailer",
  Check_Mailer_Window:"Provjerite u prozoru za adresu.",
  DocuGard_Table_Top:"DocuGard provjera i stol (vrh)",
  DocuGard_Table_Middle:"DocuGard Check & Table (Srednji)",
  DocuGard_Table_Bottom:"DocuGard provjera i tablica (dolje)",
  DocuGard_Mailer_Top:"DocuGard Check Mailer (Vrh)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (Srednji)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mailer (dole)",
  DocuGard_Three_Cheques:"DocuGard provjere (trostruki)",
  DocuGard_Cheque_Top:"DocuGard Check (vrh)",
  DocuGard_Cheque_Middle:"DocuGard Check (Srednji)",
  DocuGard_Cheque_Bottom:"DocuGard Check (dole)",
  DocuGard_Three_Cheques_Window:"Tri čeka na jednoj stranici.",
  DocuGard_Table_Top_Window:"Tabela čekova i zarade.",
  DocuGard_Table_Middle_Window:"Tabela čekova i zarade.",
  DocuGard_Table_Bottom_Window:"Tabela čekova i zarade.",
  DocuGard_Mailer_Top_Window:"Ček, sto i adresa.",
  DocuGard_Mailer_Middle_Window:"Ček, sto i adresa.",
  DocuGard_Mailer_Bottom_Window:"Ček, sto i adresa.",
  DocuGard_Cheque_Top_Window:"Provjerite siguran papir.",
  DocuGard_Cheque_Middle_Window:"Provjerite siguran papir.",
  DocuGard_Cheque_Bottom_Window:"Provjerite siguran papir.",
  Basic_Cheque:"Provjeri (vrh)",
  Basic_Cheque_Print:"Odštampajte jedan ček.",
  Error_Setting_As_Paid:"Greška pri postavljanju kao plaćeno",
  Add_Attachment:"Dodaj prilog",
  PrinterUnavailable:"Štampač nedostupan",
  CreditCardComponent:"karte",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Novi depozit",
  Deposits:"Depoziti",
  No_Deposits:"Bez depozita",
  Credit_Card_Deposit:"Kreditna kartica",
  New_Credit_Card_Deposit_Message:"Depozit kreditne kartice",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin depozit",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Interac Transfer",
  Payments_Limit:"Limit plaćanja",
  No_Payment_Limit:"Bez limita plaćanja",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal depozit",
  No_Deposits_Word:"Dodajte prvu kompaniju <a routerLink='/folder/Deposit/New'>Depozit</a>.",
  No_Documents_Specified:"Nije određen dokument za štampanje",
  No_Printers_Added:"Nije pronađen nijedan štampač. Idite na Postavke > Štampači da ga dodate.",
  DB_Erase_Prompt:"Potpuno obrisati cijelu bazu podataka? UPOZORENJE: Izgubit ćete sve pohranjene informacije!",
  Console_Warning:"Ne lijepite nikakav tekst u ovu konzolu. Možete sebe i/ili svoju kompaniju izložiti ozbiljnom riziku.",
  No_Faxes_Word:"Kreirajte prvi <a routerLink='/folder/Fax/New'>Fax</a>.",
  Cheque_Delete_Confirmation:"Jeste li sigurni da želite izbrisati ovaj ček?",
  Design_Delete_Confirmation:"Jeste li sigurni da želite izbrisati ovaj dizajn?",
  Cheque_Pay_Confirmation:"Označiti ovaj ček kao plaćen? Neće se pojaviti u redu čekanja za štampanje.",
  Payment_Pay_Confirmation:"Označiti ovu uplatu kao plaćenu? Neće se pojaviti u redu čekanja.",
  Delete_Deduction_Confirmation:"Jeste li sigurni da želite izbrisati ovaj odbitak?",
  Delete_Job_Confirmation:"Jeste li sigurni da želite izbrisati ovaj posao?",
  Delete_Timesheet_Confirmation:"Jeste li sigurni da želite izbrisati ovaj raspored?",
  Delete_Schedule_Confirmation:"Jeste li sigurni da želite izbrisati ovaj raspored?",
  Delete_Setting_Confirmation:"Jeste li sigurni da želite resetirati ovu postavku?",
  Delete_Fax_Confirmation:"Jeste li sigurni da želite izbrisati ovaj faks?",
  Delete_File_Confirmation:"Jeste li sigurni da želite izbrisati ovaj fajl?",
  Delete_Vacation_Confirmation:"Jeste li sigurni da želite izbrisati ovaj odmor?",
  Delete_Printer_Confirmation:"Jeste li sigurni da želite izbrisati ovaj štampač?",
  Remove_Design_Confirmation:"Jeste li sigurni da želite izbrisati ovaj dizajn?",
  Delete_Payroll_Confirmation:"Jeste li sigurni da želite izbrisati ovaj platni spisak?",
  Send_Fax_Email_Confirmation:"Želite li faksom i emailom poslati ove dokumente?",
  Send_Email_Confirmation:"Želite li ovaj dokument poslati e-poštom?",
  Send_Fax_Confirmation:"Želite li faksom poslati ovaj dokument?",
  Error_Generating_PDF:"Izvinite. Došlo je do greške prilikom generisanja ovog dokumenta.",
  PDF_Error_Saving_Image:"Izvinite. Došlo je do greške prilikom spremanja PDF slike ovog dokumenta.",
  Test_Printer_Confirmation:"Želite li da odštampate probnu stranicu na ovom štampaču?",
  Save_Timesheet_Prompt:"Molimo dodajte 'Naslov' ili pritisnite 'Pokreni tajmer' da sačuvate.",
  Remove_Geofence_Prompt:"Jeste li sigurni da želite ukloniti lokaciju ove geo-ograde?",
  Delete_Employee_Confirmation:"Jeste li sigurni da želite izbrisati ",
  Fire_Employee_Confirmation:"Jesi li siguran da želiš pucati"
}