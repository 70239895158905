export const De = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Urheberrecht &copy; 2023",
  black:"Schwarz",
  green:"Grün",
  gold:"Gold",
  blue:"Blau",
  brown:"Braun",
  purple:"Lila",
  pink:"Rosa",
  red:"Rot",
  Swatches:"Farbfelder",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Farbton",
  Saturation:"Sättigung",
  Lightness:"Leichtigkeit",
  Upgrade_To_Pro:"Auf Pro upgraden",
  AllFeaturesInclude:"Alle Funktionen umfassen:",
  PrintUnlimitedCheques:"Unbegrenzt Schecks drucken",
  PremiumChequeDesigns:"Premium-Check-Designs",
  ManageUnlimitedEmployees:"Unbegrenzte Mitarbeiter verwalten",
  AddUnlimitedPayees:"Unbegrenzte Zahlungsempfänger hinzufügen",
  CreateUnlimitedPayrolls:"Erstellen Sie unbegrenzte Gehaltsabrechnungen",
  UnlimitedSchedulesandTimeSheets:"Unbegrenzte Zeitpläne und Stundenzettel",
  BulkPayPalPayouts:"Bulk-PayPal-Auszahlungen",
  UnlimitedBankAccounts:"Unbegrenzte Bankkonten",
  ManageMultipleCompanies:"Verwalten Sie mehrere Unternehmen",
  TrackInsurancePolicies:"Verfolgen Sie Versicherungspolicen",
  Bio_MetricProtection:"Biometrischer Schutz",
  Geo_FenceLock_OutProtection:"Geo-Fence-Sperrschutz",
  Multiple_Companies_Word:"Die Verwaltung mehrerer Unternehmen ist ohne Checks Premium nicht verfügbar.",
  PayPal_Payouts_Word:"PayPal-Zahlungen sind ohne Checks-Prämie deaktiviert.",
  PINProtection:"PIN-Schutz",
  PasswordProtection:"Passwortschutz",
  May_Require_Approval:"Kann eine Genehmigung erfordern.",
  Subscribe:"Abonnieren",
  Billed:"In Rechnung gestellt",
  Up:"Hoch",
  Down:"Runter",
  Positioning:"Positionierung",
  Marker:"Marker",
  Drag_Marker:"Markierung ziehen",
  Tagline:"Drucken Sie Schecks aus und tabellarisieren Sie die Gehaltsabrechnung",
  Marker_Word:"Verwenden Sie die Markierungen, um die Größe des Elements festzulegen.",
  Pinch_Zoom:"Pinch-Zoom",
  Pinch_Word:"Drücken Sie, um das Element zu vergrößern.",
  Drag:"Ziehen",
  Drag_Word:"Verwenden Sie Ihren Finger, um Elemente zu ziehen.",
  subscription_alias_word:"Abonnement mit automatischer Verlängerung",
  premium_alias_word:"Einmaliges Upgrade-Paket",
  print_alias_word:"Einzelschecks ausdrucken",
  mode_alias_word:"Modus",
  Pro:"Profi",
  Pro_word:"Pro-Version erforderlich.",
  Cant_Delete_Default_Company_Word:"Entschuldigung, Sie können Ihr Standardunternehmen nicht löschen.",
  Reinsert_Default_Designs:"Standarddesigns erneut einfügen",
  Reinsert_Default_Designs_word:"Möchten Sie Standarddesigns wieder einfügen?",
  Subscription_Active_Disable_Word:"Dieses Abonnement ist aktiv. Möchten Sie dieses Abonnement für Cheques kündigen?",
  Company_Logo:"Firmenlogo",
  ZERO_:"NULL",
  Disclaimer:"Haftungsausschluss",
  Privacy_Policy:"Datenschutz-Bestimmungen",
  EULA:"Überprüft die EULA",
  Terms_Of_Service:"Nutzungsbedingungen",
  Terms_Of_Use:"Nutzungsbedingungen",
  Refunds:"Rückgaberecht",
  Single_Print:"1 Prüfen",
  Two_Prints:"2 Schecks",
  Five_Prints:"5 Schecks",
  Ten_Prints:"10 Schecks",
  Fifteen_Prints:"15 Schecks",
  Twenty_Prints:"20 Schecks",
  Thirty_Prints:"30 Schecks",
  Image_Added:"Bild hinzugefügt",
  Image_Preview:"Bildvorschau",
  No_Image_Was_Selected:"Es wurde kein Bild ausgewählt.",
  Cheques_Unlimited:"Schecks Unbegrenzt",
  _Subscription:"Abonnement",
  Subscription:"Schecks - 1 Monat",
  Two_Month_Subscription:"Kontrollen - 2 Monate",
  Three_Month_Subscription:"Kontrollen - 3 Monate",
  Six_Month_Subscription:"Schecks - 6 Monate",
  Twelve_Month_Subscription:"Schecks - 12 Monate",
  Cheques_Are_Available:"Schecks stehen zum Ausdrucken zur Verfügung.",
  Upgrade_Required_Two:"Wählen Sie ein Paket aus und tippen Sie doppelt auf die Preisschaltfläche, um mit dem Kauf zu beginnen. Drucken Sie in Sekundenschnelle professionell aussehende Schecks in Farbe.",
  Month:"Monat",
  Due:"Fällig:",
  Expires:"Läuft ab:",
  Subscription_Active:"Abonnement aktiv",
  Subscription_Inactive:"Abonnement inaktiv",
  Purchase_Additional_Cheques:"Zusätzliche Schecks kaufen?",
  Printable_Cheque:"Druckbarer Scheck",
  Printable_Cheques:"Druckbare Schecks",
  Printable_Cheque_Word:"Scheck ist auf Ihrem Konto verfügbar.",
  Printable_Cheques_Word:"Schecks sind auf Ihrem Konto verfügbar.",
  Max_Amount_Message:"Der von Ihnen angegebene Betrag hat den für dieses System festgelegten Höchstbetrag überschritten:",
  Terms_Required_Word:"Sie müssen dieser Vereinbarung zustimmen, bevor Sie Schecks weiter verwenden können.",
  Subscriptions:"Abonnements",
  Product_Upgrades:"Upgrades",
  Mailed_Out_Cheques:"Versendete Schecks",
  Enter_A_Company_Name:"Bitte geben Sie einen Firmennamen ein.",
  Single_Cheques:"Einzelprüfungen",
  Cheque_Golden:"Goldener Scheck",
  Cheque_Golden_Window:"Goldenes Karo-Design.",
  Cheque_Green:"Grüner Scheck",
  Cheque_Green_Window:"Grünes Karodesign.",
  Cheque_Red:"Roter Scheck",
  Cheque_Red_Window:"Rot kariertes Design.",
  Cheque_Yellow:"Gelber Scheck",
  Cheque_Yellow_Window:"Gelbes Karodesign.",
  Cheque_Statue_of_Liberty:"Freiheitsstatue",
  Cheque_Statue_of_Liberty_Window:"Kariertes Design der Freiheitsstatue.",
  Cheque_Green_Wave:"Grüne Welle",
  Cheque_Green_Wave_Window:"Grünes Karodesign.",
  Cheque_Golden_Weave:"Goldenes Gewebe",
  Cheque_Golden_Weave_Window:"Elegantes goldenes Karo-Design.",
  Cheque_Green_Sun:"Grüne Sonne",
  Cheque_Green_Sun_Window:"Tiefes und beruhigendes Karomuster.",
  Cheque_Blue_Checkers:"Blaue Dame",
  Cheque_Blue_Checkers_Window:"Blaues Karodesign.",
  Cashiers_Check:"Kassenscheck",
  Cashiers_Check_Window:"Stilvorlage für Kassenschecks.",
  Cheque_Aqua_Checkers:"Aqua Dame",
  Cheque_Aqua_Checkers_Window:"Aqua-Check-Design.",
  Cheque_Golden_Checkers:"Goldene Dame",
  Cheque_Golden_Checkers_Window:"Goldenes Karo-Design.",
  Upgrade_Unavailable:"Upgrades nicht verfügbar",
  Bank_Code_Protection:"Schutz der Banknummer",
  Bank_Code_Protection_Word:"Schützen Sie Ihre Banknummern davor, in dieser App verwendet zu werden, die auf einem anderen Gerät oder für einen anderen Benutzer ausgeführt wird. Diese Aktion ist irreversibel. Weitermachen?",
  Bank_Code_Protection_Blocked_Word:"Die Banknummern, die Sie verwenden möchten, sind für einen anderen Benutzer oder ein anderes Gerät reserviert.",
  Bank_Code_Protection_Error_Word:"Die Nummernüberprüfung ist fehlgeschlagen. Stellen Sie eine Internetverbindung her und versuchen Sie erneut, dieses Bankkonto hinzuzufügen.",
  Bank_Code_Protection_Set_Error_Word:"Der Banknummernschutz erfordert, dass Sie mit dem Internet verbunden sind. Bitte verbinden und erneut versuchen.",
  Upgrade_Unavailable_Word:"Tut uns leid, wir haben Probleme, dich zu verifizieren. Abonnements und Upgrades für Checks sind derzeit in Ihrer Region nicht zum Kauf verfügbar.",
  PayPal_Payment_Preview:"PayPal-Zahlungsvorschau",
  Apple_Pay:"ApplePay",
  Select_PayPal:"Wählen Sie PayPal aus",
  PayPal_Applications:"PayPal-Anwendungen",
  Purchase_With_Apple_Pay:"Kauf mit Apple Pay",
  Google_Pay:"GooglePay",
  Companies:"Firmen",
  Insurance:"Versicherung",
  New_PayPal:"Neues Paypal",
  Pay_By:"Zahlung per",
  Insure:"Versichern",
  Miles:"Meilen",
  Payment_Method:"Bezahlverfahren",
  Select_Policies:"Wählen Sie Richtlinien aus",
  Policies:"Richtlinien",
  Policy:"Politik",
  No_PayPal_Account:"Kein PayPal-Konto",
  No_Policies:"Keine Versicherungspolicen",
  New_Policy:"Neue Richtlinien",
  PayPal_Payment:"PayPal Bezahlung",
  PayPal_Payments:"PayPal-Zahlungen",
  No_Payment_Selected:"Keine Zahlung ausgewählt",
  Sending_Payment_Word:"Bitte warten... Diese Zahlung wird gesendet.",
  Sending_Payments_Word:"Bitte warten... Zahlungen werden gesendet.",
  No_Payment_Selected_PayPal:"Keine <a routerLink='/folder/Payments'>PayPal-Zahlung</a> zum Senden ausgewählt.",
  Payment_Sent:"Bezahlung gesendet",
  PayPal_Payment_Sent:"Diese Zahlung wurde mit PayPal gesendet.",
  Copay:"Zuzahlung",
  Dead:"Tot",
  Alive:"Lebendig",
  Not_Dead:"Nicht tot",
  Unclaimed:"Nicht abgeholt",
  Attempted:"Versucht",
  Deceased:"Verstorben",
  Claimed:"Behauptet",
  Unpaid:"Unbezahlt",
  Sending_Payment:"Zahlung senden",
  Sending_Payments:"Zahlungen senden",
  Send_PayPal_Confirmation:"Möchten Sie diese Transaktion mit PayPal senden?",
  Send_PayPal_Confirmation_Word:"Drücken Sie die grüne Taste, um diese Transaktion zu senden.",
  Save_Payment_As_Unpaid:"Diese Zahlung als unbezahlt speichern?",
  Payment_Pay_Confirmation_PayPal:"Diese Zahlung als bezahlt speichern?",
  No_Policies_Word:"die erste <a routerLink='/folder/Portage/New'>Versicherungspolice</a> hinzu .",
  Timesheet_Multiple_Delete_Confirmation:"Möchten Sie wirklich mehrere Stundenzettel löschen?",
  Select_Multiple_Timesheets_Prompt:"Keine Arbeitszeittabellen ausgewählt. Wählen Sie mindestens eine Arbeitszeittabelle aus.",
  Select_Multiple_Policies_Prompt:"Keine Richtlinien ausgewählt. Wählen Sie mindestens eine Versicherungspolice aus.",
  Policies_Multiple_Delete_Confirmation:"Möchten Sie wirklich mehrere Richtlinien löschen?",
  Company:"Unternehmen",
  Add_Company:"Unternehmen hinzufügen",
  New_Company:"Unternehmen hinzufügen",
  No_Companies:"Keine Unternehmen",
  Enable_Company:"Unternehmen aktivieren",
  Select_Company:"Unternehmen auswählen",
  The_Default_Company:"Das standardmäßige Firmenlabel.",
  Manage_Companies:"Unternehmen verwalten",
  No_Companies_Word:"Prüfungen verwenden ein Standardunternehmen .<br />Fügen Sie das <a routerLink='/folder/Company/New'>erste Unternehmen</a> hinzu .",
  Default_Company:"Standardunternehmen",
  Cheques_Unlimited_Word:"Mit Checks Unlimited können Sie so viele Schecks drucken, wie Sie möchten.",
  Cheques_Subscription_Word:"Mit einem Scheckabonnement können Sie so viele Schecks drucken, wie Sie möchten.",
  You_Own_This_Product:"Sie besitzen dieses Produkt.",
  Your_Subscription_is_Active:"Ihr Abonnement ist aktiv.",
  Active_Products:"Aktivierte Produkte",
  Purchase_Confirmation:"Kaufen",
  Purchase_Cheques:"Schecks kaufen",
  Restore_Purchase:"Einkäufe wiederherstellen",
  Erase_Purchase:"Käufe löschen",
  Successfully_Purchased:"Erfolgreich gekauft",
  Enter_Your_Licence_Key:"Bitte geben Sie Ihren Lizenzschlüssel auf dieser Seite ein, um dieses Produkt zu aktivieren.",
  Licence_Key:"Lizenzschlüssel",
  Enter_Licence_Key:"Gebe den Lizenzschlüssel ein",
  Purchased:"Gekauft",
  Enable_Feature:"Funktion aktivieren",
  Cancel_Subscription:"Abonnement beenden",
  Subscription_Removed:"Abonnement entfernt",
  Select_Active_Subscription:"Wählen Sie ein aktives Abonnement aus, um es zu ändern.",
  Remove_Subscription_Word:"Möchten Sie dieses Abonnement wirklich kündigen?",
  Delete_Company_Confirmation:"Möchten Sie wirklich dieses gesamte Unternehmen löschen? ACHTUNG: Sie verlieren alle gespeicherten Informationen!",
  Delete_Default_Company_Word:"Sie können die Standardfirma nicht löschen. Möchten Sie die Anwendung zurücksetzen und auf den Standardzustand zurücksetzen? ACHTUNG: Sie verlieren alle gespeicherten Informationen!",
  Console_Warning_2:"Verwenden Sie diese Anwendung nicht für Währungen, die Ihnen derzeit nicht gehören.",
  Terms_and_Conditions:"Geschäftsbedingungen",
  and_the:"und das",
  for:"für",
  Please_Read_Word:"Bitte lesen und stimmen Sie zu",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Einige Währungsumrechnungskurse konnten nicht gefunden werden. Bitte verbinden Sie sich mit dem Internet.",
  Free:"Frei",
  DB_Erase_Prompt_2:"Die gesamte Entwicklerdatenbank vollständig löschen? WARNUNG: Sie verlieren alle KAUF- und ABONNEMENT-Informationen!",
  Successfully_Imported:"Erfolgreich importiert",
  Select_Postage:"Wählen Sie Porto aus",
  No_Postage:"Keine Briefmarken",
  No_Paid_Postage_Word:"Fügen Sie den ersten Stempel <a routerLink='/folder/Portage/New'>Paid Porto</a> hinzu.",
  Trial_Complete:'Versuch abgeschlossen',
  Please_Upgrade:'Bitte aktualisieren Sie Schecks, um mit dem Drucken fortzufahren.',
  Aa:"Aa",
  Color:"Farbe",
  Font:"Schriftart",
  Guide:"Führen",
  Guides:"Führer",
  Image:"Bild",
  Zoom:"Zoomen",
  Logo:"Logo",
  Bank:"Bank",
  MICR:"MICR",
  Total:"Gesamt",
  Cancel:"Absagen",
  Confirm:"Bestätigen",
  Method:"Methode",
  Biometric_Security:"Biometrische Sicherheit",
  Please_Login_To_Continue:"Bitte einloggen zum Fortfahren.",
  Complete:"Vollständig",
  Sign_Up:"Anmeldung",
  Error:"Fehler",
  Biometrics:"Biometrie",
  Percent:"Prozent",
  Zero_Percent:"0%",
  Top_Margin:"Oberer Rand",
  Bottom_Margin:"Unterer Rand",
  Left_Margin:"Linker Rand",
  Right_Margin:"Rechter Rand",
  MICR_Margin:"MICR-Marge",
  Table_Margin:"Tabellenrand",
  Address_Margin:"Adressrand",
  Percentage_:"Prozentsatz",
  Vacation_Title:"Urlaubstitel",
  Use_PIN:"PIN verwenden",
  Loading__:"Wird geladen...",
  Design_Title:"Designtitel",
  Authorize:"Autorisieren",
  Key:"Taste",
  Public_Key:"Öffentlicher Schlüssel",
  Private_Key:"Privat Schlüssel",
  Authenticate:"Authentifizieren",
  Last_Payroll:"Letzte Gehaltsabrechnung",
  Last_Calculation:"Letzte Berechnung",
  Authorized:"Autorisiert",
  Geo_Authorized:"Geo-Standort: Autorisiert",
  Not_Authorized:"Nicht berechtigt",
  Authorization_Complete:"Autorisierung abgeschlossen",
  Geolocation_Authorization:"Geo-Standort-Autorisierung",
  Out_of_Bounds:"Außerhalb der Grenzen",
  Cant_Delete_Default_Design:"Ein Standarddesign kann nicht gelöscht werden.",
  Unauthorized_Out_of_Bounds:"Unbefugt: Out of Bounds",
  Biometric_Authorization:"Biometrische Autorisierung",
  Locating_Please_Wait:"Lokalisierung, bitte warten...",
  Test_Biometrics:"Testen Sie Biometrie",
  Cheque_Margins:"Überprüfen Sie die Ränder",
  Percentage_Full_Error:"Das Prozentfeld kann nicht über 100 % Prozent eingestellt werden.",
  No_Payroll_Text:"Fügen Sie einen <a routerLink='/folder/Employee/New'>Mitarbeiter</a> oder <a routerLink='/folder/Payee/New'>Zahlungsempfänger</a> und einen <a routerLink='/folder/Schedule/New'>Zeitplan</a>.",
  Design_Saved:"Entwurf gespeichert",
  Default_Design_Selected:"Standarddesign ausgewählt",
  Partial_Import:"Teilimport",
  Partial_Export:"Teilexport",
  Entire_Import:"Gesamtimport",
  Entire_Export:"Gesamtexport",
  Existing_Password:"Bitte geben Sie Ihr bestehendes Passwort ein:",
  Existing_PIN:"Bitte geben Sie Ihren bestehenden PIN-Code ein:",
  Pin_Change_Header:"PIN-Bestätigung",
  Pin_Change_SubHeader:"Geben Sie Ihre alte PIN-Nummer ein, um die Änderung zu bestätigen.",
  Pass_Change_Header:"Passwort Bestätigung",
  Pass_Change_SubHeader:"Geben Sie Ihr altes Passwort ein, um die Änderung zu bestätigen.",
  PIN_Enter_Message:"Geben Sie zur Bestätigung Ihre PIN ein.",
  Password_Enter_Message:"Geben Sie zur Bestätigung Ihr Passwort ein.",
  Pin_Updated_Success:"PIN erfolgreich aktualisiert!",
  Pin_Updated_Fail:"PIN konnte nicht aktualisiert werden.",
  Pass_Updated_Success:"Passwort erfolgreich aktualisiert.",
  Pass_Updated_Fail:"Passwort konnte nicht aktualisiert werden.",
  Preview_Payment:"Zahlungsvorschau",
  Preview_Payroll:"Vorschau Gehaltsabrechnung",
  No_Payments_Created:"Es wurden keine Zahlungen zum Erstellen gefunden.",
  Payment_Preview:"Zahlungsvorschau",
  Enable_Payee:"Zahlungsempfänger aktivieren",
  Rendered:"Gerendert",
  No_Email:"Keine Email",
  Setup_Cheques:"Setup-Prüfungen",
  name:"Name",
  address:"Adresse",
  address_2:"Adresszeile 2",
  city:"Stadt",
  province:"Provinz",
  postal_code:"Postleitzahl",
  country:"Land",
  username:"Nutzername",
  full_name:"Vollständiger Name",
  birthday:"Geburtstag",
  email:"Email",
  phone:"Telefon",
  employees:"Angestellte",
  addresses:"Adressen",
  payment_amount_limit:"Zahlungsbetragslimit",
  total_limit:"Gesamtlimit",
  payees:"Zahlungsempfänger",
  description:"Beschreibung",
  address_line_one:"Adresszeile eins",
  address_line_two:"Adresszeile zwei",
  image:"Bild",
  account_holder:"Kontoinhaber",
  cheque_starting_id:"Überprüfen Sie die Start-ID",
  transit_number:"Transitnummer",
  institution_number:"Institutionsnummer",
  designation_number:"Bezeichnungsnummer",
  account_number:"Kontonummer",
  currency:"Währung",
  signature:"Unterschrift",
  payment_payroll_limit:"Zahlungslimit",
  total_limi:"Gesamtlimit",
  date:"Datum",
  printed_memo:"Gedruckte Notiz",
  banks:"Banken",
  signature_image:"Signaturbild",
  address_name:"Adressname",
  notes:"Anmerkungen",
  design:"Entwurf",
  title:"Titel",
  frequency:"Frequenz",
  fax:"Fax",
  salaries:"Gehälter",
  salary_ids:"Gehaltsausweise",
  start_time:"Startzeit",
  end_time:"Endzeit",
  paid:"Bezahlt",
  overtime_percentage:"Bezahlter Prozentsatz",
  overtime_amount:"Bezahlter Festbetrag",
  first_name:"Vorname",
  last_name:"Nachname",
  moderation:"Mäßigung",
  create:"Schaffen",
  edit:"Bearbeiten",
  destroy:"Zerstören",
  day_start_time:"Day_start_time",
  day_end_time:"Tag_Ende_Zeit",
  fullname:"Name",
  time:"Zeit",
  auto_send:"Automatisch senden",
  time_method:"Zeitmethode",
  schedules:"Zeitpläne",
  indefinite_payroll_enabled:"Auf unbestimmte Zeit aktivieren",
  amount:"Menge",
  repeat:"Wiederholen",
  always_enabled:"Immer aktiviert",
  start_date:"Anfangsdatum",
  end_date:"Endtermin",
  Cheque_Total:"Überprüfen Sie Gesamt",
  Total_Amount:"Gesamtsumme:",
  Amounts:"Beträge:",
  Images:"Bilder",
  Files:"Dateien",
  Previewing:"Vorschau:",
  Insert:"Einfügung",
  Preview_Import:"Vorschau-Import",
  Entry:"Eintrag",
  Entries:"Einträge",
  No_Entries:"Keine Einträge",
  Import_Type:"Importtyp",
  Select_Details:"Wählen Sie Einzelheiten aus",
  Select_Payee:"Wählen Sie Zahlungsempfänger aus",
  Enable_Holidays:"Feiertage aktivieren",
  Disable_Holidays:"Feiertage deaktivieren",
  Wire_Transfer:"Banküberweisung",
  New_Export:"Neuer Export",
  Export_Data:"Daten exportieren",
  Export_Data_Word:"Wählen Sie den zu exportierenden und herunterzuladenden Dateityp aus.",
  Export_File:"Exportdatei",
  Mode:"Modus",
  Times:"Mal",
  Widgets:"Widgets",
  Slider:"Schieberegler",
  Set_Details:"Details festlegen",
  Select_Type:"Art auswählen",
  Display_Slider:"Schieberegler anzeigen",
  Dashboard_Slider:"Dashboard-Schieberegler",
  Dashboard_Mode:"Dashboard-Modus",
  Show_Intro:"Einführung anzeigen",
  Show_Payrolls:"Gehaltsabrechnungen anzeigen",
  Show_Holidays:"Feiertage anzeigen",
  Show_Invoices:"Rechnungen anzeigen",
  Show_Cheques:"Schecks anzeigen",
  Enabled_Widgets:"Aktivierte Widgets",
  Disabled_Widgets:"Deaktivierte Widgets",
  Colors:"Farben",
  Barcodes:"Barcodes",
  View_Timers:"Timer anzeigen",
  Gradients:"Farbverläufe",
  No_Info:"Keine Informationen",
  Disable:"Deaktivieren",
  Show_Layer:"Ebenen anzeigen",
  Hide_Layer:"Ebenen ausblenden",
  Text_Layer:"Textebenen",
  Secondly:"Zweitens",
  Minutely:"Minutiös",
  nine_am:"9:00 MORGENS",
  five_pm:"5.00",
  Enable_Address:"Adresse aktivieren",
  Invalid_File_Type:"Entschuldigung, es wurde ein ungültiger Dateityp ausgewählt. Unterstützter Dateityp:",
  Error_Updating_Entry:"Entschuldigung, beim Aktualisieren dieses Eintrags ist ein Fehler aufgetreten.",
  Schedule_Timing_Alert:"Fehler: Die Startzeit des Zeitplans ist auf einen Wert nach der Endzeit festgelegt.",
  Select_Multiple_Payments_Prompt:"Keine Zahlungen ausgewählt. Wählen Sie mindestens eine Zahlung aus.",
  Select_Multiple_Cheques_Prompt:"Keine Prüfungen ausgewählt. Bitte wählen Sie mindestens einen Scheck aus.",
  Select_Multiple_Items_Prompt:"Keine Elemente ausgewählt. Bitte wählen Sie mindestens einen Artikel aus.",
  Paymemt_Multiple_Delete_Confirmation:"Möchten Sie wirklich mehrere Zahlungen löschen?",
  Cheque_Multiple_Delete_Confirmation:"Möchten Sie wirklich mehrere Prüfungen löschen?",
  Payee_Multiple_Delete_Confirmation:"Möchten Sie wirklich mehrere Zahlungsempfänger löschen?",
  Employee_Multiple_Delete_Confirmation:"Möchten Sie wirklich mehrere Mitarbeiter löschen?",
  MICR_Warning:"Hinweis: Einige Drucker und Geräte zeigen die MICR-Schriftart möglicherweise nicht richtig an.",
  Automatically_Send:"Automatisch senden",
  Type:"Typ",
  Payment_Type:"Zahlungsart",
  Auto_Send:"Automatisch senden",
  Automatically_Process:"Automatisch verarbeiten",
  Auto_Process:"Automatischer Prozess",
  Image_Based:"Bildbasiert",
  Font_Based:"Schriftbasiert",
  Rerender:"Neu rendern",
  Rendering:"Rendern",
  Render:"Dateien",
  Top:"oben",
  Middle:"Mitte",
  Bottom:"Unterseite",
  Top_Left:"Oben links",
  Top_Center:"Oben in der Mitte",
  Top_Right:"Oben rechts",
  Middle_Left:"Mitte links",
  Middle_Center:"Mittleres Zentrum",
  Middle_Right:"Mitte rechts",
  Bottom_Left:"Unten links",
  Bottom_Center:"Unten in der Mitte",
  Bottom_Right:"Unten rechts",
  Numbers:"Zahlen",
  Verified:"Verifiziert",
  Paper_Size:"Papier größe",
  New_Device:"Neues Gerät",
  Add_Device:"Gerät hinzufügen",
  Remove_Device:"Gerät entfernen",
  Delete_Device:"Gerät löschen",
  Block_Device:"Gerät blockieren",
  Block:"Block",
  Unblock:"Entsperren",
  Table:"Tisch",
  Scan_Login_Code:"Login-Code scannen",
  Login_Code:"Login-Code",
  Scan_Code:"Code scannen",
  Scan_QR_Code:"QR-Code scannen",
  Select_All:"Wählen Sie Alle",
  Deselect_All:"Alle abwählen",
  Increase:"Zunahme",
  Decrease:"Verringerung",
  Bold:"Fett gedruckt",
  Text:"Text",
  Style:"Stil",
  Italic:"Kursiv",
  QR_Code:"QR-Code",
  Barcode:"Strichcode",
  Browse_Images:"Bilder durchsuchen",
  Browse_Files:"Dateien durchsuchen",
  Background_Image:"Hintergrundbild",
  Logo_Image:"Logo-Bild",
  Header_Image:"Header-Bild",
  Bank_Image:"Bankbild",
  Cut_Lines:"Schnittlinien",
  Background:"Hintergrund",
  License:"Lizenz",
  One_License:"1 Lizenz:",
  Licensed:"Lizenziert für:",
  Not_Licensed:"Nicht lizenziert",
  Enter_Serial:"Geben Sie Seriennummer ein",
  Serial_Key:"Seriennummer",
  Serial:"Seriell",
  Product_Key:"Produktschlüssel",
  Check_Product_Key:"Überprüfen Sie den Produktschlüssel",
  Add_Product_Key:"Produktschlüssel hinzufügen",
  Verifying_Purchase:"Kauf bestätigen...",
  Print_Envelope:"Umschlag drucken",
  Envelope:"Umschlag",
  Thank_You:"Danke!",
  Scale:"Skala",
  Print_Scale:"Skala drucken",
  Borders:"Grenzen",
  VOID:"LEERE",
  Void_Cheque:"Leere Check",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ZAHLUNG AUF BESTELLUNG VON:",
  NO_DOLLARS:"KEINE DOLLAR ",
  ONE_DOLLAR:"EIN DOLLAR",
  DOLLARS:" DOLLAR",
  AND:" UND ",
  CENTS:" CENT.",
  NO_:"NEIN ",
  ONE_:"EINES ",
  AND_NO_:"UND NEIN ",
  _AND_ONE_:"UND EIN ",
  DOLLARS_AND_ONE_CENT:" UND EIN CENT.",
  AND_NO_CENTS:" UND NULL CENT.",
  CHEQUE_PRINT_DATE:"DATUM:",
  CHEQUE_PRINT_MEMO:"MEMO:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Erste Aufgaben",
  Inital_Setup:"Erste Einrichtung",
  Welcome_To:"Willkommen zu ",
  Welcome:"Herzlich willkommen",
  Swipe:"Wischen",
  Intro_Setup:"Intro-Setup",
  Introduction:"Einführung",
  CHEQUE_PRINT_CREDIT:"Powered by Schecks",
  Title_Intro_Word:"Willkommen bei Schecks",
  Title_Intro:"Einführung von Schecks",
  Title_Setup:"Schecks einrichten",
  PayPal_Default_Email_Message:"Sie haben eine neue PayPal-Überweisung erhalten. [Powered by Cheques]",
  Cheques_App_Export:"Durch Schecks exportiert",
  Post_Intro_Thanks:"Vielen Dank, dass Sie sich für Schecks entschieden haben. Der Einrichtungsvorgang ist jetzt abgeschlossen.",
  Post_Intro_Word:"Beginnen Sie, indem Sie Ihren ersten Scheck ausdrucken, eine zukünftige Zahlung hinzufügen oder einen Mitarbeiter zur Gehaltsabrechnung hinzufügen.",
  Upgrade_Required:"Checks erfordert, dass Sie eine Premium-Version der Software besitzen, um diese Nachricht auszublenden und zusätzliche Funktionen freizuschalten.",
  Upgrade_Title:"Schecks",
  Mailout_Prompt:"Sie können sich zusätzlich dafür entscheiden, dass Checks Ihre Gehaltsschecks per Post versendet.",
  Mailed_Cheque:"Versendeter Scheck: ",
  Mailed_Cheque_Color:"Versendeter Scheck (Farbe): ",
  Terms_Purchase:"Alle elektronischen Käufe mit Schecks sind bis zu 30 Tage ab Kaufdatum vollständig erstattungsfähig. Bitte lesen und akzeptieren Sie die <a href='#'>Allgemeinen Geschäftsbedingungen</a>, bevor Sie fortfahren.",
  Dashboard_Setup:"Primären Drucker einrichten",
  Dashboard_Add:"Primäres Bankkonto hinzufügen",
  Dashboard_Customize:"Wählen Sie eine Prüfvorlage aus",
  Dashboard_Job_Salary:"Erstellen Sie Ihren Job und fügen Sie Ihr Gehalt hinzu",
  Dashboard_Employees:"Mitarbeiter und Zahlungsempfänger verfolgen",
  Dashboard_Print:"Drucken und versenden Sie Ihre Gehaltsschecks",
  Dashboard_Payroll:"Automatisieren Sie Ihren Gehaltsabrechnungsdruck",
  Dashboard_PayPal:"PayPal Gehaltsabrechnung / Auszahlungen einrichten",
  Begin_Setup:"Einrichtung starten",
  Get_Started:"Loslegen",
  Purchase:"Kaufen",
  Lockdown:"Sperrung",
  Unlock:"Freischalten",
  Detailed:"Detailliert",
  Log_In:"Einloggen",
  Login:"Anmeldung",
  Launch:"Starten",
  Register:"Registrieren",
  Finish:"Beenden",
  List:"Aufführen",
  Weekends:"Wochenenden",
  Weekly:"Wöchentlich",
  PayPal_Default_Subject:"Neue Zahlung",
  Payment_Message:"Zahlungsnachricht",
  PayPal_Default_Payment_Note:"Danke schön",
  Setup_Your_Cheqing_Account:"Scheckkonto",
  Add_Your_Primary_Cheqing_Account:"Fügen Sie Ihr primäres Girokonto hinzu.",
  Welcome_Word:"Vereinfachen und automatisieren Sie die Lohn- und Personalverwaltung.",
  Get_Started_Quickly:"Beantworten Sie einfach ein paar einfache Fragen, die uns den Einstieg erleichtern...",
  Done_Intro_Word:"Einrichtung abgeschlossen",
  PIN_Protected:"Passwort- und PIN-geschützt",
  Enter_New_PIN:"Geben Sie einen neuen PIN-Code ein:",
  Enter_PIN:"PIN-Code eingeben:",
  Invalid_PIN:"Ungültige PIN eingegeben.",
  Account_Identifier:"Kontokennung",
  New_Account_Identifier:"Neue Kontokennung",
  attempt:"Versuch",
  attempts:"Versuche",
  remaining:"verbleibend",
  Language:"Sprache",
  languages:"Sprachen",
  select_languages:"Sprache auswählen",
  Deposit:"Anzahlung",
  Hire_One_Now:"Jetzt mieten",
  App_Locked:"Die Anwendung ist gesperrt.",
  Skip_:"Überspringen",
  Skip_to_Dashboard:"Zum Dashboard springen",
  Dashboard:"Armaturenbrett",
  Import:"Importieren",
  Admin:"Administratoren",
  New_Admin:"Neuer Administrator",
  Settings:"Einstellungen",
  Color_Picker:"Farbwähler",
  Font_Picker:"Schriftauswahl",
  Logout:"Ausloggen",
  Close:"Nah dran",
  Close_menu:"Nah dran",
  Excel:"Excel-Datei",
  Csv:"CSV-Datei",
  Sql:"SQL-Datei",
  Json:"JSON-Datei",
  Url:"Über URL importieren",
  Back:"Zurück",
  Timers:"Timer",
  Cheque:"Überprüfen",
  Print:"Drucken",
  Designs:"Entwürfe",
  Pause_Printing:"Drucken anhalten",
  Resume_Printing:"Drucken fortsetzen",
  Printing_Paused:"Drucken angehalten",
  PrintingUnavailable:"Entschuldigung! Drucken ist auf diesem System nicht verfügbar.",
  Prints_Paused:"Drucke angehalten",
  Administration:"Verwaltung",
  Loading:"Wird geladen",
  Unnamed:"Unbenannt",
  error:"Entschuldigung, dieser Scheck konnte nicht zur Ansicht geöffnet werden.",
  No_Cheques_To_Print:"Keine Schecks zum Drucken",
  No_Cheques_To_Print_Word:"Erstellen Sie einen <a routerLink='/folder/Cheque/New'>Neuen Scheck</a>.",
  New_Cheque:"Neuer Scheck",
  Start_One_Now:"Jetzt starten",
  Edit_Cheque:"Prüfung bearbeiten",
  Select_Cheques:"Prüfungen auswählen",
  Select_Employee:"Mitarbeiter auswählen",
  Default_Printer:"Standard Drucker",
  Reassign:"Neu zuweisen",
  Configure:"Konfigurieren",
  Template:"Schablone",
  Designer:"Designer",
  Edit_Designs:"Entwürfe bearbeiten",
  New_Design:"Neues Design",
  Next:"Nächste",
  Save:"Speichern",
  Name:"Name",
  Mail:"Mail",
  Amount:"Höhe",
  Date:"Datum",
  PayPal:"PayPal",
  Payouts:"Auszahlungen",
  PayPal_Label:"PayPal-Label",
  Email_Username:"E-Mail / Benutzername",
  Client_ID:"Kunden ID",
  Sandbox_Email:"Sandbox-E-Mail",
  PayPal_Email:"Paypal Email",
  PayPal_Username:"API-Benutzername",
  PayPal_Payouts:"PayPal-Auszahlungen",
  Select_PayPal_Key:"PayPal-Schlüssel auswählen",
  Secret:"Geheimnis",
  API_Secret:"API-Geheimnis",
  PayPal_API_Keys:"PayPal-Schlüssel",
  New_PayPal_Key:"Neuer PayPal-Schlüssel",
  Email_Subject:"E-Mail Betreff",
  Email_Message:"E-Mail Nachricht",
  Payout_Options:"Auszahlungsoptionen",
  Payment_Note:"Zahlungshinweis",
  Enable_Employee:"Mitarbeiter aktivieren",
  Enable_Production_Mode:"Produktionsmodus aktivieren",
  Sandbox_Username:"Sandbox-Benutzername",
  Sandbox_Signature:"Sandbox-Signatur",
  Sandbox_Password:"Sandbox-Passwort",
  Production_Username:"Produktionsbenutzername",
  Production_Signature:"Produktionssignatur",
  Production_Password:"Produktionskennwort",
  Production_Email:"Produktions-E-Mail",
  API_Client_ID:"API-Client-ID",
  API_Signature:"API-Signatur",
  API_Password:"API-Passwort",
  API_Username:"API-Benutzername",
  Secret_Key:"Geheimer Schlüssel",
  Sandbox:"Sandkasten",
  Production:"Produktion",
  Sandbox_Keys:"Sandbox-Schlüssel",
  Production_Keys:"Produktionsschlüssel",
  API_Title:"API-Titel",
  Production_Mode:"Produktionsmodus",
  Account_Label:"Kontobezeichnung",
  No_PayPal_Setup:"Kein PayPal-Schlüssel",
  Enable_PayPal_Account:"PayPal-Konto aktivieren",
  No_PayPal_Word:"Fügen Sie Ihren <a routerLink='/folder/Invoice/New'>PayPal-API-Schlüssel</a> hinzu.",
  Printed_Memo:"Gedruckte Notiz",
  Employee:"Mitarbeiter",
  View_Employee:"Mitarbeiter anzeigen",
  Mailing_Address:"Postanschrift",
  Company_Address:"Firmenanschrift",
  Select_Company_Address:"Firmenadresse auswählen",
  Address:"Adresse",
  Any_Day:"An jedem Tag",
  Address_Name:"Adressname",
  Unit:"Einheit",
  Account:"Konto",
  Bank_Account:"Bankkonto",
  Account_Limits:"Kontolimits aktivieren",
  Payroll:"Lohn-und Gehaltsabrechnung",
  New_Payroll:"Neue Gehaltsabrechnung",
  No_Payroll:"Keine bevorstehende Gehaltsabrechnung",
  Upcoming_Holiday:"Bevorstehender Feiertag:",
  Invoice_Due:"Rechnung fällig:",
  New_Invoice:"Neue Rechnung",
  No_Invoices:"Keine Rechnungen",
  No_Invoices_Word:"Erstellen Sie die erste <a routerLink='/folder/Invoice/New'>Rechnung</a>.",
  Cheque_Due:"Scheck fällig:",
  Payrolls:"Lohnabrechnungen",
  Sandbox_Mode:"Sandbox-Modus",
  Hire:"Mieten",
  Pay:"Zahlen",
  New:"Neu",
  Add:"Hinzufügen",
  Make:"Machen",
  Time:"Zeit",
  Write:"Schreiben",
  Holiday:"Urlaub",
  Holidays:"Ferien",
  Next_Holiday:"Nächsten Urlaub:",
  All_Done:"Alles erledigt!",
  Employees:"Angestellte",
  Payees:"Zahlungsempfänger",
  Job:"Arbeit",
  Jobs:"Arbeitsplätze",
  Invoice:"Rechnung",
  Invoices:"Rechnungen",
  Vacations:"Urlaube",
  Cheques:"Schecks",
  Brand_Cheques:"Marke",
  Payment:"Zahlung",
  Enable_Payment:"Zahlung aktivieren",
  Payments:"Zahlungen",
  Schedule:"Zeitplan",
  Schedules:"Zeitpläne",
  Timesheet:"Arbeitsblatt",
  Timesheets:"Stundenzettel",
  Salary:"Gehalt",
  New_Address:"Neue Adresse",
  Address_2:"Adresszeile 2)",
  _City:"Stadt",
  _State:"Staat/Prov",
  City:"Stadt / Gemeinde",
  State:"Staat / Provinz",
  Postal:"Postleitzahl",
  ZIP:"Post / PLZ",
  Country:"Land",
  Addresses:"Adressen",
  Required_Options:"Erforderliche Optionen",
  Optional_Options:"Optionale Optionen",
  Additional_Options:"Zusätzliche Optionen",
  Required:"Erforderlich",
  Optional:"Optional",
  Additional:"Zusätzlich",
  No_Addresses:"Keine Adressen",
  New_Address_Word:"Fügen Sie die erste <a routerLink='/folder/Address/New'>Adresse</a> hinzu.",
  Select_Address:"Adresse auswählen",
  No_Address:"Keine Adressen",
  Print_Cheque:"Scheck drucken",
  Print_Cheque_Now:"Check jetzt drucken",
  Description:"Beschreibung",
  Pay_To_The_Order_Of:"Zahlung an den Auftrag von:",
  Select_Date_Range:"Datumsbereich auswählen",
  Select_Starting_Date:"Startdatum auswählen",
  Select_Ending_Date:"Enddatum auswählen",
  Select_Date:"Datum auswählen",
  Cheque_Date:"Prüfdatum",
  Cheque_Memo:"Memo prüfen",
  Blank_Cheque:"Blankoscheck",
  Blank:"Leer",
  No_Cheques:"Keine Schecks",
  No_Cheques_Word:"Drucken Sie Ihren ersten <a routerLink='/folder/Cheque/New'>Scheck</a>.",
  Cheque_Amount_Placeholder:"$0,00",
  View_Image:"Bild ansehen",
  View:"Aussicht",
  Modify:"Ändern",
  Delete:"Löschen",
  Cheque_Paid:"Bezahlt",
  New_Deduction:"Neuer Abzug",
  Title:"Titel",
  Frequency:"Frequenz",
  Hourly:"Stündlich",
  Daily:"Täglich",
  Weekdays:"Werktags",
  BiWeekly:"2 Wochen",
  TriWeekly:"3 Wochen",
  Monthly:"Monatlich",
  MiMonthly:"2 Monate",
  Quarterly:"Vierteljährlich",
  Yearly:"Jährlich",
  Every_Week:"Jede Woche",
  Every_Payroll:"Jede Gehaltsabrechnung",
  Every_Month:"Jeden Monat",
  Annually:"Jährlich",
  Always_Scheduled:"Immer geplant",
  Start_Date:"Startdatum",
  End_Date:"Endtermin",
  Start_Time:"Startzeit",
  End_Time:"Endzeit",
  Deduction_Label:"Abzugsetikett",
  Notes:"Anmerkungen",
  Options:"Optionen",
  Enable:"Ermöglichen",
  Select_Deductions:"Abzüge auswählen",
  Deductions:"Abzüge",
  No_Deductions:"Keine Abzüge",
  No_Deductions_Word:"Erstellen Sie Ihren ersten <a routerLink='/folder/Deduction/New'>Abzug</a>.",
  New_Employee:"Neuer Angestellter",
  No_Title:"Kein Titel",
  _Name:"Name",
  About_Yourself:"Über dich",
  Full_Name:"Vollständiger Name",
  Birthday:"Geburtstag",
  Email:"Email",
  SMS:"SMS",
  Phone:"Telefon",
  Test:"Prüfen",
  Call:"Anruf",
  Fax:"Fax",
  Printed_Note:"Gedruckte Notiz",
  Position:"Position",
  Job_Position:"Berufliche Stellung",
  Select_a_Job:"Wählen Sie einen Job",
  Select_a_Salary:"Gehalt auswählen",
  Add_a_Deduction:"Einen Abzug hinzufügen",
  Taxes:"Steuern",
  Add_Taxes:"Steuern hinzufügen",
  Date_of_Birth:"Geburtsdatum",
  Email_Address:"E-Mail-Addresse",
  Phone_Number:"Telefonnummer",
  Phone_Call:"Anruf",
  Enable_on_Payroll:"Auf Gehaltsabrechnung aktivieren",
  Select_Employees:"Mitarbeiter auswählen",
  No_Employees:"Keine Mitarbeiter",
  No_Employees_Word:"Fügen Sie Ihren ersten neuen <a routerLink='/folder/Employee/New'>Mitarbeiter</a> hinzu.",
  No_Name:"Kein Name",
  Unemployeed:"Arbeitslos",
  Employeed:"Beschäftigt",
  Paid:"Bezahlt",
  Enabled:"Ermöglicht",
  Disabled:"Behindert",
  Fire:"Feuer",
  Not_Available:"Nicht verfügbar",
  Not_Available_Word:"Drucken Sie Ihre erste <a routerLink='/folder/Invoice/New'>Rechnung</a> und werden Sie bezahlt.",
  Select_Invoices:"Rechnungen auswählen",
  Print_Invoice_Word:"Drucken Sie Ihre erste <a routerLink='/folder/Invoice/New'>Rechnung</a> und werden Sie bezahlt.",
  Invoice_Title:"Rechnungstitel",
  Invoice_Date:"Rechnungsdatum",
  Due_Date:"Geburtstermin",
  New_Job:"Neue Arbeit",
  Details:"Einzelheiten",
  Customize:"Anpassen",
  Customize_Dashboard:"Dashboard anpassen",
  Components:"Komponenten",
  No_Components:"Keine Komponenten",
  Main_Components:"Hauptbestandteile",
  Smaller_Components:"Kleinere Bauteile",
  Error_Loading_Page:"Fehler beim Laden dieser Seite.",
  Bank_Details:"Bankdaten",
  About_Your_Job:"Über Ihren Job",
  Your_Schedule:"Dein Zeitplan",
  Job_Title:"Berufsbezeichnung",
  Job_Description:"Jobbeschreibung",
  Job_Details:"Auftragsdetails",
  Enable_Job:"Job aktivieren",
  Pay_Period:"Zahlungszeitraum",
  Perpetually_Schedule:"Immer planen",
  Select_Jobs:"Jobs auswählen",
  No_Jobs:"Keine Jobs",
  Add_Jobs:"Jobs hinzufügen",
  No_Jobs_Word:"Fügen Sie den ersten <a routerLink='/folder/Job/New'>Job</a> zur Liste hinzu.",
  Count_Employees:"job.employee_count+'Mitarbeiter'",
  Zero_Employees:"0 Mitarbeiter",
  New_Leave:"Neuer Urlaub",
  Leave_Name:"Name hinterlassen",
  Paid_Leave:"Bezahlter Urlaub",
  Leave_Pay:"Bezahlen lassen",
  Indefinite_Leave:"Unbestimmte verlassen",
  Indefinite_Payroll:"Unbefristete Gehaltsabrechnung",
  Leave:"Verlassen",
  Add_Schedules:"Zeitpläne hinzufügen",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Abwesenheit aktivieren",
  Select_Leaves:"Blätter auswählen",
  No_Leaves:"Keine Abwesenheit",
  Leave_Of_Absence:"Urlaub",
  Leaves_Of_Absence:"Beurlaubungen",
  New_Leave_of_Absense:"Neue Beurlaubung",
  No_Leaves_Word:"Fügen Sie die erste <a routerLink='/folder/Leave/New'>Beurlaubung</a> hinzu.",
  Not_Enabled:"Nicht aktiviert",
  Absences:"Abwesenheiten",
  Payee:"Zahlungsempfänger",
  New_Payee:"Neuer Zahlungsempfänger",
  Payee_Identifier:"Zahlungsempfänger-ID",
  Payee_Name:"Name des Zahlungsempfängers",
  Payee_Title:"Titel des Zahlungsempfängers",
  Payment_Memo:"Zahlungsmemo",
  Select_Payees:"Zahlungsempfänger auswählen",
  No_Payees:"Keine Zahlungsempfänger",
  Add_Payee_Note:"Fügen Sie den ersten <a routerLink='/folder/Payee/New'>Zahlungsempfänger</a> hinzu.",
  New_Payees:"Neue Zahlungsempfänger",
  About_The_Payment_Schedule:"Zahlungsplan",
  Your_Payroll_Schedule:"Automatische Gehaltsabrechnung",
  New_Payment:"Neue Zahlung",
  Identifier:"Kennung",
  Select:"Wählen",
  Memo:"Memo",
  Payment_Date:"Zahlungsdatum",
  Mark_as_Paid:"Als bezahlt markieren",
  Select_Payments:"Zahlungen auswählen",
  No_Payments:"Keine Zahlungen",
  No_Payments_Word:"Erstellen Sie die erste <a routerLink='/folder/Payment/New'>Zahlung</a>.",
  Create_Payroll:"Lohnabrechnung erstellen",
  Properties:"Eigenschaften",
  Payroll_Title:"Titel der Gehaltsabrechnung",
  Payroll_Notes:"Lohnzettel",
  Payroll_Setup:"Lohnabrechnung einrichten",
  Tabulate_Payments:"Zahlungen tabellarisch darstellen",
  Tabulate:"Tabellieren",
  By:"Durch:",
  Payments_By:"Zahlungen per",
  Timesheets_And_Schedules:"Stundenzettel & Stundenpläne",
  Both:"Beide",
  Items:"Produkte",
  Add_Payees:"Zahlungsempfänger hinzufügen",
  Add_Account:"Konto hinzufügen",
  Enable_Account:"Konto aktivieren",
  Enable_Payroll:"Abrechnung aktivieren",
  Print_Payroll:"Lohnabrechnung drucken",
  No_Payrolls:"Keine Gehaltsabrechnung",
  No_Payroll_Word:"Erstellen Sie Ihre erste <a routerLink='/folder/Payroll/New'>Gehaltsabrechnung</a>.",
  View_more:"MEHR SEHEN",
  Less:"WENIGER",
  Add_Payroll:"Lohnabrechnung hinzufügen",
  Select_Payroll:"Lohnabrechnung auswählen",
  About_Your_Salary:"Über Ihr Gehalt",
  Add_Salaries:"Gehälter hinzufügen",
  Add_Salary:"Gehalt hinzufügen",
  Salaries:"Gehälter",
  No_Salaries:"Keine Gehälter",
  No_Salaries_Word:"Fügen Sie das erste <a routerLink='/folder/Salary/New'>Gehalt</a> hinzu.",
  Select_Salaries:"Gehälter auswählen",
  New_Salary:"Neues Gehalt",
  Salary_Name:"Gehaltskennung",
  Enable_Salary:"Gehalt aktivieren",
  Salary_Amount:"Gehaltsbetrag",
  New_Schedule:"Neue Terminplanung",
  Schedule_Title:"Zeitplantitel",
  Add_Address:"Adresse hinzufügen",
  Repeat:"Wiederholen",
  Design:"Entwurf",
  Edit_Design:"Design bearbeiten",
  Edit_this_Design:"Dieses Design bearbeiten",
  Repeat_Payment:"Zahlung wiederholen",
  Enable_Schedule:"Zeitplan aktivieren",
  Never:"Noch nie",
  Select_Schedule:"Zeitpläne auswählen",
  No_Schedules:"Keine Zeitpläne",
  No_Schedules_Word:"Erstellen Sie den ersten <a routerLink='/folder/Schedule/New'>Zeitplan</a>.",
  New_Administrator:"Neuer Verwalter",
  Username:"Nutzername",
  First_Name:"Vorname",
  Last_Name:"Nachname",
  Add_an_Address:"Füge eine Adresse hinzu",
  Payment_Limit:"Limit pro Zahlung",
  Total_Limit:"Gesamtlimit",
  Select_Accounts:"Konten auswählen",
  No_Administrators:"Keine Administratoren",
  No_Administrators_Word:"Erstellen Sie das erste <a routerLink='/folder/Administrator/New'>Admin-Konto</a>.",
  New_Administrators_Word:"Fügen Sie den ersten <a routerLink='/folder/Administrator/New'>Administrator</a> hinzu",
  Cloud:"Wolke",
  Backup:"Sicherung",
  Enable_iCloud:"iCloud aktivieren",
  Enable_Google_Drive:"Google Drive aktivieren",
  Enable_Microsoft_OneDrive:"Microsoft OneDrive aktivieren",
  Automatically_Backup:"Automatisch sichern",
  FTP_Settings:"FTP-Einstellungen",
  URL_File_Prompt:"Bitte geben Sie den Speicherort für eine zu importierende .xls-/.xlsx-/.json-Datei an:",
  URL_SQL_Prompt:"Bitte geben Sie den Speicherort der zu importierenden SQLite-Datei an:",
  FTP_Backup:"FTP-Sicherung",
  FTP_Import:"FTP-Import",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Gastgeber",
  Port:"Hafen",
  Path:"Weg",
  Data_Path:"Datenweg",
  Enable_FTP_Account:"FTP-Konto aktivieren",
  HostnameIP:"Hostname",
  Password:"Passwort",
  Connection_Port:"Verbindungsport",
  Enable_MySQL_Database:"MySQL-Datenbank aktivieren",
  Log:"Protokoll",
  Reset:"Zurücksetzen",
  Erase:"Löschen",
  Export:"Export",
  Database:"Datenbank",
  Upload_CSV:"CSV-Datei hochladen",
  Download_CSV:"CSV-Datei herunterladen",
  SQL_Database:"SQL-URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL-Sicherung",
  Internal_Notes:"Interne Notizen",
  Root_Path:"Wurzelpfad",
  Select_Backup:"Sicherung auswählen",
  Add_New_Backup:"Eine neue Sicherung hinzufügen",
  First_Backup:"Erstes Backup einrichten...",
  Backups:"Sicherung",
  Add_Backup:"Sicherung hinzufügen",
  No_Backups:"Es sind keine Backups zu finden.",
  Select_Backup_Type:"Wählen Sie die Art der Sicherung aus, die Sie einrichten möchten...",
  Backup_Type:"Sicherungstyp",
  FTP_Drive:"FTP-Laufwerk",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Antrieb",
  Microsoft_OneDrive:"Antrieb",
  Import_Fields:"Felder importieren",
  Import_Fields_Word:"Verwenden Sie diesen Abschnitt, um <a routerLink='/folder/Holiday/New'>Daten importieren</a> auszuwählen.",
  Upload:"Hochladen",
  Download:"Herunterladen",
  Download_JSON:"Als JSON-Daten herunterladen",
  Download_SQL:"Als SQL-Datei herunterladen",
  New_Bank:"Neue Bank",
  New_Account:"Neues Konto",
  New_Bank_Account:"Neues Bankkonto",
  Upload_Image:"Bild hochladen",
  Bank_Name:"Bank Name",
  Bank_Address:"Bankadresse",
  Branch_Address:"Anschrift der Filiale",
  Address_on_Cheque:"Adresse",
  Cheque_Numbers:"Nummern prüfen",
  Cheque_Details:"Details prüfen",
  Bank_Logo:"Bank-Logo",
  Cheque_ID:"Ausweis prüfen",
  Starting_Cheque_ID:"Start ID überprüfen",
  Transit_Number:"Transitnummer",
  Institution_Number:"Institutsnummer",
  Designation_Number:"Bezeichnungsnummer",
  Account_Number:"Accountnummer",
  Limits:"Grenzen",
  Default_Limits:"Standardlimits",
  Over_Limit:"Über dem Limit",
  Under_Limit:"Unter Grenze",
  Payroll_Limit:"Abrechnungslimit",
  Enable_Bank_Account:"Bankkonto aktivieren",
  Select_Account:"Konto auswählen",
  No_Bank_Account:"Kein Bankkonto",
  No_Bank_Account_Word:"Fügen Sie das erste <a routerLink='/folder/Accounts/New'>Bankkonto</a> hinzu.",
  Bank_Accounts:"Bankkonten",
  No_Accounts:"Keine Konten",
  No_Accounts_Note:"Fügen Sie das erste <a routerLink='/folder/Accounts/New'>Bankkonto</a> hinzu.",
  Cheque_Designer:"Designer prüfen",
  Cheque_Design:"Design prüfen",
  Select_Design:"Design auswählen",
  Cheque_Designs:"Designs prüfen",
  No_Cheque_Designs:"Keine Check-Designs",
  No_Cheque_Designs_Word:"Erstellen Sie Ihr eigenes <a routerLink='/folder/Settings/Check/Design/New'>Überprüfen Sie das Design</a>.",
  Set_Default:"Als Standard einstellen",
  Default:"Standard",
  Remove:"Entfernen",
  Folder:"Ordner",
  Edit:"Bearbeiten",
  LoadingDots:"Wird geladen...",
  Add_a_New_File:"Eine <a href='#' (click)='add()'>Neue Datei</a> hinzufügen zu",
  this_folder:"dieser Ordner",
  FTP_Backup_Settings:"FTP-Sicherungseinstellungen",
  Secure_File_Transfer:"Sichere Dateiübertragung",
  New_Holiday:"Neuer Feiertag",
  Add_Holiday:"Feiertag hinzufügen",
  Holiday_Name:"Name des Feiertags",
  Additional_Pay:"Zusätzliche Bezahlung",
  Enable_Holiday:"Urlaub aktivieren",
  Select_Holidays:"Feiertage auswählen",
  No_Holidays:"Keine Feiertage",
  No_Holidays_Word:"Fügen Sie den ersten <a routerLink='/folder/Holiday/New'>Gesetzlichen Feiertag</a> hinzu.",
  New_Import:"Neuimport",
  Import_Data:"Daten importieren",
  Import_Data_Word:"Wählen Sie den Dateityp oder die Upload-Methode Ihrer Wahl aus.<br /> Sie können auswählen, welche importierten Felder in einer Datei welchen Parametern in der App entsprechen, nachdem Sie eine unterstützte Datei hochgeladen haben.", 
  Import_File:"Datei importieren",
  Link_To_File:"Link zu Datei",
  Select_File:"Datei aussuchen",
  New_Moderator:"Neuer Moderator",
  Allow_Moderation:"Moderation zulassen",
  Create_Paycheques:"Gehaltsabrechnungen erstellen",
  Edit_Paycheques_and_Data:"Gehaltsabrechnungen und Daten bearbeiten",
  Destroy_Data_and_Paycheques:"Daten und Gehaltsschecks vernichten",
  Bonus_Percentage:"Gehaltsscheckprozentsatz",
  Fixed_Amount:"Fester Betrag",
  Select_Moderator:"Moderator auswählen",
  No_Moderators:"Keine Moderatoren",
  Moderators:"Moderatoren",
  Moderator_Account:"Erstellen Sie das erste <a routerLink='/folder/Administrator/New'>Moderatorkonto</a>.",
  No_Moderators_Word:"Fügen Sie den ersten <a routerLink='/folder/Administrator/New'>Moderator</a> hinzu.",
  Defaults:"Standards",
  Provide_Us_Info:"Geben Sie uns Informationen",
  Setup_Your_Printer:"Drucker einrichten",
  Your_Company:"Über Ihr Unternehmen",
  Company_Name:"Name der Firma",
  Currency:"Währung",
  Default_Currency:"Standardwährung",
  Base_Monthly_Income:"Monatliches Einkommen",
  Protection:"Schutz",
  App_Protection:"App-Schutz",
  PIN_Code_Protection:"PIN-Code-Schutz",
  App_Protection_Word:"Aktivieren Sie Sicherheitsmethoden, die zum Schutz Ihres Kontos beitragen.",
  PIN_Code:"Geheimzahl",
  Change_PIN:"PIN ändern",
  New_Password:"Neues Passwort",
  Geofence_Protection:"Geo-Zaun-Schutz",
  Geofence_Area:"Bereich einstellen",
  Distance:"Distanz",
  Setup_Now:"Jetzt einrichten",
  Mile:"Meile",
  Km:"km",
  m:"m",
  Facial_Recognition:"Gesichtserkennung",
  Face:"Gesicht",
  Setup:"Konfiguration",
  Label:"Etikett",
  Password_Protection:"Passwortschutz",
  Modify_Password:"Passwort verändern",
  New_Tax_Entry:"Neuer Steuereintrag",
  New_Tax:"Neue Steuer",
  Tax_Label:"Steueretikett",
  Perpetually_Enabled:"Immer aktiviert",
  Select_Taxes:"Steuern auswählen",
  Tax_Deductions:"Steuerabzüge",
  No_Tax_Deductions:"Keine Steuerabzüge",
  No_Tax_Deductions_Word:"Fügen Sie den ersten <a routerLink='/folder/Tax/New'>Steuerabzug</a> hinzu.",
  New_Timer:"Neuer Timer",
  Start:"Anfang",
  Stop:"Halt",
  Start_Timer:"Timer starten",
  Stop_Timer:"Stopptimer",
  Timer_Active:"Timer aktiv",
  Timer:"Timer:",
  Timer_Running:"Timer: (Läuft)",
  Save_Timer:"Timer speichern",
  New_Timesheet:"Neues Stundenblatt",
  Select_Timesheets:"Stundenzettel auswählen",
  Work_Notes:"Arbeitsnotizen",
  Entry_Title:"Titel des Eintrags",
  No_Timesheets:"Keine Stundenzettel",
  No_Timesheets_Word:"Fügen Sie das erste <a routerLink='/folder/Timesheet/New'>Arbeitszeitblatt</a> hinzu.",
  Timesheet_Submitted:"Arbeitszeitblatt eingereicht",
  Timesheet_Congrats:"Herzlichen Glückwunsch! Ihr Arbeitszeitblatt wurde erfolgreich übermittelt. Vielen Dank!",
  Timesheet_Copy:"Um eine Kopie Ihrer Dokumente zu erhalten, teilen Sie uns bitte Ihre E-Mail-Adresse und/oder Handynummer mit.",
  Submit:"Einreichen",
  Allow_Notifications:"Benachrichtigungen zulassen",
  Untitled_Entry:"Neuer Eintrag",
  Untitled_Bank:"Bank ohne Titel",
  Timesheet_Entry:"Arbeitszeitblatterfassung",
  Work_Description:"Arbeitsbeschreibung",
  Enable_Timesheet:"Arbeitszeitblatt aktivieren",
  Submit_Timesheet:"Arbeitszeitblatt einreichen",
  Vacation:"Urlaub",
  New_Vacation:"Neuer Urlaub",
  Vacation_Name:"Urlaubsname",
  Paid_Vacation:"Bezahlter Urlaub",
  Vacation_Pay:"Urlaubsgeld",
  Enable_Vacation:"Urlaub aktivieren",
  Select_Vacations:"Urlaub auswählen",
  No_Vacations:"Kein Urlaub",
  No_Vacations_Word:"Erstellen Sie den ersten Eintrag <a routerLink='/folder/Vacation/New'>Urlaub</a>.",
  Payroll_Schedule:"Lohnplan",
  Next_Payroll:"Nächste Gehaltsabrechnung:",
  Upcoming_Payroll:"Anstehende Gehaltsabrechnung",
  No_Upcoming_Payroll:"Keine bevorstehende Gehaltsabrechnung",
  Address_Book:"Adressbuch",
  Archived_Documents:"Archivierte Dokumente",
  Dev_Mode:"Anwendung im Entwicklungsmodus",
  Administrators:"Administratoren",
  Privacy:"Privatsphäre",
  None:"Keiner",
  Select_Signature:"Signatur auswählen",
  No_Signatures:"Keine Unterschriften",
  No_Signatures_Word:"Fügen Sie die erste <a routerLink='/folder/Signature/New'>Signatur</a> hinzu.",
  Repeat_Indefinitely:"Unendlich wiederholen",
  Sign:"Unterschrift",
  Sign_Here:"Hier unterschreiben",
  Date_Signed:"Unterschriftsdatum",
  Signature_Pad:"Unterschriftenpad",
  Account_Holder:"Kontoinhaber",
  Account_Properties:"Kontoeigenschaften",
  Name_On_Cheque:"Name auf Scheck",
  Server_Hostname:"Server-Hostname / IP",
  Printers:"Drucker",
  No_Printers:"Keine Drucker",
  Printer_Exists:'Ein Drucker mit diesem Namen existiert bereits.',
  No_Printers_Word:"Fügen Sie den allerersten <a routerLink='/folder/Printer/New'>Drucker</a> hinzu.",
  No_Printer_Alert:"Es ist kein Drucker definiert. Möchten Sie einen Drucker einrichten?",
  Add_Printer:"Drucker hinzufügen",
  New_Printer:"Neuer Drucker",
  Printer_Hostname:"Drucker-Hostname / IP",
  Printer_Label:"Druckeretikett",
  Printer_Protocol:"Druckerprotokoll",
  Protocol:"Protokoll",
  Email_Print:"Email",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Steckdose",
  Print_Job:"Auftrag drucken",
  Printed:"Gedruckt",
  Not_Printed:"Nicht gedruckt",
  Print_Jobs:"Druckaufträge",
  Print_Queue:"Druckerwarteschlange",
  No_Print_Jobs:"Keine Druckaufträge",
  No_Prints:"Erstellen Sie einen neuen <a routerLink='/folder/Cheque/New'>Scheck</a> zum Drucken.",
  Prints:"Drucke",
  Find_Printer:"Finde einen Drucker",
  Find_AirPrint_Devices:"AirPrint-Geräte finden",
  Select_Printer:"Drucker auswählen",
  System_UI:"System-UI",
  Printer:"Drucker",
  Status:"Status",
  Preview:"Vorschau",
  Enable_Print_Job:"Druckauftrag aktivieren",
  Queue_Weight:"Warteschlangengewicht",
  Unlimited:"Unbegrenzt",
  Show_Advanced_Printer_Options:"Erweiterte Druckeroptionen anzeigen",
  Advanced:"Fortschrittlich",
  Location:"Ort",
  Note:"Notiz",
  Queue_Name:"Warteschlangenname",
  Pages_Printed_Limit:"Limit für gedruckte Seiten",
  MultiPage_Idle_Time:"Mehrseiten-Wartezeit (s)",
  Page_Count_Limit:"Seitenzahllimit",
  Page_Orientation:"Seitenausrichtung",
  Portrait:"Porträt",
  Landscape:"Landschaft",
  Duplex:"Duplex",
  Double_Sided_Printing:"Doppelseitig",
  Duplex_Mode:"Duplex-Modus",
  Duplex_Short:"Kurz",
  Duplex_Long:"Lang",
  Duplex_None:"Keiner",
  Color_And_Quality:"Farbe und Qualität",
  Monochrome:"Einfarbig",
  Photo_Quality_Prints:"Drucke in Fotoqualität",
  Printer_Email:"Drucker-E-Mail",
  Automatically_Downsize:"Automatisch verkleinern",
  Paper:"Papier",
  Paper_Name:"Papiername",
  Paper_Width:"Papierbreite",
  Paper_Height:"Papierhöhe",
  Paper_Autocut_Length:"Autom. Schnittlänge des Papiers",
  Margins:"Ränder",
  Page_Margins:"Seitenränder",
  Page_Margin_Top:"Oberer Seitenrand",
  Page_Margin_Right:"Rechter Seitenrand",
  Page_Margin_Bottom:"Unterer Seitenrand",
  Page_Margin_Left:"Linker Seitenrand",
  Add_Employees:"Mitarbeiter hinzufügen",
  Header:"Header",
  Print_A_Page_Header:"Kopfzeile einer Seite drucken",
  Header_Label:"Header-Label",
  Header_Page_Index:"Kopfseitenindex",
  Header_Font_Name:"Kopfzeilenschrift",
  Select_Font:"Schrift auswählen",
  Font_Selector:"Schriftauswahl",
  Header_Font_Size:"Kopfzeilenschrift",
  Header_Bold:"Kopfzeile fett",
  Header_Italic:"Kopfzeile kursiv",
  Header_Alignment:"Header-Ausrichtung",
  Left:"Links",
  Center:"Center",
  Right:"Richtig",
  Justified:"Gerechtfertigt",
  Header_Font_Color:"Kopfzeilenfarbe",
  Select_Color:"Wähle Farbe",
  Footer:"Fusszeile",
  Print_A_Page_Footer:"Seitenfuß drucken",
  Footer_Label:"Fußzeilenbeschriftung",
  Footer_Page_Index:"Fußseitenindex",
  Footer_Font_Name:"Fußzeilenschrift",
  Fonts:"Schriftarten",
  Done:"Fertig",
  Select_Fonts:"Schriftarten auswählen",
  Footer_Font_Size:"Fußzeilengröße",
  Footer_Bold:"Fußzeile fett",
  Footer_Italic:"Fußzeile kursiv",
  Footer_Alignment:"Fußzeilenausrichtung",
  Footer_Font_Color:"Fußzeilenfarbe",
  Page_Copies:"Seitenkopien",
  Enable_Printer:"Drucker aktivieren",
  Remote_Logging:"Remote-Protokollierung",
  Log_Server:"Protokollserver",
  Encryption:"Verschlüsselung",
  Random_Key:"Zufallsschlüssel",
  Encryption_Key:"Verschlüsselungsschlüssel",
  Cheques_Webserver:"Benutzerdefinierte Datenbank",
  Learn_How:"Lernen wie",
  Signature:"Unterschrift",
  Default_Printer_Unit:"Zoll/cm/mm/(pt)",
  View_Signature:"Signatur anzeigen",
  Printed_Signature:"Gedruckte Unterschrift",
  Digitally_Sign:"Digital signieren",
  Digital_Signature:"Digitale Unterschrift",
  Add_Signature:"Signatur hinzufügen",
  Add_Your_Signature:"Fügen Sie Ihre Unterschrift hinzu",
  Enable_Signature:"Signatur aktivieren",
  Digitally_Signed:"Digital signiert",
  Insert_Error:"Scheck konnte aufgrund von Datenbankproblemen nicht gespeichert werden.",
  Delete_Confirmation:"Sind Sie sicher, dass Sie diese Informationen löschen möchten?",
  Discard_Confirmation:"Möchten Sie diese Informationen wirklich verwerfen?",
  Discard_Bank_Confirmation:"Sind Sie sicher, dass Sie dieses Konto löschen möchten?",
  Discard_Printer_Confirmation:"Sind Sie sicher, dass Sie diesen Drucker verwerfen möchten?",
  Delete_Bonus_Confirmation:"Sind Sie sicher, dass Sie diesen Bonus löschen möchten?",
  Delete_Invoice_Confirmation:"Möchten Sie diese Rechnung wirklich löschen?",
  Generated_Cheque:"Generierter Scheck",
  Generated_Invoice:"Erstellte Rechnung",
  Schedule_Start_Time:"Planungsstart",
  Schedule_End_Time:"Zeitplanende",
  New_Call:"Neuer Anruf",
  No_Contacts:"Keine Kontakte",
  No_Contacts_Word:"Administratoren, Moderatoren, Mitarbeiter und Zahlungsempfänger werden als Kontakte angezeigt.",
  PDF_Subject:"Finanzen",
  PDF_Keywords:"Gehaltsabrechnung Gehaltsscheck PDF Scheck Schecks",
  Printer_Setup:"Druckereinstellungen",
  Printer_Selection:"Druckerauswahl",
  New_Fax:"Neues Fax",
  New_Fax_Message:"Neue Faxnachricht",
  Fax_Machine:"Faxgerät",
  Fax_Name:"Faxname",
  Fax_Email:"Fax-E-Mail",
  Fax_Number:"Faxnummer",
  Contents:"Inhalt",
  Fax_Body:"Seitentext",
  Header_Word:"Header:",
  Header_Text:"Überschrift",
  Include_Header:"Kopfzeile einschließen",
  Include_Footer:"Fußzeile einschließen",
  Footer_Word:"Fusszeile:",
  Footer_Text:"Fusszeile",
  Attach_a_Cheque:"Scheck anhängen",
  Add_Deduction:"Abzug hinzufügen",
  Enable_Fax:"Fax senden",
  Select_Fax:"Fax auswählen",
  No_Faxes:"Keine Faxe",
  Faxes:"Faxe",
  Save_and_Send:"Fax senden",
  Save_and_Pay:"Sparen und bezahlen",
  WARNING:"WARNUNG:",
  Remember:"Merken",
  Printing:"Drucken",
  Printing_Complete:"Druckvorgang abgeschlossen!\n\n",
  of:"von",
  There_Were:"Es gab ",
  Successful_Prints:"erfolgreiche Drucke und",
  Unsuccessful_Prints:" erfolglose Drucke.",
  PrinterError:"Entschuldigung, da war ein Fehler.",
  Printing_:"Drucken...",
  PrinterSuccess:"Dokument erfolgreich gedruckt.",
  PrintersSuccess:"Dokumente erfolgreich gedruckt.",
  Print_Cheques:"Schecks drucken",
  New_Message:"Neue Nachricht",
  New_Messages:"Neue Nachrichten",
  Read_Message:"Lies die Nachricht",
  Write_Message:"Nachricht schreiben",
  Send_Message:"Nachricht senden",
  Subject:"Gegenstand",
  Message:"Nachricht",
  Writing:"Schreiben...",
  Send:"Senden",
  Set_Date:"Datum einstellen",
  Set_Time:"Zeit einstellen",
  Recieve:"Empfangen",
  Set_as_Default:"Als Standard einstellen",
  Default_Account:"Standardkonto",
  Default_Design:"Standarddesign",
  Multiple_Cheques:"Checks (Triple)",
  Account_Mode:"Kontomodus",
  Multiple_Cheques_Description:"Drei Schecks pro Seite.",
  Check_and_Table:"Check & Tabelle",
  Check_including_Table:"Scheck- und Buchungstabelle.",
  Check_Mailer:"Mailer prüfen",
  Check_Mailer_Window:"Überprüfen Sie mit einem Adressfenster.",
  DocuGard_Table_Top:"DocuGard Check & Tabelle (oben)",
  DocuGard_Table_Middle:"DocuGard Check & Tabelle (Mitte)",
  DocuGard_Table_Bottom:"DocuGard Check & Tabelle (unten)",
  DocuGard_Mailer_Top:"DocuGard Check Mailer (oben)",
  DocuGard_Mailer_Middle:"DocuGard Check Mailer (Mitte)",
  DocuGard_Mailer_Bottom:"DocuGard Check Mailer (unten)",
  DocuGard_Three_Cheques:"DocuGard-Checks (Triple)",
  DocuGard_Cheque_Top:"DocuGard Check (oben)",
  DocuGard_Cheque_Middle:"DocuGard Check (Mitte)",
  DocuGard_Cheque_Bottom:"DocuGard Check (unten)",
  DocuGard_Three_Cheques_Window:"Drei Schecks auf einer Seite.",
  DocuGard_Table_Top_Window:"Scheck- und Verdiensttabelle.",
  DocuGard_Table_Middle_Window:"Scheck- und Verdiensttabelle.",
  DocuGard_Table_Bottom_Window:"Scheck- und Verdiensttabelle.",
  DocuGard_Mailer_Top_Window:"Scheck, Tisch und Adresse.",
  DocuGard_Mailer_Middle_Window:"Scheck, Tisch und Adresse.",
  DocuGard_Mailer_Bottom_Window:"Scheck, Tisch und Adresse.",
  DocuGard_Cheque_Top_Window:"Nach sicherem Papier suchen.",
  DocuGard_Cheque_Middle_Window:"Nach sicherem Papier suchen.",
  DocuGard_Cheque_Bottom_Window:"Nach sicherem Papier suchen.",
  Basic_Cheque:"Prüfen (oben)",
  Basic_Cheque_Print:"Einen einzelnen Scheck ausdrucken.",
  Error_Setting_As_Paid:"Fehler beim Festlegen als bezahlt",
  Add_Attachment:"Anhang hinzufügen",
  PrinterUnavailable:"Drucker nicht verfügbar",
  CreditCardComponent:"Karten",
  PaypalComponent:"PayPal",
  InteracComponent:"Interaktion",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Neue Einzahlung",
  Deposits:"Einzahlungen",
  No_Deposits:"Keine Einzahlungen",
  Credit_Card_Deposit:"Kreditkarte",
  New_Credit_Card_Deposit_Message:"Kreditkarteneinzahlung",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin-Einzahlung",
  New_Interac_Deposit:"Interaktion",
  New_Interac_Deposit_Message:"Interac-Übertragung",
  Payments_Limit:"Zahlungslimit",
  No_Payment_Limit:"Kein Zahlungslimit",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal-Einzahlung",
  No_Deposits_Word:"Fügen Sie die erste Firma hinzu <a routerLink='/folder/Deposit/New'>Kaution</a>.",
  No_Documents_Specified:"Es wurden keine Dokumente zum Drucken angegeben",
  No_Printers_Added:"Es wurden keine Drucker gefunden. Gehen Sie zu Einstellungen > Drucker, um einen hinzuzufügen.",
  DB_Erase_Prompt:"Die gesamte Datenbank vollständig löschen? WARNUNG: Sie werden alle gespeicherten Informationen verlieren!",
  Console_Warning:"Fügen Sie keinen Text in diese Konsole ein. Sie könnten sich und/oder Ihr Unternehmen ernsthaft gefährden.",
  No_Faxes_Word:"Erstellen Sie das erste <a routerLink='/folder/Fax/New'>Fax</a>.",
  Cheque_Delete_Confirmation:"Sind Sie sicher, dass Sie diese Prüfung löschen möchten?",
  Design_Delete_Confirmation:"Sind Sie sicher, dass Sie dieses Design löschen möchten?",
  Cheque_Pay_Confirmation:"Diesen Scheck als bezahlt markieren? Er erscheint NICHT in der Druckwarteschlange.",
  Payment_Pay_Confirmation:"Diese Zahlung als bezahlt markieren? Sie erscheint NICHT in der Scheckwarteschlange.",
  Delete_Deduction_Confirmation:"Sind Sie sicher, dass Sie diesen Abzug löschen möchten?",
  Delete_Job_Confirmation:"Sind Sie sicher, dass Sie diesen Job löschen möchten?",
  Delete_Timesheet_Confirmation:"Sind Sie sicher, dass Sie diese Arbeitszeittabelle löschen möchten?",
  Delete_Schedule_Confirmation:"Sind Sie sicher, dass Sie diesen Zeitplan löschen möchten?",
  Delete_Setting_Confirmation:"Sind Sie sicher, dass Sie diese Einstellung zurücksetzen möchten?",
  Delete_Fax_Confirmation:"Möchten Sie dieses Fax wirklich löschen?",
  Delete_File_Confirmation:"Möchten Sie diese Datei wirklich löschen?",
  Delete_Vacation_Confirmation:"Sind Sie sicher, dass Sie diesen Urlaub löschen möchten?",
  Delete_Printer_Confirmation:"Möchten Sie diesen Drucker wirklich löschen?",
  Remove_Design_Confirmation:"Sind Sie sicher, dass Sie dieses Design löschen möchten?",
  Delete_Payroll_Confirmation:"Sind Sie sicher, dass Sie diese Gehaltsabrechnung löschen möchten?",
  Send_Fax_Email_Confirmation:"Möchten Sie diese Dokumente faxen und mailen?",
  Send_Email_Confirmation:"Möchten Sie dieses Dokument per E-Mail versenden?",
  Send_Fax_Confirmation:"Möchten Sie dieses Dokument faxen?",
  Error_Generating_PDF:"Entschuldigung. Beim Erstellen dieses Dokuments ist ein Fehler aufgetreten.",
  PDF_Error_Saving_Image:"Entschuldigung. Beim Speichern des PDF-Bildes dieses Dokuments ist ein Fehler aufgetreten.",
  Test_Printer_Confirmation:"Möchten Sie eine Testseite auf diesem Drucker drucken?",
  Save_Timesheet_Prompt:"Bitte fügen Sie einen 'Titel' hinzu oder drücken Sie zum Speichern auf 'Start Timer'.",
  Remove_Geofence_Prompt:"Sind Sie sicher, dass Sie den Standort dieses Geofence entfernen möchten?",
  Delete_Employee_Confirmation:"Sind Sie sicher, dass Sie löschen möchten ",
  Fire_Employee_Confirmation:"Sind Sie sicher, dass Sie feuern wollen?"
}