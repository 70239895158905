import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatSliderModule } from '@angular/material/slider';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule,MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTreeModule } from '@angular/material/tree';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignupComponent } from './signup/signup.component';
import { PricePanelComponent } from './price-panel/price-panel.component';
import { PlansComponent } from './plans/plans.component';
import { LoginComponent } from './login/login.component';
import { DemoComponent } from './demo/demo.component'; 
import { FeaturesComponent } from './features/features.component';
import { routes } from './app-routing.module';
import { ScreenshotsComponent } from './screenshots/screenshots.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { EulaComponent } from './agreements/eula/eula.component';
import { SignInComponent } from './modals/sign-in/sign-in.component';
import { WebService } from './services/web.service';
import { PrivacyPolicyComponent } from './agreements/privacy-policy/privacy-policy.component';
import { HttpClientModule } from '@angular/common/http';
@NgModule({
  declarations: [
    AppComponent,
    PricePanelComponent,
    SignupComponent,
    PlansComponent,
    LoginComponent,
    DemoComponent,
    FeaturesComponent,
    ScreenshotsComponent,
    SignInComponent,
    HomeComponent,
    FooterComponent,
    EulaComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    BrowserModule,
    MatIconModule,
    MatSidenavModule,
    MatMenuModule,
    MatCardModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonModule,
    MatSliderModule,
    MatListModule,
    MatTreeModule,
    MatToolbarModule,
    MatTooltipModule,
    MatDividerModule,
    MatCheckboxModule,
    MatGridListModule,
    MatFormFieldModule,
    MatProgressBarModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    RouterModule.forRoot(routes),
  ],
  providers: [    
    WebService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'fill'}
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
