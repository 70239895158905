export const Te = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  Copyright:"కాపీరైట్ &కాపీ; 2023",
  black:"నలుపు",
  green:"ఆకుపచ్చ",
  gold:"బంగారం",
  blue:"నీలం",
  brown:"గోధుమ రంగు",
  purple:"ఊదా",
  pink:"పింక్",
  red:"ఎరుపు",
  Swatches:"స్వాచ్‌లు",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"రంగు",
  Saturation:"సంతృప్తత",
  Lightness:"తేలిక",
  Upgrade_To_Pro:"ప్రోకి అప్‌గ్రేడ్ చేయండి",
  AllFeaturesInclude:"అన్ని ఫీచర్లు ఉన్నాయి:",
  PrintUnlimitedCheques:"అపరిమిత తనిఖీలను ముద్రించండి",
  PremiumChequeDesigns:"ప్రీమియం చెక్ డిజైన్‌లు",
  ManageUnlimitedEmployees:"అపరిమిత ఉద్యోగులను నిర్వహించండి",
  AddUnlimitedPayees:"అపరిమిత చెల్లింపుదారులను జోడించండి",
  CreateUnlimitedPayrolls:"అపరిమిత పేరోల్‌లను సృష్టించండి",
  UnlimitedSchedulesandTimeSheets:"అపరిమిత షెడ్యూల్‌లు మరియు టైమ్ షీట్‌లు",
  BulkPayPalPayouts:"బల్క్ పేపాల్ చెల్లింపులు",
  UnlimitedBankAccounts:"అపరిమిత బ్యాంక్ ఖాతాలు",
  ManageMultipleCompanies:"బహుళ కంపెనీలను నిర్వహించండి",
  TrackInsurancePolicies:"బీమా పాలసీలను ట్రాక్ చేయండి",
  Bio_MetricProtection:"బయో-మెట్రిక్ రక్షణ",
  Geo_FenceLock_OutProtection:"జియో-ఫెన్స్ లాక్-అవుట్ రక్షణ",
  Multiple_Companies_Word:"చెక్‌ల ప్రీమియం లేకుండా బహుళ కంపెనీలను నిర్వహించడం అందుబాటులో ఉండదు.",
  PayPal_Payouts_Word:"చెక్స్ ప్రీమియం లేకుండా పేపాల్ చెల్లింపులు నిలిపివేయబడతాయి.",
  PINProtection:"పిన్ రక్షణ",
  PasswordProtection:"పాస్వర్డ్ రక్షణ",
  May_Require_Approval:"ఆమోదం అవసరం కావచ్చు.",
  Subscribe:"సభ్యత్వం పొందండి",
  Billed:"బిల్లు పెట్టారు",
  Up:"పైకి",
  Down:"క్రిందికి",
  Positioning:"పొజిషనింగ్",
  Marker:"మార్కర్",
  Drag_Marker:"మార్కర్‌ని లాగండి",
  Tagline:"చెక్కులను ముద్రించండి మరియు పేరోల్‌ను పట్టిక చేయండి",
  Marker_Word:"మూలకాన్ని పరిమాణం చేయడానికి మార్కర్‌లను ఉపయోగించండి.",
  Pinch_Zoom:"పించ్ జూమ్",
  Pinch_Word:"మూలకాన్ని జూమ్ చేయడానికి చిటికెడు.",
  Drag:"లాగండి",
  Drag_Word:"మూలకాలను లాగడానికి మీ వేలిని ఉపయోగించండి.",
  subscription_alias_word:"సభ్యత్వాన్ని స్వయంచాలకంగా పునరుద్ధరించడం",
  premium_alias_word:"వన్-టైమ్ అప్‌గ్రేడ్ ప్యాకేజీ",
  print_alias_word:"వ్యక్తిగత తనిఖీలను ప్రింట్-అవుట్ చేయండి",
  mode_alias_word:"మోడ్",
  Pro:"ప్రో",
  Pro_word:"ప్రో వెర్షన్ అవసరం.",
  Cant_Delete_Default_Company_Word:"క్షమించండి, మీరు మీ డిఫాల్ట్ కంపెనీని తొలగించలేరు.",
  Reinsert_Default_Designs:"డిఫాల్ట్ డిజైన్‌లను మళ్లీ చొప్పించండి",
  Reinsert_Default_Designs_word:"మీరు డిఫాల్ట్ డిజైన్‌లను మళ్లీ ఇన్‌సర్ట్ చేయాలనుకుంటున్నారా?",
  Subscription_Active_Disable_Word:"ఈ సబ్‌స్క్రిప్షన్ సక్రియంగా ఉంది. మీరు చెక్‌లకు ఈ సభ్యత్వాన్ని రద్దు చేయాలనుకుంటున్నారా?",
  Company_Logo:"కంపెనీ లోగో",
  ZERO_:"జీరో",
  Disclaimer:"నిరాకరణ",
  Privacy_Policy:"గోప్యతా విధానం",
  EULA:"EULAని తనిఖీ చేస్తుంది",
  Terms_Of_Service:"సేవా నిబంధనలు",
  Terms_Of_Use:"ఉపయోగ నిబంధనలు",
  Refunds:"వాపసు విధానం",
  Single_Print:"1 తనిఖీ",
  Two_Prints:"2 తనిఖీలు",
  Five_Prints:"5 తనిఖీలు",
  Ten_Prints:"10 తనిఖీలు",
  Fifteen_Prints:"15 తనిఖీలు",
  Twenty_Prints:"20 చెక్కులు",
  Thirty_Prints:"30 చెక్కులు",
  Image_Added:"చిత్రం జోడించబడింది",
  Image_Preview:"చిత్రం ప్రివ్యూ",
  No_Image_Was_Selected:"ఏ చిత్రం ఎంచుకోబడలేదు.",
  Cheques_Unlimited:"అపరిమిత తనిఖీలు",
  _Subscription:"చందా",
  Subscription:"తనిఖీలు - 1 నెల",
  Two_Month_Subscription:"తనిఖీలు - 2 నెలలు",
  Three_Month_Subscription:"తనిఖీలు - 3 నెలలు",
  Six_Month_Subscription:"తనిఖీలు - 6 నెలలు",
  Twelve_Month_Subscription:"తనిఖీలు - 12 నెలలు",
  Cheques_Are_Available:"ముద్రించడానికి చెక్కులు అందుబాటులో ఉన్నాయి.",
  Upgrade_Required_Two:"మీ కొనుగోలును ప్రారంభించడానికి ప్యాకేజీని ఎంచుకుని, ధర బటన్‌పై రెండుసార్లు నొక్కండి. ప్రొఫెషనల్‌గా కనిపించే పూర్తి రంగు తనిఖీలను సెకన్లలో ప్రింట్ చేయండి.",
  Month:"నెల",
  Due:"గడువు:",
  Expires:"గడువు ముగుస్తుంది:",
  Subscription_Active:"సబ్‌స్క్రిప్షన్ యాక్టివ్",
  Subscription_Inactive:"సభ్యత్వం నిష్క్రియంగా ఉంది",
  Purchase_Additional_Cheques:"అదనపు చెక్కులను కొనుగోలు చేయాలా?",
  Printable_Cheque:"ముద్రించదగిన చెక్",
  Printable_Cheques:"ముద్రించదగిన తనిఖీలు",
  Printable_Cheque_Word:"చెక్ మీ ఖాతాలో అందుబాటులో ఉంది.",
  Printable_Cheques_Word:"మీ ఖాతాలో చెక్కులు అందుబాటులో ఉన్నాయి.",
  Max_Amount_Message:"మీరు పేర్కొన్న మొత్తం ఈ సిస్టమ్ కోసం సెట్ చేసిన గరిష్ట మొత్తానికి చేరుకుంది:",
  Terms_Required_Word:"చెక్కులను ఉపయోగించడం కొనసాగించడానికి ముందు మీరు తప్పనిసరిగా ఈ ఒప్పందానికి అంగీకరించాలి.",
  Subscriptions:"చందాలు",
  Product_Upgrades:"అప్‌గ్రేడ్‌లు",
  Mailed_Out_Cheques:"మెయిల్-అవుట్ తనిఖీలు",
  Enter_A_Company_Name:"దయచేసి కంపెనీ పేరును నమోదు చేయండి.",
  Single_Cheques:"ఒకే తనిఖీలు",
  Cheque_Golden:"గోల్డెన్ చెక్",
  Cheque_Golden_Window:"గోల్డెన్ చెక్ డిజైన్.",
  Cheque_Green:"గ్రీన్ చెక్",
  Cheque_Green_Window:"గ్రీన్ చెక్ డిజైన్.",
  Cheque_Red:"రెడ్ చెక్",
  Cheque_Red_Window:"రెడ్ చెక్ డిజైన్.",
  Cheque_Yellow:"పసుపు తనిఖీ",
  Cheque_Yellow_Window:"పసుపు చెక్ డిజైన్.",
  Cheque_Statue_of_Liberty:"స్టాట్యూ ఆఫ్ లిబర్టీ",
  Cheque_Statue_of_Liberty_Window:"స్టాట్యూ ఆఫ్ లిబర్టీ చెక్ డిజైన్.",
  Cheque_Green_Wave:"గ్రీన్ వేవ్",
  Cheque_Green_Wave_Window:"గ్రీన్ చెక్ డిజైన్.",
  Cheque_Golden_Weave:"గోల్డెన్ వీవ్",
  Cheque_Golden_Weave_Window:"సొగసైన గోల్డెన్ చెక్ డిజైన్.",
  Cheque_Green_Sun:"ఆకుపచ్చ సూర్యుడు",
  Cheque_Green_Sun_Window:"లోతైన మరియు ప్రశాంతత చెక్ డిజైన్.",
  Cheque_Blue_Checkers:"బ్లూ చెకర్స్",
  Cheque_Blue_Checkers_Window:"బ్లూ చెక్ డిజైన్.",
  Cashiers_Check:"క్యాషియర్ చెక్",
  Cashiers_Check_Window:"క్యాషియర్ చెక్ స్టైల్ టెంప్లేట్.",
  Cheque_Aqua_Checkers:"ఆక్వా చెకర్స్",
  Cheque_Aqua_Checkers_Window:"ఆక్వా చెక్ డిజైన్.",
  Cheque_Golden_Checkers:"గోల్డెన్ చెకర్స్",
  Cheque_Golden_Checkers_Window:"గోల్డెన్ చెక్ డిజైన్.",
  Upgrade_Unavailable:"అప్‌గ్రేడ్‌లు అందుబాటులో లేవు",
  Bank_Code_Protection:"బ్యాంక్ నంబర్ రక్షణ",
  Bank_Code_Protection_Word:"మీ బ్యాంక్ నంబర్‌లను మరొక పరికరంలో లేదా మరొక వినియోగదారు కోసం అమలు చేస్తున్న ఈ యాప్‌లో ఉపయోగించకుండా రక్షించుకోండి. ఈ చర్య తిరుగులేనిది. కొనసాగించాలా?",
  Bank_Code_Protection_Blocked_Word:"మీరు ఉపయోగించడానికి ప్రయత్నిస్తున్న బ్యాంక్ నంబర్‌లు మరొక వినియోగదారు లేదా పరికరం కోసం రిజర్వ్ చేయబడ్డాయి.",
  Bank_Code_Protection_Error_Word:"నంబర్ ధృవీకరణ విఫలమైంది. దయచేసి ఇంటర్నెట్‌కి కనెక్ట్ చేసి, ఈ బ్యాంక్ ఖాతాను మళ్లీ జోడించడానికి ప్రయత్నించండి.",
  Bank_Code_Protection_Set_Error_Word:"బ్యాంక్ నంబర్ రక్షణ కోసం మీరు ఇంటర్నెట్‌కి కనెక్ట్ అయి ఉండాలి. దయచేసి కనెక్ట్ చేసి, మళ్లీ ప్రయత్నించండి.",
  Upgrade_Unavailable_Word:"క్షమించండి, మిమ్మల్ని ధృవీకరించడంలో మాకు సమస్య ఉంది. చెక్‌లకు సభ్యత్వాలు మరియు అప్‌గ్రేడ్‌లు ప్రస్తుతం మీ ప్రాంతంలో కొనుగోలు చేయడానికి అందుబాటులో లేవు.",
  PayPal_Payment_Preview:"పేపాల్ చెల్లింపు ప్రివ్యూ",
  Apple_Pay:"ఆపిల్ పే",
  Select_PayPal:"పేపాల్ని ఎంచుకోండి",
  PayPal_Applications:"పేపాల్ అప్లికేషన్లు",
  Purchase_With_Apple_Pay:"Apple Payతో కొనుగోలు చేయండి",
  Google_Pay:"Google Pay",
  Companies:"కంపెనీలు",
  Insurance:"భీమా",
  New_PayPal:"కొత్త పేపాల్",
  Pay_By:"ద్వారా చెల్లించండి",
  Insure:"బీమా చేయండి",
  Miles:"మైళ్లు",
  Payment_Method:"చెల్లింపు పద్ధతి",
  Select_Policies:"విధానాలను ఎంచుకోండి",
  Policies:"విధానాలు",
  Policy:"విధానం",
  No_PayPal_Account:"పేపాల్ ఖాతా లేదు",
  No_Policies:"బీమా పాలసీలు లేవు",
  New_Policy:"కొత్త విధానం",
  PayPal_Payment:"పేపాల్ చెల్లింపు",
  PayPal_Payments:"పేపాల్ చెల్లింపులు",
  No_Payment_Selected:"చెల్లింపు ఎంపిక చేయబడలేదు",
  Sending_Payment_Word:"దయచేసి వేచి ఉండండి... ఈ చెల్లింపు పంపబడుతోంది.",
  Sending_Payments_Word:"దయచేసి వేచి ఉండండి... చెల్లింపులు పంపబడుతున్నాయి.",
  No_Payment_Selected_PayPal:"పంపడానికి <a routerLink='/folder/Payments'>పేపాల్ చెల్లింపు</a> ఎంచుకోబడలేదు.",
  Payment_Sent:"చెల్లింపు పంపబడింది",
  PayPal_Payment_Sent:"ఈ చెల్లింపు పేపాల్ పంపబడింది.",
  Copay:"కాపీ చేయండి",
  Dead:"చనిపోయింది",
  Alive:"సజీవంగా",
  Not_Dead:"చనిపోలేదు",
  Unclaimed:"క్లెయిమ్ చేయబడలేదు",
  Attempted:"ప్రయత్నించారు",
  Deceased:"మరణించారు",
  Claimed:"క్లెయిమ్ చేసారు",
  Unpaid:"చెల్లించని",
  Sending_Payment:"చెల్లింపును పంపుతోంది",
  Sending_Payments:"చెల్లింపులను పంపుతోంది",
  Send_PayPal_Confirmation:"మీరు ఈ లావాదేవీని పేపాల్ పంపాలనుకుంటున్నారా?",
  Send_PayPal_Confirmation_Word:"ఈ లావాదేవీని పంపడానికి ఆకుపచ్చ బటన్‌ను నొక్కండి.",
  Save_Payment_As_Unpaid:"ఈ చెల్లింపును చెల్లించనిదిగా సేవ్ చేయాలా?",
  Payment_Pay_Confirmation_PayPal:"ఈ చెల్లింపును చెల్లించినట్లుగా సేవ్ చేయాలా?",
  No_Policies_Word:"ఇప్పుడు మొదటి <a routerLink='/folder/Postage/New'>భీమా పాలసీ</a>ని జోడించండి.",
  Timesheet_Multiple_Delete_Confirmation:"మీరు ఖచ్చితంగా బహుళ టైమ్‌షీట్‌లను తొలగించాలనుకుంటున్నారా?",
  Select_Multiple_Timesheets_Prompt:"టైమ్‌షీట్‌లు ఏవీ ఎంచుకోబడలేదు. కనీసం ఒక టైమ్‌షీట్‌ని ఎంచుకోండి.",
  Select_Multiple_Policies_Prompt:"విధానాలు ఏవీ ఎంచుకోబడలేదు. కనీసం ఒక బీమా పాలసీని ఎంచుకోండి.",
  Policies_Multiple_Delete_Confirmation:"మీరు ఖచ్చితంగా బహుళ విధానాలను తొలగించాలనుకుంటున్నారా?",
  Company:"కంపెనీ",
  Add_Company:"కంపెనీని జోడించండి",
  New_Company:"కంపెనీని జోడించండి",
  No_Companies:"కంపెనీలు లేవు",
  Enable_Company:"కంపెనీని ప్రారంభించండి",
  Select_Company:"కంపెనీని ఎంచుకోండి",
  The_Default_Company:"డిఫాల్ట్ కంపెనీ లేబుల్.",
  Manage_Companies:"కంపెనీలను నిర్వహించండి",
  No_Companies_Word:"తనిఖీలు డిఫాల్ట్ కంపెనీని ఉపయోగిస్తాయి.<br /><a routerLink='/folder/Company/New'>మొదటి కంపెనీ</a>ని జోడించండి.",
  Default_Company:"డిఫాల్ట్ కంపెనీ",
  Cheques_Unlimited_Word:"చెక్స్ అన్‌లిమిటెడ్ మీకు నచ్చినన్ని చెక్కులను ప్రింట్ చేయడానికి మిమ్మల్ని అనుమతిస్తుంది.",
  Cheques_Subscription_Word:"చెక్‌ల సబ్‌స్క్రిప్షన్ మీకు నచ్చినన్ని చెక్కులను ప్రింట్ చేయడానికి మిమ్మల్ని అనుమతిస్తుంది.",
  You_Own_This_Product:"మీరు ఈ ఉత్పత్తిని కలిగి ఉన్నారు.",
  Your_Subscription_is_Active:"మీ సభ్యత్వం సక్రియంగా ఉంది.",
  Active_Products:"సక్రియం చేయబడిన ఉత్పత్తులు",
  Purchase_Confirmation:"కొనుగోలు",
  Purchase_Cheques:"కొనుగోలు తనిఖీలు",
  Restore_Purchase:"కొనుగోళ్లు పునరుద్ధరించడానికి",
  Erase_Purchase:"కొనుగోళ్లను తొలగించండి",
  Successfully_Purchased:"విజయవంతంగా కొనుగోలు చేయబడింది",
  Enter_Your_Licence_Key:"ఈ ఉత్పత్తిని సక్రియం చేయడానికి దయచేసి ఈ పేజీలో మీ లైసెన్స్ కీని నమోదు చేయండి.",
  Licence_Key:"లైసెన్స్ కీ",
  Enter_Licence_Key:"లైసెన్స్ కీని నమోదు చేయండి",
  Purchased:"కొనుగోలు చేశారు",
  Enable_Feature:"లక్షణాన్ని ప్రారంభించండి",
  Cancel_Subscription:"సభ్యత్వాన్ని రద్దు చేయండి",
  Subscription_Removed:"సభ్యత్వం తీసివేయబడింది",
  Select_Active_Subscription:"సక్రియ సభ్యత్వాన్ని సవరించడానికి దాన్ని ఎంచుకోండి.",
  Remove_Subscription_Word:"మీరు ఖచ్చితంగా ఈ సభ్యత్వాన్ని రద్దు చేయాలనుకుంటున్నారా?",
  Delete_Company_Confirmation:"మీరు ఖచ్చితంగా ఈ మొత్తం కంపెనీని తొలగించాలనుకుంటున్నారా? హెచ్చరిక: మీరు నిల్వ చేసిన మొత్తం సమాచారాన్ని కోల్పోతారు!",
  Delete_Default_Company_Word:"మీరు డిఫాల్ట్ కంపెనీని తొలగించలేరు. మీరు అప్లికేషన్‌ను రీసెట్ చేసి, దాన్ని డిఫాల్ట్ స్థితికి పునరుద్ధరించాలనుకుంటున్నారా? హెచ్చరిక: మీరు నిల్వ చేసిన మొత్తం సమాచారాన్ని కోల్పోతారు!",
  Console_Warning_2:"ప్రస్తుతం మీది కాని ఈ అప్లికేషన్‌ను ఉపయోగించి ఏ కరెన్సీని నిర్వహించవద్దు.",
  Terms_and_Conditions:"నిబంధనలు మరియు షరతులు",
  and_the:"ఇంకా",
  for:"కోసం",
  Please_Read_Word:"దయచేసి చదవండి మరియు అంగీకరించండి",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"కొన్ని కరెన్సీ మార్పిడి రేట్లు కనుగొనబడలేదు. దయచేసి ఇంటర్నెట్‌కి కనెక్ట్ చేయండి.",
  Free:"ఉచిత",
  DB_Erase_Prompt_2:"మొత్తం డెవలపర్ డేటాబేస్‌ను పూర్తిగా తొలగించాలా? హెచ్చరిక: మీరు మొత్తం కొనుగోలు మరియు సభ్యత్వ సమాచారాన్ని కోల్పోతారు!",
  Successfully_Imported:"విజయవంతంగా దిగుమతి చేయబడింది",
  Select_Postage:"పోస్టేజీని ఎంచుకోండి",
  No_Postage:"తపాలా స్టాంపులు లేవు",
  No_Paid_Postage_Word:"మొదటి <a routerLink='/folder/Postage/New'>చెల్లించిన తపాలా</a> స్టాంప్‌ను జోడించండి.",
  Trial_Complete:"ట్రయల్ పూర్తయింది",
  Please_Upgrade:"దయచేసి ముద్రణను కొనసాగించడానికి తనిఖీలను అప్‌గ్రేడ్ చేయండి.",
  Aa:"ఆ",
  Color:"రంగు",
  Font:"ఫాంట్",
  Guide:"గైడ్",
  Guides:"మార్గదర్శకులు",
  Image:"చిత్రం",
  Zoom:"జూమ్ చేయండి",
  Logo:"లోగో",
  Bank:"బ్యాంక్",
  MICR:"MICR",
  Total:"మొత్తం",
  Cancel:"రద్దు చేయండి",
  Confirm:"నిర్ధారించండి",
  Method:"పద్ధతి",
  Biometric_Security:"బయోమెట్రిక్ భద్రత",
  Please_Login_To_Continue:"దయచేసి కొనసాగించడానికి లాగిన్ చేయండి.",
  Complete:"పూర్తి",
  Sign_Up:"చేరడం",
  Error:"లోపం",
  Biometrics:"బయో మెట్రిక్స్",
  Percent:"శాతం",
  Zero_Percent:"0%",
  Top_Margin:"ఎగువ మార్జిన్",
  Bottom_Margin:"దిగువ మార్జిన్",
  Left_Margin:"ఎడమ మార్జిన్",
  Right_Margin:"కుడి మార్జిన్",
  MICR_Margin:"MICR మార్జిన్",
  Table_Margin:"టేబుల్ మార్జిన్",
  Address_Margin:"చిరునామా మార్జిన్",
  Percentage_:"శాతం",
  Vacation_Title:"సెలవు శీర్షిక",
  Use_PIN:"పిన్ ఉపయోగించండి",
  Loading__:"లోడ్...",
  Design_Title:"డిజైన్ శీర్షిక",
  Authorize:"అధికారం ఇవ్వండి",
  Key:"కీ",
  Public_Key:"పబ్లిక్ కీ",
  Private_Key:"ప్రైవేట్ కీ",
  Authenticate:"ప్రమాణీకరించండి",
  Last_Payroll:"చివరి పేరోల్",
  Last_Calculation:"చివరి గణన",
  Authorized:"అధీకృతం",
  Geo_Authorized:"భౌగోళిక స్థానం: అధికారం",
  Not_Authorized:"అధీకృతం కాదు",
  Authorization_Complete:"ఆథరైజేషన్ పూర్తయింది",
  Geolocation_Authorization:"జియో-లొకేషన్ ఆథరైజేషన్",
  Out_of_Bounds:"హద్దులు దాటి",
  Cant_Delete_Default_Design:"డిఫాల్ట్ డిజైన్‌ను తొలగించడం సాధ్యం కాదు.",
  Unauthorized_Out_of_Bounds:"అనధికార: హద్దులు దాటి",
  Biometric_Authorization:"బయో-మెట్రిక్ ఆథరైజేషన్",
  Locating_Please_Wait:"గుర్తించడం, దయచేసి వేచి ఉండండి...",
  Test_Biometrics:"బయో మెట్రిక్‌లను పరీక్షించండి",
  Cheque_Margins:"మార్జిన్లను తనిఖీ చేయండి",
  Percentage_Full_Error:"శాతం ఫీల్డ్ 100% శాతం కంటే ఎక్కువ సెట్ చేయబడదు.",
  No_Payroll_Text:"<a routerLink='/folder/Employee/New'>ఉద్యోగి</a> లేదా <a routerLink='/folder/Payee/New'>చెల్లింపుదారు</a> మరియు <a routerLink='/folder/Scheduleని జోడించండి /న్యూ'>షెడ్యూల్</a>.",
  Design_Saved:"డిజైన్ సేవ్ చేయబడింది",
  Default_Design_Selected:"డిఫాల్ట్ డిజైన్ ఎంచుకోబడింది",
  Partial_Import:"పాక్షిక దిగుమతి",
  Partial_Export:"పాక్షిక ఎగుమతి",
  Entire_Import:"మొత్తం దిగుమతి",
  Entire_Export:"మొత్తం ఎగుమతి",
  Existing_Password:"దయచేసి మీ ప్రస్తుత పాస్‌వర్డ్‌ని నమోదు చేయండి:",
  Existing_PIN:"దయచేసి మీ ప్రస్తుత పిన్ కోడ్‌ని నమోదు చేయండి:",
  Pin_Change_Header:"పిన్ నిర్ధారణ",
  Pin_Change_SubHeader:"మార్పును నిర్ధారించడానికి మీ పాత పిన్ నంబర్‌ను నమోదు చేయండి.",
  Pass_Change_Header:"పాస్వర్డ్ నిర్ధారణ",
  Pass_Change_SubHeader:"మార్పును నిర్ధారించడానికి మీ పాత పాస్‌వర్డ్‌ను నమోదు చేయండి.",
  PIN_Enter_Message:"నిర్ధారించడానికి మీ PINని నమోదు చేయండి.",
  Password_Enter_Message:"నిర్ధారించడానికి మీ పాస్‌వర్డ్‌ను నమోదు చేయండి.",
  Pin_Updated_Success:"పిన్ విజయవంతంగా నవీకరించబడింది!",
  Pin_Updated_Fail:"PINని అప్‌డేట్ చేయడం సాధ్యపడలేదు.",
  Pass_Updated_Success:"పాస్‌వర్డ్ విజయవంతంగా నవీకరించబడింది.",
  Pass_Updated_Fail:"పాస్‌వర్డ్‌ని అప్‌డేట్ చేయడం సాధ్యపడలేదు.",
  Preview_Payment:"చెల్లింపు ప్రివ్యూ",
  Preview_Payroll:"ప్రివ్యూ పేరోల్",
  No_Payments_Created:"చెల్లింపులు సృష్టించబడినట్లు కనుగొనబడలేదు.",
  Payment_Preview:"చెల్లింపు ప్రివ్యూ",
  Enable_Payee:"చెల్లింపుదారుని ప్రారంభించండి",
  Rendered:"అందించబడింది",
  No_Email:"ఇమెయిల్ లేదు",
  Setup_Cheques:"సెటప్ తనిఖీలు",
  name:"పేరు",
  address:"చిరునామా",
  address_2:"చిరునామా లైన్ 2",
  city:"నగరం",
  province:"ప్రావిన్స్",
  postal_code:"పోస్టల్ / జిప్ కోడ్",
  country:"దేశం",
  username:"వినియోగదారు పేరు",
  full_name:"పూర్తి పేరు",
  birthday:"పుట్టినరోజు",
  email:"ఇమెయిల్",
  phone:"ఫోన్",
  employees:"ఉద్యోగులు",
  addresses:"చిరునామాలు",
  payment_amount_limit:"చెల్లింపు మొత్తం పరిమితి",
  total_limit:"మొత్తం పరిమితి",
  payees:"చెల్లింపుదారులు",
  description:"వివరణ",
  address_line_one:"చిరునామా లైన్ వన్",
  address_line_two:"చిరునామా లైన్ రెండు",
  image:"చిత్రం",
  account_holder:"ఖాతాదారుడు",
  cheque_starting_id:"ప్రారంభ IDని తనిఖీ చేయండి",
  transit_number:"రవాణా సంఖ్య",
  institution_number:"సంస్థ సంఖ్య",
  designation_number:"హోదా సంఖ్య",
  account_number:"ఖాతా సంఖ్య",
  currency:"కరెన్సీ",
  signature:"సంతకం",
  payment_payroll_limit:"చెల్లింపు పరిమితి",
  total_limi:"మొత్తం పరిమితి",
  date:"తేదీ",
  printed_memo:"ముద్రించిన మెమో",
  banks:"బ్యాంకులు",
  signature_image:"సంతకం చిత్రం",
  address_name:"చిరునామా పేరు",
  notes:"గమనికలు",
  design:"రూపకల్పన",
  title:"శీర్షిక",
  frequency:"తరచుదనం",
  fax:"ఫ్యాక్స్",
  salaries:"జీతాలు",
  salary_ids:"జీతం IDలు",
  start_time:"ప్రారంభ సమయం",
  end_time:"ముగింపు సమయం",
  paid:"చెల్లించారు",
  overtime_percentage:"చెల్లించిన శాతం",
  overtime_amount:"చెల్లించిన స్థిర మొత్తం",
  first_name:"మొదటి పేరు",
  last_name:"చివరి పేరు",
  moderation:"మోడరేషన్",
  create:"సృష్టించు",
  edit:"సవరించు",
  destroy:"నాశనం చేయండి",
  day_start_time:"రోజు_ప్రారంభ_సమయం",
  day_end_time:"రోజు_చివరి_సమయం",
  fullname:"పేరు",
  time:"సమయం",
  auto_send:"స్వయంచాలకంగా పంపండి",
  time_method:"సమయ పద్ధతి",
  schedules:"షెడ్యూల్స్",
  indefinite_payroll_enabled:"నిరవధికంగా ప్రారంభించండి",
  amount:"మొత్తం",
  repeat:"పునరావృతం చేయండి",
  always_enabled:"ఎల్లప్పుడూ ప్రారంభించబడింది",
  start_date:"ప్రారంబపు తేది",
  end_date:"ఆఖరి తేది",
  Cheque_Total:"మొత్తం తనిఖీ చేయండి",
  Total_Amount:"మొత్తం అమౌంట్:",
  Amounts:"మొత్తాలు:",
  Images:"చిత్రాలు",
  Files:"ఫైళ్లు",
  Previewing:"పరిదృశ్యం:",
  Insert:"చొప్పించు",
  Preview_Import:"ప్రివ్యూ దిగుమతి",
  Entry:"ప్రవేశం",
  Entries:"ఎంట్రీలు",
  No_Entries:"ఎంట్రీలు లేవు",
  Import_Type:"దిగుమతి రకం",
  Select_Details:"వివరాలను ఎంచుకోండి",
  Select_Payee:"చెల్లింపుదారుని ఎంచుకోండి",
  Enable_Holidays:"సెలవులను ప్రారంభించండి",
  Disable_Holidays:"సెలవులను నిలిపివేయండి",
  Wire_Transfer:"వైర్ బదిలీ",
  New_Export:"కొత్త ఎగుమతి",
  Export_Data:"డేటాను ఎగుమతి చేయండి",
  Export_Data_Word:"ఎగుమతి చేయడానికి మరియు డౌన్‌లోడ్ చేయడానికి ఫైల్ రకాన్ని ఎంచుకోండి.",
  Export_File:"ఫైల్‌ను ఎగుమతి చేయండి",
  Mode:"మోడ్",
  Times:"టైమ్స్",
  Widgets:"విడ్జెట్‌లు",
  Slider:"స్లయిడర్",
  Set_Details:"వివరాలను సెట్ చేయండి",
  Select_Type:"రకాన్ని ఎంచుకోండి",
  Display_Slider:"డిస్ప్లే స్లైడర్",
  Dashboard_Slider:"డాష్‌బోర్డ్ స్లైడర్",
  Dashboard_Mode:"డాష్‌బోర్డ్ మోడ్",
  Show_Intro:"పరిచయాన్ని చూపించు",
  Show_Payrolls:"పేరోల్‌లను చూపించు",
  Show_Holidays:"సెలవులు చూపించు",
  Show_Invoices:"ఇన్‌వాయిస్‌లను చూపించు",
  Show_Cheques:"తనిఖీలను చూపించు",
  Enabled_Widgets:"ప్రారంభించబడిన విడ్జెట్‌లు",
  Disabled_Widgets:"డిసేబుల్ విడ్జెట్‌లు",
  Colors:"రంగులు",
  Barcodes:"బార్‌కోడ్‌లు",
  View_Timers:"టైమర్‌లను వీక్షించండి",
  Gradients:"ప్రవణతలు",
  No_Info:"సమాచారం లేదు",
  Disable:"డిసేబుల్",
  Show_Layer:"లేయర్‌లను చూపించు",
  Hide_Layer:"పొరలను దాచండి",
  Text_Layer:"టెక్స్ట్ పొరలు",
  Secondly:"రెండవది",
  Minutely:"నిమిషానికి",
  nine_am:"ఉదయం 9.00",
  five_pm:"5:00 PM",
  Enable_Address:"చిరునామాను ప్రారంభించండి",
  Invalid_File_Type:"క్షమించండి, చెల్లని ఫైల్ రకం ఎంచుకోబడింది. మద్దతు ఉన్న ఫైల్ రకం:",
  Error_Updating_Entry:"క్షమించండి, ఈ ఎంట్రీని నవీకరిస్తున్నప్పుడు లోపం ఏర్పడింది.",
  Schedule_Timing_Alert:"లోపం: షెడ్యూల్ ప్రారంభ సమయం ముగింపు సమయం తర్వాత విలువకు సెట్ చేయబడింది.",
  Select_Multiple_Payments_Prompt:"చెల్లింపులు ఏవీ ఎంచుకోబడలేదు. కనీసం ఒక చెల్లింపును ఎంచుకోండి.",
  Select_Multiple_Cheques_Prompt:"చెక్ ఎంచుకోబడలేదు. దయచేసి కనీసం ఒక చెక్‌ని ఎంచుకోండి.",
  Select_Multiple_Items_Prompt:"అంశాలు ఏవీ ఎంచుకోబడలేదు. దయచేసి కనీసం ఒక అంశాన్ని ఎంచుకోండి.",
  Paymemt_Multiple_Delete_Confirmation:"మీరు ఖచ్చితంగా బహుళ చెల్లింపులను తొలగించాలనుకుంటున్నారా?",
  Cheque_Multiple_Delete_Confirmation:"మీరు ఖచ్చితంగా బహుళ చెక్కులను తొలగించాలనుకుంటున్నారా?",
  Payee_Multiple_Delete_Confirmation:"మీరు బహుళ చెల్లింపుదారులను ఖచ్చితంగా తొలగించాలనుకుంటున్నారా?",
  Employee_Multiple_Delete_Confirmation:"మీరు బహుళ ఉద్యోగులను ఖచ్చితంగా తొలగించాలనుకుంటున్నారా?",
  MICR_Warning:"గమనిక: కొన్ని ప్రింటర్లు మరియు పరికరాలు MICR ఫాంట్‌ను సరిగ్గా ప్రదర్శించకపోవచ్చు.",
  Automatically_Send:"స్వయంచాలకంగా పంపండి",
  Type:"టైప్ చేయండి",
  Payment_Type:"చెల్లించు విధానము",
  Auto_Send:"ఆటో పంపండి",
  Automatically_Process:"స్వయంచాలకంగా ప్రాసెస్ చేయండి",
  Auto_Process:"స్వీయ ప్రక్రియ",
  Image_Based:"చిత్రం ఆధారిత",
  Font_Based:"ఫాంట్ ఆధారిత",
  Rerender:"రీ-రెండర్",
  Rendering:"రెండరింగ్",
  Render:"ఫైళ్లు",
  Top:"టాప్",
  Middle:"మధ్య",
  Bottom:"దిగువన",
  Top_Left:"ఎగువ ఎడమ",
  Top_Center:"టాప్ సెంటర్",
  Top_Right:"ఎగువ కుడి",
  Middle_Left:"మధ్య ఎడమ",
  Middle_Center:"మధ్య కేంద్రం",
  Middle_Right:"మధ్య కుడి",
  Bottom_Left:"దిగువ ఎడమ",
  Bottom_Center:"దిగువ కేంద్రం",
  Bottom_Right:"దిగువ కుడి",
  Numbers:"సంఖ్యలు",
  Verified:"ధృవీకరించబడింది",
  Paper_Size:"పేపర్ సైజు",
  New_Device:"కొత్త పరికరం",
  Add_Device:"పరికరాన్ని జోడించండి",
  Remove_Device:"పరికరాన్ని తీసివేయండి",
  Delete_Device:"పరికరాన్ని తొలగించండి",
  Block_Device:"పరికరాన్ని బ్లాక్ చేయండి",
  Block:"నిరోధించు",
  Unblock:"అన్‌బ్లాక్ చేయండి",
  Table:"పట్టిక",
  Scan_Login_Code:"లాగిన్ కోడ్‌ని స్కాన్ చేయండి",
  Login_Code:"లాగిన్ కోడ్",
  Scan_Code:"స్కాన్ కోడ్",
  Scan_QR_Code:"QR కోడ్‌ని స్కాన్ చేయండి",
  Select_All:"అన్ని ఎంచుకోండి",
  Deselect_All:"అన్నీ ఎంపికను తీసివేయండి",
  Increase:"పెంచు",
  Decrease:"తగ్గించు",
  Bold:"బోల్డ్",
  Text:"వచనం",
  Style:"శైలి",
  Italic:"ఇటాలిక్",
  QR_Code:"QR కోడ్",
  Barcode:"బార్‌కోడ్",
  Browse_Images:"చిత్రాలను బ్రౌజ్ చేయండి",
  Browse_Files:"ఫైళ్లను బ్రౌజ్ చేయండి",
  Background_Image:"నేపథ్య చిత్రం",
  Logo_Image:"లోగో చిత్రం",
  Header_Image:"శీర్షిక చిత్రం",
  Bank_Image:"బ్యాంక్ చిత్రం",
  Cut_Lines:"కట్ లైన్స్",
  Background:"నేపథ్య",
  License:"లైసెన్స్",
  One_License:"1 లైసెన్స్:",
  Licensed:"లైసెన్స్ పొందింది:",
  Not_Licensed:"లైసెన్స్ లేదు",
  Enter_Serial:"సీరియల్‌ని నమోదు చేయండి",
  Serial_Key:"సీరియల్ కీ",
  Serial:"క్రమ",
  Product_Key:"ఉత్పత్తి కీ",
  Check_Product_Key:"ఉత్పత్తి కీని ధృవీకరించండి",
  Add_Product_Key:"ఉత్పత్తి కీని నమోదు చేయండి",
  Verifying_Purchase:"కొనుగోలును ధృవీకరిస్తోంది...",
  Print_Envelope:"ప్రింట్ ఎన్వలప్",
  Envelope:"కవచ",
  Thank_You:"ధన్యవాదాలు!",
  Scale:"స్కేల్",
  Print_Scale:"ప్రింట్ స్కేల్",
  Borders:"సరిహద్దులు",
  VOID:"శూన్యం",
  Void_Cheque:"చెల్లని తనిఖీ",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ఈ క్రమంలో చెల్లించండి:",
  NO_DOLLARS:"డాలర్లు లేవు",
  ONE_DOLLAR:"ఒక డాలరు",
  DOLLARS:"డాలర్లు",
  AND:"మరియు",
  CENTS:" సెంట్లు.",
  NO_:"నం",
  ONE_:"ఒకటి",
  AND_NO_:"మరియు కాదు",
  _AND_ONE_:"మరియు ఒక",
  DOLLARS_AND_ONE_CENT:"మరియు ఒక సెంటు.",
  AND_NO_CENTS:" మరియు సున్నా సెంట్లు.",
  CHEQUE_PRINT_DATE:"తేదీ:",
  CHEQUE_PRINT_MEMO:"మెమో:",
  CHEQUE_PRINT_SIGNATUREMP:"ఎంపీ",
  Initial_Tasks:"ప్రారంభ పనులు",
  Inital_Setup:"మొదటి ఏర్పాటు",
  Welcome_To:"కు స్వాగతం",
  Welcome:"స్వాగతం",
  Swipe:"స్వైప్ చేయండి",
  Intro_Setup:"పరిచయ సెటప్",
  Introduction:"పరిచయం",
  CHEQUE_PRINT_CREDIT:"తనిఖీల ద్వారా ఆధారితం",
  Title_Intro_Word:"తనిఖీలకు స్వాగతం",
  Title_Intro:"తనిఖీల పరిచయం",
  Title_Setup:"సెటప్‌ని తనిఖీ చేస్తుంది",
  PayPal_Default_Email_Message:"మీరు కొత్త పేపాల్ బదిలీని స్వీకరించారు.",
  Cheques_App_Export:"చెక్కుల ద్వారా ఎగుమతి చేయబడింది",
  No_Prints_Purchased:"ప్రింట్‌లు ఏవీ కొనుగోలు చేయబడలేదు",
  No_Prints_Purchased_Word:"అదనపు ప్రింట్లు అందుబాటులో లేవు. దయచేసి అదనపు చెక్కులను కొనుగోలు చేయండి.",
  Post_Intro_Thanks:"చెక్కులను ఎంచుకున్నందుకు ధన్యవాదాలు. సెటప్ ప్రక్రియ ఇప్పుడు పూర్తయింది.",
  Post_Intro_Word:"మీ మొదటి చెక్‌ను ప్రింట్ చేయడం ద్వారా ప్రారంభించండి, భవిష్యత్ చెల్లింపును జోడించండి లేదా పేరోల్‌కు ఉద్యోగులను జోడించండి.",
  Upgrade_Required:"ఈ సందేశాన్ని దాచడానికి మరియు అదనపు ఫీచర్‌లను అన్‌లాక్ చేయడానికి తనిఖీలకు మీరు సాఫ్ట్‌వేర్ యొక్క మరింత ప్రీమియం వెర్షన్‌ను కలిగి ఉండాలి.",
  Upgrade_Title:"తనిఖీలు",
  Mailout_Prompt:"మీరు అదనంగా మీ పేరోల్ చెక్‌లను చెక్‌లను మెయిల్-అవుట్ చేయడానికి ఎంచుకోవచ్చు.",
  Mailed_Cheque:"మెయిల్ చేసిన చెక్",
  Mailed_Cheque_Color:"మెయిల్ చేసిన చెక్ (రంగు)",
  Terms_Purchase:"చెక్కులతో కూడిన అన్ని ఎలక్ట్రానిక్ కొనుగోళ్లు కొనుగోలు తేదీ నుండి 30 రోజుల వరకు పూర్తిగా రీఫండ్ చేయబడతాయి. దయచేసి చదవండి మరియు అంగీకరించండి",
  Dashboard_Setup:"ప్రాథమిక ప్రింటర్‌ని సెటప్ చేయండి",
  Dashboard_Add:"ప్రాథమిక బ్యాంక్ ఖాతాను జోడించండి",
  Dashboard_Customize:"చెక్ టెంప్లేట్‌ని ఎంచుకోండి",
  Dashboard_Job_Salary:"మీ ఉద్యోగాన్ని సృష్టించండి & మీ జీతం జోడించండి",
  Dashboard_Employees:"ఉద్యోగులు & చెల్లింపుదారులను ట్రాక్ చేయండి",
  Dashboard_Print:"మీ చెల్లింపు చెక్కులను ప్రింట్ & మెయిల్ చేయండి",
  Dashboard_Payroll:"మీ పేరోల్ ప్రింటింగ్‌ను ఆటోమేట్ చేయండి",
  Dashboard_PayPal:"పేపాల్ పేరోల్ / చెల్లింపులను సెటప్ చేయండి",
  Begin_Setup:"సెటప్ ప్రారంభించండి",
  Get_Started:"ప్రారంభించడానికి",
  Purchase:"కొనుగోలు",
  Lockdown:"నిర్బంధం",
  Unlock:"అన్‌లాక్ చేయండి",
  Detailed:"వివరంగా",
  Log_In:"ప్రవేశించండి",
  Login:"ప్రవేశించండి",
  Launch:"ప్రారంభించండి",
  Register:"నమోదు చేసుకోండి",
  Finish:"ముగించు",
  List:"జాబితా",
  Weekends:"వారాంతాల్లో",
  Weekly:"వారానికోసారి",
  PayPal_Default_Subject:"కొత్త చెల్లింపు",
  Payment_Message:"చెల్లింపు సందేశం",
  PayPal_Default_Payment_Note:"ధన్యవాదాలు",
  Setup_Your_Cheqing_Account:"ఖాతా తనిఖీ",
  Add_Your_Primary_Cheqing_Account:"మీ ప్రాథమిక తనిఖీ ఖాతాను జోడించండి.",
  Welcome_Word:"పేరోల్ మరియు మానవ వనరుల నిర్వహణను సులభతరం చేయండి మరియు ఆటోమేట్ చేయండి.",
  Get_Started_Quickly:"ప్రారంభించడానికి మాకు సహాయపడే కొన్ని సాధారణ ప్రశ్నలకు సమాధానం ఇవ్వండి...",
  Done_Intro_Word:"సెటప్ పూర్తయింది",
  PIN_Protected:"పాస్‌వర్డ్ & పిన్ రక్షించబడింది",
  Enter_New_PIN:"కొత్త పిన్ కోడ్‌ని నమోదు చేయండి:",
  Enter_PIN:"పిన్ కోడ్‌ని నమోదు చేయండి:",
  Invalid_PIN:"చెల్లని పిన్ నమోదు చేయబడింది.",
  Account_Identifier:"ఖాతా ఐడెంటిఫైయర్",
  New_Account_Identifier:"కొత్త ఖాతా ఐడెంటిఫైయర్",
  attempt:"ప్రయత్నం",
  attempts:"ప్రయత్నాలు",
  remaining:"మిగిలి ఉంది",
  Language:"భాష",
  languages:"భాషలు",
  select_languages:"భాషను ఎంచుకోండి",
  Deposit:"డిపాజిట్ చేయండి",
  Hire_One_Now:"ఇప్పుడు ఒకరిని నియమించుకోండి",
  App_Locked:"అప్లికేషన్ లాక్ చేయబడింది.",
  Skip_:"దాటవేయి",
  Skip_to_Dashboard:"డాష్‌బోర్డ్‌కి వెళ్లండి",
  Dashboard:"డాష్బోర్డ్",
  Import:"దిగుమతి",
  Admin:"నిర్వాహకులు",
  New_Admin:"కొత్త అడ్మిన్",
  Settings:"సెట్టింగ్‌లు",
  Color_Picker:"రంగు ఎంపిక",
  Font_Picker:"ఫాంట్ పికర్",
  Logout:"లాగ్అవుట్",
  Close:"దగ్గరగా",
  Close_menu:"దగ్గరగా",
  Excel:"ఎక్సెల్ ఫైల్",
  Csv:"CSV ఫైల్",
  Sql:"SQL ఫైల్",
  Json:"JSON ఫైల్",
  Url:"URL ద్వారా దిగుమతి చేయండి",
  Back:"వెనుకకు",
  Timers:"టైమర్‌లు",
  Cheque:"తనిఖీ",
  Print:"ముద్రణ",
  Designs:"డిజైన్లు",
  Pause_Printing:"ముద్రణను పాజ్ చేయండి",
  Resume_Printing:"ప్రింటింగ్ పునఃప్రారంభించండి",
  Printing_Paused:"ప్రింటింగ్ పాజ్ చేయబడింది",
  PrintingUnavailable:"క్షమించండి! ఈ సిస్టమ్‌లో ప్రింటింగ్ అందుబాటులో లేదు.",
  Prints_Paused:"ప్రింట్లు పాజ్ చేయబడ్డాయి",
  Administration:"పరిపాలన",
  Loading:"లోడ్",
  Unnamed:"పేరు పెట్టలేదు",
  error:"క్షమించండి, ఈ చెక్ వీక్షించడానికి తెరవబడలేదు.",
  No_Cheques_To_Print:"ముద్రించడానికి చెక్కులు లేవు",
  No_Cheques_To_Print_Word:"<a routerLink='/folder/Cheque/New'>కొత్త చెక్</a>ని సృష్టించండి.",
  New_Cheque:"కొత్త చెక్",
  Start_One_Now:"ఇప్పుడే ఒకటి ప్రారంభించండి",
  Edit_Cheque:"తనిఖీని సవరించండి",
  Select_Cheques:"తనిఖీలను ఎంచుకోండి",
  Select_Employee:"ఉద్యోగిని ఎంచుకోండి",
  Default_Printer:"డిఫాల్ట్ ప్రింటర్",
  Reassign:"మళ్లీ కేటాయించండి",
  Configure:"కాన్ఫిగర్ చేయండి",
  Template:"మూస",
  Designer:"రూపకర్త",
  Edit_Designs:"డిజైన్లను సవరించండి",
  New_Design:"కొత్త డిజైన్",
  Next:"తరువాత",
  Save:"సేవ్ చేయండి",
  Name:"పేరు",
  Mail:"మెయిల్",
  Amount:"మొత్తం",
  Date:"తేదీ",
  PayPal:"పేపాల్",
  Payouts:"చెల్లింపులు",
  PayPal_Label:"పేపాల్ లేబుల్",
  Email_Username:"ఇమెయిల్ / వినియోగదారు పేరు",
  Client_ID:"క్లయింట్ ID",
  Sandbox_Email:"శాండ్‌బాక్స్ ఇమెయిల్",
  PayPal_Email:"పేపాల్ ఇమెయిల్",
  PayPal_Username:"API వినియోగదారు పేరు",
  PayPal_Payouts:"పేపాల్ చెల్లింపులు",
  Select_PayPal_Key:"పేపాల్ కీని ఎంచుకోండి",
  Secret:"రహస్యం",
  API_Secret:"API రహస్యం",
  PayPal_API_Keys:"పేపాల్ కీలు",
  New_PayPal_Key:"కొత్త పేపాల్ కీ",
  Email_Subject:"ఇమెయిల్ విషయం",
  Email_Message:"ఇమెయిల్ సందేశం",
  Payout_Options:"చెల్లింపు ఎంపికలు",
  Payment_Note:"చెల్లింపు గమనిక",
  Enable_Employee:"ఉద్యోగిని ప్రారంభించండి",
  Enable_Production_Mode:"ఉత్పత్తి మోడ్‌ని ప్రారంభించండి",
  Sandbox_Username:"శాండ్‌బాక్స్ వినియోగదారు పేరు",
  Sandbox_Signature:"శాండ్‌బాక్స్ సంతకం",
  Sandbox_Password:"శాండ్‌బాక్స్ పాస్‌వర్డ్",
  Production_Username:"ఉత్పత్తి వినియోగదారు పేరు",
  Production_Signature:"ఉత్పత్తి సంతకం",
  Production_Password:"ఉత్పత్తి పాస్వర్డ్",
  Production_Email:"ఉత్పత్తి ఇమెయిల్",
  API_Client_ID:"API క్లయింట్ ID",
  API_Signature:"API సంతకం",
  API_Password:"API పాస్‌వర్డ్",
  API_Username:"API వినియోగదారు పేరు",
  Secret_Key:"రహస్య కీ",
  Sandbox:"శాండ్‌బాక్స్",
  Production:"ఉత్పత్తి",
  Sandbox_Keys:"శాండ్‌బాక్స్ కీలు",
  Production_Keys:"ఉత్పత్తి కీలు",
  API_Title:"API శీర్షిక",
  Production_Mode:"ఉత్పత్తి మోడ్",
  Account_Label:"ఖాతా లేబుల్",
  No_PayPal_Setup:"పేపాల్ కీ లేదు",
  Enable_PayPal_Account:"పేపాల్ ఖాతాను ప్రారంభించండి",
  No_PayPal_Word:"మీ <a routerLink='/folder/Invoice/New'>పేపాల్ API కీ</a>ని జోడించండి.",
  Printed_Memo:"ముద్రించిన మెమో",
  Employee:"ఉద్యోగి",
  View_Employee:"ఉద్యోగిని చూడండి",
  Mailing_Address:"మెయిలింగ్ చిరునామా",
  Company_Address:"కంపెనీ చిరునామా",
  Select_Company_Address:"కంపెనీ చిరునామాను ఎంచుకోండి",
  Address:"చిరునామా",
  Any_Day:"ఏ రోజైనా",
  Address_Name:"చిరునామా పేరు",
  Unit:"యూనిట్",
  Account:"ఖాతా",
  Bank_Account:"బ్యాంకు ఖాతా",
  Account_Limits:"ఖాతా పరిమితులను ప్రారంభించండి",
  Payroll:"పేరోల్",
  Run:"పరుగు",
  Run_Payroll:"పేరోల్‌ని అమలు చేయండి",
  New_Payroll:"కొత్త పేరోల్",
  No_Payroll:"రాబోయే పేరోల్ లేదు",
  Upcoming_Holiday:"రాబోయే సెలవుదినం:",
  Invoice_Due:"ఇన్వాయిస్ బకాయి:",
  New_Invoice:"కొత్త ఇన్వాయిస్",
  No_Invoices:"ఇన్‌వాయిస్‌లు లేవు",
  No_Invoices_Word:"మొదటి <a routerLink='/folder/Invoice/New'>ఇన్వాయిస్</a>ని సృష్టించండి.",
  Cheque_Due:"బకాయిని తనిఖీ చేయండి:",
  Payrolls:"పేరోల్స్",
  Sandbox_Mode:"శాండ్‌బాక్స్ మోడ్",
  Hire:"కిరాయి",
  Pay:"చెల్లించండి",
  New:"కొత్తది",
  Add:"జోడించు",
  Make:"తయారు చేయండి",
  Time:"సమయం",
  Write:"వ్రాయడానికి",
  Holiday:"సెలవు",
  Holidays:"సెలవులు",
  Next_Holiday:"తదుపరి సెలవు:",
  All_Done:"అంతా పూర్తయింది!",
  Employees:"ఉద్యోగులు",
  Payees:"చెల్లింపుదారులు",
  Job:"ఉద్యోగం",
  Jobs:"ఉద్యోగాలు",
  Invoice:"ఇన్వాయిస్",
  Invoices:"ఇన్‌వాయిస్‌లు",
  Vacations:"సెలవులు",
  Cheques:"తనిఖీలు",
  Brand_Cheques:"మీ బ్రాండ్",
  Payment:"చెల్లింపు",
  Enable_Payment:"చెల్లింపును ప్రారంభించండి",
  Payments:"చెల్లింపులు",
  Schedule:"షెడ్యూల్",
  Schedules:"షెడ్యూల్స్",
  Timesheet:"సమయ పట్టిక",
  Timesheets:"టైమ్ షీట్లు",
  Salary:"జీతం",
  New_Address:"కొత్త చిరునామా",
  Address_2:"చిరునామా లైన్ 2)",
  _City:"నగరం",
  _State:"రాష్ట్రం/ప్రొవ్",
  City:"నగరం / టౌన్‌షిప్",
  State:"రాష్ట్రం / ప్రావిన్స్",
  Postal:"పోస్టల్ / జిప్ కోడ్",
  ZIP:"పోస్టల్ / జిప్",
  Country:"దేశం",
  Addresses:"చిరునామాలు",
  Required_Options:"అవసరమైన ఎంపికలు",
  Optional_Options:"ఐచ్ఛిక ఎంపికలు",
  Additional_Options:"అదనపు ఎంపికలు",
  Required:"అవసరం",
  Optional:"ఐచ్ఛికం",
  Additional:"అదనపు",
  No_Addresses:"చిరునామాలు లేవు",
  New_Address_Word:"మొదటి <a routerLink='/folder/Address/New'>చిరునామా</a>ని జోడించండి.",
  Select_Address:"చిరునామాను ఎంచుకోండి",
  No_Address:"చిరునామాలు లేవు",
  Print_Cheque:"ప్రింట్ చెక్",
  Print_Cheques:"తనిఖీలను ముద్రించండి",
  Print_Cheque_Now:"ప్రింట్ చెక్ ఇప్పుడు",
  Description:"వివరణ",
  Pay_To_The_Order_Of:"ఆర్డర్‌కు చెల్లించండి:",
  Select_Date_Range:"తేదీ పరిధిని ఎంచుకోండి",
  Select_Starting_Date:"ప్రారంభ తేదీని ఎంచుకోండి",
  Select_Ending_Date:"ముగింపు తేదీని ఎంచుకోండి",
  Select_Date:"తేదీని ఎంచుకోండి",
  Cheque_Date:"తేదీని తనిఖీ చేయండి",
  Cheque_Memo:"మెమోని తనిఖీ చేయండి",
  Blank_Cheque:"ఖాళీ చెక్",
  Blank:"ఖాళీ",
  No_Cheques:"తనిఖీలు లేవు",
  No_Cheques_Word:"మీ మొదటి <a routerLink='/folder/Cheque/New'>చెక్</a>ని ప్రింట్ చేయండి.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"చిత్రాన్ని వీక్షించండి",
  View:"చూడండి",
  Modify:"సవరించు",
  Delete:"తొలగించు",
  Cheque_Paid:"చెల్లించారు",
  New_Deduction:"కొత్త తగ్గింపు",
  Title:"శీర్షిక",
  Frequency:"తరచుదనం",
  Hourly:"గంటకోసారి",
  Daily:"రోజువారీ",
  Weekdays:"వారం రోజులు",
  BiWeekly:"2 వారాల",
  TriWeekly:"3 వారాలు",
  Monthly:"నెలవారీ",
  MiMonthly:"2 నెలల",
  Quarterly:"త్రైమాసిక",
  Yearly:"సంవత్సరానికి",
  Every_Week:"ప్రతీ వారం",
  Every_Payroll:"ప్రతి పేరోల్",
  Every_Month:"ప్రతి నెల",
  Annually:"వార్షికంగా",
  Always_Scheduled:"ఎల్లప్పుడూ షెడ్యూల్ చేయబడింది",
  Start_Date:"ప్రారంబపు తేది",
  End_Date:"ఆఖరి తేది",
  Start_Time:"ప్రారంభ సమయం",
  End_Time:"ముగింపు సమయం",
  Deduction_Label:"తగ్గింపు లేబుల్",
  Notes:"గమనికలు",
  Options:"ఎంపికలు",
  Enable:"ప్రారంభించు",
  Select_Deductions:"తగ్గింపులను ఎంచుకోండి",
  Deductions:"తగ్గింపులు",
  No_Deductions:"తగ్గింపులు లేవు",
  No_Deductions_Word:"మీ మొదటి <a routerLink='/folder/Deduction/New'>తగ్గింపు</a>ని సృష్టించండి.",
  New_Employee:"కొత్త ఉద్యోగి",
  No_Title:"టైటిల్ లేదు",
  _Name:"పేరు",
  About_Yourself:"నా గురించి",
  Full_Name:"పూర్తి పేరు",
  Birthday:"పుట్టినరోజు",
  Email:"ఇమెయిల్",
  SMS:"SMS",
  Phone:"ఫోన్",
  Test:"పరీక్ష",
  Call:"కాల్ చేయండి",
  Fax:"ఫ్యాక్స్",
  Printed_Note:"ప్రింటెడ్ నోట్",
  Position:"స్థానం",
  Job_Position:"ఉద్యోగ స్థానం",
  Select_a_Job:"ఉద్యోగాన్ని ఎంచుకోండి",
  Select_a_Salary:"జీతం ఎంచుకోండి",
  Add_a_Deduction:"తగ్గింపును జోడించండి",
  Taxes:"పన్నులు",
  Add_Taxes:"పన్నులను జోడించండి",
  Date_of_Birth:"పుట్టిన తేది",
  Email_Address:"ఇమెయిల్ చిరునామా",
  Phone_Number:"ఫోను నంబరు",
  Phone_Call:"ఫోన్ కాల్",
  Enable_on_Payroll:"పేరోల్‌లో ప్రారంభించండి",
  Select_Employees:"ఉద్యోగులను ఎంచుకోండి",
  No_Employees:"ఉద్యోగులు లేరు",
  No_Employees_Word:"మీ మొదటి కొత్త <a routerLink='/folder/Employee/New'>ఉద్యోగిని</a> జోడించండి.",
  No_Name:"పేరు లేదు",
  Unemployeed:"నిరుద్యోగులు",
  Employeed:"ఉపాధి పొందారు",
  Paid:"చెల్లించారు",
  Enabled:"ప్రారంభించబడింది",
  Disabled:"వికలాంగుడు",
  Fire:"అగ్ని",
  Not_Available:"అందుబాటులో లేదు",
  Not_Available_Word:"మీ మొదటి <a routerLink='/folder/Invoice/New'>ఇన్‌వాయిస్‌</a>ని ప్రింట్ చేసి చెల్లించండి.",
  Select_Invoices:"ఇన్‌వాయిస్‌లను ఎంచుకోండి",
  Print_Invoice_Word:"మీ మొదటి <a routerLink='/folder/Invoice/New'>ఇన్‌వాయిస్‌</a>ని ప్రింట్ చేసి చెల్లించండి.",
  Invoice_Title:"ఇన్వాయిస్ శీర్షిక",
  Invoice_Date:"చలానా తారీకు",
  Due_Date:"గడువు తేది",
  New_Job:"కొత్త ఉద్యోగం",
  Details:"వివరాలు",
  Customize:"అనుకూలీకరించండి",
  Customize_Dashboard:"డాష్‌బోర్డ్‌ని అనుకూలీకరించండి",
  Components:"భాగాలు",
  No_Components:"భాగాలు లేవు",
  Main_Components:"ప్రధాన భాగాలు",
  Smaller_Components:"చిన్న భాగాలు",
  Error_Loading_Page:"ఈ పేజీని లోడ్ చేయడంలో లోపం.",
  Bank_Details:"బ్యాంక్ వివరములు",
  About_Your_Job:"మీ ఉద్యోగం గురించి",
  Your_Schedule:"మీ షెడ్యూల్",
  Job_Title:"ఉద్యోగ శీర్షిక",
  Job_Description:"ఉద్యోగ వివరణ",
  Job_Details:"ఉద్యోగ వివరాలు",
  Enable_Job:"ఉద్యోగాన్ని ప్రారంభించండి",
  Pay_Period:"చెల్లింపు వ్యవధి",
  Perpetually_Schedule:"శాశ్వతంగా షెడ్యూల్ చేయండి",
  Select_Jobs:"ఉద్యోగాలను ఎంచుకోండి",
  No_Jobs:"ఉద్యోగాలు లేవు",
  Add_Jobs:"ఉద్యోగాలను జోడించండి",
  No_Jobs_Word:"జాబితాకు మొదటి <a routerLink='/folder/Job/New'>ఉద్యోగం</a>ని జోడించండి.",
  Count_Employees:"job.employee_count+'ఉద్యోగులు'",
  Zero_Employees:"0 ఉద్యోగులు",
  New_Leave:"కొత్త సెలవు",
  Leave_Name:"పేరు వదిలివేయండి",
  Paid_Leave:"చెల్లింపు సెలవు",
  Leave_Pay:"లీవ్ పే",
  Indefinite_Leave:"నిరవధిక సెలవు",
  Indefinite_Payroll:"నిరవధిక పేరోల్",
  Leave:"వదిలేయండి",
  Add_Schedules:"షెడ్యూల్‌లను జోడించండి",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"లేకపోవడం ప్రారంభించండి",
  Select_Leaves:"ఆకులను ఎంచుకోండి",
  No_Leaves:"గైర్హాజరీ లేదు",
  Leave_Of_Absence:"హాజరు కాకపోవడం వల్ల సెలవు",
  Leaves_Of_Absence:"లేకపోవడం ఆకులు",
  New_Leave_of_Absense:"గైర్హాజరీకి కొత్త సెలవు",
  No_Leaves_Word:"మొదటి <a routerLink='/folder/Leave/New'>లేవ్ ఆఫ్ ఎసెన్స్</a>ని జోడించండి.",
  Not_Enabled:"ప్రారంభించబడలేదు",
  Absences:"గైర్హాజరు",
  Payee:"చెల్లింపుదారు",
  New_Payee:"కొత్త చెల్లింపుదారు",
  Payee_Identifier:"చెల్లింపుదారు ఐడెంటిఫైయర్",
  Payee_Name:"చెల్లింపుదారు పేరు",
  Payee_Title:"చెల్లింపుదారు శీర్షిక",
  Payment_Memo:"చెల్లింపు మెమో",
  Select_Payees:"చెల్లింపుదారులను ఎంచుకోండి",
  No_Payees:"చెల్లింపుదారులు లేరు",
  Add_Payee_Note:"మొదటి <a routerLink='/folder/Payee/New'>చెల్లింపుదారు</a>ని జోడించండి.",
  New_Payees:"కొత్త చెల్లింపుదారులు",
  About_The_Payment_Schedule:"చెల్లింపు షెడ్యూల్",
  Your_Payroll_Schedule:"ఆటోమేటిక్ పేరోల్",
  New_Payment:"కొత్త చెల్లింపు",
  Identifier:"ఐడెంటిఫైయర్",
  Selected:"ఎంపిక చేయబడింది",
  Select:"ఎంచుకోండి",
  Memo:"మెమో",
  Payment_Date:"చెల్లింపు తేదీ",
  Mark_as_Paid:"చెల్లించినట్లుగా గుర్తించండి",
  Select_Payments:"చెల్లింపులను ఎంచుకోండి",
  No_Payments:"చెల్లింపులు లేవు",
  No_Payments_Word:"మొదటి <a routerLink='/folder/Payment/New'>చెల్లింపు</a>ని సృష్టించండి.",
  Create_Payroll:"పేరోల్ సృష్టించండి",
  Properties:"లక్షణాలు",
  Payroll_Title:"పేరోల్ శీర్షిక",
  Payroll_Notes:"పేరోల్ నోట్స్",
  Payroll_Setup:"పేరోల్ సెటప్",
  Tabulate_Payments:"చెల్లింపులను పట్టిక చేయండి",
  Tabulate:"పట్టిక చేయండి",
  By:"ద్వారా:",
  Payments_By:"ద్వారా చెల్లింపులు",
  Timesheets_And_Schedules:"టైమ్ షీట్‌లు & షెడ్యూల్‌లు",
  Both:"రెండు",
  Items:"వస్తువులు",
  Add_Payees:"చెల్లింపుదారులను జోడించండి",
  Add_Account:"ఖాతా జోడించండి",
  Enable_Account:"ఖాతాను ప్రారంభించండి",
  Enable_Payroll:"పేరోల్‌ని ప్రారంభించండి",
  Print_Payroll:"పేరోల్‌ను ముద్రించండి",
  No_Payrolls:"పేరోల్ లేదు",
  No_Payroll_Word:"మీ మొదటి <a routerLink='/folder/Payroll/New'>చెల్లింపు</a>ని సృష్టించండి.",
  View_more:"మరిన్ని చూడండి",
  Less:"తక్కువ",
  Add_Payroll:"పేరోల్ జోడించండి",
  Select_Payroll:"పేరోల్‌ని ఎంచుకోండి",
  About_Your_Salary:"మీ జీతం గురించి",
  Add_Salaries:"జీతాలు జోడించండి",
  Add_Salary:"జీతం జోడించండి",
  Salaries:"జీతాలు",
  No_Salaries:"జీతాలు లేవు",
  No_Salaries_Word:"మొదటి <a routerLink='/folder/Salary/New'>జీతం</a>ని జోడించండి.",
  Select_Salaries:"జీతాలు ఎంచుకోండి",
  New_Salary:"కొత్త జీతం",
  Salary_Name:"జీతం ఐడెంటిఫైయర్",
  Enable_Salary:"జీతం ప్రారంభించండి",
  Salary_Amount:"జీతం మొత్తం",
  New_Schedule:"కొత్త షెడ్యూల్",
  Schedule_Title:"షెడ్యూల్ శీర్షిక",
  Add_Address:"చిరునామాను జోడించండి",
  Repeat:"పునరావృతం చేయండి",
  Design:"రూపకల్పన",
  Edit_Design:"డిజైన్‌ని సవరించండి",
  Edit_this_Design:"ఈ డిజైన్‌ను సవరించండి",
  Repeat_Payment:"చెల్లింపును పునరావృతం చేయండి",
  Enable_Schedule:"షెడ్యూల్‌ని ప్రారంభించండి",
  Never:"ఎప్పుడూ",
  Select_Schedule:"షెడ్యూల్‌లను ఎంచుకోండి",
  No_Schedules:"షెడ్యూల్‌లు లేవు",
  No_Schedules_Word:"మొదటి <a routerLink='/folder/Schedule/New'>షెడ్యూల్</a>ని సృష్టించండి.",
  New_Administrator:"కొత్త అడ్మినిస్ట్రేటర్",
  Username:"వినియోగదారు పేరు",
  First_Name:"మొదటి పేరు",
  Last_Name:"చివరి పేరు",
  Add_an_Address:"చిరునామాను జోడించండి",
  Payment_Limit:"ప్రతి-చెల్లింపు పరిమితి",
  Total_Limit:"మొత్తం పరిమితి",
  Select_Accounts:"ఖాతాలను ఎంచుకోండి",
  No_Administrators:"నిర్వాహకులు లేరు",
  No_Administrators_Word:"మొదటి <a routerLink='/folder/Administrator/New'>అడ్మిన్ ఖాతా</a>ని సృష్టించండి.",
  New_Administrators_Word:"మొదటి <a routerLink='/folder/Administrator/New'>నిర్వాహకుడు</a>ని జోడించండి",
  Cloud:"మేఘం",
  Backup:"బ్యాకప్",
  Enable_iCloud:"iCloudని ప్రారంభించండి",
  Enable_Google_Drive:"Google డిస్క్‌ని ప్రారంభించండి",
  Enable_Microsoft_OneDrive:"Microsoft OneDriveని ప్రారంభించండి",
  Automatically_Backup:"స్వయంచాలకంగా బ్యాకప్ చేయండి",
  FTP_Settings:"FTP సెట్టింగ్‌లు",
  URL_File_Prompt:"దయచేసి దిగుమతి చేయడానికి .xls / .xlsx / .json ఫైల్ కోసం స్థానాన్ని పేర్కొనండి:",
  URL_SQL_Prompt:"దయచేసి దిగుమతి చేయడానికి SQLite ఫైల్ స్థానాన్ని పేర్కొనండి:",
  FTP_Backup:"FTP బ్యాకప్",
  FTP_Import:"FTP దిగుమతి",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"హోస్ట్",
  Port:"పోర్ట్",
  Path:"మార్గం",
  Data_Path:"డేటా మార్గం",
  Enable_FTP_Account:"FTP ఖాతాను ప్రారంభించండి",
  HostnameIP:"హోస్ట్-పేరు",
  Password:"పాస్వర్డ్",
  Connection_Port:"కనెక్షన్ పోర్ట్",
  Enable_MySQL_Database:"MySQL డేటాబేస్‌ని ప్రారంభించండి",
  Log:"లాగ్",
  Reset:"రీసెట్ చేయండి",
  Erase:"తుడిచివేయండి",
  Export:"ఎగుమతి చేయండి",
  Database:"డేటాబేస్",
  Upload_CSV:"CSVని అప్‌లోడ్ చేయండి",
  Download_CSV:"CSVని డౌన్‌లోడ్ చేయండి",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL బ్యాకప్",
  Internal_Notes:"అంతర్గత గమనికలు",
  Root_Path:"రూట్ పాత్",
  Select_Backup:"బ్యాకప్ ఎంచుకోండి",
  Add_New_Backup:"కొత్త బ్యాకప్‌ని జోడించండి",
  First_Backup:"మొదటి బ్యాకప్‌ని సెటప్ చేయండి...",
  Backups:"బ్యాకప్‌లు",
  Add_Backup:"బ్యాకప్ జోడించండి",
  No_Backups:"కనుగొనడానికి బ్యాకప్‌లు ఏవీ లేవు.",
  Select_Backup_Type:"మీరు సెటప్ చేయాలనుకుంటున్న బ్యాకప్ రకాన్ని ఎంచుకోండి...",
  Backup_Type:"బ్యాకప్ రకం",
  FTP_Drive:"FTP డ్రైవ్",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"డ్రైవ్",
  Microsoft_OneDrive:"డ్రైవ్",
  Import_Fields:"ఫీల్డ్‌లను దిగుమతి చేయండి",
  Import_Fields_Word:"<a routerLink='/folder/Holiday/New'>దిగుమతి</a> డేటాను ఎంచుకోవడానికి ఈ విభాగాన్ని ఉపయోగించండి.",
  Upload:"అప్‌లోడ్ చేయండి",
  Download:"డౌన్‌లోడ్ చేయండి",
  Download_JSON:"JSON డేటాగా డౌన్‌లోడ్ చేయండి",
  Download_SQL:"SQL ఫైల్‌గా డౌన్‌లోడ్ చేయండి",
  New_Bank:"కొత్త బ్యాంక్",
  New_Account:"కొత్త ఖాతా",
  New_Bank_Account:"కొత్త బ్యాంక్ ఖాతా",
  Upload_Image:"చిత్రాన్ని అప్‌లోడ్ చేయండి",
  Bank_Name:"బ్యాంక్ పేరు",
  Bank_Address:"బ్యాంకు చిరునామా",
  Branch_Address:"శాఖ చిరునామా",
  Address_on_Cheque:"చిరునామా",
  Cheque_Numbers:"సంఖ్యలను తనిఖీ చేయండి",
  Cheque_Details:"వివరాలను తనిఖీ చేయండి",
  Bank_Logo:"బ్యాంక్ లోగో",
  Cheque_ID:"IDని తనిఖీ చేయండి",
  Starting_Cheque_ID:"చెక్ IDని ప్రారంభిస్తోంది",
  Transit_Number:"రవాణా సంఖ్య",
  Institution_Number:"సంస్థ సంఖ్య",
  Designation_Number:"హోదా సంఖ్య",
  Account_Number:"ఖాతా సంఖ్య",
  Limits:"పరిమితులు",
  Default_Limits:"డిఫాల్ట్ పరిమితులు",
  Over_Limit:"పరిమితికి మించి",
  Under_Limit:"పరిమితి కింద",
  Payroll_Limit:"పేరోల్ పరిమితి",
  Enable_Bank_Account:"బ్యాంక్ ఖాతాను ప్రారంభించండి",
  Select_Account:"ఖాతాను ఎంచుకోండి",
  No_Bank_Account:"బ్యాంక్ ఖాతా లేదు",
  No_Bank_Account_Word:"మొదటి <a routerLink='/folder/Accounts/New'>బ్యాంక్ ఖాతా</a>ని జోడించండి.",
  Bank_Accounts:"బ్యాంకు ఖాతాల",
  No_Accounts:"ఖాతాలు లేవు",
  No_Accounts_Note:"మొదటి <a routerLink='/folder/Accounts/New'>బ్యాంక్ ఖాతా</a>ని జోడించండి.",
  Cheque_Designer:"డిజైనర్‌ని తనిఖీ చేయండి",
  Cheque_Design:"డిజైన్‌ని తనిఖీ చేయండి",
  Select_Design:"డిజైన్‌ని ఎంచుకోండి",
  Cheque_Designs:"డిజైన్లను తనిఖీ చేయండి",
  No_Cheque_Designs:"డిజైన్‌లను తనిఖీ చేయవద్దు",
  No_Cheque_Designs_Word:"మీ స్వంత <a routerLink='/folder/Settings/Cheque/Design/New'>డిజైన్‌ని తనిఖీ చేయండి</a>ని సృష్టించండి.",
  Set_Default:"ఎధావిధిగా ఉంచు",
  Default:"డిఫాల్ట్",
  Remove:"తొలగించు",
  Folder:"ఫోల్డర్",
  Edit:"సవరించు",
  LoadingDots:"లోడ్...",
  Add_a_New_File:"దీనికి <a href='#' (click)='add()'>కొత్త ఫైల్</a>ని జోడించండి",
  this_folder:"ఈ ఫోల్డర్",
  FTP_Backup_Settings:"FTP బ్యాకప్ సెట్టింగ్‌లు",
  Secure_File_Transfer:"సురక్షిత ఫైల్ బదిలీ",
  New_Holiday:"కొత్త సెలవుదినం",
  Add_Holiday:"హాలిడేని జోడించండి",
  Holiday_Name:"సెలవు పేరు",
  Additional_Pay:"అదనపు చెల్లింపు",
  Enable_Holiday:"హాలిడేని ప్రారంభించండి",
  Select_Holidays:"సెలవులను ఎంచుకోండి",
  No_Holidays:"సెలవులు లేవు",
  No_Holidays_Word:"మొదటి <a routerLink='/folder/Holiday/New'>పబ్లిక్ హాలిడే</a>ని జోడించండి.",
  New_Import:"కొత్త దిగుమతి",
  Import_Data:"డేటాను దిగుమతి చేయండి",
  Import_Data_Word:"మీరు ఎంచుకున్న ఫైల్ రకాన్ని లేదా అప్‌లోడ్ పద్ధతిని ఎంచుకోండి.<br /> మద్దతు ఉన్న ఫైల్‌ను అప్‌లోడ్ చేసిన తర్వాత యాప్‌లోని ఏ పరామితికి అనుగుణంగా ఫైల్‌లో దిగుమతి చేసుకున్న ఫీల్డ్‌లను మీరు ఎంచుకోగలరు.",
  Import_File:"ఫైల్‌ను దిగుమతి చేయండి",
  Link_To_File:"ఫైల్‌కి లింక్ చేయండి",
  Select_File:"ఫైల్‌ని ఎంచుకోండి",
  New_Moderator:"కొత్త మోడరేటర్",
  Allow_Moderation:"మోడరేషన్‌ని అనుమతించండి",
  Create_Paycheques:"చెల్లింపు చెక్కులను సృష్టించండి",
  Edit_Paycheques_and_Data:"పేచెక్‌లు మరియు డేటాను సవరించండి",
  Destroy_Data_and_Paycheques:"డేటా మరియు పేచెక్‌లను నాశనం చేయండి",
  Bonus_Percentage:"పేచెక్ శాతం",
  Fixed_Amount:"స్థిర మొత్తం",
  Select_Moderator:"మోడరేటర్‌ని ఎంచుకోండి",
  No_Moderators:"మోడరేటర్లు లేరు",
  Moderators:"మోడరేటర్లు",
  Moderator_Account:"మొదటి <a routerLink='/folder/Administrator/New'>మోడరేటర్ ఖాతా</a>ని సృష్టించండి.",
  No_Moderators_Word:"మొదటి <a routerLink='/folder/Administrator/New'>మోడరేటర్</a>ని జోడించండి.",
  Defaults:"డిఫాల్ట్‌లు",
  Provide_Us_Info:"సమాచారం అందించండి",
  Setup_Your_Printer:"మీ ప్రింటర్‌ని సెటప్ చేయండి",
  Your_Company:"మీ కంపెనీ గురించి",
  Company_Name:"కంపెనీ పేరు",
  Currency:"కరెన్సీ",
  Default_Currency:"డిఫాల్ట్ కరెన్సీ",
  Base_Monthly_Income:"నెలవారీ ఆదాయం",
  Protection:"రక్షణ",
  App_Protection:"యాప్ రక్షణ",
  PIN_Code_Protection:"పిన్ కోడ్ రక్షణ",
  App_Protection_Word:"మీ ఖాతాను రక్షించడంలో సహాయపడే భద్రతా పద్ధతులను ప్రారంభించండి.",
  PIN_Code:"పిన్ కోడ్",
  Change_PIN:"పిన్ మార్చండి",
  New_Password:"కొత్త పాస్వర్డ్",
  Geofence_Protection:"జియో-ఫెన్స్ రక్షణ",
  Geofence_Area:"ప్రాంతాన్ని సెట్ చేయండి",
  Distance:"దూరం",
  Setup_Now:"ఇప్పుడు సెటప్ చేయండి",
  Mile:"మైలు",
  Km:"కి.మీ",
  m:"m",
  Facial_Recognition:"ముఖ గుర్తింపు",
  Face:"ముఖం",
  Setup:"సెటప్",
  Label:"లేబుల్",
  Password_Protection:"పాస్వర్డ్ రక్షణ",
  Modify_Password:"పాస్‌వర్డ్‌ని సవరించండి",
  New_Tax_Entry:"కొత్త పన్ను ప్రవేశం",
  New_Tax:"కొత్త పన్ను",
  Tax_Label:"పన్ను లేబుల్",
  Perpetually_Enabled:"శాశ్వతంగా ప్రారంభించబడింది",
  Select_Taxes:"పన్నులను ఎంచుకోండి",
  Tax_Deductions:"పన్ను మినహాయింపులు",
  No_Tax_Deductions:"పన్ను మినహాయింపులు లేవు",
  No_Tax_Deductions_Word:"మొదటి <a routerLink='/folder/Tax/New'>పన్ను</a> మినహాయింపును జోడించండి.",
  New_Timer:"కొత్త టైమర్",
  Start:"ప్రారంభించండి",
  Stop:"ఆపు",
  Start_Timer:"టైమర్‌ని ప్రారంభించండి",
  Stop_Timer:"టైమర్‌ని ఆపు",
  Timer_Active:"టైమర్ యాక్టివ్",
  Timer:"టైమర్:",
  Timer_Running:"టైమర్: (రన్నింగ్)",
  Save_Timer:"టైమర్‌ను సేవ్ చేయండి",
  New_Timesheet:"కొత్త టైమ్ షీట్",
  Select_Timesheets:"టైమ్ షీట్‌లను ఎంచుకోండి",
  Work_Notes:"పని గమనికలు",
  Entry_Title:"ఎంట్రీ టైటిల్",
  No_Timesheets:"టైమ్ షీట్‌లు లేవు",
  No_Timesheets_Word:"మొదటి <a routerLink='/folder/Timesheet/New'>టైమ్ షీట్</a>ని జోడించండి.",
  Timesheet_Submitted:"టైమ్ షీట్ సమర్పించబడింది",
  Timesheet_Congrats:"అభినందనలు! మీ టైమ్ షీట్ విజయవంతంగా సమర్పించబడింది. ధన్యవాదాలు!",
  Timesheet_Copy:"మీ పత్రాల కాపీని స్వీకరించడానికి దయచేసి మీ ఇమెయిల్ చిరునామా మరియు/లేదా మొబైల్ ఫోన్ నంబర్‌ను మాకు అందించండి.",
  Submit:"సమర్పించండి",
  Allow_Notifications:"నోటిఫికేషన్‌లను అనుమతించండి",
  Untitled_Entry:"కొత్త ప్రవేశం",
  Untitled_Bank:"పేరులేని బ్యాంక్",
  Timesheet_Entry:"టైమ్ షీట్ ఎంట్రీ",
  Work_Description:"పని వివరణ",
  Enable_Timesheet:"టైమ్ షీట్‌ని ప్రారంభించండి",
  Submit_Timesheet:"టైమ్ షీట్ సమర్పించండి",
  Vacation:"సెలవు",
  New_Vacation:"కొత్త సెలవు",
  Vacation_Name:"సెలవు పేరు",
  Paid_Vacation:"చెల్లింపు సెలవు",
  Vacation_Pay:"సెలవు చెల్లింపు",
  Enable_Vacation:"సెలవును ప్రారంభించండి",
  Select_Vacations:"సెలవులను ఎంచుకోండి",
  No_Vacations:"సెలవులు లేవు",
  No_Vacations_Word:"మొదటి <a routerLink='/folder/Vacation/New'>సెలవు</a> ఎంట్రీని సృష్టించండి.",
  Payroll_Schedule:"పేరోల్ షెడ్యూల్",
  Next_Payroll:"తదుపరి పేరోల్:",
  Upcoming_Payroll:"రాబోయే పేరోల్",
  No_Upcoming_Payroll:"రాబోయే పేరోల్ లేదు",
  Address_Book:"చిరునామా పుస్తకం",
  Archived_Documents:"ఆర్కైవ్ చేసిన పత్రాలు",
  Dev_Mode:"అప్లికేషన్ డెవలప్‌మెంట్ మోడ్‌లో ఉంది",
  Administrators:"నిర్వాహకులు",
  Privacy:"గోప్యత",
  None:"ఏదీ లేదు",
  Select_Signature:"సంతకాన్ని ఎంచుకోండి",
  No_Signatures:"సంతకాలు లేవు",
  No_Signatures_Word:"మొదటి <a routerLink='/folder/Signature/New'>సంతకం</a>ని జోడించండి.",
  Repeat_Indefinitely:"నిరవధికంగా పునరావృతం చేయండి",
  Sign:"సంతకం చేయండి",
  Sign_Here:"ఇక్కడ సంతకం పెట్టండి",
  Date_Signed:"తేదీ సంతకం చేయబడింది",
  Signature_Pad:"సంతకం ప్యాడ్",
  Account_Holder:"ఖాతాదారుడు",
  Account_Properties:"ఖాతా లక్షణాలు",
  Name_On_Cheque:"చెక్‌లో పేరు",
  Server_Hostname:"సర్వర్ హోస్ట్-పేరు / IP",
  Printers:"ప్రింటర్లు",
  No_Printers:"ప్రింటర్లు లేవు",
  Printer_Exists:"ఈ పేరుతో ప్రింటర్ ఇప్పటికే ఉంది.",
  No_Printers_Word:"మొదటి <a routerLink='/folder/Printer/New'>ప్రింటర్</a>ని జోడించండి.",
  No_Printer_Alert:"ప్రింటర్ నిర్వచించబడలేదు. మీరు ప్రింటర్‌ని సెటప్ చేయాలనుకుంటున్నారా?",
  Add_Printer:"ప్రింటర్‌ని జోడించండి",
  New_Printer:"కొత్త ప్రింటర్",
  Printer_Hostname:"ప్రింటర్ హోస్ట్-పేరు / IP",
  Printer_Label:"ప్రింటర్ లేబుల్",
  Printer_Protocol:"ప్రింటర్ ప్రోటోకాల్",
  Protocol:"ప్రోటోకాల్",
  Email_Print:"ఇమెయిల్",
  AirPrint:"ఎయిర్‌ప్రింట్",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"సాకెట్",
  Print_Job:"ప్రింట్ జాబ్",
  Printed:"ముద్రించబడింది",
  Not_Printed:"ముద్రించబడలేదు",
  Print_Jobs:"ప్రింట్ ఉద్యోగాలు",
  Print_Queue:"ముద్రణ క్రమం",
  No_Print_Jobs:"ప్రింట్ జాబ్‌లు లేవు",
  No_Prints:"ముద్రించడానికి కొత్త <a routerLink='/folder/Cheque/New'>చెక్</a>ని సృష్టించండి.",
  Prints:"ప్రింట్లు",
  Find_Printer:"ప్రింటర్‌ని కనుగొనండి",
  Find_AirPrint_Devices:"ఎయిర్‌ప్రింట్ పరికరాలను కనుగొనండి",
  Select_Printer:"ప్రింటర్‌ని ఎంచుకోండి",
  System_UI:"సిస్టమ్ UI",
  Printer:"ప్రింటర్",
  Status:"స్థితి",
  Preview:"ప్రివ్యూ",
  Enable_Print_Job:"ప్రింట్ జాబ్‌ని ప్రారంభించండి",
  Queue_Weight:"క్యూ బరువు",
  Unlimited:"అపరిమిత",
  Show_Advanced_Printer_Options:"అధునాతన ప్రింటర్ ఎంపికలను చూపు",
  Advanced:"ఆధునిక",
  Location:"స్థానం",
  Note:"గమనిక",
  Queue_Name:"క్యూ పేరు",
  Pages_Printed_Limit:"పేజీల ముద్రిత పరిమితి",
  MultiPage_Idle_Time:"బహుళ-పేజీ నిరీక్షణ సమయం (లు)",
  Page_Count_Limit:"పేజీ గణన పరిమితి",
  Page_Orientation:"పేజీ ఓరియంటేషన్",
  Portrait:"చిత్తరువు",
  Landscape:"ప్రకృతి దృశ్యం",
  Duplex:"డ్యూప్లెక్స్",
  Double_Sided_Printing:"రెండు వైపులా",
  Duplex_Mode:"డ్యూప్లెక్స్ మోడ్",
  Duplex_Short:"పొట్టి",
  Duplex_Long:"పొడవు",
  Duplex_None:"ఏదీ లేదు",
  Color_And_Quality:"రంగు మరియు నాణ్యత",
  Monochrome:"మోనోక్రోమ్",
  Photo_Quality_Prints:"ఫోటో నాణ్యత ప్రింట్లు",
  Printer_Email:"ప్రింటర్ ఇమెయిల్",
  Automatically_Downsize:"స్వయంచాలకంగా తగ్గింపు",
  Paper:"పేపర్",
  Paper_Name:"పేపర్ పేరు",
  Paper_Width:"పేపర్ వెడల్పు",
  Paper_Height:"పేపర్ ఎత్తు",
  Paper_Autocut_Length:"పేపర్ ఆటో-కట్ పొడవు",
  Margins:"మార్జిన్లు",
  Page_Margins:"పేజీ మార్జిన్లు",
  Page_Margin_Top:"అగ్ర పేజీ మార్జిన్",
  Page_Margin_Right:"కుడి పేజీ మార్జిన్",
  Page_Margin_Bottom:"దిగువ పేజీ మార్జిన్",
  Page_Margin_Left:"ఎడమ పేజీ మార్జిన్",
  Add_Employees:"ఉద్యోగులను జోడించండి",
  Header:"హెడర్",
  Print_A_Page_Header:"పేజీ హెడర్‌ని ప్రింట్ చేయండి",
  Header_Label:"హెడర్ లేబుల్",
  Header_Page_Index:"హెడర్ పేజీ సూచిక",
  Header_Font_Name:"హెడర్ ఫాంట్",
  Select_Font:"ఫాంట్ ఎంచుకోండి",
  Font_Selector:"ఫాంట్ సెలెక్టర్",
  Header_Font_Size:"హెడర్ ఫాంట్",
  Header_Bold:"హెడర్ బోల్డ్",
  Header_Italic:"హెడర్ ఇటాలిక్",
  Header_Alignment:"హెడర్ అమరిక",
  Left:"ఎడమ",
  Center:"కేంద్రం",
  Right:"కుడి",
  Justified:"సమర్థించబడింది",
  Header_Font_Color:"శీర్షిక రంగు",
  Select_Color:"రంగును ఎంచుకోండి",
  Footer:"ఫుటర్",
  Print_A_Page_Footer:"పేజీ ఫుటర్‌ని ప్రింట్ చేయండి",
  Footer_Label:"ఫుటర్ లేబుల్",
  Footer_Page_Index:"ఫుటర్ పేజీ సూచిక",
  Footer_Font_Name:"ఫుటర్ ఫాంట్",
  Fonts:"ఫాంట్‌లు",
  Done:"పూర్తి",
  Select_Fonts:"ఫాంట్‌లను ఎంచుకోండి",
  Footer_Font_Size:"ఫుటర్ పరిమాణం",
  Footer_Bold:"ఫుటర్ బోల్డ్",
  Footer_Italic:"ఫుటర్ ఇటాలిక్",
  Footer_Alignment:"ఫుటర్ అమరిక",
  Footer_Font_Color:"ఫుటర్ రంగు",
  Page_Copies:"పేజీ కాపీలు",
  Enable_Printer:"ప్రింటర్‌ని ప్రారంభించండి",
  Remote_Logging:"రిమోట్ లాగింగ్",
  Log_Server:"లాగ్ సర్వర్",
  Encryption:"ఎన్క్రిప్షన్",
  Random_Key:"యాదృచ్ఛిక కీ",
  Encryption_Key:"ఎన్క్రిప్షన్ కీ",
  Cheques_Webserver:"కస్టమ్ డేటాబేస్",
  Learn_How:"ఎలాగో తెలుసుకోండి",
  Signature:"సంతకం",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"సంతకాన్ని వీక్షించండి",
  Printed_Signature:"ముద్రించిన సంతకం",
  Digitally_Sign:"డిజిటల్ సంతకం చేయండి",
  Digital_Signature:"డిజిటల్ సంతకం",
  Add_Signature:"సంతకాన్ని జోడించండి",
  Add_Your_Signature:"మీ సంతకాన్ని జోడించండి",
  Enable_Signature:"సంతకాన్ని ప్రారంభించండి",
  Digitally_Signed:"డిజిటల్ సంతకం",
  Insert_Error:"డేటాబేస్ సమస్యల కారణంగా చెక్‌ని సేవ్ చేయడం సాధ్యపడలేదు.",
  Delete_Confirmation:"మీరు ఖచ్చితంగా ఈ సమాచారాన్ని తొలగించాలనుకుంటున్నారా?",
  Discard_Confirmation:"మీరు ఖచ్చితంగా ఈ సమాచారాన్ని విస్మరించాలనుకుంటున్నారా?",
  Discard_Bank_Confirmation:"మీరు ఖచ్చితంగా ఈ ఖాతాను విస్మరించాలనుకుంటున్నారా?",
  Discard_Printer_Confirmation:"మీరు ఖచ్చితంగా ఈ ప్రింటర్‌ని విస్మరించాలనుకుంటున్నారా?",
  Delete_Bonus_Confirmation:"మీరు ఖచ్చితంగా ఈ బోనస్‌ని తొలగించాలనుకుంటున్నారా?",
  Delete_Invoice_Confirmation:"మీరు ఖచ్చితంగా ఈ ఇన్‌వాయిస్‌ని తొలగించాలనుకుంటున్నారా?",
  Generated_Cheque:"రూపొందించిన చెక్",
  Generated_Invoice:"ఇన్వాయిస్ రూపొందించబడింది",
  Schedule_Start_Time:"షెడ్యూల్ ప్రారంభం",
  Schedule_End_Time:"షెడ్యూల్ ముగింపు",
  New_Call:"కొత్త కాల్",
  No_Contacts:"పరిచయాలు లేవు",
  No_Contacts_Word:"నిర్వాహకులు, మోడరేటర్లు, ఉద్యోగులు మరియు చెల్లింపుదారులు పరిచయాలుగా కనిపిస్తారు.",
  PDF_Subject:"ఆర్థిక",
  PDF_Keywords:"పేరోల్ చెల్లింపు PDF చెక్ తనిఖీలు",
  Printer_Setup:"ప్రింటర్ సెటప్",
  Printer_Selection:"ప్రింటర్ ఎంపిక",
  New_Fax:"కొత్త ఫ్యాక్స్",
  New_Fax_Message:"కొత్త ఫ్యాక్స్ సందేశం",
  Fax_Machine:"ఫ్యాక్స్ మెషిన్",
  Fax_Name:"ఫ్యాక్స్ పేరు",
  Fax_Email:"ఫ్యాక్స్ ఇమెయిల్",
  Fax_Number:"ఫ్యాక్స్ సంఖ్య",
  Contents:"కంటెంట్‌లు",
  Fax_Body:"పేజీ బాడీ",
  Header_Word:"హెడర్:",
  Header_Text:"హెడర్ టెక్స్ట్",
  Include_Header:"శీర్షికను చేర్చండి",
  Include_Footer:"ఫుటర్‌ని చేర్చండి",
  Footer_Word:"ఫుటర్:",
  Footer_Text:"ఫుటర్ టెక్స్ట్",
  Attach_a_Cheque:"చెక్కును అటాచ్ చేయండి",
  Add_Deduction:"తగ్గింపును జోడించండి",
  Enable_Fax:"ఫ్యాక్స్ పంపండి",
  Select_Fax:"ఫ్యాక్స్ ఎంచుకోండి",
  No_Faxes:"ఫ్యాక్స్‌లు లేవు",
  Faxes:"ఫ్యాక్స్‌లు",
  Save_and_Send:"ఫ్యాక్స్ పంపండి",
  Save_and_Pay:"సేవ్ చేసి చెల్లించండి",
  WARNING:"హెచ్చరిక:",
  Remember:"గుర్తుంచుకోండి",
  Printing:"ప్రింటింగ్",
  Printing_Complete:"ప్రింటింగ్ పూర్తయింది!\n\n",
  of:"యొక్క",
  There_Were:"ఉన్నాయి",
  Successful_Prints:"విజయవంతమైన ప్రింట్లు మరియు",
  Unsuccessful_Prints:"విజయవంతం కాని ప్రింట్లు.",
  PrinterError:"క్షమించండి! లోపం ఏర్పడింది.",
  Printing_:"ప్రింటింగ్...",
  PrinterSuccess:"పత్రం విజయవంతంగా ముద్రించబడింది.",
  PrintersSuccess:"పత్రాలు విజయవంతంగా ముద్రించబడ్డాయి.",
  New_Message:"కొత్త సందేశం",
  New_Messages:"కొత్త సందేశాలు",
  Read_Message:"సందేశాన్ని చదవండి",
  Write_Message:"సందేశాన్ని వ్రాయండి",
  Send_Message:"సందేశము పంపుము",
  Subject:"విషయం",
  Message:"సందేశం",
  Writing:"రాయడం...",
  Send:"పంపండి",
  Set_Date:"తేదీని సెట్ చేయండి",
  Set_Time:"సమయం సరిచేయి",
  Recieve:"స్వీకరించండి",
  Set_as_Default:"ఎధావిధిగా ఉంచు",
  Default_Account:"డిఫాల్ట్ ఖాతా",
  Default_Design:"డిఫాల్ట్ డిజైన్",
  Multiple_Cheques:"మూడు తనిఖీలు",
  Account_Mode:"ఖాతా మోడ్",
  Multiple_Cheques_Description:"ఒక్కో పేజీకి మూడు చెక్‌లు.",
  Check_and_Table:"చెక్ & టేబుల్",
  Check_including_Table:"అకౌంటింగ్ పట్టికతో తనిఖీ చేయండి.",
  Check_Mailer:"మెయిలర్‌ని తనిఖీ చేయండి",
  Check_Mailer_Window:"చిరునామా విండోతో తనిఖీ చేయండి.",
  DocuGard_Table_Top:"పెద్ద చెక్ & టేబుల్",
  DocuGard_Table_Middle:"పెద్ద చెక్ & టేబుల్ (మధ్య)",
  DocuGard_Table_Bottom:"పెద్ద చెక్ & టేబుల్ (దిగువ)",
  DocuGard_Mailer_Top:"పెద్ద చెక్ మెయిలర్",
  DocuGard_Mailer_Middle:"పెద్ద చెక్ మెయిలర్ (మధ్య)",
  DocuGard_Mailer_Bottom:"పెద్ద చెక్ మెయిలర్ (దిగువ)",
  DocuGard_Three_Cheques:"మూడు పెద్ద తనిఖీలు",
  DocuGard_Cheque_Top:"ఒకే పెద్ద చెక్",
  DocuGard_Cheque_Middle:"పెద్ద చెక్ (మధ్య)",
  DocuGard_Cheque_Bottom:"పెద్ద చెక్ (దిగువ)",
  DocuGard_Three_Cheques_Window:"ఒక పేజీలో మూడు తనిఖీలు.",
  DocuGard_Table_Top_Window:"తనిఖీ & ఆదాయాల పట్టిక.",
  DocuGard_Table_Middle_Window:"తనిఖీ & ఆదాయాల పట్టిక.",
  DocuGard_Table_Bottom_Window:"తనిఖీ & ఆదాయాల పట్టిక.",
  DocuGard_Mailer_Top_Window:"చెక్, టేబుల్ మరియు చిరునామా.",
  DocuGard_Mailer_Middle_Window:"చెక్, టేబుల్ మరియు చిరునామా.",
  DocuGard_Mailer_Bottom_Window:"చెక్, టేబుల్ మరియు చిరునామా.",
  DocuGard_Cheque_Top_Window:"సురక్షితమైన కాగితం కోసం తనిఖీ చేయండి.",
  DocuGard_Cheque_Middle_Window:"సురక్షితమైన కాగితం కోసం తనిఖీ చేయండి.",
  DocuGard_Cheque_Bottom_Window:"సురక్షితమైన కాగితం కోసం తనిఖీ చేయండి.",
  Basic_Cheque:"సింగిల్ చెక్",
  Basic_Cheque_Print:"ఒకే చెక్కును ప్రింట్ చేయండి.",
  Error_Setting_As_Paid:"చెల్లింపుగా సెట్ చేయడంలో లోపం",
  Add_Attachment:"జోడింపుని జోడించండి",
  PrinterUnavailable:"ప్రింటర్ అందుబాటులో లేదు",
  CreditCardComponent:"కార్డులు",
  PaypalComponent:"పేపాల్",
  InteracComponent:"ఇంటరాక్",
  BitcoinComponent:"BitCoin",
  New_Deposit:"కొత్త డిపాజిట్",
  Deposits:"డిపాజిట్లు",
  No_Deposits:"డిపాజిట్లు లేవు",
  Credit_Card_Deposit:"క్రెడిట్ కార్డ్",
  New_Credit_Card_Deposit_Message:"క్రెడిట్ కార్డ్ డిపాజిట్",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin డిపాజిట్",
  New_Interac_Deposit:"ఇంటరాక్",
  New_Interac_Deposit_Message:"పరస్పర బదిలీ",
  Payments_Limit:"చెల్లింపు పరిమితి",
  No_Payment_Limit:"చెల్లింపు పరిమితి లేదు",
  PayPal_Deposit:"పేపాల్",
  PayPal_Deposit_Message:"పేపాల్ డిపాజిట్",
  No_Deposits_Word:"మొదటి కంపెనీ <a routerLink='/folder/Deposit/New'>డిపాజిట్</a>ని జోడించండి.",
  No_Documents_Specified:"ప్రింటింగ్ కోసం ఎలాంటి పత్రాలు పేర్కొనబడలేదు",
  No_Printers_Added:"ప్రింటర్లు ఏవీ కనుగొనబడలేదు. ఒకదాన్ని జోడించడానికి సెట్టింగ్‌లు > ప్రింటర్‌లకు వెళ్లండి.",
  DB_Erase_Prompt:"మొత్తం డేటాబేస్‌ను పూర్తిగా తొలగించాలా? హెచ్చరిక: మీరు నిల్వ చేసిన మొత్తం సమాచారాన్ని కోల్పోతారు!",
  Console_Warning:"ఈ కన్సోల్‌లో ఏ వచనాన్ని అతికించవద్దు. మీరు మిమ్మల్ని మరియు/లేదా మీ కంపెనీని తీవ్రమైన ప్రమాదంలో పెట్టవచ్చు.",
  No_Faxes_Word:"మొదటి <a routerLink='/folder/Fax/New'>Fax</a>ని సృష్టించండి.",
  Cheque_Delete_Confirmation:"మీరు ఖచ్చితంగా ఈ చెక్‌ని తొలగించాలనుకుంటున్నారా?",
  Design_Delete_Confirmation:"మీరు ఖచ్చితంగా ఈ డిజైన్‌ను తొలగించాలనుకుంటున్నారా?",
  Cheque_Pay_Confirmation:"ఈ చెక్కును చెల్లించినట్లుగా గుర్తించాలా? ఇది ప్రింట్ క్యూలో కనిపించదు.",
  Payment_Pay_Confirmation:"ఈ చెల్లింపును చెల్లించినట్లు గుర్తు పెట్టాలా? ఇది చెక్ క్యూలో కనిపించదు.",
  Delete_Deduction_Confirmation:"మీరు ఖచ్చితంగా ఈ తగ్గింపును తొలగించాలనుకుంటున్నారా?",
  Delete_Job_Confirmation:"మీరు ఖచ్చితంగా ఈ ఉద్యోగాన్ని తొలగించాలనుకుంటున్నారా?",
  Delete_Timesheet_Confirmation:"మీరు ఖచ్చితంగా ఈ టైమ్‌షీట్‌ని తొలగించాలనుకుంటున్నారా?",
  Delete_Schedule_Confirmation:"మీరు ఖచ్చితంగా ఈ షెడ్యూల్‌ని తొలగించాలనుకుంటున్నారా?",
  Delete_Setting_Confirmation:"మీరు ఖచ్చితంగా ఈ సెట్టింగ్‌ని రీసెట్ చేయాలనుకుంటున్నారా?",
  Delete_Fax_Confirmation:"మీరు ఖచ్చితంగా ఈ ఫ్యాక్స్‌ని తొలగించాలనుకుంటున్నారా?",
  Delete_File_Confirmation:"మీరు ఖచ్చితంగా ఈ ఫైల్‌ను తొలగించాలనుకుంటున్నారా?",
  Delete_Vacation_Confirmation:"మీరు ఖచ్చితంగా ఈ సెలవులను తొలగించాలనుకుంటున్నారా?",
  Delete_Printer_Confirmation:"మీరు ఖచ్చితంగా ఈ ప్రింటర్‌ని తొలగించాలనుకుంటున్నారా?",
  Remove_Design_Confirmation:"మీరు ఖచ్చితంగా ఈ డిజైన్‌ను తొలగించాలనుకుంటున్నారా?",
  Delete_Payroll_Confirmation:"మీరు ఖచ్చితంగా ఈ పేరోల్‌ని తొలగించాలనుకుంటున్నారా?",
  Send_Fax_Email_Confirmation:"మీరు ఈ పత్రాలను ఫ్యాక్స్ చేసి ఇమెయిల్ చేయాలనుకుంటున్నారా?",
  Send_Email_Confirmation:"మీరు ఈ పత్రాన్ని ఇమెయిల్ చేయాలనుకుంటున్నారా?",
  Send_Fax_Confirmation:"మీరు ఈ పత్రాన్ని ఫ్యాక్స్ చేయాలనుకుంటున్నారా?",
  Error_Generating_PDF:"క్షమించండి. ఈ పత్రాన్ని రూపొందించడంలో లోపం ఏర్పడింది.",
  PDF_Error_Saving_Image:"క్షమించండి. ఈ పత్రం యొక్క PDF చిత్రాన్ని సేవ్ చేయడంలో లోపం ఏర్పడింది.",
  Test_Printer_Confirmation:"మీరు ఈ ప్రింటర్‌లో పరీక్ష పేజీని ప్రింట్ చేయాలనుకుంటున్నారా?",
  Save_Timesheet_Prompt:"దయచేసి సేవ్ చేయడానికి 'శీర్షిక'ని జోడించండి లేదా 'స్టార్ట్ టైమర్'ని నొక్కండి.",
  Remove_Geofence_Prompt:"మీరు ఖచ్చితంగా ఈ భౌగోళిక కంచె స్థానాన్ని తీసివేయాలనుకుంటున్నారా?",
  Delete_Employee_Confirmation:"మీరు తొలగించాలనుకుంటున్నారా ఖచ్చితంగా",
  Fire_Employee_Confirmation:"మీరు ఖచ్చితంగా కాల్చాలనుకుంటున్నారా"
}