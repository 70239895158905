export const It = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Copyright &copia; 2023",
  black:"Nero",
  green:"Verde",
  gold:"Oro",
  blue:"Blu",
  brown:"Marrone",
  purple:"Viola",
  pink:"Rosa",
  red:"Rosso",
  Swatches:"Campioni",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Tinta",
  Saturation:"Saturazione",
  Lightness:"Leggerezza",
  Upgrade_To_Pro:"Aggiornamento a Pro",
  AllFeaturesInclude:"Tutte le funzionalità includono:",
  PrintUnlimitedCheques:"Stampa assegni illimitati",
  PremiumChequeDesigns:"Disegni di controllo premium",
  ManageUnlimitedEmployees:"Gestisci dipendenti illimitati",
  AddUnlimitedPayees:"Aggiungi beneficiari illimitati",
  CreateUnlimitedPayrolls:"Crea buste paga illimitate",
  UnlimitedSchedulesandTimeSheets:"Orari e fogli orari illimitati",
  BulkPayPalPayouts:"Pagamenti PayPal in blocco",
  UnlimitedBankAccounts:"Conti bancari illimitati",
  ManageMultipleCompanies:"Gestisci più aziende",
  TrackInsurancePolicies:"Tieni traccia delle polizze assicurative",
  Bio_MetricProtection:"Protezione biometrica",
  Geo_FenceLock_OutProtection:"Protezione da blocco Geo-Fence",
  Multiple_Companies_Word:"La gestione di più società non è disponibile senza Checks premium.",
  PayPal_Payouts_Word:"I pagamenti PayPal sono disabilitati senza assegni premium.",
  PINProtection:"Protezione PIN",
  PasswordProtection:"Protezione della password",
  May_Require_Approval:"Potrebbe richiedere l'approvazione.",
  Subscribe:"sottoscrivi",
  Billed:"Fatturato",
  Up:"Su",
  Down:"Giù",
  Positioning:"Posizionamento",
  Marker:"Marcatore",
  Drag_Marker:"Trascina il marcatore",
  Tagline:"Stampa assegni e tabula buste paga",
  Marker_Word:"Usa i marcatori per ridimensionare l'elemento.",
  Pinch_Zoom:"Pizzica Zoom",
  Pinch_Word:"Pizzica per ingrandire l'elemento.",
  Drag:"Lagna",
  Drag_Word:"Usa il dito per trascinare gli elementi.",
  subscription_alias_word:"Abbonamento con rinnovo automatico",
  premium_alias_word:"Pacchetto di aggiornamento una tantum",
  print_alias_word:"Stampa assegni individuali",
  mode_alias_word:"Modalità",
  Pro:"Pro",
  Pro_word:"Versione Pro richiesta.",
  Cant_Delete_Default_Company_Word:"Spiacenti, non puoi eliminare la tua azienda predefinita.",
  Reinsert_Default_Designs:"Reinserire i disegni predefiniti",
  Reinsert_Default_Designs_word:"Vuoi reinserire i disegni predefiniti?",
  Subscription_Active_Disable_Word:"Questo abbonamento è attivo. Vuoi cancellare questo abbonamento a Cheques?",
  Company_Logo:"Logo della compagnia",
  ZERO_:"ZERO",
  Disclaimer:"Disclaimer",
  Privacy_Policy:"politica sulla riservatezza",
  EULA:"Controlla l'EULA",
  Terms_Of_Service:"Termini di servizio",
  Terms_Of_Use:"Termini di utilizzo",
  Refunds:"Politica di rimborso",
  Single_Print:"1 Controllo",
  Two_Prints:"2 controlli",
  Five_Prints:"5 controlli",
  Ten_Prints:"10 controlli",
  Fifteen_Prints:"15 controlli",
  Twenty_Prints:"20 controlli",
  Thirty_Prints:"30 controlli",
  Image_Added:"Immagine aggiunta",
  Image_Preview:"Anteprima dell'immagine",
  No_Image_Was_Selected:"Nessuna immagine è stata selezionata.",
  Cheques_Unlimited:"Controlli illimitati",
  _Subscription:"Sottoscrizione",
  Subscription:"Assegni - 1 mese",
  Two_Month_Subscription:"Assegni - 2 mesi",
  Three_Month_Subscription:"Assegni - 3 mesi",
  Six_Month_Subscription:"Assegni - 6 mesi",
  Twelve_Month_Subscription:"Assegni - 12 mesi",
  Cheques_Are_Available:"Gli assegni sono disponibili per la stampa.",
  Upgrade_Required_Two:"Seleziona un pacchetto e tocca due volte il pulsante del prezzo per iniziare l'acquisto. Stampa assegni a colori dall'aspetto professionale in pochi secondi.",
  Month:"Mese",
  Due:"Dovuto:",
  Expires:"Scade:",
  Subscription_Active:"Abbonamento attivo",
  Subscription_Inactive:"Abbonamento non attivo",
  Purchase_Additional_Cheques:"Acquistare assegni aggiuntivi?",
  Printable_Cheque:"Assegno stampabile",
  Printable_Cheques:"Assegni stampabili",
  Printable_Cheque_Word:"l'assegno è disponibile sul tuo account.",
  Printable_Cheques_Word:"gli assegni sono disponibili sul tuo conto.",
  Max_Amount_Message:"L'importo specificato ha superato l'importo massimo impostato per questo sistema:",
  Terms_Required_Word:"È necessario accettare questo accordo prima di continuare a utilizzare gli assegni.",
  Subscriptions:"Abbonamenti",
  Product_Upgrades:"Aggiornamenti",
  Mailed_Out_Cheques:"Assegni spediti",
  Enter_A_Company_Name:"Inserisci il nome di una società.",
  Single_Cheques:"Singoli assegni",
  Cheque_Golden:"Controllo d'oro",
  Cheque_Golden_Window:"Design a quadri d'oro.",
  Cheque_Green:"Controllo verde",
  Cheque_Green_Window:"Design a quadri verdi.",
  Cheque_Red:"Controllo rosso",
  Cheque_Red_Window:"Disegno a quadri rossi.",
  Cheque_Yellow:"Controllo giallo",
  Cheque_Yellow_Window:"Design a quadri gialli.",
  Cheque_Statue_of_Liberty:"Statua della Libertà",
  Cheque_Statue_of_Liberty_Window:"Il design a scacchi della Statua della Libertà.",
  Cheque_Green_Wave:"Onda Verde",
  Cheque_Green_Wave_Window:"Design a quadri verdi.",
  Cheque_Golden_Weave:"Trama d'oro",
  Cheque_Golden_Weave_Window:"Elegante design a quadri dorati.",
  Cheque_Green_Sun:"Sole Verde",
  Cheque_Green_Sun_Window:"Design a quadri profondo e calmante.",
  Cheque_Blue_Checkers:"Dama Blu",
  Cheque_Blue_Checkers_Window:"Disegno a quadri blu.",
  Cashiers_Check:"Assegno circolare",
  Cashiers_Check_Window:"Modello di stile di assegno circolare.",
  Cheque_Aqua_Checkers:"Dama Acquatica",
  Cheque_Aqua_Checkers_Window:"Design Aqua check.",
  Cheque_Golden_Checkers:"Dama d'oro",
  Cheque_Golden_Checkers_Window:"Design a quadri d'oro.",
  Upgrade_Unavailable:"Aggiornamenti non disponibili",
  Bank_Code_Protection:"Protezione del numero di banca",
  Bank_Code_Protection_Word:"Proteggi i tuoi numeri bancari dall'uso su questa app in esecuzione su un altro dispositivo o per un altro utente. Questa azione è IRREVERSIBILE. Continua?",
  Bank_Code_Protection_Blocked_Word:"I numeri bancari che stai tentando di utilizzare sono riservati per un altro utente o dispositivo.",
  Bank_Code_Protection_Error_Word:"La verifica del numero non è riuscita. Connettiti a Internet e prova ad aggiungere nuovamente questo conto bancario.",
  Bank_Code_Protection_Set_Error_Word:"La protezione del numero di banca richiede la connessione a Internet. Connettiti e riprova.",
  Upgrade_Unavailable_Word:"Spiacenti, abbiamo problemi a verificarti. Gli abbonamenti e gli aggiornamenti agli assegni non sono attualmente disponibili per l'acquisto nella tua zona.",
  PayPal_Payment_Preview:"Anteprima pagamento PayPal",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Seleziona PayPal",
  PayPal_Applications:"Applicazioni PayPal",
  Purchase_With_Apple_Pay:"Acquista con Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Aziende",
  Insurance:"Assicurazione",
  New_PayPal:"Nuovo PayPal",
  Pay_By:"Paga con",
  Insure:"Assicurare",
  Miles:"Miglia",
  Payment_Method:"Metodo di pagamento",
  Select_Policies:"Seleziona Politiche",
  Policies:"Politiche",
  Policy:"Politica",
  No_PayPal_Account:"Nessun conto PayPal",
  No_Policies:"Nessuna polizza assicurativa",
  New_Policy:"Nuova Politica",
  PayPal_Payment:"Pagamento PayPal",
  PayPal_Payments:"Pagamenti PayPal",
  No_Payment_Selected:"Nessun pagamento selezionato",
  Sending_Payment_Word:"Attendere... Il pagamento è in fase di invio.",
  Sending_Payments_Word:"Attendere... I pagamenti vengono inviati.",
  No_Payment_Selected_PayPal:"Nessun <a routerLink='/folder/Payments'>Pagamento PayPal</a> selezionato per l'invio.",
  Payment_Sent:"Pagamento inviato",
  PayPal_Payment_Sent:"Questo pagamento è stato inviato con PayPal.",
  Copay:"Copay",
  Dead:"Morto",
  Alive:"Vivo",
  Not_Dead:"Non morto",
  Unclaimed:"Non reclamato",
  Attempted:"Tentata",
  Deceased:"Deceduto",
  Claimed:"Reclamato",
  Unpaid:"Non pagato",
  Sending_Payment:"Invio di pagamento",
  Sending_Payments:"Invio di pagamenti",
  Send_PayPal_Confirmation:"Vuoi inviare questa transazione con PayPal?",
  Send_PayPal_Confirmation_Word:"Premi il pulsante verde per inviare questa transazione.",
  Save_Payment_As_Unpaid:"Salvare questo pagamento come non pagato?",
  Payment_Pay_Confirmation_PayPal:"Salvare questo pagamento come pagato?",
  No_Policies_Word:"Aggiungi ora la prima <a routerLink='/folder/Postage/New'>Polizza Assicurativa</a>.",
  Timesheet_Multiple_Delete_Confirmation:"Sei sicuro di voler eliminare più schede attività?",
  Select_Multiple_Timesheets_Prompt:"Nessuna scheda attività selezionata. Seleziona almeno una scheda attività.",
  Select_Multiple_Policies_Prompt:"Nessun criterio selezionato. Seleziona almeno una polizza assicurativa.",
  Policies_Multiple_Delete_Confirmation:"Sei sicuro di voler eliminare più criteri?",
  Company:"Azienda",
  Add_Company:"Aggiungi azienda",
  New_Company:"Aggiungi azienda",
  No_Companies:"Nessuna azienda",
  Enable_Company:"Abilita azienda",
  Select_Company:"Seleziona Azienda",
  The_Default_Company:"L'etichetta aziendale predefinita.",
  Manage_Companies:"Gestisci le aziende",
  No_Companies_Word:"Gli assegni utilizzeranno un'azienda predefinita .<br />Aggiungi la <a routerLink='/folder/Company/New'>prima azienda</a>.",
  Default_Company:"Società predefinita",
  Cheques_Unlimited_Word:"Checks Unlimited ti consente di stampare tutti gli assegni che desideri.",
  Cheques_Subscription_Word:"Un abbonamento agli assegni ti consente di stampare tutti gli assegni che desideri.",
  You_Own_This_Product:"Possiedi questo prodotto.",
  Your_Subscription_is_Active:"Il tuo abbonamento è attivo.",
  Active_Products:"Prodotti attivati",
  Purchase_Confirmation:"Acquistare",
  Purchase_Cheques:"Assegni di acquisto",
  Restore_Purchase:"Ripristinare gli acquisti",
  Erase_Purchase:"Cancella acquisti",
  Successfully_Purchased:"Acquistato con successo",
  Enter_Your_Licence_Key:"Inserisci il tuo codice di licenza in questa pagina per attivare questo prodotto.",
  Licence_Key:"Chiave di licenza",
  Enter_Licence_Key:"Inserisci la chiave di licenza",
  Purchased:"Acquistato",
  Enable_Feature:"Abilita funzione",
  Cancel_Subscription:"Annullare l'iscrizione",
  Subscription_Removed:"Abbonamento rimosso",
  Select_Active_Subscription:"Seleziona un abbonamento attivo per modificarlo.",
  Remove_Subscription_Word:"Sei sicuro di voler annullare questo abbonamento?",
  Delete_Company_Confirmation:"Sei sicuro di voler eliminare l'intera azienda? ATTENZIONE: perderai tutte le informazioni memorizzate!",
  Delete_Default_Company_Word:"Non è possibile eliminare l'azienda predefinita. Desideri reimpostare l'applicazione e riportarla allo stato predefinito? ATTENZIONE: perderai tutte le informazioni memorizzate!",
  Console_Warning_2:"Non gestire alcuna valuta utilizzando questa applicazione che non è attualmente tua.",
  Terms_and_Conditions:"Termini e Condizioni",
  and_the:"e il",
  for:"per",
  Please_Read_Word:"Si prega di leggere e accettare il",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Impossibile trovare alcuni tassi di conversione di valuta. Si prega di connettersi a Internet.",
  Free:"Gratuito",
  DB_Erase_Prompt_2:"Cancellare completamente l'intero database degli sviluppatori? ATTENZIONE: perderai tutte le informazioni relative all'ACQUISTO e all'ABBONAMENTO!",
  Successfully_Imported:"Importato con successo",
  Select_Postage:"Seleziona Affrancatura",
  No_Postage:"Niente francobolli",
  No_Paid_Postage_Word:"Aggiungi il primo francobollo <a routerLink='/folder/Postage/New'>Affrancatura a Pagamento</a>.",
  Trial_Complete:'Prova completata',
  Please_Upgrade:'Aggiorna gli assegni per continuare a stampare.',
  Aa:"Aa",
  Color:"Colore",
  Font:"Font",
  Guide:"Guida",
  Guides:"Guide",
  Image:"Immagine",
  Zoom:"Ingrandisci",
  Logo:"Logo",
  Bank:"Banca",
  MICR:"MICR",
  Total:"Totale",
  Cancel:"Annulla",
  Confirm:"Confermare",
  Method:"Metodo",
  Biometric_Security:"Sicurezza biometrica",
  Please_Login_To_Continue:"Per favore accedi per continuare.",
  Complete:"Completare",
  Sign_Up:"Iscrizione",
  Error:"Errore",
  Biometrics:"Biometrica",
  Percent:"Per cento",
  Zero_Percent:"0%",
  Top_Margin:"Margine superiore",
  Bottom_Margin:"Margine inferiore",
  Left_Margin:"Margine sinistro",
  Right_Margin:"Margine destro",
  MICR_Margin:"Margine MICR",
  Table_Margin:"Margine tabella",
  Address_Margin:"Margine di indirizzo",
  Percentage_:"Percentuale",
  Vacation_Title:"Titolo vacanza",
  Use_PIN:"Usa il PIN",
  Loading__:"Caricamento in corso...",
  Design_Title:"Titolo del disegno",
  Authorize:"Autorizzare",
  Key:"Chiave",
  Public_Key:"Chiave pubblica",
  Private_Key:"Chiave privata",
  Authenticate:"Autenticare",
  Last_Payroll:"Ultimo libro paga",
  Last_Calculation:"Ultimo calcolo",
  Authorized:"Autorizzato",
  Geo_Authorized:"Geo-localizzazione: Autorizzata",
  Not_Authorized:"Non autorizzato",
  Authorization_Complete:"Autorizzazione completata",
  Geolocation_Authorization:"Autorizzazione alla geolocalizzazione",
  Out_of_Bounds:"Fuori dai limiti",
  Cant_Delete_Default_Design:"Impossibile eliminare un design predefinito.",
  Unauthorized_Out_of_Bounds:"Non autorizzato: fuori dai limiti",
  Biometric_Authorization:"Autorizzazione biometrica",
  Locating_Please_Wait:"Localizzazione, attendere prego...",
  Test_Biometrics:"Prova la biometria",
  Cheque_Margins:"Controlla i margini",
  Percentage_Full_Error:"Il campo percentuale non può essere impostato su 100% percento.",
  No_Payroll_Text:"Aggiungi un <a routerLink='/folder/Employee/New'>Dipendente</a> o <a routerLink='/folder/Payee/New'>Beneficiario</a> e un <a routerLink='/folder/Schedule/New'>Programma</a>.",
  Design_Saved:"Design salvato",
  Default_Design_Selected:"Design predefinito selezionato",
  Partial_Import:"Importazione parziale",
  Partial_Export:"Esportazione parziale",
  Entire_Import:"Intera importazione",
  Entire_Export:"Intera esportazione",
  Existing_Password:"Per favore inserisci la tua password esistente:",
  Existing_PIN:"Inserisci il tuo codice PIN esistente:",
  Pin_Change_Header:"Conferma PIN",
  Pin_Change_SubHeader:"Inserisci il tuo vecchio numero PIN per confermare la modifica.",
  Pass_Change_Header:"Conferma password",
  Pass_Change_SubHeader:"Inserisci la tua vecchia password per confermare la modifica.",
  PIN_Enter_Message:"Inserisci il tuo PIN per confermare.",
  Password_Enter_Message:"Inserisci la tua password per confermare.",
  Pin_Updated_Success:"PIN aggiornato correttamente!",
  Pin_Updated_Fail:"Impossibile aggiornare il PIN.",
  Pass_Updated_Success:"Password aggiornata correttamente.",
  Pass_Updated_Fail:"Impossibile aggiornare la password.",
  Preview_Payment:"Anteprima del pagamento",
  Preview_Payroll:"Anteprima libro paga",
  No_Payments_Created:"Non è stato trovato alcun pagamento creato.",
  Payment_Preview:"Anteprima di pagamento",
  Enable_Payee:"Abilita beneficiario",
  Rendered:"Resi",
  No_Email:"Nessuna e-mail",
  Setup_Cheques:"Controlli di installazione",
  name:"Nome",
  address:"Indirizzo",
  address_2:"indirizzo 2",
  city:"Città",
  province:"Provincia",
  postal_code:"Codice postale",
  country:"Paese",
  username:"Nome utente",
  full_name:"Nome e cognome",
  birthday:"Compleanno",
  email:"E-mail",
  phone:"Telefono",
  employees:"Dipendenti",
  addresses:"Indirizzi",
  payment_amount_limit:"Limite dell'importo del pagamento",
  total_limit:"Limite totale",
  payees:"Beneficiari",
  description:"Descrizione",
  address_line_one:"Indirizzo linea uno",
  address_line_two:"Indirizzo linea due",
  image:"Immagine",
  account_holder:"Titolare del conto",
  cheque_starting_id:"Controllare l'ID iniziale",
  transit_number:"Numero di transito",
  institution_number:"Numero istituto",
  designation_number:"Numero di designazione",
  account_number:"Numero di conto",
  currency:"Moneta",
  signature:"Firma",
  payment_payroll_limit:"Limite di pagamento",
  total_limi:"Limite totale",
  date:"Data",
  printed_memo:"Memo stampato",
  banks:"Banche",
  signature_image:"Immagine della firma",
  address_name:"Nome indirizzo",
  notes:"Appunti",
  design:"Disegno",
  title:"Titolo",
  frequency:"Frequenza",
  fax:"Fax",
  salaries:"Stipendi",
  salary_ids:"ID stipendio",
  start_time:"Ora di inizio",
  end_time:"Tempo scaduto",
  paid:"Pagato",
  overtime_percentage:"Percentuale pagata",
  overtime_amount:"Importo fisso pagato",
  first_name:"Nome di battesimo",
  last_name:"Cognome",
  moderation:"Moderazione",
  create:"Creare",
  edit:"Modificare",
  destroy:"Distruggere",
  day_start_time:"Giorno_inizio_ora",
  day_end_time:"Giorno_fine_ora",
  fullname:"Nome",
  time:"Volta",
  auto_send:"Invia automaticamente",
  time_method:"Metodo del tempo",
  schedules:"Orari",
  indefinite_payroll_enabled:"Abilita a tempo indeterminato",
  amount:"Quantità",
  repeat:"Ripetere",
  always_enabled:"Sempre abilitato",
  start_date:"Data d'inizio",
  end_date:"Data di fine",
  Cheque_Total:"Controlla il totale",
  Total_Amount:"Importo totale:",
  Amounts:"Importi:",
  Images:"immagini",
  Files:"File",
  Previewing:"Anteprima:",
  Insert:"Inserire",
  Preview_Import:"Importazione in anteprima",
  Entry:"Iscrizione",
  Entries:"Inserimenti",
  No_Entries:"Nessuna voce",
  Import_Type:"Tipo di importazione",
  Select_Details:"Seleziona Dettagli",
  Select_Payee:"Seleziona Beneficiario",
  Enable_Holidays:"Abilita vacanze",
  Disable_Holidays:"Disabilita le vacanze",
  Wire_Transfer:"Bonifico bancario",
  New_Export:"Nuova esportazione",
  Export_Data:"Esporta dati",
  Export_Data_Word:"Seleziona il tipo di file da esportare e scaricare.",
  Export_File:"Esporta file",
  Mode:"Modalità",
  Times:"Volte",
  Widgets:"Widget",
  Slider:"Dispositivo di scorrimento",
  Set_Details:"Imposta i dettagli",
  Select_Type:"Seleziona Tipo",
  Display_Slider:"Visualizza dispositivo di scorrimento",
  Dashboard_Slider:"Dispositivo di scorrimento del cruscotto",
  Dashboard_Mode:"Modalità dashboard",
  Show_Intro:"Mostra introduzione",
  Show_Payrolls:"Mostra buste paga",
  Show_Holidays:"Mostra Vacanze",
  Show_Invoices:"Mostra fatture",
  Show_Cheques:"Mostra assegni",
  Enabled_Widgets:"Widget abilitati",
  Disabled_Widgets:"Widget disabilitati",
  Colors:"Colori",
  Barcodes:"Codici a barre",
  View_Timers:"Visualizza timer",
  Gradients:"Gradienti",
  No_Info:"Nessuna informazione",
  Disable:"disattivare",
  Show_Layer:"Mostra livelli",
  Hide_Layer:"Nascondi livelli",
  Text_Layer:"Livelli di testo",
  Secondly:"In secondo luogo",
  Minutely:"Minuto",
  nine_am:"9:00 DEL MATTINO",
  five_pm:"17:00",
  Enable_Address:"Abilita indirizzo",
  Invalid_File_Type:"Spiacenti, è stato selezionato un tipo di file non valido. Tipo di file supportato:",
  Error_Updating_Entry:"Spiacenti, si è verificato un errore durante l'aggiornamento di questa voce.",
  Schedule_Timing_Alert:"Errore: l'ora di inizio della pianificazione è impostata su un valore successivo all'ora di fine.",
  Select_Multiple_Payments_Prompt:"Nessun pagamento selezionato. Seleziona almeno un pagamento.",
  Select_Multiple_Cheques_Prompt:"Nessun controllo selezionato. Seleziona almeno un controllo.",
  Select_Multiple_Items_Prompt:"Nessun elemento selezionato. Seleziona almeno un elemento.",
  Paymemt_Multiple_Delete_Confirmation:"Sei sicuro di voler eliminare più pagamenti?",
  Cheque_Multiple_Delete_Confirmation:"Sei sicuro di voler eliminare più assegni?",
  Payee_Multiple_Delete_Confirmation:"Sei sicuro di voler eliminare più beneficiari?",
  Employee_Multiple_Delete_Confirmation:"Sei sicuro di voler eliminare più dipendenti?",
  MICR_Warning:"Nota: alcune stampanti e dispositivi potrebbero non visualizzare correttamente il carattere MICR.",
  Automatically_Send:"Invia automaticamente",
  Type:"Tipo",
  Payment_Type:"Modalità di pagamento",
  Auto_Send:"Invio automatico",
  Automatically_Process:"Elabora automaticamente",
  Auto_Process:"Processo automatico",
  Image_Based:"Basato su immagini",
  Font_Based:"Basato sui caratteri",
  Rerender:"Re-renderizzare",
  Rendering:"Rendering",
  Render:"File",
  Top:"Superiore",
  Middle:"Mezzo",
  Bottom:"Parte inferiore",
  Top_Left:"In alto a sinistra",
  Top_Center:"Centro in alto",
  Top_Right:"In alto a destra",
  Middle_Left:"Medio Sinistra",
  Middle_Center:"Centro medio",
  Middle_Right:"Medio Destra",
  Bottom_Left:"In basso a sinistra",
  Bottom_Center:"Centro in basso",
  Bottom_Right:"In basso a destra",
  Numbers:"Numeri",
  Verified:"Verificato",
  Paper_Size:"Dimensioni del foglio",
  New_Device:"Nuovo dispositivo",
  Add_Device:"Aggiungi dispositivo",
  Remove_Device:"Rimuovi dispositivo",
  Delete_Device:"Elimina dispositivo",
  Block_Device:"Blocca dispositivo",
  Block:"Bloccare",
  Unblock:"Sbloccare",
  Table:"Tavolo",
  Scan_Login_Code:"Scansiona il codice di accesso",
  Login_Code:"Codice di accesso",
  Scan_Code:"Scansione del codice",
  Scan_QR_Code:"Scansiona il codice QR",
  Select_All:"Seleziona tutto",
  Deselect_All:"Deselezionare tutto",
  Increase:"Aumento",
  Decrease:"Diminuire",
  Bold:"Grassetto",
  Text:"Testo",
  Style:"Stile",
  Italic:"Corsivo",
  QR_Code:"QR Code",
  Barcode:"Codice a barre",
  Browse_Images:"Sfoglia le immagini",
  Browse_Files:"Sfoglia i file",
  Background_Image:"Immagine di sfondo",
  Logo_Image:"Immagine del logo",
  Header_Image:"Immagine di intestazione",
  Bank_Image:"Immagine della banca",
  Cut_Lines:"Linee di taglio",
  Background:"Sfondo",
  License:"Licenza",
  One_License:"1 licenza:",
  Licensed:"Concesso in licenza a:",
  Not_Licensed:"Non con licenza",
  Enter_Serial:"Inserisci Seriale",
  Serial_Key:"Chiave seriale",
  Serial:"Seriale",
  Product_Key:"Chiave del prodotto",
  Check_Product_Key:"Controllare la chiave del prodotto",
  Add_Product_Key:"Aggiungi codice prodotto",
  Verifying_Purchase:"Verifica acquisto...",
  Print_Envelope:"Stampa busta",
  Envelope:"Busta",
  Thank_You:"Grazie!",
  Scale:"Scala",
  Print_Scale:"Scala di stampa",
  Borders:"frontiere",
  VOID:"VUOTO",
  Void_Cheque:"Controllo nullo",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"PAGA ALL'ORDINE DI:",
  NO_DOLLARS:"NIENTE DOLLARI ",
  ONE_DOLLAR:"UN DOLLARO",
  DOLLARS:" DOLLARI",
  AND:" E ",
  CENTS:" CENTESIMI.",
  NO_:"NO ",
  ONE_:"UNO ",
  AND_NO_:"E NO ",
  _AND_ONE_:"E UNO ",
  DOLLARS_AND_ONE_CENT:" E UN centesimo.",
  AND_NO_CENTS:" E ZERO CENTESIMI.",
  CHEQUE_PRINT_DATE:"DATA:",
  CHEQUE_PRINT_MEMO:"PROMEMORIA:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Compiti iniziali",
  Inital_Setup:"Installazione iniziale",
  Welcome_To:"Benvenuto a ",
  Welcome:"Ben arrivato",
  Swipe:"Scorri",
  Intro_Setup:"Impostazione introduttiva",
  Introduction:"Introduzione",
  CHEQUE_PRINT_CREDIT:"Alimentato da assegni",
  Title_Intro_Word:"Benvenuto in Assegni",
  Title_Intro:"Presentazione assegni",
  Title_Setup:"Impostazione assegni",
  PayPal_Default_Email_Message:"Hai ricevuto un nuovo trasferimento PayPal. [Alimentato da Cheques]",
  Cheques_App_Export:"Esportato con assegni",
  Post_Intro_Thanks:"Grazie per aver scelto Assegni. Il processo di configurazione è ora completo.",
  Post_Intro_Word:"Inizia stampando il tuo primo assegno, aggiungi un pagamento futuro o aggiungi un dipendente al libro paga.",
  Upgrade_Required:"Assegni richiede di possedere una versione più premium del software per nascondere questo messaggio e sbloccare funzionalità aggiuntive.",
  Upgrade_Title:"Assegni",
  Mailout_Prompt:"Puoi inoltre scegliere di far spedire a Checks i tuoi assegni sui salari per te.",
  Mailed_Cheque:"Assegno inviato: ",
  Mailed_Cheque_Color:"Assegno inviato (colore): ",
  Terms_Purchase:"Tutti gli acquisti elettronici con assegni sono completamente rimborsabili per un massimo di 30 giorni dalla data di acquisto. Leggere e accettare i <a href='#'>Termini e condizioni</a> prima di continuare.",
  Dashboard_Setup:"Imposta la stampante principale",
  Dashboard_Add:"Aggiungi il conto bancario principale",
  Dashboard_Customize:"Seleziona un modello di controllo",
  Dashboard_Job_Salary:"Crea il tuo lavoro e aggiungi il tuo stipendio",
  Dashboard_Employees:"Traccia dipendenti e beneficiari",
  Dashboard_Print:"Stampa e spedisci i tuoi stipendi",
  Dashboard_Payroll:"Automatizzare la stampa delle buste paga",
  Dashboard_PayPal:"Impostazione Payroll/Pagamenti PayPal",
  Begin_Setup:"Inizia la configurazione",
  Get_Started:"Iniziare",
  Purchase:"Acquistare",
  Lockdown:"Confinamento",
  Unlock:"Sbloccare",
  Detailed:"dettagliato",
  Log_In:"Accesso",
  Login:"Login",
  Launch:"Lanciare",
  Register:"Registrati",
  Finish:"Fine",
  List:"Elenco",
  Weekends:"Fine settimana",
  Weekly:"Settimanalmente",
  PayPal_Default_Subject:"Nuovo pagamento",
  Payment_Message:"Messaggio di pagamento",
  PayPal_Default_Payment_Note:"Grazie",
  Setup_Your_Cheqing_Account:"Conto Corrente",
  Add_Your_Primary_Cheqing_Account:"Aggiungi il tuo conto corrente principale.",
  Welcome_Word:"Semplificare e automatizzare le buste paga e la gestione delle risorse umane.",
  Get_Started_Quickly:"Rispondi ad alcune semplici domande che ci aiuteranno a iniziare...",
  Done_Intro_Word:"Installazione completata",
  PIN_Protected:"Protetto da password e PIN",
  Enter_New_PIN:"Inserisci un nuovo codice PIN:",
  Enter_PIN:"Inserisci codice PIN:",
  Invalid_PIN:"PIN inserito non valido.",
  Account_Identifier:"Identificatore dell'account",
  New_Account_Identifier:"Nuovo identificatore di conto",
  attempt:"tentativo",
  attempts:"tentativi",
  remaining:"residuo",
  Language:"Lingua",
  languages:"Le lingue",
  select_languages:"Seleziona la lingua",
  Deposit:"Depositare",
  Hire_One_Now:"Assumi uno ora",
  App_Locked:"L'applicazione è bloccata.",
  Skip_:"Saltare",
  Skip_to_Dashboard:"Salta alla dashboard",
  Dashboard:"Pannello di controllo",
  Import:"Importare",
  Admin:"Amministratori",
  New_Admin:"Nuovo amministratore",
  Settings:"Impostazioni",
  Color_Picker:"Color Picker",
  Font_Picker:"Selettore caratteri",
  Logout:"Disconnettersi",
  Close:"Vicino",
  Close_menu:"Vicino",
  Excel:"File Excel",
  Csv:"File CSV",
  Sql:"File SQL",
  Json:"File JSON",
  Url:"Importa per URL",
  Back:"Indietro",
  Timers:"Timer",
  Cheque:"Dai un'occhiata",
  Print:"Stampa",
  Designs:"Disegni",
  Pause_Printing:"Pausa stampa",
  Resume_Printing:"Riprendi stampa",
  Printing_Paused:"Stampa sospesa",
  PrintingUnavailable:"Spiacenti! La stampa non è disponibile su questo sistema.",
  Prints_Paused:"Stampe in pausa",
  Administration:"Amministrazione",
  Loading:"Caricamento in corso",
  Unnamed:"Senza nome",
  error:"Spiacenti, non è stato possibile aprire questo controllo per la visualizzazione.",
  No_Cheques_To_Print:"Nessun assegno da stampare",
  No_Cheques_To_Print_Word:"Crea un <a routerLink='/folder/Cheque/New'>Nuovo Assegno</a>.",
  New_Cheque:"Nuovo assegno",
  Start_One_Now:"Inizia uno ora",
  Edit_Cheque:"Modifica controllo",
  Select_Cheques:"Seleziona controlli",
  Select_Employee:"Seleziona dipendente",
  Default_Printer:"Stampante predefinita",
  Reassign:"Riassegna",
  Configure:"Configura",
  Template:"Modello",
  Designer:"Progettista",
  Edit_Designs:"Modifica disegni",
  New_Design:"Nuovo design",
  Next:"Prossimo",
  Save:"Salva",
  Name:"Nome",
  Mail:"Posta",
  Amount:"Quantità",
  Date:"Data",
  PayPal:"PayPal",
  Payouts:"Pagamenti",
  PayPal_Label:"Etichetta PayPal",
  Email_Username:"E-mail/Nome utente",
  Client_ID:"Identificativo cliente",
  Sandbox_Email:"E-mail sandbox",
  PayPal_Email:"E-mail PayPal",
  PayPal_Username:"Nome utente API",
  PayPal_Payouts:"Pagamenti PayPal",
  Select_PayPal_Key:"Seleziona chiave PayPal",
  Secret:"Segreto",
  API_Secret:"Segreto API",
  PayPal_API_Keys:"Chiavi PayPal",
  New_PayPal_Key:"Nuova chiave PayPal",
  Email_Subject:"Oggetto dell'email",
  Email_Message:"Messaggio email",
  Payout_Options:"Opzioni di pagamento",
  Payment_Note:"Blocco di pagamento",
  Enable_Employee:"Abilita dipendente",
  Enable_Production_Mode:"Abilita modalità produzione",
  Sandbox_Username:"Nome utente sandbox",
  Sandbox_Signature:"Firma sandbox",
  Sandbox_Password:"Password sandbox",
  Production_Username:"Nome utente di produzione",
  Production_Signature:"Firma di produzione",
  Production_Password:"Password di produzione",
  Production_Email:"E-mail di produzione",
  API_Client_ID:"ID client API",
  API_Signature:"Firma API",
  API_Password:"Password API",
  API_Username:"Nome utente API",
  Secret_Key:"Chiave segreta",
  Sandbox:"Sabbiera",
  Production:"Produzione",
  Sandbox_Keys:"Chiavi sandbox",
  Production_Keys:"Chiavi di produzione",
  API_Title:"Titolo API",
  Production_Mode:"Modalità di produzione",
  Account_Label:"Etichetta conto",
  No_PayPal_Setup:"Nessuna chiave PayPal",
  Enable_PayPal_Account:"Abilita conto PayPal",
  No_PayPal_Word:"Aggiungi la tua <a routerLink='/folder/Invoice/New'>Chiave API PayPal</a>.",
  Printed_Memo:"Promemoria stampato",
  Employee:"Dipendente",
  View_Employee:"Visualizza dipendente",
  Mailing_Address:"Indirizzo di posta",
  Company_Address:"Indirizzo aziendale",
  Select_Company_Address:"Seleziona l'indirizzo dell'azienda",
  Address:"Indirizzo",
  Any_Day:"Qualsiasi giorno",
  Address_Name:"Nome indirizzo",
  Unit:"Unità",
  Account:"Account",
  Bank_Account:"Conto bancario",
  Account_Limits:"Abilita limiti account",
  Payroll:"Libro paga",
  New_Payroll:"Nuovo libro paga",
  No_Payroll:"Nessun libro paga in arrivo",
  Upcoming_Holiday:"Prossime vacanze:",
  Invoice_Due:"Fattura dovuta:",
  New_Invoice:"Nuova fattura",
  No_Invoices:"Nessuna fattura",
  No_Invoices_Word:"Crea la prima <a routerLink='/folder/Invoice/New'>Fattura</a>.",
  Cheque_Due:"Assegno dovuto:",
  Payrolls:"Pagamenti",
  Sandbox_Mode:"Modalità Sandbox",
  Hire:"Assumere",
  Pay:"Paga",
  New:"Nuovo",
  Add:"Aggiungere",
  Make:"Rendere",
  Time:"Volta",
  Write:"Scrivere",
  Holiday:"Vacanza",
  Holidays:"Vacanze",
  Next_Holiday:"Le prossime vacanze:",
  All_Done:"Tutto fatto!",
  Employees:"Dipendenti",
  Payees:"Pagamenti",
  Job:"Lavoro",
  Jobs:"Lavori",
  Invoice:"Fattura",
  Invoices:"Fatture",
  Vacations:"Vacanze",
  Cheques:"Controlli",
  Brand_Cheques:"Marca",
  Payment:"Pagamento",
  Enable_Payment:"Abilita pagamento",
  Payments:"Pagamenti",
  Schedule:"Programma",
  Schedules:"Orari",
  Timesheet:"Scheda attività",
  Timesheets:"Fogli orari",
  Salary:"Stipendio",
  New_Address:"Nuovo indirizzo",
  Address_2:"Indirizzo 2)",
  _City:"Città",
  _State:"Stato/Prov",
  City:"Città / Comune",
  State:"Stato/Provincia",
  Postal:"Codice postale",
  ZIP:"Postale / CAP",
  Country:"Nazione",
  Addresses:"Indirizzi",
  Required_Options:"Opzioni richieste",
  Optional_Options:"Opzioni opzionali",
  Additional_Options:"Opzioni aggiuntive",
  Required:"Necessario",
  Optional:"Opzionale",
  Additional:"aggiuntivo",
  No_Addresses:"Nessun indirizzo",
  New_Address_Word:"Aggiungi il primo <a routerLink='/folder/Address/New'>Indirizzo</a>.",
  Select_Address:"Seleziona indirizzo",
  No_Address:"Nessun indirizzo",
  Print_Cheque:"Stampa assegno",
  Print_Cheque_Now:"Stampa controlla ora",
  Description:"Descrizione",
  Pay_To_The_Order_Of:"Paga all'ordine di:",
  Select_Date_Range:"Seleziona intervallo di date",
  Select_Starting_Date:"Seleziona data di inizio",
  Select_Ending_Date:"Seleziona data di fine",
  Select_Date:"Seleziona data",
  Cheque_Date:"Controlla data",
  Cheque_Memo:"Controlla promemoria",
  Blank_Cheque:"Assegno in bianco",
  Blank:"Vuoto",
  No_Cheques:"Nessun controllo",
  No_Cheques_Word:"Stampa il tuo primo <a routerLink='/folder/Cheque/New'>Controllo</a>.",
  Cheque_Amount_Placeholder:"$ 0,00",
  View_Image:"Guarda l'immagine",
  View:"Visualizzazione",
  Modify:"Modificare",
  Delete:"Eliminare",
  Cheque_Paid:"Pagato",
  New_Deduction:"Nuova Detrazione",
  Title:"Titolo",
  Frequency:"Frequenza",
  Hourly:"Ogni ora",
  Daily:"Quotidiano",
  Weekdays:"Giorni della settimana",
  BiWeekly:"2 settimane",
  TriWeekly:"3 settimane",
  Monthly:"Mensile",
  MiMonthly:"Due mesi",
  Quarterly:"Trimestrale",
  Yearly:"Annuale",
  Every_Week:"Ogni settimana",
  Every_Payroll:"Ogni busta paga",
  Every_Month:"Ogni mese",
  Annually:"Annualmente",
  Always_Scheduled:"Sempre programmato",
  Start_Date:"Data d'inizio",
  End_Date:"Data di fine",
  Start_Time:"Ora di inizio",
  End_Time:"Tempo scaduto",
  Deduction_Label:"Etichetta di detrazione",
  Notes:"Appunti",
  Options:"Opzioni",
  Enable:"Abilitare",
  Select_Deductions:"Seleziona Detrazioni",
  Deductions:"Detrazioni",
  No_Deductions:"Nessuna detrazione",
  No_Deductions_Word:"Crea la tua prima <a routerLink='/folder/Deduction/New'>Deduzione</a>.",
  New_Employee:"Nuovo impiegato",
  No_Title:"Senza titolo",
  _Name:"Nome",
  About_Yourself:"Su te stesso",
  Full_Name:"Nome e cognome",
  Birthday:"Compleanno",
  Email:"E-mail",
  SMS:"SMS",
  Phone:"Telefono",
  Test:"Test",
  Call:"Chiamata",
  Fax:"Fax",
  Printed_Note:"Nota stampata",
  Position:"Posizione",
  Job_Position:"Posto di lavoro",
  Select_a_Job:"Seleziona un lavoro",
  Select_a_Salary:"Seleziona uno stipendio",
  Add_a_Deduction:"Aggiungi una detrazione",
  Taxes:"Le tasse",
  Add_Taxes:"Aggiungi tasse",
  Date_of_Birth:"Data di nascita",
  Email_Address:"Indirizzo email",
  Phone_Number:"Numero di telefono",
  Phone_Call:"Telefonata",
  Enable_on_Payroll:"Abilita sul libro paga",
  Select_Employees:"Seleziona dipendenti",
  No_Employees:"Nessun dipendente",
  No_Employees_Word:"Aggiungi il tuo primo nuovo <a routerLink='/folder/Employee/New'>Dipendente</a>.",
  No_Name:"Senza nome",
  Unemployeed:"Disoccupato",
  Employeed:"Impiegato",
  Paid:"Pagato",
  Enabled:"Abilitato",
  Disabled:"Disabilitato",
  Fire:"Fuoco",
  Not_Available:"Non disponibile",
  Not_Available_Word:"Stampa la tua prima <a routerLink='/folder/Invoice/New'>Fattura</a> e fatti pagare.",
  Select_Invoices:"Seleziona_Fatture",
  Print_Invoice_Word:"Stampa la tua prima <a routerLink='/folder/Invoice/New'>Fattura</a> e fatti pagare.",
  Invoice_Title:"Titolo fattura",
  Invoice_Date:"Data fattura",
  Due_Date:"Scadenza",
  New_Job:"Nuovo lavoro",
  Details:"Particolari",
  Customize:"Personalizzare",
  Customize_Dashboard:"Personalizza dashboard",
  Components:"Componenti",
  No_Components:"Nessun componente",
  Main_Components:"Componenti principali",
  Smaller_Components:"Componenti più piccoli",
  Error_Loading_Page:"Errore durante il caricamento di questa pagina.",
  Bank_Details:"Coordinate bancarie",
  About_Your_Job:"Sul tuo lavoro",
  Your_Schedule:"Il vostro programma",
  Job_Title:"Titolo di lavoro",
  Job_Description:"Descrizione del lavoro",
  Job_Details:"Dettagli di lavoro",
  Enable_Job:"Abilita lavoro",
  Pay_Period:"Periodo di pagamento",
  Perpetually_Schedule:"Programma perennemente",
  Select_Jobs:"Seleziona lavori",
  No_Jobs:"Nessun lavoro",
  Add_Jobs:"Aggiungi lavori",
  No_Jobs_Word:"Aggiungi il primo <a routerLink='/folder/Job/New'>Lavoro</a> all'elenco.",
  Count_Employees:"job.employee_count+' Dipendenti'",
  Zero_Employees:"0 dipendenti",
  New_Leave:"Nuovo congedo",
  Leave_Name:"Lascia il nome",
  Paid_Leave:"Ferie retribuite",
  Leave_Pay:"Lascia pagare",
  Indefinite_Leave:"Congedo a tempo indeterminato",
  Indefinite_Payroll:"Pagamenti a tempo indeterminato",
  Leave:"Lasciare",
  Add_Schedules:"Aggiungi orari",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Abilita assenza",
  Select_Leaves:"Seleziona foglie",
  No_Leaves:"Niente foglie di assenza",
  Leave_Of_Absence:"Congedo",
  Leaves_Of_Absence:"Foglie di assenza",
  New_Leave_of_Absense:"Nuovo congedo di assenza",
  No_Leaves_Word:"Aggiungi il primo <a routerLink='/folder/Leave/New'>Congedo</a>.",
  Not_Enabled:"Non abilitato",
  Absences:"Assenze",
  Payee:"Beneficiario",
  New_Payee:"Nuovo beneficiario",
  Payee_Identifier:"Identificatore del beneficiario",
  Payee_Name:"Nome del beneficiario",
  Payee_Title:"Titolo del beneficiario",
  Payment_Memo:"Memoria di pagamento",
  Select_Payees:"Seleziona beneficiari",
  No_Payees:"Nessun beneficiario",
  Add_Payee_Note:"Aggiungi il primo <a routerLink='/folder/Payee/New'>Beneficiario</a>.",
  New_Payees:"Nuovi beneficiari",
  About_The_Payment_Schedule:"Calendario dei pagamenti",
  Your_Payroll_Schedule:"Pagamenti automatici",
  New_Payment:"Nuovo pagamento",
  Identifier:"Identificatore",
  Select:"Selezionare",
  Memo:"Promemoria",
  Payment_Date:"Data di pagamento",
  Mark_as_Paid:"Segna come pagato",
  Select_Payments:"Seleziona pagamenti",
  No_Payments:"Nessun pagamento",
  No_Payments_Word:"Crea il primo <a routerLink='/folder/Payment/New'>Pagamento</a>.",
  Create_Payroll:"Crea busta paga",
  Properties:"Proprietà",
  Payroll_Title:"Titolo busta paga",
  Payroll_Notes:"Note sui salari",
  Payroll_Setup:"Impostazione buste paga",
  Tabulate_Payments:"Tabella Pagamenti",
  Tabulate:"Tabulare",
  By:"Di:",
  Payments_By:"Pagamenti da",
  Timesheets_And_Schedules:"Fogli orari e orari",
  Both:"Entrambi",
  Items:"Elementi",
  Add_Payees:"Aggiungi beneficiari",
  Add_Account:"Aggiungi account",
  Enable_Account:"Abilita account",
  Enable_Payroll:"Abilita libro paga",
  Print_Payroll:"Stampa busta paga",
  No_Payrolls:"Nessun libro paga",
  No_Payroll_Word:"Crea il tuo primo <a routerLink='/folder/Payroll/New'>Libro Paga</a>.",
  View_more:"VISUALIZZA_ALTRO",
  Less:"MENO",
  Add_Payroll:"Aggiungi libro paga",
  Select_Payroll:"Seleziona busta paga",
  About_Your_Salary:"Sul tuo stipendio",
  Add_Salaries:"Aggiungi stipendi",
  Add_Salary:"Aggiungi stipendio",
  Salaries:"Stipendi",
  No_Salaries:"Niente stipendi",
  No_Salaries_Word:"Aggiungi il primo <a routerLink='/folder/Salary/New'>Stipendio</a>.",
  Select_Salaries:"Seleziona stipendi",
  New_Salary:"Nuovo stipendio",
  Salary_Name:"Identificatore stipendio",
  Enable_Salary:"Abilita stipendio",
  Salary_Amount:"Importo stipendio",
  New_Schedule:"Nuovo programma",
  Schedule_Title:"Titolo programma",
  Add_Address:"Aggiungi indirizzo",
  Repeat:"Ripetere",
  Design:"Design",
  Edit_Design:"Modifica disegno",
  Edit_this_Design:"Modifica questo disegno",
  Repeat_Payment:"Ripetere il pagamento",
  Enable_Schedule:"Abilita pianificazione",
  Never:"Mai",
  Select_Schedule:"Seleziona orari",
  No_Schedules:"Nessun orario",
  No_Schedules_Word:"Crea il primo <a routerLink='/folder/Schedule/New'>Programma</a>.",
  New_Administrator:"Nuovo amministratore",
  Username:"Nome utente",
  First_Name:"Nome di battesimo",
  Last_Name:"Cognome",
  Add_an_Address:"Aggiungi un indirizzo",
  Payment_Limit:"Limite per pagamento",
  Total_Limit:"Limite totale",
  Select_Accounts:"Seleziona account",
  No_Administrators:"Nessun amministratore",
  No_Administrators_Word:"Crea il primo <a routerLink='/folder/Administrator/New'>Account amministratore</a>.",
  New_Administrators_Word:"Aggiungi il primo <a routerLink='/folder/Administrator/New'>Amministratore</a>",
  Cloud:"Nuvola",
  Backup:"Backup",
  Enable_iCloud:"Abilita iCloud",
  Enable_Google_Drive:"Abilita Google Drive",
  Enable_Microsoft_OneDrive:"Abilita Microsoft OneDrive",
  Automatically_Backup:"Backup automatico",
  FTP_Settings:"Impostazioni FTP",
  URL_File_Prompt:"Specificare il percorso per un file .xls / .xlsx / .json da importare:",
  URL_SQL_Prompt:"Specificare il percorso del file SQLite da importare:",
  FTP_Backup:"Backup FTP",
  FTP_Import:"Importazione FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Ospite",
  Port:"Porta",
  Path:"Sentiero",
  Data_Path:"Percorso dati",
  Enable_FTP_Account:"Abilita account FTP",
  HostnameIP:"Nome host",
  Password:"Parola d'ordine",
  Connection_Port:"Porta di connessione",
  Enable_MySQL_Database:"Abilita database MySQL",
  Log:"Tronco d'albero",
  Reset:"Ripristina",
  Erase:"Cancellare",
  Export:"Esportare",
  Database:"Banca dati",
  Upload_CSV:"Carica CSV",
  Download_CSV:"Scarica CSV",
  SQL_Database:"URL SQL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"Backup MySQL",
  Internal_Notes:"Note interne",
  Root_Path:"Sentiero delle radici",
  Select_Backup:"Seleziona backup",
  Add_New_Backup:"Aggiungi un nuovo backup",
  First_Backup:"Imposta il primo backup...",
  Backups:"Backup",
  Add_Backup:"Aggiungi backup",
  No_Backups:"Non ci sono backup da trovare.",
  Select_Backup_Type:"Seleziona il tipo di backup che desideri configurare...",
  Backup_Type:"Tipo di backup",
  FTP_Drive:"Unità FTP",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Unità",
  Microsoft_OneDrive:"Unità",
  Import_Fields:"Campi di importazione",
  Import_Fields_Word:"Utilizza questa sezione per selezionare <a routerLink='/folder/Holiday/New'>Importa</a> dati.",
  Upload:"Caricamento",
  Download:"Scarica",
  Download_JSON:"Scarica come dati JSON",
  Download_SQL:"Scarica come file SQL",
  New_Bank:"Nuova Banca",
  New_Account:"Nuovo account",
  New_Bank_Account:"Nuovo conto bancario",
  Upload_Image:"Carica immagine",
  Bank_Name:"Nome della banca",
  Bank_Address:"Indirizzo bancario",
  Branch_Address:"Indirizzo filiale",
  Address_on_Cheque:"Indirizzo",
  Cheque_Numbers:"Controlla i numeri",
  Cheque_Details:"Controlla dettagli",
  Bank_Logo:"Logo Banca",
  Cheque_ID:"Controlla ID",
  Starting_Cheque_ID:"Avvio controllo ID",
  Transit_Number:"Numero di transito",
  Institution_Number:"Numero istituto",
  Designation_Number:"Numero di designazione",
  Account_Number:"Numero di conto",
  Limits:"Limiti",
  Default_Limits:"Limiti predefiniti",
  Over_Limit:"Oltre il limite",
  Under_Limit:"Sotto limite",
  Payroll_Limit:"Limite buste paga",
  Enable_Bank_Account:"Abilita conto bancario",
  Select_Account:"Seleziona account",
  No_Bank_Account:"Nessun conto bancario",
  No_Bank_Account_Word:"Aggiungi il primo <a routerLink='/folder/Accounts/New'>Conto Bancario</a>.",
  Bank_Accounts:"Conto in banca",
  No_Accounts:"Nessun account",
  No_Accounts_Note:"Aggiungi il primo <a routerLink='/folder/Accounts/New'>Conto Bancario</a>.",
  Cheque_Designer:"Controlla Designer",
  Cheque_Design:"Verifica design",
  Select_Design:"Seleziona un design",
  Cheque_Designs:"Controlla i disegni",
  No_Cheque_Designs:"Nessun disegno di controllo",
  No_Cheque_Designs_Word:"Crea il tuo <a routerLink='/folder/Settings/Cheque/Design/New'>Controlla il Design</a>.",
  Set_Default:"Imposta come predefinito",
  Default:"Predefinito",
  Remove:"Rimuovere",
  Folder:"Cartella",
  Edit:"Modificare",
  LoadingDots:"Caricamento in corso...",
  Add_a_New_File:"Aggiungi un <a href='#' (click)='add()'>Nuovo File</a> a",
  this_folder:"questa cartella",
  FTP_Backup_Settings:"Impostazioni backup FTP",
  Secure_File_Transfer:"Trasferimento file sicuro",
  New_Holiday:"Nuova vacanza",
  Add_Holiday:"Aggiungi vacanza",
  Holiday_Name:"Nome festivo",
  Additional_Pay:"Paga aggiuntiva",
  Enable_Holiday:"Abilita vacanza",
  Select_Holidays:"Seleziona vacanze",
  No_Holidays:"Niente vacanze",
  No_Holidays_Word:"Aggiungi il primo <a routerLink='/folder/Holiday/New'>Festività Pubblica</a>.",
  New_Import:"Nuova importazione",
  Import_Data:"Importa dati",
  Import_Data_Word:"Seleziona il tipo di file o il metodo di caricamento che preferisci.<br /> Sarai in grado di selezionare i campi importati in un file che corrispondono a qualsiasi parametro nell'app dopo aver caricato un file supportato.", 
  Import_File:"Importare file",
  Link_To_File:"Collega al file",
  Select_File:"Seleziona il file",
  New_Moderator:"Nuovo moderatore",
  Allow_Moderation:"Consenti moderazione",
  Create_Paycheques:"Crea buste paga",
  Edit_Paycheques_and_Data:"Modifica buste paga e dati",
  Destroy_Data_and_Paycheques:"Distruggi dati e buste paga",
  Bonus_Percentage:"Percentuale busta paga",
  Fixed_Amount:"Importo fisso",
  Select_Moderator:"Seleziona moderatore",
  No_Moderators:"Nessun moderatore",
  Moderators:"Moderatori",
  Moderator_Account:"Crea il primo <a routerLink='/folder/Administrator/New'>Account Moderatore</a>.",
  No_Moderators_Word:"Aggiungi il primo <a routerLink='/folder/Administrator/New'>Moderatrice</a>.",
  Defaults:"Predefiniti",
  Provide_Us_Info:"Fornici informazioni",
  Setup_Your_Printer:"Imposta la stampante",
  Your_Company:"Informazioni sulla tua azienda",
  Company_Name:"Nome della ditta",
  Currency:"Moneta",
  Default_Currency:"Valuta predefinita",
  Base_Monthly_Income:"Reddito mensile",
  Protection:"Protezione",
  App_Protection:"Protezione app",
  PIN_Code_Protection:"Protezione codice PIN",
  App_Protection_Word:"Abilita metodi di sicurezza che aiutano a proteggere il tuo account.",
  PIN_Code:"Codice PIN",
  Change_PIN:"Cambia PIN",
  New_Password:"Nuova password",
  Geofence_Protection:"Protezione Geo-Recinzione",
  Geofence_Area:"Area prestabilita",
  Distance:"Distanza",
  Setup_Now:"Imposta ora",
  Mile:"Miglio",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Riconoscimento facciale",
  Face:"Viso",
  Setup:"Impostare",
  Label:"Etichetta",
  Password_Protection:"Protezione della password",
  Modify_Password:"Modifica password",
  New_Tax_Entry:"Nuova voce fiscale",
  New_Tax:"Nuova imposta",
  Tax_Label:"Etichetta Fiscale",
  Perpetually_Enabled:"Perennemente abilitato",
  Select_Taxes:"Seleziona tasse",
  Tax_Deductions:"Detrazioni fiscali",
  No_Tax_Deductions:"Nessuna detrazione fiscale",
  No_Tax_Deductions_Word:"Aggiungi la prima <a routerLink='/folder/Tax/New'>Imposta</a> detrazione.",
  New_Timer:"Nuovo timer",
  Start:"Inizio",
  Stop:"Fermare",
  Start_Timer:"Inizio timer",
  Stop_Timer:"Stop timer",
  Timer_Active:"Timer attivo",
  Timer:"Timer:",
  Timer_Running:"Timer: (in esecuzione)",
  Save_Timer:"Salva timer",
  New_Timesheet:"Nuovo foglio presenze",
  Select_Timesheets:"Seleziona fogli presenze",
  Work_Notes:"Note di lavoro",
  Entry_Title:"Titolo della voce",
  No_Timesheets:"Nessun foglio presenze",
  No_Timesheets_Word:"Aggiungi il primo <a routerLink='/folder/Timesheet/New'>Foglio Presenze</a>.",
  Timesheet_Submitted:"Time Sheet inviato",
  Timesheet_Congrats:"Congratulazioni! Il tuo foglio presenze è stato inviato con successo. Grazie!",
  Timesheet_Copy:"Per ricevere una copia dei tuoi documenti, forniscici il tuo indirizzo e-mail e/o numero di cellulare.",
  Submit:"Invia",
  Allow_Notifications:"Consenti notifiche",
  Untitled_Entry:"Nuovo arrivato",
  Untitled_Bank:"Banca senza titolo",
  Timesheet_Entry:"Inserimento foglio presenze",
  Work_Description:"Descrizione del lavoro",
  Enable_Timesheet:"Abilita foglio presenze",
  Submit_Timesheet:"Invia foglio presenze",
  Vacation:"Vacanza",
  New_Vacation:"Nuova Vacanza",
  Vacation_Name:"Nome della vacanza",
  Paid_Vacation:"Vacanza pagata",
  Vacation_Pay:"Ferie pagate",
  Enable_Vacation:"Abilita vacanza",
  Select_Vacations:"Seleziona Vacanze",
  No_Vacations:"Niente vacanze",
  No_Vacations_Word:"Crea la prima voce <a routerLink='/folder/Vacation/New'>Vacanza</a>.",
  Payroll_Schedule:"Programma paghe",
  Next_Payroll:"Prossimo libro paga:",
  Upcoming_Payroll:"Prossimo libro paga",
  No_Upcoming_Payroll:"Nessun libro paga in arrivo",
  Address_Book:"Rubrica",
  Archived_Documents:"Documenti archiviati",
  Dev_Mode:"Applicazione in modalità sviluppo",
  Administrators:"Amministratori",
  Privacy:"Privacy",
  None:"Nessuno",
  Select_Signature:"Seleziona firma",
  No_Signatures:"Nessuna firma",
  No_Signatures_Word:"Aggiungi la prima <a routerLink='/folder/Signature/New'>Firma</a>.",
  Repeat_Indefinitely:"Ripeti all'infinito",
  Sign:"Cartello",
  Sign_Here:"Firmare qui",
  Date_Signed:"Data firmata",
  Signature_Pad:"blocco firma",
  Account_Holder:"Titolare del conto",
  Account_Properties:"Proprietà dell'account",
  Name_On_Cheque:"Nome sull'assegno",
  Server_Hostname:"Nome host/IP del server",
  Printers:"Stampanti",
  No_Printers:"Niente stampanti",
  Printer_Exists:'Esiste già una stampante con questo nome.',
  No_Printers_Word:"Aggiungi la prima <a routerLink='/folder/Printer/New'>Stampante</a>.",
  No_Printer_Alert:"Nessuna stampante definita. Vuoi configurare una stampante?",
  Add_Printer:"Aggiungi stampante",
  New_Printer:"Nuova stampante",
  Printer_Hostname:"Nome host stampante/IP",
  Printer_Label:"Etichette per stampante",
  Printer_Protocol:"Protocollo stampante",
  Protocol:"Protocollo",
  Email_Print:"E-mail",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Presa",
  Print_Job:"Lavoro di stampa",
  Printed:"Stampato",
  Not_Printed:"Non stampato",
  Print_Jobs:"Lavori di stampa",
  Print_Queue:"Coda di stampa",
  No_Print_Jobs:"Nessun lavoro di stampa",
  No_Prints:"Crea un nuovo <a routerLink='/folder/Cheque/New'>Controllo</a> da stampare.",
  Prints:"Stampe",
  Find_Printer:"Trova stampante",
  Find_AirPrint_Devices:"Trova dispositivi AirPrint",
  Select_Printer:"Seleziona stampante",
  System_UI:"Interfaccia utente di sistema",
  Printer:"Stampante",
  Status:"Stato",
  Preview:"Anteprima",
  Enable_Print_Job:"Abilita lavoro di stampa",
  Queue_Weight:"Peso coda",
  Unlimited:"Illimitato",
  Show_Advanced_Printer_Options:"Mostra opzioni stampante avanzate",
  Advanced:"Avanzate",
  Location:"Posizione",
  Note:"Nota",
  Queue_Name:"Nome coda",
  Pages_Printed_Limit:"Limite pagine stampate",
  MultiPage_Idle_Time:"Tempo di attesa multipagina",
  Page_Count_Limit:"Limite conteggio pagine",
  Page_Orientation:"Orientamento della pagina",
  Portrait:"Ritratto",
  Landscape:"Paesaggio",
  Duplex:"Duplex",
  Double_Sided_Printing:"Doppia faccia",
  Duplex_Mode:"Modalità duplex",
  Duplex_Short:"Corto",
  Duplex_Long:"Lungo",
  Duplex_None:"Nessuno",
  Color_And_Quality:"Colore e qualità",
  Monochrome:"Monocromo",
  Photo_Quality_Prints:"Stampe di qualità fotografica",
  Printer_Email:"E-mail stampante",
  Automatically_Downsize:"Ridimensionamento automatico",
  Paper:"Carta",
  Paper_Name:"Nome carta",
  Paper_Width:"Larghezza carta",
  Paper_Height:"Altezza carta",
  Paper_Autocut_Length:"Lunghezza taglio automatico carta",
  Margins:"Margini",
  Page_Margins:"Margini della pagina",
  Page_Margin_Top:"Margine prima pagina",
  Page_Margin_Right:"Margine destro della pagina",
  Page_Margin_Bottom:"Margine pagina inferiore",
  Page_Margin_Left:"Margine sinistro della pagina",
  Add_Employees:"Aggiungi dipendenti",
  Header:"Intestazione",
  Print_A_Page_Header:"Stampa un'intestazione di pagina",
  Header_Label:"Etichetta intestazione",
  Header_Page_Index:"Indice pagina di intestazione",
  Header_Font_Name:"Carattere intestazione",
  Select_Font:"Seleziona carattere",
  Font_Selector:"Selettore font",
  Header_Font_Size:"Carattere intestazione",
  Header_Bold:"Testa grassetto",
  Header_Italic:"Intestazione Corsivo",
  Header_Alignment:"Allineamento testata",
  Left:"Sono partiti",
  Center:"Centro",
  Right:"Destra",
  Justified:"Giustificato",
  Header_Font_Color:"Colore testata",
  Select_Color:"Seleziona colore",
  Footer:"Piè di pagina",
  Print_A_Page_Footer:"Stampa un piè di pagina",
  Footer_Label:"Etichetta a piè di pagina",
  Footer_Page_Index:"Indice pagina a piè di pagina",
  Footer_Font_Name:"Carattere piè di pagina",
  Fonts:"Caratteri",
  Done:"Fatto",
  Select_Fonts:"Seleziona caratteri",
  Footer_Font_Size:"Misura del piè di pagina",
  Footer_Bold:"Piè di pagina grassetto",
  Footer_Italic:"Piè di pagina corsivo",
  Footer_Alignment:"Allineamento del piè di pagina",
  Footer_Font_Color:"Colore del piè di pagina",
  Page_Copies:"Copie di pagina",
  Enable_Printer:"Abilita stampante",
  Remote_Logging:"Registrazione remota",
  Log_Server:"Server di registro",
  Encryption:"Crittografia",
  Random_Key:"Chiave casuale",
  Encryption_Key:"Chiave di crittografia",
  Cheques_Webserver:"Banca dati personalizzata",
  Learn_How:"Impara come",
  Signature:"Firma",
  Default_Printer_Unit:"pollici/cm/mm/(pt)",
  View_Signature:"Visualizza firma",
  Printed_Signature:"Firma stampata",
  Digitally_Sign:"Firma digitale",
  Digital_Signature:"Firma digitale",
  Add_Signature:"Aggiungi firma",
  Add_Your_Signature:"Aggiungi la tua firma",
  Enable_Signature:"Abilita firma",
  Digitally_Signed:"Firma digitale",
  Insert_Error:"Impossibile salvare il controllo a causa di problemi con il database.",
  Delete_Confirmation:"Sei sicuro di voler eliminare queste informazioni?",
  Discard_Confirmation:"Sei sicuro di voler eliminare queste informazioni?",
  Discard_Bank_Confirmation:"Sei sicuro di voler eliminare questo account?",
  Discard_Printer_Confirmation:"Sei sicuro di voler eliminare questa stampante?",
  Delete_Bonus_Confirmation:"Sei sicuro di voler eliminare questo bonus?",
  Delete_Invoice_Confirmation:"Sei sicuro di voler eliminare questa fattura?",
  Generated_Cheque:"Assegno generato",
  Generated_Invoice:"Fattura generata",
  Schedule_Start_Time:"Inizio programma",
  Schedule_End_Time:"Fine programma",
  New_Call:"Nuova chiamata",
  No_Contacts:"Nessun contatto",
  No_Contacts_Word:"Amministratori, moderatori, dipendenti e beneficiari vengono visualizzati come contatti.",
  PDF_Subject:"finanze",
  PDF_Keywords:"busta paga busta paga assegni PDF assegni",
  Printer_Setup:"Impostazioni della stampante",
  Printer_Selection:"Selezione stampante",
  New_Fax:"Nuovo Fax",
  New_Fax_Message:"Nuovo messaggio fax",
  Fax_Machine:"Fax",
  Fax_Name:"Nome fax",
  Fax_Email:"E-mail fax",
  Fax_Number:"Numero di fax",
  Contents:"Contenuti",
  Fax_Body:"Corpo della pagina",
  Header_Word:"Intestazione:",
  Header_Text:"Titolo",
  Include_Header:"Includi intestazione",
  Include_Footer:"Includi piè di pagina",
  Footer_Word:"Piè di pagina:",
  Footer_Text:"Testo a piè di pagina",
  Attach_a_Cheque:"Allega un assegno",
  Add_Deduction:"Aggiungi detrazione",
  Enable_Fax:"Invia fax",
  Select_Fax:"Seleziona Fax",
  No_Faxes:"Niente fax",
  Faxes:"Fax",
  Save_and_Send:"Invia fax",
  Save_and_Pay:"Risparmia e paga",
  WARNING:"AVVERTIMENTO:",
  Remember:"Ricorda",
  Printing:"Stampa",
  Printing_Complete:"Stampa completata!\n\n",
  of:"di",
  There_Were:"C'erano ",
  Successful_Prints:" stampe di successo e ",
  Unsuccessful_Prints:" stampe non riuscite.",
  PrinterError:"Spiacente, c'è stato un errore.",
  Printing_:"Stampa...",
  PrinterSuccess:"Documento stampato correttamente.",
  PrintersSuccess:"Documenti stampati correttamente.",
  Print_Cheques:"Stampa assegni",
  New_Message:"Nuovo messaggio",
  New_Messages:"Nuovo messaggio",
  Read_Message:"Leggi messaggio",
  Write_Message:"Scrivi un messaggio",
  Send_Message:"Invia messaggio",
  Subject:"Materia",
  Message:"Messaggio",
  Writing:"Scrivere...",
  Send:"Spedire",
  Set_Date:"Impostare la data",
  Set_Time:"Tempo impostato",
  Recieve:"Ricevi",
  Set_as_Default:"Imposta come predefinito",
  Default_Account:"Account predefinito",
  Default_Design:"Disegno predefinito",
  Multiple_Cheques:"Controlli (Triplo)",
  Account_Mode:"Modalità conto",
  Multiple_Cheques_Description:"Tre controlli per pagina.",
  Check_and_Table:"Controllo e tabella",
  Check_including_Table:"Tabella di controllo e contabilità.",
  Check_Mailer:"Assegno Spedito",
  Check_Mailer_Window:"Verifica con una finestra di indirizzo.",
  DocuGard_Table_Top:"DocuGard Controlla e tabella (in alto)",
  DocuGard_Table_Middle:"DocuGard Controlla e tabella (medio)",
  DocuGard_Table_Bottom:"DocuGard Controlla e tabella (in basso)",
  DocuGard_Mailer_Top:"DocuGard Assegno Spedito (in alto)",
  DocuGard_Mailer_Middle:"DocuGard Assegno Spedito (centro)",
  DocuGard_Mailer_Bottom:"DocuGard Assegno Spedito (in basso)",
  DocuGard_Three_Cheques:"Controlli DocuGard (Triple)",
  DocuGard_Cheque_Top:"Controllo DocuGard (in alto)",
  DocuGard_Cheque_Middle:"Controllo DocuGard (medio)",
  DocuGard_Cheque_Bottom:"Controllo DocuGard (in basso)",
  DocuGard_Three_Cheques_Window:"Tre controlli su una pagina.",
  DocuGard_Table_Top_Window:"Tabella degli assegni e dei guadagni.",
  DocuGard_Table_Middle_Window:"Tabella degli assegni e dei guadagni.",
  DocuGard_Table_Bottom_Window:"Tabella degli assegni e dei guadagni.",
  DocuGard_Mailer_Top_Window:"Assegno, tabella e indirizzo.",
  DocuGard_Mailer_Middle_Window:"Assegno, tabella e indirizzo.",
  DocuGard_Mailer_Bottom_Window:"Assegno, tabella e indirizzo.",
  DocuGard_Cheque_Top_Window:"Controlla carta sicura.",
  DocuGard_Cheque_Middle_Window:"Controlla carta sicura.",
  DocuGard_Cheque_Bottom_Window:"Controlla carta sicura.",
  Basic_Cheque:"Controlla (in alto)",
  Basic_Cheque_Print:"Stampa un assegno singolo.",
  Error_Setting_As_Paid:"Errore durante l'impostazione come pagato",
  Add_Attachment:"Aggiungi allegato",
  PrinterUnavailable:"Stampante non disponibile",
  CreditCardComponent:"Carte",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Nuovo deposito",
  Deposits:"Depositi",
  No_Deposits:"Nessun deposito",
  Credit_Card_Deposit:"Carta di credito",
  New_Credit_Card_Deposit_Message:"Deposito con carta di credito",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"Deposito BitCoin",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Trasferimento Interac",
  Payments_Limit:"Limite di pagamento",
  No_Payment_Limit:"Nessun limite di pagamento",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Deposito PayPal",
  No_Deposits_Word:"Aggiungi la prima azienda <a routerLink='/folder/Deposit/New'>Deposit</a>.",
  No_Documents_Specified:"Nessun documento è stato specificato per la stampa",
  No_Printers_Added:"Nessuna stampante trovata. Vai su Impostazioni > Stampanti per aggiungerne una.",
  DB_Erase_Prompt:"Cancellare completamente l'intero database? ATTENZIONE: perderai tutte le informazioni memorizzate!",
  Console_Warning:"Non incollare alcun testo in questa console. Potresti mettere te stesso e/o la tua azienda a serio rischio.",
  No_Faxes_Word:"Crea il primo <a routerLink='/folder/Fax/New'>Fax</a>.",
  Cheque_Delete_Confirmation:"Sei sicuro di voler eliminare questo assegno?",
  Design_Delete_Confirmation:"Sei sicuro di voler eliminare questo disegno?",
  Cheque_Pay_Confirmation:"Segna questo assegno come pagato? NON apparirà nella coda di stampa.",
  Payment_Pay_Confirmation:"Segna questo pagamento come pagato? NON apparirà nella coda degli assegni.",
  Delete_Deduction_Confirmation:"Sei sicuro di voler eliminare questa detrazione?",
  Delete_Job_Confirmation:"Sei sicuro di voler eliminare questo lavoro?",
  Delete_Timesheet_Confirmation:"Sei sicuro di voler eliminare questo foglio presenze?",
  Delete_Schedule_Confirmation:"Sei sicuro di voler eliminare questo programma?",
  Delete_Setting_Confirmation:"Sei sicuro di voler ripristinare questa impostazione?",
  Delete_Fax_Confirmation:"Sei sicuro di voler eliminare questo fax?",
  Delete_File_Confirmation:"Sei sicuro di voler eliminare questo file?",
  Delete_Vacation_Confirmation:"Sei sicuro di voler eliminare questa vacanza?",
  Delete_Printer_Confirmation:"Sei sicuro di voler eliminare questa stampante?",
  Remove_Design_Confirmation:"Sei sicuro di voler eliminare questo disegno?",
  Delete_Payroll_Confirmation:"Sei sicuro di voler eliminare questo libro paga?",
  Send_Fax_Email_Confirmation:"Vuoi inviare via fax ed e-mail questi documenti?",
  Send_Email_Confirmation:"Vuoi inviare via email questo documento?",
  Send_Fax_Confirmation:"Vuoi inviare via fax questo documento?",
  Error_Generating_PDF:"Spiacenti. Si è verificato un errore durante la generazione di questo documento.",
  PDF_Error_Saving_Image:"Spiacenti. Si è verificato un errore durante il salvataggio dell'immagine PDF di questo documento.",
  Test_Printer_Confirmation:"Vuoi stampare una pagina di prova su questa stampante?",
  Save_Timesheet_Prompt:"Aggiungi un 'Titolo' o premi 'Avvia timer' per salvare.",
  Remove_Geofence_Prompt:"Sei sicuro di voler rimuovere la posizione di questa geo-recinzione?",
  Delete_Employee_Confirmation:"Sei sicuro di voler eliminare ",
  Fire_Employee_Confirmation:"Sei sicuro di voler sparare"
}