export const Ja = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"著作権とコピー; 2023年",
  black:"黒",
  green:"緑",
  gold:"金",
  blue:"青",
  brown:"茶色",
  purple:"紫",
  pink:"ピンク",
  red:"赤",
  Swatches:"見本",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"色相",
  Saturation:"飽和",
  Lightness:"軽さ",
  Upgrade_To_Pro:"プロ仕様にアップグレードする",
  AllFeaturesInclude:"すべての機能は次のとおりです。",
  PrintUnlimitedCheques:"無制限の小切手を印刷",
  PremiumChequeDesigns:"プレミアムチェックデザイン",
  ManageUnlimitedEmployees:"無制限の従業員を管理",
  AddUnlimitedPayees:"無制限の受取人を追加",
  CreateUnlimitedPayrolls:"無制限の給与計算を作成",
  UnlimitedSchedulesandTimeSheets:"無制限のスケジュールとタイムシート",
  BulkPayPalPayouts:"一括 PayPal 支払い",
  UnlimitedBankAccounts:"無制限の銀行口座",
  ManageMultipleCompanies:"複数の会社を管理する",
  TrackInsurancePolicies:"保険証券を追跡する",
  Bio_MetricProtection:"バイオメトリック保護",
  Geo_FenceLock_OutProtection:"ジオフェンス ロックアウト保護",
  Multiple_Companies_Word:"Checks プレミアムがないと、複数の会社を管理することはできません。",
  PayPal_Payouts_Word:"小切手プレミアムなしでは、PayPal 支払いは無効になります。",
  PINProtection:"PIN 保護",
  PasswordProtection:"パスワード保護",
  May_Require_Approval:"承認が必要な場合があります。",
  Subscribe:"購読",
  Billed:"請求済み",
  Up:"上",
  Down:"下",
  Positioning:"ポジショニング",
  Marker:"マーカー",
  Drag_Marker:"ドラッグ マーカー",
  Tagline:"小切手の印刷と給与計算",
  Marker_Word:"マーカーを使用して要素のサイズを調整します。",
  Pinch_Zoom:"ピンチズーム",
  Pinch_Word:"ピンチして要素をズームします。",
  Drag:"引っ張る",
  Drag_Word:"指を使って要素をドラッグします。",
  subscription_alias_word:"自動更新サブスクリプション",
  premium_alias_word:"ワンタイム アップグレード パッケージ",
  print_alias_word:"個別小切手の印刷",
  mode_alias_word:"モード",
  Pro:"プロ",
  Pro_word:"プロ版が必要です。",
  Cant_Delete_Default_Company_Word:"デフォルトの会社は削除できません。",
  Reinsert_Default_Designs:"デフォルトのデザインを再挿入",
  Reinsert_Default_Designs_word:"デフォルトのデザインを再挿入しますか?",
  Subscription_Active_Disable_Word:"このサブスクリプションはアクティブです。この Cheques へのサブスクリプションをキャンセルしますか?",
  Company_Logo:"会社のロゴ",
  ZERO_:"ゼロ",
  Disclaimer:"免責事項",
  Privacy_Policy:"プライバシーポリシー",
  EULA:"EULA を確認します",
  Terms_Of_Service:"利用規約",
  Terms_Of_Use:"利用規約",
  Refunds:"代金返却方針",
  Single_Print:"1 チェック",
  Two_Prints:"2 チェック",
  Five_Prints:"5 チェック",
  Ten_Prints:"10 チェック",
  Fifteen_Prints:"15 チェック",
  Twenty_Prints:"20 チェック",
  Thirty_Prints:"30チェック",
  Image_Added:"画像追加",
  Image_Preview:"画像プレビュー",
  No_Image_Was_Selected:"画像が選択されていません。",
  Cheques_Unlimited:"無制限の小切手",
  _Subscription:"サブスクリプション",
  Subscription:"小切手 - 1 か月",
  Two_Month_Subscription:"小切手 - 2 か月",
  Three_Month_Subscription:"小切手 - 3 か月",
  Six_Month_Subscription:"小切手 - 6 か月",
  Twelve_Month_Subscription:"小切手 - 12 か月",
  Cheques_Are_Available:"チェックは印刷可能です。",
  Upgrade_Required_Two:"パッケージを選択し、価格ボタンをダブルタップして購入を開始します。プロ並みのフルカラー チェックを数秒で印刷します。",
  Month:"月",
  Due:"期限：",
  Expires:"有効期限:",
  Subscription_Active:"サブスクリプション有効",
  Subscription_Inactive:"サブスクリプション非アクティブ",
  Purchase_Additional_Cheques:"追加の小切手を購入しますか?",
  Printable_Cheque:"印刷可能な小切手",
  Printable_Cheques:"印刷可能な小切手",
  Printable_Cheque_Word:"小切手はあなたのアカウントで利用できます。",
  Printable_Cheques_Word:"小切手はあなたのアカウントで利用できます。",
  Max_Amount_Message:"指定した金額は、このシステムに設定された最大金額を超えています:",
  Terms_Required_Word:"Cheques の使用を継続する前に、この契約に同意する必要があります。",
  Subscriptions:"サブスクリプション",
  Product_Upgrades:"アップグレード",
  Mailed_Out_Cheques:"郵送小切手",
  Enter_A_Company_Name:"会社名を入力してください。",
  Single_Cheques:"シングルチェック",
  Cheque_Golden:"ゴールデンチェック",
  Cheque_Golden_Window:"ゴールデンチェックのデザイン。",
  Cheque_Green:"グリーンチェック",
  Cheque_Green_Window:"グリーンチェックのデザイン。",
  Cheque_Red:"レッドチェック",
  Cheque_Red_Window:"赤チェックのデザイン。",
  Cheque_Yellow:"イエローチェック",
  Cheque_Yellow_Window:"イエローチェックのデザイン。",
  Cheque_Statue_of_Liberty:"自由の女神",
  Cheque_Statue_of_Liberty_Window:"自由の女神チェックのデザイン。",
  Cheque_Green_Wave:"グリーンウェーブ",
  Cheque_Green_Wave_Window:"グリーンチェックのデザイン。",
  Cheque_Golden_Weave:"ゴールデンウィーブ",
  Cheque_Golden_Weave_Window:"上品なゴールドチェックのデザイン。",
  Cheque_Green_Sun:"グリーンサン",
  Cheque_Green_Sun_Window:"深みのある落ち着いたチェックデザイン。",
  Cheque_Blue_Checkers:"ブルーチェッカーズ",
  Cheque_Blue_Checkers_Window:"ブルーチェックのデザイン。",
  Cashiers_Check:"キャッシャーズチェック",
  Cashiers_Check_Window:"レジ係のチェック スタイル テンプレート。",
  Cheque_Aqua_Checkers:"アクアチェッカーズ",
  Cheque_Aqua_Checkers_Window:"アクアチェックデザイン。",
  Cheque_Golden_Checkers:"ゴールデンチェッカー",
  Cheque_Golden_Checkers_Window:"ゴールデンチェックのデザイン。",
  Upgrade_Unavailable:"利用できないアップグレード",
  Bank_Code_Protection:"銀行番号の保護",
  Bank_Code_Protection_Word:"別のデバイスで実行されているこのアプリで、または別のユーザーのために銀行番号が使用されないように保護してください。このアクションは不可逆的です。続く？",
  Bank_Code_Protection_Blocked_Word:"使用しようとしているバンク番号は、別のユーザーまたはデバイス用に予約されています。",
  Bank_Code_Protection_Error_Word:"番号の確認に失敗しました。インターネットに接続して、この銀行口座をもう一度追加してみてください。",
  Bank_Code_Protection_Set_Error_Word:"銀行番号の保護には、インターネットへの接続が必要です。接続してからもう一度お試しください。",
  Upgrade_Unavailable_Word:"申し訳ありませんが、あなたを確認できませんでした。 Checks のサブスクリプションとアップグレードは、現在、お住まいの地域では購入できません。",
  PayPal_Payment_Preview:"PayPal 支払いのプレビュー",
  Apple_Pay:"アップルペイ",
  Select_PayPal:"ペイパルを選択",
  PayPal_Applications:"PayPal アプリケーション",
  Purchase_With_Apple_Pay:"Apple Payで購入",
  Google_Pay:"Google Pay",
  Companies:"企業",
  Insurance:"保険",
  New_PayPal:"新しいペイパル",
  Pay_By:"によって支払います",
  Insure:"保証する",
  Miles:"マイル",
  Payment_Method:"支払方法",
  Select_Policies:"ポリシーを選択",
  Policies:"ポリシー",
  Policy:"ポリシー",
  No_PayPal_Account:"PayPal アカウントがありません",
  No_Policies:"保険契約なし",
  New_Policy:"新しいポリシー",
  PayPal_Payment:"ペイパル支払い",
  PayPal_Payments:"ペイパル支払い",
  No_Payment_Selected:"支払いが選択されていません",
  Sending_Payment_Word:"お待ちください... この支払いを送信しています。",
  Sending_Payments_Word:"お待ちください... 支払いを送信しています。",
  No_Payment_Selected_PayPal:"<a routerLink='/folder/Payments'>PayPal 支払い</a> が選択されていません。",
  Payment_Sent:"支払いが送信されました",
  PayPal_Payment_Sent:"この支払いは PayPal で送信されました。",
  Copay:"自己負担",
  Dead:"死",
  Alive:"生きている",
  Not_Dead:"死んでいません",
  Unclaimed:"未請求",
  Attempted:"試みた",
  Deceased:"故人",
  Claimed:"請求済み",
  Unpaid:"未払い",
  Sending_Payment:"支払いの送信",
  Sending_Payments:"支払いの送信",
  Send_PayPal_Confirmation:"この取引を PayPal で送信しますか?",
  Send_PayPal_Confirmation_Word:"緑色のボタンを押して、このトランザクションを送信します。",
  Save_Payment_As_Unpaid:"この支払いを未払いとして保存しますか?",
  Payment_Pay_Confirmation_PayPal:"この支払いを支払い済として保存しますか?",
  No_Policies_Word:"最初の<a routerLink='/folder/Postage/New'>保険証券</a> を今すぐ追加してください。",
  Timesheet_Multiple_Delete_Confirmation:"複数のタイムシートを削除してもよろしいですか?",
  Select_Multiple_Timesheets_Prompt:"タイムシートが選択されていません。少なくとも 1 つのタイムシートを選択してください。",
  Select_Multiple_Policies_Prompt:"ポリシーが選択されていません。少なくとも 1 つの保険証券を選択してください。",
  Policies_Multiple_Delete_Confirmation:"複数のポリシーを削除してもよろしいですか?",
  Company:"会社",
  Add_Company:"会社を追加",
  New_Company:"会社を追加",
  No_Companies:"会社なし",
  Enable_Company:"会社を有効にする",
  Select_Company:"会社を選択",
  The_Default_Company:"デフォルトの会社ラベル。",
  Manage_Companies:"会社の管理",
  No_Companies_Word:"チェックはデフォルトの会社を使用します。<br /> <a routerLink='/folder/Company/New'>最初の会社</a> を追加します。",
  Default_Company:"デフォルトの会社",
  Cheques_Unlimited_Word:"Checks Unlimited では、好きなだけ小切手を印刷できます。",
  Cheques_Subscription_Word:"Checks サブスクリプションでは、好きなだけ小切手を印刷できます。",
  You_Own_This_Product:"あなたはこの製品を所有しています。",
  Your_Subscription_is_Active:"サブスクリプションはアクティブです。",
  Active_Products:"活性化製品",
  Purchase_Confirmation:"購入",
  Purchase_Cheques:"購入小切手",
  Restore_Purchase:"購入を復元",
  Erase_Purchase:"購入を消去",
  Successfully_Purchased:"購入しました",
  Enter_Your_Licence_Key:"このページでライセンス キーを入力して、この製品をアクティベートしてください。",
  Licence_Key:"ライセンスキー",
  Enter_Licence_Key:"ライセンスキーを入力します",
  Purchased:"購入した",
  Enable_Feature:"機能を有効にする",
  Cancel_Subscription:"サブスクリプションをキャンセルする",
  Subscription_Removed:"サブスクリプションが削除されました",
  Select_Active_Subscription:"アクティブなサブスクリプションを選択して変更します。",
  Remove_Subscription_Word:"このサブスクリプションをキャンセルしてもよろしいですか?",
  Delete_Company_Confirmation:"この会社全体を削除してもよろしいですか?警告: 保存されたすべての情報が失われます!",
  Delete_Default_Company_Word:"デフォルトの会社は削除できません。アプリケーションをリセットしてデフォルトの状態に戻しますか?警告: 保存されたすべての情報が失われます!",
  Console_Warning_2:"現在あなたのものではない通貨をこのアプリケーションを使用して処理しないでください。",
  Terms_and_Conditions:"規約と条件",
  and_the:"そしてその",
  for:"ために",
  Please_Read_Word:"を読んで同意してください",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"一部の通貨換算レートが見つかりませんでした。インターネットに接続してください。",
  Free:"無料",
  DB_Erase_Prompt_2:"開発者データベース全体を完全に消去しますか?警告: すべての購入およびサブスクリプション情報が失われます!",
  Successfully_Imported:"正常にインポートされました",
  Select_Postage:"送料を選択",
  No_Postage:"切手なし",
  No_Paid_Postage_Word:"最初の<a routerLink='/folder/Postage/New'>有料送料</a> スタンプを追加します。",
  Trial_Complete:'試用完了',
  Please_Upgrade:'印刷を続行するには、Checks をアップグレードしてください。',
  Aa:"Aa",
  Color:"色",
  Font:"フォント",
  Guide:"ガイド",
  Guides:"ガイド",
  Image:"画像",
  Zoom:"ズーム",
  Logo:"ロゴ",
  Bank:"銀行",
  MICR:"MICR",
  Total:"合計",
  Cancel:"キャンセル",
  Confirm:"確認",
  Method:"方法",
  Biometric_Security:"生体認証セキュリティ",
  Please_Login_To_Continue:"続行するにはログインしてください。",
  Complete:"完了",
  Sign_Up:"サインアップ",
  Error:"エラー",
  Biometrics:"バイオメトリクス",
  Percent:"パーセント",
  Zero_Percent:"0％",
  Top_Margin:"トップマージン",
  Bottom_Margin:"下マージン",
  Left_Margin:"左マージン",
  Right_Margin:"右マージン",
  MICR_Margin:"MICRマージン",
  Table_Margin:"テーブルマージン",
  Address_Margin:"住所証拠金",
  Percentage_:"パーセンテージ",
  Vacation_Title:"休暇のタイトル",
  Use_PIN:"PINを使用する",
  Loading__:"読み込んでいます...",
  Design_Title:"デザインタイトル",
  Authorize:"承認する",
  Key:"鍵",
  Public_Key:"公開鍵",
  Private_Key:"秘密鍵",
  Authenticate:"認証する",
  Last_Payroll:"最後の給与",
  Last_Calculation:"最後の計算",
  Authorized:"承認済み",
  Geo_Authorized:"ジオロケーション：承認済み",
  Not_Authorized:"許可されていません",
  Authorization_Complete:"承認完了",
  Geolocation_Authorization:"ジオロケーション認証",
  Out_of_Bounds:"立入禁止で",
  Cant_Delete_Default_Design:"デフォルトのデザインは削除できません。",
  Unauthorized_Out_of_Bounds:"許可されていない：範囲外",
  Biometric_Authorization:"生体認証",
  Locating_Please_Wait:"見つけて、待ってください...",
  Test_Biometrics:"バイオメトリクスのテスト",
  Cheque_Margins:"マージンを確認する",
  Percentage_Full_Error:"パーセンテージフィールドは、100％パーセントを超えて設定することはできません。",
  No_Payroll_Text:"<a routerLink='/folder/Employee/New'>職員</a> または <a routerLink='/folder/Payee/New'>受取人</a> と <a routerLink='/folder/Schedule/New'>スケジュール</a>。",
  Design_Saved:"デザインを保存",
  Default_Design_Selected:"選択されたデフォルトのデザイン",
  Partial_Import:"部分的なインポート",
  Partial_Export:"部分的なエクスポート",
  Entire_Import:"インポート全体",
  Entire_Export:"全体のエクスポート",
  Existing_Password:"既存のパスワードを入力してください：",
  Existing_PIN:"既存のPINコードを入力してください：",
  Pin_Change_Header:"PINの確認",
  Pin_Change_SubHeader:"古いPIN番号を入力して、変更を確認します。",
  Pass_Change_Header:"パスワードの確認",
  Pass_Change_SubHeader:"古いパスワードを入力して変更を確認します。",
  PIN_Enter_Message:"PINを入力して確認します。",
  Password_Enter_Message:"確認のためにパスワードを入力してください。",
  Pin_Updated_Success:"PINが正常に更新されました。",
  Pin_Updated_Fail:"PINを更新できませんでした。",
  Pass_Updated_Success:"パスワードが正常に更新されました。",
  Pass_Updated_Fail:"パスワードを更新できませんでした。",
  Preview_Payment:"プレビュー支払い",
  Preview_Payroll:"給与のプレビュー",
  No_Payments_Created:"作成された支払いは見つかりませんでした。",
  Payment_Preview:"支払いプレビュー",
  Enable_Payee:"受取人を有効にする",
  Rendered:"レンダリング",
  No_Email:"メールなし",
  Setup_Cheques:"セットアップチェック",
  name:"名前",
  address:"住所",
  address_2:"住所2",
  city:"街",
  province:"州",
  postal_code:"郵便番号",
  country:"国",
  username:"ユーザー名",
  full_name:"フルネーム",
  birthday:"誕生日",
  email:"Eメール",
  phone:"電話",
  employees:"従業員",
  addresses:"住所",
  payment_amount_limit:"支払い額の制限",
  total_limit:"合計制限",
  payees:"受取人",
  description:"説明",
  address_line_one:"住所1行目",
  address_line_two:"住所2行目",
  image:"画像",
  account_holder:"口座名義人",
  cheque_starting_id:"開始IDを確認してください",
  transit_number:"銀行コード",
  institution_number:"機関番号",
  designation_number:"指定番号",
  account_number:"口座番号",
  currency:"通貨",
  signature:"サイン",
  payment_payroll_limit:"支払い限度額",
  total_limi:"合計制限",
  date:"日にち",
  printed_memo:"印刷されたメモ",
  banks:"銀行",
  signature_image:"署名画像",
  address_name:"住所名",
  notes:"ノート",
  design:"デザイン",
  title:"題名",
  frequency:"周波数",
  fax:"ファックス",
  salaries:"給与",
  salary_ids:"給与ID",
  start_time:"始まる時間",
  end_time:"終了時間",
  paid:"有料",
  overtime_percentage:"支払われたパーセンテージ",
  overtime_amount:"支払った固定金額",
  first_name:"ファーストネーム",
  last_name:"苗字",
  moderation:"節度",
  create:"作成",
  edit:"編集",
  destroy:"破壊",
  day_start_time:"Day_start_time",
  day_end_time:"Day_end_time",
  fullname:"名前",
  time:"時間",
  auto_send:"自動的に送信",
  time_method:"時間法",
  schedules:"スケジュール",
  indefinite_payroll_enabled:"無期限に有効にする",
  amount:"額",
  repeat:"繰り返す",
  always_enabled:"常に有効",
  start_date:"開始日",
  end_date:"終了日",
  Cheque_Total:"合計を確認",
  Total_Amount:"合計金額：",
  Amounts:"金額：",
  Images:"画像",
  Files:"ファイル",
  Previewing:"プレビュー：",
  Insert:"入れる",
  Preview_Import:"プレビューインポート",
  Entry:"エントリ",
  Entries:"エントリー",
  No_Entries:"エントリーなし",
  Import_Type:"インポートタイプ",
  Select_Details:"詳細を選択",
  Select_Payee:"受取人を選択",
  Enable_Holidays:"休日を有効にする",
  Disable_Holidays:"休日を無効にする",
  Wire_Transfer:"電信送金",
  New_Export:"新規エクスポート",
  Export_Data:"データのエクスポート",
  Export_Data_Word:"エクスポートおよびダウンロードするファイルのタイプを選択します。",
  Export_File:"ファイルのエクスポート",
  Mode:"モード",
  Times:"タイムズ",
  Widgets:"ウィジェット",
  Slider:"スライダー",
  Set_Details:"詳細を設定",
  Select_Type:"タイプを選択",
  Display_Slider:"ディスプレイスライダー",
  Dashboard_Slider:"ダッシュボードスライダー",
  Dashboard_Mode:"ダッシュボードモード",
  Show_Intro:"イントロを表示",
  Show_Payrolls:"給与を表示",
  Show_Holidays:"休日を表示",
  Show_Invoices:"請求書を表示する",
  Show_Cheques:"小切手を表示する",
  Enabled_Widgets:"有効なウィジェット",
  Disabled_Widgets:"無効化されたウィジェット",
  Colors:"色",
  Barcodes:"バーコード",
  View_Timers:"タイマーを表示",
  Gradients:"グラデーション",
  No_Info:"情報なし",
  Disable:"無効にする",
  Show_Layer:"レイヤーを表示",
  Hide_Layer:"レイヤーを非表示",
  Text_Layer:"テキストレイヤー",
  Secondly:"第二に",
  Minutely:"毎分",
  nine_am:"午前9:00",
  five_pm:"午後5:00",
  Enable_Address:"アドレスを有効にする",
  Invalid_File_Type:"申し訳ありませんが、無効なファイルタイプが選択されました。サポートされているファイルタイプ：",
  Error_Updating_Entry:"申し訳ありませんが、このエントリの更新中にエラーが発生しました。",
  Schedule_Timing_Alert:"エラー：スケジュールの開始時刻が終了時刻より後の値に設定されています。",
  Select_Multiple_Payments_Prompt:"支払いが選択されていません。少なくとも1つの支払いを選択してください。",
  Select_Multiple_Cheques_Prompt:"チェックが選択されていません。少なくとも1つのチェックを選択してください。",
  Select_Multiple_Items_Prompt:"アイテムが選択されていません。少なくとも1つのアイテムを選択してください。",
  Paymemt_Multiple_Delete_Confirmation:"複数の支払いを削除してもよろしいですか？",
  Cheque_Multiple_Delete_Confirmation:"複数のチェックを削除してもよろしいですか？",
  Payee_Multiple_Delete_Confirmation:"複数の受取人を削除してもよろしいですか？",
  Employee_Multiple_Delete_Confirmation:"複数の従業員を削除してもよろしいですか？",
  MICR_Warning:"注：一部のプリンターおよびデバイスでは、MICRフォントが正しく表示されない場合があります。",
  Automatically_Send:"自動的に送信",
  Type:"タイプ",
  Payment_Type:"払いの種類",
  Auto_Send:"自動送信",
  Automatically_Process:"自動的に処理する",
  Auto_Process:"自動処理",
  Image_Based:"画像ベース",
  Font_Based:"フォントベース",
  Rerender:"再レンダリング",
  Rendering:"レンダリング",
  Render:"ファイル",
  Top:"上",
  Middle:"真ん中",
  Bottom:"下",
  Top_Left:"左上",
  Top_Center:"トップセンター",
  Top_Right:"右上",
  Middle_Left:"左中",
  Middle_Center:"ミドルセンター",
  Middle_Right:"右中",
  Bottom_Left:"左下の",
  Bottom_Center:"下部中央",
  Bottom_Right:"右下",
  Numbers:"数字",
  Verified:"確認済み",
  Paper_Size:"用紙サイズ",
  New_Device:"新しいデバイス",
  Add_Device:"デバイスを追加",
  Remove_Device:"デバイスを削除",
  Delete_Device:"デバイスの削除",
  Block_Device:"ブロックデバイス",
  Block:"ブロック",
  Unblock:"ブロックを解除する",
  Table:"テーブル",
  Scan_Login_Code:"ログインコードをスキャンする",
  Login_Code:"ログインコード",
  Scan_Code:"スキャンコード",
  Scan_QR_Code:"QRコードをスキャンする",
  Select_All:"すべて選択",
  Deselect_All:"すべての選択を解除",
  Increase:"増加",
  Decrease:"下降",
  Bold:"大胆な",
  Text:"文章",
  Style:"スタイル",
  Italic:"イタリック",
  QR_Code:"QRコード",
  Barcode:"バーコード",
  Browse_Images:"画像を閲覧する",
  Browse_Files:"ブラウズファイル",
  Background_Image:"背景画像",
  Logo_Image:"ロゴイメージ",
  Header_Image:"ヘッダー画像",
  Bank_Image:"銀行の画像",
  Cut_Lines:"カットライン",
  Background:"バックグラウンド",
  License:"ライセンス",
  One_License:"1ライセンス：",
  Licensed:"ライセンス供与先：",
  Not_Licensed:"ライセンスなし",
  Enter_Serial:"シリアルを入力",
  Serial_Key:"シリアルキー",
  Serial:"シリアル",
  Product_Key:"プロダクトキー",
  Check_Product_Key:"プロダクトキーを確認する",
  Add_Product_Key:"プロダクトキーを追加",
  Verifying_Purchase:"購入の確認...",
  Print_Envelope:"封筒を印刷する",
  Envelope:"封筒",
  Thank_You:"ありがとう！",
  Scale:"規模",
  Print_Scale:"プリントスケール",
  Borders:"ボーダー",
  VOID:"空所",
  Void_Cheque:"ボイドチェック",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"のために支払います：",
  NO_DOLLARS:"ドルはありません ",
  ONE_DOLLAR:"1ドル",
  DOLLARS:" ドル",
  AND:" と ",
  CENTS:" セント。",
  NO_:"いいえ ",
  ONE_:"1 ",
  AND_NO_:"といいえ ",
  _AND_ONE_:"そして1つ ",
  DOLLARS_AND_ONE_CENT:" そして1セント。",
  AND_NO_CENTS:" とゼロセント。",
  CHEQUE_PRINT_DATE:"日にち：",
  CHEQUE_PRINT_MEMO:"メモ：",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"初期タスク",
  Inital_Setup:"初期設定",
  Welcome_To:"ようこそ",
  Welcome:"いらっしゃいませ",
  Swipe:"スワイプ",
  Intro_Setup:"イントロセットアップ",
  Introduction:"序章",
  CHEQUE_PRINT_CREDIT:"小切手で動く",
  Title_Intro_Word:"小切手へようこそ",
  Title_Intro:"小切手紹介",
  Title_Setup:"小切手設定",
  PayPal_Default_Email_Message:"新しいPayPal転送を受け取りました。小切手で動く",
  Cheques_App_Export:"小切手による輸出",
  Post_Intro_Thanks:"小切手をお選びいただきありがとうございます。これでセットアッププロセスは完了です。",
  Post_Intro_Word:"最初の小切手を印刷するか,将来の支払いを追加するか,従業員を給与に追加することから始めます。",
  Upgrade_Required:"小切手では,このメッセージを非表示にして追加機能のロックを解除するために,ソフトウェアのよりプレミアムなバージョンを所有している必要があります。",
  Upgrade_Title:"小切手",
  Mailout_Prompt:"さらに,小切手に給与小切手を郵送させることもできます。",
  Mailed_Cheque:"郵送小切手：",
  Mailed_Cheque_Color:"郵送小切手（色）：",
  Terms_Purchase:"小切手付きのすべての電子購入は,購入日から最大30日間全額返金されます。続行する前に,<ahref = '＃'>利用規約</a>を読んで同意してください。",
  Dashboard_Setup:"プライマリプリンタのセットアップ",
  Dashboard_Add:"プライマリ銀行口座を追加する",
  Dashboard_Customize:"小切手テンプレートを選択",
  Dashboard_Job_Salary:"あなたの仕事を作成し,あなたの給料を追加する",
  Dashboard_Employees:"従業員と受取人の追跡",
  Dashboard_Print:"給与の印刷と郵送",
  Dashboard_Payroll:"給与印刷の自動化",
  Dashboard_PayPal:"PayPalペイロール/ペイアウトの設定",
  Begin_Setup:"セットアップを開始",
  Get_Started:"はじめに",
  Purchase:"購入",
  Lockdown:"封鎖",
  Unlock:"ロック解除",
  Detailed:"詳細",
  Log_In:"ログインする",
  Login:"ログイン",
  Launch:"発売",
  Register:"登録",
  Finish:"終了",
  List:"リスト",
  Weekends:"週末",
  Weekly:"毎週",
  PayPal_Default_Subject:"新しい支払い",
  Payment_Message:"支払いメッセージ",
  PayPal_Default_Payment_Note:"ありがとうございます",
  Setup_Your_Cheqing_Account:"当座預金口座",
  Add_Your_Primary_Cheqing_Account:"プライマリcheqingアカウントを追加してください。",
  Welcome_Word:"給与および人的資源管理を簡素化および自動化します。",
  Get_Started_Quickly:"私たちが始めるのに役立ついくつかの簡単な質問に答えるだけです...",
  Done_Intro_Word:"セットアップ完了",
  PIN_Protected:"パスワードとPINで保護",
  Enter_New_PIN:"新しいPINコードを入力してください：",
  Enter_PIN:"PINコードを入力してください：",
  Invalid_PIN:"無効なPINが入力されました。",
  Account_Identifier:"アカウント識別子",
  New_Account_Identifier:"新しいアカウント識別子",
  attempt:"試み",
  attempts:"試み",
  remaining:"残り",
  Language:"言語",
  languages:"言語",
  select_languages:"言語を選択する",
  Deposit:"保証金",
  Hire_One_Now:"HireOneNow",
  App_Locked:"アプリケーションはロックされています。",
  Skip_:"スキップ",
  Skip_to_Dashboard:"ダッシュボードにスキップ",
  Dashboard:"ダッシュボード",
  Import:"輸入",
  Admin:"管理者",
  New_Admin:"新しい管理者",
  Settings:"設定",
  Color_Picker:"カラーピッカー",
  Font_Picker:"フォントピッカー",
  Logout:"ログアウト",
  Close:"選ぶ",
  Close_menu:"選ぶ",
  Excel:"Excelファイル",
  Csv:"CSVファイル",
  Sql:"SQLファイル",
  Json:"JSONファイル",
  Url:"URLでインポート",
  Back:"戻る",
  Timers:"タイマー",
  Cheque:"チェック",
  Print:"印刷",
  Designs:"デザイン",
  Pause_Printing:"印刷の一時停止",
  Resume_Printing:"印刷再開",
  Printing_Paused:"印刷一時停止",
  PrintingUnavailable:"申し訳ありませんが,このシステムでは印刷できません。",
  Prints_Paused:"印刷物の一時停止",
  Administration:"管理",
  Loading:"読み込み中",
  Unnamed:"名前なし",
  error:"申し訳ありませんが,このチェックを開いて表示することはできませんでした。",
  No_Cheques_To_Print:"印刷する小切手なし",
  No_Cheques_To_Print_Word:"<a routerLink='/folder/Cheque/New'> 新しい小切手 </a>を作成します。",
  New_Cheque:"新しい小切手",
  Start_One_Now:"今すぐ始めましょう",
  Edit_Cheque:"小切手の編集",
  Select_Cheques:"小切手の選択",
  Select_Employee:"従業員の選択",
  Default_Printer:"デフォルトプリンタ",
  Reassign:"再割り当て",
  Configure:"構成,設定",
  Template:"レンプレート",
  Designer:"デザイナー",
  Edit_Designs:"デザインの編集",
  New_Design:"新しいデザイン",
  Next:"次",
  Save:"保存する",
  Name:"名前",
  Mail:"郵便",
  Amount:"額",
  Date:"日にち",
  PayPal:"PayPal",
  Payouts:"Payouts",
  PayPal_Label:"PayPalラベル",
  Email_Username:"メール/ユーザー名",
  Client_ID:"クライアントID",
  Sandbox_Email:"サンドボックスメール",
  PayPal_Email:"PayPalメール",
  PayPal_Username:"APIユーザー名",
  PayPal_Payouts:"PayPalペイアウト",
  Select_PayPal_Key:"PayPalキーを選択",
  Secret:"ひみつ",
  API_Secret:"APIシークレット",
  PayPal_API_Keys:"PayPalキー",
  New_PayPal_Key:"新しいPayPalキー",
  Email_Subject:"メールの件名",
  Email_Message:"メールメッセージ",
  Payout_Options:"支払いオプション",
  Payment_Note:"支払いメモ",
  Enable_Employee:"従業員を有効にする",
  Enable_Production_Mode:"本番モードを有効にする",
  Sandbox_Username:"サンドボックスユーザー名",
  Sandbox_Signature:"サンドボックス署名",
  Sandbox_Password:"サンドボックスパスワード",
  Production_Username:"本番ユーザー名",
  Production_Signature:"プロダクションシグネチャー",
  Production_Password:"本番パスワード",
  Production_Email:"プロダクションメール",
  API_Client_ID:"APIクライアントID",
  API_Signature:"API署名",
  API_Password:"APIパスワード",
  API_Username:"APIユーザー名",
  Secret_Key:"秘密の鍵",
  Sandbox:"サンドボックス",
  Production:"製造",
  Sandbox_Keys:"サンドボックスキー",
  Production_Keys:"プロダクションキー",
  API_Title:"APIタイトル",
  Production_Mode:"プロダクションモード",
  Account_Label:"アカウントラベル",
  No_PayPal_Setup:"PayPalキーなし",
  Enable_PayPal_Account:"PayPalアカウントを有効にする",
  No_PayPal_Word:" <a routerLink='/folder/Invoice/New'> PayPal APIキー</a>を追加してください。",
  Printed_Memo:"印刷メモ",
  Employee:"従業員",
  View_Employee:"従業員を表示",
  Mailing_Address:"送り先",
  Company_Address:"会社の住所",
  Select_Company_Address:"会社の住所を選択",
  Address:"住所",
  Any_Day:"いつでも",
  Address_Name:"住所名",
  Unit:"単位",
  Account:"アカウント",
  Bank_Account:"銀行口座",
  Account_Limits:"アカウント制限を有効にする",
  Payroll:"ペイロール",
  New_Payroll:"新しい給与",
  No_Payroll:"今後の給与はありません",
  Upcoming_Holiday:"次の休日：",
  Invoice_Due:"請求書の期限：",
  New_Invoice:"新しい請求書",
  No_Invoices:"請求書なし",
  No_Invoices_Word:"最初の <a routerLink='/folder/Invoice/New'> 請求書 </a>を作成します。",
  Cheque_Due:"小切手期限：",
  Payrolls:"ペイロール",
  Sandbox_Mode:"サンドボックスモード",
  Hire:"雇用",
  Pay:"支払い",
  New:"新しい",
  Add:"追加",
  Make:"作る",
  Time:"時間",
  Write:"書く",
  Holiday:"ホリデー",
  Holidays:"休日",
  Next_Holiday:"次の休暇：",
  All_Done:"すべて完了！",
  Employees:"従業員",
  Payees:"ペイズ",
  Job:"仕事",
  Jobs:"仕事",
  Invoice:"請求書",
  Invoices:"請求書",
  Vacations:"休暇",
  Cheques:"小切手",
  Brand_Cheques:"ブランド",
  Payment:"支払い",
  Enable_Payment:"支払いを有効にする",
  Payments:"支払い",
  Schedule:"スケジュール",
  Schedules:"スケジュール",
  Timesheet:"タイムシート",
  Timesheets:"タイムシート",
  Salary:"給料",
  New_Address:"新しいアドレス",
  Address_2:"住所2）",
  _City:"市",
  _State:"州/州",
  City:"市/郷",
  State:"州/県",
  Postal:"郵便番号",
  ZIP:"郵便/郵便番号",
  Country:"国",
  Addresses:"住所",
  Required_Options:"必要なオプション",
  Optional_Options:"オプションオプション",
  Additional_Options:"追加オプション",
  Required:"必須",
  Optional:"オプション",
  Additional:"追加",
  No_Addresses:"アドレスなし",
  New_Address_Word:"最初の <a routerLink='/folder/Address/New'> 住所 </a>を追加します。",
  Select_Address:"住所を選択",
  No_Address:"アドレスなし",
  Print_Cheque:"印刷小切手",
  Print_Cheque_Now:"今すぐ小切手を印刷",
  Description:"説明",
  Pay_To_The_Order_Of:"のために支払います：",
  Select_Date_Range:"日付範囲の選択",
  Select_Starting_Date:"開始日を選択",
  Select_Ending_Date:"終了日を選択",
  Select_Date:"日付を選択",
  Cheque_Date:"日付の確認",
  Cheque_Memo:"チェックメモ",
  Blank_Cheque:"白地小切手",
  Blank:"空欄",
  No_Cheques:"小切手なし",
  No_Cheques_Word:"最初の <a routerLink='/folder/Cheque/New'> チェック </a>を印刷してください。",
  Cheque_Amount_Placeholder:"$ 0.00",
  View_Image:"画像を表示",
  View:"意見",
  Modify:"変更",
  Delete:"消去",
  Cheque_Paid:"有料",
  New_Deduction:"新しい控除",
  Title:"タイトル",
  Frequency:"周波数",
  Hourly:"毎時",
  Daily:"毎日",
  Weekdays:"平日",
  BiWeekly:"2週間",
  TriWeekly:"3週間",
  Monthly:"毎月",
  MiMonthly:"2ヶ月",
  Quarterly:"四半期ごと",
  Yearly:"毎年",
  Every_Week:"毎週",
  Every_Payroll:"すべての給与",
  Every_Month:"毎月",
  Annually:"毎年",
  Always_Scheduled:"常時スケジュール",
  Start_Date:"開始日",
  End_Date:"終了日",
  Start_Time:"始まる時間",
  End_Time:"終了時間",
  Deduction_Label:"控除ラベル",
  Notes:"ノート",
  Options:"オプション",
  Enable:"有効",
  Select_Deductions:"控除の選択",
  Deductions:"控除",
  No_Deductions:"控除なし",
  No_Deductions_Word:"最初の <a routerLink='/folder/Deduction/New'>控除</a> を作成します。",
  New_Employee:"新入社員",
  No_Title:"無題",
  _Name:"名前",
  About_Yourself:"あなた自身について",
  Full_Name:"フルネーム",
  Birthday:"誕生日",
  Email:"Eメール",
  SMS:"SMS",
  Phone:"電話",
  Test:"テスト",
  Call:"電話",
  Fax:"ファックス",
  Printed_Note:"印刷されたメモ",
  Position:"ポジション",
  Job_Position:"職位",
  Select_a_Job:"仕事を選ぶ",
  Select_a_Salary:"給与を選択してください",
  Add_a_Deduction:"控除を追加",
  Taxes:"税金",
  Add_Taxes:"税金を追加",
  Date_of_Birth:"生年月日",
  Email_Address:"電子メールアドレス",
  Phone_Number:"電話番号",
  Phone_Call:"電話",
  Enable_on_Payroll:"給与で有効にする",
  Select_Employees:"従業員の選択",
  No_Employees:"従業員なし",
  No_Employees_Word:"最初の新しい <a routerLink='/folder/Employee/New'>従業員</a> を追加します。",
  No_Name:"ノーネーム",
  Unemployeed:"失業者",
  Employeed:"雇用された",
  Paid:"有料",
  Enabled:"有効",
  Disabled:"無効",
  Fire:"火",
  Not_Available:"利用不可",
  Not_Available_Word:"最初の <a routerLink='/folder/Invoice/New'>請求書</a> を印刷して,支払いを受けてください。",
  Select_Invoices:"Select_Invoices",
  Print_Invoice_Word:"最初の <a routerLink='/folder/Invoice/New'>請求書</a> を印刷して,支払いを受けてください。",
  Invoice_Title:"請求書のタイトル",
  Invoice_Date:"請求書の日付",
  Due_Date:"期日",
  New_Job:"新しい仕事",
  Details:"詳細",
  Customize:"カスタマイズ",
  Customize_Dashboard:"ダッシュボードのカスタマイズ",
  Components:"コンポーネント",
  No_Components:"コンポーネントなし",
  Main_Components:"メインコンポーネント",
  Smaller_Components:"より小さなコンポーネント",
  Error_Loading_Page:"このページの読み込み中にエラーが発生しました。",
  Bank_Details:"銀行の詳細",
  About_Your_Job:"あなたの仕事について",
  Your_Schedule:"あなたのスケジュール",
  Job_Title:"職名",
  Job_Description:"仕事内容",
  Job_Details:"仕事の詳細",
  Enable_Job:"ジョブを有効にする",
  Pay_Period:"支払い期間",
  Perpetually_Schedule:"パーペチュアルスケジュール",
  Select_Jobs:"ジョブの選択",
  No_Jobs:"仕事なし",
  Add_Jobs:"ジョブの追加",
  No_Jobs_Word:"最初の <a routerLink='/folder/Job/New'>仕事</a> をリストに追加します。",
  Count_Employees:"job.employee_count + '従業員'",
  Zero_Employees:"0人の従業員",
  New_Leave:"新休暇",
  Leave_Name:"名前を残す",
  Paid_Leave:"有給休暇",
  Leave_Pay:"休暇",
  Indefinite_Leave:"無期限休暇",
  Indefinite_Payroll:"無期限の給与",
  Leave:"離れる",
  Add_Schedules:"スケジュールの追加",
  Percentage:"100％",
  Enable_Leave_Of_Absence:"不在を有効にする",
  Select_Leaves:"葉を選択",
  No_Leaves:"休職なし",
  Leave_Of_Absence:"休職",
  Leaves_Of_Absence:"休職",
  New_Leave_of_Absense:"新しい休職",
  No_Leaves_Word:"最初の <a routerLink='/folder/Leave/New'>休学</a> を追加します。",
  Not_Enabled:"有効になっていません",
  Absences:"欠席",
  Payee:"お支払い",
  New_Payee:"新しい受取人",
  Payee_Identifier:"PayeeIdentifier",
  Payee_Name:"支払者名",
  Payee_Title:"お支払いのタイトル",
  Payment_Memo:"お支払いメモ",
  Select_Payees:"受取人の選択",
  No_Payees:"受取人なし",
  Add_Payee_Note:"最初の <a routerLink='/folder/Payee/New'>受取人</a> を追加します。",
  New_Payees:"新しい受取人",
  About_The_Payment_Schedule:"支払いスケジュール",
  Your_Payroll_Schedule:"自動給与",
  New_Payment:"新しい支払い",
  Identifier:"識別子",
  Select:"選択する",
  Memo:"メモ",
  Payment_Date:"支払期日",
  Mark_as_Paid:"有料としてマーク",
  Select_Payments:"支払いの選択",
  No_Payments:"支払いなし",
  No_Payments_Word:"最初の <a routerLink='/folder/Payment/New'>支払い</a> を作成します。",
  Create_Payroll:"給与の作成",
  Properties:"プロパティ",
  Payroll_Title:"給与タイトル",
  Payroll_Notes:"ペイロールノート",
  Payroll_Setup:"PayrollSetup",
  Tabulate_Payments:"支払いの集計",
  Tabulate:"表にまとめる",
  By:"沿って：",
  Payments_By:"支払い方法",
  Timesheets_And_Schedules:"タイムシートとスケジュール",
  Both:"両方",
  Items:"アイテム",
  Add_Payees:"受取人の追加",
  Add_Account:"アカウントを追加する",
  Enable_Account:"アカウントを有効にする",
  Enable_Payroll:"給与を有効にする",
  Print_Payroll:"印刷給与",
  No_Payrolls:"給与なし",
  No_Payroll_Word:"最初の <a routerLink='/folder/Payroll/New'>給与</a> を作成します。",
  View_more:"もっと見る",
  Less:"以下",
  Add_Payroll:"給与の追加",
  Select_Payroll:"給与の選択",
  About_Your_Salary:"あなたの給料について",
  Add_Salaries:"給与の追加",
  Add_Salary:"給与を追加",
  Salaries:"給与",
  No_Salaries:"給料なし",
  No_Salaries_Word:"最初の <a routerLink='/folder/Salary/New'>給料</a> を追加します。",
  Select_Salaries:"給与の選択",
  New_Salary:"新しい給料",
  Salary_Name:"給与識別子",
  Enable_Salary:"給与を有効にする",
  Salary_Amount:"給与額",
  New_Schedule:"新しいスケジュール",
  Schedule_Title:"スケジュールタイトル",
  Add_Address:"アドレスの追加",
  Repeat:"繰り返す",
  Design:"設計",
  Edit_Design:"デザインの編集",
  Edit_this_Design:"このデザインを編集",
  Repeat_Payment:"繰り返し支払い",
  Enable_Schedule:"スケジュールを有効にする",
  Never:"一度もない",
  Select_Schedule:"スケジュールの選択",
  No_Schedules:"スケジュールなし",
  No_Schedules_Word:"最初の <a routerLink='/folder/Schedule/New'>スケジュール</a> を作成します。",
  New_Administrator:"新しい管理者",
  Username:"ユーザー名",
  First_Name:"ファーストネーム",
  Last_Name:"苗字",
  Add_an_Address:"アドレスを追加",
  Payment_Limit:"支払いごとの制限",
  Total_Limit:"合計制限",
  Select_Accounts:"アカウントの選択",
  No_Administrators:"管理者なし",
  No_Administrators_Word:"最初の <a routerLink='/folder/Administrator/New'>管理者アカウント</a> を作成します。",
  New_Administrators_Word:"最初の <a routerLink='/folder/Administrator/New'>管理者</a> を追加します",
  Cloud:"雲",
  Backup:"バックアップ",
  Enable_iCloud:"iCloudを有効にする",
  Enable_Google_Drive:"Googleドライブを有効にする",
  Enable_Microsoft_OneDrive:"MicrosoftOneDriveを有効にする",
  Automatically_Backup:"自動バックアップ",
  FTP_Settings:"FTP設定",
  URL_File_Prompt:"インポートする.xls / .xlsx / .jsonファイルの場所を指定してください：",
  URL_SQL_Prompt:"インポートするSQLiteファイルの場所を指定してください：",
  FTP_Backup:"FTPバックアップ",
  FTP_Import:"FTPインポート",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"ホスト",
  Port:"ポート",
  Path:"道",
  Data_Path:"データ経路",
  Enable_FTP_Account:"FTPアカウントを有効にする",
  HostnameIP:"ホスト名",
  Password:"パスワード",
  Connection_Port:"接続ポート",
  Enable_MySQL_Database:"MySQLデータベースを有効にする",
  Log:"ログ",
  Reset:"リセット",
  Erase:"消去",
  Export:"輸出",
  Database:"データベース",
  Upload_CSV:"CSVをアップロード",
  Download_CSV:"CSVをダウンロード",
  SQL_Database:"SQLURL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQLバックアップ",
  Internal_Notes:"内部メモ",
  Root_Path:"ルートパス",
  Select_Backup:"バックアップの選択",
  Add_New_Backup:"新しいバックアップを追加",
  First_Backup:"最初のバックアップを設定してください...",
  Backups:"バックアップ",
  Add_Backup:"バックアップの追加",
  No_Backups:"バックアップが見つかりません。",
  Select_Backup_Type:"設定するバックアップの種類を選択してください...",
  Backup_Type:"バックアップタイプ",
  FTP_Drive:"FTPドライブ",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"ドライブ",
  Microsoft_OneDrive:"ドライブ",
  Import_Fields:"フィールドのインポート",
  Import_Fields_Word:"このセクションを使用して,<a routerLink='/folder/Holiday/New'>輸入</a> データを選択します。",
  Upload:"アップロード",
  Download:"ダウンロード",
  Download_JSON:"JSONデータとしてダウンロード",
  Download_SQL:"SQLファイルとしてダウンロード",
  New_Bank:"新銀行",
  New_Account:"新しいアカウント",
  New_Bank_Account:"新しい銀行口座",
  Upload_Image:"画像をアップロード",
  Bank_Name:"銀行名",
  Bank_Address:"銀行の住所",
  Branch_Address:"支店の住所",
  Address_on_Cheque:"住所",
  Cheque_Numbers:"番号を確認してください",
  Cheque_Details:"詳細を確認",
  Bank_Logo:"銀行のロゴ",
  Cheque_ID:"IDを確認",
  Starting_Cheque_ID:"チェックIDの開始",
  Transit_Number:"銀行コード",
  Institution_Number:"機関番号",
  Designation_Number:"指定番号",
  Account_Number:"口座番号",
  Limits:"制限",
  Default_Limits:"デフォルトの制限",
  Over_Limit:"限度を超えて",
  Under_Limit:"制限下",
  Payroll_Limit:"ペイロール制限",
  Enable_Bank_Account:"銀行口座を有効にする",
  Select_Account:"アカウントを選択",
  No_Bank_Account:"銀行口座なし",
  No_Bank_Account_Word:"最初の <a routerLink='/folder/Accounts/New'>銀行口座</a> を追加します。",
  Bank_Accounts:"銀行口座",
  No_Accounts:"アカウントなし",
  No_Accounts_Note:"最初の <a routerLink='/folder/Accounts/New'>銀行口座</a> を追加します。",
  Cheque_Designer:"チェックデザイナー",
  Cheque_Design:"チェックデザイン",
  Select_Design:"デザインを選択",
  Cheque_Designs:"チェックデザイン",
  No_Cheque_Designs:"チェックデザインなし",
  No_Cheque_Designs_Word:"独自の <a routerLink='/folder/Settings/Cheque/Design/New'>デザインを確認する</a> を作成します。",
  Set_Default:"デフォルトとして設定",
  Default:"ディフォルト",
  Remove:"削除する",
  Folder:"フォルダ",
  Edit:"編集",
  LoadingDots:"読み込み中...",
  Add_a_New_File:"<a href='＃' (click)='add()'>新しいファイル</a> を追加します",
  this_folder:"このフォルダ",
  FTP_Backup_Settings:"FTPバックアップ設定",
  Secure_File_Transfer:"安全なファイル転送",
  New_Holiday:"ニューホリデー",
  Add_Holiday:"休日を追加",
  Holiday_Name:"休日の名前",
  Additional_Pay:"追加料金",
  Enable_Holiday:"休日を有効にする",
  Select_Holidays:"休日を選択",
  No_Holidays:"休日なし",
  No_Holidays_Word:"最初の <a routerLink='/folder/Holiday/New'>祝日</a> を追加します。",
  New_Import:"新規インポート",
  Import_Data:"データのインポート",
  Import_Data_Word:"選択したファイルの種類またはアップロード方法を選択してください。<br/>サポートされているファイルをアップロードした後,ファイルにインポートされたフィールドがアプリのパラメーターに対応するものを選択できます。", 
  Import_File:"インポートファイル",
  Link_To_File:"ファイルへのリンク",
  Select_File:"ファイルを選択",
  New_Moderator:"新しいモデレーター",
  Allow_Moderation:"モデレートを許可",
  Create_Paycheques:"給与の作成",
  Edit_Paycheques_and_Data:"給与とデータの編集",
  Destroy_Data_and_Paycheques:"データと給与の破棄",
  Bonus_Percentage:"給与のパーセンテージ",
  Fixed_Amount:"定額",
  Select_Moderator:"モデレーターを選択",
  No_Moderators:"モデレーターなし",
  Moderators:"モデレーター",
  Moderator_Account:"最初の <a routerLink='/folder/Administrator/New'>モデレーターアカウント</a> を作成します。",
  No_Moderators_Word:"最初の <a routerLink='/folder/Administrator/New'>モデレーター</a> を追加します。",
  Defaults:"デフォルト",
  Provide_Us_Info:"私たちに情報を提供してください",
  Setup_Your_Printer:"プリンタのセットアップ",
  Your_Company:"あなたの会社について",
  Company_Name:"会社名",
  Currency:"通貨",
  Default_Currency:"デフォルト通貨",
  Base_Monthly_Income:"月収",
  Protection:"保護",
  App_Protection:"アプリ保護",
  PIN_Code_Protection:"PINコード保護",
  App_Protection_Word:"アカウントの保護に役立つセキュリティメソッドを有効にしてください。",
  PIN_Code:"ピンコード",
  Change_PIN:"PINの変更",
  New_Password:"新しいパスワード",
  Geofence_Protection:"ジオフェンス保護",
  Geofence_Area:"セットエリア",
  Distance:"距離",
  Setup_Now:"今すぐセットアップ",
  Mile:"マイル",
  Km:"Km",
  m:"m",
  Facial_Recognition:"顔認識",
  Face:"顔",
  Setup:"設定",
  Label:"ラベル",
  Password_Protection:"パスワード保護",
  Modify_Password:"パスワードの変更",
  New_Tax_Entry:"新規納税申告",
  New_Tax:"新税",
  Tax_Label:"税ラベル",
  Perpetually_Enabled:"永続的に有効",
  Select_Taxes:"税金を選択",
  Tax_Deductions:"税額控除",
  No_Tax_Deductions:"税額控除なし",
  No_Tax_Deductions_Word:"最初の <a routerLink='/folder/Tax/New'>税</a> 控除を追加します。",
  New_Timer:"新しいタイマー",
  Start:"始める",
  Stop:"やめる",
  Start_Timer:"タイマー開始",
  Stop_Timer:"タイマー停止",
  Timer_Active:"タイマーアクティブ",
  Timer:"タイマー：",
  Timer_Running:"タイマー:(実行中）",
  Save_Timer:"タイマーを保存",
  New_Timesheet:"新しいタイムシート",
  Select_Timesheets:"タイムシートの選択",
  Work_Notes:"作業メモ",
  Entry_Title:"エントリータイトル",
  No_Timesheets:"タイムシートなし",
  No_Timesheets_Word:"最初の <a routerLink='/folder/Timesheet/New'>タイムシート</a> を追加します。",
  Timesheet_Submitted:"提出されたタイムシート",
  Timesheet_Congrats:"おめでとうございます！タイムシートが正常に送信されました。ありがとうございます！",
  Timesheet_Copy:"書類のコピーを受け取るには,メールアドレスや携帯電話番号をお知らせください。",
  Submit:"送信",
  Allow_Notifications:"通知を許可する",
  Untitled_Entry:"新規エントリー",
  Untitled_Bank:"無題の銀行",
  Timesheet_Entry:"タイムシートエントリー",
  Work_Description:"作業説明",
  Enable_Timesheet:"タイムシートを有効にする",
  Submit_Timesheet:"タイムシートの提出",
  Vacation:"休暇",
  New_Vacation:"新しい休暇",
  Vacation_Name:"休暇名",
  Paid_Vacation:"有給休暇",
  Vacation_Pay:"休暇手当",
  Enable_Vacation:"休暇を有効にする",
  Select_Vacations:"休暇を選択",
  No_Vacations:"休暇なし",
  No_Vacations_Word:"最初の <a routerLink='/folder/Vacation/New'>休暇</a> エントリを作成します。",
  Payroll_Schedule:"ペイロールスケジュール",
  Next_Payroll:"次の給与：",
  Upcoming_Payroll:"今後の給与",
  No_Upcoming_Payroll:"今後の給与はありません",
  Address_Book:"住所録",
  Archived_Documents:"アーカイブされたドキュメント",
  Dev_Mode:"開発モードのアプリケーション",
  Administrators:"管理者",
  Privacy:"プライバシー",
  None:"なし",
  Select_Signature:"署名を選択",
  No_Signatures:"署名なし",
  No_Signatures_Word:"最初の <a routerLink='/folder/Signature/New'>サイン</a> を追加します。",
  Repeat_Indefinitely:"無期限に繰り返す",
  Sign:"サイン",
  Sign_Here:"こちらにサインして下さい",
  Date_Signed:"日付署名しました",
  Signature_Pad:"署名パッド",
  Account_Holder:"口座名義人",
  Account_Properties:"アカウントのプロパティ",
  Name_On_Cheque:"小切手の名前",
  Server_Hostname:"サーバーのホスト名/ IP",
  Printers:"プリンター",
  No_Printers:"プリンタなし",
  Printer_Exists:"この名前のプリンタはすでに存在します。",
  No_Printers_Word:"最初の <a routerLink='/folder/Printer/New'>プリンター</a> を追加します。",
  No_Printer_Alert:"プリンターが定義されていません。プリンターをセットアップしますか？",
  Add_Printer:"プリンタの追加",
  New_Printer:"新しいプリンタ",
  Printer_Hostname:"プリンタのホスト名/ IP",
  Printer_Label:"プリンターラベル",
  Printer_Protocol:"プリンタプロトコル",
  Protocol:"プロトコル",
  Email_Print:"Eメール",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"ソケット",
  Print_Job:"印刷ジョブ",
  Printed:"印刷",
  Not_Printed:"印刷されていません",
  Print_Jobs:"印刷ジョブ",
  Print_Queue:"印刷キュー",
  No_Print_Jobs:"印刷ジョブなし",
  No_Prints:"印刷する新しい <a routerLink='/folder/Cheque/New'>チェック</a> を作成します。",
  Prints:"プリント",
  Find_Printer:"プリンタを探す",
  Find_AirPrint_Devices:"AirPrintデバイスの検索",
  Select_Printer:"プリンタの選択",
  System_UI:"システムUI",
  Printer:"プリンター",
  Status:"状態",
  Preview:"プレビュー",
  Enable_Print_Job:"印刷ジョブを有効にする",
  Queue_Weight:"キューの重み",
  Unlimited:"無制限",
  Show_Advanced_Printer_Options:"詳細なプリンタオプションを表示する",
  Advanced:"高度",
  Location:"位置",
  Note:"ノート",
  Queue_Name:"キュー名",
  Pages_Printed_Limit:"印刷ページ数制限",
  MultiPage_Idle_Time:"複数ページの待機時間（秒）",
  Page_Count_Limit:"ページ数制限",
  Page_Orientation:"ページの向き",
  Portrait:"ポートレート",
  Landscape:"風景",
  Duplex:"デュプレックス",
  Double_Sided_Printing:"両面",
  Duplex_Mode:"デュプレックスモード",
  Duplex_Short:"短い",
  Duplex_Long:"長いです",
  Duplex_None:"なし",
  Color_And_Quality:"色と品質",
  Monochrome:"モノクロ",
  Photo_Quality_Prints:"写真品質のプリント",
  Printer_Email:"プリンタメール",
  Automatically_Downsize:"自動的にダウンサイズ",
  Paper:"論文",
  Paper_Name:"紙の名前",
  Paper_Width:"紙幅",
  Paper_Height:"紙の高さ",
  Paper_Autocut_Length:"紙自動カット長さ",
  Margins:"マージン",
  Page_Margins:"ページマージン",
  Page_Margin_Top:"トップページマージン",
  Page_Margin_Right:"右ページマージン",
  Page_Margin_Bottom:"下部ページの余白",
  Page_Margin_Left:"左ページマージン",
  Add_Employees:"従業員の追加",
  Header:"ヘッダ",
  Print_A_Page_Header:"ページヘッダーを印刷する",
  Header_Label:"ヘッダーラベル",
  Header_Page_Index:"ヘッダーページインデックス",
  Header_Font_Name:"ヘッダーフォント",
  Select_Font:"フォントの選択",
  Font_Selector:"フォントセレクター",
  Header_Font_Size:"ヘッダーフォント",
  Header_Bold:"ヘッダーボールド",
  Header_Italic:"ヘッダーイタリック",
  Header_Alignment:"ヘッダーの配置",
  Left:"左",
  Center:"中心",
  Right:"右",
  Justified:"正当化された",
  Header_Font_Color:"ヘッダーカラー",
  Select_Color:"色を選択",
  Footer:"フッター",
  Print_A_Page_Footer:"ページフッターを印刷する",
  Footer_Label:"フッターラベル",
  Footer_Page_Index:"フッターページインデックス",
  Footer_Font_Name:"フッターフォント",
  Fonts:"フォント",
  Done:"終わり",
  Select_Fonts:"フォントの選択",
  Footer_Font_Size:"フッターサイズ",
  Footer_Bold:"フッターボールド",
  Footer_Italic:"フッターイタリック",
  Footer_Alignment:"フッターの配置",
  Footer_Font_Color:"フッターカラー",
  Page_Copies:"ページコピー",
  Enable_Printer:"プリンタを有効にする",
  Remote_Logging:"リモートロギング",
  Log_Server:"ログサーバー",
  Encryption:"暗号化",
  Random_Key:"ランダムキー",
  Encryption_Key:"暗号化キー",
  Cheques_Webserver:"カスタムデータベース",
  Learn_How:"方法を学ぶ",
  Signature:"サイン",
  Default_Printer_Unit:"in / cm / mm /（pt）",
  View_Signature:"署名を表示",
  Printed_Signature:"印刷された署名",
  Digitally_Sign:"デジタル署名",
  Digital_Signature:"デジタル署名",
  Add_Signature:"署名を追加",
  Add_Your_Signature:"署名を追加",
  Enable_Signature:"署名を有効にする",
  Digitally_Signed:"デジタル署名",
  Insert_Error:"データベースの問題により,チェックを保存できません。",
  Delete_Confirmation:"この情報を削除してもよろしいですか？",
  Discard_Confirmation:"この情報を破棄してもよろしいですか？",
  Discard_Bank_Confirmation:"このアカウントを破棄してもよろしいですか？",
  Discard_Printer_Confirmation:"このプリンタを破棄してもよろしいですか？",
  Delete_Bonus_Confirmation:"このボーナスを削除してもよろしいですか？",
  Delete_Invoice_Confirmation:"この請求書を削除してもよろしいですか？",
  Generated_Cheque:"生成された小切手",
  Generated_Invoice:"生成された請求書",
  Schedule_Start_Time:"スケジュール開始",
  Schedule_End_Time:"スケジュール終了",
  New_Call:"新しい電話",
  No_Contacts:"連絡先なし",
  No_Contacts_Word:"管理者,モデレーター,従業員,および受取人が連絡先として表示されます。",
  PDF_Subject:"金融",
  PDF_Keywords:"給与給与PDF小切手",
  Printer_Setup:"プリンタ設定",
  Printer_Selection:"プリンタの選択",
  New_Fax:"新しいファックス",
  New_Fax_Message:"新しいファックスメッセージ",
  Fax_Machine:"ファクス",
  Fax_Name:"ファックス名",
  Fax_Email:"ファックスメール",
  Fax_Number:"ファクス番号",
  Contents:"コンテンツ",
  Fax_Body:"ページ本文",
  Header_Word:"ヘッダ：",
  Header_Text:"ヘッダーテキスト",
  Include_Header:"ヘッダーを含める",
  Include_Footer:"フッターを含める",
  Footer_Word:"フッター：",
  Footer_Text:"フッターテキスト",
  Attach_a_Cheque:"小切手を添付",
  Add_Deduction:"控除を追加",
  Enable_Fax:"FAXを送信",
  Select_Fax:"FAXを選択",
  No_Faxes:"ファックスなし",
  Faxes:"ファックス",
  Save_and_Send:"FAXを送信",
  Save_and_Pay:"保存して支払う",
  WARNING:"警告：",
  Remember:"覚えて",
  Printing:"印刷",
  Printing_Complete:"印刷が完了しました！",
  of:"の",
  There_Were:"あった ",
  Successful_Prints:"成功したプリントと",
  Unsuccessful_Prints:"失敗したプリント。",
  PrinterError:"ごめんなさい！エラーがありました。",
  Printing_:"印刷...",
  PrinterSuccess:"ドキュメントは正常に印刷されました。",
  PrintersSuccess:"ドキュメントは正常に印刷されました。",
  Print_Cheques:"印刷小切手",
  New_Message:"新しいメッセージ",
  New_Messages:"新しいメッセージ",
  Read_Message:"メッセージを読む",
  Write_Message:"メッセージを書く",
  Send_Message:"メッセージを送る",
  Subject:"主題",
  Message:"メッセージ",
  Writing:"書き込み...",
  Send:"送信",
  Set_Date:"日付を設定",
  Set_Time:"時間を設定",
  Recieve:"受け取る",
  Set_as_Default:"デフォルトとして設定",
  Default_Account:"デフォルトのアカウント",
  Default_Design:"デフォルトデザイン",
  Multiple_Cheques:"小切手（リップル）",
  Account_Mode:"アカウントモード",
  Multiple_Cheques_Description:"1ページに3回のチェック",
  Check_and_Table:"チェック＆テーブル",
  Check_including_Table:"小切手と会計の表",
  Check_Mailer:"メーラーをチェック",
  Check_Mailer_Window:"アドレスウィンドウで確認してください。",
  DocuGard_Table_Top:"DocuGard 小切手&早見（上）",
  DocuGard_Table_Middle:"DocuGard 小切手&早見（中央）",
  DocuGard_Table_Bottom:"DocuGard 小切手&早見（下）",
  DocuGard_Mailer_Top:"DocuGard 小切手メーラー（上）",
  DocuGard_Mailer_Middle:"DocuGard 小切手メーラー（中央）",
  DocuGard_Mailer_Bottom:"DocuGard 小切手メーラー（下）",
  DocuGard_Three_Cheques:"DocuGard 小切手（三つ）",
  DocuGard_Cheque_Top:"DocuGard 小切手（上）",
  DocuGard_Cheque_Middle:"DocuGard 小切手（中央）",
  DocuGard_Cheque_Bottom:"DocuGard 小切手（下）",
  DocuGard_Three_Cheques_Window:"1ページに3つのチェック",
  DocuGard_Table_Top_Window:"小切手と収益の表",
  DocuGard_Table_Middle_Window:"小切手と収益の表",
  DocuGard_Table_Bottom_Window:"小切手と収益の表",
  DocuGard_Mailer_Top_Window:"チェック,テーブル,およびアドレス。",
  DocuGard_Mailer_Middle_Window:"チェック,テーブル,およびアドレス。",
  DocuGard_Mailer_Bottom_Window:"チェック,テーブル,およびアドレス。",
  DocuGard_Cheque_Top_Window:"安全紙を確認してください。",
  DocuGard_Cheque_Middle_Window:"安全紙を確認してください。",
  DocuGard_Cheque_Bottom_Window:"安全紙を確認してください。",
  Basic_Cheque:"チェック（上）",
  Basic_Cheque_Print:"1つの小切手を印刷してください。",
  Error_Setting_As_Paid:"有料としてのエラー設定",
  Add_Attachment:"添付ファイルを追加する",
  PrinterUnavailable:"プリンタは利用できません",
  CreditCardComponent:"カード",
  PaypalComponent:"PayPal",
  InteracComponent:"インタラック",
  BitcoinComponent:"ビットコイン",
  New_Deposit:"新しい預金",
  Deposits:"預金",
  No_Deposits:"デポジットなし",
  Credit_Card_Deposit:"クレジットカード",
  New_Credit_Card_Deposit_Message:"クレジットカードデポジット",
  New_BitCoin_Deposit:"ビットコイン",
  New_BitCoin_Deposit_Message:"ビットコインデポジット",
  New_Interac_Deposit:"インタラック",
  New_Interac_Deposit_Message:"InteracTransfer",
  Payments_Limit:"支払い限度額",
  No_Payment_Limit:"支払い制限なし",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPalデポジット",
  No_Deposits_Word:"最初の会社を追加します <a routerLink='/folder/Deposit/New'>保証金</a>。",
  No_Documents_Specified:"印刷するドキュメントが指定されていません",
  No_Printers_Added:"プリンタが見つかりませんでした。[設定]> [プリンタ]に移動して追加してください。",
  DB_Erase_Prompt:"データベース全体を完全に消去しますか？警告：保存されているすべての情報が失われます！",
  Console_Warning:"このコンソールにテキストを貼り付けないでください。自分自身や会社を深刻なリスクにさらす可能性があります。",
  No_Faxes_Word:"最初の <a routerLink='/folder/Fax/New'>ファックス</a> を作成します。",
  Cheque_Delete_Confirmation:"このチェックを削除してもよろしいですか？",
  Design_Delete_Confirmation:"このデザインを削除してもよろしいですか？",
  Cheque_Pay_Confirmation:"この小切手を支払い済みとしてマークしますか？印刷キューには表示されません。",
  Payment_Pay_Confirmation:"この支払いを支払い済みとしてマークしますか？チェックキューには表示されません。",
  Delete_Deduction_Confirmation:"この控除を削除してもよろしいですか？",
  Delete_Job_Confirmation:"このジョブを削除してもよろしいですか？",
  Delete_Timesheet_Confirmation:"このタイムシートを削除してもよろしいですか？",
  Delete_Schedule_Confirmation:"このスケジュールを削除してもよろしいですか？",
  Delete_Setting_Confirmation:"この設定をリセットしてもよろしいですか？",
  Delete_Fax_Confirmation:"このFAXを削除してもよろしいですか？",
  Delete_File_Confirmation:"このファイルを削除してもよろしいですか？",
  Delete_Vacation_Confirmation:"この休暇を削除してもよろしいですか？",
  Delete_Printer_Confirmation:"このプリンタを削除してもよろしいですか？",
  Remove_Design_Confirmation:"このデザインを削除してもよろしいですか？",
  Delete_Payroll_Confirmation:"この給与を削除してもよろしいですか？",
  Send_Fax_Email_Confirmation:"これらのドキュメントをファックスおよび電子メールで送信しますか？",
  Send_Email_Confirmation:"このドキュメントをメールで送信しますか？",
  Send_Fax_Confirmation:"このドキュメントをFAXで送信しますか？",
  Error_Generating_PDF:"申し訳ありません。このドキュメントの生成中にエラーが発生しました。",
  PDF_Error_Saving_Image:"申し訳ありません。このドキュメントのPDF画像の保存中にエラーが発生しました。",
  Test_Printer_Confirmation:"このプリンタでテストページを印刷しますか？",
  Save_Timesheet_Prompt:"タイトル\"を追加するか,\"タイマーの開始\"を押して保存してください。",
  Remove_Geofence_Prompt:"このジオフェンスの場所を削除してもよろしいですか？",
  Delete_Employee_Confirmation:"消去してもよろしいですか ",
  Fire_Employee_Confirmation:"発射してもよろしいですか"
}