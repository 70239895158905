export const Am = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"የቅጂ መብት & ቅጂ; 2023",
  black:"ጥቁር",
  green:"አረንጓዴ",
  gold:"ወርቅ",
  blue:"ሰማያዊ",
  brown:"ብናማ",
  purple:"ሐምራዊ",
  pink:"ሮዝ",
  red:"ቀይ",
  Swatches:"መቀያየር",
  HSL:"HSL",
  RGB:"Rgb",
  Hue:"ሂዩ",
  Saturation:"ማሞቂያ",
  Lightness:"መብራት",
  Upgrade_To_Pro:"ወደ ፕሮ አሻሽል።",
  AllFeaturesInclude:"ሁሉም ባህሪያት የሚከተሉትን ያካትታሉ:",
  PrintUnlimitedCheques:"ያልተገደበ ቼኮችን አትም",
  PremiumChequeDesigns:"የፕሪሚየም ቼክ ንድፎች",
  ManageUnlimitedEmployees:"ያልተገደበ ሰራተኞችን ያስተዳድሩ",
  AddUnlimitedPayees:"ያልተገደበ ተከፋይ ያክሉ",
  CreateUnlimitedPayrolls:"ያልተገደበ ደሞዝ ይፍጠሩ",
  UnlimitedSchedulesandTimeSheets:"ያልተገደበ የጊዜ ሰሌዳዎች እና የጊዜ ሰሌዳዎች",
  BulkPayPalPayouts:"የጅምላ PayPal ክፍያዎች",
  UnlimitedBankAccounts:"ያልተገደበ የባንክ ሂሳቦች",
  ManageMultipleCompanies:"በርካታ ኩባንያዎችን ያስተዳድሩ",
  TrackInsurancePolicies:"የኢንሹራንስ ፖሊሲዎችን ይከታተሉ",
  Bio_MetricProtection:"የባዮ-ሜትሪክ ጥበቃ",
  Geo_FenceLock_OutProtection:"የጂኦ-አጥር መቆለፊያ-ውጭ መከላከያ",
  Multiple_Companies_Word:"ብዙ ኩባንያዎችን ማስተዳደር ያለ ቼኮች ፕሪሚየም አይገኝም።",
  PayPal_Payouts_Word:"የፔይፓል ክፍያዎች ያለ ቼኮች ፕሪሚየም ተሰናክለዋል።",
  PINProtection:"የፒን ጥበቃ",
  PasswordProtection:"የይለፍ ቃል ጥበቃ",
  May_Require_Approval:"ማጽደቅ ሊፈልግ ይችላል።",
  Subscribe:"ሰብስክራይብ ያድርጉ",
  Billed:"ተከፍሏል።",
  Up:"ወደላይ",
  Down:"ወደታች",
  Positioning:"አቀማመጥ",
  Marker:"ምልክት ማድረጊያ",
  Drag_Marker:"ምልክት ማድረጊያን ይጎትቱ",
  Tagline:"ቼኮችን ያትሙ እና የደመወዝ ክፍያን ይለጥፉ",
  Marker_Word:"ኤለመንቱን ለመለካት ማርከሮችን ይጠቀሙ።",
  Pinch_Zoom:"ቁንጥጫ አጉላ",
  Pinch_Word:"ኤለመንቱን ለማጉላት ይቆንጥጡ።",
  Drag:"ጎትት",
  Drag_Word:"ንጥረ ነገሮችን ለመጎተት ጣትዎን ይጠቀሙ።",
  subscription_alias_word:"የደንበኝነት ምዝገባን በራስ-እድሳት",
  premium_alias_word:"የአንድ ጊዜ ማሻሻያ ጥቅል",
  print_alias_word:"የግለሰብ ቼኮች ያትሙ",
  mode_alias_word:"ሁነታ",
  Pro:"ፕሮ",
  Pro_word:"Pro ስሪት ያስፈልጋል።",
  Cant_Delete_Default_Company_Word:"ይቅርታ፣ ነባሪ ኩባንያዎን መሰረዝ አይችሉም ።",
  Reinsert_Default_Designs:"ነባሪ ንድፎችን እንደገና አስገባ",
  Reinsert_Default_Designs_word:"ነባሪ ንድፎችን እንደገና ማስገባት ይፈልጋሉ?",
  Subscription_Active_Disable_Word:"ይህ የደንበኝነት ምዝገባ ንቁ ነው። ይህንን የቼኮች ምዝገባ መሰረዝ ይፈልጋሉ?",
  Company_Logo:"የኩባንያ አርማ",
  ZERO_:"ዜሮ",
  Disclaimer:"ማስተባበያ",
  Privacy_Policy:"የ ግል የሆነ",
  EULA:"EULA ን ይፈትሻል",
  Terms_Of_Service:"የአገልግሎት ውል",
  Terms_Of_Use:"የአጠቃቀም መመሪያ",
  Refunds:"የተመላሽ ገንዘብ ፖሊሲ",
  Single_Print:"1 አረጋግጥ",
  Two_Prints:"2 ቼኮች",
  Five_Prints:"5 ቼኮች",
  Ten_Prints:"10 ቼኮች",
  Fifteen_Prints:"15 ቼኮች",
  Twenty_Prints:"20 ቼኮች",
  Thirty_Prints:"30 ቼኮች",
  Image_Added:"ምስል ታክሏል።",
  Image_Preview:"የምስል ቅድመ እይታ",
  No_Image_Was_Selected:"ምንም ምስል አልተመረጠም።",
  Cheques_Unlimited:"ቼኮች ያልተገደበ",
  _Subscription:"የደንበኝነት ምዝገባ",
  Subscription:"ቼኮች - 1 ወር",
  Two_Month_Subscription:"ቼኮች - 2 ወራት",
  Three_Month_Subscription:"ቼኮች - 3 ወራት",
  Six_Month_Subscription:"ቼኮች - 6 ወራት",
  Twelve_Month_Subscription:"ቼኮች - 12 ወራት",
  Cheques_Are_Available:"ቼኮች ለማተም ይገኛሉ።",
  Upgrade_Required_Two:"አንድ ጥቅል ይምረጡ እና ግዢዎን ለመጀመር የዋጋ አዝራሩን ሁለቴ መታ ያድርጉ። ባለ ሙሉ ቀለም ቼኮች በሰከንዶች ውስጥ ያትሙ።",
  Month:"ወር",
  Due:"የሚከፈልበት፡",
  Expires:"ጊዜው ያበቃል",
  Subscription_Active:"የደንበኝነት ምዝገባ ንቁ",
  Subscription_Inactive:"የደንበኝነት ምዝገባ እንቅስቃሴ-አልባ",
  Purchase_Additional_Cheques:"ተጨማሪ ቼኮች ይግዙ?",
  Printable_Cheque:"ሊታተም የሚችል ቼክ",
  Printable_Cheques:"ሊታተም የሚችል ቼኮች",
  Printable_Cheque_Word:"ቼክ በመለያዎ ላይ ይገኛል።",
  Printable_Cheques_Word:"ቼኮች በመለያዎ ላይ ይገኛሉ።",
  Max_Amount_Message:"የገለጹት መጠን ለዚህ ስርዓት ከተቀመጠው ከፍተኛ መጠን በላይ ደርሷል፡-",
  Terms_Required_Word:"ቼኮችን መጠቀም ከመቀጠልዎ በፊት በዚህ ስምምነት መስማማት አለብዎት።",
  Subscriptions:"የደንበኝነት ምዝገባዎች",
  Product_Upgrades:"ማሻሻያዎች",
  Mailed_Out_Cheques:"በፖስታ የተላኩ ቼኮች",
  Enter_A_Company_Name:"እባክዎ የኩባንያ ስም ያስገቡ።",
  Single_Cheques:"ነጠላ ቼኮች",
  Cheque_Golden:"ወርቃማ ቼክ",
  Cheque_Golden_Window:"ወርቃማ ቼክ ንድፍ.",
  Cheque_Green:"አረንጓዴ ቼክ",
  Cheque_Green_Window:"አረንጓዴ ቼክ ንድፍ.",
  Cheque_Red:"ቀይ ቼክ",
  Cheque_Red_Window:"ቀይ ቼክ ንድፍ.",
  Cheque_Yellow:"ቢጫ ቼክ",
  Cheque_Yellow_Window:"ቢጫ ቼክ ንድፍ.",
  Cheque_Statue_of_Liberty:"የነጻነት ሃውልት",
  Cheque_Statue_of_Liberty_Window:"የነጻነት ሃውልት ቼክ ዲዛይን።",
  Cheque_Green_Wave:"አረንጓዴ ሞገድ",
  Cheque_Green_Wave_Window:"አረንጓዴ ቼክ ንድፍ.",
  Cheque_Golden_Weave:"ወርቃማ ሽመና",
  Cheque_Golden_Weave_Window:"የሚያምር ወርቃማ ቼክ ንድፍ.",
  Cheque_Green_Sun:"አረንጓዴ ፀሐይ",
  Cheque_Green_Sun_Window:"ጥልቅ እና የሚያረጋጋ የፍተሻ ንድፍ.",
  Cheque_Blue_Checkers:"ሰማያዊ ቼኮች",
  Cheque_Blue_Checkers_Window:"ሰማያዊ ቼክ ንድፍ.",
  Cashiers_Check:"ገንዘብ ተቀባይ ቼክ",
  Cashiers_Check_Window:"ገንዘብ ተቀባይ ቼክ ቅጥ አብነት።",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"አኳ ቼክ ንድፍ.",
  Cheque_Golden_Checkers:"ወርቃማ ቼኮች",
  Cheque_Golden_Checkers_Window:"ወርቃማ ቼክ ንድፍ.",
  Upgrade_Unavailable:"ማሻሻያዎች አይገኙም።",
  Bank_Code_Protection:"የባንክ ቁጥር ጥበቃ",
  Bank_Code_Protection_Word:"የባንክ ቁጥሮችዎን በዚህ መተግበሪያ በሌላ መሳሪያ ላይ ወይም ለሌላ ተጠቃሚ እንዳይጠቀሙበት ይጠብቁ። ይህ እርምጃ የማይቀለበስ ነው። ይቀጥል?",
  Bank_Code_Protection_Blocked_Word:"ለመጠቀም እየሞከሩ ያሉት የባንክ ቁጥሮች ለሌላ ተጠቃሚ ወይም መሣሪያ የተያዙ ናቸው።",
  Bank_Code_Protection_Error_Word:"ቁጥር ማረጋገጥ አልተሳካም። እባክዎ ከበይነመረቡ ጋር ይገናኙ እና ይህን የባንክ ሂሳብ እንደገና ለመጨመር ይሞክሩ።",
  Bank_Code_Protection_Set_Error_Word:"የባንክ ቁጥር ጥበቃ ከበይነመረቡ ጋር እንዲገናኙ ይጠይቃል። እባክዎ ይገናኙ እና እንደገና ይሞክሩ።",
  Upgrade_Unavailable_Word:"ይቅርታ፣ እርስዎን ለማረጋገጥ እየተቸገርን ነው። የቼኮች ምዝገባዎች እና ማሻሻያዎች በአሁኑ ጊዜ በእርስዎ አካባቢ ለግዢ አይገኙም።",
  PayPal_Payment_Preview:"የ PayPal ክፍያ ቅድመ-እይታ",
  Apple_Pay:"አፕል ክፍያ",
  Select_PayPal:"PayPal ን ይምረጡ",
  PayPal_Applications:"የ PayPal መተግበሪያዎች",
  Purchase_With_Apple_Pay:"በአፕል ክፍያ ይግዙ",
  Google_Pay:"ጎግል ክፍያ",
  Companies:"ኩባንያዎች",
  Insurance:"ኢንሹራንስ",
  New_PayPal:"አዲስ PayPal",
  Pay_By:"ክፍያ በ",
  Insure:"ኢንሹራንስ",
  Miles:"ማይል",
  Payment_Method:"የመክፈያ ዘዴ",
  Select_Policies:"መመሪያዎችን ይምረጡ",
  Policies:"ፖሊሲዎች",
  Policy:"ፖሊሲ",
  No_PayPal_Account:"የ PayPal መለያ የለም።",
  No_Policies:"የኢንሹራንስ ፖሊሲዎች የሉም",
  New_Policy:"አዲስ ፖሊሲ",
  PayPal_Payment:"የ PayPal ክፍያ",
  PayPal_Payments:"የ PayPal ክፍያዎች",
  No_Payment_Selected:"ምንም ክፍያ አልተመረጠም።",
  Sending_Payment_Word:"እባክዎ ይጠብቁ... ይህ ክፍያ እየተላከ ነው።",
  Sending_Payments_Word:"እባክዎ ይጠብቁ... ክፍያዎች እየተላኩ ነው።",
  No_Payment_Selected_PayPal:"ምንም <a routerLink='/folder/Payments'> PayPal ክፍያ</a> ለመላክ አልተመረጠም።",
  Payment_Sent:"ክፍያ ተልኳል።",
  PayPal_Payment_Sent:"ይህ ክፍያ በፔይፓል ተልኳል።",
  Copay:"ኮፒ መክፈል",
  Dead:"የሞተ",
  Alive:"ሕያው",
  Not_Dead:"አልሞተም።",
  Unclaimed:"የይገባኛል ጥያቄ አልቀረበም።",
  Attempted:"ሞክሯል።",
  Deceased:"ሞተ",
  Claimed:"ይገባኛል ተብሏል።",
  Unpaid:"ያልተከፈለ",
  Sending_Payment:"ክፍያ በመላክ ላይ",
  Sending_Payments:"ክፍያዎችን በመላክ ላይ",
  Send_PayPal_Confirmation:"ይህን ግብይት በ PayPal መላክ ይፈልጋሉ?",
  Send_PayPal_Confirmation_Word:"ይህንን ግብይት ለመላክ አረንጓዴውን ቁልፍ ይጫኑ።",
  Save_Payment_As_Unpaid:"ይህ ክፍያ ያልተከፈለ እንደሆነ ይቀመጥ?",
  Payment_Pay_Confirmation_PayPal:"ይህ ክፍያ እንደተከፈለ ይቀመጥ?",
  No_Policies_Word:"የመጀመሪያውን <a routerLink='/folder/Postage/New'> የኢንሹራንስ ፖሊሲ</a> አሁን ያክሉ ።",
  Timesheet_Multiple_Delete_Confirmation:"እርግጠኛ ነዎት ብዙ የሰዓት ሉሆችን መሰረዝ ይፈልጋሉ?",
  Select_Multiple_Timesheets_Prompt:"ምንም የጊዜ ሰሌዳዎች አልተመረጡም። ቢያንስ አንድ ጊዜ ሉህ ይምረጡ።",
  Select_Multiple_Policies_Prompt:"ምንም ፖሊሲዎች አልተመረጡም። ቢያንስ አንድ የኢንሹራንስ ፖሊሲ ይምረጡ።",
  Policies_Multiple_Delete_Confirmation:"እርግጠኛ ነዎት ብዙ ፖሊሲዎችን መሰረዝ ይፈልጋሉ?",
  Company:"ኩባንያ",
  Add_Company:"ኩባንያ አክል",
  New_Company:"ኩባንያ አክል",
  No_Companies:"ኩባንያዎች የሉም",
  Enable_Company:"ኩባንያን አንቃ",
  Select_Company:"ኩባንያ ይምረጡ",
  The_Default_Company:"ነባሪ የኩባንያ መለያ።",
  Manage_Companies:"ኩባንያዎችን ያስተዳድሩ",
  No_Companies_Word:"ቼኮች ነባሪ ኩባንያ ይጠቀማሉ ።<br /> <a routerLink='/folder/Company/New'> የመጀመሪያ ኩባንያ</a> ያክሉ ።",
  Default_Company:"ነባሪ ኩባንያ",
  Cheques_Unlimited_Word:"ቼኮች Unlimited የፈለጉትን ያህል ቼኮች እንዲያትሙ ይፈቅድልዎታል።",
  Cheques_Subscription_Word:"የቼኮች ምዝገባ የፈለጉትን ያህል ቼኮች እንዲያትሙ ያስችልዎታል።",
  You_Own_This_Product:"የዚህ ምርት ባለቤት ነዎት።",
  Your_Subscription_is_Active:"የደንበኝነት ምዝገባዎ ንቁ ነው።",
  Active_Products:"ገቢር የተደረገ ምርቶች",
  Purchase_Confirmation:"ግዢ",
  Purchase_Cheques:"የግዢ ቼኮች",
  Restore_Purchase:"ግዢዎችን ወደነበሩበት ይመልሱ",
  Erase_Purchase:"ግዢዎችን አጥፋ",
  Successfully_Purchased:"በተሳካ ሁኔታ ተገዛ",
  Enter_Your_Licence_Key:"ይህንን ምርት ለማግበር እባክዎ በዚህ ገጽ ላይ የፍቃድ ቁልፍዎን ያስገቡ።",
  Licence_Key:"የፍቃድ ቁልፍ",
  Enter_Licence_Key:"የፍቃድ ቁልፍ አስገባ",
  Purchased:"የተገዛ",
  Enable_Feature:"ባህሪን አንቃ",
  Cancel_Subscription:"የደንበኝነት ምዝገባን ሰርዝ",
  Subscription_Removed:"የደንበኝነት ምዝገባ ተወግዷል",
  Select_Active_Subscription:"እሱን ለማሻሻል ንቁ የደንበኝነት ምዝገባን ይምረጡ።",
  Remove_Subscription_Word:"እርግጠኛ ነዎት ይህን የደንበኝነት ምዝገባ መሰረዝ ይፈልጋሉ?",
  Delete_Company_Confirmation:"እርግጠኛ ነዎት ይህን ኩባንያ ሙሉ በሙሉ መሰረዝ ይፈልጋሉ? ማስጠንቀቂያ፡ ሁሉንም የተከማቸ መረጃ ታጣለህ!",
  Delete_Default_Company_Word:"መረጃ ታጣለህ!",
  Console_Warning_2:"በአሁኑ ጊዜ ያንተ ያልሆነውን ይህን መተግበሪያ በመጠቀም ምንም አይነት ገንዘብ አትያዝ።",
  Terms_and_Conditions:"አተገባበሩና መመሪያው",
  and_the:"እና የ",
  for:"ለ",
  Please_Read_Word:"እባክዎ አንብበው ይስማሙ",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"አንዳንድ የገንዘብ ልወጣ ተመኖች ሊገኙ አልቻሉም። እባክዎ ከበይነመረቡ ጋር ይገናኙ።",
  Free:"ፍርይ",
  DB_Erase_Prompt_2:"መላውን የገንቢ ዳታቤዝ ሙሉ በሙሉ ይሰረዝ? ማስጠንቀቂያ፡ ሁሉንም የግዢ እና የደንበኝነት ምዝገባ መረጃ ታጣለህ!",
  Successfully_Imported:"በተሳካ ሁኔታ ከውጭ ገብቷል።",
  Select_Postage:"ፖስታ የሚለውን ይምረጡ",
  No_Postage:"የፖስታ ቴምብሮች የሉም",
  No_Paid_Postage_Word:"የመጀመሪያውን <a routerLink='/folder/Postage/New'> የሚከፈልበት ፖስታ</a> ማህተም ያክሉ ።",
  Trial_Complete:'ሙከራ ተጠናቋል',
  Please_Upgrade:'እባክዎ ማተምን ለመቀጠል ቼኮችን ያሻሽሉ።',
  Aa:"አአ",
  Color:"ቀለም",
  Font:"ቅርጸ-ቁምፊ",
  Guide:"መመሪያ",
  Guides:"አስጎብኚዎች",
  Image:"ምስል",
  Zoom:"አጉላ",
  Logo:"አርማ",
  Bank:"ባንክ",
  MICR:"MICR",
  Total:"ጠቅላላ",
  Cancel:"ሰርዝ",
  Confirm:"አረጋግጥ",
  Method:"ዘዴ",
  Biometric_Security:"የባዮሜትሪክ ደህንነት",
  Please_Login_To_Continue:"ለመቀጠል እባክዎ ይግቡ።",
  Complete:"ተጠናቀቀ",
  Sign_Up:"ክፈት",
  Error:"ስህተት",
  Biometrics:"ባዮ-ሜትሪክስ",
  Percent:"በመቶ",
  Zero_Percent:"0%",
  Top_Margin:"ከፍተኛ ህዳግ",
  Bottom_Margin:"የታችኛው ህዳግ",
  Left_Margin:"የግራ ህዳግ",
  Right_Margin:"የቀኝ ህዳግ",
  MICR_Margin:"MICR ህዳግ",
  Table_Margin:"የጠረጴዛ ህዳግ",
  Address_Margin:"የአድራሻ ህዳግ",
  Percentage_:"መቶኛ",
  Vacation_Title:"የእረፍት ርዕስ",
  Use_PIN:"ፒን ተጠቀም",
  Loading__:"በመጫን ላይ...",
  Design_Title:"የንድፍ ርዕስ",
  Authorize:"ፍቀድ",
  Key:"ቁልፍ",
  Public_Key:"የህዝብ ቁልፍ",
  Private_Key:"የግል ቁልፍ",
  Authenticate:"አረጋግጥ",
  Last_Payroll:"የመጨረሻ ደሞዝ",
  Last_Calculation:"የመጨረሻው ስሌት",
  Authorized:"የተፈቀደ",
  Geo_Authorized:"ጂኦ-ቦታ: የተፈቀደ",
  Not_Authorized:"አልተፈቀደም።",
  Authorization_Complete:"ፈቃዱ ተጠናቋል",
  Geolocation_Authorization:"የጂኦ-አካባቢ ፍቃድ",
  Out_of_Bounds:"ከድንበር ውጪ",
  Cant_Delete_Default_Design:"ነባሪ ንድፍ መሰረዝ አይቻልም።",
  Unauthorized_Out_of_Bounds:"ያልተፈቀደ፡ ከወሰን ውጪ",
  Biometric_Authorization:"የባዮሜትሪክ ፍቃድ",
  Locating_Please_Wait:"በማግኘት ላይ፣ እባክዎ ይጠብቁ...",
  Test_Biometrics:"ባዮ-ሜትሪክስን ሞክር",
  Cheque_Margins:"ህዳጎችን ያረጋግጡ",
  Percentage_Full_Error:"የመቶኛ መስኩ ከ 100% በላይ ሊዘጋጅ አይችልም።",
  No_Payroll_Text:"<a routerLink='/folder/Employee/New'>ተቀጣሪ</a> ወይም <a routerLink='/folder/Payee/New'>ከፋይ</a> እና <a routerLink='/folder/Schedule/New'> መርሐግብር</a>።",
  Design_Saved:"ንድፍ ተቀምጧል",
  Default_Design_Selected:"ነባሪ ንድፍ ተመርጧል",
  Partial_Import:"ከፊል ማስመጣት።",
  Partial_Export:"ከፊል ወደ ውጭ መላክ",
  Entire_Import:"ሙሉ ማስመጣት",
  Entire_Export:"አጠቃላይ ወደ ውጭ መላክ",
  Existing_Password:"እባክህ ያለውን የይለፍ ቃል አስገባ፡",
  Existing_PIN:"እባክህ ያለውን ፒን ኮድ አስገባ፡",
  Pin_Change_Header:"የፒን ማረጋገጫ",
  Pin_Change_SubHeader:"ለውጡን ለማረጋገጥ የድሮውን ፒን ቁጥርዎን ያስገቡ።",
  Pass_Change_Header:"የይለፍ ቃል ማረጋገጫ",
  Pass_Change_SubHeader:"ለውጡን ለማረጋገጥ የድሮ የይለፍ ቃልዎን ያስገቡ።",
  PIN_Enter_Message:"ለማረጋገጥ ፒንዎን ያስገቡ።",
  Password_Enter_Message:"ለማረጋገጥ የይለፍ ቃልዎን ያስገቡ።",
  Pin_Updated_Success:"ፒን በተሳካ ሁኔታ ዘምኗል!",
  Pin_Updated_Fail:"ፒን ሊዘመን አልቻለም።",
  Pass_Updated_Success:"የይለፍ ቃል በተሳካ ሁኔታ ዘምኗል።",
  Pass_Updated_Fail:"የይለፍ ቃል ሊዘመን አልቻለም።",
  Preview_Payment:"ክፍያን አስቀድመው ይመልከቱ",
  Preview_Payroll:"የደመወዝ ክፍያን አስቀድመው ይመልከቱ",
  No_Payments_Created:"ምንም ክፍያዎች አልተፈጠሩም።",
  Payment_Preview:"የክፍያ ቅድመ እይታ",
  Enable_Payee:"ተከፋይን አንቃ",
  Rendered:"ቀረበ",
  No_Email:"ኢሜል የለም",
  Setup_Cheques:"ማዋቀር ቼኮች",
  name:"ስም",
  address:"አድራሻ",
  address_2:"አድራሻ መስመር 2",
  city:"ከተማ",
  province:"ክፍለ ሀገር",
  postal_code:"ፖስታ / ዚፕ ኮድ",
  country:"ሀገር",
  username:"የተጠቃሚ ስም",
  full_name:"ሙሉ ስም",
  birthday:"የልደት ቀን",
  email:"ኢሜይል",
  phone:"ስልክ",
  employees:"ሰራተኞች",
  addresses:"አድራሻዎች",
  payment_amount_limit:"የክፍያ መጠን ገደብ",
  total_limit:"ጠቅላላ ገደብ",
  payees:"ተከፋይ",
  description:"መግለጫ",
  address_line_one:"አድራሻ መስመር አንድ",
  address_line_two:"አድራሻ መስመር ሁለት",
  image:"ምስል",
  account_holder:"የመለያ ባለቤት",
  cheque_starting_id:"የመነሻ መታወቂያውን ያረጋግጡ",
  transit_number:"የመጓጓዣ ቁጥር",
  institution_number:"ተቋም ቁጥር",
  designation_number:"የመጠሪያ ቁጥር",
  account_number:"መለያ ቁጥር",
  currency:"ምንዛሪ",
  signature:"ፊርማ",
  payment_payroll_limit:"የክፍያ ገደብ",
  total_limi:"ጠቅላላ ገደብ",
  date:"ቀን",
  printed_memo:"የታተመ ማስታወሻ",
  banks:"ባንኮች",
  signature_image:"የፊርማ ምስል",
  address_name:"የአድራሻ ስም",
  notes:"ማስታወሻዎች",
  design:"ንድፍ",
  title:"ርዕስ",
  frequency:"ድግግሞሽ",
  fax:"ፋክስ",
  salaries:"ደሞዝ",
  salary_ids:"የደመወዝ መታወቂያዎች",
  start_time:"የመጀመሪያ ጊዜ",
  end_time:"የመጨረሻ ጊዜ",
  paid:"የተከፈለ",
  overtime_percentage:"የተከፈለ መቶኛ",
  overtime_amount:"የተከፈለ ቋሚ መጠን",
  first_name:"የመጀመሪያ ስም",
  last_name:"ያባት ስም",
  moderation:"ልከኝነት",
  create:"ፍጠር",
  edit:"አርትዕ",
  destroy:"አጥፋ",
  day_start_time:"የቀን_መጀመሪያ_ሰዓት",
  day_end_time:"የቀን_መጨረሻ_ሰዓት",
  fullname:"ስም",
  time:"ጊዜ",
  auto_send:"በራስ-ሰር ላክ",
  time_method:"የጊዜ ዘዴ",
  schedules:"መርሃ ግብሮች",
  indefinite_payroll_enabled:"ላልተወሰነ ጊዜ አንቃ",
  amount:"መጠን",
  repeat:"ይድገሙ",
  always_enabled:"ሁልጊዜ ነቅቷል።",
  start_date:"የመጀመሪያ ቀን",
  end_date:"የመጨረሻ ቀን",
  Cheque_Total:"ጠቅላላውን ያረጋግጡ",
  Total_Amount:"አጠቃላይ ድምሩ:",
  Amounts:"መጠኖች፡-",
  Images:"ምስሎች",
  Files:"ፋይሎች",
  Previewing:"ቅድመ እይታ፡",
  Insert:"አስገባ",
  Preview_Import:"ማስመጣት ቅድመ ዕይታ",
  Entry:"መግባት",
  Entries:"ግቤቶች",
  No_Entries:"ምንም ግቤቶች የሉም",
  Import_Type:"የማስመጣት አይነት",
  Select_Details:"ዝርዝሮችን ይምረጡ",
  Select_Payee:"ተከፋይን ይምረጡ",
  Enable_Holidays:"በዓላትን አንቃ",
  Disable_Holidays:"በዓላትን አሰናክል",
  Wire_Transfer:"የሃዋላ ገንዘብ መላኪያ",
  New_Export:"አዲስ ወደ ውጭ መላክ",
  Export_Data:"ውሂብ ወደ ውጪ ላክ",
  Export_Data_Word:"ወደ ውጭ የሚላኩ እና የሚያወርዱትን የፋይል አይነት ይምረጡ።",
  Export_File:"ፋይል ወደ ውጪ ላክ",
  Mode:"ሁነታ",
  Times:"ጊዜያት",
  Widgets:"መግብሮች",
  Slider:"ተንሸራታች",
  Set_Details:"ዝርዝሮችን አዘጋጅ",
  Select_Type:"ዓይነት ይምረጡ",
  Display_Slider:"ማሳያ ተንሸራታች",
  Dashboard_Slider:"ዳሽቦርድ ተንሸራታች",
  Dashboard_Mode:"ዳሽቦርድ ሁነታ",
  Show_Intro:"መግቢያ አሳይ",
  Show_Payrolls:"ደመወዝ አሳይ",
  Show_Holidays:"በዓላትን አሳይ",
  Show_Invoices:"የክፍያ መጠየቂያዎችን አሳይ",
  Show_Cheques:"ቼኮችን አሳይ",
  Enabled_Widgets:"የነቁ መግብሮች",
  Disabled_Widgets:"የተሰናከሉ መግብሮች",
  Colors:"ቀለሞች",
  Barcodes:"ባርኮዶች",
  View_Timers:"የሰዓት ቆጣሪዎችን ይመልከቱ",
  Gradients:"ቀስቶች",
  No_Info:"ምንም መረጃ የለም።",
  Disable:"አሰናክል",
  Show_Layer:"ንብርብሮችን አሳይ",
  Hide_Layer:"ንብርብሮችን ደብቅ",
  Text_Layer:"የጽሑፍ ንብርብሮች",
  Secondly:"ሁለተኛ",
  Minutely:"በደቂቃ",
  nine_am:"9:00 AM",
  five_pm:"5:00 PM",
  Enable_Address:"አድራሻ አንቃ",
  Invalid_File_Type:"ይቅርታ፣ ልክ ያልሆነ የፋይል አይነት ተመርጧል። የሚደገፍ የፋይል አይነት፡-",
  Error_Updating_Entry:"ይቅርታ፣ ይህን ግቤት በማዘመን ላይ ስህተት ነበር።",
  Schedule_Timing_Alert:"ስህተት፡ የጊዜ ሰሌዳው የሚጀመርበት ጊዜ ከማብቂያው ጊዜ በኋላ ወደ እሴት ተቀናብሯል።",
  Select_Multiple_Payments_Prompt:"ምንም ክፍያዎች አልተመረጡም። ቢያንስ አንድ ክፍያ ይምረጡ።",
  Select_Multiple_Cheques_Prompt:"ምንም ቼኮች አልተመረጡም። እባክዎ ቢያንስ አንድ ቼክ ይምረጡ።",
  Select_Multiple_Items_Prompt:"ምንም ንጥሎች አልተመረጡም። እባክዎ ቢያንስ አንድ ንጥል ይምረጡ።",
  Paymemt_Multiple_Delete_Confirmation:"እርግጠኛ ነዎት ብዙ ክፍያዎችን መሰረዝ ይፈልጋሉ?",
  Cheque_Multiple_Delete_Confirmation:"እርግጠኛ ነዎት ብዙ ቼኮችን መሰረዝ ይፈልጋሉ?",
  Payee_Multiple_Delete_Confirmation:"እርግጠኛ ነዎት ብዙ ተከፋይን መሰረዝ ይፈልጋሉ?",
  Employee_Multiple_Delete_Confirmation:"እርግጠኛ ነዎት ብዙ ሰራተኞችን መሰረዝ ይፈልጋሉ?",
  MICR_Warning:"ማስታወሻ፡ አንዳንድ አታሚዎች እና መሳሪያዎች የ MICR ቅርጸ-ቁምፊውን በትክክል ላያሳዩ ይችላሉ።",
  Automatically_Send:"በራስ-ሰር ላክ",
  Type:"ዓይነት",
  Payment_Type:"የክፍያ ዓይነት",
  Auto_Send:"ራስ-ሰር መላክ",
  Automatically_Process:"በራስ-ሰር ሂደት",
  Auto_Process:"ራስ-ሰር ሂደት",
  Image_Based:"በምስል ላይ የተመሰረተ",
  Font_Based:"ቅርጸ-ቁምፊ-ተኮር",
  Rerender:"እንደገና ቅረጽ",
  Rendering:"ማቅረብ",
  Render:"ፋይሎች",
  Top:"ከፍተኛ",
  Middle:"መካከለኛ",
  Bottom:"ከታች",
  Top_Left:"ከላይ በግራ",
  Top_Center:"ከፍተኛ ማእከል",
  Top_Right:"ከላይ በቀኝ",
  Middle_Left:"መሃል ግራ",
  Middle_Center:"መካከለኛ ማዕከል",
  Middle_Right:"የመካከለኛው ቀኝ",
  Bottom_Left:"ከታች ግራ",
  Bottom_Center:"የታችኛው ማእከል",
  Bottom_Right:"ከታች በቀኝ",
  Numbers:"ቁጥሮች",
  Verified:"የተረጋገጠ",
  Paper_Size:"የወረቀት መጠን",
  New_Device:"አዲስ መሣሪያ",
  Add_Device:"መሣሪያ ያክሉ",
  Remove_Device:"መሣሪያን ያስወግዱ",
  Delete_Device:"መሣሪያን ሰርዝ",
  Block_Device:"መሣሪያን አግድ",
  Block:"አግድ",
  Unblock:"እገዳ አንሳ",
  Table:"ጠረጴዛ",
  Scan_Login_Code:"የመግቢያ ኮድ ይቃኙ",
  Login_Code:"የመግቢያ ኮድ",
  Scan_Code:"የቃኝ ኮድ",
  Scan_QR_Code:"የQR ኮድን ይቃኙ",
  Select_All:"ሁሉንም ምረጥ",
  Deselect_All:"ሁሉንም አይምረጡ",
  Increase:"ጨምር",
  Decrease:"ቀንስ",
  Bold:"ደፋር",
  Text:"ጽሑፍ",
  Style:"ቅጥ",
  Italic:"ኢታሊክ",
  QR_Code:"QR ኮድ",
  Barcode:"የአሞሌ ኮድ",
  Browse_Images:"ምስሎችን ያስሱ",
  Browse_Files:"ፋይሎችን ያስሱ",
  Background_Image:"የበስተጀርባ ምስል",
  Logo_Image:"የአርማ ምስል",
  Header_Image:"የራስጌ ምስል",
  Bank_Image:"የባንክ ምስል",
  Cut_Lines:"መስመሮችን ይቁረጡ",
  Background:"ዳራ",
  License:"ፈቃድ",
  One_License:"1 ፍቃድ፡-",
  Licensed:"ፍቃድ የተሰጠው ለ፡",
  Not_Licensed:"ፍቃድ አልተሰጠውም።",
  Enter_Serial:"ተከታታይ አስገባ",
  Serial_Key:"ተከታታይ ቁልፍ",
  Serial:"ተከታታይ",
  Product_Key:"የምርት ቁልፍ",
  Check_Product_Key:"የምርት ቁልፍን ያረጋግጡ",
  Add_Product_Key:"የምርት ቁልፍ አክል",
  Verifying_Purchase:"ግዢን በማረጋገጥ ላይ...",
  Print_Envelope:"የህትመት ኤንቬሎፕ",
  Envelope:"ፖስታ",
  Thank_You:"አመሰግናለሁ!",
  Scale:"ልኬት",
  Print_Scale:"የህትመት ልኬት",
  Borders:"ድንበሮች",
  VOID:"ባዶ",
  Void_Cheque:"ባዶ ቼክ",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"ለሚከተለው ትዕዛዝ ይክፈሉ፡",
  NO_DOLLARS:"ዶላር የለም",
  ONE_DOLLAR:"አንድ ዶላር",
  DOLLARS:" ዶላር",
  AND:" እና ",
  CENTS:" ሳንቲም",
  NO_:"አይ ",
  ONE_:"አንድ ",
  AND_NO_:"እና አይ ",
  _AND_ONE_:"እና አንድ ",
  DOLLARS_AND_ONE_CENT:" እና አንድ ሳንቲም",
  AND_NO_CENTS:" እና ዜሮ ሳንቲም",
  CHEQUE_PRINT_DATE:"ቀን፡-",
  CHEQUE_PRINT_MEMO:"ማስታወሻ፡",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"የመጀመሪያ ተግባራት",
  Inital_Setup:"የመጀመሪያ ማዋቀር",
  Welcome_To:"እንኩአን ደህና መጡ ",
  Welcome:"እንኳን ደህና መጣህ",
  Swipe:"ማንሸራተት",
  Intro_Setup:"የመግቢያ ማዋቀር",
  Introduction:"መግቢያ",
  CHEQUE_PRINT_CREDIT:"በቼኮች የተጎለበተ",
  Title_Intro_Word:"እንኳን ወደ ቼኮች በደህና መጡ",
  Title_Intro:"የቼኮች መግቢያ",
  Title_Setup:"የቼኮች ማዋቀር",
  PayPal_Default_Email_Message:"አዲስ የፔይፓል ዝውውር ደርሰዎታል። [በቼኮች የተጎለበተ]",
  Cheques_App_Export:"በቼኮች ወደ ውጭ የተላከ",
  Post_Intro_Thanks:"ቼኮችን ስለመረጡ እናመሰግናለን። የማዋቀሩ ሂደት አሁን ተጠናቅቋል።",
  Post_Intro_Word:"የመጀመሪያውን ቼክ በማተም ጀምር፣ የወደፊት ክፍያ ጨምር ወይም ሰራተኛን በደመወዝ መዝገብ ላይ በማከል ጀምር።",
  Upgrade_Required:"ቼኮች ይህን መልእክት ለመደበቅ እና ተጨማሪ ባህሪያትን ለመክፈት የበለጠ የላቀ የሶፍትዌር ስሪት እንዲኖሮት ይፈልጋል።",
  Upgrade_Title:"ቼኮች",
  Mailout_Prompt:"በተጨማሪም ቼኮች የደመወዝ ክፍያ ቼኮችዎን በፖስታ እንዲልኩልዎ መምረጥ ይችላሉ።",
  Mailed_Cheque:"የፖስታ ቼክ:",
  Mailed_Cheque_Color:"የደብዳቤ ቼክ (ቀለም):",
  Terms_Purchase:"ሁሉም በቼኮች የተደረጉ የኤሌክትሮኒክስ ግዢዎች ከተገዙበት ቀን ጀምሮ እስከ 30-ቀናት ድረስ ሙሉ በሙሉ ተመላሽ ይሆናሉ። እባክዎ ከመቀጠልዎ በፊት <a href='#'>ደንቦች እና ሁኔታዎች</a>ን ያንብቡ እና ይስማሙ።",
  Dashboard_Setup:"ዋና ማተሚያውን ያዋቅሩ",
  Dashboard_Add:"ዋናውን የባንክ ሒሳብ አክል",
  Dashboard_Customize:"የቼክ አብነት ምረጥ",
  Dashboard_Job_Salary:"ስራህን ፍጠር እና ደሞዝህን ጨምር",
  Dashboard_Employees:"ሰራተኞችን እና ተከፋይን ይከታተሉ",
  Dashboard_Print:"ክፍያዎችዎን ያትሙ እና ይላኩ",
  Dashboard_Payroll:"የደመወዝ ክፍያ ማተምን በራስ ሰር ያድርጉ",
  Dashboard_PayPal:"የፔይፓል ክፍያ/ክፍያ ማዋቀር",
  Begin_Setup:"ማዋቀር ጀምር",
  Get_Started:"እንጀምር",
  Purchase:"ግዢ",
  Lockdown:"መዝጋት",
  Unlock:"ክፈት",
  Detailed:"ዝርዝር",
  Log_In:"ግባ",
  Login:"ግባ",
  Launch:"አስጀምር",
  Register:"ይመዝገቡ",
  Finish:"ጨርስ",
  List:"ዝርዝር",
  Weekends:"የሳምንቱ መጨረሻ",
  Weekly:"ሳምንታዊ",
  PayPal_Default_Subject:"አዲስ ክፍያ",
  Payment_Message:"የክፍያ መልእክት",
  PayPal_Default_Payment_Note:"አመሰግናለሁ",
  Setup_Your_Cheqing_Account:"መለያ ማረጋገጥ",
  Add_Your_Primary_Cheqing_Account:"ዋና የቼኪንግ መለያህን አክል",
  Welcome_Word:"የደመወዝ ክፍያ እና የሰው ኃይል አስተዳደርን ቀላል እና አውቶማቲክ ያድርጉ።",
  Get_Started_Quickly:"ለመጀመር የሚረዱን ጥቂት ቀላል ጥያቄዎችን ብቻ መልሱ...",
  Done_Intro_Word:"ማዋቀር ተጠናቅቋል",
  PIN_Protected:"የይለፍ ቃል እና ፒን የተጠበቀ",
  Enter_New_PIN:"አዲስ ፒን ኮድ አስገባ:",
  Enter_PIN:"ፒን ኮድ አስገባ:",
  Invalid_PIN:"የተሳሳተ PIN አስገብተዋል.",
  Account_Identifier:"መለያ መለያ",
  New_Account_Identifier:"አዲስ መለያ መለያ",
  attempt:"ሙከራ",
  attempts:"ሙከራዎች",
  remaining:"ቀሪ",
  Language:"ቋንቋ",
  languages:"ቋንቋዎች",
  select_languages:"ቋንቋ ምረጥ",
  Deposit:"ተቀማጭ ገንዘብ",
  Hire_One_Now:"አሁን መቅጠር",
  App_Locked:"መተግበሪያው ተቆልፏል።",
  Skip_:"ዝለል",
  Skip_to_Dashboard:"ወደ ዳሽቦርድ ዝለል",
  Dashboard:"ዳሽቦርድ",
  Import:"አስመጣ",
  Admin:"አስተዳዳሪዎች",
  New_Admin:"አዲስ አስተዳዳሪ",
  Settings:"ቅንጅቶች",
  Color_Picker:"ቀለም መራጭ",
  Font_Picker:"የቅርጸ መራጭ",
  Logout:"ውጣ",
  Close:"ገጠመ",
  Close_menu:"ገጠመ",
  Excel:"የ Excel ፋይል",
  Csv:"CSV ፋይል",
  Sql:"SQL ፋይል",
  Json:"JSON ፋይል",
  Url:"በዩአርኤል አስመጣ",
  Back:"ተመለስ",
  Timers:"ሰዓት ቆጣሪዎች",
  Cheque:"አረጋግጥ",
  Print:"አትም",
  Designs:"ንድፍ",
  Pause_Printing:"ማተምን ለአፍታ አቁም",
  Resume_Printing:"ማተምን ከቆመበት ቀጥል",
  Printing_Paused:"ማተም ለአፍታ ቆሟል",
  PrintingUnavailable:"ይቅርታ! በዚህ ስርዓት ላይ ማተም የለም።",
  Prints_Paused:"ህትመቶች ባለበት ቆመዋል",
  Administration:"አስተዳደር",
  Loading:"በመጫን ላይ",
  Unnamed:"ስም ያልተጠቀሰ",
  error:"ይቅርታ፣ ይህ ቼክ ለእይታ ሊከፈት አልቻለም።",
  No_Cheques_To_Print:"የማይታተም ቼኮች",
  No_Cheques_To_Print_Word:"<a routerLink='/folder/Cheque/New'>አዲስ ቼክ</a> ፍጠር።",
  New_Cheque:"አዲስ ቼክ",
  Start_One_Now:"አሁን አንድ ጀምር",
  Edit_Cheque:"ማረጋገጫ አርትዕ",
  Select_Cheques:"ቼኮችን ምረጥ",
  Select_Employee:"ሰራተኛ ምረጥ",
  Default_Printer:"ነባሪ አታሚ",
  Reassign:"እንደገና መመደብ",
  Configure:"አዋቅር",
  Template:"አብነት",
  Designer:"ንድፍ አውጪ",
  Edit_Designs:"ንድፎችን አርትዕ",
  New_Design:"አዲስ ንድፍ",
  Next:"ቀጣይ",
  Save:"አስቀምጥ",
  Name:"ስም",
  Mail:"ደብዳቤ",
  Amount:"መጠን",
  Date:"ቀን",
  PayPal:"PayPal",
  Payouts:"ክፍያዎች",
  PayPal_Label:"የPayPal መለያ",
  Email_Username:"ኢሜል / የተጠቃሚ ስም",
  Client_ID:"የደንበኛ መታወቂያ",
  Sandbox_Email:"ማጠሪያ ኢሜይል",
  PayPal_Email:"PayPal ኢሜይል",
  PayPal_Username:"ኤፒአይ የተጠቃሚ ስም",
  PayPal_Payouts:"PayPal ክፍያዎች",
  Select_PayPal_Key:"የፔይፓል ቁልፍን ምረጥ",
  Secret:"ምስጢር",
  API_Secret:"ኤፒአይ ሚስጥር",
  PayPal_API_Keys:"PayPal ቁልፎች",
  New_PayPal_Key:"አዲስ የፔይፓል ቁልፍ",
  Email_Subject:"የኢሜል ርዕሰ ጉዳይ",
  Email_Message:"ኢሜል መልእክት",
  Payout_Options:"የክፍያ አማራጮች",
  Payment_Note:"የክፍያ ማስታወሻ",
  Enable_Employee:"ሰራተኛ አንቃ",
  Enable_Production_Mode:"የምርት ሁነታን አንቃ",
  Sandbox_Username:"ማጠሪያ የተጠቃሚ ስም",
  Sandbox_Signature:"ማጠሪያ ፊርማ",
  Sandbox_Password:"ማጠሪያ የይለፍ ቃል",
  Production_Username:"የምርት ተጠቃሚ ስም",
  Production_Signature:"የምርት ፊርማ",
  Production_Password:"የምርት የይለፍ ቃል",
  Production_Email:"የምርት ኢሜይል",
  API_Client_ID:"ኤፒአይ የደንበኛ መታወቂያ",
  API_Signature:"ኤፒአይ ፊርማ",
  API_Password:"ኤፒአይ የይለፍ ቃል",
  API_Username:"ኤፒአይ የተጠቃሚ ስም",
  Secret_Key:"ሚስጥራዊ ቁልፍ",
  Sandbox:"ማጠሪያ",
  Production:"ምርት",
  Sandbox_Keys:"ማጠሪያ ቁልፎች",
  Production_Keys:"የምርት ቁልፎች",
  API_Title:"ኤፒአይ ርዕስ",
  Production_Mode:"የምርት ሁነታ",
  Account_Label:"መለያ መለያ",
  No_PayPal_Setup:"የ PayPal ቁልፍ የለም",
  Enable_PayPal_Account:"የፔይፓል መለያን አንቃ",
  No_PayPal_Word:"የእርስዎን <a routerLink='/folder/Invoice/New'>PayPal የኤፒአይ ቁልፍ</a> ያክሉ።",
  Printed_Memo:"የታተመ ማስታወሻ",
  Employee:"ሰራተኛ",
  View_Employee:"ሰራተኛ ይመልከቱ",
  Mailing_Address:"የፖስታ መላኪያ አድራሻ",
  Company_Address:"የኩባንያ አድራሻ",
  Select_Company_Address:"የኩባንያ አድራሻ ምረጥ",
  Address:"አድራሻ",
  Any_Day:"ማንኛውም ቀን",
  Address_Name:"የአድራሻ ስም",
  Unit:"ክፍል",
  Account:"መለያ",
  Bank_Account:"የባንክ ሒሳብ",
  Account_Limits:"የመለያ ገደቦችን አንቃ",
  Payroll:"የደመወዝ ክፍያ",
  New_Payroll:"አዲስ ደመወዝ",
  No_Payroll:"መጪው ደመወዝ የለም",
  Upcoming_Holiday:"መጪው የበዓል ቀን:",
  Invoice_Due:"የክፍያ መጠየቂያ ደረሰኝ:",
  New_Invoice:"አዲስ ደረሰኝ",
  No_Invoices:"ምንም ደረሰኞች የሉም",
  No_Invoices_Word:"የመጀመሪያውን <a routerLink='/folder/Invoice/New'>ደረሰኝ</a> ፍጠር።",
  Cheque_Due:"የፍተሻ ክፍያ:",
  Payrolls:"የደመወዝ ክፍያ",
  Sandbox_Mode:"ማጠሪያ ሁነታ",
  Hire:"ቅጥር",
  Pay:"ክፍያ",
  New:"አዲስ",
  Add:"አክል",
  Make:"አድርግ",
  Time:"ጊዜ",
  Write:"ጻፍ",
  Holiday:"በዓል",
  Holidays:"በዓላት",
  Next_Holiday:"ቀጣዩ የበዓል ቀን:",
  All_Done:"ሁሉም ተጠናቀቀ!",
  Employees:"ሰራተኞች",
  Payees:"ተከፋዮች",
  Job:"ስራ",
  Jobs:"ስራዎች",
  Invoice:"ክፍያ መጠየቂያ",
  Invoices:"ደረሰኞች",
  Vacations:"ዕረፍት",
  Cheques:"ቼኮች",
  Brand_Cheques:"ብራንድ",
  Payment:"ክፍያ",
  Enable_Payment:"ክፍያን አንቃ",
  Payments:"ክፍያዎች",
  Schedule:"መርሃግብር",
  Schedules:"መርሃግብር",
  Timesheet:"የጊዜ ሰሌዳ",
  Timesheets:"የጊዜ ሰሌዳዎች",
  Salary:"ደሞዝ",
  New_Address:"አዲስ አድራሻ",
  Address_2:"አድራሻ (መስመር 2)",
  _City:"ከተማ",
  _State:"ግዛት/ፕሮቭ",
  City:"ከተማ / ከተማ",
  State:"ግዛት / ግዛት",
  Postal:"ፖስታ / ዚፕ ኮድ",
  ZIP:"ፖስታ / ዚፕ",
  Country:"ሀገር",
  Addresses:"አድራሻዎች",
  Required_Options:"አስፈላጊ አማራጮች",
  Optional_Options:"አማራጭ አማራጮች",
  Additional_Options:"ተጨማሪ አማራጮች",
  Required:"የሚያስፈልግ",
  Optional:"አማራጭ",
  Additional:"ተጨማሪ",
  No_Addresses:"አድራሻ የለም",
  New_Address_Word:"የመጀመሪያውን <a routerLink='/folder/Address/New'>አድራሻ</a> አክል።",
  Select_Address:"አድራሻ ምረጥ",
  No_Address:"አድራሻ የለም",
  Print_Cheque:"የህትመት ማረጋገጫ",
  Print_Cheque_Now:"አሁን የህትመት ፍተሻ",
  Description:"መግለጫ",
  Pay_To_The_Order_Of:"ለትዕዛዙ ይክፈሉ:",
  Select_Date_Range:"የቀን ክልል ምረጥ",
  Select_Starting_Date:"የመጀመሪያ ቀን ምረጥ",
  Select_Ending_Date:"የማለቂያ ቀን ምረጥ",
  Select_Date:"ቀን ምረጥ",
  Cheque_Date:"የተጣራ ቀን",
  Cheque_Memo:"ማስታወሻ አረጋግጥ",
  Blank_Cheque:"ባዶ ቼክ",
  Blank:"ባዶ",
  No_Cheques:"ቼኮች የለም",
  No_Cheques_Word:"የመጀመሪያውን <a routerLink='/folder/Cheque/New'>ቼክ</a> ያትሙ",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"ምስል ይመልከቱ",
  View:"ዕይታ",
  Modify:"ማስተካከል",
  Delete:"ሰርዝ",
  Cheque_Paid:"የተከፈለ",
  New_Deduction:"አዲስ ቅነሳ",
  Title:"ርዕስ",
  Frequency:"ድግግሞሽ",
  Hourly:"በሰዓት",
  Daily:"በየቀኑ",
  Weekdays:"የሳምንቱ ቀናት",
  BiWeekly:"2 ሳምንታት",
  TriWeekly:"3 ሳምንታት",
  Monthly:"ወርሃዊ",
  MiMonthly:"2 ወራት",
  Quarterly:"በየሩብ ዓመት",
  Yearly:"በዓመት",
  Every_Week:"በየሳምንቱ",
  Every_Payroll:"እያንዳንዱ ደመወዝ",
  Every_Month:"በየወሩ",
  Annually:"በዓመት",
  Always_Scheduled:"ሁልጊዜ መርሐግብር የተያዘለት",
  Start_Date:"የመጀመሪያ ቀን",
  End_Date:"የመጨረሻ ቀን",
  Start_Time:"የመጀመሪያ ጊዜ",
  End_Time:"የመጨረሻ ጊዜ",
  Deduction_Label:"የተቀነሰ መለያ",
  Notes:"ማስታወሻዎች",
  Options:"አማራጮች",
  Enable:"አንቃ",
  Select_Deductions:"ተቀናሾችን ምረጥ",
  Deductions:"ተቀነሰዎች",
  No_Deductions:"ምንም ተቀናሾች የሉም",
  No_Deductions_Word:"የመጀመሪያህን <a routerLink='/folder/Deduction/New'>ቅናሽ</a> ፍጠር።",
  New_Employee:"አዲስ ሰራተኛ",
  No_Title:"ርዕስ የለም",
  _Name:"ስም",
  About_Yourself:"ስለ ራስህ",
  Full_Name:"ሙሉ ስም",
  Birthday:"የልደት ቀን",
  Email:"ኢሜል",
  SMS:"ኤስኤምኤስ",
  Phone:"ስልክ",
  Test:"ፈተና",
  Call:"ጥሪ",
  Fax:"ፋክስ",
  Printed_Note:"የታተመ ማስታወሻ",
  Position:"አቀማመጥ",
  Job_Position:"የስራ ቦታ",
  Select_a_Job:"ስራ ምረጥ",
  Select_a_Salary:"ደሞዝ ምረጥ",
  Add_a_Deduction:"ተቀነሰ ጨምር",
  Taxes:"ግብር",
  Add_Taxes:"ግብር ጨምር",
  Date_of_Birth:"የተወለደበት ቀን",
  Email_Address:"የ ኢሜል አድራሻ",
  Phone_Number:"ስልክ ቁጥር",
  Phone_Call:"የስልክ ጥሪ",
  Enable_on_Payroll:"በክፍያ ላይ አንቃ",
  Select_Employees:"ሰራተኞችን ይምረጡ",
  No_Employees:"ሰራተኛ የለም",
  No_Employees_Word:"የመጀመሪያውን አዲስ <a routerLink='/folder/Employee/New'>ተቀጣሪ</a> ያክሉ።",
  No_Name:"ስም የለም",
  Unemployeed:"ስራ አጥ",
  Employeed:"የተቀጠረ",
  Paid:"የተከፈለ",
  Enabled:"ነቅቷል",
  Disabled:"ተሰናክሏል",
  Fire:"እሳት",
  Not_Available:"አይገኝም",
  Not_Available_Word:"የመጀመሪያውን <a routerLink='/folder/Invoice/New'>ደረሰኝ</a> ያትሙ እና ይክፈሉ",
  Select_Invoices:"ክፍያዎች_ምረጥ",
  Print_Invoice_Word:"የመጀመሪያውን <a routerLink='/folder/Invoice/New'>ደረሰኝ</a> ያትሙ እና ይክፈሉ",
  Invoice_Title:"የክፍያ መጠየቂያ ርዕስ",
  Invoice_Date:"የክፍያ መጠየቂያ ቀን",
  Due_Date:"የመጨረሻ ማስረከቢያ ቀን",
  New_Job:"አዲስ ስራ",
  Details:"ዝርዝሮች",
  Customize:"አብጁ",
  Customize_Dashboard:"ዳሽቦርድ አብጅ",
  Components:"ክፍሎች",
  No_Components:"ምንም ክፍሎች የሉም",
  Main_Components:"ዋና አካላት",
  Smaller_Components:"ትናንሽ አካላት",
  Error_Loading_Page:"ይህን ገጽ መጫን ላይ ስህተት።",
  Bank_Details:"የባንክ ዝርዝሮች",
  About_Your_Job:"ስለ ሥራህ",
  Your_Schedule:"የእርስዎ መርሐግብር",
  Job_Title:"የስራ መደቡ መጠሪያ",
  Job_Description:"የሥራ መግለጫ",
  Job_Details:"የሥራ ዝርዝሮች",
  Enable_Job:"ስራ አንቃ",
  Pay_Period:"የክፍያ ጊዜ",
  Perpetually_Schedule:"ዘላለማዊ መርሐግብር",
  Select_Jobs:"ስራ ምረጥ",
  No_Jobs:"ስራ የለም",
  Add_Jobs:"ስራ ጨምር",
  No_Jobs_Word:"የመጀመሪያውን <a routerLink='/folder/Job/New'>ስራ</a> ወደ ዝርዝሩ አክል።",
  Count_Employees:"የስራ.የሰራተኛ_ቁጥር+\"ሰራተኞች",
  Zero_Employees:"0 ሰራተኞች",
  New_Leave:"አዲስ ፈቃድ",
  Leave_Name:"ስም መተው",
  Paid_Leave:"የሚከፈልበት ፈቃድ",
  Leave_Pay:"ክፍያን ተው",
  Indefinite_Leave:"ያልተወሰነ ፈቃድ",
  Indefinite_Payroll:"ያልተወሰነ ደመወዝ",
  Leave:"ተወው",
  Add_Schedules:"መርሃግብሮችን ጨምር",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"አለመኖርን አንቃ",
  Select_Leaves:"ቅጠሎችን ምረጥ",
  No_Leaves:"የሌሉ ቅጠሎች የሉም",
  Leave_Of_Absence:"የመቅረት ፈቃድ",
  Leaves_Of_Absence:"የሌሉበት ቅጠሎች",
  New_Leave_of_Absense:"አዲስ የመቅረት ፈቃድ",
  No_Leaves_Word:"የመጀመሪያውን <a routerLink='/folder/Leave/New'>የመቅረት ፈቃድ</a> ያክሉ።",
  Not_Enabled:"አልነቃም",
  Absences:"መቅረት",
  Payee:"ፓዬ",
  New_Payee:"አዲስ ተከፋይ",
  Payee_Identifier:"ክፍያ መለያ",
  Payee_Name:"የተከፋይ ስም",
  Payee_Title:"የተከፋይ ርዕስ",
  Payment_Memo:"የክፍያ ማስታወሻ",
  Select_Payees:"ተከፋዮችን ይምረጡ",
  No_Payees:"ተቀጣሪዎች የሉም",
  Add_Payee_Note:"የመጀመሪያውን <a routerLink='/folder/Payee/New'>ተከፋይ</a> ያክሉ።",
  New_Payees:"አዲስ ተከፋይ",
  About_The_Payment_Schedule:"የክፍያ መርሐግብር",
  Your_Payroll_Schedule:"ራስ-ሰር ክፍያ",
  New_Payment:"አዲስ ክፍያ",
  Identifier:"መለያ",
  Select:"ምረጥ",
  Memo:"ማስታወሻ",
  Payment_Date:"የክፍያ ቀን",
  Mark_as_Paid:"እንደተከፈለ ምልክት አድርግበት",
  Select_Payments:"ክፍያዎችን ይምረጡ",
  No_Payments:"ክፍያ የለም",
  No_Payments_Word:"የመጀመሪያውን <a routerLink='/folder/Payment/New'>ክፍያ</a> ፍጠር።",
  Create_Payroll:"የደመወዝ ክፍያ ፍጠር",
  Properties:"ንብረቶች",
  Payroll_Title:"የደመወዝ ርዕስ",
  Payroll_Notes:"የደመወዝ ማስታወሻዎች",
  Payroll_Setup:"የደመወዝ ማዋቀር",
  Tabulate_Payments:"የሠንጠረዥ ክፍያዎች",
  Tabulate:"ታቡሌት",
  By:"በ:",
  Payments_By:"ክፍያዎች በ",
  Timesheets_And_Schedules:"የጊዜ ሉሆች እና መርሃ ግብሮች",
  Both:"ሁለቱም",
  Items:"ዕቃዎች",
  Add_Payees:"ተከፋዮችን ጨምር",
  Add_Account:"መለያ አክል",
  Enable_Account:"መለያ አንቃ",
  Enable_Payroll:"የደመወዝ ክፍያን አንቃ",
  Print_Payroll:"የህትመት ክፍያ",
  No_Payrolls:"ደሞዝ የለም",
  No_Payroll_Word:"የመጀመሪያውን <a routerLink='/folder/Payroll/New'>የደመወዝ ክፍያዎን</a> ይፍጠሩ።",
  View_more:"VIEW_MORE",
  Less:"ያነሰ",
  Add_Payroll:"ደመወዝ ጨምር",
  Select_Payroll:"ደመወዝ ምረጥ",
  About_Your_Salary:"ስለ ደሞዝዎ",
  Add_Salaries:"ደሞዝ ጨምር",
  Add_Salary:"ደሞዝ ጨምር",
  Salaries:"ደሞዝ",
  No_Salaries:"ደሞዝ የለም",
  No_Salaries_Word:"የመጀመሪያውን <a routerLink='/folder/Sary/New'>ደሞዝ ጨምር</a>።",
  Select_Salaries:"ደሞዝ ምረጥ",
  New_Salary:"አዲስ ደመወዝ",
  Salary_Name:"የደመወዝ መለያ",
  Enable_Salary:"ደሞዝ አንቃ",
  Salary_Amount:"የደመወዝ መጠን",
  New_Schedule:"አዲስ መርሐግብር",
  Schedule_Title:"የጊዜ መርሐግብር ርዕስ",
  Add_Address:"አድራሻ አክል",
  Repeat:"ድገም",
  Design:"ንድፍ",
  Edit_Design:"ንድፍ አርትዕ",
  Edit_this_Design:"ይህን ንድፍ አርትዕ",
  Repeat_Payment:"ክፍያ መድገም",
  Enable_Schedule:"መርሃግብር አንቃ",
  Never:"በፍፁም",
  Select_Schedule:"መርሃግብሮችን ምረጥ",
  No_Schedules:"ምንም መርሐግብር የለም",
  No_Schedules_Word:"የመጀመሪያውን <a routerLink='/folder/Schedule/New'>መርሃግብር</a> ፍጠር።",
  New_Administrator:"አዲስ አስተዳዳሪ",
  Username:"የተጠቃሚ ስም",
  First_Name:"የመጀመሪያ ስም",
  Last_Name:"ያባት ስም",
  Add_an_Address:"አድራሻ አክል",
  Payment_Limit:"በየክፍያ ገደብ",
  Total_Limit:"ጠቅላላ ገደብ",
  Select_Accounts:"መለያዎች ምረጥ",
  No_Administrators:"አስተዳዳሪዎች የሉም",
  No_Administrators_Word:"የመጀመሪያውን <a routerLink='/folder/Administrator/New'>የአስተዳዳሪ መለያ</a> ፍጠር።",
  New_Administrators_Word:"የመጀመሪያውን <a routerLink='/folder/Administrator/New'>አስተዳዳሪ</a> አክል",
  Cloud:"ደመና",
  Backup:"ምትኬ",
  Enable_iCloud:"iCloud ን አንቃ",
  Enable_Google_Drive:"Google Driveን አንቃ",
  Enable_Microsoft_OneDrive:"ማይክሮሶፍት OneDriveን አንቃ",
  Automatically_Backup:"በራስ-ሰር ምትኬ",
  FTP_Settings:"የኤፍቲፒ ቅንብሮች",
  URL_File_Prompt:"እባክዎ የ.xls / .xlsx / .json ፋይል የሚያስገባበትን ቦታ ይግለጹ፡",
  URL_SQL_Prompt:"እባክዎ ለማስመጣት የ SQLite ፋይል ቦታ ይግለጹ:",
  FTP_Backup:"ኤፍቲፒ ምትኬ",
  FTP_Import:"ኤፍቲፒ ማስመጣት",
  FTP:"ኤፍቲፒ",
  SFTP:"SFTP",
  Host:"አስተናጋጅ",
  Port:"ወደብ",
  Path:"መንገድ",
  Data_Path:"የውሂብ መንገድ",
  Enable_FTP_Account:"የኤፍቲፒ መለያን አንቃ",
  HostnameIP:"የአስተናጋጅ ስም",
  Password:"ፕስወርድ",
  Connection_Port:"ግንኙነት ወደብ",
  Enable_MySQL_Database:"የ MySQL ዳታቤዝ አንቃ",
  Log:"ምዝግብ ማስታወሻ",
  Reset:"ዳግም አስጀምር",
  Erase:"ደምስስ",
  Export:"ወደ ውጭ ላክ",
  Database:"መረጃ ቋት",
  Upload_CSV:"CSV ስቀል",
  Download_CSV:"CSV አውርድ",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL ምትኬ",
  Internal_Notes:"ውስጣዊ ማስታወሻዎች",
  Root_Path:"ሥር ዱካ",
  Select_Backup:"ምትኬን ምረጥ",
  Add_New_Backup:"አዲስ ምትኬ አክል",
  First_Backup:"የመጀመሪያውን ምትኬ አዘጋጅ...",
  Backups:"ምትኬዎች",
  Add_Backup:"ምትኬ አክል",
  No_Backups:"ምንም የሚገኙ ምትኬዎች የሉም።",
  Select_Backup_Type:"ማዋቀር የሚፈልጉትን የመጠባበቂያ አይነት ይምረጡ...",
  Backup_Type:"የምትኬ አይነት",
  FTP_Drive:"ኤፍቲፒ ድራይቭ",
  URL_Location:"ዩአርኤል",
  Apple_iCloud:"አይክላውድ",
  Google_Drive:"መንዳት",
  Microsoft_OneDrive:"መንዳት",
  Import_Fields:"ሜዳዎችን አስመጪ",
  Import_Fields_Word:"የ<a routerLink='/folder/Holiday/New'>ማስመጣት</a> ውሂብ ለመምረጥ ይህንን ክፍል ተጠቀም።",
  Upload:"ጫን",
  Download:"አውርድ",
  Download_JSON:"እንደ JSON ውሂብ አውርድ",
  Download_SQL:"እንደ SQL ፋይል አውርድ",
  New_Bank:"አዲስ ባንክ",
  New_Account:"አዲስ መለያ",
  New_Bank_Account:"አዲስ የባንክ ሂሳብ",
  Upload_Image:"ምስል ስቀል",
  Bank_Name:"የባንክ ስም",
  Bank_Address:"የባንክ አድራሻ",
  Branch_Address:"የቅርንጫፍ አድራሻ",
  Address_on_Cheque:"አድራሻ",
  Cheque_Numbers:"ቁጥሮችን ይፈትሹ",
  Cheque_Details:"ዝርዝሮችን አረጋግጥ",
  Bank_Logo:"የባንክ አርማ",
  Cheque_ID:"መታወቂያ ፈትሽ",
  Starting_Cheque_ID:"የመጀመሪያ ቼክ መታወቂያ",
  Transit_Number:"የመተላለፊያ ቁጥር",
  Institution_Number:"የተቋም ቁጥር",
  Designation_Number:"የመሰየም ቁጥር",
  Account_Number:"የመለያ ቁጥር",
  Limits:"ገደቦች",
  Default_Limits:"ነባሪ ገደቦች",
  Over_Limit:"ከመጠን በላይ",
  Under_Limit:"ከገደብ በታች",
  Payroll_Limit:"የደመወዝ ገደብ",
  Enable_Bank_Account:"የባንክ አካውንት አንቃ",
  Select_Account:"መለያ ምረጥ",
  No_Bank_Account:"የባንክ ሂሳብ የለም",
  No_Bank_Account_Word:"የመጀመሪያውን <a routerLink='/folder/Accounts/New'>ባንክ መለያ</a> አክል።",
  Bank_Accounts:"የባንክ ሂሳቦች",
  No_Accounts:"ምንም መለያዎች",
  No_Accounts_Note:"የመጀመሪያውን <a routerLink='/folder/Accounts/New'>ባንክ መለያ</a> አክል።",
  Cheque_Designer:"ንድፍ አውጪን ፈትሽ",
  Cheque_Design:"ንድፍ ይፈትሹ",
  Select_Design:"ንድፍ ምረጥ",
  Cheque_Designs:"ዲዛይኖችን ፈትሽ",
  No_Cheque_Designs:"ምንም የፍተሻ ንድፎች የሉም",
  No_Cheque_Designs_Word:"የራስህን <a routerLink='/folder/Settings/Cheque/Design/New'>ንድፍ ፈትሽ</a> ፍጠር",
  Set_Default:"እንደ ነባሪ አዘጋጅ",
  Default:"ነባሪ",
  Remove:"አስወግድ",
  Folder:"አቃፊ",
  Edit:"አርትዕ",
  LoadingDots:"በመጫን ላይ...",
  Add_a_New_File:"<a href='#' (click)=' add()'>አዲስ ፋይል</a> ወደ ላይ አክል",
  this_folder:"ይህ አቃፊ",
  FTP_Backup_Settings:"የኤፍቲፒ ምትኬ ቅንጅቶች",
  Secure_File_Transfer:"አስተማማኝ ፋይል ማስተላለፍ",
  New_Holiday:"አዲስ በዓል",
  Add_Holiday:"በዓል አክል",
  Holiday_Name:"የበዓል ስም",
  Additional_Pay:"ተጨማሪ ክፍያ",
  Enable_Holiday:"በዓልን አንቃ",
  Select_Holidays:"በዓላትን ይምረጡ",
  No_Holidays:"በዓላት የሉም",
  No_Holidays_Word:"የመጀመሪያውን <a routerLink='/folder/Holiday/New'>ህዝባዊ በዓል አክል",
  New_Import:"አዲስ ማስመጣት",
  Import_Data:"ውሂብ አስመጣ",
  Import_Data_Word:"የመረጡትን የፋይል ወይም የሰቀላ ዘዴ ይምረጡ።<br /> የሚደገፍ ፋይልን ከሰቀሉ በኋላ በፋይሉ ውስጥ የገቡትን መስኮች ከየትኛው ግቤት ጋር እንደሚዛመዱ መምረጥ ይችላሉ።",
  Import_File:"ፋይል አስመጣ",
  Link_To_File:"ከፋይል ጋር አገናኝ",
  Select_File:"ፋይል ምረጥ",
  New_Moderator:"አዲስ አወያይ",
  Allow_Moderation:"ልክነትን ፍቀድ",
  Create_Paycheques:"የክፍያ ክፍያዎችን ፍጠር",
  Edit_Paycheques_and_Data:"ክፍያዎችን እና ዳታዎችን አርትዕ",
  Destroy_Data_and_Paycheques:"ውሂብ እና ክፍያ አጥፋ",
  Bonus_Percentage:"የክፍያ ቼክ መቶኛ",
  Fixed_Amount:"ቋሚ መጠን",
  Select_Moderator:"አወያይ ምረጥ",
  No_Moderators:"አወያዮች የሉም",
  Moderators:"አወያዮች",
  Moderator_Account:"የመጀመሪያውን <a routerLink='/folder/Administrator/New'>የአወያይ መለያ</a> ፍጠር።",
  No_Moderators_Word:"የመጀመሪያውን <a routerLink='/folder/Administrator/New'>አወያይ</a> ያክሉ።",
  Defaults:"ነባሪ",
  Provide_Us_Info:"መረጃ ስጠን",
  Setup_Your_Printer:"አታሚዎን ያዋቅሩ",
  Your_Company:"ስለ ኩባንያዎ",
  Company_Name:"የድርጅት ስም",
  Currency:"ምንዛሪ",
  Default_Currency:"ነባሪ ምንዛሬ",
  Base_Monthly_Income:"ወርሃዊ ገቢ",
  Protection:"መከላከያ",
  App_Protection:"የመተግበሪያ ጥበቃ",
  PIN_Code_Protection:"የፒን ኮድ ጥበቃ",
  App_Protection_Word:"መለያህን ለመጠበቅ የሚረዱ የደህንነት ዘዴዎችን አንቃ።",
  PIN_Code:"ፒን ኮድ",
  Change_PIN:"ፒን ቀይር",
  New_Password:"አዲስ የይለፍ ቃል",
  Geofence_Protection:"የጂኦ-አጥር ጥበቃ",
  Geofence_Area:"አካባቢ አዘጋጅ",
  Distance:"ርቀት",
  Setup_Now:"አሁን አዋቅር",
  Mile:"ማይል",
  Km:"ኪሜ",
  m:"ሜ",
  Facial_Recognition:"የፊት እውቅና",
  Face:"ፊት",
  Setup:"አዘገጃጀት",
  Label:"መለያ",
  Password_Protection:"የይለፍ ቃል ጥበቃ",
  Modify_Password:"የይለፍ ቃል ቀይር",
  New_Tax_Entry:"አዲስ የግብር መግቢያ",
  New_Tax:"አዲስ ግብር",
  Tax_Label:"የግብር መለያ",
  Perpetually_Enabled:"ለዘለዓለም ነቅቷል",
  Select_Taxes:"ግብር ምረጥ",
  Tax_Deductions:"የግብር ቅነሳዎች",
  No_Tax_Deductions:"የግብር ተቀናሾች የሉም",
  No_Tax_Deductions_Word:"የመጀመሪያውን <a routerLink='/folder/Tax/New'>ግብር</a> ተቀናሽ ጨምር።",
  New_Timer:"አዲስ ሰዓት ቆጣሪ",
  Start:"ጀምር",
  Stop:"ተወ",
  Start_Timer:"የመጀመሪያ ጊዜ ቆጣሪ",
  Stop_Timer:"ሰዓት ቆጣሪን አቁም",
  Timer_Active:"ሰዓት ቆጣሪ ንቁ",
  Timer:"ሰዓት ቆጣሪ:",
  Timer_Running:"ሰዓት ቆጣሪ: (እየሮጠ)",
  Save_Timer:"ሰዓት ቆጣሪን አስቀምጥ",
  New_Timesheet:"አዲስ ጊዜ ሉህ",
  Select_Timesheets:"የጊዜ ሰሌዳዎችን ምረጥ",
  Work_Notes:"የስራ ማስታወሻዎች",
  Entry_Title:"የመግቢያ ርዕስ",
  No_Timesheets:"የጊዜ ሰሌዳዎች የሉም",
  No_Timesheets_Word:"የመጀመሪያውን <a routerLink='/folder/Timesheet/New'>የጊዜ ሉህ</a> አክል።",
  Timesheet_Submitted:"የጊዜ ሉህ ገብቷል",
  Timesheet_Congrats:"እንኳን ደስ አለህ! የጊዜ ሉህ በተሳካ ሁኔታ ገብቷል። አመሰግናለሁ!",
  Timesheet_Copy:"የሰነዶችዎን ቅጂ ለመቀበል እባክዎን የኢሜል አድራሻዎን እና/ወይም የሞባይል ስልክ ቁጥርዎን ይስጡን።",
  Submit:"አስረክብ",
  Allow_Notifications:"ማሳወቂያዎችን ፍቀድ",
  Untitled_Entry:"አዲስ ግቤት",
  Untitled_Bank:"ርዕስ የሌለው ባንክ",
  Timesheet_Entry:"የጊዜ ሉህ መግቢያ",
  Work_Description:"የሥራ መግለጫ",
  Enable_Timesheet:"የጊዜ ሰሌዳን አንቃ",
  Submit_Timesheet:"የጊዜ ሉህ አስገባ",
  Vacation:"ዕረፍት",
  New_Vacation:"አዲስ የእረፍት ጊዜ",
  Vacation_Name:"የእረፍት ጊዜ ስም",
  Paid_Vacation:"የሚከፈልበት ዕረፍት",
  Vacation_Pay:"የእረፍት ክፍያ",
  Enable_Vacation:"ዕረፍትን አንቃ",
  Select_Vacations:"ዕረፍት ምረጥ",
  No_Vacations:"ዕረፍት የለም",
  No_Vacations_Word:"የመጀመሪያውን <a routerLink='/folder/Vacation/New'>ዕረፍት</a> ግቤት ፍጠር።",
  Payroll_Schedule:"የደመወዝ መርሃ ግብር",
  Next_Payroll:"ቀጣይ ደመወዝ:",
  Upcoming_Payroll:"በመጪው ደመወዝ",
  No_Upcoming_Payroll:"መጪው ደመወዝ የለም",
  Address_Book:"የአድራሻ መጻፊያ ደብተር",
  Archived_Documents:"በማህደር የተቀመጡ ሰነዶች",
  Dev_Mode:"መተግበሪያ በልማት ሁነታ",
  Administrators:"አስተዳዳሪዎች",
  Privacy:"ግላዊነት",
  None:"ምንም",
  Select_Signature:"ፊርማ ምረጥ",
  No_Signatures:"ፊርማዎች የሉም",
  No_Signatures_Word:"የመጀመሪያውን <a routerLink='/folder/ Signature/New'>ፊርማ</a> አክል።",
  Repeat_Indefinitely:"ያለገደብ ድገም",
  Sign:"ምልክት",
  Sign_Here:"እዚህ ይመዝገቡ",
  Date_Signed:"የተፈረመበት ቀን",
  Signature_Pad:"የፊርማ ፓድ",
  Account_Holder:"የመለያ ባለቤት",
  Account_Properties:"የመለያ ባህሪያት",
  Name_On_Cheque:"በቼክ ላይ ስም",
  Server_Hostname:"የአገልጋይ አስተናጋጅ ስም / አይፒ",
  Printers:"አታሚዎች",
  No_Printers:"አታሚዎች የሉም",
  Printer_Exists:'በዚህ ስም ያለው አታሚ አስቀድሞ አለ።',
  No_Printers_Word:"የመጀመሪያውን <a routerLink='/folder/Printer/New'>አታሚ</a> አክል።",
  No_Printer_Alert:"ምንም አታሚ አልተገለጸም። አታሚ ማዋቀር ይፈልጋሉ?",
  Add_Printer:"አታሚ አክል",
  New_Printer:"አዲስ አታሚ",
  Printer_Hostname:"የአታሚ አስተናጋጅ ስም / አይፒ",
  Printer_Label:"የአታሚ መለያ",
  Printer_Protocol:"የአታሚ ፕሮቶኮል",
  Protocol:"ፕሮቶኮል",
  Email_Print:"ኢሜል",
  AirPrint:"AirPrint",
  IPP:"አይፒፒ",
  LPD:"LPD",
  HPJetDirect:"ሶኬት",
  Print_Job:"የህትመት ሥራ",
  Printed:"የታተመ",
  Not_Printed:"አልታተመም",
  Print_Jobs:"የህትመት ስራዎች",
  Print_Queue:"የህትመት ወረፋ",
  No_Print_Jobs:"የህትመት ስራዎች የሉም",
  No_Prints:"ለመታተም አዲስ <a routerLink='/folder/Cheque/New'>ያረጋግጡ</a> ፍጠር።",
  Prints:"ህትመቶች",
  Find_Printer:"አታሚ ፈልግ",
  Find_AirPrint_Devices:"AirPrint መሣሪያዎችን አግኝ",
  Select_Printer:"አታሚ ምረጥ",
  System_UI:"ስርዓት ዩአይ",
  Printer:"አታሚ",
  Status:"ሁኔታ",
  Preview:"ቅድመ እይታ",
  Enable_Print_Job:"የህትመት ስራን አንቃ",
  Queue_Weight:"የወረፋ ክብደት",
  Unlimited:"ያልተገደበ",
  Show_Advanced_Printer_Options:"የላቁ የአታሚ አማራጮችን አሳይ",
  Advanced:"የላቀ",
  Location:"ቦታ",
  Note:"ማስታወሻ",
  Queue_Name:"የወረፋ ስም",
  Pages_Printed_Limit:"የታተሙ ገጾች ገደብ",
  MultiPage_Idle_Time:"ባለብዙ ገጽ የጥበቃ ጊዜ (ዎች)",
  Page_Count_Limit:"የገጽ ብዛት ገደብ",
  Page_Orientation:"ገጽ አቀማመጥ",
  Portrait:"የቁም ሥዕል",
  Landscape:"የመሬት ገጽታ",
  Duplex:"ዱፕሌክስ",
  Double_Sided_Printing:"ድርብ ጎን",
  Duplex_Mode:"Duplex ሁነታ",
  Duplex_Short:"አጭር",
  Duplex_Long:"ረዥም",
  Duplex_None:"ምንም",
  Color_And_Quality:"ቀለም እና ጥራት",
  Monochrome:"ሞኖክሮም",
  Photo_Quality_Prints:"የፎቶ ጥራት ህትመቶች",
  Printer_Email:"የአታሚ ኢሜይል",
  Automatically_Downsize:"በራስ-ሰር አሳንስ",
  Paper:"ወረቀት",
  Paper_Name:"የወረቀት ስም",
  Paper_Width:"የወረቀት ስፋት",
  Paper_Height:"የወረቀት ቁመት",
  Paper_Autocut_Length:"የወረቀት በራስ-የተቆረጠ ርዝመት",
  Margins:"ህዳግ",
  Page_Margins:"ገጽ ህዳግ",
  Page_Margin_Top:"የላይኛው ገጽ ኅዳግ",
  Page_Margin_Right:"የቀኝ ገጽ ህዳግ",
  Page_Margin_Bottom:"የታችኛው ገጽ ኅዳግ",
  Page_Margin_Left:"የግራ ገጽ ህዳግ",
  Add_Employees:"ሰራተኞችን ጨምር",
  Header:"ራስጌ",
  Print_A_Page_Header:"የገጽ ራስጌ ያትሙ",
  Header_Label:"የራስጌ መለያ",
  Header_Page_Index:"ራስጌ ገጽ መረጃ ጠቋሚ",
  Header_Font_Name:"ራስጌ ፊደል",
  Select_Font:"ፊደል ምረጥ",
  Font_Selector:"የፊደል መራጭ",
  Header_Font_Size:"ራስጌ ፊደል",
  Header_Bold:"ራስጌ ደፋር",
  Header_Italic:"ራስጌ ኢታሊክ",
  Header_Alignment:"ራስጌ አሰላለፍ",
  Left:"ግራ",
  Center:"ማእከል",
  Right:"ቀኝ",
  Justified:"የተረጋገጠ",
  Header_Font_Color:"የራስጌ ቀለም",
  Select_Color:"ቀለም ምረጥ",
  Footer:"እግር",
  Print_A_Page_Footer:"የገጽ ግርጌ ያትሙ",
  Footer_Label:"የእግር መለያ",
  Footer_Page_Index:"የግርጌ ገጽ መረጃ ጠቋሚ",
  Footer_Font_Name:"የግርጌ ፊደል",
  Fonts:"ቅርጸ ቁምፊዎች",
  Done:"ተከናውኗል",
  Select_Fonts:"ቅርጸ ቁምፊዎችን ምረጥ",
  Footer_Font_Size:"የእግር መጠን",
  Footer_Bold:"የእግር ደፋር",
  Footer_Italic:"የእግር ኢታሊክ",
  Footer_Alignment:"የእግር አሰላለፍ",
  Footer_Font_Color:"የእግር ቀለም",
  Page_Copies:"ገጽ ቅጂዎች",
  Enable_Printer:"ማተሚያን አንቃ",
  Remote_Logging:"የርቀት ምዝግብ ማስታወሻ",
  Log_Server:"Log Server",
  Encryption:"ምስጠራ",
  Random_Key:"የዘፈቀደ ቁልፍ",
  Encryption_Key:"የምስጠራ ቁልፍ",
  Cheques_Webserver:"ብጁ የውሂብ ጎታ",
  Learn_How:"እንዴት ተማር",
  Signature:"ፊርማ",
  Default_Printer_Unit:"በ/ሴሜ/ሚሜ/(pt)",
  View_Signature:"ፊርማ ይመልከቱ",
  Printed_Signature:"የታተመ ፊርማ",
  Digitally_Sign:"በዲጂታል ምልክት",
  Digital_Signature:"ዲጂታል ፊርማ",
  Add_Signature:"ፊርማ ጨምር",
  Add_Your_Signature:"ፊርማህን ጨምር",
  Enable_Signature:"ፊርማ አንቃ",
  Digitally_Signed:"በዲጂታል የተፈረመ",
  Insert_Error:"በመረጃ ቋት ችግሮች ምክንያት ቼክ ማስቀመጥ አልተቻለም።",
  Delete_Confirmation:"እርግጠኛ ነዎት ይህን መረጃ መሰረዝ ይፈልጋሉ?",
  Discard_Confirmation:"እርግጠኛ ነዎት ይህን መረጃ መጣል ይፈልጋሉ?",
  Discard_Bank_Confirmation:"እርግጠኛ ነዎት ይህን መለያ መጣል ይፈልጋሉ?",
  Discard_Printer_Confirmation:"እርግጠኛ ነዎት ይህን አታሚ መጣል ይፈልጋሉ?",
  Delete_Bonus_Confirmation:"እርግጠኛ ነዎት ይህን ጉርሻ መሰረዝ ይፈልጋሉ?",
  Delete_Invoice_Confirmation:"እርግጠኛ ነዎት ይህን ደረሰኝ መሰረዝ ይፈልጋሉ?",
  Generated_Cheque:"የተፈጠረ ቼክ",
  Generated_Invoice:"የመነጨ ደረሰኝ",
  Schedule_Start_Time:"የመርሐግብር ጅምር",
  Schedule_End_Time:"የቀጠሮ መጨረሻ",
  New_Call:"አዲስ ጥሪ",
  No_Contacts:"እውቂያዎች የሉም",
  No_Contacts_Word:"አስተዳዳሪዎች፣ አወያዮች፣ ሰራተኞች እና ተከፋይ እንደ እውቂያዎች ይታያሉ።",
  PDF_Subject:"ፋይናንስ",
  PDF_Keywords:"የደመወዝ ክፍያ ፒዲኤፍ ቼኮች",
  Printer_Setup:"የአታሚ ማዋቀር",
  Printer_Selection:"የአታሚ ምርጫ",
  New_Fax:"አዲስ ፋክስ",
  New_Fax_Message:"አዲስ የፋክስ መልእክት",
  Fax_Machine:"ፋክስ ማሽን",
  Fax_Name:"ፋክስ ስም",
  Fax_Email:"ፋክስ ኢሜል",
  Fax_Number:"ፋክስ ቁጥር",
  Contents:"ይዘት",
  Fax_Body:"ገጽ አካል",
  Header_Word:"ራስጌ:",
  Header_Text:"ራስጌ ጽሑፍ",
  Include_Header:"ራስጌን አካትት",
  Include_Footer:"ግርጌን አካትት",
  Footer_Word:"እግር:",
  Footer_Text:"የግርጌ ጽሑፍ",
  Attach_a_Cheque:"ቼክ ያያይዙ",
  Add_Deduction:"ቅናሽ ጨምር",
  Enable_Fax:"ፋክስ ላክ",
  Select_Fax:"ፋክስ ምረጥ",
  No_Faxes:"ፋክስ የለም",
  Faxes:"ፋክስ",
  Save_and_Send:"ፋክስ ላክ",
  Save_and_Pay:"ይቆጥቡ እና ይክፈሉ",
  WARNING:"ማስጠንቀቂያ:",
  Remember:"አስታውስ",
  Printing:"ማተም",
  Printing_Complete:"ማተም ተጠናቅቋል!\n\n",
  of:"የ",
  There_Were:"እዚያ ነበሩ ",
  Successful_Prints:"ስኬታማ ህትመቶች እና",
  Unsuccessful_Prints:"ያልተሳኩ ህትመቶች",
  PrinterError:"ይቅርታ! ስህተት ነበር",
  Printing_:"ማተም...",
  PrinterSuccess:"ሰነዱ በተሳካ ሁኔታ ታትሟል።",
  PrintersSuccess:"ሰነዶች በተሳካ ሁኔታ ታትመዋል።",
  Print_Cheques:"የህትመት ፍተሻዎች",
  New_Message:"አዲስ መልእክት",
  New_Messages:"አዲስ መልዕክቶች",
  Read_Message:"መልእክት አንብብ",
  Write_Message:"መልእክት ጻፍ",
  Send_Message:"መልዕክት ላክ",
  Subject:"ርዕሰ ጉዳይ",
  Message:"መልእክት",
  Writing:"መጻፍ...",
  Send:"ላክ",
  Set_Date:"ቀን አዘጋጅ",
  Set_Time:"ጊዜ አዘጋጅ",
  Recieve:"ተቀበል",
  Set_as_Default:"እንደ ነባሪ አዘጋጅ",
  Default_Account:"ነባሪ መለያ",
  Default_Design:"ነባሪ ንድፍ",
  Multiple_Cheques:"ቼኮች (ትሪፕል)",
  Account_Mode:"የመለያ ሁነታ",
  Multiple_Cheques_Description:"በገጽ ሦስት ቼኮች።",
  Check_and_Table:"ቼክ እና ጠረጴዛ",
  Check_including_Table:"ቼክ እና የሂሳብ ሠንጠረዥ.",
  Check_Mailer:"ፖስታ አረጋግጥ",
  Check_Mailer_Window:"በአድራሻ መስኮት ፈትሽ።",
  DocuGard_Table_Top:"ዶክዩጋርድ ቼክ እና ሠንጠረዥ (ከላይ)",
  DocuGard_Table_Middle:"ዶክዩጋርድ ቼክ እና ሠንጠረዥ (መካከለኛ)",
  DocuGard_Table_Bottom:"ዶክዩጋርድ ቼክ እና ሠንጠረዥ (ከታች)",
  DocuGard_Mailer_Top:"Docugard ተልኳል ቼክ (ከላይ)",
  DocuGard_Mailer_Middle:"Docugard ተልኳል ቼክ (መካከለኛ)",
  DocuGard_Mailer_Bottom:"Docugard ተልኳል ቼክ (ታች)",
  DocuGard_Three_Cheques:"Docugard ቼኮች (ሶስት)",
  DocuGard_Cheque_Top:"ዶክዩጋርድ ቼክ (ከፍተኛ)",
  DocuGard_Cheque_Middle:"ዶክዩጋርድ ቼክ (መካከለኛ)",
  DocuGard_Cheque_Bottom:"ዶክዩጋርድ ቼክ (ታች)",
  DocuGard_Three_Cheques_Window:"በአንድ ገጽ ላይ ሶስት ቼኮች.",
  DocuGard_Table_Top_Window:"የፍተሻ እና የገቢዎች ሰንጠረዥ",
  DocuGard_Table_Middle_Window:"የፍተሻ እና የገቢዎች ሰንጠረዥ",
  DocuGard_Table_Bottom_Window:"የፍተሻ እና የገቢዎች ሰንጠረዥ",
  DocuGard_Mailer_Top_Window:"ቼክ፣ ጠረጴዛ እና አድራሻ።",
  DocuGard_Mailer_Middle_Window:"ቼክ፣ ጠረጴዛ እና አድራሻ።",
  DocuGard_Mailer_Bottom_Window:"ቼክ፣ ጠረጴዛ እና አድራሻ።",
  DocuGard_Cheque_Top_Window:"ደህንነቱ የተጠበቀ ወረቀት እንዳለ ያረጋግጡ.",
  DocuGard_Cheque_Middle_Window:"ደህንነቱ የተጠበቀ ወረቀት እንዳለ ያረጋግጡ.",
  DocuGard_Cheque_Bottom_Window:"ደህንነቱ የተጠበቀ ወረቀት እንዳለ ያረጋግጡ.",
  Basic_Cheque:"ቼክ (ከላይ)",
  Basic_Cheque_Print:"አንድ ነጠላ ቼክ ያትሙ።",
  Error_Setting_As_Paid:"እንደ ተከፍሎ ማዋቀር ላይ ስህተት",
  Add_Attachment:"አባሪ ጨምር",
  PrinterUnavailable:"አታሚ አይገኝም",
  CreditCardComponent:"ካርዶች",
  PaypalComponent:"PayPal",
  InteracComponent:"ኢንተርራክ",
  BitcoinComponent:"BitCoin",
  New_Deposit:"አዲስ ተቀማጭ ገንዘብ",
  Deposits:"ተቀማጭ ገንዘብ",
  No_Deposits:"ምንም ተቀማጭ ገንዘብ",
  Credit_Card_Deposit:"የዱቤ ካርድ",
  New_Credit_Card_Deposit_Message:"የክሬዲት ካርድ ተቀማጭ ገንዘብ",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin ተቀማጭ ገንዘብ",
  New_Interac_Deposit:"ኢንተርራክ",
  New_Interac_Deposit_Message:"ኢንተርራክ ማስተላለፍ",
  Payments_Limit:"የክፍያ ገደብ",
  No_Payment_Limit:"የክፍያ ገደብ የለም",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal ተቀማጭ ገንዘብ",
  No_Deposits_Word:"የመጀመሪያውን ኩባንያ <a routerLink='/folder/Deposit/New'>ተቀማጭ ጨምር</a>።",
  No_Documents_Specified:"ለህትመት ምንም ሰነዶች አልተገለጹም",
  No_Printers_Added:"ምንም አታሚዎች አልተገኙም። አንድ ለመጨመር ወደ ቅንብሮች > አታሚዎች ይሂዱ።",
  DB_Erase_Prompt:"ሙሉ የውሂብ ጎታውን ሙሉ በሙሉ ይሰርዙ? ማስጠንቀቂያ፡ ሁሉንም የተከማቸ መረጃ ታጣለህ!",
  Console_Warning:"በዚህ ኮንሶል ውስጥ ምንም አይነት ጽሑፍ አይለጥፉ። እራስዎን እና/ወይም ኩባንያዎን ከባድ አደጋ ላይ ሊጥሉ ይችላሉ።",
  No_Faxes_Word:"የመጀመሪያውን <a routerLink='/folder/Fax/New'>ፋክስ</a> ፍጠር።",
  Cheque_Delete_Confirmation:"እርግጠኛ ነዎት ይህን ቼክ መሰረዝ ይፈልጋሉ?",
  Design_Delete_Confirmation:"እርግጠኛ ነዎት ይህን ንድፍ መሰረዝ ይፈልጋሉ?",
  Cheque_Pay_Confirmation:"ይህን ቼክ እንደተከፈለ ምልክት አድርግበት? በህትመት ወረፋ ላይ አይታይም።",
  Payment_Pay_Confirmation:"ይህን ክፍያ እንደተከፈለ ምልክት አድርግበት? በቼክ ወረፋ ላይ አይታይም።",
  Delete_Deduction_Confirmation:"እርግጠኛ ነዎት ይህን ተቀናሽ መሰረዝ ይፈልጋሉ?",
  Delete_Job_Confirmation:"እርግጠኛ ነዎት ይህን ስራ መሰረዝ ይፈልጋሉ?",
  Delete_Timesheet_Confirmation:"እርግጠኛ ነዎት ይህን የሰዓት ሉህ መሰረዝ ይፈልጋሉ?",
  Delete_Schedule_Confirmation:"እርግጠኛ ነዎት ይህን መርሐግብር መሰረዝ ይፈልጋሉ?",
  Delete_Setting_Confirmation:"እርግጠኛ ነዎት ይህን ቅንብር ዳግም ማስጀመር ይፈልጋሉ?",
  Delete_Fax_Confirmation:"እርግጠኛ ነዎት ይህን ፋክስ መሰረዝ ይፈልጋሉ?",
  Delete_File_Confirmation:"እርግጠኛ ነዎት ይህን ፋይል መሰረዝ ይፈልጋሉ?",
  Delete_Vacation_Confirmation:"እርግጠኛ ነዎት ይህን የእረፍት ጊዜ መሰረዝ ይፈልጋሉ?",
  Delete_Printer_Confirmation:"እርግጠኛ ነዎት ይህን አታሚ መሰረዝ ይፈልጋሉ?",
  Remove_Design_Confirmation:"እርግጠኛ ነዎት ይህን ንድፍ መሰረዝ ይፈልጋሉ?",
  Delete_Payroll_Confirmation:"እርግጠኛ ነዎት ይህን የክፍያ መዝገብ መሰረዝ ይፈልጋሉ?",
  Send_Fax_Email_Confirmation:"እነዚህን ሰነዶች ፋክስ እና ኢሜል መላክ ትፈልጋለህ?",
  Send_Email_Confirmation:"ይህን ሰነድ ኢሜይል መላክ ትፈልጋለህ?",
  Send_Fax_Confirmation:"ይህን ሰነድ ፋክስ ማድረግ ትፈልጋለህ?",
  Error_Generating_PDF:"ይቅርታ ይህን ሰነድ በማመንጨት ላይ ስህተት ነበር።",
  PDF_Error_Saving_Image:"ይቅርታ የዚህን ሰነድ ፒዲኤፍ ምስል ማስቀመጥ ላይ ስህተት ነበር።",
  Test_Printer_Confirmation:"በዚህ አታሚ ላይ የሙከራ ገጽ ማተም ይፈልጋሉ?",
  Save_Timesheet_Prompt:"እባክዎ ለማስቀመጥ 'Title' ያክሉ ወይም 'Start Timer' ን ይጫኑ።",
  Remove_Geofence_Prompt:"እርግጠኛ ነዎት የዚህን ጂኦ-አጥር አካባቢ ማስወገድ ይፈልጋሉ?",
  Delete_Employee_Confirmation:"እርግጠኛ ነህ መሰረዝ ትፈልጋለህ",
  Fire_Employee_Confirmation:"እርግጠኛ ነህ ማባረር ትፈልጋለህ"
}