export const So = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  Copyright:"Xuquuqda daabacaadda & nuqul; 2023",
  black:"Madow",
  green:"Cagaaran",
  gold:"Dahab",
  blue:"Buluug",
  brown:"Bunni",
  purple:"Purple",
  pink:"Pink",
  red:"Casaan",
  Swatches:"Schetches",
  HSL:"Hsl",
  RGB:"Rgb",
  Hue:"Huysan",
  Saturation:"SATION",
  Lightness:"Nablad",
  Upgrade_To_Pro:"U cusboonaysii Pro",
  AllFeaturesInclude:"Dhammaan sifooyinka waxaa ka mid ah:",
  PrintUnlimitedCheques:"Daabac jeegag aan xadidnayn",
  PremiumChequeDesigns:"Nakhshad Hubinta Qiimaha Badan",
  ManageUnlimitedEmployees:"Maamul Shaqaale aan xadidnayn",
  AddUnlimitedPayees:"Kudar Lacag-bixiyeyaal aan xadidnayn",
  CreateUnlimitedPayrolls:"Abuur Mushahar Mushahar Aan xadidnayn",
  UnlimitedSchedulesandTimeSheets:"Jadwalka aan xadidnayn iyo xaashida wakhtiga",
  BulkPayPalPayouts:"Lacag bixinta PayPal oo badan",
  UnlimitedBankAccounts:"Xisaabaadka Bangiyada aan xadidnayn",
  ManageMultipleCompanies:"Maamul Shirkado Badan",
  TrackInsurancePolicies:"Raad raac Xeerarka Caymiska",
  Bio_MetricProtection:"Ilaalinta Bio-Metric",
  Geo_FenceLock_OutProtection:"Ilaalinta Qufulka-Deedka-Geo-Dayrka",
  Multiple_Companies_Word:"Maareynta shirkado badan lama heli karo iyada oo aan la helin lacagta jeegaga.",
  PayPal_Payouts_Word:"Lacag bixinta PayPal waa naafo la'aanteed lacagta jeegaga.",
  PINProtection:"Ilaalinta PIN",
  PasswordProtection:"Ilaalinta erayga sirta ah",
  May_Require_Approval:"Waxay u baahan kartaa ogolaansho",
  Subscribe:"Subscribe dheh",
  Billed:"Biil",
  Up:"Sare",
  Down:"Hoos",
  Positioning:"Meelaynta",
  Marker:"Calaamadeeye",
  Drag_Marker:"Calaamadeeyaha Jiid",
  Tagline:"Daabacaadda Jeegaga iyo Shabakadda Mushaharka",
  Marker_Word:"Isticmaal calaamadeeyayaasha si aad u cabbirto curiyaha.",
  Pinch_Zoom:"Qoon dhaweey",
  Pinch_Word:"Qabo si aad u soo dhawayso curiyaha.",
  Drag:"Jiid",
  Drag_Word:"Isticmaal fartaada si aad u jiido walxaha.",
  subscription_alias_word:"Isdiiwaangelinta Is-cusboonaysiinta",
  premium_alias_word:"Xidhmada Cusboonaysiinta Hal-Mar",
  print_alias_word:"Jeegaga Shakhsi ahaaneed ee Daabacaadda",
  mode_alias_word:"Habka",
  Pro:"Pro",
  Pro_word:"Nooca Pro ayaa loo baahan yahay.",
  Cant_Delete_Default_Company_Word:"Waan ka xunahay, ma tirtiri kartid shirkaddaada caadiga ah.",
  Reinsert_Default_Designs:"Dib u Geli Nakhshadihii Hore",
  Reinsert_Default_Designs_word:"Ma rabtaa inaad dib u geliso naqshadaha caadiga ah?",
  Subscription_Active_Disable_Word:"Diiwaangelintani waa mid firfircoon Ma jeclaan lahayd inaad joojiso ka-qaybgalka Jeegaga?",
  Company_Logo:"Astaanta Shirkadda",
  ZERO_:"EBER",
  Disclaimer:"Afeef",
  Privacy_Policy:"Qaanuunka Arrimaha Khaaska ah",
  EULA:"Waxay hubisaa EULA",
  Terms_Of_Service:"Shuruudaha Adeegga",
  Terms_Of_Use:"Shuruudaha isticmaalka",
  Refunds:"Siyaasadda Lacag-celinta",
  Single_Print:"1 Hubi",
  Two_Prints:"2 hubin",
  Five_Prints:"5 hubin",
  Ten_Prints:"10 jeegag",
  Fifteen_Prints:"15 jeegag",
  Twenty_Prints:"20 jeegag",
  Thirty_Prints:"30 jeegag",
  Image_Added:"Sawirka Lagu Daray",
  Image_Preview:"Hordhaca Sawirka",
  No_Image_Was_Selected:"Wax sawir ah lama dooran",
  Cheques_Unlimited:"Hubi Unlimited",
  _Subscription:"Isdiiwaangelinta",
  Subscription:"Jeegaga - 1 Bilood",
  Two_Month_Subscription:"Jeegaga - 2 bilood",
  Three_Month_Subscription:"Jeegaga - 3 bilood",
  Six_Month_Subscription:"Jeegaga - 6 bilood",
  Twelve_Month_Subscription:"Jeegaga - 12 bilood",
  Cheques_Are_Available:"Jeegaga ayaa diyaar u ah in la daabaco.",
  Upgrade_Required_Two:"Dooro xirmo oo laba jeer taabo badhanka qiimaha si aad u bilowdo iibsashadaada. Ku daabac jeegag midab-buuxa oo xirfad leh ilbidhiqsiyo gudahood.",
  Month:"Bisha",
  Due:"Waa waajib:",
  Expires:"Dhacaya:",
  Subscription_Active:"Diiwaangelinta Firfircoon",
  Subscription_Inactive:"Diiwaangelinta Aan Dhaqdhaqaaq lahayn",
  Purchase_Additional_Cheques:"Iibso jeegag dheeraad ah?",
  Printable_Cheque:"Hubinta Daabici kara",
  Printable_Cheques:"Jeegaga Daabici kara",
  Printable_Cheque_Word:"jeeg ayaa laga heli karaa akoonkaaga.",
  Printable_Cheques_Word:"jeegaga ayaa laga heli karaa akoonkaaga.",
  Max_Amount_Message:"Inta aad sheegtay waxa ay gaartay in ka badan inta ugu badan ee nidaamkan loo dejiyay:",
  Terms_Required_Word:"Waa inaad ogolaato heshiiskan ka hor intaadan sii wadin isticmaalka Jeegaga.",
  Subscriptions:"Diiwaangelinta",
  Product_Upgrades:"Horumarin",
  Mailed_Out_Cheques:"Jeegaga Boostada lagala baxay",
  Enter_A_Company_Name:"Fadlan geli magaca shirkadda",
  Single_Cheques:"Jeegaga Keliya",
  Cheque_Golden:"Jeeg Dahab ah",
  Cheque_Golden_Window:"Naqshad jeeg dahab ah.",
  Cheque_Green:"Hubinta cagaaran",
  Cheque_Green_Window:"Naqshad jeeg cagaaran.",
  Cheque_Red:"Hubinta cas",
  Cheque_Red_Window:"Naqshad jeeg casaan ah.",
  Cheque_Yellow:"Jeegaga Jaallaha ah",
  Cheque_Yellow_Window:"Naqshad jeeg jaale ah.",
  Cheque_Statue_of_Liberty:"Taalada Xoriyadda",
  Cheque_Statue_of_Liberty_Window:"Naqshadeynta jeegaynta Taalada Xoriyadda.",
  Cheque_Green_Wave:"Hirarka cagaaran",
  Cheque_Green_Wave_Window:"Naqshad jeeg cagaaran.",
  Cheque_Golden_Weave:"Weave Dahab ah",
  Cheque_Golden_Weave_Window:"Naqshad jeeg dahab ah oo qurxoon.",
  Cheque_Green_Sun:"Cadceed cagaaran",
  Cheque_Green_Sun_Window:"Naqshad hubin qoto dheer oo dejinaysa.",
  Cheque_Blue_Checkers:"Hubiyeyaasha buluuga ah",
  Cheque_Blue_Checkers_Window:"Naqshad jeeg buluug ah.",
  Cashiers_Check:"Hubinta Qasnajiga",
  Cashiers_Check_Window:"Qaabka qaabka jeegaga Qasnajiga.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Aqua check design.",
  Cheque_Golden_Checkers:"Hubiyaasha Dahabka",
  Cheque_Golden_Checkers_Window:"Naqshad jeeg dahab ah.",
  Upgrade_Unavailable:"Kor u qaadis lama heli karo",
  Bank_Code_Protection:"Ilaalinta Lambarka Bangiga",
  Bank_Code_Protection_Word:"Ka ilaali lambarada bangigaaga in lagu isticmaalo abkan ku shaqeeya qalab kale ama isticmaale kale. Falkan waa mid aan laga noqon karin Sii wad?",
  Bank_Code_Protection_Blocked_Word:"Nambarada bangiga aad isku dayeyso inaad isticmaashid waxaa loogu talagalay isticmaale ama qalab kale.",
  Bank_Code_Protection_Error_Word:"Xaqiijinta nambarku waa guuldareystay. Fadlan ku xidh internet-ka oo isku day inaad ku darto akoonkan bangiga mar labaad.",
  Bank_Code_Protection_Set_Error_Word:"Ilaalinta lambarka bangiga waxay u baahan tahay in lagugu xidho internetka. Fadlan isku xidh oo isku day markale",
  Upgrade_Unavailable_Word:"Waan ka xunahay, dhib ayaa naga haysata xaqiijinta Diiwaangelinta iyo cusboonaysiinta jeegaggu hadda lagama heli karo iibka aaggaaga.",
  PayPal_Payment_Preview:"Dib u eegida Paypal Payment",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Dooro PayPal",
  PayPal_Applications:"Codsiyada PayPal",
  Purchase_With_Apple_Pay:"Ku iibso Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Shirkadaha",
  Insurance:"Caymis",
  New_PayPal:"PayPal cusub",
  Pay_By:"Bixi by",
  Insure:"caymis",
  Miles:"Miles",
  Payment_Method:"Qaabka mushaar-bixinta",
  Select_Policies:"Dooro Siyaasadaha",
  Policies:"Siyaasadaha",
  Policy:"Siyaasadda",
  No_PayPal_Account:"Ma jiro Koontada PayPal",
  No_Policies:"Ma jiro Siyaasado Caymis",
  New_Policy:"Siyaasad Cusub",
  PayPal_Payment:"Paypal Payment",
  PayPal_Payments:"Lacagaha PayPal",
  No_Payment_Selected:"Lacag-bixin lama dooran",
  Sending_Payment_Word:"Fadlan sug... Lacagtan waa la soo diray.",
  Sending_Payments_Word:"Fadlan sug... Lacagaha waa la soo dirayaa.",
  No_Payment_Selected_PayPal:"Ma jiro <a routerLink='/folder/Payments'> PayPal lacag bixinta</a> oo la doortay in la diro.",
  Payment_Sent:"Lacag-bixinta la diray",
  PayPal_Payment_Sent:"Lacag bixintan waxa lagu soo diray PayPal.",
  Copay:"Lacag bixinta",
  Dead:"dhintay",
  Alive:"Nool",
  Not_Dead:"Ma Dhiman",
  Unclaimed:"Aan la sheegan",
  Attempted:"Isku dayay",
  Deceased:"dhintay",
  Claimed:"Sheegtay",
  Unpaid:"Lacag la'aan",
  Sending_Payment:"Diritaanka Lacag-bixinta",
  Sending_Payments:"Diritaanka Lacagaha",
  Send_PayPal_Confirmation:"Ma doonaysaa inaad ku dirto macaamilkan PayPal?",
  Send_PayPal_Confirmation_Word:"Riix badhanka cagaaran si aad u dirto macaamilkan.",
  Save_Payment_As_Unpaid:"U kaydi lacag bixintan sidii aan la bixin?",
  Payment_Pay_Confirmation_PayPal:"U kaydi lacag bixintan sidii loo bixiyay?",
  No_Policies_Word:"ku dar <a routerLink='/folder/Postage/New'> Siyaasadda caymiska</a> ee ugu horreeya .",
  Timesheet_Multiple_Delete_Confirmation:"Ma hubtaa inaad rabto inaad tirtirto xaashi waqtiyo badan?",
  Select_Multiple_Timesheets_Prompt:"Ma jiro waraaqo wakhti ah oo la doortay Dooro ugu yaraan hal xaashi waqti.",
  Select_Multiple_Policies_Prompt:"Siyaasado lama dooran Dooro ugu yaraan hal siyaasad caymis.",
  Policies_Multiple_Delete_Confirmation:"Ma hubtaa inaad rabto inaad tirtirto siyaasado badan?",
  Company:"Shirkadda",
  Add_Company:"Kudar Shirkada",
  New_Company:"Kudar Shirkada",
  No_Companies:"Shirkado ma leh",
  Enable_Company:"Awood u yeelashada Shirkadda",
  Select_Company:"Dooro Shirkad",
  The_Default_Company:"Summada shirkadda caadiga ah.",
  Manage_Companies:"Maamul shirkadaha",
  No_Companies_Word:"Jeegaggu waxay isticmaali doonaan shirkad aan caadi ahayn.<br />Ku dar <a routerLink='/folder/Company/New'> Shirkadda kowaad</a>.",
  Default_Company:"Shirkadda Default",
  Cheques_Unlimited_Word:"Checks Unlimited waxay kuu ogolaanaysaa inaad daabacdo jeegag badan inta aad rabto.",
  Cheques_Subscription_Word:"Ku biirista jeegaga waxay kuu ogolaanaysaa inaad daabacdo jeegag badan intaad rabto.",
  You_Own_This_Product:"Adiga ayaa iska leh alaabtan",
  Your_Subscription_is_Active:"Diiwaangelintaadu waa firfircoon tahay.",
  Active_Products:"Alaabooyinka Firfircoon",
  Purchase_Confirmation:"Iibsasho",
  Purchase_Cheques:"Jeegaga Iibka",
  Restore_Purchase:"Soo celinta Iibka",
  Erase_Purchase:"Tirtir Iibka",
  Successfully_Purchased:"Si Guul Ah Loo Iibsaday",
  Enter_Your_Licence_Key:"Fadlan geli furahaaga shatiga boggan si aad u dhaqaajiso alaabtan.",
  Licence_Key:"Furaha shatiga",
  Enter_Licence_Key:"Geli Furaha Shatiga",
  Purchased:"Iibsaday",
  Enable_Feature:"Daar feature",
  Cancel_Subscription:"Jooji Is-diiwaangelinta",
  Subscription_Removed:"Isdiiwaangelinta waa laga saaray",
  Select_Active_Subscription:"Dooro rukunka firfircoon si aad wax uga bedesho",
  Remove_Subscription_Word:"Ma hubtaa inaad doonayso inaad tirtirto ka-qaybgalkan?",
  Delete_Company_Confirmation:"Ma hubtaa inaad doonayso inaad tirtirto shirkaddan oo dhan? DIGNIIN: Waxaad waayi doontaa dhammaan macluumaadka kaydsan!",
  Delete_Default_Company_Word:"Ma tirtiri kartid shirkadda caadiga ah. Ma jeclaan lahayd inaad dib u dejiso arjiga oo aad ku soo celiso xaaladdii hore? DIGNIIN: Waxaad waayi doontaa dhammaan macluumaadka kaydsan!",
  Console_Warning_2:"Ha ku maamulin wax lacag ah adigoo isticmaalaya codsigan oo aan hadda ahayn kaaga.",
  Terms_and_Conditions:"Shuruudaha iyo Shuruudaha",
  and_the:"iyo",
  for:"waayo",
  Please_Read_Word:"Fadlan akhri oo ogolaw",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Qaar ka mid ah heerarka beddelka lacagta waa la heli kari waayay. Fadlan ku xidh internet-ka",
  Free:"Bilaash",
  DB_Erase_Prompt_2:"Si buuxda u tirtir dhammaan xogta horumariyaha? DIGNIIN: Waxaad waayi doontaa dhammaan macluumaadka iibka iyo is-diiwaangelinta!",
  Successfully_Imported:"Si Guul Ah Loo Soo Dejiyay",
  Select_Postage:"Dooro Boostada",
  No_Postage:"Ma jiro warqado Boostada",
  No_Paid_Postage_Word:"Ku dar shaambada ugu horreysa <a routerLink='/folder/Postage/New'> Boostada Lacag-bixinta ah</a>.",
  Trial_Complete:'Maxkamadeyntii Oo Dhammaatay',
  Please_Upgrade:'Fadlan cusboonaysii jeegaga si aad u sii waddo daabacaadda.',
  Aa:"Aa",
  Color:"Midabka",
  Font:"Farta",
  Guide:"Hagaha",
  Guides:"Hagayaasha",
  Image:"Sawirka",
  Zoom:"Soo dhaweyn",
  Logo:"Logo",
  Bank:"Bangiga",
  MICR:"MICR",
  Total:"Wadarta",
  Cancel:"Jooji",
  Confirm:"Xaqiiji",
  Method:"Habka",
  Biometric_Security:"Badbaadada biometric",
  Please_Login_To_Continue:"Fadlan gal si aad u sii wadato",
  Complete:"Dhameystiran",
  Sign_Up:"Isdiiwaangeli",
  Error:"Khalad",
  Biometrics:"Bio-metrics",
  Percent:"Boqolkiiba",
  Zero_Percent:"0%",
  Top_Margin:"Margin sare",
  Bottom_Margin:"Margin hoose",
  Left_Margin:"Margin Bidix",
  Right_Margin:"Margin Midig",
  MICR_Margin:"MICR Margin",
  Table_Margin:"Shaxda Margin",
  Address_Margin:"Ciwaanka Margin",
  Percentage_:"Boqolkiiba",
  Vacation_Title:"Magaca Fasaxa",
  Use_PIN:"Isticmaal PIN",
  Loading__:"Soodejinaya...",
  Design_Title:"Cinwaanka Naqshadda",
  Authorize:"Ogow",
  Key:"Furaha",
  Public_Key:"Furaha Dadweynaha",
  Private_Key:"Furaha Gaarka ah",
  Authenticate:"Xaqiiji",
  Last_Payroll:"Mushaharkii ugu dambeeyay",
  Last_Calculation:"Xisaabinta u dambaysa",
  Authorized:"idman",
  Geo_Authorized:"Geo-Goob: Waa la oggolaaday",
  Not_Authorized:"Lama ogola",
  Authorization_Complete:"Ogolaanshaha oo Dhamaystiran",
  Geolocation_Authorization:"Oggolaanshaha Juqraafiga",
  Out_of_Bounds:"Kabaxsan Xuduudka",
  Cant_Delete_Default_Design:"Ma tirtiri karo naqshad aan caadi ahayn",
  Unauthorized_Out_of_Bounds:"Aan La Fasaxin: Ka Baxsan Xuduudaha",
  Biometric_Authorization:"Oggolaanshaha Biometric",
  Locating_Please_Wait:"Helitaanka, Fadlan sug...",
  Test_Biometrics:"Tijaabi Bio-metrics",
  Cheque_Margins:"Hubi Margins",
  Percentage_Full_Error:"Goobta boqolleyda lama dejin karo in ka badan 100%",
  No_Payroll_Text:"Ku dar <a routerLink='/folder/Employee/New'>Shaqeeyaha</a>ama <a routerLink='/folder/Payee/New'>Payee</a> iyo <a routerLink='/folder/Schedule/New'>Jadwalka</a>.",
  Design_Saved:"Nakhshad La Badbaadiyay",
  Default_Design_Selected:"Naqshad Asal Ah Oo La Doortay",
  Partial_Import:"Soo dejinta qayb ka mid ah",
  Partial_Export:"Qayb Dhoofinta",
  Entire_Import:"Soo dejinta oo dhan",
  Entire_Export:"Dhoofinta oo dhan",
  Existing_Password:"Fadlan geli eraygaaga sirta ah ee jira:",
  Existing_PIN:"Fadlan geli lambarkaaga sirta ah ee jira:",
  Pin_Change_Header:"Xaqiijinta PIN",
  Pin_Change_SubHeader:"Geli lambarkaagi sirta ah si aad u xaqiijiso isbeddelka",
  Pass_Change_Header:"Xaqiijinta erayga sirta ah",
  Pass_Change_SubHeader:"Geli furahaagii hore si aad u xaqiijiso isbeddelka",
  PIN_Enter_Message:"Geli PIN kaaga si aad u xaqiijiso",
  Password_Enter_Message:"Geli eraygaaga sirta ah si aad u xaqiijiso",
  Pin_Updated_Success:"PIN si guul leh ayaa loo cusboonaysiiyay!",
  Pin_Updated_Fail:"PIN waa la cusboonaysiin kari waayay",
  Pass_Updated_Success:"Si guul leh ayaa loo cusboonaysiiyay erayga sirta ah",
  Pass_Updated_Fail:"Furaha lama cusboonaysiin karo",
  Preview_Payment:"Lacag bixinta Horudhac",
  Preview_Payroll:"Horudhac Mushaharka",
  No_Payments_Created:"Wax lacag ah lama helin in la abuuray",
  Payment_Preview:"Horudhac Lacag-bixinta",
  Enable_Payee:"Daar Payee",
  Rendered:"La soo bandhigay",
  No_Email:"Iimayl ma jiro",
  Setup_Cheques:"Hubinta dejinta",
  name:"Magaca",
  address:"Cinwaanka",
  address_2:"Khadka Ciwaanka 2",
  city:"Magaalada",
  province:"Gobolka",
  postal_code:"Boostada/koodka sibka",
  country:"Dalka",
  username:"Magaca isticmaale",
  full_name:"Magaca oo buuxa",
  birthday:"Maalinta dhalashada",
  email:"iimaylka",
  phone:"Taleefanka",
  employees:"Shaqaalaha",
  addresses:"Cinwaanada",
  payment_amount_limit:"Xadka Lacagta Lacagta",
  total_limit:"Wadarta Xadka",
  payees:"Lacag-bixiyeyaal",
  description:"Sharaxaada",
  address_line_one:"Cinwaanka Line One",
  address_line_two:"Laynka Ciwaanka Labaad",
  image:"Sawirka",
  account_holder:"Haystaha Akoonka",
  cheque_starting_id:"Hubi aqoonsiga bilowga",
  transit_number:"Lambarka safarka",
  institution_number:"Lambarka Hay'adda",
  designation_number:"Nambarka Magacaabista",
  account_number:"Lambarka Xisaabta",
  currency:"Lacagta",
  signature:"Saxeexa",
  payment_payroll_limit:"Xadka Lacag-bixinta",
  total_limi:"Wadarta xadka",
  date:"Taariikhda",
  printed_memo:"Xusuusin Daabacan",
  banks:"Bangiyada",
  signature_image:"Sawirka Saxiixa",
  address_name:"Magaca Ciwaanka",
  notes:"Xusuusin",
  design:"Nakhshad",
  title:"Ciwaanka",
  frequency:"Soo noqnoqda",
  fax:"Fakis",
  salaries:"Mushaharka",
  salary_ids:"Aqoonsiga mushaharka",
  start_time:"Waqtiga Bilawga",
  end_time:"Dhamaadka Waqtiga",
  paid:"La bixiyay",
  overtime_percentage:"Boqolkiiba la bixiyay",
  overtime_amount:"Lacag go'an oo la bixiyay",
  first_name:"Magaca koowaad",
  last_name:"Magaca Dambe",
  moderation:"dhexdhexaadnimo",
  create:"Abuur",
  edit:"Wax ka beddel",
  destroy:"Dumi",
  day_start_time:"Maalinta_bilawga_waqtiga",
  day_end_time:"Maalinta_dhamaadka_wakhtiga",
  fullname:"Magaca",
  time:"Waqtiga",
  auto_send:"Toos u dir",
  time_method:"Habka Waqtiga",
  schedules:"Jadwalka",
  indefinite_payroll_enabled:"U oggolow si aan xad lahayn",
  amount:"Qadarka",
  repeat:"Ku celi",
  always_enabled:"Had iyo jeer waa karti",
  start_date:"Taariikhda billowga",
  end_date:"Taariikhda dhamaadka",
  Cheque_Total:"Hubi wadarta",
  Total_Amount:"Wadarta qaddarka:",
  Amounts:"Qadarka",
  Images:"Sawirada",
  Files:"Faylasha",
  Previewing:"Dib u eegis:",
  Insert:"Geli",
  Preview_Import:"Soo dejinta horudhac",
  Entry:"Gelida",
  Entries:"Gelida",
  No_Entries:"Gelitaan La'aan",
  Import_Type:"Nooca soo dejinta",
  Select_Details:"Dooro Faahfaahin",
  Select_Payee:"Dooro Payee",
  Enable_Holidays:"Daar ciidaha",
  Disable_Holidays:"Jooji Fasaxyada",
  Wire_Transfer:"Wareejinta siliga",
  New_Export:"Dhoofinta Cusub",
  Export_Data:"Dhoofinta Xogta",
  Export_Data_Word:"Dooro nooca faylka si aad u dhoofiso oo loo soo dejiyo.",
  Export_File:"Dhoofinta File",
  Mode:"Habka",
  Times:"Waqtiyada",
  Widgets:"Widgets",
  Slider:"slider",
  Set_Details:"Deji Faahfaahin",
  Select_Type:"Dooro Nooca",
  Display_Slider:"Muuji Slider",
  Dashboard_Slider:"Slider Dashboard",
  Dashboard_Mode:"Habka Dashboard-ka",
  Show_Intro:"Muuji Hordhac",
  Show_Payrolls:"Muuji Mushaharrooyinka",
  Show_Holidays:"Muuji Fasaxyada",
  Show_Invoices:"Muuji qaansheegyada",
  Show_Cheques:"Muuji Jeegaga",
  Enabled_Widgets:"Widgets-da la dajiyay",
  Disabled_Widgets:"Widgets naafada",
  Colors:"Midabada",
  Barcodes:"Barcodes",
  View_Timers:"Daawo Saacadaha",
  Gradients:"Gradients",
  No_Info:"Xog malaha",
  Disable:"Hawl gab",
  Show_Layer:"Muuji Lakabyada",
  Hide_Layer:"Qari lakabyada",
  Text_Layer:"Lakabyada qoraalka",
  Secondly:"Marka labaad",
  Minutely:"Daqiiqad",
  nine_am:"9:00 subaxnimo",
  five_pm:"5:00 galabnimo",
  Enable_Address:"Daar Ciwaanka",
  Invalid_File_Type:"Waan ka xunahay, nooc fayl aan sax ahayn ayaa la doortay. Nooca faylka la taageeray:",
  Error_Updating_Entry:"Waan ka xunahay, khalad baa ka dhacay cusboonaysiinta gelitaankan.",
  Schedule_Timing_Alert:"Khalad: Jadwalka wakhtiga bilawga ah waxa lagu dejiyay qiime ka dib wakhtiga dhamaadka",
  Select_Multiple_Payments_Prompt:"Wax lacag bixin ah lama dooran Dooro ugu yaraan hal lacag bixin",
  Select_Multiple_Cheques_Prompt:"Jeegag lama dooran Fadlan dooro ugu yaraan hal jeeg",
  Select_Multiple_Items_Prompt:"Ma jiraan wax la doortay Fadlan dooro ugu yaraan hal shay.",
  Paymemt_Multiple_Delete_Confirmation:"Ma hubtaa inaad doonayso inaad tirtirto lacago badan?",
  Cheque_Multiple_Delete_Confirmation:"Ma hubtaa inaad rabto inaad tirtirto jeegag badan?",
  Payee_Multiple_Delete_Confirmation:"Ma hubtaa inaad doonayso inaad tirtirto lacag-bixiyeyaal badan?",
  Employee_Multiple_Delete_Confirmation:"Ma hubtaa inaad doonayso inaad tirtirto shaqaale badan?",
  MICR_Warning:"Fiiro gaar ah: Qaar ka mid ah daabacayaasha iyo aaladaha ayaa laga yaabaa inaysan si sax ah u muujin farta MICR.",
  Automatically_Send:"Toos u dir",
  Type:"Nooca",
  Payment_Type:"Nooca Lacag-bixinta",
  Auto_Send:"Si otomaatig ah u dir",
  Automatically_Process:"Si toos ah u socodsii",
  Auto_Process:"Habka Automatic",
  Image_Based:"Sawir ku salaysan",
  Font_Based:"Far ku salaysan",
  Rerender:"Dib u soo celinta",
  Rendering:"Bandhigga",
  Render:"Faylasha",
  Top:"Sare",
  Middle:"Dhexe",
  Bottom:"Hoose",
  Top_Left:"Bidix sare",
  Top_Center:"Xarunta Sare",
  Top_Right:"Midig sare",
  Middle_Left:"Bidix dhexe",
  Middle_Center:"Xarunta Dhexe",
  Middle_Right:"Midigta Dhexe",
  Bottom_Left:"Gunta Bidix",
  Bottom_Center:"Xarunta Hoose",
  Bottom_Right:"Midigta Hoose",
  Numbers:"Tirooyinka",
  Verified:"La xaqiijiyay",
  Paper_Size:"Cabbirka Warqadda",
  New_Device:"Qalab Cusub",
  Add_Device:"Kudar Aaladda",
  Remove_Device:"Ka saar Aaladda",
  Delete_Device:"Tirtir Qalabka",
  Block_Device:"Qalabka xannibaadda",
  Block:"Block",
  Unblock:"Furid",
  Table:"Miis",
  Scan_Login_Code:"Sawirka Koodhka Galitaanka",
  Login_Code:"Koodhka gelitaanka",
  Scan_Code:"Koodhka Sawirka",
  Scan_QR_Code:"Sawir code-ka QR",
  Select_All:"Dooro Dhammaan",
  Deselect_All:"Ha dooran Dhammaan",
  Increase:"Kordhiyo",
  Decrease:"Hoos u dhac",
  Bold:"Dhiirran",
  Text:"Qoraal",
  Style:"Qaabka",
  Italic:"Italic",
  QR_Code:"Koodhka QR",
  Barcode:"Barcode",
  Browse_Images:"Daawo Sawirada",
  Browse_Files:"baadh faylalka",
  Background_Image:"Sawirka Taariikhda",
  Logo_Image:"Sawirka Logo",
  Header_Image:"Sawirka madaxa",
  Bank_Image:"Sawirka Bangiga",
  Cut_Lines:"Xariiqyada gooyay",
  Background:"Asalkii hore",
  License:"Shatiga",
  One_License:"1 Ruqsadda:",
  Licensed:"Loo fasaxay:",
  Not_Licensed:"Aan shatiga u haysan",
  Enter_Serial:"Geli Taxane",
  Serial_Key:"Furaha Taxanaha ah",
  Serial:"Taxane",
  Product_Key:"Furaha Alaabta",
  Check_Product_Key:"Hubi Furaha Alaabta",
  Add_Product_Key:"Kudar Furaha Alaabta",
  Verifying_Purchase:"Xaqiijinaya Iibka...",
  Print_Envelope:"Baqshad daabacan",
  Envelope:"Baqshadda",
  Thank_You:"Mahadsanid!",
  Scale:"Miisaanka",
  Print_Scale:"Daabacaadda Miisaanka",
  Borders:"Xuduudaha",
  VOID:"MARAN",
  Void_Cheque:"Hubinta maran",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"BIXIN Amarka:",
  NO_DOLLARS:"DOLLAR MA JIRTO ",
  ONE_DOLLAR:"HAL DOLLAR",
  DOLLARS:" DOLLAR",
  AND:" IYO ",
  CENTS:" CENTS",
  NO_:"MAYA ",
  ONE_:"HAL ",
  AND_NO_:"IYO MAYA ",
  _AND_ONE_:"IYO HAL ",
  DOLLARS_AND_ONE_CENT:" IYO HAL CENT.",
  AND_NO_CENTS:" IYO CENTS EBER.",
  CHEQUE_PRINT_DATE:"TAARIIKHDA:",
  CHEQUE_PRINT_MEMO:"XUSUUS:",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Hawlaha ugu horreeya",
  Inital_Setup:"Dejinta Hore",
  Welcome_To:"Ku soo DHAWOOW ",
  Welcome:"soo dhawoow",
  Swipe:"Ku dhufo",
  Intro_Setup:"Intro Setup",
  Introduction:"Hordhac",
  CHEQUE_PRINT_CREDIT:"Waxaa ku shaqeeya jeegaga",
  Title_Intro_Word:"ku soo dhawoow jeegaga",
  Title_Intro:"hordhac jeegaga",
  Title_Setup:"Dejinta Jeegaga",
  PayPal_Default_Email_Message:"Waxaad heshay wareejin cusub PayPal. [Waxaa ku shaqeeya jeegaga]",
  Cheques_App_Export:"Waxaa dhoofiyay jeegaga",
  Post_Intro_Thanks:"Waad ku mahadsan tahay doorashada Jeegaga. Habka habayntu hadda waa dhammaatay.",
  Post_Intro_Word:"Ku bilow inaad daabacdo jeeggaaga koowaad, ku dar lacag bixin mustaqbalka, ama ku dar shaqaale liiska mushaharka.",
  Upgrade_Required:"Cheques waxay kaaga baahan yihiin inaad lahaato nooc ka sii qaalisan software-ka si aad u qariso fariintan oo aad u furto sifooyin dheeri ah.",
  Upgrade_Title:"Cheques",
  Mailout_Prompt:"Waxaa kale oo aad dooran kartaa in jeegaggu kuugu soo diro jeegaga mushaar bixinta.",
  Mailed_Cheque:"Check Boostada:",
  Mailed_Cheque_Color:"Check Boostada (Midabka):",
  Terms_Purchase:"Dhammaan iibsiyada elegtarooniga ah ee jeegaga si buuxda ayaa loo soo celin karaa ilaa 30 maalmood laga bilaabo taariikhda la iibsaday. Fadlan akhri oo ogola <a href='#'>Shuruudaha iyo Shuruudaha</a> ka hor intaadan sii wadin.",
  Dashboard_Setup:"Dejinta daabacaha koowaad",
  Dashboard_Add:"Ku dar Koontada Bangiga Koowaad",
  Dashboard_Customize:"Dooro A Check Template",
  Dashboard_Job_Salary:"Shaqadaada samee oo ku darso mushaharkaaga",
  Dashboard_Employees:"Lasoco Shaqaalaha & Lacag-bixiyayaasha",
  Dashboard_Print:"Daabac oo ku soo dir jeegaga mushaharkaaga",
  Dashboard_Payroll:"Otamaatig Daabacaadda Mushaharkaaga",
  Dashboard_PayPal:"Dejinta Payroll Mushahar / Lacag bixin",
  Begin_Setup:"Bilow Dejinta",
  Get_Started:"Bilow",
  Purchase:"Iibka",
  Lockdown:"Xiritaan",
  Unlock:"Fur",
  Detailed:"Faahfaahsan",
  Log_In:"Soo gal",
  Login:"Soo gal",
  Launch:"Launch",
  Register:"Is diwaangeli",
  Finish:"Dhamays",
  List:"Liiska",
  Weekends:"Toddobaadka",
  Weekly:"Todobaadle",
  PayPal_Default_Subject:"Lacag Cusub",
  Payment_Message:"Fariinta Lacag-bixinta",
  PayPal_Default_Payment_Note:"Mahadsanid",
  Setup_Your_Cheqing_Account:"Akoonka hubinta",
  Add_Your_Primary_Cheqing_Account:"Ku dar koontada jeegeynta koowaad.",
  Welcome_Word:"fudud oo si otomaatig ah u samee mushaar bixinta iyo maareynta kheyraadka aadanaha.",
  Get_Started_Quickly:"Kaliya ka jawaab dhowr su'aalood oo fudud oo naga caawin doona inaan bilowno...",
  Done_Intro_Word:"Dejinta oo dhamaystiran",
  PIN_Protected:"Password & PIN waa la ilaaliyaa",
  Enter_New_PIN:" Geli koodka PIN cusub:",
  Enter_PIN:" Geli lambarka sirta ah:",
  Invalid_PIN:"PIN aan sax ahayn ayaa la geliyey.",
  Account_Identifier:"Aqoonsiga Koontada",
  New_Account_Identifier:"Aqoonsiga Koontada Cusub",
  attempt:"isku day",
  attempts:"isku dayo",
  remaining:"haraad",
  Language:"Luqad",
  languages:"Luqadaha",
  select_languages:"Dooro Luuqad",
  Deposit:"Deposit",
  Hire_One_Now:"Hadda mid kirayso",
  App_Locked:"Codsiga wuu xiran yahay.",
  Skip_:"bood",
  Skip_to_Dashboard:"U gudub Dashboard-ka",
  Dashboard:"Dhashboard",
  Import:"Soo deji",
  Admin:"Maamulayaasha",
  New_Admin:"Maamulka Cusub",
  Settings:"Settings",
  Color_Picker:"Middooriyaha",
  Font_Picker:"Font Picker",
  Logout:"Ka bax",
  Close:"Xir",
  Close_menu:"Xir",
  Excel:"Excel File",
  Csv:"Faylka CSV",
  Sql:"Faylka SQL",
  Json:"Faylka JSON",
  Url:"Ku soo deji URL",
  Back:"Dib u noqo",
  Timers:"Timers",
  Cheque:"Hubi",
  Print:"Daabac",
  Designs:"Naqshadaynta",
  Pause_Printing:"Jooji Daabacaadda",
  Resume_Printing:"Bilow daabacaadda",
  Printing_Paused:"Daabacaadda waa la hakiyay",
  PrintingUnavailable:"Waan ka xunahay! Daabacaad lagama heli karo nidaamkan.",
  Prints_Paused:"Daabacaadii waa la hakiyay",
  Administration:"Maamulka",
  Loading:"loading",
  Unnamed:"Lama magacaabin",
  error:"Waan ka xunahay, jeegaan lama furi karin si loo daawado.",
  No_Cheques_To_Print:"Ma jiro jeegag lagu daabaco",
  No_Cheques_To_Print_Word:"Samee <a routerLink='/folder/Cheque/New'>Cheque Cusub</a>.",
  New_Cheque:"Check Cusub",
  Start_One_Now:"Hadda Bilow Hal",
  Edit_Cheque:"Wax ka bedel hubin",
  Select_Cheques:"Dooro Jeegaga",
  Select_Employee:"Dooro Shaqaale",
  Default_Printer:"Daabacaha caadiga ah",
  Reassign:"Dib u habeyn",
  Configure:"Habee",
  Template:"Template",
  Designer:"Naqshadeeye",
  Edit_Designs:"Wax ka bedel naqshadaha",
  New_Design:"Naqshad Cusub",
  Next:"Ku Xiga",
  Save:"Save",
  Name:"Magac",
  Mail:"Boostada",
  Amount:"Qaddarka",
  Date:"Taariikh",
  PayPal:"PayPal",
  Payouts:"Lacagta",
  PayPal_Label:"Label PayPal",
  Email_Username:"Email / Magaca isticmaalaha",
  Client_ID:"Aqoonsiga macmiilka",
  Sandbox_Email:"Imeel Sanduuqa Sanduuqa",
  PayPal_Email:"Emailka PayPal",
  PayPal_Username:"Magaca isticmaalaha API",
  PayPal_Payouts:"PayPal Payouts",
  Select_PayPal_Key:"Dooro Paypal Key",
  Secret:"Sirta",
  API_Secret:"Sirta API",
  PayPal_API_Keys:"PayPal Furayaasha",
  New_PayPal_Key:"Furaha cusub ee PayPal",
  Email_Subject:"Mawduuca iimaylka",
  Email_Message:"Fariinta iimaylka",
  Payout_Options:"Ikhtiyaarada Lacag bixinta",
  Payment_Note:"Xusuusin Lacag-bixineed",
  Enable_Employee:"U oggolow shaqaalaha",
  Enable_Production_Mode:"Kor u yeel Habka Wax-soo-saarka",
  Sandbox_Username:"Sandbox username",
  Sandbox_Signature:"Saxiixa sanduuqa Sanduuqa",
  Sandbox_Password:"Sandbox Password",
  Production_Username:"Magaca Isticmaalka Soo-saarka",
  Production_Signature:"Saxiixa Soo-saarka",
  Production_Password:"Password Production",
  Production_Email:"Iimayl-soo-saarka",
  API_Client_ID:"Aqoonsiga macmiilka API",
  API_Signature:"Saxiixa API",
  API_Password:"API Password",
  API_Username:"Magaca isticmaalaha API",
  Secret_Key:"Furaha Sirta",
  Sandbox:"Sandbox",
  Production:"Wax soo saar",
  Sandbox_Keys:"Furayaasha Sanduuqa Sanduuqa",
  Production_Keys:"furayaasha wax soo saarka",
  API_Title:"Cwaanka API",
  Production_Mode:"Qaabka wax soo saarka",
  Account_Label:"Label Account",
  No_PayPal_Setup:"Ma jiro Paypal Key",
  Enable_PayPal_Account:"Kor u yeel Koontada PayPal",
  No_PayPal_Word:"Kudar <a routerLink='/folder/Invoice/New'>PayPal API Key</a>.",
  Printed_Memo:"Xusuus Daabacan",
  Employee:"Shaqaale",
  View_Employee:"Arag Shaqaale",
  Mailing_Address:"Cinwaanka boostada",
  Company_Address:"Cinwaanka Shirkadda",
  Select_Company_Address:"Dooro Ciwaanka Shirkadda",
  Address:"Cinwaanka",
  Any_Day:"Maalin kasta",
  Address_Name:"Magaca Cinwaanka",
  Unit:"Qeyb",
  Account:"Account",
  Bank_Account:"Akoonka Bangiga",
  Account_Limits:"Kor u yeelo xadka xisaabta",
  Payroll:"Mushaarka mushaharka",
  New_Payroll:"Mushahar Cusub",
  No_Payroll:"Ma jiro Mushahar Soo Socda",
  Upcoming_Holiday:"Fasaxa soo socda:",
  Invoice_Due:"Lacag qaan-sheeg ah:",
  New_Invoice:"Biil cusub",
  No_Invoices:"Lacag ma laha",
  No_Invoices_Word:"Samee <a routerLink='/folder/Invoice/New'>Invoice-ka ugu horreeya</a>.",
  Cheque_Due:"Hubi waaga:",
  Payrolls:"Mushaharka",
  Sandbox_Mode:"Qaabka Sanduuqa",
  Hire:"Shareeyo",
  Pay:"Bil",
  New:"Cusub",
  Add:"Ku dar",
  Make:"Samee",
  Time:"Waqti",
  Write:"Qor",
  Holiday:"Fasax",
  Holidays:"Fasaxyada",
  Next_Holiday:"Fasaxa soo socda:",
  All_Done:"Dhammaan waa la sameeyay!",
  Employees:"Shaqaalaha",
  Payees:"Musharaxiinta",
  Job:"Shaqo",
  Jobs:"Shaqooyin",
  Invoice:"Bishii",
  Invoices:"Bishii",
  Vacations:"Fasaxa",
  Cheques:"Check",
  Brand_Cheques:"Calaamad",
  Payment:"Lacag bixin",
  Enable_Payment:"Kor u geli lacag bixinta",
  Payments:"Lacag bixin",
  Schedule:"Jadwalka",
  Schedules:"Jadwalka",
  Timesheet:"Waqtiga Waqtiga",
  Timesheets:"Waqtiga Waraaqaha",
  Salary:"Mushaharka",
  New_Address:"Cinwaan Cusub",
  Address_2:"Cinwaanka (Lidka 2)",
  _City:"Magaalada",
  _State:"Gobol/Prov",
  City:"Magaalada / Magaalada",
  State:"Gobol / Gobol",
  Postal:"Boostada / ZIP Code",
  ZIP:"Boostada/ZIP",
  Country:"Dal",
  Addresses:"Cinwaano",
  Required_Options:"Ikhtiyaarada loo baahan yahay",
  Optional_Options:"Ikhtiyaarada Ikhtiyaarka",
  Additional_Options:"Doorasho Dheeraad ah",
  Required:"loo baahan yahay",
  Optional:"Ikhtiyaar",
  Additional:"dheeraad ah",
  No_Addresses:"Cinwaano Ma Jiro",
  New_Address_Word:"Ku dar kan ugu horreeya <a routerLink='/folder/Cinwaanka/Cusub'>Cinwaanka</a>.",
  Select_Address:"Dooro Ciwaanka",
  No_Address:"Cinwaano Ma Jiro",
  Print_Cheque:"Check Print",
  Print_Cheque_Now:"Hubinta hadda daabac",
  Description:"Sharaxaad",
  Pay_To_The_Order_Of:"Ku bixi Amarka:",
  Select_Date_Range:"Dooro Qiyaasta Taariikhda",
  Select_Starting_Date:"Dooro Taariikhda Bilaabashada",
  Select_Ending_Date:"Dooro Taariikhda Dhammaysa",
  Select_Date:"Taariikhda dooro",
  Cheque_Date:"Taariikhda hubi",
  Cheque_Memo:"Hubi Xusuusta",
  Blank_Cheque:"Check maran",
  Blank:"Madhan",
  No_Cheques:"Lama hubin",
  No_Cheques_Word:"Daabac marka hore <a routerLink='/folder/Cheque/New'>Check</a>.",
  Cheque_Amount_Placeholder:"$0.00",
  View_Image:"Eeg sawirka",
  View:"Fiiri",
  Modify:"Wax ka beddel",
  Delete:"Tirtir",
  Cheque_Paid:"La bixiyay",
  New_Deduction:"Ka jarid Cusub",
  Title:"Cuqaal",
  Frequency:"Soo noqnoqoshada",
  Hourly:"Saacaddii",
  Daily:"Maalin walba",
  Weekdays:"Maalmaha shaqada",
  BiWeekly:"2 toddobaad",
  TriWeekly:"3 toddobaad",
  Monthly:"Bishii",
  MiMonthly:"2 Months",
  Quarterly:"Saddexdii biloodba mar",
  Yearly:"Sannad kasta",
  Every_Week:"Todobaad kasta",
  Every_Payroll:"Mushahar kasta",
  Every_Month:"Bil kasta",
  Annually:"Sannad kasta",
  Always_Scheduled:"Mar walba waa la qorsheeyay",
  Start_Date:"Taariikhda billowga",
  End_Date:"Taariikhda Dhammaadka",
  Start_Time:"Waqtiga Bilowga",
  End_Time:"Waqtiga Dhammaadka",
  Deduction_Label:"Label Deduction",
  Notes:"Xusuusin",
  Options:"Ikhtiyaarada",
  Enable:"Daw",
  Select_Deductions:"Dooro Wax-ka-jaryada",
  Deductions:"La dhimis",
  No_Deductions:"Lama Goyn",
  No_Deductions_Word:"Samee <a routerLink='/folder/Deduction/New'>Ka jaristaada ugu horreysa</a>.",
  New_Employee:"Shaqaal Cusub",
  No_Title:"Ma jiro cinwaan",
  _Name:"Magac",
  About_Yourself:"Naftaada ku saabsan",
  Full_Name:"Magaca buuxa",
  Birthday:"Maalin dhalasho",
  Email:"Imayl",
  SMS:"SMS",
  Phone:"Telefoon",
  Test:"Tijaabi",
  Call:" Wac",
  Fax:"Fax",
  Printed_Note:"Xusuusin Daabacan",
  Position:"Boos",
  Job_Position:"Boos Shaqo",
  Select_a_Job:"Dooro shaqo",
  Select_a_Salary:"Dooro Mushahar",
  Add_a_Deduction:"Ku dar wax ka dhimis",
  Taxes:"Canshuur",
  Add_Taxes:"Ku dar cashuur",
  Date_of_Birth:"Taariikhda Dhalashada",
  Email_Address:"Cinwaanka emailka",
  Phone_Number:"Lambarka taleefanka",
  Phone_Call:"Telefoonka wac",
  Enable_on_Payroll:"Ku darto mushaharka",
  Select_Employees:"Dooro Shaqaale",
  No_Employees:"Shaqaal malaha",
  No_Employees_Word:"Ku dar <a routerLink='/folder/Employee/New'>Shaqaale</a> cusub kaaga ugu horreeya.",
  No_Name:"Magac ma jiro",
  Unemployeed:"Shaqo la'aan",
  Employeed:"Shaqeeya",
  Paid:"La bixiyay",
  Enabled:"La dajiyay",
  Disabled:"Naafo",
  Fire:"Dabka",
  Not_Available:"Lama heli karo",
  Not_Available_Word:"Daabac <a routerLink='/folder/Invoice/New'>Invoice</a>kaaga ugu horreeya oo lacag hel.",
  Select_Invoices:"Dooro_Invoices",
  Print_Invoice_Word:"Daabac <a routerLink='/folder/Invoice/New'>Invoice</a>kaaga ugu horreeya oo lacag hel.",
  Invoice_Title:"Cwaanka qaansheegta",
  Invoice_Date:"Taariikhda qaansheegta",
  Due_Date:"Taariikhda dhici doonta",
  New_Job:"Shaqo Cusub",
  Details:"Faahfaahin",
  Customize:"Qabee",
  Customize_Dashboard:"Qalab khariidad",
  Components:"Qaybaha",
  No_Components:"Wax ka kooban ma jiro",
  Main_Components:"Qaybaha ugu muhiimsan",
  Smaller_Components:"Qaybaha yaryar",
  Error_Loading_Page:"Cilaad soo dejinta boggan.",
  Bank_Details:"Faahfaahinta Bangiga",
  About_Your_Job:"Ku saabsan Shaqadaada",
  Your_Schedule:"Jadwalkaaga",
  Job_Title:"Cwaanka Shaqada",
  Job_Description:"Sharaxaada Shaqada",
  Job_Details:"Faahfaahinta Shaqada",
  Enable_Job:"Daalin shaqada",
  Pay_Period:"Muddo lacag-bixineed",
  Perpetually_Schedule:"Jadwalka joogtada ah",
  Select_Jobs:"Dooro Shaqooyin",
  No_Jobs:"Shaqo la'aan",
  Add_Jobs:"Kudar Shaqooyin",
  No_Jobs_Word:"Ku dar liiska ugu horreeya <a routerLink='/folder/Job/New'>Shaqo</a>.",
  Count_Employees:"shaqada.employee_count+'Shaqaalaha'",
  Zero_Employees:"0 Shaqaale",
  New_Leave:"Fasax Cusub",
  Leave_Name:"Magaca ka tag",
  Paid_Leave:"Fasaxa Lacageed",
  Leave_Pay:"Ka tag Mushaharka",
  Indefinite_Leave:"Fasax aan xad lahayn",
  Indefinite_Payroll:"Mushaar aan xadidnayn",
  Leave:"ka tag",
  Add_Schedules:"Ku dar jadwal",
  Percentage:"100%",
  Enable_Leave_Of_Absence:"Daro Maqnaanshaha",
  Select_Leaves:"Dooro caleemaha",
  No_Leaves:"Caleemo Maqnaan La'aan",
  Leave_Of_Absence:"Fasaxa Maqnaanshaha",
  Leaves_Of_Absence:"Caleemaha Maqnaanshaha",
  New_Leave_of_Absense:"Fasaxa Maqnaanshaha Cusub",
  No_Leaves_Word:"Ku dar kan ugu horreeya <a routerLink='/folder/Leave/New'>Ka tagista Maqnaanshaha</a>.",
  Not_Enabled:"Lama darin",
  Absences:"Maqnaanshiyaha",
  Payee:"Payee",
  New_Payee:"Lacagta Cusub",
  Payee_Identifier:"Aqoonsiga Bixiyaha",
  Payee_Name:"Magaca Payee",
  Payee_Title:"Cwaanka lacag-bixiye",
  Payment_Memo:"Xusuuska Lacag-bixinta",
  Select_Payees:"Dooro Lacag-bixiyeyaal",
  No_Payees:"Ma jiro lacag-bixiyeyaal",
  Add_Payee_Note:"Ku dar kan ugu horreeya <a routerLink='/folder/Payee/New'>Payee</a>.",
  New_Payees:"Lacagta Cusub",
  About_The_Payment_Schedule:"Jadwalka Lacag-bixinta",
  Your_Payroll_Schedule:"Musharax toos ah",
  New_Payment:"Lacag Cusub",
  Identifier:"Aqoonsiga",
  Select:"Dooro",
  Memo:"Xusuus",
  Payment_Date:"Taariikhda lacag bixinta",
  Mark_as_Paid:"U calaamadee sidii loo bixiyey",
  Select_Payments:"Dooro Lacag-bixinta",
  No_Payments:"Lacag la'aan",
  No_Payments_Word:"Samee kan ugu horreeya <a routerLink='/folder/Payment/New'>Lacag bixinta</a>.",
  Create_Payroll:"Abuur Mushahar Mushahar",
  Properties:"Guryaha",
  Payroll_Title:"Cwaanka mushaharka",
  Payroll_Notes:"Xusuusyada Mushaharka",
  Payroll_Setup:"Dejinta mushaarka",
  Tabulate_Payments:"Lacag-bixinaha Tababbarka",
  Tabulate:"Tabulate",
  By:"Waxaa qoray:",
  Payments_By:"Lacag-bixinaha By",
  Timesheets_And_Schedules:"Waqtiga iyo Jadwalada",
  Both:"Labada",
  Items:"Alaab",
  Add_Payees:"Ku dar lacag-bixiyeyaal",
  Add_Account:"Kudar Account",
  Enable_Account:"Enable Account",
  Enable_Payroll:"Fur-geli mushaharka",
  Print_Payroll:"Mushaharka Daabaca",
  No_Payrolls:"Mushaar la'aan",
  No_Payroll_Word:"Samee <a routerLink='/folder/Pyroll/New'>Mushaharkaaga ugu horreeya</a>.",
  View_more:"VIEW_MORE",
  Less:" YAR",
  Add_Payroll:"Kudar Mushahar",
  Select_Payroll:"Dooro Mushaharka",
  About_Your_Salary:"Waxa ku saabsan mushaharkaaga",
  Add_Salaries:"Kudar Mushahar",
  Add_Salary:"Mushaharka ku dar",
  Salaries:"Mushaharka",
  No_Salaries:"Mushahar la'aan",
  No_Salaries_Word:"Ku dar kan ugu horreeya <a routerLink='/folder/Mushaharka/Cusub'>Mushaharka</a>.",
  Select_Salaries:"Dooro Mushahar",
  New_Salary:"Mushaharka Cusub",
  Salary_Name:"Aqoonsiga Mushaharka",
  Enable_Salary:"Furgeli mushaharka",
  Salary_Amount:"Qaddarka Mushaharka",
  New_Schedule:"Jadwal Cusub",
  Schedule_Title:"Cwaanka Jadwalka",
  Add_Address:"Cinwaanka ku dar",
  Repeat:"ku celi",
  Design:"Naqshada",
  Edit_Design:"Naqshadda Wax ka beddel",
  Edit_this_Design:"Wax ka bedel Naqshaddan",
  Repeat_Payment:"Lacagta ku celi",
  Enable_Schedule:"Dari jadwalka",
  Never:"Weligay",
  Select_Schedule:"Dooro Jadwalada",
  No_Schedules:"Jadwal malahan",
  No_Schedules_Word:"Samee <a routerLink='/folder/Jadwalka/Cusub'>Jadwalka </a> kan ugu horreeya.",
  New_Administrator:"Maamulka Cusub",
  Username:"Magaca isticmaalaha",
  First_Name:"Magaca koowaad",
  Last_Name:"Magaca Dambe",
  Add_an_Address:"Kudar ciwaan",
  Payment_Limit:"Xaddad Lacag-Bix-bixineed",
  Total_Limit:"Guud ahaan xadka",
  Select_Accounts:"Dooro Xisaabaadka",
  No_Administrators:"Maamuleyaal",
  No_Administrators_Word:"Samee <a routerLink='/folder/Administrator/New'>Akoonka maamulka</a> kan ugu horreeya.",
  New_Administrators_Word:"Ku dar kan ugu horreeya <a routerLink='/folder/Administrator/New'>Maamulaha</a>",
  Cloud:"Daruur",
  Backup:"Kabtan",
  Enable_iCloud:"Enable iCloud",
  Enable_Google_Drive:"Kor Google Drive",
  Enable_Microsoft_OneDrive:"Kor u geli Microsoft OneDrive",
  Automatically_Backup:"Kaabsi toos ah",
  FTP_Settings:"Dejinta FTP",
  URL_File_Prompt:"Fadlan sheeg goobta loogu talagalay faylka .xls / .xlsx / .json lagu soo dejiyo:",
  URL_SQL_Prompt:"Fadlan sheeg meesha uu ku yaal faylka SQLite si aad u soo dejiso:",
  FTP_Backup:"Kabtanka FTP",
  FTP_Import:"Soo dejinta FTP",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Martigeliyaha",
  Port:"Dekedda",
  Path:"Wadada",
  Data_Path:"Dariiqa Xogta",
  Enable_FTP_Account:"Kor FTP Account",
  HostnameIP:"Magaca martida",
  Password:"Password",
  Connection_Port:"Dekedda Isku xirka",
  Enable_MySQL_Database:"Enable MySQL Database",
  Log:"Log",
  Reset:"Dib u habeyn",
  Erase:"Masixi",
  Export:"Dhoofinta",
  Database:"Database",
  Upload_CSV:"Soo rar CSV",
  Download_CSV:"Soo deji CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL kaabta",
  Internal_Notes:"Xusuusyada Gudaha",
  Root_Path:"Jidka xididka",
  Select_Backup:"Dooro kaabta",
  Add_New_Backup:"Kudar Kaabta Cusub",
  First_Backup:"Dejinta kaydka ugu horreeya...",
  Backups:"Kaabayaal",
  Add_Backup:"Kudar kaabta",
  No_Backups:"Ma jiraan wax kayd ah oo la helay.",
  Select_Backup_Type:"Dooro nooca kaabta aad rabto inaad dejiso...",
  Backup_Type:"Nooca kaabta",
  FTP_Drive:"FTP Drive",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Wad",
  Microsoft_OneDrive:"Wad",
  Import_Fields:"Groonada soo dejinta",
  Import_Fields_Word:"Isticmaal qaybtan si aad u dooratid <a routerLink='/folder/Holiday/New'>I soo Dejinta</a> xogta.",
  Upload:"Soo rar",
  Download:"Download",
  Download_JSON:"U soo dejiso sida xogta JSON",
  Download_SQL:"U soo dejiso sidii faylka SQL",
  New_Bank:"Bangiga Cusub",
  New_Account:"Akoonka Cusub",
  New_Bank_Account:"Akoonka Bangiga Cusub",
  Upload_Image:"Soo rar sawirka",
  Bank_Name:"Magaca Bangiga",
  Bank_Address:"Cinwaanka Bangiga",
  Branch_Address:"Cinwaanka Laanta",
  Address_on_Cheque:"Cinwaanka",
  Cheque_Numbers:"Check Numbers",
  Cheque_Details:" Faahfaahinta hubi",
  Bank_Logo:"Logo Bank",
  Cheque_ID:"Hubi aqoonsiga",
  Starting_Cheque_ID:"Aqoonsiga hubinta bilawga",
  Transit_Number:"Lambarka Gaadiidka",
  Institution_Number:"Lambarka Hay'adda",
  Designation_Number:"Lambarka Magacaabista",
  Account_Number:"Lambarka Koontada",
  Limits:"Xaddooyinka",
  Default_Limits:"Xaddooyinka caadiga ah",
  Over_Limit:"In ka badan xad",
  Under_Limit:"Xad ka hooseeya",
  Payroll_Limit:" Xadka Mushaharka",
  Enable_Bank_Account:"Kor u yeel xisaabta bangiga",
  Select_Account:"Dooro Koontada",
  No_Bank_Account:"Akoon bangi ma jiro",
  No_Bank_Account_Word:"Ku dar kan ugu horreeya <a routerLink='/folder/Accounts/New'>Akoonka Bangi</a>.",
  Bank_Accounts:"Akoonka Bangiga",
  No_Accounts:"Accounts ma jiro",
  No_Accounts_Note:"Ku dar kan ugu horreeya <a routerLink='/folder/Accounts/New'>Akoonka Bangi</a>.",
  Cheque_Designer:"Hubi naqshadeeyaha",
  Cheque_Design:"Hubi Nakhshad",
  Select_Design:"Dooro Nakhshad",
  Cheque_Designs:"Hubi Nakhshad",
  No_Cheque_Designs:"Lama Hubin Naqshado",
  No_Cheque_Designs_Word:"Samee <a routerLink='/folder/Settings/Cheque/Design/New'>Hubi Naqshadeynta</a> adiga kuu gaar ah.",
  Set_Default:"U dhig sidii Default",
  Default:"Default",
  Remove:"Ka saar",
  Folder:"Fayl",
  Edit:"Wax ka bedel",
  LoadingDots:"Soo qaadaya...",
  Add_a_New_File:"Kudar <a href='#' (guji)='kudar()'>Fayl cusub </a>",
  this_folder:"galkan",
  FTP_Backup_Settings:"FTP Settings Backup",
  Secure_File_Transfer:"Wareejin sugan oo sugan",
  New_Holiday:"Fasaxa cusub",
  Add_Holiday:"Kudar Fasaxa",
  Holiday_Name:"Magaca fasaxa",
  Additional_Pay:"Mushahar Dheeraad ah",
  Enable_Holiday:"Daro fasax",
  Select_Holidays:"Dooro ciidaha",
  No_Holidays:"Maya Fasax",
  No_Holidays_Word:"Ku dar <a routerLink='/folder/Holiday/New'>Fasaxa Guud</a>ka ugu horreeya.",
  New_Import:"Soo dejin Cusub",
  Import_Data:"Xogta soo dejinta",
  Import_Data_Word:"Dooro nooca feylka ama habka rareynta ee aad dooratay.<br /> Waxaad awoodi doontaa inaad doorato mid kasta oo ka mid ah meelaha la soo dejiyey ee faylka u dhigma mid kasta oo ku jira abka ka dib markaad soo rogto faylka la taageeray.", 
  Import_File:"Soo deji File",
  Link_To_File:"Link to File",
  Select_File:"Dooro faylka",
  New_Moderator:"Modetor Cusub",
  Allow_Moderation:"Ogolow dhexdhexaadinta",
  Create_Paycheques:"Abuur jeegaggii mushaharka",
  Edit_Paycheques_and_Data:"Wax ka bedel jeegaga mushaharka iyo xogta",
  Destroy_Data_and_Paycheques:"Burbur xogta iyo jeegaga mushaharka",
  Bonus_Percentage:"Boqolkiiba jeegaga",
  Fixed_Amount:"Qaddarka go'an",
  Select_Moderator:"Dooro dhexdhexaadiyaha",
  No_Moderators:"Ma jiro dhexdhexaadiye",
  Moderators:"Moderators",
  Moderator_Account:"Samee <a routerLink='/folder/Administrator/New'>Koontada Dhexdhexaadiyaha</a> kan ugu horreeya.",
  No_Moderators_Word:"Ku dar kan ugu horreeya <a routerLink='/folder/Administrator/New'>Dhexdhexaadiye</a>.",
  Defaults:"Defaults",
  Provide_Us_Info:"Na sii macluumaadka",
  Setup_Your_Printer:"Daabacahaaga habee",
  Your_Company:"Ku saabsan Shirkaddaada",
  Company_Name:"Magaca Shirkadda",
  Currency:"Lacag",
  Default_Currency:"Lacagta asalka ah",
  Base_Monthly_Income:"Dakhliga bishii",
  Protection:"Ilaalinta",
  App_Protection:"Ilaalinta App",
  PIN_Code_Protection:"Ilaalinta Koodhka PIN",
  App_Protection_Word:"Dari hababka amniga ee caawinaya ilaalinta akoonkaaga.",
  PIN_Code:"Koodhka PIN",
  Change_PIN:"Bdel PIN",
  New_Password:"Sirta cusub",
  Geofence_Protection:"Ilaalinta Geo-Deedka",
  Geofence_Area:"Dejinta Aagga",
  Distance:"Masaafada",
  Setup_Now:"Dejinta Hadda",
  Mile:"Mayl",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Aqoonsiga wajiga",
  Face:"Waji",
  Setup:"Dejinta",
  Label:"Calaamad",
  Password_Protection:"Ilaalinta erayga sirta ah",
  Modify_Password:"Wax ka beddel erayga sirta ah",
  New_Tax_Entry:"Galitaanka Cusub ee Cashuurta",
  New_Tax:"Canshuurta Cusub",
  Tax_Label:"Cashuurta Canshuur",
  Perpetually_Enabled:"Si joogta ah waa la furay",
  Select_Taxes:"Dooro Canshuur",
  Tax_Deductions:"Canshuurta dhimista",
  No_Tax_Deductions:"Canshuur laga dhimi maayo",
  No_Tax_Deductions_Word:"Ku dar kan ugu horreeya <a routerLink='/folder/Tax/New'>Canshuurta</a> ka jarista.",
  New_Timer:"Waqtiga cusub",
  Start:"Bilow",
  Stop:"Jooji",
  Start_Timer:"Bilow saacad",
  Stop_Timer:"Stop Timer",
  Timer_Active:"Waqtiga Firfircoon",
  Timer:"Waqtiga:",
  Timer_Running:"Waqtiga: (Orod)",
  Save_Timer:"Save Timer",
  New_Timesheet:"Waqtiga Cusub",
  Select_Timesheets:"Dooro Xaashiyaha Waqtiga",
  Work_Notes:"Xusuusyada Shaqada",
  Entry_Title:"Cinwaanka Gelitaanka",
  No_Timesheets:"Ma jiro waraaqo waqti",
  No_Timesheets_Word:"Ku dar kan ugu horreeya <a routerLink='/folder/Timesheet/New'>Waqtiga Xaashida</a>.",
  Timesheet_Submitted:"Waqtiga Warqada La Gudbiyey",
  Timesheet_Congrats:"Hambalyo! Xaashida wakhtigaaga si guul leh ayaa loo gudbiyay. Waad ku mahadsan tahay!",
  Timesheet_Copy:"Si aad u hesho nuqul ka mid ah dukumentiyadaada fadlan na sii ciwaanka iimaylkaaga iyo/ama lambarka taleefanka gacanta.",
  Submit:"Soo gudbi",
  Allow_Notifications:"Ogolow ogeysiisyada",
  Untitled_Entry:"Gal Cusub",
  Untitled_Bank:"Bangiga aan cidi lahayn",
  Timesheet_Entry:"Galitaanka xaashida wakhtiga",
  Work_Description:"Sharaxaada Shaqada",
  Enable_Timesheet:"Kolgeli xaashida wakhtiga",
  Submit_Timesheet:"Soo gudbi xaashida wakhtiga",
  Vacation:"Fasaxa",
  New_Vacation:"Fasaxa cusub",
  Vacation_Name:"Magaca fasaxa",
  Paid_Vacation:"Fasaxa la bixiyay",
  Vacation_Pay:"Mushaarka fasaxa",
  Enable_Vacation:"Furada dami",
  Select_Vacations:"Dooro Fasaxa",
  No_Vacations:"Fasaxa la'aan",
  No_Vacations_Word:"Samee gelitaanka ugu horreeya <a routerLink='/folder/Fasaxa/Cusub'>Fasaxa</a>.",
  Payroll_Schedule:"Jadwalka Mushaharka",
  Next_Payroll:"Mushaharka soo socda:",
  Upcoming_Payroll:"Mushaharka soo socda",
  No_Upcoming_Payroll:"Ma jiro Mushahar Soo Socda",
  Address_Book:"Buugga Ciwaanka",
  Archived_Documents:"Dukumentiyada kaydsan",
  Dev_Mode:"Codsiga Qaabka Horumarineed",
  Administrators:"Maamulayaasha",
  Privacy:"Sirta",
  None:"Ma jirto",
  Select_Signature:"Dooro Saxiixa",
  No_Signatures:"Lama Saxiix",
  No_Signatures_Word:"Ku dar kan ugu horreeya <a routerLink='/folder/Saxiixa/Cusub'>Saxiixa</a>.",
  Repeat_Indefinitely:"Ku celi si aan xad lahayn",
  Sign:"Calaamad",
  Sign_Here:"Halkan saxiix",
  Date_Signed:"Taariikhda La Saxiixay",
  Signature_Pad:"Saxiixa Saxiixa",
  Account_Holder:"Haysta Koontada",
  Account_Properties:"Guryaha Koontada",
  Name_On_Cheque:"Magaca jeegga ku yaal",
  Server_Hostname:"Magaca martida loo yahay ee adeegaha",
  Printers:"Daabacayaasha",
  No_Printers:"Madabacayaal ma leh",
  Printer_Exists:'Daabacaha magacan ayaa horay u jiray.',
  No_Printers_Word:"Ku dar kan ugu horreeya <a routerLink='/folder/Printer/New'>Daabacaha</a>.",
  No_Printer_Alert:"Ma daabace lama qeexin. Ma jeclaan lahayd inaad dejiso daabacaha?",
  Add_Printer:"Kudar Printer",
  New_Printer:"Daabac cusub",
  Printer_Hostname:"Magaca martigeliyaha daabacaha / IP",
  Printer_Label:"Astaanta daabacaha",
  Printer_Protocol:"Protocol-ka daabacaha",
  Protocol:"Protocol",
  Email_Print:"Imayl",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Socket",
  Print_Job:"Shaqo daabac",
  Printed:"La daabacay",
  Not_Printed:"Lama Daabicin",
  Print_Jobs:"Shaqo daabac",
  Print_Queue:" safka daabacaadda",
  No_Print_Jobs:"Shaqo daabacan ma jirto",
  No_Prints:"Samee <a routerLink='/folder/Cheque/New'>Cheque</a> cusub si aad u daabacdo.",
  Prints:"Daabac",
  Find_Printer:"Raadi daabacaha",
  Find_AirPrint_Devices:"Raadi Aaladaha AirPrint",
  Select_Printer:"Dooro daabacaha",
  System_UI:"Nidaamka UI",
  Printer:"Daabac",
  Status:"Xaaladda",
  Preview:"Preview",
  Enable_Print_Job:"Daabacaadda shaqada",
  Queue_Weight:"Miisaanka safka",
  Unlimited:"Aan xad lahayn",
  Show_Advanced_Printer_Options:"Muuji Xulashooyinka Daabacaha Sare",
  Advanced:"Hore",
  Location:"Goob",
  Note:"Fiiro gaar ah",
  Queue_Name:"Magaca safka",
  Pages_Printed_Limit:"Xadiga Bogagga Daabacan",
  MultiPage_Idle_Time:"Waqtiga sugitaanka bogag badan",
  Page_Count_Limit:"Xaddada tirinta bogga",
  Page_Orientation:"Hagidda bogga",
  Portrait:"Sawir",
  Landscape:"Muqaal muuqaal",
  Duplex:"Duplex",
  Double_Sided_Printing:"Laba dhinac",
  Duplex_Mode:"Qaabka Duplex",
  Duplex_Short:"Gaaban",
  Duplex_Long:" dheer",
  Duplex_None:"Ma jirto",
  Color_And_Quality:"Midabka Iyo Tayada",
  Monochrome:"Monochrome",
  Photo_Quality_Prints:"Daabacaada Tayada Sawirka",
  Printer_Email:"Iimayl daabacaha",
  Automatically_Downsize:"Si toos ah hoos u dhig",
  Paper:"Warqad",
  Paper_Name:"Magaca Warqada",
  Paper_Width:"Balacda Warqad",
  Paper_Height:"Heer warqad",
  Paper_Autocut_Length:"Dheernimada Warqad si toos ah loo gooyey",
  Margins:"Margins",
  Page_Margins:"Bogga Margins",
  Page_Margin_Top:"Bogga sare ee margin",
  Page_Margin_Right:"Bogga Midig ee Margin",
  Page_Margin_Bottom:"Bogga Hoose Margin",
  Page_Margin_Left:"Bogga Bidix margin",
  Add_Employees:"Ku dar Shaqaale",
  Header:"Madax",
  Print_A_Page_Header:"Daabac madaxa bogga",
  Header_Label:"Labelka madaxa",
  Header_Page_Index:"Tusmada bogga madaxa",
  Header_Font_Name:"Foot madaxeed",
  Select_Font:"Dooro farta",
  Font_Selector:"Xuliyaha Farta",
  Header_Font_Size:"Foot madaxeed",
  Header_Bold:"Madax geesinimo leh",
  Header_Italic:"Madax Italic",
  Header_Alignment:"Isku toosinta madaxa",
  Left:"Bidix",
  Center:"Xarunta",
  Right:"Xaq",
  Justified:"Waa la caddeeyey",
  Header_Font_Color:"Midabka madaxa",
  Select_Color:"Dooro Midabka",
  Footer:"Cag",
  Print_A_Page_Footer:"Daabac bogga cagta",
  Footer_Label:"Cag sumadda",
  Footer_Page_Index:"Tusmada bogga cagta",
  Footer_Font_Name:"Foot Font",
  Fonts:"Funto",
  Done:"La sameeyay",
  Select_Fonts:"Dooro Xarfaha",
  Footer_Font_Size:"Xadiga cagta",
  Footer_Bold:"Cag dhiirranaan",
  Footer_Italic:"Footer jiifta",
  Footer_Alignment:"Isku toosinta cagta",
  Footer_Font_Color:"Midabka cagta",
  Page_Copies:"Koobiyaasha bogga",
  Enable_Printer:"Dabciga daabacaha",
  Remote_Logging:"Goynta fog",
  Log_Server:"Log Server",
  Encryption:"Sirta",
  Random_Key:"Furaha Random",
  Encryption_Key:"Furaha sirta",
  Cheques_Webserver:"Custom Database",
  Learn_How:"Sidee baro",
  Signature:"Saxiixa",
  Default_Printer_Unit:"gudaha/cm/mm/(pt)",
  View_Signature:"Eeg Saxiixa",
  Printed_Signature:"Saxiixa Daabacan",
  Digitally_Sign:"Si Dijital ah u saxiix",
  Digital_Signature:"Saxiixa Dijital ah",
  Add_Signature:"Ku dar Saxiixa",
  Add_Your_Signature:"Ku dar Saxiixaga",
  Enable_Signature:"Dari Saxiixa",
  Digitally_Signed:"Si Dijital ah u Saxiixay",
  Insert_Error:"Ma awoodo in la kaydiyo jeega sababo la xiriira xogta xogta.",
  Delete_Confirmation:"Ma hubtaa inaad doonayso inaad tirtirto macluumaadkan?",
  Discard_Confirmation:"Ma hubtaa inaad doonayso inaad iska tuurto macluumaadkan?",
  Discard_Bank_Confirmation:"Ma hubtaa inaad doonayso inaad tuurto akoonkan?",
  Discard_Printer_Confirmation:"Ma hubtaa inaad rabto inaad tuurto daabacahan?",
  Delete_Bonus_Confirmation:"Ma hubtaa inaad doonayso inaad tirtirto gunnadan?",
  Delete_Invoice_Confirmation:"Ma hubtaa inaad rabto inaad tirtirto qaansheegtan?",
  Generated_Cheque:"Check la abuuray",
  Generated_Invoice:"Bishii la soo saaray",
  Schedule_Start_Time:"Bilawga Jadwalka",
  Schedule_End_Time:"Dhammaadka Jadwalka",
  New_Call:"Wicitaan Cusub",
  No_Contacts:"Lama Xidhiidho",
  No_Contacts_Word:"Maamulka, dhexdhexaadiyeyaasha, shaqaalaha, iyo lacag-bixiyeyaasha waxay u muuqdaan inay yihiin xiriiriye.",
  PDF_Subject:"Maaliyad",
  PDF_Keywords:" jeegaga jeegaga mushaharka ee PDF",
  Printer_Setup:"Dejinta daabacaha",
  Printer_Selection:"Doorashada daabacaha",
  New_Fax:"Fakis Cusub",
  New_Fax_Message:"Fariin Fakis Cusub",
  Fax_Machine:"Mashiinka Fakiska",
  Fax_Name:"Magaca Fakiska",
  Fax_Email:"Iimayl Fakis",
  Fax_Number:"Fax Number",
  Contents:"Nuxurka",
  Fax_Body:"Bogga Bogga",
  Header_Word:"Madaxa:",
  Header_Text:"Qoraalka madaxa",
  Include_Header:"Ku dar Madaxa",
  Include_Footer:"Ku dar Lugaha",
  Footer_Word:"Cag:",
  Footer_Text:"Qoraalka cagta",
  Attach_a_Cheque:"Ku dheji jeeg",
  Add_Deduction:"Ku dar ka dhimista",
  Enable_Fax:"Fakis dir",
  Select_Fax:"Dooro Fakis",
  No_Faxes:"Fakis ma jiro",
  Faxes:"Fakis",
  Save_and_Send:"Fakis dir",
  Save_and_Pay:"Keydi oo Bixi",
  WARNING:"DIGNIIN:",
  Remember:"Xusuusnow",
  Printing:"Daabacaadda",
  Printing_Complete:"Daabacadu way dhammaatay!",
  of:"of",
  There_Were:"Waxaa jiray",
  Successful_Prints:" daabacado guul leh iyo",
  Unsuccessful_Prints:"Daabacadaha aan guulaysan.",
  PrinterError:"Waan ka xumahay! khalad baa dhacay.",
  Printing_:"Daabacaada...",
  PrinterSuccess:"Dokumentiga si guul leh ayaa loo daabacay.",
  PrintersSuccess:"Dukumentiyada si guul leh ayaa loo daabacay.",
  Print_Cheques:"baaritaannada daabacaadda",
  New_Message:"Fariin Cusub",
  New_Messages:"Farriimo Cusub",
  Read_Message:"Akhri fariinta",
  Write_Message:"Qor fariin",
  Send_Message:"Fariin dir",
  Subject:"Mawduuc",
  Message:"Fariin",
  Writing:"Qoritaanka...",
  Send:"Diri",
  Set_Date:"Taariikh deji",
  Set_Time:"Wakhti deji",
  Recieve:"Qaado",
  Set_as_Default:"U dhig sidii Default",
  Default_Account:"Akoonka Hore",
  Default_Design:"Naqshadaynta Hore",
  Multiple_Cheques:"Checks (Tripple)",
  Account_Mode:"Qaabka xisaabta",
  Multiple_Cheques_Description:"Saddex jeeg boggiiba.",
  Check_and_Table:"Hubi & Miiska",
  Check_including_Table:"Check & miiska xisaabaadka.",
  Check_Mailer:"Hubi Boostada",
  Check_Mailer_Window:"Ka hubi daaqada ciwaanka.",
  DocuGard_Table_Top:"DocuGard Hubi iyo Shaxda (Sare)",
  DocuGard_Table_Middle:"DocuGard Hubi iyo Shaxda (Dhexe)",
  DocuGard_Table_Bottom:"DocuGard Hubi iyo Shaxda (Hoose)",
  DocuGard_Mailer_Top:"DocuGard Hubinta Boostada (Sare)",
  DocuGard_Mailer_Middle:"DocuGard Hubinta Boostada (Dhexe)",
  DocuGard_Mailer_Bottom:"DocuGard Hubi Boostada (Hoose)",
  DocuGard_Three_Cheques:"DocuGard Checks (Saddex Laab)",
  DocuGard_Cheque_Top:"DocuGard Check (Sare)",
  DocuGard_Cheque_Middle:"DocuGard Check (Dhexe)",
  DocuGard_Cheque_Bottom:"DocuGard Check (Hoose)",
  DocuGard_Three_Cheques_Window:"Saddex jeeg oo hal bog ah.",
  DocuGard_Table_Top_Window:"Check & miiska dakhliga.",
  DocuGard_Table_Middle_Window:"Check & miiska dakhliga.",
  DocuGard_Table_Bottom_Window:"Check & miiska dakhliga.",
  DocuGard_Mailer_Top_Window:"Hubi, miiska, iyo ciwaanka.",
  DocuGard_Mailer_Middle_Window:"Hubi, miiska, iyo ciwaanka.",
  DocuGard_Mailer_Bottom_Window:"Hubi, miiska, iyo ciwaanka.",
  DocuGard_Cheque_Top_Window:"Ka hubi warqad sugan.",
  DocuGard_Cheque_Middle_Window:"Ka hubi warqad sugan.",
  DocuGard_Cheque_Bottom_Window:"Ka hubi warqad sugan.",
  Basic_Cheque:"Hubi (Sare)",
  Basic_Cheque_Print:"Daabac hal jeeg.",
  Error_Setting_As_Paid:"Cillad U Dejinta Sida Loo Bixiyey",
  Add_Attachment:"Kudar Lifaaqa",
  PrinterUnavailable:"Daabacaha lama heli karo",
  CreditCardComponent:" Kaararka",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Deposit Cusub",
  Deposits:"Deposit",
  No_Deposits:"Lama Dhigo",
  Credit_Card_Deposit:"Kaarka deynta",
  New_Credit_Card_Deposit_Message:"Debaajiga Kaadhka Credit",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin Deposit",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Interac Transfer",
  Payments_Limit:" xadka lacag-bixinta",
  No_Payment_Limit:"Lacag lacag bixin ah ma jirto",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"Deposit PayPal",
  No_Deposits_Word:"Ku dar shirkadda ugu horreysa <a routerLink='/folder/Deposit/New'></a>.",
  No_Documents_Specified:"Ma jiraan wax dukumeenti ah oo loo cayimay daabacaadda",
  No_Printers_Added:"Wax daabacayaal ah lama helin. Tag Settings > Printer si aad mid ugu darto.",
  DB_Erase_Prompt:"Gabi ahaanba tirtir xogta xogta oo dhan? DIGNIIN: Waxaad waayi doontaa dhammaan macluumaadka kaydsan!",
  Console_Warning:"Haku dhejin wax qoraal ah console-kan. Waxaad naftaada iyo/ama shirkaddaada gelin kartaa khatar weyn.",
  No_Faxes_Word:"Samee <a routerLink='/folder/Fakis/New'>Fakiskii ugu horreeyay</a>.",
  Cheque_Delete_Confirmation:"Ma hubtaa inaad doonayso inaad tirtirto jeegan?",
  Design_Delete_Confirmation:"Ma hubtaa inaad rabto inaad tirtirto naqshaddan?",
  Cheque_Pay_Confirmation:"U calaamadee jeegan mid la bixiyay? kuma soo muuqan doono safka daabacaadda.",
  Payment_Pay_Confirmation:"U calaamadee lacag bixintan in ay tahay mid la bixiyay? kuma muuqan doonto safka jeegga.",
  Delete_Deduction_Confirmation:"Ma hubtaa inaad doonayso inaad tirtirto lacagtan laga jaray?",
  Delete_Job_Confirmation:"Ma hubtaa inaad doonayso inaad shaqadan tirtirto?",
  Delete_Timesheet_Confirmation:"Ma hubtaa inaad rabto inaad tirtirto xaashidan wakhtiga?",
  Delete_Schedule_Confirmation:"Ma hubtaa inaad rabto inaad tirtirto jadwalkan?",
  Delete_Setting_Confirmation:"Ma hubtaa inaad rabto inaad dib u dejiso goobtan?",
  Delete_Fax_Confirmation:"Ma hubtaa inaad rabto inaad tirtirto faakiskan?",
  Delete_File_Confirmation:"Ma hubtaa inaad rabto inaad tirtirto faylkan?",
  Delete_Vacation_Confirmation:"Ma hubtaa inaad doonayso inaad tirtirto fasaxan?",
  Delete_Printer_Confirmation:"Ma hubtaa inaad rabto inaad tirtirto daabacahan?",
  Remove_Design_Confirmation:"Ma hubtaa inaad rabto inaad tirtirto naqshaddan?",
  Delete_Payroll_Confirmation:"Ma hubtaa inaad doonayso inaad tirtirto liiska mushaharka?",
  Send_Fax_Email_Confirmation:"Ma rabtaa inaad fakis iyo iimayl u dirto dukumentiyadan?",
  Send_Email_Confirmation:"Ma rabtaa inaad iimayl u dirto dukumeentigan?",
  Send_Fax_Confirmation:"Ma rabtaa inaad fakis ku sameyso dukumeentigan?",
  Error_Generating_PDF:"Waan ka xunahay. Waxaa jiray khalad samaynta dukumeentigan.",
  PDF_Error_Saving_Image:"Waan ka xunahay",
  Test_Printer_Confirmation:"Ma waxaad doonaysaa inaad ku daabacdo bogga tijaabada daabacahaan?",
  Save_Timesheet_Prompt:"Fadlan ku dar 'Title' ama taabo 'Start Timer' si aad u kaydsato.",
  Remove_Geofence_Prompt:"Ma hubtaa inaad rabto inaad meesha ka saarto xayndaabkan juqraafiyeed?",
  Delete_Employee_Confirmation:"Ma hubtaa inaad doonayso inaad tirtirto",
  Fire_Employee_Confirmation:"Ma hubtaa inaad doonayso inaad rido"
}