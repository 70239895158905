export const Sv = {
  cheque_starting_id_blank:"001",
  transit_number_blank:"•••••",
  institution_number_blank:"•••",
  designation_number_blank:"•••",
  account_number_blank:"••••",
  Author_Name: "Liam Hogan",
  CHEQUE_PRINT_AMOUNT_INDEX:"/ 100",
  Copyright:"Copyright &copy; 2023",
  black:"Svart",
  green:"Grön",
  gold:"Guld",
  blue:"Blå",
  brown:"Brun",
  purple:"Lila",
  pink:"Rosa",
  red:"Röd",
  Swatches:"Swatches",
  HSL:"HSL",
  RGB:"RGB",
  Hue:"Nyans",
  Saturation:"Mättnad",
  Lightness:"Lätthet",
  Upgrade_To_Pro:"Uppgradera till PRO",
  AllFeaturesInclude:"Alla funktioner inkluderar:",
  PrintUnlimitedCheques:"Skriv ut obegränsade checkar",
  PremiumChequeDesigns:"Premium checkdesigner",
  ManageUnlimitedEmployees:"Hantera obegränsat antal anställda",
  AddUnlimitedPayees:"Lägg till obegränsade betalningsmottagare",
  CreateUnlimitedPayrolls:"Skapa obegränsade löner",
  UnlimitedSchedulesandTimeSheets:"Obegränsade scheman och tidrapporter",
  BulkPayPalPayouts:"Bulk PayPal-utbetalningar",
  UnlimitedBankAccounts:"Obegränsade bankkonton",
  ManageMultipleCompanies:"Hantera flera företag",
  TrackInsurancePolicies:"Spåra försäkringar",
  Bio_MetricProtection:"Biometriskt skydd",
  Geo_FenceLock_OutProtection:"Geo-Fence Lock-Out-skydd",
  Multiple_Companies_Word:"Att hantera flera företag är inte tillgängligt utan Checks-premie.",
  PayPal_Payouts_Word:"PayPal-betalningar är inaktiverade utan Checks-premie.",
  PINProtection:"PIN-skydd",
  PasswordProtection:"Lösenordsskydd",
  May_Require_Approval:"Kan kräva godkännande.",
  Subscribe:"Prenumerera",
  Billed:"Fakturerad",
  Up:"Upp",
  Down:"Ner",
  Positioning:"Positionering",
  Marker:"Markör",
  Drag_Marker:"Dra markör",
  Tagline:"Skriv ut checkar och tabellera löner",
  Marker_Word:"Använd markörerna för att dimensionera elementet.",
  Pinch_Zoom:"Nyp zoom",
  Pinch_Word:"Nyp för att zooma in elementet.",
  Drag:"Drag",
  Drag_Word:"Använd fingret för att dra element.",
  subscription_alias_word:"Automatisk förnyelse av prenumeration",
  premium_alias_word:"Engångsuppgraderingspaket",
  print_alias_word:"Skriv ut individuella checkar",
  mode_alias_word:"Läge",
  Pro:"Proffs",
  Pro_word:"Pro-version krävs.",
  Cant_Delete_Default_Company_Word:"Tyvärr, du kan inte ta bort ditt standardföretag.",
  Reinsert_Default_Designs:"Infoga standardmönster igen",
  Reinsert_Default_Designs_word:"Vill du infoga standarddesigner igen?",
  Subscription_Active_Disable_Word:"Denna prenumeration är aktiv. Vill du avbryta denna prenumeration på Cheques?",
  Company_Logo:"Företagslogotyp",
  ZERO_:"NOLL",
  Disclaimer:"varning",
  Privacy_Policy:"Integritetspolicy",
  EULA:"Kontrollerar EULA",
  Terms_Of_Service:"Användarvillkor",
  Terms_Of_Use:"Villkor",
  Refunds:"Återbetalningspolicy",
  Single_Print:"1 Kontrollera",
  Two_Prints:"2 Checkar",
  Five_Prints:"5 checkar",
  Ten_Prints:"10 checkar",
  Fifteen_Prints:"15 checkar",
  Twenty_Prints:"20 checkar",
  Thirty_Prints:"30 checkar",
  Image_Added:"Bild tillagd",
  Image_Preview:"Bildförhandsgranskning",
  No_Image_Was_Selected:"Ingen bild har valts.",
  Cheques_Unlimited:"Checkar obegränsat",
  _Subscription:"Prenumeration",
  Subscription:"Checkar - 1 månad",
  Two_Month_Subscription:"Checkar - 2 månader",
  Three_Month_Subscription:"Checkar - 3 månader",
  Six_Month_Subscription:"Checkar - 6 månader",
  Twelve_Month_Subscription:"Checkar - 12 månader",
  Cheques_Are_Available:"Checkar finns att skriva ut.",
  Upgrade_Required_Two:"Välj ett paket och dubbelklicka på prisknappen för att påbörja ditt köp. Skriv ut proffsiga fullfärgscheckar på några sekunder.",
  Month:"Månad",
  Due:"På grund av:",
  Expires:"Upphör att gälla:",
  Subscription_Active:"Prenumeration aktiv",
  Subscription_Inactive:"Prenumeration inaktiv",
  Purchase_Additional_Cheques:"Köpa ytterligare checkar?",
  Printable_Cheque:"Utskrivbar check",
  Printable_Cheques:"Utskrivbara checkar",
  Printable_Cheque_Word:"check är tillgänglig på ditt konto.",
  Printable_Cheques_Word:"checkar är tillgängliga på ditt konto.",
  Max_Amount_Message:"Beloppet du har angett har nått över det maximala beloppet som har angetts för detta system:",
  Terms_Required_Word:"Du måste godkänna detta avtal innan du fortsätter att använda checkar.",
  Subscriptions:"Prenumerationer",
  Product_Upgrades:"Uppgraderingar",
  Mailed_Out_Cheques:"Utskickade checkar",
  Enter_A_Company_Name:"Ange ett företagsnamn.",
  Single_Cheques:"Enstaka kontroller",
  Cheque_Golden:"Gyllene check",
  Cheque_Golden_Window:"Golden check design.",
  Cheque_Green:"Grön bock",
  Cheque_Green_Window:"Grön rutig design.",
  Cheque_Red:"Röd check",
  Cheque_Red_Window:"Röd rutig design.",
  Cheque_Yellow:"Gul check",
  Cheque_Yellow_Window:"Gul checkdesign.",
  Cheque_Statue_of_Liberty:"Frihetsgudinnan",
  Cheque_Statue_of_Liberty_Window:"Frihetsgudinnan checkdesign.",
  Cheque_Green_Wave:"Grön våg",
  Cheque_Green_Wave_Window:"Grön rutig design.",
  Cheque_Golden_Weave:"Gyllene väv",
  Cheque_Golden_Weave_Window:"Elegant gyllene rutig design.",
  Cheque_Green_Sun:"Grön sol",
  Cheque_Green_Sun_Window:"Djup och lugnande checkdesign.",
  Cheque_Blue_Checkers:"Blå pjäser",
  Cheque_Blue_Checkers_Window:"Blå rutig design.",
  Cashiers_Check:"Kassacheck",
  Cashiers_Check_Window:"Kassacheck stil mall.",
  Cheque_Aqua_Checkers:"Aqua Checkers",
  Cheque_Aqua_Checkers_Window:"Aqua check design.",
  Cheque_Golden_Checkers:"Gyllene dam",
  Cheque_Golden_Checkers_Window:"Golden check design.",
  Upgrade_Unavailable:"Uppgraderingar ej tillgängliga",
  Bank_Code_Protection:"Banknummerskydd",
  Bank_Code_Protection_Word:"Skydda dina banknummer från att användas på den här appen som körs på en annan enhet eller för en annan användare. Den här åtgärden är IRREVERSIBEL. Fortsätta?",
  Bank_Code_Protection_Blocked_Word:"Banknumren du försöker använda är reserverade för en annan användare eller enhet.",
  Bank_Code_Protection_Error_Word:"Nummerverifiering misslyckades. Anslut till internet och försök lägga till detta bankkonto igen.",
  Bank_Code_Protection_Set_Error_Word:"Banknummerskydd kräver att du är ansluten till internet. Anslut och försök igen.",
  Upgrade_Unavailable_Word:"Tyvärr, vi har problem med att verifiera dig. Prenumerationer och uppgraderingar till Checks är för närvarande inte tillgängliga att köpa i ditt område.",
  PayPal_Payment_Preview:"PayPal-betalningsförhandsgranskning",
  Apple_Pay:"Apple Pay",
  Select_PayPal:"Välj PayPal",
  PayPal_Applications:"PayPal-applikationer",
  Purchase_With_Apple_Pay:"Köp med Apple Pay",
  Google_Pay:"Google Pay",
  Companies:"Företag",
  Insurance:"Försäkring",
  New_PayPal:"Ny PayPal",
  Pay_By:"Betala med",
  Insure:"Försäkra",
  Miles:"Miles",
  Payment_Method:"Betalningsmetod",
  Select_Policies:"Välj Policyer",
  Policies:"Policyer",
  Policy:"Politik",
  No_PayPal_Account:"Inget PayPal-konto",
  No_Policies:"Inga försäkringar",
  New_Policy:"Ny policy",
  PayPal_Payment:"PayPal-betalning",
  PayPal_Payments:"PayPal-betalningar",
  No_Payment_Selected:"Ingen betalning har valts",
  Sending_Payment_Word:"Vänta... Denna betalning skickas.",
  Sending_Payments_Word:"Vänta... Betalningar skickas.",
  No_Payment_Selected_PayPal:"Ingen <a routerLink='/folder/Payments'>PayPal-betalning</a> har valts att skicka.",
  Payment_Sent:"Betalning skickad",
  PayPal_Payment_Sent:"Denna betalning har skickats med PayPal.",
  Copay:"Copay",
  Dead:"Död",
  Alive:"Levande",
  Not_Dead:"Inte död",
  Unclaimed:"Ohämtat",
  Attempted:"Försökte",
  Deceased:"Den avlidne",
  Claimed:"Påstod",
  Unpaid:"Obetald",
  Sending_Payment:"Skickar betalning",
  Sending_Payments:"Skickar betalningar",
  Send_PayPal_Confirmation:"Vill du skicka denna transaktion med PayPal?",
  Send_PayPal_Confirmation_Word:"Tryck på den gröna knappen för att skicka denna transaktion.",
  Save_Payment_As_Unpaid:"Spara den här betalningen som obetald?",
  Payment_Pay_Confirmation_PayPal:"Spara denna betalning som betald?",
  No_Policies_Word:"Lägg till den första <a routerLink='/folder/Postage/New'>försäkringspolicyn</a> nu.",
  Timesheet_Multiple_Delete_Confirmation:"Är du säker på att du vill ta bort flera tidrapporter?",
  Select_Multiple_Timesheets_Prompt:"Inga tidrapporter har valts. Välj minst en tidrapport.",
  Select_Multiple_Policies_Prompt:"Inga policyer har valts. Välj minst en försäkring.",
  Policies_Multiple_Delete_Confirmation:"Är du säker på att du vill ta bort flera policyer?",
  Company:"Företag",
  Add_Company:"Lägg till företag",
  New_Company:"Lägg till företag",
  No_Companies:"Inga företag",
  Enable_Company:"Aktivera företag",
  Select_Company:"Välj Företag",
  The_Default_Company:"Standardföretagets etikett.",
  Manage_Companies:"Hantera företag",
  No_Companies_Word:"Checkar kommer att använda ett standardföretag.<br />Lägg till <a routerLink='/folder/Company/New'>första företaget</a>.",
  Default_Company:"Standardföretag",
  Cheques_Unlimited_Word:"Med Checks Unlimited kan du skriva ut så många checkar du vill.",
  Cheques_Subscription_Word:"Ett Checks-abonnemang låter dig skriva ut så många checkar du vill.",
  You_Own_This_Product:"Du äger denna produkt.",
  Your_Subscription_is_Active:"Ditt abonnemang är aktivt.",
  Active_Products:"Aktiverade produkter",
  Purchase_Confirmation:"Inköp",
  Purchase_Cheques:"Inköpscheckar",
  Restore_Purchase:"Återställa köp",
  Erase_Purchase:"Radera köp",
  Successfully_Purchased:"Inköpt framgångsrikt",
  Enter_Your_Licence_Key:"Vänligen ange din licensnyckel på den här sidan för att aktivera den här produkten.",
  Licence_Key:"Licensnyckel",
  Enter_Licence_Key:"Ange licensnyckel",
  Purchased:"Köpt",
  Enable_Feature:"Aktivera funktion",
  Cancel_Subscription:"Avbryt prenumeration",
  Subscription_Removed:"Abonnemanget har tagits bort",
  Select_Active_Subscription:"Välj en aktiv prenumeration för att ändra den.",
  Remove_Subscription_Word:"Är du säker på att du vill avsluta den här prenumerationen?",
  Delete_Company_Confirmation:"Är du säker på att du vill ta bort hela företaget? VARNING: Du kommer att förlora all lagrad information!",
  Delete_Default_Company_Word:"Du kan inte ta bort standardföretaget. Vill du återställa programmet och återställa det till standardläget? VARNING: Du kommer att förlora all lagrad information!",
  Console_Warning_2:"Hantera inte någon valuta med denna applikation som för närvarande inte är din.",
  Terms_and_Conditions:"Villkor",
  and_the:"och den",
  for:"för",
  Please_Read_Word:"Vänligen läs och godkänn",
  Some_Conversion_Rates_Could_Not_Be_Retrieved:"Vissa valutaomräkningskurser kunde inte hittas. Anslut till internet.",
  Free:"Fri",
  DB_Erase_Prompt_2:"Vill du radera hela utvecklardatabasen helt? VARNING: Du kommer att förlora all information om KÖP och PRENUMERATION!",
  Successfully_Imported:"Lyckades importerad",
  Select_Postage:"Välj Porto",
  No_Postage:"Inga frimärken",
  No_Paid_Postage_Word:"Lägg till den första <a routerLink='/folder/Porto/New'>Betalt Porto</a>-stämpel.",
  Trial_Complete:"Testversionen slutförd",
  Please_Upgrade:"Uppgradera Checks för att fortsätta skriva ut.",
  Aa:"Aa",
  Color:"Färg",
  Font:"Font",
  Guide:"Guide",
  Guides:"Guider",
  Image:"Bild",
  Zoom:"Zoom",
  Logo:"Logotyp",
  Bank:"Bank",
  MICR:"MICR",
  Total:"Total",
  Cancel:"Annullera",
  Confirm:"Bekräfta",
  Method:"Metod",
  Biometric_Security:"Biometrisk säkerhet",
  Please_Login_To_Continue:"Var vänlig logga in för att fortsätta.",
  Complete:"Komplett",
  Sign_Up:"Bli Medlem",
  Error:"Fel",
  Biometrics:"Biometri",
  Percent:"Procent",
  Zero_Percent:"0 %",
  Top_Margin:"Toppmarginal",
  Bottom_Margin:"Nedre marginal",
  Left_Margin:"Vänster marginal",
  Right_Margin:"Höger marginal",
  MICR_Margin:"MICR-marginal",
  Table_Margin:"Tabellmarginal",
  Address_Margin:"Adressmarginal",
  Percentage_:"Procentsats",
  Vacation_Title:"Semestertitel",
  Use_PIN:"Använd PIN",
  Loading__:"Läser in...",
  Design_Title:"Design titel",
  Authorize:"Godkänna",
  Key:"Nyckel",
  Public_Key:"Offentlig nyckel",
  Private_Key:"Privat nyckel",
  Authenticate:"Autentisera",
  Last_Payroll:"Sista lönelistan",
  Last_Calculation:"Senaste beräkningen",
  Authorized:"Auktoriserad",
  Geo_Authorized:"Geo-plats: Auktoriserad",
  Not_Authorized:"Inte behörig",
  Authorization_Complete:"Auktorisering slutförd",
  Geolocation_Authorization:"Geo-lokaliseringsbehörighet",
  Out_of_Bounds:"Utanför banan",
  Cant_Delete_Default_Design:"Kan inte ta bort en standarddesign.",
  Unauthorized_Out_of_Bounds:"Obehörig: Out of Bounds",
  Biometric_Authorization:"Biometrisk auktorisering",
  Locating_Please_Wait:"Hittar, vänligen vänta...",
  Test_Biometrics:"Testa biometri",
  Cheque_Margins:"Kontrollera Marginaler",
  Percentage_Full_Error:"Procentfältet kan inte ställas in över 100 % procent.",
  No_Payroll_Text:"Lägg till en <a routerLink='/folder/Employee/New'>Anställd</a> eller <a routerLink='/folder/Payee/New'>Betalningsmottagare</a> och en <a routerLink='/folder/Schedule/New'>Schema</a>.",
  Design_Saved:"Designen har sparats",
  Default_Design_Selected:"Standarddesign vald",
  Partial_Import:"Partiell import",
  Partial_Export:"Partiell export",
  Entire_Import:"Hela importen",
  Entire_Export:"Hela exporten",
  Existing_Password:"Vänligen ange ditt befintliga lösenord:",
  Existing_PIN:"Ange din befintliga PIN-kod:",
  Pin_Change_Header:"PIN-bekräftelse",
  Pin_Change_SubHeader:"Ange din gamla PIN-kod för att bekräfta ändringen.",
  Pass_Change_Header:"Lösenordsbekräftelse",
  Pass_Change_SubHeader:"Ange ditt gamla lösenord för att bekräfta ändringen.",
  PIN_Enter_Message:"Ange din PIN-kod för att bekräfta.",
  Password_Enter_Message:"Ange ditt lösenord för att bekräfta.",
  Pin_Updated_Success:"PIN-koden har uppdaterats!",
  Pin_Updated_Fail:"PIN-koden kunde inte uppdateras.",
  Pass_Updated_Success:"Lösenordet har uppdaterats.",
  Pass_Updated_Fail:"Lösenordet kunde inte uppdateras.",
  Preview_Payment:"Förhandsgranska betalning",
  Preview_Payroll:"Förhandsgranska Lön",
  No_Payments_Created:"Inga betalningar kunde skapas.",
  Payment_Preview:"Förhandsgranskning av betalning",
  Enable_Payee:"Aktivera betalningsmottagare",
  Rendered:"Rensat",
  No_Email:"Ingen e-post",
  Setup_Cheques:"Inställningskontroller",
  name:"namn",
  address:"Adress",
  address_2:"Adress Linje 2",
  city:"Stad",
  province:"Provins",
  postal_code:"Postnummer",
  country:"Land",
  username:"Användarnamn",
  full_name:"Fullständiga namn",
  birthday:"Födelsedag",
  email:"E-post",
  phone:"Telefon",
  employees:"Anställda",
  addresses:"Adresser",
  payment_amount_limit:"Gräns för betalningsbelopp",
  total_limit:"Total gräns",
  payees:"Betalningsmottagare",
  description:"Beskrivning",
  address_line_one:"Adressrad ett",
  address_line_two:"Adressrad två",
  image:"Bild",
  account_holder:"Kontoinnehavare",
  cheque_starting_id:"Kontrollera Start-ID",
  transit_number:"Transitnummer",
  institution_number:"Institutionsnummer",
  designation_number:"Beteckningsnummer",
  account_number:"Kontonummer",
  currency:"Valuta",
  signature:"Signatur",
  payment_payroll_limit:"Betalningsgräns",
  total_limi:"Totalgräns",
  date:"Datum",
  printed_memo:"Tryckt memo",
  banks:"Banker",
  signature_image:"Signaturbild",
  address_name:"Adressnamn",
  notes:"Anteckningar",
  design:"Design",
  title:"Titel",
  frequency:"Frekvens",
  fax:"Fax",
  salaries:"Löner",
  salary_ids:"Löne-ID",
  start_time:"Starttid",
  end_time:"Sluttid",
  paid:"Betalt",
  overtime_percentage:"Betald procent",
  overtime_amount:"Betalt fast belopp",
  first_name:"Förnamn",
  last_name:"Efternamn",
  moderation:"Måtta",
  create:"Skapa",
  edit:"Redigera",
  destroy:"Förstöra",
  day_start_time:"Day_start_time",
  day_end_time:"Dag_sluttid",
  fullname:"namn",
  time:"Tid",
  auto_send:"Skicka automatiskt",
  time_method:"Tidsmetod",
  schedules:"Scheman",
  indefinite_payroll_enabled:"Aktivera på obestämd tid",
  amount:"Belopp",
  repeat:"Upprepa",
  always_enabled:"Alltid aktiverad",
  start_date:"Start datum",
  end_date:"Slutdatum",
  Cheque_Total:"Kontrollera Totalt",
  Total_Amount:"Totala summan:",
  Amounts:"Belopp:",
  Images:"Bilder",
  Files:"Filer",
  Previewing:"Förhandsvisning:",
  Insert:"Föra in",
  Preview_Import:"Förhandsgranska import",
  Entry:"Inträde",
  Entries:"Inlägg",
  No_Entries:"Inga inlägg",
  Import_Type:"Importtyp",
  Select_Details:"Välj Detaljer",
  Select_Payee:"Välj Betalningsmottagare",
  Enable_Holidays:"Aktivera helgdagar",
  Disable_Holidays:"Inaktivera helgdagar",
  Wire_Transfer:"Elektronisk överföring",
  New_Export:"Ny export",
  Export_Data:"Exportera data",
  Export_Data_Word:"Välj vilken typ av fil som ska exporteras och laddas ner.",
  Export_File:"Exportera fil",
  Mode:"Läge",
  Times:"Tider",
  Widgets:"Widgets",
  Slider:"Skjutreglage",
  Set_Details:"Ställ in detaljer",
  Select_Type:"Välj Typ",
  Display_Slider:"Displayreglage",
  Dashboard_Slider:"Reglage för instrumentbräda",
  Dashboard_Mode:"Dashboard-läge",
  Show_Intro:"Visa intro",
  Show_Payrolls:"Visa lönelistor",
  Show_Holidays:"Visa helgdagar",
  Show_Invoices:"Visa fakturor",
  Show_Cheques:"Visa checkar",
  Enabled_Widgets:"Aktiverade widgets",
  Disabled_Widgets:"Inaktiverade widgetar",
  Colors:"Färger",
  Barcodes:"Streckkoder",
  View_Timers:"Visa timer",
  Gradients:"Gradienter",
  No_Info:"Ingen info",
  Disable:"Inaktivera",
  Show_Layer:"Visa lager",
  Hide_Layer:"Dölj lager",
  Text_Layer:"Textlager",
  Secondly:"För det andra",
  Minutely:"Minutiöst",
  nine_am:"09:00",
  five_pm:"17:00",
  Enable_Address:"Aktivera adress",
  Invalid_File_Type:"Tyvärr, en ogiltig filtyp har valts. Filtyp som stöds:",
  Error_Updating_Entry:"Det uppstod ett fel när denna post skulle uppdateras.",
  Schedule_Timing_Alert:"Fel: Schemastarttiden är inställd på ett värde efter sluttiden.",
  Select_Multiple_Payments_Prompt:"Inga betalningar har valts. Välj minst en betalning.",
  Select_Multiple_Cheques_Prompt:"Ingen bock har valts. Välj minst en check.",
  Select_Multiple_Items_Prompt:"Inga objekt har valts. Välj minst ett objekt.",
  Paymemt_Multiple_Delete_Confirmation:"Är du säker på att du vill ta bort flera betalningar?",
  Cheque_Multiple_Delete_Confirmation:"Är du säker på att du vill ta bort flera checkar?",
  Payee_Multiple_Delete_Confirmation:"Är du säker på att du vill ta bort flera betalningsmottagare?",
  Employee_Multiple_Delete_Confirmation:"Är du säker på att du vill ta bort flera anställda?",
  MICR_Warning:"Obs! Vissa skrivare och enheter kanske inte visar MICR-teckensnittet korrekt.",
  Automatically_Send:"Skicka automatiskt",
  Type:"Typ",
  Payment_Type:"Betalnings typ",
  Auto_Send:"Skicka automatiskt",
  Automatically_Process:"Bearbeta automatiskt",
  Auto_Process:"Automatisk process",
  Image_Based:"Bildbaserad",
  Font_Based:"Typsnittsbaserad",
  Rerender:"Återrendera",
  Rendering:"Tolkning",
  Render:"Filer",
  Top:"Topp",
  Middle:"Mitten",
  Bottom:"Botten",
  Top_Left:"Övre vänstra",
  Top_Center:"Top Center",
  Top_Right:"Överst till höger",
  Middle_Left:"Mitten till vänster",
  Middle_Center:"Mitten Center",
  Middle_Right:"Mitten höger",
  Bottom_Left:"Nedre vänstra",
  Bottom_Center:"Nedre mitten",
  Bottom_Right:"Nere till höger",
  Numbers:"Tal",
  Verified:"Verifierad",
  Paper_Size:"Pappersformat",
  New_Device:"Ny enhet",
  Add_Device:"Lägg till enhet",
  Remove_Device:"Ta bort enhet",
  Delete_Device:"Ta bort enhet",
  Block_Device:"Blockera enhet",
  Block:"Blockera",
  Unblock:"Avblockera",
  Table:"Tabell",
  Scan_Login_Code:"Skanna inloggningskod",
  Login_Code:"Inloggningskod",
  Scan_Code:"Skanna kod",
  Scan_QR_Code:"Skanna QR kod",
  Select_All:"Välj alla",
  Deselect_All:"Avmarkera alla",
  Increase:"Öka",
  Decrease:"Minska",
  Bold:"Djärv",
  Text:"Text",
  Style:"Stil",
  Italic:"Kursiv",
  QR_Code:"QR-kod",
  Barcode:"Streckkod",
  Browse_Images:"Bläddra bland bilder",
  Browse_Files:"Bläddra bland filer",
  Background_Image:"Bakgrundsbild",
  Logo_Image:"Logo bild",
  Header_Image:"Header Bild",
  Bank_Image:"Bankbild",
  Cut_Lines:"Klipp linjer",
  Background:"Bakgrund",
  License:"Licens",
  One_License:"1 licens:",
  Licensed:"Licensierad till:",
  Not_Licensed:"Ej licensierad",
  Enter_Serial:"Ange seriell",
  Serial_Key:"Seriell nyckel",
  Serial:"Serie",
  Product_Key:"Produktnyckel",
  Check_Product_Key:"Verifiera produktnyckel",
  Add_Product_Key:"Ange produktnyckel",
  Verifying_Purchase:"Verifierar köp...",
  Print_Envelope:"Skriv ut kuvert",
  Envelope:"Kuvert",
  Thank_You:"Tack!",
  Scale:"Skala",
  Print_Scale:"Skriv ut skala",
  Borders:"Gränser",
  VOID:"TOMHET",
  Void_Cheque:"Ogiltig check",
  CHEQUE_PRINT_PAYTOTHEORDEROF:"BETALA PÅ BESTÄLLNING AV:",
  NO_DOLLARS:"INGA DOLLAR",
  ONE_DOLLAR:"EN DOLLAR",
  DOLLARS:"DOLLAR",
  AND:"OCH",
  CENTS:" CENT.",
  NO_:"NEJ",
  ONE_:"ETT",
  AND_NO_:"OCH NEJ",
  _AND_ONE_:"OCH EN",
  DOLLARS_AND_ONE_CENT:"OCH ETT CENT.",
  AND_NO_CENTS:" OCH NOLL CENT.",
  CHEQUE_PRINT_DATE:"DATUM:",
  CHEQUE_PRINT_MEMO:"PM:",
  CHEQUE_PRINT_SIGNATUREMP:"MP",
  Initial_Tasks:"Inledande uppgifter",
  Inital_Setup:"Första installationen",
  Welcome_To:"Välkommen till",
  Welcome:"Välkommen",
  Swipe:"Hårt slag",
  Intro_Setup:"Introkonfiguration",
  Introduction:"Introduktion",
  CHEQUE_PRINT_CREDIT:"Drivs av checkar",
  Title_Intro_Word:"Välkommen till Checks",
  Title_Intro:"Kontroller Introduktion",
  Title_Setup:"Kontrollerar Setup",
  PayPal_Default_Email_Message:"Du har fått en ny PayPal-överföring.",
  Cheques_App_Export:"Exporteras med checkar",
  No_Prints_Purchased:"Inga utskrifter köpta",
  No_Prints_Purchased_Word:"Inga ytterligare utskrifter är tillgängliga. Vänligen köp ytterligare checkar.",
  Post_Intro_Thanks:"Tack för att du valde Checkar. Installationsprocessen är nu klar.",
  Post_Intro_Word:"Kom igång genom att skriva ut din första check, lägg till en framtida betalning eller lägg till en anställd i lönelistan.",
  Upgrade_Required:"Checks kräver att du äger en mer premiumversion av programvaran för att dölja detta meddelande och låsa upp ytterligare funktioner.",
  Upgrade_Title:"Checkar",
  Mailout_Prompt:"Du kan dessutom välja att låta Checks skicka ut dina lönecheckar åt dig.",
  Mailed_Cheque:"Postad check",
  Mailed_Cheque_Color:"Postad check (färg)",
  Terms_Purchase:"Alla elektroniska köp med checkar är fullt återbetalningsbara i upp till 30 dagar från inköpsdatum. Vänligen läs och godkänn",
  Dashboard_Setup:"Installera den primära skrivaren",
  Dashboard_Add:"Lägg till det primära bankkontot",
  Dashboard_Customize:"Välj en kontrollmall",
  Dashboard_Job_Salary:"Skapa ditt jobb och lägg till din lön",
  Dashboard_Employees:"Spåra anställda och betalningsmottagare",
  Dashboard_Print:"Skriv ut och posta dina lönecheckar",
  Dashboard_Payroll:"Automatisera din löneutskrift",
  Dashboard_PayPal:"Ställ in PayPal Payroll/Utbetalningar",
  Begin_Setup:"Börja installationen",
  Get_Started:"Komma igång",
  Purchase:"Inköp",
  Lockdown:"Nedstängning",
  Unlock:"Låsa upp",
  Detailed:"Detaljerad",
  Log_In:"Logga in",
  Login:"Logga in",
  Launch:"Lansera",
  Register:"Registrera",
  Finish:"Avsluta",
  List:"Lista",
  Weekends:"Helger",
  Weekly:"Varje vecka",
  PayPal_Default_Subject:"Ny betalning",
  Payment_Message:"Betalningsmeddelande",
  PayPal_Default_Payment_Note:"Tack",
  Setup_Your_Cheqing_Account:"Checkkonto",
  Add_Your_Primary_Cheqing_Account:"Lägg till ditt primära checkkonto.",
  Welcome_Word:"Förenkla och automatisera löne- och personalhantering.",
  Get_Started_Quickly:"Svara bara på några enkla frågor som hjälper oss att komma igång...",
  Done_Intro_Word:"Installationen är klar",
  PIN_Protected:"Lösenord & PIN-skyddad",
  Enter_New_PIN:"Ange en ny PIN-kod:",
  Enter_PIN:"Ange PIN-kod:",
  Invalid_PIN:"Ogiltig PIN-kod har angetts.",
  Account_Identifier:"Kontoidentifierare",
  New_Account_Identifier:"Ny kontoidentifierare",
  attempt:"försök",
  attempts:"Försök",
  remaining:"återstående",
  Language:"Språk",
  languages:"språk",
  select_languages:"Välj språk",
  Deposit:"Deposition",
  Hire_One_Now:"Hyr en nu",
  App_Locked:"Applikationen är låst.",
  Skip_:"Hoppa",
  Skip_to_Dashboard:"Hoppa till instrumentpanelen",
  Dashboard:"instrumentbräda",
  Import:"Importera",
  Admin:"Administratörer",
  New_Admin:"Ny admin",
  Settings:"inställningar",
  Color_Picker:"Färgväljare",
  Font_Picker:"Teckensnittsväljare",
  Logout:"Logga ut",
  Close:"Stänga",
  Close_menu:"Stänga",
  Excel:"Excel fil",
  Csv:"CSV-fil",
  Sql:"SQL-fil",
  Json:"JSON-fil",
  Url:"Importera via URL",
  Back:"Tillbaka",
  Timers:"Timers",
  Cheque:"Kolla upp",
  Print:"Skriva ut",
  Designs:"Mönster",
  Pause_Printing:"Pausa utskrift",
  Resume_Printing:"Återuppta utskrift",
  Printing_Paused:"Utskrift pausad",
  PrintingUnavailable:"Förlåt! Utskrift är inte tillgängligt på det här systemet.",
  Prints_Paused:"Utskrifter pausade",
  Administration:"Administrering",
  Loading:"Läser in",
  Unnamed:"Namnlös",
  error:"Den här checken kunde tyvärr inte öppnas för visning.",
  No_Cheques_To_Print:"Inga checkar att skriva ut",
  No_Cheques_To_Print_Word:"Skapa en <a routerLink='/folder/Cheque/New'>Ny Kolla upp</a>.",
  New_Cheque:"Ny check",
  Start_One_Now:"Starta en nu",
  Edit_Cheque:"Redigera kontroll",
  Select_Cheques:"Välj Checkar",
  Select_Employee:"Välj Anställd",
  Default_Printer:"Standardskrivare",
  Reassign:"Tilldela om",
  Configure:"Konfigurera",
  Template:"Mall",
  Designer:"Designer",
  Edit_Designs:"Redigera mönster",
  New_Design:"Ny design",
  Next:"Nästa",
  Save:"Spara",
  Name:"namn",
  Mail:"Post",
  Amount:"Belopp",
  Date:"Datum",
  PayPal:"PayPal",
  Payouts:"Utbetalningar",
  PayPal_Label:"PayPal-etikett",
  Email_Username:"E-post/användarnamn",
  Client_ID:"Klient ID",
  Sandbox_Email:"Sandbox e-post",
  PayPal_Email:"PayPal e-post",
  PayPal_Username:"API-användarnamn",
  PayPal_Payouts:"PayPal-utbetalningar",
  Select_PayPal_Key:"Välj PayPal-nyckel",
  Secret:"Hemlighet",
  API_Secret:"API-hemlighet",
  PayPal_API_Keys:"PayPal-nycklar",
  New_PayPal_Key:"Ny PayPal-nyckel",
  Email_Subject:"E-postämne",
  Email_Message:"E-postmeddelande",
  Payout_Options:"Utbetalningsalternativ",
  Payment_Note:"Betalningsanmärkning",
  Enable_Employee:"Aktivera anställd",
  Enable_Production_Mode:"Aktivera produktionsläge",
  Sandbox_Username:"Sandbox Användarnamn",
  Sandbox_Signature:"Sandlåda signatur",
  Sandbox_Password:"Lösenord för sandlåda",
  Production_Username:"Produktions användarnamn",
  Production_Signature:"Produktionssignatur",
  Production_Password:"Produktionslösenord",
  Production_Email:"Produktions e-post",
  API_Client_ID:"API-klient-ID",
  API_Signature:"API-signatur",
  API_Password:"API-lösenord",
  API_Username:"API-användarnamn",
  Secret_Key:"Hemlig nyckel",
  Sandbox:"Sandlåda",
  Production:"Produktion",
  Sandbox_Keys:"Sandlåda nycklar",
  Production_Keys:"Produktionsnycklar",
  API_Title:"API-titel",
  Production_Mode:"Produktionsläge",
  Account_Label:"Kontoetikett",
  No_PayPal_Setup:"Ingen PayPal-nyckel",
  Enable_PayPal_Account:"Aktivera PayPal-konto",
  No_PayPal_Word:"Lägg till din <a routerLink='/folder/Invoice/New'>PayPal API-nyckel</a>.",
  Printed_Memo:"Tryckt memo",
  Employee:"Anställd",
  View_Employee:"Visa anställd",
  Mailing_Address:"Postadress",
  Company_Address:"Företags Adress",
  Select_Company_Address:"Välj Företagsadress",
  Address:"Adress",
  Any_Day:"Vilken dag som helst",
  Address_Name:"Adressnamn",
  Unit:"Enhet",
  Account:"konto",
  Bank_Account:"Bankkonto",
  Account_Limits:"Aktivera kontogränser",
  Payroll:"Löner",
  Run:"Springa",
  Run_Payroll:"Kör Lön",
  New_Payroll:"Ny lönelista",
  No_Payroll:"Ingen kommande löneadministration",
  Upcoming_Holiday:"Kommande helgdag:",
  Invoice_Due:"Faktura förfaller:",
  New_Invoice:"Ny faktura",
  No_Invoices:"Inga fakturor",
  No_Invoices_Word:"Skapa den första <a routerLink='/folder/Invoice/New'>Fakturan</a>.",
  Cheque_Due:"Check förfallo:",
  Payrolls:"Lönelistor",
  Sandbox_Mode:"sandlådeläge",
  Hire:"Hyra",
  Pay:"Betala",
  New:"Ny",
  Add:"Lägg till",
  Make:"Göra",
  Time:"Tid",
  Write:"Skriva",
  Holiday:"Semester",
  Holidays:"Högtider",
  Next_Holiday:"Nästa helgdag:",
  All_Done:"Helt klar!",
  Employees:"Anställda",
  Payees:"Betalningsmottagare",
  Job:"Jobb",
  Jobs:"Jobb",
  Invoice:"Faktura",
  Invoices:"Fakturor",
  Vacations:"Semester",
  Cheques:"Checkar",
  Brand_Cheques:"Ditt varumärke",
  Payment:"Betalning",
  Enable_Payment:"Aktivera betalning",
  Payments:"Betalningar",
  Schedule:"Schema",
  Schedules:"Scheman",
  Timesheet:"Tidrapport",
  Timesheets:"Tidrapporter",
  Salary:"Lön",
  New_Address:"Ny adress",
  Address_2:"Adress Linje 2)",
  _City:"Stad",
  _State:"Stat/Prov",
  City:"Stad / Township",
  State:"Stat/provins",
  Postal:"Postnummer",
  ZIP:"Post/Post",
  Country:"Land",
  Addresses:"Adresser",
  Required_Options:"Obligatoriska alternativ",
  Optional_Options:"Valfria alternativ",
  Additional_Options:"Ytterligare alternativ",
  Required:"Nödvändig",
  Optional:"Frivillig",
  Additional:"Ytterligare",
  No_Addresses:"Inga adresser",
  New_Address_Word:"Lägg till den första <a routerLink='/folder/Address/New'>Adressen</a>.",
  Select_Address:"Välj Adress",
  No_Address:"Inga adresser",
  Print_Cheque:"Skriv ut check",
  Print_Cheques:"Skriv ut checkar",
  Print_Cheque_Now:"Skriv ut Kontrollera nu",
  Description:"Beskrivning",
  Pay_To_The_Order_Of:"Betala till beställning av:",
  Select_Date_Range:"Välj Datumintervall",
  Select_Starting_Date:"Välj Startdatum",
  Select_Ending_Date:"Välj Slutdatum",
  Select_Date:"Välj Datum",
  Cheque_Date:"Kontrollera datum",
  Cheque_Memo:"Kontrollera Memo",
  Blank_Cheque:"Tom kontroll",
  Blank:"Tom",
  No_Cheques:"Inga kontroller",
  No_Cheques_Word:"Skriv ut din första <a routerLink='/folder/Cheque/New'>Kolla upp</a>.",
  Cheque_Amount_Placeholder:" 0,00 USD",
  View_Image:"Se bild",
  View:"Se",
  Modify:"Ändra",
  Delete:"Radera",
  Cheque_Paid:"Betalt",
  New_Deduction:"Nytt avdrag",
  Title:"Titel",
  Frequency:"Frekvens",
  Hourly:"Varje timme",
  Daily:"Dagligen",
  Weekdays:"Vardagar",
  BiWeekly:"2 veckor",
  TriWeekly:"3 veckor",
  Monthly:"En gång i månaden",
  MiMonthly:"2 månader",
  Quarterly:"Kvartals",
  Yearly:"Årlig",
  Every_Week:"Varje vecka",
  Every_Payroll:"Varje lönelista",
  Every_Month:"Varje månad",
  Annually:"Årligen",
  Always_Scheduled:"Alltid schemalagd",
  Start_Date:"Start datum",
  End_Date:"Slutdatum",
  Start_Time:"Starttid",
  End_Time:"Sluttid",
  Deduction_Label:"Avdragsetikett",
  Notes:"Anteckningar",
  Options:"alternativ",
  Enable:"Gör det möjligt",
  Select_Deductions:"Välj Avdrag",
  Deductions:"Avdrag",
  No_Deductions:"Inga avdrag",
  No_Deductions_Word:"Skapa ditt första <a routerLink='/folder/Deduction/New'>Avdrag</a>.",
  New_Employee:"Ny anställd",
  No_Title:"Ingen titel",
  _Name:"namn",
  About_Yourself:"Om dig själv",
  Full_Name:"Fullständiga namn",
  Birthday:"Födelsedag",
  Email:"E-post",
  SMS:"SMS",
  Phone:"Telefon",
  Test:"Testa",
  Call:"Ring upp",
  Fax:"Fax",
  Printed_Note:"Tryckt anteckning",
  Position:"Placera",
  Job_Position:"Befattning",
  Select_a_Job:"Välj ett jobb",
  Select_a_Salary:"Välj en lön",
  Add_a_Deduction:"Lägg till ett avdrag",
  Taxes:"Skatter",
  Add_Taxes:"Lägg till skatter",
  Date_of_Birth:"Födelsedatum",
  Email_Address:"E-postadress",
  Phone_Number:"Telefonnummer",
  Phone_Call:"Telefonsamtal",
  Enable_on_Payroll:"Aktivera på lönelistan",
  Select_Employees:"Välj Anställda",
  No_Employees:"Inga anställda",
  No_Employees_Word:"Lägg till din första nya <a routerLink='/folder/Employee/New'>Anställd</a>.",
  No_Name:"Inget namn",
  Unemployeed:"Arbetslös",
  Employeed:"Anställd",
  Paid:"Betalt",
  Enabled:"Aktiverad",
  Disabled:"Inaktiverad",
  Fire:"Brand",
  Not_Available:"Inte tillgänglig",
  Not_Available_Word:"Skriv ut din första <a routerLink='/folder/Invoice/New'>Faktura</a> och få betalt.",
  Select_Invoices:"Välj Fakturor",
  Print_Invoice_Word:"Skriv ut din första <a routerLink='/folder/Invoice/New'>Faktura</a> och få betalt.",
  Invoice_Title:"Fakturatitel",
  Invoice_Date:"Fakturadatum",
  Due_Date:"Förfallodatum",
  New_Job:"Nya jobb",
  Details:"Detaljer",
  Customize:"Anpassa",
  Customize_Dashboard:"Anpassa instrumentpanelen",
  Components:"Komponenter",
  No_Components:"Inga komponenter",
  Main_Components:"Huvudkomponenter",
  Smaller_Components:"Mindre komponenter",
  Error_Loading_Page:"Det gick inte att läsa in den här sidan.",
  Bank_Details:"Bankuppgifter",
  About_Your_Job:"Om ditt jobb",
  Your_Schedule:"Ditt schema",
  Job_Title:"Jobbtitel",
  Job_Description:"Arbetsbeskrivning",
  Job_Details:"Jobb detaljer",
  Enable_Job:"Aktivera jobb",
  Pay_Period:"Betalningsperiod",
  Perpetually_Schedule:"Ständigt schema",
  Select_Jobs:"Välj Jobb",
  No_Jobs:"Inga jobb",
  Add_Jobs:"Lägg till jobb",
  No_Jobs_Word:"Lägg till det första <a routerLink='/folder/Job/New'>Jobbet</a> till listan.",
  Count_Employees:"job.employee_count+' Anställda'",
  Zero_Employees:"0 anställda",
  New_Leave:"Ny ledighet",
  Leave_Name:"Lämna namn",
  Paid_Leave:"Betald ledighet",
  Leave_Pay:"Lämna Pay",
  Indefinite_Leave:"Ledighet på obestämd tid",
  Indefinite_Payroll:"Tillsvidarelön",
  Leave:"Lämna",
  Add_Schedules:"Lägg till scheman",
  Percentage:"100 %",
  Enable_Leave_Of_Absence:"Aktivera frånvaro",
  Select_Leaves:"Välj Löv",
  No_Leaves:"Inga frånvaro",
  Leave_Of_Absence:"Tjänstledighet",
  Leaves_Of_Absence:"Frånvaroblad",
  New_Leave_of_Absense:"Ny tjänstledighet",
  No_Leaves_Word:"Lägg till den första <a routerLink='/folder/Leave/New'>Frånvaro</a>.",
  Not_Enabled:"Inte aktiverad",
  Absences:"Frånvaro",
  Payee:"Betalningsmottagare",
  New_Payee:"Ny betalningsmottagare",
  Payee_Identifier:"Identifierare för betalningsmottagare",
  Payee_Name:"Betalningsmottagarens namn",
  Payee_Title:"Betalningsmottagarens titel",
  Payment_Memo:"Betalningsmemo",
  Select_Payees:"Välj Betalningsmottagare",
  No_Payees:"Inga betalningsmottagare",
  Add_Payee_Note:"Lägg till den första <a routerLink='/folder/Payee/New'>Betalningsmottagaren</a>.",
  New_Payees:"Nya betalningsmottagare",
  About_The_Payment_Schedule:"Betalningsplan",
  Your_Payroll_Schedule:"Automatisk lönehantering",
  New_Payment:"Ny betalning",
  Identifier:"Identifierare",
  Selected:"Vald",
  Select:"Välj",
  Memo:"PM",
  Payment_Date:"Betalningsdag",
  Mark_as_Paid:"Markera som betald",
  Select_Payments:"Välj Betalningar",
  No_Payments:"Inga betalningar",
  No_Payments_Word:"Skapa den första <a routerLink='/folder/Payment/New'>Betalningen</a>.",
  Create_Payroll:"Skapa Lön",
  Properties:"Egenskaper",
  Payroll_Title:"Lönebeteckning",
  Payroll_Notes:"Löneanteckningar",
  Payroll_Setup:"Löninställning",
  Tabulate_Payments:"Tabellera betalningar",
  Tabulate:"Tabulera",
  By:"Förbi:",
  Payments_By:"Betalningar av",
  Timesheets_And_Schedules:"Tidrapporter och scheman",
  Both:"Både",
  Items:"Föremål",
  Add_Payees:"Lägg till betalningsmottagare",
  Add_Account:"Lägg till konto",
  Enable_Account:"Aktivera konto",
  Enable_Payroll:"Aktivera Lön",
  Print_Payroll:"Skriv ut lönelista",
  No_Payrolls:"Ingen lönelista",
  No_Payroll_Word:"Skapa din första <a routerLink='/folder/Payroll/New'>Lönelista</a>.",
  View_more:"VISA MER",
  Less:"MINDRE",
  Add_Payroll:"Lägg till Lön",
  Select_Payroll:"Välj Lön",
  About_Your_Salary:"Om din lön",
  Add_Salaries:"Lägg till löner",
  Add_Salary:"Lägg till lön",
  Salaries:"Löner",
  No_Salaries:"Inga löner",
  No_Salaries_Word:"Lägg till den första <a routerLink='/folder/Salary/New'>Lönen</a>.",
  Select_Salaries:"Välj Löner",
  New_Salary:"Ny lön",
  Salary_Name:"Löneidentifierare",
  Enable_Salary:"Aktivera Lön",
  Salary_Amount:"Lönebelopp",
  New_Schedule:"Nytt schema",
  Schedule_Title:"Schematitel",
  Add_Address:"Lägg till adress",
  Repeat:"Upprepa",
  Design:"Design",
  Edit_Design:"Redigera design",
  Edit_this_Design:"Redigera denna design",
  Repeat_Payment:"Upprepa betalning",
  Enable_Schedule:"Aktivera schema",
  Never:"Aldrig",
  Select_Schedule:"Välj Schema",
  No_Schedules:"Inga scheman",
  No_Schedules_Word:"Skapa det första <a routerLink='/folder/Schedule/New'>Schema</a>.",
  New_Administrator:"Ny administratör",
  Username:"Användarnamn",
  First_Name:"Förnamn",
  Last_Name:"Efternamn",
  Add_an_Address:"Lägg till en adress",
  Payment_Limit:"Per-betalningsgräns",
  Total_Limit:"Total gräns",
  Select_Accounts:"Välj Konton",
  No_Administrators:"Inga administratörer",
  No_Administrators_Word:"Skapa det första <a routerLink='/folder/Administrator/New'>Administratörskontot</a>.",
  New_Administrators_Word:"Lägg till den första <a routerLink='/folder/Administrator/New'>Administratören</a>",
  Cloud:"Moln",
  Backup:"Säkerhetskopiering",
  Enable_iCloud:"Aktivera iCloud",
  Enable_Google_Drive:"Aktivera Google Drive",
  Enable_Microsoft_OneDrive:"Aktivera Microsoft OneDrive",
  Automatically_Backup:"Automatisk säkerhetskopiering",
  FTP_Settings:"FTP-inställningar",
  URL_File_Prompt:"Ange platsen för en .xls/.xlsx/.json-fil som ska importeras:",
  URL_SQL_Prompt:"Vänligen ange platsen för SQLite-filen som ska importeras:",
  FTP_Backup:"FTP-säkerhetskopiering",
  FTP_Import:"FTP-import",
  FTP:"FTP",
  SFTP:"SFTP",
  Host:"Värd",
  Port:"Hamn",
  Path:"Väg",
  Data_Path:"Dataväg",
  Enable_FTP_Account:"Aktivera FTP-konto",
  HostnameIP:"Värdnamn",
  Password:"Lösenord",
  Connection_Port:"Anslutningsport",
  Enable_MySQL_Database:"Aktivera MySQL Database",
  Log:"Logga",
  Reset:"Återställa",
  Erase:"Radera",
  Export:"Exportera",
  Database:"Databas",
  Upload_CSV:"Ladda upp CSV",
  Download_CSV:"Ladda ner CSV",
  SQL_Database:"SQL URL",
  MySQL_Database:"MySQL",
  MySQL_Backup:"MySQL backup",
  Internal_Notes:"Interna anteckningar",
  Root_Path:"Rotväg",
  Select_Backup:"Välj Säkerhetskopiera",
  Add_New_Backup:"Lägg till en ny säkerhetskopia",
  First_Backup:"Konfigurera den första säkerhetskopian...",
  Backups:"Säkerhetskopieringar",
  Add_Backup:"Lägg till säkerhetskopia",
  No_Backups:"Det finns inga säkerhetskopior att hitta.",
  Select_Backup_Type:"Välj vilken typ av säkerhetskopia du vill ställa in...",
  Backup_Type:"Säkerhetskopieringstyp",
  FTP_Drive:"FTP-enhet",
  URL_Location:"URL",
  Apple_iCloud:"iCloud",
  Google_Drive:"Kör",
  Microsoft_OneDrive:"Kör",
  Import_Fields:"Importera fält",
  Import_Fields_Word:"Använd det här avsnittet för att välja <a routerLink='/folder/Holiday/New'>Importera</a> data.",
  Upload:"Ladda upp",
  Download:"Ladda ner",
  Download_JSON:"Ladda ner som JSON-data",
  Download_SQL:"Ladda ner som SQL-fil",
  New_Bank:"Ny bank",
  New_Account:"Nytt konto",
  New_Bank_Account:"Nytt bankkonto",
  Upload_Image:"Ladda upp bild",
  Bank_Name:"Bank namn",
  Bank_Address:"Bankadress",
  Branch_Address:"Filialens adress",
  Address_on_Cheque:"Adress",
  Cheque_Numbers:"Kontrollera siffror",
  Cheque_Details:"Kontrollera Detaljer",
  Bank_Logo:"Banklogotyp",
  Cheque_ID:"Kontrollera ID",
  Starting_Cheque_ID:"Startar Check ID",
  Transit_Number:"Transitnummer",
  Institution_Number:"Institutionsnummer",
  Designation_Number:"Beteckningsnummer",
  Account_Number:"Kontonummer",
  Limits:"Gränser",
  Default_Limits:"Standardgränser",
  Over_Limit:"Över gränsen",
  Under_Limit:"Under gräns",
  Payroll_Limit:"Lönegräns",
  Enable_Bank_Account:"Aktivera bankkonto",
  Select_Account:"Välj Konto",
  No_Bank_Account:"Inget bankkonto",
  No_Bank_Account_Word:"Lägg till det första <a routerLink='/folder/Accounts/New'>Bankkontot</a>.",
  Bank_Accounts:"Bankkonton",
  No_Accounts:"Inga konton",
  No_Accounts_Note:"Lägg till det första <a routerLink='/folder/Accounts/New'>Bankkontot</a>.",
  Cheque_Designer:"Kolla Designer",
  Cheque_Design:"Kontrollera design",
  Select_Design:"Välj en design",
  Cheque_Designs:"Kontrollera mönster",
  No_Cheque_Designs:"Inga checkdesigner",
  No_Cheque_Designs_Word:"Skapa din egen <a routerLink='/folder/Settings/Cheque/Design/New'>Kontrollera Design</a>.",
  Set_Default:"Ange som standard",
  Default:"Standard",
  Remove:"Avlägsna",
  Folder:"Mapp",
  Edit:"Redigera",
  LoadingDots:"Läser in...",
  Add_a_New_File:"Lägg till en <a href='#' (click)='add()'>Ny Fil</a> till",
  this_folder:"denna mapp",
  FTP_Backup_Settings:"Inställningar för FTP-säkerhetskopiering",
  Secure_File_Transfer:"Säker filöverföring",
  New_Holiday:"Ny helgdag",
  Add_Holiday:"Lägg till semester",
  Holiday_Name:"Helgdag Namn",
  Additional_Pay:"Ytterligare lön",
  Enable_Holiday:"Aktivera semester",
  Select_Holidays:"Välj Helgdagar",
  No_Holidays:"Inga helgdagar",
  No_Holidays_Word:"Lägg till den första <a routerLink='/folder/Holiday/New'>Allmänna helgdagar</a>.",
  New_Import:"Ny import",
  Import_Data:"Importera data",
  Import_Data_Word:"Välj den typ av fil eller uppladdningsmetod du väljer.<br /> Du kommer att kunna välja vilka importerade fält i en fil som motsvarar vilken parameter som helst i appen efter att ha laddat upp en fil som stöds.",
  Import_File:"Importera fil",
  Link_To_File:"Länk till fil",
  Select_File:"Välj fil",
  New_Moderator:"Ny moderator",
  Allow_Moderation:"Tillåt moderering",
  Create_Paycheques:"Skapa lönecheckar",
  Edit_Paycheques_and_Data:"Redigera lönecheckar och data",
  Destroy_Data_and_Paycheques:"Förstör data och lönecheckar",
  Bonus_Percentage:"Lönecheck i procent",
  Fixed_Amount:"Bestämd mängd",
  Select_Moderator:"Välj Moderator",
  No_Moderators:"Inga moderatorer",
  Moderators:"Moderatorer",
  Moderator_Account:"Skapa det första <a routerLink='/folder/Administrator/New'>Moderatorkontot</a>.",
  No_Moderators_Word:"Lägg till den första <a routerLink='/folder/Administrator/New'>Moderatorn</a>.",
  Defaults:"Standardvärden",
  Provide_Us_Info:"Ge information",
  Setup_Your_Printer:"Konfigurera din skrivare",
  Your_Company:"Om ditt företag",
  Company_Name:"Företagsnamn",
  Currency:"Valuta",
  Default_Currency:"Standardvaluta",
  Base_Monthly_Income:"Månadsinkomst",
  Protection:"Skydd",
  App_Protection:"Appskydd",
  PIN_Code_Protection:"PIN-kodsskydd",
  App_Protection_Word:"Aktivera säkerhetsmetoder som hjälper till att skydda ditt konto.",
  PIN_Code:"Pinkod",
  Change_PIN:"Ändra PIN-kod",
  New_Password:"nytt lösenord",
  Geofence_Protection:"Geo-fence skydd",
  Geofence_Area:"Ange område",
  Distance:"Distans",
  Setup_Now:"Konfigurera nu",
  Mile:"Mile",
  Km:"Km",
  m:"m",
  Facial_Recognition:"Ansiktsigenkänning",
  Face:"Ansikte",
  Setup:"Uppstart",
  Label:"Märka",
  Password_Protection:"Lösenordsskydd",
  Modify_Password:"Ändra lösenord",
  New_Tax_Entry:"Ny skattepost",
  New_Tax:"Ny skatt",
  Tax_Label:"Skattemärkning",
  Perpetually_Enabled:"Ständigt aktiverad",
  Select_Taxes:"Välj Skatter",
  Tax_Deductions:"Skatteavdrag",
  No_Tax_Deductions:"Inga skatteavdrag",
  No_Tax_Deductions_Word:"Lägg till det första <a routerLink='/folder/Tax/New'>Skatte</a>-avdraget.",
  New_Timer:"Ny timer",
  Start:"Start",
  Stop:"Sluta",
  Start_Timer:"Starta timer",
  Stop_Timer:"Stoppa timer",
  Timer_Active:"Timer aktiv",
  Timer:"Timer:",
  Timer_Running:"Timer: (kör)",
  Save_Timer:"Spara timer",
  New_Timesheet:"Ny tidrapport",
  Select_Timesheets:"Välj Tidrapporter",
  Work_Notes:"Arbetsanteckningar",
  Entry_Title:"Postens titel",
  No_Timesheets:"Inga tidrapporter",
  No_Timesheets_Word:"Lägg till den första <a routerLink='/folder/Timesheet/New'>Tidrapport</a>.",
  Timesheet_Submitted:"Tidrapport inskickad",
  Timesheet_Congrats:"Grattis! Din tidrapport har skickats in. Tack!",
  Timesheet_Copy:"För att få en kopia av dina dokument vänligen ge oss din e-postadress och/eller mobiltelefonnummer.",
  Submit:"Skicka in",
  Allow_Notifications:"Tillåt aviseringar",
  Untitled_Entry:"Ny ingång",
  Untitled_Bank:"Namnlös bank",
  Timesheet_Entry:"Tidrapportinmatning",
  Work_Description:"Arbets Beskrivning",
  Enable_Timesheet:"Aktivera tidrapport",
  Submit_Timesheet:"Skicka in tidsrapport",
  Vacation:"Semester",
  New_Vacation:"Ny semester",
  Vacation_Name:"Semesternamn",
  Paid_Vacation:"Betald semester",
  Vacation_Pay:"Semesterlön",
  Enable_Vacation:"Aktivera semester",
  Select_Vacations:"Välj Semester",
  No_Vacations:"Inga semester",
  No_Vacations_Word:"Skapa den första <a routerLink='/folder/Vacation/New'>Semester</a>-posten.",
  Payroll_Schedule:"Löneschema",
  Next_Payroll:"Nästa lönelista:",
  Upcoming_Payroll:"Kommande Lön",
  No_Upcoming_Payroll:"Ingen kommande löneadministration",
  Address_Book:"Adressbok",
  Archived_Documents:"Arkiverade dokument",
  Dev_Mode:"Applikation i utvecklingsläge",
  Administrators:"Administratörer",
  Privacy:"Integritet",
  None:"Ingen",
  Select_Signature:"Välj Signatur",
  No_Signatures:"Inga signaturer",
  No_Signatures_Word:"Lägg till den första <a routerLink='/folder/Signature/New'>Signaturen</a>.",
  Repeat_Indefinitely:"Upprepa på obestämd tid",
  Sign:"Skylt",
  Sign_Here:"Signera här",
  Date_Signed:"Daterad",
  Signature_Pad:"Signatur Pad",
  Account_Holder:"Kontoinnehavare",
  Account_Properties:"Kontoegenskaper",
  Name_On_Cheque:"Namn på check",
  Server_Hostname:"Server värdnamn / IP",
  Printers:"Skrivare",
  No_Printers:"Inga skrivare",
  Printer_Exists:"En skrivare med detta namn finns redan.",
  No_Printers_Word:"Lägg till den allra första <a routerLink='/folder/Printer/New'>Skrivaren</a>.",
  No_Printer_Alert:"Ingen skrivare är definierad. Vill du ställa in en skrivare?",
  Add_Printer:"Lägg till skrivare",
  New_Printer:"Ny skrivare",
  Printer_Hostname:"Skrivarens värdnamn / IP",
  Printer_Label:"Skrivarens etikett",
  Printer_Protocol:"Skrivarprotokoll",
  Protocol:"Protokoll",
  Email_Print:"E-post",
  AirPrint:"AirPrint",
  IPP:"IPP",
  LPD:"LPD",
  HPJetDirect:"Uttag",
  Print_Job:"Utskriftsjobb",
  Printed:"Tryckt",
  Not_Printed:"Ej tryckt",
  Print_Jobs:"Utskriftsjobb",
  Print_Queue:"Utskriftskö",
  No_Print_Jobs:"Inga utskriftsjobb",
  No_Prints:"Skapa en ny <a routerLink='/folder/Cheque/New'>Kolla upp</a> för att skriva ut.",
  Prints:"Grafik",
  Find_Printer:"Hitta skrivare",
  Find_AirPrint_Devices:"Hitta AirPrint-enheter",
  Select_Printer:"Välj Skrivare",
  System_UI:"System UI",
  Printer:"Skrivare",
  Status:"Status",
  Preview:"Förhandsvisning",
  Enable_Print_Job:"Aktivera utskriftsjobb",
  Queue_Weight:"Kövikt",
  Unlimited:"Obegränsat",
  Show_Advanced_Printer_Options:"Visa avancerade skrivaralternativ",
  Advanced:"Avancerad",
  Location:"Plats",
  Note:"Notera",
  Queue_Name:"Könamn",
  Pages_Printed_Limit:"Utskriftsgräns för sidor",
  MultiPage_Idle_Time:"Flersidig väntetid (er)",
  Page_Count_Limit:"Sidantalets gräns",
  Page_Orientation:"Sidorientering",
  Portrait:"Porträtt",
  Landscape:"Landskap",
  Duplex:"Duplex",
  Double_Sided_Printing:"Dubbelsidig",
  Duplex_Mode:"Duplexläge",
  Duplex_Short:"Kort",
  Duplex_Long:"Lång",
  Duplex_None:"Ingen",
  Color_And_Quality:"Färg och kvalitet",
  Monochrome:"Svartvit",
  Photo_Quality_Prints:"Fotokvalitetsutskrifter",
  Printer_Email:"Skrivarens e-post",
  Automatically_Downsize:"Minska automatiskt",
  Paper:"Papper",
  Paper_Name:"Pappersnamn",
  Paper_Width:"Pappersbredd",
  Paper_Height:"Pappershöjd",
  Paper_Autocut_Length:"Längd för automatisk klippning av papper",
  Margins:"Marginaler",
  Page_Margins:"Sidmarginaler",
  Page_Margin_Top:"Översta sidan Marginal",
  Page_Margin_Right:"Höger sidmarginal",
  Page_Margin_Bottom:"Marginal på nedersta sidan",
  Page_Margin_Left:"Vänster sidmarginal",
  Add_Employees:"Lägg till anställda",
  Header:"Rubrik",
  Print_A_Page_Header:"Skriv ut ett sidhuvud",
  Header_Label:"Rubriketikett",
  Header_Page_Index:"Header Page Index",
  Header_Font_Name:"Header Font",
  Select_Font:"Välj Teckensnitt",
  Font_Selector:"Teckensnittsväljare",
  Header_Font_Size:"Header Font",
  Header_Bold:"Rubrik fet",
  Header_Italic:"Rubrik kursiv",
  Header_Alignment:"Rubrikjustering",
  Left:"Vänster",
  Center:"Centrum",
  Right:"Höger",
  Justified:"Berättigat",
  Header_Font_Color:"Rubrikfärg",
  Select_Color:"Välj Färg",
  Footer:"Sidfot",
  Print_A_Page_Footer:"Skriv ut en sidfot",
  Footer_Label:"Sidfotsetikett",
  Footer_Page_Index:"Sidfot Index",
  Footer_Font_Name:"Sidfot Teckensnitt",
  Fonts:"Teckensnitt",
  Done:"Gjort",
  Select_Fonts:"Välj Teckensnitt",
  Footer_Font_Size:"Sidfotsstorlek",
  Footer_Bold:"Sidfot fet",
  Footer_Italic:"Sidfot kursiv",
  Footer_Alignment:"Sidfotsjustering",
  Footer_Font_Color:"Sidfotsfärg",
  Page_Copies:"Sida kopior",
  Enable_Printer:"Aktivera skrivare",
  Remote_Logging:"Fjärrloggning",
  Log_Server:"Loggserver",
  Encryption:"Kryptering",
  Random_Key:"Slumpmässig nyckel",
  Encryption_Key:"Krypteringsnyckel",
  Cheques_Webserver:"Anpassad databas",
  Learn_How:"Lära sig hur",
  Signature:"Signatur",
  Default_Printer_Unit:"in/cm/mm/(pt)",
  View_Signature:"Visa signatur",
  Printed_Signature:"Tryckt signatur",
  Digitally_Sign:"Signera digitalt",
  Digital_Signature:"Digital signatur",
  Add_Signature:"Lägg till signatur",
  Add_Your_Signature:"Lägg till din signatur",
  Enable_Signature:"Aktivera signatur",
  Digitally_Signed:"Digitalt signerad",
  Insert_Error:"Det gick inte att spara checken på grund av databasproblem.",
  Delete_Confirmation:"Är du säker på att du vill ta bort denna information?",
  Discard_Confirmation:"Är du säker på att du vill kassera denna information?",
  Discard_Bank_Confirmation:"Är du säker på att du vill kassera det här kontot?",
  Discard_Printer_Confirmation:"Är du säker på att du vill kassera den här skrivaren?",
  Delete_Bonus_Confirmation:"Är du säker på att du vill ta bort den här bonusen?",
  Delete_Invoice_Confirmation:"Är du säker på att du vill ta bort den här fakturan?",
  Generated_Cheque:"Genererad check",
  Generated_Invoice:"Genererad faktura",
  Schedule_Start_Time:"Schemalägg start",
  Schedule_End_Time:"Schema slut",
  New_Call:"Nytt samtal",
  No_Contacts:"Inga kontakter",
  No_Contacts_Word:"Administratörer, moderatorer, anställda och betalningsmottagare visas som kontakter.",
  PDF_Subject:"finanser",
  PDF_Keywords:"lönebetalningscheck PDF-kontroller",
  Printer_Setup:"Skrivarinställningar",
  Printer_Selection:"Val av skrivare",
  New_Fax:"Ny fax",
  New_Fax_Message:"Nytt faxmeddelande",
  Fax_Machine:"Faxmaskin",
  Fax_Name:"Faxnamn",
  Fax_Email:"Fax e-post",
  Fax_Number:"Faxnummer",
  Contents:"Innehåll",
  Fax_Body:"Sidans brödtext",
  Header_Word:"Rubrik:",
  Header_Text:"Rubrik",
  Include_Header:"Inkludera rubrik",
  Include_Footer:"Inkludera sidfot",
  Footer_Word:"Sidfot:",
  Footer_Text:"Sidfotstext",
  Attach_a_Cheque:"Bifoga en check",
  Add_Deduction:"Lägg till avdrag",
  Enable_Fax:"Skicka fax",
  Select_Fax:"Välj Fax",
  No_Faxes:"Inga fax",
  Faxes:"Fax",
  Save_and_Send:"Skicka fax",
  Save_and_Pay:"Spara och betala",
  WARNING:"VARNING:",
  Remember:"Kom ihåg",
  Printing:"Utskrift",
  Printing_Complete:"Utskriften är klar!\n\n",
  of:"av",
  There_Were:"Det var",
  Successful_Prints:"framgångsrika utskrifter och",
  Unsuccessful_Prints:"Misslyckade utskrifter.",
  PrinterError:"Förlåt! Det var ett problem.",
  Printing_:"Utskrift...",
  PrinterSuccess:"Dokumentet har skrivits ut.",
  PrintersSuccess:"Dokumenten har skrivits ut.",
  New_Message:"Nytt meddelande",
  New_Messages:"Nya meddelanden",
  Read_Message:"Läs meddelande",
  Write_Message:"Skriv meddelande",
  Send_Message:"Skicka meddelande",
  Subject:"Ämne",
  Message:"Meddelande",
  Writing:"Skrift...",
  Send:"Skicka",
  Set_Date:"Bestämma datum",
  Set_Time:"Ställ klockan",
  Recieve:"Motta",
  Set_as_Default:"Ange som standard",
  Default_Account:"Standardkonto",
  Default_Design:"Standarddesign",
  Multiple_Cheques:"Tre kontroller",
  Account_Mode:"Kontoläge",
  Multiple_Cheques_Description:"Tre kontroller per sida.",
  Check_and_Table:"Check & Tabell",
  Check_including_Table:"Kolla med bokföringstabell.",
  Check_Mailer:"Kolla Mailer",
  Check_Mailer_Window:"Kontrollera med ett adressfönster.",
  DocuGard_Table_Top:"Stor check & bord",
  DocuGard_Table_Middle:"Stor ruta och bord (mitten)",
  DocuGard_Table_Bottom:"Stor ruta och bord (nederst)",
  DocuGard_Mailer_Top:"Stora checkposter",
  DocuGard_Mailer_Middle:"Stor check-post (mitten)",
  DocuGard_Mailer_Bottom:"Stor checkpost (nederst)",
  DocuGard_Three_Cheques:"Tre stora checkar",
  DocuGard_Cheque_Top:"Enkel stor check",
  DocuGard_Cheque_Middle:"Stor bock (mitten)",
  DocuGard_Cheque_Bottom:"Stor check (botten)",
  DocuGard_Three_Cheques_Window:"Tre kontroller på en sida.",
  DocuGard_Table_Top_Window:"Check & intäktstabell.",
  DocuGard_Table_Middle_Window:"Check & intäktstabell.",
  DocuGard_Table_Bottom_Window:"Check & intäktstabell.",
  DocuGard_Mailer_Top_Window:"Check, tabell och adress.",
  DocuGard_Mailer_Middle_Window:"Check, tabell och adress.",
  DocuGard_Mailer_Bottom_Window:"Check, tabell och adress.",
  DocuGard_Cheque_Top_Window:"Kontrollera om det finns säkert papper.",
  DocuGard_Cheque_Middle_Window:"Kontrollera om det finns säkert papper.",
  DocuGard_Cheque_Bottom_Window:"Kontrollera om det finns säkert papper.",
  Basic_Cheque:"Enkel check",
  Basic_Cheque_Print:"Skriv ut en enda check.",
  Error_Setting_As_Paid:"Fel inställning som betald",
  Add_Attachment:"Bifoga fil",
  PrinterUnavailable:"Skrivaren ej tillgänglig",
  CreditCardComponent:"Kort",
  PaypalComponent:"PayPal",
  InteracComponent:"Interac",
  BitcoinComponent:"BitCoin",
  New_Deposit:"Ny insättning",
  Deposits:"Insättningar",
  No_Deposits:"Inga insättningar",
  Credit_Card_Deposit:"Kreditkort",
  New_Credit_Card_Deposit_Message:"Kreditkortsinsättning",
  New_BitCoin_Deposit:"BitCoin",
  New_BitCoin_Deposit_Message:"BitCoin-insättning",
  New_Interac_Deposit:"Interac",
  New_Interac_Deposit_Message:"Interac Transfer",
  Payments_Limit:"Betalningsgräns",
  No_Payment_Limit:"Ingen betalningsgräns",
  PayPal_Deposit:"PayPal",
  PayPal_Deposit_Message:"PayPal-insättning",
  No_Deposits_Word:"Lägg till det första företaget <a routerLink='/folder/Deposit/New'>Insättning</a>.",
  No_Documents_Specified:"Inga dokument angavs för utskrift",
  No_Printers_Added:"Inga skrivare hittades. Gå till Inställningar > Skrivare för att lägga till en.",
  DB_Erase_Prompt:"Radera hela databasen helt? VARNING: Du kommer att förlora all lagrad information!",
  Console_Warning:"Klistra inte in någon text i den här konsolen. Du kan utsätta dig själv och/eller ditt företag för allvarlig risk.",
  No_Faxes_Word:"Skapa det första <a routerLink='/folder/Fax/New'>Faxet</a>.",
  Cheque_Delete_Confirmation:"Är du säker på att du vill ta bort den här checken?",
  Design_Delete_Confirmation:"Är du säker på att du vill ta bort den här designen?",
  Cheque_Pay_Confirmation:"Markera denna check som betald? Det kommer INTE att synas i utskriftskön.",
  Payment_Pay_Confirmation:"Markera denna betalning som betald? Det kommer INTE att synas i checkkön.",
  Delete_Deduction_Confirmation:"Är du säker på att du vill ta bort detta avdrag?",
  Delete_Job_Confirmation:"Är du säker på att du vill ta bort det här jobbet?",
  Delete_Timesheet_Confirmation:"Är du säker på att du vill ta bort denna tidrapport?",
  Delete_Schedule_Confirmation:"Är du säker på att du vill ta bort det här schemat?",
  Delete_Setting_Confirmation:"Är du säker på att du vill återställa den här inställningen?",
  Delete_Fax_Confirmation:"Är du säker på att du vill ta bort detta fax?",
  Delete_File_Confirmation:"Är du säker på att du vill ta bort den här filen?",
  Delete_Vacation_Confirmation:"Är du säker på att du vill ta bort denna semester?",
  Delete_Printer_Confirmation:"Är du säker på att du vill ta bort den här skrivaren?",
  Remove_Design_Confirmation:"Är du säker på att du vill ta bort den här designen?",
  Delete_Payroll_Confirmation:"Är du säker på att du vill ta bort den här lönelistan?",
  Send_Fax_Email_Confirmation:"Vill du faxa och e-posta dessa dokument?",
  Send_Email_Confirmation:"Vill du skicka detta dokument via e-post?",
  Send_Fax_Confirmation:"Vill du faxa det här dokumentet?",
  Error_Generating_PDF:"Förlåt. Det uppstod ett fel när det här dokumentet skapades.",
  PDF_Error_Saving_Image:"Förlåt. Det uppstod ett fel när PDF-bilden av detta dokument skulle sparas.",
  Test_Printer_Confirmation:"Vill du skriva ut en testsida på den här skrivaren?",
  Save_Timesheet_Prompt:"Lägg till en \"Titel\" eller tryck på \"Starta Timer\" för att spara.",
  Remove_Geofence_Prompt:"Är du säker på att du vill ta bort platsen för detta geo-fence?",
  Delete_Employee_Confirmation:"Är du säker att du vill radera",
  Fire_Employee_Confirmation:"Är du säker på att du vill skjuta",
}